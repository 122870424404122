<script lang="ts">
    import ButtonComponent from "../../../libui/button/Root.svelte";
    import { CheckboxRound } from "../../../libui/checkbox";
    import { HierarchyDown } from "../../../libui/icon/svg";
    import ControlItemComponent from "./ControlItemComponent.svelte";
    import type { ControlItemConfig, TextButtonSettings } from "./SelectedArea";
    import { Button, ButtonType } from "../../../libui/button";
    import { VersionedStore } from "../../../VersionedStore";
    import Divider from "../Divider.svelte";
    import { Vector2 } from "math-ts";

    export let name: string;
    export let value: boolean = false;
    export let isEmpty: boolean = false;
    export let onChange: (value: boolean) => void = () => {};
    export let position: 'beg' | 'mid' | 'end' = 'beg';
    export let controlItemConfig: ControlItemConfig | undefined = undefined;
    export let action: TextButtonSettings | undefined = undefined;
    $: isActionDisabled = action?.isEnabled
        ? new VersionedStore(action.isEnabled)
        : undefined;
    $: displayName = isEmpty ? `${name} (empty)` : name;
</script>

<div class="ui-config-property property-row">
    {#if position != 'beg'}
        <div class="hierarchy-holder">
            <HierarchyDown />
        </div>
    {/if}
    <div class="list-components">
        <div class="toggle-root">
            <div class="property-name text-main-medium toggle-name">
                <CheckboxRound {value} {onChange} />
                <div
                    class="property-name-label mdc-typography--body1"
                    class:text-main-dark={!isEmpty}
                    class:text-main-light={isEmpty}
                >
                    {displayName}
                </div>
            </div>
            {#if controlItemConfig}
                <ControlItemComponent config={controlItemConfig} />
            {:else if action && value}
                <ButtonComponent
                    desc={new Button(
                        action.label,
                        ButtonType.Text,
                        (pos) => { action?.action(new Vector2(pos.x, pos.y)); },
                        isActionDisabled ? $isActionDisabled : false,
                        "AddPlus",
                    )}
                />
            {/if}
        </div>
        {#if value}
            <div class="body-container">
                <slot name="body" />
            </div>
        {/if}
    </div>
</div>
{#if position != 'mid'}
    <div class="divider-wrapper">
        <Divider />
    </div>
{/if}

<style lang="scss">
    .list-components {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .toggle-root {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .toggle-name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
    }

    .hierarchy-holder {
        padding: 0 4px 1px 0;
        margin-top: -10px;
        position: relative;
        top: 4px;
    }

    .body-container {
        display: flex;
        flex-direction: column;
        width: calc(100% - 24px);
        margin-left: 24px;
    }
    .property-row {
        padding-top: 4px;
        padding-bottom: 4px;
        .property-name {
            padding-top: 0;
        }
    }
    .divider-wrapper {
        padding: 4px 0;
    }
</style>
