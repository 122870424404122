<script lang="ts">
    import { onMount, onDestroy, setContext, getContext } from 'svelte';
    import { getNotificationsContext } from 'svelte-notifications';

    import Toolbar from './toolbar/Toolbar.svelte';
    import EngineUiPanels from './ui-panels/EngineUiPanels.svelte';
    import Dialog from "./dialogs/Dialog.svelte";
    import ActionSearchBar from "./actions-console/ActionsSearchBar.svelte";

    import { TrackerPilesCollection, createCapitalCostRelatedMetrics,
        type Bim, type Catalog, MathSolversApiImpl, UnitsMapper, ProjectMetrics, CostModel as CM,

        createCostReportRelatedMetrics

    } from 'bim-ts';
    import type { KreoEngine } from 'engine-ts';
    import { VerDataUiBindings, type VerDataSyncer } from 'verdata-ts';
    import { type SyncHub, type ProjectNetworkClient, ScopedLogger, Globals, ObservableStream, LazyDerived, ObjectUtils} from 'engine-utils-ts';
    import { createFilesConvertersUiBindings } from 'files-converters';
    import { VersionedStore } from './VersionedStore';
    import { createCoreUiBindings } from './CoreUiBindings';
    import { LowVoltageCables, CircuitHub } from 'lv-circuit';
    import { createPanelsUiBindings } from 'layout-service';
    import { HotkeysListener, TelemetryEvent, UiBindings } from 'ui-bindings';
    import { FilesImportsHandler } from './files-imports-exports/FilesImportsHandler';
    import { FilesExportsHandler } from './files-imports-exports/FilesExportsHandler';
    import { NotificationsHandler } from './notifications/NotificationsHandler';
    import { NotificationsLog } from './notifications/NotificationsLogStore';
    import ContextMenuViews from './libui/context-menu/ContextMenuViews.svelte';
    import { CostReport } from './cost-report/CostReport';
    import { SharedChartsZoomStates } from './tmy-data-viz-utils/SharedChartsZoomStates';
    import { ProjectVersionParams } from './verdata/ProjectVersionParams';
    import Navbar from "./navbar/Navbar.svelte";
    import RightSideNavbar from "./navbar/RightSideNavbar.svelte";
    import type { NavbarContext } from './navbar/Navbar';
    import { ProjectHub } from './ProjectHub';
    import { registerCostModelContext } from './cost-model/capital/CostModelContext';
    import { ComparisonMode } from './ComparisonMode/ComparisonMode';
    import { IAuthHub } from './auth';
    import { updateDatadogLogsContext } from './DataDogLogs';
    import { initLcoeContext } from './cost-model/lcoe/LcoeContext';
    import { ProjectComparisonItems } from './ComparisonMode/ProjectComparisonItems';

    export let bim: Bim;
    export let engineDomElement: HTMLDivElement;
    export let engine: KreoEngine;
    export let verDataSyncer!: VerDataSyncer;
    export let projectNetworkClient: ProjectNetworkClient;
    export let navbarContext: NavbarContext;

    const logger = new ScopedLogger('core-ui');

    let syncHub: SyncHub = Globals.getSyncHub(projectNetworkClient);

    const lwCables = new LowVoltageCables();
    const catalog = getContext<Catalog>('catalog');
    const network = getContext<ProjectNetworkClient>('network');
    const projectId = getContext<number>('projectId');
    const projectHub = getContext<ProjectHub>('projectHub');
    const uiNavbar = setContext("ui-navbar", navbarContext);
    const authHub = getContext<IAuthHub>('authHub');

    const company = authHub._storeValue.authData?.company ?? '';
    const mathSolverApi = setContext("mathSolverApi", new MathSolversApiImpl({baseNetworkClient: network, company, projectId, callFromDocker: env.solversApiLocal}));

    const dcCircuitHub = new CircuitHub(bim, lwCables, catalog);

    const trackerPilesCollection = setContext(
        'trackerPilesCollection',
        new TrackerPilesCollection(
            bim,
            16,
            catalog,
        )
    );
    (window as any).pilesCollection = trackerPilesCollection;

    // cost-model initialization
    const costsProvider = setContext('costsProvider', new CM.CostsConfigProvider(bim.configs));
    const costModelLazy = setContext('costModelLazy', CM.createCostModel(bim, costsProvider, trackerPilesCollection));



    setContext('bim', bim);
    setContext('engine', engine);
    setContext('verDataSyncer', verDataSyncer);
    setContext('projectNetworkClient', projectNetworkClient);
    setContext('syncHub', syncHub);
    setContext('logger', logger);
    setContext('lwCables', lwCables);
    setContext('dcCircuitHub', dcCircuitHub);
    updateDatadogLogsContext();

    const logContext = setContext('notificationsLog', new NotificationsLog());
    setContext(SharedChartsZoomStates.name, new SharedChartsZoomStates());

    // initialize custom runtimes
    bim.registerCustomRuntimes(mathSolverApi, costsProvider)

    const capitalCostsMetrics = setContext('capital-cost-related-metrics', createCapitalCostRelatedMetrics(costModelLazy));
    const lcoeContext = initLcoeContext(bim);
    const costReportMetrics = setContext(
        'cost-report-related-metrics',
        createCostReportRelatedMetrics(
            capitalCostsMetrics,
            lcoeContext.lcoe,
            bim.unitsMapper,
        )
    )
    registerCostModelContext(uiNavbar)
    // cost-model initialization end

    const layout = new VersionedStore(navbarContext.layout);
    const unitsMapper = getContext<VersionedStore<UnitsMapper>>('unitsMapper');

    const layoutMetrics = setContext('layoutMetricsLazy', new ProjectMetrics(
        bim,
        catalog,
        trackerPilesCollection,
        costReportMetrics,
    ));
    (window as any).metrics = layoutMetrics;


    const costReportUiBindings = new UiBindings(new ScopedLogger('cost-report-ui-bindings'));
    const costReport = setContext<CostReport>(
        'cost-report',
        new CostReport(
            bim,
            costReportUiBindings,
            layoutMetrics,
            network,
            engine.tasksRunner,
            trackerPilesCollection
        ),
    );

    // @ts-ignore
    window.costReport = costReport;


    const lazyProjectInfo = LazyDerived.new0(
        "project-info",
        [projectHub],
        () => {
            return ObjectUtils.deepCloneObj(projectHub._storeValue.project);
        },
    )
    const projectParams = setContext('projectParams', new ProjectVersionParams($authHub, $unitsMapper, engine, layoutMetrics, verDataSyncer));
    VerDataUiBindings.addProjectUiBindings(verDataSyncer.uiBindings, verDataSyncer, () => projectParams.saveVersionWithParams());

    const allUiBindings:UiBindings[] = [
        engine.uiBindings,
        createPanelsUiBindings(logger, engine.tasksRunner, mathSolverApi, bim, lwCables.readAll(), catalog, dcCircuitHub, verDataSyncer, engine, $layout.focusPanel.bind($layout), $unitsMapper),
        verDataSyncer.uiBindings,
        bim.createUiBindings(engine.tasksRunner),
        createFilesConvertersUiBindings(logger, bim, catalog, layoutMetrics, lazyProjectInfo, verDataSyncer, catalog.getSyncer(), trackerPilesCollection),
        catalog.uiBindings,
        costReportUiBindings,
        projectParams.uiBindings,
    ];

    if (catalog.syncer) {
        allUiBindings.push(catalog.syncer.uiBindings);
    }

    $: navbarOffset = $navbarContext.expanded ? 194 : 42;

    const selfUiBindings = createCoreUiBindings({
        logger,
        bim,
        tasksRunner: engine.tasksRunner,
        syncer: verDataSyncer,
        catalog,
        network: projectNetworkClient,
        externalUiBindings: allUiBindings,
        engine,
        layout: $layout,
    });
    allUiBindings.push(selfUiBindings);



    const uiBindings = UiBindings.merged(allUiBindings);

    const rootTelemetryStream = getContext('telemetry-events-root-stream') as ObservableStream<TelemetryEvent> | undefined;
    if (rootTelemetryStream) {
        uiBindings.notificationsStream.subscribe({
            onNext: (notification) => {
                if (notification.addToNotificationsLog) {
                    uiBindings.pushTelemetryEvent(TelemetryEvent.fromNotification({
                        notification: notification,
                        featureFlags: ['in-app-notifications-telemetry']
                    }));
                }
            }
        });
        rootTelemetryStream.mergeFrom(uiBindings.telemetryStream);
    } else {
        console.error('could not find telemetry-events-root-stream in context');
    }

    setContext(
        "projectComparisonItems",
        new ProjectComparisonItems({
            networkClient: projectNetworkClient,
            projectSyncer: verDataSyncer,
            catalogSyncer: catalog.getSyncer(),
            taskRunner: engine.tasksRunner,
            solverApi: mathSolverApi,
            metrics: layoutMetrics,
            comparisonMode: getContext<ComparisonMode>('comparison'),
            project: $projectHub.project ?? ProjectHub.getDefaultProject(),
            uiBindings: uiBindings,
        }));


    // @ts-ignore
    window.uiBindings = setContext('uiBindings', uiBindings);
    $: {
        $layout.updateProviders(uiBindings);
    }

    const hotkeyListener = new HotkeysListener();
    const boundActions = Array.from(uiBindings.actions.values());
    hotkeyListener.setActions(boundActions);

    const filesImportsHandler = new FilesImportsHandler(
        logger,
        projectNetworkClient,
        engine,
        uiBindings,
        navbarContext
    );

    filesImportsHandler.addDragDropListner(engineDomElement);
    setContext('filesImportsHandler', filesImportsHandler);

    const filesExportsHanlder = new FilesExportsHandler(
        logger,
        projectNetworkClient,
        engine,
        uiBindings,
    )
    setContext('filesExportsHandler', filesExportsHanlder);


let notificationsHandler = new NotificationsHandler(getNotificationsContext(), logContext);
$: {
    notificationsHandler.updateStream(uiBindings.notificationsStream);
}

const beforeUnloadHandler = async (event: BeforeUnloadEvent) => {
    await mathSolverApi.cancelActiveTasks();
};

onMount(() => {
    hotkeyListener.subscribe();
    window.addEventListener('beforeunload', beforeUnloadHandler);
});

onDestroy(() => {
    window.removeEventListener('beforeunload', beforeUnloadHandler);
    hotkeyListener.unsubscribe();
    filesImportsHandler.dispose(engineDomElement);
    uiBindings.dispose();
    mathSolverApi.cancelActiveTasks();
    layoutMetrics.dispose();
});

const isAdmin = localStorage.getItem('mode') === 'admin';

export let leftNavWidth: number;
export let rightNavWidth: number;
</script>


<style>
    .engine-ui-root {
        pointer-events: none;
        /* z-index: 0; */
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
    }
    .toolbar-container {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: 100;
        overflow: visible;
    }
    :global(.notifications .default-position-style-bottom-center) {
        z-index: 100;
    }
</style>

<Dialog {uiBindings}>
<ActionSearchBar boundActions={boundActions}></ActionSearchBar>
<Navbar {uiBindings} engineUiLayoutRef={$layout} bind:navbarWidth={leftNavWidth} />
<RightSideNavbar {uiBindings} bind:navbarWidth={rightNavWidth} />
<div
    class="engine-ui-root"
    style="padding-left:{navbarOffset}px;"
>
    <div class="relative" style="width: 100%; max-width:8000px;">
        <EngineUiPanels layout={$layout}></EngineUiPanels>
    </div>
</div>
<div class="toolbar-container" style="left:{leftNavWidth - 4}px;">
    <Toolbar {uiBindings}></Toolbar>
</div>
<ContextMenuViews uiBindings={uiBindings}/>
</Dialog>
