<script lang="ts">
    import type { PUI_CustomPropertyNode } from "ui-bindings";
    import Lock from '../../libui/icon/svg/lock.svelte';
    import flash from "../flash";

    export let property: PUI_CustomPropertyNode<
        any,
        {
            formatter?: (value: any) => string;
            parser?: (input: string) => any;
            errorMessage?: string;
        }
    >;

    $: value = property.value;

    $: formattedValue = property.context.formatter
        ? property.context.formatter(property.value)
        : value.toString();

    let div: HTMLElement;
    let input: HTMLInputElement;
    let preValue = property.value;
    $: if (preValue != property.value && div) {
        preValue = property.value;
        flash(div);
    }
    let isEdit: boolean = false;
    function setEditMode() {
        isEdit = true;
    }
    function setViewMode() {
        isEdit = false;
    }
</script>

<div class="ui-config-property property-row">
    <div bind:this={div} class="property-name text-main-medium">
        <div class="property-name-label mdc-typography--body1">
            {property.name}
        </div>
    </div>

    <div class="property-value" on:click={setEditMode}>
        {#if property.readonly || property.calculated}
            <div
                class="mdc-typography--body1 read-only"
                class:calculated={property.calculated}
            >
                {formattedValue}
                {#if !property.calculated}
                    <Lock />
                {/if}
            </div>
        {:else if isEdit}
            <div class="edit-field-container">
                <input
                    bind:this={input}
                    value={formattedValue}
                    on:input={(e) => {
                        const newValue = e.currentTarget.value;
                        property.value = property.context.parser
                            ? property.context.parser(newValue)
                            : newValue;
                    }}
                    on:blur={setViewMode}
                    autofocus={isEdit}
                    class="
                            config-ui-input-field
                            mdc-typography--body1
                        "
                />
            </div>
        {:else}
            <div class="mdc-typography--body1 read-only">
                {@html formattedValue}
            </div>
        {/if}
        {#if property.context.errorMessage}
            <div
                class="mdc-typography--body2 read-only calculated multiline text-danger"
            >
                {property.context.errorMessage}
            </div>
        {/if}
    </div>
</div>

<style lang="scss">
</style>