// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { GraphGeometryT } from './graph-geometry.js';
import { GraphGeometry } from './graph-geometry.js';


export class GraphGeometryCollection implements flatbuffers.IUnpackableObject<GraphGeometryCollectionT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):GraphGeometryCollection {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsGraphGeometryCollection(bb:flatbuffers.ByteBuffer, obj?:GraphGeometryCollection):GraphGeometryCollection {
  return (obj || new GraphGeometryCollection()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsGraphGeometryCollection(bb:flatbuffers.ByteBuffer, obj?:GraphGeometryCollection):GraphGeometryCollection {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new GraphGeometryCollection()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

formatVersion():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

ids(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
}

idsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

idsArray():Uint32Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? new Uint32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

collection(index: number, obj?:GraphGeometry):GraphGeometry|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new GraphGeometry()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

collectionLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startGraphGeometryCollection(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addFormatVersion(builder:flatbuffers.Builder, formatVersion:number) {
  builder.addFieldInt32(0, formatVersion, 0);
}

static addIds(builder:flatbuffers.Builder, idsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, idsOffset, 0);
}

static createIdsVector(builder:flatbuffers.Builder, data:number[]|Uint32Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createIdsVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createIdsVector(builder:flatbuffers.Builder, data:number[]|Uint32Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt32(data[i]!);
  }
  return builder.endVector();
}

static startIdsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addCollection(builder:flatbuffers.Builder, collectionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, collectionOffset, 0);
}

static createCollectionVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startCollectionVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endGraphGeometryCollection(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createGraphGeometryCollection(builder:flatbuffers.Builder, formatVersion:number, idsOffset:flatbuffers.Offset, collectionOffset:flatbuffers.Offset):flatbuffers.Offset {
  GraphGeometryCollection.startGraphGeometryCollection(builder);
  GraphGeometryCollection.addFormatVersion(builder, formatVersion);
  GraphGeometryCollection.addIds(builder, idsOffset);
  GraphGeometryCollection.addCollection(builder, collectionOffset);
  return GraphGeometryCollection.endGraphGeometryCollection(builder);
}

unpack(): GraphGeometryCollectionT {
  return new GraphGeometryCollectionT(
    this.formatVersion(),
    this.bb!.createScalarList<number>(this.ids.bind(this), this.idsLength()),
    this.bb!.createObjList<GraphGeometry, GraphGeometryT>(this.collection.bind(this), this.collectionLength())
  );
}


unpackTo(_o: GraphGeometryCollectionT): void {
  _o.formatVersion = this.formatVersion();
  _o.ids = this.bb!.createScalarList<number>(this.ids.bind(this), this.idsLength());
  _o.collection = this.bb!.createObjList<GraphGeometry, GraphGeometryT>(this.collection.bind(this), this.collectionLength());
}
}

export class GraphGeometryCollectionT implements flatbuffers.IGeneratedObject {
constructor(
  public formatVersion: number = 0,
  public ids: (number)[] = [],
  public collection: (GraphGeometryT)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const ids = GraphGeometryCollection.createIdsVector(builder, this.ids);
  const collection = GraphGeometryCollection.createCollectionVector(builder, builder.createObjectOffsetList(this.collection));

  return GraphGeometryCollection.createGraphGeometryCollection(builder,
    this.formatVersion,
    ids,
    collection
  );
}
}
