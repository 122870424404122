// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { Vector2T } from './vector2.js';
import { Vector2 } from './vector2.js';


export class SceneImageRepresentation implements flatbuffers.IUnpackableObject<SceneImageRepresentationT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SceneImageRepresentation {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSceneImageRepresentation(bb:flatbuffers.ByteBuffer, obj?:SceneImageRepresentation):SceneImageRepresentation {
  return (obj || new SceneImageRepresentation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSceneImageRepresentation(bb:flatbuffers.ByteBuffer, obj?:SceneImageRepresentation):SceneImageRepresentation {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SceneImageRepresentation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

imageId():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

worldSize(obj?:Vector2):Vector2|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new Vector2()).__init(this.bb_pos + offset, this.bb!) : null;
}

static startSceneImageRepresentation(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addImageId(builder:flatbuffers.Builder, imageId:number) {
  builder.addFieldInt32(0, imageId, 0);
}

static addWorldSize(builder:flatbuffers.Builder, worldSizeOffset:flatbuffers.Offset) {
  builder.addFieldStruct(1, worldSizeOffset, 0);
}

static endSceneImageRepresentation(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): SceneImageRepresentationT {
  return new SceneImageRepresentationT(
    this.imageId(),
    (this.worldSize() !== null ? this.worldSize()!.unpack() : null)
  );
}


unpackTo(_o: SceneImageRepresentationT): void {
  _o.imageId = this.imageId();
  _o.worldSize = (this.worldSize() !== null ? this.worldSize()!.unpack() : null);
}
}

export class SceneImageRepresentationT implements flatbuffers.IGeneratedObject {
constructor(
  public imageId: number = 0,
  public worldSize: Vector2T|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  SceneImageRepresentation.startSceneImageRepresentation(builder);
  SceneImageRepresentation.addImageId(builder, this.imageId);
  SceneImageRepresentation.addWorldSize(builder, (this.worldSize !== null ? this.worldSize!.pack(builder) : 0));

  return SceneImageRepresentation.endSceneImageRepresentation(builder);
}
}
