import type { Disposable, ObjectHash, Result} from './';
import { Failure, LruCache, Yield, resultify } from './';
import { DefaultMap } from './DefaultMap';
import { ObjectUtils } from './ObjectUtils';

export class IterUtils {

    static getFirstFromIter<T>(iter: Iterable<T>): T | undefined {
        return iter[Symbol.iterator]().next().value;
    }

	static any<T>(iter: Iterable<T>, fn: (it: T) => boolean): boolean {
		for (const it of iter) {
			if (fn(it)) {
				return true;
			}
		}
		return false;
	}

    static sum<T>(iter: Iterable<T>, fn: (it: T) => number): number {
        let sum = 0;
        for (const it of iter) {
            const n = fn(it);
            sum += n;
		}
		return sum;
    }

    static find<T>(iter: Iterable<T>, searchFunc: (it: T) => boolean): T | undefined {
        for (const it of iter) {
            if (searchFunc(it)) {
                return it;
            }
        }
        return undefined;
    }


    static findBackToFront<T>(iter: Iterable<T>, searchFunc: (it: T) => boolean): T | undefined {
        for (const it of IterUtils.reverseIter(iter)) {
            if (searchFunc(it)) {
                return it;
            }
        }
        return undefined;
    }

    static findIndexBackToFront<T>(array: ArrayLike<T>, searchFunc: (it: T) => boolean): number {
        for (let i = array.length - 1; i >= 0; --i) {
            if (searchFunc(array[i])) {
                return i;
            }
        }

        return -1;
    }

    static extendArray<T>(array: T[], iter: Iterable<T>) {
        for (const it of iter) {
            array.push(it);
        }
    }

    static extendSet<T>(set: Set<T>, iter: Iterable<T>) {
        for (const it of iter) {
            set.add(it);
        }
    }

    static intersectsSet<T>(set: Set<T>, iter: Iterable<T>): boolean {
        for (const it of iter) {
            if (set.has(it)) {
                return true;
            }
        }
        return false;
    }

    static setsDiff<T>(set1: Set<T>, set2: Set<T>): {absentInL: T[], absentInR: T[]} {
        const absentInL = [];
        const absentInR = [];
        for (const it of set2) {
            if (!set1.has(it)) {
                absentInL.push(it);
            }
        }
        for (const it of set1) {
            if (!set2.has(it)) {
                absentInR.push(it);
            }
        }
        return {absentInL, absentInR};
    }

    static areArrayItemsUnique<T>(array: T[]): boolean {
        if (array.length < 20) { //todo: test for best constant
            for (let i = 1; i < array.length; ++i) {
                if (array.includes(array[i - 1], i)) {
                    return false;
                }
            }
            return true;
        } else {
            const set = new Set(array);
            return set.size === array.length;
        }
    }

    static deleteFromSet<T>(set: Set<T>, iter: Iterable<T>) {
        for (const it of iter) {
            set.delete(it);
        }
    }

    static iterToSet<T>(iter: Iterable<T>): Set<T> {
        if (iter instanceof Set) {
            return iter;
        } else {
            return new Set(iter);
        }
    }

    static newArrayWithIndices(startFrom: number, count: number): number[] {
        const res: number[] = [];
        for (let i = 0; i < count; ++i) {
            res.push(i + startFrom);
        }
        return res;
    }

    static newArray<T>(length: number, generator: (index: number) => T): T[] {
        const res: T[] = [];
        for (let i = 0; i < length; ++i) {
            const it = generator(i);
            res.push(it);
        }
        return res;
    }

    static *lazyMap<T, R>(iter: Iterable<T>, fn: (it: T) => R): Iterable<R> {
        for (const it of iter) {
            const r = fn(it);
            yield r;
        }
    }

    static *asyncMap<T, R>(args: {
        arrayToConsume: T[],
        maxConcurrency: number,
        mapFn: (it: T) => Promise<R>,
    }): Generator<Yield, Result<R>[]> {

        const results: Result<R>[] = new Array(args.arrayToConsume.length);
        const awaitingPromises = new Map<Promise<R>, number>();
        const allPromisesIterator = IterUtils.lazyMap(args.arrayToConsume, args.mapFn)[Symbol.iterator]();

        let nextPromise = allPromisesIterator.next();
        let index = 0;
        do {
            if (awaitingPromises.size < args.maxConcurrency && nextPromise.value !== undefined) {
                const iPromise = nextPromise.value as Promise<R>;
                if (!(iPromise instanceof Promise)) {
                    console.error('expected map function to return promise, aborting', iPromise, index);
                }
                const iPromiseIndex = index++;
                nextPromise = allPromisesIterator.next();
                awaitingPromises.set(iPromise, iPromiseIndex);
                iPromise.then(
                    (r: R) => {
                        results[awaitingPromises.get(iPromise)!] = resultify(r);
                        awaitingPromises.delete(iPromise);
                    },
                    (e: any) => {
                        results[awaitingPromises.get(iPromise)!] = new Failure(e);
                        awaitingPromises.delete(iPromise);
                    }
                )
            }

            yield Yield.NextFrame;
        } while (awaitingPromises.size > 0 || index < args.arrayToConsume.length);
        console.assert(results.length === args.arrayToConsume.length);
        return results;
    }

    static map<T1, R>(
        seq1: ArrayLike<T1>,
        fn: (it1: T1) => R,
    ): R[] {
        const length = Math.min(seq1.length);
        const res: R[] = new Array(length);
        for (let i = 0; i < length; ++i) {
            const r = fn(seq1[i]);
            res[i] = r;
        }
        return res;
    }
    static map2<T1, T2, R>(
        seq1: ArrayLike<T1>,
        seq2: ArrayLike<T2>,
        fn: (it1: T1, it2: T2) => R
    ): R[] {
        const length = Math.min(seq1.length, seq2.length);
        const res: R[] = new Array(length);
        for (let i = 0; i < length; ++i) {
            const r = fn(seq1[i], seq2[i]);
            res[i] = r;
        }
        return res;
    }
    static map3<T1, T2, T3, R>(
        seq1: ArrayLike<T1>,
        seq2: ArrayLike<T2>,
        seq3: ArrayLike<T3>,
        fn: (it1: T1, it2: T2, it3: T3) => R
    ): R[] {
        const length = Math.min(seq1.length, seq2.length, seq3.length);
        const res: R[] = new Array(length);
        for (let i = 0; i < length; ++i) {
            const r = fn(seq1[i], seq2[i], seq3[i]);
            res[i] = r;
        }
        return res;
    }
    static map4<T1, T2, T3, T4, R>(
        seq1: ArrayLike<T1>,
        seq2: ArrayLike<T2>,
        seq3: ArrayLike<T3>,
        seq4: ArrayLike<T4>,
        fn: (it1: T1, it2: T2, it3: T3, it4: T4) => R
    ): R[] {
        const length = Math.min(seq1.length, seq2.length, seq3.length, seq4.length);
        const res: R[] = new Array(length);
        for (let i = 0; i < length; ++i) {
            const r = fn(seq1[i], seq2[i], seq3[i], seq4[i]);
            res[i] = r;
        }
        return res;
    }
    static map5<T1, T2, T3, T4, T5, R>(
        seq1: ArrayLike<T1>,
        seq2: ArrayLike<T2>,
        seq3: ArrayLike<T3>,
        seq4: ArrayLike<T4>,
        seq5: ArrayLike<T5>,
        fn: (it1: T1, it2: T2, it3: T3, it4: T4, it5: T5) => R
    ): R[] {
        const length = Math.min(seq1.length, seq2.length, seq3.length, seq4.length, seq5.length);
        const res: R[] = new Array(length);
        for (let i = 0; i < length; ++i) {
            const r = fn(seq1[i], seq2[i], seq3[i], seq4[i], seq5[i]);
            res[i] = r;
        }
        return res;
    }
    static map6<T1, T2, T3, T4, T5, T6, R>(
        seq1: ArrayLike<T1>,
        seq2: ArrayLike<T2>,
        seq3: ArrayLike<T3>,
        seq4: ArrayLike<T4>,
        seq5: ArrayLike<T5>,
        seq6: ArrayLike<T6>,
        fn: (it1: T1, it2: T2, it3: T3, it4: T4, it5: T5, it6: T6) => R
    ): R[] {
        const length = Math.min(seq1.length, seq2.length, seq3.length, seq4.length, seq5.length, seq6.length);
        const res: R[] = new Array(length);
        for (let i = 0; i < length; ++i) {
            const r = fn(seq1[i], seq2[i], seq3[i], seq4[i], seq5[i], seq6[i]);
            res[i] = r;
        }
        return res;
    }
    static map7<T1, T2, T3, T4, T5, T6, T7, R>(
        seq1: ArrayLike<T1>,
        seq2: ArrayLike<T2>,
        seq3: ArrayLike<T3>,
        seq4: ArrayLike<T4>,
        seq5: ArrayLike<T5>,
        seq6: ArrayLike<T6>,
        seq7: ArrayLike<T7>,
        fn: (it1: T1, it2: T2, it3: T3, it4: T4, it5: T5, it6: T6, it7: T7) => R
    ): R[] {
        const length = Math.min(seq1.length, seq2.length, seq3.length);
        const res: R[] = new Array(length);
        for (let i = 0; i < length; ++i) {
            const r = fn(seq1[i], seq2[i], seq3[i], seq4[i], seq5[i], seq6[i], seq7[i]);
            res[i] = r;
        }
        return res;
    }

    static groupBy<T, Key>(seq: Iterable<T>, keyExtractor: (it: T) => Key): Iterable<[Key, T[]]> {
        const byKey = new DefaultMap<Key, T[]>(() => []);
        for (const item of seq) {
            const key = keyExtractor(item);
            byKey.getOrCreate(key).push(item);
        }
        return byKey.entries();
    }

    static groupObjects<T>(
        objects: Iterable<T>,
        hash: (o: T) => ObjectHash,
        eq: (l: T, r: T) => boolean
    ): T[][] {
        const cache = new LruCache<T, T[]>({
            factoryFn: () => [],
            hashFn: hash,
            identifier: 'cache',
            maxSize: Infinity,
            eqFunction: eq,
        })
        for (const o of objects) {
            cache.get(o).push(o);
        }
        const result = IterUtils.mapIter(cache._entriesBirth.keys(), x => x.obj)
        return result;
    }

    static countBy<T, Key>(seq: Iterable<T>, keyExtractor: (it: T) => Key): Iterable<[Key, number]> {
        const byKey = new Map<Key, number>();
        for (const item of seq) {
            const key = keyExtractor(item);
            const prevCount = byKey.get(key) ?? 0;
            byKey.set(key, prevCount + 1);
        }
    return byKey.entries();
    }

    static count<T>(seq: Iterable<T>, filter: (it: T) => boolean): number {
        let count = 0;
        for (const item of seq) {
            if (filter(item)) {
                count += 1;
            }
        }
        return count;
    }

    static newMapFromTuples<K, V>(tuples: Iterable<[K, V]>): Map<K, V> {
        const m = new Map();
        for (const t of tuples) {
            if (m.has(t[0])) {
                throw new Error(`unexpected repeated key in tuples iter ${t[0]}`);
            }
            m.set(t[0], t[1]);
        }
        return m;
    }

    static newMapFromIter<T, K, V>(iter: Iterable<T>, keyGet: (it: T) => K, valueGet: (it: T) => V): Map<K, V> {
        const map = new Map();
        for (const it of iter) {
            const key = keyGet(it);
            const value = valueGet(it);
            if (map.has(key)) {
                throw new Error(`unexptected repeated key ${key}`);
            }
            map.set(key, value);
        }
        return map;
    }

    static combineByKey<T, K>(iter: Iterable<T>, keyGet: (it: T) => K): Map<K, T[]> {
        const map = new Map<K, T[]>();
        for (const it of iter) {
            const key = keyGet(it);
            const arr = map.get(key);
            if (arr) {
                arr.push(it);
            } else {
                map.set(key, [it])
            }
        }
        return map;
    }

    static mapIter<T, R>(iter: Iterable<T>, fn: (it: T) => R): R[] {
        const res: R[] = [];
        for (const it of iter) {
            res.push(fn(it));
        }
        return res;
    }

    static *iterMap<T, R>(iter: Iterable<T>, fn: (it: T) => R): Iterable<R> {
        for (const it of iter) {
            const r = fn(it);
            yield r;
        }
    }

    static ofClass<T>(iter: Iterable<any>, ctor: {new(...args: any[]): T}): T[] {
        const res: T[] =[];
		for (const it of iter) {
            if (it instanceof ctor) {
                res.push(it);
            }
        }
		return res;
    }

    static findByProperty<T extends Object, K extends keyof T>(iter: Iterable<T>, key: K, val: T[K]): T | undefined {
        for (const it of iter) {
            if (it[key] === val) {
                return it;
            }
        }
        return undefined;
    }

	static filter<T>(iter: Iterable<T>, fn: (it: T) => boolean): T[] {
        const res: T[] = [];
        for (const it of iter) {
            if (fn(it)) {
                res.push(it);
            }
        }
        return res;
    }

    static filterMap<T, R>(iter: Iterable<T>, fn: (it: T) => R | undefined | null): R[] {
        const res: R[] = [];
        for (const it of iter) {
            const r = fn(it);
            if (r != undefined) {
                res.push(r);
            }
        }
        return res;
    }

    static flatMapIter<T1, TRes>(iter: Iterable<T1>, map: (it: T1) => Iterable<TRes>): TRes[] {
        let res: TRes[] = [];
        for (const it of iter) {
            for (const x of map(it)) {
                res.push(x);
            }
        }
        return res;
    }

	static asArray<T>(iter: Iterable<T>): T[] {
		if (Array.isArray(iter)) {
			return iter;
		}
		return Array.from(iter);
	}

    static *reverseIter<T>(iter: Iterable<T>): Iterable<T> {
        let array: T[];
        if (Array.isArray(iter)) {
            array = iter;
        } else {
            array = Array.from(iter);
        }
        for (let i = array.length - 1; i >= 0; --i) {
            yield array[i];
        }
    }

    static reduceToString<T>(iter: Iterable<T>, stringifier: (item: T) => string): string {
        let result = '';
        for (const it of iter) {
            result += stringifier(it);
        }
        return result;
    }


    static areOptionalArraysEqual<T>(arr1: ArrayLike<T> | undefined | null, arr2: ArrayLike<T> | undefined | null, comparer: (lhs:T, rhs:T) => boolean = ObjectUtils.areObjectsEqual) {
		if (arr1 == arr2) {
			return true;
		}
		if (!arr1 || !arr2) {
			return false;
		}
		return IterUtils.areArraysEqual(arr1, arr2, comparer);
    }

    static areArraysEqual<T>(arr1: ArrayLike<T>, arr2: ArrayLike<T>, comparer: (lhs:T, rhs:T) => boolean = ObjectUtils.areObjectsEqual) {
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; ++i) {
            const o1 = arr1[i];
            const o2 = arr2[i];
            if (!comparer(o1, o2)) {
                return false;
            }
        }
        return true;

    }

    static *indexed<T>(
        iter: Iterable<T>
    ): Iterable<[item: T, index: number]> {
        let index = 0;
        for (const x of iter) {
            yield [x, index++];
        }
    }

    static *splitIterIntoChunks<T>(
        iter: Iterable<T>,
        chunkSize: number,
    ): Iterable<T[]> {
        if (!(Number.isInteger(chunkSize) && chunkSize > 0)) {
            throw new Error('chunk size should be positive integer');
        }
        let chunk: T[] = [];
        for (const x of iter) {
            chunk.push(x);
            if (chunk.length === chunkSize) {
                yield chunk;
                chunk = [];
            }
        }
        if (chunk.length !== 0) {
            yield chunk;
        }
    }

    static splitArrayIntoChunks<T>(array: T[], chunkSize: number): T[][] {
        if (array.length == 0) {
            return [];
        }
        if (!(Number.isInteger(chunkSize) && chunkSize > 0)) {
            throw new Error('chunk size should be positive integer');
        }
        const result: T[][] = [];
        for (let chunkStart = 0; chunkStart < array.length; chunkStart += chunkSize) {
            const chunk = array.slice(chunkStart, Math.min(chunkStart + chunkSize, array.length));
            result.push(chunk);
        }
        return result;
    }

    static areMapsEqual<K, V>(lhs: Map<K, V>, rhs: Map<K, V>, comparer?: (lhs:V, rhs:V) => boolean) {
        if (lhs.size !== rhs.size) {
            return false;
        }
        const comp: (lhs:V, rhs:V) => boolean = comparer ?? ObjectUtils.areObjectsEqual;
        for (const [k, v] of lhs) {
            const rv = rhs.get(k);
            if (rv === undefined) {
                return false;
            }
            if (!comp(v, rv)) {
                return false;
            }
        }
        return true;
    }

    static _sortDedupNumbers<T extends number>(numbers: T[], sortFn: (lhs: T, rhs: T) => number) {
		if (numbers.length <= 1) {
			return;
		}
		numbers.sort(sortFn);
		let nextRead = 1;
		let nextWrite = 1;
		let len = numbers.length;
		while (nextRead < len) {
			let handle = numbers[nextRead];
			let prevW = numbers[nextWrite - 1];
			if (handle !== prevW) {
				if (nextRead != nextWrite) {
					numbers[nextWrite] = handle;
				}
				nextWrite += 1;
			}
			nextRead += 1;
		}
		numbers.length = nextWrite;
	}

	static sortDedupNumbers<T extends number>(numbers: T[]) {
		IterUtils._sortDedupNumbers(numbers, (h1, h2) => h1 - h2);
	}

    static sortDedupDescending<T extends number>(numbers: T[]) {
		IterUtils._sortDedupNumbers(numbers, (h1, h2) => h2 - h1);
	}

	static min(numbers: Iterable<number>): number | undefined {
        let min: number | undefined = undefined;
        for (const n of numbers) {
            if (min === undefined || n < min) {
                min = n;
            }
        }
        return min;
    }

    static max(numbers: Iterable<number>): number | undefined {
        let max: number | undefined = undefined;
        for (const n of numbers) {
            if (max === undefined || n > max) {
                max = n;
            }
        }
        return max;
    }

    static maxBy<T>(arr: Iterable<T>, keyExtractor: (obj: T) => number): T | undefined {
        let max = -Infinity;
        let maxObj: T | undefined = undefined;
        for (const it of arr) {
            const key = keyExtractor(it);
            if (key > max) {
                max = key;
                maxObj = it;
            }
        }
        return maxObj;
    }

    static minBy<T>(arr: Iterable<T>, keyExtractor: (obj: T) => number): T | undefined {
        let min = Infinity;
        let maxObj: T | undefined = undefined;
        for (const it of arr) {
            const key = keyExtractor(it);
            if (key < min) {
                min = key;
                maxObj = it;
            }
        }
        return maxObj;
    }

    static dedupNeighboursByEq<T>(arr: T[], eq: (lhs: T, rhs: T) => boolean): {removed: T[]} {
        if (arr.length <= 1) {
            return {removed: []};
        }
        let nextRead = 1;
        let nextWrite = 1;
        let len = arr.length;
        const removed: T[] = [];
        while (nextRead < len) {
            let next = arr[nextRead];
            let prev = arr[nextWrite - 1];
            if (!eq(next, prev)) {
                if (nextRead != nextWrite) {
                    arr[nextWrite] = next;
                }
                nextWrite += 1;
            } else {
                removed.push(next);
            }
            nextRead += 1;
        }
        arr.length = nextWrite;
        return { removed };
    }

	static retain<T>(arr: T[], toRemove: (it: T, index: number) => boolean): {removed: T[], removedIndices: number[]} {
        let nextRead = 0;
        let nextWrite = 0;
        let len = arr.length;
        const removed: T[] = [];
        const removedIndices: number[] = [];
        while (nextRead < len) {
            let next = arr[nextRead];
            if (!toRemove(next, nextRead)) {
                if (nextRead != nextWrite) {
                    arr[nextWrite] = next;
                }
                nextWrite += 1;
            } else {
                removed.push(next);
                removedIndices.push(nextRead);
            }
            nextRead += 1;
        }
        arr.length = nextWrite;
        return { removed, removedIndices };
    }

    static deleteFromArray<T>(arr: T[], item: T): boolean {
        const index = arr.indexOf(item);
        if (index >= 0) {
            arr.splice(index, 1);
            return true;
        }
        return false;
    }

    static disposeArray(arr: Disposable[]) {
        for (const it of arr) {
            try {
                it.dispose();
            } catch (e) {
                console.error(e);
            }
        }
        arr.length = 0;
    }

    static disposeMap(map: Map<any, Disposable>) {
        for (const it of map.values()) {
            try {
                it.dispose();
            } catch (e) {
                console.error(e);
            }
        }
        map.clear();
    }

    static withItemSeparator<T, S>(array: T[], separator: S | (() => S)) {
        const result: Array<T | S> = [];
        for (let i = 0; i < array.length; i++) {
            const item = array[i]
            result.push(item)
            if (i < array.length - 1) {
                if (separator instanceof Function) {
                    result.push(separator());
                } else {
                    result.push(separator);
                }
            }
        }
        return result;
    }
}
