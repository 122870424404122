// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { FlatBufferKeyValue } from '../flat-buffers/flat-buffer-key-value.js';


export class FlatBufferProjectionInfo {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):FlatBufferProjectionInfo {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsFlatBufferProjectionInfo(bb:flatbuffers.ByteBuffer, obj?:FlatBufferProjectionInfo):FlatBufferProjectionInfo {
  return (obj || new FlatBufferProjectionInfo()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsFlatBufferProjectionInfo(bb:flatbuffers.ByteBuffer, obj?:FlatBufferProjectionInfo):FlatBufferProjectionInfo {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new FlatBufferProjectionInfo()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

method():string|null
method(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
method(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

parameters(index: number, obj?:FlatBufferKeyValue):FlatBufferKeyValue|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new FlatBufferKeyValue()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

parametersLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startFlatBufferProjectionInfo(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addMethod(builder:flatbuffers.Builder, methodOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, methodOffset, 0);
}

static addParameters(builder:flatbuffers.Builder, parametersOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, parametersOffset, 0);
}

static createParametersVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startParametersVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endFlatBufferProjectionInfo(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createFlatBufferProjectionInfo(builder:flatbuffers.Builder, methodOffset:flatbuffers.Offset, parametersOffset:flatbuffers.Offset):flatbuffers.Offset {
  FlatBufferProjectionInfo.startFlatBufferProjectionInfo(builder);
  FlatBufferProjectionInfo.addMethod(builder, methodOffset);
  FlatBufferProjectionInfo.addParameters(builder, parametersOffset);
  return FlatBufferProjectionInfo.endFlatBufferProjectionInfo(builder);
}
}
