// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class Polyline implements flatbuffers.IUnpackableObject<PolylineT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Polyline {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsPolyline(bb:flatbuffers.ByteBuffer, obj?:Polyline):Polyline {
  return (obj || new Polyline()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsPolyline(bb:flatbuffers.ByteBuffer, obj?:Polyline):Polyline {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Polyline()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

points(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readFloat64(this.bb!.__vector(this.bb_pos + offset) + index * 8) : 0;
}

pointsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

pointsArray():Float64Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? new Float64Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

radius():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

pointsLocalIds(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readUint32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
}

pointsLocalIdsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

pointsLocalIdsArray():Uint32Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? new Uint32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

static startPolyline(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addPoints(builder:flatbuffers.Builder, pointsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, pointsOffset, 0);
}

static createPointsVector(builder:flatbuffers.Builder, data:number[]|Float64Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createPointsVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createPointsVector(builder:flatbuffers.Builder, data:number[]|Float64Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(8, data.length, 8);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addFloat64(data[i]!);
  }
  return builder.endVector();
}

static startPointsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(8, numElems, 8);
}

static addRadius(builder:flatbuffers.Builder, radius:number) {
  builder.addFieldFloat64(1, radius, 0.0);
}

static addPointsLocalIds(builder:flatbuffers.Builder, pointsLocalIdsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, pointsLocalIdsOffset, 0);
}

static createPointsLocalIdsVector(builder:flatbuffers.Builder, data:number[]|Uint32Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createPointsLocalIdsVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createPointsLocalIdsVector(builder:flatbuffers.Builder, data:number[]|Uint32Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt32(data[i]!);
  }
  return builder.endVector();
}

static startPointsLocalIdsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endPolyline(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createPolyline(builder:flatbuffers.Builder, pointsOffset:flatbuffers.Offset, radius:number, pointsLocalIdsOffset:flatbuffers.Offset):flatbuffers.Offset {
  Polyline.startPolyline(builder);
  Polyline.addPoints(builder, pointsOffset);
  Polyline.addRadius(builder, radius);
  Polyline.addPointsLocalIds(builder, pointsLocalIdsOffset);
  return Polyline.endPolyline(builder);
}

unpack(): PolylineT {
  return new PolylineT(
    this.bb!.createScalarList<number>(this.points.bind(this), this.pointsLength()),
    this.radius(),
    this.bb!.createScalarList<number>(this.pointsLocalIds.bind(this), this.pointsLocalIdsLength())
  );
}


unpackTo(_o: PolylineT): void {
  _o.points = this.bb!.createScalarList<number>(this.points.bind(this), this.pointsLength());
  _o.radius = this.radius();
  _o.pointsLocalIds = this.bb!.createScalarList<number>(this.pointsLocalIds.bind(this), this.pointsLocalIdsLength());
}
}

export class PolylineT implements flatbuffers.IGeneratedObject {
constructor(
  public points: (number)[] = [],
  public radius: number = 0.0,
  public pointsLocalIds: (number)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const points = Polyline.createPointsVector(builder, this.points);
  const pointsLocalIds = Polyline.createPointsLocalIdsVector(builder, this.pointsLocalIds);

  return Polyline.createPolyline(builder,
    points,
    this.radius,
    pointsLocalIds
  );
}
}
