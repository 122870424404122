<script lang="ts">
import type { AssetCatalogItemProps, Catalog, SolarArrayConfig } from "bim-ts";
import { LazyBasic, LazyDerived, type LazyVersioned } from "engine-utils-ts";
import { getContext } from "svelte";
import { PUI_PropertyNodeString } from "ui-bindings";
import { VersionedStore } from "../../../VersionedStore";
import PropertyView from "../../PropertyView.svelte";
import { extractArrayVaration } from "./FrameModuleMatcher";
import ReadonlyPresetDescription from "./ReadonlyPresetDescription.svelte";


const catalog = getContext<Catalog>('catalog');

export let config: SolarArrayConfig;

const selectedCatalogItemId = new LazyBasic<number>('selectedCatalogItemId', -1)

$: selectedCatalogItemId.replaceWith(config.preset.value.at(0)?.id ?? -1)

const assetIdToSiId = catalog.assets.assetsMatcher.sceneInstancePerAsset.assetIdToSceneInstanceId;
const virtualBim = catalog.assets.assetsMatcher.sceneInstancePerAsset.bim;
const selectedPresetDescription = new VersionedStore(LazyDerived.new1(
    'selectedPresetDescription',
    [assetIdToSiId, catalog.catalogItems],
    [(selectedCatalogItemId as LazyVersioned<number>)],
    ([id]) => {
        const catalogItem = catalog.catalogItems.peekById(id);
        if (!catalogItem) {
            return null;
        }
        const assetId = catalogItem.as<AssetCatalogItemProps>().properties.asset_id.value;
        const siId = assetIdToSiId.perId.get(assetId);
        if (siId === undefined) {
            return null;
        }
        const si = virtualBim.instances.peekById(siId);
        if (!si) {
            return null;
        }
        return extractArrayVaration(si);
    }
).withoutEqCheck())

</script>

{#if $selectedPresetDescription}
    <ReadonlyPresetDescription description={$selectedPresetDescription} />
{:else}
    <PropertyView offset={0} property={new PUI_PropertyNodeString({
        name: '',
        value: 'preset is not parsable',
        onChange: () => {},
        readonly: true,
    })} />
{/if}


<style lang="scss">
</style>
