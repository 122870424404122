import { Vector4 } from 'math-ts';

import type { ShaderBase } from './ShaderBase';

export const GhostShader: ShaderBase = {

	name: 'Ghost',
	
	uniforms: {
		'sideColor': { value: new Vector4(1, 1, 1, 1) },
		'frontColor': { value: new Vector4(0, 0, 0, 1) },
	},

	defines: {
		'NEEDS_NORMALS': true,
		'CUSTOM_STD_ATTRIBUTES': true,
		'CUSTOM_STD_UNIFORMS': true,
		'NEEDS_WORLD_SPACE': true,
	},

	vertexShader: `

	
		#include <kr_std_uniforms>
		#include <kr_std_attributes>
		#include <kr_std_vars>
	
		void main() {

			#include <kr_std_vars_calc>
			
		}

	`,

	fragmentShader: `

		#include <kr_std_uniforms>
		#include <kr_std_vars>

		uniform vec4 sideColor;
		uniform vec4 frontColor;
		
		void main() {

			#include <kr_std_pixel>

			float vInterp =  abs(normal.z);
			
			gl_FragColor = mix( sideColor, frontColor, vInterp );

		}

	`
};