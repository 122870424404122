<script lang="ts">
    import type { Bim, EnergyPipelineStage } from "bim-ts";
    import type { UiBindings } from "ui-bindings";
    import { getContext } from "svelte";
    import { Button, ButtonComponent, ButtonType } from "../../libui/button";
    import { BarData } from "./CalculateBarChart";
    import ChartBar from "./ChartBar.svelte";

    export let name: string;
    export let bar: BarData;
    export let stage: EnergyPipelineStage | undefined = undefined;

    const bim = getContext("bim") as Bim;
    const uiBindings = getContext("uiBindings") as UiBindings;

    $: warnings = stage ? stage.toEnergyStageWarnings() : [];
</script>

<div class="bar">
    <slot />

    <ChartBar
        {name}
        energyLength={bar.energyLength}
        energyLabel={bar.energyLabel}
        lossLength={bar.lossLength}
        lossLabel={bar.lossLabel}
    >
        <svelte:fragment slot="warnings">
            {#if warnings.length}
                <div class="warnings mdc-typography--tooltip">
                    {#each warnings as error}
                        <div
                            class="warning"
                            class:text-danger={error.type === "error"}
                        >
                            {error.description}
                            {#each error.actions as action}
                                <ButtonComponent
                                    compact={true}
                                    desc={new Button(
                                        action.name,
                                        ButtonType.Text,
                                        () => {
                                            action.execute(bim, uiBindings);
                                        },
                                    )}
                                />
                            {/each}
                        </div>
                    {/each}
                </div>
            {/if}
        </svelte:fragment>
    </ChartBar>
</div>

<style lang="scss">
    .bar {
        margin-bottom: 4px;
        :global(.group-holder .group.root-level) {
            margin-top: 4px;
        }
        :global(.ui-config-property) {
            --offset: 28px;
        }
    }
    .warnings {
        margin-bottom: 4px;
    }
    .warning {
        padding: 0 8px;
    }
</style>
