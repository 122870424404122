import type { Bim} from 'bim-ts';
import { BimImage, BimProperty, BimRawImage, ImageFormat, SceneImageRepresentation, SceneInstance } from 'bim-ts';
import { Yield } from 'engine-utils-ts';
import { ObjectUtils, PngConverter } from 'engine-utils-ts';
import { Vector2, Vector3 } from 'math-ts';
import { requestSettings } from '../SettingsFormUi';
import type { FileImporter, FileImporterContext, FileToImport } from 'ui-bindings';

export interface SceneImageImportSettings {
    segmentSizeInMeters: number;
    origin: Vector3;
}

export class PngImageImporter implements FileImporter {

    fileExtensions = ['.png'];

    defaultImportSettings: SceneImageImportSettings = {
        segmentSizeInMeters: 0.1,
        origin: new Vector3(0, 0, 0),
    };

    constructor(
        readonly bim: Bim,
    ) {

    }

    *startImport(context: FileImporterContext, file: FileToImport): Generator<Yield, void> {
        requestSettings({
            ident: 'image-import-settings',
            defaultValue: ObjectUtils.deepCloneObj(this.defaultImportSettings),
            context,
            submit: (params) => context.importInstances(this.importInstances(context, file, params))
        });
    }

    *importInstances(context: FileImporterContext, file: FileToImport, params: SceneImageImportSettings) {
            const image = parsePng(file.fileArrayBuffer);

            const perPixelBytes = image.colors.length / (image.width * image.height);

            // swap image rows, png has +Y down
            const imageColors = image.colors.slice();
            for (let i = 0; i < image.height; ++i) {

                const row = image.colors.subarray(
                    (image.height - i - 1) * image.width * perPixelBytes,
                    (image.height - i - 0) * image.width * perPixelBytes
                );
                imageColors.set(row, i * image.width * perPixelBytes);
            }

            const imageId = this.bim.bimImages.reserveNewId();
            const bimImage = new BimImage(
                null, // TODO: upload image to local assets
                new BimRawImage(
                    image.width, image.height, ImageFormat.RgbaByte, imageColors
                )
            );

            const allocated = this.bim.bimImages.allocate([[imageId, bimImage]]);
            if (allocated[0] != imageId) {
                throw new Error('bim image alloc unsucessfull');
            };

            var imageRepresentation = new SceneImageRepresentation(
                imageId,
                new Vector2(image.width, image.height).multiplyScalar(params.segmentSizeInMeters)
            );

            const siId = this.bim.instances.idsProvider.reserveNewId();
            const alloc2= this.bim.instances.allocate([[
                siId,
                newDefaultImageInstance('image', file.filename, imageRepresentation),
            ]], {});
            this.bim.instances.setSelected(alloc2);
            yield* context.onFinish(alloc2);
    }
}

export function parsePng(raw: ArrayBuffer): { width: number, height: number, colors: Uint8Array } {
    const image = PngConverter.decode(raw) as any;
    const rgbaImageData = PngConverter.toRGBA8(image)[0];
    return {
        width: image.width,
        height: image.height,
        colors: new Uint8Array(rgbaImageData)
    }
}

export function newDefaultImageInstance(typeIdent: string, sourceFileName: string, representation: SceneImageRepresentation): SceneInstance {
    const instance = new SceneInstance(typeIdent);
    instance.representation = representation;
    const path = ['image', 'source_file_name'];
    instance.properties.applyPatch([[
        BimProperty.MergedPath(path),
        {
            path: path,
            value: sourceFileName,
            readonly: true,
        }
    ]]);
    instance.name = sourceFileName;
    return instance;
}
