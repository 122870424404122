import type { LazyVersioned, Result } from "engine-utils-ts";
import { Allocated, DefaultMap, Deleted, IterUtils, ObservableObject, ScopedLogger, Success, Updated, VersionedInvalidator, Yield } from "engine-utils-ts";
import type { Matrix4 } from "math-ts";
import { Aabb, Aabb2, Clipper, PointToPolygon, PolygonUtils, Vector2 } from "math-ts";
import type { PUI_GroupNode } from "ui-bindings";
import type { Bim, EntitiesCollectionUpdates, IdBimScene, MathSolversApi, RepresentationBase, SceneInstancePatch } from "../..";
import { BoundaryType, BoundaryTypeIdent, SceneObjDiff } from "../..";
import { TransformerIdent } from "../../archetypes/transformer/Transformer";
import type { GroupDescription, GroupIdent, GroupingSolver, GroupingSolverSettings } from "../BimCustomGroupedRuntime";
import { BimCustomGroupedRuntime } from "../BimCustomGroupedRuntime";
import type { SharedGlobalsInput } from "../RuntimeGlobals";

export function registerBlockNumberSolver(bim: Bim){
    bim.customRuntimes.registerCustomRuntime(new BimCustomGroupedRuntime(
        bim.logger,
        'block-number-solver',
        new BlockNumberSolver()
    ));
}

class BlockNumberGroupIdent implements GroupIdent {
    public readonly substationId: IdBimScene | 0;
    public readonly sortKey: string;
    
    constructor(
        substationId: IdBimScene,
    ) {
        this.substationId = substationId;
        this.sortKey = substationId.toString();
        Object.freeze(this);
    }

    uniqueHash(): string | number {
        return this.substationId;
    }
}

class TransformerGroupDescription implements GroupDescription<BlockNumberGroupIdent> {

    constructor(
        public readonly ident: BlockNumberGroupIdent,
        public readonly parent: IdBimScene,
        public readonly previousId: number,
        public readonly transformersHashes: Map<IdBimScene, boolean>,
    ) {

    }

    *inputInstancesIds(): Iterable<IdBimScene> {

    }
}

type TransformerPatches = [IdBimScene, SceneInstancePatch][];

class BlockNumberSolverSettings implements GroupingSolverSettings {
    enabled: boolean = true;
    delaySeconds: number = 0.5;
}

const globalArgsSelector = {
    
};

interface BlockInstanceHash {
    blockNumber?: number | undefined;
}

export class BlockNumberSolver implements GroupingSolver<
    BlockNumberGroupIdent,
    TransformerGroupDescription,
    TransformerPatches,
    BlockNumberSolverSettings,
    typeof globalArgsSelector
> {
    logger: ScopedLogger;
    name: string = "block-number-solver";
    calculationsInvalidator: VersionedInvalidator;
    alwaysPassAllInvalidationBimUpdates?: boolean | undefined;
    executionOrder: number = 20;
    globalArgsSelector: typeof globalArgsSelector = globalArgsSelector;
    settings: ObservableObject<BlockNumberSolverSettings>;
    ui?: LazyVersioned<PUI_GroupNode> | undefined;

    private readonly _transformersHashes: Map<IdBimScene, BlockInstanceHash> = new Map();

    constructor() {
        this.logger = new ScopedLogger(this.name);
        this.calculationsInvalidator = new VersionedInvalidator();
        this.settings = new ObservableObject({
            identifier: this.name + '-settings',
            initialState: new BlockNumberSolverSettings(),
        });
    }

    invalidate(logger: ScopedLogger, bim: Bim, instancesUpdate: EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>): void {
        if(instancesUpdate instanceof Allocated || instancesUpdate instanceof Updated){
            let hasChanges = false;
            function setHash(id: IdBimScene, collection: Map<IdBimScene, BlockInstanceHash>, hash: BlockInstanceHash) {
                const prevHash = collection.get(id);  
                if(prevHash === undefined || prevHash?.blockNumber !== hash.blockNumber){
                    collection.set(id, hash);
                    hasChanges = true;
                }
            }
            const isAllocated = instancesUpdate instanceof Allocated;
            for (const id of instancesUpdate.ids) {
                const typeIdent = bim.instances.peekTypeIdentOf(id);
                if(typeIdent !== TransformerIdent && typeIdent !== BoundaryTypeIdent){
                    continue;
                }
                const instance = bim.instances.peekById(id)!;
                const relevantFlags = SceneObjDiff.NewProps | SceneObjDiff.LegacyProps;
                const checkDiffTransformer = isAllocated 
                    ? isAllocated 
                    : (instancesUpdate.allFlagsCombined & relevantFlags) !== 0;
                if(typeIdent === TransformerIdent && checkDiffTransformer){
                    const blockNumber = instance.properties.get("circuit | position | block_number")?.asNumber();
                    setHash(id, this._transformersHashes, { blockNumber });
                    continue;
                }
            }
            if(hasChanges){
                this.calculationsInvalidator.invalidate();
            }
        } else if(instancesUpdate instanceof Deleted){
            for (const id of instancesUpdate.ids) {
                this._transformersHashes.delete(id);
            }
            this.calculationsInvalidator.invalidate();
        } else {
            logger.error('unknown instancesUpdate', instancesUpdate);
        }
    }


    *generateCalculationGroups(args: { logger: ScopedLogger; bim: Bim; globalArgs: SharedGlobalsInput<{}>; }): Generator<Yield, TransformerGroupDescription[], unknown> {
        const parents = args.bim.instances.peekByTypeIdent('substation');
        const groups: TransformerGroupDescription[] = [];

        let previousId = 0;
        let hasChanges = false;
        for (const [id] of parents) {
            const transformers = new Map<IdBimScene, boolean>();
            let blockCount = 0;
            args.bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(id, (id) => {
                const typeIdent = args.bim.instances.peekTypeIdentOf(id);
                if(typeIdent !== TransformerIdent){
                    return true;
                }

                const hash = this._transformersHashes.get(id);
                if(!hash){
                    args.logger.debug('hash not found', id);
                    return true;
                }
                const blockNumber = this._transformersHashes.get(id)?.blockNumber;
                if(blockNumber === undefined){
                    hasChanges = true;
                    blockCount++;
                }
                transformers.set(id, hash.blockNumber !== undefined);
                return true;
            }, true);
            if(!hasChanges){
                transformers.clear();
            }
            
            const group = new TransformerGroupDescription(
                new BlockNumberGroupIdent(id),
                id,
                previousId,
                transformers,
            );

            previousId += blockCount;
            groups.push(group);

            yield Yield.Asap;
        }

        return IterUtils.mapIter(groups.values(), gr => Object.freeze(gr));
    }

    *startGroupResultsCalculation(args: { logger: ScopedLogger; bim: Bim; mathSolversApi: MathSolversApi; groupDescription: TransformerGroupDescription; }): Generator<Yield, TransformerPatches, unknown> {
        return yield* numberTransformers(args.logger, args.bim, new Set(Array.from(args.groupDescription.transformersHashes.keys())), args.groupDescription.previousId);
    }

    applyResultsToBim(args: { logger: ScopedLogger; bim: Bim; groupDescription: TransformerGroupDescription; groupCalcResults: Result<TransformerPatches>; }): void {
        if(args.groupCalcResults instanceof Success) {
            args.bim.instances.applyPatches(args.groupCalcResults.value);
        } else {
            this.logger.error('applyResultsToBim ', args.groupCalcResults);
        }
    }
}

interface ObjectPosition {
    position: Vector2;
    box: Aabb2;
}

export function* numberSelectedTransformers(selectedIds: IdBimScene[], bim: Bim): Generator<Yield, void, unknown>{
    const transformers = new Set<IdBimScene>();
    for (const id of selectedIds) {
        const typeIdent = bim.instances.peekTypeIdentOf(id);
        if(typeIdent !== TransformerIdent){
            continue;
        }
        transformers.add(id);
    }
    const results =  yield* numberTransformers(bim.logger, bim, transformers, 0);
    bim.instances.applyPatches(results);
    return;
}

function* numberTransformers(logger: ScopedLogger, bim: Bim, transformers: Set<IdBimScene>, startNumber: number) {
    const boundaries = getBoundaries(bim);
    yield Yield.Asap;

    const goemetriesAabbs = bim.allBimGeometries.aabbs.poll();

    const reprsBboxes = new DefaultMap<RepresentationBase, Aabb>(r => r.aabb(goemetriesAabbs));
    const blocks = new Map<IdBimScene, {
        transformerId: IdBimScene;
        position: Vector2;
        box: Aabb2;
    }>();
    const reusableAabb = Aabb.empty();
    function createGlobalAabb2(aabb: Aabb, worldMatrix: Matrix4) {
        const aabb2 = reusableAabb.copy(aabb).applyMatrix4(worldMatrix).xy();
        return aabb2;
    }
    const groups = new DefaultMap<IdBimScene, IdBimScene[]>(() => []);
    const notGrouped: IdBimScene[] = [];
    const instances = bim.instances.peekByTypeIdent(TransformerIdent);
    for (let index = 0; index < instances.length; index++) {
        const [id, inst] = instances[index];
        if (!transformers.has(id)) {
            continue;
        }

        if (inst?.type_identifier !== TransformerIdent) {
            continue;
        }

        const representation = inst.representation;
        if (!representation) {
            continue;
        }

        const reprAabb = reprsBboxes.getOrCreate(representation);
        if (reprAabb.isEmpty()) {
            continue;
        }
        const aabb2 = createGlobalAabb2(reprAabb, inst.worldMatrix);
        const position = aabb2.getCenter();
        let boundary: IdBimScene | undefined;
        function isPointInside(p: Vector2, contour: Vector2[]) {
            return PolygonUtils.isPointInsidePolygon(contour, p) !== PointToPolygon.Outside;
        }
        function findBlockBoundary(aabb2: Aabb2, blockPoints: ReadonlyArray<Vector2>) {
            let boundaryId = undefined;
            for (const b of boundaries) {
                if (!b.box.intersectsBox2(aabb2)) {
                    continue;
                }
                const isIntersectBoundary = blockPoints.some(p => isPointInside(p, b.contour));
                if (isIntersectBoundary) {
                    boundaryId = b.id;
                    const transformerGroup = groups.getOrCreate(b.id);
                    transformerGroup.push(id);
                    break;
                }
            }
            return boundaryId;
        }

        boundary = findBlockBoundary(aabb2, aabb2.cornerPoints());


        bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(
            id,
            (childId) => {
                const childInst = bim.instances.peekById(childId);

                if (!childInst) {
                    return true;
                }
                const representation = childInst.representation;
                if (!representation) {
                    return true;
                }

                const reprAabb = reprsBboxes.getOrCreate(representation);
                if (reprAabb.isEmpty()) {
                    return true;
                }
                const instBox2 = createGlobalAabb2(reprAabb, childInst.worldMatrix);
                aabb2.union(instBox2);

                if(!boundary){
                    boundary = findBlockBoundary(aabb2, [aabb2.getCenter()]);
                }

                return true;
            },
            true
        );

        if (boundary === undefined) {
            notGrouped.push(id);
        }

        if (index % 50 === 0) {
            yield Yield.Asap;
        }

        blocks.set(id, {
            transformerId: id,
            position: position,
            box: aabb2
        });
    }


    const patches: [IdBimScene, SceneInstancePatch][] = [];
    function addPatch(id: IdBimScene, blockNumber: number) {
        patches.push([
            id,
            {
                properties: [
                    [
                        "circuit | position | block_number",
                        {
                            path: ["circuit", "position", "block_number"],
                            value: blockNumber,
                            numeric_step: 1
                        },
                    ],
                ],
            },
        ]);
    }
    let numberBlock = startNumber;
    for (const b of boundaries) {
        const group = groups.get(b.id);
        if (group && group.length > 0) {
            const items = sortFn(group.map(t => blocks.get(t)!));
            for (const item of items) {
                addPatch(item.transformerId, ++numberBlock);
            }
        }
    }
    const notGroupedItems = sortFn(notGrouped.map(g => blocks.get(g)!));
    for (const item of notGroupedItems) {
        addPatch(item.transformerId, ++numberBlock);
    }
    return patches;
}

function sortFn<T extends ObjectPosition>(positions: T[]): T[] {
    const sortByY = positions
        .slice()
        .sort((a, b) => b.position.y - a.position.y);
    const totalBound = Aabb2.empty();
    for (const pos of positions) {
        if(pos.box.isEmpty()){
            continue;
        }
        totalBound.union(pos.box);
    }

    const reusableAabb = Aabb2.empty();
    const sortedArray: T[] = [];
    while (sortByY.length > 0) {
        const minY = sortByY[0];
        reusableAabb.setFromPoints([
            {x: totalBound.min.x, y: minY.box.max.y}, 
            {x: totalBound.max.x, y: Math.min(minY.box.centerY(), minY.position.y)}
        ]);
        const line: T[] = [];
        for (const item of sortByY) {
            if (item.box.intersectsBox2(reusableAabb)) {
                line.push(item);
            } else {
                break;
            }
        }
        sortByY.splice(0, line.length);
        const sortByX = line.sort((a, b) => a.position.x - b.position.x);
        for (const pos of sortByX) {
            sortedArray.push(pos);
        }
    }

    return sortedArray;
}

function getBoundaries(bim: Bim) {
    const boundaries2d = bim
        .extractBoundaries()
        .filter(t => t.boundaryType === BoundaryType.Include && t.pointsWorldSpace.length > 2)
        .map(b => b.pointsWorldSpace);
    const merged = boundaries2d.length ? Clipper.unionPolygons2D(orderContours(boundaries2d)) : boundaries2d;
    const boundaries = merged
        .map((b, idx)=>({
            id: idx + 1,
            contour: b,
            position: getBoundaryPos(b),
            box: Aabb2.empty().setFromPoints(b)
        }));

    const sorted = sortFn(boundaries);
    return sorted;
}

function getBoundaryPos(points: Vector2[]){
    let x: number | undefined;
    let y: number | undefined;
    for (const p of points) {
        x = x ? Math.min(p.x, x) : p.x;
        y = y ? Math.max(p.y, y) : p.y;
    }
    return new Vector2(x, y);
}

function orderContours(contours: Vector2[][]){
    return contours.map(c => PolygonUtils.isClockwiseInner(c) ? c : c.slice().reverse());
}
