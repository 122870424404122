<script lang="ts">
    import type { RuntimeSystemsUiContext } from "./RuntimeSystemsUiContext";
    import { PUI_Lazy } from "ui-bindings";
    import PUI_Root from "../pui/PUI_Root.svelte";

    export let context: RuntimeSystemsUiContext;

    let runtimeUi = context.getUi();
</script>

<div class="container">
    <PUI_Root
        configIdentifier={"runtime-uis"}
        puiSource={new PUI_Lazy(runtimeUi)}
        inDialog={true}
    ></PUI_Root>
</div>

<style lang="scss">
    .container {
        max-height: inherit;
    }
</style>
