import type { PropValueTotalHash } from "../../properties/Props";
import { PropertyBase } from "../../properties/Props";
import { getPropertyReferenceId } from "../../properties/PropsRefsIds";
import type { NonMethodsOnly } from "engine-utils-ts";
import type { CustomPropertySerializer} from "../../properties/CustomPropsRegistry";
import { CustomPropsRegistry } from "../../properties/CustomPropsRegistry";
import { PerHeightSamples, ShadingFactorsTable, shadingPerHeightSamplesDedup } from './ShadingFactorsTable';


export interface TrackerShadingPropertyArgs {
    isReadonly?: boolean;
    description?: string;
    value: ShadingFactorsTable;
}

// TODO: rename to ShadingProperty
export class TrackerShadingFactorsProperty extends PropertyBase {

    description?: string;
    value: ShadingFactorsTable;

    constructor(params: TrackerShadingPropertyArgs) {
        super();
        this.description = params.description;
        this.value = params.value;
        Object.freeze(this);
    }

    uniqueValueHash(): PropValueTotalHash {
        return getPropertyReferenceId(this);
    }

    equals(other: PropertyBase): boolean {
        if (!(other instanceof TrackerShadingFactorsProperty)) {
            return false;
        }
        return this.description === other.description
            && this.value.equals(other.value);
    }
}

class TrackerShadingPropertySerializerClass implements CustomPropertySerializer<TrackerShadingFactorsProperty> {
    currentFormatVersion: number = 1;

    // serializeToBinary?(p: TrackerShadingProperty): Uint8Array {
    //     throw new Error("Method not implemented.");
    // }
    serializeToString(p: TrackerShadingFactorsProperty): string {
        return JSON.stringify(p);
    }
    // deserializeFromBinary(formatVersion: number, serialized: Uint8Array): TrackerShadingProperty {
    // }
    deserializeFromString(formatVersion: number, serialized: string): TrackerShadingFactorsProperty|null {
        if (formatVersion < this.currentFormatVersion) {
            return null;
        }
        const data = JSON.parse(serialized) as NonMethodsOnly<TrackerShadingFactorsProperty>;
        const perHeightShadingFactors = data.value.perHeightSamples.map(sf => {
            let shadingFactorsPerHeight = new PerHeightSamples(sf.height, sf.step, sf.samples);
            return shadingPerHeightSamplesDedup.getOrCreate(shadingFactorsPerHeight);
        });
        return new TrackerShadingFactorsProperty({
            value: new ShadingFactorsTable(perHeightShadingFactors)
        });
    }
}

CustomPropsRegistry.register({
    class: TrackerShadingFactorsProperty,
    serializerClass: TrackerShadingPropertySerializerClass,
    constructFromPartial: (params: Partial<TrackerShadingFactorsProperty>) => {
        return new TrackerShadingFactorsProperty({
            description: params.description,
            value: params.value || new ShadingFactorsTable([])
        });
    },
})
