import type { Bim, DC_CNSTS, IdBimScene, SceneInstance} from "src";
import { CombinerBoxTypeIdent, InverterTypeIdent, NumberProperty, TrackerTypeIdent } from "src";
import type { ConnectionDescription} from "src/sld/types";
import { ModuleTableDescription, groupByLooseComparison } from "src/sld/types";
import { CombinerBoxDescription, InverterDescription, TransformerDescription } from "./types";
import { LvWireTypeIdent } from "src/archetypes/LvWire";
import { extractConnectionDescription } from "../utils";
import { extractModuleTablesDescriptions } from "src/sld/utils";
import type { CombinerBoxConnectionType } from "src/sld/templates/module-table";
import type { Result} from "engine-utils-ts";
import { Failure, Success } from "engine-utils-ts";

export function createTransformerDescriptionFromScene(bim: Bim, id: IdBimScene)
    : Result<TransformerDescription>
{
    const transformerSi = bim.instances.peekById(id)!;
    const transformerProps = extractTransformerProperties(transformerSi);
    if (transformerProps instanceof Failure) {
        return new Failure({
            msg: 'Can not extract transformer description',
            inner: [transformerProps]
        })
    }

    const description = new TransformerDescription({
        inverters: [],
        sceneId: id,
        text: transformerProps.costItem,
    });
    const childs = bim.instances.spatialHierarchy._allObjects.get(id)?.children ?? [];
    const invertors: InverterDescription[] = [];
    for (const { id } of childs) {
        const child = bim.instances.peekById(id);
        if (!child) {
            continue;
        }
        if (child.type_identifier === InverterTypeIdent) {
            const invDesc = extractInverterDescription(
                id,
                child,
                bim,
                transformerProps.stringsDivision,
                transformerProps.pattern,
            );
            invertors.push(invDesc)
        }
    }
    description.inverters = groupByLooseComparison(invertors);
    return new Success(description);
}

function extractInverterDescription(
    id: IdBimScene,
    si: SceneInstance,
    bim: Bim,
    stringDivision: number[],
    pattern: DC_CNSTS.PatternName,
): InverterDescription {
    const costItem = si.properties.get('cost | cost_item')?.asText() ?? 'Central inverter';
    const descripiton = new InverterDescription({
        sceneId: id,
        combinerBoxes: [],
        text: costItem,
    })

    const combinerBoxConnectionType: CombinerBoxConnectionType
        = pattern === 'CI_Multiharness' ? 'multi' : 'single';

    const childs = bim.instances.spatialHierarchy._allObjects.get(id)?.children ?? [];
    const cbs: CombinerBoxDescription[] = [];
    let dcFeeder: ConnectionDescription | null = null;
    for (const { id } of childs) {
        const child = bim.instances.peekById(id);
        if (!child) {
            continue;
        }
        if (child.type_identifier === CombinerBoxTypeIdent) {
            if (combinerBoxConnectionType === 'single') {
                cbs.push(extractCombinerBoxWithTrunkbusDescription(id, child, bim))
            } else {
                cbs.push(extractCombinerBoxWithMultiharnessDescription(
                    id, child, bim, stringDivision
                ))
            }
        }
        if (!dcFeeder && child.type_identifier === LvWireTypeIdent){
            dcFeeder = extractConnectionDescription(id, child);
        }
    }
    if (dcFeeder) {
        for (const cb of cbs) {
            cb.connectionToParent = dcFeeder;
        }
    }
    descripiton.combinerBoxes = Array.from(groupByLooseComparison(cbs));

    return descripiton;
}

function extractCombinerBoxWithTrunkbusDescription(
    combinerBoxId: IdBimScene,
    combinerBoxSi: SceneInstance,
    bim: Bim,
): CombinerBoxDescription {
    const cbProps = extractCombinerBoxProperties(combinerBoxSi);
    const descripiton = new CombinerBoxDescription({
        connectionType: 'single',
        sceneId: combinerBoxId,
        moduleTables: [],
        text: cbProps.costItem,
    })
    if (cbProps.operatingCurrent) {
        descripiton.outputCurrent = NumberProperty.new({
            value: +cbProps.operatingCurrent.asNumber().toPrecision(3),
            unit: cbProps.operatingCurrent.unit,
        })
    }
    const tableDescriptions = extractModuleTablesDescriptions(combinerBoxId, bim, [1]);

    // configure wiring
    let trunkbus: ConnectionDescription | null = null
    let extender: ConnectionDescription | null = null
    for (const { id } of bim.instances.spatialHierarchy._allObjects.get(combinerBoxId)?.children ?? []) {
        if (trunkbus && extender) {
            break;
        }
        const child = bim.instances.peekById(id);
        if (!child || child.type_identifier !== LvWireTypeIdent) {
            continue;
        }
        const lvWiringType = child.properties.get('specification | type')?.asText()
        if (lvWiringType === 'Extender') {
            extender = extractConnectionDescription(id, child)
        } else if (lvWiringType === 'TrunkBus') {
            trunkbus = extractConnectionDescription(id, child)
        }
    }
    if (trunkbus) {
        for (const table of tableDescriptions) {
            table.connectionToParent = trunkbus;
        }
    }
    if (extender) {
        for (const table of tableDescriptions) {
            for (const matrix of table.matrices) {
                matrix.connectionToParent = extender;
            }
        }
    }

    const grouped = ModuleTableDescription.group(tableDescriptions);
    descripiton.moduleTables.push(...grouped);

    return descripiton;
}

function extractCombinerBoxWithMultiharnessDescription(
    combinerBoxId: IdBimScene,
    combinerBoxSi: SceneInstance,
    bim: Bim,
    stringDivision: number[],
): CombinerBoxDescription {
    const cbProps = extractCombinerBoxProperties(combinerBoxSi);
    const descripiton = new CombinerBoxDescription({
        connectionType: 'multi',
        sceneId: combinerBoxId,
        moduleTables: [],
        text: cbProps.costItem,
    })
    if (cbProps.operatingCurrent) {
        descripiton.outputCurrent = NumberProperty.new({
            value: +cbProps.operatingCurrent.asNumber().toPrecision(3),
            unit: cbProps.operatingCurrent.unit,
        })
    }
    const tableDescriptions = extractModuleTablesDescriptions(combinerBoxId, bim, stringDivision);

    let whip: ConnectionDescription | null = null
    let firstTrackerId: IdBimScene | null = null
    for (const { id } of bim.instances.spatialHierarchy._allObjects.get(combinerBoxId)?.children ?? []) {
        if (firstTrackerId && whip) {
            break;
        }
        const child = bim.instances.peekById(id);
        if (!child) {
            continue;
        }
        if (!firstTrackerId || child.type_identifier === TrackerTypeIdent) {
            firstTrackerId = id;
            continue;
        }
        if (child.type_identifier === LvWireTypeIdent) {
            const lvWiringType = child.properties.get('specification | type')?.asText()
            if (lvWiringType === 'Whip') {
                whip = extractConnectionDescription(id, child)
                continue;
            }
        }
    }

    let harness: ConnectionDescription | null = null
    if (firstTrackerId) {
        for (const { id } of bim.instances.spatialHierarchy._allObjects.get(firstTrackerId)?.children ?? []) {
            const child = bim.instances.peekById(id);
            if (!child || child.type_identifier !== LvWireTypeIdent) {
                continue;
            }
            const lvWiringType = child.properties.get('specification | type')?.asText()
            if (lvWiringType === 'MultiHarness') {
                harness = extractConnectionDescription(id, child)
                break;
            }
        }
    }
    if (whip) {
        for (const table of tableDescriptions) {
            table.connectionToParent = whip;
        }
    }
    if (harness) {
        for (const table of tableDescriptions) {
            for (const matrix of table.matrices) {
                matrix.connectionToParent = harness;
            }
        }
    }

    const grouped = ModuleTableDescription.group(tableDescriptions);
    descripiton.moduleTables.push(...grouped);
    return descripiton;
}

function extractTransformerProperties(si: SceneInstance) {
    const pattern: DC_CNSTS.PatternName | undefined =
        si.properties.get('circuit | pattern | type')?.value;
    if (!pattern) {
        return new Failure({ msg: "Transformer pattern not set" })
    }
    const costItem = si.properties.get('cost | cost_item')?.asText() ?? 'Transformer';
    let stringsDivision = si.properties
        .getPropStartingWith('circuit | pattern | multiharness | div')?.map(x => x.asNumber())
        ?? [1];
    if (!stringsDivision.length) {
        stringsDivision = [1]
    }
    return {
        pattern,
        costItem,
        stringsDivision,
    }
}

function extractCombinerBoxProperties(si: SceneInstance) {
    const operatingCurrent = si.properties.get('circuit | aggregated_capacity | max_current');
    const costItem = si.properties.get('cost | cost_item')?.asText() ?? 'Combiner box';
    return {
        operatingCurrent,
        costItem,
    }
}
