
type UrlParams = "catalog-version" | "project-version";
export function extractVerdataVersionFromUrl(type: UrlParams): number | undefined {
    const url = new URL(window.location.toString());
    const catalogVersion = parseInt(url.searchParams.get(type) ?? '');
    if(isFinite(catalogVersion)) {
        return catalogVersion;
    }

    return;
}