<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H8C8.55228 15 9 14.5523 9 14C9 13.4477 8.55228 13 8 13H7ZM6 10C6 9.44772 6.44772 9 7 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11H7C6.44771 11 6 10.5523 6 10ZM16 13C15.4477 13 15 13.4477 15 14C15 14.5523 15.4477 15 16 15H17C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13H16ZM10.5 14C10.5 13.4477 10.9477 13 11.5 13H12.5C13.0523 13 13.5 13.4477 13.5 14C13.5 14.5523 13.0523 15 12.5 15H11.5C10.9477 15 10.5 14.5523 10.5 14Z"
        fill="currentColor"
    />
</svg>
