// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { CustomSerializedPropertyT } from './custom-serialized-property.js';
import type { NumberPropertyWithOptionsT } from './number-property-with-options.js';
import type { NumericRangePropertyT } from './numeric-range-property.js';
import type { PropertyAssetsValueT } from './property-assets-value.js';
import type { PropertyBoolValueT } from './property-bool-value.js';
import type { PropertyCatalogItemsValueT } from './property-catalog-items-value.js';
import type { PropertyColorValueT } from './property-color-value.js';
import type { PropertyMultiSelectionValueT } from './property-multi-selection-value.js';
import type { PropertyNumericValueT } from './property-numeric-value.js';
import type { PropertySceneInstancesValueT } from './property-scene-instances-value.js';
import type { PropertySelectionValueT } from './property-selection-value.js';
import type { PropertyStringValueT } from './property-string-value.js';
import { PropertyValue, unionToPropertyValue } from './property-value.js';


export class Property implements flatbuffers.IUnpackableObject<PropertyT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Property {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsProperty(bb:flatbuffers.ByteBuffer, obj?:Property):Property {
  return (obj || new Property()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsProperty(bb:flatbuffers.ByteBuffer, obj?:Property):Property {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Property()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

propertyType():PropertyValue {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : PropertyValue.NONE;
}

property<T extends flatbuffers.Table>(obj:any):any|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
}

description():string|null
description(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
description(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

readonly():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

static startProperty(builder:flatbuffers.Builder) {
  builder.startObject(4);
}

static addPropertyType(builder:flatbuffers.Builder, propertyType:PropertyValue) {
  builder.addFieldInt8(0, propertyType, PropertyValue.NONE);
}

static addProperty(builder:flatbuffers.Builder, propertyOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, propertyOffset, 0);
}

static addDescription(builder:flatbuffers.Builder, descriptionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, descriptionOffset, 0);
}

static addReadonly(builder:flatbuffers.Builder, readonly:boolean) {
  builder.addFieldInt8(3, +readonly, +false);
}

static endProperty(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createProperty(builder:flatbuffers.Builder, propertyType:PropertyValue, propertyOffset:flatbuffers.Offset, descriptionOffset:flatbuffers.Offset, readonly:boolean):flatbuffers.Offset {
  Property.startProperty(builder);
  Property.addPropertyType(builder, propertyType);
  Property.addProperty(builder, propertyOffset);
  Property.addDescription(builder, descriptionOffset);
  Property.addReadonly(builder, readonly);
  return Property.endProperty(builder);
}

unpack(): PropertyT {
  return new PropertyT(
    this.propertyType(),
    (() => {
      const temp = unionToPropertyValue(this.propertyType(), this.property.bind(this));
      if(temp === null) { return null; }
      return temp.unpack()
  })(),
    this.description(),
    this.readonly()
  );
}


unpackTo(_o: PropertyT): void {
  _o.propertyType = this.propertyType();
  _o.property = (() => {
      const temp = unionToPropertyValue(this.propertyType(), this.property.bind(this));
      if(temp === null) { return null; }
      return temp.unpack()
  })();
  _o.description = this.description();
  _o.readonly = this.readonly();
}
}

export class PropertyT implements flatbuffers.IGeneratedObject {
constructor(
  public propertyType: PropertyValue = PropertyValue.NONE,
  public property: CustomSerializedPropertyT|NumberPropertyWithOptionsT|NumericRangePropertyT|PropertyAssetsValueT|PropertyBoolValueT|PropertyCatalogItemsValueT|PropertyColorValueT|PropertyMultiSelectionValueT|PropertyNumericValueT|PropertySceneInstancesValueT|PropertySelectionValueT|PropertyStringValueT|null = null,
  public description: string|Uint8Array|null = null,
  public readonly: boolean = false
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const property = builder.createObjectOffset(this.property);
  const description = (this.description !== null ? builder.createString(this.description!) : 0);

  return Property.createProperty(builder,
    this.propertyType,
    property,
    description,
    this.readonly
  );
}
}
