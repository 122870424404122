// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class Source implements flatbuffers.IUnpackableObject<SourceT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Source {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSource(bb:flatbuffers.ByteBuffer, obj?:Source):Source {
  return (obj || new Source()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSource(bb:flatbuffers.ByteBuffer, obj?:Source):Source {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Source()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

type():string|null
type(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
type(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

subject():string|null
subject(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
subject(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startSource(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addType(builder:flatbuffers.Builder, typeOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, typeOffset, 0);
}

static addSubject(builder:flatbuffers.Builder, subjectOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, subjectOffset, 0);
}

static endSource(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createSource(builder:flatbuffers.Builder, typeOffset:flatbuffers.Offset, subjectOffset:flatbuffers.Offset):flatbuffers.Offset {
  Source.startSource(builder);
  Source.addType(builder, typeOffset);
  Source.addSubject(builder, subjectOffset);
  return Source.endSource(builder);
}

unpack(): SourceT {
  return new SourceT(
    this.type(),
    this.subject()
  );
}


unpackTo(_o: SourceT): void {
  _o.type = this.type();
  _o.subject = this.subject();
}
}

export class SourceT implements flatbuffers.IGeneratedObject {
constructor(
  public type: string|Uint8Array|null = null,
  public subject: string|Uint8Array|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const type = (this.type !== null ? builder.createString(this.type!) : 0);
  const subject = (this.subject !== null ? builder.createString(this.subject!) : 0);

  return Source.createSource(builder,
    type,
    subject
  );
}
}
