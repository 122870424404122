<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 4.19995C2.28203 4.19995 1.7 4.78198 1.7 5.49995V14.5C1.7 15.2179 2.28203 15.8 3 15.8H12.5C13.218 15.8 13.8 15.2179 13.8 14.5V12.4948L16.2789 14.1474C17.1428 14.7233 18.3 14.104 18.3 13.0657V6.93421C18.3 5.8959 17.1428 5.2766 16.2789 5.85254L13.8 7.50514V5.49995C13.8 4.78198 13.218 4.19995 12.5 4.19995H3ZM13.8 9.4281V10.5718L16.7 12.5051V7.49476L13.8 9.4281ZM12.2 9.26597V5.79995H3.3V14.2H12.2V10.7339C12.2 10.7334 12.2 10.7329 12.2 10.7324V9.26754C12.2 9.26702 12.2 9.26649 12.2 9.26597Z"
        fill="currentColor"
    />
</svg>
