import type { Bim } from '../../Bim';
import { SceneInstancesProperty, CatalogItemsReferenceProperty } from '../../properties/ObjectRefProps';
import type { StringProperty } from '../../properties/PrimitiveProps';
import { NumberProperty, BooleanProperty, SelectorProperty } from '../../properties/PrimitiveProps';
import type { PropertyGroup } from '../../properties/PropertyGroup';
import type { ConfigShapeMigration} from '../ConfigsArchetypes';
import { StateType } from '../ConfigsArchetypes';
import { fromAssetToCatalogProperty, removeDescriptionMigration } from './MigrationUtils';


export const MvWiringConfigType = "mv-wiring-panel-config";

export const copperSizes = new Map<string, [
    [number, number, number],
    [number,number][]]>([
    ["#14 AWG", [[25,2.62,0.058], [[60,15], [75,20], [90,25], [105, 27]]]],
    ["#12 AWG", [[25,1.65, 0.054], [[60,20], [75,25], [90,30], [105, 32]]]],
    ["#10 AWG", [[25,1.04,0.05], [[60,30], [75,35], [90,40], [105, 59]]]],
    ["#8 AWG", [[25,0.654,0.052], [[60,40], [75,50], [90,55], [105, 86]]]],
    ["#6 AWG", [[25,0.41,0.051], [[60,55], [75,65], [90,75], [105, 93]]]],
    ["#4 AWG", [[25,0.259,0.048], [[60,70], [75,85], [90,95], [105, 120]]]],
    ["#3 AWG", [[25,0.205,0.046], [[60,85], [75,100], [90,115], [105, 143]]]],
    ["#2 AWG", [[25,0.162,0.045], [[60,95], [75,115], [90,130], [105, 165]]]],
    ["#1 AWG", [[25,0.129,0.046], [[60,110], [75,130], [90,145], [105, 190]]]],
    ["#1/0 AWG", [[25,0.102,0.044], [[60,125], [75,150], [90,170], [105, 215]]]],
    ["#2/0 AWG", [[25,0.0811,0.043], [[60,145], [75,175], [90,195], [105, 255]]]],
    ["#3/0 AWG", [[25,0.0642,0.042], [[60,165], [75,200], [90,225], [105, 290]]]],
    ["#4/0 AWG", [[25,0.0509,0.041], [[60,195], [75,230], [90,260], [105, 330]]]],
    ["250 kcmil", [[25,0.0431,0.041], [[60,215], [75,255], [90,290], [105, 365]]]],
    ["350 kcmil", [[25,0.0308,0.04], [[60,260], [75,310], [90,350], [105, 440]]]],
]);

export const alumSizes = new Map<string, [
    [number, number, number],
    [number,number][]]>([
    ["#10 AWG", [[25,1.7,0.05], [[60,25], [75,30], [90,35], [105, 35]]]],
    ["#8 AWG", [[25,1.07,0.052], [[60,35], [75,40], [90,45], [105, 45]]]],
    ["#6 AWG", [[25,0.674,0.051], [[60,40], [75,50], [90,55], [105, 72]]]],
    ["#4 AWG", [[25,0.424,0.048], [[60,55], [75,65], [90,75], [105, 94]]]],
    ["#1 AWG", [[25,0.211,0.046], [[60,85], [75,100], [90,115], [105, 150]]]],
    ["#1/0 AWG", [[25,0.168,0.044], [[60,100], [75,120], [90,135], [105, 170]]]],
    ["#2/0 AWG", [[25,0.133,0.043], [[60,115], [75,135], [90,150], [105, 200]]]],
    ["#3/0 AWG", [[25,0.105,0.042], [[60,130], [75,155], [90,175], [105, 225]]]],
    ["#4/0 AWG", [[25,0.0836,0.041], [[60,150], [75,180], [90,205], [105, 260]]]],
    ["250 kcmil", [[25,0.0708,0.041], [[60,170], [75,205], [90,230], [105, 290]]]],
    ["300 kcmil", [[25,0.059,0.041], [[60,195], [75,230], [90,260], [105, 320]]]],
    ["350 kcmil", [[25,0.0505,0.04], [[60,210], [75,250], [90,280], [105, 350]]]],
    ["400 kcmil", [[25,0.0442,0.04], [[60,225], [75,270], [90,305], [105, 380]]]],
    ["500 kcmil", [[25,0.0354,0.039], [[60,260], [75,310], [90,350], [105, 430]]]],
    ["600 kcmil", [[25,0.0295,0.039], [[60,285], [75,340], [90,385], [105, 475]]]],
    ["700 kcmil", [[25,0.0253,0.038], [[60,315], [75,375], [90,425], [105, 520]]]],
    ["750 kcmil", [[25,0.0236,0.038], [[60,320], [75,385], [90,435], [105, 540]]]],
    ["1000 kcmil", [[25,0.0177,0.037], [[60,375], [75,445], [90,500], [105, 640]]]],
    ["1250 kcmil", [[25,0.0142,0.036], [[60,405], [75,485], [90,545], [105, 740]]]],
    ["1500 kcmil", [[25,0.0118,0.035], [[60,435], [75,520], [90,585], [105, 840]]]],
]);

export const diametersIn = new Map<string, number>([
    ["#14 AWG", 0.0641],
    ["#12 AWG", 0.0808],
    ["#10 AWG", 0.1019],
    ["#8 AWG", 0.1285],
    ["#6 AWG", 0.162],
    ["#4 AWG", 0.2043],
    ["#3 AWG", 0.2294],
    ["#2 AWG", 0.2576],
    ["#1 AWG", 0.2893],
    ["#1/0 AWG", 0.3249],
    ["#2/0 AWG", 0.3648],
    ["#3/0 AWG", 0.4096],
    ["#4/0 AWG", 0.46],

    ["#14", 0.0641],
    ["#12", 0.0808],
    ["#10", 0.1019],
    ["#8", 0.1285],
    ["#6", 0.162],
    ["#4", 0.2043],
    ["#3", 0.2294],
    ["#2", 0.2576],
    ["#1", 0.2893],
    ["#1/0", 0.3249],
    ["#2/0", 0.3648],
    ["#3/0", 0.4096],
    ["#4/0", 0.46],

    ["250 kcmil", 0.5],
    ["300 kcmil", 0.548],
    ["350 kcmil", 0.592],
    ["400 kcmil", 0.632],
    ["500 kcmil", 0.707],
    ["600 kcmil", 0.775],
    ["700 kcmil", 0.837],
    ["750 kcmil", 0.866],
    ["1000 kcmil", 1],
    ["1250 kcmil", 1.118],
    ["1500 kcmil", 1.225],
    ["1750 kcmil", 1.323],
    ["2000 kcmil", 1.414],

    ["250MCM", 0.5],
    ["300MCM", 0.548],
    ["350MCM", 0.592],
    ["400MCM", 0.632],
    ["500MCM", 0.707],
    ["600MCM", 0.775],
    ["700MCM", 0.837],
    ["750MCM", 0.866],
    ["1000MCM", 1],
    ["1250MCM", 1.118],
    ["1500MCM", 1.225],
    ["1750MCM", 1.323],
    ["2000MCM", 1.414],
]);

type WireMaterial = 'copper' | 'aluminum';

export const sizesMap = new Map<WireMaterial, Map<string, [
    [number, number, number],
    [number,number][]]>>([
    ["copper", copperSizes],
    ["aluminum", alumSizes],
]);

export function calcCurrentAndResistivity(temperature: number, material: WireMaterial, size: string):[current:number, resistivity: number]{
    let pack = sizesMap.get(material)!;
    const [[temp, dc_resistivity, reactance], currents] = pack.get(size)!;
    const current = interpolate(temperature, currents);
    const coef_1 = material === "copper" ? 234.5 : 228.1;
    const dc_res = dc_resistivity * (coef_1 + temperature)/(coef_1 + temp);
    const resistivity = (dc_res*0.95 + reactance*Math.sin(Math.acos(0.95)));
    return [ current, resistivity];
}

function interpolate(temperature:number, values:[number,number][]):number{
    for (let i = 0; i < values.length; i++) {
        const [temp, value] = values[i];
        if (
            (temperature === temp)
            || (temperature < temp && i === 0)
            || (temperature > temp && values.length - 1 === i)
        ) {
            return value;
        }
        const [next_temp, next_value] = values[i+1];
        if(temperature < next_temp){
            const result = value + (next_value-value)/(next_temp-temp)*(temperature-temp);
            return result;
        }
    }
    throw new Error("unexpected error");
}

export interface MvWiringConfig extends PropertyGroup {
    layout: {
        boundaries: SceneInstancesProperty;
    };
    equipment: {
        sectionalize_cabinet: CatalogItemsReferenceProperty;
    };
    mv_wires: {
        temperature: NumberProperty,
        above_ground: BooleanProperty,
        wires : CatalogItemsReferenceProperty;
    };
    settings: {
        algorithm: SelectorProperty;
        max_voltage_drop: NumberProperty;
        max_power_mv_circuit: NumberProperty;
        bias: NumberProperty;
    };
}

export interface MvWireDefinition  extends PropertyGroup{
    id: NumberProperty;
    label: StringProperty;
    material: SelectorProperty,
    temperature: NumberProperty,
    size: SelectorProperty,
    current: NumberProperty,
    resistivity: NumberProperty
}

export function registerMvWiringConfig(bim: Bim) {

    bim.configs.archetypes.registerArchetype({
        type_identifier: MvWiringConfigType,
        properties: () => {
            const properties: MvWiringConfig = {
                equipment: {
                    sectionalize_cabinet: new CatalogItemsReferenceProperty({
                        value: [],
                        types: ["asset"],
                        assetsTypes: ["sectionalizing-cabinet"],
                        maxCount: 1,
                    }),
                },
                layout: {
                    boundaries: new SceneInstancesProperty({
                        value: [],
                        types: ["boundary"],
                    }),
                },
                mv_wires: {
                    temperature: NumberProperty.new({
                        value: 60,
                        unit: 'C',
                        range: [60, 105],
                    }),
                    above_ground: BooleanProperty.new({
                        value: false,
                    }),
                    wires: new CatalogItemsReferenceProperty({
                        value: [],
                        types: ["mv-wire-spec"],
                        assetsTypes: ["mv-wire-spec"],
                    }),
                },
                settings: {
                    algorithm: SelectorProperty.new({
                        value: "router",
                        options: ["router", "grid", "router-new"],
                    }),
                    max_voltage_drop: NumberProperty.new({
                        value: 1.5,
                        unit: "%",
                        range: [0, 100],
                    }),
                    max_power_mv_circuit: NumberProperty.new({
                        value: 26,
                        unit: "MV*A",
                        range: [0, Number.MAX_VALUE],
                    }),
                    bias: NumberProperty.new({
                        value: 5,
                        range: [0.1, 10],
                        step: 1,
                    })
                },
            };
            
            return properties;
        },
        connectionToType: "substation",
        stateType: StateType.CreateByConnection,
        shapeMigrations: migrations(),
    });
}

function migrations(): ConfigShapeMigration[] {
    return [
        {
            toVersion: 1,
            patch: (config) => {
                const props = config.get<MvWiringConfig>();
                props.equipment.sectionalize_cabinet =
                    fromAssetToCatalogProperty(
                        props.equipment.sectionalize_cabinet
                    );
                props.mv_wires.wires = fromAssetToCatalogProperty(
                    props.mv_wires.wires
                );
            },
            validation: {
                updatedProps: [
                    { path: ["equipment", "sectionalize_cabinet"] },
                    { path: ["mv_wires", "wires"] },
                ],
                deletedProps: [],
            },
        },
        {
            toVersion: 2,
            patch: (config) => {
                const props = config.get<MvWiringConfig>();
                props.settings.algorithm = SelectorProperty.new({
                    ...props.settings.algorithm,
                    options: ["router", "grid", "router-new"],
                });
                //@ts-ignore
                props.settings.wire_cost = NumberProperty.new({
                    value: 1,
                    range: [0, 1e9],
                });
                //@ts-ignore
                props.settings.trench_cost = NumberProperty.new({
                    value: 2,
                    range: [0, 1e9],
                });
            },
            validation: {
                updatedProps: [
                    { path: ["settings", "algorithm"] },
                    { path: ["settings", "wire_cost"] },
                    { path: ["settings", "trench_cost"] },
                ],
                deletedProps: [],
            },
        },
        {
            toVersion: 3,
            patch: (config) => {
                const props = config.get<MvWiringConfig>();
                //@ts-ignore
                props.settings.wire_cost = NumberProperty.new({
                    //@ts-ignore
                    ...props.settings.wire_cost,
                    //@ts-ignore
                    value: props.settings.wire_cost.value <= 0 ? 0.01 : props.settings.wire_cost.value,
                    range: [0.01, 1e9],
                });
                //@ts-ignore
                props.settings.trench_cost = NumberProperty.new({
                //@ts-ignore
                    ...props.settings.trench_cost,
                //@ts-ignore
                    value: props.settings.trench_cost.value <= 0 ? 0.01 : props.settings.trench_cost.value,
                    range: [0.01, 1e9],
                });
            },
            validation: {
                updatedProps: [
                    { path: ["settings", "wire_cost"] },
                    { path: ["settings", "trench_cost"] },
                ],
                deletedProps: [],
            },
        },
        {
            toVersion: 4,
            patch: (config) => {
                const props = config.get<MvWiringConfig>();
                //@ts-ignore
                delete props.settings.trench_cost;
                //@ts-ignore
                delete props.settings.wire_cost;
                props.settings.bias = NumberProperty.new({
                    value: 5,
                    range: [0.1, 10],
                    step: 1,
                })
            },
            validation: {
                updatedProps: [
                    { path: ["settings", "bias"] },
                ],
                deletedProps: [
                    { path: ["settings", "wire_cost"] },
                    { path: ["settings", "trench_cost"] },
                ],
            },
        },
        {
            toVersion: 5,
            patch: (config) => {
                removeDescriptionMigration(config.properties)
            },
            validation: {
                updatedProps: [],
                deletedProps: [],
            },
        },
    ];
}
