<script lang="ts">
import type { CatalogItemIdType } from "bim-ts";
import { PUI_PropertyNodeMultiSelector, type MultiSelectorValue } from "ui-bindings";
import PropertyView from "../../PropertyView.svelte";
import type { FrameSeriesDescription, FrameVariation } from "./FrameModuleMatcher";


export let seriesDescription: FrameSeriesDescription;
export let resultingTrackerFrameIds: CatalogItemIdType[];
export let initialFrameVariation: FrameVariation | undefined;


let allModulesInStringOptions: MultiSelectorValue[] = [];
let allModulesTotalOptions: number[] = [];


// update all options available
$: {
    const array: MultiSelectorValue[] = []
    for (const item of Array.from(seriesDescription.modulesInStringVariations).sort((l,r)=>l-r)) {
        array.push({ value: item, label: item.toString() })
    }
    allModulesInStringOptions = array.slice();

    allModulesTotalOptions = Array.from(seriesDescription.modulesTotalVariations).sort((l,r)=>l-r)

    // reset invalid values
    if (
        typeof modulesInString?.value === 'number' &&
        !seriesDescription.modulesInStringVariations.has(modulesInString.value)
    ) {
        modulesInString = undefined;
    }

    // reset invalid values
    if (
        typeof modulesTotal?.value === 'number' &&
        !seriesDescription.modulesTotalVariations.has(modulesTotal.value)
    ) {
        modulesTotal = undefined;
    }
}


// manage modules in string
let modulesInString: MultiSelectorValue | undefined = undefined;
let modulesInStringOptions: MultiSelectorValue[] = [];
function updateModulesInString(newValue: MultiSelectorValue[]) {
    modulesInString = newValue.at(0);
}
$: {
    const tmp = initialFrameVariation?.modulesInString
    modulesInString = typeof tmp === 'number' ? { value: tmp, label: tmp.toString() } : undefined;
}
$: modulesInStringNode = new PUI_PropertyNodeMultiSelector({
    name: 'Modules in string',
    value: modulesInString ? [modulesInString] : [],
    maxSelect: 1,
    onChange: updateModulesInString,
    options: modulesInStringOptions,
});

// manage modules total
let modulesTotal: MultiSelectorValue | undefined = undefined;
let modulesTotalOptions: number[] = [];
function updateModulesTotal(newValue: MultiSelectorValue[]) {
    modulesTotal = newValue.at(0);
}
$: {
    const tmp = initialFrameVariation?.modulesTotal;
    modulesTotal = typeof tmp === 'number' ? { value: tmp, label: tmp.toString() } : undefined;
}
let modulesTotalOptionsWithWidth: MultiSelectorValue[] = []
$: {
    const options: MultiSelectorValue[] = [];
    for (const total of modulesTotalOptions) {
        const option: MultiSelectorValue = {
            label: total.toString(),
            value: total,
        }
        options.push(option);
    }
    modulesTotalOptionsWithWidth = options;
}

$: modulesTotalNode = new PUI_PropertyNodeMultiSelector({
    name: 'Modules total',
    value: modulesTotal ? [modulesTotal] : [],
    maxSelect: 1,
    onChange: updateModulesTotal,
    options: modulesTotalOptionsWithWidth,
});


// update modules total options if modules in string was updated
$: if (typeof modulesInString?.value === 'number') {
    modulesTotalOptions = Array.from(
        seriesDescription.modulesTotalPerModulesInString.get(modulesInString.value) ?? []
    ).sort((l, r) => l - r);
} else {
    // allow any variant for modules total
    modulesTotalOptions = allModulesTotalOptions.sort((l, r) => l - r);
}

// update modules in string options if modules total was updated
$: {
    if (typeof modulesTotal?.value === 'number') {
        modulesInStringOptions = Array.from(
            seriesDescription.modulesInStringPerModulesTotal.get(modulesTotal.value) ?? []
        )
            .sort((l, r) => l - r)
            .map(x => ({ value: x, label: x.toString() }));
    }
    
    if (!modulesTotal) {
        // allow any variant for modules total
        modulesInStringOptions = allModulesInStringOptions.slice();
    }
}

// calculate which tracker frames matches
$: calculateResultingFrames: {
    resultingTrackerFrameIds = []
    if (
        typeof modulesInString?.value !== 'number' ||
        typeof modulesTotal?.value !== 'number'
    ) {
        break calculateResultingFrames;
    }
    for (const id of seriesDescription.frames) {
        const modulesInStringMatches = seriesDescription.groupedByModulesInString
            .get(modulesInString.value)?.has(id) ?? false;
        const modulesTotalMatches = seriesDescription.groupedByModulesTotal
            .get(modulesTotal.value)?.has(id) ?? false;
        if (!modulesInStringMatches || !modulesTotalMatches) {
            continue;
        }
        resultingTrackerFrameIds.push(id);
    }
}

</script>


<PropertyView offset={0} property={modulesInStringNode} />

<PropertyView offset={0} property={modulesTotalNode} />

<style lang="scss">
</style>
