import type { UnitsMapper, TMY_ColumnDates} from 'bim-ts';
import type { TMY_Props } from 'bim-ts';
import type { ResultAsync } from 'engine-utils-ts';
import { type LazyVersioned, LazyDerived, Success } from 'engine-utils-ts';
import { PUI_GroupNode, PUI_PropertyNodeString } from 'ui-bindings';
import { type TmySourceDataColumn, type TmyDataVizType, generateTmyDataVizs, TmyDataTableUi } from '../tmy-data-viz-utils/TmyDataVizGenerators';
import type { SiteMeteoVizParams } from './SiteMeteoDataVizSettings';



export function meteoDataVizGenerator(
    vizSettings: LazyVersioned<SiteMeteoVizParams>,
    siteMeteoInfo: LazyVersioned<ResultAsync<TMY_Props>>,
    unitsMapper: UnitsMapper,
    heightPerChart: number,
): LazyVersioned<TmyDataVizType[]> {

    return LazyDerived.new2(
        'meteoDataVizGenerator',
        [unitsMapper],
        [vizSettings, siteMeteoInfo],
        ([settings, siteMeteoInfo]): TmyDataVizType[] => {

            const siteMeteoProps = siteMeteoInfo instanceof Success ? siteMeteoInfo.value : null;

            const result: TmyDataVizType[] = [];

            if (settings.show_meteo_data_header && siteMeteoProps) {
                const groupNodes = new Array<PUI_GroupNode>();
                for (let i = 0; i < siteMeteoProps.prefixMetadata.rows.length; ++i) {
                    const row = siteMeteoProps.prefixMetadata.rows[i];
                    const groupNode = new PUI_GroupNode({name: i.toString(), sortChildren: false});
                    for (let j = 0; j < row.length; ++j) {
                        const cellValue = row[j];
                        groupNode.addMaybeChild(new PUI_PropertyNodeString({
                            name: j.toString(),
                            value: cellValue + '',
                            onChange: () => {},
                        }));
                    }
                    groupNodes.push(groupNode);
                }
                result.push(new TmyDataTableUi(groupNodes));
            }

            const dates: TMY_ColumnDates|null = siteMeteoProps?.datesColumns[0] ?? null;

            const dataColumns = new Map<string, TmySourceDataColumn>();

            if (siteMeteoProps) {
                for (const column of siteMeteoProps.dataColumns) {
                    const header = column.header.toHeaderString();
                    if (settings.data_selector.includes(header)) {
                        dataColumns.set(
                            header,    
                            {
                                header: header,
                                values: column,
                                unit: column.header.unit,
                            }
                        );
                    }
                }
            }

            if (dataColumns.size > 0) {
                const vizs = generateTmyDataVizs(
                    settings.visualization_type,
                    {
                        dataColumns: Array.from(dataColumns.values()),
                        dates,
                    },
                    unitsMapper,
                    heightPerChart,
                );
                result.push(...vizs);
            }
            return result;
        }
    );
}
