import { ResolvedPromise } from '.';
import { DefaultMap } from "./DefaultMap";
import { LogLevel } from "./ScopedLogger";



const deferredTraces = new DefaultMap<string, any[]>(() => []);
const deferredWarns = new DefaultMap<string, any[]>(() => []);
const deferredErrors = new DefaultMap<string, any[]>(() => []);


export class LegacyLogger {

	static logLevel: LogLevel = LogLevel.Info;
	
	static trace (message:string, ...objects:any[]) {
		if (LegacyLogger.logLevel <= LogLevel.Debug) {
			console.log(message, ...objects);
		}
	}

	static log(message: string, ...objects: any[]) {
		if (LegacyLogger.logLevel <= LogLevel.Info) {
			console.log(message, ...objects);
		}
	}

	static warn (message:string, ...objects:any[]) {
		if (LegacyLogger.logLevel <= LogLevel.Warn) {
			console.warn(message, ...objects);
		}
	}

	static error (message:string, ...err:any[]) {
		if (LegacyLogger.logLevel <= LogLevel.Error) {
			console.error(message, ...err);
		}
	}

	static debugAssert(condition: boolean, message: string, ...objects: any[]) {
		if (!condition) {
			console.error(condition, message, ...objects);
		}
	}
	
	static assert(condition:boolean, message:string, ...objects:any[]) {
		console.assert(condition, message, ...objects);
	}

	static deferredTrace(message: string, obj: any) {
		if (LegacyLogger.logLevel <= LogLevel.Debug) {
			deferredTraces.getOrCreate(message).push(obj);
			scheduleDeferredLog();
		}
	}

	static deferredWarn(message:string, obj:any) {
		if (LegacyLogger.logLevel <= LogLevel.Warn) {
			deferredWarns.getOrCreate(message).push(obj);
			scheduleDeferredLog();
		}
	}

	static deferredError(message:string, obj:any) {
		if (LegacyLogger.logLevel <= LogLevel.Warn) {
			deferredErrors.getOrCreate(message).push(obj);
			scheduleDeferredLog();
		}
	}

	static logDeferred() {
		for (const [message, objs] of deferredTraces) {
			console.log(message, objs);
		}
		deferredTraces.clear();

		for (const [message, objs] of deferredWarns) {
			console.warn(message, objs);
		}
		deferredWarns.clear();

		for (const [message, objs] of deferredErrors) {
			console.error(message, objs);
		}
		deferredErrors.clear();
	}
};

let _isDeferredLogScheduled = false;
function scheduleDeferredLog() {
	if (_isDeferredLogScheduled) {
		return;
	}
	_isDeferredLogScheduled = true;
	ResolvedPromise.then(LegacyLogger.logDeferred);
}