import type { Mesh } from '../3rdParty/three';
import { GizmoIntersection } from './GizmoIntersection';
import type { SizeLabelMesh } from '../SizeLabelMesh';
import type { Vector3, BoxCorner, Aabb, Plane } from 'math-ts';

export class ClipboxControlInters extends GizmoIntersection {
	readonly mutatedPoint : Vector3;
	readonly mesh: Mesh;
	readonly plane: Plane;
	readonly corner: BoxCorner | null;
	readonly axis: Vector3 | null;
	readonly initClipbox: Aabb;
	readonly sizeMesh: SizeLabelMesh | null;

	constructor(
		point: Vector3,
		distance: number,
		mesh: Mesh,
		plane: Plane,
		corner: BoxCorner | null,
		axis: Vector3 | null,
		initClipbox: Aabb
	) {
		super(point, distance);
		this.mutatedPoint = point.clone();
		this.mesh = mesh;
		this.plane = plane.clone();
		this.corner = corner;
		this.axis = (axis && axis.clone()) || null;
		this.initClipbox = initClipbox.clone();
		this.sizeMesh = null;
	}

	supportsDragging(): boolean {
		return true;
	}
}

