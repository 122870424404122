import './kr_pbr';

import { KrMath, Vector3, Vector4 } from 'math-ts';

import type { ShaderBase } from './ShaderBase';
import type { MaterialPhysicalParams} from '../materials/MaterialsFactory';
import { DiffuseTexturePostfix, NormalTexturePostfix } from '../materials/MaterialsFactory';
import { LessEqualDepth } from '../3rdParty/three';
import Utils from '../utils/Utils';
import type { MaterialDescr, MaterialOptions } from '../materials/MaterialDescr';
import { ShaderFlags } from '../shaders/ShaderFlags';

export const StandardShader: ShaderBase = Object.freeze({
	
	name: 'standard',
	
	uniforms:
	{
		opacity: { value: 1.0 },
		map: { value: null },
		normalMap: { value: null },
		roughMetalTiling: { value: new Vector3(0.5, 0.5, 1.0) },
		envMap: { value: null },
		baseColor: { value: new Vector4(1, 1, 1, 0) },
		isOrthographic: { value: true },
	}
	,

	defines: {
		'NEEDS_NORMALS': true
	},

	vertexShader:
	`
	#define PHYSICAL
	#define STANDARD

	#include <kr_std_attributes>
	#include <kr_std_uniforms>
	#include <kr_std_vars>

	#include <common>
	uniform vec3 roughMetalTiling;
	
	void main() {
		#include <kr_std_vars_calc>
	}
	`,

	fragmentShader:
	
	`
	#define PHYSICAL
	#define STANDARD

	#include <common>
	#include <common_math>
	#include <packing>

	#include <color_pars_fragment>
	#include <map_pars_fragment>
	
	#include <kr_std_uniforms>
	#include <kr_std_vars>

	#include <kr_normalmap_pars_fragment>

	#include <kr_pbr_pars_frag>
	#include <kr_pbr_frag>

	uniform vec3 roughMetalTiling;	
	uniform float opacity;
	uniform vec4 baseColor;
	// uniform vec3 sunDirection;

	#include <color_clipping_uniforms>
	#include <normals_packing>

	#if defined(MRT_NORMALS)
		layout(location = 1) out lowp vec4 fragData1;
	#endif


	void main() {
		#include <kr_std_pixel>

		float roughnessFactor = roughMetalTiling.x;
		float metalnessFactor = roughMetalTiling.y;

		// // //Geometric Specular Aliasing fix/hack http://media.steampowered.com/apps/valve/2015/Alex_Vlachos_Advanced_VR_Rendering_GDC2015.pdf
		vec3 vNormalWsDdx = dFdx( normal.xyz );
		vec3 vNormalWsDdy = dFdy( normal.xyz );
		float flGeometricRoughnessFactor = pow( saturate( max( dot( vNormalWsDdx.xyz, vNormalWsDdx.xyz ), dot( vNormalWsDdy.xyz, vNormalWsDdy.xyz ) ) ), 0.45 );
		
		// // Ensure we don’t double-count roughness if normal map encodes geometric roughness
		roughnessFactor = max( roughnessFactor, flGeometricRoughnessFactor ); 

		vec4 diffuseColor = vec4(baseColor.r, baseColor.g, baseColor.b, opacity);

		#ifdef USE_MAP
			vec4 texelColor = texture2D( map, vUv );
			// gl_FragColor.rgb = texelColor.rgb;
			// gl_FragColor.a = 1.0;
			// return;
			texelColor.rgb = mix(texelColor.rgb, baseColor.rgb, texelColor.a * baseColor.a);
			diffuseColor.rgb = texelColor.rgb;
		#endif

		#ifdef COLOR_CLIPPING
			vec3 ab = vWorldPosition.xyz + (colorClippingPlane.xyz * colorClippingPlane.w);
			float sign = clamp(sign(dot( ab.xyz, colorClippingPlane.xyz)) * -1.0, 0.0, 1.0);
			vec4 color_to_clip = mix(colorBelow, colorAbove, sign);

			diffuseColor.rgb = mix(diffuseColor.rgb, color_to_clip.xyz, color_to_clip.w);
		#endif

		// fix gamma

		GeometricContext geometry;
		// geometry.position = - vViewPosition;
		geometry.normal = normal;
		geometry.viewDir = ( isOrthographic ) ? vec3( 0, 0, 1 ) : normalize( vViewPosition );
		#ifdef CLEARCOAT
			geometry.clearcoatNormal = clearcoatNormal;
		#endif

		PhysicalMaterial material;
		material.diffuseColor = diffuseColor.rgb * ( 1.0 - metalnessFactor );
		vec3 dxy = max( abs( dFdx( geometry.normal ) ), abs( dFdy( geometry.normal ) ) );
		float geometryRoughness = max( max( dxy.x, dxy.y ), dxy.z );
		material.specularRoughness = max( roughnessFactor, 0.0525 );
		material.specularRoughness += geometryRoughness;
		material.specularRoughness = min( material.specularRoughness, 1.0 );
		
		material.specularColor = mix( vec3( DEFAULT_SPECULAR_COEFFICIENT ), diffuseColor.rgb, metalnessFactor );

		#ifdef CLEARCOAT
			material.clearcoat = clearcoat;
			material.clearcoatRoughness = clearcoatRoughness;
			#ifdef USE_CLEARCOATMAP
				material.clearcoat *= texture2D( clearcoatMap, vUv ).x;
			#endif
			#ifdef USE_CLEARCOAT_ROUGHNESSMAP
				material.clearcoatRoughness *= texture2D( clearcoatRoughnessMap, vUv ).y;
			#endif
			material.clearcoat = saturate( material.clearcoat );
			material.clearcoatRoughness = max( material.clearcoatRoughness, 0.0525 );
			material.clearcoatRoughness += geometryRoughness;
			material.clearcoatRoughness = min( material.clearcoatRoughness, 1.0 );
		#endif

		vec3 pbr = kr_pbr(material, geometry);

		vec4 color = vec4( pbr.xyz, opacity );

		color = linearToOutputTexel( color );

		gl_FragColor = color;

		gl_FragColor.rgb = mix(gl_FragColor.rgb, colorTint.xyz, colorTint.w);

		#ifdef MRT_NORMALS 
			fragData1 = vec4( encode_normal( normal ),  1.0, 1.0 );
		#endif

	}
	`,
});


export const GammaFactor = 2;

export function newStandardMaterialDescr(matParams: MaterialPhysicalParams): MaterialDescr {

	const options: MaterialOptions = {};

	options.uniforms = {
		roughMetalTiling: { value: Vector3.allocate(matParams.roughness, matParams.metalness, matParams.tiling) },
		map: { value: null },
		normalMap: { value: null },
		baseColor: { value: Vector4.allocate(1, 1, 1, 0) },
		opacity: { value: matParams.opacity },
	}
	options.envMap = true;

	if (matParams.color) {
		const color = matParams.color.clone();
		color.copyGammaToLinear(color, GammaFactor);

		Utils.colorToXYZ(color, options.uniforms.baseColor.value);

		let baseColorPower = 1.0;
		{
			const colorizeVector_t = Utils.colorToXYZ(matParams.color, Vector3.zero());
			const baseVector_t = Utils.colorToXYZ(matParams.defaultColor, Vector3.zero());
			const componentsDiff_t = Vector3.subVectors(colorizeVector_t, baseVector_t);
			let distance = componentsDiff_t.length() / Math.max(colorizeVector_t.length(), baseVector_t.length());
			let colorSimiliary = colorizeVector_t.normalize().distanceTo(baseVector_t.normalize());
			baseColorPower = KrMath.clamp(Math.sqrt(Math.sqrt(distance + colorSimiliary)), 0.3, 1.0);
		}

		options.uniforms.baseColor.value.w = baseColorPower;

		if (matParams.texName) {
			options.texturesPaths = {
				map: matParams.texName + DiffuseTexturePostfix,
				normalMap: matParams.texName + NormalTexturePostfix,
			}
		}
	}

	options.defines = {'NEEDS_VIEW_SPACE': true};
	
	if (matParams.isTransparent()) {
		options.params = {
			depthWrite: false,
			transparent: true,
			opacity: matParams.opacity,
		}
		options.uniforms.opacity.value = matParams.opacity;
		// mat.blendSrc = OneFactor;
		// mat.blendSrc = OneMinusSrcAlphaFactor;
		// mat.blending = CustomBlending;
		// const color = mat.uniforms.baseColor.value as Color;
		// mat.uniforms.baseColor.value.multiplyScalar(mat.uniforms.opacity.value);
	} else {
		options.params = {
			depthWrite: true,
			transparent: false,
			depthFunc: LessEqualDepth,
		}
		options.flags = ShaderFlags.MRT_NORMALS;
	}

	return {
		material: StandardShader,
		options,
	}
}

