import type { IconName } from "../icon";

export class BaseButton {
  constructor(
    public onClick?: (e: MouseEvent) => void,
    public disabled?: boolean
  ) {}
}

export class IconButton extends BaseButton {
  constructor(
    public name: IconName,
    ...baseArgs: ConstructorParameters<typeof BaseButton>
  ) {
    
    super(...baseArgs)
  }
}

export enum ButtonType {
  Text,
  Outlined,
  Contained,
  Unelevated,
  Secondary
}

export class Button extends BaseButton {
  constructor(
    public label: string,
    public type?: ButtonType,
    public onClick?: (e: MouseEvent) => void,
    public disabled?: boolean,
    public icon?: IconName,
    public iconAfterLabel?: boolean,
    public hint?: string,
  ) {
    super(onClick, disabled);
  }
}

export type ButtonVariants = Button | IconButton
