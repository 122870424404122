import { compareHandles } from "../memory/Handle";
import type { ESOHandle } from "./ESOsCollection";

export enum InObjIdType {
	ObjSelf,
	ObjSelfAux,
	EditPoint,
	EditEdge,
}


export class InObjLocalId {
	
	private constructor(
		public readonly ty: InObjIdType,
		public readonly localId: number,		
	) {
	}

	public static new(ty: InObjIdType, id: number) {
		const IdsMaxRange = 0xFFFF_FFFF_FFFF;
		if (id < 0 || id > IdsMaxRange) {
			throw new Error(`invalid local id ${id}, out of range`);
		}
		const uniqueHash = ty * IdsMaxRange + id;
		const createdAlready = _localIds.get(uniqueHash);
		if (createdAlready) {
			if (createdAlready.localId !== id || createdAlready.ty !== ty) {
				throw new Error(`invalid local ids caching ${ty} ${id} :: ${createdAlready.ty} ${createdAlready.localId}`);
			}
			return createdAlready;
		} else {
			const res = new InObjLocalId(ty, id);
			_localIds.set(uniqueHash, res);
			return res;
		}
	}

	comapreTo(other: InObjLocalId): number {
		if (this.ty !== other.ty) {
			return this.ty - other.ty;
		}
		return this.localId - other.localId;
	}
}
const _localIds = new Map<number, InObjLocalId>();

export const SelfIdZero = InObjLocalId.new(InObjIdType.ObjSelf, 0);
export const SelfIdAuxZero = InObjLocalId.new(InObjIdType.ObjSelfAux, 0);

export class InObjFullId {
	private constructor(
		public readonly objHandle: ESOHandle,
		public readonly inObjId: InObjLocalId,
	) {
	}

	public static new(
		objHandle: ESOHandle,
		inOBjId: InObjLocalId,
	) {
		const asStr = `${objHandle | 0}:${inOBjId.ty | 0}:${inOBjId.localId | 0}`;
		let fullId = _fullIdsStringCache.get(asStr);
		if (fullId === undefined) {
			fullId = new InObjFullId(objHandle, inOBjId);
			_fullIdsStringCache.set(asStr, fullId);
		}
		return fullId;
	}

	compareTo(other: InObjFullId): number {
		const handlesDiff = compareHandles(this.objHandle, other.objHandle);
		if (handlesDiff !== 0) {
			return handlesDiff;
		}
		return this.inObjId.comapreTo(other.inObjId);
	}
}

const _fullIdsStringCache = new Map<string, InObjFullId>();
