import type { LazyVersioned } from 'engine-utils-ts';
import { LazyBasic, LazyDerived } from 'engine-utils-ts';
import { Vector3 } from 'math-ts';

import {
    CircleBufferGeometry, Mesh, RingBufferGeometry
} from '../3rdParty/three';
import {
    PersonHeight, TeleportGroundGizmoDiameter, TeleportNotGroundGizmoDiameter, TeleportRingColor
} from '../Constants';
import type { MovementControls } from '../controls/MovementControls';
import { CameraProjectionMode } from '../EngineConsts';
import type { SceneRaycaster } from '../scene/SceneRaycaster';
import { newBasicMaterial } from '../shaders/BasicShader';
import type { FrustumExt } from '../structs/FrustumExt';
import { GizmoBase } from './GizmoBase';

const verticalNormalCutoffZ = 0.8;

interface TeleportGizmoState {
	enabled: boolean,
}

interface TeleportPosition {
	point: Vector3,
	normal: Vector3,
}
export default class TeleportGizmo extends GizmoBase<any> {

	state: LazyBasic<TeleportGizmoState>;

	teleportPosition: LazyVersioned<TeleportPosition | null>;

	constructor(raycaster: SceneRaycaster) {
		super();

		this.state = new LazyBasic<TeleportGizmoState>('teleport_gizmo', {enabled: false});

		this.teleportPosition = LazyDerived.new2('teleport_pos', [], [this.state, raycaster.sceneIntersection], ([s, int]) => {
			if (!s.enabled) {
				return null;
			}
			const closest = int.closest();
			if (!closest) {
				return null;
			}
			return { point: closest.point, normal: closest.normal };
		});

		this.visible = false;

		const mat = newBasicMaterial(TeleportRingColor, 0.8);
		mat.transparent = true;
		mat.depthWrite = false;
		mat.depthTest = true;
		// mat.depthFunc = GreaterDepth;

		const ringGeometry = new RingBufferGeometry(TeleportGroundGizmoDiameter / 3.3, TeleportGroundGizmoDiameter / 2, 48, 1, 0, Math.PI * 2);
		const ringMesh = new Mesh(ringGeometry, mat);

		const circleBufferGeometry = new CircleBufferGeometry(TeleportNotGroundGizmoDiameter / 2, 48, 0, Math.PI * 2);
		const circleMesh = new Mesh(circleBufferGeometry, mat);

		this.addEventListener('dispose', () => { mat.dispose(); ringGeometry.dispose(); circleBufferGeometry.dispose();});

		this.add(ringMesh);
		this.add(circleMesh);
	}

	version(): number {
		return this.teleportPosition.poll() != null ? this.teleportPosition.version() : 0;
	}

	teleport(controls: MovementControls) {
		const pos = this.teleportPosition.poll();
		if (!pos) {
			return false;
		}
		const target = pos.point.clone();
		const normal = pos.normal.clone();
		if (normal.z > verticalNormalCutoffZ) {
			target.z += PersonHeight;
		} else {
			target.add(normal);
		}
		const targetSph = controls.spherical.clone();
		targetSph.radius = controls.minDistance;
		const size = controls.getOrthoSize();
		if (controls.getProjType() === CameraProjectionMode.Orthographic) {
			// controls.bounceBackToOrthoNextTime();
			controls.setProjType(CameraProjectionMode.Perspective);
		}
		controls.setTarget(target, targetSph, size, 1);

		return true;
	}

	toggleVisibility(visible: boolean) {
		if (this.state.poll().enabled === visible) {
			return;
		}
		this.state.getForMutation().enabled = visible;
		this.visible = visible;
	}

	update(fr: FrustumExt) {
		const pos = this.teleportPosition.poll();

		const ring = this.children[0];
		const circle = this.children[1];
		if (!pos) {
			ring.visible = false;
			circle.visible = false;
			return;
		}
		let normal = pos.normal.clone();
		const camForard = Vector3.subVectors(fr.camera.position, pos.point).normalize();
		if (camForard.dot(normal) < 0) {
			normal = normal;
			normal.multiplyScalar(-1);
		}

		let obj;

		if (normal.z > verticalNormalCutoffZ) {
			obj = ring;
			ring.visible = true;
			circle.visible = false;
		} else {
			ring.visible = false;
			circle.visible = true;
			obj = circle;
		}
		obj.position.addVectors(pos.point, normal.clone().multiplyScalar(0.01));
		obj.lookAt(Vector3.addVectors(normal, obj.position));
		obj.updateMatrixWorld(true);
	}

	setContrastColor() {}

	dispose() {
		this.dispatchEvent({ type: 'dispose' });
	}
}
