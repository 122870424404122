import { WGSCoord } from "bim-ts";
import { IterUtils } from "engine-utils-ts";
import { Aabb2, Vector2, Vector3 } from "math-ts";
import { WGSConverter } from "../WGSConverter";

export interface KmlStyle{
    id:string;
    lineColor:string;
}

export interface KmlElement{
    id:string;
    parentId:string;
    name:string;
    type:string;
}

export interface KmlFolder extends KmlElement {
    children:KmlElement[];
    type:'folder';
}

export interface KmlPolygon extends KmlElement{
    styleId?:string;
    coordinates:WGSCoord[];
    type:'polygon';
}

export interface KmlPolyline extends KmlElement{
    styleId?:string;
    coordinates:WGSCoord[];
    type:'polyline';
}

export interface KmlParseResult {
    name: string | null;
    styles: { [key: string]: KmlStyle };
    elements: KmlElement[];
}


function getCoordinates(element:KmlElement): WGSCoord[]{ 
    const coordinates: WGSCoord[] = [];

    switch (element.type){
        case 'polyline':
            const kmlPolyline = element as KmlPolyline;
            IterUtils.extendArray(coordinates, kmlPolyline.coordinates);
            break;
        case 'polygon':
            const kmlPolygon = element as KmlPolygon;
            IterUtils.extendArray(coordinates, kmlPolygon.coordinates);
            break;
        case 'folder':
            const kmlFolder = element as KmlFolder;
            for(const el of kmlFolder.children){
                IterUtils.extendArray(coordinates, getCoordinates(el));
            }
            break;
        default:
            break;
    }

    return coordinates;
}

function getGeometies(element:KmlElement):{polygons:KmlPolygon[], polylines:KmlPolyline[]}{ 
    const polygons:KmlPolygon[] = [];
    const polylines:KmlPolyline[] = [];

    switch (element.type){
        case 'polyline':
            const kmlPolyline = element as KmlPolyline;
            polylines.push(kmlPolyline);
            break;
        case 'polygon':
            const kmlPolygon = element as KmlPolygon;
            polygons.push(kmlPolygon);
            break;
        case 'folder':
            const kmlFolder = element as KmlFolder;
            for(const el of kmlFolder.children){
                const geometry = getGeometies(el);
                IterUtils.extendArray(polygons, geometry.polygons);
                IterUtils.extendArray(polylines, geometry.polylines);
            }     
            break;
        default:
            break;
    }

    return {polygons:polygons, polylines:polylines};
}

export function getAllGeometry(parseResult:KmlParseResult): {polygons:KmlPolygon[], polylines:KmlPolyline[]}{
    const geometries :{polygons:KmlPolygon[], polylines:KmlPolyline[]} = {polygons:[], polylines:[]};
    parseResult.elements.map(el =>{ 
        const geom = getGeometies(el);
        IterUtils.extendArray(geometries.polygons, geom.polygons);
        IterUtils.extendArray(geometries.polylines, geom.polylines);
    });
    return geometries;
}

export function getKmlOrigin(kmlParseResult: KmlParseResult): WGSCoord | null {

    const coordinates = kmlParseResult.elements.flatMap(el => getCoordinates(el));
    if (!coordinates || coordinates.length === 0){
        return null;
    }

    const geoBbox = Aabb2.empty().setFromPoints(
        coordinates.map(c => new Vector2(c.longitude, c.latitude))
    );

    const origin = new WGSCoord(geoBbox.centerY(), geoBbox.centerX());

    return origin;
}

export function projectCoordinates(projectionOrigin: string, coordinates: WGSCoord[]) {
    //convert coordinate into flat 3d vectors
    const coords = coordinates.map((c) => {
        const projection = WGSConverter.projectWgsToFlatMap(c, projectionOrigin);
        const alt = c.altitude ?? 0;
        const coord = new Vector3(projection.x, projection.y, alt);
        return coord;
    });

    return coords;
}