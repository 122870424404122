import type { MathSolversApi } from "bim-ts";
import type { Point } from "../farm-layout/LayoutSolversTypes";


type AutoblockingSolverType = "basic" | "alternative";

interface AutoBlockingSampleBasic {
    value: number;
    min_ilr: number;
    max_ilr: number;
}

interface AutoBlockingSampleAlternative {
    min_value: number;
    max_value: number;
}

interface AutoBlockingRequest<Sample extends AutoBlockingSampleBasic | AutoBlockingSampleAlternative>{
    contours: Point[][],
    segments: [Point, Point][];
    trackers: {
        fst: Point;
        snd: Point;
        value: number;
    }[],
    samples: Sample[],
    radius: number;
}

export interface AutoblockingSolverInput extends AutoBlockingRequest<AutoBlockingSampleBasic> {  
    solverType: AutoblockingSolverType;
}

interface AutoBlockingResponse {
    blocks: number[][];
}

interface AutoblockingSolver { 
    solve(api: MathSolversApi, input: AutoblockingSolverInput): Promise<AutoBlockingResponse>;
}

export class AutoblockingSolvers {
    private readonly mathSolverApi: MathSolversApi;
    private readonly solvers: Record<AutoblockingSolverType, AutoblockingSolver>;

    constructor(mathSolverApi: MathSolversApi){
        this.mathSolverApi = mathSolverApi;
        this.solvers = {
            "basic": new AutoblockingSolverBasic(),
            "alternative": new AutoblockingSolverAlternative(),
        };      
    }

    solve(input: AutoblockingSolverInput): Promise<AutoBlockingResponse> {
        const solver = this.solvers[input.solverType];
        if (!solver) {
            throw new Error(`Unknown solver type: ${input.solverType}`);
        }
        return solver.solve(this.mathSolverApi, input);
    }
}

class AutoblockingSolverBasic implements AutoblockingSolver {
    solve(api: MathSolversApi, input: AutoblockingSolverInput): Promise<AutoBlockingResponse> {
        const request: AutoBlockingRequest<AutoBlockingSampleBasic> = { 
            contours: input.contours,
            segments: input.segments,
            trackers: input.trackers,
            samples: input.samples,
            radius: input.radius,
        };
        const responsePromise = api.callSolver<AutoBlockingRequest<AutoBlockingSampleBasic>, AutoBlockingResponse>({
            solverName: 'autoblocking',
            solverType: 'multi',
            request,
        });
        return responsePromise;
    }
}

class AutoblockingSolverAlternative implements AutoblockingSolver {
    solve(api: MathSolversApi, input: AutoblockingSolverInput): Promise<AutoBlockingResponse> {
        const request: AutoBlockingRequest<AutoBlockingSampleAlternative> = { 
            contours: input.contours,
            segments: input.segments,
            trackers: input.trackers,
            samples: input.samples.map(s => ({ min_value: s.min_ilr * s.value, max_value: s.max_ilr * s.value})),
            radius: input.radius,
        };
        const responsePromise = api.callSolver<AutoBlockingRequest<AutoBlockingSampleAlternative>, AutoBlockingResponse>({
            solverName: 'autoblocking_rectangular',
            solverType: 'multi',
            request,
        });

        return responsePromise;
    }
}