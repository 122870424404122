<script lang="ts">
    import {
        MenuItemAction,
        MenuItemSetting,
        MenuItemGroup,
        MenuItemBase,
    } from "./ToolbarItems";
    import ToolbarItemSetting from "./ToolbarItemSetting.svelte";
    import ToolbarItemAction from "./ToolbarItemAction.svelte";

    export let menuItem: MenuItemBase;
    export let hasHierarchy = false;

    const expandedGroups = ["Preferences", "Rendering"];
</script>

{#if "divider" in menuItem && menuItem.divider}
    <li role="separator" class="mdc-list-divider"></li>
{/if}
{#if menuItem instanceof MenuItemSetting}
    <ToolbarItemSetting menu={menuItem} />
{:else if menuItem instanceof MenuItemAction}
    <ToolbarItemAction menu={menuItem} hierarchy={hasHierarchy} />
{:else if menuItem instanceof MenuItemGroup}
    {@const isCustomGroup = expandedGroups.includes(menuItem.name)}
    {#if isCustomGroup || hasHierarchy}
        {#if isCustomGroup}
            <h5 class="subheader mdc-typography--headline5">
                {menuItem.name}
            </h5>
        {/if}
        {#each menuItem.children as item (item.name)}
            <svelte:self menuItem={item} hasHierarchy={true} />
        {/each}
    {:else}
        <slot />
    {/if}
{/if}

<style lang="scss">
    .mdc-list-divider {
        padding: 2px 16px;
        background-color: var(--color-divider);
        & + :global(.menu-item .property-view) {
            margin-top: 4px;
        }
    }
    .subheader {
        margin: 22px 16px 4px;
    }
</style>
