import { convertThrow, Failure, LazyDerived, ObjectUtils } from "engine-utils-ts";
import type { TrackerPile} from "../..";
import { type Bim, type BimPropertyData, type PropertiesGroupFormatters } from "../..";
import type { ModuleUniqueProps} from "../../archetypes/pv-module/PVModule";
import { PVModuleKeyProps, PVModuleTypeIdent } from "../../archetypes/pv-module/PVModule";
import { TrackerFrameKeyProps, TrackerFrameTypeIdentifier } from "../../archetypes/TrackerFrame";
import { BimProperty } from "../../bimDescriptions/BimProperty";
import { TrackerKeyProps } from "../Tracker";
import { SolverObjectInstance } from '../../runtime/SolverObjectInstance';
import { extractValueUnitPropsGroup } from "../../bimDescriptions/NamedBimPropertiesGroup";
import { flattenNamedPropsGroups } from '../../bimDescriptions/NamedBimPropertiesGroup';
import type { SolverInstancePatchResult } from "../../runtime/ReactiveSolverBase";
import { SolarTrackerPilesPropsGroup } from "src/piles/common";
import type { CostsConfigProvider} from "src/cost-model/capital";
import { mergeCostComponents, sumCostComponents } from "src/cost-model/capital";
import { InstanceCostsGlobal } from "src/archetypes/EquipmentCommon";
import { TrackerFrameUniqueProps, createSolarArrayFrameDefaultCostPerEach } from "src/cost-model/capital/tables/categories/structural/racking";
import { createDefaultCostModuleEach, createDefaultCostModulePerWatt } from "src/cost-model/capital/tables/categories/pv-modules/modules-install";
import { extractPilesFromLegacyTrackerPropsGroup } from "src/piles/TrackerPile";
import { Matrix4 } from 'math-ts';

export function registerSolarTrackerPricingSolver(bim: Bim, costs: CostsConfigProvider) {
    const global = LazyDerived.new1(
        'global',
        [bim.unitsMapper],
        [costs.lazyInstanceCostsByType(`tracker-pile`, TrackerFrameTypeIdentifier, PVModuleTypeIdent)],
        ([costs]) => {
            return new InstanceCostsGlobal(costs, bim.unitsMapper)
        }
    )

    bim.runtimeGlobals.registerByIdent(TrackerPriceSharedArgGlobalIdentifier, global);

    const legacyPropsGroups = {
        SolarTrackerPilesPropsGroup,
        TrackerKeyProps,
        PVModuleKeyProps,
        TrackerFrameUniqueProps,
        OtherPriceRelatedTrackerProps,
        TrackerFrameKeyProps,
    };

    const [flattenedProps, unflatten] = flattenNamedPropsGroups(legacyPropsGroups);

    const identityMatrix = new Matrix4();

    bim.reactiveRuntimes.registerRuntimeSolver(new SolverObjectInstance({
        solverIdentifier: 'tracker-pricing',
        objectsDefaultArgs: {
            legacyProps: flattenedProps,
        },
        globalArgsSelector: {
            [TrackerPriceSharedArgGlobalIdentifier]: InstanceCostsGlobal
        },
        objectsIdentifier: 'tracker',
        cache: true,
        solverFunction: (inputObj, globals): SolverInstancePatchResult => {
            const legacyPropsGroups = unflatten(inputObj.legacyProps);
            if (globals[TrackerPriceSharedArgGlobalIdentifier] instanceof Failure) {
                return {};
            }
            const piles = extractPilesFromLegacyTrackerPropsGroup(0, false, legacyPropsGroups.SolarTrackerPilesPropsGroup, identityMatrix);
            const result = createTrackerPricePatch(
                {
                    moduleKeyProps: legacyPropsGroups.PVModuleKeyProps,
                    otherTrackerPriceRelated: legacyPropsGroups.OtherPriceRelatedTrackerProps,
                    piles,
                    trackerFrameKeyProps: legacyPropsGroups.TrackerFrameKeyProps,
                    trackerFrameUniqueProps: legacyPropsGroups.TrackerFrameUniqueProps,
                    trackerKeyProps: legacyPropsGroups.TrackerKeyProps,
                },
                globals[TrackerPriceSharedArgGlobalIdentifier].value,
                bim.keyPropertiesGroupFormatter,
            );
            return result;
        }
    }));
}

const OtherPriceRelatedTrackerProps = {
    moduleCount: BimProperty.NewShared({
        path: ['circuit', 'equipment', 'modules_count'],
        value: 0,
    }),
    loadWindPosition: BimProperty.NewShared({
        path: ['position', 'load_wind_position'],
        value: '',
    }),
}

interface TrackerPriceInstanceRelatedArg {
    piles: TrackerPile[],
    trackerKeyProps: typeof TrackerKeyProps
    moduleKeyProps: typeof PVModuleKeyProps
    trackerFrameKeyProps: typeof TrackerFrameKeyProps
    trackerFrameUniqueProps: typeof TrackerFrameUniqueProps
    otherTrackerPriceRelated: typeof OtherPriceRelatedTrackerProps
}

function createTrackerPricePatch(
    instanceRelatedArg: TrackerPriceInstanceRelatedArg,
    sharedArg: InstanceCostsGlobal,
    keyPropsFormatter: PropertiesGroupFormatters,
): SolverInstancePatchResult {

    // find matching tracker frame
    let frameTotalCost = 0;
    const frameTitle = keyPropsFormatter.formatNamedProps(TrackerFrameTypeIdentifier, instanceRelatedArg.trackerFrameKeyProps);
    {
        const props = extractValueUnitPropsGroup(instanceRelatedArg.trackerFrameUniqueProps)
        const costs = mergeCostComponents(
            sharedArg.costs.find(x =>
                x.instance_type === TrackerFrameTypeIdentifier &&
                ObjectUtils.areObjectsEqual(x.props, props)
            )?.costs,
            createSolarArrayFrameDefaultCostPerEach(props)
        );
        const cost = costs && sumCostComponents(costs) || 0;
        frameTotalCost = cost;
    }

    // modules total cost
    let allModuleTotalCost = 0;
    let singleModuleTotalCost = 0;
    const moduleKeyProps = extractValueUnitPropsGroup(instanceRelatedArg.moduleKeyProps);
    const moduleTitle = keyPropsFormatter.formatNamedProps(PVModuleTypeIdent, moduleKeyProps);
    {
        const uniqueProps: typeof ModuleUniqueProps = {
            manufacturer: moduleKeyProps.manufacturer,
            model: moduleKeyProps.model,
            power: moduleKeyProps.maximum_power,
        }
        const costs = sharedArg.costs.filter(x =>
            x.instance_type === PVModuleTypeIdent &&
            ObjectUtils.areObjectsEqual(x.props, uniqueProps)
        )
        const wattCost = sumCostComponents(mergeCostComponents(costs.find(x => x.name === 'watt')?.costs, createDefaultCostModulePerWatt()));
        const eachCost = sumCostComponents(mergeCostComponents(costs.find(x => x.name === 'each')?.costs, createDefaultCostModuleEach()));
        const modulePowerWatt = uniqueProps.power.as('W');
        const singleCost = modulePowerWatt * wattCost + eachCost
        const count = instanceRelatedArg.otherTrackerPriceRelated.moduleCount.asNumber();
        allModuleTotalCost = count * singleCost;
        singleModuleTotalCost = singleCost;
    }



    // piles
    let pilesTotalCost = 0;
    {
        const allPileCosts = sharedArg.costs.filter(x => x.instance_type === `tracker-pile`);
        for (const pile of instanceRelatedArg.piles) {
            const cost = pile.calculatePileCost(allPileCosts);
            pilesTotalCost += convertThrow(cost.value, cost.unit, 'usd');
        }
    }

    // assembly cost
    const assemblyCostUsd = pilesTotalCost + frameTotalCost + allModuleTotalCost;

    // compose patch
    const patch: BimPropertyData[] = [
        {
                path: ['cost', 'total_cost'],
                value: assemblyCostUsd,
                unit: 'usd',
                readonly: true,
        },
        {
                path: ['cost', 'frame', 'cost_item'],
                value: frameTitle ?? 'unknown_frame',
                description: '',
                readonly: true,
        },
        {
                path: ['cost', 'frame', 'cost'],
                value: frameTotalCost,
                unit: 'usd',
                readonly: true,
        },
        {
                path: ['cost', 'frame', 'total_cost'],
                value: frameTotalCost,
                unit: 'usd',
                readonly: true,
        },
        {
                path: ['cost', 'module', 'cost_item'],
                value: moduleTitle ?? 'unknown_module',
                readonly: true,
                description: '',
        },
        {
                path: ['cost', 'module', 'per_each_cost'],
                value: singleModuleTotalCost,
                unit: 'usd',
                readonly: true,
        },
        {
                path: ['cost', 'module', 'total_cost'],
                value: allModuleTotalCost,
                unit: 'usd',
                readonly: true,
        },
        {
                path: ['cost', 'piles', 'total_cost'],
                value: pilesTotalCost,
                unit: 'usd',
                readonly: true,
        },
    ];

    return {
        legacyProps: patch,
        removeProps: [
            BimProperty.MergedPath(['cost', 'frame', 'position_multiplier']),
            BimProperty.MergedPath(['cost', 'status']),
            BimProperty.MergedPath(['cost', 'has_zero_costs']),
        ],
    }

}

export const TrackerPriceSharedArgGlobalIdentifier = 'tracker-pricing-shared-args';
