// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { FlatBufferProjectionInfo } from '../flat-buffers/flat-buffer-projection-info.js';


export class FlatBuffersGeoPoint {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):FlatBuffersGeoPoint {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsFlatBuffersGeoPoint(bb:flatbuffers.ByteBuffer, obj?:FlatBuffersGeoPoint):FlatBuffersGeoPoint {
  return (obj || new FlatBuffersGeoPoint()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsFlatBuffersGeoPoint(bb:flatbuffers.ByteBuffer, obj?:FlatBuffersGeoPoint):FlatBuffersGeoPoint {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new FlatBuffersGeoPoint()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

longitude():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

latitude():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

altitude():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

wgsOrigin(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readFloat64(this.bb!.__vector(this.bb_pos + offset) + index * 8) : 0;
}

wgsOriginLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

wgsOriginArray():Float64Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? new Float64Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

projectionInfo(obj?:FlatBufferProjectionInfo):FlatBufferProjectionInfo|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new FlatBufferProjectionInfo()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startFlatBuffersGeoPoint(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addLongitude(builder:flatbuffers.Builder, longitude:number) {
  builder.addFieldFloat64(0, longitude, 0.0);
}

static addLatitude(builder:flatbuffers.Builder, latitude:number) {
  builder.addFieldFloat64(1, latitude, 0.0);
}

static addAltitude(builder:flatbuffers.Builder, altitude:number) {
  builder.addFieldFloat64(2, altitude, 0.0);
}

static addWgsOrigin(builder:flatbuffers.Builder, wgsOriginOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, wgsOriginOffset, 0);
}

static createWgsOriginVector(builder:flatbuffers.Builder, data:number[]|Float64Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createWgsOriginVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createWgsOriginVector(builder:flatbuffers.Builder, data:number[]|Float64Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(8, data.length, 8);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addFloat64(data[i]!);
  }
  return builder.endVector();
}

static startWgsOriginVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(8, numElems, 8);
}

static addProjectionInfo(builder:flatbuffers.Builder, projectionInfoOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, projectionInfoOffset, 0);
}

static endFlatBuffersGeoPoint(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

}
