import { IterUtils } from "engine-utils-ts";
import { ppmt, ipmt } from "financial";

export interface CalculateLCOEInput {
    debtCost: {
        debtTermYears: number;
        interestRateOnDebtPercent: number;
        debtSizeAsPercentOfCapitalCost: number;
        lenderFeePercentOfDebt: number;
    };
    lcoe: {
        annualElectricityOutputWattHour: number;
        annualProductionDegradationPercent: number;
        projectUsefulLifeYears: number;
        discountRatePercent: number;
        operationGrowthRatePercent: number;
    };
    operatingCost: {
        operationAndMaintenanceCostPerWattPerYear: number;
        generalAndAdministrativePerWattPerYear: number;
        insuranceCostAsPercentOfCapitalCostPerYear: number;
        propertyTaxAsPercentOfCapitalCostPerYear: number;
        landLeaseCostPerAcrePerYear: number;
    };
    capitalCost: {
        totalCost: number;
    };
    layout: {
        areaAcres: number;
        wattDC: number;
    };
}

export interface CalculateLCOEOutputPerYear {
    production: {
        annualEnergyPerYearWattHour: number;
        discountFactorPercent: number;
        netPresentValueOfEnergyWattHour: number;

        simplifiedEnergyProductionWattHour: number;
        productionDegrationWattHour: number;
        discountRateAppliedEachYearWattHour: number;
    };
    operatingExpenses: {
        operatingGrowthRatePercent: number;
        operationAndMaitenanceCost: number;
        generalAndAdministrativeCost: number;
        insuranceCost: number;
        propertyTaxCost: number;
        landLeaseCost: number;
        totalCost: number;
    };
    debtExpenses: {
        principalCost: number;
        interestCost: number;
        feeCost: number;
        totalCost: number;
    };
    totalOperatingAndDebtCost: number;
    netPresentValueOfCost: number;
    costPerWattHour: number;
    npvOfCostDivNpvOfWattHour: number;
}

export interface CalculateLCOEOutput {
    totalEnergyProductionWattHours: number;
    NPVOfEnergyProductionWattHours: number;
    initialInvestmentInCapitalCost: number;
    totalOperatingCost: number;
    totalDebtCost: number;
    NVPOfCost: number;
    levelizedCostOfEnergyPerWattHour: number;
    perYear: CalculateLCOEOutputPerYear[];
}

export function calculateLCOE(input: CalculateLCOEInput): CalculateLCOEOutput {
    const lcoePerYear: CalculateLCOEOutputPerYear[] = [];
    for (let year = 1; year <= input.lcoe.projectUsefulLifeYears; year++) {
        const prevYearResult = lcoePerYear[year - 2] as
            | CalculateLCOEOutputPerYear
            | undefined;

        // production
        let annualEnergyPerYearWattHour: number;
        if (!prevYearResult) {
            annualEnergyPerYearWattHour =
                input.lcoe.annualElectricityOutputWattHour;
        } else {
            annualEnergyPerYearWattHour =
                prevYearResult.production.annualEnergyPerYearWattHour *
                (1 - input.lcoe.annualProductionDegradationPercent);
        }

        const prevDiscountFactorPercent =
            prevYearResult?.production.discountFactorPercent ?? 1;
        const discountFactorPercent =
            prevDiscountFactorPercent / (1 + input.lcoe.discountRatePercent);

        const netPresentValueOfEnergyWattHour =
            annualEnergyPerYearWattHour * discountFactorPercent;

        // operating expenses
        let operatingGrowthRatePercent: number;
        if (!prevYearResult) {
            operatingGrowthRatePercent = 1;
        } else {
            operatingGrowthRatePercent =
                prevYearResult.operatingExpenses.operatingGrowthRatePercent *
                (1 + input.lcoe.operationGrowthRatePercent);
        }

        const operationAndMaitenanceCost =
            input.layout.wattDC *
            input.operatingCost.operationAndMaintenanceCostPerWattPerYear *
            operatingGrowthRatePercent;
        const generalAndAdministrativeCost =
            input.layout.wattDC *
            input.operatingCost.generalAndAdministrativePerWattPerYear *
            operatingGrowthRatePercent;
        const insuranceCost =
            input.operatingCost.insuranceCostAsPercentOfCapitalCostPerYear *
            input.capitalCost.totalCost *
            operatingGrowthRatePercent;
        const propertyTaxCost =
            input.operatingCost.propertyTaxAsPercentOfCapitalCostPerYear *
            input.capitalCost.totalCost *
            operatingGrowthRatePercent;
        const landLeaseCost =
            input.operatingCost.landLeaseCostPerAcrePerYear *
            input.layout.areaAcres *
            operatingGrowthRatePercent;

        const operationTotalCost =
            operationAndMaitenanceCost +
            generalAndAdministrativeCost +
            insuranceCost +
            propertyTaxCost +
            landLeaseCost;

        // debt expenses
        let principalCost = 0;
        let interestCost = 0;
        if (year <= input.debtCost.debtTermYears) {
            principalCost = -ppmt(
                input.debtCost.interestRateOnDebtPercent,
                year,
                input.debtCost.debtTermYears,
                input.capitalCost.totalCost *
                    input.debtCost.debtSizeAsPercentOfCapitalCost,
            );
            interestCost = -ipmt(
                input.debtCost.interestRateOnDebtPercent,
                year,
                input.debtCost.debtTermYears,
                input.capitalCost.totalCost *
                    input.debtCost.debtSizeAsPercentOfCapitalCost,
            );
        }

        let debtFeeCost = 0;
        if (year === 1) {
            debtFeeCost =
                input.debtCost.lenderFeePercentOfDebt *
                input.debtCost.debtSizeAsPercentOfCapitalCost *
                input.capitalCost.totalCost;
        }

        const debtTotalCost = principalCost + interestCost + debtFeeCost;

        // operating & debt
        const totalOperatingAndDebtCost = debtTotalCost + operationTotalCost;

        const netPresentValueOfCost =
            totalOperatingAndDebtCost * discountFactorPercent;

        const costPerWattHour =
            totalOperatingAndDebtCost / annualEnergyPerYearWattHour;
        const npvOfCostDivNpvOfWattHour =
            netPresentValueOfCost / netPresentValueOfEnergyWattHour;

        const simplifiedEnergyProductionWattHour =
            input.lcoe.annualElectricityOutputWattHour;

        const productionDegrationWattHour =
            simplifiedEnergyProductionWattHour - annualEnergyPerYearWattHour;

        const discountRateAppliedEachYearWattHour =
            annualEnergyPerYearWattHour - netPresentValueOfEnergyWattHour;

        const perYearResult: CalculateLCOEOutputPerYear = {
            production: {
                annualEnergyPerYearWattHour,
                discountFactorPercent,
                netPresentValueOfEnergyWattHour,

                simplifiedEnergyProductionWattHour,
                productionDegrationWattHour,
                discountRateAppliedEachYearWattHour,
            },
            debtExpenses: {
                feeCost: debtFeeCost,
                interestCost,
                principalCost,
                totalCost: debtTotalCost,
            },
            operatingExpenses: {
                generalAndAdministrativeCost,
                insuranceCost,
                landLeaseCost,
                operatingGrowthRatePercent,
                operationAndMaitenanceCost,
                propertyTaxCost,
                totalCost: operationTotalCost,
            },
            netPresentValueOfCost,
            totalOperatingAndDebtCost,
            costPerWattHour,
            npvOfCostDivNpvOfWattHour,
        };
        lcoePerYear.push(perYearResult);
    }

    // all time results
    const totalEnergyProductionWattHours = IterUtils.sum(
        lcoePerYear,
        (x) => x.production.annualEnergyPerYearWattHour,
    );
    const NPVOfEnergyProductionWattHours = IterUtils.sum(
        lcoePerYear,
        (x) => x.production.netPresentValueOfEnergyWattHour,
    );
    const initialInvestmentInCapitalCost =
        input.capitalCost.totalCost *
        (1 - input.debtCost.debtSizeAsPercentOfCapitalCost);
    const totalOperatingCost = IterUtils.sum(
        lcoePerYear,
        (x) => x.operatingExpenses.totalCost,
    );
    const totalDebtCost = IterUtils.sum(
        lcoePerYear,
        (x) => x.debtExpenses.totalCost,
    );
    const NVPOfCost = IterUtils.sum(
        lcoePerYear,
        (x) => x.netPresentValueOfCost,
    );
    const levelizedCostOfEnergyPerWattHour =
        (initialInvestmentInCapitalCost + NVPOfCost) /
        NPVOfEnergyProductionWattHours;

    const allTimeResult: CalculateLCOEOutput = {
        totalEnergyProductionWattHours,
        NPVOfEnergyProductionWattHours,
        initialInvestmentInCapitalCost,
        totalOperatingCost,
        totalDebtCost,
        NVPOfCost,
        perYear: lcoePerYear,
        levelizedCostOfEnergyPerWattHour,
    };
    return allTimeResult;
}

export type LCOECalculationResult = {
    input: CalculateLCOEInput;
    output: CalculateLCOEOutput;
};
