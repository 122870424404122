import type { Bim, ProjectMetrics, TrackerPilesCollection } from "bim-ts"
import type { ProjectNetworkClient, TasksRunner} from "engine-utils-ts";
import { IterUtils, LazyBasic, LazyDerived, PollablePromise, VersionedInvalidator, Yield, type VersionedValue, Failure } from "engine-utils-ts"
import { NotificationDescription, NotificationType, type UiBindings } from "ui-bindings"
import { notificationSource } from '../Notifications'
import { ActionSolvers, registerActions } from "./actions/Action"
import { ReferenceTypeIdentifier } from "./actions/reference/Reference"
import type { CostReportTemplate } from "./CostReportTemplate"
import { CostReportTemplatesStorageApi } from "./CostReportTemplatesStorageApi"
import { groupHierarchy, sortHierarchy, TableHierarchy, type GroupedTableHierarchy } from "./TableHierarchy"

export class CostReportTemplates {
    api: CostReportTemplatesStorageApi;
    templates = new LazyBasic<CostReportTemplate[]>('templates', []);
    tasksRunner: TasksRunner;
    uiBindings: UiBindings;

    constructor(
        baseNetwork: ProjectNetworkClient,
        tasksRunner: TasksRunner,
        uiBindings: UiBindings,
    ) {
        this.api = new CostReportTemplatesStorageApi(baseNetwork);
        this.tasksRunner = tasksRunner;
        this.uiBindings = uiBindings;
        this.loadFromRemote();
    }

    *saveToRemoteGen() {
        const promises: Promise<any>[] = [];
        for (const report of this.templates.poll()) {
            promises.push(this.api.saveTemplate(report));
        }
        const result = yield* PollablePromise.generatorWaitFor(Promise.all(promises));
        if (result instanceof Failure) {
            throw new Error(result.errorMsg());
        }
    }

    *loadFromRemoteGen() {
        const reply = yield* PollablePromise.generatorWaitFor(this.api.getAllTemplates());
        if (reply instanceof Failure) {
            throw new Error(reply.errorMsg());
        }
        const templates = reply.value.company; 
        this.templates.forceUpdate(templates);
    }

    loadFromRemote() {
        const task = this.tasksRunner.newLongTask({ defaultGenerator: this.loadFromRemoteGen() })
    }
    loadFromRemote_withNotification() {
        const task = this.tasksRunner.newLongTask({ defaultGenerator: this.loadFromRemoteGen() })
        this.uiBindings.addNotification(NotificationDescription.newWithTask({
            source: notificationSource,
            key: 'loadCostReport',
            taskDescription: { task },
            type: NotificationType.Info,
            addToNotificationsLog: true,
        }))
    }

}

export class CostReport implements VersionedValue {

    actionSolvers = new ActionSolvers();

    invalidator: VersionedInvalidator;

    costReportTemplates: CostReportTemplates;

    constructor(
        bim: Bim,
        private uiBindings: UiBindings,
        layoutMetrics: ProjectMetrics,
        network: ProjectNetworkClient,
        tasksRunner: TasksRunner,
        trackerPilesCollection: TrackerPilesCollection,
    ) {
        const actionsInv = registerActions(bim, tasksRunner, this, layoutMetrics, trackerPilesCollection);
        this.costReportTemplates = new CostReportTemplates(network, tasksRunner, uiBindings);
        this.invalidator = new VersionedInvalidator([actionsInv, layoutMetrics]);
    }


    version() {
      return this.invalidator.version();
    }

    buildSortedHierarchyForTemplateLazy = LazyDerived.new0(
        'buildSortedHierarchyForTemplate',
        [this],
        () => {
            const costReport = this;
            return {
                calculate: function*(
                    template: CostReportTemplate,
                    projectId: number
                ) {
                    const hierarchy = yield* costReport.buildHierarchyForTemplate(
                        template,
                        projectId
                    );
                    hierarchy.delete([]);
                    const grouped = groupHierarchy(hierarchy);
                    sortHierarchy(grouped);
                    return [
                      grouped,
                      hierarchy
                    ] as [
                      grouped: GroupedTableHierarchy,
                      hierarchy: TableHierarchy
                    ];
                }
            };
        }
    ).withoutEqCheck();

    *buildHierarchyForTemplate(template: CostReportTemplate, projectId: number) {
        const hierarchy = new TableHierarchy();
        try {
            const projectBasedActions = template.projectSpecificOverriders
                .find(x => x.projectId === projectId);
            let idxOfFirstRef = template.actions
              .findIndex(x => x.type === ReferenceTypeIdentifier);
            if (idxOfFirstRef < 0) {
              idxOfFirstRef = template.actions.length
            }
            const allActions = template.actions.slice().filter(x => !x.disable);
            allActions.splice(idxOfFirstRef, 0, ...(projectBasedActions?.actions ?? []))
            for (const chunk of IterUtils.splitIterIntoChunks(allActions.entries(), 3)) {
                for (const [idx, action] of chunk) {
                    this.actionSolvers.applyActionSolver(action, idx, hierarchy);
                }
                yield Yield.NextFrame;
            }
        } catch (e) {
            this.uiBindings.addNotification(NotificationDescription.newBasic({
                source: notificationSource,
                key: 'generateTable',
                descriptionArg: e.message,
                type: NotificationType.Error,
                addToNotificationsLog: true
            }))
        }
        return hierarchy; 
    }
}


