import { ObjectUtils } from 'engine-utils-ts';

import type { BimProperty, BimPropertyData } from '../bimDescriptions/BimProperty';
import type { AnySolverObjectInput} from './ObjectsSelector';
import { ChildrenSelector, ObjectsSelector } from './ObjectsSelector';
import type { SolverInstancePatchResult } from './ReactiveSolverBase';
import { ReactiveSolverBase } from './ReactiveSolverBase';
import type { AnySharedDependenciesInput} from './RuntimeGlobals';

export interface SolverPropsInput {
    [shortName: string]: BimProperty;
}

export interface ChildIdentWithArgs {
    identifier: string;
    args: SolverPropsInput;
}


export type SolverPropsWithChildrenFn<Args extends SolverPropsInput> = (
    inObjectArgs: Args,
    childrenArgs: SolverPropsInput[][]
) => BimPropertyData[];

export class SolverPropsWithChildren
    <Args extends SolverPropsInput> extends ReactiveSolverBase {
    readonly defaultInObjectArgs: SolverPropsInput;
    readonly defaultChildrenArgs: Map<string, SolverPropsInput>;
    readonly solverFunction: SolverPropsWithChildrenFn<any>;

    private constructor(
        solverIdentifier: string,
        solverFunction: SolverPropsWithChildrenFn<Args>,
        objectsIdentifier: string | string[],
        defaultInObjectArgs: Args,
        childrenIdWithArgs: ChildIdentWithArgs[]
    ) {
        super({
            identifier: solverIdentifier,
            objFilter: new ObjectsSelector(
                objectsIdentifier,
                {
                    legacyProps: defaultInObjectArgs,
                }
            ),
            childrenFilter: new ChildrenSelector(
                childrenIdWithArgs.map((c) => {
                    return {
                        type_identifier: c.identifier,
                        propsDependencies: c.args
                    };
                })
            ),
        });
        this.defaultInObjectArgs = ObjectUtils.deepFreeze(defaultInObjectArgs);
        this.defaultChildrenArgs = new Map<string, SolverPropsInput>();
        childrenIdWithArgs.forEach((c) =>
            this.defaultChildrenArgs.set(
                c.identifier,
                ObjectUtils.deepFreeze(c.args)
            )
        );
        this.solverFunction = solverFunction;
    }

    compute(
        currObjectInput: Readonly<AnySolverObjectInput>,
        sharedArgs?: Readonly<AnySharedDependenciesInput>,
        children?: Readonly<AnySolverObjectInput>[][]
    ): SolverInstancePatchResult {
        const childrenArgs = children?.map(g => g.map(x => x.legacyProps ?? {})) ?? []
        const props = this.solverFunction(currObjectInput.legacyProps, childrenArgs);
        return {
            legacyProps: props
        };
    }

    static new1<
        Args extends SolverPropsInput,
        ChArgs1 extends SolverPropsInput
    >(
        solverIdentifier: string,
        objectsIdentifier: string,
        defaultInObjectArgs: Args,
        childrenIdWithArgs: [
            child1: { identifier: string, args: ChArgs1 },
        ],
        solverFunction: (
            inObjectArgs: Args,
            childrenArgs: [arg1: ChArgs1[]]
        ) => BimPropertyData[]
    ): SolverPropsWithChildren<Args> {
        return new SolverPropsWithChildren(
            solverIdentifier,
            solverFunction as (
                inObjectArgs: Args,
                childrenArgs: any[][]
            ) => BimPropertyData[],
            objectsIdentifier,
            defaultInObjectArgs,
            childrenIdWithArgs
        );
    }

    static new2<
        Args extends SolverPropsInput,
        ChArgs1 extends SolverPropsInput,
        ChArgs2 extends SolverPropsInput
    >(
        solverIdentifier: string,
        objectsIdentifier: string,
        defaultInObjectArgs: Args,
        childrenIdWithArgs: [
            child1: { identifier: string, args: ChArgs1 },
            child2: { identifier: string, args: ChArgs2 },
        ],
        solverFunction: (
            inObjectArgs: Args,
            childrenArgs: [arg1: ChArgs1[], arg2: ChArgs2[]]
        ) => BimPropertyData[]
    ): SolverPropsWithChildren<Args> {
        return new SolverPropsWithChildren(
            solverIdentifier,
            solverFunction as (
                inObjectArgs: Args,
                childrenArgs: any[][]
            ) => BimPropertyData[],
            objectsIdentifier,
            defaultInObjectArgs,
            childrenIdWithArgs
        );
    }

    static new3<
        Args extends SolverPropsInput,
        ChArgs1 extends SolverPropsInput,
        ChArgs2 extends SolverPropsInput,
        ChArgs3 extends SolverPropsInput
    >(
        solverIdentifier: string,
        objectsIdentifier: string,
        defaultInObjectArgs: Args,
        childrenIdWithArgs: [
            child1: { identifier: string, args: ChArgs1 },
            child2: { identifier: string, args: ChArgs2 },
            child3: { identifier: string, args: ChArgs3 },
        ],
        solverFunction: (
            inObjectArgs: Args,
            childrenArgs: [arg1: ChArgs1[], arg2: ChArgs2[], arg3: ChArgs3[]]
        ) => BimPropertyData[]
    ): SolverPropsWithChildren<Args> {
        return new SolverPropsWithChildren(
            solverIdentifier,
            solverFunction as (
                inObjectArgs: Args,
                childrenArgs: any[][]
            ) => BimPropertyData[],
            objectsIdentifier,
            defaultInObjectArgs,
            childrenIdWithArgs
        );
    }

    static new4<
        Args extends SolverPropsInput,
        ChArgs1 extends SolverPropsInput,
        ChArgs2 extends SolverPropsInput,
        ChArgs3 extends SolverPropsInput,
        ChArgs4 extends SolverPropsInput,
    >(
        solverIdentifier: string,
        objectsIdentifier: string | string[],
        defaultInObjectArgs: Args,
        childrenIdWithArgs: [
            child1: { identifier: string, args: ChArgs1 },
            child2: { identifier: string, args: ChArgs2 },
            child3: { identifier: string, args: ChArgs3 },
            child4: { identifier: string, args: ChArgs4 },
        ],
        solverFunction: (
            inObjectArgs: Args,
            childrenArgs: [
                arg1: ChArgs1[],
                arg2: ChArgs2[],
                arg3: ChArgs3[],
                arg4: ChArgs4[]
            ]
        ) => BimPropertyData[]
    ): SolverPropsWithChildren<Args> {
        return new SolverPropsWithChildren(
            solverIdentifier,
            solverFunction as (
                inObjectArgs: Args,
                childrenArgs: any[][]
            ) => BimPropertyData[],
            objectsIdentifier,
            defaultInObjectArgs,
            childrenIdWithArgs
        );
    }

    static new5<
        Args extends SolverPropsInput,
        ChArgs1 extends SolverPropsInput,
        ChArgs2 extends SolverPropsInput,
        ChArgs3 extends SolverPropsInput,
        ChArgs4 extends SolverPropsInput,
        ChArgs5 extends SolverPropsInput
    >(
        solverIdentifier: string,
        objectsIdentifier: string | string[],
        defaultInObjectArgs: Args,
        childrenIdWithArgs: [
            child1: { identifier: string, args: ChArgs1 },
            child2: { identifier: string, args: ChArgs2 },
            child3: { identifier: string, args: ChArgs3 },
            child4: { identifier: string, args: ChArgs4 },
            child5: { identifier: string, args: ChArgs5 },
        ],
        solverFunction: (
            inObjectArgs: Args,
            childrenArgs: [
                arg1: ChArgs1[],
                arg2: ChArgs2[],
                arg3: ChArgs3[],
                arg4: ChArgs4[],
                arg5: ChArgs5[]
            ]
        ) => BimPropertyData[]
    ): SolverPropsWithChildren<Args> {
        return new SolverPropsWithChildren(
            solverIdentifier,
            solverFunction as (
                inObjectArgs: Args,
                childrenArgs: any[][]
            ) => BimPropertyData[],
            objectsIdentifier,
            defaultInObjectArgs,
            childrenIdWithArgs,
        );
    }

    static new6<
        Args extends SolverPropsInput,
        ChArgs1 extends SolverPropsInput,
        ChArgs2 extends SolverPropsInput,
        ChArgs3 extends SolverPropsInput,
        ChArgs4 extends SolverPropsInput,
        ChArgs5 extends SolverPropsInput,
        ChArgs6 extends SolverPropsInput
    >(
        solverIdentifier: string,
        objectsIdentifier: string | string[],
        defaultInObjectArgs: Args,
        childrenIdWithArgs: [
            child1: { identifier: string, args: ChArgs1 },
            child2: { identifier: string, args: ChArgs2 },
            child3: { identifier: string, args: ChArgs3 },
            child4: { identifier: string, args: ChArgs4 },
            child5: { identifier: string, args: ChArgs5 },
            child6: { identifier: string, args: ChArgs6 },
        ],
        solverFunction: (
            inObjectArgs: Args,
            childrenArgs: [
                arg1: ChArgs1[],
                arg2: ChArgs2[],
                arg3: ChArgs3[],
                arg4: ChArgs4[],
                arg5: ChArgs5[],
                arg6: ChArgs6[],
            ]
        ) => BimPropertyData[]
    ): SolverPropsWithChildren<Args> {
        return new SolverPropsWithChildren(
            solverIdentifier,
            solverFunction as (
                inObjectArgs: Args,
                childrenArgs: any[][]
            ) => BimPropertyData[],
            objectsIdentifier,
            defaultInObjectArgs,
            childrenIdWithArgs,
        );
    }

    static new7<
        Args extends SolverPropsInput,
        ChArgs1 extends SolverPropsInput,
        ChArgs2 extends SolverPropsInput,
        ChArgs3 extends SolverPropsInput,
        ChArgs4 extends SolverPropsInput,
        ChArgs5 extends SolverPropsInput,
        ChArgs6 extends SolverPropsInput,
        ChArgs7 extends SolverPropsInput,
    >(
        solverIdentifier: string,
        objectsIdentifier: string,
        defaultInObjectArgs: Args,
        childrenIdWithArgs: [
            child1: { identifier: string, args: ChArgs1 },
            child2: { identifier: string, args: ChArgs2 },
            child3: { identifier: string, args: ChArgs3 },
            child4: { identifier: string, args: ChArgs4 },
            child5: { identifier: string, args: ChArgs5 },
            child6: { identifier: string, args: ChArgs6 },
            child7: { identifier: string, args: ChArgs7 },
        ],
        solverFunction: (
            inObjectArgs: Args,
            childrenArgs: [
                arg1: ChArgs1[],
                arg2: ChArgs2[],
                arg3: ChArgs3[],
                arg4: ChArgs4[],
                arg5: ChArgs5[],
                arg6: ChArgs6[],
                arg7: ChArgs7[],
            ]
        ) => BimPropertyData[]
    ): SolverPropsWithChildren<Args> {
        return new SolverPropsWithChildren(
            solverIdentifier,
            solverFunction as (
                inObjectArgs: Args,
                childrenArgs: any[][]
            ) => BimPropertyData[],
            objectsIdentifier,
            defaultInObjectArgs,
            childrenIdWithArgs,
        );
    }

    static new8<
        Args extends SolverPropsInput,
        ChArgs1 extends SolverPropsInput,
        ChArgs2 extends SolverPropsInput,
        ChArgs3 extends SolverPropsInput,
        ChArgs4 extends SolverPropsInput,
        ChArgs5 extends SolverPropsInput,
        ChArgs6 extends SolverPropsInput,
        ChArgs7 extends SolverPropsInput,
        ChArgs8 extends SolverPropsInput,
    >(
        solverIdentifier: string,
        objectsIdentifier: string | string[],
        defaultInObjectArgs: Args,
        childrenIdWithArgs: [
            child1: { identifier: string, args: ChArgs1 },
            child2: { identifier: string, args: ChArgs2 },
            child3: { identifier: string, args: ChArgs3 },
            child4: { identifier: string, args: ChArgs4 },
            child5: { identifier: string, args: ChArgs5 },
            child6: { identifier: string, args: ChArgs6 },
            child7: { identifier: string, args: ChArgs7 },
            child8: { identifier: string, args: ChArgs8 },
        ],
        solverFunction: (
            inObjectArgs: Args,
            childrenArgs: [
                arg1: ChArgs1[],
                arg2: ChArgs2[],
                arg3: ChArgs3[],
                arg4: ChArgs4[],
                arg5: ChArgs5[],
                arg6: ChArgs6[],
                arg7: ChArgs7[],
                arg8: ChArgs8[],
            ]
        ) => BimPropertyData[]
    ): SolverPropsWithChildren<Args> {
        return new SolverPropsWithChildren(
            solverIdentifier,
            solverFunction as (
                inObjectArgs: Args,
                childrenArgs: any[][]
            ) => BimPropertyData[],
            objectsIdentifier,
            defaultInObjectArgs,
            childrenIdWithArgs,
        );
    }

    static new9<
        Args extends SolverPropsInput,
        ChArgs1 extends SolverPropsInput,
        ChArgs2 extends SolverPropsInput,
        ChArgs3 extends SolverPropsInput,
        ChArgs4 extends SolverPropsInput,
        ChArgs5 extends SolverPropsInput,
        ChArgs6 extends SolverPropsInput,
        ChArgs7 extends SolverPropsInput,
        ChArgs8 extends SolverPropsInput,
        ChArgs9 extends SolverPropsInput,
    >(
        solverIdentifier: string,
        objectsIdentifier: string | string[],
        defaultInObjectArgs: Args,
        childrenIdWithArgs: [
            child1: { identifier: string, args: ChArgs1 },
            child2: { identifier: string, args: ChArgs2 },
            child3: { identifier: string, args: ChArgs3 },
            child4: { identifier: string, args: ChArgs4 },
            child5: { identifier: string, args: ChArgs5 },
            child6: { identifier: string, args: ChArgs6 },
            child7: { identifier: string, args: ChArgs7 },
            child8: { identifier: string, args: ChArgs8 },
            child9: { identifier: string, args: ChArgs9 },
        ],
        solverFunction: (
            inObjectArgs: Args,
            childrenArgs: [
                arg1: ChArgs1[],
                arg2: ChArgs2[],
                arg3: ChArgs3[],
                arg4: ChArgs4[],
                arg5: ChArgs5[],
                arg6: ChArgs6[],
                arg7: ChArgs7[],
                arg8: ChArgs8[],
                arg9: ChArgs9[],
            ]
        ) => BimPropertyData[]
    ): SolverPropsWithChildren<Args> {
        return new SolverPropsWithChildren(
            solverIdentifier,
            solverFunction as (
                inObjectArgs: Args,
                childrenArgs: any[][]
            ) => BimPropertyData[],
            objectsIdentifier,
            defaultInObjectArgs,
            childrenIdWithArgs,
        );
    }


    static new10<
        Args extends SolverPropsInput,
        ChArgs1 extends SolverPropsInput,
        ChArgs2 extends SolverPropsInput,
        ChArgs3 extends SolverPropsInput,
        ChArgs4 extends SolverPropsInput,
        ChArgs5 extends SolverPropsInput,
        ChArgs6 extends SolverPropsInput,
        ChArgs7 extends SolverPropsInput,
        ChArgs8 extends SolverPropsInput,
        ChArgs9 extends SolverPropsInput,
        ChArgs10 extends SolverPropsInput,
    >(
        solverIdentifier: string,
        objectsIdentifier: string | string[],
        defaultInObjectArgs: Args,
        childrenIdWithArgs: [
            child1: { identifier: string, args: ChArgs1 },
            child2: { identifier: string, args: ChArgs2 },
            child3: { identifier: string, args: ChArgs3 },
            child4: { identifier: string, args: ChArgs4 },
            child5: { identifier: string, args: ChArgs5 },
            child6: { identifier: string, args: ChArgs6 },
            child7: { identifier: string, args: ChArgs7 },
            child8: { identifier: string, args: ChArgs8 },
            child9: { identifier: string, args: ChArgs9 },
            child10: { identifier: string, args: ChArgs10 },
        ],
        solverFunction: (
            inObjectArgs: Args,
            childrenArgs: [
                arg1: ChArgs1[],
                arg2: ChArgs2[],
                arg3: ChArgs3[],
                arg4: ChArgs4[],
                arg5: ChArgs5[],
                arg6: ChArgs6[],
                arg7: ChArgs7[],
                arg8: ChArgs8[],
                arg9: ChArgs9[],
                arg9: ChArgs10[],
            ]
        ) => BimPropertyData[]
    ): SolverPropsWithChildren<Args> {
        return new SolverPropsWithChildren(
            solverIdentifier,
            solverFunction as (
                inObjectArgs: Args,
                childrenArgs: any[][]
            ) => BimPropertyData[],
            objectsIdentifier,
            defaultInObjectArgs,
            childrenIdWithArgs,
        );
    }
}
