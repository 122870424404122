<script lang="ts">
    import type { PUI_PropertyNodeSlider } from "ui-bindings";
    import SliderComponent from "../libui/slider/SliderComponent.svelte";
    import PropertyHelp from "./PropertyHelp.svelte";

    export let property: PUI_PropertyNodeSlider;
</script>

<div 
    class="ui-config-property property-row"
    class:not-active = {property.notActive}
    >
    <div class="property-name text-main-medium">
        <div
            class="property-name-label mdc-typography--body1"
            class:property-help={!!property.description}
        >
            {property.name}
            {#if !!property.description}
                <PropertyHelp
                    content={property.description}
                    name={property.name}
                />
            {/if}
        </div>
    </div>
    <div class="property-value multiline">
        <SliderComponent
            bind:value={property.value}
            readonly={property.readonly}
            settings={{
                minMax: property.minMax,
                step: property.step,
                minLabel: property.settings?.minValueLabel,
                maxLabel: property.settings?.maxValueLabel,
                message: property.settings?.message,
            }}
        />
    </div>
</div>

<style lang="scss">
    .property-help {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
</style>
