<script lang="ts">
    import type { Bim, TrackerShadingFactorsProperty } from "bim-ts";
    import { trackerShadingPolarChart, trackerShadingPropertyGridPui } from "./TrackerShadingPui";
    import TableUi from "../../grid-table-ui/TableUi.svelte";
    import { LazyBasic } from "engine-utils-ts";
    import PolarHeatmapChart from '../../charts/PolarHeatmapChart.svelte';

    export let sourceProperty: TrackerShadingFactorsProperty;

    let shadingTableView = trackerShadingPropertyGridPui(sourceProperty);

    let shadingPolarHeatmapDescription = trackerShadingPolarChart(sourceProperty);



</script>

<div class="root">
    <span class="controls-group">
    </span>
    <span class="actions-group">
    </span>
    <div class="table">
        <TableUi
            lazyTableData = {new LazyBasic('', shadingTableView)}
        />
    </div>
    </div>
        <div class="chart">
            <PolarHeatmapChart chartDescription={shadingPolarHeatmapDescription}>
            </PolarHeatmapChart>
        </div>
    <div>


</div>

<style>
    .root {
        display: flex;
        flex-direction: column;
    }
    .table {
        width: 1200px;
        height: 450px;
        display: flex;
        flex-direction: column;
    }
    .chart {
        width: 400px;
        height: 300px;
        display: flex;
        margin: 0 auto;
    }
    .controls-group {
        padding: 5px;
        display: flex;
    }
    .actions-group {
        padding: 2px;
        display: flex;
        justify-content: center;
    }
</style>
