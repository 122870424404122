// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { CatalogItemValueT } from './catalog-item-value.js';
import { CatalogItemValue } from './catalog-item-value.js';


export class PropertyCatalogItemsValue implements flatbuffers.IUnpackableObject<PropertyCatalogItemsValueT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):PropertyCatalogItemsValue {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsPropertyCatalogItemsValue(bb:flatbuffers.ByteBuffer, obj?:PropertyCatalogItemsValue):PropertyCatalogItemsValue {
  return (obj || new PropertyCatalogItemsValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsPropertyCatalogItemsValue(bb:flatbuffers.ByteBuffer, obj?:PropertyCatalogItemsValue):PropertyCatalogItemsValue {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new PropertyCatalogItemsValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value(index: number, obj?:CatalogItemValue):CatalogItemValue|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new CatalogItemValue()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

valueLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

maxCount():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

types(index: number):string
types(index: number,optionalEncoding:flatbuffers.Encoding):string|Uint8Array
types(index: number,optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
}

typesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

assetsTypes(index: number):string
assetsTypes(index: number,optionalEncoding:flatbuffers.Encoding):string|Uint8Array
assetsTypes(index: number,optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
}

assetsTypesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

defaultValues(index: number, obj?:CatalogItemValue):CatalogItemValue|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new CatalogItemValue()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

defaultValuesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startPropertyCatalogItemsValue(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, valueOffset, 0);
}

static createValueVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startValueVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addMaxCount(builder:flatbuffers.Builder, maxCount:number) {
  builder.addFieldInt32(1, maxCount, 0);
}

static addTypes(builder:flatbuffers.Builder, typesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, typesOffset, 0);
}

static createTypesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startTypesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addAssetsTypes(builder:flatbuffers.Builder, assetsTypesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, assetsTypesOffset, 0);
}

static createAssetsTypesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startAssetsTypesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addDefaultValues(builder:flatbuffers.Builder, defaultValuesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, defaultValuesOffset, 0);
}

static createDefaultValuesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startDefaultValuesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endPropertyCatalogItemsValue(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createPropertyCatalogItemsValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset, maxCount:number, typesOffset:flatbuffers.Offset, assetsTypesOffset:flatbuffers.Offset, defaultValuesOffset:flatbuffers.Offset):flatbuffers.Offset {
  PropertyCatalogItemsValue.startPropertyCatalogItemsValue(builder);
  PropertyCatalogItemsValue.addValue(builder, valueOffset);
  PropertyCatalogItemsValue.addMaxCount(builder, maxCount);
  PropertyCatalogItemsValue.addTypes(builder, typesOffset);
  PropertyCatalogItemsValue.addAssetsTypes(builder, assetsTypesOffset);
  PropertyCatalogItemsValue.addDefaultValues(builder, defaultValuesOffset);
  return PropertyCatalogItemsValue.endPropertyCatalogItemsValue(builder);
}

unpack(): PropertyCatalogItemsValueT {
  return new PropertyCatalogItemsValueT(
    this.bb!.createObjList<CatalogItemValue, CatalogItemValueT>(this.value.bind(this), this.valueLength()),
    this.maxCount(),
    this.bb!.createScalarList<string>(this.types.bind(this), this.typesLength()),
    this.bb!.createScalarList<string>(this.assetsTypes.bind(this), this.assetsTypesLength()),
    this.bb!.createObjList<CatalogItemValue, CatalogItemValueT>(this.defaultValues.bind(this), this.defaultValuesLength())
  );
}


unpackTo(_o: PropertyCatalogItemsValueT): void {
  _o.value = this.bb!.createObjList<CatalogItemValue, CatalogItemValueT>(this.value.bind(this), this.valueLength());
  _o.maxCount = this.maxCount();
  _o.types = this.bb!.createScalarList<string>(this.types.bind(this), this.typesLength());
  _o.assetsTypes = this.bb!.createScalarList<string>(this.assetsTypes.bind(this), this.assetsTypesLength());
  _o.defaultValues = this.bb!.createObjList<CatalogItemValue, CatalogItemValueT>(this.defaultValues.bind(this), this.defaultValuesLength());
}
}

export class PropertyCatalogItemsValueT implements flatbuffers.IGeneratedObject {
constructor(
  public value: (CatalogItemValueT)[] = [],
  public maxCount: number = 0,
  public types: (string)[] = [],
  public assetsTypes: (string)[] = [],
  public defaultValues: (CatalogItemValueT)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const value = PropertyCatalogItemsValue.createValueVector(builder, builder.createObjectOffsetList(this.value));
  const types = PropertyCatalogItemsValue.createTypesVector(builder, builder.createObjectOffsetList(this.types));
  const assetsTypes = PropertyCatalogItemsValue.createAssetsTypesVector(builder, builder.createObjectOffsetList(this.assetsTypes));
  const defaultValues = PropertyCatalogItemsValue.createDefaultValuesVector(builder, builder.createObjectOffsetList(this.defaultValues));

  return PropertyCatalogItemsValue.createPropertyCatalogItemsValue(builder,
    value,
    this.maxCount,
    types,
    assetsTypes,
    defaultValues
  );
}
}
