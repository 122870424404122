import type { Builder } from 'flatbuffers';

import { AssetRef } from '../AssetRef';
import { AssetRef as AssetRefF } from '../schema/asset-ref';

export function writeAssetRef(builder: Builder, assetRef: AssetRef) {
    return AssetRefF.createAssetRef(
        builder,
        builder.createString(assetRef.path),
        assetRef.inAssetId ? builder.createString(assetRef.inAssetId) : 0,
    );
}
export function readAssetRef(assetRef: AssetRefF) {
    return new AssetRef(
        assetRef.assetPath()!,
        assetRef.inAssetId()
    )
}

