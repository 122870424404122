<script lang="ts">
import { OpenExternal } from "../../libui/icon/svg";
import SelectItem from "../../libui/icon/svg/selectItem.svelte";

export let text: string;
export let onClick: () => void;
export let secondAction: (() => void) | undefined;
export let disableHover: boolean = false;
</script>

<span class="container" >
    <span class="title">{text}</span>
    <span class="actions">
        {#if !disableHover}
            <span class="icon" on:click={onClick}>
                <SelectItem />
            </span>
        {/if}
        {#if !disableHover && secondAction}
            <span class="icon" on:click={secondAction}>
                <OpenExternal />
            </span>
        {/if}
    </span>
</span>

<style lang="scss">
.icon {
    display: none;
}
.container {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
        .icon {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            margin-left: 4px;
        }
    }
    .actions {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: flex-end;
        .icon {
            transition: 0.2s transform;
            &:hover {
                transform: scale(1.1);
            }
            &:active {
                transition: 0.1s transform;
                transform: scale(1.2);
            }
        }
    }
}
.title {
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
