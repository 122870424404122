import { IterUtils } from "engine-utils-ts";
import { DXFNode, DxfProperties, DxfProperty } from "./DxfNode";


export class DxfSerializer {
    static serialize(dxf: DXFNode): string {
        const builder = this.serializeNode(dxf);
        return builder.build();
    }

    static deserialize(fileString: string): DXFNode {
        fileString = fileString.replaceAll("\r\n", "\n");
        const lines = fileString.split("\n");
        const root: DXFNode = {
            properties: new DxfProperties(),
            children: [],
            type: "DXF",
        };
        let currentProperties: DxfProperties = new DxfProperties();
        let currentNode: DXFNode | null = null;
        const parentStack: DXFNode[] = [root];

        for (let i = 0; i < lines.length; i += 2) {
            const line = lines[i];
            const groupCode = parseInt(line, 10);
            const value = lines[i + 1];

            if (value === "EOF") {
                break;
            }

            if (groupCode === 0 || groupCode === 9) {
                if (currentNode) {
                    currentNode.properties = currentProperties;
                    currentProperties = new DxfProperties();
                    const parentNode = parentStack[parentStack.length - 1];

                    if (
                        currentNode.type === "ENDSEC" ||
                        currentNode.type === "ENDTAB" ||
                        currentNode.type === "ENDBLK"
                    ) {
                        parentNode.closeNode = currentNode;
                        parentStack.pop();
                        currentNode = null;
                    } else if (
                        currentNode.type === "SECTION" ||
                        currentNode.type === "TABLE" ||
                        currentNode.type === "BLOCK"
                    ) {
                        parentStack.push(currentNode);
                    }

                    if (currentNode?.type === "SEQEND") {
                        this.handleSeqend(parentNode, currentNode);
                        currentNode = null;
                    } else if (currentNode) {
                        parentNode.children.push(currentNode);
                    }
                }

                currentNode = {
                    properties: new DxfProperties(),
                    children: [],
                    type: value,
                };
            } else {
                currentProperties.set(groupCode, value, false);
            }
        }

        return root;
    }

    private static handleSeqend(
        parentNode: DXFNode,
        seqendNode: DXFNode
    ): void {
        const ownerHandle = seqendNode.properties.get(330) as string;
        const parentHandle = parentNode.properties.get(5) as string;
        if (parentHandle === ownerHandle) {
            return;
        }

        const collectedNodes: DXFNode[] = [];
        let targetNode: DXFNode | null = null;

        for (let i = parentNode.children.length - 1; i >= 0; i--) {
            const child = parentNode.children[i];

            if (child.type === "INSERT" || child.type === "POLYLINE") {
                const childHandle = child.properties.get(5) as string;
                if (childHandle === ownerHandle) {
                    targetNode = child;
                    break;
                }
            } else {
                collectedNodes.unshift(child);
            }
        }

        if (!targetNode) {
            return;
        }

        parentNode.children.splice(
            parentNode.children.length - collectedNodes.length
        );
        targetNode.children.push(...collectedNodes);
        targetNode.closeNode = seqendNode;
    }

    private static serializeNode(dxfNode: DXFNode) {
        const builder = new DxfBuilder();
        const type = dxfNode.type;
        if (type !== undefined) {
            const typeCode = type.startsWith("$") ? 9 : 0;
            builder.addPart(typeCode, type);
        }

        for (const prop of dxfNode.properties.entries()) {
            builder.addPart(prop.code, prop.value);
        }

        for (const child of dxfNode.children) {
            builder.merge(this.serializeNode(child));
        }

        if (dxfNode.closeNode !== undefined) {
            builder.merge(this.serializeNode(dxfNode.closeNode));
        }
        return builder;
    }
}


type dxfEntityProperty = { code: number; value: string | number };

class DxfBuilder {
    private _parts: dxfEntityProperty[] = [];

    constructor(...parts: dxfEntityProperty[]) {
        this._parts = parts;
    }

    addPart(codeOrProperty: DxfProperty): void;
    addPart(codeOrProperty: number, value: string | number): void;
    addPart(
        codeOrProperty: number | DxfProperty,
        value?: string | number
    ): void {
        if (codeOrProperty instanceof DxfProperty && value === undefined) {
            if (codeOrProperty.property !== null) {
                this._parts.push(codeOrProperty.property);
            }
        } else if (typeof codeOrProperty === "number" && value !== undefined) {
            this._parts.push({ code: codeOrProperty, value: value });
        }
    }

    addXData(...parts: dxfEntityProperty[]) {
        this.addPart(1002, "{");
        for (const part of parts) {
            this.addPart(part.code, part.value);
        }
        this.addPart(1002, "}");
    }

    merge(builder: DxfBuilder) {
        IterUtils.extendArray(this._parts, builder._parts);
    }

    startSection(sectionName: string) {
        this.addPart(0, "SECTION");
        this.addPart(2, sectionName);
    }

    endSection() {
        this.addPart(0, "ENDSEC");
    }

    startTable(tableName: string, handle: DxfProperty) {
        this.addPart(0, "TABLE");
        this.addPart(2, tableName);
        this.addPart(handle);
        this.addPart(100, "AcDbSymbolTable");
    }
    endTable() {
        this.addPart(0, "ENDTAB");
    }

    build(): string {
        return this._parts
            .map((part) => `${part.code}\n${part.value}`)
            .join("\n");
    }
}
