import { UPNG } from './3rdParty/UPNG';

export class PngConverter {
    static decode(buff:any){
        return UPNG.decode(buff);
    }
    static encode(bufs:ArrayBuffer[], w:number, h:number, ps:number|null = 0, dels:number[] = [], forbidPlte:boolean|null = false):ArrayBuffer{
        return UPNG.encode(bufs, w, h, ps, dels, forbidPlte);
    }
    static toRGBA8(out:any){
        return UPNG.toRGBA8(out);
    }
}
