
import { Vector4 } from 'math-ts';

import type { ShaderBase } from './ShaderBase';

export const RotationGizmoShader: ShaderBase = {
	name: 'RotationGizmoShader',
	
	uniforms: {
		'steps': { value: Vector4.allocate(5, 15, 45, 90)},
		'colorOpacity': { value: Vector4.allocate(1, 1, 1, 1) },
		'activeRange': { value: new Vector4(0, 0, 0, 1) },
	},

	vertexShader: `
		varying vec2 U;

		void main() {
			vec3 pos = position;
			U = vec2(-pos.y, pos.x);

			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
		}

	`,

	fragmentShader: `
		uniform vec4 steps;
		uniform vec4 activeRange;
		uniform vec4 colorOpacity;
	
		varying vec2 U;

		
		float snatchValue(float rulerValue, float snatchInterval, float snatchWidth, float aa) {
			float nextSnatch = ceil(rulerValue / snatchInterval) * snatchInterval;
			float prevSnatch = floor(rulerValue / snatchInterval) * snatchInterval;
			float distFromSnatch = min(nextSnatch - rulerValue, rulerValue - prevSnatch);
			float alpha = smoothstep(0.5 * (snatchWidth + aa), 0.5 * (snatchWidth - aa), distFromSnatch);
			return alpha;
		}

		void main() {
			float angle = atan(U.x, U.y) * 57.295779 + 180.0;
			float aa = length(fwidth(angle));

			float angleDiff = 180.0 - abs(abs(angle - activeRange.x) - 180.0);

			vec4 activeColor;
			{
				float lineValue = snatchValue(angle, steps.x, steps.x * 0.3, aa) * activeRange.z;
				activeColor.xyz = mix(colorOpacity.xyz, vec3(1.0, 1.0, 1.0), lineValue);
				activeColor.w = 1.0;
			}
			
			vec4 inactiveColor;
			{
				float lineValue = snatchValue(angle, steps.x, 2.0, aa) * 0.3;
				inactiveColor.xyz = mix(colorOpacity.xyz,  vec3(1.0, 1.0, 1.0), lineValue);
				inactiveColor.w = colorOpacity.w * activeRange.w;
			}


			float inActiveRange = smoothstep(1.0, 0.0, angleDiff - activeRange.y); 
			vec4 outColor = mix(inactiveColor, activeColor, inActiveRange);

			float segmentWidth = mix(0.03, 0.1, inActiveRange);
			float radius = length(U);
			float inCircle = snatchValue(radius, 0.9, segmentWidth, length(fwidth(radius)));
			outColor.w *= inCircle;

			gl_FragColor = outColor;
		}
	`
};
