<script lang="ts">

import { setContext } from 'svelte';
import VerDataUi from "../verdata/VerDataUi.svelte";
import PUI_Root from "../pui/PUI_Root.svelte";

import SceneExplorer from "../scene-explorer/SceneExplorer.svelte";
import { WiresRouterMenu as WiresRouter } from '../components/menus/wires-router'
import QuantityTable from "../quantities-table/components/QuantityTableComponent.svelte";
import { VerDataSyncer } from "verdata-ts";
import TerrainAnalysisComponent from "../terrain-analysis/TerrainAnalysisComponent.svelte";
import EquipmentSlopeAnalysisComponent from "../equipment-slope-analysis/EquipmentSlopeAnalysisComponent.svelte";
import { CatalogItemManagerInScene } from "../catalog";
import ProjectPanel from "../project-panel/ProjectPanel.svelte";
import { ComparisonModeMenu } from "../ComparisonMode";
import { UiCustomNode, type UiViewSource } from "ui-bindings";
import SelectionProps from '../scene-selection-props/SelectionProps.svelte';
import CostReportMenu from "../cost-report/CostReportMenu.svelte";
import PropsCharts from '../props-charts/PropsCharts.svelte';
import { PropsChartsContext } from '../props-charts/PropsChartsContext';
import EnergyView from '../energy-view/EnergyView.svelte';
import { EnergyViewContext } from '../energy-view/EnergyViewContext';
import ImportPanel from '../files-imports-exports/ImportPanel.svelte';
import { PanelPosition } from '../navbar/Navbar';
import EngineUiPanel from './EngineUiPanel.svelte';
import TerrainPilesView from '../terrain-analysis/TerrainPilesView.svelte';
import FarmLayoutView from '../layout/FarmLayoutView.svelte';
import ExportPanel from '../files-imports-exports/ExportPanel.svelte';
import { PileOffsetContext, WindLoadVariantSettingsContext } from '../piles-positions/PilePositions';
import WindLoadVariantSettings from '../piles-positions/WindLoadVariantSettings.svelte';
import { EditTrackerPilesContext } from 'bim-ts';
import PilePositions from '../piles-positions/PilePositions.svelte';
import PilesView from '../piles/PilesView.svelte';
import PileOffsetContextMenu from '../piles-positions/tracker-scheme/PileOffsetContextMenu.svelte';


export let identifier: string = "";
export let uiNodeDescription: UiViewSource<any>;

export let name: string;
export let position: PanelPosition | undefined = undefined;
export let close: (() => void) | undefined;
export let movePointerDown: ((e: MouseEvent) => void) | undefined = undefined;
export let resizePointerDown: ((e: MouseEvent) => void) | undefined = undefined;
export let inContextMenu: boolean = false;

setContext('panel', {
    identifier,
    name,
    position,
    close,
    move: movePointerDown,
    resize: resizePointerDown
});
</script>

{#if uiNodeDescription instanceof UiCustomNode }
    {#if uiNodeDescription.context instanceof VerDataSyncer}
        <VerDataUi syncer={uiNodeDescription.context} />
    {:else if uiNodeDescription.context instanceof PropsChartsContext}
        <PropsCharts context={uiNodeDescription.context} />
    {:else if uiNodeDescription.context instanceof EnergyViewContext}
        <EnergyView context={uiNodeDescription.context} />
    {:else if uiNodeDescription.context instanceof PileOffsetContext}
        <PileOffsetContextMenu context={uiNodeDescription.context} />
    {:else if uiNodeDescription.context instanceof WindLoadVariantSettingsContext}
        <WindLoadVariantSettings context={uiNodeDescription.context} />
    {:else if uiNodeDescription.context instanceof EditTrackerPilesContext}
        <PilePositions context={uiNodeDescription.context} />
    {:else if identifier == 'View.Model Explorer'}
        <SceneExplorer/>
    {:else if identifier == 'Generate.LV Wiring 2D'}
        <WiresRouter/>
    {:else if identifier == 'View.Quantities'}
        <QuantityTable/>
    {:else if identifier == 'View.Terrain Analysis'}
        <TerrainAnalysisComponent/>
    {:else if identifier == 'View.Equipment Slope Analysis'}
        <EquipmentSlopeAnalysisComponent/>
    {:else if identifier == 'View.Catalog'}
        <CatalogItemManagerInScene />
    {:else if identifier == 'View.Project'}
        <ProjectPanel />
    {:else if identifier == 'View.Comparison'}
        <ComparisonModeMenu />
    {:else if identifier == 'View.Properties'}
        <SelectionProps />
    {:else if identifier == 'View.Cost Report'}
        <CostReportMenu />
    {:else if identifier == 'Add.Import'}
        <ImportPanel />
    {:else if identifier == 'Project.Export'}
        <ExportPanel />
    {:else if identifier == 'View.Piles'}
        <PilesView />
    {:else}
        {console.error('unrecognized ui panel identifier', identifier)}
    {/if}
{:else }
    {#if identifier == 'Generate.Equipment Placement'}
        <TerrainPilesView puiSource={uiNodeDescription} />
    {:else if identifier == 'Generate.Farm Layout'}
        <FarmLayoutView puiSource={uiNodeDescription} />
    {:else}
        {#if inContextMenu}
            <PUI_Root
                configIdentifier={identifier}
                puiSource={uiNodeDescription}
            />
        {:else}
            <EngineUiPanel>
                <PUI_Root
                    configIdentifier={identifier}
                    puiSource={uiNodeDescription}
                />
            </EngineUiPanel>
        {/if}
    {/if}
{/if}
