import { KrMath } from 'math-ts';
import { DefaultMapObjectKey, LegacyLogger, WorkerClassPassRegistry } from "engine-utils-ts";

// export type ShadingAnglePacked = number;

// export function packShadingAngle(height: number, azimuth: number): ShadingAnglePacked {
//     height = Math.round(height * 10); // to allow decimal values
//     azimuth = Math.round(azimuth * 10);
//     if ((height | 0) !== height) {
//         throw new Error(`invalid height ${height}`);
//     }
//     if ((azimuth | 0) !== azimuth) {
//         throw new Error(`invalid azimuth ${azimuth}`);
//     }
//     return (azimuth & 0xFFFF) | (height << 16);
// }
// export function unpackShadingAngle(packed: ShadingAnglePacked): {height: number, azimuth: number} {
//     const height = (packed & 0xFFFF0000) >> 16;
//     const azimuth = packed & 0x0000FFFF;
//     return {height, azimuth};
// }

export class PerHeightSamples {
    constructor(
        readonly height: number,
        readonly step: number,
        readonly samples: number[],
    ) {
        if (height < 0 || height > 90) {
            throw new Error('invalid height ' + height);
        }
        const expectedSamplesCount = 360 / step;
        if (expectedSamplesCount !== samples.length) {
            throw new Error('invalid samples count');
        }
        Object.freeze(this);
    }

    sampleAt(azimuthDeg: number): number {
        azimuthDeg = (azimuthDeg + 360) % 360;
        if (azimuthDeg < 0 || azimuthDeg >= 360) {
            throw new Error('invalid azimuth ' + azimuthDeg);
        }
        const index0 = Math.floor(azimuthDeg / this.step);
        const index1 = Math.ceil(azimuthDeg / this.step);
        const ratio = (azimuthDeg - index0 * this.step) / this.step;
        const sample0 = this.samples[index0];
        const sample1 = index1 >= this.samples.length ? this.samples[0] : this.samples[index1];
        let res = sample0 * (1 - ratio) + sample1 * ratio;
        if (!Number.isFinite(res)) {
            LegacyLogger.deferredError('invalid shading factor computed', [this, azimuthDeg, res]);
            res = 0;
        }
        return res;
    }

    equals(other: PerHeightSamples): boolean {
        if (other === this) {
            return true;
        }
        if (this.height !== other.height) {
            return false;
        }
        if (this.step !== other.step) {
            return false;
        }
        if (this.samples.length !== other.samples.length) {
            return false;
        }
        for (let i = 0; i < this.samples.length; i++) {
            if (this.samples[i] !== other.samples[i]) {
                return false;
            }
        }
        return true;
    }
}
WorkerClassPassRegistry.registerClass(PerHeightSamples);

export class ShadingFactorsTable {

    constructor(
        public readonly perHeightSamples: PerHeightSamples[],
    ) {
        Object.freeze(this);
    }

    equals(other: ShadingFactorsTable): boolean {
        if (this === other) {
            return true;
        }
        if (this.perHeightSamples.length !== other.perHeightSamples.length) {
            return false;
        }
        for (let i = 0; i < this.perHeightSamples.length; i++) {
            if (!this.perHeightSamples[i].equals(other.perHeightSamples[i])) {
                return false;
            }
        }
        return true;
    }
    
    sampleAt(heightDeg: number, azimuthDeg: number): number {
        if (heightDeg < 0 || heightDeg > 90) {
            throw new Error('invalid height ' + heightDeg);
        }
        for (let i = 1; i < this.perHeightSamples.length; ++i) {
            const phs0 = this.perHeightSamples[i - 1];
            const phs1 = this.perHeightSamples[i];
            if (heightDeg >= phs0.height && heightDeg <= phs1.height) {
                const ratio = (heightDeg - phs0.height) / (phs1.height - phs0.height);
                return phs0.sampleAt(azimuthDeg) * (1 - ratio) + phs1.sampleAt(azimuthDeg) * ratio;
            }
        }
        LegacyLogger.deferredError('could not find requested sample', heightDeg);
        return 0;
    }

    ambientShadingFactor(): number {
        let shadingSum = 0;
        let samplesCount = 0;
        for (let height = 1; height <= 90; height += 2) {
            const samplesCountAtThisHeight = (36 * Math.cos(KrMath.degToRad(height))) || 1;
            const azIncrement = 360 / samplesCountAtThisHeight;

            for (let azimuth = 0; azimuth < 360; azimuth += azIncrement) {
                const sample = this.sampleAt(height, azimuth);
                samplesCount += 1;
                shadingSum += sample;
            }
        }
        const ambientShadingFactor = shadingSum / samplesCount;
        return ambientShadingFactor;
    }
}
WorkerClassPassRegistry.registerClass(ShadingFactorsTable);

export const shadingPerHeightSamplesDedup = new DefaultMapObjectKey<PerHeightSamples, PerHeightSamples>({
    unique_hash: (v: PerHeightSamples) => {
        return v.height + ':' + v.step + ':' + v.samples.join(',');
    },
    valuesFactory: (v: PerHeightSamples) => {
        return Object.freeze(v);
    }
});
(globalThis as any).__shadingPerHeightSamplesDedup = shadingPerHeightSamplesDedup;

// export const shadingTablesDedup = new LruCache<ShadingFactorsTable, ShadingFactorsTable>({
//     unique_hash: (v: ShadingFactorsTable) => {
//         return v.join(',');
//     },
//     valuesFactory: (v: ShadingFactorsTable) => {
//         return Object.freeze(v) as number[];
//     }
// });


export class ShadingTableSampler {

    private constructor(
        readonly heightStep: number,
        readonly azimuthStep: number,
        readonly factors: number[],
        readonly heightsCount: number,
        readonly azimuthsCount: number,
    ) {
    }

    static new(sft: ShadingFactorsTable|null) {
        if (sft == null || sft.perHeightSamples.length === 0) {
            return null;
        }
        let minHeightStep: number = 90;
        let minAzStep: number = 360;
        for (let i = 1; i < sft.perHeightSamples.length; ++i) {
            const phs0 = sft.perHeightSamples[i - 1];
            const phs1 = sft.perHeightSamples[i];
            const heightStep = phs1.height - phs0.height;
            if (heightStep < minHeightStep) {
                minHeightStep = heightStep;
            }
            if (phs0.step < minAzStep) {
                minAzStep = phs0.step;
            }
        }
        const heightRegularSamplesCount = 90 / minHeightStep + 1;
        const azimuthRegularSamplesCount = 360 / minAzStep + 1;
        const regularGridSamplesCount = heightRegularSamplesCount * azimuthRegularSamplesCount;
        const factorsArr = new Array(regularGridSamplesCount);
        for (let heightI = 0; heightI < heightRegularSamplesCount; heightI += 1) {
            for (let azimuthI = 0; azimuthI < azimuthRegularSamplesCount; azimuthI += 1) {

                const index = heightI * azimuthRegularSamplesCount + azimuthI;
                if (index > factorsArr.length) {
                    throw new Error(`calculated sampler index out of bounds: ${index} out of ${factorsArr.length}`);
                }
                factorsArr[index] = sft.sampleAt(heightI * minHeightStep, azimuthI * minAzStep);
            }
        }
        return new ShadingTableSampler(minHeightStep, minAzStep, factorsArr, heightRegularSamplesCount, azimuthRegularSamplesCount);
    }

    sampleAt(heightDeg: number, azimuthDeg: number): number {
        azimuthDeg = (azimuthDeg + 360) % 360;
        if (heightDeg < 0 || heightDeg > 90) {
            throw new Error('invalid height ' + heightDeg);
        }
        if (azimuthDeg < 0 || azimuthDeg > 360) {
            throw new Error('invalid azimuth ' + azimuthDeg);
        }
        const heightIndex0 = Math.floor(heightDeg / this.heightStep);
        const heightIndex1 = Math.ceil(heightDeg / this.heightStep);
        const heightRatio = (heightDeg - heightIndex0 * this.heightStep) / this.heightStep;
        const azimuthIndex0 = Math.floor(azimuthDeg / this.azimuthStep);
        const azimuthIndex1 = Math.ceil(azimuthDeg / this.azimuthStep);
        const azimuthRatio = (azimuthDeg - azimuthIndex0 * this.azimuthStep) / this.azimuthStep;
        
        const index00 = heightIndex0 * this.azimuthsCount + azimuthIndex0;
        const index01 = heightIndex0 * this.azimuthsCount + azimuthIndex1;
        const index10 = heightIndex1 * this.azimuthsCount + azimuthIndex0;
        const index11 = heightIndex1 * this.azimuthsCount + azimuthIndex1;

        const factor00 = this.factors[index00];
        const factor01 = this.factors[index01];
        const factor10 = this.factors[index10];
        const factor11 = this.factors[index11];

        const factor0 = factor00 * (1 - azimuthRatio) + factor01 * azimuthRatio;
        const factor1 = factor10 * (1 - azimuthRatio) + factor11 * azimuthRatio;
        const result = factor0 * (1 - heightRatio) + factor1 * heightRatio;
        return result;
    }
}


export class ShadingFactorsMerger {

    _mergedCount = 0;
    _errorsCount = 0;

    _shadedModulesArea = 0;

    perHeightSamples?: {
        height: number,
        step: number,
        samples: number[],
    }[];

    constructor(
    ) {
    }

    mergeIn(shadingFactors: ShadingFactorsTable|null, multiplier: number, area: number) {
        if (shadingFactors == null) {
            this._errorsCount += 1;
            return;
        }
        if (this.perHeightSamples === undefined) {
            this.perHeightSamples = shadingFactors.perHeightSamples.map(phs => ({
                height: phs.height,
                step: phs.step,
                samples: phs.samples.map(() => 0),
            }));
        } else if (this.perHeightSamples.length !== shadingFactors.perHeightSamples.length) {
            console.warn('shading factors with different dimensions');
            this._errorsCount += 1;
            return;
        }
        const areaToAdd = multiplier * area;
        for (let heightIndex = 0; heightIndex < this.perHeightSamples.length; ++heightIndex) {
            const thisPhs = this.perHeightSamples[heightIndex];
            const shadingFactorsPhs = shadingFactors.perHeightSamples[heightIndex];
            if (thisPhs.samples.length === shadingFactorsPhs.samples.length) {
                for (let i = 0; i < thisPhs.samples.length; ++i) {
                    thisPhs.samples[i] += (shadingFactorsPhs.samples[i] ?? 0) * areaToAdd;
                }
            } else {
                for (let i = 0; i < thisPhs.samples.length; ++i) {
                    thisPhs.samples[i] += shadingFactorsPhs.sampleAt(i * thisPhs.step) * areaToAdd;
                }
            }
        }
        this._mergedCount += 1;
        this._shadedModulesArea += (areaToAdd);
    }

    shadedModulesArea() {
        return this._shadedModulesArea;
    }


    finish(): ShadingFactorsTable | null {
        if (this.perHeightSamples === undefined) {
            return null;
        }
        const perHeightSamples = this.perHeightSamples.map(
            phs => new PerHeightSamples(phs.height, phs.step, phs.samples.map(v => v / this._shadedModulesArea))
        );
        return new ShadingFactorsTable(perHeightSamples);
    }
}

