import {TerrainDisplaySlopeSelector, TerrainGeoVersionSelector} from 'bim-ts';


export enum TerrainDisplayMode {
	BasicTransparent = 0,
	Elevation,
	Slope,
	CutFill,
}


export class TerrainDisplayEngineSettings {
	terrainVersion: TerrainGeoVersionSelector = TerrainGeoVersionSelector.Latest;
	mode: TerrainDisplayMode = TerrainDisplayMode.BasicTransparent;
	slopeSelector: TerrainDisplaySlopeSelector = TerrainDisplaySlopeSelector.NS;
	showGrid: number = 1;
	opacity: number = 1;
}
