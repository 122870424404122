import type {
	CachingNetworkClient,
	ProjectNetworkClient, ScopedLogger, SyncHub, Yield} from 'engine-utils-ts';
import { DefaultMap, ErrorUtils, Failure, IterUtils, LazyMapBulk, ObjectUtils, ObservableObject, WorkerPool
} from 'engine-utils-ts';

import { calculateNewBatchesCollection } from './CollectionVersionBuilder';
import type { IdVersionPacked} from './CompactVersionId';
import {
	getIdFromIDV, getVersionFromIDV, newCompactIDV,
} from './CompactVersionId';
import {
	RuntimeObjectsVersionsProvider,
} from './RuntimeObjectsVersionsProvider';
import type {
	PersistedCollectionConfig,
	VerDataPersistedCollection,
} from './VerDataPersistedCollection';
import type { Id, ObjectVersion, ObjectVersionsRange } from './VerDataSyncerImpl';
import { VerdataUrls } from './VerdataUrls';
import { VerdataUtils } from './VerdataUtils';
import type { CompressionType } from './wire/compression-type';
import { BatchContainer, BatchContainerSerializer } from './WireBatchContainer';
import type {
	BatchDescription} from './WireCollectionHistory';
import { BatchPartialReference, CollectionHistory,
	CollectionHistorySerializer, CollectionVersion,
} from './WireCollectionHistory';
import {
	compressBinarySync, decompressAndDeserialize, decompressBinary,
} from './WireCommon';
import { MaxVersionsPerIdJobExecutor, type MaxVersionsPerIdJobExecutorArgs } from './CompressedIntegersInMemory';

export interface DiffToVersionPotential {
    targetVersionId: number,
    targetVersionContext: any,
    versionInv: any,
    delete: Id[],
    theSame: Id[],
    batchesToLoad: Map<string, {toAlloc: Id[], toReconcile: Id[]}>,
}

export interface DiffToVersionReal<D, Context> {
    targetVersionId: number,
    targetVersionContext: Context,
    delete: Id[],
    allocate: [Id, number, D][],
    update: [Id, number,  D][],
}

export interface ObjectsToLoad {
    versions: ObjectsInVersionToLoad[],
    versionInv: any,
}

export interface ObjectsInVersionToLoad {
    targetVersionId: number,
    targetVersionContext: any,
    objects: Id[],
    batchesToLoad: Map<string, Id[]>,
}

export class CollectionSyncer<D extends Object> {

    readonly config: PersistedCollectionConfig;
    readonly collection: VerDataPersistedCollection<D, any>;

    objectsVersions: RuntimeObjectsVersionsProvider<D>;
    readonly _objsEqComparator: (d1: D, d2: D) => boolean;

    _history: ObservableObject<CollectionHistory>;

    _lastLoadedVersion: number = 0;
    // _prevSaved: { invalidator: any, verions: number } | null = null;

    constructor(
        logger: ScopedLogger,
        config: Partial<PersistedCollectionConfig>,
        versionedCollection: VerDataPersistedCollection<D, any>,
    ) {
        if (!config.identifier) {
            throw new Error('provide collection identifier');
        }
        this.config = {
            identifier: config.identifier,
            loadAfter: config.loadAfter ?? [],
            loadInOneTickWith: config.loadInOneTickWith ?? [],
            objectCountInBatchHint: config.objectCountInBatchHint ?? 1000,
        }
        this.collection = versionedCollection;

        if (this.collection.getAllIds().length !== 0) {
            logger.error('collection should be empty at the initializtion time', this.collection);
        }

        this._history = new ObservableObject({
            logger: logger.newScope(`${config.identifier}_sync`),
			identifier: VerdataUrls.collectionHistorySyncIdentifier(config.identifier),
            initialState: new CollectionHistory(config.identifier, [], []),
            serializer: new CollectionHistorySerializer()
        });

        this.objectsVersions = new RuntimeObjectsVersionsProvider(
            config.identifier,
            versionedCollection.updates
        );
        this._objsEqComparator = versionedCollection.dataEq ?? ObjectUtils.areObjectsEqual;
    }

    async fetchHistory(networkClient: ProjectNetworkClient): Promise<CollectionHistory> {
        const historyFetch = networkClient.get(VerdataUrls.collectionHistorySyncIdentifier(this.config.identifier));
        const history = VerdataUtils.deserializeAsync<CollectionHistory>(
            new CollectionHistorySerializer(),
            VerdataUtils.bytesFromResponse(historyFetch)
        );
        return history;
    }
    
    async initialize(syncHub: SyncHub) {
        await this._history.attachToSyncHub(syncHub);
        const jobArgs: MaxVersionsPerIdJobExecutorArgs = {
            idsVersions: [],
        };
        for (const bd of this._history.poll().allBatchesPerGuid.values()) {
            jobArgs.idsVersions.push({compressedIds: bd.compressedIds, versions: bd.compressedVersions});
        }
        const versionPerId = await WorkerPool.execute(MaxVersionsPerIdJobExecutor, jobArgs);

        let maxId = 0;
        for (const [id, version] of versionPerId) {
            this.objectsVersions.markVersionOccupied(id, version);
            maxId = Math.max(id, maxId);
        }
        if (maxId) {
            this.collection.getIdsProvider().markOccupied(maxId);
        }
    }

    dispose() {
        this._history.dispose();
        this.objectsVersions.dispose();
    }

    _assertIsInit() {
        if (!this._history || !this.objectsVersions) {
            throw new Error('collection not initialized');
        }
    }

    calculatePotentialDiffToVersion(logger: ScopedLogger, collectionVersionId: number): DiffToVersionPotential {
        this._assertIsInit();
        logger = logger.newScope(this.config.identifier);
        let cv = this._history.poll().versions.find(c => c.id == collectionVersionId)!;
        if (!cv) {
            if (collectionVersionId == 0) {
                cv = new CollectionVersion(0, 0, new Date(), [], null);
            } else {
                throw new Error(`invalid collection version ${collectionVersionId}`);
            }
        }
        const {
            versionInv,
            currVersionWithDirtyFlagPerId,
            dataPerIdV
        } = this._calcCurrentObjectsState(logger);

        const toLoad = CollectionVersion.getIdsVersionsInside(logger, cv, this._history.poll().allBatchesPerGuid);
        const diffToNew = CollectionsObjectsDiff.calculateDiff(currVersionWithDirtyFlagPerId, toLoad.perIdVersion);
        logger.debug('diff to new', diffToNew);

        const perBatch: Map<string, { toAlloc: Id[], toReconcile: Id[] }> =
            diffToNew.selectBatchesThatNeedLoading(toLoad.perBatchIds);
        
        const context = this.parseContext(cv, logger);
        
        return {
            targetVersionId: collectionVersionId,
            targetVersionContext: context,
            versionInv,
            theSame: diffToNew.theSame,
            delete: diffToNew.toDelete,
            batchesToLoad: perBatch
        };
    }

    private parseContext(cv: CollectionVersion, logger: ScopedLogger) {
        const contextBinary = cv.additionalContext;
        let context: any = undefined;
        if (contextBinary) {
            const [ct, bin] = contextBinary;
            const serializer = this.collection.getContextSerializer();
            if (!serializer) {
                logger.error('hash context, but not context serializer', contextBinary, cv, this.collection);
                throw new Error(`${logger.scopeMsg} context serializer required`);
            }
            context = serializer.deserialize(decompressBinary(ct, bin));
        }
        return context;
    }

    extractObjsVersionsPerIdForVersion(logger: ScopedLogger, projectVersion: number): Map<number, number> {
        this._assertIsInit();

        const version = this._history.poll().findVersionForProjectId(projectVersion);
        if (!version) {
            logger.error(
                `version ${projectVersion} not found in colletion ` + this.config.identifier,
            );
            return new Map<number, number>();
        }
        const allIdsInVersion = CollectionVersion.getIdsVersionsInside(logger, version, this._history.poll().allBatchesPerGuid);
        return allIdsInVersion.perIdVersion;
    }

    findObjects(logger: ScopedLogger, objs: number[], lastProjVersion: number): ObjectVersionsRange[] {
        this._assertIsInit();
        const objectsToFind = new Set<number>(objs);        

        const perProjectVersionMap = new Map<number, CollectionVersion>();
        for (const version of this._history.poll().versions) {
            perProjectVersionMap.set(version.projectVersion, version);
        }
        const perProjectVersion = Array.from(perProjectVersionMap).sort((a, b)=> a[0] - b[0]);
        
        const allBatchesPerGuid = this._history.poll().allBatchesPerGuid;
        const idPerVersions = new DefaultMap<number, Set<number>>(() => new Set());
        for (let index = 0; index < perProjectVersion.length; index++) {
            const [projVer, collVersion] = perProjectVersion[index];
            const allIdsInVersion = CollectionVersion.getIdsVersionsInside(
                logger,
                collVersion,
                allBatchesPerGuid
            );
            const idAndVersion = perProjectVersion[index - 1];
            if(index > 0 && idAndVersion[0] != projVer - 1){
                for (const [_, versions] of idPerVersions) {
                    if(!versions.has(idAndVersion[0])){
                        continue;
                    }
                    const startVer = idAndVersion[0] + 1;
                    const versionWithoutChanges = IterUtils.newArrayWithIndices(startVer, projVer - startVer);
                    IterUtils.extendSet(versions, versionWithoutChanges);
                }
            }
            for (const id of objectsToFind) {
                if (allIdsInVersion.perIdVersion.has(id)) {
                    const versions = idPerVersions.getOrCreate(id);
                    versions.add(projVer);
                }
            }
        }
        const [previousVersion] = perProjectVersion[perProjectVersion.length - 1];
        if(previousVersion != lastProjVersion){
            for (const [_, versions] of idPerVersions) {
                if(!versions.has(previousVersion)){
                    continue;
                }
                const versionWithoutChanges = IterUtils.newArrayWithIndices(previousVersion + 1, lastProjVersion - previousVersion);
                IterUtils.extendSet(versions, versionWithoutChanges);
            }
        }

        const objectsVersionRanges:ObjectVersionsRange[] = [];
        for (const [id, versions] of idPerVersions) {
            objectsVersionRanges.push({
                id,
                projectVersionRange: [IterUtils.min(versions)!, IterUtils.max(versions)!]
            })
        }
        
        if (objectsToFind.size !== idPerVersions.size) {
            logger.error(
                "Objects not found in collection - " + this.config.identifier,
                IterUtils.filter(objectsToFind, id => !idPerVersions.has(id))
            );
        }
        
        return objectsVersionRanges;
    }

    findBatchesWithObjects(logger: ScopedLogger, objIdPerVersion: ObjectVersion[]): ObjectsToLoad {
        this._assertIsInit();

        logger = logger.newScope(this.config.identifier);

        const {
            versionInv,
        } = this._calcCurrentObjectsState(logger);
        
        const objectsToLoad : ObjectsToLoad = {
            versionInv: versionInv,
            versions: [],
        };

        const perVersionIdsToLoad = new DefaultMap<number, Set<number>>(() => new Set());
        for (const obj of objIdPerVersion) {
            const ids = perVersionIdsToLoad.getOrCreate(obj.projectVersion);
            ids.add(obj.id);
        }
        const history = this._history.poll();
        for (const [projectVerson, idsInVersionToLoad] of perVersionIdsToLoad) {
            const collectionVersion = history.findVersionForProjectId(projectVerson);
            if(!collectionVersion){
                ErrorUtils.logThrow(`project version ${projectVerson} not found in collection - ` + this.config.identifier);
            }
            const allIdsInVersion = CollectionVersion.getIdsVersionsInside(logger, collectionVersion, history.allBatchesPerGuid);
   

            const context = this.parseContext(collectionVersion, logger);

            const objectsInVersion: ObjectsInVersionToLoad = {
                targetVersionId: collectionVersion.projectVersion,
                targetVersionContext: context,
                objects: Array.from(idsInVersionToLoad),
                batchesToLoad: new Map(),
            };
            for (const [batch, ids] of allIdsInVersion.perBatchIds) {
                const idsInBatch = IterUtils.filter(ids, id => idsInVersionToLoad.has(id));
                if (!idsInBatch.length) {
                    continue;
                }
                
                let idsToLoad = objectsInVersion.batchesToLoad.get(batch);
                if (!idsToLoad) {
                    idsToLoad = [];
                    objectsInVersion.batchesToLoad.set(batch, idsToLoad);
                }
                for (const id of idsInBatch) {
                    idsToLoad.push(id);
                }
            }
            if(!objectsInVersion.batchesToLoad.size){
                logger.error(`Not found batches to load in version - `, projectVerson);
            }
            objectsToLoad.versions.push(objectsInVersion);

            if(objectsInVersion.objects.length !== IterUtils.sum(objectsInVersion.batchesToLoad, ([_, objs]) => objs.length)) {
                const inBatches = new Set<number>();
                for (const [_, ids] of objectsInVersion.batchesToLoad) {
                    IterUtils.extendSet(inBatches, ids);
                }
                const notFound: number[] = [];
                for (const id of idsInVersionToLoad) {
                    if (!inBatches.has(id)) {
                        notFound.push(id);
                    }
                }
                ErrorUtils.logThrow('objects not found in collection - ' + this.config.identifier, notFound);
            }
        }
        
        return objectsToLoad;
    }

    async fetchBatch(client: CachingNetworkClient, guid: string): Promise<BatchContainer> {
        // console.assert(res.includes(guid) == false, 'batches fetch guids sanity check');
        const bd = this._history.poll().allBatchesPerGuid.get(guid);
        if (!bd) {
            return Promise.reject(`unknown batch reference to load:${guid}`);
        }
        const respPromise = client.get(VerdataUrls.batchUrl(bd.filename));
        return VerdataUtils.deserializeAsync(
            new BatchContainerSerializer(),
            VerdataUtils.bytesFromResponse(respPromise)
        )
    }

    *deserializeItems(objsToLoadPerBatch: Map<string, number[]>, batches: BatchContainer[], logger: ScopedLogger) {
        const serializer = this.collection.getObjectsPickingSerializer();
        const deserializeInPool = this.collection.serializeInWorkerPool();

        const results = yield* IterUtils.asyncMap({
            arrayToConsume: batches,
            maxConcurrency: 10,
            mapFn: async (b) => {
                const objsToLoad = objsToLoadPerBatch.get(b.batchGuid);
                if (!objsToLoad) {
                    ErrorUtils.logThrow(
                        'loaded batches and diff to load should have corresponding to each other batches',
                        objsToLoadPerBatch,
                        b.batchGuid,
                        objsToLoad
                    );
                }
                const deserialized = await decompressAndDeserialize({
                    binary: b.payload,
                    compressionType: b.payloadCompression,
                    objsIdsToDeserialize: objsToLoad,
                    serializer: serializer,
                    inWorkerPool: deserializeInPool,
                });

                const bd = this._history.poll().allBatchesPerGuid.get(b.batchGuid)!;

                const bdIdsVersions: Map<Id, number> = bd.versionPerId();

                const toAllocate: [Id, number, D][] = [];
                for (const [id, obj] of deserialized) {
                    if (obj === null) {
                        continue;
                    }
                    const version = bdIdsVersions.get(id);
                    if (version == undefined) {
                        ErrorUtils.logThrow('batch expected to have version for id', id, bd, objsToLoad);
                    }
                    toAllocate.push([id, version, obj]);
                }
                return toAllocate;
            },
        });

        const toAllocate: [Id, number, D][] = [];
        for (let i = 0; i < batches.length; ++i) {
            const batch = batches[i];
            const result = results[i];
            if (!result) {
                ErrorUtils.logThrow(`unexpected abscence of batch at index ${i}`, batch, result);
            }
            if (result instanceof Failure) {
                throw new Error(`failed to load object from batch ${result.errorMsg()}, ${objsToLoadPerBatch}`);
            }
            const res = result.value;
            for (const t of res) {
                toAllocate.push(t);
            }
        }
        
        logger.debug(this.config.identifier, 'to allocate', toAllocate);
        return toAllocate;
    }

    *calcRealDiff(
        logger: ScopedLogger,
        networkClient: CachingNetworkClient,
        potentialDiff: DiffToVersionPotential
    ): Generator<Yield, DiffToVersionReal<D, any>> {
        this._assertIsInit();
        const toAllocate: [Id, number, D][] = [];
        const toUpdate: [Id, number, D][] = [];

        const serializer = this.collection.getObjectsPickingSerializer();
        const deserializeInPool = this.collection.serializeInWorkerPool();

        const results = yield* IterUtils.asyncMap({
            arrayToConsume: Array.from(potentialDiff.batchesToLoad),
            maxConcurrency: 15,
            mapFn: async ([batchGuid, batchDiff]) => {
                
                const batch = await this.fetchBatch(networkClient, batchGuid);

                if (!batchDiff) {
                    ErrorUtils.logThrow(
                        'loaded batches and diff to load should have corresponding to each other batches',
                        potentialDiff.batchesToLoad,
                        batchGuid,
                        batchDiff
                    );
                }
                const bd = this._history.poll().allBatchesPerGuid.get(batchGuid)!;
                const bdIdsVersions = bd.versionPerId();

                const objsIdsToDeserialize = batchDiff.toAlloc.concat(batchDiff.toReconcile);
    
                const deserialized = await decompressAndDeserialize({
                    binary: batch.payload,
                    compressionType: batch.payloadCompression,
                    objsIdsToDeserialize,
                    serializer: serializer,
                    inWorkerPool: deserializeInPool,
                });

                const toUpdate: [Id, number, D][] = [];
        
                if (batchDiff.toReconcile.length) {
                    const loaded = this.collection.getObjects(batchDiff.toReconcile);
                    const comparator = this._objsEqComparator;
                    for (const [id, d] of loaded) {
                        const de = deserialized.get(id);
                        if (de == undefined) {
                            continue;
                        }
                        if (comparator(d, de)) {
                            continue; // the same, skip
                        } else {
                            const version = bdIdsVersions.get(id);
                            if (version == undefined) {
                                ErrorUtils.logThrow('batch expected to have version for id', id, bd, batchDiff);
                            }
                            toUpdate.push([id, version, de]);
                        }
                    }
                }

                const toAllocate: [Id, number, D][] = [];

                for (const id of batchDiff.toAlloc) {
                    const de = deserialized.get(id);
                    if (de === null) {
                        continue;
                    }
                    if (de === undefined) {
                        ErrorUtils.logThrow('object that should be present is undefined', this.config.identifier, id);
                    }
                    const version = bdIdsVersions.get(id);
                    if (version == undefined) {
                        ErrorUtils.logThrow('batch expected to have version for id', id, bd, batchDiff);
                    }
                    toAllocate.push([id, version, de]);
                }

                return {toAllocate, toUpdate};
            },
        });

        for (const result of results) {
            if (result instanceof Failure) {
                throw ErrorUtils.logThrow('could not load batch:', result.errorMsg());
            }
            const res = result.value;
            for (const t of res.toAllocate) {
                toAllocate.push(t);
            }
            for (const t of res.toUpdate) {
                toUpdate.push(t);
            }
        }

        const realDiff: DiffToVersionReal<D, any> = {
            targetVersionId: potentialDiff.targetVersionId,
            targetVersionContext: potentialDiff.targetVersionContext,
            delete: potentialDiff.delete,
            allocate: toAllocate,
            update: toUpdate,
        }
        logger.debug(this.config.identifier, 'real diff', realDiff);
        return realDiff;
    }

    prepareNewVersion(logger: ScopedLogger, projectVersion: number): CollectionVersionToSave<D> {
        this._assertIsInit();
        logger = logger.newScope(this.config.identifier);

        const {
            versionInv,
            currVersionWithDirtyFlagPerId,
            dataPerIdV
        } = this._calcCurrentObjectsState(logger);

        const newVersion = (this._history.poll().lastVersion()?.id ?? 0) + 1;

        // calculate new distribution of elements into batches

        const batchesToTryToReuse = new Set<BatchDescription>();
        {
            let objectsCountToTryReuse = 0;
            const objectCountToStop = Math.min(100, currVersionWithDirtyFlagPerId.size * 4);
            for (let i = this._history.poll().versions.length - 1; i >= 0; --i) {
                const collVersion = this._history.poll().versions[i];
                for (const br of collVersion.batchesRefs) {
                    const bd = this._history.poll().allBatchesPerGuid.get(br.guid)!;
                    if (!batchesToTryToReuse.has(bd)) {
                        objectsCountToTryReuse += bd.idsCount();
                        batchesToTryToReuse.add(bd);
                    }
                }
                if (objectsCountToTryReuse > objectCountToStop) {
                    break;
                }
            }
        }
        
        const { toReuse, toUploadNew } = calculateNewBatchesCollection(
            logger,
            IterUtils.mapIter(currVersionWithDirtyFlagPerId, ([id, [version]]) => newCompactIDV(id, version)),
            batchesToTryToReuse,
            this.config
        );

        const newVersionAllPartialRefs: BatchPartialReference[] = toReuse.slice();
        for (const newBatch of toUploadNew) {
            const bpr = new BatchPartialReference(newBatch.guid, null);
            newVersionAllPartialRefs.push(bpr);
        }

        const newBatchesToUpload: BatchToUpload<D>[] = [];
        for (const newBatch of toUploadNew) {
            const objects: Map<IdVersionPacked, D> = dataPerIdV.getBulk(newBatch.idvs());
            const btu: BatchToUpload<D> = {
                description: newBatch,
                contents: objects,
            };
            newBatchesToUpload.push(btu);
        }

        logger.debug('new batches to upload', newBatchesToUpload);

        const serializer = this.collection.getObjectsPickingSerializer();
        
        const batchContainers: [BatchDescription, Promise<BatchContainer>][] =
            newBatchesToUpload.map(b => {
                const batchContainer = BatchContainer.createNewAsync(
                    b.description.guid,
                    this.config.identifier,
                    IterUtils.mapIter(b.contents, t => [getIdFromIDV(t[0]), t[1]]),
                    serializer,
                    this._objsEqComparator
                )
                return [b.description, batchContainer];
            });
        
        let collectionVersion: CollectionVersion | null = null;

        // if nothing to upload, try to reuse last version
        // if (newBatchesToUpload.length == 0) {
        //     const lastLoadedVersion = this._history.versions.find(v => v.id == this._lastLoadedVersion);
        //     if (lastLoadedVersion) {
        //         const lastBatchPerGuid: Map<string, IdVersionPacked[]> = IterUtils.newMapFromIter(
        //             lastLoadedVersion.batchesRefs,
        //             bpr => bpr.guid,
        //             bpr => bpr.idsToExclude,
        //         );
        //         const newBatchPerGuid: Map<string, IdVersionPacked[]> = IterUtils.newMapFromIter(
        //             toReuse,
        //             bpr => bpr.guid,
        //             bpr => bpr.idsToExclude,
        //         );
        //         if (IterUtils.areMapsEqual(lastBatchPerGuid, newBatchPerGuid)) {
        //             collectionVersion = new CollectionVersion(
        //                 newVersion,
        //                 projectVersion,
        //                 new Date(),
        //                 lastLoadedVersion.batchesRefs
        //             );
        //         }
        //     }
        // }


        let contextSerialized: [CompressionType, Uint8Array] | null = null;
            
        const context = this.collection.additionalContext?.poll();
        if (context !== undefined) {
            const serializer = this.collection.getContextSerializer();
            if (serializer) {
                const binary = serializer.serialize(context);
                contextSerialized = compressBinarySync(binary);
            } else {
                logger.error('collection has context, but no serializer for context', context, serializer);
            }
        }

        collectionVersion = new CollectionVersion(
            newVersion,
            projectVersion,
            new Date(),
            newVersionAllPartialRefs,
            contextSerialized
        );
        const res: CollectionVersionToSave<D> = {
            invalidatorValue: versionInv,
            batchesToUploadByFileName: batchContainers,
            collectionVersion,
        }
        return res;
    }

    async saveNewVersion(logger: ScopedLogger, cvts: CollectionVersionToSave<D>, cachingClient: CachingNetworkClient): Promise<CollectionHistory> {
        logger = logger.newScope(this.config.identifier);

        const newBatchesDescriptions: BatchDescription[] = [];
        logger.debug('saving new batches', cvts);
        
        const MAX_IN_PROGRESS_UPLOADS_COUNT = 4;

        const batchUploads: Set<Promise<Response>> = new Set();

        const serializer = new BatchContainerSerializer();
        for (const [description, containerPromise] of cvts.batchesToUploadByFileName) {
            newBatchesDescriptions.push(description);
            
            const container = await containerPromise;
            
            if (batchUploads.size >= MAX_IN_PROGRESS_UPLOADS_COUNT) {
                logger.debug('waiting for some uploads to finish');
                await Promise.race(batchUploads);
            }

            const binary = serializer.serialize(container);
            const uploadPromise = cachingClient.put(
                VerdataUrls.batchUrl(description.filename),
                binary
            );
            batchUploads.add(uploadPromise);
            uploadPromise.finally(() => batchUploads.delete(uploadPromise));
        }

        await Promise.all(batchUploads);

        const newCollectionHistory = this._history!.poll().duplicateAndUpdate(
            newBatchesDescriptions, cvts.collectionVersion
        );
        logger.info('new history', newCollectionHistory);
       
        await this._history.applyPatch({ patch: newCollectionHistory });

        return newCollectionHistory;
    }

    _calcCurrentObjectsState(logger: ScopedLogger): CurrentCollectionState<D> {
        this._assertIsInit();
        const collection = this.collection;

        const allIds: Id[] = collection.getAllIds();

        const versionsPerId: Map<Id, [number, boolean]> = new Map();

        const objectsData = new LazyMapBulk<IdVersionPacked, D>((ids) => {
            console.error('objects data should already be in dictionary', Array.from(ids));
            throw new Error('object data should already be in dictionary');
        });
        const allObjects: [Id, D][] = this.collection.getObjects(allIds);
        const vidObjects = this.objectsVersions.getVerdataVersionForEachObject(logger, allObjects);
        for (const t of vidObjects) {
            const idv = newCompactIDV(t.id, t.version);
            versionsPerId.set(t.id, [t.version, t.isDirty]);
            objectsData.set(idv, t.data);
        }

        logger.assert(versionsPerId.size == allIds.length, 'versions per id size check');

        const res: CurrentCollectionState<D> = {
            dataPerIdV: objectsData,
            versionInv: collection.stateInvalidator.version(),
            currVersionWithDirtyFlagPerId: versionsPerId
        };
        logger.debug('calculated collection state', res);
        return res;
    }
}

class CollectionsObjectsDiff {
    theSame:        Id[] = [];
    toReconcile:    Set<Id> = new Set();
    toAlloc:        Set<Id> = new Set();
    toDelete:       Id[] = [];

    private constructor(
    ) {
    }

    static calculateDiff(from: Map<Id, [number, boolean]>, to: Map<Id, number>): CollectionsObjectsDiff {

        const diff = new CollectionsObjectsDiff();

        for (const [id, version] of to) {
            const vFromWithDirtyFlag = from.get(id);
            if (!vFromWithDirtyFlag) {
                diff.toAlloc.add(id);
            } else if (vFromWithDirtyFlag[0] === version && !vFromWithDirtyFlag[1]) {
                diff.theSame.push(id);
            } else {
                diff.toReconcile.add(id);
            }
        }

        for (const id of from.keys()) {
            if (!to.has(id)) {
                diff.toDelete.push(id);
            }
        }

        return diff;
    }

    selectBatchesThatNeedLoading(perBatchIds: Map<string, Id[]>): Map<string, {toAlloc: Id[], toReconcile: Id[]}> {
        const res = new Map<string, {toAlloc: Id[], toReconcile: Id[]}>();
        for (const [guid, ids] of perBatchIds) {
            const toAlloc: Id[] = [];
            const toReconcile: Id[] = [];
            for (const id of ids) {
                if (this.toAlloc.has(id)) {
                    toAlloc.push(id);
                } else if (this.toReconcile.has(id)) {
                    toReconcile.push(id);
                }
            }
            if (toAlloc.length || toReconcile.length) {
                res.set(guid, { toAlloc, toReconcile });
            }
        }
        return res;
    }
}

interface CurrentCollectionState<D> {
    versionInv: any;
    currVersionWithDirtyFlagPerId: Map<Id,[number, boolean]>;
    dataPerIdV: LazyMapBulk<IdVersionPacked, D>;
}

export interface CollectionVersionToSave<D> {
    invalidatorValue: any,
    batchesToUploadByFileName: [BatchDescription, Promise<BatchContainer>][],
    collectionVersion: CollectionVersion,
}

export interface BatchToUpload<D> {
    description: BatchDescription,
    contents: Map<IdVersionPacked, D>;
}
