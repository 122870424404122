
import type { NonMethodsOnly} from 'engine-utils-ts';
import { IterUtils, StringUtils, CompressibleNumbersArray } from 'engine-utils-ts';
import type { CustomPropertySerializer} from '../properties/CustomPropsRegistry';
import { CustomPropsRegistry } from '../properties/CustomPropsRegistry';
import type { PropValueTotalHash } from '../properties/Props';
import { PropertyBase } from '../properties/Props';
import { getPropertyReferenceId } from '../properties/PropsRefsIds';
import { TMY_ColumnHeader } from './TMY_ColumnHeader';

export class TMY_ColumnGeneral extends PropertyBase {

    constructor(
        public readonly header: TMY_ColumnHeader,
        public readonly valuesPalette: (string | number)[],
        public readonly valuesIndicesInPalette: Uint8Array | Uint16Array | Uint32Array,
    ) {
        super();
        for (const index of valuesIndicesInPalette) {
            if (!(index >= 0 && index <= valuesPalette.length)) {
                throw new Error(`valuesIndicesInPalette index out of range: ${index} of ${valuesPalette.length}`);
            }
        }
        Object.freeze(this);
    }

    uniqueValueHash(): PropValueTotalHash {
        return getPropertyReferenceId(this);
    }
    equals(other: PropertyBase): boolean {
        if (!(other instanceof TMY_ColumnGeneral)) {
            return false;
        }
        return this.header.equals(other.header)
            && IterUtils.areArraysEqual(this.valuesPalette, other.valuesPalette)
            && IterUtils.areArraysEqual(this.valuesIndicesInPalette, other.valuesIndicesInPalette);
    }

    get length() {
        return this.valuesIndicesInPalette.length;
    }

    at(index: number): number | string | undefined {
        if (index >= 0 && index < this.valuesIndicesInPalette.length) {
            const value = this.valuesPalette[this.valuesIndicesInPalette[index]];
            return value;
        }
        return undefined
    }

    mapToNumbersFlatArr(defaultValue: number = 0): Float32Array {
        const arr = new Float32Array(this.length);
        for (let i = 0; i < arr.length; ++i) {
            const value = this.at(i);
            if (typeof value === 'number') {
                arr[i] = value;
            } else {
                arr[i] = defaultValue;
            }
        }
        return arr;
    }

    peakHours() {
        const perHourCounts = new Map<number, number>();
        for (let i = 0; i < this.length; i += 24) {
            let peakValueInADay = -Infinity;
            let peakIndex = -1;
            for (let j = 0; j < 24; ++j) {
                const value = this.at(i + j);
                if (typeof value === 'number' && value > peakValueInADay) {
                    peakValueInADay = value;
                    peakIndex = j;
                }
            }
            perHourCounts.set(peakIndex, (perHourCounts.get(peakIndex) ?? 0) + 1);
        }
        return Array.from(perHourCounts).sort((a, b) => b[1] - a[1]);
    }

    mapToCompressibleArray(defaultValue: number) {
        const flat = this.mapToNumbersFlatArr(defaultValue);
        return CompressibleNumbersArray.newFromValues(this.header.unit ?? '', flat);
    }

    isMostlyNumeric() {
        return this.valuesPalette.filter((value) => typeof value === 'number').length
            >= this.valuesPalette.length / 2;
    }

    public static newFromValues(
        header: TMY_ColumnHeader,
        values: (string | number)[],
    ) {
        const paletteMap: Map<(string | number), number> = new Map();
        const indices = values.map((sourceValue) => {
            if (typeof sourceValue === 'number' && !isFinite(sourceValue)) {
                sourceValue = '';
            }

            const inPaletteIndex = paletteMap.get(sourceValue);
            if (inPaletteIndex !== undefined) {
                return inPaletteIndex;
            } else {
                const index = paletteMap.size;
                paletteMap.set(sourceValue, index);
                return index;
            }
        });
        const paletteArray = Array.from(paletteMap.keys());
        if (paletteArray.length < 0xFF) {
            return new TMY_ColumnGeneral(header, paletteArray, new Uint8Array(indices));
        }
        if (paletteArray.length < 0xFFFF) {
            return new TMY_ColumnGeneral(header, paletteArray, new Uint16Array(indices));
        }
        if (paletteArray.length < 0xFFFFFFFF) {
            return new TMY_ColumnGeneral(header, paletteArray, new Uint32Array(indices));
        }
        throw new Error(`too many unique values: ${paletteArray.length}`);
    }

    public static parseFromStrings(args: {
        header: TMY_ColumnHeader,
        dataStrings: string[],
    }) {
        const { header, dataStrings } = args;
        const values = dataStrings.map(StringUtils.tryParseAsNumberIfExact);
        return TMY_ColumnGeneral.newFromValues(header, values);
    }
}

interface TMY_ColumnGeneralSerialized {
    header: NonMethodsOnly<TMY_ColumnHeader>;
    values_palette: (number|string)[];
    in_palette_indices: number[];
}
class TMY_ColumnGeneralSerializer implements CustomPropertySerializer<TMY_ColumnGeneral> {
    currentFormatVersion: number = 0;
    serializeToString(p: TMY_ColumnGeneral): string {
        const obj: TMY_ColumnGeneralSerialized = {
            header: p.header,
            values_palette: p.valuesPalette,
            in_palette_indices: Array.from(p.valuesIndicesInPalette),
        };
        return JSON.stringify(obj);
    }
    deserializeFromString(formatVersion: number, serialized: string): TMY_ColumnGeneral {
        const obj = JSON.parse(serialized) as TMY_ColumnGeneralSerialized;
        const values = obj.in_palette_indices.map((index) => obj.values_palette[index]);
        return TMY_ColumnGeneral.newFromValues(
            new TMY_ColumnHeader({
                name: obj.header.name,
                unit: obj.header.unit,
                raw: obj.header.raw,
            }),
            values,
        );
    }
    
}

CustomPropsRegistry.register({
    class: TMY_ColumnGeneral,
    serializerClass: TMY_ColumnGeneralSerializer,
    constructFromPartial: (params: Partial<TMY_ColumnGeneral>) => {
        return new TMY_ColumnGeneral(
            params.header ?? new TMY_ColumnHeader({raw: [""]}),
            params.valuesPalette ?? [],
            params.valuesIndicesInPalette ?? new Uint8Array(0),
        );
    },
})
