<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6032 4.73475C12.5282 4.6597 12.4076 4.65635 12.3284 4.72713L5.09389 11.2002H6.8V20.0002C6.8 20.1106 6.88954 20.2002 7 20.2002H9.2V14.2002H14.8V20.2002H17C17.1105 20.2002 17.2 20.1106 17.2 20.0002V11.2002H19.0686L12.6032 4.73475ZM11.2616 3.53474C11.9735 2.89774 13.0591 2.92787 13.7346 3.60338L20.7121 10.5809C21.5311 11.3999 20.9511 12.8002 19.7929 12.8002H18.8V20.0002C18.8 20.9943 17.9941 21.8002 17 21.8002H13.2V15.8002H10.8V21.8002H7C6.00589 21.8002 5.2 20.9943 5.2 20.0002V12.8002H4.30868C3.11539 12.8002 2.55256 11.327 3.44185 10.5313L11.2616 3.53474Z"
        fill="currentColor"
    />
</svg>
