import type { Bim, TerrainAnalysisConfig} from "bim-ts";
import { TerrainAnalysisTypeIdent, TerrainDisplaySlopeSelector } from "bim-ts";
import type { LazyVersioned, RGBAHex } from "engine-utils-ts";
import { LazyDerived } from "engine-utils-ts";
import { Vector3 } from "math-ts";
import type { TerrainDisplayEngineSettings} from "../TerrainDisplayEngineSettings";
import { TerrainDisplayMode } from "../TerrainDisplayEngineSettings";



export interface ColorValue<T> {
	color: RGBAHex,
	value: T,
}

export class EngineTerrainPaletteSlice {
	
	constructor(
		public min: number,
		public max: number,
		public color: RGBAHex,
	) {
	}

	static sort(l: EngineTerrainPaletteSlice, r: EngineTerrainPaletteSlice) {
		if (l.min === r.min) {
			return l.max - r.max;
		}
		return l.min - r.min;
	}
}

export class EngineTerrainPalette {


	constructor(
        readonly displayMode: TerrainDisplayMode,
		readonly unit: string,
		readonly slices: EngineTerrainPaletteSlice[],
        readonly slopeVector: Vector3,
	) {
		this.unit = unit;
		this.slices = slices;
	}

	sortSlices() {
		this.slices.sort(EngineTerrainPaletteSlice.sort);
	}

	cleanup() {
	}
}


export function getDerivedEngineTerrainPalette(
    bim: Bim,
    engineSelector: LazyVersioned<TerrainDisplayEngineSettings>
): LazyDerived<EngineTerrainPalette> {
    return LazyDerived.new2(
        'engine-terrain-settings-derived-from-bim',
        [],
        [bim.configs.getLazySingletonOf({type_identifier: TerrainAnalysisTypeIdent}), engineSelector],
        ([config, paletteSelector]) => {

            let result: EngineTerrainPalette;
            if (!config) {
                console.warn('bim terrain config absent');
                result = new EngineTerrainPalette(paletteSelector.mode, '', [], NS_Vector);
            } else if (paletteSelector.mode === TerrainDisplayMode.BasicTransparent) {
                result = new EngineTerrainPalette(paletteSelector.mode, '', [], NS_Vector);

            } else if (paletteSelector.mode === TerrainDisplayMode.Elevation) {
                const palette = config.get<TerrainAnalysisConfig>().elevation_palette.slices;
                const slices: EngineTerrainPaletteSlice[] = [];
                for (const slice of palette) {
                    const min = slice.min.as('m');
                    const max = slice.max.as('m');
                    slices.push(new EngineTerrainPaletteSlice(min, max, slice.color.value as RGBAHex));
                }
                result = new EngineTerrainPalette(paletteSelector.mode, 'm', slices, NS_Vector);
            } else if (paletteSelector.mode === TerrainDisplayMode.Slope) {
                const palette = config.get<TerrainAnalysisConfig>().slope_palette.slices;

                const slices: EngineTerrainPaletteSlice[] = [];
                for (const slice of palette) {
                    const min = slice.min.as('rad');
                    const max = slice.max.as('rad');
                    slices.push(new EngineTerrainPaletteSlice(min, max, slice.color.value as RGBAHex));
                }
                result = new EngineTerrainPalette(paletteSelector.mode, 'rad', slices, paletteSelector.slopeSelector === TerrainDisplaySlopeSelector.NS ? NS_Vector : EW_Vector);
            } else if (paletteSelector.mode === TerrainDisplayMode.CutFill) {
                const palette = config.get<TerrainAnalysisConfig>().cut_fill_palette.slices;
                const slices: EngineTerrainPaletteSlice[] = [];
                for (const slice of palette) {
                    const min = slice.min.as('m');
                    const max = slice.max.as('m');
                    slices.push(new EngineTerrainPaletteSlice(min, max, slice.color.value as RGBAHex));
                }
                result = new EngineTerrainPalette(paletteSelector.mode, 'm', slices, NS_Vector);
            } else {
                console.error('unrecognized terrain display settings', paletteSelector.mode);
                result = new EngineTerrainPalette(paletteSelector.mode, '', [], NS_Vector);
            }
            return result;
        }
    )
}

// SHOULD BE IN SYNC WITH TERRAIN ANALYSIS METRICS
const NS_Vector = Object.freeze(new Vector3(0, -1, 0));
const EW_Vector = Object.freeze(new Vector3(-1, 0, 0));

