
import { GpuGeometries } from '../geometries/GpuGeometries';
import type { GlobalUniforms } from '../materials/GlobalUniforms';
import type { MaterialsFactory } from '../materials/MaterialsFactory';
import { MaterialsPool } from '../pools/MaterialsPool';
import type { RendererExt } from './RendererExt';


export class GpuResources {
	readonly renderer: RendererExt;
	readonly geometries: GpuGeometries;
	
	materials!: MaterialsPool;
	globalUniforms!: GlobalUniforms;

	constructor(
		renderer: RendererExt,
	) {
		this.renderer = renderer;
		this.geometries = new GpuGeometries(renderer);
		this.renderer._geometries = this.geometries;

		// this.renderer.state.reset();
	}

	setMaterialsFactory(materialsFactory: MaterialsFactory) {
		this.materials = new MaterialsPool(materialsFactory);
		this.globalUniforms = materialsFactory.globalUniforms;
	}

	dispose() {
		this.geometries.clear();
		this.materials.clear();
		this.renderer.dispose();
	}
}
