import type { Bim, WireGauge } from "../..";
import { AWG_LV_GAUGES, AWG_MV_GAUGES, BimProperty } from "../..";
import type { GroupsShapesToDefine } from "../../scene/SceneInstanesShapeHooks";

const basePath = ['wire'];
const stdPath = [...basePath, 'std'];
const gaugePath = [...basePath, 'gauge'];
const materialPath = [...basePath, 'material'];
const diamaterPath = [...basePath, 'diameter'];
const stcBasePath = [...basePath, 'stc'];
const ampacityBasePath = [...basePath, 'ampacity'];
const ampacityLengthPath = [...ampacityBasePath, 'length'];
const ampacityItemsBase = [...ampacityBasePath, 'items']

const stcTemperaturePath = [...stcBasePath, 'temperature'];
const stcReactancePath = [...stcBasePath, 'reactance'];
const stcAcResistivityPath = [...stcBasePath, 'ac resistivity'];
const stcDcResistivityPath = [...stcBasePath, 'dc resistivity'];
const ampacityFirstItemBuriedPath = [...ampacityItemsBase, '1', 'buried']



export const createWireSpecPropsShaper = (bim: Bim) => {
        //const stds = new Map<string, WireGauge[]>([
        //    ['awg', AWG_GAUGES]
        //])
    const typesmap = new Map([
        ['lv-wire-spec', new Map([['awg', AWG_LV_GAUGES]])],
        ['mv-wire-spec', new Map([['awg', AWG_MV_GAUGES]])]
    ]);
    // register shaper for transformer
    for (const [wireInstaType, stds] of typesmap) {
        bim.instances.registerPropsShapeHook(
            wireInstaType,
            {
				std: BimProperty.NewShared({
					value: '',
					path: stdPath,
				}),
                gauge: BimProperty.NewShared({
                    value: '',
                    path: gaugePath,
                }),
                material: BimProperty.NewShared({
                    value: '',
                    path: materialPath,
                }),
                ampacityLength: BimProperty.NewShared({
                    value: NaN,
                    path: ampacityLengthPath,
					numeric_step: 1,
                }),
                stcTemperature: BimProperty.NewShared({
                    value: '',
                    path: stcTemperaturePath,
                }),
                stcReactance: BimProperty.NewShared({
                    value: '',
                    path: stcReactancePath,
                }),
                stcDcResistivity: BimProperty.NewShared({
                    value: '',
                    path: stcDcResistivityPath,
                }),
                stcAcResistivity: BimProperty.NewShared({
                    value: '',
                    path: stcAcResistivityPath,
                }),
                ampacityFirstItemBuried: BimProperty.NewShared({
                    value: '',
                    path: ampacityFirstItemBuriedPath,
                }),
            },
            args => {
                const result: GroupsShapesToDefine = {
                    propertiesToHave: new Map(),
                    propertiesToOverride: new Map(),
                    groupsToDefinesMergedPrefixes: [],
                };
block1: {
/////////////////////////////////////////
// resetting indent to save some space //
/////////////////////////////////////////

// std
let allowedStdNames = Array.from(stds.keys());
result.propertiesToOverride.set(
	BimProperty.MergedPath(stdPath),
	BimProperty.NewShared({
		...args['std'].property,
		discrete_variants: allowedStdNames,
	})
)
result.groupsToDefinesMergedPrefixes.push(BimProperty.MergedPath(stdPath));
const gauges = stds.get(args['std'].property.value)
if (!gauges) {
	result.groupsToDefinesMergedPrefixes.push(BimProperty.MergedPath(basePath));
	break block1;
}

// gauge
let curGauge = args['gauge'].property.value as string;
const availableGaugeNames = gauges.reduce((a,c) => a.add(c.name), new Set<string>());
result.propertiesToOverride.set(BimProperty.MergedPath(gaugePath), BimProperty.NewShared({
    ...args['gauge'].property,
    discrete_variants: Array.from(availableGaugeNames),
}))
result.groupsToDefinesMergedPrefixes.push(BimProperty.MergedPath(gaugePath));
if (!gauges.find(x => x.name === curGauge)) {
	result.groupsToDefinesMergedPrefixes.push(BimProperty.MergedPath(basePath));
	break block1;
}


// material
let curMaterial = args['material'].property.value as string;
const gaugesMatchingSelectedGauge = gauges.filter(x => x.name === curGauge);
const materialsMatchingSelectedGauge =
    gaugesMatchingSelectedGauge.reduce((a,c) => a.add(c.material), new Set<string>());
result.propertiesToOverride.set(BimProperty.MergedPath(materialPath), BimProperty.NewShared({
    ...args['material'].property,
    discrete_variants: Array.from(materialsMatchingSelectedGauge),
}))
result.groupsToDefinesMergedPrefixes.push(BimProperty.MergedPath(gaugePath));
const gauge = gauges.find(x => x.material === curMaterial && x.name === curGauge);
if (!gaugesMatchingSelectedGauge.find(x => x.material === curMaterial) || !gauge) {
	result.groupsToDefinesMergedPrefixes.push(BimProperty.MergedPath(basePath));
	break block1;
}


const newGauge = !!(
	args['gauge'].prev && args['material'].prev &&
	(args['gauge'].prev.value !== curGauge || args['material'].prev.value !== curMaterial)
)
const propsTo_Group = newGauge ? result.propertiesToOverride : result.propertiesToHave;


// diameter
propsTo_Group.set(
	BimProperty.MergedPath(diamaterPath),
	BimProperty.NewShared({
		path: diamaterPath,
		readonly: true,
		value: gauge.diameter.value,
		unit: gauge.diameter.unit,
	})
)



// stc group
stc: {
	result.groupsToDefinesMergedPrefixes.push(BimProperty.MergedPath(stcBasePath));
	// temperature
	propsTo_Group.set(
		BimProperty.MergedPath(stcTemperaturePath),
		BimProperty.NewShared({
			...args['stcTemperature'].property,
			value: gauge.stc.temperature.value,
			unit: gauge.stc.temperature.unit,
		}),
	);
	// dc resistivity
	propsTo_Group.set(
		BimProperty.MergedPath(stcDcResistivityPath),
		BimProperty.NewShared({
			...args['stcDcResistivity'].property,
			value: gauge.stc.dcResistivity.value,
			unit: gauge.stc.dcResistivity.unit,
		}),
	);
	// ac resistivity
	propsTo_Group.set(
		BimProperty.MergedPath(stcAcResistivityPath),
		BimProperty.NewShared({
			...args['stcAcResistivity'].property,
			value: gauge.stc.acResistivity.value,
			unit: gauge.stc.acResistivity.unit,
		}),
	);
	// reactance resistivity
	propsTo_Group.set(
		BimProperty.MergedPath(stcReactancePath),
		BimProperty.NewShared({
			...args['stcReactance'].property,
			value: gauge.stc.reactance.value,
			unit: gauge.stc.reactance.unit,
		}),
	);
}


// ampacity group
if (newGauge) newAmpacity: {
	result.groupsToDefinesMergedPrefixes.push(BimProperty.MergedPath(ampacityBasePath));
	// set ampacity length
	propsTo_Group.set(
		BimProperty.MergedPath(ampacityLengthPath),
		BimProperty.NewShared({
			...args['ampacityLength'].property,
			value: gauge.ampacities.length,
		})
	);
	for (const [idx, amp] of gauge.ampacities.entries()) {
		// set ampacity item
		const tempPath = [...ampacityItemsBase, '' + (idx + 1), 'temperature']
		propsTo_Group.set(
			BimProperty.MergedPath(tempPath),
			BimProperty.NewShared({
				...args['ampacityFirstItemBuried'].property,
				path: tempPath,
				value: amp.temperature.value,
				unit: amp.temperature.unit,
			})
		);
		const buriedPath = [...ampacityItemsBase, '' + (idx + 1), 'buried']
		propsTo_Group.set(
			BimProperty.MergedPath(buriedPath),
			BimProperty.NewShared({
				...args['ampacityFirstItemBuried'].property,
				path: buriedPath,
				value: amp.buriedAmpacity.value,
				unit: amp.buriedAmpacity.unit,
			})
		);
		const freeAirPath = [...ampacityItemsBase, '' + (idx + 1), 'free air']
		propsTo_Group.set(
			BimProperty.MergedPath(freeAirPath),
			BimProperty.NewShared({
				...args['ampacityFirstItemBuried'].property,
				path: freeAirPath,
				value: amp.freeAirAmpacity.value,
				unit: amp.freeAirAmpacity.unit,
			})
		);
	}
} else updateAmpacity: {
	result.groupsToDefinesMergedPrefixes.push(BimProperty.MergedPath(ampacityBasePath))
	let length = Number.isFinite(args['ampacityLength'].property.value as number)
		? args['ampacityLength'].property.value
		: gauge.ampacities.length
	length = Math.max(length, 0);
	propsTo_Group.set(
		BimProperty.MergedPath(ampacityLengthPath),
		BimProperty.NewShared({
			...args['ampacityLength'].property,
			value: length,
		}),
	);
	let temperatureUnit: string = '';
	let buriedUnit: string = '';
	let freeAirUnit: string = '';
	for (let i = 0; i < length; i ++) {
		const predefinedAmp: WireGauge['ampacities'][0] | undefined = gauge.ampacities[i];
		const tempVal = predefinedAmp?.temperature.value ?? 0;
		const buriedVal = predefinedAmp?.buriedAmpacity.value ?? 0;
		const freeAirVal = predefinedAmp?.freeAirAmpacity.value ?? 0;
		if (predefinedAmp) {
			temperatureUnit = predefinedAmp.temperature.unit;
			buriedUnit = predefinedAmp.buriedAmpacity.unit;
			freeAirUnit = predefinedAmp.freeAirAmpacity.unit;
		} else if (i === 0) {
			console.error('predefined ampacity is not found.')
			break block1;
		}

		const arrIdxStr = '' + (i + 1);
		const temperaturePath = [...ampacityItemsBase, arrIdxStr, 'temperature'];
		const buriedPath = [...ampacityItemsBase, arrIdxStr, 'buried'];
		const freeAirPath = [...ampacityItemsBase, arrIdxStr, 'free air'];

		// temperature
		result.propertiesToHave.set(
			BimProperty.MergedPath(temperaturePath),
			BimProperty.NewShared({
				path: temperaturePath,
				value: tempVal,
				unit: temperatureUnit,
			})
		);
		// buried
		result.propertiesToHave.set(
			BimProperty.MergedPath(buriedPath),
			BimProperty.NewShared({
				path: buriedPath,
				value: buriedVal,
				unit: buriedUnit,
			})
		);
		// free air
		result.propertiesToHave.set(
			BimProperty.MergedPath(freeAirPath),
			BimProperty.NewShared({
				path: freeAirPath,
				value: freeAirVal,
				unit: freeAirUnit,
			})
		);

	}
}

/////////////////////////////////////
// End of dc pattern related props //
/////////////////////////////////////
}
                return result;
            }
        )
    }
}

