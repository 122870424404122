import type { BimGeometryBase, LocalIdsCounter } from "bim-ts";
import type { ScopedLogger, Writeable } from "engine-utils-ts";
import type { Transform, Vector3 } from "math-ts";
import type { ESO_Any } from "../esos/ESO";
import type { ESSO_Any, ESSO_InteractiveAny } from "../esos/ESSO";
import type { InObjLocalId } from "./EngineSceneIds";
import type { ESOHandle } from "./ESOsCollection";


export type GeometryEditsHandlerAny = GeometryEditsHandler<BimGeometryBase>;

export type GeometryCtor = {new(...args: any[]): BimGeometryBase};

export abstract class GeometryEditsHandler<Geometry extends BimGeometryBase> {
	readonly logger: ScopedLogger;

	constructor(
		logger: ScopedLogger,
		ident: string,
	) {
		this.logger = logger.newScope(ident);
	}

	abstract geometryCtor(): GeometryCtor;

	abstract createEditReprImpl(handle: ESOHandle, obj: Readonly<ESO_Any>, geometryRef: Readonly<Geometry>, result: ESSO_Any[]): void;
	
	createEditRepr(handle: ESOHandle, obj: ESO_Any, geometryRef: Readonly<Geometry>, result: ESSO_Any[]): void {
		this.createEditReprImpl(handle, obj, geometryRef, result);
	}

	abstract patchPositions(
		geometryInOut: Writeable<Geometry>,
		localIdsGenerator: LocalIdsCounter,
		subobjs: ESSO_InteractiveAny[],
		positionsPatch: Map<InObjLocalId, Transform>,
	): {};

	abstract cloneParts(
		geometryInOut: Writeable<Geometry>,
		localIdsGenerator: LocalIdsCounter,
		subobjs: Readonly<ESSO_InteractiveAny>[],
		idsToClone: InObjLocalId[],
		cloneDirection: Vector3,
	): {
		added: InObjLocalId[],
        toUseForGesture?: InObjLocalId[],
    };

	abstract deleteParts(
		geometryInOut: Writeable<Geometry>,
		localIdsGenerator: LocalIdsCounter,
		subobjs: Readonly<ESSO_InteractiveAny>[],
		idsToDelete: InObjLocalId[],
	): {}
}

// export interface GeoinstanceSubobjsRepr {
// 	allSubobjs(result: ESSO_Any[]): void;
// }

// export class GeoSubobjsReprFlat implements GeoinstanceSubobjsRepr {

// 	readonly all: ESSO_Any[];
	
// 	constructor(all: ESSO_Any[]) {
// 		this.all = all;
// 	}

// 	allSubobjs(result: ESSO_Any[]): void {
// 		for (const it of this.all) {
// 			result.push(it);
// 		}
// 	}

// }
