import type { Bim, IdBimScene, RepresentationBase} from "bim-ts";
import { SceneObjDiff, TransformerIdent } from "bim-ts";
import { TextStyleOptions } from "../esos/ESSO_TextAnnotation";
import { ESO_Diff } from "../esos/ESO_Diff";
import { TextLayoutOptions } from "../three-mesh-ui/components/Text";
import { BlockOptions } from "../three-mesh-ui/components/Block";
import { Aabb, Vector3 } from "math-ts";
import type { AnnotationRepr, AnnotationsMetrics } from "./AnnotationsCalculatorBase";
import { AnnotationDirtyFlags, AnnotationsCalculatorBase } from "./AnnotationsCalculatorBase";
import type { ESOHandle, ESOsCollection } from "../scene/ESOsCollection";
import type { ObservableObject} from "engine-utils-ts";
import { DefaultMap } from "engine-utils-ts";
import type { ESOsHandlerBase } from "../esos/ESOsHandlerBase";
import type { ESO } from "../esos/ESO";
import type { AnnotationsSettings } from "./AnnotationsSettingsUiBindings";
import { TextBlockGeometry } from "../geometries/EngineGeoTextBlock";


const DescendantsTypes = ['tracker', 'fixed-tilt', 'any-tracker'];

const fontSizes = [70, 30, 35];

const enum TrackerDirtyFlags {
    None = 0,
    Props = 1,
    Geo = 2,
    Parent = 4,
    All = 4 + 2 + 1,
}


interface DescendantInfo { 
    parentId: IdBimScene; 
    modulesCount: number;
}

class ParentMetrics implements AnnotationsMetrics {
    esoHandle: ESOHandle;
    index: number;
    bbox: Aabb;
    trackersMetrics: Map<number, number>;
    power: number;

    constructor(esoHandle: ESOHandle) {
        this.esoHandle = esoHandle;
        this.index = 0;
        this.bbox = Aabb.empty();
        this.trackersMetrics = new Map<number, number>();
        this.power = 0;
    };

    equals(metrics: ParentMetrics) {
        if (!Object.is(this.power, metrics.power) || 
            !Object.is(this.index, metrics.index)) {
            return false;
        }

        if (!this.bbox.equals(metrics.bbox)) {
            return false;
        }
        
        if (this.trackersMetrics.size !== metrics.trackersMetrics.size) {
            return false
        }
        for (const [modules, trackers] of this.trackersMetrics) {
            if (metrics.trackersMetrics.get(modules) !== trackers) {
                return false;
            }
        }

        return true;
    }

    copy(): ParentMetrics {
        const copy = new ParentMetrics(this.esoHandle);
        copy.bbox = this.bbox;
        copy.trackersMetrics = this.trackersMetrics;
        copy.power = this.power;
        copy.index = this.index;
        return copy;
    }
}


export class StdStaticAnnotationsCalculator extends AnnotationsCalculatorBase {

    readonly descendantTrackersMetrics: Map<IdBimScene, DescendantInfo>;

    readonly parentBlocksMetrics: Map<IdBimScene, ParentMetrics>;

    readonly parentsDescendants: Map<IdBimScene, IdBimScene[]>;
    
    readonly dirtyParents: Map<IdBimScene, AnnotationDirtyFlags>;

    readonly dirtyDescendants: Map<IdBimScene, TrackerDirtyFlags>;

    readonly currentAnnotations: Map<ESOHandle, AnnotationRepr>;

    constructor(
        bim: Bim,
        esos: ESOsCollection,
        annotationsSettings: ObservableObject<AnnotationsSettings>
    ) {
        super(bim, esos, annotationsSettings);

        this.descendantTrackersMetrics = new Map<IdBimScene, DescendantInfo>();
        this.parentBlocksMetrics = new Map<IdBimScene, ParentMetrics>();
        this.parentsDescendants = new Map<IdBimScene, IdBimScene[]>();
        this.dirtyParents = new Map<IdBimScene, AnnotationDirtyFlags>();
        this.dirtyDescendants = new Map<IdBimScene, TrackerDirtyFlags>();
        this.currentAnnotations = new Map<ESOHandle, AnnotationRepr>();
    }


    markAllocated(ids: Set<IdBimScene>) {
        if (ids.size === 0) {
            return;
        }

        const instances = this.bim.instances.peekByIds(ids);
        for (const [id, instance] of instances) {
            if (instance.type_identifier === TransformerIdent) {
                const esoHandle = this.esos.handlesOf([id])[0];
                this.parentBlocksMetrics.set(id, new ParentMetrics(esoHandle));
                this.dirtyParents.set(id, AnnotationDirtyFlags.All);
            }
            if (DescendantsTypes.includes(instance.type_identifier)) {
                this.dirtyDescendants.set(id, TrackerDirtyFlags.All);
            }
        }
    }

    markUpdated(perIdDiffs: Map<IdBimScene, SceneObjDiff>) {
        for (const [id, diff] of perIdDiffs) {
            const parentInfo = this.parentBlocksMetrics.get(id);
            if (parentInfo) {
                let parentDirtyFlags = this.dirtyParents.get(id) ?? AnnotationDirtyFlags.None;

                if ((diff & (SceneObjDiff.LegacyProps | SceneObjDiff.NewProps)) !== 0) {
                    parentDirtyFlags |= AnnotationDirtyFlags.Props;
                }

                if ((diff & SceneObjDiff.WorldPosition) !== 0) {
                    parentDirtyFlags |= AnnotationDirtyFlags.Geo;
                }

                if ((diff & SceneObjDiff.SpatialDescendants) !== 0) {
                    parentDirtyFlags |= (AnnotationDirtyFlags.ChildrenList 
                        | AnnotationDirtyFlags.ChildrenGeo
                        | AnnotationDirtyFlags.ChildrenProps
                        | AnnotationDirtyFlags.Props);
                }

                if (parentDirtyFlags !== AnnotationDirtyFlags.None) {
                    this.dirtyParents.set(id, parentDirtyFlags);
                }

                continue;
            }

            const descendantInfo = this.descendantTrackersMetrics.get(id);
            if (descendantInfo) {
                let descendantDirtyFlags = this.dirtyDescendants.get(id) ?? TrackerDirtyFlags.None;

                if ((diff & SceneObjDiff.SpatialParentRef) !== 0) {
                    descendantDirtyFlags |= TrackerDirtyFlags.Parent;
                }
                
                if ((diff & (
                    SceneObjDiff.Representation | SceneObjDiff.GeometryReferenced | SceneObjDiff.WorldPosition
                    )) !== 0) {
                    
                    descendantDirtyFlags |= TrackerDirtyFlags.Geo;
                } 
                
                if ((diff & (SceneObjDiff.LegacyProps | SceneObjDiff.NewProps)) !== 0) {
                    descendantDirtyFlags |= TrackerDirtyFlags.Props;
                }

                if (descendantDirtyFlags !== TrackerDirtyFlags.None) {
                    this.dirtyDescendants.set(id, descendantDirtyFlags);
                }

                continue;
            }
        }
    };

    markDeleted(ids: Set<IdBimScene>) {
        for (const id of ids) {
            if (this.parentsDescendants.delete(id)) {
                const esoHandle = this.parentBlocksMetrics.get(id)?.esoHandle;
                if (esoHandle) {
                    this.currentAnnotations.delete(esoHandle);
                }
                this.parentBlocksMetrics.delete(id);
                this.dirtyParents.delete(id);
            }

            this.descendantTrackersMetrics.delete(id);
        }
    };

    updateAnnotations(handler: ESOsHandlerBase<ESO>) {
        const settings = this.annotationsSettings.currentValue();
        const bimInstances = this.bim.instances;

        if (settings.showAnnotations === false) {
            for (const [esoHandle, _] of this.currentAnnotations) {
                handler.markDirty(esoHandle, ESO_Diff.RepresentationBreaking);
            }
            this.currentAnnotations.clear();
            this.dirtyDescendants.clear();
            this.dirtyParents.clear();
            this.descendantTrackersMetrics.clear();
            this.parentBlocksMetrics.clear();
            return;
        }

        const dirtyDescendants = Array.from(this.dirtyDescendants);
        
        this.dirtyDescendants.clear();

        for (const [id, flag] of dirtyDescendants) {
            let info: DescendantInfo;

            if (flag & TrackerDirtyFlags.Parent) {
                const transformerId = bimInstances.getClosestParentOfTypeFor(id, TransformerIdent, true);
                info = { parentId: transformerId ?? 0, modulesCount: this.descendantTrackersMetrics.get(id)?.modulesCount ?? 0 };
                this.descendantTrackersMetrics.set(id, info);
            } else {
                info = this.descendantTrackersMetrics.get(id)!;
            }

            let parentDirtyFlag = this.dirtyParents.get(info.parentId) ?? AnnotationDirtyFlags.None;

            if (flag & TrackerDirtyFlags.Props) {
                const instance = bimInstances.perId.get(id)!;
                const modulesCount = instance.properties.get("circuit | equipment | modules_count")?.asNumber();
                if (modulesCount !== undefined && !Object.is(info.modulesCount, modulesCount)) {
                    info.modulesCount = modulesCount;
                    parentDirtyFlag |= AnnotationDirtyFlags.ChildrenProps;
                }
            }

            if (flag & TrackerDirtyFlags.Geo) {
                parentDirtyFlag |= AnnotationDirtyFlags.ChildrenGeo;
            }

            if (info.parentId !== 0) {
                this.dirtyParents.set(info.parentId, parentDirtyFlag);
            }
        }

        const dirtyParents = Array.from(this.dirtyParents).sort((a, b) => a[0] - b[0]);

        this.dirtyParents.clear();

        const goemetriesAabbs = this.bim.allBimGeometries.aabbs.poll();
        const reprsBboxes = new DefaultMap<RepresentationBase, Aabb>(r => r.aabb(goemetriesAabbs));
        const bbox = Aabb.empty();

        for (const [id, flag] of dirtyParents) {
            if (flag & AnnotationDirtyFlags.ChildrenList) {
                const childrenIds: IdBimScene[] = [];
                bimInstances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(
                    id, (childId, _) => {
                        const chInstance = bimInstances.peekById(childId);
                        const chType = chInstance?.type_identifier;
                        if (chType && chType === TransformerIdent) {
                            return false;
                        }

                        if (this.descendantTrackersMetrics.has(childId)) {
                            childrenIds.push(childId);
                            return false;
                        }
                        return true;
                    }, true
                )
                this.parentsDescendants.set(id, childrenIds);
            }

            const esoHandle = this.esos.handlesOf([id])[0];

            let parentMetricsOld = this.parentBlocksMetrics.get(id);
            if (!parentMetricsOld) {
                parentMetricsOld = new ParentMetrics(esoHandle);
            } else if (parentMetricsOld.esoHandle !== esoHandle) {
                const annotation = this.currentAnnotations.get(parentMetricsOld.esoHandle);
                if (annotation) {
                    this.currentAnnotations.delete(parentMetricsOld.esoHandle);
                    this.currentAnnotations.set(esoHandle, annotation);
                }
                parentMetricsOld.esoHandle = esoHandle;
            }
            const parentMetrics = parentMetricsOld.copy();

            if (flag & AnnotationDirtyFlags.ChildrenGeo) {
                const groupBbox = Aabb.empty();
                const childrenIds = this.parentsDescendants.get(id)!;
                for (const chId of childrenIds) {
                    const tracker = bimInstances.perId.get(chId)!;
                    const repr = tracker.representation ?? tracker.representationAnalytical;
                    if (repr) {
                        bbox.copy(reprsBboxes.getOrCreate(repr));
                        bbox.applyMatrix4(tracker.worldMatrix);
                        groupBbox.union(bbox);
                    }
                }

                parentMetrics.bbox = groupBbox;
            }

            if (flag & AnnotationDirtyFlags.ChildrenProps) {
                const trackersMetrics = new Map<number, number>();
                const childrenIds = this.parentsDescendants.get(id)!;
                for (const chId of childrenIds) {
                    const modulesCount = this.descendantTrackersMetrics.get(chId)!.modulesCount;
                    const trackersCount = trackersMetrics.get(modulesCount) ?? 0;
                    trackersMetrics.set(modulesCount, trackersCount + 1);
                }

                parentMetrics.trackersMetrics = trackersMetrics;
            }

            if (flag & AnnotationDirtyFlags.Props) {
                const properties = bimInstances.peekById(id)?.properties;
                parentMetrics.power = properties?.get(
                    "circuit | block_capacity | dc_power")?.as('MW') ?? 0;
                parentMetrics.index = properties?.get(
                    "circuit | position | block_number")?.asNumber() ?? 0;
                if (parentMetrics.index === 0) {
                    let maxIndex = 0;
                    for (const [_, a] of this.currentAnnotations) {
                        const aI = +a.textRepr.textOptions.layoutOptions[0].content;
                        if (aI > maxIndex) {
                            maxIndex = aI;
                        }
                    };
                    parentMetrics.index = maxIndex + 1;
                }
            }

            if (flag & AnnotationDirtyFlags.Geo) {
                handler.markDirty(esoHandle, ESO_Diff.RepresentationBreaking);
            }

            if (!parentMetrics.equals(parentMetricsOld)) {
                this.parentBlocksMetrics.set(id, parentMetrics);

                const metrics = this.parentBlocksMetrics.get(id)!;

                if (metrics.bbox.isEmpty()) {
                    if (this.currentAnnotations.delete(esoHandle)) {
                        handler.markDirty(esoHandle, ESO_Diff.RepresentationBreaking);
                    }
                    continue;
                }

                let textOptions: { layoutOptions: TextLayoutOptions[], styleOptions: TextStyleOptions };
                let blockOptions: BlockOptions;
                const fontSizeMultiplier = metrics.bbox.width() < 125 ? metrics.bbox.width() / 125 : 1;

                textOptions = { 
                    layoutOptions: [ 
                        new TextLayoutOptions( metrics.index.toString(), 
                        fontSizeMultiplier * fontSizes[0] ) ], 
                    styleOptions: new TextStyleOptions()
                };
                blockOptions = new BlockOptions( metrics.bbox.width(), metrics.bbox.depth() );
                
                for (const trackersMetrics of metrics.trackersMetrics) {
                    textOptions.layoutOptions.push(
                        new TextLayoutOptions( 
                            trackersMetrics[0].toString() + " / " + trackersMetrics[1].toString(), 
                            fontSizeMultiplier * fontSizes[1] )
                    );
                }
                textOptions.layoutOptions.push(
                    new TextLayoutOptions( metrics.power.toFixed(3) + " MW", fontSizeMultiplier * fontSizes[2] ),
                );
        
                this.currentAnnotations.set(esoHandle, { 
                    position: new Vector3(metrics.bbox.centerX(), metrics.bbox.centerY(), metrics.bbox.maxz()), 
                    textRepr: new TextBlockGeometry(textOptions, blockOptions)
                });
                
                handler.markDirty(esoHandle, ESO_Diff.RepresentationBreaking);
            }
        }
    }
}