import type { PropertiesGroupFormatters } from "../bimDescriptions/PropertiesGroupFormatter";
import type { AssetId, MvWireSpecCatalogItemProps } from "../catalog";
import { sceneInstanceHierarchyPropsRegistry } from "../catalog/SceneInstanceHierarchyPropsRegistry";
import type { AssetBasedCatalogItemCreators} from "../catalog/CatalogItemCollection";
import { MvWireTypeIdent } from "./MvWire";
import { commonWireSpecAssetToCatalogItem, registerWireSpecCommonKeyPropertiesGroupFormatter, WireSpecCommonHierarchyProps } from "./WireSpecCommon";

export const MVWireSpecIdent = 'mv-wire-spec';

export function registerMVWireSpecKeyPropertiesGroupFormatter(
    group: PropertiesGroupFormatters,
) {
    registerWireSpecCommonKeyPropertiesGroupFormatter(MVWireSpecIdent, group);
}

export function registerMvWireSpecAssetToCatalogItem(group: AssetBasedCatalogItemCreators) {
    group.register(
        MVWireSpecIdent,
        (assetId: AssetId) => commonWireSpecAssetToCatalogItem(MVWireSpecIdent, assetId)
            .as<MvWireSpecCatalogItemProps>()
    );
}

sceneInstanceHierarchyPropsRegistry.set(MvWireTypeIdent, WireSpecCommonHierarchyProps);
