import type { Vector2 } from 'math-ts';
import {
    findIntersectionPointBetweenTwoDirectionsFromPoints,
} from '../../vectors';
import type { Node } from '../models/node';
import type { Connection } from '../models/connection';
import type { Route } from '../models/route';
import { CablePairingType } from '../models/route';

export function makePairingForNode(node: Node) {
    if (node.step.patternStepType === 'string_exit')
        return makePairingForString(node);
    for (const req of node.toChildren) {
        if (req.route.isPairingSet)
            continue;
        makePairingForRequest(req);
    }
}

function makePairingForGeneric2PhaseRequest(req: Connection) {
    const route = req.route;
    route.initPairing(CablePairingType.Positive);
    route.addPairing(CablePairingType.Negative);
}

function makePairingForGeneric3PhaseRequest(req: Connection) {
    const route = req.route;
    route.initPairing(CablePairingType.Phase1);
    route.addPairing(CablePairingType.Phase2);
    route.addPairing(CablePairingType.Phase3);
}

function makePairingForRequest(req: Connection) {
    if (req.isAc())
        makePairingForGeneric3PhaseRequest(req);
    else
        makePairingForGeneric2PhaseRequest(req);
}

function makePairingForString(string: Node) {
    const req = string.toParent;
    if (!req) return;
    const parent = req.from;
    const route = req.route;
    const furthestPoint = string.hints
        .find(x => !x.equals(string.actualPosition));
    if (!furthestPoint)
        throw new Error('Furthest point was not found for string');
    if (parent.step.patternStepType === 'utility')
        forReqFromStringToUtility(req, parent, furthestPoint);
    else if (parent.step.patternStepType === 'end_of_multiharness') {
        const fromMulharnRoute = parent.toParent;
        if (!fromMulharnRoute)
            throw new Error('multiharness does not have route to parent');
        forReqFromStringToEndOfMultiharness(
            string, route, parent, fromMulharnRoute.route,
        );
    } else
        forRouteFromStringToNonUtility(route, furthestPoint);
}

function forReqFromStringToEndOfMultiharness(
    str: Node,
    strToMulharnRoute: Route,
    mulharn: Node,
    fromMulharnRoute: Route,
) {
    const mulharnConfig = mulharn.step.patternConfig.multiharness;
    if (!mulharnConfig)
        throw new Error('no multiharness config found');
    // create negative for `strToMulharnRoute`
    strToMulharnRoute.initPairing(CablePairingType.Positive);
    const actualPt = str.actualPosition;
    const otherPt = str.hints.find(x => x !== actualPt);
    if (!otherPt)
        throw new Error('string does not have polar connection point');
    const adder = otherPt.clone().sub(actualPt);
    let negative = strToMulharnRoute.addPairing(CablePairingType.Negative);
    // if same_length option is set, only move furtherst point
    if (!mulharnConfig.same_length) {
        negative.points[negative.points.length - 1].add(adder);
        negative.recalculateLength();
        return;
    }
    negative.points.forEach(x => x.add(adder));
    negative.recalculateLength();
    // create negative from `fromMulharnRoute`
    fromMulharnRoute.initPairing(CablePairingType.Positive);
    negative = fromMulharnRoute.addPairing(CablePairingType.Negative);
    const latest = negative.points[negative.points.length - 1];
    negative.points.push(latest.clone().add(adder));
    negative.recalculateLength();
}


// if next node is utility, then copy both cables on each side
// of utility node
function forReqFromStringToUtility(
    req: Connection,
    utility: Node,
    furthestPoint: Vector2,
) {
    const route = req.route;
    const reqAfterUtil = utility.toParent;
    if (!reqAfterUtil) return;
    const routeAfterUtil = reqAfterUtil.route;
    if (route.points.length < 2 || routeAfterUtil.points.length < 2)
        throw new Error('Intermediate point was not found');
    const [tmp1, tmp2] = route.points.slice(0, 2);
    const [tmp3, tmp4] = routeAfterUtil.points.slice(-2);
    const mid = findIntersectionPointBetweenTwoDirectionsFromPoints(
        furthestPoint, tmp2.clone().sub(tmp1),
        tmp3, tmp4.clone().sub(tmp3),
    );
    if (mid === null)
        throw new Error('Intermediate point was not found.');

    // create pairing for route before utility
    route.initPairing(CablePairingType.Positive);
    let negative = route.addPairing(CablePairingType.Negative);
    negative.points[0] = mid.clone();
    negative.points[negative.points.length-1] = furthestPoint.clone();
    negative.recalculateLength();
    // create pairing for route after utility
    routeAfterUtil.initPairing(CablePairingType.Positive);
    negative = routeAfterUtil.addPairing(CablePairingType.Negative);
    negative.points[negative.points.length-1] = mid.clone();
    negative.recalculateLength();
}


/**
 * if next node is not utility, then simply clone single route and
 * connect it to furtherst point on string
 */
function forRouteFromStringToNonUtility(
    route: Route,
    furthestPoint: Vector2,
) {
    route.initPairing(CablePairingType.Positive);
    const negative = route.addPairing(CablePairingType.Negative);
    negative.points[negative.points.length-1] = furthestPoint;
    negative.recalculateLength();
}
