import type { SceneInstances } from 'bim-ts';
import { KeyModifiersFlags } from 'ui-bindings';
import { MouseButton } from '../controls/InputController';
import type { MouseClickConsumer, MouseDragConsumer, MouseDragInfo, MouseEventData, MouseGestureConsumer
} from '../controls/MouseGesturesBase';
import {
    GesturesButtons
} from '../controls/MouseGesturesBase';
import type { KrCamera, MovementControls } from '../controls/MovementControls';
import type { SceneInt, SceneRaycaster } from '../scene/SceneRaycaster';
import { ClipBoxGizmo } from './ClipboxGizmo';
import { ClipboxControlInters } from './ClipboxIntersection';
import { GizmoIntersection } from './GizmoIntersection';
import type { GizmosController } from './GizmosController';
import { TransformGizmo } from './TransformGizmo';
import { TransformMoveControlInters } from './TransformGizmoStateController';

type S = GizmoIntersection;

export class GizmosGesturesConsumer implements MouseGestureConsumer {

    readonly clickConsumers: MouseClickConsumer[] = [];
	readonly dragConsumers: MouseDragConsumer<any>[] = [];


    readonly gizmos: GizmosController;
    sceneRaycaster: SceneRaycaster;
    controls: MovementControls;

    onHover (int: SceneInt | null): {cursorStyleToSet: string} | null {
        if (int instanceof GizmoIntersection) {
            return { cursorStyleToSet : 'grab' };
        }
        return null;
    }

    constructor(
        gizmos: GizmosController,
        sceneRaycaster: SceneRaycaster,
        sceneInstances: SceneInstances,
        controls: MovementControls
    ) {
        this.gizmos = gizmos;
        this.sceneRaycaster = sceneRaycaster;
        this.controls = controls;


        {
            const clipbox: ClipBoxGizmo = gizmos.gizmos[1];
            const typeguard = (int: SceneInt) => int instanceof ClipboxControlInters;
            this.clickConsumers.push(new GizmosMouseClickConsumer(
                clipbox, controls, GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.None), typeguard
            ));
            this.clickConsumers.push(new GizmosMouseClickConsumer(
                clipbox, controls, GesturesButtons.newShared(MouseButton.Right, KeyModifiersFlags.None), typeguard
            ));
            this.dragConsumers.push(new GizmosMouseDragConsumer(
                controls.camera, clipbox, sceneInstances, sceneRaycaster, GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.None),
                typeguard
            ));
            this.dragConsumers.push(new GizmosMouseDragConsumer(
                controls.camera, clipbox, sceneInstances, sceneRaycaster, GesturesButtons.newShared(MouseButton.Right, KeyModifiersFlags.None),
                typeguard
            ));
        }

        {
            const transform: TransformGizmo = gizmos.gizmos[0];
            const typeguard = (int: SceneInt) => int instanceof TransformMoveControlInters;
            this.clickConsumers.push(new GizmosMouseClickConsumer(
                transform, controls, GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.None), typeguard
            ));
            this.clickConsumers.push(new GizmosMouseClickConsumer(
                transform, controls, GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.Ctrl), typeguard
            ));
            this.clickConsumers.push(new GizmosMouseClickConsumer(
                transform, controls, GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.Alt), typeguard
            ));
            this.clickConsumers.push(new GizmosMouseClickConsumer(
                transform, controls, GesturesButtons.newShared(MouseButton.Right, KeyModifiersFlags.None),
                (int) => int !== null && !(int instanceof GizmoIntersection)
            ));
            this.dragConsumers.push(new GizmosMouseDragConsumer(
                controls.camera, transform, sceneInstances, sceneRaycaster, GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.None),
                typeguard
            ));
            this.dragConsumers.push(new GizmosMouseDragConsumer(
                controls.camera, transform, sceneInstances, sceneRaycaster, GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.Ctrl),
                typeguard
            ));
            this.dragConsumers.push(new GizmosMouseDragConsumer(
                controls.camera, transform, sceneInstances, sceneRaycaster, GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.Alt),
                typeguard
            ));
            this.dragConsumers.push(new GizmosMouseDragConsumer(
                controls.camera, transform, sceneInstances, sceneRaycaster, GesturesButtons.newShared(MouseButton.Right, KeyModifiersFlags.None),
                typeguard
            ));
        }
    }

    isEnabled(): boolean {
        return true;
    }
}

class GizmosMouseClickConsumer implements MouseClickConsumer {
    buttons: GesturesButtons;
    sceneRaycastTypeguard: (closest: SceneInt) => boolean;

    readonly gizmo: TransformGizmo | ClipBoxGizmo;
    readonly controls: MovementControls;

    constructor(
		gizmo: TransformGizmo | ClipBoxGizmo,
        controls: MovementControls,
        buttons: GesturesButtons,
        typeguard: (closest: SceneInt) => boolean,
    ) {
        this.gizmo = gizmo;
		this.controls = controls;
        this.buttons = buttons;
        this.sceneRaycastTypeguard = typeguard;
    }

    clickHandler(sceneInt: SceneInt, me: MouseEventData): void {
        if (!me.mouseCone) {
            return;
        }
        const int = sceneInt;
        const buttons = this.buttons;
		if (this.gizmo instanceof ClipBoxGizmo && (int instanceof ClipboxControlInters)) {
			this.gizmo.handleMouseClick(int, this.buttons, this.controls);
		} else if (this.gizmo instanceof TransformGizmo)  {
			this.gizmo.handleMouseClick(int, this.controls.camera, me.mouseCone.raySection, buttons.mouseButton, buttons.modifierKeys);
		}
    }
}

class GizmosMouseDragConsumer implements MouseDragConsumer<S> {

    readonly buttons: GesturesButtons;
    readonly sceneRaycastTypeguard: (closest: SceneInt) => boolean;

    readonly camera: KrCamera;
    readonly gizmo: TransformGizmo | ClipBoxGizmo;
    readonly sceneInstances: SceneInstances;
    readonly raycaster: SceneRaycaster;

    cursorDragStyle(s: S): string {
        if (s instanceof TransformMoveControlInters && s.constraint.isInScreenPlane) {
            return 'crosshair';
        }
        return 'grabbing';
    }

    constructor(
        camera: KrCamera,
		gizmo: TransformGizmo | ClipBoxGizmo,
		sceneInstances: SceneInstances,
		raycaster: SceneRaycaster,
        buttons: GesturesButtons,
        typeguard: (closest: SceneInt) => boolean,
    ) {
        this.camera = camera;
        this.gizmo = gizmo;
		this.sceneInstances = sceneInstances;
		this.raycaster = raycaster;
        this.buttons = buttons;
        this.sceneRaycastTypeguard = typeguard;
    }

    onButtonDown(sceneInt: SceneInt, me: MouseEventData): boolean {
        return sceneInt instanceof ClipboxControlInters
            || sceneInt instanceof TransformMoveControlInters;
    }

    tryStartDrag(med: MouseEventData, mdi: MouseDragInfo, ): S | null {
        if (this.gizmo instanceof ClipBoxGizmo
            && ((mdi.sceneInt instanceof ClipboxControlInters))) {
            return this.gizmo.startDrag(mdi.sceneInt) ? mdi.sceneInt : null;

        } else if (this.gizmo instanceof TransformGizmo && (mdi.sceneInt instanceof TransformMoveControlInters)) {
            return this.gizmo.startDrag(
                mdi.sceneInt, this.camera,
                this.buttons.mouseButton,
                this.buttons.modifierKeys,
                mdi.worldSpaceDirection
            ) ? mdi.sceneInt : null;

        }
        return null;
    }
    handleDrag(s: S, me: MouseEventData): boolean {
        const sceneInt = s;
        const ray = me.mouseCone?.raySection;
        if (!ray) {
            return false;
        }
        if (this.gizmo instanceof ClipBoxGizmo
            && ((sceneInt instanceof ClipboxControlInters))) {
            return this.gizmo.handleDrag(ray, sceneInt, this.buttons.mouseButton, this.camera, this.buttons.modifierKeys);

        } else if (this.gizmo instanceof TransformGizmo && (sceneInt instanceof TransformMoveControlInters)) {
            return this.gizmo.handleDrag(ray, this.camera);

        }
        return false;
    }
    onButtonUp(s: S, me: MouseEventData): void {
        if (this.gizmo instanceof TransformGizmo) {
            this.gizmo.gizmoState.stopDrag();
        }
    }
    stop(): void {
        if (this.gizmo instanceof TransformGizmo) {
            this.gizmo.gizmoState.stopDrag();
        }
    }
}
