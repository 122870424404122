import { PropsGroupBase } from "../../properties/Props";
import { PropsFieldFlags, PropsFieldOneOf } from "../../properties/PropsGroupComplexDefaults";
import { PropsGroupsRegistry } from "../../properties/PropsGroupsRegistry";
import { TrackerShadingFactorsProperty } from "./TrackerShadingProperty";

export class TrackerShadingProps extends PropsGroupBase {
    shading_factors: TrackerShadingFactorsProperty | null;

    constructor(args: Partial<TrackerShadingProps>) {
        super();
        this.shading_factors = args.shading_factors ?? null;
    }
}
PropsGroupsRegistry.register({
    class: TrackerShadingProps,
    complexDefaults: {
        shading_factors: new PropsFieldOneOf(
            PropsFieldFlags.SkipClone,
            null,
            TrackerShadingFactorsProperty,
        )
    }
})
