import type { ColDef } from "ag-grid-enterprise";
import type { PropsGroupField, UnitsMapper } from "bim-ts";
import { BimProperty, BooleanProperty, NumberProperty, SceneInstance, SelectorProperty, StringProperty } from "bim-ts";
import type { ScopedLogger } from "engine-utils-ts";
import type { CommonType } from "./QuantityItemsCollection";
import { TrackerPile } from "bim-ts";
import { traverseByProperties, valueFormatter } from "./TableUtils";
import { LayoutMetricsConfig } from "./QuantityItemsLazy";

interface ColumnDescription extends ColDef<any> {
    id: string;
    headerName: string;
    field: string;
    unit?: string;
    filter?:
        | "agSetColumnFilter"
        | "agTextColumnFilter"
        | "agNumberColumnFilter";
    getFilterValues?: () => { values: (number | string)[] };
}

export class TableColumnsStore {
    private readonly _columns: Map<string, ColumnDescription>;
    private readonly digits = 4;


    constructor(readonly unitsMapper: UnitsMapper, readonly logger: ScopedLogger){
        this._columns = new Map();
    }

    add(stateRef: CommonType) {
        const booleanValues = ['true', 'false'];
        const addFromBimProperty = (prop: BimProperty, useValueFormatterForExport: boolean) => {
            if (this._columns.has(prop._mergedPath)) {
                return;
            }
            this._columns.set(prop._mergedPath, {
                id: prop._mergedPath,
                unit: prop.unit ?? undefined,
                headerName: prop._mergedPath,
                field: prop._mergedPath,
                filter: prop.discrete_variants || prop.isBoolean()
                    ? "agSetColumnFilter"
                    : prop.isNumeric() ? 'agNumberColumnFilter' : 'agTextColumnFilter',
                getFilterValues: prop.discrete_variants
                    ? () => ({ values: prop.discrete_variants! })
                    : prop.isBoolean() ? () => ({ values: booleanValues }) : undefined,
                valueFormatter: (api) => valueFormatter(api, this.unitsMapper),
                useValueFormatterForExport: useValueFormatterForExport,
            });
        }
        const addFromProperty = (prop: PropsGroupField, path: (string | number)[], useValueFormatterForExport: boolean) => {
            if(!(prop instanceof StringProperty || prop instanceof NumberProperty || prop instanceof SelectorProperty || prop instanceof BooleanProperty)){
                //this.logger.warn('Not implemented type: ', prop);
                return;
            }
            const mergedPath = BimProperty.MergedPath(path.map(p => p.toString()));
            if (this._columns.has(mergedPath)) {
                return;
            }
            const isNumber = prop instanceof NumberProperty;
            const isBoolean = prop instanceof BooleanProperty;
            const isSelector = prop instanceof SelectorProperty;
            const unit = isNumber ? prop.unit : undefined;
            const options = isSelector ? prop.options : undefined; 
            this._columns.set(mergedPath, {
                id: mergedPath,
                unit: unit,
                headerName: mergedPath,
                field: mergedPath,
                filter: options || isBoolean
                    ? "agSetColumnFilter"
                    : isNumber ? 'agNumberColumnFilter' : 'agTextColumnFilter',
                getFilterValues: options
                    ? () => ({ values: options! })
                    : isBoolean ? () => ({ values: booleanValues }) : undefined,
                valueFormatter: (api) => valueFormatter(api, this.unitsMapper),
                useValueFormatterForExport: useValueFormatterForExport,
            });
        }
        if(stateRef instanceof SceneInstance){
            for (const prop of stateRef.properties.values()) {
                addFromBimProperty(prop, true);
            }
            traverseByProperties(stateRef.props, (prop, path) => {
                addFromProperty(prop, path, true);
            });
        } else if(stateRef instanceof TrackerPile){
            for (const prop of stateRef.asBimProperties({})) {
                if (this._columns.has(prop._mergedPath)) {
                    continue;
                }
                addFromBimProperty(prop, false);
            }
        } else if(stateRef instanceof LayoutMetricsConfig){
             for (const prop of stateRef.properties) {
                addFromProperty(prop.property, prop.path, false);
             }
        } else {
            this.logger.error('Not implemented type: ', stateRef);
        }
    }

    get(key:string){
        return this._columns.get(key);
    }

    getAll(allTypes: Set<string>): Map<string, ColDef>{
        const cols = new Map<string, ColDef>();
        const defaultCols: ColumnDescription[] = [
            //{ id: "index", headerName: "index", field: "index" },
            { id: "id", headerName: "id", field: "id", filter: "agNumberColumnFilter"  },
            {
                id: "type",
                headerName: "type",
                field: "type",
                filter: "agSetColumnFilter",
                getFilterValues: () => {
                    return { values: Array.from(allTypes) };
                },
            },
            { id: "name", headerName: "name", field: "name", filter: "agTextColumnFilter",  },
            { id: "parent_id", headerName: "parent_id", field: "parent_id", filter: "agNumberColumnFilter" },
            {
                id: "parent_type",
                headerName: "parent_type",
                field: "parent_type",
                filter: "agSetColumnFilter",
                getFilterValues: () => {
                    return { values: [...Array.from(allTypes), 'none'] };
                },
            },
            { id: "is_hidden", headerName: "is_hidden", field: "is_hidden",  filter: "agSetColumnFilter",  getFilterValues: () => {return {values: ['true', 'false']}}},

        ];
        for (const type of allTypes) {
            const identifier = "parents " + type;
            defaultCols.push(
                { id: identifier, headerName: identifier, field: identifier, filter: "agTextColumnFilter"  },
            )
        }
        const log = this.logger;
        function addColumn(col:ColumnDescription){
            if(!cols.has(col.id)){
                cols.set(col.field ,{
                    headerName: col.headerName,
                    field: col.field,
                    filter: col.filter,
                    filterParams: col.getFilterValues
                        ? col.getFilterValues()
                        : undefined,
                    valueFormatter: col.valueFormatter,
                });
            } else {
                log.batchedWarn('Duplicate column type: ', col);
            }
        }

        if(this._columns.size > 0){
            for (const col of defaultCols) {
                addColumn(col);
            }
            for (const [_id, col] of this._columns) {
                addColumn(col);
            }
        }

        return cols;
    }

    dispose() {
        this._columns.clear();
    }
}