<script lang="ts">
  import { getContext, tick } from 'svelte'
  import type { Bim, IdBimScene, SceneInstancePatch} from 'bim-ts';
  import { SceneInstances } from 'bim-ts';
	import VirtualList from 'svelte-tiny-virtual-list'
  import { MDCTextField } from '@material/textfield';
  import ListItem from './ListItem.svelte';
  import type { DataModel, HoveredSegment, InputCombo, SceneExplorerObj, SceneExplorerObjectsList } from './SceneExplorer';
  import { IterUtils } from 'engine-utils-ts';

  export let sceneExplorerData: DataModel
  export let sceneExplorerList: SceneExplorerObjectsList

  let instances: SceneInstances = (getContext('bim') as Bim).instances;

  export async function resetFocus() {
      focusIdx = undefined
      await tick()
  }
  let search: string
  let el: Element
  let width: number = 100
  let height: number = 100
  $: input = el && new MDCTextField(el)
  let listItemHeightPx = 28

    function unselectAll() {
        instances.toggleSelected(false, instances.getSelected())
    }
  const createItemActions = (item: SceneExplorerObj) => ({
    selectNode: (e: InputCombo) => sceneExplorerList.selectNode(item, e),
    toggleNodeChildren: (e: InputCombo) => sceneExplorerList.toggleNodeChildren(item, e),
    toggleNodeVisibility: (e: InputCombo) => sceneExplorerList.toggleNodeVisibility(item, e),
  })
  let focusIdx: number | undefined = undefined
  $: {
    // focusIdx should be set after items update on virtual list
    tick().then(() => {
        const idx = sceneExplorerData.scrollToIndex;
        focusIdx = idx == undefined ? undefined : idx + 1;
    })
  }
    function handleDropEvent(
        onId: IdBimScene,
        whatId: IdBimScene,
        segment: HoveredSegment
    ) {
        const onInst = instances.perId.get(onId);
        const whatInst = instances.perId.get(whatId);
        if (!onInst || !whatInst) {
            return;
        }

        let sortKeysRangeToUse: {min: number, max: number};
        let newParentId: IdBimScene | 0;
        if (segment === 'top') {
            newParentId = onInst.spatialParentId;
            sortKeysRangeToUse = instances.extractHierarchySortKeysRangeToInsertInFrontOf(onId);
        } else if (segment === 'bottom') {
            newParentId = onInst.spatialParentId;
            sortKeysRangeToUse = instances.extractHierarchySortKeysRangeToInsertAfter(onId);
        } else {
            newParentId = onId;
            // if just assigning new parent without particular order, insert children in the beginning
            const firstChild = IterUtils.getFirstFromIter(
                instances.spatialHierarchy.iteratorOfChildrenOf(newParentId)
            );
            const insertInFrontOfId: IdBimScene | 0 = firstChild ?? 0;
            sortKeysRangeToUse = instances.extractHierarchySortKeysRangeToInsertInFrontOf(insertInFrontOfId);
        }

        let idsToReparent = whatInst.isSelected ? instances.getSelected() : [whatId];
        idsToReparent = idsToReparent.filter(
            id => instances.spatialHierarchy.isValidParentFor(
                id,
                newParentId,
            )
        );
        // TODO: sort ids by their index in explorer list

        const newParentmatrix = instances.perId.get(newParentId)?.worldMatrix;

        const sortKeyIncrement = (sortKeysRangeToUse.max - sortKeysRangeToUse.min) / idsToReparent.length;
        let nextSortKey = sortKeysRangeToUse.min;

        const patches = IterUtils.filterMap(idsToReparent,
            (id) => {
                const state = instances.perId.get(id)
                if (!state) {
                    return undefined;
                }
                const sortKey = nextSortKey;
                nextSortKey += sortKeyIncrement;
                return [id, {
                    spatialParentId: newParentId,
                    localTransform: SceneInstances.getLocalTransformRelativeToParentMatrix(
                        newParentmatrix,
                        state.worldMatrix,
                    ),
                    hierarchySortKey: sortKey
                }] as [IdBimScene, SceneInstancePatch];
            }
        );
        instances.applyPatches(patches);
    }
</script>
<div
  on:click={unselectAll}
  class="virtual-list-container"
  bind:clientWidth={width} bind:clientHeight={height}
>
  <VirtualList
    height={height}
    width="auto"
    itemCount={sceneExplorerData.displayList.length+1}
    itemSize={(idx) => {
        if (idx === 0) {
            return 0;
        }
        return listItemHeightPx;
    }}
    scrollToAlignment='center'
    scrollToIndex={focusIdx}
  >
    <div
      slot="item"
      let:index
      let:style
      {style}
    >
        {#if index === 0}
            <div></div>
        {:else}
          <ListItem
              item={sceneExplorerData.displayList[index-1]}
              heightPx={listItemHeightPx}
              on:drop={e => handleDropEvent(
                sceneExplorerData.displayList[index-1].id,
                e.detail.payload,
                e.detail.segment,
              )}
              {...createItemActions(sceneExplorerData.displayList[index-1])}
              isOddInList={index % 2 !== 0}
              primaryProperties={sceneExplorerData.primaryProperties}
          />
        {/if}
    </div>
  </VirtualList>
</div>
<style lang="scss">
  .virtual-list-container {
    flex: 1;
    user-select: none;
    overflow: hidden;
    width: 100%;
  }
</style>
