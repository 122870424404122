<script lang="ts">
    import { PileUndulationType } from "bim-ts";
    import UndulationPileButton from "./UndulationPileButton.svelte";

    export let undulationType: PileUndulationType;

    $: undulation = undulationType === PileUndulationType.Undulated;

    const name = "Undulated/Bending pile";
</script>

<span title={name}>
    <div class="divider" />
    <span class="option-container">
        <span class="icon-holder">
            <UndulationPileButton active={undulation} disabled={true} />
        </span>
        <span class="label">{name}</span>
    </span>
</span>

<style lang="scss">
    .option-container {
        display: flex;
        align-items: center;
        gap: 4px;
        width: 100%;
        justify-content: left;
    }

    .divider {
        background: var(--color-divider);
        border-color: var(--color-divider);
        border-radius: 1px;
        height: 1px;
        width: 100%;
    }

    .icon-holder {
        width: 24px;
        height: 24px;
    }

    .label {
        max-width: fit-content;
    }
</style>
