
export class Time {
	
	animFrameStart: number = 0;
	animDelta: number = 0;

	trueFrameStart: number = 0;
	trueDelta: number = 0;

	frameN: number = 1;

	updateTime(timestampMs: number) {
		const newTrueStart = timestampMs / 1000;
		this.trueDelta = newTrueStart - this.trueFrameStart;
		this.trueFrameStart = newTrueStart;

		const newAnimFrameStart = this.animFrameStart + this.trueDelta;
		this.animDelta = newAnimFrameStart - this.animFrameStart;
		this.animFrameStart = newAnimFrameStart;
	}

	startNewFrame() {
		this.frameN += 1;
	}

	fastForwardAnimations() {
		this.animFrameStart += 100; // all engine animation (like clipbox), should be fastforwarded for 100 seconds next frame
	}

}