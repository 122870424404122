<script lang="ts">
    import type { UnitsMapper } from "bim-ts";
    import flash from "../../flash";
    import { PUI_PropertyNodeNumber } from "ui-bindings";
    import SelectProperty from "./SelectValueControl.svelte";
    import NumberSpinner from "./NumberPropertyBase.svelte";
    import type { NumberPropertyWithOptions } from "bim-ts";
    import { ObjectUtils } from "engine-utils-ts";
    import { PUI_NumberWithOptionsContext } from "./types";


    export let unitsMapper: UnitsMapper;
    export let name: string;
    export let notActive: boolean | undefined = undefined;
    export let sourceProperty: NumberPropertyWithOptions;
    export let context: PUI_NumberWithOptionsContext;

    let refreshNumberComponent = { option: sourceProperty.selectedOption, context: context.valueRenderFormatter?.context };
    $:invalidateValue = { option: sourceProperty.selectedOption, context: context.valueRenderFormatter?.context };
    $:if(!ObjectUtils.areObjectsEqual(refreshNumberComponent, invalidateValue)){
        refreshNumberComponent = invalidateValue;
    }
    
    $:numberProperty = new PUI_PropertyNodeNumber({
            name: name,
            value: sourceProperty.value,
            unit: sourceProperty.unit,
            readonly: context.valueRenderFormatter?.isReadonly 
                ? sourceProperty.isReadonly || context.valueRenderFormatter?.isReadonly({value: sourceProperty.value, unit: sourceProperty.unit, context: context.valueRenderFormatter.context, option: sourceProperty.selectedOption})
                : sourceProperty.isReadonly,
            icon: context.icon,
            minMax: sourceProperty.range,
            step: sourceProperty.step,
            onChange: (v) => {
                sourceProperty = sourceProperty.withDifferentValue(v);
            },
        });

    
    $:options = sourceProperty.options;

    let div: HTMLElement;

    let prevValue = numberProperty?.value;
    $:if (prevValue != numberProperty?.value) {
        prevValue = numberProperty.value;
        if(div){
            flash(div);
        }
    }

    $: error = numberProperty.hasError();
      
    $:valueColorStyle = context.valueRenderFormatter?.textColorFormatter 
        ? context.valueRenderFormatter.textColorFormatter({value: sourceProperty.value, unit: sourceProperty.unit, context: context.valueRenderFormatter.context, option: sourceProperty.selectedOption}) 
        : 'inherit';
    
    $:formatter = context.valueRenderFormatter?.formatter;
    let formatterFn: ((v: number, u: string) => string) | undefined;
    $:if(formatter){
        formatterFn = (v, u) => formatter!({value: v, unit: u, context: context.valueRenderFormatter?.context, option: sourceProperty.selectedOption});
    } else {
        formatterFn = undefined;
    }

    $: parser = context.valueRenderFormatter?.parser;
    let parserFn: ((v: string, prev: number, u: string) => number) | undefined;
    $:if(parser){
        parserFn = (v, prev, u) => parser!({value: v, prevValue: prev, unit: u, context: context.valueRenderFormatter?.context, option: sourceProperty.selectedOption});
    } else {
        parserFn = undefined;
    }
    $: singleSelectedOption = sourceProperty.options.length === 1 && sourceProperty.options[0] === sourceProperty.selectedOption;
</script>

<div 
    class="ui-config-property property-row"
    class:not-active = {notActive}
    >
    <div bind:this={div} class="property-name text-main-medium">
        <div
            title={numberProperty.hint}
            class="property-name-label mdc-typography--body1"
        >
            {numberProperty.name}
        </div>
    </div>

    <div class="property-value">
        <div class="edit-field-container number-with-options-property">
            {#if !context.doNotShowOptions}
                <SelectProperty
                    bind:selected={sourceProperty.selectedOption}
                    values = {options}
                    onChange={(o) => {
                        sourceProperty = sourceProperty.withDifferentOption(o);
                    }}
                    readonly = {sourceProperty.isReadonly || singleSelectedOption}
                />         
            {/if}
            <div
                class="input-value text-main-dark"
                class:read-only={numberProperty.readonly}
                class:edit-field-container={!numberProperty.readonly}
            >
                {#key refreshNumberComponent}
                    <NumberSpinner
                        bind:sourceProperty = {numberProperty}
                        unitsMapper = {unitsMapper}
                        valueColorStyle = {valueColorStyle}
                        formatter = {formatterFn}
                        parser = {parserFn}
                        decimals = {sourceProperty.decimals}
                    />
                {/key}
            </div>
        </div>
    </div>
</div>

<style lang="scss">

    .input-value {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
</style>
