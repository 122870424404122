<script lang="ts">
    export let showLabel: boolean = false;
</script>

<div class="divider" class:label={showLabel}>
    <slot />
    {#if showLabel}
        <div class="group-label">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
            >
                <path
                    d="M0.5 6V4C0.5 3.44772 0.947715 3 1.5 3H5C5.55228 3 6 3.44772 6 4V6C6 6.55228 5.55228 7 5 7H1.5C0.947715 7 0.5 6.55228 0.5 6Z"
                    stroke="#1A1E1F"
                    stroke-opacity="0.4"
                />
                <path
                    d="M1.5 1.5H6.5C7.05228 1.5 7.5 1.94772 7.5 2.5V5.5"
                    stroke="#1A1E1F"
                    stroke-opacity="0.4"
                />
            </svg>
        </div>
    {/if}
</div>

<style lang="scss">
    .divider {
        position: relative;
        margin: 12px 0;
        &:before {
            content: "";
            display: block;
            border-top: 1px solid var(--color-divider);
            position: absolute;
            top: -12px;
            width: calc(100% - 16px);
            margin: 0 8px;
        }
        &.label:before {
            width: calc(100% - 36px);
        }
    }

    .group-label {
        position: absolute;
        top: -22px;
        right: 10px;
    }
</style>
