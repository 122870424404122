<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M13.3511 4.78969L14.1281 5.56665L11.7291 7.96564L11.7114 7.98332L11.7291 8.00099L14.1447 10.4166L13.3677 11.1936L10.1574 7.98332L13.3511 4.78969ZM1.86558 12.0866V11.0011H10.6177V12.0866H1.86558ZM1.86558 8.5094V7.4239H8.61774V8.5094H1.86558ZM1.86558 4.9989V3.9094H10.6177V4.9989H1.86558Z"
        fill="#9C27B0"
        stroke="#9C27B0"
        stroke-width="0.05"
    />
</svg>
