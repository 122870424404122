<script lang="ts">
import type { ProjectOverride } from "../CostReportTemplate";
import ActionListEditor from './ActionListEditor.svelte';

export let exit: (newOverride?: ProjectOverride) => void;
export let config: ProjectOverride | undefined

let actionsEditorMode = false;

let projectId = config?.projectId ?? 0;
let actions = config?.actions ?? [];

function localClose() {
    const newConfig: ProjectOverride = {
        actions,
        projectId,
    }
    if (config) {
        Object.assign(config, newConfig);
        return exit();
    }
    return exit(newConfig);
}

function enterActionListEditor() {
    actionsEditorMode = true;
}
function exitActionListEditor() {
    actionsEditorMode = false;
}
</script>

{#if actionsEditorMode}
    <ActionListEditor
        close={exitActionListEditor}
        bind:actions={actions}
    />
{:else}
    <a class="basic-interact" on:click={() => exit()}>{'<'}</a>

    <h3>project override editor</h3>

    <p>projectId</p>
    <input type="number" bind:value={projectId} />

    <h3>actions(count {actions.length})</h3>
    <button on:click={enterActionListEditor}>
        edit actions
    </button>


    <h3></h3>
    <a class="basic-interact" on:click={localClose}>Done</a>
{/if}
