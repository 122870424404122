import { DC_CNSTS } from 'bim-ts';
import type { CablePairingType } from '../generator/models/route';

export enum EquipmentGeometry {
    Circle,
    Box,
    Triangle,
}

export interface CircleConfig {
    type: EquipmentGeometry.Circle;
    radius: number;
}

export interface BoxConfig {
    type: EquipmentGeometry.Box;
    side: number;
}

export interface TriangleConfig {
    type: EquipmentGeometry.Triangle;
    side: number;
}

export type EquipmentGeometryConfig =
    CircleConfig | BoxConfig | TriangleConfig;

export interface EquipmentSharedConfig {
    geo: EquipmentGeometryConfig;
    color: string;
    // items with bigger priority will render on top
    // from 0 to 100
    priority: number;
}

export type MeshPerNodeTypeMap =
    { [key in DC_CNSTS.NodeName]: EquipmentSharedConfig };

export const meshPerNodeType: MeshPerNodeTypeMap = {
    utility: {
        geo: {
            type: EquipmentGeometry.Circle,
            radius: 0.15,
        },
        color: '#100000',
        priority: 100,
    },
    string_exit: {
        geo: {
            type: EquipmentGeometry.Box,
            side: 0.3,
        },
        priority: 90,
        color: '#bf42f5',
    },
    end_of_multiharness: {
        geo: {
            type: EquipmentGeometry.Box,
            side: 0.4,
        },
        color: '#29c4c4',
        priority: 80,
    },
    end_of_tracker: {
        geo: {
            type: EquipmentGeometry.Box,
            side: 0.6,
        },
        color: '#29c4c4',
        priority: 70,
    },
    end_of_group: {
        geo: {
            type: EquipmentGeometry.Box,
            side: 0.8,
        },
        color: '#f20c0c',
        priority: 60,
    },
    cb_si_circuit_exit: {
        geo: {
            type: EquipmentGeometry.Circle,
            radius: 0.4,
        },
        color: '#9629c4',
        priority: 50,
    },
    inverter: {
        geo: {
            type: EquipmentGeometry.Box,
            side: 0.75,
        },
        color: '#9629c4',
        priority: 40,
    },
    ac_combiner_exit: {
        geo: {
            type: EquipmentGeometry.Box,
            side: 0.75,
        },
        color: '#c42929',
        priority: 40,
    },
    block_circuit_exit: {
        geo: {
            type: EquipmentGeometry.Circle,
            radius: 0.5,
        },
        color: '#cfb732',
        priority: 30,
    },
};


export interface RenderConfigForLine {
    thickness: number;
    // from 0 to 100
    priority: number;
    color: string;
}
export type RenderConfigPerCableMap =
    { [key in DC_CNSTS.ConductorType]: RenderConfigForLine };

export const renderConfigPerCable: RenderConfigPerCableMap = {
    [DC_CNSTS.ConductorType.Extender]: {
        priority: 95,
        thickness: 0.5,
        color: '#fa0602',
    },
    [DC_CNSTS.ConductorType.MultiHarness]: {
        priority: 90,
        thickness: 0.8,
        color: '#fab002',
    },
    [DC_CNSTS.ConductorType.Whip]: {
        priority: 80,
        thickness: 0.6,
        color: '#b7b700',
    },
    [DC_CNSTS.ConductorType.TrunkBus]: {
        priority: 70,
        thickness: 0.8,
        color: '#17fa02',
    },
    [DC_CNSTS.ConductorType.DcFeeder]: {
        priority: 60,
        thickness: 1,
        color: '#02fad1',
    },
    [DC_CNSTS.ConductorType.AcFeeder]: {
        priority: 50,
        thickness: 1.2,
        color: '#02d5fa',
    },
    [DC_CNSTS.ConductorType.BusBars]: {
        priority: 40,
        thickness: 1.4,
        color: '#0251fa',
    },
};

export const priorityByPairingType: Record<CablePairingType, number> = {
    positive: 0,
    negative: 1,
    'phase 1': 0,
    'phase 2': 1,
    'phase 3': 2,
};

export const trackerContourRenderConfig: RenderConfigForLine = {
    priority: 0,
    color: '#999',
    thickness: 0.2,
};

export const stringContourRenderConfig: RenderConfigForLine = {
    priority: 1,
    color: '#222',
    thickness: 0.1,
};

export const pairingCableRenderConfig: RenderConfigForLine = {
    color: '#000',
    priority: 97,
    thickness: 0.05,
};

export const EQUIPMENT_PRIORITY_OFFSET = -100;
export const LINE_PRIORITY_OFFSET = -200;
