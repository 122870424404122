<script lang="ts">
    import type { NavbarContext, NavbarItem, PanelPosition } from "./Navbar";
    import EngineUiPanelsConstructors from "../ui-panels/EngineUiPanelsConstructors.svelte";
    import { PanelViewPosition } from "ui-bindings";
    import { getContext } from "svelte";

    export let item: NavbarItem | undefined;
    export let onClose: (() => void) | undefined = undefined;
    export let position: PanelPosition | undefined = undefined;

    const navbar = getContext<NavbarContext>("ui-navbar");
    $: showMenu =
        item?.view.position !== PanelViewPosition.Fixed &&
        item?.view.position !== PanelViewPosition.Overlay;
    let dragStartX = 0;
    let dragStartY = 0;
    let dragMoveX = 0;
    let dragMoveY = 0;
    let drag = false;
    let startDrag = false;
    function dragMouseDown(e: MouseEvent) {
        if (startDrag) {
            dragStartX = e.clientX;
            dragStartY = e.clientY;
            dragMoveX = 0;
            dragMoveY = 0;
            document.addEventListener("mousemove", moveView);
            document.addEventListener("mouseup", stopDrag);
        }
    }

    function moveView(e: MouseEvent) {
        if (
            !$navbar.dragViewId &&
            drag &&
            (Math.abs(dragMoveX) > 5 || Math.abs(dragMoveY) > 5)
        ) {
            navbar.dragView(item!.id);
        }
        drag = true;
        dragMoveX = e.clientX - dragStartX;
        dragMoveY = e.clientY - dragStartY;
    }

    function stopDrag() {
        drag = false;
        startDrag = false;
        navbar.dragView(null);
        document.removeEventListener("mousemove", moveView);
        document.removeEventListener("mouseup", stopDrag);
    }
</script>

{#if item}
    {#key item.id}
        <div
            class="panel-view-container"
            class:dragging={startDrag}
            style="--drag-x:{dragMoveX}px;--drag-y:{dragMoveY}px;"
            on:mousedown={dragMouseDown}
        >
            <EngineUiPanelsConstructors
                close={onClose}
                movePointerDown={showMenu
                    ? () => {
                          startDrag = true;
                      }
                    : undefined}
                identifier={item.id}
                name={item.name}
                uiNodeDescription={item.view.viewSource}
                {position}
            ></EngineUiPanelsConstructors>
        </div>
    {/key}
{/if}

<style lang="scss">
    .panel-view-container {
        height: 100%;
    }
    .dragging {
        position: relative;
        z-index: 100;
        background-color: white;
        transform: translate(var(--drag-x), var(--drag-y));
    }
</style>
