// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { BimMaterialStdRenderParamsT } from './bim-material-std-render-params.js';
import { BimMaterialStdRenderParams } from './bim-material-std-render-params.js';


export class BimMaterial implements flatbuffers.IUnpackableObject<BimMaterialT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):BimMaterial {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsBimMaterial(bb:flatbuffers.ByteBuffer, obj?:BimMaterial):BimMaterial {
  return (obj || new BimMaterial()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsBimMaterial(bb:flatbuffers.ByteBuffer, obj?:BimMaterial):BimMaterial {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new BimMaterial()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

name():string|null
name(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
name(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

renderParams(obj?:BimMaterialStdRenderParams):BimMaterialStdRenderParams|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new BimMaterialStdRenderParams()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startBimMaterial(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addName(builder:flatbuffers.Builder, nameOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, nameOffset, 0);
}

static addRenderParams(builder:flatbuffers.Builder, renderParamsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, renderParamsOffset, 0);
}

static endBimMaterial(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): BimMaterialT {
  return new BimMaterialT(
    this.name(),
    (this.renderParams() !== null ? this.renderParams()!.unpack() : null)
  );
}


unpackTo(_o: BimMaterialT): void {
  _o.name = this.name();
  _o.renderParams = (this.renderParams() !== null ? this.renderParams()!.unpack() : null);
}
}

export class BimMaterialT implements flatbuffers.IGeneratedObject {
constructor(
  public name: string|Uint8Array|null = null,
  public renderParams: BimMaterialStdRenderParamsT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const name = (this.name !== null ? builder.createString(this.name!) : 0);
  const renderParams = (this.renderParams !== null ? this.renderParams!.pack(builder) : 0);

  BimMaterial.startBimMaterial(builder);
  BimMaterial.addName(builder, name);
  BimMaterial.addRenderParams(builder, renderParams);

  return BimMaterial.endBimMaterial(builder);
}
}
