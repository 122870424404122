import { Vector2 } from "math-ts";
import type { FileImporterContext } from "ui-bindings";
import type { DemHeader, DemProfile } from "./DemSurface";
import { UnitsCode } from "./DemSurface";
import { FeetToMeters } from "../dxf/DxfUnitsConverters";


const splitNumbersFromDemString = (data: string) =>
    data.trim().split(/\s+/).map(uniformScienceNotation);

const enc = new TextDecoder("utf-8");

const decodeText = (
    buffer: ArrayBuffer,
    start: number,
    length: number,
    trim: boolean
): string => {

    let slicedArray: Uint8Array;
    if (buffer instanceof Uint8Array) {
        slicedArray = buffer.subarray(start, start + length);
    } else {
        slicedArray = new Uint8Array(buffer, start, length);
    }

    const data = enc.decode(slicedArray)

    if(trim){
        return data.trim();
    }
 
    return data;
};

interface SurfaceData {
    elevations: Float32Array;
    poitsCountX: number;
    pointsCountY: number;
    minMaxCoords: [number, number, number, number, number, number];
    name: string;
}

export function decodeDem(
    context: FileImporterContext,
    bufferArray: ArrayBuffer
): SurfaceData {
    const chunkSize = 1024;
    const profileHeaderCells = [6, 6, 6, 6, 24, 24, 24, 24, 24];
    const profileDataLength = 6;

    const [headerBuffer, ...chunks] = chunkArrayBuffer(bufferArray, chunkSize);

    const header = decodeHeader(context, headerBuffer);

    let isProfileHeader = true;

    let numberOfElevations = 0;

    let elevations: number[] = [];
    let headerData: number[] = [];
    const profiles: DemProfile[] = [];

    for (let i = 0; i < chunks.length; i++) {
        let currentPosition = 0;

        const chunk = chunks[i];
        if (isProfileHeader) {
            for(const i of profileHeaderCells){

                const profileHeaderRawData = decodeText(
                    chunk,
                    currentPosition,
                    i,
                    true
                );
                currentPosition += i;
                const dataValue = uniformScienceNotation(profileHeaderRawData);
                headerData.push(dataValue);
            }
            numberOfElevations = headerData[2];
            isProfileHeader = false;
        }

        while (
            currentPosition < chunkSize &&
            elevations.length < numberOfElevations
        ) {
            const elevationData = decodeText(
                chunk,
                currentPosition,
                profileDataLength,
                true
            );
            currentPosition += profileDataLength;

            const elevation = Number.parseFloat(elevationData);

            if (Number.isNaN(elevation)) {
                continue;
            }

            elevations.push(elevation);
        }

        if (elevations.length === numberOfElevations) {
            if (headerData.some(x => !Number.isFinite(x))) {
                throw new Error(
                    `Profile header data at ${
                        currentPosition + chunkSize * i
                    } parsed incorrectly!`
                );
            }

            const profile: DemProfile = {
                Row: headerData[0],
                Column: headerData[1],
                NumberOfElevations: numberOfElevations,
                ProfileColumns: 1,
                Coordinate: {
                    x: headerData[4],
                    y: headerData[5],
                }, //coordinates of the first elevation
                LokalElevation: headerData[6],
                MinProfileElevation: headerData[7],
                MaxProfileElevation: headerData[8],
                Elevations: elevations,
            };

            profiles.push(profile);
            headerData = [];
            elevations = [];
            isProfileHeader = true;
            continue;
        }
    }

    const demData = demRegularData(header, profiles);

    return demData;
}

function decodeHeader(
    context: FileImporterContext,
    bufferArray: ArrayBuffer
): DemHeader {
    const name = decodeText(bufferArray, 0, 144, true);
    const planimetricData = decodeText(bufferArray, 161, 10, true);
    const [refSystem, zoneCode] = splitNumbersFromDemString(planimetricData);
    const encodedProfiles = decodeText(bufferArray, 852, 12, true);
    const dataUnitsCodes = decodeText(bufferArray, 528, 12, true);

    const unitsCodes = splitNumbersFromDemString(dataUnitsCodes);
    let unitsCodePlanimetric = UnitsCode.Meters;
    let unitsCodeElevation = UnitsCode.Meters;
    if (unitsCodes.length === 2) {
        unitsCodePlanimetric = unitsCodes[0] as UnitsCode;
        unitsCodeElevation = unitsCodes[1] as UnitsCode;
    }

    const limitsData = decodeText(bufferArray, 546, 192, true);
    const limits = parseLimits(limitsData);
    const min = uniformScienceNotation(decodeText(bufferArray, 738, 24, true));
    const max = uniformScienceNotation(decodeText(bufferArray, 762, 24, true));

    const resolution = decodeText(bufferArray, 810, 42, true);
    const res = parseResolution(resolution);

    for (const r of res) {
        if (Number.isNaN(r)) {
            context.logger.error("dem file has undefined resolution!");
        }
    }

    if (res[0] !== res[1]) {
        context.logger.error("dem file has unsupported resolution!");
    }

    const [rows, columns] = splitNumbersFromDemString(encodedProfiles);

    const demHeader: DemHeader = {
        QuadrangleName: name,
        DemLevelCode: 1,
        PatternCode: 1,
        PlanimetricReferenceSystemCode: refSystem,
        ZoneCode: zoneCode,
        ProjectParameters: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        UnitsCodePlanimetric: unitsCodePlanimetric,
        UnitsCodeElevation: unitsCodeElevation,
        PoligonSides: 4,
        DemBounds: [limits.min, limits.max],
        MinElevation: min,
        MaxElevation: max,
        Angle: 0,
        AccuracyCode: 1,
        SpatalResolution: { width: res[0], length: res[1], height: res[2] },
        Profiles: { rows: rows, columns: columns },
    };

    return demHeader;
}

function demRegularData(
    header: DemHeader,
    profiles: DemProfile[]
): SurfaceData {

    const unitsPlanimetric = getUnits(header.UnitsCodePlanimetric);
    const unitsElevation = getUnits(header.UnitsCodeElevation);
    const stepY = header.SpatalResolution.length * unitsPlanimetric;
    const stepX = header.SpatalResolution.width * unitsPlanimetric;
    const surfaceName = header.QuadrangleName;

    const profileMinY = (profile:DemProfile) => profile.Coordinate.y * unitsPlanimetric - stepY/2 ; 
    const profileMaxY = (profile:DemProfile) => profileMinY(profile) + stepY * (profile.NumberOfElevations - 1);
    const profileX = (profile:DemProfile) => profile.Coordinate.x * unitsPlanimetric - stepX/2; 

    let minY = Infinity;
    let maxY = -Infinity;
    let minX = Infinity;
    let maxX = -Infinity;
    let minZ = Infinity;
    let maxZ = -Infinity;

    for (const profile of profiles) {

        const profileMinYval = profileMinY(profile);
        const profileMaxYval = profileMaxY(profile);
        const profileXval = profileX(profile);
        const profileMinZval = Math.min(...profile.Elevations) * unitsElevation;
        const profileMaxZval = Math.max(...profile.Elevations) * unitsElevation;

        minY = Math.min(minY, profileMinYval);
        maxY = Math.max(maxY, profileMaxYval);
        minX = Math.min(minX, profileXval);
        maxX = Math.max(maxX, profileXval);
        minZ = Math.min(minZ, profileMinZval);
        maxZ = Math.max(maxZ, profileMaxZval);     
    }
     
    const numOfColumns = profiles.length;
    const numOfRows = Math.round((maxY - minY) / stepY) + 1;

    const flatElevs = new Float32Array(numOfColumns * numOfRows);

    for (let i = 0; i < numOfColumns; i++) {
        const profile = profiles[i];
        const profileMinYval = profileMinY(profile);
        const profileMaxYval = profileMaxY(profile);

        let k = 0;
        const heights = profile.Elevations;

        for (let j = 0; j < numOfRows; j++) {
            const currentY = minY + stepY * j;
            const ind = numOfColumns * j + i;
            if (currentY < profileMinYval || currentY > profileMaxYval) {
                flatElevs[ind] = NaN;
            } else {
                const elev = heights[k];
                k++; 
                if (elev < header.MinElevation){
                    flatElevs[ind] = header.MinElevation * unitsPlanimetric;
                }else{
                   flatElevs[ind] = elev * unitsPlanimetric;      
                }
            }
        }
    }
    const coords: [number, number, number, number, number, number] = [
        minX,
        minY,
        minZ,
        maxX,
        maxY,
        maxZ,
    ];

    return {
        elevations: flatElevs,
        poitsCountX: numOfColumns,
        pointsCountY: numOfRows,
        minMaxCoords: coords,
        name: surfaceName
    };
}

function uniformScienceNotation(data: string): number {
    data = data.toUpperCase();
    const isFortranNotation = data.includes("D");
    const separator = isFortranNotation ? "D" : "E";

    const [coefficient, exp] = data.split(separator);

    if (exp != null) {
        const exponent = isFortranNotation ? parseInt(exp, 16) : +exp;
        data = `${coefficient}e${exponent}`;
    } else {
        data = coefficient;
    }

    const num = Number(data);

    if (Number.isNaN(num)) {
        throw new Error(
            "Failure while trying to read value from the dem file."
        );
    }
    return num;
}

function parseResolution(resolution: string) {
    let res = [];

    for (let i = resolution.length; i > 12; i -= 12) {
        const data = resolution.slice(i - 12, i);
        const num = uniformScienceNotation(data);
        res.push(num);
    }

    res = res.reverse();
    return res;
}

function parseLimits(limits: string) {
    const min = Vector2.zero();
    const max = Vector2.zero();
    const limitData = splitNumbersFromDemString(limits);
    if (limitData.length !== 8) {
        return { min: min, max: max };
    }
    for (const n of limitData) {
        if (Number.isNaN(n)) {
            return { min: min, max: max };
        }
    }
    const xs = limitData.filter(function (element, i, arr) {
        return i % 2 === 0;
    });
    const ys = limitData.filter(function (element, i, arr) {
        return i % 2 !== 0;
    });
    min.x = Math.min(...xs);
    min.y = Math.min(...ys);
    max.x = Math.max(...xs);
    max.y = Math.max(...ys);

    return { min: min, max: max };
}

function getUnits(unitscode: UnitsCode) {
    let units = 1;
    switch (unitscode) {
        case UnitsCode.Meters: {
            break;
        }
        case UnitsCode.ArcSeconds: {
            units = 30.87;
            break;
        }
        case UnitsCode.feet: {
            units = FeetToMeters(units);
        }
        default: {
            break;
        }
    }
    return units;
}

function chunkArrayBuffer(
    buffer: ArrayBuffer,
    chunkSize: number
): Uint8Array[] {
    const chunks: Uint8Array[] = [];
    const bufferLength = buffer.byteLength;

    for (let i = 0; i < bufferLength; i += chunkSize) {
        const chunk = new Uint8Array(
            buffer,
            i,
            Math.min(chunkSize, bufferLength - i)
        );
        chunks.push(chunk);
    }

    return chunks;
}
