// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class CatalogItemValue implements flatbuffers.IUnpackableObject<CatalogItemValueT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):CatalogItemValue {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsCatalogItemValue(bb:flatbuffers.ByteBuffer, obj?:CatalogItemValue):CatalogItemValue {
  return (obj || new CatalogItemValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsCatalogItemValue(bb:flatbuffers.ByteBuffer, obj?:CatalogItemValue):CatalogItemValue {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new CatalogItemValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

id():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

type():string|null
type(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
type(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startCatalogItemValue(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addId(builder:flatbuffers.Builder, id:number) {
  builder.addFieldInt32(0, id, 0);
}

static addType(builder:flatbuffers.Builder, typeOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, typeOffset, 0);
}

static endCatalogItemValue(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createCatalogItemValue(builder:flatbuffers.Builder, id:number, typeOffset:flatbuffers.Offset):flatbuffers.Offset {
  CatalogItemValue.startCatalogItemValue(builder);
  CatalogItemValue.addId(builder, id);
  CatalogItemValue.addType(builder, typeOffset);
  return CatalogItemValue.endCatalogItemValue(builder);
}

unpack(): CatalogItemValueT {
  return new CatalogItemValueT(
    this.id(),
    this.type()
  );
}


unpackTo(_o: CatalogItemValueT): void {
  _o.id = this.id();
  _o.type = this.type();
}
}

export class CatalogItemValueT implements flatbuffers.IGeneratedObject {
constructor(
  public id: number = 0,
  public type: string|Uint8Array|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const type = (this.type !== null ? builder.createString(this.type!) : 0);

  return CatalogItemValue.createCatalogItemValue(builder,
    this.id,
    type
  );
}
}
