<script lang="ts">
    import type { IdBimScene } from 'bim-ts';
    import { createEventDispatcher } from 'svelte';
    import { ButtonComponent, IconButton } from '../libui/button';
    import { IconComponent } from '../libui/icon';

    import type { SceneExplorerObj, InputCombo, HoveredSegment, SceneExplorerObjPrimaryProperty } from './SceneExplorer';


  export let item: SceneExplorerObj;
  export let heightPx: number
  export let isOddInList: boolean
  export let primaryProperties: SceneExplorerObjPrimaryProperty[] = [];

  export let toggleNodeChildren: (e: InputCombo) => void
  export let selectNode: (e: InputCombo) => void
  export let toggleNodeVisibility: (e: InputCombo) => void

  $: itemHeight = `${heightPx}px`
  $: itemLeftOffset = `${item.depth * 10}px`

  let identifier: string = ""
  let editMode = false;
  let el: Element
  let isHoveredCnt: number = 0
  $: isHovered = isHoveredCnt > 0
  let dragStartedMs: number | null = null
  let expandedWithDrag: boolean = false
  const expandMsDelay: number = 500
  $: if (!isHovered || hoveredSegment !== 'center') {
    dragStartedMs = null
    expandedWithDrag = false
  }
  let hoveredSegment: HoveredSegment | null = null
  let isDragged: boolean = false
  function handleDragEnter(e: DragEvent) {
    isHoveredCnt += 1
  }
  function handleDragEnd() {
    isDragged = false
    isHoveredCnt = 0
  }
  function handleDragLeave() {
    isHoveredCnt -= 1
  }
  function handleDragOver(e: Event) {
    const dragE = e as DragEvent
    const box = el.getBoundingClientRect();
    const dragY = dragE.y
    const relY = dragY - box.y
    const heightPortion = relY/box.height
    if (heightPortion < 0.33)
        hoveredSegment = 'top'
    else if (heightPortion < 0.66)
        hoveredSegment = 'center'
    else if (heightPortion >= 0.66) {
        if (item.hasChildren && item.isNodeOpen)
            hoveredSegment = 'center'
        else
            hoveredSegment = 'bottom'
    }
    if (hoveredSegment === 'center') {
        if (dragStartedMs === null) {
            dragStartedMs = Date.now()
        }
        if (
            dragStartedMs &&
            (Date.now() - dragStartedMs > expandMsDelay) &&
            !expandedWithDrag &&
            !item.isNodeOpen
        ) {
            expandedWithDrag = true
            toggleNodeChildren({})
        }
    }
  }
  const dispatch = createEventDispatcher<{
    drop: {
        payload: IdBimScene,
        segment: HoveredSegment
    }
  }>()
  function handleDragDrop(e: DragEvent) {
    isHoveredCnt = 0
    if (!e.dataTransfer || !hoveredSegment) return
    const id = e.dataTransfer.getData("text/plain")
    dispatch('drop', {
        segment: hoveredSegment,
        payload: (+id) as IdBimScene
    })
  }
  function handleDragStart(e: DragEvent) {
    if (!e.dataTransfer) return
    isDragged = true
    const placeholder = document.createElement('span')
    e.dataTransfer.setDragImage(placeholder, 0, 0);
    e.dataTransfer.setData('text/plain', ""+item.id)
  }
</script>

<div
    on:click|preventDefault|stopPropagation={() => null}
    class="list-item mdc-typography mdc-typography--body1"
    style="
           --height:{itemHeight};
           --item-left-offset:{itemLeftOffset};
    "
    class:selected-list-item="{item.bimStateRef.isSelected}"
    class:hidden="{item.bimStateRef.isHidden}"
    class:odd-list-item="{!isOddInList}"
    class:searchMatch={item.searchHL}
    class:searchMatchRelated={item.searchHLextra}
    draggable={true}
    bind:this={el}
    on:dragover={handleDragOver}
    on:drop={handleDragDrop}
    on:dragstart={handleDragStart}
    on:dragend={handleDragEnd}
    on:dragleave={handleDragLeave}
    on:dragenter={handleDragEnter}
    on:dragover={(e) => { e.preventDefault() }}
    class:hovered-top={isHovered && hoveredSegment === 'top'}
    class:hovered-bottom={isHovered && hoveredSegment === 'bottom'}
    class:hovered-center={isHovered && hoveredSegment === 'center'}
    class:hovered={isHovered}
    class:dragged={isDragged}
>
    <div class="separator"></div>
  <div
    style="--item-left-offset:{itemLeftOffset};"
    class="expand-identifier"
  >
    <span
      on:click={toggleNodeChildren}
      class="toggle-childs"
      draggable={false}
      class:no-display="{!item.hasChildren}"
    >
      <IconComponent name={item.isNodeOpen ? "ChevronDown" : "ChevronRight"} />
    </span>
    {#if editMode}
      <input bind:value={identifier} />
      <button on:click={() => null}>save</button>
    {:else}
      <p
        on:click={selectNode}
        class="identifier"
      >
        {item.uiName()}
      </p>
      {#each primaryProperties as property}
        {@const propValue = property.getDisplayValue(item.bimStateRef)}
        {#if property.show && propValue}
          <div class="mdc-chip-set" on:click={selectNode}>
            <div title="{propValue || ""}" class="compact mdc-chip" tabindex="0">
              <div style="
                overflow: hidden;
                max-width: 100px;
              " class="mdc-chip__text">{propValue}</div>
            </div>
          </div>
        {/if}
      {/each}
    {/if}

  </div>
  <div class="toggle-hide">
    <ButtonComponent desc={new IconButton(item.bimStateRef.isHidden ? "VisibilityOff" : "VisibilityOn", toggleNodeVisibility)} />
  </div>
</div>

<style lang="scss">
  .list-item {
    height: var(--height);
    padding: 0 5px;
    display: flex;
    align-items: center;
    cursor: default;
    position: relative;
    .identifier {
        box-sizing: border-box;
    }

    .separator {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        top: -2px;
        pointer-events: none;
        z-index: 10;
        border-top: 4px dashed rgba(0,0,0,0);
        border-bottom: 4px dashed rgba(0,0,0,0);
        border-radius: 0;
        transition: border 0.1s;
        opacity: 0.6;
    }
    &.hovered-top {
        .separator {
            border-top: 4px dashed var(--mdc-theme-primary);
            transition: none;
        }
    }
    &.hovered-center {
        .separator {
            border: 4px dashed var(--mdc-theme-primary);
            transition: none;
        }
    }
    &.hovered-bottom {
        .separator {
            transition: none;
            border-bottom: 4px dashed var(--mdc-theme-primary);
        }
    }

    &.dragged {
        opacity: 0.5;
    }
  }
  .list-item:after,:before {
    content: "";
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  .list-item.odd-list-item:after {
    background-color: rgba(0,0,0,0.05);
  }
  .list-item.selected-list-item:before {
    background-color: rgba(0,0,0,0.3);
  }
  p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p.identifier {
    flex: 1;
    height:100%;
    margin:0;
    justify-content: left;
    overflow: hidden;
    white-space: nowrap;
  }
  .expand-identifier {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding-left: var(--item-left-offset);
    height: inherit;
    overflow: hidden;
  }
  .hidden {
    opacity: 0.5;
  }
  .toggle-childs {
    display: flex;
    align-items: center;
    padding: 0 4px;
  }
  .toggle-hide {
    transform: scale(0.7);
  }
  .no-display {
    visibility: hidden;
  }
  .searchMatch {
    background: orange
  }
  .searchMatchRelated {
    background: pink
  }
  .mdc-chip-set {
    padding: 0px;
  }
</style>
