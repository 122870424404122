<script lang="ts">
import type { Action } from "./Action";
import { CustomAmountTypeIdentifier } from "./custom-amount/CustomAmount";
import CustomAmount from './custom-amount/CustomAmount.svelte';
import { HideTypeIdentifier } from "./hide/Hide";
import Hide from './hide/Hide.svelte';
import { OverrideTypeIdentifier } from "./override/Override";
import Override from "./override/Override.svelte";
import { QuantityTemplateImportConfigTypeIdentifier } from "./quantity-template/QuantityTemplate";
import QuantityTemplate from './quantity-template/QuantityTemplate.svelte';
import { ReferenceTypeIdentifier } from "./reference/Reference";
import Reference from './reference/Reference.svelte';

export let exit: (newAction?: Action) => void;
export let action: Action | undefined

let newItemType: string;

let newItemTypes: Record<string, any> = {
    [OverrideTypeIdentifier]: Override,
    [ReferenceTypeIdentifier]: Reference,
    [QuantityTemplateImportConfigTypeIdentifier]: QuantityTemplate,
    [HideTypeIdentifier]: Hide,
    [CustomAmountTypeIdentifier]: CustomAmount,
};

$: itemType = action?.type ?? newItemType;

</script>

{#if !action && !newItemType}
    <a class="basic-interact" on:click={() => exit()}>{'<'}</a>
    {#each Object.entries(newItemTypes) as [key]}
        <button on:click={() => newItemType = key}>{key}</button>
    {/each}
{/if}

{#if itemType}
    <svelte:component
        this={newItemTypes[itemType]}
        action={action}
        exit={exit}
    />
{/if}
