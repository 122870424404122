import type { Writeable } from 'engine-utils-ts';
import { WorkerClassPassRegistry } from 'engine-utils-ts';
import { NumberProperty } from '../properties/PrimitiveProps';
import { PropsGroupBase } from '../properties/Props';
import type { EnergyPipelineProperty, EnergyStageName } from './EnergyPipelineProperty';
import { getValueOrThrow } from './EnergyUtils';



export class EnergyYieldPropsGroup extends PropsGroupBase {

    pipeline: EnergyPipelineProperty;

    annual_yield: NumberProperty;
    daily_yield: NumberProperty;
    performance_ratio: NumberProperty;
    specific_annual_yield: NumberProperty;

    losses: EnergyYieldEnergyLossesGroup;

    constructor(args: {
        pipeline: EnergyPipelineProperty,
    }) {
        super();
        this.pipeline = getValueOrThrow(args.pipeline);


        const outputPowerUnit = this.pipeline.nominal_power.unit + 'h';
        const outputPower = this.pipeline.energyTotal(outputPowerUnit);

        this.annual_yield = NumberProperty.new({value: outputPower, unit: outputPowerUnit, isReadonly: true});
        this.daily_yield = NumberProperty.new({value: outputPower / 365, unit: outputPowerUnit, isReadonly: true});


        this.performance_ratio = NumberProperty.new({
            value: this.pipeline.performanceRatio() * 100,
            unit: '%',
            isReadonly: true,
        });

        let specific_annual_yield_value = this.pipeline.energyTotal('Wh') / this.pipeline.dc_nominal_power;
        if (!Number.isFinite(specific_annual_yield_value)) {
            specific_annual_yield_value = 0;
        }
        this.specific_annual_yield = NumberProperty.new({
            value: specific_annual_yield_value,
            unit: 'Wh/W',
            isReadonly: true,
        })

        this.losses = EnergyYieldEnergyLossesGroup.fromPipeline(this.pipeline);

        Object.freeze(this);
    }
}
WorkerClassPassRegistry.registerClass(EnergyYieldPropsGroup);


type EnergyYieldLosses = Record<EnergyStageName, NumberProperty | null>;

export class EnergyYieldEnergyLossesGroup extends PropsGroupBase implements EnergyYieldLosses {

    ['horizontal_irradiance']: NumberProperty | null = null;
    ['tilt']: NumberProperty | null = null;
    ['shading']: NumberProperty | null = null;
    ['bifaciality']: NumberProperty | null = null;
    ['soiling']: NumberProperty | null = null;
    ['incidence_angle']: NumberProperty | null = null;
    ['pv_conversion']: NumberProperty | null = null;
    ['temperature']: NumberProperty | null = null;
    ['spectral_correction']: NumberProperty | null = null;
    ['module_quality']: NumberProperty | null = null;
    ['module_mismatch']: NumberProperty | null = null;
    ['strings_mismatch']: NumberProperty | null = null;
    ['dc_wiring']: NumberProperty | null = null;
    ['inverter_voltage_clipping']: NumberProperty | null = null;
    ['inverter_efficiency']: NumberProperty | null = null;
    ['inverter_power_clipping']: NumberProperty | null = null;
    // ['grid_limit']: NumberProperty | null = null;

    constructor(args: Partial<EnergyYieldEnergyLossesGroup>) {
        super();
        for (const key in args) {
            if (key in this) {
                (this as Writeable<EnergyYieldEnergyLossesGroup>)[key] = args[key as keyof EnergyYieldEnergyLossesGroup] ?? null;
            }
        }
        Object.freeze(this);
    }

    static fromPipeline(pipeline: EnergyPipelineProperty): EnergyYieldEnergyLossesGroup {
        const lossesPerStageName = pipeline.lossPercentPerStage();
        const args: Partial<EnergyYieldEnergyLossesGroup> = {};
        for (const [stageName, loss] of lossesPerStageName) {
            args[stageName] = NumberProperty.new({value: loss, unit: '%', isReadonly: true});
        }
        return new EnergyYieldEnergyLossesGroup(args);
    }
}
WorkerClassPassRegistry.registerClass(EnergyYieldEnergyLossesGroup);
