<script lang="ts">
    import type { KreoEngine } from "engine-ts";
    import GridSizeLabel from "./GridSizeLabel.svelte";

    export let engine: KreoEngine;
    export let leftNavWidth: number;
    export let rightNavWidth: number;

    export let engineDomElement: HTMLDivElement;

    const resizeLineWidth = 4;
    $: leftOffset = leftNavWidth > 0 ? leftNavWidth - resizeLineWidth : 0;
    $: rightOffset = rightNavWidth > 0 ? rightNavWidth - resizeLineWidth : 0;

    $: {
        if (engineDomElement && engine) {
            const element = engineDomElement.style;
            if (
                leftOffset !== parseInt(element.marginLeft) ||
                rightOffset !== parseInt(element.marginRight)
            ) {
                const width = window.innerWidth - rightOffset - leftOffset;
                engine.resize(width, window.innerHeight);
            }
        }
    }
</script>

<div class="page">
    <div
        class="engine-root"
        style:margin-left="{leftOffset}px"
        style:margin-right="{rightOffset}px"
        bind:this={engineDomElement}
    >
        {#if engine}
            <GridSizeLabel {engine} />
        {/if}
    </div>
</div>

<style>
    .page {
        background-color: #f0f1f3;
        height: 100%;
    }
    .engine-root {
        position: relative;
        height: inherit;
    }
</style>
