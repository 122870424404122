import { DefaultMap, Success } from "engine-utils-ts";
import type { Vector2} from "math-ts";
import { Vector3 } from "math-ts";
import type { Bim} from "src";
import { BoundaryType } from "src";
import type { IdBimScene} from "src/scene/SceneInstances";
import { calculateStdRepresentationLocalBBox } from "src/scene/SceneInstances";

export class Extractor {
    private bim: Bim;

    constructor(bim: Bim) {
        this.bim = bim;
    }

    extract() {
        const solarArraysPerParent = new DefaultMap<IdBimScene, SolarArrayDescription[]>(() => []);
        for (const [id, si] of this.bim.instances.peekByTypeIdents(['fixed-tilt', 'any-tracker', 'tracker'])) {
            if (!si.representation) {
                continue;
            }
            const aabbResult = calculateStdRepresentationLocalBBox(si.representation, this.bim.allBimGeometries)
            if (!(aabbResult instanceof Success)) {
                continue
            }
            const points = aabbResult.value.getCornerPoints().map(x => x.applyMatrix4(si.worldMatrix));
            const parent = si.spatialParentId;
            solarArraysPerParent.getOrCreate(parent).push({
                abcd: [points[0], points[1], points[2], points[3]],
                bimObjectId: id,
            })
        }
        const result: Response = {
            boundaries: this.bim.extractBoundaries()
                .map(x => ({
                    bimObjectId: x.bimObjectId,
                    points: x.pointsWorldSpace,
                    isExcludeType: x.boundaryType === BoundaryType.Exclude,
                })),
            solarArrayGroups: [],
        }
        for (const [parentId, arrays] of solarArraysPerParent) {
            const worldMatrix = this.bim.instances.peekWorldMatrix(parentId);
            if (!worldMatrix) {
                continue;
            }
            const parentPositionWorld = new Vector3().applyMatrix4(worldMatrix);
            result.solarArrayGroups.push({
                arrays,
                parentRefId: parentId,
                point: parentPositionWorld,
            })
        }
        console.log(result)
        return result;
    }
}

export interface Response {
    boundaries: BoundaryDescription[]
    solarArrayGroups: SolarArrayGroupDescription[]
}

export interface SolarArrayGroupDescription {
    parentRefId: IdBimScene
    point: Vector3
    arrays: SolarArrayDescription[]
}

export interface BoundaryDescription {
    bimObjectId: IdBimScene
    points: Vector2[]
    isExcludeType: boolean
}

export interface SolarArrayDescription {
    bimObjectId: IdBimScene
    abcd: [a: Vector3, b: Vector3, c: Vector3, d: Vector3]
}
