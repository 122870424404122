import type { Vector2} from 'math-ts';
import { Vector3, Vector4 } from 'math-ts';

import type { Camera, Geometry, Group, Material, MeshIntersection,
    Raycaster, Scene, ShaderMaterial, WebGLRenderer
} from './3rdParty/three';
import {
    BufferAttribute, BufferGeometry, GreaterDepth, LessDepth, Mesh
} from './3rdParty/three';
import { BrighGizmoColor } from './Constants';
import type { GlobalUniforms } from './materials/GlobalUniforms';
import { generateSnatchMaterial } from './ruler/RulerMaterials';
import { GizmoBrightness } from './SizeLabelMesh';

function RulerOnAfterRender2ndRender(this:SnatchLineMesh, renderer: WebGLRenderer, _scene: Scene, camera: Camera, geometry: Geometry | BufferGeometry,
	material: Material, _group: Group): void {
	renderer.state.buffers.depth.setFunc(GreaterDepth);
	const opacityBefore = this.color.w;
	this.color.w *= 0.25;
	renderer.renderBufferDirect(camera, null, geometry, material, this, null);
	this.color.w = opacityBefore;
	renderer.state.buffers.depth.setFunc(LessDepth);
}

export class SnatchLineMesh extends Mesh {

	readonly localUpVec: Vector3 = Vector3.allocate(0, 0, 1);
	readonly localRightVec: Vector3 = Vector3.allocate(1, 0, 0);
	readonly color: Vector4 = Vector4.allocate(0, 0, 0, 0.5);

	// as for DynamicMesh, usage of this stuff hordcoded inside three.js renderer 
	readonly dynamicUniforms: Map<string, Vector2 | Vector3 | Vector4> = new Map();

	_whiteness: number = 0.5;
	_brightness: GizmoBrightness = GizmoBrightness.Default;

	constructor(geometry: BufferGeometry, material: ShaderMaterial) {
		super(geometry, material);
		this.dynamicUniforms.set('right_v', this.localRightVec);
		this.dynamicUniforms.set('up_v', this.localUpVec);
		this.dynamicUniforms.set('colorOpacity', this.color);
		this.frustumCulled = false; // disable frustum culling, becase we use shader to set position of geometry :(
		this.matrixAutoUpdate = false;
	}
	
	draw2TimesDepthTested() {
		this.material!.depthTest = true;
		this.material!.depthFunc = LessDepth;
		this.onAfterRender = RulerOnAfterRender2ndRender;
	}

	setContrastColor(white: number) {
		this._whiteness = white;
		this._updateColor();
	}

	setBrightness(b: GizmoBrightness) {
		this._brightness = b;
		this._updateColor();
	}

	_updateColor() {
		const color = this.color;
		color.set(this._whiteness, this._whiteness, this._whiteness, 0.55);
		if (this._brightness & GizmoBrightness.Highlighted) {
			color.lerpTo(BrighGizmoColor, 0.3);
		}
		if (this._brightness & GizmoBrightness.Selected) {
			color.lerpTo(BrighGizmoColor, 0.7);
		} 
	}

	raycast(_raycaster: Raycaster, _intersects: MeshIntersection[]): void {
	}

}

export function createSnatchLineMesh(g_uniforms: GlobalUniforms): SnatchLineMesh {
	let geo = sharedSnatchLineGeo || (sharedSnatchLineGeo = generateRulerLineGeo());
	let mat = sharedSnatchMaterial || (sharedSnatchMaterial = generateSnatchMaterial(g_uniforms));
	return new SnatchLineMesh(geo, mat);
}

let sharedSnatchMaterial: ShaderMaterial | null = null;
let sharedSnatchLineGeo: BufferGeometry | null = null;


function generateRulerLineGeo(): BufferGeometry {
	const geo = new BufferGeometry();
	const positions = new Float32Array([0.0,0.0, 0.0,1.0, 1.0,1.0, 1.0,0.0]);
	geo.attributes['position2d'] = new BufferAttribute(positions, 2);
	geo.setIndex([0, 1, 3, 3, 1, 2]);
	return geo;
}
