export enum InRowPositionMask {
    None = 0b00,
    Begin = 0b01,
    End = 0b10,
}

export function getInRowPosition(inRowIndex: number, rowLength: number) {
    if (rowLength === 1) {
        return InRowPositionMask.Begin | InRowPositionMask.End;
    }

    if (inRowIndex === 0) {
        return InRowPositionMask.Begin;
    }
    if (inRowIndex === rowLength - 1) {
        return InRowPositionMask.End;
    }
    return InRowPositionMask.None;
}

export enum InRowPositionPacked {
    // 22 bits for RowIndex
    // 8 bits for InRowIndex
    // 2 bits for InGroupPosition
};

export function newIndexWithInRowPosition(
    rowIndex: number, inRowIndex: number, position: InRowPositionMask
): InRowPositionPacked {
    return (rowIndex << 10) | (inRowIndex << 2) | position;
}

export function getRowIndex(indexAndPosition: InRowPositionPacked): number {
    return indexAndPosition >>> 10;
}

export function getInRowIndex(indexAndPosition: InRowPositionPacked): number {
    return (indexAndPosition << 22) >>> 24;
}

export function getPositionMask(indexAndPosition: InRowPositionPacked): InRowPositionMask {
    return indexAndPosition & 0b11;
}