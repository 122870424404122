<script lang="ts">
    import { DialogDescription, UiCustomNode, type PUI_ActionDescr, type PUI_CustomPropertyNode, type UiBindings } from 'ui-bindings';
    import { NumbersArrayProperty, UnitsMapper } from 'bim-ts';
    import { getContext } from 'svelte';
    import { CompressibleNumbersArray, StringUtils } from 'engine-utils-ts';
    import type { Readable } from 'svelte/store';
    import { ButtonComponent, IconButton } from '../../libui/button';

    export let property: PUI_CustomPropertyNode<NumbersArrayProperty | CompressibleNumbersArray>;

    $: unitsMapper = getContext('unitsMapper') as Readable<UnitsMapper>;

    $: numbersArray = property.value instanceof CompressibleNumbersArray ? property.value : property.value.value;

    $: minValue = numbersArray.min() ?? 0;
    $: maxValue = numbersArray.max() ?? 0;

    $: minAsStr = $unitsMapper.mapToConfiguredOrNull({value: minValue, unit: numbersArray.unit}) ?? {value: 0, unit: ''};
    $: maxAsStr = $unitsMapper.mapToConfiguredOrNull({value: maxValue, unit: numbersArray.unit}) ?? {value: 0, unit: ''};

    $: rangeStr = `min:${StringUtils.roundedForUi(minAsStr.value)} ${minAsStr.unit}; max:${StringUtils.roundedForUi(maxAsStr.value)} ${maxAsStr.unit}`;

    const uiBindings: UiBindings = getContext("uiBindings");

    const openTableAction: PUI_ActionDescr<any> = {
        label: `${property.name} (full)`,
        action: () => {
            const asNumbersPropery: NumbersArrayProperty = property.value instanceof NumbersArrayProperty ?
                property.value :
                new NumbersArrayProperty(property.value);

            uiBindings.dialogsStream.pushNext(new DialogDescription({
                name: `${property.name}`,
                context: undefined,
                uiSource: new UiCustomNode({context: asNumbersPropery}),
            }));
        }
    }


</script>

<span class="ui-config-property property-row">

    <div class="property-name text-main-medium">
        <div class="property-name-label mdc-typography--body1">
            {property.name}
        </div>
    </div>
    <div class="property-value">
        <div
            class="mdc-typography--body1 read-only"
            class:calculated={property.calculated}
        >
            {rangeStr}
            <ButtonComponent desc={new IconButton(
                'OpenFull',
                openTableAction.action,

            )}/>
        </div>
    </div>
<!-- 
    <ActionProperty
        actionDescr={openTableAction}
        context={null}
    ></ActionProperty> -->

<!-- 
    <div class="chart">
        <AnyEchart
            bind:echartOptions={echartOptions}
        ></AnyEchart>
    </div> -->
</span>

<style lang="scss">
</style>

