


export class PointerLocker {

}

export class HtmlUtils {

    readonly domElement: HTMLElement;

	constructor(domElement: HTMLElement) {
		this.domElement = domElement;
	}

	setCursorStyle(cursorStyle: string) {
		if (this.domElement.style.cursor != cursorStyle) {
			this.domElement.style.cursor = cursorStyle;
		}
    }

    lockRequested: boolean = false;
    requestPointerLock() {
        if (this.lockRequested) {
            return;
        }
        this.lockRequested = true;
        this.domElement.requestPointerLock();
    }
    unlockPointerIfLocked() {
        if (this.lockRequested) {
            this.lockRequested = false;
            if (document.pointerLockElement == this.domElement) {
                document.exitPointerLock();
            }
        }
    }

}
