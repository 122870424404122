<script lang="ts">
import Select from '../../reusable/Select.svelte'
import { getContext } from "svelte";
import { LazyBasic, LazyDerived, convertUnits, Success } from 'engine-utils-ts';
import { NotificationDescription, NotificationType, PUI_GroupNode, PUI_PropertyNodeNumber, PUI_PropertyNodeString, UiBindings } from 'ui-bindings';
import TableUi from '../../grid-table-ui/TableUi.svelte';
import { Bim, ProjectMetrics, NumberProperty, ValueAndUnit, createTotalDCLazy } from 'bim-ts';
import { VersionedStore } from '../../VersionedStore';
import type { CostReport } from '../CostReport';
import type { CostReportTemplate } from '../CostReportTemplate';
import { Override, OverrideTypeIdentifier } from '../actions/override/Override';
import { Button, ButtonComponent, ButtonType } from '../../libui/button';
import { TableEntry, type GroupedTableHierarchy } from '../TableHierarchy';
import { Action } from '../actions/Action';
import { Action as MenuAction, ContextMenu, ContextMenuStructure } from '../../libui/context-menu';
import type { KreoEngine } from 'engine-ts';
import { notificationSource } from '../../Notifications';

const projectId = getContext<number>('projectId');

let costReport = getContext<CostReport>('cost-report');
let engine = getContext<KreoEngine>('engine');
let uiBindings = getContext<UiBindings>('uiBindings');

async function fetch() {
    costReport.costReportTemplates.loadFromRemote_withNotification();
}

const templateStore = new VersionedStore(costReport.costReportTemplates.templates)

$: templates = $templateStore;

let selectedTemplateId: string | undefined = undefined; 

let _selectedTemplate = new LazyBasic<CostReportTemplate | undefined>(
  'selectedTemplate',
  undefined,
)
let selectedTemplate = new VersionedStore(_selectedTemplate);

$: hasOverrides = (
  $selectedTemplate
    ?.projectSpecificOverriders.find(x => x.projectId === projectId)
    ?.actions.length
    ?? 0
) > 0

$: templatesList = templates.filter(x => x.visible).map(x => x.name);
$: currentTemplateId = selectedTemplateId ?? templatesList[0];

$: _selectedTemplate.forceUpdate(templates.find(x => x.name === currentTemplateId));

const isAdmin = localStorage.getItem('mode') === 'admin';
const bim = getContext<Bim>('bim');

let layoutMetrics = getContext<ProjectMetrics>('layoutMetricsLazy');


function addOverride(override: Override) {
    const template = $selectedTemplate;
    if (!template) {
        return;
    }
    const overrides = template.projectSpecificOverriders
        .find(x => x.projectId === projectId) ?? {
            actions: [],
            projectId: projectId
        };
    const action = new Action<Override>(
      'custom override',
      OverrideTypeIdentifier,
      override
    );
    action.userCreated = true;
    overrides.actions.push(action);
    if (!template.projectSpecificOverriders.includes(overrides)) {
        template.projectSpecificOverriders.push(overrides);
    }
    isOverrideChanged = true;
    templates = templates;
}

let tableData = new LazyBasic<PUI_GroupNode[]>('tableData', []);

let throttleTimeout: NodeJS.Timeout | null = null;
function throttleCostTableCalculation(totalDC?: NumberProperty) {
    if (throttleTimeout) {
        clearTimeout(throttleTimeout);
    }
    throttleTimeout = setTimeout(() => calculateTable(totalDC), 1000);
}

function calculateTable(totalDC?: NumberProperty) {
    const template = _selectedTemplate.poll();
    const task = engine.tasksRunner.newLongTask({
        defaultGenerator: (function*() {
            const groups: PUI_GroupNode[] = [];
            if (!template) {
                tableData.forceUpdate(groups);
                return;
            }
            const [grouped, hierarchy] = yield* costReport.buildSortedHierarchyForTemplateLazy.poll()
                .calculate(template, projectId);
            let idx = 0;
            function drawLevel(level: Readonly<GroupedTableHierarchy>) {
                for (const item of level) {
                    if ('items' in item) {
                        drawLevel(item.items);
                    } else {
                        const group = new PUI_GroupNode({
                            name: (idx++).toString(),
                            typeSortKeyOverride: idx,
                        });

                        const childNum = hierarchy.getEntriesWithPrefix(item.path).length
                        const isEditable = childNum === 1;

                        // title column
                        const titleIndent = new Array(item.path.length - 1).fill('    ').join('')
                        const title = titleIndent + item.path[item.path.length - 1];
                        group.addMaybeChild(new PUI_PropertyNodeString({
                            name: "Description",
                            value: title,
                            readonly: true,
                            onChange: (_, prop) => {},
                            nameSortKeyOverride: 1,
                        }));

                        // rate unit column
                        const reversedQtyUnits = bim.unitsMapper.converter
                            .reverseUnits(item.quantity?.unit ?? "");
                        const rateUnit = bim.unitsMapper.converter.combineUnits(
                            item.rate?.unit ?? "",
                            reversedQtyUnits,
                        );
                        const result = bim.unitsMapper.mapToConfiguredResult({
                          value: 1, unit: rateUnit
                        });
                        function convertUnitValue(x:ValueAndUnit) {
                            const unit = x.unit ?? '';
                            if (!unit.includes('/')) {
                                return unit + '/each';
                            }
                            return unit;
                        }

                        group.addMaybeChild(
                            new PUI_PropertyNodeString({
                                name: "Cost Unit",
                                value: (
                                  item.rate &&
                                  item.quantity &&
                                  result instanceof Success
                                ) ? convertUnitValue(result.value) : '',
                                readonly: true,
                                nameSortKeyOverride: 5,
                                onChange: () => {},
                            })
                        );

                        // quantity column
                        group.addMaybeChild(
                            new PUI_PropertyNodeNumber({
                                name: "Quantity",
                                value: item.quantity?.value ?? NaN,
                                unit: item.quantity?.unit,
                                readonly: !isEditable,
                                onChange: (_, prop) => {
                                    addOverride(new Override(
                                        item.path,
                                        new TableEntry(
                                          [],
                                          NumberProperty.new({
                                            value: prop.value ?? NaN,
                                            unit: (prop as PUI_PropertyNodeNumber).unit ?? ''
                                          }),
                                        ),
                                    ));
                                },
                                valueRenderFormatter: () => {
                                    if (item.quantityOverride) {
                                        return 'lightgreen';
                                    }
                                    return 'inherit';
                                },
                                nameSortKeyOverride: 10,
                            })
                        );

                        // rate column
                        group.addMaybeChild(new PUI_PropertyNodeNumber({
                            name: "Rate",
                            value: item.rate?.value ?? NaN,
                            unit: item.rate?.unit,
                            readonly: !isEditable,
                            onChange: (_, prop) => {
                                const quantityUnit = item.quantity?.unit;
                                const rateUnit = (prop as PUI_PropertyNodeNumber).unit || 'usd';
                                if (quantityUnit && prop.value) {
                                    const pricePerQuantityUnit = bim.unitsMapper.converter.combineUnits(
                                        rateUnit,
                                        bim.unitsMapper.converter.reverseUnits(quantityUnit),
                                    )
                                    const pricePerQuantityUnitConfigured = bim.unitsMapper
                                        .mapUnitToConfigured(pricePerQuantityUnit);
                                    const basePrice = convertUnits(
                                        prop.value,
                                        pricePerQuantityUnitConfigured,
                                        pricePerQuantityUnit,
                                    )
                                    if (basePrice instanceof Success) {
                                        addOverride(new Override(
                                            item.path,
                                            new TableEntry(
                                                [],
                                                undefined,
                                                NumberProperty.new({
                                                    value: basePrice.value,
                                                    unit: rateUnit,
                                                }),
                                            ),
                                        ));
                                        return;
                                    }
                                }
                                addOverride(new Override(
                                    item.path,
                                    new TableEntry(
                                      [],
                                      undefined,
                                      NumberProperty.new({
                                        value: prop.value ?? 0,
                                        unit: rateUnit,
                                      }),
                                    ),
                                ));
                            },
                            valueRenderFormatter: () => {
                                if (item.rateOverride) {
                                    return 'lightgreen';
                                }
                                return 'inherit';
                            },
                            nameSortKeyOverride: 15,
                        }));


                        // total cost column
                        let totalCost = item.totalCost;
                        if (!totalCost && item.rate && item.quantity) {
                            totalCost = NumberProperty.new({
                                unit: item.rate.unit,
                                value: item.quantity.value * item.rate.value,
                            });
                        }
                        group.addMaybeChild(new PUI_PropertyNodeNumber({
                            name: "Total Cost",
                            value: totalCost?.value ?? NaN,
                            unit: totalCost?.unit,
                            readonly: true,
                            onChange: () => {},
                            nameSortKeyOverride: 20,
                        }));

                        const pricePerWatt = NumberProperty.new({
                            unit: totalCost?.unit ?? '',
                            value: (!totalCost || !totalDC) ? NaN : totalCost.value / totalDC.as('W'),
                        })
                        // total cost per watt
                        group.addMaybeChild(new PUI_PropertyNodeNumber({
                            name: "Cost/Watt",
                            value: pricePerWatt.value,
                            unit: pricePerWatt.unit,
                            readonly: true,
                            onChange: () => {},
                            nameSortKeyOverride: 25,
                        }));

                        // price per power
                        groups.push(group);
                    }
                }
            }
            drawLevel(grouped);
            tableData.forceUpdate(groups);
            return
        })()
    })
    uiBindings.addNotification(NotificationDescription.newWithTask({
        source: notificationSource,
        taskDescription: { task },
        type: NotificationType.Info,
        addToNotificationsLog: true,
        key: 'generateCostReport',
    }))
}

const lazyTableData = LazyDerived.new2(
    'lazyTableData',
    [costReport.buildSortedHierarchyForTemplateLazy],
    [_selectedTemplate, createTotalDCLazy(layoutMetrics)],
    ([_, totalDC]) => throttleCostTableCalculation(totalDC),
).withoutEqCheck();

const lazyTableStore = new VersionedStore(lazyTableData);
$lazyTableStore;

let isOverrideChanged = false;
async function saveOverride() {
    const template = $selectedTemplate;
    if (template) {
        await costReport.costReportTemplates.api.saveTemplate(template);
    }
    await fetch();
    isOverrideChanged = false;
}

async function cancelOverride() {
    await fetch();
    isOverrideChanged = false;
}

async function removeOverrides() {
    const overrides = $selectedTemplate?.projectSpecificOverriders
        .find(x => x.projectId === projectId)
    if (overrides) {
        overrides.actions = [];
    }
    isOverrideChanged = true;
    templates = templates;
}

$: menu = new ContextMenuStructure([
    isOverrideChanged && new MenuAction('save', saveOverride),
    isOverrideChanged && new MenuAction('cancel', cancelOverride),
    hasOverrides && new MenuAction('remove all', removeOverrides),
].filter((x): x is MenuAction => !!x));

$: overridesButtonText = isOverrideChanged ? 'unsaved overrides' : 'overrides';

let contextMenu = getContext<ContextMenu>('context-menu');

$: autoFillButtonInput = new Button(
    overridesButtonText,
    ButtonType.Contained,
    e => contextMenu.openMenuAtPosition(e.clientX, e.clientY, menu),
    false,
    'Difference'
)

</script>

<div class="root">
    {#if templates.length}
        <div class="selector-holder">
          <div style="display: flex; align-items: center;">
            <Select
                labelId='template name'
                labelText='template name'
                values={templatesList}
                selected={currentTemplateId}
                on:select={e => {
                    selectedTemplateId = e.detail
                }}
            />
            <div style="width: 10px"></div>
            {#if menu.items.length}
              <ButtonComponent desc={autoFillButtonInput} />
            {/if}
          </div>
          {#if isAdmin}
              <button on:click={fetch}>refresh</button>
          {/if}
        </div>
        <TableUi lazyTableData={tableData} />
    {/if}
</div>

<style lang="scss">
.root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}
.selector-holder {
    margin: 5px 10px;
    justify-content: space-between;
    display: flex;
    align-items: center;
}
</style>
