import { Vector2, Vector3 } from "math-ts";
import { AnyTrackerProps, type SceneInstance } from 'bim-ts';


export interface TrackerDimensions {
    src: SceneInstance;
    isFixed:boolean;
    length: number;
    dc_power: number;
    max_current: number;
    width: number;
    string_max_current: number;
    string_power: number;
}

export function isFixedTracker(inst:Readonly<SceneInstance>){
    return inst.type_identifier === "fixed-tilt";
}

export function calculateTrackerDimensions(tracker: SceneInstance): TrackerDimensions {
    if (tracker.props instanceof AnyTrackerProps) {
        return {
            src: tracker,
            isFixed: false,
            dc_power: tracker.properties.get("circuit | aggregated_capacity | dc_power")?.as("W")!,
            length: tracker.props.tracker_frame.dimensions.length!.as('m'),
            width: tracker.props.tracker_frame.dimensions.max_width!.as('m'),
            max_current: tracker.properties.get("circuit | aggregated_capacity | max_current")?.as("A")!,
            string_max_current: tracker.props.tracker_frame.string.max_current!.as('A'),
            string_power: tracker.props.tracker_frame.string.power!.as('W'),
        }
    }

    const isFixed = isFixedTracker(tracker);
    const dc_power = tracker.properties.get("circuit | aggregated_capacity | dc_power")?.as("W")!;
    const max_current = tracker.properties.get("circuit | aggregated_capacity | max_current")?.as("A")!;
    const string_max_current = isFixed
        ? tracker.properties.get("string | max_current")?.as("A")!
        : tracker.properties.get("tracker-frame | string | max_current")?.as("A")!;
    const string_power = isFixed
        ? tracker.properties.get("string | power")?.as("W")!
        : tracker.properties.get("tracker-frame | string | power")?.as("W")!
    const length = isFixed
        ? tracker.properties.get("dimensions | length")?.as("m")!
        : tracker.properties.get("tracker-frame | dimensions | length")?.as("m")!;
    const width = isFixed
        ? tracker.properties.get("dimensions | width")?.as("m")!
        : tracker.properties.get("tracker-frame | dimensions | max_width")?.as("m")!;

    return {
        src: tracker,
        isFixed,
        length,
        dc_power,
        width,
        max_current,
        string_max_current,
        string_power,
    };
}

export function calculateTrackerDirection(si: SceneInstance) {
    const isFixed = isFixedTracker(si);
    reusedV3.setFromMatrixColumn(si.worldMatrix, 1);
    const dir2d = reusedV2.set(reusedV3.x, reusedV3.y).normalize();
    const angle = dir2d.angle();
    if (isFixed) {
        dir2d.copy(Vector2.fromAngle(angle - Math.PI / 2))
    }
    return [dir2d.x, dir2d.y] as [x: number, y: number]
}

const reusedV2 = new Vector2();
const reusedV3 = new Vector3();
