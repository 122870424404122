<script lang="ts">
    import type { Bim } from "bim-ts";
    import ToggleComponent from "./ToggleComponent.svelte";
    import { getContext } from "svelte";
    import {
        SelectedAreaLazy,
        createConfigForInstance,
    } from "./SelectedArea";
    import type { KreoEngine } from "engine-ts";
    import ViewResultProperty from "./ViewResultProperty.svelte";
    import { VersionedStore } from "../../../VersionedStore";

    import {
        SubareaProps,
        AllSiteAreaProps,
        SelectAreaPropertiesGroup,
        type SiteAreaContext,
        type ZoneBoundaryProps,
        UnallocatedSubareaProps,
    } from "layout-service";
    import PropertyView from "../../PropertyView.svelte";
    import { Button, ButtonComponent, ButtonType } from "../../../libui/button";
    import type {
        PUI_CustomGroupNode,
        PUI_PropertyNodeNumber,
    } from "ui-bindings";
    import Message from "../TextMessage.svelte";

    export let property: PUI_CustomGroupNode<
        SelectAreaPropertiesGroup,
        SiteAreaContext
    >;

    const bim = getContext<Bim>("bim");
    const engine: KreoEngine = getContext("engine");

    $: areasProperties = new VersionedStore(
        property.context.lazyAreasProps
    );
    $: selectedArea = new VersionedStore(
        new SelectedAreaLazy(property.children.selected_area)
    );

    let allSiteArea: AllSiteAreaProps | undefined = undefined;
    let unallocatedSubarea: UnallocatedSubareaProps | undefined = undefined;
    let subareas: SubareaProps[] = [];
    $: {
        const newSubareas: SubareaProps[] = [];
        const allSiteAreas: AllSiteAreaProps[] = [];
        const unallocatedAreas: UnallocatedSubareaProps[] = [];
        for (const area of property.children.site_areas) {
            if (area.children instanceof SubareaProps) {
                newSubareas.push(area.children);
            } else if (area.children instanceof AllSiteAreaProps) {
                allSiteAreas.push(area.children);
                if(allSiteAreas.length > 1){
                    console.error("multiple all site areas");
                }
            } else if (area.children instanceof UnallocatedSubareaProps) {
                unallocatedAreas.push(area.children);
                if(unallocatedAreas.length > 1){
                    console.error("multiple unallocated areas");
                }
            } else {
                console.error("unknown area type", area);
            }
        }
        subareas = newSubareas;
        if(allSiteAreas.length > 0){
            allSiteArea = allSiteAreas[0];
        }
        if(unallocatedAreas.length > 0){
            unallocatedSubarea = unallocatedAreas[0];
        }
    }

    function setSelectedArea(
        indexProperty: PUI_PropertyNodeNumber | undefined
    ) {
        if (indexProperty && indexProperty.value != null) {
            $selectedArea.setSelected(indexProperty.value);
        } else {
            console.error("property not updated", indexProperty);
        }
    }
    function getResultingAreaMsg(idx: number | null | undefined, props: ZoneBoundaryProps[]): string {
        if(idx == null || idx >= props.length){
            return " ";
        }
        return props[idx].message;
    }
    function isEmpty(idx: number | null | undefined, props: ZoneBoundaryProps[]): boolean {
        if(idx == null || idx >= props.length){
            return false;
        }
        return props[idx].availableArea.value === 0;
    }
    function getResultingAreaErrorMsg(idx: number | null | undefined, props: ZoneBoundaryProps[]): string | undefined {
        if(idx == null || idx >= props.length){
            return;
        }
        return props[idx].errorMessage;
    }
</script>

<div class="root">
    {#if allSiteArea}
        <ToggleComponent
            name={"Buildable area"}
            value={$selectedArea.isSelected(allSiteArea.index.value)}
            position={"beg"}
            onChange={() => setSelectedArea(allSiteArea?.index)}
            isEmpty = {isEmpty(allSiteArea?.index.value, $areasProperties)}
        >
            <div slot="body">
                <div class="body-props">
                    <PropertyView
                        bind:property={allSiteArea.boundaries}
                        offset={0}
                    />
                    <ViewResultProperty
                        name={"Resulting area"}
                        value={getResultingAreaMsg(allSiteArea?.index.value, $areasProperties)}
                        errorMessage={getResultingAreaErrorMsg(allSiteArea?.index.value, $areasProperties)}
                    />
                </div>
            </div>
        </ToggleComponent>
    {/if}
    {#if unallocatedSubarea && subareas.length > 0}
        <ToggleComponent
            name={"Unallocated subarea"}
            value={$selectedArea.isSelected(unallocatedSubarea.index.value)}
            onChange={() => setSelectedArea(unallocatedSubarea?.index)}
            position={"mid"}
            isEmpty = {isEmpty(unallocatedSubarea?.index.value, $areasProperties)}
        >
            <div slot="body">
                <div class="body-props">
                    <Message
                        message={`Whenever equipment is placed or generated in the subareas some places 
                        on the site will remain unused (unless every square feet 
                        of the site is filled with the equipment).<br />
                        <br />
                        Unallocated subarea includes all such places.`
                        }
                    />
                    <ViewResultProperty
                        name={"Layout generation priority"}
                        value={"Lowest"}
                    />
                    <ViewResultProperty
                        name={"Boundaries"}
                        value={`All site`}
                    />
                    <ViewResultProperty
                        name={"Area"}
                        value={getResultingAreaMsg(unallocatedSubarea?.index.value, $areasProperties)}
                        errorMessage={getResultingAreaErrorMsg(unallocatedSubarea?.index.value, $areasProperties)}
                    />
                </div>
            </div>
        </ToggleComponent>
    {/if}
    {#each subareas as area, index}
        <ToggleComponent
            name={"Subarea " + (index + 1)}
            value={$selectedArea.isSelected(area.index.value)}
            onChange={() => setSelectedArea(area?.index)}
            isEmpty = {isEmpty(area?.index.value, $areasProperties)}
            controlItemConfig={createConfigForInstance(
                area.equipmentBoundaries.value.map((v) => v.value),
                bim,
                engine,
                () => {
                    if (area && area.index.value !== null) {
                        const toDeleteIds = area.equipmentBoundaries.value.map(
                            (v) => v.value
                        );
                        bim.instances.delete(toDeleteIds);
                        property.context.removeArea(area.index.value);
                    } else {
                        console.error("index is null");
                    }
                }
            )}
            position = { subareas.length - 1 === index ? 'end' : 'mid' }
        >
            <div slot="body">
                <div class="body-props">
                    <!-- <PropertyView bind:property={area.name} offset={0} /> -->
                    <PropertyView bind:property={area.priority} offset={0} />
                    <PropertyView bind:property={area.zones} offset={0} />
                    <ViewResultProperty
                        name={"Area"}
                        value={getResultingAreaMsg(area?.index.value, $areasProperties)}
                        errorMessage={getResultingAreaErrorMsg(area?.index.value, $areasProperties)}
                    />
                </div>
            </div>
        </ToggleComponent>
    {/each}
    <div>
        <ButtonComponent
            desc={new Button(
                "Add new Subarea",
                ButtonType.Text,
                () => property.context.addNewArea(),
                undefined,
                "AddPlus"
            )}
        />
    </div>
</div>

<style lang="scss">
    .root {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .body-props {
        padding-top: 12px;
        & :global(> .property-view ~ .ui-config-property) {
            margin-top: 16px;
        }
        :global(.hierarchy-icon) {
            position: absolute;
            left: -16px;
        }
    }
</style>
