import { ObjectUtils } from 'engine-utils-ts';
import type { Matrix4, Transform } from 'math-ts';

import type {
    BimProperty, ObjectRepresentation, BasicAnalyticalRepresentation, SceneInstance} from '../';
import { SceneObjDiff
} from '../';
import type { PropsGroupBase } from '../properties/Props';


export class ChildrenSelector {

    constructor(
        readonly identifiersWithProps: {
            type_identifier: string;
            propsDependencies: SolverLegacyPropsInput;
            newPropsDefaults?: PropsGroupBase;
        }[]
    ) {
        const identifiersSet = new Set<string>();
        for (const d of identifiersWithProps) {
            if (identifiersSet.has(d.type_identifier)) {
                throw new Error(`children identifiers double entry ${d.type_identifier}`);
            }
            identifiersSet.add(d.type_identifier);
        }
        ObjectUtils.deepFreeze(this);
    }

    getIndexAndPropsFor(identifier: string): [number, SolverLegacyPropsInput, PropsGroupBase | undefined] | undefined {
        for (let i = 0; i < this.identifiersWithProps.length; ++i) {
            const identProps = this.identifiersWithProps[i];
            if (identProps.type_identifier === identifier) {
                return [i, identProps.propsDependencies, identProps.newPropsDefaults];
            }
        }
        return undefined;
    }

    getDefaultPropsFor(identifier: string): SolverLegacyPropsInput | undefined {
        for (const idProps of this.identifiersWithProps) {
            if (idProps.type_identifier === identifier) {
                return idProps.propsDependencies;
            }
        }
        return undefined;
    }
}

export class ObjectsSelector<LegacyProps extends SolverLegacyPropsInput, NewProps extends PropsGroupBase, D extends Partial<InObjectDependencies<LegacyProps, NewProps>>> {

    readonly objectTypeIdentifier: Set<string>;
    readonly inObjectDependencies: D;
    readonly invalidationFlags: SceneObjDiff;

    constructor(
        identifierStartsWith: string | string[],
        inObjectDependencies: D,
    ) {
        const arrOfIdentifiers = Array.isArray(identifierStartsWith)
            ? identifierStartsWith
            : [identifierStartsWith];
        this.objectTypeIdentifier = new Set(arrOfIdentifiers);
        this.inObjectDependencies = inObjectDependencies;
        this.invalidationFlags = invalidationFlagsFor(inObjectDependencies);
        ObjectUtils.deepFreeze(this);
    }

    fits(state: Readonly<SceneInstance>) {
        return this.objectTypeIdentifier.has(state.type_identifier);
    }
}

export type AnySolverObjectInput = Partial<InObjectDependencies<SolverLegacyPropsInput, PropsGroupBase>>;

export interface SolverLegacyPropsInput {
    [shortName: string]: BimProperty;
}

export interface InObjectDependencies<BimProps extends SolverLegacyPropsInput, NewProps extends PropsGroupBase> {
    legacyProps: BimProps;
    propsInOut: NewProps;
    localTransform: Readonly<Transform>;
    worldMatrix: Readonly<Matrix4>;
    representation: Readonly<ObjectRepresentation> | null;
    representationAnalytical: Readonly<BasicAnalyticalRepresentation> | null;
}


export function invalidationFlagsFor(deps: Partial<InObjectDependencies<any, any>>): SceneObjDiff {
    let flags = SceneObjDiff.None;
    if (deps.legacyProps) {
        flags |= SceneObjDiff.LegacyProps;
    }
    if (deps.propsInOut) {
        flags |= SceneObjDiff.NewProps;
    }
    if (deps.localTransform) {
        flags |= SceneObjDiff.LocalTransform;
    }
    if (deps.worldMatrix) {
        flags |= SceneObjDiff.WorldPosition;
    }
    if (deps.representation !== undefined) {
        flags |= SceneObjDiff.Representation;
        flags |= SceneObjDiff.GeometryReferenced;
    }
    if (deps.representationAnalytical !== undefined) {
        flags |= SceneObjDiff.Representation;
        flags |= SceneObjDiff.GeometryReferenced;
    }
    return flags;
}
