<script lang="ts">
    import { onMount } from "svelte";

    export let lineCount: number;
    let root: HTMLDivElement;
    let truncated: boolean;
    let expanded = false;

    onMount(() => {
        const maximumHeight =
            (lineCount || 1) *
            parseInt(window.getComputedStyle(root).lineHeight, 10);
        truncated = maximumHeight < root.scrollHeight;
    });
</script>

<div
    bind:this={root}
    class:container={truncated && !expanded}
    style="--line-count:{lineCount};"
>
    <slot />
</div>
{#if truncated}
    <div
        class="button mdc-typography--body2-strong"
        on:click={() => {
            expanded = !expanded;
        }}
    >
        {#if expanded}Hide{:else}More{/if} details
    </div>
{/if}

<style lang="scss">
    .container {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: var(--line-count);
        line-clamp: var(--line-count);
        -webkit-box-orient: vertical;
    }
    .button {
        cursor: pointer;
    }
</style>
