import type { Bim, IdBimScene, PropertiesGroupFormatters, SceneInstance} from "src";
import { NumberProperty, SceneObjDiff } from "src";
import type { LazyVersioned} from "engine-utils-ts";
import { DefaultMap, IterUtils, LazyDerivedAsync, LegacyLogger, ObjectUtils, Yield, preferPreviousOverInProgressWrapper, replaceCurrencyUnitWithSymbol } from "engine-utils-ts";
import { CombinerBoxKeyProps, CombinerBoxTypeIdent } from "src/archetypes/CombinerBox";
import type { CostComponents, CostComponentsNonNullable, CostsConfigProvider, EstimateCost, InstanceCost, SingleEstimateProvider} from "src/cost-model/capital";
import { CostHierarchy, createEmptyCostComponents, createEmptyCostComponentsNonNullable, createMiscCategory, fillModelBasedCostCategory, fillModelBasedTopLevelCategory } from "src/cost-model/capital";

export function create_ElectricalSubtotal_CombinerBoxs(
    bim: Bim,
    provider: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>,
) {
    const instances = bim.instances.getLazyListOf({
        type_identifier: CombinerBoxTypeIdent,
        relevantUpdateFlags: SceneObjDiff.NewProps | SceneObjDiff.LegacyProps
    })
    const groupedInstances = createGroupedInstances(instances, bim.keyPropertiesGroupFormatter);

    const result = LazyDerivedAsync.new4<
        CostHierarchy,
        GroupedCombinerBoxs,
        InstanceCost[],
        EstimateCost[],
        NumberProperty
    >(
        'cb',
        [bim.unitsMapper],
        [groupedInstances, provider.allInstanceCosts, provider.allEstimateCosts, totalDC],
        function* ([groupedCables, costs, estimates, totalDC]) {
            const costUnit = bim.unitsMapper.mapToConfigured({ value: 0, unit: 'usd' }).unit!;

            // extract final result
            const hierarchy = new CostHierarchy()

            for (const { type, uniqueCBGroups } of groupedCables.cbTypeGroups) {
                const sub = new CostHierarchy();
                for (const { ids, name, props } of uniqueCBGroups) {
                    let overrides = costs.find(x => ObjectUtils.areObjectsEqual(x.props, props))?.costs ?? createEmptyCostComponents();
                    const defaults = getCombinerBoxDefaultCostsPerEach(props);

                    const category = sub.add({
                        description: { value: name },
                        costUnit: {
                            options: [replaceCurrencyUnitWithSymbol(costUnit) + '/each' ],
                            index: 0,
                        },
                        quantity: {
                            value: NumberProperty.new({ value: ids.size }),
                            integer: true,
                        },
                        relatedSceneInstanceIds: new Set(ids),
                    })

                    const updateCosts = (newCosts: CostComponents) => {
                        provider.findAndUpdateInstanceCost(
                            (prev) => (prev.costs = newCosts, prev),
                            { instance_type: CombinerBoxTypeIdent, props }
                        )
                    }
                    category[1].matchesSceneInstance = (query) => {
                        if (query.si?.type_identifier !== CombinerBoxTypeIdent) {
                            return false;
                        }
                        const uniqueProps = query.si.properties.extractPropertiesGroup(CombinerBoxKeyProps, { valueUnitOnly: true })
                        const result = ObjectUtils.areObjectsEqual(uniqueProps, props);
                        return result;
                    }
                    fillModelBasedCostCategory(category[1], overrides, defaults, updateCosts, ids.size);
                }

                // add subroot
                {
                    const category = sub.addRoot({ description: { value: type } });
                    sub.sumupChildren(category[0])
                }

                hierarchy.merge(sub);
            }

            // create misc cable misc
            {
                const costId = 'combiner-box-misc';
                const overrides = estimates.find(x => x.id.value === costId);
                const state: SingleEstimateProvider = {
                    id: costId,
                    defaults: createEmptyCostComponentsNonNullable(),
                    value: overrides,
                    update: (fn) => provider.findAndUpdateEstimateCost(costId, fn),
                }
                hierarchy.add(createMiscCategory(state, bim.unitsMapper, totalDC));
            }

            // create model root category
            {
                const root = hierarchy.addRoot({ description: { value: 'Combiner Box' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.laborCost = NumberProperty.new({ value: 0.001, unit: 'usd' });
                defaults.materialCost = NumberProperty.new({ value: 0.009, unit: 'usd' });
                fillModelBasedTopLevelCategory(
                    root[0],
                    hierarchy,
                    'combiner-box-benchmark',
                    estimates,
                    provider.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
            }


            return hierarchy;
        }
    )
    return result;
}


function createGroupedInstances(
    instances: LazyVersioned<Array<[id: IdBimScene, si: SceneInstance]>>,
    keyPropsGroupFormatter: PropertiesGroupFormatters,
) {
    const result = preferPreviousOverInProgressWrapper(LazyDerivedAsync.new1(
        'cb-grouped',
        [],
        [instances],
        function* ([instances]) {
            type Type = string;
            type Name = string;
            const groups = new DefaultMap<
                Type,
                DefaultMap<Name, UniqueCombinerBoxGroup>
            >(() => new DefaultMap(name => ({
                name,
                ids: new Set(),
                props: CombinerBoxKeyProps,
            })));

            for (const chunk of IterUtils.splitIterIntoChunks(instances, 10e3)) {
                yield Yield.Asap;
                const grouped = IterUtils.groupObjects(
                    chunk,
                    (o) => {
                        const props = o[1].properties.extractPropertiesGroup(CombinerBoxKeyProps);
                        return props.model.value + props.voltage.as('V') + props.type.value;
                    },
                    (l, r) => ObjectUtils.areObjectsEqual(
                        l[1].properties.extractPropertiesGroup(CombinerBoxKeyProps, { valueUnitOnly: true }),
                        r[1].properties.extractPropertiesGroup(CombinerBoxKeyProps, { valueUnitOnly: true })
                    ),
                )
                for (const members of grouped) {
                    const sample = members[0][1];
                    const props = sample.properties.extractPropertiesGroup(CombinerBoxKeyProps);
                    const type = props.type.asText();
                    const name = keyPropsGroupFormatter.format(CombinerBoxTypeIdent, members[0][1].properties, members[0][1].props);
                    if (!type || !name) {
                        LegacyLogger.warn('Invalid name ' + name + '. Skipping');
                        continue;
                    }
                    const group = groups.getOrCreate(type).getOrCreate(name);
                    group.props = props;
                    members.forEach(x => group.ids.add(x[0]));
                }
            }

            const result: GroupedCombinerBoxs = {
                cbTypeGroups: Array.from(groups.entries()).map(([k, v]) => ({
                    type: k,
                    uniqueCBGroups: Array.from(v.values())
                }))
            }
            return result;
        }
    ));
    return result;
}


interface UniqueCombinerBoxGroup {
    name: string
    props: typeof CombinerBoxKeyProps
    ids: Set<IdBimScene>
}

interface CombinerBoxTypeGroup {
    type: string
    uniqueCBGroups: UniqueCombinerBoxGroup[]
}

interface GroupedCombinerBoxs {
    cbTypeGroups: CombinerBoxTypeGroup[],
}

export function getCombinerBoxDefaultCostsPerEach(props: typeof CombinerBoxKeyProps): CostComponentsNonNullable {
    const result: CostComponentsNonNullable = {
        ...createEmptyCostComponentsNonNullable(),
        laborTimeUnits: NumberProperty.new({ value: 4 }),
        laborTimeUnitCost: NumberProperty.new({ value: 50, unit: 'usd' }),
        laborCost: NumberProperty.new({ value: 200, unit: 'usd' }),
        materialCost: NumberProperty.new({ value: props.current.as('A') * 3.6, unit: 'usd' }),
    };

    return result;
}
