import { Failure, PollablePromise, Result, Success, Yield } from 'engine-utils-ts';
import { Vector2 } from 'math-ts';
import { EChartOptions, echarts } from 'ui-charts';

export function* createChartPngImage(size: Vector2, options: EChartOptions): Generator<Yield, Result<string>, unknown> {
    // draw chart
    const link = document.createElement("div");
    const chart = echarts.init(link, null, {
        renderer: "svg",
        width: size.x,
        height: size.y,
    });
    const chartOptions: EChartOptions = { 
        ...options,
    };
    if(options["yAxis"]){
        chartOptions["yAxis"] ={
            ...options["yAxis"] || {},
            name: ""
        };
    }

    chart.setOption(chartOptions);
    
    function waitForTillChartLoaded(сhartInstance: echarts.ECharts) {
        return new Promise((resolve) => {
            сhartInstance.on('finished',  () => {
                resolve(null);
            });
        });
    }
    const pr = waitForTillChartLoaded(chart);
    const result = yield* PollablePromise.generatorWaitFor(pr);

    if(result instanceof Failure){
        return result;
    }
    
    const image = chart.getDataURL({
        type: 'png',
        pixelRatio: 2,
        backgroundColor: '#fff',
        excludeComponents: ['toolbox'],
    });

    // Dispose
    chart.dispose();
    link.remove();

    return new Success(image);
}
