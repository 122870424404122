<script lang="ts">
// @ts-ignore
import Grid from "svelte-grid";
import { getContext } from 'svelte';
import EngineUiPanelsConstructors from './EngineUiPanelsConstructors.svelte';
import { UI_LAYOUT_COLUMNS, type SvelteGridLayoutItem } from './EngineUiLayout';
import { PanelViewPosition } from 'ui-bindings';
import { ViewPosition, type NavbarContext } from '../navbar/Navbar';

const svelteGridCols = [[80000, UI_LAYOUT_COLUMNS]];
const navbar = <NavbarContext>getContext('ui-navbar');

export let onLayoutChanged: (item: SvelteGridLayoutItem) => void;
export let onClose: () => void;
export let focus: () => void;

export let item: SvelteGridLayoutItem;
let items: SvelteGridLayoutItem[] = [];

$: setItems(item);

function setItems(..._deps: any[]) {
  if (item) {
    items = [item]
  } else {
    items = [];
  }
}

$: sendOnChange(items);

function sendOnChange(..._deps: any[]) {
  onLayoutChanged(items[0]);
}

function onPointerUp() {
    focus();
    navbar.dragView(null);
}

</script>

<Grid
    cols={svelteGridCols}
    gap={[0, 0]}
    rowHeight={40}
    fastStart={true}
    let:movePointerDown let:resizePointerDown
    bind:items={items}
    let:dataItem
    on:pointerup={onPointerUp}
>
    {#if dataItem.isVisible && (
        dataItem.position === PanelViewPosition.None ||
        $navbar.views[dataItem.id] === ViewPosition.Float
    )}
        <div
            class="panel-outer"
            on:pointerdown={focus}
        >
            <div class="panel-inner">
                <EngineUiPanelsConstructors
                    close={onClose}
                    identifier={dataItem.id}
                    name={dataItem.name}
                    uiNodeDescription={dataItem.uiNodeDescription}
                    movePointerDown={(e) => {
                        movePointerDown(e);
                        if (dataItem.position !== PanelViewPosition.None) {
                            navbar.dragView(dataItem.id);
                        }
                    }}
                    resizePointerDown={resizePointerDown}
                >
                </EngineUiPanelsConstructors>
            </div>
        </div>
    {/if}
</Grid>

<style lang="scss">
    .panel-outer {
        pointer-events: auto;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        position: relative;
        left: -1px;
        top: -1px;
    }
    .panel-inner {
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        background: white;
        position: relative;
        left: 3px;
        top: 3px;
        border-radius: 2px;
        box-shadow: var(--dropdownsimple-box-shadow);
    }
</style>
