import { isInNode, IterUtils, ResolvedPromise } from '../';
import type { LazyVersioned, PollWithVersionResult } from '../stateSync/LazyVersioned';
import type { ObjectSerializer } from '../ObjectSerializer';
import { ObjectUtils } from '../ObjectUtils';
import { ScopedLogger } from '../ScopedLogger';
import type { UndoStack } from '../UndoStack';
import type {
    EventStackFrame} from './EventsStackFrame';
import { peekCurrentEventFrame, unsafePopFromEventStackFrame, unsafePushToEventStackFrame
} from './EventsStackFrame';
import type { IObservableObject, Observer, ObserverSettings, SvelteStore } from './Observables';
import { getUniqueEntityIdentifier } from './Observables';
import { ObservableStream } from './ObservableStream';
import type { RemoteSyncHub} from './RemoteSyncHub';
import { SyncEventIdents } from './RemoteSyncHub';

export type PatchCallback<T> = (patch: Partial<T>, currentValueRef: Readonly<T>, revertPatchRef: Partial<T>) => void;
export type StatePatchTransformer<From, To> = (patch: Partial<From>, full: From) => Partial<To> | null;

export interface ThrottleSettings {
	// resetOnMouseUp: boolean;
	resetInBetweenTimeoutMs: number;
	resetTotalTimeoutMs: number;
}

const DefaultThrottlingSettings: ThrottleSettings = Object.freeze({
	// resetOnMouseUp: true,
	resetInBetweenTimeoutMs: 1000,
	resetTotalTimeoutMs: 5000,
});

export interface ObservableObjectParams<T extends Object> {
	identifier: string,
	initialState: T,
	logger?: ScopedLogger,
	throttling?: {
		onlyFields?: (keyof T)[],
		settings?: ThrottleSettings,
	},
	undoStack?: UndoStack,
	serializer?: ObjectSerializer<T>,
	isShapeReplacementAllowed?: boolean,
	disposeListener?: (self: ObservableObject<T>) => void
}


export class ObservableObject<T extends Object> implements IObservableObject<T>, LazyVersioned<T>, SvelteStore<T> {

	readonly identifier: string;
	readonly uniqueIdent: string;
	readonly logger: ScopedLogger;

	readonly throttleSettings: ThrottleSettings | null;

    private _onlyThrottleFields: Set<keyof T> | null;
	private _throttle_step_n: number = 0;
	private _lastThrottleResetTime: number = 0;
	private _lastUpdateTime: number = 0;

    private _version: number = 0;
    private _value: T;
    private readonly _patchNotifications: ObservableStream<{ patch: Partial<T>, currentValueRef: Readonly<T>, event: EventStackFrame }>;
    private readonly _shapeReplacementNotification?: ObservableStream<{ value: T, currentValueRef: Readonly<T>, event: EventStackFrame }>;

	private  _undoStack?: UndoStack;

	private _stateValidators: Map<keyof T, (field: any) => any> = new Map();

	private _syncHub: RemoteSyncHub | null = null; // TODO: remove this, make syncing external
	private _serializer?: ObjectSerializer<T>;

	private _isDisposed: boolean = false;
	private _disposeListeners?: ((self: ObservableObject<T>) => void)[];

	constructor(params: ObservableObjectParams<T>) {
		this.identifier = params.identifier;
		this.uniqueIdent = getUniqueEntityIdentifier('observable-object', this.identifier);
		this.logger = params.logger ? params.logger.newScope(params.identifier) : new ScopedLogger(this.identifier);
        // this._prevValue = initValue;
		this._value = params.initialState;

		this._patchNotifications = new ObservableStream({
			identifier: this.identifier + "_patch_stream",
			holdLastValueForNewSubscribers: false
		});
		if (params.isShapeReplacementAllowed) {
			this._shapeReplacementNotification = new ObservableStream({
				identifier: this.identifier,
				holdLastValueForNewSubscribers: false
			})
		}


		this._onlyThrottleFields = (params.throttling?.onlyFields?.length ?? 0 > 0) ? new Set(params.throttling?.onlyFields) : null;
		this.throttleSettings = params.throttling ? { ...DefaultThrottlingSettings, ...params.throttling?.settings } : null;

		const serializer = params.serializer;
		if (serializer && !isInNode() && (window as any)['IsDevEnv']) {
			// double check serializer
			this._serializer = {
				deserialize: (data) => {
					return serializer.deserialize(data);
				},
				serialize: (obj) => {
					const serialized = serializer.serialize(obj);
					const deserialized = serializer.deserialize(serialized);
					if (!ObjectUtils.areObjectsEqual(obj, deserialized)) {
						this.logger.error(`serialized and deserialized objects are not equal`, obj, deserialized);
						throw new Error(`${this.identifier} serializer is broken`);
					}
					return serialized;
				}
			}
		} else {
			this._serializer = serializer;
		}

		this._undoStack = params.undoStack;

		if (params.disposeListener) {
			this.withDisposeListener(params.disposeListener);
		}
	}

	withDisposeListener(disposeListener: (self: ObservableObject<T>) => void) {
		if (!this._disposeListeners) {
			this._disposeListeners = [];
		}
		this._disposeListeners.push(disposeListener);
	}

	isShapeReplacementAllowed(): boolean {
		return this._shapeReplacementNotification != undefined;
	}

	async attachToSyncHub(hub: RemoteSyncHub): Promise<void> {
		if (this._serializer == undefined) {
			throw new Error(`${this.identifier} doesnt have serializer set up, to be attached to synchub`);
		}
		if (this._syncHub) {
			if (this._syncHub == hub) {
				console.warn('double attach to the same sync hub', this.identifier);
			}
            throw new Error(`${this.identifier} is already listening to sync hub`);
        }
		await hub.startSyncing(this);
        this._syncHub = hub;
	}

	setAttachedSyncHub(hub: RemoteSyncHub) {
		this.logger.assert(this._syncHub == null, 'attaching sync hub, prev should be null')
		this._syncHub = hub;
	}

	detachFromSyncHub() {
		if (this._syncHub) {
			this._syncHub.removeFromSync(this);
			this._syncHub = null;
		}
	}

	dispose() {
		if (this._isDisposed) {
			return;
		}
		this._isDisposed = true;
		while (this._disposeListeners?.length) {
			const l = this._disposeListeners.shift()!;
			try {
				l(this);
			} catch (e) {
				console.error('error in observable_object dispose listener', e);
			}
		}
		// this._immidiateCallbacks.length = 0;
		this.detachFromSyncHub();
	}

    addPatchValidator<Key extends keyof T>(key: Key, validator: (field: T[Key]) => T[Key]) {
		if (this._stateValidators.has(key)) {
			console.error('state field validator is already set for', key);
		}
		this._stateValidators.set(key, validator);
	}

    poll(): Readonly<T> {
        return this._value;
    }
    version(): number {
        return this._version;
    }
    pollWithVersion(): PollWithVersionResult<T> {
        return { value: this._value, version: this._version };
    }

    currentValue(): T {
        return ObjectUtils.deepCloneObj(this._value);
    }

	forceResetThrottle() {
		this._throttle_step_n = 0;
		this._lastThrottleResetTime = performance.now();
    }

	applyPatch({ patch, event }: { patch: Partial<T>; event?: Partial<EventStackFrame>; }): Promise<void> {
		const prevEvent = peekCurrentEventFrame();
		const newEventWithThisObservable: Partial<EventStackFrame> = { ...event, observableUniqueIdent: this.uniqueIdent };
		const thisEvent: Readonly<EventStackFrame> = unsafePushToEventStackFrame(newEventWithThisObservable);
		try {
			return this._applyPatch(patch, prevEvent, thisEvent) ?? ResolvedPromise;
		} finally {
			unsafePopFromEventStackFrame(thisEvent);
		}
	}

	applyPatchOrReplace(args: { value: T; event?: Partial<EventStackFrame>; }): Promise<void> {
		let shapeChanged = !ObjectUtils.isTheSameShapeAs(this._value, args.value);
		if (shapeChanged) {
			if (!this.isShapeReplacementAllowed()) {
				this.logger.error('changing shape is not allowed');
				throw new Error(`changing shape is not allowed ${this.identifier}`);
			} else {
				return this.replace(args);
			}
		} else {
			return this.applyPatch({patch: this._value, event: args.event });
		}
	}

	replace(args: { value: T; event?: Partial<EventStackFrame>; }): Promise<void> {
		let thisEvent = unsafePushToEventStackFrame({ ...args.event, observableUniqueIdent: this.uniqueIdent });
		try {
			throw new Error('not impl');
		} finally {
			unsafePopFromEventStackFrame(thisEvent);
		}
	}


	private _applyPatch(patch: Partial<T>, prevEvent: EventStackFrame, thisEvent: EventStackFrame): Promise<void> | null {
		if (this._isDisposed) {
			return Promise.reject(`observable ${this.identifier} is disposed already`)
		}
		this.logger.debugAssert(thisEvent == peekCurrentEventFrame(), 'observable: current event santiy check');
		if (this._stateValidators.size) {
			for (const [key, validator] of this._stateValidators) {
				if (patch[key] !== undefined) {
					patch[key] = validator(patch[key]);
				}
			}
		}
		const now = performance.now();

		if (this.throttleSettings && this._throttle_step_n != 0) {
			if (now - this._lastUpdateTime > this.throttleSettings.resetInBetweenTimeoutMs) {
				this.forceResetThrottle();
			} else if (now - this._lastThrottleResetTime > this.throttleSettings.resetTotalTimeoutMs) {
				this.forceResetThrottle();
			} else if (this._onlyThrottleFields) {
				for (const key in patch) {
					if (this._value[key] !== undefined && !this._onlyThrottleFields.has(key)) {
						this.forceResetThrottle();
						break;
					}
				}
			}
		}

		let promiseToReturn: Promise<void> | null = null;

		const patchRes: { revertPatch: Partial<T>, appliedPatch: Partial<T> } | null
			= ObjectUtils.patchObject(this._value, patch);
		if (patchRes) {
			const throttleStepN = this._throttle_step_n;

			this._version += 1;
			this._lastUpdateTime = now;
			this._throttle_step_n += 1;
			const { appliedPatch, revertPatch } = patchRes

			if (this._undoStack && (throttleStepN == 0)) {
				this._undoStack.addUndoAction<typeof revertPatch>({
					actionName: 'udpate',
					sourceIdentifier: this.identifier,
					args: revertPatch,
					act: (patch) => { this.forceResetThrottle(); this.applyPatch({ patch }); },
				});
			}

			this._patchNotifications.pushNext({ patch: appliedPatch, currentValueRef: this._value, event: thisEvent });

			if (this._syncHub && !thisEvent.identifier.startsWith(SyncEventIdents.Prefix)) {
				promiseToReturn = this._syncHub.pushUpdateOf(this, throttleStepN);
			}
		}
		return promiseToReturn;
	}

	// private _replace(newValue: T, thisEvent: EventStackFrame): Promise<void> {
	// 	if (this._isDisposed) {
	// 		return Promise.reject(`observable ${this.identifier} is disposed already`)
	// 	}
	// 	if (!this.isShapeReplacementAllowed()) {
	// 		return Promise.reject(`shape replacement is not allowed`);
	// 	}

	// 	this.logger.debugAssert(thisEvent == peekCurrentEventFrame(), 'observable: current event santiy check');
	// 	if (this._stateValidators.size) {
	// 		for (const [key, validator] of this._stateValidators) {
	// 			if (newValue[key] !== undefined) {
	// 				newValue[key] = validator(newValue[key]);
	// 			}
	// 		}
	// 	}
	// 	const now = performance.now();

	// 	if (this.throttleSettings && this._throttle_step_n != 0) {
	// 		this.forceResetThrottle();
	// 	}

	// 	let promiseToReturn: Promise<void> | null = null;

	// 	if (!ObjectUtils.areObjectsEqual(newValue, this._value)) {

	// 		this._version += 1;
	// 		this._lastUpdateTime = now;

	// 		const prevValue = this._value;
	// 		this._value = newValue;

	// 		this._shapeReplacementNotification!.pushNext({
	// 			value: ObjectUtils.deepCloneObj(newValue), currentValueRef: this._value, event: thisEvent
	// 		});

	// 		if (this._undoStack && (thisEvent.saveUndoHint !== false)) {
	// 			this._undoStack!.addUndoAction({
	// 				name: this.uniqueIdent,
	// 				args: prevValue,
	// 				act: (value) => { this.replace({ value }); },
	// 			});
	// 		}

	// 		if (this._syncHub && !thisEvent.identifier.startsWith(SyncEventIdents.Prefix)) {
	// 			promiseToReturn = this._syncHub.pushUpdateOf(this, 0);
	// 		}

	// 		if (this._immidiateCallbacks.length) {
	// 			for (const c of this._immidiateCallbacks) {
	// 				try {
	// 					c(patchRes.appliedPatch, this._value);
	// 				} catch (e) {
	// 					console.error('error duing observable immimdiate callback', patchRes, e);
	// 				}
	// 			}
	// 		}
	// 		for (const [o, tr] of this._chainedObservables) {
	// 			if (o._isDisposed) {
	// 				this._chainedObservables.delete(o);
	// 				continue;
	// 			}
	// 			if (prevEvent.observableUniqueIdent == o.uniqueIdent) {
	// 				// if event originated in chained observable
	// 				// do not notify it back, it makes no sense and will create infinite loop of events
	// 				continue;
	// 			}
	// 			try {
	// 				const patchTransformed = tr(patchRes.appliedPatch, this._value);
	// 				if (patchTransformed) {
	// 					o.applyPatch({patch: patchTransformed});
	// 				}
	// 			} catch (e) {
	// 				console.error('error duing observable chained callback', patchRes, e);
	// 			}
	// 		}
	// 	}
	// 	return promiseToReturn;
	// }


	observeObject({ onPatch, onTypeReplacement, settings }: {
		onPatch: (args: { patch: Partial<T>, currentValueRef: Readonly<T>, event: EventStackFrame }) => void,
		onTypeReplacement?: (args: { value: T, event: EventStackFrame }) => void,
		settings?: Partial<ObserverSettings>,
	}): Observer {
		const l = this._patchNotifications.subscribe({ settings, onNext: onPatch });
		if (!settings?.doNotNotifyCurrentState) {
			onPatch({ patch: ObjectUtils.deepCloneObj(this._value), currentValueRef: this._value, event: peekCurrentEventFrame() });
		}
		if (this.isShapeReplacementAllowed()) {
			if (onTypeReplacement == undefined) {
				this.logger.error(`typeReplacement is allowed, but typeReplacemenet observer is not provided, on type change observer may go out of sync`);
			} else {
				this._shapeReplacementNotification?.subscribe({ settings, onNext: onTypeReplacement });
			}
		} else if (onTypeReplacement) {
			// this.logger.info(`type replacement is not allowed for this observable, type replacement listener is not necessary`);
		}
        return l;
	}

	_addChainedObservable<T2 extends Object>(
		obs: ObservableObject<T2>,
		transform: StatePatchTransformer<T, T2>,
		eventParams: Partial<EventStackFrame>
	): Observer {
		return this.observeObject({
			onPatch: ({ patch, currentValueRef, event }) => {
				const patchTransformed = transform(patch, this._value);
				if (patchTransformed) {
					obs.applyPatch({ patch: patchTransformed, event: eventParams });
				}
			},
			onTypeReplacement: ({ }) => {
				throw new Error('not implemented');
			},
			settings: { immediateMode: true, doNotNotifyCurrentState: true }
		});
	}

	newChainedObservable<T2 extends Object>(
		transformPatch: StatePatchTransformer<T, T2>,
		transformBack?: StatePatchTransformer<T2, T>,
	) {
		const initialState = transformPatch(this.poll(), this.poll()) as T2;
		const o = new ObservableObject<T2>({
			identifier: this.identifier + '_transformed_',
			initialState,
		});
		const observers: Observer[] = [];

		const eventParams = { skipObserverWithIdent: '' };
		const o1 = this._addChainedObservable(o, transformPatch, eventParams);
		observers.push(o1);
		if (transformBack) {
			const o2 = o._addChainedObservable(this, transformBack, { skipObserverWithIdent: o1.uniqueIdent });
			observers.push(o2);
			eventParams.skipObserverWithIdent = o2.uniqueIdent; // mutate initial object
		}
		o.withDisposeListener(() => IterUtils.disposeArray(observers));
		this.withDisposeListener(() => o.dispose());
		return o;
	}

	newChainedSubsetObservable<T2 extends Object>(
		keys: (keyof T2 & keyof T)[],
	) {
		const initialStateFull = this.poll();
		for (const k of keys) {
			if (initialStateFull[k] == undefined) {
				throw new Error('source observable state doesnt have field ' + (k as string));
			}
		}
		const keysAllowed = new Set(keys);
		const patchTransform: StatePatchTransformer<T, T2> = (p, _) => extracPatchPoriton(p, keysAllowed);
		const patchTransformBack: StatePatchTransformer<T2, T> = (p, _) => p as Partial<T>;
		return this.newChainedObservable(patchTransform, patchTransformBack);
	}

	serializer(): ObjectSerializer<T> {
		if (!this._serializer) {
			throw new Error(`no serializer provided for ${this.identifier}`);
		}
		return this._serializer;
	}

	// svelte store
	subscribe(callback: (value: T) => void): { unsubscribe: () => void } {
		return this.observeObject({
			onPatch: ({ currentValueRef }) => {
				callback(currentValueRef);
			}
		});
	}
}

function extracPatchPoriton<T, T2>(patch: Partial<T>, keysAllowed: Set<keyof T & keyof T2>): Partial<T2> | null {
	const apiPatch: any = {};
	let patchIsEmpty = true;
	for (const key in patch) {
		if (keysAllowed.has(key as any)) {
			patchIsEmpty = false;
			apiPatch[key] = ObjectUtils.deepCloneObj(patch[key]);
		}
	}
	if (patchIsEmpty) {
		return null;
	} else {
		return apiPatch;
	}
}
