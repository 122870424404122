// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { NamedNodeT } from './named-node.js';
import { NamedNode } from './named-node.js';


export class PropertiesGroup implements flatbuffers.IUnpackableObject<PropertiesGroupT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):PropertiesGroup {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsPropertiesGroup(bb:flatbuffers.ByteBuffer, obj?:PropertiesGroup):PropertiesGroup {
  return (obj || new PropertiesGroup()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsPropertiesGroup(bb:flatbuffers.ByteBuffer, obj?:PropertiesGroup):PropertiesGroup {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new PropertiesGroup()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

fields(index: number, obj?:NamedNode):NamedNode|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new NamedNode()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

fieldsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

typeIdent():string|null
typeIdent(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
typeIdent(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startPropertiesGroup(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addFields(builder:flatbuffers.Builder, fieldsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, fieldsOffset, 0);
}

static createFieldsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startFieldsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addTypeIdent(builder:flatbuffers.Builder, typeIdentOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, typeIdentOffset, 0);
}

static endPropertiesGroup(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createPropertiesGroup(builder:flatbuffers.Builder, fieldsOffset:flatbuffers.Offset, typeIdentOffset:flatbuffers.Offset):flatbuffers.Offset {
  PropertiesGroup.startPropertiesGroup(builder);
  PropertiesGroup.addFields(builder, fieldsOffset);
  PropertiesGroup.addTypeIdent(builder, typeIdentOffset);
  return PropertiesGroup.endPropertiesGroup(builder);
}

unpack(): PropertiesGroupT {
  return new PropertiesGroupT(
    this.bb!.createObjList<NamedNode, NamedNodeT>(this.fields.bind(this), this.fieldsLength()),
    this.typeIdent()
  );
}


unpackTo(_o: PropertiesGroupT): void {
  _o.fields = this.bb!.createObjList<NamedNode, NamedNodeT>(this.fields.bind(this), this.fieldsLength());
  _o.typeIdent = this.typeIdent();
}
}

export class PropertiesGroupT implements flatbuffers.IGeneratedObject {
constructor(
  public fields: (NamedNodeT)[] = [],
  public typeIdent: string|Uint8Array|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const fields = PropertiesGroup.createFieldsVector(builder, builder.createObjectOffsetList(this.fields));
  const typeIdent = (this.typeIdent !== null ? builder.createString(this.typeIdent!) : 0);

  return PropertiesGroup.createPropertiesGroup(builder,
    fields,
    typeIdent
  );
}
}
