<script lang="ts">
</script>

<div class="text-main-dark content">
    <div class="mdc-typography--headline5">Arrays alignment</div>
    <div>
        On rectangular areas both Maximum capacity and arrays alignment will be
        achieved. For the areas with complex boundaries there are 2 strategies
        to chose from:
    </div>
    <div class="mdc-typography--tooltip images-container">
        <div>
            <img src="static/images/maximum-capacity.png" alt="" /><span
                >Maximum capacity</span
            >
        </div>
        <div>
            <img src="static/images/align-arrays.png" alt="" /><span
                >Aligned arrays rows</span
            >
        </div>
    </div>
</div>

<style lang="scss">
    .content > div {
        margin-bottom: 4px;
    }
    .images-container {
        display: flex;
        justify-content: space-between;
    }
    .images-container div {
        display: flex;
        flex-direction: column;
    }
    .images-container span {
        margin-top: 4px;
        margin-left: 8px;
    }
</style>
