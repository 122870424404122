<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
>
    <path
        d="M21 12.1714C21 13.0623 19.9228 13.5085 19.2929 12.8785C19.1053 12.691 19 12.4366 19 12.1714V2.99983C19 2.62108 19.2139 2.27484 19.5527 2.10544C20.2176 1.77291 21 2.25641 21 2.99983V12.1714Z"
        fill="currentColor"
    />
    <path
        d="M5.53 13.6144C5.82239 13.4317 6.00001 13.1112 6.00001 12.7664L5.99999 10.4997C5.99999 9.75629 5.21768 9.2728 4.55278 9.60525C4.214 9.77464 4 10.1209 4 10.4997V12.7664C4 13.5518 4.86396 14.0307 5.53 13.6144Z"
        fill="currentColor"
    />
    <path
        d="M9.34146 16.0949C9.12444 15.905 8.99996 15.6307 8.99996 15.3423V7.99988C8.99996 7.62111 9.21397 7.27485 9.55275 7.10546C10.2177 6.77301 11 7.2565 11 7.99989V15.7419C11 16.0242 10.8546 16.2866 10.6152 16.4362C10.3091 16.6275 9.91383 16.5957 9.64218 16.358L9.34146 16.0949Z"
        fill="currentColor"
    />
    <path
        d="M14 12.5164C14 13.3019 14.8639 13.7807 15.53 13.3644C15.8223 13.1817 16 12.8612 16 12.5164V5.49988C16 4.7565 15.2177 4.27301 14.5528 4.60546C14.214 4.77485 14 5.12111 14 5.49988V12.5164Z"
        fill="currentColor"
    />
    <path
        d="M19.8321 15.4178C18.8443 14.43 17.3054 14.2547 16.1208 14.9951L10.7294 18.3648C10.3555 18.5985 9.87267 18.5597 9.54086 18.2694L7.77615 16.7252C6.78071 15.8542 5.33229 15.738 4.21064 16.439L1.47 18.1519C1.17762 18.3346 1 18.6551 1 18.9999C1 19.7853 1.86395 20.2642 2.53 19.8479L5.27064 18.135C5.64452 17.9013 6.12733 17.9401 6.45914 18.2304L8.22385 19.7745C9.21928 20.6455 10.6677 20.7618 11.7894 20.0608L17.1808 16.6911C17.5757 16.4443 18.0886 16.5027 18.4179 16.832L21.2929 19.707C21.9229 20.337 23 19.8908 23 18.9999C23 18.7347 22.8946 18.4803 22.7071 18.2928L19.8321 15.4178Z"
        fill="currentColor"
    />
</svg>
