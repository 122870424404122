import type { LazyVersioned, Result} from 'engine-utils-ts';
import { ObservableObject, ScopedLogger, Yield, VersionedInvalidator,
    Allocated, Deleted, ObjectUtils, DefaultMap, IterUtils, CompressibleNumbersArray, Failure, Success
} from 'engine-utils-ts';
import type { PUI_GroupNode } from 'ui-bindings';
import { InverterProps } from '../archetypes/Inverter/Inverter';
import { EnergyYieldPropsGroup } from './EnergyYieldPropsGroup';
import { SubstationProps } from '../archetypes/substation/Substation';
import type { Bim } from '../Bim';
import type { EntitiesCollectionUpdates} from '../collections/EntitiesCollectionUpdates';
import { EntitiesUpdated } from '../collections/EntitiesCollectionUpdates';
import type { TMY_ColumnDates } from '../meteo/TMY_ColumnDates';
import { producePropsPatch } from '../properties/Props';
import type { GroupDescription, GroupIdent, GroupingSolver, GroupingSolverSettings } from '../runtime/BimCustomGroupedRuntime';
import { BimCustomGroupedRuntime } from '../runtime/BimCustomGroupedRuntime';
import type { SharedGlobalsInput } from '../runtime/RuntimeGlobals';
import type { IdBimScene, SceneInstance} from '../scene/SceneInstances';
import { SceneObjDiff } from '../scene/SceneObjDiff';
import { EnergyPipelineProperty } from './EnergyPipelineProperty';
import { EnergyPipelinesMerger } from './EnergyPipelinesMerger';
import { energySucess, EnergyFailure } from './EnergyResult';
import { EnergyCalculationsEnabled } from './EnergyCalculationsEnabled';

export function registerEnergyYieldSubstationProps(bim: Bim) {
    bim.customRuntimes.registerCustomRuntime(new BimCustomGroupedRuntime(
        bim.logger,
        'SubstationTmyStats',
        new SubstationsEnergySolver()
    ));
}

class SubstationGroupIdent implements GroupIdent {

    public readonly substationId: IdBimScene | 0;
    public readonly sortKey: string;
    
    constructor(
        substationId: IdBimScene,
    ) {
        this.substationId = substationId;
        this.sortKey = substationId.toString();
        Object.freeze(this);
    }

    uniqueHash(): string | number {
        return this.substationId;
    }
}

interface InverterInputsHash {
    readonly power_hash: string|number,
}


class SubstationGroupDescription implements GroupDescription<SubstationGroupIdent> {

    constructor(
        public readonly ident: SubstationGroupIdent,
        public readonly circuit_dc_power: number,
        public readonly invertersIds: IdBimScene[],
        public readonly invertersPropsHashes: InverterInputsHash[],
    ) {
        if (invertersIds.length !== invertersPropsHashes.length) {
            throw new Error('invertersIds.length !== invertersParamsVersions.length');
        }
    }

    *inputInstancesIds(): Iterable<IdBimScene> {
        // yield this.ident.substationId;
        // yield* this.invertersIds;
    }
}

class SubstationEnergySolverSettings implements GroupingSolverSettings {
    enabled: boolean = true;
    delaySeconds: number = 0.5;
}


const globalArgsSelector = {
    [EnergyCalculationsEnabled.name]: EnergyCalculationsEnabled,
};


export class SubstationsEnergySolver implements GroupingSolver<
    SubstationGroupIdent,
    SubstationGroupDescription,
    EnergyYieldPropsGroup,
    SubstationEnergySolverSettings,
    {}
> {
    logger: ScopedLogger;
    name: string = 'substations-energy-solver';
    executionOrder: number = 100;
    calculationsInvalidator: VersionedInvalidator;

    globalArgsSelector = globalArgsSelector;
    
    settings: ObservableObject<SubstationEnergySolverSettings>;
    ui?: LazyVersioned<PUI_GroupNode> | undefined;
    
    alwaysPassAllInvalidationBimUpdates: boolean = true;

    private _perInverterHashes = new Map<IdBimScene, InverterInputsHash | null>();
    private _substations = new Map<IdBimScene, null>();

    constructor(
    ) {
        this.logger = new ScopedLogger(this.name);
        this.calculationsInvalidator = new VersionedInvalidator();
        this.settings = new ObservableObject({
            identifier: this.name + '-settings',
            initialState: new SubstationEnergySolverSettings(),
        });
    }

    invalidate(
        logger: ScopedLogger,
        bim: Bim,
        instancesUpdate: EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>,
    ): void {
        const instances = bim.instances;
        if (instancesUpdate instanceof Allocated) {
            for (const id of instancesUpdate.ids) {
                const typeIdent = instances.peekTypeIdentOf(id);
                if (!typeIdent) {
                    continue;
                }
                if (typeIdent === 'inverter') {
                    this._checkInverterInputsHash(id, instances.peekById(id)!);
                } else if (typeIdent === 'substation') {
                    this._substations.set(id, null);
                }
            }
        } else if (instancesUpdate instanceof EntitiesUpdated) {
            const relevantFlags = SceneObjDiff.NewProps | SceneObjDiff.SpatialDescendants | SceneObjDiff.LegacyProps;

            for (let i = 0; i < instancesUpdate.ids.length; ++i) {
                const id = instancesUpdate.ids[i];
                const flags = instancesUpdate.diffs[i];
                if ((flags & relevantFlags) == 0) {
                    continue;
                }
                const typeIdent = instances.peekTypeIdentOf(id);
                if (!typeIdent) {
                    continue;
                }
                if (typeIdent === 'inverter' && (flags & SceneObjDiff.NewProps)) {
                    this._checkInverterInputsHash(id, instances.peekById(id)!);
                } else if (typeIdent === 'substation' && (flags & SceneObjDiff.SpatialDescendants)) {
                    this.calculationsInvalidator.invalidate();
                }
            }

        } else if (instancesUpdate instanceof Deleted) {
            for (const id of instancesUpdate.ids) {
                if (this._perInverterHashes.delete(id)) {
                    this.calculationsInvalidator.invalidate();
                } else {
                    this._substations.delete(id);
                }
            }
        } else {
            logger.error('could not recognize update type');
        }
    }

    invalidateFromSharedDependenciesUpdates?(ident: never): void {
    }

    *generateCalculationGroups(args: {
        logger: ScopedLogger;
        bim: Bim;
        globalArgs: SharedGlobalsInput<typeof globalArgsSelector>;
    }): Generator<Yield, SubstationGroupDescription[], unknown> {

        const enabledRes = args.globalArgs[EnergyCalculationsEnabled.name] as Result<EnergyCalculationsEnabled>;
        const isEnabled = enabledRes instanceof Success && enabledRes.value;

        const instances = args.bim.instances;

        const perSubstationGroups = new DefaultMap<IdBimScene, SubstationGroupDescription | null>(
            (invId) => {
                let substationParams = this._substations.get(invId);
                if (substationParams === undefined) {
                    this.logger.batchedError('unexpected abscence of substation params', invId);
                    return null;
                }
                const instance = instances.peekById(invId);
                if (!instance) {
                    this.logger.batchedError('unexpected abscence of substation instance', invId);
                    return null;
                }
                const dc_power = instance.properties.getPropNumberAs('circuit | aggregated_capacity | dc_power', 0, 'W');
                return new SubstationGroupDescription(new SubstationGroupIdent(invId), dc_power, [], []);
            }
        );
        for (const invId of this._substations.keys()) {
            perSubstationGroups.getOrCreate(invId);
        }
        yield Yield.Asap;

        const isSubstation = (id: IdBimScene): boolean => {
            return this._substations.has(id);
        }

        if (isEnabled) {
            for (const [inverterId, hash] of this._perInverterHashes) {
                if (!hash) {
                    continue;
                }
                const substationId = instances.spatialHierarchy.findAmongAscendandsOf(inverterId, isSubstation) || 0;
                const gd = perSubstationGroups.getOrCreate(substationId);
                if (!gd) {
                    continue;
                }
                gd.invertersIds.push(inverterId);
                gd.invertersPropsHashes.push(hash);
            }
        }


        yield Yield.Asap;

        return IterUtils.filterMap(perSubstationGroups.values(), gr => Object.freeze(gr));
    }

    *startGroupResultsCalculation(args: {
        logger: ScopedLogger;
        bim: Bim;
        groupDescription: SubstationGroupDescription;
    }): Generator<Yield, Result<EnergyYieldPropsGroup>, unknown> {

        if (args.groupDescription.invertersIds.length === 0) {
            return new Failure({msg: 'no inverters with energy in substation'});
        }

        const warnings: EnergyFailure[] = [];
        
        const instances = args.bim.instances;

        const pipelineMerger = new EnergyPipelinesMerger();
        const nominalPowerCombined = new Float32Array(365 * 24);

        let dates: TMY_ColumnDates | null = null;

        for (const inverterId of args.groupDescription.invertersIds) {
            const inverterInstance = instances.peekById(inverterId);
            if (!inverterInstance) {
                this.logger.batchedError('unexpected abscence of inverter when calculating group', inverterId);
                continue;
            }
            const energyProps = inverterInstance.propsAs(InverterProps).energy;
            if (!energyProps) {
                warnings.push(EnergyFailure.new('InverterWithNoEnergy'));
                continue;
            }


            pipelineMerger.mergeIn(energyProps.pipeline, 1, new Map());

            const nominal_input_power = energyProps.pipeline.nominal_power.toArray('kW');
            for (let i = 0; i < nominalPowerCombined.length; ++i) {
                nominalPowerCombined[i] += nominal_input_power[i];
            }
            
            yield Yield.Asap;
        }

        const {stages, shaded_modules_area, shading_factors} = pipelineMerger.finish();

        const nominalPowerOutput = CompressibleNumbersArray.newFromValues(
            'MW', nominalPowerCombined.map(v => v / 1_000)
        );

        const pipeline = new EnergyPipelineProperty(
            stages,
            null,
            nominalPowerOutput,
            null,
            args.groupDescription.circuit_dc_power,
            shaded_modules_area,
            shading_factors,
        );

        return energySucess(new EnergyYieldPropsGroup({
            pipeline,
        }), warnings);
    };
    
    applyResultsToBim(args: {
        logger: ScopedLogger; 
        bim: Bim;
        groupDescription: SubstationGroupDescription;
        groupCalcResults: Result<EnergyYieldPropsGroup>;
    }): void {
        const substationId = args.groupDescription.ident.substationId;
        const substationInstance = args.bim.instances.peekById(
            substationId
        );

        if (!substationInstance) {
            this.logger.batchedError(
                'unexpected abscence of substation in bim',
                substationId
            );
            return;
        }

        const props = substationInstance.propsAs(SubstationProps);

        const propsPatch = producePropsPatch(props, (props) => {
            if (args.groupCalcResults instanceof Success) {
                props.energy = args.groupCalcResults.value;
            } else {
                props.energy = null;
            }
        });

        if (propsPatch) {
            args.bim.instances.applyPatches([
                [substationId, {props: propsPatch}]
            ])
        };
    }


    private _checkInverterInputsHash(id: IdBimScene, inverter: SceneInstance) {
        const prevHash = this._perInverterHashes.get(id);
        const inverterProps = inverter.props;
        let perInverterHashes: InverterInputsHash | null;
        if (!(inverterProps instanceof InverterProps)) {
            this.logger.batchedError('unexpected props type on inverter instance', id);
            perInverterHashes = null;
        } else {
            const power_hash = inverterProps.energy?.pipeline?.uniqueValueHash();
            perInverterHashes = (power_hash) ? {power_hash} : null;
        }
        if (ObjectUtils.areObjectsEqual(perInverterHashes, prevHash)) {
            return;
        }
        this._perInverterHashes.set(id, perInverterHashes);
        this.calculationsInvalidator.invalidate();
        return perInverterHashes;
    }
}

