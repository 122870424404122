<svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g clip-path="url(#clip0_802_242)">
        <path d="M24.0002 0H0.000976562V24H24.0002V0Z" fill="#394851" />
        <path
            d="M21.8919 12.6992L20.7831 13.1107C20.8895 12.6351 20.9459 12.1408 20.9459 11.6331C20.9459 11.1559 20.8956 10.6906 20.8014 10.2417C20.1602 7.18646 17.4509 4.89258 14.205 4.89258C10.9591 4.89258 8.25021 7.18685 7.60866 10.2417C7.51444 10.6906 7.4641 11.1559 7.4641 11.6331C7.4641 12.4394 7.60599 13.2126 7.86535 13.9293L5.93954 14.1208L2.1089 12.6992L0 13.3762V15.4893L3.1929 14.3618L5.27853 15.0644L5.48183 15.1327L7.68494 15.0137L7.93935 14.9999L8.29369 14.8752L8.87727 14.67L9.74311 14.3645L10.0074 14.2714L11.6399 11.9558L11.8043 11.7224L11.8592 11.6453L11.886 11.6072L11.9004 11.5862L12 11.4466L12.0995 11.587L12.114 11.6072L12.1407 11.6453L12.1957 11.7224L12.3601 11.9558L13.9926 14.2714L14.2576 14.3653L15.1227 14.67L16.0606 14.9999L16.3151 15.0137L18.5182 15.1327L18.7215 15.0644L20.2906 14.5361L20.8078 14.3618L24 15.4893V13.3762L21.8919 12.6992Z"
            fill="#FF6F21"
        />
        <path
            d="M20.8063 14.5343L24 15.6626V15.3174L20.8082 14.1899L20.3833 14.3333L18.4956 14.9688L16.0923 14.8391L15.1536 14.5084L14.0951 14.1361L12.1335 11.3529L12.0003 11.1641L11.8676 11.3529L9.90518 14.1361L8.84673 14.5084L8.21662 14.7304L7.90804 14.8391L5.50469 14.9688L3.19211 14.1899L0.000732422 15.3174V15.6626L3.1944 14.5343L5.09542 15.1747L3.65097 16.0379L0.000732422 18.22V18.5999L4.46721 15.9292L5.53139 15.2937L7.49916 15.1869L7.07044 15.5832L0.000732422 22.122V22.5652L7.63075 15.5085L8.02628 15.1431L8.37567 15.0199L8.90814 14.8318L9.58173 14.595L8.81507 15.682L2.95067 24.0001H3.3485L9.02562 15.9479L10.1112 14.4081L11.4259 12.5437L9.87123 16.7966L7.23826 24.0001H7.5846L10.1394 17.0105L11.6834 12.7866L11.1284 17.6323L10.4003 24.0001H10.7279L11.4389 17.7822L12.0003 12.8751L12.6076 18.1819L13.2724 24.0001H13.6004L12.9425 18.2555L12.3173 12.7866L14.3583 18.3715L16.4157 24.0001H16.7624L14.6989 18.3554L12.5744 12.5429L13.8892 14.4081H13.8899L16.4214 17.9996L20.6522 24.0001H21.0496L16.7365 17.8813L14.4193 14.595L15.0926 14.8318L15.974 15.1431L16.3696 15.5085L18.1161 17.1234L24 22.5652V22.1228L18.3804 16.9247L16.9303 15.5832L16.5023 15.1869L18.4689 15.2937L19.4438 15.8758L19.5331 15.9292L24 18.5999V18.22L20.3493 16.0379L19.6433 15.6164L18.9049 15.1747L20.1872 14.7426L20.8063 14.5343Z"
            fill="white"
        />
    </g>
    <defs>
        <clipPath id="clip0_802_242">
            <rect width="24" height="24" rx="2" fill="white" />
        </clipPath>
    </defs>
</svg>
