import { Aabb2 } from './Aabb2';
import { KrMath } from './KrMath';
import { Matrix2 } from './Matrix2';
import { PolygonUtils } from './PolygonUtils';
import { Vec2Zero, Vector2 } from './Vector2';


export class Obb2 {

    readonly local_space_center: Vector2;
    readonly rotation_scale: Matrix2;

    constructor(
        local_space_center: Vector2,
        rotation_scale: Matrix2,
    ) {
        this.local_space_center = local_space_center;
        this.rotation_scale = rotation_scale;
    }

    static newAlignedToYByLength(
        aabb: Aabb2,
        rotationInRad: number,
    ) {
        const size = aabb.getSize();
        const center = aabb.getCenter();
        if (size.x > size.y) {
            [size.x, size.y] = [size.y, size.x];
            [center.x, center.y] = [-center.y, center.x];
            rotationInRad -= Math.PI / 2;
        }
        const rotationScale = Matrix2.fromAngle(rotationInRad).scale(size.x, size.y);
        center.x /= size.x;
        center.y /= size.y;
        return new Obb2(center, rotationScale);
    }

    static fromPoints(args: {
        points: Vector2[],
        rotationsRoundToDeg?: number, // = 0.5 deg
        minSegmentLengthToCheck?: number, // = 0.01
    }): Obb2 | null {
        const hull = PolygonUtils.convexHull(args.points);
        if (hull.length < 3) {
            return null;
        }
        
        const roundRotationsToDeg = args.rotationsRoundToDeg ?? 0.5;
        const minSegmentLengthToCheck = args.minSegmentLengthToCheck ?? 0.01;
        
        const rotationsToCheckInDeg = new Set<number>();
        {
            for (let i = 0; i < hull.length; ++i) {
                const p0 = hull.at(i-1)!;
                const p1 = hull.at(i)!;
                if (!(p0.distanceTo(p1) > minSegmentLengthToCheck)) {
                    continue;
                }
                const deltaX = p1.x - p0.x;
                const deltaY = p1.y - p0.y;
                let angle = -Math.atan(deltaX / deltaY);
                angle = KrMath.radToDeg(angle);
                angle = KrMath.roundTo(angle, roundRotationsToDeg);

 
                
                if (angle === -90 || angle === 90) {
                    angle = 0;
                } else if (angle < 0) {
                    angle += 90;
                }

                rotationsToCheckInDeg.add(angle);
            }
        }

        let minArea = Infinity;
        let bestAngle = NaN;
        let bestObb: Obb2 | null = null;
        
        for (const rotationInDeg of rotationsToCheckInDeg) {
            const aabb2 = Aabb2.empty();
            const v2 = new Vector2();
            const rotationInRad = KrMath.degToRad(rotationInDeg);
            for (const p of args.points) {
                v2.copy(p).rotateAround(Vec2Zero, -rotationInRad);
                aabb2.expandByPoint(v2);
            }
            const size = aabb2.getSize();
            const area = size.x * size.y;
            if (area < minArea || (area === minArea && rotationInRad < bestAngle)) {
                minArea = area;
                bestAngle = rotationInRad;
                bestObb = Obb2.newAlignedToYByLength(aabb2, rotationInRad);
            }
        }
        return bestObb;
    }

    width() {
        const me = this.rotation_scale.elements;
        return Math.sqrt(me[0] * me[0] + me[1] * me[1]);
    }

    height() {
        const me = this.rotation_scale.elements;
        return Math.sqrt(me[2] * me[2] + me[3] * me[3]);
    }

    rotationAngle() {
        reusedX.set(this.rotation_scale.elements[0], this.rotation_scale.elements[1]);
        const angle = reusedX.angle();
        if (angle >= Math.PI) {
            return angle - Math.PI;
        }
        return angle;
    }

    rotationAngleDeg(roundTo: number = 0.1) {
        const angleInRad = this.rotationAngle();
        return KrMath.roundTo(KrMath.radToDeg(angleInRad), roundTo);
    }

    size(): Vector2 {
        this.rotation_scale.extractBasis(reusedX, reusedY);
        const size_x = reusedX.length();
        const size_y = reusedY.length();
        return new Vector2(size_x, size_y);
    }

    center(): Vector2 {
        return this.local_space_center.clone().applyMatrix2(this.rotation_scale);
    }

    corners(): [Vector2, Vector2, Vector2, Vector2] {
        const corners = [
            new Vector2(-0.5, -0.5),
            new Vector2(-0.5, 0.5),
            new Vector2(0.5, 0.5),
            new Vector2(0.5, -0.5),
        ];
        for (const c of corners) {
            c.add(this.local_space_center).applyMatrix2(this.rotation_scale);
        }
        return corners as [Vector2, Vector2, Vector2, Vector2];
    }

    area() {
        const size = this.size();
        return size.x * size.y;
    }

    // makeBoxLongestAxisToBeY() {
    //     this.rotation_scale.extractBasis(reusedX, reusedY);
    //     const sizeX = reusedX.length();
    //     const sizeY = reusedY.length();
    //     if (sizeX < sizeY) {
    //         return this;
    //     }

    //     const size = this.size();
    //     const center = this.center();
    //     const rotate90 = Matrix2.fromAngle(KrMath.degToRad(-90));
    //     this.rotation_scale.multiply(rotate90);    
    //     this.rotation_scale.set(
    //         -reusedY.x, reusedX.x, 
    //         -reusedY.y, reusedX.y,
    //     );
    //     const invertedM = this.rotation_scale.clone().invert();
    //     this.local_space_center.copy(center).applyMatrix2(invertedM);

    //     // this.local_space_center.set(
    //     //     -this.local_space_center.y,
    //     //     this.local_space_center.x,
    //     // );

    //     return this;
    // }

}

const reusedX = new Vector2();
const reusedY = new Vector2();
