// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class BimMaterialStdRenderParams implements flatbuffers.IUnpackableObject<BimMaterialStdRenderParamsT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):BimMaterialStdRenderParams {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsBimMaterialStdRenderParams(bb:flatbuffers.ByteBuffer, obj?:BimMaterialStdRenderParams):BimMaterialStdRenderParams {
  return (obj || new BimMaterialStdRenderParams()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsBimMaterialStdRenderParams(bb:flatbuffers.ByteBuffer, obj?:BimMaterialStdRenderParams):BimMaterialStdRenderParams {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new BimMaterialStdRenderParams()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

color():string|null
color(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
color(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

transparency():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

metalness():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

roughness():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

colorImageId():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

normalMapId():number {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

metalnessImageId():number {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

roughnessImageId():number {
  const offset = this.bb!.__offset(this.bb_pos, 18);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

static startBimMaterialStdRenderParams(builder:flatbuffers.Builder) {
  builder.startObject(8);
}

static addColor(builder:flatbuffers.Builder, colorOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, colorOffset, 0);
}

static addTransparency(builder:flatbuffers.Builder, transparency:number) {
  builder.addFieldFloat64(1, transparency, 0.0);
}

static addMetalness(builder:flatbuffers.Builder, metalness:number) {
  builder.addFieldFloat64(2, metalness, 0.0);
}

static addRoughness(builder:flatbuffers.Builder, roughness:number) {
  builder.addFieldFloat64(3, roughness, 0.0);
}

static addColorImageId(builder:flatbuffers.Builder, colorImageId:number) {
  builder.addFieldInt32(4, colorImageId, 0);
}

static addNormalMapId(builder:flatbuffers.Builder, normalMapId:number) {
  builder.addFieldInt32(5, normalMapId, 0);
}

static addMetalnessImageId(builder:flatbuffers.Builder, metalnessImageId:number) {
  builder.addFieldInt32(6, metalnessImageId, 0);
}

static addRoughnessImageId(builder:flatbuffers.Builder, roughnessImageId:number) {
  builder.addFieldInt32(7, roughnessImageId, 0);
}

static endBimMaterialStdRenderParams(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createBimMaterialStdRenderParams(builder:flatbuffers.Builder, colorOffset:flatbuffers.Offset, transparency:number, metalness:number, roughness:number, colorImageId:number, normalMapId:number, metalnessImageId:number, roughnessImageId:number):flatbuffers.Offset {
  BimMaterialStdRenderParams.startBimMaterialStdRenderParams(builder);
  BimMaterialStdRenderParams.addColor(builder, colorOffset);
  BimMaterialStdRenderParams.addTransparency(builder, transparency);
  BimMaterialStdRenderParams.addMetalness(builder, metalness);
  BimMaterialStdRenderParams.addRoughness(builder, roughness);
  BimMaterialStdRenderParams.addColorImageId(builder, colorImageId);
  BimMaterialStdRenderParams.addNormalMapId(builder, normalMapId);
  BimMaterialStdRenderParams.addMetalnessImageId(builder, metalnessImageId);
  BimMaterialStdRenderParams.addRoughnessImageId(builder, roughnessImageId);
  return BimMaterialStdRenderParams.endBimMaterialStdRenderParams(builder);
}

unpack(): BimMaterialStdRenderParamsT {
  return new BimMaterialStdRenderParamsT(
    this.color(),
    this.transparency(),
    this.metalness(),
    this.roughness(),
    this.colorImageId(),
    this.normalMapId(),
    this.metalnessImageId(),
    this.roughnessImageId()
  );
}


unpackTo(_o: BimMaterialStdRenderParamsT): void {
  _o.color = this.color();
  _o.transparency = this.transparency();
  _o.metalness = this.metalness();
  _o.roughness = this.roughness();
  _o.colorImageId = this.colorImageId();
  _o.normalMapId = this.normalMapId();
  _o.metalnessImageId = this.metalnessImageId();
  _o.roughnessImageId = this.roughnessImageId();
}
}

export class BimMaterialStdRenderParamsT implements flatbuffers.IGeneratedObject {
constructor(
  public color: string|Uint8Array|null = null,
  public transparency: number = 0.0,
  public metalness: number = 0.0,
  public roughness: number = 0.0,
  public colorImageId: number = 0,
  public normalMapId: number = 0,
  public metalnessImageId: number = 0,
  public roughnessImageId: number = 0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const color = (this.color !== null ? builder.createString(this.color!) : 0);

  return BimMaterialStdRenderParams.createBimMaterialStdRenderParams(builder,
    color,
    this.transparency,
    this.metalness,
    this.roughness,
    this.colorImageId,
    this.normalMapId,
    this.metalnessImageId,
    this.roughnessImageId
  );
}
}
