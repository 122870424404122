import { IterUtils, LegacyLogger } from 'engine-utils-ts';

import type { BudgetLimitForPass } from './RenderPass';
import type { RenderTimeBudget } from './RenderTimeBudget';
import type { WebglTimer } from './WebglTimer';

const MaxRenderedUnitsLastValuesArrayLength = 10;

export class PassRenderPerformance {

	jsRenderSpeed: number = 500;
	gpuRenderSpeed: number = 4000;
	webglActiveQueries: { query: any; meshesCount: number; }[] | null = null;

	_lastJsRendererdUnitsValues: number[] = [];

	updateJsSpeed(time: number, rendereredUnits: number) {
		// time *= 10;
		const speed = rendereredUnits / time;
		if (speed > 0.001 && speed < Infinity) {
			this.jsRenderSpeed = (this.jsRenderSpeed * 2 + speed) / 3;
		}
		if (rendereredUnits > 0) {
			this._lastJsRendererdUnitsValues.unshift(rendereredUnits);
			this._lastJsRendererdUnitsValues.length = Math.min(
				this._lastJsRendererdUnitsValues.length,
				MaxRenderedUnitsLastValuesArrayLength
			);
		}
	}
	updateWebglSpeed(time: number, rendereredUnits: number) {
		// time *= 10;
		const speed = rendereredUnits / time;
		if (speed > 0.001 && speed < Infinity) {
			this.gpuRenderSpeed = (this.gpuRenderSpeed * 2 + speed) / 3;
		}
	}

	getMinRendererdUnitsOfLastKnown(): number {
		return IterUtils.min(this._lastJsRendererdUnitsValues) ?? 0;
	}

	startNewQuery(wt: WebglTimer, gl: WebGL2RenderingContext): WebGLQuery | null {
		const timerQuery = gl.createQuery();
		gl.beginQuery(wt.TIME_ELAPSED_EXT, timerQuery!);
		return timerQuery;
	}

	markQueryEnd(wt: WebglTimer, gl: WebGL2RenderingContext, query: WebGLQuery, meshesCount: number) {
		gl.endQuery(wt.TIME_ELAPSED_EXT);
		if (!this.webglActiveQueries) {
			this.webglActiveQueries = [];
		}
		this.webglActiveQueries.push({ query, meshesCount });
	}

	readQueriesThatAreDone(gl: WebGL2RenderingContext, wt: WebglTimer | null) {
		if (!this.webglActiveQueries || !wt) {
			return;
		}
		// also check prev queries
		for (let i = 0; i < this.webglActiveQueries.length; ++i) {
			const q = this.webglActiveQueries[i];
			const ready = gl.getQueryParameter(q.query, gl.QUERY_RESULT_AVAILABLE);
			let disjoint = gl.getParameter(wt.GPU_DISJOINT_EXT);
			if (disjoint) {
				LegacyLogger.trace('disjoint query');
			}
			if (ready && !disjoint) {
				const elapsedNanoSeconds = gl.getQueryParameter(q.query, gl.QUERY_RESULT);
				const elapsedMs = elapsedNanoSeconds / 1000000;
				this.updateWebglSpeed(elapsedMs, q.meshesCount);
			}
			if (ready || disjoint) {
				this.webglActiveQueries.splice(i, 1);
				i -= 1;
				gl.deleteQuery(q.query);
			}

		}
	}

	maxJobsToRenderInBudget(timeBudget: RenderTimeBudget): BudgetLimitForPass {
		const jsJobsBudget = timeBudget.jsTimeBudgetLeft() * this.jsRenderSpeed;

		let jobsToRender: number;
		if (this.gpuRenderSpeed) {
			const gpuJobsBudget = timeBudget.gpuTimeBudgetLeft() * this.gpuRenderSpeed;

			jobsToRender = Math.min(gpuJobsBudget, jsJobsBudget);
			// if (jsJobsBudget > gpuJobsBudget) {
			// 	jobsToRender = gpuJobsBudget;
			// } else {
			// 	jobsToRender = KrMath.lerp(jsJobsBudget, gpuJobsBudget, 0.1);

			// }

			// let min = Math.min(jsJobsBudget, gpuJobsBudget);
			// let max = Math.max(jsJobsBudget, gpuJobsBudget);
			// jobsToRender = KrMath.lerp(min, max, 0.02);

			// if (gpuJobsBudget < jsJobsBudget * 1.5) {
			//     jobsToRender = gpuJobsBudget;
			// } else {
			//     let min = Math.min(jsJobsBudget, gpuJobsBudget);
			//     let max = Math.max(jsJobsBudget, gpuJobsBudget);
			//     jobsToRender = KrMath.lerp(min, max, 0.1);
			// }
			// console.log(`BUDGET: ${jobsToRender}, (gpu:${gpuJobsBudget} js:${jsJobsBudget})`);
			// if (gpuJobsBudget < jsJobsBudget) {
			//     console.log("GPU TIME BUDGET");
			// }
		} else {
			jobsToRender = jsJobsBudget;
		}
		return { unitsToRender: jobsToRender };
	}

	freeGLQueriesOnContextLoss(gl: WebGL2RenderingContext) {
		if(this.webglActiveQueries) {
			for(const q of this.webglActiveQueries!) {
				gl.deleteQuery(q.query);
			}
	
			this.webglActiveQueries!.splice(0);
		}
	}
}
