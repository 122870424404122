import { ObservableObject, Yield } from "engine-utils-ts";
import type { UiBindings, ObservableConfigObjectUiBuilderParams } from "ui-bindings";
import { buildFromObservableConfigObject, DialogDescription, CustomUiBuilder } from "ui-bindings";

interface RequestSettingsArgs<Settings extends Object> {
    header: string,
    ident: string,
    defaultValue: Settings,
    uiBuilderParams?: ObservableConfigObjectUiBuilderParams | CustomUiBuilder<Settings>,
    submitActionLabel?: string,
    cancel: () => void,
    uiBindings: UiBindings,
}

export function *requestSettingsViaDialog<Settings extends Object> (
    args: RequestSettingsArgs<Settings>,
    getDialog = getDefaultDialog<Settings>,
): Generator<Yield, Settings> {
    const settingsObservable = new ObservableObject({
        identifier: args.ident,
        initialState: args.defaultValue,
    });

    let done = false;

    const dialogDescription = getDialog(
        settingsObservable,
        args,
        {
            label: args.submitActionLabel ?? "Ok" , 
            action: () => { 
                done = true; 
            } 
        },
        () => {
            done = true;
            args.cancel();
        }
    );

    args.uiBindings.dialogsStream.pushNext(dialogDescription);

    // now, if ui is listening, it will prevent default action, otherwise do default
    if (dialogDescription.canExecuteDefault()) {
        dialogDescription.executeDefault();
    }

    while (!done) {
        yield Yield.NextFrame;
    }
    yield Yield.NextFrame; // allow ui to update, and hide dialog

    return settingsObservable.poll();
}

function getDefaultDialog<T extends Object>(
    settingsObs: ObservableObject<T>,
    params: {
        header: string,
        uiBuilderParams?: ObservableConfigObjectUiBuilderParams | CustomUiBuilder<T>,
    },
    submitAction: {label: string, action: () => void},
    _cancelAction: () => void,
) {
    const uiConfig = params?.uiBuilderParams instanceof CustomUiBuilder 
        ? params.uiBuilderParams.buildUi(settingsObs, submitAction.action) 
        : buildFromObservableConfigObject({
            configObj: settingsObs,
            configBuilderParams: params.uiBuilderParams?.configBuilderParams,
            puiBuilderParams: params.uiBuilderParams?.puiBuilderParams,
            defaultMessage: params.uiBuilderParams?.defaultMessage,
        });
    return new DialogDescription({
        name: params.header,
        uiSource: uiConfig,
        context: undefined,
        userActions: [
            {
                name: submitAction.label,
                action: submitAction.action,
            },
        ],
        defaultAction: submitAction.action,
    });
}