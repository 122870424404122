import { Vector3 } from "math-ts";
import { BimSceneOrigin, WgsProjectionOrigin } from "../../scene/BimSceneOrigin";
import { WGSCoord } from "../../scene/WGSCoord";
import { ProjectionInfo } from "../../scene/ProjectionInfo";

export interface AssetTransform {
    position: [number, number, number],
    scale?: [number, number, number] | number,
    rotation_euler?: [number, number, number],
}

export interface AssetRef {
    path: string;
    in_asset_id?: string;
}

export interface AssetImage {
    id?: number | string;
    ref: AssetRef;
}

export interface AssetPolyline {
    points: [number, number, number][];
    radius?: number,
}

export interface AssetCube {
    size: [number, number, number] | number,
    center?: [number, number, number]
}

export interface AssetExtrudedPolygon {
    points_2d: number[],
    holes?: number[][],
    base_elevation: number,
    top_elevation: number,
}

export interface AssetGraph {
    points: [number, number, number][],
    pointsIds?: number[],
    edges: [number, number][],
}

export interface AssetGeometry {
    id?: number | string;
    tri_geo_ref?: AssetRef;
    polyline?: AssetPolyline,
    cube?: AssetCube,
    extruded_polygon?: AssetExtrudedPolygon,
    graph?: AssetGraph,
}

export interface AssetMaterial {
    id?: number | string;
    name: string;
    render_params?: {
        color?: string,
        transparency?: number; // 0 to 1
        metalness?: number; // 0 to 1
        roughness?: number; // 0 to 1
    }
}

export interface AssetStdMeshRepresentation {
    geometry?: AssetGeometry;
    geometry_id?: number | string;

    material?: AssetMaterial;
    material_id?: number | string;

    transform?: AssetTransform;
}

export interface AssetSceneImageRepresentation {
    image_id?: number | string;
    image?: AssetImage;
    world_size: [number, number];
}

// export interface AssetHeightmapRepresentation {
//     heightmap_geo_id: number | string;
//     heightmap_ref: AssetRef;
//     heightmap_image_id: number | string;
//     heightmap_image_ref: AssetRef;
// }


export interface AssetRulerRepresentation {
    points: [number, number, number][];
}

export interface AssetBasicAnalyticalRepresentation {
    geometry?: AssetGeometry;
    geometry_id?: number | string;
}

export interface AssetAnalyticalRepresentation {
    basic?: AssetBasicAnalyticalRepresentation,
}

export interface AssetObjectRepresentation {
    std?: AssetStdMeshRepresentation | AssetStdMeshRepresentation[],// | AssetRef,
    image?: AssetSceneImageRepresentation,
    // heightmap?: AssetHeightmapRepresentation,
    // ruler?: AssetRulerRepresentation,
}

export interface AssetProperty {
    name: string,
    description?: string | null;
    value: number | string | boolean,
    unit?: string,
    discrete_variants?: (number | string)[] | null
    numeric_step?: number;
    numeric_range?: [number, number];
    readonly?: boolean;
}


export type AssetPropertiesGroup = {
    [name: string]:
    AssetProperty[] |
    AssetPropertiesGroup |
    Omit<AssetProperty, 'name'>;
}

export interface AssetSceneInstance {
    id?: string | number;
    type_identifier?: string;
    name?: string;

    local_transform?: AssetTransform;
    properties?: AssetPropertiesGroup;

    representation?: AssetObjectRepresentation,

    representationAnalytical?: AssetAnalyticalRepresentation,

    template_id?: number | string;

    spatial_parent_id?: number | string;
    electrical_parent_id?: number | string;

}

export interface AssetProjectionInfo {
    method: string;
    parameters: [string, string][];
}

export interface AssetOrigin {
    civilOrigin?: [number, number, number];
    wgsCoordinates?: WGSCoord;
    projectionInfo?: AssetProjectionInfo;
    wgsOrigin?: [number, number, number];
}

export interface BimAssetDescription {
    // kreo_asset: string;
    images?: AssetImage[],
    materials?: AssetMaterial[],
    geometries?: AssetGeometry[],
    origin?: AssetOrigin,
    instances: AssetSceneInstance[],
    name: string,
    formatVersion: number;
    versionPerTypeIdentifier?: [string, number][];
}

export enum BimAssetFormatVersions {
    None,
    RenameTypeIdentifier,
    AddMaxSlopeForTracker,
    AddMinEmbedmentToTrackerAndFixedTilt,
    AddFXMRAndSkid,
    AddAssetSelectToMinWiringOnTransformers,
    RemoveFXMRAndSkid,
}

export function parseAssetOrigin(assetOrigin: AssetOrigin | undefined): Partial <BimSceneOrigin> | null {
    if (!assetOrigin) {
        return null;
    }

    let civilOrig: Vector3 | undefined = undefined;

    if (assetOrigin.civilOrigin) {
        civilOrig = new Vector3(...assetOrigin.civilOrigin)
    }

    let wgsOrig: WgsProjectionOrigin | null = null;

    if (assetOrigin?.wgsCoordinates!=null) {
        const coords = assetOrigin.wgsCoordinates;
        const wgsCoord = WGSCoord.new(coords.latitude, coords.longitude, coords.altitude)

        if (wgsCoord != null) {
            let proj = new ProjectionInfo();
            if (assetOrigin.projectionInfo !== undefined) {
                proj = new ProjectionInfo(
                    assetOrigin.projectionInfo.method,
                    new Map(assetOrigin.projectionInfo.parameters)
                )
            }

            if (assetOrigin.wgsOrigin!=null){
                const wgsOffset = new Vector3(...assetOrigin.wgsOrigin);
                wgsOrig = new WgsProjectionOrigin(wgsCoord, proj, wgsOffset);
            } else {
                wgsOrig = new WgsProjectionOrigin(wgsCoord, proj)
            }
        }
    }

    return new BimSceneOrigin(civilOrig, wgsOrig);
}

