import { CostModel as CM } from 'bim-ts';
import { ColDef } from "ag-grid-enterprise";
import { commonCellClasses, createCostValueFormatter, getContext } from './common';


export const createTotalCostColumnDefinition = (): ColDef<CM.FlattenedCostCategoryParams> => ({
    colId: CM.TotalCostColId,
    headerName: CM.TotalCostColId,
    valueGetter: (params) => {
        const { category } = getContext(params);
        if (!category) {
            return;
        }
        const totalCost = CM.findCostCategoryTotalCost(category);
        return totalCost;

    },
    valueFormatter: createCostValueFormatter({ doNotShowZeroCosts: true, round: true }),
    headerClass: [
        'ag-grid-header-align-right',
        'ag-grid-cell-with-left-separator',
    ],
    cellClass: (params) => {
        const classes = new Set(commonCellClasses(getContext(params), () => undefined));
        classes.add('ag-grid-cell-with-left-separator')
        classes.add('ag-grid-cell-bold')
        return Array.from(classes);
    }
})
