<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        d="M13.5 8L10 6L6.5 8M13.5 8L17 10L13.5 12M13.5 8L6.5 12M6.5 12L3 10L6.5 8M6.5 12L10 14L13.5 12M6.5 8L13.5 12"
        stroke="currentColor"
        stroke-width="0.8"
        stroke-linejoin="round"
    />
    <path d="M17 14V6" stroke="currentColor" stroke-width="1.6" />
    <path d="M3 14V6" stroke="currentColor" stroke-width="1.6" />
    <path d="M10 6V2" stroke="currentColor" stroke-width="1.6" />
    <path d="M10 18V14" stroke="currentColor" stroke-width="1.6" />
</svg>
