import type { LazyVersioned, TasksRunner, UndoStack} from 'engine-utils-ts';
import {
    Allocated,
    DefaultMap, isInNode, IterUtils, LazyDerived, ScopedLogger, Updated, Yield,
} from 'engine-utils-ts';
import type { EntityIdAny, VerDataSyncer } from 'verdata-ts';

import { registerBoundary } from './archetypes/Boundary';
import { registerRoad } from './archetypes/Road';
import { registerTrench } from './archetypes/Trench';
import { BimImages } from './BimImages';
import { BimMaterials } from './BimMaterials';
import { createPersistedBimCollections } from './BimPersistance';
import { createBimUiBindings } from './BimUiBindings';
import type { BimCollection } from './collections/BimCollection';
import { BimGeometries } from './geometries/BimGeometries';
import { CubeGeometries } from './geometries/CubeGeometries';
import {
	ExtrudedPolygonGeometries,
} from './geometries/ExtrudedPolygonGeometries';
import { GraphGeometries } from './geometries/GraphGeometries';
import {
	IrregularHeightmapGeometries,
} from './geometries/IrregularHeightmapGeometries';
import { PolylineGeometries } from './geometries/PolylineGeometries';
import {
	RegularHeightmapGeometries,
} from './geometries/RegularHeightmapGeometry';
import { TriGeometries } from './geometries/TriGeometries';
import { BimReactiveRuntime } from './runtime/BimReactiveRuntime';
import type {
	Boundary2DDescription} from './runtime/solar/BimBoundaries';
import { extractBoundariesFromBim,
} from './runtime/solar/BimBoundaries';
import {
	createLowVoltagePropsGroupShaper,
} from './runtime/solar/LowVoltagePropsGroupShaper';
import {
	sectionalizedCabinetMeshGenerator,
} from './runtime/solar/SectionalizedCabinetMeshGenerator';
import {
	solarCombinerBoxMeshGenerator,
} from './runtime/solar/SolarCombinerBoxMeshGenerator';
import {
	solarInverterMeshGenerator,
} from './runtime/solar/SolarInverterMeshGenerator';
import { addSolarPrimaryLabels } from './runtime/solar/SolarPrimaryLabels';
import {
	allInstancesAverageLossesCalculator, combinerBoxesCircuitCalculator,
	fixedTrackersEnergyCalculator, groupLvWiring, scCircuitCalculator,
	solarInverterInObjectPropsCalculator, solarInvertersCircuitCalculator,
	solarSubstationsCircuitCalculator, solarTransformersCircuitCalculator,
	trackersPropsCalculator, wiresCircuitCalculator, wiresPropsCalculator,
} from './runtime/solar/SolarPropsSolvers';

import {
	solarTransformerMeshGenerator,
} from './runtime/solar/SolarTransformerMeshGenerator';
import {
	substationMeshGenerator,
} from './runtime/solar/SubstationMeshGenerator';
import { SceneInstances, type IdBimScene, type SceneInstance, type SceneInstancePatch } from './scene/SceneInstances';
import { UnitsMapper } from './UnitsMapper';
import { ConfigsCollection } from './bimConfigs/ConfigsCollection';
import { registerTerrainObject } from './terrain/Terrain';
import { registerConfigArchetypes } from './bimConfigs/configTypes/registerConfigArchetypes';
import { registerFixedTiltTracker } from './archetypes/fixed-tilt/FixedTilt';
import { registerTracker } from './trackers/Tracker';
import { createWireSpecPropsShaper } from './runtime/solar/WireSpecPropsShaper';
import { registerCombinerBox } from './archetypes/CombinerBox';
import type { SystemOfUnitsConfig} from './bimConfigs/configTypes/SystemOfUnitsConfigType';
import { SystemOfUnitsConfigTypeIdent } from './bimConfigs/configTypes/SystemOfUnitsConfigType';
import type { UiBindings } from 'ui-bindings';
import { createKeyPropertiesGroupFormatters } from './runtime/solar/KeyPropertiesGroupFormatters';
import type { PropertiesGroupFormatters } from './bimDescriptions/PropertiesGroupFormatter';
import { registerSectionalizingCabinet } from './archetypes/SectionalizingCabinet';
import { registerSubstation } from './archetypes/substation/Substation';
import { registerTransformer } from './archetypes/transformer/Transformer';
import { registerPVModuleArchetype } from './archetypes/pv-module/PVModule';
import { registerTMY_Config } from './meteo/TMY_Config';
import { registerTrackerFrame } from './archetypes/TrackerFrame';
import { registerInverterArchetype } from './archetypes/Inverter/Inverter';
import { VirutalPropsRuntime } from "./runtime/VirutalPropsRuntime";
import { RuntimeGlobals } from './runtime/RuntimeGlobals';
import { registerLvWire } from './archetypes/LvWire';
import { registerMvWire } from './archetypes/MvWire';
import { registerPolyline } from './archetypes/Kml';
import type { SolversExecutionMetrics } from './runtime/DurationTimer';
import { BimUtils } from './BimUtils';
import { BimCustomRuntimes } from './runtime/BimCustomRuntimes';
import { EntitiesBase } from './collections/EntitiesBase';
import { PolyEntitiesBase } from './collections/PolyEntitiesBase';
import { Transform, Vector3 } from 'math-ts';
import { registerAnyTracker } from './anyTracker/AnyTrackerRuntimes';
import { AnyTrackerProps, newAnyTrackerInstance } from './anyTracker/AnyTracker';
import { convertLegacyTrackerPropsIntoAnyTrackerProps } from './anyTracker/ToAnyTrackerMigration';
import { SceneInstanceSerializable } from './persistence/SceneInstancesSerializer';
import { producePropsPatch } from './properties/Props';
import { SmallNumericArrayProperty } from './properties/SmallNumberArrayProperty';
import type { MathSolversApi } from './mathSolversApi/MathSolversApi';
import { registerBlockNumberSolver } from './runtime/blockNumber/BlockNumberSolver';
import { registerShadingRuntime } from './trackers/shading/ShadingRunnerSolver';
import { registerTrackersRowsRuntime } from './trackers/rows/TrackersRowsRunnerSolver';
import { registerEnergyRuntimes } from './energy/EnergyRuntimes';
import { registerPricingRuntime } from './catalog/pricing-runtime/registerPricingRuntime';
import type { CostsConfigProvider } from './cost-model/capital';


export class Bim {

	readonly logger: ScopedLogger;

    readonly undoStack?: UndoStack;

    readonly instances: SceneInstances;

    readonly allBimGeometries: BimGeometries;
    readonly triGeometries: TriGeometries;
    readonly cubeGeometries: CubeGeometries;
    readonly extrudedPolygonGeometries: ExtrudedPolygonGeometries;
    readonly polylineGeometries: PolylineGeometries;
    readonly regularHeightmapGeometries: RegularHeightmapGeometries;
    readonly irregularHeightmapGeometries: IrregularHeightmapGeometries;
    readonly graphGeometries: GraphGeometries;

    readonly configs: ConfigsCollection;

    readonly bimMaterials: BimMaterials;
    readonly bimImages: BimImages;

    readonly unitsMapper: UnitsMapper;

    readonly keyPropertiesGroupFormatter: PropertiesGroupFormatters;

    readonly runtimeGlobals: RuntimeGlobals;
    readonly reactiveRuntimes: BimReactiveRuntime;
    readonly customRuntimes: BimCustomRuntimes;
    readonly virtualPropsRuntime: VirutalPropsRuntime;

    private _isDisposed: boolean = false;
    private _loopFn: (time: number) => void;
    private _globalsPollingRoutine: Generator<Yield, any> | null = null;

    private readonly _runtimesCompletionStatus: LazyVersioned<boolean>;

    private _idleFramesCount: number = 0; // used to detect when to run GC
    private _prevRuntimesVersion: number = -1;

    constructor( args: {
        undoStack?: UndoStack,
        unitsMapper?: UnitsMapper,
    }) {

		this.logger = new ScopedLogger('bim');
        this.undoStack = args.undoStack;
        this.unitsMapper = args.unitsMapper ?? new UnitsMapper();


        this.bimImages = new BimImages(args.undoStack);
        this.bimMaterials = new BimMaterials(this.bimImages, args.undoStack);

        this.triGeometries = new TriGeometries(args.undoStack);
        this.polylineGeometries = new PolylineGeometries(args.undoStack);
        this.cubeGeometries = new CubeGeometries(args.undoStack);
        this.extrudedPolygonGeometries = new ExtrudedPolygonGeometries(args.undoStack);
        this.regularHeightmapGeometries = new RegularHeightmapGeometries(args.undoStack);
        this.irregularHeightmapGeometries = new IrregularHeightmapGeometries(args.undoStack);
        this.graphGeometries = new GraphGeometries(args.undoStack);
        this.allBimGeometries = new BimGeometries(this.logger, [
			this.triGeometries,
			this.polylineGeometries,
			this.cubeGeometries,
			this.extrudedPolygonGeometries,
			this.regularHeightmapGeometries,
			this.irregularHeightmapGeometries,
            this.graphGeometries,
        ]);

        this.instances = new SceneInstances(this.allBimGeometries, this.bimMaterials, this.bimImages, this.unitsMapper, args.undoStack);

        this.configs = new ConfigsCollection(this.instances, args.undoStack);

        this.runtimeGlobals = new RuntimeGlobals(this.logger);
        this.reactiveRuntimes = new BimReactiveRuntime(this);
        this.customRuntimes = new BimCustomRuntimes(this);

        this.virtualPropsRuntime = new VirutalPropsRuntime(this);


        this._loopFn = () => {
            if (this._isDisposed) {
                return;
            }
            setTimeout(this._loopFn, 25);
            this._runUpdates(20);
        }
        if(!isInNode()){
            setTimeout(this._loopFn);
        }

        registerConfigArchetypes(this);

        registerAnyTracker(this);

        registerTrackerFrame(this);
        registerTracker(this);
        registerFixedTiltTracker(this);

        this.reactiveRuntimes.registerRuntimeSolver(solarCombinerBoxMeshGenerator(this));
        this.reactiveRuntimes.registerRuntimeSolver(sectionalizedCabinetMeshGenerator(this));
        this.reactiveRuntimes.registerRuntimeSolver(solarInverterInObjectPropsCalculator());
        this.reactiveRuntimes.registerRuntimeSolver(solarInverterMeshGenerator(this));
        this.reactiveRuntimes.registerRuntimeSolver(solarTransformerMeshGenerator(this));
        this.reactiveRuntimes.registerRuntimeSolver(substationMeshGenerator(this));

        registerInverterArchetype(this);

        this.reactiveRuntimes.registerRuntimeSolver(wiresPropsCalculator(this));

        this.reactiveRuntimes.registerRuntimeSolver(trackersPropsCalculator());
        this.reactiveRuntimes.registerRuntimeSolver(fixedTrackersEnergyCalculator());
        this.reactiveRuntimes.registerRuntimeSolver(scCircuitCalculator());
        this.reactiveRuntimes.registerRuntimeSolver(combinerBoxesCircuitCalculator());
        this.reactiveRuntimes.registerRuntimeSolver(solarInvertersCircuitCalculator());
        this.reactiveRuntimes.registerRuntimeSolver(solarTransformersCircuitCalculator());
        this.reactiveRuntimes.registerRuntimeSolver(wiresCircuitCalculator());
        this.reactiveRuntimes.registerRuntimeSolver(solarSubstationsCircuitCalculator());
        this.reactiveRuntimes.registerRuntimeSolver(allInstancesAverageLossesCalculator());
        this.reactiveRuntimes.registerRuntimeSolver(groupLvWiring());

        createLowVoltagePropsGroupShaper(this);
        createWireSpecPropsShaper(this);

        addSolarPrimaryLabels(this);

		registerBoundary(this);
        registerRoad(this);
		registerTrench(this);
		registerTerrainObject(this);
        registerCombinerBox(this);
        registerTransformer(this);
        registerSectionalizingCabinet(this);
        registerSubstation(this);
        registerPVModuleArchetype(this);
        registerLvWire(this);
        registerMvWire(this);
        registerPolyline(this);

        registerTMY_Config(this);

        this.syncUnitsMapperWithConfig();

        this.keyPropertiesGroupFormatter = createKeyPropertiesGroupFormatters(this.unitsMapper);

        this._runtimesCompletionStatus = LazyDerived.new0(
            "runtimes-status",
            [this.reactiveRuntimes, this.customRuntimes, this.runtimeGlobals],
            () => {
                const hasPendingUpdates = this.reactiveRuntimes._hasPendingUpdates()
                    || this.customRuntimes._hasPendingUpdates()
                    || this.runtimeGlobals._hasPendingUpdates();
                return !hasPendingUpdates;
        });
    }

    dispose() {
        this._isDisposed = true;
        this.reactiveRuntimes.dispose();
        this.customRuntimes.dispose();
        for (const c of this.allEntitiesCollections({sortRootFirst: true})) {
            c.collection.dispose();
        }
    }

    allEntitiesCollections(params: {sortRootFirst: boolean}): {
        collection: EntitiesBase<any, any>;
        referencedBy: EntitiesBase<any, any>[];
     }[] {
        const allCollectionsReferencedBy = new DefaultMap<EntitiesBase<any, any>, Set<EntitiesBase<any, any>>>(
            () => new Set()
        );
        for (const key in this) {
            const value = this[key];
            if (value instanceof EntitiesBase) {
                allCollectionsReferencedBy.getOrCreate(value);
                for (const c of value.allBaseCollectionsReferencedFirstHand()) {
                    allCollectionsReferencedBy.getOrCreate(c).add(value);
                }
            } else if (value instanceof PolyEntitiesBase) {
                for (const c of value.entitiesByType.values()) {
                    allCollectionsReferencedBy.getOrCreate(c);
                    for (const cc of c.allBaseCollectionsReferencedFirstHand()) {
                        allCollectionsReferencedBy.getOrCreate(cc).add(c);
                    }
                }
            }
        }
        const result: {
            collection: EntitiesBase<any, any>;
            referencedBy: EntitiesBase<any, any>[];
        }[] = [];

        if (!params.sortRootFirst) {
            for (const [c, referencedBy] of allCollectionsReferencedBy) {
                result.push({
                    collection: c,
                    referencedBy: Array.from(referencedBy),
                });
            }
        } else {
            // add in order, from root collections to the rest
            while (allCollectionsReferencedBy.size > 0) {
                let collectionToAddNext = IterUtils.find(allCollectionsReferencedBy, ([c, referencedBy]) => {
                    for (const rc of referencedBy) {
                        if (!result.find(({collection}) => rc === collection)) {
                            // if collection we are refernced by is not already added
                            // retry to add this collection later
                            return false;
                        }
                    }
                    return true;
                });
                if (!collectionToAddNext) {
                    console.error('BIM could not order collections in proper order of interdependencies', Array.from(allCollectionsReferencedBy));
                    collectionToAddNext = IterUtils.getFirstFromIter(allCollectionsReferencedBy)!;
                }
                allCollectionsReferencedBy.delete(collectionToAddNext[0]);
                result.push({
                    collection: collectionToAddNext[0],
                    referencedBy: Array.from(collectionToAddNext[1]),
                });
            }
        }
        return result;
    }

    private _hasPendingUpdates() {
        return this.reactiveRuntimes._hasPendingUpdates()
            || this.customRuntimes._hasPendingUpdates()
            || this.runtimeGlobals._hasPendingUpdates()
    }

    *runUpdatesTillCompletion(args: {forceRun: boolean}): Generator<Yield, void> {
        let count = 1;
        while (this._hasPendingUpdates()) {
            if (isInNode() || args.forceRun) {
                this._runUpdates(50);
                yield Yield.Asap;
            } else {
                yield Yield.NextFrame;
            }
            if ((count % 1_000) === 0) {
                this.logger.warn('waiting on updates for too long', count);
            }
        }
    }

    *runBasicUpdatesTillCompletion(args: {forceRun: boolean}): Generator<Yield, void> {
        let count = 1;
        while (this.reactiveRuntimes._hasPendingUpdates() || this.runtimeGlobals._hasPendingUpdates()) {
            if (isInNode() || args.forceRun) {
                this._runUpdates(50);
                yield Yield.Asap;
            } else {
                yield Yield.NextFrame;
            }
            if ((count % 1_000) === 0) {
                this.logger.warn('waiting on updates for too long', count);
            }
        }
    }

    _runUpdates(timeLimit: number) {

        if (this.instances.isLocked() || this.configs.isLocked()) {
            return;
        }

        const startTime = performance.now();
        const timeEndLimitReactive = startTime + timeLimit * 1.5;
        const timeEndLimitCustomRuntimes = startTime + timeLimit * 0.5;

        this.reactiveRuntimes.runUpdates(timeEndLimitReactive);

        if (performance.now() < timeEndLimitCustomRuntimes - timeLimit * 0.25) {
            this.customRuntimes.runUpdate(timeEndLimitCustomRuntimes);
        }

        // if we didnt run much solvers, try refreshing globals
        const updatesDuration = performance.now() - startTime;
        if (updatesDuration < 3) {
            const timeLimit = Math.min(performance.now() + 3, timeEndLimitCustomRuntimes); // run for 3ms max, dont waste time on this
            // runtime solvers poll globals by themslelves when they need them
            // this is required only for invaliding globals separately

            if (!this._globalsPollingRoutine) {
                this._globalsPollingRoutine = this.runtimeGlobals.acquireByIdents(
                    Array.from([...this.runtimeGlobals.allRegisteredIdents()])
                );
            }

            while (performance.now() < timeLimit) {
                const {value, done} = this._globalsPollingRoutine.next();
                if (done) {
                    this._globalsPollingRoutine = null;
                    this.logger.debug('polling globals done');
                    break;
                }
                if (value === Yield.NextFrame) {
                    break;
                }
            }
        }

        const runtimesVersion = this._runtimesCompletionStatus.version(); // use runtimes status version as proxy for nothing happening
        if (runtimesVersion === this._prevRuntimesVersion) {
            this._idleFramesCount += 1;
        } else {
            this._idleFramesCount = 0;
        }
        this._prevRuntimesVersion = runtimesVersion;

        if (this._idleFramesCount === 100) {
            this.runGC();
        }
    }

    getRuntimesCompletionStatusLazy(): LazyVersioned<boolean> {
        return this._runtimesCompletionStatus;
    }

    // *_pollAllGlobalsRoutine() {
    //     const idents = new Set<string>();
    //     for (const solver of this.reactiveRuntimes._solversRunners) {
    //         if (solver.globalArgsSelector) {
    //             for (const key in solver.globalArgsSelector) {
    //                 idents.add(key);
    //             }
    //         }
    //     }
    //     for (const runtime of this.customRuntimes.runtimes) {
    //         if (runtime.globalArgsSelector) {
    //             for (const key in runtime.globalArgsSelector) {
    //                 idents.add(key);
    //             }
    //         }
    //     }
    //     yield Yield.Asap;
    //     yield* this.runtimeSharedDependencies.acquireByIdents(Array.from(idents));
    // }

    runGC() {
        const startT = performance.now();
        const referencesPerCollection = new DefaultMap<BimCollection<any, any>, Set<EntityIdAny>>(
            () => new Set(),
        );

        for (const {collection, referencedBy} of this.allEntitiesCollections({sortRootFirst: true})) {

            if (referencedBy.length > 0) {
                // gc non root collections
                const idsUsedExternally = referencesPerCollection.get(collection);
                if (idsUsedExternally === undefined) {
                    console.error(`could not find external ids for collection`, collection, referencedBy);
                } else {
                    const removed = collection.deleteAllExcept(idsUsedExternally, {identifier: "GC"});
                }
            }

            for (const externalRef of collection.collectAllExternalBaseReferences()) {
                const idsReferenced = referencesPerCollection.getOrCreate(externalRef.collection);
                IterUtils.extendSet(idsReferenced, externalRef.idsReferenced);
            }
        }
        const duration = performance.now() - startT;
        if (duration > 10) {
            console.log('bim gc duration ms ', duration);
        }
    }

	async connectToVerdata(syncer: VerDataSyncer) {
        const collectionsToSync = createPersistedBimCollections(this);
		await syncer.attachCollections(collectionsToSync);
		syncer.addListener('beforeSync', () => this.runGC());
	}

    createUiBindings(tasksRunner: TasksRunner): UiBindings {
        return createBimUiBindings(this, tasksRunner);
    }

    extractBoundaries(): Boundary2DDescription[] {
        return extractBoundariesFromBim(this);
    }

	generateShorDescriptionsForUi(): string[] {
		const instancesShortDescription = this.instances.generateShorDescriptionsForUi();
		return instancesShortDescription;
	}

    syncUnitsMapperWithConfig() {
        this.configs.updatesStream.subscribe({
            settings: {immediateMode: true},
            onNext: (update) => {
                if (update instanceof Allocated || update instanceof Updated) {
                } else {
                    return;
                }
                for (const [_id, config] of this.configs.peekByIds(update.ids)) {
                    if (config.type_identifier !== SystemOfUnitsConfigTypeIdent) {
                        continue;
                    }
                    const props = config.get<SystemOfUnitsConfig>()
                    this.unitsMapper.setCurrentSystemOfUnits(props.systemOfUnits.value)
                    this.unitsMapper.converter.setUnitGroupsOverride([
                        [{ price: 1 }, { unitNames: [props.currency.value] }]
                    ])
                }
            }
        })
    }

    clear() {
        this.bimImages.clear();
		this.bimMaterials.clear();
		this.instances.clear();
    }

    getExecutionTimes(): SolversExecutionMetrics[] {
        const res = [
            ...this.reactiveRuntimes.getExecutionTimes(),
            ...this.customRuntimes.getExecutionTimes(),
        ];
        res.sort((l, r) => r.time - l.time);

        const sum = res.reduce((acc, {time}) => acc + time, 0);
        res.unshift({ident: 'TOTAL', time: sum, count: 0});

        return res;
    }

    _props(type: string = 'tracker') {
        return BimUtils.generatePropsDescriptionFromKnownProps(this.instances, type);
    }

    addTestTrackers() {
        const toAlloc: [IdBimScene, Partial<SceneInstance>][] = [];
        for (let i = 0; i < 3; ++i) {
            const tracker = newAnyTrackerInstance();
            tracker.localTransform = new Transform(
                new Vector3(i * 10, 0, 0),
            );
            toAlloc.push([this.instances.idsProvider.reserveNewId(), tracker]);
        }
        const ids = this.instances.allocate(toAlloc);
        this.instances.setSelected(ids);
    }

    convertToAnyTrackers(selected: IdBimScene[] = []) {
        let idsToConvert: IdBimScene[];
        if (selected.length === 0) {
            idsToConvert = this.instances.peekByTypeIdent('tracker').map(([id]) => id); 
        } else {
            idsToConvert = selected.filter(id => this.instances.peekTypeIdentOf(id) === 'tracker');
        }

        const patchToClearParentsOfChildren: [IdBimScene, SceneInstancePatch][] = [];
        const toDelete: IdBimScene[] = [];
        const toAlloc: [IdBimScene, Partial<SceneInstance>][] = [];
        const patchToRemountChildren: [IdBimScene, SceneInstancePatch][] = [];

        for (const [id, tracker] of this.instances.peekByIds(idsToConvert)) {

            const newTracker = convertLegacyTrackerPropsIntoAnyTrackerProps(
                SceneInstanceSerializable.fromFull(tracker)
            );
            const newTrackerId = this.instances.idsProvider.reserveNewId();
            toAlloc.push([newTrackerId, newTracker]);

            const childrenToRemount = Array.from(this.instances.spatialHierarchy.iteratorOfChildrenOf(id));
            for (const childId of childrenToRemount) {
                patchToClearParentsOfChildren.push([childId, {spatialParentId: 0}]);
                patchToRemountChildren.push([childId, {spatialParentId: newTrackerId}]);
            }

            toDelete.push(id);
        }

        this.instances.applyPatches(patchToClearParentsOfChildren);
        this.instances.delete(toDelete);
        this.instances.allocate(toAlloc);
        this.instances.applyPatches(patchToRemountChildren);

        if (selected.length > 0) {
            this.instances.setSelected(toAlloc.map(([id]) => id));
        }
    }

    setMockEmbedments() {

        const selected = this.instances.getSelected();
        let idsToConvert: IdBimScene[];
        if (selected.length === 0) {
            idsToConvert = this.instances.peekByTypeIdent('any-tracker').map(([id]) => id); 
        } else {
            idsToConvert = selected.filter(id => this.instances.peekTypeIdentOf(id) === 'any-tracker');
        }

        let patches: [IdBimScene, SceneInstancePatch][] = [];

        for (const [id, inst] of this.instances.peekByIds(idsToConvert)) {
            if (!(inst.props instanceof AnyTrackerProps)) {
                continue;
            }
            const lengths = inst.props.piles.lengths;
            if (lengths && lengths.count > 0) {
                const multiplier = Math.random();
                const embedments = lengths.values.map((l) => l * multiplier);
                const propsPatch = producePropsPatch(inst.props, (props) => {
                    props.piles._pile_tops_distance_to_ground = new SmallNumericArrayProperty({values: embedments, unit: 'm'});
                })
                if (propsPatch) {
                    patches.push([id, {props: propsPatch}]);
                }
            }
        }

        this.instances.applyPatches(patches);
    }

    setMockLengths() {
        const selected = this.instances.getSelected();
        let idsToConvert: IdBimScene[];
        if (selected.length === 0) {
            idsToConvert = this.instances.peekByTypeIdent('any-tracker').map(([id]) => id); 
        } else {
            idsToConvert = selected.filter(id => this.instances.peekTypeIdentOf(id) === 'any-tracker');
        }

        let patches: [IdBimScene, SceneInstancePatch][] = [];

        for (const [id, inst] of this.instances.peekByIds(idsToConvert)) {
            if (!(inst.props instanceof AnyTrackerProps)) {
                continue;
            }
            const piles = inst.props.piles.active_configuration;
            if (piles && piles.count > 0) {
                const lengths = piles.features.map((l) => Math.random() * 30);
                const propsPatch = producePropsPatch(inst.props, (props) => {
                    props.piles.lengths = new SmallNumericArrayProperty({values: lengths, unit: 'm'});
                })
                if (propsPatch) {
                    patches.push([id, {props: propsPatch}]);
                }
            }
        }

        this.instances.applyPatches(patches);
    }

    // recreateAsAnyTrackers() {
    //     const trackers = this.instances.peekByTypeIdent('tracker');

    //     const totalAabb = Aabb.empty();
    //     const bboxes = this.allBimGeometries.aabbs.poll();
        
    //     const trackersPositionsPerId = new Map<IdBimScene, Matrix4>();
    //     const toAlloc: [IdBimScene, Partial<SceneInstance>][] = [];

    //     for (const [id, tracker] of trackers) {
    //         if (!tracker.representation) {
    //             console.warn('tracker has no representation', id);
    //             continue;
    //         }
    //         const aabb = tracker.representation.aabb(bboxes);
    //         aabb.applyMatrix4(tracker.worldMatrix);
    //         totalAabb.union(aabb);

    //         const propsAsJson = BimUtils.convertLegacyPropsIntoJsonLikeObjs(tracker);
    //         console.log('propsAsJson', propsAsJson);
    //         const newProps = PropsGroupsRegistry.newPropsGroupFromJsonLikeArgs(AnyTrackerProps, propsAsJson);

    //         const newInstance: Partial<SceneInstance> = {
    //             type_identifier: 'any-tracker',
    //             props: newProps,
    //             colorTint: tracker.colorTint,
    //             spatialParentId: tracker.spatialParentId,
    //             name: tracker.name + "as any tracker",
    //         };

    //         const newId = this.instances.idsProvider.reserveNewId();
    //         toAlloc.push([newId, newInstance]);

    //         trackersPositionsPerId.set(newId, tracker.worldMatrix.clone());
    //     }

    //     const totalBoundsSize = totalAabb.getSize();
    //     console.log('totalBoundsSize', totalBoundsSize);
    //     for (const wm of trackersPositionsPerId.values()) {
    //         // const posBefore = wm.extractPosition();
    //         wm.elements[13] += totalBoundsSize.y;
    //         // console.log('posBefore', posBefore.y, 'posAfter', wm.extractPosition().y, totalBoundsSize.y);
    //     }

    //     this.instances.allocate(toAlloc);
    //     this.instances.patchWorldMatrices(trackersPositionsPerId);
    // }

    registerCustomRuntimes(mathSolverApi: MathSolversApi, costsProvider: CostsConfigProvider) {
        registerBlockNumberSolver(this);
        registerShadingRuntime(this, mathSolverApi);
        registerTrackersRowsRuntime(this);
        registerEnergyRuntimes(this);
        registerPricingRuntime(this, costsProvider);
    }
}
