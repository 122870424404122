import type { IdVersionPacked } from "./CompactVersionId";
import { getIdFromIDV } from "./CompactVersionId";
import type { PersistedCollectionConfig } from './VerDataPersistedCollection';
import { BatchDescription, BatchPartialReference } from './WireCollectionHistory';
import type { Id } from './VerDataSyncerImpl';
import type { ScopedLogger } from 'engine-utils-ts';
import { FetchUtils, IterUtils } from 'engine-utils-ts';
import { VerdataUrls } from './VerdataUrls';
import { CompressedIntegersInMemory } from './CompressedIntegersInMemory';


const MIN_BATCH_FITNESS = 0.6;

export function calculateNewBatchesCollection(
    logger: ScopedLogger,
    idvs: IdVersionPacked[],
    thisSessionBatches: Set<BatchDescription>, // should only contain batches actively used in this session
    config: PersistedCollectionConfig,
): { toReuse: BatchPartialReference[], toUploadNew: BatchDescription[] } {

    logger.debug('calculate_batches of ids ', idvs.map(getIdFromIDV));
    const idvsLeftToFindBatchesFor: Set<IdVersionPacked> = new Set(idvs);


    { // sanity check
        const allIds = IterUtils.map(idvs, getIdFromIDV);
        const asSet = new Set(allIds);
        if (asSet.size !== allIds.length) {
            const countsPerId = new Map<number, number>();
            for (const id of allIds) {
                countsPerId.set(id, (countsPerId.get(id) ?? 0) + 1);
            }
            const repeatedIds = IterUtils.filterMap(countsPerId, ([id, count]) => {
                if (count == 1) {
                    return undefined;
                }
                return [id, count];
            });
            logger.error(`repeated ids are passed into new batches calculation`, repeatedIds);
            throw new Error('repeated ids');
        }
    }

    const includedIds: number[] = [];
    
    const reusedBatchesRefs: BatchPartialReference[] = [];
    
    {// first find good batches that can be reused for new collection version
        const savedBatchesToTryToReuse = new Set(thisSessionBatches);
        while (savedBatchesToTryToReuse.size > 0 && idvsLeftToFindBatchesFor.size > 0) {
            const fitBatchToReuse = chooseMostFitBatch(config, savedBatchesToTryToReuse, idvsLeftToFindBatchesFor);
            
            if (fitBatchToReuse == null || fitBatchToReuse.fitness < MIN_BATCH_FITNESS) {
                break;
            }
            logger.debug('fitness', fitBatchToReuse.fitness);

            const batch = fitBatchToReuse.batch;
            savedBatchesToTryToReuse.delete(batch);

            const batchIdvs = batch.idvs();
            
            const idsToExclude: Id[] = [];
            for (const idv of batchIdvs) {
                if (!idvsLeftToFindBatchesFor.delete(idv)) {
                    idsToExclude.push(getIdFromIDV(idv));
                } else {
                    includedIds.push(getIdFromIDV(idv));
                }
            }
            logger.assert(idsToExclude.length < batchIdvs.length, "ids to exclude count sanity check");
            reusedBatchesRefs.push(new BatchPartialReference(
                batch.guid,
                CompressedIntegersInMemory.fromDecompressed(idsToExclude),
            ));
        }
    }
    const date = new Date();
    // now create batches for the elements left
    const newBatchesToUpload: BatchDescription[] = [];
    if (idvsLeftToFindBatchesFor.size > 0) {
        const numberOfNewBatches = Math.max(1, Math.floor(idvsLeftToFindBatchesFor.size / config.objectCountInBatchHint));
        const objectsPerBatch = Math.ceil(idvsLeftToFindBatchesFor.size / numberOfNewBatches);
        const perBatchIds = IterUtils.splitArrayIntoChunks(
            Array.from(idvsLeftToFindBatchesFor), objectsPerBatch);
        
        // if last chunk is small, add it to previous
        if (perBatchIds.length > 1
            && perBatchIds.at(-1)!.length < objectsPerBatch * 0.5
        ) {
            IterUtils.extendArray(perBatchIds[perBatchIds.length - 2], perBatchIds.pop()!);    
        }
        
        for (const batchIdvs of perBatchIds) {
            const guid = FetchUtils.generateGuid();
            const filename = VerdataUrls.batchFileName(config.identifier, date, guid);
            newBatchesToUpload.push(BatchDescription.newWithPackedIds(
                guid,
                filename,
                batchIdvs,
            ));
            for (const idv of batchIdvs) {
                const id = getIdFromIDV(idv);
                includedIds.push(id);
            }
        }
        logger.assert(newBatchesToUpload.length > 0, 'objects left, should have batches to upload');
    }

    // sanity check
    if (includedIds.length !== idvs.length) {
        const idsRequested = new Set(idvs.map(getIdFromIDV));
        const idsProduced = new Set(includedIds);
        const diff = IterUtils.setsDiff(idsRequested, idsProduced);

        logger.error(`requested ${idvs.length}, included ${includedIds.length}`);
        if (diff.absentInL.length) {
            logger.error('not included requested ids:', diff.absentInL);
        }
        if (diff.absentInR.length) {
            logger.error('included unnecessary ids:', diff.absentInR)
        }
        throw new Error('erroneous batches calculation');
    }

    logger.debug('calculated_batches ', reusedBatchesRefs, newBatchesToUpload);
    return { toReuse: reusedBatchesRefs, toUploadNew: newBatchesToUpload };
}



function chooseMostFitBatch(
    settings: PersistedCollectionConfig, batches: Iterable<BatchDescription>, idvs: Set<IdVersionPacked>
): { batch: BatchDescription, fitness: number } | null
{
    let bestBatchYet: BatchDescription | null = null;
    let bestFitYet: number = 0;
    const preferredBatchSize = settings.objectCountInBatchHint;
    for (const batch of batches) {
        let countInside = 0;
        const idvsInBatch = batch.idvs();
        for (const idv of idvsInBatch) {
            if (idvs.has(idv)) {
                countInside += 1;
            }
        }
        const fit = batchFitness01(preferredBatchSize, idvsInBatch.length, countInside);
        if (fit == 1) {
            return { batch, fitness: fit };
        }
        if (bestBatchYet == null || bestFitYet < fit) {
            bestBatchYet = batch;
            bestFitYet = fit;
        }
    }
    if (bestBatchYet) {
        return { batch: bestBatchYet, fitness: bestFitYet };
    }
    return null;
}



function batchFitness01(
    prefferedBatchSize: number,
    batchIdsCount: number,
    countInside: number
): number {
    if (countInside > batchIdsCount) {
        throw new Error("batch fitness params are invalid, count inside > batch size");
    }
    if (countInside == batchIdsCount) {
        return 1.0;
    }
    let fitness = countInside / batchIdsCount;
    const sizeRatio = batchIdsCount / prefferedBatchSize;
    if (sizeRatio < 1) { // decrease fitness of small incomplete batches
        fitness *= Math.sqrt(sizeRatio); //the smaller the batch, the harder for it to stay
    }
    return fitness;
}

