import type {
    BimPropertyData,
    ParsedPropsType} from "bim-ts";
import { Bim, BimProperty, NumberProperty, StringProperty, exportToBimAsset } from 'bim-ts';
import { Failure, LazyDerived, ObservableObject, Success, Yield } from 'engine-utils-ts';
import type { UiBindings } from 'ui-bindings';
import { DialogDescription, PUI_Builder, PUI_Lazy } from 'ui-bindings';
import type { ProjectNetworkClient, Result, ScopedLogger} from "engine-utils-ts";

type PropertiesConfig = {
    [key: string]: BimProperty | PropertiesConfig;
};

export function* importPanOndFile(
    type: string,
    fileName: string,
    parsedFileProps: ParsedPropsType,
    network: ProjectNetworkClient,
    uiBindings: UiBindings,
    instancePropsShape: Record<string, BimPropertyData>,
    logger: ScopedLogger,
): Generator<Yield, Result<Uint8Array>, unknown> {
    const bim = new Bim({});

    const configProps: {[key: string]: BimProperty} = {};
    for (const key in instancePropsShape) {
        const prop = instancePropsShape[key];
        const parsedPropResult = parsedFileProps[key];
        if(parsedPropResult instanceof Failure) {
            return parsedPropResult;
        }
        const parsedProp = parsedPropResult?.value;
        let newPropData: BimPropertyData = prop;
        if(parsedProp instanceof StringProperty) {
            newPropData = {...newPropData, value: parsedProp.value};
        } else if(parsedProp instanceof NumberProperty) { 
            newPropData = {...newPropData, value: parsedProp.value, unit: parsedProp.unit};
        } else if(!parsedProp) {
            // skip
        } else {
            logger.error("unknown parsed prop type", parsedProp);
        }

        const newProp = BimProperty.NewShared(newPropData);
        configProps[newProp._mergedPath] = newProp;
    }
    
    const observableProps = new ObservableObject({
        identifier: type + "-properties-import",
        initialState: configProps,
    });

    const puiLazy = LazyDerived.new1(
        type + "-pui-props-lazy",
        null,
        [observableProps],
        ([props]) => {
            const configProps: PropertiesConfig = {};
            for (const key in props) {
                const prop = props[key];
                setPropertyOnObject(configProps, prop.path.slice(), prop);
            }
            const builder = new PUI_Builder({ sortChildrenDefault: false});
            buildPropertiesConfig(builder, configProps, (prop) => {
                const patch: {[key:string]: BimProperty} = {};
                patch[prop._mergedPath] = prop;
                observableProps.applyPatch({ patch });
            }, parsedFileProps, logger);
            const pui = builder.finish();
            return pui;
        }
    ).withoutEqCheck();
    let cancel = false;
    let importFile = false;
    const description = new DialogDescription({
        name: fileName,
        context: null,
        userActions: [
            {
                name: 'cancel',
                action: () => {
                    cancel = true;
                },
                style: 4,
            },
            {
                name: 'import',
                action: () => {
                    importFile = true;
                },
            }
        ],
        defaultAction: () => {
            cancel = true;
        },
        uiSource: new PUI_Lazy(puiLazy),  
        compact: true,
        onClose: () => {
            cancel = true;
        }
    });
    uiBindings.addDialog(description);

    if (description.canExecuteDefault()) {
        description.executeDefault();
    }

    while (!cancel && !importFile) {
        yield Yield.NextFrame;
    }
    yield Yield.NextFrame; 
    if(cancel) {
        return new Failure({msg: "cancelled"});
    }

    const updatedProps = observableProps.poll();
    const updatedPropsArray:[string, BimProperty][] = []; 
    for (const key in updatedProps) {
        const prop = updatedProps[key];
        updatedPropsArray.push([prop._mergedPath, prop]);
    }

    const newInstance = bim.instances.archetypes.newDefaultInstanceForArchetype(type);
    newInstance.properties.applyPatch(updatedPropsArray);
    const newId = bim.instances.idsProvider.reserveNewId();
    bim.instances.allocate([[newId, newInstance]]);
    yield* bim.runUpdatesTillCompletion({forceRun: true});
    const assetResult = yield* exportToBimAsset(bim, [newId], false);

    return new Success(assetResult);
}

function buildPropertiesConfig(
    builder: PUI_Builder,
    properties: PropertiesConfig,
    callBack: (prop: BimProperty) => void,
    parsedFileProps: ParsedPropsType,
    logger: ScopedLogger,
) {
    for (const key in properties) {
        const field = properties[key];
        if (field instanceof BimProperty) {
            convertProperty(builder, field, callBack, logger, parsedFileProps);
        } else {
            builder.inGroup(
                {
                    name: key,
                },
                () => {
                    buildPropertiesConfig(builder, field, callBack, parsedFileProps, logger);
                }
            );
        }
    }
}

function setPropertyOnObject(
    obj: PropertiesConfig,
    path: string[],
    prop: BimProperty,
): void {
    const currentPath = path.slice();
    const groupKey = currentPath.shift();
    if (currentPath.length === 0 && groupKey) {
        obj[groupKey] = prop;
        return;
    }
    if (!groupKey) {
        return;
    }
    let group = obj[groupKey];
    if (group === undefined) {
        group = {};
        obj[groupKey] = group;
    }
    setPropertyOnObject(group as PropertiesConfig, currentPath, prop);
}

function convertProperty(
    builder: PUI_Builder,
    prop: BimProperty,
    callBack: (prop: BimProperty) => void,
    logger: ScopedLogger,
    parsedFileProps: ParsedPropsType,
) {
    if (typeof prop.value === "number") {
        builder.addNumberProp({
            name: prop.path[prop.path.length - 1],
            value: prop.value,
            unit: prop.unit ?? undefined,
            step: prop.numeric_step ? prop.numeric_step : undefined,
            minMax: prop.numeric_range ?? undefined,
            onChange: (newValue: number) => {
                callBack(prop.withDifferentValue(newValue));
            },
            readonly: prop.readonly,
        });
    } else if (typeof prop.value === "boolean") {
        builder.addBoolProp({
            name: prop.path[prop.path.length - 1],
            value: prop.value,
            onChange: (newValue: boolean) => {
                callBack(prop.withDifferentValue(newValue));
            },
        });
    } else if (typeof prop.value === "string" && prop.discrete_variants) {
        builder.addSelectorProp({
            name: prop.path[prop.path.length - 1],
            value: prop.value,
            options: prop.discrete_variants as string[],
            onChange: (newValue: string) => {
                callBack(prop.withDifferentValue(newValue));
            },
        });
    } else if (typeof prop.value === "string") {
        builder.addStringProp({
            name: prop.path[prop.path.length - 1],
            value: prop.value,
            onChange: (newValue: string) => {
                callBack(prop.withDifferentValue(newValue));
            },
        });
    } else {
        logger.error("unknown property type", prop);
    }

    const parsedProp = parsedFileProps[prop.path[prop.path.length - 1]];   
    if(parsedProp instanceof Success && parsedProp.warnings?.length) {
        showError(builder, parsedProp.warnings.map(w => w.errorMsg()).join(", "));
    } else if(parsedProp instanceof Failure) {
        showError(builder, parsedProp.errorMsg());
    } else if(parsedProp instanceof Success) {
        // no messages
    } else {
        logger.error("unknown parsed prop type", parsedProp);
    }
}

function showError(builder: PUI_Builder, errorMsg: string) {
    builder.addCustomProp({
        name: errorMsg,
        value: errorMsg,
        onChange: () => {},
        readonly: true,
        type_ident: "error_message",
        context: { }
    });
}
