// automatically generated by the FlatBuffers compiler, do not modify

import { BoolValue } from './bool-value.js';
import { NumericValue } from './numeric-value.js';
import { StringValue } from './string-value.js';


export enum BimPropertyValue {
  NONE = 0,
  NumericValue = 1,
  StringValue = 2,
  BoolValue = 3
}

export function unionToBimPropertyValue(
  type: BimPropertyValue,
  accessor: (obj:BoolValue|NumericValue|StringValue) => BoolValue|NumericValue|StringValue|null
): BoolValue|NumericValue|StringValue|null {
  switch(BimPropertyValue[type]) {
    case 'NONE': return null; 
    case 'NumericValue': return accessor(new NumericValue())! as NumericValue;
    case 'StringValue': return accessor(new StringValue())! as StringValue;
    case 'BoolValue': return accessor(new BoolValue())! as BoolValue;
    default: return null;
  }
}

export function unionListToBimPropertyValue(
  type: BimPropertyValue, 
  accessor: (index: number, obj:BoolValue|NumericValue|StringValue) => BoolValue|NumericValue|StringValue|null, 
  index: number
): BoolValue|NumericValue|StringValue|null {
  switch(BimPropertyValue[type]) {
    case 'NONE': return null; 
    case 'NumericValue': return accessor(index, new NumericValue())! as NumericValue;
    case 'StringValue': return accessor(index, new StringValue())! as StringValue;
    case 'BoolValue': return accessor(index, new BoolValue())! as BoolValue;
    default: return null;
  }
}
