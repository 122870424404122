<script lang="ts">
import { LCOE, NumberProperty, UnitsMapper } from "bim-ts";
import { formatQuantityToShortest } from "./table/column-definitions/LCOEInput";
import { replaceCurrencyUnitWithSymbol } from "engine-utils-ts";
import { defaultTextColor } from "./table/column-definitions/common";

export let lcoe: LCOE.LCOECalculationResult | undefined;
export let unitsMapper: UnitsMapper;

$: initialInvestment = lcoe && (lcoe.output.initialInvestmentInCapitalCost);
$: npvOfCost = NumberProperty.new({ value: (lcoe?.output.NVPOfCost ?? 0) + (initialInvestment ?? 0), unit: 'usd' });
$: npvOfEnergy = NumberProperty.new({ value: lcoe?.output.NPVOfEnergyProductionWattHours ?? 0, unit: 'Wh' });
$: priceSymbol = replaceCurrencyUnitWithSymbol(unitsMapper.mapToConfigured({ value: 0, unit: 'usd' }).unit ?? '');
$: result = NumberProperty.new({ value: LCOE.inverseLcoeResultDiv(lcoe?.output.levelizedCostOfEnergyPerWattHour ?? 0), unit: 'Wh' });
$: resultShortest = NumberProperty.new(unitsMapper.converter.toShortest(result));
$: resultString = `${priceSymbol}${Number.isFinite(resultShortest.value) ? resultShortest.value.toFixed(2) : '-'}/${resultShortest.unit}`

</script>

<div class="container1">
    <div><b>Levelised Cost of Energy (LCOE)</b></div>
    <div style="color: {defaultTextColor}">
        {[
            formatQuantityToShortest(npvOfCost, unitsMapper),
            formatQuantityToShortest(npvOfEnergy, unitsMapper)
        ].join('    /    ')}
    </div>
    <div><b>{resultString}</b></div>
</div>

<style lang="scss">
.container1 {
    display: flex;
    font-size: 16px;
    align-items: center;
    height: inherit;
    justify-content: space-between;
    margin: 0 7px;
    b {
        font-weight: 600;
    }
}
</style>
