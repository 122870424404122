// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { PropertiesGroupT } from './properties-group.js';
import { PropertiesGroup } from './properties-group.js';


export class PropertiesGroupArray implements flatbuffers.IUnpackableObject<PropertiesGroupArrayT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):PropertiesGroupArray {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsPropertiesGroupArray(bb:flatbuffers.ByteBuffer, obj?:PropertiesGroupArray):PropertiesGroupArray {
  return (obj || new PropertiesGroupArray()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsPropertiesGroupArray(bb:flatbuffers.ByteBuffer, obj?:PropertiesGroupArray):PropertiesGroupArray {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new PropertiesGroupArray()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

groups(index: number, obj?:PropertiesGroup):PropertiesGroup|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new PropertiesGroup()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

groupsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startPropertiesGroupArray(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addGroups(builder:flatbuffers.Builder, groupsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, groupsOffset, 0);
}

static createGroupsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startGroupsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endPropertiesGroupArray(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createPropertiesGroupArray(builder:flatbuffers.Builder, groupsOffset:flatbuffers.Offset):flatbuffers.Offset {
  PropertiesGroupArray.startPropertiesGroupArray(builder);
  PropertiesGroupArray.addGroups(builder, groupsOffset);
  return PropertiesGroupArray.endPropertiesGroupArray(builder);
}

unpack(): PropertiesGroupArrayT {
  return new PropertiesGroupArrayT(
    this.bb!.createObjList<PropertiesGroup, PropertiesGroupT>(this.groups.bind(this), this.groupsLength())
  );
}


unpackTo(_o: PropertiesGroupArrayT): void {
  _o.groups = this.bb!.createObjList<PropertiesGroup, PropertiesGroupT>(this.groups.bind(this), this.groupsLength());
}
}

export class PropertiesGroupArrayT implements flatbuffers.IGeneratedObject {
constructor(
  public groups: (PropertiesGroupT)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const groups = PropertiesGroupArray.createGroupsVector(builder, builder.createObjectOffsetList(this.groups));

  return PropertiesGroupArray.createPropertiesGroupArray(builder,
    groups
  );
}
}
