<script lang="ts">
    import type { PUI_CustomPropertyNode } from "ui-bindings";

    export let property: PUI_CustomPropertyNode<string, {color?: string} | undefined>;

</script>

<div class="ui-config-property property-row">
    <!-- empty block is needed to show the message in column with inputs, not in full row -->
    <div class="property-name" />
    <div class="property-value">
        <div class="message mdc-typography--tooltip {property.context?.color ?? 'text-danger'}">
            {property.value}
        </div>
    </div>
</div>

<style lang="scss">
    .message {
        padding: 0 8px;
    }
</style>
