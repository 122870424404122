import { EnumUtils, LegacyLogger } from 'engine-utils-ts';
import { KrMath } from 'math-ts';


export interface DatesParseResult {
    utcDates: Date[];
    startIndex: number;
    dateKind: DateKind;
    timeSource: TimeSource;
}
export enum DateKind {
    YMD_Split,
    YMD_Joined,
    DMY_Split,
    MDY_Split,
}
export enum TimeSource {
    JoinedWithDate = 1,
    NextColumn = 2,
}

export function tryParseDates(args: {
    datesColumn: string[],
    maybeTimeColumn: string[],
    searchIndexStart: number,
    startIndexLimit: number,
    minSequenceLength: number,
    maxSequenceLength: number,
}): DatesParseResult | null {

    const combinationsToTry: [DateKind, TimeSource][] = [];

    for (const dateKind of EnumUtils.getAllEnumConstsValues(DateKind)) {
        for (const timeKind of [TimeSource.JoinedWithDate, TimeSource.NextColumn]) {
            combinationsToTry.push([dateKind, timeKind]);
        }
    }

    const searchEndIndex = args.maxSequenceLength ?
        Math.min(args.searchIndexStart + args.maxSequenceLength, args.datesColumn.length)
        : args.datesColumn.length;

    chooseDateKind:
    for (const [dateKind, timeSource] of combinationsToTry) {
        const utcDates: Date[] = [];
        let datesStartIndex: number = 0;
        for (let i = args.searchIndexStart; i < searchEndIndex; ++i) {

            if (utcDates.length === 0 && i > args.startIndexLimit) {
                continue chooseDateKind;
            }

            const dateStr = args.datesColumn[i];
            const maybeHourString = args.maybeTimeColumn[i];

            const date = parseUtcDateFromString(dateStr, dateKind, timeSource, maybeHourString);
            if (date == null) {
                if (utcDates.length === 0) {
                    continue;
                } else if (utcDates.length >= args.minSequenceLength){
                    break;
                } else {
                    continue chooseDateKind;
                }
            }
            utcDates.push(date);
            if (utcDates.length === 1) {
                datesStartIndex = i;
            }

            if (utcDates.length >= args.maxSequenceLength) {
                break;
            }
        }
        return {
            utcDates,
            dateKind,
            timeSource,
            startIndex: datesStartIndex,
        };
    }
    return null;
}


// const EndingInYearSplitRegex = /^\d\d[\/\.-]\d\d[\/\.-]\d\d\d\d/;
// const StartingWithYearSplitRegex = /^\d\d\d\d[\/\.-]\d\d[\/\.-]\d\d/;
// const StartingWithYearJoinedRegex = /^\d\d\d\d\d\d\d\d/;


function parseUtcDateFromString(dateStr: string, dateKind: DateKind, timeSource: TimeSource, maybeTimeString: string,): Date | null {
    const dateParsed = parseYearMonthDateFromStr(dateStr, dateKind);
    if (dateParsed == null) {
        return null;
    }
    let timeString: string;
    if (timeSource === TimeSource.JoinedWithDate) {
        timeString = dateStr.slice(dateParsed.usedCharsCount + 1);
    } else if (timeSource === TimeSource.NextColumn) {
        timeString = maybeTimeString;
    } else {
        LegacyLogger.deferredError('unexpected time source', timeSource);
        return null;
    }
    const timeParsed = parseTimeFromString(timeString);
    if (timeParsed == null) {
        return null;
    }
    const date = Date.UTC(
        dateParsed.year,
        dateParsed.month - 1,
        dateParsed.day,
        timeParsed.hour,
        timeParsed.minute,
    );
    return new Date(date);
}

interface DateParseResult {
    year: number;
    month: number;
    day: number;
    usedCharsCount: number;
}

function parseYearMonthDateFromStr(dateStr: string, dateKind: DateKind): DateParseResult | null {

    let ymd: DateParseResult | null;

    switch(dateKind) {
        case DateKind.YMD_Split: {
            const [year, month, day, usedCharsCount] = parse3SplitDateNumbers(dateStr);
            ymd = { year, month, day, usedCharsCount};
        }; break;
        case DateKind.YMD_Joined: {
            const year = parseInt(dateStr.slice(0, 4));
            const month = parseInt(dateStr.slice(4, 6));
            const day = parseInt(dateStr.slice(6, 8));
            ymd = { year, month, day, usedCharsCount: 8};
        }; break;
        case DateKind.DMY_Split: {
            const [day, month, year, usedCharsCount] = parse3SplitDateNumbers(dateStr);
            ymd = { year, month, day, usedCharsCount};
        }; break;
        case DateKind.MDY_Split: {
            const [month, day, year, usedCharsCount] = parse3SplitDateNumbers(dateStr);
            ymd = { year, month, day, usedCharsCount};
        }; break;
        default: {
            console.error('invalid date kind', dateKind);
            ymd = null;
        }
    }
    if (ymd) {
        if (KrMath.clamp(ymd.year, 0, 9999) !== ymd.year) {
            return null;
        }
        if (KrMath.clamp(ymd.month, 1, 12) !== ymd.month) {
            return null;
        }
        if (KrMath.clamp(ymd.day, 1, 31) !== ymd.day) {
            return null;
        }
    }
    return ymd;
}

const datesNumbersRegex = new RegExp(/(\d{1,4})[^0-9](\d{1,4})[^0-9](\d{1,4})/);

function parse3SplitDateNumbers(dateStr: string): [n1: number, n2: number, n3: number, usedChartsCount: number] {
    const numbersGroups = dateStr.match(datesNumbersRegex);

    if (!numbersGroups) {
        return [NaN, NaN, NaN, NaN];
    }

    const numbers: number[] = [];
    let usedCharactersCount = 0;
    for (let i = 1; i < numbersGroups.length; ++i) {
        const group = numbersGroups[i];
        numbers.push(parseInt(group));
        const indexOf = dateStr.indexOf(group, usedCharactersCount);
        usedCharactersCount = indexOf + group.length;
    }
    if (numbers.length !== 3) {
        console.error('unexpected date parsing numbers count', numbers.length);
    }
    numbers.push(usedCharactersCount);
    return numbers as [number, number, number, number];
}

interface TimeParseResult {
    hour: number;
    minute: number;
}

const timeNumbersRegex = new RegExp(/(\d{1,2})[^0-9](\d{1,2})/);

function parseTimeFromString(timeStr: string): TimeParseResult | null {

    let timeParsed: TimeParseResult | null;

    const dividerIndex = timeStr.indexOf(':');
    if (dividerIndex > 0 && dividerIndex <= 2) {
        let hour = parseInt(timeStr.slice(0, dividerIndex));
        const minute = parseInt(timeStr.slice(dividerIndex + 1));
        if (timeStr.endsWith('M') || timeStr.endsWith('m')) {
            let isPmTime = false;
            if (timeStr.at(-2) === 'P' || timeStr.at(-2) === 'p') {
                isPmTime = true;
            }
            if (isPmTime && hour < 12) {
                hour += 12;
            } else if (!isPmTime && hour === 12) {
                hour = 0;
            }
        }
        timeParsed = { hour, minute };
    } else if (timeStr.length === 4) {
        // try joined military time
        const hour = parseInt(timeStr.slice(0, 2));
        const minute = parseInt(timeStr.slice(2, 4));
        timeParsed = { hour, minute };
    } else {
        return null;
    }

    if (timeParsed) {
        if (KrMath.clamp(timeParsed.hour, 0, 24) !== timeParsed.hour) {
            return null;
        }
        if (KrMath.clamp(timeParsed.minute, 0, 59) !== timeParsed.minute) {
            return null;
        }
    }
    return timeParsed;
}