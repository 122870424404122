<script lang="ts">
    import { type NavbarContext, PanelPosition, ViewPosition } from "./Navbar";

    export let navbar: NavbarContext;
    export let side: PanelPosition.Left | PanelPosition.Right;

    $: isLeftSide = side === PanelPosition.Left;

    let mousePosition: boolean = false;

    function onDrop(position: ViewPosition) {
        navbar.changeViewPosition($navbar.dragViewId!, position);
    }
</script>

<div
    class="drop-area"
    style="display: {$navbar.dragViewId
        ? 'grid'
        : 'none'}; opacity: {mousePosition ? '1' : '0'}; {side ===
    PanelPosition.Left
        ? 'left'
        : 'right'}: 0;"
    on:mouseenter={(e) => {
        mousePosition = true;
    }}
    on:mouseleave={(e) => {
        mousePosition = false;
    }}
>
    <div
        class="drop-panel"
        on:pointerup={() =>
            onDrop(isLeftSide ? ViewPosition.LeftTop : ViewPosition.RightTop)}
    />
    <div
        class="drop-panel"
        on:pointerup={() =>
            onDrop(
                isLeftSide ? ViewPosition.LeftBottom : ViewPosition.RightBottom
            )}
    />
    {#if isLeftSide}
        <div
            class="drop-panel"
            on:pointerup={() => onDrop(ViewPosition.LeftSecond)}
        />
    {/if}
</div>

<style lang="scss">
    .drop-area {
        position: absolute;
        height: 100%;
        padding: 8px;
        box-sizing: border-box;
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(4, 1fr);
        grid-auto-columns: 120px;
        grid-gap: 8px;
        z-index: 1000;
    }
    .drop-panel {
        cursor: pointer;
        border-radius: 8px;
        border: 2px solid var(--mdc-theme-primary);
        background-color: rgba(156, 39, 176, 0.08);
        &:hover {
            background-color: rgba(156, 39, 176, 0.24);
        }
        &:first-child {
            grid-row: span 3;
        }
        &:nth-child(3) {
            grid-row: span 4;
        }
    }
</style>
