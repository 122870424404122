import type { ObservableStream } from "./ObservableStream";

export type IncomingNotificationsHandler = (msg: Uint8Array) => void;

export enum ConnectionEventType {
    Open,
    Closed,
};

export interface RemoteMsgHub {
    dispose(): void;
    request(array: Uint8Array): Promise<Uint8Array>;
    onNotification: IncomingNotificationsHandler;
    isNetworkBusy(): boolean;
    connectionEvents: ObservableStream<ConnectionEventType>;
}
