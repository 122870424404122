<script lang="ts">
    import { EnergyFailure, TrackerConnectionFailure } from "bim-ts";
    import NotConnectedTrackers from "./NotConnectedTrackers.svelte";

    export let warnings: EnergyFailure[];

</script>

{#each warnings as warning (warning.errorMsgIdent)}
    {@const uiWarnings = warning.toEnergyStageWarnings("warning")}
    <div class="error-container text-danger">
        {#if warning instanceof TrackerConnectionFailure}
            <NotConnectedTrackers warning={uiWarnings[0]} />
        {:else}
            {#each uiWarnings as warn}
                <div>{warn.description}</div>
            {/each}
        {/if}
    </div>
{/each}

<style lang="scss">
    .error-container {
        padding: 4px 16px;
        &:first-child {
            margin-top: 12px;
        }
    }
</style>
