<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        d="M10.007 15.5C11.7189 15.5 13.2918 14.9997 14.7258 13.999C16.1598 12.9983 17.2512 11.6653 18 10C17.2512 8.33472 16.161 7.00174 14.7294 6.00104C13.2978 5.00035 11.726 4.5 10.0142 4.5C8.3023 4.5 6.72936 5.00035 5.29536 6.00104C3.86137 7.00174 2.76291 8.33472 2 10C2.76291 11.6653 3.86017 12.9983 5.29178 13.999C6.7234 14.9997 8.29515 15.5 10.007 15.5Z"
        stroke="currentColor"
        stroke-width="1.6"
    />
    <path
        d="M9.99528 12.25C9.36896 12.25 8.83817 12.0308 8.4029 11.5924C7.96763 11.1539 7.75 10.6216 7.75 9.99528C7.75 9.36896 7.96921 8.83817 8.40762 8.4029C8.84605 7.96763 9.37842 7.75 10.0047 7.75C10.631 7.75 11.1618 7.96921 11.5971 8.40762C12.0324 8.84605 12.25 9.37842 12.25 10.0047C12.25 10.631 12.0308 11.1618 11.5924 11.5971C11.1539 12.0324 10.6216 12.25 9.99528 12.25Z"
        stroke="currentColor"
        stroke-width="1.6"
    />
</svg>
