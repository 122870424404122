import { BimProperty } from "../bimDescriptions/BimProperty";
import type { PropertiesGroupFormatters } from "../bimDescriptions/PropertiesGroupFormatter";
import { PropertiesGroupFormatter } from "../bimDescriptions/PropertiesGroupFormatter";
import type { AssetId, WireSpecCatalogItemPropsBase } from "../catalog";
import { CatalogItem } from "../catalog";
import { BimPropertiesFormatter } from "../catalog/SceneInstanceHierarchyPropsRegistry";
import { NumberProperty } from "../properties/PrimitiveProps";

export const WireSpecCommonKeyProps = {
    gauge: BimProperty.NewShared({
        path: ['wire', 'gauge'],
        value: 'unknown_model'
    }),
    material: BimProperty.NewShared({
        path: ['wire', 'material'],
        value: 'unknown_material'
    }),
};

export function registerWireSpecCommonKeyPropertiesGroupFormatter(
    type: string,
    group: PropertiesGroupFormatters
) {
    group.register(
        type,
        new PropertiesGroupFormatter(
            WireSpecCommonKeyProps,
            (props) => {
                return Array.from(new Set([
                    props.gauge,
                    props.material
                ].map(x => x.asText()))).join(' ');
            }
        )
    )
}

export function commonWireSpecAssetToCatalogItem(type: string, assetId: AssetId) {
    const item = new CatalogItem<WireSpecCatalogItemPropsBase>(
        undefined,
        type,
        {
            asset_id: NumberProperty.new({
                value: assetId,
                unit: '',
                description: 'id of the asset',
                range: [0, 1000000000000],
                step: 1,
                isReadonly: true,
            }),
            price_per_length: NumberProperty.new({
                value: 0,
                unit: 'usd',
                description: 'price of the asset',
                step: 0.1,
                range: [0, 100000]
            }),
            length_unit: NumberProperty.new({
                value: 1,
                unit: 'ft',
                description: 'length unit of the asset',
                step: 0.1,
                range: [0, 100000]
            }),
        },
        undefined,
    );
    return item;
}

export const WireSpecCommonHierarchyProps = [
    new BimPropertiesFormatter(
        {
            material: BimProperty.NewShared({
                path: ['wire', 'material'],
                value: '',
            }),
        },
        (props) => [props.material.asText() || 'material not specified'],
    )
];

