import type { EntityId } from 'verdata-ts';

import type { EntitiesBase } from './EntitiesBase';
import type { VersionedValue } from 'engine-utils-ts';

export class SharedEntitiesInterner<T, IdT> implements VersionedValue {

    _version: number = 0;
    _savedShared = new Map<string, EntityId<IdT>>();
    _savedSharedR = new Map<EntityId<IdT>, string>();

    // _cachedResultIds: WeakMap<T, EntityId<IdT>> = new WeakMap();

    constructor(
        readonly collection: EntitiesBase<T, IdT, any, any>,
        readonly stringifier: (entity: T) => string = JSON.stringify,
		readonly idsReserver: () => EntityId<IdT>,
    ) {
    }

    version(): number {
        return this._version;
    }

    delete(ids: Set<EntityId<IdT>>) {
        for (const [s, id] of this._savedShared) {
            if (ids.has(id)) {
                this._savedShared.delete(s);
                this._savedSharedR.delete(id);
                this._version += 1;
            }
        }
    }

    has(id: EntityId<IdT>): boolean {
        return this._savedSharedR.has(id);
    }

    get(entity: Partial<T>): EntityId<IdT> | undefined {
        // if (entity instanceof this.collection._T_Contstrutor) {
            
        // }
        const full = this.collection.fullFromPartial(entity);
        if (full == null) {
            return undefined;
        }
        const validationErros: string[] = [];
        this.collection.checkForErrors(full, validationErros);
        if (validationErros.length) {
            this.collection.logger.error('cant alloc shared entity', full, validationErros);
            return undefined;
        }
        const str = this.stringifier(full);
        let id = this._savedShared.get(str);
        if (id !== undefined) {
            return id;
        }
        id = this.idsReserver();
        const allocated = this.collection.allocate([[id, entity]]);
        if (allocated[0] != id) {
            return undefined;
        }
        this._savedShared.set(str, id);
        this._savedSharedR.set(id, str);
        this._version += 1;
        return id;
    }

    markShared(id: EntityId<IdT>, entity: T) {
        if (this.has(id)) {
            return;
        }
        const str = this.stringifier(entity);
        let existedId = this._savedShared.get(str);
        if (existedId !== undefined) {
            return;
        }
        this._savedShared.set(str, id);
        this._savedSharedR.set(id, str);
        this._version += 1;
        return id;
    }

    getAllIds() {
        return Array.from(this._savedShared.values());
    }
}
