<script lang='ts'>
 // forked from https://github.com/farzher/fuzzysort
    import { tick, onMount, createEventDispatcher } from 'svelte';
    import fuzzysort from 'fuzzysort';
    import { ActionVisibilityFlags, type ActionDescription } from 'ui-bindings';

    export let boundActions: ActionDescription<any>[] = [];

    class ActionWrapper {
        actionDescr: ActionDescription<any>;
        mergedPath: string;

        constructor(
            actionDescr: ActionDescription<any>,
        ) {
            this.actionDescr = actionDescr;
            this.mergedPath = actionDescr.name.join(' | ');
        }
    }

    $: allActions = boundActions
        .filter(a => a.visibility & ActionVisibilityFlags.Search)
        .map(a => new ActionWrapper(a));


    interface ActionWrapper {
        label: string,
        link: string,
    }

    let keys: (keyof ActionWrapper)[] = ['mergedPath'];
    let userInput = '';

    let barElement: HTMLDivElement;
    let inputEl: HTMLInputElement;

    const dispatch = createEventDispatcher();

    let listEl: HTMLUListElement;

    onMount(() => {
        barElement.addEventListener('keydown', onKeyDown);
        return () => {
            barElement.removeEventListener('keydown', onKeyDown);
        };
    });

    $: actions = allActions.filter(wa => wa.actionDescr.canUseNow.poll());
    $: actionsNotAvailable = allActions.filter(wa => !wa.actionDescr.canUseNow.poll());

    $: availiableOptions = getFilteredOptions(userInput, actions);
    $: notAvailiableOptions = getFilteredOptions(userInput, actionsNotAvailable);

    $: focusText();

    function onAction(action?: ActionWrapper) {
        dispatch('done');
        if (action?.actionDescr.action) {
            // const dynamicArgs = action.actionDescr.dynamicArgsDescription?.validator()
            action.actionDescr.action(undefined);
        }
    }
    async function focusText() {
        userInput = '';
        await tick();
        inputEl?.focus();
    }
    function getFilteredOptions(value: string, actions: ActionWrapper[]) {
        const filteredOptions = fuzzysort.go(value, actions, { keys });
        const visibleOptions = value ? filteredOptions.map(r => r.obj) : actions;
        const items = visibleOptions.map((obj, i) => {
            let html: any = {};
            for (let j = 0; j < keys.length; ++j) {
                if (filteredOptions[i] && filteredOptions[i][j]) {
                    const key = keys[j]
                    html[key] = fuzzysort.highlight(
                        filteredOptions[i][j],
                        '<b>',
                        '</b>'
                    ) ?? '';
                } else {
                    html[keys[j]] = obj[keys[j]];
                }
            }
            let item = {
                obj,
                html
            };
            return item;
        });
        return items;
    }

    function onKeyDown(e: KeyboardEvent) {
        e.stopPropagation();

        switch (e.code) {
            case 'Escape':
                onAction(undefined);
                e.preventDefault();
                e.stopPropagation();
                break;
            case 'ArrowUp':
                if (document.activeElement === inputEl) {
                    (listEl.lastChild as HTMLElement | null)?.focus();
                } else if (document.activeElement?.previousSibling) {
                    (document.activeElement!.previousSibling as HTMLElement).focus();
                } else {
                    (listEl.lastChild as HTMLElement | null)?.focus();
                }
                e.preventDefault();
            break;
            // ArrowDown
            case 'ArrowDown':
                if (document.activeElement === inputEl) {
                    let indexToFocus = 0;
                    if (listEl.childNodes.length > 1) {
                        indexToFocus = 1;
                    }
                    (listEl.childNodes[indexToFocus] as HTMLElement)?.focus();
                } else if (document.activeElement?.nextSibling) {
                    (document.activeElement.nextSibling as HTMLElement).focus();
                } else {
                    (listEl.firstChild as HTMLElement | null)?.focus();
                }
                e.preventDefault();
            break;
            // Enter
            case 'NumpadEnter':
            case 'Enter':
                const index = Array.prototype.slice
                    .call(listEl.children)
                    .indexOf(document.activeElement);
                let option;
                option = availiableOptions[index === -1 ? 0 : index];
                if (option) {
                    onAction(option.obj);
                }
            break;
            // Allow nativation with more keys
            // case '16': // SHIFT
            // case '17': // CTRL
            // case '18': // ALT
            // case '9': // TAB
            // console.log(e.keyCode);
            // break;
            // Any other key
            default:
                if (
                    (e.key.length === 1 &&
                    e.ctrlKey === false &&
                    e.altKey === false &&
                    e.metaKey === false) ||
                    e.key === 'Backspace'
                ) {
                    inputEl.focus();
                }
            break;
        }
    }

</script>

<style>
    input {
        width:97%;
        margin:3px 3px;
        height: 30px;
    }
    .lists-container {
        margin:3px 1px 3px 3px;
        height: 450px;
        overflow: auto;
    }
    .list {
        padding: 0;
    }
    .list li {
        margin: 0;
        padding: 0;
        text-indent: 0;
        list-style-type: none;
        height: 14px;
        line-height: 14px;
        padding: 10px;
    }
    .list li:focus {
        background-color: rgba(0, 0, 0, 0.1);
    }
    .list:not(:focus-within) > :first-child {
        background-color: rgba(0, 0, 0, 0.1);
    }
    .hotkeys {
        float: right;
    }

    .list-unavailable {
        padding: 0;
    }
    .list-unavailable li {
        margin: 0;
        padding: 0;
        text-indent: 0;
        list-style-type: none;
        height: 14px;
        line-height: 14px;
        padding: 10px;
        color:rgba(0, 0, 0, 0.35)
    }
</style>


<div bind:this={barElement}>
    <input type='text' bind:value={userInput} bind:this={inputEl} />
    <div class='lists-container custom-scrollbar'>
        <ul class='list' bind:this={listEl}>
            {#each availiableOptions as option}
                <li tabindex='0' on:click={() => onAction(option.obj)}>
                    {#each keys as key}
                        <span>
                            {@html option.html[key]}
                        </span>
                    {/each}
                    <span class='hotkeys'>
                        {option.obj.actionDescr.hotkeysString()}
                    </span>
                </li>
            {/each}
        </ul>
        <ul class='list-unavailable'>
            {#each notAvailiableOptions as option}
                <li>
                    {#each keys as key}
                        <span>
                            {@html option.html[key]}
                        </span>
                    {/each}
                    <span class='hotkeys'>
                        {option.obj.actionDescr.hotkeysString()}
                    </span>
                </li>
            {/each}
        </ul>
    </div>
</div>

