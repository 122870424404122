import type { LazyVersioned, ObservableStream, Observer} from "engine-utils-ts";
import { LazyBasic, ScopedLogger } from "engine-utils-ts";
import type { ButtonType } from "../libui/button";
import type { DialogDescription, UiViewSource } from "ui-bindings";


export interface DialogButtonDescription {
    name: string;
    action: () => void;
    style?: ButtonType;
    disabled?: LazyVersioned<boolean>;
}

export class DialogConfigToShow {
    id: string;
    name: string;
    message?: string;
    compact?: boolean;
    buttons: DialogButtonDescription[];
    uiSource?: UiViewSource<any>;
    close: ()=>void;

    constructor(params: {
        id: string,
        name: string,
        message?: string,
        compact?: boolean,
        buttons: DialogButtonDescription[],
        uiSource?: UiViewSource<any>,
        close:()=>void,
    }) {
        this.id = params.id;
        this.name = params.name;
        this.message = params.message;
        this.compact = params.compact;
        this.buttons = params.buttons;
        this.uiSource = params.uiSource;
        this.close = params.close;
    }
}


export class DialogsListener {

    logger: ScopedLogger;

    showDialog: LazyBasic<boolean>; // false, to interop with Smelte.Dialog bound value
    dialogToShow: LazyBasic<DialogConfigToShow | null>; // false, to interop with Smelte.Dialog bound value

    _dialogsQueueToHandle: DialogDescription<any, any>[] = [];

    _streamSubscription: Observer | null = null;

    constructor() {
        this.logger = new ScopedLogger('ui-dialogs-listener');
        this.showDialog = new LazyBasic('showDialog', false);
        this.dialogToShow = new LazyBasic<DialogConfigToShow | null>('dialogToShow', null);
    }

    updateSubscription(stream: ObservableStream<DialogDescription<any, any>>) {
        this._streamSubscription?.dispose();
        this._streamSubscription = stream.subscribe({
            onNext: (dialog) => this._handleIncomingDialog(dialog),
            settings: {immediateMode: true}
        })
    }

    _handleIncomingDialog(dialog: DialogDescription<any, any>) {
        if (dialog.isActedUpon()) {
            this.logger.error('dialog is already acted upon, skip', dialog);
            return;
        }
        this.logger.debug('handling dialog', dialog.name);
        dialog.preventDefaultAction();
        this._dialogsQueueToHandle.push(dialog);
        this.tryGetFromQueue();

    }

    update() {
        if (this.showDialog.poll() == false && this.dialogToShow.poll() != null) {
            this.closeCurrentDialog();
        }
    }

    closeCurrentDialog() {
        this.showDialog.replaceWith(false);
        this.dialogToShow.replaceWith(null);
        this.tryGetFromQueue();
    }

    tryGetFromQueue() {
        if (this.dialogToShow.poll() || this._dialogsQueueToHandle.length == 0) {
            return;
        }
        const dialog = this._dialogsQueueToHandle.shift()!;
        const buttons: DialogButtonDescription[] = [];
        if (dialog.userActions) {
            for (const ua of dialog.userActions) {
                buttons.push({
                    name: ua.name,
                    action: () => {
                        dialog.executeAction(ua);
                        this.closeCurrentDialog();
                    },
                    style: ua.style as ButtonType,
                    disabled: ua.disabled,
                })
            }
        }
        if (dialog.closeAction) {
            buttons.push({
                name: dialog.closeAction,
                action: () => {
                    this.closeCurrentDialog();
                },
            })
        }

        const dialogToShow = new DialogConfigToShow({
            id: dialog.id,
            name: dialog.name,
            message: dialog.message,
            compact: dialog.compact,
            buttons: buttons,
            uiSource: dialog.uiSource,
            close: () => { 
                this.closeCurrentDialog();
                dialog.onClose?.();
            },
        });
        this.dialogToShow.replaceWith(dialogToShow);
        this.showDialog.replaceWith(true);
    }

    dispose() {
        this._streamSubscription?.dispose();
        this.dialogToShow.replaceWith(null);
        this
    }
}

