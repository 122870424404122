import { Failure, Success } from '.';
import { type Result } from './Result';

export class EnumUtils {

	static enumFlagsToString(enumObject: any, flags: number): string {
		let resString = ``;
		for (const k in enumObject) {
			const v = enumObject[k];
			if (flags & v) {
				if (resString.length > 0) {
					resString += '|';
				}
				resString += k;
			}
		}
		return resString;
	}

	static getAllEnumConstsStringValues(enumObject: any): string[] {
		const result: string[] = [];
		for (const key in enumObject) {
			const v = enumObject[key];
			if (typeof v == 'string') {
				result.push(v);
			}			
		}
		if (result.length == 0) {
			throw new Error('could not find values in enum');
		}
		return result;
	}
	static getAllEnumConstsValues(enumObject: any): number[] {
		const result: number[] = [];
		for (const key in enumObject) {
			const n = Number(key);
			if (!isNaN(n) && isFinite(n) && Number.isInteger(n)) {
				result.push(n);
			}
		}
		if (result.length == 0) {
			throw new Error('could not find values in enum');
		}
		return result;
	}
	static getAllEnumConstsNames(enumObject: any): string[] {
		const result: string[] = [];
		for (const key in enumObject) {
			const n = Number(key);
			if (!isNaN(n) && isFinite(n)) {
				const name = enumObject[n];
				if (typeof name == 'string') {
					result.push(name);
				}
			}
		}
		if (result.length == 0) {
			throw new Error('could not find keys in enum');
		}
		return result;
	}
	static enumNumberValueFromString(enumObject: any, key: string, defaulValue?: number): Result<number> {
		const n = enumObject[key];
        if (typeof n !== 'number') {
            if (defaulValue !== undefined) {
                return new Success(defaulValue);
            }
            return new Failure({msg: 'unexepected enum string ' + key});
		}
		return new Success(n);
	}
	static enumStringFromKey(enumObject: any, key: number, defaulValue: string): Success<string>;
	static enumStringFromKey(enumObject: any, key: number, defaulValue?: string): Result<string>;
	static enumStringFromKey(enumObject: any, key: number, defaulValue?: string): Result<string> {
		const s = enumObject[key];
        if (typeof s !== 'string') {
            if (defaulValue !== undefined) {
                return new Success(defaulValue);
            }
			return new Failure({msg: 'unexepected enum constant ' + key});
		}
		return new Success(s);
    }

	static isValidEnumValue(enumObject: any, value: number): boolean {
		return EnumUtils.getAllEnumConstsValues(enumObject).includes(value);
	}

	static nextEnumValue(enumObject: any, value: number): number {
		const allValues = EnumUtils.getAllEnumConstsValues(enumObject);
		const index = allValues.indexOf(value);
		if (index >= 0 && index < allValues.length - 1) {
			return allValues[index + 1];
		}
		return allValues[0];
	}

	static prevEnumValue(enumObject: any, value: number): number {
		const allValues = EnumUtils.getAllEnumConstsValues(enumObject);
		const index = allValues.indexOf(value);
		if (index >= 1) {
			return allValues[index - 1];
		}
		if (index == 0) {
			return allValues[allValues.length - 1];
		}
		return allValues[0];
	}
}
