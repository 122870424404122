import type { UndoStack } from 'engine-utils-ts';
import type { EntityId } from 'verdata-ts';

import { EntitiesBase } from './collections/EntitiesBase';
import { ImageFormat } from './schema/image-format';
import type { AssetRef } from './AssetRef';

export { ImageFormat };

export class BimRawImage {
    constructor(
		public width: number,
		public height: number,
		public format: ImageFormat,
		public rawData: Uint8Array
    ) {
    }
}

export class BimImage {
    constructor(
      public assetRef: AssetRef | null = null,
      public inline: BimRawImage | null = null,
	) {
    }
}

export enum BimImageType {
	Default = 0,
}

export type IdBimImage = EntityId<BimImageType>;

export class BimImages extends EntitiesBase<BimImage, BimImageType> {

	constructor(undoStack?: UndoStack) {
		super({
			identifier: 'bim-images',
			idsType: BimImageType.Default,
			undoStack,
			T_Constructor: BimImage
		});
	}

	checkForErrors(t: BimImage, errors: string[]): void {
        if (t.assetRef == null && t.inline == null) {
            errors.push('image should have inline data or asset ref');
        }
	}

}
