import type { ScopedLogger } from 'engine-utils-ts';
import posthog from 'posthog-js'
import type { TelemetryEvent } from 'ui-bindings';
import type { UserAuthData } from './auth'

export function configurePosthog(logger: ScopedLogger, auth: UserAuthData): PostHogWrapper | undefined {
    posthog.init(
        'phc_EHWPVzGlptFl6OTdeK6SVfdC9k4SlZYhuqA9AkorJjO',
        {
            api_host: 'https://app.posthog.com',
            autocapture: false,
            disable_cookie: true,
            persistence: 'localStorage',
            persistence_name: 'posthog-storage',
            //disable_session_recording: true,
        },
    );
    posthog.identify(
        auth.id,
        {
            company: auth.company,
            email: auth.email,
            name: auth.name,
        }
    )
    // posthog.onFeatureFlags()


    // instance.capture(
    //     'test-event',
    //     {}
    // )

    // instance.capture(
    //     'test-event-name',
    //     {
    //         test_prop: 1,
    //         test_prop_2: {
    //             test_prop_nested_3: "nested-prop-3",
    //             test_prop_nested_4: "nested-prop-4",

    //         },
    //     },
    // );

    return new PostHogWrapper(logger);
}

export class PostHogWrapper {

    featureFlags: string[] = [];

    constructor(
        private readonly logger: ScopedLogger,
    ) {
        posthog.onFeatureFlags((featureFlags) => {
            this.featureFlags = featureFlags;
        });
    }

    reset() {
        posthog.reset();
    }

    captureEvent(te: TelemetryEvent) {
        let hasEnabledFeatureFlags = true;
        if (te.featureFlags.length > 0) {
            hasEnabledFeatureFlags = false;
            for (const ff of te.featureFlags) {
                if (this.featureFlags.includes(ff)) {
                    hasEnabledFeatureFlags = true;
                    break;
                }
            }
        }
        if (!hasEnabledFeatureFlags) {
            this.logger.info('skipping event', te.name, 'because of feature flags', te.featureFlags, this.featureFlags);
            return;
        }
        posthog.capture(
            te.name,
            {
                ...te.metadata,
            },
            {
                send_instantly: true,
            }
        );
    }

    captureRaw(eventName: string, metadata?: Record<string, unknown>) {
        posthog.capture(
            eventName,
            metadata,
        );
    }
}
