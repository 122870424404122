import type { BimOperations } from '../create/bim-operations';
import { Nodes } from './nodes';
import { Connections } from './connections';
import type { TotalCondDetails } from '../utils';

export class Circuit {
    nodes = new Nodes();
    connections = new Connections();
    private _groupingRoutes?: TotalCondDetails;
    get groupingRoutes() {
        if (!this._groupingRoutes)
            throw new Error('Grouping routes were not initialized.');
        return this._groupingRoutes;
    }
    set groupingRoutes(val: TotalCondDetails) {
        this._groupingRoutes = val;
    }
    constructor(readonly bimOps: BimOperations) {}
}
