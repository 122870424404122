import { AssetRef, BimImageType, ImageFormat, SceneInstance, type Bim, type TrackerPilesCollection } from "bim-ts";
import { CompressionUtils, Failure, LazyDerived, LazyVersioned, ObservableObject, PngConverter, PollablePromise, ProjectNetworkClient, ScopedLogger, Yield } from "engine-utils-ts";
import { CustomUiBuilder,   PUI_CustomPropertyNode, PUI_GroupNode, PUI_Lazy, ExportedFileDescription, FileExporter,  FileExporterContext } from "ui-bindings";
import { getExportProjectName } from "../CommonExportSettings";
import { DxfSerializer } from "./DxfSerializer";
import { ProjectInfo, VerdataSyncerType } from "src";
import { DxfService } from "./DxfService";
import { DxfExportContext } from './DxfExportContext';


export class DxfExporterSettings {
    layout: boolean;
    pile_position_plan: boolean;
    terrain: boolean;
    wireing: boolean;
    images: boolean;
    singleLineDiagram: boolean;
    export_only_selected: boolean;

    constructor(args: Partial<DxfExporterSettings> = {}) {
        this.layout = args.layout ?? true;
        this.pile_position_plan = args.pile_position_plan ?? false;
        this.terrain = args.terrain ?? false;
        this.wireing = args.wireing ?? false;
        this.images = args.images ?? false;
        this.singleLineDiagram = args.singleLineDiagram ?? false;
        this.export_only_selected = args.export_only_selected ?? false;
    }

    static withSettings(settings: Partial<DxfExporterSettings> = {}) {
        return new DxfExporterSettings({ layout: false, ...settings});
    }
}

class DxfExporterUiBuilder extends CustomUiBuilder<DxfExporterSettings> {
    buildUi(
        obsSettings: ObservableObject<DxfExporterSettings>,
        submitAction: () => void,
    ){
        const ui =  LazyDerived.new0(
            "DxfExporterSettingsUi",
            null,
            () => {
                const root = new PUI_GroupNode({name: "", sortChildren: false});

                root.addMaybeChild(new PUI_CustomPropertyNode({
                    name: "DXF export",
                    value: null,
                    type_ident: "dxf-export",
                    onChange: () => {},
                    context: new DxfExportContext(obsSettings, submitAction)
                }));

                return root;
            }
        );

        return new PUI_Lazy(ui);
    }
    
}

export class DxfFileExporter implements FileExporter<DxfExporterSettings> {
    constructor(
        readonly bim: Bim,
        readonly pilesCollection: TrackerPilesCollection,
        readonly projectInfo: LazyVersioned<ProjectInfo | undefined>,
        readonly projectVerdataSyncer: VerdataSyncerType
    ) {}

    initialSettings() {
        return {
            defaultValue: new DxfExporterSettings(),
            uiBuilderParams: new DxfExporterUiBuilder(),
        };
    }

    *startExport(
        settings: DxfExporterSettings,
        context: FileExporterContext
    ): Generator<Yield, ExportedFileDescription[]> {
        
        const name = getdxfFileName(this.projectVerdataSyncer,  settings, this.projectInfo.poll() )

        const service = new DxfService(
            this.bim,
            settings,
            context,
            this.pilesCollection,
            name
        );
        yield* service.generateDxfModel();

        const serilizedNode = DxfSerializer.serialize(service.getDxfModel());
        const encoded = new TextEncoder().encode(serilizedNode);

        const files: ExportedFileDescription[] = [];
        if (settings.images) {
            const imagesInstances = this.bim.instances.peekByTypeIdent("image");

            for (const [instId, instImg] of imagesInstances) {
                const imgs: BimImageType[] = [];
                SceneInstance.imageIdsReferences(instImg, imgs);

                for (const imgId of imgs) {
                    if (context.network) {
                        const result = yield* PollablePromise.generatorWaitFor(
                            toImage(
                                imgId,
                                this.bim,
                                context.network,
                                context.logger
                            )
                        );

                        if (result instanceof Failure) {
                            context.logger.error(result.errorMsg());
                            continue;
                        }
                        const [img, rawImage] = result.value;

                        if (rawImage) {
                            files.push({
                                name: instImg.name,
                                file: rawImage.file,
                                extension: "",
                            });
                            yield Yield.Asap;
                        }
                    }
                }
            }
        } else {
            files.push({ extension: "dxf", name, file: encoded });
        }

        return files;
    }
}

function getdxfFileName(
    projectVerdataSyncer: VerdataSyncerType,
    settings: DxfExporterSettings,
    projectInfo?: ProjectInfo
): string {
    let name =
        getExportProjectName(projectVerdataSyncer, projectInfo) || "Solar farm";

    const additionalName: string[] = [];
    if (settings.layout) {
        additionalName.push("Layout");
    }
    if (settings.pile_position_plan) {
        additionalName.push("Piles");
    }
    if (settings.terrain) {
        additionalName.push("Terrain");
    }
    if (settings.wireing) {
        additionalName.push("Wiring");
    }
    if (settings.images) {
        additionalName.push("Images");
    }
    if (settings.singleLineDiagram) {
        additionalName.push("SLD");
    }
    name += `_${additionalName.join(" & ")}`;

    return name;
}



type RawImage = { name: string, file: Uint8Array };
export interface AssetImage {
    id?: number | string;
    ref: AssetRef;
}

async function toImage(id: BimImageType, bim: Bim, network: ProjectNetworkClient, logger: ScopedLogger): Promise<[AssetImage | undefined, RawImage | undefined]> {
    const img = bim.bimImages.peekById(id);
    const ref = img?.assetRef;
    const inLine = img?.inline;
    if (ref) {
        let image: RawImage | undefined;
        try {
            const response = await network.get(ref.path);
            const byteArray = await response.arrayBuffer();
            image = {
                name: ref.path,
                file: new Uint8Array(byteArray),
            };
        } catch (error) {
            logger.error('image ' + ref.path, error);
        }
        return [
            {
                id,
                ref: {
                    path: ref.path,
                    inAssetId: ref.inAssetId ?? null,
                },
            },
            image,
        ];
    }
    if (inLine && inLine.format === ImageFormat.RgbaByte) {
        const perPixelBytes = inLine.rawData.length / (inLine.width * inLine.height);

        const imageColors = inLine.rawData.slice();
        for (let i = 0; i < inLine.height; ++i) {

            const row = inLine.rawData.subarray(
                (inLine.height - i - 1) * inLine.width * perPixelBytes,
                (inLine.height - i - 0) * inLine.width * perPixelBytes
            );
            imageColors.set(row, i * inLine.width * perPixelBytes);
        }
        const rawImage = PngConverter.encode([imageColors.buffer], inLine.width, inLine.height, 0, [], false);
        const name = `${id}.png`;
        return [
            {
                id,
                ref: {
                    path: name,
                    inAssetId:null
                },
            },
            {
                name,
                file: new Uint8Array(rawImage),
            },
        ];
    }
    logger.warn(`image with id [${id}] not converted:`, img);
    return [undefined, undefined];
}



