import type { ObservableObject } from "engine-utils-ts";
import type { UiBindings } from 'ui-bindings';
import { PUI_PropertyNodeBool, PUI_PropertyNodeNumber } from "ui-bindings";

export class SnappingSettings {

	snapToObjects: boolean = false;
	snapToGridStep: number = 0;
}


export function snappingUiBindings(uiBindings: UiBindings, state: ObservableObject<SnappingSettings>) {
    const snappingSettings = state.poll();
    uiBindings.addSetting({
        name: ['Edit', 'Preferences', 'Snap to grid'],
        priority: 12,
		property: new PUI_PropertyNodeNumber({
			name: "Snap to grid",
            value: snappingSettings.snapToGridStep,
			minMax: [0, 100],
			unit: 'm',
            onChange: (newValue) => {
                state.applyPatch({
					patch: { snapToGridStep: newValue }
				})
            },
		}),
    });
	uiBindings.addSetting({
        name: ['Edit', 'Preferences', 'Snap to objects'],
        priority: 13,
		property: new PUI_PropertyNodeBool({
			name: "Snap to objects",
            value: snappingSettings.snapToObjects,
            onChange: (newValue) => {
                state.applyPatch({
					patch: { snapToObjects: newValue }
				})
            },
		}),
    });
}
