<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.5 4H4.59501C5.62252 4 6.48285 4.77859 6.58509 5.80099L7.72489 17.199C7.82713 18.2214 8.68746 19 9.71496 19H14.285C15.3125 19 16.1729 18.2214 16.2751 17.199L17.4149 5.80099C17.5172 4.77859 18.3775 4 19.405 4H22.5V6L19.405 6L18.2652 17.398C18.0607 19.4428 16.34 21 14.285 21H9.71496C7.65996 21 5.93929 19.4428 5.73481 17.398L4.59501 6H1.5V4Z"
        fill="currentColor"
    />
    <path
        d="M11.25 16C11.25 16.6904 10.6904 17.25 10 17.25C9.30964 17.25 8.75 16.6904 8.75 16C8.75 15.3096 9.30964 14.75 10 14.75C10.6904 14.75 11.25 15.3096 11.25 16Z"
        fill="currentColor"
    />
    <path
        d="M11.25 11C11.25 11.6904 10.6904 12.25 10 12.25C9.30964 12.25 8.75 11.6904 8.75 11C8.75 10.3096 9.30964 9.75 10 9.75C10.6904 9.75 11.25 10.3096 11.25 11Z"
        fill="currentColor"
    />
    <path
        d="M11.25 6C11.25 6.69036 10.6904 7.25 10 7.25C9.30964 7.25 8.75 6.69036 8.75 6C8.75 5.30964 9.30964 4.75 10 4.75C10.6904 4.75 11.25 5.30964 11.25 6Z"
        fill="currentColor"
    />
    <path
        d="M15.25 16C15.25 16.6904 14.6904 17.25 14 17.25C13.3096 17.25 12.75 16.6904 12.75 16C12.75 15.3096 13.3096 14.75 14 14.75C14.6904 14.75 15.25 15.3096 15.25 16Z"
        fill="currentColor"
    />
    <path
        d="M15.25 11C15.25 11.6904 14.6904 12.25 14 12.25C13.3096 12.25 12.75 11.6904 12.75 11C12.75 10.3096 13.3096 9.75 14 9.75C14.6904 9.75 15.25 10.3096 15.25 11Z"
        fill="currentColor"
    />
    <path
        d="M15.25 6C15.25 6.69036 14.6904 7.25 14 7.25C13.3096 7.25 12.75 6.69036 12.75 6C12.75 5.30964 13.3096 4.75 14 4.75C14.6904 4.75 15.25 5.30964 15.25 6Z"
        fill="currentColor"
    />
</svg>
