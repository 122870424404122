import type { Camera } from "../3rdParty/three";
import type { GpuResources } from "../composer/GpuResources";
import type { BudgetLimitForPass, RenderResult } from "./RenderPass";
import type { RTIdent, RenderTargets } from "./RenderTargets";
import type { ShaderBase } from "../shaders/ShaderBase";
import type { LazyVersioned } from "engine-utils-ts";
import { PostProcPass } from "./PostProcPass";
import { KrMaterial } from "../materials/KrMaterial";


export class AccumMixInPass extends PostProcPass {
    
    mixInPower: LazyVersioned<number>;

    constructor(
        identifier: string,
        inputs: [RTIdent, RTIdent],
		output: RTIdent,
        mixInPower: LazyVersioned<number>,
    ) {
        super(
            identifier,
            inputs,
            output,
            KrMaterial.newPostMat(AccumBlendShader),
            [
                [inputs[0], 'tDiffuse'],
                [inputs[1], 'tEdges'],
            ],
            {
                'opacity': mixInPower
            }
        );
        this.mixInPower = mixInPower;
    }

    render(
        camera: Readonly<Camera>,
        gpuRes: GpuResources,
        renderTargets: RenderTargets,
        budget: BudgetLimitForPass,
        anyInputsIncomplete: boolean
    ): RenderResult {
        const mixInPower = this.mixInPower.poll();
        if (!(mixInPower > 0)) {
            return {finished: true, unitsRenderered: 0};
        }
        if (mixInPower < 1 && anyInputsIncomplete) {
            return {finished: true, unitsRenderered: 0};
        }
        return super.render(camera, gpuRes, renderTargets, budget, anyInputsIncomplete);
    }
}





const AccumBlendShader: Readonly<ShaderBase> = {
    name: 'accum-blend',
	params: {
		transparent: true,
	},
    uniforms: {
		tDiffuse:   { value: null },
		tEdges:   { value: null },
        opacity: {value: 1},
    },

	vertexShader: `

		uniform sampler2D tDiffuse;
		varying vec2 resolution;

		void main() {
			ivec2 size = textureSize(tDiffuse, 0);
			resolution = 1.0 / vec2(float(size.x), float(size.y));
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

		}

	`,

	fragmentShader: `

		uniform sampler2D tDiffuse;
		uniform sampler2D tEdges;
		uniform float opacity;
		varying vec2 resolution;

		void main() {

			vec4 diffuse = texture2D( tDiffuse,  gl_FragCoord.xy  * resolution );
			float edge1 = texture2D( tEdges,  (gl_FragCoord.xy + vec2(-0.4, 0.4)) * resolution ).r;
			float edge2 = texture2D( tEdges,  (gl_FragCoord.xy + vec2(0.4, -0.4)) * resolution ).r;

            float edge = (edge1 + edge2) * 0.5 * 0.6;

            vec4 color = mix(diffuse, vec4(0., 0., 0., 1.0), edge);

            color.a *= opacity;
			gl_FragColor = color;
		}

	`
}
