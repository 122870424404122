import { Vector2 } from "math-ts";
import { createCombinerBoxObject2D as createCombinerBoxObject2DBase } from "src/sld/templates/combiner-box";
import { wireWidth } from "src/sld/templates/config";
import type { EquipmentDescription } from "src/sld/types";
import { Anchor2D, VectorPrimitivePath, VectorPrimitivePathDescription, VectorPrimitiveText, createTextLabelWithPointer } from "vector-graphic";

export function createCombinerBoxObject2D(
    desc: EquipmentDescription,
    params?: { index?: string }
): Anchor2D {
    const combinerBox = createCombinerBoxObject2DBase()
    // draw cb label
    {
        const aabb = combinerBox.aabb;
        const combinerBoxLabelPrimitive = new VectorPrimitiveText({
            x: aabb.centerX(),
            y: aabb.max.y + 20,
            fontSize: 20,
            text: desc.text + (params?.index === undefined ? '' : ` #${params.index}`),
            anchor: 'middle',
            verticalAlignment: 'hanging',
        })
        combinerBox.addPrimitive(combinerBoxLabelPrimitive);
    }
    // draw output wire
    const cbRightEdge = new Vector2(combinerBox.aabb.max.x, combinerBox.aabb.centerY())
    const connectionPt = new Vector2(cbRightEdge.x + 40, cbRightEdge.y);
    combinerBox.addPrimitiveAndExpandAabb(new VectorPrimitivePath({
        paths: [new VectorPrimitivePathDescription([cbRightEdge, connectionPt])],
        strokeWidth: wireWidth,
    }))

    // add out connection label
    if (desc.connectionToParent) {
        const label = createTextLabelWithPointer({
            text: desc.connectionToParent.text,
            fontSize: 20,
            offset: 50,
            pointerWidth: 2,
            angle: Math.PI / 6 * 2,
        })
        label.position.copy(connectionPt);
        label.updateMatrix()
        combinerBox.addAndExpandAabb(label);
    }

    const anchor = new Anchor2D();
    anchor.addAndExpandAabb(combinerBox);

    anchor.point.copy(connectionPt)

    return anchor;
}
