import type { Bim, IdBimGeo, IdBimScene, SceneInstance, SceneInstancePatch, GaugePack, WireGauge
} from 'bim-ts';
import {
    BasicAnalyticalRepresentation, BimProperty, PolylineGeometry,
    PropertiesCollection, DC_CNSTS, SceneInstances
} from 'bim-ts';
import type { TasksRunner, LongTask} from 'engine-utils-ts';
import { ScopedLogger, Yield, IterUtils, LogLevel } from 'engine-utils-ts';
import type { Vector2} from 'math-ts';
import { Matrix4, Transform, Vector3 } from 'math-ts';
import type { UiBindings} from 'ui-bindings';
import { NotificationDescription, NotificationType } from 'ui-bindings';

import { calcLength, convertFeetToMetersNum, isEqual, mergeEdges } from '../LayoutUtils';
import { notificationSource } from '../Notifications';
import { PointsSet } from '../PointsSet';
import { SplitEdges } from '../SplitEdges';


enum WireType {
    MV = "MV",
    AC_Feeder = "AC_Feeder",
    DC_Feeder = "DC_Feeder",
    WHIP = "Whip",
}

const lvTypesMap = new Map<DC_CNSTS.ConductorType, WireType>([
    [DC_CNSTS.ConductorType.AcFeeder, WireType.AC_Feeder],
    [DC_CNSTS.ConductorType.DcFeeder, WireType.DC_Feeder],
    [DC_CNSTS.ConductorType.Whip, WireType.WHIP],
])

export interface TrenchConfig {
    substationIds: IdBimScene[];
    mvSpaceBetweenWiresInch: number;
    mvDepthInch: number;
    dcSpaceBetweenWiresInch: number;
    dcDepthInch: number;
    acSpaceBetweenWiresInch: number;
    acDepthInch: number;
    whipSpaceBetweenWiresInch: number;
    whipDepthInch: number;
    lowVoltageCables: [number, CableDef][];
}

interface TrenchInput {
    substationId: IdBimScene;
    mvSpaceBetweenWiresInch: number;
    mvDepthInch: number;
    acSpaceBetweenWiresInch: number;
    acDepthInch: number;
    dcSpaceBetweenWiresInch: number;
    dcDepthInch: number;
    whipSpaceBetweenWiresInch: number;
    whipDepthInch: number;
    lowVoltageCables: [number, CableDef][];
}

export async function placeTrenchesOnLayout(
    rawInput: TrenchConfig,
    bim: Bim,
    tasksRunner: TasksRunner,
    uiBindings: UiBindings,
    gaugePack: GaugePack,
) {
    await arrangeTrenches({
        input: {
            mvSpaceBetweenWiresInch: rawInput.mvSpaceBetweenWiresInch,
            mvDepthInch: rawInput.mvDepthInch,
            dcSpaceBetweenWiresInch: rawInput.dcSpaceBetweenWiresInch,
            dcDepthInch: rawInput.dcDepthInch,
            acSpaceBetweenWiresInch: rawInput.acSpaceBetweenWiresInch,
            acDepthInch: rawInput.acDepthInch,
            whipSpaceBetweenWiresInch: rawInput.whipSpaceBetweenWiresInch,
            whipDepthInch: rawInput.whipDepthInch,
            substationId: rawInput.substationIds[0],
            lowVoltageCables: rawInput.lowVoltageCables,
        },
        bim,
        tasksRunner,
        uiBindings,
        gaugePack,
    });
}

async function arrangeTrenches(args: {
    input: TrenchInput;
    bim: Bim;
    tasksRunner: TasksRunner;
    uiBindings: UiBindings;
    gaugePack: GaugePack;
}) {
    const TIMEOUT = 300_000;
    const logger = new ScopedLogger('trench service');
    try {
        deleteTrenchesByParents([args.input.substationId], new Set(), args.bim);
        const task: LongTask<any> = args.tasksRunner.newLongTask<any>({
            defaultGenerator: _arrangeTrenches({ ...args, logger }),
            taskTimeoutMs: TIMEOUT,
        });

        args.uiBindings.addNotification(
            NotificationDescription.newWithTask({
                source: notificationSource,
                key: 'generateTranches',
                taskDescription: { task },
                type: NotificationType.Info,
                addToNotificationsLog: true
            })
        );
        await task.asPromise();
    } catch (e) {
        console.error(e);
    }
}


export function deleteTrenchesByParents(parents: IdBimScene[], excludeIds:Set<IdBimScene>, bim: Bim) {
    const idsToDelete: IdBimScene[] = [];
    const typesToDelete = new Set<string>(['trench', 'lv-wire', 'debug-wire']);
    for (const parent of parents) {
        for (const child of bim.instances.spatialHierarchy.iteratorOfChildrenOf(parent)) {
            if(excludeIds.has(child)){
                continue;
            }
            const inst = bim.instances.peekById(child);
            if(!inst){
                continue;
            }
            if(typesToDelete.has(inst.type_identifier)){
                idsToDelete.push(child);
            }
        }
    }
    bim.instances.delete(idsToDelete);
}

function* generateTrenches(args: {
    input: TrenchInput;
    bim: Bim;
    tasksRunner: TasksRunner;
    uiBindings: UiBindings;
    logger: ScopedLogger;
    gaugePack: GaugePack;
}) {
    yield Yield.Asap;
    // console.time('check getWires');
    
    const wires = yield* getWires(args.bim, args.input, args.logger, args.gaugePack);
    // console.timeEnd('check getWires');

    yield Yield.Asap;
    // console.time('check merge trenches');

    const trenchesDef = yield* mergeWiresInTrench(wires, args.input, args.logger);
    // console.timeEnd('check merge trenches');

    yield Yield.Asap;
    return {trenchesDef, wires};
}

function* getWires(
    bim:Bim,
    input: TrenchInput,
    logger: ScopedLogger,
    gaugePack: GaugePack,
){
    const wires: WireData[] = [];
    const children = new Set<IdBimScene>();
    const points: Vector2[] = [];

    for (const id of bim.instances.spatialHierarchy.gatherIdsWithSubtreesOf({ids: [input.substationId]})) {
        children.add(id);
    }
    const wiresTypes = new Set(['wire', 'lv-wire']);
    const trenchTypes = new Set([
        DC_CNSTS.ConductorType.AcFeeder, 
        DC_CNSTS.ConductorType.DcFeeder, 
        DC_CNSTS.ConductorType.Whip
    ]);
    for (const [id, inst] of bim.instances.peekByIds(children)) {
        if(!wiresTypes.has(inst.type_identifier)){
            continue;
        }
        const geomId = inst.representationAnalytical?.geometryId;
        if(!geomId){
            logger.batchedWarn("Geometry Id not found for wires:", [id, inst]);
            continue;
        }
        const geom = bim.polylineGeometries.peekById(geomId);
        if(!geom){
            logger.batchedWarn("Geometry not found for wires:", [id, inst]);
            continue;
        }
        const isMvWire = inst.type_identifier === 'wire';
        const lvType = inst.properties.get('specification | type')?.asText() as DC_CNSTS.ConductorType;
        if(!isMvWire && !lvType){
            logger.batchedWarn("lv wire doesn't have type", [id, inst]);
            continue;
        }
        if(!isMvWire && !trenchTypes.has(lvType)){
            continue;
        }

        const gaugeAssetId = isMvWire 
            ? inst.properties.get('commercial | asset')?.asNumber() 
            : inst.properties.get('specification | asset')?.asNumber();
        
        if(!gaugeAssetId){
            logger.batchedWarn("Gauge asset Id not found for wires:", [gaugeAssetId, id, inst]);
            continue;
        }
        const gauge = gaugePack.gauges.get(gaugeAssetId);
        if (!gauge){
            logger.batchedWarn("Gauge not found for:", [gaugeAssetId, id, inst]);
            continue;
        }
        const diameterIn = gauge.diameter.as('inch');
        if(diameterIn === 0){
            logger.batchedWarn('Cross section not found:', [id, inst]);
        }

        const polyline = Vector3.arrayFromFlatArray(geom.points3d).map(p => p.applyMatrix4(inst.worldMatrix).xy());
        if(calcLength(polyline) === 0){
            logger.batchedWarn('Invalid polyline', [id, inst]);
        }
        if (!isMvWire) {
            IterUtils.extendArray(points, polyline);
        }
        const type = isMvWire ? WireType.MV : lvTypesMap.get(lvType)!;

        const wire: WireData = {
            id,
            type,
            sizeInch: diameterIn,
            polyline,
            gauge: gauge.getFullName(),
            lv_type: isMvWire ? undefined : lvType,
        };
        wires.push(wire);
    }
    
    const splitter = new SplitEdges(points);
    for (let i = 0; i < wires.length; i++) {
        const wire = wires[i];
        if(wire.type != WireType.MV){
            wire.polyline = splitter.Split(wire.polyline);
            if (i % 500) {
                yield Yield.Asap;
            }
        }
    }

    return wires;
}

function* mergeWiresInTrench(wires: WireData[], input: TrenchInput, logger: ScopedLogger) {
    const edges: WireEdge[] = [];
    const pointsSet = new PointsSet<Vector2>();
    for (const wire of wires) {
        for (let i = 0; i < wire.polyline.length-1; i++) {
            const edge: WireEdge = {
                fromId: wire.id,
                fst: pointsSet.Idx(wire.polyline[i]),
                snd: pointsSet.Idx(wire.polyline[i+1]),
                sizeInch: wire.sizeInch,
                type: wire.type,
            };
            edges.push(edge);
        }
    }
    yield Yield.Asap;
    logger.debug("points set", pointsSet);
    logger.debug("edges", edges);
    const trenchesMap = new Map<string, TrenchEdge>();
    for (const edge of edges) {
        const hash = getEdgeHash(edge);
        let trenchEdge = trenchesMap.get(hash);
        if (!trenchEdge) {
            const sizesInch = [edge.sizeInch];
            trenchesMap.set(hash, {
                fromIds: [edge.fromId],
                fst: edge.fst,
                snd: edge.snd,
                sizesInch,
                widthMeter: calcTrenchWidth(sizesInch, edge.type, input),
                depthMeter: calcTrenchDepth(sizesInch, edge.type, input),
                type: edge.type,
            });
        } else {
            trenchEdge.fromIds.push(edge.fromId);
            trenchEdge.sizesInch.push(edge.sizeInch);
            trenchEdge.widthMeter = calcTrenchWidth(trenchEdge.sizesInch, edge.type, input);
            trenchEdge.depthMeter = calcTrenchDepth(trenchEdge.sizesInch, edge.type, input);
        }
    }
    yield Yield.Asap;
    logger.debug("trenchesMap", trenchesMap);

    const points = pointsSet.ToArray();
    function getPoint(index:number){
        const point = points[index];
        return new Vector3(point.x, point.y, 0);
    }
    const trenches = mergeEdges<TrenchEdge, TrenchDef>(
        Array.from(trenchesMap.values()),
        isEqualEdge,
        (poly, edge) => {
            return {
                polyline: poly.map(getPoint),
                sizesInch: edge.sizesInch,
                depth: edge.depthMeter,
                width: edge.widthMeter,
                formIds: edge.fromIds,
                type: edge.type
            };
        }
    );

    // const debugTrenches = Array.from(trenchesMap.values()).map(edge=>({
    //     polyline: [getPoint(edge.fst), getPoint(edge.snd)],
    //     sizesInch: edge.sizesInch,
    //     depth: edge.depthMeter,
    //     width: edge.widthMeter,
    //     formIds: edge.fromIds,
    //     type: edge.type
    // }));

    logger.debug("trenches", trenches);

    return trenches;
}

function calcTrenchWidth(sizesInch: number[], type: WireType, input: TrenchInput) {
    let totalSize = 0;
    for (const size of sizesInch) {
        totalSize += size;
    }
    const diameter = totalSize / sizesInch.length;

    let width = 0;
    switch (type) {
        case WireType.MV:
            width =
                (sizesInch.length - 1) * input.mvSpaceBetweenWiresInch +
                totalSize * 2 +
                12;
            break;
        case WireType.DC_Feeder:
            const dcGroup = 2;
            const dcGroups = Math.ceil(sizesInch.length/dcGroup);
            width = (dcGroups - 1) * input.dcSpaceBetweenWiresInch + dcGroups*diameter*dcGroup + 6;
            break;
        case WireType.AC_Feeder:
            const acGroup = 3;
            const acGroups = Math.ceil(sizesInch.length/acGroup);
            width = (acGroups - 1) * input.acSpaceBetweenWiresInch + diameter*acGroup*acGroups + 6;
            break;
        case WireType.WHIP:
            const whipGroup = 8;
            const whipGroups = Math.ceil(sizesInch.length / whipGroup);
            width =
                (whipGroups - 1) * input.whipSpaceBetweenWiresInch +
                (whipGroups * diameter * whipGroup) / 2 + 3;
            break;
        default:
            break;
    }

    const result = width < 24 ? 24 : Math.ceil(width / 12) * 12;

    return convertFeetToMetersNum(result / 12);
}

function calcTrenchDepth(sizesInch: number[], type: WireType, input: TrenchInput) {
    const maxSizeInch = Math.max(...sizesInch);
    let depthInch = 0;
    switch (type) {
        case WireType.MV:
            depthInch = maxSizeInch * 2 + input.mvDepthInch;
            break;
        case WireType.DC_Feeder:
            depthInch = maxSizeInch * 2 + input.dcDepthInch;
            break;
        case WireType.AC_Feeder:
            depthInch = maxSizeInch * 2 + input.acDepthInch;
            break;
        case WireType.WHIP:
            depthInch = maxSizeInch * 2 + input.whipDepthInch;
            break;
        default:
            break;
    }
    const depthMeter = convertFeetToMetersNum(Math.ceil(depthInch) / 12);
    return depthMeter;
}

function getEdgeHash(edge: WireEdge){
    const fstHash = Math.min(edge.fst, edge.snd);
    const sndHash = Math.max(edge.fst, edge.snd);
    const type = edge.type === WireType.MV ? 'mv' : 'lv';
    return `${fstHash}${sndHash}${type}`;
}

function isEqualEdge(
    pos: number,
    edge: TrenchEdge,
    otherEdge: TrenchEdge
): boolean {
    const isEqualPos =
        isEqual(pos, otherEdge.fst) || isEqual(pos, otherEdge.snd);
    const isEqualProp =
        isEqual(edge.widthMeter, otherEdge.widthMeter) &&
        isEqual(edge.depthMeter, otherEdge.depthMeter);
    const isEqualType = edge.type === otherEdge.type;
    return isEqualPos && isEqualProp && isEqualType;
}


function* _arrangeTrenches(args:{input: TrenchInput, bim: Bim, tasksRunner:TasksRunner, uiBindings: UiBindings, logger: ScopedLogger, gaugePack: GaugePack}) {
    const bim = args.bim;
    args.logger.debug(`trenches generator is starting`);
    const {trenchesDef, wires} = yield*  generateTrenches(args);
    const debug = false;
    if(debug){
        yield* _arrangeDebugWiring(wires, args.input.substationId, bim, args.logger);
    }
    const sceneInstancesToAlloc: [IdBimScene, Partial<SceneInstance>][] = [];
    const polylinesToAlloc: [IdBimGeo, Partial<PolylineGeometry>][] = [];

    let reusableMatrix1 = new Matrix4();

    function addClonedSceneInstanceWith(trench: TrenchDef): IdBimScene {
        const newId = bim.instances.idsProvider.reserveNewId();

        const newGeoId = bim.polylineGeometries.reserveNewId();
		const trenchInstance = bim.instances.archetypes.newDefaultInstanceForArchetype('trench');

        const mainParentMatrix = bim.instances.peekById(args.input.substationId)?.worldMatrix;
        const parentGlobalPos = mainParentMatrix ? Transform.fromMatrix(mainParentMatrix).position : new Vector3();
        const objectWorldMatrix = new Matrix4().setPositionV(new Vector3(trench.polyline[0].x, trench.polyline[0].y, parentGlobalPos.z));
        
        const inverseWorldMatrix = reusableMatrix1.getInverse(objectWorldMatrix);
        const polylineGeometry = PolylineGeometry
            .newWithAutoIdsFiltered(trench.polyline.map(p => p.clone().applyMatrix4(inverseWorldMatrix)), trench.width);
        polylinesToAlloc.push([newGeoId, polylineGeometry]);

        trenchInstance.localTransform = SceneInstances.getLocalTransformRelativeToParentMatrix(
            mainParentMatrix,
            objectWorldMatrix,
        );

        trenchInstance.spatialParentId = args.input.substationId;

        const props = [
            BimProperty.NewShared({path: ['dimensions', 'width'], value: trench.width*100, unit: 'cm'}),
            BimProperty.NewShared({path: ['dimensions', 'depth'], value: trench.depth*100, unit: 'cm'}),
            BimProperty.NewShared({path: ['wiring', 'wire_type'], value: trench.type}),
            BimProperty.NewShared({path: ['wiring', 'wires_count'], value: trench.formIds.length }),
        ];
        trenchInstance.representationAnalytical = new BasicAnalyticalRepresentation(
            newGeoId
        );

		trenchInstance.properties.applyPatch(props.map(p => [p._mergedPath, p]));

        sceneInstancesToAlloc.push([newId, trenchInstance]);

        return newId;
    }

    for (const obj of trenchesDef) {
        addClonedSceneInstanceWith(obj);
    }
    yield Yield.NextFrame;

    const ALLOCATION_SIZE = polylinesToAlloc.length;
    args.logger.debug(`count of trenches to allocate - ${polylinesToAlloc.length}`);
    for (const objectsChunk of IterUtils.splitArrayIntoChunks(
        polylinesToAlloc,
        ALLOCATION_SIZE
    )) {
        bim.polylineGeometries.allocate(objectsChunk);
        yield Yield.NextFrame;
        yield Yield.NextFrame;
    }
    args.logger.debug(
        `count of instances to allocate - ${sceneInstancesToAlloc.length}`
    );
    for (const objectsChunk of IterUtils.splitArrayIntoChunks(
        sceneInstancesToAlloc,
        ALLOCATION_SIZE
    )) {
        bim.instances.allocate(objectsChunk);
        yield Yield.NextFrame;
        yield Yield.NextFrame;
    }

    args.logger.debug("allocating trenches finished");
}


function* _arrangeDebugWiring(input: WireData[], substation: IdBimScene, bim: Bim, log: ScopedLogger) {
    const logger = log.newScope('lv wires', LogLevel.Default);
    logger.debug(`wiring solver is starting`);

    const sceneInstancesToAlloc: [IdBimScene, Partial<SceneInstance>][] = [];
    const polylinesToAlloc: [IdBimGeo, Partial<PolylineGeometry>][] = [];
    const patches: [IdBimScene, SceneInstancePatch][] = [];

    let reusableMatrix1 = new Matrix4();
    function addClonedSceneInstanceWith(wire: WireData): IdBimScene {

        const newGeoId = bim.polylineGeometries.reserveNewId();
        const points = wire.polyline.map(p=>new Vector3(p.x, p.y, 0));
        const objectWorldMatrix = new Matrix4().setPositionV(points[0]);
        const inverseWorldMatrix = reusableMatrix1.getInverse(objectWorldMatrix);
        const polylineGeometry: Partial<PolylineGeometry> = PolylineGeometry.newWithAutoIds(
            points.map(v=>v.applyMatrix4(inverseWorldMatrix)), 0.5);
        const newId = bim.instances.idsProvider.reserveNewId();
        polylinesToAlloc.push([newGeoId, polylineGeometry]);

        const mainParentMatrix = bim.instances.peekById(substation)?.worldMatrix;

        const localTransform = SceneInstances.getLocalTransformRelativeToParentMatrix(mainParentMatrix, objectWorldMatrix);

        const spatialParentId = substation;

        const properties = new PropertiesCollection([
            BimProperty.NewShared({path: ['debugData', 'gauge'], value: wire.gauge}),
            BimProperty.NewShared({path: ['debugData', 'lv-cable-type'], value: wire.lv_type}),
            BimProperty.NewShared({path: ['debugData', 'sizeInch'], value: wire.sizeInch, unit: 'in'}),
            BimProperty.NewShared({path: ['debugData', 'type'], value: wire.type.toString()}),
        ]);

        const state: Partial<SceneInstance> = {
            type_identifier: "debug-wire",
            representationAnalytical: new BasicAnalyticalRepresentation(
                newGeoId
            ),
            spatialParentId,
            localTransform,
            properties,
        };

        sceneInstancesToAlloc.push([newId, state]);

        return newId;
    }

    for (const obj of input) {
        if(obj.type !== WireType.MV){
            addClonedSceneInstanceWith(obj);
        }
    }

    yield Yield.NextFrame;

    const ALLOCATION_SIZE = 1000;
    logger.debug(`count of wires to allocate - ${polylinesToAlloc.length}`);
    for (const objectsChunk of IterUtils.splitArrayIntoChunks(
        polylinesToAlloc,
        ALLOCATION_SIZE
    )) {
        bim.polylineGeometries.allocate(objectsChunk);
        yield Yield.NextFrame;
        yield Yield.NextFrame;
    }
    logger.debug(
        `count of instances to allocate - ${sceneInstancesToAlloc.length}`
    );
    for (const objectsChunk of IterUtils.splitArrayIntoChunks(
        sceneInstancesToAlloc,
        ALLOCATION_SIZE
    )) {
        bim.instances.allocate(objectsChunk);
        yield Yield.NextFrame;
        yield Yield.NextFrame;
    }

    bim.instances.applyPatches(patches);

    logger.debug("allocating wires finished");
}



interface TrenchDef {
    polyline: Vector3[];
    width: number;
    depth: number;
    sizesInch: number[];
    formIds: IdBimScene[];
    type: WireType;
}

interface TrenchEdge {
    fst: number;
    snd: number;
    sizesInch:number[];
    widthMeter: number;
    depthMeter:number;
    fromIds: IdBimScene[];
    type: WireType;
}


interface WireEdge {
    fst: number;
    snd: number;
    sizeInch:number;
    fromId: IdBimScene;
    type: WireType;
}

interface WireData{
    id: number;
    polyline:Vector2[];
    sizeInch: number;
    type: WireType;
    gauge?:string;
    lv_type?:string;
}

export interface CableDef {
    isBuried: boolean | null;
    type: DC_CNSTS.ConductorType | null,
    gauge: number | null,
    gaugeName: string | null,
    pairingType: string | null,
    constraints: DC_CNSTS.Constraints[] | null,
    temperature: number;
    parent: IdBimScene;
    losses: number;
    drop: number;
    polyline: Vector2[] | null;
    length: number;
    maxAmp: number;
    resistivity: number;
    gaugeSpec: WireGauge | null;
    operatingCurrent: number;
    maxCurrent: number;
    power: number;
    dcResistivity: number;
    acResistivity: number;
    reactance: number;

}
