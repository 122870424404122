// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class PropertyBoolValue implements flatbuffers.IUnpackableObject<PropertyBoolValueT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):PropertyBoolValue {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsPropertyBoolValue(bb:flatbuffers.ByteBuffer, obj?:PropertyBoolValue):PropertyBoolValue {
  return (obj || new PropertyBoolValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsPropertyBoolValue(bb:flatbuffers.ByteBuffer, obj?:PropertyBoolValue):PropertyBoolValue {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new PropertyBoolValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

static startPropertyBoolValue(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addValue(builder:flatbuffers.Builder, value:boolean) {
  builder.addFieldInt8(0, +value, +false);
}

static endPropertyBoolValue(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createPropertyBoolValue(builder:flatbuffers.Builder, value:boolean):flatbuffers.Offset {
  PropertyBoolValue.startPropertyBoolValue(builder);
  PropertyBoolValue.addValue(builder, value);
  return PropertyBoolValue.endPropertyBoolValue(builder);
}

unpack(): PropertyBoolValueT {
  return new PropertyBoolValueT(
    this.value()
  );
}


unpackTo(_o: PropertyBoolValueT): void {
  _o.value = this.value();
}
}

export class PropertyBoolValueT implements flatbuffers.IGeneratedObject {
constructor(
  public value: boolean = false
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return PropertyBoolValue.createPropertyBoolValue(builder,
    this.value
  );
}
}
