import type { LazyVersioned, ResultAsync } from "engine-utils-ts";
import { LazyDerivedAsync } from "engine-utils-ts";
import type { LCOECalculationResult } from "..";
import { CostHierarchy } from "../CostHierarchy";
import type { LcoeSettings, LcoeSettingsProvider } from "../LcoeSettings";
import { NumberProperty } from "src";
import { KrMath } from "math-ts";

export function createEconomicHierarchy(
    lcoe: LazyVersioned<ResultAsync<LCOECalculationResult>>,
    defaultSettings: LcoeSettings,
    provider: LcoeSettingsProvider,
) {
    const economics = LazyDerivedAsync.new2(
        "economics",
        [],
        [lcoe, provider.lcoeSettings],
        function* ([lcoe, settings]) {
            const hierarchy = new CostHierarchy();
            hierarchy.add({
                description: { value: "Project lifecycle", indent: 3 },
                lcoeInput: {
                    input: {
                        value:
                            settings.lcoe.projectUsefulLifeYears?.value ??
                            defaultSettings.lcoe.projectUsefulLifeYears
                                ?.value ??
                            0,
                        update: (value) => {
                            if (!value) {
                                provider.updateLcoeSettings(
                                    (state) =>
                                        (state.lcoe.projectUsefulLifeYears =
                                            null),
                                );
                            } else {
                                value = KrMath.clamp(value, 1, 100);
                                provider.updateLcoeSettings(
                                    (state) =>
                                        (state.lcoe.projectUsefulLifeYears =
                                            NumberProperty.new({ value })),
                                );
                            }
                        },
                        flags: {
                            editable: true,
                            overriden: !!settings.lcoe.projectUsefulLifeYears,
                        },
                    },
                    resultingUnit: "Years",
                },
            });
            hierarchy.addRoot({
                description: { value: "Economics" },
            });
            return hierarchy;
        },
    );
    return economics;
}
