// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { VerDataTask } from '../wire/ver-data-task.js';


export class VerDataState {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):VerDataState {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsVerDataState(bb:flatbuffers.ByteBuffer, obj?:VerDataState):VerDataState {
  return (obj || new VerDataState()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsVerDataState(bb:flatbuffers.ByteBuffer, obj?:VerDataState):VerDataState {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new VerDataState()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

activeTask(obj?:VerDataTask):VerDataTask|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new VerDataTask()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

tasksPending(index: number, obj?:VerDataTask):VerDataTask|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new VerDataTask()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

tasksPendingLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startVerDataState(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addActiveTask(builder:flatbuffers.Builder, activeTaskOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, activeTaskOffset, 0);
}

static addTasksPending(builder:flatbuffers.Builder, tasksPendingOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, tasksPendingOffset, 0);
}

static createTasksPendingVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startTasksPendingVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endVerDataState(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishVerDataStateBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset);
}

static finishSizePrefixedVerDataStateBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, undefined, true);
}

static createVerDataState(builder:flatbuffers.Builder, activeTaskOffset:flatbuffers.Offset, tasksPendingOffset:flatbuffers.Offset):flatbuffers.Offset {
  VerDataState.startVerDataState(builder);
  VerDataState.addActiveTask(builder, activeTaskOffset);
  VerDataState.addTasksPending(builder, tasksPendingOffset);
  return VerDataState.endVerDataState(builder);
}
}
