<script lang="ts">
    import { getContext } from "svelte";
    import type { AuthHub } from "../auth";
    import { ButtonComponent, Button, ButtonType } from "../libui/button";

    let authHub = getContext<AuthHub>("authHub");
</script>

{#if $authHub.authData}
    <div class="topbar-container">
        <slot />
        <div class="user-info">
            <span class="mdc-typography--body1 text-main-medium">
                {$authHub.authData?.email ?? $authHub.authData?.name ?? "User"}
                <!-- {#if $authHub.authData.company}
                    <b>at</b> {$authHub.authData.company}
                {/if} -->
            </span>
            <ButtonComponent
                compact={true}
                desc={new Button(
                    "Logout",
                    ButtonType.Text,
                    () => authHub.logout(),
                    false,
                    "Logout"
                )}
            />
        </div>
    </div>
{/if}

<style lang="scss">
    .topbar-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
    }
    .user-info {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-left: auto;
    }
</style>
