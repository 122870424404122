// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { ErrorMsgT } from '../wire/error-msg.js';
import { MsgType, unionToMsgType } from '../wire/msg-type.js';
import type { ObjectStateT } from '../wire/object-state.js';
import type { ObjectStateQueryT } from '../wire/object-state-query.js';
import type { ObjectUpdateT } from '../wire/object-update.js';
import type { UpdateAcceptedT } from '../wire/update-accepted.js';


export class SyncMessage implements flatbuffers.IUnpackableObject<SyncMessageT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SyncMessage {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSyncMessage(bb:flatbuffers.ByteBuffer, obj?:SyncMessage):SyncMessage {
  return (obj || new SyncMessage()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSyncMessage(bb:flatbuffers.ByteBuffer, obj?:SyncMessage):SyncMessage {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SyncMessage()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

payloadType():MsgType {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : MsgType.NONE;
}

payload<T extends flatbuffers.Table>(obj:any):any|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
}

static startSyncMessage(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addPayloadType(builder:flatbuffers.Builder, payloadType:MsgType) {
  builder.addFieldInt8(0, payloadType, MsgType.NONE);
}

static addPayload(builder:flatbuffers.Builder, payloadOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, payloadOffset, 0);
}

static endSyncMessage(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishSyncMessageBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset);
}

static finishSizePrefixedSyncMessageBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, undefined, true);
}

static createSyncMessage(builder:flatbuffers.Builder, payloadType:MsgType, payloadOffset:flatbuffers.Offset):flatbuffers.Offset {
  SyncMessage.startSyncMessage(builder);
  SyncMessage.addPayloadType(builder, payloadType);
  SyncMessage.addPayload(builder, payloadOffset);
  return SyncMessage.endSyncMessage(builder);
}

unpack(): SyncMessageT {
  return new SyncMessageT(
    this.payloadType(),
    (() => {
      const temp = unionToMsgType(this.payloadType(), this.payload.bind(this));
      if(temp === null) { return null; }
      return temp.unpack()
  })()
  );
}


unpackTo(_o: SyncMessageT): void {
  _o.payloadType = this.payloadType();
  _o.payload = (() => {
      const temp = unionToMsgType(this.payloadType(), this.payload.bind(this));
      if(temp === null) { return null; }
      return temp.unpack()
  })();
}
}

export class SyncMessageT implements flatbuffers.IGeneratedObject {
constructor(
  public payloadType: MsgType = MsgType.NONE,
  public payload: ErrorMsgT|ObjectStateQueryT|ObjectStateT|ObjectUpdateT|UpdateAcceptedT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const payload = builder.createObjectOffset(this.payload);

  return SyncMessage.createSyncMessage(builder,
    this.payloadType,
    payload
  );
}
}
