<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M7.44637 11.437H8.65364V7.33335H7.44637V11.437ZM8.00054 6.24134C8.19656 6.24134 8.36069 6.17668 8.49294 6.04737C8.62519 5.91806 8.69132 5.75783 8.69132 5.56669C8.69132 5.35903 8.6251 5.18912 8.49265 5.05695C8.36021 4.92479 8.19609 4.8587 8.0003 4.8587C7.79745 4.8587 7.63146 4.92479 7.50235 5.05695C7.37324 5.18912 7.30869 5.35746 7.30869 5.56197C7.30869 5.75705 7.37499 5.91904 7.5076 6.04795C7.6402 6.17688 7.80451 6.24134 8.00054 6.24134Z"
        fill="currentColor"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 2.35006H5C3.53645 2.35006 2.35 3.53651 2.35 5.00006V11.0001C2.35 12.4636 3.53645 13.6501 5 13.6501H11C12.4636 13.6501 13.65 12.4636 13.65 11.0001V5.00006C13.65 3.53651 12.4636 2.35006 11 2.35006ZM5 1.00006C2.79086 1.00006 1 2.79092 1 5.00006V11.0001C1 13.2092 2.79086 15.0001 5 15.0001H11C13.2091 15.0001 15 13.2092 15 11.0001V5.00006C15 2.79092 13.2091 1.00006 11 1.00006H5Z"
        fill="currentColor"
    />
</svg>