import { derived } from 'svelte/store';
import {
    ProjectStatus,
    type PaginatedProjects,
    type ProjectApi,
    type ProjectSearchParams
} from '../projects';
import { BindedStore } from '../utils';
import { DefaultMap, ProjectNetworkClient, ScopedLogger } from "engine-utils-ts";
import { type ProjectHistory, VerdataApi } from "verdata-ts";
import { navigate } from 'svelte-routing';

type ProjectFilterType = null | ProjectStatus;

export enum Mode {
    Standard,
    Compact,
}
export class DashboardStore {
    search: string = '';
    paginated?: PaginatedProjects;
    filter: ProjectFilterType = ProjectStatus.Active;
    versions = new Map();
    mode: Mode = Mode.Standard;
}

export class Dashboard extends BindedStore<DashboardStore> {
    readonly _logger: ScopedLogger;
    private _icons: DefaultMap<number, Record<number, string>> = new DefaultMap(() => ({}));
    _shareActionInProgress = false;

    _defaultSearchConfig: ProjectSearchParams = {
        size: 200,
        archived: false,
        offset: 0,
    };
    constructor(readonly _projectApi: ProjectApi, readonly _baseNetwork: ProjectNetworkClient) {
        super(new DashboardStore);
        const mode = localStorage.getItem('dashboardMode') as keyof typeof Mode | null;
        if (mode) {
            this.update(s => (s.mode = Mode[mode], s));
        }
        this.search = this.search.bind(this);

        derived(this, s => s.search)
            .subscribe(this.search);
        let init = true;
        derived(this, s => s.filter)
            .subscribe(() => {
                if(!init) {
                    this.search();
                } else {
                    init = false;
                }
            });
        this._logger = new ScopedLogger('dashboard');
    }

    async remove(projectId: number) {
        try {
            await this._projectApi.archive(projectId);
            await this.search();
        } catch (e) {
            console.error(e);
        }
    }

    async archive(projectId: number) {
        try {
            await this._projectApi.updateStatus(projectId, ProjectStatus.Archived);
            await this.search();
        } catch (e) {
            console.error(e);
        }
    }

    async reserveNew() {
        try {
            const project = await this._projectApi.reserveProject();
            navigate(project.id.toString());
        } catch (e) {
            console.error(e);
        }
    }

    async search() {
        const s = this._storeValue;
        try {
            const result = await this._projectApi.search({
                ...this._defaultSearchConfig,
                ...this.paramsByFilter,
                search: s.search,
            });
            this.update(s => (s.paginated = result, s));
        } catch (e) {
            console.error(e);
        }
    }

    async share(id: number) {
        if (this._shareActionInProgress)
            return;
        try {
            this._shareActionInProgress = true;
            await this._projectApi.share(id);
            await this.search()
        } catch (e) {
            console.error(e);
        } finally {
            this._shareActionInProgress = false;
        }
    }

    async unshare(id: number) {
        if (this._shareActionInProgress)
            return;
        try {
            this._shareActionInProgress = true;
            await this._projectApi.unshare(id);
            await this.search()
        } catch (e) {
            console.error(e);
        } finally {
            this._shareActionInProgress = false;
        }
    }

    async trySearchMore() {
        const s = this._storeValue;
        if (!s.paginated) {
            return await this.search();
        }
        try {
            const result = await this._projectApi.search({
                ...this._defaultSearchConfig,
                ...this.paramsByFilter,
                search: s.search,
                offset: s.paginated.items.length,
            });
            const mergedItems = [
                ...s.paginated.items,
                ...result.items
            ];
            this.update(s => {
                s.paginated = {
                    items: mergedItems,
                    more: result.more,
                }
                return s;
            });
        } catch (e) {
            console.error(e);
        }
    }

    get paramsByFilter() {
        const filter = this._storeValue.filter;
        if (filter) {
            return {
                status: filter
            };
        }
        return {};
    }

    updateFilter(type: ProjectFilterType) {
        this.update((s) => ((s.filter = type), s));
    }

    async getProjectVersion(projectId: number) {
        const s = this._storeValue;
        if (!s.versions.has(projectId)) {
            const network = new ProjectNetworkClient({
                ...this._baseNetwork.config,
                basePath: "api/verdata/" + projectId,
            });

            VerdataApi.getProjectHistory(network)
                .then((history: ProjectHistory) => {
                    const lastVersion = history.versions.pop();
                    this.update((s) => ((s.versions.set(projectId, lastVersion)), s));
                }).catch(() => {
                    this._logger.info("No version history for project:", projectId);
                });
        }
    }

    setMode(mode: Mode) {
        localStorage.setItem('dashboardMode', Mode[mode]);
        this.update(s => (s.mode = mode, s));
    }

    async getScreenshot(projectId: number, versionId: number) {
        const iconCache = this._icons.getOrCreate(projectId);
        if (!iconCache[versionId]) {
            const response = await this._baseNetwork.get(`api/verdata/${projectId}/proj-ver-thumb/${versionId}.png`);
            const binaryData = await response.arrayBuffer();
            const base64 = btoa(String.fromCharCode(...new Uint8Array(binaryData)));
            if (base64) {
                iconCache[versionId] = `data:image/png;base64,${base64}`;
            }
        }
        return iconCache[versionId];
    }
}
