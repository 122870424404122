import { MappedCollectionBasic, RecalcScheduleType } from 'engine-utils-ts';
import type { Aabb } from 'math-ts';
import type { EntityId } from 'verdata-ts';
import type { EntitiesBaseConstructorParams } from '../collections/EntitiesBase';
import { EntitiesBase } from '../collections/EntitiesBase';
import type { BimGeometryType } from '../schema/bim-geometry-type';
import type { IdBimGeo } from './BimGeometries';


export interface BimGeometryBase {
	calcAabb(): Aabb;
	checkForErrors(errors: string[]): void;
}

export abstract class BimGeometriesBase<G extends BimGeometryBase> extends EntitiesBase<G, BimGeometryType> {

	readonly aabbs: MappedCollectionBasic<Aabb, IdBimGeo, BimGeometryBase>;

    checkForErrors(t: G, validationErrors: string[]): void {
        t.checkForErrors(validationErrors);
    }

	constructor(args: EntitiesBaseConstructorParams<G, BimGeometryType>) {
		super(args);

		this.aabbs = new MappedCollectionBasic<Aabb, EntityId<BimGeometryType>, BimGeometryBase>({
			identifier: 'aabbs',
			logger: this.logger,
			dataSource: this,
			schedule: RecalcScheduleType.OnDemand,
			mapFn: g => g.calcAabb(),
		})
	}

	dispose() {
		this.aabbs.dispose();
		super.dispose();
	}
}
