import type { CatalogPropertyValue } from "../../properties/ObjectRefProps";
import { AssetsProperty, CatalogItemsReferenceProperty } from "../../properties/ObjectRefProps";
import { PrimitivePropertyBase } from "../../properties/PrimitiveProps";
import type { PropertyGroup } from "../../properties/PropertyGroup";
import type { PropertyBase } from "../../properties/Props";
import { ConfigUtils } from "../ConfigUtils";

export function fromAssetToCatalogProperty(
    asset: PropertyBase
): CatalogItemsReferenceProperty {
    if (!(asset instanceof AssetsProperty)) {
        console.error('expected asset property for legacy migration', asset);
        return new CatalogItemsReferenceProperty({types: ["asset"], value: []});
    }
    return new CatalogItemsReferenceProperty({
        ...asset,
        value: asset.value.map<CatalogPropertyValue>((v) => ({
            id: v,
            type: 'asset',
        })),
        assetsTypes: asset.types.slice(),
        types: ["asset"],
    });
}

export function removeDescriptionMigration(properties: PropertyGroup){
    ConfigUtils.traverseByProperties(properties, (prop, path, source) => {
        if(prop instanceof PrimitivePropertyBase && prop.description !== undefined){
            const copyProp = removeDescription(prop);
            const key = path[path.length-1];
            //@ts-ignore
            if(!(source[key] instanceof PrimitivePropertyBase)){
                console.error('unexpected type in', source, key);
                throw new Error(`unexpected type for key - ${key}`);
            }
            //@ts-ignore
            source[key] = copyProp;                    
        }
    });
}

function removeDescription<T extends PrimitivePropertyBase<any>>(
    prop: T
): T {
    let proto = Object.getPrototypeOf(prop);
    let copyProp = Object.assign(Object.create(proto), prop);
    copyProp.description = undefined;
    return copyProp;
}