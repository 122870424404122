import { ObjectUtils } from 'engine-utils-ts';

import type { BimPropertyData } from '../bimDescriptions/BimProperty';
import type { AnySolverObjectInput, SolverLegacyPropsInput } from './ObjectsSelector';
import { ObjectsSelector } from './ObjectsSelector';
import type { SolverInstancePatchResult } from './ReactiveSolverBase';
import { ReactiveSolverBase } from './ReactiveSolverBase';
import type { AnySharedDependenciesInput, SharedGlobalsInput, GlobalArgsSelector } from './RuntimeGlobals';

export type SolverPropsInObjectFn<Args extends SolverLegacyPropsInput, SD extends GlobalArgsSelector> = (
    args: Args, globalArgs: Readonly<SharedGlobalsInput<SD>>
) => BimPropertyData[];

export class SolverPropsInObject<Args extends SolverLegacyPropsInput, GAS extends GlobalArgsSelector> extends ReactiveSolverBase {

    readonly defaultArgs: SolverLegacyPropsInput;
    readonly solverFunction: SolverPropsInObjectFn<any, any>;

    constructor(params: {
        solverIdentifier: string,
        objectsIdentifier: string,
        objectsDefaultArgs: Args,
        globalArgsSelector?: GAS,
        cache?: boolean,
        solverFunction: SolverPropsInObjectFn<Args, GAS>,
    }){
        super({
            identifier: params.solverIdentifier,
            globalArgsSelector: params.globalArgsSelector,
            objFilter: new ObjectsSelector(
                params.objectsIdentifier,
                {
                    legacyProps: params.objectsDefaultArgs
                },
            ),
            cache: params.cache
        });
        this.defaultArgs = ObjectUtils.deepFreeze(params.objectsDefaultArgs);
        this.solverFunction = params.solverFunction;
    }

    compute(
        currObjectInput: Readonly<AnySolverObjectInput>,
        sharedArgs?: Readonly<AnySharedDependenciesInput>,
    ): SolverInstancePatchResult {
        const props = this.solverFunction(
            currObjectInput.legacyProps,
            sharedArgs as Readonly<SharedGlobalsInput<GAS>>
        );
        return {
            legacyProps: props
        }
    }
}

