<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M9.43081 10.8831L8.54663 13.5533L6.40092 6.40092L13.5533 8.54663L10.8831 9.43081L10.8446 9.44356L10.8732 9.47222L13.9646 12.5636L12.5636 13.9646L9.47222 10.8732L9.44356 10.8446L9.43081 10.8831ZM6.11122 10.7003C4.96944 10.6328 4.00547 10.1877 3.21807 9.36454C2.42259 8.53291 2.025 7.53307 2.025 6.36364C2.025 5.15807 2.44666 4.13415 3.2904 3.2904C4.13415 2.44666 5.15807 2.025 6.36364 2.025C7.53307 2.025 8.53291 2.42259 9.36454 3.21807C10.1876 4.00538 10.6328 4.97521 10.7003 6.12885L9.60385 5.78076C9.46744 5.00599 9.10176 4.36037 8.50729 3.84475C7.90857 3.32545 7.19358 3.06591 6.36364 3.06591C5.44799 3.06591 4.66888 3.38667 4.02778 4.02778C3.38667 4.66888 3.06591 5.44799 3.06591 6.36364C3.06591 7.18156 3.32551 7.89344 3.84467 8.4981C4.36021 9.09856 5.00578 9.46731 5.78052 9.6038L6.11122 10.7003Z"
        fill="#9C27B0"
        stroke="#9C27B0"
        stroke-width="0.05"
    />
</svg>
