import type { LazyVersioned } from "engine-utils-ts";
import { LazyDerivedAsync } from "engine-utils-ts";
import type { Bim } from "src";
import { NumberProperty } from "src";
import { CostHierarchy, fillModelBasedTopLevelCategory } from "..";
import type { CostsConfigProvider, EstimateCost} from "src/cost-model/capital";
import { DesignAndEngineeringTopCategoryName, createEmptyCostComponentsNonNullable } from "src/cost-model/capital";

export function create_DesignAndEngineering(
    bim: Bim,
    costs: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>,
) {
    const unitsMapper = bim.unitsMapper;

    const result = LazyDerivedAsync.new2<
        CostHierarchy,
        EstimateCost[],
        NumberProperty
    >(
        'PCS',
        [unitsMapper],
        [costs.allEstimateCosts, totalDC],
        function* ([estimates, totalDC]) {
            const hierarchy = new CostHierarchy();

            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'Modules Design & Engineering' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.001 });

                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'modules-design-and-engineering-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'PCS Design & Engineering' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.004 });

                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'pcs-design-engineering-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'Structural Design & Engineering' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.006 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'structural-design-engineering-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'Electrical Design & Engineering' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.007 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'electrical-design-engineering-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'Civil Design & Engineering' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.009 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'civil-design-engineering-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'Other Engineering and Testing' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.001 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'other-engineering-and-testing-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            // create root category
            {
                const root = hierarchy.addRoot({ description: { value: DesignAndEngineeringTopCategoryName } })
                hierarchy.categoryWithSceneInstances.add(root[0])
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.028 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    hierarchy,
                    'design-and-engineering-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC,
                );
            }

            return hierarchy;
        }
    )

    return result;
}
