<script lang="ts">
    import { getContext } from "svelte";
    import { Button, ButtonComponent, ButtonType } from "../../../libui/button";
    import flash from "../../flash";
    import { UiBindings } from "ui-bindings";
    import { openBoundariesHelp } from "src/layout/HelpDialog";

    export let name: string;
    export let value: string;
    export let errorMessage: string | undefined = undefined;

    let div: HTMLElement;
    let preValue = value;
    $: if (preValue != value && div) {
        preValue = value;
        flash(div);
    }

    const uiBindings = getContext<UiBindings>("uiBindings");
    const openGuideButton = new Button(
        "Check our quick guide on boundaries",
        ButtonType.Text,
        () => {
            openBoundariesHelp(uiBindings);
        },
        false,
        "Info",
    );
</script>

<div class="ui-config-property property-row">
    <div bind:this={div} class="property-name text-main-medium">
        <div class="property-name-label mdc-typography--body1">
            {name}
        </div>
    </div>

    <div class="property-value">
        <div
            class="mdc-typography--body1 read-only calculated"
            class:text-danger={errorMessage}
        >
            {value}
        </div>
        {#if errorMessage}
            <div class="mdc-typography--body2 read-only calculated multiline">
                {@html errorMessage}
            </div>
            <ButtonComponent compact={true} desc={openGuideButton} />
        {/if}
    </div>
</div>

<style lang="scss">
</style>
