import { Color } from '../3rdParty/three';
import { LegacyLogger } from 'engine-utils-ts'
import { lead,iron,silver,calcium,aluminum,chrome,copper,nickel,brass,zinc,steel,tin,bronze,foil,brick,masonry,block,thermalite,fiberglass,polyester,acrylic,polymer,polythene,plastic,neoprene,caulking,polypropylene,plastisol,vinyl,polycarbonate,polyisocyanurate,epoxy,sealer,resin,rubber,silicon,asphalt,latex,bitum,nitrile,geotextile,geosynthetic,geomembrane,geocomposite,geocell,hoggin,sand,soil,clay,gravel,slag,stone,marble,granite,concrete,cast_in_situ,cast_in_place,cement_screed,precast,slab,aquapanel,cement,panel,wood,cedar,pine,redwood,fir,yew,juniper,spruce,lumber,hardwood,hickory,maple,teak,balsa,mahogany,birch,oak,alder,walnut,cherry,cork,veneer,plywood,grass,vegetation,render,stucco,plaster,gypsum,mastic,metal,adhesive,glue,sealant,primer,board,blockboard,matchboard,beadboard,baseboard,softboard,windowboard,weatherboard,corkboard,plasterboard,chipboard,particleboard,hardboard,parquet,rubble,paving,cobblestone,laminate,paint,linoleum,membrane,fiber,sash,carbon,tile,ceramic,wool,insulation,batt,glass,light,air,glazing, galvanized,heavy, siding,elevator,button,window,floor,ceiling,wall,pipe,door,roof,roofing,table,frame,kitchen,flooring,shingles,slate, PVC, timber, finish, tower, /*painted,glossy,matte,powder_coating,light_easy,chimney,fence,porcelain,carpet,trim,yellow,blue,golden,white,grey,black,chocolate,red,purple*/ } from './Keywords';
import Utils from '../utils/Utils';


class MaterialsCategory {
	templates: MatTemplate[];
	keywords: string[][];
	metaKeywords: string[][];
	constructor(templates: MatTemplate[], keywords: string[][], metaKeywords:string[][]) {
		for (const t of templates) {
			LegacyLogger.assert(t instanceof MatTemplate, 'mattemplate type check', t)
		}
		for (const k of keywords) {
			LegacyLogger.assert(k instanceof Array, 'keywords array type check', k)
		}
		for (const k of metaKeywords) {
			LegacyLogger.assert(k instanceof Array, 'metaKeywords array type check', k)
		}
		this.templates = templates;
		this.keywords = keywords;
		this.metaKeywords = metaKeywords;
	}
}

export class MatTemplate {
	readonly texName: string;
	readonly roughness: number;
	readonly metalness: number;
	readonly tiling: number;
	readonly color: Readonly<Color>;
	readonly keywords: string[][];

	constructor(texName:string, roughness:number, metalness:number, tiling:number, color:number, keywords: string[][]) {
		this.texName = texName;
		this.roughness = roughness;
		this.metalness = metalness;
		this.tiling = tiling; // if tiling is 0 - transparent
		this.color = Object.freeze(new Color(color));
		this.keywords = keywords;
	}

	isTransparent() {
		return this.tiling === 0;
	}
}


const _asphalt 				= new MatTemplate( 'asphalt', 				0.8, 0.1, 1.0, 0x3F3B38,  [asphalt]);
const _birch 				= new MatTemplate( 'birch', 				0.5, 0.1, 1.0, 0xB59B60,  [birch]);
const _bitum 				= new MatTemplate( 'bitum', 				0.5, 0.1, 0.2, 0x3A3A3A, [bitum]);
const _blocks 				= new MatTemplate( 'blocks', 				0.7, 0.1, 1.0, 0xC4C4C5, [block, concrete, thermalite]);
const _brass 				= new MatTemplate( 'brass', 				0.2, 1.0, 1.0, 0xD09735, [brass]);
const _bricks 				= new MatTemplate( 'bricks', 				0.99, 0.1, 1.0, 0x7F4949, [brick]);
const _bronze 				= new MatTemplate( 'bronze', 				0.4, 1.0, 1.0, 0x745536, [bronze]);
const _brushed_aluminium 	= new MatTemplate( 'brushed_aluminium', 	0.4, 1.0, 1.0, 0x6A6A6A, [aluminum]);
const _cement_panels 		= new MatTemplate( 'cement_panels', 		0.83, 0.1, 1.0, 0xBDBDBD, [cement, panel]);
const _cherry 				= new MatTemplate( 'cherry', 				0.78, 0.1, 1.0, 0x9B4D36, [cherry, redwood, mahogany]);
const _chrome 				= new MatTemplate( 'chrome', 				0.4, 1.0, 1.0, 0x8C8C8C, [chrome]);
const _concrete 			= new MatTemplate( 'concrete', 				0.99, 0.1, 4.0, 0xADAAA5, [concrete]);
const _concrete2 			= new MatTemplate( 'concrete', 				0.95, 0.1, 4.0, 0x939393, [concrete, heavy]);
const _concrete_inplace 	= new MatTemplate( 'concrete_inplace', 		0.98, 0.1, 4.0, 0x767573, [concrete, cast_in_place, cast_in_situ]);
const _concrete_precast 	= new MatTemplate( 'concrete_precast', 		0.97, 0.1, 4.0, 0xBBBABB, [concrete, precast, cement_screed, cement]);
const _copper 				= new MatTemplate( 'copper', 				0.4, 1.0, 1.0, 0xA76520, [copper]);
const _cork 				= new MatTemplate( 'cork', 					0.9, 0.0, 1.0, 0xB49966, [cork, balsa]);
const _fiberglass 			= new MatTemplate( 'fiberglass', 			0.6, 0.0, 1.0, 0xCACACA, [fiberglass, fiber]);
const _geotextile 			= new MatTemplate( 'geotextile', 			0.9, 0.0, 1.0, 0x696060, [geotextile]);
const _grass 				= new MatTemplate( 'grass', 				1.0, 0.0, 1.0, 0x657A3C, [grass, vegetation] );
const _gravel 				= new MatTemplate( 'gravel', 				0.93, 0.1, 1.0, 0x4B4743, [gravel, hoggin]);
const _hardwood 			= new MatTemplate( 'hardwood', 				0.7, 0.0, 1.0, 0x794F39, [hardwood, cedar, oak, walnut, veneer, yew]);
const _marble 				= new MatTemplate( 'marble', 				0.5, 0.0, 1.0, 0xE0E0DF, [marble]);
const _membrane				= new MatTemplate( 'membrane', 				0.8, 0.0, 5.0, 0x404042, [membrane, carbon]);
const _paint 				= new MatTemplate( 'paint', 				0.69, 0.2, 1.0, 0xD4DCCD, [paint]);
const _particleboard		= new MatTemplate( 'particleboard', 		0.85, 0.0, 1.0, 0xC39766, [particleboard, lumber]);
const _parquet 				= new MatTemplate( 'parquet', 				0.7, 0.1, 0.8, 0x7C462E, [parquet]);
const _paving_stones 		= new MatTemplate( 'paving_stones', 		0.7, 0.1, 1.0, 0x6F5448, [paving, cobblestone]);
const _plastic 				= new MatTemplate( 'plastic', 				0.6, 0.0, 1.0, 0x585858, [plastic]);
const _plywood 				= new MatTemplate( 'plywood', 				0.7, 0.1, 1.0, 0xF3DFB8, [plywood, timber, lumber, board]);
const _roof_tiles 			= new MatTemplate( 'roof_tiles', 			0.4, 0.6, 0.5, 0x884646, [tile]);
const _roof_wood 			= new MatTemplate( 'roof_wood', 			0.74, 0.0, 0.5, 0x9D7962, [wood]);
const _rubber 				= new MatTemplate( 'rubber', 				0.94, 0.1, 1.0, 0x2D2D2D, [rubber, membrane]);
const _sand 				= new MatTemplate( 'sand', 					0.8, 0.2, 1.0, 0x705F4B, [sand]);
const _siding_wood 			= new MatTemplate( 'siding_wood', 			0.79, 0.0, 0.5, 0xC2AE95, [siding, wood]);
const _soil					= new MatTemplate( 'soil', 					0.9, 0.0, 1.0, 0x574D41, [soil]);
const _steel 				= new MatTemplate( 'steel', 				0.3, 1.0, 1.0, 0x909090, [steel, carbon]);
const _stone 				= new MatTemplate( 'stone', 				0.63, 0.0, 1.0, 0x66615D, [stone]);
const _stucco 				= new MatTemplate( 'stucco', 				0.86, 0.0, 4.0, 0xC6C1BB, [stucco]);
const _tiles 				= new MatTemplate( 'tiles', 				0.25, 0.2, 1.0, 0xBBBBBB, [tile]);
const _tiles_big 			= new MatTemplate( 'tiles_big', 			0.4, 0.1, 1.0, 0xDBDAD5, [tile]);
const _wool 				= new MatTemplate( 'wool', 					0.9, 0.0, 1.0, 0xBAB7A8, [wool]);
const _wood_planks			= new MatTemplate( 'wood_planks', 			0.85, 0.0, 2.0, 0xB47F53, [wood]);
const _defolt 				= new MatTemplate( '', 						0.6, 0.7, 1.0, 0x999999, [resin, polymer]);
const _glass                = new MatTemplate( '',                      0.1, 1.0, 0, 0xFFFFFF, [glass, glazing]);
const _light 				= new MatTemplate( '', 						1.0, 0.0, 0, 0xFDFF1D, [light] );
const _air 					= new MatTemplate( '', 						1.0, 0.0, 0, 0xFFFFFF, [air]);

const categories = [
	//concrete
	new MaterialsCategory(
		[_concrete, _concrete2, _concrete_inplace, _concrete_precast, _cement_panels],
		[concrete, cement_screed, cement, panel, aquapanel],
		[wall, slab, tower]
	),
	//metals
	new MaterialsCategory(
		[_steel, _brushed_aluminium, _brass, _bronze, _copper, _chrome],
		[lead, iron, silver, calcium, aluminum, chrome, copper, nickel, brass, zinc, steel, tin, bronze, foil],
		[metal, window, pipe, frame, galvanized]
	),
	//masonry
	new MaterialsCategory(
		[_bricks, _blocks],
		[brick, block, thermalite],
		[wall, masonry]
	),
	// plasticy shit
	new MaterialsCategory(
		[_defolt, _membrane, _fiberglass, _plastic, _rubber, _paint],
		[fiberglass, polyester, acrylic, polymer, polythene, plastic, neoprene, caulking, polypropylene, plastisol, vinyl, polycarbonate,polyisocyanurate, epoxy,sealer,resin,rubber,silicon,latex,nitrile,paint,mastic,adhesive,glue,sealant,primer,PVC],
		[polymer, membrane]
	),
	// fabrics?
	new MaterialsCategory(
		[_geotextile, _membrane, _fiberglass, _wool],
		[fiberglass, carbon, geotextile, geosynthetic, geomembrane, geocomposite, geocell, membrane, fiber],
		[polymer, fiberglass, fiber, membrane, geotextile]
	),
	// tiles
	new MaterialsCategory(
		[_tiles, _tiles_big],
		[tile, ceramic],
		[tile, ceramic, kitchen]
	),
	// insulation
	new MaterialsCategory(
		[_wool, _membrane, _steel],
		[wool, batt, membrane],
		[insulation]
	),
	//glass
	new MaterialsCategory(
		[_glass],
		[glass, glazing],
		[window]
	),
	//other transparent
	new MaterialsCategory(
		[_air, _light],
		[air, light],
		[]
	),
	//wood
	new MaterialsCategory(
		[_wood_planks, _birch, _hardwood, _plywood, _particleboard, _cherry, _cork],
		[wood,cedar,pine,redwood,fir,yew,juniper,spruce,lumber,hardwood,hickory,maple,teak,balsa,mahogany,birch,oak,alder,walnut,cherry,cork,veneer,plywood,timber],
		[door, table, frame, finish, sash, window, panel]
	),
	// roofs
	new MaterialsCategory(
		[_bitum, _roof_tiles, _roof_wood, _rubber, _membrane],
		[tile, shingles, slate],
		[roof, roofing, bitum]
	),
	//ground
	new MaterialsCategory(
		[_grass, _gravel, _soil, _sand],
		[rubble, sand, gravel, clay, hoggin, soil],
		[vegetation]
	),
	// plasters
	new MaterialsCategory(
		[_stucco],
		[render, stucco, plaster, gypsum, panel, plasterboard],
		[wall]
	),
	// boards
	new MaterialsCategory(
		[_wood_planks, _particleboard,  _siding_wood, _cork],
		[board, blockboard, matchboard, beadboard, baseboard, softboard, windowboard, weatherboard, corkboard, chipboard, particleboard, hardboard],
		[wall]
	),
	// floors
	new MaterialsCategory(
		[_parquet, _tiles_big, _defolt, _rubber],
		[parquet, laminate, linoleum, resin, rubber],
		[floor, flooring]
	),
	// ceiling
	new MaterialsCategory(
		[_tiles_big],
		[ceiling],
		[]
	),
	// paving
	new MaterialsCategory(
		[_paving_stones],
		[cobblestone],
		[paving]
	),
	// black stuff
	new MaterialsCategory(
		[_asphalt, _bitum, _rubber],
		[asphalt, bitum, rubber],
		[]
	),
	// stones
	new MaterialsCategory(
		[_marble, _stone],
		[stone, marble, granite, slag],
		[]
	),
	// insulation
	new MaterialsCategory(
		[_wool, _steel, _membrane],
		[insulation, wool, batt],
		[]
	),
	// siding
	new MaterialsCategory(
		[_siding_wood, _wood_planks],
		[siding, board],
		[]
	),
	// stuff
	new MaterialsCategory(
		[_defolt, _plastic, _wood_planks, _steel],
		[ window, table, kitchen, elevator],
		[button, elevator]
	),
]

export function similarityToKeyword(token:string, keyword:string[]) {
	let similarity = 0;
	for (const translation of keyword) {
		const minCount = Math.min(translation.length, token.length);
		const maxLength = Math.max(translation.length, token.length);
		if (maxLength - minCount > 3) {
			break;
		}
		let equalChars = 0;
		for (let i = 0; i < minCount; ++i){
			if (translation[i] === token[i]) {
				equalChars += 1;
			}
		}
		similarity = Math.max(equalChars / maxLength, similarity);
	}
	return similarity;
}


export function getMatTemplateFromString(str: string): MatTemplate {
	let matTemplate = _defolt;
	
	if (!str || str.length === 0) {
		return matTemplate;
	}
	
	const scoresByCategory: number[] = [];
	const tokens = str.split(' ').filter(s => s.length >= 3);
	for (const cat of categories) {
		let score1 = 0;
		let score2 = 0;
		for (const token of tokens) {
			for (const kw of cat.metaKeywords) {
				const s = similarityToKeyword(token, kw);
				if (s >= 0.7) {
					score1+=s;
				}
			}
			for (const kw of cat.keywords) {
				const s = similarityToKeyword(token, kw);
				if (s >= 0.7) {
					score2+=s;
				}
			}
		}
		scoresByCategory.push(score1 * 1.5 + score2);
	}

	const maxScore = Math.max(...scoresByCategory);
	if (maxScore > 0) {
		const indexOfMaxScore = scoresByCategory.indexOf(maxScore);
		const cat = categories[indexOfMaxScore];
		matTemplate = cat.templates[0]; // use first category material as default choise
		let bestScoreYet = 0;
		for (const templ of cat.templates) {
			let score = 0;
			for (const kw of templ.keywords) {
				if (kw.some(tr => str.includes(tr))) {
					score += 1;
					continue;
				}
				for (const token of tokens) {
					const s = similarityToKeyword(token, kw);
					if (s > 0.7) {
						score += s;
						break;
					}
				}
			}
			if (score > bestScoreYet) {
				bestScoreYet = score;
				matTemplate = templ;
			}
		}
	}
	return matTemplate;
}
for (const cat of categories) {
	// filter out repeated arrays of keywords via Set
	const templKeywords = Array.from(new Set(cat.templates.map(t => t.keywords).reduce((prev, curr) => prev.concat(curr), [])));
	Utils.extendArray(cat.keywords, templKeywords);

	Object.freeze(cat);
}

