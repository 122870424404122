import type { InverterDescrription } from "../types";
import { createModuleTables2D } from "src/sld/templates/module-table";
import { createInverterObject2D } from "../../templates/inverter";
import { createManyToOneConnection } from "src/sld/templates/connections";
import { Anchor2D } from 'vector-graphic';
import { createOutConnectionWithLabel } from "src/sld/templates/common";
import { Aabb2 } from "math-ts";
import { wireOffset } from "src/sld/templates/config";

export function createInverterObject2DWithChilds(
    inverter: InverterDescrription,
    params?: { index?: string },
) : Anchor2D {
    const children = createModuleTables2D(inverter.moduleTables, 'multi')

    // create base inverter
    const inverterObject = createInverterObject2D(inverter, params)

    // add children wiring
    const wiring = createManyToOneConnection({
        sources: children.points,
        sourceOffset: 200,
        wireOffset: wireOffset,
        targetOffset: 30,
        targetHeight: inverterObject.aabb.height() * 0.4,
    })
    children.addAndExpandAabb(wiring);

    // position inverter
    if (!children.aabb.isEmpty() && wiring.points.length) {
        const aabb = aabbReused.setFromPoints(wiring.points);
        inverterObject.position.set(aabb.centerX() - inverterObject.aabb.min.x, aabb.centerY());
        inverterObject.updateMatrix()
    }
    children.addAndExpandAabb(inverterObject);

    const object = new Anchor2D();
    object.addAndExpandAabb(children);
    object.point.copy(inverterObject.point).applyMatrix3(inverterObject.matrix);

    // add out connection label
    if (inverter.connectionToParent) {
        const label = createOutConnectionWithLabel(inverter.connectionToParent);
        label.position.copy(inverterObject.point);
        label.updateMatrix();
        inverterObject.addAndExpandAabb(label);
        object.point.copy(label.point)
            .applyMatrix3(label.matrix)
            .applyMatrix3(inverterObject.matrix)
    }

    return object;
}

const aabbReused = Aabb2.empty();
