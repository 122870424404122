import { RGBA } from "engine-utils-ts";
import { Mesh } from "../3rdParty/three";
import type { MovementControls } from "../controls/MovementControls";
import { GeometryGenerator } from "../geometries/GeometryGenerator";
import { newBasicMaterial } from "../shaders/BasicShader";
import type { FrustumExt } from "../structs/FrustumExt";
import type { RaySection } from "../structs/RaySection";
import { GizmoBase } from "./GizmoBase";
import { scaleForCamera } from "./GizmoUtils";

export class CameraRotationPointGizmo extends GizmoBase<any> {

    pointDepthTested: Mesh;
    pointNotDepthTested: Mesh;

    constructor(
        readonly movementControls: MovementControls
    ) {
        super();
        const sphGeo = GeometryGenerator.generateSphere(1, 12, 12);
        this.pointDepthTested = new Mesh(
            sphGeo,
            newBasicMaterial(RGBA.new(0, 0, 0, 0.5)),
        );
        this.pointDepthTested.material!.depthTest = true;
        this.pointNotDepthTested = new Mesh(
            sphGeo,
            newBasicMaterial(RGBA.new(0, 0, 0, 0.15)),
        );
        this.pointNotDepthTested.material!.depthTest = false;

        this.add(this.pointDepthTested);
        this.add(this.pointNotDepthTested);
        this.visible = false;
    }

    update(camera:FrustumExt, _ray:RaySection) {
        this.position.copy(this.movementControls.target);
        const scale = scaleForCamera(this.position, camera.camera) * 0.08;
        this.scale.setScalar(scale);
        this.updateMatrix();
        this.updateMatrixWorld(true);
	}

    dispose(): void {
    }

    version(): number {
        return 1;
    }

    setContrastColor(white: number): void {
    }
}
