import { BasicSpreadsheetCellsProperty, TMY_Props, TMY_ColumnDates, TMY_ColumnGeneral, TMY_ColumnHeader, type Bim, StringProperty } from 'bim-ts';
import { StringUtils, type ScopedLogger, Yield, ObservableObject } from 'engine-utils-ts';
import { IterUtils } from 'engine-utils-ts';
import Papa from 'papaparse';
import { TimeSource, tryParseDates } from './TMY_Dates_Parser';
import { MeteoParsingResult } from './TMY_EPW_Parser';
import { MeteoLocationData, tryReadMeteoLocationData } from './TMY_ParserUtils';
import { getSettingsDialog } from './TMY_SettingsDialog';
import { DialogDescription, PUI_GroupNode } from 'ui-bindings';


export function* parseMeteoCsv({bim, csvString, logger, fileName, requestSettings}: {
    logger: ScopedLogger,
    fileName: string,
    csvString: string,
    bim: Bim,
    requestSettings: RequestSettingsDialog,
}): Generator<Yield, MeteoParsingResult> {

    const result = new MeteoParsingResult();
    
    const parsedCSV = Papa.parse<string[]>(csvString, {});

    if (parsedCSV.errors.length) {
        IterUtils.extendArray(result.errors, parsedCSV.errors.map(e => {
            return `${e.row}: ${e.message}`
        }));
    }

    logger.debug('parsed', parsedCSV.data);
    
    const datesStrings = parsedCSV.data.map(row => row[0]);
    const maybeTimeColumn = parsedCSV.data.map(row => row[1]);

    const datesParsed = tryParseDates({
        datesColumn: datesStrings,
        maybeTimeColumn: maybeTimeColumn,
        searchIndexStart: 1,
        startIndexLimit: 50,
        minSequenceLength: 365 * 24,
        maxSequenceLength: 366 * 24,
    });

    if (!datesParsed) {
        result.errors.push('failed to parse dates from 1st column');
        return result;
    }

    const firstDataColumnIndex = datesParsed.timeSource === TimeSource.NextColumn ? 2 : 1;

    // now make sure hours are consecutive 0 - 23
    {
        for (let i = 1; i < datesParsed.utcDates.length; ++i) {
            const prevDate = datesParsed.utcDates[i - 1];
            const date = datesParsed.utcDates[i];
            if (date.getUTCHours() % 24 !== (prevDate.getUTCHours() + 1) % 24) {
                const row = i + datesParsed.startIndex;
                result.errors.push(`hours in rows ${row - 1} and ${row} are not consecutive`);
            }
        }
    }

    let dataRowsSorted: string[][];
    {
        // now extract rows per hour
        const rowsPerDate: [Date, string[]][] = datesParsed.utcDates.map((date, dateIndex) => {
            const rowIndex = dateIndex + datesParsed.startIndex;
            const row = parsedCSV.data[rowIndex];
            return [date, row];
        });
        // sometimes data doesnt start at the jan1 00:00
        // find the first row with that date, and splice data to make it aligned
        {
            const jan1_00_00_row_index = rowsPerDate.findIndex(([date]) => {
                const month = date.getUTCMonth();
                const day = date.getUTCDate();
                const hour = date.getUTCHours();
                return month == 0 && day === 1 && hour === 0;
            });

            if (jan1_00_00_row_index === -1) {
                result.errors.push('failed to find jan1 00:00 row');
            } else {
                logger.info('found jan1 00:00 row', jan1_00_00_row_index);
                // const rowsBefore = rowsPerDate.slice(0, jan1_00_00_row_index);
                // const rowsAfter = rowsPerDate.slice(jan1_00_00_row_index);
                // rowsPerDate.splice(0, rowsPerDate.length, ...rowsAfter, ...rowsBefore);

                // const firstDate = rowsPerDate[0][0];
                // logger.assert(
                //     firstDate.getUTCMonth() == 0
                //     && firstDate.getUTCDate() == 1
                //     && firstDate.getUTCHours() == 0
                //     , 'first date is jan1 00:00'
                // );
            }
        }
        logger.debug('rowsPerDate', rowsPerDate);
        // we should have all the data rows in correct order now
        dataRowsSorted = rowsPerDate.map(([_date, row]) => row);

        if (dataRowsSorted.length > 365*24) {
            result.warnings.push(`trimmed ${dataRowsSorted.length - 365*24} rows to have exactly 365 days`);
            dataRowsSorted.splice(365*24);
        }
    }

    // now find out real data columns indices
    const datesAndDataColumnsIndices: number[] = [0]; // dates column is 0 index
    {
        const firstRowLength = dataRowsSorted[0].length;
        const incompleteColumns: number[] = [];
        for (let i = firstDataColumnIndex; i < firstRowLength; ++i) {
            // in case user put some kind of annotations to the right of the data
            // check that we have non empty values in at least 300 rows
            // and we have description above for that column

            const rowsWithDataAtThatColumn = dataRowsSorted.map(row => row[i]).filter(v => v !== undefined && v !== '').length;
            const hasColumnDescriptionRightAbove = Boolean(parsedCSV.data[datesParsed.startIndex - 1][i]);
            
            if (hasColumnDescriptionRightAbove && rowsWithDataAtThatColumn > 300) {
                datesAndDataColumnsIndices.push(i);
            } else {
                incompleteColumns.push(i + 1);
            }
        }
        if(incompleteColumns.length) {
            result.warnings.push(`Columns [${incompleteColumns.join(', ')}] are incomplete, skipping`);
        }
    }

    let perDataColumnsDescriptions: TMY_ColumnHeader[];
    const wholeFilePrefixMetadata: (number|string)[][] = [];
    {
        // figure out columns descriptions
        // columns descriptions are expected to be right above the data
        // but they can occupy multiple rows
        // this code decides on which rows have columns descriptions
        // based on the assumption that every column description occupies the same number of rows
        let columnsDescriptionsFirstRow = datesParsed.startIndex - 1;
        for (let i = columnsDescriptionsFirstRow; i >= 0; --i) {
            const row = parsedCSV.data[i];
            const allColumnsHaveNonEmptyDescription = datesAndDataColumnsIndices.every(columnIndex => {
                return row[columnIndex] != undefined && row[columnIndex] != ''
            });
            if (allColumnsHaveNonEmptyDescription) {
                columnsDescriptionsFirstRow = i;
            } else {
                break;
            }
        }
        perDataColumnsDescriptions = datesAndDataColumnsIndices.map(columnIndex => {
            const strings: string[] = [];
            for (let i = columnsDescriptionsFirstRow; i < datesParsed.startIndex; ++i) {
                const row = parsedCSV.data[i];
                strings.push(row[columnIndex]);
            }
            const parsed = TMY_ColumnHeader.tryParseAsNameAndUnit(strings);
            if (parsed) {
                return parsed;
            }
            return new TMY_ColumnHeader({
                raw: strings
            });
        });

        for (let i = 0; i < columnsDescriptionsFirstRow; ++i) {
            const row = parsedCSV.data[i];
            wholeFilePrefixMetadata.push(row.map(StringUtils.tryParseAsNumberIfExact));
        }
    }

    logger.assert(
        perDataColumnsDescriptions.length === datesAndDataColumnsIndices.length,
        'perDataColumnsDescriptions.length === dataColumnsIndices.length'
    );

    // create data columns
    const columnsHasFewUniqueValues:[number, string][] = [];
    const dataColumns: (TMY_ColumnGeneral | TMY_ColumnDates | undefined)[] = datesAndDataColumnsIndices.map((columnIndex, i) => {

        const header = perDataColumnsDescriptions[i];

        const column = dataRowsSorted.map(row => row[columnIndex]);

        if (columnIndex === 0 ||
            (i < datesAndDataColumnsIndices.length - 1 || datesParsed.timeSource !== TimeSource.NextColumn)
        ) {
            const asDates = tryParseDates({
                datesColumn: column,
                maybeTimeColumn: dataRowsSorted.map(row => row[columnIndex + 1]),
                maxSequenceLength: column.length,
                minSequenceLength: column.length,
                searchIndexStart: 0,
                startIndexLimit: 0,
            });
            if (asDates) {
                return new TMY_ColumnDates(
                    header,
                    new Float64Array(asDates.utcDates.map(d => d.valueOf())),
                )
            } else if (i === 0) {
                console.error('expected to reparse dates at 0 column, something went wrong', columnIndex, i);
            }
        }
        const numbersStringsColumn = TMY_ColumnGeneral.parseFromStrings({
            header,
            dataStrings: column,
        });
        if (numbersStringsColumn.valuesPalette.length < 3) {
            columnsHasFewUniqueValues.push([i + 1, header.toHeaderString()]);
            return undefined;
        }
        return numbersStringsColumn;
    });

    if(columnsHasFewUniqueValues.length){
        result.warnings.push(`Columns [${columnsHasFewUniqueValues.map(c => c.join(", ")).join("; ")}] have too few unique values`);
    }

    let locationContext = tryReadMeteoLocationDataFromCsvRowHinted(fileName, wholeFilePrefixMetadata);

    if (!locationContext) {
        const settings = yield* requestSettings({
            ident: 'meteo-settings',
            defaultValue: {
                lat: 0,
                long: 0,
                timeZone: 0,
                alt: 0,
            }
        }, getSettingsDialog);
        locationContext = tryReadMeteoLocationData(settings);
    }

    const meteoDataFile = new TMY_Props({
        fileName: StringProperty.new({isReadonly: true, value: fileName}),
        prefixMetadata: new BasicSpreadsheetCellsProperty(wholeFilePrefixMetadata),
        datesColumns: IterUtils.ofClass(dataColumns, TMY_ColumnDates),
        dataColumns: IterUtils.ofClass(dataColumns, TMY_ColumnGeneral),
        locationContext: locationContext,
    });
    result.data = meteoDataFile;
    return result;
}


function tryReadMeteoLocationDataFromCsvRowHinted(fileName: string, rows: (string|number)[][]) {
    if (rows.length === 0) {
        return undefined;
    }

    if (isSolarAnywhereFile(fileName, rows)) {
        return tryReadMeteoLocationData({
            timeZone: rows[0][3],
            lat: rows[0][4],
            long: rows[0][5],
            alt: rows[0][6],
        });
    } else {
        // try to parse names fields, value expected to be in the same row next cell, or in the row below in the same column
        const latCellNameLocation = findCellCoordsForField('latitude', rows);
        const longCellNameLocation = findCellCoordsForField('longitude', rows);
        const altCellNameLocation = findCellCoordsForField('altitude', rows);
        const timeZoneCellNameLocation = findCellCoordsForField('timezone', rows);
        if (!latCellNameLocation || !longCellNameLocation || !altCellNameLocation || !timeZoneCellNameLocation) {
            return undefined;
        }
        if (latCellNameLocation.row === longCellNameLocation.row) {
            return tryReadMeteoLocationData({
                timeZone: rows[timeZoneCellNameLocation.row + 1][timeZoneCellNameLocation.col],
                lat: rows[latCellNameLocation.row + 1][latCellNameLocation.col],
                long: rows[longCellNameLocation.row + 1][longCellNameLocation.col],
                alt: rows[altCellNameLocation.row + 1][altCellNameLocation.col],
            });
        } else {
            return tryReadMeteoLocationData({
                timeZone: rows[timeZoneCellNameLocation.row][timeZoneCellNameLocation.col + 1],
                lat: rows[latCellNameLocation.row][latCellNameLocation.col + 1],
                long: rows[longCellNameLocation.row][longCellNameLocation.col + 1],
                alt: rows[altCellNameLocation.row][altCellNameLocation.col + 1],
            })
        }
    }
}

function isSolarAnywhereFile(fileName: string, rows: (string|number)[][]) {
    if (fileName.toLowerCase().includes('solaranywhere') || rows[0][7]?.toString().toLowerCase().startsWith("data version")) {
        return true;
    }
    const rowsFlat = rows.flatMap(s => s.toString().toLowerCase());
    return  rowsFlat.includes('solaranywhere');
}

const SingularFieldsDescriptions = {
    'latitude': ['Latitude (dd)', 'Latitude'],
    'longitude': ['Longitude (dd)', 'Longitude'],
    'altitude': ['Elevation (m)', 'Altitude'],
    'timezone': ['Time Zone (UTC)', 'Time Zone', 'Timezone'],
}

function findCellCoordsForField(field: keyof typeof SingularFieldsDescriptions,rows: (string|number)[][]): {row: number, col: number} | undefined {
    for (let row = 0; row < rows.length; ++row) {
        const rowContent = rows[row];
        for (let col = 0; col < rowContent.length; ++col) {
            const cellContent = rowContent[col];
            if (typeof cellContent !== 'string') {
                continue;
            }
            if (doesSingularFieldNameMatch(field, cellContent)) {
                return {row, col};
            }
        }
    }
    return undefined;
}

function doesSingularFieldNameMatch(key: keyof typeof SingularFieldsDescriptions, fieldContent: string): boolean {
    const fieldContentCleanedUp = fieldContent.trim().toLowerCase().split(/\s/g).map(s => s.trim());
    for (const name of SingularFieldsDescriptions[key]) {
        const nameCleanedUp = name.trim().toLowerCase().split(/\s/g).map(s => s.trim());
        const fieldNameToSearch = nameCleanedUp[0];
        // not trying to parse units ATM, expect them to be SI
        if (fieldContentCleanedUp.some((word, i) => word === fieldNameToSearch)) {
            return true;
        }
    }
    return false;

}

type RequestSettingsDialog = (
    args: { ident: string, defaultValue: MeteoLocationData },
    getDialog: (
        obs: ObservableObject<MeteoLocationData>, _params: any, submitAction: {label: string, action: () => void}, cancel: () => void,
    ) => DialogDescription<PUI_GroupNode, undefined>,
) => Generator<Yield, MeteoLocationData>;
