import type { Result} from "engine-utils-ts";
import { Failure, IterUtils, ObjectUtils, Success } from "engine-utils-ts";
import type { GroupedNotificationGenerator, UiBindings } from "ui-bindings";
import { NotificationDescription, NotificationType } from "ui-bindings";
import type { Bim, Catalog, CatalogItemId, PropertyBase, IdBimScene, BlockEquipment, SceneInstance} from "bim-ts";
import { CatalogItemsReferenceProperty, InverterTypeIdent, NumberProperty, NumberPropertyWithOptions, NumberRangeProperty, StringProperty, TransformerIdent } from "bim-ts";
import { notificationSource } from "../Notifications";
import { getChildEquipments, TrackersTypes } from "./common";

export function createSiteAreaSettingsFromScene(
    mainParents: IdBimScene[],
    bim: Bim,
    catalog: Catalog,
    uiBindings: UiBindings,
    notificationGroup: GroupedNotificationGenerator,
) {

    const notFoundTransformers: IdBimScene[] = [];

    const groups: BlockEquipmentExtended[] = [];
    const transformers: [IdBimScene, Readonly<SceneInstance>][] = [];
    for (const parent of mainParents) {
        bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(parent, (id) => {
            const instance = bim.instances.peekById(id);
            if(instance?.type_identifier === TransformerIdent){
                transformers.push([id, instance]);
            }
            return true;
        });
    }

    outer: for (const [transformerId] of transformers) {
        const equipmentReply = extractBlockConfigurationFromTransformer(
            transformerId,
            bim,
            catalog
        );

        if (equipmentReply instanceof Failure) {
            if (equipmentReply.errorCode === LayoutSceneParserErrorCodes.TransformerNotFound) {
                notFoundTransformers.push(transformerId);
            }
            continue;
        }

        const equipment = equipmentReply.value;

        if ((equipment.blockDCWatt as NumberProperty).value === 0) {
            continue;
        }

        for (const group of groups) {
            if (blockEquipmentsShouldBeGrouped(group, equipment)) {;
                group.matchingTransformers
                    .push(NumberProperty.new({ value: transformerId }));
                (group.transformersDCWatt as PropertyBase[])
                    .push(equipment.blockDCWatt as NumberProperty);
                continue outer;
            }
        }
        equipment.matchingTransformers = [NumberProperty.new({ value: transformerId })];
        equipment.transformersDCWatt = [equipment.blockDCWatt as NumberProperty]
        groups.push(equipment);
    }

    if (notFoundTransformers.length) {
        uiBindings.addNotification(
            notificationGroup.addNotification(
                NotificationDescription.newWithActionToStart({
                    source: notificationSource,
                    actionDescription: {
                        action: () => bim.instances.setSelected(notFoundTransformers),
                        name: 'select missing transformers',
                        actionArgs: [],
                    },
                    addToNotificationsLog: true,
                    key: "augmentationTransformerNotInCatalog",
                    type: NotificationType.Warning,
                    removeAfterMs: 10e3,
                    
                })
            )
        )
    }

    for (const [idx, group] of groups.entries()) {
        group.number_of_inverters = group.number_of_inverters.withDifferentValue(
            (group.matchingTransformers as PropertyBase[]).length
        );
        const minDCWatt = IterUtils.minBy(group.transformersDCWatt as NumberProperty[], x => x.value)?.value ?? 0;
        const maxDCWatt = IterUtils.maxBy(group.transformersDCWatt as NumberProperty[], x => x.value)?.value ?? 0;
        group.label = group.label.withDifferentValue(
            `${idx + 1} - DC ${(minDCWatt/1000).toFixed(2)}..${(maxDCWatt/1000).toFixed(2)} kW`
        );
        group.blocks_dc = group.blocks_dc.withDifferentValue([minDCWatt/1000, maxDCWatt/1000]);
        group.id = NumberProperty.new({ value: idx });
    }

    return groups
}

export function extractBlockConfigurationFromTransformer(
    transformerId: IdBimScene,
    bim: Bim,
    catalog: Catalog,
) {
    return extractBlockConfigurationFromTrackerFlatHierarchy(transformerId, bim, catalog);
}



export function blockEquipmentsShouldBeGrouped(l: BlockEquipment, r: BlockEquipment) {
    if (!ObjectUtils.areObjectsEqual(l.transformer, r.transformer)) {
        return false;
    }
    if (!ObjectUtils.areObjectsEqual(l.inverter, r.inverter)) {
        return false;
    }
    return true
}

export function extractBlockConfigurationFromTrackerFlatHierarchy(
    transformerId: IdBimScene,
    bim: Bim,
    catalog: Catalog,
): Result<BlockEquipmentExtended, LayoutSceneParserFailure> {
    const transformerCatalogItemId =
        getEquipmentMatchingCatalogItemId(transformerId, bim, catalog);
    if (transformerCatalogItemId instanceof Failure) {
        return new LayoutSceneParserFailure({errorCdoe: LayoutSceneParserErrorCodes.TransformerNotFound});
    }


    const block = createDefaultBlockEquipment();

    block.transformer = block.transformer.withDifferentValue(
        [{ id: transformerCatalogItemId.value, type: 'catalog_item' }]
    );

    const inverterTemplate =
        getChildEquipments(
            [transformerId],
            bim,
            [InverterTypeIdent],
            true,
            [TransformerIdent],
        ).at(0)?.[0]
    if (inverterTemplate !== undefined) {
        const inverterTemplateCatalogId = getEquipmentMatchingCatalogItemId(
            inverterTemplate, bim, catalog
        )
        if (inverterTemplateCatalogId instanceof Success) {
            block.inverter = block.inverter.withDifferentValue(
                [{ id: inverterTemplateCatalogId.value, type: 'catalog_item' }]
            );
        }
    }

    const trackers = getChildEquipments(
        [transformerId],
        bim,
        TrackersTypes,
        false,
        [TransformerIdent],
    )
    const trackerTotalDCWatt = IterUtils.sum(
        trackers,
        x => x[1].properties.get('circuit | aggregated_capacity | dc_power')?.as('W') ?? 0,
    );
    block.blockDCWatt = NumberProperty.new({ value: trackerTotalDCWatt });

    return new Success(block);
}

export function createDefaultBlockEquipment(): BlockEquipmentExtended {
    return {
        id: NumberProperty.new({ value: 0 }),
        inverter: new CatalogItemsReferenceProperty({
            value: [], maxCount: 1, types: ['asset'], assetsTypes: [InverterTypeIdent],
        }),
        ilr_range: NumberRangeProperty.new({ value: [1.05, 1.25], isReadonly: true }),
        inverters_per_block: NumberPropertyWithOptions.new({
            options: ['auto'], value: 0, selectedOption: 'auto',
            isReadonly: true,
        }),
        label: StringProperty.new({ value: '', isReadonly: true }),
        number_of_inverters: NumberPropertyWithOptions.new({
            options: ['existing'], value: 0, selectedOption: 'existing',
            isReadonly: true,
        }),
        transformer: new CatalogItemsReferenceProperty({
            value: [],
            maxCount: 1,
            types: ['asset'],
            assetsTypes: [TransformerIdent],
            isReadonly: true,
        }),
        blocks_dc: NumberRangeProperty.new({ value: [0, 4200], range: [0, Number.MAX_SAFE_INTEGER], step: 1, unit: 'kW',  }),
        blockDCWatt: NumberProperty.new({ value: 0 }),
        matchingTransformers: [],
        transformersDCWatt: [],
    }
}

export function getEquipmentMatchingCatalogItemId(
    equipmentId: IdBimScene,
    bim: Bim,
    catalog: Catalog,
): Result<CatalogItemId> {
    const si = bim.instances.peekById(equipmentId);
    if (!si) {
        return new Failure({msg: 'no scene instance found for equipment ' + equipmentId});
    }
    const assetId = catalog.assets.assetsMatcher.matchSceneInstanceWithAsset(si);
    if (!assetId) {
        return new Failure({msg: 'no matching asset found for equipment ' + equipmentId});
    }
    const catalogItemId = catalog.catalogItems.catalogItemIdPerAssetId.poll().get(assetId);
    if (!catalogItemId) {
        return new Failure({msg: 'no matching catalog item found for equipment ' + equipmentId});
    }
    return new Success(catalogItemId);
}


export enum LayoutSceneParserErrorCodes {
    TransformerNotFound = 'Transformer Not Found',
}

export class LayoutSceneParserFailure extends Failure {

    errorCode: LayoutSceneParserErrorCodes;

    constructor(args: {errorCdoe: LayoutSceneParserErrorCodes}) {
        super({});
        this.errorCode = args.errorCdoe;
    }
}


export interface BlockEquipmentExtended extends BlockEquipment {
    blocks_dc: NumberRangeProperty;
    blockDCWatt: NumberProperty
    matchingTransformers: NumberProperty[]
    transformersDCWatt: NumberProperty[]
}
