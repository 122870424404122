import { BimUiBindings } from "bim-ts";
import { Failure, InProgress } from "engine-utils-ts";
import { PUI_ConfigPropertyTransformer, PUI_FailureNode, PUI_OperationInProgressNode } from "ui-bindings";
import { PUI_ConfigBasedBuilderParams } from "ui-bindings";

export function createSelectionPropsPuiMappingConfig(): PUI_ConfigBasedBuilderParams {
    return PUI_ConfigBasedBuilderParams.new([
        ...BimUiBindings.propsToPuiNodesTransformers(),
        ...BimUiBindings.instancesPropsConfigTransformers(),
        [
            (name, value, path) => value instanceof InProgress,
            new PUI_ConfigPropertyTransformer(
                (p) => p,
                () => {
                    return new Failure({msg: 'custom props edits are not implemented'});
                },
                (value) => {
                    return [
                        PUI_OperationInProgressNode,
                        {
                            value: value,
                            // description: bimpProp.description,
                            // readonly: bimpProp.isReadonly,
                        }
                    ];
                }
            )
        ],
        [
            (name, value, path) => value instanceof Failure,
            new PUI_ConfigPropertyTransformer(
                (p) => p,
                () => {
                    return new Failure({msg: 'custom props edits are not implemented'});
                },
                (value) => {
                    return [
                        PUI_FailureNode,
                        {
                            value: value,
                            // description: bimpProp.description,
                            // readonly: bimpProp.isReadonly,
                        }
                    ];
                }
            )
        ]
    ]);

}
