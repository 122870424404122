<script lang="ts">
    import {
    getContext,
        onDestroy,
        onMount,
    } from "svelte";
    import type {
        ColDef,
        GridApi,
        GridOptions} from "ag-grid-enterprise";
    import {
        createGrid
    } from "ag-grid-enterprise";
    import "ag-grid-enterprise/styles/ag-grid.css";
    import "ag-grid-enterprise/styles/ag-theme-alpine.css";
    import type { UnitsMapper } from "bim-ts";
    import type { Readable } from "svelte/store";
    import type { LazyVersioned} from "engine-utils-ts";
    import { ObjectUtils } from "engine-utils-ts";
    import { VersionedStore } from "../VersionedStore";
    import type { TableRowType} from "./TableUi";
    import { addColumn, addRowValue, updateProperty } from "./TableUi";
    import { PUI_GroupNode, PUI_Node, PUI_PropertyNode } from "ui-bindings";

    export let lazyTableData: LazyVersioned<PUI_GroupNode[]>;
    const unitsMapper = getContext('unitsMapper') as Readable<UnitsMapper>;

    $: tableData = new VersionedStore(lazyTableData);
    $:{
        refreshTable($tableData);
    }

    export let theme = "alpine";
    let themeUrl = `https://unpkg.com/ag-grid-community/dist/styles/ag-theme-${theme}.css`;
    let ref: HTMLElement;
    let api: GridApi;

    const gridOptions: GridOptions = {
        columnDefs: [],
        rowData: [],
        defaultColDef: {
            sortable: false,
            useValueFormatterForExport: true,
            enableValue: true,
            enableRowGroup: false,
            resizable: true,
            lockPinned: true,
            suppressMovable: true,
        },
        suppressDragLeaveHidesColumns: true,
        onCellEditingStopped: (event) => {
            const data = event.data;
            if(event.newValue != event.oldValue){
                const children = $tableData.slice().sort(PUI_Node.sortNodes);
                const slice = children[event.rowIndex!];
                if(!(slice instanceof PUI_GroupNode)){
                    console.error('tablet not updated:', event.rowIndex, JSON.stringify(data), JSON.stringify(slice));
                    return;
                }
                const cell = slice.children.get(event.colDef.field!);
                if(!(cell instanceof PUI_PropertyNode)){
                    console.error('tablet not updated:', JSON.stringify(cell));
                    return;
                }
                updateProperty(cell, event.newValue, $unitsMapper);
            }
        },
    };

    let lastStateCols: ColDef[] = [];
    let lastStaeRows: TableRowType[] = [];

    function refreshTable(root:PUI_GroupNode[]) {
        if (api && root) {
            const columnsMap = new Map<string, ColDef>();
            const slices : TableRowType[] = [];
            for (const slice of root) {
                if(slice instanceof PUI_GroupNode){
                    const row : TableRowType = {};
                    const cells =  slice.childrenOrdered();
                    for (const cell of cells) {
                        addRowValue(cell, $unitsMapper, (n, v)=>{
                            row[n] = v;
                        });
                        addColumn(cell, $unitsMapper, c =>{
                            if(!columnsMap.has(c.field!)){
                                columnsMap.set(c.field!, c);
                            }
                        });
                    }
                    slices.push(row);
                }else {
                    console.error('Row is not group type:', JSON.stringify(slice));
                }
            }

            const curCols: ColDef[] = Array.from(columnsMap.values());

            if(!ObjectUtils.areObjectsEqual(lastStateCols, curCols)){
                api.setGridOption('columnDefs', curCols);
                lastStateCols = curCols;
                // console.log('cols refreshed');
            }
            if(!ObjectUtils.areObjectsEqual(lastStaeRows, slices)){
                api.setGridOption('rowData', slices);
                lastStaeRows = slices;
                // console.log('rows refreshed');
            }
        } else {
            console.warn('table not updated')
        }
    }

    onMount(() => {
        api = createGrid(ref, gridOptions);
        refreshTable($tableData);
    });

    onDestroy(() => {
        api?.destroy();
    });

</script>

<svelte:head>
    {#if theme !== "alpine" && !Object.values(document.styleSheets).some((styleSheet) => styleSheet.href === themeUrl)}
        <link rel="stylesheet" href={themeUrl} />
    {/if}
</svelte:head>

<div
    bind:this={ref}
    class="ag-theme-{theme} table"
/>

<style>
    .table {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .table :global(div[col-id="color"] .ag-header-cell-text) {
        display: none;
    }
    .table :global(div[col-id="id"]) {
        overflow: visible;
        z-index: 1;
    }
</style>
