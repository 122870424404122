import type { GeometryGpuRepr } from "../../geometries/KrBufferGeometry";
import { WHITE_SPACE, collapseWhitespaceOnString, newlineBreakability } from "../utils/inline-layout/Whitespace";
import { Inline } from "./Lines";
import type { Font } from "./Font";
import { Object3D } from "../../3rdParty/three";
import { getGlyphDimensions, getGlyphPairKerning } from "../content/MSDFText";
import { FONT_ID, setFontFamily } from "../content/FontLibrary";

export class TextLayoutOptions {
    content: string

    constructor(
        content: string,
        readonly fontSize: number,
        readonly fontId: FONT_ID = FONT_ID.ROBOTO,
        readonly fontKerning: 'none' | 'normal' = 'normal',
        readonly breakOn: string = '- ,.:?!\n',
        readonly letterSpacing: number = 0,
        readonly whiteSpace: WHITE_SPACE = WHITE_SPACE.PRE_LINE,
    ) {
        this.content = content;
    };

    clone(): TextLayoutOptions {
        return new TextLayoutOptions(
            this.content,
            this.fontSize,
            this.fontId,
            this.fontKerning,
            this.breakOn,
            this.letterSpacing,
            this.whiteSpace
        );
    }
}

export class Text extends Object3D {
    content: string;

    fontId: FONT_ID;
    fontSize: number;
    fontKerning: string;
    breakOn: string;
    letterSpacing: number;
    whiteSpace: WHITE_SPACE;
    fontFamily?: Font;
    
    _fitFontSize?: number;

    textContent?: GeometryGpuRepr;

    inlines?: Inline[];

    constructor ( options: TextLayoutOptions ) {
        super();

        this.content = options.content;

        this.fontSize = options.fontSize;
        this.fontId = options.fontId;
        this.fontKerning = options.fontKerning;
        this.breakOn = options.breakOn;
        this.letterSpacing = options.letterSpacing;
        this.whiteSpace = options.whiteSpace;
        setFontFamily( this, this.fontId );

        this.calculateInlines( this._fitFontSize || this.fontSize );
    };

    calculateInlines( fontSize: number ) {
        const content = this.content!;
        const font = this.fontFamily;

        // Abort condition

        if ( !font || typeof font === 'string' ) {
            return;
        }

        if ( !this.content ) {
            this.inlines = undefined;
            return;
        }

        // collapse whitespace for white-space normal
        const whitespaceProcessedContent = collapseWhitespaceOnString( content, this.whiteSpace );
        const chars = Array.from( whitespaceProcessedContent );

        // Compute glyphs sizes
        const SCALE_MULT = fontSize / font.info.size;
        const lineHeight = font.common.lineHeight * SCALE_MULT;
        const lineBase = font.common.base * SCALE_MULT;

        const glyphInfos = chars.map( ( glyph ) => {
            // Get height, width, and anchor point of this glyph
            const dimensions = getGlyphDimensions( glyph, font, fontSize );

            //

            let lineBreak: string | undefined = undefined;

            if( this.whiteSpace !== WHITE_SPACE.NOWRAP ) {
                if ( this.breakOn.includes( glyph ) || glyph.match( /\s/g ) ) lineBreak = 'possible';
            }


            if ( glyph.match( /\n/g ) ) {
                lineBreak = newlineBreakability( this.whiteSpace );
            }

            //

            return new Inline(
                dimensions.height,
                dimensions.width,
                dimensions.anchor,
                dimensions.xadvance,
                dimensions.xoffset,
                lineBreak,
                glyph,
                fontSize,
                lineHeight,
                lineBase
            );
        } );

        // apply kerning
        if ( this.fontKerning !== 'none' ) {
            // First character won't be kerned with its void lefthanded peer
            for ( let i = 1; i < glyphInfos.length; i++ ) {
                const glyphInfo = glyphInfos[ i ];
                const glyphPair = glyphInfos[ i - 1 ].glyph + glyphInfos[ i ].glyph;

                // retrieve the kerning from the font
                const kerning = getGlyphPairKerning( font, glyphPair );

                // compute the final kerning value according to requested fontSize
                glyphInfo.kerning = kerning * ( fontSize / font.info.size );
            }
        }

        // Update 'inlines' property, so that the parent can compute each glyph position
        this.inlines = glyphInfos;
    }
}