<script lang="ts">
    import { PUI_SceneInstancesSelectorPropertyNode } from "ui-bindings";

    import SelectSceneObjectsComponent from "./SelectSceneObjectsComponent.svelte";
    import SelectVersionsComponent from "./SelectVersionsComponent.svelte";

    export let property: PUI_SceneInstancesSelectorPropertyNode;
</script>

<div class="ui-config-property property-row">
    {#key property.types.join()}
        <div class="list-components">
            {#if property.types.length === 1 && property.types[0] === "version"}
                <SelectVersionsComponent bind:property />
            {:else}
                <SelectSceneObjectsComponent bind:property />
            {/if}
        </div>
    {/key}
</div>

<style lang="scss">
    .list-components {
        display: flex;
        flex-direction: column;
        width: 100%;
        :global(.body-container) {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        :global(
                .body-container
                    .ui-config-property.property-row
                    .property-value
                    .read-only.actions-property
            ) {
            justify-content: center;
        }
    }
</style>
