<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M5.88 11.505C5.88 11.1598 6.15983 10.88 6.505 10.88H9.505C9.85018 10.88 10.13 11.1598 10.13 11.505C10.13 11.8502 9.85018 12.13 9.505 12.13H6.505C6.15983 12.13 5.88 11.8502 5.88 11.505Z"
        fill="currentColor"
    />
    <path
        d="M6.505 8.88C6.15983 8.88 5.88 9.15983 5.88 9.505C5.88 9.85018 6.15983 10.13 6.505 10.13H9.505C9.85018 10.13 10.13 9.85018 10.13 9.505C10.13 9.15983 9.85018 8.88 9.505 8.88H6.505Z"
        fill="currentColor"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.88 3.505C1.88 2.60754 2.60754 1.88 3.505 1.88H11.2979C11.5963 1.88 11.8824 1.99853 12.0934 2.20951L13.8005 3.91662C14.0115 4.1276 14.13 4.41374 14.13 4.71211V12.505C14.13 13.4025 13.4025 14.13 12.505 14.13H3.505C2.60754 14.13 1.88 13.4025 1.88 12.505V3.505ZM3.505 3.13C3.2979 3.13 3.13 3.2979 3.13 3.505V12.505C3.13 12.7121 3.2979 12.88 3.505 12.88H3.88V8.505C3.88 7.60754 4.60754 6.88 5.505 6.88H10.505C11.4025 6.88 12.13 7.60754 12.13 8.505V12.88H12.505C12.7121 12.88 12.88 12.7121 12.88 12.505V4.76389L11.2461 3.13H9.13V4.005C9.13 4.62633 8.62633 5.13 8.005 5.13H5.005C4.38368 5.13 3.88 4.62633 3.88 4.005V3.13H3.505ZM5.13 3.88H7.88V3.13H5.13V3.88ZM10.88 8.505V12.88H5.13V8.505C5.13 8.2979 5.2979 8.13 5.505 8.13H10.505C10.7121 8.13 10.88 8.2979 10.88 8.505Z"
        fill="currentColor"
    />
</svg>
