import { Bim, BimGeometryType, IdBimScene, newFlagsPatch, SceneInstanceFlags, SceneInstancePatch, StdGroupedMeshRepresentation, StdMeshRepresentation, TerrainHeightMapRepresentation } from "bim-ts";
import { KreoEngine, TerrainDisplayEngineSettings } from "engine-ts";
import { EventStackFrame, ObjectUtils, PollablePromise, RGBA, ScopedLogger, Yield } from "engine-utils-ts";
import { Aabb, Aabb2, Vector3 } from "math-ts";
import { entityTypeFromId } from "verdata-ts";

export type ScreenshotFocusSettings = {
    ids: IdBimScene[];
    focusOnUpdatedTerrainTiles?: boolean;
} | Aabb2;

interface ScreenshotMakerArgs {
    ids: IdBimScene[];
    focusSettings?: ScreenshotFocusSettings;
    terrainDisplaySettings?: Partial<TerrainDisplayEngineSettings>;
    showAnnotations?: boolean;
    showGizmo?: boolean;
    showClickbox?: boolean;
    colorize?: (bim: Bim, ids: IdBimScene[], event?: Partial<EventStackFrame>) => void;
}
export class ScreenshotMaker {
    private readonly engine: KreoEngine;
    private readonly bim: Bim;
    private readonly logger: ScopedLogger;
    private _executed = false;

    private _state: {
        visibleIds: IdBimScene[],
        terrainDisplaySettings: TerrainDisplayEngineSettings,
        showAnnotations: boolean,
        showGizmo: boolean,
        showClickbox: boolean,
    } | undefined = undefined;

    constructor(engine: KreoEngine, bim: Bim, logger: ScopedLogger) {
        this.engine = engine;
        this.bim = bim;
        this.logger = logger.newScope('screenshot-maker');
    }

    *makeScreenshotOf(args: ScreenshotMakerArgs) {
        this._executed = true;
        yield* this.applyDisplaySettings(args, {identifier: 'screenshot maker', transactionId: 'screenshot maker'});
        const imgSize = calcTotalBounds(this.bim, args.focusSettings ?? { ids:args.ids }, this.logger);
        const image = yield* PollablePromise.generatorWaitFor(this.engine.takecreenshotTopdown(
            imgSize.wsCoords.xy(),
            imgSize.screenshotSize,
            {
                backgroundColor: RGBA.new(1, 1, 1, 0),
            }
        ));
        
        yield Yield.NextFrame;
        return {image, wsCoords: imgSize.wsCoords};
    }

    private *applyDisplaySettings(args: {
        ids: IdBimScene[], 
        terrainDisplaySettings?: Partial<TerrainDisplayEngineSettings>,
        showAnnotations?: boolean,
        showGizmo?: boolean,
        showClickbox?: boolean,
        colorize?: (bim: Bim, ids: IdBimScene[], event?: Partial<EventStackFrame>) => void
    }, event?: Partial<EventStackFrame>) {
        this._makeVisible(args.ids, event);
        if(args.terrainDisplaySettings){
            this.engine.terrainDisplaySettings.applyPatch({patch: args.terrainDisplaySettings, event});
        }

        if(args.colorize){
            args.colorize(this.bim, args.ids, event);
        }

        if(args.showAnnotations !== undefined){
            this.engine.annotationsSettings.applyPatch({patch: { showAnnotations: args.showAnnotations}, event});
        }

        if(args.showGizmo !== undefined){
            this.engine.transformGizmoSettings.applyPatch({patch: { isActive: args.showGizmo}, event});
        }
        if(args.showClickbox !== undefined){
            this.engine.clipboxState.applyPatch({patch: { isActive: args.showClickbox}, event});
        }
    }

    saveCurrentDisplaySettings() {
        if(this._state){ 
            return;
        }

        this._state = {
            visibleIds: this.bim.instances.getVisible(),
            terrainDisplaySettings: ObjectUtils.deepCloneObj(this.engine.terrainDisplaySettings.poll()),
            showAnnotations: this.engine.annotationsSettings.poll().showAnnotations,
            showGizmo: this.engine.transformGizmoSettings.poll().isActive,
            showClickbox: this.engine.clipboxState.poll().isActive,
        };
    }
    
    restoreDisplaySettings() {
        if (this._state && this._executed) {
            this.bim.undoStack?.undo();
            this.engine.terrainDisplaySettings.applyPatch({patch: this._state.terrainDisplaySettings});
            this.engine.annotationsSettings.applyPatch({patch: { showAnnotations: this._state.showAnnotations}});
            this.engine.transformGizmoSettings.applyPatch({patch: { isActive: this._state.showGizmo}});
            this.engine.clipboxState.applyPatch({patch: { isActive: this._state.showClickbox}});
            this._executed = false;
        }
    }

    private _makeVisible(ids: IdBimScene[], event?: Partial<EventStackFrame>) { 
        const visibleIds = new Set(ids);
        const patches: [IdBimScene, SceneInstancePatch][] = [];
        for (const [id, inst] of this.bim.instances.perId) {
            if(visibleIds.has(id) && inst.isHidden){
                patches.push([id, {flags: newFlagsPatch(SceneInstanceFlags.isHidden, false)}]);
            } else if(!visibleIds.has(id)) {
                patches.push([id, {flags: newFlagsPatch(SceneInstanceFlags.isHidden, true)}]);
            }
        }
        this.bim.instances.applyPatches(patches, event);
    }
}

export function calcTotalBounds(bim: Bim, settings: ScreenshotFocusSettings, logger: ScopedLogger) {
    const totalBounds = Aabb.empty();
    if(settings instanceof Aabb2){ 
        totalBounds.setFromPoints([settings.min, settings.max].map(p => new Vector3(p.x, p.y, 0)));
    } else {
        const geosAabbs = bim.allBimGeometries.aabbs.poll();

        const instances = bim.instances.peekByIds(settings.ids);
        const reusedWmBounds = Aabb.empty();
        for (const [_id, inst] of instances) {
            const representation = inst.representationAnalytical ?? inst.representation;
            let localAabb: Aabb | undefined;
            if(settings.focusOnUpdatedTerrainTiles && representation instanceof TerrainHeightMapRepresentation){
                const updatedTilesAabb = Aabb.empty();
                //TODO: focus on updated points
                for (const [tileId, tile] of representation.tiles) {
                    if(!tile.updatedGeo){
                        continue;
                    }
                    let geoAabb =  geosAabbs.get(tile.updatedGeo);
                    if (!geoAabb) {
                        continue;
                    }
                    if (entityTypeFromId<BimGeometryType>(tile.updatedGeo) === BimGeometryType.HeightmapRegular) {
                        geoAabb = geoAabb.clone();
                        tileId.offsetAabb(geoAabb, representation.tileSize);
                    }
                    updatedTilesAabb.union(geoAabb);
                }
                localAabb = updatedTilesAabb;
            }else if (
                representation instanceof StdGroupedMeshRepresentation ||
                representation instanceof StdMeshRepresentation
            ) {
                // trackers should have lods
                if (representation.lod1) {
                    // find out
                    localAabb = representation.lod1.aabb(geosAabbs);
                } else {
                    localAabb = representation.aabb(geosAabbs);
                }
            } else if (typeof representation?.aabb === 'function') {
                localAabb = representation.aabb(geosAabbs);
            } else {
                logger.warn('Unknown representation type', representation);
            }
            
            if(!localAabb || !localAabb.areNumbersReal() || localAabb.maxSideArea() < 1e-2){
                continue;
            }
    
            reusedWmBounds.copy(localAabb).applyMatrix4(inst.worldMatrix);
            totalBounds.union(reusedWmBounds);
        }
    
        totalBounds.expandByScalar(totalBounds.getSize().xy().length() * 0.01);
    }

    const totalBoundsSize = totalBounds.getSize();
    if (!totalBoundsSize.isFinite()) {
        throw new Error("Total bounds size is not finite");
    }
    if (totalBoundsSize.xy().length() > 50_000) {
        throw new Error("Total bounds size is too big");
    }

    const boundsSizeRounded = totalBounds.getSize().xy();

    const screenshotDesiredSize = 4096;
    const screenshotSizeScale = Math.min(
        screenshotDesiredSize / boundsSizeRounded.maxComponent(),
        50
    );

    const screenshotSize = boundsSizeRounded
        .clone()
        .multiplyScalar(screenshotSizeScale)
        .round();


    return {wsCoords: totalBounds,  screenshotSize};
}