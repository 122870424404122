import { VersionedInvalidator, RGBA, LegacyLogger } from 'engine-utils-ts';
import type { BoxCorner, Vector4
} from 'math-ts';
import {
    Aabb, BoxGridOriginCorner, Euler, KrMath, Matrix3, Matrix4, Plane, Quaternion, Ray, Vector3
} from 'math-ts';
import { KeyModifiersFlags } from 'ui-bindings';

import type { Camera, MeshIntersection, Object3D, ShaderMaterial} from '../3rdParty/three';
import {
    Box3, Box3Helper, Color, Group, Math as _Math, Mesh, PerspectiveCamera,
    PlaneBufferGeometry, Raycaster, Sphere
} from '../3rdParty/three';
import type { ClipBox } from '../clipbox/ClipBox';
import { SectionBoxColor } from '../Constants';
import { MouseButton } from '../controls/InputController';
import type { GesturesButtons } from '../controls/MouseGesturesBase';
import type { MovementControls } from '../controls/MovementControls';
import { ClipboxSizeIntersection } from '../controls/SizeLabelsClicksConsumer';
import type { GlobalUniforms } from '../materials/GlobalUniforms';
import { ClipboxShaderEnlargment } from '../materials/GlobalUniforms';
import { MathUtils } from '../MathUtils';
import { newBasicMaterial } from '../shaders/BasicShader';
import { SizeLabelMesh } from '../SizeLabelMesh';
import type { FrustumExt } from '../structs/FrustumExt';
import type { RaySection } from '../structs/RaySection';
import type { TextGeometries } from '../text/TextGeometry';
import { TextMesh } from '../TextMesh';
import type { Time } from '../time/TIme';
import type { TotalBounds } from '../TotalBounds';
import type { EngineLegacyUiUnits } from '../EngineLegacyUiUnits';
import Utils from '../utils/Utils';
import { ClipboxControlInters } from './ClipboxIntersection';
import { GizmoBase } from './GizmoBase';
import { scaleForCamera } from './GizmoUtils';
import { setColorFromAxisIndex } from './TransformGizmo';

const reusedBox = new Box3();
const reusedColor = new Color();

export class ClipBoxGizmo extends GizmoBase<ClipboxControlInters> {

	isEnabled: boolean = true;
	totalBounds: TotalBounds;
	clipbox: ClipBox;
	defaultMaterial: ShaderMaterial;
	highlightedMaterial: ShaderMaterial;
	activeMaterial: ShaderMaterial;
	updated: boolean;

	cornerControls: Group[] = [];
	centerControls: Mesh[] = [];
	boxLines: Box3Helper;
	clipboxBoundsMirror: Box3;
	uiUnits: EngineLegacyUiUnits;
	controls: Object3D[] = [];

	prevFr: number = 0;
	prevUnits: number = 0;

	edgeSizes: 	SizeLabelMesh[/*6*/] = [];
	prevFrameEdgePositions: Vector3[] = [];
	prevRay: Ray = new Ray(Vector3.zero(), Vector3.zero());
	prevBounds: Aabb = Aabb.empty();

	invalidator: VersionedInvalidator = new VersionedInvalidator();

	constructor(
		totalBounds: TotalBounds,
		clipbox: ClipBox,
		uiUnits: EngineLegacyUiUnits,
		g_uniforms: GlobalUniforms,
		textGeometries: TextGeometries,
		time: Time,
	) {
		super();
		this.clipboxBoundsMirror = new Box3();
		this.totalBounds = totalBounds;
		this.clipbox = clipbox;
		this.uiUnits = uiUnits;
		this.invalidator.addDependency(uiUnits);
		// this.gridProjectionState = gridProjectionState;

		this.defaultMaterial = newBasicMaterial(RGBA.new(0.7, 0.7, 0.7, 0.4));
		this.highlightedMaterial = newBasicMaterial(RGBA.new(0.8, 0.8, 0.8, 0.4));
		this.activeMaterial = newBasicMaterial(RGBA.new(0.8, 0.8, 0.8, 0.5));

		this.updated = false;

		const cornerGeom = new PlaneBufferGeometry(1, 1, 1, 1);
		cornerGeom.setDrawRange(0, 3);

		const createBoxCorner = () => {
			const corner = new Group();

			const topCorner = new Mesh(cornerGeom, this.defaultMaterial);
			topCorner.position.set(0.5, 0.5, 0.0);
			topCorner.rotateZ(_Math.degToRad(90));
			corner.add(topCorner);

			const leftCorner = new Mesh(cornerGeom, this.defaultMaterial);
			leftCorner.position.set(0.0, 0.5, -0.5);
			leftCorner.rotateY(_Math.degToRad(90));
			leftCorner.rotateX(_Math.degToRad(180));
			corner.add(leftCorner);

			const rightCorner = new Mesh(cornerGeom, this.defaultMaterial);
			rightCorner.position.set(0.5, 0.0, -0.5);
			rightCorner.rotateX(_Math.degToRad(90));
			corner.add(rightCorner);

			return corner;
		}

		for (let i = 0; i < 8; ++i) {
			const corner = createBoxCorner();
			corner.quaternion.copy(cornersRotationsByIndex[i]);
			const cornerWrapper = new Group();
			cornerWrapper.add(corner);
			this.add(cornerWrapper);
			this.cornerControls.push(cornerWrapper);
		}

		const centersGeometry = new PlaneBufferGeometry(1, 1, 1, 1);
		centersGeometry.rotateX(-Math.PI / 2);
		centersGeometry.rotateY(Math.PI / 4);
		const createBoxSurfaceCenter = (index: number) => {
			const centerMesh = new Mesh(centersGeometry, this.defaultMaterial);
			centerMesh.quaternion.copy(centersRotations[index]);
			this.add(centerMesh);
			this.centerControls.push(centerMesh);
		}
		for (let i = 0; i < 6; ++i){
			createBoxSurfaceCenter(i);
		}

		this.boxLines = new Box3Helper(this.clipboxBoundsMirror, 0);
		this.boxLines.material = newBasicMaterial(SectionBoxColor, 0.8);
		this.boxLines.material.depthTest = true;
		this.add(this.boxLines);

		Utils.extendArray(this.controls, this.cornerControls);
		Utils.extendArray(this.controls, this.centerControls);

		// create edge sizes
		for (let i = 0; i < 6; ++i) {
			const sizeMesh = new SizeLabelMesh(g_uniforms, uiUnits, textGeometries);
			this.edgeSizes.push(sizeMesh);
			this.add(sizeMesh);
		}
	}

	version(): number {
		return this.clipbox.version() + this.invalidator.version(); // TODO add hover
	}

	setContrastColor(white: number) {
		for (const mesh of this.edgeSizes) {
			mesh.setContrastColor(white);
		}
	}

	static _calcCornerScale(cornerPos:Vector3, boundsHalfSize: Vector3, camera: Camera, scaleOut:Vector3) {
		let cameraScale = scaleForCamera(cornerPos, camera);
		scaleOut.setScalar(cameraScale);
		scaleOut.min(boundsHalfSize);
		// return Math.min(cameraScale, boundsMin * 0.5);
	}

	static _centerScale(position: Vector3, index:number, boundsSize: Vector3, camera: Camera): number {
		let scale = scaleForCamera(position, camera);
		const signs = centersOffsetsSigns[index];
		let minSideSize = boundsSize.maxComponent();
		for (let i = 0 ; i < 3; ++i){
			if (signs.getComponent(i) === 0) {
				minSideSize = Math.min(minSideSize, boundsSize.getComponent(i));
			}
		}
		return Math.min(minSideSize, scale);
	}

	update(fr: FrustumExt, raycaster: RaySection) {

		const camera = fr.camera;

		this.visible = this.clipbox.isEnabled() && !this.totalBounds.isEmpty();
		if (!this.visible) {
			return;
		}
		let updated = this.updated;
		this.updated = false;

		const clipboxCurrBounds = this.clipbox.getBounds();
		this.clipboxBoundsMirror.min.fromArray(clipboxCurrBounds.elements, 0);
		this.clipboxBoundsMirror.max.fromArray(clipboxCurrBounds.elements, 3);

		// set edge centers positions
		if (this.prevFr === fr.version()
			&& this.prevRay.equals(raycaster.ray)
			&& this.prevBounds.equals(clipboxCurrBounds)
			&& this.prevUnits === this.uiUnits.version()
		) {
			if (!updated) {
				return;
			}
		} else {
			updated = true;
			this.prevFr = fr.version()
			this.prevRay.copy(raycaster.ray);
			this.prevBounds.copy(clipboxCurrBounds);
			this.prevUnits = this.uiUnits.version();
		}

		const bounds = clipboxCurrBounds;
		bounds.expandByScalar(ClipboxShaderEnlargment);
		const boundsCenter_t = bounds.getCenter_t();
		const boundsHalfSize_t = bounds.getSize().multiplyScalar(0.5);

		{
			const pos_t = Vector3.zero();
			const scale_t = Vector3.zero();
			for (let i = 0; i < 8; ++i) {
				// set position
				const corner = this.cornerControls[i];
				const components = cornersPositionsOffsetsByIndex[i];
				for (let j = 0; j < 3; ++j){ // set x,y,z
					const cornOffs = components[j];
					pos_t.setComponent(j, bounds.elements[cornOffs + j]);
				}
				corner.position.copy(pos_t as any);

				//set scale
				ClipBoxGizmo._calcCornerScale(pos_t, boundsHalfSize_t, camera, scale_t);
				corner.scale.copy(scale_t as any);
			}
		}


		const sphere = new Sphere();
		const m = new Matrix4();
		const invisibleControls = false; //(gc.gizmos[0].visible || gc.gizmos[1].visible);
		for (let i = 0; i < this.controls.length; ++i) {
			const ch = this.children[i];

			m.copy(ch.matrixWorld);
			ch.updateMatrixWorld(true);

			updated = updated || !ch.matrixWorld.equals(m);

			reusedBox.setFromObject(ch);

			let visible: boolean = false;
			if (invisibleControls) {
				visible = false;
			} else if (i < this.cornerControls.length) {
				reusedBox.getBoundingSphere(sphere);
				sphere.radius *= 5;
				visible = raycaster.ray.intersectsSphere(sphere.center, sphere.radius)
					|| ch.children[0].children.some(c => this.defaultMaterial !== (c as Mesh).material)
			} else if (i < this.cornerControls.length + this.centerControls.length) {
				reusedBox.getBoundingSphere(sphere);
				sphere.radius *= 7;
				visible = raycaster.ray.intersectsSphere(sphere.center, sphere.radius)
					|| (ch instanceof Mesh && ch.material !== this.defaultMaterial);
			}

			if (visible !== ch.visible) {
				ch.visible = visible;
				updated = true;
			}
		}

		for (let i = 0; i < this.centerControls.length; ++i){
			const signs = centersOffsetsSigns[i];
			const center = this.centerControls[i];
			center.position.multiplyVectors(signs, boundsHalfSize_t.clone())
				.add(boundsCenter_t);
			const scale = ClipBoxGizmo._centerScale(center.position, i, boundsHalfSize_t, camera);
			center.scale.setScalar(scale)
			center.updateMatrixWorld(true);
		}

		if (updated) {
			const edgeCenterPositionsToShow_t: Vector3[] = [];

			const boundsCenter_t = bounds.getCenter_t();
			const boundsSize_t = bounds.getSize();
			const cameraPosition_t = camera.position.clone();
			const cameraToBoxCenterNorm_t = cameraPosition_t.clone().sub(boundsCenter_t).normalize();
			const testRay = new Ray(cameraPosition_t, cameraToBoxCenterNorm_t);
			for (const edgeCenterGroup of edgeCentersOffsetsSigns) {

				const groupPositions: Vector3[] = [];
				for (const edgeCenterSigns of edgeCenterGroup) {
					const edgeCenterWS_t = boundsSize_t.clone().multiplyScalar(0.5);
					edgeCenterWS_t.multiply(edgeCenterSigns);
					edgeCenterWS_t.add(boundsCenter_t);

					if (camera instanceof PerspectiveCamera) {
						testRay.direction.copy(edgeCenterWS_t).add(edgeCenterSigns.clone().multiplyScalar(0.002)).sub(testRay.origin).normalize();
					} else {
						testRay.direction.setFromMatrixColumn(camera.matrixWorld as any, 2);
						testRay.origin.copy(edgeCenterWS_t).add(testRay.direction).add(edgeCenterSigns.clone().multiplyScalar(0.002));
						testRay.direction.multiplyScalar(-1);
					}
					if (!testRay.intersectsBox(bounds)) {
						groupPositions.push(edgeCenterWS_t);
					}
				}
				if (groupPositions.length > 2) {
					groupPositions.sort((v1, v2) => v1.distanceToSquared(cameraPosition_t) - v2.distanceToSquared(cameraPosition_t));

					const closest2DistRatio = groupPositions[0]!.distanceToSquared(cameraPosition_t) / groupPositions[1]!.distanceToSquared(cameraPosition_t);
					if (closest2DistRatio < 0.9999 || closest2DistRatio > 1.00001) {
						groupPositions.shift();
					}
					// when camera is in ortho mode, we can have more than 2 points found when camera is parallel
					// to one of axes, in that case just get 2 closes points (which should be at the same distance)
					// otherwise remove closes point, it's probably wrong
					groupPositions.length = 2;
				}
				Utils.extendArray(edgeCenterPositionsToShow_t, groupPositions);
			}

			if (!Utils.areObjectArraysEqual(edgeCenterPositionsToShow_t, this.prevFrameEdgePositions)) {
				updated = true;
				this.prevFrameEdgePositions = edgeCenterPositionsToShow_t.map(v => v.clone());
			}

			if (updated) {

				const v_t = Vector3.zero();
				const v_comps: number[] = [];
				const box_grid_origin_t = bounds.getCornerPoint(BoxGridOriginCorner);
				for (let i = 0; i < this.edgeSizes.length; ++i){
					const pos_t = edgeCenterPositionsToShow_t[i];
					const edgeMesh = this.edgeSizes[i];
					if (pos_t) {
						let edgePoints_t = getEdgeCoordsForPointOnBoxEdge_t(pos_t, clipboxCurrBounds);

						if (Vector3.distBetween(box_grid_origin_t, edgePoints_t[0]) > Vector3.distBetween(box_grid_origin_t, edgePoints_t[1])) {
							edgePoints_t = [edgePoints_t[1], edgePoints_t[0]];
						}

						v_t.copy(pos_t).sub(boundsCenter_t).applyAbs();
						v_t.toArray(v_comps, 0).sort((n1, n2) => n1 - n2);
						edgeMesh.hor_size_cap = Math.max(v_comps[1], Vector3.distBetween(edgePoints_t[0], edgePoints_t[1]));

						edgeMesh.edgeFrom.copy(edgePoints_t[0]);
						edgeMesh.edgeTo.copy(edgePoints_t[1]);
						edgeMesh.upOutFrom.copy(boundsCenter_t);

						edgeMesh.updateForCamera(camera, camera.matrixWorld);
					} else {
						edgeMesh.visible = false;
					}
				}
			}
		}
		if (updated) {
			this.invalidator.invalidate();
		}
	}

	intersectRay(raycaster: RaySection): ClipboxControlInters | ClipboxSizeIntersection| null {
		if (!this.visible) {
			return null;
		}
		const krRay = raycaster.ray;
		const clipboxBounds = this.clipbox.getBounds();

		let int: MeshIntersection | null = null;
		let isCenterIntersection: boolean = true;

		const threeCaster = new Raycaster(
			raycaster.ray.origin.clone(),
			raycaster.ray.direction.clone(),
			raycaster.near, raycaster.far
		);

		const labelIntersects = threeCaster.intersectObjects(this.edgeSizes, true);
		if (labelIntersects.length > 0) {
			int = labelIntersects[0];
		}

		if (!int && !krRay.intersectsBox(clipboxBounds)) {
			return null;
		}

		const centersIntersects = threeCaster.intersectObjects(this.centerControls, true);
		if (centersIntersects.length > 0) {
			if (int === null || centersIntersects[0].distance < int.distance) {
				int = centersIntersects[0];
			}
		}
		const cornersIntersects = threeCaster.intersectObjects(this.cornerControls, true);
		if (cornersIntersects.length > 0) {
			if (int === null || int.distance + 0.00001 > cornersIntersects[0].distance) {
				int = cornersIntersects[0];
				isCenterIntersection = false;
			}
		}

		if (!int) {
			return null;
		}

		const mesh = int.object;
		const intPoint_t = int.point.clone();

		if (mesh instanceof TextMesh) {

			const sizeObj = mesh.parent as SizeLabelMesh;
			return new ClipboxSizeIntersection(intPoint_t, int.distance, sizeObj);

		} else if (mesh instanceof Mesh) {

			reusedBox.setFromObject(mesh);

			const normalIndex = Utils.vectorMinComponentIndex(reusedBox.getSize(new Vector3()));
			const normalSign = Math.sign(intPoint_t.getComponent(normalIndex) - clipboxBounds.getCenter_t().getComponent(normalIndex));

			const planeNormal = Vector3.zero().setComponent(normalIndex, normalSign);
			const plane = Plane.allocateZero().setFromNormalAndCoplanarPoint(planeNormal, intPoint_t);

			if (isCenterIntersection) {
				return new ClipboxControlInters(
					intPoint_t, int.distance, mesh, plane, null, planeNormal, clipboxBounds
				);

			} else {
				// find closest bounds corner
				const corner = clipboxBounds.findClosestCorner(intPoint_t);
				return new ClipboxControlInters(
					intPoint_t, int.distance, mesh, plane, corner, null, clipboxBounds
				);
			}
		}
		return null;
	}

	setHover(int: ClipboxControlInters | ClipboxSizeIntersection | null) {
		if (int instanceof ClipboxControlInters) {
			this.updated = true;
			this.defaultMaterial.visible = true;
			const colorVec = this.highlightedMaterial.uniforms['colorOpacity'].value;
			setColorFromMeshBoundsDirection(int.mesh, colorVec);
			int.mesh.material = this.highlightedMaterial;

		} else if (int instanceof ClipboxSizeIntersection) {

		} else {
			this.defaultMaterial.visible = true;
			for (let i = 0; i < 8; ++i){
				const ch = this.cornerControls[i].children[0];
				for (let j = 0; j < 3; ++j){
					const m = ch.children[j] as Mesh;
					m.material = this.defaultMaterial;
				}
			}
			for (let i = 0; i < this.centerControls.length; ++i){
				const m = this.centerControls[i];
				m.material = this.defaultMaterial;
			}
			for (const s of this.edgeSizes) {
				// s.setBrightness(GizmoBrightness.Default)
			}
		}
	}

	startDrag(int: ClipboxControlInters) {
		this.updated = true;
		this.defaultMaterial.visible = false;
		const colorVec = this.activeMaterial.uniforms['colorOpacity'].value;
		setColorFromMeshBoundsDirection(int.mesh, colorVec);
		this.clipbox.state.forceResetThrottle();
		return true;
	}

	handleDrag(
		raycaster: RaySection,
		int: ClipboxControlInters,
		button: MouseButton,
		camera: Camera,
		keyMods: KeyModifiersFlags
	): boolean {

		const ray = raycaster.ray;
		const boxControls = this.clipbox;

		let snapping_distance: number | null = null;
		if (keyMods === KeyModifiersFlags.Ctrl) {
			snapping_distance = this.uiUnits.getRulerSnatchIntervals()[0];
		} else if (keyMods === KeyModifiersFlags.Shift) {
			snapping_distance = this.uiUnits.getRulerSnatchIntervals()[1];
		}

		if (int.corner) {

			const currInt_t = ray.intersectPlane_t(int.plane, Vector3.zero());
			if (!currInt_t) {
				return true;
			}

			if (button === MouseButton.Right) {

				const offset = Vector3.subVectors(currInt_t, int.mutatedPoint);

				if (snapping_distance) {
					offset.roundTo(snapping_distance);
				}

				const appliedOffset_t = boxControls.move_t(offset, this.totalBounds);

				int.mutatedPoint.add(appliedOffset_t);
				return true;

			} else if (button === MouseButton.Left) {

				const offsetFromInit_t = Vector3.subVectors(currInt_t, int.point);
				const initCorner_t = int.initClipbox.getCornerPoint(int.corner);
				let newCorner_t = Vector3.addVectors(initCorner_t, offsetFromInit_t);

				if (snapping_distance) {
					let oppositeCorner_t = int.initClipbox.getCornerOppositeOf_t(int.corner);
					newCorner_t.sub(oppositeCorner_t).roundTo(snapping_distance).add(oppositeCorner_t);
					newCorner_t = int.plane.projectPoint_t(newCorner_t);
				}

				const newTarget = boxControls.current.clone();
				newTarget.setCorner(int.corner, newCorner_t);

				boxControls.setImmediately(newTarget);

				return true;

			} else {

				return false;
			}

		} else if (int.axis) {

			let resultOffset_t: Vector3;
			{
				const cameraForward_t = Vector3.zero().setFromMatrixColumn(camera.matrixWorld as any, 2);

				let resultOffsetFactor: number;;
				if (Math.abs(cameraForward_t.dot(int.axis)) < 0.97) {
					const closestPoints = MathUtils.closestPointsOnTwoRays(
						new Ray(int.point, int.axis),
						ray
					);
					if (!closestPoints) {
						LegacyLogger.warn('closestPoints should not be null for non parallel rays');
						return false;
					}
					resultOffsetFactor = closestPoints.distance1 * -1;
				} else {

					const cameraPlane = Plane.allocateZero().setFromNormalAndCoplanarPoint(cameraForward_t, int.point);
					const cameraPlaneIntersection_t = ray.intersectPlane_t(cameraPlane, Vector3.zero())!;
					const cameraPlaneOffset_t = Vector3.subVectors(int.point, cameraPlaneIntersection_t);
					const cameraRotMatrixInverse = new Matrix3().setFromMatrix4(camera.matrixWorldInverse);
					cameraPlaneOffset_t.applyMatrix3(cameraRotMatrixInverse);

					const offsetFromCameraPower = cameraPlaneOffset_t.dot(new Vector3(-1, -1, 0).normalize());
					resultOffsetFactor = offsetFromCameraPower;
				}

				resultOffset_t = int.axis.clone().multiplyScalar(resultOffsetFactor);
			}

			if (button === MouseButton.Right) {

				if (snapping_distance) {
					resultOffset_t.roundTo(snapping_distance);
				}

				boxControls.setImmediately(int.initClipbox);
				boxControls.move_t(resultOffset_t, this.totalBounds);
				return true;

			} else if (button === MouseButton.Left) {

				const target = int.initClipbox.clone();
				const axisIndex = int.axis.maxAbsComponentIndex();
				let box_min_t = target.getMin_t();
				let box_max_t = target.getMax_t();

				if (int.axis.getComponent(axisIndex) > 0) {
					let newMax_t = box_max_t.add(resultOffset_t);
					let size_by_axis = newMax_t.getComponent(axisIndex) - box_min_t.getComponent(axisIndex);
					if (snapping_distance) {
						size_by_axis = KrMath.roundUpTo(size_by_axis, snapping_distance);
					}
					newMax_t.setComponent(axisIndex, size_by_axis + box_min_t.getComponent(axisIndex));

					target.setMaxFrom(newMax_t);
				} else {
					let newMin_t = box_min_t.add(resultOffset_t);

					let size_by_axis = box_max_t.getComponent(axisIndex) - newMin_t.getComponent(axisIndex);
					if (snapping_distance) {
						size_by_axis = KrMath.roundUpTo(size_by_axis, snapping_distance);
					}
					newMin_t.setComponent(axisIndex, box_max_t.getComponent(axisIndex) - size_by_axis);

					target.setMinFrom(newMin_t);
				}

				boxControls.setImmediately(target);

				return true;

			}  else {

				return false;
			}

		}
		return false;
	}

	handleMouseClick(int: ClipboxControlInters | ClipboxSizeIntersection, buttons: GesturesButtons, controls: MovementControls) {

		const boxControls = this.clipbox;

		if (int instanceof ClipboxSizeIntersection) {
			if (buttons.mouseButton == MouseButton.Right) {
				// const edgeV_t = int.sizeMesh.edgeFrom.clone_t().sub(int.sizeMesh.edgeTo);
				// const axisIndex = edgeV_t.maxAbsComponentIndex();
				// const currAxes = this.gridProjectionState.showAxes;
				// currAxes.setComponent(axisIndex, currAxes.getComponent(axisIndex) == 0 ? 1 : 0);
				// this.gridProjectionState.enabled = currAxes.maxComponent() > 0;

			} else if (buttons.mouseButton == MouseButton.Left) {

			}


		} else if (int instanceof ClipboxControlInters) {

			if (buttons.mouseButton === MouseButton.Right) {

				let point_t: Vector3;
				if (int.corner) {
					point_t = int.plane.projectPoint_t(boxControls.current.getCenter_t());
				} else if (int.axis) {
					point_t = int.point.clone();
				} else {
					return;
				}
				const bounds = boxControls.current.clone();
				const p1_t = int.plane.projectPoint_t(bounds.getMax_t());
				const p2_t = int.plane.projectPoint_t(bounds.getMin_t());
				const size = Vector3.subVectors(p1_t, p2_t);

				controls.focusCameraOnPlane(int.plane, point_t, size);

			} else if (buttons.mouseButton === MouseButton.Left) {

				if (int.corner) {

					const point_t = this.totalBounds.bounds.getCornerPoint(int.corner);
					const projected_t = int.plane.projectPoint_t(point_t);
					const targetBounds = boxControls.current.clone();
					targetBounds.setCorner(int.corner, projected_t);

					boxControls.setTarget(targetBounds);

				} else if (int.axis) {

					const axisIndex = int.axis.maxAbsComponentIndex();
					const maxBounds = this.totalBounds;

					const target = int.initClipbox.clone();

					const minMax = int.axis.getComponent(axisIndex) > 0 ? 3 : 0;
					target.elements[axisIndex + minMax] = maxBounds.bounds.elements[axisIndex + minMax];

					boxControls.setTarget(target);

				} else {
					return;
				}

				controls.focusCameraOnBounds(boxControls.state.poll().target.clone());
			}
		}
		this.resetMaterials();

	}

	dispose() {
		this.activeMaterial.dispose();
		this.highlightedMaterial.dispose();
		this.defaultMaterial.dispose();

		this.traverse(ch => {
			const m = ch as Mesh;
			if (m.isMesh) {
				m.geometry!.dispose();
				m.material!.dispose();
			}
		});
	}
}

function getEdgeCoordsForPointOnBoxEdge_t(point: Vector3, box: Aabb): [Vector3, Vector3] {
	// find 2 closest corners
	const corners_t: Vector3[] = [];
	for (const corner of allBoxCorners) {
		corners_t.push(box.getCornerPoint(corner));
	}
	corners_t.sort((d1, d2) => d1.distanceToSquared(point) - d2.distanceToSquared(point));
	corners_t.length = 2;
	return corners_t as [Vector3, Vector3];
}

function getColorCodeFromMesh(mesh: Mesh): number {
	reusedBox.setFromObject(mesh);
	reusedBox.getSize(reusedSizeVector);
	let color = 0;
	const minCompIndex = Utils.vectorMinComponentIndex(reusedSizeVector);
	switch (minCompIndex) {
		case 0: color = 0xee0000; break;
		case 1: color = 0x0000ff; break;
		case 2: color = 0x00aa00; break;
	}
	return color;
}

function setColorFromMeshBoundsDirection(mesh: Mesh, color: Vector4) {
	reusedBox.setFromObject(mesh);
	reusedBox.getSize(reusedSizeVector);
	const minCompIndex = Utils.vectorMinComponentIndex(reusedSizeVector);
	setColorFromAxisIndex(minCompIndex, color);
}

const allBoxCorners: BoxCorner[] = [
	[0, 0, 0],
	[0, 3, 0],
	[0, 0, 3],
	[3, 0, 0],

	[3, 3, 3],
	[3, 0, 3],
	[3, 3, 0],
	[0, 3, 3],
]

const reusedSizeVector = new Vector3();
/*
		  3____2
		0/___1/|
		| 7__|_6
		4/___5/
*/

const rot90deg = 90 * 0.0174533;
const cornersRotationsByIndex = [
	// top half
	new Quaternion().setFromEuler(new Euler(0, 0, rot90deg * 0)), //0
	new Quaternion().setFromEuler(new Euler(0, 0, rot90deg * 1)), //1
	new Quaternion().setFromEuler(new Euler(0, 0, rot90deg * 2)), //2
	new Quaternion().setFromEuler(new Euler(0, 0, rot90deg * 3)), //3

	// bot half
	new Quaternion().setFromEuler(new Euler(rot90deg, rot90deg * 0, 0)), //4
	new Quaternion().setFromEuler(new Euler(rot90deg, rot90deg * 1, 0)), //5
	new Quaternion().setFromEuler(new Euler(rot90deg, rot90deg * 2, 0)), //6
	new Quaternion().setFromEuler(new Euler(rot90deg, rot90deg * 3, 0)), //7
]

const cornersPositionsOffsetsByIndex: BoxCorner[] = [
	// top part
	[0, 0, 3], //0
	[3, 0, 3], //1
	[3, 3, 3], //2
	[0, 3, 3], //3

	// bottom part
	[0, 0, 0], //4
	[3, 0, 0], //5
	[3, 3, 0], //6
	[0, 3, 0], //7
]

// top-down view
// 	  4
//  1 2 0 3
// 	  5

const centersOffsetsSigns = [
	new Vector3( 1,  0,  0), //0
	new Vector3(-1,  0,  0), //1
	new Vector3( 0,  1,  0), //2
	new Vector3( 0, -1,  0), //3
	new Vector3( 0,  0,  1), //4
	new Vector3( 0,  0, -1), //5
];

const edgeCentersOffsetsSigns = [
	[
		Vector3.allocate(-1,  1,  0), //21
		Vector3.allocate( 1,  1,  0), //20
		Vector3.allocate( 1, -1,  0), //30
		Vector3.allocate(-1, -1,  0), //31
	],
	[
		Vector3.allocate( 0,  1,  1), //24
		Vector3.allocate( 0, -1,  1), //43
		Vector3.allocate( 0, -1, -1), //35
		Vector3.allocate( 0,  1, -1), //52

	],
	[
		Vector3.allocate(-1,  0,  1), //14
		Vector3.allocate( 1,  0,  1), //40
		Vector3.allocate( 1,  0, -1), //05
		Vector3.allocate(-1,  0, -1), //51
	]
];

const centersRotations = [
	new Quaternion().setFromEuler(new Euler(0, 0, -rot90deg)),
	new Quaternion().setFromEuler(new Euler(0, 0, rot90deg)),
	new Quaternion().setFromEuler(new Euler(0, 0, 0)),
	new Quaternion().setFromEuler(new Euler(0, 0, rot90deg * 2)),
	new Quaternion().setFromEuler(new Euler(rot90deg, 0, 0)),
	new Quaternion().setFromEuler(new Euler(-rot90deg, 0, 0))
];
