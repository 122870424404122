import type { NonMethodsOnly} from 'engine-utils-ts';
import { IterUtils } from 'engine-utils-ts';
import type { CustomPropertySerializer} from '../properties/CustomPropsRegistry';
import { CustomPropsRegistry } from '../properties/CustomPropsRegistry';
import type { PropValueTotalHash } from '../properties/Props';
import { PropertyBase } from '../properties/Props';
import { getPropertyReferenceId } from '../properties/PropsRefsIds';
import { TMY_ColumnHeader } from './TMY_ColumnHeader';

export class TMY_ColumnDates extends PropertyBase {

    constructor(
        public readonly header: TMY_ColumnHeader,
        public readonly utcDates: Float64Array,
    ) {
        super();
        Object.freeze(this);
    }

    uniqueValueHash(): PropValueTotalHash {
        return getPropertyReferenceId(this);
    }
    equals(other: PropertyBase): boolean {
        if (!(other instanceof TMY_ColumnDates)) {
            return false;
        }
        return this.header.equals(other.header) && IterUtils.areArraysEqual(this.utcDates, other.utcDates);
    }

    get length(): number {
        return this.utcDates.length;
    }

    at(index: number): Date | undefined {
        if (index >= 0 && index < this.utcDates.length) {
            const date = new Date(this.utcDates[index]);
            return date;
        }
        return undefined
    }

    getUtcValue(index: number): number | undefined {
        if (index >= 0 && index < this.utcDates.length) {
            return this.utcDates[index];
        }
        return undefined
    }

    static utcDateToString(date: Date): string {
        const localTimezoneOffsetInMinutes = date.getTimezoneOffset();
        const offsettedDate = new Date(date.valueOf() + localTimezoneOffsetInMinutes * 60 * 1000);
        return offsettedDate.toLocaleString();
    }

}

interface TMY_ColumnDatesSerialized {
    header: NonMethodsOnly<TMY_ColumnHeader>;
    utcDates: number[];
}
class TMY_ColumnDatesSerializer implements CustomPropertySerializer<TMY_ColumnDates> {
    currentFormatVersion: number = 0;
    serializeToString?(p: TMY_ColumnDates): string {
        const obj: TMY_ColumnDatesSerialized = {
            header: p.header,
            utcDates: Array.from(p.utcDates),
        };
        return JSON.stringify(obj);
    }
    deserializeFromString?(formatVersion: number, serialized: string): TMY_ColumnDates {
        const des = JSON.parse(serialized) as TMY_ColumnDatesSerialized;
        return new TMY_ColumnDates(
            new TMY_ColumnHeader({
                name: des.header.name,
                unit: des.header.unit,
                raw: des.header.raw,
            }),
            new Float64Array(des.utcDates)
        ); 
    }

}

CustomPropsRegistry.register({
    class: TMY_ColumnDates,
    serializerClass: TMY_ColumnDatesSerializer,
    constructFromPartial: (params: Partial<TMY_ColumnDates>) => {
        return new TMY_ColumnDates(
            params.header ?? new TMY_ColumnHeader({raw: [""]}),
            new Float64Array()
        );
    }
});
