<script lang="ts">
    import { getContext } from "svelte";
    import {
        DialogDescription,
        PUI_Lazy,
        UiBindings,
        UiCustomNode,
    } from "ui-bindings";
    import PUI_Root from "../pui/PUI_Root.svelte";
    import EngineUiPanel from "../ui-panels/EngineUiPanel.svelte";
    import { ButtonComponent, IconButton } from "../libui/button";

    export let puiSource: PUI_Lazy<any>;

    const uiBindings: UiBindings = getContext("uiBindings");

    function openHelpDialog() {
        uiBindings.addDialog(
            new DialogDescription({
                id: "help-dialog",
                name: "Terrain and Piles corner-cases",
                context: null,
                uiSource: new UiCustomNode({ context: "terrain" }),
            }),
        );
    }
</script>

<EngineUiPanel>
    <svelte:fragment slot="buttons">
        <ButtonComponent desc={new IconButton("Info", openHelpDialog)} />
    </svelte:fragment>
    <PUI_Root configIdentifier="terrain-and-piles" {puiSource}></PUI_Root>
</EngineUiPanel>

<style lang="scss">
</style>
