import { ShaderChunk } from '../3rdParty/three';
import { getAttrIndexOf } from '../geometries/AttributesIndices';

export const ObjectMaxBatchCount = 20;

export const PerObjectBlockSizeInFloats = 20;

{
	ShaderChunk['kr_std_uniforms'] = 
	`
	#ifdef CUSTOM_STD_UNIFORMS

		uniform mat4 viewProjMatrix;

		#if defined(IS_SPRITE_GEO) || defined(IS_LINE_GEO)
			uniform float lineWidth;
			uniform vec2 resolution;
			uniform mat4 projectionMatrix;

		#elif defined(IS_TRACKER_PILE_BILLBOARD_GEO)
			uniform mat4 projectionMatrix;
			uniform sampler2D billboardImage;
		
		#elif defined(IS_LINE_GEO)
		#endif

		#ifdef BOX_CLIPPING
			uniform vec3 clipBoxMin;
			uniform vec3 clipBoxMax;
		#endif

		#ifdef PLANE_CLIPPING
			uniform vec4 clippingPlane;
		#endif

		#ifdef UV_TRANSFORM
			uniform mat3 uvTransform;
		#endif
	
		// uniform mat4 transforms[${ObjectMaxBatchCount}];

		struct PerObjectData {
			mat4 matrix;
			vec4 colorTint;
		};

		uniform int instanceOffset;

		layout(std140) uniform Transforms
		{
			PerObjectData transforms[${ObjectMaxBatchCount}];
		};

	#endif
	`
}

{
	ShaderChunk['kr_std_attributes'] = 
	`
	#ifdef CUSTOM_STD_ATTRIBUTES
	
		layout(location = 0) in vec3 position;

		#if defined (NEEDS_NORMALS) && !defined(FLAT_NORMALS)
			layout(location = 1) in  vec3 normal;
		#endif
	
		layout(location = 2) in vec2 uv;

		#if defined(IS_LINE_GEO)
			layout(location = ${getAttrIndexOf("previous")})in vec3 previous;
			layout(location = ${getAttrIndexOf("next")})in vec3 next;
			layout(location = ${getAttrIndexOf("side")})in float side;
			layout(location = ${getAttrIndexOf("distance")})in float distance;
		#endif


		// struct Transform {
		// 	mat4 offset;
		// };

		// layout(std140) uniform Transforms
		// {
		// 	mat4 transforms[${ObjectMaxBatchCount}];
		// };

	#endif
	`
}


{
	ShaderChunk['kr_std_vars'] = 
	`
	#if defined(NEEDS_NORMALS) && !defined(FLAT_NORMALS)
		varying vec3 vNormal;
		varying vec3 vWorldNormal;
	#endif

	#if defined(NEEDS_VIEW_SPACE)
		varying vec3 vViewPosition;
	#endif

	#ifdef IS_SPRITE_GEO
		varying vec2 inRoundBoxPos; // x,y and radius in z
		varying vec3 boxSizeRadius; // x,y
	#elif defined(IS_LINE_GEO)
		varying float vDistance;
	#endif
	
	varying vec4 vWorldPosition;

	varying vec4 colorTint;

	// #if defined(USE_MAP) || defined(USE_NORMALMAP)
		varying vec2 vUv;
	// #endif
	`

	ShaderChunk['kr_std_vars_calc'] =
	`
	vec4 localPosition = vec4( position, 1.0 );

	mat4 worldMatrix = transforms[gl_InstanceID + instanceOffset].matrix;
	colorTint = transforms[gl_InstanceID + instanceOffset].colorTint;

	#if defined(IS_SPRITE_GEO)

		vWorldPosition = worldMatrix * vec4( 0.0, 0.0, 0.0, 1.0 );
		inRoundBoxPos = position.xy;
		boxSizeRadius = vec3(abs(position.xy), position.z);

		mat4 m = viewProjMatrix * worldMatrix;

		vec4 finalPosition = m * vec4( 0.0, 0.0, 0.0, 1.0 );
		vec4 side = vec4( position.x, position.y, 0., 1. );

		side *= projectionMatrix;

		side.xy *= pow(finalPosition.w, 0.9); // make objects farther away a little smaller
		side.xy /= ( vec4( resolution, 0., 1. ) * projectionMatrix ).xy;

		finalPosition.xy += side.xy;
		gl_Position = finalPosition;

	#elif defined(IS_LINE_GEO)

		vWorldPosition = worldMatrix * localPosition;

		float vSideSign = sign(side);
		vDistance = distance;

		float aspect = resolution.x / resolution.y;

		mat4 m = viewProjMatrix * worldMatrix;

		vec4 finalPosition = m * vec4( position, 1.0 );
		vec4 prevPos = m * vec4( previous, 1.0 );
		vec4 nextPos = m * vec4( next, 1.0 );


		vec2 currentP = fixAspect( finalPosition, aspect );
		vec2 prevP = fixAspect( prevPos, aspect );
		vec2 nextP = fixAspect( nextPos, aspect );

		float w = lineWidth;

		vec2 dir;
		if( nextP == currentP ){
			dir = normalize( currentP - prevP );
		}
		else if( prevP == currentP ){
			dir = normalize( nextP - currentP );
		}
		else {
			vec2 dirA = normalize( currentP - prevP );
			vec2 dirB = normalize( nextP - currentP );
			// dir = normalize( dir1 + dir2 );


			//now compute the miter join normal and length
			vec2 tangent = normalize(dirA + dirB);
			vec2 perp = vec2(-dirA.y, dirA.x);
			vec2 miter = vec2(-tangent.y, tangent.x);
			dir = tangent;

			w = clamp(lineWidth / dot(miter, perp), 0.5, 4. * lineWidth);
		}

		//vec2 normal = ( cross( vec3( dir, 0. ) vec3( 0., 0., 1. ) ) ).xy;
		vec4 normal = vec4( -dir.y, dir.x, 0., 1. );
		normal.xy *= .5 * w;
		normal *= projectionMatrix;

		normal.xy *= pow(finalPosition.w, 0.9); // make objects farther away a little smaller
		normal.xy /= ( vec4( resolution, 0., 1. ) * projectionMatrix ).xy;

		finalPosition.xy += normal.xy * vSideSign;

		gl_Position = finalPosition;
	
	#elif defined(IS_TRACKER_PILE_BILLBOARD_GEO)

		vec3 cameraUp = vec3(viewMatrix[0][1], viewMatrix[1][1], viewMatrix[2][1]);
		vec3 cameraForward = vec3(viewMatrix[0][2], viewMatrix[1][2], viewMatrix[2][2]);
		vec3 targetRight = cross(cameraUp, cameraForward);

		worldMatrix[0].xyz = normalize(targetRight);
		worldMatrix[1].xyz = cameraUp;
		worldMatrix[2].xyz = cameraForward;

		vWorldPosition = worldMatrix * localPosition;
		gl_Position = viewProjMatrix * vWorldPosition;

	#elif defined(ALIGN_TEXT_GEO_WITH_CAMERA_UP)

		vec3 cameraUp = vec3(viewMatrix[0][1], viewMatrix[1][1], viewMatrix[2][1]);
		vec3 originForward = worldMatrix[2].xyz;
		vec3 targetRight = cross(cameraUp, originForward);
		vec3 targetUp = cross(originForward, targetRight);

		worldMatrix[0].xyz = normalize(targetRight);
		worldMatrix[1].xyz = normalize(targetUp);
		worldMatrix[2].xyz = normalize(originForward);

		vWorldPosition = worldMatrix * localPosition;
		gl_Position = viewProjMatrix * vWorldPosition;

	#else
		vWorldPosition = worldMatrix * localPosition;

		gl_Position = viewProjMatrix * vWorldPosition;

		#if defined(NEEDS_NORMALS) && !defined(FLAT_NORMALS)
			
			mat3 objectRotation = mat3(worldMatrix);
			vWorldNormal = normalize(objectRotation * vec3(normal));
			vWorldNormal *= determinant(objectRotation);

			vec3 transformedNormal = mat3(viewMatrix) * vWorldNormal;
			#ifdef FLIP_SIDED
				transformedNormal = - transformedNormal;
			#endif
			
			vNormal = normalize(transformedNormal);
		#endif
	#endif

	#if defined(NEEDS_VIEW_SPACE)	
		vec4 mvPosition = viewMatrix * vWorldPosition;
		vViewPosition = - mvPosition.xyz;
	#endif

	#include <kr_std_uvs_vertex>
	`

	ShaderChunk['kr_std_uvs_vertex'] = 
	`
	#if defined(UV_FROM_LOCAL_POSITION)
		vUv = position.xy;
	#else 
		vUv = uv;
	#endif

	#ifdef UV_TRANSFORM
		vUv = (uvTransform * vec3(vUv, 1.0)).xy;
	#endif

	`

	ShaderChunk['kr_std_pixel'] = 
	`
	#ifdef BOX_CLIPPING
		bool toClip = any(lessThan(vWorldPosition.xyz, clipBoxMin));
		toClip = toClip || any(greaterThan(vWorldPosition.xyz, clipBoxMax));
		
		#ifdef BOX_CLIP_INSIDE
		toClip = !toClip;
		#endif
	
		if (toClip){
			discard;
		}
	#endif

	#ifdef PLANE_CLIPPING
		vec3 abb = vWorldPosition.xyz + (clippingPlane.xyz * clippingPlane.w);
		float dist_to_clip_plane = dot( abb.xyz, clippingPlane.xyz );
		if ( dist_to_clip_plane < 0.0 ) {
			discard;
		}
	#endif

	#ifdef NEEDS_NORMALS
		#if defined(FLAT_NORMALS)
			vec3 fdx = vec3( dFdx( vViewPosition.x ), dFdx( vViewPosition.y ), dFdx( vViewPosition.z ) );
			vec3 fdy = vec3( dFdy( vViewPosition.x ), dFdy( vViewPosition.y ), dFdy( vViewPosition.z ) );
			vec3 normal  = normalize( cross( fdx, fdy ) );

		#else
			float faceDirection = gl_FrontFacing ? 1.0 : - 1.0;
			vec3 normal = normalize( vNormal );
			// #ifdef DOUBLE_SIDED
				// normal = normal * faceDirection;
			// #endif

					
			#ifdef USE_NORMALMAP
				vec3 mapN = texture2D( normalMap, vUv ).xyz * 2.0 - 1.0;
				normal = perturbNormal2Arb( -vViewPosition, normal, mapN,faceDirection );
			#endif
		#endif
		
	#endif

	#if defined(IS_LINE_GEO) && defined(USE_DASHES_FOR_LINE_GEO)
		float segmentSize = 2.0;
		float segmentStartOffset = 1.0;
		float gapRationInSegment = 0.5; //from 0 to 1
		float currentPosition = vDistance + segmentStartOffset;
		float alpha = ceil( mod( currentPosition, segmentSize) - (segmentSize * gapRationInSegment));
		if(alpha < 1.0)
		{
			discard;
		}
	#endif
	`

	ShaderChunk['kr_alpha_test'] = 
	`
	#ifdef IS_SPRITE_GEO
		float outside = boxDist(inRoundBoxPos, boxSizeRadius.xy, boxSizeRadius.z);

		#ifdef TRANSPARENT
			gl_FragColor.a *= smoothSdfBorderAlpha(outside);
		#endif
	#endif

	#if defined(IS_TRACKER_PILE_BILLBOARD_GEO) && defined(TRANSPARENT)
		vec4 color = texture2D(billboardImage, vUv.xy);
		gl_FragColor.a *= color.a;
	#endif
	`
}



{
	ShaderChunk['color_clipping_uniforms'] = 
	`
	#ifdef COLOR_CLIPPING
		uniform vec4 colorClippingPlane;
		uniform vec4 colorBelow;
		uniform vec4 colorAbove;
	#endif
	`
}

{
	ShaderChunk['kr_envmap_pars_fragment'] = 
	`
	#ifdef USE_ENVMAP
	
	#ifdef ENVMAP_TYPE_CUBE
		uniform samplerCube envMap;
	#else
		uniform sampler2D envMap;
	#endif
	
	#endif
	`
}

{
	ShaderChunk['kr_metalnessmap_pars_fragment'] = 
	`
	#ifdef USE_METALNESSMAP
	
		uniform sampler2D metalnessMap;
	
	#endif
	`
	
	ShaderChunk['kr_metalnessmap_fragment'] = 
	`
	float metalnessFactor = roughMetalTiling.y;
	
	#ifdef USE_METALNESSMAP
	
		vec4 texelMetalness = texture2D( metalnessMap, vUv );
	
		// reads channel B, compatible with a combined OcclusionRoughnessMetallic (RGB) texture
		metalnessFactor *= texelMetalness.b;
	
	#endif
	
	`
}

{
	ShaderChunk['normals_packing'] = 
	`
	// Spheremap Transform
	// https://aras-p.info/texts/CompactNormalStorage.html

	vec2 encode_normal(vec3 n)
	{
		vec2 enc = normalize(n.xy) * (sqrt(-n.z * 0.5 + 0.5));
		enc = enc * 0.5 + 0.5;
		return enc;
	}
	vec3 decode_normal(vec2 enc)
	{
		vec4 nn = vec4(2.0 * enc.x, 2.0 * enc.y, 0.0, 0.0) + vec4(-1.0, -1.0, 1.0, -1.0);
		float l = dot(nn.xyz,-nn.xyw);
		nn.z = l;
		nn.xy *= sqrt(l);
		return nn.xyz * 2.0 + vec3(0.0, 0.0, -1.0);
	}
	`
}

{
	ShaderChunk['kr_normalmap_pars_fragment'] = 
	`
	#ifdef USE_NORMALMAP 
		uniform sampler2D normalMap;
	
		vec3 perturbNormal2Arb( vec3 eye_pos, vec3 surf_norm, vec3 mapN, float faceDirection ) {

			vec3 q0 = dFdx( eye_pos );
			vec3 q1 = dFdy( eye_pos );
			vec2 st0 = dFdx( vUv.st );
			vec2 st1 = dFdy( vUv.st );
	
			vec3 N = surf_norm; // normalized
	
			vec3 q1perp = cross( q1, N );
			vec3 q0perp = cross( N, q0 );
	
			vec3 T = q1perp * st0.x + q0perp * st1.x;
			vec3 B = q1perp * st0.y + q0perp * st1.y;
	
			float det = max( dot( T, T ), dot( B, B ) );
			float scale = ( det == 0.0 ) ? 0.0 : faceDirection * inversesqrt( det );
	
			return normalize( T * ( mapN.x * scale ) + B * ( mapN.y * scale ) + N * mapN.z );
	
		}
	#endif
	`
}

{
	ShaderChunk['kr_roughnessmap_pars_fragment'] = 
	`
	#ifdef USE_ROUGHNESSMAP
	
		uniform sampler2D roughnessMap;
	
	#endif
	`
	
	ShaderChunk['kr_roughnessmap_fragment'] = 
	`
	float roughnessFactor = roughMetalTiling.x;
	
	#ifdef USE_ROUGHNESSMAP
	
		vec4 texelRoughness = texture2D( roughnessMap, vUv );
	
		// reads channel G, compatible with a combined OcclusionRoughnessMetallic (RGB) texture
		roughnessFactor *= texelRoughness.g;
	
	#endif
	
	`

}

{
	ShaderChunk['visibility_texture_pars_fragment'] = 
	`
	#if defined(VISIBILITY_FROM_TEXTURE)
		uniform sampler2D visibilityTexture;
	#endif
	`
	
	ShaderChunk['visibility_texture_fragment'] = 
	`
	#if defined(VISIBILITY_FROM_TEXTURE)
		float visibility = texture2D( visibilityTexture, vUv ).r;
		if (visibility < 0.999) {
			discard;
		}
	#endif
	`
}

{
	ShaderChunk['dithering'] = 
	`
	vec3 dithering( vec3 color ) {
        float grid_position = rand( gl_FragCoord.xy );
        vec3 dither_shift_RGB = vec3( 0.25 / 255.0, -0.25 / 255.0, 0.25 / 255.0 );
        dither_shift_RGB = mix( 2.0 * dither_shift_RGB, -2.0 * dither_shift_RGB, grid_position );
        return color + dither_shift_RGB;
    }
	`
}


ShaderChunk['sdfs'] = 
`
float boxDist(vec2 p, vec2 size, float radius)
{
	size -= vec2(radius);
	vec2 d = abs(p) - size;
	return min(max(d.x, d.y), 0.0) + length(max(d, 0.0)) - radius;
}

float smoothSdfBorderAlpha(float signedDistance) {

	return clamp(signedDistance * -1.0, 0., 1.0);

	// float aa = length(fwidth(signedDistance));

	// float alpha = smoothstep(0.5 * (stepWidth + aa), 0.5 * (stepWidth - aa), toEdgeDistance);

	// float alpha = smoothstep(0.0, 1.5, (0.5 - signedDistance) / a); // smoothstep with a 1.5 pixel falloff gets you a really smooth looking circle, where a 1 pixel linear falloff can still look minorly aliased
	// return fixed4(IN.color.rgb, IN.color.a * alpha);
}

`

ShaderChunk['common_math'] = 
`
vec2 fixAspect( vec4 i, float aspect ) {
	vec2 res = i.xy / i.w;
	res.x *= aspect;
	return res;
}
`


ShaderChunk['sample_grid_fn'] = 
`
 // https://bgolus.medium.com/the-best-darn-grid-shader-yet-727f9278b9d8
float sampleGrid(vec2 coords, vec2 gridSize, float lineW) {

	vec2 uv = coords / gridSize - vec2(0.5);

	float ratio = gridSize.x / gridSize.y;
	vec2 lineWidth = vec2(lineW, lineW * ratio);

	vec4 uvDDXY = vec4(dFdx(uv), dFdy(uv)); //
	vec2 uvDeriv = vec2(length(uvDDXY.xz), length(uvDDXY.yw)); //
	bvec2 invertLine = bvec2(lineWidth.x > 0.5, lineWidth.y > 0.5);
	
	// vec2 targetWidth = invertLine ? 1.0 - lineWidth : lineWidth;
	vec2 targetWidth = lineWidth;
	if (invertLine.x) {
		targetWidth.x = 1.0 - targetWidth.x;
	}
	if (invertLine.y) {
		targetWidth.y = 1.0 - targetWidth.y;
	}

	vec2 drawWidth = clamp(targetWidth, uvDeriv, vec2(0.5));
	vec2 lineAA = uvDeriv * 1.5;
	vec2 gridUV = abs(fract(uv) * 2.0 - 1.0);
	
	// gridUV = invertLine ? gridUV : 1.0 - gridUV;
	if (invertLine.x) {
		gridUV.x = 1.0 - gridUV.x;
	}
	if (invertLine.y) {
		gridUV.y = 1.0 - gridUV.y;
	}
	
	vec2 grid2 = smoothstep(drawWidth + lineAA, drawWidth - lineAA, gridUV);
	grid2 *= saturate(targetWidth / drawWidth);
	grid2 = mix(grid2, targetWidth, saturate(uvDeriv * 2.0 - 1.0));

	// grid2 = invertLine ? 1.0 - grid2 : grid2;
	if (invertLine.x) {
		grid2.x = 1.0 - grid2.x;
	}
	if (invertLine.y) {
		grid2.y = 1.0 - grid2.y;
	}

	float grid = mix(grid2.x, 1.0, grid2.y);
	return grid;
}

`
