import { AnyTrackerProps, Bim, getCharCodesForPileIndex, getRowIndex, getOrInitTrackerBins, getPileFeaturesWithUndulation, IdBimScene, NumberProperty, PileBinsConfig, pileCrossSectionToString, PileUndulationType, pileWeightPerSize, TrackerPile, getInRowIndex } from "bim-ts";
import { Result, Success, unitsConverter, Yield } from "engine-utils-ts";
import { KrMath, Vector3 } from "math-ts";
import { ExportedFileDescription } from "ui-bindings";


export function* writePilesProps(
    piles: TrackerPile[], 
    trackers: Map<IdBimScene, { props: AnyTrackerProps, block_number: number | undefined }>,
    bim: Bim,
    projectUnits: string,
    projectName?:string,
): Generator<Yield, Result<ExportedFileDescription>[], unknown> {
    const lengthUnit = projectUnits === "Imperial" ? 'ft' : 'm';
    const massUnit = projectUnits === "Imperial" ? 'lb' : 'kg';

    const roundTo = 1e-6;

    let content = `"X, ${lengthUnit}","Y, ${lengthUnit}","Z, ${lengthUnit}",Tracker_wind_load_position,Block#,Row#,Pile#,Pile_bin,Pile_type,"Pile_length, ${lengthUnit}","Pile_reveal, ${lengthUnit}",Pile_size,"Pile_weight, ${massUnit}","Angle_between_each_bay, deg"\n`;

    const cartesianOrigin = bim.instances.getSceneOrigin()?.cartesianCoordsOrigin ?? new Vector3();
    for (const pile of piles) {
        const trackerInfo = trackers.get(pile.parentId)!;
        const trackerProps = trackerInfo.props as AnyTrackerProps;

        const x = KrMath.roundTo(unitsConverter.convertValue(pile.position.x + cartesianOrigin.x, 'm', lengthUnit), roundTo);
        const y = KrMath.roundTo(unitsConverter.convertValue(pile.position.y + cartesianOrigin.y, 'm', lengthUnit), roundTo);
        const z = KrMath.roundTo(unitsConverter.convertValue(pile.position.z + cartesianOrigin.z, 'm', lengthUnit), roundTo);

        const trackerRowIndex = trackerProps.position.row_index  
            ? getRowIndex(trackerProps.position.row_index.value) + "." + getInRowIndex(trackerProps.position.row_index.value) 
            : undefined;
        
        const pileRowIndex = (trackerProps.piles.indices 
            && trackerProps.piles.indices.values[pile.inArrayIndex])
            ? getRowIndex(trackerProps.piles.indices.values[pile.inArrayIndex]!) : undefined;
        let pileRowCode: number[] | undefined = undefined;
        if (pileRowIndex !== undefined) {
            pileRowCode = [];
            getCharCodesForPileIndex(pileRowIndex - 1, pileRowCode);
        }

        const pileReveal = pile.getReveal();
        const pileRevealM = unitsConverter.convertValue(pileReveal.value, pileReveal.unit, 'm');

        let binIndex: number | undefined = undefined;
        const binsConfig = bim.configs.peekSingleton(PileBinsConfig.name)!;
        const trackerBins = getOrInitTrackerBins(trackerProps.tracker_frame.commercial.model.value, binsConfig, bim);
        if (trackerBins) {
            binIndex = trackerBins.bins.findIndex(b => b.maxReveal.as('m') >= pileRevealM);
            if (binIndex < 0 || binIndex === trackerBins.bins.length - 1) {
                binIndex = undefined;
            }
        }

        const pileLength = pile.getLength();

        const pileWeigthPerLength = pileWeightPerSize(pile.shape);
        const pileWeigthPerLengthKgDM = unitsConverter.convertValue(
            pileWeigthPerLength.value, pileWeigthPerLength.unit, 'kg/m'
        );
        const pileWeigthKg = unitsConverter.convertValue(pileLength.value, pileLength.unit, 'm') * pileWeigthPerLengthKgDM;

        const length = KrMath.roundTo(unitsConverter.convertValue(pileLength.value, pileLength.unit, lengthUnit), roundTo);
        const reveal = KrMath.roundTo(unitsConverter.convertValue(pileReveal.value, pileReveal.unit, lengthUnit), roundTo);
        const mass = KrMath.roundTo(unitsConverter.convertValue(pileWeigthKg, 'kg', massUnit), roundTo);
        const withoutUndulatedFlag = getPileFeaturesWithUndulation(pile.features, PileUndulationType.Undulated);

        const segmentsSlopes = trackerProps.piles._segments_slopes?.values;
        let bayToBayAngle = 0;
        if (segmentsSlopes
            && pile.inArrayIndex >= 1 
            && pile.inArrayIndex <= segmentsSlopes.length - 1
        ) {
            bayToBayAngle = KrMath.roundTo(KrMath.radToDeg(Math.abs(
                segmentsSlopes[pile.inArrayIndex] - segmentsSlopes[pile.inArrayIndex - 1]
            )), roundTo);
        }
    
        content += `${x},${y},${z},`
            + `${trackerProps.position.wind_load_position?.value ?? "N/A"},`
            + `${trackerInfo.block_number ?? "N/A"},`
            + `${trackerRowIndex ?? "N/A"},`
            + `${pileRowCode ? String.fromCharCode(...pileRowCode) : "N/A"},`
            + `${binIndex !== undefined ? binIndex + 1 : "Free length piles"},`
            + `${trackerBins?.getTypeByFeaturesKey(withoutUndulatedFlag)?.shortName.value ?? "N/A"},`
            + `${length},`
            + `${reveal},`
            + `${pileCrossSectionToString(pile.shape)},`
            + `${mass},`
            + `${bayToBayAngle}\n`;
    }

    const fileName = projectName ? `${projectName}-piles-properties` : "piles-properties";

    return [new Success({extension: 'csv', file: new TextEncoder().encode(content), name: fileName})];
}