// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class ObjectStateQuery implements flatbuffers.IUnpackableObject<ObjectStateQueryT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ObjectStateQuery {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsObjectStateQuery(bb:flatbuffers.ByteBuffer, obj?:ObjectStateQuery):ObjectStateQuery {
  return (obj || new ObjectStateQuery()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsObjectStateQuery(bb:flatbuffers.ByteBuffer, obj?:ObjectStateQuery):ObjectStateQuery {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ObjectStateQuery()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

identifier():string|null
identifier(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
identifier(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

knownHash():string|null
knownHash(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
knownHash(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startObjectStateQuery(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addIdentifier(builder:flatbuffers.Builder, identifierOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, identifierOffset, 0);
}

static addKnownHash(builder:flatbuffers.Builder, knownHashOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, knownHashOffset, 0);
}

static endObjectStateQuery(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createObjectStateQuery(builder:flatbuffers.Builder, identifierOffset:flatbuffers.Offset, knownHashOffset:flatbuffers.Offset):flatbuffers.Offset {
  ObjectStateQuery.startObjectStateQuery(builder);
  ObjectStateQuery.addIdentifier(builder, identifierOffset);
  ObjectStateQuery.addKnownHash(builder, knownHashOffset);
  return ObjectStateQuery.endObjectStateQuery(builder);
}

unpack(): ObjectStateQueryT {
  return new ObjectStateQueryT(
    this.identifier(),
    this.knownHash()
  );
}


unpackTo(_o: ObjectStateQueryT): void {
  _o.identifier = this.identifier();
  _o.knownHash = this.knownHash();
}
}

export class ObjectStateQueryT implements flatbuffers.IGeneratedObject {
constructor(
  public identifier: string|Uint8Array|null = null,
  public knownHash: string|Uint8Array|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const identifier = (this.identifier !== null ? builder.createString(this.identifier!) : 0);
  const knownHash = (this.knownHash !== null ? builder.createString(this.knownHash!) : 0);

  return ObjectStateQuery.createObjectStateQuery(builder,
    identifier,
    knownHash
  );
}
}
