import type { EnergyStageName, ValueRenderFormatter, PropertyBase, EnergyStageSettings, PropsGroupBase} from 'bim-ts';
import { NumberPropertyWithOptions, NumberProperty, BooleanProperty } from 'bim-ts';
import { type Bim, EnergyStageSettingsConfig, propsWithDifferentValueAt } from 'bim-ts';
import { LazyDerived, ObjectUtils, StringUtils } from 'engine-utils-ts';
import { PUI_CustomPropertyNode, PUI_GroupNode, PUI_PropertyNodeBool, PUI_PropertyNodeNumber } from 'ui-bindings';
import type { PUI_NumberWithOptionsContext } from '../../pui/custom-components/NumberPropertyWithOption/types';

export type EnergySetupSettings = Record<EnergyStageName, {enabled: PUI_PropertyNodeBool, pui: PUI_GroupNode}>;

const valueRenderFormatter: ValueRenderFormatter = {
    formatter: (args) => {
            if (args.option === 'calculate') {
                return '-';
            }
            const valAsStr = args.value.toFixed(2);
            return `${valAsStr} ${args.unit}`;
        },
    isReadonly: ({option}) => option === "calculate",
    textColorFormatter: (args) => {
        return args.option === "calculate" ? "#10131461": "inherit";
    },
    context: undefined
}

const puiCustomNames: Record<string, {name: string, description: string}> = {
    include_terrain: {
        name: "Shading from the terrain (for hilly sites)",
        description: "Terrain shading has a slight impact on results unless slopes on the site are at least 10˚. Enabling this option significantly slows down shading calculation."
    }
};

export function createEnergySetupPui(
    bim: Bim,
): LazyDerived<EnergySetupSettings> {

    const defaultConfig = ObjectUtils.deepFreeze(new EnergyStageSettingsConfig({}));

    return LazyDerived.new1(
        'energySetupUi',
        [],
        [bim.configs.getLazySingletonOf({type_identifier: EnergyStageSettingsConfig.name})],
        ([setupSettings]) => {
            const pui = {} as EnergySetupSettings;
            let energySetupProps = setupSettings.propsAs(EnergyStageSettingsConfig);

            const patchConfig = (path: string[], newValue: PropertyBase) => {
                const patched = propsWithDifferentValueAt(energySetupProps, newValue, path);
                if (patched) {
                    energySetupProps = patched; // for consecutive patches
                    bim.configs.applyPatchToSingleton(
                        EnergyStageSettingsConfig.name,
                        {
                            props: patched
                        }
                        
                    );
                }
            }

            for (const [stageName, stageSettings] of Object.entries(energySetupProps) as Array<[EnergyStageName, EnergyStageSettings<PropsGroupBase|null>|null]>) {
                const stageGroup = new PUI_GroupNode({
                    name: stageName,
                    sortChildren: false,
                });
                

                let enabledButton: PUI_PropertyNodeBool;

                if (stageSettings === null) {
                    enabledButton = new PUI_PropertyNodeBool({
                        name: 'enabled',
                        value: true,
                        readonly: true,
                        onChange: () => {},
                    })
                } else {
                    enabledButton = new PUI_PropertyNodeBool({
                        name: 'enabled',
                        value: stageSettings.enabled.value,
                        readonly: stageSettings.enabled.isReadonly,
                        onChange: (newValue) => {
                            const newProp = stageSettings.enabled.withDifferentValue(newValue);
                            
                            if ((stageName === 'shading' && newValue == false)
                                || (stageName === 'bifaciality' && newValue == true)
                            ) {
                                patchConfig(['shading', 'enabled'], newProp);
                                patchConfig(['bifaciality', 'enabled'], newProp);
                            } else {
                                patchConfig([stageName, 'enabled'], newProp);
                            }
                        }
                    });
                }

                pui[stageName] = {
                    pui: stageGroup,
                    enabled: enabledButton,
                };

                if (!stageSettings || !stageSettings.enabled.value) {
                    continue;
                }

                const selector = stageSettings.calc_path_selector;
                const loss = stageSettings.override_settings.output_correction;

                const perc_loss_default = defaultConfig[stageName]!.override_settings.output_correction;

                stageGroup.addMaybeChild(new PUI_CustomPropertyNode<NumberPropertyWithOptions, PUI_NumberWithOptionsContext>({
                    type_ident: "number-property-with-options",
                    name: "Energy Loss",
                    value: NumberPropertyWithOptions.new({
                        value: loss.value,
                        unit: loss.unit,
                        range: loss.range,
                        step: loss.step,
                        options: selector.options,
                        selectedOption: selector.value, 
                    }),
                    context: {
                        valueRenderFormatter,
                        icon: loss.value !== perc_loss_default.value ? {
                            iconName: "Restore",
                            onClick: () => {
                                patchConfig([stageName, 'override_settings', 'output_correction'], perc_loss_default);
                            }
                        }: undefined,
                    },
                    onChange: (newValue) => {
                        if (newValue.value !== loss.value) {
                            patchConfig([stageName, 'override_settings', 'output_correction'], loss.withDifferentValue(newValue.value));
                        } else if (newValue.selectedOption !== selector.value){
                            patchConfig([stageName, 'calc_path_selector'], selector.withDifferentValue(newValue.selectedOption));
                        } else {
                            console.warn(`unexpected change: Energy loss value and selector value are the same as the default value`);
                        }
                    }
                }));

                if (ObjectUtils.isObjectEmpty(stageSettings.calculation_settings) || selector.value !== 'calculate') {
                    continue;
                }

                for (const [settingName, prop] of Object.entries(stageSettings.calculation_settings!)) {

                    if (prop instanceof NumberProperty) {
                        const settingDefault = defaultConfig[stageName]!.calculation_settings![settingName] as NumberProperty;

                        stageGroup.addMaybeChild(new PUI_PropertyNodeNumber({
                            name: StringUtils.capitalizeFirstLatterInWord(settingName),
                            unit: prop.unit,
                            value: prop.value,
                            minMax: prop.range,
                            step: prop.step,
                            icon:  prop.value !== settingDefault.value ? {
                                iconName: "Restore",
                                onClick: () => {
                                    patchConfig([stageName, 'calculation_settings', settingName], settingDefault);
                                }
                            }: undefined,
                            onChange: (newValue) => {
                                patchConfig([stageName, 'calculation_settings', settingName], prop.withDifferentValue(newValue));
                            }
                        }));
                    } else if (prop instanceof BooleanProperty) {
                        stageGroup.addMaybeChild(new PUI_PropertyNodeBool({
                            name: puiCustomNames[settingName]?.name ?? StringUtils.capitalizeFirstLatterInWord(settingName),
                            value: prop.value,
                            description: puiCustomNames[settingName]?.description,
                            onChange: (newValue) => {
                                patchConfig([stageName, 'calculation_settings', settingName], prop.withDifferentValue(newValue));
                            }
                        }));
                    } else {
                        console.warn(`unexpected property type for ${settingName} in ${stageName}`);
                    }
                }


            }

            return pui;
        }
    ).withoutEqCheck();
}

