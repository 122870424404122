import type { LocalIdsCounter } from "bim-ts";
import { PolylineGeometry } from "bim-ts";
import type { Writeable } from "engine-utils-ts";
import { IterUtils } from "engine-utils-ts";
import type { Transform} from "math-ts";
import { Matrix4, Vector3 } from "math-ts";
import { InObjFullId, InObjIdType, InObjLocalId } from "../scene/EngineSceneIds";
import type { ESOHandle } from "./ESOsCollection";
import type { GeometryCtor} from "../scene/GeometryEditsHandler";
import { GeometryEditsHandler } from "../scene/GeometryEditsHandler";
import { GizmoEditUtils } from "../scene/GizmoEditUtils";
import type { ESO_Any } from "../esos/ESO";
import type { ESSO_Any, ESSO_InteractiveAny } from "../esos/ESSO";
import { ESSO_EditPoint } from "../esos/ESSO_EditPoint";

export class ESSO_EditPointPolyline extends ESSO_EditPoint<number> {

}


export class PolylineGeometryEditsHandler extends GeometryEditsHandler<PolylineGeometry> {

	geometryCtor(): GeometryCtor {
		return PolylineGeometry;
	}

	createEditReprImpl(handle: ESOHandle, obj: ESO_Any, geometryRef: Readonly<PolylineGeometry>, result: ESSO_Any[]): void {
		const geoPoints = Vector3.arrayFromFlatArray(geometryRef.points3d);
		for (let i = 0; i < geoPoints.length; ++i) {
			const point = geoPoints[i];
			const localId = geometryRef.pointsLocalIds[i];
			const pRepr = new ESSO_EditPointPolyline(
				obj,
				InObjFullId.new(handle, InObjLocalId.new(InObjIdType.EditPoint, localId)),
				{},
				new Matrix4().setPositionV(point),
				i
			);
			result.push(pRepr);
		}
	}

	patchPositions(
		geometryInOut: PolylineGeometry,
		localIdsGenerator: LocalIdsCounter,
		subobjs: ESSO_InteractiveAny[],
		positionsPatch: Map<InObjLocalId, Transform>,
	): {
	} {
		if (positionsPatch.size === 0) {
			return {toPatch: []};
		}
		
		const geoPointsIds = geometryInOut.pointsLocalIds;

		for (let i = 0; i < geoPointsIds.length; ++i) {
			const localId = InObjLocalId.new(InObjIdType.EditPoint, geoPointsIds[i]);
			const patch = positionsPatch.get(localId);
			if (patch) {
				const posPatch = patch.position;
				posPatch.toArray(geometryInOut.points3d, i * 3);
			}	
		}
		return {};
	}
	
	cloneParts(
		geometryInOut: Writeable<PolylineGeometry>,
		localIdsGenerator: LocalIdsCounter,
		subobjs: ESSO_InteractiveAny[],
		idsToClone: InObjLocalId[],
		localSpaceDirection: Vector3,
	): {
		added: InObjLocalId[],
        toUseForGesture?: InObjLocalId[],
    } {

		const geoPoints = Vector3.arrayFromFlatArray(geometryInOut.points3d);
		const geoPointsIds = geometryInOut.pointsLocalIds;

		const pointIdsToClone = IterUtils.filterMap(idsToClone, id => id.ty === InObjIdType.EditPoint ? id.localId : undefined)

		const pointsIndices = GizmoEditUtils.mapToIndicesInByProp(pointIdsToClone, geoPointsIds, id => id);
	    const segment = GizmoEditUtils.getPointsIndicesAsConsecutiveSegment(pointsIndices, geoPoints.length);

		let added: InObjLocalId[] = [];
		let toUseForGesture: InObjLocalId[] | undefined;

        if (segment) {
            const {indices, crossesZero} = segment;
            const firstPointIndex = indices[0];
            const secondPointIndex = indices[indices.length - 1];
            // insert new points instead of first and last points, and move the rest

            const firstPointToClone = geoPoints[firstPointIndex];
            const secondtPointToClone = geoPoints[secondPointIndex];

			let pointsToClone = [firstPointToClone, secondtPointToClone];
			let insertionPoints: [number, number];
            if (crossesZero) {
                insertionPoints = [firstPointIndex + 1, secondPointIndex + 1];
            } else {
                insertionPoints = [firstPointIndex, secondPointIndex + 2];
            }
			const idsCloned = [localIdsGenerator.nextId(), localIdsGenerator.nextId()];

			for (let i = 0; i < insertionPoints.length; ++i) {
				geoPoints.splice(insertionPoints[i], 0, pointsToClone[i].clone());
				geoPointsIds.splice(insertionPoints[i], 0, idsCloned[i]);
			}

			added = idsCloned.map(id => InObjLocalId.new(InObjIdType.EditPoint, id));
			toUseForGesture = idsToClone;

        } else if (pointsIndices.length == 1) {
            const pointIndex = pointsIndices[0];
            if (pointIndex >= 0) {
				const pointToClone = geoPoints[pointIndex];
				const insertionIndex = GizmoEditUtils.chooseNewPointInsertionIndex({
                    pointIndex,
                    currentPoints: geoPoints,
                    gestureDirection: localSpaceDirection,
                    closeFirstToLast: false
                });
				const id = localIdsGenerator.nextId();

				geoPoints.splice(insertionIndex, 0, pointToClone.clone());
				geoPointsIds.splice(insertionIndex, 0, id);

				added = [InObjLocalId.new(InObjIdType.EditPoint, id)];
				toUseForGesture = undefined;
            }

        }

		geometryInOut.points3d = Vector3.arrToDoubleArr(geoPoints);

        return {added, toUseForGesture};
	}

	deleteParts(
		geometryInOut: Writeable<PolylineGeometry>,
		localIdsGenerator: LocalIdsCounter,
		subobjs: Readonly<ESSO_InteractiveAny>[],
		idsToDelete: InObjLocalId[],
	): {} {
		const points = Vector3.arrayFromFlatArray(geometryInOut.points3d);
		GizmoEditUtils.deleteBySeparateKeys(
			geometryInOut.pointsLocalIds,
			points,
			IterUtils.filterMap(idsToDelete, id => id.ty === InObjIdType.EditPoint ? id.localId : undefined),
		)
		geometryInOut.points3d = Vector3.arrToDoubleArr(points);
		return {};
	}

}