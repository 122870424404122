import type { Result } from "engine-utils-ts";
import { Failure, Success } from "engine-utils-ts";
import type { Bim, IdBimScene } from "src";
import type { Object2D } from "vector-graphic";
import { createSVGFromObject2D } from "vector-graphic";
import { generateSLDForTransformer as createCIScheme } from "./central-inverter-chain-scheme";
import { generateSLDForTransformer as createSIScheme } from "./string-inverter-chain-scheme";
import { getBlockPattern } from "./utils";

export function generateSLDForTransformerSVG(
    bim: Bim,
    transformerId: IdBimScene
): Result<SVGSVGElement> {
    const objResult = generateSLDForTransformer(bim, transformerId);
    if (objResult instanceof Failure) {
        return objResult;
    }
    const svg = createSVGFromObject2D(objResult.value);
    return new Success(svg);
}

export function generateSLDForTransformer(
    bim: Bim,
    transformerId: IdBimScene
): Result<Object2D> {
    const transformerPattern = getBlockPattern(transformerId, bim);
    if (transformerPattern instanceof Failure) {
        return transformerPattern;
    }
    let scene: Result<Object2D>;
    if (transformerPattern.value === 'SI_Multiharness') {
        scene = createSIScheme(bim, transformerId)
    } else {
        scene = createCIScheme(bim, transformerId)
    }
    return scene;
}
