<script lang="ts">
    import { DialogDescription, UiBindings, type PUI_ActionDescr, type PUI_CustomPropertyNode, UiCustomNode } from 'ui-bindings';
    import { getContext } from 'svelte';
    import type { EnergyYieldPropsGroup, EnergyPipelineProperty } from 'bim-ts';
    import ActionProperty from '../pui/ActionProperty.svelte';
    import EnergyReportChart from './chart/EnergyReportChart.svelte';
    

    export let pui_property: PUI_CustomPropertyNode<EnergyPipelineProperty, EnergyYieldPropsGroup>;

    const uiBindings: UiBindings = getContext("uiBindings");
    
    $: property = pui_property.value;

    const openTableAction: PUI_ActionDescr<any> = {
        label: 'Overview',
        action: () => {
                uiBindings.dialogsStream.pushNext(new DialogDescription({
                name: 'Energy Pipeline Overview',
                context: null,
                uiSource: new UiCustomNode({context: property}),
            }));
        }
    }

</script>

<div class="property property-row">
    <EnergyReportChart
        property = {property}
    ></EnergyReportChart>

    <span class="ui-config-property property-row buttons">
        <ActionProperty
            actionDescr={openTableAction}
            context={null}
        ></ActionProperty>
    </span>
</div>

<style lang="scss">
    .property {
        justify-content: flex-end;
        margin-left: auto;
        width: 98%;
    }
</style>

