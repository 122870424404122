import type { LazyVersioned} from "engine-utils-ts";
import { VersionedInvalidator, type VersionedValue } from "engine-utils-ts";
import type { Project } from "../projects";
import type { ProjectMetricsType } from "bim-ts";

export interface ComparisonItemView {
    id: string;
    projectVersion: number;
    catalogVersion: number;
    projectName: string;
    header: string;
    metrics: Partial<ProjectMetricsType> | null;
}

export class ZeroVersionIdentifier {
    readonly id = 0;
    public readonly textDescription?: LazyVersioned<string | undefined>;
}
export class VerdataVersionDescription {
    constructor(
        public readonly id: number,
        public readonly textDescription?: LazyVersioned<string | undefined>,
    ){}
}

export class CurrentVersion {
    readonly id = -1;
    constructor(
        public readonly textDescription: LazyVersioned<string | undefined>,
    ) {}
}

export class ComparisonItem implements VersionedValue {
    public id: string;
    private _invalidator: VersionedInvalidator;

    constructor(
        public project: Project,
        public projectVersion: VerdataVersionDescription | ZeroVersionIdentifier | CurrentVersion,
        public catalogVersion: VerdataVersionDescription | ZeroVersionIdentifier | CurrentVersion,
        public metrics: LazyVersioned<Partial<ProjectMetricsType> | null>,
    ) {
        this._invalidator = new VersionedInvalidator();
        this._invalidator.addDependency(metrics);
        if(projectVersion.textDescription){
            this._invalidator.addDependency(projectVersion.textDescription);
        }
        this.id = ComparisonMode.createItemIdFromItem(this);
    }

    version(): number {
        return this._invalidator.version();
    }
}

export class ComparisonMode implements VersionedValue {
    private _invalidator: VersionedInvalidator;
    items = new Map<string, ComparisonItem>();

    constructor() {
        this._invalidator = new VersionedInvalidator();
    }

    version(): number {
        return this._invalidator.version();
    }

    add(item: ComparisonItem) {
        this.items.set(item.id, item);
        this._invalidator.invalidate();
        this._invalidator.addDependency(item);
    }

    remove(id: string) {
        const item = this.items.get(id);
        if(!item){
            return;
        }
        this._invalidator.removeDependency(item);
        this.items.delete(id);
        this._invalidator.invalidate();
    }

    static createItemIdFromItem(item: Omit<ComparisonItem, "metrics">){
        return this.createItemId(item.project.id, item.projectVersion.id, item.catalogVersion.id);
    }
    
    static createItemId(projectId: number, projectVerdataId: number | string, catalogVeradataId: number | string){
        return `project:${projectId};version:${projectVerdataId};catalog:${catalogVeradataId};`;
    }
}
