import { DefaultMapWeak, ObjectUtils } from 'engine-utils-ts';
import { KrMath } from 'math-ts';

export interface ChartSharedZoomState {
    readonly start: number;
    readonly end: number;
}

export type SharedZoomStateChangedCallback = (
    sourceChartInstance: Symbol,
    zoomSouceObject: Object,
    zoomState: ChartSharedZoomState
) => void

export class SharedChartsZoomStates {


    private _shared_zoom_states = new DefaultMapWeak<Object, ChartSharedZoomState>(
        () => {
            return {start: 0, end: 100};
        }
    );
    
    _callbacks: Set<SharedZoomStateChangedCallback> = new Set();
    _ignoreNewPushes = false;

    getStateForZoomSourceObject(zoomSourceObject: Object): ChartSharedZoomState{
        return this._shared_zoom_states.getOrCreate(zoomSourceObject);
    }

    pushNewStateForZoomSourceObject(sourceChartInstance: Symbol, zoomSourceObject: Object, zoomState: ChartSharedZoomState): void {
        if (this._ignoreNewPushes) {
            return;
        }
        if (KrMath.clamp(zoomState.start, 0, 100) !== zoomState.start) {
            console.error('invalid zoom state start', zoomState.start);
            return;
        }
        if (KrMath.clamp(zoomState.end, 0, 100) !== zoomState.end) {
            console.error('invalid zoom state end', zoomState.end);
            return;
        }
        let savedState = this._shared_zoom_states.get(zoomSourceObject);
        if (ObjectUtils.areObjectsEqual(zoomSourceObject, savedState)) {
            return;
        }
        this._shared_zoom_states.set(zoomSourceObject, zoomState);
        this._ignoreNewPushes = true;
        try {
            for (const callback of this._callbacks) {
                try {
                    callback(sourceChartInstance, zoomSourceObject, zoomState);
                } catch (e) {
                    console.error('error during shared zoom state callback', e);
                    this._callbacks.delete(callback);
                }
            }
        } finally {
            this._ignoreNewPushes = false;
        }

    }

    subscibeToZoomStatesUpdates(callback:SharedZoomStateChangedCallback): () => void {
        this._callbacks.add(callback);
        return () => {
            this._callbacks.delete(callback);
        };
    }
}