import { NumberProperty, StringProperty, BooleanProperty } from '../properties/PrimitiveProps';
import { PropsGroupsRegistry } from '../properties/PropsGroupsRegistry';
import { PropsGroupBase } from '../properties/Props';
import { newPileFeatures, PileDamperType, type PileFeaturesFlags, PileMotorType, PileStrengthModifier, PileUndulationType, PileWeightClass } from '../anyTracker/PilesFeatures';
import { PileProfileType } from '../anyTracker/PileProfileType';

export class PileTypeSettings extends PropsGroupBase {
    enabled: BooleanProperty;
    shortName: StringProperty;
    pileFeatures: NumberProperty;

    constructor(args: Partial<PileTypeSettings>) {
        super();
        this.enabled = args.enabled ?? BooleanProperty.new({ value: true });
        this.shortName = args.shortName ?? StringProperty.new({ value: 'unknown' });
        this.pileFeatures = args.pileFeatures ?? NumberProperty.new({ value: 0 });

        Object.freeze(this);
    }

    static newEnabled(name: string) {
        return new PileTypeSettings({
            enabled: BooleanProperty.new({ value: true }),
            shortName: StringProperty.new({ value: name })
        })
    }

    static newDisabled(name: string) {
        return new PileTypeSettings({
            enabled: BooleanProperty.new({ value: false }),
            shortName: StringProperty.new({ value: name })
        })
    }

    withPileFeatures(pileFeatures: PileFeaturesFlags): PileTypeSettings {
        return new PileTypeSettings({
            ...this,
            pileFeatures: NumberProperty.new({ value: pileFeatures })
        });
    }
}

PropsGroupsRegistry.register({
    class: PileTypeSettings,
    complexDefaults: {}
});

interface TypeSettingDescription {
    settings: PileTypeSettings,
    profile: NumberProperty,
}

export const DefaultPileTypes = new Map<PileFeaturesFlags, TypeSettingDescription>([
    [
        newPileFeatures(PileWeightClass.Standard, PileStrengthModifier.None, PileMotorType.None, PileDamperType.None, PileUndulationType.Undulated), 
        {
            settings: PileTypeSettings.newEnabled("SAP"),
            profile: NumberProperty.new({ value: PileProfileType.W6x7 })
        }
    ],
    [
        newPileFeatures(PileWeightClass.Standard, PileStrengthModifier.None, PileMotorType.None, PileDamperType.Damper, PileUndulationType.Undulated),
        {
            settings: PileTypeSettings.newDisabled("SAPD"),
            profile: NumberProperty.new({ value: PileProfileType.W6x7 })
        }
    ],
    [
        newPileFeatures(PileWeightClass.Standard, PileStrengthModifier.Edge, PileMotorType.None, PileDamperType.None, PileUndulationType.Undulated),
        {
            settings: PileTypeSettings.newEnabled("SAE"),
            profile: NumberProperty.new({ value: PileProfileType.W6x8dot5 })
        }
    ],
    [
        newPileFeatures(PileWeightClass.Standard, PileStrengthModifier.Edge, PileMotorType.None, PileDamperType.Damper, PileUndulationType.Undulated),
        {
            settings: PileTypeSettings.newEnabled("SAED"),
            profile: NumberProperty.new({ value: PileProfileType.W6x7 })
        }
    ],
    [
        newPileFeatures(PileWeightClass.Standard, PileStrengthModifier.Cantilever, PileMotorType.None, PileDamperType.None, PileUndulationType.Undulated),
        {
            settings: PileTypeSettings.newDisabled("SC"),
            profile: NumberProperty.new({ value: PileProfileType.W6x7 })
        }
    ],
    [
        newPileFeatures(PileWeightClass.Standard, PileStrengthModifier.Cantilever, PileMotorType.None, PileDamperType.Damper, PileUndulationType.Undulated),
        {
            settings: PileTypeSettings.newEnabled("SCD"),
            profile: NumberProperty.new({ value: PileProfileType.W6x7 })
        }
    ],
    [
        newPileFeatures(PileWeightClass.Standard, PileStrengthModifier.None, PileMotorType.Motor, PileDamperType.None, PileUndulationType.Undulated),
        {
            settings: PileTypeSettings.newEnabled("SMP"),
            profile: NumberProperty.new({ value: PileProfileType.W6x10dot5 })
        }
    ],

    [
        newPileFeatures(PileWeightClass.Heavy, PileStrengthModifier.None, PileMotorType.None, PileDamperType.None, PileUndulationType.Undulated),
        {
            settings: PileTypeSettings.newEnabled("HAP"),
            profile: NumberProperty.new({ value: PileProfileType.W6x9 })
        }
    ],
    [
        newPileFeatures(PileWeightClass.Heavy, PileStrengthModifier.None, PileMotorType.None, PileDamperType.Damper, PileUndulationType.Undulated),
        {
            settings: PileTypeSettings.newDisabled("HAPD"),
            profile: NumberProperty.new({ value: PileProfileType.W6x9 })
        }
    ],
    [
        newPileFeatures(PileWeightClass.Heavy, PileStrengthModifier.Edge, PileMotorType.None, PileDamperType.None, PileUndulationType.Undulated),
        {
            settings: PileTypeSettings.newEnabled("HAE"),
            profile: NumberProperty.new({ value: PileProfileType.W6x9 })
        }
    ],
    [
        newPileFeatures(PileWeightClass.Heavy, PileStrengthModifier.Edge, PileMotorType.None, PileDamperType.Damper, PileUndulationType.Undulated),
        {
            settings: PileTypeSettings.newEnabled("HAED"),
            profile: NumberProperty.new({ value: PileProfileType.W6x10dot5 })
        }
    ],
    [
        newPileFeatures(PileWeightClass.Heavy, PileStrengthModifier.Cantilever, PileMotorType.None, PileDamperType.None, PileUndulationType.Undulated),
        {
            settings: PileTypeSettings.newDisabled("HC"),
            profile: NumberProperty.new({ value: PileProfileType.W6x9 })
        }
    ],
    [
        newPileFeatures(PileWeightClass.Heavy, PileStrengthModifier.Cantilever, PileMotorType.None, PileDamperType.Damper, PileUndulationType.Undulated),
        {
            settings: PileTypeSettings.newEnabled("HCD"),
            profile: NumberProperty.new({ value: PileProfileType.W6x9 })
        }
    ],
    [
        newPileFeatures(PileWeightClass.Heavy, PileStrengthModifier.None, PileMotorType.Motor, PileDamperType.None, PileUndulationType.Undulated),
        {
            settings: PileTypeSettings.newEnabled("HMP"),
            profile: NumberProperty.new({ value: PileProfileType.W6x15 })
        }
    ],
]);

export class TypeDescription extends PropsGroupBase {
    profile: NumberProperty;
    length: NumberProperty;
    pileFeatures: NumberProperty;

    constructor(args: Partial<TypeDescription>) {
        super();
        this.profile = args.profile ?? NumberProperty.new({ value: PileProfileType.None });
        this.length = args.length ?? NumberProperty.new({ value: 2.4384, unit: 'm'});
        this.pileFeatures = args.pileFeatures ?? NumberProperty.new({ value: 0, isReadonly: true});
        Object.freeze(this);
    }

    withDifferentProfile(value: PileProfileType): TypeDescription {
        return new TypeDescription({
            ...this,
            profile: this.profile.withDifferentValue(value)
        });
    }

    withDifferentLength(value: number): TypeDescription {
        return new TypeDescription({
            ...this,
            length: this.length.withDifferentValue(value)
        });
    }
}

PropsGroupsRegistry.register({
    class: TypeDescription,
    complexDefaults: {
    }
});