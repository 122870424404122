import type { Bim, IdBimScene, IrregularHeightmapGeometry, RegularHeightmapGeometry, SceneInstance} from "bim-ts";
import { SceneInstances, TerrainHeightMapRepresentation } from "bim-ts";
import { Failure, Success } from "engine-utils-ts";
import { Vector3 } from "math-ts";
import { ITinSurface } from "./ITinSurface";


export function CreateLandXmlString(bim: Bim, instanceId: IdBimScene, trianglesSize: number): Failure | Success<string> {
    const tinSurface = ConvertTerrainToTin(bim, instanceId, trianglesSize);

    if (tinSurface instanceof Success) {
        const landXmLText = ConvertTinSurfaceToXml(tinSurface.value);
        return new Success(landXmLText);
    }

    return tinSurface;
}

function binarySearch(arr: number[], target: number): { exists: boolean, position: number } {
    let left: number = 0;
    let right: number = arr.length - 1;
    let mid: number = 0;
  
    while (left <= right) {
        mid = Math.floor((left + right) / 2);

        if (arr[mid] === target) {
            return { exists: true, position: mid };
        } 
        if (target < arr[mid]) {
            right = mid - 1;
        } else {
            left = mid + 1;
        }
    }
    
    return { exists: false, position: left };
}

export function ConvertTerrainToTin(bim: Bim, instanceId: IdBimScene, trianglesSize: number) {

    const instance = bim.instances.peekById(instanceId);

    if (!instance || !(instance.representation instanceof TerrainHeightMapRepresentation)) {
        return new Failure({msg: "Export land to xml: Instance is not a land!"});
    }

    const origin3d = bim.instances.getSceneOrigin()?.cartesianCoordsOrigin ?? new Vector3(0, 0, 0);

    const heightMap = instance.representation;

    let ind = 0;
    const points: Vector3[] = [];
    const triangles: [number, number, number][] = [];

    const name = SceneInstances.uiNameFor(instanceId, instance);
    const description = "TinSurface";
    const projectName = window.location.pathname.slice(1);

    for (const [terrainTileId, terrainTile] of heightMap.tiles) {

        const tileGeoId = terrainTile.updatedGeo === 0 ? terrainTile.initialGeo : terrainTile.updatedGeo;
        let tileGeo: IrregularHeightmapGeometry | RegularHeightmapGeometry | undefined = bim.irregularHeightmapGeometries.peekById(tileGeoId);

        if (tileGeo) {
            const vertices = Vector3.arrayFromFlatArray(tileGeo.points3d);

            for (let v of vertices) {

                let v1 = new Vector3(v.x, v.y, v.z);

                v1 = v1.applyMatrix4(instance.worldMatrix);
                v1 = v1.add(origin3d);
                points.push(v1);
            }

            for (let i = 0; i < tileGeo.trianglesIndices.length; i += 3) {
                const ind0 = tileGeo.trianglesIndices[i] + ind;
                const ind1 = tileGeo.trianglesIndices[i + 1] + ind;
                const ind2 = tileGeo.trianglesIndices[i + 2] + ind;

                triangles.push([ind0, ind1, ind2]);
            }

            ind += vertices.length;
        } else {
            tileGeo = bim.regularHeightmapGeometries.peekById(tileGeoId);

            if (tileGeo) {
                const tileOffset = terrainTileId.localOffset(heightMap.tileSize);
                const unusedPoints = new Array<number>();

                const xPointsCount = 1 + tileGeo.xSegmentsCount / trianglesSize;
                const yPointsCount = 1 + tileGeo.ySegmentsCount / trianglesSize;
                
                for (let iy = 0; iy <= tileGeo.ySegmentsCount; iy += trianglesSize) {
                    for (let ix = 0; ix <= tileGeo.xSegmentsCount; ix += trianglesSize) {
                        const elevation = tileGeo.readElevationAtInds(ix, iy);
                        if (Number.isNaN(elevation)) {
                            unusedPoints.push((ix / trianglesSize) + (iy / trianglesSize) * xPointsCount);
                        } else {
                            const v = new Vector3(
                                tileOffset.x + ix * tileGeo.segmentSizeInMeters, 
                                tileOffset.y + iy * tileGeo.segmentSizeInMeters, 
                                elevation
                            );
                            v.applyMatrix4(instance.worldMatrix);
                            v.add(origin3d);
                            points.push(v);
                        }
                    }
                }

                for (let iy = 0; iy < yPointsCount - 1; ++iy) {
                    for (let ix = 0; ix < xPointsCount - 1; ++ix) {
                        const p00status = binarySearch(unusedPoints, ix + iy * xPointsCount);
                        const p10status = binarySearch(unusedPoints, ix + 1 + iy * xPointsCount);
                        if (p00status.exists) {
                            if (!p10status.exists) {
                                const p01status = binarySearch(unusedPoints, ix + (iy + 1) * xPointsCount);
                                const p11status = binarySearch(unusedPoints, ix + 1 + (iy + 1) * xPointsCount);
                                if (!p01status.exists && !p11status.exists) {
                                    triangles.push([
                                        ind + ix + 1 + iy * xPointsCount - p10status.position, 
                                        ind + ix + (iy + 1) * xPointsCount - p01status.position, 
                                        ind + ix + 1 + (iy + 1) * xPointsCount - p11status.position
                                    ]);
                                }
                            }
                        } else {
                            const p01status = binarySearch(unusedPoints, ix + (iy + 1) * xPointsCount);
                            if (p10status.exists) {
                                const p11status = binarySearch(unusedPoints, ix + 1 + (iy + 1) * xPointsCount);
                                if (!p01status.exists && !p11status.exists) {
                                    triangles.push([
                                        ind + ix + iy * xPointsCount - p00status.position, 
                                        ind + ix + (iy + 1) * xPointsCount - p01status.position, 
                                        ind + ix + 1 + (iy + 1) * xPointsCount - p11status.position
                                    ]);
                                }
                            }
                            else {
                                const p11status = binarySearch(unusedPoints, ix + 1 + (iy + 1) * xPointsCount);
                                if (p01status.exists) {
                                    if (!p11status.exists) {
                                        triangles.push([
                                            ind + ix + iy * xPointsCount - p00status.position, 
                                            ind + ix + 1 + (iy + 1) * xPointsCount - p11status.position,
                                            ind + ix + 1 + iy * xPointsCount - p10status.position, 
                                        ]);
                                    }
                                } else {
                                    if (p11status.exists) {
                                        triangles.push([
                                            ind + ix + iy * xPointsCount - p00status.position, 
                                            ind + ix + (iy + 1) * xPointsCount - p01status.position,
                                            ind + ix + 1 + iy * xPointsCount - p10status.position, 
                                        ]);
                                    } else {
                                        const p00 = points[ind + ix + iy * xPointsCount - p00status.position].z;
                                        const p10 = points[ind + ix + 1 + iy * xPointsCount - p00status.position].z;
                                        const p01 = points[ind + ix + (iy + 1) * xPointsCount - p01status.position].z;
                                        const p11 = points[ind + ix + 1 + (iy + 1) * xPointsCount - p11status.position].z;
                                        if ((p00 + p11 === p10 + p01) && !(p00 === p10 && p00 === p01)) {
                                            if (Math.abs(p11 - p00) <= Math.abs(p01 - p10)) {
                                                triangles.push([
                                                    ind + ix + iy * xPointsCount - p00status.position, 
                                                    ind + ix + (iy + 1) * xPointsCount - p01status.position, 
                                                    ind + ix + 1 + (iy + 1) * xPointsCount - p11status.position
                                                ]);
                                                triangles.push([
                                                    ind + ix + iy * xPointsCount - p00status.position, 
                                                    ind + ix + 1 + (iy + 1) * xPointsCount - p11status.position,
                                                    ind + ix + 1 + iy * xPointsCount - p10status.position, 
                                                ]);
                                            } else {
                                                triangles.push([
                                                    ind + ix + iy * xPointsCount - p00status.position, 
                                                    ind + ix + (iy + 1) * xPointsCount - p01status.position, 
                                                    ind + ix + 1 + (iy + 1) * xPointsCount - p11status.position
                                                ]);
                                                triangles.push([
                                                    ind + ix + iy * xPointsCount - p00status.position, 
                                                    ind + ix + 1 + (iy + 1) * xPointsCount - p11status.position,
                                                    ind + ix + 1 + iy * xPointsCount - p10status.position, 
                                                ]);
                                            }

                                            continue;
                                        }

                                        const cx = (ix + 0.5) * trianglesSize * tileGeo.segmentSizeInMeters;
                                        const cy = (iy + 0.5) * trianglesSize * tileGeo.segmentSizeInMeters;
                                        const v = new Vector3(
                                            tileOffset.x + cx, 
                                            tileOffset.y + cy, 
                                            0
                                        );
                                        v.applyMatrix4(instance.worldMatrix);
                                        v.add(origin3d);
                                        v.z = 0.25 * (p00 + p10 + p01 + p11);
                                        points.push(v);

                                        triangles.push([
                                            ind + ix + iy * xPointsCount - p00status.position, 
                                            ind + ix + (iy + 1) * xPointsCount - p01status.position, 
                                            points.length - 1
                                        ]);
                                        triangles.push([
                                            ind + ix + iy * xPointsCount - p00status.position, 
                                            points.length - 1,
                                            ind + ix + 1 + iy * xPointsCount - p10status.position, 
                                        ]);
                                        triangles.push([
                                            ind + ix + 1 + (iy + 1) * xPointsCount - p11status.position,
                                            points.length - 1,
                                            ind + ix + (iy + 1) * xPointsCount - p01status.position, 
                                        ]);
                                        triangles.push([
                                            ind + ix + 1 + (iy + 1) * xPointsCount - p11status.position,
                                            ind + ix + 1 + iy * xPointsCount - p10status.position,
                                            points.length - 1,
                                        ]);
                                    }
                                }
                            }
                        }
                    }
                }
    
                ind = points.length;
            }
        }
    }

    const tinModel: ITinSurface = {
        Name: name,
        Description: description,
        ProjectName: projectName,
        Points: points,
        Triangles: triangles
    };

    return new Success(tinModel)
}

function ConvertTinSurfaceToXml(tinModel: ITinSurface): string {

    const j = 5; //start count index for points

    let sb = '<?xml version="1.0"?>' +
        '<LandXML  xmlns="http://www.landxml.org/schema/LandXML-1.2" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.landxml.org/schema/LandXML-1.2 http://www.landxml.org/schema/LandXML-1.2/LandXML-1.2.xsd" date="" time="" version="1.2" language="English" readOnly="false">' +
        '<Units>' +
        `<Metric areaUnit="squareMeter" linearUnit="meter" volumeUnit="cubicMeter" temperatureUnit="celsius" pressureUnit="milliBars" diameterUnit="millimeter" angularUnit="decimal degrees" directionUnit="decimal degrees"></Metric>` +
        '</Units>' +
        '<CoordinateSystem></CoordinateSystem>' +
        `<Project name="${tinModel.ProjectName}"></Project>` +
        '<Application name="SolarFarm" desc="SolarFarm" manufacturer="PVFARM"></Application>' +
        '<Surfaces>';

    sb += `<Surface xmlns="" name="${tinModel.Name}" desc="${tinModel.Description}">`;
    sb += '<SourceData>';
    sb += '</SourceData>';
    sb += '<Definition surfType="TIN">'

    sb += '<Pnts>'

    for (let i = 0; i < tinModel.Points.length; i++) {
        const point = tinModel.Points[i];
        sb += `<P id="${i + j}">${point.y} ${point.x} ${point.z}</P>`
    }
    sb += '</Pnts>'

    sb += '<Faces>'
    for (let i = 0; i < tinModel.Triangles.length; i++) {
        const triangle = tinModel.Triangles[i];
        sb += `<F>${triangle[0] + j} ${triangle[1] + j} ${triangle[2] + j}</F>`
    }

    sb += '</Faces>'

    sb += '</Definition>'
    sb += '</Surface>';
    sb += '</Surfaces>';
    sb += '</LandXML>';

    return sb;
}


