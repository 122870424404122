import type {
	PersistedCollectionConfig, VerDataPersistedCollection,
} from 'verdata-ts';

import type {
	Bim, BimImage, BimMaterial, CubeGeometry, ExtrudedPolygonGeometry,
	GraphGeometry, PolylineGeometry,
	TriGeometry} from './';
import { RegularHeightmapGeometry
} from './';
import { Config } from './bimConfigs/ConfigsCollection';
import type {
	IrregularHeightmapGeometry,
} from './geometries/IrregularHeightmapGeometries';
import { BimImagesSerializer } from './persistence/BimImagesSerializer';
import { BimMaterialsSerializer } from './persistence/BimMaterialsSerializer';
import { bimImageMigration } from './persistence/migrations/ImagesMigrations';
import { configsCollectionMigration } from './persistence/migrations/ConfigsMigrations'
import { ConfigsSerializer } from './persistence/ConfigSerializer';
import { EntitiesPersisted } from './persistence/EntitiesPersisted';
import {
	CubesSerializer, ExtrudedPolygonsSerializer, GraphsSerializer,
	IrregularHeightmapsSerializer, PolylinesSerializer,
	RegularHeightmapsSerializer, TriGeosSerializer,
} from './persistence/GeometriesSerializers';
import {
	SceneInstanceSerializable
} from './persistence/SceneInstancesSerializer';
import { sceneInstanceMigration } from './persistence/migrations/SceneInstancesMigrations';
import { SceneInstanceEntitiesPersisted } from './persistence/SceneInstanceEntitiesPersisted';


export function createPersistedBimCollections(bim: Bim): [PersistedCollectionConfig, VerDataPersistedCollection<any, any>][] {
    const res: [PersistedCollectionConfig, VerDataPersistedCollection<any, any>][] = [];

    res.push([
        {
            identifier: 'bim-images',
            loadAfter: [],
            loadInOneTickWith: [],
            objectCountInBatchHint: 10,
        },
        new EntitiesPersisted<BimImage>({
            entities: bim.bimImages,
            serializer: new BimImagesSerializer(bimImageMigration),
        })
    ]);

    res.push([
        {
            identifier: 'bim-materials',
            loadAfter: ['bim-images'],
            loadInOneTickWith: [],
            objectCountInBatchHint: 100,
        },
        new EntitiesPersisted<BimMaterial>({
            entities: bim.bimMaterials,
            serializer: new BimMaterialsSerializer(),
        })
    ]);

    res.push([
        {
            identifier: 'tri-geometries',
            loadAfter: [],
            loadInOneTickWith: [],
            objectCountInBatchHint: 1000,
        },
        new EntitiesPersisted<TriGeometry>({
            entities: bim.triGeometries,
            serializer: new TriGeosSerializer(),
        })
    ]);

    res.push([
        {
            identifier: 'polyline-geometries',
            loadAfter: [],
            loadInOneTickWith: [],
            objectCountInBatchHint: 4000,
        },
        new EntitiesPersisted<PolylineGeometry>({
            entities: bim.polylineGeometries,
            serializer: new PolylinesSerializer(),
        })
    ]);

    res.push([
        {
            identifier: 'cube-geometries',
            loadAfter: [],
            loadInOneTickWith: [],
            objectCountInBatchHint: 1000,
        },
        new EntitiesPersisted<CubeGeometry>({
            entities: bim.cubeGeometries,
            serializer: new CubesSerializer(),
        })
    ]);

    res.push([
        {
            identifier: 'regular-heightmap-geometries',
            loadAfter: [],
            loadInOneTickWith: [],
            objectCountInBatchHint: 400,
        },
        new EntitiesPersisted<RegularHeightmapGeometry>({
            entities: bim.regularHeightmapGeometries,
            serializer: new RegularHeightmapsSerializer(),
            dataEq: RegularHeightmapGeometry.eq,
            serializeInWorkerPool: true,
        })
    ]);

	
    res.push([
        {
            identifier: 'irregular-heightmap-geometries',
            loadAfter: [],
            loadInOneTickWith: [],
            objectCountInBatchHint: 400,
        },
        new EntitiesPersisted<IrregularHeightmapGeometry>({
            entities: bim.irregularHeightmapGeometries,
            serializer: new IrregularHeightmapsSerializer(),
        })
    ]);

    res.push([
        {
            identifier: 'extruded-polygon-geometries',
            loadAfter: [],
            loadInOneTickWith: [],
            objectCountInBatchHint: 100,
        },
        new EntitiesPersisted<ExtrudedPolygonGeometry>({
            entities: bim.extrudedPolygonGeometries,
            serializer: new ExtrudedPolygonsSerializer(),
        })
    ]);

    res.push([
        {
            identifier: 'graph-geometries',
            loadAfter: [],
            loadInOneTickWith: [],
            objectCountInBatchHint: 500,
        },
        new EntitiesPersisted<GraphGeometry>({
            entities: bim.graphGeometries,
            serializer: new GraphsSerializer(),
        })
    ]);

    res.push([
        {
            identifier: "scene-objects",
            loadAfter: [
                "bim-images",
                "bim-materials",
                "tri-geometries",
                "polyline-geometries",
                "cube-geometries",
                "regular-heightmap-geometries",
                "irregular-heightmap-geometries",
                "extruded-polygon-geometries",
            ],
            loadInOneTickWith: ["configs"],
            objectCountInBatchHint: 5000,
        },
        new SceneInstanceEntitiesPersisted(
            bim.instances,
            sceneInstanceMigration,
            bim.instances.archetypes,
            SceneInstanceSerializable.fromFull,
            SceneInstanceSerializable.toPartialFull,
            bim.reactiveRuntimes,
            bim.customRuntimes,
        ),
    ]);

    res.push([
        {
            identifier: 'configs',
            loadAfter: ['scene-objects'],
            loadInOneTickWith: ['scene-objects'],
            objectCountInBatchHint: 5000,
        },
        new EntitiesPersisted<Config>({
            entities: bim.configs,
            versionedPortionFromState: (c) => new Config(c.type_identifier, c.connectedTo, c.properties, c.props),
            versionedPortionToState: (c) => new Config(c.type_identifier, c.connectedTo, c.properties, c.props),
            serializer: createConfigsSerializer(bim),
        })
    ]);

    return res;
}

export function createConfigsSerializer(bim: Bim) {
    return new ConfigsSerializer(
        configsCollectionMigration,
        bim.configs.archetypes
    );
}
