import type { Result} from "engine-utils-ts";
import { Failure, Success } from "engine-utils-ts";
import type { Bim, IdBimScene } from "src";
import { createSubstationDescriptionFromScene } from "./utils";
import { createSubstationChain } from "./templates/substation";
import type { Object2D} from "vector-graphic";
import { createSVGFromObject2D } from "vector-graphic";

export function generateSLDForSubstationSVG(
    bim: Bim,
    substationId: IdBimScene,
): Result<SVGElement> {
    const objResult = generateSLDForSubstation(bim, substationId);
    if(objResult instanceof Failure) {
        return objResult;
    } 
    const svg = createSVGFromObject2D(objResult.value);
    return new Success(svg);
}

export function generateSLDForSubstation(
    bim: Bim,
    substationId: IdBimScene,
) : Result<Object2D> {
    const description = createSubstationDescriptionFromScene(bim, substationId);
    if (description instanceof Failure) {
        return new Failure({ msg: 'Invalid Substation', inner: [description] });
    }
    const obj = createSubstationChain(description.value)
    return new Success(obj);
}

