<script lang="ts">
import { ObjectUtils } from "engine-utils-ts";
import { Hide, HideTypeIdentifier } from "./Hide";
import PathEditor from "../../components/PathEditor.svelte";
import { Action } from "../Action";


export let exit: (action?: Action<Hide>) => void;
export let action: Action<Hide> | undefined;


let editableAction = action
    ? ObjectUtils.deepCloneObj(action)
    : new Action('hide action', HideTypeIdentifier, new Hide());

function save() {
    if (action) {
        Object.assign(action, editableAction);
        return exit();
    }
    return exit(editableAction);
}
</script>

<a class="basic-interact" on:click={() => exit()}>{'<'}</a>

<h3>Hide editor</h3>

<p>disable</p>
<input bind:checked={editableAction.disable} type="checkbox" />

<p>name</p>
<input bind:value={editableAction.name} />

<p>path</p>
<PathEditor bind:path={editableAction.props.path} />

<h3>should remove top level</h3>
<p>
    (if you only want to remove `path` childs, then set to false.)
</p>

<input
    class="basic-interact"
    type="checkbox"
    bind:checked={editableAction.props.deleteTopLevel}
/>

<br/>
<br/>

<a class="basic-interact" on:click={save}>Done</a>
