<script lang="ts">
    import {
        SelectAreaPropertiesGroup,
        SolarArraysProperties,
        TrackerWindPositionUiContext,
    } from "layout-service";
    import type { PUI_CustomGroupNode } from "ui-bindings";
    import SelectedAreaComponent from "./custom-components/SelectArea/SelectedAreaComponent.svelte";
    import SolarArraysView from "./custom-components/SolarArraysView/SolarArraysView.svelte";
    import ImportGroup from "./custom-components/import-object/ImportGroup.svelte";
    import TrackerWindPositionView from "../layout/TrackerWindPositionView.svelte";
    import { LayoutImportGroupUiContext } from 'files-converters';

    export let group: PUI_CustomGroupNode<any, any>;
</script>

{#if group.children instanceof SelectAreaPropertiesGroup}
    <SelectedAreaComponent bind:property={group} />
{:else if group.children instanceof SolarArraysProperties}
    <SolarArraysView bind:property={group} />
{:else if group.context instanceof TrackerWindPositionUiContext}
    <TrackerWindPositionView context={group.context} />
{:else if group.context instanceof LayoutImportGroupUiContext}
    <ImportGroup context={group.context} />
{:else}
    <div class="ui-config-property property-row custom-prop">
        <div class="custom-holder">
            {group?.name}
        </div>
    </div>
{/if}

<style lang="scss">
</style>
