<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.35825 3.94C4.72373 3.35524 5.36467 3 6.05425 3H17.9458C18.6353 3 19.2763 3.35524 19.6417 3.94L21.696 7.2268C21.8947 7.54466 22 7.91196 22 8.2868V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V8.2868C2 7.91196 2.10534 7.54466 2.304 7.2268L4.35825 3.94ZM15.6465 12.5608C15.4512 12.3655 15.4512 12.0489 15.6465 11.8537L16.3536 11.1465C16.5488 10.9513 16.8654 10.9513 17.0607 11.1465L21.9142 15.9999L17.0607 20.8536C16.8654 21.0489 16.5488 21.0489 16.3536 20.8536L15.6465 20.1465C15.4512 19.9513 15.4512 19.6347 15.6464 19.4394L18.0859 16.9999H11.5C11.2239 16.9999 11 16.776 11 16.4999V15.4999C11 15.2238 11.2239 14.9999 11.5 14.9999H18.0857L15.6465 12.5608ZM6.05426 5H17.9458L19.1958 7H4.80426L6.05426 5Z"
        fill="currentColor"
    />
</svg>
