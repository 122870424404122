<script lang="ts">
    import {
        EnergyStagesNames,
        EnergyYieldPropsGroup,
        EnergyYieldSiteProps,
        type EnergyPipelineStage,
    } from "bim-ts";
    import { VersionedStore } from "../../VersionedStore";
    import EnergySetupChartPortion from "./EnergySetupChartPortion.svelte";
    import { EnergyViewContext } from "../EnergyViewContext";
    import { Success, type ResultAsync } from "engine-utils-ts";

    export let context: EnergyViewContext;

    const energyDataLazy = context.bim.runtimeGlobals.getAsLazyVersionedByIdent(
        EnergyYieldSiteProps,
        EnergyYieldPropsGroup,
    );

    $: energyData = new VersionedStore<ResultAsync<EnergyYieldPropsGroup>>(
        energyDataLazy,
    );

    let pipelineStages: EnergyPipelineStage[] = [];
    $: {
        if ($energyData instanceof Success) {
            pipelineStages = $energyData.value.pipeline.stages;
        } else {
            pipelineStages = [];
        }
    }

    const splitAfterStageIndex = EnergyStagesNames.indexOf("pv_conversion");
    const namesUpToPv = EnergyStagesNames.slice(0, splitAfterStageIndex + 1);
    const namesAfterPv = EnergyStagesNames.slice(splitAfterStageIndex);

    let lastEnergyLength: number;
</script>

<div class="root">
    <EnergySetupChartPortion
        stageNames={namesUpToPv}
        allStages={pipelineStages}
        bind:lastEnergyLength
    />
    <EnergySetupChartPortion
        stageNames={namesAfterPv}
        allStages={pipelineStages}
        prevEnergyLength={lastEnergyLength}
        showResultBar={true}
    />
</div>

<style lang="scss">
    .root {
        padding: 12px 0;
        > :global(div:last-child .bar:last-child) {
            border-bottom: 1px solid var(--color-divider);
        }
    }
</style>
