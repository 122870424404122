import type { LazyVersioned, VersionedValue } from 'engine-utils-ts';
import { LegacyLogger } from 'engine-utils-ts';

import type { Camera, ShaderMaterial, Uniform } from '../3rdParty/three';
import { Mesh, OrthographicCamera, PerspectiveCamera } from '../3rdParty/three';
import type { AnyUniform } from '../composer/DynamicUniforms';
import type { GpuResources } from '../composer/GpuResources';
import TrianglePostProcGeom from '../composer/TrianglePostProcGeom';
import { KrMaterial } from '../materials/KrMaterial';
import { CopyShader } from '../shaders/CopyShader';
import type { DictionaryStr } from '../utils/Utils';
import type { BudgetLimitForPass, RenderPass, RenderResult } from './RenderPass';
import type { RenderTargets, RTIdent } from './RenderTargets';

export class PostProcPass implements RenderPass {
    enabled: boolean = true;
    readonly identifier: string;
    readonly inputs: RTIdent[];
	readonly outputs: RTIdent[];

	readonly material: KrMaterial;

	readonly uniforms = new Map<string, Uniform>();
	readonly texturesToUniformsMap = new Map<string, {rtIdent: RTIdent, isDepth: boolean }>();

	private _postCamera: OrthographicCamera;
	private _quad: TrianglePostProcGeom;
	private _mesh: Mesh;

	// invalidator: VersionedValue;
	dynamicUniforms?: Map<string, LazyVersioned<AnyUniform | number>>;
    
    constructor(
        identifier: string,
        input: RTIdent | RTIdent[],
		output: RTIdent,
		material: KrMaterial,
		texturesUniformsMap?: [RTIdent, string][],
		dynamicUniforms?: DictionaryStr<LazyVersioned<AnyUniform | number>>,
    ) {
        this.identifier = identifier;
        this.inputs = Array.isArray(input) ? input : [input];
		this.outputs = [output];

		this.material = material;
		for (const key in material.uniforms) {
			this.uniforms.set(key, material.uniforms[key]);
		}

		for (const ident of this.inputs) {
			if (ident == output) {
				continue;
			}
			const mat = this.material as ShaderMaterial;
			if (mat.uniforms[ident.ident]) {
				this.texturesToUniformsMap.set(ident.ident, {rtIdent: ident, isDepth: false});
			} else if (mat.uniforms.tDiffuse) {
				this.texturesToUniformsMap.set('tDiffuse', {rtIdent: ident, isDepth: false});
			}
			if (mat.uniforms.tDepth && ident.depth) {
				this.texturesToUniformsMap.set('tDepth', {rtIdent: ident, isDepth: true});
			}
		};
		if (texturesUniformsMap) {
			for (const [ident, name] of texturesUniformsMap) {
				this.texturesToUniformsMap.set(
					name,
					{ rtIdent: ident, isDepth: name.toLowerCase().includes('depth') }
				);
				if (!(this.uniforms.has(name))) {
					LegacyLogger.error(`material doesnt have ${name} uniform for texture ${ident}`);
					this.uniforms.set(name, { value: null });
				}
			}
		}


		let invalidators: VersionedValue[] = [];
		if (dynamicUniforms) {
			this.dynamicUniforms = new Map();
			for (const [name, value] of Object.entries(dynamicUniforms)) {
				invalidators.push(value);
				this.dynamicUniforms.set(name, value);
			}
		}


		this._postCamera = new OrthographicCamera(- 1, 1, 1, - 1, 0, 1);
		this._quad = new TrianglePostProcGeom(false);
		this._mesh = new Mesh(this._quad, material);
	}
	version(): number {
		return 0;
	}
	
	static newCopyPass(
		identifier: string,
        input: RTIdent,
		output: RTIdent,
	): PostProcPass {
		return new PostProcPass(
			identifier,
			input,
			output,
			KrMaterial.newPostMat(CopyShader),
		);
	}

	// static newBlurPass(
	// 	identifier: string,

	// )

	render(
        camera: Readonly<Camera>,
        gpuRes: GpuResources,
        renderTargets: RenderTargets,
		budget: BudgetLimitForPass,
        anyInputsIncomplete: boolean,
	): RenderResult {
		gpuRes.renderer.resetRenderState();
		
		for (const [uniName, rtInfo] of this.texturesToUniformsMap) {
			const rt = renderTargets.getRT(rtInfo.rtIdent);
			this.uniforms.get(uniName)!.value = rtInfo.isDepth ? rt.wrt.depthTexture : rt.wrt.texture;
		}
		if (this.uniforms.has('isOrtho')) {
			this.uniforms.get('isOrtho')!.value = camera instanceof OrthographicCamera;
		}
		if (this.uniforms.has('cameraNear')) {
			this.uniforms.get('cameraNear')!.value = camera.near;
		}
		if (this.uniforms.has('cameraFar')) {
			this.uniforms.get('cameraFar')!.value = camera.far;
		}
		if (this.uniforms.has('cameraWorldMatrix')) {
			this.uniforms.get('cameraWorldMatrix')!.value.copy(camera.matrixWorld);
		}
		if (this.uniforms.has('cameraProjectionMatrix')) {
			this.uniforms.get('cameraProjectionMatrix')!.value = camera.projectionMatrix;
		}
		if (this.uniforms.has('cameraProjectionMatrixInverse')) {
			this.uniforms.get('cameraProjectionMatrixInverse')!.value.copy(camera.projectionMatrixInverse);
		}
		if (this.uniforms.has('viewProjectionMatrixInverse')) {
			this.uniforms.get('viewProjectionMatrixInverse')!.value.copy(camera.matrixWorld).multiply(camera.projectionMatrixInverse);
		}
		if (this.uniforms.has('gridLevelOpacityInterval')) {
			this.uniforms.get('gridLevelOpacityInterval')!.value.copy(gpuRes.globalUniforms.gridLevelOpacityInterval.value);
		}
		if (this.uniforms.has('gridLevelOpacityIntervalMinor')) {
			this.uniforms.get('gridLevelOpacityIntervalMinor')!.value.copy(gpuRes.globalUniforms.gridLevelOpacityIntervalMinor.value);
		}
		if (this.uniforms.has('gridWidth')) {
			this.uniforms.get('gridWidth')!.value.copy(gpuRes.globalUniforms.gridWidth.value);
		}
		if (this.uniforms.has('fov')) {
			this.uniforms.get('fov')!.value = camera instanceof PerspectiveCamera ? camera.fov : 1;
		}
		if (this.uniforms.has('envMap')) {
			this.uniforms.get('envMap')!.value = gpuRes.globalUniforms.envMap.value;
			this.material.envMap = gpuRes.globalUniforms.envMap.value;
		}

		if (this.dynamicUniforms) {
			for (const [name, lazyValue] of this.dynamicUniforms) {
				const uniform = this.material.uniforms[name];
				if (uniform == undefined) {
					LegacyLogger.deferredError('invalid post proc dynamic uniform', [name, lazyValue, uniform]);
					continue;
				}
				uniform.value = lazyValue.poll();
			}
		}

		camera = this._postCamera;
		
		renderTargets.bindByIdents(this.outputs);
		this._mesh.updateMatrixWorld(true);
		this._mesh.modelViewMatrix.multiplyMatrices( camera.matrixWorldInverse, this._mesh.matrixWorld );
		this._mesh.normalMatrix.getNormalMatrix(this._mesh.modelViewMatrix);
		
		gpuRes.renderer.getWebglGeometries().update(this._mesh.geometry!);
		gpuRes.renderer.renderBufferDirect(
			camera,
			null,
			this._mesh.geometry!,
			this._mesh.material!,
			this._mesh,
			null,
		);
		// gpuRes.renderer.render(
		// 	this._mesh,
		// 	camera,
		// );
		// const sc = new Scene();
		// sc.add(this.mesh);
		// const rt = renderTargets.getRT(this.outputs[0]);
		// gpuRes.renderer.render(sc, this.postCamera, (rt.ident.name == 'screen' ? null : rt.wrt) as any);
        
		return { finished: true, unitsRenderered: 1 };
	}
	
	dispose() {
		this._quad.dispose();
		this._mesh.material!.dispose();
	}

};