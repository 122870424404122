import { CostModel as CM } from 'bim-ts';
import {  GridOptions, GridSizeChangedEvent, ICellRendererComp, ICellRendererParams, IsFullWidthRowParams, RowHeightParams } from "ag-grid-enterprise";
import ChartsRowSvelte from '../ChartsRow.svelte';
import { GridContext, RowDataType } from "./types";

export function createChartsRowGridOptions() {
    const gridOptions: Partial<GridOptions<RowDataType>> = {
        fullWidthCellRenderer: ChartsRow,

        isFullWidthRow: (params: IsFullWidthRowParams<CM.FlattenedCostCategoryParams, GridContext>) => {
            const totalItemsLength = params.api.getGridOption('rowData')?.length;
            if (totalItemsLength === undefined) {
                return false;
            }
            const isFullWidthRow =
                !!params.context.echarts?.length &&
                params?.rowNode.rowIndex === totalItemsLength - 1;
            return isFullWidthRow;
        },

        onGridSizeChanged: (params: GridSizeChangedEvent<CM.FlattenedCostCategoryParams, GridContext>) => {
            params.context.tableWidth = params.clientWidth;
            params.api.resetRowHeights();
        },

        getRowHeight: (params: RowHeightParams<CM.FlattenedCostCategoryParams, GridContext>) => {
            if (!params.node.isFullWidthCell?.()) {
                return;
            }
            return ChartsRow.calculateRowHeightFromTableWidth(params.context.echarts.length, params.context.tableWidth);
        }
    }
    return gridOptions;
}

export class ChartsRow implements ICellRendererComp {
    static ChartWidthPx = 600
    static ChartHeightPx = 500

    static calculateRowHeightFromTableWidth(itemCount: number, widthPx: number) {
        const placesPerRow = Math.floor(widthPx / this.ChartWidthPx);
        const rows = Math.ceil(itemCount / placesPerRow)
        return rows * this.ChartHeightPx;
    }

    div!: HTMLElement;
    comp!: ChartsRowSvelte;

    init(params: ICellRendererParams<CM.FlattenedCostCategoryParams, unknown, GridContext>) {
        this.div = document.createElement('div');
        this.comp = new ChartsRowSvelte({
            target: this.div,
            props: {
                echarts: params.context.echarts,
                chartWidth: ChartsRow.ChartWidthPx,
                chartHeight: ChartsRow.ChartHeightPx,
            },
        })
    }

    destroy() {
        this.comp.$destroy();
    }

    getGui() {
        return this.div;
    }

    refresh(params: ICellRendererParams<CM.FlattenedCostCategoryParams, unknown, GridContext>): boolean {
        this.comp.$set({ echarts: params.context.echarts })
        return true;
    }
}
