import type { Yield } from 'engine-utils-ts';
import { Failure, PollablePromise, ProjectNetworkClient, Success } from 'engine-utils-ts';
import type { FileToImport, UiBindings, FileImporterContext} from 'ui-bindings';
import { NotificationDescription, NotificationType } from 'ui-bindings';
import { notificationSource } from '../Notifications';
import { deserializeFlatBufferModel } from './SiteModelParser';


export function *checkCivilPluginVersion(
    context: FileImporterContext,
    file: FileToImport
): Generator<Yield, void> {
    const network = new ProjectNetworkClient({
        ...context.network?.config,
    });

    const latestVersionPromise = network.get("api/civil-plugin-latest-version");
    const latestVersion = yield* PollablePromise.generatorWaitFor(latestVersionPromise.then(r => r.text()));

    if (latestVersion instanceof Failure) {
        context.logger.error("Cant find latest plugin version!");
    }

    let pluginVer:string | undefined = undefined;


    if (file.filename.endsWith('.json')) {
        const asStr = new TextDecoder().decode(file.fileArrayBuffer);
        pluginVer = JSON.parse(asStr).PluginVersion;

    }else if (file.filename.endsWith('.pvfm')){
        const asObj = deserializeFlatBufferModel(file.fileArrayBuffer);
        pluginVer = asObj.PluginVersion;
    }

    

    if (latestVersion instanceof Success) {
        const latestVersionVal = latestVersion.value;
        if (latestVersionVal !== pluginVer) {
            context.setMessage(`The uploaded file was created using an outdated PVFarm plugin (Version #${pluginVer}).
            Please download the latest version of the plugin and re-export the file for optimal results.`);
        } else {
            context.setMessage(`File created by the latest PVFarm plugin (Version #${pluginVer}).`, false);
        }
    }
}

export async function getCivilPluginInstaller(uiBindings: UiBindings): Promise<void> {

    const network = new ProjectNetworkClient({});

    function errorHendler(message:string){
        console.error(message);
        uiBindings.addNotification(NotificationDescription.newBasic({
            source: notificationSource,
            key: 'civil_plugin_download',
            type: NotificationType.Error,
            removeAfterMs: 3000,
            addToNotificationsLog: true
        }));

    }

    const response = await network.get("api/civil-plugin-installer");

    if (response.ok) {
        const binaryData = await response.arrayBuffer();
        let filename = "installer.msi";

        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition != null) {
            const parts = contentDisposition.split(';');
            const filenamePart = parts.find(part => part.trim().startsWith("filename="));
            if (filenamePart) {
                filename = filenamePart.split("=")[1].trim();
            } else {
                errorHendler("Can't get installer name!")
            }
        } else {
            errorHendler("Can't find installer name!")
        }

        downloadFile(filename, binaryData);

    } else{
        errorHendler(response.statusText)
    }

}

function downloadFile(fileName: string, data: ArrayBuffer | Uint8Array | Blob) {
    const url = URL.createObjectURL(new globalThis.Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.insertAdjacentElement("beforeend", link);
    link.click();
    link.remove();
}


