import type { RGBAHex, TasksRunner} from 'engine-utils-ts';
import { ScopedLogger, LazyDerived, nameof, CompressibleNumbersArray, Success, Failure, LazyBasic } from 'engine-utils-ts';
import { Quaternion, Vector3 } from 'math-ts';
import type { PropertyFitChecker, PUI_CustomPropertyNodeArgs, PUI_PropertyNodeBoolArgs, PUI_PropertyNodeColorArgs, PUI_PropertyNodeNumberArgs, PUI_PropertyNodeSelectorArgs, PUI_PropertyNodeStringArgs} from 'ui-bindings';
import { ActionVisibilityFlags, NotificationDescription, NotificationType, PUI_ConfigPropertyTransformer, PUI_CustomPropertyNode, PUI_PropertyNodeBool, PUI_PropertyNodeColor, PUI_PropertyNodeNumber, PUI_PropertyNodeSelector, PUI_PropertyNodeString, UiBindings } from 'ui-bindings';

import type { Bim, PropertyBase, SceneInstance} from './';
import { BooleanProperty, ColorProperty, newAnyTrackerInstance, NumberProperty, NumbersArrayProperty, SceneInstanceFlags, StringProperty } from './';
import { BimProperty } from './bimDescriptions/BimProperty';
import { EnergyPipelineProperty } from './energy/EnergyPipelineProperty';
import { mapBoundaryInstancesToPolygons } from './terrain/BoundariesUtils';
import { findInstancesIntersectedWithPolygons } from './terrain/cut-fill/CutFillBase';
import { notificationSource } from './Notifications';

export function createBimUiBindings(bim: Bim, tasksRunner: TasksRunner): UiBindings {
    const bindings = new UiBindings(new ScopedLogger('bim'));
    selectedGroupsUi(bim, bindings, tasksRunner);
    for (const customRuntime of bim.customRuntimes.solversUiBindings()) {
        bindings.addRuntimeSystemUi(customRuntime.name, customRuntime);
    }
    bindings.addAction({
        canUseNow: new LazyBasic('', true),
        name: ['Convert trackers to any-trackers (selected or all)'],
        action: () => {
            bim.convertToAnyTrackers(bim.instances.getSelected());
        },
        visibility: ActionVisibilityFlags.Search,
    });
    bindings.addAction({
        canUseNow: new LazyBasic('', true),
        name: ['Set any trackers mock embedments'],
        action: () => {
            bim.setMockEmbedments();
        },
        visibility: ActionVisibilityFlags.Search,
    });

    bindings.addAction({
        canUseNow: new LazyBasic('', true),
        name: ['Add', 'Tracker'],
        visibility: ActionVisibilityFlags.ContextMenu | ActionVisibilityFlags.Menu | ActionVisibilityFlags.Search,
        priority: 3,
        action: () => {
            const newTracker = newAnyTrackerInstance();
            const ids = bim.instances.allocate([[bim.instances.reserveNewId(), newTracker]]);
            bim.instances.setSelected(ids);
        },
    })

    return bindings;
}

function selectedGroupsUi(bim: Bim, bindings: UiBindings, tasksRunner: TasksRunner) {
    const boundaryInstances = bim.instances.getLazyListOf({type_identifier: 'boundary'});
    const selectedIds = bim.instances.selectHighlight.getVersionedFlagged(SceneInstanceFlags.isSelected);
    const isAnyBoundarySelected = LazyDerived.new2(
        'is-any-boundary-selected',
        null,
        [boundaryInstances, selectedIds],
        ([boundaryInstances, selectedIds]) => {
            if (selectedIds.length === 0 || boundaryInstances.length === 0) {
                return false;
            }
            for (const [id, boundaryInstance] of boundaryInstances) {
                if (boundaryInstance.isSelected) {
                    return true;
                }
            }
            return false;
        }
    );

    bindings.addAction<undefined>({
        name: ['Select', 'All within selected Boundaries'],
        canUseNow: isAnyBoundarySelected,
        visibility: ActionVisibilityFlags.Menu | ActionVisibilityFlags.Search,
        priority: 1,
        action: async () => {
            const logger = new ScopedLogger('in-boundary-select');
            const polygons = mapBoundaryInstancesToPolygons(
                logger,
                selectedIds.poll(),
                bim
            );
            const excludeTypes = new Set(['image', 'terrain-heightmap']);
            const task = tasksRunner.newLongTask({
                identifier: "find-instances-intersected-with-boundaries",
                defaultGenerator: findInstancesIntersectedWithPolygons(
                    polygons,
                    bim,
                    (_id, inst) => !excludeTypes.has(inst.type_identifier),
                )
            });
            bindings.addNotification(NotificationDescription.newWithTask({
                type: NotificationType.Info,
                source: notificationSource,
                key: 'selectingWithinBoundaries',
                taskDescription: { task,},
                addToNotificationsLog: false,
            }));

            const instances = await task.asPromise();

            const ids = instances.map(t => t[0]);
            bim.instances.setSelected(ids);
        }
    });
}


export class BimUiBindings {

    static propsToPuiNodesTransformers(): [string | string[] | PropertyFitChecker, PUI_ConfigPropertyTransformer<any, any, any, any>][] {
        const transformers: [string | string[] | PropertyFitChecker, PUI_ConfigPropertyTransformer<any, any, any, any>][] = [
            [
                (name, value, path) => value instanceof NumberProperty,
                BimUiBindings.numberPropertyTransformer(),
            ],
            [
                (name, value, path) => value instanceof StringProperty,
                BimUiBindings.stringPropertyTransformer(),
            ],
            [
                (name, value, path) => value instanceof BooleanProperty,
                BimUiBindings.boolPropertyTransformer(),
            ],
            [
                (name, value, path) => value instanceof ColorProperty,
                BimUiBindings.colorPropertyTransformer(),
            ],
            [
                (name, value, path) => {
                    return value instanceof NumbersArrayProperty
                        || value instanceof CompressibleNumbersArray
                        || value instanceof EnergyPipelineProperty
                },
                BimUiBindings.customReadonlyPropertyTransformer(),
            ],
            // [
            //     (name, value, path) => {
            //         return value instanceof EnergyPipelineProperty
            //     },
            //     BimUiBindings.customReadonlyPropertyTransformer(),
            // ],
        ];

        return transformers;
    }

    static instancesPropsConfigTransformers(): [string | string[] | PropertyFitChecker, PUI_ConfigPropertyTransformer<any, any, any, any>][] {

        const tranformers:
            [string[] | string | PropertyFitChecker, PUI_ConfigPropertyTransformer<any, any, any, any>][] =
        [

            [
                (name, value, path) => value instanceof BimProperty && value.isText(),
                BimUiBindings.bimPropertyStringTransformer({})
            ],
            [
                (name, value, path) => value instanceof BimProperty && value.isText() && value.hasDiscreteVariants(),
                BimUiBindings.bimPropertyStringSelectorTransformer()
            ],
            [
                (name, value, path) => value instanceof BimProperty && value.isBoolean(),
                BimUiBindings.bimPropertyBoolTransformer({})
            ],
            [
                (name, value, path) => value instanceof BimProperty && value.isNumeric(),
                BimUiBindings.bimPropertyNumberTransformer({})
            ],
            [
                (name, value, path) => typeof value == 'number' && (name.toString().toLocaleLowerCase().endsWith('id')),
                PUI_ConfigPropertyTransformer.readonlyString(),
            ],
            [
                (name, value, path) => typeof value == 'number' && name.toString().includes('count'),
                PUI_ConfigPropertyTransformer.numberProp({
                    minMax: [0, 1000],
                    step: 1,
                })
            ],
            [
                nameof<SceneInstance>('type_identifier'),
                PUI_ConfigPropertyTransformer.readonlyString(),
            ],
            [
                (name, value, path) => typeof value == 'number' && name.toString().toLowerCase().includes('color'),
                PUI_ConfigPropertyTransformer.colorSelector({})
            ],
            [
                (name, value, path) => value instanceof Vector3,
                PUI_ConfigPropertyTransformer.vector3({})
            ],
            [
                (name, value, path) => value instanceof Vector3 && name == 'scale',
                PUI_ConfigPropertyTransformer.vector3({defaulValue: new Vector3(1, 1, 1)})
            ],
            [
                (name, value, path) => value instanceof Vector3 && name == 'position',
                PUI_ConfigPropertyTransformer.vector3({unit: 'm'})
            ],
            [
                (name, value, path) => value instanceof Quaternion,
                PUI_ConfigPropertyTransformer.quatRotation({step: 0.1})
            ],
            [
                (name, value, path) =>
                    value instanceof BimProperty &&
                    !!BimUiBindings.getAssetSingleSelectParams(value),
                BimUiBindings.bimPropertyAssetSingleSelectTransform(),
            ],
        ];
        return tranformers;
    }
    static getAssetSingleSelectParams(prop: BimProperty): { instanceType: string } | null {
        const description = prop.description;
        if (!description) return null;
        const match = description.match(/asset=(.*)=/);
        if (!match) return null;
        return { instanceType: match[1] };
    }
    static bimPropertyAssetSingleSelectTransform(): PUI_ConfigPropertyTransformer<
        BimProperty,
        Array<number>,
        PUI_CustomPropertyNode<unknown, any>,
        PUI_CustomPropertyNodeArgs<unknown, any>
    > {
        return new PUI_ConfigPropertyTransformer(
            (prop) => Number.isFinite(prop.value) && prop.value > 0 ? [prop.value] : [],
            ([val], initialBimProperty) => Number.isFinite(val)
                ? new Success(initialBimProperty.withDifferentValue(val))
                : new Success(initialBimProperty),
            (prop) => {
                const typeIdent =
                    BimUiBindings.getAssetSingleSelectParams(prop)?.instanceType ?? ''
                return [
                    PUI_CustomPropertyNode,
                    {
                        type_ident: 'assets-selector',
                        context: {
                            maxCount: 1,
                            types: [typeIdent],
                        },
                        readonly: prop.readonly,
                    }
                ]
            }
        )
    }

	static bimPropertyBoolTransformer(args: {
        readonly?: boolean,
    }): PUI_ConfigPropertyTransformer<BimProperty, boolean, PUI_PropertyNodeBool, PUI_PropertyNodeBoolArgs> {
        return new PUI_ConfigPropertyTransformer(
            (p) => p.asBoolean(),
            (propValue, initialBimProp: BimProperty) => new Success(initialBimProp.withDifferentValue(propValue)),
            (bimProp: BimProperty) => {
                let hint = bimProp.description ?? '';
                if (bimProp.isComputedBy) {
                    hint += ` (computed by ${bimProp.isComputedBy})`;
                }
                return [
                    PUI_PropertyNodeBool,
                    {
                        hint,
                        description: bimProp.description,
                        readonly: bimProp.readonly || args.readonly,
                    }
                ];
            },
        )
    }

    static boolPropertyTransformer(): PUI_ConfigPropertyTransformer<BooleanProperty, boolean, PUI_PropertyNodeBool, PUI_PropertyNodeBoolArgs> {
        return new PUI_ConfigPropertyTransformer(
            (p) => p.value,
            (propValue, initialBimProp: BooleanProperty) => {
                return new Success(initialBimProp.withDifferentValue(propValue));
            },
            (bimpProp) => {
                return [
                    PUI_PropertyNodeBool,
                    {
                        value: bimpProp.value,
                        description: bimpProp.description,
                        readonly: bimpProp.isReadonly,
                    }
                ]
            },

        )
    }
    static numberPropertyTransformer(): PUI_ConfigPropertyTransformer<NumberProperty, number, PUI_PropertyNodeNumber, PUI_PropertyNodeNumberArgs> {
        return new PUI_ConfigPropertyTransformer(
            (p) => p.value,
            (propValue, initialBimProp: NumberProperty) => {
                // if (prop.isValid()) {
                    return new Success(initialBimProp.withDifferentValue(propValue));
                // } else {
                //     return new Failure('invalid');
                // }
            },
            (bimpProp) => {
                return [
                    PUI_PropertyNodeNumber,
                    {
                        value: bimpProp.value,
                        description: bimpProp.description,
                        readonly: bimpProp.isReadonly,
                        step : bimpProp.step,
                        unit: bimpProp.unit ?? undefined,
                        minMax: bimpProp.range ?? undefined,
                    }
                ]
            },

        )
    }
    static colorPropertyTransformer(): PUI_ConfigPropertyTransformer<ColorProperty, RGBAHex, PUI_PropertyNodeColor, PUI_PropertyNodeColorArgs> {
        return new PUI_ConfigPropertyTransformer(
            (p) => p.value,
            (propValue, initialBimProp: ColorProperty) => {
                return new Success(initialBimProp.withDifferentValue(propValue));
            },
            (bimpProp) => {
                return [
                    PUI_PropertyNodeColor,
                    {
                        value: bimpProp.value,
                        description: bimpProp.description,
                        readonly: bimpProp.isReadonly,
                    }
                ]
            },

        )
    }
    static stringPropertyTransformer(): PUI_ConfigPropertyTransformer<StringProperty, string, PUI_PropertyNodeString, PUI_PropertyNodeStringArgs> {
        return new PUI_ConfigPropertyTransformer(
            (p) => p.value,
            (propValue, initialBimProp: StringProperty) => {
                return new Success(initialBimProp.withDifferentValue(propValue));
            },
            (bimpProp) => {
                return [
                    PUI_PropertyNodeString,
                    {
                        value: bimpProp.value,
                        description: bimpProp.description,
                        readonly: bimpProp.isReadonly,
                    }
                ]
            },

        )
    }
    static customReadonlyPropertyTransformer<
        V,
        P extends PropertyBase & {value: V}
    >(
    ): PUI_ConfigPropertyTransformer<P, P, PUI_CustomPropertyNode<P>, PUI_CustomPropertyNodeArgs<P>> {
        return new PUI_ConfigPropertyTransformer(
            (p, outerContext) => p,
            (propValue, initialBimProp) => {
                return new Failure({msg: 'custom props edits are not implemented'});
            },
            (bimpProp, _, context) => {
                return [
                    PUI_CustomPropertyNode,
                    {
                        value: bimpProp,
                        context: context,
                        // description: bimpProp.description,
                        // readonly: bimpProp.isReadonly,
                    }
                ]
            },
        )
    }

    static bimPropertyNumberTransformer(args: {
        readonly?: boolean,
    }): PUI_ConfigPropertyTransformer<BimProperty, number, PUI_PropertyNodeNumber, PUI_PropertyNodeNumberArgs> {
        return new PUI_ConfigPropertyTransformer(
            (p) => p.asNumber(),
            (propValue, initialBimProp: BimProperty) => {
                // if (prop.isValid()) {
                    return new Success(initialBimProp.withDifferentValue(propValue));
                // } else {
                //     return new Failure('invalid');
                // }
            },
            (bimpProp) => {
                let hint = bimpProp.description ?? '';
                if (bimpProp.isComputedBy) {
                    hint += ` (computed by ${bimpProp.isComputedBy})`;
                }
                let step = 0.0001;
                const propName = bimpProp.path[bimpProp.path.length - 1].toLocaleLowerCase();
                if (propName.includes('count')) {
                    step = 1;
                }
                return [
                    PUI_PropertyNodeNumber,
                    {
                        hint: hint,
                        description: bimpProp.description,
                        readonly: bimpProp.readonly || args.readonly,
                        step : bimpProp.numeric_step || step,
                        unit: bimpProp.unit ?? undefined,
                        minMax: bimpProp.numeric_range ?? undefined,
                    }
                ]
            },

        )
    }

    static bimPropertyStringTransformer(args: {
        readonly?: boolean,
    }): PUI_ConfigPropertyTransformer<BimProperty, string, PUI_PropertyNodeString, PUI_PropertyNodeStringArgs> {
        return new PUI_ConfigPropertyTransformer(
            (p) => p.asText(),
            (propValue, initialBimProp: BimProperty) => {
                // if (prop.isValid()) {
                    return new Success(initialBimProp.withDifferentValue(propValue));
                // } else {
                //     return new Failure('invalid');
                // }
            },
            (bimProp) => {
                let hint = bimProp.description ?? '';
                if (bimProp.isComputedBy) {
                    hint += ` (computed by ${bimProp.isComputedBy})`;
                }
                return [
                    PUI_PropertyNodeString,
                    {
                        hint: hint,
                        description: bimProp.description,
                        readonly: bimProp.readonly || args.readonly,
                    }
                ]
            },

        )
    }

    static bimPropertyStringSelectorTransformer(): PUI_ConfigPropertyTransformer<BimProperty, string, PUI_PropertyNodeSelector, PUI_PropertyNodeSelectorArgs> {
        return new PUI_ConfigPropertyTransformer(
            (p) => p.asText(),
            (propValue, initialBimProp: BimProperty) => {
                // if (prop.isValid()) {
                    return new Success(initialBimProp.withDifferentValue(propValue));
                // } else {
                //     return new Failure('invalid');
                // }
            },
            (bimProp) => {
                console.assert(Boolean(bimProp.discrete_variants?.length), 'selector property should have variants');
                let hint = bimProp.description ?? '';
                if (bimProp.isComputedBy) {
                    hint += ` (computed by ${bimProp.isComputedBy})`;
                }
                return [
                    PUI_PropertyNodeSelector,
                    {
                        hint: hint,
                        description: bimProp.description,
                        readonly: bimProp.readonly,
                        options: bimProp.discrete_variants as string[],
                    }
                ]
            },

        )
    }



}
