import type { Aabb } from './Aabb';
import { Matrix3 } from './Matrix3';
import type { Matrix4 } from './Matrix4';
import { Vector3 } from './Vector3';

//mostly taken from three.js math lib

export class Plane {

	readonly normal: Vector3;
	constant: number;
	intersectLine!: (lineStart: Vector3, lineEnd:Vector3, target: Vector3) => Vector3 | null;
	setFromCoplanarPoints!: (a:Vector3, b:Vector3, c:Vector3) => Plane;

	constructor(normal:Readonly<Vector3>, constant:number) {
		this.normal = normal.clone();
		this.constant = constant;
	}

	static allocateZero(): Plane {
		return new Plane(Vector3.zero(), 0);
	}

	setComponents(x:number, y:number, z:number, w:number) {
		this.normal.set(x, y, z);
		this.constant = w;

		return this;
	}
	
	copy(p: Plane) {
		this.normal.copy(p.normal);
		this.constant = p.constant;
	}

	clone(): Plane {
		return new Plane(this.normal, this.constant);
	}

	equals(p: Plane): boolean {
		return this.normal.equals(p.normal) && this.constant === p.constant;
	}

	roughlyEquals(p: Plane, eps = 0.000001): boolean {
		return this.normal.distanceToSquared(p.normal) < eps && Math.abs(this.constant - p.constant) < eps;
	}

	setFromNormalAndCoplanarPoint ( normal:Vector3, point:Vector3 | Vector3 ):Plane {
		this.normal.copy( normal );
		this.constant = - point.dot(this.normal as any);
		return this;
	}

	distanceToPoint(point: Vector3) {
		const normal = this.normal;
		// inline dot product
		return normal.x * point.x + normal.y * point.y + normal.z * point.z + this.constant;
	}

	distancesToAabb(aabb: Aabb): {min: number, max: number} {
		let dotMin, dotMax : number;
	
		if (this.normal.x > 0) {
			dotMin = this.normal.x * aabb.elements[0];
			dotMax = this.normal.x * aabb.elements[3];
		} else {
			dotMin = this.normal.x * aabb.elements[3];
			dotMax = this.normal.x * aabb.elements[0];
		}

		if (this.normal.y > 0) {
			dotMin += this.normal.y * aabb.elements[1];
			dotMax += this.normal.y * aabb.elements[4];
		} else {
			dotMin += this.normal.y * aabb.elements[4];
			dotMax += this.normal.y * aabb.elements[1];
		}

		if (this.normal.z > 0) {
			dotMin += this.normal.z * aabb.elements[2];
			dotMax += this.normal.z * aabb.elements[5];
		} else {
			dotMin += this.normal.z * aabb.elements[5];
			dotMax += this.normal.z * aabb.elements[2];
		}
		
		return {
			min: dotMin + this.constant,
			max: dotMax + this.constant,
		}
	}

	projectPoint_t( point:Vector3 ): Vector3 {
		return  this.normal.clone().multiplyScalar( - this.distanceToPoint( point ) ).add( point );
	}

	coplanarPoint(target:Vector3) {
		return target.copy(this.normal).multiplyScalar( - this.constant );
	}

	applyMatrix4 ( matrix: Matrix4 ) {
		var normalMatrix = reusedM31.getNormalMatrix( matrix as any);
		var referencePoint_t = this.coplanarPoint(reusedV3).applyMatrix4( matrix );
		this.normal.applyMatrix3(normalMatrix).normalize();
		this.constant = - referencePoint_t.dot( this.normal );

		return this;
	}
	
	normalize () {
		// Note: will lead to a divide by zero if the plane is invalid.
		const inverseNormalLength = 1.0 / this.normal.length();
		this.normal.multiplyScalar( inverseNormalLength );
		this.constant *= inverseNormalLength;

		return this;
	}
}

Plane.prototype.intersectLine = function () {

	const v1 = Vector3.zero();

	return function intersectLine( this:Plane, lineStart:Vector3, lineEnd:Vector3, target:Vector3 ): Vector3 | null {

		const direction = v1.copy(lineEnd).sub(lineStart);
		const denominator = this.normal.dot( direction );

		if ( denominator === 0 ) {
			// line is coplanar, return null
			return null;
		}
		const t = - ( lineStart.dot( this.normal ) + this.constant ) / denominator;
		if (!(t >= 0 && t <= 1)) {
			return null;
		}
		return target.copy( direction ).multiplyScalar( t ).add( lineStart );
	};

}()

Plane.prototype.setFromCoplanarPoints = function () {
	const v1 = Vector3.zero();
	const v2 = Vector3.zero();

	return function setFromCoplanarPoints(this:Plane, a:Vector3, b:Vector3, c:Vector3 ) {
		const normal_t = v1.copy(c).sub(b).cross( v2.copy(a).sub(b)).normalize();
		this.setFromNormalAndCoplanarPoint( normal_t, a );
		return this;
	};
}()


const reusedV3 = Vector3.zero();
const reusedM31 = new Matrix3();


