import type { LcoeCategory } from ".";
import type { FlattenedCostCategoryParams, IdCostCategory } from "../capital";
import { reserveCostCategoryId } from "../capital";

export class CostHierarchy {
    private roots: IdCostCategory[] = [];
    readonly categories: Map<IdCostCategory, LcoeCategory> = new Map();

    merge(...sources: CostHierarchy[]) {
        for (const source of sources) {
            for (const entry of source.categories) {
                this.categories.set(...entry);
            }
            for (const id of source.roots) {
                this.roots.push(id);
            }
        }
        return this;
    }

    static clone(source: CostHierarchy) {
        const result = new CostHierarchy();
        result.merge(source);
        return result;
    }

    addRoot(category: LcoeCategory): [id: IdCostCategory, root: LcoeCategory] {
        const id = reserveCostCategoryId();
        this.categories.set(id, category);
        if (category.children?.length) {
            throw new Error("category for addRoot can not have children");
        }
        if (this.roots.length) {
            category.children = this.roots;
        }
        this.roots = [id];
        return [id, category];
    }

    add(category: LcoeCategory): [id: IdCostCategory, category: LcoeCategory] {
        const id = reserveCostCategoryId();
        this.categories.set(id, category);
        this.roots.push(id);
        return [id, category];
    }

    flattenCostHierarchy() {
        const hierarchy = this;
        const flattened: FlattenedCostCategoryParams[] = [];
        type QueueItem = {
            level: number;
            id: IdCostCategory;
            path: string[];
        };
        const queue: QueueItem[] = [];
        for (const [id, category] of hierarchy.getRootCategories()) {
            queue.push({ id, level: 0, path: [category.description.value] });
        }

        while (queue.length) {
            const { id, level, path } = queue.splice(0, 1)[0];
            const category = hierarchy.categories.get(id);
            if (!category) {
                continue;
            }

            const nestLevel = category.description?.indent ?? level;
            flattened.push({
                categoryId: id,
                isBottom: !category.children?.length,
                nestLevel,
                path,
            });

            if (category.children?.length) {
                const children: QueueItem[] = [];
                for (const id of category.children) {
                    const category = hierarchy.categories.get(id);
                    if (!category) {
                        continue;
                    }
                    children.push({
                        id,
                        level: nestLevel + 1,
                        path: [...path, category.description.value],
                    });
                }
                queue.splice(0, 0, ...children);
            }
        }

        return flattened;
    }

    getRootCategories(): [id: IdCostCategory, root: LcoeCategory][] {
        return this.roots.map((x) => [x, this.categories.get(x)!]);
    }
}

export interface LCOELayoutInput {
    annualOutput: number;
    capitalCost: number;
}
