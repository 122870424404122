<script lang="ts">
    import {
        NotificationType,
        type NotificationActionDescription,
    } from "ui-bindings";
    import { IconComponent, type IconName } from "../libui/icon";
    import {
        type NotificationItem,
        setHeaderFromTaskResult,
    } from "./NotificationsHandler";
    import TextClamp from "../libui/text/TextClamp.svelte";
    import { Failure, Result, Success } from "engine-utils-ts";

    type IconType = {
        [key in NotificationType]?: IconName;
    };

    const NotificationIcons: IconType = {
        [NotificationType.Success]: "Success",
        [NotificationType.Warning]: "Warning",
        [NotificationType.Error]: "Error",
    };

    export let notification: NotificationItem;
    export let status: NotificationType | undefined = undefined;
    export let isHeader: boolean;
    export let onFinish: (notification: NotificationItem) => void;
    export let onRemove = () => {};
    export let collapseDetails: boolean = false;

    $: inProgress = !!notification.taskDescription;
    $: notificationType = status ?? notification.type;
    $: isError = notificationType === NotificationType.Error;
    $: iconName = inProgress ? "Working" : NotificationIcons[notificationType];

    function executeAction(description?: NotificationActionDescription<any>) {
        const { action, actionArgs } = description || {};
        if (action) {
            action(...actionArgs);
        }
        onRemove();
    }

    if (notification.taskDescription) {
        let updatedNotification: NotificationItem;
        notification.taskDescription.task
            .asPromise()
            .then(
                (res) => {
                    updatedNotification = {
                        ...notification,
                        type: NotificationType.Success,
                        taskDescription: undefined,
                        header: setHeaderFromTaskResult(
                            notification,
                            new Success(res)
                        ),
                    };
                },
                (err) => {
                    updatedNotification = {
                        ...notification,
                        type: NotificationType.Error,
                        taskDescription: undefined,
                        header: setHeaderFromTaskResult(
                            notification,
                            new Failure(err)
                        ),
                    };
                }
            )
            .finally(() => {
                onFinish(updatedNotification);
            });
    }
</script>

<div class="notification mdc-typography--body2 text-main-medium">
    <div class="notification-header">
        <div class="notification-status">
            {#if isHeader && iconName}
                <IconComponent name={iconName} />
            {/if}
        </div>
        <div class="notification-title">
            <div
                class:mdc-typography--body1={isHeader}
                class:text-main-dark={isHeader && !(inProgress || isError)}
                class:text-accent={inProgress}
                class:text-danger={isError}
            >
                {notification.header || notification.message}
            </div>
        </div>
        <slot />
    </div>
    {#if notification.message && notification.header}
        <div class="notification-message mdc-typography--body2">
            {#if collapseDetails}
                <TextClamp lineCount={3}>
                    {notification.message}
                </TextClamp>
            {:else}
                <div>{notification.message}</div>
            {/if}
        </div>
    {/if}
    {#if notification.actionDescription}
        <div class="notification-action">
            <div
                class="action-button mdc-typography--body2-strong text-accent"
                on:click={() => executeAction(notification.actionDescription)}
            >
                {notification.actionDescription.name ?? "Execute"}
            </div>
        </div>
    {/if}
</div>

<style lang="scss">
    .notification:not(:last-child) {
        margin-bottom: 4px;
    }
    .notification-header {
        display: flex;
        align-items: flex-start;
        gap: 8px;
    }
    .notification-status {
        width: 16px;
        display: flex;
        padding-top: 1px;
    }
    .notification-title {
        flex: 1;
    }
    .notification-message {
        margin: 4px 0 0 24px;
    }
    .notification-action {
        margin: 4px 0 4px 24px;
    }
    .action-button {
        cursor: pointer;
    }
</style>
