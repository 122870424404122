import type { IdBimScene } from "bim-ts";
import type { Coordinate, PVColor } from "./Models";
import type { Vector3 } from "math-ts";

export interface ShadingDefinition{
    TypeIdentifier:TypeIdentifier,
    parentId:IdBimScene,
    width:number;
    length:number;
    color:PVColor;
    position:Coordinate;
    rotate:number;
    slope:number;
    tilt:number;
    orientation:Vector3;

}

export class GroupData {
    TypeIdentifier: TypeIdentifier;
    parentId: IdBimScene;
    width: number;
    length: number;
    color: PVColor;
    rotate: number;
    slope: number;
    tilt: number;
    heightPosition: number;
    orientation:Vector3;

    constructor(typeIdentifier: TypeIdentifier,
        parentId: IdBimScene,
        width: number, 
        length: number,
        color: PVColor, 
        rotate: number,
        slope: number,
        tilt: number,
        heightPosition: number,
        orientation:Vector3

        ) {

        this.TypeIdentifier = typeIdentifier;
        this.parentId = parentId;
        this.width = width;
        this.length = length;
        this.color = color;
        this.rotate = rotate;
        this.slope = slope;
        this.tilt = tilt;
        this.heightPosition = heightPosition;
        this.orientation = orientation;
    }

    toString(): string {
        return JSON.stringify(this);
      }
    
}

export enum TypeIdentifier
{
    tracker,
    fixtilt,
    undulated,
}
