import type { ScopedLogger, LazyVersioned, Result, Observer} from 'engine-utils-ts';
import { Yield, VersionedInvalidator, Allocated, Deleted, ObservableObject, PollablePromise, DefaultMap, LruCache, LogLevel, Failure, Success, IterUtils } from 'engine-utils-ts';
import type { Bim } from '../../Bim';
import type { EntitiesCollectionUpdates} from '../../collections/EntitiesCollectionUpdates';
import { EntitiesUpdated } from '../../collections/EntitiesCollectionUpdates';
import type { GroupDescription, GroupIdent, GroupingSolver, GroupingSolverSettings } from '../../runtime/BimCustomGroupedRuntime';
import { BimCustomGroupedRuntime } from '../../runtime/BimCustomGroupedRuntime';
import type { IdBimScene, SceneInstance, SceneInstancePatch} from '../../scene/SceneInstances';
import { SceneObjDiff } from 'src/scene/SceneObjDiff';
import type { RepresentationBase} from '../../representation/Representations';
import { StdGroupedMeshRepresentation, StdMeshRepresentation, TerrainHeightMapRepresentation } from '../../representation/Representations';
import type { InputShading, OutputShading, Point, Quad } from './ShadingServiceApi';
import type { Matrix4, Vector2} from 'math-ts';
import { Aabb, KrMath, Vector3 } from 'math-ts';
import { TrackerShadingFactorsProperty } from './TrackerShadingProperty';
import { ShadingFactorsTable, PerHeightSamples, shadingPerHeightSamplesDedup } from './ShadingFactorsTable';
import { TerrainElevation } from '../../terrain/TerrainElevation';
import { BimGeometryType, type BimGeometries, type IdBimGeo } from '../../geometries/BimGeometries';
import type { RegularHeightmapGeometry } from '../../geometries/RegularHeightmapGeometry';
import { RegularHeightmapGeometries } from '../../geometries/RegularHeightmapGeometry';
import { TerrainGeoVersionSelector, TerrainTileId } from '../../terrain/TerrainTile';
import type { TrackerBuilderInfo, TrackerProps} from '../Tracker';
import { TrackerTypeIdent, getLocalPilesCoords, hasDifferentSegmentsSlopes } from '../Tracker';
import { TrackerShadingProps } from './TrackerShadingProps';
import { TerrainVersion } from '../../terrain/Terrain';
import type { PUI_GroupNode } from 'ui-bindings';
import type { MathSolversApi } from '../../mathSolversApi/MathSolversApi';
import { FixedTiltTypeIdent, type FixedTiltProps } from '../../archetypes/fixed-tilt/FixedTilt';
import { getHeightmapFromContour, prepareBbox, type HeightMap } from 'src/terrain/cut-fill/CutFillSolverGenerator';
import { EnergyStageCalculateConfig } from '../../energy/EnergyCalcConfig';
import { EnergyStageSettingsConfig } from '../../energy/EnergyStageSettingsConfig';
import { producePropsPatch } from '../../properties/Props';
import { AnyTrackerProps } from '../../anyTracker/AnyTracker';



export function registerShadingRuntime(
    bim: Bim,
    mathSolversApi: MathSolversApi,
) {
    bim.customRuntimes.registerCustomRuntime(new BimCustomGroupedRuntime(
        bim.logger.newScope('shading', LogLevel.Info),
        'shading-runtime',
        new TerrainShadingSolver(bim, mathSolversApi),
    ));
}

class ShadingGroupDescription implements GroupDescription<ShadingSingleGlobalGroupIdent> {

    constructor(
        public readonly ident: ShadingSingleGlobalGroupIdent,
        public readonly invalidator: number,
        public readonly trackersInstancesIds: IdBimScene[],
        public readonly simpleOccludorsIds: IdBimScene[],
        public readonly terrainIds: IdBimScene[],
    ) {
        this.trackersInstancesIds.sort((n1, n2) => n1 - n2);
        this.simpleOccludorsIds.sort((n1, n2) => n1 - n2);
        this.terrainIds.sort((n1, n2) => n1 - n2);
    }

    *inputInstancesIds(): Iterable<IdBimScene> {
        yield* this.trackersInstancesIds;
        yield* this.simpleOccludorsIds;
        yield* this.terrainIds;
    }

}

class ShadingSettings implements GroupingSolverSettings {
    enabled: boolean = false;
    delaySeconds: number = 3;
    includeTerrain: boolean = false;
}


interface ShadingGroupCalcResults {
    per_tracker_shading_factors: Map<IdBimScene, ShadingFactorsTable>;
}

class ShadingSingleGlobalGroupIdent implements GroupIdent {
    sortKey: string = '';
    uniqueHash(): string | number {
        return this.sortKey;
    }
    constructor() {
        Object.freeze(this);
    }
}


const trackersTypes = ['tracker', 'fixed-tilt', 'any-tracker'];

export class TerrainShadingSolver implements GroupingSolver<
    ShadingSingleGlobalGroupIdent,
    ShadingGroupDescription,
    ShadingGroupCalcResults,
    ShadingSettings,
    {}
> {
    
    name: string = 'trackers-shading';
    executionOrder: number = 50;
    
    calculationsInvalidator = new VersionedInvalidator();

    settings: ObservableObject<ShadingSettings>;
    configSubscriber: Observer;
    ui: LazyVersioned<PUI_GroupNode> | undefined;

    mathSolversApi: MathSolversApi;

    constructor(
        bim: Bim,
        mathSolversApi: MathSolversApi,
    ){
        this.mathSolversApi = mathSolversApi;
        this.settings = new ObservableObject<ShadingSettings>({
            identifier: '',
            initialState: new ShadingSettings(),
        });

        this.configSubscriber = bim.configs.updatesStream.subscribe({
            onNext: (update) => {
                if (update instanceof Deleted) {
                    return;
                }
                for (let i = 0; i < update.ids.length; i++) {
                    const id = update.ids[i];
                    const config = bim.configs.peekById(id);
                    if (config?.type_identifier !== EnergyStageSettingsConfig.name) {
                        continue;
                    }
                    const currentSettings = this.settings.currentValue();
                    const configProps = config.propsAs(EnergyStageSettingsConfig);
                    const includeTerrain = configProps.shading.calculation_settings.include_terrain.value;
                    if (currentSettings.includeTerrain !== includeTerrain) {
                        this.settings.applyPatch({patch: { includeTerrain }});
                    }
                    const shadingCalcConfig = configProps.shading.calculationConfig();
                    const shadingEnabled = shadingCalcConfig instanceof EnergyStageCalculateConfig;
                    if (currentSettings.enabled !== shadingEnabled) {
                        this.settings.applyPatch({patch: { enabled: shadingEnabled }});
                    }
                }
            }
        });
    }

    invalidateFromSharedDependenciesUpdates(ident: never): void {
    }

    invalidate(
        logger: ScopedLogger,
        bim: Bim,
        instancesUpdate: EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>,
        allPrevUsedInstances: ReadonlySet<IdBimScene>,
    ): void {
        if (isShadingInvalidatedByUpdate(logger, bim, instancesUpdate, allPrevUsedInstances, this.settings.poll())) {
            this.calculationsInvalidator.invalidate();
        }
    }

    *generateCalculationGroups(args: {
        logger: ScopedLogger,
        bim: Bim,
    }): Generator<Yield, ShadingGroupDescription[]> {

        const groupIdent = new ShadingSingleGlobalGroupIdent();
        const invalidator = this.calculationsInvalidator.version();
        const trackersInstancesIds: IdBimScene[] = [];
        const simpleOccludorsIds: IdBimScene[] = [];
        const terrainIds: IdBimScene[] = [];

        const settings = this.settings.poll();
        for (const [id, instance] of args.bim.instances.perId) {
            if (isInstanceIncludedInShading(instance, settings)) {
                if (instance.representation instanceof TerrainHeightMapRepresentation) {
                    terrainIds.push(id);
                } else if (trackersTypes.includes(instance.type_identifier)) {
                    trackersInstancesIds.push(id);
                } else {
                    simpleOccludorsIds.push(id);
                }
            }
        };

        if (trackersInstancesIds.length === 0) {
            args.logger.debug('no trackers to calculate shading for');
            return [];
        }

        return [new ShadingGroupDescription(
            groupIdent,
            invalidator,
            trackersInstancesIds,
            simpleOccludorsIds,
            terrainIds,
        )];
    }


    *startGroupResultsCalculation(args: { logger: ScopedLogger; bim: Bim; groupDescription: ShadingGroupDescription; }): Generator<Yield, ShadingGroupCalcResults> {
        
        const logger = args.logger;
        const instances = args.bim.instances;
        const geometries = args.bim.allBimGeometries;
        
        const trackers = new Map<IdBimScene, SmartTrackerRepr | SimpleTrackerRepr | UndulatedTrackerRepr | null>();
        let trackersMinZ = -Infinity;
        {
            const geometriesAabb = geometries.aabbs.poll();
            for (const [id, instance] of instances.peekByIds(args.groupDescription.trackersInstancesIds)) {
                const trackerShadingDescr = extractTrackerShadingData(args.logger, instance, geometriesAabb);
                trackers.set(id, trackerShadingDescr);
                if (trackerShadingDescr) {
                    const minZ = trackerShadingDescr.minZ();
                    if (minZ < trackersMinZ) {
                        trackersMinZ = minZ;
                    }
                } else {
                    logger.debug('no tracker shading data found', instance);
                }
            }
        }

        yield Yield.Asap;

        if (trackersMinZ === -Infinity) {
            args.logger.debug('no trackers aabb found');
        }

        const request: InputShading = {
            points: [],
            triangles: [],
            simple_trackers: [],
            smart_trackers: [],
            factor_request: [],
            shade_request: [],
            undulated_trackers: [],
        };

        const geometriesAabb = geometries.aabbs.poll();
        for (const terrainId of args.groupDescription.terrainIds) {
            const terrainInstance = args.bim.instances.peekById(terrainId);
            if (!terrainInstance) {
                continue;
            }

            const repr = terrainInstance.representation;
            if (!(repr instanceof TerrainHeightMapRepresentation)) {
                logger.error('unexpected terrain representation', repr);
                continue;
            }

            const terrainAabb = repr.aabb(geometriesAabb);
            terrainAabb.applyMatrix4(terrainInstance.worldMatrix);

            if (trackersMinZ >= terrainAabb.maxz()) {
                logger.debug('terrain is completely below trackers');
                continue;
            }

            const terrainShadingObjects = yield* extractRegularTerrainInstancePointsToUse(
                logger, repr, terrainAabb, terrainInstance.worldMatrix, geometries,
            );

            if (terrainShadingObjects) {
                request.height_map = terrainShadingObjects;
            }

            yield Yield.Asap;
        }

        const allPointsToSample: Vector2[] = [];
        const perIndexIds: IdBimScene[] = [];
        const perIndexZs: IdBimScene[] = [];
        for (const [id, trackerDescr] of trackers) {
            let points: Vector3[] = [];
            if (trackerDescr instanceof SmartTrackerRepr) {
                points = trackerDescr.core;
            } else if (trackerDescr instanceof SimpleTrackerRepr) {
                points = trackerDescr.points;
            } else if (trackerDescr instanceof UndulatedTrackerRepr) {
                points = trackerDescr.piles;
            } else {
                continue;
            }
            for (const p of points) {
                allPointsToSample.push(p.xy());
                perIndexIds.push(id);
                perIndexZs.push(p.z);
            }
        }

        logger.assert(allPointsToSample.length === perIndexIds.length, 'points ids arrays sanity check');

        const elevations = TerrainElevation.sampleFromBim(logger, args.bim, allPointsToSample, TerrainVersion.Latest);

        const trackersIntersectingGround = new Set<IdBimScene>();
        for (let i = 0; i < elevations.length; ++i) {
            const elevation = elevations[i];
            if (elevation.elevation === null) {
                continue;
            }
            if (elevation.elevation > perIndexZs[i]) {
                trackersIntersectingGround.add(perIndexIds[i]);
            }
        }

        if (trackersIntersectingGround.size > 0) {
            logger.debug('trackers intersecting ground', Array.from(trackersIntersectingGround));
            for (const id of trackersIntersectingGround.keys()) {
                trackers.set(id, null);
            }
        }

        const simpleTrackers: IdBimScene[] = [];
        const smartTrackers: IdBimScene[] = [];
        const undulatedTrackers: IdBimScene[] = [];

        for (const [id, trackerShadingData] of trackers) {
            if (trackerShadingData instanceof SimpleTrackerRepr) {
                simpleTrackers.push(id);
                request.simple_trackers.push(
                    trackerShadingData.points.map(p => [p.x, p.y, p.z] as const) as Quad
                );
            } else if (trackerShadingData instanceof UndulatedTrackerRepr) {
                undulatedTrackers.push(id);
                request.undulated_trackers.push({
                    piles: trackerShadingData.piles.map(c => [c.x, c.y, c.z]),
                    half_width: trackerShadingData.half_width,
                    left_max_angle: trackerShadingData.left_max_angle,
                    right_max_angle: trackerShadingData.right_max_angle,
                });
            } else if (trackerShadingData instanceof SmartTrackerRepr) {
                smartTrackers.push(id);
                request.smart_trackers.push({
                    core: trackerShadingData.core.map(c => [c.x, c.y, c.z] as const) as [Point, Point],
                    half_width: trackerShadingData.half_width,
                    left_max_angle: trackerShadingData.left_max_angle,
                    right_max_angle: trackerShadingData.right_max_angle,
                });
            } else {
                logger.batchedError('unexpected prepared tracker data format', trackerShadingData);
            }
        }
        yield Yield.Asap;

        {
            const geometriesAabbs = geometries.aabbs.poll();
            const reprsAabbs = new DefaultMap<RepresentationBase, Aabb>(r => {
                return r.aabb(geometriesAabbs);
            });

            perInstance:
            for (const [id, instance] of instances.peekByIds(instances.allIds())) {
                if (!(instance.representation instanceof StdMeshRepresentation)) {
                    continue;
                }
                if (trackers.has(id)) {
                    continue;
                }
                const shadingProps = extractShadingObjectTriangles(logger, instance, reprsAabbs, geometries);
                if (!shadingProps) {
                    continue;
                }
                const { points, triangles } = shadingProps;
                const offset = request.points.length;

                for (const index of triangles) {
                    if (index < 0 || index >= points.length) {
                        logger.batchedError('invalid instance shading geometry indices', [instance, shadingProps]);
                        continue perInstance;
                    }
                }

                for (const p of points) {
                    request.points.push([p.x, p.y, p.z]);
                }
                for (let i = 0; i < triangles.length; i += 3) {
                    const ind0 = offset + triangles[i];
                    const ind1 = offset + triangles[i + 1];
                    const ind2 = offset + triangles[i + 2];
                    request.triangles.push([ind0, ind1, ind2]);
                }
            }
        }

        const TotalTrackersCount = simpleTrackers.length + smartTrackers.length + undulatedTrackers.length;

        yield Yield.NextFrame;


        const sunHeightDegrees: number[] = [];
        const sunAzimuthDegreesPerHeight: number[][] = [];

        for (let height = 0; height <= 24; height += 3) {
            const sunAzimuthDegrees = [];
            for (let azimuth = 0; azimuth < 360; azimuth += 18) { // 12 samples
                sunAzimuthDegrees.push(azimuth);
            }
            sunHeightDegrees.push(height);
            sunAzimuthDegreesPerHeight.push(sunAzimuthDegrees);
        }
        for (let height = 24 + 6; height <= 48; height += 6) {
            const sunAzimuthDegrees = [];
            for (let azimuth = 0; azimuth < 360; azimuth += 30) { // 12 samples
                sunAzimuthDegrees.push(azimuth);
            }
            sunHeightDegrees.push(height);
            sunAzimuthDegreesPerHeight.push(sunAzimuthDegrees);
        }
        for (let height = 48 + 6; height <= 84; height += 6) {
            const sunAzimuthDegrees = [];
            for (let azimuth = 0; azimuth < 360; azimuth += 45) { // 8 samples
                sunAzimuthDegrees.push(azimuth);
            }
            sunHeightDegrees.push(height);
            sunAzimuthDegreesPerHeight.push(sunAzimuthDegrees);
        }
        // last top sample
        sunHeightDegrees.push(90);
        sunAzimuthDegreesPerHeight.push([0]);

        const azimuthRowsDelimiterPerHeight: number[] = [];

        for (let ih = 0; ih < sunHeightDegrees.length; ++ih) {
            const height = sunHeightDegrees[ih];
            const azimuths = sunAzimuthDegreesPerHeight[ih];
            for (const azimuth of azimuths) {
                request.factor_request.push({ azimuth, elevation: height });
            }
            azimuthRowsDelimiterPerHeight.push(request.factor_request.length);
        }

        logger.debug('request', request);

        const solverRequestPromise = this.mathSolversApi.callSolver<
            InputShading,
            OutputShading
        >({
            solverName: "trackers_shading",
            solverType: "multi",
            request,
        });

        const responseResult = yield* PollablePromise.generatorWaitFor(solverRequestPromise);
        if (responseResult instanceof Failure) {
            logger.error('shading calculation failed', responseResult.toString());
            throw new Error("failed");
        }
        const response = responseResult.value;
        logger.debug('shading response', response);

        yield Yield.NextFrame;

        const SHADING_FACTORS_ROUNDING = 0.01;

        const perSimpleTrackerShadingFactors: number[][] = simpleTrackers.map(() => []);
        const perSmartTrackerShadingFactors: number[][] = smartTrackers.map(() => []);
        const perUndulatedTrackerShadingFactors: number[][] = undulatedTrackers.map(() => []);

        for (const factors of response.factors) {
            args.logger.assert(factors.simple_tracker_direct_shading_factors.length === simpleTrackers.length, 'simple shading factors length sanity check');
            for (let i = 0; i < simpleTrackers.length; ++i) {
                const shadingFactor = factors.simple_tracker_direct_shading_factors[i];
                perSimpleTrackerShadingFactors[i].push(KrMath.roundTo(shadingFactor, SHADING_FACTORS_ROUNDING));
            }
            args.logger.assert(factors.smart_tracker_direct_shading_factors.length === smartTrackers.length, 'smart shading factors length sanity check');
            for (let i = 0; i < smartTrackers.length; ++i) {
                const shadingFactor = factors.smart_tracker_direct_shading_factors[i];
                perSmartTrackerShadingFactors[i].push(KrMath.roundTo(shadingFactor, SHADING_FACTORS_ROUNDING));
            }
            args.logger.assert(factors.undulated_tracker_direct_shading_factors.length === undulatedTrackers.length, 'undulated shading factors length sanity check');
            for (let i = 0; i < undulatedTrackers.length; ++i) {
                const shadingFactor = factors.undulated_tracker_direct_shading_factors[i];
                perUndulatedTrackerShadingFactors[i].push(KrMath.roundTo(shadingFactor, SHADING_FACTORS_ROUNDING));
            }
        }
        response.factors.length = 0; // allow gc


        const trackersShadingTables = new Map<IdBimScene, ShadingFactorsTable>();

        yield Yield.NextFrame;

        let heightSamplesRuntimeIds: number = 1;
        const idsGenerator = new DefaultMap<PerHeightSamples, number>((phs) => heightSamplesRuntimeIds++)

        const perShadingFactorsDedupedTables = new LruCache<PerHeightSamples[], ShadingFactorsTable>({
            identifier: 'shading-factors-dedup',
            maxSize: Math.max(100, TotalTrackersCount * 0.15),
            hashFn: (arr) => {
                // PER HEIGHT SAMPLES ASSUMED TO BE DEDUPED
                let hash = ``;
                for (const phs of arr) {
                    hash += idsGenerator.getOrCreate(phs) + ';';
                }
                return hash;
            },
            eqFunction: (a, b) => {
                if (a.length !== b.length) {
                    return false;
                }
                for (let i = 0; i < a.length; ++i) {
                    if (!a[i].equals(b[i])) {
                        return false;
                    }
                }
                return true;
            },
            factoryFn: (arr) => {
                const table = new ShadingFactorsTable(arr);
                return table;
            }
        });

        function createShadingTableFromShadingFactors(shadingTableNumbers: number[]) {
            const perHeightSamples: PerHeightSamples[] = [];
            let lastAzimuthIndex = 0;
            for (let j = 0; j < sunHeightDegrees.length; ++j) {
                const height = sunHeightDegrees[j];
                const delimiter = azimuthRowsDelimiterPerHeight[j];
                const factors = shadingTableNumbers.slice(lastAzimuthIndex, delimiter);
                lastAzimuthIndex = delimiter;
                const sunAzimuthDegrees = sunAzimuthDegreesPerHeight[j];
                const step = sunAzimuthDegrees.length > 1 ? sunAzimuthDegrees[1] - sunAzimuthDegrees[0] : 360;
                let phs = new PerHeightSamples(height, step, factors);
                phs = shadingPerHeightSamplesDedup.getOrCreate(phs);
                perHeightSamples.push(phs);
            }
            const shadingTable = perShadingFactorsDedupedTables.get(perHeightSamples);
            return shadingTable;
        }

        for (let i = 0; i < perSimpleTrackerShadingFactors.length; ++i) {
            const id = simpleTrackers[i];
            const shadingTableNumbers = perSimpleTrackerShadingFactors[i];
            const shadingTable = createShadingTableFromShadingFactors(shadingTableNumbers);
            trackersShadingTables.set(id, shadingTable);
        }
        yield Yield.NextFrame;
        for (let i = 0; i < perSmartTrackerShadingFactors.length; ++i) {
            const id = smartTrackers[i];
            const shadingTableNumbers = perSmartTrackerShadingFactors[i];
            const shadingTable = createShadingTableFromShadingFactors(shadingTableNumbers);
            trackersShadingTables.set(id, shadingTable);
        }
        yield Yield.NextFrame;
        for (let i = 0; i < perUndulatedTrackerShadingFactors.length; ++i) {
            const id = undulatedTrackers[i];
            const shadingTableNumbers = perUndulatedTrackerShadingFactors[i];
            const shadingTable = createShadingTableFromShadingFactors(shadingTableNumbers);
            trackersShadingTables.set(id, shadingTable);
        }

        const allShadingTables = new Set(trackersShadingTables.values());
        console.log('unique shading tables count', allShadingTables.size);

        return {
            per_tracker_shading_factors: trackersShadingTables,
        }
    }


    applyResultsToBim(args: {
        logger: ScopedLogger;
        bim: Bim;
        groupDescription: ShadingGroupDescription;
        groupCalcResults: Result<ShadingGroupCalcResults>;
    }): void {

        let perTrackerShadingCalculated: Map<IdBimScene, ShadingFactorsTable>;
        if (args.groupCalcResults instanceof Success) {
            perTrackerShadingCalculated = args.groupCalcResults.value.per_tracker_shading_factors
        } else {
            perTrackerShadingCalculated = new Map();
        }
        
        const patches: [IdBimScene, SceneInstancePatch][] = IterUtils.filterMap(
            args.groupDescription.trackersInstancesIds,
            id => {
                const instance = args.bim.instances.peekById(id);
                if (!instance) {
                    return undefined;
                }
                const ident = args.bim.instances.peekTypeIdentOf(id);
                const shadingTable = perTrackerShadingCalculated.get(id);

                const shadingProperty = shadingTable ? new TrackerShadingFactorsProperty({
                    value: shadingTable,
                }) : null;

                const patch = producePropsPatch(instance.props as TrackerProps | FixedTiltProps | AnyTrackerProps, (props) => {
                    props.shading = new TrackerShadingProps({
                        shading_factors: shadingProperty,
                    });
                });
                if (!patch) {
                    return undefined;
                }
                return [id, {props: patch}];
            }
        );

        args.bim.instances.applyPatches(patches, {doesInvalidatePersistedState: true});
    }
    dispose(): void {
        this.configSubscriber.dispose();
    }
}

function isShadingInvalidatedByUpdate(
    logger: ScopedLogger,
    bim: Bim,
    instancesUpdate: EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>,
    allPrevUsedInstances: ReadonlySet<IdBimScene>,
    settings: ShadingSettings
): boolean {
    const relevantObjFlags = SceneObjDiff.GeometryReferenced | SceneObjDiff.Representation | SceneObjDiff.WorldPosition;
    if (instancesUpdate instanceof Allocated) {
        for (const id of instancesUpdate.ids) {
            const instance = bim.instances.peekById(id);
            if (!instance) {
                continue;
            }
            if (isInstanceIncludedInShading(instance, settings)) {
                return true;
            }
        }
    } else if (instancesUpdate instanceof EntitiesUpdated) {
        if ((instancesUpdate.allFlagsCombined & relevantObjFlags) === 0) {
            return false;
        }
        for (let i = 0; i < instancesUpdate.ids.length; ++i) {
            const diff = instancesUpdate.diffs[i];
            if ((diff & relevantObjFlags) === 0) {
                continue;
            }
            const id = instancesUpdate.ids[i];
            if (allPrevUsedInstances.has(id)) {
                return true;
            }
            const instance = bim.instances.peekById(id);
            if (!instance) {
                continue;
            }
            if (isInstanceIncludedInShading(instance, settings)) {
                return true;
            }
        }
        
    } else if (instancesUpdate instanceof Deleted) {
        for (const id of instancesUpdate.ids) {
            if (allPrevUsedInstances.has(id)) {
                return true;
            }
        }
    } else {
        logger.error('Unexpected type of instancesUpdate', instancesUpdate);
    }
    return false;
}

function isInstanceIncludedInShading(instance: SceneInstance, shadingSettings: ShadingSettings) {
    if (instance.representation instanceof StdMeshRepresentation || instance.representation instanceof StdGroupedMeshRepresentation) {
        return true;
    }
    if (shadingSettings.includeTerrain && instance.representation instanceof TerrainHeightMapRepresentation) {
        return true;
    }
    return false;
}

class UndulatedTrackerRepr {
    constructor(
        public readonly piles: Vector3[],
        public readonly half_width: number,
        public readonly left_max_angle: number,
        public readonly right_max_angle: number,
    ) {
    }

    minZ() {
        return IterUtils.minBy(this.piles, p => p.z)!.z;
    }
}

class SmartTrackerRepr {
    constructor(
        public readonly core: [Vector3, Vector3],
        public readonly half_width: number,
        public readonly left_max_angle: number,
        public readonly right_max_angle: number,
    ) {
    }

    minZ() {
        return Math.min(this.core[0].z, this.core[1].z);
    }
}
class SimpleTrackerRepr {
    constructor(
        public readonly points: [Vector3, Vector3, Vector3, Vector3],
    ) {
    }

    minZ() {
        return IterUtils.minBy(this.points, p => p.z)!.z;
    }
}

class ObjectShadingGeometry {
    constructor(
        public readonly points: Vector3[],
        public readonly triangles: number[],
    ) {

    }
}

function isUndulatedTracker(instance: SceneInstance): boolean {
    const slopes = instance.properties.get("position | _segments-slopes")?.asText();
    if (instance.type_identifier !== TrackerTypeIdent || !slopes) {
        return false;
    }
    const isUndulated = hasDifferentSegmentsSlopes(slopes);
    return isUndulated;
}

export function extractTrackerShadingData(
    logger: ScopedLogger,
    instance: SceneInstance,
    geometriesAabbs: ReadonlyMap<IdBimGeo, Aabb>
): SmartTrackerRepr | SimpleTrackerRepr | UndulatedTrackerRepr | null {

    if (instance.props instanceof AnyTrackerProps) {

        if(!instance.representation){
            return null;
        }
        const aabb = instance.representation.aabb(geometriesAabbs);
        if(aabb.isEmpty()){
            return null;
        }
        const width = instance.props.tracker_frame.dimensions.max_width?.as('m');
        const length = instance.props.tracker_frame.dimensions.length?.as('m');
        if (!width || !length) {
            return null;
        }
        const startEnd: [Vector3, Vector3] = [
            new Vector3(0, -length * 0.5, 0),
            new Vector3(0, length * 0.5, 0),
        ];

        for (const p of startEnd) {
            p.applyMatrix4(instance.worldMatrix);
        }

        return new SmartTrackerRepr(
            startEnd,
            width * 0.5,
            60,
            60,
        );
        
    } else if (instance.type_identifier === TrackerTypeIdent && !isUndulatedTracker(instance)) {
        if(!instance.representation){
            return null;
        }
        const aabb = instance.representation.aabb(geometriesAabbs);
        if(aabb.isEmpty()){
            return null;
        }
        const reveal = instance.properties.get("tracker-frame | piles | max_reveal")?.as("m")!;

        const startEnd: [Vector3, Vector3] = [
            new Vector3(aabb.minx() + (aabb.maxx() - aabb.minx()) / 2, aabb.miny(), 0 + reveal),
            new Vector3(aabb.minx() + (aabb.maxx() - aabb.minx()) / 2, aabb.maxy(), 0 + reveal),
        ];

        for (const p of startEnd) {
            p.applyMatrix4(instance.worldMatrix);
        }
        const width = instance.properties.get("tracker-frame | dimensions | max_width")?.as("m")!;

        return new SmartTrackerRepr(
            startEnd,
            width * 0.5,
            60,
            60,
        );
    } else if(instance.type_identifier === TrackerTypeIdent ){
        const slopes = instance.properties.get("position | _segments-slopes")?.asText()!;
        const width = instance.properties.get("tracker-frame | dimensions | max_width")?.as("m")!;
        const moduleMountingWidth = instance.properties.get("tracker-frame | module_mounting | module_width")?.as("m")!;
        const moduleWidth = instance.properties.get("module | width")?.as("m")!;
        const buildParams: TrackerBuilderInfo = {
            useModulesRow: instance.properties.get("tracker-frame | dimensions | use_modules_row")?.asBoolean()!,
            modulesRow: instance.properties.get("tracker-frame | dimensions | modules_row")?.asText()!,
            modulesPerStringCountHorizontal: instance.properties.get("tracker-frame | string | modules_count_x")?.asNumber()!,
            stringsPerTrackerCount: instance.properties.get("tracker-frame | dimensions | strings_count")?.asNumber()!,
            moduleBayCount: instance.properties.get("tracker-frame | dimensions | module_bay_size")?.asNumber()!,
            moduleSize: moduleMountingWidth || moduleWidth,
            motorPlacementCoefficient: instance.properties.get("tracker-frame | dimensions | motor_placement")?.asNumber()!,
            pileGap: instance.properties.get("tracker-frame | dimensions | pile_bearings_gap")?.as("m")!,
            motorGap: instance.properties.get("tracker-frame | dimensions | motor_gap")?.as("m")!,
            stringGap: instance.properties.get("tracker-frame | dimensions | strings_gap")?.as("m")!,
            modulesGap: instance.properties.get("tracker-frame | dimensions | modules_gap")?.as("m")!,
        }
        const reveal = instance.properties.get("tracker-frame | piles | max_reveal")?.as("m")!;

        const pilesCoords = getLocalPilesCoords(buildParams, slopes);
        for (const coord of pilesCoords) {
            coord.set(coord.x, coord.y, coord.z + reveal);
            coord.applyMatrix4(instance.worldMatrix);
        }
        return new UndulatedTrackerRepr(
            pilesCoords,
            width * 0.5,
            60,
            60,
        );
    } else if (instance.type_identifier === FixedTiltTypeIdent) {
        const result = createPvModuleSurface(logger, instance, geometriesAabbs);
        if(!result){
            return null;
        }
        const [_lodGeoAabb, lodModulesPlaneQuad, _startEnd] = result;
        return new SimpleTrackerRepr(lodModulesPlaneQuad);
    }

    return null;
}

function createPvModuleSurface(logger: ScopedLogger, instance: SceneInstance, geometriesAabbs: ReadonlyMap<IdBimGeo, Aabb>) {
    const repr = instance.representation;

    if (!(repr instanceof StdMeshRepresentation)) {
        logger.batchedError('tracker instance without representation, skipping', instance);
        return null;
    }

    if (!repr.lod1 || repr.lod1.submeshes.length !== 1) {
        logger.batchedError('expected tracker lod1 representation with 1 submesh', instance);
        return null;
    }
    // expect tracker representation to have lod1 with single stretched cube geo
    const lodSubmesh = repr.lod1.submeshes[0];
    const lodGeoAabb = geometriesAabbs.get(lodSubmesh.geometryId);

    if (!lodGeoAabb) {
        logger.batchedError('unexpected abscence of geo aabb', lodSubmesh.geometryId);
        return null;
    }

    const lodGeoCenter = lodGeoAabb.getCenter_t();
    const lodGeoTr = lodSubmesh.transform!;

    const lodModulesPlaneQuad = [
        new Vector3(lodGeoAabb.minx(), lodGeoAabb.miny(), lodGeoCenter.z).multiply(lodGeoTr.scale),
        new Vector3(lodGeoAabb.minx(), lodGeoAabb.maxy(), lodGeoCenter.z).multiply(lodGeoTr.scale),
        new Vector3(lodGeoAabb.maxx(), lodGeoAabb.maxy(), lodGeoCenter.z).multiply(lodGeoTr.scale),
        new Vector3(lodGeoAabb.maxx(), lodGeoAabb.miny(), lodGeoCenter.z).multiply(lodGeoTr.scale),
    ] as [Vector3, Vector3, Vector3, Vector3];

    const planeQuadAabb = Aabb.empty().setFromPoints(lodModulesPlaneQuad);
    const planeQuadAabbSizeLocal = planeQuadAabb.getSize();

    logger.assert(planeQuadAabbSizeLocal.z < planeQuadAabbSizeLocal.xy().length(), 'expect x y coords to contain lod horizontal plane');

    for (const p of lodModulesPlaneQuad) {
        p.applyQuaternion(lodGeoTr.rotation).add(lodGeoTr.position);
        p.applyMatrix4(instance.worldMatrix);
    }

    const startEnd = [
        new Vector3(lodGeoAabb.centerX(), lodGeoAabb.miny(), lodGeoCenter.z).multiply(lodGeoTr.scale),
        new Vector3(lodGeoAabb.centerX(), lodGeoAabb.maxy(), lodGeoCenter.z).multiply(lodGeoTr.scale),
    ] as [Vector3, Vector3];
    for (const p of startEnd) {
        p.applyQuaternion(lodGeoTr.rotation).add(lodGeoTr.position);
        p.applyMatrix4(instance.worldMatrix);
    }

    return [lodGeoAabb, lodModulesPlaneQuad, startEnd] as const;
}


function extractShadingObjectTriangles(
    logger: ScopedLogger,
    instance: SceneInstance,
    representationsAabbs: DefaultMap<RepresentationBase, Aabb>,
    geometries: BimGeometries
): ObjectShadingGeometry | null {

    if (instance.representation instanceof StdMeshRepresentation) {
        const bbox = representationsAabbs.getOrCreate(instance.representation);
        if (bbox.isEmpty()) {
            logger.batchedWarn('mesh instance with empty aabb, skipping', instance);
            return null;
        }

        const minMax = bbox.elements;

        // create geometry of bbox points

        //http://web.cse.ohio-state.edu/~crawfis.3/cse581/Slides/cse581_extra_Modeling.pdf
        // Point3 vertices[] = {
        //     ( 1,-1, 1),
        //     ( 1,-1,-1),
        //     ( 1, 1,-1),
        //     ( 1, 1, 1),
        //     (-1,-1, 1),
        //     (-1,-1,-1),
        //     (-1, 1,-1),
        //     (-1, 1, 1)
        // };

        // triangles[] = {
        //     (4, 0, 3),
        //     (4, 3, 7),
        //     (0, 1, 2),
        //     (0, 2, 3),
        //     (1, 5, 6),
        //     (1, 6, 2),
        //     (5, 4, 7),
        //     (5, 7, 6),
        //     (7, 3, 2),
        //     (7, 2, 6),
        //     (0, 5, 1),
        //     (0, 4, 5)
        // };

        const points = [
			new Vector3(minMax[3], minMax[1], minMax[5]).applyMatrix4(instance.worldMatrix),
            new Vector3(minMax[3], minMax[1], minMax[2]).applyMatrix4(instance.worldMatrix),
			new Vector3(minMax[3], minMax[4], minMax[2]).applyMatrix4(instance.worldMatrix),
			new Vector3(minMax[3], minMax[4], minMax[5]).applyMatrix4(instance.worldMatrix),

			new Vector3(minMax[0], minMax[1], minMax[5]).applyMatrix4(instance.worldMatrix),
			new Vector3(minMax[0], minMax[1], minMax[2]).applyMatrix4(instance.worldMatrix),
			new Vector3(minMax[0], minMax[4], minMax[2]).applyMatrix4(instance.worldMatrix),
			new Vector3(minMax[0], minMax[4], minMax[5]).applyMatrix4(instance.worldMatrix),

		];

        const triangles: number[] = [
            4, 0, 3,
            4, 3, 7,
            0, 1, 2,
            0, 2, 3,
            1, 5, 6,
            1, 6, 2,
            5, 4, 7,
            5, 7, 6,
            7, 3, 2,
            7, 2, 6,
            0, 5, 1,
            0, 4, 5,
        ]

        return new ObjectShadingGeometry(points, triangles);
    }
    return null;
}

function* extractRegularTerrainInstancePointsToUse(
    logger: ScopedLogger,
    terrain: TerrainHeightMapRepresentation,
    aabb: Aabb,
    worldMatrix: Matrix4,
    geometries: BimGeometries,
): Generator<Yield, HeightMap | null, unknown> {
    const regularGeos = geometries.getCollectionByType<RegularHeightmapGeometry>(BimGeometryType.HeightmapRegular);
    if(!(regularGeos instanceof RegularHeightmapGeometries)){
        logger.warn('expected regular heightmap geometries', regularGeos);
        return null;
    }

    const aabb2 = aabb.xy();
    const polygon = aabb2.cornerPoints().slice();
    const tile = terrain.tiles.entries().next().value[1];
    const tileGeo = regularGeos.peekById(tile?.initialGeo)!;
    const segmentSize = tileGeo.segmentSizeInMeters;
    const cellSize = 8;
    prepareBbox(aabb2, segmentSize, cellSize);
    const gridFactor = cellSize / segmentSize;
    const minTileId: TerrainTileId = TerrainTileId.newFromPoint(aabb2.min, terrain.tileSize);
    const maxTileId: TerrainTileId = TerrainTileId.newFromPoint(aabb2.max, terrain.tileSize);
    const heightMap = yield* getHeightmapFromContour(
        polygon,
        [],
        aabb2,
        regularGeos,
        terrain,
        worldMatrix,
        minTileId,
        maxTileId,
        segmentSize,
        gridFactor,
        tile => tile.selectGeoId(TerrainGeoVersionSelector.Latest),
    );

    logger.debug('heightMap', heightMap);
    return heightMap;
}