import type { ObjectSerializer } from 'engine-utils-ts';
import { Builder, ByteBuffer } from 'flatbuffers';
import type { Vector3 } from 'math-ts';
import type { EntityIdAny } from 'verdata-ts';
import { ProjectionInfo } from '../';
import { FlatbufCommon } from '../';
import { WgsProjectionOrigin } from '../scene/BimSceneOrigin';
import { CollectionAdditionalContext as CollectionAdditionalContextW } from '../schema/collection-additional-context';
import { MapProjectionOrigin } from '../schema/map-projection-origin';
import { SceneOrigin as SceneOriginW } from '../schema/scene-origin';
import { FlatBufferKeyValue } from 'src/schema/flat-buffer-key-value';
import { FlatBufferProjectionInfo } from 'src/schema/flat-buffer-projection-info';

export interface CollectionAdditionalContext {
    sharedEntitiesIds?: EntityIdAny[],
    civilCoordsOrigin?: Vector3;
	projectionOrigin?: WgsProjectionOrigin;
}

export class CollectionAdditionalContextSerializer implements ObjectSerializer<CollectionAdditionalContext> {
    serialize(obj: CollectionAdditionalContext): Uint8Array {
        const b = new Builder();

        const idsOffset = obj.sharedEntitiesIds?.length ?
            CollectionAdditionalContextW.createSharedEntitiesIdsVector(b, obj.sharedEntitiesIds) : 0;
        
		let sceneOriginOffset: number;
		if (obj.civilCoordsOrigin || obj.projectionOrigin) {

			let projectionOriginOffset: number;
			if (obj.projectionOrigin) {
                const data: number[] = [];
                for (const par of obj.projectionOrigin.projectionInfo.parameters) {
                    data.push(FlatBufferKeyValue.createFlatBufferKeyValue(
                        b, b.createString(par[0]), b.createString(par[1])
                    ));
                }
                const projectionInfoOffset = FlatBufferProjectionInfo.createFlatBufferProjectionInfo(b, 
                    b.createString(obj.projectionOrigin.projectionInfo.method), 
                    FlatBufferProjectionInfo.createParametersVector(b, data)
                );
				MapProjectionOrigin.startMapProjectionOrigin(b);
				MapProjectionOrigin.addPoint(b, FlatbufCommon.writeWgsCoord(b, obj.projectionOrigin.wgsOriginLatLong)),
                MapProjectionOrigin.addProjectionInfo(b, projectionInfoOffset),
                MapProjectionOrigin.addWgsOriginLinearOffset(b, FlatbufCommon.writeVec3(b, obj.projectionOrigin.wgsOriginCartesianCoords)),
				projectionOriginOffset = MapProjectionOrigin.endMapProjectionOrigin(b);
			} else {
				projectionOriginOffset = 0;
			}

			SceneOriginW.startSceneOrigin(b);
			if (obj.civilCoordsOrigin) {
				SceneOriginW.addCivilSceneOriginLocal(b, FlatbufCommon.writeVec3(b, obj.civilCoordsOrigin));
			}
			SceneOriginW.addMapProjectionOrigin(b, projectionOriginOffset)
			sceneOriginOffset = SceneOriginW.endSceneOrigin(b);
		} else {
			sceneOriginOffset = 0;
		}
        
        CollectionAdditionalContextW.startCollectionAdditionalContext(b);
        CollectionAdditionalContextW.addSharedEntitiesIds(b, idsOffset);
        CollectionAdditionalContextW.addSceneOrigin(b, sceneOriginOffset);
        CollectionAdditionalContextW.addVersionPerTypeIdentifier(b, 0);
        const o = CollectionAdditionalContextW.endCollectionAdditionalContext(b);
        b.finish(o);
        return b.asUint8Array().slice();
    }

    deserialize(buffer: Uint8Array): CollectionAdditionalContext {
        const b = new ByteBuffer(buffer);
        const cac = CollectionAdditionalContextW.getRootAsCollectionAdditionalContext(b);
        const ids = cac.sharedEntitiesIdsArray();
        const sceneOrigin = cac.sceneOrigin();

        const res: CollectionAdditionalContext = {};
        if (ids) {
            res.sharedEntitiesIds = Array.from(ids);
        }
        if (sceneOrigin) {
            const civilOriginW = sceneOrigin.civilSceneOriginLocal();
            if (civilOriginW) {
                res.civilCoordsOrigin = FlatbufCommon.readVec3(civilOriginW);
            }
			const projOrigin = sceneOrigin.mapProjectionOrigin();
			if (projOrigin) {
                const wgsCoord = FlatbufCommon.readWgsCoord(projOrigin.point()!);
                let wgsOrigin:Vector3 | undefined = undefined;
                const wgsCoordLinOffset = projOrigin.wgsOriginLinearOffset();      
                if(wgsCoordLinOffset!=null){
                    wgsOrigin = FlatbufCommon.readVec3(wgsCoordLinOffset);
                }
                
                if (wgsCoord) {
                    let projectionInfo: ProjectionInfo | undefined = undefined;
                    const fbProjectionInfo = projOrigin.projectionInfo();
                    if (fbProjectionInfo) {
                        const parameters = new Map<string, string>();
                        for (let i = 0; i < fbProjectionInfo.parametersLength(); ++i) {
                            const par = fbProjectionInfo.parameters(i)!;
                            parameters.set(par.key()!, par.value()!);
                        }
                        projectionInfo = new ProjectionInfo(fbProjectionInfo.method()!, parameters);
                    }                    

                    res.projectionOrigin = new WgsProjectionOrigin(
                        wgsCoord,
                        projectionInfo ?? new ProjectionInfo(),
                        wgsOrigin
                    );
                }
			}
        }

        return res;
    }
}

