// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

export class TaskDescription {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):TaskDescription {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsTaskDescription(bb:flatbuffers.ByteBuffer, obj?:TaskDescription):TaskDescription {
  return (obj || new TaskDescription()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsTaskDescription(bb:flatbuffers.ByteBuffer, obj?:TaskDescription):TaskDescription {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new TaskDescription()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

guid():string|null
guid(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
guid(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

timeoutUtc():bigint {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint64(this.bb_pos + offset) : BigInt('0');
}

progress():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

static startTaskDescription(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addGuid(builder:flatbuffers.Builder, guidOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, guidOffset, 0);
}

static addTimeoutUtc(builder:flatbuffers.Builder, timeoutUtc:bigint) {
  builder.addFieldInt64(1, timeoutUtc, BigInt('0'));
}

static addProgress(builder:flatbuffers.Builder, progress:number) {
  builder.addFieldFloat32(2, progress, 0.0);
}

static endTaskDescription(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createTaskDescription(builder:flatbuffers.Builder, guidOffset:flatbuffers.Offset, timeoutUtc:bigint, progress:number):flatbuffers.Offset {
  TaskDescription.startTaskDescription(builder);
  TaskDescription.addGuid(builder, guidOffset);
  TaskDescription.addTimeoutUtc(builder, timeoutUtc);
  TaskDescription.addProgress(builder, progress);
  return TaskDescription.endTaskDescription(builder);
}
}
