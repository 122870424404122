<script lang="ts">
import { createMultiClickHandler } from "src/utils/multiClickHandler";
import { Chips } from "../chips";

import type { ListItem } from "./types";
import Variants from "./Variants.svelte";

export let componentType: string = 'li';
export let offset: number = 0;
export let item: ListItem;
export let secondLine: boolean;

$: itemClickHandler = createMultiClickHandler({
    onClick: item.onClick,
    on2Click: item.onDoubleClick
})

</script>

<svelte:element
    this={componentType}
    class="mdc-deprecated-list-item"
    class:mdc-deprecated-list-item--disabled={item.disabled}
    style="margin-left: {offset}px"
    on:click={() => !item.disabled && itemClickHandler()}
>
  <span class="mdc-deprecated-list-item__ripple"></span>

  <!-- Before content -->
  {#if item.before}
    <span class="mdc-deprecated-list-item__graphic">
      <Variants variant={item.before}/>
    </span>
  {/if}

  <!-- Main content -->
  {#if !secondLine}
    <span class="mdc-deprecated-list-item__text">
      {#if item.hasHtmlContent}
        {@html item.text}
      {:else}
        {item.text}
      {/if}
    </span>
  {:else}
    <span class="mdc-deprecated-list-item__text">
      <span class="mdc-deprecated-list-item__primary-text">{item.text}</span>
      <span class="mdc-deprecated-list-item__secondary-text">
        {item.secondary || ""}
      </span>
    </span>
  {/if}

  <!-- no after + tags -->
  {#if !item.after && item.chips && item.chips.items}
    <span class="mdc-deprecated-list-item__meta">
      <Chips data={item.chips} />
    </span>
  {/if}

  <!-- After content -->
  {#if item.after}
    <span class="mdc-deprecated-list-item__meta">
      {#if item.chips && item.chips.items.length}
          <Chips data={item.chips} />
      {/if}
      <Variants variant={item.after} />
    </span>
  {/if}

</svelte:element>

<style lang="scss">
.mdc-deprecated-list-item__meta {
    display: flex;
    align-items: center;
    height: inherit;
}
.mdc-deprecated-list-item {
    overflow: visible;
    will-change: unset;
    user-select: none;
}
.mdc-deprecated-list-item__ripple {
    overflow: hidden;
}
.mdc-deprecated-list-item :global(.mdc-icon-button) {
    width: 40px;
    height: 40px;
    padding: 4px;
}
.mdc-deprecated-list-item :global(.mdc-chip-set) {
    padding: 0 4px;
}
</style>
