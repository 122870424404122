// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { PropertiesGroupT } from './properties-group.js';
import { PropertiesGroup } from './properties-group.js';
import type { SourceT } from './source.js';
import { Source } from './source.js';


export class CatalogItem implements flatbuffers.IUnpackableObject<CatalogItemT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):CatalogItem {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsCatalogItem(bb:flatbuffers.ByteBuffer, obj?:CatalogItem):CatalogItem {
  return (obj || new CatalogItem()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsCatalogItem(bb:flatbuffers.ByteBuffer, obj?:CatalogItem):CatalogItem {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new CatalogItem()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

name():string|null
name(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
name(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

typeIdentifier():string|null
typeIdentifier(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
typeIdentifier(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

properties(obj?:PropertiesGroup):PropertiesGroup|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new PropertiesGroup()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

source(obj?:Source):Source|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new Source()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

createdAt():bigint {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.readInt64(this.bb_pos + offset) : BigInt('0');
}

updatedAt():bigint {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.readInt64(this.bb_pos + offset) : BigInt('0');
}

static startCatalogItem(builder:flatbuffers.Builder) {
  builder.startObject(6);
}

static addName(builder:flatbuffers.Builder, nameOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, nameOffset, 0);
}

static addTypeIdentifier(builder:flatbuffers.Builder, typeIdentifierOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, typeIdentifierOffset, 0);
}

static addProperties(builder:flatbuffers.Builder, propertiesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, propertiesOffset, 0);
}

static addSource(builder:flatbuffers.Builder, sourceOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, sourceOffset, 0);
}

static addCreatedAt(builder:flatbuffers.Builder, createdAt:bigint) {
  builder.addFieldInt64(4, createdAt, BigInt('0'));
}

static addUpdatedAt(builder:flatbuffers.Builder, updatedAt:bigint) {
  builder.addFieldInt64(5, updatedAt, BigInt('0'));
}

static endCatalogItem(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): CatalogItemT {
  return new CatalogItemT(
    this.name(),
    this.typeIdentifier(),
    (this.properties() !== null ? this.properties()!.unpack() : null),
    (this.source() !== null ? this.source()!.unpack() : null),
    this.createdAt(),
    this.updatedAt()
  );
}


unpackTo(_o: CatalogItemT): void {
  _o.name = this.name();
  _o.typeIdentifier = this.typeIdentifier();
  _o.properties = (this.properties() !== null ? this.properties()!.unpack() : null);
  _o.source = (this.source() !== null ? this.source()!.unpack() : null);
  _o.createdAt = this.createdAt();
  _o.updatedAt = this.updatedAt();
}
}

export class CatalogItemT implements flatbuffers.IGeneratedObject {
constructor(
  public name: string|Uint8Array|null = null,
  public typeIdentifier: string|Uint8Array|null = null,
  public properties: PropertiesGroupT|null = null,
  public source: SourceT|null = null,
  public createdAt: bigint = BigInt('0'),
  public updatedAt: bigint = BigInt('0')
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const name = (this.name !== null ? builder.createString(this.name!) : 0);
  const typeIdentifier = (this.typeIdentifier !== null ? builder.createString(this.typeIdentifier!) : 0);
  const properties = (this.properties !== null ? this.properties!.pack(builder) : 0);
  const source = (this.source !== null ? this.source!.pack(builder) : 0);

  CatalogItem.startCatalogItem(builder);
  CatalogItem.addName(builder, name);
  CatalogItem.addTypeIdentifier(builder, typeIdentifier);
  CatalogItem.addProperties(builder, properties);
  CatalogItem.addSource(builder, source);
  CatalogItem.addCreatedAt(builder, this.createdAt);
  CatalogItem.addUpdatedAt(builder, this.updatedAt);

  return CatalogItem.endCatalogItem(builder);
}
}
