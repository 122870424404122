import type { LazyVersioned, ResultAsync } from "engine-utils-ts";
import { LazyDerivedAsync } from "engine-utils-ts";
import { NumberProperty, type Bim } from "src";
import type { CostsConfigProvider, EstimateCost} from "src/cost-model/capital";
import { CivilTopCategoryName, CostHierarchy, createEmptyCostComponentsNonNullable, fillModelBasedTopLevelCategory } from "src/cost-model/capital";
import { create_Civil_Roads } from "./roads";
import { create_Civil_Earchwork } from "./earthwork";

export function create_CivilSubtotal(
    bim: Bim,
    costs: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>
) : LazyVersioned<ResultAsync<CostHierarchy>> {

    const roads = create_Civil_Roads(bim, costs, totalDC);
    const earthwork = create_Civil_Earchwork(bim, costs, totalDC);

    const electricalSubtotal = LazyDerivedAsync.new4<
        CostHierarchy,

        CostHierarchy,
        CostHierarchy,

        EstimateCost[],
        NumberProperty
    >(
        'civil',
        [bim.unitsMapper],
        [roads, earthwork, costs.allEstimateCosts, totalDC],
        function* ([roads, earthwork, estimates, totalDC]) {
            const hierarchy = new CostHierarchy().merge(roads, earthwork);


            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'Site Clearing' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.018 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'site-clearing-misc',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }


            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'Seeding & Landscaping' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.005 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'seeding-landscaping-misc',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'SWPPP / Dust Control / Fence' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.009 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'swppp-dust-control-fence-misc',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'Civil Construction Water' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.001 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'civil-construction-water',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'Civil General Conditions' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.001 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'civil general conditions',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'Civil Logistics & Misc.' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.001 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'civil logistics & misc.',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            // create root category
            {
                const root = hierarchy.addRoot({ description: { value: CivilTopCategoryName } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.09 });
                hierarchy.categoryWithSceneInstances.add(root[0])
                fillModelBasedTopLevelCategory(
                    root[0],
                    hierarchy,
                    'civil-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
            }

            return hierarchy;
        }
    );

    return electricalSubtotal;
}
