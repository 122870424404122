<script lang="ts">
import { getContext } from 'svelte';
import { NotificationDescription, NotificationType, type UiBindings } from 'ui-bindings';
import ReportTemplateEditor from "./ReportTemplateEditor.svelte";
import { onMount } from "svelte";
import type { KreoEngine } from 'engine-ts';
import FileUpload from '../../FileUpload.svelte';
import { notificationSource } from '../../Notifications';
import { CostReportTemplateLatestVersion, deserializeCostReport, migrateCostReportTemplate, type CostReportTemplate } from '../CostReportTemplate';
import type { CostReport } from '../CostReport';
import { VersionedStore } from '../../VersionedStore';

let uiBindings = getContext<UiBindings>('uiBindings');
let engine = getContext<KreoEngine>('engine');
let costReport = getContext<CostReport>('cost-report');

const templateStore = new VersionedStore(costReport.costReportTemplates.templates)
$: reportTemplates = $templateStore;

let fileUpload: FileUpload;

export let close: () => void;


let selectedTemplate: CostReportTemplate | undefined;

function exitSelectedTemplateMode() {
    selectedTemplate = undefined;
    reportTemplates = reportTemplates.slice();
}
function removeTemplate(template: CostReportTemplate) {
    reportTemplates = reportTemplates.filter(x => x !== template);
    costReport.costReportTemplates.api.deleteTemplate(template);
}
function addNewTemplate(template?: CostReportTemplate) {
    const newTemplate: CostReportTemplate = template ?? {
        name: 'new template',
        visible: false,
        id: Date.now(),
        actions: [],
        projectSpecificOverriders: [],
        version: CostReportTemplateLatestVersion,
    };
    migrateCostReportTemplate(newTemplate);
    costReport.costReportTemplates.templates.getForMutation().push(newTemplate);
}


function saveToRemote() {
    const task = engine.tasksRunner.newLongTask({
        defaultGenerator: costReport.costReportTemplates.saveToRemoteGen()
    });
    uiBindings.addNotification(NotificationDescription.newWithTask({
        source: notificationSource,
        key: 'loadCostReport',
        taskDescription: { task },
        type: NotificationType.Info,
        addToNotificationsLog: true,
    }))
    costReport.costReportTemplates.loadFromRemote_withNotification();
}

function loadFromRemote() {
    costReport.costReportTemplates.loadFromRemote_withNotification()
}

onMount(() => {
    loadFromRemote();
})


function onUploadFile(files: FileList) {
    const file = files.item(0);
    const reader = new FileReader();
    if (!file) {
        return;
    }
    reader.readAsText(file)
    reader.onload = (data) => {
        const result = data.target?.result;
        if (result) {
            const loaded: CostReportTemplate = deserializeCostReport(result.toString());
            loaded.id = Date.now();
            addNewTemplate(loaded)
        }
    }
}
function uploadTemplate() {
    fileUpload.load();
}

</script>

<div class="root">
    {#if selectedTemplate}
        <ReportTemplateEditor
            template={selectedTemplate}
            close={exitSelectedTemplateMode}
        />
    {:else}
        <a class="basic-interact" on:click={close}>{'<'}</a>
        <button class="basic-interact" on:click={saveToRemote}>save to server</button>
        <button class="basic-interact" on:click={loadFromRemote}>load from server</button>
        <button class="basic-interact" on:click={uploadTemplate}>upload template</button>
        <FileUpload bind:this={fileUpload} on:upload={(e) => onUploadFile(e.detail)}/>
        <h3>report templates list(electrical, civil, pcs, ...)</h3>
        {#if !reportTemplates.length}
            <p>empty</p>
        {/if}
        <ul>
            {#each reportTemplates as template}
                <li
                    class="basic-interact"
                    on:click={() => selectedTemplate = template}
                >
                    {template.name}&nbsp;&nbsp;
                    <span
                        class="basic-interact"
                        on:click|stopPropagation={() => removeTemplate(template)}
                    >x</span>
                </li>
            {/each}
        </ul>
        <span class="basic-interact" on:click={() => addNewTemplate()}>+add</span>
    {/if}
</div>

<style lang="scss">
</style>
