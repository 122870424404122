<svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M5.40578 14.2356L1.76447 10.5943V5.39643L5.40578 1.75513H10.6036L14.2449 5.39643V10.5943L10.6036 14.2356H5.40578ZM5.98767 10.7877L7.99998 8.77543L10.0123 10.7877L10.7971 10.0124L8.77535 8.00006L10.7971 5.98774L10.0123 5.20296L7.99998 7.22469L5.98767 5.20296L5.2123 5.98774L7.22462 8.00006L5.2123 10.0124L5.98767 10.7877Z"
        fill="#E72C03"
    />
</svg>
