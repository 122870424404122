<script lang="ts">
import { ButtonComponent, IconButton } from "../libui/button";
import { VersionedStore } from "../VersionedStore";
import EngineUiPanelPositionMenu from "./EngineUiPanelPositionMenu.svelte";
import { getContext } from 'svelte';
import { NavbarContext, PanelPosition } from '../navbar/Navbar';

export let header: string = '';
export let showPositionMenu: boolean = false;

interface PanelContext {
    identifier: string;
    name: string;
    position?: PanelPosition;
    close: (() => void) | undefined;
    move: ((e: MouseEvent) => void) | undefined;
    resize: ((e: MouseEvent) => void) | undefined;
}
const navbar = <NavbarContext>getContext("ui-navbar");
const { name, identifier, close, move, position, resize } = <PanelContext>getContext('panel');

let container: HTMLDivElement;

let calculateResizerBottomOffset = VersionedStore.simplePollingLambda(() => {
    if ([document, container].findIndex(x => !x) != -1) {
        return null;
    }
    const diff =
        container.getBoundingClientRect().bottom -
        document.documentElement.clientHeight;
    return Math.max(0, diff);
}, 200)

$: resizerBottomOffset = $calculateResizerBottomOffset ?? 0;

let scrolled = false;

function handleScroll(e: Event) {
    let scroll = (e.currentTarget as HTMLElement).scrollTop;
    scrolled = scroll > 0;
}

</script>

<div
    bind:this={container}
    class="panel-container mdc-typography text-main-dark"
>
    <div class="header-holder">
        <div
            class="mdc-typography--headline4 header"
            class:dragger={!!move}
            on:pointerdown={move}
        >
            {header || name}
        </div>
        <div class="button-holder">
            <slot name="buttons" />
            {#if showPositionMenu}
                <EngineUiPanelPositionMenu id={identifier} />
            {/if}
            {#if position !== undefined}
                <ButtonComponent
                    desc={new IconButton(
                        $navbar.panels.getOrCreate(position).collapsed ? "ChevronUp" : "ChevronDown",
                        () => navbar.toggleView(position)
                    )}
                />
            {/if}
            {#if close}
                <ButtonComponent desc={new IconButton("Close", close)} />
            {/if}
        </div>
    </div>
    <div
        class="custom-scrollbar panels-constructor-holder"
        style="--bottom-offset:{resize ? 20 : 0}px"
        on:scroll={handleScroll}
    >
        <slot></slot>
    </div>
    <div>
        {#if resize}
            <span
                class="resizer"
                style="bottom: {resizerBottomOffset}px"
                on:pointerdown={resize}
            />
        {/if}
    </div>
    <div class:shadow={scrolled}></div>
</div>

<style lang="scss">
    $headerHeight: 48px;
    .panel-container {
        height: 100%;
        width: 100%;
    }
    .button-holder {
        display: flex;
        gap: 8px;
        padding: 0 16px;
        align-items: center;
        :global(.custom-icon-container) {
            padding: 4px;
        }
        :global(svg) {
            width: 16px;
            height: 16px;
        }
    }
    .header-holder {
        user-select: none;
        display: flex;
        align-items: center;
        height: $headerHeight;
        position: relative;

        .header {
            flex: 1;
            height: 100%;
            padding: 0 16px;
            text-align: center;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .shadow {
        width: 100%;
        position: absolute;
        top: 48px;
        left: 0px;
        border-bottom: 1px solid rgba(21, 27, 31, 0.12);
        box-shadow: 0px -8px 16px 8px rgba(21, 27, 31, 0.12);
        clip-path: inset(0px 0px -10px 0px);
    }
    .dragger {
        user-select: none;
        cursor: grab;
    }
    .resizer {
        user-select: none;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: se-resize;
        visibility: visible;
        z-index: 2;
    }
    .resizer::after {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        right: 3px;
        bottom: 3px;
        border-right: 2px solid var(--color-main-medium);
        border-bottom: 2px solid var(--color-main-medium);
    }
    .panels-constructor-holder {
        height: calc(100% - $headerHeight - var(--bottom-offset));
        margin: 0 2px;
        overflow: auto;
        position:relative;
    }
</style>
