import { PropsGroupBase } from "./Props";
import type { PropsFieldFlags } from './PropsGroupComplexDefaults';
import { PropsGroupsRegistry } from "./PropsGroupsRegistry";


export class _EmptyPropsStub extends PropsGroupBase {

    constructor(args: {}) {
        super();
    }

    cloneWithoutFlags(flags: PropsFieldFlags): this {
        return this;
    }
}

export const EmptyPropsStub = new _EmptyPropsStub({});

PropsGroupsRegistry.register({
    class: _EmptyPropsStub,
    complexDefaults: {},
});
