import type { ObservableObject, RGBAHex} from 'engine-utils-ts';
import type { UiBindings } from 'ui-bindings';
import { PUI_PropertyNodeBool, PUI_PropertyNodeNumber } from 'ui-bindings';

import type { Camera} from './3rdParty/three';
import { OrthographicCamera } from './3rdParty/three';
import { DefaultBackgroundColor } from './Constants';
import { EdgesRenderMode, MeshRenderType } from './EngineConsts';
import { Vector2 } from 'math-ts';


export class GraphicsSettings {

	backgroundColor: RGBAHex = DefaultBackgroundColor as RGBAHex;
	floorGridOpacity: number = 0.3;
	floorGridCellSize: Vector2 = new Vector2(1, 1);
    floorGridCellSizeAuto: boolean = true;

	_floorGridCellSizeMinor: Vector2 = new Vector2(1, 1);


	resolution_scale: number = 1;
	cumulative_antialias: boolean = true;

	zoom_to_cursor: boolean = true;
	horizontal_pivot_mode: boolean = true;

	_stdMeshesRenderType: MeshRenderType = MeshRenderType.Standard;
    _renderTypeOutsideClipbox: MeshRenderType = MeshRenderType.Ghost;
	_inactiveAsGhost: boolean = false;
    _edgesRenderMode: EdgesRenderMode = EdgesRenderMode.Enabled;

    _backgroundColor: number = DefaultBackgroundColor;

    _backgroundAlpha: number = 1;
    _progressiveRender: boolean = true;

	constructor() {
    }

    shouldRenderEdges(camera: Camera): boolean {
        return this._edgesRenderMode == EdgesRenderMode.Enabled
            || this._edgesRenderMode == EdgesRenderMode.Auto && camera instanceof OrthographicCamera;
    }
}


export class GraphicsSettingsUiBindings {
	static addBindings(uiBindings: UiBindings, settings: ObservableObject<GraphicsSettings>) {
        const graphicsSettings = settings.poll();
        uiBindings.addSetting({
            name: ['View', 'Zoom'],
            priority: 7,
			property: new PUI_PropertyNodeBool({
				name: "Zoom to cursor",
                value: graphicsSettings.zoom_to_cursor,
                onChange: (newValue) => {
                    settings.applyPatch({
						patch: { zoom_to_cursor: newValue }
					})
                },
			}),
        });
		uiBindings.addSetting({
            name: ['View', 'Rendering', 'Pivot'],
            priority: 8,
			property: new PUI_PropertyNodeBool({
				name: "Horizontal pivot mode",
                value: graphicsSettings.horizontal_pivot_mode,
                onChange: (newValue) => {
                    settings.applyPatch({
						patch: { horizontal_pivot_mode: newValue }
					})
                },
			}),
        });
		uiBindings.addSetting({
            name: ['View', 'Rendering', 'Cumulative antialias'],
            priority: 9,
			property: new PUI_PropertyNodeBool({
				name: "Cumulative antialias (recomended)",
                value: graphicsSettings.cumulative_antialias,
                onChange: (newValue) => {
                    settings.applyPatch({
						patch: { cumulative_antialias: newValue }
					})
                },
			}),
        });
		uiBindings.addSetting({
            name: ['View', 'Rendering', 'Resolution'],
			priority: 10,
            property: new PUI_PropertyNodeNumber({
				name: "Resolution scale",
                value: graphicsSettings.resolution_scale,
                minMax: [0.5, 2],
				step: 0.05,
                onChange: (newValue) => {
                    settings.applyPatch({
						patch: { resolution_scale: newValue }
					})
                },
			}),
        });
    }
}

