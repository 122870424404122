<script lang="ts">
    import { getContext } from "svelte";
    import type { KreoEngine } from "engine-ts";
    import type { Bim, DC_CNSTS } from "bim-ts";
    import type { UiBindings } from "ui-bindings";
    import { UiPersistentState } from "../../../UiPersistentState";
    import {
        CircuitHub, round, Visualizer, EquipmentGeometry,
        meshPerNodeType, renderConfigPerCable,
    } from "lv-circuit";
    import { VersionedStore } from "../../../VersionedStore";
    import { IconComponent } from '../../../libui/icon';
    import { ButtonComponent, IconButton } from '../../../libui/button';
    import EngineUiPanel from '../../../ui-panels/EngineUiPanel.svelte';

    let bim = getContext<Bim>('bim');
    let engine = getContext<KreoEngine>('engine');
    let uiBindings = getContext<UiBindings>('uiBindings');
    let canvas: HTMLCanvasElement;
    $: visualizer = canvas && new Visualizer(
        canvas,
        engine.tasksRunner,
        uiBindings,
    );
    $: visualizerStore = visualizer && new VersionedStore(visualizer);

    // track parent size by svelte bindings
    let clientHeight: number;
    let clientWidth: number;
    $: if (visualizer)
        visualizer.canvas.setCanvasSize(clientWidth, clientHeight);

    $: if (visualizer && circuit) {
        visualizer.setCircuit(circuit);
    }

    let dcCircuitHub = getContext<CircuitHub>('dcCircuitHub');
    $: dcCircuitHubStore = dcCircuitHub && new VersionedStore(dcCircuitHub);
    $: circuit = $dcCircuitHubStore && $dcCircuitHubStore;


    class UiState {
        showTable: boolean = true;
        tableFullScreen: boolean = false;
        showLegend: boolean = true;
    }
    let uiState = new UiPersistentState<UiState>(
        'wires-router',
        new UiState()
    ).store;

    $: transparentControls = false;
    $: canCalculate = !!(visualizer && dcCircuitHub);
    $: loading = false;

    let errorMsg: string | null = null;
    async function calculate() {
        try {
            loading = true;
            errorMsg = null;
            await dcCircuitHub.calculate(engine.tasksRunner, uiBindings);
        } catch (e) {
            console.error(e);
            errorMsg = e.message;
        } finally {
            loading = false;
        }
    }

    function getColorForCable(type: DC_CNSTS.ConductorType) {
        return renderConfigPerCable[type].color;
    }
</script>


<EngineUiPanel>
<div
    bind:clientWidth={clientWidth}
    bind:clientHeight={clientHeight}
    class="component-root-container"
>
    <!-- Menu with error/info msg and activate button -->
    <div
        class="user-action-panel"
        class:transparent={transparentControls}
    >
        <!-- Error/warning/info message -->
        <div
            style="display: flex; align-items: center;"
            class="mdc-typhography mdc-typography--headline6"
        >
            {#if errorMsg !== null}
                <span class="not-ok-msg">{errorMsg}</span>
            {/if}
        </div>


        <!-- Visible/Invisible container for activate button -->
        <div style="visibility: {canCalculate ? 'visible' : 'hidden'}">

            <!-- Start Calculation button -->
            <button
                disabled={loading}
                on:click={calculate}
                class="mdc-fab mdc-fab--extended"
                class:loading={loading}
            >
                <div class="mdc-fab__ripple"></div>
                <span class="mdc-fab__icon">
                    <svg xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="currentColor"
                    >
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"/>
                    </svg>
                </span>
                <span class="mdc-fab__label">
                    {!loading ? "Update" : "Loading "}
                </span>
            </button>

        </div>
        <!-- End of container for button -->
    </div>
    <!-- End of menu 5 -->



    <!-- Container that holds info about circuit:
        - equipment related legend
        - cable length, coloring explonation -->
    <div
        class="circuit-info-section"
        class:report-opened={$uiState.showTable && circuit}
        class:halfed-table={!$uiState.tableFullScreen}
        class:transparent={transparentControls}
    >
        {#if !$uiState.showTable}
            <button
                on:click={() => $uiState.showTable = true}
                class="density__0 mdc-button"
                style="pointer-events: all"
            >
                <span class="mdc-button__label">Open Report</span>
            </button>
        {/if}

        <!-- Menu with: cable lengths -->
        {#if $uiState.showTable && circuit && visualizerStore && $visualizerStore}
            <div
                class="cable-lengths-section mdc-card"
                class:transparent={false}
            >
                <div class="table-controls">
                    <ButtonComponent desc={new IconButton("Close", () => $uiState.showTable = false)} />
                    <ButtonComponent desc={new IconButton($uiState.tableFullScreen ? "Collapse" : "Expand", () => $uiState.tableFullScreen = !$uiState.tableFullScreen)} />
                </div>


                <!-- HTML Table -->
                <div class="mdc-data-table">
                    <div class="mdc-data-table__table-container custom-scrollbar">
                        <table class="mdc-data-table__table">
                            <thead>
                                <tr class="mdc-data-table__header-row">
                                    <!-- Zero header -->
                                    <th class="mdc-data-table__header-cell" role="columnheader" scope="col">
                                        <span style="visibility: hidden; width: 16px;" />
                                    </th>
                                    <!-- First header -->
                                    <th class="mdc-data-table__header-cell" role="columnheader" scope="col">Cable</th>
                                    <!-- Gauge header -->
                                    <th class="mdc-data-table__header-cell" role="columnheader" scope="col">Gauge</th>
                                    <!-- Pairing header -->
                                    <th class="mdc-data-table__header-cell" role="columnheader" scope="col">Phase</th>
                                    <!-- Each len header -->
                                    <th class="mdc-data-table__header-cell" role="columnheader" scope="col">Length of each</th>
                                    <th class="mdc-data-table__header-cell" role="columnheader" scope="col">Quantity</th>
                                    <th class="mdc-data-table__header-cell" role="columnheader" scope="col">Total Length({bim.unitsMapper.mapToConfigured({ value: 1, unit: 'm' }).unit ?? ''})</th>
                                </tr>
                            </thead>

                            <tbody class="mdc-data-table__content custom-scrollbar">
                                <!-- display cable groups by conductor type -->
                                {#each Array.from(circuit.groupingRoutes.detailsPerCondType) as [condType, descByCondType]}
                                    <tr
                                        class="mdc-data-table__row"
                                        class:hidden-row={$visualizerStore.hidden.has(['cable', condType].join('|'))}
                                    >
                                        <!-- hide placeholer -->
                                        <td
                                            on:click={() => {visualizer.toggleVisibility(['cable', condType])}}
                                            class="mdc-data-table__cell"
                                            style="cursor: pointer"
                                        >
                                            <IconComponent name="VisibilityOn" />
                                        </td>
                                        <!-- Name ceil -->
                                        <td class="mdc-data-table__cell" style="font-weight: bold; color: {getColorForCable(condType)}">{condType}</td>
                                        <!-- gauge placeholer -->
                                        <td class="mdc-data-table__cell"></td>
                                        <!-- phase placeholer -->
                                        <td class="mdc-data-table__cell"></td>
                                        <!-- len each placeholer -->
                                        <td class="mdc-data-table__cell"></td>
                                        <!-- len qty placeholer -->
                                        <td class="mdc-data-table__cell"></td>
                                        <!-- total length -->
                                        <td class="mdc-data-table__cell"><b>{round(bim.unitsMapper.mapToConfigured({ value: descByCondType.totalLength, unit: 'm' }).value, 0.01)}</b></td>
                                    </tr>
                                    {#if !$visualizerStore.hidden.has(['cable', condType].join('|'))}
                                        <!-- display cable groups by gauge -->
                                        {#each Array.from(descByCondType.detailsPerGauge) as [gauge, descByGauge]}
                                            <tr
                                                class="mdc-data-table__row"
                                                class:hidden-row={$visualizerStore.hidden.has(['cable', condType, gauge].join('|'))}
                                            >
                                                <!-- hide placeholer -->
                                                <td
                                                    on:click={() => visualizer.toggleVisibility(['cable', condType, gauge.toString()])}
                                                    class="mdc-data-table__cell"
                                                    style="cursor: pointer"
                                                >
                                                    <IconComponent name="VisibilityOn" />
                                                </td>
                                                <!-- Name ceil -->
                                                <td class="mdc-data-table__cell"></td>
                                                <!-- gauge placeholer -->
                                                <td class="mdc-data-table__cell">{gauge}</td>
                                                <!-- phase placeholer -->
                                                <td class="mdc-data-table__cell"></td>
                                                <!-- len each placeholer -->
                                                <td class="mdc-data-table__cell"></td>
                                                <!-- len qty placeholer -->
                                                <td class="mdc-data-table__cell"></td>
                                                <!-- total length -->
                                                <td class="mdc-data-table__cell">{round(bim.unitsMapper.mapToConfigured({ value: descByGauge.totalLength, unit: 'm' }).value, 0.01)}</td>
                                            </tr>



                                            {#if !$visualizerStore.hidden.has(['cable', condType, gauge].join('|'))}
                                                <!-- display cable groups by each len -->
                                                {#each Array.from(descByGauge.detailsPerPairingType) as [pairingType, descByPairingType]}
                                                <tr
                                                    class="mdc-data-table__row"
                                                    class:hidden-row={$visualizerStore.hidden.has(['cable', condType, gauge, pairingType].join('|'))}
                                                >
                                                        <!-- hide placeholer -->
                                                        <td
                                                            on:click={() => visualizer.toggleVisibility(['cable', condType, gauge + "", pairingType])}
                                                            class="mdc-data-table__cell"
                                                            style="cursor: pointer"
                                                        >
                                                            <IconComponent name="VisibilityOn" />
                                                        </td>
                                                        <!-- Name ceil -->
                                                        <td class="mdc-data-table__cell"></td>
                                                        <!-- gauge placeholer -->
                                                        <td class="mdc-data-table__cell"></td>
                                                        <!-- phase placeholer -->
                                                        <td class="mdc-data-table__cell">{pairingType}</td>
                                                        <!-- len each placeholer -->
                                                        <td class="mdc-data-table__cell"></td>
                                                        <!-- len qty placeholer -->
                                                        <td class="mdc-data-table__cell"></td>
                                                        <!-- total length -->
                                                        <td class="mdc-data-table__cell">{round(bim.unitsMapper.mapToConfigured({ value: descByPairingType.totalLength, unit: 'm' }).value, 0.01)}</td>
                                                    </tr>


                                                    {#if !$visualizerStore.hidden.has(['cable', condType, gauge, pairingType].join('|'))}
                                                        <!-- display cable groups by each len -->
                                                        {#each Array.from(descByPairingType.detailsPerLength.entries()) as [eachLen, descByEachLen]}
                                                        <tr
                                                            class="mdc-data-table__row"
                                                            class:hidden-row={$visualizerStore.hidden.has(['cable', condType, gauge, pairingType, eachLen.toString()].join('|'))}
                                                        >
                                                                <!-- hide placeholer -->
                                                                <td
                                                                    on:click={() => visualizer.toggleVisibility(['cable', condType, gauge + "", pairingType, eachLen.toString()])}
                                                                    class="mdc-data-table__cell"
                                                                    style="cursor: pointer"
                                                                >
                                                                    <IconComponent name="VisibilityOn" />
                                                                </td>
                                                                <!-- Name ceil -->
                                                                <td class="mdc-data-table__cell"></td>
                                                                <!-- gauge placeholer -->
                                                                <td class="mdc-data-table__cell"></td>
                                                                <!-- phase placeholer -->
                                                                <td class="mdc-data-table__cell"></td>
                                                                <!-- len each placeholer -->
                                                                <td class="mdc-data-table__cell">{round(bim.unitsMapper.mapToConfigured({ value: eachLen, unit: 'm' }).value, 0.01)}</td>
                                                                <!-- len qty placeholer -->
                                                                <td class="mdc-data-table__cell">{descByEachLen.routes.length}</td>
                                                                <!-- total length -->
                                                                <td class="mdc-data-table__cell">{round(bim.unitsMapper.mapToConfigured({ value: descByEachLen.totalLength, unit: 'm' }).value, 0.01)}</td>
                                                            </tr>
                                                        {/each}
                                                    {/if}
                                                {/each}
                                            {/if}
                                        {/each}
                                    {/if}
                                {/each}
                            </tbody>

                        </table>

                    </div>
                </div>
                <!-- End of table -->


            </div>
        {/if}
        <!-- End of menu with cable lengths -->

    </div>
    <!-- End of circuit info container -->

    <!-- Menu with equipment/cable legend -->
    <div
        class="
            equip-legend-section
            mdc-typography mdc-typography--body2
        "
        class:transparent={transparentControls}
    >

        <button
            on:click={() => $uiState.showLegend = !$uiState.showLegend}
            class="density__0 mdc-button mdc-button--icon-leading"
            style="
                pointer-events: all;
                margin-bottom: 20px;
                margin-top: 6px;
                color: var(--mdc-theme-text-hint-on-light);
            "
        >
            <IconComponent name={$uiState.showLegend ? 'Close' : 'Info'} />
            <span class="mdc-button__label">Legend</span>
        </button>


        {#if $uiState.showLegend && visualizerStore && $visualizerStore && circuit}
            {#each Object.entries(meshPerNodeType) as [type, mesh]}
                <div
                    on:click={() => visualizer.toggleVisibility(['equipment', type])}
                    class="marker-desc"
                    class:marker-desc-hidden={$visualizerStore.hidden.has(['equipment', type].join('|'))}
                >
                    <div
                        class:circle={mesh.geo.type === EquipmentGeometry.Circle}
                        class:box={mesh.geo.type === EquipmentGeometry.Box}
                        class:triangle={mesh.geo.type === EquipmentGeometry.Triangle}
                        class="marker"
                        style="--color: {mesh.color}"
                    ></div>
                    <div class="marker-explain">{type}</div>
                </div>
            {/each}
            {#each Array.from(circuit.groupingRoutes.detailsPerCondType) as [condType]}
                <div
                    on:click={() => visualizer.toggleVisibility(['cable', condType])}
                    class="marker-desc"
                    class:marker-desc-hidden={$visualizerStore.hidden.has(['cable', condType].join('|'))}
                >
                    <div
                        class="marker line"
                        style="--color: {getColorForCable(condType)}"
                    ></div>
                    <div class="marker-explain">{condType}</div>
                </div>
            {/each}
        {/if}
    </div>
    <!-- end of equipment legend -->

    <!-- Canvas with 2d visualization of circuit -->
    <canvas bind:this={canvas}></canvas>

</div>
</EngineUiPanel>
<!-- End of component -->




<style lang="scss">
    @keyframes rotate-keyframes {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
    }
    .user-action-panel {
        position: absolute;
        right: 10px;
        top: 10px;
        left: 10px;
        display: flex;
        justify-content: space-between;
        .not-ok-msg {
            color: var(--mdc-theme-error);
        }
        button {
            margin-left: 10px;
            &.loading {
                filter: grayscale(1);
                svg {
                    animation:
                        rotate-keyframes 3s
                        cubic-bezier(0.1, -0.6, 0.2, 0)
                        infinite;
                }
            }
        }
    }
    .cable-lengths-section {
        width: 100%;
        height: 100%;
        z-index: 100;
        margin-bottom: 20px;
        .table-controls {
            position: absolute;
            left: -8px;
            bottom: -24px;
            z-index: 10;
            display: flex;
            gap: 16px;
            :global(svg) {
                transform: scale(2);
            }
            :global(.custom-icon-container:first-child) {
                color: var(--mdc-theme-error);
            }
        }
        table {
            :global(svg) {
                color: var(--color-main-medium);
            }
            th,td {
                width: 16%;
                max-width: 16%;
                min-width: 16%;
                text-align: right;
                &:first-child {
                    width: 4%;
                    max-width: 4%;
                    min-width: 4%;
                    text-align: center;
                }
                &:nth-child(2) {
                    text-align: left;
                }
            }
            tbody {
                overflow-y: auto;
                display:block;
                overflow-y:auto;
            }
            thead, tbody tr {
                display:table;
                width: 100%;
            }
            tr {
                &.hidden-row {
                    opacity: 0.4;
                }
            }
        }
    }



    .mdc-data-table {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }
    .component-root-container {
        user-select: none;
        overflow: hidden;
        position: relative;
        background-color: white;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    canvas {
        cursor: grab;
    }
    .circuit-info-section {
        user-select: text;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 10px;
        display: flex;
        margin: 5px;
        align-items: flex-end;

        &.report-opened {
            right: 0;
            top: 40px;
            &.halfed-table {
                top: 50%;
            }
        }
    }
    .equip-legend-section {
        user-select: none;
        position: absolute;
        top: 0;
        left: 0;
        margin: 10px;
        flex-wrap: wrap;
        .marker-desc {

            background: var(--mdc-theme-surface);
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-start;
            padding: 4px;
            padding-bottom: 8px;
            &.marker-desc-hidden {
                opacity: 0.4;
            }
            .marker-explain {
                text-align: center;
                white-space: nowrap;
                margin-right: 10px;
            }
            .marker {
                margin-right: 10px;
                &.line {
                    min-width: 5px;
                    min-height: 20px;
                    max-width: 5px;
                    max-height: 20px;
                    background: var(--color);
                    margin-right: 17.5px;
                    margin-left: 7.5px;
                }
                &.circle {
                    min-width: 20px;
                    min-height: 20px;
                    max-width: 20px;
                    max-height: 20px;
                    background: var(--color);
                    border-radius: 50%

                }
                &.box {
                    min-width: 20px;
                    min-height: 20px;
                    max-width: 20px;
                    max-height: 20px;
                    background: var(--color);
                }
                &.triangle {
                    min-width: 0;
                    min-height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 20px solid var(--color);
                }
            }
        }
    }
    .transparent {
        pointer-events: none;
        opacity: 0.3;
        * {
            pointer-events: none!important;
        }
    }
</style>
