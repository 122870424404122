import { LegacyLogger } from 'engine-utils-ts';

import type { HashedUniforms} from '../composer/DynamicUniforms';
import {
	EmptyHashedUniforms
} from '../composer/DynamicUniforms';
import { RenderJob, RenderJobsPool } from '../composer/RenderJob';
import type { RenderJobsGenerator, RenderListFlags } from '../composer/RenderLists';
import { IntersectionType } from '../geometries/GeometryUtils';
import type { GeometryGpuId } from '../geometries/GpuGeometries';
import type {
	MergedJobs, RenderJobsMerged, RenderJobsMergedPool, RJsMergeSettings,
} from '../geometries/RenderJobsMerged';
import { EngineMaterialId, GetMaterialIDF } from '../pools/EngineMaterialId';
import { SortableVecWasm } from '../pools/SortableVec';
import { ShaderFlags } from '../shaders/ShaderFlags';
import type { EnumsGateway } from '../structs/EnumsGateway';
import type { CulledIndices} from './BoundsSceneWrap';
import { DepthPrecision } from './BoundsSceneWrap';
import type { Submeshes2, SubmeshRenderJob } from './Submeshes2';
import { SubmeshInstancesInfoRaw, SubmeshRawDataSize } from './SubmeshesInstancingRawBlocks';
import { ObjectMaxBatchCount } from '../shaders/shaders_chunks';

export class OpaqueSubmeshesRenderJobsGenerator implements RenderJobsGenerator {

	readonly renderListFlag: RenderListFlags;

	_enabled: boolean = true;
	readonly _submeshes: Submeshes2;
	readonly _renderJobs: SubmeshRenderJob[];

	constructor(submeshes: Submeshes2, renderJobs: SubmeshRenderJob[], renderList: RenderListFlags) {
		this._submeshes = submeshes;
		this._renderJobs = renderJobs;
		this.renderListFlag = renderList;
	}

	_version: number = 1;
	version(): number {
		return this._version;
	}
	markDirty() {
		this._version += 1;
	}
	toggleEnabled(enabled: boolean): void {
		if (this._enabled != enabled) {
			this._enabled = enabled;
			this.markDirty();
		}
	}
	isEnabled(): boolean {
		return this._enabled;
	}

	renderListFlags(): EnumsGateway<RenderListFlags, any> {
		return this._submeshes.renderListsIdents;
	}

	getRenderJobsOf(
		indices: CulledIndices,
		mergedRjsPool: RenderJobsMergedPool,
		mergeSettings: RJsMergeSettings,
	): MergedJobs {

		const clipboxPositions = this._submeshes.scene.clipboxPositions();
		const stdRenderJobs = this._renderJobs;
		const rjsPool = RenderJobsPool.getTemporary();

		const renderJobsSortable: SortableVecWasm<RenderJob> = new SortableVecWasm(indices.len(), RenderJob.hash)
		indices.iter(DepthPrecision.Low, (index, lod, depth) => {
			const rj = stdRenderJobs[index];
			if (!rj) {
				LegacyLogger.deferredWarn('renderlist ident flag erroneous', index);
				return;
			}
			const posToClipbox = clipboxPositions[index];
			if (posToClipbox == IntersectionType.Full) {
				renderJobsSortable.add(rjsPool.getWithLod(index, lod, rj), depth);
			} else if (posToClipbox == IntersectionType.Partial) {
				renderJobsSortable.add(rjsPool.getWithFlags(index, lod, rj, ShaderFlags.BOX_CLIPPING), depth);
			}
		})
		
		return mergedRjsPool.mergeJobs(renderJobsSortable, this._submeshes.submeshesInstancingRawBlocks, mergeSettings);
	}
}


export class TransparentSubmeshesRenderJobsGenerator implements RenderJobsGenerator {

	readonly renderListFlag: RenderListFlags;

	_enabled: boolean = true;
	readonly _submeshes: Submeshes2;
	readonly _renderJobs: SubmeshRenderJob[];

	constructor(submeshes: Submeshes2, renderJobs: SubmeshRenderJob[], renderList: RenderListFlags) {
		this._submeshes = submeshes;
		this._renderJobs = renderJobs;
		this.renderListFlag = renderList;
	}

	_version: number = 1;
	version(): number {
		return this._version;
	}
	markDirty() {
		this._version += 1;
	}
	toggleEnabled(enabled: boolean): void {
		if (enabled !== this._enabled) {
			this._enabled = enabled;
			this.markDirty();
		}
	}
	isEnabled(): boolean {
		return this._enabled;
	}

	renderListFlags(): EnumsGateway<RenderListFlags, any> {
		return this._submeshes.renderListsIdents;
	}

	getRenderJobsOf(
		indices: CulledIndices,
		mergedRjsPool: RenderJobsMergedPool,
		mergeSettings: RJsMergeSettings,
	): MergedJobs {
		// const rendTypeOutsideBox = this.submeshes.stdMeshes.settings.poll().renderTypeOutsideClipbox;
		const matIndexdOutside = /*rendTypeOutsideBox === MeshRenderType.Ghost ? SpecMatsIds.Ghost :*/ EngineMaterialId.None;
		const clipboxPositions = this._submeshes.scene.clipboxPositions();
		const stdRenderJobs = this._renderJobs;
		const rjsPool = RenderJobsPool.getTemporary();

		const renderJobsSortable: SortableVecWasm<RenderJob> = new SortableVecWasm(indices.len(), RenderJob.hash)
		indices.iter(DepthPrecision.High, (index, lod, depth) => {
			const rj = stdRenderJobs[index];

			if (!rj) {
				LegacyLogger.deferredWarn('renderlist ident flag erroneous', index);
				return;
			}

			const posToClipbox = clipboxPositions[index];

			if (posToClipbox == IntersectionType.Full) {
				renderJobsSortable.add(rjsPool.getWithLod(index, lod, rj), depth);
			} else if (posToClipbox == IntersectionType.Partial) {
				renderJobsSortable.add(rjsPool.getWithFlags(index, lod, rj, ShaderFlags.BOX_CLIPPING), depth);
				if (matIndexdOutside) {
					const matIdOutside = GetMaterialIDF(matIndexdOutside, ShaderFlags.BOX_CLIP_INSIDE);
					const outsideRj = rjsPool.getWithoutUniforms(index, lod, rj, matIdOutside);
					renderJobsSortable.add(outsideRj, depth);
				}
			} else if (posToClipbox == IntersectionType.Outside && matIndexdOutside) {
				const matIdOutside = GetMaterialIDF(matIndexdOutside, ShaderFlags.None);
				const outsideRj = rjsPool.getWithoutUniforms(index, lod, rj, matIdOutside);
				renderJobsSortable.add(outsideRj, depth);
			}
		});
		
		return mergedRjsPool.mergeJobs(renderJobsSortable, this._submeshes.submeshesInstancingRawBlocks, mergeSettings);
	}

}

export class SimpleSubmeshesRenderJobsGenerator implements RenderJobsGenerator {

	renderListFlag: RenderListFlags;

	submeshes: Submeshes2;
	_matId: EngineMaterialId;
	_depthPrecision: DepthPrecision;
	_enabled: boolean = true;

	constructor(
		submeshes: Submeshes2,
		renderList: RenderListFlags,
		matId: EngineMaterialId,
		depthSortPrecision: DepthPrecision
	) {
		this.submeshes = submeshes;
		this.renderListFlag = renderList;
		this._depthPrecision = depthSortPrecision;
		this._matId = matId;
	}

	_version: number = 1;
	version(): number {
		return this._version;
	}
	markDirty() {
		this._version += 1;
	}
	toggleEnabled(enabled: boolean): void {
		if (enabled !== this._enabled) {
			this._enabled = enabled;
			this.markDirty();
		}
	}
	isEnabled(): boolean {
		return this._enabled;
	}

	renderListFlags(): EnumsGateway<RenderListFlags, any> {
		return this.submeshes.renderListsIdents;
	}

	getRenderJobsOf(
		indices: CulledIndices,
		mergedRjsPool: RenderJobsMergedPool,
		mergeSettings: RJsMergeSettings,
	): MergedJobs {

		const geoGpus = this.submeshes.geomsGpuHandles.buffer;
		const rawData = this.submeshes.submeshesInstancingRawBlocks;
		const offsets = rawData.instancingBufferRef;

		const resultMergedJobs: RenderJobsMerged[] = [];

		let prevGeoId: GeometryGpuId = 0xFFFFFFFF;
		let prevJobsMerge: RenderJobsMerged | null = null;

		const matId = GetMaterialIDF(this._matId, 0);

		indices.iter(this._depthPrecision, (index, lod, depth) => {
			const geoId = geoGpus[index];

			let uniforms: HashedUniforms;
			// const geoShaderInfo = geometries[index]?.asGpuRepr().shaderInfo;
			// let flags: ShaderFlags;
			// if (geoShaderInfo) {
			// 	flags = geoShaderInfo.flags;
			// 	uniforms = HashedUniforms.fromFlat(geoShaderInfo.uniforms);
			// } else {
			// 	flags = 0;
				uniforms = EmptyHashedUniforms;
			// }

			if (prevGeoId !== geoId || !prevJobsMerge) {
				prevGeoId = geoId;
				prevJobsMerge = mergedRjsPool.get(geoId, lod, matId, uniforms);
				resultMergedJobs.push(prevJobsMerge);
			}

			rawData.getSubmeshInstancesInfoRaw(index, reusedSubmeshInstancesInfo);
			
			while(reusedSubmeshInstancesInfo.instancesCount > 0) {
				let currentMergeInstancesCount = prevJobsMerge!.instance_count();

				if (currentMergeInstancesCount === ObjectMaxBatchCount) {
					prevJobsMerge = mergedRjsPool.get(geoId, lod, matId, uniforms);
					resultMergedJobs.push(prevJobsMerge);
					currentMergeInstancesCount = 0;
				}

				const instancesCountToAddToMerge = Math.min(ObjectMaxBatchCount - currentMergeInstancesCount, reusedSubmeshInstancesInfo.instancesCount);
				const sourceEnd = reusedSubmeshInstancesInfo.instancesOffset + instancesCountToAddToMerge * SubmeshRawDataSize;
				prevJobsMerge!.addInstancesFromRaw(mergedRjsPool.transforms, offsets, reusedSubmeshInstancesInfo.instancesOffset, sourceEnd, mergedRjsPool.worldOrigin);
				reusedSubmeshInstancesInfo.instancesCount -= instancesCountToAddToMerge;
				reusedSubmeshInstancesInfo.instancesOffset = sourceEnd;
			}
		});
		
		return { jobsCount: indices.len(), mergedJobs: resultMergedJobs, transforms: mergedRjsPool.transforms, worldOrigin: mergedRjsPool.worldOrigin };
	}
}

const reusedSubmeshInstancesInfo: SubmeshInstancesInfoRaw = new SubmeshInstancesInfoRaw();

// export class StdShadowMaterialBatch implements SimpleRenderListProvider {
// 	readonly groundShadow: GroundShadowFrustum;
// 	readonly groundShadowBox: KrBox3;
// 	readonly submeshes: Submeshes2;
// 	readonly materialId: MaterialIdFlags;

// 	_version = 1;
// 	_shadowFrustVersion: number = -1;

// 	dirty_to_check: Set<number> = new Set();
// 	all_indices: Set<number> = new Set();
// 	culled: Set<number> = new Set();

// 	indicesCulledSorted: number[] = [];

// 	constructor(groundShadow: GroundShadowFrustum, submeshes: Submeshes2) {
// 		this.groundShadow = groundShadow;
// 		this.groundShadowBox = groundShadow.bounds;
// 		this.materialId = GetMaterialIDF(SpecMatsIds.Depth, 0);
// 		this.submeshes = submeshes;
// 	}

// 	version(): number {
// 		this._updateCullIfDirty();
// 		return this._version + this.groundShadow.frustum._version;
// 	}


// 	markMoved(submesh: index | SubmeshHandle) {
// 		const index = HandleIndexMask & submesh;
// 		if (this.all_indices.has(index)) {
// 			this.dirty_to_check.add(index);
// 		}
// 	}

// 	add(submesh: index | SubmeshHandle) {
// 		const index = HandleIndexMask & submesh;
// 		if (!this.all_indices.has(index)) {
// 			this.all_indices.add(index);
// 			this.dirty_to_check.add(index);
// 		}
// 	}

// 	remove(submesh: index | SubmeshHandle) {
// 		const index = HandleIndexMask & submesh;
// 		if (this.all_indices.delete(index)) {
// 			if (this.culled.delete(index)) {
// 				this._version += 1;
// 			}
// 		}
// 	}

// 	_updateCullIfDirty() {
// 		const vBefore = this._version;
// 		if (this._shadowFrustVersion !== this.groundShadow.frustum._version) {
// 			// cull all
// 			if (this._updateCulling(this.all_indices)) {
// 				this._version += 1;
// 			}
// 			this._shadowFrustVersion = this.groundShadow.frustum._version;
// 			// this.submeshes.scene.update({ frustums: [this.groundShadow.frustum] });
// 		} else if (this.dirty_to_check.size) {
// 			if (this._updateCulling(this.dirty_to_check)) {
// 				this._version += 1;
// 			}
// 		}
// 		this.dirty_to_check.clear();

// 		if (vBefore != this._version) {
// 			this.indicesCulledSorted.length = 0;
// 			for (const sInd of this.culled) {
// 				this.indicesCulledSorted.push(sInd);
// 			}
// 			const geometries = this.submeshes.geomsGpuHandles;
// 			this.indicesCulledSorted.sort((i1, i2) => {
// 				const geo1 = geometries.buffer[i1];
// 				const geo2 = geometries.buffer[i2];
// 				return geo1 - geo2;
// 			})
// 		}
// 	}

// 	produceRenderList(fr: FrustumExt, resultList: RenderJobsMerged[], rjsPool: RenderJobsMergedPool) {
// 		LegacyLogger.debugAssert(fr === this.groundShadow.frustum, 'shadow frust check');
// 		this._updateCullIfDirty();
// 		const geometries = this.submeshes.geomsGpuHandles.buffer;
// 		const offsets = this.submeshes.worldMatrices.buffer;
// 		let prevGeoId: GeometryGpuId = 0xFFFFFFFF;
// 		let prevJobsMerge: RenderJobsMerged | null = null;
// 		for (const sInd of this.indicesCulledSorted) {
// 			const geoId = geometries[sInd];
// 			if (prevGeoId !== geoId || prevJobsMerge?.instance_count() == UboInstancesCount) {
// 				prevGeoId = geoId;
// 				prevJobsMerge = rjsPool.get(geoId, this.materialId, EmptyHashedUniforms);
// 				resultList.push(prevJobsMerge);
// 			}
// 			prevJobsMerge!.addInstanceFromBin(offsets, sInd * TransformFloats);
// 		}
// 	}

// 	_updateCulling(indices: Set<number>): boolean {
// 		const bounds = this.submeshes.bounds;
// 		const shadowBox = this.groundShadowBox;
// 		// we know that currently shadow box occupies all of the scene, from the bottom
// 		// so the only variable we have to check is box ymin, is it lower than shadow ymax
// 		const shadowMaxZ = shadowBox.maxz();
// 		const shadowMinZ = shadowBox.minz();
// 		let is_dirty = false;
// 		for (const ind of indices) {
// 			const indMinZ = bounds.minZ(ind);
// 			const area = bounds.horArea(ind);
// 			if ((indMinZ - shadowMinZ < area * 15) && indMinZ < shadowMaxZ) { // inside
// 				if (!this.culled.has(ind)) {
// 					this.culled.add(ind);
// 					is_dirty = true;
// 				}
// 			} else {
// 				if (this.culled.delete(ind)) {
// 					is_dirty = true;
// 				}
// 			}
// 		}
// 		return is_dirty;
// 	}

// }

// export const enum BatchDirtyFlags {
// 	None,
// 	Position,
// 	All
// }


