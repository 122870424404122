import { ErrorUtils, FetchUtils, isInNode, LogLevel, ProjectNetworkClient, ScopedLogger } from "engine-utils-ts";
import { isLocalOrigin } from "./urls";

export type MathSolverQueuePriorityType = 'high' | 'low';

export interface MathSolverCommand<TRequest extends Object, TResponse extends Object = Object> {
    solverName: string;
    solverType: "single" | "multi";
    request: TRequest;
    priority?: MathSolverQueuePriorityType; 
    serialize?(request: TRequest): Uint8Array | string;
    deserialize?(response: ArrayBuffer): TResponse;
}

interface MathSolverCommandResponse {
    id: number;
}

interface MathSolverResponseBase {
    id: number;
    status: 'completed' | 'failed' | 'pending' | 'cancelled';
}

interface MathSolverSuccessResponse extends MathSolverResponseBase {
    id: number;
    status: 'completed';
}

interface MathSolverFailedResponse extends MathSolverResponseBase {
    id: number;
    status: 'failed';
    errorMessage: string;
}

interface MathSolverPendingResponse extends MathSolverResponseBase {
    id: number;
    status: 'pending';
}

interface MathSolverCancelledResponse extends MathSolverResponseBase {
    id: number;
    status: 'cancelled';
}

type MathSolverStatusResponse = MathSolverSuccessResponse | MathSolverFailedResponse | MathSolverPendingResponse | MathSolverCancelledResponse;


type BadResponse = {
    detail: Array<{
        loc: Array<string | number>;
        msg: string;
        type: string;
    }>;
};


function isOk(response: BadResponse): boolean {
    return !response.detail;
}

export interface MathSolversApi { 
    callSolver<TRequest extends Object, TResponse extends Object>(command: MathSolverCommand<TRequest, TResponse>): Promise<TResponse>;
    cancelActiveTasks(): void;
}

export class MathSolversApiImpl implements MathSolversApi {

    private readonly _networkClient: ProjectNetworkClient;
    private readonly _timeoutMs: number;
    private readonly _startPeriodMs: number = 1000;
    private readonly _localNetworkClient: ProjectNetworkClient;

    private _deserialize<T extends Object>(buffer: ArrayBuffer): T {
        const str = new TextDecoder().decode(buffer);
        return JSON.parse(str) as T;
    };

    private _serialize<T extends Object>(request: T): string {
        const str = JSON.stringify(request);
        return str;
    }

    private readonly _callFromDocker: boolean;

    private readonly _activeTasks = new Set<number>();
    private _isCancelingInProgress = false;

    readonly logger = new ScopedLogger('MathSolversApi', LogLevel.Info);

    constructor({ 
        baseNetworkClient, 
        projectId, 
        company,
        callFromDocker, 
        timeoutMs = 900000 
    }: { 
        baseNetworkClient: ProjectNetworkClient; 
        projectId: number; 
        company: string;
        callFromDocker?: boolean; 
        timeoutMs?: number; 
    }){
        this._callFromDocker = callFromDocker ?? false;
        const basePath = isLocalOrigin()
            ? `https://app.solar-dev.pvfarm.io/api/solvers/${projectId}` 
            : `api/solvers/${projectId}`;
        this._networkClient = new ProjectNetworkClient({
            ...baseNetworkClient.config,
            basePath,
        });
        
        this._localNetworkClient = new ProjectNetworkClient({
            ...baseNetworkClient.config,
            //Run solvers-api on port 3007 for local development
            basePath: isInNode() 
                ? "" 
                : location.origin.replace(location.port, '3007') + `/solvers/${company}/${projectId}`,
        });

        this._timeoutMs = timeoutMs;
    }

    public async callSolver<TRequest extends Object, TResponse extends Object>(command: MathSolverCommand<TRequest, TResponse>): Promise<TResponse> { 
        if(this._isSolverCallLocalFromDocker()){
            return await this._executeCommandLocal<TRequest, TResponse>(command);
        } else {
            return await this._executeCommand<TRequest, TResponse>(command);
        }
    }

    private _isSolverCallLocalFromDocker(): boolean {
        return isLocalOrigin() && this._callFromDocker;
    }

    public async cancelActiveTasks(){
        if(!this._activeTasks.size || this._isSolverCallLocalFromDocker() || this._isCancelingInProgress){
            return;
        }
        try {
            this._isCancelingInProgress = true;
            const taskIds = Array.from(this._activeTasks);
            const response = await this._networkClient.postJson('cancel', { TaskIds: taskIds });
            if(response.status !== 200){
                this.logger.error('Error cancelling tasks', response);
            } else {
                for (const id of taskIds) {
                    this._activeTasks.delete(id);
                }
            }
        } catch (e) {
            this.logger.error('Error cancelling tasks', e);
        } finally{
            this._isCancelingInProgress = false;
        }
    }

    private async _executeCommandLocal<TRequest extends Object, TResponse extends Object>(command: MathSolverCommand<TRequest, TResponse>) {
        const serialize = command.serialize ? command.serialize : this._serialize<TRequest>;
        const formData = new FormData();
        formData.append(
            "request",
            new Blob([serialize(command.request)]),
        );
        this.logger.debug("url", this._localNetworkClient.config.basePath, "command", command);
        const response = await this._localNetworkClient.postFormData(`${command.solverName}`, formData);
        if(response.status !== 200){
            this.logger.error(`Error calling ${command.solverName}: ${response.statusText}`);
            throw new Error(`Error calling ${command.solverName}: ${response.statusText}`);
        }
        
        const parse = command.deserialize ?? this._deserialize<TResponse>; 
        const bytes = await response.arrayBuffer();
        const parsedData = parse(bytes);

        if(!isOk(parsedData as any)){
            this.logger.error(`Error calling ${command.solverName}:`, parsedData);
            throw new Error(`Error calling ${command.solverName}: ${parsedData}`);
        }
        
        this.logger.debug(`response-${command.solverName}:`, parsedData);

        return parsedData;
    }

    private async _executeCommand<TRequest extends Object, TResponse extends Object>(command: MathSolverCommand<TRequest, TResponse>): Promise<TResponse> { 
        const commandId = await this._sendCommand(command);
        this._activeTasks.add(commandId.id);
        const calcPeriodMs = (attempt: number): number => {
            return Math.min(
                this._startPeriodMs * attempt,
                5_000
            );
        };

        let attemptNumber = 0;
        const startTime = Date.now();
        while(Date.now() - startTime < this._timeoutMs){
            attemptNumber++;
            const response = await this._getCommandStatus(commandId.id);
            if(response.status !== 'pending'){
                this._activeTasks.delete(commandId.id);
            }
            if(response.status === 'failed'){
                throw new Error(response.errorMessage);
            } else if(response.status === 'cancelled') {
                throw new Error('Command cancelled');
            } else if(response.status === 'completed'){
                const response = await this._getResponse(commandId.id, command.deserialize);
                return response;
            } else if(response.status === 'pending'){
                const periodMs = calcPeriodMs(attemptNumber);
                await new Promise(resolve => setTimeout(resolve, periodMs));
            } else {
                ErrorUtils.logThrow('Unknown response status:', response);
            }
        }
        throw new Error(`Timeout calling ${command.solverName}`);
    }


    private async _sendCommand<TRequest extends Object>(command: MathSolverCommand<TRequest>): Promise<MathSolverCommandResponse> {
        const formData = new FormData();
        const serialize = command.serialize ? command.serialize : this._serialize<TRequest>;
        formData.append(
            "request",
            new Blob([serialize(command.request)]),
        );
        let url = `${command.solverType}/${command.solverName}`;
        if(command.priority){
          url = FetchUtils.combineURLs(url, command.priority);  
        }
        const response = await this._networkClient.postFormData(url, formData);
        if(response.status !== 200){
            throw new Error(`Error calling ${command.solverName}: ${response.statusText}`);
        }
        const commandId: MathSolverCommandResponse = await response.json();
        return commandId;
    }

    private async _getCommandStatus(commandId: number): Promise<MathSolverStatusResponse> {
        const response = await this._networkClient.get(commandId.toString());
        if(response.status !== 200){
            throw new Error(`Error calling ${commandId}: ${response.statusText}`);
        }
        const status: MathSolverStatusResponse = await response.json();
        return status;
    }

    private async _getResponse<TResponse extends Object>(commandId: number, deserialize?: (buffer: ArrayBuffer) => TResponse): Promise<TResponse> { 
        const parse = deserialize ?? this._deserialize<TResponse>; 

        const response = await this._networkClient.get(`${commandId}/download`);
        if(response.status !== 200){
            throw new Error(`Error calling ${commandId}: ${response.statusText}`);
        }
        const bytes = await response.arrayBuffer();
        const parsedData = parse(bytes);
        
        this.logger.debug(`response-${commandId}:`, parsedData);
        return parsedData;
    }
}