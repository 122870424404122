import { ScopedLogger, LogLevel, ObservableObject, LazyBasic, LazyDerived } from 'engine-utils-ts';
import { KrMath, Matrix4, Plane, Quaternion, Ray, Transform, Vector3 } from 'math-ts';
import { KeyModifiersFlags } from 'ui-bindings';

import type { Camera } from '../3rdParty/three';
import type { KrCamera } from '../controls/MovementControls';
import { TransformPivotMode, TransformOrientationMode } from '../EngineConsts';
import { MathUtils } from '../MathUtils';
import type { IdEngineObject, InteractiveObjectsActive } from '../scene/InteractiveSceneObjects';
import type { SnappingSettings } from '../SnappingSettings';
import type { RaySection } from '../structs/RaySection';
import type { DeepReadonly } from '../utils/Utils';
import { GizmoIntersection } from './GizmoIntersection';

export interface TransformGizmoSettings {
	// isEnabled: boolean;
	isActive: boolean;
	moveOnlyParents: boolean,
	pivot: TransformPivotMode,
	orientation: TransformOrientationMode,

	_transform: Transform, // current implementation will create patching cycle with ui if transform is public
								// should refactor transform state position handling
}


export class TransformGizmoStateController {
	readonly _logger: ScopedLogger = new ScopedLogger('gizmo_transf', LogLevel.Default);
	readonly state: ObservableObject<TransformGizmoSettings>;

	// readonly state: ObservableObject<TransformGizmoSettings>;

	transformsProvider: InteractiveObjectsActive;

	dragState: LazyBasic<DraggingState | null>;

	gizmoTransform: LazyDerived<Transform | null>;

	snappingSettings: ObservableObject<SnappingSettings>;

	constructor(interactiveObjects: InteractiveObjectsActive, snappingSettings: ObservableObject<SnappingSettings>) {
		this.transformsProvider = interactiveObjects;

		this.state = new ObservableObject<TransformGizmoSettings>({
			identifier: 'gizmo-transform-settings',
			initialState: {
				// isEnabled: true,
				isActive: false,
				moveOnlyParents: true,
				pivot: TransformPivotMode.BBox,
				orientation: TransformOrientationMode.Global,
				_transform: new Transform(),
			},
			throttling: {
				onlyFields: ['_transform'],
			},
		});
		this.snappingSettings = snappingSettings;

		this.state.observeObject({
			settings: {immediateMode: true, doNotNotifyCurrentState: true },
			onPatch: ({ patch, currentValueRef }) => {
				if (patch.isActive != undefined) {
					if (!currentValueRef.isActive) {
						this.stopDrag();
					}
				}
			},
		});

		this.dragState = new LazyBasic<DraggingState | null>('dragState', null);

		const statePositionSettings = LazyDerived.new1('tr-state-without-position', null, [this.state], ([s]) => {
			const r: Partial<TransformGizmoSettings> = {
				orientation: s.orientation,
				pivot: s.pivot,
			};
			return r;
		})

		this.gizmoTransform = LazyDerived.new2(
			'gizmo-transform-result',
			[
				this.transformsProvider.selectionInvalidator,
				this.transformsProvider.repr_invalidator,
				statePositionSettings
			],
			[this.dragState, this.state],
			([ds, state]) => {
				if (!state.isActive) {
					return null;
				}
				if (ds) {
					return this.state.poll()._transform.clone();
				}
				const selected = this.transformsProvider.getSelected();
				const tr = this._calcGizmoPosition(selected);
				this.state.applyPatch({ patch: { _transform: tr } }); // bad, circular dependency, should, fix
				return tr;
			},
		);
	}

	version(): number {
		// return this.dragState.version() + this.state.version();
		return this.dragState.version() + this.gizmoTransform.version() + this.state.version();
	}

	getGizmoPosition(): Transform | null {
		if (!this.isActive()) {
			return null;
		}
		return this.gizmoTransform.poll();
	}

	_calcGizmoPosition(ids: IdEngineObject[]): Transform {
		const s = this.peek();

		let rotation: Matrix4;
		switch (s.orientation) {
			case TransformOrientationMode.Local: { rotation = this.transformsProvider.calcLocalOrientationFor(ids); }; break;
			case TransformOrientationMode.Global: { rotation = new Matrix4()}; break;
			// case TransformOrientationMode.Screen: { rotation = new Matrix4().extractRotation(camera.matrixWorld); }; break;
		}

		let gizmoPosition: Readonly<Vector3>;
		switch (s.pivot) {
			case TransformPivotMode.BBox: {
				const aabb = this.transformsProvider.calcBboxOf(ids);
				const center = aabb.isEmpty() ? Vector3.zero() : aabb.getCenter_t();
				gizmoPosition = center;
			}; break;
			case TransformPivotMode.Origin: { gizmoPosition = this.transformsProvider.calcOriginsCenterOf(ids); }; break;
			case TransformPivotMode.Manual: { gizmoPosition = s._transform.position.clone(); }; break;
		}

		rotation.setPositionV(gizmoPosition);

		const tr = new Transform();
		tr.setFromMatrix4(rotation);
		return tr;
	}

	peek(): DeepReadonly<TransformGizmoSettings> {
		return this.state.poll();
	}

	isActive(): boolean {
		const s = this.state.poll();
		return s.isActive;
	}

	isDraggingGismoItself(): boolean {
		return !this.dragState.poll()?.movementTarget;
	}

	toggleActive(isActive: boolean) {
		this.state.applyPatch({ patch: { isActive } })
	}


	togglePivotMode(mode: TransformPivotMode) {
		if (this.state.poll().pivot !== mode) {
			this.state.applyPatch({ patch: { pivot: mode } });
		}
	}

	startDrag(inters: TransformMoveControlInters, camera: Camera, action: DragAction, worldSpaceDirection: Vector3, keyMods: KeyModifiersFlags): boolean {
		const selected = this.transformsProvider.getSelected();
		if (selected.length == 0 && action != DragAction.MoveGizmoItself) {
			return false;
		}
		const gizmoStartTransform = this.getGizmoPosition();
		if (!gizmoStartTransform) {
			return false;
		}
		const int = constraintsInters(gizmoStartTransform, inters.constraint, inters.initialRay, camera);
		if (!int || !int.pointLS.isFinite()) {
			return false;
		}
		let startTransforms: Map<IdEngineObject, Matrix4> | null = null;
		let childrenStartTransform: Map<IdEngineObject, Matrix4> | null = null;
		switch (action) {
			case DragAction.MoveGizmoItself:
				startTransforms = new Map();
				childrenStartTransform = new Map();
				break;
			case DragAction.MoveObjects:
				startTransforms = this.transformsProvider.getWorldMatricesOf(selected);
				childrenStartTransform = this.transformsProvider.getWorldMatricesOf(this.transformsProvider.getChildrenOf(selected));
				break;
			case DragAction.CloneAndMoveObjects:
				if (inters.constraint.guide instanceof Vector3) {
					worldSpaceDirection = worldSpaceDirection.dot(int.axisWS) > 0
						? int.axisWS.clone()
						: int.axisWS.clone().multiplyScalar(-1);
				}
				const {newObjects, toUseForGesture} = this.transformsProvider.cloneObjects(selected, worldSpaceDirection);
				if (toUseForGesture.length) {
					this.transformsProvider.setSelected(toUseForGesture);
					startTransforms = this.transformsProvider.getWorldMatricesOf(toUseForGesture);
				}
				break;
		}
		const moveOnlyParents = keyMods & KeyModifiersFlags.Alt ? !this.state.poll().moveOnlyParents : this.state.poll().moveOnlyParents;
		if (startTransforms) {
			this.dragState.forceUpdate(new DraggingState(
				gizmoStartTransform,
				int,
				startTransforms,
				inters.constraint,
				startTransforms.size > 0 ? TransformMovementTarget.Objects : TransformMovementTarget.Self,
				childrenStartTransform ?? new Map(),
				moveOnlyParents
			));
			this._logger.debug('started drag', this.dragState.poll());
			return true;
		}
		return true;
	}

	stopDrag() {
		this.dragState.replaceWith(null);
		this._logger.debug('stop drag', this.dragState.poll());
	}

	handleDrag(
		raycaster: RaySection,
		camera: KrCamera,
	): boolean {
		const ds = this.dragState.poll();
		if (ds) {
			return this._handleDrag(raycaster, camera)
		}
		return false;
	}


	_handleDrag(
		raycaster: RaySection,
		camera: KrCamera,
	): boolean {
		const dragState = this.dragState.poll() as DraggingState | null; // make mutable
		if (!dragState) {
			return false;
		}
		this.dragState.forceUpdate(dragState);
		const startInter = dragState.intersStart;

		dragState.intersLast = constraintsInters(dragState.gizmoStartTransform, dragState.constraint, raycaster, camera);
		// const thisTransform = dragState.gizmoStartTransform.clone();

		if (!dragState.intersLast) {
			return false;
		}

		// offsetFromStart.roundTo(snappingDistance);

		const transformsReplacement = new Map<IdEngineObject, Matrix4>();

		const newTransform = dragState.gizmoStartTransform.clone();

		if (dragState.constraint.action == 'rotate') {

			startInter.angle = KrMath.roundTo(startInter.angle, Math.PI / 36);
			dragState.intersLast.angle = KrMath.roundTo(dragState.intersLast.angle, Math.PI / 36);

			let angleStart = startInter.angle;
			let angleEnd = dragState.intersLast.angle;

			const transformMatrix = new Matrix4();
			newTransform.toMatrix4(transformMatrix);
			const transformInverseMatrix = new Matrix4().getInverse(transformMatrix);

			const rotationAxisLocal = startInter.axisLS;
			const rotStart = Quaternion.fromAxisAngle(rotationAxisLocal, angleStart).normalize();
			const rotEnd = Quaternion.fromAxisAngle(rotationAxisLocal, angleEnd).normalize();
			const rotationLocal = rotEnd.clone().inverse().multiply(rotStart);
			const rotationMatrix = new Matrix4().makeRotationFromQuaternion(rotationLocal);

			const resultMatrixMultiply =
				transformInverseMatrix.clone().premultiply(rotationMatrix).premultiply(transformMatrix);

			for (let [id, matrix] of dragState.startMatrices) {
				matrix = matrix.clone();
				matrix.premultiply(resultMatrixMultiply);
				transformsReplacement.set(id, matrix);
			}
			// newTransform.rotation.multiply(rotationLocal);

		} else if (dragState.constraint.action == 'mirror') {

			const transformMatrix = new Matrix4();
			newTransform.toMatrix4(transformMatrix);
			const transformInverseMatrix = new Matrix4().getInverse(transformMatrix);

			const rotationAxisLocal = startInter.axisLS;
			const axis = rotationAxisLocal.maxAbsComponentIndex();
			const newScale = Vector3.allocate(1, 1, 1);
			newScale.setComponent(axis, -1);
			const rotationMatrix = new Matrix4().makeScale(newScale.x, newScale.y, newScale.z);
			const resultMatrixMultiply =
				transformInverseMatrix.clone().premultiply(rotationMatrix).premultiply(transformMatrix);

			for (let [id, tr] of dragState.startMatrices) {
				tr = tr.clone();
				tr.premultiply(resultMatrixMultiply);
				transformsReplacement.set(id, tr);
			}

			newTransform.scale.copy(newScale);

		} else {

			const intersectionsDiff = dragState.intersLast.pointWS.clone().sub(dragState.intersStart.pointWS);
			this._logger.debug('intersection diff', intersectionsDiff);
			newTransform.position.add(intersectionsDiff);

			const idsToSkipSnappingFor = new Set(dragState.startMatrices.keys());

			const p = this.transformsProvider.findPointToSnap(
				newTransform.position,
				idsToSkipSnappingFor,
				camera,
				this.snappingSettings.poll()
			);
			if (p) {
				newTransform.position.copy(p);
			}

			const diff = newTransform.position.clone().sub(dragState.gizmoStartTransform.position);

			for (let [id, tr] of dragState.startMatrices) {
				tr = tr.clone();
				tr.addToPosition(diff);
				transformsReplacement.set(id, tr);
			}

		}

		if (transformsReplacement.size > 0 && dragState.moveOnlyParents) {
			let childrenIds = this.transformsProvider.getChildrenOf(Array.from(transformsReplacement.keys()));
			childrenIds = childrenIds.filter(chId => !transformsReplacement.has(chId));

			const childIdsMatricesToSet = new Map<IdEngineObject, Matrix4>();

			const childrenToAskMatricesFor = [];
			for (const chId of childrenIds) {
				let knownMatrix = dragState.childrenStartTransforms.get(chId);
				if (knownMatrix) {
					childIdsMatricesToSet.set(chId, knownMatrix);
				} else {
					childrenToAskMatricesFor.push(chId);
				}
			}
			for (const [id, wm] of this.transformsProvider.getWorldMatricesOf(childrenToAskMatricesFor)) {
				childIdsMatricesToSet.set(id, wm);
			}

			for (const [chId, wm] of childIdsMatricesToSet) {
				transformsReplacement.set(chId, wm);
			}
		}

		if (dragState.movementTarget === TransformMovementTarget.Objects) {
			this.transformsProvider.patchWorldMatrices(
				transformsReplacement,
				{}
			);
		} else {
			this.togglePivotMode(TransformPivotMode.Manual);
		}
		this.state.applyPatch({ patch: { _transform: newTransform } });
		dragState.moveN += 1;
		return true;
	}
}

export enum TransformMovementTarget {
	Self,
	Objects,
	EditControlPoints,
}

export class DraggingState {
	readonly gizmoStartTransform: Transform;
	readonly intersStart: TransformGizmoInters;
	intersLast: TransformGizmoInters | null = null;

	readonly startMatrices: Map<IdEngineObject, Matrix4>;
	readonly childrenStartTransforms: Map<IdEngineObject, Matrix4>;

	moveN: number = 0;
	readonly constraint: MovementConstraint;
	readonly movementTarget: TransformMovementTarget;
	readonly moveOnlyParents: boolean;

	constructor(
		gizmoStartTransform: Transform,
		gizmoIntersStart: TransformGizmoInters,
		startTransforms: Map<IdEngineObject, Matrix4>,
		constraint: MovementConstraint,
		movementTarget: TransformMovementTarget,
		childrenStartTransforms: Map<IdEngineObject, Matrix4>,
		moveOnlyParents: boolean
	) {
		this.gizmoStartTransform = gizmoStartTransform;
		this.intersStart = gizmoIntersStart;
		this.startMatrices = startTransforms;
		this.constraint = constraint;
		this.movementTarget = movementTarget;
		this.childrenStartTransforms = childrenStartTransforms;
		this.moveOnlyParents = moveOnlyParents;
		// Object.freeze(this)
	}
}

export const enum DragAction {
	MoveGizmoItself,
	MoveObjects,
	CloneAndMoveObjects
	// DuplicateMoving
}

// export class TransformMovementTarget {

// }


export class TransformMoveControlInters extends GizmoIntersection {
	readonly constraint: MovementConstraint;
	readonly initialRay: RaySection;

	constructor(
		point: Vector3,
		distance: number,
		constraint: MovementConstraint,
		initialRay: RaySection
	) {
		super(point, distance);
		this.constraint = constraint;
		this.initialRay = initialRay;
	}

	supportsDragging(): boolean {
		return this.constraint.action != 'mirror';
	}
}

// export enum MovementConstraint {
// 	X,
// 	Y,
// 	Z,
// 	XZ,
// 	ScreenMove,
// 	RotXZ,
// }

export type TransformationAction = 'rotate' | 'move' | 'mirror';
export class MovementConstraint {
	readonly guide: Vector3 | Plane;
	readonly action: TransformationAction;
	readonly isInScreenPlane: boolean;

	constructor(guide: Vector3 | Plane, action: TransformationAction, cameraPlane?: boolean) {
		this.guide = guide;
		this.action = action;
		this.isInScreenPlane = cameraPlane ?? false;

		if (!this.isInScreenPlane) {
			Object.freeze(this);
		}
	}

	isAxisAlignedWith(constraint: MovementConstraint) {
		let thisAxis = this.guide instanceof Plane ? this.guide.normal : this.guide;
		let otherAxis = constraint.guide instanceof Plane ? constraint.guide.normal : constraint.guide;
		return thisAxis.dotAbs(otherAxis) > 0.7;
	}
}


function constraintsInters(tr: Transform, constraint: MovementConstraint, raycaster: RaySection, camera: Camera):
	TransformGizmoInters | null
{

	let pointWS: Vector3 | null = null;
	let intersSourceWS: Plane | Ray;
	let axisLS: Vector3 | null = null;
	let axisWS: Vector3 | null = null;

	if (constraint.guide instanceof Plane) {
		axisLS = constraint.guide.normal.clone();
		axisWS = axisLS.clone().multiply(tr.scale).applyQuaternion(tr.rotation).normalize();
		let normal: Vector3;
		if (constraint.isInScreenPlane) {
			normal = Vector3.zero().setFromMatrixColumn(camera.matrixWorld, 2);
		} else {
			normal = constraint.guide.normal.clone();
			normal.applyQuaternion(tr.rotation);
			normal.multiplyScalar(tr.scale.y);
		}
		intersSourceWS = Plane.allocateZero();
		intersSourceWS.setFromNormalAndCoplanarPoint(normal, tr.position);
		pointWS = raycaster.ray.intersectPlane_t(intersSourceWS, Vector3.zero());
	} else if (constraint.guide instanceof Vector3) {
		axisLS = constraint.guide.clone();
		axisWS = axisLS.clone().multiply(tr.scale).applyQuaternion(tr.rotation).normalize();
		intersSourceWS = new Ray(tr.position, axisWS);
		const closestPoints = MathUtils.closestPointsOnTwoRays(
			intersSourceWS,
			raycaster.ray
		);
		if (closestPoints) {
			pointWS = intersSourceWS.at(-closestPoints.distance1, Vector3.zero());
		}
	}
	if (!pointWS || !axisLS || !axisWS) {
		return null;
	}
	const pointLS = tr.inverseTransformVec(pointWS.clone());
	let angle = 0;
	switch (pointLS.minAbsComponentIndex()) {
		case 0: angle = Math.atan2(pointLS.y, pointLS.z); break;
		case 1: angle = Math.atan2(pointLS.z, pointLS.x); break;
		case 2: angle = Math.atan2(pointLS.x, pointLS.y); break;
	}
	return {
		pointLS,
		pointWS,
		axisLS,
		axisWS,
		angle
	};
}

// class TransformGizmoInters {
// 	pointWS: Vector3;
// 	intersSourceLS: KrPlane | Vector3;

// 	constructor(
// 		worldPoint: Vector3,
// 		intersSourceLS: KrPlane | Vector3
// 	) {
// 		this.pointWS = worldPoint;
// 		this.intersSourceLS = intersSourceLS;
// 	}

// 	worldDiff(rhs: TransformGizmoInters): Vector3 {
// 		return this.pointWS.clone().sub(rhs.pointWS);
// 	}

// 	static angle(transform: KrTransform, pointWS: Vector3): number {
// 		// hardcode for 3 main planes
// 		// const pointLS = transform.inverseTransformVec(pointWS.clone_t());

// 		// return Math.atan2(point.z, point.x);
// 		throw new Error('no impl');
// 	}

// 	static intersectRay(raycaster: KrRaySection, transform: KrTransform, source: KrPlane | KrRay): Vector3 | null {
// 		const ray = raycaster.ray;
// 		if (source instanceof KrPlane) {
// 			return ray.intersectPlane_t(source, Vector3.allocateZero());
// 		} else {
// 			const closestPoints = MathUtils.closestPointsOnTwoRays(
// 				source,
// 				raycaster.ray
// 			);
// 			if (closestPoints) {
// 				return source.at(-closestPoints.distance1, Vector3.allocateZero());
// 			}
// 		}
// 		return null;

// 	}
// }

interface TransformGizmoInters {
	pointWS: Vector3,
	pointLS: Vector3,
	axisLS: Vector3,
	axisWS: Vector3,
	angle: number
}
