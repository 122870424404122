import type { Bim, IdBimScene, PropertiesGroupFormatters, SceneInstance} from "src";
import { NumberProperty, SceneObjDiff } from "src";
import type { LazyVersioned} from "engine-utils-ts";
import { DefaultMap, IterUtils, LazyDerivedAsync, LegacyLogger, ObjectUtils, Yield, preferPreviousOverInProgressWrapper, replaceCurrencyUnitWithSymbol } from "engine-utils-ts";
import { SectionalizingCabinetIdent, SectionalizingCabinetKeyProps } from "src/archetypes/SectionalizingCabinet";
import type { CostComponents, CostComponentsNonNullable, CostsConfigProvider, EstimateCost, InstanceCost, SingleEstimateProvider} from "src/cost-model/capital";
import { CostHierarchy, createEmptyCostComponents, createEmptyCostComponentsNonNullable, createMiscCategory, fillModelBasedCostCategory, fillModelBasedTopLevelCategory } from "src/cost-model/capital";

export function create_ElectricalSubtotal_SectionalizingCabinets(
    bim: Bim,
    provider: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>
) {
    const instances = bim.instances.getLazyListOf({ type_identifier: SectionalizingCabinetIdent, relevantUpdateFlags: SceneObjDiff.NewProps | SceneObjDiff.LegacyProps })
    const grouped = createGroupedInstances(instances, bim.keyPropertiesGroupFormatter);

    const result = LazyDerivedAsync.new4<
        CostHierarchy,
        GroupedSectionalizingCabinets,
        InstanceCost[],
        EstimateCost[],
        NumberProperty
    >(
        'sc',
        [bim.unitsMapper],
        [grouped, provider.allInstanceCosts, provider.allEstimateCosts, totalDC],
        function* ([groupedCables, costs, estimates, totalDC]) {
            const costUnit = bim.unitsMapper.mapToConfigured({ value: 0, unit: 'usd' }).unit!;

            // extract final result
            const hierarchy = new CostHierarchy()

            for (const { ids, name, props } of groupedCables.groupes) {
                let overrides = costs.find(x => ObjectUtils.areObjectsEqual(x.props, props))?.costs
                    ?? createEmptyCostComponents();
                const defaults = getSectionalizingCabinetDefaultCostsPerEach(props);

                const category = hierarchy.add({
                    description: { value: name },
                    costUnit: {
                        options: [replaceCurrencyUnitWithSymbol(costUnit) + '/each' ],
                        index: 0,
                    },
                    quantity: {
                        value: NumberProperty.new({ value: ids.size }),
                        integer: true,
                    },
                    relatedSceneInstanceIds: new Set(ids),
                    matchesSceneInstance: (query) => {
                        if (query.si?.type_identifier !== SectionalizingCabinetIdent) {
                            return false;
                        }
                        const sampleProps = query.si.properties.extractPropertiesGroup(SectionalizingCabinetKeyProps, { valueUnitOnly: true })
                        const result = ObjectUtils.areObjectsEqual(props, sampleProps);
                        return result;
                    }
                })

                const updateCosts = (newCosts: CostComponents) => {
                    provider.findAndUpdateInstanceCost(
                        (prev) => (prev.costs = newCosts, prev),
                        { instance_type: SectionalizingCabinetIdent, props }
                    )
                }
                fillModelBasedCostCategory(category[1], overrides, defaults, updateCosts, ids.size);
            }

            {
                const category = hierarchy.addRoot({ description: { value: 'Sectionalizing cabinet' } })
                hierarchy.sumupChildren(category[0]);
            }

            // create misc cable misc
            {
                const costId = 'sectionalizing-cabinet-misc';
                const overrides = estimates.find(x => x.id.value === costId);
                const state: SingleEstimateProvider = {
                    id: costId,
                    defaults: createEmptyCostComponentsNonNullable(),
                    value: overrides,
                    update: (fn) => provider.findAndUpdateEstimateCost(costId, fn),
                }
                const misc = createMiscCategory(state, bim.unitsMapper, totalDC)
                hierarchy.add(misc);
            }

            // create model based category
            {
                const root = hierarchy.addRoot({ description: { value: 'Electrical Install' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.materialCost = NumberProperty.new({ value: 0.002 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    hierarchy,
                    'sectionalizing-cabinet-benchmark',
                    estimates,
                    provider.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
            }


            return hierarchy
        }
    )
    return result;
}


function createGroupedInstances(
    instances: LazyVersioned<Array<[id: IdBimScene, si: SceneInstance]>>,
    keyPropsGroupFormatter: PropertiesGroupFormatters,
) {
    const result = preferPreviousOverInProgressWrapper(LazyDerivedAsync.new1(
        'sc-grouping',
        [],
        [instances],
        function* ([instances]) {
            type Name = string
            const groups = new DefaultMap<
                Name,
                UniqueSectionalizingCabinetGroup
            >((name) => ({
                name: name,
                props: SectionalizingCabinetKeyProps,
                ids: new Set(),
            }))

            for (const chunk of IterUtils.splitIterIntoChunks(instances, 10e3)) {
                yield Yield.Asap;
                const grouped = IterUtils.groupObjects(
                    chunk,
                    (o) => {
                        const props = o[1].properties.extractPropertiesGroup(SectionalizingCabinetKeyProps);
                        return props.height.as('m') + ';' + props.length.as('m') + ';' + props.width.as('m');
                    },
                    (l, r) => ObjectUtils.areObjectsEqual(
                        l[1].properties.extractPropertiesGroup(SectionalizingCabinetKeyProps, { valueUnitOnly: true }),
                        r[1].properties.extractPropertiesGroup(SectionalizingCabinetKeyProps, { valueUnitOnly: true })
                    ),
                )
                for (const members of grouped) {
                    const sample = members[0][1];
                    const props = sample.properties.extractPropertiesGroup(SectionalizingCabinetKeyProps, { valueUnitOnly: true });
                    const name = keyPropsGroupFormatter.format(SectionalizingCabinetIdent, sample.properties, sample.props)
                    if (!name) {
                        LegacyLogger.warn('Invalid wire group ' + name + '. Skipping');
                        continue;
                    }
                    const group = groups.getOrCreate(name);
                    group.props = props;
                    members.forEach(x => group.ids.add(x[0]));
                }
            }

            const result: GroupedSectionalizingCabinets = { groupes: Array.from(groups.values()) }
            return result;
        }
    ));
    return result;
}


interface UniqueSectionalizingCabinetGroup {
    name: string
    props: typeof SectionalizingCabinetKeyProps
    ids: Set<IdBimScene>
}

interface GroupedSectionalizingCabinets {
    groupes: UniqueSectionalizingCabinetGroup[],
}

export function getSectionalizingCabinetDefaultCostsPerEach(_props: typeof SectionalizingCabinetKeyProps): CostComponentsNonNullable {
    const result: CostComponentsNonNullable = {
        ...createEmptyCostComponentsNonNullable(),
        laborTimeUnits: NumberProperty.new({ value: 6 }),
        laborTimeUnitCost: NumberProperty.new({ value: 50, unit: 'usd' }),
        laborCost: NumberProperty.new({ value: 6*50, unit: 'usd' }),

        materialCost: NumberProperty.new({ value: 500, unit: 'usd' }),
    };

    return result;
}
