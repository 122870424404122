import type { ColumnVO } from "ag-grid-enterprise";
import type { ProjectMetrics, TrackerPilesCollection} from "bim-ts";
import { CostModel, NumberProperty, type Bim, type UnitsMapper } from "bim-ts";
import type { TasksRunner} from "engine-utils-ts";
import { Failure, LazyDerived, VersionedInvalidator } from "engine-utils-ts";
import { createQuantityItemsLazy, type QuantitiesItems } from "../../../quantities-table/QuantityItemsLazy";
import { TableRowValueToPropertyType, type TemplateDescription } from "../../../quantities-table/Models";
import type { CostReport } from "../../CostReport";
import { TableEntry, TableHierarchy } from "../../TableHierarchy";
import { fixRateByRateUnit, remapNumber, type QuantityRemapper } from "../Action";

export const QuantityTemplateImportConfigTypeIdentifier = 'quantity_template';

export class QuantityTemplate {
    constructor(
        public template: TemplateDescription | null = null,
        public displayFromLevelIdx = 0,
        public prefixLevels: string[] = [],
        public quantityColumnId = '',
        public rateColumnId = '',
        /**
         * if exist: cost = quantity / rateUnit * rate
         * if not exist: cost = quantity * rate
         */
        public rateUnitColumnId = '',
        public quantityRemapper: QuantityRemapper | null = null,
        public rateRemapper: QuantityRemapper | null = null,
    ) {}
}

export function buildHierarchyFromQuantityTemplateImportConfig(
    config: QuantityTemplate,
    quantityItems: Readonly<QuantitiesItems>,
    unitsMapper: UnitsMapper,
    order: number,
    groupKeys: string[] = [],
    hierarchy = new TableHierarchy(),
) {
    if (!config.template) {
        return hierarchy;
    }
    const cols = new Map<string, TemplateDescription['state'][number]>();
    for (const col of config.template.state) {
        cols.set(col.colId, col);
    }
    function getColVO(colId: string): ColumnVO {
        const col = cols.get(colId);
        if (!col) {
            throw new Error(`col <${colId}> not found`);
        }
        return {
            aggFunc: typeof col.aggFunc === 'string' ? col.aggFunc : undefined,
            displayName: col.colId,
            id: col.colId,
            field: col.colId
        }
    }
    const {
        template: {
            data: {
                rowGroupCols,
                filterModel,
                valueCols
            }
        }
    } = config;
    const reply = quantityItems.getRowsWithColumns({
        sortModel: [],
        endRow: 5000,
        startRow: 0,
        rowGroupCols: rowGroupCols.map(getColVO),
        pivotCols: [],
        filterModel,
        groupKeys,
        pivotMode: true,
        valueCols: valueCols.map(getColVO),
    })
    const currentGroupKey = rowGroupCols[groupKeys.length];
    if (groupKeys.length + 1 >= rowGroupCols.length) {
        for (const row of reply.params.rowData) {
            let keyProp = row[currentGroupKey];
            if (keyProp === undefined) {
                continue;
            }
            const prop = TableRowValueToPropertyType(keyProp);
            let key: string;
            if (prop instanceof NumberProperty) {
                key = prop.valueUnitUiString(unitsMapper);
            } else {
                key = prop.value.toString();
            }

            const qtyValue = row[config.quantityColumnId];
            let qty = !qtyValue ? undefined : TableRowValueToPropertyType(qtyValue);

            const rateValue = row[config.rateColumnId];
            let rate = !rateValue ? undefined : TableRowValueToPropertyType(rateValue);



            const rateUnitValue = row[config.rateUnitColumnId];
            let rateUnit = !rateUnitValue ? undefined : TableRowValueToPropertyType(rateUnitValue);

            const entry = new TableEntry(
                [...groupKeys, key],
                qty instanceof NumberProperty ? qty : null,
                rate instanceof NumberProperty ? rate : null,
                undefined,
                order,
            )
            hierarchy.addEntry(entry);
            if (entry.quantity && entry.rate) {
                fixRateByRateUnit(
                    entry,
                    unitsMapper,
                    rateUnit instanceof NumberProperty ? rateUnit : undefined,
                )
            }
        }
        return hierarchy;
    }
    for (const row of reply.params.rowData) {
        buildHierarchyFromQuantityTemplateImportConfig(
            config,
            quantityItems,
            unitsMapper,
            order,
            [...groupKeys, row[currentGroupKey]],
            hierarchy,
        );
    }
    return hierarchy;
}


export function registerQuantityTemplateAction(
    costReport: CostReport,
    bim: Bim,
    taskRunner: TasksRunner,
    layoutMetrics: ProjectMetrics,
    trackerPilesCollection: TrackerPilesCollection,
): VersionedInvalidator {
    const quantityItemsLazy = createQuantityItemsLazy(
        "quantity-template",
        bim,
        taskRunner,
        layoutMetrics,
        trackerPilesCollection,
        new CostModel.CostsConfigProvider(bim.configs)
    );
    const lazyQuantitiesItems = LazyDerived.new0(
        'lazyQuantitiesItems',
        [quantityItemsLazy.items],
        () => {
          return quantityItemsLazy.items.poll();
        },
    ).withoutEqCheck();
    costReport.actionSolvers.registerActionSolver<QuantityTemplate>(
        QuantityTemplateImportConfigTypeIdentifier,
        (action, hierarchy, actionIdx) => {
            const quantitiesItems = lazyQuantitiesItems.poll();
            const templateBasicHierarchy = buildHierarchyFromQuantityTemplateImportConfig(
                action.props,
                quantitiesItems,
                bim.unitsMapper,
                actionIdx
            );
            for (const entry of templateBasicHierarchy.getEntriesWithPrefix([])) {
                if (action.props.displayFromLevelIdx ?? 0 > 0) {
                    templateBasicHierarchy.delete(entry.path);
                    entry.path.splice(0, action.props.displayFromLevelIdx);
                    templateBasicHierarchy.addEntry(entry);
                }

                if (action.props.prefixLevels?.length) {
                    templateBasicHierarchy.delete(entry.path);
                    entry.path.splice(0, 0, ...action.props.prefixLevels);
                    templateBasicHierarchy.addEntry(entry);
                }

                if (action.props.quantityRemapper && entry.quantity) {
                    const result = remapNumber(entry.quantity, action.props.quantityRemapper);
                    if (result instanceof Failure) {
                        console.error('can not remap property');
                        return
                    }
                    entry.quantity = result.value;
                }

                if (action.props.rateRemapper && entry.rate) {
                    const result = remapNumber(entry.rate, action.props.rateRemapper);
                    if (result instanceof Failure) {
                        console.error('can not remap property');
                        return
                    }
                    entry.rate = result.value;
                }
            }
            for (const entry of templateBasicHierarchy.getEntriesWithPrefix([])) {
                hierarchy.addEntry(entry)
                hierarchy.leafRelactulation(entry.path);
            }
            return hierarchy
        }
    )
    return new VersionedInvalidator([lazyQuantitiesItems]);
}
