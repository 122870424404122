import type {
    Bim, Config, PropertyBase,
    RegularHeightmapGeometry,
    TerrainAnalysisConfig, TerrainPalette, TerrainPaletteSlice
} from 'bim-ts';
import { SceneObjDiff, TerrainAnalysisTypeIdent, TerrainMetrics, TerrainMetricsRanges,
    TerrainMetricsType, TerrainInstanceTypeIdent,
    TerrainMetricsRegular,
    TerrainDisplaySlopeSelector
} from 'bim-ts';
import type { TerrainDisplayEngineSettings} from 'engine-ts';
import { TerrainDisplayMode } from 'engine-ts';
import { type ResultAsync, type LazyVersioned, convertThrow, convertUnits} from 'engine-utils-ts';
import {
    EnumUtils, LazyDerived, LazyDerivedAsync, Failure, Success, ObjectUtils, ScopedLogger, Immer
} from 'engine-utils-ts';
import { Aabb, KrMath } from 'math-ts';
import { PUI_GroupNode, PUI_PropertyNodeColor, PUI_PropertyNodeNumber, PUI_PropertyNodeString } from 'ui-bindings';

import { patchConfigProperty } from 'layout-service';

export function pickConfigPaletteFromDisplayType(
    config: TerrainAnalysisConfig,
    displayType: TerrainDisplayMode
): [TerrainPalette, string] {
    if (displayType === TerrainDisplayMode.BasicTransparent) {
        return [{slices: []}, "none"];
    } else if (displayType === TerrainDisplayMode.Elevation) {
        return [config.elevation_palette, 'elevation_palette'];
    } else if (displayType === TerrainDisplayMode.Slope) {
        return [config.slope_palette, 'slope_palette'];
    } else if (displayType === TerrainDisplayMode.CutFill) {
        return [config.cut_fill_palette, 'cut_fill_palette'];
    } else {
        console.error('unrecognized palette display type', displayType);
        return [{slices: []}, ""];
    }
}

export function getData(
    bim:Bim, 
    config:Config, 
    displaySettings: TerrainDisplayEngineSettings, 
    metricsResult:ResultAsync<TerrainMetrics>,
    isUpdatingRanges: boolean
): PUI_GroupNode[] {
    const paletteProps = config.get<TerrainAnalysisConfig>();

    const [setting, settingPath] = pickConfigPaletteFromDisplayType(paletteProps, displaySettings.mode);


    function patchProperty<T>(value: PropertyBase, path: string[]){
        const newProps = patchConfigProperty(paletteProps, path, value);
        bim.configs.applyPatchToSingleton(TerrainAnalysisTypeIdent, {properties: newProps});
    }

    function patchRange(newValue: number, position: number, prop: 'min'|'max'){
        const patchedProps = ObjectUtils.deepCloneObj(paletteProps);
        const curState: number[] = [];
        for (const s of setting.slices) {
            curState.push(s.min.value);
        }
        curState.push(setting.slices[setting.slices.length - 1].max.value);
        const ranges = autoRange(newValue, prop === 'min' ? position: position + 1, curState);
        const newProps = Immer.produce(patchedProps, (draft) => {
            const slices = [];
            for (let i = 0; i < setting.slices.length; i++) {
                const [min, max] = ranges[i];
                const slice = setting.slices[i];
                slices.push({
                    min: slice.min.withDifferentValue(min),
                    max: slice.max.withDifferentValue(max),
                    color: slice.color
                });
            }
            if(displaySettings.mode === TerrainDisplayMode.Elevation){
                draft.elevation_palette.slices = slices;
            } else if (displaySettings.mode === TerrainDisplayMode.Slope){
                draft.slope_palette.slices = slices;
            } else if (displaySettings.mode === TerrainDisplayMode.CutFill){
                draft.cut_fill_palette.slices = slices;
            } else {
                console.error(`unexpected type`, displaySettings.mode)
            }
        })

        bim.configs.applyPatchToSingleton(TerrainAnalysisTypeIdent, {properties: newProps});
    }
    const modeResult = EnumUtils.enumStringFromKey(TerrainDisplayMode, displaySettings.mode);
    const mode = modeResult instanceof Success ? " " + modeResult.value.toLowerCase() : "";
    const groups:PUI_GroupNode[] = [];
    const path:string[] = [settingPath, 'slices'];

    for (let i = 0; i < setting.slices.length; i++) {
        const slice = setting.slices[i];
        const sliceId = `${i+1}`;
        const group = new PUI_GroupNode({
            name: sliceId,
            typeSortKeyOverride: i
        });
        group.addMaybeChild(new PUI_PropertyNodeString({
            name: 'id',
            value: `${i+1}`,
            readonly: true,
            typeSortKeyOverride: 1,
            onChange: ()=>{}
        }));
        
        if (isUpdatingRanges) {
            const str = metricsResult instanceof Failure ? 'error' : '...';
            group.addMaybeChild(new PUI_PropertyNodeString({
                name: 'min' + mode,
                value: str,
                readonly: true,
                typeSortKeyOverride: 2,
                onChange: ()=>{}
            }));
            group.addMaybeChild(new PUI_PropertyNodeString({
                name: 'max' + mode,
                value: str,
                readonly: true,
                typeSortKeyOverride: 3,
                onChange: ()=>{}
            }));
        } else {
            const min = bim.unitsMapper.mapToConfigured({
                value: slice.min.value,
                unit: slice.min.unit,
            })
            group.addMaybeChild(new PUI_PropertyNodeNumber({
                name: 'min' + mode,
                value: min.value,
                readonly: slice.min.isReadonly,
                description: slice.min.description,
                unit: min.unit,
                typeSortKeyOverride: 2,
                step: slice.min.step,
                minMax: slice.min.range ?? undefined,
                onChange: (v) => {
                    const result = convertUnits(v, min.unit ?? slice.min.unit, slice.min.unit);
                    if (result instanceof Failure) {
                        return;
                    }
                    patchRange(result.value, i,'min');
                }
            }));
            const max = bim.unitsMapper.mapToConfigured({
                value: slice.max.value,
                unit: slice.max.unit,
            })
            group.addMaybeChild(new PUI_PropertyNodeNumber({
                name: 'max' + mode,
                value: max.value,
                readonly: slice.max.isReadonly,
                description: slice.max.description,
                unit: max.unit,
                typeSortKeyOverride: 3,
                step: slice.max.step,
                minMax: slice.max.range ?? undefined,
                onChange: (v) => {
                    const result = convertUnits(v, max.unit ?? slice.max.unit, slice.max.unit);
                    if (result instanceof Failure) {
                        return;
                    }
                    patchRange(result.value, i,'max');
                }
            }));
        }

        if (metricsResult instanceof Success) {
            group.addMaybeChild(new PUI_PropertyNodeNumber({
                name: 'area',
                value: convertThrow(metricsResult.value.perSliceArea[i] ?? 0, 'm2', 'ac') ,
                unit: 'ac',
                readonly: true,
                typeSortKeyOverride: 4,
                step: 0.01,
                onChange: ()=>{}
            }));
            group.addMaybeChild(new PUI_PropertyNodeNumber({
                name: 'share',
                value: ((metricsResult.value.perSliceArea[i] / metricsResult.value.totalArea) || 0) * 100,
                unit: '%',
                readonly: true,
                typeSortKeyOverride: 5,
                step: 0.01,
                onChange: ()=>{}
            }));

            if (displaySettings.mode === TerrainDisplayMode.CutFill &&
                metricsResult.value.perSliceVolume !== undefined && metricsResult.value.totalVolume !== undefined) {

                group.addMaybeChild(new PUI_PropertyNodeNumber({
                    name: 'volume',
                    value: metricsResult.value.perSliceVolume[i] ?? 0,
                    unit: 'm3',
                    readonly: true,
                    typeSortKeyOverride: 6,
                    step: 0.01,
                    onChange: ()=>{}
                }));
                group.addMaybeChild(new PUI_PropertyNodeNumber({
                    name: 'volume share',
                    value: ((metricsResult.value.perSliceVolume[i] / metricsResult.value.totalVolume) || 0) * 100,
                    unit: '%',
                    readonly: true,
                    typeSortKeyOverride: 7,
                    step: 0.01,
                    onChange: ()=>{}
                }));
            }
        } else {
            const str = metricsResult instanceof Failure ? 'error' : '...';
            group.addMaybeChild(new PUI_PropertyNodeString({
                name: 'area',
                value: str,
                readonly: true,
                typeSortKeyOverride: 4,
                onChange: ()=>{}
            }));
            group.addMaybeChild(new PUI_PropertyNodeString({
                name: 'share',
                value: str,
                readonly: true,
                typeSortKeyOverride: 5,
                onChange: ()=>{}
            }));

            if (displaySettings.mode === TerrainDisplayMode.CutFill) {
                group.addMaybeChild(new PUI_PropertyNodeString({
                    name: 'volume',
                    value: str,
                    readonly: true,
                    typeSortKeyOverride: 6,
                    onChange: ()=>{}
                }));
                group.addMaybeChild(new PUI_PropertyNodeString({
                    name: 'volume share',
                    value: str,
                    readonly: true,
                    typeSortKeyOverride: 7,
                    onChange: ()=>{}
                }));
            }
        }

        group.addMaybeChild(new PUI_PropertyNodeColor({
            name: 'color',
            value: slice.color.value,
            readonly: slice.color.isReadonly,
            description: slice.color.description,
            typeSortKeyOverride: 8,
            onChange: (v)=>{
                patchProperty(slice.color.withDifferentValue(v), [...path, i.toString(), 'color'])
            }
        }));
        groups.push(group);
    }

    return groups;
}

export function getConfig(bim:Bim):TerrainAnalysisConfig{
    const config = bim.configs.peekSingleton(TerrainAnalysisTypeIdent)!;
    const settings = config.get<TerrainAnalysisConfig>();
    return settings;
}

export function createLazyUiRowData(
    bim: Bim, 
    displaySettings: LazyVersioned<TerrainDisplayEngineSettings>,
    isUpdatingRanges: LazyVersioned<boolean>,
): [LazyDerived<PUI_GroupNode[]>, LazyDerivedAsync<TerrainMetrics>] {

    const lazyConfig = bim.configs.getLazySingletonOf({type_identifier: TerrainAnalysisTypeIdent});

    const lazyMetricsPalette: LazyVersioned<TerrainMetricsRanges | undefined> = LazyDerived.new2(
        'metrics-palette-from-config',
        [],
        [lazyConfig, displaySettings],
        ([config, displaySettings]) => {
            const paletteProps = config.get<TerrainAnalysisConfig>();

            let palette: TerrainMetricsRanges;
            let slices: TerrainPaletteSlice[];
            let unit: string;
            if (displaySettings.mode === TerrainDisplayMode.Slope) {
                palette = new TerrainMetricsRanges(TerrainMetricsType.Slope, '%', []);
                slices = paletteProps.slope_palette.slices;
                unit = '%';
            } else if (displaySettings.mode === TerrainDisplayMode.Elevation) {
                palette = new TerrainMetricsRanges(TerrainMetricsType.Elevation, 'm', []);
                slices = paletteProps.elevation_palette.slices;
                unit = 'm';
            } else if (displaySettings.mode === TerrainDisplayMode.CutFill) {
                palette = new TerrainMetricsRanges(TerrainMetricsType.CutFill, 'm', []);
                slices = paletteProps.cut_fill_palette.slices;
                unit = 'm';
            } else if(displaySettings.mode === TerrainDisplayMode.BasicTransparent){
                // skip
                return
            } else {
                console.error('unrecognized display mode', displaySettings.mode);
                return;
            }

            for (let i = 0; i < slices.length; ++i) {
                palette.slices.push(slices[i].min.as(unit));
            }
            palette.slices.push(slices.at(-1)!.max.as(unit));

            return palette;
        }
    );

    const lazyTerrainInBim = bim.instances.getLazyListOf({
        type_identifier: TerrainInstanceTypeIdent,
        relevantUpdateFlags: SceneObjDiff.GeometryReferenced | SceneObjDiff.WorldPosition | SceneObjDiff.Representation
    });

    const lazyMetrics = LazyDerivedAsync.new3(
        'terrain-metrics',
        [],
        [lazyMetricsPalette, lazyTerrainInBim, displaySettings],
        function* ([metricsPalette, bimTerrain, displaySettings]) {
            if(!metricsPalette){
                const defaultPalette = new TerrainMetricsRanges(TerrainMetricsType.Elevation, 'm', []);
                return TerrainMetrics.emptyForPalette(defaultPalette);
            } else if (metricsPalette.type === TerrainMetricsType.Elevation) {
                const metrics = yield* TerrainMetrics.calcuateBimObjsAreaMetricsForElevation(
                    new ScopedLogger('terr-analys-metrics-elevation'),
                    metricsPalette,
                    bim,
                    bimTerrain,
                    displaySettings.terrainVersion,
                );
                return metrics;

            } else if (metricsPalette.type === TerrainMetricsType.Slope) {

                const metrics = yield* TerrainMetrics.calcuateBimObjsAreaMetricsForSlope(
                    new ScopedLogger('terr-analys-metrics-slope'),
                    metricsPalette,
                    bim,
                    bimTerrain,
                    displaySettings.terrainVersion,
                    displaySettings.slopeSelector,
                );
                return metrics;

            } else if (metricsPalette.type === TerrainMetricsType.CutFill) {

                const metrics = yield* TerrainMetrics.calcuateBimObjsMetricsForCutfill(
                    new ScopedLogger('terr-analys-metrics-cutfill'),
                    metricsPalette,
                    bim,
                    bimTerrain
                );

                return metrics;
            }  else {
                console.error('unexpected metrics palette type', metricsPalette.type);
                return TerrainMetrics.emptyForPalette(metricsPalette);
            }
        }
    )

    return [LazyDerived.new4<PUI_GroupNode[], TerrainDisplayEngineSettings, ResultAsync<TerrainMetrics>, Config, boolean>(
        "surface-analysis",
        [],
        [displaySettings, lazyMetrics, lazyConfig, isUpdatingRanges],
        ([terrainMode, metrics, config, isUpdatingRanges]) => {
            return getData(bim, config, terrainMode, metrics, isUpdatingRanges);
        }
    ).withoutEqCheck(), lazyMetrics];
}


export function getTerrainElevationsRange(bim: Bim): [min: number, max: number] {
    const terrainObjs = bim.instances.peekByTypeIdent(TerrainInstanceTypeIdent);

    const totalAabb = Aabb.empty();
    const geometriesAabbs = bim.allBimGeometries.aabbs.poll();
    for (const [_, tObj] of terrainObjs) {
        if (tObj.representation) {
            const aabb = tObj.representation.aabb(geometriesAabbs);
            if (!tObj.worldMatrix.isIdentity()) {
                aabb.applyMatrix4(tObj.worldMatrix);
            }
            totalAabb.union(aabb);
        }
    }
    if (totalAabb.isEmpty()) {
        return [0, 1];
    }
    return [totalAabb.minz(), totalAabb.maxz()];
}

export function getTerrainSlopesRange(regularTiles: RegularHeightmapGeometry[], direction: TerrainDisplaySlopeSelector) {
    let slopesRange = 0;
    if (direction === TerrainDisplaySlopeSelector.EW) {
        for (const tile of regularTiles) {
            slopesRange = Math.max(slopesRange, TerrainMetricsRegular.getMaxEWSlope(tile));
        }
    } else {
        for (const tile of regularTiles) {
            slopesRange = Math.max(slopesRange, TerrainMetricsRegular.getMaxNSSlope(tile));
        }
    }
    return slopesRange;
}

function autoRange(
    newvalue: number,
    position: number,
    curState: number[]
): [number, number][] {
    let min = curState[0];
    let max = curState[curState.length - 1];
    const range = Math.abs((max-min)/(curState.length-1));
    min = min > newvalue ? newvalue - range : min;
    max = max < newvalue ? newvalue + range : max;

    const newState = curState.slice();
    const steps = newState.length - (position + 1);
    for (let i = 0; i < newState.length; i++) {
        if (i > position) {
            if(curState[position+1] === undefined || curState[position+1] > newvalue){
                continue;
            }
            newState[i] = KrMath.lerp(newvalue, max, (i - position) / steps);
        } else if (i < position) {
            if(curState[position-1] === undefined || curState[position-1] < newvalue){
                continue;
            }
            newState[i] = KrMath.lerp(min, newvalue, i / position);
        } else {
            newState[position] = newvalue;
        }
    }

    const slices: [number, number][] = [];
    for (let i = 0; i < newState.length - 1; i++) {
        slices.push([newState[i], newState[i + 1]]);
    }

    return slices;
}

export function isInt(input:string){
    const number = parseInt(input);
    return isFinite(number) && number >= 0;
}