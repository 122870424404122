import type { Vector3 } from 'math-ts';
import { Transform } from 'math-ts';

import type { Bim, BimImage, BimMaterial, IdBimImage, IdBimMaterial } from './';
import { BimCollectionPatch } from './collections/BimCollection';
import type { AnyBimGeometry, IdBimGeo } from './geometries/BimGeometries';
import type { IdBimScene, SceneInstance, SceneInstancePatch } from './scene/SceneInstances';
import type { BimSceneOrigin } from "./scene/BimSceneOrigin";
import type { EventStackFrame} from 'engine-utils-ts';
import { ObjectUtils, ObservableStream } from 'engine-utils-ts';

export class BimPatch {
    readonly instances: BimCollectionPatch<SceneInstance, IdBimScene, SceneInstancePatch>
		= new BimCollectionPatch('instances');
    readonly geometries: BimCollectionPatch<AnyBimGeometry, IdBimGeo, AnyBimGeometry>
		= new BimCollectionPatch('geometries');
    readonly images: BimCollectionPatch<BimImage, IdBimImage>
		= new BimCollectionPatch('images');
    readonly materials: BimCollectionPatch<BimMaterial, IdBimMaterial>
		= new BimCollectionPatch('materials');

    sceneOrigin: Partial<BimSceneOrigin> = {};

	readonly onPatchApplied = new ObservableStream<{patch: BimPatch, bim: Bim}>({
		identifier: 'bim-patch-applied',
	})

    mergeWith(laterPatch: BimPatch) {
        this.instances.mergeWith(laterPatch.instances);
        this.geometries.mergeWith(laterPatch.geometries);
        this.images.mergeWith(laterPatch.images);
        this.materials.mergeWith(laterPatch.materials);
        if (!ObjectUtils.isObjectEmpty(laterPatch.sceneOrigin)) {
            this.sceneOrigin = {...this.sceneOrigin, ...laterPatch.sceneOrigin};
        }

    }

    mergeWithCollectionPatch(collection: BimCollectionPatch<any, any, any>) {
        for (const key in this) {
            const v = this[key];
            if (v instanceof BimCollectionPatch && v.identifier == collection.identifier) {
                v.mergeWith(collection);
                return;
            }
        }
    }

    translateNewSceneObjects(offset: Vector3) {
        for (const [id, s] of this.instances.toAlloc) {
            if (s.spatialParentId) {
                continue;
            }
            if (!s.localTransform) {
                s.localTransform = new Transform();
            }
            s.localTransform.position.add(offset);
        }
    }

	applyTo(bim: Bim, eventParams?: Partial<EventStackFrame>) {
		bim.bimImages.applyCollectionPatch(this.images, eventParams);
		bim.bimMaterials.applyCollectionPatch(this.materials, eventParams);
		bim.allBimGeometries.applyCollectionPatch(this.geometries, eventParams);
        if (this.sceneOrigin !== undefined) {
            bim.instances.patchSceneOrigin(this.sceneOrigin);
        }
		const instanceIds = bim.instances.applyCollectionPatch(this.instances, eventParams).allocated;
		this.onPatchApplied.pushNext({bim, patch: this});
		return instanceIds;
	}

}
