<script lang="ts">
    import { ListComponent } from "../../../libui/list";
    import { getContextMenuSettings } from 'src/libui/context-menu';
    import { ILRInverterGroups } from './ILRInverterGroups';
    import { getContext } from 'svelte';
    import { Bim } from 'bim-ts';
    import { KreoEngine } from 'engine-ts';

    export let value: ILRInverterGroups;

    const bim = getContext<Bim>('bim');
    const engine = getContext<KreoEngine>("engine");
    const contextMenuSettings = getContextMenuSettings();

    $: listInput = value.getUiList(contextMenuSettings, bim, engine);

</script>

<div class="ui-config-property property-row custom-prop">
    <div class="custom-holder-list">
        <ListComponent input={listInput} />
    </div>
</div>

<style lang="scss">
    .custom-holder-list {
        width: 100%;
    }
</style>
