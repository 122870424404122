
import { IterUtils } from 'engine-utils-ts';
import type { Builder } from 'flatbuffers';
import { Aabb2, Transform, Vector2, Vector3 } from 'math-ts';
import { WGSCoord } from '../scene/WGSCoord';

import { Aabb2 as Aabb2F } from '../schema/aabb2';
import { Transform as TransformF } from '../schema/transform';
import { TypeIdentifierVersion } from '../schema/type-identifier-version';
import { TypeIdentifierVersions } from '../schema/type-identifier-versions';
import { Vector2 as Vector2F } from '../schema/vector2';
import { Vector3 as Vector3F } from '../schema/vector3';
import { WgsCoord as WgsCoordF } from '../schema/wgs-coord';
import type { FlatBufferProjectionInfo } from 'src/schema/flat-buffer-projection-info';
import { ProjectionInfo } from '../scene/ProjectionInfo';

export class FlatbufCommon {

    static writeTransform(builder: Builder, tr: Transform): number {
        return TransformF.createTransform(
            builder,
            tr.position.x, tr.position.y, tr.position.z,
            tr.rotation.x, tr.rotation.y, tr.rotation.z, tr.rotation.w,
            tr.scale.x, tr.scale.y, tr.scale.z
        );
    }

    static readTransform(wtr: TransformF): Transform {
        const offset = wtr.bb_pos;
        const tr = new Transform();
        tr.position.x = wtr.bb!.readFloat64(offset + 0 * 8);
        tr.position.y = wtr.bb!.readFloat64(offset + 1 * 8);
        tr.position.z = wtr.bb!.readFloat64(offset + 2 * 8);

        tr.rotation.x = wtr.bb!.readFloat64(offset + 3 * 8);
        tr.rotation.y = wtr.bb!.readFloat64(offset + 4 * 8);
        tr.rotation.z = wtr.bb!.readFloat64(offset + 5 * 8);
        tr.rotation.w = wtr.bb!.readFloat64(offset + 6 * 8);

        tr.scale.x = wtr.bb!.readFloat64(offset + 7 * 8);
        tr.scale.y = wtr.bb!.readFloat64(offset + 8 * 8);
        tr.scale.z = wtr.bb!.readFloat64(offset + 9 * 8);
        return tr;
    }


    static writeAabb2(builder: Builder, aabb2: Aabb2): number {
        return Aabb2F.createAabb2(
            builder,
            aabb2.min.x, aabb2.min.y, aabb2.max.x, aabb2.max.y
        );
    }

    static readAabb2(wtr: Aabb2F): Aabb2 {
        const offset = wtr.bb_pos;
        return new Aabb2(
            new Vector2(wtr.bb!.readFloat64(offset + 0 * 8), wtr.bb!.readFloat64(offset + 1 * 8)),
            new Vector2(wtr.bb!.readFloat64(offset + 2 * 8), wtr.bb!.readFloat64(offset + 3 * 8)),
        );
    }

    static writeVec2(builder: Builder, vec: Vector2): number {
        return Vector2F.createVector2(builder, vec.x, vec.y);
    }


    static readVec2(wtr: Vector2F): Vector2 {
        const offset = wtr.bb_pos;
        return new Vector2(
            wtr.bb!.readFloat64(offset + 0 * 8),
            wtr.bb!.readFloat64(offset + 1 * 8),
        )
    }

    static writeVec3(builder: Builder, vec: Vector3): number {
        return Vector3F.createVector3(builder, vec.x, vec.y, vec.z);
    }


    static readVec3(wtr: Vector3F): Vector3 {
        const offset = wtr.bb_pos;
        return Vector3.allocate(
            wtr.bb!.readFloat64(offset + 0 * 8),
            wtr.bb!.readFloat64(offset + 1 * 8),
            wtr.bb!.readFloat64(offset + 2 * 8),
        )
    }

	static writeWgsCoord(builder: Builder, wgs: WGSCoord): number {
		return WgsCoordF.createWgsCoord(builder, wgs.latitude, wgs.longitude, wgs.altitude ?? 0);
	}
	static readWgsCoord(wgs: WgsCoordF): WGSCoord | null {
		return WGSCoord.new(wgs.lat(), wgs.lon(), wgs.alt());
	}

	static readProjectionInfo(fbpi: FlatBufferProjectionInfo): ProjectionInfo | null {
        const method = fbpi.method();
        if (method) {
            const parameters = new Map<string, string>();
            for (let i = 0; i < fbpi.parametersLength(); ++i) {
                const par = fbpi.parameters(i)!;
                parameters.set(par.key()!, par.value()!);
            }
            return new ProjectionInfo(method, parameters);
        } else {
            return null;
        }
	}

    static writeFloats(builder: Builder, floats: Float32Array | number[]): number {
        builder.startVector(4, floats.length, 4);
        for (let i = floats.length - 1; i >= 0; --i) {
            builder.addFloat32(floats[i]);
        }
        return builder.endVector();
    }
    static writeDoubles(builder: Builder, floats: Float64Array | number[]): number {
        builder.startVector(8, floats.length, 8);
        for (let i = floats.length - 1; i >= 0; --i) {
            builder.addFloat64(floats[i]);
        }
        return builder.endVector();
    }

    static writeInt8s(builder: Builder, ints: Int8Array | Uint8Array): number {
        builder.startVector(1, ints.length, 1);
        for (let i = ints.length - 1; i >= 0; --i) {
            builder.addInt8(ints[i]);
        }
        return builder.endVector();
    }

    static writeInt16Vector(builder: Builder, numbers: Uint16Array | Int16Array | Uint32Array | number[]): number {
        builder.startVector(2, numbers.length, 2);
        for (let i = numbers.length - 1; i >= 0; --i) {
            builder.addInt16(numbers[i]);
        }
        return builder.endVector();
    }

    static writeInt32Vector(builder: Builder, ids: number[] | Uint32Array | Int32Array): number {
        builder.startVector(4, ids.length, 4);
        for (let i = ids.length - 1; i >= 0; --i) {
            builder.addInt32(ids[i]);
        }
        return builder.endVector();
    }

    static writeVec3sAsDoubles(builder: Builder, vecs: Vector3[]): number {
        builder.startVector(8, vecs.length * 3, 8);
        for (let i = vecs.length - 1; i >= 0; --i) {
            const v = vecs[i];
            builder.addFloat64(v.z);
            builder.addFloat64(v.y);
            builder.addFloat64(v.x);
        }
        return builder.endVector();
    }


    static writeVec2ArrAsDoubles(builder: Builder, vecs: Vector2[]): number {
        builder.startVector(8, vecs.length * 2, 8);
        for (let i = vecs.length - 1; i >= 0; --i) {
            const v = vecs[i];
            builder.addFloat64(v.y);
            builder.addFloat64(v.x);
        }
        return builder.endVector();
    }

    static writeTypeIdentsVersions(builder: Builder, typeIdentVersions: Map<string, number>): number {

        return TypeIdentifierVersions.createTypeIdentifierVersions(
            builder,
            TypeIdentifierVersions.createPerTypeVersionVector(
                builder,
                IterUtils.mapIter(
                    typeIdentVersions,
                    ([typeIdent, version]) => TypeIdentifierVersion.createTypeIdentifierVersion(
                        builder,
                        builder.createSharedString(typeIdent),
                        version
                    ),
                ),
            ),
        );
    }

    static readTypeIdentsVersions(versions: TypeIdentifierVersions): Map<string, number> {
        const map = new Map<string, number>();
        for (let i = 0, il = versions.perTypeVersionLength(); i < il; ++i) {
            const type = versions.perTypeVersion(i)!.typeIdentifier()!;
            const version = versions.perTypeVersion(i)!.version();
            map.set(type, version);
        }
        return map;
    }

    // static readTemplateBase(base: EntityTemplateBase): SceneTemplateBase {
    //     let children: SceneTemplateChild[] | null = null;
    //     for (let i = 0, il = base.childrenLength(); i < il; ++i) {
    //         if (!children) {
    //             children = [];
    //         }
    //         const ch = base.children(i)!;
    //         children.push(
    //             new SceneTemplateChild(ch.templateId(), FlatbufCommon.readTransform(ch.localTransform()!))
    //         );
    //     }
    //     return new SceneTemplateBase(base.visibilityMask(), children);
    // }

    // static writeTemplateBase(builder: Builder, base: SceneTemplateBase): number {
    //     return EntityTemplateBase.createEntityTemplateBase(
    //         builder,
    //         base.visibilityMask,
    //         base.children?.length ?
    //             EntityTemplateBase.createChildrenVector(
    //                 builder,
    //                 base.children.map(ch => {
    //                     TemplateChild.startTemplateChild(builder);
    //                     TemplateChild.addTemplateId(builder, ch.templateId);
    //                     TemplateChild.addLocalTransform(builder, FlatbufCommon.writeTransform(builder, ch.transform));
    //                     return TemplateChild.endTemplateChild(builder);
    //                 }),
    //             )
    //             : 0
    //     );
    // }

    // static readSceneEntityBase(base: WireSceneEntityBase): VersionedMeshBase {
    //     return {
    //         templateId: base.templateId(),
    //         localTransform: FlatbufCommon.readTransform(base.localTransform()!),
    //         parentId: base.parentId(),
    //         inParentId: base.inParentId(),
    //     };
    // }

    // static writeSceneEntityBase(builder: Builder, base: VersionedMeshBase): number {
    //     WireSceneEntityBase.startSceneEntityBase(builder);
    //     WireSceneEntityBase.addTemplateId(builder, base.templateId);
    //     WireSceneEntityBase.addLocalTransform(builder, FlatbufCommon.writeTransform(builder, base.localTransform));
    //     WireSceneEntityBase.addParentId(builder, base.parentId);
    //     WireSceneEntityBase.addInParentId(builder, base.inParentId);
    //     return WireSceneEntityBase.endSceneEntityBase(builder);
    //     // return SceneEntityBase.createSceneEntityBase(
    //     //     builder,
    //     //     base.templateId,
    //     //     FlatbufCommon.writeTransform(builder, base.localTransform),
    //     //     base.parentId,
    //     //     base.inParentId
    //     // );
    // }
}
