import { Failure, LazyDerived, type Result } from 'engine-utils-ts';
import { DefaultMapWeak, LruCache } from 'engine-utils-ts';
import type { SolverInstancePatchResult } from '../runtime/ReactiveSolverBase';
import { SolverObjectInstance } from '../runtime/SolverObjectInstance';
import { TrackerProps } from '../trackers/Tracker';
import { KrMath, Vector3 } from 'math-ts';
import type { PlaneOfArrayIrradianceWithWarnings} from './POA_Irradiance';
import { POA_IrradiancePerModulePlane } from './POA_Irradiance';
import { FixedTiltProps } from '../archetypes/fixed-tilt/FixedTilt';
import type { SharedGlobalsInput } from '../runtime/RuntimeGlobals';
import { EnergyFailure, energySucess, type EnergyResult } from './EnergyResult';
import { EnergyCalculationsEnabled } from './EnergyCalculationsEnabled';
import { Matrix4 } from 'math-ts';
import { EnergyYieldPerStringProducer, type StageEnergyConfigPerString, type StringEnergyArgs } from './EnergyYieldPerStringProducer';
import type { Bim } from '..';
import { BimProperty } from '../bimDescriptions/BimProperty';
import { TMY_Props } from '../meteo/TMY_Config';
import { EnergyStageSettingsConfig } from './EnergyStageSettingsConfig';
import { AnyTrackerProps } from '../anyTracker/AnyTracker';



export function registerStringEnergySolvers(bim: Bim) {

    const cachedObjsIds = DefaultMapWeak.newIdsCounter<Object>();

    const stringEnergyPropsProducer = new LruCache<StringEnergyArgs, EnergyYieldPerStringProducer>({
        identifier: 'tracker-ModuleEnergyArgs-cache',
        maxSize: 500,
        factoryFn: (args) => new EnergyYieldPerStringProducer(args),
        hashFn: (args) => {
            let hash = ``;
            for (const [key, v] of Object.entries(args)) {
                let strToAddToHash: string;
                if (v instanceof Object) {
                    strToAddToHash = `${key}:${cachedObjsIds.getOrCreate(v)}|`;
                } else {
                    strToAddToHash = v + '';
                }
                hash += strToAddToHash;
            }
            return hash;
        },
    });

    bim.runtimeGlobals.registerByIdent('strings-energy-stage-config', LazyDerived.new1(
        'strings-energy-stage-config',
        [],
        [bim.configs.getLazySingletonOf({type_identifier: EnergyStageSettingsConfig.name})],
        ([stageSettings]): StageEnergyConfigPerString => {
            const stageEnergyConfig = stageSettings.propsAs(EnergyStageSettingsConfig);
            return {
                tilt: stageEnergyConfig.tilt.calculationConfig(),
                shading: stageEnergyConfig.shading.calculationConfig(),
                bifaciality: stageEnergyConfig.bifaciality.calculationConfig(),
                soiling: stageEnergyConfig.soiling.calculationConfig(),
                incidence_angle: stageEnergyConfig.incidence_angle.calculationConfig(),
                temperature: stageEnergyConfig.temperature.calculationConfig(),
                module_mismatch: stageEnergyConfig.module_mismatch.calculationConfig(),
                module_quality: stageEnergyConfig.module_quality.calculationConfig(),
                spectral_correction: stageEnergyConfig.spectral_correction.calculationConfig(),
            };
        },
    ));

    POA_IrradiancePerModulePlane.registerAsGlobal(bim);

    const globalArgsSelector = {
        [EnergyCalculationsEnabled.name]: EnergyCalculationsEnabled,
        [TMY_Props.name]: TMY_Props,
        ['strings-energy-stage-config']: Object,
        [POA_IrradiancePerModulePlane.name]: POA_IrradiancePerModulePlane,
    };

    interface POA_Inputs {
        meteoData: TMY_Props;
        stageEnergyConfig: StageEnergyConfigPerString;
        irradiance: PlaneOfArrayIrradianceWithWarnings;
    }

    function readPvModuleGlobalArgs(
        globalArgs: SharedGlobalsInput<typeof globalArgsSelector>,
        module_tilt: {module_tilt_range_deg: number, module_tilt_deg: number},
        tracker_tilt: {tracker_slope: number, tracker_azim: number},
    ): EnergyResult<POA_Inputs> {

        const isEnabled = globalArgs[EnergyCalculationsEnabled.name] as Result<EnergyCalculationsEnabled>;
        if (isEnabled instanceof Failure || !isEnabled.value.isEnabled) {
            return EnergyFailure.new('_CalculationsDisabled');
        }

        const meteoDataResult = globalArgs[TMY_Props.name];
        const stageEnergyGlobals = globalArgs['strings-energy-stage-config'];
        const POA_IrradiancePerModulePlaneResult = globalArgs[POA_IrradiancePerModulePlane.name];

        if (meteoDataResult instanceof Failure) {
            return EnergyFailure.new('MeteoDataNotLoaded');
        } else if (stageEnergyGlobals instanceof Failure) {
            return EnergyFailure.new('_EnergyGlobalsNotLoaded');
        } else if (POA_IrradiancePerModulePlaneResult instanceof Failure) {
            return EnergyFailure.new('_EnergyPOANotLoaded');
        }

        const irradianceProducer = (POA_IrradiancePerModulePlaneResult.value as POA_IrradiancePerModulePlane);
        const irradianceResult = irradianceProducer.getPerPlaneIrradianceFor({
            module_tilt_deg: module_tilt.module_tilt_deg,
            module_tilt_range_deg: module_tilt.module_tilt_range_deg,
            tracker_slope: tracker_tilt.tracker_slope,
            tracker_azim: tracker_tilt.tracker_azim,
        });

        if (irradianceResult instanceof Failure) {
            return irradianceResult;
        }

        return energySucess({
            meteoData: meteoDataResult.value as TMY_Props,
            stageEnergyConfig: stageEnergyGlobals.value as StageEnergyConfigPerString,
            irradiance: irradianceResult.value,
        });
    }


    const v_reused_1 = new Vector3();
    const v_reused_2 = new Vector3();
    function extractTrackerSlopeAzimuth(worldMatrix: Matrix4) {

        v_reused_1.setFromMatrixColumn(worldMatrix, 1);
        v_reused_1.z = 0;
        v_reused_1.normalize();
        let azimuth = Math.atan2(v_reused_1.x, v_reused_1.y);

        v_reused_2.setFromMatrixColumn(worldMatrix, 1);
        let slope = v_reused_2.angleTo(v_reused_1);
        v_reused_2.setFromMatrixColumn(worldMatrix, 2);
        if (v_reused_2.dot(v_reused_1) > 0) {
            slope *= -1;
        }

        slope = KrMath.roundTo(slope, KrMath.degToRad(1));
        azimuth = KrMath.roundTo(azimuth, KrMath.degToRad(2));

        return {slope, azimuth};
    }



    const AnyTrackerSolverInput = {
        propsInOut: new AnyTrackerProps({}),
        worldMatrix: new Matrix4(),
    }
    const solverForAnyTrackers = new SolverObjectInstance({
        solverIdentifier: 'any-tracker-per-string-energy',
        objectsDefaultArgs: AnyTrackerSolverInput,
        objectsIdentifier: 'any-tracker',
        globalArgsSelector: globalArgsSelector,
        solverFunction: (inputObj, globalArgs): SolverInstancePatchResult => {

            const {slope, azimuth} = extractTrackerSlopeAzimuth(inputObj.worldMatrix);

            const globalResult = readPvModuleGlobalArgs(
                globalArgs,
                {module_tilt_deg: 0, module_tilt_range_deg: 60},
                {tracker_slope: slope, tracker_azim: azimuth},
            );

            if (globalResult instanceof Failure) {
                inputObj.propsInOut.energy_per_string = null;
                return {};
            }

            const shading = inputObj.propsInOut.shading;
            const shading_factors = shading?.shading_factors?.value ?? null;

            const moduleProps = inputObj.propsInOut.module;
            const stringProps = inputObj.propsInOut.tracker_frame.string;
            const module_area = moduleProps.area();
            const array_height_m = inputObj.propsInOut.modulesArrayHeight();

            const meteoData = globalResult.value.meteoData;
            const Ambient_Temperature = meteoData.getDataColumnAsCompressibleArray('Ambient_Temperature');
            const Wind_Speed = meteoData.getDataColumnAsCompressibleArray('Wind_Speed');
            const Relative_Humidity = meteoData.getDataColumnAsCompressibleArray('Relative_Humidity');

            const energyPropsProducer = stringEnergyPropsProducer.get({
                Ambient_Temperature,
                Wind_Speed,
                Relative_Humidity,
                stageEnergyConfig: globalResult.value.stageEnergyConfig,
                poa_data: globalResult.value.irradiance,
                Impp_STC: moduleProps.current.as('A'),
                Isc_STC: moduleProps.short_circuit_current.as('A'),
                Vmpp_STC: moduleProps.voltage.as('V'),
                Voc_STC: moduleProps.open_circuit_voltage.as('V'),
                VMaxUL_STC: moduleProps.max_system_voltage.as('V'),
                ModuleTechnology: moduleProps.technology.value,
                bifaciality_factor: moduleProps.bifaciality_factor.value,
                Current_Temperature_Coefficients: moduleProps.temp_coeff_current.value,
                Voltage_Temperature_Coefficients: moduleProps.temp_coeff_voltage.value,
                Power_Temperature_Coefficients: moduleProps.temp_coeff_power.value,
                shading_factors,
                module_area,
                string_size: stringProps.modules_count.value,
                array_height_m,
                string_voltage: stringProps.voltage!.as("V"),
                module_power_W: stringProps.power!.as("W"),
            });
                    
            inputObj.propsInOut.energy_per_string = energyPropsProducer;

            return {
                legacyProps: [
                ]
            };
        }
    });
    bim.reactiveRuntimes.registerRuntimeSolver(solverForAnyTrackers);


    const moduleLegacyProps = {
        Imp: BimProperty.NewShared({path: ["module", "current"], value: 0, unit: 'I'}),
        Isc: BimProperty.NewShared({path: ["module", "short_circuit_current"], value: 0, unit: 'I'}),
        Vmp: BimProperty.NewShared({path: ["module", "voltage"], value: 0, unit: 'V'}),
        Voc: BimProperty.NewShared({path: ["module", "open_circuit_voltage"], value: 0, unit: 'V'}),
        VMaxUL: BimProperty.NewShared({path: ["module", "max_system_voltage"], value: 0, unit: 'V'}),
        Technology: BimProperty.NewShared({path: ["module", "technology"], value: ""}),
        temp_coeff_current: BimProperty.NewShared({path: ["module", "temp_coeff_current"], value: 0}),
        temp_coeff_voltage: BimProperty.NewShared({path: ["module", "temp_coeff_voltage"], value: 0}),
        temp_coeff_power: BimProperty.NewShared({path: ["module", "temp_coeff_power"], value: 0}),
        bifaciality_factor: BimProperty.NewShared({path: ["module", "bifaciality_factor"], value: 0}),

        max_efithiency: BimProperty.NewShared({path: ["module", "max_efithiency"], value: 0}),
        cec_efficiency: BimProperty.NewShared({path: ["module", "cec_efficiency"], value: 0}),
        euro_efficiency: BimProperty.NewShared({path: ["module", "euro_efficiency"], value: 0}),

        
        module_size_x: BimProperty.NewShared({path: ["module", "width"], value: 0, unit: 'm'}),
        module_size_y: BimProperty.NewShared({path: ["module", "length"], value: 0, unit: 'm'}),
        module_power: BimProperty.NewShared({path: ["module", "maximum_power"], value: 0, unit: 'W'}),
    };


    const TrackerSolverInput = {
        legacyProps: {
            ...moduleLegacyProps,
            string_size: BimProperty.NewShared({
                path: ["tracker-frame", "string", "modules_count"],
                value: 0,
            }),
            modules_count_y: BimProperty.NewShared({
                path: ["tracker-frame", "string", "modules_count_y"],
                value: 0,
            }),
            modules_gap: BimProperty.NewShared({
                path: ["tracker-frame", "dimensions", "modules_gap"],
                value: 0,
            }),
            string_voltage: BimProperty.NewShared({path: ["tracker-frame", "string", "voltage"], value: 0, unit: 'V'}),
        },
        propsInOut: new TrackerProps({}),
        worldMatrix: new Matrix4(),
    }
    const solverForTrackers = new SolverObjectInstance({
        solverIdentifier: 'tracker-per-string-energy',
        objectsDefaultArgs: TrackerSolverInput,
        objectsIdentifier: 'tracker',
        globalArgsSelector: globalArgsSelector,
        solverFunction: (inputObj, globalArgs): SolverInstancePatchResult => {

            const {slope, azimuth} = extractTrackerSlopeAzimuth(inputObj.worldMatrix);

            const globalResult = readPvModuleGlobalArgs(
                globalArgs,
                {module_tilt_deg: 0, module_tilt_range_deg: 60},
                {tracker_slope: slope, tracker_azim: azimuth},
            );

            if (globalResult instanceof Failure) {
                inputObj.propsInOut.energy_per_string = null;
                return {};
            }

            const shading = inputObj.propsInOut.shading;
            const shading_factors = shading?.shading_factors?.value ?? null;

            const module_area = inputObj.legacyProps.module_size_x.as('m') * inputObj.legacyProps.module_size_y.as('m');
            const modules_gap = inputObj.legacyProps.modules_gap.as('m');
            const array_height_m = inputObj.legacyProps.modules_count_y.value * (inputObj.legacyProps.module_size_y.as('m') + modules_gap) - modules_gap;

            const meteoData = globalResult.value.meteoData;
            const Ambient_Temperature = meteoData.getDataColumnAsCompressibleArray('Ambient_Temperature');
            const Wind_Speed = meteoData.getDataColumnAsCompressibleArray('Wind_Speed');
            const Relative_Humidity = meteoData.getDataColumnAsCompressibleArray('Relative_Humidity');

            const energyPropsProducer = stringEnergyPropsProducer.get({
                Ambient_Temperature,
                Wind_Speed,
                Relative_Humidity,
                stageEnergyConfig: globalResult.value.stageEnergyConfig,
                poa_data: globalResult.value.irradiance,
                Impp_STC: inputObj.legacyProps.Imp.value,
                Isc_STC: inputObj.legacyProps.Isc.value,
                Vmpp_STC: inputObj.legacyProps.Vmp.value,
                Voc_STC: inputObj.legacyProps.Voc.value,
                VMaxUL_STC: inputObj.legacyProps.VMaxUL.value,
                ModuleTechnology: inputObj.legacyProps.Technology.value,
                bifaciality_factor: inputObj.legacyProps.bifaciality_factor.value,
                Current_Temperature_Coefficients: inputObj.legacyProps.temp_coeff_current.value,
                Voltage_Temperature_Coefficients: inputObj.legacyProps.temp_coeff_voltage.value,
                Power_Temperature_Coefficients: inputObj.legacyProps.temp_coeff_power.value,
                shading_factors,
                module_area,
                string_size: inputObj.legacyProps.string_size.value,
                array_height_m,
                string_voltage: inputObj.legacyProps.string_voltage.as("V"),
                module_power_W: inputObj.legacyProps.module_power.as("W"),
            });
                    
            inputObj.propsInOut.energy_per_string = energyPropsProducer

            return {
                legacyProps: [
                    // {path: ['SLOPE'], value: slope, readonly: true, unit: 'rad'},
                    // {path: ['AZIMUTH'], value: azimuth, readonly: true, unit: 'rad'},
                ]
            };
        }
    });
    bim.reactiveRuntimes.registerRuntimeSolver(solverForTrackers);

    function extractFixTiltSlopeAzimuth(worldMatrix: Matrix4) {
        v_reused_1.setFromMatrixColumn(worldMatrix, 0);
        v_reused_1.z = 0;
        v_reused_1.normalize();
        let azimuth = Math.atan2(-v_reused_1.x, v_reused_1.y);

        v_reused_2.setFromMatrixColumn(worldMatrix, 0);
        let slope = v_reused_2.angleTo(v_reused_1);
        v_reused_2.setFromMatrixColumn(worldMatrix, 2);
        if (v_reused_2.dot(v_reused_1) > 0) {
            slope *= -1;
        }

        slope = KrMath.roundTo(slope, KrMath.degToRad(1));
        azimuth = KrMath.roundTo(azimuth, KrMath.degToRad(2));
        return {slope, azimuth};
    }
    const DefaultFixTiltPropsInput = {
        legacyProps: {
            ...moduleLegacyProps,
            tilt: BimProperty.NewShared({path: ["dimensions", "tilt"], value: 0, unit: 'deg'}),
            string_size: BimProperty.NewShared({
                path: ["string", "modules_count"],
                value: 0,
            }),
            modules_count_y: BimProperty.NewShared({
                path: ["modules", "count_y"],
                value: 0,
            }),
            modules_gap: BimProperty.NewShared({
                path: ["dimensions", "modules_gap"],
                value: 0,
            }),

            string_voltage: new BimProperty({path: ["string", "voltage"], value: 0, unit: 'V'}),
        },
        propsInOut: new FixedTiltProps({}),
        worldMatrix: new Matrix4(),
    }

    const solverForFixedTilt = new SolverObjectInstance({
        solverIdentifier: 'fixed-tilt-per-string-energy',
        objectsDefaultArgs: DefaultFixTiltPropsInput,
        objectsIdentifier: 'fixed-tilt',
        globalArgsSelector: globalArgsSelector,
        solverFunction: (inputObj, globalArgs): SolverInstancePatchResult => {

            const {slope, azimuth} = extractFixTiltSlopeAzimuth(inputObj.worldMatrix);

            const globalResult = readPvModuleGlobalArgs(
                globalArgs,
                {module_tilt_deg: inputObj.legacyProps.tilt.as('deg'), module_tilt_range_deg: 0},
                {tracker_slope: slope, tracker_azim: azimuth},
            );

            if (globalResult instanceof Failure) {
                inputObj.propsInOut.energy_per_string = null;
                return {};
            }

            const shading = inputObj.propsInOut.shading;
            const shading_factors = shading?.shading_factors?.value ?? null;

            const module_area = inputObj.legacyProps.module_size_x.as('m') * inputObj.legacyProps.module_size_y.as('m');
            const modules_gap = inputObj.legacyProps.modules_gap.as('m');
            const array_height_m = inputObj.legacyProps.modules_count_y.value * (inputObj.legacyProps.module_size_y.as('m') + modules_gap) - modules_gap;

            const meteoData = globalResult.value.meteoData;
            const Ambient_Temperature = meteoData.getDataColumnAsCompressibleArray('Ambient_Temperature');
            const Wind_Speed = meteoData.getDataColumnAsCompressibleArray('Wind_Speed');
            const Relative_Humidity = meteoData.getDataColumnAsCompressibleArray('Relative_Humidity');

            const energyPropsProducer = stringEnergyPropsProducer.get({
                Ambient_Temperature,
                Wind_Speed,
                Relative_Humidity,
                stageEnergyConfig: globalResult.value.stageEnergyConfig,
                poa_data: globalResult.value.irradiance,
                Impp_STC: inputObj.legacyProps.Imp.value,
                Isc_STC: inputObj.legacyProps.Isc.value,
                Vmpp_STC: inputObj.legacyProps.Vmp.value,
                Voc_STC: inputObj.legacyProps.Voc.value,
                VMaxUL_STC: inputObj.legacyProps.VMaxUL.value,
                ModuleTechnology: inputObj.legacyProps.Technology.value,
                bifaciality_factor: inputObj.legacyProps.bifaciality_factor.value,
                Current_Temperature_Coefficients: inputObj.legacyProps.temp_coeff_current.value,
                Voltage_Temperature_Coefficients: inputObj.legacyProps.temp_coeff_voltage.value,
                Power_Temperature_Coefficients: inputObj.legacyProps.temp_coeff_power.value,
                shading_factors,
                module_area,
                string_size: inputObj.legacyProps.string_size.value,
                array_height_m,
                string_voltage: inputObj.legacyProps.string_voltage.as("V"),
                module_power_W: inputObj.legacyProps.module_power.as("W")
            });
                    
            inputObj.propsInOut.energy_per_string = energyPropsProducer

            return {
                legacyProps: [
                    // {path: ['SLOPE'], value: slope, readonly: true, unit: 'rad'},
                    // {path: ['AZIMUTH'], value: azimuth, readonly: true, unit: 'rad'},
                ]
            };
        }
    });
    bim.reactiveRuntimes.registerRuntimeSolver(solverForFixedTilt);
}
