<script lang="ts">
export let active: boolean = false;
export let onClick: () => void = () => {};
export let disabled: boolean = false;

</script>

<div 
    on:click={() => {
        if (!disabled) {
            onClick();
        }
    }}
    class:clickable = {!disabled}
>
    {#if active}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M8 6.5V15" stroke="#9C27B0" stroke-width="1.6" stroke-linejoin="round"/>
            <path d="M1.5 2.5L8 5L14.5 2.5" stroke="#9C27B0" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    {:else}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M8 6.5V15" stroke="#0F1314" stroke-opacity="0.7" stroke-width="1.6" stroke-linejoin="round"/>
            <path d="M1.5 4H8H14.5" stroke="#0F1314" stroke-opacity="0.7" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    {/if}
</div>

<style lang="scss">
    div {
        display: flex;
        align-items: center;
        padding: 4px;
    }
    .clickable {
        cursor: pointer;
    }
</style>
