import { Vector2 } from 'math-ts';

import { DoubleSide } from '../3rdParty/three';
import type { ShaderBase } from './ShaderBase';

export const BasicAnalyticalShader: Readonly<ShaderBase> = Object.freeze({
	
	name: 'basic-analytical',
	
    uniforms: {
		resolution: { value: new Vector2(100, 100) },
    },

	params: {
        transparent: false,
        side: DoubleSide,
    },
	
	defines: {
		'NEEDS_NORMALS': true,
		'FLAT_NORMALS': true,
		'NEEDS_VIEW_SPACE': true,
	},

	vertexShader:
	`
	#include <kr_std_attributes>
	#include <kr_std_uniforms>
	#include <kr_std_vars>

	#include <common>
	#include <common_math>
	#include <normals_packing>
	
	void main() {
		#include <kr_std_vars_calc>
	}
	`,

	fragmentShader:
	
	`
	#include <kr_std_uniforms>
	#include <kr_std_vars>

	#include <common_math>
	#include <common>
	#include <packing>

	
	#if defined(MRT_NORMALS)
		layout(location = 1) out highp vec4 fragData1;
	#endif

	void main() {
		#include <kr_std_pixel>

		vec3 viewDirection = normalize(vViewPosition);
		vec3 geometryNormal = normal;

		vec3 baseColor = colorTint.rgb;

		float reflPower = (1.0 - dot(viewDirection, geometryNormal));
		vec3 reflColor = mix(baseColor, vec3(0.93, 0.93, 1.0), 0.06);
		vec3 color = mix(baseColor * 0.94, reflColor, reflPower); 

		gl_FragColor = vec4( color, 1.0 );
		#ifdef MRT_NORMALS 
			fragData1 = vec4( packNormalToRGB( normal ), 1.0);
		#endif
		gl_FragColor = vec4( colorTint.rgb, 1.0 );
	}
	`,
});
