<script lang="ts">
import { MDCChipSet } from '@material/chips';
import { onMount } from 'svelte';
import type { ChipsInput } from './types';

export let data: ChipsInput;
let chipSet: HTMLSpanElement;

onMount(() => {
  new MDCChipSet(chipSet);
})

</script>

<span bind:this={chipSet} class="mdc-chip-set" role="grid">
  {#each data.items as item}
    <span class="mdc-chip" role="row">
      <span class="mdc-chip__text">{item}</span>
    </span>
  {/each}
</span>

<style lang="scss">
  .mdc-chip-set {
    flex-wrap: nowrap;
  }
</style>
