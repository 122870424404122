import { PropsGroupsRegistry, type Bim, type SceneInstanceShapeMigration, PropsGroupBase, PropsFieldOneOf, PropsFieldFlags } from "..";
import { BimProperty } from "../bimDescriptions/BimProperty";
import type { PropertiesGroupFormatters } from "../bimDescriptions/PropertiesGroupFormatter";
import { PropertiesGroupFormatter } from "../bimDescriptions/PropertiesGroupFormatter";
import type { AssetBasedCatalogItemCreators } from "../catalog/CatalogItemCollection";
import { create_expandCommonEquipmentPropsWithCostTableLinks, registerEquipmentCommonAssetToCatalogItem } from "./EquipmentCommon";
import { NestedLvWires } from "./LvWire";
import { removeEnergyPropsMigration } from "./transformer/Transformer";

export const SectionalizingCabinetIdent = 'sectionalizing-cabinet';
export const SectionalizingCabinetKeyProps = {
    length: BimProperty.NewShared({
        path: ['dimensions', 'length'],
        value: 0,
        unit: 'm'
    }),
    width: BimProperty.NewShared({
        path: ['dimensions', 'width'],
        value: 0,
        unit: 'm',
    }),
    height: BimProperty.NewShared({
        path: ['dimensions', 'height'],
        value: 0,
        unit: 'm',
    }),
};

export function registerSectionalizingCabinetKeyPropertiesGroupFormatter(
    group: PropertiesGroupFormatters,
) {
    group.register(
        SectionalizingCabinetIdent,
        new PropertiesGroupFormatter(
            SectionalizingCabinetKeyProps,
            (props, unitsMapper) => {
                return [
                    props.length,
                    props.width,
                    props.height,
                ].map(x => x.valueUnitUiString(unitsMapper)).join(' ');
            }
        )
    )
}

export function registerSectionalizingCabinetAssetToCatalogItem(
    group: AssetBasedCatalogItemCreators
) {
    registerEquipmentCommonAssetToCatalogItem(SectionalizingCabinetIdent, group);
}

export class SectionalizingCabinetProps extends PropsGroupBase {
    lv_wiring: NestedLvWires | null;
    constructor(args: Partial<SectionalizingCabinetProps>) {
        super()
        this.lv_wiring = args.lv_wiring ?? null;
    }
}

PropsGroupsRegistry.register({
    class: SectionalizingCabinetProps,
    complexDefaults: {
        lv_wiring: new PropsFieldOneOf(
            PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization,
            null,
            NestedLvWires,
        ),
    }
});


export function registerSectionalizingCabinet(bim: Bim) {
    bim.instances.archetypes.registerArchetype(
		{
			type_identifier: SectionalizingCabinetIdent,
			mandatoryProps: [
                {path: ['dimensions', 'width'], value: 3, unit: 'ft', numeric_range: [0.01, 50]},
                {path: ['dimensions', 'depth'], value: 3, unit: 'ft', numeric_range: [0.01, 50]},
                {path: ['dimensions', 'length'], value: 3, unit: 'ft', numeric_range: [0.01, 50]},
			],
            propsShapeMigrations: migrations(),
            propsClass: SectionalizingCabinetProps,
		}
	);

}

function migrations():SceneInstanceShapeMigration[]{
    return [
        {
			toVersion: 1,
			validation: {
				updatedProps: [],
				deletedProps: [],
			},
			patch: (inst)=>{
                const mv_props = inst.properties.createPatchToRemovePropsStartingWith("circuit | mv_wiring");
                if(mv_props){
                    inst.properties.applyPatch(mv_props);
                }
			}
        },
        removeEnergyPropsMigration(2),
    ]
}

export function create_expandSectionalizingCabinetPropsWithCostTableLinks() {
    return create_expandCommonEquipmentPropsWithCostTableLinks(SectionalizingCabinetKeyProps);
}
