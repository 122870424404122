import type { UnitsMapper } from "bim-ts";
import { PUI_PropertyNodeNumber } from "ui-bindings";

interface GroupPropertyArgs {
    name: string,
    value: [number | null, number | null],
    readonly?: boolean,
    minMax?: [number, number];
    step?: number,
    onChange: (newValue: [number | null, number | null], unit?: string) => void,
    unit?: string,
    unitsMapper: UnitsMapper,
}

type RemappedProperty =  ReturnType<UnitsMapper['getMappedBoundProperty']>;

export class RangeProperty {
    private readonly _min: RemappedProperty;
    private readonly _max: RemappedProperty;
    private _value: [number | null, number | null];
    private _onChangeCallBack: (newValue: [number | null, number | null]) => void;

    readonly name: string;
    readonly readonly: boolean;

    constructor(args: GroupPropertyArgs) {      
        this.name = args.name;
        this._value = args.value;
        this.readonly = !!args.readonly;
        this._min = args.unitsMapper.getMappedBoundProperty(new PUI_PropertyNodeNumber({
            name: args.name,
            value: args.value[0],
            readonly: args.readonly,
            minMax: args.minMax,
            step: args.step,
            unit: args.unit,
            onChange: (newValue) => {
                this.setValue(newValue, 0);
            },
        }));
        this._max = args.unitsMapper.getMappedBoundProperty(new PUI_PropertyNodeNumber({
            name: args.name,
            value: args.value[1],
            readonly: args.readonly,
            minMax: args.minMax,
            step: args.step,
            unit: args.unit,
            onChange: (newValue) => {
                this.setValue(newValue, 1);
            },
        }));
        this._onChangeCallBack = args.onChange;
    }

    get minValue() {
        return this._min.value!;
    }

    set minValue(newValue: number) {
        this._min.value = newValue;
    }

    get maxValue() {
        return this._max.value!;
    }

    set maxValue(newValue: number) {
        this._max.value = newValue;
    }

    private setValue(newValue: number, idx: number){
        const currValue = this._value[idx];
        if (currValue === newValue) {
            return;
        }
        const value = this._value.slice();
        value[idx] = newValue;
        this._value = value as [number, number];
        this._onChangeCallBack(this._value);
    }

    get decimals() {
        return this._min.decimals;
    }

    get step() {
        return this._min.step;
    }

    get minMax() {
       return this._min.minMax;
    }

    get unit() {
        return this._min.unit;
    }

    get sliderSpeed() {
        return this._min.sliderSpeed;
    }
}