import { DefaultMap, findMappedPathFor } from "engine-utils-ts";
import type { Texture} from "../../3rdParty/three";
import { LinearFilter, TextureLoader } from "../../3rdParty/three";
import type { Font } from "../components/Font";
import type { Text } from "../components/Text";


export const enum FONT_ID {
	ROBOTO = 0
}
const urlsJSONs: Map<FONT_ID, string> = new Map([
	[ FONT_ID.ROBOTO, findMappedPathFor('./dist/Roboto-msdf.json') ]
]);
const urlsImages: Map<FONT_ID, string> = new Map([
	[ FONT_ID.ROBOTO, findMappedPathFor('./dist/Roboto-msdf.png') ]
]);

const fontFamilies: Map<FONT_ID, Font> = new Map<FONT_ID, Font>();

const textureLoader = new TextureLoader();
const fontTextures: DefaultMap<FONT_ID, Texture> = new DefaultMap<FONT_ID, Texture>( ( fontId ) => {
	const url = urlsImages.get(fontId);
	return textureLoader.load( url!, ( texture ) => {
		texture.generateMipmaps = false;
		texture.minFilter = LinearFilter;
		texture.magFilter = LinearFilter;
	} );
});

export async function loadFontsFamilies(): Promise<void> {
	if (fontFamilies.size === 0) {
		for(const [name, url] of urlsJSONs) {
			const response = await fetch(url);
			const font = await response.json() as Font;

			_buildFriendlyKerningValues( font );

			fontFamilies.set( name, font);
		}
	}
}

export function setFontFamily( component: Text, fontId: FONT_ID ) {
	const fontFamily = fontFamilies.get( fontId );
	if ( fontFamily ) {
		component.fontFamily = fontFamily;
	}
}

export function getFontTexture( fontId: FONT_ID ): Texture {
	return fontTextures.getOrCreate( fontId );
}

/**
 * From the original json font kernings array
 * First  : Reduce the number of values by ignoring any kerning defining an amount of 0
 * Second : Update the data structure of kernings from
 * 			{Array} : [{first: 97, second: 121, amount: 0},{first: 97, second: 122, amount: -1},...]
 * 			to
 * 			{Object}: {"ij":-2,"WA":-3,...}}
 *
 */
function _buildFriendlyKerningValues( font: Font ) {
	// As "font registering" can comes from different paths : addFont, loadFontJSON, setFontFamily
	// Be sure we don't repeat this operation
	if ( font._kernings ) return;

	const friendlyKernings: Map<string, number> = new Map<string, number>();

	for ( let i = 0; i < font.kernings.length; i++ ) {
		const kerning = font.kernings[ i ];

		// ignore zero kerned glyph pair
		if ( kerning.amount === 0 ) continue;

		// Build and store the glyph paired characters "ij","WA", ... as keys, referecing their kerning amount
		const glyphPair = String.fromCharCode( kerning.first, kerning.second );
		friendlyKernings.set( glyphPair, kerning.amount);
	}

	// update the font to keep it
	font._kernings = friendlyKernings;
}
