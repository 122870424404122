<script lang="ts">
    import type { ContextMenuSettings } from "./ContextMenuSettings";
    import { Button, ButtonComponent, ButtonType, IconButton } from "../button";
    import { clickOutside } from "./ClickOutside";
    import { VersionedStore } from "src/VersionedStore";
    import {
        getContextMenuSettings,
        setSettings,
    } from "./ContextMenuSettingsStore";

    export let settings: ContextMenuSettings;
    let menuEl: HTMLDivElement;

    setSettings();

    function close() {
        if (settings.dispose) {
            settings.dispose();
        }
    }

    function onActionClick() {
        if (settings.menuAction?.actionFn) {
            settings.menuAction.actionFn();
        }
        close();
    }

    const actionEnabled = settings.menuAction?.isEnabled
        ? new VersionedStore(settings.menuAction.isEnabled)
        : undefined;
    const contextSettings = getContextMenuSettings();

    function onClickOutside() {
        if (settings.dispose && $contextSettings.closeOnClickOutside) {
            settings.dispose();
        }
    }
</script>

{#if settings.isMenuOpened}
    <div
        style="
            --mouse-x: {settings.offsetPx.x}px;
            --mouse-y: {settings.offsetPx.y}px;
            --z-index: {settings.zIndex ?? 999}
        "
        class="mdc-menu mdc-menu-surface mdc-menu-surface--open"
        class:focus-menu={!$contextSettings.closeOnClickOutside}
        on:click={() => {
            settings.closeOnInteraction && close();
        }}
        use:clickOutside={onClickOutside}
        tabindex="0"
        bind:this={menuEl}
    >
        {#if settings.closeIcon}
            <div class="header-holder">
                <div class="mdc-typography--subheader2 header">
                    {settings.header ?? ""}
                </div>
                <div class="close-menu-action-container">
                    <ButtonComponent desc={new IconButton("Close", close)} />
                </div>
            </div>
        {/if}
        <div
            class="body-container custom-scrollbar"
            style="
                --max-height: {settings.maxHeightPx ? settings.maxHeightPx + 'px' : '50vh'};
                --width: {settings.widthPx ? settings.widthPx + 'px' : 'auto'};
                --min-width: {settings.minWidthPx ? settings.minWidthPx + 'px' : 'auto'}
            "
        >
            <slot name="body"></slot>
        </div>
        {#if settings.menuAction}
            <div
                class="action-container"
                class:columns={settings.widthPx ? settings.widthPx > 300 : true}
            >
                <div class="action">
                    <ButtonComponent
                        desc={new Button(
                            settings.menuAction.name,
                            settings.menuAction.name === "Ok"
                                ? ButtonType.Outlined
                                : ButtonType.Unelevated,
                            onActionClick,
                            actionEnabled ? !$actionEnabled : false,
                        )}
                        compact={true}
                    />
                </div>
            </div>
        {/if}
    </div>
{/if}

<style lang="scss">
    .close-menu-action-container {
        :global(.custom-icon-container) {
            padding: 4px;
        }
    }
    .body-container {
        flex: 1;
        max-height: var(--max-height);
        width: var(--width);
        min-width: var(--min-width);
        overflow: auto;
        & :global(.mdc-tooltip) {
            width: 320px;
        }
    }
    .mdc-menu {
        //display: flex;
        flex-direction: column;
        transition: none;
        --mouse-x: 0;
        --mouse-y: 0;
        left: 0;
        top: 0;
        transform: translateX(min(var(--mouse-x), calc(100vw - 100%)))
            translateY(min(var(--mouse-y), calc(100vh - 100%)));

        --z-index: 999;
        z-index: var(--z-index);
        overflow: inherit;
        &.focus-menu {
            border-radius: 1px;
            outline: 4px solid rgba(156, 39, 176, 0.32);
        }
    }

    .header-holder {
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        height: 40px;
        position: relative;
        z-index: 1;
        padding-right: 16px;
        padding-left: 12px;
    }
    .action-container {
        padding: 0px 16px;
        margin: 8px 0 16px;
        &.columns {
            display: grid;
            grid-template-columns: 3fr 4fr;
            gap: 4px;
        }
    }
    .mdc-menu :global(.container .group:first-child:last-child) {
        margin: 0;
    }
    .action {
        grid-column-start: 2;
        :global(button) {
            width: 100%;
        }
    }
</style>
