<script lang="ts">
    import type { MenuItemSetting } from "./ToolbarItems";
    import PropertyView from "../pui/PropertyView.svelte";

    export let menu: MenuItemSetting;
</script>

<div class="menu-item">
    <PropertyView bind:property={menu.property} offset={0} />
    {#if menu.hotkeysString}
        <span class="text-main-medium">{menu.hotkeysString}</span>
    {/if}
</div>

<style>
    .menu-item {
        padding: 0 16px;
        display: flex;
        align-items: center;
    }
    .menu-item :global(.bool-prop .property-name) {
        display: none;
    }
    .menu-item :global(.property-row .property-name) {
        flex: 3;
    }
    .menu-item :global(.property-row .property-name .property-name-label) {
        font-weight: inherit;
        color: var(--color-main-dark);
    }
    .menu-item :global(.property-row .property-value) {
        flex: 2;
    }
    .menu-item:last-child :global(.property-row .property-value) {
        margin-bottom: 0;
    }
    .menu-item :global(.property-value .sv-dropdown) {
        width: 133px;
    }
</style>
