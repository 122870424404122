<script lang="ts">
import { CostModel as CM, UnitsMapper } from 'bim-ts';
import { replaceCurrencyUnitWithSymbol } from 'engine-utils-ts';
import { VersionedStore } from 'src/VersionedStore';
import Icon from '../../libui/icon/Icon.svelte';
import { getContext, onMount } from 'svelte';

export let initialValue: CM.CostSourceType;
export let initialOptions: CM.CostSourceType[];
export let onChange: (value: CM.CostSourceType) => void;

const unitsMapper = new VersionedStore(getContext<UnitsMapper>('unitsMapper'));


class Title {
    constructor(public text: string) {}
}

class Option {
    constructor(
        public title: string,
        public description: string,
        public relatedCostSource: CM.CostSourceType,
    ) {}
}

type Item = Title | Option

let items: Item[] = [];

onMount(() => {
    const currencySymbol = replaceCurrencyUnitWithSymbol($unitsMapper.mapUnitToConfigured('usd'))
    items = [];
    if (initialOptions.includes(CM.CostSourceType.GroupSum)) {
        items.push(
            new Title('Reflected in object properties'),
            new Option('Model-based and Benchmark mix', 'Simple sum of costs.', CM.CostSourceType.GroupSum),
        )
    }
    if (
        initialOptions.includes(CM.CostSourceType.BenchmarkLumpSum) ||
        initialOptions.includes(CM.CostSourceType.BenchmarkPerQuantity)
    ) {
        items.push(new Title('Visible in cost report only'));
        if (initialOptions.includes(CM.CostSourceType.BenchmarkLumpSum)) {
            items.push(new Option(
                'Overwrite to Benchmark lump sum',
                `Total cost is fixed regardless of DC capacity and ${currencySymbol}/Watt is shown for the reference only.`,
                CM.CostSourceType.BenchmarkLumpSum
            ));
        }
        if (initialOptions.includes(CM.CostSourceType.BenchmarkPerQuantity)) {
            items.push(new Option(
                `Overwrite to Benchmark ${currencySymbol}/Watt`,
                `${currencySymbol}/Watt is fixed and total cost is calculated based on layout DC capacity.`,
                CM.CostSourceType.BenchmarkPerQuantity
            ))
        }
    }
})


</script>

<div class="container">
    {#each items as item}
        {#if item instanceof Title}
            <h3>{item.text}</h3>
        {:else if item instanceof Option}
            {@const option = item}
            <div
                class="option"
                on:click={() => onChange(option.relatedCostSource)}
                class:selected-option={item.relatedCostSource === initialValue}
            >
                <div class="option-content">
                    <p class="option-title">
                        {item.title}
                        {#if item.relatedCostSource === initialValue}
                            <span class="active-icon">
                                <Icon name="Check"/>
                            </span>
                        {/if}
                    </p>
                    <p class="option-description">{item.description}</p>
                </div>
            </div>
        {/if}
    {/each}
</div>

<style lang="scss">
.container {
    padding: 10px;
    background: white;
    box-shadow: var(--dropdownsimple-box-shadow);
    max-width: 250px;
    font-family: "Source Sans";
}
h3 {
    margin: 0 5px;
}
.option {
    margin: 1px -10px;
    padding: 2px 10px;
    &:hover {
        background: rgba(0,0,0,0.05)
    }
    &.selected-option {
        .option-title {
            color: #4daf00;
            position: relative;
        }
    }
    .option-content {
        margin-left: 15px;
        margin-bottom: 6px;
        cursor: pointer;
        .option-title {
            font-size: 16px;
            margin: 0;
        }
        .option-description {
            font-size: 12px;
            opacity: 0.8;
            margin: 0;
        }
    }
}
.active-icon {
    position: absolute;
    left: -15px;
    width: 15px;
    display: flex;
    height: 100%;
    top: 0;
    justify-content: center;
    align-items: center;
}
</style>
