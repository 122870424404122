import type { UserAuthData } from "./auth"

export function setupIntercom(authData: UserAuthData, mode: string | null) {
    const company = authData.company ?? 'unknown company';
    const settingsObject = {
        api_base: "https://api-iam.eu.intercom.io",
        app_id: "bzgdrqak",
        name: authData.name,
        email: authData.email,
        company: {
          id: company,
          name: company,
        },
        user_id: authData.id,
        custom_launcher_selector: '.intercom-button',
        hide_default_launcher: true,
        dashboard_mode: mode,
    }
    // @ts-ignore
    window.intercomSettings = settingsObject;
    // @ts-ignore
    // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/bzgdrqak'
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/bzgdrqak';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}

