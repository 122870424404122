import type { LazyVersioned } from 'engine-utils-ts';
import type { Camera} from '../3rdParty/three';
import type { GpuResources } from '../composer/GpuResources';
import { KrMaterial } from '../materials/KrMaterial';
import { FXAAShader } from '../shaders/FXAAShader';
import { PostProcPass } from './PostProcPass';
import type { BudgetLimitForPass, RenderPass, RenderResult } from './RenderPass';
import type { RTIdent, RenderTargets } from './RenderTargets';




export class AAPass implements RenderPass {
    
    enabled: boolean = true;
    identifier: string;
    inputs: RTIdent[];
    outputs: RTIdent[];

    antiAliasPower01: LazyVersioned<number>;

    _fxaaPass: PostProcPass;
    _copyPass: PostProcPass;

    //smaa
    constructor(
        inputs: RTIdent[],
		output: RTIdent,
        antiAliasPower01: LazyVersioned<number>,
    ) {
        this.identifier = 'aa';
        
        this.inputs = inputs;
        this.outputs = [output];

        this.antiAliasPower01 = antiAliasPower01;

        this._fxaaPass = new PostProcPass('to_pre_screen_fxaa', inputs[0], output, KrMaterial.newPostMat(FXAAShader));
        this._copyPass = PostProcPass.newCopyPass('', inputs[0], output);

    }

    version(): number {
        return 0;
    }
    
    render(
        camera: Readonly<Camera>,
        gpuRes: GpuResources,
        renderTargets: RenderTargets,
        budget: BudgetLimitForPass,
        anyInputsIncomplete: boolean
    ): RenderResult {

        const aaPower = this.antiAliasPower01.poll();

        if (aaPower > 0.01) {
            this._fxaaPass.render(camera, gpuRes, renderTargets, budget, false);
        } else {
            this._copyPass.render(camera, gpuRes, renderTargets, budget, false);
        }

        // if (anyInputsIncomplete) {
        // } else {
            // this._edgesPass.render(camera, gpuRes, renderTargets, budget);
            // this._weightsPass.render(camera, gpuRes, renderTargets, budget);
            // this._screenBlendPass.render(camera, gpuRes, renderTargets, budget);
        // }

        return {finished: true, unitsRenderered: 1}
    }
}

