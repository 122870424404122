import type { SceneInstance } from 'bim-ts';
import type { MakeOptional } from 'engine-utils-ts';
import type { Node } from '../models/node';
import type { PatternStepDesc, SceneInstanceDesc } from '../utils';

export interface AddSiParams {
    si: SceneInstanceDesc;
    step: PatternStepDesc;
    parent: Node;
}

export type AddSiParamsWithOptionalParent = MakeOptional<AddSiParams, 'parent'>;

export enum EquipmentType {
	CombinerBox,
	Transformer,
	Inverter,
	Tracker,
	Substation,
}

const typeIdentifierTextPatternToEquipmentType = [
	['transformer', EquipmentType.Transformer],
	['combiner-box', EquipmentType.CombinerBox],
	['inverter', EquipmentType.Inverter],
	['tracker', EquipmentType.Tracker],
	['any-tracker', EquipmentType.Tracker],
	['substation', EquipmentType.Substation],
	['fixed-tilt', EquipmentType.Tracker],
] as [string, EquipmentType][];

export function equipmentType(instance: SceneInstance): EquipmentType | null {
	for (const [key, ty] of typeIdentifierTextPatternToEquipmentType) {
		if (instance.type_identifier.includes(key)) {
			return ty;
		}
	}
	return null;
}
