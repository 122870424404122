import type { RGBAHex, ScopedLogger } from "engine-utils-ts";
import { DefaultMap, ErrorUtils } from "engine-utils-ts";
import type { Builder } from "flatbuffers";
import { PropertyValue } from "../schema/property-value";
import { PropertyNumericValueT } from "../schema/property-numeric-value";
import { PropertyStringValueT } from "../schema/property-string-value";
import { PropertyBoolValueT } from "../schema/property-bool-value";
import { PropertySceneInstancesValueT } from "../schema/property-scene-instances-value";
import { PropertyNumberRangeT } from "../schema/property-number-range";
import type { Property} from "../schema/property";
import { PropertyT } from "../schema/property";
import { PropertySelectionValueT } from "../schema/property-selection-value";
import { PropertyMultiSelectionValueT } from "../schema/property-multi-selection-value";
import { PropertyAssetsValueT } from '../schema/property-assets-value';
import { PropertyColorValueT } from '../schema/property-color-value';
import type { CatalogPropertyValue } from "../properties/ObjectRefProps";
import { SceneInstancesProperty, AssetsProperty, CatalogItemsReferenceProperty } from "../properties/ObjectRefProps";
import { NumberProperty, StringProperty, SelectorProperty, MultiSelectorProperty, BooleanProperty, ColorProperty, NumberRangeProperty, NumberPropertyWithOptions } from "../properties/PrimitiveProps";
import type { PropertyBase } from "../properties/Props";
import { PropertyCatalogItemsValueT } from "../schema/property-catalog-items-value";
import { CatalogItemValueT } from "../schema/catalog-item-value";
import { CustomSerializedPropertyT } from "../schema/custom-serialized-property";
import { FlatbufCommon } from "./FlatbufCommon";
import type { CustomPropertySerializer} from "../properties/CustomPropsRegistry";
import { CustomPropsRegistry } from "../properties/CustomPropsRegistry";
import { NumberPropertyWithOptionsT } from "../schema/number-property-with-options";
import { NumericRangePropertyT } from "../schema/numeric-range-property";

type CustomPropCtor = {new (...args: any): PropertyBase};

export class PropertySerializer {

    readonly builder: Builder
    readonly _alreadySerialized: DefaultMap<PropertyBase, number>;

    readonly _customSerializers: DefaultMap<CustomPropCtor, CustomPropertySerializer<PropertyBase> | null>;
    readonly _customSerializedTypesVersions = new Map<string, number>();

    constructor(args: {
        logger: ScopedLogger,
        builder: Builder,
    }) {
        this.builder = args.builder;
        this._customSerializers = new DefaultMap((ctor) => {
            const serializerCtor = CustomPropsRegistry.tryGetSerializerForClass(ctor);
            if (!serializerCtor) {
                return null;
            }
            return new serializerCtor(args.logger);
        });

        this._alreadySerialized = new DefaultMap((p) => {
            let propType = PropertyValue.NONE;
            let value:
                | PropertyNumericValueT
                | PropertyStringValueT
                | PropertyBoolValueT
                | PropertySceneInstancesValueT
                | PropertyMultiSelectionValueT
                | PropertyAssetsValueT
                | PropertyCatalogItemsValueT
                | PropertyColorValueT
                | CustomSerializedPropertyT
                | NumericRangePropertyT
                | NumberPropertyWithOptionsT;
            if (p instanceof NumberRangeProperty) {
                propType = PropertyValue.NumericRangeProperty;
                value = new NumericRangePropertyT(
                    new PropertyNumberRangeT(p.value[0], p.value[1]),
                    p.step,
                    p.range
                        ? new PropertyNumberRangeT(p.range[0], p.range[1])
                        : null,
                    p.unit
                );
            } else if (p instanceof NumberPropertyWithOptions) {
                propType = PropertyValue.NumberPropertyWithOptions;
                value = new NumberPropertyWithOptionsT(
                    p.value,
                    p.step,
                    p.range
                        ? new PropertyNumberRangeT(p.range[0], p.range[1])
                        : null,
                    p.unit,
                    p.selectedOption,
                    p.options,
                );
            }
            else if(p instanceof NumberProperty) {
                propType = PropertyValue.PropertyNumericValue;
                value = new PropertyNumericValueT(
                    p.value,
                    p.step,
                    p.range
                        ? new PropertyNumberRangeT(p.range[0], p.range[1])
                        : null,
                    p.unit
                );
            } else if (p instanceof StringProperty) {
                propType = PropertyValue.PropertyStringValue;
                value = new PropertyStringValueT(p.value);
            } else if (p instanceof SelectorProperty) {
                propType = PropertyValue.PropertySelectionValue;
                value = new PropertySelectionValueT(p.value, p.options);
            } else if (p instanceof SceneInstancesProperty) {
                propType = PropertyValue.PropertySceneInstancesValue;
                value = new PropertySceneInstancesValueT(
                    p.value,
                    p.maxCount ?? undefined,
                    p.types
                );
            } else if (p instanceof MultiSelectorProperty) {
                propType = PropertyValue.PropertyMultiSelectionValue;
                value = new PropertyMultiSelectionValueT(
                    p.value,
                    p.options,
                    p.maxSelect ?? undefined
                );
            } else if (p instanceof BooleanProperty) {
                propType = PropertyValue.PropertyBoolValue;
                value = new PropertyBoolValueT(p.value);
            } else if (p instanceof AssetsProperty) {
                propType = PropertyValue.PropertyAssetsValue;
                value = new PropertyAssetsValueT(p.value, p.maxCount ?? undefined, p.types);
            } else if (p instanceof ColorProperty) {
                propType = PropertyValue.PropertyColorValue;
                value = new PropertyColorValueT(p.value);
            } else if (p instanceof CatalogItemsReferenceProperty) {
                propType = PropertyValue.PropertyCatalogItemsValue;
                value = new PropertyCatalogItemsValueT(
                    p.value.map(v => new CatalogItemValueT(v.id, v.type)),
                    p.maxCount ?? undefined,
                    p.types,
                    p.assetsTypes ?? undefined,
                    p.defaultValues ? p.defaultValues.map(v => new CatalogItemValueT(v.id, v.type)) : undefined
                );
            } else {
                const customTypeIdent = CustomPropsRegistry.getSerializationTypeIdentifierOf(p);
                if (!customTypeIdent) {
                    ErrorUtils.logThrow(`custom typeIdent not found, serialization for property is not implemented or registered`, p);
                }
                // must be custom property
                const customSerializer = this._customSerializers.getOrCreate(p.constructor as CustomPropCtor);
                if (!customSerializer) {
                    ErrorUtils.logThrow(`serialization for property is not implemented or registered`, p);
                } else {
                    propType = PropertyValue.CustomSerializedProperty;
                    this._customSerializedTypesVersions.set(customTypeIdent, customSerializer.currentFormatVersion);
                    if (customSerializer.serializeToBinary) {
                        value = new CustomSerializedPropertyT(
                            customTypeIdent,
                            null,
                            customSerializer.serializeToBinary(p) as unknown as number[]
                        );
                    } else if (customSerializer.serializeToString) {
                        value = new CustomSerializedPropertyT(
                            customTypeIdent,
                            customSerializer.serializeToString(p),
                            []
                        );
                    } else {
                        ErrorUtils.logThrow(`${customTypeIdent} property custom serializer doesnt implement any serilization methods`, customSerializer);
                    }
                }
            }
            const description = ('description' in p ? p.description : undefined) as string | null;
            const isReadonly = ('isReadonly' in p ? p.isReadonly : undefined) as boolean | undefined;
            const prop = new PropertyT(
                propType,
                value,
                description ?? null,
                isReadonly ?? false
            );
            return prop.pack(this.builder);
        });
    }

    serializeProp(property: PropertyBase): number {
        return this._alreadySerialized.getOrCreate(property);
    }

    serializeCustomTypesVersions(): number {
        return FlatbufCommon.writeTypeIdentsVersions(
            this.builder,
            this._customSerializedTypesVersions
        );
    }
}

export class PropsDeserializer {

    readonly logger: ScopedLogger
    readonly customTypesVersions: Map<string, number>;

    readonly _deserializedPropsPerOffset = new Map<number, PropertyBase | null>();
    readonly _customDeserializersPerTypeIdent: DefaultMap<string, CustomPropertySerializer<PropertyBase> | null>;

    constructor( args: {
        logger: ScopedLogger,
        customTypesVersions: Map<string, number>,
    }) {
        this.logger = args.logger;
        this.customTypesVersions = args.customTypesVersions;
        this._customDeserializersPerTypeIdent = new DefaultMap((typeIdent) => {
            const customSerializer = CustomPropsRegistry.tryGetSerializerForTypeIdent(typeIdent);
            if (customSerializer) {
                return new customSerializer(args.logger);
            }
            this.logger.error('no serializer found for custom property ident ', typeIdent);
            return null;
        });
    }

    deserialize(flatProperty: Property): PropertyBase | null {
        const offset = flatProperty.bb_pos;

        let deserialized = this._deserializedPropsPerOffset.get(offset);
        if (deserialized === undefined) {
            try {
                const propertyT = flatProperty.unpack();
                deserialized = this._deserProp(propertyT);
            } catch (e) {
                this.logger.error('error deserializing property', e);
                deserialized = null;
            }
            this._deserializedPropsPerOffset.set(offset, deserialized);
        }
        return deserialized;
    }

    private _deserProp(p: PropertyT): PropertyBase | null {
        let prop:PropertyBase | null;
        const description = p.description as (string | null) ?? undefined;
        const isReadonly = p.readonly ?? false;

        if (p.property instanceof CustomSerializedPropertyT) {
            const typeIdent = p.property.typeIdent as string;
            const customSerializer = this._customDeserializersPerTypeIdent.getOrCreate(typeIdent);
            if (customSerializer) {
                // TODO: more logs and errors handling
                // TODO: get rid of propertyT class usage
                const customFormatVersion = this.customTypesVersions.get(typeIdent) ?? 0;
                if (p.property.asString) {
                    prop = customSerializer.deserializeFromString!(customFormatVersion, p.property.asString as string);
                } else if (p.property.asBinary) {
                    prop = customSerializer.deserializeFromBinary!(customFormatVersion, new Uint8Array(p.property.asBinary));
                } else {
                    prop = null;
                }
            } else {
                prop = null;
            }

        } else if (p.property instanceof PropertyNumericValueT) {
            prop = NumberProperty.new({
                value: p.property.value,
                range: p.property.range == null ? undefined: [p.property.range.min!, p.property.range.max!],
                step: p.property.step || undefined,
                unit: p.property.unit === null? "": p.property.unit as string,
                description,
                isReadonly
            });
        } else if (p.property instanceof PropertyStringValueT) {
            prop = StringProperty.new({
                value: p.property.value === null ? "": p.property.value as string,
                description,
                isReadonly
            });
        } else if (p.property instanceof PropertySelectionValueT) {
            prop = SelectorProperty.new({
                value: p.property.value === null ? "": p.property.value as string,
                options: p.property.options,
                description,
                isReadonly
            });
        }else if (p.property instanceof PropertySceneInstancesValueT) {
            prop = new SceneInstancesProperty({
                value: p.property.value,
                maxCount: p.property.maxCount,
                types: p.property.types,
                description,
                isReadonly
            });
        }else if (p.property instanceof PropertyMultiSelectionValueT) {
            prop = MultiSelectorProperty.new({
                value: p.property.value,
                options: p.property.options,
                maxSelect: p.property.maxSelect,
                description,
                isReadonly
            });
        }else if (p.property instanceof PropertyAssetsValueT) {
            prop = new AssetsProperty({
                value: p.property.value,
                maxCount: p.property.maxCount,
                types: p.property.types,
                description,
                isReadonly
            });
        } else if (p.property instanceof PropertyBoolValueT) {
            prop = BooleanProperty.new({
                value: p.property.value,
                description,
                isReadonly
            });
        } else if (p.property instanceof PropertyColorValueT) {
            prop = ColorProperty.new({
                value: p.property.value as RGBAHex,
                description,
                isReadonly,
            });
        } else if (p.property instanceof PropertyCatalogItemsValueT) {
            const convertCatalogValue = (values: CatalogItemValueT[]): CatalogPropertyValue[] => {
                const value: CatalogPropertyValue[] = [];
                for (const v of values) {
                    if(v.type === 'asset' || v.type === 'catalog_item'){
                        value.push({id: v.id, type: v.type});
                    } else {
                        this.logger.error('undefined catalog items property value type: ' + JSON.stringify(p));
                    }
                }
                return value;
            }

            const defaultValues = convertCatalogValue(p.property.defaultValues);

            prop = new CatalogItemsReferenceProperty({
                value: convertCatalogValue(p.property.value),
                maxCount: p.property.maxCount,
                types: p.property.types,
                assetsTypes: p.property.assetsTypes,
                description,
                isReadonly,
                defaultValues: defaultValues.length > 0 ? defaultValues : undefined,
            });
        } else if (p.property instanceof NumericRangePropertyT) {
            prop = NumberRangeProperty.new({
                value: p.property.value instanceof PropertyNumberRangeT ? [p.property.value.min, p.property.value.max] : [0, 10],
                range: p.property.range == null ? undefined: [p.property.range.min!, p.property.range.max!],
                step: p.property.step || undefined,
                unit: p.property.unit === null ? "" : p.property.unit as string,
                description,
                isReadonly,
            });
        } else if (p.property instanceof NumberPropertyWithOptionsT) {
            prop = NumberPropertyWithOptions.new({
                value: p.property.value,
                range: p.property.range == null ? undefined: [p.property.range.min!, p.property.range.max!],
                step: p.property.step || undefined,
                unit: p.property.unit === null ? "": p.property.unit as string,
                description,
                isReadonly,
                options: p.property.options,
                selectedOption: p.property.selectedOption === null ? "": p.property.selectedOption as string,
            });
        } else {
            this.logger.error('unrecognized property: ' + JSON.stringify(p));
            prop = null;
        }
        return prop;
    }
}
