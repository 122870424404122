import type { UndoStack } from 'engine-utils-ts';
import { Aabb, Vector3 } from 'math-ts';

import { BimGeometryType } from './BimGeometries';
import type { BimGeometryBase } from './BimGeometriesBase';
import { BimGeometriesBase } from './BimGeometriesBase';

export class CubeGeometry implements BimGeometryBase {
    constructor(
        public readonly size: Vector3 = new Vector3(),
        public readonly center: Vector3 = new Vector3(),
    ) {
    }

	calcAabb(): Aabb {
		return Aabb.empty().setFromCenterAndSize(this.center, this.size);
	}

    checkForErrors(validationErrors: string[]) {
    }
}

export class CubeGeometries extends BimGeometriesBase<CubeGeometry> {

    constructor(
        undoStack?: UndoStack,
    ) {
        super({
            identifier: "cube-geometries",
            idsType: BimGeometryType.Cube,
            undoStack,
            T_Constructor: CubeGeometry,
            interner: (p) => `${Vector3.asString(p.size)}|${Vector3.asString(p.center)}`
        });
    }
}

