<svg width="16" height="16" viewBox="0 0 16 16" fill="none"
    ><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 16 16)"
        ><path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.5268 6.52673L8 11.0535L3.47321 6.52673L4.52679 5.47314L8 8.94635L11.4732 5.47314L12.5268 6.52673Z"
            fill="currentColor"
        /></g
    ></svg
>
