import type { RegularHeightmapGeometry } from "../../geometries/RegularHeightmapGeometry";
import type { Matrix4} from "math-ts";
import { Vector2, Vector3 } from "math-ts";
import type { TerrainMetricsRanges } from "./TerrainMetrics";
import { TerrainDisplaySlopeSelector, TerrainMetrics, PerTileCutFillHeatmap, calcTriangleAreaByPoints, paletteSlicesIndicesFromValue, calcTriangleAreaByVectors } from "./TerrainMetrics";

/*

p01         b2        p11
   +<----------------+
   | \      |      / |
   |  \    h20    /  |
   |   \    |    /   |
   |    \   |   /    |
   |     \  |  /     |
   |      \ | /      |
   |       \v/       |
 b1|<--h31--с<--h31--|b3
   |       /|\       |
   |      / | \      |
   |     /  |  \     |
   |    /   |   \    |
   |   /   h20   \   |
   |  /     |     \  |
   v /      v      \ v
   +<----------------+
p00         b0        p10

*/

export class TerrainMetricsRegular {

    static getMaxEWSlope(
        geometry: RegularHeightmapGeometry, 
    ): number {
        let coord = 0, maxDiff = 0;
        let el1: number, el2: number, diff: number;
        
        for (let iy = 0; iy <= geometry.ySegmentsCount; ++iy, ++coord) {
            for (let ix = 0; ix < geometry.xSegmentsCount; ++ix, ++coord) {
                el1 = geometry.elevationsInCmRelative[coord];
                el2 = geometry.elevationsInCmRelative[coord + 1];
                if (el1 === 0 || el2 === 0) {
                    continue;
                }

                diff = Math.abs(el1 - el2);
                if (diff > maxDiff) {
                    maxDiff = diff;
                }
            }
        }

        return maxDiff / geometry.segmentSizeInMeters;
    }

    static getMaxNSSlope(
        geometry: RegularHeightmapGeometry, 
    ): number {
        let coord = 0, maxDiff = 0;
        let el1: number, el2: number, diff: number;
        const xPointsCount = geometry.xSegmentsCount + 1;
        
        for (let ix = 0; ix <= geometry.xSegmentsCount; ++ix) {
            coord = ix;
            for (let iy = 0; iy < geometry.ySegmentsCount; ++iy, coord += xPointsCount) {
                el1 = geometry.elevationsInCmRelative[coord];
                el2 = geometry.elevationsInCmRelative[coord + xPointsCount];
                if (el1 === 0 || el2 === 0) {
                    continue;
                }

                diff = Math.abs(el1 - el2);
                if (diff > maxDiff) {
                    maxDiff = diff;
                }
            }
        }

        return maxDiff / geometry.segmentSizeInMeters;
    }

    static calcuateAreaMetricsForSlopeTo(
        palette: TerrainMetricsRanges, 
        geometry: RegularHeightmapGeometry, 
        wm: Matrix4, 
        slopeSelector: TerrainDisplaySlopeSelector
    ): TerrainMetrics {
        
        const isIdentity = wm.isIdentity();
        const halfSegmentSize = 0.5 * geometry.segmentSizeInMeters;

        let p00 = new Vector3(), p10 = new Vector3(), p01 = new Vector3(), p11 = new Vector3();
        let b0 = new Vector3(), b1 = new Vector3(), b2 = new Vector3(), b3 = new Vector3();
        let c = new Vector3(), h20 = new Vector3(), h31 = new Vector3();
        
        const perSliceArea = new Array(palette.slices.length - 1).fill(0);
        let area: number, totalArea = 0, currRange = {min: Infinity, max: -Infinity};
        let yCoord, xCoord;

        for (let iy = 0; iy < geometry.ySegmentsCount; iy++) {
            yCoord = iy * geometry.segmentSizeInMeters;

            p10.set(0, yCoord, geometry.readElevationAtInds(0, iy));
            p11.set(0, yCoord + geometry.segmentSizeInMeters, geometry.readElevationAtInds(0, iy + 1));
            if (!isIdentity) {
                p10.applyMatrix4(wm);
                p11.applyMatrix4(wm);
            }
            b3.copy(p10).sub(p11);

            for (let ix = 0; ix < geometry.xSegmentsCount; ix++) {
                xCoord = ix * geometry.segmentSizeInMeters;

                p00.copy(p10);
                p01.copy(p11);
                b1.copy(b3);

                p10.set(xCoord + geometry.segmentSizeInMeters, yCoord, geometry.readElevationAtInds(ix + 1, iy));
                p11.set(
                    xCoord + geometry.segmentSizeInMeters, 
                    yCoord + geometry.segmentSizeInMeters, 
                    geometry.readElevationAtInds(ix + 1, iy + 1)
                );
                b3.copy(p10).sub(p11);

                if (Number.isNaN(p00.z + p01.z + p10.z + p11.z)) {
                    continue;
                }

                if (!isIdentity) {
                    p10.applyMatrix4(wm);
                    p11.applyMatrix4(wm);
                }
                
                c.copy(p00).add(p10).add(p01).add(p11).multiplyScalar(0.25);
                b0.copy(p00).sub(p10);
                b2.copy(p01).sub(p11);

                if (slopeSelector === TerrainDisplaySlopeSelector.EW) {
                    h31.copy(p00).add(p01).multiplyScalar(0.5).sub(c);
                    
                    area = calcTriangleAreaByVectors(b0, c.clone().sub(p10));
                    TerrainMetricsRegular.updateAngleMetricsByTriangle(
                        b0, area, geometry.segmentSizeInMeters, palette, perSliceArea, currRange);
                    totalArea += area;
                    
                    area = calcTriangleAreaByVectors(b3, c.clone().sub(p11));
                    TerrainMetricsRegular.updateAngleMetricsByTriangle(
                        h31, area, halfSegmentSize, palette, perSliceArea, currRange);
                    totalArea += area;
                    
                    area = calcTriangleAreaByVectors(b2, c.clone().sub(p11));
                    TerrainMetricsRegular.updateAngleMetricsByTriangle(
                        b2, area, geometry.segmentSizeInMeters, palette, perSliceArea, currRange);
                    totalArea += area;
                    
                    area = calcTriangleAreaByVectors(b1, c.clone().sub(p01));
                    TerrainMetricsRegular.updateAngleMetricsByTriangle(
                        h31, area, halfSegmentSize, palette, perSliceArea, currRange);
                    totalArea += area;
                } else {
                    h20.copy(p00).add(p10).multiplyScalar(0.5).sub(c);

                    area = calcTriangleAreaByVectors(b0, c.clone().sub(p10));
                    TerrainMetricsRegular.updateAngleMetricsByTriangle(
                        h20, area, halfSegmentSize, palette, perSliceArea, currRange);
                    totalArea += area;

                    area = calcTriangleAreaByVectors(b3, c.clone().sub(p11));
                    TerrainMetricsRegular.updateAngleMetricsByTriangle(
                        b3, area, geometry.segmentSizeInMeters, palette, perSliceArea, currRange);
                    totalArea += area;
                    
                    area = calcTriangleAreaByVectors(b2, c.clone().sub(p11));
                    TerrainMetricsRegular.updateAngleMetricsByTriangle(
                        h20, area, halfSegmentSize, palette, perSliceArea, currRange);
                    totalArea += area;
                    
                    area = calcTriangleAreaByVectors(b1, c.clone().sub(p01));
                    TerrainMetricsRegular.updateAngleMetricsByTriangle(
                        b1, area, geometry.segmentSizeInMeters, palette, perSliceArea, currRange);
                    totalArea += area;
                }
            }
        }

        return new TerrainMetrics(
            palette,
            perSliceArea,
            totalArea,
            currRange
        );
    }

    static updateAngleMetricsByTriangle(
        base: Vector3, area: number, baseProjectionLength: number,
        palette: TerrainMetricsRanges, perSlice: number[], currRange: {min: number, max: number}
    ) {
        const perc = 100 * base.z / baseProjectionLength;

        const slicesIndices = paletteSlicesIndicesFromValue(perc, palette.slices);
        if (slicesIndices instanceof Array) {
            perSlice[slicesIndices[0]] += area * 0.5;
            perSlice[slicesIndices[1]] += area * 0.5;
        } else if (slicesIndices >= 0) {
            perSlice[slicesIndices] += area;
        }
        if (perc < currRange.min) {
            currRange.min = perc;
        }
        if (perc > currRange.max) {
            currRange.max = perc;
        }
    }

    static calcuateAreaMetricsForElevation(
        palette: TerrainMetricsRanges, 
        geometry: RegularHeightmapGeometry, 
        wm: Matrix4,
    ): TerrainMetrics {
        const isIdentity = wm.isIdentity();
        const halfSegmentSize = 0.5 * geometry.segmentSizeInMeters;

        let p00 = new Vector3(), p10 = new Vector3(), p01 = new Vector3(), p11 = new Vector3();
        let c = new Vector3();
        
        const perSliceArea = new Array(palette.slices.length - 1).fill(0);
        let totalArea = 0, currRange = {min: Infinity, max: -Infinity};
        let yCoord, xCoord;

        for (let iy = 0; iy < geometry.ySegmentsCount; iy++) {
            yCoord = iy * geometry.segmentSizeInMeters;

            p10.set(0, yCoord, geometry.readElevationAtInds(0, iy));
            p11.set(0, yCoord + geometry.segmentSizeInMeters, geometry.readElevationAtInds(0, iy + 1));
            if (!isIdentity) {
                p10.applyMatrix4(wm);
                p11.applyMatrix4(wm);
            }

            for (let ix = 0; ix < geometry.xSegmentsCount; ix++) {
                xCoord = ix * geometry.segmentSizeInMeters;

                p00.copy(p10);
                p01.copy(p11);

                p10.set(xCoord + geometry.segmentSizeInMeters, yCoord, geometry.readElevationAtInds(ix + 1, iy));
                p11.set(
                    xCoord + geometry.segmentSizeInMeters, 
                    yCoord + geometry.segmentSizeInMeters, 
                    geometry.readElevationAtInds(ix + 1, iy + 1)
                );

                if (Number.isNaN(p00.z + p01.z + p10.z + p11.z)) {
                    continue;
                }

                if (!isIdentity) {
                    p10.applyMatrix4(wm);
                    p11.applyMatrix4(wm);
                }

                c.x = xCoord + halfSegmentSize;
                c.y = yCoord + halfSegmentSize;
                c.z = 0.25 * (p00.z + p01.z + p10.z + p11.z);

                totalArea += TerrainMetricsRegular.updateHeightMetricsByTriangle(p00, p10, c, palette, perSliceArea, currRange);
                totalArea += TerrainMetricsRegular.updateHeightMetricsByTriangle(p10, p11, c, palette, perSliceArea, currRange);
                totalArea += TerrainMetricsRegular.updateHeightMetricsByTriangle(p11, p01, c, palette, perSliceArea, currRange);
                totalArea += TerrainMetricsRegular.updateHeightMetricsByTriangle(p01, p00, c, palette, perSliceArea, currRange);
            }
        }

        return new TerrainMetrics(
            palette,
            perSliceArea,
            totalArea,
            currRange
        );
    }

    static updateHeightMetricsByTriangle(p1: Vector3, p2: Vector3, p3: Vector3, 
        palette: TerrainMetricsRanges, perSliceArea: number[], currRange: {min: number, max: number}
    ) {
        const area = calcTriangleAreaByPoints(p1, p2, p3);

        if (Number.isNaN(area)) {
            return 0;
        }

        const subTri1Area = TerrainMetricsRegular.tryCalcSubAreaAndUpdate(p1.z, p2.z, p3.z, area, palette.slices, perSliceArea);
        const subTri2Area = TerrainMetricsRegular.tryCalcSubAreaAndUpdate(p2.z, p1.z, p3.z, area, palette.slices, perSliceArea);
        const subTri3Area = TerrainMetricsRegular.tryCalcSubAreaAndUpdate(p3.z, p1.z, p2.z, area, palette.slices, perSliceArea);
        const areaRemaining = area - subTri1Area - subTri2Area - subTri3Area;

        if (areaRemaining > 1e-6) {
            let p: Vector3;
            if (subTri1Area === 0) {
                p = p1;
            } else if (subTri2Area === 0) { 
                p = p2;
            } else if (subTri3Area === 0) { 
                p = p3;
            } else {
                return area;
            }
            const slicesIndices = paletteSlicesIndicesFromValue(p.z, palette.slices);
            if (slicesIndices instanceof Array) {    
                perSliceArea[slicesIndices[0]] += 0.5 * areaRemaining;
                perSliceArea[slicesIndices[1]] += 0.5 * areaRemaining;
            } else if (slicesIndices >= 0) {
                perSliceArea[slicesIndices] += areaRemaining;
            }
        }

        const bottomZ = Math.min(p1.z, p2.z, p3.z);
        if (bottomZ < currRange.min) {
            currRange.min = bottomZ;
        }

        const topZ = Math.max(p1.z, p2.z, p3.z);
        if (topZ > currRange.max) {
            currRange.max = topZ;
        }

        return area;
    }

    static tryCalcSubAreaAndUpdate(z1: number, z2: number, z3: number, area: number, slices: number[], perSliceArea: number[]): number {
        function checkSlice(): number {
            if (z1 > min) {
                if (z1 < max) {
                    return 0;
                } else if (z1 === max) {
                    if (z2 < z1) {
                        return 0;
                    } else {
                        return +1;
                    }
                } else {
                    return +2;
                }
            } else if (z1 === min) {
                if (z2 > z1) {
                    return 0;
                } else {
                    return -1;
                }
            } else {
                return -2;
            }
        }
        

        if ((z1 <= z2 && z1 >= z3) || (z1 >= z2 && z1 <= z3)) {
            return 0;
        }


        let sliceI = lastCalculatedSliceIndex;
        let min: number, max: number;
        let sliceFlag: number;
        do {
            min = slices[sliceI];
            max = slices[sliceI + 1];
            if (min === undefined || max === undefined) {
                break;
            }

            sliceFlag = checkSlice();
            if (sliceFlag < 0) {
                --sliceI;
            } else if (sliceFlag > 0) {
                ++sliceI;
            }
        } while (sliceFlag < -1 || sliceFlag > 1)
        if (min === undefined || max === undefined) {
            lastCalculatedSliceIndex = 0;
        } else {
            lastCalculatedSliceIndex = sliceI;
        }


        let subArea = 0;

        if (z2 < z1) {
            if (z2 <= min && z3 <= min) {
                subArea = area * (z1 - min) * (z1 - min) / ((z1 - z2) * (z1 - z3));
            }
        } else {
            if (z2 >= max && z3 >= max) {
                subArea = area * (max - z1) * (max - z1) / ((z2 - z1) * (z3 - z1));
            }
        }
        if (min !== undefined && max !== undefined) {
            perSliceArea[sliceI] += subArea;
        }
        

        return subArea;
    }

    static calculateCutFillHeatmapForTileGeos(
        tileSize: number,
        initialGeo: RegularHeightmapGeometry,
        updatedGeo: RegularHeightmapGeometry,
        heatmapSize: number,
    ): PerTileCutFillHeatmap {

        const tilePointsCount = heatmapSize + 1;
        const heatmapStepMeters = tileSize / heatmapSize;
        const samplesCoords: Vector2[] = [];
        {
            for (let iy = 0; iy <= heatmapSize; ++iy) {
                for (let ix = 0; ix <= heatmapSize; ++ix) {
                    const flatIndex = iy * tilePointsCount + ix;
                    const coord = new Vector2(
                        ix * heatmapStepMeters,
                        iy * heatmapStepMeters,
                    );
                    samplesCoords[flatIndex] = coord;
                }
            }
        }

        const originalSamples = initialGeo.sampleInLocalSpace(
            samplesCoords
        );

        const updatedSamples = updatedGeo.sampleInLocalSpace(
            samplesCoords
        );

        const heatmapData = new Float32Array(heatmapSize * heatmapSize);
        const averageAreas = new Float32Array(heatmapSize * heatmapSize);

        let pOr00 = new Vector3(), pOr10 = new Vector3(), pOr01 = new Vector3(), pOr11 = new Vector3();
        let pUd00 = new Vector3(), pUd10 = new Vector3(), pUd01 = new Vector3(), pUd11 = new Vector3();
        let cOr = new Vector3(), cUd = new Vector3();

        for (let iy = 0; iy < heatmapSize; ++iy) {
            for (let ix = 0; ix < heatmapSize; ++ix) {
                const flatIndices = [iy * tilePointsCount + ix, (iy + 1) * tilePointsCount + ix];
                
                const originalSample = [originalSamples[flatIndices[0]], originalSamples[flatIndices[0] + 1],
                    originalSamples[flatIndices[1]], originalSamples[flatIndices[1] + 1]];
                const updatedSample = [updatedSamples[flatIndices[0]], updatedSamples[flatIndices[0] + 1],
                    updatedSamples[flatIndices[1]], updatedSamples[flatIndices[1] + 1]];

                
                if (originalSample[0].distToRealSample < 0.05 && originalSample[2].distToRealSample < 0.05 &&
                    originalSample[1].distToRealSample < 0.05 && originalSample[3].distToRealSample < 0.05 &&
                    updatedSample[0].distToRealSample < 0.05 && updatedSample[2].distToRealSample < 0.05 &&
                    updatedSample[1].distToRealSample < 0.05 && updatedSample[3].distToRealSample < 0.05) {
                    
                    pOr00.set(ix * heatmapStepMeters, iy * heatmapStepMeters, originalSample[0].elevation!);
                    pOr10.set((ix + 1) * heatmapStepMeters, iy * heatmapStepMeters, originalSample[1].elevation!);
                    pOr01.set(ix * heatmapStepMeters, (iy + 1) * heatmapStepMeters, originalSample[2].elevation!);
                    pOr11.set((ix + 1) * heatmapStepMeters, (iy + 1) * heatmapStepMeters, originalSample[3].elevation!);

                    cOr.x = (ix + 0.5) * heatmapStepMeters;
                    cOr.y = (iy + 0.5) * heatmapStepMeters;
                    cOr.z = 0.25 * (pOr00.z + pOr01.z + pOr10.z + pOr11.z);

                    pUd00.set(ix * heatmapStepMeters, iy * heatmapStepMeters, updatedSample[0].elevation!);
                    pUd10.set((ix + 1) * heatmapStepMeters, iy * heatmapStepMeters, updatedSample[1].elevation!);
                    pUd01.set(ix * heatmapStepMeters, (iy + 1) * heatmapStepMeters, updatedSample[2].elevation!);
                    pUd11.set((ix + 1) * heatmapStepMeters, (iy + 1) * heatmapStepMeters, updatedSample[3].elevation!);

                    cUd.x = (ix + 0.5) * heatmapStepMeters;
                    cUd.y = (iy + 0.5) * heatmapStepMeters;
                    cUd.z = 0.25 * (pUd00.z + pUd01.z + pUd10.z + pUd11.z);

                    const diff = cUd.z - cOr.z;
                    const originalArea = calcTriangleAreaByPoints(pOr00, pOr10, cOr) + calcTriangleAreaByPoints(pOr10, pOr11, cOr) + 
                        calcTriangleAreaByPoints(pOr11, pOr01, cOr) + calcTriangleAreaByPoints(pOr01, pOr00, cOr);
                    const updatedArea = calcTriangleAreaByPoints(pUd00, pUd10, cUd) + calcTriangleAreaByPoints(pUd10, pUd11, cUd) + 
                        calcTriangleAreaByPoints(pUd11, pUd01, cUd) + calcTriangleAreaByPoints(pUd01, pUd00, cUd);

                    heatmapData[iy * heatmapSize + ix] = diff;
                    averageAreas[iy * heatmapSize + ix] = 0.5 * (originalArea + updatedArea);
                } else {
                    heatmapData[iy * heatmapSize + ix] = NaN;
                    averageAreas[iy * heatmapSize + ix] = NaN;
                }
            }
        }

        return PerTileCutFillHeatmap.newFromFloats(
            heatmapStepMeters,
            tileSize,
            heatmapSize,
            heatmapData,
            averageAreas
        );
    }
}


let lastCalculatedSliceIndex = 0;