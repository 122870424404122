<script lang="ts">
    import { getContext } from "svelte";
    import NumberPropertyBase from "../../pui/custom-components/NumberPropertyWithOption/NumberPropertyBase.svelte";
    import flash from "../../pui/flash";
    import { VersionedStore } from "src/VersionedStore";
    import { getPileFeaturesWithUndulation, getPileUndulationType, PileFeaturesFlags, PileUndulationType, TrackerBins, UnitsMapper } from "bim-ts";
    import { PUI_PropertyNodeNumber } from "ui-bindings";
    import UndulationPileButton from "./UndulationPileButton.svelte";
    import PilesFeaturesList from "./PilesFeaturesSelector.svelte";

    export let numberProperty: PUI_PropertyNodeNumber;
    export let decimals: number = 3;
    export let pileFeatures: PileFeaturesFlags;
    export let settings: TrackerBins = new TrackerBins({});
    export let onChange: (value: PileFeaturesFlags) => void;

    let div: HTMLElement;

    const unitsMapper = getContext<VersionedStore<UnitsMapper>>("unitsMapper");

    let prevValue = numberProperty.value;
    $: if (prevValue != numberProperty.value) {
        prevValue = numberProperty.value;
        if (div) {
            flash(div);
        }
    }

    $:undulation = getPileUndulationType(pileFeatures) === PileUndulationType.Undulated;

    let clientWidth = 0;
    let inputWidth = 0;
</script>

<div bind:clientWidth={clientWidth} class="pile-type-prop ui-config-property property-row">
    <div class="property-value text-main-dark">
        <div
            bind:clientWidth = {inputWidth}
            class="edit-field-container number-input"
        >
            <NumberPropertyBase
                bind:sourceProperty={numberProperty}
                unitsMapper={$unitsMapper}
                {decimals}
            />
        </div>
        <PilesFeaturesList
            selected={pileFeatures}
            settingsGroup={settings}
            onChange={onChange}
            width={clientWidth}
            offset={-inputWidth-4}
        />
        <div
            class="edit-field-container pile"
        >
            <UndulationPileButton
                active = {undulation}
                onClick = {() => {
                    const updatedFeatures = getPileFeaturesWithUndulation(
                        pileFeatures, 
                        undulation ? PileUndulationType.Rigid : PileUndulationType.Undulated
                    );
                    onChange(updatedFeatures);
                }}
            />
        </div>
    </div>
</div>

<style lang="scss">
    // .pile-type-prop :global(.ui-config-property.property-row .property-value) {
    //     flex: 1;
    //     width: 100%;
    // }
    .pile-type-prop :global(.custom-svelecte .sv-content) {
        padding: 0;
    }
    
    .number-input {
        min-width: 50px;
        max-width: 65px;
    }
    .number-input {
        min-width: 50px;
        max-width: 65px;
    }
    .pile {
        display: flex;
        align-items: center;
        padding: 0 4px;
    }
    .property-value {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
    }
    // .edit-pile {
    //     width: 100%;
    // }
</style>
