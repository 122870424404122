
export interface Project {
    id: number;
    name: string;
    archived: boolean;
    createBy: string;
    updatedAt: string;
    createdAt: string;
    company: string;
    isShared: boolean;
    status: ProjectStatus;
}

export interface PaginatedProjects {
    items: Project[];
    more: boolean;
}

export interface ProjectSearchParams {
    offset?: number,
    size?: number,
    archived?: boolean,
    search?: string,
}

export enum ProjectStatus {
    Active = 'Active',
    Draft = 'Draft',
    Completed = 'Completed',
    Archived = 'Archived',
}
