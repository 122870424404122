import { KrMath, combineHashCodes, type TypedArray } from './KrMath';
import { Vector2 } from './Vector2';
import type { Vector4 } from './Vector4';
import type { Spherical } from './Spherical';
import type { Euler } from './Euler';
import type { Matrix3 } from './Matrix3';
import type { Matrix4 } from './Matrix4';
import { Quaternion} from './Quaternion';

//mostly taken from three.js math lib

export class Vector3 {

	x: number;
	y: number;
	z: number;

	distanceToLine!: (this: Vector3, v1: Vector3, v2: Vector3) => number;
	nearesPointOnLine!: (this: Vector3, v1: Vector3, v2: Vector3, pointOut: Vector3) => void;

	constructor(x: number = 0, y: number = 0, z: number = 0) {
		this.x = x;
		this.y = y;
		this.z = z;
	}
	hash(): number {
		return combineHashCodes(
			combineHashCodes(this.x * 257, this.y * 263),
			this.z * 269,
		);
	}

	toString() {
		return `(${this.x}:${this.y}:${this.z})`;
	}

	static fromVec2(vec2: Vector2) {
		return new Vector3(vec2.x, vec2.y, 0);
	}

	areComponentsEqual(): boolean {
		return this.x === this.y && this.x === this.z;
	}

	isFinite(): boolean {
		return isFinite(this.x) && isFinite(this.y) && isFinite(this.z);
	}

	static allocate(x:number, y:number, z:number) {
		return new Vector3(x, y, z);
	}

	static parseAlloc(x: string, y: string, z: string) {
		return new Vector3(
			parseFloat(x),
			parseFloat(y),
			parseFloat(z),
		)
	}

	static zero(): Vector3 {
		return new Vector3(0.0, 0.0, 0.0);
	}

	static one(): Vector3 {
		return new Vector3(1.0, 1.0, 1.0);
	}

	static fromScalar(scalar: number): Vector3 {
		return new Vector3(scalar, scalar, scalar);
	}

	static fromArray(arr: ArrayLike<number>, offset: number): Vector3 {
		return new Vector3(arr[offset + 0], arr[offset + 1], arr[offset + 2]);
	}

	static arrayFromFlatArray(arr: ArrayLike<number>): Vector3[] {
		if (arr.length % 3 !== 0) {
			throw new Error('krVec3s from array, should be multiple of 3');
		}
		const res: Vector3[] = [];
		for (let i = 0; i < arr.length; i += 3) {
			res.push(new Vector3(
				arr[i + 0],
				arr[i + 1],
				arr[i + 2],
			));
		}
		return res;
	}

	static arrayFromTriples(arr: [number, number, number][]): Vector3[] {
		return arr.map(t => new Vector3(t[0], t[1], t[2]));
	}

	copy(v: Vector3): Vector3 {
		this.x = v.x;
		this.y = v.y;
		this.z = v.z;
		return this;
	}

	copyTo(v: Vector3) {
		v.x = this.x;
		v.y = this.y;
		v.z = this.z;
	}


	clone(): Vector3 {
		return new Vector3(this.x, this.y, this.z);
	}

	set(x: number, y: number, z: number):Vector3 {
		this.x = x;
		this.y = y;
		this.z = z;
		return this;
	}

	setScalar(s: number):Vector3 {
		this.x = s;
		this.y = s;
		this.z = s;
		return this;
	}

	setFromArray(arr: ArrayLike<number> | TypedArray, offset: number):Vector3 {
		this.x = arr[offset + 0];
		this.y = arr[offset + 1];
		this.z = arr[offset + 2];
		return this;
	}

	xy(): Vector2 {
		return new Vector2(this.x, this.y);
	}
	xz(): Vector2 {
		return new Vector2(this.x, this.z);
	}

	multiply(vec: Vector3): Vector3 {
		this.x = this.x * vec.x;
		this.y = this.y * vec.y;
		this.z = this.z * vec.z;
		return this;
	}

	multiplyVectors( a: Vector3, b: Vector3 ) {

		this.x = a.x * b.x;
		this.y = a.y * b.y;
		this.z = a.z * b.z;

		return this;
	}

	static multiply_t(v1: Vector3, v2: Vector3): Vector3 {
		const x = v1.x * v2.x;
		const y = v1.y * v2.y;
		const z = v1.z * v2.z;
		return new Vector3(x, y, z);
	}

	multiplyScalar(scalar:number): Vector3 {
		this.x *= scalar;
		this.y *= scalar;
		this.z *= scalar;
		return this;
	}

	divide(vec: Vector3) {
		this.x = this.x / vec.x;
		this.y = this.y / vec.y;
		this.z = this.z / vec.z;
		return this;
	}

	divideScalar(scalar:number): Vector3 {
		return this.multiplyScalar( 1 / scalar );
	}

	add(vec: Vector3): Vector3 {
		this.x = this.x + vec.x;
		this.y = this.y + vec.y;
		this.z = this.z + vec.z;
		return this;
	}

	addFromVec4(vec: Vector4): Vector3 {
		this.x = this.x + vec.x;
		this.y = this.y + vec.y;
		this.z = this.z + vec.z;
		return this;
	}

	addScaledVector( v:Vector3, s: number ) {
		this.x += v.x * s;
		this.y += v.y * s;
		this.z += v.z * s;
		return this;
	}

	static addVectors(v1: Vector3, v2: Vector3): Vector3 {
		const x = v1.x + v2.x;
		const y = v1.y + v2.y;
		const z = v1.z + v2.z;
		return new Vector3(x, y, z);
	}

	addVectors(v1: Vector3, v2: Vector3): Vector3 {
		this.x = v1.x + v2.x;
		this.y = v1.y + v2.y;
		this.z = v1.z + v2.z;
		return this;
	}

	addScalar(scalar: number) {
		this.x += scalar;
		this.y += scalar;
		this.z += scalar;
		return this;
	}

	sub(vec: Vector3): Vector3 {
		this.x = this.x - vec.x;
		this.y = this.y - vec.y;
		this.z = this.z - vec.z;
		return this;
	}

	static subVectors(v1: Vector3, v2: Vector3, result?: Vector3): Vector3 {
		const x = v1.x - v2.x;
		const y = v1.y - v2.y;
		const z = v1.z - v2.z;
		if (result) {
			result.set(x, y, z);
			return result;
		}
		return new Vector3(x, y, z);
	}

	subVectors( a: Vector3, b: Vector3 ) {

		this.x = a.x - b.x;
		this.y = a.y - b.y;
		this.z = a.z - b.z;

		return this;

	}

	dot(v:Vector3): number {
		return this.x * v.x + this.y * v.y + this.z * v.z;
	}

	dotAbs(v:Vector3): number {
		return Math.abs(this.x * v.x + this.y * v.y + this.z * v.z);
	}

	cross (vec:Vector3): Vector3 {
		const ax = this.x, ay = this.y, az = this.z;
		const bx = vec.x, by = vec.y, bz = vec.z;

		this.x = ay * bz - az * by;
		this.y = az * bx - ax * bz;
		this.z = ax * by - ay * bx;
		return this;
	}

	crossLength(crossWith: Vector3): number {
		const ax = this.x, ay = this.y, az = this.z;
		const bx = crossWith.x, by = crossWith.y, bz = crossWith.z;

		const x = ay * bz - az * by;
		const y = az * bx - ax * bz;
		const z = ax * by - ay * bx;

		return Math.sqrt(x * x + y * y + z * z);
	}

	static crossVectors(v1: Vector3, v2: Vector3): Vector3 {
		return v1.clone().cross(v2);
	}

	applyAbs() {
		this.x = Math.abs(this.x);
		this.y = Math.abs(this.y);
		this.z = Math.abs(this.z);
	}

	lengthSq ():number {
		return this.x * this.x + this.y * this.y + this.z * this.z;
	}

	length (): number {
		return Math.sqrt( this.x * this.x + this.y * this.y + this.z * this.z );
	}

	static distBetween(v1: Vector3, v2: Vector3) {
		const x = v1.x - v2.x;
		const y = v1.y - v2.y;
		const z = v1.z - v2.z;
		return Math.sqrt( x * x + y * y + z * z );
	}

	distanceToSquared (v: Vector3): number {
		const dx = this.x - v.x, dy = this.y - v.y, dz = this.z - v.z;
		return dx * dx + dy * dy + dz * dz;
	}

	distanceTo (v: Vector3) {
		const dx = this.x - v.x, dy = this.y - v.y, dz = this.z - v.z;
		return Math.sqrt(dx * dx + dy * dy + dz * dz);
	}

	xyDistanceToSquared (v: Vector3): number {
		const dx = this.x - v.x, dy = this.y - v.y;
		return dx * dx + dy * dy;
	}

	xyDistanceTo (v: Vector3) {
		const dx = this.x - v.x, dy = this.y - v.y;
		return Math.sqrt(dx * dx + dy * dy);
	}

	normalize(): Vector3 {
		return this.divideScalar( this.length() || 1 );
	}

	normalizeTo(length:number): Vector3 {
		return this.divideScalar( this.length() / length );
	}

	round(): Vector3 {
		this.x = Math.round(this.x);
		this.y = Math.round(this.y);
		this.z = Math.round(this.z);
		return this;
	}

	roundTo(roundTo: number): Vector3 {
		this.x = KrMath.roundTo(this.x, roundTo);
		this.y = KrMath.roundTo(this.y, roundTo);
		this.z = KrMath.roundTo(this.z, roundTo);
		return this;
	}

	ceil(): Vector3 {
		this.x = Math.ceil(this.x);
		this.y = Math.ceil(this.y);
		this.z = Math.ceil(this.z);
		return this;
	}

	floor(): Vector3 {
		this.x = Math.floor(this.x);
		this.y = Math.floor(this.y);
		this.z = Math.floor(this.z);
		return this;
	}

	static arrToArr(vecsArr: Vector3[]): number[] {
		const res = [];
		for (const v of vecsArr) {
			res.push(v.x, v.y, v.z);
		}
		return res;
	}
	static arrToFloatArr(vecsArr: Vector3[]): Float32Array {
		const res = new Float32Array(vecsArr.length * 3);
		for (let i = 0; i < vecsArr.length; ++i) {
			const v = vecsArr[i];
			res[i * 3 + 0] = v.x;
			res[i * 3 + 1] = v.y;
			res[i * 3 + 2] = v.z;
		}
		return res;
	}
	static arrToDoubleArr(vecsArr: Vector3[]): Float64Array {
		const res = new Float64Array(vecsArr.length * 3);
		for (let i = 0; i < vecsArr.length; ++i) {
			const v = vecsArr[i];
			res[i * 3 + 0] = v.x;
			res[i * 3 + 1] = v.y;
			res[i * 3 + 2] = v.z;
		}
		return res;
	}


	YZBasisSwap() {
		const z = this.z;
		this.z = this.y;
		this.y = -z;
	}

	toArray(arr: Array<number> | TypedArray, offset: number): Array<number> | TypedArray {
		arr[offset + 0] = this.x;
		arr[offset + 1] = this.y;
		arr[offset + 2] = this.z;
		return arr;
	}

	asArray(): [number, number, number] {
		return [this.x, this.y, this.z];
	}

	getComponent(i: number): number {
		switch ( i ) {
			case 0: return this.x;
			case 1: return this.y;
			case 2: return this.z;
			default: console.error('vec3:getComp:number is out of range: ', i); return 0;
		}
	}

	setComponent(i: number, value:number): Vector3 {
		switch ( i ) {
			case 0: this.x = value; break;
			case 1: this.y = value; break;
			case 2: this.z = value; break;
			default: console.error('vec3:setComp:number is out of range: ', i);
		}
		return this;
	}

	min(v: Vector3): Vector3 {
		this.x = Math.min( this.x, v.x );
		this.y = Math.min( this.y, v.y );
		this.z = Math.min( this.z, v.z );
		return this;
	}

	max(v: Vector3): Vector3 {
		this.x = Math.max( this.x, v.x );
		this.y = Math.max( this.y, v.y );
		this.z = Math.max(this.z, v.z);
		return this;
	}


	minComponent(): number {
		let min = this.x;
		if (this.y < min) {
			min = this.y;
		}
		if (this.z < min) {
			min = this.z;
		}
		return min;
	}

	maxComponent(): number {
		let max = this.x;
		if (this.y > max) {
			max = this.y;
		}
		if (this.z > max) {
			max = this.z;
		}
		return max;
	}

	minComponentIndex(): number {
		let min = 0;
		if (this.y < this.x) {
			min = 1;
		}
		if ((this.z < this.x) && (this.z < this.y)) {
			min = 2;
		}
		return min;
	}

	minAbsComponentIndex(): number {
		let number = 0;
		if (Math.abs(this.y) < Math.abs(this.x)) {
			number = 1;
		}
		if (Math.abs(this.z) < Math.abs(this.y) && Math.abs(this.z) < Math.abs(this.x)) {
			number = 2;
		}
		return number;
	}

	maxComponentIndex(): number {
		let max = 0;
		if (this.y > this.x) {
			max = 1;
		}
		if ((this.z > this.x) && (this.z > this.y)) {
			max = 2;
		}
		return max;
	}

	maxAbsComponentIndex(): number {
		let number = 0;
		if (Math.abs(this.y) > Math.abs(this.x)) {
			number = 1;
		}
		if (Math.abs(this.z) > Math.abs(this.y) && Math.abs(this.z) > Math.abs(this.x)) {
			number = 2;
		}
		return number;
	}

	equals(v: Vector3): boolean {
		return this.distanceToSquared(v) < 0.0001;
	}

	static equal(v1: Vector3, v2: Vector3): boolean {
		return v1.distanceToSquared(v2) < 0.0001;
	}

	setFromSphericalCoords(radius:number, phi:number, theta:number): Vector3 {
		const sinPhiRadius = Math.sin( phi ) * radius;
		this.x = sinPhiRadius * Math.sin( theta );
		this.y = -sinPhiRadius * Math.cos( theta );
		this.z = Math.cos( phi ) * radius;
		return this;
	}

	applyMatrix4(m: Matrix4):Vector3 {
		const x = this.x, y = this.y, z = this.z;
		const e = m.elements;
		const w = 1 / ( e[ 3 ] * x + e[ 7 ] * y + e[ 11 ] * z + e[ 15 ] );

		this.x = ( e[ 0 ] * x + e[ 4 ] * y + e[ 8 ] * z + e[ 12 ] ) * w;
		this.y = ( e[ 1 ] * x + e[ 5 ] * y + e[ 9 ] * z + e[ 13 ] ) * w;
		this.z = ( e[ 2 ] * x + e[ 6 ] * y + e[ 10 ] * z + e[ 14 ] ) * w;
		return this;
	}

	applyMatrix4Rotation(m: Matrix4):Vector3 {
		const x = this.x, y = this.y, z = this.z;
		const e = m.elements;

		this.x = e[ 0 ] * x + e[ 4 ] * y + e[ 8 ] * z;
		this.y = e[ 1 ] * x + e[ 5 ] * y + e[ 9 ] * z;
		this.z = e[ 2 ] * x + e[ 6 ] * y + e[ 10] * z;
		return this;
	}


	applyQuaternion(q: Quaternion) {
		const x = this.x, y = this.y, z = this.z;
		const qx = q.x, qy = q.y, qz = q.z, qw = q.w;

		// calculate quat * vector
		const ix = qw * x + qy * z - qz * y;
		const iy = qw * y + qz * x - qx * z;
		const iz = qw * z + qx * y - qy * x;
		const iw = - qx * x - qy * y - qz * z;

		// calculate result * inverse quat
		this.x = ix * qw + iw * - qx + iy * - qz - iz * - qy;
		this.y = iy * qw + iw * - qy + iz * - qx - ix * - qz;
		this.z = iz * qw + iw * - qz + ix * - qy - iy * - qx;

		return this;
	}

	applyInverseOfQuaternion(q: Quaternion) {
		const x = this.x, y = this.y, z = this.z;
		const qx = -q.x, qy = -q.y, qz = -q.z, qw = q.w;

		// calculate quat * vector
		const ix = qw * x + qy * z - qz * y;
		const iy = qw * y + qz * x - qx * z;
		const iz = qw * z + qx * y - qy * x;
		const iw = - qx * x - qy * y - qz * z;

		// calculate result * inverse quat
		this.x = ix * qw + iw * - qx + iy * - qz - iz * - qy;
		this.y = iy * qw + iw * - qy + iz * - qx - ix * - qz;
		this.z = iz * qw + iw * - qz + ix * - qy - iy * - qx;

		return this;
	}

	transformDirection(m: Matrix4):Vector3 {
		const x = this.x, y = this.y, z = this.z;
		const e = m.elements;

		this.x = e[ 0 ] * x + e[ 4 ] * y + e[ 8 ] * z;
		this.y = e[ 1 ] * x + e[ 5 ] * y + e[ 9 ] * z;
		this.z = e[ 2 ] * x + e[ 6 ] * y + e[ 10 ] * z;
		return this.normalize();
	}

	setFromMatrixColumn(m: Matrix4, number: number) {
		return this.setFromArray( m.elements, number * 4 );
	}

	setFromMatrix3Column(m: Matrix3, number: number) {
		return this.setFromArray( m.elements, number * 3 );
	}

	setSigns() {
		this.x = Math.sign(this.x);
		this.y = Math.sign(this.y);
		this.z = Math.sign(this.z);
	}

	lerpTo(v: Vector3, t: number): Vector3 {
		this.x += ( v.x - this.x ) * t;
		this.y += ( v.y - this.y ) * t;
		this.z += (v.z - this.z) * t;
		return this;
	}

	clamp(min: Vector3, max: Vector3) {
		this.x = Math.max( min.x, Math.min( max.x, this.x ) );
		this.y = Math.max( min.y, Math.min( max.y, this.y ) );
		this.z = Math.max( min.z, Math.min( max.z, this.z ) );
		return this;
	}

	applyEuler( euler: Euler ) {

		return this.applyQuaternion( _qReused.setFromEuler( euler ) );

	}

	applyAxisAngle( axis: Vector3, angle: number ) {

		return this.applyQuaternion( _qReused.setFromAxisAngle( axis, angle ) );

	}

	applyMatrix3( m: Matrix3 ) {

		const x = this.x, y = this.y, z = this.z;
		const e = m.elements;

		this.x = e[ 0 ] * x + e[ 3 ] * y + e[ 6 ] * z;
		this.y = e[ 1 ] * x + e[ 4 ] * y + e[ 7 ] * z;
		this.z = e[ 2 ] * x + e[ 5 ] * y + e[ 8 ] * z;

		return this;

	}

	applyNormalMatrix( m: Matrix3 ) {

		return this.applyMatrix3( m ).normalize();

	}

	project( camera: any ) {

		return this.applyMatrix4( camera.matrixWorldInverse ).applyMatrix4( camera.projectionMatrix );

	}

	unproject( camera: any ) {

		return this.applyMatrix4( camera.projectionMatrixInverse ).applyMatrix4( camera.matrixWorld );

	}

	clampScalar( minVal: number, maxVal: number ) {

		this.x = Math.max( minVal, Math.min( maxVal, this.x ) );
		this.y = Math.max( minVal, Math.min( maxVal, this.y ) );
		this.z = Math.max( minVal, Math.min( maxVal, this.z ) );

		return this;

	}

	clampLength( min: number, max: number ) {

		const length = this.length();

		return this.divideScalar( length || 1 ).multiplyScalar( Math.max( min, Math.min( max, length ) ) );

	}

	roundToZero() {

		this.x = ( this.x < 0 ) ? Math.ceil( this.x ) : Math.floor( this.x );
		this.y = ( this.y < 0 ) ? Math.ceil( this.y ) : Math.floor( this.y );
		this.z = ( this.z < 0 ) ? Math.ceil( this.z ) : Math.floor( this.z );

		return this;

	}

	negate() {

		this.x = - this.x;
		this.y = - this.y;
		this.z = - this.z;

		return this;

	}


	manhattanLength() {

		return Math.abs( this.x ) + Math.abs( this.y ) + Math.abs( this.z );

	}

	setLength( length: number ) {

		return this.normalize().multiplyScalar( length );

	}

	lerp( v: Vector3, alpha: number ) {

		this.x += ( v.x - this.x ) * alpha;
		this.y += ( v.y - this.y ) * alpha;
		this.z += ( v.z - this.z ) * alpha;

		return this;

	}

	lerpVectors( v1: Vector3, v2: Vector3, alpha:number ) {

		this.x = v1.x + ( v2.x - v1.x ) * alpha;
		this.y = v1.y + ( v2.y - v1.y ) * alpha;
		this.z = v1.z + ( v2.z - v1.z ) * alpha;

		return this;

	}

	crossVectors( a: Vector3, b: Vector3 ) {

		const ax = a.x, ay = a.y, az = a.z;
		const bx = b.x, by = b.y, bz = b.z;

		this.x = ay * bz - az * by;
		this.y = az * bx - ax * bz;
		this.z = ax * by - ay * bx;

		return this;

	}

	projectOnVector( v: Vector3 ) {

		const denominator = v.lengthSq();

		if ( denominator === 0 ) return this.set( 0, 0, 0 );

		const scalar = v.dot( this ) / denominator;

		return this.copy( v ).multiplyScalar( scalar );

	}

	projectOnPlane( planeNormal: Vector3 ) {

		_v.copy( this ).projectOnVector( planeNormal );

		return this.sub( _v );

	}

	reflect( normal: Vector3 ) {

		// reflect incident vector off plane orthogonal to normal
		// normal is assumed to have unit length

		return this.sub( _v.copy( normal ).multiplyScalar( 2 * this.dot( normal ) ) );

	}

	angleTo( v: Vector3 ) {

		const denominator = Math.sqrt( this.lengthSq() * v.lengthSq() );

		if ( denominator === 0 ) return Math.PI / 2;

		const theta = this.dot( v ) / denominator;

		// clamp, to handle numerical problems

		return Math.acos( KrMath.clamp( theta, - 1, 1 ) );

	}

	static angleBetweenSegments(p1: Vector3, pCommon: Vector3, p2: Vector3) {
		_v.copy(p1).sub(pCommon);
		_v2.copy(p2).sub(pCommon);
		return _v.angleTo(_v2);
	}

	static angleBetweenVectorsOnXY(v1: Vector3, v2: Vector3) {
		const dotProduct = v1.x * v2.x + v1.y * v2.y;
		const magnitudeV1 = Math.sqrt(v1.x * v1.x + v1.y * v1.y);
		const magnitudeV2 = Math.sqrt(v2.x * v2.x + v2.y * v2.y);
	
		// Ensure the denominator is not zero
		if (magnitudeV1 === 0 || magnitudeV2 === 0) {
			return 0; // Angle is undefined
		}
	
		const cosAngle = dotProduct / (magnitudeV1 * magnitudeV2);
		const angleRadians = Math.acos(cosAngle);
	
		return angleRadians;
	}

	manhattanDistanceTo( v: Vector3 ) {

		return Math.abs( this.x - v.x ) + Math.abs( this.y - v.y ) + Math.abs( this.z - v.z );

	}

	setFromSpherical( s: Spherical ) {

		return this.setFromSphericalCoords( s.radius, s.phi, s.theta );

	}


	setFromMatrixPosition( m: Matrix4 ) {

		const e = m.elements;

		this.x = e[ 12 ];
		this.y = e[ 13 ];
		this.z = e[ 14 ];

		return this;

	}

	setFromMatrixScale( m: Matrix4 ) {

		const sx = this.setFromMatrixColumn( m, 0 ).length();
		const sy = this.setFromMatrixColumn( m, 1 ).length();
		const sz = this.setFromMatrixColumn( m, 2 ).length();

		this.x = sx;
		this.y = sy;
		this.z = sz;

		return this;

	}

	fromArray( array: number[], offset = 0 ) {

		this.x = array[ offset ];
		this.y = array[ offset + 1 ];
		this.z = array[ offset + 2 ];

		return this;

	}


	fromBufferAttribute( attribute: any, index: number ) {

		this.x = attribute.getX( index );
		this.y = attribute.getY( index );
		this.z = attribute.getZ( index );

		return this;

	}

	random() {

		this.x = Math.random();
		this.y = Math.random();
		this.z = Math.random();

		return this;

	}

	static asString(v: Vector3): string {
		return `[${v.x},${v.y},${v.z}]`;
	}

}
Object.defineProperty(
	Vector3.prototype,
	'isVector3',
	{
		enumerable: false,
		configurable: false,
		value: true,
		writable: false
	}
);

Vector3.prototype.distanceToLine = function () {
	const diff1 = Vector3.zero();
	const diff2 = Vector3.zero();
    // http://mathworld.wolfram.com/Point-LineDistance3-Dimensional.html

	return function(this: Vector3, v1: Vector3, v2: Vector3): number {
		diff1.copy(this).sub(v1);
		diff2.copy(v2).sub(v1);
		diff1.cross(diff2);
		return diff1.length() / diff2.length();
	}
}();

Vector3.prototype.nearesPointOnLine = function () {
	const lineDir = Vector3.zero();
	const v = Vector3.zero();
    // http://mathworld.wolfram.com/Point-LineDistance3-Dimensional.html

	return function (this: Vector3, v1: Vector3, v2: Vector3, pointOut: Vector3) {
		lineDir.copy(v2).sub(v1).normalizeTo(1);
		v.copy(this).sub(v1);
		const d = v.dot(lineDir);
		pointOut.copy(v1);
		if (Math.abs(d) > 0) {
			lineDir.multiplyScalar(d);
			pointOut.add(lineDir);
		}
	}
}();

const _qReused = new Quaternion();

const _v = /*@__PURE__*/ new Vector3();
const _v2 = /*@__PURE__*/ new Vector3();


export const Vec3Zero = /*@__PURE__*/ Object.freeze(new Vector3(0.0, 0.0, 0.0));
export const Vec3One = /*@__PURE__*/ Object.freeze(new Vector3(1.0, 1.0, 1.0));

export const Vec3X = /*@__PURE__*/ Object.freeze(new Vector3(1.0, 0.0, 0.0));
export const Vec3Y = /*@__PURE__*/ Object.freeze(new Vector3(0.0, 1.0, 0.0));
export const Vec3Z = /*@__PURE__*/ Object.freeze(new Vector3(0.0, 0.0, 1.0));


export const Vec3XNeg = /*@__PURE__*/ Object.freeze(new Vector3(-1., 0.0, 0.0));
export const Vec3YNeg = /*@__PURE__*/ Object.freeze(new Vector3(0.0, -1., 0.0));
export const Vec3ZNeg = /*@__PURE__*/ Object.freeze(new Vector3(0.0, 0.0, -1.));
