<svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <circle cx="12.5" cy="4.5" r="2" fill="#E72C03" />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.26157 11.5442V12.874H13.7478V11.5442H12.2797V7.49204C11.7956 7.4569 11.3433 7.30687 10.95 7.06908V11.5442H5.06667V6.70803C5.06667 5.88581 5.35278 5.18581 5.925 4.60803C6.49722 4.03025 7.19444 3.74136 8.01667 3.74136C8.57751 3.74136 9.0789 3.87577 9.52083 4.14459C9.57673 3.67101 9.74294 3.2312 9.99307 2.85158C9.71446 2.71761 9.4146 2.61555 9.09348 2.5454V2.11308C9.09348 1.81043 8.98625 1.55928 8.77178 1.35963C8.5573 1.16 8.30004 1.06018 8 1.06018C7.69995 1.06018 7.44269 1.16 7.22822 1.35963C7.01375 1.55928 6.90652 1.81043 6.90652 2.11308V2.5454C5.94999 2.75574 5.18417 3.2545 4.60905 4.04168C4.03393 4.82886 3.74637 5.71764 3.74637 6.70803V11.5442H2.26157ZM7.01508 14.4884C7.29762 14.7643 7.62749 14.9022 8.00472 14.9022C8.39046 14.9022 8.72246 14.7654 9.00072 14.4917C9.27898 14.2181 9.41812 13.8853 9.41812 13.4935H6.5913C6.5913 13.8809 6.73256 14.2126 7.01508 14.4884Z"
        fill="#101314"
        fill-opacity="0.69"
    />
</svg>
