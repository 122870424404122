import type { AssetId, Catalog, CatalogItem, CatalogItemId,
    CatalogItemUiLabels, PropertiesGroupFormatters, PropertiesStringTemplate} from 'bim-ts';
import {
    Asset, AssetCatalogItemTypeIdentifier, CatalogSource, getAssetGeneratedName, InverterMandatoryProps, InverterTypeIdent,
    LvWireSpecCatalogItemTypeIdent, MvWireSpecCatalogItemTypeIdent, parseOndToInverter,
    parsePanToPVModule, PvModuleMandatoryProps, PVModuleTypeIdent, stringifyProps
} from 'bim-ts';
import {
    Failure, ObjectUtils, PollablePromise, ScopedLogger, Success, VersionedInvalidator
} from 'engine-utils-ts';
import { NotificationDescription, NotificationType } from 'ui-bindings';
import { VerDataSyncStatus } from 'verdata-ts';

import { ListGroup, ListInput } from '../libui/list';
import { notificationSource } from '../Notifications';
import { importPanOndFile } from './importPanOndFile';

import type { UiBindings } from 'ui-bindings';
import type { Result, LazyVersioned, ProjectNetworkClient, Yield, PollWithVersionResult } from "engine-utils-ts";
import type { CreateContextMenuFn } from "./types";
export interface CatalogItemView {
    id: CatalogItemId,
    catalogItem: CatalogItem,
    catalogItemUiInfo: CatalogItemUiLabels;
}

export class CatalogItemGroupView {
    constructor(
        public name: string = '',
        public items: CatalogItemView[] = [],
        public enabled: boolean = true,
    ) {}
}

export class CatalogItemManagerView {
    constructor(
        public groups: CatalogItemGroupView[] = [],
    ) {}
}

export class CatalogItemManagerHierarchy {
    constructor(
        public children: Map<PropertiesStringTemplate, CatalogItemManagerHierarchy>
            = new Map(),
        public items: Array<CatalogItemView> = [],
        public path: PropertiesStringTemplate[] = [],
        public allItems: Array<CatalogItemView> = [],
        public hide: boolean = false,
    ) {}

    getChild(searchKey: PropertiesStringTemplate): CatalogItemManagerHierarchy | null{
        for (const [key, value] of this.children) {
            const areKeysEqual = ObjectUtils.areObjectsEqual(searchKey, key);
            if (areKeysEqual) {
                return value;
            }
        }
        return null;
    }

    hideAll() {
        this.hide = true;
        for (const child of this.children.values()) {
            child.hideAll()
        }
    }

}


export class CatalogItemManager implements LazyVersioned<CatalogItemManager> {
    search = '';
    invalidator = new VersionedInvalidator();
    disabledGroups = new Set<string>();
    catalogItemEditMode: CatalogItemView | null = null
    empty: boolean = false;
    syncStatus: string = "";
    logger: ScopedLogger = new ScopedLogger('CatalogItemManager');
    constructor(
        public catalog: Catalog,
        public uiBindings: UiBindings,
        public siLabelFormatter: PropertiesGroupFormatters,
        public createExtraMenuForAsset?: CreateContextMenuFn,
        public itemDoubleClickAction?: (assetId: AssetId) => void,
    ) {}
    view: CatalogItemManagerHierarchy = new CatalogItemManagerHierarchy();
    allItems: Map<CatalogItemId, CatalogItemView> = new Map();
    visibilityPaths: Array<PropertiesStringTemplate[]> = [];
    visibilityPathReverse: boolean = false;
    pollWithVersion(): PollWithVersionResult<Readonly<CatalogItemManager>> {
        return {
            version: this.version(),
            value: this.poll(),
        };
    }
    changeSearch(newVal: string) {
        if (this.search === newVal) {
            return;
        }
        this.search = newVal
        this.invalidator.invalidate();
    }
    toggleHierarchyVisibility(path: PropertiesStringTemplate[]) {
        this.invalidator.invalidate();
        const index = this.visibilityPaths.findIndex(x => ObjectUtils.areObjectsEqual(x, path));
        const isHidden = index < 0;
        if (isHidden) {
            this.visibilityPaths.push(path);
            return;
        }
        this.visibilityPaths.splice(index, 1);
    }
    hideAll() {
        this.invalidator.invalidate();
        this.visibilityPathReverse = false;
        this.visibilityPaths = [];
    }
    expandAll() {
        this.invalidator.invalidate();
        this.visibilityPathReverse = true;
        this.visibilityPaths = [];
    }
    poll(): Readonly<CatalogItemManager> {
        const status = this.catalog.getSyncer().status.poll().syncStatus;
        if (status === VerDataSyncStatus.Loaded) {
            this.syncStatus = "";
        } else {
            this.syncStatus = VerDataSyncStatus[status];
        }
        const searchTerms = this.search.toLowerCase().split(' ');
        const groups: Map<string, CatalogItemGroupView> = new Map();
        let cnt = 0;
        this.allItems.clear();
        const infos: Array<CatalogItemView> = [];
        const supportedTypes = new Set([
            AssetCatalogItemTypeIdentifier,
            LvWireSpecCatalogItemTypeIdent,
            MvWireSpecCatalogItemTypeIdent,
        ])
        for (const [id, catalogItem] of this.catalog.catalogItems.perId) {
            if (!supportedTypes.has(catalogItem.typeIdentifier)) {
                continue;
            }
            cnt++;
            const searchComps: string[] = [];
            searchComps.push(catalogItem.typeIdentifier);

            const uiInfo = this.catalog.catalogItemsUiLabels.solve(
                catalogItem.typeIdentifier,
                catalogItem.properties,
            );
            if (uiInfo === null) continue;

            if (uiInfo.title) {
                searchComps.push(uiInfo.title);
            }
            searchComps.push(...uiInfo.group.map(x => stringifyProps(x, this.catalog.unitsMapper)))

            const doSearchOnStr = searchComps.join(' ').toLowerCase();

            if (!searchTerms.every(x => doSearchOnStr.includes(x))) {
                continue;
            }

            //// add to group
            //const groupName = uiInfo.group ?? catalogItem.typeIdentifier;
            //const group = groups.get(groupName) ?? new CatalogItemGroupView(
            //    groupName,
            //    [],
            //    !this.disabledGroups.has(groupName),
            //);
            //groups.set(groupName, group);
            const itemWithIdAndTitle: CatalogItemView = {
                id,
                catalogItem,
                catalogItemUiInfo: uiInfo,
            }
            //group.items.push(itemWithIdAndTitle);
            this.allItems.set(id, itemWithIdAndTitle);
            infos.push(itemWithIdAndTitle);
        }
        this.empty = cnt === 0;
        const hierarchy = buildHierarchy(infos);

        if (!this.search.length) {
            if (!this.visibilityPathReverse) {
                hierarchy.hideAll();
            }
            // manage hide/show groups
            outer: for (const visiblePath of this.visibilityPaths) {
                let node = hierarchy;
                for (const comp of visiblePath) {
                    const next = node.getChild(comp);
                    if (!next) {
                        continue outer;
                    }
                    node = next;
                }
                node.hide = this.visibilityPathReverse;
            }
        }

        this.view = hierarchy;

        if (this.empty) {
            this.cancelEditMode();
        }
        return this;
    }

    version(): number {
        return this.catalog.invalidator.version()
            + this.invalidator.version()
            + this.catalog.getSyncer().status.version();
    }


    cancelEditMode() {
        this.catalogItemEditMode = null;
        this.invalidator.invalidate();
    }

    setCatalogItemInEditMode(id: CatalogItemId) {
        const catalogItem = this.catalog.catalogItems.perId.get(id)
        if (!catalogItem) {
            return;
        }
        this.catalogItemEditMode = this.allItems.get(id) ?? null;
        if (this.catalogItemEditMode) {
            this.invalidator.invalidate();
        }
    }

    generateListInputFromCatalogItemManagerView() {
        const listGroup = new ListGroup([]);
        return new ListInput(listGroup);
    }

    *importAssetsFromFS(files: FileList) {
        const assetsAdded: Asset[] = [];
        for (let i = 0; i < files.length; i++) {
            const file = files.item(i);
            if (!file) {
                continue;
            }
            const isError = <T>(result: Result<T>): result is Failure => {
                if(result instanceof Failure){
                    this.uiBindings.addNotification(NotificationDescription.newBasic({
                        source: notificationSource,
                        key: 'cantParsePanOndFile',
                        descriptionArg: [file.name, result.errorMsg()],
                        type: NotificationType.Error,
                        addToNotificationsLog: true
                    }));
                    this.logger.error("can't parse file " + file.name, result.errorMsg());
                    return true;
                }
                return false;
            }
            const filename = file.name.toLowerCase();
            let binary: Uint8Array
            if (file.size / 1000 / 1000 > 1) {
              this.uiBindings.addNotification(NotificationDescription.newBasic({
                  type: NotificationType.Error,
                  source: notificationSource,
                  key: 'bigFile',
                  descriptionArg: file.name,
                  removeAfterMs: 3000,
                  addToNotificationsLog: true
              }));
              return;
            }
            if (filename.includes('.ond')) {
                const inventer = yield* parseOndFile(file, this.catalog.baseNetwork, this.uiBindings, this.logger);
                if(isError(inventer)){
                    continue;
                }
                binary = inventer.value;
            } else if (filename.includes('.pan')) {
                const pvModule = yield* parsePanFile(file, this.catalog.baseNetwork, this.uiBindings, this.logger);
                if(isError(pvModule)){
                    continue;
                }
                binary = pvModule.value;
            } else if (filename.includes('.bimasset')) {
                const row = yield* PollablePromise.generatorWaitFor(file.arrayBuffer())
                if (row instanceof Failure) {
                    continue;
                }
                binary = new Uint8Array(row.value);
            } else {
                this.logger.error('file', file.name, 'has unsupported format')
                continue;
            }
            const asset: Asset = new Asset(
                '',
                new CatalogSource('user'),
                binary,
            );
            this.catalog.assets.allocate([[
                this.catalog.assets.reserveNewId(),
                asset
            ]]);
            assetsAdded.push(asset);
        }
        if (assetsAdded.length) {
            const asset = assetsAdded[0];
            const name = yield* getAssetGeneratedName(asset, this.siLabelFormatter);

            if (name) {
                window.dispatchEvent(new SetCatalogSearchEvent(name));
                this.uiBindings.addNotification(NotificationDescription.newBasic({
                    source: notificationSource,
                    key: 'fileImportedToCatalog',
                    type: NotificationType.Success,
                    descriptionArg: name,
                    addToNotificationsLog: true,
                }));
            }
        }
    }

}

function* parsePanFile(file: File, network: ProjectNetworkClient, uiBindings: UiBindings, logger: ScopedLogger): Generator<Yield, Result<Uint8Array>, unknown>{
    const row = yield* PollablePromise.generatorWaitFor(file.arrayBuffer());
    if (row instanceof Failure) {
        return new Failure({msg: row.errorMsg()});
    }
    const fileAsString = new TextDecoder().decode(row.value);
    const pvModuleResult = parsePanToPVModule(fileAsString);
    if(pvModuleResult instanceof Failure){
        return new Failure({msg: pvModuleResult.errorMsg()});
    }
    const pvModule = pvModuleResult.value;

    const assetResult = yield* importPanOndFile(PVModuleTypeIdent, file.name, pvModule, network, uiBindings, PvModuleMandatoryProps, logger);
    if (assetResult instanceof Failure) {
        return new Failure({msg: assetResult.errorMsg()});
    }

    return new Success(assetResult.value);
}

function* parseOndFile(file: File, network: ProjectNetworkClient, uiBindings: UiBindings, logger: ScopedLogger): Generator<Yield, Result<Uint8Array>, unknown>{
    const row = yield* PollablePromise.generatorWaitFor(file.arrayBuffer());
    if (row instanceof Failure) {
        return new Failure({msg: row.errorMsg()});
    }
    const fileAsString = new TextDecoder().decode(row.value);
    const inverterResult = parseOndToInverter(fileAsString);

    if(inverterResult instanceof Failure){
        return new Failure({msg: inverterResult.errorMsg()});
    }
    const inverter = inverterResult.value;

    const assetResult = yield* importPanOndFile(InverterTypeIdent, file.name, inverter, network, uiBindings, InverterMandatoryProps, logger);
    if (assetResult instanceof Failure) {
        return new Failure({msg: assetResult.errorMsg()});
    }

    return new Success(assetResult.value);
}

function buildHierarchy(items: Array<CatalogItemView>) {
    const hierarchy = new CatalogItemManagerHierarchy();
    for (const item of items) {
        // traverse group
        let node = hierarchy;
        const path: PropertiesStringTemplate[] = [];
        for (const groupComp of item.catalogItemUiInfo.group) {
            path.push(groupComp);
            const existingChild = node.getChild(groupComp);
            if (existingChild) {
                existingChild.allItems.push(item);
                node = existingChild;
                continue;
            }
            const newChild = new CatalogItemManagerHierarchy();
            newChild.allItems.push(item);
            newChild.path = path.slice();
            node.children.set(groupComp, newChild);
            node = newChild;
        }
        node.items.push(item);
    }
    return hierarchy;
}

export const SetCatalogSearchEventTypeIdent = 'SetCatalogSearchEventTypeIdent';
export class SetCatalogSearchEvent extends CustomEvent<any> {
    constructor(public search: string) {
        super(SetCatalogSearchEventTypeIdent);
    }
}
