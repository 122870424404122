<script lang="ts">
    import { getContext } from "svelte";
    import { ButtonComponent, Button, ButtonType } from "../libui/button";
    import EngineUiPanel from "../ui-panels/EngineUiPanel.svelte";
    import { NavbarContext } from "../navbar/Navbar";
    import { FilesExportsHandler } from "./FilesExportsHandler";
    import { IconComponent } from "../libui/icon";
    import { Bim, CostModel, SceneInstanceFlags } from "bim-ts";
    import { VersionedStore } from "../VersionedStore";
    import { LazyVersioned } from "engine-utils-ts";

    const filesExportsHandler = getContext<FilesExportsHandler>(
        "filesExportsHandler",
    );
    const navbar = getContext<NavbarContext>("ui-navbar");
    const bim = getContext("bim") as Bim;
    const bimInstancesSelection = new VersionedStore(
        bim.instances.selectHighlight.getVersionedFlagged(
            SceneInstanceFlags.isSelected,
        ),
    );

    const costModeExportDisabled = new VersionedStore(CostModel.createCostConfigIsEmpty(bim) as LazyVersioned<boolean>);
</script>

<EngineUiPanel>
    <div class="export-panel mdc-typography--body1">
        <span class="mdc-typography--headline5">
            Layout and equipment to a different application
        </span>
        <ul class="format-list">
            <li>
                <ButtonComponent
                    desc={new Button(
                        "DXF for AutoCAD/Civil3D",
                        ButtonType.Text,
                        () => {
                            filesExportsHandler.export("DXF");
                        },
                        false,
                        "Export",
                    )}
                />
                <div>
                    2D (flat) layout with solar arrays, transformers, roads, and
                    boundaries.
                </div>
            </li>
            <li>
                <ButtonComponent
                    desc={new Button(
                        "SHD for PVSyst",
                        ButtonType.Text,
                        () => {
                            filesExportsHandler.export("SHD");
                        },
                        false,
                        "Export",
                    )}
                />
                <div>Solar arrays only in 3D space with slopes and height.</div>
            </li>
        </ul>
        <div class="divider" />
        <span class="mdc-typography--headline5">
            PVFARM internal file formats
        </span>
        <ul class="format-list">
            <li>
                <ButtonComponent
                    desc={new Button(
                        "BIMASSET",
                        ButtonType.Text,
                        () => {
                            filesExportsHandler.export("BIMASSET");
                        },
                        false,
                        "Export",
                    )}
                />
                <div>
                    Move selected objects or whole layout between projects.
                </div>
            </li>
            <li>
                <ButtonComponent
                    desc={new Button(
                        "BIMASSET for catalog ",
                        ButtonType.Text,
                        () => {
                            filesExportsHandler.export("BIMASSET");
                        },
                        $bimInstancesSelection.length !== 1,
                        "Export",
                    )}
                />
                <div>
                    <span>
                        Modify parameters of any equipment, export it, and
                        re-upload to the
                    </span>
                    <span
                        class="guide-button mdc-typography--body1-strong"
                        on:click={() => {
                            navbar.openTab("View.Catalog");
                        }}
                    >
                        <span>Catalog</span>
                        <IconComponent name="OpenWindow" />
                    </span>
                </div>
                <div>
                    <span>Single item should be selected.</span>
                    <span
                        class="guide-button mdc-typography--body1-strong"
                        on:click={() => {
                            window.open(
                                "https://intercom-help.eu/PVFARM/en/articles/89583-how-to-create-new-equipment",
                            );
                        }}
                    >
                        <span>Guide on modifying equipment</span>
                        <IconComponent name="OpenExternal" />
                    </span>
                </div>
            </li>
            <li>
                <ButtonComponent
                    desc={new Button(
                        "PVFARMCOST",
                        ButtonType.Text,
                        () => filesExportsHandler.export("PVFARMCOST"),
                        $costModeExportDisabled,
                        "Export",
                    )}
                />
                <div>
                    Save the cost report settings as a file to overwrite all
                    costs in another project.
                </div>
            </li>
        </ul>
        <div class="divider" />
        <span class="mdc-typography--headline5">
            Terrain with cut fill (grading) applied
        </span>
        <ul class="format-list">
            <li>
                <ButtonComponent
                    desc={new Button(
                        "DEM",
                        ButtonType.Text,
                        () => {
                            filesExportsHandler.export("DEM");
                        },
                        false,
                        "Export",
                    )}
                />
            </li>
            <li>
                <ButtonComponent
                    desc={new Button(
                        "LandXML",
                        ButtonType.Text,
                        () => {
                            filesExportsHandler.export("LandXml");
                        },
                        false,
                        "Export",
                    )}
                />
            </li>
            <li>
                <ButtonComponent
                    desc={new Button(
                        "GeoTIFF",
                        ButtonType.Text,
                        () => {
                            filesExportsHandler.export("GeoTIFF");
                        },
                        false,
                        "Export",
                    )}
                />
            </li>
        </ul>
        <div class="divider" />
        <span class="mdc-typography--headline5">Reports</span>
        <ul class="format-list">
            <li>
                <ButtonComponent
                    desc={new Button(
                        "Project presentation as PDF",
                        ButtonType.Text,
                        () => {
                            filesExportsHandler.export("PDF");
                        },
                        false,
                        "Export",
                    )}
                />
            </li>
            <li>
                <ButtonComponent
                    desc={new Button(
                        "Project metrics as XLSX spreadsheet",
                        ButtonType.Text,
                        () => {
                            filesExportsHandler.export("XLSX");
                        },
                        false,
                        "Export",
                    )}
                />
            </li>
            <li class="disabled-report">
                <ButtonComponent
                    desc={new Button(
                        "Custom report as XLSX spreadsheet",
                        ButtonType.Text,
                        () => {},
                        true,
                        "Export",
                    )}
                />
                <div>
                    <span>Open </span>
                    <span
                        class="guide-button mdc-typography--body1-strong"
                        on:click={() => {
                            navbar.openTab("View.Quantities");
                        }}
                    >
                        <span>Custom reports</span>
                        <IconComponent name="OpenWindow" />
                    </span>
                    <span>
                        , load an existing template or build a new report, and
                        use the export button on the top right corner of the
                        report window.
                    </span>
                </div>
            </li>
            <li>
                <ButtonComponent
                    desc={new Button(
                        "Project piles as CSV spreadsheet",
                        ButtonType.Text,
                        () => {
                            filesExportsHandler.export("CSV");
                        },
                        false,
                        "Export",
                    )}
                />
            </li>
        </ul>
    </div>
</EngineUiPanel>

<style lang="scss">
    .export-panel {
        margin: 12px 16px 0;
        :global(.mdc-button svg) {
            width: 16px;
            height: 16px;
        }
        .format-list {
            margin: 0;
            padding-left: 20px;
            list-style-type: none;
            li {
                margin-top: 8px;
                > div {
                    margin-top: 4px;
                }
            }
            :global(.mdc-button) {
                min-width: auto;
                padding: 0;
                position: relative;
                left: -20px;
                height: 22px;
                --mdc-text-button-label-text-transform: none;
            }
        }
    }
    .guide-button {
        cursor: pointer;
        display: inline-flex;
        gap: 2px;
        align-items: center;
    }
    .divider {
        border-top: 1px solid var(--color-divider);
        margin: 8px 0;
    }
    .disabled-report :global(.mdc-button) {
        --mdc-text-button-disabled-label-text-color: var(--color-main-dark);
    }
</style>
