import { requestExecutionFrame } from 'engine-utils-ts';

export default function flash(element?: HTMLElement) {
	requestExecutionFrame(() => {
		if (!element) {
			return;
		}
		element.style.transition = 'none';
		element.style.color = 'var(--color-good)';
		// element.style.backgroundColor = 'rgba(155,255,155,0.2)';

		setTimeout(() => {
			element.style.transition = 'color 6s, background 2s';
			element.style.color = '';
			// element.style.backgroundColor = '';
		});
	});
}