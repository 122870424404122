import type { SiteArea } from "bim-ts";
import type { ZoneBoundaryProps } from "./EquipmentBoundariesCreator";

export class SelectedAreaData {
    constructor(
        private readonly selectedArea: SiteArea,
        public readonly zoneProps: ZoneBoundaryProps,
    ) {}

    get isBlockingLayout() {
        return this.selectedArea.settings.capacity.number_of_blocks.selectedOption !== 'ignore';
    }
}