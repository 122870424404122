import { RGBA, type ObservableObject } from 'engine-utils-ts';
import { DEG2RAD, KrMath, Matrix4, Plane, Vec3Z, Vector2, Vector3, Vector4 } from 'math-ts';

import type { Texture} from '../3rdParty/three';
import {
	Color, CubeUVReflectionMapping, DataTexture, OrthographicCamera, PerspectiveCamera, RGBEFormat,
	UnsignedByteType,
} from '../3rdParty/three';
import type { ClipBox } from '../clipbox/ClipBox';
import type { StdFrameComposer } from '../frameComposer/StdFrameComposer';
import type { GraphicsSettings } from '../GraphicsSettings';
import type { EngineTerrainPalette, EngineTerrainPaletteSlice } from '../terrain/EngineTerrainPalette';
import { TerrainShaderMaxSlices } from '../terrain/TerrainShader';
import type { TotalBounds } from '../TotalBounds';
import { type EngineLegacyUiUnits } from '../EngineLegacyUiUnits';
import { RaySection } from '../structs/RaySection';
import type { KrCamera } from '../controls/MovementControls';
import { TerrainDisplayMode } from '../TerrainDisplayEngineSettings';
import type { EngineScene } from '../scene/EngineScene';
import { ESO_TerrainHeightmapHandler } from '../esos/ESO_Terrain';

export interface ShaderUniform<T>{ value: T; }

export const ClipboxShaderEnlargment = 2 / 1000;


export class GlobalUniforms {
	
	readonly ghostSideColor: ShaderUniform<Vector4> = { value: Vector4.allocateZero() };
	readonly ghostFrontColor: ShaderUniform<Vector4> = { value: Vector4.allocateZero() };
	readonly ghostEdgeColor: ShaderUniform<Vector4> = { value: Vector4.allocateZero() };

	readonly clipboxMin: ShaderUniform<Vector3> = { value: Vector3.zero() };
	readonly clipboxMax: ShaderUniform<Vector3> = { value: Vector3.zero() };

	readonly totalBoundsMin: ShaderUniform<Vector3> = { value: Vector3.zero() };
	readonly totalBoundsMax: ShaderUniform<Vector3> = { value: Vector3.zero() };

	readonly gridLevelOpacityInterval: ShaderUniform<Vector4> = { value: new Vector4(0, 0.5, 1, 1) };
	readonly gridLevelOpacityIntervalMinor: ShaderUniform<Vector4> = { value: new Vector4(0, 0.5, 1, 1) };
	readonly gridWidth: ShaderUniform<Vector2> = { value: new Vector2(0.1, 0.1) };
	
	readonly snatchedRulerIntervals: ShaderUniform<Vector4> = { value: Vector4.allocate(0.1, 0.05, 0.01, 0.01 * 0.5) };

	readonly selectionSideColor: ShaderUniform<Vector4> = { value: Vector4.allocateZero() };
	readonly selectionFrontColor: ShaderUniform<Vector4> = { value: Vector4.allocateZero() };
	readonly selectionEdgeColor: ShaderUniform<Vector4> = { value: Vector4.allocateZero() };

	readonly highlightSideColor: ShaderUniform<Vector4> = { value: Vector4.allocate(215/255, 235/255, 255/255, 0.08) };
	readonly highlightFrontColor: ShaderUniform<Vector4> = { value: Vector4.allocate(193/255, 228/255, 255/255, 0.08) };
	readonly highlightEdgeColor: ShaderUniform<Vector4> = { value: Vector4.allocate(0.9, 0.9, 1.0, 0.05) };


	readonly terrain = {
		paletteColors: { value: Array(TerrainShaderMaxSlices).fill(0).map((_, index) => new Vector4().setScalar(index / 10)) },
		paletteRanges: { value: Array(TerrainShaderMaxSlices).fill(0).map((_, index) => new Vector2().setScalar(index / 10)) },
		angleToVectorAndSource: {value: new Vector4(0, 1, 0, 0)},
		gridStep: { value: new Vector2(1, 1) }
	};

	readonly resolution: ShaderUniform<Vector2> = { value: new Vector2() };

	readonly envMap: ShaderUniform<Texture>;

	constructor() {
		this.setSelectionColorStd();
		this.envMap = { value: new DataTexture(new Uint8ClampedArray([1, 1, 1, 1]), 1, 1, RGBEFormat, UnsignedByteType, CubeUVReflectionMapping) };
	}
	
	setGhostOpacity(opacity: number) {
		this.ghostSideColor.value.w = opacity;
		this.ghostFrontColor.value.w = opacity;
	}

	setGhostColor(color: Color) {
		const lighter = new Color().copy(color);
		const contrast = new Color().copy(color);
		const opacity = this.ghostFrontColor.value.w;
		if (Math.max(color.r, color.g, color.b) > 0.5) {
			lighter.lerp(new Color(0, 0, 0), 0.5);
		} else {
			lighter.lerp(new Color(1, 1, 1), 0.5);
		}
		this.ghostFrontColor.value.set(lighter.r, lighter.g, lighter.b, opacity);
		this.ghostSideColor.value.set(contrast.r, contrast.g, contrast.b, opacity);
		this.ghostEdgeColor.value.set(lighter.r, lighter.g, lighter.b, 0.06);
	}

	updateUniforms(
		totalBounds: TotalBounds,
		clipbox: ClipBox,
		renderSettings: GraphicsSettings,
		composer: StdFrameComposer,
		terrainSettings: Readonly<EngineTerrainPalette>,
		uiUnits: EngineLegacyUiUnits,
		engineScene: EngineScene
	) {
		const clipboxBounds = clipbox.getBounds();
		this.clipboxMin.value.setFromArray(clipboxBounds.elements, 0).addScalar(-ClipboxShaderEnlargment);
		this.clipboxMax.value.setFromArray(clipboxBounds.elements, 3).addScalar(ClipboxShaderEnlargment);
		this.totalBoundsMin.value.setFromArray(totalBounds.bounds.elements, 0);
		this.totalBoundsMax.value.setFromArray(totalBounds.bounds.elements, 3);
		// this.terrainLinesOpacity.value = renderSettings.terrainHeightSlicesVisibility;
		// this.terrainLinesInterval.value = renderSettings.terrainHeightSlicesInterval;
		const rs = renderSettings;

					
		const [unitBig, unitSmall] = uiUnits.getRulerSnatchIntervals();

		const rulerLineWidth = 0.005;
		if (uiUnits.isImperial()) {
			this.snatchedRulerIntervals.value.set(unitBig, unitBig * 0.5, unitSmall, rulerLineWidth * 2);
		} else {
			this.snatchedRulerIntervals.value.set(unitSmall, unitSmall * 0.5, unitSmall * 0.1, rulerLineWidth);
		}

		const showMinorGridOnTerrain = rs.floorGridCellSize.x > 500;

		{
			// RGBA.RGBAHexToVec4(colorRange.min.color, this.terrain.startColor.value);
			// RGBA.RGBAHexToVec4(colorRange.max.color, this.terrain.endColor.value);

			const palette = terrainSettings;
			const paletteColorsUniform = this.terrain.paletteColors.value;
			const paletteRangesUniform = this.terrain.paletteRanges.value;
			for (let i = 0; i < TerrainShaderMaxSlices; ++i) {
				const slice: EngineTerrainPaletteSlice | undefined = palette.slices[i] || palette.slices.at(-1);
				const colorVector = paletteColorsUniform[i];
				RGBA.RGBAHexToVec4(slice?.color ?? 0, colorVector);

				const rangeVector = paletteRangesUniform[i];
				rangeVector.set(slice?.min ?? 0, slice?.max ?? 0);
			}
			
			const sv = palette.slopeVector;
			this.terrain.angleToVectorAndSource.value.set(
				sv.x, sv.y, sv.z,
				terrainSettings.displayMode,

			);

			const gridSize = showMinorGridOnTerrain ? rs._floorGridCellSizeMinor : rs.floorGridCellSize;
			this.terrain.gridStep.value.set(gridSize.x, gridSize.y);
		}

		const rtSize = composer.resultRenderTargetSize.poll();
		this.resolution.value.copy(rtSize);

		const groundLevel = totalBounds.bounds.minz();

		let floorGridOpacity = rs.floorGridOpacity;


		if (floorGridOpacity > 0 && terrainSettings.displayMode === TerrainDisplayMode.BasicTransparent && !showMinorGridOnTerrain) {
			const camera = composer.frustum.camera;
		 	const topDownNessOfCamera = Math.abs(reusedVec3.setFromMatrixColumn(camera.matrixWorld, 2).dot(Vec3Z));

			if (topDownNessOfCamera < 0.99 && engineScene.esos.anyVisibleObjectsHandledBy(ESO_TerrainHeightmapHandler)) {
				floorGridOpacity *= 0.1;
			}
		}
		
		this.gridLevelOpacityInterval.value.set(groundLevel, floorGridOpacity, rs.floorGridCellSize.x, rs.floorGridCellSize.y);
		const minorGridOpacity = floorGridOpacity > 0 && rs.floorGridCellSizeAuto ? floorGridOpacity * 0.7 : 0;
		this.gridLevelOpacityIntervalMinor.value.set(groundLevel, minorGridOpacity, rs._floorGridCellSizeMinor.x, rs._floorGridCellSizeMinor.y);

		let minorGridMultiplier = rs.floorGridCellSize.x === rs._floorGridCellSizeMinor.x ? 0.4 : 0.8;
		if (rs.floorGridCellSize.x > 800) {
			minorGridMultiplier *= 2;
		}

		this.gridWidth.value.set(
			this._groundGridRelativeWidthSelectFor(rs.floorGridCellSize.x),
			this._groundGridRelativeWidthSelectFor(rs._floorGridCellSizeMinor.x) * minorGridMultiplier,
		);
	}

	setSelectionColorStd() {
		this.selectionSideColor.value.set(127 / 255, 198 / 255, 255/255, 0.45);
		this.selectionFrontColor.value.set(64 / 255, 164 / 255, 254/255, 0.45);
		this.selectionEdgeColor.value.set(140 / 255, 205 / 255, 255/ 255, 0.6);
	}

	setSelectionColorTransformEnabled() {
		this.selectionSideColor.value.set(127 / 255, 198 / 255, 255/255, 0.2);
		this.selectionFrontColor.value.set(64 / 255, 164 / 255, 254/255, 0.3);
		this.selectionEdgeColor.value.set(140 / 255, 205 / 255, 255/ 255, 0.4);
	}

	setSelectionColorTransformActive() {
		this.selectionSideColor.value.set(255/255, 255/255, 255/255, 0.2);
		this.selectionFrontColor.value.set(255/255, 255/255, 255/255, 0.3);
		this.selectionEdgeColor.value.set(255/255, 255/255, 255/255, 0.7);
	}

	_prevCameraMatrix: Matrix4 = new Matrix4();
	_prevBoundsMinZ: number = -1;
	_prevUnitsVersion: number = -1;

	_updateGridSizeSettingsIfNecessary(
		graphicsSettings: ObservableObject<GraphicsSettings>,
		camera: KrCamera,
		uiUnits: EngineLegacyUiUnits,
		totalBounds: TotalBounds
	) {
		const rs = graphicsSettings.poll();

		if (!rs.floorGridCellSizeAuto || !(rs.floorGridOpacity > 0)) {
			return;
		}
		const groundLevel = totalBounds.bounds.minz();

		if (this._prevCameraMatrix.equals(camera.matrixWorld)
			&& this._prevBoundsMinZ === groundLevel
			&& this._prevUnitsVersion === uiUnits.version()
		) {
			return;
		}
		this._prevCameraMatrix.copy(camera.matrixWorld);
		this._prevBoundsMinZ = groundLevel;
		this._prevUnitsVersion = uiUnits.version();

		let cameraFrustumHeightAtGroundLevel: number;
		if (camera instanceof PerspectiveCamera) {
			const groudPlane = new Plane(new Vector3(0, 0, 1), groundLevel);
			let ray = RaySection.fromCameraMousePosHtml(camera, new Vector2(0, 0));
			if (ray && ray.ray.direction.angleTo(Vec3Z) > KrMath.degToRad(80)) {
				ray = null;
			}
			const cameraCenterIntersectionWithGround = ray?.ray.intersectPlane_t(groudPlane, new Vector3());
			let distanceToGround = cameraCenterIntersectionWithGround?.distanceTo(camera.position);
			if (distanceToGround === undefined) {
				distanceToGround = Math.abs(camera.matrixWorld.elements[14] - groundLevel);
			}
			cameraFrustumHeightAtGroundLevel = 2.0 * distanceToGround * Math.tan(camera.fov * 0.5 * DEG2RAD);

		} else if (camera instanceof OrthographicCamera) {
			cameraFrustumHeightAtGroundLevel = (camera.top - camera.bottom) / camera.zoom;
		} else {
			cameraFrustumHeightAtGroundLevel = 1;
		}

		let groundGridIntervalX: number;
		let groundGridIntervalY: number;

		let groundGridIntervalXMinor: number;
		let groundGridIntervalYMinor: number;

		if (uiUnits.isImperial()) {
			// set up in feet, convert to meters later
			cameraFrustumHeightAtGroundLevel /= 0.3048;

			if (cameraFrustumHeightAtGroundLevel > 5280 * 2.5) {
				groundGridIntervalX = 5280;
				groundGridIntervalY = 5280;
				groundGridIntervalXMinor = 66;
				groundGridIntervalYMinor = 66;
			} else if (cameraFrustumHeightAtGroundLevel > 66 * 3) {
				groundGridIntervalX = 66;
				groundGridIntervalY = 66;
				groundGridIntervalXMinor = 1;
				groundGridIntervalYMinor = 1;
			} else {
				groundGridIntervalX = 1;
				groundGridIntervalY = 1;
				groundGridIntervalXMinor = 1 / 12;
				groundGridIntervalYMinor = 1 / 12;
			}

			groundGridIntervalX *= 0.3048;
			groundGridIntervalY *= 0.3048;
			groundGridIntervalXMinor *= 0.3048;
			groundGridIntervalYMinor *= 0.3048;

		} else {
			if (cameraFrustumHeightAtGroundLevel > 1000 * 3) {
				groundGridIntervalX = 1000;
				groundGridIntervalY = 1000;
				groundGridIntervalXMinor = 100;
				groundGridIntervalYMinor = 100;
			} else if (cameraFrustumHeightAtGroundLevel > 100 * 3) {
				groundGridIntervalX = 100;
				groundGridIntervalY = 100;
				groundGridIntervalXMinor = 10;
				groundGridIntervalYMinor = 10;
			} else if (cameraFrustumHeightAtGroundLevel > 10 * 2) {
				groundGridIntervalX = 10;
				groundGridIntervalY = 10;
				groundGridIntervalXMinor = 1;
				groundGridIntervalYMinor = 1;
			} else {
				groundGridIntervalX = 1;
				groundGridIntervalY = 1;
				groundGridIntervalXMinor = 0.1;
				groundGridIntervalYMinor = 0.1;
			}
		}

		graphicsSettings.applyPatch({
			patch: {
				floorGridCellSize: new Vector2(groundGridIntervalX, groundGridIntervalY),
				_floorGridCellSizeMinor: new Vector2(groundGridIntervalXMinor, groundGridIntervalYMinor),
			}
		})
	}

	_groundGridRelativeWidthSelectFor(gridCellSize: number) {
		if (gridCellSize > 500) {
			return 0.008;
		} else if (gridCellSize > 150) {
			return 0.015;
		} else if (gridCellSize > 50) {
			return 0.01;
		} else if (gridCellSize > 10) {
			return 0.02;
		} else if (gridCellSize > 2) {
			return 0.03;
		} else if (gridCellSize > 0.3) {
			return 0.04;
		} else {
			return 0.06;
		}
	}
}

const reusedVec3 = new Vector3();
