import type { Inline, Line } from "../../components/Lines";


export class WhiteSpaceOptions {
    constructor (
        readonly WHITESPACE: WHITE_SPACE,
        readonly LETTERSPACING: number,
        readonly BREAKON: string,
        readonly INNER_WIDTH: number) { }
}

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Document_Object_Model/Whitespace#whitespace_helper_functions
 *
 * Throughout, whitespace is defined as one of the characters
 *  "\t" TAB \u0009
 *  "\n" LF  \u000A
 *  "\r" CR  \u000D
 *  " "  SPC \u0020
 *
 * This does not use Javascript's "\s" because that includes non-breaking
 * spaces (and also some other characters).
 **/
export const WHITE_CHARS: Map<string, string> = new Map<string, string>([ 
	['\t', '\u0009'], ['\n', '\u000A'], ['\r', '\u000D'], [' ', '\u0020'] 
]);

export const enum WHITE_SPACE {
	NORMAL = 0,
	NOWRAP = 1,
	PRE = 2,
	PRE_LINE = 3,
	PRE_WRAP = 4
}


/**
 * Collapse whitespaces and sequence of whitespaces on string
 */
export function collapseWhitespaceOnString ( textContent: string, whiteSpace: WHITE_SPACE ): string {
	switch ( whiteSpace ) {
		case WHITE_SPACE.NOWRAP:
		case WHITE_SPACE.NORMAL:
			// newlines are treated as other whitespace characters
			textContent = textContent.replace( /\n/g, ' ' );
		//falls through

		case WHITE_SPACE.PRE_LINE:
			// collapsed white spaces sequences
			textContent = textContent.replace( /[ ]{2,}/g, ' ' );
			break;

		default:
	}

	return textContent;
};

/**
 * Get the breakability of a newline character according to white-space property
 */
export const newlineBreakability = function ( whiteSpace: WHITE_SPACE ): string | undefined {
	switch ( whiteSpace ) {
		case WHITE_SPACE.PRE:
		case WHITE_SPACE.PRE_WRAP:
		case WHITE_SPACE.PRE_LINE:
			return 'mandatory';

		case WHITE_SPACE.NOWRAP:
		case WHITE_SPACE.NORMAL:
		default:
		// do not automatically break on newline
			return undefined;
	}
};

/**
 * Check for breaks in inlines according to whiteSpace value
 */
export function shouldBreak ( inlines: Inline[], i: number, lastInlineOffset: number, options: WhiteSpaceOptions ): boolean {
	const inline = inlines[i];

	switch ( options.WHITESPACE ){

		case WHITE_SPACE.NORMAL:
		case WHITE_SPACE.PRE_LINE:
		case WHITE_SPACE.PRE_WRAP:

			// prevent additional computation if line break is mandatory
			if( inline.lineBreak === 'mandatory' ) return true;

			// prevent additional computation if this character already exceed the available size
			if( lastInlineOffset + inline.xadvance + inline.xoffset + inline.kerning > options.INNER_WIDTH ) return true;


			const nextBreak = _distanceToNextBreak( inlines, i, options );
			return _shouldFriendlyBreak( inlines[ i - 1 ], lastInlineOffset, nextBreak, options );

		case WHITE_SPACE.PRE:
			return inline.lineBreak === 'mandatory';

		case WHITE_SPACE.NOWRAP:
		default:
			return false;
	}
}


/**
 * Alter a line of inlines according to white-space property
 */
export function collapseWhitespaceOnInlines ( line: Line ) {
	const firstInline = line.inlines[ 0 ];
	const lastInline = line.inlines[ line.inlines.length - 1 ];

	// @see https://developer.mozilla.org/en-US/docs/Web/API/Document_Object_Model/Whitespace
	// current implementation is 'pre-line'
	// if the breaking character is a space, get the previous one
	switch ( line.whiteSpace ) {
		// trim/collapse first and last whitespace characters of a line
		case WHITE_SPACE.PRE_WRAP:
			// only process whiteChars glyphs inlines
			// if( firstInline.glyph && whiteChars[firstInline.glyph] && line.length > 1 ){
			if ( firstInline.glyph && firstInline.glyph === '\n' && line.inlines.length > 1 ) {
				_collapseLeftInlines( [ firstInline ], line.inlines[ 1 ] );
			}

			// if( lastInline.glyph && whiteChars[lastInline.glyph] && line.length > 1 ){
			if ( lastInline.glyph && lastInline.glyph === '\n' && line.inlines.length > 1 ) {
				_collapseRightInlines( [ lastInline ], line.inlines[ line.inlines.length - 2 ] );
			}
			break;

		case WHITE_SPACE.PRE_LINE:
		case WHITE_SPACE.NOWRAP:
		case WHITE_SPACE.NORMAL:
			let inlinesToCollapse = [];
			let collapsingTarget: Inline | null = null;
			// collect starting whitespaces to collapse
			for ( let i = 0; i < line.inlines.length; i++ ) {
				const inline = line.inlines[ i ];
				if ( inline.glyph && WHITE_CHARS.has( inline.glyph ) && line.inlines.length > i ) {
					inlinesToCollapse.push( inline );
					collapsingTarget = line.inlines[ i + 1 ];
					continue;
				}
				break;
			}

			_collapseLeftInlines( inlinesToCollapse, collapsingTarget );

			inlinesToCollapse = [];
			collapsingTarget = null;
			// collect ending whitespace to collapse
			for ( let i = line.inlines.length - 1; i > 0; i-- ) {
				const inline = line.inlines[ i ];
				if ( inline.glyph && WHITE_CHARS.has( inline.glyph ) && i > 0 ) {
					inlinesToCollapse.push( inline );
					collapsingTarget = line.inlines[ i - 1 ];
					continue;
				}
				break;
			}
			_collapseRightInlines( inlinesToCollapse, collapsingTarget );
			break;

		case WHITE_SPACE.PRE:
			break;

		default:
			console.warn( `whiteSpace: '${line.whiteSpace}' is not valid` );
			return 0;

	}

	return firstInline.offsetX;
};


/***********************************************************************************************************************
 * Internal logics
 **********************************************************************************************************************/


/**
 * Visually collapse inlines from right to left ( endtrim )
*/
function _collapseRightInlines( inlines: Inline[], targetInline: Inline | null ) {
	if ( !targetInline ) return;

	for ( let i = 0; i < inlines.length; i++ ) {
		const inline = inlines[ i ];
		inline.width = 0;
		inline.height = 0;
		inline.offsetX = targetInline.offsetX + targetInline.width;
	}
}

/**
 * Visually collapse inlines from left to right (starttrim)
 */
function _collapseLeftInlines( inlines: Inline[], targetInline: Inline | null ) {
	if ( !targetInline ) return;

	for ( let i = 0; i < inlines.length; i++ ) {
		const inline = inlines[ i ];
		inline.width = 0;
		inline.height = 0;
		inline.offsetX = targetInline.offsetX;
	}
}

/**
 * get the distance in world coord to the next glyph defined
 * as break-line-safe ( like whitespace for instance )
 */
function _distanceToNextBreak( inlines: Inline[], currentIdx: number, options: WhiteSpaceOptions, accu?: number ): number {
	accu = accu || 0;

	// end of the text
	if ( !inlines[ currentIdx ] ) return accu;

	const inline = inlines[ currentIdx ];
	const kerning = inline.kerning ? inline.kerning : 0;
	const xoffset = inline.xoffset ? inline.xoffset : 0;
	const xadvance = inline.xadvance ? inline.xadvance : inline.width;

	// if inline.lineBreak is set, it is 'mandatory' or 'possible'
	if ( inline.lineBreak ) return accu + xadvance;

	// no line break is possible on this character
	return _distanceToNextBreak(
		inlines,
		currentIdx + 1,
		options,
		accu + xadvance + options.LETTERSPACING + xoffset + kerning
	);
}

/**
 * Test if we should line break here even if the current glyph is not out of boundary.
 * It might be necessary if the last glyph was break-line-friendly (whitespace, hyphen..)
 * and the distance to the next friendly glyph is out of boundary.
 */
function _shouldFriendlyBreak( prevChar: Inline, lastInlineOffset: number, nextBreak: number, options: WhiteSpaceOptions ): boolean {
	// We can't check if last glyph is break-line-friendly it does not exist
	if ( !prevChar || !prevChar.glyph ) return false;

	// Next break-line-friendly glyph is inside boundary
	if ( lastInlineOffset + nextBreak < options.INNER_WIDTH ) return false;

	// Previous glyph was break-line-friendly
	return options.BREAKON.indexOf( prevChar.glyph ) > -1;
}
