import type { LazyVersioned } from 'engine-utils-ts';
import type { PUI_ActionDescr, PUI_GroupNode } from './PUI_Nodes';

export type UiViewSource<T> = UiCustomNode<T> | PUI_Lazy<T>;

export class UiCustomNode<T> {

    context: T;

    constructor({ context }:{ context: T }) {
        this.context = context;
    }
}

export interface DefaultMessage<Context = any> {
    message?: string;
    action?: {
        context: LazyVersioned<Context>;
        actionDescr: PUI_ActionDescr<Context>;
    };
}

export class PUI_Lazy<OwnedContext = undefined> {
    constructor(
        public readonly ui:
            LazyVersioned<PUI_GroupNode>
            | ((context: OwnedContext) => LazyVersioned<PUI_GroupNode>),
        public readonly defaultMessage?: DefaultMessage,
        public readonly createContext?: () => OwnedContext,
        public readonly disposeContext?: (c:OwnedContext) => void
    ) {
        if (typeof ui === "function" && (!createContext || !disposeContext)) {
            console.error('not implemented createContext or disposeContext fn');
        }
    }
}
