import { ObservableObject, type LazyVersioned, LazyBasic } from "engine-utils-ts";
import type { PUI_ConfigBasedBuilderParams, PUI_BuilderParams, DefaultMessage, PUI_GroupNode } from "ui-bindings";
import { buildFromObservableConfigObject, PropertyActionsValue, FileImporterContext } from "ui-bindings";

export function requestSettings<Settings extends Object>(args: {
    ident: string,
    defaultValue: Settings,
    configBuilderParams?: PUI_ConfigBasedBuilderParams,
    puiBuilderParams?: PUI_BuilderParams,
    defaultMessage?: DefaultMessage,
    context: FileImporterContext,
    submit: (settings: Settings) => void,
}): void {
    const importEnabled = new LazyBasic('importing', true);
    const settingsObservable = new ObservableObject({
        identifier: args.ident,
        initialState: {
            ...args.defaultValue,
            wbutt: new PropertyActionsValue([{
                label: 'Ok',
                isEnabled: importEnabled,
                action: () => {
                    importEnabled.replaceWith(false);
                    args.submit(settingsObservable.poll());
                },
                style: {
                    type: 'primary'
                }
            }])
        }
    });

    const uiConfig = buildFromObservableConfigObject({
        configObj: settingsObservable,
        configBuilderParams: args.configBuilderParams,
        puiBuilderParams: {
            ...args.puiBuilderParams,
            sortChildrenDefault: false
        },
        defaultMessage: args.defaultMessage,
    });

    args.context.setForm(uiConfig.ui as LazyVersioned<PUI_GroupNode>);
}
