import { createGrid } from "ag-grid-enterprise";

export function downloadSpreadsheets(spreadsheets: string[]) {
    const div = document.createElement("div");
    const gridApi = createGrid(div, {});
    gridApi.exportMultipleSheetsAsExcel({
        data: spreadsheets,
        fileName: "Cost Model.xlsx",
    });
    gridApi.destroy();
}
