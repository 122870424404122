
import { LegacyLogger } from 'engine-utils-ts';
import { KrMath } from 'math-ts';

import type { WebGLUniforms } from '../3rdParty/three';
import type { MergedJobs, RenderJobsMerged } from '../geometries/RenderJobsMerged';
import { ObjectMaxBatchCount, PerObjectBlockSizeInFloats } from '../shaders/shaders_chunks';

export const UboPerJobBindRangeInFloats = ObjectMaxBatchCount * (16 + 4);

export class UboBuffersAllocator {

	gl: WebGL2RenderingContext;
	alignment: number;

	readonly allocatedUbos: Map<number, WebGLBuffer> = new Map();

	_reusedUboBuffer: Float32Array;

	buffers_ids: number = 0;
	_boundUboId: number = 0;
	_boundOffset: number = 0;
	_boundUboGL: WebGLBuffer | null = null;

	// should not be necessary, but ... 
	// https://bugs.chromium.org/p/chromium/issues/detail?id=906683
	// https://bugzilla.mozilla.org/show_bug.cgi?id=1509801
	maxBufferSizeInFloats: number = 0;

	constructor(gl: WebGL2RenderingContext) {
		this.gl = gl;
		this.alignment = gl.getParameter(gl.UNIFORM_BUFFER_OFFSET_ALIGNMENT) as number;
		this.maxBufferSizeInFloats = (gl.getParameter(gl.MAX_UNIFORM_BLOCK_SIZE) as number / 4) || 1024 * 1024;
		this._reusedUboBuffer = new Float32Array(this.maxBufferSizeInFloats);
	}


	startNewFrame() {
		for (const buf of this.allocatedUbos.values()) {
			this.gl.deleteBuffer(buf);
		}
		this.allocatedUbos.clear();
		this.resetBindings();
	}

	bind(job: RenderJobsMerged, p_uniforms: WebGLUniforms, gl: WebGLRenderingContext) {

		p_uniforms.setValue(gl, 'instanceOffset', job.ubo_instanceOffset);

		if (this._boundOffset !== job.ubo_offset || this._boundUboId !== job.ubo_id) {
			this._boundUboId = job.ubo_id;
			this._boundOffset = job.ubo_offset;

			this._boundUboGL = this.allocatedUbos.get(job.ubo_id) || null;
			
			this.gl.bindBufferRange(this.gl.UNIFORM_BUFFER, 0, this._boundUboGL, job.ubo_offset * 4, UboPerJobBindRangeInFloats * 4);
		} else {
		}
	}

	initTransformsUbo(mjs: MergedJobs) {
		let alignmentInFloats = (this.alignment / 4) | 0;

		const tempBuffer = this._reusedUboBuffer;

		let bufferId = this.buffers_ids += 1;
		let inBufferOffset = 0;
		let inBlockOffset = 0;

		tempBuffer.fill(0); // could remove in release, should work correctly anyway, if all offsets and writes are fine

		for (const job of mjs.mergedJobs) {

			LegacyLogger.debugAssert(job.transforms_instanced_start % PerObjectBlockSizeInFloats === 0, 'ubo floats length multiple of 16');
			LegacyLogger.debugAssert(job.transforms_instanced_end % PerObjectBlockSizeInFloats === 0, 'ubo floats length multiple of 16');
			LegacyLogger.debugAssert(job.instance_count() <= ObjectMaxBatchCount, 'ubo instances overflow check');

			let floatCount = job.transforms_instanced_end - job.transforms_instanced_start;

			if (inBlockOffset + floatCount > UboPerJobBindRangeInFloats) {
				inBufferOffset = KrMath.roundUpTo(inBufferOffset + inBlockOffset, alignmentInFloats);
				inBlockOffset = 0;
			}
			if (inBufferOffset + UboPerJobBindRangeInFloats > tempBuffer.length) {
				this._allocate(bufferId, tempBuffer);
				// console.log('more than 1 ubo buffer')
				bufferId = this.buffers_ids += 1;
				inBufferOffset = 0;
				inBlockOffset = 0;
				tempBuffer.fill(0)
			}

			for (let i = 0; i < floatCount; ++i) {
				tempBuffer[inBufferOffset + inBlockOffset + i] =
					 mjs.transforms.buffer[i + job.transforms_instanced_start];
			}

			job.ubo_id = bufferId;
			job.ubo_offset = inBufferOffset;
			job.ubo_instanceOffset = inBlockOffset / PerObjectBlockSizeInFloats;

			inBlockOffset += floatCount;
		}
		this._allocate(bufferId, tempBuffer);
	}

	_allocate(bufferId: number, buffer: Float32Array) {
		const gl = this.gl;
		const uniformTransformBuffer = gl.createBuffer()!;
		gl.bindBuffer(gl.UNIFORM_BUFFER, uniformTransformBuffer);
		gl.bufferData(gl.UNIFORM_BUFFER, buffer, gl.DYNAMIC_DRAW);
		this.allocatedUbos.set(bufferId, uniformTransformBuffer);
		this.resetBindings();
	}

	freeBuffersOnContextLoss() {
		for (const buf of this.allocatedUbos.values()) {
			this.gl.deleteBuffer(buf);
		}
		this.allocatedUbos.clear();
		this.resetBindings();
	}

	reInitializeOnContextRestore(gl: WebGL2RenderingContext) {
		this.gl = gl;
		this.alignment = gl.getParameter(gl.UNIFORM_BUFFER_OFFSET_ALIGNMENT) as number;

		const oldBufferSize = this.maxBufferSizeInFloats;
		this.maxBufferSizeInFloats = (gl.getParameter(gl.MAX_UNIFORM_BLOCK_SIZE) as number / 4) || 1024 * 1024;

		if(oldBufferSize !== this.maxBufferSizeInFloats) {
			this._reusedUboBuffer = new Float32Array(this.maxBufferSizeInFloats);
		}
	}

	resetBindings() {
		this._boundOffset = 0;
		this._boundUboId = 0;
		this._boundUboGL = null;
		this.gl.bindBuffer(this.gl.UNIFORM_BUFFER, null);
	}

}
