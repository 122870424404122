import { WorkerClassPassRegistry } from "engine-utils-ts";
import type { Aabb} from "math-ts";
import { Aabb2, Vector2, Vector3 } from "math-ts";
import type { IdBimGeo } from "../geometries/BimGeometries";



export class TerrainTileId {

    private constructor(
        public readonly x: number,
        public readonly y: number,
    ) {
    }

    static new(x: number, y: number): TerrainTileId {
        x = x | 0;
        y = y | 0;
        const str =`${x}:${y}`;
        let tile = _tilesPerStr.get(str);
        if (tile === undefined) {
            tile = new TerrainTileId(x, y);
            _tilesPerStr.set(str, tile);
        }
        return tile;
    }

    static newFromPoint(point: {x: number, y: number}, tileSize: number) {
        const x = Math.floor(point.x / tileSize);
        const y = Math.floor(point.y / tileSize);
        return TerrainTileId.new(x, y);
    }

    toAabb(aabb: Aabb2, tileSize: number) {
        aabb.min.x = this.x * tileSize;
        aabb.min.y = this.y * tileSize;
        aabb.max.x = this.x * tileSize + tileSize;
        aabb.max.y = this.y * tileSize + tileSize;
    }

    aabb(tileSize: number): Aabb2 {
        return new Aabb2(
            new Vector2(this.x * tileSize, this.y * tileSize),
            new Vector2(this.x * tileSize + tileSize, this.y * tileSize + tileSize),
        )
    }

    localOffset(tileSize: number): Vector2 {
        return new Vector2(this.x * tileSize, this.y * tileSize);
    }

    offsetAabb(aabb: Aabb, tileSize: number) {
        aabb.translate(new Vector3(
            this.x * tileSize,
            this.y * tileSize,
            0
        ));
    }
};
WorkerClassPassRegistry.registerClassExtended<TerrainTileId>("TerrainTileId", (args) => TerrainTileId.new(args.x, args.y));
const _tilesPerStr = new Map<string, TerrainTileId>();


export enum TerrainGeoVersionSelector {
    Initial,
    Latest,
}


export class TerrainTile {

    constructor(
        public readonly initialGeo: IdBimGeo,
        public readonly updatedGeo: IdBimGeo | 0,
    ) {
    }

    selectGeoId(selector: TerrainGeoVersionSelector) {
        if (this.updatedGeo > 0 && selector === TerrainGeoVersionSelector.Latest) {
            return this.updatedGeo;
        }
        return this.initialGeo;
    }
}

