<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        d="M8.97917 17.3479H3.79483C3.41151 17.3479 3.07912 17.2067 2.79765 16.9242C2.51619 16.6418 2.37546 16.3082 2.37546 15.9235V3.88821C2.37546 3.50489 2.4997 3.1725 2.74819 2.89104C2.99667 2.60957 3.22055 2.46883 3.41983 2.46883H7.48867C7.59918 1.97608 7.85322 1.57179 8.25079 1.25596C8.64838 0.940136 9.10645 0.782227 9.625 0.782227C10.1383 0.782227 10.5942 0.940136 10.9928 1.25596C11.3913 1.57179 11.6492 1.97608 11.7663 2.46883H15.8302C16.2149 2.46883 16.5484 2.60957 16.8309 2.89104C17.1133 3.1725 17.2545 3.50489 17.2545 3.88821V8.06258H15.8302V3.88821H13.6218V6.59654H6.00317V3.88821H3.79483V15.9235H8.97917V17.3479ZM12.9167 16.7573L9.52854 13.3692L10.524 12.3687L12.9167 14.7614L17.851 9.82708L18.8465 10.8275L12.9167 16.7573ZM10 3.75008C10.2361 3.75008 10.434 3.67022 10.5938 3.5105C10.7535 3.35078 10.8333 3.15286 10.8333 2.91675C10.8333 2.68064 10.7535 2.48272 10.5938 2.323C10.434 2.16328 10.2361 2.08341 10 2.08341C9.76389 2.08341 9.56597 2.16328 9.40625 2.323C9.24653 2.48272 9.16667 2.68064 9.16667 2.91675C9.16667 3.15286 9.24653 3.35078 9.40625 3.5105C9.56597 3.67022 9.76389 3.75008 10 3.75008Z"
        fill="currentColor"
    />
</svg>
