<script lang="ts">
    import { LazyBasic } from "engine-utils-ts";
    import type {
        SolarArraysViewContext,
        SolarArraysProperties,
    } from "layout-service";
    import { setContext } from "svelte";
    import type { PUI_CustomGroupNode } from "ui-bindings";
    import { Button, ButtonComponent, ButtonType } from "../../../libui/button";
    import { VersionedStore } from "../../../VersionedStore";
    import SolarArray from "./SolarArray.svelte";
    import PropertyHelp from "../../PropertyHelp.svelte";
    import AlignmentHelp from "./AlignmentHelp.svelte";

    export let property: PUI_CustomGroupNode<
        SolarArraysProperties,
        SolarArraysViewContext
    >;

    const contextLazy = new LazyBasic<SolarArraysViewContext>(
        "contextLazy",
        property.context,
    );
    $: contextLazy.replaceWith(property.context);
    const contextStore = new VersionedStore(contextLazy);
    setContext('context', contextStore);

    let initialCount = property.context.arrays.length;
    function scroll(node: Element) {
        if (property.context.arrays.length > initialCount) {
            node.scrollIntoView();
            initialCount = property.context.arrays.length;
        }
    };
    $: {
        if (initialCount > property.context.arrays.length) {
            initialCount = property.context.arrays.length;
        }
    }
</script>

<div style:position="relative">
    <div class="icon-help-container">
        <PropertyHelp name={property.name}>
            <AlignmentHelp />
        </PropertyHelp>
    </div>
</div>
<div class="array-configs custom-scrollbar">
    {#each property.context.arrays as array (array.id.value)}
        <div use:scroll class="card-wrapper">
            <SolarArray arrayConfig={array} />
        </div>
    {/each}
</div>
<div class="btn-container">
    <ButtonComponent
        desc={new Button(
            "Add array configuration",
            ButtonType.Text,
            () => $contextStore.createNew(),
            false,
            "AddPlus"
        )}
    />
</div>

<style lang="scss">
    .icon-help-container {
        position: absolute;
        top: -26px;
        right: 4px;
        float: right;
    }
    .btn-container {
        margin: 4px 0;
    }
    .array-configs {
        max-height: calc(var(--max-height) - 100px);
        overflow-y: auto;
        width: 100%;
        .card-wrapper:not(:first-child) {
            margin-top: 10px;
        }
    }
</style>
