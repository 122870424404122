<script lang="ts">
    import flash from "../pui/flash";
    
    export let name: string;
    export let notActive: boolean | undefined = undefined;
    export let showValue1: boolean = true;
    export let showValue2: boolean = true;
    export let changedValue: any;
    export let multiline: boolean = false;

    let div: HTMLElement;

    let prevValue = changedValue;
    $: if (prevValue != changedValue) {
        prevValue = changedValue;
        if (div) {
            flash(div);
        }
    }
</script>

<div 
    class="ui-config-property property-row" 
    class:custom-prop = {multiline}
    class:not-active={notActive}
    >
    <div bind:this={div} class="property-name text-main-medium">
        <div class="property-name-label mdc-typography--body1">
            {name}
        </div>
    </div>

    <div class="property-value">
        <div class="edit-field-container number-with-options-property multiselect-property">
            {#if showValue1}
                <slot name="value1" />
            {/if}
            {#if showValue2}
                <slot name="value2" />
            {/if}
        </div>
    </div>
</div>

<style lang="scss">

</style>
