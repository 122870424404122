import type { EventStackFrame} from 'engine-utils-ts';
import { IterUtils } from 'engine-utils-ts';
import type { EntityId} from 'verdata-ts';

export class BimCollectionPatch<T, IdT, PatchT = Partial<T>> {

    constructor(
        readonly identifier: string
    ) {
    }

    readonly toDelete: EntityId<IdT>[] = [];
    readonly toPatch: [EntityId<IdT>, PatchT][] = [];
    readonly toAlloc: [EntityId<IdT>, Partial<T>][] = [];

    mergeWith(other: BimCollectionPatch<T, IdT, PatchT>) {
        IterUtils.extendArray(this.toDelete, other.toDelete);
        IterUtils.extendArray(this.toPatch, other.toPatch);
        IterUtils.extendArray(this.toAlloc, other.toAlloc);
    }
}

export interface BimCollection<T, ET, PatchT = Partial<T>> {

    applyCollectionPatch(diff: BimCollectionPatch<T, ET, PatchT>, e?: Partial<EventStackFrame>): void;

    allocate(argsPerObject: [EntityId<ET>, Partial<T>][], e?: Partial<EventStackFrame>): readonly EntityId<ET>[];
    
    applyPatchTo(patch: PatchT, ids: EntityId<ET>[], event?: Partial<EventStackFrame>): void;
        // readonly [EntityId<ET>, Readonly<PatchT>][];
    applyPatches(perId: [EntityId<ET>, PatchT][], event?: Partial<EventStackFrame>): void;
        // readonly [EntityId<ET>, Readonly<PatchT>][];
    
    delete(ids: EntityId<ET>[], e?: Partial<EventStackFrame>): readonly [EntityId<ET>, Readonly<T>][];

    clone(ids: EntityId<ET>[]): EntityId<ET>[];

	allIds(): IterableIterator<EntityId<ET>>;
    peekById(id: EntityId<ET>): Readonly<T> | undefined;
    peekByIds(ids: Iterable<EntityId<ET>>): Map<EntityId<ET>, T>;
}
