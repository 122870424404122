import type { Bim, TrackerPilesCollection} from "../../../..";
import { NumberProperty} from "../../../..";
import { StringProperty } from "../../../..";
import { create_ElectricalSubtotal } from "./electrical-subtotal/electrical-subtotal";
import { create_PowerConversionSystem } from "./power-conversion-system/power-conversion-system";
import { create_CivilSubtotal } from "./civil/civil";
import { create_DesignAndEngineering } from "./design-and-engineering";
import type { CostModelFocusApi, CostsConfigProvider, EstimateCost, TableSettings } from "../..";
import { CivilTableId, CivilTableTabName, CostHierarchy, CostPerWattColId, CostSourceColId, CostSourceType, CostStateColId, CostUnitColId, DesignAndEngineeringTableId, DesignAndEngineeringTableTabName, ElectricalTableId, ElectricalTableTabName, EquipmentCostPerUnitColId, EquipmentTotalColId, LaborCostPerUnitColId, LaborPerUnitColId, LaborTotalColId, LoadedWageRateColId, MaterialCostPerUnitColId, MaterialTotalColId, PCSTableId, PCSTableTabName, PVModuleTableId, PVModuleTableTabName, QuantityColId, SetupTableId, SetupTableTabName, StructuralTableId, StructuralTableTabName, SubServiceCostPerUnitColId, SubServiceTotalColId, SummaryTableId, SummaryTableTabName, TotalCostColId, createEmptyCostComponentsNonNullable, fillModelBasedTopLevelCategory } from "../..";
import { createTotalDCLazy } from "src/archetypes/substation/utils";
import { create_ModuelsSubtotal } from "./pv-modules/modules-subtotal";
import { create_StructuralSubtotal } from "./structural/structural-subtotal";
import type { FindAndUpdateTableSettings, IdCostCategory, MatchesCostCategoryParams } from "../../../../cost-model/capital";
import { LazyDerivedAsync } from "engine-utils-ts";
import { EquipmentHiddableColumnGroupId, LaborHiddableColumnGroupId, LaborHoursAndRageHiddableColumnGroupId, MaterialHiddableColumnGroupId, SubcontractorHiddableColumnGroupId, TopLevelHiddableColumnGroups } from "src/cost-model/capital/HiddableColumnGroups";
import type { EChartOptions } from 'ui-charts';
import { createCostByColumnChart, createCostBySourceChart, createGroundingCostsChart } from "./OverviewEcharts";

export class CostModel {
    constructor(
        public totalWattDC: number,
        public tables: CostTable[],
        public updateTableSettings: FindAndUpdateTableSettings,
    ) {}

    findSceneInstanceMatchingCategories = (params: MatchesCostCategoryParams) => {
        const matchingPlaces: Array<[table: string, categoryId: IdCostCategory]> = [];
        for (const table of this.tables) {
            for (const [categoryId, category] of table.hierarchy.categories) {
                if (!category.matchesSceneInstance?.(params)) {
                    continue;
                }
                return [[table.id, categoryId]] as [table: string, categoryId: IdCostCategory][];
                //matchingPlaces.push([table.id, categoryId]);
            }
        }
        return matchingPlaces
    }

    focusApi?: CostModelFocusApi;
}

export function createCostModel(bim: Bim, costs: CostsConfigProvider, piles: TrackerPilesCollection) {
    const totalDC = createTotalDCLazy(bim);

    const electricalCables = create_ElectricalSubtotal(bim, costs, totalDC);
    const pcs = create_PowerConversionSystem(bim, costs, totalDC);
    const civil = create_CivilSubtotal(bim, costs, totalDC);
    const designEngineering = create_DesignAndEngineering(bim, costs, totalDC);
    const modules = create_ModuelsSubtotal(bim, costs, totalDC);
    const structural = create_StructuralSubtotal(bim, costs, totalDC, piles);

    const costModel = LazyDerivedAsync.new9<
        CostModel,

        CostHierarchy,
        CostHierarchy,
        CostHierarchy,
        CostHierarchy,
        CostHierarchy,
        CostHierarchy,

        NumberProperty,
        TableSettings[],
        EstimateCost[]
    >(
        'cost model',
        [],
        [electricalCables, pcs, civil, designEngineering, modules, structural, totalDC, costs.allTableSettings, costs.allEstimateCosts],
        function* ([electricalCables, pcs, civil, designEngineering, modules, structural, totalDC, tableSettings, estimates]) {
            const costModel = new CostModel(totalDC.as('W'), [], costs.findAndUpdateTableSettings)

            const tableDefinitions: Array<CreateCostTableInput> = [];

            tableDefinitions.push(
                {
                    id: PVModuleTableId,
                    name: PVModuleTableTabName,
                    hierarchy: modules,
                    defaultHiddenColumnGroups: [
                        SubcontractorHiddableColumnGroupId,
                        EquipmentHiddableColumnGroupId,
                    ],
                },
                {
                    id: PCSTableId,
                    name: PCSTableTabName,
                    hierarchy: pcs,
                    defaultHiddenColumnGroups: [
                        LaborHiddableColumnGroupId,
                        LaborHoursAndRageHiddableColumnGroupId,
                        EquipmentHiddableColumnGroupId,
                    ]
                },
                {
                    id: ElectricalTableId,
                    name: ElectricalTableTabName,
                    hierarchy: electricalCables,
                    defaultHiddenColumnGroups: [
                        SubcontractorHiddableColumnGroupId,
                        EquipmentHiddableColumnGroupId,
                    ]
                },
                {
                    id: CivilTableId,
                    name: CivilTableTabName,
                    hierarchy: civil,
                    defaultHiddenColumnGroups: [
                        LaborHiddableColumnGroupId,
                        LaborHoursAndRageHiddableColumnGroupId,
                        MaterialHiddableColumnGroupId,
                        EquipmentHiddableColumnGroupId,
                    ]
                },
                {
                    id: StructuralTableId,
                    name: StructuralTableTabName,
                    hierarchy: structural,
                    defaultHiddenColumnGroups: [SubcontractorHiddableColumnGroupId]
                },
                {
                    id: DesignAndEngineeringTableId,
                    name: DesignAndEngineeringTableTabName,
                    hierarchy: designEngineering,
                    defaultHiddenColumnGroups: [
                        LaborHiddableColumnGroupId,
                        LaborHoursAndRageHiddableColumnGroupId,
                        MaterialHiddableColumnGroupId,
                        EquipmentHiddableColumnGroupId,
                    ]
                },
            )
            const rootHierarhies = tableDefinitions.map(x => x.hierarchy);

            // create global
            const overviewOther = new CostHierarchy();
            {
                const hierarchy = overviewOther
                const categories: Array<{
                    id: string,
                    name: string,
                    defaultPrice: number
                }> = [
                    { id: 'global-permitting', defaultPrice: 0.01, name: 'Permitting' },
                    { id: 'global-taxes', defaultPrice: 0.04, name: 'Taxes' },
                    { id: 'global-overhead-and-margin', defaultPrice: 0.04, name: 'Overhead & Margin' },
                ]

                // add permitting
                for (const c of categories) {
                    const root = hierarchy.add({
                        description: {
                            value: c.name,
                            onClick: () => costModel.focusApi?.focusOnCostModel?.({ type_identifier: OverviewOtherTypeIdent + '_' + c.name })
                        },
                    });
                    const defaults = createEmptyCostComponentsNonNullable();
                    defaults.serviceCost = NumberProperty.new({ value: c.defaultPrice });
                    fillModelBasedTopLevelCategory(
                        root[0],
                        overviewOther,
                        c.id,
                        estimates,
                        costs.findAndUpdateEstimateCost,
                        defaults,
                        bim.unitsMapper,
                        totalDC
                    );
                }
            }

            // create summary table
            let overviewCostTable: CreateCostTableInput;
            {
                const hierarchy = new CostHierarchy();
                for (const table of tableDefinitions) {
                    const root = table.hierarchy.getRootCategories().at(0)?.[1]
                    if (!root) {
                        continue;
                    }
                    hierarchy.add({
                        ...root,
                        description: {
                            ...root.description,
                            onClick: () => costModel.focusApi?.focusOnTable(table.id),
                        }
                    });
                }
                hierarchy.merge(overviewOther);

                const category = hierarchy.addRoot({
                    description: { value: 'Total Cost' },
                    costSource: { index: 0, options: [CostSourceType.GroupSum] }
                })
                hierarchy.sumupChildren(category[0]);

                const alwaysHiddenColumns = [
                    CostSourceColId,
                    CostUnitColId,
                    CostStateColId,
                    QuantityColId,

                    LaborPerUnitColId,
                    LoadedWageRateColId,
                    LaborCostPerUnitColId,
                    LaborTotalColId,

                    MaterialCostPerUnitColId,
                    MaterialTotalColId,

                    SubServiceTotalColId,
                    SubServiceCostPerUnitColId,

                    EquipmentCostPerUnitColId,
                    EquipmentTotalColId,
                ];
                overviewCostTable = { id: SummaryTableId, name: SummaryTableTabName, hierarchy, alwaysHiddenColumns };
                overviewCostTable.echarts = [
                    {
                        id: 'cost-by-column',
                        options: createCostByColumnChart(rootHierarhies, bim.unitsMapper),
                    },
                    {
                        id: 'cost-by-source',
                        options: createCostBySourceChart(rootHierarhies, bim.unitsMapper),
                    },
                ]
            }


            // create setup table
            let setupCostTable: CreateCostTableInput;
            {
                const hierarchy = new CostHierarchy();
                for (const table of tableDefinitions) {
                    hierarchy.merge(table.hierarchy)
                }
                {
                    // add other category to setup table
                    const otherWithRoot = new CostHierarchy();
                    for (const x of overviewOther.getRootCategories()) {
                        otherWithRoot.add({
                            ...x[1],
                            description: {
                                ...x[1].description,
                                onClick: undefined,
                            },
                            matchesSceneInstance: (params) => {
                                if (params.type_identifier === OverviewOtherTypeIdent + '_' + x[1].description.value) {
                                    return true;
                                }
                                return false;
                            },

                        })
                    }
                    const root = otherWithRoot.addRoot({
                        description: { value: 'Other' },
                    })
                    otherWithRoot.sumupChildren(root[0]);
                    hierarchy.merge(otherWithRoot);
                }

                setupCostTable = {
                    hierarchy,
                    id: SetupTableId,
                    name: SetupTableTabName,
                    alwaysHiddenColumns: [
                        LaborTotalColId,
                        MaterialTotalColId,
                        SubServiceTotalColId,
                        TotalCostColId,
                        CostPerWattColId,
                        QuantityColId,
                        EquipmentTotalColId,
                    ],
                    hiddableColumns: [],
                }
            }

            tableDefinitions.splice(0, 0, overviewCostTable);
            tableDefinitions.push(setupCostTable);


            for (const table of tableDefinitions) {
                const { id, name, hierarchy } = table
                const defaultTableSettings: TableSettings = {
                    hiddenColumnGroupIds: table.defaultHiddenColumnGroups?.map(x => StringProperty.new({ value: x })) ?? [],
                    id: StringProperty.new({ value: id }),
                }
                const settings = tableSettings.find(x => x.id.value === id) ?? defaultTableSettings;
                const alwaysHiddenColumns = table.alwaysHiddenColumns ?? [];
                const costTable: CostTable = {
                    id,
                    name,
                    hierarchy,
                    settings,
                    alwaysHiddenColumns,
                    hiddableColumns: table.hiddableColumns ?? TopLevelHiddableColumnGroups,
                    echarts: table.echarts ?? [],
                }
                costModel.tables.push(costTable);
            }

            // add grounding chart to Civil, Structure tabs
            const civilTable = costModel.tables.find(x => x.id === CivilTableId);
            if (civilTable) {
                civilTable.echarts.push(
                    {
                        id: 'grounding-costs',
                        options: createGroundingCostsChart(rootHierarhies, bim.unitsMapper, totalDC.as('W')),
                    },
                )
            }
            const structuralTable = costModel.tables.find(x => x.id === StructuralTableId);
            if (structuralTable) {
                structuralTable.echarts.push(
                    {
                        id: 'grounding-costs',
                        options: createGroundingCostsChart(rootHierarhies, bim.unitsMapper, totalDC.as('W')),
                    },
                )
            }

            return costModel;
        }
    );



    return costModel;
}

export interface CostTable {
    id: string
    name: string
    hierarchy: CostHierarchy
    settings: TableSettings
    alwaysHiddenColumns: string[]
    hiddableColumns: string[]
    echarts: TableEChartIntegration[]
}

export interface CreateCostTableInput {
    id: string,
    name: string,
    hierarchy: CostHierarchy,
    defaultHiddenColumnGroups?: string[],
    alwaysHiddenColumns?: string[],
    hiddableColumns?: string[],
    echarts?: TableEChartIntegration[]
}

export interface TableEChartIntegration {
    id: string
    options: EChartOptions
}

const OverviewOtherTypeIdent = 'overview-other-taxes';
