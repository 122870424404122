import type { Result } from "engine-utils-ts";
import { Base64Binary, Failure, PollablePromise, ProjectNetworkClient } from "engine-utils-ts";
import type { IdBimScene} from "..";
import { Bim, CatalogSource, importBimassetToBim, importDefaultCutAndFill, importDefaultPiles, importDefaultRoads, importDefaultTrackerPositionMultiplier, importDefaultTrenches } from "..";
import type { Catalog } from "./Catalog";

export function* importDefaultPack(catalog: Catalog, networkBase: ProjectNetworkClient) {
    yield* importAssets(catalog, networkBase);
    importDefaultNonAssets(catalog);
}

function* importAssets(catalog: Catalog, networkBase: ProjectNetworkClient) {
    // fetch new assets
    const network = new ProjectNetworkClient({
        ...networkBase.config,
        basePath: 'api/assets/default-asset-pack',
    });
    
    const response = yield* new PollablePromise(network.get('')).generatorWaitForResult();
    if (response instanceof Failure) {
        throw new Error(response.errorMsg());
    }
    const result: Result<Array<{
        name: string,
        content: string
    }>> = yield* new PollablePromise(response.value.json()).generatorWaitForResult();
    if (result instanceof Failure) {
        throw new Error(result.errorMsg());
    }
    const assets = result.value;
    const bim = new Bim({});

    const siIdsPerAsset: IdBimScene[][] = [];
    const bimassets: Uint8Array[] = [];

    for (const { content } of assets) {
        const bimasset = new Uint8Array(Base64Binary.decodeArrayBuffer(content));
        bimassets.push(bimasset);
        const ids = yield* importBimassetToBim(
            bimasset,
            bim,
        );
        siIdsPerAsset.push(ids);
    }

    // manually trigger solver recalculation
    yield* bim.runUpdatesTillCompletion({forceRun: true});

    const obsoleteAssets = new Set(
      Array.from(catalog.assets.perId.entries())
        .filter(([_id, asset]) => asset.source.type === 'default')
        .map(x => x[0])
    );
    for (let i = 0, len = assets.length; i < len; i++) {
        const ids = siIdsPerAsset[i];
        const bimasset = bimassets[i];
        if (ids.length !== 1) {
            console.warn(`asset ${i} is skipped. Has more then 1 scene instance`);
        }
        const si = bim.instances.peekById(ids[0]);
        if (!si) {
            continue;
        }
        const existingAssetId = catalog.assets.assetsMatcher.matchSceneInstanceWithAsset(si);
        if (existingAssetId) {
            // asset already exist, update
            catalog.assets.applyPatchTo(
                {
                    bimasset: bimasset,
                    name: si.name ?? undefined,
                    source: new CatalogSource('default'),
                },
                [existingAssetId],
            );
            obsoleteAssets.delete(existingAssetId);
            continue;
        }
        // create new asset;
        catalog.assets.allocate([[
            catalog.assets.reserveNewId(),
            {
                bimasset: bimasset,
                name: si.name ?? undefined,
                source: new CatalogSource('default'),
            }
        ]])
    }
    catalog.assets.applyPatchTo(
        { source: new CatalogSource('obsolete') },
        Array.from(obsoleteAssets),
    )

    // dispose
    bim.dispose();
}

function importDefaultNonAssets(catalog: Catalog) {
    importDefaultRoads(catalog);
    importDefaultTrenches(catalog);
    importDefaultPiles(catalog);
    importDefaultCutAndFill(catalog);
    importDefaultTrackerPositionMultiplier(catalog);
}

