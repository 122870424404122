import type { Bim, IrregularHeightmapGeometry, RegularHeightmapGeometry, IdBimScene, TerrainTileId, SceneInstance } from "bim-ts";
import { TerrainGeoVersionSelector, TerrainHeightMapRepresentation, TerrainInstanceTypeIdent } from "bim-ts";
import { LazyVersioned, Yield } from "engine-utils-ts";
import { Aabb2, KrMath, Matrix4 } from "math-ts";
import type { ExportedFileDescription, FileExporter, FileExporterContext } from "ui-bindings";
import { CommonExportSettings, getAvailableSegmentSizes, getExportProjectName, getPropsForExportOptions, selectExportResolution } from "../CommonExportSettings";
import { createGeoTiff } from "./DownloadGeoTiff";
import { ProjectInfo, VerdataSyncerType } from "src";


export class TiffFileExporter implements FileExporter<CommonExportSettings> {

    constructor(
        readonly bim: Bim,
        readonly projectInfo: LazyVersioned<ProjectInfo | undefined>,
        readonly projectVerdataSyncer: VerdataSyncerType,
    ) {
    }

    initialSettings() {
        return { defaultValue: new CommonExportSettings() };
    }

    *startExport(settings: CommonExportSettings, context: FileExporterContext): Generator<Yield, ExportedFileDescription[]> {
        const prefix = getExportProjectName(this.projectVerdataSyncer, this.projectInfo.poll());
        
        let terrains = this.bim.instances.peekByTypeIdent(TerrainInstanceTypeIdent);
        if (settings.export_only_selected) {
            const selectedIds = new Set(this.bim.instances.getSelected());
            terrains = terrains.filter(t => selectedIds.has(t[0]));
        }

        const { maxSegmentSize, totalArea } = getPropsForExportOptions(terrains, this.bim);
        const options = getAvailableSegmentSizes(maxSegmentSize, totalArea, 4);
        const resolutionParam = yield * selectExportResolution(context, options, "pixel_size", maxSegmentSize);

        const exportedFilesDescriptions: ExportedFileDescription[] = [];
        for (const [id, instance] of terrains) {
            if (instance.representation == null || !(instance.representation instanceof TerrainHeightMapRepresentation)) {
                continue;
            }
        
            const tilesIdsBbox = Aabb2.empty();
            const regularTiles = new Map<TerrainTileId, RegularHeightmapGeometry>();
            const irregularTiles = new Map<TerrainTileId, IrregularHeightmapGeometry>();
        
            for (const [tileId, tile] of instance.representation.tiles) {
                const regularGeometry = this.bim.regularHeightmapGeometries.peekById(
                    tile.selectGeoId(TerrainGeoVersionSelector.Latest));
                if (regularGeometry !== undefined) {
                    tilesIdsBbox.expandByPoint(tileId);
                    regularTiles.set(tileId, regularGeometry);
                }
        
                const irregularGeometry = this.bim.irregularHeightmapGeometries.peekById(
                    tile.selectGeoId(TerrainGeoVersionSelector.Latest));
                if (irregularGeometry !== undefined) {
                    tilesIdsBbox.expandByPoint(tileId);
                    irregularTiles.set(tileId, irregularGeometry);
                }
            }
        
            if (irregularTiles.size !== 0 && regularTiles.size !== 0) {
                console.error("instance has both regular and non-regular tiles");
                continue;
            }
    
            Yield.Asap;
    
            const geoTiffBinary = yield* createGeoTiff(
                resolutionParam,
                this.bim.instances.getSceneOrigin(),
                tilesIdsBbox,
                instance.representation.tileSize,
                regularTiles.size > 0 ? regularTiles : irregularTiles,
                instance.worldMatrix,
            );
    
            exportedFilesDescriptions.push({ extension: 'tif', file: geoTiffBinary, name: prefix + "_terrain." + id });

            Yield.Asap;
        }

        return exportedFilesDescriptions;
    }
}