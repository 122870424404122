import { RefsCounter, type UndoStack } from 'engine-utils-ts';
import type { EntityId, EntityIdAny } from 'verdata-ts';

import type { BimImages } from './';
import type { IdBimImage } from './BimImages';
import { EntitiesBase } from './collections/EntitiesBase';

export enum BimMaterialType {
    Default = -1,
}

export class BimMaterialStdRenderParams {
    constructor(
        public color: string|null = null,
        public transparency: number = 0.0,
        public metalness: number = 0.0,
        public roughness: number = 1.0,
        public colorImageId: IdBimImage = 0,
        public normalsImageId: IdBimImage = 0,
        public metalnessImageId: IdBimImage = 0,
        public roughnessImageId: IdBimImage = 0
    ) {
    }
}

export class BimMaterial {
    constructor(
        public name: string = '',
        public readonly stdRenderParams: BimMaterialStdRenderParams = new BimMaterialStdRenderParams()
    ) {
    }

    static imageIdsReferences(self: BimMaterial, result: EntityIdAny[]): void {
        if (self.stdRenderParams) {
            result.push(self.stdRenderParams.colorImageId);
            result.push(self.stdRenderParams.normalsImageId);
            result.push(self.stdRenderParams.metalnessImageId);
            result.push(self.stdRenderParams.roughnessImageId);
        }
    }
}


export type IdBimMaterial = EntityId<BimMaterialType>;

export class BimMaterials extends EntitiesBase<BimMaterial, BimMaterialType> {

    constructor(images: BimImages, undoStack?: UndoStack) {
        super({
            identifier: 'bim-materials',
            idsType: BimMaterialType.Default,
            undoStack,
            T_Constructor: BimMaterial,
			interner: (bm) => JSON.stringify(bm),
            collectionsRerenced: [
                {
                    collection: images,
                    idsFromState: BimMaterial.imageIdsReferences,
                    externalRefsChecker: new RefsCounter(),
                }
            ]
        });
    }

    checkForErrors(t: BimMaterial): void {
    }
}
