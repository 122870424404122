import {
    PropertyBase,
    BimProperty,
    PrimitivePropertyBase,
    BooleanProperty,
    NumberProperty,
    StringProperty,
    SelectorProperty,
    MultiSelectorProperty,
    ColorProperty,
    VirtualPropertyBase
} from "bim-ts";
import { CompressibleNumbersArray, Failure, InProgress, ObjectUtils, Success } from "engine-utils-ts";
import type { PUI_Builder, PUI_GroupNodeArgs } from "ui-bindings";
import type { AsyncPropsUiRetriver } from './AsyncPropsUiRetriver';


type GroupCtor = { new(...args: any[]): Object };

export enum FieldsMergeState {
    None,
    Merged,
    Unmergable,
    Different,
}

const primitivePropClasses = [
    BooleanProperty,
    NumberProperty,
    StringProperty,
    SelectorProperty,
    MultiSelectorProperty,
    ColorProperty
];

function isPrimitiveProperty(value: PropertyBase): boolean {
    return primitivePropClasses.includes(value.constructor as any);
}


export class CommonValueMerger<T> {

    _state: FieldsMergeState = FieldsMergeState.None;
    _mergedValue: T | undefined = undefined;
    _readonlyValue: boolean | undefined = undefined;

    merge(newValue: T) {
        if (this._state === FieldsMergeState.Unmergable) {
            return; // fast path
        }
        if (this._state === FieldsMergeState.None) {
            this._mergedValue = newValue;
            this._state = FieldsMergeState.Merged;
        } else if (!ObjectUtils.areObjectsEqual(this._mergedValue, newValue)) {

            if (typeof this._mergedValue === 'number'
                && typeof newValue === 'number'
                && Math.abs(this._mergedValue - newValue) < 0.001
            ) {
                return;
            }

            const valueType = typeof newValue;
            if (valueType === 'string' || valueType === 'number') {
                this._state = FieldsMergeState.Different;
                return;
            } else if (this._mergedValue instanceof BimProperty
                && newValue instanceof BimProperty
            ) {
                this._state = FieldsMergeState.Different;
                this._readonlyValue = (this._readonlyValue ?? this._mergedValue.readonly) || newValue.readonly;
                return;
            } else if (this._mergedValue instanceof PrimitivePropertyBase
                && newValue instanceof PrimitivePropertyBase
                && isPrimitiveProperty(this._mergedValue)
                && isPrimitiveProperty(newValue)
            ) {
                this._state = FieldsMergeState.Different;
                this._readonlyValue = (this._readonlyValue ?? this._mergedValue.isReadonly) || newValue.isReadonly;
                return;
            }

            this._state = FieldsMergeState.Unmergable;
            this._mergedValue = undefined;
        }
    }

    state(): FieldsMergeState {
        return this._state;
    }

    result(): T | undefined {
        return this._mergedValue;
    }

    nodeValue(): T | undefined | null {
        return this._state === FieldsMergeState.Different ? null : this.result();
    }

    isValid(): boolean {
        return this._state === FieldsMergeState.Merged || this._state === FieldsMergeState.Different;
    }

    isReadonly(): boolean | undefined {
        return this._readonlyValue;
    }
}


export class GroupsCommonFieldsMerger<T extends object> {

    _asyncPropsUiRetriver?: AsyncPropsUiRetriver;
    _state: FieldsMergeState = FieldsMergeState.None;
    _groupClass: GroupCtor | undefined;
    _mergedValues = new Map<string | number, GroupsCommonFieldsMerger<any> | CommonValueMerger<any>>();

    constructor(
        asyncPropsUiRetriver?: AsyncPropsUiRetriver,
    ) {
        this._asyncPropsUiRetriver = asyncPropsUiRetriver;
    }

    get(key: string | number): CommonValueMerger<any> | GroupsCommonFieldsMerger<any> | undefined {
        return this._mergedValues.get(key);
    }

    _init(sourceObject: Object) {
        this._groupClass = sourceObject.constructor as GroupCtor;
        this._state = FieldsMergeState.Merged;
        const keys = Array.isArray(sourceObject) ? sourceObject.keys() : ObjectUtils.keysIncludingGetters(sourceObject);
        for (const key of keys) {
            let value = (sourceObject as any)[key];

            if (typeof value === 'function' || typeof value === 'undefined') {
                continue;
            }

            let fieldMerger: GroupsCommonFieldsMerger<any> | CommonValueMerger<any>;

            if (this._asyncPropsUiRetriver && value instanceof VirtualPropertyBase) {
                const virtValue = this._asyncPropsUiRetriver.getValueOf(value, []);
                if (virtValue instanceof Success) {
                    value = virtValue.value;
                } else {
                    value = virtValue;
                }
            }

            if (ObjectUtils.isPrimitive(value)
                || value instanceof PropertyBase
                || value instanceof BimProperty
                || value instanceof InProgress
                || value instanceof Failure
                || value instanceof CompressibleNumbersArray
            ) {
                fieldMerger = new CommonValueMerger();
                fieldMerger.merge(value);
            } else if (typeof value === 'object') {
                fieldMerger = new GroupsCommonFieldsMerger(this._asyncPropsUiRetriver);
                fieldMerger.merge(value);
            } else {
                continue;
            }
            this._mergedValues.set(key, fieldMerger);
        }
    }

    _markInvalid() {
        this._state = FieldsMergeState.Unmergable;
        this._mergedValues.clear();
    }

    state(): FieldsMergeState {
        return this._state;
    }

    merge(newValue: Object | null | undefined | string | number) {
        if (this._state === FieldsMergeState.Unmergable) {
            return;
        }
        if (!newValue || typeof newValue !== 'object') {
            this._markInvalid();
            return;
        }
        if (this._asyncPropsUiRetriver && newValue instanceof VirtualPropertyBase) {
            const virtValue = this._asyncPropsUiRetriver.getValueOf(newValue, []);
            if (virtValue instanceof Success) {
                newValue = virtValue.value;
            } else {
                newValue = virtValue;
            }
        }
        if (this._state === FieldsMergeState.None) {
            this._init(newValue!);
            return;
        }
        if (this._groupClass !== newValue!.constructor) {
            this._markInvalid();
            return;
        }
        let hasAnythingValid = false;
        for (const [key, merger] of this._mergedValues) {
            const valueToMergeWith = (newValue as any)[key];
            if (valueToMergeWith !== undefined) {
                merger.merge(valueToMergeWith);
                if (merger instanceof GroupsCommonFieldsMerger) {
                    if (merger.isEmpty()) {
                        this._mergedValues.delete(key); // only hold shared keys
                    }
                } else {
                    if (merger.state() === FieldsMergeState.Unmergable) {
                        this._mergedValues.delete(key); // only hold shared keys
                    }
                }
            } else {
                this._mergedValues.delete(key); // only hold shared keys
            }
            hasAnythingValid = hasAnythingValid || merger.isValid();
        }
        if (!hasAnythingValid) {
            this._markInvalid();
        }
    }

    isEmpty() {
        return this._mergedValues.size === 0;
    }

    isValid(): boolean {
        return this._state === FieldsMergeState.Merged;
    }

    result() {
        const res: any = {};
        for (const [key, merger] of this._mergedValues) {
            if (merger.isValid()) {
                res[key] = merger.result();
            }
        }
        return res;
    }

    buildPui<C>(
        builder: PUI_Builder,
        createGroupParams: (
            groupCtor: GroupCtor,
            path: (string | number)[],
            parentGroupsContext: C[],
        ) => {groupNodeArgs: PUI_GroupNodeArgs, context: C} | undefined,
        addProperty: (
            builder: PUI_Builder,
            merger: CommonValueMerger<any>,
            path: (string | number)[],
            parentGroupsContext: C[]
        ) => void,
    ) {
        return this._buildUi(builder, createGroupParams, addProperty, [], []);
    }

    _buildUi<C>(
        builder: PUI_Builder,
        createGroupParams: (
            groupCtor: GroupCtor,
            path: (string | number)[],
            parentContext: C[],
        ) => {groupNodeArgs: PUI_GroupNodeArgs, context: C} | undefined,
        addProperty: (
            builder: PUI_Builder,
            merger: CommonValueMerger<any>,
            path: (string | number)[],
            parentGroupsContext: C[]
        ) => void,
        path: (string | number)[],
        parentGroupsContext: C[],
    ) {
        if (!this._groupClass || this._state !== FieldsMergeState.Merged) {
            return;
        }
        try {
            const groupParams = createGroupParams(this._groupClass, path, parentGroupsContext);
            if (!groupParams) {
                return;
            }
            builder.inGroup(groupParams.groupNodeArgs, () => {
                for (const [key, child] of this._mergedValues) {
                    const keyPath = [...path, key];
                    const context = [...parentGroupsContext, groupParams.context];
                    if (child instanceof GroupsCommonFieldsMerger) {
                        child._buildUi(builder, createGroupParams, addProperty, keyPath, context);
                    } else {
                        const childValue = child.result();
                        if (childValue !== undefined) {
                            addProperty(builder, child, keyPath, context);
                        }
                    }
                }
            });
            return groupParams;
        } catch (e) {
            console.error(e);
            return;
        }
    }
}
