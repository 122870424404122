<script lang="ts">
import { ListItem, ListSeparator} from "./types";
import ListItemComponent from './ListItem.svelte';

export let item: ListItem | ListSeparator;
export let showSeparator: boolean;
export let secondLine: boolean = false;
export let index: number;
export let elementType: string = 'li';

</script>

{#if !index && showSeparator}
  <svelte:element this={elementType} role="separator" class="mdc-deprecated-list-divider" />
{/if}
{#if item instanceof ListSeparator}
  <svelte:element this={elementType} role="separator" class="mdc-deprecated-list-divider" />
{:else if item instanceof ListItem}
  <ListItemComponent {item} {secondLine} componentType={elementType}/>
  {#if showSeparator}
    <svelte:element this={elementType} role="separator" class="mdc-deprecated-list-divider" />
  {/if}
{/if}

<style lang="scss">
</style>
