// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class ObjectUpdate implements flatbuffers.IUnpackableObject<ObjectUpdateT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ObjectUpdate {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsObjectUpdate(bb:flatbuffers.ByteBuffer, obj?:ObjectUpdate):ObjectUpdate {
  return (obj || new ObjectUpdate()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsObjectUpdate(bb:flatbuffers.ByteBuffer, obj?:ObjectUpdate):ObjectUpdate {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ObjectUpdate()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

identifier():string|null
identifier(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
identifier(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

value(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index) : 0;
}

valueLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

valueArray():Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? new Uint8Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

previousHash():string|null
previousHash(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
previousHash(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startObjectUpdate(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addIdentifier(builder:flatbuffers.Builder, identifierOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, identifierOffset, 0);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, valueOffset, 0);
}

static createValueVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset {
  builder.startVector(1, data.length, 1);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt8(data[i]!);
  }
  return builder.endVector();
}

static startValueVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(1, numElems, 1);
}

static addPreviousHash(builder:flatbuffers.Builder, previousHashOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, previousHashOffset, 0);
}

static endObjectUpdate(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createObjectUpdate(builder:flatbuffers.Builder, identifierOffset:flatbuffers.Offset, valueOffset:flatbuffers.Offset, previousHashOffset:flatbuffers.Offset):flatbuffers.Offset {
  ObjectUpdate.startObjectUpdate(builder);
  ObjectUpdate.addIdentifier(builder, identifierOffset);
  ObjectUpdate.addValue(builder, valueOffset);
  ObjectUpdate.addPreviousHash(builder, previousHashOffset);
  return ObjectUpdate.endObjectUpdate(builder);
}

unpack(): ObjectUpdateT {
  return new ObjectUpdateT(
    this.identifier(),
    this.bb!.createScalarList<number>(this.value.bind(this), this.valueLength()),
    this.previousHash()
  );
}


unpackTo(_o: ObjectUpdateT): void {
  _o.identifier = this.identifier();
  _o.value = this.bb!.createScalarList<number>(this.value.bind(this), this.valueLength());
  _o.previousHash = this.previousHash();
}
}

export class ObjectUpdateT implements flatbuffers.IGeneratedObject {
constructor(
  public identifier: string|Uint8Array|null = null,
  public value: (number)[] = [],
  public previousHash: string|Uint8Array|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const identifier = (this.identifier !== null ? builder.createString(this.identifier!) : 0);
  const value = ObjectUpdate.createValueVector(builder, this.value);
  const previousHash = (this.previousHash !== null ? builder.createString(this.previousHash!) : 0);

  return ObjectUpdate.createObjectUpdate(builder,
    identifier,
    value,
    previousHash
  );
}
}
