<script lang="ts">
    import { WindLoadVariantSettingsContext } from "./PilePositions";
    import { VersionedStore } from "../VersionedStore";
    import DoubleInputProperty from "./DoubleInputProperty.svelte";
    import SwitchButton from "./SwitchButton.svelte";
    import Divider from "../pui/custom-components/Divider.svelte";
    import Icon from "../libui/icon/Icon.svelte";
    import { BooleanProperty, TrackerWindPosition } from "bim-ts";

    export let context: WindLoadVariantSettingsContext;


    $: state = new VersionedStore(context.stateManager.state);
    $: activeVariants = new Set($state.props?.activeWindLoadVariants);
    
    $: interior = activeVariants.has(TrackerWindPosition.Interior);
    $: exterior = activeVariants.has(TrackerWindPosition.Exterior);
    $: edge = activeVariants.has(TrackerWindPosition.Edge);
    $: edgeTopBot = activeVariants.has(TrackerWindPosition.EdgeTop) && activeVariants.has(TrackerWindPosition.EdgeBot);

    function applyChanges(position: TrackerWindPosition[], isActive: boolean) {
        const variants = new Set(activeVariants);
        if(isActive) {
            position.forEach(p => variants.add(p));
        } else {
            // skip interior
            position.filter(p => p !== TrackerWindPosition.Interior).forEach(p => variants.delete(p));
        }
        context.stateManager.applyPatch({activeWindLoadVariants: Array.from(variants)});
    }
</script>

<div class="root">
    <DoubleInputProperty name={"Pile types variants"} changedValue={false}>
        <div class="switch-holder" slot="value1">
            <SwitchButton
                name={"Interior, default"}
                value={interior}
                disabled={true}
                onChange={(v) => {
                    applyChanges([TrackerWindPosition.Interior], v);
                }}
            />
        </div>
    </DoubleInputProperty>
    <DoubleInputProperty name={" "} changedValue={false}>
        <div class="switch-holder" slot="value1">
            <SwitchButton
                name={"Exterior"}
                value={exterior}
                onChange={(v) => {
                    applyChanges([TrackerWindPosition.Exterior], v);
                }}
            />
        </div>
    </DoubleInputProperty>
    <DoubleInputProperty name={" "} changedValue={false}>
        <div class="switch-holder" slot="value1">
            <SwitchButton
                name={"Edge"}
                value={edge}
                onChange={(v) => {
                    if(v) {
                        applyChanges([TrackerWindPosition.Edge], v);
                    } else {
                        applyChanges([TrackerWindPosition.Edge, TrackerWindPosition.EdgeTop, TrackerWindPosition.EdgeBot], v);
                    }
                }}
            />
        </div>
    </DoubleInputProperty>
    <DoubleInputProperty name={" "} changedValue={false}>
        <div class="subswitch-holder" slot="value1">
            <span class="icon-holder">
                <Icon name="HierarchyDown" />
            </span>
            <SwitchButton
                name={"Top-Bottom"}
                value={edge ? edgeTopBot : false}
                onChange={(v) => {
                        applyChanges([TrackerWindPosition.EdgeTop, TrackerWindPosition.EdgeBot], v);
                    }}
                disabled={!edge}
            />
        </div>
    </DoubleInputProperty>
    <div style="padding: 4px 0;">
        <Divider />
    </div>
    <DoubleInputProperty name={" "} changedValue={false} multiline={true}>
        <div class="switch-holder" slot="value1">
            <SwitchButton
                name={"Unique piles coordinates for each wind position"}
                value={!$state.props?.same_piles_offsets.value}
                onChange={(v) => {
                    context.stateManager.applyPatch({same_piles_offsets: BooleanProperty.new({value: !v})});
                }}
                multiline={true}
                disabled={$state.props === null}
            />
        </div>
    </DoubleInputProperty>
</div>

<style lang="scss">
    .root {
        padding: 14px;
        width: calc(100% - 30px);
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .switch-holder {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .subswitch-holder {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
    }
    .icon-holder {
        padding-bottom: 4px;
    }
</style>
