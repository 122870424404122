import { Aabb2, Matrix3, Vector2 } from "math-ts";
import { Anchor2D, Object2D, VectorPrimitive, VectorPrimitiveCircle, VectorPrimitivePath, VectorPrimitivePathDescription, VectorPrimitiveText } from ".";

export function createTextLabelWithPointer(params: {
    text: string,
    fontSize: number,
    pointerWidth: number,
    offset: number,
    angle?: number,
}) {
    const obj = new Object2D();
    obj.name = 'pointer-text'

    const zeroPoint = new Vector2();
    const pointerMidPoint = new Vector2(0, -params.offset / 3 * 2);
    const defaultAngle = Math.PI / 180 * 30

    const pointerRotate = Math.PI / 2 - (params.angle ?? defaultAngle);
    const textPoint = new Vector2()
        .copy(pointerMidPoint)
        .addScaledVector(pointerMidPoint, 0.5)
        .rotateAround(pointerMidPoint, pointerRotate)

    // add pointer
    const pointerPrimitive = new VectorPrimitivePath({
        strokeWidth: params.pointerWidth,
        paths: [new VectorPrimitivePathDescription([zeroPoint, pointerMidPoint, textPoint])],
    })
    obj.primitives.push(pointerPrimitive);

    // add circle
    const circle = new VectorPrimitiveCircle({
        radius: params.pointerWidth * 2,
        fill: 'black',
    })
    obj.primitives.push(circle);

    // add text
    const textPrimitive = new VectorPrimitiveText({
        fontSize: params.fontSize,
        text: params.text,
        x: textPoint.x,
        y: textPoint.y,
        anchor: Math.sin(pointerRotate) > 0 ? 'start' : 'end',
    })
    obj.primitives.push(textPrimitive);

    obj.recalculateAabb(false, true);

    return obj;
}

export function createDoubleArrow(params: {
    strokeWidth: number,
}) {
    const obj = new Anchor2D();
    obj.name = 'double-arrow';

    const delta = params.strokeWidth * 2;
    const length = delta * 1.1;
    const primitive = new VectorPrimitivePath({
        paths: [
            new VectorPrimitivePathDescription([
                new Vector2(-delta, -delta),
                new Vector2(0, 0),
                new Vector2(-delta, +delta),
            ]),
            new VectorPrimitivePathDescription([
                new Vector2(length - delta, -delta),
                new Vector2(length, 0),
                new Vector2(length - delta, +delta),
            ]),
        ],
        strokeWidth: params.strokeWidth,
    })
    obj.primitives.push(primitive);

    obj.point.set(length, 0);

    obj.updateMatrix(true);
    obj.recalculateAabb(true, true);

    return obj;
}

export function createTreeDots(params: {
    vertical?: boolean,
    length: number,
    x: number,
    y: number,
}): VectorPrimitive[] {
    const primitives: VectorPrimitive[] = [];
    let diff  = new Vector2()

    if (params.vertical) {
        diff.y += params.length / 2
    } else {
        diff.x += params.length / 2
    }

    const ptMid = new Vector2(params.x, params.y);
    const ptLeft = new Vector2(params.x - diff.x, params.y - diff.y);
    const ptRight = new Vector2(params.x + diff.x, params.y + diff.y);

    for (const point of [ptMid, ptLeft, ptRight]) {
        primitives.push(new VectorPrimitiveCircle({
            radius: params.length / 10,
            fill: 'black',
            cx: point.x,
            cy: point.y,
        }))
    }
    return primitives;
}

export function createAabbLabel(params: {
    aabb: Aabb2,
    side: 'bottom' | 'left' | 'right' | 'top',
    fontSize: number,
    text: string,
}) {
    const aabb = params.aabb;
    const fontSize = params.fontSize;
    const label = new VectorPrimitiveText({
        fontSize: params.fontSize,
        text: params.text,
    });
    if (params.side === 'bottom') {
        label.x = aabb.centerX();
        label.y = aabb.max.y + params.fontSize;
        label.anchor = 'middle';
        label.verticalAlignment = 'hanging';
    } else if (params.side === 'left') {
        label.matrix = new Matrix3()
            .rotate(Math.PI / 2)
            .translate(aabb.min.x - fontSize, aabb.centerY())
        label.anchor = 'middle';
        label.verticalAlignment = 'text-after-edge';
    } else if (params.side === 'top') {
        label.anchor = 'middle';
        label.verticalAlignment = 'text-after-edge';
        label.y = aabb.min.y - fontSize;
        label.x = aabb.centerX();
    }
    return label;
}
