import type {
    Bim, DC_CNSTS,
    GaugePack,
    IdBimScene, SceneInstance
} from 'bim-ts';
import { DefaultMap } from 'engine-utils-ts';
import { Vector2 } from 'math-ts';
import { ANGLE_RAD_EPSILON } from '../../constants';
import type { TrackerConfig} from '../../props-gather';
import {
    getTrackerConfig, parseMultiHarnessConfigFromInstance,
    parsePatternFromInstance
} from '../../props-gather';
import { EquipmentType, equipmentType } from './types';

export function getAllTransformersBelowSi(
    siIds: IdBimScene[],
    bim: Bim,
) {
    const seenIds: Set<IdBimScene> = new Set();
    const transformerIds: Set<IdBimScene> = new Set();
    for (const siId of siIds) {
        bim.instances.spatialHierarchy
            .traverseRootToLeavesDepthFirstFrom(siId, siId => {
                if (seenIds.has(siId))
                    return false;
                seenIds.add(siId);
                const si = bim.instances.perId.get(siId);
                if (!si)
                    return false;
				const ty = equipmentType(si);

                if (ty === null)
                    return true;
                const isSiStandAfterTransformer = [
                    EquipmentType.CombinerBox,
                    EquipmentType.Inverter,
                    EquipmentType.Tracker,
                ].includes(ty);
                if (isSiStandAfterTransformer)
                    return false;
                if (ty === EquipmentType.Transformer)
                    transformerIds.add(siId);
                return true;
            });
    }
    return transformerIds;
}

export function getAllTransformerIds(bim: Bim) {
    const roots = bim.instances.spatialHierarchy._rootObjects;
    return getAllTransformersBelowSi(Array.from(roots.keys()), bim);
}

type TrackerConfigWithId = TrackerConfig & { id: IdBimScene };
export class BimOperations {
    private bim: Bim;
    private gaugePack: GaugePack;
    private transformers: IdBimScene[]
    private minTrackerWidth = 5;

    patternConfigs:
        DefaultMap<IdBimScene, DC_CNSTS.PatternConfig>;
    trackerGroupingHints: DefaultMap<IdBimScene, Vector2[]>;
    trackerConfigs: DefaultMap<IdBimScene, TrackerConfigWithId>;
    selectedTransformers: Set<IdBimScene>;

    public _globalTrackerAngle?: number;
    set globalTrackerAngle(val: number) {
        if (typeof this._globalTrackerAngle === 'undefined')
            this._globalTrackerAngle = val;
        if (Math.sin(this._globalTrackerAngle - val) < Math.sin(ANGLE_RAD_EPSILON)) {
            return;
        }
        throw new Error([
            'Trackers with multiple angles found.',
            'Tracker rotation angle should be the same on all of the tracker.',
        ].join('\n'));

    }
    get globalTrackerAngle(): number {
        if (typeof this._globalTrackerAngle === 'undefined')
            throw new Error('No global tracker angle set.');
        return this._globalTrackerAngle;
    }

    constructor(bim: Bim, gaugePack: GaugePack, transformers: IdBimScene[]) {
        this.bim = bim;
        this.transformers = transformers;
        this.gaugePack = gaugePack;
        this.patternConfigs = new DefaultMap(id => {
            const si = this.bim.instances.perId.get(id);
            if (!si)
                throw new Error('scene instance with pattern config not exist');
            const config = parsePatternFromInstance(si);
            if (!config) {
                return this.patternConfigs
                    .getOrCreate(si.spatialParentId);
            }
            const sampleGauge = gaugePack.sortedGauges[0];
            const sampleGaugeId = gaugePack.gaugeIds.get(sampleGauge);
            if (!sampleGauge || sampleGaugeId === undefined || sampleGaugeId < 0) {
                throw new Error('sample gauge not found');
            }
            config.defaultCables.forEach(x => {
                if (x.gauge < 0) {
                    x.gauge = sampleGaugeId;
                }
            })
            const missingCnt = config.pattern.conductors.length - config.defaultCables.length;
            for (let i = 0; i < missingCnt; i++) {
                config.defaultCables.push({
                    gauge: sampleGaugeId
                })
            }
            const multiharnessConfig =
                parseMultiHarnessConfigFromInstance(si);
            config.multiharness = multiharnessConfig ?? undefined;
            return config;
        });
        this.trackerConfigs = new DefaultMap(id => {
            const si = this.bim.instances.perId.get(id);
            if (!si || equipmentType(si) !== EquipmentType.Tracker) {
                throw new Error([
                    `Scene instance with id ${id}`,
                    'is not a tracker',
                ].join(' '));
            }
            const config = getTrackerConfig(si);
            if (!config) throw new Error('Tracker config was not parsed');
            return { ...config, id };
        });
        this.trackerGroupingHints = new DefaultMap<IdBimScene, Vector2[]>(id => {
            throw new Error('no grouping node for tracker ' + id);
        });
        this.selectedTransformers = new Set(this.transformers);
        // iterate all instances to gather extra statistics.
        for (const id of this.selectedTransformers) {
            this.bim.instances.spatialHierarchy
                .traverseRootToLeavesDepthFirstFrom(id, id => {
                    const si = this.bim.instances.perId.get(id);
                    if (!si) return false;
                    // calculate pattern config
                    this.patternConfigs.getOrCreate(id);
                    // calculate tracker config
                    if (equipmentType(si)=== EquipmentType.Tracker) {
                        this.gatherInitialConfigsFromTracker(id, si);
                    }
                    return true;
                });
        }
        this.populateTrackerGroupingHints();
    }

    private gatherInitialConfigsFromTracker(
        id: IdBimScene,
        si: SceneInstance,
    ) {
        // save rotated tracker config
        const config = this.trackerConfigs.getOrCreate(id);
        // populate global tracker angle
        const angle = config.rotationZ;
        this.globalTrackerAngle = angle;
        this.rotateTrackerConfig(config);
        // search for smallest tracker width
        if (config.tracker.maxWidth < this.minTrackerWidth)
            this.minTrackerWidth = config.tracker.maxWidth;
    }

    private rotateTrackerConfig(config: TrackerConfig) {
        this.globalAngleFix(config.tracker.minPoint);
        this.globalAngleFix(config.tracker.maxPoint);
        config.tracker.hints = [
            config.tracker.minPoint,
            config.tracker.maxPoint,
        ];
    }

    private groupTransformerConfigsByNeibours() {
        const groups: Array<TrackerConfigWithId[]> = [];
        const configs = Array.from(this.trackerConfigs.values());
        if (!configs.length) return groups;
        configs.sort((a,b) => a.tracker.minPoint.x - b.tracker.minPoint.x);
        groups.push([configs[0]]);
        if (configs.length < 2) return groups;
        for (let i = 1; i < configs.length; i++) {
            const current = configs[i];
            const neighbor = groups[groups.length - 1][0];
            const neighborDist = Math.abs(
                current.tracker.maxPoint.x -
                neighbor.tracker.maxPoint.x,
            );
            if (neighborDist < this.minTrackerWidth / 2) {
                groups[groups.length - 1].push(current);
                continue;
            }
            groups.push([current]);
        }
        return groups;
    }


    private populateTrackerGroupingHints() {
        const groups = this.groupTransformerConfigsByNeibours();
        this.trackerGroupingHints.clear();
        for (const group of groups) {
            const hints: Vector2[] = [];
            for (const config of group)
                hints.push(config.tracker.minPoint, config.tracker.maxPoint);
            for (const config of group) {
                this.trackerGroupingHints.set(config.id, hints);
            }
        }
    }

    private getFirstTransformersAboveSi(siIds: IdBimScene[]) {
        const seenIds: Set<IdBimScene> = new Set();
        const transformerIds: Set<IdBimScene> = new Set();
        for (const siId of siIds) {
            this.bim.instances.spatialHierarchy.traverseLeaveToRoot(
                siId, siId => {
                    if (seenIds.has(siId))
                        return false;
                    seenIds.add(siId);
                    const si = this.bim.instances.perId.get(siId);
                    if (!si) return false;
                    if (equipmentType(si) === EquipmentType.Transformer) {
                        transformerIds.add(siId);
                        return false;
                    }
                    return true;
                },
            );
        }
        return transformerIds;
    }

    private getTransformerIdsFromSelected() {
        const transformerIds: Set<IdBimScene> = new Set();
        const selected = this.bim.instances.getSelected();
        const transformersBelowSelected =
            getAllTransformersBelowSi(selected, this.bim);
        transformersBelowSelected.forEach(x => transformerIds.add(x));
        const transformersAboveSelected =
            this.getFirstTransformersAboveSi(selected);
        transformersAboveSelected.forEach(x => transformerIds.add(x));
        return transformerIds;
    }

    private getTransformerIds() {
        const trackerIds: IdBimScene[] = [];
        if (this.bim.instances.anySelected())
            trackerIds.push(...this.getTransformerIdsFromSelected());
        else
            trackerIds.push(...getAllTransformerIds(this.bim));
        return trackerIds.filter(x => this.bim.instances.spatialHierarchy
            ._allObjects.get(x)?.children?.length);
    }

    globalAngleFix(point: Vector2) {
        point.rotateAround(new Vector2(0, 0), -this.globalTrackerAngle);
    }

}
