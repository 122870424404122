import { LegacyLogger } from 'engine-utils-ts';
import { Quaternion, Vector3 } from 'math-ts';

import { createIndexArrayOfSize, CreateKrGeometryFromData } from './GeometryUtils';
import type { GeometryGpuRepr } from './KrBufferGeometry';

export function generatePolylineGeometry(line: {radius: number, points: Vector3[]}, segments: number): GeometryGpuRepr {
	LegacyLogger.assert(line.points.length >= 2, ' line should have 2 or more points');
	LegacyLogger.assert(segments >= 4, ' polyline should have 4 or more segments');
	LegacyLogger.assert(segments % 2 === 0, ' polyline should have even number of  segments');

	const basePoints2d: number[] = calculateCirclePoints2d(segments);
	const pointsInCircle = segments + 1;
	LegacyLogger.assert(basePoints2d.length / 2 === pointsInCircle, 'base points - segments length check');

	const tubePointsN = line.points.length * pointsInCircle;
	const stubPointsN = pointsInCircle;
	const meshPointsN = tubePointsN + stubPointsN * 2;

	const points = new Float32Array(meshPointsN * 3);
	const normals = new Int8Array(meshPointsN * 3);

	const v = Vector3.zero();
	const radius = line.radius;

	const linePoints = line.points;
	//todo: get rid of mapping to three vectors here
	const rotations = calcQuaternionRotationsForLine_t(linePoints);

	for (let pointInd = 0; pointInd < linePoints.length; ++pointInd) { // tube vertices

		const point = linePoints[pointInd];
		const rot = rotations[pointInd];

		const circlePointsOffset = pointInd * pointsInCircle;

		for (let i = 0; i < pointsInCircle; ++i){
			const circleI2 = i * 2;
			v.x = basePoints2d[circleI2];
			v.y = 0;
			v.z = basePoints2d[circleI2 + 1];

			v.applyQuaternion(rot);

			const i3 = circlePointsOffset * 3 + i * 3;

			v.normalize();
			normals[i3 + 0] = (v.x * 127) | 0;
			normals[i3 + 1] = (v.y * 127) | 0;
			normals[i3 + 2] = (v.z * 127) | 0;

			v.multiplyScalar(radius);
			
			v.add(point);
	
			points[i3 + 0] = v.x;
			points[i3 + 1] = v.y;
			points[i3 + 2] = v.z;
		}
	}

	
	for (let circleInd = 0; circleInd < 2; ++circleInd){ // populate first and last plane circles vertices at the endes of the tube
		const isFirstCircle = circleInd === 0;

		const rot = isFirstCircle ? rotations[0] : rotations[rotations.length - 1];

		if (isFirstCircle) {
			v.copy(linePoints[0]);
			v.sub(linePoints[1]);
			v.normalize();
		} else {
			v.copy(linePoints[linePoints.length - 1]);
			v.sub(linePoints[linePoints.length - 2]);
			v.normalize();
		}
		const nx = (v.x * 127) | 0;
		const ny = (v.y * 127) | 0;
		const nz = (v.z * 127) | 0;


		const pointsOffset = isFirstCircle ? tubePointsN : tubePointsN + pointsInCircle;

		for (let i = 0; i < pointsInCircle; ++i){
			const circleI2 = i * 2;
			
			v.x = basePoints2d[circleI2];
			v.y = 0;
			v.z = basePoints2d[circleI2 + 1];
			v.applyQuaternion(rot);

			v.normalize().multiplyScalar(radius);
			if (isFirstCircle) {
				v.add(linePoints[0]);
			} else {
				v.add(linePoints[linePoints.length - 1]);
			}

			const i3 = (pointsOffset + i) * 3;
			
			points[i3 + 0] = v.x;
			points[i3 + 1] = v.y;
			points[i3 + 2] = v.z;

			normals[i3 + 0] = nx;
			normals[i3 + 1] = ny;
			normals[i3 + 2] = nz;
		}
	}

	const tubeIndsN = (line.points.length - 1) * segments * 6;
	const stubIndsN = (segments - 2) * 3;
	const indsNumber = tubeIndsN + stubIndsN * 2;
	const indices = createIndexArrayOfSize(indsNumber, meshPointsN);

	/*
	tube indices pattern is (imagine there are 3 points in circle, which is not valid, but anyway)
	6-7-8
	|\|\|
	3-4-5
	|\|\|
	0-1-2
	*/
	for (let pointInd = 0; pointInd < line.points.length - 1; ++pointInd){

		const stripPointsOffset = (pointInd) * pointsInCircle;
		const stripIndsOffset = pointInd * segments * 6;

		for (let i = 0; i < segments; ++i){
			const faceOffset = stripIndsOffset + i * 6;
			
			const currentPointsOffset = stripPointsOffset + i;

			indices[faceOffset + 0] = currentPointsOffset + 0;
			indices[faceOffset + 1] = currentPointsOffset + 1;
			indices[faceOffset + 2] = currentPointsOffset + pointsInCircle;

			indices[faceOffset + 3] = currentPointsOffset + 1;
			indices[faceOffset + 4] = currentPointsOffset + pointsInCircle + 1;
			indices[faceOffset + 5] = currentPointsOffset + pointsInCircle;
		}
		//todo: snake like reversal of next layer
	}

	for (let stubI = 0; stubI < 2; ++stubI){
		const stubOffset = tubeIndsN + stubI * stubIndsN;
		const pointsOffset = tubePointsN + stubI * stubPointsN;
		if (stubI === 0) {
			for (let i = 1; i < segments; ++i){
				const i3 = stubOffset + (i-1) * 3;
				indices[i3 + 0] = pointsOffset + i + 1;
				indices[i3 + 1] = pointsOffset + i;
				indices[i3 + 2] = pointsOffset;
			}
		} else {
			for (let i = 1; i < segments; ++i){
				const i3 = stubOffset + (i-1) * 3;
				indices[i3 + 0] = pointsOffset + i;
				indices[i3 + 1] = pointsOffset + i + 1;
				indices[i3 + 2] = pointsOffset;
			}
		}
		
	}
	
	const edgesIndexSize = segments * 2 * 2 + (line.points.length - 1) * 2 * 2;
	const edgeIndices = createIndexArrayOfSize(edgesIndexSize, meshPointsN);

	let edgeInd = 0; // current edge index

	for (let i = 0; i < segments; ++i){ // line between points of 1st circle
		edgeIndices[edgeInd + 0] = i; 
		edgeIndices[edgeInd + 1] = i + 1;
		edgeInd += 2;
	}
	for (let i = 0; i < line.points.length - 1; ++i) { // line between last points of circles
		edgeIndices[edgeInd + 0] = (i + 1) * pointsInCircle - 1; 
		edgeIndices[edgeInd + 1] = (i + 2) * pointsInCircle - 1;
		edgeInd += 2;
	}
	for (let i = 0; i < line.points.length - 1; ++i){ // line between middle points of circles
		edgeIndices[edgeInd + 0] = (i + 0) * pointsInCircle + segments / 2; 
		edgeIndices[edgeInd + 1] = (i + 1) * pointsInCircle + segments / 2;
		edgeInd += 2;
	}
	const pointsOffsetToLastCircle = (line.points.length - 1) * pointsInCircle;
	for (let i = 0; i < segments; ++i){ // line between points of last circle
		edgeIndices[edgeInd + 0] = pointsOffsetToLastCircle + i; 
		edgeIndices[edgeInd + 1] = pointsOffsetToLastCircle + i + 1;
		edgeInd += 2;
	}
	
	LegacyLogger.assert(edgeInd === edgeIndices.length, 'all edge indices where used');
	
	const g = CreateKrGeometryFromData({
		vertices: points,
		uvs: undefined,
		normals: normals,
		indices: indices,
		edgesInds: edgeIndices,
	});

	return g;
}

export function calcQuaternionRotationsForLine_t(points: Vector3[]): Quaternion[] {
	LegacyLogger.assert(points.length >= 2, ' should be 2 or more points in line');

	const ups_t = calcUpVectorsForLineSmooth_t(points);
	const relativeRotations_t = calcRelativeRotationsFromUps_t(ups_t);
	
	// mutate relative rotations to be absolute
	let prevRot = relativeRotations_t[0];
	for (let i = 1; i < relativeRotations_t.length; ++i){
		const currRot = relativeRotations_t[i];
		currRot.multiply(prevRot);
		currRot.normalize();
		prevRot = currRot;
	}
	return relativeRotations_t;
}

function calcRelativeRotationsFromUps_t(ups: Vector3[]): Quaternion[] {
	const rotations_t: Quaternion[] = [];
	let prevUp_t = new Vector3(0, 1, 0);
	for (const up of ups) {
		const rotation_t = Quaternion.fromUnitVectors(prevUp_t, up);
		rotations_t.push(rotation_t);
		prevUp_t = up;
	}
	return rotations_t;
}

export function calcUpVectorsForLineSmooth_t(points: Vector3[]): Vector3[] {
	LegacyLogger.assert(points.length >= 2, ' should be 2 or more points in line');

	const upVectors: Vector3[] = [];

	for (let i = 0; i < points.length; ++i) {
		let up_t: Vector3;
		if (i === 0) {

			up_t = Vector3.subVectors(points[i + 1], points[i]);
		
		} else if (i === points.length - 1) {
			
			up_t = Vector3.subVectors(points[i], points[i - 1]);
		
		} else {
		
			const prevLineDir_t = Vector3.subVectors(points[i], points[i - 1]).normalize();
			const nexLineDir_t = Vector3.subVectors(points[i + 1], points[i]).normalize();

			up_t = Vector3.addVectors(prevLineDir_t, nexLineDir_t);			
		}
		up_t.normalize();
		upVectors.push(up_t);
	}

	return upVectors;
}

function calculateCirclePoints2d(segmentsN: number): number[] {
	LegacyLogger.assert(segmentsN > 2, 'should be more than 2 segments in circe');
	const points2d: number[] = [];
	for (let i = 0; i < segmentsN; ++i){
		const angle = Math.PI * 2 / segmentsN * i;
		const x = Math.sin(angle);
		const y = Math.cos(angle);
		points2d.push(x);
		points2d.push(y);
	}
	points2d.push(Math.sin(0));
	points2d.push(Math.cos(0));
	return points2d;
}
