// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

export class FlatBuffersTransformer {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):FlatBuffersTransformer {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsFlatBuffersTransformer(bb:flatbuffers.ByteBuffer, obj?:FlatBuffersTransformer):FlatBuffersTransformer {
  return (obj || new FlatBuffersTransformer()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsFlatBuffersTransformer(bb:flatbuffers.ByteBuffer, obj?:FlatBuffersTransformer):FlatBuffersTransformer {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new FlatBuffersTransformer()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

name():string|null
name(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
name(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

origin(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readFloat64(this.bb!.__vector(this.bb_pos + offset) + index * 8) : 0;
}

originLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

originArray():Float64Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? new Float64Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

id():string|null
id(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
id(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

rotation():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

blockNumber():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
}

static startFlatBuffersTransformer(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addName(builder:flatbuffers.Builder, nameOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, nameOffset, 0);
}

static addOrigin(builder:flatbuffers.Builder, originOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, originOffset, 0);
}

static createOriginVector(builder:flatbuffers.Builder, data:number[]|Float64Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createOriginVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createOriginVector(builder:flatbuffers.Builder, data:number[]|Float64Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(8, data.length, 8);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addFloat64(data[i]!);
  }
  return builder.endVector();
}

static startOriginVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(8, numElems, 8);
}

static addId(builder:flatbuffers.Builder, idOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, idOffset, 0);
}

static addRotation(builder:flatbuffers.Builder, rotation:number) {
  builder.addFieldFloat64(3, rotation, 0.0);
}

static addBlockNumber(builder:flatbuffers.Builder, blockNumber:number) {
  builder.addFieldInt32(4, blockNumber, 0);
}

static endFlatBuffersTransformer(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createFlatBuffersTransformer(builder:flatbuffers.Builder, nameOffset:flatbuffers.Offset, originOffset:flatbuffers.Offset, idOffset:flatbuffers.Offset, rotation:number, blockNumber:number):flatbuffers.Offset {
  FlatBuffersTransformer.startFlatBuffersTransformer(builder);
  FlatBuffersTransformer.addName(builder, nameOffset);
  FlatBuffersTransformer.addOrigin(builder, originOffset);
  FlatBuffersTransformer.addId(builder, idOffset);
  FlatBuffersTransformer.addRotation(builder, rotation);
  FlatBuffersTransformer.addBlockNumber(builder, blockNumber);
  return FlatBuffersTransformer.endFlatBuffersTransformer(builder);
}
}
