<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.1 2.5C8.82386 2.5 8.6 2.72386 8.6 3V8.6H3C2.72386 8.6 2.5 8.82386 2.5 9.1V10.9C2.5 11.1761 2.72386 11.4 3 11.4H8.6V17C8.6 17.2761 8.82386 17.5 9.1 17.5H10.9C11.1761 17.5 11.4 17.2761 11.4 17V11.4H17C17.2761 11.4 17.5 11.1761 17.5 10.9V9.1C17.5 8.82386 17.2761 8.6 17 8.6H11.4V3C11.4 2.72386 11.1761 2.5 10.9 2.5H9.1Z"
        fill="currentColor"
    />
</svg>
