import { ExecutionThreadPreference, JobExecutor, registerExecutor } from 'engine-utils-ts';
import type { Point } from './regular-filler/solver';


export interface TrackerInstallerArgs {
    contours: Point[][],
    tracker_values: number[],
    tracker_heights: number[],
    tracker_width: number,
    row_to_row: number,
    road_segments: [Point, Point][],
    road_width: number,
}

export interface Tracker {
    x: number,
    y: number,
    i: number,
}

export interface TrackerInstallerResult {
    trackers: Tracker[];
}

export class TrackerInstallerExecutor extends JobExecutor<TrackerInstallerArgs, TrackerInstallerResult> {
    execute(args: TrackerInstallerArgs): TrackerInstallerResult {
        return { trackers: [] };
    }

    executionPreference(_data: TrackerInstallerArgs): ExecutionThreadPreference {
        return ExecutionThreadPreference.WorkerThread;
    }
}
registerExecutor(TrackerInstallerExecutor);