

import type {
    Bim, Catalog, IdBimScene, TrackerPositionsConfig
} from 'bim-ts';
import {
    AnyTrackerProps,
    FixedTiltTypeIdent,
    PilesConfigsPerWindPosition,
    TrackerPositionType,
    TrackerTypeIdent,
} from 'bim-ts';
import type { LazyVersioned } from 'engine-utils-ts';
import { type TasksRunner, Immer, LazyBasic, LazyDerived, ObjectUtils, ScopedLogger } from 'engine-utils-ts';
import { type PUI_Builder, type UiBindings } from 'ui-bindings';
import { getSceneInstancesInvalidator } from '../panels-config-ui/GeneratePanelUiBindings';
import { removeDeletedSceneInstances } from '../panels-config-ui/RemoveDeletedSceneInstances';
import type {
    TrackerPositionInput
} from './TrackerWindPositionService';
import {
    runTrackerWindPositionSolver,
    TrackerTypes
} from './TrackerWindPositionService';

interface TrackerWindPositionWarnings {
    hasConfiguration?: string;
    hasNoConfiguration?: string;
}

export class TrackerWindPositionUiContext {
    readonly logger: ScopedLogger;
    readonly props: LazyVersioned<TrackerPositionsConfig>;
    readonly isGeneratingAvailable = new LazyBasic("is-generating", true);
    readonly warnings: LazyVersioned<TrackerWindPositionWarnings>;

    constructor(
        readonly bim: Bim,
        readonly catalog: Catalog,
        readonly ui: UiBindings, 
        readonly tasksRunner: TasksRunner,
        logger?: ScopedLogger,
    ) {
        this.logger = logger ? logger.newScope("tracker-wind-position-panel") : new ScopedLogger("tracker-wind-position-panel"); 
        const selectedItemsLazy = getSceneInstancesInvalidator(
            bim,
            TrackerPositionType
        );
        const lazyConfig = bim.configs.getLazySingletonOf({
            type_identifier: TrackerPositionType,
        });

        this.props = LazyDerived.new2(
            "tracker-wind-position-props",
            null,
            [lazyConfig, selectedItemsLazy],
            ([config, _selectedIds]) => {
                let props = config.get<TrackerPositionsConfig>();
                removeDeletedSceneInstances(
                    props,
                    bim,
                    this.logger,
                    [],
                    (updatedConfig) => {
                        props = updatedConfig;
                        bim.configs.applyPatchToSingleton(TrackerPositionType, {
                            properties: updatedConfig,
                        });
                    }
                );

                return props;
            }
        );
        const trackers = bim.instances.getLazyListOfTypes({type_identifiers: ["any-tracker"]});

        this.warnings = LazyDerived.new1(
            "tracker-wind-position-warnings",
            null,
            [trackers],
            ([trackers]) => {

                const hasExterior = new Set<string>();
                const hasNoExterior  = new Set<string>();
                const hasEdge = new Set<string>();
                const hasNoEdge = new Set<string>();

                for (const [id, inst] of trackers) {
                    const name = catalog.keyPropertiesGroupFormatters.format(
                        inst.type_identifier,
                        inst.properties,
                        inst.props,
                    );
                    if(!name){
                        this.logger.batchedError("equipment name not found", [id, inst]);
                        continue;
                    }
                    
                    const props = inst.propsAs(AnyTrackerProps);
                    if(!(props.tracker_frame.piles_configurations instanceof PilesConfigsPerWindPosition)) {
                        continue;
                    }

                    if(props.tracker_frame.piles_configurations.exterior){
                        hasExterior.add(name);
                    } else {
                        hasNoExterior.add(name);
                    }

                    if(props.tracker_frame.piles_configurations.edge){
                        hasEdge.add(name);
                    } else {
                        hasNoEdge.add(name);
                    }
                }
                const hasConfiguration = new Set([...hasExterior, ...hasEdge]);
                const hasConfigurationTypes = hasExterior.size > 0 && hasEdge.size > 0 ? "Edge, Exterior" : hasExterior.size > 0 ? "Exterior" : "Edge";
                const hasNoConfiguration = new Set([...hasNoExterior, ...hasNoEdge]);;
                const hasNoConfigurationTypes = hasNoExterior.size > 0 && hasNoEdge.size > 0 ? "Edge, Exterior" : hasNoExterior.size > 0 ? "Exterior" : "Edge";
                return {
                    hasConfiguration: hasConfiguration.size > 0 
                        ? `${Array.from(hasConfiguration).join(", ")} trackers have ${hasConfigurationTypes} piles configuration. Consider enabling these options for wind position calculation.`
                        : undefined,
                    hasNoConfiguration: hasNoConfiguration.size > 0 
                        ? `${Array.from(hasNoConfiguration).join(", ")} have no ${hasNoConfigurationTypes} piles configuration. Substitutions rules:<br />
                            • Edge configuration will be used for Edge-Top/Bottom wind positions if they are not configured<br />  
                            • Exterior configuration for Edge wind position<br />
                            • Interior configuration for Exterior wind position<br />
                            Open tracker piles setup to configure wind positions available for the trackers.
                        `
                        : undefined,
                };
            }
        );
    }

    updateConfig(cb: (config: TrackerPositionsConfig) => void) {
        const config = this.props.poll();
        const updatedProps = Immer.produce(config, (draft) => {
            cb(draft);
        });
        this.bim.configs.applyPatchToSingleton(TrackerPositionType, {
            properties: updatedProps,
        });
    }

    async setWindPosition() {
        const config = this.props.poll();

        if (config) {
            const allTrackers = this.bim.instances.peekByTypeIdents(TrackerTypes).map(x => x[0]);

            const input: TrackerPositionInput = {
                colorize: false,
                gapXMeter: config.parameters.gapX.as("m"),
                offsetXMeter: config.parameters.offsetX.as('m'),
                gapYMeter: config.parameters.gapY.as("m"),
                offsetYMeter: config.parameters.offsetY.as('m'),
                trackerIds: config.equipment.trackers.value.length === 0 
                    ? allTrackers
                    : config.equipment.trackers.value,
                exterior_arrays: config.settings.exterior_arrays.value,
                edge_arrays: config.settings.edge_arrays.value,
                edge_top_bot_arrays: config.settings.edge_top_bot_arrays.value,
            };
            this.logger.debug("run generate", [input, config]);

            this.isGeneratingAvailable.replaceWith(false);
            await runTrackerWindPositionSolver(
                ObjectUtils.deepCloneObj(input),
                this.tasksRunner,
                this.ui,
                this.bim
            ).finally(() => {
                this.isGeneratingAvailable.replaceWith(true);
            });
        } else {
            this.logger.error("tracker wind position config not found");
        }
    }
}

export function createTrackerPositionsUi(
    ui: UiBindings,
    tasksRunner: TasksRunner,
    scopedLogger: ScopedLogger,
    bim: Bim,
    catalog: Catalog,
) { 
    const context = new TrackerWindPositionUiContext(bim, catalog, ui, tasksRunner, scopedLogger);

    return {
        puiCallback: (builder: PUI_Builder) => {
            builder.addCustomGroup({
                name: "tracker-positions",
                children: {},
                context: context,
            });
        }
    }
}