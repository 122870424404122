import { Success, type ScopedLogger} from "engine-utils-ts";
import type { Result } from "engine-utils-ts";
import { Vector3, Aabb, Matrix4 } from "math-ts";
import { EntitiesInterned } from "../resources/EntitiesInterned";
import { EngineGeoType } from "./AllEngineGeometries";
import { EngineGeometry } from "./EngineGeometry";
import type { PlaneGeneratorParams } from "./GeometryGenerator";
import { GeometryGenerator } from "./GeometryGenerator";
import type { GeometryGpuRepr} from "./KrBufferGeometry";
import { KrEdgedGeoEmpty} from "./KrBufferGeometry";
import type { PlaneGeo } from "./EngineGeoPlane";
import type { KrCamera } from "src/controls/MovementControls";
import type { RaySection } from "src/structs/RaySection";
import type { GeometryIntersection } from "./GeometryUtils";
import { ShaderFlags } from "src/shaders/ShaderFlags";

export class EngineGeoTrackerPileBillboard extends EngineGeometry {

    constructor(
        public readonly sourcePlane: PlaneGeo | null = null,
    ) {
        super();
    }
    
    calcAabb(): Aabb {
        const gpuRepr = this.asGpuRepr();

        if(!this.sourcePlane) {
            return gpuRepr.aabb;
        }

        const centerVector = new Vector3();
		gpuRepr.aabb.getCenter(centerVector);

        //billboard texture always has a little padding to it, so we scale down aab size a little
        const maxSide = Math.max(this.sourcePlane.width, this.sourcePlane.height) * 0.9;
        const sizeVector = new Vector3(maxSide, maxSide, maxSide);
        return Aabb.empty().setFromCenterAndSize(centerVector, sizeVector);
    }

    raycast(ray: RaySection, modelMatrix: Matrix4, camera: KrCamera): GeometryIntersection[] {
        reusedMatrix4.extractRotation(camera.matrixWorld);
        modelMatrix.multiply(reusedMatrix4);
        return super.raycast(ray, modelMatrix, camera);
    }

    _calcGpuRepr(): GeometryGpuRepr {
		if (!this.sourcePlane) {
			return KrEdgedGeoEmpty;
		}
		const triangulation = GeometryGenerator.generatePlane(this.sourcePlane, ShaderFlags.IS_TRACKER_PILE_BILLBOARD_GEO);
        return triangulation
	}
}

export class EngineTrackerPileBillboardGeometries extends EntitiesInterned<EngineGeoTrackerPileBillboard, EngineGeoType, PlaneGeo, number>{

    constructor(
        logger: ScopedLogger,
    ) {
        super({
            logger,
            identifier: 'engine-tracker-pile-billboard-geos',
            idsType: EngineGeoType.TrackerPileBillboardGeometry,
            uniqueReducerFn: (plane) => JSON.stringify(plane),
            T_Constructor: EngineGeoTrackerPileBillboard,
        });
    }

    convertFromInternedType(bimObj: PlaneGeneratorParams): Result<EngineGeoTrackerPileBillboard> {
        return new Success(new EngineGeoTrackerPileBillboard(bimObj));
    }

    checkForErrors(t: EngineGeoTrackerPileBillboard, errors: string[]): void {
        if (t.aabb().isEmpty()) {
            errors.push('empty aabb');
        }
    }
}

const reusedMatrix4: Matrix4 = new Matrix4();