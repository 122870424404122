import { get } from 'svelte/store';
import type { Bim } from "bim-ts";
import { ProjectVersion, type VerDataSyncer } from "verdata-ts";
import { LazyDerived, ObservableObject, type LazyVersioned } from 'engine-utils-ts';
import { type PUI_GroupNode, PUI_Builder } from 'ui-bindings';
import type { ProjectHub } from "../ProjectHub";
import { ProjectStatus } from '../projects';
import type { ProjectVersionParams } from './ProjectVersionParams';


interface NewProjectForm {
    description: string;
    projectName: string;
    projectStatus: ProjectStatus;
    isShared: boolean;
}

const metricNames = {
    dcPower: 'DC Power',
    acPower: 'AC Power',
    footprint: 'Footprint',
    buildableArea: 'Buildable area',
    modules: 'Modules area',
    gcr: 'GCR'
};

export function createProjectVersionUi(
    projectParams: ProjectVersionParams,
    verDataSyncer: VerDataSyncer,
    projectHub: ProjectHub,
    cancel: () => void
): LazyVersioned<PUI_GroupNode> {
    const $projectHub = get(projectHub);
    const currentStatus = $projectHub.project?.status ?? ProjectStatus.Active;
    const currentIsShared = $projectHub.project?.isShared ?? false;

    const paramsObs = new ObservableObject<NewProjectForm>({
        identifier: 'version-props',
        initialState: {
            description: '',
            projectName: $projectHub.name,
            projectStatus: currentStatus,
            isShared: currentIsShared
        }
    });

    const paramsUi = LazyDerived.new3(
        'newVersionParamsUi',
        [],
        [verDataSyncer.history, paramsObs, projectParams.versionMetricsLazy!],
        ([verDataSyncerHistory, params, options]) => {
            const builder = new PUI_Builder({
                sortChildrenDefault: false
            });
            builder.inGroup(
                {
                    name: 'Key Metrics',
                    sortChildren: false,
                    collapsible: false
                },
                () => {
                    Object.entries(metricNames).forEach(([key, name]) => {
                        const metric = options.metrics[key];
                        if (metric && !isNaN(metric.value)) {
                            builder.addNumberProp({
                                name,
                                value: metric.value,
                                unit: metric.unit,
                                calculated: true,
                                onChange: () => {}
                            });
                        }
                    });
                }
            );
            builder.inGroup(
                {
                    name: 'Add Version',
                    sortChildren: false,
                    collapsible: false
                },
                () => {
                    builder.addStringProp({
                        name: 'New version ID',
                        value: `#${(verDataSyncerHistory.lastVersionId() ?? 0 )+ 1}`,
                        calculated: true,
                        onChange: () => {}
                    });
                    builder.addStringProp({
                        name: 'Updated by',
                        value: options.createdBy,
                        calculated: true,
                        onChange: () => {}
                    });
                    builder.addStringProp({
                        name: 'Description',
                        value: params.description,
                        defaultValue: '',
                        isTextarea: true,
                        placeholder: 'No description',
                        onChange: (newValue) => {
                            paramsObs.applyPatch({
                                patch: {
                                    description: newValue,
                                },
                            });
                        },
                    });
                }
            );
            const isProjectChanged = params.projectStatus !== currentStatus
                || params.isShared !== currentIsShared;
            builder.inGroup(
                {
                    name: 'button',
                    sortChildren: false,
                    collapsible: false,
                    showTitle: false
                },
                () => {
                    builder.addActionsNode({
                        name: "addVersion",
                        context: undefined,
                        actions: [
                            {
                                label: "Add version",
                                isEnabled: LazyDerived.fromMutatingObject<boolean>(() => verDataSyncer.canStartSaveNow() || isProjectChanged),
                                action: () => {
                                    projectHub.update(s => (s.name = params.projectName, s));
                                    if (verDataSyncer.canStartSaveNow()) {
                                        verDataSyncer.saveNewVersion({
                                            textDescription: params.description,
                                            image: projectParams.takeScreenshot(),
                                            ...options
                                        });
                                    }
                                    projectHub.updateName();
                                    projectHub.updateStatus(params.projectStatus);
                                    projectHub.toggleShared(params.isShared);
                                    cancel();
                                },
                                style: {
                                    type: 'primary'
                                },
                            }
                        ]
                    });
                }
            );
            return builder.finish();
        }
    ).withoutEqCheck();
    return paramsUi;
}

export function saveProjectVersionUi(
    verDataSyncer: VerDataSyncer,
    paramsObs: ObservableObject<{description: string}>,
): LazyVersioned<PUI_GroupNode> {
    const paramsUi = LazyDerived.new1(
        'saveProjectVersionUi',
        [],
        [verDataSyncer.history],
        ([verDataSyncerHistory]) => {
            const builder = new PUI_Builder({
                sortChildrenDefault: false
            });
            builder.addStringProp({
                name: 'New version ID',
                value: `#${(verDataSyncerHistory.lastVersionId() ?? 0 )+ 1}`,
                calculated: true,
                onChange: () => {}
            });
            builder.addStringProp({
                name: 'Description',
                defaultValue: '',
                isTextarea: true,
                placeholder: 'No description',
                onChange: (newValue) => {
                    paramsObs.applyPatch({
                        patch: {
                            description: newValue,
                        },
                    });
                },
            });
            return builder.finish();
        }
    ).withoutEqCheck();
    return paramsUi;
}

interface NewCatalogForm {
    description: string;
}

export function createCatalogVersionUi(
    bim: Bim,
    verDataSyncer: VerDataSyncer,
    cancel: () => void
): LazyVersioned<PUI_GroupNode> {
    const paramsObs = new ObservableObject<NewCatalogForm>({
        identifier: 'version-props',
        initialState: {
            description: '',
        }
    });

    const paramsUi = LazyDerived.new1(
        'newVersionParamsUi',
        [],
        [paramsObs],
        ([params]) => {
            const builder = new PUI_Builder({});
            builder.inGroup(
                {
                    name: 'Add Version',
                    sortChildren: false,
                    collapsible: false,
                    showTitle: false,
                    typeSortKeyOverride: 1
                },
                () => {
                    builder.addStringProp({
                        name: 'Description',
                        value: params.description,
                        defaultValue: '',
                        isTextarea: true,
                        placeholder: 'No description',
                        onChange: (newValue) => {
                            paramsObs.applyPatch({
                                patch: {
                                    description: newValue,
                                },
                            });
                        },
                    });
                }
            );
            builder.inGroup(
                {
                    name: 'button',
                    sortChildren: false,
                    collapsible: false,
                    showTitle: false,
                    typeSortKeyOverride: 2
                },
                () => {
                    builder.addActionsNode({
                        name: "addVersion",
                        context: undefined,
                        actions: [
                            {
                                label: "autofill",
                                action: () => {
                                    const descriptionStrings = bim.generateShorDescriptionsForUi();
                                    paramsObs.applyPatch({
                                        patch: {
                                            description: descriptionStrings.join(" "),
                                        },
                                    });
                                },
                                style: {
                                    type: 'secondary'
                                },
                            },
                            {
                                label: "Add version",
                                isEnabled: LazyDerived.fromMutatingObject<boolean>(() => verDataSyncer.canStartSaveNow()),
                                action: () => {
                                    verDataSyncer.saveNewVersion({
                                        textDescription: params.description,
                                    });
                                    cancel();
                                },
                                style: {
                                    type: 'primary'
                                },
                            }
                        ]
                    });
                }
            );
            return builder.finish();
        }
    ).withoutEqCheck();
    return paramsUi;
}

export function editVersionUi(
    verDataSyncer: VerDataSyncer,
    versionDescription: Readonly<ProjectVersion>,
    cancel: () => void
): LazyVersioned<PUI_GroupNode> {

    let params = {
        description: versionDescription.textDescription || '',
    };

    async function save(newDescription: string) {
        if (versionDescription.textDescription === newDescription) {
            return cancel();
        }
        const newVersionDescription = new ProjectVersion(
            versionDescription.id,
            versionDescription.date,
            ProjectVersion.trimDescription(newDescription),
            versionDescription.additionalContext,
            versionDescription.createdBy
        );
        const currentHistory = verDataSyncer.history.poll();
        await verDataSyncer.history.applyPatch({
            patch: {
                versions: currentHistory.versions.map(v => {
                    if (v.id === newVersionDescription.id) {
                        return newVersionDescription;
                    }
                    return v;
                })
            }
        });
        cancel();
    }

    const paramsUi = LazyDerived.new0(
        'editVersionParamsUi',
        [],
        () => {
            const builder = new PUI_Builder({});
            builder.inGroup(
                {
                    name: 'description',
                    sortChildren: false,
                    collapsible: false,
                    showTitle: false,
                    typeSortKeyOverride: 1
                },
                () => {
                    builder.addStringProp({
                        name: 'Description',
                        value: params.description,
                        defaultValue: '',
                        isTextarea: true,
                        placeholder: 'No description',
                        onChange: (newValue) => {
                            params = {
                                ...params,
                                description: newValue,
                            }
                        },
                    });
                }
            );
            builder.inGroup(
                {
                    name: 'button',
                    sortChildren: false,
                    collapsible: false,
                    showTitle: false,
                    typeSortKeyOverride: 2
                },
                () => {
                    builder.addActionsNode({
                        name: "editVersion",
                        context: undefined,
                        actions: [
                            {
                                label: "Save",
                                isEnabled: LazyDerived.fromMutatingObject<boolean>(() => verDataSyncer.canStartEditVersionNow()),
                                action: () => {
                                    save(params.description);
                                },
                                style: {
                                    type: 'primary'
                                },
                            }
                        ]
                    });
                }
            );
            return builder.finish();
        }
    );
    return paramsUi;
}