<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.20001 19V17.8002H3.50001C2.78204 17.8002 2.20001 17.2182 2.20001 16.5002V3.5002C2.20001 2.78223 2.78204 2.2002 3.50001 2.2002H8.20001V1H9.80001V19H8.20001ZM8.20001 8.20415V10.6627L3.80001 15.796V13.3375L8.20001 8.20415Z"
        fill="currentColor"
    />
    <path
        d="M11.8 17.8002H16.5C17.218 17.8002 17.8 17.2182 17.8 16.5002V3.5002C17.8 2.78222 17.218 2.2002 16.5 2.2002H11.8V9.2042L16.2 14.3375V16.2002H11.8V17.8002Z"
        fill="currentColor"
    />
</svg>
