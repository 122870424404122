<script lang="ts">
    import { onMount } from "svelte";
    import { MDCDialog } from "@material/dialog";
    import DialogForm from "./DialogForm.svelte";
    import type { DialogInput } from "./types";

    export let dialogInput: DialogInput;
    export let compact: boolean = false;
    let dialogRef: HTMLDivElement;
    let dialog: MDCDialog;

    onMount(() => {
        dialog = new MDCDialog(dialogRef);
        dialog.listen("MDCDialog:closed", function () {
            if (dialogInput.onClosed) {
                dialogInput.onClosed();
            }
        });
    });
    export const open = () => dialog?.open();
    export const close = () => dialog?.close();
</script>

<div bind:this={dialogRef} class="mdc-dialog" class:mdc-dialog--small={compact}>
    <div class="mdc-dialog__container">
        <DialogForm {dialogInput} {close}>
            <slot name="body" slot="body" />
            <slot name="actions" slot="actions" />
        </DialogForm>
    </div>
    <div class="mdc-dialog__scrim" />
</div>

<style>
    .mdc-dialog {
        z-index: 1000;
    }
</style>
