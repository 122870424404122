<script lang="ts">
    import { getContext } from "svelte";
    import type { NavbarContext, NavbarItem } from "./Navbar";
    import NavbarPanelView from "./NavbarPanelView.svelte";

    export let view: NavbarItem | undefined;

    const navbar = <NavbarContext>getContext("ui-navbar");
</script>

{#if view}
    <div class="backdrop" on:click={() => navbar.openOverlayPanel()} />
    <div class="panel bg-white">
        <NavbarPanelView
            item={view}
            onClose={() => navbar.openOverlayPanel()}
        />
    </div>
    <div class="panel-backgound bg-main-light" />
{/if}

<style lang="scss">
    .panel {
        height: 100%;
        width: 516px;
        position: absolute;
        left: 0;
        z-index: 250;

        :global(.header) {
            --mdc-typography-headline4-font-size: 19px;
        }
    }
    .panel-backgound {
        position: fixed;
        right: 0;
        top: 0;
        width: calc(100% - 250px);
        height: 100%;
        z-index: 200;
    }
    .backdrop {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        z-index: 250;
    }
</style>
