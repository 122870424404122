import { TerrainHeightMapRepresentation } from 'bim-ts';
import type { Bim, IdBimScene, SceneInstance, TrackerPilesCollection } from 'bim-ts';
import { IterUtils } from 'engine-utils-ts';
import type { DxfExportContext, DxfSettingsProperties } from 'files-converters';

export function terrainHasChanges(instance?: SceneInstance) {
    if (
        !!instance &&
        instance.representation instanceof TerrainHeightMapRepresentation
    ) {
        for (const [_tileId, tile] of instance.representation.tiles) {
            if (tile.updatedGeo != 0) {
                return true;
            }
        }
    }
    return false;
}

export function sumByProperty(
    bim: Bim,
    instances: [IdBimScene, string][],
    path: string,
    propertyUnit: string = "m",
) {
    const sum = IterUtils.sum(instances, ([id]) => {
        const inst = bim.instances.perId.get(id);
        if (inst) {
            return inst.properties.get(path)?.asNumber() ?? 0;
        }
        return 0;
    });
    const { value, unit } = bim.unitsMapper.mapToConfigured({
        value: sum,
        unit: propertyUnit,
    })!;
    return `${Math.round(value)} ${unit}`;
}

interface GroupItem {
    name: string;
    count: number;
    tag?: string;
    instance?: SceneInstance;
}

export class Group {
    public items: GroupItem[] = [];
    public selected: boolean;
    constructor(
        private settingsKey: keyof DxfSettingsProperties,
        settings: DxfSettingsProperties,
        private context: DxfExportContext,
        private count?: number,
    ) {
        this.selected = settings[settingsKey];
    }

    get total() {
        return this.count ?? IterUtils.sum(this.items, (item) => item.count);
    }

    onChange(val: boolean) {
        this.context.updateSettings({ [this.settingsKey]: val });
    }

    export() {
        this.context.exportGroup(this.settingsKey);
    }
}

export function getExportGroups(bim: Bim, context: DxfExportContext, pilesCollection: TrackerPilesCollection) {
    const instancesByType = new Map(
        IterUtils.groupBy(bim.instances.perIdTypeIdent, ([_id, type]) => type),
    );
    const getInstances = (identifier: string) => instancesByType.get(identifier) ?? [];

    const settings = context.getSettings();
    const sldPagesCount = getInstances("transformer").length + getInstances("substation").length;

    const groups = {
        piles: new Group("pile_position_plan", settings, context, pilesCollection.poll().size),
        sld: new Group("singleLineDiagram", settings, context, sldPagesCount),
        terrain: new Group("terrain", settings, context),
        images: new Group("images", settings, context),
        layout: new Group("layout", settings, context),
        wiring: new Group("wireing", settings, context),
    }

    groups.terrain.items = IterUtils.map(
        getInstances("terrain-heightmap"),
        ([id]) => {
            const instance = bim.instances.perId.get(id);
            return { name: instance?.name || "", count: 1, instance };
    });

    groups.images.items = IterUtils.map(
        getInstances("image"),
        ([id]) => ({ name: bim.instances.perId.get(id)?.name || "", count: 1 })
    );

    function createGroupItem(name: string, idents: string[], label?: {path: string, unit?: string}) {
        const tag = label ? sumByProperty(
            bim,
            instancesByType.get(idents[0]) ?? [],
            label.path,
            label.unit,
        ) : undefined;
        const count = IterUtils.sum(idents, (ident) => getInstances(ident).length);
        return { name, count, tag };
    }

    groups.layout.items = [
        createGroupItem("Boundaries", ["boundary"], {path: "dimensions | area", unit: "ac"}),
        createGroupItem("Roads", ["road"], {path: "dimensions | length"}),
        createGroupItem("Solar arrays", ["tracker", "any-tracker", "fixed-tilt"]),
        createGroupItem("Combiner boxes", ["combiner-box"]),
        createGroupItem("Sectionalized cabinets", ["sectionalizing-cabinet"]),
        createGroupItem("Substations", ["substation"]),
        createGroupItem("Inverters", ["inverter"]),
        createGroupItem("Transformers", ["transformer"]),
    ];

    groups.wiring.items = [
        createGroupItem("Low Voltage wires", ["lv-wire"], {path: "computed_result | length"}),
        createGroupItem("Medium Voltage wires", ["wire"], {path: "computed_result | length"}),
        createGroupItem("Trenches", ["trench"], {path: "dimensions | length"}),
    ];

    return groups;
}