import type { Bim} from "bim-ts";
import { SceneInstances, TerrainHeightMapRepresentation, TerrainInstanceTypeIdent } from "bim-ts";
import type { ScopedLogger} from "engine-utils-ts";
import { Result, Success, Yield } from "engine-utils-ts";
import type { ExportedFileDescription } from "ui-bindings";
import type { CommonExportSettings} from "../CommonExportSettings";
import { getExportProjectName } from "../CommonExportSettings";
import { CreateLandXmlString } from "./TinCreator";


export function* convertXmlAndDownload(
    bim: Bim, logger: ScopedLogger, settings: CommonExportSettings, trianglesSize: number, projectName?: string
): Generator<Yield, Result<ExportedFileDescription>[]> {

    const selectedIds = settings.export_only_selected ? bim.instances.getSelected() : bim.instances.readAll().map((i) => i[0]);

    const terrainInstances = bim.instances.peekByTypeIdent(TerrainInstanceTypeIdent).filter(x => selectedIds.includes(x[0]));

    const result: Result<ExportedFileDescription>[] = [];

    for (const [id, terrainInstance] of terrainInstances) {

        yield Yield.Asap;

        const instanceName = SceneInstances.uiNameFor(id, terrainInstance);

        const fileName = projectName ? `${projectName}_${instanceName}` : instanceName;

        const xmlString = CreateLandXmlString(bim, id, trianglesSize)
        if (xmlString instanceof Success) {
            const encodedXml = new TextEncoder().encode(xmlString.value);
            result.push(new Success({extension: 'xml', file: encodedXml, name: fileName}));
        }
    }
    return result;
}