import type { WebGLInfo } from '../3rdParty/three';
import type { GeoInChunkRanges } from '../geometries/GpuGeometries';
import { LegacyLogger } from 'engine-utils-ts';


export class IndexedRenderer {
	gl: WebGL2RenderingContext;
	info: WebGLInfo;

	constructor(gl: WebGL2RenderingContext, info: WebGLInfo) {
		this.gl = gl;
		this.info = info;
	}

	reInitializeOnContextRestore(gl: WebGL2RenderingContext, info: WebGLInfo) {
		this.gl = gl;
		this.info = info;
	}

	drawRange(range: GeoInChunkRanges, render_edges: boolean, instance_count: number) {
		if (instance_count <= 0) {
			LegacyLogger.warn('renger_geo instance count should be positive');
			return;
		}
		let rangeStart: number;
		let rangeCount: number;
		let mode: number;
		if (render_edges) {
			rangeStart = range.edgesIndex.start;
			rangeCount = range.edgesIndex.count;
			mode = this.gl.LINES;
			
		} else {
			rangeStart = range.index.start;
			rangeCount = range.index.count;
			mode = this.gl.TRIANGLES;
		}
		
		if (instance_count > 1) {
			this.gl.drawElementsInstanced( mode, rangeCount, this.gl.UNSIGNED_INT, rangeStart * 4, instance_count);
		} else {
			this.gl.drawElements( mode, rangeCount, this.gl.UNSIGNED_INT, rangeStart * 4);
		}
		this.info.update( rangeCount, mode, instance_count);
	}
}
