import type { ScopedLogger } from 'engine-utils-ts';
import { JoinedCollectionBasic } from 'engine-utils-ts';
import type { Aabb } from 'math-ts';
import type { EntityId } from 'verdata-ts';

import type { IrregularHeightmapGeometry, PolylineGeometry } from '../';
import { PolyEntitiesBase } from '../collections/PolyEntitiesBase';
import { BimGeometryType } from '../schema/bim-geometry-type';
import type { BimGeometriesBase } from './BimGeometriesBase';
import type { CubeGeometry } from './CubeGeometries';
import type { ExtrudedPolygonGeometry } from './ExtrudedPolygonGeometries';
import type { GraphGeometry } from './GraphGeometries';
import type { RegularHeightmapGeometry } from './RegularHeightmapGeometry';
import type { TriGeometry } from './TriGeometries';

export { BimGeometryType };


export type AnyBimGeometry =
    TriGeometry
    | CubeGeometry
    | PolylineGeometry
    | ExtrudedPolygonGeometry
    | RegularHeightmapGeometry
	| IrregularHeightmapGeometry
    | GraphGeometry
    ;

export type IdBimGeo = EntityId<BimGeometryType>;


export class BimGeometries extends PolyEntitiesBase<AnyBimGeometry, BimGeometryType> {

	readonly aabbs: JoinedCollectionBasic<Aabb, IdBimGeo>;

    constructor(
		logger: ScopedLogger,
        subtypesCollections: BimGeometriesBase<any>[],
    ) {
        super('bim-geometries', subtypesCollections);
		this.aabbs = new JoinedCollectionBasic({
			identifier: `bim-geos-aabbs`,
			logger,
			dataSources: subtypesCollections.map(c => c.aabbs),
		});
    }

	localIdsCounterFor(id: EntityId<BimGeometryType>) {
		return this.getCollectinoOfId(id).localIdsCounterFor(id);
	}

	dispose(): void {
		this.aabbs.dispose();
		super.dispose();
	}
}


