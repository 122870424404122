// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class CustomSerializedProperty implements flatbuffers.IUnpackableObject<CustomSerializedPropertyT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):CustomSerializedProperty {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsCustomSerializedProperty(bb:flatbuffers.ByteBuffer, obj?:CustomSerializedProperty):CustomSerializedProperty {
  return (obj || new CustomSerializedProperty()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsCustomSerializedProperty(bb:flatbuffers.ByteBuffer, obj?:CustomSerializedProperty):CustomSerializedProperty {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new CustomSerializedProperty()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

typeIdent():string|null
typeIdent(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
typeIdent(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

asString():string|null
asString(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
asString(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

asBinary(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index) : 0;
}

asBinaryLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

asBinaryArray():Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? new Uint8Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

static startCustomSerializedProperty(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addTypeIdent(builder:flatbuffers.Builder, typeIdentOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, typeIdentOffset, 0);
}

static addAsString(builder:flatbuffers.Builder, asStringOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, asStringOffset, 0);
}

static addAsBinary(builder:flatbuffers.Builder, asBinaryOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, asBinaryOffset, 0);
}

static createAsBinaryVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset {
  builder.startVector(1, data.length, 1);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt8(data[i]!);
  }
  return builder.endVector();
}

static startAsBinaryVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(1, numElems, 1);
}

static endCustomSerializedProperty(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createCustomSerializedProperty(builder:flatbuffers.Builder, typeIdentOffset:flatbuffers.Offset, asStringOffset:flatbuffers.Offset, asBinaryOffset:flatbuffers.Offset):flatbuffers.Offset {
  CustomSerializedProperty.startCustomSerializedProperty(builder);
  CustomSerializedProperty.addTypeIdent(builder, typeIdentOffset);
  CustomSerializedProperty.addAsString(builder, asStringOffset);
  CustomSerializedProperty.addAsBinary(builder, asBinaryOffset);
  return CustomSerializedProperty.endCustomSerializedProperty(builder);
}

unpack(): CustomSerializedPropertyT {
  return new CustomSerializedPropertyT(
    this.typeIdent(),
    this.asString(),
    this.bb!.createScalarList<number>(this.asBinary.bind(this), this.asBinaryLength())
  );
}


unpackTo(_o: CustomSerializedPropertyT): void {
  _o.typeIdent = this.typeIdent();
  _o.asString = this.asString();
  _o.asBinary = this.bb!.createScalarList<number>(this.asBinary.bind(this), this.asBinaryLength());
}
}

export class CustomSerializedPropertyT implements flatbuffers.IGeneratedObject {
constructor(
  public typeIdent: string|Uint8Array|null = null,
  public asString: string|Uint8Array|null = null,
  public asBinary: (number)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const typeIdent = (this.typeIdent !== null ? builder.createString(this.typeIdent!) : 0);
  const asString = (this.asString !== null ? builder.createString(this.asString!) : 0);
  const asBinary = CustomSerializedProperty.createAsBinaryVector(builder, this.asBinary);

  return CustomSerializedProperty.createCustomSerializedProperty(builder,
    typeIdent,
    asString,
    asBinary
  );
}
}
