import type { PropertyBase, PropsGroupBase, PropsGroupField } from "./Props";



export enum PropsFieldFlags {
    None = 0,
    SkipSerialization = 1,
    SkipClone = 2,
}

export type PropsGroupComplexDefaults<T> = {
    [P in keyof T as T[P] extends PropsGroupBase | PropertyBase | Function | undefined ? never : P]
        : PropsFieldOneOf | PropsFieldArrayOf<any>
};


export type PropsGroupDerivedValues<T> = {
    [P in keyof T as T[P] extends Function ? P : never]?
        : PropsGroupBasicDerivedValue | undefined
};

export class PropsGroupBasicDerivedValue {
    
}

// export class PropsGroupParametrizedDerivedValue {
    
// }


export interface PropsFieldOneOfOptions {
    userSelectable: boolean;
}

export class PropsFieldOneOf {

    public readonly flags: PropsFieldFlags;
    public readonly allowedTypesCtors: (null|{new(args: any): any})[];

    constructor(
        flags: PropsFieldFlags,
        ...allowedTypesCtors: (null | any)[]
    ) {
        this.flags = flags;
        this.allowedTypesCtors = allowedTypesCtors;
        Object.freeze(this);
        Object.freeze(this.allowedTypesCtors);
    }

    isValidType(value: PropsGroupField): boolean {
        const ctorToCheck = value ? value.constructor : null;
        if (this.allowedTypesCtors.includes(ctorToCheck as any)) {
            return true;
        }
        return false;
    }
}


export interface PropsFieldArrayOfOptions {
    flags: PropsFieldFlags;
    userChangableLength: boolean;
    allowedLengthRange: [number, number];
}

export class PropsFieldArrayOf<T> {

    public readonly flags: PropsFieldFlags;
    public readonly userChangableLength: boolean;
    public readonly allowedLengthRange: [number, number];
    public readonly allowedTypeClass: { new(...args: any[]): T}


    constructor(
        params: Partial<PropsFieldArrayOfOptions>,
        allowedTypeClass: { new(...args: any[]): T}
    ) {
        this.flags = params.flags ?? PropsFieldFlags.None;
        this.userChangableLength = params.userChangableLength ?? false;
        this.allowedLengthRange = params.allowedLengthRange ?? [0, Infinity];
        this.allowedTypeClass = allowedTypeClass;
        Object.freeze(this);
    }

    isValidType(value: any[]): boolean {
        for (const v of value) {
            if (v !== null && v.constructor !== this.allowedTypeClass) {
                return false;
            }
        }
        return true;
    }
}

