import type { ObjectSerializer, ValueAndUnit } from 'engine-utils-ts';
import * as flatbuffers from 'flatbuffers';

import {
	InputFileDescription as WireInputFileDescription,
} from './wire/input-file-description';
import { ProjectHistory as WireProjectHistory } from './wire/project-history';
import {
	ProjectVersionDescription as WireProjectVersionDescription,
} from './wire/project-version-description';
import { ProjectVersionAdditionalContext as WireProjectVersionAdditionalContext } from './wire/project-version-additional-context';
import { ProjectVersionMetric as WireProjectVersionMetric } from './wire/project-version-metric';
import { dateFromFlatbufLong, dateToFlatbufLong } from './WireCommon';
import { IdentifierVersion } from './ProjectHistory_generated';

export interface ProjectVersionDescription {
    date: Date;
    textDescription: string;
    image: Promise<Uint8Array>;
}

export interface ProjectVersionAdditionalContext {
    versionPerIdentifier: Map<string, number>;
    metrics: ProjectVersionMetrics;
}

export type ProjectVersionMetrics = Record<string, ValueAndUnit>;

enum ProjectVersionFormatVersions {
    None = 0,
    AddContext
}

export class ProjectVersion {

    readonly id: number;
    readonly date: Date;
    readonly textDescription: string;
    readonly additionalContext: ProjectVersionAdditionalContext | null;
    readonly createdBy: string;


	static maxDescriptionLength(): number {
		return 10_000;
	}
	static trimDescription(descr: string): string {
		return descr.slice(0, 10_000);
	}


    constructor(
        id: number,
        date: Date,
        textDescription: string,
        additionalContext: ProjectVersionAdditionalContext | null,
        createdBy: string
    ) {
        this.id = id;
        this.date = date;
        this.textDescription = ProjectVersion.trimDescription(textDescription);
        this.additionalContext = additionalContext;
        this.createdBy = createdBy;
        Object.freeze(this);
    }

    clone() {
        return new ProjectVersion(this.id, this.date, this.textDescription, this.additionalContext, this.createdBy);
    }

    static parseFromFlatbuf(pv: WireProjectVersionDescription): ProjectVersion {
        const additionalContext = pv.additionalContext();
        let pvContext: ProjectVersionAdditionalContext | null = null;
        if(additionalContext){
            pvContext = {
                versionPerIdentifier: new Map(),
                metrics: {}
            }
            for (let i = 0; i < additionalContext.versionPerIdentifierLength(); i++) {
                const versionPerIdentifier = additionalContext.versionPerIdentifier(i)!;
                const identifier = versionPerIdentifier.identifier()!;
                if(pvContext.versionPerIdentifier.has(identifier)){
                    throw new Error('have duplicates in versionPerIdentifier ' + identifier + JSON.stringify(versionPerIdentifier));
                }
                pvContext.versionPerIdentifier.set(identifier, versionPerIdentifier.version());
            }
            for (let i = 0; i < additionalContext.metricsLength(); i++) {
                const metric = additionalContext.metrics(i)!;
                const name = metric.name();
                if (name) {
                    pvContext.metrics[name] = {
                        value: metric.value(),
                        unit: metric.unit() || ""
                    }
                }
            }
        }

        return new ProjectVersion(
            pv.id(),
            dateFromFlatbufLong(pv.date()),
            pv.textDescription() || "",
            pvContext,
            pv.createdBy() || "",
        );
    }

    addToFlatbuf(builder: flatbuffers.Builder): number {
        const textDescriptionOffset = builder.createString(this.textDescription); 
        let versionPerIdentifierOffset: number = 0;
        let metricsOffset: number = 0;
        if(this.additionalContext){
            if (this.additionalContext.versionPerIdentifier){
                const versionPerIdentifierVector:number[] = [];
                for (const [identifier, version] of this.additionalContext.versionPerIdentifier) {
                    const typeOffset = builder.createString(identifier);
                    IdentifierVersion.startIdentifierVersion(builder);
                    IdentifierVersion.addIdentifier(builder, typeOffset);
                    IdentifierVersion.addVersion(builder, version);
                    versionPerIdentifierVector.push(IdentifierVersion.endIdentifierVersion(builder));
                }
                versionPerIdentifierOffset = WireProjectVersionAdditionalContext
                    .createVersionPerIdentifierVector(builder, versionPerIdentifierVector);
            }
            if (this.additionalContext.metrics) {
                const metricVector:number[] = [];
                Object.entries(this.additionalContext.metrics).forEach(([name, value]) => {
                    const nameOffset = builder.createString(name);
                    const unitOffset = builder.createString(value.unit);
                    metricVector.push(WireProjectVersionMetric.createProjectVersionMetric(builder, nameOffset, value.value, unitOffset));
                });
                metricsOffset = WireProjectVersionAdditionalContext.createMetricsVector(builder, metricVector);
            }
        }
        const contextOffset = this.additionalContext 
            ? WireProjectVersionAdditionalContext.createProjectVersionAdditionalContext(builder, versionPerIdentifierOffset, metricsOffset) 
            : 0;
        const createdOffset = builder.createString(this.createdBy);
        WireProjectVersionDescription.startProjectVersionDescription(builder);
        WireProjectVersionDescription.addFormatVersion(builder, ProjectVersionFormatVersions.AddContext);
        WireProjectVersionDescription.addId(builder, this.id);
        WireProjectVersionDescription.addDate(builder, dateToFlatbufLong(this.date));
        WireProjectVersionDescription.addTextDescription(builder, textDescriptionOffset);
        WireProjectVersionDescription.addAdditionalContext(builder, contextOffset);
        WireProjectVersionDescription.addCreatedBy(builder, createdOffset);
        
        
        return WireProjectVersionDescription.endProjectVersionDescription(builder);
    }
}

export class InpuFileDescription {
    readonly id: number;
    readonly version: number;
    readonly filePath: string;
    readonly initialFileName: string;
    readonly textDescription: string;

    constructor(
        id: number,
        version: number,
        filePath: string,
        initialFileName: string,
        textDescription: string,
    ) {
        this.id = id;
        this.version = version;
        this.filePath = filePath;
        this.initialFileName = initialFileName;
        this.textDescription = textDescription;
    }

    static parseFromFlatbuf(fd: WireInputFileDescription): InpuFileDescription {

        return new InpuFileDescription(
            fd.id(),
            fd.version(),
            fd.filePath()!,
            fd.initialFileName()!,
            fd.textDescription() || "",
        );
    }

    addToFlatbuf(builder: flatbuffers.Builder): number {
        return WireInputFileDescription.createInputFileDescription(
            builder,
            this.id,
            this.version,
            builder.createString(this.initialFileName),
            builder.createString(this.filePath),
            builder.createString(this.textDescription),
        );
    }
}

export class ProjectHistory {
    versions: ProjectVersion[];
    inputFiles: InpuFileDescription[];

    constructor(
        versions: ProjectVersion[],
        inputFiles: InpuFileDescription[],
    ) {
        this.versions = versions;
        this.inputFiles = inputFiles;
    }

    lastVersion(): ProjectVersion {
        return this.versions[this.versions.length - 1];
    }

    lastVersionId(): number | undefined {
        return this.versions[this.versions.length - 1]?.id;
    }

    duplicateWithNewVersion(date: Date, textDescription: string, context: ProjectVersionAdditionalContext|null, createdBy: string): ProjectHistory {
        const versions = this.versions.slice();
        const newVersionId = (this.lastVersionId() ?? 0) + 1;
        versions.push(
            new ProjectVersion(
                newVersionId,
                date,
                textDescription,
                context,
                createdBy,
            )
        );
        var inputFiles = this.inputFiles.slice();
        return new ProjectHistory(
            versions,
            inputFiles
        )
    }
}

export class ProjectHistorySerializer implements ObjectSerializer<ProjectHistory> {
    
    serialize(data: ProjectHistory): Uint8Array {
        const builder = new flatbuffers.Builder(data.versions.length * 10000);
        const root = WireProjectHistory.createProjectHistory(
            builder,
            0,
            WireProjectHistory.createVersionsVector(
                builder,
                data.versions.map(
                    v => v.addToFlatbuf(builder)
                ),
            ),
            WireProjectHistory.createInputFilesVector(
                builder,
                data.inputFiles.map(
                    fd => fd.addToFlatbuf(builder)
                ),
            )
        )
        WireProjectHistory.finishProjectHistoryBuffer(builder, root);
        return builder.asUint8Array().slice();
    }

    deserialize(bytes: Uint8Array): ProjectHistory {
        const buffer = new flatbuffers.ByteBuffer(bytes);
        const ph = WireProjectHistory.getRootAsProjectHistory(buffer);

        const versions: ProjectVersion[] = [];
        for (let i = 0, il = ph.versionsLength(); i < il; ++i) {
            const pv = ph.versions(i)!;
            versions.push(ProjectVersion.parseFromFlatbuf(pv));
        }

        const inputFiles: InpuFileDescription[] = [];
        for (let i = 0, il = ph.inputFilesLength(); i < il; ++i) {
            const ff = ph.inputFiles(i)!;
            inputFiles.push(InpuFileDescription.parseFromFlatbuf(ff));
        }

        return new ProjectHistory(
            versions,
            inputFiles,
        );
    }

}
