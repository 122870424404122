<script lang="ts">
    import type { EnergyViewContext } from './EnergyViewContext';
    import { VersionedStore } from '../VersionedStore';
    import { Failure, LazyVersioned, ResultAsync, Success } from 'engine-utils-ts';
    import { EnergyFailure, EnergyYieldPropsGroup, EnergyYieldSiteProps } from 'bim-ts';
    import EnergyOverview from './EnergyOverview.svelte';
    import EnergyWarnings from "./warnings/EnergyWarnings.svelte";
    
    export let context: EnergyViewContext;

    const bim = context.bim;

    const energyDataLazy = bim.runtimeGlobals.getAsLazyVersionedByIdent(
        EnergyYieldSiteProps, EnergyYieldPropsGroup
    ) as LazyVersioned<ResultAsync<EnergyYieldPropsGroup, EnergyFailure> | Failure>;

    $: energyData = new VersionedStore(energyDataLazy);

</script>

<div class="root">

    {#if $energyData instanceof Success}
        {#if $energyData.warnings}
            <EnergyWarnings warnings={$energyData.warnings} />
        {/if}
        <EnergyOverview energyProps={$energyData.value}></EnergyOverview>
    {/if}
</div>

<style lang="scss">
</style>