<script lang="ts">
    import Slider from "./Slider.svelte";


    export let value: number;
    export let readonly: boolean = false;
    export let settings: {
        minMax: [number, number];
        step: number;
        minLabel?: string;
        maxLabel?: string;
        message?: string;
    }


    $: min = settings.minMax[0];
    $: max = settings.minMax[1];

</script>

<div class="slider-root">
    <div class="slider-holder">
        <Slider min={min} max={max} bind:value={value} step={settings.step} {readonly} />
    </div>
    <div class="labels mdc-typography--body2 text-main-light">
        {#if settings.minLabel}
            <span>{@html settings.minLabel}</span>
        {/if}
        {#if settings.maxLabel}
            <span>{@html settings.maxLabel}</span>
        {/if}
    </div>
    {#if settings.message}
        <div class="slider-message mdc-typography--body2 text-main-light">
            {@html settings.message}
        </div>
    {/if}
</div>


<style lang="scss">
    .slider-root{
        width: 100%;
    }
    .slider-holder {
        width: 100%;
        height: 24px;
    }
    .slider-message {
        padding-top: 8px;
    }

    .labels{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
</style>