import { isInNode } from './EnvChecks';
import { FetchUtils } from './FetchUtils';

export type MutateParams = (params: RequestInit) => Promise<void>;
export type MutateRequest = (params: Request) => Promise<void>;

export interface NetworkLoaderConfig {
    urlOrigin: string;
    basePath: string,
    credentials: 'include' | 'omit' | 'same-origin',
    tokenRefresUrl?: string;
    mutateParams?: MutateParams;
    mutateRequest?: MutateRequest;
}

export class ProjectNetworkClient {

    readonly config: NetworkLoaderConfig;
    private _isDisposed: boolean = false;
    private _disposeListeners: ((self: ProjectNetworkClient) => void)[] = [];

    private _outboundFetchRequests: Map<string, Promise<Response>> = new Map();

    private _tokenRefreshing: Promise<void> | null = null; // null initially
    // promise when refreshing, if sucesfully refreshed - make null again
    // if refresh failed - leave as failed response


    constructor(config: Partial<NetworkLoaderConfig>) {
        const origin = isInNode() ? "" : document.location.origin;
        this.config = {
            ...config,
            urlOrigin: config.urlOrigin ?? origin,
            basePath: config.basePath ?? '',
            credentials: config.credentials ?? 'same-origin',
        };
    }

    dispose() {
        if (this._isDisposed) {
            return;
        }
        this._isDisposed = true;
        while (this._disposeListeners?.length) {
            const l = this._disposeListeners.shift()!;
            try {
                l(this);
            } catch (e) {
                console.error('error in observable_object dispose listener', e);
            }
        }
    }
    
    withDisposeListener(disposeListener: (self: ProjectNetworkClient) => void) {
        this._disposeListeners.push(disposeListener);
    }
  
    getFullUrl(relativeUrl: string): string {
        const baseRelative = FetchUtils.combineURLs(this.config.basePath, relativeUrl);
        const url = new URL(baseRelative, this.config.urlOrigin);
        return url.href;
    }

    async getRequestFor(
        params: {
            relativeUrl: string,
            method: 'PUT' | 'GET' | 'POST' | 'DELETE',
            data?: Uint8Array | FormData
        },
        init: RequestInit = {},
    ): Promise<Request> {
        const reqConfig: RequestInit = {
            ...init,
            credentials: this.config.credentials,
            method: params.method,
            body: params.data,
        };
        const fullUrl = this.getFullUrl(params.relativeUrl);
        if (this.config.mutateParams) {
            await this.config.mutateParams(reqConfig);
        }
        const req = new Request(fullUrl, reqConfig);
        if (this.config.mutateRequest) {
            await this.config.mutateRequest(req);
        }
        return req;
    }

    executeRequest(request: Request): Promise<Response> {
        let responsePromise: Promise<Response> | undefined;
        if (request.method == 'GET') {
            responsePromise = this._outboundFetchRequests.get(request.url);
            if (!responsePromise) {
                responsePromise = this._fetchAndHandleTokenRefresh(request);
                this._outboundFetchRequests.set(request.url, responsePromise);
                // Use then instead of finally to be able to catch the error further in the code
                const onFinally = () => this._outboundFetchRequests.delete(request.url);
                responsePromise
                    .then(onFinally, onFinally);
                    // .finally(() => this._outboundFetchRequests.delete(request.url));
            }
        } else {
            responsePromise = this._fetchAndHandleTokenRefresh(request);
        }
        return responsePromise;
    }

    async delete(relativeUrl: string, init?: RequestInit): Promise<Response> {
        const req = await this.getRequestFor({
            relativeUrl: relativeUrl,
            method: 'DELETE'
        }, init);
        return this.executeRequest(req);
    }

    async get(relativeUrl: string, init?: RequestInit): Promise<Response> {
        const req = await this.getRequestFor({
            relativeUrl: relativeUrl,
            method: 'GET'
        }, init);
        return this.executeRequest(req);
    }

    async put(relativeUrl: string, data: Uint8Array | FormData, init?: RequestInit): Promise<Response> {
        const req = await this.getRequestFor({
            relativeUrl: relativeUrl,
            method: 'PUT',
            data
        }, init);
        return this.executeRequest(req);
    }

    async post(
        relativeUrl: string,
        data: Uint8Array = new Uint8Array(),
        init?: RequestInit,
    ): Promise<Response> {
        const req = await this.getRequestFor({
            relativeUrl: relativeUrl,
            method: 'POST',
            data
        }, init);
        return this.executeRequest(req);
    }

    async postFormData(
        relativeUrl: string,
        data: FormData = new FormData(),
        init?: RequestInit,
    ): Promise<Response> {
        const req = await this.getRequestFor({
            relativeUrl: relativeUrl,
            method: 'POST',
            data,
        }, init)
        return this.executeRequest(req);
    }

    async postJson(
        relativeUrl: string,
        data: object,
        init: RequestInit = {},
    ): Promise<Response> {
        const _data = new TextEncoder().encode(JSON.stringify(data));
        init.headers = {
            ...(init.headers || {}),
            'Content-Type': "Application/Json",
        };
        return this.post(relativeUrl, _data, init);
    }

    // async openWebsocket(relativeUrl: string): Promise<WebSocket> {
    //     const url = FetchUtils.combineURLs(this.config.baseUrl, relativeUrl);
    //     return WebsocketClient.newWebsocketConnection(url)
    // }

    private async _fetchAndHandleTokenRefresh(req: Request): Promise<Response> {
        if (this._tokenRefreshing) {
            await this._tokenRefreshing;
        }
        let r = await fetch(req);
        if (r.status == 401) {
            // refresh token and retry
            await this._tryRefreshToken();
            r = await fetch(req);
        }
        // fetch promise will only be rejected in case of network failure, so check status
        if (r.ok) {
            return r;
        } else {
            return Promise.reject(r);
        }
    }

    private async _tryRefreshToken(): Promise<void> {
        if (this._tokenRefreshing) {
            return this._tokenRefreshing;
        }
        if (this.config.tokenRefresUrl) {
            const tokentRefreshUrl = this.config.tokenRefresUrl;
            this._tokenRefreshing = new Promise(async (resolve, reject) => {
                try {
                    const response = await fetch(tokentRefreshUrl, {credentials: this.config.credentials});
                    if (response.ok) {
                        resolve();
                        this._tokenRefreshing = null;
                    } else {
                        reject(response);
                    }
                } catch (e) {
                    reject(e);
                }
            });
        } else {
            this._tokenRefreshing = Promise.reject(new Error('no token refresh url set in configuration'));
        }
        return this._tokenRefreshing;
    }
}
