import type { BimGeometryType, ExtrudedPolygonGeometries} from 'bim-ts';
import { ExtrudedPolygonGeometry } from 'bim-ts';
import { Success, type ScopedLogger} from 'engine-utils-ts';
import type { Result } from 'engine-utils-ts';
import type { EntityId } from 'verdata-ts';
import { BimEngineGeometriesSyncBase } from '../resources/BimGeometriesSync';
import { EngineGeoType } from './AllEngineGeometries';

import { EngineBimGeometry } from "./EngineGeometry";
import { GeometryGenerator } from './GeometryGenerator';
import type { GeometryGpuRepr } from './KrBufferGeometry';

export class EngineGeoExtrudedPolygon  extends EngineBimGeometry<ExtrudedPolygonGeometry> {

    constructor(
        sourceGeo: ExtrudedPolygonGeometry = new ExtrudedPolygonGeometry(),
    ) {
        super(sourceGeo);
    }

	_calcGpuRepr(): GeometryGpuRepr {
		return  GeometryGenerator.generateExtrudedPolygon(this.bimGeo);
	}
}


export class EngineExtrPolygonGeosSynced
    extends BimEngineGeometriesSyncBase<EngineGeoExtrudedPolygon, ExtrudedPolygonGeometry> {


    constructor(
        logger: ScopedLogger,
        bimGeos: ExtrudedPolygonGeometries,
    ) {
        super(
            bimGeos,
            {
                logger,
                identifier: 'engine-polygon-geos',
                idsType: EngineGeoType.ExtrudedPolygon,
                T_Constructor: EngineGeoExtrudedPolygon,
                interner: (polygon) => JSON.stringify(ExtrudedPolygonGeometry.toString()),
                internerIdsReserver: () => this.reserveEngineOnlyId(),
            }
        );
    }

    checkForErrors(t: EngineGeoExtrudedPolygon, errors: string[]) {
    }

    convertFromBim(bimObj: ExtrudedPolygonGeometry, id: EntityId<BimGeometryType>): Result<EngineGeoExtrudedPolygon> {
        return new Success(new EngineGeoExtrudedPolygon(bimObj));
    }
}
