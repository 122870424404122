<script lang="ts">
import { FetchUtils } from 'engine-utils-ts';
import { ButtonComponent, Button, ButtonType } from './libui/button';

import { navigate } from 'svelte-routing';

let text = FetchUtils.createObjectFromQueryString(location.search)['text'] ?? '';
</script>


<div
    style="
        flex: 1;
        flex-direction: column;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 200px;
    "
>
    <p
        style="margin-top: 0; margin-bottom: 40px; opacity: 0.2"
        class="mdc-typography mdc-typography--headline2"
    >
        Something bad happaned
    </p>
    <p class="mdc-typography mdc-typography--subtitle1">{text}</p>
    <ButtonComponent desc={new Button("Return to Dashboard", ButtonType.Contained, () => navigate('/dashboard'))} />
</div>

