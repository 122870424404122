<script lang="ts">
    import { getContext } from "svelte";
    import { DxfExportContext } from "files-converters";
    import ExportGroup from "./ExportGroup.svelte";
    import { Bim, TrackerPilesCollection } from "bim-ts";
    import ExportGroupItem from "./ExportGroupItem.svelte";
    import { getExportGroups, terrainHasChanges } from "./DxfGroupsUtils";

    export let context: DxfExportContext;

    const bim = getContext<Bim>("bim");
    const pilesCollection = getContext<TrackerPilesCollection>(
        "trackerPilesCollection",
    );

    const groups = getExportGroups(bim, context, pilesCollection);
</script>

<div class="export-content">
    <ExportGroup
        name="Terrain"
        group={groups.terrain}
        singleExport={true}
        itemsType="Terrain file"
    >
        {#each groups.terrain.items as item}
            <div class="terrain-group">
                <ExportGroupItem name={item.name}></ExportGroupItem>
                <div class="mdc-typography--body2">
                    <ExportGroupItem
                        name={terrainHasChanges(item.instance)
                            ? "Final grading, cut fill applied"
                            : "Existing grading, no cut fill"}
                    />
                </div>
            </div>
        {/each}
    </ExportGroup>

    <ExportGroup
        name="Imported images"
        group={groups.images}
        singleExport={true}
        itemsType="Image"
    >
        {#each groups.images.items as item}
            <ExportGroupItem name={item.name} />
        {/each}
    </ExportGroup>
    <ExportGroup name="Layout" group={groups.layout} itemsType="Object">
        {#each groups.layout.items as item}
            <ExportGroupItem
                name={item.name}
                count={item.count}
                tag={item.tag}
            />
        {/each}
    </ExportGroup>
    <ExportGroup
        name="Piles position plan"
        group={groups.piles}
        singleExport={true}
        itemsType="Pile"
    />
    <ExportGroup name="Wiring" group={groups.wiring} itemsType="Object">
        {#each groups.wiring.items as item}
            <ExportGroupItem
                name={item.name}
                count={item.count}
                tag={item.tag}
            />
        {/each}
    </ExportGroup>
    <ExportGroup
        name="SLD"
        group={groups.sld}
        singleExport={true}
        itemsType="Page"
    />
</div>

<style lang="scss">
    .export-content {
        width: 468px;
    }
    .terrain-group :global(:nth-child(2) .export-group-item) {
        margin: 0;
    }
</style>
