import type { BinsBuilder } from 'bim-ts';
import { IterUtils, LazyBasic, LazyDerived } from 'engine-utils-ts';
import { PUI_Builder, type PUI_GroupNode, PUI_Lazy, type UiBindings, ContextMenuConfig } from 'ui-bindings';
import type { Vector2 } from 'math-ts';

export function getBinsPui(binsBuilder: BinsBuilder, uiBindings: UiBindings) {
    const pilesCountTag = LazyDerived.new1(
        "piles-count",
        null,
        [binsBuilder.selectedBins],
        function([selectedBins]) {
            return `${IterUtils.sum(selectedBins, (binData) => binData.total)} piles`;
        }
    );
    return new PUI_Lazy(
        LazyDerived.new2<PUI_GroupNode, string[], string | null>(
            "pile-bins-props",
            null,
            [binsBuilder.trackerModels, binsBuilder.selectedTracker],
            ([modelNames, selectedTracker]) => {
                const builder = new PUI_Builder({ sortChildrenDefault: false });
                if (modelNames.length) {
                    builder.addSelectorProp({
                        name: "Tracker model",
                        value: selectedTracker,
                        options: modelNames,
                        tag: pilesCountTag,
                        onChange: (newVal) => {
                            binsBuilder.selectTracker(newVal);
                        },
                    });
                    builder.addCustomProp<
                        string[],
                        {
                            onClick: (pos: Vector2) => void;
                        }
                    >({
                        name: " ",
                        context: {
                            onClick: (pos: Vector2) => {
                                const contextMenuConfig = createConfig(pos, binsBuilder);
                                uiBindings.addContextMenuView(contextMenuConfig);
                            },
                        },
                        value: ['14 Piles types'],
                        type_ident: "group_properties",
                        onChange: () => { },
                    });
                }

                return builder.finish();
            },
        ).withoutEqCheck(),
    );
}

function createConfig(pos: Vector2, binsBuilder: BinsBuilder) {
    const prefixTag = new LazyBasic("prefix-tag", "prefix");
    const nameTag = new LazyBasic("name-tag", "name");
    const postfixTag = new LazyBasic("postfix-tag", "postfix");
    const puiLazy = LazyDerived.new0(
        "pile-type-pui", 
        [binsBuilder.binsLazyConfig], 
        () => {
            const builder = new PUI_Builder({sortChildrenDefault: false});
            const trackerBins = binsBuilder.getSelectedBinsConfig();
            if (!trackerBins) { return builder.finish(); }
            builder.inGroup({
                name: 'Piles naming convention',
                sortChildren: false,
                collapsible: false,
            }, () => {
                builder.addStringProp({
                    name: 'Standard pile',
                    tag: prefixTag,
                    value: trackerBins.standard.value,
                    onChange: (val) => {
                        binsBuilder.saveChangesToConfig(trackerBins.withNewProps({ standard: trackerBins.standard.withDifferentValue(val) }));
                    },
                });
                builder.addStringProp({
                    name: 'Heavy pile',
                    tag: prefixTag,
                    value: trackerBins.heavy.value,
                    onChange: (val) => {
                        binsBuilder.saveChangesToConfig(trackerBins.withNewProps({ heavy: trackerBins.heavy.withDifferentValue(val) }));
                    },
                });
            });
            builder.inGroup({
                name: 'type convention',
                showTitle: false,
                collapsible: false,
            }, () => {
                builder.addStringProp({
                    name: 'Motor pile',
                    tag: nameTag,
                    value: trackerBins.motor.value,
                    onChange: (val) => {
                        binsBuilder.saveChangesToConfig(trackerBins.withNewProps({ motor: trackerBins.motor.withDifferentValue(val) }));
                    },
                });
                builder.addStringProp({
                    name: 'Default pile',
                    tag: nameTag,
                    value: trackerBins.default.value,
                    onChange: (val) => {
                        binsBuilder.saveChangesToConfig(trackerBins.withNewProps({ default: trackerBins.default.withDifferentValue(val) }));
                    },
                });
                builder.addStringProp({
                    name: 'Edge pile',
                    tag: nameTag,
                    value: trackerBins.edge.value,
                    onChange: (val) => {
                        binsBuilder.saveChangesToConfig(trackerBins.withNewProps({ edge: trackerBins.edge.withDifferentValue(val) }));
                    },
                });
                builder.addStringProp({
                    name: 'Special pile',
                    tag: nameTag,
                    value: trackerBins.special.value,
                    onChange: (val) => {
                        binsBuilder.saveChangesToConfig(trackerBins.withNewProps({ special: trackerBins.special.withDifferentValue(val) }));
                    },
                });
            });
            builder.inGroup({
                name: 'additional type',
                showTitle: false,
                collapsible: false,
            }, () => {
                builder.addStringProp({
                    name: 'Has damper',
                    tag: postfixTag,
                    value: trackerBins.damper.value,
                    onChange: (val) => {
                        binsBuilder.saveChangesToConfig(trackerBins.withNewProps({ damper: trackerBins.damper.withDifferentValue(val) }));
                    },
                });
            });
            builder.addCustomProp({
                name: "Pile types",
                type_ident: "piles-type",
                value: trackerBins,
                onChange: () => {},
                context: {
                    binsBuilder,
                },
            });
        return builder.finish();
    }).withoutEqCheck();


    return new ContextMenuConfig({
        identity: 'pile-type-menu', 
        viewSource: new PUI_Lazy(puiLazy), 
        action: { name: 'Ok' },
        positionPx: pos,
        header: `${binsBuilder.selectedTracker.poll()} pile types`,
        widthPx: 460,
        maxHeightPx: 800
    });
}