import { IterUtils } from 'engine-utils-ts';
import type { Vector3 } from 'math-ts';

import Utils from '../utils/Utils';

export class GizmoEditUtils {

	static deleteBySeparateKeys<K, V>(keys: K[], values: V[], keysToDelete: K[]) {
		const {removedIndices} = IterUtils.retain(keys, (k) => keysToDelete.includes(k));
		IterUtils.retain(values, (_, index) => removedIndices.includes(index));
	}

	static mapToIndicesInByProp<T1, T2>(objsToMap: T1[], indiciesIn: T2[], propExtractor: (prop: T2) => T1): number[] {
		const result: number[] = [];

		for (const obj of objsToMap) {
			let found = false;
			for (let i = 0; i < indiciesIn.length; ++i) {
				if (propExtractor(indiciesIn[i]) === obj) {
					result.push(i);
					found = true;
					break;
				}
			}
			if (!found) {
				result.push(-1);
			}
		}
		return result;
	}

    static chooseNewPointInsertionIndex(args: {
        pointIndex: number,
        currentPoints: Vector3[],
        gestureDirection: Vector3,
        closeFirstToLast: boolean
    }): number {
        const pointRepr = args.currentPoints[args.pointIndex];
        // choose best direction
        const prevPointIfAbsent = args.closeFirstToLast ? args.currentPoints[args.currentPoints.length - 1] : pointRepr;
        const lastPointIfAbsent = args.closeFirstToLast ? args.currentPoints[0] : pointRepr;
        const prevPoint = (args.currentPoints[args.pointIndex - 1] ?? prevPointIfAbsent).clone();
        const nextPoint = (args.currentPoints[args.pointIndex + 1] ?? lastPointIfAbsent).clone();

        const prevDirection = pointRepr.clone().sub(prevPoint).normalize();
        const nextDirection = pointRepr.clone().sub(nextPoint).normalize();

        const prevDot = prevDirection.dot(args.gestureDirection);
        const nextDot = nextDirection.dot(args.gestureDirection);

        const positionToInsertInto = prevDot < nextDot ? args.pointIndex : args.pointIndex + 1;

        return positionToInsertInto;
    }

    static getPointsIndicesAsConsecutiveSegment(indices: number[], pointsCount: number)
        : {indices: number[], crossesZero: boolean} | null 
    {
        indices = indices.filter(ind => ind >= 0 && ind < pointsCount);
        IterUtils.sortDedupNumbers(indices);

        if (!(indices.length > 1 && indices.length < pointsCount && pointsCount >= 3)) {
            return null;
        }
        
        const crossesZero = indices.includes(pointsCount - 1) && indices.includes(0);

        if (crossesZero) {
            // if includes 0 and last point
            for (let i = 0; i < indices.length; ++i) {
                if (indices[i] !== i) {
                    break;
                }
                indices[i] += pointsCount;
            }
        }
        indices.sort(Utils.sortNumbersAscend);

        for (let i = 1; i < indices.length; ++i) {
            const prevIndex = indices[i - 1];
            const nextIndex = indices[i];
            let areConsecutive = prevIndex + 1 === nextIndex;
            if (!areConsecutive) {
                return null;
            }
        }

        return {indices: indices.map(ind => ind % pointsCount), crossesZero };
    }
}
