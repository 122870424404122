import type { ScopedLogger, Result} from 'engine-utils-ts';
import { Failure, isResult, JobExecutor, ObjectUtils, registerExecutor, resultify, Success, WorkerPool, Yield } from 'engine-utils-ts';
import type { Bim } from '..';
import type { VirtualPropertyBase, AnyVirtualProperty } from '../properties/VirtualProperty';


export class VirutalPropsRuntime {

    readonly logger: ScopedLogger;
    readonly _bim: Bim;

    private readonly _calcStates: WeakMap<AnyVirtualProperty, PropertyCalculationState> = new WeakMap();

    private _estimatedRamConsumptionBytes: number = 0;
    private _calculatedObjectsLiveCount: number = 0;

    private readonly _calculatedValuesFinRegistry: FinalizationRegistry<number>;

    private readonly _hardLinks: Map<Result<Object>, number> = new Map();
    private _accessCounter: number = 0;

    constructor(bim: Bim) {
        this.logger = bim.logger.newScope('on-demand-props');
        this._bim = bim;

        this._calculatedValuesFinRegistry = new FinalizationRegistry(
            (size) => {
                this._estimatedRamConsumptionBytes -= size;
                this._calculatedObjectsLiveCount -= 1;
            },
        );
    }

    estimateRamConsumption(): { objsCount: number, bytes: number } {
        return {
            bytes: this._estimatedRamConsumptionBytes,
            objsCount: this._calculatedObjectsLiveCount,
        }
    }

    acquireTemporaryValueOf<V extends Object>(p: VirtualPropertyBase<V, any, any>): Generator<Yield, Result<V>> {
        let calcState = this._calcStates.get(p);
        if (calcState === undefined) {
            calcState = {
                generator: null,
                value: null,
            };
            this._calcStates.set(p, calcState);
        } else if (calcState.value?.deref() !== undefined) {
            const value = calcState.value.deref() as Result<V>;
            this._updateHardLinkAcessTimeCounter(value);
            return doneIterator(value as Result<V>);
        }
        let generator = calcState.generator?.deref();
        if (!generator) {
            generator = new GeneratorWrapperForMultipleUsers(p, this);
            calcState.generator = new WeakRef(generator);
        }
        return generator as Generator<Yield, Result<V>>;
    }

    forEachTemporaryValueOf<Value extends Object, P extends VirtualPropertyBase<Value, any, any>>(
        props: P[],
        processFn: (pValue: Result<Value>, index: number, property: P) => void,
    ): Generator<Yield, void> {

        // make sure to call callbacks in the order of props
        // othwerwise we may produce unstable results
        // because of floating point operations order dependency

        return new GeneratorForForEachProps(
            this,
            props,
            processFn as (pValue: Result<any>, index: number, p: AnyVirtualProperty) => void
        );
    }

    __saveResultFor(prop: AnyVirtualProperty, value: Result<Object>) {
        let estimatedResSize: number;
        if (value instanceof Failure) {
            const e = value.errorMsg();
            estimatedResSize = typeof e === 'string' ? e.length * 2 + 24 : 1000;
        } else {
            estimatedResSize = ObjectUtils.fastEvaluateObjectSizeInbytes(value.value) + 24;
        }
        const calculationState = this._calcStates.get(prop)!;
        const resWr = new WeakRef(value);
        calculationState.generator = null;
        calculationState.value = resWr;
        this._calculatedObjectsLiveCount += 1;
        this._estimatedRamConsumptionBytes += estimatedResSize;
        this._calculatedValuesFinRegistry.register(value, estimatedResSize);

        this._updateHardLinkAcessTimeCounter(value);
    }

    private _updateHardLinkAcessTimeCounter(val: Result<Object>) {

        this._hardLinks.set(val, this._accessCounter++);

        if (this._hardLinks.size > HardLinksCacheMaxCount) {
            this.logger.debug('clearing hard links cache');
            const expectedHalfAcessTimeCounterValue = this._accessCounter - HardLinksCacheMaxCount * 0.5;
            for (const [res, counter] of this._hardLinks.entries()) {
                if (counter < expectedHalfAcessTimeCounterValue) {
                    this._hardLinks.delete(res);
                }
            }
        }
    }
}

interface PropertyCalculationState {
    generator: WeakRef<GeneratorWrapperForMultipleUsers> | null;
    value: WeakRef<Result<Object>> | null;
}

const HardLinksCacheMaxCount = 50;


function* doneIterator<V>(value: Result<V>): Generator<Yield, Result<V>> {
    return value;
}

class GeneratorWrapperForMultipleUsers implements Generator<Yield, Result<Object>> {

    private _result: Result<Object> | null = null;

    private _argsGenerator: Generator<Yield, Object> | null = null;
    private _resultGenerator: Generator<Yield, Object> | null = null;

    private _prop: AnyVirtualProperty | null = null;
    private _virtualPropsRuntime: VirutalPropsRuntime | null = null;

    constructor(
        prop: AnyVirtualProperty,
        virtualPropsRuntime: VirutalPropsRuntime,
    ) {
        this._prop = prop;
        this._virtualPropsRuntime = virtualPropsRuntime;
        if (prop._extractAdditionalArgsFromBim) {
            this._argsGenerator = prop._extractAdditionalArgsFromBim(virtualPropsRuntime._bim) as Generator<Yield, Object>;
        } else {
            this._resultGenerator = this._createResultGenerator(undefined) as Generator<Yield, Object>;
        }
    }

    private _createResultGenerator(additionalArgs: Object | undefined): Generator<Yield, Object> {
        const estimatedWPDuration = this._prop!.workerPoolJobDurationEstimatorMs(additionalArgs);
        if (estimatedWPDuration > 0 && estimatedWPDuration < Infinity) {
            return new InWorkerPoolPropertyAwaiterGenerator(this._prop!, additionalArgs, estimatedWPDuration);
        } else {
            return this._prop!._calculate(additionalArgs);
        }
    }

    private _setResult(result: Result<Object>) {
        if (this._result) {
            throw new Error('result already set');
        }
        if (this._result === result) {
            return;
        }
        this._result = result;
        this._argsGenerator = null;
        this._resultGenerator = null;
        this._virtualPropsRuntime!.__saveResultFor(this._prop!, result);
        this._prop = null;
        this._virtualPropsRuntime = null;
    }

    next(): IteratorResult<Yield, Result<Object>> {
        if (this._result) {
            return { value: this._result, done: true };
        }
        try {
            if (this._argsGenerator) {
                const iterResult = this._argsGenerator.next();
                if (iterResult.done) {
                    this._argsGenerator = null;
                    this._resultGenerator = this._createResultGenerator(iterResult.value);
                } else {
                    return iterResult;
                }
            }
            const iterResult = this._resultGenerator!.next();
            if (iterResult.done) {
                this._setResult(resultify(iterResult.value));
            } else {
                return iterResult;
            }
        } catch (e) {
            this._setResult(new Failure(e));
        }
        return { value: this._result!, done: true };
    }
    return(value: Result<Object>): IteratorResult<Yield, Result<Object>> {
        if (!(isResult(value))) {
            const v = value as any;
            throw new Error(`unexpected value type: ${v}::${typeof v}::${v?.constructor?.name}}`);
        }
        if (this._resultGenerator) {
            try {
                this._resultGenerator.return(value);
            } catch (e) {
                console.error('error while returning value to generator', e);
            }
        }
        this._setResult(value);
        return { value: this._result!, done: true };
    }
    throw(e: any): IteratorResult<Yield, Result<Object>> {
        if (this._resultGenerator) {
            try {
                this._resultGenerator.throw(e);
            } catch (e) {
                console.error('error while throwing value to generator', e);
            }
        }
        this._setResult(new Failure(e));
        return { value: this._result!, done: true };
    }
    [Symbol.iterator](): Generator<Yield, Result<Object>, unknown> {
        return this;
    }
}

class InWorkerPoolPropertyAwaiterGenerator implements Generator<Yield, Object> {

    _result: Result<Object> | null = null;

    constructor(
        property: AnyVirtualProperty,
        additionalArgs: Object | undefined,
        estimatedDurationMs: number,
    ) {
        const propertyId = WorkerPool.getUniqueIdForObject(property);
        WorkerPool.execute(
            VirtPropertyWorkerExecutor,
            {
                property,
                additionalArgs,
                estimatedDurationMs,
            },
            property.constructor.name + ':' + propertyId,
        ).then(
            res => { this._result = new Success(res) },
            (err) => { this._result = new Failure(err) },
        )
    }

    next(): IteratorResult<Yield, Object> {
        if (!this._result) {
            return { value: Yield.NextFrame, done: false };
        }
        if (this._result instanceof Success) {
            return { value: this._result.value, done: true };
        } else {
            throw this._result;
        }
    }
    return(value: Object): IteratorResult<Yield, Object> {
        throw new Error('Method not implemented.');
    }
    throw(e: any): IteratorResult<Yield, Object> {
        throw new Error('Method not implemented.');
    }
    [Symbol.iterator](): Generator<Yield, Object, unknown> {
        return this;
    }
}


interface PropertyCalcState {
    property: AnyVirtualProperty;
    index: number;
    result: Generator<Yield, Result<Object>> | Result<Object> | undefined;
}
class GeneratorForForEachProps implements Generator<Yield, void> {

    private _virtualPropsRuntime: VirutalPropsRuntime;

    forEachResultCallback: (pValue: Result<any>, index: number, p: AnyVirtualProperty) => void;

    reversedIndexedPropsLeft: PropertyCalcState[];

    constructor(
        virtualPropsRuntime: VirutalPropsRuntime,
        props: AnyVirtualProperty[],
        forEachCallback: (pValue: Result<any>, index: number, p: AnyVirtualProperty) => void,
    ) {
        this._virtualPropsRuntime = virtualPropsRuntime;
        this.forEachResultCallback = forEachCallback;
        this.reversedIndexedPropsLeft = props.map((p, i) => ({
            property: p,
            index: i,
            result: undefined,
        })).reverse();
        props.length = 0;

    }

    next(): IteratorResult<Yield, void> {

        const startT = performance.now();

        const MaxPropsInFlight = 25;
        const AllowedYieldingTimeMsTotalMs = 8;

        let haveMoreToRunAsap = false;
        outer: for (
            let i = this.reversedIndexedPropsLeft.length - 1, iEnd = Math.max(0, i - MaxPropsInFlight);
            i >= iEnd && (performance.now() - startT < AllowedYieldingTimeMsTotalMs);
            --i
        ) {
            const p = this.reversedIndexedPropsLeft[i];
            if (!p.result) {
                p.result = this._virtualPropsRuntime.acquireTemporaryValueOf(p.property);
            }
            if (isResult(p.result)) {
                if (i === this.reversedIndexedPropsLeft.length - 1) {
                    this.reversedIndexedPropsLeft.pop();
                    this.forEachResultCallback(p.result, p.index, p.property);
                }
            } else {
                do {
                    const yielded = p.result.next();
                    if (yielded.done) {
                        p.result = resultify(yielded.value);
                        haveMoreToRunAsap = true;
                        break;
                    }
                    if (yielded.value === Yield.NextFrame) {
                        break;
                    } else {
                        haveMoreToRunAsap = true;
                    }
                } while (performance.now() - startT < AllowedYieldingTimeMsTotalMs)
            }
        }

        // console.log('foreach generator duration', performance.now() - startT);

        if (this.reversedIndexedPropsLeft.length > 0) {
            const valueToYield = haveMoreToRunAsap ? Yield.Asap : Yield.NextFrame;
            return { value: valueToYield, done: false };
        }

        return { value: undefined, done: true };
    }
    return(value: void): IteratorResult<Yield, void> {
        throw new Error('Method not implemented.');
    }
    throw(e: any): IteratorResult<Yield, void> {
        throw new Error('Method not implemented.');
    }
    [Symbol.iterator](): Generator<Yield, void, unknown> {
        return this;
    }
}


interface VirtPropertyWorkerExecutorArgs {
    property: AnyVirtualProperty;
    additionalArgs: Object | undefined;
    estimatedDurationMs: number;
}
class VirtPropertyWorkerExecutor extends JobExecutor<VirtPropertyWorkerExecutorArgs, Result<any> > {

    execute(args: VirtPropertyWorkerExecutorArgs): Result<any> {
        let result: Result<any>;
        try {
            const generator = args.property._calculate(args.additionalArgs);
            let nextFramesCount = 0;
            for (let i = 0; ; ++i) {
                const genValue = generator.next();
                if (genValue.done) {
                    result = new Success(genValue.value);
                    break;
                } else if (genValue.value === Yield.NextFrame) {
                    console.warn('unexpected yield next frame in worker virtual property executor');
                    nextFramesCount += 1;
                    if (nextFramesCount > 5) {
                        const msg = `virtual property executor stuck yielding NextFrame`;
                        console.error(msg, args.property, args.additionalArgs);
                        result = new Failure({msg: msg});
                        break;
                    }
                }
                if (i > 10_000) {
                    const msg = `virtual property executor stuck in loop, too many iteration ${i}`;
                    console.error(msg, i, args.property, args.additionalArgs);
                    result = new Failure({msg: msg});
                    break;
                }
            }
        } catch (e) {
            console.error('error while executing virtual property', args.property, args.additionalArgs, e);
            result = new Failure(e);
        }
        return result;
    }

    estimateTaskDurationMs(args: VirtPropertyWorkerExecutorArgs): number {
        return args.estimatedDurationMs;
    }

    argsAndResultsMayShareObjects(): boolean {
        return true;
    }

}
registerExecutor(VirtPropertyWorkerExecutor);