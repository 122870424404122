import type { UndoStack, Result} from 'engine-utils-ts';
import { Failure, Success, WorkerClassPassRegistry } from 'engine-utils-ts';
import { Aabb, MAXINT16, Vector2, Vector3 } from 'math-ts';

import { BimGeometryType } from './BimGeometries';
import { ElevationSample } from '../terrain/TerrainElevation';
import { calcTriangleAreaByPoints } from '../terrain/metrics/TerrainMetrics';
import type { BimGeometryBase } from './BimGeometriesBase';
import { BimGeometriesBase } from './BimGeometriesBase';

export class RegularHeightmapGeometry implements BimGeometryBase {

    public readonly _area: number;

    constructor(
        public readonly xSegmentsCount: number = 0,
        public readonly ySegmentsCount: number = 0,
        public readonly segmentSizeInMeters: number = 0,
        public readonly elevationsBaseInCm: number = 0, // should always be 1cm lower than minimum elevation
        public readonly elevationsInCmRelative: Uint8Array | Uint16Array | Uint32Array = new Uint8Array(), // 0 means absence
    ) {
        this._area = this.___calculateArea();
    }

    checkForErrors(errors: string[]): void {
		if (!(this.segmentSizeInMeters > 0 && this.segmentSizeInMeters < Infinity)) {
            errors.push(`invalid pixel size ${this.segmentSizeInMeters}`);
        }
        if (!Number.isInteger(this.xSegmentsCount) || !(this.xSegmentsCount > 0)) {
            errors.push(`invalid xCount ${this.xSegmentsCount}`);
        }
        if (!Number.isInteger(this.ySegmentsCount) || !(this.ySegmentsCount > 0)) {
            errors.push(`invalid yCount ${this.ySegmentsCount}`);
        }
        if (!Number.isInteger(this.elevationsBaseInCm)) {
            errors.push(`invalid elevationsBaseInCm ${this.elevationsBaseInCm}`);
        }
        if (this.elevationsInCmRelative.length !== (this.xSegmentsCount + 1) * (this.ySegmentsCount + 1)) {
            errors.push(`heightsLength=${this.elevationsInCmRelative.length}; xSegmentsCount=${this.xSegmentsCount}; ySegmentsCount=${this.ySegmentsCount}`);
        }
        if (!this.elevationsInCmRelative.includes(1)) {
            errors.push(`elevationsInCm should include at least one 1cm elevation`);
            if (this.elevationsInCmRelative.every((e) => Number.isNaN(e))) {
                errors.push(`geometry doesnt have any valid elevations`);
            }
        }
	}

    static newFromMetersAndNaNs(
        xSegmentsCount: number = 0,
        ySegmentsCount: number = 0,
        segmentSizeInMeters: number = 0,
        elevations: Float32Array,
    ): Result<RegularHeightmapGeometry> {
        let minElevation = Infinity;
        let maxElevation = -Infinity;
        for (const elevation of elevations) {
            if (elevation > -MAXINT16 && elevation < MAXINT16) {
                if (elevation < minElevation) {
                    minElevation = elevation;
                }
                if (elevation > maxElevation) {
                    maxElevation = elevation;
                }
            }
        }
        if (!Number.isFinite(minElevation)) {
            return new Failure({msg: 'elevations are not valid'});
        }
        const minElevationCm = Math.round(minElevation * 100);
        const maxElevationCm = Math.round(maxElevation * 100);
        const elevationsRangeCm = maxElevationCm - minElevationCm;

        let relativeElevationsInCm: Uint8Array | Uint16Array | Uint32Array;
        if (elevationsRangeCm < 0xFF) {
            relativeElevationsInCm = new Uint8Array(elevations.length);
        } else if (elevationsRangeCm < 0xFFFF) {
            relativeElevationsInCm = new Uint16Array(elevations.length);
        } else {
            relativeElevationsInCm = new Uint32Array(elevations.length);
        }

        const baseElevationCm = minElevationCm - 1;
        for (let i = 0; i < elevations.length; ++i) {
            const elevation = elevations[i];
            if (elevation > -MAXINT16 && elevation < MAXINT16) {
                const relativeElevationInCm = Math.round(elevation * 100) - baseElevationCm;
                relativeElevationsInCm[i] = relativeElevationInCm;
            }
        }
        return new Success(new RegularHeightmapGeometry(
            xSegmentsCount,
            ySegmentsCount,
            segmentSizeInMeters,
            baseElevationCm,
            relativeElevationsInCm
        ));
    }

    elevationsAsFloats(): Float32Array {
        const res = new Float32Array(this.elevationsInCmRelative.length);
        for (let i = 0; i < this.elevationsInCmRelative.length; ++i) {
            const elevRelative = this.elevationsInCmRelative[i];
            let floatHeight: number;
            if (elevRelative > 0) {
                floatHeight = (elevRelative + this.elevationsBaseInCm) * 0.01;
            } else {
                floatHeight = NaN;
            }
            res[i] = floatHeight;
        }
        return res;
    }

    hasHoles() {
        return this.elevationsInCmRelative.includes(0);
    }

    area(): number {
        return this._area;
    }

    ___calculateArea(): number {
        let p00 = new Vector3(), p10 = new Vector3(), p01 = new Vector3(), p11 = new Vector3();
        let c = new Vector3();
        
        let totalArea = 0;

        for (let iy = 0; iy < this.ySegmentsCount; iy++) {
            const yCoord = iy * this.segmentSizeInMeters;

            p10.set(0, yCoord, this.readElevationAtInds(0, iy));
            p11.set(0, yCoord + this.segmentSizeInMeters, this.readElevationAtInds(0, iy + 1));

            for (let ix = 0; ix < this.xSegmentsCount; ix++) {
                const xCoord = ix * this.segmentSizeInMeters;

                p00.copy(p10);
                p01.copy(p11);

                p10.set(xCoord + this.segmentSizeInMeters, yCoord, this.readElevationAtInds(ix + 1, iy));
                p11.set(
                    xCoord + this.segmentSizeInMeters, 
                    yCoord + this.segmentSizeInMeters, 
                    this.readElevationAtInds(ix + 1, iy + 1)
                );

                c.x = xCoord + 0.5 * this.segmentSizeInMeters;
                c.y = yCoord + 0.5 * this.segmentSizeInMeters;
                c.z = 0.25 * (p00.z + p01.z + p10.z + p11.z);

                if (!isNaN(c.z)) {
                    totalArea += calcTriangleAreaByPoints(p00, p10, c);
                    totalArea += calcTriangleAreaByPoints(p10, p11, c);
                    totalArea += calcTriangleAreaByPoints(p11, p01, c);
                    totalArea += calcTriangleAreaByPoints(p01, p00, c);
                }
            }
        }

        return totalArea;
    }

	calcAabb(): Aabb {
		let maxZInCm = 0;

        let minValidIx = Infinity;
        let minValidIy = Infinity;
        let maxValidIx = -Infinity;
        let maxValidIy = -Infinity;
        for (let iy = 0; iy < this.ySegmentsCount + 1; ++iy) {
            for (let ix = 0; ix < this.ySegmentsCount + 1; ++ix) {
                const e = this.elevationsInCmRelative[ix + iy * (this.xSegmentsCount + 1)];
                if (e === 0) {
                    continue;
                }
                maxZInCm = Math.max(maxZInCm, e);
                minValidIx = Math.min(minValidIx, ix);
                minValidIy = Math.min(minValidIy, iy);
                maxValidIx = Math.max(maxValidIx, ix);
                maxValidIy = Math.max(maxValidIy, iy);
            }
        }

		const result = Aabb.empty();
        result.setMinFrom(new Vector3(
            minValidIx * this.segmentSizeInMeters,
            minValidIy * this.segmentSizeInMeters,
            (this.elevationsBaseInCm  + 1) * 0.01,
        ));
        result.setMaxFrom(new Vector3(
            maxValidIx * this.segmentSizeInMeters,
            maxValidIy * this.segmentSizeInMeters,
            (this.elevationsBaseInCm  + maxZInCm) * 0.01,
        ));
		return result;
	}

    public static eq(lhs: RegularHeightmapGeometry, rhs: RegularHeightmapGeometry) {
        if (lhs.xSegmentsCount !== rhs.xSegmentsCount || lhs.ySegmentsCount !== rhs.ySegmentsCount) {
            return false;
        }
        if (lhs.segmentSizeInMeters != rhs.segmentSizeInMeters) {
            return false;
        }
        if (lhs.elevationsBaseInCm !== rhs.elevationsBaseInCm) {
            return false;
        }
        for (let i = 0; i < lhs.elevationsInCmRelative.length; ++i) {
            const lh = lhs.elevationsInCmRelative[i];
            const rh = rhs.elevationsInCmRelative[i];
            if (lh !== rh) {
                return false;
            }
        }
        return true;
    }

    sampleInLocalSpace(points: Vector2[]): ElevationSample[] {
        const result = points.map(p => this.sampleInLocalSpaceSingular(p));
        return result;
    }

    sampleInLocalSpaceSingular(p: Vector2): ElevationSample {
        const ixFract = p.x / this.segmentSizeInMeters;
        const iyFract = p.y / this.segmentSizeInMeters;

        const ix0 = Math.floor(ixFract);
        const iy0 = Math.floor(iyFract);
        const ix1 = Math.ceil(ixFract)
        const iy1 = Math.ceil(iyFract);

        const z00 = this.readElevationAtInds(ix0, iy0);
        const z10 = this.readElevationAtInds(ix1, iy0);
        const z01 = this.readElevationAtInds(ix0, iy1);
        const z11 = this.readElevationAtInds(ix1, iy1);

        const dxNorm = ixFract - ix0;
        const dyNorm = iyFract - iy0;

        const z = (1 - dxNorm) * (1 - dyNorm) * z00
            + dxNorm * (1 - dyNorm) * z10
            + (1 - dxNorm) * dyNorm * z01
            + dxNorm * dyNorm * z11;

        const sample = new ElevationSample();
        if (!Number.isNaN(z)) {
            sample.distToRealSample = 0;
            sample.elevation = z;
        } else {
            // if any of 4 points is absent, just find closest valid sample

            let resultZ: number = NaN;
            let minDistanceYet = Infinity;
            
            if (!Number.isNaN(z00)) {
                if (!Number.isNaN(z10) && dyNorm < minDistanceYet) {
                    resultZ = (1 - dxNorm) * z00 + dxNorm * z10;
                    minDistanceYet = dyNorm;
                }
                if (!Number.isNaN(z01) && dxNorm < minDistanceYet) {
                    resultZ = (1 - dyNorm) * z00 + dyNorm * z01;
                    minDistanceYet = dxNorm;
                }
            } 
            if (!Number.isNaN(z11)) {
                if (!Number.isNaN(z10) && 1 - dxNorm < minDistanceYet) {
                    resultZ = (1 - dyNorm) * z10 + dyNorm * z11;
                    minDistanceYet = 1 - dxNorm;
                }
                if (!Number.isNaN(z01) && 1 - dyNorm < minDistanceYet) {
                    resultZ = (1 - dxNorm) * z01 + dxNorm * z11;
                    minDistanceYet = 1 - dyNorm;
                }
            }

            if (minDistanceYet < Infinity) {
                sample.tryUdpateSample(resultZ, minDistanceYet * this.segmentSizeInMeters);
            }
        }
        return sample;
    }

    readElevationAtInds(ix: number, iy: number): number {
        if (ix < 0 || ix > this.xSegmentsCount) {
            return NaN;
        }
        if (iy < 0 || iy > this.ySegmentsCount) {
            return NaN;
        }
        const eCm = this.elevationsInCmRelative[ix + iy * (this.xSegmentsCount + 1)];
        if (eCm === 0) {
            return NaN;
        }
        return (eCm + this.elevationsBaseInCm) * 0.01;
    }

    nonZeroPointsCount() {
        let count = 0;
        for (const elevation of this.elevationsInCmRelative) {
            if (elevation) {
                count += 1;
            }
        }
        return count;
    }

    resampledWithSegmentsCounts(xSegmentCount: number, ySegmentCount: number): Result<RegularHeightmapGeometry> {
        if (xSegmentCount / this.xSegmentsCount !== ySegmentCount / this.ySegmentsCount) {
            return new Failure({msg: `segments counts ratio arent equal ${xSegmentCount}/${this.xSegmentsCount} ${ySegmentCount}/${this.ySegmentsCount}`});
        };
        const newSegmentsSize = this.xSegmentsCount * this.segmentSizeInMeters / xSegmentCount;
        const newElevations = new Float32Array((xSegmentCount + 1) * (ySegmentCount + 1));

        const reusedSamplePos = new Vector2();
        for (let iy = 0; iy <= ySegmentCount; ++iy) {
            const yCoord = iy * newSegmentsSize;

            for (let ix = 0; ix <= xSegmentCount; ++ix) {

                const xCoord = ix * newSegmentsSize;

                const elevationSample = this.sampleInLocalSpaceSingular(reusedSamplePos.set(xCoord, yCoord));

                const elevation = elevationSample.distToRealSample > 0.01 ? NaN : elevationSample.elevation!;

                newElevations[ix + iy * (xSegmentCount + 1)] = elevation;
            }
        }
        return RegularHeightmapGeometry.newFromMetersAndNaNs(xSegmentCount, ySegmentCount, newSegmentsSize, newElevations);
    }
}
WorkerClassPassRegistry.registerClass(RegularHeightmapGeometry);

export class RegularHeightmapGeometries extends BimGeometriesBase<RegularHeightmapGeometry> {

    constructor(
        undoStack?: UndoStack,
    ) {
        super({
            identifier: "regular-heightmap-geometries",
            idsType: BimGeometryType.HeightmapRegular,
            undoStack,
            T_Constructor: RegularHeightmapGeometry
        })
    }
}
