import { EquipmentArea, MultiSelectorProperty, ProjectMetrics, PropertyGroup, UnitsMapper, createPropertyGroupPuiTransformers } from "bim-ts";
import { Failure, IterUtils, Yield, getResultValueOrThrow } from "engine-utils-ts";
import { ExportedFileDescription, FileExporter, FileExporterContext } from "ui-bindings";
import { convertMetricsToXlsx } from "./ConvertMetricsToXlsx";

interface MetricsExporterSettings extends PropertyGroup { 
    'Selected area': MultiSelectorProperty;
}

export class ProjectMetricsExporter implements FileExporter<MetricsExporterSettings> {
    private readonly areaPerName: Map<string, EquipmentArea>;

    constructor(
        readonly unitsMapper: UnitsMapper,
        readonly projectMetrics: ProjectMetrics,
    ) {
        this.areaPerName = new Map();
    }

    initialSettings() {
        this.areaPerName.clear();
        const areasContext = this.projectMetrics.areasContext.poll();
        for (const area of areasContext.areas) {
            if(!this.areaPerName.has(area.name)){
                this.areaPerName.set(area.name, area);
            } else {
                this.areaPerName.set(`${area.name} (${area.id})`, area)
            }
        }
        const settingsConfig: MetricsExporterSettings = {
            'Selected area': MultiSelectorProperty.new({
                value: Array.from(this.areaPerName.keys()),
                options: Array.from(this.areaPerName.keys()),
            })
        };
        const transformer = createPropertyGroupPuiTransformers();
        return {
            defaultValue: settingsConfig, 
            uiBuilderParams: {
                configBuilderParams: transformer,
            },
        };
    }



    *startExport(
        settings: MetricsExporterSettings, 
        context: FileExporterContext,
        ): Generator<Yield, ExportedFileDescription[]> {
        const selectedAreasNames = this.getSelectedAreas(settings);
        const timeoutMs = 30_000;
        const metricsResult = yield* this.projectMetrics.waitTillCompletion(timeoutMs);

        if(metricsResult instanceof Failure){
            throw new Error("metrics not calculated");
        }
        
        const xlsxFileResult = yield* convertMetricsToXlsx(
            this.unitsMapper, 
            metricsResult.value, 
            selectedAreasNames, 
            context.logger,
        );
        
        const file = getResultValueOrThrow(xlsxFileResult, "failed to convert metrics to xlsx");

        const result: ExportedFileDescription = {
            name: "Project metrics",
            file: file,
            extension: "xlsx",
        };

        return [result];
    }

    private getSelectedAreas(settings: MetricsExporterSettings): EquipmentArea[] {
        const areas = IterUtils.filterMap(settings['Selected area'].value, a => this.areaPerName.get(a));
        if(areas.length === 0){
            throw new Error("No areas selected");
        }
        return areas;
    }
}