<script lang="ts">
    import { navigate } from "svelte-routing";
    import { getContext } from "svelte";
    import Logo from "../libui/icon/svg/navbar/logo.svelte";
    import LogoFull from "../libui/icon/svg/navbar/logo-full.svelte";
    import Menu from "../libui/icon/svg/navbar/menu.svelte";
    import type { UiBindings } from "ui-bindings";
    import NavbarItem from "./NavbarItem.svelte";
    import {
        NavbarContext,
        PanelPosition,
        navItemFromUiBindings,
        getNavItemsByGroup,
    } from "./Navbar";
    import NavbarPanel from "./NavbarPanel.svelte";
    import OverlayPanel from "./OverlayPanel.svelte";
    import NavbarGroup from "./NavbarGroup.svelte";
    import type { EngineUiLayout } from "../ui-panels/EngineUiLayout";
    import DropArea from "./DropArea.svelte";
    import NavbarDynamicItem from "./NavbarDynamicItem.svelte";
    import IntercomButton from "./IntercomButton.svelte";

    export let uiBindings: UiBindings;
    export let engineUiLayoutRef: EngineUiLayout;

    const navbar = <NavbarContext>getContext("ui-navbar");
    const panelViews = navItemFromUiBindings(uiBindings, navbar);
    navbar.syncTabsPosition(panelViews);

    $: menuGroups = getNavItemsByGroup(panelViews, $navbar.views);
    $: panel = $navbar.panels.getOrCreate(PanelPosition.Left);
    $: secondPanel = $navbar.panels.getOrCreate(PanelPosition.LeftSecond);

    $: primaryViewName =
        panel.views[0] || (secondPanel.open ? secondPanel.views[0] : undefined);

    function getView(panelName?: string) {
        return panelViews.find((m) => m.id === panelName);
    }
    export let navbarWidth;
</script>

<div class="navbar-container text-main-dark" bind:offsetWidth={navbarWidth}>
    <div
        class="navbar mdc-typography--headline5 text-main-medium"
        class:expanded={$navbar.expanded}
        class:overlay={!!$navbar.overlayPanel}
    >
        <div class="navbar-logo text-main-dark" on:click={navbar.toggle}>
            {#if $navbar.expanded}
                <Menu />
            {/if}
            <Logo />
            {#if $navbar.expanded}
                <LogoFull />
            {/if}
        </div>
        <div class="navbar-top">
            <NavbarGroup>
                <NavbarItem
                    id="dashboard"
                    name="Dashboard"
                    iconName={"Home"}
                    collapsed={!$navbar.expanded}
                    onClick={() => navigate("dashboard")}
                />
            </NavbarGroup>
        </div>
        <div class="navbar-groups">
            {#each menuGroups as menuGroup (menuGroup.name)}
                <NavbarGroup>
                    {#each menuGroup.children as menuItem (menuItem.name)}
                        {#if menuItem.view.dynamicLabel}
                            <NavbarDynamicItem
                                name={menuItem.view.dynamicLabel}
                                collapsed={!$navbar.expanded}
                                active={$navbar.overlayPanel === menuItem.id}
                                onClick={menuItem.toggle}
                            />
                        {:else}
                            <NavbarItem
                                id={menuItem.id}
                                name={menuItem.name}
                                iconName={menuItem.iconName}
                                collapsed={!$navbar.expanded}
                                compact={menuGroup.name === "Analysis"}
                                active={menuItem.isActive(panel)}
                                secondary={menuItem.isSecondary(
                                    panel,
                                    engineUiLayoutRef.isPanelEnabled(
                                        menuItem.id,
                                    ),
                                ) || menuItem.isActive(secondPanel)}
                                onClick={menuItem.toggle}
                            />
                        {/if}
                    {/each}
                </NavbarGroup>
            {/each}
        </div>
        <div class="navbar-bottom">
            <NavbarGroup>
                <NavbarItem
                    id="help-center"
                    name="Help center"
                    iconName={"Help"}
                    collapsed={!$navbar.expanded}
                    compact={true}
                    onClick={() =>
                        window.open("https://intercom-help.eu/PVFARM/en/")}
                />
                <IntercomButton showName={$navbar.expanded} />
            </NavbarGroup>
        </div>
    </div>
    <div class="panels-container">
        {#if secondPanel.open && (!panel.open || (panel.views[0] && secondPanel.views[0]))}
            <NavbarPanel
                position={PanelPosition.LeftSecond}
                topView={getView(secondPanel.views[0])}
            />
        {/if}
        {#if panel.open}
            <NavbarPanel
                position={PanelPosition.Left}
                topView={getView(primaryViewName)}
                bottomView={getView(panel.views[1])}
                onClose={() => {
                    if (primaryViewName === secondPanel.views[0]) {
                        navbar.closePanel(PanelPosition.LeftSecond);
                    }
                    navbar.closePanel(PanelPosition.Left);
                }}
            />
        {/if}
        <OverlayPanel view={getView($navbar.overlayPanel)} />
        <DropArea {navbar} side={PanelPosition.Left} />
    </div>
</div>

<style lang="scss">
    .navbar-container {
        height: 100%;
        position: absolute;
        top: 0;
        display: flex;
    }

    .navbar {
        width: 42px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(white, 0.4);
        z-index: 250;
        background: linear-gradient(
                0deg,
                rgba(16, 58, 82, 0.04) 0%,
                rgba(16, 58, 82, 0.04) 100%
            ),
            white;
        box-shadow:
            0px 2px 8px 0px rgba(0, 0, 0, 0.12),
            0px 0px 2px 0px rgba(0, 0, 0, 0.06);

        &.expanded {
            width: 194px;
        }
        .navbar-groups {
            flex: 1;
            display: flex;
            flex-direction: column;
            :global(.divider:last-child) {
                margin-top: auto;
            }
        }
        :global(.nav-button.selected:after) {
            right: -1px;
        }
        :global(.mdc-tooltip) {
            transform: translate(50px, -34px);
        }

        &.overlay:after {
            content: "";
            position: absolute;
            top: 0;
            left: -4px;
            width: 300px;
            height: 100%;
            background-color: var(--color-main-light);
        }
    }
    .navbar-bottom {
        :global(.divider) {
            margin: 4px 0;
            &::before {
                top: -4px;
            }
        }
        :global(.mdc-tooltip) {
            transform: translate(50px, 32px);
        }
    }
    .navbar-top {
        :global(.nav-button) {
            align-items: end;
        }
        :global(.divider) {
            margin: 6px 0 10px;
            &:before {
                display: none;
            }
        }
    }
    .panels-container {
        height: 100%;
        display: flex;
        flex-direction: row-reverse;
        position: relative;
        :global(.nav-panel:first-child:nth-last-child(3)) {
            left: -4px;
        }
    }
    .navbar-logo {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 4px 8px;
    }
</style>
