import { Failure, LazyDerived, ObjectUtils } from "engine-utils-ts";
import { BimProperty, type Bim, type BimPropertyData, type PropertiesGroupFormatters, type UnitsMapper } from "..";
import type { NamedBimPropertiesGroup } from "../bimDescriptions/NamedBimPropertiesGroup";
import { extractValueUnitPropsGroup } from "../bimDescriptions/NamedBimPropertiesGroup";
import type { AssetCatalogItemProps, AssetId} from "../catalog";
import { AssetCatalogItemTypeIdentifier, CatalogItem } from "../catalog";
import type { AssetBasedCatalogItemCreators } from "../catalog/CatalogItemCollection";
import { NumberProperty } from "../properties/PrimitiveProps";
import { SolverObjectInstance } from "../runtime/SolverObjectInstance";
import type { CostComponentsNonNullable, CostsConfigProvider, ExpandLegacyPropsWithCostTableLinks } from "src/cost-model/capital";
import { sumCostComponents, type EstimateCost, type InstanceCost, createEmptyCostComponentsNonNullable, mergeCostComponents } from "src/cost-model/capital";
import type { SolverInstancePatchResult } from "src/runtime/ReactiveSolverBase";
import { PUI_ActionsNode, PUI_GroupNode } from "ui-bindings";

export function registerEquipmentPriceSolver<T extends NamedBimPropertiesGroup>(
    type_identifier: string,
    KeyProps: T,
    bim: Bim,
    costs: CostsConfigProvider,
    getDefaultCosts?: (props: T) => CostComponentsNonNullable,
) {
    const global = LazyDerived.new1(
        type_identifier + ' global',
        [bim.unitsMapper],
        [costs.lazyInstanceCostsByType(type_identifier)],
        ([costs]) => {
            return new InstanceCostsGlobal(costs, bim.unitsMapper);
        }
    )
    const PricingSharedArgGlobaIdent = type_identifier + '-pricing-shared-arg-global-ident';
    bim.runtimeGlobals.registerByIdent(PricingSharedArgGlobaIdent, global)

    bim.reactiveRuntimes.registerRuntimeSolver(new SolverObjectInstance({
        solverIdentifier: 'pricing_solver_' + type_identifier,
        objectsDefaultArgs: {
            legacyProps: KeyProps,
        },
        objectsIdentifier: type_identifier,
        globalArgsSelector: {
            [PricingSharedArgGlobaIdent]: InstanceCostsGlobal,
        },
        cache: true,
        solverFunction: (props, globals) => {
            const valueUnitOnlyProps = extractValueUnitPropsGroup(props.legacyProps);
            const shared = globals[PricingSharedArgGlobaIdent];
            if (shared instanceof Failure) {
                return {}
            }
            const result = createEquipmentPricePatch(
                type_identifier,
                { keyProps: valueUnitOnlyProps },
                shared.value,
                bim.keyPropertiesGroupFormatter,
                getDefaultCosts?.(valueUnitOnlyProps) ?? createEmptyCostComponentsNonNullable(),
            );
            return result
        }
    }));
}

export function registerEquipmentCommonAssetToCatalogItem(
    typeIdentifier: string,
    group: AssetBasedCatalogItemCreators
) {
    group.register(
        typeIdentifier,
        (assetId: AssetId) => {
            return new CatalogItem<AssetCatalogItemProps>(
                undefined,
                AssetCatalogItemTypeIdentifier,
                {
                    asset_id: NumberProperty.new({
                        unit: '',
                        value: assetId,
                    }),
                    price_each: NumberProperty.new({
                        unit: 'usd',
                        value: 0,
                    })
                },
                undefined,
            );
        }
    )
}

interface EquipmentPricingArg {
    keyProps: NamedBimPropertiesGroup
}

function createEquipmentPricePatch(
    type_identifier: string,
    instanceRelatedArgs: EquipmentPricingArg,
    commonArgs: InstanceCostsGlobal,
    keyPropsFormatter: PropertiesGroupFormatters,
    defaultCosts: CostComponentsNonNullable,
): SolverInstancePatchResult {
    let hasZeroCosts = false;
    // find matching equipment
    let cost = mergeCostComponents(
        commonArgs.costs.find(x => ObjectUtils.areObjectsEqual(x.props, instanceRelatedArgs.keyProps))?.costs,
        defaultCosts,
    )
    const totalCostPerEach = cost && sumCostComponents(cost) || 0;

    const itemCostUsd = totalCostPerEach
    if (itemCostUsd === 0) {
        hasZeroCosts = true;
    }

    const ref = keyPropsFormatter.formatNamedProps(type_identifier, instanceRelatedArgs.keyProps);

    // compose patch
    const patch: BimPropertyData[] = [
        {
            path: ['cost', 'cost_item'],
            value: ref ?? 'unknown',
            readonly: true,
        },
        {
            path: ['cost', 'cost'],
            value: itemCostUsd,
            unit: 'usd',
            readonly: true,
        },
        {
            path: ['cost', 'total_cost'],
            value: itemCostUsd,
            unit: 'usd',
            readonly: true,
        },
    ];

    return {
        legacyProps: patch,
        removeProps: [
            BimProperty.MergedPath(['cost', 'status']),
            BimProperty.MergedPath(['cost', 'has_zero_costs']),
            BimProperty.MergedPath(['cost', 'asset']),
        ]

    }
}

export class InstanceCostsGlobal {
    readonly costs: Readonly<InstanceCost[]>
    readonly unitsMapper: UnitsMapper
    constructor(
        costs: Readonly<InstanceCost[]>,
        unitsMapper: UnitsMapper,
    ) {
        this.costs = costs;
        this.unitsMapper = unitsMapper;
    }
}

export class EstimateCostGlobal {
    readonly estimate?: Readonly<EstimateCost>
    readonly unitsMapper: UnitsMapper;
    constructor(
        estimate: Readonly<EstimateCost> | undefined,
        unitsMapper: UnitsMapper,
    ) {
        this.estimate = estimate;
        this.unitsMapper = unitsMapper;
    }
}

export const create_expandCommonEquipmentPropsWithCostTableLinks =
    (uniquePropsDef: NamedBimPropertiesGroup): ExpandLegacyPropsWithCostTableLinks =>
    (params) => {
        const sis = Array.from(params.bim.instances.peekByIds(params.ids).values());
        if (!sis.length) {
            return;
        }
        const sampleSi = sis[0];
        const type_identifier = sampleSi.type_identifier;
        if (sis.some(x => x.type_identifier !== type_identifier)) {
            return;
        }
        const costGroup = PUI_GroupNode.tryGetNestedChild(params.pui, ['cost'])
        if (!(costGroup instanceof PUI_GroupNode)) {
            return;
        }
        const sampleProps = sis[0].properties.extractPropertiesGroup(uniquePropsDef, { valueUnitOnly: true });
        for (const si of sis) {
            const props = si.properties.extractPropertiesGroup(uniquePropsDef, { valueUnitOnly: true });
            if (!ObjectUtils.areObjectsEqual(sampleProps, props)) {
                return;
            }
        }
        const action = new PUI_ActionsNode({
            actions: [
                {
                    action: () => params.costModelFocusApi.focusOnCostModel({ si: sis[0] }),
                    style: { icon: 'Cost', compact: true, type: 'secondary' },
                    label: 'Setup costs',
                }
            ],
            context: {},
            name: '',
            typeSortKeyOverride: 100,
        })
        costGroup.addMaybeChild(action);
    }

export const create_expandLegacyPropsWithCostTableLink =
    (tableId: string, type_identifier: string): ExpandLegacyPropsWithCostTableLinks =>
    (params) => {
        const sis = Array.from(params.bim.instances.peekByIds(params.ids).values());
        if (sis.some(x => x.type_identifier !== type_identifier)) {
            return;
        }

        const costs = PUI_GroupNode.tryGetNestedChild(params.pui, ['cost'])
        if (!costs || !(costs instanceof PUI_GroupNode)) {
            return;
        }
        const action = new PUI_ActionsNode({
            actions: [
                {
                    action: () => params.costModelFocusApi.focusOnTable(tableId),
                    style: { icon: 'Cost', compact: true, type: 'secondary' },
                    label: 'Setup costs',
                }
            ],
            context: {},
            name: '',
            typeSortKeyOverride: 100,

        })
        costs.addMaybeChild(action);
    }
