import { Console } from "console";
import { Aabb2 } from "./Aabb2";
import { PolygonUtils } from "./PolygonUtils";
import type { Vector2 } from "./Vector2";


export enum ContoursOrientation {
    ClockwiseInner = 1,
    FirstInner = 0,
    ClockwiseOuter = -1
}

function calculateBbox(points: Vector2[]): Aabb2 {
    let min = points[0].clone(), max = points[0].clone();
    for (let i = 1; i < points.length; ++i) {
        if (points[i].x < min.x) {
            min.x = points[i].x;
        } else if (points[i].x > max.x) {
            max.x = points[i].x;
        }

        if (points[i].y < min.y) {
            min.y = points[i].y;
        } else if (points[i].y > max.y) {
            max.y = points[i].y;
        }
    }

    return new Aabb2(min, max);
}

function isPointInside(p: Vector2, contour: Vector2[]): boolean {
    if (contour.length < 3) {
        return false;
    }

    let k = 0

    const contourLen = contour.length;

    let currentP = contour[0];
    const px = p.x
    const py = p.y
    let u1 = currentP.x - px
    let v1 = currentP.y - py

    for (let ii = 0; ii < contourLen; ii++) {
        const nextP = ii + 1 === contourLen ? contour[0] : contour[ii + 1];

        let f = 0
        let u2 = 0
        let v2 = 0

        v2 = nextP.y - py

        if ((v1 < 0 && v2 < 0) || (v1 > 0 && v2 > 0)) {
            currentP = nextP
            v1 = v2
            u1 = currentP.x - px
            continue
        }

        u2 = nextP.x - p.x

        if (v2 > 0 && v1 <= 0) {
            f = (u1 * v2) - (u2 * v1)
            if (f > 0) k = k + 1
            else if (f === 0) return true;
        } else if (v1 > 0 && v2 <= 0) {
            f = (u1 * v2) - (u2 * v1)
            if (f < 0) k = k + 1
            else if (f === 0) return true;
        } else if (v2 === 0 && v1 < 0) {
            f = (u1 * v2) - (u2 * v1)
            if (f === 0) return true;
        } else if (v1 === 0 && v2 < 0) {
            f = u1 * v2 - u2 * v1
            if (f === 0) return true;
        } else if (v1 === 0 && v2 === 0) {
            if (u2 <= 0 && u1 >= 0) {
                return true;
            } else if (u1 <= 0 && u2 >= 0) {
                return true;
            }
        }
        currentP = nextP
        v1 = v2
        u1 = u2
    }

    return k % 2 === 1;
}

class Polygon2D {
    contour: Vector2[];
    bbox: Aabb2;

    constructor(contour2d: Vector2[]) {
        this.contour = contour2d;
        this.bbox = calculateBbox(contour2d);
    }

    isPointInside(p: Vector2): boolean {
        if (this.bbox.containsPoint(p) && isPointInside(p, this.contour)) {
            return true;
        } else {
            return false;
        }
    }
}

export class PointsInPolygonChecker {
    private static eps = 1e-8;

    includedContours: Polygon2D[] = [];
    excludedContours: Polygon2D[] = [];
    revertLogic: boolean = false;
    
    constructor(
        includedContours: Vector2[][], 
        excludedContours: Vector2[][], 
        revertLogic: boolean = false
    ) {
        this.includedContours = includedContours.map(c => new Polygon2D(c));
        this.excludedContours = excludedContours.map(c => new Polygon2D(c));
        
        this.revertLogic = revertLogic;
    }

    static fromContoursOrientation(contours2d: Vector2[][], contoursOrientation: ContoursOrientation, revertLogic: boolean = false) {
        let clockwiseInner;
        if (contoursOrientation !== ContoursOrientation.FirstInner) {
            clockwiseInner = contoursOrientation === ContoursOrientation.ClockwiseInner;
        }

        const includedContours: Vector2[][] = [];
        const excludedContours: Vector2[][] = [];
        for (let j = 0; j < contours2d.length; ++j) {
            const contourSimplified = PolygonUtils.simplifyContour(contours2d[j], PointsInPolygonChecker.eps);

            if(contourSimplified.length<3){
                console.warn("The contour derived from the imported surface has been excluded due to its insufficient size.");
                continue;
            }

            if (contoursOrientation === ContoursOrientation.FirstInner && j === 0) {
                clockwiseInner = PolygonUtils.isClockwiseInner(contourSimplified);
            }

            if (PolygonUtils.isClockwiseInner(contourSimplified) === clockwiseInner) {
                includedContours.push(contourSimplified);
            } else {
                excludedContours.push(contourSimplified);
            }            
        }
        if(includedContours.length<1){
            throw Error("The outher contour of the surface has been excluded due to its insufficient size.")
        }

        return new PointsInPolygonChecker(includedContours, excludedContours, revertLogic);
    }

    isPointInside(p: Vector2): boolean {
        for (const hole of this.excludedContours) {
            if (hole.isPointInside(p)) {
                return this.revertLogic;
            }
        }

        for (const polygon of this.includedContours) {
            if (polygon.isPointInside(p)) {
                return !this.revertLogic;
            }
        }

        return this.revertLogic;
    }
}