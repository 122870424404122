import type { DC_CNSTS } from 'bim-ts';
import { DefaultMap } from 'engine-utils-ts';
import type { Vector2 } from 'math-ts';
import { DISTANCE_EPSILON } from '../../constants';
import { round, sumReduce } from '../../utils';
import type { Connection } from '../models/connection';
import { Node } from '../models/node';
import type { RotateUtils } from './rotate-utils';

export class Wrapper {

    // amount of equipment on each ortagonal line
    private equipmentCount: DefaultMap<
        DC_CNSTS.ConductorType,
        { x: DefaultMap<number, number>, offset: DefaultMap<number, number> }
    > = new DefaultMap(() => ({
        x: new DefaultMap(() => 0),
        offset: new DefaultMap(() => 0),
    }));

    constructor(
        private readonly root: Node,
        private readonly rotateUtils: RotateUtils,
    ) {}

    private checkIfConnIsValidForWrapping(conn: Connection): boolean {
        const routes = conn.route.getPairingRoutes(true);
        if (routes.find(x => x.points.length !== 3))
            return false;
        for (let i = 0; i < 3; i++) {
            const points = routes.map(x => x.points[i]);
            if (!points.every(x => x.equals(points[0])))
                return false;
        }
        return true;
    }

    private tryWrapConnection(conn: Connection) {
        const type = conn.conductorType;
        const [a, b, c] = conn.route.points;
        const result = this.rotateUtils.findOrtagonalIntermidPoints(a, c);
        const points = [
            result.horizontalThenVertical,
            result.verticalThenHorizontal,
        ];
        const newB =
            points.sort((l, r) => r.distanceTo(b) - l.distanceTo(b))[0];
        const scoreBefore = sumReduce([
            this.getEquipmentCountOnEdge(type, a, b),
            this.getEquipmentCountOnEdge(type, b, c),
        ], x => x);
        const scoreAfter = sumReduce([
            this.getEquipmentCountOnEdge(type, a, newB),
            this.getEquipmentCountOnEdge(type, newB, c),
        ], x => x);
        if (scoreBefore >= scoreAfter) return;
        conn.route.getPairingRoutes(true).map(x => x.points[1] = newB);
    }

    wrap() {
        Node.traverseBranch({ node: this.root, beforeEach: node => {
            for (const conn of node.toChildren.values()) {
                if (!this.checkIfConnIsValidForWrapping(conn)) continue;
                this.tryWrapConnection(conn);
            }
            return false;
        } });
    }

    addNodeStats(node: Node) {
        const pt = node.actualPosition;
        const offset = this.rotateUtils.getOffsetForPoint(pt);
        const anyChild = node.getAnyChildNode();
        if (anyChild && anyChild.toParent) {
            this.addEquipmentToLine(
                anyChild.toParent.conductorType, pt.x, offset,
            );
        }
        if (node.toParent) {
            this.addEquipmentToLine(
                node.toParent.conductorType, pt.x, offset,
            );
        }
    }

    private getEquipmentCountOnEdge(
        type: DC_CNSTS.ConductorType,
        a: Vector2,
        b: Vector2,
    ) {
        if (!this.rotateUtils.arePointsOrtagonal(a, b)) return 0;
        const aOffset = this.rotateUtils.getOffsetForPoint(a);
        return this.rotateUtils.arePointsOnLine(a, b)
            ? this.getEquipmentCountOnLine({ type, offset: aOffset })
            : this.getEquipmentCountOnLine({ type, x: a.x });
    }

    private getEquipmentCountOnLine(params: {
        type: DC_CNSTS.ConductorType,
        x?: number,
        offset?: number,
    }) {
        const perCondType = this.equipmentCount.getOrCreate(params.type);
        if (typeof params.x !== 'undefined') {
            const x = round(params.x, DISTANCE_EPSILON);
            return perCondType.x.getOrCreate(x);
        }
        if (typeof params.offset !== 'undefined') {
            const offset = round(params.offset, DISTANCE_EPSILON);
            return perCondType.offset.getOrCreate(offset);
        }
        throw new Error('No filter set');
    }

    private addEquipmentToLine(
        type: DC_CNSTS.ConductorType,
        x: number,
        offset: number,
    ) {
        offset = round(offset, DISTANCE_EPSILON);
        x = round(x, DISTANCE_EPSILON);
        const perCondType = this.equipmentCount.getOrCreate(type);
        perCondType.x.set(x, perCondType.x.getOrCreate(x) + 1);
        perCondType.offset
            .set(offset, perCondType.offset.getOrCreate(offset) + 1);
    }

}
