import { isValueAndUnit, type RowModel } from "./Models";

export class GroupTypesMap {
    private readonly groups: Set<string>;
    public count: number;
    private constructor(count: number, groups:Set<string>) {
        this.groups = groups;
        this.count = count;
    }

    public static init(rows: RowModel[], groupFields: string[], isPivotMode: boolean) {
        const allGroups = new Set<string>();
        const pivotGroups = new Set<string>();
        for (const row of rows) {
            const route: string[] = [];
            for (let i = 0; i < groupFields.length; i++) {
                const field = groupFields[i];
                const value = row[field];
                let valueStr: string;
                if(value === undefined){
                    valueStr = 'undefined';
                } else if(isValueAndUnit(value)) {
                    valueStr = `${value.value}` + (value.unit ? ` ${value.unit}` : '');
                } else {
                    valueStr = `${value}`;
                }
                route.push(valueStr);
                const mergedKey = makeKey(route);
                allGroups.add(mergedKey);
                if (i < groupFields.length - 1) {
                    pivotGroups.add(mergedKey);
                }
            }
        }

        const count = isPivotMode ? allGroups.size : allGroups.size + rows.length;
        const groupsSet = isPivotMode ? pivotGroups : allGroups;
        return new GroupTypesMap(count, groupsSet);
    }

    public has(route: string[]) {
        const key = makeKey(route);
        return this.groups.delete(key);
    }
}

function makeKey(route: string[]){
    return route.join('|');
}