import type { Vector2 } from 'math-ts';

export function hashObjectByValuesSerialization(obj: Record<string, unknown>) {
    return Object.entries(obj)
        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
        .map(x => x[1])
        .join(',');
}

export function sumReduce<T>(
    array: T[],
    extractSumComponent: (x: T) => number,
) {
    return array.reduce((acc, cur) => acc + extractSumComponent(cur), 0);
}

/**
 * @example
 * round(12.3125, 0.2) // 12.2
 *
 * @example
 * round(14.3925, 0.2) // 12.2
 */
export function round(
    from: number,
    divider: number,
) {
    let result = Math.round(from / divider) * divider;
    // remove epsilon
    result = Number.parseFloat(result.toFixed(5));
    return result;
}

export function findPolylineLength(points: Vector2[]) {
    if (points.length < 2) {
        return 0;
    }
    let length = 0;
    for (let i = 1; i < points.length; i++) {
        const prev = points[i-1];
        const cur = points[i];
        length += prev.distanceTo(cur);
    }
    return length;
}
