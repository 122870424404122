import { getContext, setContext } from 'svelte';
import { BindedStore } from '../../utils';

class ContextMenuSettings {
    closeOnClickOutside: boolean = true;
}

export class ContextMenuSettingsStore extends BindedStore<ContextMenuSettings> {
    constructor() {
        super(new ContextMenuSettings);
    }

    disableAutoClose() {
        this.update((settings) => {
            settings.closeOnClickOutside = false;
            return settings;
        });
    }
}

export function setSettings() {
    setContext("contextMenuSettings", new ContextMenuSettingsStore());
}

export function getContextMenuSettings() {
    return getContext<ContextMenuSettingsStore>('contextMenuSettings');
}