<script lang="ts">
    export let value: boolean | null;
    export let readonly: boolean = false;
    export let onChange: (value: boolean) => void = () => {};
</script>

<svg
    class:switch-holder={!readonly}
    class:readonly
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    on:click={() => {
        if (!readonly) {
            onChange(!value);
        }
    }}
>
    <path
        d="M6.32503 14.325C4.56808 14.325 3.07467 13.7096 1.8448 12.479C0.614933 11.2483 0 9.7539 0 7.9958C0 6.2377 0.614933 4.74469 1.8448 3.51678C3.07467 2.28888 4.56808 1.67493 6.32503 1.67493H13.675C15.4319 1.67493 16.9253 2.29027 18.1552 3.52095C19.3851 4.75162 20 6.246 20 8.0041C20 9.7622 19.3851 11.2552 18.1552 12.4831C16.9253 13.711 15.4319 14.325 13.675 14.325H6.32503ZM6.32503 12.5H13.675C14.925 12.5 15.9875 12.0625 16.8625 11.1875C17.7375 10.3125 18.175 9.24995 18.175 7.99995C18.175 6.74995 17.7375 5.68745 16.8625 4.81245C15.9875 3.93745 14.925 3.49995 13.675 3.49995H6.32503C5.07503 3.49995 4.01252 3.93745 3.13752 4.81245C2.26252 5.68745 1.82502 6.74995 1.82502 7.99995C1.82502 9.24995 2.26252 10.3125 3.13752 11.1875C4.01252 12.0625 5.07503 12.5 6.32503 12.5Z"
        fill="#101314"
        fill-opacity="0.69"
    />
    {#if value}
        <path
            d="M6.32501 12.5H13.675C14.925 12.5 15.9875 12.0625 16.8625 11.1875C17.7375 10.3125 18.175 9.25 18.175 8C18.175 6.75 17.7375 5.6875 16.8625 4.8125C15.9875 3.9375 14.925 3.5 13.675 3.5H6.32501C5.07501 3.5 4.01251 3.9375 3.13751 4.8125C2.26251 5.6875 1.82501 6.75 1.82501 8C1.82501 9.25 2.26251 10.3125 3.13751 11.1875C4.01251 12.0625 5.07501 12.5 6.32501 12.5Z"
            fill="white"
        />
    {/if}
    {#if value}
        <path
            d="M13.6935 10.525C14.3945 10.525 14.9908 10.2796 15.4825 9.78895C15.9741 9.29827 16.22 8.70244 16.22 8.00145C16.22 7.30047 15.9746 6.70414 15.484 6.21248C14.9933 5.72081 14.3974 5.47498 13.6965 5.47498C12.9955 5.47498 12.3991 5.72032 11.9075 6.211C11.4158 6.70168 11.17 7.29752 11.17 7.9985C11.17 8.69948 11.4153 9.29581 11.906 9.78748C12.3967 10.2791 12.9925 10.525 13.6935 10.525Z"
            fill={readonly ? "#101314" : "#9C27B0"}
        />
    {:else}
        <path
            d="M6.30355 10.525C7.00454 10.525 7.60086 10.2796 8.09253 9.78895C8.5842 9.29827 8.83003 8.70244 8.83003 8.00145C8.83003 7.30047 8.58469 6.70414 8.094 6.21248C7.60332 5.72081 7.00749 5.47498 6.3065 5.47498C5.60552 5.47498 5.0092 5.72032 4.51753 6.211C4.02586 6.70168 3.78003 7.29752 3.78003 7.9985C3.78003 8.69948 4.02537 9.29581 4.51605 9.78748C5.00674 10.2791 5.60257 10.525 6.30355 10.525Z"
            fill="#101314"
            fill-opacity="0.69"
        />
    {/if}
</svg>

<style>
    .switch-holder {
        cursor: pointer;
    }
    .readonly {
        opacity: 0.38;
    }
</style>
