// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { PropertyNumberRangeT } from './property-number-range.js';
import { PropertyNumberRange } from './property-number-range.js';


export class NumberPropertyWithOptions implements flatbuffers.IUnpackableObject<NumberPropertyWithOptionsT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):NumberPropertyWithOptions {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsNumberPropertyWithOptions(bb:flatbuffers.ByteBuffer, obj?:NumberPropertyWithOptions):NumberPropertyWithOptions {
  return (obj || new NumberPropertyWithOptions()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsNumberPropertyWithOptions(bb:flatbuffers.ByteBuffer, obj?:NumberPropertyWithOptions):NumberPropertyWithOptions {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new NumberPropertyWithOptions()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

step():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

range(obj?:PropertyNumberRange):PropertyNumberRange|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new PropertyNumberRange()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

unit():string|null
unit(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
unit(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

selectedOption():string|null
selectedOption(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
selectedOption(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

options(index: number):string
options(index: number,optionalEncoding:flatbuffers.Encoding):string|Uint8Array
options(index: number,optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
}

optionsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startNumberPropertyWithOptions(builder:flatbuffers.Builder) {
  builder.startObject(6);
}

static addValue(builder:flatbuffers.Builder, value:number) {
  builder.addFieldFloat64(0, value, 0.0);
}

static addStep(builder:flatbuffers.Builder, step:number) {
  builder.addFieldFloat64(1, step, 0.0);
}

static addRange(builder:flatbuffers.Builder, rangeOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, rangeOffset, 0);
}

static addUnit(builder:flatbuffers.Builder, unitOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, unitOffset, 0);
}

static addSelectedOption(builder:flatbuffers.Builder, selectedOptionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, selectedOptionOffset, 0);
}

static addOptions(builder:flatbuffers.Builder, optionsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, optionsOffset, 0);
}

static createOptionsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startOptionsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endNumberPropertyWithOptions(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): NumberPropertyWithOptionsT {
  return new NumberPropertyWithOptionsT(
    this.value(),
    this.step(),
    (this.range() !== null ? this.range()!.unpack() : null),
    this.unit(),
    this.selectedOption(),
    this.bb!.createScalarList<string>(this.options.bind(this), this.optionsLength())
  );
}


unpackTo(_o: NumberPropertyWithOptionsT): void {
  _o.value = this.value();
  _o.step = this.step();
  _o.range = (this.range() !== null ? this.range()!.unpack() : null);
  _o.unit = this.unit();
  _o.selectedOption = this.selectedOption();
  _o.options = this.bb!.createScalarList<string>(this.options.bind(this), this.optionsLength());
}
}

export class NumberPropertyWithOptionsT implements flatbuffers.IGeneratedObject {
constructor(
  public value: number = 0.0,
  public step: number = 0.0,
  public range: PropertyNumberRangeT|null = null,
  public unit: string|Uint8Array|null = null,
  public selectedOption: string|Uint8Array|null = null,
  public options: (string)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const range = (this.range !== null ? this.range!.pack(builder) : 0);
  const unit = (this.unit !== null ? builder.createString(this.unit!) : 0);
  const selectedOption = (this.selectedOption !== null ? builder.createString(this.selectedOption!) : 0);
  const options = NumberPropertyWithOptions.createOptionsVector(builder, builder.createObjectOffsetList(this.options));

  NumberPropertyWithOptions.startNumberPropertyWithOptions(builder);
  NumberPropertyWithOptions.addValue(builder, this.value);
  NumberPropertyWithOptions.addStep(builder, this.step);
  NumberPropertyWithOptions.addRange(builder, range);
  NumberPropertyWithOptions.addUnit(builder, unit);
  NumberPropertyWithOptions.addSelectedOption(builder, selectedOption);
  NumberPropertyWithOptions.addOptions(builder, options);

  return NumberPropertyWithOptions.endNumberPropertyWithOptions(builder);
}
}
