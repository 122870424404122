<script lang="ts">
    import { MDCTooltip } from "@material/tooltip";
    import { onMount } from "svelte";

    export let id: string;

    let tooltip: HTMLDivElement;
    let offsetTop: number = 0;

    let anchorElem: HTMLDivElement;
    let scrollableContainer: Element | null;

    onMount(() => {
        const mdcTooltip = new MDCTooltip(tooltip);
        mdcTooltip.setHideDelay(0);
        mdcTooltip.setShowDelay(0);
        scrollableContainer = anchorElem.offsetParent;
    });

    function onHover() {
        offsetTop = scrollableContainer?.scrollTop ?? 0;
    }
</script>

<div class="mdc-tooltip-wrapper--rich" bind:this={anchorElem}>
    <div aria-describedby={id} on:mouseenter={onHover}>
        <slot name="anchor" />
    </div>
    <div class="tooltip-container" style="--offset-top:{-offsetTop}px">
        <div
            bind:this={tooltip}
            {id}
            class="mdc-tooltip mdc-tooltip--rich"
            aria-hidden="true"
            role="tooltip"
        >
            <div class="mdc-tooltip__surface mdc-tooltip__surface-animation">
                <div class="mdc-tooltip__content">
                    <div class="mdc-typography--body1 text-main-medium">
                        <slot name="content" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<style lang="scss">
    .mdc-tooltip__surface {
        padding: 4px;
    }
    .mdc-tooltip.mdc-tooltip--rich {
        position: fixed;
        top: auto !important;
        left: auto !important;
    }
    .tooltip-container {
        position: relative;
        top: var(--offset-top);
    }
    .mdc-tooltip__content :global(br) {
        display: block;
        content: "";
        margin-top: 8px;
    }
</style>
