<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.50001 4C2.94842 4 4.46471 3.89049 6.0781 3.77398C7.91227 3.64152 9.87193 3.5 12 3.5C14.1281 3.5 16.0877 3.64155 17.9219 3.77404C19.5353 3.89058 21.0516 4.0001 22.5 4.0001V6.00019L19.405 6.00019L18.2652 17.3981C18.0607 19.4429 16.34 21.0001 14.285 21.0001H9.71496C7.65996 21.0001 5.93929 19.4429 5.73481 17.3981L4.59501 6.00019H1.5L1.50001 4ZM11.25 17.0001C11.25 17.6905 10.6904 18.2501 10 18.2501C9.30964 18.2501 8.75 17.6905 8.75 17.0001C8.75 16.3098 9.30964 15.7501 10 15.7501C10.6904 15.7501 11.25 16.3098 11.25 17.0001ZM10 13.2502C10.6904 13.2502 11.25 12.6905 11.25 12.0002C11.25 11.3098 10.6904 10.7502 10 10.7502C9.30964 10.7502 8.75 11.3098 8.75 12.0002C8.75 12.6905 9.30964 13.2502 10 13.2502ZM11.25 7.00019C11.25 7.69054 10.6904 8.25018 10 8.25018C9.30964 8.25018 8.75 7.69054 8.75 7.00019C8.75 6.30984 9.30964 5.75019 10 5.75019C10.6904 5.75019 11.25 6.30984 11.25 7.00019ZM14 18.2501C14.6904 18.2501 15.25 17.6905 15.25 17.0001C15.25 16.3098 14.6904 15.7501 14 15.7501C13.3096 15.7501 12.75 16.3098 12.75 17.0001C12.75 17.6905 13.3096 18.2501 14 18.2501ZM15.25 12.0002C15.25 12.6905 14.6904 13.2502 14 13.2502C13.3096 13.2502 12.75 12.6905 12.75 12.0002C12.75 11.3098 13.3096 10.7502 14 10.7502C14.6904 10.7502 15.25 11.3098 15.25 12.0002ZM14 8.25018C14.6904 8.25018 15.25 7.69054 15.25 7.00019C15.25 6.30984 14.6904 5.75019 14 5.75019C13.3096 5.75019 12.75 6.30984 12.75 7.00019C12.75 7.69054 13.3096 8.25018 14 8.25018Z"
        fill="currentColor"
    />
</svg>
