import RTree from "rtree";


function bisect_left(
    data: number[],
    value: number
): number {
    let lo = 0, hi = data.length;
    while (lo < hi) {
        const mid = Math.floor((lo + hi) / 2);
        if (data[mid] < value) {
            lo = mid + 1;
        } else {
            hi = mid;
        }
    }
    return lo;
}


function find_rank(
    x_min: number,
    x_max: number,
    segments: { x_min: number, x_max: number, rank: number }[]
): number {
    if (segments.length === 0) { return 0; }
    const points = [...new Set<number>(
        segments.map(s => [s.x_min, s.x_max]).flat())].sort((a, b) => a - b);
    if (points[0] > x_min || points[points.length - 1] < x_max) { return 0; }
    const records = Array(points.length - 1).fill(-1);
    for (let s of segments) {
        const beg = bisect_left(points, s.x_min);
        const end = bisect_left(points, s.x_max);
        for (let i = beg; i < end; ++i) {
            records[i] = Math.max(records[i], s.rank);
        }
    }
    return Math.min(...records) + 1;
}


export interface Box {
    x: number;
    y: number;
    w: number;
    h: number;
}


function find_r2l_ranks(
    boxes: Box[],
    gap: number,
    offset: number
): number[] {
    const tree = RTree()
    for (const [i, box] of boxes.entries()) {
        tree.insert(box, i);
    }
    const indices = [...Array(boxes.length).keys()];
    indices.sort((i, j) => boxes[j].x - boxes[i].x);
    const result: number[] = Array(indices.length).fill(0);
    for (let i of indices) {
        const box = boxes[i];
        const subset: number[] = tree.search({
            x: box.x + box.w, y: box.y - offset,
            w: gap, h: box.h + 2 * offset
        });
        const segments: { x_min: number, x_max: number, rank: number }[] = [];
        for (let j of subset) {
            const other = boxes[j];
            if (other.x < box.x + box.w) { continue; }
            const x_min = Math.max(box.y, other.y - offset);
            const x_max = Math.min(box.y + box.h,
                other.y + other.h + 2 * offset);
            if (x_min < x_max) {
                segments.push({ x_min, x_max, rank: result[j] });
            }
        }
        result[i] = find_rank(box.y, box.y + box.h, segments);
    }
    return result;
}


function find_l2r_ranks(
    boxes: Box[],
    gap: number,
    offset: number
): number[] {
    return find_r2l_ranks(boxes.map(b => {
        return { x: -(b.x + b.w), y: b.y, w: b.w, h: b.h };
    }), gap, offset);
}


function find_t2b_ranks(
    boxes: Box[],
    gap: number,
    offset: number
): number[] {
    return find_r2l_ranks(boxes.map(b => {
        return { x: b.y, y: b.x, w: b.h, h: b.w };
    }), gap, offset);
}


function find_b2t_ranks(
    boxes: Box[],
    gap: number,
    offset: number
): number[] {
    return find_r2l_ranks(boxes.map(b => {
        return { x: -(b.y + b.h), y: b.x, w: b.h, h: b.w };
    }), gap, offset);
}


export enum Label {
    ExteriorLeft,
    ExteriorRight,
    Exterior,
    EdgeBottom,
    EdgeTop,
    Edge,
    Interior
}


function classify(
    boxes: Box[],
    gap_x: number,
    offset_x: number,
    gap_y: number,
    offset_y: number
): Label[] {
    const result: Label[] = Array(boxes.length).fill(Label.Interior);
    const l2r = find_l2r_ranks(boxes, gap_x, offset_y);
    const r2l = find_r2l_ranks(boxes, gap_x, offset_y);
    const indices: number[] = [];
    for (let i = 0; i < boxes.length; ++i) {
        if (l2r[i] <= 1) {
            result[i] = r2l[i] <= 1 ? Label.Exterior : Label.ExteriorLeft;
        } else if (r2l[i] <= 1) {
            result[i] = Label.ExteriorRight;
        } else {
            indices.push(i);
        }
    }
    const subset = indices.map(i => boxes[i]);
    const b2t = find_b2t_ranks(subset, gap_y, offset_x);
    const t2b = find_t2b_ranks(subset, gap_y, offset_x);
    for (const [i, index] of indices.entries()) {
        if (b2t[i] === 0) {
            result[index] = t2b[i] === 0 ? Label.Edge : Label.EdgeBottom;
        } else if (t2b[i] === 0) {
            result[index] = Label.EdgeTop;
        }
    }
    return result;
}


export interface Point {
    x: number;
    y: number;
}


function group_segments(
    segments: { fst: Point, snd: Point }[],
    n: number = 90
): number[][] {
    const step = Math.PI / (2 * n);
    const result: number[][] = Array(2 * n).fill(null).map(() => []);
    for (let [i, { fst, snd }] of segments.entries()) {
        if (fst.y > snd.y) {
            [fst, snd] = [snd, fst];
        }
        let j = Math.round(Math.atan2(snd.y - fst.y, snd.x - fst.x) / step);
        if (j === 2 * n) {
            j = 0;
        }
        result[j].push(i);
    }
    return result;
}


function rotate(p: Point, cos: number, sin: number): Point {
    return { x: cos * p.x - sin * p.y, y: sin * p.x + cos * p.y };
}


export interface Tracker {
    fst: Point;
    snd: Point;
    w: number;
}


export function solve(
    trackers: Tracker[],
    gap_x: number,
    offset_x: number,
    gap_y: number,
    offset_y: number
): Label[] {
    const groups = group_segments(trackers);
    const result: Label[] = Array(trackers.length).fill(Label.Interior);
    for (const [i, group] of groups.entries()) {
        if (group.length === 0) { continue; }
        const angle = i * Math.PI / groups.length;
        const cos = Math.cos(angle), sin = Math.sin(angle);
        const boxes: Box[] = [];
        for (const j of group) {
            const tracker = trackers[j];
            const fst = rotate(tracker.fst, sin, cos);
            const snd = rotate(tracker.snd, sin, cos);
            const x = (fst.x + snd.x) / 2 - tracker.w / 2;
            const y = Math.min(fst.y, snd.y);
            boxes.push({ x, y, w: tracker.w, h: Math.max(fst.y, snd.y) - y });
        }
        const labels = classify(boxes, gap_x, offset_x, gap_y, offset_y);
        for (const [j, label] of labels.entries()) { result[group[j]] = label; }
    }
    return result;
}