import type { AssetId, CatalogItemId } from "../catalog";
import type { IdBimScene } from "../scene/SceneInstances";
import type { PrimitivePropertyBaseParams } from "./PrimitiveProps";
import { PrimitivePropertyBase } from "./PrimitiveProps";

export interface ObjectsPropertyBaseParams<T> extends PrimitivePropertyBaseParams<T>{
    maxCount?: number;
    types: string[];
}
export class ObjectsPropertyBase<T> extends PrimitivePropertyBase<T> {

    readonly types: string[];
    readonly maxCount: number;

    constructor(args: ObjectsPropertyBaseParams<T>) {
        super(args);
        this.types = args.types;
        this.maxCount = args.maxCount ?? 0;
    }

    withDifferentValue(value: T): ObjectsPropertyBase<T>{
        return new ObjectsPropertyBase({
            ...this,
            value,
        });
    };

}

export class SceneInstancesProperty extends ObjectsPropertyBase<IdBimScene[]> {

    withDifferentValue(value: IdBimScene[]): SceneInstancesProperty {
        return new SceneInstancesProperty({
            ...this,
            value
        })
    }
}

export class AssetsProperty extends ObjectsPropertyBase<AssetId[]> {

    withDifferentValue(value: AssetId[]): AssetsProperty {
        return new AssetsProperty({
            ...this,
            value
        })
    }
}

export interface CatalogItemPropertyValue {
    id: CatalogItemId,
    type: 'catalog_item',
}

export interface AssetPropertyValue {
    id: AssetId,
    type: 'asset',
}

export type CatalogPropertyValue = CatalogItemPropertyValue | AssetPropertyValue;

export interface CatalogItemsReferencePropertyParams extends ObjectsPropertyBaseParams<CatalogPropertyValue[]> {
    assetsTypes?: string[];
    defaultValues?: CatalogPropertyValue[];
}

export class CatalogItemsReferenceProperty extends ObjectsPropertyBase<CatalogPropertyValue[]> {
    readonly assetsTypes: string[] | undefined;
    readonly defaultValues: CatalogPropertyValue[] | undefined;

    constructor(args: CatalogItemsReferencePropertyParams) {
        super(args);
        this.assetsTypes = args.assetsTypes ?? undefined;
        this.defaultValues = args.defaultValues ?? undefined;
    }

    withDifferentValue(value: CatalogPropertyValue[]): CatalogItemsReferenceProperty {
        return new CatalogItemsReferenceProperty({
            ...this,
            value,
            assetsTypes: this.assetsTypes
        });
    }
}

