// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { Points2DT } from './points2-d.js';
import { Points2D } from './points2-d.js';


export class ExtrudedPolygon implements flatbuffers.IUnpackableObject<ExtrudedPolygonT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ExtrudedPolygon {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsExtrudedPolygon(bb:flatbuffers.ByteBuffer, obj?:ExtrudedPolygon):ExtrudedPolygon {
  return (obj || new ExtrudedPolygon()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsExtrudedPolygon(bb:flatbuffers.ByteBuffer, obj?:ExtrudedPolygon):ExtrudedPolygon {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ExtrudedPolygon()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

outer(obj?:Points2D):Points2D|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new Points2D()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

holes(index: number, obj?:Points2D):Points2D|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new Points2D()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

holesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

baseElevation():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

topElevation():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

static startExtrudedPolygon(builder:flatbuffers.Builder) {
  builder.startObject(4);
}

static addOuter(builder:flatbuffers.Builder, outerOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, outerOffset, 0);
}

static addHoles(builder:flatbuffers.Builder, holesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, holesOffset, 0);
}

static createHolesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startHolesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addBaseElevation(builder:flatbuffers.Builder, baseElevation:number) {
  builder.addFieldFloat64(2, baseElevation, 0.0);
}

static addTopElevation(builder:flatbuffers.Builder, topElevation:number) {
  builder.addFieldFloat64(3, topElevation, 0.0);
}

static endExtrudedPolygon(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createExtrudedPolygon(builder:flatbuffers.Builder, outerOffset:flatbuffers.Offset, holesOffset:flatbuffers.Offset, baseElevation:number, topElevation:number):flatbuffers.Offset {
  ExtrudedPolygon.startExtrudedPolygon(builder);
  ExtrudedPolygon.addOuter(builder, outerOffset);
  ExtrudedPolygon.addHoles(builder, holesOffset);
  ExtrudedPolygon.addBaseElevation(builder, baseElevation);
  ExtrudedPolygon.addTopElevation(builder, topElevation);
  return ExtrudedPolygon.endExtrudedPolygon(builder);
}

unpack(): ExtrudedPolygonT {
  return new ExtrudedPolygonT(
    (this.outer() !== null ? this.outer()!.unpack() : null),
    this.bb!.createObjList<Points2D, Points2DT>(this.holes.bind(this), this.holesLength()),
    this.baseElevation(),
    this.topElevation()
  );
}


unpackTo(_o: ExtrudedPolygonT): void {
  _o.outer = (this.outer() !== null ? this.outer()!.unpack() : null);
  _o.holes = this.bb!.createObjList<Points2D, Points2DT>(this.holes.bind(this), this.holesLength());
  _o.baseElevation = this.baseElevation();
  _o.topElevation = this.topElevation();
}
}

export class ExtrudedPolygonT implements flatbuffers.IGeneratedObject {
constructor(
  public outer: Points2DT|null = null,
  public holes: (Points2DT)[] = [],
  public baseElevation: number = 0.0,
  public topElevation: number = 0.0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const outer = (this.outer !== null ? this.outer!.pack(builder) : 0);
  const holes = ExtrudedPolygon.createHolesVector(builder, builder.createObjectOffsetList(this.holes));

  return ExtrudedPolygon.createExtrudedPolygon(builder,
    outer,
    holes,
    this.baseElevation,
    this.topElevation
  );
}
}
