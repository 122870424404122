import type { RadioButtonOption } from "src/libui/radio-buttons";
import { RadioButton } from 'src/libui/radio-buttons';

import type { PUI_PropertyNodeNumber } from "ui-bindings";

export class PowerModeType extends RadioButton {
    inputValue: PUI_PropertyNodeNumber | undefined

    constructor(args:{
        value: string | number,
        label: string,
        disabled?: boolean,
        option?: RadioButtonOption
        inputValue?: PUI_PropertyNodeNumber,
    }) {
        super(args.value, args.label, args.disabled, args.option);
        this.inputValue = args.inputValue;
    }
}