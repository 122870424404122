import { TerrainInstanceTypeIdent, type Bim } from "bim-ts";
import { LazyVersioned, Success, type Yield } from "engine-utils-ts";
import { type ExportedFileDescription, type FileExporter, type FileExporterContext } from "ui-bindings";
import { CommonExportSettings, getAvailableSegmentSizes, getExportProjectName, getPropsForExportOptions, selectExportResolution } from "../CommonExportSettings";
import { convertDem } from "./ConvertTerrain";
import { ProjectInfo, VerdataSyncerType } from "..";


export class DemExporter implements FileExporter<CommonExportSettings> {

    constructor(
        readonly bim: Bim,
        readonly projectInfo: LazyVersioned<ProjectInfo | undefined>,
        readonly projectVerdataSyncer: VerdataSyncerType,
    ) {
    }

    initialSettings() {
        return {defaultValue: new CommonExportSettings()};
    }

    *startExport(settings: CommonExportSettings, context: FileExporterContext): Generator<Yield, ExportedFileDescription[]> {
        const prefix = getExportProjectName(this.projectVerdataSyncer, this.projectInfo.poll());
        
        let terrains = this.bim.instances.peekByTypeIdent(TerrainInstanceTypeIdent);
        if (settings.export_only_selected) {
            const selectedIds = new Set(this.bim.instances.getSelected());
            terrains = terrains.filter(t => selectedIds.has(t[0]));
        }

        const { maxSegmentSize, totalArea } = getPropsForExportOptions(terrains, this.bim);
        const options = getAvailableSegmentSizes(maxSegmentSize, totalArea, 6);
        const resolutionParam = yield * selectExportResolution(context, options, "grid_spacing", maxSegmentSize);

        const files = yield* convertDem(this.bim, context.logger, settings, resolutionParam, prefix);

        const exportedFilesDescriptions: ExportedFileDescription[] = [];
        for (const fileResult of files) {
            if (fileResult instanceof Success) {
                exportedFilesDescriptions.push(fileResult.value)
            } else {
                //todo: notifications
                context.logger.error(fileResult.errorMsg())
            }
        }

        return exportedFilesDescriptions;
    }
}