
import type { UniformsFlat } from '../composer/DynamicUniforms';
import type { EngineMaterialId } from '../pools/EngineMaterialId';
import { ShaderFlags } from '../shaders/ShaderFlags';
import type { EngineSubmeshDescription, RenderJobUpdater } from '../scene/Submeshes2';
import type { EngineFullGraphicsSettings } from '../GraphicsSettingsFull';

export class StdRenderJobUpdater implements RenderJobUpdater{

    updaterRenderJob(
        submeshDescription: Readonly<EngineSubmeshDescription>,
        renderSettings: Readonly<EngineFullGraphicsSettings>,
        output: {
            flags: ShaderFlags,
            materialId: EngineMaterialId,
            uniforms: UniformsFlat,
        }
    ) {
		if (submeshDescription.subObjectRef.isHidden) {
			return;
		}
        let materialId = submeshDescription.localDescr.materialId;
        let materialFlags: ShaderFlags = ShaderFlags.None;
        output.materialId = materialId;
        output.flags = materialFlags;
    }
}

export const SharedStdRenderJobUpdater = new StdRenderJobUpdater();
