import type { BimGeometries, BimPatch, SceneInstance, SparseFlaggedSets } from "bim-ts";
import { applyFlagsPatch, SceneObjDiff } from "bim-ts";
import type { LazyVersioned, ScopedLogger, TasksRunner } from "engine-utils-ts";
import { HandleIndexMask } from "../memory/Handle";
import type { Submeshes2 } from "../scene/Submeshes2";
import type { ESOsCollection, ESOHandle } from "../scene/ESOsCollection";
import { ESO_Diff } from "./ESO_Diff";
import type { AllEngineGeometries } from "../geometries/AllEngineGeometries";
import type { ESO} from "./ESO";
import { BimFlagsObjDiff, ESO_AnotationFlags } from "./ESO";
import type { ESSO} from "./ESSO";
import { SubObjectsReconciler } from "./ESSO";
import { ESSO_Diff } from "./ESSO_Diff";
import type { Matrix4, Vector3 } from "math-ts";
import type { EngineImages } from "../materials/EngineImages";
import type { EngineStdMaterials } from "../materials/EngineStdMaterials";
import type { GeometryCtor, GeometryEditsHandlerAny } from "../scene/GeometryEditsHandler";
import { ECollPatched } from "../scene/EngineCollection";
import type { InObjFullId} from "../scene/EngineSceneIds";
import { InObjIdType } from "../scene/EngineSceneIds";
import type { EngineFullGraphicsSettings } from "../GraphicsSettingsFull";
import type { AnnotationsCalculatorBase } from "../annotations/AnnotationsCalculatorBase";
import type { AnnotationRepr } from "../annotations/AnnotationsCalculatorBase";

export interface ESOsHandlerInputs {
	logger: ScopedLogger,
	geometries: AllEngineGeometries,
	bimGeos: BimGeometries,
	images: EngineImages,
	materials: EngineStdMaterials,
	submeshes: Submeshes2,
	geometriesEditHandlers: Map<GeometryCtor, GeometryEditsHandlerAny>,
	tasksRunner: TasksRunner,
	graphicsSettings: LazyVersioned<EngineFullGraphicsSettings>,
}

export abstract class ESOsHandlerBase<O extends ESO> {

	readonly identifier: string;
	readonly logger: ScopedLogger;
	readonly geometries: AllEngineGeometries;
	readonly bimGeos: BimGeometries;
	readonly images: EngineImages;
	readonly materials: EngineStdMaterials;
	readonly submeshes: Submeshes2;
	readonly geometriesEditHandlers: Map<GeometryCtor, GeometryEditsHandlerAny>;
	readonly graphicsSettings: LazyVersioned<EngineFullGraphicsSettings>;
	readonly annotationsCalculator: AnnotationsCalculatorBase | undefined;

	readonly _dirtyObjects = new Map<ESOHandle, ESO_Diff>();
	readonly _allObjectsHandled = new Set<ESOHandle>();

	_relevantBimUpdatesFlags: SceneObjDiff =
		SceneObjDiff.Hidden | SceneObjDiff.Selected | SceneObjDiff.Highlighted
		| SceneObjDiff.WorldPosition | SceneObjDiff.ColorTint
		| SceneObjDiff.RepresentationAnalytical | SceneObjDiff.Representation | SceneObjDiff.GeometryReferenced;

	_annotationsMask: ESO_AnotationFlags = ESO_AnotationFlags.None;


	constructor(
		identifier: string,
		args: ESOsHandlerInputs,
		annotationsCalculator: AnnotationsCalculatorBase | undefined = undefined
	) {
		this.identifier = identifier;
		this.logger = args.logger.newScope(identifier);
		this.geometries = args.geometries;
		this.bimGeos = args.bimGeos;
		this.images = args.images;
		this.materials = args.materials;
		this.submeshes = args.submeshes;
		this.geometriesEditHandlers = args.geometriesEditHandlers;
		this.graphicsSettings = args.graphicsSettings;
		this.annotationsCalculator = annotationsCalculator;
	}

	dispose() {
	}

    isSynced() {
        return this._dirtyObjects.size === 0;
    }

	abstract esosTypesToHandle(): Iterable<{ new(...args: any[]): O; }>;
	abstract tryCreateESO(instance: SceneInstance): O | undefined;


	markDirty(handle: ESOHandle, flags: ESO_Diff) {
		this._dirtyObjects.set(handle, (this._dirtyObjects.get(handle) as number) | flags);
	}

	onAllocated(handles: Iterable<ESOHandle>) {
		for (const h of handles) {
			this._allObjectsHandled.add(h);
			this._dirtyObjects.set(h, ESO_Diff.All);
		}
	}

	onDeleted(handles: ESOHandle[]) {
		for (const h of handles) {
			this._allObjectsHandled.delete(h);
			this._dirtyObjects.delete(h);
		}
	}

	applySelfImposedUpdates(_coll: ESOsCollection) {
	}

	onAnnotationsUpdates(
		coll: Readonly<ESOsCollection>,
		patches: [ESOHandle, ESO_AnotationFlags][],
		sparseFlags: SparseFlaggedSets<ESOHandle, ESO_AnotationFlags>,
	) {
		for (const [h, flagsPatch] of patches) {
			const obj = coll.peek(h);
			if (obj === undefined) {
				continue;
			}
			const currFlags = obj.annotationFlags;
			const newFlags = applyFlagsPatch(currFlags, flagsPatch) & this._annotationsMask;
			let dirtyFlags = ESO_Diff.None;
			if (currFlags !== newFlags) {
				(obj as ESO).annotationFlags = newFlags;
				sparseFlags.updateSceneInstanceFlagsFor(h, obj.annotationFlags);

				const currentFlagsChanged = (currFlags ^ newFlags);
				if (currentFlagsChanged & ESO_AnotationFlags.IsInEdit) {
					dirtyFlags |= ESO_Diff.RepresentationOverlaySoft;
					dirtyFlags |= ESO_Diff.IsEditable;
					dirtyFlags |= ESO_Diff.ForceReprRecheck;
				}
				this.markDirty(h, dirtyFlags);
			}
		}
	}

	applyBimUpdates(
		coll: Readonly<ESOsCollection>,
		diffs: [ESOHandle, SceneObjDiff][],
	) {
		const objects = coll.objsPerHandleIndex;
		const relevantBimupdates = this._relevantBimUpdatesFlags;
		for (const [handle, diff] of diffs) {
			if ((diff & relevantBimupdates) === 0) {
				continue;
			}
			const obj = objects[handle & HandleIndexMask];
			if (!obj) {
				continue;
			}
			const appliedDiff = this.applyBimDiffToESO(obj as O, diff, handle);
			if (appliedDiff) {
				this.markDirty(handle, appliedDiff);
			}
		}
	}

	applyBimDiffToESO(obj: O, diff: SceneObjDiff, handle: ESOHandle): ESO_Diff {
		let appliedDiff: ESO_Diff = ESO_Diff.None;
		if (diff & (BimFlagsObjDiff)) {
			obj.bimFlags = obj.sceneInstanceRef.flags;
			appliedDiff |= (ESO_Diff.RepresentationOverlaySoft | ESO_Diff.RepresentationSoft);
		}
		if (diff & SceneObjDiff.ColorTint) {
			obj.colorTint = obj.sceneInstanceRef.colorTint;
			appliedDiff |= ESO_Diff.ColorTint;
		}
		if (diff & SceneObjDiff.WorldPosition) {
			appliedDiff |= ESO_Diff.Position;
		}
		if (diff & (SceneObjDiff.Representation | SceneObjDiff.GeometryReferenced | SceneObjDiff.RepresentationAnalytical)) {
			appliedDiff |= ESO_Diff.RepresentationBreaking;
		}
		return appliedDiff;
	}

	propogateDirtyFlagsInside() {
		// this hook should be used by objects that have group behaviour
		// for instance road objects tringulation may influence each other
		// if they are close enough
	}

	reconcileSubObjectsAndNotify(engineSceneObjects: ESOsCollection): void {

		if (this._dirtyObjects.size) {
			this.propogateDirtyFlagsInside();
			const dirtyObjects = Array.from(this._dirtyObjects);
			this._dirtyObjects.clear();

			const subObjectsToForceRebuild: InObjFullId[] = [];
			const stdSubmeshesToRealloc: [ESOHandle, O][] = [];

			for (const [h, dirty] of dirtyObjects) {
				const obj = engineSceneObjects.peek(h) as O | undefined;
				if (obj === undefined) {
					continue;
				}
				if (dirty & ESO_Diff.RepresentationBreaking) {
					stdSubmeshesToRealloc.push([h, obj]);
					for (const ch of engineSceneObjects.subObjects.getChildrenOf(h)) {
						if (ch.id.inObjId.ty === InObjIdType.ObjSelf || ch.id.inObjId.ty === InObjIdType.ObjSelfAux) {
							subObjectsToForceRebuild.push(ch.id);
						}
					}
				} else if (dirty & ESO_Diff.ForceReprRecheck) {
					stdSubmeshesToRealloc.push([h, obj]);
				}
			}

			if (stdSubmeshesToRealloc.length) {
				const reconciler = new SubObjectsReconciler();
				const subObjectsPerHandle = this.createSubObjectsFor(stdSubmeshesToRealloc);
				for (const [handle, newSubobjects] of subObjectsPerHandle) {
					const prevSubOjects = engineSceneObjects.subObjects.getChildrenOf(handle);
					reconciler.reconcileSubobjectsAndRenderMeshes(
						prevSubOjects,
						newSubobjects
					);
					// subobjectsPerHanlde.set(handle, newSubobjects);
				}
				reconciler.apply(engineSceneObjects.subObjects);
				// for (const [handle, newSubobjects] of subobjectsPerHanlde) {
				// 	const subobjects = engineSceneObjects.subObjects.getChildrenOf(handle);
				// 	if (!ObjectUtils.areObjectsEqual(new Set(subobjects.map(s => s.id)), new Set(newSubobjects.map(s => s.id)))) {
				// 		console.error('reconcilation error', handle, newSubobjects, subobjects);
				// 	}
				// }
			}

			engineSceneObjects.subObjects.updatesStream.pushNext(
				new ECollPatched(subObjectsToForceRebuild.map(id => [
					engineSceneObjects.subObjects.idsToHandles.get(id)!, ESSO_Diff.RepresentationBreaking
				]))
			);

			engineSceneObjects.updatesStream.pushNext(new ECollPatched(dirtyObjects));
		}
	}

	abstract createSubObjectsFor(
		objectsToRealloc: [ESOHandle, O][],
	): Iterable<[ESOHandle, ESSO<any>[]]>;

	getAnnotation(handle: ESOHandle, eso: O): AnnotationRepr | undefined {
		const annotation = this.annotationsCalculator?.currentAnnotations.get(handle);
		return annotation;
	}

	patchSubObjectsWorldMatrices(
		coll: Readonly<ESOsCollection>,
		patches: [ESOHandle, O, [InObjFullId, Matrix4][]][],
		outputPatch: BimPatch,
	): void {
		this.logger.error(`pos patches not implmemented for ${this.identifier}`);
	}

	cloneInteractiveSubObjects(
		coll: Readonly<ESOsCollection>,
		ids: [ESOHandle, O, InObjFullId[]][],
		worldSpaceDirectionOfClone: Vector3,
		outputPatch: BimPatch,
	): {newObjects: InObjFullId[], toUseForGesture: InObjFullId[] }  {
		this.logger.error(`pos patches not implmemented for ${this.identifier}`);
		return {newObjects: [], toUseForGesture: []};
	}

	deleteInteractiveSubObjects(
		coll: Readonly<ESOsCollection>,
		ids: [ESOHandle, O, InObjFullId[]][],
		outputPatch: BimPatch,
	): void  {
		this.logger.error(`delete not implmemented for ${this.identifier}`);
	}
	// abstract applySubObjectsPatches(
	// 	patches: []
	// ) {

	// }
}
