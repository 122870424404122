import type {
    AssetCatalogItemProps,
    AssetId,
    Bim, Catalog, IdBimScene, MathSolversApi, MvWiringConfig, PropertyGroup, SceneInstance
} from 'bim-ts';
import { GaugePack, MvWiringConfigType
} from 'bim-ts';
import type { ScopedLogger, TasksRunner,
} from 'engine-utils-ts';
import {
    Immer,
    LazyBasic,
    LazyDerived, ObjectUtils,
} from 'engine-utils-ts';

import type { AcWiringConfig} from './WiringService';
import {
    AcWiringSolver, deleteWiresByParents, placeCablesOnFarmLayout
} from './WiringService';
import { type UiBindings, PUI_ConfigBasedBuilderParams, NotificationDescription, NotificationType, type PUI_Builder, type PUI_Node, GroupedNotificationGenerator, PanelViewPosition } from 'ui-bindings';
import { notificationSource } from '../Notifications';
import type { Context, GlobalContext } from '../panels-config-ui/GeneratePanelUiBindings';
import { createLazyUiConfig, getAssets } from '../panels-config-ui/GeneratePanelUiBindings';
import type { CableDef } from '../trenches/TrenchService';
import { setDefaultAssets } from '../panels-config-ui/DefaultAssets';
import type { VerDataSyncer } from 'verdata-ts';
import { addProperty } from '../panels-config-ui/PropertyBuilders';
import { removeDeletedSceneInstances } from '../panels-config-ui/RemoveDeletedSceneInstances';

export function createMvWiringUi(
    ui: UiBindings,
    builderSettings: PUI_ConfigBasedBuilderParams,
    mathSolverApi: MathSolversApi,
    tasksRunner: TasksRunner,
    logger: ScopedLogger,
    bim: Bim,
    cablesCollection: [number, CableDef][],
    catalog: Catalog,
    vds: VerDataSyncer
) {
    const configType = MvWiringConfigType;

    const isGeneratingLayoutLazy = new LazyBasic<boolean>('is-layout-generating', false);
    const generateFn = async (context:Context) => {
        const selectedConfig = bim.configs.peekById(context.propertyId);
        const gaugePack = new GaugePack(catalog, 'mv-wire-spec');
        const notificationGroup = new GroupedNotificationGenerator('Generating Wirings');

        if (selectedConfig) {
            const config = selectedConfig.get<MvWiringConfig>();
            const collections = getAssets(catalog);
            const cabinetId = config.equipment.sectionalize_cabinet.value[0];
            let cabinetInstance:SceneInstance|undefined = cabinetId.type === 'catalog_item' 
                ? collections.perCatalogItems.get(cabinetId.id)
                : collections.perAsset.get(cabinetId.id);

            if(!cabinetInstance){
                ui.addNotification(
                    notificationGroup.addNotification(
                        NotificationDescription.newBasic({
                            type: NotificationType.Error,
                            source: notificationSource,
                            key: 'assetNotFound',
                            descriptionArg: config.equipment.sectionalize_cabinet.value[0].toString(),
                            removeAfterMs: 5_000,
                            addToNotificationsLog: true
                        })
                    )
                );
                return;
            }
            const wiresIds:AssetId[] = [];
            for (const wire of config.mv_wires.wires.value) {
                if(wire.type === 'asset'){
                    wiresIds.push(wire.id);
                }else {
                    const item = catalog.catalogItems.peekById(wire.id);
                    const assetId = item?.as<AssetCatalogItemProps>().properties?.asset_id?.value;
                    if(assetId){
                        wiresIds.push(assetId);
                    }
                }
            }
            // let algo: AcWiringAlgorithm = AcWiringAlgorithm.acRouter;
            // if(config.settings.algorithm.value === 'grid'){
            //     algo = AcWiringAlgorithm.acWiringGrid;
            // } else if(config.settings.algorithm.value === 'router'){
            //     algo = AcWiringAlgorithm.acRouter;
            // } else if(config.settings.algorithm.value === 'router-new'){
            //     algo = AcWiringAlgorithm.acRouterNew;
            // } else {
            //     throw new Error('unrecognized wiring algorithm : ' + config.settings.algorithm.value);
            // }
            const wire_cost = 1;
            const trench_cost = wire_cost * (config.settings.bias.value / wire_cost);
            const input: AcWiringConfig = {
                generateLvWires: false,
                boundariesIds:config.layout.boundaries.value,
                substationIds:[selectedConfig.connectedTo as IdBimScene],
                cabinetInstance,
                wiresIds: wiresIds,
                temperature: config.mv_wires.temperature,
                maxPowerMvCircuitVA: config.settings.max_power_mv_circuit.as("V*A"),
                acWiringSolver: AcWiringSolver.acRouterNew,
                maxVoltageDropPercent: config.settings.max_voltage_drop.as('%'),
                lowVoltageCables: cablesCollection,
                aboveGround: config.mv_wires.above_ground.value,
                trench_cost: trench_cost,
                wire_cost: wire_cost,
            };
            isGeneratingLayoutLazy.replaceWith(true);
            await placeCablesOnFarmLayout(
                ObjectUtils.deepCloneObj(input), bim, mathSolverApi, tasksRunner, ui, gaugePack, notificationGroup
            ).finally(()=>{
                isGeneratingLayoutLazy.replaceWith(false);
            });
            //logger.batchedInfo('input', input);
        }
    };
    function isGenerationAvailable(context:Context, globalContext: GlobalContext){
        return LazyDerived.new1("mv-wiring-poller", [globalContext.configObserver], [isGeneratingLayoutLazy],  ([isGeneratingLayout]) => {
            const selectedConfig = bim.configs.peekById(context.propertyId);

            if (selectedConfig) {
                const config = selectedConfig.get<MvWiringConfig>();
                const check = (!isGeneratingLayout &&
                    selectedConfig.connectedTo !== 0 &&
                    config.equipment.sectionalize_cabinet.value.length > 0 &&
                    config.mv_wires.wires.value.length > 0
                );
                return check;
            }
            return false;
        });
    }

    function puiCallback(builder: PUI_Builder, _root: PUI_Node, context: Context, globalContext: GlobalContext){
        const config = context.previousConfig as MvWiringConfig;

        if(ObjectUtils.isObjectEmpty(config)){
            return;
        }
        
        builder.addActionsNode({
            name: "removeWiresAction",
            context: undefined,
            typeSortKeyOverride: 998,
            actions: [
                {
                    label: "remove wires",
                    action: () => {
                        const config = bim.configs.peekById(context.propertyId);
                        if(config){
                            deleteWiresByParents([config.connectedTo], bim, ["wire", "trench", "sectionalizing-cabinet"]);
                        }
                    },
                    style: {
                        type: 'outlined'
                    },
                }
            ]
        });
        let sortKey = 0;
        const getNextSortKey = () => ++sortKey;
        const patchConfig = (props: PropertyGroup) => {
            bim.configs.applyPatches([
                [context.propertyId, { properties: props }],
            ]);
        }
        const addProp = (path:string[], name?: string, enableSelectAll?: boolean) => {
            addProperty({logger, config, patch: patchConfig, builder, path, sortKey: getNextSortKey(), name, enableSelectAll});
        }

        const inGroup = (name: string, inGroupCode: () => void) => {
            builder.inGroup({
                name,
                collapsible: false,
                showTitle: true,
            }, () => {
                inGroupCode();
            });
        }

        inGroup('Equipment', () => {
            addProp(['equipment', 'sectionalize_cabinet']);
        });

        inGroup('Layout', () => {
            builder.addSceneInstancesSelectorProp({
                name: 'Exclude boundaries',
                value: config.layout.boundaries.value.map(value => ({value})),
                onChange: (v) => {
                    const props = Immer.produce(config, draft => {
                        draft.layout.boundaries = draft.layout.boundaries.withDifferentValue(v.map(v => v.value));
                    });
                    patchConfig(props);
                },
                types: config.layout.boundaries.types,
                typeSortKeyOverride: getNextSortKey(),
                maxSelect: config.layout.boundaries.maxCount,
                filterItems: (id) => {
                    const inst = bim.instances.peekById(id);
                    const boundaryType = inst?.properties.get("boundary | boundary_type")?.asText();
                    const source_type = inst?.properties.get("boundary | source_type")?.asText();
                    return boundaryType === 'include' || source_type === 'equipment';
                }
            })
        });

        inGroup('Settings', () => {
            // addProp(['settings', 'algorithm']);
            addProp(['settings', 'max_voltage_drop']);
            addProp(['settings', 'max_power_mv_circuit']);

            builder.addSliderProp({
                name: 'Bias',
                value: config.settings.bias.value,
                minMax: config.settings.bias.range ?? [0.1, 10],
                step: 1,
                onChange: (v) => {
                    const props = Immer.produce(config, draft => {
                        draft.settings.bias = draft.settings.bias.withDifferentValue(v);
                    });
                    patchConfig(props);
                },
                typeSortKeyOverride: getNextSortKey(),
                settings: {
                    minValueLabel: 'Less wires',
                    maxValueLabel: 'Less trenches',
                },
                description: BiasHelpText
            });
        });
        
        inGroup('MV wires', () => {
            addProp(['mv_wires', 'above_ground']);
            addProp(['mv_wires', 'temperature']);
            addProp(['mv_wires', 'wires'], undefined, true);
        });
        
        builder.addActionsNode({
            name: "generateAction",
            context: undefined,
            typeSortKeyOverride: 999,
            actions: [
                {
                    label: "generate",
                    hint: "click for generate",
                    isEnabled: isGenerationAvailable(context, globalContext),
                    action: ()=>{generateFn(context)},
                    style: {
                        type: 'primary'
                    },
                }
            ]
        });

        let updatedConfig = config;
        removeDeletedSceneInstances(config, bim, logger, [], (newConfig) => {
            updatedConfig = newConfig;
        });
        setDefaultAssets(updatedConfig, catalog, logger, context, [], (newConfig) => {
            updatedConfig = newConfig;
        }, true);
        patchConfig(updatedConfig);
    };

    const builderParams = PUI_ConfigBasedBuilderParams.new(
        [],
        ['settings', 'equipment', 'layout', 'mv_wires']
    ).mergedWith(builderSettings);

    ui.addViewToNavbar(
        ["Generate", "MV Wiring"],
        createLazyUiConfig({ configBuilderParams: builderParams, onAfterRootNodeCallback: puiCallback, bim, logger, type: configType, ui, tasksRunner, catalog, vds }),
        {
            name: 'Medium voltage',
            iconName: 'Ac',
            group: 'General',
            sortOrder: 4,
            position: PanelViewPosition.Fixed
        }
    );
}

const BiasHelpText = `
    In a rich network of trenches wires length from the each
    inverter to the substation is minimised, reducing wires
    cost. Wires length increase as the trenches network is
    being optimised and number of trenches reduced.<br/>
    By default PVFarm generates wires and trenches networks
    with an economically reasonable balance. Adjust the
    slider if you feel the default balance is suboptimal for
    your project.
`;
