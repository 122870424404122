import type { LazyVersioned} from 'engine-utils-ts';
import type { Matrix4} from 'math-ts';
import { Plane, Vector3 } from 'math-ts';

import type { IdEngineObject, InteractiveObjectsActive, InteractiveSceneObjects } from '../scene/InteractiveSceneObjects';
import type { SceneInt } from '../scene/SceneRaycaster';
import { GroundIntersection } from '../scene/SceneRaycaster';
import { EditObjectIntersection, InteractiveObjectIntersection } from '../scene/Raycasts';
import { MouseButton } from './InputController';
import type { MouseClickConsumer, MouseDragConsumer, MouseDragInfo, MouseEventData, MouseGestureConsumer
} from './MouseGesturesBase';
import {
    GesturesButtons
} from './MouseGesturesBase';
import type { KrCamera } from './MovementControls';
import type { SnappingSettings } from '../SnappingSettings';
import { KeyModifiersFlags } from 'ui-bindings';

export class InteractiveEntitiesClickSelector implements MouseGestureConsumer {

    readonly clickConsumers: MouseClickConsumer[] = [];
    readonly dragConsumers: MouseDragConsumer<any>[] = [];

    constructor(
        readonly objects: InteractiveObjectsActive,
		readonly snappingSettings: LazyVersioned<SnappingSettings>,
    ) {

        const sceneRaycastTypeguard =
            (int: SceneInt) => int instanceof InteractiveObjectIntersection
                || int instanceof GroundIntersection
                || int === null;

		const getIdsFromIntersection = (int: SceneInt) => {
			if (int instanceof InteractiveObjectIntersection) {
				return objects.getIdsFromIntersection(int);
			}
			return [];
		}

		this.clickConsumers.push({
			buttons: GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.None),
			sceneRaycastTypeguard,
			clickHandler: (int) => {
				this.objects.setSelected(getIdsFromIntersection(int));
			},
		});

		this.clickConsumers.push({
			buttons: GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.Ctrl),
			sceneRaycastTypeguard,
			clickHandler: (int) => {
				this.objects.toggleSelected(true, getIdsFromIntersection(int));
			},
		});

		this.clickConsumers.push({
			buttons: GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.Shift),
			sceneRaycastTypeguard,
			clickHandler: (int) => {
				this.objects.toggleSelected(false, getIdsFromIntersection(int));
			},
		});

		this.clickConsumers.push({
			buttons: GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.Alt),
			sceneRaycastTypeguard,
			clickHandler: (int) => {
				this.objects.setSelected(
					getEntitiesHierarchyFromRayacast(int, objects)
				);
			},
		});

		this.clickConsumers.push({
			buttons: GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.Alt | KeyModifiersFlags.Ctrl),
			sceneRaycastTypeguard,
			clickHandler: (int) => {
				this.objects.toggleSelected(
					true,
					getEntitiesHierarchyFromRayacast(int, objects)
				);
			},
		});

		this.clickConsumers.push({
			buttons: GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.Alt | KeyModifiersFlags.Shift),
			sceneRaycastTypeguard,
			clickHandler: (int) => {
				this.objects.toggleSelected(
					false,
					getEntitiesHierarchyFromRayacast(int, objects)
				);
			},
		});

		this.clickConsumers.push({
			buttons: GesturesButtons.newShared(MouseButton.Right, KeyModifiersFlags.None),
			sceneRaycastTypeguard,
			clickHandler: (int, me: MouseEventData) => {
				this.objects.setSelected(getIdsFromIntersection(int));
			},
		});

		this.dragConsumers.push(
			new EditControlsMouseDragConsumer(
				objects,
				GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.None),
				this.snappingSettings
			),
			new EditControlsMouseDragConsumer(
				objects,
				GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.Ctrl),
				this.snappingSettings
			),
		)

    }

    onHover (int: SceneInt | null): {cursorStyleToSet: string} | null {
        if (int instanceof InteractiveObjectIntersection) {
			const ids = this.objects.getIdsFromIntersection(int);
            this.objects.setHighlighted(ids);
            return {cursorStyleToSet: 'pointer'};
        } else {
            this.objects.setHighlighted([]);
        }
        return null;
    }


    isEnabled(): boolean {
        return true;
    }
}

interface EntitiesDragState {
    readonly dragPlane: Plane;
    readonly intersStartWS: Vector3;
	readonly startMatrices: Map<IdEngineObject, Matrix4>;
}

class EditControlsMouseDragConsumer implements MouseDragConsumer<EntitiesDragState> {

    constructor(
        readonly interactiveEntities: InteractiveSceneObjects<IdEngineObject, InteractiveObjectIntersection>,
        readonly buttons: GesturesButtons,
		readonly snapSettings: LazyVersioned<SnappingSettings>,
    ) {
        this.buttons = buttons;
	}
	lockPointerOnDrag?: ((s: EntitiesDragState) => boolean) | undefined;

    onButtonDown(sceneInt: SceneInt, me: MouseEventData): boolean {
        return true;
    }

    tryStartDrag(
        med: MouseEventData, mouseDragInfo: MouseDragInfo
    ): EntitiesDragState | null
    {
        const intersPoint = mouseDragInfo.sceneInt?.point;
        if (!intersPoint) {
            return null;
        }
		let ids: IdEngineObject[] = [];
		if (mouseDragInfo.sceneInt instanceof InteractiveObjectIntersection) {
			ids = this.interactiveEntities.getIdsFromIntersection(mouseDragInfo.sceneInt);
		}
		if (ids.length === 0) {
			return null;
		}

        const normal = new Vector3().setFromMatrixColumn(med.camera.matrixWorld, 2);
        const plane = Plane.allocateZero().setFromNormalAndCoplanarPoint(normal, intersPoint);

        const objects = this.interactiveEntities;

        let idsToUse: IdEngineObject[];

        if (this.buttons.modifierKeys === KeyModifiersFlags.Ctrl) {
            const cloned = objects.cloneObjects(ids, mouseDragInfo.worldSpaceDirection);
            idsToUse = cloned.newObjects;
        } else {
			idsToUse = objects.getSelected();
			if (!idsToUse.includes(ids[0])) {
				idsToUse = ids;
			}
        }

        if (idsToUse.length === 0) {
            return null;
        }
        objects.setSelected(idsToUse);

        const startMatrices = objects.getWorldMatricesOf(idsToUse);

        return {
            dragPlane:plane,
            intersStartWS: intersPoint,
            startMatrices,
        }
    }
	handleDrag(dragState: EntitiesDragState, me: MouseEventData, camera: KrCamera): boolean {

        const newIntersPoint = me.mouseCone?.raySection.ray.intersectPlane_t(dragState.dragPlane, new Vector3());
        if (!newIntersPoint) {
            return false;
        }
		const pointToSnapTo = dragState.startMatrices.size === 1 && this.interactiveEntities.findPointToSnap(
			newIntersPoint,
			new Set(dragState.startMatrices.keys()),
			camera, this.snapSettings.poll()
		);
		if (pointToSnapTo) {
			newIntersPoint.copy(pointToSnapTo);
		}
        const intersectionsDiff = newIntersPoint.clone().sub(dragState.intersStartWS);
		const transformsReplacement = new Map<IdEngineObject, Matrix4>();

        for (let [id, tr] of dragState.startMatrices) {
            tr = tr.clone();
            tr.addToPosition(intersectionsDiff);
            transformsReplacement.set(id, tr);
        }

        this.interactiveEntities.patchWorldMatrices(transformsReplacement, {});
		return true;
	}

    cursorDragStyle() {
        return 'grabbing'
    }
    sceneRaycastTypeguard(closest: SceneInt): boolean {
        return closest instanceof EditObjectIntersection;
    }

	onButtonUp(s: EntitiesDragState, med: MouseEventData): void {
	}

	stop(): void {
    }

}



function getEntitiesHierarchyFromRayacast(int: SceneInt, objects: InteractiveObjectsActive): IdEngineObject[] {
	const ids = objects.getIdsFromIntersection(int);
    return objects.gatherIdsWithSubtreesOf({ids, sortParentFirst: false});
}
