import { AuthHubStore } from './AuthHub';
import type { IAuthHub } from './AuthHub';
import type { UserAuthData } from './types';
import { BindedStore } from '../utils';

const mockUser: UserAuthData = {
    id: '68a735b1-961b-4b5c-9016-db82c23b1fb0',
    email: 'testemail@gmail.com',
    name: 'Full Name',
    isAdmin: true,
    company: 'mock-company',
}

export class MockedAuthHub extends BindedStore<AuthHubStore> implements IAuthHub {
    
    constructor() {
        super(new AuthHubStore());
        this.update(s => (s.authData = mockUser, s));
    }
    onLogOut(_callback: () => void): void {
    }
    async getAuthToken(): Promise<string | null> {
        return 'Bearer mock';
    }
}
