import { CostModel as CM } from "bim-ts";
import { ColDef } from "ag-grid-enterprise";
import {
    getContext,
    commonCellClasses,
    commonFontColor,
    defaultTextColor,
    commonFontSize,
} from "./common";
import { defaultCellStyle } from "src/cost-model/capital/table/column-definitions/common";

export const createCostSourceColumnDefinition =
    (): ColDef<CM.FlattenedCostCategoryParams> => ({
        colId: CM.CostSourceColId,
        headerName: CM.CostSourceColId,
        pinned: "left",
        valueGetter: (params) => {
            const { category } = getContext(params);
            if (!category?.source) {
                return;
            }
            return category.source.title;
        },

        //editable: (params) => {
        //    const context = getContext(params);
        //    if (!context) {
        //        return false;
        //    }
        //    if (!canEditCommonCell(context, context?.category?.costSource)) {
        //        return false
        //    }
        //    return !!context.category?.costSource?.update;
        //},

        //valueSetter: (params: ValueSetterParams<CM.FlattenedCostCategoryParams, string>) => {
        //    const context = getContext(params);
        //    if (typeof params.newValue !== 'string') {
        //        return false;
        //    }
        //    const newIndex = context.category?.costSource?.options?.findIndex(x => params.newValue === x)
        //    if (newIndex === undefined) {
        //        return false;
        //    }
        //    context.category?.costSource?.update?.(newIndex);
        //    //if (context.category?.costSource) {
        //    //    context.category.costSource.index = newIndex;
        //    //}
        //    return true;
        //},

        cellClass: (params) =>
            commonCellClasses(getContext(params), (x) => x.source),

        cellStyle: (params) => {
            const styles = {
                ...defaultCellStyle,
                color: defaultTextColor,
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
            };
            commonFontColor(styles, getContext(params));
            return styles;
        },

        minWidth: 180,
        maxWidth: 180,

        //cellEditor: CostSourceEditor,
    });
