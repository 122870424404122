
let wasm;

const heap = new Array(32).fill(undefined);

heap.push(undefined, null, true, false);

function getObject(idx) { return heap[idx]; }

let heap_next = heap.length;

function dropObject(idx) {
    if (idx < 36) return;
    heap[idx] = heap_next;
    heap_next = idx;
}

function takeObject(idx) {
    const ret = getObject(idx);
    dropObject(idx);
    return ret;
}

const cachedTextDecoder = new TextDecoder('utf-8', { ignoreBOM: true, fatal: true });

cachedTextDecoder.decode();

let cachegetUint8Memory0 = null;
function getUint8Memory0() {
    if (cachegetUint8Memory0 === null || cachegetUint8Memory0.buffer !== wasm.memory.buffer) {
        cachegetUint8Memory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachegetUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
    return cachedTextDecoder.decode(getUint8Memory0().slice(ptr, ptr + len));
}

function addHeapObject(obj) {
    if (heap_next === heap.length) heap.push(heap.length + 1);
    const idx = heap_next;
    heap_next = heap[idx];

    heap[idx] = obj;
    return idx;
}

let cachegetInt32Memory0 = null;
function getInt32Memory0() {
    if (cachegetInt32Memory0 === null || cachegetInt32Memory0.buffer !== wasm.memory.buffer) {
        cachegetInt32Memory0 = new Int32Array(wasm.memory.buffer);
    }
    return cachegetInt32Memory0;
}
/**
* @returns {string}
*/
export function engine_version() {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.engine_version(retptr);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        return getStringFromWasm0(r0, r1);
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
        wasm.__wbindgen_free(r0, r1);
    }
}

/**
*/
export function run() {
    wasm.run();
}

function _assertClass(instance, klass) {
    if (!(instance instanceof klass)) {
        throw new Error(`expected instance of ${klass.name}`);
    }
    return instance.ptr;
}

let WASM_VECTOR_LEN = 0;

const cachedTextEncoder = new TextEncoder('utf-8');

const encodeString = function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
};

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length);
        getUint8Memory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len);

    const mem = getUint8Memory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3);
        const view = getUint8Memory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}
/**
*/
export class BoundsScene {

    static __wrap(ptr) {
        const obj = Object.create(BoundsScene.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_boundsscene_free(ptr);
    }
    /**
    */
    constructor() {
        const ret = wasm.boundsscene_new();
        return BoundsScene.__wrap(ret);
    }
    /**
    * @param {number} index
    */
    mark_dirty(index) {
        wasm.boundsscene_mark_dirty(this.ptr, index);
    }
    /**
    */
    clear_objects() {
        wasm.boundsscene_clear_objects(this.ptr);
    }
    /**
    */
    update() {
        wasm.boundsscene_update(this.ptr);
    }
    /**
    */
    stats() {
        wasm.boundsscene_stats(this.ptr);
    }
    /**
    * @param {number} cull_size_normalized
    * @param {number} near
    * @param {number} far
    * @param {number} frustum_matrix_x0
    * @param {number} frustum_matrix_x1
    * @param {number} frustum_matrix_x2
    * @param {number} frustum_matrix_x3
    * @param {number} frustum_matrix_y0
    * @param {number} frustum_matrix_y1
    * @param {number} frustum_matrix_y2
    * @param {number} frustum_matrix_y3
    * @param {number} frustum_matrix_z0
    * @param {number} frustum_matrix_z1
    * @param {number} frustum_matrix_z2
    * @param {number} frustum_matrix_z3
    * @param {number} frustum_matrix_w0
    * @param {number} frustum_matrix_w1
    * @param {number} frustum_matrix_w2
    * @param {number} frustum_matrix_w3
    * @returns {ProgressiveCullListWasm}
    */
    new_list(cull_size_normalized, near, far, frustum_matrix_x0, frustum_matrix_x1, frustum_matrix_x2, frustum_matrix_x3, frustum_matrix_y0, frustum_matrix_y1, frustum_matrix_y2, frustum_matrix_y3, frustum_matrix_z0, frustum_matrix_z1, frustum_matrix_z2, frustum_matrix_z3, frustum_matrix_w0, frustum_matrix_w1, frustum_matrix_w2, frustum_matrix_w3) {
        const ret = wasm.boundsscene_new_list(this.ptr, cull_size_normalized, near, far, frustum_matrix_x0, frustum_matrix_x1, frustum_matrix_x2, frustum_matrix_x3, frustum_matrix_y0, frustum_matrix_y1, frustum_matrix_y2, frustum_matrix_y3, frustum_matrix_z0, frustum_matrix_z1, frustum_matrix_z2, frustum_matrix_z3, frustum_matrix_w0, frustum_matrix_w1, frustum_matrix_w2, frustum_matrix_w3);
        return ProgressiveCullListWasm.__wrap(ret);
    }
    /**
    * @param {number} near
    * @param {number} far
    * @param {number} frustum_matrix_x0
    * @param {number} frustum_matrix_x1
    * @param {number} frustum_matrix_x2
    * @param {number} frustum_matrix_x3
    * @param {number} frustum_matrix_y0
    * @param {number} frustum_matrix_y1
    * @param {number} frustum_matrix_y2
    * @param {number} frustum_matrix_y3
    * @param {number} frustum_matrix_z0
    * @param {number} frustum_matrix_z1
    * @param {number} frustum_matrix_z2
    * @param {number} frustum_matrix_z3
    * @param {number} frustum_matrix_w0
    * @param {number} frustum_matrix_w1
    * @param {number} frustum_matrix_w2
    * @param {number} frustum_matrix_w3
    * @returns {Array<any>}
    */
    intersect_frustum(near, far, frustum_matrix_x0, frustum_matrix_x1, frustum_matrix_x2, frustum_matrix_x3, frustum_matrix_y0, frustum_matrix_y1, frustum_matrix_y2, frustum_matrix_y3, frustum_matrix_z0, frustum_matrix_z1, frustum_matrix_z2, frustum_matrix_z3, frustum_matrix_w0, frustum_matrix_w1, frustum_matrix_w2, frustum_matrix_w3) {
        const ret = wasm.boundsscene_intersect_frustum(this.ptr, near, far, frustum_matrix_x0, frustum_matrix_x1, frustum_matrix_x2, frustum_matrix_x3, frustum_matrix_y0, frustum_matrix_y1, frustum_matrix_y2, frustum_matrix_y3, frustum_matrix_z0, frustum_matrix_z1, frustum_matrix_z2, frustum_matrix_z3, frustum_matrix_w0, frustum_matrix_w1, frustum_matrix_w2, frustum_matrix_w3);
        return takeObject(ret);
    }
    /**
    * @param {number} minx
    * @param {number} miny
    * @param {number} minz
    * @param {number} maxx
    * @param {number} maxy
    * @param {number} maxz
    * @returns {Uint32Array}
    */
    udpate_clipbox_positions(minx, miny, minz, maxx, maxy, maxz) {
        const ret = wasm.boundsscene_udpate_clipbox_positions(this.ptr, minx, miny, minz, maxx, maxy, maxz);
        return takeObject(ret);
    }
    /**
    * @param {number} ray_x
    * @param {number} ray_y
    * @param {number} ray_z
    * @param {number} ray_dir_x
    * @param {number} ray_dir_y
    * @param {number} ray_dir_z
    * @returns {Float64Array}
    */
    raycast(ray_x, ray_y, ray_z, ray_dir_x, ray_dir_y, ray_dir_z) {
        const ret = wasm.boundsscene_raycast(this.ptr, ray_x, ray_y, ray_z, ray_dir_x, ray_dir_y, ray_dir_z);
        return takeObject(ret);
    }
    /**
    * @param {number} point_x
    * @param {number} point_y
    * @param {number} point_z
    * @param {number} max_distance
    * @returns {Float64Array}
    */
    find_close_to_point(point_x, point_y, point_z, max_distance) {
        const ret = wasm.boundsscene_find_close_to_point(this.ptr, point_x, point_y, point_z, max_distance);
        return takeObject(ret);
    }
    /**
    * @param {number} ray_x
    * @param {number} ray_y
    * @param {number} ray_z
    * @param {number} ray_dir_x
    * @param {number} ray_dir_y
    * @param {number} ray_dir_z
    * @param {number} cone_radius_at_0
    * @param {number} cone_radius_at_1
    * @returns {Float64Array}
    */
    cone_cast(ray_x, ray_y, ray_z, ray_dir_x, ray_dir_y, ray_dir_z, cone_radius_at_0, cone_radius_at_1) {
        const ret = wasm.boundsscene_cone_cast(this.ptr, ray_x, ray_y, ray_z, ray_dir_x, ray_dir_y, ray_dir_z, cone_radius_at_0, cone_radius_at_1);
        return takeObject(ret);
    }
    /**
    * @returns {Float64Array}
    */
    get_total_bounds() {
        const ret = wasm.boundsscene_get_total_bounds(this.ptr);
        return takeObject(ret);
    }
    /**
    * @param {number} new_size
    * @returns {Float64Array}
    */
    reallocate_bounds_buffer(new_size) {
        const ret = wasm.boundsscene_reallocate_bounds_buffer(this.ptr, new_size);
        return takeObject(ret);
    }
    /**
    * @param {number} new_size
    * @returns {Uint8Array}
    */
    reallocate_clipbox_positions_buffer(new_size) {
        const ret = wasm.boundsscene_reallocate_clipbox_positions_buffer(this.ptr, new_size);
        return takeObject(ret);
    }
}
/**
*/
export class ByteBuffer {

    static __wrap(ptr) {
        const obj = Object.create(ByteBuffer.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bytebuffer_free(ptr);
    }
    /**
    * @param {number} byte_size
    */
    constructor(byte_size) {
        const ret = wasm.bytebuffer_new(byte_size);
        return ByteBuffer.__wrap(ret);
    }
    /**
    * @returns {number}
    */
    pointer() {
        const ret = wasm.bytebuffer_pointer(this.ptr);
        return ret >>> 0;
    }
    /**
    * @returns {number}
    */
    byte_size() {
        const ret = wasm.bytebuffer_byte_size(this.ptr);
        return ret >>> 0;
    }
    /**
    * @returns {Uint32Array}
    */
    u32_view() {
        const ret = wasm.bytebuffer_u32_view(this.ptr);
        return takeObject(ret);
    }
    /**
    * @returns {number}
    */
    used_portion_byte_length() {
        const ret = wasm.bytebuffer_used_portion_byte_length(this.ptr);
        return ret >>> 0;
    }
    /**
    * @returns {Uint32Array}
    */
    used_portion_u32_view() {
        const ret = wasm.bytebuffer_used_portion_u32_view(this.ptr);
        return takeObject(ret);
    }
}
/**
*/
export class ProgressiveCullListWasm {

    static __wrap(ptr) {
        const obj = Object.create(ProgressiveCullListWasm.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_progressiveculllistwasm_free(ptr);
    }
    /**
    * @returns {Int32Array}
    */
    current_result_temp_view() {
        const ret = wasm.progressiveculllistwasm_current_result_temp_view(this.ptr);
        return takeObject(ret);
    }
    /**
    * @returns {number}
    */
    current_count() {
        const ret = wasm.progressiveculllistwasm_current_count(this.ptr);
        return ret >>> 0;
    }
    /**
    * @param {BoundsScene} scene
    * @param {number} count
    */
    cull_more(scene, count) {
        _assertClass(scene, BoundsScene);
        wasm.progressiveculllistwasm_cull_more(this.ptr, scene.ptr, count);
    }
    /**
    * @param {number} render_list_index
    * @returns {boolean}
    */
    has_more_after(render_list_index) {
        const ret = wasm.progressiveculllistwasm_has_more_after(this.ptr, render_list_index);
        return ret !== 0;
    }
    /**
    * @returns {number}
    */
    depth_multiplier() {
        const ret = wasm.progressiveculllistwasm_depth_multiplier(this.ptr);
        return ret;
    }
}
/**
*/
export class SortableWasmVec {

    static __wrap(ptr) {
        const obj = Object.create(SortableWasmVec.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_sortablewasmvec_free(ptr);
    }
    /**
    * @param {number} capacity
    */
    constructor(capacity) {
        const ret = wasm.sortablewasmvec_new(capacity);
        return SortableWasmVec.__wrap(ret);
    }
    /**
    */
    clear() {
        wasm.sortablewasmvec_clear(this.ptr);
    }
    /**
    * @param {number} key
    * @returns {number}
    */
    add1(key) {
        const ret = wasm.sortablewasmvec_add1(this.ptr, key);
        return ret >>> 0;
    }
    /**
    * @param {number} key1
    * @param {number} key2
    * @returns {number}
    */
    add2(key1, key2) {
        const ret = wasm.sortablewasmvec_add2(this.ptr, key1, key2);
        return ret >>> 0;
    }
    /**
    * @returns {Uint32Array}
    */
    get_sorted() {
        const ret = wasm.sortablewasmvec_get_sorted(this.ptr);
        return takeObject(ret);
    }
}

async function load(module, imports) {
    if (typeof Response === 'function' && module instanceof Response) {
        if (typeof WebAssembly.instantiateStreaming === 'function') {
            try {
                return await WebAssembly.instantiateStreaming(module, imports);

            } catch (e) {
                if (module.headers.get('Content-Type') != 'application/wasm') {
                    console.warn("`WebAssembly.instantiateStreaming` failed because your server does not serve wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n", e);

                } else {
                    throw e;
                }
            }
        }

        const bytes = await module.arrayBuffer();
        return await WebAssembly.instantiate(bytes, imports);

    } else {
        const instance = await WebAssembly.instantiate(module, imports);

        if (instance instanceof WebAssembly.Instance) {
            return { instance, module };

        } else {
            return instance;
        }
    }
}

async function init(input, maybe_memory) {
    if (typeof input === 'undefined') {
        input = new URL('engine_wasm_bg.wasm', import.meta.url);
    }
    const imports = {};
    imports.wbg = {};
    imports.wbg.__wbg_log_9057747d6886b01e = function(arg0, arg1) {
        console.log(getStringFromWasm0(arg0, arg1));
    };
    imports.wbg.__wbg_log_c24487efd5816ed7 = function(arg0, arg1, arg2, arg3) {
        console.log(getStringFromWasm0(arg0, arg1), getStringFromWasm0(arg2, arg3));
    };
    imports.wbg.__wbindgen_object_drop_ref = function(arg0) {
        takeObject(arg0);
    };
    imports.wbg.__wbg_new_16f24b0728c5e67b = function() {
        const ret = new Array();
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_from_4216160a11e086ef = function(arg0) {
        const ret = Array.from(getObject(arg0));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_push_a72df856079e6930 = function(arg0, arg1) {
        const ret = getObject(arg0).push(getObject(arg1));
        return ret;
    };
    imports.wbg.__wbg_buffer_5e74a88a1424a2e0 = function(arg0) {
        const ret = getObject(arg0).buffer;
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_newwithbyteoffsetandlength_a0b51a3de0017386 = function(arg0, arg1, arg2) {
        const ret = new Int32Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_newwithbyteoffsetandlength_278ec7532799393a = function(arg0, arg1, arg2) {
        const ret = new Uint8Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_newwithbyteoffsetandlength_f6c2c5e40f6f5bda = function(arg0, arg1, arg2) {
        const ret = new Uint32Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_newwithbyteoffsetandlength_6b7175db6fe51c86 = function(arg0, arg1, arg2) {
        const ret = new Float64Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_slice_01f02b2533b2b9f2 = function(arg0, arg1, arg2) {
        const ret = getObject(arg0).slice(arg1 >>> 0, arg2 >>> 0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_slice_38328d43a36547c6 = function(arg0, arg1, arg2) {
        const ret = getObject(arg0).slice(arg1 >>> 0, arg2 >>> 0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_slice_93f303d88707b165 = function(arg0, arg1, arg2) {
        const ret = getObject(arg0).slice(arg1 >>> 0, arg2 >>> 0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_new_693216e109162396 = function() {
        const ret = new Error();
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_stack_0ddaca5d1abfb52f = function(arg0, arg1) {
        const ret = getObject(arg1).stack;
        const ptr0 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        getInt32Memory0()[arg0 / 4 + 1] = len0;
        getInt32Memory0()[arg0 / 4 + 0] = ptr0;
    };
    imports.wbg.__wbg_error_09919627ac0992f5 = function(arg0, arg1) {
        try {
            console.error(getStringFromWasm0(arg0, arg1));
        } finally {
            wasm.__wbindgen_free(arg0, arg1);
        }
    };
    imports.wbg.__wbindgen_throw = function(arg0, arg1) {
        throw new Error(getStringFromWasm0(arg0, arg1));
    };
    imports.wbg.__wbindgen_memory = function() {
        const ret = wasm.memory;
        return addHeapObject(ret);
    };

    if (typeof input === 'string' || (typeof Request === 'function' && input instanceof Request) || (typeof URL === 'function' && input instanceof URL)) {
        input = fetch(input);
    }

    imports.wbg.memory = maybe_memory || new WebAssembly.Memory({initial:18,maximum:65535,shared:true});

    const { instance, module } = await load(await input, imports);

    wasm = instance.exports;
    init.__wbindgen_wasm_module = module;
    wasm.__wbindgen_start();
    return wasm;
}

export default init;

