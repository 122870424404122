<script lang="ts">
    import { getContext, onDestroy } from "svelte";
    import {
        type NavbarContext,
        type NavbarItem,
        PanelPosition,
    } from "./Navbar";
    import NavbarPanelView from "./NavbarPanelView.svelte";

    export let position: PanelPosition;
    export let topView: NavbarItem | undefined;
    export let bottomView: NavbarItem | undefined = undefined;
    export let onClose: (() => void) | undefined = undefined;

    const navbar = <NavbarContext>getContext("ui-navbar");

    $: panel = $navbar.panels.getOrCreate(position);
    $: hasBottomView = !!topView && !!bottomView;

    let panelHeight = 0;
    let panelWidth = 0;
    let topPanelHeight = 0;

    const defaultBottomHeight = 283;

    $: topPanelInitialHeight =
        panel.height ?? panelHeight - defaultBottomHeight;

    let panelEl: HTMLDivElement;
    let topViewEl: HTMLDivElement;
    let resizeEl: HTMLDivElement;

    const unsubscribeNavbar = navbar.subscribe((store) => {
        const p = store.panels.get(position);
        if(p && resizeEl && p.width !== panelWidth) {
            resizeEl.style.width = `var(--panel-width)`;
            panelWidth = p.width;
        }
    })

    function throttle(f: ResizeObserverCallback, delay: number) {
        let timer: ReturnType<typeof setTimeout>;
        return function () {
            clearTimeout(timer);
            timer = setTimeout(() => f.apply(null, arguments), delay);
        };
    }
    let widthObserver: ResizeObserver;
    let heightObserver: ResizeObserver;

    $: {
        if (!widthObserver && panelEl) {
            widthObserver = new ResizeObserver(
                throttle((entries) => {
                    const newWidth = entries[0].contentRect.width;
                    if (newWidth) {
                        navbar.setPanelWidth(position, newWidth);
                    }
                }, 500)
            );
            widthObserver.observe(panelEl);
        }
        if (!heightObserver && topViewEl) {
            heightObserver = new ResizeObserver(
                throttle((entries) => {
                    const newHeight = entries[0].contentRect.height;
                    if (hasBottomView && !panel.collapsed && newHeight) {
                        navbar.setPanelHeight(position, newHeight);
                    }
                }, 500)
            );
            heightObserver.observe(topViewEl);
        }
    }

    onDestroy(() => {
        if (widthObserver) {
            widthObserver.disconnect();
        }
        if (heightObserver) {
            heightObserver.disconnect();
        }
        unsubscribeNavbar();
    });

    function close() {
        if (onClose) {
            onClose();
        } else {
            navbar.closePanel(position);
        }
    }
</script>

<div
    class="nav-panel resize-parent resize-horizontal"
    class:bottom-collapsed={panel.collapsed}
    class:right-side={position === PanelPosition.Right}
    style="--full-panel-height:{panelHeight}px;"
    bind:this={panelEl}
    bind:offsetHeight={panelHeight}
    bind:offsetWidth={panelWidth}
>
    <div
        bind:this={resizeEl}
        class="resize"
        style="--panel-height:{panelHeight}px;--panel-width:{panel.width}px"
    />
    <div class="resize-content">
        <div
            class="panel-top"
            class:resize-parent={hasBottomView}
            class:resize-vertical={hasBottomView}
            bind:this={topViewEl}
            bind:offsetHeight={topPanelHeight}
        >
            {#if hasBottomView}
                <div
                    class="resize"
                    style="--panel-height:{topPanelInitialHeight}px;--panel-width:{panelWidth}px"
                />
            {/if}
            <div class="view-wrapper" class:resize-content={hasBottomView}>
                <NavbarPanelView item={topView || bottomView} onClose={close} />
            </div>
        </div>
        {#if hasBottomView}
            <div
                class="panel-bottom"
                style="--top-panel-height:{topPanelHeight}px;"
            >
                <NavbarPanelView
                    item={bottomView}
                    {position}
                />
            </div>
        {/if}
    </div>
</div>

<style lang="scss">
    $resizeLineWidth: 4px;
    $collapsedPanelHeight: 50px;
    $minPanelWidth: 360px + $resizeLineWidth;
    .nav-panel {
        position: relative;
        margin-top: var(--toolbar-height, 0px);
        :global(.panels-constructor-holder) {
            margin: 0;
        }

        &.right-side {
            > .resize-content {
                right: 0;
            }
            .resize {
                direction: rtl;
            }
        }
        :global(.panel-container) {
            --panel-top-offset: calc(
                var(--top-panel-height, 0px) + var(--toolbar-height, 0px)
            );
        }
    }
    .panel-top {
        .bottom-collapsed & .resize {
            height: calc(
                var(--full-panel-height) - $collapsedPanelHeight
            ) !important;
        }
        .bottom-collapsed & .resize {
            resize: none;
        }
        :global(.header) {
            --mdc-typography-headline4-font-size: 19px;
        }
        &:not(.resize-parent) {
            height: 100%;
            .view-wrapper {
                height: 100%;
            }
        }
    }
    .view-wrapper {
        background-color: white;
        z-index: 1;
    }
    .panel-bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: calc(100% - var(--top-panel-height));
        padding-bottom: 2px;
        box-sizing: border-box;

        .bottom-collapsed & {
            height: $collapsedPanelHeight;
        }
    }

    .resize-parent {
        position: relative;
        display: inline-block;
    }
    .resize {
        width: var(--panel-width);
        height: var(--panel-height);
        position: relative;
        min-width: $minPanelWidth;
        .resize-horizontal > & {
            resize: horizontal;
            overflow-x: overlay;
        }
        .resize-vertical > & {
            resize: vertical;
            overflow-y: overlay;
            width: calc(var(--panel-width) - $resizeLineWidth);
            min-width: calc($minPanelWidth - $resizeLineWidth);
            min-height: 200px;
            .nav-panel:not(.bottom-collapsed) & {
                max-height: calc(var(--full-panel-height) - 200px);
            }
        }
    }

    .resize::before {
        content: "";
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: transparent;
        pointer-events: none;
        display: block;
        position: absolute;
    }

    .resize-content {
        position: absolute;
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        .resize-horizontal > & {
            top: 0;
            right: $resizeLineWidth;
            width: calc(100% - $resizeLineWidth);
            box-shadow: 0px 1px 4px 0px rgba(16, 19, 20, 0.04);
            background-color: white;
        }
        .resize-vertical > & {
            bottom: $resizeLineWidth;
            height: calc(100% - $resizeLineWidth);
            &:before {
                content: "";
                display: block;
                width: calc(100%);
                position: absolute;
                border-bottom: 1px solid var(--color-divider);
                bottom: -2px;
                pointer-events: none;
            }
        }
    }

    .resize-horizontal .resize::-webkit-scrollbar {
        height: var(--panel-height);
        width: $resizeLineWidth;
    }

    .resize-vertical .resize::-webkit-scrollbar {
        width: var(--panel-width);
        height: $resizeLineWidth;
    }

    .resize::-webkit-resizer {
        background: transparent;
    }
    .resize::-webkit-scrollbar-corner {
        background-color: transparent;
    }
</style>
