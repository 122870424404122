<script lang="ts">
import { NumberProperty } from "bim-ts";
import type { QuantityRemapper } from "../actions/Action";
import NumAndUnitEditor from "./NumAndUnitEditor.svelte";

export let value: QuantityRemapper | null;
export let change: (x: QuantityRemapper | null) => void;

function toggleRemapper() {
    if (value) {
        change(null);
    } else {
        change({
            from: NumberProperty.new({ value: 0, unit: '' }),
            to: NumberProperty.new({ value: 0, unit: '' }),
        })
    }
}

</script>


<h3>remapper</h3>
<button on:click={toggleRemapper}>{value ?  'remove remapper' : 'add remapper'}</button>
{#if value}
    <p>from</p>
    <NumAndUnitEditor
        change={(val) => value && change({ from: val, to: value.to })}
        value={value.from}
    />
    <p>to</p>
    <NumAndUnitEditor
        change={(val) => value && change({ to: val, from: value.from })}
        value={value.to}
    />
    <br />
{/if}

<style lang="scss">
</style>

