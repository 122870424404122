import { Bim, CostModel as CM, LCOE } from "bim-ts";
import { GridConfigType } from "./types";
import { setCostCategoryRows } from "src/cost-model/capital/table/utils";
import { GridApiType } from "src/cost-model/capital/table/types";
import { IterUtils } from "engine-utils-ts";
import { ColDef, createGrid } from "ag-grid-enterprise";
import { createPerYearColumnDefinition } from "./column-definitions/PerYear";
import { createGridConfig } from ".";

export function populateAgGridTable(params: {
    lcoe: LCOE.LCOECalculationResult;
    gridConfig: GridConfigType;
    gridApi: GridApiType;
    flattened: CM.FlattenedCostCategoryParams[];
    hierarchy: LCOE.CostHierarchy;
    hideIntermidYears: boolean;
    isReadonly: boolean;
}) {
    params.gridConfig.context.hierarchy = params.hierarchy;
    params.gridConfig.context.lcoe = params.lcoe;

    for (const x of params.hierarchy.categories.values()) {
        if (x.lcoeInput) {
            x.lcoeInput.input.flags = {
                ...x.lcoeInput.input.flags,
                editable: !params.isReadonly && !!x.lcoeInput.input?.update,
                overriden: x.lcoeInput.input.flags?.overriden && !params.isReadonly,
            };
        }
    }

    const years = new Set(
        params.flattened.flatMap((x) => [
            ...(params.hierarchy.categories
                .get(x.categoryId)
                ?.costPerYear?.keys() ?? []),
        ]),
    );
    const minYear = IterUtils.min(years);
    const maxYear = IterUtils.max(years);

    let cols = params.gridConfig.options.columnDefs;
    if (cols) {
        const perYearCols: ColDef<CM.FlattenedCostCategoryParams>[] = [];
        for (const col of cols) {
            if ("colId" in col) {
                col.hide = false;
                if (col.type?.includes("perYear")) {
                    perYearCols.push(col);
                    col.hide = true;
                }
            }
        }
        if (minYear === undefined || maxYear === undefined) {
        } else {
            const yearRange =
                maxYear -
                minYear + // base years
                1; // + net column as the last column
            const yearDiff = yearRange - perYearCols.length;
            if (yearDiff < 0) {
            } else if (yearDiff > 0) {
                const baseIndex = perYearCols.length;
                for (let i = 0; i < yearDiff; i++) {
                    const col = createPerYearColumnDefinition({
                        yearSequenceNumber: baseIndex + i,
                    });
                    cols.push(col);
                    perYearCols.push(col);
                }
                params.gridApi.setGridOption("columnDefs", cols);
            }
            const perYearIds = perYearCols.map((x) => x.colId!);
            params.gridApi.setColumnsVisible(perYearIds, false);
            params.gridApi.setColumnsVisible(
                perYearIds.slice(0, yearRange),
                true,
            );
            const intermidCol = perYearIds.slice(1, yearRange - 1);
            if (params.hideIntermidYears) {
                params.gridApi.setColumnsVisible(intermidCol, false);
            }
        }
    }

    const roots = params.hierarchy.getRootCategories();
    setCostCategoryRows(params.flattened, params.gridApi, {
        bottomPin: roots.length ? roots.map((x) => x[0]) : undefined,
    });
}

export function exportLcoeAsExcel(
    bim: Bim,
    lcoe: LCOE.CreateLcoeHierarchyResult,
    //uiSettings: LcoeUi
) {
    const div = document.createElement("div");
    const gridConfig = createGridConfig(bim);
    const gridApi = createGrid(div, gridConfig.options);
    const spreadsheets: string[] = [];
    populateAgGridTable({
        lcoe: lcoe.lcoe,
        gridApi,
        gridConfig,
        flattened: lcoe.hierarchy.flattenCostHierarchy(),
        hideIntermidYears: false,
        isReadonly: true,
        hierarchy: lcoe.hierarchy,
    });
    const sheet = gridApi.getSheetDataForExcel({
        sheetName: "LCOE",
    });
    if (sheet) {
        spreadsheets.push(sheet);
    }
    gridApi.destroy();
    return spreadsheets;
    gridApi.exportMultipleSheetsAsExcel({
        data: spreadsheets,
        fileName: "Lcoe.xlsx",
    });
}
