import { Vector2 } from "math-ts";
import proj4 from 'proj4';
import { NotificationDescription } from "ui-bindings";
import * as epsgJson from 'epsg-index/all.json' with { type: "json" };
import { ProjectionInfo, WGSCoord, WgsProjectionOrigin } from "bim-ts";


interface EpsgDatum {
	code: number,
	proj4: string,
}

//@ts-ignore
export const epsg = new Map<string, EpsgDatum>(Object.entries(epsgJson.default));


export class WGSConverter {
	static getEllipsoidName(projInfo: ProjectionInfo): string {
		const ellipsoidName = projInfo.parameters.get("EllipsoidName");
		switch (ellipsoidName) {
			case undefined:
				return "WGS84"
			case "GRS1980":
				return "GRS80";
			default:
				return ellipsoidName;
		}
	}

	static getDatum(
		projectionOrigin: WgsProjectionOrigin,
		sendNotification?: (notification: NotificationDescription) => void
	): string {
		const projectionString = projectionOrigin.projectionInfo.toProj4Datum();

		const ellipsoidName = this.getEllipsoidName(projectionOrigin.projectionInfo);
		
		const datum = projectionString + `
			+lat_0=${projectionOrigin.wgsOriginLatLong.latitude} 
			+lon_0=${projectionOrigin.wgsOriginLatLong.longitude} 
			+x_0=${projectionOrigin.wgsOriginCartesianCoords.x} 
			+y_0=${projectionOrigin.wgsOriginCartesianCoords.y}
			+ellps=${ellipsoidName}
		`;

		return datum;
	}

	static projectWgsToFlatMap(wgsCoord: WGSCoord, datum: string): Vector2 {
		try {
			const coords = [wgsCoord.longitude, wgsCoord.latitude];
			const flat = proj4(datum).forward(coords);
			return new Vector2(flat[0], flat[1]);
		} catch (e) {
			console.error('projection error', e);
			throw e;
		}
	}

	static projectFlatMapToWgs(coord: Vector2, datum: string): WGSCoord {
		try {
			const wgsCoord = proj4(datum).inverse(coord.clone());
			return new WGSCoord(wgsCoord.y, wgsCoord.x)!;
		} catch (e) {
			console.error('projection error', e);
			throw e;
		}
	}

	static getCartesianVector(from: WgsProjectionOrigin, to: WGSCoord){
		const datum1 = this.getDatum(from);

		const pFrom = this.projectWgsToFlatMap(from.wgsOriginLatLong, datum1);
		const pTo = this.projectWgsToFlatMap(to, datum1);

		return pTo.sub(pFrom);
	}
}