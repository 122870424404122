import type { VersionedValue, Result} from 'engine-utils-ts';
import { unitsConverter, convertUnits, Failure, Success, IterUtils } from 'engine-utils-ts';
import { PUI_PropertyNodeNumber } from 'ui-bindings';
import { BimProperty } from './bimDescriptions/BimProperty';
import { NumberProperty } from './properties/PrimitiveProps';


import type { ValueAndUnit } from 'engine-utils-ts'
export type { ValueAndUnit }

export class UnitsMapper implements VersionedValue {
    static converter = unitsConverter;
    converter = unitsConverter;

    private _version: number = 0;

    constructor(){
        this.setCurrentSystemOfUnits('Imperial');
    }


    currentSystemOfUnits: string = 'Imperial';
    listSystemsOfUnits() {
        return Array.from(unitsConverter.systems.keys());
    }
    setCurrentSystemOfUnits(val: string) {
        if (!unitsConverter.systems.has(val)) {
            throw new Error(`No system of units "${val}" found`);
        }
        this.currentSystemOfUnits = val;
        this._version += 1;
    }

    isImperial() {
        return this.currentSystemOfUnits === 'Imperial';
    }


    version(): number {
        return this._version;
    }

    mapToConfiguredOrNull(args: ValueAndUnit): ValueAndUnit | null {
        const configured = this.mapToConfiguredResult(args);
        if (configured instanceof Success) {
            return configured.value;
        } else {
            return null;
        }
    }

    mapToConfiguredResult(args: ValueAndUnit): Result<ValueAndUnit> {
        try {
            return new Success(this.mapToConfigured(args));
        } catch (e) {
            return new Failure(e.message);
        }
    }
    mapToConfigured(args: ValueAndUnit): ValueAndUnit {
        if (!args.unit || !this.currentSystemOfUnits) {
            return args;
        }
        const toUnit = unitsConverter.convertUnitsToSystem(args.unit, this.currentSystemOfUnits);
        //const toRemap = args.unit ? this._unitsMappings.get(args.unit) : null;
        //const [value, unit] = unitsConverter.convertValueToSystem(
        //    args.value,
        //    args.unit,
        //    this.currentSystemOfUnits
        //);
        const value = convertUnits(args.value, args.unit, toUnit);
        if (value instanceof Failure) {
            console.error(`error converting from ${args.unit} to ${toUnit}`);
            return { value: 0, unit: toUnit };
        } else {
            return { value: value.value, unit: toUnit };
        }
        //if (toRemap) {
        //    const value = convertUnits(args.value, args.unit!, toRemap);
        //    if (value instanceof Failure) {
        //        console.error(`error converting from ${args.unit} to ${toRemap}`);
        //        return {value: 0, unit: toRemap};
        //    } else {
        //        return {value: value.value, unit: toRemap};
        //    }
        //} else {
        //    return args;
        //}
    }

    mapUnitToConfigured(sourceUnit: string): string {
        return unitsConverter.convertUnitsToSystem(sourceUnit, this.currentSystemOfUnits);
    }

    static mapTo(valueUnit: ValueAndUnit, convertTo: string): number {
        if (!valueUnit.unit || !convertTo) {
            return valueUnit.value;
        }
        const converted = convertUnits(valueUnit.value, valueUnit.unit, convertTo);
        if (converted instanceof Failure) {
            console.error(`error converting from ${valueUnit.unit} to ${convertTo}`);
            return 0;
        } else {
            return converted.value;
        }
    }

    getMappedBoundProperty(sourceProperty: PUI_PropertyNodeNumber): RemappedProperty {
        const toUnits = unitsConverter.convertUnitsToSystem(
            sourceProperty.unit!,
            this.currentSystemOfUnits,
        );
        //const toRemap = this._unitsMappings.get(sourceProperty.unit!);
        return new RemappedProperty(sourceProperty, toUnits)
    }
}

interface NodeNumberValueAndUnit {
    value: number | null;
    unit?: string;
}

class RemappedProperty implements NodeNumberValueAndUnit {

    _sourceProp: PUI_PropertyNodeNumber;
    _mapTo: string | undefined;
    _lastMapped: number | undefined;

    constructor(sourceProp: PUI_PropertyNodeNumber, mapTo: string | undefined) {
        this._sourceProp = sourceProp;
        this._mapTo = mapTo;
    }

    get value(): number | null {
        if (this._sourceProp.value === null) {
            return this._sourceProp.value;
        }
        const { value, unit } = this._sourceProp;
        this._lastMapped = this._mapTo ? UnitsMapper.mapTo({ value, unit }, this._mapTo) : this._sourceProp.value;
        return this._lastMapped;
    }

    set value(value: number | null) {
        if (Object.is(value, this._lastMapped)) {
            return;
        }
        const EPSILON = 1e-7;
        if (value == undefined) {
          return;
        }
        if (
            this._lastMapped !== undefined &&
            Math.abs(this._lastMapped - value) + EPSILON < this.step
        ) {
            return;
        }
        this._lastMapped = value;
        if (this._mapTo) {
            const mapped = UnitsMapper.mapTo({value, unit: this._mapTo}, this._sourceProp.unit!);
            if (Object.is(this._sourceProp.value, mapped)) {
                return;
            }
            if (this.step === 1) {
                const result = PUI_PropertyNodeNumber.isIntegerValidator(value, this._sourceProp);
                if (result instanceof Failure) {
                    return;
                }
            }
            this._sourceProp.value = mapped;
        } else {
            this._sourceProp.value = value;
        }

    }

    get unit(): string | undefined {
        return this._mapTo ?? this._sourceProp.unit;
    }

    get step(): number {
        return this._sourceProp.step;
    }

    get minMax(): [number, number] {
        if (this._mapTo) {
            const mapped0 = UnitsMapper.mapTo({value: this._sourceProp.minMax[0], unit: this._sourceProp.unit!}, this._mapTo)!;
            const mapped1 = UnitsMapper.mapTo({value: this._sourceProp.minMax[1], unit: this._sourceProp.unit!}, this._mapTo)!;
            return [mapped0, mapped1];
        } else {
            return this._sourceProp.minMax
        }
    }

    get decimals() {
        return this.step < 0.01 ? 4 : this.step < 0.1 ? 3 : this.step < 0.5 ? 2 : 0
    }

    get sliderSpeed() {
		const decimals = this.decimals;
		if (decimals <= 0) {
			return 0.1;
		}
		if (decimals <= 2) {
			return 10;
		}
		return 100;
    }
}

export function sumValueUnitLike(
    items: Array<NumberProperty | BimProperty | ValueAndUnit>,
    params?: {
        returnFirstUnit?: true,
        skipNonMatchingUnit?: true,
        takeZeroValue?: true,
    }
) : Result<ValueAndUnit>
{
    const valueUnits: ValueAndUnit[] = []
    for (const x of items) {
        let valueUnit: ValueAndUnit | null = null;
        if (x instanceof NumberProperty) {
            valueUnit = {
                value: x.value,
                unit: x.unit || undefined,
            }
        } else if (x instanceof BimProperty) {
            if (!x.isNumeric()) {
                if (params?.skipNonMatchingUnit) {
                    continue;
                }
                return new Failure({ msg: 'property is not numeric' })
            }
            valueUnit = {
                value: x.value,
                unit: x.unit || undefined,
            }
        } else if (typeof x.value === 'number'){
            valueUnit = x
        } else {
            // skipping no supported type
            continue;
        }

        if (valueUnit.value === 0 && !params?.takeZeroValue) {
            continue
        }
        valueUnits.push(valueUnit);
    }

    if (!valueUnits.length) {
        return new Failure({ msg: 'valid input items not found' })
    }

    let resultingUnit: string | null = null;
    if (params?.returnFirstUnit) {
        resultingUnit = valueUnits[0]?.unit ?? ''
    } else {
        const unitFrequencies = Array.from(
            IterUtils.countBy(
                valueUnits,
                (x) => {
                    return x.unit ?? ''
                }
            )
        )
        const mostFrequent = unitFrequencies.sort((a, b) => b[1] - a[1])[0];
        resultingUnit = mostFrequent[0]
    }

    const result: ValueAndUnit = { value: 0, unit: resultingUnit };
    for (const valueUnit of valueUnits) {
        if (
            unitsConverter.getDimensionsOfUnitsString(valueUnit.unit ?? '') !==
            unitsConverter.getDimensionsOfUnitsString(result.unit ?? '')
        ) {
            if (params?.skipNonMatchingUnit) {
                continue;
            }
            return new Failure({
                msg: 'units do not match ' + valueUnit.unit + ' and ' + result.unit
            })
        }
        result.value += unitsConverter.convertValue(
            valueUnit.value,
            valueUnit.unit ?? '',
            result.unit ?? '',
        )
    }

    return new Success(result);
}
