import { ObjectUtils, type Result } from "engine-utils-ts";
import type { Vector2 } from "math-ts";
import type { InverterProperties } from "./FarmLayoutMetrics";

export interface ILRPropertyArgs {
    name: string,
    value: [number, number],
    readonly?: boolean,
    minMax?: [number, number];
    step?: number,
    onChange?: OnChangeILRRangeCallBack,
    action?: (pos: Vector2) => void,
    validator?: ValidatorILRRange,
}
export type OnChangeILRRangeCallBack = (newValue: [number, number], property: ILRProperty) => void;
export type ValidatorILRRange = (property: ILRProperty) => Result<[number, number]>;

export class ILRProperty {
    readonly name: string;
    readonly minMax: [number, number];
    readonly step: number;
    readonly readonly: boolean;

    private _value: [number, number];
    private _onChangeCallBack?: OnChangeILRRangeCallBack;
    private _validator?: ValidatorILRRange;
    readonly _action?: (pos: Vector2) => void;
    constructor(args: ILRPropertyArgs) {
        this.name = args.name;
        this._value = args.value;
        this.readonly = !!args.readonly;
        this.minMax = args.minMax ?? [0, 1_000_000_000];
        this.step = 0.01;
        this._onChangeCallBack = args.onChange;
        this._action = args.action;
        this._validator = args.validator;
    }

    get minValue() {
        return this._value[0];
    }

    set minValue(newValue: number) {
        this.setValue(newValue, 0);
    }

    get maxValue() {
        return this._value[1];
    }

    set maxValue(newValue: number) {
        this.setValue(newValue, 1);
    }

    private setValue(newValue:number, idx: number){
        const currValue = this._value[idx];
        if (ObjectUtils.areObjectsEqual(currValue, newValue)) {
            return;
        }
        const value = this._value.slice();
        value[idx] = newValue;
        this._value = value as [number, number];
        if (this._onChangeCallBack) {
            this._onChangeCallBack(this._value, this);
        }
    }

    public action(pos: Vector2){
        if(this._action){
            this._action(pos);
        }
    }

    public value(){
        return 
    }
}

export interface InvertersGroup {
    label: string;
    inverters: InverterProperties[];
    minMax: [number, number];
}