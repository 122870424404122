<script lang="ts">
    import IconComponent from "../icon/Icon.svelte";
    import type { IconButton } from "./types";

    export let data: IconButton;
</script>

<span
    class="custom-icon-container"
    class:icon-actionable={data.onClick && !data.disabled}
    on:click={(e) => {
        if (data.onClick && !data.disabled) data.onClick(e);
    }}
>
    <IconComponent name={data.name} />
</span>

<style>
    .custom-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .icon-actionable {
        cursor: pointer;
    }
</style>
