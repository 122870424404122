import type { BimSceneOrigin, IrregularHeightmapGeometry, TerrainTileId } from "bim-ts";
import { RegularHeightmapGeometry, WGSCoord} from "bim-ts";
import { PollablePromise, Success, WorkerPool, Yield } from "engine-utils-ts";
import { Aabb2, Matrix4, Vector2 } from "math-ts";
import { CreateGeoTiffExecutor } from "./CreateGeoTiffExecutor.js";
//@ts-ignore
import UTIF from "./UTIF.js";
import { WGSConverter } from "../WGSConverter";


export function* createGeoTiff(
    pixelSizeMeters: number, 
    sceneOrigin: BimSceneOrigin | null, 
    tilesIdsBbox: Aabb2,
    tilesSize: number,
    geoTiles: Map<TerrainTileId, RegularHeightmapGeometry> | Map<TerrainTileId, IrregularHeightmapGeometry>,
    worldMatrix: Matrix4,
) {
    const datum = sceneOrigin?.wgsProjectionOrigin ? WGSConverter.getDatum(sceneOrigin.wgsProjectionOrigin) : null;
    const bbox = calculateBboxGeo(tilesIdsBbox, tilesSize, geoTiles, worldMatrix, datum); 

    let bboxWidthInM: number, bboxHeightInM: number;
    if (datum) {
        const bboxFlat = [
            WGSConverter.projectWgsToFlatMap(new WGSCoord(bbox.centerY(), bbox.min.x)!, datum),
            WGSConverter.projectWgsToFlatMap(new WGSCoord(bbox.centerY(), bbox.max.x)!, datum),
            WGSConverter.projectWgsToFlatMap(new WGSCoord(bbox.min.y, bbox.centerX())!, datum),
            WGSConverter.projectWgsToFlatMap(new WGSCoord(bbox.max.y, bbox.centerX())!, datum)
        ];
        bboxWidthInM = bboxFlat[1].x - bboxFlat[0].x;
        bboxHeightInM = bboxFlat[3].y - bboxFlat[2].y;
    } else {
        bboxWidthInM = bbox.width();
        bboxHeightInM = bbox.height();
    }

    tilesIdsBbox.max.addScalar(1);
    tilesIdsBbox.scale(tilesSize);

    const height = Math.floor(bboxWidthInM / pixelSizeMeters);
    const width = Math.floor(bboxHeightInM / pixelSizeMeters);
    const dx = bbox.width() / (width - 1), dy = bbox.height() / (height - 1);
    const image = new Float32Array(height * width).fill(NaN);
    
    yield Yield.Asap;

    const yStripsLengths = 30;
    const yIntervals = getIntervals(yStripsLengths, height - 1);

    const geoTiffPromises: { 
        startIndex: number, 
        promise: PollablePromise<Float32Array> 
    }[] = [];
    for (let yInterval of yIntervals) {
        const startIndex = (height - 1 - yInterval.max) * width;
        const geoTiffArgs = { 
            yInterval,
            bbox,
            dy, dx,
            width,
            datum,
            tilesIdsBbox,
            tilesSize,
            geoTiles,
            worldMatrix,
        };
        const geoTiffPromise = WorkerPool.execute(CreateGeoTiffExecutor, geoTiffArgs);
        geoTiffPromises.push({ startIndex, promise: new PollablePromise(geoTiffPromise) });
    }

    for (const geoTiffPromise of geoTiffPromises) {
        const geoTiffResult = yield* geoTiffPromise.promise.generatorWaitForResult();

        if (geoTiffResult instanceof Success) {
            const subimage = geoTiffResult.value;
            for (let i = 0; i < image.length; i++) {
                image[geoTiffPromise.startIndex + i] = subimage[i];
            }
        } else {
            console.error(geoTiffResult.errorMsg());
        }
    }

    const geoKeys = [1, 1, 2, 3, 1024, 0, 1, datum ? 2 : 1, 1025, 0, 1, 1];
    if (!datum && sceneOrigin?.cartesianCoordsOrigin) {
        bbox.translate(sceneOrigin.cartesianCoordsOrigin.xy());
    }

    const metadata = {
        "t322": [width],
        "t323": [height],
        "t324": [1000],
        "t325": [image.buffer.byteLength],
        "t34264": [dx, 0, 0, bbox.min.x, 0, -dy, 0, bbox.max.y, 0, 0, 0, 0, 0, 0, 0, 1],
        "t34735": geoKeys
    };

    // @ts-ignore
    const tiffBuffer = UTIF.encodeImage(image.buffer, width, height, metadata);

    return tiffBuffer;
}

function getIntervals(yStripsLengths: number, height: number): {min: number, max: number}[] {
    const yIntervals: {min: number, max: number}[] = [];
    let yMax = height;

    for (let i = yStripsLengths; i > 0; i--) {
        const intervalHeight = Math.ceil((yMax + 1) / i);
        yIntervals.push({min: yMax - intervalHeight + 1, max: yMax});
        yMax -= intervalHeight;
    }

    return yIntervals;
}

function calculateBboxGeo(
    tilesIdsBbox: Aabb2,
    tilesSize: number,
    geoTiles: Map<TerrainTileId, RegularHeightmapGeometry> | Map<TerrainTileId, IrregularHeightmapGeometry>,
    worldMatrix: Matrix4,
    datum: string | null,
): Aabb2 {
    
    const bboxGeo = Aabb2.empty();

    if (geoTiles.values().next().value instanceof RegularHeightmapGeometry) {
        const regularTiles = geoTiles as Map<TerrainTileId, RegularHeightmapGeometry>;
        const regularBbboxGeo = calculateRegularBboxGeo(
            tilesIdsBbox, tilesSize, regularTiles, worldMatrix, datum
        );
        bboxGeo.union(regularBbboxGeo);
    } else {
        const irregularTiles = geoTiles as Map<TerrainTileId, IrregularHeightmapGeometry>;
        const irregularBbboxGeo = calculateIrregularBboxGeo(
            tilesIdsBbox, irregularTiles, worldMatrix, datum
        );
        bboxGeo.union(irregularBbboxGeo);
    }

    return bboxGeo;
}

function calculateRegularBboxGeo(
    tilesIdsBbox: Aabb2, 
    tilesSize: number, 
    regularTiles: Map<TerrainTileId, RegularHeightmapGeometry>,
    worldMatrix: Matrix4,
    datum: string | null,
): Aabb2 {
    const bboxGeo = Aabb2.empty();

    const leftTiles: [TerrainTileId, RegularHeightmapGeometry][] = [];
    const rightTiles: [TerrainTileId, RegularHeightmapGeometry][] = [];
    const bottomTiles: [TerrainTileId, RegularHeightmapGeometry][] = [];
    const topTiles: [TerrainTileId, RegularHeightmapGeometry][] = [];

    for (const tile of regularTiles) {
        if (tile[0].x === tilesIdsBbox.min.x) {
            leftTiles.push([tile[0], tile[1]]);
        }
        if (tile[0].x === tilesIdsBbox.max.x) {
            rightTiles.push([tile[0], tile[1]]);
        }
        if (tile[0].y === tilesIdsBbox.min.y) {
            bottomTiles.push([tile[0], tile[1]]);
        }
        if (tile[0].y === tilesIdsBbox.max.y) {
            topTiles.push([tile[0], tile[1]]);
        }
    }

    const tilesXSegmentsCount = regularTiles.values().next().value.xSegmentsCount;
    const tilesYSegmentsCount = regularTiles.values().next().value.ySegmentsCount;
    const segmentSize = regularTiles.values().next().value.segmentSizeInMeters;

    const borderPoints: Vector2[] = [];
    let tileOffset: Vector2;

    function checkNearBorderAndUpdate(tile: RegularHeightmapGeometry, ix: number, iy: number): boolean {
        const elevation = tile.readElevationAtInds(ix, iy);
        if (!isNaN(elevation)) {
            const point = new Vector2(ix, iy).multiplyScalar(segmentSize).add(tileOffset);
            point.applyMatrix4(worldMatrix);
            borderPoints.push(point);
            return true;
        }
        return false;
    };

    for (const tile of leftTiles) {
        tileOffset = tile[0].localOffset(tilesSize);
        let lastY = 0, minX = tilesXSegmentsCount + 1;
        for (let iy = 0; iy <= tilesYSegmentsCount; ++iy) {
            for (let ix = 0; ix < minX; ++ix) {
                if (checkNearBorderAndUpdate(tile[1], ix, iy)) {
                    minX = ix;
                    lastY = iy;
                }
            }
        }
        minX = tilesXSegmentsCount + 1;
        for (let iy = tilesYSegmentsCount; iy > lastY; --iy) {
            for (let ix = 0; ix < minX; ++ix) {
                if (checkNearBorderAndUpdate(tile[1], ix, iy)) {
                    minX = ix;
                    lastY = iy;
                }
            }
        }
    }

    for (const tile of rightTiles) {
        tileOffset = tile[0].localOffset(tilesSize);
        let lastY = 0, maxX = -1;
        for (let iy = 0; iy <= tilesYSegmentsCount; ++iy) {
            for (let ix = tilesYSegmentsCount; ix > maxX; --ix) {
                if (checkNearBorderAndUpdate(tile[1], ix, iy)) {
                    maxX = ix;
                    lastY = iy;
                }
            }
        }
        maxX = -1;
        for (let iy = tilesYSegmentsCount; iy > lastY; --iy) {
            for (let ix = tilesYSegmentsCount; ix > maxX; --ix) {
                if (checkNearBorderAndUpdate(tile[1], ix, iy)) {
                    maxX = ix;
                    lastY = iy;
                }
            }
        }
    }

    for (const tile of bottomTiles) {
        tileOffset = tile[0].localOffset(tilesSize);
        let lastX = 0, minY = tilesYSegmentsCount + 1;
        for (let ix = 0; ix <= tilesYSegmentsCount; ++ix) {
            for (let iy = 0; iy < minY; ++iy) {
                if (checkNearBorderAndUpdate(tile[1], ix, iy)) {
                    minY = iy;
                    lastX = ix;
                }
            }
        }
        minY = tilesXSegmentsCount + 1;
        for (let ix = tilesYSegmentsCount; ix > lastX; --ix) {
            for (let iy = 0; iy < minY; ++iy) {
                if (checkNearBorderAndUpdate(tile[1], ix, iy)) {
                    minY = iy;
                    lastX = ix;
                }
            }
        }
    }

    for (const tile of topTiles) {
        tileOffset = tile[0].localOffset(tilesSize);
        let lastX = 0, maxY = -1;
        for (let ix = 0; ix <= tilesYSegmentsCount; ++ix) {
            for (let iy = tilesYSegmentsCount; iy > maxY; --iy) {
                if (checkNearBorderAndUpdate(tile[1], ix, iy)) {
                    maxY = iy;
                    lastX = ix;
                }
            }
        }
        maxY = -1;
        for (let ix = tilesYSegmentsCount; ix > lastX; --ix) {
            for (let iy = tilesXSegmentsCount; iy > maxY; --iy) {
                if (checkNearBorderAndUpdate(tile[1], ix, iy)) {
                    maxY = iy;
                    lastX = ix;
                }
            }
        }
    }

    if (datum) {
        const geoPoints = borderPoints.map(p => WGSConverter.projectFlatMapToWgs(p, datum));
        geoPoints.map(p => bboxGeo.expandByPoint({x: p.longitude, y: p.latitude}));
    } else {
        bboxGeo.setFromPoints(borderPoints);
    }

    return bboxGeo;
}

function calculateIrregularBboxGeo(
    tilesIdsBbox: Aabb2, 
    irregularTiles: Map<TerrainTileId, IrregularHeightmapGeometry>,
    worldMatrix: Matrix4,
    datum: string | null,
): Aabb2 {

    const bboxGeo = Aabb2.empty();
    const bbox2 = Aabb2.empty();

    const borderPoints: Vector2[] = [];
    const maxDistToBorder = 10;

    for (const tile of irregularTiles) {
        bbox2.union(tile[1].calcAabb().xy());

        if (tile[0].x === tilesIdsBbox.min.x) {
            for (let i = 0; i < tile[1].points3d.length; i += 3) {
                if (tile[1].points3d[i] - bbox2.min.x < maxDistToBorder) {
                    const point = new Vector2(tile[1].points3d[i], tile[1].points3d[i+1]);
                    point.applyMatrix4(worldMatrix);
                    borderPoints.push(point);
                }
            }
        }
        if (tile[0].x === tilesIdsBbox.max.x) {
            for (let i = 0; i < tile[1].points3d.length; i += 3) {
                if (bbox2.max.x - tile[1].points3d[i] < maxDistToBorder) {
                    const point = new Vector2(tile[1].points3d[i], tile[1].points3d[i+1]);
                    point.applyMatrix4(worldMatrix);
                    borderPoints.push(point);
                }
            }
        }
        if (tile[0].y === tilesIdsBbox.min.y) {
            for (let i = 1; i < tile[1].points3d.length; i += 3) {
                if (tile[1].points3d[i] - bbox2.min.y < maxDistToBorder) {
                    const point = new Vector2(tile[1].points3d[i-1], tile[1].points3d[i]);
                    point.applyMatrix4(worldMatrix);
                    borderPoints.push(point);
                }
            }
        }
        if (tile[0].y === tilesIdsBbox.max.y) {
            for (let i = 1; i < tile[1].points3d.length; i += 3) {
                if (bbox2.max.y - tile[1].points3d[i] < maxDistToBorder) {
                    const point = new Vector2(tile[1].points3d[i-1], tile[1].points3d[i]);
                    point.applyMatrix4(worldMatrix);
                    borderPoints.push(point);
                }
            }
        }
    }

    if (datum) {
        const geoPoints = borderPoints.map(p => WGSConverter.projectFlatMapToWgs(p, datum));
        geoPoints.map(p => bboxGeo.expandByPoint({x: p.longitude, y: p.latitude}));
    } else {
        bboxGeo.setFromPoints(borderPoints);
    }

    return bboxGeo;
}