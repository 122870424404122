import { IdBimScene, Bim, Catalog, WgsProjectionOrigin, BimSceneOrigin } from 'bim-ts';
import { BimPatch } from 'bim-ts';
import { Yield } from 'engine-utils-ts';
import { type Matrix4 } from 'math-ts';
import type { FileImporter, FileToImport, FileImporterContext } from 'ui-bindings';
import { ImportForm, buildLayoutFormUi } from '../LayoutFormUi';
import { checkCivilPluginVersion } from './CheckCivilPlugin';
import { deserializeFlatBufferModel } from './SiteModelParser';
import { LayoutJson, allocateBorder, allocateEquipment, allocateRoad, getLayoutOrigin, importSurface, parseLayoutWgsOrigin } from './LayoutParser';
import { getBimPatchOrigin, ImportMode } from 'src/bim-assets/BimAssetsOriginHandling';

export interface AssetImportSettings {

}

export class LayoutImporter implements FileImporter {

    fileExtensions = ['.json','.pvfm'];

    constructor(
        readonly bim: Bim,
        readonly catalog: Catalog
    ) {
    }

	additionalFileCheck(file: FileToImport): boolean {
		if (file.filename.endsWith('.json')) {
			const asStr = new TextDecoder().decode(file.fileArrayBuffer);
			if(asStr==="" && file.fileArrayBuffer.byteLength>0){
				console.error("Surface file is too big to parse. Please, use binary format from latest plugin version instead");
				return false;
			}

			return true;
		}else if (file.filename.endsWith('.pvfm')){
			return true;
		}
		return false;
	}

    *startImport(context: FileImporterContext, file: FileToImport): Generator<Yield, void> {
        yield* checkCivilPluginVersion(context, file);

        let asObj: LayoutJson | null = null;
        
        if (file.filename.endsWith('.json')) {
            const asStr = new TextDecoder().decode(file.fileArrayBuffer);
            asObj = JSON.parse(asStr) as LayoutJson;
        } else if (file.filename.endsWith('.pvfm')) {
            asObj = deserializeFlatBufferModel(file.fileArrayBuffer);
        }
        if (asObj == null) {
            context.logger.error('Surface construction failed due to incorrect data.', file.filename);
            throw new Error('Not valid file');
        }
        console.log(`Loading surface created with plugin version ${asObj.PluginVersion}.`)

        //remove unwanted elements
        asObj.Contour3D = [];
        asObj.Roads = [];
        asObj.Trackers = [];
        asObj.Transformers = [];

        buildLayoutFormUi(
            context,
            asObj,
            this.catalog,
            (formData) => context.importInstances(this.importInstances(context, formData, asObj!))
        );
    }

    *importInstances(context: FileImporterContext, formData: ImportForm, asObj: LayoutJson) {
            const getSceneInstance = (type: 'trackers' | 'skid', name: string) => {
                const data = formData[type][name];
                return data.selected?.sceneInstance;
            }

            const layoutOrigin = getLayoutOrigin(asObj);
            const layoutWgsOrigin: WgsProjectionOrigin | null = parseLayoutWgsOrigin(asObj, context.sendNotification);
            const layoutSceneOriginAndCorrection = getBimPatchOrigin(
                this.bim, 
                formData.mode, 
                layoutOrigin, 
                layoutWgsOrigin,
                context.sendNotification
            );

            let bimPatch = new BimPatch();
            bimPatch.sceneOrigin = layoutSceneOriginAndCorrection.origin;
            const correction = layoutSceneOriginAndCorrection.correction;
            

            const positionPatches: Map<IdBimScene, Matrix4> = new Map();

            for (const surfaceName of formData.surfaces) {
                const surface = asObj.Surfaces.find(surface => surface.Name === surfaceName);
                if (surface) {
                    const surfaceBimPatch = yield* importSurface(this.bim, surface, correction, context, formData.surfaceSettings);
                    bimPatch.mergeWith(surfaceBimPatch);
                    //yield Yield.NextFrame;
                }
			}

            const roadBimPatch = allocateRoad(this.bim, asObj.Roads, correction, context);
            const borderBimPatch = allocateBorder(this.bim, asObj.Contour3D, correction.xy(), context);
            const equipmentBimPatch = allocateEquipment(this.bim, asObj, getSceneInstance, correction.xy(), context, this.catalog);

            bimPatch.mergeWith(equipmentBimPatch.bimPatch);
            for (const [idBimScene, matrix4] of equipmentBimPatch.matixes){
                positionPatches.set(idBimScene, matrix4)
            }

            if (roadBimPatch !== null) {
                bimPatch.mergeWith(roadBimPatch.bimPatch);
                for (const [idBimScene, matrix4] of roadBimPatch.matixes){
                    positionPatches.set(idBimScene, matrix4)
                }
            }
            if (borderBimPatch !== null) {
                bimPatch.mergeWith(borderBimPatch);
            }

            if (formData.mode === ImportMode.Replace) {
                this.bim.clear();
            }

            const allocatedSceneIstances = bimPatch.applyTo(this.bim);

            this.bim.instances.applyPatches(equipmentBimPatch.patches);
            this.bim.instances.patchWorldMatrices(positionPatches);
            this.bim.instances.setSelected(allocatedSceneIstances);

            yield* context.onFinish(allocatedSceneIstances);
    }
}


