import type { AssetId, PrimitivePropertyBase} from "..";
import { AssetsProperty, BooleanProperty, CatalogItemsReferenceProperty, MultiSelectorProperty, NumberProperty, SceneInstancesProperty, SelectorProperty, StringProperty } from "..";
import { Success, type Result } from "engine-utils-ts";
import type { MultiSelectorValue, PUI_CustomPropertyNodeArgs, PUI_PropertyNodeArgs, PUI_PropertyNodeBoolArgs, PUI_PropertyNodeMultiSelectorArgs, PUI_PropertyNodeNumberArgs, PUI_PropertyNodeSelectorArgs, PUI_PropertyNodeStringArgs, PUI_SceneInstancesSelectorPropertyNodeArgs, SceneInstancesSelectorValue } from "ui-bindings";
import { PUI_ConfigBasedBuilderParams, PUI_ConfigPropertyTransformer, PUI_CustomPropertyNode, PUI_PropertyNodeBool, PUI_PropertyNodeMultiSelector, PUI_PropertyNodeNumber, PUI_PropertyNodeSelector, PUI_PropertyNodeString, PUI_SceneInstancesSelectorPropertyNode } from "ui-bindings";

type PUI_DefaultPropertiesArgs = Pick<PUI_PropertyNodeArgs<any>, 'readonly' | "calculated" | "notActive">;
export function createPropertyGroupPuiTransformers(params?: PUI_DefaultPropertiesArgs){
    const configBuilderSettings = PUI_ConfigBasedBuilderParams.new([
        [
            (name, value, path) => value instanceof StringProperty,
            stringPropertyTransformer(params)
        ],
        [
            (name, value, path) => value instanceof NumberProperty,
            numberPropertyTransformer(params),
        ],
        [
            (name, value, path) => value instanceof BooleanProperty,
            booleanPropertyTransformer(params),
        ],
        [
            (name, value, path) => value instanceof SceneInstancesProperty,
            sceneInstancesPropertyTransformer(params),
        ],
        [
            (name, value, path) => value instanceof AssetsProperty,
            assetsPropertyTransformer(params),
        ],
        [
            (name, value, path) => value instanceof SelectorProperty,
            selectionPropertyTransformer(params),
        ],
        [
            (name, value, path) => value instanceof MultiSelectorProperty,
            multiSelectionPropertyTransformer(params),
        ],
        [
            (name, value, path) => value instanceof CatalogItemsReferenceProperty,
            catalogItemsPropertyTransformer(params),
        ],
    ]);

    return configBuilderSettings;
}

export function stringPropertyTransformer(params?: PUI_DefaultPropertiesArgs): PUI_ConfigPropertyTransformer<StringProperty, string, PUI_PropertyNodeString, PUI_PropertyNodeStringArgs> {
    return new PUI_ConfigPropertyTransformer(
        (p) => p.value,
        produceNewPropertyWitDifferentValue,
        (prop) => [
            PUI_PropertyNodeString,
            {
                hint: prop.description || undefined,
                readonly: prop.isReadonly ?? params?.readonly,
                notActive: params?.notActive,
                calculated: params?.calculated,
            }
        ],

    );
}

export function numberPropertyTransformer(params?: PUI_DefaultPropertiesArgs): PUI_ConfigPropertyTransformer<NumberProperty, number, PUI_PropertyNodeNumber, PUI_PropertyNodeNumberArgs> {
    return new PUI_ConfigPropertyTransformer(
        (p) => p.value,
        produceNewPropertyWitDifferentValue,
        (prop) => [
            PUI_PropertyNodeNumber,
            {
                hint: prop.description || undefined,
                unit: prop.unit,
                minMax: prop.range ?? undefined,
                step:prop.step,
                readonly: prop.isReadonly ?? params?.readonly,
                notActive: params?.notActive,
                calculated: params?.calculated,
            },
        ],

    );
}

export function booleanPropertyTransformer(params?: PUI_DefaultPropertiesArgs): PUI_ConfigPropertyTransformer<BooleanProperty, boolean, PUI_PropertyNodeBool, PUI_PropertyNodeBoolArgs> {
    return new PUI_ConfigPropertyTransformer(
        (p) => p.value,
        produceNewPropertyWitDifferentValue,
        (prop) => [
            PUI_PropertyNodeBool,
            {
                hint: prop.description || undefined,
                readonly: prop.isReadonly ?? params?.readonly,
                notActive: params?.notActive,
                calculated: params?.calculated,            
            },
        ],
    )
}

export function selectionPropertyTransformer(params?: PUI_DefaultPropertiesArgs): PUI_ConfigPropertyTransformer<
    SelectorProperty,
    string,
    PUI_PropertyNodeSelector,
    PUI_PropertyNodeSelectorArgs
> {
    return new PUI_ConfigPropertyTransformer(
        (p) => p.value,
        produceNewPropertyWitDifferentValue,
        (prop) => [
            PUI_PropertyNodeSelector,
            {
                hint: prop.description || undefined,
                description: prop.description ?? undefined,
                options: prop.options,
                readonly: prop.isReadonly ?? params?.readonly,
                notActive: params?.notActive,
                calculated: params?.calculated,              
            },
        ]
    );
}

export function multiSelectionPropertyTransformer(params?: PUI_DefaultPropertiesArgs): PUI_ConfigPropertyTransformer<
    MultiSelectorProperty,
    MultiSelectorValue[],
    PUI_PropertyNodeMultiSelector,
    PUI_PropertyNodeMultiSelectorArgs
> {
    return new PUI_ConfigPropertyTransformer(
        (p) => p.value.map<MultiSelectorValue>(v=>({value:v, label: v})),
        (newValue, prop) => {
            const values = newValue.map(v=>v.value as string);
            const newProp = produceNewPropertyWitDifferentValue(values, prop);
            return newProp;
        },
        (prop) => [
            PUI_PropertyNodeMultiSelector,
            {
                hint: prop.description || undefined,
                description: prop.description ?? undefined,
                options: prop.options.map(o=>({value: o, label: o})),
                maxSelect: prop.maxSelect ?? undefined,
                readonly: prop.isReadonly ?? params?.readonly,
                notActive: params?.notActive,
                calculated: params?.calculated,             
            },
        ]
    );
}
interface SceneInstancesContext {
    maxCount: number | undefined;
    types: string[];
}

export function sceneInstancesPropertyTransformer(params?: PUI_DefaultPropertiesArgs): PUI_ConfigPropertyTransformer<
    SceneInstancesProperty,
    SceneInstancesSelectorValue[],
    PUI_SceneInstancesSelectorPropertyNode,
    PUI_SceneInstancesSelectorPropertyNodeArgs
> {
    return new PUI_ConfigPropertyTransformer(
        (p) => p.value.map(v=>({value: v})),
        (newValue, property)=>{
            const newProp = property.withDifferentValue(newValue.map(v => v.value));
            return new Success(newProp);
        },
        (prop) => [
            PUI_SceneInstancesSelectorPropertyNode,
            {
                hint: prop.description || undefined,
                types: prop.types,
                maxSelect: prop.maxCount ?? undefined,
                readonly: prop.isReadonly ?? params?.readonly,
                notActive: params?.notActive,
                calculated: params?.calculated,             
            }
        ],
    )
}

export function assetsPropertyTransformer(params?: PUI_DefaultPropertiesArgs): PUI_ConfigPropertyTransformer<
    AssetsProperty,
    AssetId[],
    PUI_CustomPropertyNode<AssetsProperty, SceneInstancesContext>,
    PUI_CustomPropertyNodeArgs<AssetsProperty, SceneInstancesContext>
> {
    return new PUI_ConfigPropertyTransformer(
        (p) => p.value,
        produceNewPropertyWitDifferentValue,
        (prop) => [
            PUI_CustomPropertyNode,
            {
                hint: prop.description || undefined,
                description: prop.description,
                readonly: prop.isReadonly ?? params?.readonly,
                notActive: params?.notActive,
                calculated: params?.calculated,                 
                type_ident: 'assets-selector',
                context:{
                    maxCount: prop.maxCount ?? undefined,
                    types: prop.types
                },
            }
        ],
    )
}
export interface CatalogItemsPropertyContext {}

export function catalogItemsPropertyTransformer(params?: PUI_DefaultPropertiesArgs): PUI_ConfigPropertyTransformer<
    CatalogItemsReferenceProperty,
    CatalogItemsReferenceProperty,
    PUI_CustomPropertyNode<CatalogItemsReferenceProperty, CatalogItemsPropertyContext>,
    PUI_CustomPropertyNodeArgs<CatalogItemsReferenceProperty, CatalogItemsPropertyContext>
> {
    return new PUI_ConfigPropertyTransformer(
        (p) => p,
        (v) => new Success(v.withDifferentValue(v.value)),
        (prop) => [
            PUI_CustomPropertyNode,
            {
                type_ident: "catalog-items-selector",
                context: {},
                readonly: prop.isReadonly ?? params?.readonly,
                notActive: params?.notActive,
                calculated: params?.calculated, 
            },
        ]
    );
}

function produceNewPropertyWitDifferentValue<V, P extends PrimitivePropertyBase<V>>(newValue: V, property: P): Result<P> {
    return new Success(
        property.withDifferentValue(newValue) as P
    );
}