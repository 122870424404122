import { ObjectUtils } from 'engine-utils-ts';
import { Aabb } from 'math-ts';

import { Box3, BufferAttribute, BufferGeometry } from '../3rdParty/three';
import type { UniformsFlat } from '../composer/DynamicUniforms';
import type { ShaderFlags } from '../shaders/ShaderFlags';
import Utils from '../utils/Utils';
import { getAttrIndexOf } from './AttributesIndices';


export interface PerGeoShaderInfo {
	flags: ShaderFlags;
	uniforms: UniformsFlat;
}

export type AttributesDictionary = {
	[key: string]: BufferAttribute | undefined,
};


export class GeometryGpuRepr extends BufferGeometry {

	layoutBinFlags: number;
	edgesIndex: BufferAttribute;
	edgesDrawRange: { start: number, count: number };

    shaderInfo: PerGeoShaderInfo | null;

	aabb: Aabb;

	constructor(
		attributes: AttributesDictionary,
		index: BufferAttribute,
		edgesIndex: BufferAttribute,
		shaderInfo?: PerGeoShaderInfo
	) {
		super();
		let layout: number = 0;
		for (const attrName in attributes) {
			// @ts-ignore
			const attr = attributes[attrName];
			if (attr == undefined) {
				continue;
			}
			if (!(attr instanceof BufferAttribute)) {
				throw new Error(`only BufferAttributes are allowed`);
			}

			layout |= (1 << getAttrIndexOf(attrName));
			this.attributes[attrName] = attr;
		}
		this.layoutBinFlags = layout;


		this.index = index;
		this.edgesIndex = edgesIndex;
		this.drawRange.start = 0;
		this.drawRange.count = index.count;
		this.edgesDrawRange = { start: 0, count: edgesIndex.count };

		this.shaderInfo = shaderInfo ?? null;

		const positions = this.positionsF();
		this.aabb = positions ? Aabb.calcFromArray(positions) : Aabb.empty();
		this.boundingBox = new Box3().setFromArray(this.aabb.elements);
	}

	setEdgesDrawRange(start: number, count: number) {
		this.edgesDrawRange.start = start;
		this.edgesDrawRange.count = count;
	}

	positionsF(): Float32Array | undefined {
		return this.attributes.position.array as Float32Array;
	}
	
	normalsF(): Int8Array  | undefined {
		return this.attributes.normal.array as Int8Array;
	}
	
	uvsF(): Float32Array | null {
		return this.attributes.uv?.array as Float32Array || null;
	}

	equals(rhs: GeometryGpuRepr): boolean {
		return this === rhs
			|| (Utils.areArraysEqual(this.index.array as Uint32Array, rhs.index.array as Uint32Array)
				&& Utils.areFloatArraysEqual(this.positionsF(), rhs.positionsF())
				&& Utils.areFloatArraysEqual(this.normalsF(), rhs.normalsF())
			);
	}

}

export const KrEdgedGeoEmpty = ObjectUtils.deepFreeze(
	new GeometryGpuRepr(
		{position: new BufferAttribute(new Float32Array(), 3)},
		new BufferAttribute(new Uint16Array(), 1),
		new BufferAttribute(new Uint16Array(), 1),
		undefined
	)
);


export function box3SizeMax(box: Box3): number {
	const x = box.max.x - box.min.x;
	const y = box.max.y - box.min.y;
	const z = box.max.z - box.min.z;
	return Math.max(x, y, z);
}
