import { Builder, ByteBuffer } from "flatbuffers";
import type { ObjectsPickingSerializer } from "verdata-ts";
import { Config } from "../bimConfigs/ConfigsCollection";
import type { IdBimScene } from "../scene/SceneInstances";
import { ConfigObject } from "../schema/config-object";
import { ConfigObjectCollection } from './../schema/config-object-collection';
import { FlatbufCommon } from "./FlatbufCommon";
import type { ConfigsArchetypes } from "../bimConfigs/ConfigsArchetypes";
import { TypeIdentifierVersion } from "../schema/type-identifier-version";
import { PropsGroupsDeserializer, PropsGroupsSerializer } from "./PropsGroupsSerializer";
import { ScopedLogger } from "engine-utils-ts";
import { PropsDeserializer } from "./PropsSerializer";
import { PropsGroupBase } from '../properties/Props';
import { EmptyPropsStub } from '../properties/EmptyPropsStub';

export enum ConfigsCollectionVersions {
    None,
    FixTrackerTypes,
    AddTargetAcMode,
    RenameToMaxAcMode,
    AddAssetsProperty,
    AddRoadGridAlgo,
    RenameTypeIdentifiers,
    RemoveLvGenerationOption,
    FixMaxCountSelectedAssets,
    MigrateToColorProperty,
    AddAdaptiveRoadsInFarmLayout,
    AddTerrainAnalysisCutFill,
    RenameHeightPreferencesToMaxRowHeight,
    FixRangeCutFill,
    RenameCutFill,
    AddPropsInCutFill,
    MigrateTerrainSlopeToPercentages,
    ConnectAssetsToMvWiring,
    AddAboveGroundToMvWiring,
    AddGroupsSettingsInCutFill,
    AddNorthSouthSlopeSettingsInCutFill,
    AddVersionPerType,
    RedoVAUnitAsCombinationOfUnits,
    PropsGroupsArraysFix,
    Remove_SiteEnergySettingsConfig,
    Remove_LvWiringPanelConfig,
}

export class ConfigsSerializer implements ObjectsPickingSerializer<Config|null> {

    constructor(
        private readonly migrationFn:(img:Config, version:ConfigsCollectionVersions) => Config,
        private readonly archetypes: ConfigsArchetypes,
    ){}

    serialize(objects: [number, Config][]): Uint8Array {

        const logger = new ScopedLogger('configs-ser');
        const builder = new Builder(objects.length * 100);
        const propsS = new PropsGroupsSerializer({logger, builder, strictGroupsTypes: false});
        const versionPerType = this.archetypes.getVersionPerTypeIdentifier();

        const idsOffset = FlatbufCommon.writeInt32Vector(builder, objects.map((t) => t[0]));
        const collectionOffset = ConfigObjectCollection.createCollectionVector(
            builder,
            objects.map((t) => writeObj(builder, t[1], propsS))
        );
        const versionPerTypeOffset = ConfigObjectCollection.createVersionPerTypeIdentifierVector(
            builder,
            versionPerType.map((v) =>
                serializeTypeIdentifierVersion(builder, v)
            )
        );
        const groupsVersionsPerTypeOffset = 0; // we are not using typed groups in configs yet
        const customPropsVersionsPerTypeOffset = propsS.propsSerializer.serializeCustomTypesVersions();

        ConfigObjectCollection.startConfigObjectCollection(builder);
        ConfigObjectCollection.addFormatVersion(builder, ConfigsCollectionVersions.Remove_LvWiringPanelConfig);
        ConfigObjectCollection.addIds(builder, idsOffset);
        ConfigObjectCollection.addCollection(builder, collectionOffset);
        ConfigObjectCollection.addVersionPerTypeIdentifier(builder, versionPerTypeOffset);
        ConfigObjectCollection.addPropsGroupsVersionsPerTypeIdent(builder, groupsVersionsPerTypeOffset);
        ConfigObjectCollection.addCustomPropsVersionsPerTypeIdent(builder, customPropsVersionsPerTypeOffset);
        const root = ConfigObjectCollection.endConfigObjectCollection(builder);

        builder.finish(root);
        return builder.asUint8Array().slice();
    }

    _wasConfigRemoved(formatVersion: ConfigsCollectionVersions, objTypeIdent: string|null) {
        if (formatVersion < ConfigsCollectionVersions.Remove_SiteEnergySettingsConfig
            && objTypeIdent === 'SiteEnergySettingsConfig'
        ) {
            return true;
        } else if (formatVersion < ConfigsCollectionVersions.Remove_LvWiringPanelConfig
            && objTypeIdent === 'lv-wiring-panel-config'
        ) {
            return true;
        }
        return false;
    }

    deserialize(buffer: Uint8Array, idsToDeserialize: Set<number>): [number, Config|null][] {
        const rts = ConfigObjectCollection.getRootAsConfigObjectCollection(new ByteBuffer(buffer));
        
        const version = rts.formatVersion();

        const customPropsTypesVersionsFlat = rts.customPropsVersionsPerTypeIdent();
        const customPropsTypesVersions = customPropsTypesVersionsFlat ? FlatbufCommon.readTypeIdentsVersions(customPropsTypesVersionsFlat) : new Map<string, number>();
        const propsDeserializer = new PropsDeserializer({
            logger: new ScopedLogger('configs-props'),
            customTypesVersions: customPropsTypesVersions,
        });

        const propsGroupsDes = new PropsGroupsDeserializer({
            logger: new ScopedLogger('configs-props'),
            groupsTypesVersions: new Map(),
            expectGroupsAraysLegacyBug: version < ConfigsCollectionVersions.PropsGroupsArraysFix,
            propsDeserializer
        });

        const versionPerType = new Map<string, number>();
        for (let i = 0; i < rts.versionPerTypeIdentifierLength(); i++) {
            const typeIdentVersion = rts.versionPerTypeIdentifier(i)!;
            versionPerType.set(
                typeIdentVersion.typeIdentifier()!,
                typeIdentVersion.version()
            );
        }
        const res: [number, Config|null][] = [];
        for (let i = 0, il = rts.idsLength(); i < il; ++i) {
            const id = rts.ids(i)!;
            if (!idsToDeserialize.has(id)) {
                continue;
            }
            const t = rts.collection(i)!;

            if (this._wasConfigRemoved(version, t.typeIdentifier()!)) {
                res.push([id, null]);
                continue;
            }

            let obj: Config|null = readObj(t, propsGroupsDes);
            obj = this.migrationFn(obj, version);
            this.archetypes.migrate(
                obj,
                versionPerType.get(obj.type_identifier) ?? 0
            );
            res.push([
                id,
                obj
            ]);
        }
        return res;
    }
}

function writeObj(builder: Builder, g: Config, propsSerializer: PropsGroupsSerializer): number {
    const peropertiesOffset = propsSerializer.serializePropertyGroup(g.properties);
    
    if (!(g.props instanceof PropsGroupBase)) {
        throw new Error('config props field should be PropsGroupBase');
    }
    const propsOffset = propsSerializer.serializePropertyGroup(g.props);

    const typeIdent = g.type_identifier ? builder.createSharedString(g.type_identifier) : 0;

    ConfigObject.startConfigObject(builder);
    ConfigObject.addTypeIdentifier(builder, typeIdent);
    ConfigObject.addConnectedTo(builder, g.connectedTo);

    ConfigObject.addProperties(builder, peropertiesOffset);
    ConfigObject.addProps(builder, propsOffset);

    return ConfigObject.endConfigObject(builder);
}

function readObj(pl: ConfigObject, propsDeserializer: PropsGroupsDeserializer): Config {
    const properties = propsDeserializer.deserializePropertyGroup(pl.properties());
    const props = propsDeserializer.deserializePropsGroup(pl.props());

    const connectedTo = pl.connectedTo() === 0 ? 0: pl.connectedTo() as IdBimScene;
    return new Config(
        pl.typeIdentifier() ?? "",
        connectedTo,
        properties ?? {},
        props ?? EmptyPropsStub
    )
}

export function serializeTypeIdentifierVersion(builder:Builder, [type, version]: [string, number] ){
    const typeOffset = builder.createString(type);
    TypeIdentifierVersion.startTypeIdentifierVersion(builder);
    TypeIdentifierVersion.addTypeIdentifier(builder, typeOffset);
    TypeIdentifierVersion.addVersion(builder, version);
    return TypeIdentifierVersion.endTypeIdentifierVersion(builder);
}
