<script lang="ts">
import { createEventDispatcher } from "svelte";

let files: FileList | undefined;

let fileInput: HTMLInputElement;

export const load = () => {
    files = undefined;
    fileInput.click();
}

const dispatch = createEventDispatcher<{ upload: FileList }>();

$: if (files) {
    dispatch("upload", files)
}

</script>

<input bind:files bind:this={fileInput} type="file" />

<style>
input {
    display: none;
}
</style>
