/** Handle click outside of node */
export function clickOutside(node: HTMLElement, callbackFunction: () => void) {
    function handleClick(event: MouseEvent) {
        if (node && event.target instanceof Node && !node.contains(event.target)) {
            callbackFunction();
        }
    }
    document.addEventListener('click', handleClick, true);
    
    return {
        update(newCallbackFunction: () => void) {
            callbackFunction = newCallbackFunction;
        },
        destroy() {
            document.removeEventListener('click', handleClick, true);
        }
    }
}