import type { AnyTrackerProps, Bim, IdBimScene, SceneInstance} from "src";
import { FixedTiltTypeIdent, PVModuleTypeIdent, TrackerTypeIdent } from "src";
import type { ConnectionDescription} from "./types";
import { ModuleMatrixDescription, ModuleTableDescription } from "./types";
import { IterUtils } from "engine-utils-ts";

export function extractModuleTablesDescriptions(
    parent: IdBimScene,
    bim: Bim,
    stringsDivision: number[],
) {
    const descriptions: ModuleTableDescription[] = [];
    const children = bim.instances.spatialHierarchy._allObjects.get(parent)?.children ?? [];
    for (const { id } of children) {
        const child = bim.instances.peekById(id);
        if (!child) {
            continue;
        }
        if (child.type_identifier === TrackerTypeIdent) {
            descriptions.push(extractModuleTableFromTracker(
                id,
                child,
                bim,
                stringsDivision
            ));
        } else if (child.type_identifier === 'any-tracker') {
            descriptions.push(extractModuleTableFromAnyTracker(
                id,
                child,
                bim,
                stringsDivision
            ));
        } else if (child.type_identifier === FixedTiltTypeIdent) {
            descriptions.push(extractModuleTableFromFixedTilt(
                id,
                child,
                bim,
                stringsDivision
            ));
        }
    }
    return descriptions;
}

function extractModuleTableFromFixedTilt(
    id: IdBimScene,
    si: SceneInstance,
    bim: Bim,
    stringDivision: number[],
) {
    const modulesInString = si.properties.get("string | modules_count")?.asNumber() ?? 0;

    const stringCount = si.properties.get("dimensions | strings_count")?.asNumber() ?? 0;
    const moduleDescription = bim.keyPropertiesGroupFormatter.format(PVModuleTypeIdent, si.properties, si.props) ?? "unknown"
    const description = new ModuleTableDescription({
        sceneIds: [id],
        tableCount: 1,
        text: moduleDescription,
        matrices: [],
    })
    let outConnection: ConnectionDescription | null = null;
    for (const stringGroupCount of calculateStringsGrouping(stringDivision, stringCount)) {
        description.matrices.push(new ModuleMatrixDescription({
            modulesInString: modulesInString,
            stringCount: stringGroupCount,
            connectionToParent: outConnection ?? undefined,
        }))
    }
    return description;
}

function extractModuleTableFromAnyTracker(
    id: IdBimScene,
    si: SceneInstance,
    bim: Bim,
    stringDivision: number[],
) {
    const props = si.props as AnyTrackerProps;
    const modulesInString = props.tracker_frame.string.modules_count.value;
    const stringCount = si.properties.get("circuit | equipment | strings_count")?.asNumber() ?? 0;
    const moduleDescription = bim.keyPropertiesGroupFormatter.format(PVModuleTypeIdent, si.properties, si.props) ?? "unknown"
    return extractModuleTableFromTrackerBase(id, stringDivision, stringCount, modulesInString, moduleDescription);
}

function extractModuleTableFromTrackerBase(
    id: IdBimScene,
    stringDivision: number[],
    stringCount: number,
    modulesInString: number,
    moduleDescription: string,
) {
    const description = new ModuleTableDescription({
        sceneIds: [id],
        tableCount: 1,
        text: moduleDescription,
        matrices: [],
    })
    let outConnection: ConnectionDescription | null = null;
    for (const stringGroupCount of calculateStringsGrouping(stringDivision, stringCount)) {
        description.matrices.push(new ModuleMatrixDescription({
            modulesInString: modulesInString,
            stringCount: stringGroupCount,
            connectionToParent: outConnection ?? undefined,
        }))
    }
    return description
}

function extractModuleTableFromTracker(
    id: IdBimScene,
    si: SceneInstance,
    bim: Bim,
    stringDivision: number[],
) {
    const modulesInString = si.properties.get("tracker-frame | string | modules_count")?.asNumber() ?? 0;
    const stringCount = si.properties.get("circuit | equipment | strings_count")?.asNumber() ?? 0;
    const moduleDescription = bim.keyPropertiesGroupFormatter.format(PVModuleTypeIdent, si.properties, si.props) ?? "unknown"
    return extractModuleTableFromTrackerBase(id, stringDivision, stringCount, modulesInString, moduleDescription);
}

export function calculateStringsGrouping(stringDivision: number[], stringCount: number) {
    const stringGroups: number[] = []
    const totalStringDivisions = IterUtils.sum(stringDivision, x => x);
    let stringsLeft = stringCount;
    for (const [idx, div] of stringDivision.entries()) {
        let stringCountDiv: number;
        if (idx === stringDivision.length - 1) {
            stringCountDiv = stringsLeft;
        } else {
            stringCountDiv = Math.floor(stringCount * div / totalStringDivisions);
        }
        if (stringCountDiv) {
            stringGroups.push(stringCountDiv);
        }
        stringsLeft -= stringCountDiv;
    }
    return stringGroups;
}
