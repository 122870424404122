<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M1 5V1H5V5H1ZM1 10V6H5V10H1ZM6 5V1H10V5H6ZM6 10V6H10V10H6ZM2 4H4V2H2V4ZM7 4H9V2H7V4ZM7 9H9V7H7V9ZM2 9H4V7H2V9Z"
        fill="currentColor"
    />
    <path
        d="M6 15V11H10V15H6ZM1 15V11H5V15H1ZM11 5V1H15V5H11ZM11 10V6H15V10H11ZM7 14H9V12H7V14ZM12 4H14V2H12V4ZM12 9H14V7H12V9ZM2 14H4V12H2V14Z"
        fill="currentColor"
    />
    <path d="M11 15V11H15V15H11ZM12 14H14V12H12V14Z" fill="currentColor" />
</svg>
