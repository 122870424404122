<script lang="ts">
import { Catalog, CatalogItemIdType } from "bim-ts";
import { getContext } from "svelte";
import { PUI_PropertyNodeMultiSelector, type MultiSelectorValue } from "ui-bindings";
import PropertyView from "../../PropertyView.svelte";

const catalog = getContext<Catalog>('catalog');

export let frames: CatalogItemIdType[];

export let frame: CatalogItemIdType | undefined;

export let defaultFrame: CatalogItemIdType | undefined;

function getFrameLabel(frameId: CatalogItemIdType) {
    const catalogItem = catalog.catalogItems.peekById(frameId);
    if (!catalogItem) {
        return;
    }
    const label = catalog.catalogItemsUiLabels.solve(
        catalogItem.typeIdentifier,
        catalogItem.properties
    )?.title;
    return label;
}

function getFrameMultiselectValue(frameId: CatalogItemIdType): MultiSelectorValue | undefined {
    const label = getFrameLabel(frameId);
    if (!label) {
        return;
    }
    return {
        value: frameId,
        label: label,
    }
}



let frameSelectValue: MultiSelectorValue  | undefined = undefined;
$: frameSelectValue = defaultFrame && getFrameMultiselectValue(defaultFrame);

$: frame = frameSelectValue?.value as (CatalogItemIdType | undefined);

$: if (frames.length === 1) {
    frame = frames[0];
}


$: frameSelectorNode = new PUI_PropertyNodeMultiSelector({
    name: 'Frame',
    value: frameSelectValue ? [frameSelectValue] : [],
    maxSelect: 1,
    onChange: (newValue) => {
        frameSelectValue = newValue.at(0);
    },
    options: frames
        .map(getFrameMultiselectValue)
        .filter((x): x is MultiSelectorValue => !!x),
});

</script>

{#if frames.length >= 1}
    <PropertyView offset={0} property={frameSelectorNode} />
{/if}

<style lang="scss">
</style>
