import { ColDef, ValueFormatterFunc, ValueGetterFunc } from "ag-grid-enterprise";
import { CostModel as CM } from 'bim-ts';
import { getContext, commonCellClasses } from "./common";


export const createCostStateColumnDefinition = (): ColDef<CM.FlattenedCostCategoryParams> => ({
    colId: CM.CostStateColId,
    headerName: CM.CostStateColId,
    valueGetter: ((params) => {
        const data = params.data;
        const context = getContext(params)

        const category = context.category
        if (!category || !data) {
            return;
        }
        if (context.globalContext.hierarchy.ignored.has(data.categoryId)) {
            return 'Ignored';
        }
        if (CM.CostHierarchy.categoryHasCustomValues(category)) {
            return 'Customised'
        }

        if (CM.CostHierarchy.categoryHasSomePrice(category)) {
            return 'Default';
        }

        return ''
    }) as ValueGetterFunc<CM.FlattenedCostCategoryParams, CM.CostDataVariation>,


    cellClass: (params) => {
        const classes = commonCellClasses(getContext(params), x => x.costData);
        return classes;
    },

    cellStyle: {
        'text-align': 'left'
    },

    //headerClass: [
    //    'ag-grid-header-align-right',
    //],
})
