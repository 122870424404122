import type { RGBAHex , Result} from "engine-utils-ts";
import { EnumUtils, Failure, RGBA, Success } from "engine-utils-ts";
import type { Vector3 } from "math-ts";
import { DEG2RAD, Euler, Quaternion, RAD2DEG } from "math-ts";
import type { PropertyActionsValue, PUI_ActionsNodeArgs, PUI_Node, PUI_PropertyNodeColorArgs, PUI_PropertyNodeNumberArgs, PUI_PropertyNodeSelectorArgs, PUI_PropertyNodeStringArgs, PUI_PropertyNodeVec3Args } from "./PUI_Nodes";
import { PUI_ActionsNode, PUI_PropertyNodeColor, PUI_PropertyNodeNumber, PUI_PropertyNodeSelector, PUI_PropertyNodeString, PUI_PropertyNodeVec3 } from "./PUI_Nodes";



export class PUI_ConfigPropertyTransformer<
    ConfigPropTy,
    PUI_PropValueTy,
    Node extends PUI_Node,
    Params,
    OuterContext = undefined,
> {
    constructor(
        readonly mapObjectFieldToPuiNodeValue: (configPropValue: ConfigPropTy, outerContext: OuterContext) => PUI_PropValueTy,
        readonly mapFromPuiPNodeBackToObjField: (pui: PUI_PropValueTy, initialConfigPropValue: ConfigPropTy) => Result<ConfigPropTy>,
        readonly addNode: (
            configPropValue: ConfigPropTy,
            mappedPuiValue: PUI_PropValueTy,
            outerContext: OuterContext,
        ) => [
            ctor: { new(params: Params): Node; },
            params: Partial<Params>,
        ],
        readonly requiredOutContextClass: {new(args: any): OuterContext} | undefined = undefined,
    ) {
    }

    static enumSelector(enumTSObject: any, defaultValue: number)
        : PUI_ConfigPropertyTransformer<number, string, PUI_PropertyNodeSelector, PUI_PropertyNodeSelectorArgs>
    {
        return new PUI_ConfigPropertyTransformer(
            (num) => (EnumUtils.enumStringFromKey(enumTSObject, num, enumTSObject[defaultValue]) as Success<string>).value,
            (propValue) => EnumUtils.enumNumberValueFromString(enumTSObject, propValue),
            () => [
                PUI_PropertyNodeSelector,
                {
                    options: EnumUtils.getAllEnumConstsNames(enumTSObject),
                }
            ],
        );
    }

    static readonlyString(): PUI_ConfigPropertyTransformer<string, string, PUI_PropertyNodeString, PUI_PropertyNodeStringArgs> {
        return new PUI_ConfigPropertyTransformer(
            (v) => v,
            (p) => new Failure({msg: 'property is readonly'}),
            (val: string) => [
                PUI_PropertyNodeString,
                { readonly: true }
            ],
        )
    }

    static numberProp(numberParams: {
        minMax?: [number, number];
        step?: number,
        unit?: string,
        defaultValue?: number,
        readonly?: boolean,
    })
        : PUI_ConfigPropertyTransformer<number, number, PUI_PropertyNodeNumber, PUI_PropertyNodeNumberArgs>
    {
        return new PUI_ConfigPropertyTransformer(
            (prop) => prop,
            (prop) => new Success(prop),
            () => [
                PUI_PropertyNodeNumber,
                {
                    minMax: numberParams.minMax,
                    step: numberParams.step,
                    unit: numberParams.unit,
                    defaultValue: numberParams.defaultValue,
                    readonly: numberParams.readonly,
                }
            ]
        );
    }

    static vector3(args: {
        defaulValue?: Vector3,
        unit?: string,
        step?: number,
        readonly?: boolean,
    }): PUI_ConfigPropertyTransformer<Vector3, Vector3, PUI_PropertyNodeVec3, PUI_PropertyNodeVec3Args> {
        return new PUI_ConfigPropertyTransformer(
            (v3) => v3.clone(),
            (prop) => {
                if (prop.isFinite()) {
                    return new Success(prop.clone());
                } else {
                    return new Failure({msg: 'invalid'});
                }
            },
            () => [
                PUI_PropertyNodeVec3,
                {
                    defaultValue: args.defaulValue,
                    unit: args.unit,
                    step: args.step,
                    readonly: args.readonly,
                }
            ],
        );
    }

    static quatRotation(args: {
        defaulValue?: Vector3,
        step?: number,
        readonly?: boolean,
    }): PUI_ConfigPropertyTransformer<Quaternion, Vector3, PUI_PropertyNodeVec3, PUI_PropertyNodeVec3Args> {
        return new PUI_ConfigPropertyTransformer(
            (q) => new Euler().setFromQuaternion(q).toVector3().multiplyScalar(RAD2DEG),
            (prop) => {
                if (prop.isFinite()) {
                    const v = prop.clone().multiplyScalar(DEG2RAD);
                    const euler = new Euler(v.x, v.y, v.z);
                    const q = new Quaternion().setFromEuler(euler);
                    return new Success(q);
                } else {
                    return new Failure({msg: 'invalid'});
                }
            },
            () => [
                PUI_PropertyNodeVec3,
                {
                    defaultValue: args.defaulValue,
                    step: args.step,
                    readonly: args.readonly,
                }
            ]
        )
    }

    static colorSelector(args: {
        defaulValue?: string,
    }): PUI_ConfigPropertyTransformer<RGBAHex, string, PUI_PropertyNodeColor, PUI_PropertyNodeColorArgs> {
        return new PUI_ConfigPropertyTransformer(
            (c) => RGBA.toHexRgbString(c),
            (prop) => {
                return new Success(RGBA.parseFromHexString(prop));
            },
            () => [
                PUI_PropertyNodeColor,
                {
                }
            ],

        )
    }

    static actionsProperty<T>(args: {
        transformToActions: (prop: T) => PropertyActionsValue,
    }): PUI_ConfigPropertyTransformer<T, PropertyActionsValue, PUI_ActionsNode, PUI_ActionsNodeArgs<T>> {
        return new PUI_ConfigPropertyTransformer(
            (value) => args.transformToActions(value),
            (_prop) => {
                return new Success(undefined as any);
            },
            (context, actions) => [
                PUI_ActionsNode,
                {
                    context,
                    actions: actions.actions,
                }
            ],
        )
    }
}
