import { Matrix4 } from "math-ts";
import type {  BasicAnalyticalRepresentation, Bim, BimGeometries } from "src";
import { PolylineGeometry } from "src";
import type { ReactiveSolverBase } from "src/runtime/ReactiveSolverBase";
import { SolverObjectInstance } from "src/runtime/SolverObjectInstance";

export const PolylineTypeIdent = 'polyline';

export function registerPolyline(bim: Bim) {
    bim.instances.archetypes.registerArchetype(
		{
			type_identifier: PolylineTypeIdent,
			mandatoryProps: [
			],
		}
	);

	bim.reactiveRuntimes.registerRuntimeSolver(kmlPolylineDimensionsSolver(bim.allBimGeometries));

}

function kmlPolylineDimensionsSolver(geometries: BimGeometries): ReactiveSolverBase {
    const objectsDefaultArgs = {
        representationAnalytical: null as BasicAnalyticalRepresentation | null,
        worldMatrix: new Matrix4(),
        legacyProps: {
        },
    };

    return new SolverObjectInstance({
        solverIdentifier: "polyline-dimensions",
        objectsIdentifier: "polyline",
        objectsDefaultArgs,
        cache: false,
        solverFunction: (args) => {
            const repr = args.representationAnalytical;

			let length = 0;

            if (repr) {
                const geo = geometries.peekById(repr.geometryId);
				if (geo instanceof PolylineGeometry) {
                    length += geo.length();
				}
            }

            return {
                legacyProps: [
                    { path: ["dimensions", "length"], value: length, unit: "m" },
                ]
            };
        },
    });
}
