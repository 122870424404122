import { type ObservableObject } from "engine-utils-ts";
import { type UiBindings, PUI_PropertyNodeBool } from "ui-bindings";


export class TrackersPilesMarkupSettings {
    showTrackersPilesMarkup: boolean = false;
}

export class TrackersPilesMarkupSettingsUiBindings {
    static addBindings(uiBindings: UiBindings, settings: ObservableObject<TrackersPilesMarkupSettings>) {
        uiBindings.addSetting({
            name: ['View', 'Show trackers piles markup'],
            priority: 7,
            property: new PUI_PropertyNodeBool({
				name: "Show trackers piles markup",
                value: settings.poll().showTrackersPilesMarkup,
                onChange: (newValue) => {
                    settings.applyPatch({
						patch: { showTrackersPilesMarkup: newValue }
					})
                },
			}),
        });
    }
}