import type { LazyVersioned } from "engine-utils-ts";
import { DefaultMap, Failure, IterUtils, LazyDerivedAsync, LegacyLogger, ObjectUtils, Yield, preferPreviousOverInProgressWrapper, replaceCurrencyUnitWithSymbol } from "engine-utils-ts";
import type { Bim, IdBimScene, PropertiesGroupFormatters, SceneInstance } from "src";
import { BimProperty, DC_CNSTS, NumberProperty, SceneObjDiff } from "src";
import { sumValueUnitLike } from "src/UnitsMapper";
import { LvWirePricingRelatedPropsExtra, LvWireTypeIdent } from "src/archetypes/LvWire";
import type { CostComponents, CostComponentsNonNullable, CostsConfigProvider, EstimateCost, InstanceCost, SingleEstimateProvider} from "src/cost-model/capital";
import { CostHierarchy, createEmptyCostComponents, createEmptyCostComponentsNonNullable, createMiscCategory, fillModelBasedCostCategory, fillModelBasedTopLevelCategory, multiplyCosts } from "src/cost-model/capital";
import type { SpecificCableGroup } from "./types";

export function create_ElectricalSubtotal_AcDcElectricalCable_LV(
    bim: Bim,
    provider: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>
) {
    const wires = bim.instances.getLazyListOf({
        type_identifier: LvWireTypeIdent,
        relevantUpdateFlags: SceneObjDiff.NewProps | SceneObjDiff.LegacyProps,
    });

    const grouped = createGroupedCablesLazy(wires, bim.keyPropertiesGroupFormatter);

    const result = LazyDerivedAsync.new4<
        CostHierarchy,
        GroupedCables,
        InstanceCost[],
        EstimateCost[],
        NumberProperty
    >(
        'lv',
        [bim.unitsMapper],
        [
            grouped,
            provider.allInstanceCosts,
            provider.allEstimateCosts,
            totalDC,
        ],
        function* ([grouped, costs, estimates, totalDC]) {
            const quantityUnit = bim.unitsMapper.mapToConfigured({ value: 0, unit: 'm' }).unit!;
            const costUnit = bim.unitsMapper.mapToConfigured({ value: 0, unit: 'usd' }).unit!;
            const quantityUnitInMeters = NumberProperty.new({ value: 1, unit: quantityUnit }).as('m');

            // extract final result
            const lvDC = new CostHierarchy()
            const lvAC = new CostHierarchy()

            for (const { type, specificGroups } of grouped.cableTypeGroups) {
                let parentHierarchy = lvDC;
                if (type === DC_CNSTS.ConductorType.AcFeeder) {
                    parentHierarchy = lvAC;
                }
                const sub = new CostHierarchy();

                for (const { name, props, totalLength, ids } of specificGroups) {
                    const overrides = multiplyCosts(
                        costs.find(x => ObjectUtils.areObjectsEqual(x.props, props))?.costs ?? createEmptyCostComponents(),
                        quantityUnitInMeters
                    );
                    const defaults = multiplyCosts(createDefaultDCCostsPerMeter(), quantityUnitInMeters);

                    const category = sub.add({
                        description: { value: name },
                        relatedSceneInstanceIds: new Set(ids),
                        costUnit: {
                            options: [replaceCurrencyUnitWithSymbol(costUnit) + '/' + quantityUnit ],
                            index: 0,
                        },
                        quantity: { value: totalLength },
                        matchesSceneInstance: (query) => {
                            if (query.si?.type_identifier !== LvWireTypeIdent) {
                                return false;
                            }
                            const sampleProps = query.si.properties.extractPropertiesGroup(DcWireIdProps, { valueUnitOnly: true })
                            const result = ObjectUtils.areObjectsEqual(props, sampleProps);
                            return result;
                        }
                    })[1];


                    const updateCosts = (newCosts: CostComponents) => {
                        provider.findAndUpdateInstanceCost(
                            (prev) => (prev.costs = multiplyCosts(newCosts, 1 / quantityUnitInMeters), prev),
                            { instance_type: LvWireTypeIdent, props }
                        )
                    }

                    fillModelBasedCostCategory(category, overrides, defaults, updateCosts, totalLength.as(quantityUnit));
                }

                const category = sub.addRoot({ description: { value: type } });
                sub.sumupChildren(category[0])

                parentHierarchy.merge(sub);
            }

            const hier = new CostHierarchy();

            // add lv dc root category
            {
                const category = lvDC.addRoot({ description: { value: 'DC' } });
                lvDC.sumupChildren(category[0])
                hier.merge(lvDC);
            }

            // add lv ac root category
            {
                const category = lvAC.addRoot({ description: { value: 'AC' } });
                lvAC.sumupChildren(category[0])
                hier.merge(lvAC);
            }

            // add misc
            {
                const costId = 'ac-dc-electrical-cables-misc';
                const overrides = estimates.find(x => x.id.value === costId);
                const state: SingleEstimateProvider = {
                    id: costId,
                    defaults: createEmptyCostComponentsNonNullable(),
                    value: overrides,
                    update: (fn) => provider.findAndUpdateEstimateCost(costId, fn),
                }
                hier.add(createMiscCategory(state, bim.unitsMapper, totalDC))
            }

            // add lv root Category
            {
                const root = hier.addRoot({ description: { value: 'Low Voltage Elect Cable' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.materialCost = NumberProperty.new({ value: 0.032, unit: 'usd' });
                fillModelBasedTopLevelCategory(
                    root[0],
                    hier,
                    'ac-dc-electrical-cables-benchmark',
                    estimates,
                    provider.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
            }


            return hier;
        }
    )
    return result
}

interface GroupedCables {
    cableTypeGroups: CableTypeGroup[]
}

interface CableTypeGroup {
    type: string,
    specificGroups: SpecificCableGroup[]
}


export function getDCDefaultCostsPerMeter(_props: typeof DcWireIdProps): CostComponentsNonNullable {
    const result: CostComponentsNonNullable = {
        equipmentCost: NumberProperty.new({ value: 0, unit: 'usd' }),
        laborCost: NumberProperty.new({ value: 0, unit: 'usd' }),
        laborTimeUnitCost: NumberProperty.new({ value: 0, unit: 'usd' }),
        laborTimeUnits: NumberProperty.new({ value: 0, unit: 'usd' }),
        serviceCost: NumberProperty.new({ value: 0, unit: 'usd' }),
        materialCost: NumberProperty.new({ value: 0, unit: 'usd' }),
    };

    return result;
}

export const DcWireIdProps = {
    gauge: BimProperty.NewShared({
        path: ['specification', 'gauge'],
        value: 'unknown_model'
    }),
    material: BimProperty.NewShared({
        path: ['specification', 'material'],
        value: 'unknown_material'
    }),
    type: BimProperty.NewShared({
        path: ['specification', 'type'],
        value: 'unknown_type',
    }),
};

function createGroupedCablesLazy(
    wires: LazyVersioned<Array<[id: IdBimScene, si: SceneInstance]>>,
    keyPropsGroupFormatter: PropertiesGroupFormatters,
) {
    const groupedCables = preferPreviousOverInProgressWrapper(LazyDerivedAsync.new1(
        'groupedCables',
        [],
        [wires],
        function* ([wires]) {
            type CableType = string
            type SpecificCableName = string

            const groups = new DefaultMap<
                CableType,
                DefaultMap<SpecificCableName, SpecificCableGroup>
            >(
                () => new DefaultMap(
                    (cableName) => ({
                        name: cableName,
                        props: DcWireIdProps,
                        totalLength: NumberProperty.new({ value: 0, unit: 'ft' }),
                        ids: []
                    })
                )
            )

            for (const chunk of IterUtils.splitIterIntoChunks(wires, 10e3)) {
                yield Yield.Asap;
                const grouped = IterUtils.groupObjects(
                    chunk,
                    (o) => {
                        const props = o[1].properties.extractPropertiesGroup(DcWireIdProps);
                        return props.gauge.value + props.material.value + props.type.value;
                    },
                    (l, r) => ObjectUtils.areObjectsEqual(
                        l[1].properties.extractPropertiesGroup(DcWireIdProps, { valueUnitOnly: true }),
                        r[1].properties.extractPropertiesGroup(DcWireIdProps, { valueUnitOnly: true })
                    ),
                )
                for (const members of grouped) {
                    const sample = members[0][1];
                    const name = keyPropsGroupFormatter.format(LvWireTypeIdent, sample.properties, sample.props)
                    const props = sample.properties.extractPropertiesGroup(DcWireIdProps, { valueUnitOnly: true });
                    const lengths = members.map(x => x[1].properties.extractPropertiesGroup(LvWirePricingRelatedPropsExtra).length);
                    const totalLength = sumValueUnitLike(lengths)
                    if (!name || totalLength instanceof Failure) {
                        LegacyLogger.warn('Invalid wire group ' + name + '. Skipping');
                        continue;
                    }
                    const groupByCableType = groups.getOrCreate(props.type.asText());
                    const group = groupByCableType.getOrCreate(name);
                    const newGroupTotalLength = sumValueUnitLike([totalLength.value, group.totalLength])
                    if (newGroupTotalLength instanceof Failure) {
                        LegacyLogger.warn('can not sum up total lengths. Skipping');
                        continue;
                    }
                    group.props = props;
                    group.ids.push(...members.map(x => x[0]));
                    group.totalLength = NumberProperty.new(newGroupTotalLength.value);
                }
            }

            const result: GroupedCables = {
                cableTypeGroups: []
            }
            for (const [type, typeGroup] of groups) {
                const group: CableTypeGroup = { type, specificGroups: [] }
                group.specificGroups.push(...typeGroup.values())
                result.cableTypeGroups.push(group);
            }
            return result;
        }
    ));
    return groupedCables;
}

export function createDefaultDCCostsPerMeter() {
    const costs = createEmptyCostComponentsNonNullable();
    costs.materialCost = NumberProperty.new({ value: 1*3.28084, unit: 'usd' })
    return costs;
}
