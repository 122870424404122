import type { Writable } from 'svelte/store';
import { writable } from 'svelte/store';


export class UiPersistentState<T> {
    public store: Writable<T>;
    constructor(key: string, defaultStoreVal: T) {
        let initStoreVal = defaultStoreVal
        const loadedStr = localStorage.getItem(key)
        if (loadedStr)
            try { initStoreVal = JSON.parse(loadedStr) as T } catch (_) {}
        this.store = writable(initStoreVal)
        this.store.subscribe((newVal) => {
            const newValStr = JSON.stringify(newVal)
            try { localStorage.setItem(key, newValStr) } catch (_) {}
        })
    }
}
