import type { NumbersArrayProperty, UnitsMapper } from 'bim-ts';
import type { EChartOptions } from 'ui-charts';



export interface NumbersArrayChart {
    header: string;
    createChart: () => EChartOptions;
}

export function numbersArrayCharts(
    unitsMapper: UnitsMapper,
    p: NumbersArrayProperty,
): NumbersArrayChart[] {

    const res: NumbersArrayChart[] = [];

    let unit: string;
    if (p.value.unit) {
        unit = p.value.unit == 'rad' ? 'deg' : unitsMapper.mapUnitToConfigured(p.value.unit);
    } else {
        unit = '';
    }
    const Float64Array = p.value.toArray(unit);
    
    res.push({
        header: 'chart',
        createChart: () => generateChartFromNumbersArray({
            header: 'chart',
            values: Float64Array,
            valuesUnit: unit,
        }),
    })


    if (length >= 365 * 24 && length <= 366*24) {
        // looks like a hourly year chart, generate 24/12 heatmaps
    }

    return res;
}

function generateChartFromNumbersArray(args: {
    header: string,
    values: ArrayLike<number>,
    valuesUnit: string,

}) {
    // const count = col.count();
    // const months = [
    //     'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    //     'Jul', 'Aug', 'Sept', 'Oct', 'Novr', 'Dec'
    // ];
    const data = [];
    for (let i = 0; i < args.values.length; i++) {
        // let dateStr: string | number;
        // let dateUtc = dates?.get(i);
        // if (dateUtc === undefined) {
        //     dateStr = i;
        // } else {
        //     const date = new Date(dateUtc);
        //     const monthStr = months[date.getUTCMonth()];
        //     const hour = date.getUTCHours().toString().padStart(2, '0');
        //     const min = date.getUTCMinutes().toString().padStart(2, '0');
        //     dateStr = `${monthStr} ${date.getUTCDate()} - ${hour}:${min}`;
        // }
        // let value = col.get(i);
        // if (typeof value !== 'number') {
        //     value = 0;
        // }

        let dateStr = i;
        let value = args.values[i];
        data.push([dateStr, value]);
    }

    const options: EChartOptions = {
        tooltip: {
            renderMode: 'html',
            appendToBody: true,
            confine: true,
            trigger: 'axis',
            // position: function (pt) {
            //     return [pt[0], '10%'];
            // }
        },
        title: {
            left: 'center',
            text: args.header.toString(),
        },
        toolbox: {
            feature: {
                dataZoom: {
                    yAxisIndex: 'none'
                },
                restore: {},
            }
        },
        xAxis: {
            type: 'category',
            // boundaryGap: false
        },
        yAxis: {
            type: 'value',
            name: args.valuesUnit,
            // boundaryGap: [0, '100%']
        },
        dataZoom: [
            {
                type: 'inside',
                zoomOnMouseWheel: 'ctrl',
            },
            {
                show: true,
                type: 'slider',
            }
        ],
        series: [
            {
                type: 'line',
                smooth: true,
                symbol: 'none',
                areaStyle: {},
                data: data
            }
        ]
    };
    return options;
}
