<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <rect
        x="2"
        y="2"
        width="1"
        height="12"
        rx="0.5"
        fill="#1A1E1F"
        fill-opacity="0.4"
    />
    <path
        d="M5 3C5 2.44772 5.44772 2 6 2H13C13.5523 2 14 2.44772 14 3V13C14 13.5523 13.5523 14 13 14H6C5.44772 14 5 13.5523 5 13V3Z"
        fill="currentColor"
    />
</svg>
