

import { KrMath, type TypedArray } from './KrMath';
import { Vector3 } from './Vector3';
import type { Matrix3 } from './Matrix3';
import type { Matrix4 } from './Matrix4';
import type { Transform } from './Transform';
import { Aabb2 } from './Aabb2';
import { Vector2 } from './Vector2';

export type Box3FlatArray = [number, number, number, number, number, number];

export class Aabb {

	elements: [number, number, number, number, number, number];
	applyMatrix3!: (this: Aabb, matrix: Matrix3) => Aabb;
	applyMatrix4!: (this: Aabb, matrix: Matrix4) => Aabb;
	applyTransform!: (this: Aabb, tr: Transform) => Aabb;
	intersectsTriangle!: (this: Aabb, ttriangle: {a: Vector3, b: Vector3, c: Vector3}) => boolean;

	constructor(minx:number, miny:number, minz:number, maxx:number, maxy:number, maxz:number) {
		this.elements = [
			minx, miny, minz,
			maxx, maxy, maxz
		];
	}

	static empty(): Aabb {
		return new Aabb(
			Infinity, Infinity, Infinity,
			-Infinity, -Infinity, -Infinity
		)
	}

	static infinite(): Aabb {
		return new Aabb(
			-Infinity, -Infinity, -Infinity,
			Infinity, Infinity, Infinity
		)
	}

	static allocateSizeOne(): Aabb {
		return new Aabb(
			-0.5, -0.5, -0.5,
			0.5, 0.5, 0.5
		);
	}

	static allocateFromVecs(min: Vector3, max: Vector3): Aabb {
		return new Aabb(
			min.x, min.y, min.z,
			max.x, max.y, max.z
		);
	}

	static allocFromArray(arr: Readonly<Box3FlatArray>) {

		return new Aabb(arr[0], arr[1], arr[2], arr[3], arr[4], arr[5]);
	}

	static calcFromArray(array: ArrayLike<number>, start: number = 0, end: number = array.length) {

		let minX = +Infinity;
		let minY = +Infinity;
		let minZ = +Infinity;

		let maxX = -Infinity;
		let maxY = -Infinity;
		let maxZ = -Infinity;

		for ( var i = start, l = end; i < l; i += 3 ) {
			const x = array[ i ];
			const y = array[ i + 1 ];
			const z = array[ i + 2 ];

			if ( x < minX ) minX = x;
			if ( y < minY ) minY = y;
			if ( z < minZ ) minZ = z;

			if ( x > maxX ) maxX = x;
			if ( y > maxY ) maxY = y;
			if ( z > maxZ ) maxZ = z;
		}
		return this.allocFromArray([minX, minY, minZ, maxX, maxY, maxZ]);
	}

	copy(box: Readonly<Aabb>):Aabb {
		for (let i = 0; i < 6; ++i){
			this.elements[i] = box.elements[i];
		}
		return this;
	}

	copyFlat(elements: Readonly<Box3FlatArray>) {
		for (let i = 0; i < this.elements.length; ++i) {
			this.elements[i] = elements[i];
		}
	}

	clone(): Aabb {
		return Aabb.allocFromArray(this.elements);
	}

	isEmpty(): boolean {
		const elements = this.elements;
		return !((elements[3] >= elements[0])
			&& (elements[4] >= elements[1])
			&& (elements[5] >= elements[2]));
	}

	makeEmpty(): void {
		const elements = this.elements;
		elements[0] = elements[1] = elements[2] = +Infinity;
		elements[3] = elements[4] = elements[5] = -Infinity;
	}

	setFromPoints(points: Iterable<Vector3>) {
		this.makeEmpty();
		for (const p of points){
			this.expandByPoint(p);
		}
		return this;
	}

	setFromCenterAndSize(center: Readonly<Vector3>, size: Readonly<Vector3>): Aabb {
		const elements = this.elements;
		elements[0] = center.x - size.x * 0.5;
		elements[1] = center.y - size.y * 0.5;
		elements[2] = center.z - size.z * 0.5;
		elements[3] = center.x + size.x * 0.5;
		elements[4] = center.y + size.y * 0.5;
		elements[5] = center.z + size.z * 0.5;
		return this;
	}

	setSizeOne() {
		this.setFromCenterAndSize(Vector3.zero(), Vector3.one());
	}


	containsBox(box: Readonly<Aabb>) {
		const thisElements = this.elements;
		const boxElements = box.elements;
		return thisElements[0] <= boxElements[0] && thisElements[3] >= boxElements[3]
			&& thisElements[1] <= boxElements[1] && thisElements[4] >= boxElements[4]
			&& thisElements[2] <= boxElements[2] && thisElements[5] >= boxElements[5];
	}

	intersectsBox3(box: Aabb): boolean {
		const thisElements = this.elements;
		const boxElements = box.elements;
		return 	thisElements[0] <= boxElements[3] && thisElements[3] >= boxElements[0] &&
				thisElements[1] <= boxElements[4] && thisElements[4] >= boxElements[1] &&
				thisElements[2] <= boxElements[5] && thisElements[5] >= boxElements[2];
	}

	containsPoint(point: Vector3) {
		const elements = this.elements;
		return point.x > elements[0] && point.x < elements[3]
			&& point.y > elements[1] && point.y < elements[4]
			&& point.z > elements[2] && point.z < elements[5];
	}

	expandByFlatArray(array: TypedArray): void {
		if (array.length % 3 !== 0) {
			console.error('aabb: expandByFlatArray, array length is not multiple of 3');
			return;
		}
		let minX = +Infinity;
		let minY = +Infinity;
		let minZ = +Infinity;

		let maxX = -Infinity;
		let maxY = -Infinity;
		let maxZ = -Infinity;

		for ( var i = 0, l = array.length; i < l; i += 3 ) {
			const x = array[ i ];
			const y = array[ i + 1 ];
			const z = array[ i + 2 ];

			if ( x < minX ) minX = x;
			if ( y < minY ) minY = y;
			if ( z < minZ ) minZ = z;

			if ( x > maxX ) maxX = x;
			if ( y > maxY ) maxY = y;
			if ( z > maxZ ) maxZ = z;
		}
		this.elements[0] = Math.min(minX, this.elements[0]);
		this.elements[1] = Math.min(minY, this.elements[1]);
		this.elements[2] = Math.min(minZ, this.elements[2]);
		this.elements[3] = Math.max(maxX, this.elements[3]);
		this.elements[4] = Math.max(maxY, this.elements[4]);
		this.elements[5] = Math.max(maxZ, this.elements[5]);
	}

	expandByPoint(point: Vector3): this {
		const elements = this.elements;
		elements[0] = Math.min(elements[0], point.x);
		elements[1] = Math.min(elements[1], point.y);
		elements[2] = Math.min(elements[2], point.z);

		elements[3] = Math.max(elements[3], point.x);
		elements[4] = Math.max(elements[4], point.y);
		elements[5] = Math.max(elements[5], point.z);
		return this;
	}

	expandByScalar(s: number): this {
		const elements = this.elements;
		elements[0] -= s;
		elements[1] -= s;
		elements[2] -= s;

		elements[3] += s;
		elements[4] += s;
		elements[5] += s;
		return this;
	}

	translate(offset: Vector3): this {
		const elements = this.elements;
		elements[0] += offset.x;
		elements[1] += offset.y;
		elements[2] += offset.z;
		elements[3] += offset.x;
		elements[4] += offset.y;
		elements[5] += offset.z;
		return this;
	}
	translate3(x: number, y: number, z: number): this {
		const elements = this.elements;
		elements[0] += x;
		elements[1] += y;
		elements[2] += z;
		elements[3] += x;
		elements[4] += y;
		elements[5] += z;
		return this;
	}

	union(box: Aabb) {
		this._unionWithBoxArray(box.elements, 0);
	}

	_unionWithBoxArray(boxElements: ArrayLike<number>, offset: number) {
		if (boxElements.length < offset + 6) {
			console.warn('union invalid offset', offset);
			return;
		}
		const elements = this.elements;
		elements[0] = Math.min(elements[0], boxElements[offset + 0]);
		elements[1] = Math.min(elements[1], boxElements[offset + 1]);
		elements[2] = Math.min(elements[2], boxElements[offset + 2]);

		elements[3] = Math.max(elements[3], boxElements[offset + 3]);
		elements[4] = Math.max(elements[4], boxElements[offset + 4]);
		elements[5] = Math.max(elements[5], boxElements[offset + 5]);
	}

	expandByVector(vec: Vector3): Aabb {
		const elements = this.elements;
		elements[0] -= vec.x;
		elements[1] -= vec.y;
		elements[2] -= vec.z;
		elements[3] += vec.x;
		elements[4] += vec.y;
		elements[5] += vec.z;
		return this;
	}

	offsetByBoxMinMax(box: Aabb, multiplier:number) {
		const elements = this.elements;
		const boxElements = box.elements;
		elements[0] += boxElements[0] * multiplier;
		elements[1] += boxElements[1] * multiplier;
		elements[2] += boxElements[2] * multiplier;
		elements[3] += boxElements[3] * multiplier;
		elements[4] += boxElements[4] * multiplier;
		elements[5] += boxElements[5] * multiplier;
	}

	getSize(target?: Vector3): Vector3 {
		const elements = this.elements;
		const x = elements[3] - elements[0];
		const y = elements[4] - elements[1];
		const z = elements[5] - elements[2];
		if (target) {
			return target.set(x, y, z);
		}
		return new Vector3(x, y, z);
	}

	getMin_t(): Vector3 {
		const elements = this.elements;
		return new Vector3(elements[0], elements[1], elements[2]);
	}


	getMax_t(): Vector3 {
		const elements = this.elements;
		return new Vector3(elements[3], elements[4], elements[5]);
	}

	getMin(): Vector3 {
		const elements = this.elements;
		return Vector3.allocate(elements[0], elements[1], elements[2]);
	}

	getMax(): Vector3 {
		const elements = this.elements;
		return Vector3.allocate(elements[3], elements[4], elements[5]);
	}

	maxSide(): number {
		const elements = this.elements;
		return Math.max(Math.max(elements[3] - elements[0], elements[4] - elements[1]), elements[5] - elements[2]);
	}

	maxSideArea(): number {
		let heigh = this.height();
		let width = this.width();
		let depth = this.depth();
		return Math.max(Math.max(heigh * width, heigh * depth), width * depth)
	}

	minx() { return this.elements[0]; }
	miny() { return this.elements[1]; }
	minz() { return this.elements[2]; }
	maxx() { return this.elements[3]; }
	maxy() { return this.elements[4]; }
	maxz() { return this.elements[5]; }

	width()  { return this.elements[3] - this.elements[0]; }
	height() { return this.elements[5] - this.elements[2]; }
	depth()  { return this.elements[4] - this.elements[1]; }

	getCenter_t(): Vector3 {
		const elements = this.elements;
		return new Vector3(
			(elements[3] + elements[0]) * 0.5,
			(elements[4] + elements[1]) * 0.5,
			(elements[5] + elements[2]) * 0.5
		);
	}

	getCenter(target: Vector3): Vector3 {
		const elements = this.elements;
		target.x = (elements[3] + elements[0]) * 0.5;
		target.y = (elements[4] + elements[1]) * 0.5;
		target.z = (elements[5] + elements[2]) * 0.5;
		return target;
	}

	centerX(): number {
		return (this.elements[3] + this.elements[0]) * 0.5;
	}

	centerY(): number {
		return (this.elements[4] + this.elements[1]) * 0.5;
	}

	centerZ(): number {
		return (this.elements[5] + this.elements[2]) * 0.5;
	}

	setMinFrom(vec: Vector3) {
		vec.toArray(this.elements, 0);
	}

	setMaxFrom(vec: Vector3) {
		vec.toArray(this.elements, 3);
	}

	clampVec3ToThis(vec: Vector3) {
		const elements = this.elements;
		vec.x = KrMath.clamp(vec.x, elements[0], elements[3]);
		vec.y = KrMath.clamp(vec.y, elements[1], elements[4]);
		vec.z = KrMath.clamp(vec.z, elements[2], elements[5]);
	}

	lerpTo(target: Readonly<Aabb>, t: number): void {
		const elements = this.elements;
		for (let i = 0; i < 6; ++i){
			elements[i] = KrMath.lerp(elements[i], target.elements[i], t);
		}
	}

	areNumbersReal(): boolean {
		const elements = this.elements;
		for (let i = 0; i < 6; ++i){
			if (!isFinite(elements[i])) {
				return false;
			}
		}
		return true;
	}

	equals(box: Readonly<Aabb>): boolean {
		const elements = this.elements;
		const boxElements = box.elements;
		for (let i = 0; i < 6; ++i){
			if (Math.abs(elements[i] - boxElements[i]) > Number.EPSILON) {
				return false;
			}
		}
		return true;
	}

	equalsFlat(boxElements: Readonly<Box3FlatArray>): boolean {
		const elements = this.elements;
		for (let i = 0; i < 6; ++i){
			if (Math.abs(elements[i] - boxElements[i]) > Number.EPSILON) {
				return false;
			}
		}
		return true;
	}

	clampTo(box: Aabb): void {
		const elements = this.elements;
		const boxElements = box.elements;
		for (let i = 0; i < 3; ++i){
			elements[i] = Math.max(elements[i], boxElements[i]);
		}
		for (let i = 3; i < 6; ++i){
			elements[i] = Math.min(elements[i], boxElements[i]);
		}
	}

	clampPoint ( point:Vector3 ): Vector3 {
		return point.clamp( this.getMin_t(), this.getMax_t() );
	}

	distanceToPoint(point: Vector3): number {
		return this.clampPoint(point.clone()).sub(point).length();
	}

	xy(): Aabb2 {
		const els = this.elements;
		return new Aabb2(
			new Vector2(els[0], els[1]),
			new Vector2(els[3], els[4]),
		);
	}

	getCornerPoint(corner: BoxCorner): Vector3 {
		const elements = this.elements;
		const v = new Vector3(
			elements[0 + corner[0]],
			elements[1 + corner[1]],
			elements[2 + corner[2]],
		);
		return v;
	}

	getCornerOppositeOf_t(corner: BoxCorner): Vector3 {
		const elements = this.elements;
		const v = new Vector3(
			elements[(0 + corner[0] + 3) % 6],
			elements[(1 + corner[1] + 3) % 6],
			elements[(2 + corner[2] + 3) % 6],
		);
		return v;
	}

	findClosestCorner(point: Vector3): BoxCorner {
		const elements = this.elements;
		const corner: BoxCorner = [0, 0, 0];
		for (let i = 0; i < 3; ++i){
			const d1 = Math.abs(elements[i] - point.getComponent(i));
			const d2 = Math.abs(elements[i + 3] - point.getComponent(i));
			corner[i] = d1 < d2 ? 0 : 3;
		}
		return corner;
	}

	setCorner(corner: BoxCorner, value: Vector3) {
		for (let i = 0; i < 3; ++i){
			this.elements[i + corner[i]] = value.getComponent(i);
		}
	}


	getCornerPoints(): Vector3[] {
		const elements = this.elements;
		return [
			new Vector3(elements[0], elements[1], elements[2]),
			new Vector3(elements[0], elements[1], elements[5]),
			new Vector3(elements[0], elements[4], elements[2]),
			new Vector3(elements[0], elements[4], elements[5]),
			new Vector3(elements[3], elements[1], elements[2]),
			new Vector3(elements[3], elements[1], elements[5]),
			new Vector3(elements[3], elements[4], elements[2]),
			new Vector3(elements[3], elements[4], elements[5])
		];
	}

	get2DCornersAtZ(z: number): [Vector3, Vector3, Vector3, Vector3] {
		const elements = this.elements;
		return [
			new Vector3(elements[0], elements[1], z),
			new Vector3(elements[0], elements[4], z),
			new Vector3(elements[3], elements[4], z),
			new Vector3(elements[3], elements[1], z),
		];
	}
}

Aabb.prototype.applyMatrix3 = (function () {

	const points = [
		Vector3.zero(),
		Vector3.zero(),
		Vector3.zero(),
		Vector3.zero(),
		Vector3.zero(),
		Vector3.zero(),
		Vector3.zero(),
		Vector3.zero()
	];

	return function (this: Aabb, matrix: Matrix3) {

		// transform of empty box is an empty box.
		if (this.isEmpty()) return this;

		const elements = this.elements;
		// NOTE: I am using a binary pattern to specify all 2^3 combinations below
		points[0].set(elements[0], elements[1], elements[2]).applyMatrix3(matrix); // 000
		points[1].set(elements[0], elements[1], elements[5]).applyMatrix3(matrix); // 001
		points[2].set(elements[0], elements[4], elements[2]).applyMatrix3(matrix); // 010
		points[3].set(elements[0], elements[4], elements[5]).applyMatrix3(matrix); // 011
		points[4].set(elements[3], elements[1], elements[2]).applyMatrix3(matrix); // 100
		points[5].set(elements[3], elements[1], elements[5]).applyMatrix3(matrix); // 101
		points[6].set(elements[3], elements[4], elements[2]).applyMatrix3(matrix); // 110
		points[7].set(elements[3], elements[4], elements[5]).applyMatrix3(matrix); // 111

		this.setFromPoints(points);

		return this;

	};

})();


Aabb.prototype.intersectsTriangle = ( function () {

	// triangle centered vertices
	var v0 = new Vector3();
	var v1 = new Vector3();
	var v2 = new Vector3();

	// triangle edge vectors
	var f0 = new Vector3();
	var f1 = new Vector3();
	var f2 = new Vector3();

	var testAxis = new Vector3();

	var center = new Vector3();
	var extents = new Vector3();

	var triangleNormal = new Vector3();

	function satForAxes( axes: number[] ) {

		var i, j;

		for ( i = 0, j = axes.length - 3; i <= j; i += 3 ) {

			testAxis.fromArray( axes, i );
			// project the aabb onto the seperating axis
			var r = extents.x * Math.abs( testAxis.x ) + extents.y * Math.abs( testAxis.y ) + extents.z * Math.abs( testAxis.z );
			// project all 3 vertices of the triangle onto the seperating axis
			var p0 = v0.dot( testAxis );
			var p1 = v1.dot( testAxis );
			var p2 = v2.dot( testAxis );
			// actual test, basically see if either of the most extreme of the triangle points intersects r
			if ( Math.max( - Math.max( p0, p1, p2 ), Math.min( p0, p1, p2 ) ) > r ) {

				// points of the projected triangle are outside the projected half-length of the aabb
				// the axis is seperating and we can exit
				return false;

			}

		}

		return true;

	}

	return function intersectsTriangle(this: Aabb, triangle: {a: Vector3, b: Vector3, c: Vector3} ) {

		if ( this.isEmpty() ) {

			return false;

		}

		// compute box center and extents
		this.getCenter( center );
		this.getSize(extents).multiplyScalar(0.5);

		// translate triangle to aabb origin
		v0.subVectors( triangle.a, center );
		v1.subVectors( triangle.b, center );
		v2.subVectors( triangle.c, center );

		// compute edge vectors for triangle
		f0.subVectors( v1, v0 );
		f1.subVectors( v2, v1 );
		f2.subVectors( v0, v2 );

		// test against axes that are given by cross product combinations of the edges of the triangle and the edges of the aabb
		// make an axis testing of each of the 3 sides of the aabb against each of the 3 sides of the triangle = 9 axis of separation
		// axis_ij = u_i x f_j (u0, u1, u2 = face normals of aabb = x,y,z axes vectors since aabb is axis aligned)
		var axes = [
			0, - f0.z, f0.y, 0, - f1.z, f1.y, 0, - f2.z, f2.y,
			f0.z, 0, - f0.x, f1.z, 0, - f1.x, f2.z, 0, - f2.x,
			- f0.y, f0.x, 0, - f1.y, f1.x, 0, - f2.y, f2.x, 0
		];
		if ( ! satForAxes( axes ) ) {

			return false;

		}

		// test 3 face normals from the aabb
		axes = [ 1, 0, 0, 0, 1, 0, 0, 0, 1 ];
		if ( ! satForAxes( axes ) ) {

			return false;

		}

		// finally testing the face normal of the triangle
		// use already existing triangle edge vectors here
		triangleNormal.crossVectors( f0, f1 );
		axes = [ triangleNormal.x, triangleNormal.y, triangleNormal.z ];
		return satForAxes( axes );

	};

} )(),

Aabb.prototype.applyMatrix4 = (function () {

	const points = [
		Vector3.zero(),
		Vector3.zero(),
		Vector3.zero(),
		Vector3.zero(),
		Vector3.zero(),
		Vector3.zero(),
		Vector3.zero(),
		Vector3.zero()
	];

	return function (this: Aabb, matrix: Matrix4) {

		// transform of empty box is an empty box.
		if (this.isEmpty()) return this;

		const elements = this.elements;
		// NOTE: I am using a binary pattern to specify all 2^3 combinations below
		points[0].set(elements[0], elements[1], elements[2]).applyMatrix4(matrix); // 000
		points[1].set(elements[0], elements[1], elements[5]).applyMatrix4(matrix); // 001
		points[2].set(elements[0], elements[4], elements[2]).applyMatrix4(matrix); // 010
		points[3].set(elements[0], elements[4], elements[5]).applyMatrix4(matrix); // 011
		points[4].set(elements[3], elements[1], elements[2]).applyMatrix4(matrix); // 100
		points[5].set(elements[3], elements[1], elements[5]).applyMatrix4(matrix); // 101
		points[6].set(elements[3], elements[4], elements[2]).applyMatrix4(matrix); // 110
		points[7].set(elements[3], elements[4], elements[5]).applyMatrix4(matrix); // 111

		this.setFromPoints(points);

		return this;

	};

})();

Aabb.prototype.applyTransform = (function () {

	const points = [
		Vector3.zero(),
		Vector3.zero(),
		Vector3.zero(),
		Vector3.zero(),
		Vector3.zero(),
		Vector3.zero(),
		Vector3.zero(),
		Vector3.zero()
	];

	return function (this: Aabb, tr: Transform) {

		// transform of empty box is an empty box.
		if (this.isEmpty()) return this;

		const q = tr.rotation;
		const s = tr.scale;

		const elements = this.elements;
		// NOTE: I am using a binary pattern to specify all 2^3 combinations below
		points[0].set(elements[0], elements[1], elements[2]).multiply(s).applyQuaternion(q); // 000
		points[1].set(elements[0], elements[1], elements[5]).multiply(s).applyQuaternion(q); // 001
		points[2].set(elements[0], elements[4], elements[2]).multiply(s).applyQuaternion(q); // 010
		points[3].set(elements[0], elements[4], elements[5]).multiply(s).applyQuaternion(q); // 011
		points[4].set(elements[3], elements[1], elements[2]).multiply(s).applyQuaternion(q); // 100
		points[5].set(elements[3], elements[1], elements[5]).multiply(s).applyQuaternion(q); // 101
		points[6].set(elements[3], elements[4], elements[2]).multiply(s).applyQuaternion(q); // 110
		points[7].set(elements[3], elements[4], elements[5]).multiply(s).applyQuaternion(q); // 111

		this.setFromPoints(points);

		this.translate(tr.position);

		return this;

	};

})();

export const EmptyBox: Readonly<Aabb> = Aabb.empty();

type CornerCoordOffset = 0 | 3;

export type BoxCorner = [CornerCoordOffset, CornerCoordOffset, CornerCoordOffset];

export const BoxGridOriginCorner: BoxCorner = [0, 0, 3]
