import { IsInWebworker, isInNode } from './EnvChecks';
import type { ProjectNetworkClient } from './ProjectNetworkClient';
import { ScopedLogger } from './ScopedLogger';

const CachingNetworkClientCachesPrefix = 'CNC|';
const CachesVersion = 1;

(globalThis as any)['_SKIP_NETWORK_CACHE'] = false;

let caches: CacheStorage | null = null;

if (!(IsInWebworker() || isInNode())) {
    if (typeof window !== 'undefined' && window.caches) {
        caches = window.caches;
    }
}

export class CachingNetworkClient {

    readonly logger: ScopedLogger;

    readonly client: ProjectNetworkClient;
    
    readonly _cachesVersionedNamePrefix: string;
    readonly _cacheName: string;

    _lastCacheCleanupTime = 0;
    _putInCacheSincePurgeCount = 0;

    constructor(client: ProjectNetworkClient) {
        this.client = client;
        this.logger = new ScopedLogger(this.client.config.basePath);
        this._cachesVersionedNamePrefix = `${CachingNetworkClientCachesPrefix}v${CachesVersion}|`;
        this._cacheName = `${this._cachesVersionedNamePrefix}${this.client.config.basePath}`;
    }

    private async _tryOpenCache(): Promise<null | Cache> {
        if ((globalThis as any)['_SKIP_NETWORK_CACHE']) {
            return null;
        }
        if (!caches) {
            return null;
        }
        return caches.open(this._cacheName);
    }

    private async _tryPutInCache(relativeUrl: string, response: Response): Promise<void> {
        if (!caches) {
            return;
        }
        const cache = await this._tryOpenCache();
        if (!cache) {
            return;
        }
        if (caches != null) {
            await this._tryPurgeCache();
        }
        cache.put(relativeUrl, response);
        this._putInCacheSincePurgeCount += 1;
        const cachesDatesStr = localStorage.getItem(this._cacheName);
        const cachesDates: CachesDates = cachesDatesStr ? JSON.parse(cachesDatesStr) : {};
        cachesDates[relativeUrl] = Date.now();
        localStorage.setItem(this._cacheName, JSON.stringify(cachesDates));
        this.logger.debug('put in cache', relativeUrl);
    }

    private async _tryPurgeCache() {
        if (!caches) {
            return;
        }
        if (performance.now() - this._lastCacheCleanupTime < 15_000 || this._putInCacheSincePurgeCount < 50) {
            return;
        }
        this._lastCacheCleanupTime = performance.now();
        this._putInCacheSincePurgeCount = 0;
        const cacheEsimate = await navigator.storage.estimate();

        if (cacheEsimate.usage == undefined || cacheEsimate.quota == undefined) {
            return;
        }
        const usageRatio = cacheEsimate.usage / cacheEsimate.quota;
        if (usageRatio < 0.75 && cacheEsimate.usage < 1024 * 1024 * 1000) {
            return;
        }
        this.logger.info('cache usage estimate', cacheEsimate);
        // purge oldest caches
        const objsPerCacheByDate: [Cache, string, number][] = [];

        const allCachesKeys = await caches.keys();
        for (const cacheName of allCachesKeys) {
            if (!cacheName.startsWith(CachingNetworkClientCachesPrefix)) {
                continue;
            }
            if (!cacheName.startsWith(this._cachesVersionedNamePrefix)) {
                this.logger.debug('deleting old cache', cacheName);
                // old version of cache
                caches.delete(cacheName);
                continue;
            }
            const cache = await caches.open(cacheName);
            const cachesDatesStr = localStorage.getItem(cacheName);
            if (!cachesDatesStr) {
                this.logger.debug('deleting cache without dates', cacheName);
                caches.delete(cacheName);
                continue;
            }
            const cacheDates = JSON.parse(cachesDatesStr) as CachesDates;
            for (const entryKey in cacheDates) {
                const date = cacheDates[entryKey];
                if (date) {
                    objsPerCacheByDate.push([cache, entryKey, date]);
                } else {
                    this.logger.debug('deleting cache entry without date', cacheName, entryKey);
                    cache.delete(entryKey);
                }
            }
        }
        this.logger.debug('objsPerCacheByDate', objsPerCacheByDate);
        objsPerCacheByDate.sort((a, b) => a[2] - b[2]);
        const objsToDelete = objsPerCacheByDate.slice(0, Math.floor(objsPerCacheByDate.length / 2));
        for (const [cache, entryKey] of objsToDelete) {
            this.logger.debug('deleting cache entry', this._cacheName, entryKey);
            cache.delete(entryKey);
        }
        this.logger.info('cache cleanup duration', performance.now() - this._lastCacheCleanupTime);
    }


    async get(relativeUrl: string): Promise<Response> {
        //TODO: downloads queue
        const cache = await this._tryOpenCache();
        if (cache) {
            const cachedResp = await cache.match(relativeUrl);
            if (cachedResp) {
                this.logger.debug('response from cache', relativeUrl);
                return cachedResp;
            }
        }
        const req = await this.client.getRequestFor({relativeUrl, method: 'GET'});
        const resp = await this.client.executeRequest(req);
        if (resp.ok && cache) {
            this._tryPutInCache(relativeUrl, resp.clone());
        }
        return resp;
    }
    
    async put(relativeUrl: string, data: Uint8Array): Promise<Response> {
        const req = await this.client.getRequestFor({relativeUrl, method: 'PUT', data});
        const resp = await this.client.executeRequest(req);
        if (resp.status === 200) {
            const getReponse = new Response(data, {status: resp.status});
            getReponse.headers.set('Content-Type', 'application/octet-stream');
            this._tryPutInCache(relativeUrl, getReponse);
        }
        return resp;
    }

    getFullUrl(relativeUrl: string): string {
        return this.client.getFullUrl(relativeUrl);
    }
}


interface CachesDates {
    [key: string]: number; // utc 
}