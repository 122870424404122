import type { AssetCatalogItemProps, Catalog, CatalogPropertyValue, PropertyGroup } from "bim-ts";
import { CatalogItemsReferenceProperty } from "bim-ts";
import type { ScopedLogger } from "engine-utils-ts";
import { patchConfigProperty } from "./GeneratePanelUiBindings";
import type { PropertyPathType} from "bim-ts";
import { ConfigUtils } from "bim-ts";
interface DefaultAssetsContext {
    notFoundCatalogItems: Set<number>;
    defaultAssets?: Map<string, number[]>;
}

export function setDefaultAssets<T extends PropertyGroup, TContext extends DefaultAssetsContext>(
    config: T, 
    catalog: Catalog, 
    logger: ScopedLogger, 
    context: TContext, 
    skipPaths: PropertyPathType[][],
    onUpdate:(props: T) => void,
    setAllDefaults: boolean = false,
    ){
    let updatedProps = config;
    let haveUpdates = false;
    
    ConfigUtils.traverseByProperties(
        config,
        (prop, path) => {
            if(prop instanceof CatalogItemsReferenceProperty){
                const selectAll = !prop.maxCount && setAllDefaults; 
                if(prop.value.length === 0){
                    const defaultValue = prop.assetsTypes ? getDefaultValues(prop.assetsTypes, context, selectAll) : undefined;
                    if(!defaultValue){
                        return;
                    }
                    const updatedProp = new CatalogItemsReferenceProperty({
                        ...prop,
                        value: defaultValue,
                        defaultValues: defaultValue,
                    });
                    updatedProps = patchConfigProperty(updatedProps, path, updatedProp);
                    haveUpdates = true;
                } else if(prop.defaultValues) {
                    const defaultValuesSet = new Set(prop.defaultValues.map(v => v.id));
                    const filtredValues = prop.value.filter(v => !defaultValuesSet.has(v.id));
                    let updatedProp = new CatalogItemsReferenceProperty({
                        ...prop,
                        value: filtredValues,
                        defaultValues: undefined,
                    });
                    if(prop.defaultValues.some(p => context.notFoundCatalogItems.has(p.id)) && filtredValues.length === 0){
                        const defaultValue = prop.assetsTypes ? getDefaultValues(prop.assetsTypes, context, selectAll) : undefined;
                        updatedProp = new CatalogItemsReferenceProperty({
                            ...prop,
                            value: defaultValue ? defaultValue: [],
                            defaultValues: defaultValue ? defaultValue : undefined,
                        });
                        updatedProps = patchConfigProperty(updatedProps, path, updatedProp);
                        haveUpdates = true;
                    }
                    if(filtredValues.length > 0){
                        updatedProps = patchConfigProperty(updatedProps, path, updatedProp);
                        haveUpdates = true;
                    }
                } else if(prop.value.length > 0){
                    const defaultValue = prop.assetsTypes ? getDefaultValues(prop.assetsTypes, context, selectAll) : undefined;
                    if(!defaultValue){
                        return;
                    }
                    const defaultValuesSet = new Set(defaultValue.map(v=>v.id));
                    const filtredValues = prop.value.filter(v =>!defaultValuesSet.has(v.id));
                    if(filtredValues.length > 0){
                        const updatedProp = new CatalogItemsReferenceProperty({
                            ...prop,
                            value: filtredValues,
                            defaultValues: undefined,
                        });
                        updatedProps = patchConfigProperty(updatedProps, path, updatedProp);
                        haveUpdates = true;
                    }
                } else {
                    logger.error('unexpected option', prop);
                }
            }
        }, 
        skipPaths ?? [], 
    );
    if(haveUpdates){
        onUpdate(updatedProps);
    }
}

function getDefaultValues(types: string[], context: DefaultAssetsContext, setAllDefaults: boolean) : CatalogPropertyValue[] | undefined {
    let catalogItem: CatalogPropertyValue[] | undefined;
    let assets:number[] | undefined;
    for (const type of types) {
        const ids = context.defaultAssets?.get(type);
        if(ids && ids.length > 0){
            assets = ids;
            break;
        }
    }

    if(assets?.length){
        catalogItem = setAllDefaults 
            ? assets.map<CatalogPropertyValue>(a => ({type: 'catalog_item', id: a})) 
            : [{type: 'catalog_item', id: assets[0]}];
    }

    return catalogItem;
}

export function getDefaultAssets(catalog: Catalog){
    const perType = new Map<string, number[]>();
    for (const [id, item] of catalog.catalogItems.readAll()) {

        let label = item.name;
        
        const assetId = item.as<AssetCatalogItemProps>()?.properties?.asset_id?.value ?? 0;
        const isDefaultType = catalog.assets.peekById(assetId)?.source?.type;

        if(!isDefaultType){
            continue;
        }
        const assetInst = catalog.assets.sceneInstancePerAsset.getAssetAsSceneInstance(assetId);
        const assetType = assetInst?.type_identifier ?? '';

        if(assetType){
            const asset = catalog.assets.peekById(assetId);
            const defaultName = catalog.catalogItemsUiLabels.solve(
                item.typeIdentifier,
                item.properties,
            )?.title ?? label;

            label = asset && asset.name.length > 0 ? asset.name : defaultName;
        }
        if(label.toLowerCase().startsWith('generic') || assetType === "mv-wire-spec"){
            const items = perType.get(assetType);
            if(items){
                items.push(id);
            }else {
                perType.set(assetType, [id]);
            }
        }
    }

    return perType;
}