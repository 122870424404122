import type { EventStackFrame, LazyVersioned, Observer} from "engine-utils-ts";
import { Allocated, Deleted, LazyDerived, Updated } from "engine-utils-ts";
import type { EntityId } from "verdata-ts";
import { registerCombinerBoxAssetToCatalogItem } from "../archetypes/CombinerBox";
import { registerFixedTiltAssetToCatalogItem } from "../archetypes/fixed-tilt/FixedTilt";
import { registerInverterAssetToCatalogItem } from "../archetypes/Inverter/Inverter";
import { registerLvWireSpecAssetToCatalogItem } from "../archetypes/LvWireSpec";
import { registerMvWireSpecAssetToCatalogItem } from "../archetypes/MvWireSpec";
import { registerPVModuleAssetToCatalogItem } from "../archetypes/pv-module/PVModule";
import { registerSectionalizingCabinetAssetToCatalogItem } from "../archetypes/SectionalizingCabinet";
import { registerSubstationAssetToCatalogItem } from "../archetypes/substation/Substation";
import { registerTrackerAssetToCatalogItem } from "../trackers/Tracker";
import { registerTransformerAssetToCatalogItem } from "../archetypes/transformer/Transformer";
import { NumberProperty } from "../properties/PrimitiveProps";
import { EntitiesBase } from "../collections/EntitiesBase";
import { EntitiesLazyLists } from "../collections/EntitiesLazyLists";
import type { AssetCollection, AssetId, AssetIdType } from "./AssetCollection";
import { AssetCatalogItemTypeIdentifier, LvWireSpecCatalogItemTypeIdent, MvWireSpecCatalogItemTypeIdent } from "./catalog-subtypes";
import type { AssetRelatedCatalogItem } from "./catalog-subtypes/AssetRelatedCatalogItem";
import { CatalogItem } from "./CatalogItem";
import { registerTrackerFrameAssetToCatalogItem } from "../archetypes/TrackerFrame";
import { registerEquipmentCommonAssetToCatalogItem } from "src/archetypes/EquipmentCommon";

export enum CatalogItemIdType {
    Default = -1,
}
export type CatalogItemId = EntityId<CatalogItemIdType>;

type AssetBasedCatalogItemCreator = (id: AssetId) => CatalogItem;

export class AssetBasedCatalogItemCreators {
    private readonly creators = new Map<string, AssetBasedCatalogItemCreator>()

    register(
        underlyingSceneInstanceTypeIdentifier: string,
        creator: AssetBasedCatalogItemCreator,
    ) {
        this.creators.set(
            underlyingSceneInstanceTypeIdentifier,
            creator,
        )
    }

    get(underlyingSceneInstanceTypeIdentifier: string) {
        return this.creators.get(underlyingSceneInstanceTypeIdentifier);
    }

}

export class CatalogItemCollection
    extends EntitiesBase<CatalogItem, CatalogItemId> {

    private _lazyLists: EntitiesLazyLists<CatalogItem, CatalogItemId, number>;

    public readonly catalogItemIdPerAssetId: LazyVersioned<Map<AssetIdType, CatalogItemId>>;

    public readonly fromAsset = new AssetBasedCatalogItemCreators();

    private assets: AssetCollection;
    private assetsObserver: Observer;


    constructor(assets: AssetCollection) {
        super({
            identifier: 'catalogItemCollection',
            idsType: CatalogItemIdType.Default,
            T_Constructor: CatalogItem,
        });
        this._lazyLists = new EntitiesLazyLists({
            entities: this,
            permanentTypeExtractor: (x) => x.typeIdentifier,
        });
        this.catalogItemIdPerAssetId = LazyDerived.new3(
            'catalogItemIdPerAssetId',
            [],
            [
                this.getLazyListOf(AssetCatalogItemTypeIdentifier),
                this.getLazyListOf(LvWireSpecCatalogItemTypeIdent),
                this.getLazyListOf(MvWireSpecCatalogItemTypeIdent),
            ],
            ([assets, lvWireSpecs, mvWireSpecs]) => {
                const map = new Map<AssetIdType, CatalogItemIdType>();
                for (const [id, item] of [...assets, ...lvWireSpecs, ...mvWireSpecs]) {
                    const props = item.as<AssetRelatedCatalogItem>().properties;
                    const assetId = props.asset_id.value;
                    map.set(assetId, id);
                }
                return map;
            }
        );

        registerLvWireSpecAssetToCatalogItem(this.fromAsset);
        registerMvWireSpecAssetToCatalogItem(this.fromAsset);
        registerInverterAssetToCatalogItem(this.fromAsset);
        registerSubstationAssetToCatalogItem(this.fromAsset);
        registerCombinerBoxAssetToCatalogItem(this.fromAsset);
        registerPVModuleAssetToCatalogItem(this.fromAsset);
        registerTrackerAssetToCatalogItem(this.fromAsset);
        registerSectionalizingCabinetAssetToCatalogItem(this.fromAsset);
        registerTransformerAssetToCatalogItem(this.fromAsset);
        registerFixedTiltAssetToCatalogItem(this.fromAsset);
        registerTrackerFrameAssetToCatalogItem(this.fromAsset);
        registerEquipmentCommonAssetToCatalogItem('any-tracker', this.fromAsset)

        this.assets = assets;

        this.assetsObserver =  assets.sceneInstancePerAsset.assetIdToSceneInstanceId
            .updatesStream.subscribe({
                onNext: (updates) => {
                    if (updates instanceof Updated) {
                    } else if (updates instanceof Deleted) {
                        for (const id of updates.ids) {
                            const catalogItemId =
                                this.catalogItemIdPerAssetId.poll().get(id);
                            if (!catalogItemId) {
                                console.error('no catalog item found for deleted asset')
                                continue;
                            }
                            this.delete([catalogItemId]);
                        }
                    } else if (updates instanceof Allocated) {
                        for (const id of updates.ids) {
                            const catalogItemId =
                                this.catalogItemIdPerAssetId.poll().get(id);
                            // asset already exists as catalog item
                            if (catalogItemId !== undefined) {
                                continue;
                            }
                            // create catalog item
                            const assetSi = this.assets.sceneInstancePerAsset
                                .getAssetAsSceneInstance(id);
                            if (!assetSi) {
                                console.error('no si found for newly created asset');
                                continue;
                            }
                            const newCatalogItem = this.fromAsset.get(
                                assetSi.type_identifier,
                            )?.(id);
                            if (!newCatalogItem) {
                                console.error('asset is not supported');
                                continue;
                            }
                            this.allocate([
                                [this.idsProvider.reserveNewId(), newCatalogItem]
                            ])
                        }
                    }
                }
            });
    }
    dispose() {
        super.dispose();
        this._lazyLists.dispose();
        this.assetsObserver.dispose();
    }
    checkForErrors(_t: CatalogItem, _errors: string[]): void {
        return;
    }
    getLazyListOf(type_identifier: string): LazyVersioned<[CatalogItemId, CatalogItem][]> {
        return this._lazyLists.getLazyListOf({ type_identifier: type_identifier });
    }
	delete(
        idsToDelete: CatalogItemId[],
        e?: Partial<EventStackFrame>
    ): [CatalogItemId, Readonly<CatalogItem>][] {
        // find all assetIds to remove
        const assetIdsToRemove = [];
        for (const id of idsToDelete) {
            const item = this.peekById(id);
            if (!item) {
                continue;
            }
            const assetId = item.properties['asset_id'];
            if (assetId instanceof NumberProperty) {
                assetIdsToRemove.push(assetId.value);
            }
        }
        // call super
        const result = super.delete(idsToDelete, e);

        // removing related assets
        if (assetIdsToRemove.length) {
            this.assets.delete(assetIdsToRemove)
        }

        return result
    }


}
