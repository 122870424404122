// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { Vector3T } from './vector3.js';
import { Vector3 } from './vector3.js';


export class Cube implements flatbuffers.IUnpackableObject<CubeT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Cube {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsCube(bb:flatbuffers.ByteBuffer, obj?:Cube):Cube {
  return (obj || new Cube()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsCube(bb:flatbuffers.ByteBuffer, obj?:Cube):Cube {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Cube()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

size(obj?:Vector3):Vector3|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new Vector3()).__init(this.bb_pos + offset, this.bb!) : null;
}

center(obj?:Vector3):Vector3|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new Vector3()).__init(this.bb_pos + offset, this.bb!) : null;
}

static startCube(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addSize(builder:flatbuffers.Builder, sizeOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, sizeOffset, 0);
}

static addCenter(builder:flatbuffers.Builder, centerOffset:flatbuffers.Offset) {
  builder.addFieldStruct(1, centerOffset, 0);
}

static endCube(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): CubeT {
  return new CubeT(
    (this.size() !== null ? this.size()!.unpack() : null),
    (this.center() !== null ? this.center()!.unpack() : null)
  );
}


unpackTo(_o: CubeT): void {
  _o.size = (this.size() !== null ? this.size()!.unpack() : null);
  _o.center = (this.center() !== null ? this.center()!.unpack() : null);
}
}

export class CubeT implements flatbuffers.IGeneratedObject {
constructor(
  public size: Vector3T|null = null,
  public center: Vector3T|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  Cube.startCube(builder);
  Cube.addSize(builder, (this.size !== null ? this.size!.pack(builder) : 0));
  Cube.addCenter(builder, (this.center !== null ? this.center!.pack(builder) : 0));

  return Cube.endCube(builder);
}
}
