import Utils from '../utils/Utils';
import type { WebGLRenderTarget, Camera} from '../3rdParty/three';
import type { RenderJobsMerged } from '../geometries/RenderJobsMerged';
import { DrawcallsInfo } from './DrawcallsInfo';
import { LegacyLogger } from 'engine-utils-ts';
import type { RendererExt } from './RendererExt';
import { EmptyScene } from './RendererExt';
import type { GpuResources } from './GpuResources';
import type { Disposable } from 'engine-utils-ts';


export type OptionalRenderTarget = WebGLRenderTarget | null;

export abstract class Pass {
	
	enabled: boolean = true;
	
	readonly drawInfo: DrawcallsInfo = new DrawcallsInfo();

	readonly toDispose: Disposable[] = [];
	
	setSize(_width: number, _height: number) {
	}
	
	abstract render(
		_renderer: RendererExt,
		writeBuffer: OptionalRenderTarget,
		readBuffer: OptionalRenderTarget,
		_camera: Camera
	): number; // return units rendered

	dispose() {
		Utils.disposeArrayObjects(this.toDispose);
	}

	static initRenderState(renderer: RendererExt, writeBuffer: OptionalRenderTarget, camera: Camera) {
		renderer.setRenderTarget(writeBuffer);
		renderer.setupCurrentRenderStateWithLights(EmptyScene, camera);
	}

	static resetRenderState(renderer: RendererExt) {
		renderer.resetRenderState();
	}
	
	static renderFullSceneStd(
		renderer: RendererExt, gpuResources: GpuResources, writeBuffer: WebGLRenderTarget, camera: Camera, renderList: RenderJobsMerged[]
	) {
		Pass.renderSceneStd(renderer, gpuResources, writeBuffer, camera, renderList, 0, renderList.length);
	}

	static renderSceneStd(
		renderer: RendererExt, gpuResources: GpuResources,
		writeBuffer: WebGLRenderTarget, camera: Camera,
		renderList: RenderJobsMerged[], from: number, to: number
	) {
		const materials = gpuResources.materials;
		LegacyLogger.debugAssert(renderList.length >= to, 'renderlist upper bound is valid');
		Pass.initRenderState(renderer, writeBuffer, camera);
		for (let i = from; i < to; ++i){
			const obj = renderList[i];
			const mat = materials.get(obj.materialId);
			if (mat) {
				// renderer.renderBufferDirect(camera, obj.geoId, mat, obj, false);
			}
		}
		Pass.resetRenderState(renderer);
	}

	static renderFullSceneEdges(
		renderer: RendererExt, gpuResources: GpuResources, writeBuffer: WebGLRenderTarget, camera: Camera, renderList: RenderJobsMerged[]
	) {
		Pass.renderSceneEdges(renderer, gpuResources, writeBuffer, camera, renderList, 0, renderList.length);
	}

	static renderSceneEdges(
		renderer: RendererExt, gpuResources: GpuResources,
		writeBuffer: WebGLRenderTarget, camera: Camera,
		renderList: RenderJobsMerged[], from: number, to: number
	) {
		const materials = gpuResources.materials;
		LegacyLogger.debugAssert(renderList.length >= to, 'renderlist upper bound is valid');
		Pass.initRenderState(renderer, writeBuffer, camera);
		for (let i = from; i < to; ++i){
			const obj = renderList[i];
			const mat = materials.get(obj.materialId);
			if (mat && mat.edgeMaterial) {
				// renderer.renderJob(camera, obj.geoId, mat.edgeMaterial, obj, true);
			}
		}
		renderer.resetRenderState();
	}
};

