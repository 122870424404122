import { UnitsMapper } from "../UnitsMapper";
import { createKeyPropertiesGroupFormatters } from '../runtime/solar/KeyPropertiesGroupFormatters';
import type { Observer} from "engine-utils-ts";
import { Allocated, DefaultMap, Deleted, Updated } from "engine-utils-ts";
import type { SceneInstance } from "../scene/SceneInstances";
import type { AssetId } from "./AssetCollection";
import { BimProperty } from "../bimDescriptions/BimProperty";
import type { SceneInstancePerAsset } from "./SceneInstancePerAsset";
import type { PropertiesCollection } from "../bimDescriptions/PropertiesCollection";
import type { PropsGroupBase } from '../properties/Props';


export class AssetsMatcher {
    private keyPropertiesGroupFormatters = createKeyPropertiesGroupFormatters(new UnitsMapper());

    private hashPerAsset = new Map<AssetId, string>();
    private assetsPerHash = new DefaultMap<string, Set<AssetId>>(() => new Set());

    private idMapperObserver: Observer;
    private bimObserver: Observer;

    public sceneInstancePerAsset: SceneInstancePerAsset

    constructor(sceneInstancePerAsset: SceneInstancePerAsset) {
        this.sceneInstancePerAsset = sceneInstancePerAsset;
        this.idMapperObserver = sceneInstancePerAsset.assetIdToSceneInstanceId
            .updatesStream.subscribe({
                onNext: (updates) => {
                    if (updates instanceof Updated) {
                        for (const id of updates.ids) {
                            this.assetUpdated(id);
                        }
                    }
                    if (updates instanceof Deleted) {
                        for (const id of updates.ids) {
                            this.assetDeleted(id);
                        }
                    }
                    if (updates instanceof Allocated) {
                        for (const id of updates.ids) {
                            this.assetAllocated(id);
                        }
                    }
                }
            })
        const bim = sceneInstancePerAsset.bim;
        this.bimObserver = bim.instances.updatesStream.subscribe({
            onNext: (updates) => {
                if (updates instanceof Updated) {
                    for (const id of updates.ids) {
                        const si = bim.instances.peekById(id);
                        if (!si) {
                            continue;
                        }
                        const assetId = si.properties.get(
                            BimProperty.MergedPath(['_meta', 'assetId'])
                        );
                        if (!assetId) {
                            continue;
                        }
                        this.assetUpdated(assetId.asNumber());
                    }
                }
            }
        })
    }

    dispoce() {
        this.idMapperObserver.dispose();
        this.bimObserver.dispose();
    }

    private assetDeleted(id: AssetId) {
        this.disposeAsset(id);
    }

    private assetAllocated(id: AssetId) {
        this.addAsset(id);
    }

    private assetUpdated(id: AssetId) {
        this.disposeAsset(id);
        this.addAsset(id);
    }

    private disposeAsset(id: AssetId) {
        const hash = this.hashPerAsset.get(id);
        if (hash !== undefined) {
            const set = this.assetsPerHash.getOrCreate(hash);
            set.delete(id);
            if (set.size === 0) {
                this.assetsPerHash.delete(hash);
            }
            this.hashPerAsset.delete(id);
        }
    }

    private addAsset(id: AssetId) {
        const assetSi = this.sceneInstancePerAsset.getAssetAsSceneInstance(id);
        if (!assetSi) {
            return;
        }
        const hash = this.keyPropertiesGroupFormatters.fullKeyPropsStr(
          assetSi.type_identifier,
          assetSi.properties,
          assetSi.props
        );
        if (hash === null) {
            return;
        }
        this.hashPerAsset.set(id, hash);
        this.assetsPerHash.getOrCreate(hash).add(id);
    }

    matchSceneInstanceWithAsset(si: SceneInstance): AssetId | null {
        return this.matchPropertiesWithAsset(si.type_identifier, si.properties, si.props);
    }
    matchPropertiesWithAsset(type_identifier: string, properties: PropertiesCollection, props: PropsGroupBase) {
        const hash = this.keyPropertiesGroupFormatters.fullKeyPropsStr(
          type_identifier,
          properties,
          props,
        );
        if (hash === null) {
            return null;
        }
        const assetId = Array.from(this.assetsPerHash.getOrCreate(hash)).at(0);
        return assetId ?? null;
    }

}
