import type { SceneInstance } from 'bim-ts';
import { IterUtils } from 'engine-utils-ts';
import { Vector2 } from 'math-ts';

import type { UniformsFlat } from '../composer/DynamicUniforms';
import { GeoSprite } from '../geometries/EngineGeoSprite';
import type { EngineFullGraphicsSettings } from '../GraphicsSettingsFull';
import { EngineMaterialId } from '../pools/EngineMaterialId';
import { InObjFullId, SelfIdZero } from '../scene/EngineSceneIds';
import type { ESOHandle } from '../scene/ESOsCollection';
import { newLodGroupLocalIdent } from '../scene/LodGroups';
import type {
	EngineSubmeshDescription, RenderJobUpdater} from '../scene/Submeshes2';
import { LodMask
} from '../scene/Submeshes2';
import { ShaderFlags } from '../shaders/ShaderFlags';
import { ESO } from './ESO';
import { ESOsHandlerBase } from './ESOsHandlerBase';
import type { SubmeshesCreationOutput, SubmeshesCreationResources} from './ESSO';
import {
	ESSO
} from './ESSO';
import { SharedSelectHighlightJobUpdater } from './ESSO_HighlightUpdaters';

export class ESO_Empty extends ESO {
	constructor(
		sceneInstanceRef: Readonly<SceneInstance>,
	) {
		super(sceneInstanceRef);
	}
}

const DefaulEmptyObjSprite = new GeoSprite(new Vector2(10, 10), 10);

export class ESSO_Empty extends ESSO<{}> {
	createSubmeshes(resoures: SubmeshesCreationResources, output: SubmeshesCreationOutput): void {
		output.submeshes.push({
			id: this.id,
			lodMask: LodMask.All,
			lodGroupLocalIdent: newLodGroupLocalIdent(0, 0),
			subObjectRef: this,
			descr: {
				geoId: resoures.geometries.spriteGeometries.allocateOrReferenceSingle(DefaulEmptyObjSprite)!,
				materialId: EngineMaterialId.Sprite,
				localTransforms: null,
				mainRenderJobUpdater: new BasicPointSpriteJobUpdater(),
				overlayRenderJobUpdater: SharedSelectHighlightJobUpdater
			}
		});
	}
}

const _emptyRepr = Object.freeze({});

export class ESO_EmptyHandler extends ESOsHandlerBase<ESO_Empty> {

	tryCreateESO(instance: SceneInstance): ESO_Empty | undefined {
		return new ESO_Empty(instance);
	}
	esosTypesToHandle(): Iterable<new (...args: any[]) => ESO_Empty> {
		return [ESO_Empty];
	}

	createSubObjectsFor(objectsToRealloc: [ESOHandle, ESO_Empty][]): Iterable<[ESOHandle, ESSO<any>[]]> {
		return IterUtils.iterMap(
			objectsToRealloc,
			([handle, obj]) => {
			const subObj = new ESSO_Empty(obj, InObjFullId.new(handle, SelfIdZero), _emptyRepr, null);
			return [handle, [subObj]];
		});
	}
}

export class BasicPointSpriteJobUpdater implements RenderJobUpdater {
	readonly additionalFlags: ShaderFlags;

	constructor(additionalFlags?: ShaderFlags) {
		this.additionalFlags = additionalFlags ?? ShaderFlags.None;
	}

	updaterRenderJob(
		submeshDescription: Readonly<EngineSubmeshDescription<{}>>,
		renderSettings: Readonly<EngineFullGraphicsSettings>,
		output: { flags: ShaderFlags; materialId: EngineMaterialId; uniforms: UniformsFlat; }
	): void {
		if (submeshDescription.subObjectRef.isHidden) {
			return;
		}
		output.materialId = submeshDescription.localDescr.materialId;
		output.flags |= this.additionalFlags;
	}
}

