import type { Bim, VirtualPropertyBase } from 'bim-ts';
import type { Yield} from 'engine-utils-ts';
import { Failure, LazyDerivedAsync, Success, type ResultAsync, type VersionedValue } from 'engine-utils-ts';


export class AsyncPropsUiRetriver implements VersionedValue {

    _version: number = 0;

    _propsCalculators: Map<VirtualPropertyBase<any, any>, PropertyCalculation> = new Map();

    _requestedPropsThisFrame: Set<VirtualPropertyBase<any, any>> = new Set();

    constructor(
        readonly bim: Bim
    ) {

    }

    version(): number {
        for (const calc of this._propsCalculators.values()) {
            calc.producer.poll();
            const version = calc.producer.version();
            if (calc.prevVersion !== version) {
                calc.prevVersion = version;
                this._version += 1;
                break;
            }
        }
        return this._version;
    }

    disposeUnused() {
        for (const [p, {producer}] of this._propsCalculators) {
            if (!this._requestedPropsThisFrame.has(p)) {
                this._propsCalculators.delete(p);
                producer.dispose();
            }
        }
        this._requestedPropsThisFrame.clear();
    }

    getValueOf<T extends Object>(p: VirtualPropertyBase<T, any>, fullpath: (string|number)[]): ResultAsync<T> {
        this._requestedPropsThisFrame.add(p);
        let calculation = this._propsCalculators.get(p);
        const self = this;
        if (calculation === undefined) {
            const producer = LazyDerivedAsync.new0(
                'virtual-property-seleciton-calc::' + fullpath.join('|'),
                [],
                function* (): Generator<Yield, ResultAsync<T>> {
                    const result = yield* self.bim.virtualPropsRuntime.acquireTemporaryValueOf(p);
                    if (result instanceof Failure) {
                        return result;
                    }
                    return new Success(result.value);
                }
            );
            calculation = {producer, prevVersion: -1};
            this._propsCalculators.set(p, calculation);
        };
        const result = calculation.producer.poll();
        const version = calculation.producer.version();

        // do not invalidate version on retreival
        // only increment self version inside of version call
        // to avoid unnecessary ui rebuilds
        calculation.prevVersion = version;
        
        return result;
    }
}


interface PropertyCalculation {
    producer: LazyDerivedAsync<any>;
    prevVersion: number;
}
