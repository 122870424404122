import { IterUtils, LegacyLogger } from 'engine-utils-ts';
import { Vector2, Vector3 } from 'math-ts';

import type { Bim } from '../../Bim';
import { BimProperty } from '../../bimDescriptions/BimProperty';
import { ExtrudedPolygonGeometry } from '../../geometries/ExtrudedPolygonGeometries';
import { BasicAnalyticalRepresentation } from '../../representation/Representations';
import type { IdBimScene } from '../../scene/SceneInstances';

export enum BoundaryType {
    Include,
    Exclude
}

export interface Boundary2DDescription {
    bimObjectId: IdBimScene,
    pointsWorldSpace: Vector2[];
    boundaryType: BoundaryType;
    minElevation: number,
}

const BoundaryTypePropPath = BimProperty.MergedPath(['boundary', 'boundary_type']);

export function extractBoundariesFromBim(bim: Bim): Boundary2DDescription[] {

    const sceneBoundaryObjects = bim.instances.filter(o => o.type_identifier == 'boundary');

    const boundaries: Boundary2DDescription[] = IterUtils.filterMap(sceneBoundaryObjects, ([id, obj]) => {

        if (!(obj.representationAnalytical instanceof BasicAnalyticalRepresentation)) {
            LegacyLogger.deferredError('expected BasicAnalyticalRepresentation in boundary object', id);
            return;
        }
        const geometry = bim.allBimGeometries.peekById(obj.representationAnalytical.geometryId);
        if (!(geometry instanceof ExtrudedPolygonGeometry)) {
            LegacyLogger.deferredError('expected ExtrudedPolygonGeometry in boundary object', id);
            return;
        }
        let minElevation = Number.MAX_SAFE_INTEGER;
        const pointsWorldSpace = geometry.outerShell.points.map(p => {
            const p3 = new Vector3(p.x, p.y, geometry.topElevation);
            p3.applyMatrix4(obj.worldMatrix);
            minElevation = Math.min(p3.z, minElevation);
            return new Vector2(p3.x, p3.y);
        });

        const boundaryTypeProp = obj.properties.get(BoundaryTypePropPath);

        let boundaryType: BoundaryType;
        if (boundaryTypeProp != undefined) {
            boundaryType = boundaryTypeProp.value == 'exclude' ? BoundaryType.Exclude: BoundaryType.Include;
        } else {
            LegacyLogger.deferredWarn(`boundary type property absent, set default`, id);
            boundaryType = BoundaryType.Include;
        }

        return {
            bimObjectId: id,
            pointsWorldSpace: pointsWorldSpace,
            boundaryType,
            minElevation,
        }
    });

    return boundaries;
}
