<script lang="ts">
import type { Action } from "../actions/Action";
import ActionEditor from '../actions/ActionEditor.svelte';

export let actions: Action[];
export let close: () => void;

let selectedAction: Action | undefined;
let actionEditorMode = false;

function addNewTemplate() {
    actionEditorMode = true;
    selectedAction = undefined;
}

function exitActionEditorMode(newAction?: Action) {
    actionEditorMode = false;
    selectedAction = undefined;
    if (newAction) {
        actions.push(newAction);
    }
    actions = actions;
}

function removeAction(toRemove: Action) {
    actions = actions.filter(x => x !== toRemove);
}
function moveDown(toMove: Action) {
    const idx = actions.indexOf(toMove);
    removeAction(toMove);
    actions.splice(Math.min(idx + 1, actions.length), 0, toMove);
    actions = actions;
}
function moveUp(toMove: Action) {
    const idx = actions.indexOf(toMove);
    removeAction(toMove);
    actions.splice(Math.max(idx - 1, 0), 0, toMove);
    actions = actions;
}


function enterActionEditorMode(toEdit: Action) {
     selectedAction = toEdit;
     actionEditorMode = true; 
}

function download(content: string, fileName: string, contentType: string) {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
}

let disableFromN = 0;
function disableFrom() {
  for (let i = 0; i < actions.length; i++) {
    if (i < disableFromN - 1) {
      actions[i].disable = false;
    } else {
      actions[i].disable = true;
    }
  }
  disableFromN = 0;
  actions = actions;
}

</script>

{#if actionEditorMode}
    <ActionEditor
        action={selectedAction}
        exit={exitActionEditorMode}
    />
{:else}
    <a class="basic-interact" on:click={close}>{'<'}</a>
    <h3>Actions editor</h3>
    <p>disable actions from idx n</p>
    <input bind:value={disableFromN} /><button on:click={disableFrom}>disable</button>
    <h3>action:</h3>
    <p>(ag grid request, custom values, references, overrides)</p>
    <ol>
        {#each actions as action, idx}
            <li
                class="basic-interact"
                on:click={() => enterActionEditorMode(action)}
                class:disabled={action.disable}
            >
                {action.name}({action.type.slice(0, 8)})&nbsp;&nbsp;
                <span
                    class="basic-interact"
                    on:click|stopPropagation={() => moveUp(action)}
                >|up</span>
                <span
                    class="basic-interact"
                    on:click|stopPropagation={() => moveDown(action)}
                >|down</span>
                <span
                    class="basic-interact"
                    on:click|stopPropagation={() => removeAction(action)}
                >|del</span>
            </li>
        {/each}
    </ol>
    <span class="basic-interact" on:click={addNewTemplate}>+add</span>
{/if}

<style lang="scss">
.disabled {
  opacity: 0.7
}
</style>
