import { NumberProperty } from "../../properties/PrimitiveProps";
import type { PropertyGroup } from "../../properties/PropertyGroup";
import { KrMath } from "math-ts";
import type { UnitsMapper } from "../../UnitsMapper";
import type { Catalog } from "../Catalog";
import { CatalogItem } from "../CatalogItem";
import type { CatalogItemsUiLabels } from "../CatalogItemsUiLabels";
import { CatalogSource } from "../Source";

export const RoadCatalogItemTypeIdent = 'road';

export const roadCatalogItemAreaUnit = NumberProperty.new({
    value: 1,
    unit: 'ft2',
});

export interface RoadCatalogItemProps extends PropertyGroup {
    width: NumberProperty;
    price_per_area: NumberProperty;
}

export function registerRoadCatalogItemUiLabels(
    labelsProvider: CatalogItemsUiLabels,
    unitsMapper: UnitsMapper
) {
    labelsProvider.register<RoadCatalogItemProps>(
        RoadCatalogItemTypeIdent,
        (road) => {
            const title = `${road.width.valueUnitUiString(unitsMapper)} width road`

            const rootGroup = [RoadCatalogItemTypeIdent]

            const price = road.price_per_area.valueUnitUiString(unitsMapper) +
                '/' + (roadCatalogItemAreaUnit.valueUnitUiString(unitsMapper));
            return {
                title,
                price,
                group: [rootGroup]
            }
        }
    );
}


export function importDefaultRoads(catalog: Catalog) {
    const roads = catalog.catalogItems.filter(x => x.typeIdentifier === RoadCatalogItemTypeIdent);
    const roadPerFtWidth = new Map<number, CatalogItem>()
    for (const [_id, road] of roads) {
        roadPerFtWidth.set(
            KrMath.roundTo(road.as<RoadCatalogItemProps>().properties.width.as('ft'), 0.1),
            road
        );
    }
    for (let width = 10; width <= 25; width += 2) {
        if (roadPerFtWidth.has(width)) {
            // skip
            continue;
        }
        // create
        const road = new CatalogItem<RoadCatalogItemProps>(
            undefined,
            RoadCatalogItemTypeIdent,
            {
                width: NumberProperty.new({
                    value: width,
                    unit: 'ft',
                    range: [10, 30],
                    step: 1,
                    description: 'road width',
                    isReadonly: true,
                }),
                price_per_area: NumberProperty.new({
                    value: 0,
                    unit: 'usd',
                    range: [0, 100000],
                    step: 0.1,
                    description: 'price per unit of area',
                })

            },
            new CatalogSource('default'),
        );
        catalog.catalogItems.allocate([[catalog.catalogItems.reserveNewId(), road ]])
    }
}
