import { type ObservableObject } from "engine-utils-ts";
import { type UiBindings, PUI_PropertyNodeBool } from "ui-bindings";


export class AnnotationsSettings {
    showAnnotations: boolean = true;
}

export class AnnotationsSettingsUiBindings {
    static addBindings(uiBindings: UiBindings, settings: ObservableObject<AnnotationsSettings>) {
        uiBindings.addSetting({
            name: ['View', 'Show annotations'],
            priority: 6,
            divider: true,
            property: new PUI_PropertyNodeBool({
				name: "Show annotations",
                value: settings.poll().showAnnotations,
                onChange: (newValue) => {
                    settings.applyPatch({
						patch: { showAnnotations: newValue }
					})
                },
			}),
        });
    }
}