import type { Bim, IdBimScene, Catalog } from 'bim-ts';
import { SceneInstances } from 'bim-ts';
import { EngineControlsMode } from '../EngineConsts';
import type {
    LazyVersioned,
    ObservableObject, ProjectNetworkClient, ScopedLogger, TasksRunner} from 'engine-utils-ts';
import type { Aabb, Matrix4} from 'math-ts';

import type { ClipBox } from '../clipbox/ClipBox';
import type { GpuResources } from '../composer/GpuResources';
import type { EngineControlsState } from '../EngineControlsState';
import { AllEngineGeometries } from '../geometries/AllEngineGeometries';
import { GpuUploadUrgency } from '../geometries/GpuGeometries';
import type { GlobalUniforms } from '../materials/GlobalUniforms';
import { BimInstancesSyncer } from './BimInstancesSyncer';
import { SubmeshesRaycasts } from './SubmeshesRaycasts';
import type { FrustumExt } from '../structs/FrustumExt';
import type { TextGeometries } from '../text/TextGeometry';
import type { EngineLegacyUiUnits } from '../EngineLegacyUiUnits';
import { Submeshes2 } from '../scene/Submeshes2';
import { EngineImages } from '../materials/EngineImages';
import { EngineStdMaterials } from '../materials/EngineStdMaterials';
import { ESOsCollection } from './ESOsCollection';
import { EngineAnalytPolylinesGeosSynced } from '../geometries/EngineGeoAnalytPolyline';
import { EngineCubeGeosSynced } from '../geometries/EngineGeoCube';
import { EngineExtrPolygonGeosSynced } from '../geometries/EngineGeoExtrudedPolygon';
import { EnginePolylinesGeosSynced } from '../geometries/EngineGeoPolyline';
import { EngineTriGeosSynced } from '../geometries/EngineGeoTriangle';
import { EngineSpriteGeometries } from '../geometries/EngineGeoSprite';
import { EnginePlaneGeometries } from '../geometries/EngineGeoPlane';
import { EngineTerrainGeosIrregSynced } from '../geometries/EngineGeoTerrainIrregular';
import { EngineResourcesGC } from './EngineResourcesGC';
import type { EngineFullGraphicsSettings } from '../GraphicsSettingsFull';
import { EngineTerrainGeosRegSynced } from '../geometries/EngineGeoTerrainRegular';
import { EngineTextBlockGeometries } from '../geometries/EngineGeoTextBlock';
import type { RuntimeSystemUiDescription } from 'ui-bindings';
import { BimAnnotationsSyncer } from '../annotations/BimAnnotationsSyncer';
import type { AnnotationsSettings } from '../annotations/AnnotationsSettingsUiBindings';
import type { TrackersPilesMarkupSettings } from 'src/TrackersPilesMarkupSettingsUiBindings';
import { EngineTrackerPileBillboardGeometries } from 'src/geometries/EngineGeoTrackerPileBillboard';


export class EngineScene {

    readonly gc: EngineResourcesGC;

    readonly bim: Bim;

    readonly catalog: Catalog;

	readonly bimInstancesSyncer: BimInstancesSyncer;
    readonly bimAnnotationsSyncer: BimAnnotationsSyncer;

	readonly esos: ESOsCollection;

    readonly submeshes: Submeshes2;
	readonly submeshesRaycasts: SubmeshesRaycasts;

    readonly engineGeometries: AllEngineGeometries;

    readonly stdMaterials: EngineStdMaterials;
    readonly engineImages: EngineImages;

	_controlsState: ObservableObject<EngineControlsState>;

    constructor({
        logger, network, bim, gpuResources, clipbox, graphicsSettings, tasksRunner, controlsState, catalog, annotationsSettings, trackersPilesMarkupSettings
    }: {
        logger: ScopedLogger,
        network: ProjectNetworkClient,
        bim: Bim,
        catalog: Catalog,
        gpuResources: GpuResources,
        clipbox: ClipBox,
        graphicsSettings: LazyVersioned<EngineFullGraphicsSettings>,
        uiUnits: EngineLegacyUiUnits,
        textGeometries: TextGeometries,
        globalUniforms: GlobalUniforms,
        frustum: FrustumExt,
		tasksRunner: TasksRunner,
        controlsState: ObservableObject<EngineControlsState>,
        annotationsSettings: ObservableObject<AnnotationsSettings>,
        trackersPilesMarkupSettings: ObservableObject<TrackersPilesMarkupSettings>
    }) {
        this.gc = new EngineResourcesGC(logger);
        this.bim = bim;
        this.catalog = catalog;

		this.engineImages = new EngineImages(logger, bim.bimImages, network);
        this.stdMaterials = new EngineStdMaterials(logger, bim.bimMaterials);

		this.engineGeometries = new AllEngineGeometries({
            logger: logger,
            gc: this.gc,
            gpuGeos: gpuResources.geometries,
            subtypes: {
                planeGeometries: new EnginePlaneGeometries(logger),
				polylineGeometries: new EnginePolylinesGeosSynced(logger, bim.polylineGeometries),
				analytPolylines: new EngineAnalytPolylinesGeosSynced(logger, bim.polylineGeometries),
				cubeGeometries: new EngineCubeGeosSynced(logger, bim.cubeGeometries),
				extrudedPolygonGeometries: new EngineExtrPolygonGeosSynced(logger, bim.extrudedPolygonGeometries),
				triGeometries: new EngineTriGeosSynced(logger, bim.triGeometries),
				spriteGeometries : new EngineSpriteGeometries(logger),
                trackerPileBillboardGeometries: new EngineTrackerPileBillboardGeometries(logger),
                terrainIrregular: new EngineTerrainGeosIrregSynced(logger, bim.irregularHeightmapGeometries),
                terrainRegular: new EngineTerrainGeosRegSynced(logger, bim.regularHeightmapGeometries),
                textAnnotations: new EngineTextBlockGeometries(logger)
			},
        });
		this.submeshes = new Submeshes2(
            logger,
            this.gc,
            bim.allBimGeometries,
            this.engineGeometries,
            this.stdMaterials,
            graphicsSettings,
            clipbox
        );

		this.esos = new ESOsCollection(
			logger,
			bim.undoStack!, clipbox, bim,
			this.submeshes, this.engineGeometries, this.engineImages,
			tasksRunner,
            graphicsSettings,
            annotationsSettings,
            trackersPilesMarkupSettings
		);

		this.submeshesRaycasts = new SubmeshesRaycasts(
			this.submeshes,
			this.esos,
			clipbox
		);


		this.bimInstancesSyncer = new BimInstancesSyncer(
			logger,
			bim,
			this.esos
		);
        this.bimAnnotationsSyncer = new BimAnnotationsSyncer(
			logger,
			bim,
			this.esos,
            annotationsSettings
		);

		this._controlsState = controlsState;
    }

    dispose() {
        this.bimInstancesSyncer.dispose();
        this.bimAnnotationsSyncer.dispose();
        this.esos.dispose();
        this.engineGeometries.dispose();
        this.stdMaterials.dispose();
        this.engineImages.dispose();
        this.submeshes.dispose();
	}

    anyPendingUpdates(): boolean {
        if (this.bimInstancesSyncer.anyPendingUpdates() || this.submeshes.anySubmeshesArentReady()) {
            return true;
        }
        for (const esosHandler of this.esos.esosHandlers) {
            if (!esosHandler.isSynced()) {
                return true;
            }
        }
        return false;
    }


    syncWithBim() {
		this.engineGeometries.sync();
        this.engineImages.sync();
        this.stdMaterials.sync();
        this.bimInstancesSyncer.syncToBim();
        this.bimAnnotationsSyncer.syncToBim();
        this.esos.applySelfImposedUpdates();
		this.esos.reconcileSubobjects();
    }

    syncGeometriesWithGpu() {
		const isEditMode = this._controlsState.poll().controlsMode === EngineControlsMode.Edit;
        this.engineGeometries.update(isEditMode ? GpuUploadUrgency.Urgent : GpuUploadUrgency.Default);
    }

    reconcileSubmeshesWithSubobjects() {
		this.esos.subObjects.applyUpdatesToSubmeshes();
    }

	applySubmeshesUpdates() {
        this.submeshes.applyUpdates();
	}

    updateCullingAndRenderLists(clipbox: ClipBox, frustums: FrustumExt[]) {
		this.submeshes.updateCullingAndRenderLists(clipbox, frustums);
    }

    calcBoundsByIds(ids: IdBimScene[]): Aabb {
		const handles = this.esos.handlesOf(ids)
		const aabb = this.submeshes.calcBoundsByParentHandles(handles);
        return aabb;
    }

    peekWorldMatrix(id: IdBimScene): Readonly<Matrix4> | undefined {
        return this.bim.instances.peekWorldMatrix(id);
    }
    getLocalTransformRelativeTo(parentId: IdBimScene | 0, currentWorldPosition: Matrix4) {
        const parentMatrix = this.bim.instances.peekWorldMatrix(parentId);
        return SceneInstances.getLocalTransformRelativeToParentMatrix(parentMatrix, currentWorldPosition);
    }

    engineRuntimeUiBindings(): RuntimeSystemUiDescription[] {
        return [];
        // const isSynced = LazyDerived.fromMutatingObject<boolean>(() => {
        //     return this.submeshes.isSynced() && this.esos.isSynced();
        // });

        // const runtimeSystemDescription: RuntimeSystemUiDescription = {
        //     group_sort_key: '3d-engine-uploads',
        //     name: '3d-engine-uploads',
        //     executionStatus: LazyDerived.new1('', null, [isSynced], ([isSynced]) => {
        //         const status = isSynced ? RuntimeSystemExecutionStatus.Done : RuntimeSystemExecutionStatus.InProgress;
        //         console.log('is engine synced', isSynced, status)
        //         return status
        //     }),
        // }
        // return [runtimeSystemDescription];
    }
}


