import type { ScopedLogger } from "engine-utils-ts";
import { IterUtils } from "engine-utils-ts";
import type { Builder } from "flatbuffers";
import type { PropertyGroup } from "../properties/PropertyGroup";
import type { PropsGroupField } from "../properties/Props";
import { PropertyBase, PropsGroupBase } from "../properties/Props";
import { PropsGroupsRegistry } from "../properties/PropsGroupsRegistry";
import { ConfigNode } from "../schema/config-node";
import { NamedNode } from "../schema/named-node";
import { PropertiesGroup } from "../schema/properties-group";
import {
    PropertiesGroupArray,
} from "../schema/properties-group-array";
import { Property } from "../schema/property";
import { PropertyArray } from "../schema/property-array";
import { FlatbufCommon } from "./FlatbufCommon";
import type { PropsDeserializer} from "./PropsSerializer";
import { PropertySerializer } from "./PropsSerializer";
import { _EmptyPropsStub } from "../properties/EmptyPropsStub";

export class PropsGroupsSerializer {
    readonly builder: Builder;
    readonly strictGroupsTypes: boolean;
    readonly propsSerializer: PropertySerializer;

    readonly groupTypesUsed: Set<string> = new Set();

    constructor(args: {
        logger: ScopedLogger,
        builder: Builder,
        strictGroupsTypes: boolean,
    }) {
        this.builder = args.builder;
        this.strictGroupsTypes = args.strictGroupsTypes;
        this.propsSerializer = new PropertySerializer({logger: args.logger, builder: args.builder});
    }

    serializePropertyGroup(props: PropertyGroup | PropsGroupBase | null): number {
        if (props instanceof _EmptyPropsStub) {
            return 0;
        }
        if (props === null) {
            return 0;
        }
        const fieldsOffsets: number[] = [];

        for (const key in props) {
            const value = props[key] as PropsGroupField;
            if (typeof value === "function") {
                continue;
            }

            let offset: number;
            let type: ConfigNode;

            if (Array.isArray(value)) {
                if (value[0] instanceof PropertyBase) {
                    offset = PropertyArray.createPropertyArray(
                        this.builder,
                        PropertyArray.createPropertiesVector(
                            this.builder,
                            value.map((p) =>
                                this.propsSerializer.serializeProp(p as PropertyBase)
                            )
                        )
                    );
                    type = ConfigNode.PropertyArray;
                } else {
                    offset = PropertiesGroupArray.createPropertiesGroupArray(
                        this.builder,
                        PropertiesGroupArray.createGroupsVector(
                            this.builder,
                            value.map((p) =>
                                this.serializePropertyGroup(p as PropertyGroup)
                            )
                        )
                    );
                    type = ConfigNode.PropertiesGroupArray;
                }
            } else if (value instanceof PropertyBase) {
                offset = this.propsSerializer.serializeProp(value);
                type = ConfigNode.Property;
            } else if (value === null) {
                type = ConfigNode.NONE;
                offset = 0;
            } else if (typeof value === "object") {
                offset = this.serializePropertyGroup(value as PropertyGroup);
                type = ConfigNode.PropertiesGroup;
            } else {
                throw new Error(
                    `unexpected property type ${key} - ${typeof value}`
                );
            }
            const fieldNodeOffset = NamedNode.createNamedNode(
                this.builder,
                this.builder.createSharedString(key),
                type,
                offset
            );
            fieldsOffsets.push(fieldNodeOffset);
        }

        let groupTypeOffset = 0;
        if (props instanceof PropsGroupBase || this.strictGroupsTypes) {
            const groupType = PropsGroupsRegistry.getSerializationTypeIdentifierOf(props as PropsGroupBase);
            groupTypeOffset = this.builder.createSharedString(groupType);
            this.groupTypesUsed.add(groupType);
        }

        return PropertiesGroup.createPropertiesGroup(
            this.builder,
            PropertiesGroup.createFieldsVector(this.builder, fieldsOffsets),
            groupTypeOffset
        );
    }

    // serializePropsTypesVersions(): number {

    // }

    serializeGroupsTypesVersions(): number {
        return FlatbufCommon.writeTypeIdentsVersions(
            this.builder,
            IterUtils.newMapFromTuples(
                IterUtils.mapIter(this.groupTypesUsed, (t) => {
                    return [t, PropsGroupsRegistry.getSerializedTypeVersion(t)];
                })
            )
        );
    }
}

export class PropsGroupsDeserializer {

    readonly logger: ScopedLogger;
    readonly groupsVersion: Map<string, number>;
    readonly handleGroupsAraysLegacyBug: boolean;
    readonly propsSerializer: PropsDeserializer;



    // readonly groupsVersions = new Map<string, number>();

    constructor(args: {
        logger: ScopedLogger,
        groupsTypesVersions: Map<string, number>,
        expectGroupsAraysLegacyBug: boolean,
        propsDeserializer: PropsDeserializer,
    }) {
        this.logger = args.logger;
        this.groupsVersion = args.groupsTypesVersions;
        this.handleGroupsAraysLegacyBug = args.expectGroupsAraysLegacyBug;
        this.propsSerializer = args.propsDeserializer;
    }

    deserializePropsGroup(propsGroup: PropertiesGroup | null): PropsGroupBase | null {
        if (!propsGroup) {
            return null;
        }
        const fields = this._deserializePropertyGroup(propsGroup);
        if (fields instanceof PropsGroupBase) {
            return fields;
        }
        this.logger.error(`deserialized props groups is not instance of class`, fields);
        return null;
    }

    deserializePropertyGroup(propsGroup: PropertiesGroup | null): PropertyGroup | null {
        if (!propsGroup) {
            return null;
        }
        const fields = this._deserializePropertyGroup(propsGroup);
        if (fields instanceof PropsGroupBase) {
            this.logger.error(`unexpected props class instance when deserializing property group`, fields);
            const res: PropertyGroup = {};
            for (const key in fields) {
                const value = fields[key];
                if (value && typeof value === 'object') {
                    res[key] = value as PropertyBase | PropertyGroup | PropertyBase[] | PropertyGroup[];
                }
            }
            return res;
        }
        return fields;
    }

    _deserializePropertyGroup(propsGroup: PropertiesGroup): PropertyGroup | PropsGroupBase | null {

        const fields: {[key: string]: PropsGroupField | PropertyGroup | PropertyGroup[]} = {};

        for (let i = 0; i < propsGroup.fieldsLength(); ++i) {
            const field = propsGroup.fields(i)!;
            const key = field.name()!;

            const valueType = field.valueType();
            if (valueType === ConfigNode.Property) {
                const property = new Property();
                field.value(property);
                const p = this.propsSerializer.deserialize(property);
                if (p) {
                    fields[key] = p;
                }

            } else if (valueType === ConfigNode.PropertyArray) {
                const flatPropsAray = new PropertyArray();
                field.value(flatPropsAray);

                const resultPropsArray: PropertyBase[] = [];
                for (let j = 0; j < flatPropsAray.propertiesLength(); ++j) {
                    const property = flatPropsAray.properties(j)!;
                    const p = this.propsSerializer.deserialize(property);
                    if (p) {
                        resultPropsArray.push(p);
                    }
                }
                fields[key] = resultPropsArray;

            } else if (valueType === ConfigNode.PropertiesGroup) {
                const propsGroup = new PropertiesGroup();
                field.value(propsGroup);
                const group = this._deserializePropertyGroup(propsGroup);
                if (group) {
                    fields[key] = group;
                }

            } else if (valueType === ConfigNode.PropertiesGroupArray) {
                const propsGroupArray = new PropertiesGroupArray();
                field.value(propsGroupArray);

                const resultGroupsArray: (PropertyGroup | PropsGroupBase)[] = [];
                for (let j = 0; j < propsGroupArray.groupsLength(); ++j) {
                    const propsGroup = propsGroupArray.groups(j)!;
                    const group = this._deserializePropertyGroup(propsGroup);
                    if (group) {
                        if (this.handleGroupsAraysLegacyBug) {
                            for (const key in group) {
                                if (parseInt(key) >= 0) {
                                    const value = group[key];
                                    resultGroupsArray.push(value as PropertyGroup);
                                } else {
                                    this.logger.error('expected array index key in legacy bug props group array', key);
                                }
                            }
                            break; // this bug written the same keys n^2 times, for each index all elements
                        } else {
                            resultGroupsArray.push(group);
                        }
                    }
                }
                fields[key] = resultGroupsArray as PropertyGroup[] | PropsGroupBase[];

            } else if (valueType === ConfigNode.NONE) {
                fields[key] = null;
            } else {
                this.logger.error(`unexpected property field type ${valueType}`);
            }
        }

        const propsGroupTypeIdent = propsGroup.typeIdent();
        if (propsGroupTypeIdent) {
            const version = this.groupsVersion.get(propsGroupTypeIdent) ?? 0;
            return PropsGroupsRegistry.newInstanceFromSerializedTypeIdent(
                propsGroupTypeIdent,
                version,
                fields as {[key: string]: PropsGroupField},
            );
        }
        return fields as PropertyGroup;
    }
}
