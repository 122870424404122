<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        d="M3.80001 3.8002H6.00001V2.2002H3.50001C2.78204 2.2002 2.20001 2.78223 2.20001 3.5002V6.0002H3.80001V3.8002Z"
        fill="currentColor"
    />
    <path
        d="M16.2 3.8002H14V2.2002H16.5C17.218 2.2002 17.8 2.78223 17.8 3.5002V6.0002H16.2V3.8002Z"
        fill="currentColor"
    />
    <path
        d="M6.00001 16.1998H3.80001V13.9998H2.20001V16.4998C2.20001 17.2178 2.78204 17.7998 3.50001 17.7998H6.00001V16.1998Z"
        fill="currentColor"
    />
    <path
        d="M16.2 16.1998H14V17.7998H16.5C17.218 17.7998 17.8 17.2178 17.8 16.4998V13.9998H16.2V16.1998Z"
        fill="currentColor"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3249 4.26915C10.1181 4.17721 9.88195 4.17721 9.6751 4.26915L5.1751 6.26915C4.8862 6.39755 4.70001 6.68404 4.70001 7.0002V13.0002C4.70001 13.3163 4.8862 13.6028 5.1751 13.7312L9.6751 15.7312C9.88195 15.8232 10.1181 15.8232 10.3249 15.7312L14.8249 13.7312C15.1138 13.6028 15.3 13.3163 15.3 13.0002V7.0002C15.3 6.68404 15.1138 6.39755 14.8249 6.26915L10.3249 4.26915ZM9.20001 9.52009L6.30001 8.2312V12.4803L9.20001 13.7692V9.52009ZM13.7 12.4803L10.8 13.7692V9.52009L13.7 8.2312V12.4803ZM12.5302 7.0002L10 8.12474L7.46978 7.0002L10 5.87565L12.5302 7.0002Z"
        fill="currentColor"
    />
</svg>
