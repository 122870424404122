import type {
	WebGLRenderTarget} from '../3rdParty/three';
import {
	ShaderMaterial,
	UniformsUtils,
	OrthographicCamera,
	Scene,
	Mesh, Texture
} from '../3rdParty/three'
import TrianglePostProcGeom from './TrianglePostProcGeom'
import type { OptionalRenderTarget } from './Pass';
import { Pass } from './Pass'
import type { ShaderBase } from '../shaders/ShaderBase';
import type { RendererExt } from './RendererExt';

export default class ShaderPass extends Pass {
	textureID: string;
	uniforms: any;
	material: ShaderMaterial;
	postCamera: OrthographicCamera;
	quad: TrianglePostProcGeom;
	quadFlipped: TrianglePostProcGeom;
	scene: Scene;

	constructor(shader: ShaderBase, textureID?: string) {
		super();
		
		if (!shader) {
			throw 'invalid shader';
		}
		
		this.textureID = (textureID) ? textureID : 'tDiffuse';

		this.uniforms = UniformsUtils.clone(shader.uniforms);
		this.material = new ShaderMaterial({
			defines: shader.defines || {},
			uniforms: this.uniforms,
			vertexShader: shader.vertexShader,
			fragmentShader: shader.fragmentShader,
			depthWrite: false,
			depthTest: false,

		});

		this.toDispose.push(this.material);

		this.postCamera = new OrthographicCamera(- 1, 1, 1, - 1, 0, 1);

		this.quad = new TrianglePostProcGeom(false);
		this.toDispose.push(this.quad);
		this.quadFlipped = new TrianglePostProcGeom(true);
		this.toDispose.push(this.quadFlipped);
		
		this.scene = new Scene();
		this.scene.add(new Mesh(null, null));
	}

	render(): number {
		throw 'user renderShader';
	}

	renderShader(renderer: RendererExt, writeBuffer:OptionalRenderTarget, readBuffer:WebGLRenderTarget | Texture, toFlip:boolean = false) {
		if (this.uniforms[this.textureID]) {
			this.uniforms[this.textureID].value = readBuffer instanceof Texture ? readBuffer : readBuffer.texture;
		}

		const mesh = this.scene.children[0] as Mesh;
		mesh.geometry = toFlip === true ? this.quadFlipped : this.quad;
		mesh.material = this.material;

		renderer.setRenderTarget(writeBuffer);
		renderer.render(this.scene, this.postCamera);
	}

	setSize(width: number, height: number) {
		if (this.uniforms['resolution'] !== undefined) {
			this.uniforms['resolution'].value.set(1 / width, 1 / height);
		}
	}
};