import type { CellStyle, GridOptions } from 'ag-grid-enterprise';
import { type PileFeaturesFlags, getPileTypeIcon, unpackPileFeatures } from 'bim-ts';

const defaultCellStyle: CellStyle = {
    "text-overflow": "ellipsis",
    "white-space": "nowrap",
    overflow: "hidden",
    "text-align": "left",
};

export const DefaultGridOptions: Partial<GridOptions> = {
    defaultColDef: {
        // cell related
        suppressMovable: true,
        flex: 1,
        cellStyle: defaultCellStyle,
        // header related
        resizable: false,
        suppressColumnsToolPanel: true,
        suppressHeaderMenuButton: true,
        autoHeaderHeight: true,
        sortable: false,
    },
    suppressCellFocus: false,
    suppressHeaderFocus: true,
    suppressRowHoverHighlight: true,
    suppressContextMenu: true,
    singleClickEdit: true,
    enableRangeSelection: false,
    domLayout: "autoHeight",
    rowHeight: 30,
    onCellClicked: (params) => {
        params.api.deselectAll();
    },
}

export function pileIconCellRenderer(featureFlags: PileFeaturesFlags, color: string) {
    const features = unpackPileFeatures(featureFlags);
    return getPileTypeIcon(
        features.weight_class,
        features.motor,
        features.damper,
        features.modifier,
        color,
    );
}