import { Texture, LinearFilter, LinearMipmapLinearFilter, RGBFormat } from '../3rdParty/three'
import { atlasnBase64 } from './FontAtlas';

// minx, miny, maxx; (maxy = minx + const height)
export interface CharUvBounds {
	minx: number;
	miny: number;
	maxx: number;
};

export interface CharsAtlas {
	charsHeight: number;
	charCoords: Map<string, CharUvBounds>;
	texture: Texture; 
}

export function createAtlasTexture(): Texture {
	const atlasImg = new Image();
	const atlasTexture = new Texture(atlasImg);
	atlasTexture.name = 'font_atlas';
	atlasImg.onload = () => {
		atlasTexture.anisotropy = 16;

		atlasTexture.generateMipmaps = false;
		atlasTexture.format = RGBFormat;
		atlasTexture.premultiplyAlpha = false;
		atlasTexture.magFilter = LinearFilter;
		atlasTexture.minFilter = LinearFilter;

		atlasTexture.generateMipmaps = true;
		atlasTexture.minFilter = LinearMipmapLinearFilter;

		atlasTexture.needsUpdate = true;
	}
	atlasImg.src = atlasnBase64;
	return atlasTexture;
}

export const AtlasPixelSize = 512;

const AtlasGlyphize = 8;
const GlyphHeightNormalized = 0.85;
export const GlyphUvHeight = 1 / AtlasGlyphize * GlyphHeightNormalized;

function boundsToCharCoods(row: number, col: number, norm_width: number): CharUvBounds {
	col = AtlasGlyphize - col - 1;
	const uv_y_offset = 4 / AtlasPixelSize;
	const uv_x_offset = 4 / AtlasPixelSize;
	const row_uv = row / AtlasGlyphize + uv_x_offset; // add pixel translation
	return {
		minx: row_uv,
		miny: (col) / AtlasGlyphize + uv_y_offset,
		maxx: row_uv + norm_width * 1 / AtlasGlyphize,
	}
}

export function CreateCharsAtlas(): CharsAtlas {
	const charCoords = new Map<string, CharUvBounds>();

	charCoords.set('0', boundsToCharCoods(0, 0, 17/32));
	charCoords.set('1', boundsToCharCoods(1, 0, 12/32));
	charCoords.set('2', boundsToCharCoods(0, 1, 17/32));
	charCoords.set('3', boundsToCharCoods(1, 1, 17/32));
	charCoords.set('4', boundsToCharCoods(2, 0, 16/32));
	charCoords.set('5', boundsToCharCoods(2, 1, 17/32));
	charCoords.set('6', boundsToCharCoods(3, 0, 17/32));
	charCoords.set('7', boundsToCharCoods(3, 1, 16/32));
	charCoords.set('8', boundsToCharCoods(0, 2, 17/32));
	charCoords.set('9', boundsToCharCoods(0, 3, 17/32));
	charCoords.set('.', boundsToCharCoods(2, 3, 7/32));
	charCoords.set('m', boundsToCharCoods(1, 3, 26/32));
	charCoords.set('f', boundsToCharCoods(2, 2, 12/32));
	charCoords.set('t', boundsToCharCoods(4, 1, 12/32));
	charCoords.set('\'', boundsToCharCoods(5, 0, 8/32));
	charCoords.set('"', boundsToCharCoods(1, 2, 10/32));
	charCoords.set('?', boundsToCharCoods(4, 0, 15/32));
	charCoords.set('²', boundsToCharCoods(3, 2, 12/32));
	charCoords.set('³', boundsToCharCoods(3, 3, 12/32));

	const atlasTexture = createAtlasTexture();

	return {
		charCoords: charCoords,
		charsHeight: GlyphUvHeight,
		texture: atlasTexture
	}
}
