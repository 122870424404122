import { convertThrow } from 'engine-utils-ts';
import { Matrix4, Matrix3 } from "math-ts";
import type { Bim } from "../Bim";
import type { BimGeometries } from "../geometries/BimGeometries";
import { ExtrudedPolygonGeometry } from "../geometries/ExtrudedPolygonGeometries";
import type { BasicAnalyticalRepresentation } from "../representation/Representations";
import type { ReactiveSolverBase } from "../runtime/ReactiveSolverBase";
import { SolverObjectInstance } from "../runtime/SolverObjectInstance";
import type { SceneInstance } from "../scene/SceneInstances";


export const BoundaryTypeIdent = 'boundary';

export function registerBoundary(bim: Bim) {
    bim.instances.archetypes.registerArchetype(
        {
            type_identifier: BoundaryTypeIdent,
            mandatoryProps: [
                {path: ['boundary', 'boundary_type'], discrete_variants: ['include', 'exclude'], value: 'include'},
                {path: ['boundary', 'source_type'], discrete_variants: ['origin', 'equipment'], value: 'origin', readonly: true},
            ]
        }
    );


    bim.instances.registerPrimaryPropertyLabelProvider('boundary', (boundaryInstance: Readonly<SceneInstance>) => {
        const areaProp = boundaryInstance.properties.get('dimensions | area');
        return areaProp?.valueUnitUiString(bim.unitsMapper);
    });


	bim.reactiveRuntimes.registerRuntimeSolver(boundarySizeSolver(bim.allBimGeometries));




}



function boundarySizeSolver(geometries: BimGeometries): ReactiveSolverBase {
    const objectsDefaultArgs = {
        representationAnalytical: null as BasicAnalyticalRepresentation | null,
        worldMatrix: new Matrix4(),
    };

    return new SolverObjectInstance({
        solverIdentifier: "boundary-dimensions",
        objectsIdentifier: "boundary",
        objectsDefaultArgs,
        cache: false,
        solverFunction: (args) => {

            const repr = args.representationAnalytical;

            let area = 0;
            let perimeter = 0;

            if (repr) {
                const scaleMatrix = new Matrix3().setFromMatrix4(args.worldMatrix);
                const geo = geometries.peekById(repr.geometryId);
                if (geo && geo instanceof ExtrudedPolygonGeometry) {
                    area = Math.abs(geo.area(scaleMatrix));
                    perimeter = geo.perimeter(scaleMatrix);
                }
            }


            return {
                legacyProps: [
                    { path: ["dimensions", "area"], value: convertThrow(area, 'm2', 'ac'), unit: "ac" },
                    { path: ["dimensions", "perimeter"], value: perimeter, unit: "m" },
                ]
            };
        },
    });
}
