
export enum ESO_Diff {
	None = 0,

	Position = 1 << 1,

	RepresentationSoft = 1 << 2,
	RepresentationOverlaySoft 	= 1 << 3,

	RepresentationBreaking 	= 1 << 4,
	ForceReprRecheck 		= 1 << 5,

	IsEditable = 1 << 5,
	
	ColorTint = 1 << 6,

	All = 0xF_FF_FF_FF
}
