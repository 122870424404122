import type { ScopedLogger } from 'engine-utils-ts';
import type { IdBimScene, SceneInstance} from '../scene/SceneInstances';
import type { SceneObjDiff } from 'src/scene/SceneObjDiff';



export class PerInstanceCalculationsInvalidator<InputToInvalidateWith, R> {
    
    readonly logger: ScopedLogger;
    readonly checkIfArgsChanged: (updateFlags: SceneObjDiff, prev: InputToInvalidateWith, curr: SceneInstance) => boolean;
    readonly computationsCacheSize: number;
    
    private readonly _dirtyIds: Set<IdBimScene> = new Set();
    private readonly _lastCalcArgs = new Map<IdBimScene, InputToInvalidateWith | null>();
    private _version: number = 0;

    private readonly prevRuns: {input: InputToInvalidateWith, result: R}[] = [];
    
    cacheHits: number = 0;
    cacheMisses: number = 0;

    constructor(args: {
        logger: ScopedLogger
        checkIfArgsChanged: (updateFlags: SceneObjDiff, prev: InputToInvalidateWith, curr: SceneInstance) => boolean,
        // areInputsEqual?: (prev: InputToInvalidateWith, toRun: InputToInvalidateWith) => boolean,
        computationsCacheSize?: number,
        // sortFn?: (a: IdBimScene, b: IdBimScene) => number,
    }) {

        this.logger = args.logger;
        this.checkIfArgsChanged = args.checkIfArgsChanged;
        this.computationsCacheSize = args.computationsCacheSize ?? 2;

        if (this.computationsCacheSize > 4) {
            this.logger.warn('inputsCacheSize is probably too big', this.computationsCacheSize);
        }
    }

    clear() {
        this._dirtyIds.clear();
        this._lastCalcArgs.clear();
        this.prevRuns.length = 0;
        this._version += 1;
    }

    clearCache() {
        this.prevRuns.length = 0;
    }

    version() {
        return this._version;
    }

    invalidateCachedOutputs(filterFn: (output: R) => boolean) {
        for (let i = this.prevRuns.length - 1; i >= 0; --i) {
            const prevRun = this.prevRuns[i];
            if (filterFn(prevRun.result)) {
                this.prevRuns.splice(i, 1);
            }
        }
    }

    forceMarkDirtyIfAdded(ids: Iterable<IdBimScene>) {
        for (const id of ids) {
            if (this._lastCalcArgs.has(id)) {
                this._dirtyIds.add(id);
            }
        }
        this._version += 1;
    }

    forceMarkDirtyAllAdded() {
        for (const id of this._lastCalcArgs.keys()) {
            this._dirtyIds.add(id);
        }
        this._version += 1;
    }

    addNewObject(id: IdBimScene) {
        this._dirtyIds.add(id);
        this._lastCalcArgs.set(id, null);
        this._version += 1;
    }

    removeObject(id: IdBimScene) {
        this._dirtyIds.delete(id);
        if(this._lastCalcArgs.delete(id)){
            this._version += 1;
        }
    }

    removeObjects(ids: Iterable<IdBimScene>) {
        let isDirty = false;
        for (const id of ids) {
            if (this._lastCalcArgs.delete(id)) {
                isDirty = true;
            };
            this._dirtyIds.delete(id);
        }
        if(isDirty){
            this._version += 1;
        }
    }

    markDirty(id: IdBimScene) {
        if (!this._lastCalcArgs.has(id)) {
            this.logger.batchedError('unexpected mark dirty id', id);
            return;
        }
        this._dirtyIds.add(id);
        this._version += 1;
    }

    markDirtyIfIncluded(id: IdBimScene) {
        if (this._lastCalcArgs.has(id)) {
            this._dirtyIds.add(id);
            this._version += 1;
        }
    }

    checkIfBecameDirty(diff: SceneObjDiff, id: IdBimScene, instance: SceneInstance): boolean {
        if (this._dirtyIds.has(id)) {
            return false;
        }
        let isDirty = false;
        const lastArgs = this._lastCalcArgs.get(id);
        if (!lastArgs) {
            if (lastArgs === undefined) {
                this.logger.batchedWarn('unexpected invalidation attempt', [id, lastArgs]);
            }
        } else if (this.checkIfArgsChanged(diff, lastArgs, instance)) {
            isDirty = true;
        } 
        return isDirty;
    }
    
    checkAndMarkIBecamefDirty(diff: SceneObjDiff, id: IdBimScene, instance: SceneInstance): boolean {
        const isDirty = this.checkIfBecameDirty(diff, id, instance);
        if (isDirty) {
            this._dirtyIds.add(id);
            this._version += 1;
        }
        return isDirty
    }

    markCalculated(id: IdBimScene, input: InputToInvalidateWith) {
        this._lastCalcArgs.set(id, input);
        this._dirtyIds.delete(id);
    }

    getLastArgs(id: IdBimScene): InputToInvalidateWith | null {
        return this._lastCalcArgs.get(id) ?? null;
    }

    putIntoCache(input: InputToInvalidateWith, result: R) {
        if (!this.computationsCacheSize) {
            return;
        }
        if (this.prevRuns.length >= this.computationsCacheSize) {
            this.prevRuns.pop();
        }
        this.prevRuns.unshift({
            input,
            result: result,
        });
    }

    tryGetCachedResult(instance: SceneInstance): {input: InputToInvalidateWith, result: R} | null {
        for (let i = 0; i < this.prevRuns.length; ++i) {
            const prevRun = this.prevRuns[i];
            if (!this.checkIfArgsChanged(0xFFFFFFFF as SceneObjDiff, prevRun.input, instance)) {
                if (i !== 0) {
                    this.prevRuns.splice(i, 1);
                    this.prevRuns.unshift(prevRun);
                }
                this.cacheHits += 1;
                return prevRun;
            }
        }
        this.cacheMisses += 1;
        return null;
    }

    hasDirtyIds() {
        return this._dirtyIds.size > 0;
    }

    consumeNextDirtyId(): IdBimScene | null {
        for (const id of this._dirtyIds) {
            this._dirtyIds.delete(id);
            if (!this._lastCalcArgs.has(id)) {
                this.logger.batchedError('unexpected dirty id', id);
                continue;
            }
            return id;
        }
        return null;
    }

    consumeAllDirtyIds(): IdBimScene[] {
        const ids = Array.from(this._dirtyIds);
        this._dirtyIds.clear();
        return ids;
    }

}