import type { LazyVersioned, ResultAsync } from "engine-utils-ts";
import { IterUtils, LazyDerivedAsync } from "engine-utils-ts";
import type { LCOECalculationResult } from "..";
import { NumberProperty } from "src";
import { CostHierarchy } from "../CostHierarchy";
import type { LcoeSettings, LcoeSettingsProvider } from "../LcoeSettings";
import { KrMath } from "math-ts";

export function createNpvOfCostHierarchy(
    lcoe: LazyVersioned<ResultAsync<LCOECalculationResult>>,
    defaultSettings: LcoeSettings,
    provider: LcoeSettingsProvider,
) {
    const npvOfCost = LazyDerivedAsync.new2(
        "npvOfCost",
        [],
        [lcoe, provider.lcoeSettings],
        function* ([lcoe, settings]) {
            const hierarchy = new CostHierarchy();

            hierarchy.add({
                description: { value: "Initial Investment in Capital Cost" },
                source: { title: "Capital Cost, total" },
                lcoeInput: {
                    quantity: NumberProperty.new({
                        value: lcoe.input.capitalCost.totalCost,
                        unit: "usd",
                    }),
                    input: {
                        value:
                            100 -
                            100 *
                                (settings.debtCost
                                    .debtSizeAsPercentOfCapitalCost?.value ??
                                    defaultSettings.debtCost
                                        .debtSizeAsPercentOfCapitalCost
                                        ?.value ??
                                    0),
                        update: (value) => {
                            if (!value) {
                                provider.updateLcoeSettings(
                                    (state) =>
                                        (state.debtCost.debtSizeAsPercentOfCapitalCost =
                                            null),
                                );
                            } else {
                                value = KrMath.clamp(1 - value / 100, 0, 1);
                                provider.updateLcoeSettings(
                                    (state) =>
                                        (state.debtCost.debtSizeAsPercentOfCapitalCost =
                                            NumberProperty.new({ value })),
                                );
                            }
                        },
                        flags: {
                            editable: true,
                            overriden:
                                !!settings.debtCost
                                    .debtSizeAsPercentOfCapitalCost,
                        },
                    },
                    resultingUnit: "%",
                },
            });

            // debt
            const debt = createDebtCostSection(
                lcoe,
                settings,
                defaultSettings,
                provider,
            );
            hierarchy.merge(debt);

            // operating costs
            const operatingCosts = createOperatingCostSection(
                lcoe,
                settings,
                defaultSettings,
                provider,
            );
            hierarchy.merge(operatingCosts);

            hierarchy.addRoot({
                description: { value: "NPV of Cost" },
                costPerYear: lcoe.output.perYear
                    .map((x) => x.netPresentValueOfCost)
                    .concat(
                        IterUtils.sum(
                            lcoe.output.perYear,
                            (x) => x.netPresentValueOfCost,
                        ),
                    )
                    .map((x) => ({
                        value: NumberProperty.new({ value: x, unit: "usd" }),
                    })),
            });
            return hierarchy;
        },
    );
    return npvOfCost;
}

function createDebtCostSection(
    lcoe: LCOECalculationResult,
    settings: LcoeSettings,
    defaultSettings: LcoeSettings,
    provider: LcoeSettingsProvider,
) {
    const debt = new CostHierarchy();
    debt.add({
        description: { value: "Principal Debt" },
        source: { title: "Capital Cost, total" },
        lcoeInput: {
            quantity: NumberProperty.new({
                value: lcoe.input.capitalCost.totalCost,
                unit: "usd",
            }),
            input: {
                value:
                    100 *
                    (settings.debtCost.debtSizeAsPercentOfCapitalCost?.value ??
                        defaultSettings.debtCost.debtSizeAsPercentOfCapitalCost
                            ?.value ??
                        0),
                update: (value) => {
                    if (!value) {
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.debtCost.debtSizeAsPercentOfCapitalCost =
                                    null),
                        );
                    } else {
                        value = KrMath.clamp(value / 100, 0, 1);
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.debtCost.debtSizeAsPercentOfCapitalCost =
                                    NumberProperty.new({ value })),
                        );
                    }
                },
                flags: {
                    editable: true,
                    overriden:
                        !!settings.debtCost.debtSizeAsPercentOfCapitalCost,
                },
            },
            resultingUnit: "%",
        },
        costPerYear: lcoe.output.perYear
            .map((x) => x.debtExpenses.principalCost)
            .concat(
                IterUtils.sum(
                    lcoe.output.perYear,
                    (x) => x.debtExpenses.principalCost,
                ),
            )
            .map((x) => ({
                value: NumberProperty.new({ value: x, unit: "usd" }),
                integer: true,
            })),
    });
    debt.add({
        description: { value: "Debt Term" },
        lcoeInput: {
            input: {
                value:
                    settings.debtCost.debtTermYears?.value ??
                    defaultSettings.debtCost.debtTermYears?.value ??
                    0,
                update: (value) => {
                    if (!value) {
                        provider.updateLcoeSettings(
                            (state) => (state.debtCost.debtTermYears = null),
                        );
                    } else {
                        value = KrMath.clamp(value, 0, 100);
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.debtCost.debtTermYears =
                                    NumberProperty.new({ value })),
                        );
                    }
                },
                flags: {
                    editable: true,
                    overriden: !!settings.debtCost.debtTermYears,
                },
            },
            resultingUnit: "Years",
        },
    });
    debt.add({
        description: { value: "Interest Rate on Term Debt" },
        lcoeInput: {
            input: {
                value:
                    100 *
                    (settings.debtCost.interestRateOnDebtPercent?.value ??
                        defaultSettings.debtCost.interestRateOnDebtPercent
                            ?.value ??
                        0),
                update: (value) => {
                    if (!value) {
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.debtCost.interestRateOnDebtPercent =
                                    null),
                        );
                    } else {
                        value = KrMath.clamp(value / 100, 0, 1);
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.debtCost.interestRateOnDebtPercent =
                                    NumberProperty.new({ value })),
                        );
                    }
                },
                flags: {
                    editable: true,
                    overriden: !!settings.debtCost.interestRateOnDebtPercent,
                },
            },
            resultingUnit: "%",
        },
        costPerYear: lcoe.output.perYear
            .map((x) => x.debtExpenses.interestCost)
            .concat(
                IterUtils.sum(
                    lcoe.output.perYear,
                    (x) => x.debtExpenses.interestCost,
                ),
            )
            .map((x) => ({
                value: NumberProperty.new({ value: x, unit: "usd" }),
                integer: true,
            })),
    });
    debt.add({
        description: { value: "Lender's Fee" },
        source: { title: "Debt size" },
        lcoeInput: {
            input: {
                value:
                    100 *
                    (settings.debtCost.lenderFeePercentOfDebt?.value ??
                        defaultSettings.debtCost.lenderFeePercentOfDebt
                            ?.value ??
                        0),
                update: (value) => {
                    if (!value) {
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.debtCost.lenderFeePercentOfDebt = null),
                        );
                    } else {
                        value = KrMath.clamp(value / 100, 0, 1);
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.debtCost.lenderFeePercentOfDebt =
                                    NumberProperty.new({ value })),
                        );
                    }
                },
                flags: {
                    editable: true,
                    overriden: !!settings.debtCost.lenderFeePercentOfDebt,
                },
            },
            quantity: NumberProperty.new({
                unit: "usd",
                value:
                    lcoe.input.debtCost.debtSizeAsPercentOfCapitalCost *
                    lcoe.input.capitalCost.totalCost,
            }),
            resultingUnit: "%",
        },
        costPerYear: lcoe.output.perYear
            .map((x) => x.debtExpenses.feeCost)
            .concat(
                IterUtils.sum(
                    lcoe.output.perYear,
                    (x) => x.debtExpenses.feeCost,
                ),
            )
            .map((x) => ({
                value: NumberProperty.new({ value: x, unit: "usd" }),
                integer: true,
            })),
    });
    debt.addRoot({
        description: { value: "Debt Cost" },
        costPerYear: lcoe.output.perYear
            .map((x) => x.debtExpenses.totalCost)
            .concat(
                IterUtils.sum(
                    lcoe.output.perYear,
                    (x) => x.debtExpenses.totalCost,
                ),
            )
            .map((x) => ({
                value: NumberProperty.new({ value: x, unit: "usd" }),
                integer: true,
            })),
    });
    return debt;
}

function createOperatingCostSection(
    lcoe: LCOECalculationResult,
    settings: LcoeSettings,
    defaultSettings: LcoeSettings,
    provider: LcoeSettingsProvider,
) {
    const operating = new CostHierarchy();
    operating.add({
        description: { value: "Operating and Maintenance" },
        source: { title: "Project metrics, DC Total" },
        lcoeInput: {
            quantity: NumberProperty.new({
                value: lcoe.input.layout.wattDC,
                unit: "W",
            }),
            input: {
                value:
                    settings.operatingCost
                        .operationAndMaintenanceCostPerWattPerYear?.value ??
                    defaultSettings.operatingCost
                        .operationAndMaintenanceCostPerWattPerYear?.value ??
                    0,
                update: (value) => {
                    if (!value) {
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.operatingCost.operationAndMaintenanceCostPerWattPerYear =
                                    null),
                        );
                    } else {
                        value = Math.max(value, 0);
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.operatingCost.operationAndMaintenanceCostPerWattPerYear =
                                    NumberProperty.new({ value })),
                        );
                    }
                },
                flags: {
                    editable: true,
                    overriden:
                        !!settings.operatingCost
                            .operationAndMaintenanceCostPerWattPerYear,
                },
            },
            resultingUnit: "$/W/year",
        },
        costPerYear: lcoe.output.perYear
            .map((x) => x.operatingExpenses.operationAndMaitenanceCost)
            .concat(
                IterUtils.sum(
                    lcoe.output.perYear,
                    (x) => x.operatingExpenses.operationAndMaitenanceCost,
                ),
            )
            .map((x) => ({
                value: NumberProperty.new({ value: x, unit: "usd" }),
                integer: true,
            })),
    });
    operating.add({
        description: { value: "General & Administrative" },
        source: { title: "Project metrics, DC Total" },
        lcoeInput: {
            quantity: NumberProperty.new({
                value: lcoe.input.layout.wattDC,
                unit: "W",
            }),
            input: {
                value:
                    settings.operatingCost
                        .generalAndAdministrativePerWattPerYear?.value ??
                    defaultSettings.operatingCost
                        .generalAndAdministrativePerWattPerYear?.value ??
                    0,
                update: (value) => {
                    if (!value) {
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.operatingCost.generalAndAdministrativePerWattPerYear =
                                    null),
                        );
                    } else {
                        value = Math.max(value, 0);
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.operatingCost.generalAndAdministrativePerWattPerYear =
                                    NumberProperty.new({ value })),
                        );
                    }
                },
                flags: {
                    editable: true,
                    overriden:
                        !!settings.operatingCost
                            .generalAndAdministrativePerWattPerYear,
                },
            },
            resultingUnit: "$/W/year",
        },
        costPerYear: lcoe.output.perYear
            .map((x) => x.operatingExpenses.generalAndAdministrativeCost)
            .concat(
                IterUtils.sum(
                    lcoe.output.perYear,
                    (x) => x.operatingExpenses.generalAndAdministrativeCost,
                ),
            )
            .map((x) => ({
                value: NumberProperty.new({ value: x, unit: "usd" }),
                integer: true,
            })),
    });
    operating.add({
        description: { value: "Insurance" },
        source: { title: "Capital Cost, Total" },
        lcoeInput: {
            quantity: NumberProperty.new({
                value: lcoe.input.capitalCost.totalCost,
                unit: "usd",
            }),
            input: {
                value:
                    100 *
                    (settings.operatingCost
                        .insuranceCostAsPercentOfCapitalCostPerYear?.value ??
                        defaultSettings.operatingCost
                            .insuranceCostAsPercentOfCapitalCostPerYear
                            ?.value ??
                        0),
                update: (value) => {
                    if (!value) {
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.operatingCost.insuranceCostAsPercentOfCapitalCostPerYear =
                                    null),
                        );
                    } else {
                        value = KrMath.clamp(value / 100, 0, 1);
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.operatingCost.insuranceCostAsPercentOfCapitalCostPerYear =
                                    NumberProperty.new({ value })),
                        );
                    }
                },
                flags: {
                    editable: true,
                    overriden:
                        !!settings.operatingCost
                            .insuranceCostAsPercentOfCapitalCostPerYear,
                },
            },
            resultingUnit: "%/year",
        },
        costPerYear: lcoe.output.perYear
            .map((x) => x.operatingExpenses.insuranceCost)
            .concat(
                IterUtils.sum(
                    lcoe.output.perYear,
                    (x) => x.operatingExpenses.insuranceCost,
                ),
            )
            .map((x) => ({
                value: NumberProperty.new({ value: x, unit: "usd" }),
                integer: true,
            })),
    });
    operating.add({
        description: { value: "Property Tax" },
        source: { title: "Capital Cost, Total" },
        lcoeInput: {
            quantity: NumberProperty.new({
                value: lcoe.input.capitalCost.totalCost,
                unit: "usd",
            }),
            input: {
                value:
                    100 *
                    (settings.operatingCost
                        .propertyTaxAsPercentOfCapitalCostPerYear?.value ??
                        defaultSettings.operatingCost
                            .propertyTaxAsPercentOfCapitalCostPerYear?.value ??
                        0),
                update: (value) => {
                    if (!value) {
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.operatingCost.propertyTaxAsPercentOfCapitalCostPerYear =
                                    null),
                        );
                    } else {
                        value = KrMath.clamp(value / 100, 0, 1);
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.operatingCost.propertyTaxAsPercentOfCapitalCostPerYear =
                                    NumberProperty.new({ value })),
                        );
                    }
                },
                flags: {
                    editable: true,
                    overriden:
                        !!settings.operatingCost
                            .propertyTaxAsPercentOfCapitalCostPerYear,
                },
            },
            resultingUnit: "%/year",
        },
        costPerYear: lcoe.output.perYear
            .map((x) => x.operatingExpenses.propertyTaxCost)
            .concat(
                IterUtils.sum(
                    lcoe.output.perYear,
                    (x) => x.operatingExpenses.propertyTaxCost,
                ),
            )
            .map((x) => ({
                value: NumberProperty.new({ value: x, unit: "usd" }),
                integer: true,
            })),
    });
    operating.add({
        description: { value: "All include zones only" },
        source: { title: "Project metrics, Full area" },
        lcoeInput: {
            input: {
                value:
                    settings.layout.areaAcres?.value ??
                    defaultSettings.layout.areaAcres?.value ??
                    0,
                update: (value) => {
                    if (!value) {
                        provider.updateLcoeSettings(
                            (state) => (state.layout.areaAcres = null),
                        );
                    } else {
                        value = Math.max(0, value);
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.layout.areaAcres = NumberProperty.new({
                                    value,
                                })),
                        );
                    }
                },
                flags: {
                    editable: true,
                    overriden: !!settings.layout.areaAcres,
                },
            },
            resultingUnit: "ac",
        },
    });
    operating.add({
        description: { value: "Land Lease" },
        lcoeInput: {
            input: {
                value:
                    settings.operatingCost.landLeaseCostPerAcrePerYear?.value ??
                    defaultSettings.operatingCost.landLeaseCostPerAcrePerYear
                        ?.value ??
                    0,
                update: (value) => {
                    if (!value) {
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.operatingCost.landLeaseCostPerAcrePerYear =
                                    null),
                        );
                    } else {
                        value = Math.max(value, 0);
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.operatingCost.landLeaseCostPerAcrePerYear =
                                    NumberProperty.new({ value })),
                        );
                    }
                },
                flags: {
                    editable: true,
                    overriden:
                        !!settings.operatingCost.landLeaseCostPerAcrePerYear,
                },
            },
            resultingUnit: "$/ac/year",
        },
        costPerYear: lcoe.output.perYear
            .map((x) => x.operatingExpenses.landLeaseCost)
            .concat(
                IterUtils.sum(
                    lcoe.output.perYear,
                    (x) => x.operatingExpenses.landLeaseCost,
                ),
            )
            .map((x) => ({
                value: NumberProperty.new({ value: x, unit: "usd" }),
                integer: true,
            })),
    });
    operating.add({
        description: { value: "Discount Rate, applied each year" },
        lcoeInput: {
            input: {
                value:
                    100 *
                    (settings.lcoe.discountRatePercent?.value ??
                        defaultSettings.lcoe.discountRatePercent?.value ??
                        0),
                update: (value) => {
                    if (!value) {
                        provider.updateLcoeSettings(
                            (state) => (state.lcoe.discountRatePercent = null),
                        );
                    } else {
                        value = KrMath.clamp(value / 100, 0, 1);
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.lcoe.discountRatePercent =
                                    NumberProperty.new({ value })),
                        );
                    }
                },
                flags: {
                    editable: true,
                    overriden: !!settings.lcoe.discountRatePercent,
                },
            },
            resultingUnit: "%",
        },
    });

    operating.add({
        description: { value: "Operation Growth Rate, each year" },
        lcoeInput: {
            input: {
                value:
                    100 *
                    (settings.lcoe.operationGrowthRatePercent?.value ??
                        defaultSettings.lcoe.operationGrowthRatePercent
                            ?.value ??
                        0),
                update: (value) => {
                    if (!value) {
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.lcoe.operationGrowthRatePercent = null),
                        );
                    } else {
                        value = KrMath.clamp(value / 100, 0, 1);
                        provider.updateLcoeSettings(
                            (state) =>
                                (state.lcoe.operationGrowthRatePercent =
                                    NumberProperty.new({ value })),
                        );
                    }
                },
                flags: {
                    editable: true,
                    overriden: !!settings.lcoe.operationGrowthRatePercent,
                },
            },
            resultingUnit: "%/year",
        },
    });
    operating.addRoot({
        description: { value: "Operating Costs" },
        costPerYear: lcoe.output.perYear
            .map((x) => x.operatingExpenses.totalCost)
            .concat(
                IterUtils.sum(
                    lcoe.output.perYear,
                    (x) => x.operatingExpenses.totalCost,
                ),
            )
            .map((x) => ({
                value: NumberProperty.new({ value: x, unit: "usd" }),
                integer: true,
            })),
    });
    return operating;
}
