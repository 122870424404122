import type {
    AssetPropertiesGroup, AssetProperty, AssetSceneInstance, BimAssetDescription} from './BimAssetDescription';
import {
    BimAssetFormatVersions
} from './BimAssetDescription';

export function bimAssetMigration(bimAsset: BimAssetDescription):BimAssetDescription{
    let migratedBimAsset = bimAsset;
    const version = bimAsset?.formatVersion ?? 0;
    if(version < BimAssetFormatVersions.RenameTypeIdentifier){
        const instances:AssetSceneInstance[] = [];
        for (const inst of migratedBimAsset.instances) {
            let migratedInst = inst;
            if(inst.type_identifier === 'solar-tracker'){
                let properties = inst.properties;
                if (properties && Array.isArray(properties["dimensions"])) {
                    const migratedDimensions:AssetProperty[] = [];
                    const namesSet = new Set<string>();
                    for (const prop of properties["dimensions"]) {
                        namesSet.add(prop.name);
                        if(prop.name === "motor_gap"){
                            migratedDimensions.push({
                                ...prop,
                                numeric_range:[0, 5]
                            });
                        }else {
                            migratedDimensions.push(prop);
                        }
                    }
                    const modules_row:AssetProperty = {
                        name: "modules_row",
                        value: "1-7-7-7-7-7-M-6-6",
                    };
                    const use_modules_row:AssetProperty = {
                        name: "use_modules_row",
                        value: false,
                    };

                    if(!namesSet.has(modules_row.name)){
                        migratedDimensions.push(modules_row);
                    }
                    if(!namesSet.has(use_modules_row.name)){
                        migratedDimensions.push(use_modules_row);
                    }
                    properties = {
                        ...properties,
                        dimensions: migratedDimensions
                    };
                }
                migratedInst = updateAssetSceneInstance(inst, {type_identifier: 'tracker', properties});
            }else if(inst.type_identifier === 'tracker-fixed'){
                migratedInst = updateAssetSceneInstance(inst, {type_identifier: 'fixed-tilt'});
            }else if(inst.type_identifier === 'solar-transformer'){
                migratedInst = updateAssetSceneInstance(inst, {type_identifier: 'transformer'});
            }else if(inst.type_identifier === 'solar-inverter'){
                migratedInst = updateAssetSceneInstance(inst, {type_identifier: 'inverter'});
            }else if(inst.type_identifier === 'solar-combiner-box'){
                migratedInst = updateAssetSceneInstance(inst, {type_identifier: 'combiner-box'});
            }else if(inst.type_identifier === 'sectionalized-cabinet'){
                migratedInst = updateAssetSceneInstance(inst, {type_identifier: 'sectionalizing-cabinet'});
            }
            instances.push(migratedInst);
        }
        migratedBimAsset = {
            ...migratedBimAsset,
            instances,
            formatVersion: BimAssetFormatVersions.RenameTypeIdentifier,
        };
    }
    if(migratedBimAsset.formatVersion < BimAssetFormatVersions.AddMinEmbedmentToTrackerAndFixedTilt){
        const instances:AssetSceneInstance[] = [];
        for (const inst of migratedBimAsset.instances) {
            let migratedInst = inst;
            if(inst.type_identifier === 'tracker' || inst.type_identifier === 'fixed-tilt'){
                const removeProps = new Set([
                    "reveal",
                    "total_length",
                    "min_reveal",
                    "max_reveal",
                    "min_embedment",
                    "pile_reveal",
                ]);

                let properties = inst.properties;
                if (properties && Array.isArray(properties["position"])) {
                    const migratedPosition: AssetProperty[] = [];
                    for (const prop of properties["position"]) {
                        if (prop.name !== "max_slope") {
                            migratedPosition.push(prop);
                        }
                    }
 
                    properties = {
                        ...properties,
                        position: migratedPosition
                    };
                }
                if(properties && Array.isArray(properties['dimensions'])){
                    const dimensions:AssetProperty[] = [];
                    for (const p of properties['dimensions']) {
                        if(!removeProps.has(p.name)){
                            dimensions.push(p);
                        }
                    }
                    properties = {
                        ...properties,
                        dimensions,
                    };
                }
                if(properties && Array.isArray(properties['piles'])){
                    const piles:AssetProperty[] = [];
                    for (const p of properties['piles']) {
                        if(!removeProps.has(p.name)){
                            piles.push(p);
                        }
                    }
                    properties = {
                        ...properties,
                        piles,
                    };
                }
                if (properties) {
                    const min_embedment: AssetProperty = {
                        name: 'min_embedment',
                        value: 1.2192,
                        unit: 'm',
                        numeric_range: [0, 100],
                    };
                    const max_reveal :AssetProperty = {
                        name: 'max_reveal',
                        value: 2.44,
                        unit: 'm',
                        numeric_range: [0, 100],
                    };
                    const piles =
                        properties["piles"] &&
                        Array.isArray(properties["piles"])
                            ? properties["piles"]
                            : [];
                    piles.push(min_embedment);
                    piles.push(max_reveal);
 
                    properties = {
                        ...properties,
                        piles
                    };
                }
                migratedInst = updateAssetSceneInstance(inst, {properties});
            }
            instances.push(migratedInst);
        }

        migratedBimAsset = {
            ...migratedBimAsset,
            instances,
            formatVersion: BimAssetFormatVersions.AddMinEmbedmentToTrackerAndFixedTilt,
        };
    }
    if (migratedBimAsset.formatVersion < BimAssetFormatVersions.AddFXMRAndSkid) {
        const instances:AssetSceneInstance[] = [];
        for (const inst of migratedBimAsset.instances) {
            let migratedInst = inst;
            migration: {
                if (!migratedInst.properties) {
                    break migration;
                }
                if (migratedInst.type_identifier === 'transformer') {
                    if (!migratedInst.properties['transformer']) {
                        migratedInst.properties['transformer'] = [];
                    }
                    if (!Array.isArray(migratedInst.properties['transformer'])) {
                        break migration;
                    }
                    const skidPropPresent = !!migratedInst.properties['transformer']
                        .find(x => x.name === 'skid')
                    if (skidPropPresent) break migration
                    migratedInst.properties['transformer'].push({
                        name: 'skid',
                        value: false,
                    });
                } else if (migratedInst.type_identifier === 'inverter') {
                    if (!migratedInst.properties['inverter']) {
                        migratedInst.properties['inverter'] = [];
                    }
                    if (!Array.isArray(migratedInst.properties['inverter'])) {
                        break migration;
                    }
                    const fxmrPropPresent = !!migratedInst.properties['inverter']
                        .find(x => x.name === 'fxmr')
                    if (fxmrPropPresent) break migration
                    migratedInst.properties['inverter'].push({
                        name: 'fxmr',
                        value: false,
                    });
                }
            }
            instances.push(migratedInst);
        }
        migratedBimAsset = {
            ...migratedBimAsset,
            instances,
            formatVersion: BimAssetFormatVersions.AddFXMRAndSkid,
        };
    }
    if (migratedBimAsset.formatVersion < BimAssetFormatVersions.AddAssetSelectToMinWiringOnTransformers) {
        const instances:AssetSceneInstance[] = [];
        for (const inst of migratedBimAsset.instances) {
            let migratedInst = inst;
            migration: {
                if (
                    !migratedInst.properties ||
                    migratedInst.type_identifier !== 'transformer'
                ) break migration;
                if (!('circuit' in migratedInst.properties)) break migration;
                const circuit = migratedInst.properties['circuit'] as AssetPropertiesGroup;
                if (!('pattern' in circuit)) break migration;
                const pattern = circuit['pattern'] as AssetPropertiesGroup;
                if (!('min_wiring' in pattern)) break migration;
                const minWiring = pattern['min_wiring'] as AssetPropertiesGroup;
                if (!Array.isArray(minWiring)) break migration;
                const anyWire = minWiring[0] as AssetProperty;
                if (anyWire.description && anyWire.description.startsWith('=asset=')) break migration;
                pattern['min_wiring'] = [];
            }
            instances.push(migratedInst);
        }
        migratedBimAsset = {
            ...migratedBimAsset,
            instances,
            formatVersion: BimAssetFormatVersions.AddFXMRAndSkid,
        };
    }
    if (migratedBimAsset.formatVersion < BimAssetFormatVersions.RemoveFXMRAndSkid) {
        const instances:AssetSceneInstance[] = [];
        for (const inst of migratedBimAsset.instances) {
            let migratedInst = inst;
            migration: {
                if (!migratedInst.properties) {
                    break migration;
                }
                if (migratedInst.type_identifier === 'transformer') {
                    if (!migratedInst.properties['transformer']) {
                        migratedInst.properties['transformer'] = [];
                    }
                    if (!Array.isArray(migratedInst.properties['transformer'])) {
                        break migration;
                    }
                    const skidPropPresent = !!migratedInst.properties['transformer']
                        .find(x => x.name === 'skid')
                    if (!skidPropPresent) {
                        break migration
                    }
                    migratedInst.properties['transformer'] = migratedInst.properties['transformer']
                        .filter(x => x.name !== 'skid');
                } else if (migratedInst.type_identifier === 'inverter') {
                    if (!migratedInst.properties['inverter']) {
                        migratedInst.properties['inverter'] = [];
                    }
                    if (!Array.isArray(migratedInst.properties['inverter'])) {
                        break migration;
                    }
                    const fxmrPropPresent = !!migratedInst.properties['inverter']
                        .find(x => x.name === 'fxmr')
                    if (!fxmrPropPresent) {
                        break migration
                    }
                    migratedInst.properties['inverter'] = migratedInst.properties['inverter']
                        .filter(x => x.name !== 'fxmr');
                }
            }
            instances.push(migratedInst);
        }
        migratedBimAsset = {
            ...migratedBimAsset,
            instances,
            formatVersion: BimAssetFormatVersions.RemoveFXMRAndSkid,
        };
    }



    return migratedBimAsset;
}

function updateAssetSceneInstance(src:AssetSceneInstance, patch:Partial<AssetSceneInstance>){
    return {
        ...src,
        ...patch,
    };
}