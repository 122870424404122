import type { IdBimGeo, IdBimMaterial } from '..';
import type { PropsGroupBase } from '../properties/Props';
import type { AnySolverObjectInput, InObjectDependencies, SolverLegacyPropsInput } from './ObjectsSelector';
import { ObjectsSelector } from './ObjectsSelector';
import type { SolverInstancePatchResult } from './ReactiveSolverBase';
import { ReactiveSolverBase } from './ReactiveSolverBase';
import type { SharedGlobalsInput, GlobalArgsSelector } from './RuntimeGlobals';

export class SolverObjectInstance<
    Props extends SolverLegacyPropsInput,
    NewProps extends PropsGroupBase,
    D extends Partial<InObjectDependencies<Props, NewProps>>,
    GAS extends GlobalArgsSelector,
> extends ReactiveSolverBase {

    readonly solverFunction: (
        currObjectInput: Readonly<D>,
        globalArgs: Readonly<SharedGlobalsInput<GAS>>,
    ) => SolverInstancePatchResult;

    constructor(params: {
        solverIdentifier: string,
        objectsIdentifier: string | string[],
        objectsDefaultArgs: D,
        globalArgsSelector?: GAS,
        cache?: boolean,
        solverFunction: (
            currObjectInput: Readonly<D>,
            globalArgs: Readonly<SharedGlobalsInput<GAS>>,
        ) => SolverInstancePatchResult,
        invalidateInnerCache?: (args: {
            geometriesIds: Set<IdBimGeo>,
            materialsIds: Set<IdBimMaterial>,
        }) => void
    }){
        super({
            identifier: params.solverIdentifier,
            globalArgsSelector: params.globalArgsSelector,
            objFilter: new ObjectsSelector(
                params.objectsIdentifier,
                params.objectsDefaultArgs,
            ),
            cache: params.cache
        });
        this.solverFunction = params.solverFunction;
        this.invalidateInnerCache = params.invalidateInnerCache;
    }

    compute(
        currObjectInput: Readonly<AnySolverObjectInput>,
        sharedArgs?: Readonly<SharedGlobalsInput<GAS>>,
    ): SolverInstancePatchResult {
        return this.solverFunction(currObjectInput as Readonly<D>, sharedArgs as Readonly<SharedGlobalsInput<GAS>>);
    }
}

