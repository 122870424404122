import { Aabb2, Vector2 } from "math-ts";
import { Anchors2D, VectorPrimitivePath, VectorPrimitivePathDescription } from "vector-graphic";
import { wireWidth } from "./config";

export function createManyToOneConnection(params: {
    sources: Vector2[],
    sourceOffset?: number,
    wireOffset?: number,
    targetOffset?: number,
    targetY?: number,
    targetX?: number,
    targetHeight?: number
    wireWidth?: number
}): Anchors2D {
    const targetOffset = params.targetOffset ?? 0;
    const targetHeight = params.targetHeight ?? 0;
    const sourceOffset = params.sourceOffset ?? 0;
    const wireOffset = params.wireOffset ?? 0;
    let targetY = params.targetY;
    if (!targetY) {
        aabbReused.setFromPoints(params.sources);
        targetY = aabbReused.centerY();
    }

    const wiresParams: Array<{
        start: Vector2,
        endY: number,
        endXOffset: number
    }> = []
    let maxEndXOffset = 0;
    let maxSourceX = -Infinity;
    for (const [idx, start] of params.sources.entries()) {
        maxSourceX = Math.max(maxSourceX, start.x);
        let endY;
        if (params.sources.length !== 1) {
            endY = targetY
                + targetHeight * (idx / (params.sources.length - 1) - 0.5);
        } else {
            endY = targetY
        }
        let endXOffset
        if (start.y >= endY) {
            endXOffset = params.sources.length - idx;
        } else {
            endXOffset = idx + 1;
        }
        maxEndXOffset = Math.max(maxEndXOffset, endXOffset);
        wiresParams.push({
            endY,
            start,
            endXOffset
        })
    }

    const targetX = params.targetX ?? (maxSourceX + sourceOffset + wireOffset * maxEndXOffset + targetOffset);

    const paths: VectorPrimitivePathDescription[] = []
    const ends: Vector2[] = [];
    for (const { endXOffset, endY, start } of wiresParams) {
        const pt1 = start.clone();
        const pt2 = new Vector2(targetX - targetOffset - endXOffset * wireOffset, pt1.y);
        const pt3 = new Vector2(pt2.x, endY);
        const pt4 = new Vector2(targetX, pt3.y);
        ends.push(pt4);
        paths.push(new VectorPrimitivePathDescription([pt1, pt2, pt3, pt4]))
    }

    const wiring = new Anchors2D()
    wiring.addPrimitiveAndExpandAabb(new VectorPrimitivePath({
        paths,
        strokeWidth: params.wireWidth ?? wireWidth
    }))
    wiring.points = ends;
    return wiring;
}


const aabbReused = Aabb2.empty();
