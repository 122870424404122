import type { LazyVersioned} from 'engine-utils-ts';
import { LazyBasic, LazyDerived, LegacyLogger, ObservableObject,
	ScopedLogger,
} from 'engine-utils-ts';
import { KrMath, Vector2 } from 'math-ts';
import type { Texture, TextureFilter} from '../3rdParty/three';
import { LinearFilter, NearestFilter, OrthographicCamera, RGBAFormat, WebGLRenderTarget } from '../3rdParty/three';

import type { GpuResources } from '../composer/GpuResources';
import type { KrCamera } from '../controls/MovementControls';
import type { GraphicsSettings } from '../GraphicsSettings';
import type { KreoEngineImpl } from '../KreoEngineImpl';
import { KrMaterial } from '../materials/KrMaterial';
import type { ProgressiveLoddedList } from '../scene/BoundsSceneWrap';
import { LodMask } from '../scene/Submeshes2';
import { EdgeBlendShader } from '../shaders/EdgeBlendPass';
import { OverlayBlendShader } from '../shaders/OverlayBlendshader';
import { FrustumExt } from '../structs/FrustumExt';
import { AAPass } from './AAPass';
import { AccumMixInPass } from './AccumMixInPass';
import { ClearPass } from './ClearPass';
import type { WebglTimerDescr } from './ComposerRenderBlock';
import { ComposerRenderBlock } from './ComposerRenderBlock';
import { GizmosPass } from './GizmosPass';
import { PostProcPass } from './PostProcPass';
import {
	OpaquePass, OverlayPass, TranspPass,
} from './ProgressivePass';
import type { RenderPass } from './RenderPass';
import { RenderTargets, RTIdent } from './RenderTargets';
import type { RenderTimeBudget } from './RenderTimeBudget';
import { SaoPass } from './SaoPass';
import ShaderPass from '../composer/ShaderPass';
import { CopyShader } from '../shaders/CopyShader';


export class StdFrameComposer {

    logger: ScopedLogger;
    gpuResources: GpuResources;
    renderTargets: RenderTargets;

	engineCanvasRenderSize = new ObservableObject<Vector2>({identifier: 'size-source', initialState: new Vector2(100, 100)});
    graphicsSettings: LazyVersioned<GraphicsSettings>;

	resultRenderTargetSize: LazyVersioned<Vector2>;

    frustum: FrustumExt;

    mainBlock: ComposerRenderBlock;
    jitterBlock: ComposerRenderBlock;
    jitterState: JitterRerenderState;

    overlayBlock: ComposerRenderBlock;

    webglTimer: WebglTimerDescr | null = null;
	frameEndSyncObjs: WebGLSync[] = [];
    frameCounter: number = 0;
    mainBlockFinishOutputsVersion: number = 0;

    private readonly glContextLostCallback: () => void;
    private readonly glContextRestoredCallback: () => void;

    constructor({ident, logger, gpuRes, graphicsSettings, frustum, engine, renderToScreen, renderOverlays}: {
        ident: string,
        logger: ScopedLogger,
        gpuRes: GpuResources,
        graphicsSettings: LazyVersioned<GraphicsSettings>,
        frustum: FrustumExt,
        engine: KreoEngineImpl,
        renderToScreen: boolean,
        renderOverlays: boolean,
    }) {
        this.logger = logger.newScope(ident);
        this.gpuResources = gpuRes;
        this.graphicsSettings = graphicsSettings;
        this.resultRenderTargetSize = LazyDerived.new2(
			'composer-render-size',
			[],
			[this.engineCanvasRenderSize, graphicsSettings],
			([canvasSize, graphicsSettings]) => {
				let x = canvasSize.x;
				let y = canvasSize.y;
				const scale = graphicsSettings.resolution_scale;
				return new Vector2(
					KrMath.clamp((x * scale) | 0, 1, 8192 * 2),
					KrMath.clamp((y * scale) | 0, 1, 8192 * 2),
				);
			}
		);
        this.frustum = frustum;

        this.glContextLostCallback = () => this.onGlContextLost();
        this.glContextRestoredCallback = () => this.onGlContextRestored();
        this.gpuResources.renderer.addGlContextEventListeners(this.glContextLostCallback, this.glContextRestoredCallback);

		this.renderTargets = new RenderTargets(new ScopedLogger('rts'), gpuRes.renderer);
        
        this.jitterState = new JitterRerenderState();
        const jitterFrustum = this.jitterState.frustum;

        const fxaaPower = LazyDerived.new1('aa-power', null, [this.jitterState.jitterMixInWeight], ([mixInPower]) => {
            if (mixInPower == 0) {
                return 1;
            }
            if (mixInPower > 0.1) {
                return mixInPower;
            }
            return 0;
        })

        const submeshesCulledList = engine.engineScene.submeshes.cullingFor(frustum);

        const jitterBlockMixIn = this.jitterState.jitterMixInWeight;

        this.mainBlock = StdFrameComposer.submeshesRenderBlock(this.logger, this.renderTargets, '_main', graphicsSettings, submeshesCulledList, frustum, new LazyBasic<number>('', 1), engine);
        this.jitterBlock = StdFrameComposer.submeshesRenderBlock(this.logger, this.renderTargets, '_double', graphicsSettings, submeshesCulledList, jitterFrustum, jitterBlockMixIn, engine);

        this.overlayBlock = StdFrameComposer.submeshesOverlayRenderBlock(
            this.logger,
            this.renderTargets,
            '_main',
            graphicsSettings,
            submeshesCulledList,
            frustum,
            fxaaPower,
            engine,
            renderToScreen,
            renderOverlays,
        );

        this.initWebGlTimer();
        this.invalidateFrame();
    }

    dispose() {
        this.gpuResources.renderer.removeGLContextEventListeners(this.glContextLostCallback, this.glContextRestoredCallback);
        this.renderTargets.dispose();
    }

    setSize(x: number, y: number) {
		this.engineCanvasRenderSize.applyPatch({
			patch: new Vector2(x, y),
		});
    }

    invalidateFrame() {
        this.mainBlock._invalidateAll();
        this.jitterBlock._invalidateAll();
        this.overlayBlock._invalidateAll();
    }


    render(timeBudget: RenderTimeBudget): boolean { // return true if finished
        this.frameCounter += 1;
		const screenSize = this.resultRenderTargetSize.poll();
        if (screenSize.x <= 1 || screenSize.y <= 1) {
            return false;
        }

		if (this.renderTargets.updateScreenSize(screenSize)) {
			this.invalidateFrame();
		}

		this.gpuResources.renderer.setViewport(0, 0, screenSize.x, screenSize.y);

        this.gpuResources.renderer.startNewFrame();

		const gl = this.gpuResources.renderer.context;
        const settings = this.graphicsSettings.poll();

        const mainBlockResult = this.mainBlock.render(timeBudget, this.webglTimer, this.gpuResources);

        if (settings.cumulative_antialias) {
            let resetJitter = false;
            if (mainBlockResult.finished == false) {
                resetJitter = true;
                this.logger.debug('reset jitter because main render didnt finish');
            }
            if (mainBlockResult.renderedAnything) {
                resetJitter = true;
                this.logger.debug('reset jitter because main rendered this frame');
            }
            if (resetJitter) {
                this.jitterState.reset();
            } else if (timeBudget.haveMuchTimeLeft()) {
                const gpuBudget = timeBudget.gpuTimeBudgetLeft();
                if (gpuBudget > 0) {
                    timeBudget.decreaseGpuBudgetLeftBy(gpuBudget * 0.5)
                }
                if (!this.jitterState.isDone()) {
                    this.jitterState.updateFrustumForJitterOffset(this.frustum.camera, this.resultRenderTargetSize.poll());
                    const jitRender = this.jitterBlock.render(timeBudget, this.webglTimer, this.gpuResources);
                    if (jitRender.finished) {
                        this.jitterState.increment();
                    }
                }
            }
        } else {
            this.jitterState.reset();
        }
        
        const overlayRender = this.overlayBlock.render(timeBudget, this.webglTimer, this.gpuResources);

        gl.flush();
        gl.finish();

        return mainBlockResult.finished && this.jitterState.isDone() && overlayRender.finished;
    }


    static submeshesRenderBlock(
        logger: ScopedLogger,
        renderTargets: RenderTargets,
        renderTargetsPostfix: string,
        renderSettings: LazyVersioned<GraphicsSettings>,
        culledList: ProgressiveLoddedList,
        frustum: FrustumExt,
        mixInAccumPower: LazyVersioned<number>,
        engine: KreoEngineImpl,
    ): ComposerRenderBlock {

        const submeshes = engine.engineScene.submeshes;

        const clearColor = LazyDerived.new1('clearColor', null, [renderSettings], ([rs]) => rs._backgroundColor);
        const clearAlpha = LazyDerived.new1('clearAlpha', null, [renderSettings], ([rs]) => rs._backgroundAlpha);

        const rts = {
            opaqueColorRT: RTIdent.withDepth('opaque_color' + renderTargetsPostfix, 'own'),
            edgesStd: RTIdent.withDepth('edges_std' + renderTargetsPostfix, 'opaque_color' + renderTargetsPostfix),
            edgesTransp: RTIdent.withDepth('edges_transp' + renderTargetsPostfix, 'opaque_color' + renderTargetsPostfix),
            normalsRT: RTIdent.simple('opaque_normals' + renderTargetsPostfix),
            opaqueLitNoDepth: RTIdent.simple('opaque_lit' + renderTargetsPostfix),
            opaqueLit: RTIdent.withDepth('opaque_lit' + renderTargetsPostfix, 'opaque_color' + renderTargetsPostfix),
            accumRT: RTIdent.simple('aa_accumulator'), // shared between main and double blocks
        }

        const passes: RenderPass[] = [

            new ClearPass([rts.opaqueColorRT, rts.normalsRT], clearColor, clearAlpha, [engine.engineFullGraphicsSettings]),
            new ClearPass([rts.edgesStd], 0, 0, []),

            new OpaquePass([rts.opaqueColorRT, rts.edgesStd, rts.normalsRT], culledList, submeshes.std_opaque_provider, engine.renderSettings),
            new SaoPass([rts.opaqueColorRT, rts.normalsRT], rts.opaqueLitNoDepth),

            new ClearPass([rts.edgesTransp], 0, 0, []),
            new TranspPass([rts.opaqueLit, rts.edgesTransp], culledList, submeshes.std_transp_provider, engine.renderSettings),
            
            new AccumMixInPass('mix-in' + renderTargetsPostfix, [rts.opaqueLit, rts.edgesStd], rts.accumRT, mixInAccumPower),
        ];

        return new ComposerRenderBlock(
            'submeshes-op-tr',
            logger,
            renderTargets,
            renderSettings,
            passes,
            frustum
        );
    }

    static submeshesOverlayRenderBlock(
        logger: ScopedLogger,
        renderTargets: RenderTargets,
        renderTargetsPostfix: string,
        renderSettings: LazyVersioned<GraphicsSettings>,
        culledList: ProgressiveLoddedList,
        frustum: FrustumExt,
        antiAliasPower01: LazyVersioned<number>,
        engine: KreoEngineImpl,
        renderToScreen: boolean,
        renderOverlays: boolean,
    ): ComposerRenderBlock {

        const submeshes = engine.engineScene.submeshes;

        const rts = {
            accumRT: RTIdent.simple('aa_accumulator'),
            opaqueLitCopy: RTIdent.simple('opaque_lit' + renderTargetsPostfix),
            edgesStd: RTIdent.withDepth('edges_std' + renderTargetsPostfix, 'opaque_color' + renderTargetsPostfix),
            edgesTransp: RTIdent.withDepth('edges_transp' + renderTargetsPostfix, 'opaque_color' + renderTargetsPostfix),
			overlay: RTIdent.simple('overlay' + renderTargetsPostfix),
			overlayEdges: RTIdent.simple('edges_overlay' + renderTargetsPostfix),
            preScreen: RTIdent.withDepth('pre_screen' + renderTargetsPostfix, 'opaque_color' + renderTargetsPostfix),
            preScreenWithoutDepth: RTIdent.simple('pre_screen' + renderTargetsPostfix),
        }

        const passes: (RenderPass | false)[] = [
            new AAPass([rts.accumRT], rts.preScreenWithoutDepth, antiAliasPower01),

            renderOverlays && new ClearPass([rts.overlay, rts.overlayEdges], 0, 0, [culledList]),
			renderOverlays && new OverlayPass([rts.overlay, rts.overlayEdges], culledList, submeshes.overlay_jobs_provider, {edgesLodsToRender: LodMask.All, meshesLodsToRender: LodMask.All}),
            renderOverlays && new PostProcPass('add_overlay', [rts.overlay, rts.preScreenWithoutDepth], rts.preScreenWithoutDepth, KrMaterial.newPostMat(OverlayBlendShader)),
            renderOverlays && new PostProcPass('blend_edges', [rts.edgesStd, rts.overlayEdges, rts.preScreenWithoutDepth], rts.preScreenWithoutDepth, KrMaterial.newPostMat(EdgeBlendShader),
                [
                    [rts.overlayEdges, 'tSelection'],
                ]
            ),
            new GizmosPass(rts.preScreen, engine.gizmosController),
        ];

        if (renderToScreen) {
            passes.push(PostProcPass.newCopyPass('to_screen', rts.preScreen, RTIdent.simple('screen')) );
        }

        return new ComposerRenderBlock(
            'submeshes-overlay',
            logger,
            renderTargets,
            renderSettings,
            passes.filter(p => typeof p === 'object') as RenderPass[],
            frustum
        );
    }

    geLastRTIdent(): RTIdent {
        const lastRenderPass = this.overlayBlock.passes.at(-1)!;

        if (lastRenderPass.outputs[0].ident === 'screen') {
            return lastRenderPass.inputs[0];
        } else {
            return lastRenderPass.outputs[0];
        }
    }


    downsampleAndReadPixels(rtIdent: RTIdent, width:number, height:number, flipVertically = true): Uint8Array | null {
        const rt = this.renderTargets.getRT(rtIdent).wrt;
        const renderer = this.gpuResources.renderer;
        const changeTextureMinFilter = (texture:Texture, desiredMinFilter:TextureFilter) => {
			if (texture.minFilter === desiredMinFilter) {
				return;
			}
			const prop = renderer.properties.get(texture);
			if (!prop.__webglTexture) {
				return;
			}
			const gl = renderer.context;
			let glFilter = 0;
			if (desiredMinFilter === NearestFilter) {
				glFilter = gl.NEAREST;
			} else if (desiredMinFilter === LinearFilter) {
				glFilter = gl.LINEAR;
			} else {
				LegacyLogger.error('unsupported min filter');
				return;
			}
			renderer.state.bindTexture(gl.TEXTURE_2D, prop.__webglTexture);
			gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, glFilter);
			texture.minFilter = desiredMinFilter;
		};
		const rtParams = {
			minFilter: LinearFilter,
			magFilter: LinearFilter,
			format: RGBAFormat,
			stencilBuffer: false,
			depthBuffer: false
		};

		width = Math.round(width);
		height = Math.round(height);

		let pixelsArray: Uint8Array | null = null;
		const prevMinFilter = rt.texture.minFilter;

		const screenshotBuffer = new WebGLRenderTarget(width, height, rtParams);
		const downsamplePass = new ShaderPass(CopyShader);
		let preScreenshotBuffer: WebGLRenderTarget | null = null;

		try {
			changeTextureMinFilter(rt.texture, LinearFilter);

			const portionX = width / rt.width;
			const portionY = height / rt.height;

			const portion = new Vector2(portionX, portionY);
			portion.multiplyScalar(1 / Math.max(portionX, portionY));

			downsamplePass.uniforms.portion.value.copy(portion);

			const isDoubleDownsample = rt.width / width * portion.x > 2.0 || rt.height / height * portion.y > 2.0;
			if (isDoubleDownsample) {
				preScreenshotBuffer = new WebGLRenderTarget(width * 2, height * 2, rtParams);

				downsamplePass.renderShader(renderer, preScreenshotBuffer, 	rt, false);
				downsamplePass.uniforms.portion.value.set(1,1);
				downsamplePass.renderShader(renderer, screenshotBuffer, 		preScreenshotBuffer, flipVertically);
			} else {
				downsamplePass.renderShader(renderer, screenshotBuffer, 		rt, flipVertically);
			}

			const byteArray = new Uint8Array(width * height * 4);
			renderer.readRenderTargetPixels(screenshotBuffer, 0, 0, width, height, byteArray);
            const screenshotMade = byteArray.some(v => v !== 0);
			if (screenshotMade) {
				pixelsArray = byteArray;
			} else {
				LegacyLogger.error('failed to read pixels');
			}
		} catch (e) {
			LegacyLogger.error(e);
		} finally {
			changeTextureMinFilter(rt.texture, prevMinFilter);
			downsamplePass.dispose();
			screenshotBuffer.dispose();
			if (preScreenshotBuffer) {
				preScreenshotBuffer.dispose();
			}
		}
		return pixelsArray;
	}

    private onGlContextLost() {
        const context = this.gpuResources.renderer.context;

        if(this.webglTimer) {
            context.endQuery(this.webglTimer.TIME_ELAPSED_EXT)
        }
        
        this.mainBlock.freeGLQueriesOnContextLoss(context);
        this.jitterBlock.freeGLQueriesOnContextLoss(context);
        this.overlayBlock.freeGLQueriesOnContextLoss(context);
    }

    private onGlContextRestored() {
        this.initWebGlTimer();
        this.invalidateFrame();
    }

    private initWebGlTimer() {
        this.webglTimer = this.gpuResources.renderer.context.getExtension('EXT_disjoint_timer_query_webgl2') ?? null;

		if (!this.webglTimer) {
			console.warn('webgl timer is not availbale, relying on js timing only');
        }
    }
}




const _JitterVectors = [
    [[ 0, 0 ]],
    [[ 4, 4 ], [ - 4, - 4 ]],
    [[ - 2, - 6 ], [ 6, - 2 ], [ - 6, 2 ], [ 2, 6 ]],
    [[ 1, - 3 ], [ - 1, 3 ], [ 5, 1 ], [ - 3, - 5 ], [ - 5, 5 ], [ - 7, - 1 ], [ 3, 7 ], [ 7, - 7 ]],
    [[ 1, 1 ], [ - 1, - 3 ], [ - 3, 2 ], [ 4, - 1 ], [ - 5, - 2 ], [ 2, 5 ], [ 5, 3 ], [ 3, - 5 ], [ - 2, 6 ], [ 0, - 7 ], [ - 4, - 6 ], [ - 6, 4 ], [ - 8, 0 ], [ 7, - 4 ], [ 6, 7 ], [ - 7, - 8 ]],
    [[ - 4, - 7 ], [ - 7, - 5 ], [ - 3, - 5 ], [ - 5, - 4 ], [ - 1, - 4 ], [ - 2, - 2 ], [ - 6, - 1 ], [ - 4, 0 ], [ - 7, 1 ], [ - 1, 2 ], [ - 6, 3 ], [ - 3, 3 ], [ - 7, 6 ], [ - 3, 6 ], [ - 5, 7 ], [ - 1, 7 ], [ 5, - 7 ], [ 1, - 6 ], [ 6, - 5 ], [ 4, - 4 ], [ 2, - 3 ], [ 7, - 2 ], [ 1, - 1 ], [ 4, - 1 ], [ 2, 1 ], [ 6, 2 ], [ 0, 4 ], [ 4, 4 ], [ 2, 5 ], [ 7, 5 ], [ 5, 6 ], [ 3, 7 ]]
];


class JitterRerenderState {
    
    readonly offsets: Vector2[] = [];

    readonly nextJitterIndex = new LazyBasic<number>('', 0);

    readonly jitterMixInWeight: LazyVersioned<number>;

    readonly frustum = new FrustumExt(1, new OrthographicCamera(-1, 1, 1, -1, 0, 100));

    constructor() {
        const jitterOffsets = _JitterVectors[5].map(t => new Vector2(t[0] / 16, t[1] / 16));
        this.offsets = [];
        for (const jo of jitterOffsets) {
            this.offsets.push(jo);
            // this.offsets.push(jo.clone().multiplyScalar(-1));
        }
        // this.offsets.sort((v1, v2) => v2.length() - v1.length());
        Object.freeze(this.offsets);

        this.jitterMixInWeight = LazyDerived.new1<number, number>(
            '', null,
            [this.nextJitterIndex],
            ([nextJitterIndex]) => {
                const jitterIndex = nextJitterIndex - 1;
                if (jitterIndex < 0) {
                    return 0;
                }
                return 1 / (jitterIndex + 2);
            }
        );
    }

    reset() {
        this.nextJitterIndex.replaceWith(0);
    }

    increment() {
        const curr = this.nextJitterIndex.poll();
        const next = curr + 1;
        if (next > this.offsets.length) {
            return;
        }
        this.nextJitterIndex.replaceWith(next);
    }

    isDone() {
        return this.nextJitterIndex.poll() === this.offsets.length;
    }

    updateFrustumForJitterOffset(sourceCamera: KrCamera, renderSize: Vector2) {
        let camera: KrCamera;
        if (sourceCamera.constructor === this.frustum.camera.constructor) {
            camera = this.frustum.camera;
            camera.copy(sourceCamera as any);
        } else {
            camera = sourceCamera.clone();
        }
        let currentJitterOffset = this.offsets[this.nextJitterIndex.poll()];
        if (currentJitterOffset === undefined) {
            console.error('inalid jitter index', this.nextJitterIndex.poll());
            currentJitterOffset = new Vector2(0,0);
        }
        camera.setViewOffset(renderSize.x, renderSize.y, currentJitterOffset.x, currentJitterOffset.y, renderSize.x, renderSize.y);
        this.frustum.update(camera);
    }
}
