import { DefaultMap, StreamTransformedAccumulator, peekCurrentEventFrame, type BasicCollectionUpdates, unsafePushToEventStackFrame, unsafePopFromEventStackFrame, Updated } from 'engine-utils-ts';
import type {
    Id,
	VerdataCollectionPatch, VerdataCollectionUpdates,
} from 'verdata-ts';

import type {
	CollectionAdditionalContext,
} from './CollectionAdditionalContext';
import type { SceneInstanceSerializable} from './SceneInstancesSerializer';
import { SceneInstancesPatchesAfterMigrations, SceneInstancesSerializer, SceneInstancesVersions } from './SceneInstancesSerializer';
import type { Vector3 } from 'math-ts';
import type { BimReactiveRuntime } from 'src/runtime/BimReactiveRuntime';
import { EntitiesPersisted, VerdataApplyPatchEventName } from './EntitiesPersisted';
import { type IdBimScene, type SceneInstance, type SceneInstancePatch, type SceneInstances } from 'src/scene/SceneInstances';
import { SceneObjDiff } from '../scene/SceneObjDiff';
import type { BimCustomRuntimes } from 'src/runtime/BimCustomRuntimes';
import type { SceneInstancesArchetypes } from 'src/scene/SceneInstancesArhetypes';
import { EntitiesUpdated } from 'src/collections/EntitiesCollectionUpdates';

const PersitedInstanceUpdateFlags = ~(
    SceneObjDiff.Selected | SceneObjDiff.Highlighted
    | SceneObjDiff.Hidden | SceneObjDiff.WorldPosition
    | SceneObjDiff.SpatialChildrenList | SceneObjDiff.SpatialDescendants
    | SceneObjDiff.ChildrenSortOrder
    | SceneObjDiff.GeometryReferenced
);

export class SceneInstanceEntitiesPersisted extends EntitiesPersisted<SceneInstance, SceneInstanceSerializable>
{
    private readonly globalMigrationsData: DefaultMap<SceneInstancesVersions, SceneInstancesPatchesAfterMigrations<any>>;
    private readonly reactiveRuntimes: BimReactiveRuntime;
    private readonly customRuntimes: BimCustomRuntimes;

    constructor(
        entities: SceneInstances,
        migrationFn: (
            inst: SceneInstanceSerializable,
            version: SceneInstancesVersions,
            id: IdBimScene,
            globalMigrationsData: DefaultMap<SceneInstancesVersions, SceneInstancesPatchesAfterMigrations<any>>
        ) => SceneInstanceSerializable,
        archetypes: SceneInstancesArchetypes,
        versionedPortionFromState: (state: SceneInstance) => SceneInstanceSerializable,
        versionedPortionToState: (vd: SceneInstanceSerializable) => Partial<SceneInstance>,
        reactiveRuntimes: BimReactiveRuntime,
        customRuntimes: BimCustomRuntimes,
    ) {
        const globalMigrationsData = new DefaultMap<SceneInstancesVersions, SceneInstancesPatchesAfterMigrations<any>>((version) => {
            switch (version) {
                case SceneInstancesVersions.RecenterTracker:
                    return new SceneInstancesPatchesAfterMigrations<Map<IdBimScene, Vector3>>(
                        new Map<IdBimScene, Vector3>(), 
                        recenterTrackerApply
                    );
                default:
                    return new SceneInstancesPatchesAfterMigrations<undefined>(
                        undefined, () => []
                    );
            }
        });

        const updatesAccumulator = new StreamTransformedAccumulator<BasicCollectionUpdates<any>, VerdataCollectionUpdates<Id>>(
            entities.updatesStream,
            (update) => {
                const e = peekCurrentEventFrame();
                if (e.isEventDerived && !e.doesInvalidatePersistedState) {
                    return null;
                }
                if (update instanceof EntitiesUpdated) {
                    if ((update.allFlagsCombined & PersitedInstanceUpdateFlags) === 0) {
                        return null;
                    }
                    if ((update.allFlagsCombined & PersitedInstanceUpdateFlags) !== update.allFlagsCombined) {
                        // filter out updates that do not invalidate objects
                        const updatedIds: Id[] = [];
                        for (let i = 0; i < update.ids.length; ++i) {
                            const flags = update.diffs[i];
                            if ((flags & PersitedInstanceUpdateFlags) !== 0) {
                                updatedIds.push(update.ids[i]);
                            }
                        }
                        update = new Updated(updatedIds);
                    }
                }
                return {update, isCausedByVerdataApply: e.identifier === VerdataApplyPatchEventName};
            }
        );

        super({
            entities: entities,
            serializer: new SceneInstancesSerializer(
                migrationFn,
                archetypes,
                globalMigrationsData
            ),
            versionedPortionFromState,
            versionedPortionToState,
            updates: updatesAccumulator,
        });

        this.globalMigrationsData = globalMigrationsData;
        this.reactiveRuntimes = reactiveRuntimes;
        this.customRuntimes = customRuntimes;
    }

	applyPatch(patch: VerdataCollectionPatch<SceneInstanceSerializable, CollectionAdditionalContext | null>)
        : {toDeleteLater: number[]} {
        
        const entities = this._entities as SceneInstances;
        
		const event = unsafePushToEventStackFrame({identifier: VerdataApplyPatchEventName});
        try {

            const allInstancesIdsLeftToReset = new Set(entities.allIds());
            const toAlloc: [number, Partial<SceneInstance>][] = [];
            const toDelete: number[] = [];
            
            if (patch.toAlloc) {
                for (const [id, itemToAlloc] of patch.toAlloc) {
                    toAlloc.push([id, this.versionedPortionToState(itemToAlloc)]);
                }
            }

            // instances are special case
            // because runtime generated non persisted representations can reference other collections
            // we have to reset all instances to persisted state default
            // and mark all of them for solvers as dirty

            if (patch.toPatch) {
                for (const [id, data] of patch.toPatch) {
                    allInstancesIdsLeftToReset.delete(id);
                    toDelete.push(id);
                    toAlloc.push([id, this.versionedPortionToState(data)]);
                }
            }
            if (patch.toDelete) {
                for (const id of patch.toDelete) {
                    allInstancesIdsLeftToReset.delete(id);
                }
            }
            for (const id of allInstancesIdsLeftToReset) {
                const instance = entities.perId.get(id);
                if (instance) {
                    const toVersionedState = this.versionedPortionFromState(instance);
                    toAlloc.push([id, this.versionedPortionToState(toVersionedState)]);
                    toDelete.push(id);
                }
            }

            if (patch.toDelete) {
                for (const id of patch.toDelete) {
                    toDelete.push(id);
                }
            }
            entities.delete(toDelete);
            entities.allocate(toAlloc);

            if (patch.toAlloc) {
                if (patch.collectionContext?.sharedEntitiesIds) {
                    entities.makeShared(patch.collectionContext.sharedEntitiesIds);
                }
                if (patch.collectionContext?.civilCoordsOrigin) {
                    entities.patchSceneOrigin({cartesianCoordsOrigin: patch.collectionContext.civilCoordsOrigin});
                }
                if (patch.collectionContext?.projectionOrigin) {
                    entities.patchSceneOrigin({wgsProjectionOrigin: patch.collectionContext.projectionOrigin});
                }
            }
        } finally {
            unsafePopFromEventStackFrame(event);
        }

        for (const [_, patchesToApply] of this.globalMigrationsData) {
            const patches = patchesToApply.createPatchesToApplyFn(entities, patchesToApply.postMigrationPatchesData);
            entities.applyPatches(patches);
        }
        this.globalMigrationsData.clear();

        return {toDeleteLater: []};
	}
}


function recenterTrackerApply(sceneInstances: SceneInstances, offsetPerTracker: Map<IdBimScene, Vector3>) {
    const patches: [IdBimScene, SceneInstancePatch][] = [];
    
    let patch: [IdBimScene, SceneInstancePatch] | null = null;
    for (const [id, instance] of sceneInstances.perId) {
        const transform = instance.localTransform.clone();

        const offset = offsetPerTracker.get(id);
        if (offset !== undefined) {
            const offsetTransformed = offset.clone().applyQuaternion(instance.localTransform.rotation);
            transform.position.add(offsetTransformed);
            patch = [id, { localTransform: transform }];
        }
        
        const parentOffset = offsetPerTracker.get(instance.spatialParentId);
        if (parentOffset !== undefined) {
            const childOffsetTransformed = parentOffset.clone().negate();
            transform.position.add(childOffsetTransformed);
            patch = [id, { localTransform: transform }];
        }

        if (patch !== null) {
            patches.push(patch);
        }
    }
    return patches;
}