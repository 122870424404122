<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.20001 2.7002C8.20001 2.42405 8.42387 2.2002 8.70001 2.2002H11.3C11.5762 2.2002 11.8 2.42405 11.8 2.7002V17.3002C11.8 17.5763 11.5762 17.8002 11.3 17.8002H8.70001C8.42387 17.8002 8.20001 17.5763 8.20001 17.3002V16.2002V3.8002V2.7002ZM14.2 6.7002C14.2 6.42405 14.4239 6.2002 14.7 6.2002H17.3C17.5762 6.2002 17.8 6.42405 17.8 6.70019V17.3002C17.8 17.5763 17.5762 17.8002 17.3 17.8002H14.7C14.4239 17.8002 14.2 17.5763 14.2 17.3002V16.2002V7.8002V6.7002ZM2.20001 8.7002C2.20001 8.42405 2.42387 8.2002 2.70001 8.2002H5.30002C5.57617 8.2002 5.80002 8.42405 5.80002 8.7002V17.3002C5.80002 17.5763 5.57617 17.8002 5.30002 17.8002H2.70001C2.42387 17.8002 2.20001 17.5763 2.20001 17.3002V16.2002V9.8002V8.7002Z"
        fill="currentColor"
    />
</svg>
