// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class PropertySelectionValue implements flatbuffers.IUnpackableObject<PropertySelectionValueT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):PropertySelectionValue {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsPropertySelectionValue(bb:flatbuffers.ByteBuffer, obj?:PropertySelectionValue):PropertySelectionValue {
  return (obj || new PropertySelectionValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsPropertySelectionValue(bb:flatbuffers.ByteBuffer, obj?:PropertySelectionValue):PropertySelectionValue {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new PropertySelectionValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value():string|null
value(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
value(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

options(index: number):string
options(index: number,optionalEncoding:flatbuffers.Encoding):string|Uint8Array
options(index: number,optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
}

optionsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startPropertySelectionValue(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, valueOffset, 0);
}

static addOptions(builder:flatbuffers.Builder, optionsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, optionsOffset, 0);
}

static createOptionsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startOptionsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endPropertySelectionValue(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createPropertySelectionValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset, optionsOffset:flatbuffers.Offset):flatbuffers.Offset {
  PropertySelectionValue.startPropertySelectionValue(builder);
  PropertySelectionValue.addValue(builder, valueOffset);
  PropertySelectionValue.addOptions(builder, optionsOffset);
  return PropertySelectionValue.endPropertySelectionValue(builder);
}

unpack(): PropertySelectionValueT {
  return new PropertySelectionValueT(
    this.value(),
    this.bb!.createScalarList<string>(this.options.bind(this), this.optionsLength())
  );
}


unpackTo(_o: PropertySelectionValueT): void {
  _o.value = this.value();
  _o.options = this.bb!.createScalarList<string>(this.options.bind(this), this.optionsLength());
}
}

export class PropertySelectionValueT implements flatbuffers.IGeneratedObject {
constructor(
  public value: string|Uint8Array|null = null,
  public options: (string)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const value = (this.value !== null ? builder.createString(this.value!) : 0);
  const options = PropertySelectionValue.createOptionsVector(builder, builder.createObjectOffsetList(this.options));

  return PropertySelectionValue.createPropertySelectionValue(builder,
    value,
    options
  );
}
}
