import type { Bim } from '../../Bim';
import { NumberProperty } from '../../properties/PrimitiveProps';
import type { PropertyGroup } from '../../properties/PropertyGroup';
import { StateType } from '../ConfigsArchetypes';

export const TrenchesConfigType = 'trenches-panel-config';

export interface TrenchesConfig extends PropertyGroup {
    mv:{
        space: NumberProperty;
        min_depth: NumberProperty;
    },
    dc_feeder:{
        space: NumberProperty;
        min_depth: NumberProperty;
    },
    ac_feeder:{
        space: NumberProperty;
        min_depth: NumberProperty;
    },
    whip:{
        space: NumberProperty;
        min_depth: NumberProperty;
    },
}

export function registerTrenchesConfig(bim: Bim) {

    

	bim.configs.archetypes.registerArchetype(
		{
			type_identifier: TrenchesConfigType,
            properties: () => {
                const properties: TrenchesConfig = {
                    mv:{
                        space: NumberProperty.new({
                            value: 24,
                            unit: "inch",
                            range: [0, Number.MAX_VALUE],
                            description: "space between wires",
                        }),
                        min_depth: NumberProperty.new({
                            value: 36,
                            unit: "inch",
                            range: [0, Number.MAX_VALUE],
                            description: "min trench depth",
                        }),
                    },
                    ac_feeder:{
                        space: NumberProperty.new({
                            value: 12,
                            unit: "inch",
                            range: [0, Number.MAX_VALUE],
                            description: "space between wires",
                        }),
                        min_depth: NumberProperty.new({
                            value: 30,
                            unit: "inch",
                            range: [0, Number.MAX_VALUE],
                            description: "min trench depth",
                        }),
                    },
                    dc_feeder:{
                        space: NumberProperty.new({
                            value: 2,
                            unit: "inch",
                            range: [0, Number.MAX_VALUE],
                            description: "space between wires",
                        }),
                        min_depth: NumberProperty.new({
                            value: 30,
                            unit: "inch",
                            range: [0, Number.MAX_VALUE],
                            description: "min trench depth",
                        }),
                    },
                    whip:{
                        space: NumberProperty.new({
                            value: 6,
                            unit: "inch",
                            range: [0, Number.MAX_VALUE],
                            description: "space between wires",
                        }),
                        min_depth: NumberProperty.new({
                            value: 30,
                            unit: "inch",
                            range: [0, Number.MAX_VALUE],
                            description: "min trench depth",
                        }),
                    }
                };
                return properties;
            },
            connectionToType: 'substation',
            stateType: StateType.CreateByConnection,
		}
	);
}
