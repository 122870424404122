import type { ProjectMetricsType, PropertyGroup} from 'bim-ts';
import {
    createPropertyGroupPuiTransformers, MetricTemplateGroup, MetricTemplateValue,
    ProjectMetricsDefaultTemplate
} from 'bim-ts';
import { LazyDerived, StringUtils } from 'engine-utils-ts';
import { VerdataVersionDescription, ZeroVersionIdentifier } from 'src/ComparisonMode/ComparisonMode';
import { buildFromConfigObject } from 'ui-bindings';
import type { VerDataCurrentVersions } from "./ProjectOverviewPUI";
import type { VerDataSyncer } from 'verdata-ts';

export function buildUiFromMetric(metrics: Partial<ProjectMetricsType> | null){
    const configBuilderSettings = createPropertyGroupPuiTransformers({
        calculated: true,
        readonly: true,
    });
    const pui = buildFromConfigObject({
        configObj: formatMetricByTemplate(metrics),
        configBuilderParams: configBuilderSettings,
        configPatchesCallback: (patch) => {
            console.error("all props must be readonly", patch);
            //readonly
        },
        puiBuilderParams: {
            sortChildrenDefault: false,
            
        }
    });

    return pui;
}

export function createCurrentVersionsLazy(args: {
    projectVds: VerDataSyncer,
    catalogVds: VerDataSyncer,
}){
    const currentVersionsLazy = LazyDerived.new0<VerDataCurrentVersions>(
        "current-version-lazy",
        [args.projectVds.status, args.catalogVds.status],
        () => {
            function getCurrentVersion(vds: VerDataSyncer) {
                const id = vds.getCurrentVersionId();
                const description = getVersionDescription(vds, id);
                return description;
            }

            const versions: VerDataCurrentVersions = {
                projectVersion: getCurrentVersion(args.projectVds),
                catalogVersion: getCurrentVersion(args.catalogVds),
            };
            return versions;
        },
    );
    return currentVersionsLazy;
}

export function getVersionDescription(vds: VerDataSyncer, id: number){ 
    if(id === 0){
        return new ZeroVersionIdentifier();
    }
    const description = LazyDerived.new1("history", null, [vds.history], ([history]) =>{
        const version = history
            .versions.find((v) => v.id === id);
            return version?.textDescription;
    })

    return new VerdataVersionDescription(id, description);
}


function formatMetricByTemplate(
    source: Partial<ProjectMetricsType> | null,
    template: MetricTemplateGroup = ProjectMetricsDefaultTemplate,
) {
    const result: PropertyGroup = {};
    traverseByTemplateGroup(template, source, result);
    return result;
}

function traverseByTemplateGroup(
    template: MetricTemplateGroup,
    source: Partial<ProjectMetricsType> | null,
    result: PropertyGroup,
) {
    for (const child of template.children) {
        const name = child.name.trim() ? child.name : StringUtils.capitalizeFirstLatterInWord(child.key);
        if (child instanceof MetricTemplateValue) {
            result[name] = source?.[child.key] ?? null;
        } else if(child instanceof MetricTemplateGroup) {
            const ref: PropertyGroup = {};
            result[name] = ref;
            traverseByTemplateGroup(child, source, ref);
        } else {
            console.error('unexpected type', child);
        }
    }
}
