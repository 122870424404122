<script lang="ts">
    import type {
        NotificationPanelItem,
        NotificationsLogItem,
    } from "./NotificationsLogStore";
    import NotificationMessage from "./NotificationMessage.svelte";
    import type { NotificationItem } from "./NotificationsHandler";
    import { NotificationType } from "ui-bindings";

    const maxGroupHeight = 118;

    export let group: NotificationPanelItem;
    export let convertDateTime: (timestamp: number) => string;
    export let updateNotification: (notification: NotificationsLogItem) => void;

    $: rootNotification = group.notifications.find((n) => n.isRootInGroup);
    $: notifications = (
        rootNotification
            ? group.notifications.filter(
                  (n) => n.uniqueIdent !== rootNotification?.uniqueIdent
              )
            : group.notifications
    ) as NotificationItem[];
    $: isGroup = !!group.groupName;

    let groupHeight: number;
    let truncated: boolean;
    $: truncated = isGroup && (truncated || groupHeight > maxGroupHeight);
    let expanded = false;

    let combinedStatus = NotificationType.Info;
    $: {
        notifications.forEach((n) => {
            combinedStatus = n.type > combinedStatus ? n.type : combinedStatus;
        });
    }
    $: groupRoot = (rootNotification ?? {
        header: group.groupName,
        type: NotificationType.Info,
    }) as NotificationItem;

    function onFinish(notification: NotificationItem) {
        updateNotification(<NotificationsLogItem>notification);
    }
</script>

<div class="group">
    <div
        class:group-content={truncated && !expanded}
        bind:clientHeight={groupHeight}
        style="--max-height:{maxGroupHeight}px;"
    >
        {#if isGroup}
            <NotificationMessage
                status={notifications.length ? combinedStatus : undefined}
                notification={groupRoot}
                isHeader={true}
                {onFinish}
            >
                <span
                    >{rootNotification
                        ? convertDateTime(rootNotification.logTime)
                        : ""}</span
                >
            </NotificationMessage>
        {/if}
        {#each notifications as item, i (item.uniqueIdent)}
            {@const isHeader = !isGroup && !i}
            <NotificationMessage
                notification={item}
                {isHeader}
                {onFinish}
                collapseDetails={isHeader}
            >
                <span>{convertDateTime(item.logTime)}</span>
            </NotificationMessage>
        {/each}
    </div>
    {#if truncated}
        <div
            class="collapse-button mdc-typography--body2-strong"
            on:click={() => {
                expanded = !expanded;
            }}
        >
            {#if expanded}Hide{:else}More{/if} details
        </div>
    {/if}
</div>

<style lang="scss">
    .group {
        padding: 12px 8px;
        margin-right: 2px;

        &:not(:last-child) {
            border-bottom: 1px solid var(--color-divider);
        }
        &-content {
            overflow: hidden;
            max-height: var(--max-height);
        }
    }

    .collapse-button {
        cursor: pointer;
        margin-left: 24px;
    }
</style>
