import jsPDF, { GState } from "jspdf";
import { PageFrameOffsets } from "./PdfReportBuilder";
import { RGBA, RGBAHex } from "engine-utils-ts";
import { setTextStyle } from "./TextBuilder";
import { convertPxToMm } from "./PdfCommon";

export class AnnotationBuilder {
    private readonly _offsetBetweenPx = 16;
    private _offsetX = 0;
    private _offsetY = 0;
    private _lineLengthPx = 16;
    private _rectSizePx = 8;
    private _rectBorderPx = 2;
    private _fontSize = 11;
    private _gap = convertPxToMm(2);
    private _endXPosition;

    constructor(
        readonly page: jsPDF,
        readonly offsets: PageFrameOffsets,
        startXPosition?: number,
        endXPosition?: number,
    ){
        this._endXPosition = endXPosition ?? (this.page.internal.pageSize.width - convertPxToMm(this.offsets.leftRightOffsetPx));
        const pageWidth = page.internal.pageSize.getWidth();
        const pageHeight = page.internal.pageSize.getHeight();
        this._offsetX = startXPosition ?? pageWidth * this.offsets.leftPartContentWidthRatio;
        this._offsetY = pageHeight - convertPxToMm(offsets.downOffsetPx + 12);
    }

    addBoxes({text, colors}: {
        text: string,
        colors: RGBAHex[],
    }) {
        if(colors.length === 0){
            return;
        }
        const rectSize = convertPxToMm(this._rectSizePx);
        const round = convertPxToMm(this._rectBorderPx);
        for (const color of colors.slice(0, 6)) {
            const [r, g, b] = RGBA.toRgbArray(color);
            this.page.setFillColor(r, g, b);
            this.page.roundedRect(
                this._offsetX,
                this._offsetY - rectSize,
                rectSize,
                rectSize,
                round,
                round,
                'F'
            );
            this._offsetX += this._gap + rectSize;
        }
    
        setTextStyle(this.page, "normal", this._fontSize);
        this.page.text(
            text,
            this._offsetX + this._gap,
            this._offsetY,
        );
        this._offsetX += this.page.getTextWidth(text) + convertPxToMm(this._offsetBetweenPx);
    }

    addLine({text, color}: {
        text: string,
        color: RGBAHex,
    }) {
        const [r, g, a] = RGBA.toRgbArray(color);
        this.page.setFillColor(r, g, a);
        const sizeX = convertPxToMm(this._lineLengthPx);
        const sizeY = convertPxToMm(3);
        this.page.rect(
            this._offsetX,
            this._offsetY - sizeY/2 - convertPxToMm(this._rectSizePx) / 2,
            sizeX,
            sizeY,
            'F'
        );
        this._offsetX += this._gap + sizeX;
        setTextStyle(this.page, "normal", this._fontSize);
    
        this.page.text(
            text,
            this._offsetX + this._gap,
            this._offsetY,
        );
        this._offsetX += this.page.getTextWidth(text) + convertPxToMm(this._offsetBetweenPx);
    }

    addLineWithBox({text, colorBox, colorLine}: {
        text: string,
        colorLine: RGBAHex,
        colorBox: RGBAHex,
    }){
        const [r1, g1, a1] = RGBA.toRgbArray(colorBox);
        this.page.setFillColor(r1, g1, a1);
        const sizeX = convertPxToMm(this._lineLengthPx);
        
        this.page.rect(
            this._offsetX,
            this._offsetY - convertPxToMm(this._rectSizePx),
            sizeX,
            convertPxToMm(this._rectSizePx),
            'F'
        );
        const [r2, g2, a2] = RGBA.toRgbArray(colorLine);
        this.page.setFillColor(r2, g2, a2);
        const excludeSizeY = convertPxToMm(2);
        this.page.rect(
            this._offsetX,
            this._offsetY - excludeSizeY,
            sizeX,
            excludeSizeY,
            'F'
        );

        this._offsetX += this._gap + sizeX;
        setTextStyle(this.page, "normal", this._fontSize);

        this.page.text(
            text,
            this._offsetX + this._gap,
            this._offsetY,
        );
        this._offsetX += this.page.getTextWidth(text) + convertPxToMm(this._offsetBetweenPx);
    }

    addGrid({text, width,}: {
        text: string,
        width: number,
    }) {
        const sizeX = width ? width : convertPxToMm(40);
        const sizeY = convertPxToMm(0.5);
        setTextStyle(this.page, "normal", this._fontSize);
        const textWidth = this.page.getTextWidth(text);
        const startX = this._endXPosition - textWidth;
        this.page.text(
            text,
            startX,
            this._offsetY,
        );

        this.page.setGState(new GState({ opacity: 0.94 }));
        this.page.setFillColor(9, 13, 15);

        const drawRect = (x: number) => {
            const height = convertPxToMm(4);
            this.page.rect(
                x,
                this._offsetY - height/2 - convertPxToMm(this._rectSizePx) / 2,
                sizeY,
                height,
                'F'
            );
        }
        const annotationX = startX - this._gap;
        drawRect(annotationX);
        this.page.rect(
            annotationX - sizeX,
            this._offsetY - sizeY/2 - convertPxToMm(this._rectSizePx) / 2,
            sizeX,
            sizeY,
            'F'
        );
        drawRect(annotationX - sizeX);

        this.page.setGState(new GState({ opacity: 1 }));
    }
}