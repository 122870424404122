import { IterUtils } from "engine-utils-ts";
import type { UnitsMapper } from "../../..";
import { BimProperty } from "../../../bimDescriptions/BimProperty";
import type { PropertiesPatch } from "../../../bimDescriptions/PropertiesCollection";
import { PropertiesCollection } from "../../../bimDescriptions/PropertiesCollection";
import type { SceneInstanceShapeMigration } from "../../../scene/SceneInstancesArhetypes";

let migration: CsvPropsMigration | undefined = undefined;

export function addFirstSolarModuleInTrackersFix_ShapeMigration(toVersion: number):
    SceneInstanceShapeMigration {
    const fixProperty = BimProperty.NewShared({path: ['module', 'voltage'], value: 181.40, unit: 'V'});

    return {
        toVersion,
        validation: {
            updatedProps: [],
            deletedProps: [],
        },
        patch: (inst, unitsMapper) => {
            const firstSolarModuleVoltage = inst.properties.get('module | voltage');
            if (firstSolarModuleVoltage && firstSolarModuleVoltage.value === 18140) {
                inst.properties.applyPatch([['module | voltage', fixProperty]]);
            }
        },
    }
}

export function addEnergyPVModuleProps_ShapeMigration(toVersion: number)
    : SceneInstanceShapeMigration
{
    return {
        toVersion,
        validation: {
            updatedProps: [],
            deletedProps: [],
        },
        patch: (inst, unitsMapper) => {
            if(!migration){
                migration = parseCSVTable({ 
                    csv: migrationTable, 
                    typePerHeader: valueTypes, 
                    unitsMapper, 
                    matchPropsPaths: matchProps,  
                    bestMathProps: {
                        manufacturerPath: "module | manufacturer",
                        powerPath: "module | maximum_power",
                    }, 
                });
            }

            const hash = makeHash(
                extractProps(inst.properties, migration.matchPropPaths),
                unitsMapper
            );

            if(!hash) {
                console.warn("Pv-module should exist. Set generic pv-module for", inst);
                inst.properties.applyPatch(migration.defaultMatchPropsPatch);
                inst.properties.applyPatch(migration.defaultUpdatePropsPatch);
                return;
            }
            const propertiesPatch = migration.perHash.get(hash);
            if(!propertiesPatch) {
                const row = tryFindByManufactureAndPower(inst.properties, migration);
                let updatePropsPatch = migration.defaultUpdatePropsPatch;
                if(row) {
                    updatePropsPatch = row.updatePropsPatch;
                    console.warn("Pv-module doesn't found in migration table. Set properties from", row, hash, inst);
                } else {
                    console.warn("Pv-module doesn't found in migration table. Set generic energy properties for", hash, inst);
                }
                applyPatchIfNotExist(inst.properties, updatePropsPatch);
                return;
            }

            applyPatchIfNotExist(inst.properties, propertiesPatch.updatePropsPatch);
        },
    }
}

export function tryFindByManufactureAndPower(
    allProperties: PropertiesCollection,
    migration: CsvPropsMigration
): MigrationRow | undefined {
    const manufacturerValue = allProperties.get(migration.bestMathProps.manufacturerPath)?.asText();
    if(!manufacturerValue) {
        return;
    }
    const manufacturerProps = IterUtils.filter(migration.perHash.values(), v => {
        return v.allProps.get(migration.bestMathProps.manufacturerPath)?.asText() === manufacturerValue;
    }).sort((a, b) => {
        const aPower = a.allProps.get(migration.bestMathProps.powerPath)?.as('W') ?? 0;
        const bPower = b.allProps.get(migration.bestMathProps.powerPath)?.as('W') ?? 0;
        return aPower - bPower;
    });
    const powerValue = allProperties.get(migration.bestMathProps.powerPath)?.as('W') ?? 0;
    let bestMatch: MigrationRow | undefined = undefined;
    for (const props of manufacturerProps) {
        const propsPower = props.allProps.get(migration.bestMathProps.powerPath)?.as('W') ?? 0;
        bestMatch = props;
        if(propsPower >= powerValue) {
            break;
        }
    }

    return bestMatch;
}

export function applyPatchIfNotExist(props: PropertiesCollection, patch: PropertiesPatch) {
    for (const [path, prop] of patch) {
        const existProp = props.get(path);
        if(!existProp) {
            props.applyPatch([[path, prop]]);
        }
    }
}

export function extractProps(props: PropertiesCollection, paths: string[][]): BimProperty[] {
    const res: BimProperty[] = [];
    for (const path of paths) {
        const prop = props.get(BimProperty.MergedPath(path));
        if (prop) {
            res.push(prop);
        }
    }
    return res;
}

export function makeHash(props: Iterable<BimProperty>, unitsMapper: UnitsMapper){
    const components: string[] = [];
    for (const prop of props) {
        let valueStr = prop.value + '';
        if (prop.unit && typeof prop.value == 'number') {
            const {value, unit} = unitsMapper.mapToConfigured({value: prop.value, unit: prop.unit})!;
            valueStr = `${value!.toFixed(0)} ${unit}`;
        }
        components.push(prop.path.at(-1) + '=' + valueStr);
    }
    return components.length > 0 ? components.join(' ') : undefined;
}

export interface CsvPropsMigration {
    matchPropPaths: string[][]
    updatePropPaths: string[][]

    perHash: Map<string, MigrationRow>
    
    defaultUpdatePropsPatch: PropertiesPatch;
    defaultMatchPropsPatch: PropertiesPatch;

    bestMathProps: {
        manufacturerPath: string,
        powerPath: string,
    }
}

interface MigrationRow {
    matchPropsHash: string;
    allProps: PropertiesCollection,
    matchPropsPatch: PropertiesPatch;
    updatePropsPatch: PropertiesPatch;
}



export function parseCSVTable({
    csv,
    typePerHeader,
    unitsMapper,
    matchPropsPaths,
    bestMathProps,
}: {
    csv: string;
    typePerHeader: Map<string, ValueTypeCsv>;
    unitsMapper: UnitsMapper;
    matchPropsPaths: string[][] | undefined;
    bestMathProps: {
        manufacturerPath: string,
        powerPath: string,
    }
}) {
    const [pathsRow, ...dataRows] = csv.trim().split("\n");
    const pathsPerColumn = pathsRow.split(";").map((x) => x.split(" | "));

    const splitterColumnIdx = pathsPerColumn.findIndex((x) =>
        x.includes("splitter")
    );
    if (splitterColumnIdx < 0) {
        throw new Error("no splitting column found");
    }
    const migration: CsvPropsMigration = {
        matchPropPaths:
            matchPropsPaths ?? pathsPerColumn.slice(0, splitterColumnIdx),
        updatePropPaths: pathsPerColumn.slice(splitterColumnIdx + 1),
        defaultUpdatePropsPatch: [],
        defaultMatchPropsPatch: [],
        perHash: new Map(),
        bestMathProps,
    };

    let genericUpdatePvModuleProps: BimProperty[] | undefined;
    let genericMatchPvModuleProps: BimProperty[] | undefined;
    for (const row of dataRows) {
        const rawCells = row.split(";");
        const cells: BimProperty[] = [];
        for (const [idx, cell] of rawCells.entries()) {
            const path = pathsPerColumn.at(idx);
            const flags = path
                ? typePerHeader.get(BimProperty.MergedPath(path))
                : undefined;
            if (!flags || !path) {
                throw new Error("flags not found for column " + idx);
            }
            if (flags === "number" || flags === "optional-number") {
                const value = parseFloat(cell.replace(",", "."));
                if(isNaN(value)){
                    cells[idx] = BimProperty.NewShared({ value: 0, path });
                    if(flags !== "optional-number"){
                        console.error("value is NaN", path, cell);
                        throw new Error("value is NaN");
                    }
                } else {
                    cells[idx] = BimProperty.NewShared({ value, path });
                }
            } else if (flags === "string") {
                cells[idx] = BimProperty.NewShared({ value: cell, path });
            } else if (flags === "boolean") {
                const parsed = cell.trim().toLocaleLowerCase() === "true";
                cells[idx] = BimProperty.NewShared({ value: parsed, path });
            } else if (flags === "unit") {
                const [valueStr, unit] = cell.split(" ");
                if (!unit) {
                    throw new Error("unit should exist");
                }
                const value = parseFloat(valueStr.replace(",", "."));
                cells[idx] = BimProperty.NewShared({ value, unit, path });
            } else if (flags === "splitter") {
                // skip
                cells[idx] = BimProperty.NewShared({ value: "", path: ["splitter"] });
            } else {
                throw new Error("column does not have flags set");
            }
        }

        const updateProps = cells.slice(splitterColumnIdx + 1);
        const existProps = cells.slice(0, splitterColumnIdx);

        if (!genericUpdatePvModuleProps) {
            const isGeneric = IterUtils.find(
                existProps,
                (p) =>
                    p.path.at(-1) === "manufacturer" &&
                    p.asText().toLocaleLowerCase() === "generic"
            );
            if (isGeneric) {
                genericUpdatePvModuleProps = updateProps;
                genericMatchPvModuleProps = existProps;
            }
        }
        const matchProps = extractProps(
            new PropertiesCollection(existProps),
            migration.matchPropPaths
        );
        const hash = makeHash(matchProps, unitsMapper);
        if (!hash) {
            console.error("hash should exist", existProps);
            throw new Error("hash should exist");
        }
        migration.perHash.set(hash, {
            matchPropsHash: hash,
            matchPropsPatch: existProps.map((x) => [x._mergedPath, x]),
            updatePropsPatch: updateProps.map((x) => [x._mergedPath, x]),
            allProps: new PropertiesCollection(cells),
        });
    }
    if (!genericUpdatePvModuleProps || !genericMatchPvModuleProps) {
        throw new Error("generic module should exist");
    }
    migration.defaultMatchPropsPatch = genericMatchPvModuleProps.map((x) => [
        x._mergedPath,
        x,
    ]);
    migration.defaultUpdatePropsPatch = genericUpdatePvModuleProps.map((x) => [
        x._mergedPath,
        x,
    ]);
    const defaultProps = new PropertiesCollection([]);

    for (const [path, prop] of defaultProps) {
        migration.defaultUpdatePropsPatch.push([path, prop]);
    }
    return migration;
}
export type ValueTypeCsv = 'string' | 'unit' | 'number' | 'optional-number' | 'splitter' | 'boolean';
const matchProps = [
    ['module | model'],
    ['module | maximum_power'],
];
const valueTypes = new Map<string, ValueTypeCsv>([
    ['module | manufacturer', "string"],
    ['module | model', 'string'],
    ['module | current', 'unit'],
    ['module | maximum_power', 'unit'],
    ['module | width', 'unit'],
    ['module | length', 'unit'],
    ['module | mounting | standard', 'string'],
    ['module | open_circuit_voltage', 'unit'],
    ['module | temp_coeff_voltage', 'number'],
    ['module | temp_coeff_current', 'number'],
    ['module | bifaciality_factor', 'number'],
    ['module | degradation | power_degradation', 'number'],
    ['module | degradation | first_year_power_degradation', 'number'],
    ['module | technology', 'string'],
    ['module | temp_coeff_power', 'number'],
    ['splitter', 'splitter'],
]);


const migrationTable =
`module | manufacturer;module | model;module | maximum_power;module | current;module | width;module | length;splitter;module | mounting | standard;module | open_circuit_voltage;module | temp_coeff_power;module | temp_coeff_voltage;module | temp_coeff_current;module | bifaciality_factor;module | degradation | power_degradation;module | degradation | first_year_power_degradation;module | technology
Boviet Solar;Boviet Vega-545W;545 W;12.82 A;1.134 m;2.296 m;;;49.98 V;-0,0035;-0,00285;0,0005;0,7;0,005;0,025;mtSiMono
BYD;BYD530MLTK-36(2278);530 W;12.74 A;1.134 m;2.278 m;;;49.12 V;-0,0033;-0,00253;0,000502;0,65;0,005;0,03;mtSiMono
BYD;BYD535MLTK-36(2278);535 W;12.79 A;1.134 m;2.278 m;;;49.42 V;-0,0033;-0,00253;0,000502;0,65;0,005;0,03;mtSiMono
BYD;BYD540MLTK-36(2278);540 W;12.84 A;1.134 m;2.278 m;;;49.72 V;-0,0033;-0,00253;0,000502;0,65;0,005;0,03;mtSiMono
Canadian Solar Inc.;CS3W-405PB-AG 1500V HE;405 W;10.42 A;1.048 m;2.132 m;;;47.4 V;-0,0036;-0,0028;0,0005;0,7;0,006;0,025;mtSiPoly
Canadian Solar;CS7N-630-AG;630 W;16.99 A;1.303 m;2.384 m;;;44.2 V;-0,0034;-0,0025;0,0005;0,8;0,004;0,01;mtSiMono
CSI Solar;CS7N-685TB-AG;685 W;17.39 A;1.303 m;2.384 m;;CS7N-TB-AG;47.3 V;-0,0034;-0,0025;0,0005;0,8;0,004;0,01;mtSiMono
CSI Solar Co., Ltd.;CS7N-685TB-AG 1500V;685 W;17.39 A;1.303 m;2.384 m;;;47.3 V;-0,0034;-0,0025;0,0005;0,8;0,004;0,01;mtSiMono
CSI Solar;CS7N-690TB-AG;690 W;17.43 A;1.303 m;2.384 m;;CS7N-TB-AG;47.5 V;-0,0034;-0,0025;0,0005;0,8;0,004;0,01;mtSiMono
CSI Solar Co., Ltd.;CS7N-690TB-AG 1500V;690 W;17.43 A;1.303 m;2.384 m;;;47.5 V;-0,0034;-0,0025;0,0005;0,8;0,004;0,01;mtSiMono
CSI Solar;CS7N-695TB-AG;695 W;17.47 A;1.303 m;2.384 m;;CS7N-TB-AG;47.7 V;-0,0034;-0,0025;0,0005;0,8;0,004;0,01;mtSiMono
CSI Solar Co., Ltd.;CS7N-695TB-AG 1500V;695 W;17.47 A;1.303 m;2.384 m;;;47.7 V;-0,0034;-0,0025;0,0005;0,8;0,004;0,01;mtSiMono
CSI Solar Co., Ltd.;CS7N-700TB-AG 1500V;700 W;17.51 A;1.3030000000000002 m;2.384 m;;;47.9 V;-0,0034;-0,0026;0,0005;0,8;0,004;0,01;mtSiMono
ET Solar;ET-M772BH545TW;545 W;13.038 A;1.134 m;2.279 m;;;49.75 V;-0,0034;-0,00251;0,00046;0,7;0,0045;0,02;mtSiMono
ET SOLAR;ET-N772TBH570GL;570 W;13.39 A;1.134 m;2.278 m;;;53.26 V;-0,0034;-0,00292;0,00044;0,7;0,0045;0,02;mtSiMono
ET SOLAR;ET-N772TBH575GL;575 W;13.46 A;1.134 m;2.278 m;;;53.42 V;-0,0034;-0,00292;0,00044;0,7;0,0045;0,02;mtSiMono
First Solar;FS S6 Plus 480;480 W;2.57 A;1.245 m;2.024 m;;;225.4 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS S6 Plus 485;485 W;2.57 A;1.2496729896 m;2.0269129896 m;;;225.9 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS6+ 470W;470 W;2.458 A;1.2451079601565453 m;2.024115775228295 m;;;224.3 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6430A;430 W;2.36 A;1.232 m;2.009 m;;FS6;219.2 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6435A;435 W;2.369 A;1.232 m;2.009 m;;;219.6 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6435A;435 W;2.369 A;1.232 m;2.009 m;;FS6;219.6 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6440A;440 W;2.381 A;1.232 m;2.009 m;;;220.0 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6440A;440 W;2.381 A;1.232 m;2.009 m;;FS6;220.0 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6445A;445 W;2.394 A;1.232 m;2.009 m;;;220.4 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6445A;455.001 W;2.42 A;1.232 m;2.009 m;;FS6;220.4 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6445A;445 W;2.394 A;1.232 m;2.009 m;;FS6;220.4 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6450-A;450 W;2.41 A;1.232 m;2.009 m;;FS6;221.1 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6450-PA October2020;450 W;2.41 A;1.245 m;2.024 m;;;221.1 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6455-P;455 W;2.42 A;1.245 m;2.024 m;;FS6+;222.0 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6460-A;460 W;2.44 A;1.232 m;2.009 m;;FS6;222.9 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6460-C;460 W;2.52 A;1.245 m;2.024 m;;;222.9 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6460-C June2021;460 W;2.523 A;1.245 m;2.024 m;;;222.9 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6460-P;460 W;2.436 A;1.245 m;2.024 m;;;222.9 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6460-P;460 W;2.436 A;1.245 m;2.024 m;;FS6+;222.9 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6465-C June2021;465 W;2.535 A;1.245 m;2.024 m;;;223.8 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6465-P;465 W;2.45 A;1.245 m;2.024 m;;FS6+;223.8 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6470A-P;470 W;2.458 A;1.245 m;2.024 m;;;224.3 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6470A-P;470 W;2.458 A;1.245 m;2.024 m;;FS6+;224.3 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6470-C June2021;470 W;2.544 A;1.245 m;2.024 m;;;224.3 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6475A-P;475 W;2.48 A;1.245 m;2.024 m;;FS6+;224.8 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6480A-P;480 W;2.49 A;1.245 m;2.024 m;;FS6+;225.4 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6480A-P/FS-6480A-P-I Jan2022;480 W;2.49 A;1.245 m;2.024 m;;;225.4 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6485A-P July2022;485 W;2.578 A;1.245 m;2.024 m;;;225.9 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-6490A-P July2022;490 W;2.592 A;1.245 m;2.024 m;;;225.5 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-7505A;505 W;2.77 A;1.216 m;2.3 m;;FS7;223.9 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-7510A;510 W;2.78 A;1.216 m;2.3 m;;FS7;224.5 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-7515A;515 W;2.8 A;1.216 m;2.3 m;;FS7;225.0 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-7520A;520 W;2.867 A;1.215 m;2.3 m;;;225.6 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-7520A;520 W;2.81 A;1.216 m;2.3 m;;FS7;225.6 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-7520A Oct2021 Prelim;520 W;2.867 A;1.215 m;2.3 m;;;225.6 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-7525A;525 W;2.82 A;1.216 m;2.3 m;;FS7;226.1 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-7525A-TR1 September2022;525 W;2.819 A;1.215 m;2.3 m;;;226.1 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-7530A;530 W;2.84 A;1.216 m;2.3 m;;FS7;226.7 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-7535A;535 W;2.85 A;1.216 m;2.3 m;;FS7;227.2 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
First Solar;FS-7540A;540 W;2.86 A;1.216 m;2.3 m;;FS7;227.7 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
JA Solar;JAM72D30-525/MB;525 W;12.76 A;1.134 m;2.285 m;;JAM72D30;49.15 V;-0,0035;-0,00275;0,00045;0,7;0,0045;0,02;mtSiMono
JA Solar;JAM72D30-530/MB;530 W;12.83 A;1.134 m;2.285 m;;JAM72D30;49.30 V;-0,0035;-0,00275;0,00045;0,7;0,0045;0,02;mtSiMono
JA Solar;JAM72D30-535/MB;535 W;12.9 A;1.134 m;2.285 m;;JAM72D30;49.45 V;-0,0035;-0,00275;0,00045;0,7;0,0045;0,02;mtSiMono
JA Solar;JAM72D30-540/MB;540 W;12.97 A;1.134 m;2.285 m;;JAM72D30;49.60 V;-0,0035;-0,00275;0,00045;0,7;0,0045;0,02;mtSiMono
JA Solar;JAM72D30-545/MB;545 W;13.04 A;1.134 m;2.285 m;;JAM72D30;49.75 V;-0,0035;-0,00275;0,00045;0,7;0,0045;0,02;mtSiMono
JA Solar;JAM72D30-545/MB;545 W;13.04 A;1.134 m;2.285 m;;;49.75 V;-0,0035;-0,00275;0,00045;0,7;0,0045;0,02;mtSiMono
JA Solar;JAM72D30-550/MB;550 W;13.11 A;1.134 m;2.285 m;;JAM72D30;49.90 V;-0,0035;-0,00275;0,00045;0,7;0,0045;0,02;mtSiMono
JA Solar;JAM72D30-550/MB;550 W;13.11 A;1.134 m;2.285 m;;;49.90 V;-0,0035;-0,00275;0,00045;0,7;0,0045;0,02;mtSiMono
JA Solar;JAM72D40-555/MB;555 W;13.14 A;1.134 m;2.278 m;;JAM72D40;50.14 V;-0,0035;-0,0026;0,00046;0,8;0,004;0,01;mtSiMono
JA Solar;JAM72D40-560/MB;560 W;13.21 A;1.134 m;2.278 m;;JAM72D40;50.32 V;-0,0035;-0,0026;0,00046;0,8;0,004;0,01;mtSiMono
JA Solar;JAM72D40-565/MB;565 W;13.28 A;1.134 m;2.278 m;;JAM72D40;50.50 V;-0,0035;-0,0026;0,00046;0,8;0,004;0,01;mtSiMono
JA Solar;JAM72D40-570/MB;570 W;13.35 A;1.134 m;2.278 m;;JAM72D40;50.68 V;-0,0035;-0,0026;0,00046;0,8;0,004;0,01;mtSiMono
JA Solar;JAM72D40-575/MB;575 W;13.42 A;1.134 m;2.278 m;;JAM72D40;50.86 V;-0,0035;-0,0026;0,00046;0,8;0,004;0,01;mtSiMono
JA Solar;JAM72D40-580/MB;580 W;13.48 A;1.134 m;2.278 m;;JAM72D40;51.04 V;-0,0035;-0,0026;0,00046;0,8;0,004;0,01;mtSiMono
Jinkosolar;JKM 395M-72HL-TV_68bifi;395 W;9.92 A;1.008 m;2.031 m;;;48.26 V;-0,003;-0,0029;0,00048;0,7;0,0055;0,025;mtSiMono
Jinkosolar;JKM 400M-72HL-TV_68bifi;400 W;9.999 A;1.008 m;2.031 m;;;48.35 V;-0,003;-0,0029;0,00048;0,7;0,0055;0,025;mtSiMono
Jinko Solar;JKM535M-72HL4-BDVP;535 W;13.07 A;1.134 m;2.274 m;;JKM-72HL4-BDV;49.26 V;-0,003;-0,0028;0,00048;0,8;0,0055;0,02;mtSiMono
Jinko Solar;JKM560N-72HL4-BDV;560 W;13.35 A;1.134 m;2.278 m;;JKM-72HL4-BDV;50.67 V;-0,003;-0,0028;0,00048;0,8;0,0055;0,02;mtSiMono
Jinko Solar;JKM565N-72HL4-BDV;565 W;13.41 A;1.134 m;2.278 m;;JKM-72HL4-BDV;50.87 V;-0,003;-0,0028;0,00048;0,8;0,0055;0,02;mtSiMono
Jinko Solar;JKM570N-72HL4-BDV;570 W;13.48 A;1.134 m;2.278 m;;JKM-72HL4-BDV;51.07 V;-0,003;-0,0028;0,00048;0,8;0,0055;0,02;mtSiMono
Jinkosolar;JKM570N-72HL4-TV;570 W;13.37 A;1.134 m;2.278 m;;;51.07 V;-0,003;-0,0025;0,00046;0,8;0,004;0,01;mtSiMono
Jinko Solar;JKM575N-72HL4-BDV;575 W;13.55 A;1.134 m;2.278 m;;JKM-72HL4-BDV;51.27 V;-0,003;-0,0028;0,00048;0,8;0,0055;0,02;mtSiMono
Jinko Solar;JKM580N-72HL4-BDV;580 W;13.62 A;1.134 m;2.278 m;;JKM-72HL4-BDV;51.47 V;-0,003;-0,0028;0,00048;0,8;0,0055;0,02;mtSiMono
Longi Solar;LR4-72HBD-425M;425 W;10.52 A;1.038 m;2.094 m;;LR4-72HBD;48.7 V;-0,0034;-0,00284;0,0005;0,7;0,0045;0,02;mtSiMono
Longi Solar;LR4-72HBD-430M;430 W;10.6 A;1.038 m;2.094 m;;LR4-72HBD;48.9 V;-0,0034;-0,00284;0,0005;0,7;0,0045;0,02;mtSiMono
Longi Solar;LR4-72HBD-435M;435 W;10.66 A;1.038 m;2.094 m;;LR4-72HBD;49.1 V;-0,0034;-0,00284;0,0005;0,7;0,0045;0,02;mtSiMono
Longi Solar;LR4-72HBD-440M;440 W;10.73 A;1.038 m;2.094 m;;LR4-72HBD;49.2 V;-0,0034;-0,00265;0,0005;0,7;0,0045;0,02;mtSiMono
Longi Solar;LR4-72HBD-445M;445 W;10.8 A;1.038 m;2.094 m;;LR4-72HBD;49.4 V;-0,0034;-0,00265;0,0005;0,7;0,0045;0,02;mtSiMono
Longi Solar;LR4-72HBD-450M;450 W;10.87 A;1.038 m;2.094 m;;LR4-72HBD;49.6 V;-0,0034;-0,00265;0,0005;0,7;0,0045;0,02;mtSiMono
Longi Solar;LR4-72HBD-455M;455 W;10.93 A;1.038 m;2.094 m;;LR4-72HBD;49.8 V;-0,0034;-0,00265;0,0005;0,7;0,0045;0,02;mtSiMono
Longi Solar;LR4-72HBD-460M;460 W;11.01 A;1.038 m;2.094 m;;LR4-72HBD;50.0 V;-0,0034;-0,00265;0,0005;0,7;0,0045;0,02;mtSiMono
Longi Solar;LR4-72HIBD-430M;430 W;10.44 A;1.052 m;2.131 m;;;48.9 V;-0,0034;-0,00284;0,0005;0,7;0,0045;0,02;mtSiMono
Longi Solar;LR4-72HIBD-435M;435 W;10.51 A;1.052 m;2.131 m;;;49.1 V;-0,0034;-0,00284;0,0005;0,7;0,0045;0,02;mtSiMono
Longi Solar;LR4-72HIBD-440M;440 W;10.61 A;1.052 m;2.131 m;;;49.2 V;-0,0034;-0,00284;0,0005;0,7;0,0045;0,02;mtSiMono
Longi Solar;LR5-72HBD-530M;530 W;12.82 A;1.133 m;2.256 m;;LR5-72HBD;49.2 V;-0,0034;-0,00265;0,0005;0,7;0,0045;0,02;mtSiMono
Longi Solar;LR5-72HBD-535M;535 W;12.9 A;1.133 m;2.256 m;;LR5-72HBD;49.35 V;-0,0034;-0,00265;0,0005;0,7;0,0045;0,02;mtSiMono
Longi Solar;LR5-72HBD-540M;540 W;12.97 A;1.133 m;2.256 m;;;49.5 V;-0,0034;-0,00265;0,0005;0,7;0,0045;0,02;mtSiMono
Longi Solar;LR5-72HBD-540M;540 W;12.97 A;1.133 m;2.256 m;;LR5-72HBD;49.5 V;-0,0034;-0,00265;0,0005;0,7;0,0045;0,02;mtSiMono
Longi Solar;LR5-72HBD-545M;545 W;13.04 A;1.133 m;2.256 m;;LR5-72HBD;49.65 V;-0,0034;-0,00265;0,0005;0,7;0,0045;0,02;mtSiMono
Longi Solar;LR5-72HBD-550M;550 W;13.12 A;1.133 m;2.256 m;;LR5-72HBD;49.8 V;-0,0034;-0,00265;0,0005;0,7;0,0045;0,02;mtSiMono
Longi Solar;LR5-72HBD-555M;555 W;13.19 A;1.133 m;2.256 m;;LR5-72HBD;49.95 V;-0,0034;-0,00265;0,0005;0,7;0,0045;0,02;mtSiMono
Generic;PV Module;485 W;2.57 A;1.2496729896 m;2.0269129896 m;;;225.9 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
Generic;PV Module;485 W;2.57 A;1.2496729896 m;2.0269129896 m;;Generic;225.9 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
Hanwha Q Cells;Q.PEAK DUO XL-G11S.3 590/BFG;590 W;13.12 A;1.134 m;2.462 m;;;53.7 V;-0,0034;-0,0027;0,0004;0;0,005;0,02;mtSiMono
Jinkosolar;Tiger Neo N-type;610 W;15.08 A;1.134 m;2.382 m;;;55.31 V;-0,003;-0,0025;0,00046;0,8;0,004;0,01;mtSiMono
Jinkosolar;Tiger Neo N-type;610 W;15.08 A;1.134 m;2.382 m;;JKM-72HL4-BDV;55.31 V;-0,003;-0,0025;0,00046;0,8;0,004;0,01;mtSiMono
Trina Solar;TSM-395DEG15HC.20(II)_68bifi;395 W;9.73 A;1.011 m;2.031 m;;;49.7 V;-0,003;-0,0029;0,0005;0,7;0,005;0,025;mtSiMono
Trina Solar;TSM-585NEG19RC.20;585 W;14.82 A;1.134 m;2.384 m;;TSM_NEG19RC.20;47.5 V;-0,003;-0,0024;0,0004;0,8;0,004;0,01;mtSiMono
Trina Solar;TSM-590NEG19RC.20;590 W;14.86 A;1.134 m;2.384 m;;TSM_NEG19RC.20;47.8 V;-0,003;-0,0024;0,0004;0,8;0,004;0,01;mtSiMono
Trina Solar;TSM-595NEG19RC.20;595 W;14.89 A;1.134 m;2.384 m;;TSM_NEG19RC.20;48.1 V;-0,003;-0,0024;0,0004;0,8;0,004;0,01;mtSiMono
Trina Solar;TSM-600NEG19RC.20;600 W;14.91 A;1.134 m;2.384 m;;TSM_NEG19RC.20;48.4 V;-0,003;-0,0024;0,0004;0,8;0,004;0,01;mtSiMono
Trina Solar;TSM-605NEG19RC.20;605 W;14.94 A;1.134 m;2.384 m;;TSM_NEG19RC.20;48.7 V;-0,003;-0,0024;0,0004;0,8;0,004;0,01;mtSiMono
Trina Solar;TSM-610NEG19RC.20;610 W;14.96 A;1.134 m;2.384 m;;TSM_NEG19RC.20;49.0 V;-0,003;-0,0024;0,0004;0,8;0,004;0,01;mtSiMono
Trina Solar;TSM-660DEG21C20;660 W;17.35 A;1.303 m;2.384 m;;;45.9 V;-0,003;-0,0025;0,0004;0,8;0,0045;0,02;mtSiMono
Trina Solar;TSM-690NEG21C20;690 W;17.23 A;1.303 m;2.384 m;;;45.3 V;-0,003;-0,0025;0,0004;0,8;0,0045;0,02;mtSiMono
VSUN Solar;VSUN 450-144BMH-DG;450 W;10.93 A;1.048 m;2.122 m;;;49.4 V;-0,0032;-0,0026;0,00054;0,7;0,005;0,02;mtSiMono
VSUN;VSUN575N_144BMH_35 BSS01;575 W;13.54 A;1.134 m;2.278 m;;;51.26 V;-0,0032;-0,0026;0,00046;0,8;0,004;0,01;mtSiMono
ZNshine solar;ZXM7-UHLDD144-570/N(2278Г—1134Г—30);570 W;13.45 A;1.134 m;2.278 m;;;51.1 V;-0,0032;-0,0025;0,00046;0,8;0,004;0,01;mtSiMono
Longi Solar;Avantus LS 460;460 W;11.01 A;1.038 m;2.094 m;;LR4-72HBD;50.0 V;-0,0034;-0,00265;0,0005;0,7;0,0045;0,02;mtSiMono
Longi Solar;Avantus LS 405;405 W;10.42 A;1.134 m;2.296 m;;;37 V;-0,0034;-0,00284;0,0005;0,7;0,0045;0,02;mtSiMono
Trina Solar;Vertex 650W;650 W;2.42 A;1.134 m;2.296 m;;;45.3 V;-0,003;-0,0025;0,0004;0,8;0,0045;0,02;mtSiMono
First Solar;FS-6455-A;455 W;2.42 A;1.134 m;2.296 m;;;222.0 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
CSI Solar Co., Ltd.;CS7N-685TB-AG;685 W;17.43 A;1.134 m;2.296 m;;;47.3 V;-0,0034;-0,0025;0,0005;0,8;0,004;0,01;mtSiMono
CSI Solar Co., Ltd.;CS7N-690TB-AG;690 W;17.43 A;1.134 m;2.296 m;;;47.5 V;-0,0034;-0,0025;0,0005;0,8;0,004;0,01;mtSiMono
CSI Solar Co., Ltd.;CS7N-695TB-AG;695 W;17.43 A;1.134 m;2.296 m;;;47.7 V;-0,0034;-0,0025;0,0005;0,8;0,004;0,01;mtSiMono
JA Solar;18;545 W;13.04 A;1.134 m;2.296 m;;;49.75 V;-0,0035;-0,00275;0,00045;0,7;0,0045;0,02;mtSiMono
Terrasmart;LR4-72HIBD-430M;540 W;12.82 A;1.134 m;2.296 m;;;49.5 V;-0,0034;-0,00265;0,0005;0,7;0,0045;0,02;mtSiMono
First Solar;FS S6 Plus 470;470 W;11.01 A;1.134 m;2.296 m;;;224.3 V;-0,0032;-0,0028;0,0004;0;0,005;0,02;mtCdTe
Longi Solar;Avantus LS 430;430 W;10.6 A;1.134 m;2.296 m;;;48.9 V;-0,0034;-0,00284;0,0005;0,7;0,0045;0,02;mtSiMono
ZNshine solar;ZXM7-UHLDD144-570/N(2278×1134×30);570 W;13.37 A;1.134 m;2.296 m;;;51.1 V;-0,0032;-0,0025;0,00046;0,8;0,004;0,01;mtSiMono
VSUN Solar;VSUN 575N-144BMH-35;575 W;13.55 A;1.134 m;2.296 m;;;51.26 V;-0,0032;-0,0026;0,00046;0,8;0,004;0,01;mtSiMono
VSUN Solar;VSUN 580N-144BMH-35;580 W;13.62 A;1.134 m;2.296 m;;;51.47 V;-0,0032;-0,0026;0,00046;0,8;0,004;0,01;mtSiMono
VSUN Solar;VSUN 585N-144BMH-35;585 W;14.82 A;1.134 m;2.296 m;;;51.68 V;-0,0032;-0,0026;0,00046;0,8;0,004;0,01;mtSiMono
Jinkosolar;JKM 395M-72HL-TV_68bifi;398 W;10.42 A;1.134 m;2.296 m;;;48.26 V;-0,003;-0,0029;0,00048;0,7;0,0055;0,025;mtSiMono
Canadian Solar;18;450 W;10.87 A;1.134 m;2.296 m;;;41.1 V;-0,0036;-0,0027;0,0005;0,7;0,006;0,025;mtSiMono
CSI;18;685 W;17.23 A;1.134 m;2.296 m;;;47.3 V;-0,0034;-0,0025;0,0005;0,8;0,004;0,01;mtSiMono
`;

