<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
>
    <path
        d="M18.0857 14.9999L15.2929 12.2072L16.7071 10.793L21.9142 15.9999L16.7071 21.2072L15.2929 19.793L18.0859 16.9999H11V14.9999H18.0857Z"
        fill="currentColor"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.35825 3.94C4.72373 3.35524 5.36467 3 6.05425 3H17.9458C18.6353 3 19.2763 3.35524 19.6417 3.94L21.696 7.2268C21.8947 7.54466 22 7.91196 22 8.2868V13.2577L20 11.2577V9H17.7422L17.7071 8.96484L17.6719 9H4L4 20H12.6711L14.6712 22H4C2.89543 22 2 21.1046 2 20V8.2868C2 7.91196 2.10534 7.54466 2.304 7.2268L4.35825 3.94ZM17.9458 5H6.05425L4.80425 7H19.1958L17.9458 5Z"
        fill="currentColor"
    />
</svg>
