import type { ObjectSerializer } from 'engine-utils-ts';
import { ErrorUtils } from 'engine-utils-ts';
import * as flatbuffers from 'flatbuffers';

import { ImportFile } from './wire/import-file';
import { SaveByUser } from './wire/save-by-user';
import { TaskDescription } from './wire/task-description';
import { Tasks as WireTasks } from './wire/tasks';
import { VerDataState as WireVerDataState } from './wire/ver-data-state';
import { VerDataTask as WireVerDataTask } from './wire/ver-data-task';
import { dateToFlatbufLong } from './WireCommon';

export class WireTaskDescription {

    guid: string;
    timeoutUTC: Date;
    progress: number;

    constructor(
        guid: string,
        timeoutUTC?: Date,
        progress?: number,
    ) {
        if (!guid) {
            throw new Error('guid not provided');
        }
        this.guid = guid;
        this.timeoutUTC = timeoutUTC ?? new Date(0);
        this.progress = progress ?? 0;
    }

    static parseFromFlatbuf(t: TaskDescription): WireTaskDescription {
        return new WireTaskDescription(
            t.guid()!,
            new Date(Number(t.timeoutUtc())),
            t.progress(),
        );
    }

    static addToFlatbuf(builder: flatbuffers.Builder, t: WireTaskDescription): number {
        return TaskDescription.createTaskDescription(
            builder,
            builder.createString(t.guid),
            dateToFlatbufLong(t.timeoutUTC),
            t.progress
        );
    }
}

export class TaskSaveByUser {
    user: string;

    constructor(user: string) {
        this.user = user;
    }
}
export class TaskImportFile {
    path: string;
    constructor(path: string) {
        this.path = path;
    }
}


export type VerDataTasks = TaskSaveByUser | TaskImportFile;

function parseTask(taskBuffer: WireVerDataTask): VerDataTasks {
    switch (taskBuffer.taskType()) {
        case WireTasks.ImportFile:
            return new TaskImportFile(taskBuffer.task(new ImportFile())!.importPath()!);
        case WireTasks.SaveByUser:
            return new TaskSaveByUser(taskBuffer.task(new SaveByUser())!.user()!);
        default:
            throw new Error('unexpected task type ' + taskBuffer.taskType());
    }
}
function addTaskToFlatbuf(builder: flatbuffers.Builder, task: VerDataTasks): [number, number] {
    if (task instanceof TaskImportFile) {
        return [
            WireTasks.ImportFile,
            ImportFile.createImportFile(builder, builder.createString(task.path)),
        ];
    } else if (task instanceof TaskSaveByUser) {
        return [
            WireTasks.SaveByUser,
            SaveByUser.createSaveByUser(builder, builder.createString(task.user)),
        ];
    } else {
        ErrorUtils.logThrow('task to flatbuf: unexpted task type', task);
    }
}



export class VerDataTask {

    task: VerDataTasks;
    description: WireTaskDescription;

    constructor(
        task: VerDataTasks,
        description: WireTaskDescription,
    ) {
        if (!task) {
            throw new Error('task not provided');
        }
        if (!description) {
            throw new Error('description not provided');
        }
        this.task = task;
        this.description = description;
    }

    static parseFromFlatbuf(t: WireVerDataTask): VerDataTask {
        const task = parseTask(t);
        const description = WireTaskDescription.parseFromFlatbuf(t.description()!);
        return new VerDataTask(
            task,
            description
        );
    }

    static addToFlatbuf(builder: flatbuffers.Builder, t?: VerDataTask | null): number {
        if (!t) {
            return 0;
        }
        const [taskTy, taskOffset] = addTaskToFlatbuf(builder, t.task);
        const descrOffset =  WireTaskDescription.addToFlatbuf(builder, t.description);
        WireVerDataTask.startVerDataTask(builder);
        WireVerDataTask.addTaskType(builder, taskTy);
        WireVerDataTask.addTask(builder, taskOffset);
        WireVerDataTask.addDescription(builder, descrOffset);
        return WireVerDataTask.endVerDataTask(builder);
    };
}

export class VerDataState {
    activeTask: VerDataTask | null;
    pendingTasks: VerDataTask[];

    constructor(
        activeTask: VerDataTask | null,
        pendingTasks: VerDataTask[],
    ) {
        this.activeTask = activeTask;
        this.pendingTasks = pendingTasks;
    }

	canStartNewTask(): boolean {
		return this.activeTask == null || this.activeTask.description.timeoutUTC < new Date();
	}
}


export class VerDataStateSerializer implements ObjectSerializer<VerDataState> {
    
    serialize(data: VerDataState): Uint8Array {
        const builder = new flatbuffers.Builder(10000);
        const root = WireVerDataState.createVerDataState(
            builder,
            VerDataTask.addToFlatbuf(builder, data.activeTask),
            WireVerDataState.createTasksPendingVector(
                builder,
                data.pendingTasks.map(t =>  VerDataTask.addToFlatbuf(builder, t))
            ),
        );
        builder.finish(root);
        return builder.asUint8Array().slice();
    }

    deserialize(bytes: Uint8Array): VerDataState {
        const buffer = new flatbuffers.ByteBuffer(bytes);
        const s = WireVerDataState.getRootAsVerDataState(buffer);

        const activeTask = s.activeTask() ? VerDataTask.parseFromFlatbuf(s.activeTask()!) : null;

        const pendingTasks: VerDataTask[] = [];
        for (let i = 0, il = s.tasksPendingLength(); i < il; ++i) {
            const t = VerDataTask.parseFromFlatbuf(s.tasksPending(i)!);
            pendingTasks.push(t);
        }
        return new VerDataState(activeTask, pendingTasks);
    }
}
