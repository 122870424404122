import type { IdBimScene } from "src/scene/SceneInstances";
import { getInRowPosition, newIndexWithInRowPosition, type InRowPositionPacked } from "./IndicesWithPositionPacker";
import { IterUtils } from "engine-utils-ts";
import type { Tracker } from "./RowsCalculator";


interface PileOnLine {
    position: number,
    trackerId: IdBimScene,
}

interface LinePiles {
    lineCoord: number,
    piles: PileOnLine[]
}


function calcBestTan(rows: Tracker[][], eps: number = 0.001): number {
    const tansWithCount: [number, number][] = [];

    for (let i = 0; i < rows.length - 1; ++i) {
        for (let j = 0; j < rows[i].length; ++j) {
            const trackerH = 0.5 * (rows[i][j].hBounds[0] + rows[i][j].hBounds[1]);
            
            let closestTrackerShift = Infinity;
            let jj: number;
            for (jj = 0; jj < rows[i + 1].length; ++jj) {
                const shift = 0.5 * (rows[i + 1][jj].hBounds[0] + rows[i + 1][jj].hBounds[1]) - trackerH;
                if (Math.abs(shift) < Math.abs(closestTrackerShift)) {
                    closestTrackerShift = shift;
                } else if (shift > closestTrackerShift) {
                    break;
                }
            }
            const tan = closestTrackerShift / (rows[i + 1][jj - 1].position - rows[i][j].position)

            let k: number;
            for (k = 0; k < tansWithCount.length; ++k) {
                if (Math.abs(tansWithCount[k][0] - tan) < eps) {
                    tansWithCount[k][0] = (tansWithCount[k][0] * tansWithCount[k][1] + tan) / (tansWithCount[k][1] + 1);
                    ++tansWithCount[k][1];
                    break;
                }
            }
            if (k === tansWithCount.length) {
                tansWithCount.push([tan, 1]);
            }
        }
    }

    let bestTan = 0, maxCount = 0;
    for (const tanWithCount of tansWithCount) {
        if (tanWithCount[1] > maxCount 
            || (tanWithCount[1] === maxCount && Math.abs(tanWithCount[0]) < Math.abs(bestTan))
        ) {
            maxCount = tanWithCount[1];
            bestTan = tanWithCount[0];
        }
    }
    
    return bestTan;
}


function calcAllLines(rows: Tracker[][], bestTan: number, eps: number = 0.5) {
    const pilesCoords: LinePiles[] = [];
    for (const row of rows) {
        const rowPiles: PileOnLine[] = [];
        let averagePosition = 0;
        for (const tracker of row) {
            averagePosition += tracker.position;
            if (tracker.piles) {
                IterUtils.extendArray(
                    rowPiles, 
                    tracker.piles.map(p => ({ position: p, trackerId: tracker.id }))
                );
            }
        }
        pilesCoords.push({ lineCoord: averagePosition / row.length, piles: rowPiles });
    }

    const allLines: LinePiles[] = [];
    for (let i = 0; i < pilesCoords.length; ++i) {
        for (let j = 0; j < pilesCoords[i].piles.length; ++j) {
            const lineY = pilesCoords[i].piles[j].position - bestTan * pilesCoords[i].lineCoord;
            const linePiles: PileOnLine[] = [{ position: pilesCoords[i].lineCoord, trackerId: pilesCoords[i].piles[j].trackerId}];
            for (let ii = i + 1; ii < pilesCoords.length; ++ii) {
                for (let jj = 0; jj < pilesCoords[ii].piles.length; ++jj) {
                    if (pilesCoords[ii].piles[jj].position > lineY + bestTan * pilesCoords[ii].lineCoord + eps) {
                        break;
                    }

                    if (Math.abs(pilesCoords[ii].piles[jj].position - lineY - bestTan * pilesCoords[ii].lineCoord) < eps) {
                        linePiles.push({ position: pilesCoords[ii].lineCoord, trackerId: pilesCoords[ii].piles[jj].trackerId});
                        pilesCoords[ii].piles.splice(jj, 1);
                    }
                }
            }
            allLines.push({ lineCoord: lineY, piles: linePiles });
        }
    }
    allLines.sort((a, b) => b.lineCoord - a.lineCoord);

    return allLines;
}


function unionLines(allLines: LinePiles[], eps: number = 0.5) {
    const lineIndicesByDistanceToNext = IterUtils.newArray(allLines.length - 1, i => i);
    
    let li: number;
    while (true) {
        lineIndicesByDistanceToNext.sort((i, j) => 
            (allLines[i].lineCoord - allLines[i + 1].lineCoord) - 
            (allLines[j].lineCoord - allLines[j + 1].lineCoord)
        );

        for (li = 0; li < lineIndicesByDistanceToNext.length; ++li) {
            const i = lineIndicesByDistanceToNext[li];

            let combine = true;
            for (let j = 0, k = 0; j < allLines[i].piles.length && k < allLines[i + 1].piles.length; ++j, ++k) {
                if (Math.abs(allLines[i].piles[j].position - allLines[i + 1].piles[k].position) < eps) {
                    combine = false;
                    break;
                }
                if (allLines[i].piles[j].position < allLines[i + 1].piles[k].position) {
                    --k;
                } else {
                    --j;
                }
            }
            
            if (combine) {
                allLines[i].lineCoord = (
                    allLines[i].lineCoord * allLines[i].piles.length 
                    + allLines[i + 1].lineCoord * allLines[i + 1].piles.length
                ) / (
                    allLines[i].piles.length + allLines[i + 1].piles.length
                );
                IterUtils.extendArray(allLines[i].piles, allLines[i + 1].piles);
                allLines[i].piles.sort((a, b) => a.position - b.position);
                allLines.splice(i + 1, 1);
                
                break;
            }
        }

        if (li === lineIndicesByDistanceToNext.length) {
            return allLines;
        } else {
            lineIndicesByDistanceToNext.splice(lineIndicesByDistanceToNext.indexOf(allLines.length - 1), 1);
        }
    }
}


export function calcPilesIndices(
    rows: Tracker[][]
): [IdBimScene, InRowPositionPacked[]][][] {
    const bestTan = calcBestTan(rows);

    const allLines = calcAllLines(rows, bestTan);

    const resultLines = unionLines(allLines);

    const trackersIds: [IdBimScene, number[]][] = rows.flatMap(r => r.map<[IdBimScene, number[]]>(tr => [tr.id, []]));
    const trackersPiles = new Map<IdBimScene, number[]>(trackersIds);
    for (let i = 0; i < resultLines.length; ++i) {
        for (let j = 0; j < resultLines[i].piles.length; ++j) {
            const trackerPiles = trackersPiles.get(resultLines[i].piles[j].trackerId)!;
            const pilesInRowPosition = getInRowPosition(j, resultLines[i].piles.length);
            trackerPiles.push(newIndexWithInRowPosition(i + 1, j + 1, pilesInRowPosition));
        }
    }

    const result: [IdBimScene, InRowPositionPacked[]][][] = 
        rows.map(r => r.map(tr => [tr.id, trackersPiles.get(tr.id)!]))
    return result;
}