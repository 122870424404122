import type { IdBimScene, SceneInstances } from 'bim-ts';
import type { NamedEvents, ObservableStream } from 'engine-utils-ts';
import { Vector3 } from 'math-ts';

import type { ClipBox } from '../clipbox/ClipBox';
import type { SceneInt } from '../scene/SceneRaycaster';
import type { RayIntersection } from '../scene/Raycasts';
import type { SizeLabelMesh } from '../SizeLabelMesh';
import type { EngineLegacyUiUnits, MeasureLabelCopyArgs } from '../EngineLegacyUiUnits';
import { MouseButton } from './InputController';
import type { MouseClickConsumer, MouseDragConsumer, MouseEventData, MouseGestureConsumer
} from './MouseGesturesBase';
import {
    GesturesButtons
} from './MouseGesturesBase';
import { UnitType } from '../EngineConsts';
import { KeyModifiersFlags } from 'ui-bindings';

export type SizeLabelSource = ClipBox | IdBimScene;


type S = SizeLabelIntersection;

export interface MeasurementLabelClickArgs {
	valueSi: number;
	type: UnitType;
	uiString: string;
	event: MouseEvent;
}

export class SizeLabelsClicksConsumer implements MouseGestureConsumer {

    clickConsumers: MouseClickConsumer[] = [];
    dragConsumers: MouseDragConsumer<any>[] = [];


    readonly sceneInstances: SceneInstances;
    readonly sizeLabelCopyEvent: ObservableStream<MeasurementLabelClickArgs>;
    readonly uiUnits: EngineLegacyUiUnits;


    constructor(
        sceneInstances: SceneInstances,
        NamedEvents: NamedEvents,
        uiUnits: EngineLegacyUiUnits,
    ) {
        this.sizeLabelCopyEvent = NamedEvents.registerEvent('measurementClick');
        this.sceneInstances = sceneInstances;
        this.uiUnits = uiUnits;

        this.clickConsumers.push(new LabelClicksConsumers(
            GesturesButtons.newShared(MouseButton.Right, KeyModifiersFlags.None),
            (int) => int instanceof SizeLabelIntersection,
            (int, med) => { this._callSizeLabelEvent(int, med) }
        ));

        this.clickConsumers.push(new LabelClicksConsumers(
            GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.None),
            (int) => int instanceof RulerSizeIntersection,
            (int, med) => {
                // if (int instanceof RulerSizeIntersection) {
                //     this.sceneInstances.setSelected([int.elementId]);
                // }
            }
        ));
        this.clickConsumers.push(new LabelClicksConsumers(
            GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.Ctrl),
            (int) => int instanceof RulerSizeIntersection,
            (int, med) => {
                // if (int instanceof RulerSizeIntersection) {
                //     this.sceneInstances.toggleSelected(true, [int.elementId]);
                // }
            }
        ));
        this.clickConsumers.push(new LabelClicksConsumers(
            GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.Shift),
            (int) => int instanceof RulerSizeIntersection,
            (int, med) => {
                // if (int instanceof RulerSizeIntersection) {
                //     this.sceneInstances.toggleSelected(false, [int.elementId]);
                // }
            }
        ));


    }

    isEnabled(): boolean {
        return true;
    }

    _handleLabelLeftClick(int: SizeLabelIntersection, buttons: GesturesButtons) {

    }

    _callSizeLabelEvent(int: SceneInt, med: MouseEventData) {
        if (!(int instanceof SizeLabelIntersection)) {
            return;
        }
        const text = this.uiUnits.convertToStringWithUnits(int.siValue, int.ty);
        const args: MeasureLabelCopyArgs = {
            'valueSi': int.siValue,
            'type': int.ty,
            'uiString': text,
            'event': med.source,
        }
        this.sizeLabelCopyEvent.notify_later_legacy(args);
    }

}

class LabelClicksConsumers implements MouseClickConsumer {

    buttons: GesturesButtons;
    clickHandler: (sceneInt: SceneInt, me: MouseEventData) => void;
    sceneRaycastTypeguard: (closest: SceneInt) => boolean;

    constructor(
        buttons: GesturesButtons,
        sceneRaycastTypeguard: (closest: SceneInt) => boolean,
        clickHandler: (sceneInt: SceneInt, me: MouseEventData,
    ) => void) {
        this.buttons = buttons;
        this.sceneRaycastTypeguard = sceneRaycastTypeguard;
        this.clickHandler = clickHandler;
    }
}


export abstract class SizeLabelIntersection implements RayIntersection {
    readonly distance: number;
	readonly point: Vector3;
    readonly meshRef: SizeLabelMesh;
    readonly ty: UnitType;
    readonly siValue: number;
    readonly normal: Vector3 = new Vector3(0, 1, 0);

    constructor(
        distance: number,
        point: Vector3,
        meshRef: SizeLabelMesh,
        ty: UnitType,
        siValue: number,
    ) {
        this.distance = distance;
        this.point = point;
        this.meshRef = meshRef;
        this.ty = ty;
        this.siValue = siValue;
    }
}

export class ClipboxSizeIntersection extends SizeLabelIntersection {

	constructor(
		point: Vector3,
		distance: number,
		sizeMesh: SizeLabelMesh
	) {
		super(distance, point, sizeMesh, UnitType.Length, sizeMesh.edgeFrom.distanceTo(sizeMesh.edgeTo));
	}
}

export class RulerSizeIntersection extends SizeLabelIntersection {

    elementId: IdBimScene;

	constructor(
		point: Vector3,
		distance: number,
        sizeMesh: SizeLabelMesh,
        elementId: IdBimScene,
        ty: UnitType,
        siValue: number,
	) {
        super(distance, point, sizeMesh, ty, siValue);
        this.elementId = elementId;
	}
}

