import { IterUtils } from "engine-utils-ts";
import { ESO_AnotationFlags } from "./ESO";
import type { ESOHandle } from "../scene/ESOsCollection";
import type { ESO_SingleGeo} from "./ESO_SingleGeo";
import { ESO_SingleGeoHandler } from "./ESO_SingleGeo";
import type { ESSO_Any, ESSO } from "./ESSO";
import type { GeometryCtor } from "../scene/GeometryEditsHandler";
import type { AnyBimGeometry, IdBimGeo } from "bim-ts";
import type { ESOsHandlerInputs } from "./ESOsHandlerBase";


export abstract class ESO_SingleGeoIndividual<ESO_T extends ESO_SingleGeo> extends ESO_SingleGeoHandler<ESO_T> {


	constructor(
		identifier: string,
		args: ESOsHandlerInputs,
	) {
		super(identifier, args);

		// this.triangulationCalculations.updatesStream.subscribe({
		// 	settings: {immediateMode: true},
		// 	onNext: (update) => {
		// 		console.warn('async triangulations update', update);
		// 	}
		// })
	}
		
	abstract createSelfESSOs(handle: ESOHandle, obj: ESO_T, result: any[]): void;

	createAuxiluaryEditESSOs(handle: ESOHandle, obj: ESO_T, geoId: IdBimGeo, bimGeo: AnyBimGeometry, result: ESSO_Any[]): void {
	};

	createSubObjectsFor(objs: [ESOHandle, ESO_T][]): Iterable<[ESOHandle, ESSO<any>[]]> {
		
		const subObjects = IterUtils.mapIter(
			objs,
			([handle, obj]) => {
				let subobjs: [] = [];

				this.createSelfESSOs(handle, obj, subobjs);

				if (obj.annotationFlags & ESO_AnotationFlags.IsInEdit) {
					const geoId = obj.geometryId()!;

					const bimGeo = this.bimGeos.peekById(geoId);
					if (bimGeo) {
						const editshandler = this.geometriesEditHandlers.get(bimGeo.constructor as GeometryCtor);
						if (editshandler) {
							editshandler.createEditRepr(handle, obj, bimGeo, subobjs);
						} else {
							this.logger.error('no geo edit handler for', bimGeo);
						}

						this.createAuxiluaryEditESSOs(handle, obj, geoId, bimGeo, subobjs);
					}
				}

				return [handle, subobjs] as [ESOHandle, ESSO<any>[]];
			}
		);

		return subObjects;
	}
}
