export const DescriptionColId = 'Description';
export const CostSourceColId = 'Cost Source';
export const CostStateColId = 'Cost state';
export const CostUnitColId = 'Cost Unit';
export const QuantityColId = 'Quantity';

export const LaborCostPerUnitColId = 'Labor cost per Unit';
export const LoadedWageRateColId = 'Loaded wage Rate';
export const LaborPerUnitColId = 'Labor hours per Unit';
export const LaborTotalColId = 'Labor Total';

export const MaterialCostPerUnitColId = 'Material cost per Unit';
export const MaterialTotalColId = 'Material Total';

export const EquipmentCostPerUnitColId = 'Equipment cost per Unit';
export const EquipmentTotalColId = 'Equipment Total';

export const SubServiceCostPerUnitColId = 'Sub/Service cost per Unit';
export const SubServiceTotalColId = 'Sub/Service Total';

export const CostPerWattColId = 'Cost Per Watt';
export const TotalCostColId = 'Total Cost';



export const SummaryTableId = 'summary';
export const SummaryTableTabName = 'Overview';

export const PVModuleTableId = 'pv';
export const PVModuleTopCategoryName = 'PV Modules Subtotal';
export const PVModuleTableTabName = 'PV Modules';

export const PCSTableId = 'pcs';
export const PCSTopCategoryName = 'Power Conversion System (PCS) Subtotal';
export const PCSTableTabName = 'PCS';

export const ElectricalTableId = 'electrical';
export const ElectricalTopCategoryName = 'Electrical Subtotal';
export const ElectricalTableTabName = 'Electrical';

export const CivilTableId = 'civil';
export const CivilTopCategoryName = 'Civil Subtotal'
export const CivilTableTabName = 'Civil'

export const StructuralTableId = 'structural';
export const StructuralTopCategoryName = 'Structural Subtotal'
export const StructuralTableTabName = 'Structural';

export const DesignAndEngineeringTableId = 'd&e';
export const DesignAndEngineeringTopCategoryName = 'Design and Engineering Subtotal'
export const DesignAndEngineeringTableTabName = 'Design and Engineering'

export const SetupTableId = 'setup'
export const SetupTableTabName = 'All Rates'

