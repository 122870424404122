<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path d="M4.5 17L2 3" stroke="#E72C03" stroke-width="1.6" />
    <path d="M8 7L7.5 1" stroke="#E72C03" stroke-width="1.6" />
    <path d="M18 3L15.5 17" stroke="#E72C03" stroke-width="1.6" />
    <path
        d="M12.5 8.33333L8 7L5.5 8.6M12.5 8.33333L17 9.66667L14.75 11.8333M12.5 8.33333L7.75 12.1M7.75 12.1L3 10.2L5.5 8.6M7.75 12.1L12.5 14L14.75 11.8333M5.5 8.6L14.75 11.8333"
        stroke="white"
        stroke-width="1.2"
        stroke-linejoin="round"
    />
</svg>
