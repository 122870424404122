import { CostModel as CM } from 'bim-ts';
import { ColDef } from "ag-grid-enterprise";
import { commonCellClasses, formatNumber, getContext } from './common';


export const createQuantityColumnDefinition = (): ColDef<CM.FlattenedCostCategoryParams> => ({
    colId: CM.QuantityColId,
    headerName: CM.QuantityColId,

    valueGetter: (params) => {
        const { category, unitsMapper } = getContext(params);
        if (!category?.quantity?.value) {
            return;
        }
        const mapped = unitsMapper.mapToConfigured(category.quantity.value);
        return formatNumber(mapped.value) + (mapped.unit ? ' ' + mapped.unit : '');
    },

    cellClass: (params) => {
        const classes = new Set(commonCellClasses(getContext(params), x => x.quantity));
        classes.add('ag-grid-cell-with-right-separator')
        return Array.from(classes);
    },

    headerClass: () => {
        return [
            'ag-grid-cell-with-right-separator',
            'ag-grid-header-align-right'
        ]
    },

})
