import { isInNode } from "engine-utils-ts";

export function isLocalOrigin() {
    return isInNode() ? false : !!["192.168", "127.0.0.1", "0.0.0.0", "localhost"].find((x) =>
        window.location.origin.includes(x)   
    );
}

export function isProduction(): boolean {
    return isInNode() ? false : !!["app.solar-prod", "app.pvfarm"].find((x) =>
        window.location.origin.includes(x)
    );
}
