import { Matrix4, Vector3 } from 'math-ts';

const svgns = "http://www.w3.org/2000/svg";
const CompassWidth = 144;
const CompassHeight = 144;
const CompassCircleRadius = 32;
const CompassAxisOffset = 68;

const CompassPoints = ['s', 'e', 'w', 'n'] as const;
type CompassPointType = typeof CompassPoints[number];
const FullMask = CompassPoints.map(p => `url(#${p}Mask)`).join(', ');

const CameraAnglesForFocus: Record<CompassPointType, [number, number]> = {
	n: [Math.PI / 2, Math.PI],
	e: [Math.PI / 2, Math.PI / 2],
	s: [Math.PI / 2, 0],
	w: [Math.PI / 2, Math.PI / 2 * 3]
}

export class Compass {
	private readonly container: HTMLElement;
	private cameraMatrix: Matrix4 = new Matrix4();

	constructor(engineContainer: HTMLElement, public readonly focusCamera: (vertAngle: number, horAngle: number) => boolean) {
		this.container = document.createElement("div");
		this.container.setAttribute('id', 'engineCompass');
		this.container.addEventListener('click', this.onPointClick.bind(this));

		this.container.appendChild(this.createSvg());
		engineContainer.appendChild(this.container);
	}

	private createSvg() {
		const svg = document.createElementNS(svgns, "svg");
		svg.setAttribute('width', `${CompassWidth}`);
		svg.setAttribute('height', `${CompassHeight}`);
		svg.setAttribute('viewBox', `${-CompassWidth / 2}, ${-CompassHeight / 2}, ${CompassWidth}, ${CompassHeight}`);
		svg.setAttribute('fill', 'none');
		
		const style = document.createElementNS(svgns, "style");
		style.appendChild(document.createTextNode(CompassStyles));
		svg.appendChild(style);

		this.addElementDefs(svg);

		this.addMainCircle(svg);
		return svg;
	}

	private addElementDefs(svg: SVGSVGElement) {
		const defs = document.createElementNS(svgns, "defs");

		const rect = document.createElementNS(svgns, 'rect');
		rect.setAttribute('x', `${-CompassWidth / 2}`);
		rect.setAttribute('y', `${-CompassHeight / 2}`);
		rect.setAttribute('width', `${CompassWidth}`);
		rect.setAttribute('height', `${CompassHeight}`);
		rect.setAttribute('fill', 'white');
		rect.setAttribute('id', 'backgroundMaskRect');
		defs.appendChild(rect);

		defs.appendChild(this.createCircle(12, 'black', 'circleMaskBase'));
		defs.appendChild(this.createCircle(13, 'black', 'circleMaskNorth'));
		defs.appendChild(this.createCircle(11, '', 'circleBase'));
		defs.appendChild(this.createCircle(12, '', 'circleNorth'));

		svg.appendChild(defs);
	}

	private createCircle(radius: number, color?: string, id?: string) {
		const circle = document.createElementNS(svgns, "circle");
        circle.setAttribute('r', `${radius}`);
		if (color) {
        	circle.setAttribute('fill', color);
		}
		if (id) {
        	circle.setAttribute('id', id);
		}
		return circle;
	}

	private wrapInGroup(child: SVGElement, id?: string, className?: string) {
		const group = document.createElementNS(svgns, "g");
		group.appendChild(child);
		if (id) {
        	group.setAttribute('id', id);
		}
		if (className) {
        	group.classList.add(className);
		}
		return group;
	}

	private addMainCircle(svg: SVGSVGElement) {
		const compassCircle = this.createCircle(CompassCircleRadius);
		compassCircle.setAttribute('stroke', '#1A1E1F');
		compassCircle.setAttribute('stroke-opacity', '0.12');
		compassCircle.setAttribute('stroke-width', '12');

		const group = this.wrapInGroup(compassCircle, 'mainCircle');
		group.appendChild(this.createCircle(2, "rgba(9, 13, 15, 0.94)"));

		const wrapperGroup = this.wrapInGroup(group);
		wrapperGroup.setAttribute('style', `mask-image: ${FullMask}`);
		svg.appendChild(wrapperGroup);

		this.addPoint(svg, 'e');
		this.addPoint(svg, 's');
		this.addPoint(svg, 'w');
		this.addPoint(svg, 'n');

		this.addAxis(svg, group, 'y', false, '#689F00');
		this.addAxis(svg, group, 'x', true, '#D75C6B');
	}

	private addAxis(svg: SVGSVGElement, axisContainer: SVGGElement, name: string, isHorizontal: boolean, color: string) {
		const length = 60;
		const centerOffset = 4;
		const line = document.createElementNS(svgns, "line");
		line.setAttribute('x1', (isHorizontal ? -centerOffset : 0).toString());
        line.setAttribute('y1', (isHorizontal ? 0 : -centerOffset).toString());
        line.setAttribute('x2', (isHorizontal ? -length : 0).toString());
        line.setAttribute('y2', (isHorizontal ? 0 : -length).toString());
        line.setAttribute('stroke-width', '2');
        line.setAttribute('stroke', color);
		axisContainer.appendChild(line);

		const label = document.createElementNS(svgns, 'text');
		label.setAttribute('id', `${name}Label`);
		label.setAttribute('fill', color);
		label.textContent = name.toUpperCase();
        svg.appendChild(this.wrapInGroup(label));
	}

	private addPoint(container: SVGElement, name: CompassPointType) {
		const isNorthPoint = name === 'n';
		const circle = document.createElementNS(svgns, 'use');
		circle.setAttribute('href', `#${isNorthPoint ? 'circleNorth' : 'circleBase'}`);
		const group = this.wrapInGroup(circle, `${name}Point`, 'point');
        
		const label = document.createElementNS(svgns, 'text');
		label.textContent = name.toUpperCase();
        group.appendChild(label);

		container.appendChild(this.wrapInGroup(group));
        
		// add mask
		const mask = document.createElementNS(svgns, 'mask');
		mask.setAttribute('id', `${name}Mask`);

		const backgroundMaskElem = document.createElementNS(svgns, 'use');
		backgroundMaskElem.setAttribute('href', '#backgroundMaskRect');
		mask.appendChild(backgroundMaskElem);

		const circleMaskElem = document.createElementNS(svgns, 'use');
		circleMaskElem.setAttribute('href', `#${isNorthPoint ? 'circleMaskNorth' : 'circleMaskBase'}`);
        circleMaskElem.setAttribute('id', `${name}PointMask`);

		mask.appendChild(circleMaskElem);
		container.appendChild(mask);
	}

	update(cameraMatrix: Matrix4) {
		if (cameraMatrix.equals(this.cameraMatrix)) {
			return;
		}
		this.cameraMatrix.copy(cameraMatrix);
		const matrixForRotation = cameraMatrix.clone().scale(new Vector3(-1, 1, -1)).transpose();
		const xAxis = new Vector3();
		const yAxis = new Vector3();
		const zAxis = new Vector3();
		matrixForRotation.extractBasis(xAxis, yAxis, zAxis);

		const angleZ = zAxis.angleTo(new Vector3(0, 0, 1));
		const angleY = yAxis.angleTo(new Vector3(0, 1, 0));

		this.container.classList.toggle('top-view', angleY < 0.01);
		this.container.classList.toggle('bottom-view', zAxis.z > 0.01);

		const angleDiff = angleZ - Math.PI / 2;
		if (Math.abs(angleDiff) < Math.PI / 18) {
			//stop compass rotation if camera is close to flat position
			const yDirection = Math.sign(zAxis.y) || 1;
			const zDirection = Math.sign(zAxis.z - 0.01) || 1;
			const matrixDiff = new Matrix4().makeRotationX((yDirection * angleDiff + yDirection * zDirection * Math.PI / 18));
			this.rotate(matrixDiff.multiply(matrixForRotation));
		} else {
			this.rotate(matrixForRotation);
		}
	}

	private rotate(cameraMatrix: Matrix4) {
		const xAxis = new Vector3();
		const yAxis = new Vector3();
		const zAxis = new Vector3();
		cameraMatrix.extractBasis(xAxis, yAxis, zAxis);

		const circle: HTMLElement | null = this.container.querySelector('#mainCircle');
		if (circle) {
			circle.style.transform = `matrix3d(${cameraMatrix.elements.join(',')})`;
		}

		this.movePoint("e", xAxis.clone().multiplyScalar(-CompassCircleRadius));
		this.movePoint("s", yAxis.clone().multiplyScalar(CompassCircleRadius));
		this.movePoint("w", xAxis.clone().multiplyScalar(CompassCircleRadius));
		this.movePoint("n", yAxis.clone().multiplyScalar(-CompassCircleRadius));

		this.moveAxisLabel("yLabel", yAxis.clone().multiplyScalar(-CompassAxisOffset));
		this.moveAxisLabel("xLabel", xAxis.clone().multiplyScalar(-CompassAxisOffset));
	}

	private movePoint(name: CompassPointType, vector: Vector3) {
		const nodeElement: HTMLElement | null = this.container.querySelector(`#${name}Point`);
		const translate = `translate(${vector.x}px, ${vector.y}px)`;
		if (nodeElement) {
			nodeElement.style.transform = translate;
			const parentElement = nodeElement.parentElement!;
			if (vector.z < 0) {
				const restPoints = CompassPoints.filter(p => p !== name);
				parentElement.style.maskImage = restPoints.map(p => `url(#${p}Mask)`).join(', ');
			} else {
				parentElement.style.maskImage = '';
			}
		}
		
		const maskElement = this.container.querySelector(`#${name}PointMask`) as HTMLElement;
		if (maskElement) {
			maskElement.style.transform = translate;
		}
	}

	private moveAxisLabel(name: string, vector: Vector3) {
		const nodeElement: HTMLElement | null = this.container.querySelector(`#${name}`);
		if (nodeElement) {
			nodeElement.style.transform = `translate(${vector.x}px, ${vector.y}px )`;
			nodeElement.parentElement!.style.maskImage = vector.z < 0 ? FullMask : '';
		}
	}

	onPointClick(event: MouseEvent) {
		const point = event.target instanceof Element ? event.target.closest('.point') : null;
		if (point && point.id === 'nPoint') {
			this.focusCamera(0, 0);
		}
	}

	dispose() {
		this.container.removeEventListener('click', this.onPointClick);
		this.container.remove();
	}
}

const CompassStyles = `
	#engineCompass {
		position: absolute;
        bottom: 0px;
        right: 20px;
		pointer-events: none;
	}
	#engineCompass svg > g {
		mask-composite: intersect;
	}
	#engineCompass text,
	#engineCompass .axisLabel {
		font-weight: 800;
		font-size: 12px;
		line-height: 12px;
		text-anchor: middle;
		dominant-baseline: central;
	}
	#engineCompass .point text {
		fill: #fff;
	}
	#engineCompass .point {
		user-select: none;
		fill: #a6a8a9;
		pointer-events: auto;
	}
	#engineCompass #nPoint {
		fill: #5e6262;
		cursor: pointer;
	}
	#engineCompass.top-view #nPoint {
		fill: #E72C03;
	}
	#engineCompass .point use,
	#engineCompass .point text {
		filter: brightness(100%);
		transition: filter 0.5s linear;
	}
	#engineCompass.bottom-view .point use {
		filter: brightness(50%);
	}
	#engineCompass.bottom-view .point text {
		filter: brightness(75%);
	}
`;