import { CostModel as CM, UnitsMapper } from 'bim-ts';
import { CellStyle, ColDef, ValueFormatterFunc, ValueGetterFunc, ValueParserFunc, ValueSetterFunc, ValueSetterParams } from "ag-grid-enterprise";
import { GridContext } from '../types';
import { currenciesCustomSymbols } from 'engine-utils-ts';

export const defaultCellStyle: CellStyle = {
    'text-overflow': 'ellipsis',
    'white-space': 'nowrap',
    'overflow': 'hidden',
    'text-align': 'right',
}

export interface CategoryContext {
    category?: CM.CostCategory,
    data?: CM.FlattenedCostCategoryParams,
    unitsMapper: UnitsMapper
    globalContext: GridContext
}

export function getContext(params: {
    data?: CM.FlattenedCostCategoryParams,
    context: GridContext
}): CategoryContext {
    const result: CategoryContext = {
        unitsMapper: params.context.unitsMapper,
        globalContext: params.context,
    }
    if (!params.data) {
        return result;
    }
    result.data = params.data;
    const context = params.context;
    const category = context.hierarchy.categories.get(params.data.categoryId);
    result.category = category;
    return result;
}

export const commonCellClasses = (
    context: CategoryContext,
    cellGetter: (category: CM.CostCategory) => CM.TableCell | undefined,
) => {
    if (!context.category) {
        return [];
    }
    const hierarchy = context.globalContext.hierarchy;

    const cell = cellGetter(context.category);

    const classes = new Set<string>()
    const categoryId = context.data?.categoryId;
    if (categoryId !== undefined) {
        const categoryIgnored = hierarchy.ignored.has(categoryId);
        const categoryDisableEdit = hierarchy.disableEdit.has(categoryId);
        if (cell?.flags?.ignored || categoryIgnored) {
            classes.add('ag-grid-cell-ignored');
        }
        if (!categoryDisableEdit) {
            if (cell?.flags?.overriden) {
                classes.add('ag-grid-cell-overriden-highlight');
            } else if (cell?.flags?.editable) {
                classes.add('ag-grid-cell-editable-highlight');
            }
        }
    }
    return Array.from(classes);
}


export const createCostValueFormatter =
    (config?: {
        doNotShowZeroCosts?: boolean
        round?: boolean,
    }): ValueFormatterFunc<
        CM.FlattenedCostCategoryParams,
        number
    > =>
    (params) => {
        const { unitsMapper } = getContext(params)
        if (typeof params.value !== 'number') {
            return ''
        }
        const trimmedValue = +params.value.toFixed(4);
        if (trimmedValue === 0 && config?.doNotShowZeroCosts) {
            return '';
        }
        const costUnit = unitsMapper.mapToConfigured({ value: 0, unit: 'usd' }).unit!;

        const formattedNumeral = formatNumber(trimmedValue, config?.round ? 0 : undefined);
        const symbol = currenciesCustomSymbols[costUnit]

        if (symbol) {
            return symbol + ' ' + formattedNumeral;
        }
        return formattedNumeral + ' ' + costUnit;
    }

export const costValueFormatter = createCostValueFormatter({ doNotShowZeroCosts: true })


export const numberValueFormatter: ValueFormatterFunc<CM.FlattenedCostCategoryParams, number> =
    (params) => {
        if (typeof params.value !== 'number') {
            return '';
        }
        if (params.value === 0) {
            return '';
        }
        return formatNumber(params.value);
    }

const defaultNumberFormatOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 4,
    minimumFractionDigits: 4,
}
export function formatNumber(value: number, fractionDigits?: number) {
    const config = { ...defaultNumberFormatOptions };
    if (typeof fractionDigits === 'number') {
        config.maximumFractionDigits = fractionDigits;
        config.minimumFractionDigits = fractionDigits;
    }
    return Intl.NumberFormat('en-US', config).format(value).replaceAll(",", " ");
}

export function canEditCommonCell(context: CategoryContext, _cell?: CM.TableCell): boolean {
    if (!context.data || !context.category) {
        return false;
    }
    const hierachy = context.globalContext.hierarchy;
    if (hierachy.ignored.has(context.data.categoryId)) {
        return false;
    }
    if (hierachy.disableEdit.has(context.data.categoryId)) {
        return false;
    }
    return true;
}

export function createModifiableCostCellDefinition(
    getter: (category: CM.CostCategory) => CM.ModifiableCostCell | undefined,
    roundNumberFormatting?: boolean
) {
    const result = {
        valueGetter: ((params) => {
            const { category } = getContext(params);
            const target = category && getter(category);
            const value = target?.value;
            if (value === undefined) {
                return;
            }
            return Number.parseFloat(value.toFixed(4));
        }) as ValueGetterFunc<CM.FlattenedCostCategoryParams, number>,

        valueParser: ((params) => {
            const newValue = Number.parseFloat(params.newValue)
            if (!Number.isFinite(newValue)) {
                return null;
            }
            return newValue;
        }) as ValueParserFunc<CM.FlattenedCostCategoryParams, number>,

        editable: (params) => {
            const context = getContext(params);
            const target = context.category && getter(context.category);
            if (!canEditCommonCell(context, target)) {
                return false;
            }
            return !!target?.update;
        },

        valueSetter: ((params: ValueSetterParams<CM.FlattenedCostCategoryParams, number>) => {
            const { category } = getContext(params);
            const target = category && getter(category);
            const mutate = target?.update;
            if (!mutate) {
                return false;
            }
            //target.value = params.newValue ?? undefined;
            mutate(params.newValue ?? undefined);
            return true;
        }) as ValueSetterFunc<CM.FlattenedCostCategoryParams, number>,

        valueFormatter: createCostValueFormatter({ doNotShowZeroCosts: true, round: roundNumberFormatting }),

        cellClass: (params) => commonCellClasses(getContext(params), getter),

        headerClass: [
            'ag-grid-header-align-right'
        ],
    } satisfies ColDef<CM.FlattenedCostCategoryParams>
    return result;
}

