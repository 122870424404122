import { Failure, ObjectUtils } from "engine-utils-ts";
import type { BimPropertyData } from "../../bimDescriptions/BimProperty";
import { BimProperty } from "../../bimDescriptions/BimProperty";
import { extractValueUnitPropsGroup, flattenNamedPropsGroups } from "../../bimDescriptions/NamedBimPropertiesGroup";
import type { SolverInstancePatchResult } from "../../runtime/ReactiveSolverBase";
import { SolverObjectInstance } from "../../runtime/SolverObjectInstance";
import type { ModuleUniqueProps} from "../pv-module/PVModule";
import { PVModuleKeyProps, PVModuleTypeIdent } from "../pv-module/PVModule";
import { FixedTiltKeyProps, FixedTiltTypeIdent } from "./FixedTilt";
import type { Bim } from '../../Bim';
import { FixedTrackerPilesPropsGroup} from "src/piles/common";
import { mergeCostComponents, sumCostComponents } from "src/cost-model/capital";
import type { TrackerFrameFormatterPropsGroup} from "../TrackerFrame";
import { TrackerFrameTypeIdentifier } from "../TrackerFrame";
import { InstanceCostsGlobal } from "../EquipmentCommon";
import { TrackerPriceSharedArgGlobalIdentifier } from "src/trackers/pricing/TrackerPricingSolver";
import type { PropertiesGroupFormatters, TrackerPile } from "src";
import { FixedTiltFrameRelatedProps, FixedTiltFrameUniqueProps, createSolarArrayFrameDefaultCostPerEach, fixedTiltToTrackerFrameFormatterProps, fixedTiltToTrackerFrameProps } from "src/cost-model/capital/tables/categories/structural/racking";
import { createDefaultCostModuleEach, createDefaultCostModulePerWatt } from "src/cost-model/capital/tables/categories/pv-modules/modules-install";
import { extractPilesFromFixedTrackerFromPropsGroup } from "src/piles/TrackerPile";
import { Matrix4 } from 'math-ts';

export function registerFixedTrackerPricingSolver(bim: Bim): void {
    const legacyPropsGroups = {
        pilesRelated: FixedTrackerPilesPropsGroup,
        fixedTiltKeyProps: FixedTiltKeyProps,
        moduleKeyProps: PVModuleKeyProps,
        otherFixedTiltPriceRelated: OtherPriceRelatedFixedTiltProps,
        FixedTiltFrameUniqueProps,
        trackerFrameRelated: FixedTiltFrameRelatedProps,
    };

    const [flattenedProps, unflatten] = flattenNamedPropsGroups(legacyPropsGroups);

    const identityMatrix = new Matrix4();

    bim.reactiveRuntimes.registerRuntimeSolver(new SolverObjectInstance({
        solverIdentifier: 'fixed-tilt-pricing',
        objectsDefaultArgs: {
            legacyProps: flattenedProps,
        },
        globalArgsSelector: {
            [TrackerPriceSharedArgGlobalIdentifier]: InstanceCostsGlobal
        },
        objectsIdentifier: FixedTiltTypeIdent,
        cache: true,
        solverFunction: (inputObj, globals): SolverInstancePatchResult => {
            const legacyPropsGroups = unflatten(inputObj.legacyProps);
            if (globals[TrackerPriceSharedArgGlobalIdentifier] instanceof Failure) {
                return {};
            }
            const piles = extractPilesFromFixedTrackerFromPropsGroup(0, false, legacyPropsGroups.pilesRelated, identityMatrix);
            const trackerFrameProps = fixedTiltToTrackerFrameFormatterProps(legacyPropsGroups.trackerFrameRelated)
            const result = createFixedTiltPricePatch(
                {
                    moduleKeyProps: legacyPropsGroups.moduleKeyProps,
                    otherFixedTiltPriceRelated: legacyPropsGroups.otherFixedTiltPriceRelated,
                    piles,
                    fixedTiltKeyProps: legacyPropsGroups.fixedTiltKeyProps,
                    fixedTiltFrameUniqueProps: legacyPropsGroups.FixedTiltFrameUniqueProps,
                    trackerFrame: trackerFrameProps,
                },
                globals[TrackerPriceSharedArgGlobalIdentifier].value,
                bim.keyPropertiesGroupFormatter,
            );
            return result;
        }
    }));
}

const OtherPriceRelatedFixedTiltProps = {
    moduleCount: BimProperty.NewShared({
        path: ['circuit', 'equipment', 'modules_count'],
        value: 0,
    }),
    loadWindPosition: BimProperty.NewShared({
        path: ['position', 'load_wind_position'],
        value: '',
    }),
}

function createFixedTiltPricePatch(
    instanceRelatedArgs: FixedTiltPricingArg,
    commonArgs: InstanceCostsGlobal,
    keyPropsFormatter: PropertiesGroupFormatters,
): SolverInstancePatchResult {

    // find matching tracker frame
    let frameTotalCost = 0;
    let frameTitle = 'unknown frame'
    {
        frameTitle = keyPropsFormatter.formatNamedProps(TrackerFrameTypeIdentifier, instanceRelatedArgs.trackerFrame) ?? frameTitle;
        const props = extractValueUnitPropsGroup(fixedTiltToTrackerFrameProps(instanceRelatedArgs.fixedTiltFrameUniqueProps))
        const cost = sumCostComponents(mergeCostComponents(
            commonArgs.costs.find(x =>
                x.instance_type === TrackerFrameTypeIdentifier &&
                ObjectUtils.areObjectsEqual(x.props, props)
            )?.costs,
            createSolarArrayFrameDefaultCostPerEach(props)
        ));
        frameTotalCost = cost;
    }

    // modules
    let allModulesTotalCost = 0;
    let singleModuleTotalCost = 0;
    const moduleKeyProps = extractValueUnitPropsGroup(instanceRelatedArgs.moduleKeyProps);
    const moduleTitle = keyPropsFormatter.formatNamedProps(PVModuleTypeIdent, moduleKeyProps);
    {
        const uniqueProps: typeof ModuleUniqueProps = {
            manufacturer: moduleKeyProps.manufacturer,
            model: moduleKeyProps.model,
            power: moduleKeyProps.maximum_power,
        }
        const costs = commonArgs.costs.filter(x =>
            x.instance_type === PVModuleTypeIdent &&
            ObjectUtils.areObjectsEqual(x.props, uniqueProps)
        )
        const wattCost = sumCostComponents(mergeCostComponents(costs.find(x => x.name === 'watt')?.costs, createDefaultCostModulePerWatt()));
        const eachCost = sumCostComponents(mergeCostComponents(costs.find(x => x.name === 'each')?.costs, createDefaultCostModuleEach()));
        const modulePowerWatt = uniqueProps.power.as('W');
        const singleCost = modulePowerWatt * wattCost + eachCost
        const count = instanceRelatedArgs.otherFixedTiltPriceRelated.moduleCount.asNumber();
        allModulesTotalCost = count * singleCost;
        singleModuleTotalCost = singleCost;
    }

    // piles
    let pilesTotalCost = 0;
    {
        const allPileCosts = commonArgs.costs.filter(x => x.instance_type === `tracker-pile`);
        for (const pile of instanceRelatedArgs.piles) {
            const cost = pile.calculatePileCost(allPileCosts);
            pilesTotalCost += cost.value;
        }
    }


    // assembly cost
    const assemblyCostUsd = pilesTotalCost + frameTotalCost + allModulesTotalCost;

    // compose patch
    const patch: BimPropertyData[] = [
        {
            path: ['cost', 'total_cost'],
            value: assemblyCostUsd,
            unit: 'usd',
            readonly: true,
        },
        {
            path: ['cost', 'frame', 'cost_item'],
            value: frameTitle,
            description: '',
            readonly: true,
        },
        {
            path: ['cost', 'frame', 'cost'],
            value: frameTotalCost,
            unit: 'usd',
            readonly: true,
        },
        {
            path: ['cost', 'frame', 'total_cost'],
            value: frameTotalCost,
            unit: 'usd',
            readonly: true,
        },
        {
            path: ['cost', 'module', 'cost_item'],
            value: moduleTitle,
            description: '',
            readonly: true,
        },
        {
            path: ['cost', 'module', 'per_each_cost'],
            value: singleModuleTotalCost,
            unit: 'usd',
            readonly: true,
        },
        {
            path: ['cost', 'module', 'total_cost'],
            value: allModulesTotalCost,
            unit: 'usd',
            readonly: true,
        },
        {
            path: ['cost', 'piles', 'total_cost'],
            value: pilesTotalCost,
            unit: 'usd',
            readonly: true,
        },
    ];

    return {
        legacyProps: patch,
        removeProps: [
            BimProperty.MergedPath(['cost', 'frame', 'position_multiplier']),
            BimProperty.MergedPath(['cost', 'status']),
            BimProperty.MergedPath(['cost', 'has_zero_costs']),
        ],
    }

}

interface FixedTiltPricingArg {
    piles: TrackerPile[]
    fixedTiltKeyProps: typeof FixedTiltKeyProps
    fixedTiltFrameUniqueProps: typeof FixedTiltFrameUniqueProps
    moduleKeyProps: typeof PVModuleKeyProps
    otherFixedTiltPriceRelated: typeof OtherPriceRelatedFixedTiltProps
    trackerFrame: typeof TrackerFrameFormatterPropsGroup
}

