import { Vector2 } from "math-ts";
import { Object2D, VectorPrimitiveCircle, VectorPrimitivePath, VectorPrimitivePathDescription, VectorPrimitiveRectangle } from "vector-graphic";
import { wireWidth } from "./config";

export function createCombinerBoxObject2D(): Object2D {
    const inverter = new Object2D()
    inverter.name = 'inverter'

    const frame = new VectorPrimitiveRectangle({
        width: 240,
        height: 240,
        strokeWidth: 8,
        stroke: 'black',
    })
    inverter.addPrimitiveAndExpandAabb(frame);

    // add wiring
    {
        const center = new Vector2();
        const delta = inverter.aabb.width() / 8
        const pt1 = center.clone().setX(delta)
        const pt2 = center.clone().setX(delta * 3)
        const pt3 = inverter.aabb.max.clone().setY(0)
        const pt4 = pt1.clone().rotateAround(pt2, Math.PI/180*20)
        const wirePrimitive = new VectorPrimitivePath({
            paths: [
                new VectorPrimitivePathDescription([center, pt1]),
                new VectorPrimitivePathDescription([pt4, pt2, pt3]),
            ],
            strokeWidth: wireWidth,
        })
        inverter.addPrimitiveAndExpandAabb(wirePrimitive);

        const circle1 = new VectorPrimitiveCircle({
            cx: pt1.x, cy: pt1.y, radius: 7, fill: 'white', stroke: 'black', strokeWidth: wireWidth,
        })
        circle1.zIndex = 1;
        const circle2 = new VectorPrimitiveCircle({
            cx: pt2.x, cy: pt2.y, radius: 7, fill: 'white', stroke: 'black', strokeWidth: wireWidth,
        })
        circle2.zIndex = 1;

        inverter.addPrimitiveAndExpandAabb(circle1)
        inverter.addPrimitiveAndExpandAabb(circle2)
    }

    return inverter
}

export function createCombinerBoxInternalWiring(params: {
    inputs: Vector2[],
    cbCenter: Vector2,
}) {
    const object = new Object2D();
    const boxHeight = 10;
    const paths: VectorPrimitivePathDescription[] = [];
    for (const input of params.inputs) {
        const pt1 = input.clone();
        const pt2 = new Vector2(params.cbCenter.x, pt1.y);
        const pt3 = params.cbCenter.clone();
        const boxWidth = (pt2.x - pt1.x) * 0.3;
        const box = new VectorPrimitiveRectangle({
            cx: (pt1.x + pt2.x) * 0.5,
            cy: pt1.y,
            height: boxHeight,
            width: boxWidth,
            fill: 'white',
            strokeWidth: wireWidth,
            stroke: 'black',
        })
        box.zIndex = 2
        object.addPrimitiveAndExpandAabb(box);
        paths.push(new VectorPrimitivePathDescription([pt1, pt2, pt3]));
    }

    object.addPrimitiveAndExpandAabb(new VectorPrimitivePath({ paths, strokeWidth: wireWidth }))
    return object
}

