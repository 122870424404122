import { LegacyLogger } from "engine-utils-ts";
import type { Handle} from "../memory/Handle";
import { HandleIndexMask } from "../memory/Handle";
import type { index } from "../utils/Utils";
import Utils from "../utils/Utils";

export class OneToManyHandles<TOne, THandleMany extends Handle> {
    readonly _oneToMany: Map<TOne, THandleMany | THandleMany[]> = new Map();
    readonly _backToOne: (TOne | undefined)[] = [];
    

	freeByParentRef(refFrom: TOne): void {
		for (const ch of this.getReferenced(refFrom)) {
			this.freeChildRef(ch);
		}
		this._oneToMany.delete(refFrom);
	}

	freeChildRef(childH: THandleMany) {
		const parentRef = this._backToOne[childH & HandleIndexMask];
		if (parentRef == undefined) {
			return;
		}
		this._backToOne[childH & HandleIndexMask] = undefined;
		{
            const toChildrenRefs = this._oneToMany.get(parentRef);
			if (toChildrenRefs instanceof Array) {
				Utils.removeFirstOccurence(toChildrenRefs, childH);
				if (toChildrenRefs.length === 0) {
					this._oneToMany.delete(parentRef);
				}
			} else if (toChildrenRefs === childH) {
				this._oneToMany.delete(parentRef);
			}
		}

	}

	add(refFrom: TOne, refTo: THandleMany): boolean {
		this._backToOne[refTo & HandleIndexMask] = refFrom;
		const saved = this._oneToMany.get(refFrom);
        if (saved === undefined) {
            this._oneToMany.set(refFrom, refTo);
		} else if (Array.isArray(saved)) {
			if (saved.includes(refTo)) {
				LegacyLogger.deferredWarn('attempt to ref the same element twice', refTo);
				return false;
			}
			saved.push(refTo);
		} else {
			if (saved === refTo) {
				LegacyLogger.deferredWarn('attempt to ref the same element twice', refTo);
				return false;
			}
			this._oneToMany.set(refFrom, [saved, refTo]);
		}
		return true;
	}

	*getReferenced(refFrom: TOne): IterableIterator<THandleMany> {
        const saved = this._oneToMany.get(refFrom);
		if (saved === undefined) {
			return;
		}
		if (saved instanceof Array) {
			yield* saved.values(); 
		} else {
			yield saved;
		}
	}

	getParentRef(childHandle: THandleMany | index): TOne | undefined {
		return this._backToOne[childHandle & HandleIndexMask];
	}

	getReferencedAsArray(parentId: TOne): THandleMany[] {
        const saved = this._oneToMany.get(parentId);
		if (saved === undefined) {
			return [];
		}
		if (saved instanceof Array) {
			return saved; 
		} else {
			return [saved];
		}
	}

	gatherReferencedBy(ids: Iterable<TOne>): THandleMany[] {
		const result: THandleMany[] = [];
		for (const id of ids) {
			for (const chH of this.getReferenced(id)) {
				result.push(chH);
			}
		}
		return result;
	}

	clear() {
		this._oneToMany.clear();
		this._backToOne.length = 0;
	}
}
