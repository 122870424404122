import type { TMY_ColumnDates, UnitsMapper } from 'bim-ts';
import { type LazyVersioned, LazyDerived, DefaultMapObjectKey, CompressibleNumbersArray } from 'engine-utils-ts';
import { generateEchartsFor, type TmyDataVizNumericColumn, type TmyDataVizType } from '../tmy-data-viz-utils/TmyDataVizGenerators';
import type { EnergyViewData } from './EnergyViewDataGenerator';
import type { EnergyViewVizParams } from './EnergyViewSettings';


export function createEnergyViewDataVizGenerator(
    vizSettings: LazyVersioned<EnergyViewVizParams>,
    energyDataLazy: LazyVersioned<EnergyViewData>,
    unitsMapper: UnitsMapper,
    heightPerChart: number,
): LazyDerived<TmyDataVizType[]> {

    return LazyDerived.new2(
        'createEnergyViewDataVizGenerator',
        [unitsMapper],
        [vizSettings, energyDataLazy],
        ([settings, energyData]): TmyDataVizType[] => {

            if (settings.data_selector.length === 0
                || !settings.visualization_type
            ) {
                return [];
            }


            interface ArrayLengtDates {
                length: number,
                dates: TMY_ColumnDates|null,
            }

            const arraysByLengthToChart = new DefaultMapObjectKey<ArrayLengtDates, TmyDataVizNumericColumn[]>({
                unique_hash: (key) => `${key.length}:${key.dates?.uniqueValueHash() ?? null}`,
                valuesFactory: () => [],
            });


            for (const [key, data] of energyData.toChart) {
                if (!settings.data_selector.includes(key)) {
                    continue;
                }

                let dates: TMY_ColumnDates|null;
                let tmyDataSource: TmyDataVizNumericColumn;

                if (data instanceof CompressibleNumbersArray) {
                    dates = null;
                    tmyDataSource = {
                        header: key,
                        min: data.min()!,
                        max: data.max()!,
                        unit: data.unit,
                        values: [...data.toArray()],
                    };
                } else {
                    console.warn('unexpected chart data type: ' + key, data);
                    continue;
                }

                arraysByLengthToChart
                    .getOrCreate(Object.freeze({length: tmyDataSource.values.length, dates}))
                    .push(tmyDataSource);
            }


            const result: TmyDataVizType[] = [];

            for (const [{dates}, columns] of arraysByLengthToChart) {

                const charts = generateEchartsFor(
                    columns,
                    dates,
                    settings.visualization_type,
                    unitsMapper,
                    heightPerChart,
                );
                result.push(...charts);
            }
            return result;
        }
    );
}
