import type { DropdownViewDescription, UiBindings } from 'ui-bindings';
import { PUI_Builder, PUI_ConfigBasedBuilderParams, PUI_ConfigPropertyTransformer, PUI_GroupNode, PUI_Lazy, PUI_PropertyNodeVec3 } from 'ui-bindings';

import { addEditUiBindings } from './controls/EditUiBindings';
import { addEngineKeyBindings } from './controls/StandardCombinationActions';
import { TransformGizmoUiBindings } from './gizmos/TransformGizmoUiBindings';
import type { KreoEngineImpl } from './KreoEngineImpl';
import { GraphicsSettingsUiBindings } from './GraphicsSettings';
import { snappingUiBindings } from './SnappingSettings';
import type { IdBimScene} from 'bim-ts';
import { BimUiBindings, SceneInstanceFlags, SceneObjDiff } from 'bim-ts';
import { SnappingMode } from './EngineConsts';
import type { LazyVersioned} from 'engine-utils-ts';
import { LazyDerived, DefaultMap, StringUtils } from 'engine-utils-ts';
import { Matrix4, Vector3 } from 'math-ts';
import type { InObjFullId } from './scene/EngineSceneIds';
import type { InteractiveSceneObjectsEdit } from './scene/InteractiveSceneObjectsEdit';
import { AnnotationsSettingsUiBindings } from './annotations/AnnotationsSettingsUiBindings';
import { TrackersPilesMarkupSettingsUiBindings } from './TrackersPilesMarkupSettingsUiBindings';

export class EngineUiBindings {

    static addEngineUiBindings(uiBindings: UiBindings, engine: KreoEngineImpl) {

        let configBuilderSettings = EngineUiBindings.defaulBuilderSettings();

        GraphicsSettingsUiBindings.addBindings(uiBindings, engine.renderSettings);
        TransformGizmoUiBindings.addBindings(uiBindings, engine.transformGizmo);
		snappingUiBindings(uiBindings, engine.snappingSettings);
        AnnotationsSettingsUiBindings.addBindings(uiBindings, engine.annotationsSettings);
        TrackersPilesMarkupSettingsUiBindings.addBindings(uiBindings, engine.trackersPilesMarkupSettings);


        // const markupUi = SiteMarkupUiBindings.markupControlsUi(configBuilderSettings, engine.siteTilesMarkupControls.state);
        // const markupViewDescr: DropdownViewDescription = {
        //     viewSource: buildFromObservableConfigObject({
        //         configBuilderParams: markupUi[0],
        //         configObj: markupUi[1]
        //     }),
        //     header: controlsModeName,
        // }

        // const engineStatusViews = LazyDerived.new1('engine-markup-status', [], [engine.controlsState], ([cs]) => {
        //     if (cs.controlsMode === EngineControlsMode.MarkupBrush) {
        //         return [markupViewDescr]
        //     } else if (cs.controlsMode === EngineControlsMode.Edit) {
        //     }
        //     return [];
        // });

        // uiBindings.addStatusViews(['engine'], engineStatusViews);

        addEngineKeyBindings(engine, uiBindings);

        addEditUiBindings(engine, configBuilderSettings, uiBindings);

        for (const runtimeSystem of engine.engineScene.engineRuntimeUiBindings()) {
            uiBindings.addRuntimeSystemUi(runtimeSystem.name, runtimeSystem);
        }

        const bim = engine.bim;


        const selectionUi = LazyDerived.new1<PUI_GroupNode, IdBimScene[]>(
            'selection-ui',
            null,
            [bim.instances.selectHighlight.getVersionedFlagged(SceneInstanceFlags.isSelected)],
            ([selectionIds]) => {
                const instances = bim.instances;
                const perTypeInfo = new DefaultMap<string, IdBimScene[]>((ty) => []);
                for (const id of selectionIds) {
                    const state = instances.peekById(id);
                    if (!state) {
                        continue;
                    }
                    let ty = state.type_identifier;
                    if (ty.startsWith('solar-')) {
                        ty = ty.slice(6);
                    }
                    const group = perTypeInfo.getOrCreate(ty);
                    group.push(id);
                }

                const puiBuilder = new PUI_Builder({ rootName: 'selection' });
                for (const [ty, ids] of perTypeInfo) {
                    let focusIndex = 0;
                    puiBuilder.addActionsNode({
                        name: ty,
                        context: ids,
                        actions: [
                            {
                                label: `${ty} [${ids.length}]`,
                                action: () => { bim.instances.setSelected(ids);}
                            },
                            {
                                label: `unselect`,
                                action: () => { bim.instances.toggleSelected(false, ids);}
                            },
                            {
                                label: `focus [F]`,
                                action: () => {
                                    engine.focusCamera(ids);
                                }
                            },
                            {
                                label: `focus next`,
                                action: () => {
                                    const id = ids[(focusIndex++) % ids.length];
                                    engine.focusCamera([id]);
                                }
                            }
                        ]
                    })
                };
                return puiBuilder.finish();
            }
        ).withoutEqCheck();

        const lazyNameInvalidator = bim.instances.getLazyListOfCollection({ relevantUpdateFlags: SceneObjDiff.Name });

        const selectionHeader = LazyDerived.new1<string, IdBimScene[]>(
            'selection-ui-header',
            [lazyNameInvalidator],
            [bim.instances.selectHighlight.getVersionedFlagged(SceneInstanceFlags.isSelected)],
            ([ids]) => {
                function getName(id: IdBimScene): string {
                    const instance = bim.instances.peekById(id);
                    if (!instance) {
                        return `${id}`;
                    }
                    const instanceType = StringUtils.capitalizeFirstLatterInWord(instance.type_identifier);
                    return instance.name ? `${instanceType}: ${instance.name}` : `${instanceType}`;
                }
                return ids.length 
                    ? ids.length === 1 ? getName(ids[0]) : `${ids.length} Objects selected`
                    : '';
            }
        )

        const selectionViewDescription: DropdownViewDescription = {
            header: selectionHeader,
            viewSource: new PUI_Lazy(selectionUi),
            activateOpened: false,
        }

        const views = [selectionViewDescription];

        const selectionViews = LazyDerived.fromArr(
            '-',
            views.map(it => it.header),
            [],
            () => {
                return views.filter(f => f.header.poll());
            }
        );

        uiBindings.addStatusViews(['selection'], selectionViews);

    }


    static defaulBuilderSettings(): PUI_ConfigBasedBuilderParams {

        const bimStdTransformers = BimUiBindings.instancesPropsConfigTransformers();

        return PUI_ConfigBasedBuilderParams.new([
            ...bimStdTransformers,
            [
                ['snapping', 'mode'],
                PUI_ConfigPropertyTransformer.enumSelector(
                    SnappingMode,
                    SnappingMode.Geometry,
                )
            ],
        ]);
    }
}


export function engineEditSelectionCustomPropsUi(engine: KreoEngineImpl): LazyVersioned<PUI_GroupNode> {

    const editObjects = engine.interactiveEditObjects;


    return LazyDerived.new0(
        'edit-selection-custom-props',
        [editObjects.selectionInvalidator, editObjects.repr_invalidator],
        () => {
            const pui = new PUI_GroupNode({name: 'in-edit-props'});
            const commonProps = getEditSceneCommonProps(
                engine.interactiveEditObjects, engine.interactiveEditObjects.getSelected(),
            );
            if (commonProps.props.position) {
                const group = new PUI_GroupNode({name: 'editing'}) // root group is not seen on the ui
                pui.addMaybeChild(group);
                group.addMaybeChild(new PUI_PropertyNodeVec3({
                    name: 'position',
                    unit: 'm',
                    value: commonProps.props.position,
                    onChange: (v) => {
                        const matrix = new Matrix4().setPositionV(v);
                        const patches = new Map<InObjFullId, Matrix4>();
                        for (const id of commonProps.ids) {
                            patches.set(id, matrix);
                        }
                        engine.interactiveEditObjects.patchWorldMatrices(patches, {});
                    }
                }))
            }
            return pui;
        }
    );
}


interface EditMeshesProps {
    position: Vector3;
}

function getEditSceneCommonProps(
    scene: InteractiveSceneObjectsEdit, ids: InObjFullId[]
): {ids: InObjFullId[], props: Partial<EditMeshesProps>} {

    let resultIds: InObjFullId[] = [];
    let prevPos: Vector3 | null = null;

    for (const [id, matrix] of scene.getWorldMatricesOf(ids)) {
		const pos = new Vector3().setFromMatrixPosition(matrix);
        if (prevPos === null || prevPos.equals(pos)) {
            prevPos = pos;
            resultIds.push(id)
        } else {
            return {ids: [], props: {}}
        }
    }
    return {
        ids: resultIds,
        props: {
            position: prevPos ? prevPos : undefined
        }
    }

}

