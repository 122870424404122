import type { VersionedValue } from 'engine-utils-ts';

import type { Color, Scene } from '../3rdParty/three';
import { ClipboxSizeIntersection } from '../controls/SizeLabelsClicksConsumer';
import type { FrustumExt } from '../structs/FrustumExt';
import { RaySection } from '../structs/RaySection';
import type { CameraRotationPointGizmo } from './CameraRotationPointGizmo';
import type { ClipBoxGizmo } from './ClipboxGizmo';
import { ClipboxControlInters } from './ClipboxIntersection';
import type { GizmoIntersection } from './GizmoIntersection';
import type { RectSelectorGizmo } from './RectSelectorGizmo';
import type TeleportGizmo from './TeleportGizmo';
import type { TransformGizmo } from './TransformGizmo';
import { TransformMoveControlInters } from './TransformGizmoStateController';

export class HighlightedGizmo {}


export class GizmosController implements VersionedValue {

	_prevHover: GizmoIntersection | null = null;
	_selfVersion: number = 0;

	gizmos: [TransformGizmo, ClipBoxGizmo, TeleportGizmo, RectSelectorGizmo, CameraRotationPointGizmo];
	frustum_version: number = 0;

	constructor(
		clipBoxGizmo: ClipBoxGizmo,
		transformGizmo: TransformGizmo,
		teleportGizmo: TeleportGizmo,
		areaSelector: RectSelectorGizmo,
		cameraRotationPointGizmo: CameraRotationPointGizmo
	) {
		this.gizmos = [
			transformGizmo,
			clipBoxGizmo,
			teleportGizmo,
			areaSelector,
			cameraRotationPointGizmo
		];
	}

	version() {
		let sum = this._selfVersion;
		for (const g of this.gizmos) {
			sum += g.version();
		}
		// sum += this.bimSceneGizmos.renderMeshes.version();
		// sum += this.editSceneGizmos.renderMeshes.version();
		return sum;
	}

	updateGizmos(frustum: FrustumExt, raycaster: RaySection | null) {
		raycaster = raycaster || new RaySection();
		for (const g of this.gizmos) {
			g.update(frustum, raycaster);
		}
	}

	getActiveGizmos(worldScene: Scene) {
		// this.bimSceneGizmos.renderMeshes.getRenderList(worldScene.children);
		// this.editSceneGizmos.renderMeshes.getRenderList(worldScene.children);
		for (const g of this.gizmos) {
			if (g.visible) {
				g.getRenderList(worldScene.children);
			}
		}
	}

	onHover(int: GizmoIntersection | null) {
		if (this._prevHover == int) {
			return;
		}
		this._selfVersion += 1;
		this._prevHover = int;
        const gizmos = this.gizmos;
        for (const g of gizmos) {
			g.setHover(null);
		}
		if (int instanceof ClipboxControlInters || int instanceof ClipboxSizeIntersection) {
			gizmos[2].setHover(int);
		} else if (int instanceof TransformMoveControlInters) {
			gizmos[0].setHover(int);
		}
    }

	setContrastColorFromBackground(background: Color) {
		const contrastColor = background.r + background.g + background.b > 1.3 ? 0.1 : 0.9;
		for (const g of this.gizmos) {
			g.setContrastColor(contrastColor);
		}
	}

	dispose() {
		for (const g of this.gizmos) {
			g.dispose();
		}
	}
}

