<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        d="M12.3 9.8002H9.30001V10.7002H11C11.718 10.7002 12.3 11.2822 12.3 12.0002V13.5002C12.3 14.2182 11.718 14.8002 11 14.8002H10.8V15.7998H9.20001V14.8002H7.70001V13.2002H10.7V12.3002H9.00001C8.28204 12.3002 7.70001 11.7182 7.70001 11.0002V9.5002C7.70001 8.78223 8.28204 8.2002 9.00001 8.2002H9.20001V7.2002H10.8V8.2002H12.3V9.8002Z"
        fill="currentColor"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.50001 2.2002C3.78204 2.2002 3.20001 2.78223 3.20001 3.5002V16.5002C3.20001 17.2182 3.78204 17.8002 4.50001 17.8002H15.5C16.218 17.8002 16.8 17.2182 16.8 16.5002V7.2073C16.8 6.86252 16.663 6.53186 16.4193 6.28806L12.7121 2.58096C12.4683 2.33716 12.1377 2.2002 11.7929 2.2002H4.50001ZM4.80001 16.2002V3.8002H11.6686L12 7L15.2 7.33157V16.2002H4.80001Z"
        fill="currentColor"
    />
</svg>
