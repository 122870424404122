import { UiPersistentState } from '../UiPersistentState';

interface GlobalNotificationsState {
    changeBrowserTimestamp?: number;
}
const defaultState: GlobalNotificationsState = {
    changeBrowserTimestamp: undefined
}

export function getGlobalNotificationsState() {
    return new UiPersistentState<GlobalNotificationsState>('global-notifications', defaultState);
}