import { EnumUtils, IterUtils, LazyDerived, ObservableObject, ScopedLogger, Success, type LazyVersioned } from 'engine-utils-ts';
import { PUI_GroupNode, PUI_PropertyNodeSelector, PUI_PropertyNodeMultiSelector } from 'ui-bindings';
import { DataVizType } from '../tmy-data-viz-utils/TmyDataVizGenerators';
import type { EnergyViewData } from './EnergyViewDataGenerator';

export const AllowedEnergyViewEquipmentTypes = ['substation', 'inverter'];


export interface EnergyViewVizParams {
    visualization_type: DataVizType;
    data_selector: string[];
}

export function createEnergyVizSettings() {
    const meteoVizParamsObs = new ObservableObject<EnergyViewVizParams>({
        identifier: 'energy-viz-params',
        initialState: {
            visualization_type: DataVizType.None,
            data_selector: [],
        }
    });
    meteoVizParamsObs.observeObject({
        settings: {doNotNotifyCurrentState: true},
        onPatch: ({currentValueRef}) => {
            try {
                localStorage.setItem('energy-view-viz-settings', JSON.stringify(currentValueRef));
            } catch (e) {
            console.error(e);
            }
        }
    });
    try {
        const settings = localStorage.getItem('energy-view-viz-settings');
        if (settings) {
            const asObj = JSON.parse(settings) as EnergyViewVizParams;
            meteoVizParamsObs.applyPatch({
                patch: asObj
            })
        }
    } catch (e) {
        console.error(e);
    }
    return meteoVizParamsObs;
}



export function createEnergyVizSettingsUiGenerator (
    settingsObs: ObservableObject<EnergyViewVizParams>,
    sourceDataAsync: LazyVersioned<EnergyViewData>,
    fieldsNamesPrefix: string = '',
): LazyDerived<PUI_GroupNode> {
    const logger = new ScopedLogger('createPropsEnergyViewVizParams');

    const meteoVizParamsUi = LazyDerived.new2(
        'propsMeteoVizParamsUi',
        [],
        [settingsObs, sourceDataAsync],
        ([settings, sourceData]) => {

            const root = new PUI_GroupNode({
                name: 'meteo-viz-params',
                sortChildren: false,
            });

            const columnsOptions: string[] = [];

            for (const key of sourceData.toChart.keys()) {
                columnsOptions.push(key);
            }

            root.addMaybeChild(new PUI_PropertyNodeSelector({
                name: 'Analysis chart',
                options: EnumUtils.getAllEnumConstsNames(DataVizType),
                value: DataVizType[settings.visualization_type],
                onChange: (newValue) => {
                    const asNumber = EnumUtils.enumNumberValueFromString(
                        DataVizType, newValue
                    );
                    if (asNumber instanceof Success) {
                        settingsObs.applyPatch({
                            patch: {
                                visualization_type: asNumber.value,
                            },
                        });
                    } else {
                        logger.error('invalid column name selected: ' + newValue);
                    }
                },
            }));

            if (settings.visualization_type == DataVizType.None) {
                return root;
            }


            // root.addMaybeChild(new PUI_PropertyNodeSelector({
            //     name: 'equipment type',
            //     options: AllowedEnergyViewEquipmentTypes,
            //     value: AllowedEnergyViewEquipmentTypes.includes(settings.equipment_type) ? settings.equipment_type : AllowedEnergyViewEquipmentTypes[0],
            //     onChange: (newValue) => {
            //         settingsObs.applyPatch({
            //             patch: {
            //                 equipment_type: newValue,
            //             },
            //         });
            //     },
            // }));
            

            const filteredOutDataSelector: string[] = [];
            const filteredDataSelector: string[] = [];

            for (const cname of settings.data_selector) {
                if (columnsOptions.includes(cname)) {
                    filteredDataSelector.push(cname);
                } else if (!(sourceData instanceof Success)) {
                    filteredOutDataSelector.push(cname);
                }
            }
            root.addMaybeChild(new PUI_PropertyNodeMultiSelector({
                name: fieldsNamesPrefix + 'data',
                options: columnsOptions.map((s) => {return {value: s}}),
                value: filteredDataSelector.map(v => {return {value: v}}),
                onChange: (newValue) => {
                    const newStrings = IterUtils.filterMap(newValue, t => {
                        if (columnsOptions.includes(t.value as string)) {
                            return t.value as string;
                        };
                        return undefined;
                    });
                    settingsObs.applyPatch({
                        patch: {
                            data_selector: newStrings.concat(filteredOutDataSelector),
                        },
                    });
                },
            }));

            return root;
        });

    return meteoVizParamsUi;
}
