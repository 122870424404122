import type { Result} from 'engine-utils-ts';
import { convertUnits, Failure, LegacyLogger, LruCache, Success, type NonMethodsOnly } from 'engine-utils-ts';
import { SmallNumericArrayProperty } from '../properties/SmallNumberArrayProperty';
import type { PropsGroupField } from '../properties/Props';
import { CustomPropsRegistry, type CustomPropertySerializer } from '../properties/CustomPropsRegistry';
import type { PileFeaturesFlags} from './PilesFeatures';
import { type PilesFeaturesAndOffsetsProperty, PileWeightClass, PileStrengthModifier, newPileFeatures, PileDamperType, PileMotorType, PileUndulationType, getPileFeaturesWithUndulation } from './PilesFeatures';
import { PropertyViewBasicTypes } from '../properties/BasicPropsView';


export class PilesProfilesProperty extends SmallNumericArrayProperty<PileProfileType> {

    protected constructor(args: Partial<PilesProfilesProperty>) {
        super(args);
    }
    
    static new(piles: PileProfileType[]): PilesProfilesProperty {
        return piles_cross_sections_dedup.get(piles);
    }

    static newDefaultPilesCrossSections(
        pilesDescriptions: PilesFeaturesAndOffsetsProperty
    ): PilesProfilesProperty {
        const piles: PileProfileType[] = [];
        for (let i = 0; i < pilesDescriptions.features.length; ++i) {
            const features = pilesDescriptions.features[i];
            const pileSize = chooseDefaultPileCrossSectionFor(features, 0);
            piles.push(pileSize);
        }
        return PilesProfilesProperty.new(piles);
    }

    equals(other: PropsGroupField): boolean {
        if (!(other instanceof PilesProfilesProperty)) {
            return false;
        }
        if (this.values.length !== other.values.length) {
            return false;
        }
        for (let i = 0; i < this.values.length; ++i) {
            if (!Object.is(this.values[i], other.values[i])) {
                return false;
            }
        }
        return true;
    }
}
class PilesProfilesPropertySerializer implements CustomPropertySerializer<PilesProfilesProperty> {
    currentFormatVersion: number = 0;
    serializeToString(property: PilesProfilesProperty): any {
        const asJson = JSON.stringify({values: property.values});
        return asJson;
    }
    deserializeFromString(fromVersion: number, serialized: string): PilesProfilesProperty {
        const {values} = JSON.parse(serialized) as NonMethodsOnly<PilesProfilesProperty>;
        return PilesProfilesProperty.new(values);
    }
}
CustomPropsRegistry.register({
    class: PilesProfilesProperty as any,
    serializerClass: PilesProfilesPropertySerializer,
    constructFromPartial: (partial: Partial<PilesProfilesProperty>) => PilesProfilesProperty.new(partial.values ?? []),
    basicTypesView: {
        basicTypes: PropertyViewBasicTypes.StringArray,
        toBasicValues: (formatters, property) => {
            return {
                value: property.values.map(pileCrossSectionToString),
            };
        }
    }
});

const piles_cross_sections_dedup = new LruCache<PileProfileType[], PilesProfilesProperty>({
    identifier: 'piles_cross_sections_dedup',
    maxSize: 200,
    eqFunction: (a, b) => {
        if (a === b) {
            return true;
        }
        if (a.length !== b.length) {
            return false;
        }
        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
        return true;        
    },
    hashFn: (a) => a.join(),
    factoryFn: (args) => new (PilesProfilesProperty as any)({values: args}),
});




export enum PileProfileType {
    None,
    W6x7,
    W6x7dot75,
    W6x8dot5,
    W6x9,
    W6x10dot5,
    W6x12,
    W6x15,
    W6x16,
    W6x20,
    W6x25,
    W8x10,
    W8x13,
    W8x15,
    W8x18,
    W8x21,
    W8x24,
    W8x28,
    W8x31,
    W8x35,
    W8x40,
}

export function pileCrossSectionToString(pileSize: PileProfileType): string {
    switch (pileSize) {
        case PileProfileType.None: return `None`;
        case PileProfileType.W6x7: return `W6x7`;
        case PileProfileType.W6x7dot75: return `W6x7.75`;
        case PileProfileType.W6x8dot5: return `W6x8.5`;
        case PileProfileType.W6x9: return `W6x9`;
        case PileProfileType.W6x10dot5: return `W6x10.5`;
        case PileProfileType.W6x12: return `W6x12`;
        case PileProfileType.W6x15: return `W6x15`;
        case PileProfileType.W6x16: return `W6x16`;
        case PileProfileType.W6x20: return `W6x20`;
        case PileProfileType.W6x25: return `W6x25`;
        case PileProfileType.W8x10: return `W8x10`;
        case PileProfileType.W8x13: return `W8x13`;
        case PileProfileType.W8x15: return `W8x15`;
        case PileProfileType.W8x18: return `W8x18`;
        case PileProfileType.W8x21: return `W8x21`;
        case PileProfileType.W8x24: return `W8x24`;
        case PileProfileType.W8x28: return `W8x28`;
        case PileProfileType.W8x31: return `W8x31`;
        case PileProfileType.W8x35: return `W8x35`;
        case PileProfileType.W8x40: return `W8x40`;
        default: {
            return PileProfileType[pileSize] ?? "---";
        }
    }
}

export function pileWeightPerSize(pileSize: PileProfileType): { value: number, unit: string } {
    switch (pileSize) {
        case PileProfileType.None: return { value: 0, unit: "lb/ft" };
        case PileProfileType.W6x7: return { value: 7, unit: "lb/ft" };
        case PileProfileType.W6x7dot75: return { value: 7.75, unit: "lb/ft" };
        case PileProfileType.W6x8dot5: return { value: 8.5, unit: "lb/ft" };
        case PileProfileType.W6x9: return { value: 9, unit: "lb/ft" };
        case PileProfileType.W6x10dot5: return { value: 10.5, unit: "lb/ft" };
        case PileProfileType.W6x12: return { value: 12, unit: "lb/ft" };
        case PileProfileType.W6x15: return { value: 15, unit: "lb/ft" };
        case PileProfileType.W6x16: return { value: 16, unit: "lb/ft" };
        case PileProfileType.W6x20: return { value: 20, unit: "lb/ft" };
        case PileProfileType.W6x25: return { value: 25, unit: "lb/ft" };
        case PileProfileType.W8x10: return { value: 10, unit: "lb/ft" };
        case PileProfileType.W8x13: return { value: 13, unit: "lb/ft" };
        case PileProfileType.W8x15: return { value: 15, unit: "lb/ft" };
        case PileProfileType.W8x18: return { value: 18, unit: "lb/ft" };
        case PileProfileType.W8x21: return { value: 21, unit: "lb/ft" };
        case PileProfileType.W8x24: return { value: 24, unit: "lb/ft" };
        case PileProfileType.W8x28: return { value: 28, unit: "lb/ft" };
        case PileProfileType.W8x31: return { value: 31, unit: "lb/ft" };
        case PileProfileType.W8x35: return { value: 35, unit: "lb/ft" };
        case PileProfileType.W8x40: return { value: 40, unit: "lb/ft" };
        default: {
            LegacyLogger.deferredError(`pileWeightPerSize: unexpected pile size`, pileWeightPerSize);
            return { value: 0, unit: "lb/ft" };
        }
    }
}

export function calculatePileWeight(size: PileProfileType, length: { value: number, unit: string }): Result<{ value: number, unit: string }> {
    const weightValueUnit = pileWeightPerSize(size);
    const lengthMeter = convertUnits(length.value, length.unit, 'm');
    if(lengthMeter instanceof Failure){
        return lengthMeter;
    }
    const weightPerMeter = convertUnits(weightValueUnit.value, weightValueUnit.unit, 'kg/m');
    if(weightPerMeter instanceof Failure){
        return weightPerMeter;
    }
    return new Success({value: lengthMeter.value * weightPerMeter.value, unit: 'kg'});
}

// export function pileHalfSizePerCrossSectionEstimate(s: PileProfileType): number {
//     const minSize = 0.07;
//     const maxSize = 0.12;
//     return KrMath.lerp(minSize, maxSize, s);
// }


const pilesProfilesDefatuls = new Map<PileFeaturesFlags, PileProfileType>([
    [newPileFeatures(PileWeightClass.Heavy, PileStrengthModifier.None, PileMotorType.None, PileDamperType.Damper, PileUndulationType.Undulated), PileProfileType.W6x9],
    [newPileFeatures(PileWeightClass.Heavy, PileStrengthModifier.Edge, PileMotorType.None, PileDamperType.Damper, PileUndulationType.Undulated), PileProfileType.W6x10dot5],
    [newPileFeatures(PileWeightClass.Heavy, PileStrengthModifier.None, PileMotorType.None, PileDamperType.None, PileUndulationType.Undulated), PileProfileType.W6x9],
    [newPileFeatures(PileWeightClass.Heavy, PileStrengthModifier.None, PileMotorType.Motor, PileDamperType.None, PileUndulationType.Undulated), PileProfileType.W6x15],

    [newPileFeatures(PileWeightClass.Standard, PileStrengthModifier.None, PileMotorType.None, PileDamperType.Damper, PileUndulationType.Undulated), PileProfileType.W6x7],
    [newPileFeatures(PileWeightClass.Standard, PileStrengthModifier.None, PileMotorType.None, PileDamperType.None, PileUndulationType.Undulated), PileProfileType.W6x7],
    [newPileFeatures(PileWeightClass.Standard, PileStrengthModifier.None, PileMotorType.Motor, PileDamperType.None, PileUndulationType.Undulated), PileProfileType.W6x10dot5],
    [newPileFeatures(PileWeightClass.Standard, PileStrengthModifier.Edge, PileMotorType.None, PileDamperType.None, PileUndulationType.Undulated), PileProfileType.W6x8dot5],
]);


export function chooseDefaultPileCrossSectionFor(features: PileFeaturesFlags, pileLength: number): PileProfileType {
    features = getPileFeaturesWithUndulation(features, PileUndulationType.Undulated);
    const defaultCrossSection = pilesProfilesDefatuls.get(features);
    return defaultCrossSection ?? PileProfileType.W6x7;
}