import { IterUtils } from "engine-utils-ts";
import { Aabb2, Segment2, Vector2 } from "math-ts";
import type { RegularHeightmapGeometries } from "../../geometries/RegularHeightmapGeometry";
import type { TerrainTileId } from "../TerrainTile";
import type { IdBimGeo } from "../../geometries/BimGeometries";
import type { Bim, TerrainHeightMapRepresentation} from "../../";
import { TerrainInstanceTypeIdent } from "../../";


export enum ContourPolygonsType {
    Elevation,
    Slope,
}


function checkSquare(
    type: ContourPolygonsType,
    sideLength: number,
    z00: number, z10: number, z01: number, z11: number, 
    minValue: number, maxValue: number
) {
    switch (type) {
        case ContourPolygonsType.Elevation:
            if ((z00 > minValue && z00 < maxValue) || 
                (z01 > minValue && z01 < maxValue) || 
                (z10 > minValue && z10 < maxValue) || 
                (z11 > minValue && z11 < maxValue)
            ) {
                return true;
            } else {
                return false;
            }
        case ContourPolygonsType.Slope:
            const angle = 100 * ((z00 + z10) - (z01 + z11)) / (2 * sideLength);
            if (angle > minValue && angle < maxValue) {
                return true;
            } else {
                return false;
            }
    }
}


function fillPolygon(
    mask: Int8Array, 
    ix: number, iy: number, 
    maskWidth: number, 
    yMin: number, yMax: number,
    fromColor: number, toColor: number
) {
    let cellsCount = 0;

    const points: number[] = [(iy << 16) + ix];

    while (points.length > 0) {
        const p = points.pop()!;
        const y = p >> 16;
        const x = (p << 16) >> 16;

        ++cellsCount;
        mask[x + y * maskWidth] = toColor;

        if (x > 0 && mask[x - 1 + y * maskWidth] === fromColor) {
            points.push((y << 16) + x - 1);
        }
        if (x < maskWidth - 1 && mask[x + 1 + y * maskWidth] === fromColor) {
            points.push((y << 16) + x + 1);
        }
        if (y > yMin && mask[x + (y - 1) * maskWidth] === fromColor) {
            points.push(((y - 1) << 16) + x);
        }
        if (y < yMax - 1 && mask[x + (y + 1) * maskWidth] === fromColor) {
            points.push(((y + 1) << 16) + x);
        }
    }

    return cellsCount;
}


function calculateYMinMax(
    mask: Int8Array, 
    ix: number, iy: number, 
    maskWidth: number, maskHeight: number,
    yMinMax: [number, number],
): number {
    let cellsCount = 0;
    const points: number[] = [(iy << 16) + ix];

    while (points.length > 0) {
        const p = points.pop()!;
        const y = p >> 16;
        const x = (p << 16) >> 16;

        if (mask[x + y * maskWidth] !== -1) {
            continue;
        }
    
        ++cellsCount;
        mask[x + y * maskWidth] = 0;

        if (y < yMinMax[0]) {
            yMinMax[0] = y;
        } else if (y + 1 > yMinMax[1]) {
            yMinMax[1] = y + 1;
        }
    
        if (x > 0) {
            points.push((y << 16) + x - 1);
        }
        if (x < maskWidth - 1) {
            points.push((y << 16) + x + 1);
        }
        if (y > 0) {
            points.push(((y - 1) << 16) + x);
        }
        if (y < maskHeight - 1) {
            points.push(((y + 1) << 16) + x);
        }
    }

    return cellsCount;
}


function calculateCuts(
    mask: Int8Array, 
    minCellsCount: number,
    maskWidth: number, 
    maskHeight: number,
): number[] {
    const yyMinMax: [number, number][] = [];
    for (let iy = 1; iy < maskHeight - 1; ++iy) {        
        for (let ix = 1; ix < maskWidth - 1; ++ix) {
            if (mask[ix + iy * maskWidth] === -1) {
                const yMinMax: [number, number] = [iy, iy + 1];
                const cellsCount = calculateYMinMax(mask, ix, iy, maskWidth, maskHeight, yMinMax);
                if (cellsCount >= minCellsCount) {
                    yyMinMax.push(yMinMax);
                } else {
                    fillPolygon(mask, ix, iy, maskWidth, 0, maskHeight, 0, 2);
                }
            }
        }
    }

    const tileCuts: number[] = [];

    yyMinMax.sort((a, b) => a[1] - b[1]);

    while (yyMinMax.length > 0) {
        const cut = yyMinMax[0][1];
        tileCuts.push(cut);
        for (let i = 0; i < yyMinMax.length; ++i) {
            if (yyMinMax[i][0] <= cut && yyMinMax[i][1] >= cut) {
                yyMinMax.splice(i, 1);
            }
        }
    }

    return tileCuts;
}


function deleteSmallAreas(
    mask: Int8Array, 
    minCellsCount: number,
    maskWidth: number, 
    maskHeight: number,
) {
    for (let iy = 0; iy < maskHeight; ++iy) {        
        for (let ix = 0; ix < maskWidth; ++ix) {
            if (mask[ix + iy * maskWidth] === 2) {
                const cellsCount = fillPolygon(mask, ix, iy, maskWidth, 0, maskHeight, 2, 1);
                if (cellsCount < minCellsCount) {
                    fillPolygon(mask, ix, iy, maskWidth, 0, maskHeight, 1, 0);
                }
            }
        }
    }
}


function extendPolygon(
    point: Vector2, prevPoint: Vector2, 
    edge: Segment2, 
    mask: Int8Array, 
    yMin: number, yMax: number, 
    maskWidth: number, 
    polygon: Vector2[],
) {
    if (edge.b.x > 0 && edge.a.x !== edge.b.x - 1 
        && ((edge.b.y === yMin && mask[edge.b.x - 1 + edge.b.y * maskWidth] === 1)
            || (edge.b.y === yMax && mask[edge.b.x - 1 + (edge.b.y - 1) * maskWidth] === 1)
            || (edge.b.y !== yMin && edge.b.y !== yMax 
                && mask[edge.b.x - 1 + edge.b.y * maskWidth] !== mask[edge.b.x - 1 + (edge.b.y - 1) * maskWidth]))
    ) {
        edge.a.copy(edge.b);
        edge.b.x -= 1;
    } else if (edge.b.x < maskWidth && edge.a.x !== edge.b.x + 1 
        && ((edge.b.y === yMin && mask[edge.b.x + edge.b.y * maskWidth] === 1)
            || (edge.b.y === yMax && mask[edge.b.x + (edge.b.y - 1) * maskWidth] === 1)
            || (edge.b.y !== yMin && edge.b.y !== yMax 
                && mask[edge.b.x + edge.b.y * maskWidth] !== mask[edge.b.x + (edge.b.y - 1) * maskWidth]))
    ) {
        edge.a.copy(edge.b);
        edge.b.x += 1;
    } else if (edge.b.y > yMin && edge.a.y !== edge.b.y - 1 
        && ((edge.b.x === 0 && mask[edge.b.x + (edge.b.y - 1) * maskWidth] === 1)
            || (edge.b.x === maskWidth && mask[edge.b.x - 1 + (edge.b.y - 1) * maskWidth] === 1)
            || (edge.b.x !== 0 && edge.b.x !== maskWidth 
                && mask[edge.b.x - 1 + (edge.b.y - 1) * maskWidth] !== mask[edge.b.x + (edge.b.y - 1) * maskWidth]))
    ) {
        edge.a.copy(edge.b);
        edge.b.y -= 1;
    } else {
        edge.a.copy(edge.b);
        edge.b.y += 1;
    }

    const nextPoint = edge.getCenter();
    if (nextPoint.distanceToLine(point, prevPoint) > 0.1) {
        polygon.push(point.clone());
    }

    prevPoint.copy(point);
    point.copy(nextPoint);
}


function improvePolygon(polygon: Vector2[]) {
    let i, prevPoint = polygon.at(-1)!;
    const prevV = new Vector2(), v = new Vector2();
    for (i = 0; i < polygon.length - 1; ++i) {
        prevV.copy(prevPoint).sub(polygon[i]);
        v.copy(polygon[i + 1]).sub(polygon[i]);
        if (prevV.lengthSq() < 0.4) {
            if (Math.abs(prevV.cross(v)) < 1e-10) {
                polygon.splice(i, 1);
                i--;
                continue;
            }
        } else if (v.lengthSq() < 0.6 && v.lengthSq() > 0.4 && Math.abs(prevV.dot(v)) > 1e-10) {
            const decimalPart = polygon[i].x % 1;
            if (decimalPart > 0.4 && decimalPart < 0.6) {
                polygon[i].x = polygon[i + 1].x;
            } else {
                polygon[i].y = polygon[i + 1].y;
            }
        } else if (prevV.lengthSq() < 0.6 && Math.abs(prevV.dot(v)) > 1e-10) {
            const decimalPart = polygon[i].x % 1;
            if (decimalPart > 0.4 && decimalPart < 0.6) {
                polygon[i].x = prevPoint.x;
            } else {
                polygon[i].y = prevPoint.y;
            }
        }
        prevPoint = polygon[i];
    }
    prevV.copy(prevPoint).sub(polygon[i]);
    v.copy(polygon[0]).sub(polygon[i]);
    if (prevV.lengthSq() < 0.3) {
        if (prevV.cross(v) < 1e-10) {
            polygon.splice(i, 1);
        }
    } else if (v.lengthSq() < 0.6 && Math.abs(prevV.dot(v)) > 1e-10) {
        const decimalPart = polygon[i].x % 1;
        if (decimalPart > 0.4 && decimalPart < 0.6) {
            polygon[i].x = polygon[0].x;
        } else {
            polygon[i].y = polygon[0].y;
        }
    }

    prevV.copy(polygon.at(-1)!).sub(polygon[0]);
    v.copy(polygon[1]).sub(polygon[0]);
    if (prevV.lengthSq() < 0.3 && prevV.cross(v) < 1e-10) {
        polygon.splice(0, 1);
    }
}


function calculatePolygonsFromSlice(
    tileMask: Int8Array, 
    yMin: number, yMax: number, 
    xSegmentsCount: number,
    ySegmentsCount: number
): Vector2[][] {
    const polygons: Vector2[][] = [];

    const edge = new Segment2(new Vector2(-1, -1), new Vector2(-1, -1));
    const prevPoint = new Vector2();
    for (let iy = yMin; iy < yMax; ++iy) {        
        for (let ix = 0; ix < xSegmentsCount; ++ix) {
            if (ix > 0 && tileMask[ix + iy * xSegmentsCount] !== tileMask[ix - 1 + iy * xSegmentsCount]) {
                edge.a.set(ix, iy);
                edge.b.set(ix, iy + 1);
            } else if (iy > 0 && tileMask[ix + iy * xSegmentsCount] !== tileMask[ix + (iy - 1) * xSegmentsCount]) {
                edge.a.set(ix, iy);
                edge.b.set(ix + 1, iy);
            }

            if (edge.a.x === -1) {
                continue;
            }

            if (edge.b.x !== edge.a.x - 1 
                && tileMask[edge.a.x - 1 + edge.a.y * xSegmentsCount] !== tileMask[edge.a.x - 1 + (edge.a.y - 1) * xSegmentsCount]
            ) {
                prevPoint.set(edge.a.x - 0.5, edge.a.y);
            } else if (edge.b.x !== edge.a.x + 1 
                && tileMask[edge.a.x + edge.a.y * xSegmentsCount] !== tileMask[edge.a.x + (edge.a.y - 1) * xSegmentsCount]
            ) {
                prevPoint.set(edge.a.x + 0.5, edge.a.y);
            } else if (edge.b.y !== edge.a.y - 1 
                && tileMask[edge.a.x - 1 + (edge.a.y - 1) * xSegmentsCount] !== tileMask[edge.a.x + (edge.a.y - 1) * xSegmentsCount]
            ) {
                prevPoint.set(edge.a.x, edge.a.y - 0.5);
            } else {
                prevPoint.set(edge.a.x, edge.a.y + 0.5);
            }

            const polygon: Vector2[] = [];
            let borders = 0;
            const point = edge.getCenter();
            while (polygon.length === 0 || !point.equals(polygon[0])) {
                if (point.x === 0) {
                    borders |= 1;
                } else if (point.x === xSegmentsCount) {
                    borders |= 1 << 1;
                }
                if (point.y === 0) {
                    borders |= 1 << 2;
                } else if (point.y === ySegmentsCount) {
                    borders |= 1 << 3;
                }
                extendPolygon(point, prevPoint, edge, tileMask, yMin, yMax, xSegmentsCount, polygon);
            }
            
            improvePolygon(polygon);
            polygons.push(polygon);

            if (tileMask[ix + iy * xSegmentsCount] === 1) {
                fillPolygon(tileMask, ix, iy, xSegmentsCount, yMin, yMax, 1, 0);
            } else if (tileMask[ix + (iy - 1) * xSegmentsCount] === 1) {
                fillPolygon(tileMask, ix, iy - 1, xSegmentsCount, yMin, yMax, 1, 0);
            } else {
                fillPolygon(tileMask, ix - 1, iy, xSegmentsCount, yMin, yMax, 1, 0);
            }

            edge.a.x = -1;
        }
    }

    return polygons;
}


export function calculatePolygonsFromMask(
    maskWidth: number,
    maskHeight: number,
    minArea: number,
    mask: Int8Array,
    segmentSizeInMeters: number,
): Vector2[][] {
    let iy, ix, changed = 1;
    while (changed > 0) {
        changed = 0;
        for (iy = 1; iy < maskHeight; ++iy) {
            for (ix = 1; ix < maskWidth; ++ix) {
                if (mask[ix + iy * maskWidth] === 2) {
                    if (mask[ix - 1 + (iy - 1) * maskWidth] === 2
                        && mask[ix + (iy - 1) * maskWidth] === -1
                        && mask[ix - 1 + iy * maskWidth] === -1
                    ) {
                        mask[ix + iy * maskWidth] = -1;
                        mask[ix - 1 + (iy - 1) * maskWidth] = -1;
                        ++ix;
                        ++changed;
                    }
                } else {
                    if (mask[ix - 1 + (iy - 1) * maskWidth] === -1
                        && mask[ix + (iy - 1) * maskWidth] === 2
                        && mask[ix - 1 + iy * maskWidth] === 2
                    ) {
                        mask[ix + (iy - 1) * maskWidth] = -1;
                        mask[ix - 1 + (iy - 1) * maskWidth] = -1;
                        ++ix;
                        ++changed;
                    }
                }
            }
        }
    }

    iy = 0;
    for (ix = 0; ix < maskWidth; ++ix) {
        if (mask[ix + iy * maskWidth] === -1) {
            fillPolygon(mask, ix, iy, maskWidth, 0, maskHeight, -1, 0);
        }
    }
    iy = maskHeight - 1;
    for (ix = 0; ix < maskWidth; ++ix) {
        if (mask[ix + iy * maskWidth] === -1) {
            fillPolygon(mask, ix, iy, maskWidth, 0, maskHeight, -1, 0);
        }
    }
    ix = 0;
    for (iy = 0; iy < maskHeight; ++iy) {
        if (mask[ix + iy * maskWidth] === -1) {
            fillPolygon(mask, ix, iy, maskWidth, 0, maskHeight, -1, 0);
        }
    }
    ix = maskWidth - 1;
    for (iy = 0; iy < maskHeight; ++iy) {
        if (mask[ix + iy * maskWidth] === -1) {
            fillPolygon(mask, ix, iy, maskWidth, 0, maskHeight, -1, 0);
        }
    }

    const minCellsCount = Math.ceil(minArea / (segmentSizeInMeters * segmentSizeInMeters));
    const cuts = calculateCuts(mask, minCellsCount, maskWidth, maskHeight);
    deleteSmallAreas(mask, minCellsCount, maskWidth, maskHeight);

    const polygons: Vector2[][] = [];
    if (cuts.length === 0) {
        IterUtils.extendArray(polygons, calculatePolygonsFromSlice(
            mask, 0, maskHeight, maskWidth, maskHeight
        ));
    } else {
        IterUtils.extendArray(polygons, calculatePolygonsFromSlice(
            mask, 0, cuts[0], maskWidth, maskHeight
        ));
        for (let i = 0; i < cuts.length - 1; ++i) {
            IterUtils.extendArray(polygons, calculatePolygonsFromSlice(
                mask, cuts[i], cuts[i + 1], maskWidth, maskHeight
            ));
        }
        IterUtils.extendArray(polygons, calculatePolygonsFromSlice(
            mask, cuts[cuts.length - 1], maskHeight, maskWidth, maskHeight
        ));
    }

    return polygons;
}


export function calculatePolygons(
    type: ContourPolygonsType,
    minValue: number,
    maxValue: number,
    minAreaHa: number,
    geos: RegularHeightmapGeometries,
    tiles: Map<TerrainTileId, IdBimGeo>,
): Vector2[][] {
    const minAreaM2 = 10000 * minAreaHa;

    const tilesAabb2 = Aabb2.empty().setFromPoints(IterUtils.mapIter(tiles, t => t[0]));
    const tileGeo = geos.peekById(tiles.values().next().value as IdBimGeo)!;
    const segmentsCount = new Vector2(
        (1 + tilesAabb2.width()) * tileGeo.xSegmentsCount,
        (1 + tilesAabb2.height()) * tileGeo.ySegmentsCount
    );
    const segmentSizeInMeters = tileGeo.segmentSizeInMeters;

    const terrainMask = new Int8Array(segmentsCount.x * segmentsCount.y);
    terrainMask.fill(-1);
    
    const tileOffset = new Vector2();
    for (const [tileId, geoId] of tiles) {
        const tileGeo = geos.peekById(geoId);
        if (!tileGeo) {
            continue;
        }

        tileOffset.set(tileId.x, tileId.y).sub(tilesAabb2.min);
        tileOffset.x *= tileGeo.xSegmentsCount;
        tileOffset.y *= tileGeo.ySegmentsCount;
        
        let z00: number, z10: number, z01: number, z11: number;
        let iy, ix;
        for (iy = 0; iy < tileGeo.ySegmentsCount; ++iy) {
            z10 = tileGeo.readElevationAtInds(0, iy);
            z11 = tileGeo.readElevationAtInds(0, iy + 1);
            
            for (ix = 0; ix < tileGeo.xSegmentsCount; ++ix) {
                z00 = z10;
                z01 = z11;
                z10 = tileGeo.readElevationAtInds(ix + 1, iy);
                z11 = tileGeo.readElevationAtInds(ix + 1, iy + 1);

                if (Number.isNaN(z00 + z01 + z10 + z11)) {
                    continue;
                }

                const shouldMark = checkSquare(type, segmentSizeInMeters, z00, z10, z01, z11, minValue, maxValue);
                if (shouldMark) {
                    const tileIndex = (tileOffset.y + iy) * segmentsCount.x + (tileOffset.x + ix);
                    terrainMask[tileIndex] = 2;
                }
            }
        }
    }

    const polygons = calculatePolygonsFromMask(
        segmentsCount.x, segmentsCount.y, 
        minAreaM2, terrainMask, segmentSizeInMeters
    );

    tileOffset.copy(tilesAabb2.min).multiplyScalar(segmentSizeInMeters);
    tileOffset.x *= tileGeo.xSegmentsCount;
    tileOffset.y *= tileGeo.ySegmentsCount;
    for (const polygon of polygons) {
        for (const point of polygon) {
            point.multiplyScalar(segmentSizeInMeters).add(tileOffset);
        }
    }

    return polygons;
}


export function testPolygons(bim: Bim, type: ContourPolygonsType, minValue: number, maxValue: number, minArea: number) {
    const terrainRepr = bim.instances.peekByTypeIdent(TerrainInstanceTypeIdent)[0][1].representation as TerrainHeightMapRepresentation;

    const tiles = new Map<TerrainTileId, IdBimGeo>(IterUtils.mapIter(terrainRepr.tiles, t => [t[0], t[1].initialGeo]));

    return calculatePolygons(type, minValue, maxValue, minArea, bim.regularHeightmapGeometries, tiles);
}

(globalThis as any).testPolygons = testPolygons;