<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M4.33331 10.6666H13.6666L13.6666 4.99994H4.33331L4.33331 10.6666ZM4.33331 11.6666C4.06665 11.6666 3.83331 11.5666 3.63331 11.3666C3.43331 11.1666 3.33331 10.9333 3.33331 10.6666V3.33325C3.33331 3.06659 3.43331 2.83325 3.63331 2.63325C3.83331 2.43325 4.06665 2.33325 4.33331 2.33325H13.6666C13.9333 2.33325 14.1666 2.43325 14.3666 2.63325C14.5666 2.83325 14.6666 3.06659 14.6666 3.33325V10.6666C14.6666 10.9333 14.5666 11.1666 14.3666 11.3666C14.1666 11.5666 13.9333 11.6666 13.6666 11.6666H4.33331ZM2.33331 13.6666C2.06665 13.6666 1.83331 13.5666 1.63331 13.3666C1.43331 13.1666 1.33331 12.9333 1.33331 12.6666V4.33325H2.33331V12.6666H12.6666V13.6666H2.33331Z"
        fill="currentColor"
    />
</svg>
