<script lang="ts">
    import PUI_Root from '../pui/PUI_Root.svelte';
    import type { EnergyViewContext } from './EnergyViewContext';
    import { PUI_Lazy, type UiBindings } from 'ui-bindings';
    import type { FilesImportsHandler } from '../files-imports-exports/FilesImportsHandler';
    import { getContext } from 'svelte';
    import { lazySiteMeteoFileName, meteoFileDetailsUi, parseMeteoFile } from './MeteoFileLocationUi';
    import { createMeteoVizSettings, meteoVizSettingsUiGenerator } from './SiteMeteoDataVizSettings';
    import { VersionedStore } from '../VersionedStore';
    import { requestFileFromUser } from '../files-imports-exports/RequestUserFileInput';
    import { Echart, TmyDataTableUi } from '../tmy-data-viz-utils/TmyDataVizGenerators';
    import TableUi from '../grid-table-ui/TableUi.svelte';
    import AnyEchart from '../charts/AnyEchart.svelte';
    import { meteoDataVizGenerator } from './SiteMeteoDataVizGenerator';
    import { TMY_Props, EnergyCalculationsEnabled } from 'bim-ts';
    import { Failure, IterUtils, LazyBasic } from 'engine-utils-ts';
    import EnergySetupChart from './chart/EnergySetupChart.svelte';
    import CollapsableGroup from '../pui/CollapsableGroup.svelte';
    import { Button, ButtonComponent, ButtonType, IconButton } from 'src/libui/button';
    
    export let context: EnergyViewContext;

    const bim = context.bim;
    const uiBindings = getContext('uiBindings') as UiBindings;
    const filesImportsHandler = getContext('filesImportsHandler') as FilesImportsHandler;

    const tmyConfigLazyProps = bim.configs.getLazyOptionalSingletonProps({type_identifier: 'typical-meteo-year', propsClass: TMY_Props});
    const runtimeTmyProps = bim.runtimeGlobals.getAsLazyVersionedByIdent(TMY_Props.name, TMY_Props);

    const parseFileResult = parseMeteoFile(runtimeTmyProps);
    const meteoDetailsUi = meteoFileDetailsUi(bim, parseFileResult);

    const vizSettings = createMeteoVizSettings();

    $: meteoProps = new VersionedStore(tmyConfigLazyProps);
    $: lazyMeteoFileName = new VersionedStore(lazySiteMeteoFileName(bim));

    const HeightPerChart = 300;

    $: meteoVizSettingsUi = meteoVizSettingsUiGenerator(vizSettings, runtimeTmyProps);
    $: visulalizations = new VersionedStore(meteoDataVizGenerator(
        vizSettings,
        runtimeTmyProps,
        bim.unitsMapper,
        HeightPerChart
    ));

    const meteoFileParsingResult = new VersionedStore(parseFileResult);
    $: errors = $meteoFileParsingResult.filter(v => !!v.error);
    $: warnings = $meteoFileParsingResult.filter(v => !!v.warning);

    let status: string[] = [];
    $: {
        status = [
            errors.length ? `<span class="text-danger">${errors.length} critical errors</span>` : '',
            warnings.length ? `${warnings.length} warnings` : '',
            errors.length || warnings.length || $meteoProps instanceof Failure ? '' : 'All data imported'
        ].filter(Boolean);
    }

    function importFile() {
        const tmyImporter = uiBindings.filesImporters.getByPath(['typical-meteo-year-importer']);
        if (!tmyImporter) {
            console.error('No importer found for typical-meteo-year');
            return;
        }
        requestFileFromUser(tmyImporter.fileExtensions, async (filesPromised) => {
            const files = [];
            for (const f of filesPromised) {
                const ab = await f.filePromise;
                files.push({
                    filename: f.filename,
                    fileArrayBuffer: ab,
                    size: f.size,
                    extension: '',
                });
            }
            filesImportsHandler.importFileWith(tmyImporter, files[0]);
        });
    }

    function deleteFile() {
        const tuples = bim.configs.peekByType('typical-meteo-year');
        if (tuples.length > 0) {
            bim.configs.delete([tuples[0][0]]);
        }
    }

</script>

<div class="root">
    <div class="import-meteo-container">


        <div class="ui-config-property property-row">
            <div class="property-name mdc-typography--overline">Meteo file</div>
            <div class="property-value">
                <div class="file-info">
                    {#if $meteoProps instanceof Failure}
                        <span class="mdc-typography--body1 text-main-light">{$lazyMeteoFileName}</span>
                    {:else}
                        <span class="mdc-typography--body1">{$meteoProps.value.fileName.value}</span>
                        <ButtonComponent desc={new IconButton('Delete', deleteFile)}/>
                    {/if}
                </div>
                {#if errors.length}
                    {@const required = IterUtils.filterMap(errors, (info) => info.context.required ? info.context.propName : undefined)}
                    {@const optional = IterUtils.filterMap(errors, (info) => !info.context.required ? info.context.propName : undefined)}
                    <div class="text-danger mdc-typography--tooltip file-errors">
                        {#if required.length}
                            <div>Uploaded meteo file has required data missing or corrupted: {required}.</div>
                        {/if}
                        {#if optional.length}
                            <div>Optional data is missing or corrupted: {optional}.</div>
                        {/if}
                        <div>Please, upload another file, or delete it to get back to Default PVFarm meteodata.</div>
                    </div>
                {/if}
                {#if $meteoProps instanceof Failure }
                    <ButtonComponent desc={new Button('Import Typical Meteo Year Data', ButtonType.Text, importFile)}/>
                {/if}
            </div>
        </div>
    </div>

    <CollapsableGroup name="Meteo data details">
        <svelte:fragment slot="info">
            <div>{@html status.join(', ')}</div>
        </svelte:fragment>
        <svelte:fragment slot="content">
            <PUI_Root
                configIdentifier={'geo-location'}
                puiSource={new PUI_Lazy(meteoDetailsUi)}
            ></PUI_Root>

            <PUI_Root
                configIdentifier={'meteo-viz-params'}
                puiSource={new PUI_Lazy(meteoVizSettingsUi)}
            ></PUI_Root>

            {#each $visulalizations as viz (viz) }
                {#if viz instanceof TmyDataTableUi}
                    <div style="height:{HeightPerChart}px;">
                        <TableUi lazyTableData = {new LazyBasic('', viz.rows)} />
                    </div>
                {:else if viz instanceof Echart}
                    <div class="chart" style="height: {viz.heightPx}px;">
                        <AnyEchart echartOptions = {viz.options} ></AnyEchart>
                    </div>
                {/if}
            {/each}
        </svelte:fragment>
    </CollapsableGroup>

    <EnergySetupChart context={context} />

</div>

<style lang="scss">
    .root {
        width: 100%;
        height: 100%;

        :global(.group-content .property-name-label) {
        --offset: 32px;
    }
    }
    .chart {
        width: 99%;
        margin: 0 auto;
    }
    .import-meteo-container {
        padding: 16px 16px 8px;
    }
    .file-info {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0 8px;
    }
    .file-errors {
        padding: 4px 8px 0;
    }
</style>