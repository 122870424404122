<script lang="ts">
    import { getContext } from "svelte";

    import type {
        Bim,
        ConfigPatch,
        TerrainPalette,
        TerrainAnalysisConfig,
        RegularHeightmapGeometry
    } from "bim-ts";
    import {
        generateTerrainConfigProps,
        autoFillCutFillColors,
        TerrainAnalysisTypeIdent,
        TerrainPaletteMaxRowCount,
        TerrainGeoVersionSelector,
        NumberProperty,
        ColorProperty,
        TerrainInstanceTypeIdent,
        TerrainHeightMapRepresentation,
        TerrainDisplaySlopeSelector,
    } from "bim-ts";
    import {
        createLazyUiRowData,
        getConfig,
        getTerrainElevationsRange,
        getTerrainSlopesRange,
        isInt,
        pickConfigPaletteFromDisplayType,
    } from "./TerrainAnalysisUiData";
    import Select from "../reusable/Select.svelte";
    import { TextField } from "../libui/fields";
    import {
        Success,
        ObjectUtils,
        RGBA,
        Immer,
        LazyBasic,
    } from "engine-utils-ts";
    import ButtonComponent from "../libui/button/Button.svelte";
    import type { KreoEngine} from "engine-ts";
    import {
        TerrainDisplayMode,
    } from "engine-ts";
    import TableUi from "../grid-table-ui/TableUi.svelte";
    import { VersionedStore } from "../VersionedStore";
    import type { TerrainPaletteSlice } from 'bim-ts';
    import Tabs from '../libui/tabs/Tabs.svelte';
    import type { Tab } from '../libui/tabs';
    import EngineUiPanel from '../ui-panels/EngineUiPanel.svelte';
    import { Quaternion, Vec3One, Vector3 } from "math-ts";

    const bim: Bim = getContext("bim");
    const engine: KreoEngine = getContext("engine");

    $: terrainPaletteSelector = engine.terrainDisplaySettings;

    $: terrainVersion = $terrainPaletteSelector.terrainVersion;
    $: terrainDisplayMode = $terrainPaletteSelector.mode;
    $: terrainSlopeSelector = $terrainPaletteSelector.slopeSelector;

    $: showPaletteControls = terrainDisplayMode > 0;

    const isLoadingLazy = new LazyBasic("isLoaded", false);
    const isLoading = new VersionedStore(isLoadingLazy);

    const [tableData, lazyMetrics] = createLazyUiRowData(
        bim,
        engine.terrainDisplaySettings,
        isLoadingLazy
    );

    const TerrainGeoVersionSelectorLabels: Record<TerrainGeoVersionSelector, string> = {
        [TerrainGeoVersionSelector.Initial]: "Initial, before cut-fill",
        [TerrainGeoVersionSelector.Latest]: "After cut-fill"
    }
    const terrainVersionStrings = Object.values(TerrainGeoVersionSelectorLabels);
    const displayModes: Tab[] = [{
        name: "Transparent",
        key: TerrainDisplayMode.BasicTransparent
    }, {
        name: "Cut-fill",
        key: TerrainDisplayMode.CutFill
    }, {
        name: "Elevation",
        key: TerrainDisplayMode.Elevation
    }];
    const slopeModes: Tab[] = [{
        name: "NS Slope",
        key: TerrainDisplaySlopeSelector.NS
    }, {
        name: "EW Slope",
        key: TerrainDisplaySlopeSelector.EW
    }];
    const tabsList = displayModes.concat(slopeModes);
    $: activeTabIndex = terrainDisplayMode !== TerrainDisplayMode.Slope ?
        displayModes.findIndex(mode => mode.key === terrainDisplayMode) :
        slopeModes.findIndex(mode => mode.key === terrainSlopeSelector) + displayModes.length;

    const DefaultColorMin = RGBA.newRGB(0, 0.8, 0);
    const DefaultColorMax = RGBA.newRGB(0.9, 0.4, 0);

    const rangeInputLazy = new LazyBasic("rangeInput", "9");

    const lazyConfig = bim.configs.getLazySingletonOf({type_identifier: TerrainAnalysisTypeIdent});
    const configs = new VersionedStore(lazyConfig);

    $:if($configs || showPaletteControls){
        updateRange();
    }

    const rangeInput = new VersionedStore<string>(rangeInputLazy);
    $: isValidRangeInput = isInt($rangeInput) && parseInt($rangeInput) > 0 && parseInt($rangeInput) <= TerrainPaletteMaxRowCount;



    $: if (showPaletteControls && $rangeInput) {
        patchRange();
    }
    
    function getDefaultOffset() {
        return bim.unitsMapper.isImperial() ? 0.003048 : 0.01;
    }

    function updateRange(){
        const terrainProps = getConfig(bim);
        if (terrainDisplayMode === TerrainDisplayMode.BasicTransparent) {
            return;
        } else if (terrainDisplayMode === TerrainDisplayMode.Elevation) {
            setRange(terrainProps.elevation_palette.slices.length);
        } else if (terrainDisplayMode === TerrainDisplayMode.Slope) {
            setRange(terrainProps.slope_palette.slices.length);
        } else if (terrainDisplayMode === TerrainDisplayMode.CutFill) {
            setRange(terrainProps.cut_fill_palette.slices.length);
        } else {
            console.error(
                "unrecognized terrain display mode",
                terrainDisplayMode
            );
        }
    }

    function setRange(sliceCount:number) {
        const strCount = sliceCount.toString();
        if(strCount !== rangeInputLazy.poll()){
            rangeInputLazy.replaceWith(strCount);
        }
    }

    async function patchRange() {
        const terrainProps = getConfig(bim);
        if (!terrainProps) {
            return;
        }
        const [palette] = pickConfigPaletteFromDisplayType(
            terrainProps,
            terrainDisplayMode
        );

        if (
            isValidRangeInput &&
            palette.slices.length !== parseInt(rangeInputLazy.poll())
        ) {
            const newRange = parseInt(rangeInputLazy.poll());
            let updatedTerrainProps = Immer.produce(terrainProps, terrainPropsDraft => {
                const [paletteDraft] = pickConfigPaletteFromDisplayType(
                    terrainPropsDraft,
                    terrainDisplayMode
                );
                if (paletteDraft.slices.length > newRange) {
                    paletteDraft.slices = paletteDraft.slices.slice(0, newRange);
                } else if (paletteDraft.slices.length < newRange) {
                    for (let i = paletteDraft.slices.length; i < newRange; i++) {
                        const lastRow: TerrainPaletteSlice = paletteDraft.slices[i - 1]
                            ?? {
                                color: ColorProperty.new({value: DefaultColorMin}),
                                min: NumberProperty.new({value: 0, unit: "m"}),
                                max: NumberProperty.new({value: 10, unit: "m"}),
                            };
                            paletteDraft.slices[i] = {
                            min: lastRow.max.withDifferentValue(+lastRow.max.value),
                            max: lastRow.max.withDifferentValue(+(lastRow.max.value * 1.5).toFixed(2)),
                            color: lastRow.color.withDifferentValue(
                                RGBA.lerpRGBAHex(
                                    DefaultColorMin,
                                    DefaultColorMax,
                                    i / (newRange - 1) || 0
                                )
                            ),
                        };
                    }
                }
            });

            updatedTerrainProps = await updateRanges(updatedTerrainProps);
            updatedTerrainProps = updateColors(updatedTerrainProps);
  
            patchConfig({
                properties: updatedTerrainProps,
            });
        }
    }

    function updateColors(terrainProps: TerrainAnalysisConfig){
        const updatedTerrainProps = Immer.produce(terrainProps, terrainPropsDraft => {
            let paletteToUpdateColors: TerrainPalette;
            let colorsSource: ColorProperty[];

            if (terrainDisplayMode === TerrainDisplayMode.Elevation) {
                paletteToUpdateColors = terrainPropsDraft.elevation_palette;
                const sceneRange = getTerrainElevationsRange(bim);
                const samplePalette = generateTerrainConfigProps({
                    elevationRangeMeters: sceneRange,
                    paletteSize: terrainPropsDraft.elevation_palette.slices.length,
                    slopeRangePercentagesExtents: 20,
                    cutfillRangeMeters: [-10, 10],
                    defaultOffset: getDefaultOffset(),
                });
                colorsSource = samplePalette.elevation_palette.slices.map(s => s.color);
            } else if (terrainDisplayMode === TerrainDisplayMode.Slope) {
                paletteToUpdateColors = terrainPropsDraft.slope_palette;
                const samplePalette = generateTerrainConfigProps({
                    elevationRangeMeters: [0, 1],
                    paletteSize: terrainPropsDraft.slope_palette.slices.length,
                    slopeRangePercentagesExtents: 20,
                    cutfillRangeMeters: [-10, 10],
                    defaultOffset: getDefaultOffset(),
                });
                colorsSource = samplePalette.slope_palette.slices.map(s => s.color);
            } else if (terrainDisplayMode === TerrainDisplayMode.CutFill) {
                paletteToUpdateColors = terrainPropsDraft.cut_fill_palette;
                const colors = autoFillCutFillColors(terrainPropsDraft.cut_fill_palette.slices.map(s => ([s.min.value, s.max.value])));

                colorsSource = colors;
            } else {
                console.error(
                    "unrecognized terrain display mode",
                    terrainDisplayMode
                );
                return;
            }

            for (let i = 0; i < colorsSource.length; ++i) {
                paletteToUpdateColors.slices[i].color = colorsSource[i];
            }
        });

        return updatedTerrainProps;
    }

    function autoFillColors() {
        const terrainProps = ObjectUtils.deepCloneObj(
            getConfig(bim)
        );

        const updatedTerrainProps = updateColors(terrainProps);

        patchConfig({
            properties: updatedTerrainProps,
        });
    }


    
    async function getTerrainMetrics() {
        const timeoutMs = 180_000;
        isLoadingLazy.replaceWith(true);
        const terrainMetrics = await engine.tasksRunner.newLongTask({
            identifier: "terrain-metrics",
            taskTimeoutMs: timeoutMs,
            defaultGenerator: function*() {
                const result = yield* lazyMetrics.waitTillCompletion(timeoutMs);
                if (result instanceof Success) {
                    return result.value;
                } else {
                    console.error("Failed to get terrain metrics", result.errorMsg);
                    return;
                }
            }()
        }).asPromise();
        isLoadingLazy.replaceWith(false);
        return terrainMetrics;
    }

    async function updateRanges(terrainProps: TerrainAnalysisConfig): Promise<TerrainAnalysisConfig> {
        const terrainPropsDraft = Immer.createDraft(terrainProps);
        let paletteToUpdateRanges: TerrainPalette;
        let rangesSource: TerrainPalette;

        if (terrainDisplayMode === TerrainDisplayMode.Elevation) {
            paletteToUpdateRanges = terrainPropsDraft.elevation_palette;
            const sceneRange = getTerrainElevationsRange(bim);
            const samplePalette = generateTerrainConfigProps({
                elevationRangeMeters: sceneRange,
                paletteSize: terrainPropsDraft.elevation_palette.slices.length,
                slopeRangePercentagesExtents: 20,
                cutfillRangeMeters: [-10, 10],
                defaultOffset: getDefaultOffset(),
            });
            rangesSource = samplePalette.elevation_palette;
        } else if (terrainDisplayMode === TerrainDisplayMode.Slope) {
            
            paletteToUpdateRanges = terrainPropsDraft.slope_palette;

            const terrainObjs = bim.instances.peekByTypeIdent(TerrainInstanceTypeIdent);
            let fastCalculationAvailable = true;
            const regularTiles: RegularHeightmapGeometry[] = [];
            for (const [_, tObj] of terrainObjs) {
                if (!tObj.representation || !(tObj.representation instanceof TerrainHeightMapRepresentation)) {
                    continue;
                }

                let position = new Vector3(), rotation = new Quaternion(), scale = new Vector3();
                tObj.worldMatrix.decompose(position, rotation, scale);
                if (!rotation.identity() || !scale.equals(Vec3One)) {
                    fastCalculationAvailable = false;
                    break;
                } else {
                    for (const [_, tile] of tObj.representation.tiles) {
                        const geo = bim.regularHeightmapGeometries.peekById(tile.initialGeo);
                        if (!geo) {
                            fastCalculationAvailable = false;
                            break;
                        } else {
                            regularTiles.push(geo);
                        }
                    }
                    if (!fastCalculationAvailable) {
                        break;
                    }
                }
            }

            let slopesRange = 20;
            if (fastCalculationAvailable) {
                slopesRange = getTerrainSlopesRange(
                    regularTiles, 
                    engine.terrainDisplaySettings.currentValue().slopeSelector
                );
            } else {
                const metrics = await getTerrainMetrics();
                if (metrics) {
                    slopesRange = Math.max(Math.abs(metrics.fullRange.min), Math.abs(metrics.fullRange.max));
                }
            }
            const samplePalette = generateTerrainConfigProps({
                elevationRangeMeters: [0, 1],
                paletteSize: terrainPropsDraft.slope_palette.slices.length,
                slopeRangePercentagesExtents: slopesRange,
                cutfillRangeMeters: [-10, 10],
                defaultOffset: getDefaultOffset(),
            });
            rangesSource = samplePalette.slope_palette;
        } else if (terrainDisplayMode === TerrainDisplayMode.CutFill) {
            paletteToUpdateRanges = terrainPropsDraft.cut_fill_palette;
            let cutfillRange: [min: number, max: number] = [-10, 10];
            const metrics =  await getTerrainMetrics();
            if (metrics) {
                cutfillRange = [metrics.fullRange.min, metrics.fullRange.max];
            }
            const samplePalette = generateTerrainConfigProps({
                elevationRangeMeters: [0, 1],
                paletteSize: terrainPropsDraft.cut_fill_palette.slices.length,
                slopeRangePercentagesExtents: 20,
                cutfillRangeMeters: cutfillRange,
                defaultOffset: getDefaultOffset(),
            });
            rangesSource = samplePalette.cut_fill_palette;
        } else {
            console.error(
                "unrecognized terrain display mode",
                terrainDisplayMode
            );
            return terrainProps;
        }
        
        for (let i = 0; i < rangesSource.slices.length; ++i) {
            paletteToUpdateRanges.slices[i].min = rangesSource.slices[i].min;
            paletteToUpdateRanges.slices[i].max = rangesSource.slices[i].max;
        }

        const updatedTerrainProps = Immer.finishDraft(terrainPropsDraft);
        return updatedTerrainProps;
    }

    async function autoFillRanges() {
        const terrainProps = getConfig(bim);
        const updatedTerrainProps = await updateRanges(terrainProps);
        patchConfig({
            properties: updatedTerrainProps,
        });
    }

    function patchConfig(patch: ConfigPatch) {
        bim.configs.applyPatchToSingleton(TerrainAnalysisTypeIdent, patch);
    }

    function selectTerrainMode(e: CustomEvent) {
        const selectedOption = Object.entries(TerrainGeoVersionSelectorLabels).find(([_k, v]) => v === e.detail);
        engine.terrainDisplaySettings.applyPatch({
            patch: { terrainVersion: Number(selectedOption![0]) as TerrainGeoVersionSelector},
        });
    }

</script>

<EngineUiPanel showPositionMenu={true}>
<div class="root">
    <div class="tabs-wrapper">
        <Tabs
            tabs={tabsList}
            firstIndexToActivate={activeTabIndex}
            compact={true}
            on:change={e => {
                const tab = e.detail.tab;
                const isSlopeActive = tab.name.includes("Slope");        
                engine.terrainDisplaySettings.applyPatch({
                    patch: {
                        mode: isSlopeActive ? TerrainDisplayMode.Slope : tab.key,
                        slopeSelector: isSlopeActive ? tab.key : terrainSlopeSelector
                    },
                });
                const config = getConfig(bim);
                let rangeInput = null;
                if(isSlopeActive){
                    rangeInput = config.slope_palette.slices.length;
                } else if (tab.key === TerrainDisplayMode.Elevation) {
                    rangeInput = config.elevation_palette.slices.length;
                } else if (tab.key === TerrainDisplayMode.CutFill) {
                    rangeInput = config.cut_fill_palette.slices.length;
                }
                if(rangeInput != null){
                    rangeInputLazy.replaceWith(rangeInput.toString());
                }
            }}
        />
    </div>
    <span class="controls-group">

        {#if showPaletteControls}
            <TextField
                labelText="Ranges Count"
                value={$rangeInput}
                isValid={isValidRangeInput}
                onChange={value => {
                    rangeInputLazy.replaceWith(value)
                }}
            />
        {/if}

        <Select
            labelText="Show terrain"
            selected={TerrainGeoVersionSelectorLabels[terrainVersion]}
            values={terrainVersionStrings}
            on:select={selectTerrainMode}
        />
    </span>

    {#if showPaletteControls}
        <TableUi lazyTableData = {tableData} />
        <span class="actions-group">
            <ButtonComponent
                data={{ label: "auto-fill ranges", onClick: autoFillRanges, disabled: $isLoading }}
            />
            <ButtonComponent
                data={{ label: "auto-fill colors", onClick: autoFillColors }}
            />
        </span>
    {/if}

</div>
</EngineUiPanel>

<style lang="scss">
    .root {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .tabs-wrapper {
        padding: 0 16px 8px;
    }
    .controls-group {
        padding: 5px;
        display: flex;
    }
    .actions-group {
        padding: 2px;
        display: flex;
        justify-content: center;
    }
</style>
