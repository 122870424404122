<script lang="ts">
    import type { PUI_PropertyNodeSwitcher } from "ui-bindings";
    import { RadioButtonsForm } from "../libui/radio-buttons";
    import { RadioButton } from "../libui/radio-buttons/types";

    export let property: PUI_PropertyNodeSwitcher;
    $: options = property.options.map(
        (o) => new RadioButton(o.value, o.label, o.disabled, o.option, o.tooltip)
    );
</script>

<div class="ui-config-property property-row">
    <div class="property-name text-main-medium">
        <div class="property-name-label mdc-typography--body1">
            {property.name}
        </div>
    </div>
    <div class="property-value">
        <div class="switcher-property">
            <RadioButtonsForm
                groupName={property.name}
                {options}
                bind:value={property.value}
            />
        </div>
    </div>
</div>

<style lang="scss">
</style>
