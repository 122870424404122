import { DefaultMap, ObjectUtils } from "engine-utils-ts";
import type { PropsGroupField, PropValueTotalHash} from "../../properties/Props";
import { applyPatchToProps, PropsPatch } from "../../properties/Props";
import type { PropsGroupBase } from "../../properties/Props";
import { PropertyBase } from "../../properties/Props";
import type { PropsReadsHistoryInterner } from "./PropsReadsHistory";
import { PropsReadsHistory } from "./PropsReadsHistory";


export type PropsProxyTargetTy = PropsGroupBase | PropertyBase[] | PropsGroupBase[];

export class PropsProxy<T extends PropsProxyTargetTy> {

    proxy: T;
    handler: PropsProxyHandler;

    constructor(
        target?: T
    ) {
        this.handler = new PropsProxyHandler();
        this.proxy = new Proxy(AbsentTarget as T, this.handler);
    }

    setForProxying(target: T) {
        this.handler.setTarget(target);
    }

    getProxy() {
        return this.proxy;
    }

    reset() {
        this.handler.reset();
    }

    getReadsHistory(builder: PropsReadsHistoryInterner): PropsReadsHistory | null {
        return this.handler.getReadsHistory(builder);
    }

    wasMutated(): boolean {
        return this.handler.wasMutated();
    }

    produceResult(): Readonly<T> {
        return this.handler.produceResult() as T;
    }

    producePatch(): PropsPatch | null {
        return this.handler.producePatch();
    }
}



export const AbsentTarget = {};

export class PropsProxyHandler implements ProxyHandler<PropsProxyTargetTy> {

    readonly children: DefaultMap<PropertyKey, PropsProxy<any>>;


    target: Readonly<PropsProxyTargetTy> | PropsProxyTargetTy | typeof AbsentTarget = AbsentTarget;
    
    _acessesHistory: Array<PropertyKey> = [];
    _writes: Map<PropertyKey, PropertyBase|PropsProxy<any>> = new Map();


    constructor() {
        this.children = new DefaultMap(() => new PropsProxy());
    }

    reset() {
        this.target = AbsentTarget;
        this._acessesHistory.length = 0;
        this._writes.clear();
        for (const child of this.children.values()) {
            child.handler.reset();
        }
    }

    setTarget(target: PropsProxyTargetTy) {
        this.target = Object.freeze(target);
    }
    private getTarget(): Readonly<PropsProxyTargetTy> {
        if (this.target === AbsentTarget) {
            throw new Error('target not initialized');
        }
        return this.target as Readonly<PropsProxyTargetTy>;
    }

    wasMutated(): boolean {
        if (this._writes.size > 0) {
            return true;
        }
        for (const child of this.children.values()) {
            if (child.handler.wasMutated()) {
                return true;
            }
        }
        return false;
    }


    produceResult(): Readonly<PropsGroupField> {
        if (this.target === AbsentTarget) {
            throw new Error('invalid state: target not initialized');
        }
        const patch = this.producePatch();
        if (patch === null) {
            return this.target as Readonly<PropsGroupField>;
        }
        const res = applyPatchToProps(patch, this.target as PropsGroupBase);
        if (!res) {
            return this.target as Readonly<PropsGroupField>;
        }
        return res[0];
    }

    producePatch(): PropsPatch | null {
        if (!this.wasMutated()) {
            return null;
        }
        const patch: PropsPatch = new PropsPatch();
        for (const [key, val] of this._writes) {
            if (val instanceof PropsProxy) {
                const target = val.handler.getTarget();
                const childPatch = val.handler.producePatch();
                if (childPatch) {
                    console.warn('written property patched!!')
                }
                patch[key as string] = target as PropsGroupField;
            } else {
                patch[key as string] = val as PropsGroupField;
            }
        }
        for (const [key, child] of this.children) {
            if (child.wasMutated()) {
                const nestedPatch = child.handler.producePatch();
                if (!nestedPatch) {
                    console.error('mutated child without patch', key);
                    continue;
                }
                patch[key as string] = nestedPatch;
            }
        }
        return patch;
    }

    getReadsHistory(builder: PropsReadsHistoryInterner): PropsReadsHistory | null {
        if (this._acessesHistory.length === 0) {
            return null;
        }
        const target = this.getTarget();

        const readKeys: string[] = [];
        const readValues: (PropsReadsHistory | PropValueTotalHash)[] = [];

        for (const key of this._acessesHistory) {
            const value = (target as any)[key];

            let valueForHistory: PropsReadsHistory | PropValueTotalHash;
            if (value instanceof PropertyBase) {
                valueForHistory = value.uniqueValueHash();
            } else if (ObjectUtils.isPrimitive(value)) {
                valueForHistory = value;
            } else {
                const child = this.children.get(key);
                const nestedReads = child?.handler.getReadsHistory(builder);
                if (nestedReads) {
                    valueForHistory = nestedReads;
                } else {
                    continue;
                }
            }
            readKeys.push(key as string);
            readValues.push(valueForHistory);
        }
        return readKeys.length > 0 ? new PropsReadsHistory(readKeys, readValues) : null;
    }

    // peekWritesHistory(): PropsWritesHistory | null {

    //     const writesKeys: PropertyKey[] = [];
    //     const writesData: (PropsWritesHistory | PropsGroupField)[] = [];
    //     const target = this.getTarget();

    //     for (const key of this._writesKeys) {
    //         const value = (target as any)[key];
    //         writesKeys.push(key);
    //         writesData.push((target as any)[key]);
    //     }

    //     // find nested writes
    //     for (const key of this._acessesHistory) {
    //         if (writesKeys.includes(key)) {
    //             throw new Error(`write and read into the same key are not supported: ${String(key)}`);
    //         }
    //         const obj = this.children.get(key)!;
    //         const hist = obj.handler.peekWritesHistory();
    //         if (hist !== null) {
    //             writesKeys.push(key);
    //             writesData.push(hist);
    //         }
    //     }
    //     if (writesKeys.length === 0) {
    //         return null;
    //     }
    //     return new PropsWritesHistory(writesKeys, writesData);
    // }

    get(_target: never, key: PropertyKey, receiver: any): any {
        const target = this.getTarget();
        const acessed = (target as any)[key] as undefined | PropertyBase | PropsProxyTargetTy;
        if (typeof acessed === 'function') {
            return acessed;
        }
        if (!this._acessesHistory.includes(key)) {
            this._acessesHistory.push(key);
        }
        const written = this._writes.get(key);
        if (written !== undefined) {
            if (written instanceof PropsProxy) {
                return written.proxy;
            }
            return written;
        }
        if (acessed instanceof PropertyBase) {
            return acessed;
        }
        if (acessed === null || acessed === undefined) {
            return acessed
        }
        if (typeof acessed === 'object') {
            const childProxy = this.children.getOrCreate(key);
            childProxy.handler.setTarget(acessed);
            return childProxy.proxy;
        }
        return acessed;
    }

    set(_target: never, key: PropertyKey, value: any, receiver: any): boolean {

        if (value instanceof PropertyBase || value === null) {
            this._writes.set(key, value);

        } else if (value instanceof Object) {
            // props group or array
            const childProxy = this.children.getOrCreate(key);
            childProxy.handler.setTarget(value);
            this._writes.set(key, childProxy);

        } else {
            throw new Error('only: null, PropertyBase or PropertyGroup or Array can be set: ' + typeof value);
        }
        return true;
    }

    getOwnPropertyDescriptor(_target: never, p: string | symbol): PropertyDescriptor | undefined {
        return Reflect.getOwnPropertyDescriptor(this.getTarget(), p);
    }

    getPrototypeOf(_target: never): object | null {
        return Reflect.getPrototypeOf(this.getTarget());
    }
    has(_target: never, p: string | symbol): boolean {
        return Reflect.has(this.getTarget(), p);
    }
    ownKeys(_target: never): ArrayLike<string | symbol> {
        return Reflect.ownKeys(this.getTarget());
    }
    isExtensible(_target: never): boolean {
        return true;
    }
    preventExtensions(_target: never): boolean {
        return false;
    }



    apply(target: never, thisArg: any, argArray: any[]) {
        throw new Error('Method not implemented.');
    }
    construct(target: never, argArray: any[], newTarget: Function): object {
        throw new Error('Method not implemented.');
    }
    defineProperty(target: never, property: string | symbol, attributes: PropertyDescriptor): boolean {
        throw new Error('Method not implemented.');
    }
    deleteProperty(target: never, p: string | symbol): boolean {
        throw new Error('Method not implemented.');
    }
    setPrototypeOf(_target: never, v: object | null): boolean {
        throw new Error('Method not implemented.');
    }
}
