<script lang="ts">
    import { getContext, setContext } from "svelte";
    import {
        ProjectStatus,
        ProjectStatusToLabel,
        type ProjectApi,
    } from "../projects";
    import { Dashboard, Mode } from "./Dashboard";
    import Topbar from "./Topbar.svelte";
    import Search from "./Search.svelte";
    import ProjectTile from "./ProjectTile.svelte";
    import ExtendedProjectTile from "./ExtendedProjectTile.svelte";
    import DeleteProjectDialog from "./DeleteProjectDialog.svelte";
    import { Tabs, type Tab } from "../libui/tabs";
    import { ButtonComponent, IconButton } from "../libui/button";
    import type { ProjectNetworkClient } from "engine-utils-ts";

    let projectApi = getContext<ProjectApi>("projectApi");
    let baseNetwork = getContext<ProjectNetworkClient>("network");
    let dashboard = setContext(
        "dashboard",
        new Dashboard(projectApi, baseNetwork)
    );

    $: loaded = !!$dashboard.paginated;

    $: projects = $dashboard.paginated?.items;

    const tabs: Tab[] = [
        {
            name: "All Projects",
            key: "all",
        },
        {
            name: ProjectStatusToLabel[ProjectStatus.Active],
            key: ProjectStatus.Active,
        },
        {
            name: ProjectStatusToLabel[ProjectStatus.Draft],
            key: ProjectStatus.Draft,
        },
        {
            name: ProjectStatusToLabel[ProjectStatus.Completed],
            key: ProjectStatus.Completed,
        },
        {
            name: ProjectStatusToLabel[ProjectStatus.Archived],
            key: ProjectStatus.Archived,
        },
    ];
    function updateProjectsFilter(e: CustomEvent) {
        const key = e.detail.tab.key;
        dashboard.updateFilter(key === "all" ? null : key);
    }
    $: mode = $dashboard.mode;
    $: TileComponent =
        mode === Mode.Standard ? ExtendedProjectTile : ProjectTile;

    let openDialog: (id: number) => void;
</script>

<div class="page-container">
    {#if loaded}
        <Topbar>
            <Search />
        </Topbar>

        <div class="filter-container">
            <Tabs
                {tabs}
                firstIndexToActivate={1}
                compact={true}
                on:change={updateProjectsFilter}
            />
            <div class="dashboard-mode">
                <div
                    class="icon-container"
                    class:selected={mode === Mode.Compact}
                >
                    <ButtonComponent
                        desc={new IconButton(
                            "GridCompact",
                            () => {
                                dashboard.setMode(Mode.Compact);
                            },
                            mode === Mode.Compact
                        )}
                    />
                </div>
                <div
                    class="icon-container"
                    class:selected={mode === Mode.Standard}
                >
                    <ButtonComponent
                        desc={new IconButton(
                            "GridStandard",
                            () => {
                                dashboard.setMode(Mode.Standard);
                            },
                            mode === Mode.Standard
                        )}
                    />
                </div>
            </div>
        </div>

        {#if projects && !!projects.length}
            <div class="cards" class:cards-compact={mode === Mode.Compact}>
                {#each projects as project (project.id)}
                    <svelte:component
                        this={TileComponent}
                        {project}
                        onRemove={openDialog}
                    />
                {/each}
            </div>
        {:else}
            <div class="empty-container">
                <span class="mdc-typography--headline4 text-main-medium">
                    No projects found
                </span>
            </div>
        {/if}
        <DeleteProjectDialog bind:openDialog />
    {/if}
</div>

<style lang="scss">
    .page-container {
        overflow-y: auto;
        max-height: 100vh;
        padding: 24px;
        box-sizing: border-box;
    }
    .filter-container {
        margin: 24px 0;
        display: flex;
        gap: 24px;
    }
    .dashboard-mode {
        display: flex;
        gap: 16px;
        & :global(.custom-icon-container) {
            padding: 8px;
            box-sizing: border-box;
        }
        .icon-container {
            border-radius: 2px;
            &.selected {
                background-color: var(--color-alt);
            }
        }
    }
    .empty-container {
        text-align: center;
        padding-top: 92px;
    }
    .cards {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        grid-gap: 16px;

        &.cards-compact {
            grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        }
    }
</style>
