import type { BimGeometryType, CubeGeometries} from "bim-ts";
import { CubeGeometry } from "bim-ts";
import { Success, type ScopedLogger} from "engine-utils-ts";
import type { Result } from "engine-utils-ts";
import type { EntityId } from "verdata-ts";
import { BimEngineGeometriesSyncBase } from "../resources/BimGeometriesSync";
import { EngineGeoType } from "./AllEngineGeometries";
import type { EngineGeometry } from "./EngineGeometry";
import { EngineBimGeometry } from "./EngineGeometry";
import { GeometryGenerator } from "./GeometryGenerator";
import type { GeometryGpuRepr } from "./KrBufferGeometry";


export class EngineGeoCube extends EngineBimGeometry<CubeGeometry> {


    constructor(
        sourcePolyline: CubeGeometry = new CubeGeometry(),
    ) {
        super(sourcePolyline);
    }

	_calcGpuRepr(): GeometryGpuRepr {
		return GeometryGenerator.generateCube(this.bimGeo.size, this.bimGeo.center);
	}

    // *snappingEdges(): IterableIterator<[Vector3, Vector3]> {
    //     const points: [Vector3, Vector3] = [Vector3.zero(), Vector3.zero()];
    //     for (let i = 1; i < this.sourceCube.points.length; ++i) {
    //         points[0].copy(this.sourceCube.points[i - 1]);
    //         points[1].copy(this.sourceCube.points[i - 0]);
    //         yield points;
    //     }
    // }
    // intersectPlanes(planes: Plane[]): IntersectionType {
    //     return GeometryUtils.checkPlanesWithCubePointsIntersection(this.sourceCube.points, planes);
    // }
}


export class EngineCubeGeosSynced
    extends BimEngineGeometriesSyncBase<EngineGeometry, CubeGeometry> {

    constructor(
        logger: ScopedLogger,
        bimGeos: CubeGeometries,
    ) {
        super(
            bimGeos,
            {
                logger,
                identifier: 'engine-cube-geos',
                idsType: EngineGeoType.Cube,
                T_Constructor: EngineGeoCube,
                interner: (obj) => JSON.stringify(obj),
                internerIdsReserver: () => this.reserveEngineOnlyId(),
            }
        );
    }

    checkForErrors(t: EngineGeoCube): boolean {
        return t.bimGeo.size.isFinite() && t.bimGeo.center.isFinite();
    }

    convertFromBim(bimObj: CubeGeometry, id: EntityId<BimGeometryType>): Result<EngineGeometry> {
        return new Success(new EngineGeoCube(bimObj));
    }
}

