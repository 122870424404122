import type { WorkerWrapper } from "./WorkerWrapper";
import type { WorkerMsg, WorkerMsgEvent } from "./WorkerMsg";

export class WebWorker implements WorkerWrapper {

    private worker: Worker;

    constructor(workerPath: string, options: WorkerOptions) {
        this.worker = new Worker(workerPath, { type: "module", name: options.name });
    }

    postMessage(message: WorkerMsg[]): void {
        this.worker.postMessage(message);
    }

    onMessage(callback: (message: WorkerMsgEvent<WorkerMsg | WorkerMsg[]>) => void): void {
        this.worker.onmessage = (e) => callback(e);
    }

    onMessageError(callback: (errorMsg: WorkerMsgEvent<WorkerMsg>) => void): void {
        this.worker.onmessageerror = (e) => callback(e);
    }

    onError(callBack: (errorMsg: string) => void): void {
        this.worker.onerror = (e) => {
            callBack(e.message);
        };
    }

    terminate(): void {
        this.worker.terminate();
    }
};