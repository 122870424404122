import type { ColDef, ColGroupDef, ColumnVO, FilterModel, IServerSideGetRowsRequest } from "ag-grid-enterprise";
import type { PropertyBase, UnitsMapper} from "bim-ts";
import { NumberProperty, StringProperty, type ValueAndUnit } from "bim-ts";
import { convertUnits, DefaultMapObjectKey, Failure, ObjectUtils, Success } from "engine-utils-ts";
import { filterList } from "./Filter";
import { PropertyTypeToTableRowValue, TableRowValueToNumberAndUnit, TableRowValueToPropertyType, type RowModel, type RowValue } from "./Models";
import { orderRows } from "./Sort";
import { valueFormatter } from "./TableUtils";


export function getData(
    request: IServerSideGetRowsRequest,
    rowData: RowModel[],
    unitsMapper: UnitsMapper,
): { rows: RowModel[]; secondaryColDefs: ColDef[] | null, usedColumns: Set<string> } {
    const rowGroupCols = request.rowGroupCols;
    const groupKeys = request.groupKeys;
    let valueCols = request.valueCols;
    const pivotCols = request.pivotCols;
    const pivotMode = request.pivotMode;
    const pivotActive = pivotMode && pivotCols.length > 0 && valueCols.length > 0;
    const filterModel = request.filterModel;
    const sortModel = request.sortModel;
    let secondaryColDefs: ColDef[] | null = null;

    rowData = filterList(rowData, filterModel as FilterModel | null);

    const usedColumns = new Set<string>();
    for (const row of rowData) {
        for (const key in row) {
            usedColumns.add(key);
        }
    }

    if (pivotActive) {
        const pivotResult = pivot(pivotCols, rowGroupCols, valueCols, rowData, unitsMapper);
        rowData = pivotResult.data;
        valueCols = pivotResult.aggCols;
        secondaryColDefs = pivotResult.secondaryColDefs;
    }

    // if not grouping, just return the full set
    if (rowGroupCols.length > 0) {
        // otherwise if grouping, a few steps...

        // first, if not the top level, take out everything that is not under the group
        // we are looking at.
        rowData = filterOutOtherGroups(rowData, groupKeys, rowGroupCols);

        // if we are showing a group level, we need to group, otherwise we are showing
        // a leaf level.
        const showingGroupLevel = rowGroupCols.length > groupKeys.length;

        if (showingGroupLevel) {
            rowData = buildGroupsFromData(
                rowData,
                rowGroupCols,
                groupKeys,
                valueCols
            );
        }
    } else if (pivotMode) {
        // if pivot mode active, but no grouping, then we aggregate everything in to one group
        const rootGroup = aggregateList(rowData, valueCols);
        rowData = [rootGroup];
    }

    rowData = orderRows(
        rowData,
        sortModel.map((s) => ({ field: s.colId, sort: s.sort }))
    );

    return { rows: rowData, secondaryColDefs, usedColumns };
}


function filterOutOtherGroups(
    originalData:RowModel[],
    groupKeys:RowValue[],
    rowGroupCols:ColumnVO[],
) {
    let filteredData = originalData;

    // if we are inside a group, then filter out everything that is not
    // part of this group
    for (let i = 0; i < groupKeys.length; i++) {
        const groupKey = groupKeys[i];
        const rowGroupCol = rowGroupCols[i];
        if (groupKey === undefined || rowGroupCol === undefined) {
            continue;
        }
        const field = rowGroupCol.id;
        const key = groupKey;
        const keyAsNumVal = TableRowValueToNumberAndUnit(key);
        filteredData = filter(filteredData, (item) => {
            const value = item[field];
            if(value === undefined && key === 'undefined') {
                return true;
            }
            if (value === undefined) {
                return false;
            }
            const prop = item[field]
            const propAsNumVal = TableRowValueToNumberAndUnit(prop);
            if (keyAsNumVal && propAsNumVal) {
                const compareResult = NumberProperty.unitBasedCompare(propAsNumVal, keyAsNumVal);
                if (compareResult instanceof Failure) {
                    return false;
                }
                return compareResult.value === 0;
            }
            return prop === key;
        });
    }

    return filteredData;
}

function filter(data:RowModel[], filterFn:(row:RowModel)=>boolean) {
    const filtered:RowModel[] = [];
    for (const row of data) {
        if (filterFn(row)) {
            filtered.push(row);
        }
    }
    return filtered;
}

function groupBy(data: RowModel[], field: string): Map<RowValue, RowModel[]> {
    let result = new Map<RowValue, RowModel[]>();

    const undefinedGroup = ObjectUtils.deepFreeze(StringProperty.new({ value: 'undefined' }));
    let numUnit: string | null = null;
    let fieldGroups = new DefaultMapObjectKey<
        PropertyBase,
        { val: RowValue, items: RowModel[]
    }>({
        valuesFactory: (key) => ({ items: [], val: PropertyTypeToTableRowValue(key) }),
        unique_hash: (key) => {
            if (key instanceof NumberProperty) {
                if (numUnit === null) {
                    numUnit = key.unit;
                }
                const valInCommonUnit = convertUnits(key.value, key.unit, numUnit);
                if (valInCommonUnit instanceof Failure) {
                    throw new Error('no unit found');
                }
                return valInCommonUnit.value;
            } else {
                return key.uniqueValueHash();
            }
        }
    });
    for (const item of data) {
        const value = item[field];
        if (value === undefined){
            fieldGroups.getOrCreate(undefinedGroup).items.push(item);
        } else {
            const key = ObjectUtils.deepFreeze(TableRowValueToPropertyType(value));
            fieldGroups.getOrCreate(key).items.push(item);
        }
    }
    for (const x of fieldGroups.values()) {
        result.set(x.val, x.items);
    }
    return result;
}

function buildGroupsFromData(rowData:RowModel[], rowGroupCols:ColumnVO[], groupKeys:string[], valueCols:ColumnVO[]) {
    const rowGroupCol = rowGroupCols[groupKeys.length];
    let field = rowGroupCol.id;
    let mappedRowData = groupBy(rowData, field);
    const groups:RowModel[] = [];

    for (const [key, value] of mappedRowData) {
        if (value) {
            const groupItem = aggregateList(value, valueCols);
            groupItem[field] = key;
            groups.push(groupItem);
        }
    }

    return groups;
}

function findRowValuesSum(rowValues: RowValue[]): NumberProperty {
    const props = rowValues.map(TableRowValueToNumberAndUnit)
        .filter((x): x is ValueAndUnit => !!x);
    let sum = NumberProperty.new({
        unit: '',
        value: 0,
    })
    const result = NumberProperty.unitBasedSum(props);
    if (result instanceof Success) {
        return result.value;
    } else {
        return sum;
    }
}

function aggregateList(rowData: RowModel[], valueCols: ColumnVO[]) {
    const result: RowModel = {};
    for (const valueCol of valueCols) {
        const field = valueCol.id;

        const values: RowValue[] = [];
        for (const childItem of rowData) {
            const value = childItem[field];
            // if pivoting, value will be undefined if this row data has no value for the column
            if (value !== undefined) {
                values.push(value);
            }
        }

        switch (valueCol.aggFunc) {
            case "first":
                result[field] = values[0];
                break;
            case "last":
                result[field] = values[values.length - 1];
                break;
            case "avg": {
                let sum = findRowValuesSum(values);
                if (values.length) {
                    sum = sum.withDifferentValue(sum.value / values.length);
                }
                result[field] = PropertyTypeToTableRowValue(sum);
                break;
            }
            case "sum": {
                const sum = findRowValuesSum(values);
                result[field] = PropertyTypeToTableRowValue(sum);
                break;
            }
            case "min": {
                const props = values.map(TableRowValueToNumberAndUnit)
                    .filter((x): x is ValueAndUnit => !!x);
                if (!props.length) {
                    console.warn(
                        "don't found values with type number in rows: " +
                            JSON.stringify(values)
                    );
                    break;
                }
                let min = props[0];
                for (const prop of props) {
                    const compResult = NumberProperty.unitBasedCompare(prop, min)
                    if (compResult instanceof Failure) {
                        break;
                    }
                    if (compResult.value < 0) {
                        min = prop;
                    }
                }
                if (min !== null) {
                    result[field] = PropertyTypeToTableRowValue(NumberProperty.new({value: min.value, unit: min.unit}));
                }
                break;
            }
            case "max": {
                const props = values.map(TableRowValueToNumberAndUnit)
                    .filter((x): x is ValueAndUnit => !!x);
                if (!props.length) {
                    console.warn(
                        "don't found values with type number in rows: " +
                            JSON.stringify(values)
                    );
                    break;
                }
                let max = props[0];
                for (const prop of props) {
                    const compResult = NumberProperty.unitBasedCompare(prop, max)
                    if (compResult instanceof Failure) {
                        break;
                    }
                    if (compResult.value > 0) {
                        max = prop;
                    }
                }
                if (max !== null) {
                    result[field] = PropertyTypeToTableRowValue(NumberProperty.new({value: max.value, unit: max.unit}));
                }
                break;
            }
            case "count":
                result[field] = values.length;
                break;
            default:
                console.warn(
                    "unrecognized aggregation function: " + valueCol.aggFunc
                );
                break;
        }
    }

    return result;
}

function pivot(
    pivotCols: ColumnVO[],
    rowGroupCols: ColumnVO[],
    valueCols: ColumnVO[],
    data: RowModel[],
    unitsMapper: UnitsMapper,
) {
    const pivotData: RowModel[] = [];
    const aggColsList: ColumnVO[] = [];

    const colKeyExistsMap = new Set<string>();

    const secondaryColDefs: ColDef[] = [];
    const secondaryColDefsMap = new Map<string, ColGroupDef>();
    for (const item of data) {
        const pivotValues: string[] = [];
        for (const pivotCol of pivotCols) {
            const pivotField = pivotCol.id;
            const pivotValue = item[pivotField];
            if (
                pivotValue !== null &&
                pivotValue !== undefined &&
                pivotValue.toString
            ) {
                pivotValues.push(pivotValue.toString());
            } else {
                pivotValues.push("-");
            }
        }

        // var pivotValue = item[pivotField].toString();
        const pivotItem: RowModel = {};

        for (const valueCol of valueCols) {
            const valField = valueCol.id;
            const colKey = createColKey(pivotValues, valField);

            const value = item[valField];
            pivotItem[colKey] = value;

            if (!colKeyExistsMap.has(colKey)) {
                addNewAggCol(colKey, valueCol);
                addNewSecondaryColDef(colKey, pivotValues, valueCol);
                colKeyExistsMap.add(colKey);
            }
        }

        for (const rowGroupCol of rowGroupCols) {
            const rowGroupField = rowGroupCol.id;
            pivotItem[rowGroupField] = item[rowGroupField];
        }

        pivotData.push(pivotItem);
    }

    function addNewAggCol(colKey: string, valueCol: ColumnVO) {
        const newCol: ColumnVO = {
            id: colKey,
            displayName: colKey,
            field: colKey,
            aggFunc: valueCol.aggFunc,
        };
        aggColsList.push(newCol);
    }

    function addNewSecondaryColDef(
        colKey: string,
        pivotValues: string[],
        valueCol: ColumnVO
    ) {
        let parentGroup: ColGroupDef | null = null;

        const keyParts: string[] = [];
        for (const pivotValue of pivotValues) {
            keyParts.push(pivotValue);
            const colKey = createColKey(keyParts);
            let groupColDef = secondaryColDefsMap.get(colKey);
            if (!groupColDef) {
                groupColDef = {
                    groupId: colKey,
                    headerName: pivotValue,
                    children: [],
                };
                secondaryColDefsMap.set(colKey, groupColDef);
                if (parentGroup) {
                    parentGroup.children.push(groupColDef);
                } else {
                    secondaryColDefs.push(groupColDef);
                }
            }
            parentGroup = groupColDef;
        }
        if (parentGroup) {
            parentGroup.children.push({
                colId: colKey,
                headerName: valueCol.aggFunc + "(" + valueCol.displayName + ")",
                field: colKey,
                valueFormatter: (params) => valueFormatter(params, unitsMapper),
            });
        }
    }

    return {
        data: pivotData,
        aggCols: aggColsList,
        secondaryColDefs: secondaryColDefs,
    };
}

function createColKey(pivotValues: string[], valueField?: string) {
    let result = pivotValues.join("|");
    if (valueField !== undefined) {
        result += "|" + valueField;
    }
    return result;
}
