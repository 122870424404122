import type {
    EventStackFrame,
    ScopedLogger} from "engine-utils-ts";
import {
    LruCache
} from "engine-utils-ts";
import { combineHashCodes } from "math-ts";
import type { BimProperty } from "../bimDescriptions/BimProperty";
import type { PropertiesCollection, PropertiesPatch } from "../bimDescriptions/PropertiesCollection";
import type { IdBimScene } from "./SceneInstances";


export interface PropsShapeHookInput {
	[shortName: string]: BimProperty;
}

export interface GroupsShapesToDefine {
	groupsToDefinesMergedPrefixes: string[],
	propertiesToHave: Map<string, BimProperty>,
	propertiesToOverride: Map<string, BimProperty>,
}

export interface BimPropWithOverride {
	property: BimProperty;
	override: boolean;
	prev?: BimProperty;
}


export interface ShapeHookArgsBroad {
	[shortName: string]: BimPropWithOverride;
}

export class SceneInstancesPropsShapeHooks {

	readonly _logger: ScopedLogger;
	readonly _perTypeGenerators = new Map<string, ShapeGenerator>();
	readonly _perTypeShapeDefiningProps = new Map<string, string[]>();

	readonly _perIdLastChecked = new Map<IdBimScene, BimProperty[]>();

	constructor(logger: ScopedLogger) {
		this._logger = logger.newScope('props-shapes-hooks');
	}

	addShapeHookFor<
		ShapeDefiningProps extends PropsShapeHookInput,
		ShapeHookArgs extends
			{ [key in keyof ShapeDefiningProps]: BimPropWithOverride }
	>(
		typeIdentifier: string,
		keyProperties: ShapeDefiningProps,
		outputShapesGenerator: (args: ShapeHookArgs) => GroupsShapesToDefine,
	) {
		if (this._perTypeGenerators.has(typeIdentifier)) {
			this._logger.error(`rewriting shape hook for ${typeIdentifier}`, this._perTypeGenerators.get(typeIdentifier), keyProperties, outputShapesGenerator);
		}

		const keyPropertiesInput = Object.keys(keyProperties);
		const keyPropertiesInputAsArray = Object.values(keyProperties); // in the same order as keys

		const shapeGenerator = new LruCache<ShapeGeneratorInput, GroupsShapesToDefine>({
			identifier: 'props-shape-hooks-cache',
			maxSize: 1000,
			hashFn: (input) => {
				let hash = input.length;
				for (const obj of input) {
					const current = combineHashCodes(
						obj.override ? 1 : 0,
						obj.property.valueUnitHash,
					);
					hash = combineHashCodes(hash, current);
				}
				return hash;
			},
			eqFunction: (l, r) => {
				for (let i = 0; i < l.length; ++i) {
					const lo = l[i];
					const ro = r[i];
					if (lo.override !== ro.override) {
						return false;
					}
					if (!!lo.prev !== !!ro.prev) {
						return false;
					}
					const lpRpS: Array<[lp: BimProperty, rp: BimProperty]> = [
						[lo.property, ro.property],
					];
					if (lo.prev && ro.prev) {
						lpRpS.push([lo.prev, ro.prev]);
					}
					for (const [lp, rp] of lpRpS) {
						if (!lp.equals(rp)) return false;
					}
				}
				return true;
			},
			factoryFn: (input): GroupsShapesToDefine => {
				const shapeFunctionInput: ShapeHookArgsBroad = {};
				for (let i = 0; i < keyPropertiesInput.length; ++i) {
					const key = keyPropertiesInput[i];
					const value = input[i];
					shapeFunctionInput[key] = value;
				}
				const args = shapeFunctionInput as ShapeHookArgs;
				const output = outputShapesGenerator(args);
				return output;
			}
		});

		this._perTypeGenerators.set(typeIdentifier, {
			keyPropertiesInput,
			keyPropertiesInputAsArray, // in the same order as keys
			outputGeneratorCached: shapeGenerator,
		});

		const shapeDefiningPropsPaths = Object.values(keyProperties).map(p => p._mergedPath);
		this._perTypeShapeDefiningProps.set(typeIdentifier, shapeDefiningPropsPaths);


	}

	shapeDefiningPropsMergedPathsFor(typeIdent: string): readonly string[] | undefined {
		return this._perTypeShapeDefiningProps.get(typeIdent);
	}

	validatePropertiesShape(
		id: IdBimScene,
		typeIdentifier: string,
		currentObjectProperties: PropertiesCollection,
		revertPatch: PropertiesPatch,
		event: EventStackFrame,
	): void {
		const validator = this._perTypeGenerators.get(typeIdentifier);
		if (!validator) {
			return;
		}
		// gather all props of interest
		const objectInputProps: BimProperty[] = [];
		for (const prop of validator.keyPropertiesInputAsArray) {
			objectInputProps
				.push(currentObjectProperties.get(prop._mergedPath) ?? prop);
		}
		const previousObjectInputProps = this._perIdLastChecked.get(id);
		let solverInput: ShapeGeneratorInput = [];
		// gather solver input
		if (
			previousObjectInputProps &&
			previousObjectInputProps.length == objectInputProps.length
		) {
			// compare current props of interest with previous one
			for (let i = 0; i < objectInputProps.length; i++) {
				const prev = previousObjectInputProps[i];
				const cur = objectInputProps[i];
				solverInput.push({
					property: cur,
					override: !prev.equals(cur),
					prev,
				});
			}
		} else {
			// current object is being validated for the first time,
			// thus no property is overriden
			solverInput = objectInputProps.map(x => ({
				override: false,
				property: x,
				prev: undefined,
			}));
		}
		// call solver
		const output = validator.outputGeneratorCached.get(solverInput);
		// gather propsToHave/propsToOverride from the result
		const propsToHave = new Map(output.propertiesToHave);
		const propsToOverride = new Map(output.propertiesToOverride);
		for (const currentProp of currentObjectProperties.values()) {
			const currentPath = currentProp._mergedPath;
			const propToHave = propsToHave.get(currentPath);
			const propToOverride = propsToOverride.get(currentPath);
			// if prop is in props-to-have, prop should be discarded
			if (propToHave) {
				propsToHave.delete(currentPath);
				// remove possible duplication in props-to-override
				propsToOverride.delete(currentPath);
				continue;
			}
			// if prop is in props-to-override and prop value is not changed
			// but prop meta does, prop should be applied
			// even if override-flag is present
			if (
				propToOverride &&
				propToOverride.valueEqual(currentProp) &&
				!propToOverride.metaEqual(currentProp)
			) {
				continue;
			}

			// check if current prop is a prop to override
			if (propToOverride) {
				if (!event.allowPropsShapeHookToResetExistingPropsToDefaultValues) {
					// if override-flag is not present, prop should be moved into props-to-have
					propsToHave.set(currentPath, currentProp);
					propsToOverride.delete(currentPath);
				}
				continue;
			}
			// if current property should be removed
			for (const groupsToDefine of output.groupsToDefinesMergedPrefixes) {
				// check if current property is in groupToDefine
				if (currentPath.startsWith(groupsToDefine)) {
					// remove currentProp and update revert
					currentObjectProperties._props!.delete(currentPath);
					revertPatch.push([currentPath, currentProp]);
					break;
				}
			}
		}
		// set props left in propsToHave/propsToOverride to current object
		propsToHave.forEach((v, k) => propsToOverride.set(k, v));
		for (const [path, propToHave] of propsToOverride) {
			const prop = currentObjectProperties.get(path);
			// add propToHave to current object and update revert
			currentObjectProperties._props!.set(path, propToHave);
			revertPatch.push([path, prop ?? null]);
		}
		// set cache of latest call of the solver for current object
		this._perIdLastChecked.set(id, objectInputProps);
	}

	deleteCachesOf(ids: IdBimScene[]) {
		for (const id of ids) {
			this._perIdLastChecked.delete(id);
		}
	}
}

interface ShapeGenerator {
	keyPropertiesInput: string[],
	keyPropertiesInputAsArray: BimProperty[];
	outputGeneratorCached: LruCache<ShapeGeneratorInput, GroupsShapesToDefine>;
}

type ShapeGeneratorInput = BimPropWithOverride[];

const EmptyStringsArray: readonly string[] = Object.freeze([] as string[]);
