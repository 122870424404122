<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M4.33334 10.6666H13.6667L13.6667 5H4.33334L4.33334 10.6666ZM4.33334 11.6666C4.06668 11.6666 3.83334 11.5666 3.63334 11.3666C3.43334 11.1666 3.33334 10.9333 3.33334 10.6666V3.33331C3.33334 3.06665 3.43334 2.83331 3.63334 2.63331C3.83334 2.43331 4.06668 2.33331 4.33334 2.33331H13.6667C13.9333 2.33331 14.1667 2.43331 14.3667 2.63331C14.5667 2.83331 14.6667 3.06665 14.6667 3.33331V10.6666C14.6667 10.9333 14.5667 11.1666 14.3667 11.3666C14.1667 11.5666 13.9333 11.6666 13.6667 11.6666H4.33334ZM2.33334 13.6666C2.06668 13.6666 1.83334 13.5666 1.63334 13.3666C1.43334 13.1666 1.33334 12.9333 1.33334 12.6666V4.33331H2.33334V12.6666H12.6667V13.6666H2.33334Z"
        fill="currentColor"
    />
</svg>
