import type { LazyVersioned, PollWithVersionResult } from "engine-utils-ts";
import { VersionedInvalidator } from "engine-utils-ts";
import { ContextMenuStructure } from "./types";

export class ContextMenu implements LazyVersioned<ContextMenu>  {
    invalidator = new VersionedInvalidator()
    constructor() {}
    xOffsetPx: number = 0;
    yOffsetPx: number = 0;
    isMenuOpened: boolean = false;
    menuStructure: ContextMenuStructure | LazyVersioned<ContextMenuStructure> = new ContextMenuStructure([]);
    pollWithVersion(): PollWithVersionResult<Readonly<ContextMenu>> {
        return {
            value: this.poll(),
            version: this.version(),
        };
    }
    poll(): Readonly<ContextMenu> {
        return this;
    }
    disposeMenu() {
        this.xOffsetPx = 0;
        this.yOffsetPx = 0;
        this.menuStructure = new ContextMenuStructure([]);
        this.invalidator.invalidate();
        this.isMenuOpened = false;
    }
    openMenuAtPosition(
        xOffsetPx: number,
        yOffsetPx: number,
        menuStructure: typeof this['menuStructure'],
    ) {
        this.xOffsetPx = xOffsetPx;
        this.yOffsetPx = yOffsetPx;
        this.menuStructure = menuStructure;
        this.isMenuOpened = true;
        this.invalidator.invalidate();
    }
    version(): number {
        let version = this.invalidator.version();
        if (!(this.menuStructure instanceof ContextMenuStructure)) {
            version += this.menuStructure.version()
        }
        return version;
    }
}
