<script lang="ts">
    import PropertyHelp from "../../pui/PropertyHelp.svelte";
    import { Switch } from "../../libui/checkbox";
    import PUI_Root from "../../pui/PUI_Root.svelte";
    import { PUI_GroupNode, PUI_Lazy, PUI_PropertyNodeBool } from "ui-bindings";
    import { LazyBasic } from "engine-utils-ts";
    import { BarData } from "./CalculateBarChart";
    import ChartBar from "./ChartBar.svelte";
    import { EnergyPipelineStage } from "bim-ts";

    export let name: string;
    export let bar: BarData | undefined;
    export let stage: EnergyPipelineStage | undefined = undefined;
    export let lossPropsUi:
        | { enabled: PUI_PropertyNodeBool; pui: PUI_GroupNode }
        | undefined = undefined;

    let lossProps =
        lossPropsUi &&
        new LazyBasic<PUI_GroupNode>("versioned-item" + name, lossPropsUi.pui);
    $: {
        if (lossPropsUi && lossProps) {
            lossProps.forceUpdate(lossPropsUi.pui);
        }
    }
    $: toggle = lossPropsUi?.enabled;

    const lossPui = lossProps && new PUI_Lazy(lossProps);
    $: description = stage?.description();
</script>

<div class="bar" class:empty={!name}>
    <div class="bar-inner">
        <slot />

        <ChartBar
            {name}
            showBar={!!toggle?.value || !lossPropsUi}
            energyLength={bar?.energyLength || 0}
            energyLabel={bar?.energyLabel}
            lossLength={bar?.lossLength || 0}
            lossLabel={bar?.lossLabel}
        >
            <svelte:fragment slot="toggle">
                <div class="stage-toggle">
                    {#if !lossPropsUi}
                        <Switch value={true} readonly={true} />
                    {:else if toggle}
                        <Switch
                            value={toggle.value}
                            readonly={toggle.readonly}
                            onChange={(newValue) => {
                                if (toggle) {
                                    toggle.value = newValue;
                                }
                            }}
                        />
                    {/if}
                </div>
            </svelte:fragment>
            <svelte:fragment slot="info">
                {#if description}
                    <div class="info">
                        <PropertyHelp {name} content={description} />
                    </div>
                {/if}
            </svelte:fragment>
        </ChartBar>
        {#if lossPui && toggle?.value}
            <PUI_Root
                configIdentifier={"energy-params-pui-root" + name}
                puiSource={lossPui}
            />
        {/if}
    </div>
</div>

<style lang="scss">
    .bar {
        padding-bottom: 4px;
        padding-top: 20px;
        margin: 0 8px;
        border-top: 1px solid var(--color-divider);
        :global(.group-holder .group.root-level) {
            margin: 4px 0;
        }
        :global(.ui-config-property) {
            --offset: 28px;
        }
        :global(.info-container) {
            padding-right: 8px;
        }
        &.empty {
            padding-top: 8px;
        }
    }
    .bar-inner {
        margin: 0 -8px;
    }
    .info {
        :global(.tooltip-container) {
            right: 320px;
        }
        :global(.mdc-tooltip) {
            transform: translateX(calc(320px - 100%));
        }
    }
    .stage-toggle {
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        height: 22px;
    }
</style>
