import type { LazyVersioned, ScopedLogger, Result} from "engine-utils-ts";
import { Allocated, Deleted, Failure, IterUtils, LazyDerived, ObservableObject, PollablePromise, Success, WorkerPool, Yield } from "engine-utils-ts";
import { PUI_GroupNode} from "ui-bindings";
import { RuntimeSystemExecutionStatus } from "ui-bindings";
import type { Bim } from "../Bim";
import type { BimCustomRuntime, CustomRuntimeAsyncWork } from "../runtime/BimCustomRuntimes";
import type { BimPropertyData } from "../bimDescriptions/BimProperty";
import { BimProperty } from "../bimDescriptions/BimProperty";
import type { EntitiesCollectionUpdates} from "../collections/EntitiesCollectionUpdates";
import { EntitiesUpdated } from "../collections/EntitiesCollectionUpdates";
import { IrregularHeightmapGeometry } from "../geometries/IrregularHeightmapGeometries";
import { RegularHeightmapGeometry } from "../geometries/RegularHeightmapGeometry";
import { TerrainHeightMapRepresentation } from "../representation/Representations";
import type { IdBimScene, SceneInstance, SceneInstancePatch} from "../scene/SceneInstances";
import { SceneObjDiff } from 'src/scene/SceneObjDiff';
import type { CutFillHeatmapCalcJobArgs} from "./cut-fill/CutFillHeatmapCalculationExecutor";
import { CutFillHeatmapCalculationExecutor } from "./cut-fill/CutFillHeatmapCalculationExecutor";
import { SolverObjectInstance } from "../runtime/SolverObjectInstance";
import { extractValueUnitPropsGroup } from "../bimDescriptions/NamedBimPropertiesGroup";
import type { CostsConfigProvider, ExpandLegacyPropsWithCostTableLinks} from "../cost-model/capital";
import { createFocusLinkOnSample, mergeCostComponents, sumCostComponents } from "../cost-model/capital";
import { EstimateCostGlobal } from "../archetypes/EquipmentCommon";
import type { PerTileCutFillHeatmap } from "./metrics/TerrainMetrics";
import type { SolverInstancePatchResult } from "src/runtime/ReactiveSolverBase";
import { getGradingDefaultCostsPerQubicMeter } from "src/cost-model/capital/tables/categories/civil/earthwork";


export enum TerrainVersion {
    Origin,
    Latest,
}

export const TerrainInstanceTypeIdent = 'terrain-heightmap';

export function registerTerrainObject(bim: Bim) {
	const instances = bim.instances;

	instances.archetypes.registerArchetype(
        {
            type_identifier: TerrainInstanceTypeIdent,
            mandatoryProps: [{
                path: ['terrain', 'source_file_name'],
                value: 'unknown',
                readonly: true,
            }],
        }
    );
    registerTerrainMetricsCustomRuntime(bim);
}

export function newDefaultTerrainInstance(bim: Bim, sourceFileName: string, representation: TerrainHeightMapRepresentation): SceneInstance {
    const instance = bim.instances.archetypes.newDefaultInstanceForArchetype(TerrainInstanceTypeIdent);
    instance.representation = representation;
    const path = ['terrain', 'source_file_name'];
    instance.properties.applyPatch([[
        BimProperty.MergedPath(path),
        {
            path: path,
            value: sourceFileName,
            readonly: true,
        }
    ]]);
    instance.name = sourceFileName;
    return instance;
}

export interface TerrainMetricsRuntimeSettings {
    enabled: boolean;
}

function registerTerrainMetricsCustomRuntime(
    bim: Bim,
) {
    bim.customRuntimes.registerCustomRuntime(new TerrainMetricsRuntime(bim.logger, bim))
}


class TerrainMetricsRuntime implements BimCustomRuntime<{}> {

    executionOrder: number = 200;
    name: string = 'terrain-metrics';
    settings: ObservableObject<TerrainMetricsRuntimeSettings>;

    logger: ScopedLogger;

    _relevantInstancesIds = new Set<IdBimScene>();
    _runningsCaclulationsPerId = new Map<IdBimScene, CustomRuntimeAsyncWork<any>>();

    _version: number = 0;

    _calculatedPatchesToApply: [IdBimScene, SceneInstancePatch][] = [];

    ui?: LazyVersioned<PUI_GroupNode> | undefined;

    _dirtyIds: IdBimScene[] = [];

    constructor(
        logger: ScopedLogger,
        readonly _bim: Bim,
    ) {
        this.logger = logger.newScope(this.name);
        this.settings = new ObservableObject<TerrainMetricsRuntimeSettings>({
            identifier: 'terrain-metrics-settings',
            initialState: {
                enabled: true,
            }
        });
    }


    dispose(): void {
    }

    version(): number {
        return this._version;
    }

    executionStatus(): RuntimeSystemExecutionStatus {
        return this._runningsCaclulationsPerId.size > 0 ? RuntimeSystemExecutionStatus.InProgress : RuntimeSystemExecutionStatus.Done;
    }

    invalidateFromSharedDependenciesUpdates(ident: never): void {
        this.logger.error('unexpected global dependency udpate', ident);
    }

    invalidateFromBimUpdates(args: {
        bim: Bim,
        updates: EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>[],
    }): void {
        const dirtyIds: IdBimScene[] = this._dirtyIds.slice();
        this._dirtyIds.length = 0;

        const diffToInvalidate = SceneObjDiff.GeometryReferenced | SceneObjDiff.Representation;

        for (const update of args.updates) {
            if (update instanceof Allocated) {
                for (const id of update.ids) {
                    const instance = args.bim.instances.peekById(id);
                    if (instance === undefined || instance.type_identifier !== TerrainInstanceTypeIdent) {
                        continue;
                    }
                    this._relevantInstancesIds.add(id);
                    dirtyIds.push(id);
                }
            } else if (update instanceof EntitiesUpdated) {
                if ((update.allFlagsCombined & diffToInvalidate) === 0) {
                    continue;
                }
                for (let i = 0; i < update.ids.length; ++i) {
                    const diff = update.diffs[i];
                    if ((diff & diffToInvalidate) === 0) {
                        continue;
                    }
                    const id = update.ids[i];
                    if (this._relevantInstancesIds.has(id)) {
                        dirtyIds.push(id);
                    }
                }

            } else if (update instanceof Deleted) {
                for (const id of update.ids) {
                    this._relevantInstancesIds.delete(id);
                    if(this._runningsCaclulationsPerId.delete(id)){
                        this._version += 1;
                    };
                }
            } else {
                this.logger.error('unexepected instances update type', update);
            }
        }

        if (dirtyIds.length > 0) {
            IterUtils.sortDedupNumbers(dirtyIds);

            for (const id of dirtyIds) {
                if (!this._relevantInstancesIds.has(id)) {
                    continue;
                }
                this._runningsCaclulationsPerId.set(id, {
                    generator: this._perInstanceCalculation(args.bim, id),
                    onSucess: (res: Result<SceneInstancePatch>) => {
                        if (res instanceof Success) {
                            this._calculatedPatchesToApply.push([id, res.value]);
                        } else {
                            this.logger.error('error calculating terrain metrics', res.errorMsg());
                        }
                    },
                    finally: () => {
                        this._version += 1;
                        this._runningsCaclulationsPerId.delete(id);
                    }
                });
                this._version += 1;
            }
        }
    }


    applyQuickPreWorkUpdates(args: {bim: Bim}): void {
        this.logger.assert(this._calculatedPatchesToApply.length === 0, 'patches to apply should be empty');
    }

    applyQuickPostWorkUpdates(args: {bim: Bim}): void {
        if (this._calculatedPatchesToApply) {
            this._version += 1;
            const patches = this._calculatedPatchesToApply;
            this._calculatedPatchesToApply = [];
            args.bim.instances.applyPatches(patches, {isEventDerived: true});
        }
    }

    asyncWorkToRun(): CustomRuntimeAsyncWork<void> | null {
        for (const [id, generator] of this._runningsCaclulationsPerId) {
            return generator;
        }
        return null;
    }

    *_perInstanceCalculation(bim: Bim, id: IdBimScene): Generator<Yield, Result<SceneInstancePatch>> {

        const logger = this.logger.newScope(id.toString());

        const instance = bim.instances.peekById(id);

        if (instance == undefined) {
            return new Failure({msg: 'instance absent'});
        }

        if (!(instance.representation instanceof TerrainHeightMapRepresentation)) {
            return new Failure({msg: 'unexepcted terrain representation'});
        }

        yield Yield.Asap;

        let initalAreaTotal: number = 0;
        let updatedAreaTotal: number = 0;

        const heatmapsPromises: PollablePromise<PerTileCutFillHeatmap | null>[] = [];

        for (const [tileId, tile] of instance.representation.tiles) {
            yield Yield.Asap;

            const geo1 = bim.allBimGeometries.peekById(tile.initialGeo);
            const geo2 = tile.updatedGeo ? bim.allBimGeometries.peekById(tile.updatedGeo) : null;

            if (geo1 === undefined) {
                logger.warn('inital geo is not present in bim', tile.initialGeo);
            }

            let initialTileArea: number;
            if (geo1 instanceof RegularHeightmapGeometry || geo1 instanceof IrregularHeightmapGeometry) {
                initialTileArea = geo1.area();
            } else {
                logger.error('unexepected geo type in tile initial geo', geo1);
                continue;
            }

            initalAreaTotal += initialTileArea;

            if (geo2 instanceof RegularHeightmapGeometry || geo2 instanceof IrregularHeightmapGeometry) {
                updatedAreaTotal += geo2.area();

                const heatmapCalcArgs: CutFillHeatmapCalcJobArgs = {
                    tileMaxAabb: tileId.aabb(instance.representation.tileSize),
                    initialGeo: geo1 as RegularHeightmapGeometry | IrregularHeightmapGeometry,
                    updatedGeo: geo2 as RegularHeightmapGeometry | IrregularHeightmapGeometry | null,
                };
                const heatmapPromise = WorkerPool.execute(CutFillHeatmapCalculationExecutor, heatmapCalcArgs);
                heatmapsPromises.push(new PollablePromise(heatmapPromise));

            } else {
                updatedAreaTotal += initialTileArea;
            }
        }

        const cutfillMetrics = new CutfillVolumeMetrics();

        for (const heatmapPromise of heatmapsPromises) {
            const heatmapResult = yield* heatmapPromise.generatorWaitForResult();

            if (heatmapResult instanceof Success) {
                const heatmap = heatmapResult.value;
                if (heatmap === null) {
                    continue;
                }
                const heatmapCellArea = heatmap.stepSizeMeters * heatmap.stepSizeMeters;
                for (let i = 0; i < heatmap.cutFillInCm.length; ++i) {
                    const diff = heatmap.cutFillInCm[i] * 0.01;
                    cutfillMetrics.updateFromDiff(diff, heatmapCellArea);
                }
            } else {
                logger.error(heatmapResult.errorMsg())
            }
        }

        const propsDescriptions = cutfillMetrics.asMetricsBimProps();
        propsDescriptions.push({path: ['metrics', 'area_initial'], value: initalAreaTotal / 10_000, unit: 'ha'});
        propsDescriptions.push({path: ['metrics', 'area_latest'], value: updatedAreaTotal / 10_000, unit: 'ha'});

        const bimProps = propsDescriptions.map(pd => BimProperty.NewShared({...pd, readonly: true, isComputedBy: this.name}));

        const bimPatch: SceneInstancePatch = {
            properties: bimProps.map(p => [p._mergedPath, p]),
        };

        return new Success(bimPatch);
    }
}


class CutfillVolumeMetrics {
    cutArea: number = 0;
    fillArea: number = 0;
    cutVolume: number = 0;
    fillVolume: number = 0;

    updateFromDiff(diff: number, area: number) {
        const volume = Math.abs(diff) * area;
        if (diff < 0) {
            this.cutArea += area
            this.cutVolume += volume;
        } else if (diff > 0) {
            this.fillArea += area
            this.fillVolume += volume;
        }
    }

    asMetricsBimProps(): BimPropertyData[] {
        return [
            {path: ['metrics', 'cut_area'], value: this.cutArea / 10_000, unit: 'ha'},
            {path: ['metrics', 'fill_area'], value: this.fillArea / 10_000, unit: 'ha'},
            {path: ['metrics', 'cut_volume'], value: this.cutVolume, unit: 'm3'},
            {path: ['metrics', 'fill_volume'], value: this.fillVolume, unit: 'm3'},
            {path: ['metrics', 'net_balance'], value: this.fillVolume - this.cutVolume, unit: 'm3'},
        ]
    }
}

const TerrainPriceRelatedArgs = {
    cutVolume: BimProperty.NewShared({
        path: ['metrics', 'cut_volume'],
        value: 0,
        unit: 'yd3',
    }),
    fillVolume: BimProperty.NewShared({
        path: ['metrics', 'fill_volume'],
        value: 0,
        unit: 'yd3',
    })
}


function createTerrainPricePatchForInstance(
    instanceRelatedArgs: typeof TerrainPriceRelatedArgs,
    commonArgs: TerrainPricingRuntimeGlobal,
): SolverInstancePatchResult {
    let hasZeroCosts = false;
    const props = instanceRelatedArgs;
    const { cut, fill } = commonArgs;

    const cutCost = mergeCostComponents(cut.estimate?.costs, getGradingDefaultCostsPerQubicMeter());
    const fillCost = mergeCostComponents(fill.estimate?.costs, getGradingDefaultCostsPerQubicMeter());

    const cutCostPerMeter = cutCost && sumCostComponents(cutCost) || 0;
    const fillCostPerMeter = fillCost && sumCostComponents(fillCost) || 0;

    const cutTotalCost = cutCostPerMeter * props.cutVolume.as('m3') ;
    const fillTotalCost = fillCostPerMeter * props.fillVolume.as('m3');
    if (cutTotalCost === 0 || fillTotalCost === 0) {
        hasZeroCosts = true;
    }
    const totalCost = cutTotalCost + fillTotalCost;

    // original
    const patch: BimPropertyData[] = [
        {
            path: ['cost', 'total_cost'],
            value: totalCost,
            unit: 'usd',
        },
        {
            path: ['cost', 'cut', 'total_cost'],
            value: cutTotalCost,
            unit: 'usd',
        },
        {
            path: ['cost', 'fill', 'total_cost'],
            value: fillTotalCost,
            unit: 'usd',
        },
    ];
    return {
        legacyProps: patch,
        removeProps: [
            BimProperty.MergedPath(['cost', 'status']),
            BimProperty.MergedPath(['cost', 'has_zero_costs']),
            BimProperty.MergedPath(['cost', 'cut', 'cost_per_volume']),
            BimProperty.MergedPath(['cost', 'volume_unit']),
            BimProperty.MergedPath(['cost', 'fill', 'cost_per_volume']),
        ]
    };
}


class TerrainPricingRuntimeGlobal {
    constructor(
        public cut: EstimateCostGlobal,
        public fill: EstimateCostGlobal,
    ) {}
}
export function registerTerrainPriceSolver(bim: Bim, costs: CostsConfigProvider) {

    const global = LazyDerived.new2(
        'terrainPriceGlobal',
        [bim.unitsMapper],
        [
            costs.createLazyEstimateById(TerrainCutPricingEstimateId),
            costs.createLazyEstimateById(TerrainFillPricingEstimateId),
        ],
        ([cut, fill]): TerrainPricingRuntimeGlobal => {
            return new TerrainPricingRuntimeGlobal(
                new EstimateCostGlobal(cut, bim.unitsMapper),
                new EstimateCostGlobal(fill, bim.unitsMapper),
            )
        }
    )

    const TerrainPricingSharedArgGlobalIdent = 'terrain-pricing-shared-arg-global';

    bim.runtimeGlobals.registerByIdent(TerrainPricingSharedArgGlobalIdent, global);

    bim.reactiveRuntimes.registerRuntimeSolver(new SolverObjectInstance({
        solverIdentifier: 'terrain-pricing-solver',
        objectsDefaultArgs: {
            legacyProps: TerrainPriceRelatedArgs,
        },
        objectsIdentifier: TerrainInstanceTypeIdent,
        globalArgsSelector: {
            [TerrainPricingSharedArgGlobalIdent]: TerrainPricingRuntimeGlobal,
        },
        cache: true,
        solverFunction: (props, globals) => {
            const valueUnitOnlyProps = extractValueUnitPropsGroup(props.legacyProps);
            const shared = globals[TerrainPricingSharedArgGlobalIdent];
            if (shared instanceof Failure) {
                return {}
            }
            const result = createTerrainPricePatchForInstance(valueUnitOnlyProps, shared.value);
            return result
        }
    }));
}

export const TerrainCutPricingEstimateId = 'cut-per-cubic-meter';
export const TerrainFillPricingEstimateId = 'fill-per-qubic-meter';

export const expandTerrainLegacyPropsWithCostTableLinks: ExpandLegacyPropsWithCostTableLinks = (params) => {
    createFocusLinkOnSample({
        costModelFocusApi: params.costModelFocusApi,
        type_identifier: TerrainInstanceTypeIdent,
        targetPui: PUI_GroupNode.tryGetNestedChild(params.pui, ['cost']),
    })
}
