import type { BimImage, BimImages, BimImageType} from 'bim-ts';
import { BimRawImage } from 'bim-ts';
import type { ScopedLogger , Result } from 'engine-utils-ts';
import { Failure, FetchUtils, ProjectNetworkClient, Success } from 'engine-utils-ts';
import type { EntityId } from 'verdata-ts';

import type { Texture} from '../3rdParty/three';
import {
    ClampToEdgeWrapping, DataTexture, LinearFilter, LinearMipmapLinearFilter, RGBAFormat, UnsignedByteType,
    UVMapping
} from '../3rdParty/three';
import { DiffuseDefaultPixel } from './MaterialsFactory';
import { TexturesLoader } from './TexturesLoader';
import { EntitiesBimSynced } from '../resources/EntitiesBimSynced';

export enum EngineImageType {
    BimImage = 0,

    EngineLegacyImage = 17
};

export type IdEngineImage = EntityId<EngineImageType>;

export class EngineImage {
    constructor(
        public readonly loadPath: string | null = null,
        public readonly rawData: BimRawImage | null = null,
    ) {
    }
}

export class EngineImages
    extends EntitiesBimSynced<EngineImage, BimImage, EngineImageType, BimImageType>
{

    readonly network: ProjectNetworkClient;

    texturesLoader: TexturesLoader;
    _threeTextures = new Map<IdEngineImage, Texture | undefined>();

    constructor(
        logger: ScopedLogger,
        bimImages: BimImages,
        network: ProjectNetworkClient,
    ) {
        super(bimImages, {
            identifier: 'engine-images',
            logger,
            idsType: EngineImageType.BimImage,
            T_Constructor: EngineImage,
        });
        this.network = network;
        this.texturesLoader = new TexturesLoader({ network: new ProjectNetworkClient({
            ...network.config,
            basePath: FetchUtils.combineURLs('api', 'assets')
        }) });
    }

    checkForErrors(t: EngineImage, errors: string[]): void {
        if (t.loadPath == undefined && !(t.rawData instanceof BimRawImage)) {
            errors.push('image should have load path or raw data');
        }
    }

	convertFromBim(bimObj: BimImage): Result<EngineImage> {
		if (bimObj.inline == undefined && bimObj.assetRef == undefined) {
            return new Failure({msg: `bim image should have inlineRawImage of assetPath set`});
        }
        return new Success(new EngineImage(
            bimObj.assetRef?.path ?? null,
            bimObj.inline
        ))
	}

    getAsEngineImage(id: IdEngineImage): Texture | undefined {
        if (this._threeTextures.has(id)) {
            return this._threeTextures.get(id);
        }
        const image = this.peekById(id);
        if (!image) {
            return undefined;
        }
        let tex: Texture | undefined;
        if (image.rawData) {
            tex = new DataTexture(
                image.rawData.rawData,
                image.rawData.width,
                image.rawData.height,
                RGBAFormat,
                UnsignedByteType,
                UVMapping,
                ClampToEdgeWrapping,
                ClampToEdgeWrapping,
                LinearFilter,
                LinearMipmapLinearFilter,
                8,
            );
            tex.generateMipmaps = true;
            tex.needsUpdate = true;

        } else if (image.loadPath) {
            tex = this.texturesLoader.get(image.loadPath, DiffuseDefaultPixel);
        } else {
            this.logger.error('texture has not asset path or raw inline data');
            tex = undefined;
        }
        this._threeTextures.set(id, tex);
        return tex;
    }
}
