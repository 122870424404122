// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { PropertiesGroupT } from './properties-group.js';
import { PropertiesGroup } from './properties-group.js';


export class ConfigObject implements flatbuffers.IUnpackableObject<ConfigObjectT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ConfigObject {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsConfigObject(bb:flatbuffers.ByteBuffer, obj?:ConfigObject):ConfigObject {
  return (obj || new ConfigObject()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsConfigObject(bb:flatbuffers.ByteBuffer, obj?:ConfigObject):ConfigObject {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ConfigObject()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

typeIdentifier():string|null
typeIdentifier(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
typeIdentifier(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

connectedTo():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

properties(obj?:PropertiesGroup):PropertiesGroup|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new PropertiesGroup()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

props(obj?:PropertiesGroup):PropertiesGroup|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new PropertiesGroup()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startConfigObject(builder:flatbuffers.Builder) {
  builder.startObject(4);
}

static addTypeIdentifier(builder:flatbuffers.Builder, typeIdentifierOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, typeIdentifierOffset, 0);
}

static addConnectedTo(builder:flatbuffers.Builder, connectedTo:number) {
  builder.addFieldInt32(1, connectedTo, 0);
}

static addProperties(builder:flatbuffers.Builder, propertiesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, propertiesOffset, 0);
}

static addProps(builder:flatbuffers.Builder, propsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, propsOffset, 0);
}

static endConfigObject(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): ConfigObjectT {
  return new ConfigObjectT(
    this.typeIdentifier(),
    this.connectedTo(),
    (this.properties() !== null ? this.properties()!.unpack() : null),
    (this.props() !== null ? this.props()!.unpack() : null)
  );
}


unpackTo(_o: ConfigObjectT): void {
  _o.typeIdentifier = this.typeIdentifier();
  _o.connectedTo = this.connectedTo();
  _o.properties = (this.properties() !== null ? this.properties()!.unpack() : null);
  _o.props = (this.props() !== null ? this.props()!.unpack() : null);
}
}

export class ConfigObjectT implements flatbuffers.IGeneratedObject {
constructor(
  public typeIdentifier: string|Uint8Array|null = null,
  public connectedTo: number = 0,
  public properties: PropertiesGroupT|null = null,
  public props: PropertiesGroupT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const typeIdentifier = (this.typeIdentifier !== null ? builder.createString(this.typeIdentifier!) : 0);
  const properties = (this.properties !== null ? this.properties!.pack(builder) : 0);
  const props = (this.props !== null ? this.props!.pack(builder) : 0);

  ConfigObject.startConfigObject(builder);
  ConfigObject.addTypeIdentifier(builder, typeIdentifier);
  ConfigObject.addConnectedTo(builder, this.connectedTo);
  ConfigObject.addProperties(builder, properties);
  ConfigObject.addProps(builder, props);

  return ConfigObject.endConfigObject(builder);
}
}
