import type { BasicPropertyValue } from "src/properties/BasicPropsView";
import { PropertyViewBasicTypes } from "src/properties/BasicPropsView";
import { CustomPropsRegistry } from "src/properties/CustomPropsRegistry";
import type { PropsGroupField, PropValueTotalHash } from "src/properties/Props";
import { PropertyBase } from "src/properties/Props";
import { getInRowIndex, getRowIndex, type InRowPositionPacked } from "src/trackers/rows/IndicesWithPositionPacker";


export class TrackerRowIndexAndPositionsProperty extends PropertyBase {
    public readonly value: InRowPositionPacked;

    constructor(args: Partial<TrackerRowIndexAndPositionsProperty>) {
        super();
        if (args.value) {
            this.value = args.value;
        } else {
            throw new Error(`value must be provided`);
        }
        
        Object.freeze(this);
    }

    equals(other: PropsGroupField): boolean {
        if (!(other instanceof TrackerRowIndexAndPositionsProperty)) {
            return false;
        }
        return Object.is(this.value, other.value);
    }

    hash(): number | string {
        return this.value;
    }

    uniqueValueHash(): PropValueTotalHash {
        return `${this.value}`;
    }
}
CustomPropsRegistry.register({
    class: TrackerRowIndexAndPositionsProperty as any,
    constructFromPartial: TrackerRowIndexAndPositionsProperty,
    basicTypesView: {
        basicTypes: PropertyViewBasicTypes.Numeric,
        toBasicValues(_, property, __): BasicPropertyValue|undefined {
            return { value: getRowIndex(property.value) + "." + getInRowIndex(property.value) };
        },
    }
});