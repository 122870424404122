import type { ContoursOrientation, Matrix4, Vector2, Vector3 } from "math-ts";
import { PointsInPolygonChecker, Segment2 } from "math-ts";
import type { Points2D } from "../geometries/ExtrudedPolygonGeometries";
import type { IdBimScene } from "../scene/SceneInstances";
import type { Bim, IdInEntityLocal, LocalIdsEdge, SegmentInterp} from "..";
import { BimPatch, GraphGeometry, LocalIdsCounter, SegmentInterpLinear } from "..";
import { IterUtils } from "engine-utils-ts";


export class BoundaryGeometry {
    constructor (
        readonly polyline: Points2D,
        readonly worldMatrix: Matrix4,
        readonly isInclude: boolean
    ) {}
}


export function unpackBoundariesForRoadsTrim(boundaries: BoundaryGeometry[]): {
    boundarySegments: Segment2[], pointsInBoundaryChecker: PointsInPolygonChecker
} {
    const includePolylines: Vector2[][] = [];
    const excludePolylines: Vector2[][] = [];
    const boundarySegments: Segment2[] = [];

    for (const boundary of boundaries) {
        let polyline = boundary.polyline.points.map(p => { 
            const transformed = p.clone(); 
            transformed.applyMatrix4(boundary.worldMatrix); 
            return transformed; 
        });

        boundarySegments.push(new Segment2(polyline[polyline.length - 1], polyline[0]));
        for (let i = 1; i < polyline.length; ++i) {
            boundarySegments.push(new Segment2(polyline[i - 1], polyline[i]));
        }

        if (boundary.isInclude) {
            includePolylines.push(polyline);
        } else {
            excludePolylines.push(polyline);
        }
    }

    const pointsInBoundaryChecker = new PointsInPolygonChecker(includePolylines, excludePolylines);

    return { boundarySegments, pointsInBoundaryChecker };
}


export function unpackContoursForRoadsTrim(contours: Vector2[][], contoursOrientation: ContoursOrientation): {
    contoursSegments: Segment2[], pointsInContourChecker: PointsInPolygonChecker
} {
    const polylines: Vector2[][] = [];
    const contoursSegments: Segment2[] = [];

    for (const polyline of contours) {
        contoursSegments.push(new Segment2(polyline[polyline.length - 1], polyline[0]));
        for (let i = 1; i < polyline.length; ++i) {
            contoursSegments.push(new Segment2(polyline[i - 1], polyline[i]));
        }

        polylines.push(polyline);
    }

    const pointsInContourChecker = PointsInPolygonChecker.fromContoursOrientation(polylines, contoursOrientation);

    return { contoursSegments, pointsInContourChecker };
}


export function calculateRoadsBoundaryTrim(
    roadsIds: IdBimScene[],
    boundariesIds: IdBimScene[],
    bim: Bim
): IdBimScene[] {
    const boundaries = bim.instances.peekByIds(boundariesIds);
    const boundariesGeometries: BoundaryGeometry[] = [];
    for (const boundary of boundaries) {
        const boundaryGeoId = boundary[1].representationAnalytical?.geometryId;
        if (boundaryGeoId === undefined) {
            continue;
        }

        const boundaryGeo = bim.extrudedPolygonGeometries.peekById(boundaryGeoId)?.outerShell;
        if (boundaryGeo === undefined) {
            continue;
        }

        boundariesGeometries.push(new BoundaryGeometry(
            boundaryGeo,
            boundary[1].worldMatrix,
            boundary[1].properties.get('boundary | boundary_type')?.value !== 'exclude'
        ));
    }
    const { boundarySegments, pointsInBoundaryChecker } = unpackBoundariesForRoadsTrim(boundariesGeometries);

    const bimPatch = new BimPatch();
    const trimmedRoadsIds = new Set(roadsIds);
    const roads = bim.instances.peekByIds(roadsIds);
    for (const [id, inst] of roads) {
        const roadGeoId = inst.representationAnalytical?.geometryId;
        if (roadGeoId === undefined) {
            continue;
        }

        const roadGeometry = bim.graphGeometries.peekById(roadGeoId);
        if (roadGeometry === undefined) {
            continue;
        }

        const trimmedRoads = trimRoadByBoundary(roadGeometry, inst.worldMatrix, boundarySegments, pointsInBoundaryChecker);
        if (trimmedRoads === false) {
            trimmedRoadsIds.delete(id);
            bimPatch.instances.toDelete.push(id);
        } else if (trimmedRoads !== true) {
            bimPatch.geometries.toPatch.push([roadGeoId, trimmedRoads]);
        }
    }

    bimPatch.applyTo(bim);

    return Array.from(trimmedRoadsIds);
}

export function trimRoadByBoundary(
    roadGeo: GraphGeometry,
    roadTransform: Matrix4,
    boundary: Segment2[],
    pointsInBoundaryChecker: PointsInPolygonChecker
): GraphGeometry | boolean {

    const pointsTransformed: Map<IdInEntityLocal, Vector3> = new Map(
        IterUtils.mapIter(roadGeo.points, p => {
            const v3 = p[1].clone();
            v3.applyMatrix4(roadTransform);
            return [p[0], v3];
        })
    );

    // edgesIntersections = [t_i] defines points of the form a + t_i * (b - a)
    const edgesIntersections = new Map<LocalIdsEdge, number[]>();

    let intersectionsExists = false;
    for (const edge of roadGeo.edges) {
        const edgeIntersections: number[] = [];

        const endPointsIds = LocalIdsCounter.edgeToTuple(edge[0]);

        const segment2d = new Segment2(pointsTransformed.get(endPointsIds[0])!.xy(), pointsTransformed.get(endPointsIds[1])!.xy());
        for (const boundarySegment of boundary) {
            const t = segment2d.intersectSegment(boundarySegment);
            if (t !== null) {
                edgeIntersections.push(t);
                intersectionsExists = true;
            }
        }

        edgesIntersections.set(edge[0], edgeIntersections.sort((a, b) => a - b));
    }

    if (!intersectionsExists) {
        return pointsInBoundaryChecker.isPointInside(pointsTransformed.values().next().value);
    }

    const pointId = new LocalIdsCounter();
    const points: Map<IdInEntityLocal, Vector3> = new Map();
    const edges: Map<LocalIdsEdge, SegmentInterp> = new Map();
    const pointsIdToNewIds: Map<IdInEntityLocal, IdInEntityLocal> = new Map();

    for (const edge of edgesIntersections) {
        const endPointsIds = LocalIdsCounter.edgeToTuple(edge[0]);
        const segment3d = [pointsTransformed.get(endPointsIds[0])!, pointsTransformed.get(endPointsIds[1])!];

        if (edge[1].length === 0) {
            const centralPoint = segment3d[0].clone().add(segment3d[1]).divideScalar(2).xy();

            if (pointsInBoundaryChecker.isPointInside(centralPoint)) {
                let newEndPointIds = [pointsIdToNewIds.get(endPointsIds[0]), pointsIdToNewIds.get(endPointsIds[1])];
                if (newEndPointIds[0] === undefined) {
                    newEndPointIds[0] = pointId.nextId();
                    pointsIdToNewIds.set(endPointsIds[0], newEndPointIds[0]);
                    points.set(newEndPointIds[0], pointsTransformed.get(endPointsIds[0])!);
                }
                if (newEndPointIds[1] === undefined) {
                    newEndPointIds[1] = pointId.nextId();
                    pointsIdToNewIds.set(endPointsIds[1], newEndPointIds[1]);
                    points.set(newEndPointIds[1], pointsTransformed.get(endPointsIds[1])!);
                }
                edges.set(LocalIdsCounter.newEdge(newEndPointIds[0], newEndPointIds[1]), new SegmentInterpLinear());
            }
        } else {
            let subSegment3d = [
                segment3d[0],
                segment3d[0].clone().add(segment3d[1].clone().sub(segment3d[0]).multiplyScalar(edge[1][0]))
            ];
            let centralPoint = subSegment3d[0].clone().add(subSegment3d[1]).divideScalar(2).xy();

            if (pointsInBoundaryChecker.isPointInside(centralPoint)) {
                let newEndPointIds = [pointsIdToNewIds.get(endPointsIds[0]), pointId.nextId()];
                if (newEndPointIds[0] === undefined) {
                    newEndPointIds[0] = pointId.nextId();
                    pointsIdToNewIds.set(endPointsIds[0], newEndPointIds[0]);
                    points.set(newEndPointIds[0], pointsTransformed.get(endPointsIds[0])!);
                }
                points.set(newEndPointIds[1]!, subSegment3d[1]);
                edges.set(LocalIdsCounter.newEdge(newEndPointIds[0], newEndPointIds[1]!), new SegmentInterpLinear());
            }

            for (let i = 0; i < edge[1].length - 1; ++i) {
                subSegment3d = [
                    segment3d[0].clone().add(segment3d[1].clone().sub(segment3d[0]).multiplyScalar(edge[1][i])),
                    segment3d[0].clone().add(segment3d[1].clone().sub(segment3d[0]).multiplyScalar(edge[1][i+1])),
                ];
                centralPoint = subSegment3d[0].clone().add(subSegment3d[1]).divideScalar(2).xy();

                if (pointsInBoundaryChecker.isPointInside(centralPoint)) {
                    let newEndPointIds = [pointId.nextId(), pointId.nextId()];
                    points.set(newEndPointIds[0]!, subSegment3d[0]);
                    points.set(newEndPointIds[1]!, subSegment3d[1]);
                    edges.set(LocalIdsCounter.newEdge(newEndPointIds[0]!, newEndPointIds[1]!), new SegmentInterpLinear());
                }
            }

            subSegment3d = [
                segment3d[0].clone().add(segment3d[1].clone().sub(segment3d[0]).multiplyScalar(edge[1][edge[1].length - 1])),
                segment3d[1]
            ];
            centralPoint = subSegment3d[0].clone().add(subSegment3d[1]).divideScalar(2).xy();

            if (pointsInBoundaryChecker.isPointInside(centralPoint)) {
                let newEndPointIds = [pointId.nextId(), pointsIdToNewIds.get(endPointsIds[1])];
                points.set(newEndPointIds[0]!, subSegment3d[0]);
                if (newEndPointIds[1] === undefined) {
                    newEndPointIds[1] = pointId.nextId();
                    pointsIdToNewIds.set(endPointsIds[1], newEndPointIds[1]);
                    points.set(newEndPointIds[1], pointsTransformed.get(endPointsIds[1])!);
                }
                edges.set(LocalIdsCounter.newEdge(newEndPointIds[0]!, newEndPointIds[1]), new SegmentInterpLinear());
            }
        }
    }

    const inverseTransform = roadTransform.clone().invert();
    for (const [id, p] of points) {
        let v3 = p.clone();
        v3.applyMatrix4(inverseTransform);
        points.set(id, v3);
    }

    return new GraphGeometry(points, edges);
}