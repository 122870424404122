<script lang="ts">
import type { Catalog, CatalogItemId, CatalogItemIdType } from "bim-ts";
import { getContext } from "svelte";
import { PUI_PropertyNodeMultiSelector, PUI_PropertyNodeString, type MultiSelectorValue } from "ui-bindings";
import PropertyView from "../../PropertyView.svelte";
import type { ModuleFrameMatchingContext } from "./FrameModuleMatcher";

const catalog = getContext<Catalog>('catalog');

export let matchingFrames: CatalogItemIdType[] = [];
export let matchingContext: ModuleFrameMatchingContext;
export let selectedModule: CatalogItemIdType | undefined;
export let initialModuleCatalogItemId: CatalogItemIdType | undefined;

let allModules: CatalogItemIdType[] = [];


let moduleManufacturerFilter: string | undefined;
$: moduleManufacturerValue = !moduleManufacturerFilter
    ? undefined
    : ({ value: moduleManufacturerFilter, label: moduleManufacturerFilter } as MultiSelectorValue);
let moduleManufacturerOptions: MultiSelectorValue[] = [];
function updateModuleManufacturer(val: MultiSelectorValue[]) {
    moduleManufacturerFilter = val.at(0)?.value.toString() || undefined;
}
$: selectModuleManufacturerNode = new PUI_PropertyNodeMultiSelector({
    name: 'Module Manufacturer',
    onChange: updateModuleManufacturer,
    options: moduleManufacturerOptions,
    value: moduleManufacturerValue ? [moduleManufacturerValue] : [],
    maxSelect: 1,
})

$: {
    const availableModuleManufacturers = new Set<string>();
    for (const moduleId of allModules) {
        const moduleVariation = matchingContext.modules.get(moduleId);
        if (moduleVariation) {
            availableModuleManufacturers.add(moduleVariation.moduleManufacturer)
        }
    }

    const moduleManufacturersMultiselectorOptions: MultiSelectorValue[] = []
    for (const manufacturer of availableModuleManufacturers) {
        moduleManufacturersMultiselectorOptions.push({
            value: manufacturer,
            label: manufacturer,
        })
    }

    moduleManufacturerOptions = moduleManufacturersMultiselectorOptions;
}


let selectModuleCatalogItemId: CatalogItemId | undefined;
let selectModuleValue: MultiSelectorValue | undefined;
let selectModuleOptions: MultiSelectorValue[] = [];

$: {
	const availableModules = new Set<CatalogItemIdType>();
	for (const frameId of matchingFrames) {
		const moduleIds = matchingContext.modulesPerFrame.get(frameId)
        for (const item of moduleIds ?? []) {
            availableModules.add(item);
        }
	}
    allModules = [...availableModules];
}


// update module options/validate module value
$: {
    const options: MultiSelectorValue[] = [];
    for (const moduleId of allModules) {
        const variation = matchingContext.modules.get(moduleId);
        if (!variation) {
            continue;
        }
        if (
            moduleManufacturerFilter &&
            moduleManufacturerFilter !== variation.moduleManufacturer
        ) {
            continue;
        }
        const catalogItem = catalog.catalogItems.peekById(moduleId);
        if (!catalogItem) {
            continue;
        }
        const label = catalog.catalogItemsUiLabels.solve(
            catalogItem.typeIdentifier,
            catalogItem.properties
        )?.title;
        if (!label) {
            continue;
        }
        options.push({
            value: moduleId,
            label,
        });
    }
    selectModuleOptions = options;
}

$: {
    selectModuleCatalogItemId = initialModuleCatalogItemId;
    if (initialModuleCatalogItemId) {
        const variation = matchingContext.modules.get(initialModuleCatalogItemId);
        moduleManufacturerFilter = variation?.moduleManufacturer;
    } else {
        moduleManufacturerFilter = undefined;
    }
}
$: {
    selectModuleValue = selectModuleOptions.find(x => x.value === selectModuleCatalogItemId)
}


function updateSelectModule(val: MultiSelectorValue[]) {
    selectModuleCatalogItemId = val.at(0)?.value as CatalogItemId;
}
$: selectModuleNode = new PUI_PropertyNodeMultiSelector({
    name: 'Module',
    onChange: updateSelectModule,
    options: selectModuleOptions,
    value: selectModuleValue ? [selectModuleValue] : [],
    maxSelect: 1,
})



// update selected module catalog item id
$: {
    const tmp = selectModuleValue?.value
    selectedModule = typeof tmp === 'number' ? tmp : undefined;
}

</script>

{#if moduleManufacturerOptions.length === 1}
    <PropertyView
        offset={0}
        property={new PUI_PropertyNodeString({
            name: 'Module Manufacturer',
            onChange: () => {},
            value: moduleManufacturerOptions[0].value.toString(),
            readonly: true,
        })}
    />
{:else if moduleManufacturerOptions.length > 1}
    <PropertyView offset={0} property={selectModuleManufacturerNode} />
{/if}

{#if matchingFrames.length}
    <PropertyView offset={0} property={selectModuleNode} />
{/if}

<style lang="scss">
</style>
