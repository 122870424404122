import { LegacyLogger } from 'engine-utils-ts';
import type { Aabb, Matrix4} from 'math-ts';
import { Plane, Vector3 } from 'math-ts';

import type { Frustum } from '../3rdParty/three';
import type { KrCamera } from '../controls/MovementControls';
import { IntersectionType } from '../geometries/GeometryUtils';
import { MathUtils } from '../MathUtils';
import type { RectPortion } from './RectPortion';

export class KrFrustum {
	readonly planes: Plane[] = [
		Plane.allocateZero(), // right
		Plane.allocateZero(), // left
		Plane.allocateZero(), // bottom
		Plane.allocateZero(), // top
		Plane.allocateZero(), // near
		Plane.allocateZero(), // far
	];


	copy(other: KrFrustum) {
		for (let i = 0; i < 6; ++i){
			this.planes[i].copy(other.planes[i]);
		}
	}

	roughlyEquals(other: KrFrustum) {
		for (let i = 0; i < 6; ++i){
			if (!this.planes[i].roughlyEquals(other.planes[i])) {
				return false;
			}
		}
		return true;
	}

	clone() {
		const newFrustum = new KrFrustum();
		for (let i = 0; i < 6; ++i){
			newFrustum.planes[i].copy(this.planes[i]);
		}
		return newFrustum;
	}

	copyFromThree(frustum: Frustum): void {
		for (let i = 0; i < 6; ++i){
			const p = this.planes[i];
			const threePlane = frustum.planes[i];
			p.normal.copy(threePlane.normal);
			p.constant = threePlane.constant
		}
	}

	containsPoint(point: Vector3): boolean {
		for (const p of this.planes) {
			const distance = p.distanceToPoint(point);
			if (distance < 0 || isNaN(distance)) {
				return false;
			}
		}
		return true;
	}

	setFromBox(box: Aabb) {
		LegacyLogger.assert(!box.isEmpty(), 'frust.setFromBox not empty');

		const center = box.getCenter_t();
		const v = new Vector3();

		for (let i = 0; i < 6; ++i){
			const compInd = i % 3;
			v.copy(center).setComponent(compInd, box.elements[i]);
			const plane = this.planes[i];
			plane.normal.copy(center).sub(v).normalize();
			plane.setFromNormalAndCoplanarPoint(plane.normal, v);
		}
		
		return this;
	}

	setFromMatrix(m: Matrix4) {
		const planes = this.planes;
		const me = m.elements;
		const xx = me[ 0 ],  xy = me[ 1 ],  xz = me[ 2 ],  xw = me[ 3 ];
		const yx = me[ 4 ],  yy = me[ 5 ],  yz = me[ 6 ],  yw = me[ 7 ];
		const zx = me[ 8 ],  zy = me[ 9 ],  zz = me[ 10 ], zw = me[ 11 ];
		const wx = me[ 12 ], wy = me[ 13 ], wz = me[ 14 ], ww = me[ 15 ];

		planes[0].setComponents( xw - xx, yw - yx, zw - zx, ww - wx ).normalize();
		planes[1].setComponents( xw + xx, yw + yx, zw + zx, ww + wx ).normalize();
		
		planes[2].setComponents( xw + xy, yw + yy, zw + zy, ww + wy ).normalize();
		planes[3].setComponents( xw - xy, yw - yy, zw - zy, ww - wy ).normalize();
		
		planes[4].setComponents( xw - xz, yw - yz, zw - zz, ww - wz ).normalize();
		planes[5].setComponents( xw + xz, yw + yz, zw + zz, ww + wz ).normalize();

		return this;
	}

	setFromCamera(camera: KrCamera) {
		// this.mvpMatrix.copy(this.matrixWorldInverse).premultiply(this.projectionMatrix);
		const mvp = camera.matrixWorldInverse.clone().premultiply(camera.projectionMatrix);
		this.setFromMatrix(mvp);
		return this;
	}

	setFromCameraSubFrustum(camera: KrCamera, portion: RectPortion) {
		const m = MathUtils.makeSubFrustumProjectionMatrix(camera, portion);
		m.multiply(camera.matrixWorldInverse as any);
		this.setFromMatrix(m as any);
		return this;
	}

	applyMatrixToPlanes(m: Matrix4) {
		for (const p of this.planes) {
			p.applyMatrix4(m);
		}
		return this;
	}

	intersects_box(box: Aabb): boolean {
		return this.intersect_box(box) != IntersectionType.Outside;
	}

	intersect_box(box: Aabb): IntersectionType {
		let res = IntersectionType.Full;

		const boxElements = box.elements;

		for (const plane of this.planes) {
			const planeNormal = plane.normal;
	
			let dotMin, dotMax : number;
	
			if (planeNormal.x > 0) {
				dotMin = planeNormal.x * boxElements[0];
				dotMax = planeNormal.x * boxElements[3];
			} else {
				dotMin = planeNormal.x * boxElements[3];
				dotMax = planeNormal.x * boxElements[0];
			}
	
			if (planeNormal.y > 0) {
				dotMin += planeNormal.y * boxElements[1];
				dotMax += planeNormal.y * boxElements[4];
			} else {
				dotMin += planeNormal.y * boxElements[4];
				dotMax += planeNormal.y * boxElements[1];
			}
	
			if (planeNormal.z > 0) {
				dotMin += planeNormal.z * boxElements[2];
				dotMax += planeNormal.z * boxElements[5];
			} else {
				dotMin += planeNormal.z * boxElements[5];
				dotMax += planeNormal.z * boxElements[2];
			}
			
			if (dotMax + plane.constant < 0) {
				return IntersectionType.Outside; 
			}
			if (dotMin + plane.constant < 0) {
				res = IntersectionType.Partial;
			}
		}
		return res;
	}
}

