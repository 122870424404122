import type { LocalIdsCounter } from "bim-ts";
import { ExtrudedPolygonGeometry } from "bim-ts";
import { IterUtils } from "engine-utils-ts";
import type { Transform} from "math-ts";
import { Matrix4, Vector3 } from "math-ts";
import { InObjFullId, InObjIdType, InObjLocalId } from "../scene/EngineSceneIds";
import type { ESOHandle } from "./ESOsCollection";
import type { GeometryCtor} from "../scene/GeometryEditsHandler";
import { GeometryEditsHandler } from "../scene/GeometryEditsHandler";
import { GizmoEditUtils } from "../scene/GizmoEditUtils";
import type { ESO_Any } from "../esos/ESO";
import type { ESSO_Any, ESSO_InteractiveAny } from "../esos/ESSO";
import { ESSO_EditPoint } from "../esos/ESSO_EditPoint";

export class ESSO_EditPointPolygon extends ESSO_EditPoint<number> {

}


export class PolygonGeometryEditsHandler extends GeometryEditsHandler<ExtrudedPolygonGeometry> {

	geometryCtor(): GeometryCtor {
		return ExtrudedPolygonGeometry;
	}

	createEditReprImpl(handle: ESOHandle, obj: ESO_Any, geometryRef: Readonly<ExtrudedPolygonGeometry>, result: ESSO_Any[]): void {
		for (let i = 0; i < geometryRef.outerShell.points.length; ++i) {
			const p = geometryRef.outerShell.points[i];
			const localId = geometryRef.outerShell.pointsLocalIds[i];
			const point = new Vector3(p.x, p.y, geometryRef.topElevation);
			const pRepr = new ESSO_EditPointPolygon(
				obj,
				InObjFullId.new(handle, InObjLocalId.new(InObjIdType.EditPoint, localId)),
				{},
				new Matrix4().setPositionV(point),
				i
			);
			result.push(pRepr);
		}
	}

	patchPositions(
		geometryInOut: ExtrudedPolygonGeometry,
		localIdsGenerator: LocalIdsCounter,
		subobjs: ESSO_InteractiveAny[],
		positionsPatch: Map<InObjLocalId, Transform>,
	): {
	} {
		if (positionsPatch.size === 0) {
			return {toPatch: []};
		}
		
		const geoPoints = geometryInOut.outerShell.points;
		const geoPointsIds = geometryInOut.outerShell.pointsLocalIds;

		for (let i = 0; i < geoPointsIds.length; ++i) {
			const localId = InObjLocalId.new(InObjIdType.EditPoint, geoPointsIds[i]);
			const patch = positionsPatch.get(localId);
			if (patch) {
				geoPoints[i] = patch.position.xy();
			}	
		}

		const patch = IterUtils.getFirstFromIter(positionsPatch.values())!;

		if (Math.abs(patch.position.z - geometryInOut.topElevation) > 0.001) {
			geometryInOut.topElevation = patch.position.z;
			geometryInOut.baseElevation = Math.min(geometryInOut.baseElevation, geometryInOut.topElevation);
		}

		return {};
	}
	
	cloneParts(
		geometryInOut: ExtrudedPolygonGeometry,
		localIdsGenerator: LocalIdsCounter,
		subobjs: ESSO_InteractiveAny[],
		idsToClone: InObjLocalId[],
		localSpaceDirection: Vector3,
	): {
		added: InObjLocalId[],
        toUseForGesture?: InObjLocalId[],
    } {

		const geoPoints = geometryInOut.outerShell.points;
		const geoPointsIds = geometryInOut.outerShell.pointsLocalIds;

		const pointIdsToClone = IterUtils.filterMap(idsToClone, id => id.ty === InObjIdType.EditPoint ? id.localId : undefined)

		const pointsIndices = GizmoEditUtils.mapToIndicesInByProp(pointIdsToClone, geoPointsIds, id => id);
	    const segment = GizmoEditUtils.getPointsIndicesAsConsecutiveSegment(pointsIndices, geoPoints.length);

        if (segment) {
            const {indices, crossesZero} = segment;
            const firstPointIndex = indices[0];
            const secondPointIndex = indices[indices.length - 1];
            // insert new points instead of first and last points, and move the rest

            const firstPointToClone = geoPoints[firstPointIndex];
            const secondtPointToClone = geoPoints[secondPointIndex];

			let pointsToClone = [firstPointToClone, secondtPointToClone];
			let insertionPoints: [number, number];
            if (crossesZero) {
                insertionPoints = [firstPointIndex + 1, secondPointIndex + 1];
            } else {
                insertionPoints = [firstPointIndex, secondPointIndex + 2];
            }
			const idsCloned = [localIdsGenerator.nextId(), localIdsGenerator.nextId()];

			for (let i = 0; i < insertionPoints.length; ++i) {
				geoPoints.splice(insertionPoints[i], 0, pointsToClone[i].clone());
				geoPointsIds.splice(insertionPoints[i], 0, idsCloned[i]);
			}

            return {
				added: idsCloned.map(id => InObjLocalId.new(InObjIdType.EditPoint, id)), toUseForGesture: idsToClone
			};

        } else if (pointsIndices.length == 1) {
            const pointIndex = pointsIndices[0];
            if (pointIndex >= 0) {
				const pointToClone = geoPoints[pointIndex];
				const insertionIndex = GizmoEditUtils.chooseNewPointInsertionIndex({
                    pointIndex,
                    currentPoints: geoPoints.map(p => new Vector3(p.x, p.y, geometryInOut.topElevation)),
                    gestureDirection: localSpaceDirection,
                    closeFirstToLast: true
                });
				const id = localIdsGenerator.nextId();

				geoPoints.splice(insertionIndex, 0, pointToClone.clone());
				geoPointsIds.splice(insertionIndex, 0, id);

				return {added: [InObjLocalId.new(InObjIdType.EditPoint, id)]};
            }

        }
        return {added: []};
	}

	deleteParts(
		geometryInOut: ExtrudedPolygonGeometry,
		localIdsGenerator: LocalIdsCounter,
		subobjs: Readonly<ESSO_InteractiveAny>[],
		idsToDelete: InObjLocalId[],
	): {} {
		GizmoEditUtils.deleteBySeparateKeys(
			geometryInOut.outerShell.pointsLocalIds,
			geometryInOut.outerShell.points,
			IterUtils.filterMap(idsToDelete, id => id.ty === InObjIdType.EditPoint ? id.localId : undefined),
		)
		return {};
	}

}