import { type Writeable, IterUtils, DefaultMap, CompressionUtils, Yield } from 'engine-utils-ts';
import type { FileImporterContext } from 'ui-bindings';
import { type EntityIdAny, entityTypeFromId } from 'verdata-ts';
import { type Bim, type IdBimGeo, type AnyBimGeometry, EntitiesPersisted, type CubeGeometry, type ExtrudedPolygonGeometry, type GraphGeometry, type IrregularHeightmapGeometry, type PolylineGeometry, type RegularHeightmapGeometry, type TriGeometry, PropsFieldFlags } from '..';
import type { IdBimImage, BimImage } from '../BimImages';
import type { IdBimMaterial, BimMaterial } from '../BimMaterials';
import { BimPatch } from '../BimPatch';
import type { EntitiesBase } from '../collections/EntitiesBase';
import { sceneInstanceMigration } from '../persistence/migrations/SceneInstancesMigrations';
import { SceneInstanceEntitiesPersisted } from '../persistence/SceneInstanceEntitiesPersisted';
import { SceneInstanceSerializable } from '../persistence/SceneInstancesSerializer';
import type { ObjectRepresentation, BasicAnalyticalRepresentation } from '../representation/Representations';
import type { IdBimScene, SceneInstance } from '../scene/SceneInstances';
import { parseBimAssetLegacy } from './legacy/parseBimAssetLegacy';
import { BimImagesSerializer } from '../persistence/BimImagesSerializer';
import { BimMaterialsSerializer } from '../persistence/BimMaterialsSerializer';
import { TriGeosSerializer, PolylinesSerializer, CubesSerializer, RegularHeightmapsSerializer, IrregularHeightmapsSerializer, ExtrudedPolygonsSerializer, GraphsSerializer } from '../persistence/GeometriesSerializers';
import { bimImageMigration } from '../persistence/migrations/ImagesMigrations';


export function* exportToBimAsset(
    bim: Bim,
    instancesIdsToSerialize: IdBimScene[],
    exportWithOrigin: boolean
): Generator<Yield, Uint8Array, unknown> {

    if (instancesIdsToSerialize.length === 0) {
        throw new Error('Nothing to serialize');
    }

    // TODO: replace with explicit parameter
    const propsCloneFlags = instancesIdsToSerialize.length == 1 ?
        PropsFieldFlags.SkipSerialization | PropsFieldFlags.SkipClone
        : PropsFieldFlags.SkipSerialization;

    const extractSerializableDataFromInstance = (self: SceneInstance): SceneInstanceSerializable => {
        return new SceneInstanceSerializable(
            self.type_identifier,
            self.name,
            self.colorTint,
            self.localTransform,
            self.properties,
            self.representation?.isRuntimeGenerated() ? null : self.representation,
            self.representationAnalytical?.isRuntimeGenerated() ? null : self.representationAnalytical,
            self.spatialParentId,
            self.electricalParentId,
            0,
            null,
            self.props.cloneWithoutFlags(propsCloneFlags),
        )
    }

    const instancesPersisted = new SceneInstanceEntitiesPersisted(
        bim.instances,
        sceneInstanceMigration,
        bim.instances.archetypes,
        extractSerializableDataFromInstance,
        SceneInstanceSerializable.toPartialFull,
        bim.reactiveRuntimes,
        bim.customRuntimes
    );

    const instancesToSerialize = instancesPersisted.getObjects(instancesIdsToSerialize);

    const dependentGeoIds: EntityIdAny[] = [];
    const dependentMatIds: EntityIdAny[] = [];
    const dependentImgIds: EntityIdAny[] = [];

    const instancesIdsToSerializeSet = new Set(instancesIdsToSerialize);
    for (const [_, inst] of instancesToSerialize) {
        if (inst.representation) {
            inst.representation.geometriesIdsReferences(dependentGeoIds);
            inst.representation.materialIdsReferences(dependentMatIds);
            inst.representation.imagesReferenced(dependentImgIds);
        }
        if (inst.representationAnalytical) {
            inst.representationAnalytical.geometriesIdsReferences(dependentGeoIds);
            inst.representationAnalytical.materialIdsReferences(dependentMatIds);
            inst.representationAnalytical.imagesReferenced(dependentImgIds);
        }
        if (inst.spatialParentId && !instancesIdsToSerializeSet.has(inst.spatialParentId)) {
            (inst as Writeable<SceneInstanceSerializable>).spatialParentId = 0;
        }
    }
    yield Yield.Asap;

    IterUtils.sortDedupNumbers(dependentGeoIds);
    IterUtils.sortDedupNumbers(dependentMatIds);
    IterUtils.sortDedupNumbers(dependentImgIds);

    const perBaseCollectionIdsToExport = new DefaultMap<EntitiesBase<any, any>, EntityIdAny[]>(() => []);

    for (const geoId of dependentGeoIds) {
        const geometriesCollection = bim.allBimGeometries.getCollectinoOfId(geoId);
        if (!geometriesCollection) {
            throw new Error(`geometry collection not found for id: ${geoId}`);
        }
        perBaseCollectionIdsToExport.getOrCreate(geometriesCollection).push(geoId);
    }
    if (dependentImgIds.length > 0) {
        perBaseCollectionIdsToExport.getOrCreate(bim.bimImages).push(...dependentImgIds);
    }
    if (dependentMatIds.length > 0) {
        perBaseCollectionIdsToExport.getOrCreate(bim.bimMaterials).push(...dependentMatIds);
    }

    yield Yield.Asap;


    const result: { name: string, file: Uint8Array }[] = [];

    const collectionsToSerialize = createBasicBimCollectionsPersistedCollections(bim);

    for (const [collection, ids] of perBaseCollectionIdsToExport) {
        const persistedCollection = collectionsToSerialize.get(collection.identifier);
        if (!persistedCollection) {
            throw new Error(`persisted collection not found for: ${collection.identifier}`);
        }
        const serializer = persistedCollection.getObjectsPickingSerializer();
        const objects = persistedCollection.getObjects(ids);
        const serialized = serializer.serialize(objects);

        result.push({
            name: collection.identifier,
            file: serialized
        });

        yield Yield.Asap;

    }

    const instancesSerialized = instancesPersisted.getObjectsPickingSerializer().serialize(instancesToSerialize);
    result.push({
        name: bim.instances.identifier,
        file: instancesSerialized
    });

    
    let contextSerialized: Uint8Array | undefined;
    if (exportWithOrigin) {
        // Only origin data expected to be in instances context
        const context = instancesPersisted.additionalContext!.poll()!;
        const serializer = instancesPersisted.getContextSerializer()!;
        contextSerialized = serializer.serialize(context);
        result.push({name: 'instances-context', file: contextSerialized});
    } else {
        contextSerialized = undefined;
    }


    result.push({
        name: 'bim-asset-metadata',
        file: new Uint8Array([1])
    });

    yield Yield.Asap;

    const zip = CompressionUtils.zip(result, {level: 1});

    return zip;
}


export function* convertBimAssetToBimPatch(
    bimAsset: Uint8Array,
    bim: Bim,
    context?: FileImporterContext,
): Generator<Yield, BimPatch, unknown> {

    const unzipped = CompressionUtils.unzip(bimAsset);
    yield Yield.Asap;
    if (!unzipped.has('bim-asset-metadata')) {
        return yield* parseBimAssetLegacy(unzipped, bim, context);
    }
    const resultBimPatch = new BimPatch();

    const bim_asset_metadata = unzipped.get('bim-asset-metadata')!;

    const instancesPersisted = new SceneInstanceEntitiesPersisted(
        bim.instances,
        sceneInstanceMigration,
        bim.instances.archetypes,
        SceneInstanceSerializable.fromFull,
        SceneInstanceSerializable.toPartialFull,
        bim.reactiveRuntimes,
        bim.customRuntimes
    );
    
    const instancesSerializer = instancesPersisted.getObjectsPickingSerializer();
    const instancesContextSerializer = instancesPersisted.getContextSerializer()!;

    const instancesSerialized = unzipped.get(bim.instances.identifier)!;
    const deserializedInstances = instancesSerializer.deserialize(instancesSerialized, {has: () => true});

    const contextSerialized = unzipped.get('instances-context');
    if (contextSerialized) {
        const deserializedInstancesContext = instancesContextSerializer.deserialize(contextSerialized);
        resultBimPatch.sceneOrigin.cartesianCoordsOrigin = deserializedInstancesContext?.civilCoordsOrigin;
        resultBimPatch.sceneOrigin.wgsProjectionOrigin = deserializedInstancesContext?.projectionOrigin;
    }

    const instancesIdsRemap = new DefaultMap<number, IdBimScene>(() => bim.instances.idsProvider.reserveNewId());
    const materialsIdsRemap = new DefaultMap<number, IdBimMaterial>(() => bim.bimMaterials.idsProvider.reserveNewId());
    const imagesIdsRemap = new DefaultMap<number, IdBimImage>(() => bim.bimImages.idsProvider.reserveNewId());
    const geometriesIdsRemap = new DefaultMap<number, IdBimGeo>((souceId: number) => {
        const collectionForId = bim.allBimGeometries.getCollectinoOfId(souceId);
        if (!collectionForId) {
            console.error(`no collection for geometry id`, souceId);
            return 0;
        }
        return collectionForId.idsProvider.reserveNewId();
    });

    function remapRepresentationIds<R extends ObjectRepresentation|BasicAnalyticalRepresentation>(repr: R): R {
        return repr.withRemappedIds(geometriesIdsRemap, materialsIdsRemap, imagesIdsRemap) as R;
    }


    for (const [id, instance] of deserializedInstances) {
        if (!instance) {
            console.error(`unexpectedly no instance for id`, id);
            continue;
        }
        if (instance.spatialParentId) {
            const newParentId = instancesIdsRemap.getOrCreate(instance.spatialParentId);
            instance.spatialParentId = newParentId;
        }
        if (instance.representation) {
            instance.representation = remapRepresentationIds(instance.representation);
        }
        if (instance.representationAnalytical) {
            instance.representationAnalytical = remapRepresentationIds(instance.representationAnalytical);
        }

        resultBimPatch.instances.toAlloc.push([
            instancesIdsRemap.getOrCreate(id),
            SceneInstanceSerializable.toPartialFull(instance),
        ]);
    }

    yield Yield.Asap;

    const basicBimCollections = createBasicBimCollectionsPersistedCollections(bim);


    if (materialsIdsRemap.size > 0) {
        const materialsFile = unzipped.get(bim.bimMaterials.identifier);
        if (!materialsFile) {
            console.error(`no materials file found in asset`);
        } else {
            const materialsDeserializer = basicBimCollections.get(bim.bimMaterials.identifier)!.getObjectsPickingSerializer();
            const deserialized = materialsDeserializer.deserialize(materialsFile, new Set(materialsIdsRemap.keys()));
            const remapped = deserialized.map(([id, material]) => [materialsIdsRemap.get(id)!, material] as [IdBimMaterial, Partial<BimMaterial>]);
            resultBimPatch.materials.toAlloc.push(...remapped);
        }
    }
    if (imagesIdsRemap.size > 0) {
        const imagesFile = unzipped.get(bim.bimImages.identifier);
        if (!imagesFile) {
            console.error(`no images file found in asset`);
        } else {
            const imagesDeserializer = basicBimCollections.get(bim.bimImages.identifier)!.getObjectsPickingSerializer();
            const deserialized = imagesDeserializer.deserialize(imagesFile, new Set(imagesIdsRemap.keys()));
            const remapped = deserialized.map(([id, image]) => [imagesIdsRemap.get(id)!, image] as [IdBimImage, Partial<BimImage>]);
            resultBimPatch.images.toAlloc.push(...remapped);
        }
    }
    yield Yield.Asap;

    if (geometriesIdsRemap.size > 0) {
        const assetGeoIdsGroupedByType = IterUtils.groupBy(geometriesIdsRemap.keys(), id => entityTypeFromId(id));
        for (const [type, ids] of assetGeoIdsGroupedByType) {
            const geometriesCollection = bim.allBimGeometries.getCollectionByType(type);
            if (!geometriesCollection) {
                console.error(`no geometries collection found in bim for id type ${type}`);
                continue;
            }
            const geometriesFile = unzipped.get(geometriesCollection.identifier);
            if (!geometriesFile) {
                console.error(`no geometries file found in asset for type ${type}`);
                continue;
            }
            const geometriesDeserializer = basicBimCollections.get(geometriesCollection.identifier)!.getObjectsPickingSerializer();
            const deserialized = geometriesDeserializer.deserialize(geometriesFile, new Set(ids));
            const remapped = deserialized.map(([id, geometry]) => [geometriesIdsRemap.get(id)!, geometry] as [IdBimGeo, AnyBimGeometry]);
            resultBimPatch.geometries.toAlloc.push(...remapped);
        }
    }

    return resultBimPatch;
}

export function* importBimassetToBim(
    bimAsset: Uint8Array,
    bim: Bim,
    context?: FileImporterContext,
) {
    const logger = context?.logger;
    const bimPatch = yield* convertBimAssetToBimPatch(bimAsset, bim, context);

    const allocatedIds = bimPatch.applyTo(bim);

    return allocatedIds;
}



function createBasicBimCollectionsPersistedCollections(bim: Bim): Map<string, EntitiesPersisted<any, any>> {
    const collectionsToSerialize: EntitiesPersisted<any, any>[] = [];

    collectionsToSerialize.push(new EntitiesPersisted<BimImage>({
        entities: bim.bimImages,
        serializer: new BimImagesSerializer(bimImageMigration),
    }));
    collectionsToSerialize.push( new EntitiesPersisted<BimMaterial>({
        entities: bim.bimMaterials,
        serializer: new BimMaterialsSerializer(),
    }));
    collectionsToSerialize.push(new EntitiesPersisted<TriGeometry>({
        entities: bim.triGeometries,
        serializer: new TriGeosSerializer(),
    }));
    collectionsToSerialize.push(new EntitiesPersisted<PolylineGeometry>({
        entities: bim.polylineGeometries,
        serializer: new PolylinesSerializer(),
    }));
    collectionsToSerialize.push(new EntitiesPersisted<CubeGeometry>({
        entities: bim.cubeGeometries,
        serializer: new CubesSerializer(),
    }));
    collectionsToSerialize.push(new EntitiesPersisted<RegularHeightmapGeometry>({
        entities: bim.regularHeightmapGeometries,
        serializer: new RegularHeightmapsSerializer(),
    }));
    collectionsToSerialize.push(new EntitiesPersisted<IrregularHeightmapGeometry>({
        entities: bim.irregularHeightmapGeometries,
        serializer: new IrregularHeightmapsSerializer(),
    }));
    collectionsToSerialize.push(new EntitiesPersisted<ExtrudedPolygonGeometry>({
        entities: bim.extrudedPolygonGeometries,
        serializer: new ExtrudedPolygonsSerializer(),
    }));
    collectionsToSerialize.push(new EntitiesPersisted<GraphGeometry>({
        entities: bim.graphGeometries,
        serializer: new GraphsSerializer(),
    }));

    const res = new Map(collectionsToSerialize.map(c => [c._entities.identifier, c]));
    return res;
}