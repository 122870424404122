import { ProjectionInfo, WGSCoord, WgsProjectionOrigin, type Bim } from 'bim-ts';
import { Failure, LazyDerived, Success } from 'engine-utils-ts';
import { PUI_GroupNode, PUI_PropertyNodeString, type PUI_PropertyNodeStringArgs } from 'ui-bindings';

export function siteLocationSettingsUi(bim: Bim) {

    return LazyDerived.new1(
        'siteGeoLocationUi',
        [],
        [
            bim.instances.getObservableSceneOrigin(),
        ],
        ([sceneOrigin]) => {
            const pui = new PUI_GroupNode({name: 'geo-location', sortChildren: false});

            let geoLocationAsString: string = '';
            if (sceneOrigin.wgsProjectionOrigin) {
                geoLocationAsString += sceneOrigin.wgsProjectionOrigin.wgsOriginLatLong.latitude;
                geoLocationAsString += ', ';
                geoLocationAsString += sceneOrigin.wgsProjectionOrigin.wgsOriginLatLong.longitude;
            }
            const geoLcationStringNodeParams: PUI_PropertyNodeStringArgs = {
                name: 'Latitude, Longitude',
                parent: pui,
                value: geoLocationAsString,
                readonly: false,
                validator: (newValue: string | null) => {
                    if (newValue == '') {
                        return new Failure({msg: 'Invalid format'});
                        // return new Success(null);
                    }
                    if (newValue == null) {
                        return new Failure({msg: 'Invalid format'});
                    }
                    const newOrigin = tryParseLatLongFromString(newValue, sceneOrigin.wgsProjectionOrigin?.wgsOriginLatLong);
                    if (newOrigin) {
                        return new Success(newValue);
                    } else {
                        return new Failure({msg: 'Invalid format'});
                    }
                },
                onChange: (newValue: string | null) => {
                    if (newValue == null) {
                        return;
                    }
                    const newOrigin = tryParseLatLongFromString(newValue, sceneOrigin.wgsProjectionOrigin?.wgsOriginLatLong);
                    if (newOrigin) {
                        const projection = sceneOrigin.wgsProjectionOrigin!.projectionInfo.withNewLonLat(newOrigin);
                        bim.instances.patchSceneOrigin({
                            wgsProjectionOrigin: new WgsProjectionOrigin(newOrigin, projection ?? new ProjectionInfo()),
                        });
                    }
                },

            };
            pui.addMaybeChild(new PUI_PropertyNodeString(geoLcationStringNodeParams));

            return pui;
        }
    ).withoutEqCheck();
}


function tryParseLatLongFromString(newValue: string, prev: WGSCoord | undefined): WGSCoord | null {
    const wgs = WGSCoord.tryParseLatLongFromString(newValue);
    if (wgs && prev && prev.altitude) {
        return wgs.withDifferentAltitude(prev.altitude);
    }
    return wgs;
}

