<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
>
    <path
        d="M8.58581 15L6.14647 12.5608C5.9512 12.3655 5.9512 12.0489 6.14645 11.8537L6.85355 11.1465C7.0488 10.9513 7.36539 10.9513 7.56065 11.1465L12.4142 15.9999L7.56067 20.8536C7.36541 21.0489 7.04883 21.0489 6.85356 20.8536L6.14644 20.1465C5.95117 19.9513 5.95116 19.6347 6.14642 19.4394L8.58575 17H2.5C2.22386 17 2 16.7761 2 16.5V15.5C2 15.2239 2.22386 15 2.5 15H8.58581Z"
        fill="currentColor"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.35825 3.94C4.72373 3.35524 5.36467 3 6.05425 3H17.9458C18.6353 3 19.2763 3.35524 19.6417 3.94L21.696 7.2268C21.8947 7.54466 22 7.91196 22 8.2868V20C22 21.1046 21.1046 22 20 22H9.24282L11.2427 20H20V9H4V11.1718L2.17161 13.0002H2V8.2868C2 7.91196 2.10534 7.54466 2.304 7.2268L4.35825 3.94ZM17.9458 5H6.05425L4.80425 7H19.1958L17.9458 5Z"
        fill="currentColor"
    />
</svg>
