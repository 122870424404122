import { type ProjectNetworkClient, LegacyLogger, type Result, Success, Failure } from 'engine-utils-ts';
import { Vector3, Quaternion, Euler, Vector2, Transform } from 'math-ts';
import type { EntityId } from 'verdata-ts';
import { type AnyBimGeometry, type BimGeometries, type Bim, AssetRef,} from '../..';
import { BimProperty } from '../../bimDescriptions/BimProperty';
import { PropertiesCollection } from '../../bimDescriptions/PropertiesCollection';
import { BimImage, type BimImages } from '../../BimImages';
import { BimMaterial, type BimMaterials, BimMaterialStdRenderParams } from '../../BimMaterials';
import { BimPatch } from '../../BimPatch';
import { BimUrls } from '../../BimUrls';
import type { BimCollectionPatch } from '../../collections/BimCollection';
import type { EntitiesBase } from '../../collections/EntitiesBase';
import { type IdInEntityLocal, LocalIdsCounter } from '../../collections/LocalIds';
import type { PolyEntitiesBase } from '../../collections/PolyEntitiesBase';
import { CubeGeometry } from '../../geometries/CubeGeometries';
import { ExtrudedPolygonGeometry } from '../../geometries/ExtrudedPolygonGeometries';
import { GraphGeometry, SegmentInterpLinearG } from '../../geometries/GraphGeometries';
import { PolylineGeometry } from '../../geometries/PolylineGeometries';
import { EmptyPropsStub } from '../../properties/EmptyPropsStub';
import { type ObjectRepresentation, StdSubmeshRepresentation, StdMeshRepresentation, SceneImageRepresentation, BasicAnalyticalRepresentation } from '../../representation/Representations';
import type { SceneInstance } from '../../scene/SceneInstances';
import type { SceneInstancesArchetypes, SceneInstancePropsShapeType } from '../../scene/SceneInstancesArhetypes';
import { BimGeometryType } from '../../schema/bim-geometry-type';
import type { AssetTransform, AssetProperty, AssetPropertiesGroup, AssetImage, AssetMaterial, AssetGeometry, AssetObjectRepresentation, AssetAnalyticalRepresentation, AssetSceneInstance, BimAssetDescription } from './BimAssetDescription';
import { bimAssetMigration } from './BimAssetMigrations';


export abstract class AssetSerializer<TAsset, T> {
    serialize(obj: T): TAsset {
        throw new Error('not impl');
    };
    abstract deserialize(
        asset: TAsset,
        colleciton: EntitiesBase<T, any, any> | PolyEntitiesBase<T, any>,
        bimParseResult: BimAssetParseResult
    ): [EntityId<any>, Partial<T> | AssetRef];
}

function deserializeTransform(asset?: AssetTransform): Transform {
    if (!asset) {
        return new Transform();
    }
    const position = Vector3.fromArray(asset.position, 0);
    const rotation = new Quaternion();
    if (asset.rotation_euler) {
        const euler = new Euler(asset.rotation_euler[0], asset.rotation_euler[1], asset.rotation_euler[2]);
        rotation.setFromEuler(euler);
    }
    const scale = Array.isArray(asset.scale) ? Vector3.fromArray(asset.scale, 0) : Vector3.fromScalar(asset.scale || 1);
    return new Transform(position, rotation, scale);
}
function deserializeVec3(asset: [number, number, number] | number | undefined): Vector3 {
    if (Array.isArray(asset)) {
        return new Vector3(asset[0], asset[1], asset[2]);
    }
    if (asset) {
        return Vector3.fromScalar(asset);
    }
    return new Vector3(0, 0, 0);
}
function deserializeProperty(property: AssetProperty, prevPath: string[]): BimProperty {
    return BimProperty.NewShared({
        path: [...prevPath, property.name],
        description: property.description ?? undefined,
        value: property.value,
        unit: property.unit,
        numeric_range: property.numeric_range,
        numeric_step: property.numeric_step,
        discrete_variants: property.discrete_variants,
        readonly: property.readonly,
    });
}
function deserializeProperties(properties?: AssetPropertiesGroup, addedPath?: string[]): BimProperty[] {
    const result: BimProperty[] = [];
    if (properties) {
        for (const name in properties) {
            const path = addedPath ? [...addedPath, name] : [name];
            const pg = properties[name];
            if (Array.isArray(pg)) {
                for (const p of pg) {
                    result.push(deserializeProperty(p, path));
                }

            // check if property describes BimProperty
            } else if (pg.value !== undefined) {
                const prop: AssetProperty = {
                    ...(pg as AssetProperty),
                    name: name,
                };
                result.push(deserializeProperty(
                    prop,
                    path.slice(0, -1),
                ));
            // else its PropertyGroup
            } else {
                const desProps = deserializeProperties(pg as AssetPropertiesGroup, path);
                for (const dp of desProps) {
                    result.push(dp);
                }
            }
        }
    }
    return result;
}

class ImagesSerializer extends AssetSerializer<AssetImage, BimImage> {
    deserialize(
        s: AssetImage,
        c: BimImages,
        bimParseResult: BimAssetParseResult
    ):
        [EntityId<any>, BimImage]
    {
        if (s.ref) {
            return [c.reserveNewId(), new BimImage(new AssetRef(s.ref.path, s.ref.in_asset_id), null)];
        }
        throw new Error(`only images with references are supported ${s.ref}`);
    }
}

class MaterialsSerializer extends AssetSerializer<AssetMaterial, BimMaterial> {
    deserialize(s: AssetMaterial, c: BimMaterials, bimParseResult: BimAssetParseResult):
        [EntityId<any>, BimMaterial]
    {
        const renderParams = new BimMaterialStdRenderParams();
        if (s.render_params) {
            if (s.render_params.color) {
                renderParams.color = s.render_params.color;
            }
            if (s.render_params.transparency) {
                renderParams.transparency = s.render_params.transparency;
            }
            if (s.render_params.metalness) {
                renderParams.metalness = s.render_params.metalness;
            }
            if (s.render_params.roughness) {
                renderParams.roughness = s.render_params.roughness;
            }
        }
        return [
            c.reserveNewId(),
            new BimMaterial(
                s.name,
                renderParams
            )
        ]
    }
}

class GeometriesSerializer extends AssetSerializer<AssetGeometry, AnyBimGeometry> {
    deserialize(s: AssetGeometry, c: BimGeometries, bimParseResult: BimAssetParseResult):
        [EntityId<any>, AnyBimGeometry | AssetRef]
    {
        if (s.cube) {
            return [
                c.reserveNewIdForType(BimGeometryType.Cube),
                new CubeGeometry(
                    deserializeVec3(s.cube.size),
                    deserializeVec3(s.cube.center),
                )
            ]
        } else if (s.polyline) {
            return [
                c.reserveNewIdForType(BimGeometryType.Polyline),
                PolylineGeometry.newWithAutoIds(
                    Vector3.arrayFromTriples(s.polyline.points),
                    s.polyline.radius
                )
            ];
        } else if (s.extruded_polygon) {
            let holes: Vector2[][] | undefined = undefined;
            if (s.extruded_polygon.holes) {
                holes = [];
                for (const h of s.extruded_polygon.holes) {
                    holes.push(Vector2.arrayFromFlatArray(h));
                }
            }
            return [
                c.reserveNewIdForType(BimGeometryType.ExtrudedPolygon),
                ExtrudedPolygonGeometry.newWithAutoIds(
                    Vector2.arrayFromFlatArray(s.extruded_polygon.points_2d),
                    holes,
                    s.extruded_polygon.base_elevation,
                    s.extruded_polygon.top_elevation
                ),
            ]
        } else if (s.tri_geo_ref) {
            return [
                c.reserveNewIdForType(BimGeometryType.Triangle),
                new AssetRef(s.tri_geo_ref.path, s.tri_geo_ref.in_asset_id)
            ]
        } else if (s.graph) {
            const pointsPerId = new Map<IdInEntityLocal, Vector3>();
            for (let i = 0; i < s.graph.points.length; ++i) {
                const p = s.graph.points[i];
                const v = new Vector3(p[0], p[1], p[2]);
                let pointId: IdInEntityLocal;
                if (s.graph.pointsIds) {
                    pointId = s.graph.pointsIds[i] as IdInEntityLocal;
                } else {
                    pointId = i as IdInEntityLocal;
                }
                pointsPerId.set(pointId, v);
            }

            const geo = new GraphGeometry(
                pointsPerId,
                new Map(
					s.graph.edges.map(t => [
						LocalIdsCounter.newEdge(t[0] as IdInEntityLocal, t[1]  as IdInEntityLocal),
						SegmentInterpLinearG
					])
				),
            );
            return [
                c.reserveNewIdForType(BimGeometryType.GraphGeometry),
                geo
            ];
        } else {
            throw new Error(`could not parse goemetry ${JSON.stringify(s)}`);
        }
    }
}


function deserializeRepresentation(
    asset: AssetObjectRepresentation,
    bim: BimAssetParseResult,
): ObjectRepresentation | undefined {
    if (asset.std) {
        const assetSubmeshes = Array.isArray(asset.std) ? asset.std : [asset.std];
        const stdSubmeshes: StdSubmeshRepresentation[] = [];
        for (const s of assetSubmeshes) {
            stdSubmeshes.push(new StdSubmeshRepresentation(
                s.geometry ?
                    bim.geometries.parse(s.geometry, bim) :
                    bim.geometries.getBimIdFromAssetId(s.geometry_id),
                s.material ?
                    bim.materials.parse(s.material, bim) :
                    bim.materials.getBimIdFromAssetId(s.material_id),
                deserializeTransform(s.transform),
            ));
        }
        return new StdMeshRepresentation(stdSubmeshes);
    } else if (asset.image) {
        const imageId = asset.image.image
            ? bim.images.parse(asset.image.image, bim)
            : bim.images.getBimIdFromAssetId(asset.image.image_id);
        return new SceneImageRepresentation(
            imageId,
            new Vector2(...asset.image.world_size)
        );
    }
    return undefined;
}

function deserializeAnalyticalRepresentation(
    asset: AssetAnalyticalRepresentation,
    bim: BimAssetParseResult,
): BasicAnalyticalRepresentation | undefined {
    if (asset.basic) {
        const geometryId = asset.basic.geometry ?
                    bim.geometries.parse(asset.basic.geometry, bim)
                    : bim.geometries.getBimIdFromAssetId(asset.basic.geometry_id);

        return new BasicAnalyticalRepresentation(geometryId);
    } else {
                // const geoId = asset.spatial.geometry ?
        //     bim.geometries.parse(asset.spatial.geometry, bim)
        //     : bim.geometries.getBimIdFromAssetId(asset.spatial.geometry_id);
        // return new ObjectRepresentation(
        //     RepresentationType.SpatialObjectRepresentation,
        //     new SpatialObjectRepresentation(
        //         bim.geometries.getBimIdFromAssetId(geoId)
        //     )
        // );
    }
    return undefined;
}

class SceneInstanceSerializer extends AssetSerializer<AssetSceneInstance, SceneInstance> {

    constructor(private readonly archetypes:SceneInstancesArchetypes, private readonly versionPerType: Map<string, number>){
        super();
    }

    deserialize(
        asset: AssetSceneInstance,
        collection: EntitiesBase<SceneInstance, any, any>,
        result: BimAssetParseResult
    ): [EntityId<any>, Partial<SceneInstance>] {
        const repr = asset.representation ? deserializeRepresentation(asset.representation, result) : null;
        const reprAnal = asset.representationAnalytical ? deserializeAnalyticalRepresentation(asset.representationAnalytical, result) : null;

        const newId = collection.idsProvider.reserveNewId();
        const inst = {
            type_identifier: asset.type_identifier,
            name: asset.name,
            localTransform: deserializeTransform(asset.local_transform),
            properties: new PropertiesCollection(deserializeProperties(asset.properties)),
            representation: repr,
            representationAnalytical: reprAnal,
            spatialParentId: result.instances.getBimIdFromAssetId(asset.spatial_parent_id),
            electricalParentId: result.instances.getBimIdFromAssetId(asset.electrical_parent_id),
            props: EmptyPropsStub,
        };

        if(inst.type_identifier){
            //TODO: typed props serialization
            const propsType = this.archetypes.getPropsClassFor(inst.type_identifier);
            if (propsType) {
                inst.props = new propsType({});
            }
            const version = this.versionPerType.get(inst.type_identifier) ?? 0;
            this.archetypes.migrate(inst as SceneInstancePropsShapeType, version);
        }
        return [newId, inst];
    }
}

export class BimCollectionParseResult<A, T> {

    idsRemapped: Map<number | string, EntityId<any>> = new Map();

    assetsToLoad: Map<EntityId<any>, AssetRef> = new Map();

    readonly _parser: AssetSerializer<A, T>;

    readonly bimCollection: EntitiesBase<T, any, any> | PolyEntitiesBase<T, any>;

    _collectionPatch: BimCollectionPatch<T, any, any>;

    constructor(
        parser: AssetSerializer<A, T>,
        collection: EntitiesBase<T, any, any, any> | PolyEntitiesBase<T, any>,
        collectionPatch: BimCollectionPatch<T, any, any>
    ) {
        this._parser = parser;
        this.bimCollection = collection;
        this._collectionPatch = collectionPatch;
    }

    getBimIdFromAssetId(assetId: number | string | undefined): EntityId<any> | undefined {
        if (assetId == undefined) {
            return undefined;
        }
        const bimId = this.idsRemapped.get(assetId);
        if (bimId == undefined) {
            console.error(`${assetId} is invalid, it's erroneous or it may not have been parsed yet`);
        }
        return bimId;
    }

    parse(asset: A, result: BimAssetParseResult): EntityId<any> {
        const assetId = (asset as any)['id'];
        if (assetId != undefined) {
            if (this.idsRemapped.has(assetId)) {
                return;
            }
        }
        const [bimId, parsed] = this._parser.deserialize(asset, this.bimCollection, result);
        if (assetId != undefined) {
            this.idsRemapped.set(assetId, bimId);
        }
        if (parsed instanceof AssetRef) {
            this.assetsToLoad.set(bimId, parsed);
        } else {
            this._collectionPatch.toAlloc.push([bimId, parsed]);
        }
        return bimId;
    }

    parseAsset(id: EntityId<any>, assetRef: AssetRef, buffer: ArrayBuffer): BimPatch {
        return new BimPatch();
    }

    async _loadReferencedAssets(network: ProjectNetworkClient): Promise<BimPatch> {
        const assetsLoads: Promise<readonly [EntityId<any>, AssetRef, ArrayBuffer]>[] =
            Array.from(this.assetsToLoad)
            .map(async ([id, assetRef]) => {
                const url = BimUrls.assetReferenceUrl(assetRef);
                const asset = await (await network.get(url)).arrayBuffer();
                return [id, assetRef, asset] as const;
            }
        );
        const resultBimPatch = new BimPatch();
        const assetsLoadsSettled = await Promise.allSettled(assetsLoads);
        for (const psr of assetsLoadsSettled) {
            if (psr.status == "fulfilled") {
                const [id, assetRef, buffer] = psr.value;
                const assetBimPatch = this.parseAsset(id, assetRef, buffer);
                resultBimPatch.mergeWith(assetBimPatch);
            } else {
                LegacyLogger.error(`asset loading error`, psr.reason, psr);
            }
        }
        return resultBimPatch;
    }

    async convertToBimPatch(args: {network?: ProjectNetworkClient}): Promise<BimPatch> {
        const resultBimPatch = new BimPatch();
        if (this.assetsToLoad.size > 0) {
            if (!args.network) {
                throw new Error(`ProjectNetworkClient required to load assets`);
            }
            const loadedPatch = await this._loadReferencedAssets(args.network);
            resultBimPatch.mergeWith(loadedPatch);
        }
        resultBimPatch.mergeWithCollectionPatch(this._collectionPatch);
        return resultBimPatch;

    }
}

export interface AssetsLoader {
    loadAsset(collection: EntitiesBase<any, any, any>, assetRef: AssetRef, reservedId: EntityId<any>): Promise<void>
}

export class BimAssetParseResult {
    images: BimCollectionParseResult<AssetImage, BimImage>;
    materials: BimCollectionParseResult<AssetMaterial, BimMaterial>;
    geometries: BimCollectionParseResult<AssetGeometry, AnyBimGeometry>;
    instances: BimCollectionParseResult<AssetSceneInstance, SceneInstance>;

    constructor(bim: Bim, versionPerType: [string, number][]) {

        const bimPatch = new BimPatch();
        this.images = new BimCollectionParseResult(new ImagesSerializer(), bim.bimImages, bimPatch.images);
        this.materials = new BimCollectionParseResult(new MaterialsSerializer(), bim.bimMaterials, bimPatch.materials);
        this.geometries = new BimCollectionParseResult(new GeometriesSerializer(), bim.allBimGeometries, bimPatch.geometries);
        this.instances = new BimCollectionParseResult(new SceneInstanceSerializer(bim.instances.archetypes, new Map(versionPerType)), bim.instances, bimPatch.instances);
    }

    async toBimPatch(args: {
        network?: ProjectNetworkClient,
    }): Promise<BimPatch> {
        const result = new BimPatch();
        for (const key in this) {
            const cpr = this[key];
            if (cpr instanceof BimCollectionParseResult) {
                const bp = await cpr.convertToBimPatch(args);
                result.mergeWith(bp);
            }
        }
        return result;
    }
}

export function parseBimAssetToResult(bimAsset: BimAssetDescription, bim: Bim): Result<BimAssetParseResult> {
    try {
        const parsed = parseBimAsset(bimAsset, bim);
        return new Success(parsed);
    } catch (e) {
        return Failure.fromException(e);
    }
}

export function parseBimAsset(bimAsset: BimAssetDescription, bim: Bim): BimAssetParseResult {
    const result = new BimAssetParseResult(bim, bimAsset.versionPerTypeIdentifier ?? []);
    const migratedBimAsset = bimAssetMigration(bimAsset);
    const allExceptInstancesKeys: (keyof BimAssetDescription & keyof BimAssetParseResult)[] = ['images', 'materials', 'geometries'];
    for (const key of allExceptInstancesKeys) {
        const assetsCollection = migratedBimAsset[key];
        const resultCollection = result[key];
        if (assetsCollection) {
            for (const asset of assetsCollection) {
                if (asset.id == undefined) {
                    throw new Error(`root assets should have local ids set ${key} ${JSON.stringify(asset)}`);
                }
                resultCollection.parse(asset as any, result);
            }
        }
    }

    for (const instance of migratedBimAsset.instances) {
        result.instances.parse(instance, result);
    }

    return result;
}
