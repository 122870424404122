// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class HeightmapRepresentation implements flatbuffers.IUnpackableObject<HeightmapRepresentationT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):HeightmapRepresentation {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsHeightmapRepresentation(bb:flatbuffers.ByteBuffer, obj?:HeightmapRepresentation):HeightmapRepresentation {
  return (obj || new HeightmapRepresentation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsHeightmapRepresentation(bb:flatbuffers.ByteBuffer, obj?:HeightmapRepresentation):HeightmapRepresentation {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new HeightmapRepresentation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

heightmapGeoId():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

heightmapImageId():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

static startHeightmapRepresentation(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addHeightmapGeoId(builder:flatbuffers.Builder, heightmapGeoId:number) {
  builder.addFieldInt32(0, heightmapGeoId, 0);
}

static addHeightmapImageId(builder:flatbuffers.Builder, heightmapImageId:number) {
  builder.addFieldInt32(1, heightmapImageId, 0);
}

static endHeightmapRepresentation(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createHeightmapRepresentation(builder:flatbuffers.Builder, heightmapGeoId:number, heightmapImageId:number):flatbuffers.Offset {
  HeightmapRepresentation.startHeightmapRepresentation(builder);
  HeightmapRepresentation.addHeightmapGeoId(builder, heightmapGeoId);
  HeightmapRepresentation.addHeightmapImageId(builder, heightmapImageId);
  return HeightmapRepresentation.endHeightmapRepresentation(builder);
}

unpack(): HeightmapRepresentationT {
  return new HeightmapRepresentationT(
    this.heightmapGeoId(),
    this.heightmapImageId()
  );
}


unpackTo(_o: HeightmapRepresentationT): void {
  _o.heightmapGeoId = this.heightmapGeoId();
  _o.heightmapImageId = this.heightmapImageId();
}
}

export class HeightmapRepresentationT implements flatbuffers.IGeneratedObject {
constructor(
  public heightmapGeoId: number = 0,
  public heightmapImageId: number = 0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return HeightmapRepresentation.createHeightmapRepresentation(builder,
    this.heightmapGeoId,
    this.heightmapImageId
  );
}
}
