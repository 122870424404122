import type { Bim, Config, EditTrackerPilesContext, IdBimScene, PileFeaturesFlags, SceneInstance, TrackerBins, UnitsMapper} from "bim-ts";
import { BooleanProperty} from "bim-ts";
import { convertPilePositionsToAbsolute, convertPilePositionsToRelative, getOrInitTrackerBins, PileBinsConfig, PilesConfigModuleBaySize, producePropsPatch, TrackerWindPosition } from "bim-ts";
import { PilesFeaturesAndOffsetsProperty } from "bim-ts";
import { calculateTrackerFlatRepr, PileFeaturesAndOffsets, PileMotorType } from "bim-ts";
import { AnyTrackerProps, NumberProperty, StringProperty, PilesConfigModulesRow, PilesConfigsPerWindPosition } from "bim-ts";
import { SceneObjDiff, TrackerTypeIdent } from "bim-ts";
import type { LazyVersioned, ValueAndUnit} from "engine-utils-ts";
import { LazyBasic, LazyDerivedAsync, preferPreviousOverInProgressWrapper, Success, Yield} from "engine-utils-ts";
import { LogLevel, ScopedLogger } from "engine-utils-ts";
import { DefaultMap, IterUtils, LazyDerived } from "engine-utils-ts";


interface TrackerCalculatedProps {
    trackerModel?: StringProperty;

    modulesCount: number;
    trackerLength: NumberProperty;
    modulesRowLength: NumberProperty;
    trackerLengthByModules: NumberProperty;

    pilesCount: number;
    pilesOffsetsMeter: number[];
    pilesFeatures: PileFeaturesFlags[] | null;

    moduleWidthMeter: number;
    modulesRowsOffsetsMeter: number[];
}

interface TrackerPatchProperties {
    modulesGapBase: NumberProperty;
    modulesGapOnMotorPilesActive: boolean;
    modulesGapOnMotorPiles: NumberProperty;
    modulesGapOnOtherPilesActive: boolean;
    modulesGapOnOtherPiles: NumberProperty;

    activeWindLoadVariants: TrackerWindPosition[];

    motorPilesCount: NumberProperty;
    otherPilesCount: NumberProperty;

    overhangNorth: NumberProperty;
    overhangSouth: NumberProperty;
    
    isAdvanceMode: boolean;
    isRelativePilePositions: boolean;
    modulesConfiguration: StringProperty;
    pilesConfiguration: StringProperty;

    piles: PileFeaturesAndOffsets[] | null;

    same_piles_offsets: BooleanProperty;
}

export interface TrackerProps extends TrackerPatchProperties, TrackerCalculatedProps {

}

interface SelectedGroupTrackers {
    trackerName?: string;
    modulesCount?: string;
    selectedWindLoadPosition?: TrackerWindPosition;
}

interface PilePositionState extends SelectedGroupTrackers {
    selectedTrackers: IdBimScene[];
    trackersGroupNames: string[];
    modulesCountGroupsNames: string[];
    selectedWindLoadPosition: TrackerWindPosition;
    trackerBins: TrackerBins | null;
    props: TrackerProps | null;
}

interface TrackersGroup {
    ids: IdBimScene[];
    props: AnyTrackerProps;
}

export class PilePositionStateManger {
    private _logger = new ScopedLogger("pile-positions", LogLevel.Info);
    private _selectedGroupTrackers: LazyBasic<SelectedGroupTrackers>;
    private _uniqueTrackers: LazyVersioned<DefaultMap<string, Map<string, TrackersGroup>>>;

    public readonly state: LazyVersioned<PilePositionState>;

    constructor(
        readonly bim: Bim,
        context: EditTrackerPilesContext,
    ) {
        // attach to local storage
        this._selectedGroupTrackers = new LazyBasic("selected trackers", {});

        const trackersList = selectSpecificTrackers(bim, context);
        
        this._uniqueTrackers =  LazyDerived.new1(
            'unique-trackers-types', 
            null,
            [trackersList], 
            ([trackers]) => {
                const uniqueTrackers = new DefaultMap<string, Map<string, TrackersGroup>>(
                    () => new Map<string, TrackersGroup>()
                );
                for (const [id, inst] of trackers) {
                    const [name, modules] = trackerNameFormatter(inst);
                    const perModules = uniqueTrackers.getOrCreate(name);
                    const result = perModules.get(modules);
                    if(result) {
                        result.ids.push(id);
                    } else {
                        perModules.set(modules, { ids: [id], props: inst.propsAs(AnyTrackerProps) });
                    }
                }
    
                return uniqueTrackers;
            });

        const selectedTrackersLazy = LazyDerived.new2(
            "selected-trackers",
            [bim.unitsMapper],
            [this._selectedGroupTrackers, this._uniqueTrackers],
            ([selectedTrackers, trackers]) => {
                const selectedTrackersGroup = trackers.get(selectedTrackers.trackerName ?? "");
                const group = selectedTrackersGroup?.get(selectedTrackers.modulesCount ?? "");
                const reconciledSelectedTrackers = reconcileSelectedTrackers(selectedTrackers, trackers);
                this._selectedGroupTrackers.replaceWith(reconciledSelectedTrackers);

                return {
                    trackerName: selectedTrackers.trackerName,
                    modulesCount: selectedTrackers.modulesCount,
                    ids: group?.ids ?? [],
                    props: group?.props ?? null,
                    modulesCountGroupsNames: selectedTrackersGroup ? Array.from(selectedTrackersGroup.keys()) : [],
                    trackersGroupNames: Array.from(trackers.keys()),
                    selectedWindLoadPosition: selectedTrackers.selectedWindLoadPosition,
                }
            }
        );

        const trackerNameLazy = LazyDerived.new1(
            "tracker-name", 
            null, 
            [selectedTrackersLazy], 
            ([selectedTrackers]) => {
                return selectedTrackers.props?.tracker_frame.commercial.model.value;
            })

        const pileTypeGroupConfigAsync = LazyDerivedAsync.new2<
            TrackerBins| null,
            Config,
            string | undefined
        >(
            "selected-trackers-bins-configs",
            null,
            [bim.configs.getLazySingletonOf({type_identifier: PileBinsConfig.name }), trackerNameLazy],
            function* ([config, name]) { 
                yield Yield.NextFrame;

                let props = config?.propsAs(PileBinsConfig);
                if(!props || !name) {
                    return null;
                }

                return getOrInitTrackerBins(name, config, bim);
            }
        );
        const pyleTypeGroup = preferPreviousOverInProgressWrapper<TrackerBins | null>(pileTypeGroupConfigAsync);

        this.state = LazyDerived.new2(
            "pile-positions-state",
            [bim.unitsMapper],
            [selectedTrackersLazy, pyleTypeGroup],
            ([selectedTrackers, trackerBinsResult]) => {

                let props: TrackerProps | undefined = undefined;
                let selectedWindLoadPosition = selectedTrackers.selectedWindLoadPosition;
                if(selectedTrackers?.props) {
                    const trackerProps = extractPropsFromTracker(
                        selectedTrackers.props, 
                        selectedTrackers.selectedWindLoadPosition, 
                        bim.unitsMapper, 
                        this._logger
                    );
                    props = trackerProps[0];
                    selectedWindLoadPosition = trackerProps[1];
                }

                const pileType = trackerBinsResult instanceof Success ? trackerBinsResult.value : null;

                const state: PilePositionState = {
                    trackerName: selectedTrackers.trackerName,
                    modulesCount: selectedTrackers.modulesCount,
                    trackerBins: pileType,

                    selectedWindLoadPosition: selectedWindLoadPosition ?? TrackerWindPosition.Interior,
                    selectedTrackers: selectedTrackers?.ids ?? [],
                    modulesCountGroupsNames: selectedTrackers.modulesCountGroupsNames,
                    trackersGroupNames: selectedTrackers.trackersGroupNames,
                    props: props ?? null,
                };
                return state;
            }
        )
    }

    selectTracker(name: string) { 
        const allTrackers = this._uniqueTrackers.poll().get(name);
        if(!allTrackers) {
            this._selectedGroupTrackers.replaceWith({});
            return;
        }
        const firstModule = IterUtils.getFirstFromIter(allTrackers.keys());
        this._selectedGroupTrackers.replaceWith({ trackerName: name, modulesCount: firstModule });
    }

    selectModule(module: string) {
        const current = this._selectedGroupTrackers.poll();
        if(!current.trackerName) {
            return;
        }
        const groupByModuleCount = this._uniqueTrackers.poll().get(current.trackerName);
        if(!groupByModuleCount || !groupByModuleCount.has(module)) {
            this._selectedGroupTrackers.replaceWith({});
            return;
        }
        this._selectedGroupTrackers.replaceWith({ ...current, modulesCount: module });
        
        const ids = groupByModuleCount.get(module)?.ids;
        this.bim.instances.setSelected(ids);
    }

    selectWindLoadPosition(windPosition: TrackerWindPosition) { 
        const current = this._selectedGroupTrackers.poll();
        this._selectedGroupTrackers.replaceWith({ ...current, selectedWindLoadPosition: windPosition});
    }

    applyPatch(patch: Partial<TrackerPatchProperties>) {
        // console.error("applyPatch", patch);
        const state = this.state.poll();
        const logger = this._logger.newScope("apply-patch");
        const group = this._uniqueTrackers.poll().get(state.trackerName ?? "")?.get(state.modulesCount ?? "");
        if(!group) {
            logger.error("failed", state);
            return;
        }
        //change on selected wind load position
        const propsWithSelectedWindLoadPosition = group.props.clone();
        propsWithSelectedWindLoadPosition.position.wind_load_position = StringProperty.new({value: state.selectedWindLoadPosition});

        const propsPatch = producePropsPatch(propsWithSelectedWindLoadPosition, proxy => {
            if(patch.modulesGapBase) {
                proxy.tracker_frame.dimensions.modules_gap_x = patch.modulesGapBase;
                proxy.tracker_frame.dimensions.modules_gap_y = patch.modulesGapBase;
            }
            if(patch.modulesGapOnOtherPilesActive) {
                proxy.tracker_frame.dimensions.pile_bearings_gap = proxy.tracker_frame.dimensions.modules_gap_x;
            }
            if(patch.modulesGapOnOtherPilesActive === false) { 
                proxy.tracker_frame.dimensions.pile_bearings_gap = NumberProperty.new({value: 0, unit: "ft"});
            }
            if(patch.modulesGapOnOtherPiles) {
                proxy.tracker_frame.dimensions.pile_bearings_gap = patch.modulesGapOnOtherPiles;
                // reset offset on other piles to default
                if(proxy.tracker_frame.piles_configurations instanceof PilesConfigsPerWindPosition) {
                    const selected = proxy.tracker_frame.piles_configurations[state.selectedWindLoadPosition]?.toDescriptions();
                    if(selected && isRelativePilePositions(selected)){
                        const piles = selected.map(p => new PileFeaturesAndOffsets({
                            ...p, 
                            offset_in_meters: p.motor === PileMotorType.Motor ? p.offset_in_meters : 0,
                        }));

                        proxy.tracker_frame.piles_configurations[state.selectedWindLoadPosition] 
                            = PilesFeaturesAndOffsetsProperty.newFromDescriptions(piles);
                    }
                }
            }
            if(patch.modulesGapOnMotorPiles) {
                proxy.tracker_frame.dimensions.motor_gap = patch.modulesGapOnMotorPiles;
                // reset offset on motor piles to default
                if(proxy.tracker_frame.piles_configurations instanceof PilesConfigsPerWindPosition) {
                const selected = proxy.tracker_frame.piles_configurations[state.selectedWindLoadPosition]?.toDescriptions();
                if(selected && isRelativePilePositions(selected)){
                    const piles = selected.map(p => new PileFeaturesAndOffsets({
                        ...p, 
                        offset_in_meters: p.motor === PileMotorType.Motor ? 0 : p.offset_in_meters,
                    }));

                    proxy.tracker_frame.piles_configurations[state.selectedWindLoadPosition] 
                        = PilesFeaturesAndOffsetsProperty.newFromDescriptions(piles);
                }
            }
            }
            if(patch.modulesGapOnMotorPilesActive === true) {
                proxy.tracker_frame.dimensions.motor_gap = NumberProperty.new({value: 0.1, unit: "ft"});
                if(proxy.tracker_frame.piles_configurations instanceof PilesConfigsPerWindPosition) {
                    const piles = proxy.tracker_frame.piles_configurations[state.selectedWindLoadPosition];
                    const relativePositions = convertToRelativePilePositions(proxy, piles);
                    proxy.tracker_frame.piles_configurations[state.selectedWindLoadPosition] = relativePositions;
                }
            }
            if(patch.modulesGapOnMotorPilesActive === false) {
                proxy.tracker_frame.dimensions.motor_gap = NumberProperty.new({value: 0, unit: "ft"});
                if(proxy.tracker_frame.piles_configurations instanceof PilesConfigsPerWindPosition) {
                    const piles = proxy.tracker_frame.piles_configurations[state.selectedWindLoadPosition];
                    const absolutePositions = convertToAbsolutePilePositions(proxy, piles);
                    proxy.tracker_frame.piles_configurations[state.selectedWindLoadPosition] = absolutePositions;
                }
            }
            if(patch.activeWindLoadVariants?.length) {
                let config: PilesConfigsPerWindPosition | null = null;
                let defaultDescr: PilesFeaturesAndOffsetsProperty | null = null;
                if(proxy.tracker_frame.piles_configurations instanceof PilesConfigsPerWindPosition) { 
                    config = new PilesConfigsPerWindPosition(proxy.tracker_frame.piles_configurations);
                    defaultDescr = config.getDefault();
                } else {
                    logger.error("Piles configuration is not per wind position", patch, proxy.tracker_frame.piles_configurations);
                    throw new Error("Piles configuration is not per wind position");
                }

                if(!defaultDescr) {
                    logger.error("No default piles configuration", patch, proxy.tracker_frame.piles_configurations);
                    throw new Error("No default piles configuration");
                }
                
                let args: Partial<PilesConfigsPerWindPosition> = {};
                for (const [wind_position, pileConfig] of config.iterWindPositions()) {
                    const isInclude = patch.activeWindLoadVariants.includes(wind_position)
                    if(isInclude) { 
                        args[wind_position] = pileConfig ?? defaultDescr 
                            ? PilesConfigsPerWindPosition.getDefaultPilesFeaturesForWindPosition(wind_position, defaultDescr) 
                            : null;
                    } else {
                        args[wind_position] = null;
                    }
                }
                if(!patch.activeWindLoadVariants.includes(state.selectedWindLoadPosition)) {
                    this.selectWindLoadPosition(patch.activeWindLoadVariants[0]);
                }

                proxy.tracker_frame.piles_configurations = new PilesConfigsPerWindPosition(args);
            }
    
            if(patch.otherPilesCount) {
                const config = proxy.tracker_frame.piles_configurations;
                if(config instanceof PilesConfigsPerWindPosition) {
                    const piles = config[state.selectedWindLoadPosition];
                    const unPackedPiles = piles?.toDescriptions() ?? [];
                    if(!piles) {
                        logger.error("no piles configuration for wind position", state.selectedWindLoadPosition);
                    } else {
                        const updatedPiles = updatePiles(unPackedPiles, patch.otherPilesCount.value, PileMotorType.None);
                        if(updatedPiles) {
                            config[state.selectedWindLoadPosition] 
                                = PilesFeaturesAndOffsetsProperty.newFromDescriptions(updatedPiles);
                        }
                    }
                } else if(config instanceof PilesConfigModulesRow || config instanceof PilesConfigModuleBaySize) {
                    const repr = calculateTrackerFlatReprAtStart(proxy);
                    const unPackedPiles = repr.piles_descriptions.toDescriptions();
                    const updatedPiles = updatePiles(unPackedPiles, patch.otherPilesCount.value, PileMotorType.None);
                    if(updatedPiles) {
                        proxy.tracker_frame.piles_configurations = buildModulesRow(updatedPiles);
                    } else {
                        logger.error("failed to update piles", patch.otherPilesCount);
                    }
                } else {
                    logger.error("unknown piles configuration", config);
                }
            }
            if(patch.motorPilesCount) {
                const config = proxy.tracker_frame.piles_configurations;
                if(config instanceof PilesConfigsPerWindPosition) {
                    const piles = config[state.selectedWindLoadPosition];
                    const unPackedPiles = piles?.toDescriptions() ?? [];
                    if(!piles) {
                        logger.error("no piles configuration for wind position", state.selectedWindLoadPosition);
                    } else {
                        const updatedPiles = updatePiles(unPackedPiles, patch.motorPilesCount.value, PileMotorType.Motor);
                        if(updatedPiles) {
                            config[state.selectedWindLoadPosition] 
                                = PilesFeaturesAndOffsetsProperty.newFromDescriptions(updatedPiles);
                        }
                    }
                } else if(config instanceof PilesConfigModulesRow || config instanceof PilesConfigModuleBaySize) {
                    const repr = calculateTrackerFlatReprAtStart(proxy);
                    const unPackedPiles = repr.piles_descriptions.toDescriptions();
                    const updatedPiles = updatePiles(unPackedPiles, patch.motorPilesCount.value, PileMotorType.Motor);
                    if(updatedPiles) {
                        proxy.tracker_frame.piles_configurations = buildModulesRow(updatedPiles);
                    } else {
                        logger.error("failed to update piles", patch.motorPilesCount);
                    }
                } else {
                    logger.error("unknown piles configuration", config);
                }
            }
            if(patch.overhangNorth) {
                proxy.tracker_frame.dimensions.tube_overhang_north = patch.overhangNorth;
            }
            if(patch.overhangSouth) {
                proxy.tracker_frame.dimensions.tube_overhang_south = patch.overhangSouth;
            }
            if(patch.isAdvanceMode !== undefined) {
                if(patch.isAdvanceMode && proxy.tracker_frame.piles_configurations instanceof PilesConfigsPerWindPosition) { 
                    // do nothing
                } else if(!patch.isAdvanceMode && proxy.tracker_frame.piles_configurations instanceof PilesConfigModulesRow) { 
                    // do nothing
                } else if(patch.isAdvanceMode) {
                    const tracker = calculateTrackerFlatReprAtStart(proxy);
                    const defaultDescr = PilesFeaturesAndOffsetsProperty.new(tracker.piles_descriptions);
                    const piles = state.props?.isRelativePilePositions == false
                        ? defaultDescr
                        : convertToAbsolutePilePositions(proxy, defaultDescr);
                      
                    proxy.tracker_frame.piles_configurations = PilesConfigsPerWindPosition.newWithAllDefault(piles);
                } else {
                    const tracker = calculateTrackerFlatReprAtStart(group.props);
                    const defaultConfig = buildModulesRow(tracker.piles_descriptions.toDescriptions());
                    proxy.tracker_frame.piles_configurations = defaultConfig;
                }
            }
            if(patch.modulesConfiguration) {
                if(proxy.tracker_frame.piles_configurations instanceof PilesConfigsPerWindPosition) {
                    logger.error("not implemented", patch.modulesConfiguration);
                } else {
                    proxy.tracker_frame.piles_configurations = new PilesConfigModulesRow({modules_row: patch.modulesConfiguration});
                    // const piles = proxy.tracker_frame.piles_configurations.createPilesDescriptions();
                    // const lastPile = piles?.at(-1);
                    // const modulesCount = lastPile ? PileFeaturesAndOffsets.fromPacked(lastPile).offset_in_modules : 0;
                    // proxy.tracker_frame.string.modules_count_x = proxy.tracker_frame.string.modules_count_x.withDifferentValue(modulesCount);
                }
            }
            if(patch.pilesConfiguration) {
                logger.error("not implemented", patch.pilesConfiguration);
            }
            if(patch.piles) {
                if(!(proxy.tracker_frame.piles_configurations instanceof PilesConfigsPerWindPosition)) {
                    throw new Error("Piles configuration is not per wind position");
                }
                proxy.tracker_frame.piles_configurations[state.selectedWindLoadPosition] = PilesFeaturesAndOffsetsProperty.newFromDescriptions(patch.piles);
            }
            if(patch.same_piles_offsets && proxy.tracker_frame.piles_configurations instanceof PilesConfigsPerWindPosition) {
                proxy.tracker_frame.piles_configurations.same_piles_offsets = patch.same_piles_offsets;
            }
            
            if(shouldReconcilePiles(proxy, patch) && proxy.tracker_frame.piles_configurations instanceof PilesConfigsPerWindPosition) {
                const selectedPiles = proxy.tracker_frame.piles_configurations[state.selectedWindLoadPosition]?.toDescriptions();
                if(!selectedPiles) {
                    this._logger.error("no piles configuration for wind position", state.selectedWindLoadPosition);
                    throw Error("no piles configuration for wind position");
                }
                const motorPilesCount = IterUtils.count(selectedPiles, p => p.motor === PileMotorType.Motor);
                const otherPilesCount = IterUtils.count(selectedPiles, p => p.motor === PileMotorType.None);
                for (const [windPosition, config] of proxy.tracker_frame.piles_configurations.iterWindPositions()) {
                    if(config === null){
                        continue;
                    }
                    if(windPosition === state.selectedWindLoadPosition) {
                        continue;
                    }

                    let configDescr = config.toDescriptions();

                    const otherReconciled = updatePiles(configDescr, otherPilesCount, PileMotorType.None);
                    if(otherReconciled) {
                        configDescr = otherReconciled;
                    }
                    const motorReconciled = updatePiles(configDescr, motorPilesCount, PileMotorType.Motor);
                    if(motorReconciled) {
                        configDescr = motorReconciled;
                    }

                    for (let i = 0; i < selectedPiles.length; i++) {
                        const selectedPile = selectedPiles[i]; 
                        const currentPile = configDescr[i];
                        const newPile = new PileFeaturesAndOffsets({
                            ...currentPile,
                            offset_in_meters: selectedPile.offset_in_meters,
                            offset_in_modules: selectedPile.offset_in_modules,
                            undulation: selectedPile.undulation,
                            motor: selectedPile.motor,
                        });
                        configDescr[i] = newPile;

                        if(currentPile.motor != selectedPile.motor) {                            
                            const defaultPileFeatures = PilesConfigsPerWindPosition.getDefaultPilesFeaturesForWindPosition(
                                windPosition, 
                                PilesFeaturesAndOffsetsProperty.newFromDescriptions(configDescr)
                            ).toDescriptions();
                            configDescr[i] = defaultPileFeatures[i];
                        }
                    }
                    proxy.tracker_frame.piles_configurations[windPosition] = PilesFeaturesAndOffsetsProperty.newFromDescriptions(configDescr);
                }
            }
        });

        this.bim.instances.applyPatchTo({ props: propsPatch ?? undefined }, group.ids);
        const piles = (group.props.tracker_frame.piles_configurations[state.selectedWindLoadPosition] as any)?.toDescriptions();
        const updated = this.bim.instances.peekById(group.ids[0]);
        const updatedPiles = (updated?.propsAs(AnyTrackerProps)?.tracker_frame.piles_configurations[state.selectedWindLoadPosition] as any)?.toDescriptions();
        logger.debug(patch, group.props, propsPatch, [piles, updatedPiles]);
    }
}


function shouldReconcilePiles(props: AnyTrackerProps, patch: Partial<TrackerPatchProperties>): boolean {
    return !!(
        props.tracker_frame.piles_configurations instanceof PilesConfigsPerWindPosition &&
        props.tracker_frame.piles_configurations.same_piles_offsets.value &&
        (patch.piles || patch.otherPilesCount || patch.motorPilesCount || patch.same_piles_offsets?.value || patch.overhangNorth)
    );
}

function convertToRelativePilePositions(props: AnyTrackerProps, piles: PilesFeaturesAndOffsetsProperty | null) {
    const repr = calculateTrackerFlatReprAtStart(props);
    const unpacked = piles?.toDescriptions() ?? [];
    const convertToRelative = convertPilePositionsToRelative(repr, unpacked);
    return PilesFeaturesAndOffsetsProperty.newFromDescriptions(convertToRelative);
}

function convertToAbsolutePilePositions(props: AnyTrackerProps, piles: PilesFeaturesAndOffsetsProperty | null) {
    const repr = calculateTrackerFlatReprAtStart(props);
    const unpacked = piles?.toDescriptions() ?? [];
    const convertToRelative = convertPilePositionsToAbsolute(repr, unpacked);
    return PilesFeaturesAndOffsetsProperty.newFromDescriptions(convertToRelative);
}

function calculateTrackerFlatReprAtStart(props: AnyTrackerProps) {
    return calculateTrackerFlatRepr(props, false);
}

export function selectSpecificTrackers(bim: Bim, context: EditTrackerPilesContext) {
    const allTrackersList = bim.instances.getLazyListOfTypes({
        type_identifiers: ['any-tracker'],
        relevantUpdateFlags: SceneObjDiff.LegacyProps | SceneObjDiff.NewProps,
    });

    const selectedTrackers = context.ids ? new Set(context.ids) : undefined;
    const trackersList = LazyDerived.new1(
        'trackers-list',
        null,
        [allTrackersList],
        ([trackers]) => {
            if (selectedTrackers) {
                return trackers.filter(([id]) => selectedTrackers.has(id));
            } else {
                return trackers;
            }
        }
    ).withoutEqCheck();
    return trackersList;
}

function updatePiles(piles: PileFeaturesAndOffsets[], goalPilesCount: number, pileType: PileMotorType) {
    const pilesCount = IterUtils.count(piles, p => p.motor === pileType);
    const isRelative = isRelativePilePositions(piles);
    let updatedPiles: PileFeaturesAndOffsets[] | undefined;
    if(pilesCount < goalPilesCount) {
        updatedPiles = addPilesToEnd(
            piles, 
            goalPilesCount - pilesCount,
            p => new PileFeaturesAndOffsets({
                motor: pileType,
                offset_in_modules: isRelative ? (p.offset_in_modules ?? 0) + 1 : 0,
                offset_in_meters: isRelative ? 0 : p.offset_in_meters + 1,
            })
        );
    } else if(pilesCount > goalPilesCount) {
        updatedPiles = removePilesFromEnd(
            piles, 
            pilesCount - goalPilesCount, 
            p => p.motor === pileType
        );
    }

    return updatedPiles;
}

function addPilesToEnd(
    unPackedPiles: PileFeaturesAndOffsets[], 
    addPilesCount: number, 
    factoryFn: (lastPile: PileFeaturesAndOffsets) => PileFeaturesAndOffsets
) {
    const updatedPiles = unPackedPiles.slice();
    for (let i = 0; i < addPilesCount; i++) {
        const lastPile = updatedPiles.at(-1) ?? new PileFeaturesAndOffsets({});
        const newPile = factoryFn(lastPile);
        updatedPiles.push(newPile);
    }
    return updatedPiles;
}

function removePilesFromEnd(piles: PileFeaturesAndOffsets[], removePilesCount: number, filterFn: (pile: PileFeaturesAndOffsets) => boolean) {
    const updatedPiles = piles.slice();
    for (let i = 0; i < removePilesCount; i++) {
        const pileToRemove = IterUtils.findIndexBackToFront(updatedPiles, filterFn);
        if (pileToRemove < 0) {
            console.error("pile to remove not found", updatedPiles);
            break;
        }
        updatedPiles.splice(pileToRemove, 1);
    }

    return updatedPiles;
}

function buildModulesRow(piles: PileFeaturesAndOffsets[]) {
    let modulesRow:string[] = [];
    const isAbsoluteValues = !isRelativePilePositions(piles);
    // let modulesCount = 0;
    let prevModulesCount = 0;
    for (let i = 0; i < piles.length; i++) {
        const pile = piles[i];
        if (pile.offset_in_modules == null) {
            //TODO: implement
            // modulesCount += pile.offset_in_modules;
            // if (pile.motor === PileMotorType.Motor) {
            //     if (i != 0) {
            //         modulesRow += "-";
            //     }
            //     modulesRow += modulesCount + "-M";
            //     modulesCount = 0;
            // }
            // if (i == piles.length - 1 && modulesCount > 0) {
            //     modulesRow += "-" + modulesCount;
            // }
        } else {
            modulesRow.push((i === 0 ? pile.offset_in_modules : pile.offset_in_modules - prevModulesCount).toString());
            if (pile.motor === PileMotorType.Motor) {
                modulesRow.push("M");
            }
        }
        prevModulesCount = pile.offset_in_modules ?? 0;
    }

    const defaultConfig = new PilesConfigModulesRow({
        modules_row: StringProperty.new({ value: modulesRow.join("-") })
    });
    return defaultConfig;
}

function extractPropsFromTracker(
    props: AnyTrackerProps,
    selectedWindLoadVariant: TrackerWindPosition | undefined,
    unitsMapper: UnitsMapper,
    logger: ScopedLogger,
    ): [TrackerProps, TrackerWindPosition | undefined] { 

    let selectedWindLoad = selectedWindLoadVariant;
    const activeWindLoadVariants: TrackerWindPosition[] = [];
    const configuration = props.tracker_frame.piles_configurations;
    let isRelative = false;
    let same_piles_offsets = BooleanProperty.new({value: false});
    if(configuration instanceof PilesConfigsPerWindPosition){
        if(!selectedWindLoad) {
            selectedWindLoad = configuration.getDefaultWindPosition() ?? undefined;
        }
        same_piles_offsets = configuration.same_piles_offsets;

        for (const [wind_position, config] of configuration.iterWindPositions()) {
            if(config){
                activeWindLoadVariants.push(wind_position);
            }
        }
        const config = selectedWindLoad ? configuration[selectedWindLoad] : null;
        isRelative = config 
            ? isRelativePilePositions(config.toDescriptions()) 
            : false;
    }
    
    const repr = calculateTrackerFlatReprByWindPosition(props, selectedWindLoad);
    const piles = repr.piles_descriptions.toDescriptions();
    const motorPilesCount = IterUtils.count(piles, p => p.motor === PileMotorType.Motor);
    const otherPilesCount =  IterUtils.count(piles, p => p.motor === PileMotorType.None);

    let pilesConfiguration = "";
    if(repr.piles_descriptions.count > 0) {
        for (let i = 0; i < repr.piles_offsets.length; i++) {
            const offset = repr.piles_offsets[i];
            const pile = piles[i];
            const configured = unitsMapper.mapToConfigured({value: offset, unit: "m"});
            if(i === 0) {
                pilesConfiguration += `${configured.unit}:`;
            }
            pilesConfiguration += configured.value.toFixed(1);
            if(pile.motor === PileMotorType.Motor) { 
                pilesConfiguration += "M";
            }
            if(i < piles.length - 1) {
                pilesConfiguration += "-";
            }
        }
    }
    const modulesRowLengthCentred = (repr.modules_rows_offsets.at(-1) ?? 0) - (repr.modules_rows_offsets.at(0) ?? 0);
    const modulesTableLength = modulesRowLengthCentred > 0 ? repr.module_size_x + modulesRowLengthCentred : 0;
    const trackerLengthByModules = modulesTableLength 
        + props.tracker_frame.dimensions.tube_overhang_north.as("m") 
        + props.tracker_frame.dimensions.tube_overhang_south.as("m");

    let modulesConfiguration = StringProperty.new({value: repr.module_size_x.toString()});
    if(configuration instanceof PilesConfigModulesRow) {
        modulesConfiguration = configuration.modules_row;
    } else {
        modulesConfiguration = buildModulesRow(repr.piles_descriptions.toDescriptions()).modules_row;
    }

    const modulesCount = props.tracker_frame.string.modules_count_x.value 
        * props.tracker_frame.string.modules_count_y.value 
        * props.tracker_frame.dimensions.strings_count.value;
    
    const isAdvanceMode = configuration instanceof PilesConfigsPerWindPosition;
    
    const trackerProps: TrackerProps = {
        trackerModel: props.tracker_frame.commercial.model,
        modulesRowLength: NumberProperty.new({value: modulesTableLength, unit: "m"}),
        trackerLengthByModules: NumberProperty.new({value: trackerLengthByModules, unit: "m"}),

        modulesGapBase: props.tracker_frame.dimensions.modules_gap_x,
        modulesGapOnMotorPilesActive: isMotorGapActive(props),
        modulesGapOnMotorPiles: props.tracker_frame.dimensions.motor_gap,
        modulesGapOnOtherPilesActive: isPileGapActive(props),
        modulesGapOnOtherPiles: props.tracker_frame.dimensions.pile_bearings_gap,

        overhangNorth: props.tracker_frame.dimensions.tube_overhang_north,
        overhangSouth: props.tracker_frame.dimensions.tube_overhang_south,

        modulesCount: modulesCount,
        trackerLength: NumberProperty.new({value: repr.torque_tube_length, unit: "m"}),

        motorPilesCount: NumberProperty.new({value: motorPilesCount, step: 1, range: [1, 1e3]}),
        otherPilesCount: NumberProperty.new({value: otherPilesCount, step: 1, range: [1, 1e3]}),
        pilesCount: motorPilesCount + otherPilesCount,
        pilesOffsetsMeter: repr.piles_offsets,

        isAdvanceMode: isAdvanceMode,
        isRelativePilePositions: isRelative,

        pilesConfiguration: StringProperty.new({value: pilesConfiguration}),
        modulesConfiguration: modulesConfiguration,

        activeWindLoadVariants: activeWindLoadVariants,
        piles: piles,
        pilesFeatures: repr.piles_descriptions.features,

        moduleWidthMeter: repr.module_size_x,
        modulesRowsOffsetsMeter: repr.modules_rows_offsets,

        same_piles_offsets: same_piles_offsets,
    }

    return [trackerProps, selectedWindLoad];
}


// function calcOverhangs(repr: TrackerFlatRepresentation) {
//     const overhangNorth = (repr.modules_rows_offsets.at(0) ?? 0) - (repr.module_size_x / 2);
//     const lastPos = (repr.modules_rows_offsets.at(-1) ?? 0) + (repr.module_size_x / 2);
//     const overhangSouth = repr.torque_tube_length - lastPos;
//     return {overhangNorth, overhangSouth};
// }

function isMotorGapActive(props: AnyTrackerProps) {
    return props.tracker_frame.dimensions.motor_gap.value > 0;
}

function isPileGapActive(props: AnyTrackerProps) {
    return props.tracker_frame.dimensions.pile_bearings_gap.value > 0;
}

function calculateTrackerFlatReprByWindPosition(props: AnyTrackerProps, windPosition: TrackerWindPosition | null | undefined) { 
    const windPositionStr = windPosition != null
        ? StringProperty.new({value: windPosition})
        : null;
    const clonedProps = props.clone();
    clonedProps.position.wind_load_position = windPositionStr;

    const repr = calculateTrackerFlatReprAtStart(clonedProps);
    return repr;
}

function reconcileSelectedTrackers(
    selectedTrackers: SelectedGroupTrackers, 
    uniqueTrackers: DefaultMap<string, Map<string, TrackersGroup>>
): SelectedGroupTrackers {
    let reconciledSelectedTrackers = selectedTrackers;
    if(uniqueTrackers.size === 0) { 
        return {};
    }

    if(!reconciledSelectedTrackers.trackerName || !uniqueTrackers.has(reconciledSelectedTrackers.trackerName)) {
        const firstTracker = IterUtils.getFirstFromIter(uniqueTrackers);
        if(firstTracker) {
            const firstModule = IterUtils.getFirstFromIter(firstTracker[1]);
            return { trackerName: firstTracker[0], modulesCount: firstModule?.[0] };
        }
        return {};
    }

    const groupByModuleCount = uniqueTrackers.get(reconciledSelectedTrackers.trackerName);
    if(!selectedTrackers.modulesCount || !groupByModuleCount?.has(selectedTrackers.modulesCount)) { 
        const modules = groupByModuleCount?.keys();
        if(modules) {
            return { ...reconciledSelectedTrackers, modulesCount: IterUtils.getFirstFromIter(modules) };
        }
    }

    return reconciledSelectedTrackers;
}

function trackerNameFormatter(instance: SceneInstance) {
    if(instance.type_identifier === TrackerTypeIdent) {
        const manufacturer = instance.properties.get("tracker-frame | commercial | manufacturer")?.asText() ?? "";
        const model = instance.properties.get("tracker-frame | commercial | model")?.asText() ?? "";
        const stringModulesCount = instance.properties.get('tracker-frame | string | modules_count')?.asNumber() ?? 0;
        const modulesCount = instance.properties.get("circuit | equipment | modules_count")?.asNumber() ?? 0;

        return [`${manufacturer} ${model}`, `${stringModulesCount} / ${modulesCount} MOD`];
    } else if(instance.type_identifier === "any-tracker"){
        const props = instance.propsAs(AnyTrackerProps);
        const manufacturer = props.tracker_frame.commercial.manufacturer.value;
        const model = props.tracker_frame.commercial.model.value;
        const stringModulesCount = props.tracker_frame.string.modules_count_x.value * props.tracker_frame.string.modules_count_y.value
        const modulesCount = props.tracker_frame.dimensions.strings_count.value * stringModulesCount;

        return [`${manufacturer} ${model}`, `${stringModulesCount} / ${modulesCount} MOD`];
    } else {
        console.error("Unknown type identifier", instance.type_identifier);
        throw new Error("Unknown type identifier");
    }
}

function isRelativePilePositions(piles: PileFeaturesAndOffsets[]): boolean{
    return piles.some(p => p.offset_in_modules != null);
}

export function valueUnitToUiValue(value: ValueAndUnit, unitsMapper: UnitsMapper,  decimals: number = 2) {
    const configured = unitsMapper.mapToConfigured(value);
    return configured.unit 
        ? `${configured.value.toFixed(decimals)} ${configured.unit}`
        : configured.value.toFixed(decimals);
}

export class WindLoadVariantSettingsContext {
    constructor(readonly stateManager: PilePositionStateManger){
    }
}

export class PileOffsetContext {
    constructor(
        readonly pileIndex: number | null,
        readonly stateManager: PilePositionStateManger
    ){
    }
}