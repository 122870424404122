import { type Bim, type Catalog } from "bim-ts";
import { BimProperty, type AssetId, type IdBimScene, type PropsGroupComplexDefaults, type SceneInstancePatch } from "bim-ts";
import type { PUI_Node, PUI_PropertyNodeArgs, PUI_GroupNode, PUI_GroupNodeArgs , PUI_ConfigBasedBuilderParams} from "ui-bindings";
import { PUI_Builder, PUI_BuilderCallbacks, tryAddPuiPropertyNode } from "ui-bindings";
import { FieldsMergeState, type GroupsCommonFieldsMerger } from './CommonFieldsMerger';

export function buildLegacySelectionPropsUi(args: {
    sharedTypeIdentifier: string | undefined,
    ids: IdBimScene[],
    bim: Bim,
    catalog: Catalog,
    props: GroupsCommonFieldsMerger<{[key: string] : BimProperty}>,
    mappingParams: PUI_ConfigBasedBuilderParams,
}): PUI_GroupNode {
    const builderCallbacks = new PUI_BuilderCallbacks();

    builderCallbacks.addAfterEveryNodeCallback(trackerModuleSelectorUiBuilderCallback(
        args.ids, args.bim, args.catalog
    ))
    const puiBuilder = new PUI_Builder({
        callbacks: builderCallbacks
    });

    args.props.buildPui<PropsGroupComplexDefaults<any> | undefined>(
        puiBuilder,
        (_groupClass, fullPath) => {
            const name = (fullPath.at(-1) ?? '').toString();
            if (name.startsWith('_')) {
                return undefined;
            }
            const groupNodeParams: PUI_GroupNodeArgs = {name};
            return { groupNodeArgs: groupNodeParams, context: undefined };
        },
        (puiBuilder, merger, fullPath, _parentsContext) => {
            const propertyValue = merger.result();
            const name = fullPath.at(-1) ?? '';
            const path = fullPath.slice(0, fullPath.length - 1);

            const additionalNodeParams: Partial<PUI_PropertyNodeArgs<any>> = merger.state() === FieldsMergeState.Different ? {
                value: merger.nodeValue(),
                readonly: merger.isReadonly()
            } : {};


            const { handled } = tryAddPuiPropertyNode({
                puiBuilder,
                name,
                path,
                outerContext: undefined,
                sourceValue: propertyValue,
                propsMappingParams: args.mappingParams,
                additionalNodeParams,
                onChange: (newValue, fullpath, uiNode) => {
                    if (newValue instanceof BimProperty) {
                        const patch: SceneInstancePatch = {
                            properties: [[newValue._mergedPath, newValue]]
                        };
                        args.bim.instances.applyPatchTo(
                            patch,
                            args.ids,
                            {
                                allowPropsShapeHookToResetExistingPropsToDefaultValues: true
                            }
                        );
                    } else {
                        console.error('expected BimProperty', newValue);
                    }
                }
            });
            if (!handled && propertyValue !== null) {
                console.error('failed to map bim property to pui node', name, fullPath, propertyValue);
            }
        }
    );
    const rootGroup = puiBuilder.finish();
    return rootGroup.children.get('') as PUI_GroupNode ?? rootGroup;
}

function trackerModuleSelectorUiBuilderCallback(
    ids: IdBimScene[],
    bim: Bim,
    catalog: Catalog,
): (b: PUI_Builder, node: PUI_Node) => void  {

    return (builder: PUI_Builder, node: PUI_Node) => {
        if (node.parent?.name === "module" && node.name === "manufacturer") {
            const value:number[] = [];
            const instances = bim.instances.peekByIds(ids);
            const pvModuleValues = new Set<string>();

            for (const [_, inst] of instances) {
                const hash = bim.keyPropertiesGroupFormatter.fullKeyPropsStr(
                    "pv-module",
                    inst.properties,
                    inst.props,
                );
                if (hash) {
                    pvModuleValues.add(hash);
                }
            }
            if (pvModuleValues.size === 1 && catalog) {
                for (const assetId of catalog.assets.sceneInstancePerAsset.assetIdToSceneInstanceId.perId.keys()) {
                    const si = catalog.assets.sceneInstancePerAsset
                        .getAssetAsSceneInstance(assetId)
                    if (!si || si.type_identifier !== "pv-module") {
                        continue;
                    }
                    const hash = catalog.keyPropertiesGroupFormatters.fullKeyPropsStr(
                        si.type_identifier,
                        si.properties,
                        si.props,
                    );

                    if (hash && pvModuleValues.has(hash)) {
                        value.push(assetId);
                        break;
                    }
                }
            }

            builder.addCustomProp<
                AssetId[],
                {
                    maxCount: number;
                    types: string[];
                }
            >({
                name: "pv-module",
                value: value,
                typeSortKeyOverride: 0,
                type_ident: "assets-selector",
                context: {
                    maxCount: 1,
                    types: ["pv-module"],
                },
                onChange: ([v]) => {
                    if (!v) {
                        console.error("pv-module not changed", v);
                        return;
                    }
                    const pvModule = catalog.assets.sceneInstancePerAsset
                        .getAssetAsSceneInstance(v)
                    if (!pvModule) {
                        console.error("pv-module not changed");
                        return;
                    }
                    const properties: [string, BimProperty][] = [];

                    for (const [_, prop] of pvModule.properties) {
                        if (prop.path[0] === "module") {
                            properties.push([prop._mergedPath, prop]);
                        }
                    }

                    bim.instances.applyPatchTo(
                        {
                            properties,
                        },
                        ids,
                        {
                            allowPropsShapeHookToResetExistingPropsToDefaultValues:
                                true,
                        }
                    );
                },
            });
        }
    }
}
