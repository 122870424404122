import type { LazyVersioned, ResultAsync } from "engine-utils-ts";
import { IterUtils, LazyDerivedAsync } from "engine-utils-ts";
import type { LCOECalculationResult } from "..";
import { NumberProperty } from "src";
import { CostHierarchy } from "../CostHierarchy";
import type { LcoeSettings, LcoeSettingsProvider } from "../LcoeSettings";
import { KrMath } from "math-ts";

export function createNpvOfEnergyHierarchy(
    lcoe: LazyVersioned<ResultAsync<LCOECalculationResult>>,
    defaultSettings: LcoeSettings,
    provider: LcoeSettingsProvider,
) {
    const npvOfEnergy = LazyDerivedAsync.new2(
        "npvOfEnergy",
        [],
        [lcoe, provider.lcoeSettings],
        function* ([lcoe, settings]) {
            const hierarchy = new CostHierarchy();

            hierarchy.add({
                description: {
                    value: "Simplified Energy Production",
                    indent: 3,
                },
                source: { title: "Energy, First year Total" },
                lcoeInput: {
                    quantity: NumberProperty.new({
                        value: lcoe.input.lcoe.annualElectricityOutputWattHour,
                        unit: "Wh",
                    }),
                    input: {
                        value:
                            settings.lcoe.projectUsefulLifeYears?.value ??
                            defaultSettings.lcoe.projectUsefulLifeYears
                                ?.value ??
                            0,
                        flags: {
                            editable: false,
                        },
                    },
                    resultingUnit: "Years",
                },
                costPerYear: lcoe.output.perYear
                    .map((x) => x.production.simplifiedEnergyProductionWattHour)
                    .concat(
                        IterUtils.sum(
                            lcoe.output.perYear,
                            (x) =>
                                x.production.simplifiedEnergyProductionWattHour,
                        ),
                    )
                    .map((x) => ({
                        value: NumberProperty.new({ value: x, unit: "Wh" }),
                    })),
            });

            hierarchy.add({
                description: {
                    value: "Production Degradation, applied from 2nd year",
                    indent: 3,
                },
                lcoeInput: {
                    quantity: NumberProperty.new({
                        value: lcoe.input.lcoe.annualElectricityOutputWattHour,
                        unit: "Wh",
                    }),
                    input: {
                        value:
                            100 *
                            (settings.lcoe.annualProductionDegradationPercent
                                ?.value ??
                                defaultSettings.lcoe
                                    .annualProductionDegradationPercent
                                    ?.value ??
                                0),
                        update: (value) => {
                            if (!value) {
                                provider.updateLcoeSettings(
                                    (state) =>
                                        (state.lcoe.annualProductionDegradationPercent =
                                            null),
                                );
                            } else {
                                value = KrMath.clamp(value / 100, 0, 1);
                                provider.updateLcoeSettings(
                                    (state) =>
                                        (state.lcoe.annualProductionDegradationPercent =
                                            NumberProperty.new({ value })),
                                );
                            }
                        },
                        flags: {
                            editable: true,
                            overriden:
                                !!settings.lcoe
                                    .annualProductionDegradationPercent,
                        },
                    },
                    resultingUnit: "%",
                },
                costPerYear: lcoe.output.perYear
                    .map((x) => -x.production.productionDegrationWattHour)
                    .concat(
                        -IterUtils.sum(
                            lcoe.output.perYear,
                            (x) => x.production.productionDegrationWattHour,
                        ),
                    )
                    .map((x) => ({
                        value: NumberProperty.new({ value: x, unit: "Wh" }),
                    })),
            });

            hierarchy.add({
                description: {
                    value: "Discount Rate, applied each year",
                    indent: 3,
                },
                lcoeInput: {
                    quantity: NumberProperty.new({
                        value: lcoe.input.lcoe.annualElectricityOutputWattHour,
                        unit: "Wh",
                    }),
                    input: {
                        value:
                            100 *
                            (settings.lcoe.discountRatePercent?.value ??
                                defaultSettings.lcoe.discountRatePercent
                                    ?.value ??
                                0),
                    },
                    resultingUnit: "%",
                },
                costPerYear: lcoe.output.perYear
                    .map(
                        (x) => x.production.discountRateAppliedEachYearWattHour,
                    )
                    .concat(
                        IterUtils.sum(
                            lcoe.output.perYear,
                            (x) =>
                                x.production
                                    .discountRateAppliedEachYearWattHour,
                        ),
                    )
                    .map((x) => ({
                        value: NumberProperty.new({ value: x, unit: "Wh" }),
                    })),
            });

            hierarchy.addRoot({
                description: { value: "NPV of Energy Production" },
                costPerYear: lcoe.output.perYear
                    .map((x) => x.production.netPresentValueOfEnergyWattHour)
                    .concat(lcoe.output.NPVOfEnergyProductionWattHours)
                    .map((x) => ({
                        value: NumberProperty.new({ value: x, unit: "Wh" }),
                    })),
            });
            return hierarchy;
        },
    );
    return npvOfEnergy;
}
