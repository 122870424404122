import { IterUtils } from "engine-utils-ts"
import { EquipmentCostPerUnitColId, EquipmentTotalColId, LaborCostPerUnitColId, LaborPerUnitColId, LaborTotalColId, LoadedWageRateColId, MaterialCostPerUnitColId, MaterialTotalColId, SubServiceCostPerUnitColId, SubServiceTotalColId } from "./const"

export class HiddableColumnGroup {
    display: string
    columns: string[]
    subMenuItems: string[]
    topLevel: boolean

    constructor(params: {
        display: string,
        columns: string[],
        subMenuItems?: string[],
        topLevel?: boolean
    }) {
        this.display = params.display;
        this.columns = params.columns;
        this.subMenuItems = params.subMenuItems ?? [];
        this.topLevel = !!params.topLevel;
    }

    static getRecursiveColumns(...ids: string[]) {
        const columns = new Set<string>();
        for (const id of ids) {
            const group = HiddableColumnGroups.get(id);
            if (!group) {
                continue;
            }
            IterUtils.extendSet(columns, group.columns);
            for (const id of group.subMenuItems) {
                IterUtils.extendSet(columns, HiddableColumnGroup.getRecursiveColumns(id));
            }
        }
        return columns;
    }
}

export const MaterialHiddableColumnGroupId = 'material';
export const LaborHiddableColumnGroupId = 'labor-cost-per-unit';
export const LaborHoursAndRageHiddableColumnGroupId = 'labor-hours-and-rate';
export const SubcontractorHiddableColumnGroupId = 'subcontractor';
export const EquipmentHiddableColumnGroupId = 'equipment';


export const HiddableColumnGroups = new Map<string, HiddableColumnGroup>([
    [
        MaterialHiddableColumnGroupId,
        new HiddableColumnGroup({
            display: 'Material',
            columns: [MaterialCostPerUnitColId, MaterialTotalColId],
            topLevel: true
        })
    ],
    [
        LaborHiddableColumnGroupId,
        new HiddableColumnGroup({
            display: 'Labor cost per unit',
            columns: [LaborCostPerUnitColId, LaborTotalColId],
            topLevel: true,
        })
    ],
    [
        LaborHoursAndRageHiddableColumnGroupId,
        new HiddableColumnGroup({
            display: 'Hours per unit and weighted wage',
            columns: [LoadedWageRateColId, LaborPerUnitColId],
            topLevel: true,
        })
    ],
    [
        SubcontractorHiddableColumnGroupId,
        new HiddableColumnGroup({
            display: 'Subcontractor',
            columns: [SubServiceTotalColId, SubServiceCostPerUnitColId],
            topLevel: true,
        })
    ],
    [
        EquipmentHiddableColumnGroupId,
        new HiddableColumnGroup({
            display: 'Equipment',
            columns: [EquipmentCostPerUnitColId, EquipmentTotalColId],
            topLevel: true,
        }),
    ],
])

export const TopLevelHiddableColumnGroups = IterUtils.filter(HiddableColumnGroups, ([k, v]) => !!v.topLevel).map(x => x[0]);

