import type { LazyVersioned } from "engine-utils-ts";
import { LazyBasic, LazyDerived } from "engine-utils-ts";
import type { NavbarContext } from "src/navbar/Navbar";
import { getContext, setContext } from "svelte"
import { CostModel as CM } from 'bim-ts';

const svelteContextIdentifier = 'cost-model-ui-api';

export type CostModelMenuCommand = CM.CostModelTableFocusCommand;

export interface CostModelContext {
    cmds: LazyBasic<Array<CostModelMenuCommand>>,
    hasCmd: LazyVersioned<boolean>,
    runCmd: (cmd: CostModelMenuCommand) => void,
    popLatestCommand: () => CostModelMenuCommand | undefined
    focusApi: CM.CostModelFocusApi
}

const createDefaultGlobalContextValue = (): CostModelContext => {
    return {
        cmds: new LazyBasic('commandBuffer', []),
        hasCmd: new LazyBasic('hasCmd', false),
        runCmd: () => {},
        focusApi: {
            focusOnCostModel: () => {},
            focusOnTable: () => {},
        },
        popLatestCommand: () => undefined,
    }
}

export function registerCostModelContext(navbar: NavbarContext) {
    const ctx: CostModelContext = createDefaultGlobalContextValue();

    ctx.runCmd = (cmd) => {
        ctx.cmds.getForMutation().push(cmd);
        navbar.openTab('View.Cost Report')
    }

    ctx.focusApi.focusOnCostModel = (params) => {
        ctx.runCmd(new CM.FocusOnCategoryThatMatchesTheInstance_Command(params))
    }
    ctx.focusApi.focusOnTable = (params) => {
        ctx.runCmd(new CM.FocusOnTableCommand(params))
    }

    ctx.hasCmd = LazyDerived.new1('hasCmd', [], [ctx.cmds], ([cmds]) => {
        return cmds.length > 0;
    })

    ctx.popLatestCommand = () => {
        const latestCmd = ctx.cmds.poll().at(-1)
        ctx.cmds.forceUpdate([]);
        return latestCmd;
    }

    setContext(svelteContextIdentifier, ctx);
}

export function getCostModelContext() {
    const context = getContext<CostModelContext>(svelteContextIdentifier)
    return context;
}

