/// <reference path="./ClipperLib.d.ts" />
import ClipperLib from 'clipper-lib';

import { KrMath } from './KrMath';
import { Vector2 } from './Vector2';
import { Vector3 } from './Vector3';

ClipperLib.use_xyz = true;

export interface ClipperPoint {
	X: number,
	Y: number,
	Z: number,
}

export function distanceBetween(p1: ClipperPoint, p2: ClipperPoint) {
	return Math.sqrt(
		Math.pow((p1.X - p2.X), 2) +
		Math.pow((p1.Y - p2.Y), 2) +
		Math.pow((p1.Z - p2.Z), 2)
	);
}

export function distanceBetween2D(p1: ClipperPoint, p2: ClipperPoint) {
    const dx = p1.X - p2.X;
    const dy = p1.Y - p2.Y;
	return Math.sqrt(dx * dx + dy * dy);
}

const MinimalMaxSignificanDelta = 0.00001;

export class Clipper {
    static getClipperPointsMappingFuncs3D(originPoint: Vector3, maxSignificanDelta: number) {

        if (!originPoint.isFinite()) {
            throw new Error('invalid origin point ' + JSON.stringify(originPoint));
        }
        if (!(maxSignificanDelta > MinimalMaxSignificanDelta)) {
            console.error('maxSignificanDelta is too low');
            maxSignificanDelta = MinimalMaxSignificanDelta;
        }

        const multipler = 1 / maxSignificanDelta;

        return {
            mapToClipper: (point: Vector3): ClipperPoint => {
                return new ClipperLib.IntPoint(
                    Math.round((point.x - originPoint.x) * multipler),
                    Math.round((point.y - originPoint.y) * multipler),
                    Math.round((point.z - originPoint.z) * multipler),
                )
            },
            mapFromClipper: (clipperPoint: ClipperPoint): Vector3 => {
                return new Vector3(
                    (clipperPoint.X * maxSignificanDelta) + originPoint.x,
                    (clipperPoint.Y * maxSignificanDelta) + originPoint.y,
                    (clipperPoint.Z * maxSignificanDelta) + originPoint.z,
                )
            },
            scaleToClipper: (n: number) => n * multipler,
        }
    }

    static getClipperPointsMappingFuncs2D(originPoint: Vector2, maxSignificanDelta: number) {

        if (!originPoint.isFinite()) {
            throw new Error('invalid origin point ' + JSON.stringify(originPoint));
        }
        if (!(maxSignificanDelta > MinimalMaxSignificanDelta)) {
            console.error('maxSignificanDelta is too low', maxSignificanDelta);
            maxSignificanDelta = MinimalMaxSignificanDelta;
        }

        const multipler = 1 / maxSignificanDelta;

        return {
            mapToClipper: (point: Vector2): ClipperPoint => {
                return new ClipperLib.IntPoint(
                    Math.round((point.x - originPoint.x) * multipler),
                    Math.round((point.y - originPoint.y) * multipler),
                )
            },
            mapFromClipper: (clipperPoint: ClipperPoint): Vector2 => {
                return new Vector2(
                    (clipperPoint.X * maxSignificanDelta) + originPoint.x,
                    (clipperPoint.Y * maxSignificanDelta) + originPoint.y,
                )
            },
            scaleToClipper: (n: number) => n * multipler,
        }
    }

    static DefaultZFillFunction(e1From: ClipperPoint, e1To: ClipperPoint, e2From: ClipperPoint, e2To: ClipperPoint, resultPoint: ClipperPoint) {

        const edge1Length = distanceBetween2D(e1From, e1To);
        const e1FromDistance = distanceBetween2D(resultPoint, e1From);

        const e1Z = KrMath.lerp(e1From.Z, e1To.Z, e1FromDistance / edge1Length);


        const edge2Length = distanceBetween2D(e2From, e2To);
        const e2Z = KrMath.lerp(e2From.Z, e2To.Z, distanceBetween2D(resultPoint, e2From) / edge2Length);

        resultPoint.Z = Math.round((e1Z + e2Z) * 0.5);
    }


    static calculatePolygonsIntersections(polygons: Readonly<Vector2>[][], maxSignificantDelta = 0.001): Vector2[][] {
        if (polygons.length === 0) {
            return [];
        }
        const origin = polygons[0][0];
        const {mapFromClipper, mapToClipper, scaleToClipper} = Clipper.getClipperPointsMappingFuncs2D(origin, maxSignificantDelta);

        const c = new ClipperLib.Clipper();
        for (const polygon of polygons) {
            const clipperPolygon = polygon.map(mapToClipper);
            c.AddPath(clipperPolygon, polygons[0] === polygon ? ClipperLib.PolyType.ptSubject : ClipperLib.PolyType.ptClip, true);
        }
        const solution: ClipperPoint[][] = new ClipperLib.Paths();
        c.Execute(ClipperLib.ClipType.ctIntersection, solution, ClipperLib.PolyFillType.pftNonZero, ClipperLib.PolyFillType.pftNonZero);
        return solution.map(polygon => polygon.map(mapFromClipper));
    }

    static subtractPolygons(sourcePolygon: Vector2[], polygonsToSubtract: Vector2[][], maxSignificantDelta = 0.001): Vector2[][] {
        if (polygonsToSubtract.length === 0) {
            return [sourcePolygon];
        }
        const origin = sourcePolygon[0];
        const { mapFromClipper, mapToClipper } = Clipper.getClipperPointsMappingFuncs2D(origin, maxSignificantDelta);

        const c = new ClipperLib.Clipper();

        const polygonToClipFrom = sourcePolygon.map(mapToClipper);
        c.AddPath(polygonToClipFrom, ClipperLib.PolyType.ptSubject, true);
        for (const polygon of polygonsToSubtract) {
            const polygonToSubtract = polygon.map(mapToClipper);
            c.AddPath(polygonToSubtract, ClipperLib.PolyType.ptClip, true);
        }
        const solution: ClipperPoint[][] = new ClipperLib.Paths();
        c.Execute(ClipperLib.ClipType.ctDifference, solution, ClipperLib.PolyFillType.pftNonZero, ClipperLib.PolyFillType.pftNonZero);
        return solution.map(polygon => polygon.map(mapFromClipper));
    }


    static calculateBoundariesWithJoinTolerance(sourceBoundaries:  Vector3[][], zonesJoinOffset: number, resultOFfset: number): Vector3[][] {

        const origin = sourceBoundaries[0][0];
        const {mapFromClipper, mapToClipper, scaleToClipper} = Clipper.getClipperPointsMappingFuncs3D(origin, 0.001);
        const clipperSourceBoundaries = sourceBoundaries.map(points => points.map(mapToClipper));

        const sourcesUnion = polygonsUnion(clipperSourceBoundaries);

        const sourcesOffset = offsetPolygons(sourcesUnion, scaleToClipper(zonesJoinOffset + resultOFfset));
        const resultPolygons = offsetPolygons(sourcesOffset, scaleToClipper(-zonesJoinOffset));

        return resultPolygons.map(points => points.map(mapFromClipper));
    }

    static calculateBoundariesWithJoinTolerance2D(sourceBoundaries:  Vector2[][], zonesJoinOffset: number, resultOFfset: number): Vector2[][] {

        const origin = sourceBoundaries[0][0];
        const {mapFromClipper, mapToClipper, scaleToClipper} = Clipper.getClipperPointsMappingFuncs2D(origin, 0.001);
        const clipperSourceBoundaries = sourceBoundaries.map(points => points.map(mapToClipper));

        const sourcesUnion = polygonsUnion(clipperSourceBoundaries);

        const sourcesOffset = offsetPolygons(sourcesUnion, scaleToClipper(zonesJoinOffset + resultOFfset));
        const resultPolygons = offsetPolygons(sourcesOffset, scaleToClipper(-zonesJoinOffset));

        return resultPolygons.map(points => points.map(mapFromClipper));
    }

    static unionPolygons2D(shapes: Vector2[][]): Vector2[][] {
        const origin = shapes[0][0];
        const {
            mapFromClipper,
            mapToClipper,
        } = Clipper.getClipperPointsMappingFuncs2D(origin, 0.001);
        const mergedShapes =
            polygonsUnion(shapes.map(shape => shape.map(mapToClipper)));
        return mergedShapes.map(shape => shape.map(mapFromClipper));
    }
    
    static offsetPolygons2D(shapes: Vector2[][], offset: number): Vector2[][] {
        const origin = shapes[0][0];
        const {
            mapFromClipper,
            mapToClipper,
            scaleToClipper
        } = Clipper.getClipperPointsMappingFuncs2D(origin, 0.001);
        const resultShapes = offsetPolygons(shapes.map(shape => shape.map(mapToClipper)), scaleToClipper(offset));
        return resultShapes.map(shape => shape.map(mapFromClipper));
    }

    static intersectsPolygons(first: Vector2[], second: Vector2[], maxSignificantDelta = 0.001): boolean {
        const solution = Clipper.calculatePolygonsIntersections([first, second], maxSignificantDelta);
        return solution.length > 0;
    }

    static offsetPolygon(polygon: Vector2[], offset: number): Vector2[][] {
        const origin = polygon[0];
        const {mapFromClipper, mapToClipper, scaleToClipper} = Clipper.getClipperPointsMappingFuncs2D(origin, 0.001);
        const clipperPolygon = polygon.map(point => mapToClipper(point));
        const offsetClipperPolygon = offsetPolygons([clipperPolygon], scaleToClipper(offset));
        return offsetClipperPolygon.map(poly => poly.map(point => mapFromClipper(point)));
    }
}


function offsetPolygons(shapes: ClipperPoint[][], offsetScaled: number) {
    const co = new ClipperLib.ClipperOffset();
    const joinResult: ClipperPoint[][] = new ClipperLib.Paths();
    co.ZFillFunction = Clipper.DefaultZFillFunction;
    co.AddPaths(shapes, ClipperLib.JoinType.jtSquare, ClipperLib.EndType.etClosedPolygon);
    co.Execute(joinResult, offsetScaled);
    return joinResult;
}

function polygonsUnion(shapes: ClipperPoint[][]): ClipperPoint[][] {
	const c = new ClipperLib.Clipper();
	c.AddPaths(shapes, ClipperLib.PolyType.ptSubject, true);
	const solution = new ClipperLib.Paths();
	c.Execute(ClipperLib.ClipType.ctUnion, solution, ClipperLib.PolyFillType.pftNonZero, ClipperLib.PolyFillType.pftNonZero);
	return solution;
}
