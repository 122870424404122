
export class DeferredPromise<T> {

    readonly promise: Promise<T>;

    private _isFinalized: boolean = false;// resolved or rejected

    private _resolve: ((res: T) => void) | null = null;
    private _reject: ((reason: any) => void) | null = null;

    private _timeoutId: ReturnType<typeof setTimeout> | 0 = 0;

    constructor(timeoutMs: number = 180_000) {

        this.promise = new Promise((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });

        if (timeoutMs >= 0 && timeoutMs < Infinity) {
            this._timeoutId = setTimeout(this._reject as any, timeoutMs, 'timeout');
        }
        this.promise.finally(() => {
            if (!this._isFinalized) {
                console.warn(`DeferredPromise was resolved in unexpected way`, this.promise);
                this._isFinalized = true;
                this._resolve = null;
                this._reject = null;
            }
            if (this._timeoutId) {
                clearTimeout(this._timeoutId);
                this._timeoutId = 0;
            }
        })
    }

    public static delay(delayMs: number): DeferredPromise<void> {
        if (!Number.isFinite(delayMs)) {
            delayMs = 0;
        }
        const dp = new DeferredPromise<void>(-1);
        setTimeout(() => { if (dp._resolve) { dp.resolve() } }, delayMs);
        return dp;
    }

    public isFinalized(): boolean {
        return this._isFinalized;
    }

    resolve(result: T) {
        if (!this._isFinalized) {
            this._isFinalized = true;
            this._resolve!(result);
            this._resolve = null;
            this._reject = null;
        } else {
            console.warn('cant resolve DeferredPromise, already finalized')
        }

    }

    reject(reason: any) {
        if (!this._isFinalized) {
            this._isFinalized = true;
            this._reject!(reason);
            this._resolve = null;
            this._reject = null;
        } else {
            console.warn('cant reject DeferredPromise, already finalized')
        }
        if (this._timeoutId) {
            clearTimeout(this._timeoutId);
            this._timeoutId = 0;
        }
    }
}
