import { LazyDerived, LazyBasic } from 'engine-utils-ts';
import type { PUI_ConfigBasedBuilderParams, UiBindings, DropdownViewDescription, PUI_GroupNode} from 'ui-bindings';
import { ActionVisibilityFlags, PUI_Lazy, buildFromConfigObject, PUI_ActionsNode } from 'ui-bindings';
import type { KreoEngineImpl } from '../KreoEngineImpl';

export function addEditUiBindings(engine: KreoEngineImpl, engineConfigBuilderSettings: PUI_ConfigBasedBuilderParams, uiBindings: UiBindings) {

    const editControls = engine.editModeControls;

    for (const operator of editControls.editOperators.all()) {

        uiBindings.addAction({
            name: operator.menuPath,
            canUseNow: operator.canStart,
            priority: operator.priority,
            divider: operator.divider,
            action: () => {
                return editControls.editOperators.startEditOperation(operator.menuPath, { resetSelection: true });
            },
            visibility: operator.menuVisibility
                ?? ActionVisibilityFlags.ContextMenu | ActionVisibilityFlags.Menu | ActionVisibilityFlags.Search,
        });


    }

    uiBindings.addStatusViews(
        ['engine-interactive-views'],
        LazyDerived.new0(
            'engine-interactive-views',
            [editControls.editOperators.stateInvalidator()],
            (_args, prev) => {
                const result: DropdownViewDescription[] = [];
                const operator = editControls.editOperators.getActiveOperator();
                if (operator) {
                    let confgUiBuilderSettings = engineConfigBuilderSettings;
                    if (operator.configBuilderSettings) {
                        confgUiBuilderSettings = confgUiBuilderSettings.mergedWith(operator.configBuilderSettings());
                    }

                    const name = operator.menuPath[0];
                    const statusViewSource = LazyDerived.new1(
                        name,
                        [],
                        [operator.config],
                        ([config]) => {
                            let puiGroupNode: PUI_GroupNode;
                            if (operator.buildUi) {
                               puiGroupNode = operator.buildUi((patch) => {
                                    editControls.editOperators.patchOperatorConfig(operator.menuPath, patch);
                                });
                            } else {
                                puiGroupNode = buildFromConfigObject({
                                    configObj: config,
                                    configPatchesCallback: (patch) => {
                                        editControls.editOperators.patchOperatorConfig(operator.menuPath, patch);
                                    },
                                    configBuilderParams: confgUiBuilderSettings,
                                });
                            }
                            puiGroupNode.addMaybeChild(new PUI_ActionsNode({
                                name: "actions",
                                nameSortKeyOverride: 999,
                                typeSortKeyOverride: 999,
                                context: null,
                                actions: [
                                    {label: "ok", action: () => {editControls.editOperators.finishEdit()}},
                                    {label: "cancel", action: () => {editControls.editOperators.cancelEdit()}},
                                ]
                            }));
                            return puiGroupNode;
                        }
                    ).withoutEqCheck();

                    const header = new LazyBasic('', name ?? '---');
                    result.push({
                        header,
                        viewSource: new PUI_Lazy(statusViewSource),
                        activateOpened: true,
                    });
                }
                return result;
            }
        )
    )

}
