import { ExecutionThreadPreference, ScopedLogger, JobExecutor, registerExecutor } from "engine-utils-ts";
import type { SegmentAnalyt, SegmentTriangulationRepr} from "./GraphTriangulation";
import { triangulateGraphEdges } from "./GraphTriangulation";



export class GraphTriangulationJobExecutor extends JobExecutor<SegmentAnalyt[], Map<number, SegmentTriangulationRepr>> {

	execute(segments: SegmentAnalyt[]): Map<number, SegmentTriangulationRepr> {
		const triangulation = triangulateGraphEdges({
			logger: new ScopedLogger('triangulator'),
			segments: segments
		});
		return triangulation;
	}

	executionPreference(args: SegmentAnalyt[]): ExecutionThreadPreference {
		return args.length > 2 ? ExecutionThreadPreference.WorkerThread : ExecutionThreadPreference.None;
	}

	estimateTaskDurationMs(args: SegmentAnalyt[]): number {
		const est = args.length * 0.5;
		return Math.max(est * est, 1);
	}
}
registerExecutor(GraphTriangulationJobExecutor);
