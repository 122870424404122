<script lang="ts">
    import { Tabs } from "../libui/tabs";
    import ProjectSettings from "./ProjectSettings.svelte";
    import LayoutMetrics from "./ProjectMetrics.svelte";
    import { UiPersistentState } from "../UiPersistentState";
    import EngineUiPanel from "../ui-panels/EngineUiPanel.svelte";
    import { ButtonComponent, IconButton } from "../libui/button";
    import {
        Action,
        ContextMenu,
        ContextMenuStructure,
    } from "../libui/context-menu";
    import {
        Catalog,
        MetricsGroup,
        ProjectMetrics,
    } from "bim-ts";
    import {
        Failure,
        InProgress,
        LazyDerived,
        ResultAsync,
        Success,
    } from "engine-utils-ts";
    import { VersionedStore } from "../VersionedStore";
    import { getContext } from "svelte";
    import { ComparisonMode } from "../ComparisonMode";
    import { ProjectHub } from "../ProjectHub";
    import { VerDataSyncer } from "verdata-ts";
    import { FilesExportsHandler } from '../files-imports-exports/FilesExportsHandler';
    import { createCurrentVersionsLazy } from "./ProjectPanel";

    const contextMenu = getContext<ContextMenu>("context-menu");
    const comparison = getContext<ComparisonMode>("comparison");
    const projectHub = getContext<ProjectHub>("projectHub");
    const projectId = getContext<number>('projectId');
    const vds = getContext<VerDataSyncer>("verDataSyncer");
    const catalog = getContext<Catalog>("catalog");
    const layoutMetricLazy = getContext<ProjectMetrics>("layoutMetricsLazy");
    const filesExportsHandler = getContext<FilesExportsHandler>('filesExportsHandler');

    $: tabs = [
        {
            key: "Overview",
            name: "Overview",
        },
        {
            key: "Settings",
            name: "Settings",
        },
    ];

    enum ActiveTabEnum {
        Overview = 0,
        Settings = 1,
    }

    const uiState = new UiPersistentState<{ tab: ActiveTabEnum }>(
        "project-panel-tab", 
        { tab: ActiveTabEnum.Overview }
    ).store;

    $: activeTabIndex = $uiState.tab;
    function changeTab(e: CustomEvent) {
        $uiState.tab = tabs.indexOf(e.detail.tab);
    }

    const layoutMetricsByArea = LazyDerived.new1<
        MetricsGroup[] | undefined,
        ResultAsync<MetricsGroup[]>
        >(
        "layout-metrics-by-area",
        null,
        [layoutMetricLazy],
        ([layoutMetrics], prev) => {
            if (layoutMetrics instanceof Success) {
                return layoutMetrics.value;
            } else if (layoutMetrics instanceof InProgress) {
                return layoutMetrics.lastSuccessful ?? prev;
            } else if(layoutMetrics instanceof Failure){
                console.error("Project metrics loading failed: ", layoutMetrics.errorMsg());
                return ;
            } else {
                console.error("Unknown project metrics state", layoutMetrics);
                return;
            }
        },
    );


    const currentVersionsLazy = createCurrentVersionsLazy({
        projectVds: vds,
        catalogVds: catalog.getSyncer(),
    });

    const contextMenuStructureLazy = LazyDerived.new0(
        "context-menu-str-lazy",
        [comparison],
        () => {
            const newMenuStructure = new ContextMenuStructure([
                new Action("Export Excel", () => {
                    filesExportsHandler.export("XLSX");
                })
            ]);

            return newMenuStructure;
        },
    );

    const menuStructure = new VersionedStore(contextMenuStructureLazy);

    $: menuButton = new IconButton("Menu", (e) =>
        contextMenu.openMenuAtPosition(e.clientX, e.clientY, $menuStructure),
    );

    $: panelHeader = `${$projectHub.name || 'Project'} #${projectId}`;
</script>

<EngineUiPanel header={panelHeader}>
    <div slot="buttons" class="energy-toggle">
        <ButtonComponent desc={menuButton} />
    </div>
    <div class="tabs">
        <Tabs
            {tabs}
            firstIndexToActivate={activeTabIndex}
            compact={true}
            on:change={changeTab}
        />
    </div>

    {#if activeTabIndex === ActiveTabEnum.Overview}
        <LayoutMetrics {currentVersionsLazy} {layoutMetricsByArea} />
    {:else if activeTabIndex === ActiveTabEnum.Settings}
        <ProjectSettings />
    {:else}
        {console.warn("Unknown tab", activeTabIndex, tabs)}
    {/if}
</EngineUiPanel>

<style>
    .tabs {
        margin-left: 15px;
    }
</style>
