import type { ScopedLogger } from 'engine-utils-ts';
import { Immer, IterUtils } from 'engine-utils-ts';
import { BimCollectionPatch } from 'src/collections/BimCollection';
import type { Bim } from '../../Bim';
import { patternNames } from '../../constants/dc-patterns';
import { CatalogItemsReferenceProperty, SceneInstancesProperty } from '../../properties/ObjectRefProps';
import { BooleanProperty, NumberProperty, NumberRangeProperty, SelectorProperty, StringProperty } from '../../properties/PrimitiveProps';
import type { PropertyGroup } from '../../properties/PropertyGroup';
import { ConfigUtils } from '../ConfigUtils';
import type { ConfigShapeMigration } from '../ConfigsArchetypes';
import { StateType } from '../ConfigsArchetypes';
import type { Config, ConfigPatch, IdConfig } from '../ConfigsCollection';
import type { ConfigResolver, ConfigsResolver } from '../ConfigsResolver';
import type { SubareasConfig } from './FarmLayoutConfigType';
import { AllSiteAreaId, FarmLayoutConfigType, UnallocatedSubareaId, type BlockEquipment, type SiteAreaBase } from './FarmLayoutConfigType';

export const AugmentConfigType = 'augment-panel-config';


export interface AugmentConfig extends PropertyGroup, SubareasConfig<AugmentSettings> {
    site_areas: SubareaConfig[];
}

export interface SubareaConfig extends SiteAreaBase<AugmentSettings> { 
}

export interface AugmentSettings extends PropertyGroup {
    capacity: {
        ilr_range: NumberRangeProperty;
    };
    offsets: {
        transformer_offset: NumberProperty;
        inverter_offset: NumberProperty;
        combiner_box_offset: NumberProperty;
    };
    equipment_roads: {
        selected_roads: SceneInstancesProperty;
    };
    electrical: {
        combiner_box: CatalogItemsReferenceProperty;
        blocks_equipment: BlockEquipment[];
        scheme: SelectorProperty;
        nec_multiplier: NumberProperty;
        colorize: BooleanProperty;
        generate_new_blocks: BooleanProperty;
        solver: BooleanProperty;
    };
}

function createDefaultProps(): AugmentConfig {
    const properties: AugmentConfig = {
        site_areas: [
            {
                id: StringProperty.new({value: AllSiteAreaId}),
                last_calculation_hash: null,
                settings: getDefaultAugmentSettings() 
            },
            { 
                id: StringProperty.new({ value: UnallocatedSubareaId }),
                last_calculation_hash: null,
                settings: getDefaultAugmentSettings(),
            },
        ],
    };

    return properties;
}

export function registerAugmentConfig(bim: Bim) {
    bim.configs.archetypes.registerArchetype({
        type_identifier: AugmentConfigType,
        properties: createDefaultProps,
        connectionToType: "substation",
        stateType: StateType.CreateByConnection,
        shapeMigrations: migrations(),
    });
}

export function createDefaultAugmentSubarea(id: string, config: AugmentConfig): SubareaConfig {
    let settings: AugmentSettings;
    const allSiteAreaProps = config.site_areas.find(a => a.id.value === AllSiteAreaId);
    if(allSiteAreaProps){
        settings = ConfigUtils.copyTo<AugmentSettings, AugmentSettings>(allSiteAreaProps.settings)
    } else {
        settings = getDefaultAugmentSettings();
    }

    return { 
        id: StringProperty.new({value: id}),
        last_calculation_hash: null,
        settings: settings
    };
}

export function getDefaultAugmentSettings(): AugmentSettings {
    const settings: AugmentSettings = {
        last_calculation_hash: null,
        capacity: {
            ilr_range: NumberRangeProperty.new({
                value: [1.05, 1.25],
                unit: "",
                step: 0.01,
                range: [0, 100],
            }),
        },
        electrical: {
            combiner_box: new CatalogItemsReferenceProperty({
                value: [],
                types: ["asset"],
                assetsTypes: ["combiner-box"],
                maxCount: 1,
            }),
            blocks_equipment: [],
            scheme: SelectorProperty.new({
                value: "CI_Multiharness",
                options: patternNames,
            }),
            nec_multiplier: NumberProperty.new({
                value: 1.5625,
                unit: "",
                range: [0, 1e9],
            }),
            colorize: BooleanProperty.new({
                value: true,
            }),
            generate_new_blocks: BooleanProperty.new({
                value: false,
            }),
            solver: BooleanProperty.new({value: true}),
        },
        equipment_roads: {
            selected_roads: new SceneInstancesProperty({
                value: [],
                types: ["road"],
            }),
        },
        offsets: {
            transformer_offset: NumberProperty.new({
                value: 20,
                unit: "ft",
                range: [0, 1e9],
            }),
            inverter_offset: NumberProperty.new({
                value: 20,
                unit: "ft",
                range: [0, 1e9],
            }),
            combiner_box_offset: NumberProperty.new({
                value: 27,
                unit: "ft",
                range: [0, 1e9],
            }),
        },
    };
    return settings;
}

export function registerAugmentSubareasResolver(resolver: ConfigsResolver, logger: ScopedLogger){
    const subareasResolver: ConfigResolver = { 
        identifier: "augment-configs-subareas-reconciler",
        dependentTypes: FarmLayoutConfigType,
        onAllocated: (collection, id) => { 
            return resolveConfigSubareas(collection, id, logger, AugmentConfigType, createDefaultAugmentSubarea);
        },
        onUpdated: (collection, id) => {
            return resolveConfigSubareas(collection, id, logger, AugmentConfigType, createDefaultAugmentSubarea);
        },  
    }

    resolver.register(subareasResolver);
}

export function resolveConfigSubareas<T extends SubareasConfig<PropertyGroup>>(
    collection: ReadonlyMap<IdConfig, Config>, 
    id: IdConfig, 
    logger: ScopedLogger, 
    type_identifier: string,
    createDefaultSubarea: (id: string, config: T) => SiteAreaBase<PropertyGroup>
): BimCollectionPatch<Config, IdConfig, ConfigPatch> | undefined {
    const dependentConfig = collection.get(id);
    if(!dependentConfig){
        logger.error('dependent config not found', id);
        return;
    }
    const connectedConfigs: [IdConfig, Config][] = [];
    for (const [id, config] of collection) {
        if(config.type_identifier === type_identifier && config.connectedTo === dependentConfig.connectedTo){
            connectedConfigs.push([id, config]);
        }
    }
    if(connectedConfigs.length !== 1){
        logger.error('to many or to few connected configs', connectedConfigs);
        return;
    }
    const [configId, config] = connectedConfigs[0];

    const farmAreasIds = dependentConfig.get<SubareasConfig<PropertyGroup>>().site_areas.map(a => a.id.value);
    const augmentConfigProps = config.get<T>();
    const subareas = augmentConfigProps.site_areas.map(a => a.id.value);

    if(IterUtils.areArraysEqual(farmAreasIds, subareas)){
        return;
    }
    
    const patchProps = Immer.produce(augmentConfigProps, (draft) => {
        const areas = draft.site_areas;
        draft.site_areas = [];
        for (let i = 0; i < farmAreasIds.length; i++) {
            const id = farmAreasIds[i];
            const siteArea = areas.find((a) => a.id.value === id);
            if (siteArea) {
                draft.site_areas.push(siteArea);
            } else {
                const newSubarea = createDefaultSubarea(id, augmentConfigProps);
                draft.site_areas.push(newSubarea);
            }
        }
    });

    const patch = new BimCollectionPatch<Config, IdConfig, ConfigPatch>("subareas-config-resolver-patches");
    patch.toPatch.push([configId, {properties: patchProps}]);

    return patch;
}

function migrations(): ConfigShapeMigration[] | undefined {
    return [
        {
            toVersion: 1,
            patch: (config) => {
                const props = config.get<AugmentConfig>();
                //@ts-ignore
                props.settings.electrical.solver = BooleanProperty.new({value: true});
            },
            validation: {
                updatedProps: [{ path: ["settings", "electrical", "solver"] }],
                deletedProps: [],
            },
        },
        {
            toVersion: 2,
            patch: (config) => {
                const props = config.get<AugmentConfig>();
                //@ts-ignore
                const min = IterUtils.min(props.settings.electrical.blocks_equipment.map((block) => block.ilr_range.value[0])) ?? 1.05;
                //@ts-ignore
                const max = IterUtils.min(props.settings.electrical.blocks_equipment.map((block) => block.ilr_range.value[1])) ?? 1.25;
                //@ts-ignore
                props.settings.capacity = {
                    ilr_range: NumberRangeProperty.new({
                        value: [min, max],
                        unit: "",
                        step: 0.01,
                        range: [0, 100],
                    }),
                };
            },
            validation: {
                updatedProps: [{ path: ["settings", "capacity", "ilr_range"] }],
                deletedProps: [],
            },
        },
        {
            toVersion: 3,
            patch: (config) => {
                const props = config.get<AugmentConfig>();
                //@ts-ignore
                const settings: AugmentSettings = props.settings;

                const augmentConfig: AugmentConfig = {
                    site_areas: [
                        { 
                            id: StringProperty.new({ value: AllSiteAreaId }),
                            last_calculation_hash: null,
                            settings: settings 
                        },
                        { 
                            id: StringProperty.new({ value: UnallocatedSubareaId }),
                            last_calculation_hash: null,
                            settings: ConfigUtils.copyTo(settings),
                        },
                    ],
                };
                config.properties = augmentConfig;
            },
            validation: {
                updatedProps: [{path: ["site_areas"]}],
                deletedProps: [{path: ["settings"]}],
            },
        },
        {
            toVersion: 5,
            patch: (config) => {
                const props = config.get<AugmentConfig>();

                for (const area of props.site_areas) {
                    if(!area.settings.electrical.generate_new_blocks.value){
                        area.settings.electrical.blocks_equipment = [];
                    }
                }
            },
            validation: {
                updatedProps: [],
                deletedProps: [],
            },
        },
    ]
}
