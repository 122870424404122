
import { RGBA, type RGBAHex, RgbaPalette } from 'engine-utils-ts';
import { StateType } from '../bimConfigs/ConfigsArchetypes';
import type { Bim } from '../Bim';
import { ColorProperty, NumberProperty, StringProperty } from '../properties/PrimitiveProps';
import { PropsGroupsRegistry } from '../properties/PropsGroupsRegistry';
import { PropsGroupBase } from '../properties/Props';
import { PropsFieldArrayOf } from '../properties/PropsGroupComplexDefaults';
import { PileDamperType, type PileFeaturesFlags, PileMotorType, PileStrengthModifier, PileWeightClass, unpackPileFeatures } from '../anyTracker/PilesFeatures';
import { DefaultPileTypes, PileTypeSettings, TypeDescription } from './DefaultTypeSettings';

export const PileBinsConfigType = 'pile-bins-config';
const firstBinReveal = NumberProperty.new({ value: 4, unit: 'ft' });
const defaultColor = ColorProperty.new({ value: RGBA.parseFromHexString("#a3a5a4"), isReadonly: true});

const colorPalette = new RgbaPalette([
    RGBA.parseFromHexString("#368BEE"),
    RGBA.parseFromHexString("#BE36EE"),
    RGBA.parseFromHexString("#87D902"),
    RGBA.parseFromHexString("#F85900"),
]);

export class BinProps extends PropsGroupBase {
    maxReveal: NumberProperty;
    standardColor: ColorProperty;
    heavyColor: ColorProperty;
    typeDescriptions: TypeDescription[];

    constructor(args: Partial<BinProps>, averageEmbedment?: number) {
        super();
        this.maxReveal = args.maxReveal ?? firstBinReveal;
        this.standardColor = args.standardColor ?? defaultColor;
        this.heavyColor = args.heavyColor ?? defaultColor;
        this.typeDescriptions = args.typeDescriptions ?? BinProps.getDefaultTypeDescriptions(this.maxReveal.as('m') + (averageEmbedment ?? 0));
    }

    getColor(type: PileWeightClass): ColorProperty {
        return type === PileWeightClass.Standard ? this.standardColor : this.heavyColor;
    }

    getIndexOfType(pileFeatures: PileFeaturesFlags): number {
        for (let i = 0; i < this.typeDescriptions.length; i++) {
            if (this.typeDescriptions[i].pileFeatures.value === pileFeatures) {
                return i;
            }
        }
        return -1;
    }

    getTypeByFeaturesKey(pileFeatures: PileFeaturesFlags): TypeDescription | undefined {
        for (const type of this.typeDescriptions) {
            if (type.pileFeatures.value === pileFeatures) {
                return type;
            }
        }
        return undefined;
    }

    withDifferentColor(type: PileWeightClass, value: RGBAHex): BinProps {
        return new BinProps({
            ...this,
            [type === PileWeightClass.Standard ? "standardColor" : "heavyColor"]:
                this.getColor(type).withDifferentValue(value),
        });
    }

    withDifferentReveal(value: number): BinProps {
        const maxReveal = this.maxReveal.withDifferentValue(value);
        const revealInMeters = maxReveal.as('m');
        return new BinProps({
            ...this,
            maxReveal,
            typeDescriptions: this.typeDescriptions.map(description =>
                revealInMeters < description.length.value
                ? description
                : new TypeDescription({
                    ...description,
                    length: description.length.withDifferentValue(revealInMeters),
                })
            )
        });
    }

    static getDefaultTypeDescriptions(defaultLength: number): TypeDescription[] {
        const typeDescriptions = [];
        for (const [key, type] of DefaultPileTypes) {
            typeDescriptions.push(new TypeDescription({
                pileFeatures: NumberProperty.new({ value: key }),
                length: NumberProperty.new({ value: defaultLength, unit: 'm'}),
                profile: type.profile,
            }));
        }
        return typeDescriptions;
    }
}

PropsGroupsRegistry.register({
    class: BinProps,
    complexDefaults: {
        typeDescriptions: new PropsFieldArrayOf(
            {},
            TypeDescription,
        )
    }
});

export class TrackerBins extends PropsGroupBase {
    tracker: StringProperty;
    bins: BinProps[];
    pileTypes: PileTypeSettings[];
    standard: StringProperty;
    heavy: StringProperty;
    motor: StringProperty;
    default: StringProperty;
    edge: StringProperty;
    special: StringProperty;
    damper: StringProperty;

    constructor(args: Partial<TrackerBins>) {
        super();
        this.tracker = args.tracker ?? StringProperty.new({value: "", isReadonly: true});
        this.bins = args.bins ?? [];
        this.pileTypes = args.pileTypes ?? TrackerBins.getDefaultTypeSettings();
        this.standard = args.standard ?? StringProperty.new({ value: "Standard" });
        this.heavy = args.heavy ?? StringProperty.new({ value: "Heavy" });
        this.motor = args.motor ?? StringProperty.new({ value: "array Motor pier" });
        this.default = args.default ?? StringProperty.new({ value: "array pier" });
        this.edge = args.edge ?? StringProperty.new({ value: "array pier, Edge" });
        this.special = args.special ?? StringProperty.new({ value: "Cantilever" });
        this.damper = args.damper ?? StringProperty.new({ value: "Damper" });
    }

    static createDefaultTrackerBins(name: string): TrackerBins {
        return new TrackerBins({
            tracker: StringProperty.new({value: name, isReadonly: true}),
            bins: [
                new BinProps({
                    maxReveal: NumberProperty.new({ value: Infinity, unit: 'ft' }),
                }),
            ]
        })
    }

    static getDefaultTypeSettings(): PileTypeSettings[] {
        const typeSettings = [];
        for (const [key, type] of DefaultPileTypes) {
            typeSettings.push(type.settings.withPileFeatures(key));
        }
        return typeSettings;
    }

    getTypeByFeaturesKey(pileFeatures: PileFeaturesFlags): PileTypeSettings | undefined {
        for (const type of this.pileTypes) {
            if (type.pileFeatures.value === pileFeatures) {
                return type;
            }
        }
        return undefined;
    }

    withNewProps(props: Partial<TrackerBins>) {
        return new TrackerBins({
            ...this,
            ...props,
        })
    }

    withSortedBins(bins: BinProps[]): TrackerBins {
        return new TrackerBins({...this, bins: bins.sort(
            (a, b) => a.maxReveal.value - b.maxReveal.value,
        )});
    }

    get newBinReveal() {
        const prevBin = this.bins.at(-2);
        return prevBin ? prevBin.maxReveal.withDifferentValue(prevBin.maxReveal.value + 1) : firstBinReveal;
    }

    createNewBin(averageEmbedment: number): BinProps {
        return new BinProps({
            maxReveal: this.newBinReveal,
            standardColor: ColorProperty.new({ value: colorPalette.get((this.bins.length-1)*2) }),
            heavyColor: ColorProperty.new({ value: colorPalette.get((this.bins.length-1)*2 + 1) }),
        }, averageEmbedment);
    }

    getPileFullName(features: PileFeaturesFlags): string {
        const flags = unpackPileFeatures(features);
        const prefix = flags.weight_class === PileWeightClass.Standard ? this.standard.value : this.heavy.value;
        const motorPartName = flags.motor === PileMotorType.Motor ? this.motor.value : null;
        const name = flags.modifier === PileStrengthModifier.None ?
            this.default.value :
            flags.modifier === PileStrengthModifier.Edge ? this.edge.value : this.special.value;
        return `${prefix} ${motorPartName ?? name} ${flags.damper === PileDamperType.Damper ? this.damper.value : ''}`;
    }
}

PropsGroupsRegistry.register({
    class: TrackerBins,
    complexDefaults: {
        bins: new PropsFieldArrayOf(
            {},
            BinProps,
        ),
        pileTypes: new PropsFieldArrayOf(
            {},
            PileTypeSettings,
        )
    }
});

export class PileBinsConfig extends PropsGroupBase {
    trackerBins: TrackerBins[];

    constructor(args: Partial<PileBinsConfig>) {
        super();
        this.trackerBins = args.trackerBins ?? [];
        
    }

    getBinsByTracker(model: string): TrackerBins | undefined {
        for (const tb of this.trackerBins) {
            if (tb.tracker.value === model) {
                return tb;
            }
        }
        return undefined;
    }
}
PropsGroupsRegistry.register({
    class: PileBinsConfig,
    complexDefaults: {
        trackerBins: new PropsFieldArrayOf(
            {},
            TrackerBins,
        )
    }
});

export function registerPileBinsConfig(bim: Bim) {
    bim.configs.archetypes.registerArchetype({
        type_identifier: PileBinsConfig.name,
        properties: () => ({}),
        propsClass: PileBinsConfig,
        stateType: StateType.Singleton,
    });
}