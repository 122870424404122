import type { LazyVersioned } from "engine-utils-ts";
import { LazyDerivedAsync } from "engine-utils-ts";
import type { Bim } from "src";
import { NumberProperty } from "src";
import type { CostsConfigProvider, EstimateCost} from "src/cost-model/capital";
import { CostHierarchy, PVModuleTopCategoryName, createEmptyCostComponentsNonNullable, fillModelBasedTopLevelCategory } from "src/cost-model/capital";
import { create_PVModules_Install } from "./modules-install";

export function create_ModuelsSubtotal(
    bim: Bim,
    costs: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>
) {
    const unitsMapper = bim.unitsMapper;

    const moduleInstallEach = create_PVModules_Install(bim, costs, totalDC);

    const result = LazyDerivedAsync.new3<
        CostHierarchy,
        CostHierarchy,
        EstimateCost[],
        NumberProperty
    >(
        'ElectricalSubtotal_Electrical_Cable',
        [unitsMapper],
        [moduleInstallEach,costs.allEstimateCosts, totalDC],
        function* ([moduleInstallEach, estimates, totalDC]) {
            const hierarchy = new CostHierarchy().merge(moduleInstallEach);

            // misc
            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'Modules General Conditions' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.materialCost = NumberProperty.new({ value: 0.005 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'modules-general-condition-misc',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            // misc
            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'Modules Logistics & Misc.' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.materialCost = NumberProperty.new({ value: 0.01 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'modules-estimate-misc',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }



            // create module subtotal root category
            {
                const root = hierarchy.addRoot({ description: { value: PVModuleTopCategoryName } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.materialCost = NumberProperty.new({ value: 0.452 });
                defaults.laborCost = NumberProperty.new({ value: 0.008 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    hierarchy,
                    'modules-subtotal-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
            }

            return hierarchy;
        }
    )

    return result;
}
