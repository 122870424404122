// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { StdSubmeshRepresentationT } from './std-submesh-representation.js';
import { StdSubmeshRepresentation } from './std-submesh-representation.js';
import type { SubmeshGroupRepresentationT } from './submesh-group-representation.js';
import { SubmeshGroupRepresentation } from './submesh-group-representation.js';


export class StdGroupedMeshRepresentation implements flatbuffers.IUnpackableObject<StdGroupedMeshRepresentationT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):StdGroupedMeshRepresentation {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsStdGroupedMeshRepresentation(bb:flatbuffers.ByteBuffer, obj?:StdGroupedMeshRepresentation):StdGroupedMeshRepresentation {
  return (obj || new StdGroupedMeshRepresentation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsStdGroupedMeshRepresentation(bb:flatbuffers.ByteBuffer, obj?:StdGroupedMeshRepresentation):StdGroupedMeshRepresentation {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new StdGroupedMeshRepresentation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

submeshes(index: number, obj?:StdSubmeshRepresentation):StdSubmeshRepresentation|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new StdSubmeshRepresentation()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

submeshesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

submeshesGrouped(index: number, obj?:SubmeshGroupRepresentation):SubmeshGroupRepresentation|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new SubmeshGroupRepresentation()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

submeshesGroupedLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

lod0DetailSize():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

lod1Submeshes(index: number, obj?:StdSubmeshRepresentation):StdSubmeshRepresentation|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new StdSubmeshRepresentation()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

lod1SubmeshesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startStdGroupedMeshRepresentation(builder:flatbuffers.Builder) {
  builder.startObject(4);
}

static addSubmeshes(builder:flatbuffers.Builder, submeshesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, submeshesOffset, 0);
}

static createSubmeshesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startSubmeshesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addSubmeshesGrouped(builder:flatbuffers.Builder, submeshesGroupedOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, submeshesGroupedOffset, 0);
}

static createSubmeshesGroupedVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startSubmeshesGroupedVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addLod0DetailSize(builder:flatbuffers.Builder, lod0DetailSize:number) {
  builder.addFieldFloat64(2, lod0DetailSize, 0.0);
}

static addLod1Submeshes(builder:flatbuffers.Builder, lod1SubmeshesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, lod1SubmeshesOffset, 0);
}

static createLod1SubmeshesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startLod1SubmeshesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endStdGroupedMeshRepresentation(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createStdGroupedMeshRepresentation(builder:flatbuffers.Builder, submeshesOffset:flatbuffers.Offset, submeshesGroupedOffset:flatbuffers.Offset, lod0DetailSize:number, lod1SubmeshesOffset:flatbuffers.Offset):flatbuffers.Offset {
  StdGroupedMeshRepresentation.startStdGroupedMeshRepresentation(builder);
  StdGroupedMeshRepresentation.addSubmeshes(builder, submeshesOffset);
  StdGroupedMeshRepresentation.addSubmeshesGrouped(builder, submeshesGroupedOffset);
  StdGroupedMeshRepresentation.addLod0DetailSize(builder, lod0DetailSize);
  StdGroupedMeshRepresentation.addLod1Submeshes(builder, lod1SubmeshesOffset);
  return StdGroupedMeshRepresentation.endStdGroupedMeshRepresentation(builder);
}

unpack(): StdGroupedMeshRepresentationT {
  return new StdGroupedMeshRepresentationT(
    this.bb!.createObjList<StdSubmeshRepresentation, StdSubmeshRepresentationT>(this.submeshes.bind(this), this.submeshesLength()),
    this.bb!.createObjList<SubmeshGroupRepresentation, SubmeshGroupRepresentationT>(this.submeshesGrouped.bind(this), this.submeshesGroupedLength()),
    this.lod0DetailSize(),
    this.bb!.createObjList<StdSubmeshRepresentation, StdSubmeshRepresentationT>(this.lod1Submeshes.bind(this), this.lod1SubmeshesLength())
  );
}


unpackTo(_o: StdGroupedMeshRepresentationT): void {
  _o.submeshes = this.bb!.createObjList<StdSubmeshRepresentation, StdSubmeshRepresentationT>(this.submeshes.bind(this), this.submeshesLength());
  _o.submeshesGrouped = this.bb!.createObjList<SubmeshGroupRepresentation, SubmeshGroupRepresentationT>(this.submeshesGrouped.bind(this), this.submeshesGroupedLength());
  _o.lod0DetailSize = this.lod0DetailSize();
  _o.lod1Submeshes = this.bb!.createObjList<StdSubmeshRepresentation, StdSubmeshRepresentationT>(this.lod1Submeshes.bind(this), this.lod1SubmeshesLength());
}
}

export class StdGroupedMeshRepresentationT implements flatbuffers.IGeneratedObject {
constructor(
  public submeshes: (StdSubmeshRepresentationT)[] = [],
  public submeshesGrouped: (SubmeshGroupRepresentationT)[] = [],
  public lod0DetailSize: number = 0.0,
  public lod1Submeshes: (StdSubmeshRepresentationT)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const submeshes = StdGroupedMeshRepresentation.createSubmeshesVector(builder, builder.createObjectOffsetList(this.submeshes));
  const submeshesGrouped = StdGroupedMeshRepresentation.createSubmeshesGroupedVector(builder, builder.createObjectOffsetList(this.submeshesGrouped));
  const lod1Submeshes = StdGroupedMeshRepresentation.createLod1SubmeshesVector(builder, builder.createObjectOffsetList(this.lod1Submeshes));

  return StdGroupedMeshRepresentation.createStdGroupedMeshRepresentation(builder,
    submeshes,
    submeshesGrouped,
    this.lod0DetailSize,
    lod1Submeshes
  );
}
}
