import type { LazyVersioned, ObservableObject} from "engine-utils-ts";
import { LazyDerived, IterUtils, ObjectUtils, Success } from "engine-utils-ts";
import type { PUI_BuilderParams } from "./PUI_Builder";
import { PUI_Builder, PUI_BuilderCallbacks } from "./PUI_Builder";
import type { PUI_ConfigPropertyTransformer } from "./PUI_ConfigPropertyTransformer";
import type { PUI_Node, PUI_PropertyChangeCallback, PUI_PropertyNodeArgs } from "./PUI_Nodes";
import { PropertyActionsValue, PUI_GroupNode, PUI_PropertyNode } from "./PUI_Nodes";
import type { DefaultMessage} from "./UI_Source";
import { PUI_Lazy } from "./UI_Source";


export class PUI_ConfigBasedBuilderParams {

    constructor(
        readonly toTransform: [PUI_ConfigPropertyFitChecker, PUI_ConfigPropertyTransformer<any, any, any, any>][],
        readonly toSkip: PUI_ConfigPropertyFitChecker[],
        readonly toOverrideBuildAt: [PUI_ConfigPropertyFitChecker, (builder: PUI_Builder) => void][],
    ){
    }

    mergedWith(other: Partial<PUI_ConfigBasedBuilderParams>) {
        return new PUI_ConfigBasedBuilderParams(
            (other.toTransform ?? []).concat(this.toTransform),
            (other.toSkip ?? []).concat(this.toSkip),
            (other.toOverrideBuildAt ?? []).concat(this.toOverrideBuildAt),
        )
    }

    static new(
        toTransform: [string[] | string | PropertyFitChecker, PUI_ConfigPropertyTransformer<any, any, any, any>][],
        toSkip?: (string[] | string | PropertyFitChecker)[],
        toOverrideBuildAt?: [(string[] | string | PropertyFitChecker), (builder: PUI_Builder) => void][],
    ) {
        return new PUI_ConfigBasedBuilderParams(
            toTransform.map(t => [new PUI_ConfigPropertyFitChecker(t[0]), t[1]]),
            toSkip?.map(t => new PUI_ConfigPropertyFitChecker(t)) || [],
            toOverrideBuildAt?.map(t => [new PUI_ConfigPropertyFitChecker(t[0]), t[1]]) || [],
        );
    }
}

export function buildFromLazyConfigObjectWithGlobalContext<Config extends Object, Context, OwnedContext extends Object>(args: {
    createConfigObj:(c:OwnedContext) => LazyVersioned<{ configSample: Config, context: Context }>,
    createContext: () => OwnedContext,
    disposeContext:(c: OwnedContext) => void,
	configBuilderParams?: PUI_ConfigBasedBuilderParams,
    puiBuilderParams?: Omit<PUI_BuilderParams, 'callbacks'>,
    afterRootNodeCallback?: ((b: PUI_Builder, node: PUI_Node, context: Context, ownedContext:OwnedContext) => void)[],
    patchCallback: (patch: Partial<Config>, context: Context) => void,
    defaultMessage?: DefaultMessage
}) {
    const createContext = (ownedContext:OwnedContext)=>{
        const lazyConfigObj = args.createConfigObj(ownedContext);
        return LazyDerived.new1(
            '',
            [],
            [lazyConfigObj],
            ([{configSample, context}]) => {
                const callbacks = new PUI_BuilderCallbacks();
                if(args.afterRootNodeCallback){
                    for (const c of args.afterRootNodeCallback) {
                        callbacks.addTypeFilteredAfterNodeCallback(PUI_GroupNode, (b, node)=>{
                            if (node.parent) {
                                return;
                            }
                            c(b, node, context, ownedContext);
                        })
                    }
                }
                const pui = buildFromConfigObject({
                    configBuilderParams: args.configBuilderParams,
                    configObj: configSample,
                    puiBuilderParams:{...args.puiBuilderParams, callbacks},
                    configPatchesCallback: (patch) => {
                        args.patchCallback(patch, context);
                    },
                });

                return pui;
            }
        ).withoutEqCheck();
    }

    return new PUI_Lazy(
        createContext,
        args.defaultMessage,
        args.createContext,
        args.disposeContext
    );
}

export function buildFromObservableConfigObject<Config extends Object>(args: {
	configObj: ObservableObject<Config>,
	configBuilderParams?: PUI_ConfigBasedBuilderParams,
    puiBuilderParams?: PUI_BuilderParams,
    defaultMessage?: DefaultMessage,
}): PUI_Lazy<PUI_GroupNode> {
    const puiLazy = LazyDerived.new1(
        '',
        [],
        [args.configObj],
        ([obj]) => {

            const pui = buildFromConfigObject({
                configObj: obj,
                configBuilderParams: args.configBuilderParams,
                puiBuilderParams: args.puiBuilderParams,
                configPatchesCallback: (patch) => {
                    args.configObj.applyPatch({patch});
                }
            });

            return pui;
        }
    ).withoutEqCheck();

    return new PUI_Lazy(
          puiLazy,
          args?.defaultMessage,
    );
}

interface OnTypeFilteredAfterNodeCallbackType<N extends PUI_Node, Params, Context> {
    ctor: {new(params: Params): N};
    callBack: (b: PUI_Builder, node: N, context: Context)=>void;
}

export function buildFromLazyConfigObject<Config extends Object, Context>(args: {
    configObj: LazyVersioned<{ configSample: Config, context: Context }>,
	configBuilderParams?: PUI_ConfigBasedBuilderParams,
    puiBuilderParams?: {
        rootName?: string,
        sortChildrenDefault?: boolean,
        onAfterEveryNodeCallbacks?: ((b: PUI_Builder, node: PUI_Node, context: Context) => void)[],
        onTypeFilteredAfterNodeCallbacks?: OnTypeFilteredAfterNodeCallbackType<any, any, Context>[],
    },
    patchCallback: (patch: Partial<Config>, context: Context) => void,
    defaultMessage?: DefaultMessage,
}): PUI_Lazy<PUI_GroupNode> {

    const puiLazy = LazyDerived.new1(
        '',
        [],
        [args.configObj],
        ([{configSample, context}]) => {
            const callbacks = new PUI_BuilderCallbacks();
            if(args.puiBuilderParams?.onAfterEveryNodeCallbacks){
                for (const c of args.puiBuilderParams.onAfterEveryNodeCallbacks) {
                    callbacks.addAfterEveryNodeCallback((b, node)=>{
                        c(b, node, context);
                    })
                }
            }
            if(args.puiBuilderParams?.onTypeFilteredAfterNodeCallbacks){
                for (const c of args.puiBuilderParams.onTypeFilteredAfterNodeCallbacks) {
                    callbacks.addTypeFilteredAfterNodeCallback(c.ctor, (b, node)=>{
                        c.callBack(b, node, context);
                    })
                }
            }
            const pui = buildFromConfigObject({
                configBuilderParams: args.configBuilderParams,
                configObj: configSample,
                puiBuilderParams: {
                    rootName: args.puiBuilderParams?.rootName,
                    sortChildrenDefault: args.puiBuilderParams?.sortChildrenDefault,
                    callbacks,
                },
                configPatchesCallback: (patch) => {
                    args.patchCallback(patch, context);
                },
            });

            return pui;
        }
    ).withoutEqCheck();

    return new PUI_Lazy(
        puiLazy,
        args.defaultMessage
    );
}

export function buildFromConfigObject<Config extends Object>(args: {
	configObj: Config,
	configBuilderParams?: PUI_ConfigBasedBuilderParams,
    puiBuilderParams?: PUI_BuilderParams,
	configPatchesCallback: (patch: Partial<Config>) => void,
}): PUI_GroupNode {

    return buildPuiFromObject({
        sourceObject: args.configObj,
        configBuilderParams: args.configBuilderParams,
        puiBuilderParams: args.puiBuilderParams,
        onAnyChange: (newValue: any, inSourceObjectPath: (string | number)[], uiNode: PUI_PropertyNode<any>) => {
            const partialPatch: Partial<Config> | undefined = createPartialObjectPatchFromDeepChange<Config>(
                args.configObj,
                inSourceObjectPath, // temp cast, add numbers for arrays path
                newValue
            );
            if (partialPatch !== undefined) {
                args.configPatchesCallback(partialPatch);
            }
        },
    });

}



export function buildPuiFromObject(args: {
	sourceObject: Object,
	configBuilderParams?: PUI_ConfigBasedBuilderParams,
    puiBuilderParams?: PUI_BuilderParams,
	onAnyChange: (newValue: any, fullPath: (string|number)[], uiNode: PUI_PropertyNode<any>) => void,
}): PUI_GroupNode {
    const b = new PUI_Builder(args.puiBuilderParams ?? {});

	function iterateObjectFields_r(objValue: any, path: (string|number)[], parent: Object|undefined): void {
		if (typeof objValue === 'object') {
			for (const name in objValue) {
				const value = objValue[name];


                if (args.configBuilderParams) {
                    const {handled} = tryAddPuiPropertyNode({
                        sourceValue: value,
                        name,
                        path,
                        outerContext: parent,
                        propsMappingParams: args.configBuilderParams,
                        puiBuilder: b,
                        onChange: args.onAnyChange
                    });
                    if (handled) {
                        continue;
                    }
                }
                const fullPath: (string | number)[] = [...path, name];

                const onChangeCallback: PUI_PropertyChangeCallback<any> = (newValue: any, puiPropNode) => {
                    args.onAnyChange(newValue, fullPath, puiPropNode);
                };

                if (value instanceof PropertyActionsValue) {
					b.addActionsNode({name: name, actions: value.actions, context: value});
				} else if (((typeof value) == 'string') && name.includes('color')) {
					b.addColorProp({name: name, value, onChange: onChangeCallback});
				} else if (Array.isArray(value) && value.length > 0 && value.every(v => typeof v === 'string')) {
					b.addSelectorProp({name: name, options: value, value: value[0], onChange: onChangeCallback});
				} else if (typeof value == 'number') {
					b.addNumberProp({name: name, value, onChange: onChangeCallback});
				} else if (typeof value == 'boolean') {
					b.addBoolProp({name: name, value, onChange: onChangeCallback});
				} else if (typeof value == 'string') {
					b.addStringProp({name: name, value, onChange: onChangeCallback});

                } else if (typeof value == 'object') {
					b.inGroup({name: name}, () => {
						iterateObjectFields_r(value, fullPath, objValue);
					})
				}
			}
		}
	};

	iterateObjectFields_r(args.sourceObject, [], undefined);

	return b.finish();
}

export function tryAddPuiPropertyNode<T>(args: {
    puiBuilder: PUI_Builder,
    sourceValue: T,
    name: (string|number),
    path: (string|number)[],
    outerContext: Object|undefined,
    onChange: (newValue: T, fullPath: (string | number)[], uiNode: PUI_PropertyNode<any>) => void,
    propsMappingParams: PUI_ConfigBasedBuilderParams,
    additionalNodeParams?: Partial<PUI_PropertyNodeArgs<T>>
}): {handled: boolean} {

    const value = args.sourceValue;
    if (value === undefined) {
        return {handled: true};
    }

    const name = args.name;

    if (typeof name === 'string' && name.startsWith("_")) {
        return {handled: true};
    }

    if (args.propsMappingParams.toSkip.some(s => s.fits(args.name, value, args.path))) {
        return {handled: true};
    }

    const overrider = IterUtils.findBackToFront(
        args.propsMappingParams.toOverrideBuildAt,
        ([fitter]) => fitter.fits(args.name, value, args.path)
    );

    if (overrider) {
        const fn = overrider[1];
        try {
            fn(args.puiBuilder);
        } catch (e) {
            console.error(e);
        }
        return {handled: true};
    }

    const fitterTransformer = IterUtils.findBackToFront(
        args.propsMappingParams.toTransform,
        ([fitter, tr]) => {
            if (tr.requiredOutContextClass && !(args.outerContext instanceof tr.requiredOutContextClass)) {
                return false;
            }
            return fitter.fits(name, value, args.path)
        }
    );

    if (fitterTransformer != undefined) {
        const tr = fitterTransformer[1] as PUI_ConfigPropertyTransformer<any, any, any, any, any>;
        const mappedValue = tr.mapObjectFieldToPuiNodeValue(value, args.outerContext);

        let [ctor, params] = tr.addNode(value, mappedValue, args.outerContext);

        const onChangeCallback: PUI_PropertyChangeCallback<any> = (newValue: any, puiPropNode) => {
            args.onChange(newValue, [...args.path, args.name], puiPropNode);
        };

        if (ctor.prototype instanceof PUI_PropertyNode) {
            const newParams: PUI_PropertyNodeArgs<any> = {
                ...params,
                name: args.name.toString(),
                value: mappedValue,
                onChange: (newMappedValue, node) => {
                    const configValueResult = tr.mapFromPuiPNodeBackToObjField(newMappedValue, value);
                    if (configValueResult instanceof Success) {
                        onChangeCallback(configValueResult.value, node)
                    } else {
                        console.warn('could not convert back property', configValueResult.errorMsg(), node.getPathFromRoot());
                    }
                }
            };
            params = newParams;
        }
        args.puiBuilder.addNode(ctor, {
            ...params,
            ...args.additionalNodeParams
        });
        return {handled: true};
    }

    return {handled: false}
}

export function createPartialObjectPatchFromDeepChange<T extends Object>(
	fullObject: Readonly<T>,
	changePath: (string | number)[],
	newValue: any,
): Partial<T> | undefined {
	if (changePath.length === 0 || typeof fullObject !== 'object') {
		console.error('unexpected args', fullObject, changePath, changePath);
		return undefined;
	}

	const resultPatch: Partial<T> = {};

	let objToReplaceFieldIn = resultPatch;
	if (changePath.length > 1) {
		(resultPatch as any)[changePath[0]] = ObjectUtils.deepCloneObj((fullObject as any)[changePath[0]]);
		for (let i = 0; i < changePath.length - 1; ++i) {
			const key = changePath[i];
			objToReplaceFieldIn = (objToReplaceFieldIn as any)[key];
			if (objToReplaceFieldIn === undefined) {
				console.error('erroneous patch path', fullObject, changePath, newValue, objToReplaceFieldIn);
				return undefined;
			}
		}
	}
	const lastKey = changePath.at(-1)!;
	(objToReplaceFieldIn as any)[lastKey] = newValue;

	return resultPatch;
}

export type PropertyFitChecker = (propertyName: string|number, propertyValue: any, propertyPath: (string|number)[]) => boolean;
export class PUI_ConfigPropertyFitChecker {

    fits: PropertyFitChecker;

    constructor(
        propertyPathEndingOrLambda: (string|number) | (string|number)[] | PropertyFitChecker
    ){
        if (typeof propertyPathEndingOrLambda == 'function') {
            this.fits = propertyPathEndingOrLambda;
        } else {
            const pathAsArray = Array.isArray(propertyPathEndingOrLambda) ?
                propertyPathEndingOrLambda
                : [propertyPathEndingOrLambda];
            this.fits = (propName: string|number, propValue: any, propPath: (string|number)[]) => {
                if (propName !== pathAsArray.at(-1)) {
                    return false;
                }
                for (let i = 1; i < pathAsArray.length; ++i) {
                    const expectedNode = pathAsArray.at((i * -1) -1);
                    const realNode = propPath.at(-i);
                    if (expectedNode !== realNode) {
                        return false;
                    }
                }
                return true;
            }
        }
    }
}
