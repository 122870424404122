<script lang="ts">
import { createKeyPropertiesGroupFormatters, UnitsMapper, type AssetId, type Catalog } from "bim-ts";
import type { UiBindings } from "ui-bindings";

import { getContext, onDestroy, onMount, setContext } from "svelte";
import { VersionedStore } from "../VersionedStore";
import { CatalogItemManager, SetCatalogSearchEvent, SetCatalogSearchEventTypeIdent } from "./CatalogItemManager";

import { ButtonComponent, IconButton } from '../libui/button';
import { VerDataSyncStatus } from "verdata-ts";
import type { ContextMenu} from "../libui/context-menu";
import { Action, ContextMenuStructure } from "../libui/context-menu";
import EditCatalogItem from './EditCatalogItem.svelte';
import { TextField } from "../libui/fields";
import type { CreateContextMenuFn } from "./types";
import { LazyDerived } from "engine-utils-ts";
import dayjs from 'dayjs';
import Hierarchy from './Hierarchy.svelte';


export let createExtraMenu: (() => ContextMenuStructure) | undefined = undefined;
export let createExtraMenuForAsset: CreateContextMenuFn | undefined = undefined; 
export let assetDoubleClickAction: ((id: AssetId) => void) | undefined = undefined;

export let status: string = '';


let catalog = getContext<Catalog>('catalog');
let contextMenu = getContext<ContextMenu>('context-menu');
let uiBindings = getContext<UiBindings>('uiBindings');
let unitsMapper = getContext<VersionedStore<UnitsMapper>>('unitsMapper');


const siLabelsPropsGroupFormatter = createKeyPropertiesGroupFormatters($unitsMapper)
let catalogItemManager = setContext('catalogItem-manager', new VersionedStore(new CatalogItemManager(
    catalog,
    uiBindings,
    siLabelsPropsGroupFormatter,
    createExtraMenuForAsset,
    assetDoubleClickAction,
)))

$: status = $catalogItemManager.syncStatus;

let search: string = '';
function handleSearchChange(..._deps: any[]) {
    $catalogItemManager.changeSearch(search);
}
$: handleSearchChange(search);

type LastVersionMetadata = {
    lastSaveDate?: Date
}

let lastVersionedMetadata = new VersionedStore(LazyDerived.new0(
    'last-version-metadata',
    [
        { version: () => catalog.syncer?.history.version() ?? 0 },
        { version: () => catalog.syncer?.status.version() ?? 0 },
    ],
    (): LastVersionMetadata => {
        if (!catalog.syncer) {
            return {};
        }
        const currentVersionId = catalog.syncer.status.poll().activeVersionId;
        const currentVersion = catalog.syncer.history.poll().versions
            .find(x => x.id === currentVersionId);
        if (!currentVersion) {
            return {};
        }
        return {
            lastSaveDate: currentVersion.date
        };
    }).withoutEqCheck()
)

let menuStructure = createMenuStructure();

$: if ($lastVersionedMetadata) {
    menuStructure = createMenuStructure();
}

function createMenuStructure() {
    const lastSaveDate = $lastVersionedMetadata.lastSaveDate;
    const fromNowString = lastSaveDate ? dayjs(lastSaveDate).fromNow() : '';
    const menu = new ContextMenuStructure([
      new Action('Load predefined pack', async () => {
        await catalog.importDefaultAssetsPack()
      }),
      new Action('Upload file', () => {
        input.click();
      }),
      new Action(
        'Save',
        () => {
          catalog.syncer?.saveNewVersion({});
        },
        fromNowString,
        undefined,
        !catalog.syncer?.canStartSaveNow(),
      ),
    ])
    if (createExtraMenu) {
        menu.merge(createExtraMenu())
    }
    return menu;
}

$: if (lastVersionedMetadata) {
    menuStructure = createMenuStructure();
}

let interval: NodeJS.Timeout;
function handleSetCatalogSearchEvent(e: Event) {
    if (e instanceof SetCatalogSearchEvent) {
        search = e.search
    }
}
onMount(() => {
    interval = setInterval(() => {
        menuStructure = createMenuStructure();
    }, 2e3)
    window.addEventListener(SetCatalogSearchEventTypeIdent, handleSetCatalogSearchEvent);
})

onDestroy(() => {
    clearInterval(interval);
    window.removeEventListener(SetCatalogSearchEventTypeIdent, handleSetCatalogSearchEvent);
})


$: listInput = $catalogItemManager.generateListInputFromCatalogItemManagerView();
let syncerStatus = new VersionedStore(catalog.getSyncer().status).skipDispose();

let input: HTMLInputElement;

function handleFilesInputOnChangeEvent() {
    if (!input.files || !input.files.length) {
        return;
    }
    catalog.tasksRunner.newLongTask({
        defaultGenerator: $catalogItemManager.importAssetsFromFS(input.files),
        taskTimeoutMs: 600_000
    });
}

</script>


<input
  bind:this={input}
  style="display: none"
  type="file"
  multiple
  accept=".bimasset,.ond,.pan"
  on:click={() => input.value = ''}
  on:change={handleFilesInputOnChangeEvent}
/>

<div
  style="max-height: 100%; display: flex; flex-direction: column; height: 100%;"
>
  <div id="catalog-item-search-container">
    <TextField bind:value={search} labelText="search" leadingIcon="Search"/>
    <ButtonComponent desc={new IconButton(
      'MenuList',
      (e) => contextMenu.openMenuAtPosition(e.clientX, e.clientY, menuStructure),
      $syncerStatus.syncStatus !== VerDataSyncStatus.Loaded
    )}/>
  </div>
  <div>
    <div style="display:flex; gap:8px; margin-left: 15px; margin-bottom: 10px">
        <ButtonComponent desc={new IconButton('CollapseAll', () => {
          $catalogItemManager.hideAll();
        })} />
        <ButtonComponent desc={new IconButton('ExpandAll', () => {
          $catalogItemManager.expandAll();
        })} />
    </div>
  </div>
  <div style="overflow-y: auto; flex: 1;">
    <Hierarchy hierarchy={$catalogItemManager.view} />
  </div>
  {#if $catalogItemManager.catalogItemEditMode}
    <div id="edit-catalog-item-controller" class="mdc-elevation--z6">
      <EditCatalogItem item={$catalogItemManager.catalogItemEditMode} />
    </div>
  {/if}
</div>

<style lang="scss">
#catalog-item-search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  gap: 8px;
}
#edit-catalog-item-controller {
  display: absolute;
  left: 0;
  right: 0;
  padding: 10px 15px;
  overflow-y: auto;
  max-height: 300px;
}
</style>
