<script lang="ts">
    import { navigate } from "svelte-routing";
    import type { Project } from "../projects";
    import { getContext, onMount } from "svelte";
    import dayjs from "dayjs";
    import type { AuthHub } from "../auth";
    import ProjectTileButtons from "./ProjectTileButtons.svelte";
    import ProjectScreenshot from "./ProjectScreenshot.svelte";
    import type { Dashboard } from "./Dashboard";
    
    const auth = getContext<AuthHub>("authHub");
    const dashboard = getContext<Dashboard>("dashboard");

    export let project: Project;
    export let onRemove: (id: number) => void;

    $: isOwner = $auth.authData?.id === project.createBy;

    $: version = $dashboard.versions.get(project.id) || {};
    $: createdBy = version.createdBy ? " by " + version.createdBy : "";
    $: metrics = version?.additionalContext?.metrics;

    function getMetricString(metric: { value?: number; unit?: string }) {
        if (metric?.value) {
            return metric.unit
                ? `${Math.round(metric.value)} ${metric.unit}`
                : metric.value.toFixed(4);
        }
        return "";
    }
    let cardElement: HTMLDivElement;

    onMount(() => {
        let observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    dashboard.getProjectVersion(project.id);
                }
            });
        });
        observer.observe(cardElement);
        return () => {
            observer.disconnect();
        };
    });
</script>

<div
    on:click={() => navigate(project.id.toString())}
    class="card text-main-dark"
    bind:this={cardElement}
>
    <div class="card-container">
        <div class="card-info">
            <span class="mdc-typography--overline-small">
                #{project.id}: {project.status}
            </span>
            <div class="mdc-typography--headline3 project-name">{project.name}</div>
            <div class="info">
                <div class="mdc-typography--body2 info-label">Updated</div>
                <span class="mdc-typography--body2 info-data"
                    >{dayjs(version.date ?? project.updatedAt).format(
                        "D MMM 'YY"
                    )}</span
                >
            </div>
            {#if metrics}
                <div class="info">
                    <div class="mdc-typography--body2 info-label">DC Power</div>
                    <span class="mdc-typography--body2 info-data"
                        >{getMetricString(metrics?.dcPower)}</span
                    >
                </div>
                <div class="info">
                    <div class="mdc-typography--body2 info-label">AC Power</div>
                    <span class="mdc-typography--body2 info-data"
                        >{getMetricString(metrics?.acPower)}</span
                    >
                </div>
                <div class="info">
                    <div class="mdc-typography--body2 info-label">
                        Footprint
                    </div>
                    <span class="mdc-typography--body2 info-data"
                        >{getMetricString(metrics?.footprint)}</span
                    >
                </div>
                <div class="info">
                    <div class="mdc-typography--body2 info-label">
                        Modules area
                    </div>
                    <span class="mdc-typography--body2 info-data"
                        >{getMetricString(metrics?.modules)}</span
                    >
                </div>
                <div class="info">
                    <div class="mdc-typography--body2 info-label">GCR</div>
                    <span class="mdc-typography--body2 info-data"
                        >{getMetricString(metrics?.gcr)}</span
                    >
                </div>
            {/if}
        </div>
        <div class="project-screenshot">
            <ProjectScreenshot
                projectId={project.id}
                versionId={version.id}
                size={184}
            />
            <div class="topbar">
                {#if isOwner}
                    <ProjectTileButtons {project} openDialog={onRemove} />
                {/if}
            </div>
        </div>
    </div>
    <div class="divider" />
    <div class="mdc-typography--body2">
        Version {version.id ?? 0}{createdBy}: {version.textDescription ||
            "No description"}
    </div>
</div>

<style lang="scss">
    .topbar {
        position: absolute;
        right: 0;
        top: 0;
    }
    .card {
        height: 248px;
        box-sizing: border-box;
        background: #eaebed;
        padding: 12px;
        cursor: pointer;
        overflow: hidden;
        z-index: 0;
    }
    .card-container {
        display: flex;
        justify-content: space-between;
    }
    .card-info {
        min-width: 0;
    }
    .project-name {
        height: 24px;
        margin-bottom: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .project-screenshot {
        position: relative;
    }
    .divider {
        height: 1px;
        width: calc(100% + 8px);
        margin: 0 -4px;
        background-color: var(--color-divider);
        margin: 4px 0;
    }
    .info {
        margin-bottom: 2px;
        .info-label,
        .info-data {
            padding: 2px 0;
        }
    }
    .info-label {
        width: 92px;
        margin-right: 4px;
        display: inline-block;
    }
    .info-data {
        font-weight: 600;
    }
</style>
