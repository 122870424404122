import { CellStyle, ColDef, ICellRendererComp, ICellRendererParams, ValueFormatterFunc } from 'ag-grid-enterprise';
import { CostModel as CM } from 'bim-ts';
import { commonCellClasses, defaultCellStyle, getContext } from './common';
import { GridContext } from '../types';
import DescriptionCell from '../../DescriptionCell.svelte';

export const createDescriptionColumnDefinition = (): ColDef<CM.FlattenedCostCategoryParams> => ({
    colId: CM.DescriptionColId,
    headerName: CM.DescriptionColId,
    flex: 2,
    minWidth: 250,
    maxWidth: 400,
    pinned: 'left',

    cellStyle: (params) => {
        if (!params.data) {
            return null;
        }
        const styles: CellStyle = {
            ...defaultCellStyle,
            "text-align": 'left',
        }
        if (params.data.nestLevel) {
            styles['padding-left'] = `${params.data.nestLevel * 15}px`
        }
        return styles;
    },

    valueGetter: (params) => {
        const {category} = getContext(params);
        if (!category) {
            return;
        }
        return category.description.value
    },


    cellClass: (params) => {
       if (!params.data) {
            return null;
        }
        let isMainCategory = params.data.nestLevel <= 1 || !params.data.isBottom;
        const classes = new Set(commonCellClasses(getContext(params), x => x.description));
        classes.add('ag-grid-cell-with-right-separator')
        if (isMainCategory) {
            classes.add('ag-grid-cell-bold')
        }
        return Array.from(classes);
    },

    valueFormatter: ((params) => {
        if (!params.data || typeof params.value !== 'string') {
            return;
        }
        return ' '.repeat(params.data.nestLevel * 4) + params.value;
    }) as ValueFormatterFunc<CM.FlattenedCostCategoryParams, string>,

    cellRenderer: CellRenderer,

    headerClass: [
        'ag-grid-cell-with-right-separator',
        'ag-grid-header-align-left'
    ]
})

class CellRenderer implements ICellRendererComp<CM.FlattenedCostCategoryParams> {
    root!: HTMLSpanElement;
    svelteComp!: DescriptionCell;

    getGui() {
        return this.root;
    }
    destroy() {
        this.svelteComp.$destroy();
    }
    init(params: ICellRendererParams<CM.FlattenedCostCategoryParams, string, GridContext>) {
        const context = getContext(params);
        this.root = document.createElement('span');
        let disableHover = true;
        if (params.data?.categoryId) {
            disableHover = !context.globalContext.hierarchy.categoryWithSceneInstances.has(params.data.categoryId);
        }
        this.svelteComp = new DescriptionCell({
            target: this.root,
            props: {
                text: params.value ?? '',
                disableHover,
                onClick: () => {
                    if (!params.data?.categoryId) {
                        return;
                    }

                    const {globalContext} = getContext(params);

                    const ids = globalContext.hierarchy.getRelatedSceneInstance(params.data?.categoryId)
                    globalContext.bim.instances.setSelected(Array.from(ids))
                },
                secondAction: context.category?.description.onClick
            }

        });
    }
    refresh() {
        return false;
    }
}
