import type {
    Bim, GaugePack, IdBimScene, SceneInstancePatch} from 'bim-ts';
import { BimProperty, DC_CNSTS, NumberProperty
} from 'bim-ts';
import { DefaultMap, IterUtils, Yield } from 'engine-utils-ts';
import { sumReduce } from '../utils';
import type { Circuit } from './models/circuit';
import type { Connection } from './models/connection';
import { Node, getAggregatedCapacity } from './models/node';
import type { ConnsPerTypePerGauge, LossDropStats } from './models/utils';

export class Writer {

    private patchesPerId = new DefaultMap<IdBimScene, SceneInstancePatch[]>(() => []);
    private connsPerSi  =  new DefaultMap<IdBimScene, ConnsPerTypePerGauge>(() => 
        new DefaultMap(() => new DefaultMap<number, Connection[]>(() => []))
    );
    private lossDropPerSi: DefaultMap<IdBimScene, LossDropStats> =
        new DefaultMap(() => ({ drop: 0, losses: 0 }));

    private readonly voltageDropPath = ['circuit', 'lv_wiring', 'voltage_drop'];
    private readonly lossesPath = ['circuit', 'lv_wiring', 'losses'];
    private readonly gaugeBasePath = ['circuit', 'lv_wiring', 'actual_cables'];

    constructor(
        readonly bim: Bim,
        readonly circuit: Circuit,
        readonly gaugePack: GaugePack,
    ) {}

    *writeProps() {
        for (const chunk of IterUtils.splitIterIntoChunks(
            this.circuit.nodes.roots, 5,
        )) {
            for (const node of chunk) {
                Node.traverseBranch({
                    node, afterEach: node => {
                        this.gatherPropsFromNode(node);
                        return false;
                    },
                });
            }
            yield Yield.NextFrame;
        }
        yield* this.composeAndSubmitPatch();
    }

    private addConnectionGroupBySi(
        id: IdBimScene,
        type: DC_CNSTS.ConductorType,
        gaugeId: number,
        conns: Connection[],
    ) {
        if (type === DC_CNSTS.ConductorType.BusBars) {
            return;
        }
        const totalLosses = sumReduce(conns, x => x.lossDrop.losses);
        const first = conns[0];
        const temperature = first.temperatureParams.temperature;
        const gauge = this.gaugePack.gauges.get(gaugeId);
        let resistivity: NumberProperty | null = null;
        let amp: NumberProperty | null = null;
        let cableGroupName: string | null = null
        if (gauge) {
            resistivity = gauge.approxDcResistivityByTemperature(
                NumberProperty.new({
                    unit: 'C',
                    value: temperature,
                })
            );
            amp = gauge.approxAmpacityByTemperature(
                NumberProperty.new({
                    unit: 'C',
                    value: temperature,
                }),
                first.temperatureParams.isBuried,
            );
            cableGroupName = [DC_CNSTS.getConductorName(type), gauge.name, gauge.material]
                .join(' ');
        }
        const cableBasePath = [...this.gaugeBasePath, cableGroupName ?? 'other'];
        conns = conns.filter(x => !x.mergingToReq || !x.isNotMainInMergingGroup());

        const totalLength = sumReduce(
            conns.filter(x => x),
            x => sumReduce(x.route.getPairingRoutes(true), x => x.length),
        );
        if (totalLength < 0.5) return;

        if (!amp || !resistivity) return;
        const patch: SceneInstancePatch = {};
        patch.properties = [];
        patch.properties.push(
            [
                BimProperty.MergedPath([...cableBasePath, 'length']),
                {
                    path: [...cableBasePath, 'length'],
                    readonly: true,
                    unit: 'm',
                    value: totalLength,
                },
            ],
            [
                BimProperty
                    .MergedPath([...cableBasePath, 'debug', 'resistivity']),
                {
                    path: [...cableBasePath, 'debug', 'resistivity'],
                    readonly: true,
                    value: resistivity.value,
                    unit: resistivity.unit,
                },
            ],
            [
                BimProperty
                    .MergedPath([...cableBasePath, 'debug', 'temperature']),
                {
                    path: [...cableBasePath, 'debug', 'temperature'],
                    readonly: true,
                    value: temperature,
                },
            ],
            [
                BimProperty.MergedPath([...cableBasePath, 'debug', 'max_amp']),
                {
                    path: [...cableBasePath, 'debug', 'max_amp'],
                    readonly: true,
                    unit: amp.unit,
                    value: amp.value,
                },
            ],
            [
                BimProperty.MergedPath([...cableBasePath, 'losses']),
                {
                    path: [...cableBasePath, 'losses'],
                    readonly: true,
                    unit: 'W',
                    value: totalLosses,
                },
            ],
        );
        const patches = this.patchesPerId.getOrCreate(id);
        patches.push(patch);
    }

    private addConductorStatsToPatch() {
        for (const [id, map] of this.connsPerSi.entries()) {
            for (const [type, perType] of map.entries()) {
                for (const [gauge, conns] of perType.entries()) {
                    this.addConnectionGroupBySi(
                        id, type, gauge, conns,
                    );
                }
            }
        }
    }

    private addLossDropStatsToPatch() {
        for (const [id, stats] of this.lossDropPerSi.entries()) {
            const patches = this.patchesPerId.getOrCreate(id);
            patches.push({
                properties: [
                    [
                        BimProperty.MergedPath(this.voltageDropPath),
                        {
                            path: this.voltageDropPath,
                            value: stats.drop*100,
                            unit:'%',
                            readonly: true,
                        },
                    ],
                    [
                        BimProperty.MergedPath(this.lossesPath),
                        {
                            value: stats.losses,
                            unit: 'W',
                            path: this.lossesPath,
                            readonly: true,
                        },
                    ],
                ],
            });
        }
    }

    private createResetPropsPatch(id: IdBimScene) {
        const instance = this.bim.instances.perId.get(id);
        if (!instance)
            throw new Error('instance not found');
        const removeGaugeInfoPatch = instance.properties
            .createPatchToRemovePropsStartingWith(
                BimProperty.MergedPath(this.gaugeBasePath),
            ) || [];
        const patch: SceneInstancePatch = {
            properties: [
                ...removeGaugeInfoPatch,
                [BimProperty.MergedPath(this.voltageDropPath), null],
                [BimProperty.MergedPath(this.lossesPath), null],
            ],
        };
        return patch;
    }

    private *composeAndSubmitPatch() {
        this.addConductorStatsToPatch();
        yield Yield.NextFrame;
        this.addLossDropStatsToPatch();
        yield Yield.NextFrame;
        for (const chunk of IterUtils.splitIterIntoChunks(
            this.patchesPerId.entries(), 500,
        )) {
            const idPatches:
                [id: IdBimScene, patch: SceneInstancePatch][] = [];
            for (const [id, patches] of chunk) {
                idPatches.push([id, this.createResetPropsPatch(id)]);
                for (const patch of patches)
                    idPatches.push([id, patch]);
            }
            this.bim.instances.applyPatches(idPatches);
            yield Yield.NextFrame;
        }
    }

    private gatherPropsFromNode(node: Node) {
        const connPerTypePerGauge = node.getConnMapByTypeAndGauge();
        const parentWithSi = node.firstParentWithSi;
        const id = parentWithSi.si.id;

        let power = parentWithSi.power;
        // multiharness split hack
        if (node.step.patternStepType === 'end_of_group') {
            power = getAggregatedCapacity(parentWithSi.si.i, false);
        }
        // add up lossDropStats
        const lossDropStats = this.lossDropPerSi.getOrCreate(id);
        lossDropStats.losses += node.lossDrop.losses;
        lossDropStats.drop = lossDropStats.losses / power;
        // add cables
        const connsPerSi = this.connsPerSi.getOrCreate(id);
        for (const [type, perGauge] of connPerTypePerGauge.entries()) {
            for ( const [gauge, conns] of perGauge.entries()) {
                const allPerType = connsPerSi.getOrCreate(type);
                const allPerGauge = allPerType.getOrCreate(gauge);
                allPerGauge.push(...conns);
            }
        }
    }


}
