<script lang="ts">
    import SliderThumb from "./SliderThumb.svelte";
    import { KrMath } from "math-ts";

    export let value: number;
    export let min: number;
    export let max: number;
    export let step: number = 1;
    export let readonly: boolean = false;

    let containerWidth: number = 0;
    let thumb: HTMLDivElement;
    let progressBar: HTMLDivElement;
    let divElement: HTMLDivElement;

    let currentThumb: HTMLDivElement | undefined;

    const mouseEventShield = document.createElement("div");
    mouseEventShield.addEventListener("mouseover", (e) => {
        e.preventDefault();
        e.stopPropagation();
    });

    let prevValue = value;
    function setValue(val: number) {
        if(val === prevValue || readonly){
            return;
        }
        value = val;
        prevValue = val;
    }

    function onTrackEvent(e: MouseEvent) {
        updateValueOnEvent(e);
        onDragStart(e);
    }

    function onDragStart(e: MouseEvent) {
        if (e.type === "mousedown") {
            document.body.append(mouseEventShield);
        }
        currentThumb = thumb;
    }

    function onDragEnd(e: MouseEvent) {
        if (e.type === "mouseup") {
            if (document.body.contains(mouseEventShield)){
                document.body.removeChild(mouseEventShield);
            }
        }
        currentThumb = undefined;
    }

    function calculateNewValue(clientX: number) {
        const elementX = divElement.getBoundingClientRect().left;
        let delta = clientX - (elementX);
        let percent = (delta * 100) / (containerWidth);
        percent = percent < 0 ? 0 : percent > 100 ? 100 : percent;
        const newValue = KrMath.roundTo((percent * (max - min)) / 100 + min, step);
        setValue(KrMath.clamp(newValue, min, max));
    }

    function updateValueOnEvent(e: MouseEvent) {
        if (!currentThumb && e.type !== "mousedown") {
            return;
        }

        if (e.stopPropagation) {
            e.stopPropagation();
        }
        if (e.preventDefault) {
            e.preventDefault();
        }

        calculateNewValue(e.clientX);
    }

    $: if (progressBar && thumb) {
        value = value > min ? value : min;
        value = value < max ? value : max;

        let percent = ((value - min) * 100) / (max - min);
        let offsetLeft = containerWidth * (percent / 100) - 7;

        thumb.style.left = `${offsetLeft}px`;
        progressBar.style.width = `${offsetLeft}px`;
    }

    let dotPositions: number[] = [];
    $:{
        const dotPositionsNew: number[] = [];
        const dotsCount = Math.ceil((max - min) / step);
        for (let index = 0; index <= dotsCount; index++) {
            const val = KrMath.roundTo(min + step * index, step);
            const newValue = KrMath.clamp(val, min, max);
        
            if(newValue === value){
                continue;
            }
            const offsetPx = index === 0 ? 1 : index === dotsCount ? -3 : -1;
            const newPosition = containerWidth * Math.abs((newValue - min) / (max - min)) + offsetPx;
            dotPositionsNew.push(newPosition);
        }

        dotPositions = dotPositionsNew;
    }
</script>

<svelte:window on:mousemove={updateValueOnEvent} on:mouseup={onDragEnd} />


<div class="slider">
    <div
        class="slider-wrapper"
        tabindex="0"
        bind:this={divElement}
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        on:mousedown={onTrackEvent}
    >
        <div class="slider-track" bind:clientWidth={containerWidth}>
            <div class="range-track-highlighted" bind:this={progressBar} />
            <div
                class="slider-thumb"
                bind:this={thumb}
                on:mousedown={onDragStart}
            >
                <SliderThumb />
            </div>
            {#each dotPositions as p}
                <div 
                    class="tick-mark" 
                    style="--position: {p}px;"
                />
            {/each}
        </div>
    </div>
</div>

<style>
    .slider {
        --track-highlight-bgcolor: var(--color-alt);
        --track-highlight-bg: var(--color-alt);
        --track-bgcolor: var(--color-alt);
        position: relative;
        flex: 1;
    }

    .slider-wrapper {
        min-width: 100%;
        position: relative;
        height: 24px;
        padding: 10px 0;
        box-sizing: border-box;
        outline: none;
    }

    .slider-track {
        height: 4px;
        background-color: var(--track-bgcolor, #d0d0d0);
        border-radius: 1px;
    }

    .range-track-highlighted {
        width: 0;
        height: 4px;
        position: absolute;
        border-radius: 1px;
    }

    .slider-thumb {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        background-color: var(--thumb-bgcolor, white);
        cursor: pointer;
        border: white 2px solid;
        border-radius: 2px;
        margin-top: -10px;
        user-select: none;
    }

    .tick-mark {
        height: 2px;
        width: 2px;
        margin-top: 1px;
        border-radius: 50%;
        background: var(--color-main-light);
        position: absolute;
        transform: translateX(var(--position));
    }
</style>
