
export function downloadFile(fileName: string, data: ArrayBuffer | Uint8Array | Blob) {
    const url = URL.createObjectURL(new globalThis.Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.insertAdjacentElement("beforeend", link);
    link.click();
    link.remove();
}

export interface ExportSceneInstancesSettings {
    export_only_selected: boolean,
}
