import { CostModel as CM, type Bim, type Catalog, type ProjectMetrics, createConfigsSerializer, TrackerPilesCollection } from "bim-ts";
import { LazyDerived, type LazyVersioned, type ScopedLogger } from "engine-utils-ts";
import { UiBindings } from "ui-bindings";
import { BimAssetExporter } from "./bim-assets/BimAssetExporter";
import { BimAssetsImporter } from "./bim-assets/BimAssetsImporter";
import { DemExporter } from "./dem/DemExporter";
import { DemImporter } from "./dem/DemImporter";
import { DxfFileExporter } from "./dxf/DxfFileExporter";
import { PngImageImporter } from "./images/PngImageImporter";
import { WebpImageImporter } from "./images/WebpImageImporter";
import { KmzImporter } from "./kmz/KmzImporter";
import { TMY_Importer } from './meteo/TMY_Importer';
import { PvSystFileExporter } from "./pvsyst/PVSystFileExporter";
import { TiffFileExporter } from "./tiff/TiffExporter";
import { TifImporter } from "./tiff/TifImporter";
import { LayoutImporter } from "./civil/LayoutImporter";
import { DxfFileImporter } from "./dxf/DxfFileImporter";
import { LandXmlFileExporter } from "./xml/LandXmlFileExporter";
import { ProjectMetricsExporter } from "./metrics-to-xlsx/ProjectMetricsExporter";
import { PdfExporter } from './pdf/PdfExporter';
import { PilesToCsvExporter } from "./piles-to-csv/PilesToCsv";
export { CommonExportSettings } from './CommonExportSettings';
export { getCivilPluginInstaller } from "./civil/CheckCivilPlugin";
export * as Layout from './civil/LayoutParser';
export * from './civil/LayoutImportContext';
export * from './props-charts/PropsChartsParams';
export * from './props-charts/PropsDatasetEchart';
export * from './props-charts/PropsDatasetGenerator';
export * from './dxf/DxfExportContext';

export interface VerdataSyncerType {
    getCurrentVersionId():number;
    haveUpdatesForSave():boolean;
}

export interface ProjectInfo {
    id: number;
    name: string;
    createBy: string;
    updatedAt: string;
    createdAt: string;
    company: string;
}

export function createFilesConvertersUiBindings(
    logger: ScopedLogger, 
    bim: Bim, 
    catalog: Catalog, 
    metrics: ProjectMetrics,
    projectInfo: LazyVersioned<ProjectInfo | undefined>,
    projectVerdataSyncer: VerdataSyncerType,
    catalogVerdataSyncer: VerdataSyncerType,
    pilesCollection: TrackerPilesCollection
): UiBindings {

    const uiBindings = new UiBindings(logger);

    uiBindings.addFileImporter(['bim-assets'], new BimAssetsImporter(bim));

    uiBindings.addFileImporter(['civil-layout'], new LayoutImporter(bim, catalog));

    uiBindings.addFileImporter(['dxf'], new DxfFileImporter(bim, catalog));


    uiBindings.addFileImporter(['dem'], new DemImporter(bim));
 
    uiBindings.addFileImporter(['typical-meteo-year-importer'], new TMY_Importer(bim));

    uiBindings.addFileImporter(['png-image'], new PngImageImporter(bim));
    uiBindings.addFileImporter(['webp-image'], new WebpImageImporter(bim));

    uiBindings.addFileImporter(['kmz'], new KmzImporter(bim));

    uiBindings.addFileImporter(['tiff'], new TifImporter(bim));

    uiBindings.addFileExporter(["BIMASSET"], new BimAssetExporter(bim));
    uiBindings.addFileExporter(["DEM"], new DemExporter(bim, projectInfo, projectVerdataSyncer));
    uiBindings.addFileExporter(["LandXml"], new LandXmlFileExporter(bim, projectInfo, projectVerdataSyncer));
    uiBindings.addFileExporter(["DXF"], new DxfFileExporter(bim, pilesCollection, projectInfo, projectVerdataSyncer));
    uiBindings.addFileExporter(["SHD"], new PvSystFileExporter(bim, projectInfo, projectVerdataSyncer));
    uiBindings.addFileExporter(["GeoTIFF"], new TiffFileExporter(bim, projectInfo, projectVerdataSyncer));
    uiBindings.addFileExporter(["XLSX"], new ProjectMetricsExporter(bim.unitsMapper, metrics));
    uiBindings.addFileExporter(["PDF"], new PdfExporter(bim, catalog, metrics, pilesCollection, projectInfo, projectVerdataSyncer, catalogVerdataSyncer));
    uiBindings.addFileExporter(["CSV"], new PilesToCsvExporter(bim, pilesCollection, projectInfo, projectVerdataSyncer));

    const projectIdLazy = LazyDerived.new1('projectIdLazy', [], [projectInfo], ([x]) => x?.id);
    const costModelExporterImporter = new CM.CostModelImportExporter(bim, createConfigsSerializer(bim), projectIdLazy)
    uiBindings.addFileExporter(["PVFARMCOST"], costModelExporterImporter);
    uiBindings.addFileImporter(["PVFARMCOST"], costModelExporterImporter);

    return uiBindings;
}

