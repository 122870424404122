
import { LazyDerived, SyncEventIdents } from 'engine-utils-ts';
import type { UiBindings} from 'ui-bindings';
import { UiCustomNode, NotificationDescription, NotificationType, KeyModifiersFlags, PanelViewPosition } from 'ui-bindings';
import { notificationSource } from './Notifications';
import type { VerDataSyncer} from './VerDataSyncer';
import { VerDataSyncStatus } from './VerDataSyncerImpl';

export class VerDataUiBindings {

    static createVerdataHeader(
        syncer: VerDataSyncer,
        type: string
    ) {
        const header = LazyDerived.new2(
            [type, 'Versions'].join(' - '),
            [syncer],
            [syncer.status, syncer.history],
            ([status, history]): string => {
                const activeVersionId = status.activeVersionId;
                const version = `<span><span class="version">${activeVersionId}</span></span>`;
                if (syncer.haveUpdatesForSave()) {
                    return `<div class="text-good">${version}<span class="status">Unsaved</span></div>`;
                }
                if (status.syncStatus === VerDataSyncStatus.Loaded) {
                    const lastVersionId = history.lastVersionId() ?? 0;
                    if (lastVersionId != activeVersionId) {
                        return `<div>${version}<span class="status">New version available</span></div>`;
                    }
                    return `<div>${version}<span class="status">No changes</span></div>`;
                }
                return '';
            }
        );
        return header;
    }

    static setupSyncerRemoteUpdate(
        uiBindings: UiBindings,
        syncer: VerDataSyncer,
        type: string,
    ) {
        let lastKnownVersion = syncer.history.poll().lastVersionId();
        syncer.history.observeObject({
            onPatch: ({ patch, event, currentValueRef }) => {
                if (event.identifier.startsWith(SyncEventIdents.Notification)
                    && lastKnownVersion !== currentValueRef.lastVersionId()
                ) {
                    uiBindings.addNotification(NotificationDescription.newWithActionToStart({
                        type: NotificationType.Warning,
                        source: notificationSource,
                        key: 'versionUpdated',
                        headerArg: type,
                        actionDescription: {
                            name: 'Load',
                            actionArgs: [],
                            action: syncer.loadLastVersion.bind(syncer),
                        },
                        removeAfterMs: 7000,
                        addToNotificationsLog: true
                    }));
                } else {
                }
                lastKnownVersion = currentValueRef.lastVersionId();
            }
        });
    }

    static addCatalogUiBindings(
        uiBindings: UiBindings,
        syncer: VerDataSyncer,
    ) {
        const historyView = new UiCustomNode({ context: syncer });
        VerDataUiBindings.setupSyncerRemoteUpdate(uiBindings, syncer, 'Catalog');
        uiBindings.addViewToNavbar(['Catalog Versions'], historyView, { 
            iconName: 'Catalog',
            group: 'Catalog',
            name:'Catalog versions',
            sortOrder: 2,
            dynamicLabel: VerDataUiBindings.createVerdataHeader(syncer, 'Catalog'),
            position: PanelViewPosition.Overlay
        });
    }

    static addProjectUiBindings(
        uiBindings: UiBindings,
        syncer: VerDataSyncer,
        saveProject: () => void,
    ) {
        const historyView = new UiCustomNode({ context: syncer });
		uiBindings.addViewToNavbar(['Project Versions'], historyView, { 
            iconName: 'Project',
            group: 'General',
            name:'Project versions',
            sortOrder: 1,
            dynamicLabel: VerDataUiBindings.createVerdataHeader(syncer, 'Project'),
            position: PanelViewPosition.Overlay
        });
        VerDataUiBindings.setupSyncerRemoteUpdate(uiBindings, syncer, 'Project');
        uiBindings.addAction({
            name: ['Project', 'Save'],
            canUseNow: LazyDerived.fromMutatingObject<boolean>(() => syncer.canStartSaveNow()),
            action: () => {
                saveProject();
            },
            keyCombinations: [{keyCode: 'KeyS', modifiers: KeyModifiersFlags.Ctrl}],
        });
    }



}
