
export enum MeshRenderType {
	None			= 0,
	Standard 		= 1,
	Animated		= 2,
	Simple 			= 3,
	HeightMap		= 4,
	Ghost			= 8,

}

export enum EdgesRenderMode {
	Auto = 0,
	Enabled = 1,
	Disabled = 2
}

export enum Unit {
	Meter,
	FootInch,

	MeterPow2,
	FootPow2,

	MeterPow3,

}

export enum UnitType {
	Length 	= 1,
	Area = 2,
	Volume = 3,
}

export enum AssemblyRotation {
	Vertical = 0,
	Horizontal = 1
}

export enum SnappingMode {
	None = 0,
	Geometry = 1,
	WorldGrid = 2,
}

export enum TransformPivotMode {
	BBox = 0,
	Origin = 1,
	Manual = 2,
}

export enum TransformOrientationMode {
	Local = 0,
	Global = 1,
	// Screen = 2,
}

export enum EngineControlsMode {
	Default = 0,
	// MarkupBrush = 1,
	Edit = 2,

}

export enum CameraProjectionMode {
	Perspective = 0,
	Orthographic = 1
}

export enum RulerMeasurementType {
	StdPoints,
}

export enum VisibilityMask {
	Default 		= 1,
	StdLocations 	= 2,
	Rulers 			= 4,
	MarkupTiles 	= 16
}
