import type { Bim, BimProperty, IdBimScene, SceneInstance} from "src";
import { NumberProperty, RoadTypeIdent, SceneObjDiff, StringProperty } from "src";
import type { LazyVersioned} from "engine-utils-ts";
import { IterUtils, LazyDerivedAsync, Success, Yield, preferPreviousOverInProgressWrapper, replaceCurrencyUnitWithSymbol } from "engine-utils-ts";
import { Immer } from 'engine-utils-ts';
import { RoadPricingPerSquareMeterIdent, RoadPricingProps } from "src/archetypes/Road";
import { sumValueUnitLike } from "src/UnitsMapper";
import { CostHierarchy, CostSourceType, createMiscCategory, fillModelBasedCostCategory, fillModelBasedTopLevelCategory } from "../..";
import type { CostComponents, CostComponentsNonNullable, CostsConfigProvider, EstimateCost, SingleEstimateProvider} from "src/cost-model/capital";
import { createEmptyCostComponents, createEmptyCostComponentsNonNullable, multiplyCosts } from "src/cost-model/capital";

export function create_Civil_Roads(
    bim: Bim,
    costs: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>,
) {
    const instances = bim.instances.getLazyListOf({ type_identifier: RoadTypeIdent, relevantUpdateFlags: SceneObjDiff.NewProps | SceneObjDiff.LegacyProps })
    const roadsData = getAggregateRoadData(instances);

    const result = LazyDerivedAsync.new3<
        CostHierarchy,
        RoadsAggregatedData,
        EstimateCost[],
        NumberProperty
    >(
        'roads',
        [bim.unitsMapper],
        [roadsData, costs.allEstimateCosts, totalDC],
        function* ([roadsData, estimates, totalDC]) {

            const quantityUnit = bim.unitsMapper.mapToConfigured({ value: 0, unit: 'm2' }).unit!;
            const costUnit = bim.unitsMapper.mapToConfigured({ value: 0, unit: 'usd' }).unit!;
            const quantityUnitInSquareMeters = NumberProperty.new({ value: 1, unit: quantityUnit }).as('m2');

            // final result
            const hierarchy = new CostHierarchy()

            // create compacted road
            {
                const estimateId = RoadPricingPerSquareMeterIdent
                const emptyCosts: EstimateCost = {
                    id: StringProperty.new({ value: estimateId }),
                    costSource: StringProperty.new({ value: CostSourceType.GroupSum }),
                    costs: createEmptyCostComponents(),
                }
                const overrides = multiplyCosts(
                    estimates.find(x => x.id.value === estimateId)?.costs ?? emptyCosts.costs,
                    quantityUnitInSquareMeters
                );
                const defaults = multiplyCosts(getRoadDefaultCostsPerSquareMeter(), quantityUnitInSquareMeters);

                const quantityNum = roadsData.totalArea.as(quantityUnit);
                const category = hierarchy.add({
                    description: { value: 'Compacted road' },
                    costUnit: {
                        options: [replaceCurrencyUnitWithSymbol(costUnit) + '/' + quantityUnit],
                        index: 0,
                    },
                    quantity: {
                        value: NumberProperty.new({ value: quantityNum, unit: quantityUnit }),
                    },
                    relatedSceneInstanceIds: new Set(roadsData.ids),
                    matchesSceneInstance: (params) => params.type_identifier === RoadTypeIdent,
                })

                const updateCosts = (newCosts: CostComponents) => {
                    costs.findAndUpdateEstimateCost(estimateId, (prev) => Immer.produce(
                        prev ?? emptyCosts,
                        draft => { draft.costs = multiplyCosts(newCosts, 1 / quantityUnitInSquareMeters); }
                    ))
                }
                fillModelBasedCostCategory(category[1], overrides, defaults, updateCosts, quantityNum);
            }

            // create roads misc
            {
                const costId = 'roads-misc';
                const overrides = estimates.find(x => x.id.value === costId);
                const state: SingleEstimateProvider = {
                    id: costId,
                    defaults: createEmptyCostComponentsNonNullable(),
                    value: overrides,
                    update: (fn) => costs.findAndUpdateEstimateCost(costId, fn),
                }
                hierarchy.add(createMiscCategory(state, bim.unitsMapper, totalDC))
            }

            // create roads category
            {
                const category = hierarchy.addRoot({ description: { value: 'Roads' } })
                hierarchy.sumupChildren(category[0])
            }

            // create roads root
            {
                const root = hierarchy.addRoot({ description: { value: 'Road Construction' } })
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.009 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    hierarchy,
                    'road-construction-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
            }


            return hierarchy;
        }
    )
    return result;
}


function getAggregateRoadData(instances: LazyVersioned<Array<[id: IdBimScene, si: SceneInstance]>>) {
    const result = preferPreviousOverInProgressWrapper(LazyDerivedAsync.new1(
        'roads-grouping',
        [],
        [instances],
        function* ([instances]) {
            const areas: BimProperty[] = []
            const allIds = new Set<IdBimScene>();
            for (const chunks of IterUtils.splitIterIntoChunks(instances, 25_000)) {
                yield Yield.Asap;
                for (const [id, si] of chunks) {
                    const props = si.properties.extractPropertiesGroup(RoadPricingProps)
                    allIds.add(id);
                    areas.push(props.area);
                }
            }
            const result: RoadsAggregatedData = {
                totalArea: NumberProperty.new({ value: 0, unit: 'ft2' }),
                ids: allIds,
            }
            const totalArea = sumValueUnitLike(areas)
            if (totalArea instanceof Success) {
                result.totalArea = NumberProperty.new(totalArea.value);
            }
            return result;
        }
    ));
    return result;
}

interface RoadsAggregatedData {
    totalArea: NumberProperty
    ids: Set<IdBimScene>
}


export function getRoadDefaultCostsPerSquareMeter(): CostComponentsNonNullable {
    const result: CostComponentsNonNullable = {
        ...createEmptyCostComponentsNonNullable(),
        serviceCost: NumberProperty.new({ value: 1.3 * 0.836127, unit: 'usd' }),
    };

    return result;
}

