// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class TypeIdentifierVersion implements flatbuffers.IUnpackableObject<TypeIdentifierVersionT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):TypeIdentifierVersion {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsTypeIdentifierVersion(bb:flatbuffers.ByteBuffer, obj?:TypeIdentifierVersion):TypeIdentifierVersion {
  return (obj || new TypeIdentifierVersion()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsTypeIdentifierVersion(bb:flatbuffers.ByteBuffer, obj?:TypeIdentifierVersion):TypeIdentifierVersion {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new TypeIdentifierVersion()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

typeIdentifier():string|null
typeIdentifier(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
typeIdentifier(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

version():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

static startTypeIdentifierVersion(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addTypeIdentifier(builder:flatbuffers.Builder, typeIdentifierOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, typeIdentifierOffset, 0);
}

static addVersion(builder:flatbuffers.Builder, version:number) {
  builder.addFieldInt32(1, version, 0);
}

static endTypeIdentifierVersion(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createTypeIdentifierVersion(builder:flatbuffers.Builder, typeIdentifierOffset:flatbuffers.Offset, version:number):flatbuffers.Offset {
  TypeIdentifierVersion.startTypeIdentifierVersion(builder);
  TypeIdentifierVersion.addTypeIdentifier(builder, typeIdentifierOffset);
  TypeIdentifierVersion.addVersion(builder, version);
  return TypeIdentifierVersion.endTypeIdentifierVersion(builder);
}

unpack(): TypeIdentifierVersionT {
  return new TypeIdentifierVersionT(
    this.typeIdentifier(),
    this.version()
  );
}


unpackTo(_o: TypeIdentifierVersionT): void {
  _o.typeIdentifier = this.typeIdentifier();
  _o.version = this.version();
}
}

export class TypeIdentifierVersionT implements flatbuffers.IGeneratedObject {
constructor(
  public typeIdentifier: string|Uint8Array|null = null,
  public version: number = 0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const typeIdentifier = (this.typeIdentifier !== null ? builder.createString(this.typeIdentifier!) : 0);

  return TypeIdentifierVersion.createTypeIdentifierVersion(builder,
    typeIdentifier,
    this.version
  );
}
}
