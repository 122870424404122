import { EntitiesInterned } from "../resources/EntitiesInterned";
import { EngineGeoType } from "./AllEngineGeometries";
import { Aabb } from "math-ts";
import type { GeometryGpuRepr} from "./KrBufferGeometry";
import type { ScopedLogger , Result} from "engine-utils-ts";
import { Success } from "engine-utils-ts";
import { GeometryGenerator } from "./GeometryGenerator";
import type { BimGeometryBase } from "bim-ts";
import { BlockOptions } from "../three-mesh-ui/components/Block";
import type { TextLayoutOptions } from "../three-mesh-ui/components/Text";
import { TextStyleOptions } from "../esos/ESSO_TextAnnotation";
import { EngineBimGeometry } from "./EngineGeometry";

;
    ;
export class TextBlockGeometry implements BimGeometryBase {
    constructor(
        public readonly textOptions: {
            layoutOptions: TextLayoutOptions[], 
            styleOptions: TextStyleOptions 
        } = {
            layoutOptions: [],
            styleOptions: new TextStyleOptions()
        },
        public readonly blockOptions: BlockOptions = new BlockOptions(1, 1),
    ) { }

	calcAabb(): Aabb {
		return new Aabb(
            - this.blockOptions.width / 2, - this.blockOptions.height / 2, 0, 
            this.blockOptions.width / 2, this.blockOptions.height / 2, 0
        );
	}

    checkForErrors(validationErrors: string[]) {
    }

    clone(): TextBlockGeometry {
        const layoutOptions: TextLayoutOptions[] = [];
        for (const option of this.textOptions.layoutOptions) {
            layoutOptions.push(option.clone());
        }

        const styleOptions = this.textOptions.styleOptions.clone();

        return new TextBlockGeometry(
            { layoutOptions, styleOptions },
            this.blockOptions.clone()
        );
    }
}

export class EngineGeoTextBlock extends EngineBimGeometry<TextBlockGeometry> {
    constructor(
        public readonly sourceOpts: TextBlockGeometry = new TextBlockGeometry(),
    ) {
        super(sourceOpts);
    }

    protected _calcGpuRepr(): GeometryGpuRepr {
        return GeometryGenerator.generateTextBlock(
            this.sourceOpts.textOptions.layoutOptions, this.sourceOpts.blockOptions);
    }
}

export class EngineTextBlockGeometries extends EntitiesInterned<EngineGeoTextBlock, EngineGeoType, TextBlockGeometry, number> {
    constructor(
        logger: ScopedLogger,
    ) {
        super({
            logger,
            identifier: 'engine-text-block-geos',
            idsType: EngineGeoType.TextBlock,
            uniqueReducerFn: (TextBlock) => JSON.stringify(TextBlock),
            T_Constructor: EngineGeoTextBlock,
        });
    }
    
    convertFromInternedType(textBlock: TextBlockGeometry): Result<EngineGeoTextBlock> {
        return new Success(new EngineGeoTextBlock(textBlock));
    }

    checkForErrors(t: EngineGeoTextBlock, errors: string[]): void {
        
    }
}