import type { LazyVersioned} from "engine-utils-ts";
import { DefaultMapObjectKey, IterUtils, LazyDerivedAsync, ObjectUtils, Yield, replaceCurrencyUnitWithSymbol, unitsConverter } from "engine-utils-ts";
import type { Bim, IdBimScene } from "src";
import { BimProperty, FixedTiltTypeIdent, NumberProperty, PVModuleTypeIdent, SceneObjDiff, TrackerTypeIdent } from "src";
import { extractValueUnitPropsGroup, type NamedBimPropertiesGroup } from "src/bimDescriptions/NamedBimPropertiesGroup";
import type { CostComponents, CostsConfigProvider, EstimateCost, InstanceCost, SingleEstimateProvider} from "src/cost-model/capital";
import { CostHierarchy, createEmptyCostComponents, createEmptyCostComponentsNonNullable, createMiscCategory, fillModelBasedCostCategory, fillModelBasedTopLevelCategory } from "src/cost-model/capital";
import { ModuleUniqueProps } from "src/archetypes/pv-module/PVModule";
import type { MatchesCostCategory } from 'src/cost-model/capital';
import { extractIntoNamedPropsGroup } from "src/bimDescriptions/PropertiesGroupFormatter";

export function create_PVModules_Install(
    bim: Bim,
    provider: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>,
) {

    const grouped = createGroupedModules(bim);

    const moduleCosts = provider.lazyInstanceCostsByType(PVModuleTypeIdent);

    const result = LazyDerivedAsync.new4<
        CostHierarchy,
        UniqueModuleGroup[],
        EstimateCost[],
        InstanceCost[],
        NumberProperty
    >(
        'modules-install',
        [bim.unitsMapper],
        [
            grouped,
            provider.allEstimateCosts,
            moduleCosts,
            totalDC,
        ],
        function* ([grouped, estimates, moduleCosts, totalDC]) {
            const costUnit = bim.unitsMapper.mapToConfigured({ value: 0, unit: 'usd' }).unit!;

            // extract final result
            const hierarchyEach = new CostHierarchy();
            const hierarchyWatt = new CostHierarchy();

            const manufacturers = IterUtils.groupBy(grouped, x => x.uniqueProps.manufacturer.asText());
            for (const manufacturer of manufacturers) {
                const manufacturerEach = new CostHierarchy();
                const manufacturerWatt = new CostHierarchy();

                const models = IterUtils.groupBy(manufacturer[1], x => x.uniqueProps.model.asText())
                for (const model of models) {
                    const modelEach = new CostHierarchy();
                    const modelWatt = new CostHierarchy();

                    const powers = IterUtils.groupBy(model[1], x => x.uniqueProps.power.as('W'))
                    for (const power of powers) {


                        const totalTrackerIds = new Set<IdBimScene>();
                        power[1].forEach(x => x.arrayIds.forEach(y => totalTrackerIds.add(y)));
                        const uniqueProps = power[1][0].uniqueProps;

                        const matchesSceneInstance: MatchesCostCategory = (query) => {
                            if (
                                !query.si ||
                                ![FixedTiltTypeIdent, TrackerTypeIdent, 'any-tracker'].includes(query.si.type_identifier) ||
                                query.type_identifier !== PVModuleTypeIdent
                            ) {
                                return false;
                            }
                            const props = extractValueUnitPropsGroup(extractIntoNamedPropsGroup(ModuleUniqueProps, query.si.properties, query.si.props));
                            const result = ObjectUtils.areObjectsEqual(props, uniqueProps);
                            return result;
                        }


                        // each
                        {
                            const overrides = moduleCosts.find(x =>
                                x.name === 'each' &&
                                ObjectUtils.areObjectsEqual(x.props, uniqueProps)
                            )?.costs ?? createEmptyCostComponents();
                            const defaults = createDefaultCostModuleEach();
                            const totalModulesCount = IterUtils.sum(power[1], x => x.moduleCount);
                            const category = modelEach.add({
                                description: { value: uniqueProps.power.valueUnitUiString(bim.unitsMapper) },
                                relatedSceneInstanceIds: totalTrackerIds,
                                costUnit: {
                                    options: [replaceCurrencyUnitWithSymbol(costUnit) + '/each' ],
                                    index: 0,
                                },
                                quantity: {
                                    value: NumberProperty.new({ value: totalModulesCount }),
                                    integer: true,
                                },
                                matchesSceneInstance,
                            })[1];


                            const updateCosts = (newCosts: CostComponents) => {
                                provider.findAndUpdateInstanceCost(
                                    (prev) => (prev.costs = newCosts, prev),
                                    { instance_type: PVModuleTypeIdent, props: uniqueProps, name: 'each' }
                                )
                            }

                            fillModelBasedCostCategory(category, overrides, defaults, updateCosts, totalModulesCount);
                        }

                        // watt
                        {
                            const overrides = moduleCosts.find(x =>
                                x.name === 'watt' &&
                                ObjectUtils.areObjectsEqual(x.props, uniqueProps)
                            )?.costs ?? createEmptyCostComponents();
                            const defaults = createDefaultCostModulePerWatt();
                            const totalPowerWatt = IterUtils.sum(power[1], x => x.totalPowerWatt);
                            const category = modelWatt.add({
                                description: { value: uniqueProps.power.valueUnitUiString(bim.unitsMapper) },
                                relatedSceneInstanceIds: totalTrackerIds,
                                costUnit: {
                                    options: [replaceCurrencyUnitWithSymbol(costUnit) + '/W' ],
                                    index: 0,
                                },
                                quantity: { value: NumberProperty.new(unitsConverter.toShortest({ value: totalPowerWatt, unit: 'W' })) },
                                matchesSceneInstance,
                            })[1];


                            const updateCosts = (newCosts: CostComponents) => {
                                provider.findAndUpdateInstanceCost(
                                    (prev) => (prev.costs = newCosts, prev),
                                    { instance_type: PVModuleTypeIdent, props: uniqueProps, name: 'watt' }
                                )
                            }

                            fillModelBasedCostCategory(category, overrides, defaults, updateCosts, totalPowerWatt);
                        }

                    }
                    const categoryEach = modelEach.addRoot({ description: { value: model[0] } });
                    modelEach.sumupChildren(categoryEach[0]);
                    manufacturerEach.merge(modelEach);

                    const categoryWatt = modelWatt.addRoot({ description: { value: model[0] } });
                    modelWatt.sumupChildren(categoryWatt[0]);
                    manufacturerWatt.merge(modelWatt);
                }
                const categoryEach = manufacturerEach.addRoot({ description: { value: manufacturer[0] } })
                manufacturerEach.sumupChildren(categoryEach[0]);
                hierarchyEach.merge(manufacturerEach);

                const categoryWatt = manufacturerWatt.addRoot({ description: { value: manufacturer[0] } })
                manufacturerWatt.sumupChildren(categoryWatt[0]);
                hierarchyWatt.merge(manufacturerWatt);
            }

            // create misc misc each
            {
                const id = ModuleInstallEachMiscEstimateId;
                const state: SingleEstimateProvider = {
                    id: id,
                    defaults: createEmptyCostComponentsNonNullable(),
                    value: estimates.find(x => x.id.value === id),
                    update: (fn) => provider.findAndUpdateEstimateCost(id, fn),
                }
                hierarchyEach.add(createMiscCategory(state, bim.unitsMapper, totalDC))
            }

            // create misc misc watt
            {
                const id = ModuleInstallWattMiscEstimateId;
                const state: SingleEstimateProvider = {
                    id: id,
                    defaults: createEmptyCostComponentsNonNullable(),
                    value: estimates.find(x => x.id.value === id),
                    update: (fn) => provider.findAndUpdateEstimateCost(id, fn),
                }
                hierarchyWatt.add(createMiscCategory(state, bim.unitsMapper, totalDC))
            }

            // create module install each category
            {
                const root = hierarchyEach.addRoot({ description: { value: 'Modules Install (per each)' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.laborCost = NumberProperty.new({ value: 0.008 });
                defaults.materialCost = NumberProperty.new({ value: 0.144 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    hierarchyEach,
                    ModuleInstallEachBenchmarkId,
                    estimates,
                    provider.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC,
                );
            }

            // create module install each category
            {
                const root = hierarchyWatt.addRoot({ description: { value: 'Modules Material (per watt)' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.materialCost = NumberProperty.new({ value: 0.293 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    hierarchyWatt,
                    ModuleInstallWattBenchmarkId,
                    estimates,
                    provider.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC,
                );
            }

            hierarchyEach.merge(hierarchyWatt);

            return hierarchyEach;
        }
    )
    return result
}

type UniqueModuleGroup = {
    uniqueProps: typeof ModuleUniqueProps,
    moduleCount: number,
    totalPowerWatt: number,
    arrayIds: Set<IdBimScene>,
}

export function createGroupedModules(bim: Bim) {
    const arrays = bim.instances.getLazyListOfTypes({
        type_identifiers: [FixedTiltTypeIdent, TrackerTypeIdent],
        relevantUpdateFlags: SceneObjDiff.NewProps | SceneObjDiff.LegacyProps,
    });
    const anyTracker = bim.instances.getLazyListOfTypes({
        type_identifiers: ['any-tracker'],
        relevantUpdateFlags: SceneObjDiff.NewProps | SceneObjDiff.LegacyProps,
    });
    const result = LazyDerivedAsync.new2(
        'groupedModules',
        [],
        [arrays, anyTracker],
        function* ([arrays, anyTracker]) {
            const uniqueModuleGroups = new DefaultMapObjectKey<
                typeof ModuleUniqueProps,
                { moduleCount: number, arrayIds: Set<IdBimScene>, totalPowerWatt: number }
            >({
                unique_hash: (props) => [
                    props.manufacturer.asText(),
                    props.model.asText(),
                    props.power.as('W')
                ].join('/'),
                valuesFactory: () => ({
                    moduleCount: 0,
                    arrayIds: new Set(),
                    totalPowerWatt: 0,
                })
            })

            for (const chunk of IterUtils.splitIterIntoChunks(arrays, 10e3)) {
                yield Yield.Asap;
                const grouped = IterUtils.mapIter(IterUtils.groupBy(chunk, (x) => {
                    const props = x[1].properties.extractPropertiesGroup(ModuleRelatedArrayUniqueProps);
                    return props.modulesCount.asNumber() +  props.moduleMaximumPower.as('W') + props.moduleModel.asText() + props.moduleMaximumPower.as('W');
                }), x => x[1])
                for (const members of grouped) {
                    const [sample] = members;
                    const uniqueProps = sample[1].properties.extractPropertiesGroup(ModuleRelatedArrayUniqueProps);
                    const moduleUniqueProps = sample[1].properties.extractPropertiesGroup(ModuleUniqueProps, { valueUnitOnly: true });
                    const group = uniqueModuleGroups.getOrCreate(Object.freeze(moduleUniqueProps));
                    members.forEach(x => group.arrayIds.add(x[0]));
                    const modulesCount = uniqueProps.modulesCount.asNumber() * members.length
                    group.moduleCount += modulesCount;
                    group.totalPowerWatt += uniqueProps.moduleMaximumPower.as('W') * modulesCount;
                }
            }

            for (const chunk of IterUtils.splitIterIntoChunks(anyTracker, 10e3)) {
                yield Yield.Asap;
                const grouped = IterUtils.mapIter(IterUtils.groupBy(chunk, (x) => {
                    const props = extractIntoNamedPropsGroup(ModuleRelatedArrayUniqueProps, x[1].properties, x[1].props)
                    return props.modulesCount.asNumber() +  props.moduleMaximumPower.as('W') + props.moduleModel.asText() + props.moduleMaximumPower.as('W');
                }), x => x[1])
                for (const members of grouped) {
                    const [sample] = members;
                    const uniqueProps = extractIntoNamedPropsGroup(ModuleRelatedArrayUniqueProps, sample[1].properties, sample[1].props);
                    const moduleUniqueProps = extractValueUnitPropsGroup(
                        extractIntoNamedPropsGroup(ModuleUniqueProps, sample[1].properties, sample[1].props)
                    );
                    const group = uniqueModuleGroups.getOrCreate(Object.freeze(moduleUniqueProps));
                    members.forEach(x => group.arrayIds.add(x[0]));
                    const modulesCount = uniqueProps.modulesCount.asNumber() * members.length
                    group.moduleCount += modulesCount;
                    group.totalPowerWatt += uniqueProps.moduleMaximumPower.as('W') * modulesCount;
                }
            }

            const result: UniqueModuleGroup[] = Array.from(uniqueModuleGroups.entries()).map(([k, v]) => ({
                uniqueProps: k,
                ...v
            }))
            return result;
        }
    )
    return result;
}

const ModuleRelatedArrayUniqueProps = {
    moduleManufacturer: BimProperty.NewShared({
        path: ['module', 'manufacturer'],
        value: 'unknown',
    }),
    moduleModel: BimProperty.NewShared({
        path: ['module', 'model'],
        value: 'unknown',
    }),
    moduleMaximumPower: BimProperty.NewShared({
        path: ['module', 'maximum_power'],
        value: 0, unit: 'W',
    }),
    modulesCount: BimProperty.NewShared({
        path: ['circuit', 'equipment', 'modules_count'],
        value: 0,
    }),
} satisfies NamedBimPropertiesGroup


const ModuleInstallEachMiscEstimateId = 'modules-install-each-misc';
const ModuleInstallEachBenchmarkId = 'modules-install-each-benchmark'

const ModuleInstallWattMiscEstimateId = 'modules-install-watt-misc';
const ModuleInstallWattBenchmarkId = 'modules-install-watt-benchmark'

export function createDefaultCostModuleEach() {
    const costs = createEmptyCostComponentsNonNullable();
    costs.laborTimeUnits = NumberProperty.new({ value: 0.11 })
    costs.laborTimeUnitCost = NumberProperty.new({ value: 50, unit: 'usd' })
    costs.laborCost = NumberProperty.new({ value: 0.11*50, unit: 'usd' })
    return costs
}

export function createDefaultCostModulePerWatt() {
    const costs = createEmptyCostComponentsNonNullable();
    costs.materialCost = NumberProperty.new({ value: 0.39, unit: 'usd' })
    return costs
}
