import { registerCombinerBoxKeyPropsGroupFormatter } from "../../archetypes/CombinerBox";
import { registerFixedTiltKeyPropsFormatter } from "../../archetypes/fixed-tilt/FixedTilt";
import { registerInverterKeyPropertiesGroupFormatter } from "../../archetypes/Inverter/Inverter";
import { registerLvWireSpecKeyPropertiesGroupFormatter } from "../../archetypes/LvWireSpec";
import { registerMVWireSpecKeyPropertiesGroupFormatter } from "../../archetypes/MvWireSpec";
import { registerPvModuleKeyPropertiesGroupFormatter } from "../../archetypes/pv-module/PVModule";
import { registerRoadKeyPropertiesGroupFormatter } from "../../archetypes/Road";
import { registerSectionalizingCabinetKeyPropertiesGroupFormatter } from "../../archetypes/SectionalizingCabinet";
import { registerSubstationKeyPropertiesGroupFormatter } from "../../archetypes/substation/Substation";
import { registerTrackerKeyPropsGroupFormatter } from "../../trackers/Tracker";
import { registerTransformerKeyPropsGroupFormatter } from "../../archetypes/transformer/Transformer";
import { PropertiesGroupFormatters } from "../../bimDescriptions/PropertiesGroupFormatter";
import type { UnitsMapper } from "../../UnitsMapper";
import { registerTrackerFrameKeyPropsGroupFormatter } from "../../archetypes/TrackerFrame";
import { registerMvWireKeyPropertiesGroupFormatter } from '../../archetypes/MvWire';
import { registerLvWireKeyPropertiesGroupFormatter } from "../../archetypes/LvWire";
import { registerAnyTrackerKeyPropsGroupFormatter } from '../../anyTracker/AnyTrackerRuntimes';

export function createKeyPropertiesGroupFormatters(unitsMapper: UnitsMapper) {
    const group = new PropertiesGroupFormatters(unitsMapper);

    registerLvWireSpecKeyPropertiesGroupFormatter(group);
    registerMVWireSpecKeyPropertiesGroupFormatter(group);
    registerPvModuleKeyPropertiesGroupFormatter(group);
    registerTrackerKeyPropsGroupFormatter(group);
    registerCombinerBoxKeyPropsGroupFormatter(group);
    registerTransformerKeyPropsGroupFormatter(group);
    registerFixedTiltKeyPropsFormatter(group);
    registerSectionalizingCabinetKeyPropertiesGroupFormatter(group);
    registerInverterKeyPropertiesGroupFormatter(group);
    registerSubstationKeyPropertiesGroupFormatter(group);
    registerRoadKeyPropertiesGroupFormatter(group);
    registerTrackerFrameKeyPropsGroupFormatter(group);
    registerMvWireKeyPropertiesGroupFormatter(group);
    registerLvWireKeyPropertiesGroupFormatter(group);
    registerAnyTrackerKeyPropsGroupFormatter(group);

    return group;
}

