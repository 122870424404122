import type {
	CachingNetworkClient, LongTask,
	ObservableStream,
	ProjectNetworkClient, Result, ScopedLogger, SyncHub,
	UndoStack} from 'engine-utils-ts';
import { ErrorUtils, Failure, FetchUtils, Globals, IterUtils,
	NamedEvents, ObservableObject, peekCurrentEventFrame, PollablePromise, requestExecutionFrame, TasksRunner, Yield,
} from 'engine-utils-ts';
import { NotificationDescription, NotificationType, UiBindings } from 'ui-bindings';

import type { DiffToVersionReal} from './CollectionSyncer';
import {
	CollectionSyncer
} from './CollectionSyncer';
import { notificationSource } from './Notifications';
import type {
	PersistedCollectionConfig, VerDataPersistedCollection, VerdataCollectionPatch,
} from './VerDataPersistedCollection';
import { VerdataUrls } from './VerdataUrls';
import type { CollectionHistory } from './WireCollectionHistory';
import type { ProjectVersion, ProjectVersionAdditionalContext, ProjectVersionMetrics
} from './WireProjectHistory';
import {
	ProjectHistory, ProjectHistorySerializer
} from './WireProjectHistory';
import {
	TaskSaveByUser, VerDataState, VerDataStateSerializer, VerDataTask,
	WireTaskDescription,
} from './WireVerDataState';

export type Id = number;

export enum VerDataSyncStatus {
	None,
	Initializing,
	Initialized,
	Loading,
	LoadingError,
	Loaded,
	Saving,
}

export interface VerDataStatus {
	syncStatus: VerDataSyncStatus;
	activeVersionId: number;
	currentOperationProgress?: number;
}


export interface NewVersionDescription {
	textDescription: string;
	image: Promise<Uint8Array>;
	createdBy: string;
	metrics: ProjectVersionMetrics;
}

export interface NewVersionAdditionalContext {
	versionPerIdentifier: Map<string, number>;
}

export interface ObjectVersionsRange {
	id: number;
	projectVersionRange: [number, number];
}

export interface ObjectVersion {
	id: number;
	projectVersion: number;
}

export class VerDataSyncerImpl {

	identifier: string;

	undoStack: UndoStack|null;
	namedEvents: NamedEvents;
	uiBindings: UiBindings;

	cachingNetClient: CachingNetworkClient | null = null;
	syncHub: SyncHub | null = null;

	_disposeEvent: ObservableStream<unknown>;
	_beforeSyncEvent: ObservableStream<void>;
	_afterSyncEvent: ObservableStream<void>;
	_afterSaveNewVersionEvent: ObservableStream<void>;

	status: ObservableObject<VerDataStatus>;
	state: ObservableObject<VerDataState>;
	history: ObservableObject<ProjectHistory>;

	_isDisposed: boolean = false;
	_reqAnimCallback: (t: number) => void;
	_collectionsSyncers: Map<string, CollectionSyncer<any>> = new Map();
	_tasksRunner: TasksRunner;

	_historyLoadPromise: Promise<void> | null = null;

	_logger: ScopedLogger;

	constructor(
		undoStack: UndoStack|null,
		logger: ScopedLogger,
		identifier: string,
	) {
		this.identifier = identifier;

		this.undoStack = undoStack;
		this._logger = logger;
		this.namedEvents = new NamedEvents();
		this.uiBindings = new UiBindings(this._logger);

		this._tasksRunner = new TasksRunner(this._logger);
		this._disposeEvent = this.namedEvents.registerEvent('dispose');
		this._beforeSyncEvent = this.namedEvents.registerEvent('beforeSync');
		this._afterSyncEvent = this.namedEvents.registerEvent('afterSync');
		this._afterSaveNewVersionEvent = this.namedEvents.registerEvent('afterSaveNewVersion');
		this.history = new ObservableObject({
			logger: logger.newScope('proj_history_sync'),
			identifier: VerdataUrls.projectHistorySyncIdentifier(),
			initialState: new ProjectHistory([], []),
			serializer: new ProjectHistorySerializer()
		});
		this.status = new ObservableObject<VerDataStatus>({
			identifier: "project_state",
			initialState: { syncStatus: VerDataSyncStatus.None, activeVersionId: 0 },
		});
		this.state = new ObservableObject({
			logger: logger.newScope('proj_state_sync'),
			identifier: VerdataUrls.verDataStateIdentifier(),
			initialState: new VerDataState(null, []),
			serializer: new VerDataStateSerializer()
		});

		this._reqAnimCallback = (t: number) => this._mainLoop(t);
		this._mainLoop(0);
	}

	clear() {
		this.status.applyPatch({
			patch: {
				syncStatus: VerDataSyncStatus.None,
				currentOperationProgress: undefined,
				activeVersionId: 0
			}
		});
		this.cachingNetClient = null;
		for (const c of IterUtils.reverseIter(this._collectionsSyncers.values())) {
			const ids = c.collection.getAllIds();
			c.collection.applyPatch({ toDelete: ids, collectionContext: undefined });
			c.objectsVersions.dispose();
		}
	}

	dispose(): void {
		this._isDisposed = true;
		for (const c of this._collectionsSyncers.values()) {
			c.dispose();
		}
		this._disposeEvent.notify_later_legacy();
	}

	private _mainLoop(_timeStamp: number) {
		if (this._isDisposed) {
			return;
		}
		requestExecutionFrame(this._reqAnimCallback);
		const startT = performance.now();
		this._tasksRunner.run(100);
		Globals.getNotifier().notifyQueued();

		for (const c of this._collectionsSyncers.values()) {
			if (((performance.now() - startT) < 98) || (Math.random() < 0.9)) {
				break;
			}
			c.objectsVersions.handleUpdates(undefined);
		}
    }

	async init(networkClient: ProjectNetworkClient) {
		this._assertVerDataStatus([VerDataSyncStatus.None]);
		this.status.applyPatch({ patch: { syncStatus: VerDataSyncStatus.Initializing } });

		this.cachingNetClient = Globals.getCachingClient(networkClient);
		this.syncHub = Globals.getSyncHub(networkClient);
		const initPromises = [];
		initPromises.push(this.state.attachToSyncHub(this.syncHub));
		initPromises.push(this.history.attachToSyncHub(this.syncHub));
		for (const cs of this._collectionsSyncers.values()) {
			initPromises.push(cs.initialize(this.syncHub));
		}
		await Promise.all(initPromises);
		this.status.applyPatch({ patch: { syncStatus: VerDataSyncStatus.Initialized } });

		if (this.history.poll().versions.length == 0) {
			this.status.applyPatch({ patch: { syncStatus: VerDataSyncStatus.Loaded } });
		}
	}

	attachCollectionForSync<T extends Object>(
		config: Partial<PersistedCollectionConfig>,
		collection: VerDataPersistedCollection<T, any>
	) {
		if (!config.identifier) {
			throw new Error("provide identifier for collection sync config");
		}
		if (this._collectionsSyncers.has(config.identifier)) {
			throw new Error(`collection with identifier:${config.identifier}  is already registered`);
		}
		const collectionSyncer = new CollectionSyncer(this._logger, config, collection);
		this._collectionsSyncers.set(config.identifier, collectionSyncer);
		this._collectionsSyncers = getCollectionsOrderedByLoadOrder(this._collectionsSyncers);
		if (this.syncHub) {
			return collectionSyncer.initialize(this.syncHub);
		}
		return Promise.resolve();
	}

	detachCollection(identifier: string) {
		if (!this._collectionsSyncers.has(identifier)) {
			this._logger.warn(`tried to detach collecetion ${identifier} which is not registered`);
			return;
		}
		this._logger.debug(`detaching ${identifier}`);
		const cs = this._collectionsSyncers.get(identifier)!;
		this._collectionsSyncers.delete(identifier);
		cs.dispose();
		// TODO: check order and dependencies?
	}

	_assertVerDataStatus(allowed: VerDataSyncStatus[]) {
		const currStatus = this.status.poll().syncStatus;
		if (!allowed.includes(currStatus)) {
			ErrorUtils.logThrow(
				'invalid projectSyncerStatus ', VerDataSyncStatus[currStatus],
				'should be one of', allowed.map(s => VerDataSyncStatus[s])
			);
		}
	}

	_patchStatus(status: VerDataSyncStatus, progress?: number) {
		this.status.applyPatch({
			patch: { syncStatus: status, currentOperationProgress: progress }
		});
	}

	async loadVersion(versionId: number): Promise<void> {
		Globals.getNotifier().notifyQueued();
		this.undoStack?.clear();
		const logger = this._logger.newScope(`loading_` + versionId);
		try {
			const allowedStatuses = [
				VerDataSyncStatus.Initialized,
				VerDataSyncStatus.Loaded,
				VerDataSyncStatus.LoadingError
			];
			this._assertVerDataStatus(allowedStatuses);
			const version = this.history.poll().versions.find(v => v.id == versionId);
			if (!version) {
				throw new Error("no project version with this id found");
			}
			Globals.getNotifier().notifyQueued();

			this.status.applyPatch({patch: {
				activeVersionId: versionId,
				syncStatus: VerDataSyncStatus.Loading,
			}})
	
			const task = this._tasksRunner.newLongTask({
				defaultGenerator: this._loadVersionDiffs(
					logger,
					version,
				)
			});
			const pr = task.asPromise();
			pr.finally(() => toggleLocks(this._collectionsSyncers, false));
			pr.then(() => {
				this.status.applyPatch({
					patch: {
						syncStatus: VerDataSyncStatus.Loaded,
						currentOperationProgress: undefined,
						activeVersionId: versionId
					}
				});
			}, (e) => {
				logger.error(e);
				this.status.applyPatch({
					patch: {
						syncStatus: VerDataSyncStatus.LoadingError,
						currentOperationProgress: undefined,
					}
				});
			});
			
			this.uiBindings.addNotification(NotificationDescription.newWithTask({
				type: NotificationType.Info,
				source: notificationSource,
				key: 'action',
				headerArg: this.identifier + ' loading',
				taskDescription: {
					task,
				},
				removeAfterMs: 1000,
				addToNotificationsLog: true
			}));
		} catch (e) {
			this._logger.error(e);
			this.uiBindings.addNotification(NotificationDescription.newBasic({
				type: NotificationType.Error,
				source: notificationSource,
				key: 'actionFailed',
				headerArg: this.identifier + ' loading',
				removeAfterMs: 3000,
				addToNotificationsLog: true
			}));
		}
	}

	*_loadVersionDiffs(
		logger: ScopedLogger,
		projectVersion: ProjectVersion,
	): Generator<Yield, ProjectVersion> {
		this._beforeSyncEvent.notify_later_legacy();
		Globals.getNotifier().notifyQueued();
		yield Yield.NextFrame; // allow 1 frame for notification to be handled

		const collections = this._collectionsSyncers;

		callBeforeSyncCallbacks(collections);
		toggleLocks(collections, true);

		const collectionsGroupedByLoadOrder = getCollectionsGroupedByLoadDependencies(collections);

		if (collectionsGroupedByLoadOrder.reduce((s, g) => (s + g.length), 0) != collections.size) {
			ErrorUtils.logThrow(
				`collections group scheduling failed, ordererd collections count != initial count`,
				collections,
				collectionsGroupedByLoadOrder
			);
		}
		logger.debug('load groups', collectionsGroupedByLoadOrder);

		const toDeleteLaterPerCollection: [CollectionSyncer<any>, number[]][] = [];

		for (const loadGroup of collectionsGroupedByLoadOrder) {
			const diffsToApply: [CollectionSyncer<any>, DiffToVersionReal<any, any>][] = [];
			for (const c of loadGroup) {
				const cv = c._history.poll().findVersionForProjectId(projectVersion.id);
				let collVerId: number;
				if (cv) {
					collVerId = cv.id;
				} else {
					logger.warn(`no ${c.config.identifier} version saved, set to 0`);
					collVerId = 0;
				}
				const diff = c.calculatePotentialDiffToVersion(logger, collVerId);
				const realDiff = yield* c.calcRealDiff(logger, this.cachingNetClient!, diff);
				
				diffsToApply.push([c, realDiff]);
			}
			yield Yield.NextFrame;
			for (const [c, diff] of diffsToApply) {
				c.collection.toggleLock(false);
				c._assertIsInit();

				const versionPerId = new Map<number, number>();
				{
					for (const [id, version] of diff.update.concat(diff.allocate)) {
						versionPerId.set(id, version);
					}
					for (const [id, version] of diff.update) {
						versionPerId.set(id, version);
					}
				}

				const collectionPatch: VerdataCollectionPatch<any, any> = {
					toAlloc: diff.allocate.map(t => [t[0], t[2]]),
					toPatch: diff.update.map(t => [t[0], t[2]]),
					toDelete: diff.delete,
					collectionContext: diff.targetVersionContext,
				};
				const {toDeleteLater} = c.collection.applyPatch(collectionPatch);
				toDeleteLaterPerCollection.push([c, toDeleteLater]);
				c.objectsVersions.handleUpdates(versionPerId);

				c._lastLoadedVersion = diff.targetVersionId;
			}
		}
		for (const [c, toDeleteLater] of toDeleteLaterPerCollection) {
			c.collection.deleteObjects(toDeleteLater);
		}

		yield Yield.NextFrame;

		// const collectionsGroupedByLoadOrder = getCollectionsGroupedByLoadDependencies(collections);

		// if (collectionsGroupedByLoadOrder.reduce((s, g) => (s + g.length), 0) != collections.size) {
		// 	ErrorUtils.logThrow(
		// 		`collections group scheduling failed, ordererd collections count != initial count`,
		// 		collections,
		// 		collectionsGroupedByLoadOrder
		// 	);
		// }
		// logger.debug('load groups', collectionsGroupedByLoadOrder);

		// let progress = 0;
		// const progressStep = 1 / collections.size;
		// for (const loadGroup of collectionsGroupedByLoadOrder) {
		// 	const realDiffsPromises = loadGroup.map(async (c) => {
		// 		const fetch = pendingLoads.get(c)!;
		// 		const batches = await fetch;
		// 		// if (fetch.status() == PromiseStatus.Rejected) {
		// 		// 	logger.error('could not load collection history, fetch error', fetch.getError());
		// 		// } else {
		// 			const potentialDiff = diffs.get(c)!;
		// 			diffs.delete(c); // allow gc

		// 		const realDiff = yield* c.calcRealDiff(logger, batches, potentialDiff);
		// 		return [c, realDiff] as [CollectionSyncer<any>, DiffToVersionReal<any, any>];
		// 	});
		// 	const groupDiffsResult = yield *PollablePromise.generatorWaitFor(Promise.all(realDiffsPromises));
		// 	const realDiffsToApplySimultaneously: [CollectionSyncer<any>, DiffToVersionReal<any, any>][] 
		// 	= groupDiffsResult.value;
		// 	yield Yield.Asap;
		// 	for (const [c, diff] of realDiffsToApplySimultaneously) {
		// 		c.collection.toggleLock(false);
		// 		progress += progressStep;
		// 		diffCallback(c, diff, progress);
		// 	}
		// }


		callAfterSyncCallbacks(collections);
		yield Yield.NextFrame;
		this._afterSyncEvent.notify_later_legacy();
		yield Yield.NextFrame;

		return projectVersion;
	}

	async saveNewVersion(descriptionOpt: Partial<NewVersionDescription>, context: NewVersionAdditionalContext | null): Promise<void> {
		this._assertVerDataStatus([VerDataSyncStatus.Loaded]);
		const logger = this._logger.newScope('save_' + (this.history.poll().lastVersionId() ?? 0 + 1));

		const lastLoadedVersion = this.status.poll().activeVersionId;
		const newVersionToSaveId = (this.history.poll().lastVersionId() ?? 0) + 1;

		this.status.applyPatch({ patch: { syncStatus: VerDataSyncStatus.Saving, activeVersionId: newVersionToSaveId} });
		const TaskDefaultTimeoutMs = 120_000;

		this._beforeSyncEvent.notify_later_legacy();

		try {

			const event = peekCurrentEventFrame();
			const taskGuid = FetchUtils.generateGuid();
			const refreshStateTaskWithFreshTimeout = () => this.state.applyPatch({
				patch: {
					activeTask: new VerDataTask(
						new TaskSaveByUser("anon"),
						new WireTaskDescription(
							taskGuid,
							new Date(new Date().getTime() + TaskDefaultTimeoutMs),
							0
						)
					)
				},
				event,
			});

			await refreshStateTaskWithFreshTimeout();
			// refresh active task with new timeout until task is done
			const taskRefresherIntervalId = setInterval(() => {
				if (savingTask != null && !savingTask.isFinalized()) {
					refreshStateTaskWithFreshTimeout();
				} else {
					clearInterval(taskRefresherIntervalId);
				}
			}, TaskDefaultTimeoutMs / 4);

			let savingTask: LongTask<ProjectHistory> | null = null;
			try {
				toggleLocks(this._collectionsSyncers, true);
				savingTask = this._tasksRunner.newLongTask<ProjectHistory>({
					defaultGenerator: this._saveNewVersion(logger, descriptionOpt, context),
					resultTypeChecker: res => res instanceof ProjectHistory,
					taskTimeoutMs: TaskDefaultTimeoutMs * 5,
				});

				this.uiBindings.addNotification(NotificationDescription.newWithTask({
					source: notificationSource,
					key: 'action',
					type: NotificationType.Info,
                    headerArg: this.identifier + ' Saving',
					taskDescription: { task: savingTask },
					removeAfterMs: 1000,
					addToNotificationsLog: true
				}));

				const newHistory = await savingTask.asPromise();
				this.history.applyPatch({ patch: { ...newHistory } });
				
				this._afterSaveNewVersionEvent.notify_later_legacy();
			} catch(e) {
				this._logger.error(e);

			} finally {
				toggleLocks(this._collectionsSyncers, false);
				this.status.applyPatch({
					patch: {
						syncStatus: VerDataSyncStatus.Loaded,
						currentOperationProgress: undefined,
						activeVersionId: this.history.poll().lastVersionId() ?? 0,
					}
				});
				this.state.applyPatch({
					patch: {
						activeTask: null,
					}
				});

			}
		} catch (e) {
			logger.error(e);
			this._patchStatus(VerDataSyncStatus.Loaded);

		} finally {

		}
	}

	*_saveNewVersion(logger: ScopedLogger, description: Partial<NewVersionDescription>, context: NewVersionAdditionalContext | null): Generator<Yield, ProjectHistory> {
		yield Yield.Asap;
		// const toSave: Map<string, CollectionVersionToSave<any>> = new Map();

		let extendedContext: ProjectVersionAdditionalContext|null = null;
		if (description.metrics || context?.versionPerIdentifier) {
			extendedContext = {
				versionPerIdentifier: context?.versionPerIdentifier ?? new Map(),
				metrics: description.metrics ?? {},
			}
		}

		const newProjectHistory = this.history.poll().duplicateWithNewVersion(
			new Date(),
			description.textDescription ?? "",
			extendedContext,
			description.createdBy ?? ""
		);

		const newProjectVersionId = newProjectHistory.lastVersionId()!;

		if (description.image) {
			const imageUrl = VerdataUrls.projectImageUrl(newProjectVersionId);
			const scr_pr = description.image.then(scr => this.cachingNetClient!.client.put(imageUrl, scr))
		}

		const perCollectionUploads: Promise<CollectionHistory>[] = [];
		for (const [ident, cs] of this._collectionsSyncers) {
			logger.debug('start preparing ', cs.config.identifier);
			const cvts = cs.prepareNewVersion(logger, newProjectVersionId);
			logger.debug('prepared cvts ', cvts);
		    yield Yield.Asap;
			if (cvts.batchesToUploadByFileName.length == 0
				&& cs._history.poll().versions.includes(cvts.collectionVersion)
				// && cvts.collectionVersion.id > 1
			) {
				logger.info('nothing to upload');
				perCollectionUploads.push(Promise.resolve(cs._history.poll()));
			} else {
				// wait for previous collections uploads to finish, before starting new one
				if (perCollectionUploads.length > 0) {
					yield* PollablePromise.generatorWaitFor(Promise.all(perCollectionUploads));
				}
				
				const versionUpload = cs.saveNewVersion(logger, cvts, this.cachingNetClient!);
				perCollectionUploads.push(versionUpload);
			}

		    yield Yield.Asap;
		}

		// if (collectionsNoUpdateCount == this._collectionsSyncers.size
		// 	&& this.status.poll().loadedVersionId ==
		// ) {
		// 	logger.info('no collection have changed, nothing to save');
		// 	return this
		// }

		const newCollectionsVersions: Result<CollectionHistory[]> = yield*
			PollablePromise.generatorWaitFor(Promise.all(perCollectionUploads));

		if (newCollectionsVersions instanceof Failure) {
			ErrorUtils.logThrow(newCollectionsVersions.errorMsg());
		}
		logger.debug(newCollectionsVersions);

		const projectVersionSaved: Result<void> = yield* PollablePromise.generatorWaitFor(
			this.history.applyPatch({ patch: newProjectHistory })
		);
		if (projectVersionSaved instanceof Failure) {
			ErrorUtils.logThrow(projectVersionSaved.errorMsg());
		}
		logger.debug('proj history saved', projectVersionSaved);
		return newProjectHistory;
	}

	findObjects(perCollectionIds: Iterable<[string, number[]]>): Map<string, ObjectVersionsRange[]>{
		Globals.getNotifier().notifyQueued();
		const logger = this._logger.newScope('Find objects');

		const collections = this._collectionsSyncers;

		const perCollectionIdsMap = new Map<string, number[]>();
        for (const [ident, ids] of perCollectionIds) {
            const collectionIds = perCollectionIdsMap.get(ident);
            if (!collectionIds) {
                perCollectionIdsMap.set(ident, ids.slice());
            } else {
                IterUtils.extendArray(collectionIds, ids);
            }
        }

		const versions = this.history.poll().versions;
		const lastProjVersion = versions[versions.length - 1].id;
		const notFoundCollections:string[] = [];
		const perCollectionVersions = new Map<string, ObjectVersionsRange[]>();
		for (const [collectionIdnt, objects] of perCollectionIdsMap) {
			const c = collections.get(collectionIdnt);
			if (!c) {
				notFoundCollections.push(collectionIdnt);
				continue;
			}
			const objectsInVersions = c.findObjects(logger, objects, lastProjVersion);
			perCollectionVersions.set(collectionIdnt, objectsInVersions);
		}

		if (notFoundCollections.length > 0) {
            logger.error(
                "Collection not found with ident: ",
                notFoundCollections
            );
        }

		return perCollectionVersions;
	}

	// for debugging purposes
	_extractObjectsVersionsOf(collectionIdent: string, projectVersion: number): Map<number, number> {
		const c = this._collectionsSyncers.get(collectionIdent);
		if (!c) {
			ErrorUtils.logThrow("Collection not found with ident: ", collectionIdent);
		}
		return c.extractObjsVersionsPerIdForVersion(this._logger.newScope(`${collectionIdent} extraction`), projectVersion);
	
	}

	getObjectsIdsInVersion(collectionsIdnt: string[], projectVersion: number){
		Globals.getNotifier().notifyQueued();
		const logger = this._logger.newScope('Fetch objects ids from version - ');

		const collections = this._collectionsSyncers;

		const notFoundCollections:string[] = [];
		const perCollectionIds = new Map<string, number[]>();
		for (const ident of collectionsIdnt) {
			const c = collections.get(ident);
			if (!c) {
				notFoundCollections.push(ident);
				continue;
			}
			const objectsInVersion = c.extractObjsVersionsPerIdForVersion(logger, projectVersion);

			perCollectionIds.set(ident, Array.from(objectsInVersion.keys()));
		}

		if (notFoundCollections.length > 0) {
            logger.error(
                "Collection not found with ident: ",
                notFoundCollections
            );
        }

		return perCollectionIds;
	}

	async LoadObjectsVersions<T extends Object>(
		collectionIdnt: string, 
		versions: number[], 
		): Promise<[projectVersion: number, perId: [number, T][]][]>{
		Globals.getNotifier().notifyQueued();
		const logger = this._logger.newScope('Load');

		const task = this._tasksRunner.newLongTask({
			defaultGenerator: this._loadObjectsVersions<T>(
				collectionIdnt,
				versions,
				logger,
			)
		});
		const pr = task.asPromise();
		return pr;
	}

	*_loadObjectsVersions<T extends Object>(
		collectionIdnt: string, 
		versions: number[], 
		logger:ScopedLogger, 
		) {
		const collections: [projectVersion: number, perId: [number, T][]][] = [];
		for (const version of versions) {
			const perCollection = this.getObjectsIdsInVersion([collectionIdnt], version);

			const objectsInVersion = perCollection.get(collectionIdnt);
			if(!objectsInVersion){
				ErrorUtils.logThrow('Objects in not found in collection ', collectionIdnt);
			}
			const collection = yield* this._loadObjects<T>(collectionIdnt, objectsInVersion.map(id => ({id, projectVersion:version})), logger);
			collections.push([version, collection]);
		}
		return collections;
	}

	async LoadObjects<T extends Object>(collectionIdnt: string, objects: ObjectVersion[]){
		const logger = this._logger.newScope('Load');

		const task = this._tasksRunner.newLongTask({
			defaultGenerator: this._loadObjects<T>(
				collectionIdnt,
				objects,
				logger,
			)
		});
		const pr = await task.asPromise();
		return pr;
	}

	*_loadObjects<T extends Object>(
		collectionIdnt: string, 
		objects: ObjectVersion[],
		logger: ScopedLogger,
		): Generator<Yield, [number, T][]>{
		Globals.getNotifier().notifyQueued();
		yield Yield.Asap;

		const coll = this._collectionsSyncers.get(collectionIdnt);
		if(!coll){
			ErrorUtils.logThrow("Collection not found with ident: ", collectionIdnt);
		}

		const toLoad = coll.findBatchesWithObjects(logger, objects);

		const batchesToLoad = new Set<string>();
		for (const ver of toLoad.versions) {
			IterUtils.extendSet(batchesToLoad, ver.batchesToLoad.keys());
		}


		const pendingLoads = IterUtils.mapIter(batchesToLoad, guid => coll.fetchBatch(this.cachingNetClient!, guid));

		const batches = yield* PollablePromise.generatorWaitFor(Promise.all(pendingLoads));
		if(batches instanceof Failure){
			ErrorUtils.logThrow(batches.errorMsg());
		}
		yield Yield.Asap;


		const perBatchObjectsIds = new Map<string, number[]>();
		for (const ver of toLoad.versions) {
			for (const [batch, toLoad] of ver.batchesToLoad) {
				if(perBatchObjectsIds.has(batch)){
					const toLoadIds = perBatchObjectsIds.get(batch)!;
					IterUtils.extendArray(toLoadIds, toLoad);
				} else {				
					perBatchObjectsIds.set(batch, toLoad.slice());
				}
			}
		}
		const loadedItems = yield* coll.deserializeItems(perBatchObjectsIds, batches.value, logger);

		const loadedObjects:[number, T][] = [];
		for (const [id, _ver, obj] of loadedItems) {
			loadedObjects.push([id, obj]);
		}

		yield Yield.Asap;

		return loadedObjects;
	}
}

function callBeforeSyncCallbacks(collections: Map<string, CollectionSyncer<any>>) {
	// update and lock all the collections
	for (const c of collections.values()) {
		if (c.collection.onBeforeSync) {
			c.collection.onBeforeSync();
		}
	}
	for (const c of IterUtils.reverseIter(collections.values())) {
		if (c.collection.onBeforeSyncReverse) {
			c.collection.onBeforeSyncReverse();
		}
	}
}

function callAfterSyncCallbacks(collections: Map<string, CollectionSyncer<any>>) {
	for (const c of collections.values()) {
		if (c.collection.onAfterSync) {
			c.collection.onAfterSync();
		}
	}
	for (const c of IterUtils.reverseIter(collections.values())) {
		if (c.collection.onAfterSync) {
			c.collection.onAfterSync();
		}
	}
}

function toggleLocks(collections: Map<string, CollectionSyncer<any>>, enabled: boolean) {
	for (const c of collections.values()) {
		c.collection.toggleLock(enabled);
	}
}


function getCollectionsOrderedByLoadOrder(collections: Map<string, CollectionSyncer<any>>)
	: Map<string, CollectionSyncer<any>>
{
	const resOrdered: Map<string, CollectionSyncer<any>> = new Map();
	while (true) {
		let added = false;
		for (const [ident, cs] of collections) {
			if (resOrdered.has(ident)) {
				continue;
			}
			if (cs.config.loadAfter.length == 0
				|| cs.config.loadAfter.every(ident => resOrdered.has(ident))
			) {
				added = true;
				resOrdered.set(ident, cs)
			}
		}
		if (resOrdered.size == collections.size) {
			break;
		}
		if (!added) {
			console.error('collections dependencies resolving error', collections);
			throw new Error("could not resolve collections load after dependencies");
		}
	}
	console.assert(resOrdered.size == collections.size, 'collections ordering sanity check');
	return resOrdered;
}


function getCollectionsGroupedByLoadDependencies(collections: Map<string, CollectionSyncer<any>>)
	: CollectionSyncer<any>[][]
{
	// algo below is not optimal, or correct in all cases -_-
	// but it works well in practice yet, so let it be

	const ordered: Map<string, number> = new Map();
	const collectionsLeftToAdd = new Set<string>(collections.keys());
	while (ordered.size != collections.size) {
		let added = false;
		for (const ident of collectionsLeftToAdd) {
			const cs = collections.get(ident)!;
			if (cs.config.loadAfter.length == 0
				|| cs.config.loadAfter.every(ident => ordered.has(ident))
			) {
				added = true;
				ordered.set(ident, ordered.size);
				collectionsLeftToAdd.delete(ident);
			}
		}
		if (!added) {
			console.error('collections dependencies resolving error', collections);
			throw new Error("could not resolve collections load after dependencies");
		}
	}
	// now check which dependencies should be loaded simultaneosly
	// and  merge load order indices that should happen simultanesously
	// O(n^2) simply find minimum load order required for each collection to load
	for (const _ of ordered) {
		for (let [ident, loadIndex] of ordered) {
			const cs2 = collections.get(ident)!;
			// find minimum order of one-tick group
			for (const loadWith of cs2.config.loadInOneTickWith) {
				const depOrder = ordered.get(loadWith);
				if (depOrder == undefined) {
					throw new Error(`could not schudle loads, ${ident} configured to be loaded with absent ${loadWith}`);
				}
				if (depOrder < loadIndex) {
					loadIndex = depOrder;
				}
			}
			// make sure dependencies are loaded before one-tick group
			for (const loadDependencyIdent of cs2.config.loadAfter) {
				const dependencyLoadOrder = ordered.get(loadDependencyIdent)!;
				if (dependencyLoadOrder > loadIndex) {
					ordered.set(loadDependencyIdent, loadIndex);
				}
			}
			ordered.set(ident, loadIndex); // set with existing key doesnt change iteration order
		}
	}

	// now that minimum load order numbers are found
	// make sure that loadAfter dependencies can also be justified
	for (const [ident, loadIndex] of ordered) {
		const cs = collections.get(ident)!;
		for (const loadAfter of cs.config.loadAfter) {
			const loadAfterIndex = ordered.get(loadAfter)!;
			if (loadIndex < loadAfterIndex) {
				throw new Error(`could not justify loading dependencies ${ident} configured to be loaded after ${loadAfter}`);
			}
		}
	}

	// we can group them
	// also make sure that loadAfter dependencies can also be justified
	const result: CollectionSyncer<any>[][] = [];
	for (let [_, loadIndexToBatch] of ordered) {
		const batchWithTheSameOrder: CollectionSyncer<any>[] =
			IterUtils.filterMap(ordered, ([ident, loadIndex]) => {
				if (loadIndex == loadIndexToBatch) {
					return collections.get(ident);
				} else {
					return undefined;
				}
			}
		);
		for (const cs of batchWithTheSameOrder) {
			ordered.delete(cs.config.identifier);
		}
		result.push(batchWithTheSameOrder);
	}

	return result;
}
