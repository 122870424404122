
export function calcSiCbPixelValue(
    inverterMaxPowerWatt: number,
    inverterLoadingRation: number,
) {
    return inverterMaxPowerWatt * inverterLoadingRation 
}

export function calcSiCbClusterValue(
    combinerBoxMaxCurrentAmp: number,
    inverterMaxCurrentOutputAmp: number,
    inverterMaxCurrentInputAmp: number,
    mostPowerfullStringMaxCurrentAmp: number,
    mostPowerfullStringPowerWatt: number,
    necMultiplier: number,
) {
    const pixel_value = Math.trunc(
        inverterMaxCurrentInputAmp 
        / mostPowerfullStringMaxCurrentAmp
    ) * mostPowerfullStringPowerWatt;

    const cluster_value = Math.trunc(
        combinerBoxMaxCurrentAmp 
        / inverterMaxCurrentOutputAmp 
        / necMultiplier
    ) * pixel_value;

    if (!cluster_value) {
        return 0.0001;
    }

    return cluster_value;
}

export function calcCiPixelValue(
    combinerBoxCurrentAmp: number,
    mostPowerfullStringPowerWatt: number,
    mostPowerfullStringMaxCurrentAmp: number,
    necMultiplier: number,
) {
    const pixel_value = Math.trunc(
        combinerBoxCurrentAmp
        / (mostPowerfullStringMaxCurrentAmp * necMultiplier)
    ) * mostPowerfullStringPowerWatt;

    return pixel_value;
}