import type { Bim } from '../../Bim';
import { SelectorProperty } from '../../properties/PrimitiveProps';
import type { PropertyGroup } from '../../properties/PropertyGroup';
import { StateType } from '../ConfigsArchetypes';

export const SystemOfUnitsConfigTypeIdent = 'system-of-units-type';

export interface SystemOfUnitsConfig extends PropertyGroup {
    systemOfUnits: SelectorProperty;
    currency: SelectorProperty;
}

export function registerSystemOfUnits(bim: Bim) {
    bim.configs.archetypes.registerArchetype({
        type_identifier: SystemOfUnitsConfigTypeIdent,
        properties: () => {
            const properties: SystemOfUnitsConfig = {
                systemOfUnits: SelectorProperty.new({
                    value: "Imperial",
                    options: ["Imperial", "Metric"],
                }),
                currency: SelectorProperty.new({
                    value: "usd",
                    options: ["usd", "eur"],
                    description: "currency to use in the project",
                }),
            };
            return properties;
        },
        stateType: StateType.Singleton,
    });
}
