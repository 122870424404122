<script lang="ts">
    import { DialogDescription, UiCustomNode, type PUI_ActionDescr, type PUI_CustomPropertyNode, type UiBindings } from 'ui-bindings';
    import type { TrackerShadingFactorsProperty } from 'bim-ts';
    import { getContext } from 'svelte';
    import ActionProperty from '../ActionProperty.svelte';
    import { trackerShadingPolarChart } from './TrackerShadingPui';
    import PolarHeatmapChart from '../../charts/PolarHeatmapChart.svelte';

    export let property: PUI_CustomPropertyNode<TrackerShadingFactorsProperty>;

    $: shadingPolarHeatmapDescription = trackerShadingPolarChart(property.value);

    const uiBindings: UiBindings = getContext("uiBindings");

    const openTableAction: PUI_ActionDescr<any> = {
        label: 'Shading factors per sun angle',
        action: () => {
            uiBindings.dialogsStream.pushNext(new DialogDescription({
                name: 'Shading factors per sun angle',
                context: property.value,
                uiSource: new UiCustomNode({context: property.value}),
            }));
        }
    }

</script>

<div class="property property-row">
    <span class="ui-config-property property-row buttons">
        <ActionProperty
            actionDescr={openTableAction}
            context={null}
        ></ActionProperty>
    </span>

    <div class="chart">
        <PolarHeatmapChart chartDescription={shadingPolarHeatmapDescription}>
        </PolarHeatmapChart>
    </div>

</div>

<style lang="scss">
    .property {
        justify-content: flex-end;
        margin-left: auto;
        width: 95%;
    }
    .chart {
        width: 100%;
        height: 180px;
        overflow: hidden;
    }
</style>

