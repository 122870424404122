import { Vector2 } from 'math-ts';
import { DISTANCE_EPSILON } from '../../constants';

export class RotateUtils {
    /**
     * @param shiftRad
     * Angle from positive Ox, that show rotation.
     */
    constructor(readonly shiftRad: number) {}

    arePointsOnLine(pt1: Vector2, pt2: Vector2) {
        const offsetAtPt1 = this.getOffsetForPoint(pt1);
        const offsetAtPt2 = this.getOffsetForPoint(pt2);
        return Math.abs(offsetAtPt1 - offsetAtPt2) < DISTANCE_EPSILON;
    }

    arePointsOrtagonal(pt1: Vector2, pt2: Vector2) {
        if (Math.abs(pt1.x - pt2.x) < DISTANCE_EPSILON) {
            return true;
        }
        return this.arePointsOnLine(pt1, pt2);
    }

    getOffsetForPoint(pt: Vector2) {
        const offset = pt.y - Math.tan(this.shiftRad)*pt.x;
        return offset;
    }

    /**
     * @returns
     * Points that are connecting `from` -> `to` through ortagonal(angled) lines.
     *
     * @remarks
     * There are 2 possible ortagonal paths. In first we start moving
     * horizontally till intermid point then move verticly. Or move vertically
     * till the intermid point and then move horizontally.
     *
     */
    findOrtagonalIntermidPoints(
        from: Vector2,
        to: Vector2,
    ) {
        const offsetAtFrom = this.getOffsetForPoint(from);
        const offsetAtTo = this.getOffsetForPoint(to);
        const horizontalThenVertical = new Vector2(
            to.x,
            Math.tan(this.shiftRad)*to.x + offsetAtFrom,
        );
        const verticalThenHorizontal = new Vector2(
            from.x,
            Math.tan(this.shiftRad)*from.x + offsetAtTo,
        );
        const distance =
            from.distanceTo(horizontalThenVertical) +
            horizontalThenVertical.distanceTo(to);
        return {
            horizontalThenVertical,
            verticalThenHorizontal,
            distance,
        };
    }

}
