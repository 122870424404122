import { createNotificationSource } from 'ui-bindings';

export const notificationSource = createNotificationSource({
    action: {
        header: (name) => `${name}`,
        description: ''
    },
    actionFailed: {
        header: (name) => `${name} failed`,
        description: ''
    },
    saving: {
        header: 'Saving',
        description: ''
    },
    versionUpdated: {
        header: (type) => `${type} - updated`,
        description: 'Load latest version?'
    }
})