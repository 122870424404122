import type { DC_CNSTS } from 'bim-ts';
import type { Result} from 'engine-utils-ts';
import { DefaultMap, Failure, Success } from 'engine-utils-ts';
import type { PatternStepDesc } from '../utils';
import { Collection } from './collection';
import type { NodeId, Node } from './node';

export class Nodes extends Collection<Node, NodeId> {
    private latestByPatternStepType: Map<DC_CNSTS.NodeName, Node> =
        new Map();
    roots: Set<Node> = new Set();
    perStepName: DefaultMap<DC_CNSTS.NodeName, Set<Node>> =
        new DefaultMap(() => new Set());
    add(val: Node): Node;
    add(val: Node[]): Node[];
    add(val: Node | Node[]): Node | Node[] {
        // handle adding list of nodes
        if (Array.isArray(val)) {
            const items = val.map(x => this.add(x));
            return items;
        }
        // if id was not provided, populate it
        if (isNaN(val.id))
            val.id = this.genId();
        this.items.set(val.id, val);
        this.latestByPatternStepType.set(
            val.step.patternStepType,
            val,
        );
        if (!val.parent) this.roots.add(val);
        // set perStepName
        this.perStepName
            .getOrCreate(val.step.patternStepType)
            .add(val);
        return val;
    }

    private static stepsToIgnoreForParenting: Set<DC_CNSTS.NodeName> = new Set([
        'utility', 'end_of_group', 'end_of_multiharness',
    ] as DC_CNSTS.NodeName[]);

    findParentByPatternStep(
        step: PatternStepDesc
    ): Result<Node, NodeParentByPatternStepFailure> {
        const prevStep = step.getParentStep();
        if (!prevStep) return new NodeParentByPatternStepFailure({ noParent: true });
        if (!Nodes.stepsToIgnoreForParenting.has(prevStep.patternStepType)) {
            const node = this.latestByPatternStepType
                .get(prevStep.patternStepType);
            if (!node) {
                return new NodeParentByPatternStepFailure({ noImmediateParent: true })
            }
            return new Success(node);
        }
        return this.findParentByPatternStep(prevStep);
    }
}

class NodeParentByPatternStepFailure extends Failure {
    noImmediateParent?: true;
    noParent?: true;
    constructor(args: { noImmediateParent?: true, noParent?: true }) {
        super({});
        this.noImmediateParent = args.noImmediateParent;
        this.noParent = args.noParent;
    }
}
