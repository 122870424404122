import { WGSCoord } from 'bim-ts';
import { Failure, LazyBasic, LazyDerived, ObservableObject, Success } from 'engine-utils-ts';
import { DialogDescription, PUI_Builder, PUI_GroupNode, PUI_Lazy } from 'ui-bindings';
import { MeteoLocationData } from './TMY_ParserUtils';

const DialogMessage = `
    The uploaded file lacks sufficient location information for the meteodata.
    Please cancel the upload if you are not ready to provide this information.<br/><br/>
    Uploading meteodata with incorrect location details will produce an energy report
    that may appear correct but will be inaccurate.
`;

export function getSettingsDialog(
    settingsObs: ObservableObject<MeteoLocationData>,
    _params: any,
    submitAction: {label: string, action: () => void},
    cancelAction: () => void,
): DialogDescription<PUI_GroupNode, undefined> {
    const isInvalid = new LazyBasic('invalid-form', false);

    const puiLazy = LazyDerived.new1(
        'meteo-settings-form',
        [],
        [settingsObs],
        ([props]) => {
            const builder = new PUI_Builder({sortChildrenDefault: false});
            builder.addStringProp({
                name: 'Meteodata geo coordinates',
                value: `${props.lat}, ${props.long}`,
                validator: (newValue) => {
                    if (newValue && WGSCoord.tryParseLatLongFromString(newValue)) {
                        isInvalid.replaceWith(false);
                        return new Success(newValue);
                    } else {
                        isInvalid.replaceWith(true);
                        return new Failure({msg:'Invalid format'});
                    }
                },
                onChange: (value) => {
                    const newOrigin = WGSCoord.tryParseLatLongFromString(value);
                    if (newOrigin) {
                        settingsObs.applyPatch({
                            patch: {
                                long: newOrigin.longitude,
                                lat: newOrigin.latitude
                            }
                        });
                    }
                },
            });
         
            builder.addNumberProp({
                name: 'Timezone',
                value: Number(props.timeZone),
                minMax: [-12, 12],
                step: 1,
                onChange: (value) => {
                    settingsObs.applyPatch({
                        patch: { timeZone: value }
                    });
                },
            });

            builder.addNumberProp({
                name: 'Altitude',
                value: Number(props.alt),
                minMax: [-1000, 10000],
                unit: 'm',
                step: 1,
                onChange: (value) => {
                    settingsObs.applyPatch({
                        patch: { alt: value }
                    });
                },
            });
      
            return builder.finish();
        }
    ).withoutEqCheck();

    return new DialogDescription({
        name: 'Meteofile data missing',
        context: undefined,
        message: DialogMessage,
        uiSource: new PUI_Lazy(puiLazy),
        userActions: [{
            name: 'Cancel',
            action: cancelAction,
            style: 4,
        },
        {
            name: 'Upload',
            disabled: isInvalid,
            action: submitAction.action,
        }],
        compact: true,
        onClose: cancelAction
    })
}