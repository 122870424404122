import { Bim, CostModel as CM, UnitsMapper } from 'bim-ts';
import { createGridConfig } from '.';
import { GridApi } from 'ag-grid-enterprise';

const {
    CostSourceColId,
    CostUnitColId,
    EquipmentCostPerUnitColId,
    EquipmentTotalColId,
    LaborCostPerUnitColId,
    LaborPerUnitColId,
    LaborTotalColId,
    LoadedWageRateColId,
    MaterialCostPerUnitColId,
    MaterialTotalColId,
    QuantityColId,
    SubServiceCostPerUnitColId,
    SubServiceTotalColId,
    TotalCostColId,
    CostPerWattColId,
    CostStateColId,
} = CM;



export type IdRowDataType = string

export type RowDataType = CM.FlattenedCostCategoryParams

export interface GridContext {
    hierarchy: CM.CostHierarchy,
    unitsMapper: UnitsMapper,
    totalDCWatt: number,
    bim: Bim,
    echarts: CM.TableEChartIntegration[],
    tableWidth: number,
}

export type GridConfigType = ReturnType<typeof createGridConfig>

export type GridApiType = GridApi<RowDataType>;

export const AllColIds = [
    CostSourceColId,
    CostUnitColId,
    CostStateColId,
    QuantityColId,

    LaborTotalColId,
    LaborPerUnitColId,
    LaborCostPerUnitColId,
    LoadedWageRateColId,

    MaterialCostPerUnitColId,
    MaterialTotalColId,

    SubServiceCostPerUnitColId,
    SubServiceTotalColId,

    EquipmentCostPerUnitColId,
    EquipmentTotalColId,

    TotalCostColId,
    CostPerWattColId,
]

export const UserHiddableColIds = [

    LaborPerUnitColId,
    LaborCostPerUnitColId,
    LoadedWageRateColId,
    LaborTotalColId,

    MaterialCostPerUnitColId,
    MaterialTotalColId,

    SubServiceCostPerUnitColId,
    SubServiceTotalColId,

    EquipmentCostPerUnitColId,
    EquipmentTotalColId,
]
