import { LazyBasic, LegacyLogger } from 'engine-utils-ts';
import type { Vector2, Vector4 } from 'math-ts';
import { KeyModifiersFlags } from 'ui-bindings';

import type { BufferGeometry, ShaderMaterial } from '../3rdParty/three';
import { Mesh, PlaneBufferGeometry } from '../3rdParty/three';
import { MouseButton } from '../controls/InputController';
import type { MouseClickConsumer, MouseDragConsumer, MouseEventData, MouseGestureConsumer
} from '../controls/MouseGesturesBase';
import {
    GesturesButtons
} from '../controls/MouseGesturesBase';
import type { KrCamera } from '../controls/MovementControls';
import { MaterialsUtils } from '../materials/MaterialsUtils';
import { MathUtils } from '../MathUtils';
import type { EngineScene } from '../scene/EngineScene';
import type { IdEngineObject, InteractiveObjectsActive } from '../scene/InteractiveSceneObjects';
import type { SceneInt, SceneRaycaster } from '../scene/SceneRaycaster';
import { RectSelectorShader } from '../shaders/RectSelectorShader';
import type { FrustumExt } from '../structs/FrustumExt';
import type { RaySection } from '../structs/RaySection';
import { RectPortion } from '../structs/RectPortion';
import type { Time } from '../time/TIme';
import Utils from '../utils/Utils';
import { GizmoBase } from './GizmoBase';
import { GizmoIntersection } from './GizmoIntersection';

export interface RectSelectorState {
	selectionStart: Vector2;
	currentSelection: IdEngineObject[];
}

export class RectSelectorGizmo extends GizmoBase<any> {

	readonly scene: EngineScene;

	readonly _state: LazyBasic<RectSelectorState | null>;

	readonly material: ShaderMaterial;
	readonly geometry: BufferGeometry;


	constructor(scene: EngineScene, time: Time) {
		super();
		this.scene = scene;
		this.visible = false;

		this._state = new LazyBasic<RectSelectorState | null>('rect_selector_state', null);

		this.material = MaterialsUtils.createShaderMaterial(RectSelectorShader);
		this.material.transparent = true;
		this.material.depthWrite = false;
		this.material.depthTest = false;

		this.geometry = new PlaneBufferGeometry(1, 1, 1, 1);

		const areaMesh = new Mesh(this.geometry, this.material);
		this.add(areaMesh);
	}

	enableSelection(coords: Vector2) {
		this._state.forceUpdate({
			selectionStart: coords.clone(),
			currentSelection: [],
		});
		this.visible = false;
	}

	update(_fr: FrustumExt, _raycaster: RaySection) {
	}

	version(): number {
		return this._state.version();
	}

	updateHighlighted(
		camera:KrCamera,
		coords: Vector2,
		objects: InteractiveObjectsActive,
		params:RectSelectorDragState
	) {
		const s = this._state.getForMutation()

		if (s === null) {
			this.visible = false;
			LegacyLogger.error('area selector null start');
			return;
		}

		const rectPortion = new RectPortion();
		rectPortion.setFromPoints(s.selectionStart, coords);
		// this.areaFrustum.setFromCameraSubFrustum(camera, rectPortion);

		if (!(rectPortion.size.y > 0) || !(rectPortion.size.x > 0)) {
			this.visible = false;
			return;
		}

		const shaderRect = new RectPortion();
		shaderRect.setFromPoints(
			s.selectionStart.mapHtmlToWebglCoords(),
			coords.mapHtmlToWebglCoords(),
		)

		this.material.uniforms['rectStart'].value.copy(shaderRect.start);
		this.material.uniforms['rectSize'].value.copy(shaderRect.size);
		this.material.uniforms['aspectRatio'].value = Utils.getCameraAspectRatio(camera);
		this.visible = true;

		const fm = MathUtils.makeSubFrustumProjectionMatrix(camera, rectPortion);
		fm.multiply(camera.matrixWorldInverse);

		let selectionIds: IdEngineObject[];

		if (coords.x < s.selectionStart.x) {
			this.material.uniforms['strokePower'].value = 1;
			selectionIds = objects.findIntersectingFrustum(fm, camera.near, camera.far, false);
		} else {
			this.material.uniforms['strokePower'].value = 0;
			selectionIds = objects.findIntersectingFrustum(fm, camera.near, camera.far, true);
		}
		if (params.selectHierarchies) {
			selectionIds = objects.gatherIdsWithSubtreesOf({ids: selectionIds, sortParentFirst: false});
		}
		s.currentSelection = selectionIds;
	}

	setContrastColor(white: number) {
		const color = this.material.uniforms['color'].value as Vector4;
		color.set(white, white, white, color.w);
	}

	currentElements(): IdEngineObject[] {
		return this._state.poll()?.currentSelection || [];
	}

	disable() {
		if (this._state.poll() === null) {
			return;
		}
		this._state.forceUpdate(null);
		this.visible = false;
	}

	dispose(): void {
		this.material.dispose();
		this.geometry.dispose();
	}


}



export class EntitiesRectSelector implements MouseGestureConsumer {

	readonly clickConsumers: MouseClickConsumer[] = [];
	readonly dragConsumers: MouseDragConsumer<any>[] = [];

	readonly sceneObjects: InteractiveObjectsActive;
	readonly rectSelectionGizmo: RectSelectorGizmo;
	readonly sceneRaycaster: SceneRaycaster;

	constructor(
		sceneObjects: InteractiveObjectsActive,
		sceneRaycaster: SceneRaycaster,
		rectSelectionGizmo: RectSelectorGizmo,
	) {
		this.sceneObjects = sceneObjects;
		this.rectSelectionGizmo = rectSelectionGizmo;
		this.sceneRaycaster = sceneRaycaster;
		this.dragConsumers.push(new RectSelectionMouseDragConsumer(
			rectSelectionGizmo,
			sceneObjects,
			sceneRaycaster,
			GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.Ctrl),
			(me) => {
				rectSelectionGizmo.enableSelection(me.pos.mousePosNormalized);
				return { removeFromSelection: false, selectHierarchies: false };
			},
		));
		this.dragConsumers.push(new RectSelectionMouseDragConsumer(
			rectSelectionGizmo,
			sceneObjects,
			sceneRaycaster,
			GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.Shift),
			(me) => {
				rectSelectionGizmo.enableSelection(me.pos.mousePosNormalized);
				return { removeFromSelection: true, selectHierarchies: false };
			},
		));

		this.dragConsumers.push(new RectSelectionMouseDragConsumer(
			rectSelectionGizmo,
			sceneObjects,
			sceneRaycaster,
			GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.Ctrl | KeyModifiersFlags.Alt),
			(me) => {
				rectSelectionGizmo.enableSelection(me.pos.mousePosNormalized);
				return { removeFromSelection: false, selectHierarchies: true };
			},
		));
		this.dragConsumers.push(new RectSelectionMouseDragConsumer(
			rectSelectionGizmo,
			sceneObjects,
			sceneRaycaster,
			GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.Shift | KeyModifiersFlags.Alt),
			(me) => {
				rectSelectionGizmo.enableSelection(me.pos.mousePosNormalized);
				return { removeFromSelection: true, selectHierarchies: true };
			},
		));

	}

	isEnabled(): boolean {
		return true;
	}
}

interface RectSelectorDragState {
	removeFromSelection: boolean;
	selectHierarchies: boolean;
}
class RectSelectionMouseDragConsumer implements MouseDragConsumer<RectSelectorDragState> {
    buttons: GesturesButtons;

	cursorDragStyle = () => 'none';

	gizmo: RectSelectorGizmo;
	objects: InteractiveObjectsActive;

    constructor(
		gizmo: RectSelectorGizmo,
		objects: InteractiveObjectsActive,
		raycaster: SceneRaycaster,
        buttons: GesturesButtons,
        tryStartDrag: (med: MouseEventData, mouseDownInfo: {sceneInt: SceneInt, med: MouseEventData}, ) => RectSelectorDragState,
    ) {
        this.gizmo = gizmo;
		this.objects = objects;
        this.buttons = buttons;
        this.tryStartDrag = tryStartDrag;
	}

	tryStartDrag: (med: MouseEventData, mouseDownInfo: {sceneInt: SceneInt, med: MouseEventData}, ) => RectSelectorDragState;

	onButtonDown(sceneInt: SceneInt, me: MouseEventData): boolean {
		return true;
	}

	lockPointerOnDrag?: ((s: RectSelectorDragState) => boolean) | undefined;

	handleDrag(s: RectSelectorDragState, me: MouseEventData): boolean {
		this.gizmo.updateHighlighted(
			me.camera,
			me.pos.mousePosNormalized,
			this.objects,
			s
		);
		this.objects.setHighlighted(this.gizmo.currentElements());
		return true;
	}

    sceneRaycastTypeguard(closest: SceneInt): boolean {
        return !(closest instanceof GizmoIntersection);
    }

	onButtonUp(s: RectSelectorDragState, me: MouseEventData): void {
		this.objects.toggleSelected(!s.removeFromSelection, this.gizmo.currentElements())
    }

	stop(): void {
		this.gizmo.disable();
    }

}

