import { Aabb } from 'math-ts';

import type { Submeshes2 } from './scene/Submeshes2';

export class TotalBounds {

	_isDefault: boolean = true;
	readonly bounds: Aabb = Aabb.allocateSizeOne();

	readonly _prevBounds: Aabb = Aabb.allocateSizeOne();
	updated: boolean = false;

	update(
		submeshes: Submeshes2,
		// gizmoMeshes: SceneGizmoObjects,
		// rulers: RulerMeshes,
		// siteMarkup: SiteMarkupTilesets,
	) {
		this.updated = false;

		this.bounds.makeEmpty();

		// this.bounds.elements[0] = siteMarkup.boundsWS.min.x;
		// this.bounds.elements[1] = siteMarkup.boundsWS.min.y;
		// this.bounds.elements[3] = siteMarkup.boundsWS.max.x;
		// this.bounds.elements[4] = siteMarkup.boundsWS.max.y;

		this.bounds.union(submeshes.scene.bounds);
		// this.bounds.union(gizmoMeshes.scene.bounds);
		// // this.bounds.union(rulers.scene.bounds);

		// if (this.bounds.isEmpty() && !siteMarkup.boundsWS.isEmpty()) {
		// 	this.bounds.elements[2] = 0;
		// 	this.bounds.elements[5] = 0;
		// }
		
		if (this.bounds.isEmpty()) {
			this.reset();
		} else {
			this._isDefault = false;
		}
		if (!this.bounds.equals(this._prevBounds)) {
			this._prevBounds.copy(this.bounds);
			this.updated = true;
		}
		// this.bounds.union(this.submeshes.scene.totalBounds);
	}

	reset() {
		this._isDefault = true;
		this.bounds.setSizeOne();
	}

	isEmpty() {
		return this._isDefault;
	}
}