
import { LegacyLogger } from 'engine-utils-ts';

import type { TypedArray } from '../3rdParty/three';
import type { Allocator } from './AllocationSynchronizer';
import type { RawDataGateway } from './RawDataGateway';

export type binaryElementProcedure<_T extends TypedArray> = (index: number) => void;
export type binaryArrayAllocator<T extends TypedArray> = (size: number) => T;

export interface BinaryAllocatorParams<StructT, BufferT extends TypedArray> {
	readonly dataGateway: RawDataGateway<StructT, BufferT>;
	readonly initialCapacityInItems: number,
	readonly bufferAllocator: binaryArrayAllocator<BufferT>;
	readonly onFree: null | ((self: RawDataGateway<StructT, BufferT>, index: number) => void);
}

export class BinaryAllocator<StructT, ArrT extends TypedArray> implements Allocator<StructT> {
	
	readonly boundGateway: RawDataGateway<StructT, ArrT>;
	readonly itemSizePrimitives: number;
	readonly bufferAllocator: binaryArrayAllocator<ArrT>;
	readonly onFree: null | ((self: RawDataGateway<StructT, ArrT>, index: number) => void);
	
	_buffer!: ArrT;
	_currentCapacity!: number;

	constructor(params: BinaryAllocatorParams<StructT, ArrT>) {
		this.boundGateway = params.dataGateway;
		this.itemSizePrimitives = this.boundGateway.itemLength;
		this.bufferAllocator = params.bufferAllocator;
		this.onFree = params.onFree;

		this._reallocateBuffer(params.initialCapacityInItems);
	}

	_reallocateBuffer(capacity: number): boolean {
		const byteCapacity = this.itemSizePrimitives * capacity;
		try {
			this._buffer = this.bufferAllocator(byteCapacity);
			LegacyLogger.debugAssert(this._buffer.length === byteCapacity, 'allocated buffer length check');
			this._currentCapacity = capacity;
			this.boundGateway.setInternalBuffer(this._buffer);
			return true;
		} catch (e) {
			LegacyLogger.error('couldnt allocate binary buffer', byteCapacity, e);
			return false;
		}
	}

	changeCapacity(newCapacity:number) {
		if (this.onFree) {
			for (let i = newCapacity; i <  this._currentCapacity; ++i) {
				this.onFree(this.boundGateway, i);
			}
		}

		if (this._buffer.byteOffset > 0) {
			LegacyLogger.trace('binary allocator: byte offset > 9, it\'s wasm memory, don\'t copy prev data');
			this._reallocateBuffer(newCapacity);
		} else {
			let prevBuffer = this._buffer;
			this._reallocateBuffer(newCapacity);
			const subsetToCopy = prevBuffer.subarray(0, Math.min(this._buffer.length, prevBuffer.length));
			this._buffer.set(subsetToCopy);
		}
	}

	allocate(index: number, source: StructT): boolean {
		if (!(index >= 0)) {
			return false;
		}
		if (index >= this._currentCapacity) {
			return false;
		}
		this.boundGateway.toBuffer(source, index);
		return true;
	}

	free(indexes: number[]): void {
		for(const index of indexes) {
			if (index < this._currentCapacity && index >= 0) {
				if (this.onFree) {
					this.onFree(this.boundGateway, index);
				}
			} else {
				LegacyLogger.error('trying to free memory out of bounds', index, this._currentCapacity);
			}
		}
	}
}
