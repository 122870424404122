<script lang="ts">
    import { getContext } from "svelte";
    import type { NotificationsLog } from "../notifications/NotificationsLogStore";
    import { IconComponent } from "../libui/icon";
    import ToolbarDropdownItem from "./ToolbarDropdownItem.svelte";
    import { MenuDropdownItem } from "./ToolbarItems";
    import { UiCustomNode } from "ui-bindings";
    import { LazyBasic } from "engine-utils-ts";

    export let activeDropDownMenu: string | null = null;

    const buttonName = "notifications";
    let isOpen = false;

    $: isMenuOpen = activeDropDownMenu === buttonName;

    let log = getContext<NotificationsLog>("notificationsLog");
    let unreadNotifications = false;

    log.subscribe(() => {
        if (!(isMenuOpen || isOpen)) {
            unreadNotifications = true;
        }
    });
    $: {
        if (isOpen) {
            unreadNotifications = false;
        }
    }
    const item = new MenuDropdownItem(
        buttonName,
        new UiCustomNode({ context: null }),
        new LazyBasic(buttonName, buttonName),
    );
</script>

<div class="notifications-button">
    <ToolbarDropdownItem menu={item} bind:activeDropDownMenu bind:isOpen>
        <IconComponent
            name={unreadNotifications ? "NotificationsUnread" : "Notifications"}
        />
    </ToolbarDropdownItem>
</div>

<style lang="scss">
</style>
