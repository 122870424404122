import type { TrackerShadingFactorsProperty } from "bim-ts";
import { IterUtils, LegacyLogger } from "engine-utils-ts";
import { PUI_GroupNode, PUI_PropertyNodeString, type CHD_CustomDataSeries, type CHD_PolarHeatmapChart } from "ui-bindings";

export function trackerShadingPropertyGridPui(prop: TrackerShadingFactorsProperty): PUI_GroupNode[] {

    const rows: PUI_GroupNode[] = [];
    const shadingData = prop.value;

    for (let heightDeg = 90; heightDeg >= 0; heightDeg -= 5) {

        if (heightDeg > 30 && heightDeg % 10 !== 0) {
            continue;
        }

        const rowGroup = new PUI_GroupNode({
            name: `height: ${heightDeg}`,
            sortChildren: false,
        });
        rows.push(rowGroup);

        rowGroup.addMaybeChild(new PUI_PropertyNodeString({
            name: "SUN HEIGHT / AZIMUTH",
            value: heightDeg + '°',
            readonly: true,
            onChange: ()=>{},
        }));

        for (let azimuth = -180; azimuth <= 180; azimuth += 30) {

            const value = shadingData.sampleAt(heightDeg, (azimuth + 360) % 360);

            rowGroup.addMaybeChild(new PUI_PropertyNodeString({
                name: azimuth.toFixed(0) + '°',
                value: value.toFixed(3),
                readonly: true,
                onChange: ()=>{},
            }));
        }

    }
    return rows;

}

export function trackerShadingPolarChart(prop: TrackerShadingFactorsProperty): CHD_PolarHeatmapChart {
    const shadingTable = prop.value;

    const series: CHD_CustomDataSeries = {
        dimensions: ['SUN HEIGHT', 'AZIMUTH', 'SHADING FACTOR'],
        data: [],
    };

    const heightsToSample = IterUtils.newArray(30, (deg) => 90 - deg * 3);
    const azimuthsToSample = IterUtils.newArray(36, (deg) => deg * 10);

    const radiusAxis: string[] = heightsToSample.map((deg) => deg.toFixed(0) + '°');
    const angleAxis: string[] = azimuthsToSample.map((deg) => {
        if (deg > 180) {
            deg -= 360;
        }
        let res = deg.toFixed(0) + '°';
        if (deg === 0) {
            res += '(N)';
        } else if (deg === 90) {
            res += '(E)';
        } else if (deg === 180) {
            res += '(S)';
        } else if (deg === 270 || deg === -90) {
            res += '(W)';
        }
        return res;
    });

    for (let azimIndex = 0; azimIndex < azimuthsToSample.length; ++azimIndex) {
        for (let heightIndex = 0; heightIndex < heightsToSample.length; ++heightIndex) {
            const height = heightsToSample[heightIndex];
            const azimuth = azimuthsToSample[azimIndex];
            const value = shadingTable.sampleAt(height, azimuth);
            if (!Number.isFinite(value)) {
                LegacyLogger.deferredError('invalid shading value at', [height, azimuth]);
                continue;
            }
            series.data.push([
                radiusAxis[heightIndex],
                angleAxis[azimIndex],
                value.toFixed(3)
            ]);
        }
    }

    return {
        type: 'polar-heatmap',
        visualMap: {
            type: 'continuous',
            show: true,
            min: 0,
            max: 1,
            align: 'auto',
            inRange: {
                color: ['#ffffff', '#000000'],
            },
            text: ['SHADED', 'LIT'],
        },
        radiusAxis: {
            data: radiusAxis,
        },
        angleAxis: {
            data: angleAxis,
        },
        series: [series],
    };
}