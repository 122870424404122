// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class Indices implements flatbuffers.IUnpackableObject<IndicesT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Indices {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsIndices(bb:flatbuffers.ByteBuffer, obj?:Indices):Indices {
  return (obj || new Indices()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsIndices(bb:flatbuffers.ByteBuffer, obj?:Indices):Indices {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Indices()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

uintInds(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
}

uintIndsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

uintIndsArray():Uint32Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? new Uint32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

ushortInds(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint16(this.bb!.__vector(this.bb_pos + offset) + index * 2) : 0;
}

ushortIndsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

ushortIndsArray():Uint16Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? new Uint16Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

static startIndices(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addUintInds(builder:flatbuffers.Builder, uintIndsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, uintIndsOffset, 0);
}

static createUintIndsVector(builder:flatbuffers.Builder, data:number[]|Uint32Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createUintIndsVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createUintIndsVector(builder:flatbuffers.Builder, data:number[]|Uint32Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt32(data[i]!);
  }
  return builder.endVector();
}

static startUintIndsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addUshortInds(builder:flatbuffers.Builder, ushortIndsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, ushortIndsOffset, 0);
}

static createUshortIndsVector(builder:flatbuffers.Builder, data:number[]|Uint16Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createUshortIndsVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createUshortIndsVector(builder:flatbuffers.Builder, data:number[]|Uint16Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(2, data.length, 2);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt16(data[i]!);
  }
  return builder.endVector();
}

static startUshortIndsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(2, numElems, 2);
}

static endIndices(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createIndices(builder:flatbuffers.Builder, uintIndsOffset:flatbuffers.Offset, ushortIndsOffset:flatbuffers.Offset):flatbuffers.Offset {
  Indices.startIndices(builder);
  Indices.addUintInds(builder, uintIndsOffset);
  Indices.addUshortInds(builder, ushortIndsOffset);
  return Indices.endIndices(builder);
}

unpack(): IndicesT {
  return new IndicesT(
    this.bb!.createScalarList<number>(this.uintInds.bind(this), this.uintIndsLength()),
    this.bb!.createScalarList<number>(this.ushortInds.bind(this), this.ushortIndsLength())
  );
}


unpackTo(_o: IndicesT): void {
  _o.uintInds = this.bb!.createScalarList<number>(this.uintInds.bind(this), this.uintIndsLength());
  _o.ushortInds = this.bb!.createScalarList<number>(this.ushortInds.bind(this), this.ushortIndsLength());
}
}

export class IndicesT implements flatbuffers.IGeneratedObject {
constructor(
  public uintInds: (number)[] = [],
  public ushortInds: (number)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const uintInds = Indices.createUintIndsVector(builder, this.uintInds);
  const ushortInds = Indices.createUshortIndsVector(builder, this.ushortInds);

  return Indices.createIndices(builder,
    uintInds,
    ushortInds
  );
}
}
