import { EnumUtils, LazyDerived } from 'engine-utils-ts';
import { PUI_PropertyNodeBool, PUI_PropertyNodeSelector, SettingDescription } from 'ui-bindings';
import type { UiBindings } from 'ui-bindings';
import { TransformOrientationMode, TransformPivotMode } from '../EngineConsts';

import type { TransformGizmo } from './TransformGizmo';

export class TransformGizmoUiBindings {
    static addBindings(uiBindings: UiBindings, tr: TransformGizmo) {
        const contextualSettings = LazyDerived.new1(
            'gizmo-settings',
            [],
            [tr.gizmoState.state],
            ([state]) => {
                return [
                    new SettingDescription({
                        name: ['Edit', 'Preferences', 'Move parents only'],
                        priority: 11,
                        property: new PUI_PropertyNodeBool({
                            name: "Move parents only",
                            value: state.moveOnlyParents,
                            onChange: (newValue) => {
                                tr.gizmoState.state.applyPatch({
                                    patch: { moveOnlyParents: newValue }
                                })
                            },
                        }),
                    }),
                    new SettingDescription({
                        name: ['Edit', 'Preferences', 'Orientation'],
                        priority: 14,
                        property: new PUI_PropertyNodeSelector({
                            name: "Orientation",
                            value: TransformOrientationMode[state.orientation],
                            options: EnumUtils.getAllEnumConstsNames(TransformOrientationMode),
                            onChange: (newValue) => {
                                tr.gizmoState.state.applyPatch({
                                    patch: { orientation: TransformOrientationMode[newValue as keyof typeof TransformOrientationMode] }
                                })
                            },
                        }),
                    }),
                    new SettingDescription({
                        name: ['Edit', 'Preferences', 'Pivot'],
                        priority: 15,
                        property: new PUI_PropertyNodeSelector({
                            name: "Pivot",
                            value: TransformPivotMode[state.pivot],
                            options: EnumUtils.getAllEnumConstsNames(TransformPivotMode),
                            onChange: (newValue) => {
                                tr.gizmoState.togglePivotMode(TransformPivotMode[newValue as keyof typeof TransformPivotMode])
                            },
                        }),
                    }),
                    new SettingDescription({
                        name: ['Edit', 'IsActive'],
                        keyCombinations: [{keyCode: 'KeyT'}],
                        priority: -1,
                        divider: true,
                        property: new PUI_PropertyNodeBool({
                            name: "Show move gizmo",
                            value: state.isActive,
                            onChange: (newValue) => {
                                tr.toggleActive(newValue);
                            },
                        }),
                    })
                ]
            }
        )
        uiBindings.addContextualActions('gizmo-settings', contextualSettings);
    }
}

