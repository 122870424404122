import { EquipmentDescription } from "../types"

export class TransformerDescription extends EquipmentDescription {
    childTransformer?: TransformerDescription
    childSC?: SCDescription
}

export class SCDescription extends EquipmentDescription {
    childTransformers: TransformerDescription[]
    childSCs: SCDescription[]

    constructor(params: {
        childTransformers: TransformerDescription[],
        childSCs: SCDescription[],
    } & ConstructorParameters<typeof EquipmentDescription>[0]) {
        super(params)
        this.childTransformers = params.childTransformers;
        this.childSCs = params.childSCs;
    }
}

export class SubstationDescription extends SCDescription {}
