<script lang="ts">

    import ActionsSearchBarImpl from './ActionsSearchBarImpl.svelte';

    import { onMount } from "svelte";
    import { Vector2 } from "math-ts";
    import { getModifierFlagsFromKeyboardEvent, KeyModifiersFlags, type ActionDescription } from 'ui-bindings';

    export let boundActions: ActionDescription<any>[] = [];

    let hidden = true;

    let searchBarWindowSize = new Vector2(400, 500);
    let mousePosition = new Vector2(700, 700);
    let topLeftPosition = new Vector2(500, 500);

    onMount(() => {
        window.addEventListener("keydown", onKeyDown);
        window.addEventListener("pointermove", onPointerMove);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
            window.removeEventListener("pointermove", onPointerMove);
        };
    });

    function onKeyDown(e: KeyboardEvent) {
        if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
            return;
        }
        if (hidden) {
            const modifiers = getModifierFlagsFromKeyboardEvent(e);
            const toOpen = (
                (modifiers === KeyModifiersFlags.None && e.code === 'F3')
                || (modifiers === KeyModifiersFlags.None && e.code === 'Enter')
            );
            if (toOpen) {
                hidden = false;
                e.preventDefault();
                e.stopPropagation();

                const windowSize = new Vector2(window.innerWidth, window.innerHeight);
                windowSize.sub(searchBarWindowSize);
                topLeftPosition.copy(mousePosition).min(windowSize);
            }
        }
    }
    function onPointerMove(e: PointerEvent) {
        mousePosition.set(e.clientX, e.clientY);
    }

    function close(e?: Event) {
        hidden = true;
        e?.preventDefault();
        e?.stopPropagation()
    }

</script>

<style>
    #background {
        position: fixed;
        z-index: 1000;
        background-color: rgba(0, 0, 0, 0.1);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    #foreground {
        z-index: 1001;
        width: 400px;
        height: 600px;
        background-color: white;
        padding: 10px;
        border-radius: 2px;
    }
</style>

{#if !hidden}
    <div id="background" on:click={(e) => {close(e); console.log('background click close');}}>
        <div id="foreground" style="
            position: fixed;
            top:{topLeftPosition.y}px; left:{topLeftPosition.x}px;
            width:{searchBarWindowSize.x}px; height:{searchBarWindowSize.y}px;
        ">
            <ActionsSearchBarImpl  boundActions={boundActions} on:done={() => close(undefined)}>
                <!-- <span slot="input">
                    <input type="text" bind:value bind:this={inputEl} />
                </span>
                <span slot="item" let:option>
                    {@html option.html.label}
                    <span class="secondary-text">
                      {@html option.html.link}
                    </span>
                </span> -->
            </ActionsSearchBarImpl>

        </div>
    </div>
{/if}
