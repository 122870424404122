import type { ScopedLogger, UndoStack} from 'engine-utils-ts';
import {
    DeferredPromise,
    IterUtils, ObjectUtils, ObservableObject, Registry,
    Success,
    VersionedInvalidator
} from 'engine-utils-ts';

import { GizmoIntersection } from '../gizmos/GizmoIntersection';
import type { SceneInt } from '../scene/SceneRaycaster';
import { EditGizmoIntersection } from '../scene/Raycasts';
import { MouseButton } from './InputController';
import type { InteractiveEditOperator, StartOptions } from './InteractiveEditOperator';
import type { MouseClickConsumer, MouseDragConsumer, MouseEventData, MouseGestureConsumer
} from './MouseGesturesBase';
import {
    GesturesButtons
} from './MouseGesturesBase';
import type { EditActionResult, MenuPath } from 'ui-bindings';

interface ActiveEditOperationData<State, Config> {
    readonly handlerIdent: MenuPath,
    readonly currentState: State,
}


type AnyInteractiveOperator = InteractiveEditOperator<Object | null, Object>;

export class EditOperators implements MouseGestureConsumer {

    readonly logger: ScopedLogger;
    private readonly _activeOperation: ObservableObject<{state:ActiveEditOperationData<Object | null, Object> | null}>;
    private _promiseResult: DeferredPromise<EditActionResult | undefined> | null;

    private readonly registry: Registry<AnyInteractiveOperator>;

    readonly clickConsumers: MouseClickConsumer[] = [];
	readonly dragConsumers: MouseDragConsumer<any>[] = [];

    constructor(
        logger: ScopedLogger,
        undoStack: UndoStack
    ) {
        this.logger = logger.newScope('edits-operations');
        this._promiseResult = null;
        this._activeOperation = new ObservableObject<{state:ActiveEditOperationData<Object | null, Object> | null}>({
            identifier: 'active-edit',
            initialState: {state: null},
            undoStack: undoStack,
            throttling: {onlyFields: []}
        });

        this.registry = new Registry(this.logger, 'edit-handlers');

        this._activeOperation.addPatchValidator("state", (state) => {
            if (state) {
                const handler = this.registry.getByPath(state.handlerIdent)
                if (!handler) {
                    this.logger.error('invalid state handler identifier', state.handlerIdent);
                    return null;
                }
            }
            if(state === null && this._promiseResult){
                if(!this._promiseResult.isFinalized()){
                    this.logger.warn('edit operation was cancelled');
                    this._promiseResult.resolve(undefined);
                }
                this._promiseResult = null;
            }
            return state;
        });

        const clickHandler = (sceneInt: SceneInt, me: MouseEventData): void => {
            const editState = this._activeOperation.poll().state;
            if (!editState) {
                return;
            }
            const handler = this.registry.getByPath(editState.handlerIdent)!;
            const {done, state} = handler.handleClick(
                sceneInt,
                me,
                ObjectUtils.deepCloneObj(editState.currentState),
            );

            if (done) {
                this.finishEdit();
            } else {
                this._activeOperation.applyPatch({patch: {state: {...editState, currentState: state}}});
            }
        }
        this.clickConsumers.push({
            buttons: GesturesButtons.newShared(MouseButton.Left),
            sceneRaycastTypeguard: (closest: SceneInt): boolean => {
                return closest !== null;
            },
            clickHandler: clickHandler,
        });
        this.clickConsumers.push({
            buttons: GesturesButtons.newShared(MouseButton.Right),
            sceneRaycastTypeguard: (closest: SceneInt): boolean => {
                return closest !== null;
            },
            clickHandler: clickHandler,
        });

    }

    finishEdit() {
        const active = this.getActiveOperator();
        const editState = this._activeOperation.poll();
        if (active) {
            this.logger.debug(`finish ${active.menuPath.join()}`);
            let result: EditActionResult | undefined = undefined;
			if (active.finish && editState.state?.currentState) {
                try {
                    result = active.finish(editState.state.currentState);
                } catch (error) {
                    this.logger.error('finish error', error);
                }
			}
            this._promiseResult?.resolve(result);
            this._activeOperation.applyPatch({patch: {state: null}});
        } else {
            this.logger.debug('nothing to finish');
        }
    }

    cancelEdit() {
        const editState = this._activeOperation.poll();
        if (editState.state) {
            this.logger.debug(`cancel`, editState.state.handlerIdent);
            const operator = this.registry.getByPath(editState.state.handlerIdent)!;
            operator.cancel(editState.state.currentState);
            this._promiseResult?.resolve(undefined);
            this._activeOperation.applyPatch({patch: {state: null}});
        } else {
            this.logger.debug('nothing to cancel');
        }
    }

    all(): Iterable<AnyInteractiveOperator> {
        return IterUtils.iterMap(this.registry.values(), ([_path, operator]) => operator);
    }

    stateInvalidator(): VersionedInvalidator {
        return new VersionedInvalidator([
            this._activeOperation,
            ...IterUtils.mapIter(this.registry.values(), op => op[1].config)
        ]);
    }

    patchOperatorConfig<T>(operatorIdent: MenuPath, patch: Partial<T>) {
        const editState = this._activeOperation.poll().state;
        if (!editState) {
            return;
        }
        if (!ObjectUtils.areObjectsEqual(editState.handlerIdent, operatorIdent)) {
            return;
        }
        const operator = this.registry.getByPath(editState.handlerIdent)!;
        operator.config.applyPatch({patch});
        const result = operator.handleConfigPatch(patch, ObjectUtils.deepCloneObj(editState.currentState));
        if (!result.done) {
            this._activeOperation.applyPatch({
                patch: {state: {
                    currentState: result.state, 
                    handlerIdent: editState.handlerIdent, 
                }}
            })
        }
    }

    onHover (int: SceneInt | null): {cursorStyleToSet: string} | null {
        if (int instanceof EditGizmoIntersection || int instanceof GizmoIntersection) {
            return null;
        }
        const active = this.getActiveOperator();
        if (active?.onHover) {
            return active.onHover(int);
        }
        return null;
    }

    getActiveOperator(): AnyInteractiveOperator | undefined {
        const editState = this._activeOperation.poll().state;
        if (!editState) {
            return undefined;
        }
        return this.registry.getByPath(editState.handlerIdent);
    }

    isEnabled() {
        return this.getActiveOperator() != undefined;
    }

    registerHandler<S extends (Object | null), C extends Object>(actionsHandler: InteractiveEditOperator<S, C>) {
        this.registry.register(
            actionsHandler.menuPath,
            actionsHandler as unknown as InteractiveEditOperator<Object | null, Object>
        );
    }

    startEditOperation(path: string[], options?: StartOptions) : Promise<EditActionResult | undefined> {
        const handler = this.registry.getByPath(path);
        this._promiseResult = new DeferredPromise<EditActionResult | undefined>(600_000);
        if (!handler) {
            this.logger.error('unregistered interactive operator', path);
            this._promiseResult.reject(['unregistered interactive operator']);
            return this._promiseResult.promise;
        }
        if (!handler?.canStart.poll()) {
            this._promiseResult.reject(['edit operation not allowed']);
            return this._promiseResult.promise;
        }
        const startAttempt = handler.start(options);
        if (startAttempt instanceof Success) {
            const state = startAttempt.value;
            this._activeOperation.applyPatch({
                patch: {state: {
                    handlerIdent: path,
                    currentState: state,
                }},
            });
            return this._promiseResult.promise;
        }
        this._promiseResult.reject([startAttempt.errorMsg()]);
        return this._promiseResult.promise;
    }


    disable() {
        this._promiseResult?.reject(['edit operation disabled']);
        this._activeOperation.applyPatch({
            patch: {state: null},
        });
    }


}

