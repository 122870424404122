import type { Matrix3, Vector2, Vector3, Vector4 } from 'math-ts';
import { combineHashCodes } from 'math-ts';

import { Texture } from '../3rdParty/three';

export type AnyUniform = Vector2 | Vector3 | Vector4 | Matrix3;

export type UniformsFlat = [string?,UniformValue?, string?, UniformValue?]

export type UniformValue = number | Vector2 | Vector3 | Vector4 | Matrix3 | Texture;

(Texture.prototype as any).hash = function (this: Texture): number {
	return this.id;
}


export function FlatUniformsHash(uniforms: UniformsFlat): number {
	let hash: number =  0;
	for (let i = 1; i < uniforms.length; i += 2) {
		const uniformValue = uniforms[i] as UniformValue;
		if (typeof uniformValue === 'number') {
			hash = combineHashCodes(hash, uniformValue);
		} else {
			hash = combineHashCodes(hash, uniformValue.hash());
		}
	}
	return hash;
}

export class HashedUniforms {
	readonly hash: number;
	readonly hasTextures: boolean = false;
	readonly map: Map<string, UniformValue> = new Map();

	constructor(hash:number, uniforms: UniformsFlat) {
		this.hash = hash;
		this.map = new Map();
		for (let i = 1; i < uniforms.length; i += 2) {
			const uni = uniforms[i];
			this.map.set(uniforms[i - 1] as any, uni as any);
			if (uni instanceof Texture) {
				this.hasTextures = true;
			}
		}
	}

	static fromFlat(uniforms: UniformsFlat): HashedUniforms {
		if (uniforms.length === 0) {
			return EmptyHashedUniforms;
		}
		return new HashedUniforms(FlatUniformsHash(uniforms), uniforms);
	}

	equalTo(flat: UniformsFlat): boolean {
		if (this.map.size * 2 !== flat.length) {
			return false;
		}
		for (let i = 1; i < flat.length; i += 2) {
			const name = flat[i - 1] as string;
			const val = flat[i] as UniformValue;
			const thisValue = this.map.get(name);
			if (!thisValue) {
				return false;
			}
			if (typeof thisValue === 'number') {
				if (thisValue !== val) {
					return false;
				}
			} else if (typeof val === 'number') {
				return false;			
			} else {
				const thisHash = thisValue.hash();
				const valHash = val.hash();
				if (thisHash !== valHash) {
					return false;
				}
				if ((thisValue as any).equals && !(thisValue as any).equals(val)) {
					return false;
				}
			}
		}
		return true;
	}

	static uniformsArray_t: UniformsFlat = [];

	static tempUniformsArray(): UniformsFlat {
		HashedUniforms.uniformsArray_t.length = 0;
		return HashedUniforms.uniformsArray_t;
	}
}
export const EmptyHashedUniforms = new HashedUniforms(0, []);

export class UniformsInterner {
	readonly _byHash: Map<number, HashedUniforms[]> = new Map();

	getUniqued(flat: UniformsFlat): HashedUniforms {
		if (flat.length === 0) {
			return EmptyHashedUniforms;
		}
		const hash = FlatUniformsHash(flat);

		let existing = this._byHash.get(hash);
		if (existing === undefined) {
			existing = [];
			this._byHash.set(hash, existing);
		}
		for (const existingUniforms of existing) {
			if (existingUniforms.equalTo(flat)) {
				return existingUniforms;
			}
		}
		const result = new HashedUniforms(hash, flat);
		existing.push(result);
		return result;
	}
}


// export function calcUniformsHash(uniforms: DynamicUniforms): number {
// 	for (const [key, val] of uniforms) {

// 	}
// }


// export class UniqueDynamicUniforms {


	
// }
