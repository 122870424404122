
import type { UiBindings, DropdownViewDescription, UiViewSource, MenuPath, PUI_PropertyNode } from 'ui-bindings';
import { ActionDescription, ActionVisibilityFlags, SettingDescription } from 'ui-bindings';
import { DefaultMap, LazyDerived, StringUtils, type LazyVersioned } from "engine-utils-ts";
import type { IconName } from '../libui/icon';

export class MenuItemBase {

    constructor(
        public readonly name: string,
        public readonly priority: number = 0,
    ) {
    }

}

export class MenuDropdownItem extends MenuItemBase {

    constructor(
        public readonly name: string,
        public readonly uiNodeDescription:  UiViewSource<any>,
        public readonly dynamicName: LazyVersioned<string>,
    ) {
        super(name);
    }

    static fromUiBinding(descr: DropdownViewDescription): MenuDropdownItem {
        return new MenuDropdownItem(
            descr.header.poll(),
            descr.viewSource,
            descr.header,
        );
    }
}

export class MenuItemGroup extends MenuItemBase {
	readonly iconName: IconName;
    constructor(
        public readonly name: string,
        public readonly children: MenuItemBase[],
    ) {
        super(name);
        this.children = children;
		this.iconName = `Toolbar${name}` as IconName;
    }
}

export class MenuItemAction extends MenuItemBase {

    constructor(
        public readonly name: string,
        public readonly hotkeysString: string,
        public readonly action: () => void,
        public readonly canUseNow: LazyVersioned<boolean>,
        public readonly activationHint: string | undefined,
		public readonly priority: number,
        public readonly divider: boolean,
        public readonly iconName?: IconName,
    ) {
        super(name);
    }

	static fromUiBinding(action: ActionDescription<any>, setIconName: boolean = false): MenuItemAction {
		const actionName = action.name[action.name.length - 1];
		const iconName = setIconName
			? StringUtils.capitalizeFirstCharInEachWord(action.name.join("")).replaceAll(" ", "") as IconName
			: undefined;

		return new MenuItemAction(
			actionName,
			action.hotkeysString(),
			() => {
				if (action.action) {
					action.action(undefined);
				} else {
					console.warn(`${action.name.join(':')} action is undefined`, action);
				}
			},
			action.canUseNow,
			action.activationHint,
			action.priority,
			action.divider,
			iconName
		)
	}
}


export class MenuItemSetting extends MenuItemBase {
    constructor(
        public readonly name: string,
		public readonly property: PUI_PropertyNode<any>,
		public readonly hotkeysString: string,
		public readonly divider: boolean,
		priority: number,
    ) {
        super(name, priority);
    }

	static fromUiBinding(desc: SettingDescription): MenuItemSetting {
		const actionName = desc.name[desc.name.length - 1];

		return new MenuItemSetting(
			actionName,
			desc.property,
			desc.hotkeysString(),
			desc.divider,
			desc.priority,
		)
	}
}

const DefaultToolbarGroups = ['Add', 'Edit', 'Select', 'View', 'Camera'];

interface ToolbarItems {
	menu: MenuItemGroup[];
	buttons: MenuItemAction[];
}

interface ContextualActions {
	menuActions: [string[], MenuItemBase][];
	buttonActions: MenuItemAction[];
}

export function menuActionsFromUiBindings(u: UiBindings): LazyVersioned<ToolbarItems> {
	const buttonActions: MenuItemAction[] = [];
	const staticActions: [string[], MenuItemBase][] = [];

	// add static actions
    for (const action of u.actions.values()) {
        if (action.visibility & ActionVisibilityFlags.Menu) {
			const menuItem = MenuItemAction.fromUiBinding(action);
			staticActions.push([action.name.slice(0, action.name.length - 1), menuItem])
		}
		if (action.visibility & ActionVisibilityFlags.Toolbar) {
			buttonActions.push(MenuItemAction.fromUiBinding(action, true));
		}
    }

	// add static settings
    for (const action of u.settings.values()) {
		const menuItem = MenuItemSetting.fromUiBinding(action);
		staticActions.push([action.name.slice(0, action.name.length - 1), menuItem])
    }

	// contextual actions getter
	const contextualActions = LazyDerived.fromArr<ContextualActions, Array<ActionDescription<any> | SettingDescription>>(
		'contextual-actions-merge',
		null,
		Array.from(u.contextualActions.values()),
		(actions) => {
			const menuActions: [string[], MenuItemBase][] = [];
			const buttonActions: MenuItemAction[] = [];
			for (const action of actions.flat()) {
				if (action instanceof SettingDescription) {
					menuActions.push([action.name.slice(0, action.name.length - 1), MenuItemSetting.fromUiBinding(action)])
				} else if (action.visibility & ActionVisibilityFlags.Menu) {
					const menuItem = MenuItemAction.fromUiBinding(action);
					menuActions.push([action.name.slice(0, action.name.length - 1), menuItem])
				} else if (action.visibility & ActionVisibilityFlags.Toolbar) {
					buttonActions.push(MenuItemAction.fromUiBinding(action, true));
				}
			}
			return {
				menuActions,
				buttonActions
			};
		}
	).withoutEqCheck();

	const fullMenuItems = LazyDerived.new1<ToolbarItems, ContextualActions>(
		'dynamic-menu-items',
		null,
		[contextualActions],
		([contextualActions]) => {
			const allActions = staticActions
				.concat(contextualActions.menuActions)
				.sort((a, b) => a[1].priority - b[1].priority);

			const rootGroups = new DefaultMap<string, MenuItemGroup>((name) => new MenuItemGroup(name, []));

			for (const group of DefaultToolbarGroups) {
				rootGroups.getOrCreate(group);
			}

			for (const [path, item] of allActions) {
				if (path.length < 1) {
					console.error('menu item wrong path', path, item);
					continue;
				}
				const menuName = path[0];
				if (rootGroups.has(menuName)) {
					const rootGroup = rootGroups.getOrCreate(menuName);
					let group = rootGroup;

					for (let i = 1; i < path.length; ++i) {
						const subGroupName = path[i];
						const g = rootGroup.children.find(
							ch => ch instanceof MenuItemGroup && ch.name === subGroupName
						) as MenuItemGroup | undefined;
						if (g) {
							group = g;
						} else {
							const g = new MenuItemGroup(subGroupName, []);
							group.children.push(g);
							group = g;
						}
					}
					group.children.push(item);
				}
			}

			return {
				menu: Array.from(rootGroups.values()),
				buttons: [...contextualActions.buttonActions, ...buttonActions]
					.sort((a, b) => a.priority - b.priority)
			};
		}
	).withoutEqCheck();

    return fullMenuItems
}

export function selectionActionsFromUiBindings(u: UiBindings): LazyVersioned<MenuItemAction[]> {
	return LazyDerived.fromArr<MenuItemAction[], Array<ActionDescription<any> | SettingDescription>>(
		'selection-actions',
		null,
		Array.from(u.contextualActions.values()),
		(actions) => {
			const result: MenuItemAction[] = [];
			for (const action of actions.flat()) {
				if (action instanceof ActionDescription && (action.visibility & ActionVisibilityFlags.SelectionMenu)) {
					const menuItem = MenuItemAction.fromUiBinding(action);
					result.push(menuItem)
				}
			}
			return result;
		}
	).withoutEqCheck();
}

export function dropdownItemsFromUiBindings(views: Iterable<[MenuPath, DropdownViewDescription]>): MenuDropdownItem[] {
    const items = new Map<string, MenuDropdownItem>();
    for (const [path, descr] of views) {
        const mergedPath = path.join();
        const menuItem = MenuDropdownItem.fromUiBinding(descr);
        items.set(mergedPath, menuItem);
    }
    const allGroups = [...items.values()];
    return allGroups;
}



