import type { KeyMetrics, ProjectMetrics, PropertyGroupArgsType, UnitsMapper } from 'bim-ts';
import type { KreoEngine } from 'engine-ts';
import { LazyDerived, Success, type LazyVersioned, InProgress, ScopedLogger, type ResultAsync } from 'engine-utils-ts';
import type { ProjectVersionMetrics, VerDataSyncer } from 'verdata-ts';
import type { AuthHubStore } from '../auth';
import { NotificationDescription, NotificationType, UiBindings } from 'ui-bindings';
import { notificationSource } from '../Notifications';

const MetricNames = {
    dcPower: 'totalDcPower',
    acPower: 'totalAcPower',
    footprint: 'footprintArea',
    buildableArea: 'buildableArea',
    modules: 'moduleArea',
    gcr: 'gcr'
} satisfies Record<string, keyof PropertyGroupArgsType<KeyMetrics>>;

interface ProjectDescription {
    createdBy?: string;
    metrics: ProjectVersionMetrics;
}

export class ProjectVersionParams {
    versionMetricsLazy: LazyVersioned<ProjectDescription>;
    uiBindings: UiBindings;
    

    constructor(
        private authHub: AuthHubStore,
        private unitsMapper: UnitsMapper,
        private engine: KreoEngine,
        private layoutMetrics: ProjectMetrics,
        private verDataSyncer: VerDataSyncer,
    ) {
        this.versionMetricsLazy = LazyDerived.new1<
            {metrics: ProjectVersionMetrics, createdBy?: string},
            ResultAsync<KeyMetrics>
        >(
            'projectMetricsLazy',
            [this.unitsMapper],
            [this.layoutMetrics.keyMetrics],
            ([keyMetrics]) => {
                const metrics = keyMetrics instanceof Success 
                    ? keyMetrics.value 
                    : keyMetrics instanceof InProgress ? keyMetrics.lastSuccessful : undefined;

                return {
                    metrics: this.getMetrics(metrics),
                    createdBy: this.authHub.authData?.email
                };
            }
        );
        this.uiBindings = new UiBindings(new ScopedLogger('project-version-params'));
    }

    takeScreenshot() {
        return this.engine.takeScreenshotRawPng(184, 184) as Promise<Uint8Array>;
    }

    getMetrics(data?: KeyMetrics) {
        const metrics: ProjectVersionMetrics = {};
        if (data) {
            Object.entries(MetricNames)
                .forEach(([name, key]) => {
                    const prop = data[key];
                    metrics[name] = prop
                        ? this.unitsMapper.mapToConfigured({ value: prop.value, unit: prop.unit })
                        : { value: 0, unit: ''};
                });
        }
        return metrics;
    }

    saveVersionWithParams(description?: string) {
        const task = this.engine!.tasksRunner.newLongTask({
            defaultGenerator: this.getProjectVersionParams(description),
        });
        this.uiBindings.addNotification(
            NotificationDescription.newWithTask({
                source: notificationSource,
                key: 'keyMetricsCalculation',
                type: NotificationType.Info,
                taskDescription: { task: task },
                removeAfterMs: 3000,
                addToNotificationsLog: true
            })
        );
        task.asPromise().then((data) => {
            this.verDataSyncer.saveNewVersion(data);
        });
    }

    *getProjectVersionParams(description?: string) {
        const metrics = yield* this.layoutMetrics.keyMetrics.waitTillCompletion();
        if (metrics instanceof Success) {
            return {
                metrics: this.getMetrics(metrics.value),
                createdBy: this.authHub.authData?.email,
                textDescription: description,
                image: this.takeScreenshot(),
            };
        }
        return {};
    }
}


