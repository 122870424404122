// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { SourceT } from './source.js';
import { Source } from './source.js';


export class Asset implements flatbuffers.IUnpackableObject<AssetT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Asset {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsAsset(bb:flatbuffers.ByteBuffer, obj?:Asset):Asset {
  return (obj || new Asset()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsAsset(bb:flatbuffers.ByteBuffer, obj?:Asset):Asset {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Asset()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

name():string|null
name(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
name(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

source(obj?:Source):Source|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new Source()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

bimasset(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index) : 0;
}

bimassetLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

bimassetArray():Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? new Uint8Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

createdAt():bigint {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readInt64(this.bb_pos + offset) : BigInt('0');
}

updatedAt():bigint {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.readInt64(this.bb_pos + offset) : BigInt('0');
}

static startAsset(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addName(builder:flatbuffers.Builder, nameOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, nameOffset, 0);
}

static addSource(builder:flatbuffers.Builder, sourceOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, sourceOffset, 0);
}

static addBimasset(builder:flatbuffers.Builder, bimassetOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, bimassetOffset, 0);
}

static createBimassetVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset {
  builder.startVector(1, data.length, 1);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt8(data[i]!);
  }
  return builder.endVector();
}

static startBimassetVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(1, numElems, 1);
}

static addCreatedAt(builder:flatbuffers.Builder, createdAt:bigint) {
  builder.addFieldInt64(3, createdAt, BigInt('0'));
}

static addUpdatedAt(builder:flatbuffers.Builder, updatedAt:bigint) {
  builder.addFieldInt64(4, updatedAt, BigInt('0'));
}

static endAsset(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): AssetT {
  return new AssetT(
    this.name(),
    (this.source() !== null ? this.source()!.unpack() : null),
    this.bb!.createScalarList<number>(this.bimasset.bind(this), this.bimassetLength()),
    this.createdAt(),
    this.updatedAt()
  );
}


unpackTo(_o: AssetT): void {
  _o.name = this.name();
  _o.source = (this.source() !== null ? this.source()!.unpack() : null);
  _o.bimasset = this.bb!.createScalarList<number>(this.bimasset.bind(this), this.bimassetLength());
  _o.createdAt = this.createdAt();
  _o.updatedAt = this.updatedAt();
}
}

export class AssetT implements flatbuffers.IGeneratedObject {
constructor(
  public name: string|Uint8Array|null = null,
  public source: SourceT|null = null,
  public bimasset: (number)[] = [],
  public createdAt: bigint = BigInt('0'),
  public updatedAt: bigint = BigInt('0')
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const name = (this.name !== null ? builder.createString(this.name!) : 0);
  const source = (this.source !== null ? this.source!.pack(builder) : 0);
  const bimasset = Asset.createBimassetVector(builder, this.bimasset);

  Asset.startAsset(builder);
  Asset.addName(builder, name);
  Asset.addSource(builder, source);
  Asset.addBimasset(builder, bimasset);
  Asset.addCreatedAt(builder, this.createdAt);
  Asset.addUpdatedAt(builder, this.updatedAt);

  return Asset.endAsset(builder);
}
}
