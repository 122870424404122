import { StateType } from '../bimConfigs/ConfigsArchetypes';
import { NumberProperty, BooleanProperty, SelectorProperty } from '../properties/PrimitiveProps';
import { PropsGroupsRegistry } from '../properties/PropsGroupsRegistry';
import { EnergyStageOverrideConfig, EnergyStageCalculateConfig, type EnergyStageCalcConfig } from './EnergyCalcConfig';
import { PropsGroupBase } from '../properties/Props';
import type { EnergyStageName } from './EnergyPipelineProperty';
import type { Bim } from '../Bim';
import { PropsFieldFlags, PropsFieldOneOf } from '../properties/PropsGroupComplexDefaults';


export abstract class EnergyStageSettings<
    CalcS extends PropsGroupBase | null,
    OverrS extends EnergyStageBasicOverrideSettings = EnergyStageBasicOverrideSettings,
> extends PropsGroupBase {
    enabled: BooleanProperty;
    calc_path_selector: SelectorProperty;
    calculation_settings: CalcS;
    override_settings: OverrS;

    constructor(
        enabled: BooleanProperty,
        calcState: SelectorProperty,
        calculationSettings: CalcS,
        overrideSettings: OverrS
    ) {
        super();
        this.enabled = enabled;
        this.calc_path_selector = calcState;
        this.calculation_settings = calculationSettings;
        this.override_settings = overrideSettings;
    }

    calculationConfig(): EnergyStageCalcConfig {
        if (!this.enabled.value) {
            return null;
        }
        if (this.calc_path_selector.value === 'calculate') {
            return new EnergyStageCalculateConfig(this.calculation_settings);
        }
        let change = this.override_settings.output_correction.value;
        if (this.override_settings.output_correction.unit === '%') {
            change /= 100;
        }
        if (!Number.isFinite(change)) {
            change = 0;
        }
        return new EnergyStageOverrideConfig(1 + change);
    }
}

const EnabledProperty = BooleanProperty.new({value: true});
const EnabledPropertyReadonly = BooleanProperty.new({value: true, isReadonly: true});
const DisabledProperty = BooleanProperty.new({value: false});
const CalculationSelectorFull = SelectorProperty.new({options: ['calculate', 'set'], value: 'calculate'});
const CalculationSelectorSetOnly = SelectorProperty.new({options: ['set'], value: 'set'});

export class EnergyStageBasicOverrideSettings extends PropsGroupBase {
    output_correction: NumberProperty;
    constructor(args: Partial<EnergyStageBasicOverrideSettings>) {
        super();
        this.output_correction = args.output_correction ?? NumberProperty.new({value: 0, unit: '%', range: [-100, 1000], step: 0.01});
    }

    static withPercentage(percentage: number) {
        return new EnergyStageBasicOverrideSettings({output_correction: NumberProperty.new({
            value: percentage, unit: '%', range: [-100, 1000], step: 0.01
        })});
    }
}
PropsGroupsRegistry.register({class: EnergyStageBasicOverrideSettings, complexDefaults: {}});


export class EnergyStageNoParamsCalculateOrBasicOverrideSettings extends EnergyStageSettings<null> {
    constructor(args: Partial<EnergyStageNoParamsCalculateOrBasicOverrideSettings>) {
        super(
            args.enabled ?? EnabledProperty,
            args.calc_path_selector ?? CalculationSelectorFull,
            null,
            args.override_settings ?? new EnergyStageBasicOverrideSettings({}),
        );
    }

    static withOverridePerc(percentage: number) {
        const overrideSettings = EnergyStageBasicOverrideSettings.withPercentage(percentage);
        return new EnergyStageNoParamsCalculateOrBasicOverrideSettings({override_settings: overrideSettings});
    }

    static withPercOnly(percentage: number, canDisable: boolean = true) {
        const overrideSettings = EnergyStageBasicOverrideSettings.withPercentage(percentage);
        return new EnergyStageNoParamsCalculateOrBasicOverrideSettings({
            enabled: canDisable ? EnabledProperty: EnabledPropertyReadonly,
            calc_path_selector: CalculationSelectorSetOnly,
            override_settings: overrideSettings,
        });
    }
}
PropsGroupsRegistry.register({class: EnergyStageNoParamsCalculateOrBasicOverrideSettings, complexDefaults: {
    calc_path_selector: new PropsFieldOneOf(PropsFieldFlags.None, SelectorProperty),
    calculation_settings: new PropsFieldOneOf(PropsFieldFlags.None, null),
}});


export class ShadingStageCalculationsSettings extends PropsGroupBase {
    include_terrain: BooleanProperty;
    constructor(args: Partial<ShadingStageCalculationsSettings>) {
        super();
        this.include_terrain = args.include_terrain ?? BooleanProperty.new({value: false});
    }
}
PropsGroupsRegistry.register({class: ShadingStageCalculationsSettings, complexDefaults: {}});

export class ShadingStageSettings extends EnergyStageSettings<ShadingStageCalculationsSettings> {
    constructor(args: Partial<ShadingStageSettings>) {
        super(
            args.enabled ?? DisabledProperty,
            args.calc_path_selector ?? CalculationSelectorFull,
            args.calculation_settings ?? new ShadingStageCalculationsSettings({}),
            args.override_settings ?? EnergyStageBasicOverrideSettings.withPercentage(-10),
        );
    }
}
PropsGroupsRegistry.register({class: ShadingStageSettings, complexDefaults: {
    calc_path_selector: new PropsFieldOneOf(PropsFieldFlags.None, SelectorProperty),
}});


export class BifacialityStageLossSettings extends PropsGroupBase {
    ground_albedo: NumberProperty;

    constructor(args: Partial<BifacialityStageLossSettings>) {
        super();
        this.ground_albedo = args.ground_albedo ?? NumberProperty.new({value: 0.2, range: [0, 1], step: 0.01});
    }
}
PropsGroupsRegistry.register({class: BifacialityStageLossSettings, complexDefaults: {}});
export class BifacialityStageSettings extends EnergyStageSettings<BifacialityStageLossSettings> {
    constructor(args: Partial<BifacialityStageSettings>) {
        super(
            args.enabled ?? DisabledProperty,
            args.calc_path_selector ?? CalculationSelectorFull,
            args.calculation_settings ?? new BifacialityStageLossSettings({}),
            args.override_settings ?? new EnergyStageBasicOverrideSettings({}),
        );
    }
}
PropsGroupsRegistry.register({class: BifacialityStageSettings, complexDefaults: {
    calc_path_selector: new PropsFieldOneOf(PropsFieldFlags.None, SelectorProperty),
}});


export class TemperatureLossCalculationSettings extends PropsGroupBase {
    module_absorption: NumberProperty;
    heat_transfer: NumberProperty;
    convective_heat_transfer: NumberProperty;

    constructor(args: Partial<TemperatureLossCalculationSettings>) {
        super();
        this.module_absorption = args.module_absorption ?? NumberProperty.new({value: 0.9, range: [0, 1], step: 0.01});
        this.heat_transfer = args.heat_transfer ?? NumberProperty.new({value: 29, range: [0, 100], step: 0.1});
        this.convective_heat_transfer = args.convective_heat_transfer ?? NumberProperty.new({value: 0, range: [0, 100], step: 0.1});
    }
}
PropsGroupsRegistry.register({class: TemperatureLossCalculationSettings, complexDefaults: {}});
export class TemperatureStageCalculationSettings extends EnergyStageSettings<TemperatureLossCalculationSettings> {
    constructor(args: Partial<TemperatureStageCalculationSettings>) {
        super(
            args.enabled ?? EnabledProperty,
            args.calc_path_selector ?? CalculationSelectorFull,
            args.calculation_settings ?? new TemperatureLossCalculationSettings({}),
            args.override_settings ?? new EnergyStageBasicOverrideSettings({}),
        );
    }
}
PropsGroupsRegistry.register({class: TemperatureStageCalculationSettings, complexDefaults: {
    calc_path_selector: new PropsFieldOneOf(PropsFieldFlags.None, SelectorProperty),
}});


type EnergyStagesConfigs = Record<EnergyStageName, EnergyStageSettings<any, any> | null>;

export class EnergyStageSettingsConfig extends PropsGroupBase implements EnergyStagesConfigs {
    ['horizontal_irradiance']: null = null;
    ['tilt']: EnergyStageNoParamsCalculateOrBasicOverrideSettings;
    ['shading']: ShadingStageSettings;
    ['bifaciality']: BifacialityStageSettings;
    ['soiling']: EnergyStageNoParamsCalculateOrBasicOverrideSettings;
    ['incidence_angle']: EnergyStageNoParamsCalculateOrBasicOverrideSettings;
    ['pv_conversion']: null = null;
    ['temperature']: TemperatureStageCalculationSettings;
    ['spectral_correction']: EnergyStageNoParamsCalculateOrBasicOverrideSettings;
    ['module_quality']: EnergyStageNoParamsCalculateOrBasicOverrideSettings;
    ['module_mismatch']: EnergyStageNoParamsCalculateOrBasicOverrideSettings;
    ['strings_mismatch']: EnergyStageNoParamsCalculateOrBasicOverrideSettings;
    ['dc_wiring']: EnergyStageNoParamsCalculateOrBasicOverrideSettings;
    ['inverter_voltage_clipping']: EnergyStageNoParamsCalculateOrBasicOverrideSettings;
    ['inverter_efficiency']: EnergyStageNoParamsCalculateOrBasicOverrideSettings;
    ['inverter_power_clipping']: EnergyStageNoParamsCalculateOrBasicOverrideSettings;

    constructor(args: Partial<EnergyStageSettingsConfig>) {
        super();
        this.tilt = args.tilt ?? EnergyStageNoParamsCalculateOrBasicOverrideSettings.withOverridePerc(+10);
        this.shading = args.shading ?? new ShadingStageSettings({});
        this.bifaciality = args.bifaciality ?? new BifacialityStageSettings({});
        this.soiling = args.soiling ?? EnergyStageNoParamsCalculateOrBasicOverrideSettings.withPercOnly(-1);

        this.incidence_angle = args.incidence_angle ?? EnergyStageNoParamsCalculateOrBasicOverrideSettings.withOverridePerc(-1);
        this.temperature = args.temperature ?? new TemperatureStageCalculationSettings({});
        this.spectral_correction = args.spectral_correction ?? EnergyStageNoParamsCalculateOrBasicOverrideSettings.withOverridePerc(-1);
        this.module_quality = args.module_quality ?? EnergyStageNoParamsCalculateOrBasicOverrideSettings.withPercOnly(-1);
        this.module_mismatch = args.module_mismatch ?? EnergyStageNoParamsCalculateOrBasicOverrideSettings.withPercOnly(-1);
        this.strings_mismatch = args.strings_mismatch ?? EnergyStageNoParamsCalculateOrBasicOverrideSettings.withPercOnly(-2, false);
        this.dc_wiring = args.dc_wiring ?? EnergyStageNoParamsCalculateOrBasicOverrideSettings.withPercOnly(-2);
        this.inverter_voltage_clipping = args.inverter_voltage_clipping ?? EnergyStageNoParamsCalculateOrBasicOverrideSettings.withOverridePerc(-2);
        this.inverter_efficiency = args.inverter_efficiency ?? EnergyStageNoParamsCalculateOrBasicOverrideSettings.withOverridePerc(-1);
        this.inverter_power_clipping = args.inverter_power_clipping ?? EnergyStageNoParamsCalculateOrBasicOverrideSettings.withOverridePerc(0);
    }

    static register(bim: Bim) {
        bim.configs.archetypes.registerArchetype({
            type_identifier: EnergyStageSettingsConfig.name,
            stateType: StateType.Singleton,
            propsClass: EnergyStageSettingsConfig,
            properties: () => ({}),
        });
        const configAsLazyObj = bim.configs.getLazySingletonProps({
            type_identifier: EnergyStageSettingsConfig.name,
            propsClass: EnergyStageSettingsConfig
        });
        bim.runtimeGlobals.registerByIdent(EnergyStageSettingsConfig.name, configAsLazyObj);
    }
}
PropsGroupsRegistry.register({
    class: EnergyStageSettingsConfig,
    complexDefaults: {
        horizontal_irradiance: new PropsFieldOneOf(PropsFieldFlags.None, null),
        pv_conversion: new PropsFieldOneOf(PropsFieldFlags.None, null),
    },
})
