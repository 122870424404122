import {
    Bim,
    CostReportRelatedMetrics,
    EnergyYieldPropsGroup,
    EnergyYieldSiteProps,
    LCOE,
} from "bim-ts";
import {
    LazyDerived,
    LazyDerivedAsync,
    ResultAsync,
    Success,
} from "engine-utils-ts";
import { getContext, setContext } from "svelte";

export function initLcoeContext(bim: Bim) {
    const costMetrics = getContext<LazyDerivedAsync<CostReportRelatedMetrics>>(
        "capital-cost-related-metrics",
    );
    const lcoeLayoutInputLazy = LCOE.createLcoeInput(bim, costMetrics);

    // lcoe related layout input
    const lcoeLazy = LCOE.createLcoeCostHierarchyLazy(bim, lcoeLayoutInputLazy);

    setContext<LazyDerivedAsync<LCOE.CreateLcoeHierarchyResult>>(
        "lcoe-lazy",
        lcoeLazy,
    );
    return getLcoeContext();
}

export function getLcoeContext() {
    const lcoe =
        getContext<LazyDerivedAsync<LCOE.CreateLcoeHierarchyResult>>(
            "lcoe-lazy",
        );
    return {
        lcoe,
    };
}
