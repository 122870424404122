import type { Allocator } from "./AllocationSynchronizer";
import type { SubmeshesInstancingRawBlocks } from "src/scene/SubmeshesInstancingRawBlocks";
import { LegacyLogger } from "engine-utils-ts";
import { IndexDataSize, SubmeshRawDataSize } from "src/scene/SubmeshesInstancingRawBlocks";

export class SubmeshesInstancingBinaryAllocator implements Allocator<number>{
    //each 2 elements contains start and end index representing an index range
    //at witch all instances per submesh are located in instancing buffer
    indexBuffer: Int32Array;

    //contains N instance matricies and color tint values per submesh for each
    //exsisting submesh in a scene
    instancingBuffer: Float32Array; 

    dataGateway: SubmeshesInstancingRawBlocks;
    currentSubmeshesCapacity: number;

    instancingBufferLength: number = 0;
    indexBufferLength: number = 0;

    //indexes of submeshes to be deleted, which are sorted and merged into sequental groups
    //to make less modification operations to instancing buffer data but in a bigger chunks
    sequentualGroupsToDelete: number[] = [];
    indexesToDeleteCount : number = 0;

    constructor(initialCapacity: number, dataGateway: SubmeshesInstancingRawBlocks){
        this.indexBuffer = new Int32Array(initialCapacity * IndexDataSize);
        this.instancingBuffer = new Float32Array(initialCapacity * SubmeshRawDataSize);

        this.dataGateway = dataGateway;
        this.currentSubmeshesCapacity = initialCapacity;

        this.dataGateway.setIndexBuffer(this.indexBuffer);
        this.dataGateway.setInstancingBuffer(this.instancingBuffer);
    }

    allocate(index: number, insancesCount: number): boolean {
        if (index < 0) {
			return false;
		}
		if (index >= this.currentSubmeshesCapacity) {
			return false;
		}

        const indexOffset = index * IndexDataSize;
        const previousStartIndex = this.indexBuffer[indexOffset + 0];
        const previousEndIndex = this.indexBuffer[indexOffset + 1];
        
        //no values were set before means completely new element, update buffer length counter
        if(previousStartIndex === 0 && previousEndIndex === 0) {
            this.indexBufferLength += 2;
        }

        const startInstancingBufferIndex = this.instancingBufferLength;
        const endInstancingBufferIndex = startInstancingBufferIndex + SubmeshRawDataSize * insancesCount - 1;
        this.indexBuffer[indexOffset + 0] = startInstancingBufferIndex;
        this.indexBuffer[indexOffset + 1] = endInstancingBufferIndex;

        const newInstancingBufferLength = endInstancingBufferIndex + 1
        if(this.instancingBuffer.length <= newInstancingBufferLength) {
            this._reallocateInstancingBuffer(newInstancingBufferLength, false);
        }
        this.instancingBufferLength = newInstancingBufferLength;
		return true;
    }

    free(indexes: number[]): void {

        //collect all indexes and merge them into sequentual sorted groups/chunks to process each of them
        //on instancing buffer in one operation and reduce number of instancing buffer modifications
        this._mergeGroupsToDelete(indexes);

        const deletePercentage = this.indexesToDeleteCount / this.instancingBuffer.length;
        if(deletePercentage < 0.1 && this.indexesToDeleteCount < this.instancingBufferLength) {
            return;
        }

        //going in right to left order to delete most right groups first, so we do not need to shift left groups indexes
        for(let i = this.sequentualGroupsToDelete.length - 1; i > 0; i-=2) {
            const startDataIndex = this.sequentualGroupsToDelete[i - 1];
            const endDataIndex = this.sequentualGroupsToDelete[i - 0];
            const freedFloatsCount = endDataIndex - startDataIndex + 1;

            //if deleting not the last element, then shift bytes of instancing buffer to the left
            //to always keep new data insertions at the end of the buffer avoiding fragmentation
            if(endDataIndex < this.instancingBufferLength - 1) {
                this.instancingBuffer.copyWithin(startDataIndex, endDataIndex + 1, this.instancingBufferLength);

                for(let i = 0; i < this.indexBufferLength - 1; i+=2) {
                    if(this.indexBuffer[i] > endDataIndex) {
                        this.indexBuffer[i + 0] -= freedFloatsCount;
                        this.indexBuffer[i + 1] -= freedFloatsCount;
                    }
                }
            }

            this.instancingBufferLength -= freedFloatsCount;
        }
        
        this.sequentualGroupsToDelete.length = 0;
        this.indexesToDeleteCount = 0;
    }

    changeCapacity(newCapacity: number): void {
        //here we only reallocate instancing buffer if capacity is shrinked, otherwise
        //do not handle expanding of instancing buffer here due to lack of information
        //about allocated instances count(expanding is done dynamically within allocate() function)
        if(newCapacity < this.currentSubmeshesCapacity) {
            const freeIndexes = this._getShrinkCapacityIndexes(newCapacity);
            this.free(freeIndexes);
            freeIndexes.length = 0;
            
            //due to free call, _instancingBufferLength actualized with shrinked data size
            this._reallocateInstancingBuffer(this.instancingBufferLength, true);
        }

        this._reallocateIndexBuffer(newCapacity * IndexDataSize);
        this.currentSubmeshesCapacity = newCapacity;
        //ArrayBuffer.resize(N); not available in current version of NodeJs
    }

    _reallocateIndexBuffer(newCapacity: number): boolean {
		try {
            let prevIndexBuffer = this.indexBuffer;
			this.indexBuffer = new Int32Array(newCapacity);
			LegacyLogger.debugAssert(this.indexBuffer.length === newCapacity, 'allocated buffer length check');
			
            this.indexBufferLength = Math.min(this.indexBuffer.length, prevIndexBuffer.length, this.indexBufferLength);
            const indexesToCopy = prevIndexBuffer.subarray(0, this.indexBufferLength);
            this.indexBuffer.set(indexesToCopy);
            this.dataGateway.setIndexBuffer(this.indexBuffer);
			return true;
		} catch (e) {
			LegacyLogger.error('couldnt allocate binary buffer', newCapacity, e);
			return false;
		}
	}

    _reallocateInstancingBuffer(minCapacity: number, shrink: boolean) {
        let newCapacity = 0;

        if(shrink) {
            newCapacity = minCapacity;
            LegacyLogger.debugAssert(minCapacity >= this.instancingBufferLength, 'shrink instancing buffer check');
        } else {
            newCapacity = Math.max(SubmeshRawDataSize, this.instancingBufferLength * 2, minCapacity);
            if (newCapacity <= this.instancingBuffer.length) {
                return;
            }
        }

		try {
            let prevDataBuffer = this.instancingBuffer;
            this.instancingBuffer = new Float32Array(newCapacity);
            LegacyLogger.debugAssert(this.instancingBuffer.length === newCapacity, 'allocated buffer length check');

            const dataToCopy = prevDataBuffer.subarray(0, this.instancingBufferLength)
            this.instancingBuffer.set(dataToCopy);
            this.dataGateway.setInstancingBuffer(this.instancingBuffer);
			return true;
		} catch (e) {
			LegacyLogger.error('couldnt allocate binary buffer', newCapacity, e);
			return false;
		}
    }

    _mergeGroupsToDelete(indexes: number[]) {
        for(const index of indexes) {
            if (index < 0 || index >= this.currentSubmeshesCapacity) {
                LegacyLogger.error('trying to free memory out of bounds', index, this.currentSubmeshesCapacity);
                continue;
            }

            const indexOffset = IndexDataSize * index;
            const startDataIndex = this.indexBuffer[indexOffset + 0];
            const endDataIndex = this.indexBuffer[indexOffset + 1];
            this.sequentualGroupsToDelete.push(startDataIndex, endDataIndex);
            this.indexesToDeleteCount += (endDataIndex - startDataIndex + 1);

            //invalidate indexes to ensure that they are not used after deletion
            this.indexBuffer[indexOffset + 0] = -1;
            this.indexBuffer[indexOffset + 1] = -1;
        }

        //sort found groups for potential merge and further deletion of groups in right to left order
        this.sequentualGroupsToDelete.sort((a, b) => (a - b)); 
        let currentGroupEndIndex = this.sequentualGroupsToDelete.length - 3; //end index of prelast group

        //merge potential neigbour groups
        while(currentGroupEndIndex >= 1) {
            const currentGroupEndValue = this.sequentualGroupsToDelete[currentGroupEndIndex];
            const neighbourGroupStartValue = this.sequentualGroupsToDelete[currentGroupEndIndex + 1];

            if(currentGroupEndValue === neighbourGroupStartValue - 1) {
                //expand current group to include right neigbour indexes range
                this.sequentualGroupsToDelete[currentGroupEndIndex] = this.sequentualGroupsToDelete[currentGroupEndIndex + 2];

                //remove two elements representing merged neighbour group
                this.sequentualGroupsToDelete.splice(currentGroupEndIndex + 1, 2);
            }

            currentGroupEndIndex -= 2;
        }
    }

    _getShrinkCapacityIndexes(newCapacity: number): number[] {
        const indexesLength = this.currentSubmeshesCapacity - newCapacity;
        const indexes: number[] = new Array(indexesLength);

        for (let i = 0; i < indexesLength; ++i) {
           indexes[i] = newCapacity + i;
        }

        return indexes;
    }
}