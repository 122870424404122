import { EdgesRenderMode } from "./EngineConsts";

export function checkEdgesRenderMode(rmode: number): EdgesRenderMode | null {
	switch (rmode) {
		case EdgesRenderMode.Auto:
		case EdgesRenderMode.Enabled:
		case EdgesRenderMode.Disabled:
			return rmode;
	}
	return null;
}

// export function EdgesRenderModeFromString(str: string): EdgesRenderMode | null {
// 	switch (str) {
// 		case 'auto': return EdgesRenderMode.Auto;
// 		case 'enabled': return EdgesRenderMode.Enabled;
// 		case 'disabled': return EdgesRenderMode.Disabled;
// 	}
// 	LegacyLogger.error('uknown edges render mode');
// 	return null;
// }

// export function EdgesRenderModeToString(rmode: EdgesRenderMode): string {
// 	switch (rmode) {
// 		case EdgesRenderMode.Auto: return 'auto';
// 		case EdgesRenderMode.Enabled: return 'enabled';
// 		case EdgesRenderMode.Disabled: return 'disabled';
// 	}
// }
