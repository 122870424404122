import type { VersionedValue } from './stateSync/LazyVersioned';
import type { ScopedLogger } from './ScopedLogger';


//TODO: improve or remove this abstraction
export class Registry<T> implements VersionedValue {

    readonly identifier: string;
    readonly logger: ScopedLogger;

    private readonly _registry = new Map<string, [string[], T]>();

    private _version: number = 0;
    private _isDisposed: boolean = false;

    constructor(logger: ScopedLogger, name: string) {
        this.logger = logger.newScope(name);
        this.identifier = name;
    }

    public mergeFrom(prs: Iterable<Registry<T>>) {
        for (const pr of prs) {
            for (const [mergedPath, v] of pr._registry) {
                if (this.has(mergedPath)) {
                    this.logger.warn('identifiers conflict, skipping', mergedPath);
                    continue;
                }
                if (pr._registry.has(mergedPath)) {
                    this._registry.set(mergedPath, v);
                }
            }
        }
    }

    entries() {
        return this._registry.entries();
    }

    values() {
        return this._registry.values();
    }

    version(): number {
        return this._version;
    }

    clearResults() {
        this._registry.clear();
    }

    dispose() {
        if (this._isDisposed) {
            return;
        }
        this._isDisposed = true;
        this._registry.clear();
    }

    has(ident: string) {
        return this._registry.has(ident);
    }

    get(identMerged: string) {
        return this._registry.get(identMerged);
    }

    getByPath(path: string[]): T | undefined {
        const identMerged = path.join('.');
        return this._registry.get(identMerged)?.[1];
    }

    register(path: string[], result: T) {
        const identifier = path.join('.');
        if (this._registry.has(identifier)) {
            const [p, r] = this._registry.get(identifier)!;
            if (r === result) {
                this.logger.warn(`${identifier} result is already registered`);
            } else {
                this.logger.error(`${identifier} result is already registered (current, new):`, r, result);
            }
        } else {
            this._version += 1;
            this._registry.set(identifier, [path, result]);
        }
    }
}

