import { EntitiesBase } from 'bim-ts';
import type {
    ObjectUniqueHashValue, Result, ScopedLogger,
	EventStackFrame} from 'engine-utils-ts';
import {
    DefaultMapObjectKey,
	ObjectUtils,
	IterUtils,
    Success
} from 'engine-utils-ts';
import type { EntityId } from 'verdata-ts';
import type { EngineGeoType } from '../geometries/AllEngineGeometries';
import type { GCedEntitiesCollection } from '../scene/EngineResourcesGC';

export abstract class EntitiesInterned<T, IdT, InternedType extends object, IdI>
    extends EntitiesBase<T, IdT>
	implements GCedEntitiesCollection<EngineGeoType>
{
    _toEngineIdsRemap = new Map<EntityId<any>, EntityId<IdT>>();
    _engineOnlyIdsCounter: number = -1; // negative progression, to allow bim ids in positive range without conflict
    _internedIds: DefaultMapObjectKey<InternedType, number>;
	_newlyInsertedWaitingForAlloc: [EntityId<IdT>, InternedType][] = [];

    constructor(params: {
        identifier: string;
        idsType: IdT | number;
        logger?: ScopedLogger;
        T_Constructor: { new(): T; };
        uniqueReducerFn: (obj: InternedType) => ObjectUniqueHashValue,
        engineIdsStart?: number,
    }) {
        super(params);
        let nextId = params.engineIdsStart ?? this.idsProvider.lowerRangeBound;
        this._internedIds = new DefaultMapObjectKey({
            valuesFactory: (obj) => {
				const id = nextId += 1;
				this._newlyInsertedWaitingForAlloc.push([id as EntityId<IdT>, obj]);
				return  id;
			},
        });
    }

    abstract convertFromInternedType(bimObj: InternedType): Result<T>;

    allocateOrReference(sources: [EntityId<IdI>, InternedType][]) {
        for (let [bimId, obj] of sources) {
			obj = ObjectUtils.deepFreeze(ObjectUtils.deepCloneObj(obj));

            const id = this._internedIds.getOrCreate(obj);
            if (!this._toEngineIdsRemap.has(bimId)) {
                this._toEngineIdsRemap.set(bimId, id as EntityId<IdT>);
            }
        }
		const toAlloc = IterUtils.filterMap(
			this._newlyInsertedWaitingForAlloc,
			([id, obj]) => {
            	const converted = this.convertFromInternedType(obj);
				if (!(converted instanceof Success)) {
					this.logger.batchedError('could not convert entity', converted.errorMsg());
					return undefined;
				}
				return [id, converted.value] as [EntityId<IdT>, T];
			}
		);
		this._newlyInsertedWaitingForAlloc.length = 0;
        return this.allocate(toAlloc);
    }

    allocateOrReferenceSingle(obj: InternedType): EntityId<IdT> | undefined {
        const id = (this._engineOnlyIdsCounter -= 1) as EntityId<IdI>;
        const allocated = this.allocateOrReference([[id, obj]]);
        return this._toEngineIdsRemap.get(id);
    }

	delete(idsToDelete: EntityId<IdT>[], e?: Partial<EventStackFrame> | undefined): [EntityId<IdT>, Readonly<T>][] {
		const res = super.delete(idsToDelete, e);
		// for (const [id, _] of res) {
		// 	if (id > this._engineOnlyIdsCounter)
		// }
		return res;
	}

    getEngineOwnedIds(result: Set<EntityId<EngineGeoType>>): void {
        for (const [id, _] of this.perId) {
            if (id < 0 && id >= this._engineOnlyIdsCounter) {
                result.add(id)
            }
        }
    }
}

