<svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M2.26157 12.874V11.5442H3.74637V6.70803C3.74637 5.71764 4.03393 4.82886 4.60905 4.04168C5.18417 3.2545 5.94999 2.75574 6.90652 2.5454V2.11308C6.90652 1.81043 7.01375 1.55928 7.22822 1.35963C7.44269 1.16 7.69995 1.06018 8 1.06018C8.30004 1.06018 8.5573 1.16 8.77178 1.35963C8.98625 1.55928 9.09348 1.81043 9.09348 2.11308V2.5454C10.0563 2.75574 10.828 3.25293 11.4087 4.03696C11.9894 4.821 12.2797 5.71135 12.2797 6.70803V11.5442H13.7478V12.874H2.26157ZM8.00472 14.9022C7.62749 14.9022 7.29762 14.7643 7.01508 14.4884C6.73256 14.2126 6.5913 13.8809 6.5913 13.4935H9.41812C9.41812 13.8853 9.27898 14.2181 9.00072 14.4917C8.72246 14.7654 8.39046 14.9022 8.00472 14.9022ZM5.06667 11.5442H10.95V6.70803C10.95 5.88581 10.6667 5.18581 10.1 4.60803C9.53333 4.03025 8.83889 3.74136 8.01667 3.74136C7.19444 3.74136 6.49722 4.03025 5.925 4.60803C5.35278 5.18581 5.06667 5.88581 5.06667 6.70803V11.5442Z"
        fill="#101314"
        fill-opacity="0.69"
    />
</svg>
