import type { NotificationActionDescription, NotificationDescription, NotificationTaskDescription, NotificationType } from 'ui-bindings';
import { BindedStore } from '../utils';

const maxTotalCount = 500;
const maxGroupCount = 50;

export class NotificationsLogItem {
    public groupId: string | number;
    public groupName: string;
    public isRootInGroup: boolean;
    public header: string;
    public message?: string;
    public type: NotificationType;
    public uniqueIdent: string;
    public logTime: number;
    public actionDescription?: NotificationActionDescription<any>;
    public taskDescription?: NotificationTaskDescription;

    constructor(notification: NotificationDescription) {
        const { header, message, type, uniqueIdent, actionDescription, taskDescription, group } = notification;
        this.groupName = group?.groupName || '';
        this.groupId = group?.processId || uniqueIdent;
        this.isRootInGroup = group?.isRootInGroup || false;
        this.header = header;
        this.message = message;
        this.type = type;
        this.uniqueIdent = uniqueIdent;
        this.logTime = Date.now()
        this.actionDescription = actionDescription;
        this.taskDescription = taskDescription;
    }
}

export interface NotificationPanelItem {
    groupId: string | number;
    groupName: string;
    notifications: Array<NotificationsLogItem>;
}

interface NotificationsLogType {
    items: Array<NotificationPanelItem>;
    totalCount: number;
}

export class NotificationsLog extends BindedStore<NotificationsLogType> {
    constructor() {
        super({items: [], totalCount: 0});
    }

    addNotification(notification: NotificationDescription) {
        this.update(store => {
            let updatedItems: NotificationPanelItem[] = [];
            let totalCount = store.totalCount;
            const newNotification = new NotificationsLogItem(notification);
            const group = newNotification.groupId && store.items.find(item => item.groupId === newNotification.groupId);

            if(group) {
                updatedItems= store.items.map(item => {
                    if(item.groupId === group.groupId) {
                        const updatedNotifications = [newNotification, ...item.notifications]
                        if(updatedNotifications.length > maxGroupCount) {
                            updatedNotifications.pop()
                            totalCount--;
                        }
                     return {
                         ...item,
                         notifications: updatedNotifications
                        }
                    }
                    return item;

                });
            } else {
                updatedItems= [
                    {
                        groupId: newNotification.groupId,
                        groupName: newNotification.groupName,
                        notifications: [newNotification]
                    },
                    ...store.items
                ];
            }
            totalCount++;
            if(totalCount > maxTotalCount) {
                const removedGroup = updatedItems.pop()
                if (removedGroup) {
                totalCount= totalCount - removedGroup.notifications.length
                }
            }

            return {
                items: updatedItems,
                totalCount
            }
        });
    }

    updateNotification(notification: NotificationsLogItem) {
        this.update(store => {
            const updatedItems = store.items.map(item => ({
                ...item,
                notifications: item.notifications.map(n => n.uniqueIdent === notification.uniqueIdent ? notification : n)
            }))

            return {
                ...store,
                items: updatedItems
            }
        });
    }
}
