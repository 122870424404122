import type { SubmeshesInstancingRawBlocks } from 'src/scene/SubmeshesInstancingRawBlocks';
import type { TypedArray } from '../3rdParty/three';
import type { AllocationSynchronizer, ArgumentGetter } from './AllocationSynchronizer';
import { BinaryAllocator } from './BinaryAllocator';
import type { ManagedDisposeProcedure } from './ManagedAllocator';
import { ManagedAllocator } from './ManagedAllocator';
import type { RawDataGateway } from './RawDataGateway';
import { SubmeshesInstancingBinaryAllocator } from './SubmeshesInstancingBinaryAllocator';

export function createAndBindManagedAllocator<TStruct, TAllocArgs>(
	allocSyncronizer: AllocationSynchronizer<any, TAllocArgs>,
	argumentGetter: ArgumentGetter<TAllocArgs, TStruct>,
	dispose?: ManagedDisposeProcedure<TStruct>
): TStruct[] {
	const managedAlloc = new ManagedAllocator<TStruct>({
		dispose: dispose
	});
	allocSyncronizer._bindAllocator(managedAlloc, argumentGetter);
	managedAlloc.changeCapacity(allocSyncronizer._capacity);
	return managedAlloc.buffer;
}

export function createAndBindBinaryAllocator<TStruct, TArray extends TypedArray, TGate extends RawDataGateway<TStruct, TArray>, TAllocArgs>(
	allocSyncronizer: AllocationSynchronizer<any, TAllocArgs>,
	gateway: TGate,
	argumentGetter: ArgumentGetter<TAllocArgs, TStruct>,
	arrayAllocator: (size: number) => TArray,
	onFree?: (self: TGate, index: number) => void
) {
	const binAlloc = new BinaryAllocator({
		dataGateway: gateway,
		initialCapacityInItems: allocSyncronizer._capacity,
		bufferAllocator: arrayAllocator,
		onFree: (onFree as any) || null
	});
	allocSyncronizer._bindAllocator(binAlloc, argumentGetter);
}

export function createAndBindSubmeshesInstancingAllocator<TAllocArgs>(
	allocSyncronizer: AllocationSynchronizer<any, TAllocArgs>,
	gateway: SubmeshesInstancingRawBlocks,
	argumentGetter: ArgumentGetter<TAllocArgs, number>
) {
	const alloc = new SubmeshesInstancingBinaryAllocator(allocSyncronizer._capacity, gateway);
	allocSyncronizer._bindAllocator(alloc, argumentGetter);
}

export function growByteArray(arr: Uint8Array): Uint8Array {
	const newSize = Math.max(arr.length * 2, 32);
	const newArr = new Uint8Array(newSize);
	newArr.set(arr);
	return newArr;
}