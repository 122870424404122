import { NotificationDescription, NotificationType} from "ui-bindings";
import { WGSCoord } from "./WGSCoord";
import { Vector3 } from "math-ts";
import { IterUtils } from "engine-utils-ts";
import { notificationSource } from "../Notifications";

export class ProjectionInfo {
    constructor(
        readonly method: string = "Lambert Azimuthal Equal Area",  
        readonly parameters: ReadonlyMap<string, string> = new Map()
    ) {
        Object.freeze(this);
    };

    static fromProj4Datum(datum: string, epsgCode?: number, sendNotification?: (notification: NotificationDescription) => void) {
        const params = IterUtils.newMapFromIter(datum.split('+').map(p => p.trim().split('=')), p => p[0], p => p[1]);

        const parameters = new Map<string, string>();

        parameters.set("False easting", params.get("x_0") ?? '0');
        parameters.set("False northing", params.get("y_0") ?? '0');

        let method = params.get("proj");
        switch (method) {
            case 'laea': 
                method = "Lambert Azimuthal Equal Area";
                parameters.set("Longitude of natural origin", params.get("lon_0") ?? '0');
                parameters.set("Latitude of false origin", params.get("lat_0") ?? '0');
                break;
            case 'tmerc':
                method = "Transverse Mercator";
                parameters.set("Longitude of natural origin", params.get("lon_0") ?? '0');
                parameters.set("Latitude of false origin", params.get("lat_0") ?? '0');
                break;
            case 'lcc':
                method = "Lambert Conic Conformal (2SP)";
                parameters.set("Longitude of false origin", params.get("lon_0") ?? '0');
                parameters.set("Latitude of false origin", params.get("lat_0") ?? '0');
                parameters.set("Latitude of 1st standard parallel", params.get("lat_1") ?? '0');
                parameters.set("Latitude of 2nd standard parallel", params.get("lat_2") ?? '0');
                break;
            case 'merc':
                method = "Mercator (variant B)";
                parameters.set("Longitude of natural origin", params.get("lon_0") ?? '0');
                break;
            case 'utm':
                method = "Transverse Mercator";

                parameters.set("False easting", params.get("x_0") ?? '500000');
                parameters.set("Latitude of false origin", params.get("lat_0") ?? '0');
                
                parameters.set("Longitude of natural origin", '0');
                const lon_0 = params.get("lon_0");
                if (lon_0) {
                    parameters.set("Longitude of natural origin", lon_0);
                } else {
                    let zone: number | string | undefined = params.get("zone");
                    if (zone) {
                        zone = Number.parseInt(zone);
                        if (zone) {
                            parameters.set("Longitude of natural origin", (6 * zone - 183).toString());
                        }
                    }
                }
                
                break;
            default:
                if (sendNotification !== undefined) {
					sendNotification(NotificationDescription.newBasic({
						type: NotificationType.Error,
						source: notificationSource,
						key: 'parseProjectionMethod',
						descriptionArg: method,
						addToNotificationsLog: true,
					}));
				}
                method = "Lambert Azimuthal Equal Area";
                parameters.set("Longitude of natural origin", params.get("lon_0") ?? '0');
                parameters.set("Latitude of false origin", params.get("lat_0") ?? '0');
                break;
        }

        parameters.set("EllipsoidName", params.get("ellps") ?? "GRS1980");

        if (epsgCode) {
            parameters.set("EPSG", epsgCode.toString());
        }

        return new ProjectionInfo(method, parameters);
    }

    toProj4Datum(sendNotification?: (notification: NotificationDescription) => void) {
        switch (this.method) {
			case "Lambert Azimuthal Equal Area":
                return "+proj=laea";
			case "Transverse Mercator":
                const k = this.parameters.get("Scaling factor for coord differences");
        
                if (k === undefined) {
                    if (sendNotification !== undefined) {
                        sendNotification(NotificationDescription.newBasic({
                            type: NotificationType.Warning,
                            source: notificationSource,
                            key: 'parseProjectionParameters',
                            descriptionArg: "Scaling factor for coord differences",
                            addToNotificationsLog: true,
                        }));
                    }
                    return "+proj=tmerc +k_0=1";
                } else {
                    return `+proj=tmerc +k_0=${k}`;
                }
			case "Lambert Conic Conformal (2SP)":
                const lat_1 = this.parameters.get("Latitude of 1st standard parallel");
                const lat_2 = this.parameters.get("Latitude of 2nd standard parallel");
                
                if (lat_1 === undefined || lat_2 === undefined) {
                    if (sendNotification !== undefined) {
                        sendNotification(NotificationDescription.newBasic({
                            type: NotificationType.Warning,
                            source: notificationSource,
                            key: 'parseProjectionParameters',
                            descriptionArg: "Latitude of standard parallels",
                            addToNotificationsLog: true,
                        }));
                    }
                    return "+proj=lcc +lat_1=0 +lat_2=0 +k_0=0.999998";
                } else {
                    return `+proj=lcc +lat_1=${lat_1} +lat_2=${lat_2} +k_0=0.999998`;
                }
			case "Mercator (variant B)":
                const lat_ts = this.parameters.get("Standard Parallel");
        
                if (lat_ts === undefined) {
                    if (sendNotification !== undefined) {
                        sendNotification(NotificationDescription.newBasic({
                            type: NotificationType.Warning,
                            source: notificationSource,
                            key: 'parseProjectionParameters',
                            descriptionArg: "Standard Parallel",
                            addToNotificationsLog: true,
                        }));
                    }
                    return "+proj=merc +lat_ts=0";
                } else {
                    return `+proj=merc +lat_ts=${lat_ts}`;
                }
			case "Popular Visualisation Pseudo Mercator":
                return "+proj=merc +lat_ts=0";
			case "Transverse Mercator Zoned Grid System":
                const zone = this.parameters.get("UTM Zone Number");
                if (zone === undefined) {
                    if (sendNotification !== undefined) {
                        sendNotification(NotificationDescription.newBasic({
                            type: NotificationType.Warning,
                            source: notificationSource,
                            key: 'parseProjectionParameters',
                            descriptionArg: "UTM Zone Number",
                            addToNotificationsLog: true,
                        }));
                    }
                } else {
                    const lon_0 = 6 * Number.parseInt(zone) - 183;
                    if (lon_0 >= -180 && lon_0 <= 180) {
                        return `+proj=tmerc +lon_0=${6 * Number.parseInt(zone) - 183} +x_0=500000 +k_0=0.9996`;
                    }
                }
                return "+proj=tmerc +k_0=0.9996";
			default:
				if (sendNotification !== undefined) {
					sendNotification(NotificationDescription.newBasic({
						type: NotificationType.Error,
						source: notificationSource,
						key: 'parseProjectionMethod',
						descriptionArg: this.method,
						addToNotificationsLog: true,
					}));
				}
				console.error(`Unknown projection method: ${this.method}`);
				return "+proj=laea";
		}
    }

    equals(projectionInfo: ProjectionInfo) {
        if (this.method !== projectionInfo.method || this.parameters.size !== projectionInfo.parameters.size) {
            return false;
        }

        for (const [k, v] of this.parameters) {
            if (projectionInfo.parameters.get(k) !== v) {
                return false;
            }
        }

        return true;
    }

    getLonLatXY(
        sendNotification: (notification: NotificationDescription) => void
    ): { lonlat: WGSCoord, xy: Vector3 } | null {
        let lon = Number.parseFloat(
            this.parameters.get("Longitude of natural origin") ??
            this.parameters.get("Longitude of false origin")!
        );
        let lat = Number.parseFloat(
            this.parameters.get("Latitude of false origin") ??
            this.parameters.get("Latitude of natural origin")!
        );
        if (this.method === "Popular Visualisation Pseudo Mercator" 
            || this.method === "Mercator (variant B)"
        ) {
            lat = 0;
        }
        const lonlat = WGSCoord.new(lat, lon);

        const x = Number.parseFloat(this.parameters.get("False easting")!);
        const y = Number.parseFloat(this.parameters.get("False northing")!);
        const xy = new Vector3(x, y, 0);

        if (lonlat && xy) {
            return { lonlat, xy };
        } else {
            return null;
        }   
    }

    withNewLonLat(lonlat: WGSCoord): ProjectionInfo {
        const newParams = new Map(this.parameters);
        if (newParams.has("Longitude of false origin")) {
            newParams.set("Longitude of false origin", lonlat.longitude.toString());
        } else {
            newParams.set("Longitude of natural origin", lonlat.longitude.toString());
        }
        if (newParams.has("Latitude of natural origin")) {
            newParams.set("Latitude of natural origin", lonlat.latitude.toString());
        } else {
            newParams.set("Latitude of false origin", lonlat.latitude.toString());
        }
        return new ProjectionInfo(this.method, newParams);
    }

    withNewXY(xy: Vector3): ProjectionInfo {
        const newParams = new Map(this.parameters);
        newParams.set("False easting", xy.x.toString());
        newParams.set("False northing", xy.y.toString());
        return new ProjectionInfo(this.method, newParams);
    }
}