import { Vector2, Vector4 } from 'math-ts';
import { DoubleSide, NormalBlending } from '../3rdParty/three';

import type { ShaderBase } from '../shaders/ShaderBase';
import { ShaderFlags } from '../shaders/ShaderFlags';

export const TerrainShaderMaxSlices = 10;

export const TerrainRegularBasicTransparent: ShaderBase = Object.freeze({
	
	name: 'terrain',

	params: {
		transparent: true,
        blending: NormalBlending,
        premultipliedAlpha: true,
		side: DoubleSide,
		depthWrite: false,
	},

    defines: {
		'FLAT_NORMALS': true,
		'NEEDS_NORMALS': true,
		'NEEDS_VIEW_SPACE': true,
    },

	flags: ShaderFlags.MRT_NORMALS,
    
	uniforms: {
		heightmap: { value: null },
		heightOffsetMultiplier: { value: new Vector2() },
		visibility: { value: null },
		color: { value: new Vector4(0.5, 0.5, 0.5, 0.1) },
		gridColor: { value: new Vector4(0.0, 0.0, 0.0, 0.5) },
		gridStep: { value: new Vector2(1, 1) },
	}
	,
	vertexShader:
	`

	#include <kr_std_attributes>
	#include <kr_std_uniforms>
	#include <kr_std_vars>

	#include <common>
	
	uniform sampler2D heightmap;
	uniform vec2 heightOffsetMultiplier;

	void main() {

        #include <kr_std_uvs_vertex>

		vec4 localPosition = vec4( position, 1.0 );

		float elevation = heightOffsetMultiplier.x + texture2D( heightmap, vUv ).r * heightOffsetMultiplier.y;
		localPosition.z = elevation;
		
		mat4 worldMatrix = transforms[gl_InstanceID + instanceOffset].matrix;
		vWorldPosition = worldMatrix * localPosition;
		

		gl_Position = viewProjMatrix * vWorldPosition;
	}
	`,

	fragmentShader:
	
	`

	#include <common>
	#include <packing>

	#include <kr_std_uniforms>
	#include <kr_std_vars>

	#include <normals_packing>

	uniform vec4 color;

	uniform vec4 gridColor;

	uniform vec2 gridStep;

	#include <visibility_texture_pars_fragment>
	#include <sample_grid_fn>

	void main() {
        #include <kr_std_pixel>
        #include <visibility_texture_fragment>

        gl_FragColor = color;
        // gl_FragColor.rgb *= gl_FragColor.a;

		// float gridPower = grid(vWorldPosition.xy, 100.0, 0.1);
		// gridPower /= length(fwidth(vWorldPosition.xy));

		float gridPower = sampleGrid(vWorldPosition.xy, gridStep.xy, 0.01);
		// gridPower /= length(fwidth(vWorldPosition.xy));

		gl_FragColor.rgba = mix(gl_FragColor.rgba, gridColor, gridPower);
	}
	`,
});

