export class RadioButton {
    public id: string;

    constructor(
        public value: string | number,
        public label: string,
        public disabled: boolean = false,
        public option: RadioButtonOption | undefined = undefined,
        public tooltip?: string,
    ) {
        this.id = this.value.toString();
    }
}

export interface RadioButtonOption{
    label: string;
    value: boolean;
    onClick: (newValue: boolean) => void;
    disabled?: boolean;
}