<script lang="ts">
    import TerrainHelp from "./TerrainHelp.svelte";
    import LayoutHelp from "./LayoutHelp.svelte";
    import BuildableAreaHelp from "./BuildableAreaHelp.svelte";

    export let context: string;
</script>

<div class="root text-main-dark mdc--typography">
    {#if context === "terrain"}
        <TerrainHelp />
    {:else if context === "layout"}
        <LayoutHelp />
    {:else if context === "buildable-area"}
        <BuildableAreaHelp />
    {/if}
</div>

<style lang="scss">
    .root {
        width: 524px;
        box-sizing: border-box;
        padding: 0 24px 16px;
        font-size: 15px;
        line-height: 22px;
        :global(p) {
            margin: 12px 0 8px;
        }
        :global(ul) {
            padding-inline-start: 24px;
            margin-top: 8px;
        }
        :global(.link-button) {
            cursor: pointer;
            display: inline-flex;
            gap: 2px;
            align-items: center;
        }
    }
</style>
