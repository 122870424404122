import type { Transform, Vector4 } from "math-ts";
import { Matrix4 } from "math-ts";

export const IndexDataSize = 2; //start and end index
export const MatrixSize = 16;
export const SubmeshRawDataSize = MatrixSize + 4;

export class SubmeshInstancesInfoRaw {
    instancesOffset: number;
    instancesCount: number;

    constructor() {
        this.instancesOffset = 0;
        this.instancesCount = 0;
    }
}

export class SubmeshesInstancingRawBlocks {
    indexBufferRef!: Int32Array;
	instancingBufferRef!: Float32Array;
	
	constructor() {
	}

	setIndexBuffer(indexBuffer: Int32Array) {
		this.indexBufferRef = indexBuffer;
	}

    setInstancingBuffer(instancingBuffer: Float32Array) {
        this.instancingBufferRef = instancingBuffer;
    }

    setTransforms(
        localTransforms: (Readonly<Transform> | null)[] | null,
        parentMatrix: Readonly<Matrix4>,
        reusedInstanceMatrix: Matrix4,
        index: number
    ) {
        const matrices = localTransforms?.map(transform => {
            if(transform === null) {
                return null;
            }
            return new Matrix4().compose(transform.position, transform.rotation, transform.scale);
        }) ?? null;

        this.setMatricies(matrices, parentMatrix, reusedInstanceMatrix, index);
    }

    setMatricies(
        localTransforms: (Readonly<Matrix4> | null)[] | null,
        parentMatrix: Readonly<Matrix4>,
        reusedInstanceMatrix: Matrix4,
        index: number
    ) {
        let indexOffset = this.indexBufferRef[index * IndexDataSize];

        if(localTransforms === null) {
            this.instancingBufferRef.set(parentMatrix.elements, indexOffset);
            return;
        }

        for(const transform of localTransforms) {
            if(transform === null) {
                this.instancingBufferRef.set(parentMatrix.elements, indexOffset);
            } else {
                reusedInstanceMatrix.copy(transform);
                reusedInstanceMatrix.premultiply(parentMatrix);
                this.instancingBufferRef.set(reusedInstanceMatrix.elements, indexOffset);
            }

            indexOffset += SubmeshRawDataSize;
        }
    }
    
    setVector(vector: Vector4, index: number) {
        const indexOffset = index * IndexDataSize;
        const firstInstanceColorIndex = this.indexBufferRef[indexOffset + 0] + MatrixSize;
        const lastInstanceColorIndex = this.indexBufferRef[indexOffset + 1] - 3;

        for(let i = firstInstanceColorIndex; i <= lastInstanceColorIndex; i += SubmeshRawDataSize) {
            this.instancingBufferRef[i + 0] = vector.x;
            this.instancingBufferRef[i + 1] = vector.y;
            this.instancingBufferRef[i + 2] = vector.z;
            this.instancingBufferRef[i + 3] = vector.w;
        }
    }

    forEachInstance(index: number, reusedMatrix: Matrix4, callbackFn: (instanceMatrix: Matrix4) => void) {
        const indexOffset = index * IndexDataSize;
        const firstMatrixIndex = this.indexBufferRef[indexOffset + 0];
        const lastMatrixIndex = this.indexBufferRef[indexOffset + 1] - (SubmeshRawDataSize - 1); //offset color and matrix size

        for(let i = firstMatrixIndex; i <= lastMatrixIndex; i += SubmeshRawDataSize) {
            reusedMatrix.fromArray(this.instancingBufferRef, i);
            callbackFn(reusedMatrix);
        }
    }

    getSubmeshInstancesInfoRaw(index: number, reusedSubmeshInstancesInfoRaw: SubmeshInstancesInfoRaw): SubmeshInstancesInfoRaw {
        const indexOffset = index * IndexDataSize;
        const startIndex = this.indexBufferRef[indexOffset + 0];
        const endIndex = this.indexBufferRef[indexOffset + 1];

        reusedSubmeshInstancesInfoRaw.instancesOffset = startIndex;
        reusedSubmeshInstancesInfoRaw.instancesCount = (endIndex - startIndex + 1) / SubmeshRawDataSize;
        return reusedSubmeshInstancesInfoRaw;
    }

    *asIterable(index: number, reusedMatrix: Matrix4): Iterable<Matrix4> {
        const indexOffset = index * IndexDataSize;
        const firstMatrixIndex = this.indexBufferRef[indexOffset + 0];
        const lastMatrixIndex = this.indexBufferRef[indexOffset + 1] - (SubmeshRawDataSize - 1); //offset color and matrix size

        for(let i = firstMatrixIndex; i <= lastMatrixIndex; i += SubmeshRawDataSize) {
            reusedMatrix.fromArray(this.instancingBufferRef, i);
            yield reusedMatrix;
        }
    }

    *map<T>(index: number, reusedMatrix: Matrix4, callbackFn: (instanceMatrix: Matrix4) => T): Iterable<T> {
        const indexOffset = index * IndexDataSize;
        const firstMatrixIndex = this.indexBufferRef[indexOffset + 0];
        const lastMatrixIndex = this.indexBufferRef[indexOffset + 1] - (SubmeshRawDataSize - 1); //offset color and matrix size

        for(let i = firstMatrixIndex; i <= lastMatrixIndex; i += SubmeshRawDataSize) {
            reusedMatrix.fromArray(this.instancingBufferRef, i);
            yield callbackFn(reusedMatrix);
        }
    }
}