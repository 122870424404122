<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M4 12L5 13L8.0223 10L11 13L12 12L8.0223 8L4 12Z"
        fill="currentColor"
    />
    <path d="M4 7L5 8L8.0223 5L11 8L12 7L8.0223 3L4 7Z" fill="currentColor" />
</svg>
