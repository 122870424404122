import { DefaultMapWeak } from "../DefaultMapWeak";
import { FetchUtils } from "../FetchUtils";
import { WorkerPoolImpl } from "./WorkerPoolImpl";
import type { JobExecutor } from "./JobExecutor";


export class WorkerPool {

    static start(workerScriptPath: string) {
        WorkerPoolImpl.start(workerScriptPath);
    }

    static execute<Args, Result>(
        jobExecutorCtor: {new(): JobExecutor<Args, Result>},
        args: Args,
        taskDisplacementGuid: string | null = null,
    ): Promise<Result> {
        const poolInstance = WorkerPoolImpl.instance();
        const executor = new jobExecutorCtor();
        return poolInstance.executeWith(executor, args, taskDisplacementGuid ?? FetchUtils.generateGuid());
    }

    static getUniqueIdForObject(obj: Object): number {
        return __wp_per_obj_weak_ids.getOrCreate(obj);
    }

    static createWorkGuidForInCollectionId(collection: Object, id: any, prefix?: string) {
        const collectionId = __wp_per_obj_weak_ids.getOrCreate(collection);
        let idString = typeof id === 'object' ? __wp_per_obj_weak_ids.getOrCreate(id) : id
        if (prefix) {
            return `${prefix}:${idString}||(${collectionId})`;
        } else {
            return `${idString}||(${collectionId})`;
        }
    }

    static clearCaches() {
        const poolInstance = WorkerPoolImpl.instance();
        poolInstance._clearCache();
    }

    static dispose() {
        const poolInstance = WorkerPoolImpl.instance();
        poolInstance.dispose();
    }
}

let _objsIdsCounter = 999;
let __wp_per_obj_weak_ids = new DefaultMapWeak<Object, number>((_obj) => _objsIdsCounter += 1);

if ((globalThis as any)['__wp_per_obj_weak_ids']) {
    console.error('__wp_per_obj_weak_ids double initialization, bundling is wrong');
    __wp_per_obj_weak_ids = (globalThis as any)['__wp_per_obj_weak_ids'];
} else {
    (globalThis as any)['__wp_per_obj_weak_ids'] = __wp_per_obj_weak_ids;
}