import { GridOptions } from "ag-grid-enterprise";
import { Bim, CostModel as CM, LCOE } from "bim-ts";
import { ContextPlaceholder } from "./types";
import { createDescriptionColumnDefinition } from "./column-definitions/Description";
import { createCommonAgGridTableOptions } from "src/cost-model/capital/table";
import { createCostSourceColumnDefinition } from "./column-definitions/Source";
import {
    creatLCOEInputColumnDefinition,
    createLcoeInputQuantityColumnDefinition,
    createLcoeInputResultingUnitColumnDefinition,
} from "./column-definitions/LCOEInput";
import { createLcoeSummaryRowGridOptions } from "./LcoeSummaryRow";

export function createGridConfig(bim: Bim): {
    options: GridOptions<CM.FlattenedCostCategoryParams>;
    context: ContextPlaceholder;
} {
    const defaultGridContext: ContextPlaceholder = {
        bim,
        hierarchy: new LCOE.CostHierarchy(),
        unitsMapper: bim.unitsMapper,
    };
    const gridOptions: GridOptions<CM.FlattenedCostCategoryParams> = {
        rowData: [],
        context: defaultGridContext,
        columnDefs: [
            createDescriptionColumnDefinition(),
            createCostSourceColumnDefinition(),
            createLcoeInputQuantityColumnDefinition(),
            creatLCOEInputColumnDefinition(),
            createLcoeInputResultingUnitColumnDefinition(),
        ],
        processUnpinnedColumns: () => [],
        ...createCommonAgGridTableOptions(),
        ...createLcoeSummaryRowGridOptions(),
        getRowHeight: (params) => {
            if (!params.data) {
                return;
            }
            if (params.data.nestLevel <= 1) {
                return 45;
            } else if (params.data.nestLevel === 2) {
                return 32;
            }
            return;
        },
    };
    //if (gridOptions.defaultColDef?.enableCellChangeFlash !== undefined) {
    //    gridOptions.defaultColDef.enableCellChangeFlash = false;
    //}
    const context = defaultGridContext;
    return {
        options: gridOptions,
        context,
    };
}
