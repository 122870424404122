import type { LazyVersioned, ResultAsync } from "engine-utils-ts";
import { LazyDerived, LazyDerivedAsync, Success } from "engine-utils-ts";
import { NumberProperty } from "../properties/PrimitiveProps";

import { SetupTableId, SummaryTableId } from "../cost-model/capital/const";
import { findCostCategoryTotalCost } from "../cost-model/capital/tables/utils";

import type {
    CostReportRelatedMetrics,
    ProjectMetrics,
    KeyMetrics,
} from "./ProjectMetrics";
import type { CostModel } from "../cost-model/capital/tables/categories/CostModel";
import type { UnitsMapper } from "../";
import { LCOE } from "../";

export function createCostReportRelatedMetrics(
    capitalCostRelatedMetrics: LazyVersioned<
        ResultAsync<CostReportRelatedMetrics>
    >,
    lcoe: LazyVersioned<ResultAsync<LCOE.CreateLcoeHierarchyResult>>,
    unitsMapper: UnitsMapper,
) {
    return LazyDerivedAsync.new2(
        "cost-report-related-metrics",
        [unitsMapper],
        [capitalCostRelatedMetrics, lcoe],
        function* ([capitalCostRelatedMetrics, lcoe]) {
            const lcoeValue = LCOE.inverseLcoeResultDiv(
                lcoe.lcoe.output.levelizedCostOfEnergyPerWattHour,
            );
            const result: CostReportRelatedMetrics = {
                ...capitalCostRelatedMetrics,
                lcoe: {
                    LCOE: NumberProperty.new(unitsMapper.converter.toShortest({
                        value: Number.isFinite(lcoeValue) ? lcoeValue : 0,
                        unit: "Wh",
                    })),
                },
            };
            return result;
        },
    );
}

export function createCapitalCostRelatedMetrics(
    costModel: LazyVersioned<ResultAsync<CostModel>>,
) {
    return LazyDerivedAsync.new1(
        "cost-report-related-metrics",
        [],
        [costModel],
        function* ([costModel]) {
            //for (let i = 0; i < 10; i++) {
            //    yield Yield.NextFrame;
            //}
            const metricsPerTemplate = new Map<
                string,
                { totalCost: NumberProperty; costPerWatt: NumberProperty }
            >();
            combineTemplates: {
                if (!costModel.totalWattDC) {
                    break combineTemplates;
                }

                // gather properties
                for (const table of costModel.tables) {
                    if (
                        table.id === SummaryTableId ||
                        table.id === SetupTableId
                    ) {
                        continue;
                    }
                    const category = table.hierarchy.getRootCategories().at(0);
                    if (!category) {
                        continue;
                    }
                    const total = findCostCategoryTotalCost(category[1]);
                    if (typeof total !== "number") {
                        continue;
                    }
                    const pricePerWatt = NumberProperty.new({
                        unit: "usd",
                        value: total / costModel.totalWattDC,
                    });
                    metricsPerTemplate.set(table.name, {
                        costPerWatt: pricePerWatt,
                        totalCost: NumberProperty.new({
                            value: total,
                            unit: "usd",
                        }),
                    });
                }
            }

            let metrics: CostReportRelatedMetrics = null;

            if (metricsPerTemplate.size) {
                const constructionCostBreakdown: Record<
                    string,
                    NumberProperty
                > = {};
                for (const [
                    template,
                    metrics,
                ] of metricsPerTemplate.entries()) {
                    if (isFinite(metrics.totalCost.value)) {
                        constructionCostBreakdown[template] = metrics.totalCost;
                    }
                }
                const constructionCostPerDCWatt: Record<
                    string,
                    NumberProperty
                > = {};
                for (const [
                    template,
                    metrics,
                ] of metricsPerTemplate.entries()) {
                    if (isFinite(metrics.costPerWatt.value)) {
                        constructionCostPerDCWatt[template] =
                            metrics.costPerWatt;
                    }
                }

                const totalConstructionCost = NumberProperty.unitBasedSum(
                    Array.from(metricsPerTemplate.values()).map(
                        (x) => x.totalCost,
                    ),
                );
                const totalCostPerWatt = NumberProperty.unitBasedSum(
                    Array.from(metricsPerTemplate.values()).map(
                        (x) => x.costPerWatt,
                    ),
                );

                metrics = {
                    construction_cost_breakdown: constructionCostBreakdown,
                    construction_cost_per_DC_Watt: constructionCostPerDCWatt,
                    construction_total_cost:
                        totalConstructionCost instanceof Success &&
                        isFinite(totalConstructionCost.value.value)
                            ? totalConstructionCost.value
                            : undefined,
                    construction_total_cost_per_DC_Watt:
                        totalCostPerWatt instanceof Success &&
                        isFinite(totalCostPerWatt.value.value)
                            ? totalCostPerWatt.value
                            : undefined,
                };
            }

            return metrics;
        },
    );
}

export function createTotalDCLazy(
    layoutMetrics: Readonly<ProjectMetrics>,
): LazyVersioned<NumberProperty | undefined> {
    return LazyDerived.new1<
        NumberProperty | undefined,
        ResultAsync<KeyMetrics>
    >("totalDCLazy", null, [layoutMetrics.keyMetrics], ([metrics]) => {
        const total_dc =
            metrics instanceof Success ? metrics.value.totalDcPower : undefined;
        if (!(total_dc instanceof NumberProperty)) {
            return;
        }
        return total_dc;
    });
}
