import type { CombinerBoxDescription } from "../types";
import { Aabb2, Matrix3, Vector2 } from "math-ts";
import { createInverterObject2DWithChilds } from "./inverter";
import { createCombinerBoxObject2D } from "../../templates/combiner-box";
import { VectorPrimitivePath, VectorPrimitivePathDescription } from "vector-graphic";
import { createManyToOneConnection } from "src/sld/templates/connections";
import { Anchor2D } from 'vector-graphic';
import { createCombinerBoxInternalWiring } from "src/sld/templates/combiner-box";
import { createJumper2D } from "src/sld/templates/jumper";
import { indexSeparator, wireOffset, wireWidth } from "src/sld/templates/config";
import { positionChildrensShortend } from "../../templates/common";

export function createCombinerBoxObject2DWithChilds(
    combinerBoxDesc: CombinerBoxDescription,
    params: { index: string }
) : Anchor2D {
    // create children
    const root = positionChildrensShortend(
        combinerBoxDesc.inverters,
        (x, index) => createInverterObject2DWithChilds(
            x, { index: [params.index, index + 1].join(indexSeparator) }
        )
    )

    // create base combiner box
    const combinerBox = createCombinerBoxObject2D(combinerBoxDesc, params)

    // create connections from children
    const wiring = createManyToOneConnection({
        sources: root.points,
        sourceOffset: 300,
        targetOffset: 100,
        wireOffset: wireOffset,
        targetHeight: combinerBox.aabb.height() * 0.7,
    })
    root.addAndExpandAabb(wiring)

    // position combiner box
    if (!root.aabb.isEmpty() && wiring.points.length) {
        const aabb = aabbReused.setFromPoints(wiring.points);
        combinerBox.position.set(aabb.centerX() - combinerBox.aabb.min.x, aabb.centerY());
        combinerBox.updateMatrix()
    }
    root.addAndExpandAabb(combinerBox);

    // cb internal wiring
    {
        const inverseMat = mat3Reused.copy(combinerBox.matrix).invert();
        combinerBox.addAndExpandAabb(createCombinerBoxInternalWiring({
            cbCenter: new Vector2(),
            inputs: wiring.points.map(x => x.clone().applyMatrix3(inverseMat))
        }))
    }

    // final object
    const result = new Anchor2D();
    result.addAndExpandAabb(root);

    // add jumper
    {
        const pt1 = combinerBox.point.clone()
        pt1.x += 100;
        combinerBox.addPrimitiveAndExpandAabb(new VectorPrimitivePath({
            paths: [new VectorPrimitivePathDescription([combinerBox.point, pt1])],
            strokeWidth: wireWidth,
        }))
        const jumper = createJumper2D({ strokeWidth: wireWidth })
        jumper.position.copy(pt1);
        jumper.updateMatrix();
        jumper.zIndex = 2;
        combinerBox.addAndExpandAabb(jumper);
        const exitPoint = jumper.point.clone()
            .applyMatrix3(jumper.matrix)
            .applyMatrix3(combinerBox.matrix);
        result.point.copy(exitPoint);
    }


    return result;
}

const aabbReused = Aabb2.empty();
const mat3Reused = new Matrix3();
