import { LegacyLogger } from 'engine-utils-ts';
import { KrMath } from 'math-ts';

export class RenderTimeBudget {

    _targetFrameTime: number = 70;

    _frameStartTime: number = 0;

    _expectedFrameEndTime: number = 0;
    _webglTimeBudgetLeft: number = this._targetFrameTime;

    startNewFrame() {
        const prevFrameStart = this._frameStartTime;
        this._frameStartTime = performance.now();

        const timeExcess = prevFrameStart + this._targetFrameTime - this._frameStartTime;
        let diffToScheduledEndOfThisFrame;
        if (timeExcess > 0) {
            // if we used less time than max limit, just use preferred target frame time
            diffToScheduledEndOfThisFrame = this._targetFrameTime;
        } else {
            // if between start of this frame, and start of previous frame
            // passed more time than allowed max
            // decrease this frame time limit
            diffToScheduledEndOfThisFrame = Math.max((this._targetFrameTime + timeExcess), this._targetFrameTime * 0.5);
        }
        this._expectedFrameEndTime = this._frameStartTime + diffToScheduledEndOfThisFrame;

		const newWebglTimeBudget = KrMath.clamp(
			KrMath.lerp(
				this._webglTimeBudgetLeft + this._targetFrameTime,
				this._targetFrameTime,
				0.5,
			), 
			this._targetFrameTime * 0.6,
			this._targetFrameTime * 1.1,
		);
		this._webglTimeBudgetLeft = newWebglTimeBudget;
    }

    haveMuchTimeLeft() {
        const targetFrameTime = this._targetFrameTime;
        return this.jsTimeBudgetLeft() > targetFrameTime * 0.5
            && this.gpuTimeBudgetLeft() > targetFrameTime * 0.5;
    }

    decreaseGpuBudgetLeftBy(gpuTime: number) {
        if (gpuTime > 0.001 && gpuTime < Infinity) {
            this._webglTimeBudgetLeft -= gpuTime;
        }
    }

    jsTimeBudgetLeft(): number {
        return this._expectedFrameEndTime - performance.now();
    }

    gpuTimeBudgetLeft(): number {
        return this._webglTimeBudgetLeft;
    }

    targetFrameTime(): number {
        return this._targetFrameTime;
    }

    setTargetFrameTime(target: number) {
        if (!(target > 0 && target < Infinity)) {
            LegacyLogger.error('invalid target frame time', target);
            return;
        }
        this._targetFrameTime = target;
    }

}
