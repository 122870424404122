import type { KreoEngine } from "engine-ts";
import { VersionedInvalidator, type RGBAHex, type VersionedValue, type LazyVersioned, IterUtils } from "engine-utils-ts";
import type { IconName } from "../../../libui/icon";
import type { PUI_PropertyNodeNumber } from "ui-bindings";
import type { Vector2 } from "math-ts";
import type { Bim, IdBimScene } from "bim-ts";

export interface ControlItemConfig {
    color: RGBAHex | 0;
    onChangeColor?: (color: RGBAHex) => void;
    onSelect?: () => void;
    onDelete: () => void;
    readonly?: boolean;
}

export function createConfigForInstance(ids: IdBimScene[], bim: Bim, engine: KreoEngine, onDeleteZone: () => void): ControlItemConfig | undefined {
    const instances = bim.instances.peekByIds(ids);
    let color: 0 | RGBAHex = 0;
    const instance = IterUtils.getFirstFromIter(instances.values())
    if (instance) {
        color = instance.colorTint;
    }
    let onChangeColor: ((color: RGBAHex) => void) | undefined;
    let onSelect: (() => void) | undefined;
    if(ids.length > 0){
        onChangeColor = (color) => {
            bim.instances.applyPatchTo({
                colorTint: color,
            }, ids);
        }
        onSelect = () => {
            bim.instances.setSelected(ids);
            engine.focusCamera(ids);
        }
    }
    return {
        color,
        onChangeColor,
        onSelect,
        onDelete: () => {
            onDeleteZone();
        },
    }
}

export class SelectedAreaLazy implements VersionedValue {
    private _version: VersionedInvalidator;
    private _currentSelectedArea: number = 0;
    private _sourceProperty: PUI_PropertyNodeNumber;

    constructor(selectedSiteAreaProp: PUI_PropertyNodeNumber){
        this._currentSelectedArea = selectedSiteAreaProp.value ?? 0;
        this._sourceProperty = selectedSiteAreaProp;
        this._version = new VersionedInvalidator();
    }

    setSelected(idx: number) {
        if(this._currentSelectedArea == idx){
            return;
        }
        this._currentSelectedArea = idx;
        // console.log("Selected area: " + this._currentSelectedArea);
        this._sourceProperty.value = idx;

        this._version.invalidate();
    }

    isSelected(idx: number | null): boolean {
        return this._currentSelectedArea == idx;
    }
    
    version(): number {
        return this._version.version();
    }
}

export interface TextButtonSettings {
    action: (pos: Vector2) => void;
	isEnabled?: LazyVersioned<boolean>;
    label: string;
    iconBefore?: IconName;  
}
