<script lang="ts">
import type { PUI_TabSelector } from "ui-bindings";
import { Tabs, type Tab } from "../libui/tabs";

export let property: PUI_TabSelector;

$: activeTabIndex =
    property.options.findIndex(x => x.value === property.value);

</script>

<Tabs
    tabs={property.options.map(x => ({ key: x.value, name: x.value }))}
    firstIndexToActivate={activeTabIndex}
    compact={true}
    on:change={e => {
        const tab = e.detail?.tab?.key;
        if (tab) {
            property.value = tab;
        }
    }}
/>
