import type { JobsArgsCachingDescription} from "engine-utils-ts";
import { ErrorUtils, ExecutionThreadPreference, JobExecutor, registerExecutor } from "engine-utils-ts";
import type { Aabb2 } from "math-ts";
import type { PerTileCutFillHeatmap } from "../metrics/TerrainMetrics";
import { RegularHeightmapGeometry } from "../../geometries/RegularHeightmapGeometry";
import { IrregularHeightmapGeometry } from "../../geometries/IrregularHeightmapGeometries";
import { TerrainMetricsIrregular } from "../metrics/TerrainMetricsIrregular";
import { TerrainMetricsRegular } from "../metrics/TerrainMetricsRegular";

export class CutFillHeatmapCalculationExecutor extends JobExecutor<CutFillHeatmapCalcJobArgs, PerTileCutFillHeatmap | null> {
    execute(args: CutFillHeatmapCalcJobArgs): PerTileCutFillHeatmap | null {
        if (args.updatedGeo == null) {
            return null;
        }
        let heatmap: PerTileCutFillHeatmap;
        const tileSize = args.tileMaxAabb.getSize();
        if (args.initialGeo instanceof RegularHeightmapGeometry && args.updatedGeo instanceof RegularHeightmapGeometry) {
            console.assert(tileSize.x === tileSize.y, 'expect tile to have equal x y dimensions');
            heatmap = TerrainMetricsRegular.calculateCutFillHeatmapForTileGeos(
                tileSize.x, args.initialGeo, args.updatedGeo, args.initialGeo.xSegmentsCount
            );
        } else if (args.initialGeo instanceof IrregularHeightmapGeometry && args.updatedGeo instanceof IrregularHeightmapGeometry) {
            heatmap = TerrainMetricsIrregular.calculateCutFillHeatmapForTileGeos(
                args.tileMaxAabb, args.initialGeo, args.updatedGeo, Math.round(tileSize.x),
            );
        } else {
            ErrorUtils.logThrow(`unexpected geometries types pair`, args.initialGeo.constructor.name, args.updatedGeo.constructor.name);
        }
        if (heatmap.cutFillInCm.every((v) => v == 0 || Number.isNaN(v))) {
            return null;
        }
        return heatmap;
    }
    executionPreference(args: CutFillHeatmapCalcJobArgs): ExecutionThreadPreference {
        if (args.updatedGeo === null) {
            return ExecutionThreadPreference.MainThread;
        }
        return ExecutionThreadPreference.WorkerThread;
    }

    argsCacheKey(args: CutFillHeatmapCalcJobArgs): JobsArgsCachingDescription<CutFillHeatmapCalcJobArgs> | null {
        return {
            byEq: ['tileMaxAabb'],
            byRef: ['initialGeo', 'updatedGeo']
        }
    }
    
}
registerExecutor(CutFillHeatmapCalculationExecutor);

export interface CutFillHeatmapCalcJobArgs {
    tileMaxAabb: Aabb2,
    initialGeo: IrregularHeightmapGeometry | RegularHeightmapGeometry,
    updatedGeo: IrregularHeightmapGeometry | RegularHeightmapGeometry | null,
}
