import type { IdBimGeo, IdBimMaterial } from '..';
import type { PropsGroupBase } from '../properties/Props';
import type { AnySolverObjectInput, InObjectDependencies, SolverLegacyPropsInput } from './ObjectsSelector';
import { ChildrenSelector, ObjectsSelector } from './ObjectsSelector';
import type { SolverInstancePatchResult } from './ReactiveSolverBase';
import { ReactiveSolverBase } from './ReactiveSolverBase';
import type { SharedGlobalsInput, GlobalArgsSelector } from './RuntimeGlobals';
import type { SolverPropsInput } from './SolverPropsWithChildren';

export interface ChildIdentWithArgs {
    identifier: string;
    legacyPropsDefaults: SolverPropsInput;
    newPropsDefaults: PropsGroupBase;
}

export class SolverObjectInstanceWithChildren<
    Props extends SolverLegacyPropsInput,
    NewProps extends PropsGroupBase,
    D extends Partial<InObjectDependencies<Props, NewProps>>,
    GAS extends GlobalArgsSelector,
> extends ReactiveSolverBase {

    readonly solverFunction: (
        currObjectInput: Readonly<D>,
        globalArgs: Readonly<SharedGlobalsInput<GAS>>,
        children: Readonly<AnySolverObjectInput>[][],
    ) => SolverInstancePatchResult;

    constructor(params: {
        solverIdentifier: string,
        objectsIdentifier: string | string[],
        objectsDefaultArgs: D,
        globalArgsSelector?: GAS,
        cache?: boolean,
        solverFunction: (
            currObjectInput: Readonly<D>,
            globalArgs: Readonly<SharedGlobalsInput<GAS>>,
            children: Readonly<AnySolverObjectInput>[][],
        ) => SolverInstancePatchResult,
        invalidateInnerCache?: (args: {
            geometriesIds: Set<IdBimGeo>,
            materialsIds: Set<IdBimMaterial>,
        }) => void,
        childrenIdWithArgs: ChildIdentWithArgs[]
    }){
        super({
            identifier: params.solverIdentifier,
            globalArgsSelector: params.globalArgsSelector,
            objFilter: new ObjectsSelector(
                params.objectsIdentifier,
                params.objectsDefaultArgs,
            ),
            childrenFilter: new ChildrenSelector(
                params.childrenIdWithArgs.map((c) => {
                    return {
                        type_identifier: c.identifier,
                        propsDependencies: c.legacyPropsDefaults,
                        newPropsDefaults: c.newPropsDefaults,
                    };
                })
            ),
            cache: params.cache
        });
        this.solverFunction = params.solverFunction;
        this.invalidateInnerCache = params.invalidateInnerCache;
    }

    compute(
        currObjectInput: Readonly<AnySolverObjectInput>,
        sharedArgs?: Readonly<SharedGlobalsInput<GAS>>,
        children?: Readonly<AnySolverObjectInput>[][],
    ): SolverInstancePatchResult {
        return this.solverFunction(
            currObjectInput as Readonly<D>,
            sharedArgs as Readonly<SharedGlobalsInput<GAS>>,
            children ?? [],
        );
    }
}

