import type { Yield } from 'engine-utils-ts';
import { ObjectUtils } from 'engine-utils-ts';
import type { Bim } from '..';
import { PropertyBase } from './Props';

export type AnyVirtualProperty = VirtualPropertyBase<Object, Object, Object | undefined>;

export abstract class VirtualPropertyBase<ValueType extends Object, Args, BimExtractedArgs = undefined> extends PropertyBase {

    args: Readonly<Args>;

    constructor(args: Args) {
        super();
        this.args = args;
    }

    equals(other: PropertyBase): boolean {
        if (!(other instanceof VirtualPropertyBase)) {
            return false;
        }
        return ObjectUtils.areObjectsEqual(this.args, other.args);
    }

    _extractAdditionalArgsFromBim?:(bim: Bim) => Generator<Yield, BimExtractedArgs>;

    abstract _calculate(additionalArgs: BimExtractedArgs): Generator<Yield, ValueType>;

    // return 0 if should not be run inside worker pool
    workerPoolJobDurationEstimatorMs(additionalArgs: BimExtractedArgs): number {
        return 0;
    }
}
