import type { EChartOptions } from "ui-charts";
import type { CostCategory} from "..";
import { CostHierarchy, findCostCategoryTotalCost } from "..";
import type { UnitsMapper } from "src";
import { replaceCurrencyUnitWithSymbol, IterUtils, UnitConverter, DefaultMap } from "engine-utils-ts";
import { RackingCategoryName } from "./structural/racking";
import { EarchworkCategoryName } from "./civil/earthwork";
import { PilesCategoryName } from "./structural/piles";

export function createCostByCategoryChart(hierarchies: CostHierarchy[], unitsMapper: UnitsMapper): EChartOptions {
    const costByCategory = new Map<string, { total: number, perWatt: number }>
    for (const hierarchy of hierarchies) {
        const root = hierarchy.getRootCategories().at(0)?.[1]
        if (!root) {
            continue;
        }
        costByCategory.set(
            root.description.value.replaceAll('Subtotal', ''),
            {
                total: Math.round(findCostCategoryTotalCost(root)),
                perWatt: 0.1
            }
        )
    }
    const totalCost = IterUtils.sum(costByCategory.values(), x => x.total) / costByCategory.size;
    const prefixedTotalCost = UnitConverter.toShortestCurrency(totalCost);
    const data: Array<[amount: number, category: string]> = [];
    for (const [name, c] of costByCategory) {
        data.push([c.total / prefixedTotalCost.prefix.multiplier, name])
    }
    const options: EChartOptions = {
        animation: false,
        title: {
            text: 'Cost Groups',
            left: 'center',
            textStyle: {
                fontSize: 16
            }
        },
        dataset: {
          source: [
            ['amount', 'category'],
            ...data,
          ]
        },
        tooltip: {
            trigger: 'item',
            valueFormatter: (value: number) => {
                return value.toFixed(2);
            }
        },
        grid: { containLabel: true, show: false },
        xAxis: {
            name: prefixedTotalCost.prefix.prefixName + " " + replaceCurrencyUnitWithSymbol(unitsMapper.mapUnitToConfigured('usd')),
            axisLine: { show: false },
        },
        yAxis: {
            type: 'category',
            axisLine: { show: false },
        },
        series: [
          {
            type: 'bar',
            encode: {
              x: 'amount',
              y: 'category'
            }
          }
        ]
    }
    return options;
}

export function createGroundingCostsChart(
    hierarchies: CostHierarchy[],
    unitsMapper: UnitsMapper,
    totalDCWatt: number,
) {
    let piles: CostCategory | undefined;
    let cutFill: CostCategory | undefined;
    let racking: CostCategory | undefined;

    for (const hierarchy of hierarchies) {
        for (const c of hierarchy.categories.values()) {
            if (c.description.value === PilesCategoryName) {
                piles = c;
            } else if (c.description.value === EarchworkCategoryName) {
                cutFill = c;
            } else if (c.description.value === RackingCategoryName) {
                racking = c;
            }
        }
    }

    const Categories = ['Piles', 'Cut & Fill', 'Racking'] as const
    type Category = typeof Categories[number];

    const totalsByCategory = new DefaultMap<Category, number>(() => 0);

    totalsByCategory.set('Piles', piles && findCostCategoryTotalCost(piles) || 0)
    totalsByCategory.set('Cut & Fill', cutFill && findCostCategoryTotalCost(cutFill) || 0)
    totalsByCategory.set('Racking', racking && findCostCategoryTotalCost(racking) || 0)

    const perWattByCategory = new DefaultMap<Category, number>(() => 0);
    perWattByCategory.set("Piles", totalsByCategory.getOrCreate('Piles') / totalDCWatt || 0)
    perWattByCategory.set("Cut & Fill", totalsByCategory.getOrCreate('Cut & Fill') / totalDCWatt || 0)
    perWattByCategory.set("Racking", totalsByCategory.getOrCreate('Racking') / totalDCWatt || 0)

    const totalCost = IterUtils.sum(totalsByCategory.values(), x => x);
    const averageCost = totalCost / totalsByCategory.size;
    const averagePrefixed = UnitConverter.toShortestCurrency(averageCost);

    const option: EChartOptions = {
        animation: false,
        textStyle: {
            fontFamily: 'Source Sans',
        },
        legend: {
            show: false,
        },
        title: {
            text: 'Grounding costs',
            left: -5,
            textStyle: {
                fontWeight: '600',
                color: 'black',
                fontSize: 16
            }
        },
        grid: {
            top: 45,
            containLabel: true,
            left: 'left',
            height: '50%',
            right: 0,
        },
        xAxis: {
            type: 'value',
            axisTick: {
                show: false,
                length: 30,
                inside: true,
                lineStyle: {
                    color: 'rgb(224, 230, 241)'
                }
            },
            axisLabel: {
                align: 'left',
                formatter: (value: number) => {
                    if (value === 0) {
                        return replaceCurrencyUnitWithSymbol(unitsMapper.mapUnitToConfigured('usd')) + ",\n" + averagePrefixed.prefix.prefixName + " "
                    }
                    return value;
                },
                lineHeight: 12
            }
        },
        yAxis: [
            {
                type: 'category',
                splitLine: { show: false },
                axisTick: { show: false },
                axisLine: { show: false },
                data: Categories,
                axisLabel: {
                    fontSize: 14,
                    lineHeight: 56,
                    fontWeight: '500',
                    inside: true,
                    color: 'black',
                    margin: 0,
                    verticalAlign: 'bottom',
                    formatter: '{value}'
                },
            },
            {
                splitLine: { show: false },
                axisTick: { show: false },
                axisLine: { show: false },
                position: 'right',
                axisLabel: {
                    margin: 70,
                    fontSize: 14,
                    fontWeight: '600',
                    verticalAlign: 'middle',
                    align: 'right',
                    formatter: (value: string) => {
                        const cost = perWattByCategory.getOrCreate(value as Category);
                        return formatCostPerWatt(cost) + " " + replaceCurrencyUnitWithSymbol(unitsMapper.mapUnitToConfigured('usd')) + "/" + "W";
                    }
                },
                data: Categories,
            },
            {
                splitLine: { show: false },
                axisTick: { show: false },
                axisLine: { show: false },
                position: 'right',
                axisLabel: {
                    margin: 140,
                    fontSize: 14,
                    fontWeight: 'normal',
                    verticalAlign: 'middle',
                    align: 'right',
                    formatter: (value: string) => {
                        const totalByCategory = totalsByCategory.get(value as Category) ?? 0;
                        const totalPrefixed = totalByCategory / averagePrefixed.prefix.multiplier;
                        return `${replaceCurrencyUnitWithSymbol(unitsMapper.mapUnitToConfigured('usd'))} ${totalPrefixed.toFixed(2)} ${averagePrefixed.prefix.prefixNameShort}`
                    }
                },
                data: Categories,
            },
        ],
        tooltip: {
            trigger: 'item',
            show: false,
        },
        series: {
            name: 'Test',
            type: 'bar',
            stack: 'total',
            barWidth: `58%`,
            label: {
                show: false,
                formatter: (params) => {
                    if (typeof params.value !== 'number') {
                        return '';
                    }
                    const fractionOfTotal = params.value / averagePrefixed.value;
                    if (fractionOfTotal < 0.2) {
                        return '';
                    }

                    const totalPerCategory = (totalsByCategory.get(params.name as Category) ?? 0) / averagePrefixed.prefix.multiplier;
                    const fraction = params.value / totalPerCategory;

                    if (!Number.isFinite(fraction)) {
                        return ''
                    }

                    return formatPercentage(fraction);
                }
            },
            data: Array.from(totalsByCategory.values()).map(x => x / averagePrefixed.prefix.multiplier),
            tooltip: {
                trigger: 'item'
            },
        }
    };
    return option;
}

export function createCostByColumnChart(hierarchies: CostHierarchy[], unitsMapper: UnitsMapper) {
    type Table = string;
    const Categories = ['Labor', 'Material', 'Sub/Service', 'Equipment'] as const;
    type Category = typeof Categories[number];
    const costByCategory = new DefaultMap<Table, Map<Category, number>>(() => new Map());
    const totalsByTable = new Map<Table, number>();
    const colorByCategory = new DefaultMap<Category, string>(category => {
        switch (category) {
            case "Labor":
                return "#9ec97f";
            case "Material":
                return "#5a70c1"
            case "Sub/Service":
                return "#dc716b";
            case "Equipment":
                return "#522e80";
            default:
                return 'grey';
        }
    })

    for (const hierarchy of hierarchies) {
        const root = hierarchy.getRootCategories().at(0)?.[1]
        if (!root) {
            continue;
        }
        const tableName = root.description.value.replaceAll('Subtotal', '');
        const category = costByCategory.getOrCreate(tableName)
        category.set('Labor', root.labor?.laborTotal?.value ?? 0);
        category.set('Material', root.material?.materialTotal?.value ?? 0);
        category.set('Sub/Service', root.subService?.subServiceTotal?.value ?? 0);
        category.set('Equipment', root.equipment?.equipmentTotal?.value ?? 0);
        totalsByTable.set(tableName, findCostCategoryTotalCost(root));
    }

    const totalCost = IterUtils.sum(totalsByTable.values(), x => x);
    const averageCost = IterUtils.sum(totalsByTable.values(), x => x) / costByCategory.size;
    const averagePrefixed = UnitConverter.toShortestCurrency(averageCost);

    const option: EChartOptions = {
        animation: false,
        textStyle: {
            fontFamily: 'Source Sans',
        },
        legend: {
            left: '-5',
            bottom: 20,
            textStyle: {
                fontWeight: 'bold',
                color: 'rgba(0,0,0,0.3)',
            },
            // 2px:
            icon: 'path://M 5 2 C 2 2 2 2 2 5 V 15 C 2 18 2 18 5 18 H 23 C 26 18 26 18 26 15 V 5 C 26 2 26 2 23 2 H 5 Z',
            //// 3px
            //icon: 'path://M5 2C3.34315 2 2 3.34314 2 5V15C2 16.6569 3.34315 18 5 18H23C24.6569 18 26 16.6569 26 15V5C26 3.34315 24.6569 2 23 2H5Z',
            //// 1px:
            // icon: 'path://M 4 2 C 2 2 2 2 2 4 V 16 C 2 18 2 18 4 18 H 24 C 26 18 26 18 26 16 V 4 C 26 2 26 2 24 2 H 6 Z',
            selectedMode: false,
        },
        title: {
            text: 'Cost by group',
            left: -5,
            textStyle: {
                fontWeight: '600',
                color: 'black',
                fontSize: 16
            }
        },
        grid: {
            top: 45,
            containLabel: true,
            left: 'left',
            right: 0,
        },
        xAxis: {
            type: 'value',
            offset: 15,
            axisLabel: {
                align: 'left',
                formatter: (value: number) => {
                    if (value === 0) {
                        return replaceCurrencyUnitWithSymbol(unitsMapper.mapUnitToConfigured('usd')) + ",\n" + averagePrefixed.prefix.prefixName + " "
                    }
                    return value;
                },
                lineHeight: 12
            },
            axisTick: {
                show: true,
                length: 15,
                inside: true,
                lineStyle: {
                    color: 'rgb(224, 230, 241)'
                }
            },
        },
        yAxis: [
            {
                type: 'category',
                splitLine: { show: false },
                axisTick: { show: false },
                axisLine: { show: false },
                data: Array.from(costByCategory.keys()),
                axisLabel: {
                    fontSize: 14,
                    lineHeight: 48,
                    fontWeight: '500',
                    inside: true,
                    color: 'black',
                    margin: 0,
                    verticalAlign: 'bottom',
                    formatter: '{value}'
                },
            },
            {
                splitLine: { show: false },
                axisTick: { show: false },
                axisLine: { show: false },
                position: 'right',
                axisLabel: {
                    margin: 70,
                    fontSize: 14,
                    fontWeight: '600',
                    verticalAlign: 'middle',
                    align: 'right',
                    formatter: (value: string) => {
                        const totalByTable = totalsByTable.get(value) ?? 0;
                        const fraction = (totalByTable / totalCost) || 0
                        return formatPercentage(fraction);
                    }
                },
                data: Array.from(costByCategory.keys()),
            },
            {
                splitLine: { show: false },
                axisTick: { show: false },
                axisLine: { show: false },
                position: 'right',
                axisLabel: {
                    margin: 140,
                    fontSize: 14,
                    fontWeight: 'normal',
                    verticalAlign: 'middle',
                    align: 'right',
                    formatter: (value: string) => {
                        const totalByTable = totalsByTable.get(value) ?? 0;
                        const totalPrefixed = totalByTable / averagePrefixed.prefix.multiplier;
                        return `${replaceCurrencyUnitWithSymbol(unitsMapper.mapUnitToConfigured('usd'))} ${totalPrefixed.toFixed(2)} ${averagePrefixed.prefix.prefixNameShort}`
                    }
                },
                data: Array.from(costByCategory.keys()),
            },
        ],
        tooltip: {
            trigger: 'item',
            formatter: (params: { marker: string, name: string, seriesName: string, value: number }) => {
                const totalPerTable = totalsByTable.get(params.name)! / averagePrefixed.prefix.multiplier;
                const fraction = params.value / totalPerTable;
                const html = `
                    <div>
                        ${params.name}<br/>
                        ${params.marker} ${params.seriesName} <b>${formatPercentage(fraction)}</b>
                    </div>
                `;
                return html
            }
        },
        color:[
            colorByCategory.getOrCreate('Labor'),
            colorByCategory.getOrCreate('Material'),
            colorByCategory.getOrCreate('Sub/Service'),
            colorByCategory.getOrCreate('Equipment'),
        ],
        series: Categories.map((name) => {
            const data: number[] = [];
            for (const costs of costByCategory.values()) {
                const value = costs.get(name)!;
                data.push(value / averagePrefixed.prefix.multiplier);
            }
            return {
                name,
                type: 'bar',
                stack: 'total',
                barWidth: `56%`,
                label: {
                    show: false,
                    formatter: (params) => {
                        if (typeof params.value !== 'number') {
                            return '';
                        }
                        const fractionOfTotal = params.value / averagePrefixed.value;
                        if (fractionOfTotal < 0.2) {
                            return '';
                        }

                        const totalPerTable = totalsByTable.get(params.name)! / averagePrefixed.prefix.multiplier;
                        const fraction = params.value / totalPerTable;

                        if (!Number.isFinite(fraction)) {
                            return ''
                        }

                        return formatPercentage(fraction);
                    }
                },
                data,
                tooltip: {
                    trigger: 'item'
                },
            };
        })
    };
    return option;
}

export function createCostBySourceChart(hierarchies: CostHierarchy[], unitsMapper: UnitsMapper) {
    const defaultCurrency = unitsMapper.mapUnitToConfigured('usd');
    const defaultCurrencySymbol = replaceCurrencyUnitWithSymbol(defaultCurrency);
    const data = CostHierarchy.groupCategoriesByCostSource(hierarchies);

    let customBenchmarkOnly = IterUtils.sum(data.customBenchmarkOnly, x => findCostCategoryTotalCost(x));
    let defaultBenchmarkOnly = IterUtils.sum(data.defaultBenchmarkOnly, x => findCostCategoryTotalCost(x));

    let customModelBasedCosts = IterUtils.sum(data.customModelBasedCosts, x => findCostCategoryTotalCost(x));
    let defaultModelBasedCosts = IterUtils.sum(data.defaultModelBasedCosts, x => findCostCategoryTotalCost(x));

    let modelBasedOverridenWithDefaultBenchmark = IterUtils.sum(data.modelBasedOverridenWithDefaultBenchmark, x => findCostCategoryTotalCost(x));
    let modelBasedOverridenWithCustomBenchmark = IterUtils.sum(data.modelBasedOverridenWithCustomBenchmark, x => findCostCategoryTotalCost(x));

    let total = customBenchmarkOnly + defaultBenchmarkOnly + customModelBasedCosts + defaultModelBasedCosts + modelBasedOverridenWithCustomBenchmark + modelBasedOverridenWithDefaultBenchmark;

    // normalize costs
    customBenchmarkOnly = customBenchmarkOnly / total || 0;
    defaultBenchmarkOnly = defaultBenchmarkOnly / total || 0;

    customModelBasedCosts = customModelBasedCosts / total || 0;
    defaultModelBasedCosts = defaultModelBasedCosts / total || 0;

    modelBasedOverridenWithDefaultBenchmark = modelBasedOverridenWithDefaultBenchmark / total || 0;
    modelBasedOverridenWithCustomBenchmark = modelBasedOverridenWithCustomBenchmark / total || 0;

    total = 1;


    interface CategoryDescription {
        title: string[]
        description?: string[]
        color?: string,
        decalSymbol?: 'circle' | 'rect' | 'roundRect' | 'triangle' | 'diamond' | 'pin' | 'arrow' | 'none',
        symbolScale?: number
    }

    const Categories = [
        'ModelBased',
        'ModelBasedCustom',
        'ModelBasedDefault',

        'Benchmark',
        'BenchmarkCustom',
        'BenchmarkDefault',

        'BenchmarkOnly',
        'BenchmarkOnlyCustom',
        'BenchmarkOnlyDefault',
    ] as const;

    type Category = typeof Categories[number];

    const categories = {
        "ModelBased": {
            title: ['Model-based costs'],
            description: ['Costs calculated for', `physical objects as ${defaultCurrencySymbol}/object`],
            color: '#6a9f45'
        },
        "ModelBasedCustom": { title: ['Customized'], color: '#9eca7f' },
        "ModelBasedDefault": { title: ['Default'], decalSymbol: 'triangle', color: '#bbdda3', symbolScale: 1.2 * 0.7 },

        "Benchmark": {
            title: ['Model-based costs', 'overwritten as benchmark'],
            description: ['Costs calculated for physical', `objects as ${defaultCurrencySymbol}/watt`],
            color: '#d89512'
        },
        "BenchmarkCustom": { title: ['Customized'], color: '#ebaa2d' },
        "BenchmarkDefault": { title: ['Default'], decalSymbol: 'rect', color: '#ffd37f', symbolScale: 1 * 0.7 },

        "BenchmarkOnly": {
            title: ['Benchmark-only costs'],
            description: ['Costs not linked to physical', `objects as ${defaultCurrencySymbol}/watt`],
            color: '#026dbb',
        },
        "BenchmarkOnlyCustom": { title: ['Customized'], color: '#008bef' },
        "BenchmarkOnlyDefault": { title: ['Default'], decalSymbol: 'circle', color: '#43aefb', symbolScale: 1 * 0.7 },
    } as { [key in Category]: CategoryDescription };

    const modelBasedValue = customModelBasedCosts + defaultModelBasedCosts;
    const benchmarkValue = modelBasedOverridenWithCustomBenchmark + modelBasedOverridenWithDefaultBenchmark;
    const benchmarkOnlyValue = customBenchmarkOnly + defaultBenchmarkOnly;


    const inner: Array<{ name: Category, value: number }> = [
        { name: "ModelBased", value: modelBasedValue },
        { name: "Benchmark", value: benchmarkValue },
        { name: "BenchmarkOnly", value: benchmarkOnlyValue },
    ]

    const outer: typeof inner = [
        { name: 'ModelBasedCustom', value: customModelBasedCosts },
        { name: 'ModelBasedDefault', value: defaultModelBasedCosts },

        { name: 'BenchmarkCustom', value: modelBasedOverridenWithCustomBenchmark },
        { name: 'BenchmarkDefault', value: modelBasedOverridenWithDefaultBenchmark },

        { name: 'BenchmarkOnlyCustom', value: customBenchmarkOnly },
        { name: 'BenchmarkOnlyDefault', value: defaultBenchmarkOnly },
    ]

    function formatLegendTopCategory(desc: CategoryDescription, cost: number) {
        const percentageStr = formatPercentage(cost).padStart(8);
        const description = [
            '',
            ...(desc.description ?? []).map(x => `{description|${x}}`),
        ].join('\n' + `{transparent|${percentageStr}} `);
        const title = `{normal|${percentageStr}} ${desc.title.map(x => `{title|${x}}`).join('\n' + `{transparent|${percentageStr}} `)}`
        return title + description;
    }
    function formatLegendInner(desc: CategoryDescription, cost: number) {
        const percentageStr = formatPercentage(cost).padStart(8);
        const title = `{normal|${percentageStr}} {normal|${desc.title.join('\n' + `{transparent|${percentageStr}}`)}}`
        return title;
    }

    const options: EChartOptions = {
        animation: false,
        aria: {
            enabled: true,
            decal: {
                show: true,
            },
        },
        title: {
            text: 'Cost Source',
            left: 30,
            textStyle: {
                fontWeight: '600',
                color: 'black',
                fontSize: 16
            }
        },
        grid: {
            top: 10,
            containLabel: true,
            left: 0,
        },
        //tooltip: {
        //    trigger: 'item',
        //    valueFormatter: (value: number) => {
        //        return formatPercentage(value);
        //    }
        //},
        legend: {
            orient: 'vertical',
            left: 'right',
            top: 'center',
            align: 'left',
            selectedMode: false,
            itemGap: 8,
            itemHeight: 18,
            icon: 'path://M 5 0 C 2 0 2 0 2 3 V 15 C 2 18 2 18 5 18 H 23 C 26 18 26 18 26 15 V 2 C 26 0 26 0 23 0 H 5 Z',
            data: [
                { name: 'ModelBased' },
                { name: 'ModelBasedCustom' },
                { name: 'ModelBasedDefault' },

                { name: 'separator1', itemStyle: { opacity: 0 } },

                { name: 'Benchmark' },
                { name: 'BenchmarkCustom' },
                { name: 'BenchmarkDefault' },

                { name: 'separator2', itemStyle: { opacity: 0 } },

                { name: 'BenchmarkOnly' },
                { name: 'BenchmarkOnlyCustom' },
                { name: 'BenchmarkOnlyDefault' },
            ],
            textStyle: {
                //lineHeight: 18,
                rich: {
                    title: {
                        fontWeight: '600',
                        fontSize: 14,
                        lineHeight: 18,
                    },
                    transparent: {
                        color: 'red',
                        opacity: 0,
                    },
                    normal: {
                        fontSize: 12,
                        fontWeights: '400',
                        lineHeight: 14,
                    },
                    description: {
                        fontSize: 12,
                        fontWeights: '400',
                        lineHeight: 14,
                        opacity: 0.6,
                    }
                }
            },
            formatter: (name: Category) => {
                if (name.startsWith('separator')) {
                    return ''
                }
                const desc = categories[name];
                if (name === 'ModelBased') {
                    return formatLegendTopCategory(desc, modelBasedValue);
                } else if (name === 'ModelBasedCustom') {
                    return formatLegendInner(desc, customModelBasedCosts);
                } else if (name === 'ModelBasedDefault') {
                    return formatLegendInner(desc, defaultModelBasedCosts);
                } else if (name === 'Benchmark') {
                    return formatLegendTopCategory(desc, benchmarkValue);
                } else if (name === 'BenchmarkCustom') {
                    return formatLegendInner(desc, modelBasedOverridenWithCustomBenchmark);
                } else if (name === 'BenchmarkDefault') {
                    return formatLegendInner(desc, modelBasedOverridenWithDefaultBenchmark);
                } else if (name === 'BenchmarkOnly') {
                    return formatLegendTopCategory(desc, benchmarkOnlyValue);
                } else if (name === 'BenchmarkOnlyCustom') {
                    return formatLegendInner(desc, customBenchmarkOnly);
                } else if (name === 'BenchmarkOnlyDefault') {
                    return formatLegendInner(desc, defaultBenchmarkOnly);
                }
                return ''
            }
        },
        series: [
            {
                name: 'Percentage of cost',
                type: 'pie',
                radius: ['0', '27%'],
                left: '-45%',
                top: 50,
                label: { show: false },
                itemStyle: {
                    //borderWidth: 1,
                    //borderColor: "#fff",
                    //decal: {
                    //    symbol: 'rect',
                    //}
                },
                data: inner.map((x) => ({
                    ...x,
                    itemStyle: {
                        color: categories[x.name].color,
                    }
                })),
            },
            {
                name: 'Percentage of cost',
                type: 'pie',
                radius: ['43%', '60%'],
                left: '-45%',
                top: 50,
                label: { show: false },
                data: [
                    { name: 'separator1', value: 0 },
                    { name: 'separator2', value: 0 },
                ]
            },
            {
                name: 'Percentage of cost',
                type: 'pie',
                radius: ['43%', '60%'],
                top: 50,
                left: '-45%',
                label: { show: false },
                itemStyle: {
                    //borderWidth: 1,
                    //borderColor: "#fff",
                },
                data: outer.map((x) => ({
                    ...x,
                    itemStyle: {
                        color: categories[x.name].color,
                        decal: {
                            symbol: categories[x.name].decalSymbol ?? 'none',
                            color: 'white',
                            rotation: Math.PI/180 * -30,
                            symbolSize: categories[x.name].symbolScale ?? 1,
                        }
                    }
                })),
            }
        ]
    }
    return options
}

function formatPercentage(fraction: number) {
    return Number(fraction).toLocaleString(
        undefined,
        {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }
    )
}

function formatCostPerWatt(cost: number) {
    return Number(cost).toLocaleString(
        undefined,
        {
            minimumFractionDigits: 2,
            maximumFractionDigits: 4
        }
    )
}
