import { Vector2 } from "math-ts";
import { Object2D, VectorPrimitiveCircle, VectorPrimitivePath, VectorPrimitivePathDescription, VectorPrimitiveText, createTextLabelWithPointer } from "vector-graphic";
import type { TransformerDescription } from "../types";
import { arrowTemplate, labelFontSize, transformerTemplate } from "./config";
import { helperLineWidth, normalTextFontSize, wireWidth } from "src/sld/templates/config";

export function createTransformerObject2DFromDescription(description: TransformerDescription)
    : TransformerObject2D
{
    const obj = new TransformerObject2D();
    obj.name = 'transformer-group'

    const transformer = transformerTemplate.clone(true);
    transformer.rotation += Math.PI / 2;
    transformer.updateMatrix();

    obj.add(transformer);

    // add transformer wiring
    {
        const paths: VectorPrimitivePathDescription[] = [];
        const aabb = obj.recalculateAabb();
        const offset = new Vector2(aabb.width() / 2, aabb.height() / 5);
        const wireEndingOnTransformerPoint = new Vector2(
            aabb.centerX(),
            aabb.centerY() + offset.y
        );
        const wireCrossPoint = new Vector2(aabb.centerX(), aabb.min.y - offset.y);
        const pt0 = wireCrossPoint;
        for (const dir of [1, -1]) {
            const pt1 = new Vector2(pt0.x + dir * offset.x, pt0.y)
            const pt2 = new Vector2(pt1.x, pt1.y - offset.y / 2);

            const arrow = arrowTemplate.clone(true)
            arrow.position.copy(pt2);
            arrow.rotation = Math.PI / 2;
            arrow.updateMatrix()

            const pt3 = arrow.point.clone().applyMatrix3(arrow.matrix);
            const pt4 = new Vector2(pt3.x, pt3.y - offset.y / 4)
            const pt5 = new Vector2(pt4.x + dir * offset.x, pt4.y)

            paths.push(
                new VectorPrimitivePathDescription([pt0, pt1, pt2]),
                new VectorPrimitivePathDescription([pt3, pt4, pt5]),
            )

            obj.add(arrow)
        }
        paths.push(new VectorPrimitivePathDescription([
            wireEndingOnTransformerPoint,
            wireCrossPoint
        ]))

        const pathPrimitive = new VectorPrimitivePath({
            paths,
            strokeWidth: wireWidth,
        })
        obj.addPrimitiveAndExpandAabb(pathPrimitive);

        // add circle to the end of wiring
        const circle = new VectorPrimitiveCircle({
            cx: wireEndingOnTransformerPoint.x,
            cy: wireEndingOnTransformerPoint.y,
            radius: wireWidth,
            fill: 'black',
        })
        obj.addPrimitiveAndExpandAabb(circle);
    }
    // create start, end anchors
    {
        const aabb = obj.recalculateAabb();

        obj.leftConnection.set(aabb.min.x, aabb.min.y)
        obj.rightConnection.set(aabb.max.x, aabb.min.y)
    }

    // draw transformer label
    {
        const aabb = obj.aabb;
        const transformerLabelPrimitive = new VectorPrimitiveText({
            x: aabb.centerX(),
            y: aabb.max.y + labelFontSize,
            fontSize: labelFontSize,
            text: description.text,
            anchor: 'middle',
            verticalAlignment: 'hanging',
        })
        obj.addPrimitiveAndExpandAabb(transformerLabelPrimitive);
    }

    // draw connection label
    if (description.connectionToParent) {
        const aabb = obj.recalculateAabb();
        const label = createTextLabelWithPointer({
            text: description.connectionToParent.text,
            fontSize: normalTextFontSize,
            offset: 50,
            pointerWidth: helperLineWidth,
            angle: Math.PI / 6 * 5,
        })
        label.position.set(aabb.max.x, aabb.min.y);
        label.updateMatrix()
        obj.addAndExpandAabb(label);
    }

    obj.recalculateAabb();

    return obj;
}

export class TransformerObject2D extends Object2D {
    leftConnection: Vector2
    rightConnection: Vector2
    constructor() {
        super()
        this.leftConnection = new Vector2()
        this.rightConnection = new Vector2()
    }

    static clone(
        recursive: boolean,
        source: TransformerObject2D,
        target?: TransformerObject2D
    ): TransformerObject2D {
        target = target ?? new TransformerObject2D();
        target.rightConnection.copy(source.rightConnection);
        target.leftConnection.copy(source.leftConnection);
        Object2D.clone(recursive, source, target);
        return target;
    }

    clone(recursive = false) {
        return TransformerObject2D.clone(recursive, this)
    }
}

