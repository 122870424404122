import type { LazyVersioned, PollWithVersionResult, ScopedLogger} from "engine-utils-ts";
import { Allocated, Deleted, LazyDerived, StreamAccumulator, VersionedInvalidator } from "engine-utils-ts";
import type { EntityId } from "verdata-ts";
import type { EntitiesBase } from "./EntitiesBase";
import type { EntitiesCollectionUpdates} from "./EntitiesCollectionUpdates";
import { EntitiesUpdated } from "./EntitiesCollectionUpdates";


export class EntitiesLazyLists<T, IdT, Flags extends number> {

    readonly logger: ScopedLogger;
    readonly _entities: EntitiesBase<T, EntityId<IdT>, number, Flags>;
    readonly _updatesAccum: StreamAccumulator<EntitiesCollectionUpdates<EntityId<IdT>, number>>;
    readonly _udpatesApplicator: LazyVersioned<null>;

    readonly _knownTypes: KnownTypesLazyVersioned;

    private _allTypesList?: PerTypeList<EntityId<IdT>, T>;

    readonly _perTypeIdent = new Map<string, PerTypeList<EntityId<IdT>, T>>();
    readonly _perIdTypes = new Map<EntityId<IdT>, string>();

    readonly globalFlagsFilter: number;
    readonly permanentTypeExtractor: (entity: T) => string;

    constructor(args: {
        entities: EntitiesBase<T, EntityId<IdT>, number, any>,
        permanentTypeExtractor: (entity: T) => string,
        ignoreFlags?: Flags,
    }) {
        this.logger = args.entities.logger.newScope('perType-lazy-lists');
        this._entities = args.entities;
        this._updatesAccum = new StreamAccumulator(args.entities.updatesStream);

        const permanentTypeExtractor = args.permanentTypeExtractor;
        this.permanentTypeExtractor = args.permanentTypeExtractor;

        this.globalFlagsFilter = (args.ignoreFlags ?? 0) ^ 0xFF_FF_FF_F;

        const entities = args.entities;
        this._udpatesApplicator = LazyDerived.new0(
            this._entities.identifier + ' - lazyVer lists',
            [this._updatesAccum],
            () => {
                const updates = this._updatesAccum.consume();
                if (!updates) {
                    return null;
                }
                const perTypeIdentDiffsAccumulated = new Map<string, number>();
                let allTypesDiffsAccumulated: number | undefined = undefined;
                for (const update of updates) {
                    if (update instanceof Allocated) {
                        for (const id of update.ids) {
                            const entity = entities.perId.get(id);
                            if (entity) {
                                if (this._allTypesList) {
                                    this._allTypesList.addAllocated(id, entity);
                                }
                                
                                const typeIdent = permanentTypeExtractor(entity);
                                const perType = this._perTypeIdent.get(typeIdent);
                                if (perType) {
                                    this._perIdTypes.set(id, typeIdent);
                                    perType.addAllocated(id, entity);
                                }
                                this._knownTypes.addType(typeIdent);
                            }
                        }
                        
                    } else if (update instanceof EntitiesUpdated) {
                        if ((update.allFlagsCombined & this.globalFlagsFilter) === 0) {
                            continue;
                        }
                        allTypesDiffsAccumulated = allTypesDiffsAccumulated ?? 0 | update.allFlagsCombined;

                        for (let i = 0; i < update.ids.length; ++i) {
                            const id = update.ids[i];
                            const diff = update.diffs[i];
                            const typeIdent = this._perIdTypes.get(id);
                            if (typeIdent === undefined) {
                                continue;
                            }
                            perTypeIdentDiffsAccumulated.set(typeIdent, (perTypeIdentDiffsAccumulated.get(typeIdent) ?? 0) | diff);
                        }
                        
                    } else if (update instanceof Deleted) {
                        for (const id of update.ids) {
                            if (this._allTypesList) {
                                this._allTypesList.delete(id);
                            }

                            const typeIdent = this._perIdTypes.get(id);
                            if (typeIdent !== undefined) {
                                this._perIdTypes.delete(id);
                                const perType = this._perTypeIdent.get(typeIdent);
                                if (perType) {
                                    perType.delete(id);
                                }
                            }
                        }
                    } else {
                        this.logger.error('unrecognized entities update', update);
                    }
                }

                if(allTypesDiffsAccumulated !== undefined && this._allTypesList) {
                    this._allTypesList.markUpdated(allTypesDiffsAccumulated);
                }

                for (const [typeIdent, diff] of perTypeIdentDiffsAccumulated) {
                    const perType = this._perTypeIdent.get(typeIdent);
                    if (perType) {
                        perType.markUpdated(diff);
                    }
                }
                return null;
            }
        );
        this._knownTypes = new KnownTypesLazyVersioned(this._udpatesApplicator);
    }

    getLazyListOf(args: {type_identifier: string, relevantUpdateFlags?: Flags}): LazyVersioned<[EntityId<IdT>, T][]> {
        let perType = this._perTypeIdent.get(args.type_identifier);
        if (perType === undefined) {
            this._udpatesApplicator.poll();// apply queued updates immidiately

            perType = new PerTypeList(args.type_identifier, this._udpatesApplicator);
            this._perTypeIdent.set(args.type_identifier, perType);

            // add all existing entities of this type, and save their types
            for (const [id, entity] of this._entities.perId) {
                if (this._perIdTypes.has(id)) {
                    continue;
                }
                const typeIdent = this.permanentTypeExtractor(entity);
                if (typeIdent === perType.typeIdent) {
                    this._perIdTypes.set(id, typeIdent);
                    perType._entitiesOfThisType.set(id, entity);
                }
            }
        }
        const flagsMask = this._unionRelevantAndGlobalUpdateFlags(args);
        
        return perType.getLazyVersionedForFlags(flagsMask);
    }

    private _unionRelevantAndGlobalUpdateFlags(args: { relevantUpdateFlags?: Flags; }) {
        if (args.relevantUpdateFlags && (args.relevantUpdateFlags & this.globalFlagsFilter) != args.relevantUpdateFlags) {
            this.logger.warn('some flags will be ignored because of global filter', args.relevantUpdateFlags, this.globalFlagsFilter);
        }
        const flagsMask = (args.relevantUpdateFlags ?? 0xFF_FF_FF_F) & this.globalFlagsFilter;
        return flagsMask;
    }

    getLazyListOfAll(args: {relevantUpdateFlags: Flags}) : LazyVersioned<[EntityId<IdT>, T][]>{
        if(!this._allTypesList) {
            this._allTypesList = new PerTypeList('all', this._udpatesApplicator);
            for (const [id, entity] of this._entities.perId) {
                this._allTypesList._entitiesOfThisType.set(id, entity);
            }
        }

        const flagsMask = this._unionRelevantAndGlobalUpdateFlags(args);

        return this._allTypesList.getLazyVersionedForFlags(flagsMask);
    }

    getLazyKnownTypes(): LazyVersioned<string[]> {
        return this._knownTypes;
    }

    dispose() {
        this._updatesAccum.dispose();
    }
}

type PerFlagsInvalidator<IdT, T> = {
    flagsMask: number;
    invalidator: VersionedInvalidator;
    lazyResult: LazyVersioned<[IdT, T][]>
}


class PerTypeList<IdT, T> {

    readonly typeIdent: string;
    readonly _entitiesOfThisType = new Map<IdT, T>();
    readonly _udpatesApplicator: LazyVersioned<any>;
    readonly _flagsIndependentInvalidtor = new VersionedInvalidator();
    readonly _perFlagsInvalidators: PerFlagsInvalidator<IdT, T>[] = [];

    constructor(typeIdent: string, updateApplicator: LazyVersioned<any>) {
        this.typeIdent = typeIdent;
        this._udpatesApplicator = updateApplicator;
    }

    getLazyVersionedForFlags(flags: number): LazyVersioned<[IdT, T][]> {
        for (const perFlags of this._perFlagsInvalidators) {
            if (perFlags.flagsMask === flags) {
                return perFlags.lazyResult;
            }
        }
        const flagsInvalidator = new VersionedInvalidator();
        const perFlags: PerFlagsInvalidator<IdT, T> = {
            flagsMask: flags,
            invalidator: flagsInvalidator,
            lazyResult: LazyDerived.new0(
                `${this.typeIdent}-lazy-list-${flags}`,
                [this._udpatesApplicator, this._flagsIndependentInvalidtor, flagsInvalidator],
                () => {
                    return Object.freeze(Array.from(this._entitiesOfThisType.entries())) as [IdT, T][];
                }
            ).withoutEqCheck(),
        };
        this._perFlagsInvalidators.push(perFlags);
        return perFlags.lazyResult;
    }

    addAllocated(id: IdT, entity: T) {
        this._entitiesOfThisType.set(id, entity);
        this._flagsIndependentInvalidtor.invalidate();
    }

    markUpdated(diff: number) {
        for (const perFlags of this._perFlagsInvalidators) {
            if (perFlags.flagsMask & diff) {
                perFlags.invalidator.invalidate();
            }
        }
    }

    delete(id: IdT) {
        this._entitiesOfThisType.delete(id);
        this._flagsIndependentInvalidtor.invalidate();
    }

}


class KnownTypesLazyVersioned implements LazyVersioned<string[]> {

    private readonly _knownTypes: Set<string> = new Set();
    private _version = 0;
    private _result: string[] | null = null;
    private _updatesApplicator: LazyVersioned<any>;

    constructor(
        updateApplicator: LazyVersioned<any>,
    ) {
        this._updatesApplicator = updateApplicator;
    }

    addType(typeIdent: string) {
        if (this._knownTypes.has(typeIdent)) {
            return;
        }
        this._knownTypes.add(typeIdent);
        this._version += 1;
        this._result = null;
    }

    version() {
        this._updatesApplicator.poll();
        return this._version;
    }

    poll(): readonly string[] {
        this._updatesApplicator.poll();
        if (this._result === null) {
            this._result = Array.from(this._knownTypes);
            this._result.sort();
            Object.freeze(this._result);
        }
        return this._result;
    }

    pollWithVersion(): PollWithVersionResult<string[]> {
        return { value: this.poll() as string[], version: this.version() };
    }
}
