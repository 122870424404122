import type { UndoStack } from 'engine-utils-ts';
import { IterUtils, LegacyLogger } from 'engine-utils-ts';
import type { Vector3 } from 'math-ts';
import { Aabb } from 'math-ts';

import type { IdInEntityLocal, LocalIdsEdge } from '../collections/LocalIds';
import { LocalIdsCounter } from '../collections/LocalIds';
import { BimGeometryType } from './BimGeometries';
import type { BimGeometryBase } from './BimGeometriesBase';
import { BimGeometriesBase } from './BimGeometriesBase';

export interface SegmentInterp {
	clone(): this;
}
export class SegmentInterpLinear implements SegmentInterp {
	clone() {
		return this;
	}
}
export const SegmentInterpLinearG = new SegmentInterpLinear();


export class GraphGeometry implements BimGeometryBase {

    constructor(
        public readonly points: Map<IdInEntityLocal, Vector3> = new Map(),
        public readonly edges: Map<LocalIdsEdge, SegmentInterp> = new Map(),
    ) {
    }

	calcAabb(): Aabb {
		return Aabb.empty().setFromPoints(this.points.values());
	}

    graphEdgesAsFlatTuples(): IdInEntityLocal[] {
        const res: IdInEntityLocal[] = [];
        for (const edge of this.edges.keys()) {
			const [id1, id2] = LocalIdsCounter.edgeToTuple(edge);
            if (this.points.has(id1) && this.points.has(id2)) {
                res.push(id1, id2);
            } else {
                LegacyLogger.deferredError('graphgeo edges: invalid edge id', [edge,id1, id2]);
            }
        }
        return res;
    }

    checkForErrors(errors: string[]): void {
        // quick check of ids
        for (const p of this.points.values()) {
            if (!p.isFinite()) {
                errors.push(`point is not finite ${p.asArray().join()}`)
            }
        }
		for (const edge of this.edges.keys()) {
			const [id1, id2] = LocalIdsCounter.edgeToTuple(edge);
			if (this.points.get(id1) === undefined || this.points.get(id2) === undefined) {
				errors.push(`invalid edge->point reference ${id1} ${id2}`);
			}
		}
    }

	getPointsConnectedToPoint(pointId: IdInEntityLocal): IdInEntityLocal[] {
		const res: IdInEntityLocal[] = [];
		for (const edge of this.edges.keys()) {
			const [id1, id2] = LocalIdsCounter.edgeToTuple(edge);
			if (id1 === pointId) {
				res.push(id2);
			} else if (id2 === pointId) {
				res.push(id1);
			}
		}
		return res;
	}

	clearOutNonReferencedPoints() {
		const edgesIds = new Set<IdInEntityLocal>();
		for (const edge of this.edges.keys()) {
			const [id1, id2] = LocalIdsCounter.edgeToTuple(edge);
			edgesIds.add(id1);
			edgesIds.add(id2);
		}
		for (const id of this.points.keys()) {
			if (!edgesIds.has(id)) {
				this.points.delete(id);
			}
		}
	}

	iterEdgesPoints(): Iterable<[point1: Vector3, point2: Vector3, edge: LocalIdsEdge, interp: SegmentInterp]> {
		return IterUtils.iterMap(this.edges, ([edge, interp]) => {
			const [id1, id2] = LocalIdsCounter.edgeToTuple(edge);
			const p1 = this.points.get(id1)!;
			const p2 = this.points.get(id2)!;
			return [p1, p2, edge, interp];
		});
	}

    static localIdsMaxiumum(self: GraphGeometry): number {
        return IterUtils.max(self.points.keys()) ?? 0;
    }

	*iterSegments(): Iterable<[Vector3, Vector3, SegmentInterp]> {
		for (const [edgeId, interp] of this.edges) {
			const [pointId1, pointId2] = LocalIdsCounter.edgeToTuple(edgeId);
			const point1 = this.points.get(pointId1);
			const point2 = this.points.get(pointId2);
			if (point1 && point2) {
				yield [point1, point2, interp];
			}
		}
	}
}


export class GraphGeometries extends BimGeometriesBase<GraphGeometry> {

    constructor(
        undoStack?: UndoStack,
    ) {
        super({
            identifier: "graph-geometries",
            idsType: BimGeometryType.GraphGeometry,
            undoStack,
            T_Constructor: GraphGeometry,
            localIdsMaximumExtractor: GraphGeometry.localIdsMaxiumum
        });
    }

    checkForErrors(t: GraphGeometry, errors: string[]): void {
        t.checkForErrors(errors);
    }

}
