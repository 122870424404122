import { LegacyLogger } from "engine-utils-ts";
import { Vector3, Vector2, KrMath } from "math-ts";

export function calcInTriangleZAt(p1: Vector3, p2: Vector3, p3: Vector3, coords: Vector2): number | null
{
    const dz = p1.x * (p2.y - p3.y) + p2.x * (p3.y - p1.y) + p3.x * (p1.y - p2.y);
    if (Math.abs(dz) < 1e-5) {
        return null;
    }
    const dx = p1.y * (p2.z - p3.z) + p2.y * (p3.z - p1.z) + p3.y * (p1.z - p2.z);
    const dy = p1.x * (p2.z - p3.z) + p2.x * (p3.z - p1.z) + p3.x * (p1.z - p2.z);
    return p1.z - ((coords.x - p1.x) * dx - (coords.y - p1.y) * dy) / dz;
}

export const tryCalcInTriangleZAt = function() {
    const baryCoordsV = new Vector3();
    const v0 = new Vector2();
    const v1 = new Vector2();
    const v2 = new Vector2();
    return function tryCalcInTriangleZAt(p0: Vector3, p1: Vector3, p2: Vector3, coords: Vector2)
        : {distToSample: number, z: number} | null
    {
        v0.x = p0.x, v0.y = p0.y;
        v1.x = p1.x, v1.y = p1.y;
        v2.x = p2.x, v2.y = p2.y;
        const baryCoords = getBarycoord2d(coords, v0, v1, v2, baryCoordsV);
        if (baryCoords.x >= -0.001 && baryCoords.y >= -0.001 && baryCoords.z >= -0.001) {

            const cx = KrMath.clamp(baryCoords.x, 0, 1);
            const cy = KrMath.clamp(baryCoords.y, 0, 1);
            const cz = KrMath.clamp(baryCoords.z, 0, 1);


            if (cx !== baryCoords.x || cy !== baryCoords.y || cz !== baryCoords.z) {

                // in case our sample is slightly outside of triangle
                // calculate z with clamped barycoords
                // and distance to actual barycoords 2d coord

                const baryX = baryCoords.x * p0.x + baryCoords.y * p1.x + baryCoords.z * p2.x;
                const baryY = baryCoords.x * p0.y + baryCoords.y * p1.y + baryCoords.z * p2.y;
                
                const cBaryX = cx * p0.x + cy * p1.x + cz * p2.x;
                const cBaryY = cx * p0.y + cy * p1.y + cz * p2.y;

                const dx = baryX - cBaryX;
                const dy = baryY - cBaryY;
                const dist = Math.sqrt(dx * dx + dy * dy);
                
                return {
                    z: cx * p0.z + cy * p1.z + cz * p2.z,
                    distToSample: dist
                }
            } else {
                return {
                    z: baryCoords.x * p0.z + baryCoords.y * p1.z + baryCoords.z * p2.z,
                    distToSample: 0,
                }
            }
        }
        return null;
    }
}();

export const getBarycoord2d = function () {

    const v0 = new Vector2();
    const v1 = new Vector2();
    const v2 = new Vector2();

    return function getBarycoord2d( point: Vector2, a: Vector2, b: Vector2, c: Vector2, target: Vector3 ): Vector3 {

        v0.subVectors( c, a );
        v1.subVectors( b, a );
        v2.subVectors( point, a );

        const dot00 = v0.dot( v0 );
        const dot01 = v0.dot( v1 );
        const dot02 = v0.dot( v2 );
        const dot11 = v1.dot( v1 );
        const dot12 = v1.dot( v2 );

        const denom = ( dot00 * dot11 - dot01 * dot01 );

        // collinear or singular triangle
        if ( denom === 0 ) {

            // arbitrary location outside of triangle?
            // not sure if this is the best idea, maybe should be returning undefined
            return target.set( - 2, - 1, - 1 );

        }

        const invDenom = 1 / denom;
        const u = ( dot11 * dot02 - dot01 * dot12 ) * invDenom;
        const v = ( dot00 * dot12 - dot01 * dot02 ) * invDenom;

        // barycentric coordinates must always sum to 1
        return target.set( 1 - u - v, v, u );
    };

}();

export function triangleArea(v0: Vector3, v1: Vector3, v2: Vector3) {
    const sideLength1 = v0.distanceTo(v1);
    const sideLength2 = v1.distanceTo(v2);
    const sideLength3 = v2.distanceTo(v0);
    const perimeter = sideLength1 + sideLength2 + sideLength3;
    //Heron's formula
    const s = perimeter * 0.5;
    const area = Math.sqrt(s * (s - sideLength1) * (s - sideLength2) * (s - sideLength3));
    if (Number.isNaN(area)) {
        LegacyLogger.deferredWarn('triangle NaN area', [Math.min(sideLength1, sideLength2, sideLength3), perimeter, v0.clone(), v1.clone(), v2.clone()]);
        return 0;
    }
    return area;
}
