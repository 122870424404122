// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { RangeT } from './range.js';
import { Range } from './range.js';


export class NumericValue implements flatbuffers.IUnpackableObject<NumericValueT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):NumericValue {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsNumericValue(bb:flatbuffers.ByteBuffer, obj?:NumericValue):NumericValue {
  return (obj || new NumericValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsNumericValue(bb:flatbuffers.ByteBuffer, obj?:NumericValue):NumericValue {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new NumericValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

step():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

discreteVariants(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readFloat64(this.bb!.__vector(this.bb_pos + offset) + index * 8) : 0;
}

discreteVariantsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

discreteVariantsArray():Float64Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? new Float64Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

range(obj?:Range):Range|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new Range()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

unit():string|null
unit(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
unit(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startNumericValue(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addValue(builder:flatbuffers.Builder, value:number) {
  builder.addFieldFloat64(0, value, 0.0);
}

static addStep(builder:flatbuffers.Builder, step:number) {
  builder.addFieldFloat64(1, step, 0.0);
}

static addDiscreteVariants(builder:flatbuffers.Builder, discreteVariantsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, discreteVariantsOffset, 0);
}

static createDiscreteVariantsVector(builder:flatbuffers.Builder, data:number[]|Float64Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createDiscreteVariantsVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createDiscreteVariantsVector(builder:flatbuffers.Builder, data:number[]|Float64Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(8, data.length, 8);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addFloat64(data[i]!);
  }
  return builder.endVector();
}

static startDiscreteVariantsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(8, numElems, 8);
}

static addRange(builder:flatbuffers.Builder, rangeOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, rangeOffset, 0);
}

static addUnit(builder:flatbuffers.Builder, unitOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, unitOffset, 0);
}

static endNumericValue(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): NumericValueT {
  return new NumericValueT(
    this.value(),
    this.step(),
    this.bb!.createScalarList<number>(this.discreteVariants.bind(this), this.discreteVariantsLength()),
    (this.range() !== null ? this.range()!.unpack() : null),
    this.unit()
  );
}


unpackTo(_o: NumericValueT): void {
  _o.value = this.value();
  _o.step = this.step();
  _o.discreteVariants = this.bb!.createScalarList<number>(this.discreteVariants.bind(this), this.discreteVariantsLength());
  _o.range = (this.range() !== null ? this.range()!.unpack() : null);
  _o.unit = this.unit();
}
}

export class NumericValueT implements flatbuffers.IGeneratedObject {
constructor(
  public value: number = 0.0,
  public step: number = 0.0,
  public discreteVariants: (number)[] = [],
  public range: RangeT|null = null,
  public unit: string|Uint8Array|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const discreteVariants = NumericValue.createDiscreteVariantsVector(builder, this.discreteVariants);
  const range = (this.range !== null ? this.range!.pack(builder) : 0);
  const unit = (this.unit !== null ? builder.createString(this.unit!) : 0);

  NumericValue.startNumericValue(builder);
  NumericValue.addValue(builder, this.value);
  NumericValue.addStep(builder, this.step);
  NumericValue.addDiscreteVariants(builder, discreteVariants);
  NumericValue.addRange(builder, range);
  NumericValue.addUnit(builder, unit);

  return NumericValue.endNumericValue(builder);
}
}
