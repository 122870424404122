import {
    ProjectNetworkClient, ScopedLogger, TasksRunner, VersionedInvalidator,
} from "engine-utils-ts";
import { UiBindings, NotificationDescription, NotificationType } from "ui-bindings";
import type { PersistedCollectionConfig} from "verdata-ts";
import { VerDataSyncer, VerDataUiBindings, VerDataIdentifier } from "verdata-ts";
import type {
    AssetCatalogItemProps, UnitsMapper} from "..";
import { EntitiesPersisted
} from "..";
import type { PropertiesGroupFormatters } from "../bimDescriptions/PropertiesGroupFormatter";
import { notificationSource } from '../Notifications';
import { createKeyPropertiesGroupFormatters } from "../runtime/solar/KeyPropertiesGroupFormatters";
import type { Asset } from "./Asset";
import type { AssetId } from "./AssetCollection";
import { AssetCollection } from "./AssetCollection";
import { AssetsSerializer } from "./AssetsSerializer";
import type { CatalogItem } from "./CatalogItem";
import type { CatalogItemId, CatalogItemIdType } from "./CatalogItemCollection";
import { CatalogItemCollection } from "./CatalogItemCollection";
import { CatalogItemsSerializer } from "./CatalogItemsSerializer";
import type { CatalogItemsUiLabels} from "./CatalogItemsUiLabels";
import { createCatalogItemsUiLabels } from "./CatalogItemsUiLabels";
import { importDefaultPack } from "./importDefaultPack";

const assetCollPersistConfig: PersistedCollectionConfig = {
    identifier: 'assets',
    objectCountInBatchHint: 10000,
    loadAfter: [],
    loadInOneTickWith: ['catalog'],
};

const catalogCollPersistConfig: PersistedCollectionConfig = {
    identifier: 'catalog',
    objectCountInBatchHint: 10000,
    loadAfter: ['assets'],
    loadInOneTickWith: ['assets'],
};

export class Catalog {

    public readonly logger = new ScopedLogger('Catalog');

    public readonly unitsMapper: UnitsMapper;
    public readonly baseNetwork: ProjectNetworkClient;

    public readonly assets: AssetCollection;
    public readonly catalogItems: CatalogItemCollection;

    public readonly keyPropertiesGroupFormatters: PropertiesGroupFormatters;
    public readonly catalogItemsUiLabels: CatalogItemsUiLabels;

    invalidator: VersionedInvalidator;


    syncer?: VerDataSyncer;
    getSyncer() {
        if (this.syncer === undefined) {
            throw new Error('Catalog was not initialized');
        }
        return this.syncer;
    }

    tasksRunner = new TasksRunner(this.logger).withSelfRunning();

    uiBindings = new UiBindings(this.logger);

    constructor(baseNetwork: ProjectNetworkClient, unitsMapper: UnitsMapper) {
        this.unitsMapper = unitsMapper;
        this.baseNetwork = baseNetwork;

        this.keyPropertiesGroupFormatters = createKeyPropertiesGroupFormatters(this.unitsMapper);
        this.catalogItemsUiLabels = createCatalogItemsUiLabels(this);


        this.assets = new AssetCollection();
        this.catalogItems = new CatalogItemCollection(this.assets);
        this.invalidator = new VersionedInvalidator([
            this.assets,
            this.catalogItems,
            unitsMapper,
        ]);
    }


    dispose() {
        this.catalogItems.dispose();
        this.assets.dispose();
        this.syncer?.dispose();
        this.tasksRunner.dispose();
    }

    async initAsync(companyId: string, catalogVersionId?: number | null) {
        const network = new ProjectNetworkClient({
            ...this.baseNetwork.config,
            basePath: 'api/company-verdata/' + companyId,
        });
        this.syncer = await VerDataSyncer.newAsync({
            identifier: VerDataIdentifier.Catalog,
            verbose: false
        });
        VerDataUiBindings.addCatalogUiBindings(this.syncer.uiBindings, this.syncer);
        this.invalidator.invalidate();
        this.syncer.init(network)
        const assetCollPersist =
            new EntitiesPersisted<Asset>({
                entities: this.assets,
                serializer: new AssetsSerializer(),
            });
        const catalogCollPersist =
            new EntitiesPersisted<CatalogItem>({
                entities: this.catalogItems,
                serializer: new CatalogItemsSerializer(),
            });
        await this.syncer.attachCollectionForSync(assetCollPersistConfig, assetCollPersist);
        await this.syncer.attachCollectionForSync(catalogCollPersistConfig, catalogCollPersist);

        if (catalogVersionId) {
            await this.syncer.loadVersion(catalogVersionId);
        } else {
            await this.syncer.loadLastVersion();
        }
    }

    async importDefaultAssetsPack() {
        const task = this.tasksRunner.newLongTask({
            taskTimeoutMs: 600_000,
            defaultGenerator: importDefaultPack(this, this.baseNetwork)
        });
        try {
            this.uiBindings?.addNotification(NotificationDescription.newWithTask({
                source: notificationSource,
                key: 'importDefaultAssets',
                type: NotificationType.Info,
                taskDescription: { task },
                addToNotificationsLog: true
            }))
            await task.asPromise();
        } catch (e) {
            console.error(e);
        }
    }

    async cleanCatalog() {
        this.assets.deleteAllExcept(new Set(), {});
        this.catalogItems.deleteAllExcept(new Set(), {});
    }

    async removeCatalogItems(ids: CatalogItemId[]) {
        this.catalogItems.delete(ids);
        // save
        for (const id of ids) {
            const item = this.catalogItems.perId.get(id);
            if (!item) {
                return;
            }
            if (item.typeIdentifier === 'asset') {
                const assetCatalogItem = item.as<AssetCatalogItemProps>();
                this.assets.delete([assetCatalogItem.properties.asset_id.value]);
            }
            this.catalogItems.delete(ids);
        }
    }

    async removeAssets(ids: AssetId[]) {
        const idsSet = new Set(ids);
        const catalogItemsToRemove: CatalogItemIdType[] = []
        for (const [catalogItemId, catalogItem] of this.catalogItems.perId) {
            if (
                catalogItem.typeIdentifier === 'asset' &&
                idsSet.has(catalogItem.as<AssetCatalogItemProps>().properties.asset_id.value)
            ) {
                catalogItemsToRemove.push(catalogItemId);
            }
        }
        // remove related catalog items
        this.catalogItems.delete(catalogItemsToRemove);
        // remove assets
        this.assets.delete(ids);
    }

    async allocate(assets: [AssetId, Asset][], catalogItems: [CatalogItemId, CatalogItem][]) {
        this.catalogItems.allocate(catalogItems);
        this.assets.allocate(assets);
    }
}
