let _id = 0;
export class Collection<T, ID> {
    items: Map<ID, T>;
    constructor() {
        this.items = new Map();
    }
    genId(): ID {
        return <any>_id++ as ID;
    }
    clear() {
        this.items.clear();
    }
}
