import type { AssetCatalogItemProps, Catalog, CatalogItem, NumberProperty} from "bim-ts";
import type { PUI_Builder } from "ui-bindings";

export function modulePerWattCost(
    builder: PUI_Builder,
    propName: string,
    prop: NumberProperty,
    onChange: (val: number) => void,
    catalogItem: CatalogItem,
    catalog: Catalog,
): boolean {
    const sortKey = propName[0].toUpperCase() === propName[0] ? 2 : 1;
    if (propName !== 'price_each') return false;
    if (catalogItem.typeIdentifier !== 'asset') return false;
    const assetId = catalogItem.as<AssetCatalogItemProps>().properties.asset_id.value;
    const si = catalog.assets.sceneInstancePerAsset
        .getAssetAsSceneInstance(assetId)
    if (!si || si.type_identifier !== 'pv-module') return false;
    function getMaxPowerWatt(): number | null {
        const si = catalog.assets.sceneInstancePerAsset
            .getAssetAsSceneInstance(assetId)
        if (!si || si.type_identifier !== 'pv-module') return null;
        const maxPower = si.properties.get('module | maximum_power')?.as('W');
        if (maxPower === undefined) return null;
        return maxPower;
    }
    const maxPowerWatt = getMaxPowerWatt();
    if (!maxPowerWatt) return false;
    const pricePerWatt = prop.value / maxPowerWatt;
    const price = prop.value * 1;

    builder.addNumberProp({
      name: propName,
      unit: prop.unit,
      value: price,
      onChange: (newVal) => {
        onChange(newVal);
      },
      typeSortKeyOverride: sortKey,
    });
    builder.addNumberProp({
      name: 'price_per_watt',
      value: pricePerWatt,
      unit: prop.unit,
      onChange: (newVal) => {
        const newPrice = newVal * maxPowerWatt;
        onChange(newPrice);
      },
      typeSortKeyOverride: sortKey,
    });
    return true;
}
export function transformerPerWattCost(
    builder: PUI_Builder,
    propName: string,
    prop: NumberProperty,
    onChange: (val: number) => void,
    catalogItem: CatalogItem,
    catalog: Catalog,
): boolean {
    const sortKey = propName[0].toUpperCase() === propName[0] ? 2 : 1;
    if (propName !== 'price_each') return false;
    if (catalogItem.typeIdentifier !== 'asset') return false;
    const assetId = catalogItem.as<AssetCatalogItemProps>().properties.asset_id.value;
    const si = catalog.assets.sceneInstancePerAsset
        .getAssetAsSceneInstance(assetId)
    if (!si || si.type_identifier !== 'transformer') return false;
    function getMaxPowerWatt(): number | null {
        const si = catalog.assets.sceneInstancePerAsset
            .getAssetAsSceneInstance(assetId)
        if (!si || si.type_identifier !== 'transformer') return null;
        const maxPower = si.properties.get('output | power')?.as('kW');
        if (maxPower === undefined) return null;
        return maxPower;
    }
    const maxPowerWatt = getMaxPowerWatt();
    if (!maxPowerWatt) return false;
    const pricePerWatt = prop.value / maxPowerWatt;
    const price = prop.value * 1;

    builder.addNumberProp({
      name: propName,
      value: price,
      unit: prop.unit,
      onChange: (newVal) => {
        onChange(newVal);
      },
      typeSortKeyOverride: sortKey,
    });
    builder.addNumberProp({
      name: 'price_per_kWatt',
      value: pricePerWatt,
      unit: prop.unit,
      onChange: (newVal) => {
        const newPrice = newVal * maxPowerWatt;
        onChange(newPrice);
      },
      typeSortKeyOverride: sortKey,
    });
    return true;
}
