import { Vector2 } from 'math-ts';
import { DISTANCE_EPSILON } from './constants';

const zeroV2 = new Vector2();

export function createRectangleOfWidth(
    from: Vector2,
    to: Vector2,
    width: number,
    offsetPercentOfWidth = 0,
): [a: Vector2, b: Vector2, c: Vector2, d: Vector2] {
    const dir = to.clone().sub(from).normalize();
    const dirRev = dir.clone().rotateAround(zeroV2, Math.PI);
    const addsUp = dir.clone()
        .rotateAround(zeroV2, Math.PI/2)
        .multiplyScalar(width/2);
    const subtract = dir.clone()
        .rotateAround(zeroV2, 1.5 * Math.PI)
        .multiplyScalar(width/2);
    const _from = from.clone()
        .add(dirRev.clone().multiplyScalar(width * offsetPercentOfWidth));
    const _to = to.clone()
        .add(dir.clone().multiplyScalar(width * offsetPercentOfWidth));
    const a = _from.clone().add(addsUp);
    const b = _to.clone().add(addsUp);
    const c = _to.clone().add(subtract);
    const d = _from.clone().add(subtract);
    return [a,b,c,d];
}


/**
 * Find an intersection point between 2 directions coming from 2 points.
 *
 * @remarks
 * If one of the directions is zero-length, intersection point between
 * direction coming from point and shortest path from another point.
 */
export function findIntersectionPointBetweenTwoDirectionsFromPoints(
    ptA: Vector2,
    dirA: Vector2,
    ptB: Vector2,
    dirB: Vector2,
): Vector2 | null {
    if (
        dirB.length() < DISTANCE_EPSILON &&
        dirA.length() < DISTANCE_EPSILON
    ) throw new Error(
        'Can not find intersection because each direction is zero-length',
    );
    if (dirA.length() < DISTANCE_EPSILON) {
        return findIntersectionPointBetweenTwoDirectionsFromPoints(
            ptA, dirB.clone().rotateAround(ptB, Math.PI / 2), ptB, dirB,
        );
    }
    if (dirB.length() < DISTANCE_EPSILON)
        return findIntersectionPointBetweenTwoDirectionsFromPoints(
            ptB, dirB, ptA, dirA,
        );
    dirA = dirA.clone().normalize();
    dirB = dirB.clone().normalize();
    // ptA.x + dirA.x * lenA = ptB.x + dirB.x * lenB
    // lenA = (ptB.x + dirB.x * lenB - ptA.x)/dirA.x

    // ptA.y + dirA.y * lenA = ptB.y + dirB.y * lenB
    // lenA = (ptB.y + dirB.y * lenB - ptA.y)/dirA.y

    // ptB.x + dirB.x * lenB - ptA.x = (ptB.y + dirB.y * lenB - ptA.y)/dirA.y*dirA.x
    // ptB.x + dirB.x*lenB - ptA.x = ptB.y*dirA.x/dirA.y + lenB*dirB.y*dirA.x/dirA.y - ptA.y*dirA.x/dirA.y
    // lenB*dirB.x - lenB*dirB.y*dirA.x/dirA.y = ptB.y*dirA.x/dirA.y - ptA.y*dirA.x/dirA.y - ptB.x + ptA.x
    // lenB = (ptB.y*dirA.x/dirA.y - ptA.y*dirA.x/dirA.y - ptB.x + ptA.x)/(dirB.x - dirB.y*dirA.x/dirA.y)
    // lenB = (ptB.y*dirA.x - ptA.y*dirA.x - ptB.x*dirA.y + ptA.x*dirA.y)/dirA.y/(dirB.x*dirA.y - dirB.y*dirA.x)*dirA.y
    // lenB = (ptB.y*dirA.x - ptA.y*dirA.x - ptB.x*dirA.y + ptA.x*dirA.y)/(dirB.x*dirA.y - dirB.y*dirA.x)
    const lenB =
        (ptB.y*dirA.x - ptA.y*dirA.x - ptB.x*dirA.y + ptA.x*dirA.y) /
        (dirB.x*dirA.y - dirB.y*dirA.x);
    if (isNaN(lenB)) return null;
    const mid = ptB.clone().add(dirB.clone().multiplyScalar(lenB));
    return mid;
}

