// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { StdSubmeshRepresentationT } from './std-submesh-representation.js';
import { StdSubmeshRepresentation } from './std-submesh-representation.js';
import type { TransformT } from './transform.js';
import { Transform } from './transform.js';


export class SubmeshGroupRepresentation implements flatbuffers.IUnpackableObject<SubmeshGroupRepresentationT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SubmeshGroupRepresentation {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSubmeshGroupRepresentation(bb:flatbuffers.ByteBuffer, obj?:SubmeshGroupRepresentation):SubmeshGroupRepresentation {
  return (obj || new SubmeshGroupRepresentation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSubmeshGroupRepresentation(bb:flatbuffers.ByteBuffer, obj?:SubmeshGroupRepresentation):SubmeshGroupRepresentation {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SubmeshGroupRepresentation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

submeshes(index: number, obj?:StdSubmeshRepresentation):StdSubmeshRepresentation|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new StdSubmeshRepresentation()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

submeshesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

transform(obj?:Transform):Transform|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new Transform()).__init(this.bb_pos + offset, this.bb!) : null;
}

static startSubmeshGroupRepresentation(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addSubmeshes(builder:flatbuffers.Builder, submeshesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, submeshesOffset, 0);
}

static createSubmeshesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startSubmeshesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addTransform(builder:flatbuffers.Builder, transformOffset:flatbuffers.Offset) {
  builder.addFieldStruct(1, transformOffset, 0);
}

static endSubmeshGroupRepresentation(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): SubmeshGroupRepresentationT {
  return new SubmeshGroupRepresentationT(
    this.bb!.createObjList<StdSubmeshRepresentation, StdSubmeshRepresentationT>(this.submeshes.bind(this), this.submeshesLength()),
    (this.transform() !== null ? this.transform()!.unpack() : null)
  );
}


unpackTo(_o: SubmeshGroupRepresentationT): void {
  _o.submeshes = this.bb!.createObjList<StdSubmeshRepresentation, StdSubmeshRepresentationT>(this.submeshes.bind(this), this.submeshesLength());
  _o.transform = (this.transform() !== null ? this.transform()!.unpack() : null);
}
}

export class SubmeshGroupRepresentationT implements flatbuffers.IGeneratedObject {
constructor(
  public submeshes: (StdSubmeshRepresentationT)[] = [],
  public transform: TransformT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const submeshes = SubmeshGroupRepresentation.createSubmeshesVector(builder, builder.createObjectOffsetList(this.submeshes));

  SubmeshGroupRepresentation.startSubmeshGroupRepresentation(builder);
  SubmeshGroupRepresentation.addSubmeshes(builder, submeshes);
  SubmeshGroupRepresentation.addTransform(builder, (this.transform !== null ? this.transform!.pack(builder) : 0));

  return SubmeshGroupRepresentation.endSubmeshGroupRepresentation(builder);
}
}
