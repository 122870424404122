<script lang="ts">
    import type { EnergyViewContext } from './EnergyViewContext';
    import { VersionedStore } from '../VersionedStore';
    import { Success } from 'engine-utils-ts';
    import { EnergyPipelineProperty, EnergyYieldPropsGroup, EnergyYieldSiteProps } from 'bim-ts';
    import EnergyOverview from './EnergyOverview.svelte';
    
    export let energy: EnergyPipelineProperty;

    $: energyPropsGroup = new EnergyYieldPropsGroup({pipeline: energy});

</script>

<div class="root">
    <EnergyOverview
        energyProps = {energyPropsGroup}
    ></EnergyOverview>
</div>

<style lang="scss">
    .root {
        width: 700px;
        height: 80%;
    }
</style>