import { applySiteAreaSettingsToScene } from "./apply-to-scene";
import { cb_ci_block_imported } from "./cb_ci_block_imported";
import { htb_cb_ci_block_imported } from "./htb_cb_ci_block_imported";
import { createSiteAreaSettingsFromScene } from "./parse-scene";
import { si_cb_t_block_imported } from "./si_cb_t_block_imported";
import { vtb_cb_ci_block_imported } from "./vtb_cb_ci_block_imported";

export const Augmentation = {
    cb_ci_block_imported,
    htb_cb_ci_block_imported,
    vtb_cb_ci_block_imported,
    si_cb_t_block_imported,
    createSiteAreaSettingsFromScene,
    applySiteAreaSettingsToScene,
}

