<script lang="ts">
import type { AssetId, Bim, Catalog} from "bim-ts";
import { InSceneCatalog } from "bim-ts";
import type { KreoEngine } from "engine-ts";
import type { UiBindings } from "ui-bindings";

import { getContext } from "svelte";
import { Action } from "../libui/context-menu";

import { ContextMenuStructure } from "../libui/context-menu";
import EngineUiPanel from '../ui-panels/EngineUiPanel.svelte';
import CatalogItemManager from "./CatalogItemManager.svelte";

import type { CreateContextMenuFn } from "./types";

let catalog = getContext<Catalog>('catalog');
let bim = getContext<Bim>('bim');
let uiBindings = getContext<UiBindings>('uiBindings');
let engine = getContext<KreoEngine>('engine');
let inSceneCatalog = new InSceneCatalog(catalog, bim, uiBindings, engine.tasksRunner);

let status: string;


let createExtraMenu: CreateContextMenuFn = id => {
  const menuStructure = new ContextMenuStructure([]);
  menuStructure.items.push(new Action(
    'apply props',
    () => inSceneCatalog
      .applyAssetPropToSceneInstances(id, bim.instances.getSelected())
  ));
  const instanceType = catalog.assets.sceneInstancePerAsset
    .getAssetAsSceneInstance(id)?.type_identifier
  if (instanceType) {
    // adding to scene is not allowed
    menuStructure.items.push(new Action(
      'add to scene',
      () => inSceneCatalog.loadAssetsToScene([id]),
      'double click',
    ));
  }
  return menuStructure;
}

function assetDoubleClickAction(id: AssetId) {
  const instanceType = catalog.assets.sceneInstancePerAsset
    .getAssetAsSceneInstance(id)?.type_identifier
  if (instanceType === undefined) return;
  inSceneCatalog.loadAssetsToScene([id])
}

</script>

<EngineUiPanel showPositionMenu={true}>
<CatalogItemManager
    createExtraMenuForAsset={createExtraMenu}
    assetDoubleClickAction={assetDoubleClickAction}
    bind:status
/>
</EngineUiPanel>
<span class="status">
  {status}
</span>

<style lang="scss">
    .status {
        position: absolute;
        bottom: 0;
        left: 10px;
        right: 10px;
        overflow: hidden;
    }
</style>
