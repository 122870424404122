import type { Bim } from "bim-ts";
import { Failure, LazyVersioned, Success, type Yield } from "engine-utils-ts";
import { PUI_ConfigPropertyTransformer, type ExportedFileDescription, type FileExporter, type FileExporterContext, type PropertyFitChecker, PUI_PropertyNodeSelector, PUI_PropertyNodeSelectorArgs, PUI_ConfigBasedBuilderParams } from "ui-bindings";
import { CommonExportSettings, getExportProjectName, selectExportResolution } from "../CommonExportSettings";
import { convertXmlAndDownload } from "./DownloadLandFile";
import { ProjectInfo, VerdataSyncerType } from "src";


export class LandXmlFileExporter implements FileExporter<CommonExportSettings> {

    constructor(
        readonly bim: Bim,
        readonly projectInfo: LazyVersioned<ProjectInfo | undefined>,
        readonly projectVerdataSyncer: VerdataSyncerType,
    ) {
    }

    initialSettings() {
        return {defaultValue: new CommonExportSettings()};
    }

    *startExport(settings: CommonExportSettings, context: FileExporterContext): Generator<Yield, ExportedFileDescription[]> {

        const options = new Map([[1, "1"], [3, "3"], [5, "5"], [15, "15"]]);

        const resolutionParam = yield * selectExportResolution(context, options, "triangles_size", 3)

        const baseFileName = getExportProjectName(this.projectVerdataSyncer, this.projectInfo.poll());

        const files = yield* convertXmlAndDownload(this.bim, context.logger, settings, resolutionParam, baseFileName);

        const result: ExportedFileDescription[] = [];
        for (const fileResult of files) {
            if (fileResult instanceof Success) {
                result.push(fileResult.value)
            } else {
                //todo: notifications
                context.logger.error(fileResult.errorMsg())
            }
        }
        return result;
    }
}
