<script lang="ts">
    import { fade } from "svelte/transition";

    import { LinearProgress } from "../reusable";
    import type {
        DefaultNotificationOptions,
        NotificationItem,
    } from "./NotificationsHandler";
    import NotificationMessage from "./NotificationMessage.svelte";
    import { ButtonComponent, IconButton } from "../libui/button";

    export let notification: DefaultNotificationOptions;
    export let onRemove: () => void;

    let currentNotification = notification as NotificationItem;
    let finishTask = false;

    function onFinish(notification: NotificationItem) {
        currentNotification = notification;
        finishTask = true;
    }
    function onClose() {
        if (notification.onClose) {
            notification.onClose();
        }
        onRemove();
    }
</script>

<div class="notification bg-white" in:fade out:fade>
    <NotificationMessage
        notification={currentNotification}
        isHeader={true}
        {onRemove}
        {onFinish}
    >
        {#if currentNotification.taskDescription}
            <div
                class="task-button"
                on:click={() =>
                    currentNotification.taskDescription?.task.reject("cancel")}
            >
                Cancel
            </div>
        {:else}
            <ButtonComponent desc={new IconButton("Close", onClose)} />
        {/if}
    </NotificationMessage>
    {#if currentNotification.taskDescription || finishTask}
        <div class="notification-progress">
            <LinearProgress finished={finishTask} />
        </div>
    {/if}
</div>

<style lang="scss">
    .notification {
        margin: 2px;
        color: #000;
        border-radius: 4px;
        box-shadow: var(--dropdownsimple-box-shadow);
        overflow: hidden;
        padding: 8px 16px;
        box-sizing: border-box;
        width: 352px;
        &:last-of-type {
            margin-bottom: 16px;
        }
    }
    .notification-progress {
        margin: 16px 0 8px;
    }
    .task-button {
        font-weight: 700;
        cursor: pointer;
    }
</style>
