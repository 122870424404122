<script lang="ts">
    import { ButtonComponent, Button, ButtonType } from "../libui/button";
    import { VersionedStore } from "../VersionedStore";
    import { DialogButtonDescription } from "./DialogsListener";

    export let buttonDescription: DialogButtonDescription;

    const disabled = buttonDescription.disabled
        ? new VersionedStore(buttonDescription.disabled)
        : undefined;
</script>

<ButtonComponent
    desc={new Button(
        buttonDescription.name,
        buttonDescription.style ?? ButtonType.Contained,
        () => {
            buttonDescription.action();
        },
        disabled ? $disabled : false,
    )}
/>

<style>
</style>
