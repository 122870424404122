import { Vector3 } from 'math-ts';

import type { Camera} from '../3rdParty/three';
import { OrthographicCamera } from '../3rdParty/three';

// export function updateGizmoScale() {
// 	const dpi = window.devicePixelRatio;
// }

const _v = new Vector3();

//TODO: scale with browser scaling (50% -> 100% -> 200% etc)

export function scaleForCamera(position: Vector3, camera: Camera): number {

	const positionInCameraCoords = _v.copy(position);
	positionInCameraCoords.applyMatrix4(camera.mvpMatrix);

	if (Math.abs(positionInCameraCoords.x) > 1.07
		|| Math.abs(positionInCameraCoords.y) > 1.07
		|| Math.abs(positionInCameraCoords.z) > 1
	) {
		return 0;
	}
	
	if (camera instanceof OrthographicCamera) {
		return 0.05 /( camera.zoom / (camera.top - camera.bottom));
	} else {
		// _v1.copy(position);
		// _v2.setFromMatrixColumn(camera.matrixWorld, 1).normalize().add(position);
		// _v1.applyMatrix4(camera.mvpMatrix);
		// _v2.applyMatrix4(camera.mvpMatrix);
		// unitScale = _v1.distanceTo(_v2);
		const e = camera.mvpMatrix.elements;
		const w = 1 / ( e[ 3 ] * position.x + e[ 7 ] * position.y + e[ 11 ] * position.z + e[ 15 ] );
		return 0.05 / (Math.pow(w, 0.93)); // make farther away objects a little smaller with pow
	}
	// if (camera instanceof OrthographicCamera) {
	// 	const camSize = (camera.right - camera.left) / camera.zoom;
	// 	return camSize * 0.03 * scaleMultiplier;
	// }
	// const distance = _v.setFromMatrixPosition(camera.matrixWorld).distanceTo(position);
	// return camera.projectionMatrix.
	// camera.projectionMatrix.
	// return position.distanceTo(camera.position) / 22 * scaleMultiplier;
}
