import type { TasksRunner } from "engine-utils-ts";
import { PollablePromise } from "engine-utils-ts";
import type { UiBindings } from "ui-bindings";
import type { AssetCatalogItemProps, Bim, Catalog, CatalogItemId, MathSolversApi, NumberRangeProperty, IdBimScene, SceneInstance, AugmentSettings } from "bim-ts";
import { Augmentation } from  "./Augmentation";
import type { BlockEquipmentExtended } from "./parse-scene";

export function* applySiteAreaSettingsToScene(
    bim: Bim,
    catalog: Catalog,
    mathSolverApi: MathSolversApi,
    tasksRunner: TasksRunner,
    uiBindings: UiBindings,
    settings: AugmentSettings,
) {
    const scheme = settings.electrical.scheme.value;
    const combinerBox = getAssetCatalogItemSceneInstance(
        settings.electrical.combiner_box.value[0].id as CatalogItemId,
        catalog,
    )!;

    if (scheme === 'SI_Multiharness') {
        yield* PollablePromise.generatorWaitFor(applySiAugmentation(
            scheme,
            combinerBox,
            settings,
            bim,
            catalog,
            mathSolverApi,
            tasksRunner,
            uiBindings,
        ));
    } else {
        yield* PollablePromise.generatorWaitFor(applyCiAugmentation(
            scheme,
            combinerBox,
            settings,
            bim,
            catalog,
            mathSolverApi,
            tasksRunner,
            uiBindings,
        ));
    }
}

async function applyCiAugmentation(
    // input
    scheme: string,
    combinerBox: SceneInstance,
    settings: AugmentSettings,

    // dependencies
    bim: Bim,
    catalog: Catalog,
    mathSolverApi: MathSolversApi,
    tasksRunner: TasksRunner,
    uiBindings: UiBindings,
) {
    const inverterPerTransformer = new Map<IdBimScene, SceneInstance>();
    const extendedBlocksEquipment = settings.electrical.blocks_equipment as BlockEquipmentExtended[];
    for (const block of extendedBlocksEquipment) {
        const inverteTemplate = getAssetCatalogItemSceneInstance(
            block.inverter.value[0].id as CatalogItemId,
            catalog,
        );
        if (!inverteTemplate) {
            console.error('block does not have inverter');
            continue;
        }
        const transformers = block.matchingTransformers
            .map(x => x.value);
        for (const transformer of transformers) {
            inverterPerTransformer.set(transformer, inverteTemplate)
        }
    }

    const blocks = Array.from(inverterPerTransformer)
    if (!blocks.length) {
        throw new Error('no blocks found');
    }

    const necMultiplier = settings.electrical.nec_multiplier.value;
    const combinerBoxOffset = settings.offsets.combiner_box_offset;
    const inverterOffset = settings.offsets.inverter_offset;
    switch (scheme) {
        case ('CI_Multiharness'): {
            return Augmentation.cb_ci_block_imported(
                { blocks, combinerBox, combinerBoxOffset, necMultiplier, inverterOffset },
                bim,
                mathSolverApi,
                tasksRunner,
                uiBindings,
            );
        }
        case ('CI_HorTrunkbus'): {
            return Augmentation.htb_cb_ci_block_imported(
                { align_boxes: false, blocks, combinerBox, necMultiplier, inverterOffset, combinerBoxOffset },
                bim,
                mathSolverApi,
                tasksRunner,
                uiBindings,
            );
        }
        case ('CI_HorTrunkbus_Trench'): {
            return Augmentation.htb_cb_ci_block_imported(
                { align_boxes: true, blocks, combinerBox, necMultiplier, inverterOffset, combinerBoxOffset },
                bim,
                mathSolverApi,
                tasksRunner,
                uiBindings,
            );
        }
        case ('CI_MultVertTrunkbus'): {
            return Augmentation.vtb_cb_ci_block_imported(
                { blocks, combinerBox, necMultiplier, combinerBoxOffset, inverterOffset },
                bim,
                mathSolverApi,
                tasksRunner,
                uiBindings,
            );
        }
        case ('CI_VertTrunkbus'): {
            return Augmentation.vtb_cb_ci_block_imported(
                { blocks, combinerBox, combinerBoxOffset, necMultiplier, inverterOffset },
                bim,
                mathSolverApi,
                tasksRunner,
                uiBindings,
            );
        }
    }
    throw new Error('scheme not supported');
}

async function applySiAugmentation(
    // input
    scheme: string,
    combinerBox: SceneInstance,
    settings: AugmentSettings,

    // dependencies
    bim: Bim,
    catalog: Catalog,
    mathSolverApi: MathSolversApi,
    tasksRunner: TasksRunner,
    uiBindings: UiBindings,
) {
    const inverterAndIlrPerTransformer = new Map<
        IdBimScene,
        {
            inverter: SceneInstance,
            ilr: NumberRangeProperty,
        }
    >();
    const extendedBlocksEquipment = settings.electrical.blocks_equipment as BlockEquipmentExtended[];
    for (const block of extendedBlocksEquipment) {
        const inverter = getAssetCatalogItemSceneInstance(
            block.inverter.value[0].id as CatalogItemId,
            catalog,
        );
        if (!inverter) {
            console.error('block does not have inverter');
            continue;
        }
        const transformers = block.matchingTransformers
            .map(x => x.value);
        const ilr = block.ilr_range;
        for (const transformer of transformers) {
            inverterAndIlrPerTransformer.set(transformer, { inverter, ilr })
        }
    }

    const blocks = Array.from(inverterAndIlrPerTransformer)
    if (!blocks.length) {
        throw new Error('no blocks found');
    }

    const necMultiplier = settings.electrical.nec_multiplier.value;
    const combinerBoxOffset = settings.offsets.combiner_box_offset;
    if (scheme === 'SI_Multiharness') {
        return Augmentation.si_cb_t_block_imported(
            {
                blocks: blocks.map(([k, v]) => [k, v.inverter, v.ilr]),
                combinerBox,
                combinerBoxOffset,
                inverterOffset: settings.offsets.inverter_offset,
                necMultiplier
            },
            bim,
            mathSolverApi,
            tasksRunner,
            uiBindings
        );
    } else {
        throw new Error('scheme not supported');
    }
}

function getAssetCatalogItemSceneInstance(catalogItemId: CatalogItemId, catalog: Catalog) {
    const catalogItem = catalog.catalogItems.peekById(catalogItemId);
    if (!catalogItem) {
        return null;
    }
    const assetCatalogItem = catalogItem.as<AssetCatalogItemProps>();
    const assetId = assetCatalogItem.properties.asset_id.value;
    const asset = catalog.assets.sceneInstancePerAsset.getAssetAsSceneInstance(assetId);
    return asset;
}
