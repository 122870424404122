<script lang="ts">
    import { IconName } from "../libui/icon";
    import * as Icons from "../libui/icon/svg";

    export let name: IconName;
    export let fill: boolean = false;

    $: filledIconName = `${name}Fill` as IconName;
    $: fullName = fill && filledIconName in Icons ? filledIconName : name;
</script>

<svelte:component this={Icons[fullName]} />
