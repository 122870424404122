// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class BasicAnalyticalRepresentation implements flatbuffers.IUnpackableObject<BasicAnalyticalRepresentationT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):BasicAnalyticalRepresentation {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsBasicAnalyticalRepresentation(bb:flatbuffers.ByteBuffer, obj?:BasicAnalyticalRepresentation):BasicAnalyticalRepresentation {
  return (obj || new BasicAnalyticalRepresentation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsBasicAnalyticalRepresentation(bb:flatbuffers.ByteBuffer, obj?:BasicAnalyticalRepresentation):BasicAnalyticalRepresentation {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new BasicAnalyticalRepresentation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

geometryId():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

static startBasicAnalyticalRepresentation(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addGeometryId(builder:flatbuffers.Builder, geometryId:number) {
  builder.addFieldInt32(0, geometryId, 0);
}

static endBasicAnalyticalRepresentation(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createBasicAnalyticalRepresentation(builder:flatbuffers.Builder, geometryId:number):flatbuffers.Offset {
  BasicAnalyticalRepresentation.startBasicAnalyticalRepresentation(builder);
  BasicAnalyticalRepresentation.addGeometryId(builder, geometryId);
  return BasicAnalyticalRepresentation.endBasicAnalyticalRepresentation(builder);
}

unpack(): BasicAnalyticalRepresentationT {
  return new BasicAnalyticalRepresentationT(
    this.geometryId()
  );
}


unpackTo(_o: BasicAnalyticalRepresentationT): void {
  _o.geometryId = this.geometryId();
}
}

export class BasicAnalyticalRepresentationT implements flatbuffers.IGeneratedObject {
constructor(
  public geometryId: number = 0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return BasicAnalyticalRepresentation.createBasicAnalyticalRepresentation(builder,
    this.geometryId
  );
}
}
