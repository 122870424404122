import { BimProperty } from '../../bimDescriptions/BimProperty';
import { PropertiesGroupFormatter } from '../../bimDescriptions/PropertiesGroupFormatter';
import {
    BimPropertiesFormatter, sceneInstanceHierarchyPropsRegistry
} from '../../catalog/SceneInstanceHierarchyPropsRegistry';
import { EnergyYieldPropsGroup } from '../../energy/EnergyYieldPropsGroup';
import { PropsGroupBase } from '../../properties/Props';
import { PropsFieldFlags, PropsFieldOneOf } from '../../properties/PropsGroupComplexDefaults';
import { PropsGroupsRegistry } from '../../properties/PropsGroupsRegistry';
import {
    create_expandCommonEquipmentPropsWithCostTableLinks, registerEquipmentCommonAssetToCatalogItem
} from '../EquipmentCommon';
import { removeEnergyPropsMigration } from '../transformer/Transformer';
import { addEnergyInverterProps_ShapeMigration } from './EnergyInverterPropsMigration';

import type { BimPropertyData } from "../../bimDescriptions/BimProperty";
import type { PropertiesGroupFormatters } from "../../bimDescriptions/PropertiesGroupFormatter";
import type { AssetBasedCatalogItemCreators } from "../../catalog/CatalogItemCollection";
import type { Bim } from '../../Bim';
import { IterUtils } from 'engine-utils-ts';
import { NestedLvWires } from '../LvWire';
export const InverterTypeIdent = 'inverter';


export class InverterProps extends PropsGroupBase {

    energy: EnergyYieldPropsGroup | null;
    lv_wiring: NestedLvWires | null;

    constructor(args: Partial<InverterProps>) {
        super();
        this.energy = args.energy ?? null;
        this.lv_wiring = args.lv_wiring ?? null;
    }
}

PropsGroupsRegistry.register({
    class: InverterProps,
    complexDefaults: {
        energy: new PropsFieldOneOf(
            PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization,
            null,
            EnergyYieldPropsGroup,
        ),
        lv_wiring: new PropsFieldOneOf(
            PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization,
            null,
            NestedLvWires,
        ),
    }
});

export const InverterMandatoryProps = {
    manufacturer: BimProperty.NewShared({ path: ['commercial', 'manufacturer'], value: '' }),
    model: BimProperty.NewShared({ path: ['commercial', 'model'], value: '' }),
    width: BimProperty.NewShared({path: ['dimensions', 'width'], value: 0, unit: 'm', numeric_range: [0, 1e9]}),
    length: BimProperty.NewShared({path: ['dimensions', 'length'], value: 0, unit: 'm', numeric_range: [0, 1e9]}),
    height: BimProperty.NewShared({path: ['dimensions', 'height'], value: 0, unit: 'm', numeric_range: [0, 1e9]}),
    max_voltage_input: BimProperty.NewShared({path: ['module', 'max_voltage_input'], value: 0, unit: 'V', numeric_range: [0, 1e9]}),
    max_current_input: BimProperty.NewShared({path: ['module', 'max_current_input'], value: 0, unit: 'A', numeric_range: [0, 1e9]}),
    max_current_output: BimProperty.NewShared({path: ['module', 'max_current_output'], value: 0, unit: 'A', numeric_range: [0, 1e9]}),
    dc_inputs_number: BimProperty.NewShared({path: ['module', 'dc_inputs_number'], value: 0, numeric_range: [0, 1e9]}),
    max_power: BimProperty.NewShared({path: ['module', 'max_power'], value: 0, unit: 'kW', numeric_range: [0, 1e9]}),
    nominal_ac_voltage: BimProperty.NewShared({path: ['module', 'nominal_ac_voltage'], value: 0, unit: 'V', numeric_range: [0, 1e9]}),
    min_mppt_voltage: BimProperty.NewShared({path: ['module', 'min_mppt_voltage'], value: 0, unit: 'V', numeric_range: [0, 1e9]}),
    max_mppt_voltage: BimProperty.NewShared({path: ['module', 'max_mppt_voltage'], value: 0, unit: 'V', numeric_range: [0, 1e9]}),
    modules_count: BimProperty.NewShared({ path: ['inverter', 'modules_count'], value: 1, numeric_step: 1, numeric_range: [1, 1e3] }),
    max_efficiency: BimProperty.NewShared({path: ['inverter', 'efficiency', 'max_efficiency'], value: 0}),
    cec_efficiency: BimProperty.NewShared({path: ['inverter', 'efficiency', 'cec_efficiency'], value: 0}),
    euro_efficiency: BimProperty.NewShared({path: ['inverter', 'efficiency', 'euro_efficiency'], value: 0}),
} satisfies { [key: string]: BimProperty };

type InverterKeyPropsType = Pick<typeof InverterMandatoryProps, "manufacturer" | "model" | "max_power" | "modules_count">
export const InverterKeyProps = {
    manufacturer: InverterMandatoryProps.manufacturer,
    model: InverterMandatoryProps.model,
    max_power: InverterMandatoryProps.max_power,
    modules_count: InverterMandatoryProps.modules_count,
} satisfies InverterKeyPropsType;

const CostBsProps = {
    cost_bs_level_1: BimProperty.NewShared({ path: ["cost_bs", "level 1"], value: "INVERTERS & TRANSFORMERS SUBTOTAL"}),
    cost_bs_level_2: BimProperty.NewShared({ path: ["cost_bs", "level 2"], value: "Inverters procurement"}),
} satisfies { [key: string]: BimProperty };


export function registerInverterKeyPropertiesGroupFormatter(
    group: PropertiesGroupFormatters,
) {
    group.register(
        InverterTypeIdent,
        new PropertiesGroupFormatter(
            InverterKeyProps,
            (props, unitsMapper) => {
                return [
                    [
                        props.manufacturer,
                        props.model
                    ].map(x => x.asText()),
                    props.max_power.valueUnitUiString(unitsMapper),
                    props.modules_count.asNumber() + 'MOD'
                ].flat().join(' ');
            }
        )
    )
}

export function registerInverterArchetype(
    bim: Bim
) {
    const mandatoryProps: BimPropertyData[] = [];
    IterUtils.extendArray(mandatoryProps, Object.values(InverterMandatoryProps));
    IterUtils.extendArray(mandatoryProps, Object.values(CostBsProps));

    bim.instances.archetypes.registerArchetype({
        type_identifier: "inverter",
        mandatoryProps: mandatoryProps,
        propsClass: InverterProps,
        propsShapeMigrations: [
            addEnergyInverterProps_ShapeMigration(1),
            removeEnergyPropsMigration(2),
        ],
    });
}


export function registerInverterAssetToCatalogItem(group: AssetBasedCatalogItemCreators) {
    registerEquipmentCommonAssetToCatalogItem(InverterTypeIdent, group);
}

sceneInstanceHierarchyPropsRegistry.set(
    InverterTypeIdent,
    [
        new BimPropertiesFormatter(
            {
                manufacturer: BimProperty.NewShared({
                    path: ['commercial', 'manufacturer'],
                    value: '',
                }),
            },
            (props) => [props.manufacturer.asText() ?? 'manufacturer not specified'],
        )
    ]
);

export const create_expandInverterPropsWithCostTableLinks = () => create_expandCommonEquipmentPropsWithCostTableLinks(InverterKeyProps);
