import { CompressibleNumbersArray, WorkerClassPassRegistry, convertThrow, unitsConverter, type ComprArrOrderingType } from 'engine-utils-ts';


export abstract class EnergyStageChart {
    abstract toPowerChart(unit: string): Float32Array;
    abstract forEachInPowerChart(unit: string, callback: (value: number, index: number) => void, ordering: ComprArrOrderingType): void;
    abstract energyTotal(unit: string): number;
    combinedArea(): number {
        return 0;
    }

    abstract equals(other: EnergyStageChart): boolean;

    outputPowerChart(): CompressibleNumbersArray {
        return CompressibleNumbersArray.newFromValues('MW', this.toPowerChart('MW'));
    }
    abstract additional_charts(): { [key: string]: CompressibleNumbersArray; };
}


export class EnergyStageChartIIVV extends EnergyStageChart {
    constructor(
        public readonly Imp: CompressibleNumbersArray,
        public readonly Isc: CompressibleNumbersArray,
        public readonly Vmp: CompressibleNumbersArray,
        public readonly Voc: CompressibleNumbersArray,
    ) {
        super();
        if (Imp.length !== 365 * 24) {
            throw new Error('Invalid current value length ' + Imp.length);
        }
        if (Vmp.length !== 365 * 24) {
            throw new Error('Invalid voltage value length ' + Vmp.length);
        }
        Object.freeze(this);
    }

    additional_charts() {
        return {
            Imp: this.Imp,
            Isc: this.Isc,
            Vmp: this.Vmp,
            Voc: this.Voc,
        }
    }

    toPowerChart(unit: string): Float32Array {
        const current = this.Imp.toArray('A');
        const voltage = this.Vmp.toArray('V');
        const res = new Float32Array(this.Imp.length);
        const mapFn = (v: number ) => unitsConverter.convertValue(v, 'W', unit);
        for (let i = 0; i < res.length; ++i) {

            const c = current[i];
            const v = voltage[i];

            let w = c * v;
            if (!Number.isFinite(w)) {
                w = 0;
            }
            const wUnit = mapFn(w);
            res[i] = wUnit;
        }
        return res;
    }

    forEachInPowerChart(unit: string, callback: (value: number, index: number) => void, ordering: ComprArrOrderingType): void {
        const unitConvertFn = unit != 'W' ? (v: number) => unitsConverter.convertValue(v, 'W', unit) : null;
        const currentToPower = this.Imp.toArray('A', ordering);
        this.Vmp.foreach('V', (v, i) => {
            const current = currentToPower[i];
            let w = current * v;
            if (Number.isFinite(w) && w > 0) {
                if (unitConvertFn) {
                    w = unitConvertFn(w);
                }
                callback(w, i);
            }
        }, ordering);
    }

    energyTotal(unit: string): number {
        const current = this.Imp.toArray('A');
        const voltage = this.Vmp.toArray('V');
        const mapFn = (v: number ) => unitsConverter.convertValue(v, 'Wh', unit);
        let sum = 0;
        for (let i = 0; i < current.length; ++i) {
            const c = current[i];
            const v = voltage[i];
            const w = c * v;
            if (Number.isFinite(w)) {
                sum += w;
            }
        }
        return mapFn(sum);
    }

    equals(other: EnergyStageChart): boolean {
        return other instanceof EnergyStageChartIIVV
            && this.Imp.equals(other.Imp)
            && this.Isc.equals(other.Imp)
            && this.Vmp.equals(other.Imp)
            && this.Voc.equals(other.Vmp);
    }
}
WorkerClassPassRegistry.registerClass(EnergyStageChartIIVV);

export class EnergyStageChartIV extends EnergyStageChart {
    constructor(
        public readonly I: CompressibleNumbersArray,
        public readonly V: CompressibleNumbersArray,
    ) {
        super();
        if (I.length !== 365 * 24) {
            throw new Error('Invalid current value length ' + I.length);
        }
        if (V.length !== 365 * 24) {
            throw new Error('Invalid voltage value length ' + V.length);
        }
        Object.freeze(this);
    }

    toPowerChart(unit: string): Float32Array {
        const current = this.I.toArray('A');
        const voltage = this.V.toArray('V');
        const res = new Float32Array(this.I.length);
        const mapFn = (v: number ) => unitsConverter.convertValue(v, 'W', unit);
        for (let i = 0; i < res.length; ++i) {

            const c = current[i];
            const v = voltage[i];

            const w = c * v;
            const wUnit = mapFn(w);
            res[i] = wUnit;
        }
        return res;
    }

    additional_charts() {
        return {
            Imp: this.I,
            Isc: this.V,
        }
    }


    forEachInPowerChart(unit: string, callback: (value: number, index: number) => void, ordering: ComprArrOrderingType): void {
        const unitConvertFn = unit != 'W' ? (v: number) => unitsConverter.convertValue(v, 'W', unit) : null;
        const currentToPower = this.I.toArray('A', ordering);
        this.V.foreach('V', (v, i) => {
            const current = currentToPower[i];
            let w = current * v;
            if (Number.isFinite(w) && w > 0) {
                if (unitConvertFn) {
                    w = unitConvertFn(w);
                }
                callback(w, i);
            }
        }, ordering)
    }

    energyTotal(unit: string): number {
        const current = this.I.toArray('A');
        const voltage = this.V.toArray('V');
        const mapFn = (v: number ) => unitsConverter.convertValue(v, 'Wh', unit);
        let sum = 0;
        for (let i = 0; i < current.length; ++i) {
            const c = current[i];
            const v = voltage[i];
            const w = c * v;
            if (Number.isFinite(w)) {
                sum += w;
            }
        }
        return mapFn(sum);
    }

    equals(other: EnergyStageChart): boolean {
        return other instanceof EnergyStageChartIV
            && this.I.equals(other.I)
            && this.V.equals(other.V);
    }
}
WorkerClassPassRegistry.registerClass(EnergyStageChartIV);

export class EnergyStageChartW extends EnergyStageChart {
    constructor(
        public readonly power: CompressibleNumbersArray,
    ) {
        super();
        if (power.length !== 365 * 24) {
            throw new Error('Invalid power value length ' + power.length);
        }
        Object.freeze(this);
    }

    toPowerChart(unit: string): Float32Array {
        return this.power.toArray(unit);
    }

    additional_charts() {
        return {
        }
    }


    forEachInPowerChart(unit: string, callback: (value: number, index: number) => void, ordering: ComprArrOrderingType): void {
        this.power.foreach(unit, (w, i) => {
            if (w > 0) {
                callback(w, i);
            }
        }, ordering)
    }

    equals(other: EnergyStageChart): boolean {
        return other instanceof EnergyStageChartW
            && this.power.equals(other.power);
    }

    energyTotal(unit: string): number {
        const sum = this.power.sum();
        return convertThrow(sum, this.power.unit + 'h', unit);
    }
}
WorkerClassPassRegistry.registerClass(EnergyStageChartW);

// export class EnergyStageChartPercLoss extends EnergyStageChart {


// }

export class EnergyStageChartWPerArea extends EnergyStageChart {
    constructor(
        public readonly area: number,
        public readonly power: CompressibleNumbersArray,
    ) {
        super();
        if (power.length !== 365 * 24) {
            throw new Error('Invalid power value length ' + power.length);
        }
        Object.freeze(this);
    }

    combinedArea(): number {
        return this.area;
    }

    additional_charts(): { [key: string]: CompressibleNumbersArray; } {
        return {
            ppsm: this.power,
        }
    }

    toPowerChart(unit: string): Float32Array {
        const arr = this.power.toArray(unit + '/m2');
        for (let i = 0; i < arr.length; ++i) {
            arr[i] *= this.area;
        }
        return arr;
    }

    forEachInPowerChart(unit: string, callback: (value: number, index: number) => void, ordering: ComprArrOrderingType): void {
        this.power.foreach(unit + '/m2', (w, i) => {
            if (w > 0) {
                callback(w * this.area, i);
            }
        }, ordering)
    }

    equals(other: EnergyStageChart): boolean {
        return other instanceof EnergyStageChartWPerArea
            && this.power.equals(other.power)
            && this.area === other.area;
    }

    energyTotal(unit: string): number {
        const sum = this.power.sum() * this.area;
        if (unit.endsWith('h')) {
            unit = unit.slice(0, -1) + '/m2';
        }
        return convertThrow(sum, this.power.unit, unit);
    }
}
WorkerClassPassRegistry.registerClass(EnergyStageChartWPerArea);

export class EnergyStageChartWPerAreaMulti extends EnergyStageChart {
    constructor(
        public readonly area: number,
        public readonly powers: CompressibleNumbersArray[],
    ) {
        super();
        for (let i = 1; i < this.powers.length; ++i) {
            if (this.powers[i].unit !== this.powers[0].unit) {
                throw new Error('powers units should be the same' + this.powers[i].unit);
            }
        }
        Object.freeze(this);
    }

    combinedArea(): number {
        return this.area;
    }

    additional_charts(): { [key: string]: CompressibleNumbersArray; } {
        return {
        }
    }

    _toCompressedPowerChart(unit: string): CompressibleNumbersArray {
        if (this.powers.length === 1) {
            return CompressibleNumbersArray.map(
                this.powers[0],
                unit + '/m2',
                (v1) => v1 * this.area,
            );
        } else if (this.powers.length === 2) {
            return CompressibleNumbersArray.map2(
                this.powers[0],
                this.powers[1],
                unit + '/m2',
                (v1, v2) => (v1 + v2) * this.area,
            );
        } else if (this.powers.length === 3) {
            return CompressibleNumbersArray.map3(
                this.powers[0],
                this.powers[1],
                this.powers[2],
                unit + '/m2',
                (v1, v2, v3) => (v1 + v2 + v3) * this.area,
            );
        } else if (this.powers.length === 4) {
            return CompressibleNumbersArray.map4(
                this.powers[0],
                this.powers[1],
                this.powers[2],
                this.powers[3],
                unit + '/m2',
                (v1, v2, v3, v4) => (v1 + v2 + v3 + v4) * this.area,
            );
        } else {
            throw new Error(`multiwat chart mapping not implemented for ${this.powers.length} charts`);
        }
    }

    toPowerChart(unit: string): Float32Array {
        const arr = this._toCompressedPowerChart(unit);
        return arr.toArray();
    }
    
    forEachInPowerChart(unit: string, callback: (value: number, index: number) => void, ordering: ComprArrOrderingType): void {
        const arr = this._toCompressedPowerChart(unit);
        arr.foreach('', (w, i) => {
            if (w > 0) {
                callback(w, i);
            }
        }, ordering);
    }

    equals(other: EnergyStageChart): boolean {
        if (!(other instanceof EnergyStageChartWPerAreaMulti)) {
            return false;
        }
        if (this.area !== other.area || this.powers.length !== other.powers.length) {
            return false;
        }
        for (let i = 0; i < this.powers.length; ++i) {
            if (!this.powers[i].equals(other.powers[i])) {
                return false;
            }
        }
        return true;
    }

    energyTotal(unit: string): number {
        let sum = 0;
        for (const power of this.powers) {
            sum += power.sum() * this.area;
        }
        if (unit.endsWith('h')) {
            unit = unit.slice(0, -1) + '/m2';
        }
        return convertThrow(sum, this.powers[0].unit, unit);
    }
}
WorkerClassPassRegistry.registerClass(EnergyStageChartWPerAreaMulti);


export class EnergyStageChartSimpleLoss extends EnergyStageChart {

    constructor(
        public readonly sourceChart: EnergyStageChart,
        public readonly multiplier: number,
    ) {
        super();
    }

    combinedArea(): number {
        return this.sourceChart.combinedArea();
    }

    additional_charts(): { [key: string]: CompressibleNumbersArray; } {
        return {
        }
    }

    toPowerChart(unit: string): Float32Array {
        const arr = this.sourceChart.toPowerChart(unit);
        for (let i = 0; i < arr.length; ++i) {
            arr[i] *= this.multiplier;
        }
        return arr;
    }
    forEachInPowerChart(unit: string, callback: (value: number, index: number) => void, ordering: ComprArrOrderingType): void {
        this.sourceChart.forEachInPowerChart(unit, (v, i) => {
            callback(v * this.multiplier, i);
        }, ordering);
    }
    energyTotal(unit: string): number {
        return this.sourceChart.energyTotal(unit) * this.multiplier;
    }
    equals(other: EnergyStageChart): boolean {
        return other instanceof EnergyStageChartSimpleLoss
            && this.multiplier === other.multiplier
            && this.sourceChart.equals(other.sourceChart)
    }
}
WorkerClassPassRegistry.registerClass(EnergyStageChartSimpleLoss);