import type { Bim, IdBimScene, ObjectRepresentation, SceneInstance} from "bim-ts";
import { StdGroupedMeshRepresentation, StdMeshRepresentation, hasDifferentSegmentsSlopes } from "bim-ts";
import type { Aabb} from "math-ts";
import { Euler, KrMath, Vector3 } from "math-ts";
import type { ArrayData, PVObject } from './Models';
import { Coordinate, Describer, PVColor, pvShading, pvShdArrayShed, pvShdArrayTracking } from './Models';
import { getTrackerDimensions, getTrackerSlopeInDegrees, groupByNumber, groupByString } from "../instanceUtils";
import type { ShadingDefinition } from "./dataModels";
import { TypeIdentifier, GroupData } from "./dataModels";
import { type RGBAHex, RGBA, Result, Failure, Success } from "engine-utils-ts";
import { getExportProjectName, type CommonExportSettings} from "../CommonExportSettings";
import type { EntityId } from "verdata-ts";
import type { FileExporterContext} from "ui-bindings";
import { DialogDescription} from "ui-bindings";


export function convertToSHDFile(trackerArrays: PVObject[], name:string): string {
    const site = new pvShading(name, trackerArrays);
    const data = new Describer(site);
    return data.toString("", 2);
}

export function convertTrackers(bim: Bim, settings: CommonExportSettings, context: FileExporterContext): PVObject[] {

    const instances = bim.instances.peekByIds(settings.export_only_selected ? bim.instances.getSelected() : bim.instances.allIds())
    let shadings: ShadingDefinition[] = setShadingDefinition(instances, bim, context);

    const groupDatas = groupByString(shadings, (shading) => {

        const definition: GroupData = new GroupData(
            shading.TypeIdentifier,
            shading.parentId,
            shading.width,
            shading.length,
            shading.color,
            shading.rotate,
            shading.slope,
            shading.tilt,
            shading.position.Z,
            shading.orientation
        );

        return definition.toString();
    });

    const shadingArray: PVObject[] = [];
    let i = 0;
    for (const groupData of groupDatas) {
        const array = groupData[1];
        const key = JSON.parse(groupData[0]) as GroupData;

        const lines = groupByNumber(array, (shading) => {
            if(key.TypeIdentifier === TypeIdentifier.fixtilt){
                return Rotetedposition(shading).x;
            }else{
                return Rotetedposition(shading).y;
            }
        });

        for (const lineData of lines) {

            //sort by x coordinate with respect to angle
            const alinedShadings = lineData[1];
            const ordered = alinedShadings
                .slice()
                .sort((a, b) => {
                    const an = a.rotate;

                    const xa = a.position.X * Math.cos(an) + a.position.Y * Math.sin(an);
                    const xb = b.position.X * Math.cos(an) + b.position.Y * Math.sin(an);

                    const ya = a.position.Y * Math.cos(an) - a.position.X * Math.sin(an);
                    const yb = b.position.Y * Math.cos(an) - b.position.X * Math.sin(an);

                    if (key.TypeIdentifier == TypeIdentifier.tracker) {

                        if (xa > xb) {
                            return 1;
                        }
                        if (xb > xa) {
                            return -1;
                        }
                        return 0;
                    } else {

                        if (ya > yb) {
                            return -1;
                        }
                        if (yb > ya) {
                            return 1;
                        }
                        return 0;
                    }

                });

            //group trackers to clusters by thair dimensions and distance between
            let clusters = groupShadingsByDistance(ordered);

            for (let cluster of clusters) {

                if (cluster.length > 0) {
                    const panel = cluster[0];
                    const dist = calculateDistance(cluster);

                    var data: ArrayData = {
                        Tilt: key.tilt,
                        TypeIdentifier: key.TypeIdentifier,
                        Angle: -key.rotate,
                        Color: new PVColor(key.color.R, key.color.G, key.color.B),
                        Coordinate: panel.position,
                        Length: key.length,
                        Width: key.width,
                        NElemChamps: cluster.length,
                        Pitch: dist,
                        Slope: key.slope
                    };

                    const t = CostructArrayOfShadings(i, data)
                    i++;
                    shadingArray.push(t);
                }
            }
        }
    }
    return shadingArray;
}


function CostructArrayOfShadings(i: number, data: ArrayData): PVObject {
    if (data.TypeIdentifier == TypeIdentifier.tracker) {
        return new pvShdArrayTracking(i, data);
    } else {
        return new pvShdArrayShed(i, data);
    }
}

function Rotetedposition(tracker: ShadingDefinition): Vector3 {
    const rotateDegree = tracker.rotate
    const rotateRad = KrMath.degToRad(rotateDegree)

    const x = tracker.position.Y * Math.sin(rotateRad) + tracker.position.X * Math.cos(rotateRad);
    const y = (tracker.position.Y * Math.cos(rotateRad)) - (tracker.position.X * Math.sin(rotateRad));

    const vec = new Vector3(+x.toFixed(3), +y.toFixed(3), 0);
    return vec;
}

function setShadingDefinition(instances: Map<EntityId<IdBimScene>, SceneInstance>, bim: Bim, context: FileExporterContext): ShadingDefinition[] {

    function calculateStdRepresentationLocalBBox(repr: Readonly<ObjectRepresentation> | null): Result<Aabb> {

        if (!repr) {
            return new Failure({msg: 'representation is absent'});
        }
        const aabbs = bim.allBimGeometries.aabbs.poll();

        if (repr instanceof StdMeshRepresentation || repr instanceof StdGroupedMeshRepresentation) {
            const aabb = repr.aabb(aabbs);

            if (aabb.isEmpty()) {
                return new Failure({msg: 'empty aabb'});
            } else {
                return new Success(aabb);
            }
        }
        
        return new Failure({msg: 'expected std representation'});
    }


    const shadingsMap: ShadingDefinition[] = [];
    const undulatedTrackers: IdBimScene[] = [];

    if (!instances || !bim) {
        return shadingsMap;
    }

    for (const [id, instance] of instances) {

        const typeIdentifier =
            instance.type_identifier === "tracker"
                ? TypeIdentifier.tracker
                : instance.type_identifier === "fixed-tilt"
                ? TypeIdentifier.fixtilt
                : instance.type_identifier === "any-tracker"
                ? TypeIdentifier.tracker :
                undefined;

        if (typeIdentifier === undefined) {
            continue;
        }

        if (typeIdentifier === TypeIdentifier.tracker) {

            const slopes = instance.properties.get("position | _segments-slopes")?.asText();
            if (slopes != null) {
                const isUndulated = hasDifferentSegmentsSlopes(slopes);
                if (isUndulated) {
                    undulatedTrackers.push(id);
                    continue;
                }
            }
        }

        const result = calculateStdRepresentationLocalBBox(instance.representation);

        if (result instanceof Success) {
            const bBox = result.value;
            const bBoxCenter = bBox.getCenter_t().applyMatrix4(instance.worldMatrix);
            let tilt = 0;

            const transformRotation = new Euler().setFromRotationMatrix(instance.worldMatrix)
            let rotation = transformRotation.toVector3();

            const rotate = KrMath.radToDeg(transformRotation.z);

            const orientation = convertRotationToVector(new Vector3(1, 0, 0), rotation)

            if (instance.type_identifier === "fixed-tilt") {          
                tilt = instance.properties.get("dimensions | tilt")?.as("deg")!;      
            } 

            const slopeDegrees = getTrackerSlopeInDegrees(instance);

            const { length, width } = getTrackerDimensions(instance);

            const colorTint = bim.instances.peekById(instance.spatialParentId)?.colorTint ?? instance.colorTint;
            const pvColor = convertColor(colorTint);

            var tracker: ShadingDefinition = {
                TypeIdentifier: typeIdentifier,
                parentId: instance.spatialParentId,
                color: pvColor,
                position: new Coordinate(bBoxCenter.x, bBoxCenter.y, bBoxCenter.z),
                length: length,
                width: width,
                rotate: KrMath.roundTo(rotate, 0.001),
                slope: slopeDegrees,
                tilt: tilt,
                orientation: orientation
            };

            shadingsMap.push(tracker);

        }
    }

    if (undulatedTrackers.length > 0) {
        undelartedTrackersMessage(context);
    }

    return shadingsMap;
}


function convertColor(colorInt: 0 | RGBAHex): PVColor {
    const hex = RGBA.toHexRgbString(colorInt as RGBAHex);
    const [r, g, b] = parseFromHexString(hex);
    return new PVColor(r, g, b);
}

function parseFromHexString(hex: string): [number, number, number] {
    if ((hex.startsWith("#") && hex.length == 7) || hex.length == 9) {
        const r = parseInt(hex.charAt(1) + hex.charAt(2), 16);
        const g = parseInt(hex.charAt(3) + hex.charAt(4), 16);
        const b = parseInt(hex.charAt(5) + hex.charAt(6), 16);
        return [r, g, b];
    } else {
        console.error("only strings starting from # are implemented", hex);
        return [255, 255, 255];
    }
}

function isEqualTrackers(first: ShadingDefinition, second: ShadingDefinition) {
    return isEqualValues(first.length, second.length) && isEqualValues(first.width, second.width);
}

function isEqualValues(first: number, second: number) {
    const epsilon = 1e-5;
    return Math.abs(first - second) <= epsilon;
}

function calculateDistance(trackers: ShadingDefinition[]): number {
    const num = trackers.length;
    let dist = 0;
    if (trackers.length < 2) {
        return dist;
    }

    let first = trackers[0];
    let last = trackers[trackers.length - 1];
    let length = distanceBetween(first, last) ?? 0;
    dist = length / (num - 1)

    return dist;
}

function groupShadingsByDistance(trackers: ShadingDefinition[]): ShadingDefinition[][] {
    let cluster: ShadingDefinition[] = [];
    let group: ShadingDefinition[][] = [];
    let dist = 0;
    let newCluster = true;

    if (trackers.length === 1) {
        group.push(trackers);
        return group;
    }

    for (let i = 0; i < trackers.length - 1; i++) {
        const current = trackers[i];
        const next = trackers[i + 1];
        cluster.push(current)
        //measure distanse to the next tracker
        let curDist = distanceBetween(current, next);


        if (!curDist) {
            continue;
        }

        curDist = +curDist.toFixed(3);

        if (newCluster) {
            dist = curDist;
            newCluster = false;
        }

        //new cluster
        let eqDist = isEqualValues(dist, curDist);
        let eqVal = isEqualTrackers(current, next);


        if (!eqDist || !eqVal) {
            group.push(cluster);
            cluster = [];
            newCluster = true;
        }

        //last item
        if (i === (trackers.length - 2)) {
            cluster.push(next);
            group.push(cluster);
        }

    }
    return group;
}

export function distanceBetween(inst1: ShadingDefinition, inst2: ShadingDefinition): number | undefined {
    if (!inst1 || !inst2) {
        return;
    }
    const x1 = inst1.position.X;
    const x2 = inst2.position.X;
    const y1 = inst1.position.Y;
    const y2 = inst2.position.Y;
    const dist = Math.sqrt((Math.pow((x2 - x1), 2) + Math.pow((y2 - y1), 2)));

    return dist;
}

function convertRotationToVector(baseVector: Vector3, rotationData: 0 | Vector3): Vector3 {

    if (rotationData === 0) {
        return baseVector;
    }

    const rotateX = rotationData.x;
    const rotateY = rotationData.y;
    const rotateZ = rotationData.z;
    const { x, y, z } = baseVector;

    if (rotateX === 0 && rotateY === 0 && rotateZ === 0) {
        return baseVector;
    }
    // Calculate the components of the vector using trigonometric functions
    const cosTheta = Math.cos(rotateZ);
    const sinTheta = Math.sin(rotateZ);

    const vecX = x * cosTheta - y * sinTheta;
    const vecY = x * sinTheta + y * cosTheta;

    // Normalize the vector to have a length of 1
    const length = Math.sqrt(vecX * vecX + vecY * vecY);
    const normalizedVecX = vecX / length;
    const normalizedVecY = vecY / length;
    return new Vector3(normalizedVecX, normalizedVecY, 0);

}

function undelartedTrackersMessage(context:FileExporterContext){

    const dialogDiscription = new DialogDescription({
        name: 'Terrain following arrays support by PVSyst',
        context: null,
        message: `
        Your layout has the solar arrays with bay-to-bay slope change (undulated, bent, etc.). 
        Such arrays should be aligned in a straight line to be supported by PVSyst. 
        <p>Please open the Terrain and Piles window and use one of the following options:</p>
        <ol><li>Place all arrays on a flat 2D plane, ignoring terrain</li>
        <li>Update Cut-Fill with Bay-to-Bay slope change set to 0</li></ol>
        Make sure to select all equipment/all boundaries.`,
        closeAction: 'Ok',
        compact: true,
    })

    context.addDialog(dialogDiscription);
}





