import type { TasksRunner , Result} from "engine-utils-ts";
import { Failure, IterUtils, Success } from "engine-utils-ts";
import type { UiBindings } from "ui-bindings";
import type { Bim, MathSolversApi, NumberProperty, NumberRangeProperty, IdBimScene, SceneInstance } from "bim-ts";
import { findMostPowerfullTracker } from "./cb_ci_block_imported";
import type { AugmentedHierarchy, AugmentedHierarchyNode, FlatHierarchyDimensions, Point2D, TrackerDimensions } from "./common";
import { ApplyAugmentationToScene, cleanupSceneHierarchy, gatherBlockDimensions, resetSceneZCoord } from "./common";
import { calcSiCbClusterValue, calcSiCbPixelValue } from "../farm-layout/ElectricalPatternFormulas";

export async function si_cb_t_block_imported(
    input: SiCbBlockImportedInput,

    // dependencies
    bim: Bim,
    mathSolversApi: MathSolversApi,
    tasksRunner: TasksRunner,
    uiBindings: UiBindings,
) {
    await resetSceneZCoord(bim, input.blocks.map(x => x[0]), tasksRunner, uiBindings);
    const solverInput: SiCbBlockImportedSolverInput = {
        blocks: IterUtils.filterMap(input.blocks, ([transformerId, inverter, ilr]) => {
            const res = calculateSiBlockDimensions(
                transformerId,
                input.combinerBox,
                inverter,
                input.necMultiplier,
                ilr,
                bim
            );
            if (res instanceof Success) {
                return res.value;
            }
            return undefined;
        }),
        box_offset: input.combinerBoxOffset.as('m'),
        inverter_offset: input.inverterOffset.as('m'),
        pixel_cross_road: false,
    }

    // call solver
    const solverOutput = await mathSolversApi.callSolver<
        SiCbBlockImportedSolverInput,
        SiCbBlockImportedSolverOutput
    >({
        solverName: 'si_cb_t_block_imported',
        request: solverInput,
        solverType: 'single',
    })

    // apply to scene
    applySiCbSolverResultToScene(input, solverInput, solverOutput, bim);
}

function applySiCbSolverResultToScene(
    input: SiCbBlockImportedInput,
    solverInput: SiCbBlockImportedSolverInput,
    solverOutput: SiCbBlockImportedSolverOutput,

    bim: Bim,
) {
    // apply sovler result to scene
    const combinerBox = input.combinerBox;
    const inverterPerBlock = new Map(input.blocks.map(x => [x[0], x[1]]));
    const blockDimensions = new Map(solverInput.blocks.map(x => [x.id, x]));

    const augmentedBlocks: AugmentedHierarchy[] = [];
    const touchedBlocks: FlatHierarchyDimensions[] = [];

    for (const block of solverOutput.blocks) {
        const transformerHierarchy: AugmentedHierarchy = {
            children: [],
            transformerId: block.id
        }
        augmentedBlocks.push(transformerHierarchy);
        const inverter = inverterPerBlock.get(block.id);
        const blockDimension = blockDimensions.get(block.id);
        if (!blockDimension || !inverter) {
            console.warn('block description not found. skipping');
            continue;
        }
        touchedBlocks.push(blockDimension)

        for (const cluster of block.clusters) {
            const combinerHierarchy: AugmentedHierarchyNode = {
                position: cluster.combiner_box,
                equipmentTemplate: combinerBox,
            }
            combinerHierarchy.children = [];
            transformerHierarchy.children.push(combinerHierarchy)
            for (const pixel of cluster.pixels) {
                const stringInverterHierarchy: AugmentedHierarchyNode = {
                    position: pixel.string_inverter,
                    equipmentTemplate: inverter,
                    trackers: pixel.trackers,
                }
                combinerHierarchy.children.push(stringInverterHierarchy);
            }
        }
    }
    cleanupSceneHierarchy(bim, touchedBlocks);
    ApplyAugmentationToScene.augmentBlock(bim, augmentedBlocks);
}


interface SiCbFlatHierarchyDimensions extends FlatHierarchyDimensions {
    number_string_inverters?: number
    min_pixel_value: number
    max_pixel_value: number
    max_cluster_value: number
}

interface SiCbBlockImportedSolverInput {
    blocks: SiCbFlatHierarchyDimensions[]
    // grouping optimal power
    box_offset: number
    inverter_offset: number
    pixel_cross_road: boolean
}

interface SiCbBlockImportedSolverOutput {
    blocks: Array<{
        clusters: Array<{
            pixels: Array<{
                trackers: TrackerDimensions[]
                string_inverter: Point2D
            }>
            combiner_box: Point2D
        }>
        id: number
    }>
}

interface SiCbBlockImportedInput {
    necMultiplier: number,
    combinerBox: SceneInstance,
    blocks: Array<[
        transformerId: IdBimScene,
        inverter: SceneInstance,
        ilr: NumberRangeProperty,
    ]>,
    combinerBoxOffset: NumberProperty,
    inverterOffset: NumberProperty,
}

function calculateSiBlockDimensions(
    // input
    transformer: IdBimScene,
    combinerBox: SceneInstance,
    inverter: SceneInstance,
    necMultiplier: number,
    ilr: NumberRangeProperty,

    // dependencies
    bim: Bim
): Result<SiCbFlatHierarchyDimensions> {
    const mostPowerfullTrackerResult = findMostPowerfullTracker([transformer], bim);
    if (mostPowerfullTrackerResult instanceof Failure) {
        return mostPowerfullTrackerResult;
    }
    const mostPowerfullTracker = mostPowerfullTrackerResult.value;
    const inverterMaxPowerWatt = inverter.properties
        .get('inverter | max_power')?.as('W') ?? 0;

    const blockDimension = gatherBlockDimensions([transformer], bim).at(0);

    if (!blockDimension) {
        return new Failure({msg: 'block dimension not found'});
    }
    const max_cluster_value = calcSiCbClusterValue(
        combinerBox.properties.get('input | current')?.as('A') ?? 0,
        inverter.properties.get('inverter | max_current_output')?.as('A') ?? 0,
        inverter.properties.get('inverter | max_current_input')?.as('A') ?? 0,
        mostPowerfullTracker.string_max_current,
        mostPowerfullTracker.string_power,
        necMultiplier,
    )
    const max_pixel_value = calcSiCbPixelValue(inverterMaxPowerWatt, ilr.value[0]);
    const min_pixel_value = calcSiCbPixelValue(inverterMaxPowerWatt, ilr.value[1]);

    const dimensions: SiCbFlatHierarchyDimensions = {
        ...blockDimension,
        id: transformer,
        max_cluster_value,
        max_pixel_value,
        min_pixel_value,
    };

    return new Success(dimensions)
}
