<script lang="ts">
    import { ObjectUtils } from "engine-utils-ts";
    import flash from "../flash";
    import type { Vector2 } from "math-ts";
    import SelectOption from "./SelectOption.svelte";
    import Lock from "../../libui/icon/svg/lock.svelte";
    import { SelectedAreaData } from "layout-service";
    import SelectedAreaWarnings from "./SelectedAreaWarnings.svelte";

    export let name: string;
    export let value: string;
    export let readonly: boolean | undefined;
    export let calculated: boolean = false;
    export let onClick: (position: Vector2) => void;
    export let tag: string | undefined;
    export let hasError: boolean = false;
    export let selectedOptionData: any;

    let div: HTMLElement;
    let preValue = value;

    $: if (!ObjectUtils.areObjectsEqual(preValue, value)) {
        preValue = value;
        flash(div);
    }
</script>

<div
    class="ui-config-property property-row multiselect-prop"
    style:position="relative"
>
    <div bind:this={div} class="property-name text-main-medium">
        <div class="property-name-label mdc-typography--body1">
            {name}
        </div>
    </div>

    <div class="property-value" class:has-error={hasError}>
        {#if readonly || calculated}
            <div class="read-only mdc-typography--body1" class:calculated>
                {"N/A"}
                {#if !calculated}
                    <Lock />
                {/if}
            </div>
        {:else}
            <SelectOption {value} {readonly} {onClick} {tag} />
        {/if}
        {#if !!selectedOptionData && selectedOptionData instanceof SelectedAreaData}
            <SelectedAreaWarnings areaData={selectedOptionData} />
        {/if}
    </div>
</div>

<style lang="scss">
    .property-value.has-error :global(.property) {
        color: var(--color-danger);
    }
</style>
