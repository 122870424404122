<script lang="ts">
    import { getContext } from "svelte";
    import PropertyHelp from "../../pui/PropertyHelp.svelte";
    import NotConnectedTrackers from "./NotConnectedTrackersTooltip.svelte";
    import { Bim, EnergyStageUiWarning, ProjectMetrics } from "bim-ts";
    import { VersionedStore } from "../../VersionedStore";
    import { Success, unitsConverter } from "engine-utils-ts";

    export let warning: EnergyStageUiWarning;

    const projectMetrics = getContext<ProjectMetrics>("layoutMetricsLazy");
    const bim = getContext("bim") as Bim;

    const keyMetrics = new VersionedStore(projectMetrics.keyMetrics);
    $: totalDcPower =
        $keyMetrics instanceof Success
            ? unitsConverter.toShortest($keyMetrics.value.totalDcPower)
            : null;

    let notConnectedDcKw: number;

    $: {
        notConnectedDcKw = 0;
        for (const id of warning.actions[0].actionsToCall[0].instancesIds) {
            const inst = bim.instances.peekById(id);
            if (inst) {
                notConnectedDcKw +=
                    inst.properties
                        .get("circuit | aggregated_capacity | dc_power")
                        ?.as("kW") ?? 0;
            }
        }
    }

    $: notConnectedDc = totalDcPower
        ? `${unitsConverter.convertValue(notConnectedDcKw, "kW", totalDcPower.unit!).toFixed(2)} ${totalDcPower.unit}`
        : "";
    $: totalDc = totalDcPower
        ? `${totalDcPower.value.toFixed(2)} ${totalDcPower.unit}`
        : "";
</script>

{#if notConnectedDc}
    <div class="error-content">
        <div class="error-text">
            {warning.description}
        </div>
        <div>
            {notConnectedDc} of {totalDc}
        </div>
        <PropertyHelp name="incomplete-hierarchy">
            <NotConnectedTrackers {notConnectedDc} {totalDc} />
        </PropertyHelp>
    </div>
{/if}

<style lang="scss">
    .error-content {
        display: flex;
        gap: 4px;
        :global(svg) {
            color: var(--color-danger);
        }
    }
    .error-text {
        flex: 1;
    }
</style>
