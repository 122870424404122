import type { BimMaterial, BimMaterialStdRenderParams, BimMaterialType, EntitiesBase} from 'bim-ts';
import type { ScopedLogger , Result } from 'engine-utils-ts';
import { DefaultMap, Success } from 'engine-utils-ts';
import type { EntityId } from 'verdata-ts';

import type { EngineMaterialIdFlags} from '../pools/EngineMaterialId';
import { EngineMaterialId, GetMaterialIDF, TransparentMaterials } from '../pools/EngineMaterialId';
import type { ShaderFlags } from '../shaders/ShaderFlags';
import { EntitiesBimSyncedInterned } from '../resources/EntitiesBimSyncedInterned';
import { MaterialPhysicalParams, MaterialsFactory } from './MaterialsFactory';
import { Color } from '../3rdParty/three';
import { validatedColor } from './ColorsExtractors';
import { getMatTemplateFromString, similarityToKeyword } from './MaterialsTemplates';
import { KrMath } from 'math-ts';
import { frosted, glossy, matte } from './Keywords';

export const TransparencyThreshold = 0.2;


export class StdMaterialTemplate {
    constructor(
        public name: string = '',
        public color?: string,
        public metalness?: number,
        public roughness?: number,
        public transparency?: number,
    ) {
    }

    public static fromBim(name: string, bimParams: BimMaterialStdRenderParams) {
        return new StdMaterialTemplate(
            name,
            bimParams.color ?? undefined,
            bimParams.metalness,
            bimParams.roughness,
            bimParams.transparency
        )
    }

}

export function newStdMaterialTemplate(t: Partial<StdMaterialTemplate>): StdMaterialTemplate {
	return {
		name: t.name || "",
		color: t.color || undefined,
		metalness: t.metalness || undefined,
		roughness: t.roughness || undefined,
		transparency: t.transparency || undefined,
	}
}

export enum EngineMaterialType {
	StdMaterial,
}

export type IdEngineMaterial = EntityId<EngineMaterialType>;

export class EngineStdMaterials extends EntitiesBimSyncedInterned<
    StdMaterialTemplate, EngineMaterialType,
    BimMaterial, BimMaterialType
> {

    constructor(
        logger: ScopedLogger,
        sourceCollection: EntitiesBase<BimMaterial, any, any>,
    ) {
        super({
            identifier: 'engine-std-materials',
            logger,
            idsType: EngineMaterialType.StdMaterial,
            T_Constructor: StdMaterialTemplate,
            uniqueReducerFn: (mt) => JSON.stringify(mt),
            sourceCollection: sourceCollection,
        });
    }

	_physicalParamsCache = new DefaultMap<IdEngineMaterial, MaterialPhysicalParams | null>(
		(id) => {
			let mt = this.peekById(id);
			if (!mt) {
				if (id == EngineMaterialId.Default as unknown as IdEngineMaterial) {
					mt = { name: 'default', transparency: 0, metalness: 0, roughness: 1, color: '#BBBBBB' };
				} else {
					return null;
				}
			}
			return this._createPhysicalParamsFromTemplate(mt);
		}
	)

    convertFromInternedType(bimObj: BimMaterial): Result<StdMaterialTemplate> {
        return new Success(StdMaterialTemplate.fromBim(bimObj.name, bimObj.stdRenderParams));
    }

    checkForErrors(t: StdMaterialTemplate, errors: string[]): void {
    }

    isTransparent(id: IdEngineMaterial | EngineMaterialId): boolean {
        if (id < EngineMaterialId.SpecialMaterialsStart) {
            const m = this.perId.get(id as IdEngineMaterial);
            return !!(m && m.transparency && m.transparency > TransparencyThreshold);
        } else {
			return TransparentMaterials.includes(id as EngineMaterialId);
        }
	}

	getSimpleMatForStd(std_mat: IdEngineMaterial, flags: ShaderFlags): EngineMaterialIdFlags {
		if (this.isTransparent(std_mat)) {
			return GetMaterialIDF(EngineMaterialId.SimpleTransparent, flags);
		} else {
			return GetMaterialIDF(EngineMaterialId.SimpleOpaque, flags);
		}
	}

	_createPhysicalParamsFromTemplate(matParams: StdMaterialTemplate): MaterialPhysicalParams {
		const materialName = MaterialsFactory.cleanUpMaterialName(matParams.name);
		let paramColor: Color | null = null;
		if (matParams.color) {
			paramColor = new Color(-1, -1, -1);
			paramColor = validatedColor(paramColor.set(matParams.color));
			if (paramColor === null && typeof matParams.color == 'string') {
				paramColor = MaterialsFactory.extractColorFromString(matParams.color);
			}
			if (paramColor === null) {
				this.logger.warn(`could not parse ${ materialName } color - ${ matParams.color}`);
			}
		}
		// paramColor = new Color(0xFF0000);

		// extract mat type keywords
		const templ = getMatTemplateFromString(materialName);
		let color = paramColor || MaterialsFactory.extractColorFromString(materialName) || (!templ.texName && templ.color) || null;
		let metalness = matParams.metalness !== undefined ? matParams.metalness : templ.metalness;
		let roughness = matParams.roughness !== undefined ? matParams.roughness : templ.roughness;

		let opacity;
		if (matParams.transparency != undefined) {
			opacity = 1 - KrMath.clamp(matParams.transparency, 0, 1);
		} else {
			opacity = templ.isTransparent() ? 0.25 : 1.0;
		}
		{
			const tokens = materialName.split(' ');

			for (const kw of [matte, frosted]) {
				for (const t of tokens) {
					const s = similarityToKeyword(t, kw);
					if (s >= 0.9) {
						if (matParams.roughness === undefined) {
							roughness = KrMath.lerp(roughness, 1.0, 0.5);
						}
						if (matParams.transparency === undefined) {
							opacity = KrMath.lerp(opacity, 1.0, 0.3);
						}
						break;
					}
				}
			}

			if (matParams.roughness === undefined) {
				for (const t of tokens) {
					const s = similarityToKeyword(t, glossy);
					if (s >= 0.9) {
						roughness = KrMath.lerp(roughness, 0.0, 0.5);
						break;
					}
				}
			}
		}

		let textPath = '';
		let tiling = 0;
		const isTransparent = opacity < (1 - TransparencyThreshold);
		if (isTransparent) {
			opacity = opacity;
		} else {
			textPath = templ.texName;
			tiling = templ.tiling;
		}

		return new MaterialPhysicalParams(materialName, textPath, roughness, metalness, tiling, opacity, color, templ.color);
	}

}
