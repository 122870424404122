import { NumberProperty } from "bim-ts"
import { Success } from 'engine-utils-ts';

export class TableEntry {
    constructor(
        public path: string[] = [],
        public quantity: NumberProperty | null = null,
        public rate: NumberProperty | null = null,
        public totalCost: NumberProperty | null = null,
        public order: number | null = null,
        public quantityOverride: boolean = false,
        public rateOverride: boolean = false,
    ) {}
}

export class TableHierarchy {
    private entries = new Map<string, TableEntry>()
    addEntry(entry: TableEntry) {
        this.entries.set(joinPath(entry.path), entry);
        return this;
    }
    getEntry(path: string[]): TableEntry | null {
        return this.entries.get(joinPath(path)) ?? null;
    }
    getEntriesWithPrefix(prefix: string[]): TableEntry[] {
        const result: TableEntry[] = [];
        const startsWith = joinPath(prefix);
        for (const [mergedPath, entry] of this.entries) {
            if (mergedPath.startsWith(startsWith)) {
                result.push(entry);
            }
        }
        return result;
    }
    getChildren(path: string[]) {
      return this.getEntriesWithPrefix(path)
        .filter(x => x.path.length === path.length + 1)
    }
    delete(path: string[]) {
        return this.entries.delete(joinPath(path));
    }
    merge(other: TableHierarchy) {
        const entries = other.getEntriesWithPrefix([]) ?? [];
        for (const entry of entries) {
            this.addEntry(entry);
        }
        return this;
    }

    leafRelactulation(leafPath: string[]) {

      let leaf = this.getEntry(leafPath)!;
      // calculate leaf
      if (leaf.quantity && leaf.rate) {
        leaf.totalCost = NumberProperty.new({
            unit: leaf.rate.unit,
            value: leaf.quantity.value * leaf.rate.value,
        });
      }

      let groupPath = leafPath.slice(0, -1);

      // traverse branch
      while (groupPath.length) {
        const entry = this.getEntry(groupPath) ?? new TableEntry(groupPath);
        this.addEntry(entry);
        const children = this.getChildren(groupPath);
        const nonEmptyQtys = children.map(x => x.quantity)
            .filter((x): x is NumberProperty => x instanceof NumberProperty);
        const nonEmptyCosts = children.map(x => x.totalCost)
            .filter((x): x is NumberProperty => x instanceof NumberProperty);

        const totalQuantity = NumberProperty.unitBasedSum(nonEmptyQtys);
        const totalCost =  NumberProperty.unitBasedSum(nonEmptyCosts);

        if (children.length === nonEmptyQtys.length && totalQuantity instanceof Success) {
            entry.quantity = totalQuantity.value;
        } else {
            entry.quantity = null;
        }

        if (totalCost instanceof Success) {
            entry.totalCost = totalCost.value;
        } else {
            entry.totalCost = null;
        }

        entry.rate = null;

        groupPath = groupPath.slice(0, -1);
      }
    }
}

export type GroupedTableHierarchy = Array<TableEntry | GroupedTableHierarchyLevel>
type GroupedTableHierarchyLevel = {
    level: string,
    items: GroupedTableHierarchy,
    order: number,
}
const bigNum = 100000;

export function groupHierarchy(hierarchy: TableHierarchy): GroupedTableHierarchy {
    const result: GroupedTableHierarchy = [];
    for (const entry of hierarchy.getEntriesWithPrefix([])) {
        // create correct hierarchy
        let root = result;
        for (const level of entry.path) {
            let child = root
                .filter((x): x is GroupedTableHierarchyLevel => 'level' in x)
                .find((x) => x.level === level)
            if (!child) {
                child = {
                    level,
                    items: [],
                    order: entry.order ?? bigNum,
                }
                root.push(child);
            }
            child.order = Math.min(entry.order ?? bigNum, child.order);
            root = child.items;
        }
        root.push(entry);
    }
    return result;
}

function isTableEntry(x: GroupedTableHierarchy[0]): x is TableEntry {
    return 'path' in x;
}
function isLevel(x: GroupedTableHierarchy[0]): x is GroupedTableHierarchyLevel {
    return 'items' in x;
}
function sortItems(a: GroupedTableHierarchy[0], b: GroupedTableHierarchy[0]) {
    const dir = -1;
    if (isTableEntry(a)) {
        if (isTableEntry(b)) {
            return 0 * dir;
        } else {
            return 1 * dir;
        }
    } else {
        if (isTableEntry(b)) {
            return -1 * dir;
        } else {
            return Math.sign(b.order - a.order) * dir;
        }
    }
}

function joinPath(path: string[]) {
    return path.map(x => `[${x}]`).join('');
}

export function sortHierarchy(hierarchy: GroupedTableHierarchy) {
    for (const item of hierarchy) {
        if (isLevel(item)) {
            sortHierarchy(item.items);
        }
    }
    hierarchy.sort(sortItems);
}
