import type { ObjectUniquePrimitiveHash } from ".";
import { LegacyLogger } from ".";

export class MapObjectKey<K, V> implements Map<K, V> {

    private valuePerHash = new Map<ObjectUniquePrimitiveHash, V>();
    private keyPerHash = new Map<ObjectUniquePrimitiveHash, K>();

    constructor(private uniqueHash: (o: K) => ObjectUniquePrimitiveHash) {

    }

    clear(): void {
        this.valuePerHash.clear();
        this.keyPerHash.clear();
    }
    delete(key: K): boolean {
        const hash = this.uniqueHash(key);
        this.keyPerHash.delete(hash);
        return this.valuePerHash.delete(hash);
    }
    forEach(callbackfn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any): void {
        return this.valuePerHash.forEach(
            (V, hash) => {
                const k = this.keyPerHash.get(hash);
                if (!k) throw new Error('no value found for hash');
                return callbackfn(V, k, thisArg)
            },
            thisArg
        )
    }
    get(key: K): V | undefined {
        return this.valuePerHash.get(this.uniqueHash(key));
    }
    has(key: K): boolean {
        return this.valuePerHash.has(this.uniqueHash(key));
    }
    set(key: K, value: V): this {
        if (!Object.isFrozen(key)) {
            LegacyLogger.deferredError('key should be frozen', key);
        }
        const hash = this.uniqueHash(key);
        this.valuePerHash.set(hash, value);
        this.keyPerHash.set(hash, key);
        return this;
    }
    get size() {
        return this.valuePerHash.size;
    }
    *entries(): IterableIterator<[K, V]> {
        for (const [hash, value] of this.valuePerHash) {
            const key = this.keyPerHash.get(hash);
            if (key === undefined) throw new Error('key not found for hash')
            yield [key, value];
        }
    }
    keys(): IterableIterator<K> {
        return this.keyPerHash.values();
    }
    values(): IterableIterator<V> {
        return this.valuePerHash.values();
    }
    [Symbol.iterator](): IterableIterator<[K, V]> {
        return this.entries();
    }
    [Symbol.toStringTag] = 'MapObjectKey';
}


