import { ObjectUtils, Success, unitsConverter } from 'engine-utils-ts';
import type { InstanceCost } from 'src/cost-model/capital';
import { mergeCostComponents, sumCostComponents } from 'src/cost-model/capital';
import { createDefaultPileCostsPerEach, createDefaultPileCostsPerKg } from 'src/cost-model/capital/tables/categories/structural/piles';
import type { AnyTrackerProps } from '../anyTracker/AnyTracker';
import { calculateTrackersPilesTopsPositionsInLocalCoords } from '../anyTracker/AnyTrackerMeshgen';
import { calculatePileWeight, chooseDefaultPileCrossSectionFor, pileCrossSectionToString, PileProfileType, pileWeightPerSize } from '../anyTracker/PileProfileType';
import { getDefaultPileFeaturesFor, getPileDamperType, getPileFeaturesWithUndulation, getPileMotorType, getPileStrengthModifier, getPileUndulationType, getPileWeightClass, PileMotorType, PileUndulationType, type PileFeaturesFlags } from '../anyTracker/PilesFeatures';
import { BimProperty } from '../bimDescriptions/BimProperty';
import { TrackerPartType, trackersPartsCache, type TrackerPart } from '../trackers/Tracker';
import { FixedTrackerPilesPropsGroup, getFixedTrackerPilesRelatedPropsFromPropsGroup, getSolarTrackerPilesRelatedPropsFromPropsGroup, PileKeyProps, SolarTrackerPilesPropsGroup } from './common';
import type { TrackerBins } from './PileBinsConfig';
import type { IdBimScene, SceneInstance } from '../scene/SceneInstances';
import { Vector3, type Matrix4 } from 'math-ts';



const const_bs_props = [
    BimProperty.NewShared({path: ["cost_bs | level 1"], value: "FURNISH PILES SUBTOTAL"}),
    BimProperty.NewShared({path: ["cost_bs | level 2"], value: "Pile install"}),
];

export class TrackerPile {

    constructor(
        public readonly parentId: IdBimScene,
        public readonly inArrayIndex: number,
        public readonly isHidden: boolean,
        public readonly position: Vector3,
        public readonly features: PileFeaturesFlags,
        public readonly shape: PileProfileType,
        public readonly length: number,
        public readonly distanceToTheGround: number,
    ) {

    }

    static equals(l: TrackerPile, r: TrackerPile): boolean {
        return Object.is(l.parentId, r.parentId)
            && Object.is(l.inArrayIndex, r.inArrayIndex)
            && Object.is(l.isHidden, r.isHidden)
            && Object.is(l.features, r.features)
            && Object.is(l.shape, r.shape)
            && Object.is(l.distanceToTheGround, r.distanceToTheGround)
            && Object.is(l.length, r.length)
            && l.position.equals(r.position)
        ;
    }

    get reveal(): number {
        return Math.min(this.length, Math.max(this.distanceToTheGround, 0));
    }

    get embedment(): number {
        return Math.max(this.length - this.distanceToTheGround, 0);
    }

    getMotorType() {
        return getPileMotorType(this.features);
    }

    getStrengthModifier() {
        return getPileStrengthModifier(this.features);
    }

    getWeightClass() {
        return getPileWeightClass(this.features);
    }

    getDamperType() {
        return getPileDamperType(this.features);
    }

    getUndulationType() {
        return getPileUndulationType(this.features);
    }

    getLength(): {value: number, unit: string} {
        return {value: this.length, unit: 'm'};
    }
    getReveal(): {value: number, unit: string} {
        return {value: this.reveal, unit: 'm'};
    }
    getEmbedment(): {value: number, unit: string} {
        return {value: this.embedment, unit: 'm'};
    }

    asBimProperties(args: {area_index?: number, trackerBin?: TrackerBins, instanceCosts?: readonly InstanceCost[]}): BimProperty[] {
        const weightResult = calculatePileWeight(this.shape, {value: this.length, unit: 'm'});
        const weight = weightResult instanceof Success ? weightResult.value : {value: 0, unit: 'kg'};
        const roundValue = (value: number) => Math.round(value * 1e3) / 1e3;
        const result: BimProperty[] = [
            BimProperty.NewShared({path: ["pile | undulation"], value: PileUndulationType[this.getUndulationType()]}),
            BimProperty.NewShared({path: ["pile | length"], value: roundValue(this.length), unit: 'm'}),
            BimProperty.NewShared({path: ["pile | reveal"], value: roundValue(this.reveal), unit: 'm'}),
            BimProperty.NewShared({path: ["pile | embedment"], value: roundValue(this.embedment), unit: 'm'}),
            BimProperty.NewShared({path: ["pile | size"], value: pileCrossSectionToString(this.shape)}),
            BimProperty.NewShared({path: ["pile | weight"], value: roundValue(weight.value), unit: weight.unit}),

            ...const_bs_props,
        ];
        if(args?.area_index != undefined){
            result.push(BimProperty.NewShared({path: ["circuit | position | area_index"], value: args.area_index}));
        }
        let fullName = "";
        let shortName = "";
        if(args?.trackerBin != undefined){
            fullName = args.trackerBin.getPileFullName(this.features);
            const withoutUndulatedFlag = getPileFeaturesWithUndulation(
                this.features,
                PileUndulationType.Undulated
            );
            shortName = args.trackerBin.getTypeByFeaturesKey(withoutUndulatedFlag)?.shortName.value ?? "";
        }
        result.push(BimProperty.NewShared({path: ["pile | type"], value: shortName }));
        result.push(BimProperty.NewShared({path: ["pile | position"], value: fullName }));
        const cost = args.instanceCosts ? this.calculatePileCost(args.instanceCosts) : {value: 0, unit: 'usd'};
        result.push(BimProperty.NewShared({path: ["pile | price"], value: roundValue(cost.value), unit: cost.unit}));
        return result;
    }

    static calculatePileCost(pile: TrackerPile, instanceCosts: readonly InstanceCost[]): {value: number, unit: string} {
        const uniqueProps: typeof PileKeyProps = {
            size: PileKeyProps.size.withDifferentValue(pile.shape),
            type: PileKeyProps.type.withDifferentValue(pile.features),
        };
        const costs = instanceCosts.filter(x => ObjectUtils.areObjectsEqual(x.props, uniqueProps));
        const eachCost = sumCostComponents(mergeCostComponents(costs.find(x => x.name === 'each')?.costs, createDefaultPileCostsPerEach()));
        const kgCost = sumCostComponents(mergeCostComponents(costs.find(x => x.name === 'kg')?.costs, createDefaultPileCostsPerKg()));

        const weightValueUnit = pileWeightPerSize(pile.shape);
        const weightPerMeter = unitsConverter.convertValue(weightValueUnit.value, weightValueUnit.unit, 'kg/m');
        const weightKg = pile.length * weightPerMeter;
        const pileTotalCost = kgCost * weightKg + eachCost;
        return {value: pileTotalCost, unit: 'usd'};
    }

    calculatePileCost(instanceCosts: readonly InstanceCost[]) {
        return TrackerPile.calculatePileCost(this, instanceCosts);
    }
}



export function extractPilesFromTrackerProps(id: IdBimScene, isHidden: boolean, trackerProps: AnyTrackerProps, wm: Matrix4): TrackerPile[] {

    const result: TrackerPile[] = [];
    if(!trackerProps.piles.active_configuration){
        console.warn("Tracker piles configuration is not set");
        return result;
    }

    const pilesPositions = calculateTrackersPilesTopsPositionsInLocalCoords(trackerProps);
    for (let i = 0; i < pilesPositions.length; ++i) {

        const pileFeatures = trackerProps.piles.active_configuration.features[i];
        const profile = trackerProps.piles.profiles?.at(i) ?? PileProfileType.None;
        const length = trackerProps.piles.lengths?.at(i) ?? 0;
        const distanceToTheGround = trackerProps.piles._pile_tops_distance_to_ground?.at(i) ?? Infinity;

        const pile = new TrackerPile(
            id,
            i,
            isHidden,
            pilesPositions[i].clone().applyMatrix4(wm),
            pileFeatures,
            profile,
            length,
            distanceToTheGround,
        );

        result.push(pile);
    }

    return result;
}

export function extractPilesFromFixedTracker(id: IdBimScene, si: SceneInstance): TrackerPile[] {
    const result = extractPilesFromFixedTrackerFromPropsGroup(
        id,
        si.isHidden,
        si.properties.extractPropertiesGroup(FixedTrackerPilesPropsGroup),
        si.worldMatrix,
    )
    return result;

}

export function extractPilesFromFixedTrackerFromPropsGroup(
    id: IdBimScene,
    isHidden: boolean,
    pilesRelatedProps: typeof FixedTrackerPilesPropsGroup,
    siMatrix: Matrix4,
): TrackerPile[] {

    const props = getFixedTrackerPilesRelatedPropsFromPropsGroup(isHidden, pilesRelatedProps);

    const result: TrackerPile[] = [];

    for (let i = 0; i < props.piles_count; ++i) {
        const pileFeatures = getDefaultPileFeaturesFor(
            props.load_position,
            i,
            props.piles_count,
            PileMotorType.None,
            PileUndulationType.Undulated,
        );

        const profile = chooseDefaultPileCrossSectionFor(pileFeatures, props.pile_length_meter);

        // FAKE POSITION, to be replace with real positions when fixed tilt is upgraded to new props
        const position = new Vector3(0, ((i / (props.piles_count - 1)) - 0.5) * props.max_length_meter, 0).applyMatrix4(siMatrix);


        const pile = new TrackerPile(
            id,
            i,
            isHidden,
            position,
            pileFeatures,
            profile,
            props.pile_length_meter,
            props.piles_max_reveal_meter,
        );
        result.push(pile);
    }

    return result;
}

export function extractPilesFromLegacyTrackerPropsGroup(
    id: IdBimScene,
    isHidden: boolean,
    pilesRelatedProps: typeof SolarTrackerPilesPropsGroup,
    siMatrix: Matrix4,
) {
    const props = getSolarTrackerPilesRelatedPropsFromPropsGroup(isHidden, pilesRelatedProps);
    const parts = trackersPartsCache.acquire({
        modulesPerStringCountHorizontal: props.string_modules_count_x,
        stringsPerTrackerCount: props.tracker_strings_count,
        moduleBayCount: props.tracker_module_bay_size,
        moduleSize: props.module_size_x_meter,
        motorPlacementCoefficient: props.tracker_motor_placement,
        pileGap: props.tracker_pile_bearings_gap_meter,
        motorGap: props.tracker_motor_gap_meter,
        stringGap: props.tracker_strings_gap_meter,
        modulesGap: props.modules_gap_meter,
        modulesRow: props.modules_row,
        useModulesRow: props.use_modules_row,
    }).parts;

    const pileParts:TrackerPart[] = [];
    for (const part of parts) {
        if (part.ty & TrackerPartType.Pile) {
            pileParts.push(part);
        }
    }

    const result: TrackerPile[] = [];

    for (let i = 0; i < pileParts.length; i++) {
        const pilePart = pileParts[i];

        const pileFeatures = getDefaultPileFeaturesFor(
            props.load_position,
            i,
            pileParts.length,
            pilePart.ty & TrackerPartType.Motor ? PileMotorType.Motor : PileMotorType.None,
            PileUndulationType.Undulated,
        );

        const profile = chooseDefaultPileCrossSectionFor(pileFeatures, props.tracker_pile_length_meter);

        const position = new Vector3(0, pilePart.centerOffset - props.tracker_pile_length_meter * 0.5, 0).applyMatrix4(siMatrix);

        const pile = new TrackerPile(
            id,
            i,
            isHidden,
            position,
            pileFeatures,
            profile,
            props.tracker_pile_length_meter,
            props.piles_max_reveal_meter,
        );
        result.push(pile);
    };
    return result;
}

export function extractPilesFromLegacyTracker(id: IdBimScene, si: SceneInstance): TrackerPile[] {
    const result = extractPilesFromLegacyTrackerPropsGroup(
        id,
        si.isHidden,
        si.properties.extractPropertiesGroup(SolarTrackerPilesPropsGroup),
        si.worldMatrix,
    )
    return result;
}

