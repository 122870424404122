import type { Bim } from "../Bim";
import { BimProperty } from "../bimDescriptions/BimProperty";
import type { PropertiesGroupFormatters } from "../bimDescriptions/PropertiesGroupFormatter";
import { PropertiesGroupFormatter } from "../bimDescriptions/PropertiesGroupFormatter";
import { BimPropertiesFormatter, sceneInstanceHierarchyPropsRegistry } from "../catalog/SceneInstanceHierarchyPropsRegistry";
import type { AssetBasedCatalogItemCreators } from "../catalog/CatalogItemCollection";
import type { SceneInstanceShapeMigration } from "../scene/SceneInstancesArhetypes";
import { create_expandCommonEquipmentPropsWithCostTableLinks, registerEquipmentCommonAssetToCatalogItem } from "./EquipmentCommon";
import { removeEnergyPropsMigration } from "./transformer/Transformer";
import { PropsFieldFlags, PropsFieldOneOf, PropsGroupBase, PropsGroupsRegistry } from "src";
import { NestedLvWires } from "./LvWire";

export const CombinerBoxTypeIdent = 'combiner-box';

export class CombinerBoxProps extends PropsGroupBase {
    lv_wiring: NestedLvWires | null;
    constructor(args: Partial<CombinerBoxProps>) {
        super()
        this.lv_wiring = args.lv_wiring ?? null;
    }
}

PropsGroupsRegistry.register({
    class: CombinerBoxProps,
    complexDefaults: {
        lv_wiring: new PropsFieldOneOf(
            PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization,
            null,
            NestedLvWires,
        ),
    }
});


export function registerCombinerBox(bim: Bim){
	bim.instances.archetypes.registerArchetype(
		{
			type_identifier: CombinerBoxTypeIdent,
			mandatoryProps: [
                { path: ['cost_bs', 'level 1'], value: "ELECTRICAL SUBTOTAL", },
			],
			propsShapeMigrations: migrations(),
			propsClass: CombinerBoxProps,
		}
	);

}

function migrations() : SceneInstanceShapeMigration[]{
	return [
		{
			toVersion: 1,
			validation: {
				updatedProps: [
					{path: ['cost_bs', 'level 1']},
					{path: ['cost_bs', 'level 2']},
				],
				deletedProps: [],
			},
			patch: (inst)=>{
                const type = inst.properties.get('commercial | type')?.asText();
                if(type?.startsWith('DC')) {
                    inst.properties.applyPatch([
                        ['cost_bs | level 1', { path: ['cost_bs', 'level 1'], value: "ELECTRICAL SUBTOTAL", }],
                        ['cost_bs | level 2', { path: ['cost_bs', 'level 2'], value: "DC", }],
                    ]);
                } else {
                    inst.properties.applyPatch([
                        ['cost_bs | level 1', { path: ['cost_bs', 'level 1'], value: "ELECTRICAL SUBTOTAL", }],
                        ['cost_bs | level 2', { path: ['cost_bs', 'level 2'], value: "AC", }],
                    ]);
                }
			}
		},
        removeEnergyPropsMigration(2),
	]
}

export const CombinerBoxKeyProps = {
    type: BimProperty.NewShared({
        path: ['commercial', 'type'],
        value: 'unknown',
    }),
    model: BimProperty.NewShared({
        path: ['commercial', 'model'],
        value: 'unknown',
    }),
    current: BimProperty.NewShared({
        path: ['input', 'current'],
        value: 0,
        unit: 'A',
    }),
    voltage: BimProperty.NewShared({
        path: ['input', 'voltage'],
        value: 0,
        unit: 'V',
    }),
};

export function registerCombinerBoxKeyPropsGroupFormatter(group: PropertiesGroupFormatters) {
    group.register(
        CombinerBoxTypeIdent,
        new PropertiesGroupFormatter(
            CombinerBoxKeyProps,
            (props, unitsMapper) => {
                return [
                    [
                        props.type,
                        props.model,
                    ].map(x => x.asText()).join(' '),
                    props.current.valueUnitUiString(unitsMapper),
                ].join(' ');
            }
        )
    )
}

export function registerCombinerBoxAssetToCatalogItem(group: AssetBasedCatalogItemCreators) {
    registerEquipmentCommonAssetToCatalogItem(CombinerBoxTypeIdent, group);
}

sceneInstanceHierarchyPropsRegistry.set(
    CombinerBoxTypeIdent,
    [
        new BimPropertiesFormatter(
            {
                type: BimProperty.NewShared({
                    path: ['commercial', 'type'],
                    value: 'unknown',
                })
            },
            (props) => [props.type.asText() || 'type not specified'],
        )
    ]
);

export function create_expandCombinerBoxPropsWithCostTableLinks() {
    return create_expandCommonEquipmentPropsWithCostTableLinks(CombinerBoxKeyProps);
}
