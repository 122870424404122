

export interface SolversExecutionMetrics {
    ident: string;
    time: number;
    count: number;
}

export class DurationTimer {

    startTime: number = 0;

    reset() {
        this.startTime = performance.now();
        return this;
    }

    consumeDuration() {
        const dur = (performance.now() - this.startTime);
        this.startTime = performance.now();
        return dur;
    }
}
