// automatically generated by the FlatBuffers compiler, do not modify

import { ImportFile } from '../wire/import-file.js';
import { SaveByUser } from '../wire/save-by-user.js';


export enum Tasks {
  NONE = 0,
  SaveByUser = 1,
  ImportFile = 2
}

export function unionToTasks(
  type: Tasks,
  accessor: (obj:ImportFile|SaveByUser) => ImportFile|SaveByUser|null
): ImportFile|SaveByUser|null {
  switch(Tasks[type]) {
    case 'NONE': return null; 
    case 'SaveByUser': return accessor(new SaveByUser())! as SaveByUser;
    case 'ImportFile': return accessor(new ImportFile())! as ImportFile;
    default: return null;
  }
}

export function unionListToTasks(
  type: Tasks, 
  accessor: (index: number, obj:ImportFile|SaveByUser) => ImportFile|SaveByUser|null, 
  index: number
): ImportFile|SaveByUser|null {
  switch(Tasks[type]) {
    case 'NONE': return null; 
    case 'SaveByUser': return accessor(index, new SaveByUser())! as SaveByUser;
    case 'ImportFile': return accessor(index, new ImportFile())! as ImportFile;
    default: return null;
  }
}
