import { LegacyLogger } from '.';


export class RefsCounter<Id> {
    readonly _counts: Map<Id, number> = new Map();

    constructor() {}

    increment(id: Id) {
        const current = this._counts.get(id);
        this._counts.set(id, current === undefined ? 1 : current + 1);
    }
    decrement(id: Id) {
        const current = this._counts.get(id);
        if (current === undefined) {
            LegacyLogger.deferredError("decrementing count to non existing id, probably double decrement bug", id);
            return;
        }
        if (current === 1) {
            this._counts.delete(id);
        } else {
            this._counts.set(id, current - 1);
        }
    }

    hasAnyRefsTo(id: Id): boolean {
        return this._counts.get(id) != undefined;
    }
}
