<script lang="ts">
    export let showName: boolean;
</script>

<div class="intercom-button mdc-typography--nav-l2">
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.80001 3.8002V16.2002H13.8486C14.1053 16.2002 14.3562 16.2762 14.5697 16.4185L16.2 17.5054V3.8002H3.80001ZM2.20001 3.5002C2.20001 2.78222 2.78204 2.2002 3.50001 2.2002H16.5C17.218 2.2002 17.8 2.78222 17.8 3.5002V18.0659C17.8 19.1042 16.6428 19.7235 15.7789 19.1476L13.7578 17.8002H3.50001C2.78204 17.8002 2.20001 17.2182 2.20001 16.5002V3.5002Z"
            fill="white"
        />
        <path
            d="M5.83433 11.056C6.07894 10.6891 6.57418 10.5895 6.94158 10.8327L6.94774 10.8367C6.95459 10.8411 6.96685 10.8488 6.98429 10.8594C7.0192 10.8806 7.07463 10.9133 7.14868 10.9537C7.2971 11.0347 7.51831 11.1455 7.79709 11.257C8.35836 11.4815 9.13034 11.6998 9.99997 11.6998C10.8696 11.6998 11.6416 11.4815 12.2029 11.257C12.4816 11.1455 12.7028 11.0347 12.8513 10.9537C12.9253 10.9133 12.9808 10.8806 13.0157 10.8594C13.0331 10.8488 13.0454 10.8411 13.0522 10.8367L13.0584 10.8327C13.4258 10.5895 13.921 10.6891 14.1656 11.056C14.4107 11.4237 14.3114 11.9204 13.9437 12.1654L13.9419 12.1667L13.9394 12.1683L13.9328 12.1726L13.9131 12.1854C13.897 12.1956 13.8751 12.2094 13.8476 12.2261C13.7926 12.2596 13.7153 12.305 13.6174 12.3584C13.4221 12.4649 13.1433 12.6041 12.7971 12.7426C12.1084 13.0181 11.1303 13.2998 9.99997 13.2998C8.86961 13.2998 7.89159 13.0181 7.20286 12.7426C6.85664 12.6041 6.57785 12.4649 6.38252 12.3584C6.28469 12.305 6.20732 12.2596 6.15238 12.2261C6.12489 12.2094 6.10298 12.1956 6.08688 12.1854L6.06711 12.1726L6.06054 12.1683L6.0581 12.1667L6.05709 12.166L6.05621 12.1654C5.68859 11.9204 5.58925 11.4237 5.83433 11.056Z"
            fill="white"
        />
    </svg>
    {#if showName}
        <span>Get assistance</span>
    {/if}
</div>

<style>
    .intercom-button {
        margin: 4px 4px 0;
        display: flex;
        padding: 4px 6px;
        align-items: center;
        gap: 10px;
        background-color: var(--color-accent);
        border-radius: 4px;
        cursor: pointer;
        user-select: none;
        color: white;
    }
</style>
