import { DefaultMap } from 'engine-utils-ts';
import type { Builder } from 'flatbuffers';

import { BimProperty } from '../bimDescriptions/BimProperty';
import type { BimProperty as BimPropertyF} from '../schema/bim-property';
import { BimPropertyT } from '../schema/bim-property';
import { BimPropertyValue as BimPropertyValueType } from '../schema/bim-property-value';
import { BoolValueT } from '../schema/bool-value';
import { NumericValueT } from '../schema/numeric-value';
import { RangeT } from '../schema/range';
import { StringValueT } from '../schema/string-value';

export class LegacyPropsSerializer {

    readonly _stringOffsets: DefaultMap<string, number>;
    readonly _alreadySerialized: DefaultMap<BimProperty, number>;

    constructor(builder: Builder) {
        this._stringOffsets = new DefaultMap((str) => {
            return builder.createString(str);
        });
        this._alreadySerialized = new DefaultMap((prop) => {

            const propType = typeof prop.value == 'number'
                ? BimPropertyValueType.NumericValue : typeof prop.value == 'string'
                    ? BimPropertyValueType.StringValue : BimPropertyValueType.BoolValue;

            let value: BoolValueT|NumericValueT|StringValueT;
            if (propType === BimPropertyValueType.NumericValue) {
                value = new NumericValueT(
                    prop.value as number,
                    prop.numeric_step,
                    prop.discrete_variants as number[] ?? [],
                    prop.numeric_range ? new RangeT(prop.numeric_range[0], prop.numeric_range[1]) : null,
                    prop.unit
                );
            } else if (propType === BimPropertyValueType.StringValue) {
                value = new StringValueT(
                    prop.value as string,
                    prop.discrete_variants as string[] ?? [],
                );
            } else {
                value = new BoolValueT(!!prop.value);
            }

            const bimProp = new BimPropertyT(
                prop.path,
                prop.description,
                prop.isComputedBy,
                propType,
                value,
                prop.readonly
            );

            return bimProp.pack(builder);
        });
    }

    serializeString(builder: Builder, s: string): number {
        return this._stringOffsets.getOrCreate(s);
    }

    serializeProp(builder: Builder, property: BimProperty): number {
        return this._alreadySerialized.getOrCreate(property);
    }

    // serializePropsTypesVersions(): number {
    //     const propsClasses = IterUtils.map(this._alreadySerialized.values(), p => p.constructor);

    // }
}

export class LegacyPropsDeserializer {

    readonly _deserializedPropsPerOffset = new Map<number, BimProperty | null>();

    constructor() {
    }

    deserProp(flatbufProp: BimPropertyF): BimProperty | null {
        const offset = flatbufProp.bb_pos;
        let deserialized = this._deserializedPropsPerOffset.get(offset);
        if (deserialized === undefined) {
            try {
                deserialized = this._unpackFlatbufProperty(flatbufProp);
            } catch (e) {
                console.error('error deserializing legacy property', e);
                deserialized = null;
            }
            this._deserializedPropsPerOffset.set(offset, deserialized);
        }
        return deserialized;
    }

    _unpackFlatbufProperty(flatbufProp: BimPropertyF) {
        const p = new BimPropertyT();
        flatbufProp.unpackTo(p);

        let discrVariants = null;
        let propValue = p.value!.value;
        if (p.value instanceof StringValueT) {
            if (p.value.discreteVariants.length) {
                discrVariants = p.value.discreteVariants;
            }
            propValue = propValue ?? "";
        } else  if (p.value instanceof NumericValueT && p.value.discreteVariants?.length) {
            discrVariants = p.value.discreteVariants;
        }
        return BimProperty.NewShared({
            path: p.path!,
            description: p.description as string ?? "",
            isComputedBy: p.computedBy as string ?? "",
            value: propValue,
            unit: p.value instanceof NumericValueT ? p.value.unit as string ?? "" : "",
            numeric_step: p.value instanceof NumericValueT ? p.value.step : 0,
            discrete_variants: discrVariants,
            numeric_range: p.value instanceof NumericValueT && p.value.range ? [p.value.range.min, p.value.range.max] : null,
            readonly: p.readonly,
        });
    }
}
