// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { CompressionType } from '../wire/compression-type.js';


export class HubMessage implements flatbuffers.IUnpackableObject<HubMessageT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):HubMessage {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsHubMessage(bb:flatbuffers.ByteBuffer, obj?:HubMessage):HubMessage {
  return (obj || new HubMessage()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsHubMessage(bb:flatbuffers.ByteBuffer, obj?:HubMessage):HubMessage {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new HubMessage()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

guid():string|null
guid(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
guid(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

payload(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index) : 0;
}

payloadLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

payloadArray():Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? new Uint8Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

payloadCompression():CompressionType {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : CompressionType.None;
}

static startHubMessage(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addGuid(builder:flatbuffers.Builder, guidOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, guidOffset, 0);
}

static addPayload(builder:flatbuffers.Builder, payloadOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, payloadOffset, 0);
}

static createPayloadVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset {
  builder.startVector(1, data.length, 1);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt8(data[i]!);
  }
  return builder.endVector();
}

static startPayloadVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(1, numElems, 1);
}

static addPayloadCompression(builder:flatbuffers.Builder, payloadCompression:CompressionType) {
  builder.addFieldInt32(2, payloadCompression, CompressionType.None);
}

static endHubMessage(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createHubMessage(builder:flatbuffers.Builder, guidOffset:flatbuffers.Offset, payloadOffset:flatbuffers.Offset, payloadCompression:CompressionType):flatbuffers.Offset {
  HubMessage.startHubMessage(builder);
  HubMessage.addGuid(builder, guidOffset);
  HubMessage.addPayload(builder, payloadOffset);
  HubMessage.addPayloadCompression(builder, payloadCompression);
  return HubMessage.endHubMessage(builder);
}

unpack(): HubMessageT {
  return new HubMessageT(
    this.guid(),
    this.bb!.createScalarList<number>(this.payload.bind(this), this.payloadLength()),
    this.payloadCompression()
  );
}


unpackTo(_o: HubMessageT): void {
  _o.guid = this.guid();
  _o.payload = this.bb!.createScalarList<number>(this.payload.bind(this), this.payloadLength());
  _o.payloadCompression = this.payloadCompression();
}
}

export class HubMessageT implements flatbuffers.IGeneratedObject {
constructor(
  public guid: string|Uint8Array|null = null,
  public payload: (number)[] = [],
  public payloadCompression: CompressionType = CompressionType.None
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const guid = (this.guid !== null ? builder.createString(this.guid!) : 0);
  const payload = HubMessage.createPayloadVector(builder, this.payload);

  return HubMessage.createHubMessage(builder,
    guid,
    payload,
    this.payloadCompression
  );
}
}
