<script lang="ts">
	import { dropdownItemsFromUiBindings, MenuDropdownItem, selectionActionsFromUiBindings } from './ToolbarItems';
	import { DropdownViewDescription, UiBindings } from 'ui-bindings';
	import { LazyDerived } from 'engine-utils-ts';
    import ToolbarDropdownItem from './ToolbarDropdownItem.svelte';
    import { VersionedStore } from '../VersionedStore';
    import NotificationsButton from './NotificationsButton.svelte';
    import ToolbarButton from './ToolbarButton.svelte';
    import ToolbarProjectInfo from './ToolbarProjectInfo.svelte';

	export let uiBindings: UiBindings;

    let dropdownItems = dropdownItemsFromUiBindings(uiBindings.dropdownViews.values());

    let statusViewsProviders = Array.from(uiBindings.statusViews.values()).map(t => t[1]);

    let syncedViews = LazyDerived.fromArr<MenuDropdownItem[], DropdownViewDescription[]>(
        'status_views_synced',
        [],
        statusViewsProviders,
        (viewsDescriptions, prev) => {

            const res: MenuDropdownItem[] = [];
            for (const views of viewsDescriptions) {
                for (const view of views) {
                    // try find previously created first
                    let menuItem = prev?.find(it => it.uiNodeDescription === view.viewSource);
                    if (!menuItem) {
                        // creating new
                        menuItem = MenuDropdownItem.fromUiBinding(view);
                        if (view.activateOpened) {
                            activeDropDownMenuLeft = menuItem.name;
                        }
                    } else {
                        console.log('found menu item in prev result');
                    }
                    res.push(menuItem);
                }
            }
            if (activeDropDownMenuLeft != null
                && !res.find(r => r.name === activeDropDownMenuLeft)
            ) {
                activeDropDownMenuLeft = null;
            }

            return res;
        }
    )

    const selectionActions = selectionActionsFromUiBindings(uiBindings);

    $: syncedStatusViews = new VersionedStore<MenuDropdownItem[]>(syncedViews);
    const selectionActionButtons = new VersionedStore(selectionActions);

    let activeDropDownMenuLeft: string | null = null;
    let activeDropDownMenuRight: string | null = null;

    const hasActions = new VersionedStore(LazyDerived.fromArr(
        'hasActions',
        [],
        $selectionActionButtons.map(action => action.canUseNow),
        (buttons) => buttons.some(Boolean)
    ));
</script>

<style lang="scss">
    .toolbars {
        display: flex;
        overflow: visible;
        padding: 0 12px;
        align-items: center;
        gap: 4px;
        :global(.anchor-inner) {
            justify-content: flex-end;
        }
        &.toolbars-selection :global(.anchor-inner) {
            justify-content: center;
        }
        :global(.selection-button) {
            gap: 24px;
        }
    }
    .mdc-menu-surface {
        position: relative;
        width: 100%;
        max-width: 100%;
        overflow: visible;
        display: flex;
        justify-content: space-between;
    }
    .surface {
        height: 32px;
        border-radius: 0;
        z-index: 200;
        box-shadow: none;
        background: linear-gradient(0deg, rgba(16, 58, 82, 0.08) 0%, rgba(16, 58, 82, 0.08) 100%), white;
        > *:first-child,
        :global(.project-info) {
            flex: 1;
            justify-content: center;
        }
    }
</style>

<div class="surface mdc-menu-surface mdc-menu-surface--open">
    {#if $syncedStatusViews.length > 0 || $hasActions}
        <ul class="mdc-deprecated-list toolbars toolbars-selection text-main-dark">
            {#if $syncedStatusViews.length > 0}
                {#each $syncedStatusViews as item (item.name)}
                    <ToolbarDropdownItem
                        menu={item}
                        bind:activeDropDownMenu={activeDropDownMenuLeft}
                    />
                {/each}
            {/if}
            {#if $selectionActionButtons.length > 0}
                {#each $selectionActionButtons as item, i}
                <ToolbarButton
                    rootClass="selection-button"
                    onClick={item.action}
                    visible={item.canUseNow}
                >
                    <svelte:fragment>
                        {item.name}
                        <span class="text-main-light">{item.hotkeysString}</span>
                    </svelte:fragment>
                </ToolbarButton>
                {/each}
            {/if}
        </ul>
    {:else}
        <ToolbarProjectInfo uiBindings={uiBindings}/>
    {/if}
        <div class="mdc-deprecated-list toolbars text-main-dark">
            <NotificationsButton
                bind:activeDropDownMenu={activeDropDownMenuRight}
            />
            {#each dropdownItems as item, i}
                <ToolbarDropdownItem
                    menu={item}
                    bind:activeDropDownMenu={activeDropDownMenuRight}
                />
            {/each}
        </div>
 </div>

