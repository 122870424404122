import type { LazyVersioned} from 'engine-utils-ts';
import {
	DefaultMap, LazyBasic, LazyDerived
} from 'engine-utils-ts';
import { KrMath, Vector2 } from 'math-ts';

import type {
	Camera} from '../3rdParty/three';
import { OrthographicCamera, PerspectiveCamera,
} from '../3rdParty/three';
import type { GpuResources } from '../composer/GpuResources';
import { KrMaterial } from '../materials/KrMaterial';
import {
	BlurShaderUtils, DepthLimitedBlurShader,
} from '../shaders/DepthLimitedBlurShader';
import { SaoOpaqueOutputShader } from '../shaders/SaoOpaqueOutputShader';
import { SAOShader } from '../shaders/SAOShader';
import { ClearPass } from './ClearPass';
import { PostProcPass } from './PostProcPass';
import type { BudgetLimitForPass, RenderPass, RenderResult } from './RenderPass';
import type { RenderTargets} from './RenderTargets';
import { RTIdent } from './RenderTargets';

export enum SaoOuput {
    Base,
    WithSao,
    SaoOnly,
}

export interface SAOPArams {
    output: SaoOuput;
    saoIntensity: number;
    saoScale: number;
    saoKernelRadius: number;
    saoMinResolution: number;
    saoBlur: boolean;
    saoBlurRadius: number;
    saoBlurStdDev: number;
    saoBlurDepthCutoff: number;
}

const enum SaoPassFlags {
    DefaultHq       = 0,
    LowQuality      = 1,
}

const enum SaoOuputFlags {
    None            = 0,
    TilesDrawing    = 1,
    TiledGround     = 2,
    TiledAll        = 4
}



export class SaoPass implements RenderPass {
    
    enabled: boolean = true;
    identifier: string;
    inputs: RTIdent[];
    outputs: RTIdent[];

    _clearPass: ClearPass;
    _saoVariantsProvider: DefaultMap<SaoPassFlags, PostProcPass>;
    _outputVaritansProvider: DefaultMap<SaoOuputFlags, PostProcPass>;

    _vBlurPass: PostProcPass;
    _hBlurPass: PostProcPass;
    params: LazyVersioned<SAOPArams>;
    _blurKernelConfig: LazyDerived<void>;

    constructor(
        inputColorNormals: [RTIdent, RTIdent],
        outputColor: RTIdent,
        // siteMarkupControls: SiteTilesMarkupControls,
    ) {
        this.identifier = 'sao';
        this.inputs = inputColorNormals;
        this.outputs = [outputColor];

        // this._siteMarkupControls = siteMarkupControls;

        this._clearPass = new ClearPass([RTIdent.simple('temp_r_1')], 0xffffff, 1.0, []);

        this._saoVariantsProvider = new DefaultMap<SaoPassFlags, PostProcPass>((flags: SaoPassFlags) => {
            const defines = flags & SaoPassFlags.LowQuality ? { 'NUM_SAMPLES': 8, 'NUM_RINGS': 2 } : { 'NUM_SAMPLES': 20, 'NUM_RINGS': 3 };

            return new PostProcPass(
                'sao',
                this.inputs,
                RTIdent.simple('temp_r_1'),
                KrMaterial.newPostMat(SAOShader, undefined, defines),
                [
                    [this.inputs[1], 'opaque_normals'],
                ]
            )
        }).withDispose('dispose');

        this._outputVaritansProvider = new DefaultMap<SaoOuputFlags, PostProcPass>((flags: SaoOuputFlags) => {

            const defines: any = {};
            if (flags & SaoOuputFlags.TilesDrawing) {
                defines['TILE_DRAWING_TOOL'] = true;
            }
            if (flags & (SaoOuputFlags.TiledGround | SaoOuputFlags.TiledAll)) {
                defines['GROUND_MARK_TILED'] = true;
            }
            if (flags & SaoOuputFlags.TiledAll) {
                defines['OPAQUE_OBJECTS_MARKUP_TILED'] = true;
            }
            return new PostProcPass(
                'sao_copy',
                [this.inputs[0], this.inputs[1], RTIdent.simple('temp_r_1')],
                this.outputs[0],
                KrMaterial.newPostMat(SaoOpaqueOutputShader, undefined, defines),
                [
                    [RTIdent.simple('temp_r_1'), 'tSao'],
                    [this.inputs[0], 'tColor'],
                ]
            );
        }).withDispose('dispose');



        this._vBlurPass = new PostProcPass(
            'sao',
            RTIdent.simple('temp_rgb_0'),
            RTIdent.simple('temp_rgb_1'),
            KrMaterial.newPostMat(DepthLimitedBlurShader)
        );
        this._hBlurPass = new PostProcPass(
            'sao',
            RTIdent.simple('temp_rgb_1'),
            RTIdent.simple('temp_rgb_0'),
            KrMaterial.newPostMat(DepthLimitedBlurShader)
        );

		// this._outputCopyPass.material.transparent = true;
		// this._outputCopyPass.material.blending = CustomBlending;
		// this._outputCopyPass.material.blendSrc = DstColorFactor;
		// this._outputCopyPass.material.blendDst = ZeroFactor;
		// this._outputCopyPass.material.blendEquation = AddEquation;
		// this._outputCopyPass.material.blendSrcAlpha = DstAlphaFactor;
		// this._outputCopyPass.material.blendDstAlpha = ZeroFactor;
        // this._outputCopyPass.material.blendEquationAlpha = AddEquation;
        

        this.params = new LazyBasic('sao_params', {
			output: SaoOuput.WithSao,
			saoIntensity: 0.04,
			saoScale: 25.0,
			saoKernelRadius: 15,
			saoMinResolution: 0,
			saoBlur: true,
			saoBlurRadius: 3,
			saoBlurStdDev: 1,
			saoBlurDepthCutoff: 0.0001
        });
        
        this._blurKernelConfig = LazyDerived.new1(
            'blur_kernel',
            [],
            [this.params],
            ([params]) => {
                BlurShaderUtils.configure(
                    this._vBlurPass.material, params.saoBlurRadius, params.saoBlurStdDev, new Vector2(0, 1))
                    ;
                BlurShaderUtils.configure(
                    this._hBlurPass.material, params.saoBlurRadius, params.saoBlurStdDev, new Vector2(0, 1)
                );
            }
        );
    }

    version(): number {
        return this.params.version();
    }
    
    render(
        camera: Readonly<Camera>,
        gpuRes: GpuResources,
        renderTargets: RenderTargets,
        budget: BudgetLimitForPass,
        anyInputsIncomplete: boolean
    ): RenderResult {

        this._clearPass.render(camera, gpuRes, renderTargets, budget);

        const params = this.params.poll();
        this._blurKernelConfig.poll();

        let saoFlags: SaoPassFlags = SaoPassFlags.DefaultHq;
        let unitsRenderered: number = 4;
        if (anyInputsIncomplete) {
            saoFlags |= SaoPassFlags.LowQuality;
            unitsRenderered = 1;
        }

        const saoPass = this._saoVariantsProvider.getOrCreate(saoFlags);

		// saoPass.uniforms.get('scaleDividedByCameraFar')!.value = params.saoScale / (camera.far) * getScaleCorrectionForCamera(camera);
		// saoPass.uniforms.get('kernelRadius')!.value = params.saoKernelRadius;
		// saoPass.uniforms.get('randomSeed')!.value = 3//this.camera.matrixWorld.elements.reduce((prev, e) => e + prev, 0);
		// saoPass.uniforms.get('randomSeed')!.value = 3//this.camera.matrixWorld.elements.reduce((prev, e) => e + prev, 0);
        
        let samplesRadiusMinMax = saoPass.uniforms.get('samplesRadiusMinMax')!.value as Vector2;
        let projScale = 1;
        if (camera instanceof OrthographicCamera) {
            const sampleSize = KrMath.clamp(5 / camera.zoom, 10, 20);
            samplesRadiusMinMax.set(sampleSize, sampleSize);
            projScale = 1;
        } else if (camera instanceof PerspectiveCamera) {
            samplesRadiusMinMax.set(0.1, 30);
            projScale = 50.0 / (2.0 * Math.tan(KrMath.degToRad(camera.fov * 0.5)));
        }
		saoPass.uniforms.get('projScale')!.value = projScale;

        saoPass.render(camera, gpuRes, renderTargets, budget, false);

        // if (params.saoBlur) {
        //     const depthCutoff = params.saoBlurDepthCutoff * ( camera.far - camera.near );
            // this._vBlurPass.uniforms.get('depthCutoff')!.value = depthCutoff;
            // this._hBlurPass.uniforms.get('depthCutoff')!.value = depthCutoff;

            // this._vBlurPass.render(camera, gpuRes, renderTargets, budget);
            // this._hBlurPass.render(camera, gpuRes, renderTargets, budget);
		// }

		// if (params.output == 3) {
		// 	this.materialCopy.uniforms[ 'tDiffuse' ].value = writeBuffer.depthTexture;
		// 	this.materialCopy.needsUpdate = true;
		// } else if ( params.output == 4 ) {
		// 	this.materialCopy.uniforms[ 'tDiffuse' ].value = readBuffer.texture;
		// 	this.materialCopy.needsUpdate = true;
		// } else {
		// 	this.materialCopy.uniforms[ 'tDiffuse' ].value = this.saoRenderTarget.texture;
		// 	this.materialCopy.needsUpdate = true;
		// }

		// if ( params.output == 0 ) {
		// 	outputMaterial.blending = CustomBlending;
		// } else {
		// 	outputMaterial.blending = NoBlending;
        // }

        let outputFlags = SaoOuputFlags.None;
        // const tilesUniforms = new Map<string, Vector4 | Texture>();
        // const brushPosition = this._siteMarkupControls.brushRectMinMax.poll();
        // const markupControlsState = this._siteMarkupControls.state.poll();
        // if (brushPosition) {
        //     outputFlags |= SaoOuputFlags.TilesDrawing;
        //     tilesUniforms.set('tileDrawingRectWS', brushPosition.asVec4());
        //     const color = new Color(markupControlsState.color);
        //     tilesUniforms.set('tileDrawingColor', new Vector4(color.r, color.g, color.b, 1.0));
        // }
        // const tileRenderState = this._siteMarkupControls.state.poll();
        // const tiledTexture = this._siteMarkupControls.tilesets.gpuSyncer.texture.peekLastValue();
        // if (tileRenderState.render == MarkupTilesetsRender.GroundOverlay && tiledTexture) {
        //     outputFlags |= SaoOuputFlags.TiledGround;
        //     tilesUniforms.set('tilesTexture', tiledTexture);

        // } else if (tileRenderState.render == MarkupTilesetsRender.FullOverlay && tiledTexture) {
        //     outputFlags |= SaoOuputFlags.TiledAll;
        //     tilesUniforms.set('tilesTexture', tiledTexture);
        // }

        const outputPass = this._outputVaritansProvider.getOrCreate(outputFlags);
        // for (const [key, val] of tilesUniforms) {
        //     const u = outputPass.uniforms.get(key);
        //     if (!u) {
        //         console.warn('sao pass uniform not found', key);
        //     } else {
        //         u.value = val;
        //     }
        // }

        outputPass.render(camera, gpuRes, renderTargets, budget, false);

        return {finished: true, unitsRenderered}
    }
}

