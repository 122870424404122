// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class StringValue implements flatbuffers.IUnpackableObject<StringValueT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):StringValue {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsStringValue(bb:flatbuffers.ByteBuffer, obj?:StringValue):StringValue {
  return (obj || new StringValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsStringValue(bb:flatbuffers.ByteBuffer, obj?:StringValue):StringValue {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new StringValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value():string|null
value(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
value(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

discreteVariants(index: number):string
discreteVariants(index: number,optionalEncoding:flatbuffers.Encoding):string|Uint8Array
discreteVariants(index: number,optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
}

discreteVariantsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startStringValue(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, valueOffset, 0);
}

static addDiscreteVariants(builder:flatbuffers.Builder, discreteVariantsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, discreteVariantsOffset, 0);
}

static createDiscreteVariantsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startDiscreteVariantsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endStringValue(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createStringValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset, discreteVariantsOffset:flatbuffers.Offset):flatbuffers.Offset {
  StringValue.startStringValue(builder);
  StringValue.addValue(builder, valueOffset);
  StringValue.addDiscreteVariants(builder, discreteVariantsOffset);
  return StringValue.endStringValue(builder);
}

unpack(): StringValueT {
  return new StringValueT(
    this.value(),
    this.bb!.createScalarList<string>(this.discreteVariants.bind(this), this.discreteVariantsLength())
  );
}


unpackTo(_o: StringValueT): void {
  _o.value = this.value();
  _o.discreteVariants = this.bb!.createScalarList<string>(this.discreteVariants.bind(this), this.discreteVariantsLength());
}
}

export class StringValueT implements flatbuffers.IGeneratedObject {
constructor(
  public value: string|Uint8Array|null = null,
  public discreteVariants: (string)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const value = (this.value !== null ? builder.createString(this.value!) : 0);
  const discreteVariants = StringValue.createDiscreteVariantsVector(builder, builder.createObjectOffsetList(this.discreteVariants));

  return StringValue.createStringValue(builder,
    value,
    discreteVariants
  );
}
}
