<script lang="ts">
    import type { EnergyViewContext } from "./EnergyViewContext";
    import Tabs from "../libui/tabs/Tabs.svelte";
    import EnergyReport from "./EnergyReport.svelte";
    import EnergySetup from "./EnergySetup.svelte";
    import { UiPersistentState } from "src/UiPersistentState";
    import EnergySpinner from "./EnergySpinner.svelte";
    import Switch from '../libui/checkbox/Switch.svelte';
    import { EnergyCalculationsEnabled } from 'bim-ts';
    import EngineUiPanel from '../ui-panels/EngineUiPanel.svelte';

    export let context: EnergyViewContext;

    const bim = context.bim;

    $: isEnergyEnabledObservable = bim.runtimeGlobals.tryGetAsObservable<EnergyCalculationsEnabled>(EnergyCalculationsEnabled.name)!;

    const tabs = [
        {
            key: 'Overview',
            name: 'Report',
        },
        {
            key: 'Setup',
            name: 'Settings',
        } 
    ];

    let uiState = new UiPersistentState<{tab:number}>('energy-view-tab', {tab: 0}).store;

    $:activeTabIndex = $uiState.tab;
    function changeTab(e: CustomEvent) {
        $uiState.tab = tabs.indexOf(e.detail.tab);
    }
</script>

<EngineUiPanel>
    <div slot="buttons" class="energy-toggle">
        <Switch
            value={$isEnergyEnabledObservable.isEnabled}
            onChange={(newValue) => {
                isEnergyEnabledObservable.applyPatch({
                    patch: {isEnabled: newValue},
                })
            }}
        />
        Calculate
    </div>

    {#if $isEnergyEnabledObservable.isEnabled}
        <div class="root">
            <div class="tabs">
                <Tabs
                    {tabs}
                    firstIndexToActivate={activeTabIndex}
                    compact={true}
                    on:change={changeTab}
                />
                <EnergySpinner {context} />
            </div>

            {#if activeTabIndex === 0}
                <EnergyReport {context}></EnergyReport>
            {:else if activeTabIndex === 1}
                <EnergySetup {context}></EnergySetup>
            {/if}
        </div>
    {/if}
</EngineUiPanel>

<style lang="scss">
    .tabs {
        margin: 0 16px;
        position: relative;
    }
    .root {
        width: 100%;
        height: 100%;
    }
    .energy-toggle {
        display: flex;
        gap: 4px;
    }
</style>
