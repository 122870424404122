<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M4 4V12.5C4 13.0523 4.44772 13.5 5 13.5H11C11.5523 13.5 12 13.0523 12 12.5V4M4 4L2.5 4M4 4L6.5 4V2L9.5 2V4L13.5 4L12 4M4 4L12 4M6.5 11.5V6M9.5 11.5V6"
        stroke="currentColor"
        stroke-width="1.49"
    />
</svg>
