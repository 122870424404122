import { fastConvexHull } from './ConcaveHull';
import type { Matrix3 } from "./Matrix3";
import { Vector2 } from "./Vector2";

export enum PointToPolygon {
    Outside,
    OnBorder,
    Inside
}

export class PolygonUtils {

    static convexHull(points: Vector2[]): Vector2[] {
        if (points.length < 3) {
            return [];
        }
        const asTuples = points.map(p => [p.x, p.y] as [number, number]);
        const hull = fastConvexHull(asTuples);
        return hull.map(([x, y]) => new Vector2(x, y));
    }

    static deleteDuplicates(contour2d: Vector2[]): Vector2[] {
        const contourWithoutDuplicates: Vector2[] = [];

        if (!contour2d[contour2d.length-1].equals(contour2d[0])) {
            contourWithoutDuplicates.push(contour2d[0]);
        }
        for (let i = 1; i < contour2d.length; ++i) {
            if (!contour2d[i-1].equals(contour2d[i])) {
                contourWithoutDuplicates.push(contour2d[i]);
            }
        }

        return contourWithoutDuplicates;
    }


    static simplifyContour(contour2d: Vector2[], eps: number = 1e-8): Vector2[] {
        const contourSimplified: Vector2[] = [];

        let cross: number;
        const prev = new Vector2(), next = new Vector2();

        prev.copy(contour2d[contour2d.length-1]);
        next.copy(contour2d[1]);
        cross = prev.sub(contour2d[0]).cross(next.sub(contour2d[0]));
        if (cross < -eps || cross > eps) {
            contourSimplified.push(contour2d[0]);
            prev.copy(contour2d[0]);
        }
        for (let i = 1; i < contour2d.length - 1; ++i) {
            next.copy(contour2d[i+1]);
            cross = prev.sub(contour2d[i]).cross(next.sub(contour2d[i]));
            if (cross < -eps || cross > eps) {
                contourSimplified.push(contour2d[i]);
                prev.copy(contour2d[i]);
            }
        }
        if (contourSimplified.length > 0) {
            next.copy(contourSimplified[0]);
            cross = prev.sub(contour2d[contour2d.length-1]).cross(next.sub(contour2d[contour2d.length-1]));
            if (cross < -eps || cross > eps) {
                contourSimplified.push(contour2d[contour2d.length-1]);
            }
        }

        return contourSimplified;
    }

    static area(points: Vector2[], matrix?: Matrix3) {

        const applyMatrix = matrix != undefined && !matrix.isIdentity();

        let sum = 0;
        for (let i = 0; i < points.length; ++i) {
            let firstPoint = points[i];
            let nextPoint = points[(i + 1) % points.length];

            let x: number, y: number;
            if (applyMatrix) {
                v1.copy(firstPoint).applyMatrix3(matrix);
                v2.copy(nextPoint).applyMatrix3(matrix);
                x = (v1.x - v2.x);
                y = (v1.y + v2.y);
            } else {
                x = (nextPoint.x - firstPoint.x);
                y = (nextPoint.y + firstPoint.y);
            }

            sum += x * y;
        }
        return sum / 2;
    }

    static perimeter(points: Vector2[], matrix?: Matrix3) {

        const applyMatrix = matrix != undefined && !matrix.isIdentity();

        let sum  = 0;
        for (let i = 0; i < points.length; ++i) {
            let firstPoint = points[i];
            let nextPoint = points[(i + 1) % points.length];

            let dist: number;
            if (applyMatrix) {
                v1.copy(firstPoint).applyMatrix3(matrix);
                v2.copy(nextPoint).applyMatrix3(matrix);
                dist = v1.distanceTo(v2);
            } else {
                dist = firstPoint.distanceTo(nextPoint);
            }

            sum += dist;
        }
        return sum;
    }


    // closed polygon, first and last points SHOULD NOT be equal
    static isPointInsidePolygon(polygon: Vector2[], p: Vector2): PointToPolygon {

        // fork of point-in-polygon-hao

        polygon = PolygonUtils.cleanUpFirstLastDuplicates(polygon);
        if (polygon.length < 3) {
            return PointToPolygon.Outside;
        }

        let k = 0
    
        const contourLen = polygon.length;

        let currentP = polygon[0];
        const px = p.x
        const py = p.y
        let u1 = currentP.x - px
        let v1 = currentP.y - py

        for (let ii = 0, il = polygon.length; ii < il; ii++) {
            const nextP = polygon[(ii + 1) % contourLen];

            let f = 0
            let u2 = 0
            let v2 = 0

            v2 = nextP.y - py

            if ((v1 < 0 && v2 < 0) || (v1 > 0 && v2 > 0)) {
                currentP = nextP
                v1 = v2
                u1 = currentP.x - px
                continue
            }

            u2 = nextP.x - p.x

            if (v2 > 0 && v1 <= 0) {
                f = (u1 * v2) - (u2 * v1)
                if (f > 0) k = k + 1
                else if (f === 0) return PointToPolygon.OnBorder;
            } else if (v1 > 0 && v2 <= 0) {
                f = (u1 * v2) - (u2 * v1)
                if (f < 0) k = k + 1
                else if (f === 0) return PointToPolygon.OnBorder;
            } else if (v2 === 0 && v1 < 0) {
                f = (u1 * v2) - (u2 * v1)
                if (f === 0) return PointToPolygon.OnBorder;
            } else if (v1 === 0 && v2 < 0) {
                f = u1 * v2 - u2 * v1
                if (f === 0) return PointToPolygon.OnBorder;
            } else if (v1 === 0 && v2 === 0) {
                if (u2 <= 0 && u1 >= 0) {
                    return PointToPolygon.OnBorder;
                } else if (u1 <= 0 && u2 >= 0) {
                    return PointToPolygon.OnBorder;
                }
            }
            currentP = nextP
            v1 = v2
            u1 = u2
        }
    
        if (k % 2 === 0) {
            return PointToPolygon.Outside;
        }
        return PointToPolygon.Inside;
    }

    static cleanUpFirstLastDuplicates(originalPolygonPoints: Vector2[]): Vector2[] {
        let points: Vector2[] = originalPolygonPoints;
        while (points.length > 3) {
            if (points[points.length - 1]!.equals(points[0])) {
                console.error('newPolygonArray first and last points should not be equal');
                if (points === originalPolygonPoints) {
                    points = originalPolygonPoints.slice(0, points.length - 1);
                } else {
                    points.pop();
                }
            } else {
                break;
            }
        }
        return points;
    }

    static isClockwiseInner(contour: Vector2[]): boolean {
        let index = 0;
        
        for (let i = 1; i < contour.length; ++i) {
            if (contour[i].x > contour[index].x || 
                (contour[i].x === contour[index].x && contour[i].y > contour[index].y)) {
                index = i;
            }
        }
    
        let prevI = index - 1;
        for (; prevI >= 0; --prevI) {
            if (!contour[prevI].equals(contour[index])) {
                break;
            }
        }
        if (prevI === -1) {
            prevI = contour.length - 1;
            while (contour[prevI].equals(contour[index])) {
                --prevI;
            }
        }
        const prevV = contour[prevI].clone().sub(contour[index]);
        
        let nextI = index + 1;
        for (; nextI < contour.length; ++nextI) {
            if (!contour[nextI].equals(contour[index])) {
                break;
            }
        }
        if (nextI === contour.length) {
            nextI = 0;
            while (contour[nextI].equals(contour[index])) {
                ++nextI;
            }
        }
        const nextV = contour[nextI].clone().sub(contour[index]);
    
        return prevV.cross(nextV) > 0;
    }

    static getCentroid(contour: Vector2[]): Vector2 {
        let area = 0;
        let cx = 0;
        let cy = 0;
    
        for (let i = 0; i < contour.length; i++) {
            const j = (i + 1) % contour.length;
            const pi = contour[i];
            const pj = contour[j];
            const cross = pi.cross(pj);
            area += cross;
            cx += (pi.x + pj.x) * cross;
            cy += (pi.y + pj.y) * cross;
        }
        area = area / 2;
        cx = cx / (6 * area);
        cy = cy / (6 * area);
    
        return new Vector2(cx, cy);
    }
}


const v1 = new Vector2();
const v2 = new Vector2();


// export class PointInPolygonFastChecker {

//     readonly points: Vector2[];
//     readonly aabb2: Aabb2;

//     constructor(
//         points: Vector2[],
//     ) {
//         this.points = PolygonUtils.cleanUpFirstLastDuplicates(points);
//         this.aabb2 = Aabb2.empty().setFromPoints(this.points);


//         const pointsGrid: Vector2[][] = [];
//         const maxSide = this.aabb2.maxSide();
//     }

//     isInside(point: Vector2): PointToPolygon {
//         return PolygonUtils.isPointInsidePolygon(this.points, point);
//     }


// }
