import type { Bim} from "bim-ts";
import { BimPatch, newFlagsPatch } from "bim-ts";
import type { EventStackFrame, ScopedLogger, VersionedValue } from "engine-utils-ts";
import { IterUtils, VersionedInvalidator } from "engine-utils-ts";
import type { Vector3} from "math-ts";
import { Matrix4, Aabb } from "math-ts";
import type { IdEngineObject, InteractiveSceneObjects} from "../scene/InteractiveSceneObjects";
import { calcOriginsCenterFromMatrices, TransformsProviderType } from "../scene/InteractiveSceneObjects";
import type { InObjFullId} from "./EngineSceneIds";
import { InObjIdType } from "./EngineSceneIds";
import type { GesturesMousePos } from "../controls/MouseGesturesBase";
import type { KrCamera } from "../controls/MovementControls";
import type { InteractiveObjectIntersection } from "./Raycasts";
import { EditObjectIntersection } from "./Raycasts";
import type { EngineScene } from "./EngineScene";
import type { SubmeshesRaycasts } from "./SubmeshesRaycasts";
import type { Submeshes2 } from "./Submeshes2";
import type { ESO } from "../esos/ESO";
import { ESSO_Flags, ESSO_Interactive } from "../esos/ESSO";
import type { ESOsCollection, ESOHandle } from "./ESOsCollection";
import type { ESSOsCollection } from "./ESSOsCollection";
import type { SnappingSettings } from "../SnappingSettings";

export class InteractiveSceneObjectsEdit implements InteractiveSceneObjects<InObjFullId, EditObjectIntersection>{

	readonly logger: ScopedLogger;
	readonly selectionInvalidator: VersionedValue;
	readonly repr_invalidator: VersionedInvalidator;

	readonly engineScene: EngineScene;
	readonly identifier: string;
	readonly objects: ESSOsCollection;
	readonly submeshes: Submeshes2;
	readonly raycasts: SubmeshesRaycasts;
	readonly esos: ESOsCollection;
	readonly bim: Bim;

	constructor(logger: ScopedLogger, scene: EngineScene) {
		this.logger = logger.newScope('interactive-edit');
		this.identifier = scene.esos.identifier + '|edit-subobjs';
		this.bim = scene.bim;
		this.engineScene = scene;
		this.objects = scene.esos.subObjects;
		this.esos = scene.esos;
		this.submeshes = scene.submeshes;
		this.raycasts = scene.submeshesRaycasts;
		this.selectionInvalidator = this.objects.selectHighlight.getInvalidatorOf(ESSO_Flags.isSelected);

		this.repr_invalidator = new VersionedInvalidator();
	}



	type(): TransformsProviderType {
		return TransformsProviderType.EditMeshes;
	}



	setHighlighted(ids: InObjFullId[]): void {
		const diff = this.objects.selectHighlight.diffToMakeFlagEnabledOnlyFor(
			ESSO_Flags.isHighlighted,
			ids,
			newFlagsPatch(ESSO_Flags.isHighlighted, true),
			newFlagsPatch(ESSO_Flags.isHighlighted, false),
		);
		this.objects.applyFlagsPatch(diff);
	}
	getHighlighted(): InObjFullId[] {
        return this.objects.selectHighlight.flagged(ESSO_Flags.isHighlighted);
	}
	anySelected(): boolean {
        return this.objects.selectHighlight.anyFlagged(ESSO_Flags.isSelected);
	}
	getSelected(): InObjFullId[] {
        return this.objects.selectHighlight.flagged(ESSO_Flags.isSelected);
	}
	getVisible(): InObjFullId[] {
		return IterUtils.filterMap(this.objects.perId.values(), o => o instanceof ESSO_Interactive ? o.id : undefined);
	}
	setSelected(ids: InObjFullId[]): void {
		const diff = this.objects.selectHighlight.diffToMakeFlagEnabledOnlyFor(
			ESSO_Flags.isSelected,
			ids,
			newFlagsPatch(ESSO_Flags.isSelected, true),
			newFlagsPatch(ESSO_Flags.isSelected, false),
		);
		this.objects.applyFlagsPatch(diff);
	}
	toggleSelected(isSelected: boolean, ids: InObjFullId[]): void {
		const patch = newFlagsPatch(ESSO_Flags.isSelected, isSelected);
		this.objects.applyFlagsPatch(ids.map(id => [id, patch]));
	}
	cloneObjects(ids: InObjFullId[], worldSpaceDirectionOfClone: Vector3): { newObjects: InObjFullId[]; toUseForGesture: InObjFullId[]; } {
		const perRootObj = Array.from(IterUtils.groupBy(ids, (id) => id.objHandle));
		const perHandler = this.esos.groupHandlesTuplesByType(perRootObj);
		const bimPatch = new BimPatch();
		const newObjectsTotal: InObjFullId[] = [];
		const toUseForGestureTotal: InObjFullId[] = [];
		for (const [handler, perHandleIds] of perHandler) {
			const {newObjects, toUseForGesture} = handler.cloneInteractiveSubObjects(
				this.esos,
				IterUtils.filterMap(perHandleIds, ([h, ids]) => {
					const obj = this.esos.peek(h);
					if (!obj) {
						return undefined;
					}
					return [h, obj, ids] as [ESOHandle, ESO, InObjFullId[]];
				}),
				worldSpaceDirectionOfClone,
				bimPatch
			);
			IterUtils.extendArray(newObjectsTotal, newObjects);
			IterUtils.extendArray(toUseForGestureTotal, toUseForGesture);
		}
		bimPatch.applyTo(this.bim);
		this.engineScene.syncWithBim();
		return {
			newObjects: newObjectsTotal,
			toUseForGesture: toUseForGestureTotal
		}
	}
	deleteObjects(ids: InObjFullId[]): void {
		const perRootObj = Array.from(IterUtils.groupBy(ids, (id) => id.objHandle));
		const perHandler = this.esos.groupHandlesTuplesByType(perRootObj);
		const bimPatch = new BimPatch();
		for (const [handler, perHandleIds] of perHandler) {
			handler.deleteInteractiveSubObjects(
				this.esos,
				IterUtils.filterMap(perHandleIds, ([h, perHandleIds]) => {
					const obj = this.esos.peek(h);
					if (!obj) {
						return undefined;
					}
					return [h, obj, perHandleIds] as [ESOHandle, ESO, InObjFullId[]];
				}),
				bimPatch
			);
		}
		bimPatch.applyTo(this.bim);
		this.engineScene.syncWithBim();
	}
	getWorldMatricesOf(ids: InObjFullId[]): Map<InObjFullId, Matrix4> {
		const res = new Map();
		for (const id of ids) {
			const m = this.objects.peekWorldMatrix(id);
			if (m) {
				res.set(id, m)
			}
		}
		return res;
	}
	patchWorldMatrices(patches: Map<InObjFullId, Matrix4>, eventParams: Partial<EventStackFrame>): void {
		const perRootObj = Array.from(IterUtils.groupBy(patches, ([id, m]) => id.objHandle));
		const perHandler = this.esos.groupHandlesTuplesByType(perRootObj);
		const bimPatch = new BimPatch();
		for (const [handler, perHandlePatches] of perHandler) {
			handler.patchSubObjectsWorldMatrices(
				this.esos,
				IterUtils.filterMap(perHandlePatches, ([h, patches]) => {
					const obj = this.esos.peek(h);
					if (!obj) {
						return undefined;
					}
					return [h, obj, patches] as [ESOHandle, ESO, [InObjFullId, Matrix4][]];
				}),
				bimPatch
			);
		}
		bimPatch.applyTo(this.bim);
		this.engineScene.syncWithBim();
	}
	getChildrenOf(ids: InObjFullId[]): InObjFullId[] {
		return [];
	}
	getParentsOf(ids: InObjFullId[]): Map<InObjFullId, InObjFullId> {
		return new Map();
	}
	gatherIdsWithSubtreesOf(params: { ids: InObjFullId[]; sortParentFirst?: boolean | undefined; }): InObjFullId[] {
		return [];
	}
	calcLocalOrientationFor(ids: InObjFullId[]): Matrix4 {
		const rotation = new Matrix4();
		const lastSelected = ids[ids.length - 1];
		if (lastSelected != undefined) {
			const m = this.objects.peekWorldMatrix(lastSelected);
			if (m) {
				rotation.extractRotation(m);
			}
		}
		return rotation;
	}
	calcBboxOf(ids: InObjFullId[]): Aabb {
		const resultUnion = Aabb.empty();
		const reused = Aabb.empty();
		for (const id of ids) {
			const obj = this.objects.peekById(id);
			if (obj instanceof ESSO_Interactive) {
				obj.aabb(reused);
				resultUnion.union(reused);
			} else {
				this.logger.batchedError('obj is not interactive, wrong id', id);
			}
		}
		return resultUnion;
	}
	calcOriginsCenterOf(ids: InObjFullId[]): Vector3 {
		return calcOriginsCenterFromMatrices(this.getWorldMatricesOf(ids).values());
	}
	findIntersectingFrustum(frustumMatrix: Matrix4, near: number, far: number, strictInside: boolean): InObjFullId[] {
		const essoIds = this.raycasts.findIntersectingFrustum<InObjFullId>(
			frustumMatrix,
			near,
			far,
			strictInside,
			s => {
				const ty = s.fullId.inObjId.ty;
				return ty === InObjIdType.EditPoint || ty === InObjIdType.EditEdge;
			},
			(h) => this.submeshes.getParentEssoId(h)!,
			(inObjId) => this.submeshes.getByParentEsso(inObjId),

		);
		return essoIds;
	}
	findPointToSnap(snapNearPosition: Vector3, skipIds: Set<IdEngineObject>, camera: KrCamera, settings: SnappingSettings): Vector3 | null {
		return this.raycasts.findPointToSnap(snapNearPosition, skipIds, camera, settings);
	}
	intersectRay(camera: KrCamera, mousePos: GesturesMousePos): EditObjectIntersection | null {
		const int = this.raycasts.intersectRay(
			camera,
			mousePos,
			(s) => {
				return s.subObjectRef instanceof ESSO_Interactive;
			},
			(int, s) => {
				return new EditObjectIntersection(
					int.distance,
					int.point,
					int.normal,
					s.fullId,
				);
			}
		);
		return int;
	}
	getIdsFromIntersection(int: InteractiveObjectIntersection): InObjFullId[] {
		if (int instanceof EditObjectIntersection) {
			return [int.id];
		}
		return [];
	}
}

