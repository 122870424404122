import { Bim, CombinerBoxTypeIdent, FixedTiltTypeIdent, IdBimScene, RoadTypeIdent, SubstationTypeIdent, TrackerTypeIdent, TransformerIdent } from "bim-ts";
import { DefaultMap, Failure, IterUtils, LazyBasic, RGBAHex, ScopedLogger, Success, Yield } from "engine-utils-ts";
import jsPDF, { GState } from "jspdf";
import { Aabb2, Vector2 } from "math-ts";
import { PropsChartParams } from "../../props-charts/PropsChartsParams";
import { createPropsChartFromDataset } from "../../props-charts/PropsDatasetEchart";
import { createPropsDatasetGenerator, HistogramDataset } from "../../props-charts/PropsDatasetGenerator";
import { createChartPngImage } from "../ChartImageCreator";
import { DrawColor } from "../LayoutDrawing";
import { convertPxToMm, EquipmentDefaultColor, SolarArraysTypes, SubstationColor } from "../PdfCommon";
import { PageFrameOffsets, PdfElement, PdfReportContext } from "../PdfReportBuilder";
import { setTextStyle } from "../TextBuilder";
import { drawLayoutOnPageByTemplate2 } from "./SubareasBreakdownPage";

interface BlockProps {
    blockNumber: number;
    combinerBoxes: number;
    trackers: number;
    fixedTilt: number;
    modules: number;
    strings: number;
    totalDcKw: number;
    totalAcKw: number;
    dcAcRatio: number;
}

interface BlocksScheduleHeader {
    propertyName: keyof BlockProps,
    widthPx: number,
    description?: string | string[],
    unit?: string,
}

function calcStartYPositionPx(offsets: PageFrameOffsets, additionalOffsetPx: number): number {
    return offsets.upOffsetPx + offsets.topContentOffsetPx + additionalOffsetPx;
}
export class BlockSchedulePageTable extends PdfElement {
    readonly headerTopOffsetPx = 16;
    readonly headerHeightPx = 43;
    readonly rowHeightPx = 22;
    readonly totalRowHeightPx = 26;

    constructor(
        readonly bim: Bim,
        readonly logger: ScopedLogger,
    ) {
        super();
    }

    *draw({page, pageFrameOffsets}: PdfReportContext): Generator<Yield, void, unknown> {
        yield Yield.Asap;
        const tableData = this._extractBlocksData();

        const leftOffsetPx = pageFrameOffsets.leftRightOffsetPx;
        //header
        const headers: BlocksScheduleHeader[] = [
            { propertyName: 'blockNumber', widthPx: 32 },
            { propertyName: 'combinerBoxes', widthPx: 85, description: "CB"},
            { propertyName: 'trackers', widthPx: 85, description: "Trackers"},
            { propertyName: 'fixedTilt', widthPx: 85, description: "Fixed-tilt"},
            { propertyName: 'modules', widthPx: 85, description: "Modules"},
            { propertyName: 'strings', widthPx: 85, description: "Strings"},
            { propertyName:'totalDcKw', widthPx: 85, description: ["DC", "capacity"], unit: "kW"},
            { propertyName:'totalAcKw', widthPx: 85, description: ["AC", "capacity"], unit: "kW"},
            { propertyName:'dcAcRatio', widthPx: 85, description: ["DC/AC", "Ratio"], unit: " "},
        ];

        let offsetYPx = calcStartYPositionPx(pageFrameOffsets, this.headerTopOffsetPx);
        const fontSize = 10;
        const {contentRightOffsetPx} = printTableHeader(leftOffsetPx, offsetYPx, fontSize, headers, page);

        offsetYPx += this.headerHeightPx;

        function drawRowText(row: BlockProps, rowHeightPx: number, isTotal?: boolean){
            const fontSize = 10;
            offsetYPx += rowHeightPx;
            const localOffsetYPx = offsetYPx - (rowHeightPx - fontSize) / 2;
            const style = isTotal ? 'bold' : 'normal';
            setTextStyle(page, style, fontSize);
            let offsetXPx = leftOffsetPx;
            for (const header of headers) {
                const value = row[header.propertyName];
                const digits = header.unit ? 2 : 0;
                const valueStr = value ? value.toFixed(digits) : "";
                offsetXPx += header.widthPx;
                const textWidth = page.getTextWidth(valueStr);
                page.text(valueStr, convertPxToMm(offsetXPx - contentRightOffsetPx) - textWidth, convertPxToMm(localOffsetYPx));
            }
        }
        const lineLength = IterUtils.sum(headers, h => h.widthPx);

        //total row
        drawRowText(tableData.total, this.totalRowHeightPx, true);
        drawLine(page, leftOffsetPx, offsetYPx, lineLength);
        //total value background
        const x = leftOffsetPx;
        const y = offsetYPx - this.totalRowHeightPx;
        page.setGState(new GState({opacity: 0.08}));
        page.setFillColor(16, 58, 82);

        page.rect(
            convertPxToMm(x),
            convertPxToMm(y),
            convertPxToMm(IterUtils.sum(headers, h => h.widthPx)),
            convertPxToMm(this.totalRowHeightPx),
            "F",
        );
        page.setGState(new GState({opacity: 1}));
        //blocks rows
        for (const row of tableData.blocks) {
            drawRowText(row, this.rowHeightPx);
            drawLine(page, leftOffsetPx, offsetYPx, lineLength);
        }
    }

    private _extractBlocksData(){
        const tableData: BlockProps[] = [];
        const transformers = this.bim.instances.peekByTypeIdent(TransformerIdent);
        for (const [transformerId, si] of transformers) {
            const blockNumber = si.properties.get("circuit | position | block_number")?.asNumber();
            if(!blockNumber){
                continue;
            }
            const blockProps = {
                id: transformerId,
                blockNumber,
                totalDcKw: si.properties.get("circuit | block_capacity | dc_power")?.as("kW") ?? 0,
                totalAcKw: si.properties.get("circuit | block_capacity | ac_power")?.as("kW") ?? 0,
                dcAcRatio: si.properties.get("circuit | block_capacity | dc/ac_ratio")?.asNumber() ?? 0,
                combinerBoxes: 0,
                trackers: 0,
                fixedTilt: 0,
                modules: 0,
                strings: 0,
            };
            for (const childId of this.bim.instances.spatialHierarchy.iteratorOfChildrenOf(transformerId)) {
                const typeIdent = this.bim.instances.peekTypeIdentOf(childId);
                if(typeIdent === "wire"){
                    continue;
                }
                this.bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(
                    childId, 
                    (id) => {
                    const si = this.bim.instances.peekById(id);
                    if(si?.type_identifier === CombinerBoxTypeIdent){ 
                        blockProps.combinerBoxes++;
                    }
                    if(si?.type_identifier === TrackerTypeIdent || si?.type_identifier === "any-tracker"){
                        blockProps.trackers++;
                    }
                    if(si?.type_identifier === FixedTiltTypeIdent){
                        blockProps.fixedTilt++;
                    }
                    if(si && SolarArraysTypes.includes(si.type_identifier)){
                        blockProps.modules += si.properties.get("circuit | equipment | modules_count")?.asNumber() ?? 0;
                        blockProps.strings += si.properties.get("circuit | equipment | strings_count")?.asNumber() ?? 0; 
                    }
                    return true;
                });
            }

            tableData.push(blockProps);  
        }
        tableData.sort((a, b) => a.blockNumber - b.blockNumber);
        const totalDcKw = IterUtils.sum(tableData, r => r.totalDcKw);
        const totalAcKw = IterUtils.sum(tableData, r => r.totalAcKw);
        const total: BlockProps = {
            blockNumber: tableData.length,
            combinerBoxes: IterUtils.sum(tableData, r => r.combinerBoxes),
            trackers: IterUtils.sum(tableData, r => r.trackers),
            fixedTilt: IterUtils.sum(tableData, r => r.fixedTilt),
            modules: IterUtils.sum(tableData, r => r.modules),
            strings: IterUtils.sum(tableData, r => r.strings),
            totalDcKw,
            totalAcKw,
            dcAcRatio: totalDcKw && totalAcKw ? totalDcKw / totalAcKw : 0,
        }

        return {
            blocks: tableData,
            total,
        };
    }

    calculateContentSize(pageSize: Vector2, { pageFrameOffsets}: PdfReportContext): Aabb2 {
        const rows = this._extractBlocksData();
        const startYPositionPx = calcStartYPositionPx(pageFrameOffsets, this.headerTopOffsetPx);
        const textOffset = convertPxToMm(startYPositionPx + rows.blocks.length * this.rowHeightPx + this.headerHeightPx + this.totalRowHeightPx);
        return Aabb2.empty().setFromPoints([
            new Vector2(0, 0),
            new Vector2(pageSize.x, textOffset + convertPxToMm(pageFrameOffsets.downOffsetPx + 10))
        ]);
    }
}

export class ScreenshotByDcAcRatio extends PdfElement {
    readonly maxImageSizePx = 330;
    readonly headerOffsetPx = 45;

    constructor(
        readonly bim: Bim,
        readonly logger: ScopedLogger,
        readonly pallete: Map<IdBimScene, RGBAHex | 0>,
    ){
        super();
    }

    *draw({page, layoutDrawing, pageFrameOffsets}: PdfReportContext) {
        yield Yield.Asap;
        const types = [
            RoadTypeIdent,
            SubstationTypeIdent,
            ...SolarArraysTypes
        ];

        const allIds = this.bim.instances.peekByTypeIdents(types).map(x => x[0]);

        const allTypes = new DefaultMap<string, IdBimScene[]>(() => []);
        const undefinedType = 'unknown';

        const idPerAcDcRatio = new Map<IdBimScene, string>();
        const transformers = this.bim.instances.peekByTypeIdent(TransformerIdent);
        for (const [transformerId, transformerInst] of transformers) {
            const dcAcRatio = transformerInst.properties.get("circuit | block_capacity | dc/ac_ratio")?.asNumber();
            if(dcAcRatio == undefined){ 
                continue;
            }
            const dcAcRatioStr = dcAcRatio.toFixed(2);
            idPerAcDcRatio.set(transformerId, dcAcRatioStr);
            for (const childId of this.bim.instances.spatialHierarchy.iteratorOfChildrenOf(transformerId)) {
                const typeIdent = this.bim.instances.peekTypeIdentOf(childId) ?? "";
                if (typeIdent === "wire") {
                    continue;
                }
                this.bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(
                    childId, 
                    (id) => {
                        idPerAcDcRatio.set(id, dcAcRatioStr);
                        return true;
                    }
                );
            }
        }

        for (const id of allIds) {
            const dcAcRatio = idPerAcDcRatio.get(id);
            if (dcAcRatio) {
                allTypes.getOrCreate(dcAcRatio).push(id);
            } else {
                allTypes.getOrCreate(undefinedType).push(id);
            }
        }
        
        const idPerColor = new Map<IdBimScene, DrawColor>();
        for (const id of allIds) {
            const colorTint = this.pallete.get(id) ?? EquipmentDefaultColor;
            const typeIdent = this.bim.instances.peekTypeIdentOf(id);
            if(typeIdent === SubstationTypeIdent){
                idPerColor.set(id, { borderColor: SubstationColor });
            } else {
                idPerColor.set(id, { borderColor: colorTint });
            }
        }

        yield* drawLayoutOnPageByTemplate2({
            page, 
            ids: allIds,
            layoutDrawing: layoutDrawing,
            layoutName: "DC/AC Ratio map",
            offsets: pageFrameOffsets,
            maxWidthPx: this.maxImageSizePx,
            headerOffsetPx: this.headerOffsetPx,
            colorize: (id) => {
                return idPerColor.get(id) ?? {borderColor: EquipmentDefaultColor};
            },
        });
    }
}

export class DcAcRationDiagram extends PdfElement {
    readonly maxWidthPx = 330;
    readonly headerOffsetPx = 405;
    constructor(
        readonly bim: Bim,
        readonly logger: ScopedLogger,
        readonly colorPerId: Map<IdBimScene, RGBAHex | 0>,
    ){
        super();
    }

    *draw({page, pageFrameOffsets}: PdfReportContext): Generator<Yield, void, unknown> {
        yield Yield.Asap;
        const fontSize = 15;
        setTextStyle(page, "bold", fontSize);
        const pageWidth = page.internal.pageSize.getWidth();
        const leftOffset = pageWidth - convertPxToMm(pageFrameOffsets.leftRightOffsetPx + this.maxWidthPx);
        const upOffsetPx = pageFrameOffsets.upOffsetPx + this.headerOffsetPx - fontSize;
        const propsChartsParams = new LazyBasic<PropsChartParams>("props-chart", {
            object_types: [TransformerIdent],
            property_path: "circuit | block_capacity | dc/ac_ratio",
            divide_by: null,
            round_min_max: false,
            auto_bin_count: true,
            bins_count: 7,
        });

        const datasetLazy = createPropsDatasetGenerator(
            propsChartsParams,
            this.bim.instances,
            this.bim.unitsMapper,
        );

        const dataSet = createPropsChartFromDataset(propsChartsParams, datasetLazy);
        const options = dataSet.poll()?.echartOptions;
        const datasetResult = datasetLazy.poll();
        if(datasetResult instanceof Failure){
            this.logger.error("Failed to create dataset", datasetResult.errorMsg());
            return;
        }
        if(options && datasetResult instanceof Success && datasetResult.value instanceof HistogramDataset){
            for (const bin of datasetResult.value.bins) {
                for (const id of bin.ids) {
                    this.bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(id, (childId) => {
                        this.colorPerId.set(childId, bin.color);
                        return true;
                    });
                }
            }
  
            const imagePngResult = yield* createChartPngImage(new Vector2(this.maxWidthPx, this.maxWidthPx), options);
            if(imagePngResult instanceof Failure){
                this.logger.error("Failed to create chart", imagePngResult.errorMsg());
                return;
            }
            page.addImage(
                imagePngResult.value, 
                'PNG',
                leftOffset, 
                convertPxToMm(upOffsetPx),
                convertPxToMm(this.maxWidthPx),
                convertPxToMm(this.maxWidthPx),
            );
        } else {
            this.logger.error("Failed to create chart");
        }
        page.text("DC/AC Ratio", leftOffset, convertPxToMm(upOffsetPx));
    }
}

export type TableHeaderDescription = {
    widthPx: number;
    description?: string | (string | null)[];
    unit?: string;
    hint?: string;
};

export function printTableHeader(
    leftOffsetPx: number, 
    offsetYPx: number, 
    fontSize: number,
    headers: TableHeaderDescription[], 
    page: jsPDF
) {
    let headerOffsetXPx = leftOffsetPx;
    const headerOffsetYPx = offsetYPx + fontSize;
    const contentRightOffsetPx = 4;
    let maxLocalOffsetYPx = headerOffsetYPx;
    for (let i = 0; i < headers.length; i++) {
        setTextStyle(page, 'bold', fontSize);
        const header = headers[i];
        headerOffsetXPx += header.widthPx;
        const xOffset = convertPxToMm(headerOffsetXPx - contentRightOffsetPx);
        const toDisplay = Array.isArray(header.description) ? header.description : [header.description ?? ""];
        let localOffsetYPx = headerOffsetYPx;
        for (let j = 0; j < toDisplay.length; j++) {
            const value = toDisplay[j];
            if(value != null){
                const width = page.getTextWidth(value);
                const xPos = i === 0 ? xOffset - convertPxToMm(header.widthPx - contentRightOffsetPx) : xOffset - width;
                page.text(value, xPos, convertPxToMm(localOffsetYPx));
            }
            localOffsetYPx += fontSize + 2;
        }
        if (header.unit) {
            const unitTopOffsetPx = 6;
            setTextStyle(page, 'transparent', fontSize);
            const width = page.getTextWidth(header.unit);
            maxLocalOffsetYPx = Math.max(maxLocalOffsetYPx, localOffsetYPx + unitTopOffsetPx);
            page.text(header.unit, convertPxToMm(headerOffsetXPx - contentRightOffsetPx) - width, convertPxToMm(localOffsetYPx + unitTopOffsetPx));
        } else if(header.hint){ 
            const unitTopOffsetPx = 6;
            setTextStyle(page, 'transparent', fontSize);
            maxLocalOffsetYPx = Math.max(maxLocalOffsetYPx, localOffsetYPx + unitTopOffsetPx);
            page.text(header.hint, convertPxToMm(leftOffsetPx), convertPxToMm(localOffsetYPx + unitTopOffsetPx));
        }
    }

    return {contentRightOffsetPx, offsetYPx: maxLocalOffsetYPx};
}

export function drawLine(page: jsPDF, startXPx: number, yPx: number, lengthPx: number){
    const x = convertPxToMm(startXPx);
    const y = convertPxToMm(yPx);
    page.setGState(new GState({opacity: 0.12}));
    page.setFillColor(26, 30, 31);
    page.setLineWidth(convertPxToMm(1));

    page.moveTo(x, y);
    page.lineTo(x + convertPxToMm(lengthPx), y);
    page.fill();

    page.setGState(new GState({opacity: 1}));
}
