import { CostModel as CM, NumberProperty, UnitsMapper } from "bim-ts";
import {
    ColDef,
    ValueFormatterFunc,
    ValueFormatterParams,
    ValueGetterFunc,
    ValueParserFunc,
    ValueSetterFunc,
    ValueSetterParams,
} from "ag-grid-enterprise";
import {
    commonCellClasses,
    commonFontColor,
    defaultTextColor,
    getContext,
} from "./common";
import { UnitConverter, replaceCurrencyUnitWithSymbol } from "engine-utils-ts";
import { defaultCellStyle } from "src/cost-model/capital/table/column-definitions/common";

export const createLcoeInputResultingUnitColumnDefinition =
    (): ColDef<CM.FlattenedCostCategoryParams> => ({
        colId: "LCOE Input Resulting Unit",
        headerName: "",
        pinned: "left",
        valueGetter: ((params) => {
            const { category } = getContext(params);
            const target = category?.lcoeInput;
            if (!target?.resultingUnit) {
                return;
            }
            return target.resultingUnit;
        }) as ValueGetterFunc<CM.FlattenedCostCategoryParams, number>,
        minWidth: 80,
        maxWidth: 80,
        cellStyle: (params) => {
            const styles = {
                ...defaultCellStyle,
                "text-align": "left",
                color: defaultTextColor,
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-start',
            };
            return styles;
        },
    });

export const createLcoeInputQuantityColumnDefinition =
    (): ColDef<CM.FlattenedCostCategoryParams> => ({
        colId: "LCOE Input Quantity Unit",
        headerName: "",
        pinned: "left",
        valueGetter: ((params) => {
            const { category, unitsMapper } = getContext(params);
            const target = category?.lcoeInput;
            if (!target?.quantity) {
                return;
            }
            const resultingString = formatQuantityToShortest(
                target.quantity,
                unitsMapper,
            );
            return resultingString;
        }) as ValueGetterFunc<CM.FlattenedCostCategoryParams, string>,

        valueFormatter: ((
            params: ValueFormatterParams<
                CM.FlattenedCostCategoryParams,
                string
            >,
        ) => {
            if (!params.value) {
                return;
            }
            return params.value + " × ";
        }) as ValueFormatterFunc<CM.FlattenedCostCategoryParams, string>,

        cellStyle: (params) => {
            const styles = {
                ...defaultCellStyle,
                color: defaultTextColor,
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
            };
            return styles;
        },

        minWidth: 100,
        maxWidth: 100,
    });

export const creatLCOEInputColumnDefinition =
    (): ColDef<CM.FlattenedCostCategoryParams> => ({
        colId: "LCOE Input",
        headerName: "LCOE Input",
        pinned: "left",
        valueGetter: ((params) => {
            const { category } = getContext(params);
            const target = category?.lcoeInput?.input;
            const value = target?.value;
            if (value === undefined) {
                return;
            }
            return Number.parseFloat(value.toFixed(4));
        }) as ValueGetterFunc<CM.FlattenedCostCategoryParams, number>,

        valueParser: ((params) => {
            const newValue = Number.parseFloat(params.newValue);
            if (!Number.isFinite(newValue)) {
                return null;
            }
            return newValue;
        }) as ValueParserFunc<CM.FlattenedCostCategoryParams, number>,

        editable: (params) => {
            const context = getContext(params);
            const target = context.category?.lcoeInput?.input;
            if (!target) {
                return false;
            }
            if (target?.flags?.editable === false) {
                return false;
            }
            return !!target?.update;
        },

        valueSetter: ((
            params: ValueSetterParams<CM.FlattenedCostCategoryParams, number>,
        ) => {
            const { category } = getContext(params);
            const target = category?.lcoeInput?.input;
            const mutate = target?.update;
            if (!mutate) {
                return false;
            }
            mutate(params.newValue ?? undefined);
            return true;
        }) as ValueSetterFunc<CM.FlattenedCostCategoryParams, number>,

        valueFormatter: ((
            params: ValueFormatterParams<
                CM.FlattenedCostCategoryParams,
                number
            >,
        ) => {
            const { category } = getContext(params);
            const target = category?.lcoeInput;
            if (!target?.input?.value) {
                return;
            }
            const resultingString = formatNumber(target.input.value);
            return resultingString;
        }) as ValueFormatterFunc<CM.FlattenedCostCategoryParams, number>,

        cellClass: (params) => {
            const clases = commonCellClasses(
                getContext(params),
                (x) => x.lcoeInput?.input,
            );
            return clases;
        },

        cellStyle: (params) => {
            const styles = {
                ...defaultCellStyle,
                color: defaultTextColor,
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
            };
            return styles;
        },

        headerClass: ["ag-grid-header-align-right"],

        //headerClass: [
        //    'ag-grid-header-align-right'
        //],
        minWidth: 100,
        maxWidth: 100,
    });

const numberFormatOptions: Intl.NumberFormatOptions = {
    style: "decimal",
    maximumFractionDigits: 4,
    minimumFractionDigits: 0,
};
export function formatNumber(value: number, fractionDigits?: number) {
    const config = { ...numberFormatOptions };
    if (typeof fractionDigits === "number") {
        config.maximumFractionDigits = fractionDigits;
        config.minimumFractionDigits = fractionDigits;
    }
    return Intl.NumberFormat("en-US", config).format(value);
}

export function formatQuantityToShortest(
    quantity: NumberProperty,
    unitsMapper: UnitsMapper,
) {
    const unitDimension = unitsMapper.converter.getDimensionsOfUnitsString(
        quantity.unit,
    );
    if (unitDimension["price"] === 1) {
        const prefixed = UnitConverter.toShortestCurrency(quantity.value);
        const value = quantity.value / prefixed.prefix.multiplier;
        const configuredUnit = unitsMapper.mapToConfigured({
            value: 1,
            unit: quantity.unit,
        }).unit;
        const unit = replaceCurrencyUnitWithSymbol(configuredUnit ?? "");
        return `${unit} ${value.toFixed(2)}${prefixed.prefix.prefixNameShort}`;
    } else {
        const configuredUnit = quantity.toConfiguredUnits(unitsMapper);
        const shortest = NumberProperty.new(
            unitsMapper.converter.toShortest(configuredUnit),
        );
        return shortest.valueUnitUiString(unitsMapper);
    }
}
