import type { GeometryIntersection, IntersectionType } from './GeometryUtils';
import { GeometryUtils } from './GeometryUtils';
import type { GeometryGpuRepr } from './KrBufferGeometry';
import type { Plane, Matrix4 } from 'math-ts';
import { Vector3 } from 'math-ts';
import { EngineBimGeometry } from "./EngineGeometry";
import type { BimGeometryType, PolylineGeometries} from 'bim-ts';
import { PolylineGeometry } from 'bim-ts';
import { GeometryGenerator } from './GeometryGenerator';
import type { KrCamera } from '../controls/MovementControls';
import type { RaySection } from '../structs/RaySection';
import type { ScopedLogger, EventStackFrame , Result} from 'engine-utils-ts';
import { LazyLambda, Success } from 'engine-utils-ts';
import type { EntityId } from 'verdata-ts';
import { BimEngineGeometriesSyncBase } from '../resources/BimGeometriesSync';
import type { IdEngineGeo } from './AllEngineGeometries';
import { EngineGeoType } from './AllEngineGeometries';


export class EngineGeoAnalytPolyline extends EngineBimGeometry<PolylineGeometry> {

    constructor(
        sourcePolyline: PolylineGeometry = new PolylineGeometry(),
    ) {
        super(sourcePolyline);
    }

	isViewDependent() {
		return true;
	}


	_calcGpuRepr(): GeometryGpuRepr {
        return GeometryGenerator.generateSplineGeometry({points: Vector3.arrayFromFlatArray(this.bimGeo.points3d), tesselateWhenAppropriate: false, width: this.bimGeo.radius * 2});
	}

    *snappingEdges(): IterableIterator<[Vector3, Vector3]> {
        const points: [Vector3, Vector3] = [Vector3.zero(), Vector3.zero()];
        for (let i = 3; i < this.bimGeo.points3d.length; i += 3) {
            points[0].setFromArray(this.bimGeo.points3d, i - 3);
            points[1].setFromArray(this.bimGeo.points3d, i);
            yield points;
        }
    }	
    intersectPlanes(planes: Plane[]): IntersectionType {
        return GeometryUtils.checkPlanesWithPolylinePointsIntersection(this.bimGeo.points3d, planes);
    }

	raycast(ray: RaySection, modelMatrix: Matrix4, camera: KrCamera): GeometryIntersection[] {
		const res: GeometryIntersection[] = [];

		const p1 = new Vector3();
		const p2 = new Vector3();

		const rayPoint = new Vector3();
		const segmentPoint = new Vector3();

		for (let i = 3; i < this.bimGeo.points3d.length; i += 3) {
			p1.setFromArray(this.bimGeo.points3d, i - 3).applyMatrix4(modelMatrix);
			p2.setFromArray(this.bimGeo.points3d, i).applyMatrix4(modelMatrix);

			const _rayToSegmentDistance = ray.ray.distanceToSegment(p1, p2, rayPoint, segmentPoint);

			const rayDistance = rayPoint.distanceTo(ray.ray.origin);

			const segmentPointProjSpace = segmentPoint.clone().applyMatrix4(camera.mvpMatrix);
			const rayPointProjSpace = rayPoint.clone().applyMatrix4(camera.mvpMatrix);

			const projectedDistance = segmentPointProjSpace.distanceTo(rayPointProjSpace);
			if (projectedDistance > 0.01) {
				continue;
			}
			// console.log('proj space dist', projectedDistance);
			res.push({
				distance: rayDistance,
				point: rayPoint,
				normal: segmentPoint.clone().sub(rayPoint).normalize(),
			});
		}
		return res;
	}
}

export const t = new EngineGeoAnalytPolyline(PolylineGeometry.newWithAutoIds(
	[new Vector3(0, 0, 0), new Vector3(1, 0, 0)], 1));

export class EngineAnalytPolylinesGeosSynced extends BimEngineGeometriesSyncBase<EngineGeoAnalytPolyline, PolylineGeometry> {

	readonly unitXLineId: LazyLambda<EntityId<EngineGeoType>>;

    constructor(
        logger: ScopedLogger,
        bimGeos: PolylineGeometries,
    ) {
        super(
			bimGeos,
			{
				logger,
				identifier: 'engine-line-analyt',
				idsType: EngineGeoType.AnalytPolyline,
				T_Constructor: EngineGeoAnalytPolyline,
				interner: (line) => {
					return JSON.stringify(line.bimGeo);
				},
				internerIdsReserver: () => this.reserveEngineOnlyId(),
			}
		);
		this.unitXLineId = new LazyLambda(() => this.shared!.get(t)!);
    }

	mapBimIdToEngineId(sourceId: EntityId<BimGeometryType>): EntityId<EngineGeoType> {
		return this.bimCollectionSource.idsProvider.mapIntoAnotherTypeRange(sourceId, this.idsType);
	}

    checkForErrors(t: EngineGeoAnalytPolyline): boolean {
        return !t.aabb().isEmpty() && t.bimGeo.points3d.length > 0;
    }

    convertFromBim(bimGeo: PolylineGeometry): Result<EngineGeoAnalytPolyline> {
        return new Success(new EngineGeoAnalytPolyline(bimGeo));
    }

	delete(idsToDelete: EntityId<EngineGeoType>[], e?: Partial<EventStackFrame> | undefined): [EntityId<EngineGeoType>, Readonly<EngineGeoAnalytPolyline>][] {
		const deleted = super.delete(idsToDelete, e);
		const unitLineId = this.unitXLineId.peek();
		if (unitLineId !== undefined) {
			for (const [id, line] of deleted) {
				if (id === unitLineId) {
					this.unitXLineId.clear();
					break;
				}
			}
		}
		return deleted;
	}

	allocateOrReferenceShared(polyline: PolylineGeometry): IdEngineGeo {
		const engineGeoPolyline = new EngineGeoAnalytPolyline(polyline);
		return this.shared!.get(engineGeoPolyline)!;
	}
}

