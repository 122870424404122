import type { ValueAndUnit } from "bim-ts";
import { NumberSpinnerUtils } from "../../pui/NumberSpinnerUtils";
import { IterUtils } from "engine-utils-ts";


export type MetricType = number | string | null | undefined;

export abstract class MetricNode {
    readonly name: string;

    constructor(args: {name: string}) {
        this.name = args.name;
    }
}
interface MetricPropertyNodeArgs {
    name: string;
    value: MetricType[];
    decimals?: number;
    unit?: string;
    isHeader?: boolean;
    colorRelativeIndex?: number;
    onClickCheckBox?: (context: any) => void;
}

export function formatMetricValue(valueUnit: string | ValueAndUnit | null | undefined, decimals: number) {
    if(valueUnit == null){
        return " "
    }
    if(typeof valueUnit === 'string'){
        return valueUnit;
    }
    const isNotNumber = valueUnit == undefined;
    return NumberSpinnerUtils.addUnit(valueUnit?.value ?? null, isNotNumber, decimals, valueUnit?.unit);
}

export class MetricPropertyNode extends MetricNode {
    readonly value: MetricType[];
    readonly decimals: number;
    readonly unit?: string;
    readonly isHeader?: boolean;
    readonly colorRelativeIndex?: number;
    readonly onClickCheckBox?: (context: any) => void;

    constructor(args: MetricPropertyNodeArgs) {
        super(args);
        this.value = args.value;
        this.decimals = args.decimals ?? 2;
        this.unit = args.unit;
        this.isHeader = args.isHeader;
        this.colorRelativeIndex = args.colorRelativeIndex;
        this.onClickCheckBox = args.onClickCheckBox;
    }
}

export type RelativeModeType = {
    units: string[];
    baseValueIdx: number;
    updateBaseValueIdx: (newIdx: number) => void;
}
interface MetricGroupNodeArgs {
    name: string;
    children?: Map<string, MetricNode>;
    total?: MetricPropertyNode;
    relativeMode?: RelativeModeType;
}

export class MetricGroupNode extends MetricNode {
    readonly children: Map<string, MetricNode>;
    readonly total?: MetricPropertyNode;
    readonly relativeMode?: RelativeModeType;

    constructor(args: MetricGroupNodeArgs) {
        super(args);
        this.children = args.children ?? new Map();
        this.total = args.total;
        this.relativeMode = args.relativeMode;
    }

    countItems(): number {
        if(this.children.size === 0){
            return 0;
        }
        const first = getFirstProperty(this);
        if(!first){
            return 0;
        }
        return first.value.length;
    }

    newWithRelativeMode(relativeMode: RelativeModeType): MetricGroupNode {
        const firstChild = IterUtils.getFirstFromIter(this.children);
        const children = new Map(this.children);
        if(firstChild?.[1] instanceof MetricPropertyNode){
            const [name, property] = firstChild;
            const newFirst = new MetricPropertyNode({...property, onClickCheckBox: relativeMode.updateBaseValueIdx});
            children.set(name, newFirst);
        }
        return new MetricGroupNode({ ...this, children, relativeMode });
    }
}

function getFirstProperty(node: MetricNode): MetricPropertyNode | null {
    if(node instanceof MetricPropertyNode){
        return node;
    } else if(node instanceof MetricGroupNode){
        for (const child of node.children.values()) {
            const prop = getFirstProperty(child);
            if(prop){
                return prop;
            }
        }
    }
    return null;
}

export function transformGroupToRelative(group: MetricGroupNode, relativeMode: RelativeModeType): MetricGroupNode {
    const newChildren = new Map<string, MetricNode>();
    for (const [key, child] of group.children.entries()) {
        if (child instanceof MetricPropertyNode) {
            const newChild = transformPropertyToRelative(child, relativeMode);
            newChildren.set(key, newChild);
        } else if (child instanceof MetricGroupNode) {
            newChildren.set(key, transformGroupToRelative(child, relativeMode));
        }
    }

    return new MetricGroupNode({
        ...group,
        total: group.total ? transformPropertyToRelative(group.total, relativeMode) : undefined,
        children: newChildren
    });
}

function transformPropertyToRelative(property: MetricPropertyNode, relativeMode: RelativeModeType): MetricPropertyNode {
    if(!property.unit || !relativeMode.units.includes(property.unit)){
        return new MetricPropertyNode({
            ...property,
            colorRelativeIndex: relativeMode.baseValueIdx,
        });
    }

    const newValue = property.value.map(val => {
        if (typeof val === 'number' && typeof property.value[relativeMode.baseValueIdx] === 'number') {
            const v1 = val as number;
            const v2 = property.value[relativeMode.baseValueIdx] as number;
            const relativeValue = (v1 === 0 || v2 === 0) ? 0 : (v1 / v2) * 100;
            return relativeValue;
        }
        return val;
    });

    return new MetricPropertyNode({
        ...property,
        value: newValue,
        colorRelativeIndex: relativeMode.baseValueIdx,
        unit: '%',
    });
}