import type { LazyVersioned, Result, ScopedLogger, Yield } from "engine-utils-ts";
import { Allocated, DefaultMap, Deleted, IterUtils, LogLevel, ObservableObject, Success, VersionedInvalidator } from "engine-utils-ts";
import type { GroupDescription, GroupIdent, GroupingSolver, GroupingSolverSettings } from "src/runtime/BimCustomGroupedRuntime";
import { BimCustomGroupedRuntime } from "src/runtime/BimCustomGroupedRuntime";
import type { PUI_GroupNode } from "ui-bindings";
import { calculateRows, Types } from "./RowsCalculator";
import type { Aabb } from "math-ts";
import type { Bim } from "src/Bim";
import type { IdBimScene, SceneInstance, SceneInstancePatch} from "src/scene/SceneInstances";
import { SceneObjDiff } from 'src/scene/SceneObjDiff';
import { EntitiesUpdated, type EntitiesCollectionUpdates } from "src/collections/EntitiesCollectionUpdates";
import type { RepresentationBase } from "src/representation/Representations";
import { TransformerIdent } from "src/archetypes/transformer/Transformer";
import { producePropsPatch } from "src/properties/Props";
import type { AnyTrackerProps } from "src/anyTracker/AnyTracker";
import { BimProperty } from "src/bimDescriptions/BimProperty";
import { PilesIndicesAndPositionsProperty } from "src/anyTracker/PilesIndices";
import { TrackerRowIndexAndPositionsProperty } from "src/anyTracker/TrackerRowIndex";
import type { InRowPositionPacked} from "./IndicesWithPositionPacker";
import { getInRowPosition, newIndexWithInRowPosition } from "./IndicesWithPositionPacker";


const row_index_property: string[] = ["circuit", "position", "row_index"];


export function registerTrackersRowsRuntime(
    bim: Bim,
) {
    bim.customRuntimes.registerCustomRuntime(new BimCustomGroupedRuntime(
        bim.logger.newScope('trackers-rows', LogLevel.Info),
        'trackers-rows-runtime',
        new TrackersRowsSolver(),
    ));
}


class TrackersRowsGroupDescription implements GroupDescription<TrackersRowsSingleGlobalGroupIdent> {
    constructor(
        public readonly ident: TrackersRowsSingleGlobalGroupIdent,
        public readonly invalidator: number,
        public readonly trackers: IdBimScene[],
    ) { }

    *inputInstancesIds(): Iterable<IdBimScene> {
        yield* this.trackers;
    }
}

class TrackersRowsSettings implements GroupingSolverSettings {
    enabled: boolean = true;
    delaySeconds: number = 0.2;
}

interface TrackersRowsGroupCalcResults {
    trackers_by_rows: [IdBimScene, InRowPositionPacked[] | undefined][][][];
}

class TrackersRowsSingleGlobalGroupIdent implements GroupIdent {
    sortKey: string = '';
    uniqueHash(): string | number {
        return this.sortKey;
    }
    constructor(index: number) {
        this.sortKey = index.toString();
        Object.freeze(this);
    }
}


export class TrackersRowsSolver implements GroupingSolver<
    TrackersRowsSingleGlobalGroupIdent,
    TrackersRowsGroupDescription,
    TrackersRowsGroupCalcResults,
    TrackersRowsSettings,
    {}
> {
    alwaysPassAllInvalidationBimUpdates = true;

    dirtyTrackers = new Set<IdBimScene>();
    dirtyTransformers = new Set<IdBimScene>();

    name: string = 'trackers-rows';
    executionOrder: number = 50;
    
    calculationsInvalidator = new VersionedInvalidator();

    settings: ObservableObject<TrackersRowsSettings>;
    ui: LazyVersioned<PUI_GroupNode> | undefined;

    constructor(
    ){
        this.settings = new ObservableObject<TrackersRowsSettings>({
            identifier: '',
            initialState: new TrackersRowsSettings(),
        });
    }

    invalidateFromSharedDependenciesUpdates(ident: never): void {
    }

    invalidate(
        logger: ScopedLogger,
        bim: Bim,
        instancesUpdate: EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>,
        allPrevUsedInstances: ReadonlySet<IdBimScene>,
    ): void {
        const relevantObjFlags = 
            SceneObjDiff.Representation
            | SceneObjDiff.GeometryReferenced
            | SceneObjDiff.WorldPosition
            | SceneObjDiff.SpatialParentRef
            | SceneObjDiff.SpatialDescendants;
        
        if (instancesUpdate instanceof Allocated) {
            for (const id of instancesUpdate.ids) {
                const type = bim.instances.peekTypeIdentOf(id);
                if (!type) {
                    continue;
                }
                if (Types.includes(type)) {
                    this.dirtyTrackers.add(id);
                    this.calculationsInvalidator.invalidate();
                }
            }
        } else if (instancesUpdate instanceof EntitiesUpdated) {
            if ((instancesUpdate.allFlagsCombined & relevantObjFlags) === 0) {
                return;
            }
            for (let i = 0; i < instancesUpdate.ids.length; ++i) {
                const diff = instancesUpdate.diffs[i];
                if ((diff & relevantObjFlags) === 0) {
                    continue;
                }

                const id = instancesUpdate.ids[i];
                if (allPrevUsedInstances.has(id)) {
                    this.dirtyTrackers.add(id);
                    this.calculationsInvalidator.invalidate();
                }
                const type = bim.instances.peekTypeIdentOf(id);
                if (!type) {
                    continue;
                }
                if (Types.includes(type)) {
                    if (!(diff & SceneObjDiff.SpatialDescendants)) {
                        this.dirtyTrackers.add(id);
                        this.calculationsInvalidator.invalidate();
                    }
                } else if (type === TransformerIdent && (diff & SceneObjDiff.SpatialDescendants)) {
                    this.dirtyTransformers.add(id);
                    this.calculationsInvalidator.invalidate();
                }
            }
        } else if (instancesUpdate instanceof Deleted) {
            for (const id of instancesUpdate.ids) {
                if (allPrevUsedInstances.has(id)) {
                    this.dirtyTrackers.add(id);
                    this.calculationsInvalidator.invalidate();
                }
            }
        } else {
            logger.error('Unexpected type of instancesUpdate', instancesUpdate);
        }
        return;
    }

    *generateCalculationGroups(args: {
        logger: ScopedLogger,
        bim: Bim,
    }): Generator<Yield, TrackersRowsGroupDescription[]> {
        const groups = IterUtils.newMapFromIter<IdBimScene, IdBimScene, IdBimScene[]>(
            this.dirtyTransformers, id => id, _ => []
        );
        this.dirtyTransformers.clear();
        const trackersWithoutBlock: IdBimScene[] = [];

        IterUtils.mapIter(this.dirtyTrackers, trackerId => {
            const transformerId = args.bim.instances.getClosestParentOfTypeFor(trackerId, TransformerIdent, true);
            if (transformerId) {
                if (!groups.has(transformerId)) {
                    groups.set(transformerId, []);
                }
            } else {
                trackersWithoutBlock.push(trackerId);
            }
        });
        this.dirtyTrackers.clear();

        for (const [groupId, childrenIds] of groups) {
            args.bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(
                groupId,
                (id, _) => {
                    const typeIdent = args.bim.instances.peekTypeIdentOf(id)!;
                    if (Types.includes(typeIdent)) {
                        childrenIds.push(id);
                        return false;
                    } else {
                        return true;
                    }
                },
                true
            )
        };

        if (trackersWithoutBlock.length > 0) {
            trackersWithoutBlock.length = 0;
            const trackers = args.bim.instances.peekByTypeIdents(Types);
            for (const [trackerId, _] of trackers) {
                const transformerId = args.bim.instances.getClosestParentOfTypeFor(trackerId, TransformerIdent, true);
                if (!transformerId) {
                    trackersWithoutBlock.push(trackerId);
                }
            }
            groups.set(-1, trackersWithoutBlock);
        }

        if (groups.size === 0) {
            args.logger.debug('no trackers to calculate shading for');
            return [];
        }

        const invalidator = this.calculationsInvalidator.version();

        return IterUtils.mapIter(groups, g => new TrackersRowsGroupDescription(
            new TrackersRowsSingleGlobalGroupIdent(g[0]),
            invalidator,
            g[1].sort((a, b) => a - b)
        ));
    }


    *startGroupResultsCalculation(args: { 
        logger: ScopedLogger; 
        bim: Bim; 
        groupDescription: TrackersRowsGroupDescription; 
    }): Generator<Yield, TrackersRowsGroupCalcResults> {
        const goemetriesAabbs = args.bim.allBimGeometries.aabbs.poll();
        const reprsBboxes = new DefaultMap<RepresentationBase, Aabb>(r => r.aabb(goemetriesAabbs));
        
        const group: [IdBimScene, SceneInstance][] = [];
        for (const id of args.groupDescription.trackers) {
            const instance = args.bim.instances.peekById(id);
            if (instance) {
                group.push([id, instance]);
            }
        }

        return { trackers_by_rows: calculateRows(group, reprsBboxes, false, 0, true) };
    }


    applyResultsToBim(args: {
        logger: ScopedLogger;
        bim: Bim;
        groupDescription: TrackersRowsGroupDescription;
        groupCalcResults: Result<TrackersRowsGroupCalcResults>;
    }): void {
        let trackersRowsGroups: [IdBimScene, number[] | undefined][][][];
        if (args.groupCalcResults instanceof Success) {
            trackersRowsGroups = args.groupCalcResults.value.trackers_by_rows;
        } else {
            return;
        }

        const patches: [IdBimScene, SceneInstancePatch][] = [];

        let nextIndex = 1;
        for (const rowsGroup of trackersRowsGroups) {
            for (let i = 0; i < rowsGroup.length; ++i) {
                const row = rowsGroup[i];
                for (let j = 0; j < row.length; ++j) {
                    const trackerId = row[j][0];
                    const instance = args.bim.instances.peekById(trackerId)!;
                    const trackerIndexWithPositionPacked = newIndexWithInRowPosition(
                        nextIndex + i, j + 1, getInRowPosition(j, row.length)
                    );
                    if (instance.type_identifier === "any-tracker") {
                        const propsPatch = producePropsPatch(instance.props as AnyTrackerProps, (props) => {
                            props.position.row_index = new TrackerRowIndexAndPositionsProperty({
                                value: trackerIndexWithPositionPacked
                            });
                            props.piles.indices = new PilesIndicesAndPositionsProperty({ values: row[j][1]?.reverse() });
                        });
                        if (propsPatch) {
                            patches.push([trackerId, { props: propsPatch }]);
                        }
                    } else {
                        patches.push([trackerId, { 
                            properties: [
                                [
                                    BimProperty.MergedPath(row_index_property),
                                    {
                                        path: row_index_property,
                                        value: nextIndex + i,
                                        numeric_step: 1,
                                        readonly: true,
                                    },
                                ],
                            ]
                        }])
                    }
                }
            }
            nextIndex += rowsGroup.length;
        }

        args.bim.instances.applyPatches(patches, { doesInvalidatePersistedState: true });
    }
}