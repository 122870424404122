import { createNotificationSource } from 'ui-bindings';

export const notificationSource = createNotificationSource({
    importSurfacePointsFilteredOut: {
        header: 'Import surface',
		description: ([pointsCount]) => `${pointsCount} surface points were filtered out`
    },
    fileLoad: {
        header: 'KML/KMZ load info',
		description: (message) => `${message}`
    },
    meteo_parse_warning: {
        header: 'meteo file parsing warning',
        description: joinArgs,
    },
    meteo_parse_error: {
        header: 'meteo file parsing error',
        description: joinArgs,
    },
    civil_plugin_download:{
        header: 'Download civil plugin error',
        description: (message) => `${message}`
    },
    shdExport: {
        header: 'Terrain following arrays support by PVSyst',
		description: (message) => `${message}`
    },
    projectionMethodMissing: {
        header: "Imported file has no projection method data",
        description: "The positions of any imported objects may look incorrect in your subsequent workflow steps. Please try to find an option to add a projection method using your software's help center, then save and import the file again"
    },
    terrainAreaTooLarge: {
        header: 'Surface import failed',
		description: ([terrainArea, maxArea]) => `Terrain area is too large: ${terrainArea} km^2. Maximum is ${maxArea} km^2`
    },
    coordinatesAreTooFarFromProjectOrigin: {
        header: "File not imported due to lack of geo-coordinates",
        description: () => `The imported file has no geo-coordinates, making it impossible to determine how to align it with the existing project objects. 
            Please consult your software's help center for instructions on setting geo-coordinates, then save and re-import the file. 
            Alternatively, consider using data from reliable public sources, which typically include accurate geo-coordinates`
    },
    objectsMayBeMisaligned: {
        header: "Imported objects may be misaligned",
        description: () => `The imported terrain geo-coordinates data is partially missing, making it impossible to verify the alignment with existing objects. 
            For accurate placement, consult your software's help centre for instructions on setting geo-coordinates, then save and re-import the file. 
            Alternatively, consider using terrain data from reliable public sources, which typically include accurate geo-coordinates.`
    },
});

function joinArgs(args: string[]) {
    return args.join(';\r\n');
}