import type { RGBAHex, Result} from "engine-utils-ts";
import { RGBA, LruCache, ObjectUtils, IterUtils, convertUnits, WorkerClassPassRegistry, IsInWebworker, Failure, Success } from "engine-utils-ts";
import { sumValueUnitLike, type UnitsMapper, type ValueAndUnit } from "../UnitsMapper";
import { PropertyBase, type PropsGroupField } from "./Props";
import { CustomPropsRegistry } from './CustomPropsRegistry';
import { PropertyViewBasicTypes } from './BasicPropsView';


export interface PrimitivePropertyBaseParams<T> {
    value: T;
    isReadonly?: boolean;
    description?: string | undefined;
}

export abstract class PrimitivePropertyBase<T> extends PropertyBase {

    readonly value: T;
    readonly isReadonly: boolean;
    readonly description?: string | undefined;

    protected constructor(params: Partial<PrimitivePropertyBase<T>>) {
        super();
        if (params.value === undefined) {
            throw new Error('value should be provided');
        }
        this.value = params.value;
        this.isReadonly = params.isReadonly ?? false;
        this.description = params.description;
    }

    hash(): string | number {
        let res = ``;
        for (const key in this) {
            const v = this[key];
            if (Array.isArray(v)) {
                if (v.length < 10) {
                    res += `${v.join(',')}|`;
                } else {
                    let hash = 1;
                    for (const el of v) {
                        hash = (hash * 31 + ObjectUtils.stringHash(el + '')) | 0;
                    }
                    res += `${v.length}::${hash}|`;
                }
            } else if (v instanceof Object) {
                throw new Error(`unexepected field in primitive property`  + key);
            } else if (typeof v !== 'function' && v !== undefined) {
                res += v + "|";
            }
        }
        return res;
    }

    uniqueValueHash(): number | string {
        if (typeof this.value === 'object') {
            throw new Error('object values hash should be overrided');
        }
        return typeof this.value === 'number' ? this.value : String(this.value);
    }

    equals(other: PropsGroupField): boolean {
        if (this.constructor !== other?.constructor) {
            return false;
        }
        return Object.is(this.value, (other as PrimitivePropertyBase<T>).value)
            && this.description === (other as PrimitivePropertyBase<T>).description
            && this.isReadonly === (other as PrimitivePropertyBase<T>).isReadonly;
    }

    abstract withDifferentValue(value: T): ThisType<this>;
}

export class BooleanProperty extends PrimitivePropertyBase<boolean> {

    private constructor(args: Partial<BooleanProperty>) {
        super(args);
    }

    static new(args: Partial<BooleanProperty>) {
        const p = new BooleanProperty(args);
        const interned = propsInterner.get(p);
        return interned as BooleanProperty;
    }

    withDifferentValue(value: boolean): BooleanProperty {
        return BooleanProperty.new({
            ...this,
            value
        })
    }
}
CustomPropsRegistry.register({
    class: BooleanProperty as any,
    constructFromPartial: BooleanProperty.new,
    basicTypesView: {
        basicTypes: PropertyViewBasicTypes.Boolean,
        toBasicValues: (formatters, p) => p
    }
});
WorkerClassPassRegistry.registerClassExtended<BooleanProperty>(BooleanProperty.name, BooleanProperty.new);


export class NumberProperty extends PrimitivePropertyBase<number> {

    readonly unit: string;
    readonly step: number | undefined;
    readonly range: [number, number] | undefined;

    protected constructor(args: Partial<NumberProperty>) {
        super(args);
        this.unit = args.unit ?? '';
        this.step = args.step;
        this.range = args.range;
    }

    static new(args: Partial<NumberProperty>) {
        const p = new NumberProperty(args);
        const interned = propsInterner.get(p);
        return interned as NumberProperty;
    }

    equals(other: PropsGroupField): boolean {
        return super.equals(other)
            && this.unit === (other as NumberProperty).unit
            && this.step === (other as NumberProperty).step
            && IterUtils.areOptionalArraysEqual(this.range, (other as NumberProperty).range)
    }

    valueUnitUiString(mapper: UnitsMapper, digits: number = 1): string {
        if (this.unit) {
            const {value, unit} = mapper.mapToConfigured({value: this.value, unit: this.unit});
            return `${value.toFixed(digits)} ${unit}`;
        }
        return this.value.toFixed(digits) + '';
    }

    valueUnitUiStringPrecise(mapper: UnitsMapper): string {
        if (this.unit && typeof this.value == 'number') {
            const {value, unit} = mapper.mapToConfigured({value: this.value, unit: this.unit})!;
            return `${value.toFixed(4)} ${unit}`;
        }
        return this.value.toFixed(4) + '';
    }

    as(unit: string): number {
        if (this.unit === unit) {
            return this.value;
        }
        return NumberProperty.convert(this.value as number, this.unit as string, unit);
    }

    public static convert(value: number, from: string, to: string) {
        const r = convertUnits(value, from, to);
        if (r instanceof Success) {
            return r.value;
        }
        console.error(`units conversion error value:${value} from:${from} to:${to}`, r.errorMsg());
        return 0;
    }

    with(args: Partial<NumberProperty>): NumberProperty {
        return NumberProperty.new({
            ...this,
            ...args
        });
    }

    withDifferentValue(value: number): NumberProperty {
        return NumberProperty.new({
            ...this,
            value
        })
    }

    withDifferentUnit(unit: string): NumberProperty {
        return NumberProperty.new({
            ...this,
            unit,
        });
    }

    toConfiguredUnits(mapper: UnitsMapper): NumberProperty {
        const mappedValueUnit = mapper.mapToConfigured({ value: this.value, unit: this.unit });
        return NumberProperty.new({
            ...this,
            value: mappedValueUnit.value,
            unit: mappedValueUnit.unit ?? this.unit,
        });
    }

    static unitBasedCompare(a: ValueAndUnit, b: ValueAndUnit) {
        const bInAUnits = convertUnits(b.value, b.unit ?? '', a.unit ?? '');
        if (bInAUnits instanceof Failure) {
            return bInAUnits;
        }
        const diff = a.value - bInAUnits.value;
        return new Success(Math.sign(diff));
    }

    static unitBasedSumV2 = sumValueUnitLike

    static unitBasedSum(
        nums: IterableIterator<ValueAndUnit> | ValueAndUnit[]
    ): Result<NumberProperty> {
        const numsIter = Array.isArray(nums) ? nums.values() : nums
        const first = numsIter.next();
        if (!first.value) {
            return new Failure({msg: 'no numbers provided'});
        }
        let sum: ValueAndUnit = {
            value: first.value.value,
            unit: first.value.unit ?? '',
        }
        for (const num of numsIter) {
            const numValInSumUnitsResult = convertUnits(num.value, num.unit ?? '', sum.unit!);
            if (numValInSumUnitsResult instanceof Failure) {
                return numValInSumUnitsResult
            }
            sum.value = sum.value + numValInSumUnitsResult.value;
        }
        return new Success(NumberProperty.new({
            value: sum.value,
            unit: sum.unit!,
        }));
    }

    get decimals(): 4 | 3 | 2 | 1 | 0  {
        const step = this.step ?? 0.0001;
        return step < 0.01 ? 4 : step < 0.1 ? 3 : step < 0.5 ? 2 : 0
    }
}
CustomPropsRegistry.register({
    class: NumberProperty as any,
    constructFromPartial: NumberProperty.new,
    basicTypesView: {
        basicTypes: PropertyViewBasicTypes.Numeric,
        toBasicValues: (formatters, p) => p
    }
});

export class ColorProperty extends PrimitivePropertyBase<RGBAHex> {

    private constructor(args: Partial<ColorProperty>) {
        super(args);
    }

    static new(args: Partial<ColorProperty>) {
        const p = new ColorProperty(args);
        const interned = propsInterner.get(p);
        return interned as ColorProperty;
    }

    withDifferentValue(value: RGBAHex): ColorProperty {
        return ColorProperty.new({
            ...this,
            value
        })
    }

    withDifferentValueFromHex(hex: string): ColorProperty {
        return ColorProperty.new({
            ...this,
            value: RGBA.parseFromHexString(hex)
        })
    }

    asHexRgbString(){
        return RGBA.toHexRgbString(this.value)
    }
}
CustomPropsRegistry.register({
    class: ColorProperty as any,
    constructFromPartial: ColorProperty.new,
    basicTypesView: {
        basicTypes: PropertyViewBasicTypes.String,
        toBasicValues: (formatters, p) => ({ value: RGBA.toHexRgbString(p.value) })
    }
});


export class StringProperty extends PrimitivePropertyBase<string> {

    constructor(args: Partial<StringProperty>) {
        super(args);
    }

    static new(args: Partial<StringProperty>) {
        const p = new StringProperty(args);
        const interned = propsInterner.get(p);
        return interned as StringProperty;
    }

    withDifferentValue(value: string): StringProperty {
        return StringProperty.new({
            ...this,
            value
        })
    }
}
CustomPropsRegistry.register({
    class: StringProperty as any,
    constructFromPartial: StringProperty.new,
    basicTypesView: {
        basicTypes: PropertyViewBasicTypes.String,
        toBasicValues: (formatters, p) => (p)
    }
});


export class SelectorProperty extends PrimitivePropertyBase<string>{

    readonly options:string[];

    private constructor(args: Partial<SelectorProperty>) {
        super(args);
        if (!args.options) {
            throw new Error('options should be provided');
        }
        this.options = args.options;
    }

    static new(args: Partial<SelectorProperty>) {
        const p = new SelectorProperty(args);
        const interned = propsInterner.get(p);
        return interned as SelectorProperty;
    }

    equals(other: PropsGroupField): boolean {
        return super.equals(other)
            && IterUtils.areOptionalArraysEqual(this.options, (other as SelectorProperty).options)
    }

    withDifferentValue(value: string): SelectorProperty {
        return SelectorProperty.new({
            ...this,
            value
        })
    }
}
CustomPropsRegistry.register({
    class: SelectorProperty as any,
    constructFromPartial: SelectorProperty.new,
    basicTypesView: {
        basicTypes: PropertyViewBasicTypes.String,
        toBasicValues: (formatters, p) => (p)
    }
});


export class MultiSelectorProperty extends PrimitivePropertyBase<string[]> {

    readonly options: string[];
    readonly maxSelect: number | undefined;

    private constructor(args: Partial<MultiSelectorProperty>) {
        super(args);
        if (!args.options) {
            throw new Error('options should be provided');
        }
        this.options = args.options;
        this.maxSelect = args.maxSelect;
    }

    static new(args: Partial<MultiSelectorProperty>) {
        const p = new MultiSelectorProperty(args);
        const interned = propsInterner.get(p);
        return interned as MultiSelectorProperty;
    }

    equals(other: PropsGroupField): boolean {
        if (!(other instanceof MultiSelectorProperty)) {
            return false;
        }
        return IterUtils.areArraysEqual(this.value, other.value)
            && this.description === other.description
            && this.isReadonly === other.isReadonly
            && IterUtils.areOptionalArraysEqual(this.options, other.options)
            && this.maxSelect === other.maxSelect
    }

    uniqueValueHash(): string | number {
        return this.value.join(',');
    }

    withDifferentValue(value: string[]): Readonly<MultiSelectorProperty> {
        return MultiSelectorProperty.new({
            ...this,
            value,
        });
    }
}
CustomPropsRegistry.register({
    class: MultiSelectorProperty as any,
    constructFromPartial: MultiSelectorProperty.new,
    basicTypesView: {
        basicTypes: PropertyViewBasicTypes.String,
        toBasicValues: (formatters, p) => ({value: p.value.join(',')})
    }
});


interface ValueRenderFormatterParams<Context> {
    value: number;
    unit?: string;
    option: string;
    context: Context
}

export interface ValueRenderFormatter<Context = any> {
    context: Context;
    formatter: (args: ValueRenderFormatterParams<Context>) => string;
    textColorFormatter?: (args: ValueRenderFormatterParams<Context>) => string;
    parser?: (args: {value: string, prevValue: number, unit?: string, option: string, context: Context}) => number;
    isReadonly?: (args: ValueRenderFormatterParams<Context>) => boolean;
}

export interface NumberPropertyWithOptionsContext<T = undefined> {
    doNotShowOptions?: boolean;
    valueRenderFormatter?: ValueRenderFormatter<T>
}

export class NumberPropertyWithOptions extends NumberProperty {
    readonly selectedOption: string;
    readonly options: string[];


    private constructor(args: Partial<NumberPropertyWithOptions>) {
        super(args);
        if (!args.options) {
            throw new Error('options should be provided');
        }
        this.options = args.options;
        this.selectedOption = args.selectedOption ?? this.options[0];
    }

    static new(args: Partial<NumberPropertyWithOptions>) {
        const p = new NumberPropertyWithOptions(args);
        const interned = propsInterner.get(p);
        return interned as NumberPropertyWithOptions;
    }

    equals(other: PropertyBase): boolean {
        if (!(other instanceof NumberPropertyWithOptions)) {
            return false;
        }
        return super.equals(other)
            && this.selectedOption === other.selectedOption
            && IterUtils.areOptionalArraysEqual(this.options, other.options)
    }

    withDifferentValue(value: number): NumberPropertyWithOptions {
        return NumberPropertyWithOptions.new({
            ...this,
            value
        });
    }

    withDifferentOption(option: string): NumberPropertyWithOptions {
        return NumberPropertyWithOptions.new({
            ...this,
            selectedOption: option
        });
    }

    get decimals(): 4 | 3 | 2 | 1 | 0 {
        const step = this.step ?? 0.0001;
        return getDecimals(step);
    }
}
CustomPropsRegistry.register({
    class: NumberPropertyWithOptions as any,
    constructFromPartial: NumberPropertyWithOptions.new,
});


function getDecimals(step: number) {
    return step <= 0.0001 ? 4 : step <= 0.001 ? 3 : step <= 0.01 ? 2 : step <= 0.1 ? 1 : 0;
}


export class NumberRangeProperty extends PrimitivePropertyBase<[min: number, max: number]> {
    readonly unit: string;
    readonly step: number | undefined;
    readonly range: [number, number] | undefined;

    private constructor(args: Partial<NumberRangeProperty>) {
        super(args);
        this.range = args.range;
        this.unit = args.unit ?? "";
        this.step = args.step !==undefined ? args.step : 0.01;
    }

    static new(args: Partial<NumberRangeProperty>) {
        const p = new NumberRangeProperty(args);
        const interned = propsInterner.get(p);
        return interned as NumberRangeProperty;
    }

    equals(other: PropsGroupField): boolean {
        if (!(other instanceof NumberRangeProperty)) {
            return false;
        }
        
        return IterUtils.areArraysEqual(this.value, other.value)
            && this.description === other.description
            && this.isReadonly === other.isReadonly
            && this.unit === other.unit
            && Object.is(this.step, other.step)
            && IterUtils.areOptionalArraysEqual(this.range, other.range)
    }

    withDifferentValue(value: [number, number]): NumberRangeProperty {
        return NumberRangeProperty.new({
            ...this,
           value,
        });
    }

    toConfiguredUnits(mapper: UnitsMapper) {
        const mappedValueUnit1 = mapper.mapToConfigured({ value: this.value[0], unit: this.unit });
        const mappedValueUnit2 = mapper.mapToConfigured({ value: this.value[1], unit: this.unit });
        return NumberRangeProperty.new({
            ...this,
            value: [mappedValueUnit1.value, mappedValueUnit2.value],
            unit: mappedValueUnit1.unit ?? this.unit,
        });
    }

    as(unit: string): [number, number] {
        if (this.unit === unit) {
            return this.value;
        }
        const min = NumberProperty.convert(this.value[0], this.unit, unit);
        const max = NumberProperty.convert(this.value[1], this.unit, unit);
        return [min, max];
    }
    
    get decimals(): 4 | 3 | 2 | 1 | 0 {
        const step = this.step ?? 0.0001;
        return getDecimals(step);
    }
}
CustomPropsRegistry.register({
    class: NumberRangeProperty as any,
    constructFromPartial: NumberRangeProperty.new,
    basicTypesView: {
        basicTypes: PropertyViewBasicTypes.String,
        toBasicValues: (formatters, p) => ({value: `[${p.value[0]}, ${p.value[1]}]`})
    }
});


export abstract class EnumProperty<E extends number|string> extends PrimitivePropertyBase<E> {

    // static new<E extends number|string>(args: Partial<EnumProperty<E>>): EnumProperty<E> {
    //     const p = new EnumProperty(args);
    //     const interned = propsInterner.get(p);
    //     return interned as EnumProperty<E>;
    // }

    // withDifferentValue(value: E): ThisType<this> {
    //     return EnumProperty.new({
    //         ...this,
    //         value
    //     });
    // }

    abstract enumObjectRef(): E;
}

// export interface EnumRegistrationArgs {
//     enumObject: any;
//     // todo: migrations
// }

// class EnumsRegistry {
//     private _enums: Map<string, any> = new Map();


//     registerEnum<E extends number|string>(args: EnumRegistrationArgs) {
//         this._enums.set(args.enumObject, args.enumObject);
//     }
// }


// export class EnumProperty<E extends number> extends PrimitivePropertyBase<E> {



//     constructor() {
//         super();
//     }

//     hash(): string {
//         return this.value as number;
//     }

//     uniqueValueHash(): string {
//         return this.value as number;
//     }
// }








const propsInterner: LruCache<PropertyBase, PropertyBase> = new LruCache({
    identifier: `interned-props`,
    maxSize: IsInWebworker() ? 100 : 5000,
    hashFn: (p) => {
        const ty = p.constructor.name;
        const hash = p.hash();
        return `${ty}:${hash}`;
    },
    eqFunction: (a, b) => {
        return a.equals(b);
    },
    factoryFn: (p) => p,
});

if ((globalThis as any)['new_primitive_props_interned']) {
    console.error('interned props double init, check bundling');
} else {
    (globalThis as any)['new_primitive_props_interned'] = propsInterner;
}

