import { Vector3 } from 'math-ts';
import * as flatbuffers from 'flatbuffers';
import { FlatBuffersSiteModel } from './flatBufferSurfaceModel/flat-buffers';
import { BorderJson, LayoutGeoData, LayoutJson, RoadDataInput, ShadingDataInput, SurfaceJson, TransformerDataInput } from './LayoutParser';
import { ProjectionInfo } from 'bim-ts';

export function deserializeFlatBufferModel(fileArrayBuffer: ArrayBuffer): LayoutJson {

	const bytes = new Uint8Array(fileArrayBuffer);

	let buf = new flatbuffers.ByteBuffer(bytes);
	let siteBinModel = FlatBuffersSiteModel.getRootAsFlatBuffersSiteModel(buf);
	const ver = siteBinModel.pluginVersion() ?? ""

	const surfaces = parseSurfaces(siteBinModel);
	const geoData = parseGeodata(siteBinModel);
	const borders = parseBorders(siteBinModel);
	const trackers = parseTrackers(siteBinModel);
	const transformers = parseTransformers(siteBinModel);
	const roads = parseRoads(siteBinModel);

	const siteModel:LayoutJson = {
		Contour3D:borders, 
		Transformers:transformers, 
		Roads:roads, 
		Trackers:trackers, 
		GeoData:geoData,
		PluginVersion:ver,
		Surfaces:surfaces
	}

	return siteModel;
}

function parseSurfaces(siteBinModel:FlatBuffersSiteModel):SurfaceJson[]{
	const numOfSurfaces = siteBinModel.surfacesLength();
	const surfaces = [];

	for (let i = 0; i < numOfSurfaces; i++) {
		
		const surface = siteBinModel.surfaces(i);
		if (surface == null) {
			continue;
		}
		const contours: number[][] = [];
		const name = surface.name() ?? "";

		const numOfContours = surface.contoursPoints3dLength();
		const innerPointsArr = surface.innerPoints3dArray()?.slice() ?? new Float64Array();

		const linearMinMaxArr = surface.linearCoordsMinMax2dArray();
		const linearMinMax = floar64ArrayToArrayOfNumbers(linearMinMaxArr);

		const geoMinMaxArr = surface.geographicCoordsMinMax2dArray();
		const geoMinMax = floar64ArrayToArrayOfNumbers(geoMinMaxArr);

		for (let i = 0; i < numOfContours; i++) {
				
			const contour = surface.contoursPoints3d(i);

			if (contour !== null) {

				const numbers = contour.pointsArray();
				const contourAsArrayOfNumbers = floar64ArrayToArrayOfNumbers(numbers);
				contours.push(contourAsArrayOfNumbers);
				
			}
		}

		const surfaceObject: SurfaceJson = {
			Name: name,
			ContoursPoints3d: contours,
			InnerPoints3d: innerPointsArr,
			LinearCoordsMinMax2d: linearMinMax,
			GeographicCoordsMinMax2d: geoMinMax,
		}

		surfaces.push(surfaceObject);
	}
	return surfaces;

}

function parseTrackers(siteBinModel:FlatBuffersSiteModel): ShadingDataInput[]{
	const trackers: ShadingDataInput[] = [];

	for(let i=0; i< siteBinModel.trackersLength(); i++){
		const binTracker = siteBinModel.trackers(i);
		if(binTracker==null){
			continue;
		}

		const origData = binTracker.originArray()
		if(origData!==null){
			const trackerOrigin = floar64ArrayToArrayOfNumbers(origData);

			const trackerData: ShadingDataInput = 
		{
			Name: binTracker.name()??'',
			Length: binTracker.length(),
			Width: binTracker.width(),
			Manufacturer: binTracker.manufacturer()??'',
			ModuleModel: binTracker.moduleModel()??'',
			ModulePower: binTracker.modulePower()??'',
			NumberOfModulesPerRow: binTracker.numberOfModulesPerRow(),
			NumberOfModulesPerString: binTracker.numberOfModulesPerString(),
			Origin: trackerOrigin,
			Rotation: binTracker.rotation(),
			TransformerId: binTracker.transformerId()??'',
		}

		trackers.push(trackerData);

		}else{
			console.warn(`Can't create tracker ${binTracker.name()} due to incorrect origin data`)
			continue;
		}
		
		
	}
	return trackers;
}

function parseBorders(siteBinModel:FlatBuffersSiteModel):BorderJson[]{
	const borders:BorderJson[] = [];
	for(let i=0; i< siteBinModel.contour3dLength(); i++){
		const binBorder = siteBinModel.contour3d(i);
		if (binBorder !== null) {
			const numbers = binBorder.pointsArray();
			const contourAsArrayOfNumbers = floar64ArrayToArrayOfNumbers(numbers);
			const borderVertices = Vector3.arrayFromFlatArray(contourAsArrayOfNumbers);
			const name = binBorder.name()??"";
			const borderData:BorderJson = {name:name, points:borderVertices};
			
			borders.push(borderData);		
		}
	}
	return borders;
}

function parseRoads(siteBinModel: FlatBuffersSiteModel) {
	const roads: RoadDataInput[] = [];
	for (let i = 0; i < siteBinModel.roadsLength(); i++) {
		const binRoad = siteBinModel.roads(i);
		if (binRoad == null) {
			continue;
		}
		const width = binRoad.width();

		const pointsData: Vector3[] = [];
		for (let j = 0; j < binRoad.pointsLength(); j += 3) {

			const x = binRoad.points(j);
			const y = binRoad.points(j + 1);
			const z = binRoad.points(j + 2);
			if (x!==null && y!==null && z!==null) {
				pointsData.push( new Vector3(x, y, z));
			}

		}
		const road: RoadDataInput = { Points: pointsData, Width: width };
		roads.push(road);
	}

	return roads;
}

function parseGeodata(siteBinModel: FlatBuffersSiteModel): LayoutGeoData | undefined {
	let geodataJson: LayoutGeoData | undefined = undefined;
	const binGeodata = siteBinModel.geodata();
	if (binGeodata !== null) {
		const lat = binGeodata.latitude();
		const lon = binGeodata.longitude();
		const alt = binGeodata.altitude();
		const originData = binGeodata.wgsOriginArray();
		const origin = Vector3.zero();
		const projectionInfoRawData = binGeodata.projectionInfo();

		let method: string | null = null;
		const geoDataParameters = new Map<string, string>();

		if (projectionInfoRawData !== null) {
			method = projectionInfoRawData.method();

			for (let i = 0; i < projectionInfoRawData.parametersLength(); i++) {
				const parameter = projectionInfoRawData.parameters(i);
				if (parameter !== null) {
					const key = parameter.key();
					const value = parameter.value();
					if (key !== null && value !== null) {
						geoDataParameters.set(key, value);
					}
				}
			}
		}

		let projectionInfo: ProjectionInfo | undefined = undefined;

		if (method !== null && geoDataParameters.size > 0) {
			projectionInfo = new ProjectionInfo(method, geoDataParameters);
		}

		if (originData !== null) {
			origin.set(originData[0], originData[1], originData[2]);
		}
		geodataJson = { ReferencePoint:{Latitude: lat, Longitude: lon, Altitude: alt}, ReferencePointOrigin: origin, ProjectionInfo: projectionInfo };
	}

	return geodataJson;
}

function parseTransformers(siteBinModel:FlatBuffersSiteModel){
	const transformers: TransformerDataInput[] = [];

	for(let i=0; i< siteBinModel.transformersLength(); i++){
		const binTransformer = siteBinModel.transformers(i);
		if(binTransformer==null){
			continue;
		}

		const origData = binTransformer.originArray();
		if(origData!==null){		
			const transformerOrigin = floar64ArrayToArrayOfNumbers(origData);

			const transformer: TransformerDataInput = {
				Name: binTransformer.name()??'',
				Origin: transformerOrigin,
				Rotation: binTransformer.rotation(),
				Id: binTransformer.id()??'',
				BlockNumber: binTransformer.blockNumber()
			}
			transformers.push(transformer);
		}else{
			console.warn(`Can't create transformer ${binTransformer.name()} due to incorrect origin data ${origData}`)
		}
	}
	return transformers;
}

function floar64ArrayToArrayOfNumbers(array: Float64Array | null): number[] {
	return array ? Array.from(array) : [];
}
