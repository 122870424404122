import { CachedCalculations, IterUtils } from 'engine-utils-ts';
import type { Aabb} from 'math-ts';
import { Matrix4, Vector3 } from 'math-ts';

import type { ClipBox } from '../clipbox/ClipBox';
import type { GesturesMousePos } from '../controls/MouseGesturesBase';
import type { KrCamera } from '../controls/MovementControls';
import type { EngineGeometry } from '../geometries/EngineGeometry';
import type {
	GeometryIntersection} from '../geometries/GeometryUtils';
import { IntersectionType,
} from '../geometries/GeometryUtils';
import { ClipboxShaderEnlargment } from '../materials/GlobalUniforms';
import { HandleIndexMask } from '../memory/Handle';
import { IsMaterialSelectable } from '../pools/EngineMaterialId';
import type { SnappingSettings } from '../SnappingSettings';
import { KrFrustum } from '../structs/KrFrustum';
import type { RaySection } from '../structs/RaySection';
import type { index } from '../utils/Utils';
import type { ConeCastResults} from './BoundsSceneWrap';
import { MouseRayCone } from './BoundsSceneWrap';
import { InObjFullId } from './EngineSceneIds';
import type { ESOsCollection } from './ESOsCollection';
import type { IdEngineObject } from './InteractiveSceneObjects';
import type { InteractiveObjectIntersection } from './Raycasts';
import type {
	EngineSubmeshDescription, Submeshes2, SubmeshHandle,
} from './Submeshes2';
import { RenderListFlags } from '../composer/RenderLists';
import type { SubmeshesInstancingRawBlocks } from './SubmeshesInstancingRawBlocks';

export class SubmeshesRaycasts {

	readonly submeshes: Submeshes2;
	readonly esos: ESOsCollection;

	readonly submeshesRawDataBlocks: SubmeshesInstancingRawBlocks;

	readonly boundsConeCasts: CachedCalculations<MouseRayCone, ConeCastResults>;

	constructor(
		submeshes: Submeshes2,
		engineObjcets: ESOsCollection,
		readonly clipBox: ClipBox,
	) {
		this.esos = engineObjcets;
		this.submeshes = submeshes;
		this.submeshesRawDataBlocks = this.submeshes.submeshesInstancingRawBlocks;

		this.boundsConeCasts = new CachedCalculations({
			identifier: 'submeshes-bounds-conecasts',
			calculator: (cone) => {
				return this.submeshes.scene.coneCast(cone);
			},
			lazyInvalidation: this.submeshes.scene,
			maxCacheSize: 5,
		});
	}

	

	intersectRay<Int extends InteractiveObjectIntersection>(
		camera: KrCamera,
		mousePos: GesturesMousePos,
		filter: (s: Readonly<EngineSubmeshDescription<Object>>) => boolean,
		interesectionCtor: (geoInt: GeometryIntersection, submesh: EngineSubmeshDescription<Object>) => Int | null,
	): Int | null {
		const cone = MouseRayCone.newForInCameraPos(camera, mousePos);
		if (!cone) {
			return null;
		}

		const conecast = this.boundsConeCasts.acquire(cone);
		const rawBoundsCastTriples = conecast.raw;
		const submeshes = this.submeshes;

		const clipboxBounds = this.clipBox.getBounds();
		clipboxBounds.expandByScalar(ClipboxShaderEnlargment);

		let result: Int | null = null;

		for (let i = 0; i < rawBoundsCastTriples.length; i += 3) {
			const submeshIndex = rawBoundsCastTriples[i] | 0;
			const rayDist = rawBoundsCastTriples[i + 1];
			const toRayDist = rawBoundsCastTriples[i + 2];

			if (result && result.distance < rayDist) {
				continue;
			}
			if (!this.isSelectable(submeshIndex)) {
				continue;
			}

			const submesh = submeshes.submeshesDescriptions[submeshIndex];
			if (!submesh) {
				continue;
			}
			if (!filter(submesh)) {
				continue;
			}

			const geometry = submesh.geometry;
	
			if (!geometry.isViewDependent() && !this.submeshes.bounds.intersectsRay(submeshIndex, cone.raySection.ray, null)) {
				continue;
			}

			const submeshInstances = this.submeshesRawDataBlocks.asIterable(submeshIndex, reusedMatrix4);
			for(const instance of submeshInstances) {
				const geometryIntersection = SubmeshesRaycasts.raycastGeo(geometry, instance, cone.raySection, clipboxBounds, camera);
				const intersection = geometryIntersection && interesectionCtor(
					geometryIntersection,
					submesh
				);

				if(intersection !== null && (result === null || intersection.distance < result.distance)) {
					result = intersection;
				}
			}
		}
		return result;
	}

	
	static raycastGeo(
		geometry: EngineGeometry,
		matrix: Matrix4,
		raySection: RaySection,
		clippingBox: Aabb,
		camera: KrCamera,

	): GeometryIntersection | null {
		let minDistanceYet = Infinity;
		let closesetIntersYet: GeometryIntersection | null = null;
		const intersections = geometry.raycast(raySection, matrix, camera);
		for (let i = 0; i < intersections.length; ++i){
			const int = intersections[i];
			const isInsideBox = clippingBox.containsPoint(int.point);
			if (!(isInsideBox)) {
				continue;
			}
			if (int.distance < minDistanceYet) {
				minDistanceYet = int.distance;
				closesetIntersYet = int;
			}
		}
		return closesetIntersYet;
	}
	
	isSelectable(handle: SubmeshHandle | number): boolean {
		let index = handle & HandleIndexMask;
		const rl = this.submeshes.renderListsIdents.buffer[index];
		if (!rl) {
			return false;
		}
		const renderJobsToCheck = rl === RenderListFlags.Overlay ? this.submeshes.renderJobsOverlay : this.submeshes.renderJobsMain;
		const rj = renderJobsToCheck[index];
		if (rj == undefined || !(IsMaterialSelectable(rj.materialIDF))) {
			return false;
		}
		const clipBoxPos = this.submeshes.scene._clipboxPositions[index];
		if (clipBoxPos === IntersectionType.Outside) {
			return false;
		}
		return true;
	}

	*_getSubmeshesToUseForSnapping(
		snapNearPosition: Vector3, skipIds: Set<IdEngineObject>, snappingMaxDistance: number
	): Iterable< [aabbDistnace: number, submesh: EngineSubmeshDescription, worldMatrix: Matrix4]> {
		

		const raycasted_flat = this.submeshes.scene.find_close_to_point(snapNearPosition, snappingMaxDistance);

		for (let i = 0; i < raycasted_flat.length; i += 2) {
			const submeshIndex = raycasted_flat[i];
			const aabbToPointDistance = raycasted_flat[i + 1];
			const parentHandle = this.submeshes.rootObjsRefs.getParentRef(submeshIndex);
			const submesh = this.submeshes.submeshesDescriptions[submeshIndex];
			if (parentHandle == undefined || !submesh) {
				continue;
			}
			const submeshParentId = this.esos.idOf(parentHandle)!;
			if (skipIds.has(submeshParentId) || skipIds.has(submesh.fullId)) {
				continue;
			}
			
			const submeshInstances = this.submeshesRawDataBlocks.map<[number, EngineSubmeshDescription, Matrix4]>(
				submeshIndex, reusedMatrix4,(instanceMatrix: Matrix4) => {
					const worldMatrix = new Matrix4().copy(instanceMatrix);
					return [aabbToPointDistance, submesh, worldMatrix];
			});

			yield * submeshInstances;
		}
	}

	findPointToSnap(snapNearPosition: Vector3, skipIds: Set<IdEngineObject>, camera: KrCamera, settings: SnappingSettings): Vector3 | null {

		const additionalIdsToSkip = IterUtils.filterMap(skipIds, id => {
			if (id instanceof InObjFullId) {
				return this.submeshes.esos.idOf(id.objHandle);
			}
			return undefined;
		});

		skipIds = new Set([...skipIds, ...additionalIdsToSkip]);

		let pointToSnapTo: Vector3 | null = null;
		if (settings.snapToObjects) {
			pointToSnapTo = this.findPointToSnapAmongObjects(snapNearPosition, skipIds, camera);
		}
		if (settings.snapToGridStep > 0 && pointToSnapTo == null) {
			pointToSnapTo = snapNearPosition.clone().roundTo(settings.snapToGridStep);
		}

		return pointToSnapTo;
	}

	findPointToSnapAmongObjects(snapNearPosition: Vector3, skipIds: Set<IdEngineObject>, camera: KrCamera): Vector3 | null {
		const cameraInvertMatrix = camera.mvpMatrix.clone().invert();
		const inCameraPosition = snapNearPosition.clone().applyMatrix4(camera.mvpMatrix);
		const inCameraDistance = new Vector3(0.02, 0.02, 0);
		const inCameraPositionMaxOut = inCameraDistance.add(inCameraPosition);
		const nearPosWorldSpace = inCameraPositionMaxOut.clone().applyMatrix4(cameraInvertMatrix);
		const snappingMaxDistance = nearPosWorldSpace.distanceTo(snapNearPosition);
		
		let mindEdgeDistanceYet: number = Infinity;
		let minEdgePointYet: Vector3 | null = null;
		let minVertexDistanceYet: number = Infinity;
		let minVertexPointYet: Vector3 | null = null;
		for (const [aabbDistance, submesh, worldMatrix] of this._getSubmeshesToUseForSnapping(
			snapNearPosition, skipIds, snappingMaxDistance
		)) {
			if (aabbDistance > Math.max(mindEdgeDistanceYet, mindEdgeDistanceYet)) {
				break;
			}
			const p = this.findSnappingPointsForWithSubmesh(snapNearPosition, submesh, worldMatrix);
			if (p) {
				const { edgePoint, vertexPoint } = p;
				const edgeDistance = edgePoint.distanceTo(snapNearPosition);

				if (edgeDistance < mindEdgeDistanceYet && edgeDistance < snappingMaxDistance) {
					mindEdgeDistanceYet = edgeDistance;
					minEdgePointYet = edgePoint;
				}

				const vertexDistance = vertexPoint.distanceTo(snapNearPosition);
				if (vertexDistance < minVertexDistanceYet && vertexDistance < snappingMaxDistance) {
					minVertexDistanceYet = vertexDistance;
					minVertexPointYet = vertexPoint;
				}
			}
		}
		if (minVertexDistanceYet < mindEdgeDistanceYet * 2) {
			return minVertexPointYet;
		}
		return minEdgePointYet;
	}

	
	findSnappingPointsForWithSubmesh(point: Vector3, submesh: EngineSubmeshDescription, worldMatrix: Matrix4): { edgePoint: Vector3, vertexPoint: Vector3 } | null {
		const geo = submesh.geometry;

		let minEdgeDistanceYet: number = Infinity;
		const minEdgePointYet = Vector3.zero();
		
		let minVertexDistYet: number = Infinity;
		const minVertexYet = Vector3.zero();

		const reusedOut = Vector3.zero();
		
		// reusedMatrix4.getInverse(worldMatrix);
		point = point.clone();
		// point.applyMatrix4(reusedMatrix4);

		for (let [p1, p2] of geo.snappingEdges()){
			p1 = p1.clone().applyMatrix4(worldMatrix);
			p2 = p2.clone().applyMatrix4(worldMatrix);

			point.nearesPointOnLine(p1, p2, reusedOut);

			const edgeDistance = reusedOut.distanceTo(point);
			if (edgeDistance < minEdgeDistanceYet) {
				minEdgeDistanceYet = edgeDistance;
				minEdgePointYet.copy(reusedOut);
			}

			const d1 = p1.distanceTo(point);
			if (d1 < minVertexDistYet) {
				minVertexDistYet = d1;
				minVertexYet.copy(p1);
			}
			const d2 = p2.distanceTo(point);
			if (d2 < minVertexDistYet) {
				minVertexDistYet = d2;
				minVertexYet.copy(p2);
			}
		}
		if (minEdgeDistanceYet < Infinity) {
			return { edgePoint: minEdgePointYet, vertexPoint: minVertexYet };
		}
		return null;
	}

	intersectWithFrustum(handles: (SubmeshHandle | index)[], frustum: KrFrustum/*, clippingBox: Aabb*/): IntersectionType[] {
		const result: IntersectionType[] = [];
		
		const inverseMatrix = new Matrix4();
		const geometrySpaceFrusutm = frustum.clone();
		
		// const geometrySpaceClipboxFrusutm = new KrFrustum();
		// geometrySpaceClipboxFrusutm.setFromBox(clippingBox);

		// const frustumAndBoxPlanes: Plane[] = geometrySpaceFrusutm.planes.concat(geometrySpaceClipboxFrusutm.planes);
		// const clipboxPositions = this.scene.clipboxPositions();

		for (const h of handles) {
			let index = h & HandleIndexMask;

			const geometry = this.submeshes.geometries[index];
			if (!geometry) {
				continue;
			}
			// LegacyLogger.assert(this.isSelectable(h), 'frustum intersecting selectable submesh');

			let intersection: IntersectionType | null = null;

			this.submeshesRawDataBlocks.forEachInstance(index, inverseMatrix, (instanceMatrix: Matrix4) => {
				instanceMatrix.invert();
				geometrySpaceFrusutm.copy(frustum);
				geometrySpaceFrusutm.applyMatrixToPlanes(instanceMatrix);
					
				let planesToCheck = geometrySpaceFrusutm.planes;
				const instanceIntersection = geometry.intersectPlanes(planesToCheck);

				if(intersection === null) {
					intersection = instanceIntersection;
				} else if (instanceIntersection !== intersection && (instanceIntersection === IntersectionType.Full || intersection === IntersectionType.Full)) {
					intersection = IntersectionType.Partial;
				} else {
					intersection |= instanceIntersection;
				}
			});

			result.push(intersection!);
		}
		return result;
	}
	
	findIntersectingFrustum<ParentId>(
		fm: Matrix4,
		near: number,
		far: number,
		strictInside: boolean,
		filter: (s: EngineSubmeshDescription) => boolean,
		parentIdAcessor: (h: SubmeshHandle | index) => ParentId,
		submeshesOfAcessor: (parentId: ParentId) => Iterable<SubmeshHandle>,
	): ParentId[] {
		// const clipbox = this.clipBox.getBounds();
		
		const submeshes = this.submeshes;
		let { inside, partial: assumedPartialInds } = this.submeshes.scene.intersectFrustum(fm, near, far);
		
		for (const sInd of Array.from(inside)) {
			const submesh = submeshes.submeshesDescriptions[sInd];
			if (!(submesh && this.isSelectable(sInd) && filter(submesh))) {
				inside.delete(sInd);
			}
		}
		const partial = assumedPartialInds.filter(sInd => {
			const submesh = submeshes.submeshesDescriptions[sInd];
			if (!(submesh && this.isSelectable(sInd) && filter(submesh))) {
				return false;
			}
			return true;
		});

		const frustum = new KrFrustum().setFromMatrix(fm);
		const partialIndsIntersections = this.intersectWithFrustum(partial, frustum/*, clipbox*/);
		const partialInds: number[] = [];

		for (let i = 0; i < partialIndsIntersections.length; ++i) {
			const int = partialIndsIntersections[i];
			if (int === IntersectionType.Full) {
				inside.add(partial[i]);
			} else if (int === IntersectionType.Partial) {
				partialInds.push(partial[i]);
			}
		}

		const resultHandles = new Set<ParentId>();
		if (strictInside) {
			for (const sHandle of inside) {
				const parentId = parentIdAcessor(sHandle)!;
				let allSubmeshesInside = true;
				for (const sh of submeshesOfAcessor(parentId)) {
					if (!inside.has(sh & HandleIndexMask) && this.isSelectable(sh)) {
						allSubmeshesInside = false;
						break;
					}
				}
				if (allSubmeshesInside) {
					resultHandles.add(parentId);
				}
			}
		} else {
			for (const sInd of inside) {
				const parentId = parentIdAcessor(sInd)!;
				resultHandles.add(parentId);
			}
			for (const sInd of partialInds) {
				const parentId = parentIdAcessor(sInd)!;
				resultHandles.add(parentId);
			}
		}

		return Array.from(resultHandles);
	}
}

const reusedMatrix4: Matrix4 = new Matrix4();

// export class BoundsConeCastResult {

// 	constructor(
// 		readonly rawResult: Float64Array,
// 	) {
// 	}

// 	iterate(fn: )

// }

