<script lang="ts">
    import Icon from "./NavbarIcon.svelte";
    import SimpleTooltip from "../libui/tooltip/SimpleTooltip.svelte";
    import { IconName } from "../libui/icon";

    export let id: string;
    export let name: string;
    export let iconName: IconName;
    export let collapsed: boolean;
    export let active: boolean = false;
    export let secondary: boolean = false;
    export let compact: boolean = false;
    export let onClick: (isActive: boolean) => void;

    $: isSelected = active || secondary;
</script>

<SimpleTooltip {id} text={name} show={collapsed}>
    <div
        class="nav-button mdc-typography--nav-l1"
        class:mdc-theme--primary={active}
        class:text-main-dark={secondary}
        class:collapsed
        class:selected={isSelected}
        class:active
        class:compact
        class:mdc-typography--nav-l2={compact}
        on:click={() => onClick(!isSelected)}
    >
        <Icon name={iconName} fill={isSelected} />
        <span class="nav-label">{name}</span>
    </div>
</SimpleTooltip>

<style lang="scss">
    .nav-button {
        padding: min(0.5vh, 6px) 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        position: relative;

        &.collapsed {
            .nav-label {
                display: none;
            }
        }
    }
    .selected:after {
        content: "";
        display: block;
        max-height: 34px;
        height: 100%;
        width: calc(100% - 6px);
        position: absolute;
        right: 0px;
        border-right: 2px solid var(--color-main-medium);
        border-radius: 2px 0 0 2px;
    }
    .active:after {
        background-color: var(--color-alt);
        border-right: 2px solid var(--mdc-theme-primary);
    }
    .compact {
        margin: 0;
        :global(svg) {
            margin: 0 2px;
        }
    }
    .nav-label {
        flex: 1;
    }
</style>
