import { ExecutionThreadPreference, IterUtils, JobExecutor, registerExecutor } from "engine-utils-ts";
import { PointsInPolygonChecker } from "math-ts";
import { Vector2 } from "math-ts";
import type { RegularHeightmapGeometry } from "src";

export class HeightmapFromTileExecutor extends JobExecutor<HeightmapFromTileJobArgs, HeightmapFromTileResponse> {
    execute(args: HeightmapFromTileJobArgs): HeightmapFromTileResponse {
        const checker = new PointsInPolygonChecker([args.polygon], args.holes);
        
        const heightmapWidth = (args.maxX - args.minX) / args.delta + 1;
        const heightmapHeight = (args.maxY - args.minY) / args.delta + 1;
        const heightmap: number[] = new Array(heightmapHeight * heightmapWidth);
        
        const point = new Vector2();
        let j = 0;
        for (let iy = args.minY; iy <= args.maxY; iy += args.delta) {
            for (let ix = args.minX; ix <= args.maxX; ix += args.delta) {
                point.set(ix, iy).multiplyScalar(args.segmentSize).add(args.tileOffset);
                if (!checker.isPointInside(point)) {
                    heightmap[j] = NaN;
                } else {
                    let elevationRelative = args.tileGeo.elevationsInCmRelative[iy * args.tilePointsSize + ix];
                    
                    if (elevationRelative === 0 && ix > args.minX) {
                        elevationRelative = args.tileGeo.elevationsInCmRelative[iy * args.tilePointsSize + ix - args.delta];
                    }

                    if (elevationRelative === 0 && ix < args.maxX) {
                        elevationRelative = args.tileGeo.elevationsInCmRelative[iy * args.tilePointsSize + ix + args.delta];
                    }

                    if (elevationRelative === 0 && iy > args.minY) {
                        elevationRelative = args.tileGeo.elevationsInCmRelative[(iy - args.delta) * args.tilePointsSize + ix];
                    }

                    if (elevationRelative === 0 && iy < args.maxY) {
                        elevationRelative = args.tileGeo.elevationsInCmRelative[(iy + args.delta) * args.tilePointsSize + ix];
                    }

                    if (elevationRelative > 0) {
                        heightmap[j] = args.tileGeo.elevationsBaseInCm + elevationRelative;
                    } else {
                        heightmap[j] = NaN;
                    }
                }
                ++j;
            }
        }

        return {
            heightmap, 
            minY: args.minY, maxY: args.maxY, 
            minX: args.minX, maxX: args.maxX, 
            tileOffset: args.tileOffset
        };
    }

    estimateTaskDurationMs(args: HeightmapFromTileJobArgs): number {
        const sumLength = args.polygon.length + IterUtils.sum(args.holes, h => h.length);
        return sumLength / 100;
    }

    executionPreference(args: HeightmapFromTileJobArgs): ExecutionThreadPreference {
        if (args.polygon.length < 5 && args.holes.length === 0) {
            return ExecutionThreadPreference.MainThread;
        } else {
            return ExecutionThreadPreference.WorkerThread;
        }
    }
}
registerExecutor(HeightmapFromTileExecutor);

export interface HeightmapFromTileJobArgs {
    polygon: Vector2[],
    holes: Vector2[][],
    tileGeo: RegularHeightmapGeometry,
    minY: number, maxY: number,
    minX: number, maxX: number,
    delta: number, segmentSize: number,
    tilePointsSize: number, tileOffset: Vector2,
}

export interface HeightmapFromTileResponse {
    heightmap: number[],
    minY: number, maxY: number,
    minX: number, maxX: number,
    tileOffset: Vector2,
}