import { DefaultMap, IterUtils, type RGBAHex, Yield, LazyBasic, type LazyVersioned, LazyDerived, LazyDerivedAsync, Success, type ResultAsync } from 'engine-utils-ts';
import type { IdPile, TrackerPilesCollection } from './TrackerPilesCollection';
import type { Bim } from '../Bim';
import type { Config } from '../bimConfigs/ConfigsCollection';
import { BinProps, TrackerBins, PileBinsConfig } from './PileBinsConfig';
import type { PileTypeSettings } from './DefaultTypeSettings';
import { type PropsGroupField, propsWithDifferentValueAt} from '../properties/Props';
import { AnyTrackerProps } from '../anyTracker/AnyTracker';
import { PileProfileType } from '../anyTracker/PileProfileType';
import type { SceneInstance } from '../scene/SceneInstances';
import type { TrackerPile } from './TrackerPile';
import { PileWeightClass, getPileFeaturesWithUndulation, PileUndulationType, type PileFeaturesFlags, getPileWeightClass, getPileFeaturesDefaultAbbreviatedName } from '../anyTracker/PilesFeatures';
import { FixedTiltProps } from '../archetypes/fixed-tilt/FixedTilt';
import { TrackerProps } from '../trackers/Tracker';


export class BinTableItem {
    private readonly bin: BinProps;
    readonly pileIds: IdPile[] = [];
    readonly name: string;
    readonly type: PileFeaturesFlags;
    profile: PileProfileType;
    length: number;
    profileInScene?: PileProfileType;
    lengthInScene: [number, number] = [0, 0];
    reveal: [number, number] = [0, 0];
    embedment: [number, number] = [0, 0];
    readonly color: RGBAHex;
    readonly enabled: boolean;

    constructor(
        bin: BinProps,
        type: PileFeaturesFlags,
        settings?: PileTypeSettings,
        profile?: PileProfileType,
        length?: number,
    ) {
        this.bin = bin;
        this.name = settings ? settings.shortName.value : getPileFeaturesDefaultAbbreviatedName(type);
        this.type = type;
        this.profile = profile ?? PileProfileType.None;
        this.length = length ?? 0;
        this.color = bin.getColor(this.weight).value;
        this.enabled = settings ? settings.enabled.value : true;
    }

    get weight() {
        return getPileWeightClass(this.type);
    }
    get sortKey() {
        return this.weight === PileWeightClass.Heavy ? 24 : 0;
    }
    static sortItems(a: BinTableItem, b: BinTableItem) {
        return a.sortKey - b.sortKey;
    }

    updatePileTypeProfile(profile: PileProfileType): BinProps {
        const index = this.bin.getIndexOfType(this.type);
        const typeDescriptions = this.bin.typeDescriptions.slice();
        typeDescriptions[index] = this.bin.typeDescriptions[index].withDifferentProfile(profile);
        return new BinProps({
            ...this.bin,
            typeDescriptions,
        });
    }

    updatePileTypeLength(length: number): BinProps {
        const index = this.bin.getIndexOfType(this.type);
        const typeDescriptions = this.bin.typeDescriptions.slice();
        typeDescriptions[index] = this.bin.typeDescriptions[index].withDifferentLength(length);
        return new BinProps({
            ...this.bin,
            typeDescriptions,
        });
    }
}

export class BinTable {
    private readonly bin: BinProps;
    pilesWithNonConformingLength: IdPile[] = [];
    pilesWithNonComformingProfile: IdPile[] = [];

    items: Map<PileFeaturesFlags, BinTableItem>;

    constructor(bin: BinProps, binsFromConfig: TrackerBins) {
        this.bin = bin;
        this.items = new Map<PileFeaturesFlags, BinTableItem>();
        bin.typeDescriptions.forEach(description => {
            const type = description.pileFeatures.value;
            const settings = binsFromConfig.getTypeByFeaturesKey(type);
            this.items.set(type, new BinTableItem(bin, type, settings, description.profile.value, description.length.value))
        });
    }

    get readonly() {
        return this.bin.maxReveal.value === Infinity;
    }

    get maxReveal() {
        return this.bin.maxReveal;
    }

    get total() {
        return IterUtils.sum(this.items.values(), (tableItem) => tableItem.pileIds.length);
    }

    getItemGroup(featureFlags: PileFeaturesFlags) {
        let typeGroup = this.items.get(featureFlags);
        if (!typeGroup) {
            typeGroup = new BinTableItem(this.bin, featureFlags);
            this.items.set(featureFlags, typeGroup)
        }
        return typeGroup;
    }

    addPile(id: IdPile, pile: TrackerPile) {
        const featureFlags = getPileFeaturesWithUndulation(pile.features, PileUndulationType.Undulated);
        let typeGroup = this.getItemGroup(featureFlags);
        if (!typeGroup.pileIds.length) {
            typeGroup.profileInScene = pile.shape;
        } else if (typeGroup.profileInScene !== pile.shape) {
            typeGroup.profileInScene = PileProfileType.None;
        }

        if (typeGroup.profile !== pile.shape) {
            this.pilesWithNonComformingProfile.push(id);
        }
        if (typeGroup.length !== pile.length) {
            this.pilesWithNonConformingLength.push(id);
        }

        typeGroup.pileIds.push(id);
    
        setMinMax(typeGroup.lengthInScene, pile.length);
        setMinMax(typeGroup.reveal, pile.reveal);
        setMinMax(typeGroup.embedment, pile.embedment);
    }

    getSortedItems() {
        return IterUtils.filterMap(this.items,
            ([_type, item]) => item.enabled || item.pileIds.length > 0 ? item: null
        ).sort(BinTableItem.sortItems);
    }

    equals(binTable: BinTable) {
        return this.bin.equals(binTable.bin);
    }

    updateReveal(newVal: number): BinProps {
        return this.bin.withDifferentReveal(newVal);
    }

    getColor(weight: PileWeightClass): RGBAHex {
        return this.bin.getColor(weight).value;
    }

    updateColor(newColor: RGBAHex, weight: PileWeightClass): BinProps {
        return this.bin.withDifferentColor(weight, newColor);
    }
}

export function getTrackerModel(instance: SceneInstance): string|undefined {
    let modelStr: string|undefined = undefined;
    if (instance.props instanceof AnyTrackerProps) {
        modelStr = instance.propsAs(AnyTrackerProps).tracker_frame.commercial.model.value;
    }
    else if (instance.props instanceof FixedTiltProps) {
        modelStr = instance.properties.get("commercial | model")?.asText();
    } else if (instance.props instanceof TrackerProps) {
        modelStr = instance.properties.get("tracker-frame | commercial | model")?.asText();
    }
    return modelStr;
}

export class BinsBuilder {
    public readonly binsPerTracker: DefaultMap<string, BinTable[]>;
    public readonly binsLazyConfig: LazyVersioned<Config>;

    public readonly trackerModels: LazyVersioned<string[]>;
    public readonly selectedTracker: LazyBasic<string | null>;
    public readonly selectedBins: LazyVersioned<BinTable[]>;

    constructor(
        private readonly bim: Bim,
        private readonly pilesCollection: TrackerPilesCollection,
    ) {
        this.binsPerTracker = new DefaultMap<string, BinTable[]>((name: string) => this.initBins(name));
        this.binsLazyConfig = bim.configs.getLazySingletonOf({
            type_identifier: PileBinsConfig.name,
        });
        this.selectedTracker = new LazyBasic("selected tracker", null);

        const pilesDataLazy = LazyDerivedAsync.new0<DefaultMap<string, BinTable[]>>(
            "piles-per-bins",
            [this.binsLazyConfig, this.pilesCollection],
            this.fillWithPiles.bind(this),
        );

        this.trackerModels = LazyDerived.new1<
            string[],
            ResultAsync<DefaultMap<string, BinTable[]>>
        >(
            "tracker-models-list",
            null,
            [pilesDataLazy],
            ([data], prevResult) => {
                if (data instanceof Success) {
                    const modelNames = Array.from(data.value.keys());
                    const selectedTracker = this.selectedTracker.poll();
                    if (!selectedTracker || !modelNames.includes(selectedTracker)) {
                        this.selectedTracker.replaceWith(modelNames[0]);
                    }
                    return modelNames;
                }
                return prevResult ?? [];
            },
        );

        this.selectedBins = LazyDerived.new2<BinTable[], ResultAsync<DefaultMap<string, BinTable[]>>, string|null>(
            "selected-bins",
            null,
            [pilesDataLazy, this.selectedTracker],
            ([binsResult, selectedTracker], prevResult) => {
                if (selectedTracker && binsResult instanceof Success) {
                    return binsResult.value.get(selectedTracker) ?? [];
                }
                return prevResult ?? [];
            }
        );
    }

    private initBins(trackerModelName: string): BinTable[] {
        const binsFromConfig = getOrInitTrackerBins(trackerModelName, this.binsLazyConfig.poll(), this.bim);
    
        return binsFromConfig.bins.map((bin) => new BinTable(bin, binsFromConfig));
    }

    *fillWithPiles() {
        this.binsPerTracker.clear();
        const piles = this.pilesCollection.poll();
        for (const chunk of IterUtils.splitIterIntoChunks(piles, 10e3)) {
            for (const [id, pile] of chunk) {
                const bin = this.findBinForPile(pile);
                if (bin) {
                    bin.addPile(id, pile);
                }
            }
            yield Yield.Asap;
        }
        return this.binsPerTracker;
    }

    private findBinForPile(pile: TrackerPile): BinTable | undefined {
        const tracker = this.bim.instances.peekById(pile.parentId);
        if (tracker) {
            const model = getTrackerModel(tracker);
            if (model) {
                const bins = this.binsPerTracker.getOrCreate(model);
                for (let index = 0; index < bins.length; index++) {
                    if (pile.reveal > bins[index].maxReveal.as('m')) {
                        continue;
                    }
                    return bins[index];
                }
            }
        }
        return;
    }

    private getBinIndex(bin: BinTable) {
        const selectedBins = this.selectedBins.poll();
        return selectedBins.findIndex(sb => sb.equals(bin));
    }

    selectTracker(name: string) { 
        const allTrackers = this.trackerModels.poll();
        if (allTrackers.includes(name)) {
            this.selectedTracker.replaceWith(name);
        }
    }

    getSelectedBinsConfig(): TrackerBins | undefined {
        const selectedTracker = this.selectedTracker.poll();
        const binsConfigProps = this.binsLazyConfig.poll().propsAs(PileBinsConfig);
        return selectedTracker ? binsConfigProps.getBinsByTracker(selectedTracker) : undefined;
    }

    addBin() {
        const selectedTrackerBins = this.getSelectedBinsConfig();
        if (selectedTrackerBins) {
            const newBinMaxReveal = selectedTrackerBins.newBinReveal.as('m');
            const outliersBin = this.selectedBins.poll().at(-1)!;
            let totalEmbedment = 0;
            let count = 0;
            for (const [_, it] of outliersBin.items) {
                for (const id of it.pileIds) {
                    const pile = this.pilesCollection.peekById(id);
                    if (pile && pile.reveal <= newBinMaxReveal) {
                        count += 1;
                        totalEmbedment += pile.embedment;
                    }
                }
            }
            const averageEmbedment = count ? Math.round(totalEmbedment/count * 100) / 100 : 0;
 
            const updatedBins = selectedTrackerBins.bins.slice();
            updatedBins.push(selectedTrackerBins.createNewBin(averageEmbedment));
            this.saveChangesToConfig(selectedTrackerBins.withSortedBins(updatedBins));
        }
    }

    updateBin(originalBinTable: BinTable, bin: BinProps, sortAllBins: boolean = false) {
        const index = this.getBinIndex(originalBinTable);
        if (sortAllBins) {
            const selectedTrackerBins = this.getSelectedBinsConfig();
            if (selectedTrackerBins) {
                const updatedBins = selectedTrackerBins.bins.slice();
                updatedBins[index] = bin;
                this.saveChangesToConfig(selectedTrackerBins.withSortedBins(updatedBins));
            }
        } else {
            this.saveChangesToConfig(bin, ["bins", index]);
        }
    }

    removeBin(bin: BinTable) {
        const index = this.getBinIndex(bin);
        const selectedTrackerBins = this.getSelectedBinsConfig();
        if (selectedTrackerBins) {
            const updatedBins = selectedTrackerBins.bins.slice();
            updatedBins.splice(index, 1);
            this.saveChangesToConfig(selectedTrackerBins.withSortedBins(updatedBins));
        }
    }

    saveChangesToConfig(newProps: PropsGroupField, path: (string|number)[] = []) {
        const selectedTrackerBins = this.getSelectedBinsConfig();
        if (selectedTrackerBins) {
            const binsConfigProps = this.binsLazyConfig.poll().propsAs(PileBinsConfig);
            const trackerIndex = binsConfigProps.trackerBins.indexOf(selectedTrackerBins);
            const patched = propsWithDifferentValueAt(binsConfigProps, newProps, ["trackerBins", trackerIndex, ...path]);
            if (patched) {
                this.bim.configs.applyPatchToSingleton(
                    PileBinsConfig.name,
                    {
                        props: patched
                    }
                );
            }
        }
    }
}

function setMinMax(minMaxRange: [number, number], value: number) {
    if (!minMaxRange[0] && !minMaxRange[1]) {
        minMaxRange[0] = minMaxRange[1] = value;
    } else {
        if (value < minMaxRange[0]) {
            minMaxRange[0] = value;
        } else if (value > minMaxRange[1]) {
            minMaxRange[1] = value;
        }
    }
}

export function getOrInitTrackerBins(trackerModelName: string, binsConfig: Config, bim: Bim) {
    const configProps = binsConfig.propsAs(PileBinsConfig);
    let binsFromConfig = configProps.getBinsByTracker(trackerModelName);
    if (!binsFromConfig) {
        binsFromConfig = TrackerBins.createDefaultTrackerBins(trackerModelName);
        const patched = propsWithDifferentValueAt(configProps, [...configProps.trackerBins, binsFromConfig], ["trackerBins"]);
        if (patched) {
            bim.configs.applyPatchToSingleton(
                PileBinsConfig.name,
                {
                    props: patched
                }
            );
        }
    }

    return binsFromConfig;
}