<script lang="ts">
  import type { Bim } from "bim-ts";
  import { getContext, onDestroy, onMount } from "svelte";
  export const header: string = "Temp Scheme Tool"
  export const status: string = ""

  let bim: Bim = getContext('bim')
  let iframe: HTMLIFrameElement

  let tempSchemeUrl = 'http://' + window.location.hostname + ':3003';

  function sendBimInfo() {
    iframe?.contentWindow?.postMessage({
      hierarchy: bim.instances.spatialHierarchy,
      objects: bim.instances.perId
    }, "*")
  }
  let sub = bim.instances.updatesStream.subscribe({ onNext: () => {
    sendBimInfo()
  }})
  onMount(() => {
    iframe?.addEventListener("load", () => {
      if (bim.instances.perId.size)
        sendBimInfo()
    })
  })
  onDestroy(() => {
    sub.dispose()
  })
</script>
<div class="container">
  <iframe bind:this={iframe} src="{tempSchemeUrl}" title="description"></iframe>
</div>
<style>
  .container {
    background: white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  iframe {
    height: 100%;
    width: 100%;
    border: 0;
  }
</style>
