<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M5.77395 13.3259L5.77393 13.3259L1.87395 9.44261C1.87395 9.44261 1.87395 9.4426 1.87394 9.4426C1.78584 9.3545 1.71712 9.25824 1.66767 9.15385C1.61836 9.04975 1.59367 8.9374 1.59367 8.81664C1.59367 8.69589 1.61836 8.57783 1.66783 8.46241C1.71731 8.34695 1.78601 8.24528 1.87395 8.15734L5.55728 4.47401L5.56465 4.46664L5.55728 4.45928L3.54798 2.44998L4.38325 1.61471L10.9259 8.15734C11.0138 8.24528 11.0797 8.34411 11.1236 8.45384C11.1675 8.5637 11.1895 8.67905 11.1895 8.79998C11.1895 8.9209 11.1675 9.03625 11.1236 9.14611C11.0797 9.25584 11.0138 9.35467 10.9259 9.44261L7.04255 13.3259C6.95453 13.414 6.85557 13.4827 6.74564 13.5321C6.63602 13.5815 6.52097 13.6062 6.40039 13.6062C6.27934 13.5952 6.16666 13.5677 6.06229 13.5237C5.9581 13.4798 5.86198 13.414 5.77395 13.3259ZM6.40728 5.30928L6.39992 5.30191L6.39255 5.30928L2.90922 8.79261L2.89144 8.81039H2.91659H9.88325H9.9084L9.89062 8.79261L6.40728 5.30928ZM12.9499 13.5896C12.5414 13.5896 12.1969 13.4517 11.9155 13.1759C11.6343 12.9001 11.4937 12.5584 11.4937 12.15C11.4937 11.885 11.5544 11.6282 11.6759 11.3796C11.7979 11.1302 11.9419 10.8947 12.1082 10.673C12.1082 10.673 12.1082 10.6729 12.1083 10.6729L12.9498 9.61679L13.7747 10.6726C13.7748 10.6727 13.7748 10.6728 13.7749 10.6728C13.9301 10.8946 14.0715 11.1302 14.199 11.3797C14.326 11.6283 14.3895 11.885 14.3895 12.15C14.3895 12.5584 14.2489 12.9001 13.9676 13.1759C13.6863 13.4517 13.3472 13.5896 12.9499 13.5896Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="0.0208333"
    />
</svg>
