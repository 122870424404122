<script lang="ts" generics="T">
    import SegmentedButton from "./SegmentedButton.svelte";

    export let options: T[] = [];
    export let selected: T;
</script>

<div class="segmented-buttons-container bg-alt text-main-dark">
    <div class="buttons-group">
        {#each options as option (option)}
            <SegmentedButton {option} active={option === selected} on:change>
                <slot name="button" {option}>{option}</slot>
            </SegmentedButton>
        {/each}
        <slot name="additional-buttons"></slot>
    </div>
</div>

<style lang="scss">
    .segmented-buttons-container {
        width: 100%;
        border-radius: 1px;
        height: 26px;
        display: flex;
        gap: 4px;
        padding: 0 2px;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        box-sizing: border-box;
    }
    .buttons-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2px;
        width: 100%;
    }
</style>
