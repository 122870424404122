import type { Bim, Catalog, IdBimScene, TrenchesConfig } from 'bim-ts';
import { GaugePack, TrenchesConfigType } from 'bim-ts';
import type {
    ScopedLogger, TasksRunner
} from 'engine-utils-ts';
import {
    LazyBasic,
    LazyDerived, ObjectUtils
} from 'engine-utils-ts';
import { addProperty } from 'src/panels-config-ui/PropertyBuilders';
import type { PUI_Builder, PUI_Node, UiBindings } from 'ui-bindings';
import { PUI_ConfigBasedBuilderParams, PanelViewPosition } from 'ui-bindings';
import type { VerDataSyncer } from 'verdata-ts';
import type { Context, GlobalContext } from '../panels-config-ui/GeneratePanelUiBindings';
import { createLazyUiConfig } from '../panels-config-ui/GeneratePanelUiBindings';
import type { CableDef, TrenchConfig } from './TrenchService';
import { deleteTrenchesByParents, placeTrenchesOnLayout } from './TrenchService';

export function createTrenchesUi(
    ui: UiBindings,
    configBuilderParams: PUI_ConfigBasedBuilderParams,
    tasksRunner: TasksRunner,
    logger: ScopedLogger,
    bim: Bim,
    cablesCollection: [number, CableDef][],
    catalog: Catalog,
    vds: VerDataSyncer,
) {
    const type = TrenchesConfigType;

    const isGeneratingLazy = new LazyBasic<boolean>('is-generating', false);
    const generateFn = async (context:Context) => {
        const gaugePack = new GaugePack(catalog, ['mv-wire-spec', 'lv-wire-spec'])
        const selectedConfig = bim.configs.peekById(context.propertyId);
        //logger.info('run generate: configid', context.propertyId);

        if (selectedConfig) {
            const props = selectedConfig.get<TrenchesConfig>();

            const input:TrenchConfig = {
                substationIds: [selectedConfig.connectedTo as IdBimScene],
                mvDepthInch: props.mv.min_depth.as('inch'),
                mvSpaceBetweenWiresInch: props.mv.space.as('inch'),
                dcDepthInch: props.dc_feeder.min_depth.as('inch'),
                dcSpaceBetweenWiresInch: props.dc_feeder.space.as('inch'),
                acDepthInch: props.ac_feeder.min_depth.as('inch'),
                acSpaceBetweenWiresInch: props.ac_feeder.space.as('inch'),
                whipDepthInch: props.whip.min_depth.as('inch'),
                whipSpaceBetweenWiresInch: props.whip.space.as('inch'),
                lowVoltageCables: cablesCollection
            };
            //logger.batchedInfo("trench input", input);
            isGeneratingLazy.replaceWith(true);
            await placeTrenchesOnLayout(
                ObjectUtils.deepCloneObj(input),
                bim,
                tasksRunner,
                ui,
                gaugePack,
            ).finally(()=>{
                isGeneratingLazy.replaceWith(false);
            });
        }
    };
    function isGenerationAvailable(context:Context, ownedContext:GlobalContext){
        return LazyDerived.new1("trench-poller", [ownedContext.configObserver], [isGeneratingLazy],  ([isGenerating]) => {
            const selectedConfig = bim.configs.peekById(context.propertyId);
            //logger.info('isGenerationAvailable: configid', context.propertyId);
            return !isGenerating && !!selectedConfig?.connectedTo;
        });
    }

    function puiCallback(builder: PUI_Builder, _root: PUI_Node, context: Context, ownedContext:GlobalContext){
        if(ObjectUtils.isObjectEmpty(context.previousConfig)){
            return;
        }
        const config = bim.configs.peekById(context.propertyId)?.get<TrenchesConfig>();
        if(!config){
            return;
        }
        function patchConfig(config: TrenchesConfig){
            bim.configs.applyPatchTo({properties: config}, [context.propertyId]);
        }

        let sorKey = 20;
        const addProp = (path: string[], name?: string) => {
            addProperty({logger, config, patch: patchConfig, builder, path, sortKey: ++sorKey, name });
        };
        const addGroup = (name: string, inGroup: () => void) => { 
            builder.inGroup({
                name, 
                collapsible: false, 
                typeSortKeyOverride: ++sorKey 
            }, 
            () => {
                inGroup();
            });
        }

        addGroup("AC Feeder", () =>{
            addProp(["ac_feeder", "space"], "Space between wires");
            addProp(["ac_feeder", "min_depth"], "Min trench depth");
        });

        addGroup("DC Feeder", () =>{
            addProp(["dc_feeder", "space"], "Space between wires");
            addProp(["dc_feeder", "min_depth"], "Min trench depth");
        });

        addGroup("MV", () => {
            addProp(["mv", "space"], "Space between wires");
            addProp(["mv", "min_depth"], "Min trench depth");
        });

        addGroup("Whip", () =>{
            addProp(["whip", "space"], "Space between wires");
            addProp(["whip", "min_depth"], "Min trench depth");
        });

        builder.addActionsNode({
            name: "removeTrenchesAction",
            context: undefined,
            typeSortKeyOverride: 998,
            actions: [
                {
                    label: "remove trenches",
                    action: () => {
                        const config = bim.configs.peekById(context.propertyId);
                        if(config){
                            deleteTrenchesByParents([config.connectedTo], new Set(), bim);
                        }
                    },
                    style: {
                        type: 'outlined'
                    },
                }
            ]
        });

        builder.addActionsNode({
            name: 'generate',
            context: undefined,
            typeSortKeyOverride: 999,
            actions: [
                {
                    label: "generate",
                    hint: "click for generate",
                    isEnabled: isGenerationAvailable(context, ownedContext),
                    action:()=>{generateFn(context)},
                    style: {
                        type: 'primary'
                    },
                }
            ]
        })
    };

    const builderParams = PUI_ConfigBasedBuilderParams.new(
        [],
        [ "mv", "dc_feeder", "ac_feeder", "whip"]
    ).mergedWith(configBuilderParams);

    ui.addViewToNavbar(
        ["Generate", "Trenches"],
        createLazyUiConfig({configBuilderParams: builderParams, onAfterRootNodeCallback: puiCallback, bim, logger, type, ui, tasksRunner, vds, catalog }),
        {
            name: 'Trenches',
            iconName: 'Trenches',
            group: 'General',
            sortOrder: 5,
            position: PanelViewPosition.Fixed
        }
    );
}

