<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path d="M14 8.5H12.5V3.49998L7.5 3.5V2H14V8.5Z" fill="currentColor" />
    <path
        d="M2.00001 7.49998H3.5V12.5L8.50001 12.5V14H2.00001V7.49998Z"
        fill="currentColor"
    />
</svg>
