import { Aabb2, Vector2 } from "math-ts";
import { Object2D } from "./Object2D";

export class Anchors2D extends Object2D {
    points: Vector2[]
    constructor() {
        super()
        this.points = [];
    }

    static clone(
        recursive: boolean,
        source: Anchors2D,
        target?: Anchors2D
    ): Anchors2D {
        target = target ?? new Anchors2D();
        target.points = source.points.map(x => x.clone())
        Object2D.clone(recursive, source, target);
        return target;
    }

    clone(recursive = false) {
        return Anchors2D.clone(recursive, this)
    }

    static stackAnchorsAndMerge(
        anchors: Array<AnyAnchor2D>,
        params: { yOffset: number }
    ) {
        let yMax = 0;
        for (const anchor of anchors) {
            if (anchor instanceof Anchor2D) {
                anchor.position.x -= anchor.point.x
            } else {
                const pointsAabb = aabbReused1
                    .setFromPoints(anchor.points)
                    .applyMatrix3(anchor.matrix);
                anchor.position.x -= pointsAabb.centerX();
            }

            const anchorAabb = aabbReused1
                .copy(anchor.aabb)
                .applyMatrix3(anchor.matrix);
            anchor.position.y += yMax - anchorAabb.min.y;
            yMax += anchorAabb.height() + params.yOffset

            anchor.updateMatrix();
        }

        const result = new Anchors2D()
        for (const anchor of anchors) {
            result.addAndExpandAabb(anchor);
            if (anchor instanceof Anchor2D) {
                result.points.push(anchor.point.clone().applyMatrix3(anchor.matrix))
            } else {
                for (const point of anchor.points) {
                    result.points.push(point.clone().applyMatrix3(anchor.matrix))
                }
            }
        }
        return result;
    }

}

export class Anchor2D extends Object2D {
    point: Vector2;
    constructor() {
        super()
        this.point = new Vector2();
    }

    static clone(
        recursive: boolean,
        source: Anchor2D,
        target?: Anchor2D
    ): Anchor2D {
        target = target ?? new Anchor2D();
        target.point.copy(source.point);
        Object2D.clone(recursive, source, target);
        return target;
    }

    clone(recursive = false) {
        return Anchor2D.clone(recursive, this)
    }
}

export type AnyAnchor2D = Anchors2D | Anchor2D;

const aabbReused1 = Aabb2.empty();
