import type { CatalogItem, CatalogItemIdType, SceneInstance} from "bim-ts";
import { AnyTrackerProps, AssetCatalogItemTypeIdentifier, BimUtils, NumberProperty, PVModuleTypeIdent, TrackerFrameTypeIdentifier, type AssetCatalogItemProps, type Catalog } from "bim-ts";
import { DefaultMap, LazyDerived, type LazyVersioned } from "engine-utils-ts";


const notSpecifiedValueString = 'not specified';

export type ModuleVariation = {
    modulePower: NumberProperty
    moduleModel: string
    moduleManufacturer: string
    mountingStd: string
    moduleWidth: NumberProperty
    moduleLength: NumberProperty
}

export type FrameVariation = {
    frameSeries: string
    frameTotalLength: NumberProperty
    modulesTotal: number
    modulesInString: number
    mountingStd: string
}

export type FrameSeriesDescription = {

    frames: Set<CatalogItemIdType>;

    groupedByModulesInString: Map<number, Set<CatalogItemIdType>>
    groupedByModulesTotal: Map<number, Set<CatalogItemIdType>>

    modulesInStringVariations: Set<number>
    modulesTotalVariations: Set<number>

    modulesTotalPerModulesInString: Map<number, Set<number>>
    modulesInStringPerModulesTotal: Map<number, Set<number>>
}

export type ModuleFrameMatchingContext = {
    modules: Map<CatalogItemIdType, ModuleVariation>
    frames: Map<CatalogItemIdType, FrameVariation>
    modulesPerFrame: Map<CatalogItemIdType, CatalogItemIdType[]>
    frameSeriesDescriptions: Map<string, FrameSeriesDescription>
}

export type ArrayVariation = {
    trackerFrame: FrameVariation
    module: ModuleVariation
}

function createTypeIdentifierCatalogListLazy(
    typeIdentifier: string,
    catalog: Catalog
) {
    return LazyDerived.new1(
        'tracker-frames',
        [catalog.assets.sceneInstancePerAsset.assetIdToSceneInstanceId],
        [catalog.catalogItems.getLazyListOf(AssetCatalogItemTypeIdentifier)],
        ([catalogItems]) => {
            const trackerFrames: Array<[
                sceneInstance: SceneInstance,
                catalogItemId: CatalogItemIdType,
                catalogItem: CatalogItem<AssetCatalogItemProps>,
            ]> = []
            const assetIdToSceneInstanceId =
                catalog.assets.sceneInstancePerAsset.assetIdToSceneInstanceId.poll();
            const catalogBim = catalog.assets.sceneInstancePerAsset.bim;
            for (const [catalogItemId, catalogItem] of catalogItems) {
                const assetCatalogItem = catalogItem.as<AssetCatalogItemProps>();
                const assetId = assetCatalogItem.properties.asset_id.value;
                const siId = assetIdToSceneInstanceId.get(assetId);
                if (!siId) {
                    continue;
                }
                const si = catalogBim.instances.peekById(siId);
                if (!si) {
                    continue;
                }
                if (si.type_identifier !== typeIdentifier) {
                    continue;
                }
                trackerFrames.push([si, catalogItemId, assetCatalogItem])
            }
            return trackerFrames;
        }
    ).withoutEqCheck();
}

export function extractModuleVariation(si: SceneInstance) {
    const moduleModel = BimUtils.getByMergedPathAsBimProperty(si, 'module | model')?.asText() || notSpecifiedValueString;
    const mountingStd = BimUtils.getByMergedPathAsBimProperty(si, 'module | mounting | standard')?.asText() || notSpecifiedValueString;
    const power = BimUtils.getByMergedPathAsBimProperty(si, 'module | maximum_power');
    const width = BimUtils.getByMergedPathAsBimProperty(si, 'module | width');
    const length = BimUtils.getByMergedPathAsBimProperty(si, 'module | length');
    const moduleManufacturer = BimUtils.getByMergedPathAsBimProperty(si, 'module | manufacturer')?.asText() || notSpecifiedValueString;
    if (!power || !width || !length) {
        // skip module
        return null;
    }
    const moduleVariation: ModuleVariation = {
        modulePower: NumberProperty.new({
            value: power.asNumber(),
            unit: power.unit ?? '',
        }),
        moduleModel: moduleModel,
        mountingStd,
        moduleLength: NumberProperty.new({
            value: length.asNumber(),
            unit: length.unit ?? '',
        }),
        moduleWidth: NumberProperty.new({
            value: width.asNumber(),
            unit: width.unit ?? '',
        }),
        moduleManufacturer,

    }
    return moduleVariation;
}

export function extractTrackerFrameVariation(si: SceneInstance): FrameVariation | null {
    let series: string;
    let modulesInStringX: number;
    let modulesInStringY: number;
    let modulesInString: number;
    let stringCount: number;
    let totalLength: number;
    let mountingStd: string;
    if (si.props instanceof AnyTrackerProps) {
        series = si.props.tracker_frame.commercial.manufacturer.value;
        modulesInStringX = si.props.tracker_frame.string.modules_count_x.value;
        modulesInStringY = si.props.tracker_frame.string.modules_count_y.value;
        modulesInString = modulesInStringX * modulesInStringY;
        stringCount = si.props.tracker_frame.dimensions.strings_count.value;
        totalLength = si.props.tracker_frame.dimensions.length?.as('m') ?? 0;
        mountingStd = notSpecifiedValueString;
    } else {
        series = si.properties.get('tracker-frame | commercial | manufacturer')?.asText() || notSpecifiedValueString;
        modulesInStringX = si.properties.get('tracker-frame | string | modules_count_x')?.asNumber() ?? 0;
        modulesInStringY = si.properties.get('tracker-frame | string | modules_count_y')?.asNumber() ?? 0;
        modulesInString = modulesInStringX * modulesInStringY;
        stringCount = si.properties.get('tracker-frame | dimensions | strings_count')?.asNumber() ?? 0;
        totalLength = si.properties.get('tracker-frame | dimensions | length')?.as('m') ?? 0;
        mountingStd = si.properties.get('tracker-frame | module_mounting | standard')?.asText() || notSpecifiedValueString;
    }

    if (!modulesInString || !stringCount || !totalLength) {
        // skip frame
        return null;
    }
    const frameVariation: FrameVariation = {
        frameSeries: series,
        frameTotalLength: NumberProperty.new({
            value: totalLength,
            unit: 'm',
        }),
        modulesInString,
        modulesTotal: modulesInString * stringCount,
        mountingStd,
    }
    return frameVariation
}

export function extractArrayVaration(si: SceneInstance): ArrayVariation | null {
    const moduleVariation = extractModuleVariation(si);
    const frameVariation = extractTrackerFrameVariation(si);
    if (!moduleVariation || !frameVariation) {
        return null;
    }
    return {
        module: moduleVariation,
        trackerFrame: frameVariation,
    }
}



export function createTrackerFrameAndModuleVariations(catalog: Catalog)
    : LazyVersioned<ModuleFrameMatchingContext>
{
    return LazyDerived.new2(
        'trackerFrameAndModuleVariations',
        [],
        [
            createTypeIdentifierCatalogListLazy(PVModuleTypeIdent, catalog),
            createTypeIdentifierCatalogListLazy(TrackerFrameTypeIdentifier, catalog),
        ],
        ([modules, frames]): ModuleFrameMatchingContext => {

            // group module variations by mounting standard
            const moduleVariations = new Map<CatalogItemIdType, ModuleVariation>();
            const modulesByMountingStd = new DefaultMap<string, CatalogItemIdType[]>(() => []);
            for (const [si, catalogItemId] of modules) {
                const variation = extractModuleVariation(si);
                if (!variation) {
                    continue;
                }
                modulesByMountingStd.getOrCreate(variation.mountingStd).push(catalogItemId);
                moduleVariations.set(catalogItemId, variation);
            }

            // make variations for all tracker frames
            const trackerFrameVariations = new Map<CatalogItemIdType, FrameVariation>();
            const modulesPerTrackerFrame = new Map<CatalogItemIdType, CatalogItemIdType[]>();
            const framesPerFrameSeries = new DefaultMap<string, CatalogItemIdType[]>(() => []);
            for (const [si, catalogItemId] of frames) {
                const variation = extractTrackerFrameVariation(si);

                if (!variation) {
                    continue;
                }

                const matchingModules = modulesByMountingStd.get(variation.mountingStd) ?? []

                modulesPerTrackerFrame.set(catalogItemId, matchingModules);
                trackerFrameVariations.set(catalogItemId, variation);

                const frameSeries = variation.frameSeries;
                framesPerFrameSeries.getOrCreate(frameSeries).push(catalogItemId);
            }

            // fill frame series description
            const groupedByModulesInString = new DefaultMap<number, Set<CatalogItemIdType>>(() => new Set());
            const groupedByModulesTotal = new DefaultMap<number, Set<CatalogItemIdType>>(() => new Set());
            const seriesDescription = new Map<string, FrameSeriesDescription>();
            const modulesInStringPerModulesTotal = new DefaultMap<number, Set<number>>(() => new Set());
            const modulesTotalPerModulesInString = new DefaultMap<number, Set<number>>(() => new Set());
            for (const [series, frames] of framesPerFrameSeries) {
                groupedByModulesInString.clear();
                groupedByModulesTotal.clear();
                modulesInStringPerModulesTotal.clear();
                modulesTotalPerModulesInString.clear();
                for (const id of frames) {
                    const variation = trackerFrameVariations.get(id)
                    if (!variation) {
                        continue;
                    }
                    groupedByModulesInString.getOrCreate(variation.modulesInString).add(id)
                    groupedByModulesTotal.getOrCreate(variation.modulesTotal).add(id)
                }
                for (const [modulesInString, frames] of groupedByModulesInString) {
                    for (const frame of frames) {
                        const modulesTotal = trackerFrameVariations.get(frame)?.modulesTotal;
                        if (!modulesTotal) {
                            continue;
                        }
                        modulesTotalPerModulesInString
                            .getOrCreate(modulesInString)
                            .add(modulesTotal);
                    }
                }
                for (const [modulesTotal, frames] of groupedByModulesTotal) {
                    for (const frame of frames) {
                        const modulesInString = trackerFrameVariations.get(frame)?.modulesInString;
                        if (!modulesInString) {
                            continue;
                        }
                        modulesInStringPerModulesTotal
                            .getOrCreate(modulesTotal)
                            .add(modulesInString);
                    }
                }
                seriesDescription.set(
                    series,
                    {
                        frames: new Set(frames),

                        groupedByModulesInString: new Map(groupedByModulesInString.entries()),
                        groupedByModulesTotal: new Map(groupedByModulesTotal.entries()),

                        modulesInStringVariations: new Set(groupedByModulesInString.keys()),
                        modulesTotalVariations: new Set(groupedByModulesTotal.keys()),

                        modulesInStringPerModulesTotal: new Map(modulesInStringPerModulesTotal.entries()),
                        modulesTotalPerModulesInString: new Map(modulesTotalPerModulesInString.entries())
                    }
                )
            }

            return {
                frames: trackerFrameVariations,
                modules: moduleVariations,
                modulesPerFrame: modulesPerTrackerFrame,
                frameSeriesDescriptions: seriesDescription,
            }
        }
    ).withoutEqCheck();
}
