import type { Config} from "..";
import { FixedTiltTypeIdent, TrackerTypeIdent, type Bim, type FarmLayoutConfig, type RepresentationBase, type SceneInstance, type IdBimScene, BimProperty, NumberProperty, type IdConfig, TerrainInstanceTypeIdent, AnyTrackerProps } from "..";
import { BasicAnalyticalRepresentation, ExtrudedPolygonGeometry } from "..";
import type { ScopedLogger} from 'engine-utils-ts';
import { DefaultMap, Failure, IterUtils} from 'engine-utils-ts';
import { convertThrow} from 'engine-utils-ts';
import { type Result, Yield, Success } from 'engine-utils-ts';
import type { Aabb, Vector2 } from "math-ts";
import { Aabb2, Clipper, PolygonUtils } from "math-ts";

export class LayoutMetricsUtils {
    constructor(public bim: Bim, public logger: ScopedLogger) {}

    *calculateFootprintArea(substations: IdBimScene[]): Generator<Yield, NumberProperty> {
        yield Yield.Asap;
        const goemetriesAabbs = this.bim.allBimGeometries.aabbs.poll(); 
        const reprsBboxes = new DefaultMap<RepresentationBase, Aabb>(r => r.aabb(goemetriesAabbs));
        const instancesIds = this.bim.instances.spatialHierarchy.gatherIdsWithSubtreesOf({ids: substations});
        const instances = this.bim.instances.peekByIds(instancesIds);

        const instancesBoundaries: Vector2[][] = [];
        let counter = 0;
        for (const [_, inst] of instances) {
            if (!inst.representation) {
                continue;
            }
            const reprAabb = reprsBboxes.getOrCreate(inst.representation);
            if (reprAabb.isEmpty()) {
                continue;
            }
            
            const bbox = reprAabb.get2DCornersAtZ(0);

            const bbox2d: Vector2[] = [];
            for (const p of bbox) {
                p.applyMatrix4(inst.worldMatrix);
                bbox2d.push(p.xy());
            }
    
            instancesBoundaries.push(bbox2d);
            counter++;
            if (counter % 100 === 0) {
                yield Yield.Asap;
            }
        }
        yield Yield.Asap;

        let footprintArea: NumberProperty | undefined = undefined;
        if(instancesBoundaries.length > 0){
            const equipmentContours = Clipper.calculateBoundariesWithJoinTolerance2D(
                instancesBoundaries, 
                20, 
                0,
            );
            yield Yield.Asap;
            let areaM2 = 0;
            for (const contour of equipmentContours) {
                if(PolygonUtils.isClockwiseInner(contour)){
                    continue;
                }
                areaM2 += Math.abs(PolygonUtils.area(contour));
            }
            footprintArea =  NumberProperty.new({ value: convertThrow(areaM2, "m2", "ha"), unit: 'ha', isReadonly: true });
        } else {
            footprintArea = NumberProperty.new({ value: 0, unit: 'ha', isReadonly: true })
        }

        return footprintArea;
    }

    *calculateBuildableArea(boundaries: [IdBimScene, SceneInstance][]): Generator<Yield, {buildable_area: NumberProperty, site_area: NumberProperty}> {
        // boundaries
        yield Yield.Asap;

        const includeBoundaries: SceneInstance[] = []
        const excludeBoundaries: SceneInstance[] = []

        const boundaryPolygons = new WeakMap<SceneInstance, Vector2[]>();
        for (const [boundaryId, boundary] of boundaries) {
            const sourceType = boundary.properties.get('boundary | source_type')?.asText();
            if (sourceType === 'equipment') {
                continue;
            }
            if (!(boundary.representationAnalytical instanceof BasicAnalyticalRepresentation)) {
                continue;
            }
            const geo = this.bim.allBimGeometries.peekById(boundary.representationAnalytical.geometryId);
            if (!(geo instanceof ExtrudedPolygonGeometry)) continue;
            const pointsWs2D = geo.outerShell.points.map(p => p.clone());
            if(pointsWs2D.length < 3) {
                this.logger.batchedWarn('Invalid boundaries', [boundaryId, boundary.representationAnalytical.geometryId, geo]);
                continue;
            }
            const type = boundary.properties.get('boundary | boundary_type')?.asText();
            if (type === 'exclude') {
                excludeBoundaries.push(boundary);
            } else if (type === 'include') {
                includeBoundaries.push(boundary);
            }

            if (!boundary.worldMatrix.isIdentity()) {
                pointsWs2D.forEach(p => p.applyMatrix4(boundary.worldMatrix));
            }
            const isClockwise = PolygonUtils.isClockwiseInner(pointsWs2D);
            if ((type === 'exclude' && !isClockwise) || (type === 'include' && isClockwise)) {
                pointsWs2D.reverse();
            }
            boundaryPolygons.set(boundary, pointsWs2D);
        }
        yield Yield.Asap;
        // split by groups of boundaries that possible overlaps
        const boundaryGroups = new Map<
            SceneInstance,        // include boundary
            Set<SceneInstance>    // exclude boundaries near/overlapping include boundary
        >();
        const reusedAabb1 = Aabb2.empty();
        const reusedAabb2 = Aabb2.empty();
        for (const include of includeBoundaries) {
            const intersectWith = new Set<SceneInstance>();
            const includeContour = boundaryPolygons.get(include);
            if (!includeContour){ 
                continue;
            }
            const includeBbox2d = reusedAabb1.setFromPoints(includeContour);

            for (const exclude of excludeBoundaries) {
                const contour = boundaryPolygons.get(exclude);
                if (!contour) {
                    continue;
                }
     
                const excludeBbox2d = reusedAabb2.setFromPoints(contour);
                if (!includeBbox2d.intersectsBox2(excludeBbox2d)){
                    continue;
                }

                intersectWith.add(exclude);
            }
            boundaryGroups.set(include, intersectWith);
        }
        yield Yield.Asap;
        // calculate include without exclude parts
        const includeWithoutExcludePolygons: Vector2[][] = [];
        let counter = 0;
        for (const [include, excludes] of boundaryGroups) {
            const includePolygon = boundaryPolygons.get(include)!;
            if (!excludes.size){
                includeWithoutExcludePolygons.push(includePolygon);
            }
            const excludePolygons = IterUtils.filterMap(excludes, e => boundaryPolygons.get(e));
            const resultingPolygon = Clipper.subtractPolygons(includePolygon, excludePolygons);

            if (resultingPolygon.length) {
                IterUtils.extendArray(includeWithoutExcludePolygons, resultingPolygon);
            }
            counter++;
            if (counter % 10 === 0) {
                yield Yield.Asap;
            }
        }
        // calculate buildable polygon
        let buildable_area: NumberProperty | undefined;
        if (includeWithoutExcludePolygons.length) {
            const buildablePolygon = Clipper.unionPolygons2D(includeWithoutExcludePolygons);
            yield Yield.Asap;
            const areaM2 = Math.abs(IterUtils.sum(buildablePolygon, c => PolygonUtils.area(c)));
            buildable_area = NumberProperty.new({
                value: convertThrow(areaM2, "m2", "ha"),
                unit: 'ha',
                isReadonly: true,
            });
        } else {
            buildable_area = NumberProperty.new({value: 0, unit: "ha", isReadonly: true,});
        }

        let site_area: NumberProperty | undefined;
        if(includeBoundaries.length){ 
            const sitePolygon = Clipper.unionPolygons2D(
                includeBoundaries.map(b => boundaryPolygons.get(b)!)
            );
            yield Yield.Asap;
            const areaM2 = Math.abs(IterUtils.sum(sitePolygon, c => PolygonUtils.area(c)));
            site_area = NumberProperty.new({
                value: convertThrow(areaM2, "m2", "ha"),
                unit: 'ha',
                isReadonly: true,
            });
        } else {
            site_area = NumberProperty.new({value: 0, unit: "ha", isReadonly: true});
        }
        
        return {buildable_area, site_area};
    }

    calculateGCR(farmConfigs: [IdConfig, Config][], perSubstation: Map<IdBimScene, IdBimScene[]>) : NumberProperty{

        let gcr = 0;
        let gcrCount = 0;
        function calculateGCR(
            si: SceneInstance,
            rowToRowSpace: NumberProperty
        ): Result<number> {
            let trackerWidthAsMeters: number | undefined;
            if (si.type_identifier === TrackerTypeIdent) {
                trackerWidthAsMeters = si.properties.get('tracker-frame | dimensions | max_width')?.as('m');
            } else if (si.type_identifier === FixedTiltTypeIdent) {
                trackerWidthAsMeters = si.properties.get('dimensions | width')?.as('m');
            } else if (si.type_identifier === "any-tracker") {
                const trackerProps = si.propsAs(AnyTrackerProps);
                trackerWidthAsMeters = trackerProps.tracker_frame.dimensions.max_width?.as('m');
            } else {
                return new Failure({msg: "can't calculate GCR for this type: " + si.type_identifier});
            }

            const rowToRowSpaceAsMeters = rowToRowSpace.as('m');
            if (!trackerWidthAsMeters || !rowToRowSpaceAsMeters) {
                return new Failure({msg: 'invalid arguments'});
            }

            return new Success(trackerWidthAsMeters / rowToRowSpaceAsMeters);
        }

        function addGcr(inst: SceneInstance, r2r: NumberProperty){
            const result = calculateGCR(inst, r2r);
            if (result instanceof Success) {
                gcrCount += 1;
                gcr += result.value;
            }
        }
        for (let [_configId, config] of farmConfigs) {
            const configProps = config.get<FarmLayoutConfig>();
            const substationId = config.connectedTo as IdBimScene;
            const substationSi = this.bim.instances.peekById(substationId);
            if (!substationSi || substationSi.type_identifier !== 'substation') {
                continue;
            }
            // get substation config
            // get all trackers of the substation
            const substationChildrenIds = perSubstation.get(substationId) ?? [];
            const substationChildren = this.bim.instances.peekByIds(substationChildrenIds);

            for (const [_, inst] of substationChildren) {
                const path = ["circuit", "position", "area_index"];
                const selectedAreaIdx = inst.properties.get(BimProperty.MergedPath(path))?.asNumber() ?? 0;
                let selectedConfig = configProps.site_areas[selectedAreaIdx];
                if(!selectedConfig){
                  //set allsite area
                  selectedConfig = configProps.site_areas[0];
                }
                addGcr(inst, selectedConfig.settings.spacing.row_to_row_space);
            }
        }

        return NumberProperty.new({value: gcr ? gcr / gcrCount : 0, isReadonly: true});
    }

    calculateTotalModulesArea(
        modules?: DefaultMap<string, { count: number }>,
        dimensions: Map<string, { length: NumberProperty, width: NumberProperty }> = new Map()
    ) {
        // calculate area of all modules
        let moduleAreaFt2 = 0;
        for (const [equipName, cntStat] of modules ?? []) {
            const dimenStat = dimensions.get(equipName);
            if (!dimenStat) continue;
            const areaFt = dimenStat.length.as('ft') * dimenStat.width.as('ft');
            const cnt = cntStat.count;
            const totalAreaFt2 = cnt * areaFt;
            moduleAreaFt2 += totalAreaFt2;
        }
        return NumberProperty.new({value: moduleAreaFt2, unit: 'ft2', isReadonly: true});
    }

    static createQtyCalculatorsPerType() : DefaultMap<string, Set<QtyCalculator>>{
        const qtyProcessingPerType = new DefaultMap<
        string,
        Set<QtyCalculator>
      >(() => new Set())
        for (const type of ['fixed-tilt', 'tracker']) {
            qtyProcessingPerType.getOrCreate(type).add(si => {
                return [
                    { amount: si.properties.get('circuit | equipment | strings_count')?.asNumber() ?? 0, name: 'string_count'},
                ];
            })
        }

        // terrains
        qtyProcessingPerType.getOrCreate(TerrainInstanceTypeIdent).add((si) => {
            const result: ReturnType<QtyCalculator> = [];
            cut_fill_volume: {
                const cutVolume = si.properties.get('metrics | cut_volume');
                const fillVolume = si.properties.get('metrics | fill_volume');
                if (!cutVolume || !fillVolume) {
                    break cut_fill_volume;
                }
                result.push({
                    amount: cutVolume.as('yd3') + fillVolume.as('yd3'),
                    name: 'cut_fill_total',
                    unit: 'yd3',
                });
                result.push({
                    amount: cutVolume.as('yd3'),
                    name: 'cut',
                    unit: 'yd3',
                });
                result.push({
                    amount: fillVolume.as('yd3'),
                    name: 'fill',
                    unit: 'yd3',
                });
                const cutArea = si.properties.get('metrics | cut_area');
                if(cutArea){
                    result.push({
                        amount: cutArea?.asNumber() ?? 0,
                        name: 'cut_area',
                        unit: cutArea?.unit ?? undefined,
                    });
                }
                const fillArea = si.properties.get('metrics | fill_area');
                if(fillArea){
                    result.push({
                        amount: fillArea?.asNumber() ?? 0,
                        name: 'fill_area',
                        unit: fillArea?.unit ?? undefined,
                    });
                }
                if(fillArea && cutArea){
                    result.push({
                        amount: (fillArea?.asNumber() ?? 0) + (cutArea.as(fillArea.unit) ?? 0),
                        name: 'cut_fill_total_area',
                        unit: fillArea?.unit,
                    });
                }
            }
            net_balance: {
                const netBalance = si.properties.get('metrics | net_balance');
                if (!netBalance){
                    break net_balance;
                }
                result.push({
                    amount: netBalance.as('yd3'),
                    name: 'cut_fill_net_balance',
                    unit: 'yd3',
                });
            }

            return result;
        });

        // trenches
        qtyProcessingPerType.getOrCreate('trench').add((si) => {
            const result: ReturnType<QtyCalculator> = [];
            volume: {
                const prop = si.properties.get('dimensions | volume');
                if (!prop) {
                    break volume;
                }
                result.push({
                    amount: prop.asNumber(),
                    name: 'trenches_volume',
                    unit: prop.unit,
                });
            }
            length: {
                const prop = si.properties.get('dimensions | length');
                if (!prop) {
                    break length;
                }
                result.push({
                    amount: prop.asNumber(),
                    name: 'trenches_length',
                    unit: prop.unit,
                });
            }
            return result;
        });

        // roads
        qtyProcessingPerType.getOrCreate('road').add((si) => {
            const result: ReturnType<QtyCalculator> = [];
            const areaProp = si.properties.get('dimensions | area');
            if (!areaProp) return result;
            result.push({
                amount: areaProp.asNumber(),
                name: 'roads_total_area',
                unit: areaProp.unit ?? undefined,
            });
            const lengthProp = si.properties.get('dimensions | length');
            if (!lengthProp) return result;

            result.push({
                amount: lengthProp.asNumber(),
                name: 'roads_total_length',
                unit: lengthProp.unit ?? undefined,
            })
            return result;
        });
        
        return qtyProcessingPerType;
    }
}


export type QtyCalculator = (si: SceneInstance) => Array<{
    amount: number,
    name: string,
    unit?: string
}>;
  

