import type { Bim, TMY_ColumnSelector} from 'bim-ts';
import { LogLevel, type Yield } from 'engine-utils-ts';
import type { FileImporter, FileImporterContext, FileToImport} from 'ui-bindings';
import { NotificationDescription, NotificationType } from 'ui-bindings';
import { notificationSource } from '../Notifications';
import { parseMeteoCsv } from './TMY_CSV_Parser';
import type { MeteoParsingResult} from './TMY_EPW_Parser';
import { parseMeteoEDW } from './TMY_EPW_Parser';

export class TMY_Importer implements FileImporter {

    fileExtensions = ['.epw', '.csv'];

    constructor(
        readonly bim: Bim
    ) {
    }
    additionalFileCheck(file: FileToImport): boolean {
        if (file.filename.endsWith('.csv')) {
            // could check if looks like TMY
        }
        return true;
    }

    *startImport(context: FileImporterContext, file: FileToImport): Generator<Yield, void, unknown> {
        let parsedMeteo: MeteoParsingResult;
        if (file.filename.toLowerCase().endsWith('.epw')) {
            const asText = new TextDecoder().decode(file.fileArrayBuffer);
            parsedMeteo = parseMeteoEDW({
                logger: context.logger.newScope('tmy_epw', LogLevel.Default),
                fileName: file.filename,
                fileContent: asText,
            })
        } else if (file.filename.toLowerCase().endsWith('.csv')) {
            const asText = new TextDecoder().decode(file.fileArrayBuffer);
            parsedMeteo = yield* parseMeteoCsv({
                logger: context.logger.newScope('tmy_csv', LogLevel.Default),
                bim: this.bim,
                fileName: file.filename,
                csvString: asText,
                requestSettings: context.requestSettings
            });
        } else {
            throw new Error('unexpected file format: ' + file.filename);
        }

        if (parsedMeteo.warnings.length) {
            context.sendNotification(NotificationDescription.newBasic({
                addToNotificationsLog: true,
                source: notificationSource,
                key: 'meteo_parse_warning',
                type: NotificationType.Warning,
                descriptionArg: parsedMeteo.warnings,
            }))
        }
        if (parsedMeteo.errors.length) {
            context.sendNotification(NotificationDescription.newBasic({
                addToNotificationsLog: true,
                source: notificationSource,
                key: 'meteo_parse_error',
                type: NotificationType.Error,
                descriptionArg: parsedMeteo.errors,
            }))
        }
        
        if (parsedMeteo.data) {
            function checkColumnsPresence(columnNames: TMY_ColumnSelector[], warningType: NotificationType): boolean {
                const absentColumnsUiNames: string[] = [];
                for (const cn of columnNames) {
                    const columnData = parsedMeteo.data?.getDataColumn(cn);
                    if (!columnData) {
                        absentColumnsUiNames.push(cn.replaceAll('_', ' '));
                    }
                }
                if (absentColumnsUiNames.length > 0) {
                    context.sendNotification(NotificationDescription.newBasic({
                        addToNotificationsLog: true,
                        source: notificationSource,
                        key: 'meteo_parse_error',
                        type: warningType,
                        descriptionArg: [`could not parse necessary data: ${absentColumnsUiNames.join()}`],
                    }))
                }
                return absentColumnsUiNames.length === 0;
            }
            const necessaryColumnsNames: TMY_ColumnSelector[] = [
                'Global_Horizontal_Irradiance',
                'Diffuse_Horizontal_Irradiance',
                'Direct_Normal_Irradiance'
            ];
            const optionalColumnsNames: TMY_ColumnSelector[] = [
                'Ambient_Temperature',
                'Relative_Humidity',
                'Wind_Speed',
            ];
            checkColumnsPresence(optionalColumnsNames, NotificationType.Warning);
            if (!checkColumnsPresence(necessaryColumnsNames, NotificationType.Error)) {
                parsedMeteo.data = null;
            }
        }

        if (!parsedMeteo.data) {
            throw new Error('could not import meteo data');
        }

        this.bim.configs.allocateSingleton({
            type_identifier: 'typical-meteo-year',
            props: parsedMeteo.data,
            properties: {},
        });
    }

}
