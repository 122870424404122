<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="24"
    viewBox="0 0 16 24"
    fill="none"
>
    <path
        d="M8 0L8 14C8 15.1046 8.89543 16 10 16L15 16"
        stroke="#72868C"
        stroke-opacity="0.5"
    />
</svg>
