import { Bim, ColorProperty, generateTerrainConfigProps, NumberProperty, NumberRangeProperty, TerrainAnalysisConfig, TerrainAnalysisTypeIdent, TerrainDisplaySlopeSelector, TerrainGeoVersionSelector, TerrainInstanceTypeIdent, TerrainMetrics, TerrainMetricsRanges, TerrainMetricsType, TerrainPaletteSlice } from "bim-ts";
import { PageFrameOffsets, PdfElement, PdfReportContext } from "../PdfReportBuilder";
import { Immer, IterUtils, RGBA, ScopedLogger, Success, Yield } from "engine-utils-ts";
import { TerrainDisplayMode } from "engine-ts";
import jsPDF from "jspdf";
import { Aabb, Aabb2, Vector2 } from "math-ts";
import { convertPxToMm, fitMaxRectangleInPolygon } from "../PdfCommon";
import { AnnotationBuilder } from "../AnnotationsBuilder";
import { findOptimalStepForGrid } from "../LayoutDrawing";
import { setTextStyle, TextBuilder } from "../TextBuilder";
import { drawLine, printTableHeader, TableHeaderDescription } from "./BlocksSchedulePage";
import { createTerrainOuterContour } from "../TerrainOuterContour";

interface TerrainSlopeContext {
    imagePositionRatio: number;
    spaceBetweenImagesPx: number;
    terrainGeoVersion: TerrainGeoVersionSelector;
    ewTableRows: TerrainSlopeTableProps;
    nsTableRows: TerrainSlopeTableProps;
    focusAabb2: Aabb2;
}

interface TerrainSlopeTableProps {
    slices: TerrainPaletteSlice[];
    metrics: TerrainMetrics;
    tableRows: TerrainSlopeRangeProps[];
};

interface TerrainSlopeRangeProps {
    color: ColorProperty;
    range: NumberRangeProperty;
    area: NumberProperty;
    areaShare: NumberProperty;
}

interface TerrainSlopeTableHeader extends TableHeaderDescription {
    key: keyof TerrainSlopeRangeProps;
    convertUnit?: string;
    align?: 'left' | 'right';
}

export class TerrainSlopePropsTable extends PdfElement<TerrainSlopeContext> {
    constructor(
        readonly bim: Bim, 
        readonly logger: ScopedLogger,
        readonly slopeSelector: TerrainDisplaySlopeSelector,
        readonly position: 'left' | 'right'
    ){
        super();
    }

    *draw({page, pageFrameOffsets, context}: PdfReportContext<TerrainSlopeContext>): Generator<Yield, void, unknown> {
        const {tableRows} = this.slopeSelector === TerrainDisplaySlopeSelector.EW ? context.ewTableRows : context.nsTableRows;
        function convertMmToPx(v: number) {
            return v / convertPxToMm(1);
        }
        const startXPosPx = this.position === 'left' 
            ? pageFrameOffsets.leftRightOffsetPx
            : convertMmToPx(page.internal.pageSize.getWidth() * context.imagePositionRatio) + context.spaceBetweenImagesPx/2;
        const textBuilder = new TextBuilder(
            this.bim.unitsMapper,
            page, 
            pageFrameOffsets.upOffsetPx, 
            startXPosPx,
            null,
            this.logger,
            convertPxToMm
        );
        const header = this.slopeSelector === TerrainDisplaySlopeSelector.EW ? "EW Slopes" : "NS Slopes";
        textBuilder.addRow({text: header, headerLevel: 1, values: null, style: 'bold'});
        textBuilder.addCustomOffset(10);
        const totalWidth = page.internal.pageSize.width / 2 - convertPxToMm(pageFrameOffsets.leftRightOffsetPx + context.spaceBetweenImagesPx/2);
        const totalWidthPx = convertMmToPx(totalWidth);
        const headers: TerrainSlopeTableHeader[] = [ 
            {description: "Slope", key: "color", widthPx: 40},
            {description: "", key: "range", widthPx: 0, convertUnit: "%", align: 'left'},
            {description: "Area", key: "area", widthPx: 120, unit: "ac", convertUnit: "ac"},
            {description: "/ Share", key: "areaShare", widthPx: 120, unit: "%", convertUnit: "%"},
        ];
        headers[1].widthPx = totalWidthPx - IterUtils.sum(headers, x => x.widthPx);

        const fontSize = 11;
        const headerOffsets = printTableHeader(textBuilder.leftOffsetPx, textBuilder.offsetYPx, fontSize, headers, page);
        let offsetYPx = headerOffsets.offsetYPx + 8;
        function drawRowText(row: TerrainSlopeRangeProps, rowHeightPx: number, isTotal?: boolean){
            const fontSize = 10;
            offsetYPx += rowHeightPx;
            const style = isTotal ? 'bold' : 'normal';
            setTextStyle(page, style, fontSize);
            const textHeight = page.getTextDimensions("1").h;
            const localOffsetY = convertPxToMm(offsetYPx) - (convertPxToMm(rowHeightPx) - textHeight) * 0.5
            let offsetXPx = textBuilder.leftOffsetPx;
            for (const header of headers) {
                const prop = row[header.key];
                offsetXPx += header.widthPx;
                let valueStr = '';
                function getSignOfValue(value: number){
                    return value > 0 ? '+' : '';
                }
                if(prop instanceof NumberRangeProperty){
                    const digits = 2;
                    const unit = header.convertUnit ?? prop.unit;
                    const [value1, value2] = header.unit ? prop.as(unit) : prop.value;
                    const configured1 = textBuilder.unitsMapper.mapToConfigured({value: value1, unit: unit });
                    const configured2 = textBuilder.unitsMapper.mapToConfigured({value: value2, unit: unit });
                    const valueStr1 = configured1.value.toFixed(digits);
                    const valueStr2 = configured2.value.toFixed(digits);
                    valueStr = `${getSignOfValue(configured1.value)}${valueStr1}${configured1.unit} / ${getSignOfValue(configured2.value)}${valueStr2}${configured2.unit}`;
                } else if(prop instanceof NumberProperty){
                    const digits = header.convertUnit ? 2 : 0;
                    const value = header.convertUnit ? prop.as(header.convertUnit) : prop.value;
                    const configured = textBuilder.unitsMapper.mapToConfigured({value, unit: header.convertUnit });
                    valueStr = configured.value.toFixed(digits);
                } else if(prop instanceof ColorProperty){
                    const [r, g, b] = RGBA.toRgbArray(prop.value);
                    page.setFillColor(r, g, b);
                    const upBotOffsetPx = 5;
                    const height = convertPxToMm(rowHeightPx - upBotOffsetPx*2);
                    const width = convertPxToMm(header.widthPx-8);
                    const round = convertPxToMm(1);
                    const compensationY = (height - textHeight) * 0.5;
                    page.roundedRect(
                        convertPxToMm(offsetXPx - header.widthPx), 
                        localOffsetY - (height) + compensationY, 
                        width, 
                        height,
                        round,
                        round,
                        'F'
                    );
                    
                } else if(prop === null){
                    valueStr = '—';
                }
                
                const textWidth = page.getTextWidth(valueStr);
                const xPos = header.align === 'left' 
                    ? convertPxToMm(offsetXPx - header.widthPx + headerOffsets.contentRightOffsetPx) 
                    : convertPxToMm(offsetXPx - headerOffsets.contentRightOffsetPx) - textWidth;
                page.text(valueStr, xPos, localOffsetY);
            }
        }

        const lineLengthPx = IterUtils.sum(headers, x => x.widthPx);
        for (const row of tableRows){
            drawLine(page, textBuilder.leftOffsetPx, offsetYPx, lineLengthPx);
            drawRowText(row, 20);
        }
    }
}

export class TerrainSlopeScreenshot extends PdfElement<TerrainSlopeContext> {
    constructor(
        readonly bim: Bim, 
        readonly logger: ScopedLogger,
        readonly slopeSelector: TerrainDisplaySlopeSelector,
        readonly position: 'left' | 'right'
    ){
        super();
    }

    *draw({page, screenshotMaker, pageFrameOffsets, context}: PdfReportContext<TerrainSlopeContext>): Generator<Yield, void, unknown> {
        const {slices} = this.slopeSelector === TerrainDisplaySlopeSelector.EW ? context.ewTableRows : context.nsTableRows;
        const visibleIds = this.bim.instances.peekByTypeIdent('terrain-heightmap').map(x => x[0]);
        const imageResult = yield* screenshotMaker.makeScreenshotOf({
            ids: visibleIds,
            focusSettings: context.focusAabb2,
            showAnnotations: false,
            showGizmo: false,
            showClickbox: false,
            terrainDisplaySettings: {
                terrainVersion: context.terrainGeoVersion,
                mode: TerrainDisplayMode.Slope,
                slopeSelector: this.slopeSelector,
            },
            colorize: (bim, _ids, event) => {
                const config = bim.configs.peekSingleton(TerrainAnalysisTypeIdent)?.get<TerrainAnalysisConfig>();
                if(!config){
                    return;
                }
                const updatedConfig = Immer.produce(config, draft => {
                    draft.slope_palette.slices = slices;
                });
                bim.configs.applyPatchToSingleton(TerrainAnalysisTypeIdent, {properties: updatedConfig}, event);
            }
        });
        yield Yield.Asap;
        if(imageResult.image instanceof Success){ 
            addImageOfTerrainSlopes({
                bim: this.bim,
                page,
                image: new Uint8Array(imageResult.image.value), 
                wsCoords: imageResult.wsCoords,
                offsets: pageFrameOffsets,
                grid: true,
                imagePositionRatio: context.imagePositionRatio,
                spaceBetweenImagesPx: context.spaceBetweenImagesPx,
                position: this.position,
            });
        } else {
            this.logger.error("Failed to make screenshot of cut fill");
        }
    }
}


function addImageOfTerrainSlopes({bim, page, image, offsets, wsCoords, grid, position, imagePositionRatio, spaceBetweenImagesPx}: {
    bim: Bim,
    position: 'left' | 'right',
    page: jsPDF, 
    image: Uint8Array,
    wsCoords: Aabb,
    offsets: PageFrameOffsets,
    spaceBetweenImagesPx: number,
    imagePositionRatio: number,
    grid?: boolean
}){
    const tableOffsetPx = 280;
    const imageInfo = page.getImageProperties(image);
    const imageWidth = imageInfo.width;
    const imageHeight = imageInfo.height;
    const pageWidth = page.internal.pageSize.getWidth();
    const pageHeight = page.internal.pageSize.getHeight();
    const downAdditionOffsetPx = 50;

    const maxHeight = pageHeight 
        - convertPxToMm(offsets.upOffsetPx) 
        - convertPxToMm(offsets.downOffsetPx)
        - convertPxToMm(downAdditionOffsetPx)
        - convertPxToMm(tableOffsetPx);
    const maxWidth = pageWidth * imagePositionRatio 
        - convertPxToMm(offsets.leftRightOffsetPx) - convertPxToMm(spaceBetweenImagesPx)/2;
    
    const imagedToPageScale = Math.min(
        maxWidth / imageWidth,
        maxHeight / imageHeight
    );

    const imageDrawSize = new Vector2(
        imageWidth,
        imageHeight
    ).multiplyScalar(imagedToPageScale);

    const posX = position === 'left' 
        ? convertPxToMm(offsets.leftRightOffsetPx) + imageDrawSize.x * 0.5
        : pageWidth * imagePositionRatio + convertPxToMm(spaceBetweenImagesPx)/2 + imageDrawSize.x * 0.5;
    const drawingCenter = new Vector2(
        posX,
        convertPxToMm(offsets.upOffsetPx) + convertPxToMm(tableOffsetPx) + imageDrawSize.y * 0.5
    );

    const pos = new Vector2(
        drawingCenter.x - imageDrawSize.x * 0.5,
        drawingCenter.y - imageDrawSize.y * 0.5,
    );
    page.addImage(image, "PNG", pos.x, pos.y, imageDrawSize.x, imageDrawSize.y);


    if(grid){
        const maxSize = wsCoords.getSize().xy();
        const worldToPageScale = Math.min(imageDrawSize.x / maxSize.x, imageDrawSize.y / maxSize.y);
        const gridSize = findOptimalStepForGrid(wsCoords.getSize().xy().maxComponent(), worldToPageScale, bim.unitsMapper.isImperial());
        const startX = position === 'left' ? convertPxToMm(offsets.leftRightOffsetPx) : pageWidth * imagePositionRatio + convertPxToMm(spaceBetweenImagesPx)/2;
        const endX = position === 'left' ? pageWidth * imagePositionRatio - convertPxToMm(spaceBetweenImagesPx)/2 : pageWidth - convertPxToMm(offsets.leftRightOffsetPx);  
        const annotations = new AnnotationBuilder(
            page, 
            offsets,
            startX,
            endX
        );
        annotations.addGrid({
            text: `${gridSize.step} ${gridSize.unit}`,
            width: gridSize.widthLocalSystem,
        });
    }
}

export function* createTerrainSlopeContext(bim: Bim, logger: ScopedLogger): Generator<Yield, TerrainSlopeContext, any>{
    const terrainGeoVersion = TerrainGeoVersionSelector.Initial;
    const ewTableRows = yield* createTerrainSlopePallette(bim, logger, TerrainDisplaySlopeSelector.EW, terrainGeoVersion);
    const nsTableRows = yield* createTerrainSlopePallette(bim, logger, TerrainDisplaySlopeSelector.NS, terrainGeoVersion);

    const terrainId = bim.instances.peekByTypeIdent(TerrainInstanceTypeIdent)[0][0];
    const contour = createTerrainOuterContour(bim, terrainId);
    const focusAabb2 = Aabb2.empty();
    if(contour !== null){
        const fitRect = fitMaxRectangleInPolygon(contour, 1.4);
        if(fitRect) {
            const xmin = fitRect.center.x - (fitRect.size.x * 0.5);
            const xmax = fitRect.center.x + (fitRect.size.x * 0.5);
            const ymin = fitRect.center.y - (fitRect.size.y * 0.5);
            const ymax = fitRect.center.y + (fitRect.size.y * 0.5);
            const rect = [
                new Vector2(xmin, ymin),
                new Vector2(xmax, ymin),
                new Vector2(xmax, ymax),
                new Vector2(xmin, ymax),
            ];
            focusAabb2.setFromPoints(rect);
            // debugBoundary(bim, rect, 'rectangle 1.3');
        }
    }

    return {ewTableRows, nsTableRows, terrainGeoVersion, imagePositionRatio: 0.5, spaceBetweenImagesPx: 50, focusAabb2};
}

function* createTerrainSlopePallette(
    bim: Bim,
    logger: ScopedLogger,
    slopeSelector: TerrainDisplaySlopeSelector,
    terrainGeoVersion: TerrainGeoVersionSelector
): Generator<Yield, TerrainSlopeTableProps, any> {
    const unit = "%";
    const terrains = bim.instances.peekByTypeIdent(TerrainInstanceTypeIdent);
    const tableRowsCount = 8;

    const defaultRanges = IterUtils.newArray(tableRowsCount + 1, i => -100 + i * 200 / tableRowsCount);
    const defaultPallete = new TerrainMetricsRanges(
        TerrainMetricsType.Slope,
        unit,
        defaultRanges
    );
    const defaultMetrics =
        yield* TerrainMetrics.calcuateBimObjsAreaMetricsForSlope(
            logger,
            defaultPallete,
            bim,
            terrains,
            terrainGeoVersion,
            slopeSelector
        );
    const maxAbs = Math.max(Math.abs(defaultMetrics.fullRange.min), Math.abs(defaultMetrics.fullRange.max));
    const step = maxAbs*2 / (tableRowsCount);

    const samplePalette = generateTerrainConfigProps({
        elevationRangeMeters: [0, 1],
        paletteSize: tableRowsCount,
        slopeRangePercentagesExtents: maxAbs,
        cutfillRangeMeters: [-10, 10],
        defaultOffset: 1,
    });
    const slices = samplePalette.slope_palette.slices;
    const ranges: number[] = [];
    for (let i = 0; i < slices.length; i++) {
        const slice = slices[i];
        const min = slice.min.as(unit);
        ranges.push(min);
        if (i === tableRowsCount - 1) {
            ranges.push(slice.max.as(unit));
        }
    }
    
    const metrics = yield* TerrainMetrics.calcuateBimObjsAreaMetricsForSlope(
        logger,
        new TerrainMetricsRanges(TerrainMetricsType.Slope, unit, ranges),
        bim,
        terrains,
        terrainGeoVersion,
        slopeSelector
    );
    const tableRows: TerrainSlopeRangeProps[] = [];
    for (let i = 0; i < slices.length; i++) {
        const area = metrics.perSliceArea[i];
        const areaShare = area && metrics.totalArea ? (100 * area) / metrics.totalArea : 0;
        tableRows.push({
            color: slices[i].color,
            range: NumberRangeProperty.new({value: [slices[i].min.value, slices[i].max.value], unit: slices[i].min.unit}),
            area: NumberProperty.new({ value: area, unit: "m2" }),
            areaShare: NumberProperty.new({ value: areaShare, unit: "%" }),
        });
    }

    return {slices , metrics, tableRows};
}

