import type { EnergyWarningType } from './EnergyStageWarning';


export class EnergyErrorDescription {
    type?: EnergyWarningType;
    uiMsg: string;
    uiMsgLong?: string;

    constructor(arg: {
        type?: EnergyWarningType,
        uiMsg: string,
        uiMsgLong?: string,
        msgArgs?: [string|number][],
    }) {
        this.type = arg.type;
        this.uiMsg = arg.uiMsg;
        this.uiMsgLong = arg.uiMsgLong;
    }
}

export const EnergyErrorMsgs = {

    _CalculationsDisabled: "Energy calculations are disabled",

    _EnergyGlobalsNotLoaded: "(internal error) Energy globals",
    _EnergyPOANotLoaded: "(internal error) POA",

    MeteoDataNotLoaded: "Meteo Data Not Loaded",

    InvalidLatitiude: "Invalid latitude",
    InvalidLongitude: "Invalid longitude",
    InvalidAltitude: "Invalid altitude",
    InvalidTimezone: "Invalid timezone",
    InvalidGeolocation: "Invalid geolocation",

    DNI_Absent: 'Direct Normal Irradiance not found',
    GHI_Absent: 'Global Horizontal Irradiance not found',
    DHI_Absent: 'Diffuse Horizontal Irradiance not found',

    InvalidStageConfiguration: "Invalid stage configuration",
    IncompleteStage: "Incomplete stage",
    InvalidPreviousStage: "Invalid previous stage",

    ShadingNotCalculated: "Shading is not calculated",

    AmbientTempNotFound: "Meteo: Ambient temperature not found, using constant 20 deg C",
    WindSpeedNotFound: "Meteo: Wind speed not found, using constant 0 m/s",
    RelativeHumidityNotFound: "Meteo: Relative Humidity not found",

    UnexpectedInverterEffStd: "Unexpected Efficiency Standard (CEC or EURO expected)",

    TimezoneAndGeoCoordsDoNotConverge: new EnergyErrorDescription({
        uiMsg: "Timezone or geo-coordinates do not converge with meteo data",
        uiMsgLong: "Peak sun hours calculated from geo-coordinates has $0 hours offset from from meteo data peak irradiance, either timezone or geo-coordinates are incorrect",
    }),

    InadequateTrackerPosition: "Tracker position is not optimal, should be NS oriented",

    InadequateFixTiltTilt: "Tilt is not optimal for meteo file geo coordinates",
    
    InvalidModuleProperties: new EnergyErrorDescription({ 
        uiMsg: "It is necessary to check the adequacy of the specified module properties",
        type: 'error'
    }),

    DifferentStringsConnectedToInverter: new EnergyErrorDescription({
        uiMsg: "Different strings connected to one inverter",
        type: 'error',
    }),
    StringVoltageDoesNotFallWithinVoltageOfInverter: new EnergyErrorDescription({ 
        uiMsg:"String Voltage does not fall within the operating voltage range of the inverter",
        type: 'error'
    }),

    InverterWithNoEnergy: 'Inverter without energy',
    NotConnectedTrackers: 'Solar arrays not connected to the inverter or transformer',

} satisfies {[key: string]: EnergyErrorDescription | string};

export const EnergyActionNames = {
    
}

