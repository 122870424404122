import type { WebGLInfo } from '../3rdParty/three';

export class DrawcallsInfo {

	calls: number = 0;
	triangles: number = 0;
	lines: number = 0;

	reset() {
		this.calls = 0;
		this.triangles = 0;
		this.lines = 0;
	}

	copyFromRendererWebGLInfo(info: WebGLInfo) {
		this.calls = info.render.calls;
		this.triangles = info.render.triangles;
		this.lines = info.render.lines;
	}

}