<script lang="ts">
    import { TextField } from "../../libui/fields";

    export let isValid:boolean = false;
    export let label: string = '';
    
    $: isValidLabelInput = !!label && label.length > 0;
    $: isValid = isValidLabelInput;
</script>

<style lang="scss">
 
</style>


<br>
<TextField
    labelText="label"
    bind:value={label}
    bind:isValid ={isValidLabelInput}
/>