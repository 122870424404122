import type { SceneInstance} from 'bim-ts';
import { Asset, getAssetGeneratedName, type Bim, type Catalog} from 'bim-ts';
import { CatalogSource, exportToBimAsset } from 'bim-ts';
import {
    Yield,
    type LongTask, type TasksRunner} from 'engine-utils-ts';
import { type UiBindings, NotificationDescription, NotificationType } from 'ui-bindings';
import { SetCatalogSearchEvent } from '../../catalog/CatalogItemManager';
import { notificationSource } from '../../Notifications';

export async function saveBimAssetToCatalog(
    bim: Bim,
    uiBindings: UiBindings,
    tasksRunner: TasksRunner,
    catalog: Catalog,
) {
    try {
        const bimInstancesIds = bim.instances.getSelected();
        if (bimInstancesIds.length !== 1) {
            throw new Error('Select only one instance');
        }
        const selectedSceneInstance = bim.instances.peekById(bimInstancesIds[0])!;
        const assetName = bim.keyPropertiesGroupFormatter.format(
            selectedSceneInstance.type_identifier,
            selectedSceneInstance.properties,
            selectedSceneInstance.props,
        );

        matchingCatalogItemShouldNotExist(selectedSceneInstance, catalog);
        const task: LongTask<void> = tasksRunner.newLongTask({
            defaultGenerator: function*() {
                yield Yield.Asap;
                const zip = yield* exportToBimAsset(bim, bimInstancesIds, false);
                const asset = new Asset(
                    selectedSceneInstance.name || undefined,
                    new CatalogSource('user'),
                    zip,
                )
                // if asset already exist, 
                catalog.assets.allocate([[
                    catalog.assets.reserveNewId(),
                    asset,
                ]])
                // get bim asset name
                const label = yield* getAssetGeneratedName(asset, bim.keyPropertiesGroupFormatter);
                if (label) {
                    window.dispatchEvent(new SetCatalogSearchEvent(label))
                }
            }(),
        });

        uiBindings.addNotification(
            NotificationDescription.newWithTask({
                source: notificationSource,
                key: 'convertToAsset',
                taskDescription: { task },
                descriptionArg: assetName,
                type: NotificationType.Info,
                removeAfterMs: 3000,
                addToNotificationsLog: true
            })
        );
        await task.asPromise();
    } catch (e) {
        uiBindings.addNotification(
            NotificationDescription.newBasic({
                source: notificationSource,
                key: 'convertToAssetError',
                descriptionArg: e[3] ?? e[1] ?? e,
                type: NotificationType.Error,
                removeAfterMs: 3000,
                addToNotificationsLog: true
            })
        );
        console.error(e);
    }
}


function matchingCatalogItemShouldNotExist(
    si: SceneInstance,
    catalog: Catalog
) {
    /** @TODO: undo this hack which allows to add any-tracker duplicates */
    if (si.type_identifier === 'any-tracker') {
        return;
    }
    const existingAssetId = catalog.assets.assetsMatcher
        .matchSceneInstanceWithAsset(si);
    if (!existingAssetId) {
        return;
    }
    const catalogItemId = catalog.catalogItems.catalogItemIdPerAssetId.poll()
        .get(existingAssetId);
    const catalogItem = catalogItemId && catalog.catalogItems.peekById(catalogItemId);
    if (!catalogItem) {
        throw new Error('Error searching for duplicate catalog item')
    }

    const existingCatalogItemName = catalog.catalogItemsUiLabels.solve(
        catalogItem.typeIdentifier,
        catalogItem.properties
    )
    if (existingCatalogItemName) {
        throw new Error(`asset ${existingCatalogItemName.title} already exists`)
    } else {
        throw new Error(`asset already exists`)
    }
}
