<script lang="ts">
    import type { EnergyViewContext } from "./EnergyViewContext";
    import { InProgress, LazyDerived } from "engine-utils-ts";
    import { VersionedStore } from "src/VersionedStore";
    import { RuntimeSystemExecutionStatus } from "ui-bindings";
    import { EnergyYieldPropsGroup, EnergyYieldSiteProps } from "bim-ts";

    export let context: EnergyViewContext;

    const RuntimeNames = [
        "shading-runtime",
        "InverterTmyStats",
        "SubstationTmyStats",
    ];
    const runtimes = context.bim.customRuntimes.runtimes.filter((runtime) =>
        RuntimeNames.includes(runtime.name),
    );

    const energyStatusLazy = LazyDerived.new1(
        "energy-status",
        runtimes,
        [
            context.bim.runtimeGlobals.getAsLazyVersionedByIdent(
                EnergyYieldSiteProps,
                EnergyYieldPropsGroup,
            ),
        ],
        ([energyData]) => {
            return (
                energyData instanceof InProgress ||
                runtimes.some(
                    (runtime) =>
                        runtime.executionStatus() ===
                        RuntimeSystemExecutionStatus.InProgress,
                )
            );
        },
    );

    const energyStatus = new VersionedStore<Boolean>(energyStatusLazy);
</script>

{#if $energyStatus}
    <div class="status-spinner mdc-typography--body2-strong text-main-light">
        Updating...
    </div>
{/if}

<style lang="scss">
    .status-spinner {
        position: absolute;
        right: 0;
        top: 8px;
    }
</style>
