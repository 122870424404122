<script lang="ts">
    import { PUI_PropertyNodeNumber } from "ui-bindings";
    import InputOffset from "./InputOffset.svelte";
    import { PileOffsetContext } from "../PilePositions";
    import { VersionedStore } from "../../VersionedStore";
    import { PileMotorType } from "bim-ts";
    import { PileLimits } from "./PileLimits";
    import { IterUtils } from "engine-utils-ts";

    export let context: PileOffsetContext;

    $: state = new VersionedStore(context.stateManager.state);

    $: piles = $state.props?.piles ?? [];
    $: positions = $state.props?.pilesOffsetsMeter ?? [];


    interface OffsetItem {
        name: string;
        value: PUI_PropertyNodeNumber;
    }

    let items: OffsetItem[] = [];

    function createNumberPropReadonly(value: number, unit: string): PUI_PropertyNodeNumber {
        return new PUI_PropertyNodeNumber({
            name: "_",
            value: value,
            unit: unit,
            onChange: () => {},
            readonly: true,
        })
    }

    $: {
        const props = $state.props;
        const index = context.pileIndex;
        if(!props || !props.isRelativePilePositions){
            items.length = 0;
        } else {
  
            let prevPosition = 0;
            const newItems: OffsetItem[] = [];
            if(index === 0){
                newItems.push({name: "Overhang", value: createNumberPropReadonly(props.overhangNorth.as("m"), "m")});
            } else {
                const prevPileIndex = index != null && index > 0 ? index - 1 : piles.length - 1;
                const prevPileState = new PileLimits(props, prevPileIndex, context.stateManager);
                const prevPile = piles[prevPileIndex];
                prevPosition = positions[prevPileIndex];
                const prevPileOffsetMessage = prevPile.motor === PileMotorType.Motor ? "Motor pile offset" : "Pile offset";
                const prevPileOffset = prevPileState.shiftMinMax[1] - prevPile.offset_in_meters;
                newItems.push({name: "Last pile", value: createNumberPropReadonly(prevPosition, "m")});
                newItems.push({name: prevPileOffsetMessage, value: createNumberPropReadonly(prevPileOffset, "m")});
            }

            if(index != null) {
                const pileState = new PileLimits(props, index, context.stateManager);
                const pile = piles[index];
                const position = positions[index];

                const modulesOffsetStart = props.modulesRowsOffsetsMeter[pile.offset_in_modules! - pileState.modules];
                const modulesOffsetEnd = props.modulesRowsOffsetsMeter[pile.offset_in_modules! - 1];
                const modulesWidth = pileState.modules * props.moduleWidthMeter;
                const modulesOffset = modulesOffsetEnd - modulesOffsetStart - (pileState.modules - 1) * props.moduleWidthMeter;

                const totalOffset = position - prevPosition;
                const centredCoordValue = position - pile.offset_in_meters;
                const pileOffsetAfter = pile.motor === PileMotorType.Motor ? "Motor pile offset after" : "Pile offset after";
                const offsetAfter = pileState.shiftMinMax[1] - pile.offset_in_meters;
                const pileOffsetBefore = pile.motor === PileMotorType.Motor ? "Motor pile offset before" : "Pile offset before";
                const offsetBefore = pile.offset_in_meters - pileState.shiftMinMax[0];

                const optionsWithModules = [
                    {name: `${pileState.modules} Modules`, value: createNumberPropReadonly(modulesWidth, "m")},
                    {name: `${pileState.modules - 1} Modules offset`, value: createNumberPropReadonly(modulesOffset, "m")},

                    { name: pileOffsetBefore, value: createNumberPropReadonly(offsetBefore, "m")},
                    { name: "Actual total offset", value: createNumberPropReadonly(totalOffset, "m")},
                    { name: "Pile coordinate if centered", value: createNumberPropReadonly(centredCoordValue, "m")},
                    { name: "Shift pile", value: new PUI_PropertyNodeNumber({
                        name: "_",
                        value: pile.offset_in_meters, 
                        unit: "m", 
                        minMax: pileState.shiftMinMax,
                        onChange: (newValue) => {
                            pileState.updatePile({offset_in_meters: newValue});
                        },
                        step: 0.001,
                    })},
                    {name: "Actual pile coordinate", value: new PUI_PropertyNodeNumber({
                        name: "_",
                        value: position, 
                        unit: "m", 
                        minMax: pileState.positionMinMax,
                        onChange: (newValue) => {
                            pileState.updatePosition(newValue);
                        },
                        step: 0.001,
                    })},
                    { name: pileOffsetAfter, value: createNumberPropReadonly(offsetAfter, "m")},
                ];

                IterUtils.extendArray(newItems, optionsWithModules);
            } else {
                const lastPile = piles[piles.length - 1];
                const modulesCount = props.modulesCount - (lastPile.offset_in_modules ?? 0); 
                const modulesWidth = modulesCount * props.moduleWidthMeter;
                const modulesOffsetStart = props.modulesRowsOffsetsMeter[(lastPile.offset_in_modules ?? 0) - modulesCount];
                const modulesOffsetEnd = props.modulesRowsOffsetsMeter[props.modulesRowsOffsetsMeter.length - 1];
                const modulesOffset = modulesOffsetEnd - modulesOffsetStart - (modulesCount - 1) * props.moduleWidthMeter;
                const totalOffset = props.trackerLength.as("m") - positions[positions.length - 1];
                const lastPileOptions: OffsetItem[] = [
                    {name: `${modulesCount} Modules`, value: createNumberPropReadonly(modulesWidth, "m")},
                    {name: `${modulesCount - 1} Modules offset`, value: createNumberPropReadonly(modulesOffset, "m")},
                    {name: "Overhang", value: createNumberPropReadonly(props.overhangSouth.as("m"), "m")},
                    {name: "Actual total offset", value: createNumberPropReadonly(totalOffset, "m")},
                    {name: "Tracker end coordinate", value: createNumberPropReadonly(props.trackerLength.as("m"), "m")},
                ];

                IterUtils.extendArray(newItems, lastPileOptions);
            }


            items = newItems;
        }
    }
</script>

<div class="root">
    {#each items as item}
        <span class="item">
            <span class="name">{item.name}</span>
            <span class="number-value">
                <InputOffset numberProperty ={item.value} readonly = {item.value.readonly} description = {undefined}/>
            </span>
        </span>
    {/each}
</div>

<style lang="scss">
    .root {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 8px 16px 8px 12px
    }

    .number-value{
        min-width: 80px;
        max-width: 80px;
    }
    .item {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
</style>