<script lang="ts">
    import { Hierarchy } from "../libui/icon";

    export let name: string;
    export let openedGroup: string;

    $: expanded = name === openedGroup;

    function onClick() {
        openedGroup = expanded ? "" : name;
    }
</script>

<div class="group">
    <div on:click={onClick} class="header">
        <div class="hierarchy-icon">
            <Hierarchy isOpen={expanded} />
        </div>
        <span class="mdc-typography--headline4">
            {name}
        </span>
    </div>
    {#if expanded}
        <div class="content">
            <slot />
        </div>
    {/if}
</div>

<style>
    .group {
        margin: 16px 0;
    }
    .header {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: -8px;
    }
    .hierarchy-icon {
        display: flex;
    }
    .content {
        padding: 12px 8px 0;
    }
</style>
