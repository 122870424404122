<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 5C2 3.89543 2.89543 3 4 3H20C21.1046 3 22 3.89543 22 5V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V5ZM20 5L4 5V19H20V5Z"
        fill="currentColor"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 8C10 7.44772 10.4477 7 11 7H17C17.5523 7 18 7.44772 18 8C18 8.55228 17.5523 9 17 9H11C10.4477 9 10 8.55228 10 8Z"
        fill="currentColor"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 12C10 11.4477 10.4477 11 11 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H11C10.4477 13 10 12.5523 10 12Z"
        fill="currentColor"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 16C10 15.4477 10.4477 15 11 15H17C17.5523 15 18 15.4477 18 16C18 16.5523 17.5523 17 17 17H11C10.4477 17 10 16.5523 10 16Z"
        fill="currentColor"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 8C6 7.44772 6.44772 7 7 7C7.55228 7 8 7.44772 8 8C8 8.55228 7.55228 9 7 9C6.44772 9 6 8.55228 6 8Z"
        fill="currentColor"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 12C6 11.4477 6.44772 11 7 11C7.55228 11 8 11.4477 8 12C8 12.5523 7.55228 13 7 13C6.44772 13 6 12.5523 6 12Z"
        fill="currentColor"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 16C6 15.4477 6.44772 15 7 15C7.55228 15 8 15.4477 8 16C8 16.5523 7.55228 17 7 17C6.44772 17 6 16.5523 6 16Z"
        fill="currentColor"
    />
</svg>
