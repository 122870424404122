import type { ScopedLogger } from 'engine-utils-ts';
import { DefaultMap, DefaultMapObjectKey, ObjectUtils } from 'engine-utils-ts';
import { PropertyBase, PropsGroupBase } from '../properties/Props';
import type { IdBimScene, SceneInstance } from '../scene/SceneInstances';
import { type BimObjects, type Properties, type Property, type SceneObject, type Transform as ApiTransform} from './BimData';
import type { UnitsMapper } from '../UnitsMapper';
import { BimProperty } from '../bimDescriptions/BimProperty';
import type { PropertiesCollection } from '../bimDescriptions/PropertiesCollection';
import { PrimitivePropertyBase } from '../properties/PrimitiveProps';
import { CustomPropsRegistry } from '../properties/CustomPropsRegistry';
import { getPropertyAllBasicValues } from '../properties/BasicPropsView';
import { Euler, Transform, Vec3One, type Matrix4 } from 'math-ts';
import { KrMath } from 'math-ts';
import type { Bim } from '..';

export interface BimApiExportOptions {
}

export function exportBimToApi(args: {
    logger: ScopedLogger,
    bim: Bim,
    options: BimApiExportOptions,
}): {
    bim_objects: BimObjects,
} {
    const serializer = new BimApiSerializer({
        logger: args.logger,
        unitsMapper: args.bim.unitsMapper,
    });

    for (const [id, inst] of args.bim.instances.perId) {
        serializer.addInstance(id, inst);
    }
    const bim_objects = serializer.finish();
    return {bim_objects};
}

export class BimApiSerializer {
 
    readonly logger: ScopedLogger;
    readonly unitsMapper: UnitsMapper;

    readonly _props_paths_dedup = new Map<string, (string|number)[]>();

    readonly legacy_props_converter: DefaultMap<BimProperty, Property>;
    readonly props_converter: DefaultMap<PropertyBase, [string, Property][]>;

    readonly _preConvertedInstances: PreConvertedInstance[] = [];

    constructor(args: {
        logger: ScopedLogger,
        unitsMapper: UnitsMapper,
    }) {
        this.logger = args.logger;
        this.unitsMapper = args.unitsMapper;

        this.legacy_props_converter = new DefaultMap<BimProperty, Property>(p => {
            if (p.isNumeric() && p.unit) {
                const converted = this.unitsMapper.mapToConfigured(p);
                return {v: converted.value, unit: converted.unit};
            } else {
                return {v: p.value};
            }
        });

        this.props_converter = new DefaultMap(p => {
            const res: [string, Property][] = [];
            if (p instanceof PrimitivePropertyBase) {

                if (typeof p.value === 'number') {
                    if ((p as any).unit) {
                        const converted = this.unitsMapper.mapToConfigured(p);
                        res.push([``, {v: converted.value, unit: converted.unit}]);
                    } else {
                        res.push([``, {v: p.value}]);
                    }
                } else if (typeof p.value === 'string' || typeof p.value === 'boolean') {
                    res.push([``, {v: p.value}]);
                } else {
                    //
                }
            } else {
                const toBasicTypesMapper = CustomPropsRegistry.tryGetBasicTypesViewForClass(p.constructor as any);
                if (toBasicTypesMapper) {
                    const asBasic = getPropertyAllBasicValues(
                        {unitsMapper: this.unitsMapper},
                        toBasicTypesMapper,
                        p
                    );
                    if (asBasic instanceof Map) {
                        for (const [key, prop] of asBasic) {
                            res.push([key, {v: prop.value, unit: prop.unit}]);
                        }
                    } else if (asBasic) {
                        res.push([``, {v: asBasic.value, unit: asBasic.unit}]);
                    }
                }
            }
            return res;
        });
    }

    _addLegacyProps(instance_type_ident: string, instance_props: PropertiesCollection, output: Map<string, Property>) {
        for (const p of instance_props.values()) {
            if (p.path.at(-1)?.startsWith("_")) {
                continue;
            }
            try {
                let mergedKey = p._mergedPath;
                let fullPath = p.path;
                if (fullPath.includes("tracker-frame")) {
                    const idx = fullPath.indexOf("tracker-frame");
                    fullPath = fullPath.slice();
                    fullPath[idx] = "tracker_frame";
                    mergedKey = BimProperty.MergedPath(fullPath);
                }
                if (!this._props_paths_dedup.has(mergedKey)) {
                    this._props_paths_dedup.set(mergedKey, fullPath);
                }
                const as_basic_property = this.legacy_props_converter.getOrCreate(p);
                output.set(mergedKey, as_basic_property);
            } catch (e) {
                this.logger.batchedError(`error converting legacy property`, e);
            }
        }
    }

    _addProps(instance_type_ident: string, path: (string|number)[], pg: PropsGroupBase, output: Map<string, Property>) {
        let versionChange = 0;
        for (const key of ObjectUtils.keysIncludingGetters(pg)) {

            if (key.startsWith("_")) {
                continue;
            }

            const field = pg[key];

            if (field instanceof PropertyBase) {
                try {
                    const converted = this.props_converter.getOrCreate(field);

                    for (const [pk, value] of converted) {
                        const fullPath = [...path, key];
                        if (pk) {
                            fullPath.push(pk);
                        }
    
                        let mergedPath = BimProperty.MergedPath(fullPath);
    
                        if (!this._props_paths_dedup.has(mergedPath)) {
                            this._props_paths_dedup.set(mergedPath, fullPath);
                        }
    
                        output.set(mergedPath, value);
                    }
                } catch(e) {
                    this.logger.batchedError(`error converting property`, e);
                }
            } else if (field instanceof PropsGroupBase) {
                this._addProps(instance_type_ident, [...path, key], field, output);

            } else if (Array.isArray(field)) {
                // TODO
            }
        }
        return versionChange;
    }

    addInstance(id: IdBimScene, instance: SceneInstance) {

        const convertedProps = new Map<string, Property>();
        this._addLegacyProps(instance.type_identifier, instance.properties, convertedProps);
        this._addProps(instance.type_identifier, [], instance.props, convertedProps);


        const inst: PreConvertedInstance = {
            id: id,
            parent_id: instance.spatialParentId,
            type_ident: instance.type_identifier,
            name: instance.name,
            props: convertedProps,
            wm: instance.worldMatrix,
        };
        this._preConvertedInstances.push(inst);
    }

    finish(): BimObjects {

        const fullPathToShortNamesRemap = new Map<string, string>();
        const uniqueShortNamesForEachString = new Map<string, (number|string)[]>();

        {
            const allPropsPaths = Array.from(this._props_paths_dedup.keys());
            allPropsPaths.sort();
            for (let pathIdx = 0; pathIdx < allPropsPaths.length; ++pathIdx) {
                const mergedPath = allPropsPaths[pathIdx];
                const fullPath = this._props_paths_dedup.get(mergedPath)!;
                const shortName = excelColumnNameFromNumber(pathIdx + 1);

                fullPathToShortNamesRemap.set(mergedPath, shortName);
                uniqueShortNamesForEachString.set(shortName, fullPath);
            }
        }

        const proeprties_array: Property[] = [];
        const propsIndicesGenerator = new DefaultMapObjectKey<Property, number>({
            unique_hash: JSON.stringify,
            valuesFactory: (p): number => proeprties_array.push(p) - 1,
        });

        const sceneObjects: SceneObject[] = [];

        for (const obj of this._preConvertedInstances) {

            const propsInds: {[key: string]: number} = {};

            for (const [mergedPath, property] of obj.props) {

                const shortPath = fullPathToShortNamesRemap.get(mergedPath);

                if (!shortPath) {
                    throw new Error(`remapping to short path expected to exist for ${mergedPath}`);
                }

                const proeprtyIndex = propsIndicesGenerator.getOrCreate(Object.freeze(property));

                propsInds[shortPath] = proeprtyIndex;
            }

            const sceneObject: SceneObject = {
                id: obj.id,
                parent_id: obj.parent_id,
                type_ident: obj.type_ident,
                name: obj.name,
                props_idxs: propsInds,
                transform: matrix4ToApiTransform(obj.wm),
            };
            sceneObjects.push(sceneObject);
        }

        const properties: Properties = {
            props_paths_map: Object.fromEntries(uniqueShortNamesForEachString),
            props_values: proeprties_array,
        };

        return {
            format_version: "0.1.0",
            properties: properties,
            scene_objects: sceneObjects,
        };
    }
}

interface PreConvertedInstance {
    id: number;
    parent_id: number;
    type_ident: string;
    name: string;
    props: Map<string, Property>;
    wm: Matrix4;
}

function excelColumnNameFromNumber(columnNumber: number): string {
    if (columnNumber <= 0) {
        throw new Error("Column number must be positive.");
    }

    let columnName: string = '';
    while (columnNumber > 0) {
        // Find remainder
        let remainder = (columnNumber - 1) % 26;
        
        // Convert to letter. 'a' is 97 in ASCII
        columnName = String.fromCharCode(97 + remainder) + columnName;
        
        // Reduce columnNumber for next iteration or if we need to go to the next letter set
        columnNumber = Math.floor((columnNumber - 1) / 26);
    }
    return columnName;
}

const transformReused = new Transform();
const eulerRotationReused = new Euler();

function matrix4ToApiTransform(m: Matrix4): ApiTransform {
    transformReused.setFromMatrix4(m);

    transformReused.scale.roundTo(0.001);
    eulerRotationReused.setFromQuaternion(transformReused.rotation);

    const rotX = KrMath.roundTo(KrMath.radToDeg(eulerRotationReused.x), 0.1);
    const rotY = KrMath.roundTo(KrMath.radToDeg(eulerRotationReused.y), 0.1);
    const rotZ = KrMath.roundTo(KrMath.radToDeg(eulerRotationReused.z), 0.1);
    
    let res: ApiTransform = {
        position: [transformReused.position.x, transformReused.position.y, transformReused.position.z],
    };

    if (rotX || rotY || rotZ) {
        res.rotation = [rotX, rotY, rotZ];
    }
    if (!transformReused.scale.equals(Vec3One)) {
        res.scale = [transformReused.scale.x, transformReused.scale.y, transformReused.scale.z];
    }

    return res;
}

