<script lang="ts">
    import type { EnergyPipelineStage, EnergyStageName } from "bim-ts";
    import ChartPortionTransition from "./ChartPortionTransition.svelte";
    import EnergyReportBar from "./EnergyReportBar.svelte";
    import { calculateBars } from "./CalculateBarChart";

    export let stages: EnergyPipelineStage[];

    export let lastEnergyLength = 0;
    export let prevEnergyLength = 0;
    export let showResultBar = false;

    $: bars = [...calculateBars(stages).values()];

    $: {
        lastEnergyLength = lastBar?.energyLength ?? 0;
    }

    $: lastBar = {
        ...bars.at(-1)!,
        lossLength: 0,
        lossLabel: "",
    };
</script>

<div class="mdc-typography--body1">
    {#each bars as bar, index}
        {#if index || !showResultBar}
            <EnergyReportBar
                name={stages[index].name}
                {bar}
                stage={stages[index]}
            />
        {:else if showResultBar}
            <EnergyReportBar name="" {bar}>
                <ChartPortionTransition
                    startEnergyLength={prevEnergyLength}
                    endEnergyLength={bar.energyLength}
                />
            </EnergyReportBar>
        {/if}
    {/each}
    {#if showResultBar && stages.length}
        <EnergyReportBar name="Annual yield" bar={lastBar} />
    {/if}
</div>

<style lang="scss">
</style>
