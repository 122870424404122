
import type {
    BimGeometryType, EntitiesBase, EntitiesBaseConstructorParams} from 'bim-ts';
import type { ObjectUniqueHashValue, ScopedLogger } from 'engine-utils-ts';
import type { EntityId } from 'verdata-ts';

import type { EngineGeoType, IdEngineGeo } from '../geometries/AllEngineGeometries';
import type { EngineGeometry } from "../geometries/EngineGeometry";
import type { GCedEntitiesCollection } from '../scene/EngineResourcesGC';
import { EntitiesBimSynced } from './EntitiesBimSynced';
import { EntitiesBimSyncedInterned } from './EntitiesBimSyncedInterned';

// export function GeometriesProvidersFromBim()


export abstract class BimEngineGeometriesSyncBase<G extends EngineGeometry, BG>
    extends EntitiesBimSynced<G, BG, EngineGeoType, BimGeometryType>
	implements GCedEntitiesCollection<EngineGeoType>
{
    constructor(
        sourceCollection: EntitiesBase<BG, BimGeometryType, any>,
        entitiesParams:  EntitiesBaseConstructorParams<G, EngineGeoType, number>
    ) {
        super(sourceCollection, entitiesParams);
        this._backwardIdCounter = this.idsProvider.upperRangeBound;

		if (entitiesParams.interner && !entitiesParams.internerIdsReserver) {
			this.logger.error('provided sharedi entities interner without overrideing ids provider, probably error');
		}
    }

    _backwardIdCounter: number;// TEMPORARY

    reserveEngineOnlyId(): IdEngineGeo {
        return this._backwardIdCounter -= 1;
    }

	peekById(id: EntityId<EngineGeoType>): Readonly<G> | undefined {
		const geo = super.peekById(id);
		return geo;
	}

    getEngineOwnedIds(result: Set<IdEngineGeo>): void {
        for (const id of this.perId.keys()) {
            if (id >= this._backwardIdCounter) {
                result.add(id)
            }
        }
    }
}

export abstract class BimEngineInternedGeometriesSyncBase<G extends EngineGeometry, BG extends object>
    extends EntitiesBimSyncedInterned<G, EngineGeoType, BG, BimGeometryType>
	implements GCedEntitiesCollection<EngineGeoType>
{

    constructor(params: {
        identifier: string;
        idsType: EngineGeoType;
        logger?: ScopedLogger;
        T_Constructor: { new(): G; };
        uniqueReducerFn: (obj: BG) => ObjectUniqueHashValue,
        engineIdsStart?: number,
        sourceCollection: EntitiesBase<BG, BimGeometryType>
    }) {
        super(params);
        this._backwardIdCounter = this.idsProvider.upperRangeBound;
    }

    _backwardIdCounter: number;// TEMPORARY
    getEngineOnlyId(): IdEngineGeo {
        return this._backwardIdCounter -= 1;
    }

    getEngineOwnedIds(result: Set<IdEngineGeo>): void {
        for (const [id, _] of this.perId) {
            if (id >= this._backwardIdCounter) {
                result.add(id)
            }
        }
    }
}



