import type { PropValueTotalHash} from "src";
import { PropertyBase, SubstationProps, SubstationTypeIdent } from "src";
import { SolverObjectInstance } from "src/runtime/SolverObjectInstance";

export class SubstationSLDViewerProperty extends PropertyBase {
    uniqueValueHash(): PropValueTotalHash {
        return 0;
    }
    equals(): boolean {
        return false;
    }
}

export function registerSubstationSLDRuntime() {
    return new SolverObjectInstance({
        solverIdentifier: 'attach-substation-sld-viewer-property',
        objectsDefaultArgs: {
            propsInOut: new SubstationProps({})
        },
        objectsIdentifier: SubstationTypeIdent,
        solverFunction: (props) => {
            props.propsInOut.singleLineDiagramViewer = new SubstationSLDViewerProperty()
            return {}
        }
    });
}
