export function createMultiClickHandler(params: {
    onClick?: () => void,
    on2Click?: () => void
    click2MaxDurationMs?: number
}) {
    let prevClickMs = 0;
    let singleClickTimeout: NodeJS.Timeout;
    const doubleClickMaxDurationMs = params.click2MaxDurationMs ?? 300;
    function onItemBodyClick() {
        clearTimeout(singleClickTimeout);

        const now = Date.now();
        const elapsed = now - prevClickMs;

        // double click
        if (elapsed < doubleClickMaxDurationMs) {
            params.on2Click?.();
            prevClickMs = 0;
        } else {
            // single click
            singleClickTimeout = setTimeout(() => {
                params.onClick?.();
            }, doubleClickMaxDurationMs)
            prevClickMs = now;
        }

    }
    return onItemBodyClick;
}
