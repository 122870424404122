
export enum AttrFlag{
    Default = 0,
    Invisible = 1, //does not appear
    Constan = 2,
    Verification = 3,
    Preset = 4, //(no prompt during inserti

}

export enum BlockTypeFlag{
    SimpleBlock = 0, 
    AnonymousBlock = 1,
    BlockHasAttributes = 2,
    BlockIsExref = 4,
    NotUsed = 8,
    ExternallyDependent = 16,
    ResolvedExref = 32,
    DefinitionReferenced = 64
}

export enum HorizontalJustification{
    Left = 0,
    Center = 1,
    Right = 2
}

export enum VerticalJustification{
    Top = 0,
    Middle = 1,
    Bottom = 2,
    Baseline = 3
}

export enum LengthUnits{

Scientific = 1,
Decimal,
Engineering,
Architectural,
Fractional,

}

export enum InsertUnits {
    Unitless = 0,
    Inches,
    Feet,
    Miles,
    Millimeters,
    Centimeters,
    Meters,
    Kilometers,
    Microinches,
    Mils,
    Yards,
    Angstroms,
    Nanometers,
    Microns,
    Decimeters,
    Decameters,
    Hectometers,
    Gigameters,
    Astronomical_units,
    Light_years,
    Parsecs,
    US_Survey_Feet
}