import type { CombinerBoxConnectionType } from "src/sld/templates/module-table";
import type { ModuleTableDescription } from "../../types";
import { EquipmentDescription } from "../../types";
import type { NumberProperty } from "src";

export class CombinerBoxDescription extends EquipmentDescription {
    moduleTables: ModuleTableDescription[]
    connectionType: CombinerBoxConnectionType
    outputCurrent?: NumberProperty

    constructor(params: {
        moduleTables: ModuleTableDescription[],
        connectionType: CombinerBoxConnectionType,
        outputCurrent?: NumberProperty,
    } & ConstructorParameters<typeof EquipmentDescription>[0]) {
        super(params);
        this.moduleTables = params.moduleTables;
        this.connectionType = params.connectionType;
        this.outputCurrent = params.outputCurrent;
    }

    isLooseEqual(other: CombinerBoxDescription): boolean {
        if (!super.isLooseEqual(other)) {
            return false;
        }
        if (this.connectionType !== other.connectionType) {
            return false;
        }
        if (!this.outputCurrent !== !other.outputCurrent) {
            return false;
        }
        if (this.outputCurrent && other.outputCurrent) {
            if (!this.outputCurrent.equals(other.outputCurrent)) {
                return false;
            }
        }
        return true;
    }
}

export class InverterDescription extends EquipmentDescription {
    combinerBoxes: CombinerBoxDescription[][]

    constructor(params: {
        combinerBoxes: CombinerBoxDescription[][],
    } & ConstructorParameters<typeof EquipmentDescription>[0]) {
        super(params);
        this.combinerBoxes = params.combinerBoxes;
    }
}

export class TransformerDescription extends EquipmentDescription {
    inverters: InverterDescription[][]

    constructor(params: {
        inverters: InverterDescription[][],
    } & ConstructorParameters<typeof EquipmentDescription>[0]) {
        super(params);
        this.inverters = params.inverters;
    }
}
