import type {
	Bim} from 'bim-ts';
import type { UndoStack } from 'engine-utils-ts';

import type { EditModeControls } from './EditControls';
import { InteractiveBoundaryAdderBase } from './InteractiveBoundaryBase';

export class InteractiveBoundaryAdder extends InteractiveBoundaryAdderBase {
    readonly priority: number = 0;

    constructor(
        readonly bim: Bim,
        readonly editControls: EditModeControls,
        undoStack: UndoStack,
    ) {
        super(bim, editControls, undoStack, ['Add', 'Boundary'], 'boundary');
    }
}
