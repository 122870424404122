import { Builder, ByteBuffer } from "flatbuffers";
import type { ObjectsPickingSerializer } from "verdata-ts";
import { dateFromFlatbufLong, dateToFlatbufLong } from "verdata-ts";
import { AssetCollection } from "../schema/asset-collection";
import { Asset as AssetFB } from '../schema/asset'
import { Asset } from "./Asset";
import { Source as SourceFB } from "../schema/source";
import { CatalogSource } from "./Source";

export class AssetsSerializer implements ObjectsPickingSerializer<Asset> {

    serialize(objects: [number, Asset][]): Uint8Array {
        const builder = new Builder();
        const idsOffsetVector =
            AssetCollection.createIdsVector(builder, objects.map(([id]) => id));
        const assetsOffset: number[] = []
        for (const [_id, asset] of objects) {
            // construct asset's dependencies
            const source = buildSourceFB(builder, asset.source);
            const name = builder.createString(asset.name);
            const bimasset = AssetFB.createBimassetVector(builder, asset.bimasset);

            // construct asset
            AssetFB.startAsset(builder);
            AssetFB.addName(builder, name);
            AssetFB.addSource(builder, source);
            AssetFB.addBimasset(builder, bimasset);
            AssetFB.addCreatedAt(builder, dateToFlatbufLong(asset.createdAt));
            AssetFB.addUpdatedAt(builder, dateToFlatbufLong(asset.updatedAt));
            const offset = AssetFB.endAsset(builder);

            assetsOffset.push(offset);
        }
        const assetsOffsetVector =
            AssetCollection.createCollectionVector(builder, assetsOffset);
        const assetCollectionOffset = AssetCollection.createAssetCollection(
            builder, 0, idsOffsetVector, assetsOffsetVector
        );
        builder.finish(assetCollectionOffset);
        return builder.asUint8Array().slice();
    }

    deserialize(buffer: Uint8Array, idsToDeserialize: Set<number>): [number, Asset][] {
        const assetsCollectionEncoded = AssetCollection
            .getRootAsAssetCollection(new ByteBuffer(buffer));
        const result: [number, Asset][] = [];
        for (let i = 0; i < assetsCollectionEncoded.idsLength(); i++) {
            const id = assetsCollectionEncoded.ids(i);
            if (id === null || !idsToDeserialize.has(id)) {
                continue;
            }
            const assetEncoded = assetsCollectionEncoded.collection(i);
            if (assetEncoded === null) {
                continue;
            }
            const source = parseSourceFB(assetEncoded.source() ?? undefined);
            const createdAt = dateFromFlatbufLong(assetEncoded.createdAt());
            const updatedAt = dateFromFlatbufLong(assetEncoded.updatedAt());

            const asset = new Asset(
                assetEncoded.name() ?? undefined,
                source,
                assetEncoded.bimassetArray() ?? undefined,
                createdAt,
                updatedAt,
            )
            result.push([id, asset])
        }
        return result;
    }

}

export function buildSourceFB(builder: Builder, source: CatalogSource): number {
    return SourceFB.createSource(
        builder,
        builder.createString(source.type),
        builder.createString(source.subject),
    );
}

export function parseSourceFB(encoded?: SourceFB): CatalogSource {
    const source = !encoded
        ? new CatalogSource()
        : new CatalogSource(
            encoded.type() ?? undefined,
            encoded.subject() ?? undefined,
        );
    return source;
}
