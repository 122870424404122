import type {
    AllSiteArea,
    AssetCatalogItemProps,
    Bim,
    BlockEquipment,
    Catalog,
    CatalogPropertyValue,
    ConfigPatch,
    IdBimScene,
    NumberPropertyWithOptionsContext,
    PropertyGroup,
    SceneInstance,
    SiteArea,
    SiteAreaSettings,
    SiteSubarea,
    UnallocatedSubarea,
    ValueRenderFormatter
, DC_CNSTS, FarmLayoutConfig, IdConfig,
    PropertyBase} from 'bim-ts';
import {
    BooleanProperty, BoundaryType, CatalogItemsReferenceProperty,
    createPropertyGroupPuiTransformers, FarmLayoutConfigType, getDefaultSubarea, NumberProperty,
    NumberPropertyWithOptions, NumberRangeProperty, SceneInstancesProperty, StringProperty
} from 'bim-ts';
import {
    convertUnits, DefaultMap, Failure, Immer, InProgress, IterUtils, LazyBasic, LazyDerived, LazyDerivedAsync,
    ObjectUtils, ScopedLogger, StringUtils, Success, Yield
} from 'engine-utils-ts';
import { Aabb2, Clipper, KrMath, PolygonUtils } from 'math-ts';
import {
    buildPuiFromObject, ContextMenuConfig, EditActionResult, PUI_Builder,
    PUI_ConfigBasedBuilderParams, PUI_CustomGroupNode, PUI_CustomGroupNodeChildren,
    PUI_CustomPropertyNode, PUI_GroupNode, PUI_Lazy, PUI_PropertyNodeNumber, PUI_PropertyNodeString,
    PUI_SceneInstancesSelectorPropertyNode
} from 'ui-bindings';

import { orderContour, orderContours, orderHoles } from '../LayoutUtils';
import { createContext, patchObject } from '../panels-config-ui/GeneratePanelUiBindings';
import { addProperty } from '../panels-config-ui/PropertyBuilders';
import { getBoundariesLazyList } from './EquipmentBoundariesCreator';
import { BlockProperties } from './FarmLayoutMetrics';
import { createFarmLayoutSettingsPath } from './FarmLayoutUi';
import { FarmLayoutValidation } from './FarmLayoutValidation';
import { SelectLayoutGenerationMode } from './SelectLayoutGenerationMode';
import { createSolarArraysUi } from './SolarArraysView';

import type { LazyVersioned , ResultAsync} from 'engine-utils-ts';
import type { Vector2 } from 'math-ts';
import type {
    ItemErrorMsg,
    PUI_Node, SceneInstancesSelectorValue, UiBindings
} from 'ui-bindings';
import type { PropertyPath } from '../panels-config-ui/PropertyBuilders';
import type { ZoneBoundaryProps } from './EquipmentBoundariesCreator';
import type { InverterProperties, LayoutProperties, SiteAreaProperties, TransformerProperties } from './FarmLayoutMetrics';
import type { InvertersGroup } from './ILRPropertyConfig';

export interface FarmLayoutViewsConfigsCreator {
    createBlockSettingsUi: (pos: Vector2) => ContextMenuConfig;
    createRotationUi: (pos: Vector2) => ContextMenuConfig;
    createOffsetsUi: (pos: Vector2) => ContextMenuConfig;
    createShiftsUi: (pos: Vector2) => ContextMenuConfig;
    createRoadsUi: (pos: Vector2) => ContextMenuConfig;
    createSelectedAreaUi: (pos: Vector2) => ContextMenuConfig;
    createSolarArraysUi: (pos: Vector2) => ContextMenuConfig;
    createSelectModeUi: (pos: Vector2) => ContextMenuConfig;
}

export function getFarmLayoutViews(
    bim: Bim, 
    catalog: Catalog, 
    ui: UiBindings, 
    siteAreaData: LazyVersioned<ZoneBoundaryProps[]>,
    lazyMetrics: LazyDerived<Map<IdBimScene, LayoutProperties>>, 
):FarmLayoutViewsConfigsCreator {
    const logger = new ScopedLogger("farmlayout-views");
    const lazyConfig = createSelectedConfigLazy(bim, logger);
    const createRoads:(pos: Vector2) => ContextMenuConfig = (pos: Vector2) => createRoadsUi(bim, logger.newScope('roads'), lazyConfig, pos);
    const createBlocksPui: (pos: Vector2) => ContextMenuConfig = (pos: Vector2) => createBlockSettingsUi(bim, catalog, logger.newScope('blocks'), lazyConfig, lazyMetrics, pos);
    const views = {
        createBlockSettingsUi: createBlocksPui,
        createRotationUi: (pos: Vector2) => createRotationUi(bim, logger.newScope('rotation'), lazyConfig, pos),
        createOffsetsUi: (pos: Vector2) =>
            createOffsetsUi(
                logger.newScope('offsets'),
                lazyConfig,
                pos,
                (props) => {
                    const farmConfig = lazyConfig.poll();
                    if (!farmConfig) {
                        return;
                    }
                    bim.configs.applyPatches([
                        [farmConfig.id, { properties: props }],
                    ]);
                },
            ),
        createShiftsUi: (pos: Vector2) => createShiftsUi(bim, logger.newScope('shift'), lazyConfig, pos),
        createRoadsUi: (pos: Vector2) => createRoads(pos),
        createSelectedAreaUi: (pos: Vector2) => createSelectedAreaUi(bim, logger.newScope('selected-area'), lazyConfig, pos, siteAreaData),
        createSolarArraysUi: (pos: Vector2) => createSolarArraysUi(bim, logger.newScope('solarArray'), lazyConfig, pos),
        createSelectModeUi: (pos: Vector2) => createSelectModeUi(bim, logger.newScope("select-gen-mode"), lazyConfig, pos, createRoads, createBlocksPui),
    };
    return views;
}

export function createILRContextViewUi(
    catalog: Catalog,
    layoutMetrics : SiteAreaProperties | undefined,
    block: BlockEquipment,
    index: number,
    ): [InvertersGroup[] | undefined, BlockProperties[] | undefined] {

        function getAssetModel(catalogValue: CatalogPropertyValue){
            let assetId: number = 0;
            if(catalogValue.type === 'catalog_item'){
                const item = catalog.catalogItems.peekById(catalogValue.id);
                assetId = item?.as<AssetCatalogItemProps>().properties?.asset_id?.value ?? 0;
            } else {
                assetId = catalogValue.id;
            }
            return catalog.assets.sceneInstancePerAsset
                .getAssetAsSceneInstance(assetId)?.properties.get('commercial | model')?.asText() ?? '';
        }

        let inverterModel = "";
        if(layoutMetrics && block.inverter.value.length > 0){
            inverterModel = getAssetModel(block.inverter.value[0]);
        }

        let transformerModel = "";
        if(layoutMetrics && block.transformer.value.length > 0){
            transformerModel = getAssetModel(block.transformer.value[0]);
        }

        const blockProperties = layoutMetrics?.blocks.get(BlockProperties.createBlockId(index, transformerModel, inverterModel));
        const invertersInBlock: InverterProperties[] = [];
        let transformer: TransformerProperties | undefined;
        if(blockProperties){
            for (const block of blockProperties) {
                for (const inv of block.inverters) {
                    invertersInBlock.push(inv);
                }
                transformer = block.transformer;
            }
        }

        let invertersGroups: InvertersGroup[]|undefined = undefined;
        invertersGroups = invertersInBlock.length > 0 
            ? [{label: `${block.label.value} ${transformer!.powerKw} kW - ${layoutMetrics?.areaName ?? ""}`, inverters: invertersInBlock, minMax: block.ilr_range.value}] 
            : undefined;
        // console.log(invertersGroups, blockProperties);
        
    return [invertersGroups, blockProperties];
}

export function createILRContextViewUiForBlocks(
    catalog: Catalog,
    metrics: SiteAreaProperties | undefined,
    blocks: BlockEquipment[],
    minMax: [number, number],
) {
    const result: InvertersGroup[] = [];
    if(!metrics){
        return result;
    }
    const invertersIds = new Set<number>();
    for (let index = 0; index < blocks.length; index++) {
        const block = blocks[index];
        const [groups] = createILRContextViewUi(
            catalog,
            metrics,
            block,
            index
        );
        if (groups) {
            for (const group of groups) {
                for (const inv of group.inverters) {
                    invertersIds.add(inv.id);
                }
            }
            result.push(...groups);
        }
    }
    const inverters = new DefaultMap<string, InverterProperties[]>(()=>[]);
    for (const blockSProps of metrics.blocks.values()) {
        for (const blockProp of blockSProps) {
            for (const inv of blockProp.inverters) {
                if(!invertersIds.has(inv.id)){
                    const group = inverters.getOrCreate(inv.model);
                    group.push(inv);
                }
            }
        }
    }
    for (const [label, invs] of inverters) {
        result.push({
            label: label,
            minMax: minMax,
            inverters: invs,
        });
    }

    return result;
}

function createBlockSettingsUi(
    bim: Bim, 
    catalog: Catalog,
    logger: ScopedLogger,
    configLazy: LazyDerived<FarmConfigProperties>,
    lazyMetrics: LazyDerived<Map<IdBimScene, LayoutProperties>>, 
    pos: Vector2
): ContextMenuConfig {
    const identity = 'farm-layout-block-settings-ui';

    const patchConfig = (properties: PropertyGroup) => {
        const farmConfig = configLazy.poll();
        if(farmConfig){
            bim.configs.applyPatches([[farmConfig.id, {properties}]])
        }else {
            logger.error('Config not patched', properties);
        }
    }

    const electricalPuiLazy = createElectricalUi(
        catalog,
        logger,
        configLazy,
        (props) => {
            patchConfig(props);
        },
    );

    const blockPuiLazy = createBlocksPui({
            configLazy, 
            logger, 
            catalog, 
            onChange: (idx, path, newValue) => {
                const farmConfig = configLazy.poll();
                logger.debug('update', path, newValue);
                if (!farmConfig) {
                    return;
                }
                const updatedConfig = Immer.produce(
                    farmConfig.config,
                    (draft) => {
                        const siteArea = draft.site_areas[farmConfig.selectedArea];
                        siteArea.settings.electrical.blocks_equipment[idx][path] = newValue;
                    }
                );
                patchConfig(updatedConfig);
            }, 
            addNew: (block) => {
                const farmConfig = configLazy.poll();
                logger.debug('update block');
                if (!farmConfig) {
                    return;
                }
                const updatedConfig = Immer.produce(
                    farmConfig.config,
                    (draft) => {
                        const siteArea = draft.site_areas[farmConfig.selectedArea];
                        siteArea.settings.electrical.blocks_equipment.push(
                            block
                        );
                    }
                );
                patchConfig(updatedConfig);
            }, 
            remove: (idx) => {
                const farmConfig = configLazy.poll();
                logger.debug('remove block', idx);

                if (!farmConfig) {
                    return;
                }
                const updatedConfig = Immer.produce(
                    farmConfig.config,
                    (draft) => {
                        const siteArea = draft.site_areas[farmConfig.selectedArea];
                        siteArea.settings.electrical.blocks_equipment =
                            siteArea.settings.electrical.blocks_equipment.filter(
                                (_, i) => i != idx
                            );
                        const blocks = siteArea.settings.electrical.blocks_equipment;
                        if(blocks.length === 1) {
                            const block = blocks[0];
                            siteArea.settings.capacity.ilr_range = siteArea.settings.capacity.ilr_range.withDifferentValue(block.ilr_range.value);
                        } else if(blocks.length > 1) {
                            const min = IterUtils.min(blocks.map(b => b.ilr_range.value[0])) ?? siteArea.settings.capacity.ilr_range.value[0];
                            const max = IterUtils.max(blocks.map(b => b.ilr_range.value[1])) ?? siteArea.settings.capacity.ilr_range.value[1];
                            siteArea.settings.capacity.ilr_range = siteArea.settings.capacity.ilr_range.withDifferentValue([
                                Math.min(min, siteArea.settings.capacity.ilr_range.value[0]),
                                Math.max(max, siteArea.settings.capacity.ilr_range.value[1])
                            ]);
                        }
                    }
                );
                patchConfig(updatedConfig);
            }, 
            createIlrContext: (block, index) => {
                const metrics = lazyMetrics.poll();
                const farmConfig = configLazy.poll();
                const layoutMetricsByArea = metrics.get(farmConfig?.connectedTo ?? 0);
                const layoutMetrics = farmConfig?.selectedArea !== undefined
                    ? layoutMetricsByArea?.perZone[farmConfig?.selectedArea]
                    : undefined;
                const equipment = createILRContextViewUi(catalog, layoutMetrics, block, index);
                return equipment;
            }, 
            updateConfig: (props) => {
                patchConfig(props);
            }
        },
    );

    const puiLazy = LazyDerived.new2(
        "blocks-settings-pui", 
        null, 
        [electricalPuiLazy, blockPuiLazy], 
        ([electricalPui, blocksPui]) => {
        const builder = new PUI_Builder({});
        for (const child of electricalPui.children.values()) {
            builder.addChild(child);
        }
        for (const child of blocksPui.children.values()) {
            builder.addChild(child);
        }
        return builder.finish();
    }).withoutEqCheck();


    return new ContextMenuConfig({
        identity, 
        viewSource: new PUI_Lazy(puiLazy), 
        action: { name: 'Ok' }, 
        positionPx: pos,
        header: 'Blocks equipment',
    });
}

function createRotationUi(bim: Bim, logger: ScopedLogger, lazyConfig: LazyDerived<FarmConfigProperties>, pos: Vector2): ContextMenuConfig{
    const identity = 'farm-layout-rotation-ui';
    const puiLazy = LazyDerived.new1<
        PUI_GroupNode,
        FarmConfigProperties
    >(identity, null, [lazyConfig], ([farmConfig]) => {
        const builder = new PUI_Builder({});
        if(farmConfig){
            addProperty({
                    logger, 
                    config: farmConfig.config, 
                    patch: (props) => {
                        bim.configs.applyPatches([
                            [farmConfig.id, { properties: props }],
                       ]);
                    }, 
                    builder, 
                    path: createFarmLayoutSettingsPath(farmConfig.selectedArea, ["rotation", "angle"]), sortKey: 1
            });
        }
        const puiRoot = builder.finish();
        return puiRoot;
    }).withoutEqCheck();

    return new ContextMenuConfig({
        identity, 
        viewSource: new PUI_Lazy(puiLazy), 
        action: { name: 'Ok' }, 
        positionPx: pos, 
        header: "Rotation",
    });
}


export function createOffsetsUi<T>(
    logger: ScopedLogger,
    lazyConfig: LazyVersioned<FarmConfigProperties>,
    pos: Vector2,
    patch: (props: PropertyGroup) => void,
): ContextMenuConfig {
    const identity = 'farm-layout-offsets-ui';
    const puiLazy = LazyDerived.new1<
        PUI_GroupNode,
        FarmConfigProperties
    >(identity, null, [lazyConfig], ([farmConfig]) => {
        const builder = new PUI_Builder({});
        if(farmConfig){
            let sortKey = 0;
            const addProp = (path: PropertyPath, name?: string)=> {
                addProperty(
                    {
                        logger,
                        config: farmConfig.config,
                        patch,
                        builder,
                        path,
                        sortKey: ++sortKey,
                        name
                    }
                ); 
            };
            const addDivider = (name: string) =>{
                builder.addCustomProp({
                    name,
                    context: {},
                    value: {},
                    onChange: () => {},
                    type_ident: "divider",
                    typeSortKeyOverride: ++sortKey,
                });
            }
            const mainPath = createFarmLayoutSettingsPath(farmConfig.selectedArea, ['offsets']);
            addProp([...mainPath, "tracker_offset"], 'Array offset');
            addProp([...mainPath, "block_offset"]);
            const roadsPath = createFarmLayoutSettingsPath(farmConfig.selectedArea, ['spacing']);
            addDivider("glass-to-glass-beginning-divider");
            addProp([...roadsPath, "equipment_glass_to_glass"], "Equipment road glass to glass");
            addProp([...roadsPath, "support_glass_to_glass"], "Support road glass to glass");
            addDivider("glass-to-glass-end-divider");
            addProp([...mainPath, "transformer_offset"]);
            
            addProp([...mainPath, "inverter_offset"]);
            addProp([...mainPath, "combiner_box_offset"]);
        }

        const puiRoot = builder.finish();
        return puiRoot;
    }).withoutEqCheck();

    return new ContextMenuConfig({
        identity, 
        viewSource: new PUI_Lazy(puiLazy), 
        action: { name: 'Ok' },
        positionPx: pos,
        header: "Offsets",
        widthPx: 460,
    });
}

function createShiftsUi(bim: Bim, logger: ScopedLogger, lazyConfig: LazyDerived<FarmConfigProperties>, pos: Vector2 ):ContextMenuConfig{
    const identity = 'farm-layout-shift-ui';
    const puiLazy = LazyDerived.new1<
        PUI_GroupNode,
        FarmConfigProperties
    >(identity, null, [lazyConfig], ([farmConfig]) => {
        const builder = new PUI_Builder({});
        if(farmConfig){
            let sortKey = 0;
            const addProp = (path: PropertyPath, name?: string) => {
                addProperty({
                        logger, 
                        config: farmConfig.config, 
                        patch: (props) => {
                            bim.configs.applyPatches([
                                [farmConfig.id, { properties: props }],
                            ]);
                        }, 
                        builder, 
                        path, 
                        sortKey: ++sortKey, 
                        name
                    });
            };
            const mainPath = createFarmLayoutSettingsPath(farmConfig.selectedArea, ['shift']);
            addProp([...mainPath, "auto_shift_detection"]);
            addProp([...mainPath, "shift"]);
        }

        const puiRoot = builder.finish();
        return puiRoot;
    }).withoutEqCheck();

    return new ContextMenuConfig({
        identity, 
        viewSource: new PUI_Lazy(puiLazy), 
        action: { name: 'Ok' }, 
        positionPx: pos, 
        header: "Shift",
    });
}


function createElectricalUi(
    catalog: Catalog,
    logger: ScopedLogger, 
    lazyConfig: LazyVersioned<FarmConfigProperties>, 
    patch: (props: PropertyGroup) => void,
): LazyDerived<PUI_GroupNode>{
    const identity = 'farm-layout-electrical-ui';
    const puiLazy = LazyDerived.new1<
        PUI_GroupNode,
        FarmConfigProperties
    >(
        identity, 
        null, 
        [lazyConfig],
        ([farmConfig]) => {
        const builder = new PUI_Builder({});
        if(farmConfig) {
            let sortKey = 0;
            function getNextSortKey(){
                return ++sortKey;
            }
            const addProp = (path: PropertyPath, name?: string) => {
                addProperty(
                    {
                        logger, config: farmConfig.config, patch,
                        builder, path, sortKey: getNextSortKey(), name
                    }
                );
            };
            const addError = (name: string, msg: string) => {
                builder.addCustomProp({
                    name: name,
                    value: msg,
                    context: {},
                    onChange: () => {},
                    type_ident: 'error_message',
                    typeSortKeyOverride: getNextSortKey(),
                });
            }
            const mainPath = createFarmLayoutSettingsPath(farmConfig.selectedArea, ['electrical']);

            addProp([...mainPath, "scheme"]);
            addProp([...mainPath, "nec_multiplier"]);
            addCombinerBoxProperty({
                builder,
                prop: farmConfig.settings.electrical.combiner_box,
                catalog,
                scheme: farmConfig.settings.electrical.scheme.value,
                sortKey: getNextSortKey(),
                addError: addError,
                patch: (newProp) => {
                    patchObject(farmConfig.config, [...mainPath, "combiner_box"], newProp, patch);
                }
            });
        }

        const puiRoot = builder.finish();
        return puiRoot;
    }).withoutEqCheck();

    return puiLazy;
}

export function addCombinerBoxProperty({
    builder,
    sortKey,
    prop,
    catalog,
    patch,
    addError,
    scheme,
}: {
    builder: PUI_Builder;
    prop: CatalogItemsReferenceProperty;
    sortKey: number;
    catalog: Catalog;
    patch: (newProp: CatalogItemsReferenceProperty) => void;
    addError: (name: string, msg: string) => void;
    scheme: string;
}) {
    builder.addCustomProp({
        name: StringUtils.capitalizeFirstLatterInWord("combiner_box"),
        typeSortKeyOverride: sortKey,
        value: prop,
        type_ident: "catalog-items-selector",
        context: {
            doubleLine: true,
            formatter: (assetSi: SceneInstance) => ({
                group: assetSi.properties.get("commercial | type")?.asText(),
                name: [
                    assetSi.properties.get("commercial | model")?.asText(),
                    "\n",
                    assetSi.properties
                        .get("input | current")
                        ?.valueUnitUiString(catalog.unitsMapper),
                    assetSi.properties
                        .get("input | voltage")
                        ?.valueUnitUiString(catalog.unitsMapper),
                ].join(" "),
            }),
        },
        onChange: (value) => {
            const newProp = value.withDifferentValue(value.value);
            patch(newProp);
        },
    });
    const checkCombinerBox = FarmLayoutValidation.checkCombinerBoxType(
        scheme,
        prop.value,
        catalog
    );
    if (checkCombinerBox) {
        addError("combiner-box-type-error", checkCombinerBox);
    }
}

function createRoadsUi(bim: Bim, logger: ScopedLogger, lazyConfig: LazyDerived<FarmConfigProperties>, pos: Vector2):ContextMenuConfig{
    const identity = 'farm-layout-roads-ui';
    const puiLazy = LazyDerived.new1<
        PUI_GroupNode,
        FarmConfigProperties
    >(identity, null, [lazyConfig], ([farmConfig]) => {
        const builder = new PUI_Builder({});
        if(farmConfig){
            let sortKey = 0;
            const updateConfig = (props: PropertyGroup) => {
                bim.configs.applyPatches([[farmConfig.id, { properties: props }]]);
            }
            const addProp = (path: PropertyPath, name?: string | undefined, readonly?: boolean) => {
                addProperty({
                    logger, 
                    config: farmConfig.config, 
                    patch: (props) => {
                        updateConfig(props);
                    }, 
                    builder, 
                    path, 
                    sortKey: ++sortKey, 
                    name, 
                    readonly
                });
            };
            const addDivider = (name: string) =>{
                builder.addCustomProp({
                    name,
                    context: {},
                    value: {},
                    onChange: () => {},
                    type_ident: "divider",
                    typeSortKeyOverride: ++sortKey,
                });
            }
            const mainPath = createFarmLayoutSettingsPath(farmConfig.selectedArea, ['roads']);
            const equipmentOptions = farmConfig.settings.roads.equipment_roads_options;

            const isSelectExistedOpt = (v: string | number) => v === 'Select existing';
            const isGenerateRoadOpt = (v: string) => v.startsWith('Generate');
            const isIgnoreRoadOpt = (v: string) => v === ('Ignore');
            const tooltip = "Options to generate layout with the target DC capacity or specific number of blocks are not available with the existing roads selected for layout generation.";
            const isGenEquipmentRoads = isGenerateRoadOpt(equipmentOptions.value);
            builder.addSwitcherProp({
                name: 'Equipment roads',
                onChange: (v) => {
                    const updatedProps = Immer.produce(farmConfig.config, draft => {
                        const draftSettings = draft.site_areas[farmConfig.selectedArea].settings;
                        const draftRoadsSettings = draftSettings.roads;
                        const prop = draftRoadsSettings.equipment_roads_options;
                        draftRoadsSettings.equipment_roads_options = prop.withDifferentValue(v as string);
                        if(isSelectExistedOpt(v)){
                            draftRoadsSettings.support_roads_options = 
                                draftRoadsSettings.support_roads_options.withDifferentValue(v as string);
                            draftSettings.spacing.align_arrays = draftSettings.spacing.align_arrays.withDifferentValue(true);
                        }
                        if(draftSettings.capacity.total_dc_power.selectedOption === "target") {
                            draftSettings.capacity.total_dc_power = draftSettings.capacity.total_dc_power.withDifferentOption("find max");
                        }
                        if(draftSettings.capacity.number_of_blocks.selectedOption === "set"){
                            draftSettings.capacity.number_of_blocks = draftSettings.capacity.number_of_blocks.withDifferentOption("auto");
                            for (const block of draftSettings.electrical.blocks_equipment) {
                                block.number_of_inverters = block.number_of_inverters.withDifferentOption("auto");
                            }
                        }
                    });
                    updateConfig(updatedProps);
                },
                value: equipmentOptions.value,
                options: equipmentOptions.options.map(o => ({
                    value: o, 
                    label: o, 
                    tooltip: isSelectExistedOpt(o) ? tooltip : undefined,
                })),
                typeSortKeyOverride: ++sortKey,
            });
            addProp([...mainPath, "equipment_road_width"], 'Generated roads width', !isGenEquipmentRoads);
            addProp(createFarmLayoutSettingsPath(farmConfig.selectedArea, ['spacing', 'equipment_glass_to_glass']), 'Glass to glass');
            addProp([...mainPath, "transformer_roadside"], undefined, farmConfig.settings.capacity.number_of_blocks.selectedOption === 'ignore' || !isGenEquipmentRoads);
            const roadAngle = NumberPropertyWithOptions.new({
                ...farmConfig.settings.roads.equipment_roads_angle,
                isReadonly: !isGenEquipmentRoads,
            });
            builder.addCustomProp<NumberPropertyWithOptions, NumberPropertyWithOptionsContext<any>>({
                name: "Equipment roads rotation",
                type_ident: "number-property-with-options",
                value: roadAngle,
                typeSortKeyOverride: ++sortKey,
                context: {
                    valueRenderFormatter: {
                        context: {},
                        formatter: (args) => { 
                            const decimals = roadAngle.decimals;
                            const valAsStr = args.value.toFixed(decimals);
                            return args.option === 'set' ? `${valAsStr} ${args.unit}` : ``
                        },
                        isReadonly: (args) => args.option === 'auto',
                    }
                },
                onChange: (v) => { 
                    const updatedProps = Immer.produce(farmConfig.config, draft => {
                        draft.site_areas[farmConfig.selectedArea].settings.roads.equipment_roads_angle = v;
                    });
                    updateConfig(updatedProps);
                },
                readonly: !isGenEquipmentRoads
            });
            addProp([...mainPath, "tracker_angle"], "Solar array rotation");

            builder.addNumberProp({
                name: 'Row height',
                value: farmConfig.settings.spacing.max_row_height.value,
                unit: farmConfig.settings.spacing.max_row_height.unit,
                step: farmConfig.settings.spacing.max_row_height.step,
                minMax: farmConfig.settings.spacing.max_row_height.range,
                onChange: (v) => {
                    const updatedProps = Immer.produce(farmConfig.config, draft => {
                        draft.site_areas[farmConfig.selectedArea].settings.spacing.max_row_height = 
                        farmConfig.settings.spacing.max_row_height.withDifferentValue(v);
                    });
                    updateConfig(updatedProps);
                },
                typeSortKeyOverride: ++sortKey,
                readonly: farmConfig.settings.spacing.max_row_height.isReadonly || !isGenEquipmentRoads,
                tag: new LazyBasic("net-balance", "Arrays from the roadside, max"),
            });
            addDivider('equipment-roads-end');


            const supportOptionsProp = farmConfig.settings.roads.support_roads_options;
            const isGenSupportRoads = isGenerateRoadOpt(supportOptionsProp.value);
            const isIgnoreSupportRoads = isIgnoreRoadOpt(supportOptionsProp.value);
            const matchBetweenBoundaries = farmConfig.settings.roads.global_ns_roads;

            builder.addSwitcherProp({
                name: 'Support roads',
                onChange: (v) => {
                    const updatedProps = Immer.produce(farmConfig.config, draft => {
                        const draftRoadsSettings = draft.site_areas[farmConfig.selectedArea].settings.roads;
                        const prop = draftRoadsSettings.support_roads_options;
                        draftRoadsSettings.support_roads_options = prop.withDifferentValue(v as string);

                    });
                    updateConfig(updatedProps);
                },
                value: supportOptionsProp.value,
                options: supportOptionsProp.options.map(o => ({
                    value: o, 
                    label: o, 
                    disabled: !isSelectExistedOpt(o) && isSelectExistedOpt(equipmentOptions.value),
                    option: isGenerateRoadOpt(o) 
                        ? {
                            label: 'Match between boundaries', 
                            value: matchBetweenBoundaries.value, 
                            onClick: (newV) => {
                                const updatedProps = Immer.produce(farmConfig.config, draft => {
                                    const roads = draft.site_areas[farmConfig.selectedArea].settings.roads;
                                    roads.global_ns_roads = roads.global_ns_roads.withDifferentValue(newV);
                                });
                                updateConfig(updatedProps);
                            }
                        }
                        : undefined
                })),
                typeSortKeyOverride: ++sortKey,
            });
            
            addProp([...mainPath, "support_road_width"], 'Generated road width ', !isGenSupportRoads);
            addProp(createFarmLayoutSettingsPath(farmConfig.selectedArea, ['spacing', 'support_glass_to_glass']), 'Glass to glass ', isIgnoreSupportRoads);

            addProp([...mainPath, "roads_step"], 'max_step', !isGenSupportRoads);
        

            addDivider('end-line');
            if (isSelectExistedOpt(equipmentOptions.value) || isSelectExistedOpt(supportOptionsProp.value)) {
                const roads = farmConfig.settings.roads.roads;
                builder.addSceneInstancesSelectorProp({
                    name: 'Selected roads',
                    value: roads.value.map(v=>({value:v})),
                    types: roads.types,
                    typeSortKeyOverride: ++sortKey,
                    onChange: (v) => {
                        const updatedProps = Immer.produce(farmConfig.config, draft => {
                            draft.site_areas[farmConfig.selectedArea].settings.roads.roads = roads.withDifferentValue(v.map(v => v.value));
                        });
                        updateConfig(updatedProps);
                    },
                    createInstance: async (ui) => {
                        const createBoundaryDecr = ui.actions.get(['Add', 'Road'].join(','));
                        if(!createBoundaryDecr?.action){
                            return;
                        }
                        const result = await createBoundaryDecr.action(undefined);
                        if(result instanceof EditActionResult){
                            const newIds = new Set(result.ids);
                            for (const v of roads.value) {
                                newIds.add(v);
                            }
                            const updatedProps = Immer.produce(farmConfig.config, draft => {
                                draft.site_areas[farmConfig.selectedArea].settings.roads.roads = roads.withDifferentValue(Array.from(newIds));
                            });
                            updateConfig(updatedProps);  
                        }
                    }
                });
            } else {
                builder.addCustomProp({
                    name: 'Resulting pattern',
                    value: {
                        angleDeg: farmConfig.settings.roads.tracker_angle.as('deg'),
                        shiftDeg: farmConfig.settings.roads.equipment_roads_angle.as('deg'),
                    },
                    context: {},
                    onChange: () => {},
                    type_ident: 'resulting-pattern',
                    typeSortKeyOverride: ++sortKey,
                });
            }
        }

        const puiRoot = builder.finish();
        return puiRoot;
    }).withoutEqCheck();

    return new ContextMenuConfig({
        identity, 
        viewSource: new PUI_Lazy(puiLazy), 
        action: { name: 'Ok' }, 
        positionPx: pos, 
        header: "Roads",
        maxHeightPx: 670,
        widthPx: 450,
    });
}


export function createSelectedConfigLazy(bim: Bim, logger: ScopedLogger) {
    const context = createContext(bim, FarmLayoutConfigType);
    const lazyConfig = LazyDerived.new2(
        "lazy-configs",
        null,
        [context.selectionObserver, context.configObserver],
        ([lastSelected, configs]) => {
            const selectedConfig = configs.find(
                ([_id, c]) => c.connectedTo === lastSelected.selected?.id
            );
            let settings: FarmConfigProperties;
            if(selectedConfig){
                const config = selectedConfig[1].get<FarmLayoutConfig>();
                const selectedAreaIdx = config.selected_area.value;
                const selectedArea = config.site_areas[selectedAreaIdx];
                if(!selectedArea){
                    logger.error(`Not found area with index ${selectedAreaIdx} in config`, config);
                    return undefined;
                }

                settings = {
                    id: selectedConfig[0],
                    connectedTo: selectedConfig[1].connectedTo,
                    config,
                    selectedArea: selectedAreaIdx,
                    settings: selectedArea.settings,
                    selectedSubstationName: lastSelected.selected!.name,
                    substations: lastSelected.instancesByName,
                };
            }
            // logger.info(settings);
            return settings;
        }
    );
    return lazyConfig;
}

export type FarmConfigProperties =
    | {
          id: IdConfig;
          connectedTo: IdBimScene;
          config: FarmLayoutConfig;
          selectedArea: number;
          settings: SiteAreaSettings;
          substations: Map<string, IdBimScene>;
          selectedSubstationName: string;
      }
    | undefined;


export interface BlocksUiPanelFlags {
    hideElectrical?: boolean;
    autoblocking?: boolean;
    autofill?: boolean;
    disableElectricalBlockRemoval?: boolean;
    disableNewBlockEquipmentCreation?: boolean;
}

function createBlocksPui({
    configLazy,
    logger,
    catalog,
    onChange,
    addNew,
    remove,
    createIlrContext,
    updateConfig,
    flags,
} : {
    configLazy: LazyVersioned<FarmConfigProperties>;
    logger: ScopedLogger;
    catalog: Catalog;
    onChange: (id: number, filed: keyof BlockEquipment, newValue: PropertyBase) => void;
    addNew: (block: BlockEquipment) => void;
    remove: (idx: number) => void;
    createIlrContext: (
        block: BlockEquipment,
        index: number
    ) => [InvertersGroup[] | undefined, BlockProperties[] | undefined];
    updateConfig: (config: PropertyGroup) => void;
    flags?: BlocksUiPanelFlags;
}): LazyVersioned<PUI_GroupNode> {

    const puiLazy = LazyDerived.new1(
        "",
        [],
        [configLazy],
        ([farmConfig]) => {
            const builder = new PUI_Builder({});
            const scheme: DC_CNSTS.PatternName =
                (farmConfig?.settings?.electrical.scheme
                    .value as DC_CNSTS.PatternName) ?? "CI_HorTrunkbus";
            if (!farmConfig) {
                return builder.finish();
            }

            let sorkey: number = 1000;
            function getNextSortKey() {
                return ++sorkey;
            }
            const blocks = farmConfig.settings.electrical.blocks_equipment;
            let mainProp = farmConfig.settings.capacity.number_of_blocks;
            if (blocks.length > 0 && mainProp.selectedOption !== "ignore") {
                const updatedOption =
                    blocks.length > 1
                        ? "mixed"
                        : blocks[0].number_of_inverters.selectedOption;
                if (mainProp.selectedOption !== updatedOption) {
                    mainProp = NumberPropertyWithOptions.new({
                        ...mainProp,
                        selectedOption: updatedOption,
                    });
                }
            }
            if (blocks.length === 1) {
                const updatedValue = blocks[0].number_of_inverters.value;
                if (mainProp.value !== updatedValue) {
                    mainProp = NumberPropertyWithOptions.new({
                        ...farmConfig.settings?.capacity.number_of_blocks,
                        value: updatedValue,
                    });
                }
            }

            const mainPath = createFarmLayoutSettingsPath(
                farmConfig.selectedArea
            );
            if (
                !ObjectUtils.areObjectsEqual(
                    farmConfig.settings.capacity.number_of_blocks,
                    mainProp
                )
            ) {
                const updated = Immer.produce(farmConfig.config, (draft) => {
                    draft.site_areas[
                        farmConfig.selectedArea
                    ].settings.capacity.number_of_blocks = mainProp;
                });
                updateConfig(updated);
            }

            const addProp = (
                path: PropertyPath,
                name?: string,
                readonly?: boolean,
                notActive?: boolean
            ) => {
                if (farmConfig) {
                    addProperty({
                        logger,
                        config: farmConfig.config,
                        patch: (props) => {
                            updateConfig(props);
                        },
                        builder,
                        path,
                        sortKey: getNextSortKey(),
                        name,
                        readonly,
                        notActive,
                    });
                } else {
                    logger.error("undefined config");
                }
            };

            addBlocks({
                blocks,
                logger,
                builder,
                getNextSortKey,
                remove,
                flags,
                addProp,
                mainPath: [...mainPath, "electrical"],
                createIlrContext,
                onChange,
                scheme,
                onChangeILRProp: (v, blockIndex) => {

                    const updatedConfig = Immer.produce(
                        farmConfig.config,
                        (draft) => {
                            const block = blocks[blockIndex];
                            const updatedSettings = draft.site_areas[draft.selected_area.value].settings;
                            const main_ilr = updatedSettings.capacity.ilr_range;
                            if (blocks.length === 1) {
                                updatedSettings.capacity.ilr_range = main_ilr.withDifferentValue(v);
                            } else {
                                const [min, max] = main_ilr.value;
                                updatedSettings.capacity.ilr_range =
                                    NumberRangeProperty.new({
                                        ...main_ilr,
                                        value: [
                                            Math.min(min, v[0]),
                                            Math.max(max, v[1]),
                                        ],
                                    });
                            }
                            const updatedProp = NumberRangeProperty.new({
                                ...block.ilr_range,
                                value: v,
                            });
                            updatedSettings.electrical.blocks_equipment[blockIndex].ilr_range = updatedProp;
                        }
                    );
                    updateConfig(updatedConfig);
                },
                catalog,
                addNewBlock: () =>
                    addNewBlockEquipment(blocks, farmConfig.settings, addNew),
            });

            const root = builder.finish();
            return root;
        }
    ).withoutEqCheck();

    return puiLazy;
}


export function addBlocks({
    blocks,
    logger,
    builder,
    getNextSortKey,
    remove,
    flags,
    addProp,
    mainPath,
    createIlrContext,
    onChange,
    scheme,
    catalog,
    addNewBlock,
    onChangeILRProp
}: {
    builder: PUI_Builder;
    blocks: BlockEquipment[];
    logger: ScopedLogger;
    getNextSortKey: () => number;
    remove: (idx: number) => void;
    addProp: (path: PropertyPath, name?: string, readonly?: boolean, notActive?: boolean) => void;
    flags?: BlocksUiPanelFlags;
    mainPath: PropertyPath;
    createIlrContext: (
        block: BlockEquipment,
        index: number
    ) => [InvertersGroup[] | undefined, BlockProperties[] | undefined];
    onChange: (
        id: number,
        filed: keyof BlockEquipment,
        newValue: PropertyBase
    ) => void;
    scheme: DC_CNSTS.PatternName;
    catalog: Catalog;
    addNewBlock: () => void;
    onChangeILRProp: (newValue: [number, number], blockIndex: number) => void;
}) {
    for (let i = 0; i < blocks.length; i++) {
        const block = blocks[i];
        logger.debug("update block " + block.id.value, block.inverter.value);
        builder.inGroup(
            {
                showTitle: false,
                collapsible: false,
                typeSortKeyOverride: getNextSortKey(),
                name: i.toString(),
            },
            () => {
                const removeAction = {
                    iconName: "Delete",
                    onClick: () => {
                        remove(i);
                    },
                };
                builder.addCustomProp({
                    name: block.label.value ? block.label.value : "Block " + (i + 1),
                    type_ident: "custom-group-name",
                    value: " ",
                    typeSortKeyOverride: getNextSortKey(),
                    onChange: () => {},
                    context: {
                        showOnlyName: true,
                        iconAfter: blocks.length === 1 || flags?.disableElectricalBlockRemoval === true
                            ? undefined
                            : removeAction,
                    },
                });
                addProp([
                    ...mainPath,
                    "blocks_equipment",
                    i.toString(),
                    "label",
                    ], 
                    undefined,
                    flags?.autoblocking === false,
                    flags?.autoblocking === false,
                );
                const [inverters, blocksProperties] = createIlrContext(
                    block,
                    i
                );
                    type LayoutMetricsContext = {
                        blocksProperties?: BlockProperties[];
                    };

                const valueRenderFormatter: ValueRenderFormatter<LayoutMetricsContext> =
                    {
                        context: { blocksProperties },
                        textColorFormatter: (args) =>
                            args.option === "auto" ? "green" : "inherit",
                        formatter: (args) =>
                            args.option === "auto"
                                ? `${
                                        args.context.blocksProperties
                                            ? args.context.blocksProperties
                                                .length
                                            : "-"
                                    }`
                                : `${args.value}`,
                        isReadonly: (args) => args.option === "auto",
                    };
                builder.addCustomProp<
                    NumberPropertyWithOptions,
                    NumberPropertyWithOptionsContext<LayoutMetricsContext>
                >({
                    name: "Blocks number",
                    type_ident: "number-property-with-options",
                    value: block.number_of_inverters,
                    typeSortKeyOverride: getNextSortKey(),
                    context: {
                        valueRenderFormatter,
                    },
                    onChange: (v) => {
                        onChange(i, "number_of_inverters", v);
                    },
                });
                if (scheme === "SI_Multiharness") {
                    const valueRenderFormatter: ValueRenderFormatter<LayoutMetricsContext> =
                        {
                            context: { blocksProperties },
                            textColorFormatter: (args) =>
                                args.option === "auto"
                                    ? "green"
                                    : "inherit",
                            formatter: (args) => {
                                const minNumberOfInverters =
                                    args.context.blocksProperties &&
                                    Math.min(
                                        ...args.context.blocksProperties.map(
                                            (b) => b.inverters.length
                                        )
                                    );
                                const maxNumberOfInverters =
                                    args.context.blocksProperties &&
                                    Math.max(
                                        ...args.context.blocksProperties.map(
                                            (b) => b.inverters.length
                                        )
                                    );
                                const message =
                                    minNumberOfInverters &&
                                    maxNumberOfInverters
                                        ? minNumberOfInverters ===
                                            maxNumberOfInverters
                                            ? `${minNumberOfInverters}`
                                            : `${minNumberOfInverters}-${maxNumberOfInverters}`
                                        : "-";

                                return args.option === "auto"
                                    ? message
                                    : `${args.value}`;
                            },
                            isReadonly: (args) => args.option === "auto",
                        };
                    builder.addCustomProp<
                        NumberPropertyWithOptions,
                        NumberPropertyWithOptionsContext<LayoutMetricsContext>
                    >({
                        name: "Inverters number in block",
                        type_ident: "number-property-with-options",
                        value: block.inverters_per_block,
                        typeSortKeyOverride: getNextSortKey(),
                        context: {
                            valueRenderFormatter,
                        },
                        onChange: (v) => {
                            onChange(i, "inverters_per_block", v);
                        },
                    });
                } else {
                    builder.addStringProp({
                        name: "Inverters number in block",
                        value: "1",
                        typeSortKeyOverride: getNextSortKey(),
                        onChange: () => {},
                        readonly: true,
                    });
                }

                builder.addCustomProp<
                [number, number],
                {
                    name: string;
                    minMax: [number, number];
                    step: number | undefined;
                    inverters: InvertersGroup[] | undefined;
                }
                >({
                    name: "ILR_range",
                    type_ident: "ilr_property",
                    value: block.ilr_range.value,
                    typeSortKeyOverride: getNextSortKey(),
                    context: {
                        name: "ILR range",
                        minMax: block.ilr_range.range ?? [0, 10],
                        step: block.ilr_range.step,
                        inverters: inverters,
                    },
                    onChange: (v) => {
                        onChangeILRProp(v, i);
                    },
                    readonly: flags?.autofill === true,
                    notActive: flags?.autofill === true,
                });
                addProp([
                        ...mainPath,
                        "blocks_equipment",
                        i.toString(),
                        "inverter",
                    ],
                );
                addProp([
                    ...mainPath,
                    "blocks_equipment",
                    i.toString(),
                    "transformer"
                ], undefined, 
                undefined, 
                flags?.autofill === true,
                ); 
            }
        );
    }

    if (!flags?.disableNewBlockEquipmentCreation) {
        builder.addActionsNode({
            name: "Add block configuration",
            typeSortKeyOverride: getNextSortKey(),
            context: undefined,
            actions: [
                {
                    label: "Add block configuration",
                    action: () => addNewBlock(),
                    style: {
                        type: "text",
                        icon: "AddPlus",
                    },
                },
            ],
        });
    }
}

export function addNewBlockEquipment(blocks: BlockEquipment[], config: SiteAreaSettings | undefined, updateConfig: (block: BlockEquipment) => void) {
    
    const mainSelected = config?.capacity.number_of_blocks.selectedOption ?? 'auto';
    const defaultSelected = mainSelected === 'set' ? 'set' : 'auto';
    const defaultValue = mainSelected === 'set' ? config?.capacity.number_of_blocks.value ?? 1 : 1;
    let lastId = 0;
    for (const block of blocks) {
        lastId = Math.max(block.id.value, lastId);
    }
    const id = lastId + 1;
    const newBlock: BlockEquipment = {
        id: NumberProperty.new({
            value: id,
            unit: "",
            isReadonly: true,
        }),
        inverter: new CatalogItemsReferenceProperty({
            value: [],
            maxCount: 1,
            assetsTypes: ["inverter"],
            types: ["asset"]
        }),
        transformer: new CatalogItemsReferenceProperty({
            value: [],
            maxCount: 1,
            assetsTypes: ["transformer"],
            types: ["asset"]
        }),
        number_of_inverters: NumberPropertyWithOptions.new({
            value: defaultValue,
            unit: "",
            step: 1,
            range: [1, 1e9],
            selectedOption: defaultSelected,
            options: ['auto', 'set']
        }),
        label: StringProperty.new({
            value: `Block configuration ${id}`,
        }),
        inverters_per_block: NumberPropertyWithOptions.new({
            value: 3,
            unit: "",
            step: 1,
            range: [1, 1e9],
            options: ['set', 'auto'],
            selectedOption: 'set',
        }),
        ilr_range: NumberRangeProperty.new({
            value: config ? config.capacity.ilr_range.value : [1.05, 1.25],
            unit: "",
            step: 0.01,
            range: [0, 100],
        }),
    }
    updateConfig(newBlock);
}

function createSelectedAreaUi(
    bim: Bim, 
    logger: ScopedLogger, 
    lazyConfig: LazyDerived<FarmConfigProperties>,
    pos: Vector2,
    siteAreaPropsLazy: LazyVersioned<ZoneBoundaryProps[]>,
    ) : ContextMenuConfig{

    const intersectionsWithAllSiteAreaLazy = checkLazyItemsInAllSiteArea(bim, logger.newScope('check-errors'), lazyConfig);

    const identity = 'farm-layout-selected-area-ui';
    const puiLazy = LazyDerived.new1<
        PUI_GroupNode,
        FarmConfigProperties
    >(identity, null, [lazyConfig], ([farmConfig]) => {
        const builder = new PUI_Builder({});
        if(farmConfig){

            builder.addCustomGroup<SelectAreaPropertiesGroup, SiteAreaContext>({
                name: "Site area",
                children: new SelectAreaPropertiesGroup(
                    farmConfig.config, 
                    bim,
                    (path, value, idx) => {
                        patchObject(farmConfig.config, path, value, (properties) => {
                            const updatedProperties = Immer.produce(properties as FarmLayoutConfig, draft => {
                                if(path[path.length - 1] === 'priority' && value instanceof NumberProperty){
                                    const prevPriority = farmConfig.config.site_areas[idx].priority;
                                    if(prevPriority instanceof NumberProperty){
                                        for (let i = 0; i < draft.site_areas.length; i++) {
                                            const area = draft.site_areas[i];
                                            if(i === idx){
                                                continue;
                                            }
                                            if(!(area.priority instanceof NumberProperty)){
                                                continue; 
                                            }
                                            if(value.value === area.priority.value){
                                                area.priority = area.priority.withDifferentValue(prevPriority.value);
                                            }
                                        }
                                    }
                                }
                            });
                            bim.configs.applyPatches([
                                [farmConfig.id, { properties: updatedProperties }],
                            ]);
                        });
                    },
                    logger,
                    intersectionsWithAllSiteAreaLazy,
                ),
                context: {
                    addNewArea: () => {
                        let maxPriority = 0;
                        let lastIndex = 0;
                        let allSiteAreaSettings: SiteAreaSettings | undefined;
                        for (const area of farmConfig.config.site_areas) {
                            if(area.priority instanceof NumberProperty){
                                maxPriority = Math.max(area.priority.value, maxPriority);
                                lastIndex++;
                            }
                            if(area.boundaries instanceof SceneInstancesProperty){
                                allSiteAreaSettings = ObjectUtils.deepCloneObj(area.settings);
                            }
                        }
          
                        const newPriority = maxPriority + 1;

                        const newArea: SiteSubarea = getDefaultSubarea({
                            name: 'Site Subarea ' + (lastIndex + 1),
                            priority: 1,
                            rangePriority: [1, newPriority],
                            settings: allSiteAreaSettings
                        });

                        const updatedProperties = Immer.produce(farmConfig.config, draft => { 
                            if (
                                farmConfig.config.site_areas.length < 3 &&
                                allSiteAreaSettings
                            ) {
                                const unlocatedAreaIdx =
                                    draft.site_areas.findIndex(
                                        (a) => !(a.zones instanceof SceneInstancesProperty) 
                                        && !(a.boundaries instanceof SceneInstancesProperty)
                                    );
                                if (unlocatedAreaIdx !== -1) {
                                    draft.site_areas[
                                        unlocatedAreaIdx
                                    ].settings = allSiteAreaSettings;
                                }
                            }

                            for (const area of draft.site_areas) {
                                if(area.priority instanceof NumberProperty){
                                    area.priority = NumberProperty.new({
                                        ...area.priority,
                                        range: [1, newPriority],
                                        value: area.priority.value + 1,
                                    });
                                }
                            }
                            draft.site_areas.push(newArea);
                            
                            draft.selected_area = draft.selected_area.withDifferentValue(draft.site_areas.length - 1);
                        });

                        const patches: [IdConfig, ConfigPatch][] = [[farmConfig.id, { properties: updatedProperties }]];

                        bim.configs.applyPatches(patches);
                    },
                    removeArea: (index: number) => {
                        const updatedProperties = Immer.produce(farmConfig.config, draft => {
                            draft.site_areas.splice(index, 1);
                            draft.site_areas = orderPriority(draft.site_areas);

                            draft.selected_area = draft.selected_area.withDifferentValue(0);
                        });
                        const patches: [IdConfig, ConfigPatch][] = [[farmConfig.id, { properties: updatedProperties }]];

                        bim.configs.applyPatches(patches);
                    },
                    lazyAreasProps: siteAreaPropsLazy,
                }
            });
        }
        const puiRoot = builder.finish();
        return puiRoot;
    }).withoutEqCheck();

    return new ContextMenuConfig({
        identity, 
        viewSource: new PUI_Lazy(puiLazy), 
        action: { name: 'Ok' }, 
        positionPx: pos, 
        header: "Selected area",
        widthPx: 530,
        maxHeightPx: 700,
    });
}

function orderPriority(areas: SiteArea[]): SiteArea[]{
    const newAreas:[number, number][] = [];
    for (let i = 0; i < areas.length; i++) {
        const area = areas[i];
        if(area.priority instanceof NumberProperty){
            newAreas.push([i, area.priority.value]);
        }                       
    }

    const sortedAreas = newAreas
        .sort((a, b) => a[1] - b[1])
        .map(((a, i) => [a[0], i + 1] as const));

    const subareasCount = newAreas.length;
    const areaPriorityMap = new Map(sortedAreas);
    const ordered = Immer.produce(areas, draft => {
        for (let i = 0; i < draft.length; i++) {
            const area = draft[i];
            if(area.priority instanceof NumberProperty){
                area.priority = NumberProperty.new({
                    ...area.priority,
                    range: [1, subareasCount],
                    value: areaPriorityMap.get(i)!
                });
            }
        }
    });

    return ordered;
}

export interface SiteAreaContext {
    addNewArea: () => void;
    removeArea: (index: number) => void;
    lazyAreasProps: LazyVersioned<ZoneBoundaryProps[]>;
}

export class SelectAreaPropertiesGroup extends PUI_CustomGroupNodeChildren {
    selected_area: PUI_PropertyNodeNumber;
    site_areas: PUI_CustomGroupNode<SubareaProps | AllSiteAreaProps | UnallocatedSubareaProps>[];
    constructor(
        config: FarmLayoutConfig, 
        bim: Bim, 
        onChange: (path: (string|number)[], newValue: PropertyBase, idx: number) => void,
        logger: ScopedLogger,
        intersectionsWithAllSiteAreaLazy: LazyVersioned<Map<IdBimScene, IntersectionBoundaryArea>>,
        ){
        super();
        this.site_areas = [];
        let allSiteAreaIdx: number | undefined;
        for (let index = 0; index < config.site_areas.length; index++) {
            const area = config.site_areas[index];
            const onChangeArea = (path: (string|number)[], newValue: PropertyBase) => {
                onChange(['site_areas', index, ...path], newValue, index);
            };
            if(area.zones instanceof SceneInstancesProperty){
                const subarea = area as SiteSubarea;
                const subareaProps = new SubareaProps(
                    {
                    ...subarea,
                    index: NumberProperty.new({value: index, isReadonly: true}),
                    },
                    config.site_areas,
                    onChangeArea,
                    bim,
                    logger,
                    intersectionsWithAllSiteAreaLazy,
                    (ids) => {
                        if(allSiteAreaIdx === undefined){
                            logger.error('Not found all site area');
                            return;
                        }
                        const boundaries = config.site_areas[allSiteAreaIdx].boundaries;
                        if(!(boundaries instanceof SceneInstancesProperty)){
                            logger.error('Not found all site area boundaries', config);
                            return;
                        }
                        const newIds = new Set(boundaries.value);
                        IterUtils.extendSet(newIds, ids);
                        onChange(['site_areas', allSiteAreaIdx, 'boundaries'], boundaries.withDifferentValue(Array.from(newIds)), index);
                    }
                );
                this.site_areas.push(new PUI_CustomGroupNode({children: subareaProps, name: subarea.name.value, context: undefined}));
            } else if(area.boundaries instanceof SceneInstancesProperty) {
                const allSiteArea = area as AllSiteArea;
                allSiteAreaIdx = index;
                const allSiteAreaProps = new AllSiteAreaProps(
                    {
                        ...allSiteArea,
                        index: NumberProperty.new({value: index, isReadonly: true}),
                    }, 
                    onChangeArea, 
                    bim
                );
                this.site_areas.push(new PUI_CustomGroupNode({children: allSiteAreaProps, name: 'All area', context: undefined}));
            } else if(area['unallocated'] instanceof BooleanProperty) {
                const unlocatedArea = area as UnallocatedSubarea;
                const unlocatedAreaProps = new UnallocatedSubareaProps(
                    {
                        ...unlocatedArea,
                        index: NumberProperty.new({value: index, isReadonly: true}),
                    }, 
                    onChangeArea, 
                    bim
                );
                this.site_areas.push(new PUI_CustomGroupNode({children: unlocatedAreaProps, name: 'Unlocated area', context: undefined}));
            } else {
                console.error('Unknown area type', area);
            }
        }
        this.selected_area = new PUI_PropertyNodeNumber({
            name: 'Selected area',
            value: config.selected_area.value,
            readonly: true,
            onChange: (newValue) => {
                onChange(['selected_area'], config.selected_area.withDifferentValue(newValue), 0);
            }
        });
    }
}

export class SubareaProps extends PUI_CustomGroupNodeChildren {
    index: PUI_PropertyNodeNumber;
    name: PUI_PropertyNodeString;
    priority: PUI_CustomPropertyNode<NumberPropertyWithOptions, NumberPropertyWithOptionsContext<any>>;
    zones: PUI_SceneInstancesSelectorPropertyNode;
    equipmentBoundaries: PUI_SceneInstancesSelectorPropertyNode;
    constructor(
        area:SiteSubarea & {index: NumberProperty},
        allAreas: SiteArea[],
        onChange: (path: (string|number)[],
        newValue: PropertyBase) => void, 
        bim: Bim,
        logger: ScopedLogger,
        intersectionsWithAllSiteAreaLazy: LazyVersioned<Map<IdBimScene, IntersectionBoundaryArea>>,
        addInstancesInAllSiteArea: (ids: IdBimScene[]) => void,
    ){
        super();
        const puiGroup = createPuiGroupFromObject({sourceObject: area, onChange: onChange});
        this.index = getPuiProperty('index', puiGroup.children, PUI_PropertyNodeNumber);
        this.name = new PUI_PropertyNodeString({
            name: 'Name',
            value: area.name.value,
            onChange: (newValue) => {
                onChange(['name'], area.name.withDifferentValue(newValue));
            }
        });
        type PriorityContext = {maxValue: number | undefined};
        this.priority = new PUI_CustomPropertyNode<NumberPropertyWithOptions, NumberPropertyWithOptionsContext<PriorityContext>>({
            name: 'Priority',
            type_ident: "number-property-with-options",
            value: NumberPropertyWithOptions.new({
                value: area.priority.value,
                range: area.priority.range,
                step: area.priority.step,
                options: [],
                selectedOption: "",
                isReadonly: area.priority.isReadonly,
                unit: area.priority.unit,

            }),
            context: {
                doNotShowOptions: true,
                valueRenderFormatter: {
                    context: {maxValue: area.priority?.range ? area.priority.range[1] : 0},
                    formatter: (args) => {
                        return args.context.maxValue !== undefined ? args.value + ' of ' + args.context.maxValue : `${args.value}`;
                    }
                }
            },
            onChange: (newValue) => {
                onChange(['priority'], area.priority.withDifferentValue(newValue.value));
            }
        });

        this.equipmentBoundaries = new PUI_SceneInstancesSelectorPropertyNode({
            name: "Equipment boundaries",
            value: area.equipmentBoundaries.value.map(v => ({ value: v })),
            types: area.equipmentBoundaries.types,
            onChange: (newVal) => {
                onChange(['equipmentBoundaries'], area.equipmentBoundaries.withDifferentValue(newVal.map(v=>v.value)));
            },
        });
        const equipmentBoundaries = new Map<IdBimScene, SceneInstancesSelectorValue>();
        for (const someArea of allAreas) {
            if(!(someArea.priority instanceof NumberProperty && someArea.equipmentBoundaries instanceof SceneInstancesProperty)){
                continue;
            }
            if(area.priority.value > someArea.priority.value ){

                for (const id of someArea.equipmentBoundaries.value) {
                    if(equipmentBoundaries.has(id)){
                        logger.error('Duplicate equipment boundary', id, someArea);
                    }
                    equipmentBoundaries.set(id, {value: id, readonly: true});
                }
            }
        }
        
        const subareaItems = area.zones.value.map(v => ({ value: v }));
        IterUtils.extendArray(subareaItems, equipmentBoundaries.values());

        const convertArea = (areaM2: number) => {
            const areaAc = convertUnits(areaM2, 'm2', 'ac');
            if (areaAc instanceof Failure) {
                throw new Error(areaAc.errorMsg());
            }
            const mapped = bim.unitsMapper.mapToConfigured({value: areaAc.value, unit: 'ac'});
            return { value: KrMath.ceilTo(mapped.value, 1), unit: mapped.unit };
        }

        this.zones = new PUI_SceneInstancesSelectorPropertyNode({
            name: "Boundaries",
            value: subareaItems,
            types: area.zones.types,
            onChange: (newVal) => {
                const ids = newVal
                    .filter(v => !equipmentBoundaries.has(v.value))
                    .map<IdBimScene>(v => v.value);
                if(!ObjectUtils.areObjectsEqual(area.zones.value, ids)){
                    onChange(['zones'], area.zones.withDifferentValue(ids));
                }
            },
            createInstance: async (ui) => createBoundary(ui, (ids)=>{
                const newIds = new Set(ids);
                for (const v of area.zones.value) {
                    newIds.add(v);
                }
                onChange(['zones'], area.zones.withDifferentValue(Array.from(newIds)));   
            }),
            hasItemsError: LazyDerived.new1(
                'has-items-error-lazy', 
                [bim.unitsMapper],
                [intersectionsWithAllSiteAreaLazy], 
                ([intersectionsWithAllSiteArea]) => {
                    const itemsError = new Map<IdBimScene, ItemErrorMsg>();
                    for (const id of area.zones.value) {
                        const intersectionArea = intersectionsWithAllSiteArea.get(id);
                        if(!intersectionArea){
                            continue;
                        }
                        const mapped = convertArea(intersectionArea.areaM2);
                        itemsError.set(id, [
                            `There is ${mapped.value} ${mapped.unit} of Site Area within Line ${id}. `,
                            {label: "Add this boundary to the Site area", action: () => { addInstancesInAllSiteArea([id]);}},
                            " or check if it's not placed completely within one of the exclude boundaries.",
                        ]);
                    }
                    return itemsError;
                },
            ),
            hasErrorInGroups: LazyDerived.new1(
                'has-error-in-group-lazy', 
                [bim.unitsMapper], 
                [intersectionsWithAllSiteAreaLazy], 
                ([intersectionsWithAllSiteArea]) => {
                    const itemsError = new Map<string, ItemErrorMsg>();
                    const groups: [string, SceneInstancesSelectorValue[]][] = [['Boundaries', this.zones.value.map(v => ({value: v.value}))]];
                    for (const [group, values] of groups) {
                        let allArea: number[] = [];
                        const idsInGroup: IdBimScene[] = [];
                        for (const v of values) {
                            const intersectionArea = intersectionsWithAllSiteArea.get(v.value);
                            if(!intersectionArea){
                                continue;
                            }
                            allArea.push(intersectionArea.areaM2);
                            idsInGroup.push(v.value);
                        }
                        if(allArea.length > 0){
                            const mapped = allArea.map(v => convertArea(v));
                            const totalArea = IterUtils.sum(mapped, v => v.value);
                            const plural1 = idsInGroup.length > 1 ? 's' : '';
                            const plural2 = idsInGroup.length > 1 ? 'ies' : 'y';
                            itemsError.set(group, [
                                `There is ${totalArea} ${mapped[0].unit} of Site Area within Line${plural1} ${idsInGroup.join(', ')}. `,
                                {label: `Add this boundar${plural2} to the Site area`, action: () => { addInstancesInAllSiteArea(idsInGroup);}},
                                " or check if it's not placed completely within one of the exclude boundaries.",
                            ]);
                        }
                    }
                    return itemsError;
                },
            ),
        });
    }
}
interface IntersectionBoundaryArea{
    areaM2: number,
}

function checkLazyItemsInAllSiteArea(
        bim: Bim, 
        logger: ScopedLogger,     
        lazyFarmConfig: LazyDerived<FarmConfigProperties>,
    ):LazyVersioned<Map<IdBimScene, IntersectionBoundaryArea>>{
    const boundariesLazy = getBoundariesLazyList(bim);

    const lazySubAreaProps = LazyDerived.new1(
        'subarea-props-lazy', 
        null, 
        [lazyFarmConfig], 
        ([farmConfig]) => {
        const area = farmConfig?.config.site_areas.find(s => s.boundaries instanceof SceneInstancesProperty);
        if(!area || !farmConfig){
            return;
        }
        const zones = new Set<IdBimScene>();
        for (const iterator of farmConfig.config.site_areas) {
            if(iterator.zones instanceof SceneInstancesProperty){
                for (const zone of iterator.zones.value) {
                    zones.add(zone);
                }
            }
        }
    
        const allSiteArea = area as AllSiteArea;
        const allSiteAreaBoundariesIds = new Set(allSiteArea.boundaries.value);

        return {
            allSiteAreaBoundariesIds,
            zones
        }
    });

    const checkLazyItemsInAllSiteArea = LazyDerivedAsync.new2(
        'calc-area-lazy-async',
        null,
        [lazySubAreaProps, boundariesLazy],
        function* ([props, allBoundaries]) {
            yield Yield.Asap;
            const itemsArea = new Map<IdBimScene, IntersectionBoundaryArea>();
            if(!props || !props.allSiteAreaBoundariesIds.size){
                return itemsArea;
            }
            const allSiteAreaBoundaries = IterUtils.filter(allBoundaries.values(), b => props.allSiteAreaBoundariesIds.has(b.bimObjectId));
            const includeContours = orderContours(allSiteAreaBoundaries.filter(b => b.boundaryType === BoundaryType.Include).map(c => c.pointsWorldSpace));
            const excludeContours = orderHoles(allSiteAreaBoundaries.filter(b => b.boundaryType === BoundaryType.Exclude).map(c => c.pointsWorldSpace));

            for (const id of props.zones) {
                if(props.allSiteAreaBoundariesIds.has(id)){
                    continue;
                }
                const contour = allBoundaries.get(id);
                if(!contour){
                    logger.error('Not found contour', id);
                    continue;
                }
                const mergedContours = Clipper.unionPolygons2D(includeContours);
                yield Yield.Asap;
                const reusedAabb1 = Aabb2.empty();
                const reusedAabb2 = Aabb2.empty();
                const cutContours:Vector2[][] = [];
                for(let i = 0; i < mergedContours.length; i++) {
                    if(i % 10 === 0){
                        yield Yield.Asap;
                    }
                    const mergedContour = mergedContours[i];
                    reusedAabb1.setFromPoints(mergedContour);
                    const excludeContoursFiltered: Vector2[][] = [];
                    for (const excludeContour of excludeContours) {
                        reusedAabb2.setFromPoints(excludeContour);
                        if(reusedAabb1.intersectsBox2(reusedAabb2)){
                            excludeContoursFiltered.push(excludeContour);
                        }
                    }
                    const result = Clipper.subtractPolygons(mergedContour, excludeContoursFiltered);
                    IterUtils.extendArray(cutContours, result);
                }
                const ordered = orderContour(contour.pointsWorldSpace);
                const intersections = orderContours(cutContours)
                    .flatMap(c => Clipper.calculatePolygonsIntersections([c, ordered]));
                yield Yield.Asap;

                let areaM2 = 0;
                if(intersections.length === 1){
                    areaM2 = PolygonUtils.area(intersections[0]);
                } else if(intersections.length > 1){
                    for (const contour of intersections) {
                        if(!PolygonUtils.isClockwiseInner(contour)){
                            areaM2 += PolygonUtils.area(contour);
                        }
                    }
                }
                const areaAcRes = convertUnits(Math.abs(areaM2), 'm2', 'ac');
                if(areaAcRes instanceof Success && areaAcRes.value <= 25){
                    itemsArea.set(id, {areaM2: Math.abs(areaM2)});
                }
            }
            return itemsArea;
        }
    );


    return LazyDerived.new1<Map<IdBimScene, IntersectionBoundaryArea>, ResultAsync<Map<IdBimScene, IntersectionBoundaryArea>>>(
        'calc-area-lazy',
        null,
        [checkLazyItemsInAllSiteArea], 
        ([itemsErrorResult], prevResult) => {
        if(itemsErrorResult instanceof Success){
            return itemsErrorResult.value;
        } else if(itemsErrorResult instanceof Failure){
            logger.error(itemsErrorResult.msg);
        } else if(itemsErrorResult instanceof InProgress) {
            //
        } else {
            logger.error("Raw status", itemsErrorResult);
        }
        
        return prevResult ?? new Map();
    });
}

export class AllSiteAreaProps extends PUI_CustomGroupNodeChildren {
    index: PUI_PropertyNodeNumber;
    name: PUI_PropertyNodeString;
    boundaries: PUI_SceneInstancesSelectorPropertyNode;

    constructor(
        area: AllSiteArea & {index: NumberProperty},
        onChange: (path: (string|number)[], newValue: PropertyBase) => void,
        bim: Bim,
    ) {
        super();
        const puiGroup = createPuiGroupFromObject({sourceObject: area, onChange});
        this.index = getPuiProperty('index', puiGroup.children, PUI_PropertyNodeNumber);
        this.name = getPuiProperty('name', puiGroup.children, PUI_PropertyNodeString);
        this.boundaries = new PUI_SceneInstancesSelectorPropertyNode({
            name: "Boundaries",
            value: area.boundaries.value.map(v => ({ value: v })),
            types: area.boundaries.types,
            maxSelect: area.boundaries.maxCount,
            onChange: (newVal) => {
                const newProp = area.boundaries.withDifferentValue(newVal.map(v=>v.value));
                onChange(['boundaries'], newProp);
            },
            createInstance: (ui) => createBoundary(ui, (ids)=>{
                const newIds = new Set(ids);
                for (const v of area.boundaries.value) {
                    newIds.add(v);
                }
                onChange(['boundaries'], area.boundaries.withDifferentValue(Array.from(newIds)));   
            })
        });
    }
}

export async function createBoundary(ui: UiBindings, onCreate: (ids: IdBimScene[]) => void){
    const createBoundaryDecr = ui.actions.get(['Add', 'Boundary'].join(','));
    if(!createBoundaryDecr?.action){
        return;
    }
    const result = await createBoundaryDecr.action(undefined);
    if(result instanceof EditActionResult){
        onCreate(result.ids);
    }
}

export class UnallocatedSubareaProps extends PUI_CustomGroupNodeChildren {
    index: PUI_PropertyNodeNumber;
    name: PUI_PropertyNodeString;

    constructor(
        area: UnallocatedSubarea & {index: NumberProperty},
        onChange: (path: (string|number)[], newValue: PropertyBase) => void,
        bim: Bim,
    ) {
        super();
        const puiGroup = createPuiGroupFromObject({sourceObject: area, onChange});
        this.index = getPuiProperty('index', puiGroup.children, PUI_PropertyNodeNumber);
        this.name = getPuiProperty('name', puiGroup.children, PUI_PropertyNodeString);
    }
}

function getPuiProperty<T extends PUI_Node, Params>(name: string, items: ReadonlyMap<string, PUI_Node>, ctor: {new(params: Params): T}):T{
    const item = items.get(name);
    if(item && item instanceof ctor){
        return item;
    }
    throw new Error(`Property ${name} not found with type ${typeof ctor}`);
}

function createPuiGroupFromObject(args:{
    sourceObject: Object,
    onChange: (path: (string|number)[], newValue: PropertyBase) => void;
}) {
    const configBuilderParams = PUI_ConfigBasedBuilderParams.new(
        [],
        []
    ).mergedWith(createPropertyGroupPuiTransformers());
    const puiGroup = buildPuiFromObject({
        configBuilderParams,
        onAnyChange: (newValue, fullPath)=> {
            args.onChange(fullPath, newValue);
        },
        sourceObject: args.sourceObject,
        puiBuilderParams: {

        }
    });
    return puiGroup;
}

function createSelectModeUi(
    bim: Bim, 
    logger: ScopedLogger, 
    lazyConfig: LazyDerived<FarmConfigProperties>, 
    pos: Vector2, 
    openRoads: (p: Vector2) => ContextMenuConfig,
    openBlocksEquipment:  (p: Vector2) => ContextMenuConfig,
):ContextMenuConfig{
    const identity = 'farm-layout-select-mode-ui';
    const puiLazy = LazyDerived.new1<
        PUI_GroupNode,
        FarmConfigProperties
    >(identity, null, [lazyConfig], ([farmConfig]) => {
        const puiRoot = new PUI_GroupNode({name: ""});
        if(farmConfig){
            puiRoot.addMaybeChild(new PUI_CustomPropertyNode({
                name: "Select generation mode",
                value: new SelectLayoutGenerationMode(
                    farmConfig.settings,
                    (updatedConfig) => {
                        const updated = Immer.produce(farmConfig.config, (draft) => {
                            draft.site_areas[
                                farmConfig.selectedArea
                            ].settings = updatedConfig;
                        });
                        bim.configs.applyPatchTo({properties: updated}, [farmConfig.id]);
                    },
                    openRoads,
                    openBlocksEquipment
                ),
                onChange: () => {},
                context: null,
                type_ident: "select-gen-mode"
            }));
        }

        return puiRoot;
    }).withoutEqCheck();

    return new ContextMenuConfig({
        identity, 
        viewSource: new PUI_Lazy(puiLazy), 
        action: { name: 'Ok' }, 
        positionPx: pos, 
        header: "Layout generation target",
        widthPx: 430,
    });
}