import { ObjectUtils } from "engine-utils-ts";
import { Config } from "../../bimConfigs/ConfigsCollection";
import type { CutFillConfig} from "../../bimConfigs/configTypes/CutFillConfig";
import { CutFillConfigType } from "../../bimConfigs/configTypes/CutFillConfig";
import type { FarmLayoutConfig } from "../../bimConfigs/configTypes/FarmLayoutConfigType";
import { FarmLayoutConfigType } from "../../bimConfigs/configTypes/FarmLayoutConfigType";
import type { MvWiringConfig } from "../../bimConfigs/configTypes/MvWiringConfigType";
import { MvWiringConfigType } from "../../bimConfigs/configTypes/MvWiringConfigType";
import type { TerrainAnalysisConfig} from "../../bimConfigs/configTypes/TerrainAnalysisConfig";
import { TerrainAnalysisTypeIdent } from "../../bimConfigs/configTypes/TerrainAnalysisConfig";
import type { TrackerPositionsConfig} from "../../bimConfigs/configTypes/TrackerPositionsConfigType";
import { TrackerPositionType } from "../../bimConfigs/configTypes/TrackerPositionsConfigType";
import { AssetsProperty, SceneInstancesProperty } from "../../properties/ObjectRefProps";
import { SelectorProperty, BooleanProperty, NumberProperty, ColorProperty } from "../../properties/PrimitiveProps";
import type { PropertyGroup } from "../../properties/PropertyGroup";
import { ConfigsCollectionVersions } from "../ConfigSerializer";

export function configsCollectionMigration(
    config: Config,
    version: ConfigsCollectionVersions
): Config {
    let updatedConfig = config;

    if (config.type_identifier === FarmLayoutConfigType) {
        if (version < ConfigsCollectionVersions.RenameToMaxAcMode) {
            updatedConfig = migrateConfigProps<FarmLayoutConfig>(updatedConfig, (props) => {
                //@ts-ignore
                props.electrical_settings.block_mode = SelectorProperty.new({
                    //@ts-ignore
                    ...props.electrical_settings.block_mode,
                    options: [
                        'max unbalanced power',
                        'max balanced power',
                        'target dc power',
                        "max ac power",
                        'target block number',
                    ],
                    //@ts-ignore
                    value: props.electrical_settings.block_mode.value ===  "target ac power"
                        ? "max ac power"
                        //@ts-ignore
                        : props.electrical_settings.block_mode.value
                });
            });
        }
        if (version < ConfigsCollectionVersions.AddAssetsProperty) {
            updatedConfig = migrateConfigProps<FarmLayoutConfig>(updatedConfig, (props) => {
                //@ts-ignore
                props.equipment_settings.trackers = new AssetsProperty({
                    //@ts-ignore
                    ...props.equipment_settings.trackers,
                    types: ["tracker", "fixed-tilt"],
                    description: "select trackers",
                    value: [],
                });
                //@ts-ignore
                props.equipment_settings.combiner_box = new AssetsProperty({
                    //@ts-ignore
                    ...props.equipment_settings.combiner_box,
                    description: "select combiner-box",
                    value: [],
                });
                //@ts-ignore
                props.electrical_settings.blockCases = [];
            });
        }
        if (version < ConfigsCollectionVersions.AddRoadGridAlgo) {
            updatedConfig = migrateConfigProps<FarmLayoutConfig>(updatedConfig, (props)=>{
                //@ts-ignore
                props.layout_settings.roads = {
                    create_ew_roads:BooleanProperty.new({
                        value: true,
                    }),
                    create_ns_roads:BooleanProperty.new({
                        value: true,
                    }),
                    //@ts-ignore
                    adaptive_roads:BooleanProperty.new({
                        value: false,
                    }),
                    roads_step:NumberProperty.new({
                        value: 1000,
                        unit: 'ft',
                        description: 'step of NS roads',
                        range: [0, Number.MAX_VALUE],
                        step: 1
                    }),
                    //@ts-ignore
                    roads: props.layout_settings.roads.roads,
                }
            });
        }
        if (version < ConfigsCollectionVersions.AddAdaptiveRoadsInFarmLayout) {
            updatedConfig = migrateConfigProps<FarmLayoutConfig>(updatedConfig, (props)=>{
                //@ts-ignore
                props.layout_settings.roads = {
                    //@ts-ignore
                    ...props.layout_settings.roads,
                    //@ts-ignore
                    adaptive_roads: BooleanProperty.new({
                        value: false,
                    }),
                };
            });
        }
    } else if (config.type_identifier === MvWiringConfigType) {
        if (version < ConfigsCollectionVersions.AddAssetsProperty) {
            updatedConfig = migrateConfigProps<MvWiringConfig>(updatedConfig, (props)=>{
                //@ts-ignore
                props.equipment.sectionalize_cabinet = new AssetsProperty({
                    ...props.equipment.sectionalize_cabinet,
                    description: 'select sectionalize cabinet',
                    value: []
                });
            });
        }
    } else if (config.type_identifier === TerrainAnalysisTypeIdent) {
        if (version < ConfigsCollectionVersions.AddTerrainAnalysisCutFill) {
            const configProps = config.get<TerrainAnalysisConfig>();
            const newProps: TerrainAnalysisConfig = {
                elevation_palette: configProps.elevation_palette,
                slope_palette: configProps.slope_palette,
                cut_fill_palette: configProps.elevation_palette,
            };
            updatedConfig.properties = newProps;
        }
    }

    updatedConfig = migrateByRemovingLvGenerationOption(updatedConfig, version);
    updatedConfig = migrateByFixMaxCountSelectedAssets(updatedConfig, version);
    updatedConfig = migrateByMigrateToColorProperty(updatedConfig, version);
    updatedConfig = migrateByRenameHeightPreferencesToMaxRowHeight(updatedConfig, version);
    updatedConfig = migrateFixRangeCutFill(updatedConfig, version);
    updatedConfig = migrateRenameCutAndFill(updatedConfig, version);
    updatedConfig = migrateAddPropsInCutFillConfig(updatedConfig, version);
    updatedConfig = migrateToMigrateTerrainSlopeToPercentages(updatedConfig, version);
    updatedConfig = migrateToConnectAssetsToMvWiringConfig(updatedConfig, version);
    updatedConfig = migrateToAddAboveGroundToMvWiringConfig(updatedConfig, version);
    updatedConfig = migrateToAddGroupsSettingsInCutFill(updatedConfig, version);
    updatedConfig = migrateToAddNorthSouthSlopeSettingsInCutFill(updatedConfig, version);
    updatedConfig = redoVAUnitAsCombinationOfUnits(updatedConfig, version);

    return updatedConfig;
}

function redoVAUnitAsCombinationOfUnits(
    config: Config,
    version: ConfigsCollectionVersions,
) {
    let updatedConfig = config;
    if (version < ConfigsCollectionVersions.RedoVAUnitAsCombinationOfUnits) {
        if (config.type_identifier === MvWiringConfigType) {
            updatedConfig = migrateConfigProps<MvWiringConfig>(updatedConfig, (props) => {
                const unit = props.settings.max_power_mv_circuit.unit.replace('VA', 'V*A');
                props.settings.max_power_mv_circuit = NumberProperty.new({
                    ...props.settings.max_power_mv_circuit,
                    unit,
                });
            })
        }
    }
    return updatedConfig;

}


function migrateByMigrateToColorProperty(
    config: Config,
    version: ConfigsCollectionVersions,
) {
    let updatedConfig = config;
    if (
        version < ConfigsCollectionVersions.MigrateToColorProperty &&
        config.type_identifier === TerrainAnalysisTypeIdent
    ) {
        updatedConfig = migrateConfigProps<TerrainAnalysisConfig>(updatedConfig, (props)=>{
            props.slope_palette.slices.forEach(s=>{
                const value = s.color.value;
                s.color = ColorProperty.new({value});
            });
            props.elevation_palette.slices.forEach(s=>{
                const value = s.color.value;
                s.color = ColorProperty.new({value});
            });
        });
    }
    return updatedConfig;
}

function migrateByRemovingLvGenerationOption(
    config: Config,
    version: ConfigsCollectionVersions,
) {
    let updatedConfig = config;
    if (
        version < ConfigsCollectionVersions.RemoveLvGenerationOption &&
        config.type_identifier === MvWiringConfigType &&
        config.properties?.['settings'] &&
        'generate_lv_wires' in config.properties['settings']
    ) {
        updatedConfig = migrateConfigProps<MvWiringConfig>(updatedConfig, (props)=>{
            // @ts-ignore
            delete props.settings.generate_lv_wires;
        });
    }
    return updatedConfig;
}

function migrateByFixMaxCountSelectedAssets(config: Config, version: ConfigsCollectionVersions){
    let updatedConfig = config;

    if(version < ConfigsCollectionVersions.FixMaxCountSelectedAssets){
        if (config.type_identifier === FarmLayoutConfigType) {
            updatedConfig = migrateConfigProps<FarmLayoutConfig>(updatedConfig, (props)=>{
                //@ts-ignore
                props.equipment_settings.trackers = new AssetsProperty({
                        //@ts-ignore
                        ...props.equipment_settings.trackers,
                        types: ["tracker", "fixed-tilt"],
                    });
                //@ts-ignore
                props.equipment_settings.combiner_box = new AssetsProperty({
                    //@ts-ignore
                    ...props.equipment_settings.combiner_box,
                    types: ["combiner-box"],
                });
                //@ts-ignore
                props.electrical_settings.blockCases = [];
                //@ts-ignore
                for (const block of props.electrical_settings.blockCases) {
                    //@ts-ignore
                    props.electrical_settings.blockCases.push({
                        ...block,
                        //@ts-ignore
                        transformer: new AssetsProperty({
                            ...block.transformer,
                            types: ['transformer'],
                        }),
                        //@ts-ignore
                        inverter: new AssetsProperty({
                            ...block.inverter,
                            types: ['inverter'],
                        })
                    })
                }
            });
        } else if (config.type_identifier === MvWiringConfigType) {
            updatedConfig = migrateConfigProps<MvWiringConfig>(updatedConfig, (props)=>{
                //@ts-ignore
                props.equipment.sectionalize_cabinet = new AssetsProperty({
                    ...props.equipment.sectionalize_cabinet,
                    types: ['sectionalizing-cabinet']
                });
            });
        } else if(config.type_identifier === TrackerPositionType){
            updatedConfig = migrateConfigProps<TrackerPositionsConfig>(updatedConfig, (props)=>{
                props.equipment.trackers = new SceneInstancesProperty({
                    ...props.equipment.trackers,
                    types: ["tracker", "fixed-tilt"],
                });
            });
        }
    }

    return updatedConfig;
}

function migrateByRenameHeightPreferencesToMaxRowHeight(
    config: Config,
    version: ConfigsCollectionVersions,
) {
    let updatedConfig = config;
    
    if (
        version < ConfigsCollectionVersions.RenameHeightPreferencesToMaxRowHeight &&
        config.type_identifier === FarmLayoutConfigType &&
        //@ts-ignore;
        config.properties?.layout_settings &&
        //@ts-ignore;
        'hight_preference' in config.properties.layout_settings
    ) {
        updatedConfig = migrateConfigProps<FarmLayoutConfig>(config, (props)=>{
            // @ts-ignore
            props.layout_settings.max_row_height = props.layout_settings.hight_preference;
            // @ts-ignore
            delete props.layout_settings.hight_preference;
        });
    }

    return updatedConfig;
}

function migrateFixRangeCutFill(
    config: Config,
    version: ConfigsCollectionVersions,
) {
    let updatedConfig = config;

    if (
        version < ConfigsCollectionVersions.FixRangeCutFill &&
        config.type_identifier === CutFillConfigType
    ) {
        updatedConfig = migrateConfigProps<CutFillConfig>(config, (props)=>{
            // @ts-ignore
            props.install_equipment.net_cut_and_fill = NumberProperty.new({
                // @ts-ignore
                value: props.install_equipment.net_cut_and_fill.value,
                // @ts-ignore
                unit: props.install_equipment.net_cut_and_fill.unit,
            });
            // @ts-ignore
            props.install_equipment.min_pile_reveal = props.install_equipment.min_pileReveal;
            // @ts-ignore
            delete props.install_equipment.min_pileReveal;
        });
    }

    return updatedConfig;
}

function migrateRenameCutAndFill(
    config: Config,
    version: ConfigsCollectionVersions,
) {
    let updatedConfig = config;

    if (
        version < ConfigsCollectionVersions.RenameCutFill &&
        config.type_identifier === CutFillConfigType
    ) {
        updatedConfig = migrateConfigProps<CutFillConfig>(config, (props)=>{
            //@ts-ignore
            props.install_equipment.placement_method = SelectorProperty.new({
                value: "basic",
                options: [
                    "basic",
                    "piles optimization",
                    "cut&fill",
                ],
            });
        });
    }

    return updatedConfig;
}

function migrateToAddGroupsSettingsInCutFill(
    config: Config,
    version: ConfigsCollectionVersions,
) {
    let updatedConfig = config;

    if (
        version < ConfigsCollectionVersions.AddGroupsSettingsInCutFill &&
        config.type_identifier === CutFillConfigType
    ) {
        updatedConfig = migrateConfigProps<CutFillConfig>(config, (props)=>{
            // @ts-ignore
            props.install_equipment.max_angle_between_groups =
                NumberProperty.new({
                    value: 5,
                    unit: "deg",
                    range: [0, 89],
                });
            //@ts-ignore
            props.install_equipment.max_distance_between_groups =
                NumberProperty.new({
                    value: 30,
                    unit: "ft",
                    range: [0, Number.MAX_VALUE],
                });
        });
    }

    return updatedConfig;
}

function migrateToAddNorthSouthSlopeSettingsInCutFill(
    config: Config,
    version: ConfigsCollectionVersions,
) {
    let updatedConfig = config;

    if (
        version < ConfigsCollectionVersions.AddNorthSouthSlopeSettingsInCutFill &&
        config.type_identifier === CutFillConfigType
    ) {
        updatedConfig = migrateConfigProps<CutFillConfig>(
            config,
            (props) => {
                // @ts-ignore
                props.install_equipment.north_max_slope = NumberProperty.new({
                    value: 6.1,
                    unit: "%",
                    range: [0, Number.MAX_VALUE],
                });
                // @ts-ignore
                props.install_equipment.south_max_slope = NumberProperty.new({
                    value: 6.1,
                    unit: "%",
                    range: [0, Number.MAX_VALUE],
                });
            }
        );
    }

    return updatedConfig;
}

function migrateAddPropsInCutFillConfig(
    config: Config,
    version: ConfigsCollectionVersions,
) {
    let updatedConfig = config;

    if (
        version < ConfigsCollectionVersions.AddPropsInCutFill &&
        config.type_identifier === CutFillConfigType
    ) {
        updatedConfig = migrateConfigProps<CutFillConfig>(config, (props)=>{
            // @ts-ignore
            props.install_equipment.max_distance_between_equipment= NumberProperty.new({
                value: 5,
                unit: "ft",
                range: [0, Number.MAX_VALUE],
            });
            // @ts-ignore
            props.install_equipment.max_height_between_equipment= NumberProperty.new({
                value: 50,
                unit: "ft",
                range: [0, Number.MAX_VALUE],
            });
        });
    }

    return updatedConfig;
}

function migrateToMigrateTerrainSlopeToPercentages(
    config: Config,
    version: ConfigsCollectionVersions,
) {
    let updatedConfig = config;

    if (
        version < ConfigsCollectionVersions.MigrateTerrainSlopeToPercentages &&
        config.type_identifier === TerrainAnalysisTypeIdent
    ) {
        updatedConfig = migrateConfigProps<TerrainAnalysisConfig>(config, (props)=>{
            for (const slice of props.slope_palette.slices) {
                slice.min = NumberProperty.new({
                    value: slice.min.as('%'),
                    unit: '%'
                });
                slice.max = NumberProperty.new({
                    value: slice.max.as('%'),
                    unit: '%'
                });
            }
        });
    }

    return updatedConfig;
}

function migrateToConnectAssetsToMvWiringConfig(
    config: Config,
    version: ConfigsCollectionVersions,
) {
    let updatedConfig = config;

    if (
        version < ConfigsCollectionVersions.ConnectAssetsToMvWiring &&
        config.type_identifier === MvWiringConfigType
    ) {
        updatedConfig = migrateConfigProps<MvWiringConfig>(config, (props)=>{
            props.mv_wires.temperature = NumberProperty.new({
                value: 60,
                unit: 'C',
                range: [60, 105],
            });
            //@ts-ignore
            props.mv_wires.wires = new AssetsProperty({
                value: [],
                types: ["mv-wire-spec"],
            });
            props.settings.max_power_mv_circuit = NumberProperty.new({
                value: 26,
                unit: "MVA",
                range: [0, Number.MAX_VALUE],
                description: "max power mv circuit",
            });
        });
    }

    return updatedConfig;
}

function migrateToAddAboveGroundToMvWiringConfig(
    config: Config,
    version: ConfigsCollectionVersions
    ){
    let updatedConfig = config;

    if (
        version < ConfigsCollectionVersions.AddAboveGroundToMvWiring &&
        config.type_identifier === MvWiringConfigType
    ) {
        updatedConfig = migrateConfigProps<MvWiringConfig>(config, (props)=>{
            props.mv_wires.above_ground = BooleanProperty.new({
                value: false,
            });
        });
    }

    return updatedConfig;
}

function migrateConfigProps<T extends PropertyGroup>(config:Config, updateConfigFn:(props: T)=>void ):Config {
    const updatedProps = ObjectUtils.deepCloneObj(config.get<T>());
    updateConfigFn(updatedProps);
    const updatedConfig = new Config(
        config.type_identifier,
        config.connectedTo,
        updatedProps
    );
    return updatedConfig;
}
