import { PileBinsConfig, SelectorProperty, SystemOfUnitsConfigTypeIdent, TransformerIdent, type AnyTrackerProps, type Bim, type IdBimScene, type SceneInstances, type TrackerPile, type TrackerPilesCollection } from "bim-ts";
import { IterUtils, LazyVersioned, Success, type Yield } from "engine-utils-ts";
import { type ExportedFileDescription, type FileExporter, type FileExporterContext } from "ui-bindings";
import { CommonExportSettings, getExportProjectName } from "../CommonExportSettings";
import { writePilesProps } from "./PilesPropsToCsv";
import { ProjectInfo, VerdataSyncerType } from "src";


function getBlockNumber(trackerId: IdBimScene, sceneInstances: SceneInstances) {
    const transformerId = sceneInstances.getClosestParentOfTypeFor(trackerId, TransformerIdent, true);    
    if (!transformerId) {
        return undefined;
    }

    const properties = sceneInstances.peekById(transformerId)?.properties;
    if (!properties) {
        return undefined;
    }

    return properties?.get("circuit | position | block_number")?.asNumber();
}


export class PilesToCsvExporter implements FileExporter<CommonExportSettings> {

    constructor(
        readonly bim: Bim,
        readonly trackerPilesCollection: TrackerPilesCollection,
        readonly projectInfo: LazyVersioned<ProjectInfo | undefined>,
        readonly projectVerdataSyncer: VerdataSyncerType,
    ) {
    }

    initialSettings() {
        return {defaultValue: new CommonExportSettings()};
    }

    *startExport(settings: CommonExportSettings, context: FileExporterContext): Generator<Yield, ExportedFileDescription[]> {
        let trackers: Map<IdBimScene, { props: AnyTrackerProps, block_number: number | undefined }>;
        if (settings.export_only_selected) {
            trackers = IterUtils.newMapFromIter(
                this.bim.instances.getSelected(), 
                id => id, 
                id => ({
                    props: this.bim.instances.peekById(id)!.props as AnyTrackerProps,
                    block_number: getBlockNumber(id, this.bim.instances),
                })
            );
        } else {
            trackers = IterUtils.newMapFromIter(
                this.bim.instances.peekByTypeIdent("any-tracker"),
                ([id, _]) => id, 
                ([id, inst]) => ({
                    props: inst.props as AnyTrackerProps,
                    block_number: getBlockNumber(id, this.bim.instances),
                })
            );
        }
    
        const piles: TrackerPile[] = [];
        for (const [_, pile] of this.trackerPilesCollection.poll()) {
            if (trackers.has(pile.parentId)) {
                piles.push(pile);
            }
        }
        piles.sort((a, b) => {
            if (a.position.x === b.position.x) {
                return a.position.y - b.position.y;
            } else {
                return a.position.x - b.position.x;
            }
        });

        let projectUnits: string = "Metric";
        const projectProperties = this.bim.configs.peekSingleton(SystemOfUnitsConfigTypeIdent)?.properties;
        if (projectProperties) {
            projectUnits = (projectProperties['systemOfUnits'] as SelectorProperty).value;
        }

        const baseFileName = getExportProjectName(this.projectVerdataSyncer, this.projectInfo.poll());

        const files = yield* writePilesProps(piles, trackers, this.bim, projectUnits, baseFileName);

        const result: ExportedFileDescription[] = [];
        for (const fileResult of files) {
            if (fileResult instanceof Success) {
                result.push(fileResult.value)
            } else {
                //todo: notifications
                context.logger.error(fileResult.errorMsg())
            }
        }
        return result;
    }
}