import { convertThrow, unitsConverter } from './UnitsConverter';
import { WorkerClassPassRegistry } from './workers/WorkerClassPassRegistry';


export enum ComprArrOrderingType {
    None,
    Tmy_365_24,
}

const ChunkSize = 220;

export class CompressibleNumbersArray {

    public readonly unit: string;
    public readonly orderingType: ComprArrOrderingType = ComprArrOrderingType.None;

    private readonly _length: number;
    private readonly _chunks: NumbersChunk[];

    private readonly _min: number | null;
    private readonly _max: number | null;
    private readonly _sum: number;

    constructor(
        unit: string,
        chunks: NumbersChunk[],
        reordering: ComprArrOrderingType,
    ) {

        this.unit = unit;
        this.orderingType = reordering;

        this._length = chunks.length === 0 ? 0 : (chunks.length - 1) * ChunkSize + chunks[chunks.length - 1].length;
        this._chunks = chunks;

        let min: number = Infinity;
        let max: number = -Infinity;
        let sum: number = 0;
        for (const chunk of chunks) {
            min = Math.min(min, chunk.min());
            max = Math.max(max, chunk.max());
            sum += chunk.sum();
        }

        this._min = chunks.length > 0 ? min : null;
        this._max = chunks.length > 0 ? max : null;
        this._sum = sum;


        Object.freeze(this);
    }

    public static newUncompressed(
        unit: string,
        values: number[] | Float32Array | Float64Array,
    ) {
        return CompressibleNumbersArray.newFromValues(
            unit,
            values,
        );
    }

    public static newFromValues(
        unit: string,
        values: number[] | Float32Array | Float64Array,
        ordered?: ComprArrOrderingType,
    ) {
        if (ordered === undefined) {
            ordered = values.length === 365 * 24 ? ComprArrOrderingType.Tmy_365_24 : ComprArrOrderingType.None;
            if (ordered === ComprArrOrderingType.Tmy_365_24) {
                // reorder values
                const reordedValues = new Float32Array(values.length);
                for (let i = 0; i < values.length; ++i) {
                    const hour = i % 24;
                    const day = (i / 24) | 0;
                    const reordedIndex = hour * 365 + day;
                    reordedValues[reordedIndex] = values[i];
                }
                values = reordedValues;
            }
        }

        const builder  = NumbersCompressor.get(values.length);

        for (let i = 0; i < values.length; i++) {
            builder.push(values[i]);
        }

        const compressed = builder.finish(unit, ordered);

        return compressed;
    }

    public static newSingleValue(
        unit: string,
        value: number,
        length: number,
        ordering: ComprArrOrderingType,
    ) {
        const fullChunk = new SingleValueDataChunk(value, ChunkSize);
        const chunks: NumbersChunk[] = [];
        for (let i = 0; i < length; i += ChunkSize) {
            if (i + ChunkSize <= length) {
                chunks.push(fullChunk);
            } else {
                chunks.push(new SingleValueDataChunk(value, length - i));
            }
        }
        return new CompressibleNumbersArray(unit, chunks, ordering);
    }

    withUnit(unit: string): CompressibleNumbersArray {
        if (this.unit === unit) {
            return this;
        }
        return new CompressibleNumbersArray(unit, this._chunks, this.orderingType);
    }

    equals(other: CompressibleNumbersArray): boolean {
        if (this === other) {
            return true;
        }
        if (this.orderingType !== other.orderingType) {
            return false;
        }
        if (this.unit !== other.unit) {
            return false;
        }
        if (this.length !== other.length) {
            return false;
        }
        for (let ci = 0; ci < this._chunks.length; ci++) {
            const thisChunk = this._chunks[ci];
            const otherChunk = other._chunks[ci];
            for (let i = 0; i < thisChunk.length; i++) {
                if (thisChunk.at(i) !== otherChunk.at(i)) {
                    return false;
                }
            }
        }
        return true;
    }

    at(index: number): number | undefined {
        if (this.orderingType === ComprArrOrderingType.Tmy_365_24) {
            const day = (index / 24) | 0;
            const hour = index % 24;
            index = day + hour * 365;
        } else if (this.orderingType === ComprArrOrderingType.None) {

        } else {
            console.error(`unknown reordering type ${this.orderingType}`);
        }
        const ci = (index / ChunkSize) | 0;
        const chunk = this._chunks[ci];
        if (chunk === undefined) {
            return undefined;
        }
        return chunk.at(index % ChunkSize);
    }

    get length(): number {
        return this._length;
    }

    min(unit?: string): number | undefined {
        if (this._min != null && unit && unit != this.unit) {
            return convertThrow(this._min, this.unit, unit);
        }
        return this._min ?? undefined;
    }
    max(unit?: string): number | undefined {
        if (this._max != null && unit && unit != this.unit) {
            return convertThrow(this._max, this.unit, unit);
        }
        return this._max ?? undefined;
    }
    sum(unit?: string): number {
        if (unit && unit != this.unit) {
            return convertThrow(this._sum, this.unit, unit);
        }
        return this._sum;
    }

    foreach(
        inUnits: string,
        fn: (v: number, index: number) => void,
        ordering: ComprArrOrderingType = ComprArrOrderingType.None,
    ) {
        let unitMapFn: ((value: number, index: number) => number) | undefined = undefined;
        if (inUnits && inUnits != this.unit) {
            unitMapFn = unitsConverter.getConverterFnFromTo(this.unit, inUnits);
        }
        const indexRemapFn = getIndexRemapFunction(this.orderingType, ordering);
        for (let ci = 0; ci < this._chunks.length; ci++) {
            const chunk = this._chunks[ci];
            for (let i = 0; i < chunk.length; i++) {
                let val = chunk.at(i);

                let resultIndex = ci * ChunkSize + i;
                resultIndex = indexRemapFn(resultIndex);
                if (unitMapFn) {
                    val = unitMapFn(val, resultIndex);
                }
                fn(val, resultIndex);
            }
        }
    }

    map(
        resultUnit: string,
        fn: (it: number) => number,
    ): CompressibleNumbersArray {
        return CompressibleNumbersArray.map(this, resultUnit, fn);
    }

    asArrayMapped(mapFn?: (value: number, index: number) => number, orderAs?: ComprArrOrderingType): Float32Array {
        const result = new Float32Array(this._length);
        const indexRemapFn = getIndexRemapFunction(this.orderingType, orderAs ?? ComprArrOrderingType.None);

        for (let ci = 0; ci < this._chunks.length; ci++) {
            const chunk = this._chunks[ci];
            for (let i = 0; i < chunk.length; i++) {
                let val = chunk.at(i);

                let resultIndex = ci * ChunkSize + i;
                resultIndex = indexRemapFn(resultIndex);
                if (mapFn) {
                    val = mapFn(val, resultIndex);
                }
                result[resultIndex] = val;
            }
        }
        return result;
    }

    toArray(inUnits?: string, orderAs?: ComprArrOrderingType): Float32Array {
        let unitMapFn: ((value: number, index: number) => number) | undefined = undefined;
        if (inUnits && inUnits != this.unit) {
            unitMapFn = unitsConverter.getConverterFnFromTo(this.unit, inUnits);
        }
        return this.asArrayMapped(unitMapFn, orderAs);
    }

    static map(
        seq1: CompressibleNumbersArray,
        unit: string,
        fn: (it1: number) => number,
    ): CompressibleNumbersArray {

        const ordering = chooseOrderingType([seq1]);
        seq1 = toArayWithOrdering(seq1, ordering);

        const length = Math.min(seq1.length);
        const res = NumbersCompressor.get(length);
        const chunksCount = Math.ceil(length / ChunkSize);
        for (let ci = 0; ci < chunksCount; ++ci) {
            const ch1 = seq1._chunks[ci];

            const chunkLength = Math.min(ChunkSize, length - ci * ChunkSize);

            if (ch1 instanceof SingleValueDataChunk
            ) {
                const r = fn(ch1.value);
                res.pushSingleValueChunk(r, chunkLength);
                
            } else {
                for (let i = 0; i < chunkLength; i++) {
                    const v1 = ch1.at(i);
                    const r = fn(v1);
                    res.push(r);
                }
            }
        }
        return res.finish(unit, ordering);
    }
    static map2(
        seq1: CompressibleNumbersArray,
        seq2: CompressibleNumbersArray,
        unit: string,
        fn: (it1: number, it2: number) => number
    ): CompressibleNumbersArray {

        const ordering = chooseOrderingType([seq1, seq2]);
        seq1 = toArayWithOrdering(seq1, ordering);
        seq2 = toArayWithOrdering(seq2, ordering);

        const length = Math.min(seq1.length);
        const res = NumbersCompressor.get(length);
        const chunksCount = Math.ceil(length / ChunkSize);

        for (let ci = 0; ci < chunksCount; ++ci) {
            const ch1 = seq1._chunks[ci];
            const ch2 = seq2._chunks[ci];

            const chunkLength = Math.min(ChunkSize, length - ci * ChunkSize);

            if (ch1 instanceof SingleValueDataChunk
                && ch2 instanceof SingleValueDataChunk
            ) {
                const r = fn(ch1.value, ch2.value);
                res.pushSingleValueChunk(r, chunkLength);

            } else {
                for (let i = 0; i < chunkLength; i++) {
                    const v1 = ch1.at(i);
                    const v2 = ch2.at(i);
                    const r = fn(v1, v2);
                    res.push(r);
                }
            }
        }

        return res.finish(unit, ordering);
    }
    static map3(
        seq1: CompressibleNumbersArray,
        seq2: CompressibleNumbersArray,
        seq3: CompressibleNumbersArray,
        unit: string,
        fn: (it1: number, it2: number, it3: number) => number
    ): CompressibleNumbersArray {
                
        const ordering = chooseOrderingType([seq1, seq2, seq3]);
        seq1 = toArayWithOrdering(seq1, ordering);
        seq2 = toArayWithOrdering(seq2, ordering);
        seq3 = toArayWithOrdering(seq3, ordering);

        const length = Math.min(seq1.length);
        const res = NumbersCompressor.get(length);
        const chunksCount = Math.ceil(length / ChunkSize);

        for (let ci = 0; ci < chunksCount; ++ci) {
            const ch1 = seq1._chunks[ci];
            const ch2 = seq2._chunks[ci];
            const ch3 = seq3._chunks[ci];

            const chunkLength = Math.min(ChunkSize, length - ci * ChunkSize);

            if (ch1 instanceof SingleValueDataChunk
                && ch2 instanceof SingleValueDataChunk
                && ch3 instanceof SingleValueDataChunk
            ) {
                const r = fn(ch1.value, ch2.value, ch3.value);
                res.pushSingleValueChunk(r, chunkLength);

            } else {
                for (let i = 0; i < chunkLength; i++) {
                    const v1 = ch1.at(i);
                    const v2 = ch2.at(i);
                    const v3 = ch3.at(i);
                    const r = fn(v1, v2, v3);
                    res.push(r);
                }
            }

        }
        return res.finish(unit, ordering);
    }
    static map4(
        seq1: CompressibleNumbersArray,
        seq2: CompressibleNumbersArray,
        seq3: CompressibleNumbersArray,
        seq4: CompressibleNumbersArray,
        unit: string,
        fn: (it1: number, it2: number, it3: number, it4: number) => number
    ): CompressibleNumbersArray {
                        
        const ordering = chooseOrderingType([seq1, seq2, seq3, seq4]);
        seq1 = toArayWithOrdering(seq1, ordering);
        seq2 = toArayWithOrdering(seq2, ordering);
        seq3 = toArayWithOrdering(seq3, ordering);
        seq4 = toArayWithOrdering(seq4, ordering);

        const length = Math.min(seq1.length, seq2.length, seq3.length, seq4.length);
        const res = NumbersCompressor.get(length);
        const chunksCount = Math.ceil(length / ChunkSize);

        for (let ci = 0; ci < chunksCount; ++ci) {
            const ch1 = seq1._chunks[ci];
            const ch2 = seq2._chunks[ci];
            const ch3 = seq3._chunks[ci];
            const ch4 = seq4._chunks[ci];

            const chunkLength = Math.min(ChunkSize, length - ci * ChunkSize);

            if (ch1 instanceof SingleValueDataChunk
                && ch2 instanceof SingleValueDataChunk
                && ch3 instanceof SingleValueDataChunk
                && ch4 instanceof SingleValueDataChunk
            ) {
                const r = fn(ch1.value, ch2.value, ch3.value, ch4.value);
                res.pushSingleValueChunk(r, chunkLength);

            } else {
                for (let i = 0; i < chunkLength; i++) {
                    const v1 = ch1.at(i);
                    const v2 = ch2.at(i);
                    const v3 = ch3.at(i);
                    const v4 = ch4.at(i);
                    const r = fn(v1, v2, v3, v4);
                    res.push(r);
                }
            }

        }
        return res.finish(unit, ordering);
    }
    static map5(
        seq1: CompressibleNumbersArray,
        seq2: CompressibleNumbersArray,
        seq3: CompressibleNumbersArray,
        seq4: CompressibleNumbersArray,
        seq5: CompressibleNumbersArray,
        unit: string,
        fn: (it1: number, it2: number, it3: number, it4: number, it5: number) => number
    ): CompressibleNumbersArray {

        const ordering = chooseOrderingType([seq1, seq2, seq3, seq4, seq5]);
        seq1 = toArayWithOrdering(seq1, ordering);
        seq2 = toArayWithOrdering(seq2, ordering);
        seq3 = toArayWithOrdering(seq3, ordering);
        seq4 = toArayWithOrdering(seq4, ordering);
        seq5 = toArayWithOrdering(seq5, ordering);

        const length = Math.min(seq1.length, seq2.length, seq3.length, seq4.length);
        const res = NumbersCompressor.get(length);
        const chunksCount = Math.ceil(length / ChunkSize);

        for (let ci = 0; ci < chunksCount; ++ci) {
            const ch1 = seq1._chunks[ci];
            const ch2 = seq2._chunks[ci];
            const ch3 = seq3._chunks[ci];
            const ch4 = seq4._chunks[ci];
            const ch5 = seq5._chunks[ci];

            const chunkLength = Math.min(ChunkSize, length - ci * ChunkSize);

            if (ch1 instanceof SingleValueDataChunk
                && ch2 instanceof SingleValueDataChunk
                && ch3 instanceof SingleValueDataChunk
                && ch4 instanceof SingleValueDataChunk
                && ch5 instanceof SingleValueDataChunk
            ) {
                const r = fn(ch1.value, ch2.value, ch3.value, ch4.value, ch5.value);
                res.pushSingleValueChunk(r, chunkLength);

            } else {
                for (let i = 0; i < chunkLength; i++) {
                    const v1 = ch1.at(i);
                    const v2 = ch2.at(i);
                    const v3 = ch3.at(i);
                    const v4 = ch4.at(i);
                    const v5 = ch5.at(i);
    
                    const r = fn(v1, v2, v3, v4, v5);
                    res.push(r);
                }
            }

        }
        return res.finish(unit, ordering);
    }
    static map6(
        seq1: CompressibleNumbersArray,
        seq2: CompressibleNumbersArray,
        seq3: CompressibleNumbersArray,
        seq4: CompressibleNumbersArray,
        seq5: CompressibleNumbersArray,
        seq6: CompressibleNumbersArray,
        unit: string,
        fn: (it1: number, it2: number, it3: number, it4: number, it5: number, it6: number) => number
    ): CompressibleNumbersArray {

        const ordering = chooseOrderingType([seq1, seq2, seq3, seq4, seq5, seq6]);
        seq1 = toArayWithOrdering(seq1, ordering);
        seq2 = toArayWithOrdering(seq2, ordering);
        seq3 = toArayWithOrdering(seq3, ordering);
        seq4 = toArayWithOrdering(seq4, ordering);
        seq5 = toArayWithOrdering(seq5, ordering);
        seq6 = toArayWithOrdering(seq6, ordering);

        const length = Math.min(seq1.length, seq2.length, seq3.length, seq4.length);
        const res = NumbersCompressor.get(length);
        const chunksCount = Math.ceil(length / ChunkSize);

        for (let ci = 0; ci < chunksCount; ++ci) {
            const ch1 = seq1._chunks[ci];
            const ch2 = seq2._chunks[ci];
            const ch3 = seq3._chunks[ci];
            const ch4 = seq4._chunks[ci];
            const ch5 = seq5._chunks[ci];
            const ch6 = seq6._chunks[ci];

            const chunkLength = Math.min(ChunkSize, length - ci * ChunkSize);

            for (let i = 0; i < chunkLength; i++) {
                const v1 = ch1.at(i);
                const v2 = ch2.at(i);
                const v3 = ch3.at(i);
                const v4 = ch4.at(i);
                const v5 = ch5.at(i);
                const v6 = ch6.at(i);

                const r = fn(v1, v2, v3, v4, v5, v6);
                res.push(r);
            }
        }
        return res.finish(unit, ordering);
    }
}

function passThroughIndexFunction(index: number): number {
    return index;
}

function normalIndexToTmyIndex(index: number): number {
    const hour = index % 24;
    const day = (index / 24) | 0;
    const reordedIndex = hour * 365 + day;
    return reordedIndex;
}

function tmyIndexToNormalIndex(index: number): number {
    const day = (index % 365) | 0;
    const hour = (index / 365) | 0;
    const reordedIndex = day * 24 + hour;
    return reordedIndex;
}

function getIndexRemapFunction(from: ComprArrOrderingType, to: ComprArrOrderingType): (index: number) => number {
    if (from === to) {
        return passThroughIndexFunction;
    }
    if (from === ComprArrOrderingType.None && to === ComprArrOrderingType.Tmy_365_24) {
        return normalIndexToTmyIndex;
    }
    if (from === ComprArrOrderingType.Tmy_365_24 && to === ComprArrOrderingType.None) {
        return tmyIndexToNormalIndex;
    }
    throw new Error(`unexpected ordering type ${from} -> ${to}`);
}

function chooseOrderingType(arrays: CompressibleNumbersArray[]): ComprArrOrderingType {
    if (arrays.length === 0) {
        throw new Error(`unexpected empty arrays`);
    }
    let res: ComprArrOrderingType = arrays[0].orderingType;
    for (let i = 1; i < arrays.length; ++i) {
        if (arrays[i].orderingType !== res) {
            return ComprArrOrderingType.None;
        }
    }
    return res;
}

function toArayWithOrdering(arr: CompressibleNumbersArray, ordering: ComprArrOrderingType): CompressibleNumbersArray {
    if (arr.orderingType === ordering) {
        return arr;
    }
    if (ordering === ComprArrOrderingType.None) {
        console.warn('reordering to none', arr.unit);
        const flat = arr.toArray();
        return CompressibleNumbersArray.newFromValues(arr.unit, flat, ordering)

    } else {
        throw new Error(`unexpected ordering type ${ordering}`);
    }
}


interface NumbersChunk {
    length: number;
    at(index: number): number;
    min(): number;
    max(): number;
    sum(): number;
}

class SingleValueDataChunk implements NumbersChunk {
    constructor(
        public readonly value: number,
        public readonly length: number,
    ) {
    }
    at(index: number): number {
        return this.value;
    }
    min(): number {
        return this.value;
    }
    max(): number {
        return this.value;
    }
    sum(): number {
        return this.value * this.length;
    }
}
WorkerClassPassRegistry.registerClass(SingleValueDataChunk);

// class RangeCompressedArrayDataChunk implements NumbersChunk {

//     readonly multiplier: number;

//     constructor(
//         public readonly minValue: number,
//         public readonly maxValue: number,
//         public readonly multipliers: Uint16Array,
//     ) {
//         this.multiplier = (maxValue - minValue) / 0xFFFF;
//         if (!(this.multiplier > 0)) {
//             throw new Error(`multiplier should be > 0: ${this.multiplier}`);
//         }
//     }

//     get length(): number {
//         return this.multipliers.length;
//     }
//     at(index: number): number {
//         const mult = this.multipliers[index];
//         return this.minValue + mult * this.multiplier;
//     }
//     min(): number {
//         return this.minValue;
//     }
//     max(): number {
//         return this.maxValue;
//     }
//     sum(): number {
//         let multipliersSum = 0;
//         for (let i = 0; i < this.multipliers.length; i++) {
//             multipliersSum += this.multipliers[i];
//         }
//         return this.minValue * this.length + this.multiplier * multipliersSum;
//     }

//     static canRangeCompress(min: number, max: number): boolean {
//         return false;
//         if (min > max) {
//             throw new Error(`min should be < max: ${min} < ${max}`);
//         }
//         const step = (max - min) / 0xFFFF;
//         const maxAbsValue = Math.max(Math.abs(min), Math.abs(max));
//         return step / maxAbsValue < 0.000_1;
//     }
//     static newFromValues(
//         minValue: number,
//         maxValue: number,
//         values: number[] | Float32Array | Float64Array,
//         inValuesOffset: number,
//         count: number
//     ): RangeCompressedArrayDataChunk {
//         const multipliers = new Uint16Array(count);
//         const range = maxValue - minValue;
//         for (let i = 0; i < count; i++) {
//             const v = values[inValuesOffset + i];
//             const mult = ((v - minValue) / range) * 0xFFFF;
//             multipliers[i] = mult;
//         }
//         return new RangeCompressedArrayDataChunk(minValue, maxValue, multipliers);
//     }
// } 
// WorkerClassPassRegistry.registerClass(RangeCompressedArrayDataChunk);

class UncompressedDataChunk  implements NumbersChunk {

    constructor(
        public readonly minValue: number,
        public readonly maxValue: number,
        public readonly values: Float32Array,
    ) {
    }

    get length(): number {
        return this.values.length;
    }
    at(index: number): number {
        return this.values[index];
    }
    min(): number {
        return this.minValue;
    }
    max(): number {
        return this.maxValue;
    }
    sum(): number {
        let sum = 0;
        for (let i = 0; i < this.values.length; i++) {
            const v = this.values[i];
            if (Number.isFinite(v)) {
                sum += v;
            }
        }
        return sum;
    }
}
WorkerClassPassRegistry.registerClass(UncompressedDataChunk);

class NumbersCompressor {

    _inChunkMin: number = Infinity;
    _inChunkMax: number = -Infinity;
    _nextIndex: number = 0;
    _bufferForChunks: Float32Array = new Float32Array(365 * 24);

    _chunksRanges: { min: number, max: number, start: number, end: number}[] = [];

    constructor() {
    }

    reset(expectedLength: number) {
        if (this._bufferForChunks.length < expectedLength) {
            this._bufferForChunks = new Float32Array(expectedLength);
        }
        this._nextIndex = 0;
        this._chunksRanges.length = 0;
        return this;
    }

    _finishChunk() {
        const start = Math.max(0, Math.floor((this._nextIndex - 1) / ChunkSize)) * ChunkSize;
        this._chunksRanges.push({min: this._inChunkMin, max: this._inChunkMax, start, end: this._nextIndex});
        this._inChunkMin = Infinity;
        this._inChunkMax = -Infinity;
    }

    push(value: number) {
        this._inChunkMin = Math.min(this._inChunkMin, value);
        this._inChunkMax = Math.max(this._inChunkMax, value);
        this._bufferForChunks[this._nextIndex] = value;
        this._nextIndex += 1;

        if (this._nextIndex % ChunkSize === 0) {
            this._finishChunk();
        }
    }

    pushSingleValueChunk(value: number, length: number) {
        if (this._nextIndex % ChunkSize !== 0) {
            throw new Error(`unexpected attempt to push chunk with single value, when current unfinished chunk is not empty ${this._nextIndex}`);
        }
        // for (let i = 0; i < length; i++) {
        //     this.push(value);
        // }
        this._chunksRanges.push({min: value, max: value, start: this._nextIndex - length, end: this._nextIndex});
    }

    static get(expectedLength: number) {
        if (_reusedCompressors.length === 0) {
            return new NumbersCompressor();
        }
        return _reusedCompressors.pop()!.reset(expectedLength);
    }

    finish(unit: string, reorderingTy: ComprArrOrderingType): CompressibleNumbersArray {
        if (this._nextIndex % ChunkSize !== 0) {
            this._finishChunk();
        }

        const chunksResult: NumbersChunk[] = new Array(this._chunksRanges.length);

        let chunksUncompressedBufferSize = 0;
        for (let i = 0; i < this._chunksRanges.length; ++i) {
            const chr = this._chunksRanges[i];
            if (chr.min === chr.max) {
                chunksResult[i] = new SingleValueDataChunk(chr.min, chr.end - chr.start);
            }  else {
                chunksUncompressedBufferSize += (chr.end - chr.start);
            }
        }

        const sharedBuf = new Float32Array(chunksUncompressedBufferSize);
        let currentInBufferOffset = 0;
        for (let i = 0; i < chunksResult.length; ++i) {
            if (chunksResult[i] !== undefined) {
                continue;
            }
            const chr = this._chunksRanges[i];
            const chunkLength = chr.end - chr.start;
            const b = sharedBuf.subarray(currentInBufferOffset, currentInBufferOffset + chunkLength);
            b.set(this._bufferForChunks.subarray(chr.start, chr.end));
            const chunk = new UncompressedDataChunk(chr.min, chr.max, b);
            chunksResult[i] = chunk;
            currentInBufferOffset += chunkLength;
        }

        console.assert(currentInBufferOffset === sharedBuf.length, 'compressed buffer size mismatch');

        const res = new CompressibleNumbersArray(unit, chunksResult, reorderingTy);
        return res;
    }
}

WorkerClassPassRegistry.registerClass(CompressibleNumbersArray);

const _reusedCompressors: NumbersCompressor[] = []

