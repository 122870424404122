<script lang="ts">
    import { AnyTrackerProps, Bim, EditTrackerPilesContext } from "bim-ts";
    import { getContext } from "svelte";
    import { ContextMenuConfig, PUI_CustomPropertyNode, UiBindings, UiCustomNode } from "ui-bindings";
    import ActionProperty from '../../pui/ActionProperty.svelte';
    import { selectSpecificTrackers } from "../../piles-positions/PilePositions";
    import { LazyDerived } from "engine-utils-ts";
    import { VersionedStore } from "src/VersionedStore";


    export let property: PUI_CustomPropertyNode<EditTrackerPilesContext, {}>;
    const bim = getContext<Bim>('bim');
    const ui = getContext<UiBindings>('uiBindings');
    
    $: innerWidth = 0;
    $: innerHeight = 0;

    const offsetPx = 30;
    const panelWidthPx = 600;
    const trackersList = selectSpecificTrackers(bim, property.value);

    $: headerLazy = LazyDerived.new1(
        'header', 
        null, 
        [trackersList], 
        ([trackers]) => {
            if(trackers.length === 1) {
                const inst = trackers[0][1];
                const name = inst.name;
                const props = inst.propsAs(AnyTrackerProps);
                return name ? name : props.tracker_frame.commercial.manufacturer.value + ' ' + props.tracker_frame.commercial.model.value;
            }
        return 'Pile positions';
    });

    $:header = new VersionedStore<string>(headerLazy);
    
</script>

<svelte:window bind:innerWidth bind:innerHeight />

<div class="root">
    <div class="property-row">
        <span class="ui-config-property property-row buttons">
            <ActionProperty
                actionDescr={{
                    label: "Pile positions",
                    action: () => {
                        
                        ui.addContextMenuView(new ContextMenuConfig({
                            identity: "pile-positions-context-menu",
                            header: $header,
                            viewSource: new UiCustomNode({
                                context: property.value,
                            }),
                            positionPx: { x: innerWidth / 2 - panelWidthPx / 2, y: offsetPx },
                            widthPx: panelWidthPx,
                            maxHeightPx: innerHeight - offsetPx * 3,
                            action: {
                                name: "Ok"
                            },
                        }));
                    }
                }}
                context={null}
            ></ActionProperty>
        </span>
    </div>
</div>


<style>
    .root {
        width: 100%;
    }
</style>