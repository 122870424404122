import type { Result} from "engine-utils-ts";
import { Failure, Success } from "engine-utils-ts";
import type { Bim, IdBimScene } from "src";
import { createTransformerObject2DWithChilds } from "./templates/transformer";
import type { Object2D } from "vector-graphic";
import { createTransformerDescriptionFromScene } from "./utils";

export function generateSLDForTransformer(
    bim: Bim,
    transformerId: IdBimScene
): Result<Object2D> {
    const description = createTransformerDescriptionFromScene(bim, transformerId);
    if (description instanceof Failure) {
        return description;
    }
    const obj = createTransformerObject2DWithChilds(description.value)
    return new Success(obj);
}
