// automatically generated by the FlatBuffers compiler, do not modify

import { BasicAnalyticalRepresentation } from './basic-analytical-representation.js';


export enum SceneObjectAnalyticalRepresentation {
  NONE = 0,
  BasicAnalyticalRepresentation = 1
}

export function unionToSceneObjectAnalyticalRepresentation(
  type: SceneObjectAnalyticalRepresentation,
  accessor: (obj:BasicAnalyticalRepresentation) => BasicAnalyticalRepresentation|null
): BasicAnalyticalRepresentation|null {
  switch(SceneObjectAnalyticalRepresentation[type]) {
    case 'NONE': return null; 
    case 'BasicAnalyticalRepresentation': return accessor(new BasicAnalyticalRepresentation())! as BasicAnalyticalRepresentation;
    default: return null;
  }
}

export function unionListToSceneObjectAnalyticalRepresentation(
  type: SceneObjectAnalyticalRepresentation, 
  accessor: (index: number, obj:BasicAnalyticalRepresentation) => BasicAnalyticalRepresentation|null, 
  index: number
): BasicAnalyticalRepresentation|null {
  switch(SceneObjectAnalyticalRepresentation[type]) {
    case 'NONE': return null; 
    case 'BasicAnalyticalRepresentation': return accessor(index, new BasicAnalyticalRepresentation())! as BasicAnalyticalRepresentation;
    default: return null;
  }
}
