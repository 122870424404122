import type {
	BasicAnalyticalRepresentation, Bim, IdBimGeo, IdBimScene,
	ObjectRepresentation, SceneObjDiff} from 'bim-ts';
import { newFlagsPatch,
	SparseFlaggedSets,
} from 'bim-ts';
import type {
    BasicCollectionUpdates,
	LazyVersioned,
	ObservableObject,
	ScopedLogger, TasksRunner, UndoStack} from 'engine-utils-ts';
import {
	DefaultMap, DefaultMapObjectKey, Deleted, IterUtils, LazyDerived, StreamAccumulator, Updated,
} from 'engine-utils-ts';
import type { Aabb } from 'math-ts';

import type { ClipBox } from '../clipbox/ClipBox';
import type { ESO, ESO_Any } from '../esos/ESO';
import { ESO_AnotationFlags } from '../esos/ESO';
import { ESO_BasicAnalyticalHandler } from '../esos/ESO_BasicAnalytical';
import { ESO_BoundariesHandler } from '../esos/ESO_Boundary';
import type { ESO_Diff } from '../esos/ESO_Diff';
import { ESO_EmptyHandler } from '../esos/ESO_Empty';
import { ESO_TerrainHeightmapHandler } from '../esos/ESO_Terrain';
import { ESO_RoadHandler } from '../esos/ESO_Road';
import { ESO_SceneImageHandler } from '../esos/ESO_SceneImage';
import { ESO_StdStaticHandler } from '../esos/ESO_StdStatic';
import type { ESOsHandlerBase, ESOsHandlerInputs } from '../esos/ESOsHandlerBase';
import type { ESSO_Flags } from '../esos/ESSO';
import type { AllEngineGeometries } from '../geometries/AllEngineGeometries';
import type { EngineImages } from '../materials/EngineImages';
import type { Handle} from '../memory/Handle';
import { HandleIndexMask } from '../memory/Handle';
import { createAndBindBinaryAllocator } from '../memory/MemoryUtils';
import type { Submeshes2 } from '../scene/Submeshes2';
import { EnumsGateway } from '../structs/EnumsGateway';
import type { ECollUpdate} from './EngineCollection';
import {
	ECollAllocated, ECollDeleted, ECollPatched, EngineCollection,
} from './EngineCollection';
import type { InObjFullId } from './EngineSceneIds';
import { ESSOsCollection } from './ESSOsCollection';
import type { GeometryCtor, GeometryEditsHandlerAny } from './GeometryEditsHandler';
import { GraphGeometryEditsHandler } from './GraphGeometryEditsHandler';
import { PolygonGeometryEditsHandler } from './PolygonGeometryEditsHandler';
import { PolylineGeometryEditsHandler } from './PolylineGeometryEditsHandler';
import type { EngineFullGraphicsSettings } from '../GraphicsSettingsFull';
import { ESO_TrenchHandler } from '../esos/ESO_Trench';
import { StdStaticAnnotationsCalculator } from '../annotations/StdStaticAnnotationsCalculator';
import type { AnnotationsSettings } from '../annotations/AnnotationsSettingsUiBindings';
import { ESO_TrackerHandler } from 'src/esos/ESO_Tracker';
import type { TrackersPilesMarkupSettings } from 'src/TrackersPilesMarkupSettingsUiBindings';

export type ESOHandle = ESOsCollection & Handle;

export type EsoConstructor = {new(...args: any[]): ESO};

export type BimReprsBboxes = DefaultMapObjectKey<ObjectRepresentation | BasicAnalyticalRepresentation, Aabb>;

// export type EngineOnlyId =

export type SubObjsSparseFlags = SparseFlaggedSets<InObjFullId, ESSO_Flags>;

export class ESOsCollection extends EngineCollection<ESOHandle, ESO_Any, IdBimScene, ESO_Diff> {

	readonly typeIds: EnumsGateway<number, Uint8Array>;
	readonly _typeIdsGenerator: DefaultMap<EsoConstructor, number>; // should never delete from here
	readonly _perTypeObjectsHandlers = new Map<typeof ESO, ESOsHandlerBase<ESO>>();

	private readonly _annotationFlagsRedundant = new SparseFlaggedSets<ESOHandle, ESO_AnotationFlags>(ESO_AnotationFlags.IsInEdit);

	readonly geometriesEditsHandlers = new Map<GeometryCtor, GeometryEditsHandlerAny>();

	readonly esosHandlers: ESOsHandlerBase<any>[];

	readonly subObjects: ESSOsCollection;
	readonly submeshes: Submeshes2;


	readonly _bimRepresentationsBoxes: LazyDerived<BimReprsBboxes>;
    private _aabbsUpdatesAccumulator: StreamAccumulator<BasicCollectionUpdates<IdBimGeo>>;

	constructor(
		logger: ScopedLogger,
		undoStack: UndoStack,
		clipbox: ClipBox,
		bim: Bim,
		staticSubmeshes: Submeshes2,
		engineGeometries: AllEngineGeometries,
		engineImages: EngineImages,
		tasksRunner: TasksRunner,
		graphicsSettings: LazyVersioned<EngineFullGraphicsSettings>,
		annotationsSettings: ObservableObject<AnnotationsSettings>,
		trackersPilesMarkupSettings: ObservableObject<TrackersPilesMarkupSettings>
	) {
		super(
			'eso-s',
			logger,
		)
		staticSubmeshes.esos = this;
		this.submeshes = staticSubmeshes;
		this._typeIdsGenerator = new DefaultMap(c => {
			const id = this._typeIdsGenerator.size;
			if (id > 255) {
				throw new Error('to many types, should increase per id space, uint8 array type is not enough');
			}
			return id;
		});

		this.typeIds = new EnumsGateway<number, Uint8Array>('eso type ids');
		createAndBindBinaryAllocator(
			this.allocSyncer,
			this.typeIds,
			args => this._typeIdsGenerator.getOrCreate(args[1].constructor  as new (...args: any[]) => ESO),
			size => new Uint8Array(size),
		);

		const aabbs = bim.allBimGeometries.aabbs;

		this._aabbsUpdatesAccumulator = new StreamAccumulator(aabbs.updatesStream);

		this._bimRepresentationsBoxes = LazyDerived.new1(
			'bim-repr-bboxes',
			[this._aabbsUpdatesAccumulator],
			[aabbs],
			([aabbs], prevResult) => {
				const cache: BimReprsBboxes = prevResult ?? new DefaultMapObjectKey({
					valuesFactory: r => r.aabb(aabbs),
					unique_hash: (r) => r.asString(),
				});
				const updates = this._aabbsUpdatesAccumulator.consume();
				if (updates) {
					const geoIdsToInvalidate = new Set<IdBimGeo>();
					for (const update of updates) {
						if (update instanceof Updated) {
							IterUtils.extendSet(geoIdsToInvalidate, update.ids);
						} else if (update instanceof Deleted) {
							IterUtils.extendSet(geoIdsToInvalidate, update.ids);
						}
					}
					if (geoIdsToInvalidate.size) {
						const geoIds: IdBimGeo[] = [];
						const reprsToDelete: (ObjectRepresentation | BasicAnalyticalRepresentation)[] = [];
						for (const repr of cache.keys()) {
							geoIds.length = 0;
							repr.geometriesIdsReferences(geoIds);
							for (const id of geoIds) {
								if (geoIdsToInvalidate.has(id)) {
									reprsToDelete.push(repr);
									break;
								}
							}
						}
						for (const r of reprsToDelete) {
							cache.delete(r);
						}
					}
				}
				return cache;
			}
		)

		const geometriesHandlers: GeometryEditsHandlerAny[] = [
			new PolygonGeometryEditsHandler(this.logger, 'polygons-edits'),
			new PolylineGeometryEditsHandler(this.logger, 'polylines-edits'),
			new GraphGeometryEditsHandler(this.logger, 'graph-edits'),
		];
		for (const geoHandler of geometriesHandlers) {
			this.geometriesEditsHandlers.set(geoHandler.geometryCtor(), geoHandler);
		}

		const handlerCtorArgs: ESOsHandlerInputs = {
			geometries: engineGeometries,
			bimGeos: bim.allBimGeometries,
			images: engineImages,
			materials: staticSubmeshes.engineMaterials,
			submeshes: staticSubmeshes,
			logger: this.logger,
			geometriesEditHandlers: this.geometriesEditsHandlers,
			tasksRunner,
			graphicsSettings,
		};
		
		this.esosHandlers = [
			new ESO_TrackerHandler("trackers", handlerCtorArgs, trackersPilesMarkupSettings, bim.configs),
			new ESO_StdStaticHandler('std-static', handlerCtorArgs, new StdStaticAnnotationsCalculator(bim, this, annotationsSettings)),
			new ESO_BoundariesHandler('basic-polygon', handlerCtorArgs),
			new ESO_RoadHandler('roads', handlerCtorArgs),
			new ESO_TrenchHandler('trenches', handlerCtorArgs),
			new ESO_SceneImageHandler('scene-images', handlerCtorArgs),
			new ESO_TerrainHeightmapHandler('heightmaps', handlerCtorArgs),
			new ESO_BasicAnalyticalHandler('basic-analyticals', handlerCtorArgs),
			new ESO_EmptyHandler('esos-empty', handlerCtorArgs),
		];

		for (const handler of this.esosHandlers) {
			for (const ty of handler.esosTypesToHandle()) {
				this._perTypeObjectsHandlers.set(ty, handler);
			}
		}

		this.updatesStream.subscribe({
			settings: {immediateMode: true, doNotNotifyCurrentState: true},
			onNext: (update: ECollUpdate<ESOHandle, IdBimScene, ESO_Diff>) => {
				if (update instanceof ECollAllocated) {
					for (const [handler, handles] of this.groupHandlesByType(update.allocated)) {
						handler.onAllocated(handles);
					}
				} else if (update instanceof ECollPatched) {
					// this.staticSubmeshes.markDirtyByParentDiffs(update.updated)

				} else if (update instanceof ECollDeleted) {
					for (const [handler, handles] of this.groupHandlesTuplesByType(update.deleted)) {
						handler.onDeleted(handles.map(t => t[0]));
					}
					for (const [h, id] of update.deleted) {
						this._annotationFlagsRedundant.updateSceneInstanceFlagsFor(h, 0);
					}
				}
			}
		});

		this.subObjects = new ESSOsCollection(this, undoStack, clipbox, bim, this.submeshes, engineGeometries, engineImages);
	}

	dispose() {
		for (const esosHandler of this.esosHandlers) {
			esosHandler.dispose();
		}
        this._aabbsUpdatesAccumulator.dispose();
	}

    isSynced(): boolean {
        for (const esosHandler of this.esosHandlers) {
            if (!esosHandler.isSynced()) {
                return false;
            }
        }
        return true;
    }


	// updateBounds() {
	// 	this._applyBimGeometriesUpdates();
	// 	for (let h of this.allocSyncer.getActiveHandles()) {
	// 		const eso = this.objsPerHandleIndex[h & HandleIndexMask];
	// 		let aabb: Aabb;
	// 		const repr = eso?.bimRepresentation();
	// 		if (repr) {
	// 			aabb = this._bimRepresentationsBboxes.getOrCreate(repr);
	// 			aabb.applyMatrix4(eso!.worldMatrix);
	// 		} else {
	// 			aabb = EmptyBox;
	// 		}
	// 		if (this.bounds.toBufferEqualityCheck(aabb, h & HandleIndexMask)) {
	// 			this.scene.markDirty(h);
	// 		}
	// 	}
	// 	this.scene.updateWasmSceneHierarchy();

	// }

	propogateBimUpdatesToEngineObjs(updates: Iterable<[IdBimScene, SceneObjDiff]>) {
		for (const [handler, groupDiffs] of this.mapToHandlesGroupedByType(updates)) {
			handler.applyBimUpdates(this, groupDiffs);
		}
	}

	applyAnnotationFlagsTo(allPatches: [ESOHandle, ESO_AnotationFlags][]) {
		for (const [handler, patches] of this.groupHandlesTuplesByType(allPatches)) {
			handler.onAnnotationsUpdates(this, patches, this._annotationFlagsRedundant);
		}
	}

	setEditableObjects(ids: IdBimScene[]) {
		const absentIds: IdBimScene[] = [];
		const handles = this.handlesOf(ids, absentIds);
		if (absentIds.length > 0) {
			// probably sync sequence mistake, should set editable objects only after bim sync
			this.logger.error('could not make objects editable, ids absent', ids);
		}
		const allPatches = this._annotationFlagsRedundant.diffToMakeFlagEnabledOnlyFor(
			ESO_AnotationFlags.IsInEdit,
			handles,
			newFlagsPatch(ESO_AnotationFlags.IsInEdit, true),
			newFlagsPatch(ESO_AnotationFlags.IsInEdit, false),
		);
		if (allPatches.length) {
			this.applyAnnotationFlagsTo(allPatches);
		}
	}

	applySelfImposedUpdates() {
		for (const handler of this.esosHandlers) {
			handler.applySelfImposedUpdates(this);
		}
	}

	reconcileSubobjects() {
		for (const handler of this.esosHandlers) {
			handler.reconcileSubObjectsAndNotify(this);
		}
	}

	mapToHandlesGroupedByType<T>(tuples: Iterable<[IdBimScene, T]>): [ESOsHandlerBase<ESO>, [ESOHandle, T][]][] {
		const mappedToHandles: [ESOHandle, T][] = [];
		for (const [id, obj] of tuples) {
			const h = this.idsToHandles.get(id);
			if (h !== undefined) {
				mappedToHandles.push([h, obj]);
			}
		}
		return this.groupHandlesTuplesByType(mappedToHandles);
	}

	groupHandlesTuplesByType<T>(tuples: Iterable<[ESOHandle, T]>): [ESOsHandlerBase<ESO>, [ESOHandle, T][]][] {
		const handlesTuplesPerGroupTypeId = new DefaultMap<number, [ESOHandle, T][]>(_ => []);
		let prevGroup:  [ESOHandle, T][];
		let prevGroupTy: number = -1;
		for (const t of tuples) {
			const handle = t[0];
			const typeId = this.typeIds.buffer[handle & HandleIndexMask];
			if (prevGroupTy !== typeId) {
				prevGroupTy = typeId;
				prevGroup = handlesTuplesPerGroupTypeId.getOrCreate(typeId);
			}
			prevGroup!.push(t);
		}
		const res: [ESOsHandlerBase<ESO>, [ESOHandle, T][]][] = [];
		for (const [tyId, group] of handlesTuplesPerGroupTypeId) {
			const ctor = IterUtils.find(this._typeIdsGenerator, (t) => t[1] === tyId)?.[0];
			if (ctor === undefined) {
				this.logger.error('constructor id mapping is erroneus, could not find constructor', tyId);
				continue;
			}
			const handler = this._perTypeObjectsHandlers.get(ctor);
			if (handler === undefined) {
				this.logger.error('handler for constructor is not registered', ctor);
				continue;
			}
			res.push([handler, group]);
			if (group.length > 20) {
				// sort handles if group is large enought
				// make acesses to them later more predictable
				group.sort((l, r) => {
					const indexL = l[0] & HandleIndexMask;
					const indexR = r[0] & HandleIndexMask;
					return indexL - indexR;
				});
			}
		}
		return res;
	}

	groupHandlesByType(handles: Iterable<ESOHandle>): [ESOsHandlerBase<ESO>, ESOHandle[]][] {
		const handlesTuplesPerGroupTypeId = new DefaultMap<number, ESOHandle[]>(_ => []);
		let prevGroup:  ESOHandle[];
		let prevGroupTy: number = -1;
		for (const handle of handles) {
			const typeId = this.typeIds.buffer[handle & HandleIndexMask];
			if (prevGroupTy !== typeId) {
				prevGroupTy = typeId;
				prevGroup = handlesTuplesPerGroupTypeId.getOrCreate(typeId);
			}
			prevGroup!.push(handle);
		}
		const res: [ESOsHandlerBase<ESO>, ESOHandle[]][] = [];
		for (const [tyId, group] of handlesTuplesPerGroupTypeId) {
			const ctor = IterUtils.find(this._typeIdsGenerator, (t) => t[1] === tyId)?.[0];
			if (ctor === undefined) {
				this.logger.error('constructor id mapping is erroneus, could not find constructor', tyId);
				continue;
			}
			const handler = this._perTypeObjectsHandlers.get(ctor);
			if (handler === undefined) {
				this.logger.error('handler for constructor is not registered', ctor);
				continue;
			}

			res.push([handler, group]);
			if (group.length > 20) {
				// sort handles if group is large enough
				// makes acesses to them later more rpedictable
				group.sort((l, r) => {
					const indexL = l & HandleIndexMask;
					const indexR = r & HandleIndexMask;
					return indexL - indexR;
				});
			}
		}
		return res;
	}

	getHandlerOfType<E extends ESO, T extends ESOsHandlerBase<E>>(ctor: {new(ident: string, params: ESOsHandlerInputs): T}): T | undefined {
		const handler = this.esosHandlers.find(h => h instanceof ctor);
		return handler as T | undefined;
	}

	markAllocated(ids: Set<IdBimScene>) {
		for (const handler of this.esosHandlers) {
			handler.annotationsCalculator?.markAllocated(ids);
		}
	};

    markUpdated(perIdDiffs: Map<IdBimScene, SceneObjDiff>) {
		for (const handler of this.esosHandlers) {
			handler.annotationsCalculator?.markUpdated(perIdDiffs);
		}
	};

    markDeleted(ids: Set<IdBimScene>) {
		for (const handler of this.esosHandlers) {
			handler.annotationsCalculator?.markDeleted(ids);
		}
	};

	updateAnnotations() {
		for (const handler of this.esosHandlers) {
			handler.annotationsCalculator?.updateAnnotations(handler);
		}
	}

	anyVisibleObjectsHandledBy<ESOH extends ESOsHandlerBase<any>>(handlerCtor: {new (...args: any[]): ESOH}): boolean {
		for (const handler of this.esosHandlers) {
			if (!(handler instanceof handlerCtor)) {
				continue;
			}
			for (const objHandle of handler._allObjectsHandled) {
				const obj = this.peek(objHandle);
				if (obj && !obj.isHidden) {
					return true;
				}
			}
		}
		return false;

	}
}



