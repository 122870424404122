import type { ObjectSerializer } from 'engine-utils-ts';
import { ErrorUtils, IterUtils, LegacyLogger, ObjectUtils } from 'engine-utils-ts';
import * as flatbuffers from 'flatbuffers';

import type { ObjectsPickingSerializer } from './VerDataPersistedCollection';
import type { Id } from './VerDataSyncerImpl';
import { BatchContainer as WireBatchContainer } from './wire/batch-container';
import type { CompressionType } from './wire/compression-type';
import { compressBinaryAsync } from './WireCommon';

export class BatchContainer {

    batchGuid: string;
    collectionIdent: string;
    payloadCompression: CompressionType;
    payload: Uint8Array;


    constructor(
        guid: string,
        collectionIdent: string,
        payloadCompression: CompressionType,
        payload: Uint8Array,
    ) {
        this.batchGuid = guid;
        this.collectionIdent = collectionIdent;
        this.payloadCompression = payloadCompression;
        this.payload = payload;
    }

    static async createNewAsync<D>(
        guid: string,
        collecitonIdent: string,
        objects: [Id, D][],
        serializer: ObjectsPickingSerializer<D>,
        comparator: (d1: D, d2: D) => boolean,
    ): Promise<BatchContainer> {
        if (objects.length === 0) {
            throw new Error('attempt to create batch with 0 objects');
        }
        const binary = serializer.serialize(objects);
        {// correctness check
            const ids: Set<Id> = new Set(objects.map(t => t[0]));
            const deserialized = serializer.deserialize(binary, ids);

            if (!IterUtils.areArraysEqual(
                objects.map(t => t[1]),
                deserialized.map(t => t[1]!),
                comparator
            )) {
                const notEqual = []
                for (let i = 0; i < objects.length; ++i) {
                    const o1 = objects[i][1];
                    const o2 = deserialized[i][1]!;
                    if (!(comparator(o1, o2))) {
                        notEqual.push([o1, o2]);
                        const diff = ObjectUtils.getDiff(o1, o2);
                        LegacyLogger.error(`${collecitonIdent} serialized and deserialized objects arent equal at index ${i}`, o1, o2);
                        LegacyLogger.error(`difference between serialised and deserialised objects: `, diff);
                    }
                }
                console.error(
                    `${collecitonIdent} serialized and deserialized objects arent equal, smth is broken`,
                    notEqual
                );
                /*ErrorUtils.logThrow(
                    `${collecitonIdent} serialized and deserialized objects arent equal, smth is broken`,
                    notEqual
                );*/
            }
        }
        const [compression, compressedBinary] = await compressBinaryAsync(binary);
        return new BatchContainer(
            guid,
            collecitonIdent,
            compression,
            compressedBinary
        );
    }
}

export class BatchContainerSerializer implements ObjectSerializer<BatchContainer> {
    serialize(data: BatchContainer): Uint8Array {
        const builder = new flatbuffers.Builder(data.payload.length + 300);
        const root = WireBatchContainer.createBatchContainer(
            builder,
            0,
            builder.createString(data.batchGuid),
            builder.createString(data.collectionIdent),
            data.payloadCompression,
            WireBatchContainer.createObjectsPayloadVector(builder, data.payload)
        );
        builder.finish(root);
        return builder.asUint8Array().slice();
    }
    deserialize(bin: Uint8Array): BatchContainer {
        const buffer = new flatbuffers.ByteBuffer(bin);
        const baco = WireBatchContainer.getRootAsBatchContainer(buffer);
        return new BatchContainer(
            baco.guid()!,
            baco.collectionIdent()!,
            baco.payloadCompression(),
            baco.objectsPayloadArray()!
        );
    }
}
