import type { Line, Lines } from "../../components/Lines";

export const enum TEXT_ALIGN_H {
	LEFT = 0,
	RIGHT = 1,
	CENTER = 2,
	JUSTIFY = 10,
	JUSTIFY_LEFT = 11,
	JUSTIFY_RIGHT = 12,
	JUSTIFY_CENTER = 13
}


export function textAlignH( lines: Lines, ALIGNMENT: TEXT_ALIGN_H, INNER_WIDTH: number ) {
	// Start the alignment by sticking to directions : left, right, center
	for ( let i = 0; i < lines.lines.length; i++ ) {
		const line = lines.lines[ i ];

		// compute the alignment offset of the line
		const offsetX = _computeLineOffset( line, ALIGNMENT, INNER_WIDTH, i === lines.lines.length - 1 );

		// apply the offset to each characters of the line
		for ( let j = 0; j < line.inlines.length; j++ ) {
			line.inlines[ j ].offsetX += offsetX;
		}

		line.x = offsetX;
	}

	// last operations for justifications alignments
	if ( ALIGNMENT >= 10 ) {
		for ( let i = 0; i < lines.lines.length; i++ ) {
			const line = lines.lines[ i ];

			// do not process last line for justify-left or justify-right
			if ( ALIGNMENT > 10 && i === lines.lines.length - 1 ) return;

			// can only justify is space is remaining
			const REMAINING_SPACE = INNER_WIDTH - line.width;
			if ( REMAINING_SPACE <= 0 ) return;

			// count the valid spaces to extend
			// Do not take the first nor the last space into account
			let validSpaces = 0;
			for ( let j = 1; j < line.inlines.length - 1; j++ ) {
				validSpaces += line.inlines[ j ].glyph === ' ' ? 1 : 0;
			}
			const additionalSpace = REMAINING_SPACE / validSpaces;


			// for right justification, process the loop in reverse
			let inverter = 1;
			if ( ALIGNMENT === TEXT_ALIGN_H.JUSTIFY_RIGHT ) {
				line.inlines.reverse();
				inverter = -1;
			}

			let incrementalOffsetX = 0;

			// start at ONE to avoid first space
			for ( let j = 1; j <= line.inlines.length - 1; j++ ) {
				// apply offset on each char
				const char = line.inlines[ j ];
				char.offsetX += incrementalOffsetX * inverter;

				// and increase it when space
				incrementalOffsetX += char.glyph === ' ' ? additionalSpace : 0;
			}

			// for right justification, the loop was processed in reverse
			if ( ALIGNMENT === TEXT_ALIGN_H.JUSTIFY_RIGHT ) {
				line.inlines.reverse();
			}
		}
	}
}


const _computeLineOffset = ( line: Line, ALIGNMENT: TEXT_ALIGN_H, INNER_WIDTH: number, lastLine: boolean ) => {
	switch ( ALIGNMENT ) {
		case TEXT_ALIGN_H.JUSTIFY_LEFT:
		case TEXT_ALIGN_H.JUSTIFY:
		case TEXT_ALIGN_H.LEFT:
			return -INNER_WIDTH / 2;

		case TEXT_ALIGN_H.JUSTIFY_RIGHT:
		case TEXT_ALIGN_H.RIGHT:
			return -line.width + ( INNER_WIDTH / 2 );

		case TEXT_ALIGN_H.CENTER:
			return -line.width / 2;

		case TEXT_ALIGN_H.JUSTIFY_CENTER:
			if ( lastLine ) {
				// center alignement
				return -line.width / 2;
			}else{
				// left alignment
				return -INNER_WIDTH / 2;
			}
	}
};
