import type { Bim, IdBimScene, PropertiesGroupFormatters, SceneInstance} from "src";
import { NumberProperty, SceneObjDiff } from "src";
import { MvWirePricingRelatedPropsExtra, MvWireSpecKeyProps, MvWireTypeIdent } from "src/archetypes/MvWire";
import type { LazyVersioned } from "engine-utils-ts";
import { DefaultMap, Failure, IterUtils, LazyDerivedAsync, LegacyLogger, ObjectUtils, Yield, preferPreviousOverInProgressWrapper, replaceCurrencyUnitWithSymbol } from "engine-utils-ts";
import { sumValueUnitLike } from "src/UnitsMapper";
import type { CostComponents, CostsConfigProvider, EstimateCost, InstanceCost, SingleEstimateProvider} from "src/cost-model/capital";
import { CostHierarchy, createEmptyCostComponents, createEmptyCostComponentsNonNullable, createMiscCategory, fillModelBasedCostCategory, fillModelBasedTopLevelCategory, multiplyCosts } from "src/cost-model/capital";
import type { SpecificCableGroup } from "./types";

export function create_ElectricalSubtotal_AcDcElectricalCable_MV(
    bim: Bim,
    costs: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>,
) {
    const wires = bim.instances.getLazyListOf({
        type_identifier: MvWireTypeIdent,
        relevantUpdateFlags: SceneObjDiff.NewProps | SceneObjDiff.LegacyProps,
    })

    const groupedCables = createGroupedCablesLazy(wires, bim.keyPropertiesGroupFormatter);

    const result = LazyDerivedAsync.new4<
        CostHierarchy,
        GroupedCables,
        InstanceCost[],
        EstimateCost[],
        NumberProperty
    >(
        'mv',
        [bim.unitsMapper],
        [groupedCables, costs.allInstanceCosts, costs.allEstimateCosts, totalDC],
        function* ([groupedCables, cableCosts, estimates, totalDC]) {
            cableCosts = cableCosts.filter(x => x.instance_type === MvWireTypeIdent)

            const quantityUnit = bim.unitsMapper.mapToConfigured({ value: 0, unit: 'm' }).unit!;
            const costUnit = bim.unitsMapper.mapToConfigured({ value: 0, unit: 'usd' }).unit!;
            const quantityUnitInMeters = NumberProperty.new({ value: 1, unit: quantityUnit }).as('m');

            // extract final result
            const hierarchy = new CostHierarchy();

            for (const { name, props, totalLength, ids } of groupedCables) {
                let overrides = multiplyCosts(
                    cableCosts.find((x) => ObjectUtils.areObjectsEqual(x.props, props))?.costs ?? createEmptyCostComponents(),
                    quantityUnitInMeters
                );
                const defaults = multiplyCosts(createDefaultACCostsPerMeter(), quantityUnitInMeters);

                const category = hierarchy.add({
                    description: { value: name },
                    costUnit: {
                        options: [replaceCurrencyUnitWithSymbol(costUnit) + '/' + quantityUnit ],
                        index: 0,
                    },
                    quantity: { value: totalLength },
                    relatedSceneInstanceIds: new Set(ids),
                    matchesSceneInstance: (query) => {
                        if (query.si?.type_identifier !== MvWireTypeIdent) {
                            return false;
                        }
                        const sampleProps = query.si.properties.extractPropertiesGroup(MvWireSpecKeyProps, { valueUnitOnly: true })
                        const result = ObjectUtils.areObjectsEqual(props, sampleProps);
                        return result;
                    }
                })

                const updateCosts = (newCosts: CostComponents) => {
                    costs.findAndUpdateInstanceCost(
                        (prev) => (prev.costs = multiplyCosts(newCosts, 1 / quantityUnitInMeters), prev),
                        { instance_type: MvWireTypeIdent, props }
                    )
                }

                fillModelBasedCostCategory(category[1], overrides, defaults, updateCosts, totalLength.as(quantityUnit));

            }

            // set ac feeder category
            {
                const category = hierarchy.addRoot({ description: { value: 'Mv Cable' } });
                hierarchy.sumupChildren(category[0])
            }

            // add misc
            {
                const costId = 'mv-electrical-cables-misc';
                const overrides = estimates.find(x => x.id.value === costId);
                const state: SingleEstimateProvider = {
                    id: costId,
                    defaults: createEmptyCostComponentsNonNullable(),
                    value: overrides,
                    update: (fn) => costs.findAndUpdateEstimateCost(costId, fn),
                }
                hierarchy.add(createMiscCategory(state, bim.unitsMapper, totalDC))
            }

            // add mv root Category
            {
                const root = hierarchy.addRoot({ description: { value: 'Medium Voltage Elect Cable' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.materialCost = NumberProperty.new({ value: 0.011, unit: 'usd' });
                fillModelBasedTopLevelCategory(
                    root[0],
                    hierarchy,
                    'mv-electrical-cables-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
            }

            return hierarchy;
        }
    )
    return result
}


function createGroupedCablesLazy(
    wires: LazyVersioned<Array<[id: IdBimScene, si: SceneInstance]>>,
    keyPropsGroupFormatter: PropertiesGroupFormatters,
) {
    const groupedCables = preferPreviousOverInProgressWrapper(LazyDerivedAsync.new1(
        'groupedCables',
        [],
        [wires],
        function* ([wires]) {
            type SpecificCableName = string
            const groups = new DefaultMap<
                SpecificCableName,
                SpecificCableGroup<typeof MvWireSpecKeyProps>
            >(
                (cableName) => ({
                    name: cableName,
                    props: MvWireSpecKeyProps,
                    totalLength: NumberProperty.new({ value: 0, unit: 'ft' }),
                    ids: []
                })
            )

            for (const chunk of IterUtils.splitIterIntoChunks(wires, 10e3)) {
                yield Yield.Asap;
                const grouped = IterUtils.groupObjects(
                    chunk,
                    (o) => {
                        const props = o[1].properties.extractPropertiesGroup(MvWireSpecKeyProps);
                        return props.gauge.value + props.material.value;
                    },
                    (l, r) => ObjectUtils.areObjectsEqual(
                        l[1].properties.extractPropertiesGroup(MvWireSpecKeyProps, { valueUnitOnly: true }),
                        r[1].properties.extractPropertiesGroup(MvWireSpecKeyProps, { valueUnitOnly: true })
                    ),
                )
                for (const members of grouped) {
                    const sample = members[0][1];
                    const props = sample.properties.extractPropertiesGroup(MvWireSpecKeyProps, { valueUnitOnly: true });
                    const name = keyPropsGroupFormatter.format(MvWireTypeIdent, sample.properties, sample.props);
                    const lengths = members.map(x => x[1].properties.extractPropertiesGroup(MvWirePricingRelatedPropsExtra).length);
                    const totalLength = sumValueUnitLike(lengths)
                    if (!name || totalLength instanceof Failure) {
                        LegacyLogger.warn('Invalid wire group ' + name + '. Skipping');
                        continue;
                    }
                    const group = groups.getOrCreate(name);
                    const newGroupTotalLength = sumValueUnitLike([totalLength.value, group.totalLength])
                    if (newGroupTotalLength instanceof Failure) {
                        LegacyLogger.warn('can not sum up total lengths. Skipping');
                        continue;
                    }
                    group.props = props;
                    group.totalLength = NumberProperty.new(newGroupTotalLength.value)
                    group.ids.push(...members.map(x => x[0]))
                }

            }

            return Array.from(groups.values());
        }
    ));

    return groupedCables;
}

type GroupedCables = SpecificCableGroup<typeof MvWireSpecKeyProps>[];

export function createDefaultACCostsPerMeter() {
    const costs = createEmptyCostComponentsNonNullable();
    costs.materialCost = NumberProperty.new({ value: 1*3.28084, unit: 'usd' })
    return costs;
}
