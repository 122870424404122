import { yellow, blue, golden, white, grey, black, chocolate, quarry } from './Keywords';

// color, 381 code, name
export const BritishStandard381 = [
    [
        '101',
        '#94bfac',
        'sky blue'
    ],
    [
        '102',
        '#5b9291',
        'turquoise blue'
    ],
    [
        '103',
        '#3b6879',
        'peacock blue'
    ],
    [
        '104',
        '#264d7e',
        'azure blue'
    ],
    [
        '105',
        '#1f3057',
        'oxford blue'
    ],
    [
        '106',
        '#2a283d',
        'royal blue'
    ],
    [
        '107',
        '#3a73a9',
        'strong blue'
    ],
    [
        '108',
        '#173679',
        'aircraft blue'
    ],
    [
        '109',
        '#1c5680',
        'middle blue / anchusa'
    ],
    [
        '110',
        '#2c3e75',
        'roundel blue'
    ],
    [
        '111',
        '#8cc5bb',
        'pale blue'
    ],
    [
        '112',
        '#78adc2',
        'arctic blue / fiesta blue'
    ],
    [
        '113',
        '#3f687d',
        'deep saxe blue'
    ],
    [
        '114',
        '#1f4b61',
        'rail blue'
    ],
    [
        '115',
        '#5f88c1',
        'cobalt blue'
    ],
    [
        '166',
        '#2458af',
        'french blue'
    ],
    [
        '169',
        '#135b75',
        'traffic blue'
    ],
    [
        '172',
        '#a7c6eb',
        'pale roundel blue'
    ],
    [
        '174',
        '#64a0aa',
        'orient blue'
    ],
    [
        '175',
        '#4f81c5',
        'light french blue'
    ],
    [
        '210',
        '#bbc9a5',
        'sky'
    ],
    [
        '216',
        '#bcd890',
        'eau de nil'
    ],
    [
        '217',
        '#96bf65',
        'sea green'
    ],
    [
        '218',
        '#698b47',
        'grass green'
    ],
    [
        '219',
        '#757639',
        'sage green'
    ],
    [
        '220',
        '#4b5729',
        'olive green'
    ],
    [
        '221',
        '#507d3a',
        'brilliant green'
    ],
    [
        '222',
        '#6a7031',
        'light bronze green'
    ],
    [
        '223',
        '#49523a',
        'middle bronze green'
    ],
    [
        '224',
        '#3e4630',
        'deep bronze green'
    ],
    [
        '225',
        '#406a28',
        'light brunswick green'
    ],
    [
        '226',
        '#33533b',
        'mid brunswick green'
    ],
    [
        '227',
        '#254432',
        'deep brunswick green'
    ],
    [
        '228',
        '#428b64',
        'emerald green / viridian'
    ],
    [
        '241',
        '#4f5241',
        'dark green'
    ],
    [
        '262',
        '#44945e',
        'bold green'
    ],
    [
        '267',
        '#476a4c',
        'deep chrome green / traffic green'
    ],
    [
        '275',
        '#8fc693',
        'opaline green'
    ],
    [
        '276',
        '#2e4c1e',
        'lincon green'
    ],
    [
        '277',
        '#364a20',
        'cypress green'
    ],
    [
        '278',
        '#87965a',
        'light olive green'
    ],
    [
        '279',
        '#3b3629',
        'steel furniture green'
    ],
    [
        '280',
        '#68ab77',
        'verdigris green'
    ],
    [
        '282',
        '#506b52',
        'forest green'
    ],
    [
        '283',
        '#7e8f6e',
        'aircraft grey green'
    ],
    [
        '284',
        '#6b6f5a',
        'spruce green'
    ],
    [
        '285',
        '#5f5c4b',
        'nato green'
    ],
    [
        '298',
        '#4f5138',
        'olive drab'
    ],
    [
        '309',
        '#feec04',
        'canary yellow'
    ],
    [
        '310',
        '#fef963',
        'primrose'
    ],
    [
        '315',
        '#fef96a',
        'grapefruit'
    ],
    [
        '320',
        '#9e7339',
        'light brown'
    ],
    [
        '337',
        '#4c4a3c',
        'very dark drab'
    ],
    [
        '350',
        '#7b6b4f',
        'dark earth'
    ],
    [
        '352',
        '#fced96',
        'pale cream'
    ],
    [
        '353',
        '#fdf07a',
        'deep cream'
    ],
    [
        '354',
        '#e9bb43',
        'primrose'
    ],
    [
        '355',
        '#fdd906',
        'lemon'
    ],
    [
        '356',
        '#fcc808',
        'golden yellow'
    ],
    [
        '358',
        '#f6c870',
        'light buff'
    ],
    [
        '359',
        '#dbac50',
        'middle buff'
    ],
    [
        '361',
        '#d4b97d',
        'light stone'
    ],
    [
        '362',
        '#ac7c42',
        'middle stone'
    ],
    [
        '363',
        '#fde706',
        'bold yellow'
    ],
    [
        '364',
        '#cec093',
        'portland stone'
    ],
    [
        '365',
        '#f4f0bd',
        'vellum'
    ],
    [
        '366',
        '#f5e7a1',
        'light beige'
    ],
    [
        '367',
        '#fef6bf',
        'manilla'
    ],
    [
        '368',
        '#dd7b00',
        'traffic yellow'
    ],
    [
        '369',
        '#feeba8',
        'biscuit'
    ],
    [
        '380',
        '#bba38a',
        'camouflage desert sand'
    ],
    [
        '384',
        '#eedfa5',
        'light straw'
    ],
    [
        '385',
        '#e8c88f',
        'light biscuit'
    ],
    [
        '386',
        '#e6c18d',
        'champagne'
    ],
    [
        '387',
        '#cfb48a',
        'sunrise / sunshine'
    ],
    [
        '388',
        '#e4cf93',
        'beige'
    ],
    [
        '389',
        '#b2a788',
        'camouflage beige'
    ],
    [
        '397',
        '#f3d163',
        'jasmine yellow'
    ],
    [
        '410',
        '#93612b',
        'light brown'
    ],
    [
        '411',
        '#74542f',
        'middle brown'
    ],
    [
        '412',
        '#5c422e',
        'dark brown'
    ],
    [
        '413',
        '#402d21',
        'nut brown'
    ],
    [
        '414',
        '#a86c29',
        'golden brown'
    ],
    [
        '415',
        '#61361e',
        'imperial brown'
    ],
    [
        '420',
        '#a89177',
        'dark camouflage desert sand'
    ],
    [
        '435',
        '#845b4d',
        'camouflage red'
    ],
    [
        '436',
        '#564b47',
        'dark camouflage brown'
    ],
    [
        '437',
        '#464334',
        'very dark drab'
    ],
    [
        '439',
        '#753b1e',
        'orange brown'
    ],
    [
        '443',
        '#c98a71',
        'salmon'
    ],
    [
        '444',
        '#a65341',
        'terracotta'
    ],
    [
        '445',
        '#83422b',
        'venetian red'
    ],
    [
        '446',
        '#774430',
        'red oxide'
    ],
    [
        '447',
        '#f3b28b',
        'salmon pink'
    ],
    [
        '448',
        '#67403a',
        'deep indian red'
    ],
    [
        '449',
        '#693b3f',
        'light purple brown'
    ],
    [
        '450',
        '#745f46',
        'dark earth'
    ],
    [
        '452',
        '#613339',
        'dark crimson'
    ],
    [
        '453',
        '#fbded6',
        'shell pink'
    ],
    [
        '454',
        '#e8a1a2',
        'pale roundel red'
    ],
    [
        '460',
        '#bd8f56',
        'deep buff'
    ],
    [
        '473',
        '#793932',
        'gulf red'
    ],
    [
        '489',
        '#8d5b41',
        'leaf brown'
    ],
    [
        '490',
        '#573320',
        'beech brown'
    ],
    [
        '499',
        '#59493e',
        'service brown'
    ],
    [
        '536',
        '#bb3016',
        'poppy'
    ],
    [
        '537',
        '#dd3420',
        'signal red'
    ],
    [
        '538',
        '#c41c22',
        'post office red / cherry'
    ],
    [
        '539',
        '#d21e2b',
        'currant red'
    ],
    [
        '540',
        '#8b1a32',
        'crimson'
    ],
    [
        '541',
        '#471b21',
        'maroon'
    ],
    [
        '542',
        '#982d57',
        'ruby'
    ],
    [
        '557',
        '#ef841e',
        'light orange'
    ],
    [
        '564',
        '#dd3524',
        'bold red'
    ],
    [
        '568',
        '#fb9c06',
        'apricot'
    ],
    [
        '570',
        '#a83c19',
        'traffic red'
    ],
    [
        '591',
        '#d04e09',
        'deep orange'
    ],
    [
        '592',
        '#e45523',
        'international orange'
    ],
    [
        '593',
        '#f24816',
        'rail red / azo orange'
    ],
    [
        '626',
        '#a0a9aa',
        'camouflage grey'
    ],
    [
        '627',
        '#bec0b8',
        'light aircraft grey'
    ],
    [
        '628',
        '#9d9d7e',
        'silver grey'
    ],
    [
        '629',
        '#7a838b',
        'dark camouflage grey'
    ],
    [
        '630',
        '#a5ad98',
        'french grey'
    ],
    [
        '631',
        '#9aaa9f',
        'light grey'
    ],
    [
        '632',
        '#6b7477',
        'dark admiralty grey'
    ],
    [
        '633',
        '#424c53',
        'raf blue grey'
    ],
    [
        '634',
        '#6f7264',
        'slate'
    ],
    [
        '635',
        '#525b55',
        'lead'
    ],
    [
        '636',
        '#5f7682',
        'pru blue'
    ],
    [
        '637',
        '#8e9b9c',
        'medium sea grey'
    ],
    [
        '638',
        '#6c7377',
        'dark sea grey'
    ],
    [
        '639',
        '#667563',
        'light slate grey'
    ],
    [
        '640',
        '#566164',
        'extra dark sea grey'
    ],
    [
        '641',
        '#484837',
        'dark green'
    ],
    [
        '642',
        '#282b2f',
        'night'
    ],
    [
        '671',
        '#4e5355',
        'middle graphite'
    ],
    [
        '676',
        '#a9b7b9',
        'light weatherwork grey'
    ],
    [
        '677',
        '#676f76',
        'dark weatherwork grey'
    ],
    [
        '692',
        '#7b93a3',
        'smoke grey'
    ],
    [
        '693',
        '#88918d',
        'aircraft grey'
    ],
    [
        '694',
        '#909a92',
        'dove grey'
    ],
    [
        '697',
        '#b6d3cc',
        'light admiralty grey'
    ],
    [
        '796',
        '#6e4a75',
        'dark violet'
    ],
    [
        '797',
        '#c9a8ce',
        'light violet'
    ]
]

// color, code, name
export const BritishStandard4800 = [
    [
        '04-c-31',
        '#fee7d5',
        'pink'
    ],
    [
        '06-c-35',
        '#eda672',
        'light orange'
    ],
    [
        '06-c-40',
        '#4b2c17',
        'brown'
    ],
    [
        '08-c-33',
        '#efc289',
        'beige'
    ],
    [
        '12-c-31',
        '#e9eeac',
        'light yellow'
    ],
    // [
    //     "12-c-37",
    //     "#5e6b1b",
    //     "green"
    // ],
    [
        '08-d-41',
        '#febb75',
        'yellow'
    ],
    // [
    //     "12-d-41",
    //     "#d4e273",
    //     "green"
    // ],
    [
        '20-d-41',
        '#97b5d5',
        'powder blue'
    ],
    // [
    //     "22-d-41",
    //     "#afadd5",
    //     "purple"
    // ],
    [
        '04-e-55',
        '#b2341e',//"#cf3b21",
        'red'
    ],
    [
        '04-e-56',
        '#c71c2c',
        'carnival red'
    ],
    [
        '04-e-58',
        '#6a2c39',
        'purple'
    ],
    [
        '08-e-55',
        '#f99e2c',
        'orange'
    ],
    [
        '10-e-55',
        '#ffcb05',
        'cannary yellow'
    ],
    [
        '12-e-55',
        '#5da039',
        'green'
    ],
    [
        '14-e-49',
        '#c0e1c2',
        'light green'
    ],
    // [
    //     "14-e-50",
    //     "#9dd29c",
    //     "green"
    // ],
    [
        '16-e-50',
        '#97d5c8',
        'turquoise'
    ],
    [
        '20-e-53',
        '#096eb2',
        'blue'
    ],
    // [
    //     "20-e-56",
    //     "#014782",
    //     "blue"
    // ],
    [
        '24-e-53',
        '#90538f',
        'purple'
    ]
];

//color, code, english, german, france, espanol, nederlands
export const RalClassic = [
    [
        '1000',
        '#bebd7f',
        'grünbeige',
        'green beige',
        'beige vert',
        'beige verdoso',
        'beige verdastro',
        'зелено бежевый'
    ],
    [
        '1001',
        '#c2b078',
        'beige',
        'beige',
        'beige',
        'beige',
        'beige',
        'бежевый'
    ],
    [
        '1002',
        '#c6a664',
        'sandgelb',
        'sand yellow',
        'jaune sable',
        'amarillo arena',
        'giallo sabbia',
        'песочно желтый'
    ],
    [
        '1003',
        '#e5be01',
        'signalgelb',
        'signal yellow',
        'jaune de sécurité',
        'amarillo señales',
        'giallo segnale',
        'сигнальный желтый'
    ],
    [
        '1004',
        '#cda434',
        'goldgelb',
        'golden yellow',
        'jaune or',
        'amarillo oro',
        'giallo oro',
        'желто золотой'
    ],
    [
        '1005',
        '#a98307',
        'honiggelb',
        'honey yellow',
        'jaune miel',
        'amarillo miel',
        'giallo miele',
        'медово желтый'
    ],
    [
        '1006',
        '#e4a010',
        'maisgelb',
        'maize yellow',
        'jaune maïs',
        'amarillo maiz',
        'giallo polenta',
        'кукурузно желтый'
    ],
    [
        '1007',
        '#dc9d00',
        'narzissengelb',
        'daffodil yellow',
        'jaune narcisse',
        'amarillo narciso',
        'giallo narciso',
        'нарциссово желтый'
    ],
    [
        '1011',
        '#8a6642',
        'braunbeige',
        'brown beige',
        'beige brun',
        'beige pardo',
        'beige marrone',
        'коричнево бежевый'
    ],
    [
        '1012',
        '#c7b446',
        'zitronengelb',
        'lemon yellow',
        'jaune citron',
        'amarillo limón',
        'giallo limone',
        'лимонно желтый'
    ],
    [
        '1013',
        '#eeeacd',
        'perlweiß',
        'oyster white',
        'blanc perlé',
        'blanco perla',
        'bianco perla',
        'жемчужно белый'
    ],
    [
        '1014',
        '#eddd82',//"#e1cc4f",
        'elfenbein',
        'ivory',
        'ivoire',
        'marfil',
        'avorio',
        'слоновая кость'
    ],
    [
        '1015',
        '#e6d690',
        'hellelfenbein',
        'light ivory',
        'ivoire clair',
        'marfil claro',
        'avorio chiaro',
        'светлая слоновая кость'
    ],
    [
        '1016',
        '#edff21',
        'schwefelgelb',
        'sulfur yellow',
        'jaune soufre',
        'amarillo azufre',
        'giallo zolfo',
        'желтая сера'
    ],
    [
        '1017',
        '#f5d033',
        'safrangelb',
        'saffron yellow',
        'jaune safran',
        'amarillo azafrán',
        'giallo zafferano',
        'шафраново желтый'
    ],
    [
        '1018',
        '#f8f32b',
        'zinkgelb',
        'zinc yellow',
        'jaune zinc',
        'amarillo de zinc',
        'giallo zinco',
        'цинково желтый'
    ],
    [
        '1019',
        '#9e9764',
        'graubeige',
        'grey beige',
        'beige gris',
        'beige agrisado',
        'beige grigiastro',
        'серо бежевый'
    ],
    [
        '1020',
        '#999950',
        'olivgelb',
        'olive yellow',
        'jaune olive',
        'amarillo oliva',
        'giallo olivastro',
        'оливково желтый'
    ],
    [
        '1021',
        '#f3da0b',
        'rapsgelb',
        'colza yellow',
        'jaune colza',
        'amarillo colza',
        'giallo navone',
        'рапсово желтый'
    ],
    [
        '1023',
        '#fad201',
        'verkehrsgelb',
        'traffic yellow',
        'jaune signalisation',
        'amarillo tráfico',
        'giallo traffico',
        'транспортно желтый'
    ],
    [
        '1024',
        '#aea04b',
        'ockergelb',
        'ochre yellow',
        'jaune ocre',
        'amarillo ocre',
        'giallo ocra',
        'охра желтая'
    ],
    [
        '1026',
        '#ffff00',
        'leuchtgelb',
        'luminous yellow',
        'jaune brillant',
        'amarillo brillante',
        'giallo brillante',
        'люминесцентный желтый'
    ],
    [
        '1027',
        '#9d9101',
        'currygelb',
        'curry',
        'jaune curry',
        'amarillo curry',
        'giallo curry',
        'карри желтый'
    ],
    [
        '1028',
        '#f4a900',
        'melonengelb',
        'melon yellow',
        'jaune melon',
        'amarillo melón',
        'giallo melone',
        'дынно желтый'
    ],
    [
        '1032',
        '#d6ae01',
        'ginstergelb',
        'broom yellow',
        'jaune genêt',
        'amarillo retama',
        'giallo scopa',
        'желтый ракитник'
    ],
    [
        '1033',
        '#f3a505',
        'dahliengelb',
        'dahlia yellow',
        'jaune dahlia',
        'amarillo dalia',
        'giallo dahlien',
        'георгиново желтый'
    ],
    [
        '1034',
        '#efa94a',
        'pastellgelb',
        'pastel yellow',
        'jaune pastel',
        'amarillo pastel',
        'giallo pastello',
        'пастельно желтый'
    ],
    [
        '1035',
        '#6a5d4d',
        'perlbeige',
        'pearl beige',
        'beige nacré',
        'beige perlado',
        'beige perlato',
        'перламутрово бежевый'
    ],
    [
        '1036',
        '#705335',
        'perlgold',
        'pearl gold',
        'or nacré',
        'oro perlado',
        'oro perlato',
        'перламутрово золотой'
    ],
    [
        '1037',
        '#f39f18',
        'sonnengelb',
        'sun yellow',
        'jaune soleil',
        'amarillo sol',
        'giallo sole',
        'солнечно желтый'
    ],
    [
        '2000',
        '#ed760e',
        'gelborange',
        'yellow orange',
        'orangé jaune',
        'amarillo naranja',
        'arancio giallastro',
        'желто оранжевый'
    ],
    [
        '2001',
        '#c93c20',
        'rotorange',
        'red orange',
        'orangé rouge',
        'rojo anaranjado',
        'arancio rossastro',
        'красно оранжевый'
    ],
    [
        '2002',
        '#cb2821',
        'blutorange',
        'vermilion',
        'orangé sang',
        'naranja sanguineo',
        'arancio sanguigno',
        'алый'
    ],
    [
        '2003',
        '#ff7514',
        'pastellorange',
        'pastel orange',
        'orangé pastel',
        'naranja pálido',
        'arancio pastello',
        'пастельно оранжевый'
    ],
    [
        '2004',
        '#f44611',
        'reinorange',
        'pure orange',
        'orangé pur',
        'naranja puro',
        'arancio puro',
        'оранжевый'
    ],
    [
        '2005',
        '#ff2301',
        'leuchtorange',
        'luminous orange',
        'orangé brillant',
        'naranja brillante',
        'arancio brillante',
        'люминесцентный оранжевый'
    ],
    [
        '2007',
        '#ffa420',
        'leuchthellorange',
        'luminous bright orange',
        'orangé clair rillant',
        'naranja claro brillante',
        'arancio chiaro brillante',
        'люминесцентный ярко оранжевый'
    ],
    [
        '2008',
        '#f75e25',
        'hellrotorange',
        'bright red orange',
        'orangé rouge clair',
        'rojo claro anaranjado',
        'rosso arancio chiaro',
        'ярко красно оранжевый'
    ],
    [
        '2009',
        '#f54021',
        'verkehrsorange',
        'traffic orange',
        'orangé signalisation',
        'naranja tráfico',
        'arancio traffico',
        'транспортный оранжевый'
    ],
    [
        '2010',
        '#d84b20',
        'signalorange',
        'signal orange',
        'orangé de sécurité',
        'naranja señales',
        'arancio segnale',
        'сигнальный оранжевый'
    ],
    [
        '2011',
        '#ec7c26',
        'tieforange',
        'deep orange',
        'orangé foncé',
        'naranja intenso',
        'arancio profondo',
        'насыщенный оранжевый'
    ],
    [
        '2012',
        '#e55137',
        'lachsorange',
        'salmon range',
        'orangé saumon',
        'naranja salmón',
        'arancio salmone',
        'лососево оранжевый'
    ],
    [
        '2013',
        '#c35831',
        'perlorange',
        'pearl orange',
        'orangé nacré',
        'naranja perlado',
        'arancio perlato',
        'перламутрово оранжевый'
    ],
    [
        '3000',
        '#af2b1e',
        'feuerrot',
        'flame red',
        'rouge feu',
        'rojo vivo',
        'rosso fuoco',
        'огненно красный'
    ],
    [
        '3001',
        '#a52019',
        'signalrot',
        'signal red',
        'rouge de sécurité',
        'rojo señales',
        'rosso  segnale',
        'сигнальный красный'
    ],
    [
        '3002',
        '#a2231d',
        'karminrot',
        'carmine red',
        'rouge carmin',
        'rojo carmin',
        'rosso carminio',
        'карминно красный'
    ],
    [
        '3003',
        '#9b111e',
        'rubinrot',
        'ruby red',
        'rouge rubis',
        'rojo rubí',
        'rosso rubino',
        'рубиново красный'
    ],
    [
        '3004',
        '#75151e',
        'purpurrot',
        'purple red',
        'rouge pourpre',
        'rojo purpura',
        'rosso porpora',
        'пурпурно красный'
    ],
    [
        '3005',
        '#5e2129',
        'weinrot',
        'wine red',
        'rouge vin',
        'rojo vino',
        'rosso vino',
        'винно красный'
    ],
    [
        '3007',
        '#351f21',
        'schwarzrot',
        'black red',
        'rouge noir',
        'rojo negruzco',
        'rosso nerastro',
        'черно красный'
    ],
    [
        '3009',
        '#642424',
        'oxidrot',
        'oxide red',
        'rouge oxyde',
        'rojo óxido',
        'rosso  ossido',
        'оксид красный'
    ],
    [
        '3011',
        '#781f19',
        'braunrot',
        'brown red',
        'rouge brun',
        'rojo pardo',
        'rosso marrone',
        'коричнево красный'
    ],
    [
        '3012',
        '#c1876b',
        'beigerot',
        'beige red',
        'rouge beige',
        'rojo beige',
        'rosso beige',
        'бежево красный'
    ],
    [
        '3013',
        '#a12312',
        'tomatenrot',
        'tomato red',
        'rouge tomate',
        'rojo tomate',
        'rosso pomodoro',
        'томатно красный'
    ],
    [
        '3014',
        '#d36e70',
        'altrosa',
        'antique pink',
        'vieux rose',
        'rojo viejo',
        'rosa antico',
        'розовый антик'
    ],
    [
        '3015',
        '#ea899a',
        'hellrosa',
        'light pink',
        'rose clair',
        'rosa claro',
        'rosa chiaro',
        'светло розовый'
    ],
    [
        '3016',
        '#b32821',
        'korallenrot',
        'coral red',
        'rouge corail',
        'rojo coral',
        'rosso corallo',
        'кораллово красный'
    ],
    [
        '3017',
        '#e63244',
        'rosé',
        'rose',
        'rosé',
        'rosa',
        'rosato',
        'розовый'
    ],
    [
        '3018',
        '#d53032',
        'erdbeerrot',
        'strawberry red',
        'rouge fraise',
        'rojo fresa',
        'rosso fragola',
        'клубнично красный'
    ],
    [
        '3020',
        '#cc0605',
        'verkehrsrot',
        'traffic red',
        'rouge signalisation',
        'rojo tráfico',
        'rosso traffico',
        'транспортный красный'
    ],
    [
        '3022',
        '#d95030',
        'lachsrot',
        'salmon pink',
        'rouge saumon',
        'rojo salmón',
        'rosso salmone',
        'лососево красный'
    ],
    [
        '3024',
        '#f80000',
        'leuchtrot',
        'luminous red',
        'rouge brillant',
        'rojo brillante',
        'rosso brillante',
        'люминесцентный красный'
    ],
    [
        '3026',
        '#fe0000',
        'leuchthellrot',
        'luminousbright red',
        'rouge clair brillant',
        'rojo claro brillante',
        'rosso chiaro brillante',
        'люминесцентный ярко красный'
    ],
    [
        '3027',
        '#c51d34',
        'himbeerrot',
        'raspberry red',
        'rouge framboise',
        'rojo frambuesa',
        'rosso lampone',
        'малиново красный'
    ],
    [
        '3028',
        '#cb3234',
        'reinrot',
        'pure  red',
        'rouge puro',
        'rojo puro',
        'rosso puro',
        'чистый красный'
    ],
    [
        '3031',
        '#b32428',
        'orientrot',
        'orient red',
        'rouge oriental',
        'rojo oriente',
        'rosso oriente',
        'ориент красный'
    ],
    [
        '3032',
        '#721422',
        'perlrubinrot',
        'pearl ruby red',
        'rouge rubis nacré',
        'rojo rubí perlado',
        'rosso rubino perlato',
        'перламутрово рубиновый'
    ],
    [
        '3033',
        '#b44c43',
        'perlrosa',
        'pearl pink',
        'rose nacré',
        'rosa perlado',
        'rosa perlato',
        'перламутрово розовый'
    ],
    [
        '4001',
        '#6d3f5b',
        'rotlila',
        'red lilac',
        'lilas rouge',
        'rojo lila',
        'lilla rossastro',
        'красно сиреневый'
    ],
    [
        '4002',
        '#922b3e',
        'rotviolett',
        'red violet',
        'violet rouge',
        'rojo violeta',
        'viola rossastro',
        'красно фиолетовый'
    ],
    [
        '4003',
        '#de4c8a',
        'erikaviolett',
        'heather violet',
        'violet bruyère',
        'violeta érica',
        'viola erica',
        'вересково фиолетовый'
    ],
    [
        '4004',
        '#6e1c34',
        'bordeauxviolett',
        'claret violet',
        'violet bordeaux',
        'burdeos',
        'viola bordeaux',
        'бордово фиолетовый'
    ],
    [
        '4005',
        '#6c4675',
        'blaulila',
        'blue lilac',
        'lilas bleu',
        'lila azulado',
        'lilla bluastro',
        'сине сиреневый'
    ],
    [
        '4006',
        '#a03472',
        'verkehrspurpur',
        'traffic purple',
        'pourpre signalisation',
        'púrpurá tráfico',
        'porpora traffico',
        'транспортный пурпурный'
    ],
    [
        '4007',
        '#4a192c',
        'purpurviolett',
        'purple violet',
        'violet pourpre',
        'violeta púrpura',
        'porpora violetto',
        'пурпурно фиолетовый'
    ],
    [
        '4008',
        '#924e7d',
        'signalviolett',
        'signal violet',
        'violet de sécurité',
        'violeta señales',
        'violetto segnale',
        'сигнальный фиолетовый'
    ],
    [
        '4009',
        '#a47d90',
        'pastellviolett',
        'pastel violet',
        'violet pastel',
        'violeta pastel',
        'violetto pastello',
        'пастельно фиолетовый'
    ],
    [
        '4010',
        '#d72d6d',
        'telemagenta',
        'telemagenta',
        'telemagenta',
        'magenta tele',
        'tele magenta',
        'телемагента'
    ],
    [
        '4011',
        '#8673a1',
        'perlviolett',
        'pearl violet',
        'violet nacré',
        'violeta perlado',
        'violetto perlato',
        'перламутрово фиолетовый'
    ],
    [
        '4012',
        '#6c6881',
        'perlbrombeer',
        'pearl black berry',
        'mûre nacré',
        'morado perlado',
        'mora perlato',
        'перламутрово ежевичный'
    ],
    [
        '5000',
        '#2a2e4b',
        'violettblau',
        'violet blue',
        'bleu violet',
        'azul violeta',
        'blu violaceo',
        'фиолетово синий'
    ],
    [
        '5001',
        '#1f3438',
        'grünblau',
        'green blue',
        'bleu vert',
        'azul verdoso',
        'blu verdastro',
        'зелено синий'
    ],
    [
        '5002',
        '#20214f',
        'ultramarinblau',
        'ultramarine blue',
        'bleu outremer',
        'azul ultramar',
        'blu oltremare',
        'ультрамариново синий'
    ],
    [
        '5003',
        '#1d1e33',
        'saphirblau',
        'saphire blue',
        'bleu saphir',
        'azul zafiro',
        'blu zaffiro',
        'сапфирово синий'
    ],
    [
        '5004',
        '#18171c',
        'schwarzblau',
        'black blue',
        'bleu noir',
        'azul negruzco',
        'blu nerastro',
        'черно синий'
    ],
    [
        '5005',
        '#1e2d6e',
        'signalblau',
        'signal blue',
        'bleu de sécurité',
        'azul señales',
        'blu segnale',
        'сигнальный синий'
    ],
    [
        '5007',
        '#3e5f8a',
        'brillantblau',
        'brillant blue',
        'bleu brillant',
        'azul brillante',
        'blu brillante',
        'бриллиантово синий'
    ],
    [
        '5008',
        '#26252d',
        'graublau',
        'grey blue',
        'bleu gris',
        'azul grisáceo',
        'blu grigiastro',
        'серо синий'
    ],
    [
        '5009',
        '#025669',
        'azurblau',
        'azure blue',
        'bleu azur',
        'azul azur',
        'blu  azzurro',
        'лазурно синий'
    ],
    [
        '5010',
        '#0e294b',
        'enzianblau',
        'gentian blue',
        'bleu gentiane',
        'azul genciana',
        'blu  genziana',
        'горечавково синий'
    ],
    [
        '5011',
        '#231a24',
        'stahlblau',
        'steel blue',
        'bleu acier',
        'azul acero',
        'blu acciaio',
        'стально синий'
    ],
    [
        '5012',
        '#3b83bd',
        'lichtblau',
        'light blue',
        'bleu clair',
        'azul luminoso',
        'blu luce',
        'голубой'
    ],
    [
        '5013',
        '#1e213d',
        'kobaltblau',
        'cobalt blue',
        'bleu cobalt',
        'azul cobalto',
        'blu cobalto',
        'кобальтово синий'
    ],
    [
        '5014',
        '#606e8c',
        'taubenblau',
        'pigeon blue',
        'bleu pigeon',
        'azul olombino',
        'blu colomba',
        'голубино синий'
    ],
    [
        '5015',
        '#2271b3',
        'himmelblau',
        'sky blue',
        'bleu ciel',
        'azul celeste',
        'blu cielo',
        'небесно синий'
    ],
    [
        '5017',
        '#063971',
        'verkehrsblau',
        'traffic blue',
        'bleu signalisation',
        'azul tráfico',
        'blu traffico',
        'транспортный синий'
    ],
    [
        '5018',
        '#3f888f',
        'türkisblau',
        'turquoise blue',
        'bleu turquoise',
        'azul turquesa',
        'blu turchese',
        'бирюзово синий'
    ],
    [
        '5019',
        '#1b5583',
        'capriblau',
        'capri blue',
        'bleu capri',
        'azul capri',
        'blu capri',
        'капри синий'
    ],
    [
        '5020',
        '#1d334a',
        'ozeanblau',
        'ocean blue',
        'bleu océan',
        'azul oceano',
        'blu oceano',
        'океанская синь'
    ],
    [
        '5021',
        '#256d7b',
        'wasserblau',
        'water blue',
        'bleu d’eau',
        'azul agua',
        'blu acqua',
        'водная синь'
    ],
    [
        '5022',
        '#252850',
        'nachtblau',
        'night blue',
        'bleu nocturne',
        'azul noche',
        'blu notte',
        'ночной синий'
    ],
    [
        '5023',
        '#49678d',
        'fernblau',
        'distant blue',
        'bleu distant',
        'azul lejanía',
        'blu distante',
        'отдаленно синий'
    ],
    [
        '5024',
        '#5d9b9b',
        'pastellblau',
        'pastel blue',
        'bleu pastel',
        'azul pastel',
        'blu pastello',
        'пастельно синий'
    ],
    [
        '5025',
        '#2a6478',
        'perlenzian',
        'pearl gentian blue',
        'gentiane nacré',
        'gencian perlado',
        'blu genziana perlato',
        'перламутровый горечавково синий'
    ],
    [
        '5026',
        '#102c54',
        'perlnachtblau',
        'pearl night blue',
        'bleu nuit nacré',
        'azul noche perlado',
        'blu notte perlato',
        'перламутровый ночной синий'
    ],
    [
        '6000',
        '#316650',
        'patinagrün',
        'patina green',
        'vert patine',
        'verde patina',
        'verde patina',
        'патиново зеленый'
    ],
    [
        '6001',
        '#287233',
        'smaragdgrün',
        'emerald green',
        'vert émeraude',
        'verde esmeralda',
        'verde smeraldo',
        'изумрудно зеленый'
    ],
    [
        '6002',
        '#2d572c',
        'laubgrün',
        'leaf green',
        'vert feuillage',
        'verde hoja',
        'verde foglia',
        'лиственно зеленый'
    ],
    [
        '6003',
        '#424632',
        'olivgrün',
        'olive green',
        'vert olive',
        'verde oliva',
        'verde oliva',
        'оливково зеленый'
    ],
    [
        '6004',
        '#1f3a3d',
        'blaugrün',
        'blue green',
        'vert bleu',
        'verde azulado',
        'verde bluastro',
        'сине зеленый'
    ],
    [
        '6005',
        '#2f4538',
        'moosgrün',
        'moss green',
        'vert mousse',
        'verde musgo',
        'verde muschio',
        'зеленый мох'
    ],
    [
        '6006',
        '#3e3b32',
        'grauoliv',
        'grey olive',
        'olive gris',
        'oliva grisáceo',
        'oliva grigiastro',
        'серо оливковый'
    ],
    [
        '6007',
        '#343b29',
        'flaschengrün',
        'bottle green',
        'vert bouteille',
        'verde botella',
        'verde bottiglia',
        'бутылочно зеленый'
    ],
    [
        '6008',
        '#39352a',
        'braungrün',
        'brown green',
        'vert brun',
        'verde parduzco',
        'verde brunastro',
        'коричнево зеленый'
    ],
    [
        '6009',
        '#31372b',
        'tannengrün',
        'fir green',
        'vert sapin',
        'verde abeto',
        'verde abete',
        'пихтовый зеленый'
    ],
    [
        '6010',
        '#35682d',
        'grasgrün',
        'grass green',
        'vert herbe',
        'verde hierba',
        'verde erba',
        'травяной зеленый'
    ],
    [
        '6011',
        '#587246',
        'resedagrün',
        'reseda green',
        'vert réséda',
        'verde reseda',
        'verde reseda',
        'резедово зеленый'
    ],
    [
        '6012',
        '#343e40',
        'schwarzgrün',
        'black green',
        'vert noir',
        'verde negruzco',
        'verde nerastro',
        'черно зеленый'
    ],
    [
        '6013',
        '#6c7156',
        'schilfgrün',
        'reed green',
        'vert jonc',
        'verde caña',
        'verde canna',
        'тростниково зеленый'
    ],
    [
        '6014',
        '#47402e',
        'gelboliv',
        'yellow olive',
        'olive jaune',
        'amarillo oliva',
        'oliva giallastro',
        'желто оливковый'
    ],
    [
        '6015',
        '#3b3c36',
        'schwarzoliv',
        'black olive',
        'olive noir',
        'oliva negruzco',
        'oliva nerastro',
        'черно оливковый'
    ],
    [
        '6016',
        '#1e5945',
        'türkisgrün',
        'turquoise green',
        'vert turquoise',
        'verde turquesa',
        'verde turchese',
        'бирюзово зеленый'
    ],
    [
        '6017',
        '#4c9141',
        'maigrün',
        'may green',
        'vert mai',
        'verde mayo',
        'verde maggio',
        'майский зеленый'
    ],
    [
        '6018',
        '#57a639',
        'gelbgrün',
        'yellow green',
        'vert jaune',
        'verde amarillento',
        'verde giallastro',
        'желто зелёный'
    ],
    [
        '6019',
        '#bdecb6',
        'weißgrün',
        'pastel green',
        'vert blanc',
        'verde lanquecino',
        'verde biancastro',
        'бело зеленый'
    ],
    [
        '6020',
        '#2e3a23',
        'chromoxidgrün',
        'chrome green',
        'vert oxyde chromique',
        'verde cromo',
        'verde cromo',
        'хромовый зеленый'
    ],
    [
        '6021',
        '#89ac76',
        'blassgrün',
        'pale green',
        'vert pâle',
        'verde pálido',
        'verde pallido',
        'бледно зеленый'
    ],
    [
        '6022',
        '#25221b',
        'braunoliv',
        'olive drab',
        'olive brun',
        'oliva parduzco',
        'oliva brunastro',
        'коричнево оливковый'
    ],
    [
        '6024',
        '#308446',
        'verkehrsgrün',
        'traffic green',
        'vert signalisation',
        'verde tráfico',
        'verde traffico',
        'транспортный зеленый'
    ],
    [
        '6025',
        '#3d642d',
        'farngrün',
        'fern green',
        'vert fougère',
        'verde helecho',
        'verde felce',
        'папоротниково зеленый'
    ],
    [
        '6026',
        '#015d52',
        'opalgrün',
        'opal green',
        'vert opale',
        'verde opalo',
        'verde opale',
        'опаловый зеленый'
    ],
    [
        '6027',
        '#84c3be',
        'lichtgrün',
        'light green',
        'vert clair',
        'verde luminoso',
        'verde chiaro',
        'светло зеленый'
    ],
    [
        '6028',
        '#2c5545',
        'kieferngrün',
        'pine green',
        'vert pin',
        'verde pino',
        'verde pino',
        'сосновый зеленый'
    ],
    [
        '6029',
        '#20603d',
        'minzgrün',
        'mint green',
        'vert menthe',
        'verde menta',
        'verde menta',
        'мятно зеленый'
    ],
    [
        '6032',
        '#317f43',
        'signalgrün',
        'signal green',
        'vert de sécurité',
        'verde señales',
        'verde segnale',
        'сигнальный зеленый'
    ],
    [
        '6033',
        '#497e76',
        'minttürkis',
        'mint turquoise',
        'turquoise menthe',
        'turquesa menta',
        'turchese menta',
        'мятно бирюзовый'
    ],
    [
        '6034',
        '#7fb5b5',
        'pastelltürkis',
        'pastel turquoise',
        'turquoise pastel',
        'turquesa pastel',
        'turchese pastello',
        'пастельно бирюзовый'
    ],
    [
        '6035',
        '#1c542d',
        'perlgrün',
        'pearl green',
        'vert nacré',
        'verde perlado',
        'verde perlato',
        'перламутрово зеленый'
    ],
    [
        '6036',
        '#193737',
        'perlopalgrün',
        'pearl opal green',
        'vert opal nacré',
        'verde ópalo perlado',
        'verde opalo perlato',
        'перламутровый опаловый зеленый'
    ],
    [
        '6037',
        '#008f39',
        'reingrün',
        'pure green',
        'vert pur',
        'verde puro',
        'verde puro',
        'зеленый'
    ],
    [
        '6038',
        '#00bb2d',
        'leuchtgrün',
        'luminous green',
        'vert brillant',
        'verde brillante',
        'verde brillante',
        'люминесцентный зеленый'
    ],
    [
        '7000',
        '#78858b',
        'fehgrau',
        'squirrel grey',
        'gris petit gris',
        'gris ardilla',
        'grigio vaio',
        'серая белка'
    ],
    [
        '7001',
        '#8a9597',
        'silbergrau',
        'silver grey',
        'gris argent',
        'gris plata',
        'grigio argento',
        'серебристо серый'
    ],
    [
        '7002',
        '#7e7b52',
        'olivgrau',
        'olive grey',
        'gris olive',
        'gris oliva',
        'grigio olivastro',
        'оливково серый'
    ],
    [
        '7003',
        '#6c7059',
        'moosgrau',
        'moss grey',
        'gris mousse',
        'gris musgo',
        'grigio muschio',
        'серый мох'
    ],
    [
        '7004',
        '#969992',
        'signalgrau',
        'signal grey',
        'gris de sécurité',
        'gris señales',
        'grigio segnale',
        'сигнальный серый'
    ],
    [
        '7005',
        '#646b63',
        'mausgrau',
        'mouse grey',
        'gris souris',
        'gris ratón',
        'grigio topo',
        'мышино серый'
    ],
    [
        '7006',
        '#6d6552',
        'beigegrau',
        'beige grey',
        'gris beige',
        'gris beige',
        'grigio beige',
        'бежево серый'
    ],
    [
        '7008',
        '#6a5f31',
        'khakigrau',
        'khaki grey',
        'gris kaki',
        'gris caqui',
        'grigio kaki',
        'серое хаки'
    ],
    [
        '7009',
        '#4d5645',
        'grüngrau',
        'green grey',
        'gris vert',
        'gris verdoso',
        'grigio verdastro',
        'зелено серый'
    ],
    [
        '7010',
        '#4c514a',
        'zeltgrau',
        'tarpaulin grey',
        'gris tente',
        'gris lona',
        'grigio tenda',
        'брезентово серый'
    ],
    [
        '7011',
        '#434b4d',
        'eisengrau',
        'iron grey',
        'gris fer',
        'gris hierro',
        'grigio ferro',
        'железно серый'
    ],
    [
        '7012',
        '#4e5754',
        'basaltgrau',
        'basalt grey',
        'gris basalte',
        'gris basalto',
        'grigio basalto',
        'базальтово серый'
    ],
    [
        '7013',
        '#464531',
        'braungrau',
        'brown grey',
        'gris brun',
        'gris parduzco',
        'grigio brunastro',
        'коричнево серый'
    ],
    [
        '7015',
        '#434750',
        'schiefergrau',
        'slate grey',
        'gris ardoise',
        'gris pizarra',
        'grigio ardesia',
        'сланцево серый'
    ],
    [
        '7016',
        '#293133',
        'anthrazitgrau',
        'anthracite grey',
        'gris anthracite',
        'gris antracita',
        'grigio antracite',
        'антрацитово серый'
    ],
    [
        '7021',
        '#23282b',
        'schwarzgrau',
        'black grey',
        'gris noir',
        'gris negruzco',
        'grigio nerastro',
        'черно серый'
    ],
    [
        '7022',
        '#332f2c',
        'umbragrau',
        'umbra grey',
        'gris terre d’ombre',
        'gris sombra',
        'grigio ombra',
        'серая умбра'
    ],
    [
        '7023',
        '#686c5e',
        'betongrau',
        'concrete grey',
        'gris béton',
        'gris hormigón',
        'grigio calcestruzzo',
        'серый бетон'
    ],
    [
        '7024',
        '#474a51',
        'graphitgrau',
        'graphite grey',
        'gris graphite',
        'gris grafita',
        'grigio grafite',
        'графитовый серый'
    ],
    [
        '7026',
        '#2f353b',
        'granitgrau',
        'granite grey',
        'gris granit',
        'gris granito',
        'grigio granito',
        'гранитовый серый'
    ],
    [
        '7030',
        '#8b8c7a',
        'steingrau',
        'stone grey',
        'gris pierre',
        'gris piedra',
        'grigio pietra',
        'каменно серый'
    ],
    [
        '7031',
        '#474b4e',
        'blaugrau',
        'blue grey',
        'gris bleu',
        'gris azulado',
        'grigio bluastro',
        'сине серый'
    ],
    [
        '7032',
        '#b8b799',
        'kieselgrau',
        'pebble grey',
        'gris silex',
        'gris guijarro',
        'grigio ghiaia',
        'галечный серый'
    ],
    [
        '7033',
        '#7d8471',
        'zementgrau',
        'cement grey',
        'gris ciment',
        'gris cemento',
        'grigio cemento',
        'цементно серый'
    ],
    [
        '7034',
        '#8f8b66',
        'gelbgrau',
        'yellow grey',
        'gris jaune',
        'gris amarillento',
        'grigio giallastro',
        'желто серый'
    ],
    [
        '7035',
        '#d7d7d7',
        'lichtgrau',
        'light grey',
        'gris clair',
        'gris luminoso',
        'grigio luce',
        'светло серый'
    ],
    [
        '7036',
        '#7f7679',
        'platingrau',
        'platinum grey',
        'gris platine',
        'gris platino',
        'grigio platino',
        'платиново серый'
    ],
    [
        '7037',
        '#7d7f7d',
        'staubgrau',
        'dusty grey',
        'gris poussière',
        'gris polvo',
        'grigio polvere',
        'пыльно серый'
    ],
    [
        '7038',
        '#c3c3c3',
        'achatgrau',
        'agate grey',
        'gris agate',
        'gris ágata',
        'grigio agata',
        'агатовый серый'
    ],
    [
        '7039',
        '#6c6960',
        'quarzgrau',
        'quartz grey',
        'gris quartz',
        'gris cuarzo',
        'grigio quarzo',
        'кварцевый серый'
    ],
    [
        '7040',
        '#9da1aa',
        'fenstergrau',
        'window grey',
        'gris fenêtre',
        'gris ventana',
        'grigio finestra',
        'серое окно'
    ],
    [
        '7042',
        '#8d948d',
        'verkehrsgrau a',
        'traffic grey a',
        'gris signalisation a',
        'gris tráfico a',
        'grigio traffico a',
        'транспортный серый a'
    ],
    [
        '7043',
        '#4e5452',
        'verkehrsgrau b',
        'traffic grey b',
        'gris signalisation b',
        'gris tráfico b',
        'grigio traffico b',
        'транспортный серый b'
    ],
    [
        '7044',
        '#cac4b0',
        'seidengrau',
        'silk grey',
        'gris soie',
        'gris seda',
        'grigio seta',
        'серый шелк'
    ],
    [
        '7045',
        '#909090',
        'telegrau 1',
        'telegrey 1',
        'telegris 1',
        'gris tele 1',
        'tele grigio 1',
        'телегрей 1'
    ],
    [
        '7046',
        '#82898f',
        'telegrau 2',
        'telegrey 2',
        'telegris 2',
        'gris tele 2',
        'tele grigio 2',
        'телегрей 2'
    ],
    [
        '7047',
        '#d0d0d0',
        'telegrau 4',
        'telegrey 4',
        'telegris 4',
        'gris tele 4',
        'tele grigio 4',
        'телегрей 4'
    ],
    [
        '7048',
        '#898176',
        'perlmausgrau',
        'pearl mouse grey',
        'gris souris nacré',
        'gris musgo perlado',
        'grigio topo perlato',
        'перламутровый мышино серый'
    ],
    [
        '8000',
        '#826c34',
        'grünbraun',
        'green brown',
        'brun vert',
        'pardo verdoso',
        'marrone verdastro',
        'зелено коричневый'
    ],
    [
        '8001',
        '#955f20',
        'ockerbraun',
        'ochre brown',
        'brun terre de sienne',
        'pardo ocre',
        'marrone ocra',
        'охра коричневая'
    ],
    [
        '8002',
        '#6c3b2a',
        'signalbraun',
        'signal brown',
        'brun de sécurité',
        'marrón señales',
        'marrone segnale',
        'сигнальный коричневый'
    ],
    [
        '8003',
        '#734222',
        'lehmbraun',
        'clay brown',
        'brun argile',
        'pardo arcilla',
        'marrone fango',
        'глиняный коричневый'
    ],
    [
        '8004',
        '#8e402a',
        'kupferbraun',
        'copper brown',
        'brun cuivré',
        'pardo cobre',
        'marronerame',
        'медно коричневый'
    ],
    [
        '8007',
        '#59351f',
        'rehbraun',
        'fawn brown',
        'brun fauve',
        'pardo corzo',
        'marrone capriolo',
        'олень коричневый'
    ],
    [
        '8008',
        '#6f4f28',
        'olivbraun',
        'olive brown',
        'brun olive',
        'pardo oliva',
        'marrone oliva',
        'оливково коричневый'
    ],
    [
        '8011',
        '#5b3a29',
        'nussbraun',
        'nut brown',
        'brun noisette',
        'pardo nuez',
        'marrone noce',
        'орехово коричневый'
    ],
    [
        '8012',
        '#592321',
        'rotbraun',
        'red brown',
        'brun rouge',
        'pardo rojo',
        'marrone rossiccio',
        'красно коричневый'
    ],
    [
        '8014',
        '#382c1e',
        'sepiabraun',
        'sepia brown',
        'brun sépia',
        'sepia',
        'marrone seppia',
        'сепия коричневый'
    ],
    [
        '8015',
        '#633a34',
        'kastanienbraun',
        'chestnut brown',
        'marron',
        'castaño',
        'marrone castagna',
        'каштаново коричневый'
    ],
    [
        '8016',
        '#4c2f27',
        'mahagonibraun',
        'mahogany brown',
        'brun acajou',
        'caoba',
        'marrone mogano',
        'махагон коричневый'
    ],
    [
        '8017',
        '#45322e',
        'schokoladen braun',
        'chocolate brown',
        'brun chocolat',
        'chocolate',
        'marrone cioccolata',
        'шоколадно коричневый'
    ],
    [
        '8019',
        '#403a3a',
        'graubraun',
        'grey brown',
        'brun gris',
        'pardo grisáceo',
        'marrone grigiastro',
        'серо коричневый'
    ],
    [
        '8022',
        '#212121',
        'schwarzbraun',
        'black brown',
        'brun noir',
        'pardo negruzco',
        'marrone nerastro',
        'черно коричневый'
    ],
    [
        '8023',
        '#a65e2e',
        'orangebraun',
        'orange brown',
        'brun orangé',
        'pardo anaranjado',
        'marrone arancio',
        'оранжево коричневый'
    ],
    [
        '8024',
        '#79553d',
        'beigebraun',
        'beige brown',
        'brun beige',
        'pardo beige',
        'marrone beige',
        'бежево коричневый'
    ],
    [
        '8025',
        '#755c48',
        'blassbraun',
        'pale brown',
        'brun pâle',
        'pardo pálido',
        'marrone pallido',
        'бледно коричневый'
    ],
    [
        '8028',
        '#4e3b31',
        'terrabraun',
        'terra brown',
        'brun terre',
        'marrón tierra',
        'marrone terra',
        'терракотовый'
    ],
    [
        '8029',
        '#763c28',
        'perlkupfer',
        'pearl copper',
        'cuivre nacré',
        'cobre perlado',
        'rame perlato',
        'перламутровый медный'
    ],
    [
        '9001',
        '#faf4e3',
        'cremeweiß',
        'cream',
        'blanc crème',
        'blanco crema',
        'bianco crema',
        'кремово белый'
    ],
    [
        '9002',
        '#e7ebda',
        'grauweiß',
        'grey white',
        'blanc gris',
        'blanco grisáceo',
        'bianco grigiastro',
        'светло серый'
    ],
    [
        '9003',
        '#f4f4f4',
        'signalweiß',
        'signal white',
        'blanc de sécurité',
        'blanco señales',
        'bianco segnale',
        'сигнальный белый'
    ],
    [
        '9004',
        '#282828',
        'signalschwarz',
        'signal black',
        'noir de sécurité',
        'negro señales',
        'nero segnale',
        'сигнальный черный'
    ],
    [
        '9005',
        '#0a0a0a',
        'tiefschwarz',
        'jet black',
        'noir foncé',
        'negro intenso',
        'nero intenso',
        'черный янтарь'
    ],
    [
        '9006',
        '#a5a5a5',
        'weißaluminium',
        'white aluminium',
        'aluminium blanc',
        'aluminio blanco',
        'aluminio brillante',
        'бело алюминиевый'
    ],
    [
        '9007',
        '#8f8f8f',
        'graualuminium',
        'grey aluminium',
        'aluminium gris',
        'aluminio gris',
        'aluminio grigiastro',
        'темно алюминиевый'
    ],
    [
        '9010',
        '#ffffff',
        'reinweiß',
        'pure white',
        'blanc pur',
        'blanco puro',
        'bianco puro',
        'белый'
    ],
    [
        '9011',
        '#1c1c1c',
        'graphitschwarz',
        'graphite black',
        'noir graphite',
        'negro grafito',
        'nero grafite',
        'графитно черный'
    ],
    [
        '9016',
        '#f6f6f6',
        'verkehrsweiß',
        'traffic white',
        'blanc signalisation',
        'blanco tráfico',
        'bianco traffico',
        'транспортный белый'
    ],
    [
        '9017',
        '#1e1e1d',
        'verkehrs schwarz',
        'traffic black',
        'noir signalisation',
        'negro tráfico',
        'nero traffico',
        'транспортный черный'
    ],
    [
        '9018',
        '#d7d7d7',
        'papyrusweiß',
        'papyrus white',
        'blanc papyrus',
        'blanco papiro',
        'bianco papiro',
        'папирусно белый'
    ],
    [
        '9022',
        '#9c9c9c',
        'perlhellgrau',
        'pearl light grey',
        'gris clair nacré',
        'gris claro perlado',
        'grigio chiaro perlato',
        'перламутровый светло серый'
    ],
    [
        '9023',
        '#828282',
        'perldunkelgrau',
        'pearl dark grey',
        'gris fonçé nacré',
        'gris oscuro perlado',
        'grigio scuro perlato',
        'перламутровый темно серый'
    ]
]

export const AdditionalColors: string[][] = [
	['', '#FBEC5D', ...yellow],
	['', '#2A52BE', ...blue],
	['', '#FFD700', ...golden],
	['', '#efefef', ...white],
	['', '#37363C', 'dark grey', 'темно серый'],
	['', '#808080', ...grey],
	['', '#1e1e1e', ...black],
	['', '#44271C', ...chocolate],
	['', '#B47256', ...quarry]
]

