import type { ColumnState } from "ag-grid-enterprise";
import type { PropertyBase} from "bim-ts";
import { BooleanProperty, NumberProperty, StringProperty, type ValueAndUnit } from "bim-ts";
import { LegacyLogger } from "engine-utils-ts";

export type RowValue = string | number | boolean | ValueAndUnit;
export type RowModel = { [field: string]: RowValue };



export function isValueAndUnit(object: RowValue): object is ValueAndUnit {
    return typeof object === "object" && "value" in object;
}

export interface TemplateDescription {
    id: number;
    label: string;
    state: ColumnState[];
    filter: {
        [key: string]: any;
    };
    data: {
        rowGroupCols: string[];
        valueCols: string[];
        pivotCols: string[];
        pivotMode: boolean;
        filterModel: any;
    }
    version: TemplateDescriptionVersion
}

export function TableRowValueToNumberAndUnit(rowValue: RowValue): ValueAndUnit | null {
    if (typeof rowValue === 'number') {
        return { value: rowValue };
    } else if (isValueAndUnit(rowValue)) {
        return rowValue;
    }
    return null;
}

export function TableRowValueToPropertyType(rowValue: RowValue): NumberProperty | StringProperty | BooleanProperty {
    if (typeof rowValue === 'boolean') {
        return BooleanProperty.new({ value: rowValue });
    }
    if (typeof rowValue === 'number') {
        return NumberProperty.new({ unit: '', value: rowValue });
    }
    if (typeof rowValue === 'string') {
        return StringProperty.new({ value: rowValue });
    }
    if (isValueAndUnit(rowValue)) {
        return NumberProperty.new({ value: rowValue.value, unit: rowValue.unit ?? '' });
    }
    throw new Error(`type "${rowValue}" is not supported`);
}

export function PropertyTypeToTableRowValue(
    prop: PropertyBase
): RowValue {
    if (prop instanceof NumberProperty && prop.unit) {
        return {
            value: prop.value,
            unit: prop.unit
        }
    }
    if (
        prop instanceof BooleanProperty ||
        prop instanceof StringProperty ||
        prop instanceof NumberProperty
    ) {
        return prop.value
    }
    LegacyLogger.deferredWarn('PropertyTypeToTableRowValue unexpected input property type', prop.constructor.name);
    return '';
}

export enum TemplateDescriptionVersion {
    None = 0,
    AddVersion = 1,
    RenameCircuitEnergyToCircuitAggregatedCapacity = 2,
}

export const TemplateDescriptionLatestVersion = TemplateDescriptionVersion.RenameCircuitEnergyToCircuitAggregatedCapacity;


export function migrateTemplateDescription(description: TemplateDescription) {
    if (!description.version || description.version < TemplateDescriptionVersion.AddVersion) {
        description.version = TemplateDescriptionVersion.AddVersion;
    }

    if (description.version < TemplateDescriptionVersion.RenameCircuitEnergyToCircuitAggregatedCapacity) {
        const {
            data: { valueCols, filterModel, pivotCols, rowGroupCols },
            filter,
            state,
        } = description;

        // migrate data.valuesCols
        for (let i = 0; i < valueCols.length; i++) {
            valueCols[i] = replaceCircuitEnergyByCircuitAggregatedCapacityInPath(valueCols[i])
        }

        // migrate data.filterModel
        const newFilterModel: Record<string, unknown> = {}
        for (const [key, value] of Object.entries(filterModel)) {
            const newKey = replaceCircuitEnergyByCircuitAggregatedCapacityInPath(key)
            newFilterModel[newKey] = value
        }
        description.data.filterModel = newFilterModel

        // migrate filter
        const newFilter: Record<string, unknown> = {}
        for (const [key, value] of Object.entries(filter)) {
            const newKey = replaceCircuitEnergyByCircuitAggregatedCapacityInPath(key)
            newFilter[newKey] = value
        }
        description.filter = newFilter

        // migrate pivotCols
        for (let i = 0; i < pivotCols.length; i++) {
            pivotCols[i] = replaceCircuitEnergyByCircuitAggregatedCapacityInPath(pivotCols[i])
        }

        // migrate rowGroupCols
        for (let i = 0; i < rowGroupCols.length; i++) {
            rowGroupCols[i] = replaceCircuitEnergyByCircuitAggregatedCapacityInPath(rowGroupCols[i])
        }

        // migrate state
        for (let i = 0; i < state.length; i++) {
            state[i].colId = replaceCircuitEnergyByCircuitAggregatedCapacityInPath(state[i].colId)
        }
    }

    description.version = TemplateDescriptionLatestVersion;

    return description;
}

export function replaceCircuitEnergyByCircuitAggregatedCapacityInPath(path: string) {
    const components = path.split(' | ');
    if (
        components.length < 2 ||
        components[0] !== 'circuit' ||
        components[1] !== 'energy'
    ) {
        return path
    }
    components[1] = 'aggregated_capacity';
    return components.join(' | ')
}
