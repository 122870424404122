import { CostModel as CM } from "bim-ts";
import {
    GridOptions,
    GridSizeChangedEvent,
    ICellRendererComp,
    ICellRendererParams,
    IsFullWidthRowParams,
    RowHeightParams,
} from "ag-grid-enterprise";
import SvelteComponent from "../LcoeSummaryRow.svelte";
import { ContextPlaceholder } from "./types";
import { getContext } from "./column-definitions/common";

export function createLcoeSummaryRowGridOptions() {
    const gridOptions: Partial<GridOptions<CM.FlattenedCostCategoryParams>> = {
        fullWidthCellRenderer: ChartsRow,

        isFullWidthRow: (
            params: IsFullWidthRowParams<
                CM.FlattenedCostCategoryParams,
                ContextPlaceholder
            >,
        ) => {
            if (params.rowNode.data?.nestLevel === 0) {
                return true;
            }
            return false;
        },

        //getRowHeight: (params: RowHeightParams<CM.FlattenedCostCategoryParams, ContextPlaceholder>) => {
        //    if (!params.node.isFullWidthCell?()) {
        //        return;
        //    }
        //    return ChartsRow.calculateRowHeightFromTableWidth(params.context.echarts.length, params.context.tableWidth);
        //}
    };
    return gridOptions;
}

export class ChartsRow implements ICellRendererComp {
    div!: HTMLElement;
    comp!: SvelteComponent;

    init(
        params: ICellRendererParams<
            CM.FlattenedCostCategoryParams,
            unknown,
            ContextPlaceholder
        >,
    ) {
        this.div = document.createElement("div");
        this.div.style.height = '100%';
        this.comp = new SvelteComponent({
            target: this.div,
            props: {
                lcoe: params.context.lcoe,
                unitsMapper: params.context.unitsMapper,
            },
        });
    }

    destroy() {
        this.comp.$destroy();
    }

    getGui() {
        return this.div;
    }

    refresh(
        params: ICellRendererParams<
            CM.FlattenedCostCategoryParams,
            unknown,
            ContextPlaceholder
        >,
    ): boolean {
        this.comp.$set({
            lcoe: params.context.lcoe,
            unitsMapper: params.context.unitsMapper,
        });
        return true;
    }
}
