import type { ICellEditorComp, ICellEditorParams, ICellRendererComp, ICellRendererParams } from 'ag-grid-enterprise';
import { RGBA } from 'engine-utils-ts';

export class ColorCellEditor implements ICellEditorComp {
  eGui!: HTMLDivElement;
  eInput!: HTMLInputElement;

  // gets called once before the renderer is used
  init(params: ICellEditorParams) {
    // create the cell
    const hex = RGBA.toHexRgbString(params.value);

    this.eGui = document.createElement('div');
    this.eGui.style.background = hex;
    this.eGui.style.height = "100%";
    this.eGui.style.width = "100%";
    this.eGui.style.boxShadow = 'inset 0 0 0 2px #ccc';
    this.eGui.style.display = "flex";

    this.eGui.innerHTML = `
          <input
          value=${hex} 
          class="config-ui-input-field colored-input" 
          type="color"
          >
       `;

    // get references to the elements we want
    this.eInput = this.eGui.querySelector<HTMLInputElement>('.colored-input')!;
    this.eInput.style.width = '100%';
    this.eInput.style.height = '100%';

    const updateValue = (e:Event)=>{
      const event = e as any;
      if(event?.target?.value){
        this.eInput.value = `${event?.target?.value}`;
        // console.log(`${this.eInput.value}`);
      }
    }
    this.eInput.addEventListener('input', updateValue);


  }
   
  getGui() {
    return this.eGui;
  }

  afterGuiAttached() {
    this.eGui.focus();
  }

  getValue() {
    const rgba = RGBA.parseFromHexString(this.eInput.value);
    return rgba;
  }

  // if true, then this editor will appear in a popup
  isPopup() {
    // and we could leave this method out also, false is the default
    return false;
  }
}

export class ColorCellRender implements ICellRendererComp  {

  eGui!: HTMLDivElement;

  // gets called once before the renderer is used
  init(params: ICellRendererParams) {
    // create the cell
    const hex = RGBA.toHexRgbString(params.value);
    this.eGui = document.createElement('div');
    this.eGui.style.background = hex;
    this.eGui.style.height = "100%";
    this.eGui.style.width = "100%";
    this.eGui.style.boxShadow = 'inset 0 0 0 2px #ccc'

  }
   
  getGui() {
    return this.eGui;
  }
  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams) {
    // set value into cell again
    const hex = RGBA.toHexRgbString(params.value);

    this.eGui.style.background = hex;

    // return true to tell the grid we refreshed successfully
    return true;
  }

  // gets called when the cell is removed from the grid
  destroy() {
  }
}