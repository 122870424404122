import type { LazyVersioned } from "engine-utils-ts";
import type { UiViewSource } from "./UI_Source";

export type DialogAction<Context> = (context: Context) => void;

export interface DialogActionDescription<Context> {
    name: string;
    action: DialogAction<Context>;
    style?: number;
    disabled?: LazyVersioned<boolean>;
}

export class DialogDescription<T, Context> {
    id: string;
    name: string;
    message?: string;
    closeAction?: string;
    compact?: boolean;
    uiSource?: UiViewSource<T>;

    context: Context;
    userActions?: DialogActionDescription<Context>[];
    onClose?: () => void;

    private _defaultAction?: DialogAction<Context>;

    private _isActedUpon = false;

    constructor(params: {
        id?: string;
        name: string;
        message?: string;
        uiSource?: UiViewSource<T>;
        context: Context;
        defaultAction?: DialogAction<Context>,
        userActions?: DialogActionDescription<Context>[];
        closeAction?: string;
        compact?: boolean;
        onClose?: () => void;
    }) {
        if (params.defaultAction && !params.uiSource) {
            throw new Error(`if dialog description has defaultAction, uiSource should also be provided`);
        }
        if (params.userActions?.length && !params.uiSource) {
            throw new Error(`if dialog description has userActions, uiSource should also be provided`);
        }

        this.id = params.id ?? params.name;
        this.name = params.name;
        this.message = params.message;
        this.uiSource = params.uiSource;
        this.context = params.context;
        this._defaultAction = params.defaultAction;
        this.userActions = params.userActions;
        this.closeAction = params.closeAction;
        this.compact = params.compact;
        this.onClose = params.onClose;
    }

    isActedUpon() {
        return this._isActedUpon;
    }

    // should be immidiately called by UI, if ui is active
    preventDefaultAction() {
        this._defaultAction = undefined;
    }

    canExecuteDefault() {
        return !this._isActedUpon && this._defaultAction != undefined;
    }

    executeDefault() {
        if (!this.canExecuteDefault()) {
            return;
        }
        this._isActedUpon = true;
        this._defaultAction!(this.context);
    }

    executeAction(action: DialogActionDescription<Context>) {
        if (this._isActedUpon) {
            return;
        }
        const isActionFromThisDialog = (this.userActions ?? []).includes(action);
        if (!isActionFromThisDialog) {
            throw new Error(`attempt to call action not included in this DialogDescription`);
        }
        this._isActedUpon = true;
        action.action(this.context);
    }
}
