import type { Vector2 } from 'math-ts';
import { createRectangleOfWidth } from '../vectors';

/**
 * @param _before
 * Is a point on previous edge if exist
 * @param _after
 * Is a point on the next edge if exist
 */
export function createVerticesForEdge(
    from: Vector2, to: Vector2, thickness: number, buffer: number[],
) {
    const [a, b, c, d] = createRectangleOfWidth(
        from,
        to,
        thickness,
        0.45,
    );
    buffer.push(

        a.x, a.y, 0,
        b.x, b.y, 0,
        c.x, c.y, 0,

        d.x, d.y, 0,
        c.x, c.y, 0,
        a.x, a.y, 0,

    );
}

export function createVerticesFromPolyline(
    points: Vector2[],
    thickness: number,
    buffer: number[],
) {
    if (points.length < 2) return;
    for (let i = 1; i < points.length; i++) {
        createVerticesForEdge(
            points[i-1], points[i], thickness, buffer,
        );
    }
}

export function setZOnVertices(vertices: number[], newZ: number) {
    if (vertices.length % 3 !== 0)
        throw new Error('vertices array is not multiply of 3');
    if (vertices.length / 3 < 1)
        return;
    for (let i = 2; i < vertices.length; i += 3) {
        vertices[i] = newZ;
    }
}
