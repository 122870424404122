import { DefaultMap, CompressibleNumbersArray, ComprArrOrderingType, Success, IterUtils } from 'engine-utils-ts';
import { EnergyStagesNames, EnergyPipelineStage, type EnergyStageName } from './EnergyPipelineProperty';
import { energySucess, EnergyFailure, type EnergyResult } from './EnergyResult';
import { EnergyStageChartW, type EnergyStageChart, EnergyStageChartWPerArea } from './EnergyStageCharts';
import type { EnergyPipelineProperty, IdBimScene, ShadingFactorsTable } from '..';
import { ShadingFactorsMerger } from '../trackers/shading/ShadingFactorsTable';



export class EnergyPipelinesMerger {

    _energyStagesPowerCombined = new DefaultMap<EnergyStageName, EnergyStageBeingMerged>((name) => {
        return new EnergyStageBeingMerged(name);
    });

    _shadingMerger: ShadingFactorsMerger = new ShadingFactorsMerger();

    mergeIn(
        energy: EnergyPipelineProperty,
        multiplier: number,
        errorsAffectedIdsMap: Map<EnergyStageName, IdBimScene[]>,
    ) {
        const stages = energy.stages;

        if (this._energyStagesPowerCombined.size === 0) {
            // first time, initialize
            for (const stage of stages) {
                this._energyStagesPowerCombined.getOrCreate(stage.name);
            }
        } else {
            for (const stage of stages) {
                let combined = this._energyStagesPowerCombined.get(stage.name);
                if (!combined) {
                    // adding stage that was not previously seen
                    // try to populate from previous step and mark incomplete
                    const nameIndex = EnergyStagesNames.indexOf(stage.name);
                    for (let i = nameIndex - 1; i >= 0; --i) {
                        const prevName = EnergyStagesNames[i];
                        const prevCombined = this._energyStagesPowerCombined.get(prevName);
                        if (prevCombined) {
                            // console.warn(`substituting ${stage.name} from ${prevCombined.name}`);
                            combined = this._energyStagesPowerCombined.getOrCreate(stage.name);
                            combined.mergeInIncompletePowerPrevStageSubstitute(prevCombined);
                            break;
                        }
                    }
                }
            }
        }

        let mergedInCount = 0;
        for (let i = 0; i < stages.length; ++i) {
            const stage = stages[i];
            const combined = this._energyStagesPowerCombined.get(stage.name);
            if (combined) {
                ++mergedInCount;

                if (stage.energy instanceof Success) {
                    combined.mergeIn(stage.energy, multiplier, errorsAffectedIdsMap.get(stage.name));
                } else {
                    const indexOfName = EnergyStagesNames.indexOf(stage.name);
                    for (let j = indexOfName - 1; j >= 0; --j) {
                        const name = EnergyStagesNames[j];
                        const prevStage = stages.find(s => s.name === name);
                        if (prevStage && prevStage.energy instanceof Success) {
                            combined.mergeInFailedStageSubstite(
                                stage.energy,
                                prevStage.energy.value,
                                multiplier,
                                errorsAffectedIdsMap.get(stage.name),
                            );
                            break;
                        }
                    }
                }
            }
        }

        if (mergedInCount < this._energyStagesPowerCombined.size) {
            // some of known stages were not merged in
            // populate with previous closest stage if possible
            // and mark incomplete
            const stagesNames = stages.map(s => s.name);

            for (const combined of this._energyStagesPowerCombined.values()) {
                if (stagesNames.includes(combined.name)) {
                    continue;
                }
                combined.incomplete = true;
                const indexOfName = EnergyStagesNames.indexOf(combined.name);
                for (let i = indexOfName - 1; i >= 0; --i) {
                    const name = EnergyStagesNames[i];
                    const prevStage = stages.find(s => s.name === name);
                    if (prevStage && prevStage.energy instanceof Success) {
                        // console.warn(`substituting ${combined.name} from ${stage.name}`);
                        combined.mergeInFailedStageSubstite(
                            undefined,
                            prevStage.energy.value,
                            multiplier,
                            errorsAffectedIdsMap.get(combined.name),
                        );
                        break;
                    }
                }
            }
        }

        this._shadingMerger.mergeIn(energy.shading_factors, multiplier, energy.shaded_modules_area);
    }

    finish(): {stages: EnergyPipelineStage[], shading_factors: ShadingFactorsTable|null, shaded_modules_area: number} {
        const stages = Array.from(this._energyStagesPowerCombined.values())
            .map(sd => sd.toEnergyStage())
            .sort((s1, s2) => EnergyStagesNames.indexOf(s1.name) - EnergyStagesNames.indexOf(s2.name));

        const shading_factors = this._shadingMerger.finish();
        const shaded_modules_area = this._shadingMerger.shadedModulesArea();
        return {stages, shading_factors, shaded_modules_area};
    }
}

class EnergyStageBeingMerged {

    readonly name: EnergyStageName;
    public incomplete: boolean = false;
    private power: Float32Array = new Float32Array(365 * 24);
    private warnings: {e: EnergyFailure, ids: IdBimScene[]|undefined}[] = [];
    private errors: {e: EnergyFailure, ids: IdBimScene[]|undefined}[] = [];
    private sucessfullMergesCount: number = 0;
    private area: number|null = null;

    constructor(
        name: EnergyStageName,
    ) {
        this.name = name;
    }

    _mergeInArea(area: number|null) {
        if (area != null && area > 0
            && (this.sucessfullMergesCount === 0 || this.area !== null)
        ) {
            this.area = (this.area ?? 0) + area;
        } else {
            this.area = null;
        }
    }

    mergeInIncompletePowerPrevStageSubstitute(prevStage: EnergyStageBeingMerged) {
        for (let i = 0; i < prevStage.power.length; ++i) {
            this.power[i] += prevStage.power[i];
        }
        this.incomplete = true;
        this._mergeInArea(prevStage.area);
    }

    mergeInFailedStageSubstite(
        stageError: EnergyFailure|undefined,
        substitute: EnergyStageChart,
        multiplier: number,
        errorIds: IdBimScene[]|undefined,
    ) {
        if (stageError) {
            this.errors.push({e: stageError, ids: errorIds});
        }
        this.incomplete = true;
        substitute.forEachInPowerChart(
            'W',
            (value, index) => {
                this.power[index] += value * multiplier;
            },
            ComprArrOrderingType.Tmy_365_24,
        );
        this._mergeInArea(substitute.combinedArea());
    }

    mergeIn(
        stage: Success<EnergyStageChart, EnergyFailure>,
        multiplier: number,
        errorIds: IdBimScene[]|undefined,
    ) {
        stage.value.forEachInPowerChart(
            'W',
            (value, index) => {
                this.power[index] += value * multiplier;
            },
            ComprArrOrderingType.Tmy_365_24,
        );
        if (stage.warnings?.length) {
            for (const warning of stage.warnings) {
                this.warnings.push({e: warning, ids: errorIds});
            }
        }
        this._mergeInArea(stage.value.combinedArea() * multiplier);
        this.sucessfullMergesCount += 1;
    }

    _toCompressibleArray(resultUnit: string): CompressibleNumbersArray {
        return CompressibleNumbersArray.newFromValues(resultUnit, this.power, ComprArrOrderingType.Tmy_365_24);
    }

    toEnergyStage(): EnergyPipelineStage {
        let stageChart: EnergyResult<EnergyStageChart>;
        if (this.sucessfullMergesCount > 0) {
            const allErrors = dedupErrors(this.errors.concat(this.warnings));
            let chart: EnergyStageChart;
            if (this.area != null && this.area > 0) {
                for (let i = 0; i < this.power.length; ++i) {
                    this.power[i] /= this.area;
                }
                chart = new EnergyStageChartWPerArea(this.area, this._toCompressibleArray('W/m2'));
            } else {
                chart = new EnergyStageChartW(this._toCompressibleArray('W'));
            }
            stageChart = energySucess(
                chart,
                allErrors.length > 1 ? [EnergyFailure.merged(allErrors)] : allErrors,
            );
        } else if (this.errors.length > 0) {
            stageChart = EnergyFailure.merged(dedupErrors(this.errors));
        } else {
            stageChart = EnergyFailure.new('IncompleteStage');
        }
        return new EnergyPipelineStage(
            this.name,
            stageChart,
        );
    }

}

function dedupErrors(errors: {e: EnergyFailure, ids: IdBimScene[]|undefined}[]): EnergyFailure[] {
    if (errors.length === 0) {
        return [];
    }
    if (errors.length === 1) {
        return [errors[0].e.withAffectedInstancesIds(errors[0].ids)];
    }
    errors.sort((e1, e2) => {
        const identsCmp = e1.e.errorMsgIdent.localeCompare(e2.e.errorMsgIdent);
        if (identsCmp) {
            return identsCmp;
        }
        const actionsAsStr1 = e1.e?._actions?.map(a => a.name)?.join(':') ?? '';
        const actionsAsStr2 = e2.e?._actions?.map(a => a.name)?.join(':') ?? '';
        return actionsAsStr1.localeCompare(actionsAsStr2);
    });

    const res: EnergyFailure[] = [];

    let currError = errors[0].e;
    let currIds = (errors[0].ids ?? []).concat(errors[0].e._affectedInstanceIds ?? []);
    for (let i = 1; i < errors.length; ++i) {
        const {e, ids} = errors[i];
        if (e.equalExceptIds(currError)) {
            if (ids) {
                IterUtils.extendArray(currIds, ids);
            }
            if (e._affectedInstanceIds) {
                IterUtils.extendArray(currIds, e._affectedInstanceIds);
            }
        } else {
            res.push(currError.withAffectedInstancesIds(currIds));
            currError = e;
            currIds = (ids ?? []).concat(ids ?? []);
        }
    }
    res.push(currError.withAffectedInstancesIds(currIds));
    return res;
}
