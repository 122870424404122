<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M1 7.5V1H7V7.5H1ZM1 15V9H7V15H1ZM9 7.5V1H15V7.5H9ZM9 15V9H15V15H9ZM2 6.5H6V2H2V6.5ZM10 6.5H14V2H10V6.5ZM10 14H14V10H10V14ZM2 14H6V10H2V14Z"
        fill="currentColor"
    />
</svg>
