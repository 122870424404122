import { CostModel as CM } from 'bim-ts';
import { ColDef } from "ag-grid-enterprise";
import { commonCellClasses, createCostValueFormatter, getContext } from './common';


export const createCostPerWattColumnDefinition = (): ColDef<CM.FlattenedCostCategoryParams> => ({
    colId: CM.CostPerWattColId,
    headerName: CM.CostPerWattColId,
    valueGetter: (params) => {
        const { category, globalContext } = getContext(params);
        if (!category) {
            return;
        }
        const totalCost =
            (category.labor?.laborTotal?.value ?? 0) +
            (category.material?.materialTotal?.value ?? 0) +
            (category.subService?.subServiceTotal?.value ?? 0) +
            (category.equipment?.equipmentTotal?.value ?? 0);

        if (!globalContext.totalDCWatt) {
            return 0;
        }
        return totalCost / globalContext.totalDCWatt;
    },
    valueFormatter: createCostValueFormatter(),
    headerClass: [
        'ag-grid-header-align-right'
    ],
    cellClass: (params) => commonCellClasses(getContext(params), () => undefined),
})
