import type { AugmentConfig, AugmentSettings, Bim, BlockEquipment, Boundary2DDescription, Catalog, DC_CNSTS, FarmLayoutConfig, IdBimScene, IdConfig, MathSolversApi, PropertyBase, PropertyPathType, SubareaConfig, UnitsMapper } from "bim-ts";
import { AugmentConfigType, BoundaryType, CatalogItemsReferenceProperty, FarmLayoutConfigType, NumberPropertyWithOptions, NumberRangeProperty, SceneInstances, StringProperty, TransformerIdent, createDefaultAugmentSubarea } from "bim-ts";
import type { LazyVersioned, TasksRunner, VersionedValue } from "engine-utils-ts";
import { Immer, IterUtils, LazyBasic, LazyDerived, ObjectUtils, ScopedLogger, VersionedInvalidator } from "engine-utils-ts";
import { Aabb2, PointToPolygon, PolygonUtils, type Vector2 } from "math-ts";
import type { UiBindings } from "ui-bindings";
import { PUI_CustomPropertyNode, PUI_GroupNode } from "ui-bindings";
import { ContextMenuConfig, GroupedNotificationGenerator, PUI_Builder, PUI_Lazy } from "ui-bindings";
import type { ZoneBoundaryProps } from "../farm-layout/EquipmentBoundariesCreator";
import type { BlockProperties, LayoutProperties } from "../farm-layout/FarmLayoutMetrics";
import { addSelectSubareaProperty, checkValidArea, makeHashFromSettings } from "../farm-layout/FarmLayoutUi";
import { FarmLayoutValidation } from "../farm-layout/FarmLayoutValidation";
import { addBlocks, addCombinerBoxProperty, addNewBlockEquipment, createILRContextViewUi, createILRContextViewUiForBlocks } from "../farm-layout/FarmLayoutViews";
import type { InvertersGroup } from "../farm-layout/ILRPropertyConfig";
import { getDefaultAssets, setDefaultAssets } from '../panels-config-ui/DefaultAssets';
import { createContext, patchObject } from "../panels-config-ui/GeneratePanelUiBindings";
import type { PropertyPath } from "../panels-config-ui/PropertyBuilders";
import { addProperty } from "../panels-config-ui/PropertyBuilders";
import { removeDeletedSceneInstances } from "../panels-config-ui/RemoveDeletedSceneInstances";
import { Augmentation } from "./Augmentation";
import { runAugmentSolvers } from "./AugumentationLayout";
import type { BlockEquipmentExtended } from "./parse-scene";

export function createAugmentationUI(props: {
    bim: Bim,
    ui: UiBindings,
    logger: ScopedLogger,
    catalog: Catalog,
    tasksRunner: TasksRunner,
    mathSolversApi: MathSolversApi,
    unitsMapper: UnitsMapper,
    lazyMetrics: LazyDerived<Map<IdBimScene, LayoutProperties>>, 
    siteAreaDataLazy: LazyDerived<ZoneBoundaryProps[]>,
    createSelectedAreaUiConfigCallback: (pos: Vector2) => ContextMenuConfig,
}): {
    invalidator: VersionedValue,
    puiCallback: PuiCallback,
} {
    const notificationGroup = new GroupedNotificationGenerator('Augment layout');
    const logger = props.logger.newScope('augmentation-ui');
    const augmentContextLazy = createSelectedConfigLazy(props.bim, logger, props.lazyMetrics, props.siteAreaDataLazy);
    let sortKeyCounter = 20;
    function getNextSortKey() {
        return sortKeyCounter++;
    }
    const transformersInvalidator = props.bim.instances.getLazyListOf({type_identifier: TransformerIdent});

    const isLoading = new LazyBasic('loading', false);

    const invalidator = new VersionedInvalidator([augmentContextLazy, isLoading, transformersInvalidator])

    return {
        invalidator,
        puiCallback: (builder) => {
            const context = augmentContextLazy.poll();
            if(!context){
                return;
            }

            const addGroupNode = (addToGroupFn: () => void) => {
                const sortKey = getNextSortKey();
                builder.inGroup(
                    {
                        name: `group_${sortKey}`,
                        typeSortKeyOverride: sortKey,
                        collapsible: false,
                        showTitle: false,
                    },
                    addToGroupFn
                );
            }

            const setSettings: SetFn<AugmentSettings> = (fn) => {
                const newSettings = fn(context.settings);
                const newProps = Immer.produce(context.config, (draft) => {
                    draft.site_areas[context.selectedAreaIndex].settings = newSettings;
                });
                patchConfig(props.bim, context.id, newProps);
            }

            addGroupNode(() => {

                addSelectSubareaProperty(
                    builder, 
                    props.ui, 
                    props.siteAreaDataLazy, 
                    context.farmConfig,
                    props.createSelectedAreaUiConfigCallback
                );
    
                const selectedSubstationName = IterUtils.find(
                    context.substations, 
                    ([_, substationId]) => substationId === context.connectedTo)?.[0];
                if(selectedSubstationName){
                    builder.addSelectorProp({
                        name: 'Substation',
                        value: selectedSubstationName,
                        options: Array.from(context.substations.keys()),
                        onChange: (substationName) => {
                            const selectedSubstation = context.substations.get(
                                substationName
                            )!;
                            props.bim.instances.setSelected([selectedSubstation]);
                        },
                        typeSortKeyOverride: getNextSortKey(),
                    });
                }
            });
            
            addGroupNode(() => {
                const subarea = context.config.site_areas[context.selectedAreaIndex];
                const subareaMetrics = context.metrics.perZone[context.selectedAreaIndex];
                const message: string[] = [
                    "Place blocks equipment",
                ];
                if(context.settings.electrical.generate_new_blocks.value){ 
                    message.push("Combine solar arrays into blocks");
                } else {
                    message.push("Use existing blocks");
                }

                builder.addCustomProp<string[], {}>({
                    name: 'Target',
                    typeSortKeyOverride: getNextSortKey(),
                    context: {
                        onClick: (p: Vector2) => {
                            const config = createSelectModeUi(
                                props.bim, 
                                props.catalog, 
                                props.ui, 
                                notificationGroup, 
                                logger, 
                                augmentContextLazy, 
                                p, 
                                (settings) => patchConfig(props.bim, context.id, settings));
                            props.ui.addContextMenuView(config);
                        }
                    },
                    value: message,
                    type_ident: "group_properties",
                    onChange: () => { },
                });

                let resultingCapacity = "New layout not generated";
                const hash = makeHash(subarea);
                const haveBlocks = !!subareaMetrics?.numberOfBlocks;
                if(hash !== subarea.last_calculation_hash?.value){
                    //need to regenerate layout
                } else if(haveBlocks) {
                    const totalDc = `<nobr>${subareaMetrics.dcPowerKW.toFixed(0)} kW DC</nobr>`;
                    const dcAcRatio = subareaMetrics.dcPowerKW && subareaMetrics.acPowerKW ? subareaMetrics.dcPowerKW / subareaMetrics.acPowerKW : 0;
                    const totalAc = `<nobr>${subareaMetrics.acPowerKW.toFixed(0)} kW AC (${dcAcRatio.toFixed(2)})</nobr>`
                    resultingCapacity = `${totalDc} / ${totalAc}, <nobr>${subareaMetrics.numberOfBlocks} blocks</nobr>`;
                } else if(subareaMetrics?.dcPowerKW){
                    resultingCapacity = `${subareaMetrics.dcPowerKW.toFixed(2)} kW DC`;
                }
    
                builder.addCustomProp({
                    name: "Resulting capacity",
                    type_ident: "custom-group-name",
                    value: resultingCapacity,
                    onChange: () => {},
                    context: {
                        isProperty: true,
                        multiline: true,
                    },
                    notActive: !haveBlocks,
                });

                builder.addCustomProp<[number, number], {
                    name: string,
                    minMax: [number, number],
                    step: number | undefined
                    openBlocks?: ((pos:Vector2) => void)
                    inverters?: InvertersGroup[]
                }>({
                    name: 'ILR range',
                    type_ident: "ilr_property",
                    value: context.settings.capacity.ilr_range.value,
                    typeSortKeyOverride: getNextSortKey(),
                    context: {
                        name: 'ILR range',
                        minMax: context.settings.capacity.ilr_range.range ?? [0, 10],
                        step: context.settings.capacity.ilr_range.step,
                        inverters: createILRContextViewUiForBlocks(
                            props.catalog, 
                            subareaMetrics, 
                            context.settings.electrical.blocks_equipment,
                            context.settings.capacity.ilr_range.value,
                        ),
                    },
                    onChange: (v) => {
                        setSettings((prev) =>{
                            const updatedProps = Immer.produce(prev, draft => {
                                draft.capacity.ilr_range = draft.capacity.ilr_range.withDifferentValue(v);
                                const blocksEquipment = draft.electrical.blocks_equipment;
                                const [min, max] = draft.capacity.ilr_range.value;
                                for (const block of blocksEquipment) {
                                    const [minValue, maxValue] = block.ilr_range.value;
                                    
                                    block.ilr_range = NumberRangeProperty.new({
                                        ...block.ilr_range,
                                        value: blocksEquipment.length === 1
                                        ? [min, max]
                                        : [Math.max(minValue, min), Math.min(maxValue, max)]
                                    });
                                }
                            });
                            return updatedProps;
                        });
                    },
                });
            });

            addGroupNode(() => {
                const disableInverterOffset = context.settings.electrical.scheme.value !== 'SI_Multiharness';
                const message: string[] = [];
                const combinerBox = context.settings.offsets.combiner_box_offset.toConfiguredUnits(props.bim.unitsMapper);
                const inverter = context.settings.offsets.inverter_offset.toConfiguredUnits(props.bim.unitsMapper);
                const transformer = context.settings.offsets.transformer_offset.toConfiguredUnits(props.bim.unitsMapper);
                message.push(`${transformer.value.toFixed(1)}/${inverter.value.toFixed(1)}/${combinerBox.value.toFixed(1)} ${combinerBox.unit} Equipment`);

                builder.addCustomProp<string[], { onClick: (pos:Vector2) => void; }>({
                    name: 'Offsets',
                    typeSortKeyOverride: getNextSortKey(),
                    context: {
                        onClick: (pos: Vector2) => {
                            const contextMenuConfig = createOffsetsUi(
                                props.bim,
                                props.logger,
                                augmentContextLazy,
                                pos,
                                {
                                    disableInverterOffset: disableInverterOffset,
                                }
                            );
                            props.ui.addContextMenuView(contextMenuConfig);
                        }
                    },
                    value: message,
                    type_ident: "group_properties",
                    onChange: () => { },
                });
            });
            
            addGroupNode(() => {
                const message: string [] = [];
                const selectedRoads = context.settings.equipment_roads.selected_roads.value.length;
                if(context.metrics.roads.length === 0){
                    message.push('No roads');
                } else if(selectedRoads === 0 || selectedRoads === context.metrics.roads.length) {
                    message.push("All roads selected")
                } else {
                    message.push(`${selectedRoads} roads selected`);
                }
                if(context.settings.electrical.generate_new_blocks.value){
                    builder.addCustomProp<string[], { onClick: (pos:Vector2) => void; }>({
                        name: 'Equipment roads',
                        typeSortKeyOverride: getNextSortKey(),
                        context: {
                            onClick: (pos: Vector2) => {
                                const panelSettings = createRoadsUi(props.bim, props.logger, augmentContextLazy, pos, setSettings);
                                props.ui.addContextMenuView(panelSettings);
                            }
                        },
                        value: message,
                        type_ident: "group_properties",
                        onChange: () => { },
                    });
                } else {
                    builder.addStringProp({
                        name: 'Equipment roads',
                        value: 'Ignored',
                        onChange: () => {},
                        readonly: true,
                        notActive: true,
                    });
                }
            });

            addGroupNode(() => {

                builder.addCustomProp<string[], { onClick: (pos:Vector2) => void; }>({
                    name: 'Blocks equipment',
                    typeSortKeyOverride: getNextSortKey(),
                    context: {
                        onClick: (pos: Vector2) => {
                            const blocksConfig = createBlockSettingsUi(
                                props.bim,
                                props.catalog,
                                logger,
                                augmentContextLazy,
                                props.lazyMetrics,
                                pos,
                                props.ui,
                                notificationGroup,
                            );

                            props.ui.addContextMenuView(blocksConfig);
                        }
                    },
                    value: context.settings.electrical.blocks_equipment.map(b => b.label.value),
                    type_ident: "group_properties",
                    onChange: () => { },
                });
            });

            builder.addCustomProp({
                name: 'end-line-2',
                value: {},
                type_ident: "divider",
                onChange: () =>{ },
                readonly: true,
                context: undefined,
                typeSortKeyOverride: getNextSortKey(),
            });

            builder.addActionsNode({
                actions: [
                    {
                        action: async () => {
                            try {
                                isLoading.replaceWith(true);
                                const context = augmentContextLazy.poll();
                                if(!context){
                                    throw new Error('undefined context');
                                }
                                await runAugmentSolvers(
                                    context.connectedTo,
                                    context.settings,
                                    [context.farmConfigId, context.farmConfig],
                                    props.bim,
                                    props.catalog,
                                    props.mathSolversApi,
                                    props.tasksRunner,
                                    props.ui,
                                    notificationGroup,
                                )
                                .then(() => {
                                    return props.tasksRunner.newLongTask({
                                        defaultGenerator: props.bim.runBasicUpdatesTillCompletion({forceRun: true})
                                    }).asPromise();
                                })
                                .then(() => {
                                    const context = augmentContextLazy.poll();

                                    if(context?.config){
                                        const updatedConfig = Immer.produce(context?.config, draft => {
                                            const draftSettings = draft.site_areas[context.selectedAreaIndex];
                                            if(!draftSettings.settings.electrical.generate_new_blocks.value){
                                                draftSettings.settings.electrical.blocks_equipment 
                                                    = autoFill(props.bim, props.catalog, props.ui, context, notificationGroup);
                                            }
                                            draftSettings.last_calculation_hash = StringProperty.new({value: makeHash(draft.site_areas[context.selectedAreaIndex])});
                                        });
                                        props.bim.configs.applyPatchTo({properties: updatedConfig}, [context.id]);
                                    }

                                });
                            } catch (e) {
                                console.error(e)
                            } finally {
                                isLoading.replaceWith(false);
                            }
                        },
                        label: 'Augment equipment',
                        isEnabled: LazyDerived.new2(
                            '',
                            [],
                            [augmentContextLazy, isLoading],
                            ([config, isLoading]) => {
                                if(!config){
                                    return false;
                                }
                                const checkSubareas = checkValidArea(config.farmConfig);
                                const checkCombinerBox = FarmLayoutValidation.checkCombinerBoxType(
                                        config.settings.electrical.scheme.value, 
                                        config.settings.electrical.combiner_box.value,
                                        props.catalog
                                    );
                                const isEnabled =
                                    config.settings.electrical.blocks_equipment.length > 0 
                                    && checkSubareas
                                    && !checkCombinerBox 
                                    && !isLoading;
                                return !!isEnabled;
                        }),
                        style: {
                            type: 'primary',
                        }
                    },
                ],
                typeSortKeyOverride: getNextSortKey(),
                context: {},
                name: 'actions',
            });

            setDefaultProperties(
                context.id, 
                context.settings, 
                logger, context, 
                props.bim, 
                props.catalog, 
                props.ui, 
                [], 
                notificationGroup
            )
        },
    }
}

interface AugmentPanelContext {
    id: IdConfig;
    connectedTo: IdBimScene;
    config: AugmentConfig;
    settings: AugmentSettings;
    substations: Map<string, IdBimScene>;
    selectedSubstationName: string;
    metrics: LayoutProperties;
    selectedAreaIndex: number;
    farmConfigId: IdConfig;
    farmConfig: FarmLayoutConfig;
    transformersIntoSiteArea: IdBimScene[];
}

function isBoxInsideSomePolygon(boundaries: Boundary2DDescription[], box: Aabb2, position?: Vector2): boolean {
    const aabb = Aabb2.empty();
    const pos = position ? position : box.getCenter();
    for (const boundary of boundaries) {
        aabb.setFromPoints(boundary.pointsWorldSpace);
        if(!aabb.intersectsBox2(box)){
            continue;
        }
        if (PolygonUtils.isPointInsidePolygon(boundary.pointsWorldSpace, pos) !== PointToPolygon.Outside) {
            return true;
        }
    }

    return false;
}

export function isBoxInsideSiteArea(boundaries: Boundary2DDescription[], box: Aabb2, position?: Vector2): boolean {
    const isBoxInsideSiteArea = isBoxInsideSomePolygon(
        boundaries.filter(b => b.boundaryType === BoundaryType.Include), 
        box,
        position
    );

    if(!isBoxInsideSiteArea){
        return false;
    }

    const excludedBoundaries = boundaries.filter(b => b.boundaryType === BoundaryType.Exclude);
    if(excludedBoundaries.length === 0){
        return true;
    }

    const isBoxInsideExcludedArea = isBoxInsideSomePolygon(
        excludedBoundaries,
        box,
        position
    );

    return !isBoxInsideExcludedArea;
}

function createSelectedConfigLazy(
    bim: Bim, 
    logger: ScopedLogger, 
    lazyMetrics: LazyDerived<Map<IdBimScene, LayoutProperties>>,
    siteAreaDataLazy: LazyDerived<ZoneBoundaryProps[]>,
) {
    const context = createContext(bim, AugmentConfigType);
    const contextFarmConfig = createContext(bim, FarmLayoutConfigType);

    const transformersInvalidator = bim.instances.getLazyListOf({type_identifier: TransformerIdent});
    const transformerPerSiteAreasLazy = LazyDerived.new2(
        "transformers-into-area", 
        [transformersInvalidator],
        [siteAreaDataLazy, context.selectionObserver], 
        ([siteAreaData, lastSelected]) => {
            const transformersPerSubarea: IdBimScene[][] = [];
            const lastSelectedId = lastSelected.selected?.id ?? 0;
            const transformerBox = Aabb2.empty();
            const geometriesAabbs = bim.allBimGeometries.aabbs.poll();
            for (let idx = 0; idx < siteAreaData.length; idx++) {
                const boundaries = siteAreaData[idx].boundaries;
                
                const transformers = new Set<IdBimScene>();

                bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(
                    lastSelectedId, 
                    (id) => {
                    const typeIdnt = bim.instances.peekTypeIdentOf(id);
                    if(typeIdnt !== TransformerIdent){
                        return true;
                    }
                    const instance = bim.instances.peekById(id);
                    const position = instance?.properties.get("circuit | position | area_index")?.asNumber() ?? 0;
                    if(position === idx) {
                        transformers.add(id);
                        return true;
                    }
                    const box = instance?.representation?.aabb(geometriesAabbs);
                    if(!box || !instance){
                        return true;
                    }
                    box.applyMatrix4(instance.worldMatrix);
                    transformerBox.setFromPoints(box.get2DCornersAtZ(0));

                    const isTransformerInsideSiteArea = isBoxInsideSiteArea(boundaries, transformerBox);

                    if(isTransformerInsideSiteArea){
                        transformers.add(id);
                    }
    
                    return true;
                });
    
                transformersPerSubarea.push(Array.from(transformers));
            }
            return transformersPerSubarea;
        }
    );

    const lastSelectedItemsLazy = LazyDerived.new3(
        'selected-configs', 
        null, 
        [context.selectionObserver, context.configObserver, contextFarmConfig.configObserver], 
        ([lastSelected, augmentConfigs, farmLayoutConfigs]) => {
            const augmentConfig = augmentConfigs.find(
                ([_id, c]) => c.connectedTo === lastSelected.selected?.id
            );
            const farmLayoutConfig = farmLayoutConfigs.find(
                ([_id, c]) => c.connectedTo === lastSelected.selected?.id
            );

            return {
                instancesByName: lastSelected.instancesByName,
                selectedSubstation: lastSelected.selected,
                augmentConfig: augmentConfig,
                farmLayoutConfig: farmLayoutConfig,
                substations: lastSelected.instancesByName,
            };
        }
    ).withoutEqCheck();

    const lazyConfig = LazyDerived.new3(
        "lazy-augment-config",
        null,
        [lastSelectedItemsLazy, lazyMetrics, transformerPerSiteAreasLazy],
        ([lastSelected, metrics, transformersPerSiteAreas]) => {
            const layoutMetrics = metrics.get(lastSelected.selectedSubstation?.id ?? -1);
            let context: AugmentPanelContext | undefined = undefined;
            if(lastSelected.augmentConfig && layoutMetrics && lastSelected.farmLayoutConfig){
                const [augmentConfigId, config] = lastSelected.augmentConfig;
                const augmentConfig = config.get<AugmentConfig>();
                const farmLayoutConfig = lastSelected.farmLayoutConfig[1].get<FarmLayoutConfig>();
                const selectedAreaIndex = farmLayoutConfig.selected_area.value;
                const subareaId = farmLayoutConfig.site_areas[selectedAreaIndex].id;

                let configWithReconciledSubareas = augmentConfig;
                if(!augmentConfig.site_areas[selectedAreaIndex]){
                    configWithReconciledSubareas = Immer.produce(augmentConfig, draft => {
                        draft.site_areas[selectedAreaIndex] = createDefaultAugmentSubarea(subareaId.value, draft);
                    })
                }
                const settings = configWithReconciledSubareas.site_areas[selectedAreaIndex].settings;
                if(settings){
                    context = {
                        id: augmentConfigId,
                        connectedTo: config.connectedTo,
                        config: configWithReconciledSubareas,
                        settings: settings,
                        selectedSubstationName: lastSelected.selectedSubstation!.name,
                        substations: lastSelected.instancesByName,
                        metrics: layoutMetrics,
                        selectedAreaIndex: selectedAreaIndex,
                        farmConfigId: lastSelected.farmLayoutConfig[0],
                        farmConfig: farmLayoutConfig,
                        transformersIntoSiteArea: transformersPerSiteAreas[selectedAreaIndex] ?? [],
                    };
                }
            }

            return context;
        }
    );
    return lazyConfig;
}

type SetFn<T> = (fn: (prev: T) => T) => void

type PuiCallback = (builder: PUI_Builder) => void

function createRoadsUi(bim: Bim, logger: ScopedLogger, lazyContext: LazyDerived<AugmentPanelContext | undefined>, pos: Vector2, setSettings: SetFn<AugmentSettings>): ContextMenuConfig{
    const identity = 'farm-layout-rotation-ui';
    const puiLazy = LazyDerived.new1<
        PUI_GroupNode,
        AugmentPanelContext | undefined
    >(identity, null, [lazyContext], ([context]) => {
        const builder = new PUI_Builder({});
        if(context){
            const roadsProp = context.settings.equipment_roads.selected_roads;
            if(context.settings.electrical.generate_new_blocks.value){
                builder.addSceneInstancesSelectorProp({
                    name: "Selected roads",
                    typeSortKeyOverride: 1,
                    value: roadsProp.value.map(v => ({value: v})),
                    onChange: (v) => {
                        setSettings(prev => 
                            Immer.produce(prev, draft => {
                                draft.equipment_roads.selected_roads = draft.equipment_roads.selected_roads.withDifferentValue(v.map(v => v.value));
                        }));
                    },
                    customSelectedItemsMessage: (selected, allItems) => {
                        if(allItems.length === 0){
                            return 'No roads'
                        } else if(selected.length === allItems.length || selected.length === 0){
                            return "All roads selected";
                        } else if(selected.length === 1) {
                            const instance = bim.instances.peekById(selected[0]);
                            const name = SceneInstances.uiNameFor(selected[0], instance!);
                            return name;
                        }else {
                            return `${selected.length} roads selected`;
                        }
                    },
                    types: roadsProp.types,
                }); 
            } else {
                builder.addStringProp({
                    name: 'Selected roads',
                    value: 'Ignored',
                    onChange: () => {},
                    readonly: true,
                    typeSortKeyOverride: 1,
                });
            }
            
        }
        const puiRoot = builder.finish();
        return puiRoot;
    }).withoutEqCheck();

    return new ContextMenuConfig({
        identity, 
        viewSource: new PUI_Lazy(puiLazy), 
        action: { name: 'Ok'}, 
        positionPx: pos, 
        header: "Rotation",
    });
}
interface OffsetUiPanelFlags {
    disableInverterOffset?: boolean;
}

function createOffsetsUi(
    bim: Bim,
    logger: ScopedLogger,
    lazyConfig: LazyVersioned<AugmentPanelContext | undefined>,
    pos: Vector2,
    flags?: OffsetUiPanelFlags,
): ContextMenuConfig {
    const identity = 'augment-offsets-ui';
    const puiLazy = LazyDerived.new1<
        PUI_GroupNode,
        AugmentPanelContext | undefined
    >(identity, null, [lazyConfig], ([context]) => {
        const builder = new PUI_Builder({});
        if(context){
            let sortKey = 0;

            const addProp = (path: PropertyPath, name?: string, readonly?: boolean)=> {
                addProperty({
                    logger,
                    config: context.settings,
                    patch: (props) => {
                        const updated = Immer.produce(context.config, draft => {
                            draft.site_areas[context.selectedAreaIndex].settings = props;
                        });
                        patchConfig(bim, context.id, updated);
                    }, 
                    builder,
                    path,
                    sortKey: ++sortKey,
                    name,
                    readonly,
                }); 
            };

            const mainPath = ['offsets'];
            addProp([...mainPath, "transformer_offset"], undefined, !context.settings.electrical.generate_new_blocks.value);
            addProp([...mainPath, "inverter_offset"], undefined, flags?.disableInverterOffset);
            addProp([...mainPath, "combiner_box_offset"]);
        }

        const puiRoot = builder.finish();
        return puiRoot;
    }).withoutEqCheck();

    return new ContextMenuConfig({
        identity, 
        viewSource: new PUI_Lazy(puiLazy), 
        action: { name: 'Ok' },
        positionPx: pos,
        header: "Offsets",
        widthPx: 460,
    });
}

function patchConfig(bim: Bim, id: IdConfig, properties: AugmentConfig) {
    bim.configs.applyPatchTo({ properties }, [id]);
}

function createBlockSettingsUi(
    bim: Bim, 
    catalog: Catalog,
    logger: ScopedLogger,
    contextLazy: LazyDerived<AugmentPanelContext | undefined>,
    lazyMetrics: LazyDerived<Map<IdBimScene, LayoutProperties>>, 
    pos: Vector2,
    ui: UiBindings,
    notificationGroup: GroupedNotificationGenerator,
): ContextMenuConfig {
    const identity = 'farm-layout-block-settings-ui';

    const setSettings: SetFn<AugmentSettings> = (fn) => {
        const context = contextLazy.poll();
        if(!context){
            logger.error('undefined context');
            return;
        }
        const newSettings = fn(context.settings);
        const newProps = Immer.produce(context.config, (clone) => {
            clone.site_areas[context.selectedAreaIndex].settings = newSettings;
        });
        patchConfig(bim, context.id, newProps);
    }

    const electricalPuiLazy = createElectricalUi(
        bim,
        ui,
        catalog,
        logger,
        contextLazy,
        (props) => {
            setSettings(_prev => props);
        },
        notificationGroup
    );

    const blockPuiLazy = createBlocksPui({
        contextLazy,
        logger, 
        catalog,
        onChange: (idx, key, newValue) => {
            setSettings(prev => Immer.produce(
                prev,
                clone => {
                    clone.electrical.blocks_equipment[idx][key] = newValue;
                    return clone;
                }
            ));
        },
        addNew: (block) => {
            setSettings(prev => Immer.produce(
                prev,
                clone => {
                    clone.electrical.blocks_equipment.push(block);
                    return clone;
                }
            ));
        },
        remove: (idx) => {
            setSettings(prev => Immer.produce(
                prev,
                clone => {
                    clone.electrical.blocks_equipment.splice(idx, 1);
                    const blocks = clone.electrical.blocks_equipment;
                    if(blocks.length === 1) {
                        const block = blocks[0];
                        clone.capacity.ilr_range = clone.capacity.ilr_range.withDifferentValue(block.ilr_range.value);
                    } else if(blocks.length > 1) {
                        const min = IterUtils.min(blocks.map(b => b.ilr_range.value[0])) ?? clone.capacity.ilr_range.value[0];
                        const max = IterUtils.max(blocks.map(b => b.ilr_range.value[1])) ?? clone.capacity.ilr_range.value[1];
                        clone.capacity.ilr_range = clone.capacity.ilr_range.withDifferentValue([
                            Math.min(min, clone.capacity.ilr_range.value[0]),
                            Math.max(max, clone.capacity.ilr_range.value[1])
                        ]);
                    }
                    return clone;
                }
            ));
        },
        createIlrContext: (block, index) => {
            const metrics = lazyMetrics.poll();
            const context = contextLazy.poll();
            const selectedSubArea = context?.selectedAreaIndex;
            const layoutMetricsByArea = metrics.get(context?.connectedTo ?? 0);
            const layoutMetrics = selectedSubArea != undefined ? layoutMetricsByArea?.perZone[selectedSubArea] : undefined;
            const equipment = createILRContextViewUi(catalog, layoutMetrics, block, index);
            return equipment;
        }, 
        updateConfig: (value) => {
            setSettings((_prev) => value);
        },
    });
   

    const puiLazy = LazyDerived.new2(
        "blocks-settings-pui", 
        null, 
        [electricalPuiLazy, blockPuiLazy], 
        ([electricalPui, blocksPui]) => {
        const builder = new PUI_Builder({});
        for (const child of electricalPui.children.values()) {
            builder.addChild(child);
        }
        for (const child of blocksPui.children.values()) {
            builder.addChild(child);
        }
        return builder.finish();
    }).withoutEqCheck();


    return new ContextMenuConfig({
        identity, 
        viewSource: new PUI_Lazy(puiLazy), 
        action: { name: 'Ok' }, 
        positionPx: pos,
        header: 'Blocks equipment',
        widthPx: 500,
    });
}

function createElectricalUi(
    bim: Bim,
    ui: UiBindings,
    catalog: Catalog,
    logger: ScopedLogger, 
    lazyContext: LazyVersioned<AugmentPanelContext | undefined>, 
    patch: (props: AugmentSettings) => void,
    notificationGroup: GroupedNotificationGenerator,
): LazyDerived<PUI_GroupNode>{
    const identity = 'Augment-electrical-ui';
    const puiLazy = LazyDerived.new1<
        PUI_GroupNode,
        AugmentPanelContext|undefined
    >(
        identity, 
        null, 
        [lazyContext],
        ([context]) => {
        const builder = new PUI_Builder({});
        if(context) {
            
            let sortKey = 0;
            const addProp = (path: PropertyPath, name?: string, readonly?: boolean) => {
                addProperty({
                    logger, config: context.settings, patch,
                    builder, path, sortKey: ++sortKey, name, readonly
                });
            };
            const addError = (name: string, msg: string) => {
                builder.addCustomProp({
                    name: name,
                    value: msg,
                    context: {},
                    onChange: () => {},
                    type_ident: 'error_message',
                    typeSortKeyOverride: ++sortKey,
                });
            }
            const mainPath = ['electrical'];

            addProp([...mainPath, "scheme"]);
            addProp([...mainPath, "nec_multiplier"]);

            addCombinerBoxProperty({
                builder,
                prop: context.settings.electrical.combiner_box,
                catalog,
                scheme: context.settings.electrical.scheme.value,
                sortKey: ++sortKey,
                addError: addError,
                patch: (newProp) => {
                    patchObject(context.settings, [...mainPath, "combiner_box"], newProp, patch);
                }
            });
        }

        const puiRoot = builder.finish();
        return puiRoot;
    }).withoutEqCheck();

    return puiLazy;
}

function createSelectModeUi(
    bim: Bim, 
    catalog: Catalog,
    ui: UiBindings,
    notificationGroup: GroupedNotificationGenerator,
    logger: ScopedLogger, 
    lazyContext: LazyVersioned<AugmentPanelContext | undefined>, 
    pos: Vector2,
    patch: (props: AugmentConfig) => void,
):ContextMenuConfig{
    const identity = 'augment-select-mode-ui';
    const puiLazy = LazyDerived.new1(
        identity, 
        null, 
        [lazyContext], 
        ([context]) => {
        const puiRoot = new PUI_GroupNode({name: "", sortChildren: false});
        if(context){
            puiRoot.addMaybeChild(new PUI_CustomPropertyNode({
                name: 'Generate new blocks',
                value: new SelectAugmentationModeProperty(
                    context.settings,
                    (settings) => {
                        const updated = Immer.produce(context.config, draft => {
                            draft.site_areas[context.selectedAreaIndex].settings = settings;
                        });
                        patch(updated);
                    },
                    () => autoFill(bim, catalog, ui, context, notificationGroup),
                ),
                type_ident: "gen_new_blocks_props",
                onChange: () => {},
                context: null,
            }));
        }

        return puiRoot;
    }).withoutEqCheck();

    return new ContextMenuConfig({
        identity, 
        viewSource: new PUI_Lazy(puiLazy), 
        action: { name: 'Ok' },
        positionPx: pos, 
        header: "Layout augmentation target",
        widthPx: 430,
        maxHeightPx: 300,
    });
}

function autoFill(bim: Bim, catalog: Catalog, ui: UiBindings, context: AugmentPanelContext, notificationGroup: GroupedNotificationGenerator): BlockEquipment[] {
    const blocks = Augmentation.createSiteAreaSettingsFromScene(context.transformersIntoSiteArea, bim, catalog, ui, notificationGroup);
    if(blocks.some(b => !b.transformer)){
        console.error('transformer not found', blocks)
        throw new Error('transformer not found');
    }

    const mockContext = {
        notFoundCatalogItems: new Set<number>(),
        defaultAssets: getDefaultAssets(catalog),
    };

    let blocksEquipment = blocks.slice();
    setDefaultAssets(
        {
            blocks_equipment: blocks,
        },
        catalog,
        new ScopedLogger('set-default-assets'),
        mockContext,
        [],
        (val) => {
            blocksEquipment = val.blocks_equipment;
        }
    );

    return blocksEquipment;
}

function createBlocksPui({
    contextLazy,
    logger,
    catalog,
    onChange,
    addNew,
    remove,
    createIlrContext,
    updateConfig,
} : {
    contextLazy: LazyVersioned<AugmentPanelContext | undefined>;
    logger: ScopedLogger;
    catalog: Catalog;
    onChange: (id: number, filed: keyof BlockEquipment, newValue: PropertyBase) => void;
    addNew: (block: BlockEquipment) => void;
    remove: (idx: number) => void;
    createIlrContext: (
        block: BlockEquipment,
        index: number
    ) => [InvertersGroup[] | undefined, BlockProperties[] | undefined];
    updateConfig: (settings: AugmentSettings) => void;
}): LazyVersioned<PUI_GroupNode> {


    const puiLazy = LazyDerived.new1(
        "",
        [],
        [contextLazy],
        ([context]) => {
            const builder = new PUI_Builder({});

            if (!context) {
                return builder.finish();
            }
            const scheme: DC_CNSTS.PatternName =
            (context.settings.electrical.scheme.value as DC_CNSTS.PatternName) ?? "CI_HorTrunkbus";

            let sorkey: number = 1000;
            function getNextSortKey() {
                return ++sorkey;
            }
            const blocks = context.settings.electrical.blocks_equipment;

            const mainPath: string[] = [];

            const genNewBlocks = context.settings.electrical.generate_new_blocks.value;
            const addProp = (
                path: PropertyPath,
                name?: string,
                readonly?: boolean,
                notActive?: boolean
            ) => {
                if (context) {
                    addProperty({
                        logger,
                        config: context.settings,
                        patch: (props) => {
                            updateConfig(props);
                        },
                        builder,
                        path,
                        sortKey: getNextSortKey(),
                        name,
                        readonly,
                        notActive,
                    });
                } else {
                    logger.error("undefined config");
                }
            };

            addBlocks({
                blocks,
                logger,
                builder,
                getNextSortKey,
                remove,
                flags: {
                    autofill: !genNewBlocks,
                    autoblocking: genNewBlocks,
                    disableElectricalBlockRemoval: !genNewBlocks,
                    disableNewBlockEquipmentCreation: !genNewBlocks,
                },
                addProp,
                mainPath: [...mainPath, "electrical"],
                createIlrContext,
                onChange,
                scheme,
                onChangeILRProp: (v, blockIndex) => {         
                    const updatedConfig = Immer.produce(
                        context.settings,
                        (draft) => {
                            const block = blocks[blockIndex];
                            const main_ilr = draft.capacity.ilr_range;
                            if (blocks.length === 1) {
                                draft.capacity.ilr_range = main_ilr.withDifferentValue(v);
                            } else {
                                const [min, max] = main_ilr.value;
                                draft.capacity.ilr_range =
                                    NumberRangeProperty.new({
                                        ...main_ilr,
                                        value: [
                                            Math.min(min, v[0]),
                                            Math.max(max, v[1]),
                                        ],
                                    });
                            }
                            const updatedProp = NumberRangeProperty.new({
                                ...block.ilr_range,
                                value: v,
                            });
                            draft.electrical.blocks_equipment[blockIndex].ilr_range = updatedProp;
                        }
                    );
                    updateConfig(updatedConfig);
                },
                catalog,
                addNewBlock: () => {
                    addNewAugmentBlock(context.settings, addNew);
                }
            });
            const root = builder.finish();
            return root;
        }
    ).withoutEqCheck();

    return puiLazy;
}

function setDefaultProperties(
    id: IdConfig, 
    settings: AugmentSettings, 
    logger: ScopedLogger, 
    context: AugmentPanelContext, 
    bim: Bim, 
    catalog: Catalog, 
    ui: UiBindings,
    skipPaths: PropertyPathType[][],
    notificationGroup: GroupedNotificationGenerator,
    ){
    let updatedSettings = settings;
    const defaultAssets = getDefaultAssets(catalog);
    if(updatedSettings.electrical.generate_new_blocks.value && updatedSettings.electrical.blocks_equipment.length === 0){
        addNewAugmentBlock(updatedSettings, (block) => {
            updatedSettings = Immer.produce(updatedSettings, draft => {
                draft.electrical.blocks_equipment = [block];
            });
        });
    } else if(!updatedSettings.electrical.generate_new_blocks.value){
        const blocks = autoFill(bim, catalog, ui, context, notificationGroup);
        const updatedBlocks = updatedSettings.electrical.blocks_equipment
            .flatMap(b => (b as BlockEquipmentExtended).matchingTransformers.map(p => p.value));
        const newBlocks = blocks
            .flatMap(b => (b as BlockEquipmentExtended).matchingTransformers.map(p => p.value));

        if(IterUtils.areArraysEqual(updatedBlocks, newBlocks)) {
            updatedSettings = Immer.produce(updatedSettings, draft => {
                for (let i = 0; i < draft.electrical.blocks_equipment.length; i++) {
                    const draftBlock = draft.electrical.blocks_equipment[i];
                    const autoFillBlock = blocks[i];
                    if(autoFillBlock){
                        draft.electrical.blocks_equipment[i] = {
                            ...autoFillBlock,
                            inverter: draftBlock.inverter,
                        };
                    }
                }
            });
        } else {
            updatedSettings = Immer.produce(updatedSettings, draft => {
                draft.electrical.blocks_equipment = blocks;
            });
        }
    } else if(updatedSettings.electrical.generate_new_blocks.value){
        updatedSettings = Immer.produce(updatedSettings, draft => {
            for (const block of draft.electrical.blocks_equipment) {
                block.transformer = new CatalogItemsReferenceProperty({...block.transformer, isReadonly: false});
            }
        });
    }

    setDefaultAssets(updatedSettings, catalog, logger, {defaultAssets, notFoundCatalogItems: new Set() }, skipPaths, (newConfig) => {
        updatedSettings = newConfig;
    });
    
    removeDeletedSceneInstances(updatedSettings, bim, logger, skipPaths, (newConfig) => {
        updatedSettings = newConfig;
    });

    if(!ObjectUtils.areObjectsEqual(settings, updatedSettings)){
        const updatedConfig = Immer.produce(context.config, draft => {
            draft.site_areas[context.selectedAreaIndex].settings = updatedSettings;
        });
        patchConfig(bim, id, updatedConfig);
    }
}

export class SelectAugmentationModeProperty {
    constructor(
        public settings: AugmentSettings,
        public patch: (settings: AugmentSettings) => void,
        public autoFill: () => BlockEquipment[]
        ) {}
}

function addNewAugmentBlock(settings: AugmentSettings, addNew: (block: BlockEquipment) => void): void {
    addNewBlockEquipment(settings.electrical.blocks_equipment, undefined, (block) => {
        const augmentBlock: BlockEquipment = {
            ...block,
            ilr_range: NumberRangeProperty.new({
                value: settings.capacity.ilr_range.value,
                unit: "",
                step: 0.01,
                range: [0, 100],
            }),            
            number_of_inverters: NumberPropertyWithOptions.new({
                ...block.number_of_inverters,
                selectedOption: 'auto',
                options: ['auto'],
                isReadonly: true,
            }),
            inverters_per_block: NumberPropertyWithOptions.new({
                ...block.inverters_per_block,
                selectedOption: 'auto',
                options: ['auto'],
                isReadonly: true,
            }),
            blocks_dc: NumberRangeProperty.new({value: [3200, 4200], unit: 'kW', range: [0, Number.MAX_SAFE_INTEGER], step: 1,}),
        };
        addNew(augmentBlock);
    });
}

function makeHash(props: SubareaConfig) {
    return makeHashFromSettings(props);
}