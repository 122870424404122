import { TerrainInstanceTypeIdent, type Bim, BimProperty, type IdBimScene, NumberProperty, type SceneInstance, SceneObjDiff, StringProperty} from "src";
import type { LazyVersioned} from "engine-utils-ts";
import { IterUtils, LazyDerivedAsync, Success, Yield, preferPreviousOverInProgressWrapper, replaceCurrencyUnitWithSymbol } from "engine-utils-ts";
import { Immer } from 'engine-utils-ts';
import { sumValueUnitLike } from "src/UnitsMapper";
import type { NamedBimPropertiesGroup } from "src/bimDescriptions/NamedBimPropertiesGroup";
import type { CostComponents, CostComponentsNonNullable, CostsConfigProvider, EstimateCost, SingleEstimateProvider} from "src/cost-model/capital";
import { CostHierarchy, CostSourceType, createEmptyCostComponents, createEmptyCostComponentsNonNullable, createMiscCategory, fillModelBasedCostCategory, fillModelBasedTopLevelCategory, multiplyCosts } from "src/cost-model/capital";
import { TerrainCutPricingEstimateId, TerrainFillPricingEstimateId } from "src/terrain/Terrain";

export function create_Civil_Earchwork(
    bim: Bim,
    costs: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>,
) {
    const instances = bim.instances.getLazyListOf({ type_identifier: TerrainInstanceTypeIdent, relevantUpdateFlags: SceneObjDiff.NewProps | SceneObjDiff.LegacyProps })
    const earthData = getAggregateGradingData(instances);

    const result = LazyDerivedAsync.new3<
        CostHierarchy,
        GradingsAggregatedData,
        EstimateCost[],
        NumberProperty
    >(
        'earthwork',
        [bim.unitsMapper],
        [earthData, costs.allEstimateCosts, totalDC],
        function* ([earthData, estimates, totalDC]) {

            const quantityUnit = bim.unitsMapper.mapToConfigured({ value: 0, unit: 'm3' }).unit!;
            const costUnit = bim.unitsMapper.mapToConfigured({ value: 0, unit: 'usd' }).unit!;
            const quantityUnitInSquareMeters = NumberProperty.new({ value: 1, unit: quantityUnit }).as('m3');

            // extract final result
            const hierarchy = new CostHierarchy()

            // create cut category
            {
                const estimateId = TerrainCutPricingEstimateId;
                const emptyCosts: EstimateCost = {
                    id: StringProperty.new({ value: estimateId }),
                    costSource: StringProperty.new({ value: CostSourceType.GroupSum }),
                    costs: createEmptyCostComponents(),
                }
                const overrides = multiplyCosts(
                    estimates.find(x => x.id.value === estimateId)?.costs ?? emptyCosts.costs,
                    quantityUnitInSquareMeters
                );
                const defaults = multiplyCosts(getGradingDefaultCostsPerQubicMeter(), quantityUnitInSquareMeters);

                const quantityNum = earthData.totalCut.as(quantityUnit);
                const category = hierarchy.add({
                    description: { value: 'Cut' },
                    costUnit: {
                        options: [replaceCurrencyUnitWithSymbol(costUnit) + '/' + quantityUnit],
                        index: 0,
                    },
                    quantity: {
                        value: NumberProperty.new({ value: quantityNum, unit: quantityUnit }),
                    },
                    relatedSceneInstanceIds: earthData.ids,
                })

                const updateCosts = (newCosts: CostComponents) => {
                    costs.findAndUpdateEstimateCost(estimateId, (prevDCCosts) => Immer.produce(
                        prevDCCosts ?? emptyCosts,
                        draft => { draft.costs = multiplyCosts(newCosts, 1 / quantityUnitInSquareMeters); }
                    ))
                }
                fillModelBasedCostCategory(category[1], overrides, defaults, updateCosts, quantityNum);
            }

            // create fill category
            {
                const estimateId = TerrainFillPricingEstimateId;
                const emptyCosts: EstimateCost = {
                    id: StringProperty.new({ value: estimateId }),
                    costSource: StringProperty.new({ value: CostSourceType.GroupSum }),
                    costs: createEmptyCostComponents(),
                }
                const overrides = multiplyCosts(
                    estimates.find(x => x.id.value === estimateId)?.costs ?? emptyCosts.costs,
                    quantityUnitInSquareMeters
                );
                const defaults = multiplyCosts(getGradingDefaultCostsPerQubicMeter(), quantityUnitInSquareMeters);

                const quantityNum = earthData.totalFill.as(quantityUnit);
                const category = hierarchy.add({
                    description: { value: 'Fill' },
                    costUnit: {
                        options: [replaceCurrencyUnitWithSymbol(costUnit) + '/' + quantityUnit],
                        index: 0,
                    },
                    quantity: {
                        value: NumberProperty.new({ value: quantityNum, unit: quantityUnit }),
                    }
                })

                const updateCosts = (newCosts: CostComponents) => {
                    costs.findAndUpdateEstimateCost(estimateId, (prevDCCosts) => Immer.produce(
                        prevDCCosts ?? emptyCosts,
                        draft => { draft.costs = multiplyCosts(newCosts, 1 / quantityUnitInSquareMeters); }
                    ))
                }
                fillModelBasedCostCategory(category[1], overrides, defaults, updateCosts, quantityNum);
            }

            // create grading root
            {
                const category = hierarchy.addRoot({ description: { value: 'Gradings' } });
                hierarchy.sumupChildren(category[0]);
                category[1].matchesSceneInstance = (query) => {
                    return query.type_identifier === TerrainInstanceTypeIdent
                }
            }

            //{
            //    const costId = 'top-soil-benchmark';
            //    const overrides = estimates.find(x => x.id.value === costId);
            //    const state: SingleEstimateProvider = {
            //        id: costId,
            //        defaults: createEmptyCostComponentsNonNullable(),
            //        value: overrides,
            //        update: (fn) => costs.findAndUpdateEstimateCost(costId, fn),
            //    }
            //    hierarchy.add(createMiscCategory(state, bim.unitsMapper, totalDC, { name: 'Top Soil' }))
            //}

            {
                const costId = 'earthwork-misc';
                const overrides = estimates.find(x => x.id.value === costId);
                const state: SingleEstimateProvider = {
                    id: costId,
                    defaults: createEmptyCostComponentsNonNullable(),
                    value: overrides,
                    update: (fn) => costs.findAndUpdateEstimateCost(costId, fn),
                }
                hierarchy.add(createMiscCategory(state, bim.unitsMapper, totalDC))
            }

            // create roads root
            {
                const root = hierarchy.addRoot({ description: { value: EarchworkCategoryName } })
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.046 })
                fillModelBasedTopLevelCategory(
                    root[0],
                    hierarchy,
                    'earthwork-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
            }


            return hierarchy;
        }
    )
    return result;
}

export const EarchworkCategoryName = 'Earthwork';


const TerrainGradingProps = {
    cutVolume: BimProperty.NewShared({
        path: ['metrics', 'cut_volume'],
        unit: 'yd3',
        value: 0,
    }),
    fillVolume: BimProperty.NewShared({
        path: ['metrics', 'fill_volume'],
        unit: 'yd3',
        value: 0,
    }),
} satisfies NamedBimPropertiesGroup

function getAggregateGradingData(instances: LazyVersioned<Array<[id: IdBimScene, si: SceneInstance]>>) {
    const result = preferPreviousOverInProgressWrapper(LazyDerivedAsync.new1(
        'earthwork-grouping',
        [],
        [instances],
        function* ([instances]) {
            const cuts: BimProperty[] = [];
            const fills: BimProperty[] = []
            const allIds = new Set<IdBimScene>();
            for (const chunks of IterUtils.splitIterIntoChunks(instances, 25_000)) {
                yield Yield.Asap;
                for (const [id, si] of chunks) {
                    const props = si.properties.extractPropertiesGroup(TerrainGradingProps)
                    allIds.add(id);
                    cuts.push(props.cutVolume);
                    fills.push(props.fillVolume);
                }
            }
            const result: GradingsAggregatedData = {
                totalCut: NumberProperty.new({ value: 0, unit: 'yd3' }),
                totalFill: NumberProperty.new({ value: 0, unit: 'yd3' }),
                ids: allIds,
            }
            const totalCut = sumValueUnitLike(cuts)
            const totalFill = sumValueUnitLike(fills)
            if (totalCut instanceof Success) {
                result.totalCut = NumberProperty.new(totalCut.value);
            }
            if (totalFill instanceof Success) {
                result.totalFill = NumberProperty.new(totalFill.value);
            }
            return result;
        }
    ));
    return result;
}

interface GradingsAggregatedData {
    totalCut: NumberProperty
    totalFill: NumberProperty
    ids: Set<IdBimScene>
}


export function getGradingDefaultCostsPerQubicMeter(): CostComponentsNonNullable {
    const result: CostComponentsNonNullable = {
        ...createEmptyCostComponentsNonNullable(),
        serviceCost: NumberProperty.new({ value: 5*1.30795, unit: 'usd' }),
    };

    return result;
}
