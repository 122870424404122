import { Bim, BimSceneOrigin, convertBimAssetToBimPatch } from 'bim-ts';
import type { Yield } from 'engine-utils-ts';
import type { FileImporter, FileImporterContext, FileToImport } from 'ui-bindings';
import { applyOriginCompensationToPatch, requestOriginCompensationSettings } from './BimAssetsOriginHandling';

export class BimAssetImportSettings {
	offsetByCameraPosition: boolean = true;
}

export class BimAssetsImporter implements FileImporter {

    fileExtensions = ['.bimasset'];

    constructor(
        readonly bim: Bim
    ) {
    }

    importSettings(): BimAssetImportSettings {
        return {
		    offsetByCameraPosition: true,
	    }
    };

    *startImport(context: FileImporterContext, file: FileToImport): Generator<Yield, void> {

        if (!context.network) {
            context.logger.error('network not available');
            return;
        }

        const ids = yield* importBimassetToBimAndRequestSettings(
            new Uint8Array(file.fileArrayBuffer),
            this.bim,
            context,
        )
        this.bim.instances.setSelected(ids);
        yield* context.onFinish(ids);
    }
}


export function* importBimassetToBimAndRequestSettings(
    bimAsset: Uint8Array,
    bim: Bim,
    context: FileImporterContext,
) {
    const resultBimPatch = yield* convertBimAssetToBimPatch(bimAsset, bim, context);

    const sceneOrigin = bim.instances.getSceneOrigin();
    const assetOrigin = new BimSceneOrigin(resultBimPatch.sceneOrigin.cartesianCoordsOrigin, resultBimPatch.sceneOrigin.wgsProjectionOrigin)
    resultBimPatch.sceneOrigin = assetOrigin;
    
    const compSettings = yield* requestOriginCompensationSettings({
        context,
        assetOrigin,
        sceneOrigin,        
    });
    
    applyOriginCompensationToPatch({
        bimPatch: resultBimPatch,
        bim,
        compensateOriginsDifference: compSettings.compensateOriginsDifferenceOnImport,
        replaseProjectOrigin: compSettings.replaceProjectCoordinateSystem,
        sendNotification: context.sendNotification
    });

    const allocatedIds = resultBimPatch.applyTo(bim);

    return allocatedIds;
}