<script lang="ts">
    import type { GridApi } from 'ag-grid-enterprise';
    import type { ProjectNetworkClient } from 'engine-utils-ts';
    import { getContext, onMount } from 'svelte';

    import { ButtonComponent, Button, ButtonType} from "../../libui/button";
    import ListItems from './TemplatesListComponent.svelte';
    import { TemplateDescriptionVersion, type TemplateDescription, migrateTemplateDescription } from '../Models';
    import type { TemplateGetReply} from '../TemplatesStorageApi';
    import { TemplatesStorageApi } from '../TemplatesStorageApi';
    import TemplateConfig from './SaveStateComponent.svelte';

    export let label: string = "";
    export let gridApi: GridApi|undefined = undefined;
    let network = getContext<ProjectNetworkClient>('network');
    let templateApi = new TemplatesStorageApi(network);

    let templatesInput: TemplateGetReply = {
        company: [],
        global: [],
    };
    let hidden:boolean = true;
    let addMode:boolean = true;
    let isEditFromPanel:boolean = false;
    let newTemplateLabel:string = "";
    let editTemplateDescr:TemplateDescription | undefined;

    let isValidInput: boolean =false;
    let selectedCompanyId: number | undefined = undefined;
    let selectedGlobalId: number | undefined = undefined;
    let selectedIdGlobal: number | undefined = undefined;
    $: selectedIdGlobal = selectedCompanyId || selectedIdGlobal;

    onMount(async () => {
        templatesInput = await templateApi.getAllTemplates();
    });

    function close(e?: Event) {
        hidden = true;
        e?.preventDefault();
        e?.stopPropagation()
    }

    function closeEdit(e?: Event) {
        addMode = true;
        if(isEditFromPanel){
            hidden = true;
            isEditFromPanel = false;
        }
        e?.preventDefault();
        e?.stopPropagation()
    }

    function getNewId(){
        return Date.now();
    }

    function createTemplateFromTable() {
        if(gridApi){
            const newTemplate: TemplateDescription = migrateTemplateDescription({
                state: gridApi.getColumnState(),
                filter: gridApi.getFilterModel(),
                id: getNewId(),
                label: '',
                data: {
                    rowGroupCols: gridApi.getRowGroupColumns().map(x => x.getId()),
                    valueCols: gridApi.getValueColumns().map(x => x.getId()),
                    pivotCols: gridApi.getPivotColumns().map(x => x.getId()),
                    pivotMode: gridApi.isPivotMode(),
                    filterModel: gridApi.getFilterModel(),
                },
                version: TemplateDescriptionVersion.RenameCircuitEnergyToCircuitAggregatedCapacity,
            })
            if (newTemplateLabel) {
                newTemplate.label = newTemplateLabel;
                newTemplateLabel = "";
            }
            return newTemplate;
        }

        throw new Error('ag-grid is not defined');
    }
    // @ts-ignore
    window.pullTemplateFromQuantity = createTemplateFromTable;

    async function add(){
        const newTemplate = createTemplateFromTable();
        await templateApi.saveTemplate(newTemplate);
        templatesInput = await templateApi.getAllTemplates();
    }

    async function apply(e?: Event){
        if(editTemplateDescr){
            const updatedTemplate: TemplateDescription={
                ...editTemplateDescr,
                label: newTemplateLabel,
            };
            newTemplateLabel = "";
            if(gridApi&&gridApi) {
                updatedTemplate.state = gridApi.getColumnState();
                updatedTemplate.filter = gridApi.getFilterModel();
                updatedTemplate.data = {
                    rowGroupCols: gridApi.getRowGroupColumns().map(x => x.getId()),
                    valueCols: gridApi.getValueColumns().map(x => x.getId()),
                    pivotCols: gridApi.getPivotColumns().map(x => x.getId()),
                    pivotMode: gridApi.isPivotMode(),
                    filterModel: gridApi.getFilterModel(),
                }
                await templateApi.saveTemplate(updatedTemplate);
            } else {
                console.error('column is undefined');
            }
            templatesInput = await templateApi.getAllTemplates();
        }
        closeEdit(e);
    }

    function editSelection(template: TemplateDescription, isFromPanel: boolean){
        editTemplateDescr = template;
        newTemplateLabel = template.label;
        addMode = !addMode;
        hidden = false;
        isEditFromPanel = isFromPanel;
    }

   async function removeFromSelect(template: TemplateDescription){
        await templateApi.deleteTemplate(template);

        templatesInput = await templateApi.getAllTemplates();
    }

    function addNew(){
        hidden = !hidden;
    }

    function resetState() {
        if(gridApi&&gridApi){
            gridApi.resetColumnState();
            gridApi.setFilterModel(null);
            selectedCompanyId = undefined;
            selectedGlobalId = undefined;
        }
        else{
            console.error('column is undefined');
        }
    }

    // @ts-ignore
    window.setQuantityTemplateState = (t: TemplateDescription) => {
        if(gridApi && gridApi){
            gridApi.setFilterModel(t.filter);
            gridApi.resetColumnState();
            gridApi.applyColumnState({
                state: t.state,
                applyOrder: true,
                defaultState: {
                    hide: true,
                }
            });
        }
    }

    function setState() {
        const template = [
            ...templatesInput.company,
            ...templatesInput.global
        ].find(b => b.id === selectedIdGlobal);
        if(gridApi && gridApi && template){
            gridApi.setFilterModel(template.filter);
            gridApi.resetColumnState();
            gridApi.applyColumnState({
                state: template?.state,
                applyOrder: true,
                defaultState: {
                    hide: true,
                }
            });
        }
        else{
            console.error('column is undefined');
        }
        selectedCompanyId = undefined;
        selectedGlobalId = undefined;
    }

</script>

<style lang="scss">
    .buttons-row{
        display: flex;
        justify-content: space-between;
    }

    .root{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        overflow: auto;
        width: 100%;
        height: 100%;
        &>div {
                width: 240px;
                display: flex;
                justify-content: center;
                flex-direction: column;
            }
        }
</style>


<div>
    <div class="root custom-scrollbar">
        <div>
            {#if hidden}
                {#if templatesInput.global.length}
                    <ListItems
                        label="Generic templates"
                        bind:equipmentInputs={templatesInput.global}
                        maxItemes = {{count:10, alwaysShow: false}}
                        showDeleteAll={false}
                        readonly={true}
                        editSelection = {() => {
                            //readonly
                        }}
                        selectObject={id => {
                            selectedGlobalId = id;
                            selectedCompanyId = undefined;
                        }}
                        bind:selectedId={selectedGlobalId}
                    />
                {/if}
                <br/>
                <ListItems
                    {label}
                    bind:equipmentInputs={templatesInput.company}
                    maxItemes = {{count:15, alwaysShow: false}}
                    action = {addNew}
                    editSelection = {(t)=>{editSelection(t, true);}}
                    removeFromSelect = {(t) => removeFromSelect(t)}
                    showDeleteAll={false}
                    selectObject={id => {
                        selectedCompanyId = id;
                        selectedGlobalId = undefined;
                    }}
                    bind:selectedId={selectedCompanyId}
                />
                <div class="buttons-row">
                    <ButtonComponent desc={new Button("APPLY", ButtonType.Contained, setState, selectedIdGlobal === undefined)} />
                    <ButtonComponent desc={new Button("RESET", ButtonType.Contained, resetState)} />
                </div>
            {:else}
                {#if addMode}
                    <ListItems
                        label="Templates"
                        bind:equipmentInputs={templatesInput.company}
                        maxItemes = {{count:10, alwaysShow: true}}
                        editSelection = {(t)=>{editSelection(t, false);}}
                        {removeFromSelect}
                        showDeleteAll={false}
                    />
                    <TemplateConfig
                        bind:isValid = {isValidInput}
                        bind:label = {newTemplateLabel}
                    />
                    <div class="buttons-row">
                        <ButtonComponent
                            desc={new Button(
                                "ADD",
                                ButtonType.Contained,
                                add,
                                !isValidInput
                            )}
                        />
                        <ButtonComponent
                            desc={new Button(
                                "CLOSE",
                                ButtonType.Contained,
                                close
                            )}
                        />
                    </div>
                {:else}
                    <TemplateConfig
                        bind:isValid = {isValidInput}
                        bind:label = {newTemplateLabel}
                    />
                    <div class="buttons-row">
                        <ButtonComponent
                            desc={new Button(
                                "APPLY",
                                ButtonType.Contained,
                                apply,
                                !isValidInput
                            )}
                        />
                        <ButtonComponent
                            desc={new Button(
                                "CLOSE",
                                ButtonType.Contained,
                                closeEdit
                            )}
                        />
                    </div>
                {/if}
            {/if}
    </div>
    </div>
</div>
