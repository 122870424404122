import type { ConfigsCollection } from "src";
import { StringProperty, BooleanProperty } from "src";
import type { CostComponents, CostComponentsNonNullable, CostsConfigProps, EstimateCost, TableSettings } from "./CostsConfig";
import { CostsConfigIdentifier } from "./CostsConfig";
import type { LazyVersioned} from "engine-utils-ts";
import { LazyDerived, ObjectUtils } from "engine-utils-ts";
import type { NamedBimPropertiesGroup as PropsGroup } from "src/bimDescriptions/NamedBimPropertiesGroup";
import { Immer } from 'engine-utils-ts';
import { InstanceCostFromPersistent, InstanceCostToPersistent, NamedBimPropsGroupToPrimitiveProps, createEmptyCostComponents } from ".";

export class CostsConfigProvider {
    private readonly configs: ConfigsCollection;

    readonly costsConfigProps: LazyVersioned<CostsConfigProps>
    readonly allTableSettings: LazyVersioned<TableSettings[]>
    readonly allEstimateCosts: LazyVersioned<EstimateCost[]>
    readonly allInstanceCosts: LazyVersioned<InstanceCost[]>

    constructor(configs: ConfigsCollection) {
        this.configs = configs;
        const costsConfig = this.configs.getLazySingletonOf({
            type_identifier: CostsConfigIdentifier
        })

        this.costsConfigProps = LazyDerived.new1(
            'costsConfigProps',
            [],
            [costsConfig],
            ([costsConfig]) => {
                return costsConfig.get<CostsConfigProps>();
            }
        );

        this.allTableSettings = LazyDerived.new1(
            'tableSettings',
            [],
            [this.costsConfigProps],
            ([props]) => {
                return props.tables;
            }
        );

        this.allEstimateCosts = LazyDerived.new1(
            'tableSettings',
            [],
            [this.costsConfigProps],
            ([props]) => {
                return props.estimates;
            }
        );

        this.allInstanceCosts = LazyDerived.new1(
            'tableSettings',
            [],
            [this.costsConfigProps],
            ([props]) => {
                return props.instances.map(x => InstanceCostFromPersistent(x));
            }
        );

    }

    updateCostsConfig: UpdateCostsConfig = (updater) => {
        this.configs.applyPatchToSingleton(
            CostsConfigIdentifier,
            { properties: updater(this.costsConfigProps.poll()) }
        )
    }

    findAndUpdateTableSettings: FindAndUpdateTableSettings = (id, updater) => {
        this.updateCostsConfig(Immer.produce(draft => {
            const prevIdx = draft.tables.findIndex(x => x.id.value === id);
            const prev: TableSettings = draft.tables[prevIdx] ?? {
                hiddenColumnGroupIds: [],
                id: StringProperty.new({ value: id }),
                isReadonly: BooleanProperty.new({ value: false }),
            }
            const updated = updater(prev);
            if (!updated) {
                // remove
                if (prevIdx >= 0) {
                    draft.tables.splice(prevIdx, 1);
                    return
                }
            } else {
                if (prevIdx >= 0) {
                    // update existing
                    draft.tables[prevIdx] = updated;
                } else {
                    // create new
                    draft.tables.push(updated);
                }
            }
        }))
    }

    makeUpdateTableSettings =
        (id: string): UpdateTableSettings =>
        (updater) => this.findAndUpdateTableSettings(id, updater);

    findAndUpdateEstimateCost: FindAndUpdateEstimateCost = (id, update) => {
        this.updateCostsConfig(Immer.produce(draft => {
            const items = draft.estimates;
            const idx = items.findIndex(x => x.id.value === id);
            const prevItem = idx >= 0 ? items[idx] : undefined;
            const newItem = update(prevItem);
            if (newItem) {
                // updating item
                if (idx < 0) {
                    items.push(newItem);
                } else {
                    items[idx] = newItem;
                }
            } else {
                // removing item
                if (idx >= 0) {
                    items.splice(idx, 1);
                }
            }
        }))
    }

    makeUpdateEstimateCost =
        (id: string): UpdateEstimateCost =>
        (updater) => this.findAndUpdateEstimateCost(id, updater);

    findAndUpdateInstanceCost<T extends PropsGroup>(
        updater: InstanceCostUpdater<T>,
        search: {
            instance_type: string,
            props: T,
            name?: string
        }
    ) {
        const propsPersistent = NamedBimPropsGroupToPrimitiveProps(search.props);
        this.updateCostsConfig(Immer.produce(draft => {
            const idx = draft.instances.findIndex(x =>
                x.instance_type.value === search.instance_type &&
                (!search.name ? true : x.name.value === search.name) &&
                ObjectUtils.areObjectsEqual(propsPersistent, x.props)
            );
            const prevPersistent = idx >= 0 ? draft.instances[idx] : undefined;
            const prev: InstanceCost<T> = prevPersistent
                ? InstanceCostFromPersistent<T>(prevPersistent)
                : { costs: createEmptyCostComponents(), instance_type: search.instance_type, props: search.props, name: search.name ?? '' }
            const newValue = updater(prev);
            const newPersistent = newValue && InstanceCostToPersistent(newValue)
            if (newPersistent) {
                // updating item
                if (idx < 0) {
                    draft.instances.push(newPersistent);
                } else {
                    draft.instances[idx] = newPersistent;
                }
            } else {
                // removing item
                if (idx >= 0) {
                    draft.instances.splice(idx, 1);
                }
            }
        }))
    }

    createLazyEstimateById(id: string) {
        return LazyDerived.new1(
            'estimateById',
            [],
            [this.allEstimateCosts],
            ([costs]) => {
                return costs.find(x => x.id.value === id);
            }
        )
    }

    createLazyEstimates(...ids: string[]) {
        return LazyDerived.new1(
            'estimatesByIds',
            [],
            [this.allEstimateCosts],
            ([costs]) => {
                return costs.find(x => ids.includes(x.id.value));
            }
        )
    }

    lazyInstanceCostsByType(...instance_types: string[]) {
        return LazyDerived.new1(
            'instanceCostsByBypeIdent',
            [],
            [this.allInstanceCosts],
            ([costs]) => {
                return costs.filter(x => instance_types.includes(x.instance_type));
            }
        )
    }

}

export type CostsConfigUpdater = (prev: CostsConfigProps) => CostsConfigProps
export type UpdateCostsConfig = (updater: CostsConfigUpdater) => void

export type TableSettingsUpdater = (prev: TableSettings) => TableSettings | undefined;
export type FindAndUpdateTableSettings = (id: string, updater: TableSettingsUpdater) => void;
export type UpdateTableSettings = (updater: TableSettingsUpdater) => void;


export type EstimateCostUpdater = (prev?: EstimateCost) => EstimateCost | undefined;
export type FindAndUpdateEstimateCost = (id: string, updater: EstimateCostUpdater) => void;
export type UpdateEstimateCost = (updater: EstimateCostUpdater) => void;
export interface SingleEstimateProvider {
    id: string
    value?: EstimateCost
    defaults: CostComponentsNonNullable
    update: UpdateEstimateCost
}


export interface InstanceCost<T extends PropsGroup = PropsGroup> {
    costs: CostComponents
    props: T
    instance_type: string
    name: string
}
export type InstanceCostUpdater<T extends PropsGroup = PropsGroup> = (prev: InstanceCost<T>) => InstanceCost<T> | undefined;
export type FindAndUpdateInstanceCost<T extends PropsGroup = PropsGroup> = (
    instance_type: string,
    props: T,
    updater: InstanceCostUpdater
) => void;
export type UpdateInstanceCost<T extends PropsGroup = PropsGroup> = (updater: InstanceCostUpdater<T>) => void;

export interface InstanceCostsProvider<T extends PropsGroup = PropsGroup> {
    value: LazyVersioned<InstanceCost<PropsGroup>[]>
    update: FindAndUpdateInstanceCost<T>
}
