import type { CostsConfigProvider, ExpandLegacyPropsWithCostTableLinks} from "src/cost-model/capital";
import { createFocusLinkOnSample, mergeCostComponents, sumCostComponents } from "src/cost-model/capital";
import { InstanceCostsGlobal } from "src/archetypes/EquipmentCommon";
import type { TrackerFrameUniqueProps} from "src/cost-model/capital/tables/categories/structural/racking";
import { AnyTrackerFrameUniqueProps, anyTrackerToTrackerFrameProps, createSolarArrayFrameDefaultCostPerEach } from "src/cost-model/capital/tables/categories/structural/racking";
import { createDefaultCostModuleEach, createDefaultCostModulePerWatt } from "src/cost-model/capital/tables/categories/pv-modules/modules-install";
import type { Bim, BimPropertyData, TrackerPile} from "src";
import { AnyTrackerProps, BimProperty, PVModuleTypeIdent, PropertiesCollection, TrackerFrameTypeIdentifier } from "src";
import { convertThrow, Failure, IterUtils, LazyDerived, ObjectUtils } from "engine-utils-ts";
import { SolverObjectInstance } from "src/runtime/SolverObjectInstance";
import type { SolverInstancePatchResult } from "src/runtime/ReactiveSolverBase";
import { extractPilesFromTrackerProps } from "src/piles/TrackerPile";
import { Matrix4 } from "math-ts";
import type { PropertiesGroupFormatters} from "src/bimDescriptions/PropertiesGroupFormatter";
import { extractIntoNamedPropsGroup } from "src/bimDescriptions/PropertiesGroupFormatter";
import type { ModuleUniqueProps} from "src/archetypes/pv-module/PVModule";
import { PVModuleKeyProps } from "src/archetypes/pv-module/PVModule";
import { extractValueUnitPropsGroup } from "src/bimDescriptions/NamedBimPropertiesGroup";
import { TrackerFrameFormatterPropsGroup } from "src/archetypes/TrackerFrame";
import { createTrackerModuleCostModelLink } from "src/trackers/Tracker";
import { PUI_GroupNode } from "ui-bindings";

export function registerAnyTrackerPricingSolver(bim: Bim, costs: CostsConfigProvider) {
    const global = LazyDerived.new1(
        'global',
        [bim.unitsMapper],
        [costs.lazyInstanceCostsByType(`tracker-pile`, TrackerFrameTypeIdentifier, PVModuleTypeIdent)],
        ([costs]) => {
            return new InstanceCostsGlobal(costs, bim.unitsMapper)
        }
    )

    bim.runtimeGlobals.registerByIdent(AnyTrackerPriceSharedArgGlobalIdentifier, global);

    bim.reactiveRuntimes.registerRuntimeSolver(new SolverObjectInstance({
        solverIdentifier: 'any-tracker-pricing',
        objectsDefaultArgs: {
            legacyProps: {
                modulesCount: BimProperty.NewShared({ path: ["circuit", "equipment", "modules_count"], value: 0 }),
            },
            propsInOut: new AnyTrackerProps({}),
        },
        globalArgsSelector: {
            [AnyTrackerPriceSharedArgGlobalIdentifier]: InstanceCostsGlobal
        },
        objectsIdentifier: 'any-tracker',
        cache: true,
        solverFunction: (inputObj, globals): SolverInstancePatchResult => {
            const emptyLegacyProps = new PropertiesCollection();
            if (globals[AnyTrackerPriceSharedArgGlobalIdentifier] instanceof Failure) {
                return {};
            }
            const piles = extractPilesFromTrackerProps(0, false, inputObj.propsInOut, new Matrix4());
            const moduleKeyProps = extractIntoNamedPropsGroup(PVModuleKeyProps, emptyLegacyProps, inputObj.propsInOut);
            const otherTrackerPriceRelated: typeof OtherPriceRelatedTrackerProps = {
                loadWindPosition: OtherPriceRelatedTrackerProps.loadWindPosition.withDifferentValue(inputObj.propsInOut.position.wind_load_position?.value ?? 'none'),
                moduleCount: OtherPriceRelatedTrackerProps.moduleCount.withDifferentValue(inputObj.legacyProps.modulesCount.asNumber()),
            }
            const trackerFrameUniqueProps = anyTrackerToTrackerFrameProps(
                extractIntoNamedPropsGroup(AnyTrackerFrameUniqueProps, emptyLegacyProps, inputObj.propsInOut)
            );

            const trackerFrameFormatterProps: typeof TrackerFrameFormatterPropsGroup = {
                model: TrackerFrameFormatterPropsGroup.model.withDifferentValue(inputObj.propsInOut.tracker_frame.commercial.model.value),
                mountingStandard: TrackerFrameFormatterPropsGroup.mountingStandard.withDifferentValue(''),
                stringCount: TrackerFrameFormatterPropsGroup.stringCount.withDifferentValue(inputObj.propsInOut.tracker_frame.dimensions.strings_count.value),
                stringModulesCount: TrackerFrameFormatterPropsGroup.stringModulesCount.withDifferentValue(inputObj.propsInOut.tracker_frame.string.modules_count_x.value),
            }

            const result = createTrackerPricePatch(
                {
                    piles,
                    moduleKeyProps,
                    otherTrackerPriceRelated,
                    trackerFrameUniqueProps,
                    trackerFrame: trackerFrameFormatterProps,
                },
                globals[AnyTrackerPriceSharedArgGlobalIdentifier].value,
                bim.keyPropertiesGroupFormatter,
            );
            return result;
        }
    }));
}

const OtherPriceRelatedTrackerProps = {
    moduleCount: BimProperty.NewShared({
        path: ['circuit', 'equipment', 'modules_count'],
        value: 0,
    }),
    loadWindPosition: BimProperty.NewShared({
        path: ['position', 'load_wind_position'],
        value: '',
    }),
}

interface TrackerPriceInstanceRelatedArg {
    piles: TrackerPile[],

    trackerFrame: typeof TrackerFrameFormatterPropsGroup,
    trackerFrameUniqueProps: typeof TrackerFrameUniqueProps,
    moduleKeyProps: typeof PVModuleKeyProps,
    otherTrackerPriceRelated: typeof OtherPriceRelatedTrackerProps,
}

function createTrackerPricePatch(
    instanceRelatedArg: TrackerPriceInstanceRelatedArg,
    sharedArg: InstanceCostsGlobal,
    keyPropsFormatter: PropertiesGroupFormatters,
): SolverInstancePatchResult {

    // find matching tracker frame
    let frameTotalCost = 0;
    let frameTitle = 'frame'
    {
        const props = extractValueUnitPropsGroup(instanceRelatedArg.trackerFrameUniqueProps)
        frameTitle = keyPropsFormatter.formatNamedProps(TrackerFrameTypeIdentifier, instanceRelatedArg.trackerFrame) ?? frameTitle;

        const costs = mergeCostComponents(
            sharedArg.costs.find(x =>
                x.instance_type === TrackerFrameTypeIdentifier &&
                ObjectUtils.areObjectsEqual(x.props, props)
            )?.costs,
            createSolarArrayFrameDefaultCostPerEach(props)
        );
        const cost = costs && sumCostComponents(costs) || 0;
        frameTotalCost = cost;
    }

    // modules total cost
    let allModuleTotalCost = 0;
    let singleModuleTotalCost = 0;
    const moduleKeyProps = extractValueUnitPropsGroup(instanceRelatedArg.moduleKeyProps);
    const moduleTitle = keyPropsFormatter.formatNamedProps(PVModuleTypeIdent, moduleKeyProps);
    {
        const uniqueProps: typeof ModuleUniqueProps = {
            manufacturer: moduleKeyProps.manufacturer,
            model: moduleKeyProps.model,
            power: moduleKeyProps.maximum_power,
        }
        const costs = sharedArg.costs.filter(x =>
            x.instance_type === PVModuleTypeIdent &&
            ObjectUtils.areObjectsEqual(x.props, uniqueProps)
        )
        const wattCost = sumCostComponents(mergeCostComponents(costs.find(x => x.name === 'watt')?.costs, createDefaultCostModulePerWatt()));
        const eachCost = sumCostComponents(mergeCostComponents(costs.find(x => x.name === 'each')?.costs, createDefaultCostModuleEach()));
        const modulePowerWatt = uniqueProps.power.as('W');
        const singleCost = modulePowerWatt * wattCost + eachCost
        const count = instanceRelatedArg.otherTrackerPriceRelated.moduleCount.asNumber();
        allModuleTotalCost = count * singleCost;
        singleModuleTotalCost = singleCost;
    }


    // piles
    let pilesTotalCost = 0;
    {
        const allPileCosts = sharedArg.costs.filter(x => x.instance_type === `tracker-pile`);
        for (const pile of instanceRelatedArg.piles) {
            const costAndUnit = pile.calculatePileCost(allPileCosts);
            const cost = convertThrow(costAndUnit.value, costAndUnit.unit, 'usd');
            pilesTotalCost += cost;
        }
    }

    // assembly cost
    const assemblyCostUsd = pilesTotalCost + frameTotalCost + allModuleTotalCost;

    // compose patch
    const patch: BimPropertyData[] = [
        {
                path: ['cost', 'total_cost'],
                value: assemblyCostUsd,
                unit: 'usd',
                readonly: true,
        },
        {
                path: ['cost', 'frame', 'cost_item'],
                value: frameTitle ?? 'unknown_frame',
                description: '',
                readonly: true,
        },
        {
                path: ['cost', 'frame', 'cost'],
                value: frameTotalCost,
                unit: 'usd',
                readonly: true,
        },
        {
                path: ['cost', 'frame', 'total_cost'],
                value: frameTotalCost,
                unit: 'usd',
                readonly: true,
        },
        {
                path: ['cost', 'module', 'cost_item'],
                value: moduleTitle ?? 'unknown_module',
                readonly: true,
                description: '',
        },
        {
                path: ['cost', 'module', 'per_each_cost'],
                value: singleModuleTotalCost,
                unit: 'usd',
                readonly: true,
        },
        {
                path: ['cost', 'module', 'total_cost'],
                value: allModuleTotalCost,
                unit: 'usd',
                readonly: true,
        },
        {
                path: ['cost', 'piles', 'total_cost'],
                value: pilesTotalCost,
                unit: 'usd',
                readonly: true,
        },
    ];

    return {
        legacyProps: patch,
        removeProps: [
            BimProperty.MergedPath(['cost', 'frame', 'position_multiplier']),
            BimProperty.MergedPath(['cost', 'status']),
            BimProperty.MergedPath(['cost', 'has_zero_costs']),
        ],
    }

}

export const AnyTrackerPriceSharedArgGlobalIdentifier = 'any-tracker-pricing-shared-args';

export const expandAnyTrackerLegacyPropsWithCostTableLinks: ExpandLegacyPropsWithCostTableLinks = (params) => {
    const sis = Array.from(params.bim.instances.peekByIds(params.ids).values());
    if (sis.some(x => x.type_identifier !== 'any-tracker')) {
        return;
    }

    createTrackerModuleCostModelLink(params);

    frame: {
        const groupByFrame = Array.from(IterUtils.groupBy(
            sis,
            (o) => {

                const props = o.properties.extractPropertiesGroup(AnyTrackerFrameUniqueProps)
                return [
                    props.manufacturer.asText(),
                    props.model.asText(),
                    props.loadWindPosition.asText(),
                    props.moduleCountX.asNumber(),
                ].join('/');
            },
        ))
        if (groupByFrame.length !== 1) {
            break frame;
        }
        const sample = groupByFrame[0][1][0];

        createFocusLinkOnSample({
            costModelFocusApi: params.costModelFocusApi,
            sample,
            targetPui: PUI_GroupNode.tryGetNestedChild(params.pui, ['cost', 'frame']),
            type_identifier: TrackerFrameTypeIdentifier,
            label: 'Setup frame costs'
        })
    }

    createFocusLinkOnSample({
        costModelFocusApi: params.costModelFocusApi,
        targetPui: PUI_GroupNode.tryGetNestedChild(params.pui, ['cost', 'piles']),
        type_identifier: `tracker-pile`,
        label: 'Setup piles costs'
    })
}
