import { isInNode, IsInWebworker } from '../EnvChecks';
import { ErrorUtils } from '../ErrorUtils';

export class EventStackFrame {
	readonly identifier: string;
	readonly transactionId?: number | string | Symbol;
	readonly browserEvent?: Event | PointerEvent;
	readonly observableUniqueIdent?: string;
	readonly skipObserverWithIdent?: string; // skip sending notification to this listener
	readonly isEventDerived: boolean;
	readonly doesInvalidatePersistedState?: boolean;
	readonly allowPropsShapeHookToResetExistingPropsToDefaultValues?: boolean;

	constructor(
		identifier: string,
		transactionId?: number | string | Symbol,
		browserEvent?: Event,
		observableUniqueIdent?: string,
		skipObserverWithIdent?: string,
		isEventDerived?: boolean,
		doesInvalidatePersistedState?: boolean,
		allowPropsShapeHookToResetExistingPropsToDefaultValues?: boolean,
	) {
		this.identifier = identifier;
		this.transactionId = transactionId;
		this.browserEvent = browserEvent;
		this.observableUniqueIdent = observableUniqueIdent;
		this.skipObserverWithIdent = skipObserverWithIdent;
		this.isEventDerived = isEventDerived ?? false;
		this.doesInvalidatePersistedState = doesInvalidatePersistedState;
		this.allowPropsShapeHookToResetExistingPropsToDefaultValues =
			allowPropsShapeHookToResetExistingPropsToDefaultValues;

		Object.freeze(this);
	}

	combineWithPartial(partial: Partial<EventStackFrame>): EventStackFrame {
		return new EventStackFrame(
			partial.identifier ?? this.identifier,
			partial.transactionId ?? this.transactionId,
			partial.browserEvent ?? this.browserEvent,
			partial.observableUniqueIdent ?? undefined,
			partial.skipObserverWithIdent ?? undefined,
			partial.isEventDerived ?? this.isEventDerived,
			partial.doesInvalidatePersistedState ?? undefined,
			partial.allowPropsShapeHookToResetExistingPropsToDefaultValues
				?? this.allowPropsShapeHookToResetExistingPropsToDefaultValues,
		);
	}
}

export function isCurrentEventEqualTo(e: EventStackFrame) {
	const st = eventsSourcesStack();
	const curr = st[st.length - 1];
	return curr.identifier == e.identifier
		&& curr.transactionId == e.transactionId
		&& curr.browserEvent == e.browserEvent
		&& curr.observableUniqueIdent == e.observableUniqueIdent
		&& curr.skipObserverWithIdent == e.skipObserverWithIdent
		&& curr.allowPropsShapeHookToResetExistingPropsToDefaultValues ==
			e.allowPropsShapeHookToResetExistingPropsToDefaultValues;
}

let eventsSourceStack: Readonly<EventStackFrame>[] = [
	new EventStackFrame('default'),
];
if (!IsInWebworker() && !isInNode()) {
	if ((self as any).eventsSourcesStack) {
		console.error('events source stack double initialialization, fix your bundling, this will not work');
	}
	(self as any).eventsSourcesStack = eventsSourceStack;
}


function eventsSourcesStack(): Readonly<EventStackFrame>[] {
	return eventsSourceStack;
}


export function peekCurrentEventFrame(): Readonly<EventStackFrame> {
	const st = eventsSourcesStack();
	return st[st.length - 1];
}

export function withEventFrame(e: EventStackFrame, f: Function) {
	const pushed = unsafePushToEventStackFrame(e);
	try {
		f();
	} catch (e) {
		throw e;
	} finally {
		unsafePopFromEventStackFrame(pushed);
	}
}

export function withOptionalEventFrame<R>(e: Partial<EventStackFrame> | undefined, f: (e: EventStackFrame) => R): R {
	let pushed: Readonly<EventStackFrame> | null = null;
	if (e) {
		pushed = unsafePushToEventStackFrame(e);
	}
	try {
		return f(peekCurrentEventFrame());
	} finally {
		if (pushed) {
			unsafePopFromEventStackFrame(pushed);
		}
	}
}

export function unsafePushToEventStackFrame(toSet: Readonly<Partial<EventStackFrame>>): Readonly<EventStackFrame> {
	const st = eventsSourcesStack();
	if (!st || st.length == 0) {
		ErrorUtils.logThrow('invalid events stack');
	}
	const prev = st[st.length - 1];
	const toPush = prev.combineWithPartial(toSet);
	st.push(toPush);
	return toPush;
}
export function unsafePopFromEventStackFrame(prevToCheck: Readonly<EventStackFrame>) {
	const last = eventsSourcesStack()[eventsSourcesStack().length - 1];
	if (last != prevToCheck) {
		console.error('events sources stack is erroneus, expected ', prevToCheck.identifier);
	} else {
		eventsSourcesStack().pop();
	}
}

