import { Texture, RepeatWrapping, RGBAFormat, UnsignedByteType, UVMapping, RGBFormat, LinearFilter, LinearMipmapLinearFilter, sRGBEncoding, LinearEncoding } from '../3rdParty/three';
import type { ObjectHandleMethod } from '../utils/Utils';
import type { Disposable} from 'engine-utils-ts';
import { CachingNetworkClient, ProjectNetworkClient } from 'engine-utils-ts';

export class TexturesLoader implements Disposable {

	private loader: CachingNetworkClient;

	readonly _textures: Map<string, Texture | null> = new Map();
	_callback: ((texture: Texture) => void) | undefined = undefined;

	constructor({
		loadPath,
		network,
		onLoadCallback: onLoadCallback,
	}: { loadPath?: string, network?: ProjectNetworkClient, onLoadCallback?: (texture: Texture) => void }) {
		if (network) {
			this.loader = new CachingNetworkClient(network);
		} else if (loadPath) {
			this.loader = new CachingNetworkClient(
				new ProjectNetworkClient({basePath: loadPath}),
			);
		} else {
			throw new Error('load path or network should be provided');
		}
		this._callback = onLoadCallback;
	}

	get(texturePath: string, preloadPixel: Uint8ClampedArray, textureWidth?: number, textureHeight?: number): Texture {
		if (!this._textures.has(texturePath)) {
			const tex = new Texture(
				new ImageData(preloadPixel, 1, 1 ),
				UVMapping,
				RepeatWrapping,
				RepeatWrapping,
				LinearFilter,
				LinearMipmapLinearFilter,
				preloadPixel.length == 4 ? RGBAFormat : RGBFormat,
				UnsignedByteType,
				8,
				texturePath.includes('normal') ? LinearEncoding : sRGBEncoding,
			);
			tex.needsUpdate = true;
			this._textures.set(texturePath, tex);

			loadTexture(
				this.loader,
				texturePath,
				tex,
				this._callback,
				textureWidth,
				textureHeight
			).catch((e) => {
				console.error('error loading texture ', texturePath, e);
				this._textures.set(texturePath, null);
			})
		}
		return this._textures.get(texturePath)!;
	}

	getTexturesWithLoadErrors(): string[] {
		const texturesPathsFailed: string[] = [];
		for (const [path, texture] of this._textures) {
			if (texture === null) {
				texturesPathsFailed.push(path);
			}
		}
		return texturesPathsFailed;
	}

	areTherePendingTextureLoads() {
		for (const texture of this._textures.values()) {
			if (texture && texture.image === undefined) {
				return true;
			}
		}
		return false;
	}

	dispose() {
		for (const texture of this._textures.values()) {
			if (texture) {
				texture.dispose();
			}
		}
		this._textures.clear();
	}

}

async function loadTexture(
	loaderAsync: CachingNetworkClient | Promise<CachingNetworkClient>,
	url: string,
	texture: Texture,
	textureLoadedCallback?: ObjectHandleMethod<Texture>,
	textureWidth?: number,
	textureHeight?: number,
): Promise<void> {
				
	const loader = await loaderAsync;
	const r = await loader.get(url);
	const b = await r.blob();
	const urlObj = URL.createObjectURL(b);
	const image = new Image(textureWidth, textureHeight);
	image.src = urlObj;
	image.onload = () => {
		texture.image = image as any;
		texture.needsUpdate = true;
		if (textureLoadedCallback) {
			textureLoadedCallback(texture);
		}
		URL.revokeObjectURL(urlObj);
	};
}
