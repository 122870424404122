<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M7.9962 9.35363C7.6229 9.35363 7.30303 9.22137 7.0366 8.95683C6.77017 8.69231 6.63696 8.37432 6.63696 8.00285C6.63696 7.63138 6.76935 7.31244 7.03413 7.04602C7.2989 6.77958 7.61719 6.64637 7.989 6.64637C8.3634 6.64637 8.68444 6.77748 8.95211 7.0397C9.2198 7.30192 9.35365 7.62149 9.35365 7.9984C9.35365 8.37531 9.22073 8.69541 8.9549 8.9587C8.68906 9.22199 8.3695 9.35363 7.9962 9.35363ZM7.9887 14.2355C6.26323 14.2355 4.79233 13.6277 3.576 12.4119C2.35965 11.1962 1.75582 9.72001 1.7645 7.98333H3.07538C3.08261 9.34899 3.56279 10.5124 4.51591 11.4735C5.46904 12.4346 6.62726 12.9152 7.9906 12.9152C9.36452 12.9152 10.5272 12.4385 11.4786 11.485C12.4301 10.5315 12.9058 9.37038 12.9058 8.00158C12.9058 6.63279 12.4301 5.47113 11.4786 4.5166C10.5272 3.56205 9.36452 3.08478 7.9906 3.08478C7.28324 3.08478 6.61437 3.23382 5.984 3.53188C5.35362 3.82995 4.79785 4.23961 4.3167 4.76087H5.85218V5.88333H2.18768V2.23767H3.30073V3.90072C3.90846 3.22438 4.61756 2.69769 5.42803 2.32063C6.2385 1.94359 7.09135 1.75507 7.9866 1.75507C8.8552 1.75507 9.66879 1.91968 10.4274 2.2489C11.1859 2.57813 11.8464 3.02455 12.4088 3.58817C12.9712 4.15178 13.4162 4.81199 13.7439 5.5688C14.0717 6.32562 14.2355 7.13663 14.2355 8.00182C14.2355 8.86699 14.0709 9.67719 13.7417 10.4324C13.4125 11.1876 12.9674 11.8472 12.4065 12.4112C11.8457 12.9752 11.1854 13.4203 10.4258 13.7464C9.66611 14.0725 8.85375 14.2355 7.9887 14.2355Z"
        fill="currentColor"
    />
    <path
        d="M4.3167 4.78587H5.82718V5.85833H2.21268V2.26267H3.27573V3.90072V3.96594L3.31933 3.91743C3.92484 3.24356 4.63122 2.71891 5.43858 2.3433C6.24573 1.96779 7.09499 1.78007 7.9866 1.78007C8.85195 1.78007 9.66213 1.94404 10.4174 2.27183C11.1732 2.59986 11.831 3.04453 12.3911 3.60582C12.9512 4.16713 13.3945 4.8247 13.721 5.57873C14.0473 6.33227 14.2105 7.13987 14.2105 8.00182C14.2105 8.86373 14.0466 9.67051 13.7188 10.4224C13.3907 11.1749 12.9474 11.8319 12.3888 12.3936C11.8303 12.9553 11.1727 13.3985 10.4159 13.7234C9.65951 14.0481 8.85052 14.2105 7.9887 14.2105C6.26983 14.2105 4.8053 13.6053 3.59367 12.3943C2.38791 11.1891 1.78663 9.72763 1.78941 8.00833H3.05056C3.0635 9.36974 3.54617 10.5311 4.49816 11.4911C5.45602 12.457 6.62065 12.9402 7.9906 12.9402C9.37101 12.9402 10.5401 12.4609 11.4963 11.5027C12.4525 10.5444 12.9308 9.37688 12.9308 8.00158C12.9308 6.62629 12.4525 5.45825 11.4964 4.49895C10.5401 3.5396 9.37102 3.05978 7.9906 3.05978C7.2796 3.05978 6.60708 3.20961 5.97331 3.50928C5.33998 3.80874 4.78162 4.22034 4.29833 4.74391L4.2596 4.78587H4.3167ZM7.9962 9.32863C7.62954 9.32863 7.31599 9.19901 7.05421 8.93909C6.79246 8.67921 6.66196 8.36758 6.66196 8.00285C6.66196 7.63807 6.79168 7.32544 7.05186 7.06364C7.31199 6.80187 7.62392 6.67137 7.989 6.67137C8.35685 6.67137 8.67161 6.79991 8.93462 7.05756C9.19748 7.31505 9.32865 7.62816 9.32865 7.9984C9.32865 8.36869 9.19836 8.68238 8.9373 8.94094C8.67614 9.1996 8.36289 9.32863 7.9962 9.32863Z"
        stroke="currentColor"
        stroke-width="0.05"
    />
</svg>
