import { CatalogSource } from "./Source";

export class Asset {
    constructor(
        public name: string = '',
        public source: CatalogSource = new CatalogSource(),
        public bimasset: Uint8Array = new Uint8Array(),
        public createdAt: Date = new Date(),
        public updatedAt: Date = new Date(),
    ) {}
}

