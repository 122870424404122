import type { Bim} from 'bim-ts';
import { BimProperty } from 'bim-ts';
import type { PUI_Node } from 'ui-bindings';
import {
    getAllTransformerIds,
} from 'lv-circuit';

const pathsWithApplyProps = [
    'circuit | pattern',
    'circuit | lv_wiring | max_voltage_drop',
];
export function matchPathForApplyProps(path: string[]) {
    const str = BimProperty.MergedPath(path);
    const isMatching = !!pathsWithApplyProps.find(x => str.endsWith(x));
    return isMatching;
}
export function getPathFromConfigUiNode(config: PUI_Node) {
    const path: string[] = [];
    let node: PUI_Node | null = config;
    while (node) {
        if (node.name === 'properties')
            break;
        path.push(node.name);
        node = node.parent;
    }
    return path.reverse();
}

export function applyProps(bim: Bim, path: string[]) {
    const selected = bim.instances.getSelected();
    if (selected.length > 1)
        return;
    const id = selected[0];
    const si = bim.instances.perId.get(id);
    if (!si) return;
    const props = si.properties
        .getPropStartingWith(BimProperty.MergedPath(path));

    const transformers = Array.from(getAllTransformerIds(bim))
        .filter(x => x !== id);
    for (const prop of props) {
        bim.instances.applyPatchTo({ properties: [
            [prop._mergedPath, prop],
        ] }, transformers);
    }
}
