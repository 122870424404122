<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M2.33334 13.3334C2.07779 13.3334 1.84723 13.2306 1.64168 13.025C1.43612 12.8195 1.33334 12.5889 1.33334 12.3334V3.66669C1.33334 3.41113 1.43612 3.18058 1.64168 2.97502C1.84723 2.76946 2.07779 2.66669 2.33334 2.66669H7.01668L8.01668 3.66669H13.6667C13.9222 3.66669 14.1528 3.76946 14.3583 3.97502C14.5639 4.18058 14.6667 4.41113 14.6667 4.66669H7.58334L6.58334 3.66669H2.33334V12.3334L4.03334 5.66669H15.6667L13.8833 12.5667C13.8167 12.8334 13.6945 13.0278 13.5167 13.15C13.3389 13.2722 13.1111 13.3334 12.8333 13.3334H2.33334ZM3.38334 12.3334H12.9167L14.3167 6.66669H4.78334L3.38334 12.3334Z"
        fill="currentColor"
    />
</svg>
