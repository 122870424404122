<svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
>
    <path d="M0 4H4V0" stroke="currentColor" stroke-width="1.6" />
    <path d="M18 14H14V18" stroke="currentColor" stroke-width="1.6" />
    <path
        d="M4 7V12C4 13.1046 4.89543 14 6 14H11"
        stroke="currentColor"
        stroke-width="1.6"
    />
    <path
        d="M14 11V6C14 4.89543 13.1046 4 12 4H7"
        stroke="currentColor"
        stroke-width="1.6"
    />
</svg>
