import type { EntitiesCollectionUpdates, EntitiesBaseConstructorParams } from 'bim-ts';
import { EntitiesBase, handleEntitiesUpdates } from 'bim-ts';
import type { Result} from 'engine-utils-ts';
import { StreamAccumulator, Success } from 'engine-utils-ts';
import type { EntityId } from 'verdata-ts';

export abstract class EntitiesBimSynced<T, BT, SyncedType extends number, SourceType extends number>
    extends EntitiesBase<T, SyncedType>
{
    bimCollectionSource: EntitiesBase<BT, SourceType>;
    _bimUpdates: StreamAccumulator<EntitiesCollectionUpdates<EntityId<SourceType>, number>>;

    constructor(
        sourceCollection: EntitiesBase<BT, SourceType, any>,
        entitiesParams:  EntitiesBaseConstructorParams<T, SyncedType, number>
    ) {
        super(entitiesParams);
        this.bimCollectionSource = sourceCollection;
        this._bimUpdates = new StreamAccumulator(this.bimCollectionSource.updatesStream);
    }

    dispose() {
        this._bimUpdates.dispose();
    }

    abstract convertFromBim(bimObj: BT, id: EntityId<SourceType>): Result<T>;

    mapBimIdToEngineId(sourceId: EntityId<SourceType>): EntityId<SyncedType> {
        // default implementation uses bim ids, overload if necessary
        return sourceId as unknown as EntityId<SyncedType>;
    }
	mapBimIdsToEngineIds(sourceIds: Iterable<EntityId<SourceType>>): EntityId<SyncedType>[] {
        // default implementation uses bim ids, overload if necessary
		const res: EntityId<SyncedType>[] = [];
		for (const sId of sourceIds) {
			const id = this.mapBimIdToEngineId(sId);
			res.push(id);
		}
        return res;
    }

    sync() {
        const deltas = this._bimUpdates.consume();
        if (!deltas) {
            return;
        }
        handleEntitiesUpdates(
			deltas,
            (allocatedIds) => {
                const toAlloc = this._convertForAlloc(allocatedIds);
                this.allocate(toAlloc);
            },
            (perIdDiff) => {
				const _removed = this.delete(this.mapBimIdsToEngineIds(perIdDiff.keys()));
				const toAlloc = this._convertForAlloc(perIdDiff.keys());
                this.allocate(toAlloc);
            },
            (removed) => {
                this.delete(this.mapBimIdsToEngineIds(removed));
            }
        )
    }

    _convertForAlloc(sourceIds: Iterable<EntityId<SourceType>>): [EntityId<SyncedType>, T][] {
        const toAlloc: [EntityId<SyncedType>, T][] = [];
        for (const id of sourceIds) {
            const g = this.bimCollectionSource.peekById(id);
            if (!g) {
                continue;
            }
            const convertedObj = this.convertFromBim(g, id);
            if (convertedObj instanceof Success) {
                toAlloc.push([this.mapBimIdToEngineId(id), convertedObj.value]);
            }
        }
        return toAlloc;
    }

}

