import type { IdBimScene} from '..';
import { BooleanProperty, NumberProperty, PropsFieldOneOf, PropsGroupBase, PropsGroupsRegistry, StringProperty, type SceneInstance } from '..';
import { EnergyYieldPerStringProducer } from 'src/energy/EnergyYieldPerStringProducer';
import { TrackerShadingProps } from '../trackers/shading/TrackerShadingProps';
import { PropsFieldFlags } from '../properties/PropsGroupComplexDefaults';
import { ModuleProps } from './ModuleProps';
import { PilesConfigsPerWindPosition, PilesConfigModulesRow, PilesConfigModuleBaySize, PilesProps } from './PilesProps';
import { SmallNumericArrayProperty } from '../properties/SmallNumberArrayProperty';
import type { TrackerRowIndexAndPositionsProperty } from './TrackerRowIndex';
import { NestedLvWires } from 'src/archetypes/LvWire';


export class TrackerFrameCommercialProps extends PropsGroupBase {

    manufacturer: StringProperty;
    model: StringProperty;
    series: StringProperty;
    type: StringProperty;

    constructor(args: Partial<TrackerFrameCommercialProps>) {
        super();
        this.manufacturer = args.manufacturer ?? StringProperty.new({value: ``});
        this.model = args.model ?? StringProperty.new({value: ``});
        this.series = args.series ?? StringProperty.new({value: ``});
        this.type = args.type ?? StringProperty.new({value: ``});
    }
}
PropsGroupsRegistry.register({
    class: TrackerFrameCommercialProps,
    complexDefaults: {
        
    }
});

export class TrackerFrameDimensionsProps extends PropsGroupBase {

    strings_count: NumberProperty;
    
    modules_gap_x: NumberProperty;
    modules_gap_y: NumberProperty;
    modules_horizontal_placement: BooleanProperty;
    motor_gap: NumberProperty;
    pile_bearings_gap: NumberProperty;

    tube_overhang_north: NumberProperty;
    tube_overhang_south: NumberProperty;

    length: NumberProperty | null;
    max_width: NumberProperty | null;

    constructor(args: Partial<TrackerFrameDimensionsProps>) {
        super();

        this.modules_gap_x = args.modules_gap_x ?? NumberProperty.new({value: 0, unit: 'm', range: [0, 1]});
        this.modules_gap_y = args.modules_gap_y ?? NumberProperty.new({value: 0, unit: 'm', range: [0, 1]});
        this.modules_horizontal_placement = args.modules_horizontal_placement ?? BooleanProperty.new({value: false});
        this.motor_gap = args.motor_gap ?? NumberProperty.new({value: 0, unit: 'm', range: [0, 10]});
        this.pile_bearings_gap = args.pile_bearings_gap ?? NumberProperty.new({value: 0, unit: 'm', range: [0, 10]});
        this.tube_overhang_north = args.tube_overhang_north ?? NumberProperty.new({value: 0, unit: 'm', range: [0, 10]});
        this.tube_overhang_south = args.tube_overhang_south ?? NumberProperty.new({value: 0, unit: 'm', range: [0, 10]});
        this.strings_count = args.strings_count ?? NumberProperty.new({value: 1, range: [1, 100], step: 1});

        this.length = args.length ?? null;
        this.max_width = args.max_width ?? null;
    }
}
PropsGroupsRegistry.register({
    class: TrackerFrameDimensionsProps,
    complexDefaults: {
        length: new PropsFieldOneOf(PropsFieldFlags.None, NumberProperty),
        max_width: new PropsFieldOneOf(PropsFieldFlags.None, NumberProperty),
    }
});


export class TrackerStringProps extends PropsGroupBase {

    modules_count_x: NumberProperty;
    modules_count_y: NumberProperty;

    current: NumberProperty | null;
    max_current: NumberProperty | null;
    voltage: NumberProperty | null;
    power: NumberProperty | null;

    constructor(args: Partial<TrackerStringProps>) {
        super();

        this.modules_count_x = args.modules_count_x ?? NumberProperty.new({value: 1, range: [1, 255], step: 1});
        this.modules_count_y = args.modules_count_y ?? NumberProperty.new({value: 1, range: [1, 10], step: 1});

        this.current = args.current ?? null;
        this.max_current = args.max_current ?? null;
        this.voltage = args.voltage ?? null;
        this.power = args.power ?? null
    }

    get modules_count(): NumberProperty {
        return NumberProperty.new({
            value: this.modules_count_x.value * this.modules_count_y.value,
            isReadonly: true,
            step: 1,
        });
    }
}
PropsGroupsRegistry.register({
    class: TrackerStringProps,
    complexDefaults: {
        modules_count: new PropsFieldOneOf(PropsFieldFlags.SkipSerialization, NumberProperty),
        current: new PropsFieldOneOf(PropsFieldFlags.SkipSerialization, NumberProperty),
        max_current: new PropsFieldOneOf(PropsFieldFlags.SkipSerialization, NumberProperty),
        voltage: new PropsFieldOneOf(PropsFieldFlags.SkipSerialization, NumberProperty),
        power: new PropsFieldOneOf(PropsFieldFlags.SkipSerialization, NumberProperty),
    }
});

export class TrackerModules extends PropsGroupBase {

    modules_count_x: NumberProperty;
    modules_count_y: NumberProperty;
    
    // modules_count: NumberProperty;

    constructor(args: Partial<TrackerModules>) {
        super();
        this.modules_count_x = args.modules_count_x ?? NumberProperty.new({value: 1, range: [1, 10_000]});
        this.modules_count_y = args.modules_count_y ?? NumberProperty.new({value: 1, range: [1, 10]});
    }

}

export class TrackerFrameModulesProps extends PropsGroupBase {

    modules_count_x: NumberProperty;

    constructor(args: Partial<TrackerFrameModulesProps>) {
        super();
        this.modules_count_x = args.modules_count_x ?? NumberProperty.new({value: 1, range: [1, 10_000], step: 1, isReadonly: true});
    }
}
PropsGroupsRegistry.register({
    class: TrackerFrameModulesProps,
    complexDefaults: {
    },
});

export class TrackerFrameProps extends PropsGroupBase {

    commercial: TrackerFrameCommercialProps;
    string: TrackerStringProps;
    dimensions: TrackerFrameDimensionsProps;
    modules: TrackerFrameModulesProps;

    piles_configurations: PilesConfigsPerWindPosition | PilesConfigModulesRow | PilesConfigModuleBaySize;

    constructor(args: Partial<TrackerFrameProps>) {
        super();

        this.commercial = args.commercial ?? new TrackerFrameCommercialProps({});
        this.string = args.string ?? new TrackerStringProps({});
        this.dimensions = args.dimensions ?? new TrackerFrameDimensionsProps({});
        this.piles_configurations = args.piles_configurations ?? new PilesConfigModuleBaySize({});
        this.modules = args.modules ?? new TrackerFrameModulesProps({});
    }
}
PropsGroupsRegistry.register({
    class: TrackerFrameProps,
    complexDefaults: {
        piles_configurations: new PropsFieldOneOf(PropsFieldFlags.None, PilesConfigsPerWindPosition, PilesConfigModulesRow, PilesConfigModuleBaySize),
        piles_lengths: new PropsFieldOneOf(PropsFieldFlags.None, SmallNumericArrayProperty),
        piles_reveal: new PropsFieldOneOf(PropsFieldFlags.None, SmallNumericArrayProperty),
    }
});


export class TrackerPosition extends PropsGroupBase {

    wind_load_position: StringProperty | null;

    facing_first_to_last_pile: StringProperty; // computed by runtime
    slope_first_to_last_pile: NumberProperty; // computed by runtime
    max_slope_per_bay: NumberProperty; // computed by runtime
    max_bay_to_bay_slope_change: NumberProperty; // computed by runtime
    cumulative_slope_first_to_last: NumberProperty; // computed by runtime
    cumulative_slopes: SmallNumericArrayProperty | null; // computed by runtime

    row_index: TrackerRowIndexAndPositionsProperty | null; // computed by runtime
    
    constructor(args: Partial<TrackerPosition>) {
        super();

        this.wind_load_position = args.wind_load_position ?? null;

        this.facing_first_to_last_pile = args.facing_first_to_last_pile 
            ?? StringProperty.new({ value: "north", isReadonly: true });
        this.slope_first_to_last_pile = args.slope_first_to_last_pile 
            ?? NumberProperty.new({ value: 0, unit: '%', isReadonly: true });
        this.max_slope_per_bay = args.max_slope_per_bay 
            ?? NumberProperty.new({ value: 0, unit: '%', isReadonly: true });
        this.max_bay_to_bay_slope_change = args.max_bay_to_bay_slope_change 
            ?? NumberProperty.new({ value: 0, unit: '%', isReadonly: true });
        this.cumulative_slope_first_to_last = args.cumulative_slope_first_to_last 
            ?? NumberProperty.new({ value: 0, unit: '%', isReadonly: true });
        this.cumulative_slopes = args.cumulative_slopes ?? null;

        this.row_index = args.row_index ?? null;
    }
}
PropsGroupsRegistry.register({
    class: TrackerPosition,
    complexDefaults: {
        wind_load_position: new PropsFieldOneOf(PropsFieldFlags.SkipClone, null, StringProperty),
        facing_first_to_last_pile: new PropsFieldOneOf(PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization, null, StringProperty),
        slope_first_to_last: new PropsFieldOneOf(PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization, null, NumberProperty),
        max_slope_per_bay: new PropsFieldOneOf(PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization, null, NumberProperty),
        max_bay_to_bay_slope_change: new PropsFieldOneOf(PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization, null, NumberProperty),
        cumulative_slope_first_to_last: new PropsFieldOneOf(PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization, null, NumberProperty),
        cumulative_slopes: new PropsFieldOneOf(PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization, null, SmallNumericArrayProperty),
        row_index: new PropsFieldOneOf(PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization, null, NumberProperty),
    }
});


export class AnyTrackerProps extends PropsGroupBase {
    
    module: ModuleProps;
    tracker_frame: TrackerFrameProps;

    position: TrackerPosition;
    piles: PilesProps;

    shading: TrackerShadingProps | null;
    energy_per_string: EnergyYieldPerStringProducer | null;

    lv_wiring: NestedLvWires | null;

    constructor(args: Partial<AnyTrackerProps>) {
        super();

        this.module = args.module ?? new ModuleProps({});
        this.tracker_frame = args.tracker_frame ?? new TrackerFrameProps({});

        this.position = args.position ?? new TrackerPosition({});
        this.piles = args.piles ?? new PilesProps({});


        this.shading = args.shading ?? null;
        this.energy_per_string = args.energy_per_string ?? null;
        this.lv_wiring = args.lv_wiring ?? null;
    }

    modulesArrayHeight(): number {
        const dimensions = this.tracker_frame.dimensions;
        const string = this.tracker_frame.string;
        const modulesCountY = string.modules_count_y.value;
        const moduleHeight = dimensions.modules_horizontal_placement.value ?
            this.module.width.value : this.module.length.value;
        
        if (modulesCountY > 1) {
            return moduleHeight * modulesCountY + dimensions.modules_gap_y.as('m') * (modulesCountY - 1);
        } else {
            return moduleHeight * modulesCountY;
        }
    }
}
PropsGroupsRegistry.register({
    class: AnyTrackerProps,
    complexDefaults: {
        energy_per_string: new PropsFieldOneOf(
            PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization,
            null,
            EnergyYieldPerStringProducer,
        ),
        shading: new PropsFieldOneOf(
            PropsFieldFlags.SkipClone,
            null,
            TrackerShadingProps,
        ),
        lv_wiring: new PropsFieldOneOf(
            PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization,
            null,
            NestedLvWires,
        ),
    },
});

// export interface TrackerParts {
//     modules: {
//         size: Vector3,
//         instances: Matrix4[],
//     },
//     piles: {
//         size: Vector3,
//         instances: Matrix4[],
//     },
//     motors: {
//         size: Vector3,
//         instances: Matrix4[],
//     },
//     pile_caps: {
//         size: Vector3,
//         instances: Matrix4[],
//     },
// }

export function newAnyTrackerInstance(): Partial<SceneInstance> {
    // const props = PropsGroupsRegistry.newPropsGroupFromJsonLikeArgs(AnyTrackerProps, {
    //     module: {
    //         width: {value: 1, unit: 'm'},
    //         length: {value: 2, unit: 'm'},
    //         current: {value: 10, unit: 'A'},
    //         voltage: {value: 40, unit: 'V'},
    //         maximum_power: {value: 400, unit: 'W'},
    //     },
    //     tracker_frame: {
    //         commercial: {
    //             manufacturer: {value: "Some Manufacturer"},
    //             model: {value: "Some Model"},
    //         },
    //         dimensions: {
    //             strings_count: {value: 5},
    //             modules_gap_x: {value: 0.1, unit: 'm'},
    //             modules_gap_y: {value: 0.1, unit: 'm'},
    //             motor_gap: {value: 0.4, unit: 'm'},
    //             pile_bearings_gap: {value: 0.2, unit: 'm'},
    //             tube_overhang_north: {value: 1, unit: 'm'},
    //             tube_overhang_south: {value: 2, unit: 'm'},
    //         },
    //         string: {
    //             modules_count_x: {value: 4},
    //             modules_count_y: {value: 2},
    //         },
    //         piles_configurations: PropsGroupsRegistry.newPropsGroupFromJsonLikeArgs(PilesConfigModuleBaySize, {
    //             module_bay_size: {value: 5},
    //             first_pile_offset: {value: 2},
    //             motor_at_pile: {value: 0},
    //         }),
    //     },
    //     piles: {
    //         lengths: new SmallNumericArrayProperty({values: [1, 2, 3, 4, 5], unit: 'm'}),
    //         _pile_tops_distance_to_ground: new SmallNumericArrayProperty({values: [0.1, 0.2, 0.3, 0.4, 0.5], unit: 'm'}),
    //     }
    // });

    const anyTrackerProps = PropsGroupsRegistry.newPropsGroupFromJsonLikeArgs(AnyTrackerProps, {
        module: {
            width: {
                value: 1.245,
                unit: "m"
            },
            length: {
                value: 2.024,
                unit: "m"
            },
            manufacturer: {
                value: "Generic Manufacturer"
            },
            model: {
                value: "Generic Model"
            },
            technology: {
                value: "mtCdTe"
            },
            current: {
                value: 2.42,
                unit: "A"
            },
            voltage: {
                value: 187.8,
                unit: "V"
            },
            max_system_voltage: {
                value: 1500,
                unit: "V"
            },
            maximum_power: {
                value: 455,
                unit: "W"
            },
            open_circuit_voltage: {
                value: 222,
                unit: "V"
            },
            short_circuit_current: {
                value: 2.58,
                unit: "A"
            },
            temp_coeff_current: {
                value: 0.0004,
            },
            temp_coeff_power: {
                value: -0.0032,
            },
            temp_coeff_voltage: {
                value: -0.0028,
            },
            bifaciality_factor: {
                value: 0,
            },
            degradation: {
                first_year_power_degradation: {
                    value: 0.02,
                },
                power_degradation: {
                    value: 0.005,
                }
            }
        },
        tracker_frame: {
            commercial: {
                manufacturer: {
                    value: "Generic Manufacturer"
                },
                model: {
                    value: "Generic Model"
                },
                series: {
                    value: ""
                },
                type: {
                    value: "Horizontal single-axis"
                }
            },
            string: {
                modules_count_x: {
                    value: 30,
                    unit: ""
                },
                modules_count_y: {
                    value: 1,
                    unit: ""
                }
            },
            dimensions: {
                modules_gap_x: {
                    value: 0.03,
                    unit: "m"
                },
                modules_gap_y: {
                    value: 0.03,
                    unit: "m"
                },
                modules_horizontal_placement: {
                    value: false
                },
                motor_gap: {
                    value: 1.82,
                    unit: "m"
                },
                pile_bearings_gap: {
                    value: 0,
                    unit: "ft"
                },
                tube_overhang_north: {
                    value: 0.8,
                    unit: "m"
                },
                tube_overhang_south: {
                    value: 0.8,
                    unit: "m"
                },
                strings_count: {
                    value: 2,
                    unit: ""
                }
            },
            piles_configurations: PropsGroupsRegistry.newPropsGroupFromJsonLikeArgs(PilesConfigModuleBaySize, {
                module_bay_size: { value: 6 },
                motor_at_pile: { value: 4 }
            }),
        },
    });

    return {
        type_identifier: 'any-tracker',
        props: anyTrackerProps,
    }
}


export class EditTrackerPilesContext {
    constructor(
        public objectDetailsMode?: boolean,
        public ids?: IdBimScene[],
    ) {}
}
