import type { ValueOf} from "engine-utils-ts";
import { DefaultMap, ErrorUtils, ScopedLogger, WorkerClassPassRegistry } from "engine-utils-ts";
import type { PropsGroupField, TypeHintedPropsGroupArgs } from "./Props";
import { PropertyBase, PropsGroupBase } from "./Props";
import type { PropsGroupComplexDefaults, PropsGroupDerivedValues} from "./PropsGroupComplexDefaults";
import { PropsFieldOneOf, PropsFieldArrayOf, PropsFieldFlags } from "./PropsGroupComplexDefaults";
import { CustomPropsRegistry } from './CustomPropsRegistry';


type PropsGroupCtor<PG extends PropsGroupBase> = {new (args: PropsGroupTypingArgs<PG>): PG};
type AnyPropsGroupCtor = PropsGroupCtor<any>;


export type PropsGroupTypingArgs<T extends PropsGroupBase> = {
    [K in keyof T as
        string extends K ? never :
        number extends K ? never :
        T[K] extends Function ? never :
        K
    ]?: T[K]
};


export interface PropertyGroupToMigrate {
    [key: string]: PropsGroupField;
}
export interface PropertyGroupMigration {
    toVersion: number,
    migrationFn: (propsGroup: PropertyGroupToMigrate) => PropertyGroupToMigrate,
}

export class _PropsGroupsRegistry {

    private readonly logger: ScopedLogger = new ScopedLogger('props');

    private readonly _perCtorName = new Map<string, AnyPropsGroupCtor>();
    private readonly _serializedTypeIdentPerCtor = new Map<AnyPropsGroupCtor, string>();
    private readonly _classPerSerializedTypeIdent = new Map<string, AnyPropsGroupCtor>();
    private readonly _migraionsPerSerializedTypeIdent = new Map<string, PropertyGroupMigration[]>();
    private readonly _allowedFieldsTypesPerClass = new Map<AnyPropsGroupCtor, PropsGroupArgsTypeChecker>();

    private readonly _currentVersionPerSerializedTypeIdent: DefaultMap<string, number>;

    constructor() {
        this._currentVersionPerSerializedTypeIdent = new DefaultMap((typeIdent): number => {
            const migrations = this._migraionsPerSerializedTypeIdent.get(typeIdent);
            let lastVersion = 0;
            if (migrations) {
                for (const m of migrations) {
                    if (m.toVersion < 0) {
                        this.logger.error('only positive versions are allowed');
                    }
                    if (m.toVersion <= lastVersion) {
                        this.logger.error('migrations versions should be strictly increasing');
                    }
                    lastVersion = Math.max(lastVersion, m.toVersion);
                }
            } else if (this._classPerSerializedTypeIdent.has(typeIdent) === false) {
                this.logger.error(`group migrations for non registered group ${typeIdent} are requested`);
            }
            return lastVersion;
        });
    }

    newPropsGroupFromJsonLikeArgs<T extends PropsGroupBase>(clss: PropsGroupCtor<T>, args: TypeHintedPropsGroupArgs<T>): T {

        const fields = this._allowedFieldsTypesPerClass.get(clss);

        if (!fields) {
            const errorMsg = `no allowed fields registered for constructor ${clss.name}`;
            this.logger.error(errorMsg);
            throw new Error(errorMsg);
        }

        const groupArgs: Partial<T> = {};
        for (const argsKey in args) {
            const argsValue = args[argsKey];

            const allowed = fields.allowedFields.get(argsKey);

            if (allowed === undefined) {
                this.logger.error(`unexpected props group ${clss.name} argument at ${String(argsKey)}`, argsValue);
                continue;
            }

            const createdField = this._createPropsFieldFromIncompleteArgs(allowed, argsValue);

            if (createdField === undefined) {
                this.logger.error(`could not create props group ${clss.name} argument at ${String(argsKey)}`, argsValue);
                continue;
            }
            
            (groupArgs as any)[argsKey] = createdField;
        }

        return new clss(groupArgs as any);
    }

    private _createPropsFieldFromIncompleteArgs(allowed: ArgsCheckerField, value: any): PropsGroupField | undefined {
        
        if (allowed instanceof PropertyBase) {
            if (value instanceof allowed.constructor) {
                return value as PropertyBase;
            } else {
                return CustomPropsRegistry.constructPropertyFromPartialArgs(allowed.constructor as any, {...allowed, ...value});
            }
        } else if (allowed instanceof PropsGroupBase) {
            if (value instanceof allowed.constructor) {
                return value as PropsGroupBase;
            } else {
                return this.newPropsGroupFromJsonLikeArgs(allowed.constructor as any, value);
            }
        } else if (allowed instanceof PropsFieldOneOf) {
            // const typeIdent = value.type;
            // if (typeIdent === undefined) {
            //     // not explicit type ident, try to determine the correct runtime type
            //     // this.logger.batchedWarn('no explicit type provided, trying to guess', [value, allowed]);
            // }
            if (typeof value !== 'object') {
                this.logger.batchedError(`unexpected value type for oneof field`, value);
                return undefined;
            }
            // if (value === null) {
            //     if (allowed.allowedTypesCtors.includes(null)) {
            //         return null;
            //     } else {
            //         this.logger.batchedError(`null is not allowed for oneof field`, value);
            //         return undefined;
            //     }
            // }
            for (const ctor of allowed.allowedTypesCtors) {
                if (ctor === null) {
                    if (value === null) {
                        return null;
                    }
                    continue;
                }
                if (value instanceof ctor) {
                    return value;
                }
                if (ctor.name === value?.type) {
                    if (ctor.prototype instanceof PropertyBase) {
                        return CustomPropsRegistry.constructPropertyFromPartialArgs(ctor, value);
                    } else {
                        return this.newPropsGroupFromJsonLikeArgs(ctor, value);
                    }
                }
            }
            const firstNonNull = allowed.allowedTypesCtors.find((ctor) => ctor !== null)!;
            if (firstNonNull.prototype instanceof PropertyBase) {
                return CustomPropsRegistry.constructPropertyFromPartialArgs(firstNonNull, value);
            } else {
                return this.newPropsGroupFromJsonLikeArgs(firstNonNull, value);
            }

        } else if (allowed instanceof PropsFieldArrayOf) {
            this.logger.batchedWarn('arrays are not implemented yet', [allowed, value]);
            return undefined;
        }

        this.logger.error(`unexpected propsGroup ${allowed} default field type`, value);
        return undefined;
    }

    register<T extends PropsGroupBase>(args: {
        class: PropsGroupCtor<T>,
        complexDefaults: PropsGroupComplexDefaults<T>,
        derivedProps?: PropsGroupDerivedValues<T>,
        serializedTypeIdent?: string,
        migrations?: PropertyGroupMigration[],
    }) {
        const serializedTypeIdent = args.serializedTypeIdent || args.class.name;
        const ctor = args.class as unknown as AnyPropsGroupCtor;

        if (this._perCtorName.has(args.class.name)) {
            this.logger.error(`PROPS GROUP WITH NAME ${args.class.name} IS already registered`);
        }
        if (this._classPerSerializedTypeIdent.has(serializedTypeIdent)) {
            this.logger.error(`PROPS GROUP WITH SERIALIZED NAME ${serializedTypeIdent} IS already registered`);
        }
        this._perCtorName.set(args.class.name, ctor);
        this._serializedTypeIdentPerCtor.set(ctor,serializedTypeIdent);
        this._classPerSerializedTypeIdent.set(serializedTypeIdent, ctor);
        this._allowedFieldsTypesPerClass.set(ctor, PropsGroupArgsTypeChecker.newFor(args.class, args.complexDefaults));

        if (args.migrations) {
            this._migraionsPerSerializedTypeIdent.set(serializedTypeIdent, args.migrations);
        }

        WorkerClassPassRegistry.registerClass(args.class);
    }

    newInstanceFromClassName<T extends PropsGroupBase>(propsGroupTypeName: string, args: Partial<T>): T {
        const ctor = this._perCtorName.get(propsGroupTypeName);
        if (!ctor) {
            throw new Error(`No group with name ${propsGroupTypeName} registered`);
        }
        return new ctor(args) as T;
    }

    newInstanceFromSerializedTypeIdent<T extends PropsGroupBase>(
        serializedGroupTypeIdent: string,
        serializedVersion: number,
        args: Partial<T>,
    ): T | null {
        const migrations = this._migraionsPerSerializedTypeIdent.get(serializedGroupTypeIdent);
        if (migrations) {
            for (const migration of migrations) {
                if (migration.toVersion > serializedVersion) {
                    try {
                        args = migration.migrationFn(args as PropertyGroupToMigrate) as Partial<T>;
                    } catch (e) {
                        this.logger.batchedError(`error during migration application for group ${serializedGroupTypeIdent} from version ${serializedVersion} to ${migration.toVersion}`, e)
                    }
                }
            }
        } else if (serializedVersion !== 0) {
            this.logger.batchedError(`no migrations for group ${serializedGroupTypeIdent} but version is ${serializedVersion}`, args);
        }

        const ctor = this._classPerSerializedTypeIdent.get(serializedGroupTypeIdent);
        if (!ctor) {
            this.logger.batchedError(`no group with name ${serializedGroupTypeIdent} registered`, args);
            return null;
        }
        return this.newGroupInstanceChecked(ctor, args) as T;
    }

    newGroupInstanceChecked<T extends PropsGroupBase>(ctor: PropsGroupCtor<T>, args: PropsGroupTypingArgs<T>): T {
        const allowedFields = this._allowedFieldsTypesPerClass.get(ctor);
        if (!allowedFields) {
            this.logger.batchedError(`no allowed fields registered for constructor ${ctor.name}`, args);
        } else {
            const filteredArgs: PropsGroupTypingArgs<T> = {};

            argsCheckLoop: for (const key in args) {
                const value = args[key];
                if (allowedFields.isFieldTypeValid(key, value)) {
                    filteredArgs[key] = value;
                } else if (value !== undefined) {
                    this.logger.batchedError(`invalid props group ${ctor.name} argument at ${String(key)}`, value);
                    continue argsCheckLoop;
                }
            }
            args = filteredArgs;
        }
        return new ctor(args).freeze() as T;
    }

    newGroupInstance<T extends PropsGroupBase>(ctor: PropsGroupCtor<T>, args: PropsGroupTypingArgs<T>): T {
        return new ctor(args) as T;
    }


    getSerializationTypeIdentifierOf<T extends PropsGroupBase>(propsGroup: T): string {
        const ctor = propsGroup.constructor as AnyPropsGroupCtor;
        const ident = this._serializedTypeIdentPerCtor.get(ctor);
        if (!ident) {
            ErrorUtils.logThrow('no type ident registered for constructor', ctor.name, propsGroup);
        }
        return ident;
    }

    getSerializedTypeVersion(serializedTypeIdent: string): number {
        return this._currentVersionPerSerializedTypeIdent.getOrCreate(serializedTypeIdent);
    }

    getComplexDefaultsFor<T extends PropsGroupBase>(ctor: PropsGroupCtor<T>): PropsGroupComplexDefaults<T> | undefined {
        const complexDefaults = this._allowedFieldsTypesPerClass.get(ctor);
        if (!complexDefaults) {
            return undefined;
        }
        return complexDefaults.complexDefaults;
    }
    isRegistered<T extends PropsGroupBase>(ctor: PropsGroupCtor<T>): boolean {
        return this._allowedFieldsTypesPerClass.has(ctor);
    }
    getTypeCheckerFor<T extends PropsGroupBase>(ctor: PropsGroupCtor<T>): PropsGroupArgsTypeChecker|undefined {
        return this._allowedFieldsTypesPerClass.get(ctor);
    }

    cloneWithoutFlags<T extends PropsGroupBase>(props: T, flagsToSkip: PropsFieldFlags): T {
        const typeChecks = this._allowedFieldsTypesPerClass.get(props.constructor as AnyPropsGroupCtor);
        if (!typeChecks) {
            throw new Error(props.constructor.name + ' is not registered');
        }
        if ((typeChecks.allFieldsFlagsCombined & flagsToSkip) === 0) {
            return props;
        }
        const args: {[key: string]: PropsGroupField} = {};
        for (const key in props) {
            const v = props[key];
            if (typeof v !== 'object') {
                continue;
            }
            if ((typeChecks.perFieldFlags.get(key) || 0) & flagsToSkip) {
                continue; // skipping field by flags
            }
            args[key] = v instanceof PropsGroupBase ? this.cloneWithoutFlags(v, flagsToSkip) : v;
        }
        return new (props.constructor as AnyPropsGroupCtor)(args) as T;
    }
}


type ArgsCheckerField = PropertyBase | PropsGroupBase | null | PropsFieldArrayOf<any> | PropsFieldOneOf;

export class PropsGroupArgsTypeChecker {
    constructor(
        public readonly complexDefaults: PropsGroupComplexDefaults<any>,
        public readonly allowedFields: Map<string, ArgsCheckerField>,
        public readonly perFieldFlags: Map<string, PropsFieldFlags>,
        public readonly allFieldsFlagsCombined: PropsFieldFlags,
    ) {
    }

    static newFor<T extends PropsGroupBase>(
        ctor: PropsGroupCtor<T>,
        complexDefaults: PropsGroupComplexDefaults<T>
    ): PropsGroupArgsTypeChecker {
        const emptyGroup = new ctor({});
        const allowedFields:  Map<string, ArgsCheckerField> = new Map();
        let allFieldsFlagsCombined: PropsFieldFlags = PropsFieldFlags.None;
        const perFieldFlags: Map<string, PropsFieldFlags> = new Map();
        for (const key in emptyGroup) {
            const complexDefaultDefined = (complexDefaults as any)[key] as ValueOf<PropsGroupComplexDefaults<T>>|undefined;
            if (complexDefaultDefined) {
                allowedFields.set(key, complexDefaultDefined);
                allFieldsFlagsCombined |= complexDefaultDefined.flags;
                perFieldFlags.set(key, complexDefaultDefined.flags);

            } else {
                const value = emptyGroup[key];
                if (typeof value === 'function') {
                    continue;
                }
                if (value instanceof PropertyBase
                    || value instanceof PropsGroupBase
                    || value === null
                ) {
                    allowedFields.set(key, value);
                } else {
                    console.error(`unexpected propsGroup ${ctor.name}.${key} default field type`, value);
                }
            }
        }
        return new PropsGroupArgsTypeChecker(complexDefaults, allowedFields, perFieldFlags, allFieldsFlagsCombined);
    }

    isFieldTypeValid(key: string, value: any) {
        const allowed = this.allowedFields.get(key);

        if (allowed === undefined) {
            return false;
        }
        if (allowed instanceof PropsFieldOneOf) {
            return allowed.isValidType(value as PropsGroupField);
        }
        if (allowed instanceof PropsFieldArrayOf) {
            if (Array.isArray(value)) {
                return allowed.isValidType(value);
            } else {
                return false;
            }
        }
        if (value === null) {
            return allowed === null;
        } else {
            return value.constructor === allowed?.constructor;
        }
    }
}

export const PropsGroupsRegistry = new _PropsGroupsRegistry();
