import { combineHashCodes } from "math-ts";
import type { BasicPropertyValue} from "src/properties/BasicPropsView";
import { PropertyViewBasicTypes } from "src/properties/BasicPropsView";
import { CustomPropsRegistry } from "src/properties/CustomPropsRegistry";
import type { PropsGroupField, PropValueTotalHash } from "src/properties/Props";
import { PropertyBase } from "src/properties/Props";
import { toArrayWithExactCapacity } from "src/properties/SmallNumberArrayProperty";
import { getRowIndex, type InRowPositionPacked } from "src/trackers/rows/IndicesWithPositionPacker";
import { getCharCodesForPileIndex } from "./PilesProps";


export class PilesIndicesAndPositionsProperty extends PropertyBase {
    public readonly values: InRowPositionPacked[];

    constructor(args: Partial<PilesIndicesAndPositionsProperty>) {
        super();
        if (args.values && args.values.length > 0) {
            this.values = toArrayWithExactCapacity(args.values);
        } else {
            this.values = [];
        }
        
        Object.freeze(this.values);
        Object.freeze(this);
    }

    equals(other: PropsGroupField): boolean {
        if (!(other instanceof PilesIndicesAndPositionsProperty)) {
            return false;
        }
        if (this.values.length !== other.values.length) {
            return false;
        }
        for (let i = 0; i < this.values.length; ++i) {
            if (!Object.is(this.values[i], other.values[i])) {
                return false;
            }
        }
        return true;
    }

    hash(): number | string {
        let hash = 2;
        for (const v of this.values) {
            hash = combineHashCodes(v, hash);
        }
        return hash;
    }

    uniqueValueHash(): PropValueTotalHash {
        return `${this.values.join()}`;
    }
}
CustomPropsRegistry.register({
    class: PilesIndicesAndPositionsProperty as any,
    constructFromPartial: PilesIndicesAndPositionsProperty,
    basicTypesView: {
        basicTypes: PropertyViewBasicTypes.StringArray,
        toBasicValues(_, property, __): BasicPropertyValue|undefined {
            return {value: property.values.map(i => {
                const pileRowCode: number[] = [];
                getCharCodesForPileIndex(getRowIndex(i) - 1, pileRowCode);
                return String.fromCharCode(...pileRowCode);
            })};
        },
    }
});