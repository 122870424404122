import type { DC_CNSTS } from 'bim-ts';
import * as T from 'three';
import {
    ArcballControls,
} from 'three/examples/jsm/controls/ArcballControls';
import type {
    RenderConfigForLine} from './constants';
import {
    LINE_PRIORITY_OFFSET, EquipmentGeometry,
    EQUIPMENT_PRIORITY_OFFSET, meshPerNodeType,
} from './constants';
import type { Polyline } from './types';
import { DefaultMap, IterUtils, Yield } from 'engine-utils-ts';
import { createVerticesFromPolyline, setZOnVertices } from './utils';
import type { Vector2 } from 'math-ts';

/**
 * @returns
 * If true is returned iteration of current branch will be stopped.
 */
type TraverseCallback =
    (x: HierarchyLevel, path: string[]) => boolean;

class HierarchyLevel {
    subLevels =
        new DefaultMap<string, HierarchyLevel>(() => new HierarchyLevel());
    items: T.Object3D[] = [];
    visible = true;
    add(path: string[], obj: T.Object3D) {
        const subLevel = this.get(path);
        subLevel.items.push(obj);
    }
    get(path: string[]): HierarchyLevel {
        if (!path.length)
            return this;
        const [subLevelName, ...remainingPath] = path;
        const subLevel = this.subLevels.getOrCreate(subLevelName);
        return subLevel.get(remainingPath);
    }
    list(path: string[]): T.Object3D[] {
        const subLevel = this.get(path);
        const allItems: T.Object3D[] = [];
        subLevel.traverse(x => (allItems.push(...x.items), true));
        return allItems;
    }
    private _traverse(
        fn: TraverseCallback,
        path: string[] = [],
    ) {
        const shouldStop = fn(this, path);
        if (shouldStop) return;
        for (const [name, level] of this.subLevels) {
            level._traverse(fn, [...path, name]);
        }
    }
    traverse(fn: TraverseCallback) {
        this._traverse(fn, []);
    }
    clear() {
        this.items = [];
        this.subLevels.clear();
    }
}

export class Operations {
    private camRot = 0;

    readonly canvas: HTMLCanvasElement;

    // three js
    private readonly scene: T.Scene;
    private readonly renderer: T.WebGLRenderer;
    private readonly camera: T.OrthographicCamera;
    private readonly controls: ArcballControls;
    private readonly hierarchy: HierarchyLevel;
    readonly hidden = new Set<string>();

    constructor(canvas: HTMLCanvasElement) {
        this.hierarchy = new HierarchyLevel();
        this.canvas = canvas;
        this.renderer = new T.WebGLRenderer({ canvas });
        this.camera = new T.OrthographicCamera(-5, 5, -10, 10, 0, 1000);
        this.camera.position.setZ(10);
        this.camera.lookAt(0,0,0);
        this.renderer.setClearColor(
            new T.Color('#ddd').getHex(),
        );
        this.scene = new T.Scene();
        this.controls = new ArcballControls(
            this.camera,
            this.renderer.domElement,
            this.scene,
        );
        this.controls.addEventListener('change', this.render.bind(this));
        this.controls.enableRotate = false;
        this.controls.maxZoom = 20;
        this.controls.minZoom = 0.001;
        this.controls.cursorZoom = true;
        this.controls.enableGizmos = false;
        this.controls.setGizmosVisible(false);
        // arguments are not parsed correctly
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.controls.setMouseAction('PAN', 0);
    }

    //private getPositionFromMouseEvent(e:MouseEvent) {

    //}
    //private handleClickEvent(e: MouseEvent) {
    //    const rect = this.renderer.domElement.getBoundingClientRect();
    //    const x = (e.clientX - rect.left) / rect.width - 0.5;
    //    const y = (rect.height - e.clientY + rect.top) / rect.height - 0.5;
    //    console.log(x, y);
    //    const clickAt = new T.Vector3(x, y, 100000);
    //    const unproject = clickAt.unproject(this.camera);
    //    console.log(unproject);
    //    return;

    //    //this.raycaster.setFromCamera(new T.Vector3(1,1,0), this.camera);
    //    //this.raycaster.ray.recast
    //    console.log(this.controls.scaleFactor)

    //    //console.log(clickAt);
    //    //console.log(clickAt.clone().unproject(this.camera));
    //    //var vector = new THREE.Vector3(event.clientX, event.clientY, 1);
    //    return

    //    this.raycaster.setFromCamera(clickAt, this.camera);
    //    const newPos = new T.Vector3();
    //    this.raycaster.ray.at(0.1, newPos);
    //    //this.addEquipment("end_of_multiharness", [new Vector2(newPos.x, newPos.y)]);
    //    console.log(newPos);
    //    //newPos.unproject()
    //    this.render();
    //}

    //private handleContextMenuEvent(e: MouseEvent) {
    //}

    toggleVisibility(path: string[], isVisible?: boolean) {
        const level = this.hierarchy.get(path);
        level.visible = isVisible ?? !level.visible;
    }

    clear() {
        this.scene.clear();
        this.hidden.clear();
        this.hierarchy.clear();
    }

    /**
     * Same as addPolylines, but also connects last point with the first one.
     */
    *addContours(
        path: string[],
        config: RenderConfigForLine,
        lines: Polyline[],
    ) {
        const contours: Polyline[] = [];
        for (const line of lines) {
            if (line.length < 2) contours.push(line);
            const contour = [...line, line[0]];
            contours.push(contour);
        }
        yield* this.addPolylines(path, config, contours);
    }

    *addPolylines(
        path: string[],
        config: RenderConfigForLine,
        lines: Polyline[],
    ) {
        const vertices: number[] = [];
        for (const [chunk, idx] of IterUtils.indexed(
            IterUtils.splitIterIntoChunks(lines, 10_000),
        )) {
            for (const line of chunk) {
                createVerticesFromPolyline(line, config.thickness, vertices);
            }
            if (idx > 0)
                yield Yield.NextFrame;
        }
        setZOnVertices(vertices, LINE_PRIORITY_OFFSET + config.priority);
        const verticesAsFloatArray = new Float32Array(vertices);
        const geometry = new T.BufferGeometry();
        geometry.setAttribute(
            'position',
            new T.BufferAttribute(verticesAsFloatArray, 3),
        );
        const material = new T.MeshBasicMaterial({
            color: new T.Color(config.color),
            side: T.DoubleSide,
        });
        const line = new T.Mesh(geometry, material);
        this.scene.add(line);
        this.hierarchy.add(path, line);
    }

    private createInstancedMeshFromEquipment(
        type: DC_CNSTS.NodeName,
        copiesAmount: number,
    ) {
        const meshDescription = meshPerNodeType[type];
        let geometry: T.BufferGeometry;
        switch (meshDescription.geo.type) {
            case EquipmentGeometry.Box:
                geometry = new T.BoxGeometry(
                    meshDescription.geo.side,
                    meshDescription.geo.side,
                    0,
                );
                break;
            case EquipmentGeometry.Circle:
                geometry = new T.CircleGeometry(
                    meshDescription.geo.radius,
                    10,
                );
                break;
            case EquipmentGeometry.Triangle:
                geometry = new T.CircleGeometry(
                    meshDescription.geo.side,
                    3,
                    Math.PI/2,
                );
                break;
            default:
                throw new Error(`mesh type ${type} is not supported`);
        }
        const material = new T.MeshBasicMaterial({
            color: new T.Color(meshDescription.color).getHex(),
        });
        return new T.InstancedMesh(geometry, material, copiesAmount);
    }

    *addEquipment(
        path: string[],
        type: DC_CNSTS.NodeName,
        positions: Vector2[],
    ) {
        const mesh = this.createInstancedMeshFromEquipment(
            type, positions.length,
        );
        const meshDescription = meshPerNodeType[type];
        for (const chunk of IterUtils.splitIterIntoChunks(
            positions.entries(), 10_000,
        )) {
            for (const [idx, position] of chunk) {
                const tmpGeo = new T.Group();
                tmpGeo.position.set(
                    position.x,
                    position.y,
                    EQUIPMENT_PRIORITY_OFFSET + meshDescription.priority,
                );
                tmpGeo.updateMatrix();
                mesh.setMatrixAt(idx, tmpGeo.matrix);
            }
            yield Yield.NextFrame;
        }
        this.scene.add(mesh);
        this.hierarchy.add(path, mesh);
    }

    setCanvasSize(width: number, height: number) {
        const oldSize = new T.Vector2();
        this.renderer.getSize(oldSize);
        const oldWidth = oldSize.x;
        const oldHeight = oldSize.y;
        const oldAspect = oldWidth / oldHeight;
        const frustum = this.camera.right * 2 / oldAspect;
        const aspect = width / height;
        this.camera.left = frustum * aspect / -2;
        this.camera.right = frustum * aspect / 2;
        this.camera.top = frustum / 2;
        this.camera.bottom = frustum / -2;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(width, height);
        this.render();
    }

    focus(val: Vector2) {
        this.camera.position.setX(val.x);
        this.camera.position.setY(val.y);
    }

    setCamRot(val: number) {
        this.camRot = val % (Math.PI*2);
    }

    render() {
        this.hidden.clear();
        this.hierarchy.traverse((x, path) => {
            // if current group is hidden, then
            if (!x.visible) {
                // put group to hidden
                this.hidden.add(path.join('|'));
                // make all childs hidden
                x.traverse(y => {
                    y.items.forEach(x => x.visible = false);
                    return false;
                });
                // stop branch traversing
                return true;
            }
            // set current item visibility
            x.items.forEach(y => y.visible = x.visible);
            // continue traversing
            return false;
        });
        this.camera.rotation.z = this.camRot;
        this.renderer.render(this.scene, this.camera);
    }

}
