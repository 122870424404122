import { CostModel as CM } from "bim-ts";
import { UiSettings } from "../types";
import { AllColIds, GridApiType, GridConfigType } from "./types";
import { IterUtils } from "engine-utils-ts";

export function populateAgGridTable(params: {
    costModel: CM.CostModel;
    uiSettings: UiSettings;
    selectedTable: CM.CostTable;
    gridConfig: GridConfigType;
    gridApi: GridApiType;
    focusOnCategory?: CM.IdCostCategory;
}) {
    const table = params.selectedTable;
    const hierarchy = CM.CostHierarchy.clone(table.hierarchy);
    const settings = table.settings;

    let flattened = hierarchy.flattenCostHierarchy();

    if (params.uiSettings.isReadonly) {
        const filtered: CM.FlattenedCostCategoryParams[] = [];
        for (const item of flattened) {
            if (hierarchy.ignored.has(item.categoryId)) {
                continue;
            }
            const category = hierarchy.categories.get(item.categoryId);
            hierarchy.disableEdit.add(item.categoryId);
            if (!category) {
                continue;
            }
            if (CM.findCostCategoryTotalCost(category) === 0) {
                // do not show zero categories in readonly
                continue;
            }
            filtered.push(item);
        }
        flattened = filtered;
    }

    // add echart placeholder
    if (params.selectedTable.echarts) {
        flattened.push({
            categoryId: -1,
            isBottom: false,
            nestLevel: -1,
            path: [],
        });
    }

    params.gridConfig.context.hierarchy = hierarchy;
    params.gridConfig.context.totalDCWatt = params.costModel.totalWattDC;
    params.gridConfig.context.echarts = params.selectedTable.echarts;

    params.gridApi?.setColumnsVisible(AllColIds, true);

    const hiddenColumns = new Set<string>([
        ...CM.HiddableColumnGroup.getRecursiveColumns(
            ...settings.hiddenColumnGroupIds.map((x) => x.value),
        ),
        ...table.alwaysHiddenColumns,
    ]);
    params.gridApi?.setColumnsVisible(Array.from(hiddenColumns), false);

    const roots = hierarchy.getRootCategories();
    setCostCategoryRows(flattened, params.gridApi, {
        bottomPin: roots.length === 1 ? roots.map((x) => x[0]) : undefined,
    });

    params.gridApi.resetRowHeights();

    focusOnCategory: {
        if (params.focusOnCategory) {
            const indexToFocus = flattened.findIndex(
                (x) => x.categoryId === params.focusOnCategory,
            );
            if (indexToFocus < 0) {
                break focusOnCategory;
            }
            const flattenedCategory = flattened[indexToFocus];
            const row = params.gridApi.getRowNode(
                flattenedCategory.path.join(" | "),
            );
            if (!row) {
                break focusOnCategory;
            }
            params.gridApi.deselectAll();
            params.gridApi.setNodesSelected({ nodes: [row], newValue: true });
            params.gridApi.ensureNodeVisible(row, "middle");
        }
    }
}

export function setCostCategoryRows(
    flattened: CM.FlattenedCostCategoryParams[],
    api: GridApiType,
    params?: {
        bottomPin?: CM.IdCostCategory[];
    },
) {
    api.setGridOption("pinnedBottomRowData", []);

    if (params?.bottomPin?.length) {
        // pin top category to bottom
        const pinnedBottomRows: CM.FlattenedCostCategoryParams[] = [];
        const normalRows: CM.FlattenedCostCategoryParams[] = [];
        for (const f of flattened) {
            if (params.bottomPin.includes(f.categoryId)) {
                pinnedBottomRows.push(f);
            } else {
                normalRows.push(f);
            }
        }
        api.setGridOption("rowData", normalRows);
        api.setGridOption("pinnedBottomRowData", pinnedBottomRows);
    } else {
        api.setGridOption("rowData", flattened);
    }
    api.refreshCells({ force: true, suppressFlash: true });
}
