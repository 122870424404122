<script lang="ts">
    import NumberProperty from './NumberProperty.svelte';
    import { PUI_PropertyNodeNumber, type PUI_PropertyNodeVec3 } from 'ui-bindings';

    export let property: PUI_PropertyNodeVec3;

    const compNames = ['x', 'y', 'z'] as const;
    let compProps: PUI_PropertyNodeNumber[] = []
    $: if (property && property.value) {
        compProps = compNames.map(compName => {
            const compProp = new PUI_PropertyNodeNumber({
               value: property.value[compName],
               name: (compName === 'x' ? property.name : '') + ' ' + compName.toUpperCase(),
               step: property.step,
               unit: property.unit,
               parent: property.parent!,
               onChange: (newVal: number) => {
                    if (isNaN(newVal)) return
                    const newV = property.value.clone()
                    newV[compName] = newVal
                    if (newV.distanceTo(property.value) >= property.step) {
                        property.value = newV; // trigger update by
                    }
               }
            })
            return compProp
        })
    }
</script>
<style>
    div {
        flex: 1;
    }
</style>

<div>
    {#each compProps as compProp}
        <NumberProperty
            sourceProperty={compProp}
        />
    {/each}
</div>
