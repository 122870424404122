import { isInNode, ObservableObject } from 'engine-utils-ts';
import type { Bim } from '..';



export class EnergyCalculationsEnabled {
    constructor(
        public isEnabled: boolean
    ) {
    }

    static register(bim: Bim) {
        const state = new EnergyCalculationsEnabled(false);
        state.isEnabled = isInNode() ? true : localStorage.getItem(EnergyCalculationsEnabled.name) === 'true';

        const obs = new ObservableObject<EnergyCalculationsEnabled>({
            identifier: EnergyCalculationsEnabled.name,
            initialState: state,
        });
        obs.observeObject({
            settings: { doNotNotifyCurrentState: true },
            onPatch: ({ currentValueRef }) => {
                if(!isInNode()){
                    localStorage.setItem(EnergyCalculationsEnabled.name, currentValueRef.isEnabled.toString());
                }
            },
        });
        bim.runtimeGlobals.registerByIdent(EnergyCalculationsEnabled.name, obs);
    }
}
