import { Vector2 } from 'math-ts';
import type { Circuit } from '../generator/models/circuit';
import type { Node } from '../generator/models/node';
import type {
    RenderConfigForLine} from './constants';
import {
    priorityByPairingType, renderConfigPerCable,
    stringContourRenderConfig,
    trackerContourRenderConfig,
} from './constants';
import { Operations } from './operations';
import { createRectangleOfWidth } from '../vectors';
import Color from 'color';
import type { CablePairingType, Route } from '../generator/models/route';
import type { ObjectSelector, Polyline, SceneObjectId } from './types';
import type { DC_CNSTS, IdBimScene } from 'bim-ts';
import type { TasksRunner } from 'engine-utils-ts';
import { LazyBasic } from 'engine-utils-ts';
import { DefaultMapObjectKey, IterUtils, Yield } from 'engine-utils-ts';
import { type UiBindings, NotificationDescription, NotificationType } from 'ui-bindings';
import { notificationSource } from '../Notifications';

export interface State {
    hidden: Set<string>;
}

export class Visualizer extends LazyBasic<State> {
    private _circuit?: Circuit;
    private sceneMidPoint: Vector2 = new Vector2();
    canvas: Operations;
    private sceneSelectors:
        DefaultMapObjectKey<ObjectSelector, SceneObjectId[]>;
    get circuit(): Circuit {
        if (!this._circuit)
            throw new Error('circuit was not set');
        return this._circuit;
    }

    constructor(
        canvas: HTMLCanvasElement,
        private readonly taskRunner: TasksRunner,
        private readonly uiBindings: UiBindings,
    ) {
        super('visualizer', { hidden: new Set() });
        this.canvas = new Operations(canvas);
        this.sceneSelectors = new DefaultMapObjectKey({
            unique_hash: x => JSON.stringify(x),
            valuesFactory: () => [],
        });
    }

    private createState(): State {
        return { hidden: new Set(this.canvas.hidden) };
    }

    setCircuit(val: Circuit) {
        this._circuit = val;
        this.handleCircuitUpdate();
    }

    toggleVisibility(path: string[], value?: boolean) {
        this.canvas.toggleVisibility(path, value);
        this.updateView();
    }

    private updateView() {
        this.canvas.render();
        this.forceUpdate(this.createState());
    }

    private *addNodes() {
        let pointCount = 0;
        const summedPosition = new Vector2();
        for (const [step, nodes] of this.circuit.nodes.perStepName) {
            const positions =
                Array.from(nodes.values()).map(x => x.actualPosition);
            positions.forEach(x => summedPosition.add(x));
            pointCount += positions.length;
            yield* this.canvas.addEquipment(
                ['equipment', step],
                step,
                positions,
            );
        }
        this.sceneMidPoint = summedPosition.multiplyScalar(1 / pointCount);
        this.setDefaultFocus();
    }
    private setDefaultFocus() {
         const roots = Array.from(this.circuit.nodes.roots);
         if (!roots.length) throw new Error('No nodes found to display');
         this.canvas.focus(roots[0].actualPosition);
    }

    private *addUniqueRoutesGroup(
        path: string[],
        conductorType: DC_CNSTS.ConductorType,
        pair: CablePairingType,
        routes: Route[],
    ) {
        const pairingTypePriority = priorityByPairingType[pair];
        const rootConfig = renderConfigPerCable[conductorType];
        const renderConfig: RenderConfigForLine = {
            priority: rootConfig.priority + pairingTypePriority + 1,
            color: Color(rootConfig.color)
                .darken(0.05 * (pairingTypePriority + 1))
                .hex(),
            thickness:
                rootConfig.thickness *
                Math.pow(0.5, pairingTypePriority + 1),
        };
        yield* this.canvas.addPolylines(
            path,
            renderConfig,
            routes.map(x => x.points),
        );
    }

    private *addRoutes() {
        const map = this.circuit.groupingRoutes.detailsPerCondType;
        for (const [type, perType] of map) {
            for (const [gauge, perGauge] of perType.detailsPerGauge) {
                for (const [pair, perPair] of perGauge.detailsPerPairingType) {
                    for (const [len, perLen] of perPair.detailsPerLength) {
                        yield* this.addUniqueRoutesGroup(
                            ['cable', type, gauge.toString(), pair, len.toString()],
                            type, pair, perLen.routes,
                        );
                    }
                }
            }
        }
    }

    private *addStrings() {
        const set = this.circuit.nodes.perStepName.getOrCreate('string_exit');
        const contours: Polyline[] = [];
        for (const chunk of IterUtils.splitIterIntoChunks(set, 10_000)) {
            for (const string of chunk) {
                const contour = this.getContourFromString(string);
                contours.push(contour);
            }
            yield Yield.NextFrame;
        }
        yield* this.canvas.addContours(
            ['equipment', 'string_exit'],
            stringContourRenderConfig,
            contours,
        );
    }

    private *addTrackers() {
        const contours: Polyline[] = [];
        for (const chunk of IterUtils.splitIterIntoChunks(
            this.circuit.bimOps.trackerConfigs.keys(),
            3000,
        )) {
            for (const id of chunk) {
                const contour = this.getContourFromTracker(id);
                contours.push(contour);
            }
            yield Yield.NextFrame;
        }
        yield* this.canvas.addContours(
            ['equipment', 'string_exit'],
            trackerContourRenderConfig,
            contours,
        );
    }

    private *_handleCircuitUpdate() {
        this.canvas.clear();
        this.sceneSelectors.clear();
        yield* this.addNodes();
        yield Yield.NextFrame;
        yield* this.addRoutes();
        yield Yield.NextFrame;
        yield* this.addStrings();
        yield Yield.NextFrame;
        yield* this.addTrackers();
        this.canvas
            .setCamRot(-this.circuit.bimOps.globalTrackerAngle);
        this.updateView();
    }
    async handleCircuitUpdate() {
        const task = this.taskRunner.newLongTask<void>({
            defaultGenerator: this._handleCircuitUpdate(),
            taskTimeoutMs: 600_000,
        });
        try {
            this.uiBindings.addNotification(
                NotificationDescription.newWithTask({
                    source: notificationSource,
                    key: 'generateVisuals',
                    taskDescription: { task },
                    type: NotificationType.Info,
                    addToNotificationsLog: true
                }),
            );
            await task.asPromise();
        } catch (e) {
            this.uiBindings.addNotification(
                NotificationDescription.newBasic({
                    source: notificationSource,
                    key: 'generateVisualsError',
                    descriptionArg: e.message,
                    type: NotificationType.Error,
                    removeAfterMs: 10_000,
                    addToNotificationsLog: true
                }),
            );
        }
    }

    private getContourFromString(string: Node) {
        string.assertsSi();
        const config = this.circuit.bimOps.trackerConfigs
            .getOrCreate(string.si.id);
        // because strings might be offsetted from tracker x coord,
        // generate contour based on string y coords and tracker x coords.
        const width = config.tracker.maxWidth;
        const zeroV2 = Vector2.zero();
        const [
            fromString,
            toString,
            trackerMin,
            trackerMax,
        ] = [
            string.hints[0],
            string.hints[1],
            config.tracker.maxPoint,
            config.tracker.minPoint,
        ].map(x => x.clone().rotateAround(zeroV2, -config.rotationZ));
        const box = createRectangleOfWidth(
            fromString.setX(trackerMin.x),
            toString.setX(trackerMax.x),
            width,
        ).map(x => x.rotateAround(zeroV2, config.rotationZ));
        return box;
    }

    private getContourFromTracker(id: IdBimScene) {
        const config = this.circuit.bimOps.trackerConfigs
            .getOrCreate(id);
        const [from, to] = config.tracker.hints;
        return createRectangleOfWidth(
            from,
            to,
            config.tracker.maxWidth,
        );
    }

}
