// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class TerrainTileId implements flatbuffers.IUnpackableObject<TerrainTileIdT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):TerrainTileId {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsTerrainTileId(bb:flatbuffers.ByteBuffer, obj?:TerrainTileId):TerrainTileId {
  return (obj || new TerrainTileId()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsTerrainTileId(bb:flatbuffers.ByteBuffer, obj?:TerrainTileId):TerrainTileId {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new TerrainTileId()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

x():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

y():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

static startTerrainTileId(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addX(builder:flatbuffers.Builder, x:number) {
  builder.addFieldInt32(0, x, 0);
}

static addY(builder:flatbuffers.Builder, y:number) {
  builder.addFieldInt32(1, y, 0);
}

static endTerrainTileId(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createTerrainTileId(builder:flatbuffers.Builder, x:number, y:number):flatbuffers.Offset {
  TerrainTileId.startTerrainTileId(builder);
  TerrainTileId.addX(builder, x);
  TerrainTileId.addY(builder, y);
  return TerrainTileId.endTerrainTileId(builder);
}

unpack(): TerrainTileIdT {
  return new TerrainTileIdT(
    this.x(),
    this.y()
  );
}


unpackTo(_o: TerrainTileIdT): void {
  _o.x = this.x();
  _o.y = this.y();
}
}

export class TerrainTileIdT implements flatbuffers.IGeneratedObject {
constructor(
  public x: number = 0,
  public y: number = 0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return TerrainTileId.createTerrainTileId(builder,
    this.x,
    this.y
  );
}
}
