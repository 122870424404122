import { BimPatch, IdBimScene, Bim, SceneInstance, ProjectionInfo } from 'bim-ts';
import { WgsProjectionOrigin, BimSceneOrigin } from 'bim-ts';
import { Yield } from 'engine-utils-ts';
import { IterUtils } from 'engine-utils-ts';
import { Matrix4, Vector3 } from 'math-ts';
import type { FileImporter, FileImporterContext, FileToImport} from 'ui-bindings';
import { NotificationDescription, NotificationType } from 'ui-bindings';
import { notificationSource } from '../Notifications';
import { parseFile } from './kmzParser';
import { KmlParseResult, getAllGeometry, getKmlOrigin } from './kmlDataModel';
import { allocateGeometryRecursively } from './kmlGeometryAllocator';
import { WGSConverter } from '../WGSConverter';


export class KmzImporter implements FileImporter {

    fileExtensions = [".kmz", ".kml"];

    constructor(
        readonly bim: Bim
    ) {
    }

    *startImport(context: FileImporterContext, file: FileToImport): Generator<Yield, void> {
			const parseResult = yield *this.importFile(file, context);
			let message = '';

			if(parseResult!=null){
				const geometry = getAllGeometry(parseResult);
				message = `loaded ${geometry.polylines.length} lines, `+
				`${geometry.polygons.length} poligons`;
			}

			if(parseResult==null){
				message = `File didn't load`;
			}

			const disc = NotificationDescription.newBasic({
				type:NotificationType.Info,
				source: notificationSource,
				key: 'fileLoad',
				descriptionArg: message,
				removeAfterMs:5000,
				addToNotificationsLog: true
			})

			context.sendNotification(disc);
    }


	GetLayoutOrigin(layout: KmlParseResult, context: FileImporterContext): BimSceneOrigin {

        const sceneOrigin = this.bim.instances.getSceneOrigin();
        
		const layoutBimOrigin = new BimSceneOrigin();
		const layoutWgsOrigin = getKmlOrigin(layout);

		if(layoutWgsOrigin == null){
			throw new Error("Cant find the origin of the imported file")
		}

        if(sceneOrigin?.cartesianCoordsOrigin!=null && sceneOrigin.wgsProjectionOrigin == null){
			context.logger.info(`Geodetic origin ${layoutWgsOrigin} for the new project has been automatically set, the positions of imported objects may be incorrect!`);
			layoutBimOrigin.cartesianCoordsOrigin = sceneOrigin.cartesianCoordsOrigin;
			layoutBimOrigin.wgsProjectionOrigin = new WgsProjectionOrigin(layoutWgsOrigin, new ProjectionInfo());

		}else if(sceneOrigin?.cartesianCoordsOrigin!=null && sceneOrigin?.wgsProjectionOrigin != null){

			layoutBimOrigin.cartesianCoordsOrigin = sceneOrigin.cartesianCoordsOrigin;
			layoutBimOrigin.wgsProjectionOrigin = sceneOrigin.wgsProjectionOrigin;

		}else if(sceneOrigin?.cartesianCoordsOrigin==null && sceneOrigin?.wgsProjectionOrigin == null){

			layoutBimOrigin.cartesianCoordsOrigin = new Vector3();
			layoutBimOrigin.wgsProjectionOrigin = new WgsProjectionOrigin(layoutWgsOrigin, new ProjectionInfo());	

		}else{
			context.logger.error("Geodetic origin presents, but no Cartesian reference!");
           
			layoutBimOrigin.cartesianCoordsOrigin = new Vector3();
			layoutBimOrigin.wgsProjectionOrigin = sceneOrigin.wgsProjectionOrigin;
		}
        
        return layoutBimOrigin;
    }


	BuildBimPatch(sceneOrigin: BimSceneOrigin): BimPatch {

        let bimPatch = new BimPatch();
        bimPatch.sceneOrigin = sceneOrigin;
        
        return bimPatch;
    }

	*importFile(file: FileToImport, context: FileImporterContext) {

		const parseResult = parseFile(file, context);
		if (parseResult == null) {
			throw new Error(`failed to parse the kml file`);
		}

		yield Yield.Asap;

		const allocatedSceneIstances: IdBimScene[] = [];

		const origin = getKmlOrigin(parseResult);
		// evaluate different projections
		if (origin == null) {
			throw new Error('invalid data, no origin');
		}

		const layoutSceneOrigin = this.GetLayoutOrigin(parseResult, context);
		let bimPatch = new BimPatch();
		bimPatch.sceneOrigin = layoutSceneOrigin;

		const basePoint = bimPatch.sceneOrigin.wgsProjectionOrigin!;
		const matrixes = new Map<IdBimScene, Matrix4>();

		const datum = WGSConverter.getDatum(basePoint);

		const folderInstanceId = this.bim.instances.reserveNewId();
		const folderInstance = new SceneInstance();
		folderInstance.name = parseResult.name ?? file.filename;
		bimPatch.instances.toAlloc.push([folderInstanceId, folderInstance]);

		const allocatedGeometry = allocateGeometryRecursively(this.bim, datum, parseResult, parseResult.elements, folderInstanceId);
		if (allocatedGeometry != null) {
			bimPatch.mergeWith(allocatedGeometry.bimPatch);
			for (const matrix of allocatedGeometry.matixes) {
				matrixes.set(matrix[0], matrix[1]);
			}
		}

		yield Yield.Asap;

		IterUtils.extendArray(allocatedSceneIstances, bimPatch.applyTo(this.bim));

		yield Yield.Asap;

		this.bim.instances.patchWorldMatrices(matrixes);

		yield Yield.Asap;

		this.bim.instances.setSelected(allocatedSceneIstances);

		yield* context.onFinish(allocatedSceneIstances);

		return parseResult;
	}
	
}








