import type { GeometryGpuId } from '../geometries/GpuGeometries';
import { geoGpuIdHash14Bits } from '../geometries/GpuGeometries';
import type {
    EngineMaterialIdFlags} from '../pools/EngineMaterialId';
import { GetMaterialFlagsFromIDF, GetMaterialIDF, getMaterialIdFromIDF
} from '../pools/EngineMaterialId';
import type { ShaderFlags } from '../shaders/ShaderFlags';
import type { HashedUniforms } from './DynamicUniforms';
import { EmptyHashedUniforms } from './DynamicUniforms';

// export class RenderJobsMerged {

// 	readonly materialId: MaterialId;
// 	readonly geometryId: GeometryGpuId;

// 	indices: number[] = [];
// }

// export interface RenderObjectsData {
// 	culling: Uint8Array;
// 	transforms: KrVectorsGateway;
// }
// export function renderJobsSort(rj1: RenderJob, rj2: RenderJob): number {
// 	return rj1.job_hash_key - rj2.job_hash_key;
// }


export class RenderJob {

	object_index: number; // used to lookup culling and transform
	object_lod: number;
	materialIDF: EngineMaterialIdFlags;
	geometryId: GeometryGpuId;
	dynamicUniforms: HashedUniforms;

	constructor(
		object_index: number,
		object_lod: number,
		materialId: EngineMaterialIdFlags,
		geometry: GeometryGpuId, // temp
		dynamicUniforms?: HashedUniforms,
		// dynamicUniformsHash: number,
		// offsetIndex: number,
	) {
		this.object_index = object_index;
		this.object_lod = object_lod;
		this.materialIDF = materialId;
		this.geometryId = geometry;
		this.dynamicUniforms = dynamicUniforms ?? EmptyHashedUniforms;
		// this.offsetIndex = offsetIndex;
	}

	can_merge(other: RenderJob) {
		// return this.job_hash_key === other.job_hash_key && this.dynamicUniforms === other.dynamicUniforms;
		return this.geometryId === other.geometryId
			&& this.materialIDF == other.materialIDF
			&& this.dynamicUniforms === other.dynamicUniforms
			&& this.object_lod === other.object_lod;
	}

	static hash(self: RenderJob): number {
		const materialFlags = GetMaterialFlagsFromIDF(self.materialIDF);
		const materialId = getMaterialIdFromIDF(self.materialIDF);
		const materialHash = (materialId & 0xFF) << 8 | (materialFlags & 0xF); // 12 bits
		
		const uniformsHash = self.dynamicUniforms.hash & 0x1F; // 5 bits
	
		const geoHash = geoGpuIdHash14Bits(self.geometryId) & 0x3FFF; // 14 bits
	
		return (materialHash << (14 + 5)) | (geoHash << 5) | uniformsHash;
	}
}

export interface RenderJobBase {
	geometryGpuId: GeometryGpuId,
	materialIDF: EngineMaterialIdFlags,
	dynamicUniforms: HashedUniforms
}

export class RenderJobsPool {

	readonly rjs: RenderJob[] = [];
	_used_counter: number = 0;

	static getTemporary(): RenderJobsPool {
		__rjsPoolReused.reset();
		return __rjsPoolReused;
	}

	constructor(size_hint: number) {
		for (let i = 0; i < size_hint; ++i) {
			this.rjs.push(new RenderJob(0, 0, 0, 0));
		}
	}

	reset() {
		this._used_counter = 0;
	}

	getWithoutUniforms(objectIndex: number, lod: number, sourceRj:RenderJobBase, material: EngineMaterialIdFlags): RenderJob {
		if (this._used_counter == this.rjs.length) {
			this.allocate_more();
		}
		const rj = this.rjs[this._used_counter];
		this._used_counter += 1;

		rj.object_index = objectIndex;
		rj.object_lod = lod;
		rj.geometryId = sourceRj.geometryGpuId;
		rj.materialIDF = material;
		return rj;
	}

	getWithFlags(objectIndex: number, lod:number, sourceRj: RenderJobBase, flags: ShaderFlags): RenderJob {
		if (this._used_counter == this.rjs.length) {
			this.allocate_more();
		}
		const rj = this.rjs[this._used_counter];
		this._used_counter += 1;

		const materialId = getMaterialIdFromIDF(sourceRj.materialIDF);
		const materialFlags = GetMaterialFlagsFromIDF(sourceRj.materialIDF) | flags;
		
		rj.object_index = objectIndex;
		rj.object_lod = lod;
		rj.geometryId = sourceRj.geometryGpuId;
		rj.materialIDF = GetMaterialIDF(materialId, materialFlags);
		rj.dynamicUniforms = sourceRj.dynamicUniforms;
		return rj;
	}

	getWithLod(objectIndex: number,lod:number, sourceRj: RenderJobBase): RenderJob {
		if (this._used_counter == this.rjs.length) {
			this.allocate_more();
		}
		const rj = this.rjs[this._used_counter];
		this._used_counter += 1;

		rj.object_index = objectIndex;
		rj.object_lod = lod;
		rj.geometryId = sourceRj.geometryGpuId;
		rj.materialIDF = sourceRj.materialIDF;
		rj.dynamicUniforms = sourceRj.dynamicUniforms;
		return rj;
	}

	allocate_more() {
		for (let i = 0; i < 1000; ++i) {
			this.rjs.push(new RenderJob(0, 0, 0, 0));
		}
	}
}

const __rjsPoolReused = new RenderJobsPool(0);



function GetGeoGpuIdHash14Bits(geometryId: GeometryGpuId) {
	throw new Error('Function not implemented.');
}
// export class RenderJobsMerge {
// 	renderJob: RenderJob;
// 	positions: number[];
// }
