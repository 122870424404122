import type { Lines } from "../../components/Lines";

export const enum TEXT_ALIGN_V {
	TOP = 0,
	CENTER = 1,
	BOTTOM = 2
}


export function textAlignV( lines: Lines, ALIGNMENT: TEXT_ALIGN_V, INNER_HEIGHT: number ) {
	let offsetY = 0;
    switch ( ALIGNMENT ) {
        case TEXT_ALIGN_V.TOP:
            return;
		case TEXT_ALIGN_V.CENTER:
            offsetY = ( INNER_HEIGHT - lines.height) / 2;
            if (offsetY < 0) {
                return;
            }
            break;
		case TEXT_ALIGN_V.BOTTOM:
            offsetY = INNER_HEIGHT - lines.height;
            if (offsetY < 0) {
                return;
            }
            break;
    }
    
    for ( let i = 0; i < lines.lines.length; i++ ) {
		const line = lines.lines[ i ];

		for ( let j = 0; j < line.inlines.length; j++ ) {
			line.inlines[ j ].offsetY -= offsetY;
		}

		line.y -= offsetY;
	}
}