// automatically generated by the FlatBuffers compiler, do not modify

import type * as flatbuffers from 'flatbuffers';

import type { Vector2T } from './vector2.js';
import { Vector2 } from './vector2.js';


export class Aabb2 implements flatbuffers.IUnpackableObject<Aabb2T> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Aabb2 {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

min(obj?:Vector2):Vector2|null {
  return (obj || new Vector2()).__init(this.bb_pos, this.bb!);
}

max(obj?:Vector2):Vector2|null {
  return (obj || new Vector2()).__init(this.bb_pos + 16, this.bb!);
}

static sizeOf():number {
  return 32;
}

static createAabb2(builder:flatbuffers.Builder, min_x: number, min_y: number, max_x: number, max_y: number):flatbuffers.Offset {
  builder.prep(8, 32);
  builder.prep(8, 16);
  builder.writeFloat64(max_y);
  builder.writeFloat64(max_x);
  builder.prep(8, 16);
  builder.writeFloat64(min_y);
  builder.writeFloat64(min_x);
  return builder.offset();
}


unpack(): Aabb2T {
  return new Aabb2T(
    (this.min() !== null ? this.min()!.unpack() : null),
    (this.max() !== null ? this.max()!.unpack() : null)
  );
}


unpackTo(_o: Aabb2T): void {
  _o.min = (this.min() !== null ? this.min()!.unpack() : null);
  _o.max = (this.max() !== null ? this.max()!.unpack() : null);
}
}

export class Aabb2T implements flatbuffers.IGeneratedObject {
constructor(
  public min: Vector2T|null = null,
  public max: Vector2T|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return Aabb2.createAabb2(builder,
    (this.min?.x ?? 0),
    (this.min?.y ?? 0),
    (this.max?.x ?? 0),
    (this.max?.y ?? 0)
  );
}
}
