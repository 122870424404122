import type { Bim, NumberProperty } from "src";
import type {
    CalculateLCOEInput,
    CreateLcoeHierarchyResult,
    LCOECalculationResult,
    LCOELayoutInput,
} from "..";
import { CostHierarchy, calculateLCOE } from "..";
import type { LazyVersioned } from "engine-utils-ts";
import { LazyDerivedAsync } from "engine-utils-ts";
import { createEconomicHierarchy } from "../hierarchy/economics";
import { createNpvOfCostHierarchy } from "../hierarchy/npvOfCost";
import { createNpvOfEnergyHierarchy } from "../hierarchy/npvOfEnergyProduction";
import type { LcoeSettings } from "../LcoeSettings";
import {
    LcoeSettingsProvider,
    createLcoeSettingsDefault,
} from "../LcoeSettings";
import { createTotalDCLazy } from "src/archetypes/substation/utils";

export function createLcoeCostHierarchyLazy(
    bim: Bim,
    input: LazyVersioned<LCOELayoutInput>,
) {
    const defaultLcoeSettings = createLcoeSettingsDefault();
    const lcoeSettingsProvider = new LcoeSettingsProvider(bim);

    const totalDC = createTotalDCLazy(bim);
    const lcoeCalculation = LazyDerivedAsync.new3<
        LCOECalculationResult,
        LcoeSettings,
        LCOELayoutInput,
        NumberProperty
    >(
        "lcoeCalculationRoot",
        [],
        [lcoeSettingsProvider.lcoeSettings, input, totalDC],
        function* ([ops, _input, totalDC]) {
            const defOpts = defaultLcoeSettings;
            const capitalCost = _input.capitalCost;
            const input: CalculateLCOEInput = {
                capitalCost: {
                    totalCost: capitalCost ?? 0,
                },
                debtCost: {
                    debtTermYears:
                        ops.debtCost.debtTermYears?.value ??
                        defOpts.debtCost.debtTermYears?.value ??
                        0,
                    interestRateOnDebtPercent:
                        ops.debtCost.interestRateOnDebtPercent?.value ??
                        defOpts.debtCost.interestRateOnDebtPercent?.value ??
                        0,
                    debtSizeAsPercentOfCapitalCost:
                        ops.debtCost.debtSizeAsPercentOfCapitalCost?.value ??
                        defOpts.debtCost.debtSizeAsPercentOfCapitalCost
                            ?.value ??
                        0,
                    lenderFeePercentOfDebt:
                        ops.debtCost.lenderFeePercentOfDebt?.value ??
                        defOpts.debtCost.lenderFeePercentOfDebt?.value ??
                        0,
                },
                lcoe: {
                    annualElectricityOutputWattHour: _input.annualOutput,
                    annualProductionDegradationPercent:
                        ops.lcoe.annualProductionDegradationPercent?.value ??
                        defOpts.lcoe.annualProductionDegradationPercent
                            ?.value ??
                        0,
                    projectUsefulLifeYears:
                        ops.lcoe.projectUsefulLifeYears?.value ??
                        defOpts.lcoe.projectUsefulLifeYears?.value ??
                        0,
                    discountRatePercent:
                        ops.lcoe.discountRatePercent?.value ??
                        defOpts.lcoe.discountRatePercent?.value ??
                        0,
                    operationGrowthRatePercent:
                        ops.lcoe.operationGrowthRatePercent?.value ??
                        defOpts.lcoe.operationGrowthRatePercent?.value ??
                        0,
                },
                operatingCost: {
                    operationAndMaintenanceCostPerWattPerYear:
                        ops.operatingCost
                            .operationAndMaintenanceCostPerWattPerYear?.value ??
                        defOpts.operatingCost
                            .operationAndMaintenanceCostPerWattPerYear?.value ??
                        0,
                    generalAndAdministrativePerWattPerYear:
                        ops.operatingCost.generalAndAdministrativePerWattPerYear
                            ?.value ??
                        defOpts.operatingCost
                            .generalAndAdministrativePerWattPerYear?.value ??
                        0,
                    insuranceCostAsPercentOfCapitalCostPerYear:
                        ops.operatingCost
                            .insuranceCostAsPercentOfCapitalCostPerYear
                            ?.value ??
                        defOpts.operatingCost
                            .insuranceCostAsPercentOfCapitalCostPerYear
                            ?.value ??
                        0,
                    propertyTaxAsPercentOfCapitalCostPerYear:
                        ops.operatingCost
                            .propertyTaxAsPercentOfCapitalCostPerYear?.value ??
                        defOpts.operatingCost
                            .propertyTaxAsPercentOfCapitalCostPerYear?.value ??
                        0,
                    landLeaseCostPerAcrePerYear:
                        ops.operatingCost.landLeaseCostPerAcrePerYear?.value ??
                        defOpts.operatingCost.landLeaseCostPerAcrePerYear
                            ?.value ??
                        0,
                },
                layout: {
                    wattDC: totalDC.value,
                    areaAcres:
                        ops.layout.areaAcres?.value ??
                        defOpts.layout.areaAcres?.value ??
                        0,
                },
            };
            const output = calculateLCOE(input);
            return { input, output };
        },
    );

    const economics = createEconomicHierarchy(
        lcoeCalculation,
        defaultLcoeSettings,
        lcoeSettingsProvider,
    );
    const npvOfCost = createNpvOfCostHierarchy(
        lcoeCalculation,
        defaultLcoeSettings,
        lcoeSettingsProvider,
    );
    const npvOfEnergy = createNpvOfEnergyHierarchy(
        lcoeCalculation,
        defaultLcoeSettings,
        lcoeSettingsProvider,
    );

    const hierarchy = LazyDerivedAsync.new4<
        CreateLcoeHierarchyResult,
        CostHierarchy,
        CostHierarchy,
        CostHierarchy,
        LCOECalculationResult
    >(
        "lcoe hierarchy",
        [],
        [economics, npvOfCost, npvOfEnergy, lcoeCalculation],
        function* ([economics, npvOfCost, npvOfEnergy, lcoeCalculation]) {
            const hierarchy = new CostHierarchy();
            hierarchy.merge(economics);
            hierarchy.merge(npvOfCost);
            hierarchy.merge(npvOfEnergy);

            hierarchy.addRoot({
                description: { value: "Levelised Cost of Energy (LCOE)" },
            });

            return {
                hierarchy,
                lcoe: lcoeCalculation,
            };
        },
    );

    return hierarchy;
}
