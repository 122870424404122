import type { IdBimScene } from "src"

export class ModuleTableDescription implements LooseComparison {
    text: string
    sceneIds: IdBimScene[]
    connectionToParent?: ConnectionDescription
    matrices: ModuleMatrixDescription[]
    tableCount: number

    constructor(params: {
        matrices: ModuleMatrixDescription[]
        tableCount: number
        text: string,
        sceneIds: IdBimScene[],
        connectionToParent?: ConnectionDescription,
    }) {
        this.text = params.text;
        this.sceneIds = params.sceneIds;
        this.connectionToParent = params.connectionToParent;
        this.matrices = params.matrices;
        this.tableCount = params.tableCount;
    }

    merge(other: ModuleTableDescription) {
        if (!this.canMerge(other)) {
            return false;
        }
        this.tableCount += other.tableCount;
        this.sceneIds.push(...other.sceneIds);
        return true;
    }

    static group(descriptions: ModuleTableDescription[]) {
        const uniqueItems: ModuleTableDescription[] = [];
        outer: for (const description of descriptions) {
            for (const unique of uniqueItems) {
                const mergeResult = unique.merge(description);
                if (mergeResult) {
                    continue outer;
                }
            }
            uniqueItems.push(description);
        }
        return uniqueItems;
    }

    canMerge(other: ModuleTableDescription) {
        if (this.text !== other.text) {
            return false;
        }
        if (this.matrices.length !== other.matrices.length) {
            return false;
        }
        for (let i = 0; i < this.matrices.length; i++) {
            if (!this.matrices[i].isLooseEqual(other.matrices[i])) {
                return false;
            }
        }
        return true;
    }

    isLooseEqual(other: ModuleTableDescription) {
        if (!this.canMerge(other)) {
            return false;
        }
        if (this.tableCount !== other.tableCount) {
            return false;
        }
        return true;
    }

}

export class ModuleMatrixDescription implements LooseComparison {
    stringCount: number
    modulesInString: number
    connectionToParent?: ConnectionDescription

    constructor(params: {
        stringCount: number
        modulesInString: number
        connectionToParent?: ConnectionDescription
    }) {
        this.stringCount = params.stringCount;
        this.modulesInString = params.modulesInString;
        this.connectionToParent = params.connectionToParent
    }

    isLooseEqual(other: ModuleMatrixDescription) {
        if (this.stringCount !== other.stringCount) {
            return false;
        }

        if (this.modulesInString !== other.modulesInString) {
            return false;
        }

        if (!this.connectionToParent !== !other.connectionToParent) {
            return false;
        }
        if (this.connectionToParent && other.connectionToParent) {
            if (!this.connectionToParent.isLooseEqual(other.connectionToParent)) {
                return false;
            }
        }

        return true;
    }

}

export class EquipmentDescription implements LooseComparison {
    connectionToParent?: ConnectionDescription
    text: string
    sceneId: IdBimScene

    constructor(params: {
        connectionToParent?: ConnectionDescription
        text: string
        sceneId: IdBimScene
    }) {
        this.connectionToParent = params.connectionToParent;
        this.text = params.text;
        this.sceneId = params.sceneId;
    }

    isLooseEqual(other: EquipmentDescription) {
        if (this.text !== other.text) {
            return false;
        }
        if (!this.connectionToParent !== !other.connectionToParent) {
            return false;
        }
        if (this.connectionToParent && other.connectionToParent) {
            if (!this.connectionToParent.isLooseEqual(other.connectionToParent)) {
                return false;
            }
        }
        return true;
    }

}

export class ConnectionDescription implements LooseComparison {
    text: string
    sceneId: IdBimScene

    constructor(params: {
        text: string
        sceneId: IdBimScene
    }) {
        this.text = params.text;
        this.sceneId = params.sceneId;
    }

    isLooseEqual(other: this) {
        return this.text === other.text;
    }
}

export abstract class EquipmentWithGroupedChildren<T extends LooseComparison>
    extends EquipmentDescription
{
    abstract getGroupedChildren(): T[][]

    constructor(params: ConstructorParameters<typeof EquipmentDescription>[0]) {
        super(params);
    }

    isLooseEqual(other: EquipmentWithGroupedChildren<T>) {
        if (!super.isLooseEqual(other)) {
            return false;
        }
        const lChildren = this.getGroupedChildren();
        const rChildren = other.getGroupedChildren();
        if (lChildren.length !== rChildren.length) {
            return false;
        }
        for (let i = 0; i < lChildren.length; i++) {
            const lGroup = lChildren[i];
            const rGroup = rChildren[i];
            if (lGroup.length !== rGroup.length) {
                return false;
            }
            for (let j = 0; j < lGroup.length; j++) {
                const lDesc = lGroup[j];
                const rDesc = rGroup[j];
                if (!lDesc.isLooseEqual(rDesc)) {
                    return false;
                }
            }
        }
        return true;
    }
}

export abstract class EquipmentWithChildren
    extends EquipmentDescription
{
    abstract getChildren(): LooseComparison[]

    constructor(params: ConstructorParameters<typeof EquipmentDescription>[0]) {
        super(params);
    }

    isLooseEqual(other: EquipmentWithChildren) {
        if (!super.isLooseEqual(other)) {
            return false;
        }
        const lChildren = this.getChildren();
        const rChildren = other.getChildren();
        if (lChildren.length !== rChildren.length) {
            return false;
        }
        for (let i = 0; i < lChildren.length; i++) {
            const l = lChildren[i];
            const r = rChildren[i];
            if (!l.isLooseEqual(r)) {
                return false;
            }
        }
        return true;
    }
}

export interface LooseComparison {
    isLooseEqual(other: this): boolean
}

export function groupByLooseComparison<T extends LooseComparison>(items: T[]) {
    const groups = new Map<T, T[]>();
    outer: for (const inverter of items) {
        for (const [item, array] of groups) {
            if (item.isLooseEqual(inverter)) {
                array.push(inverter);
                continue outer;
            }
        }
        groups.set(inverter, [inverter]);
    }
    return Array.from(groups.values());
}
