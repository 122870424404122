<script lang="ts">
  import { getContext } from 'svelte'
  import { onMount } from 'svelte'
  import {MDCMenuSurface} from '@material/menu-surface';
  import { applyProps } from './utils';
  import type { Bim } from 'bim-ts';

  let bim = getContext<Bim>('bim');

  export let path: string[];

  let el: Element
  let menu: MDCMenuSurface
  onMount(() => {
    menu = new MDCMenuSurface(el)
  })
  function toggleMenu() {
    if (menu.isOpen()){
      menu.close()
    }
    else{
      menu.open();
    }
  }
</script>


<style>
  .root {
    position: relative;
    display: flex;
  }
  .mdc-menu-surface--anchor {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .mdc-menu-surface{
    width: max-content;
  }
  .mdc-icon-button {
    width: 24px;
    height: 24px;
    padding: 0;
    margin-left: 12px;
    background-color: var(--mdc-theme-surface);
    border-radius: 100%;
  }
</style>

<div class="root" on:click|stopPropagation={() => null}>
  <button class="mdc-icon-button" on:click={toggleMenu}>
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/></svg>
  </button>
  <div class="mdc-menu-surface--anchor">
    <div bind:this={el} class="with-selects mdc-menu-surface">
      <ul class="mdc-deprecated-list">
        <li
          style="z-index: 10"
          class="mdc-deprecated-list-item"
          on:click={() => {
              applyProps(bim, path);
              menu.close();
          }}
        >
          <span class="mdc-deprecated-list-item__ripple"></span>
          <span class="mdc-deprecated-list-item__text">apply to all</span>
        </li>
      </ul>
    </div>
  </div>
</div>
