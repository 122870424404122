import type { BasicCollectionUpdates, ScopedLogger} from "engine-utils-ts";
import { DefaultMap, Deleted, IterUtils, ObservableStream, StreamAccumulator } from "engine-utils-ts";
import type { EntityId } from "verdata-ts";
import type { RenderTimeBudget } from "../frameComposer/RenderTimeBudget";


export interface GCedEntitiesUser<IdT, THandle> {
    getIdsToRetain(result: EntityId<IdT>[]): void;
    updatesStream: ObservableStream<BasicCollectionUpdates<THandle>>;
}

export interface GCedEntitiesCollection<IdT> {
    getEngineOwnedIds(result: Set<EntityId<IdT>>): void;
    delete(ids: EntityId<IdT>[]): void;
}


export class EngineResourcesGC {

    logger: ScopedLogger;
    perIdent = new DefaultMap<string, PerResourceRegistrations>((ident) => new PerResourceRegistrations(ident));

    constructor(logger: ScopedLogger) {
        this.logger = logger.newScope('gc');
    }

    registerGCedEntitiesCollection<IdT>(resourceIdent: string, coll: GCedEntitiesCollection<IdT>) {
        const registered = this.perIdent.getOrCreate(resourceIdent);
        registered.collections.push(coll);
    }

    registerGCedEntitiesUser<IdT, THandle>(resourceIdent: string, user: GCedEntitiesUser<EntityId<IdT>, THandle>) {
        const registered = this.perIdent.getOrCreate(resourceIdent);
        registered.addUser(user);
    }

    runIfShould(timeBudget: RenderTimeBudget) {
        for (const registered of this.perIdent.values()) {
            const shouldRun = registered.shouldRunGC(timeBudget);
            if (shouldRun) {
                registered.runGC(this.logger);
            }
        }
    }

    forceRun() {
        for (const registered of this.perIdent.values()) {
            registered.runGC(this.logger);
        }
    }
}

const DeletionsToTriggerGC = {
    lowerBound: 50,
    higherBound: 250,
}

class PerResourceRegistrations {
    
    readonly identifier: string;
    readonly collections: GCedEntitiesCollection<any>[] = [];
    readonly users: GCedEntitiesUser<any, any>[] = [];
    readonly allUsersUpdatesStream: ObservableStream<BasicCollectionUpdates<any>>;
    readonly accumulator: StreamAccumulator<BasicCollectionUpdates<any>>;

    refsDeletionsDetectedSinceGC: number = 0;

    constructor(
        identifier: string,
    ) {
        this.identifier = identifier;
        this.allUsersUpdatesStream = new ObservableStream({
            identifier
        });
        this.accumulator = new StreamAccumulator(this.allUsersUpdatesStream);
    }

    dispose(): void {
        this.allUsersUpdatesStream.dispose();
        this.accumulator.dispose();
    }

    addUser<IdT, THandle>(user: GCedEntitiesUser<IdT, THandle>) {
        this.allUsersUpdatesStream.mergeFrom(user.updatesStream);
        this.users.push(user);
    }


    shouldRunGC(timeBudget: RenderTimeBudget): boolean {
        const updates = this.accumulator.consume();
        if (updates) {
            for (const update of updates) {
                if (update instanceof Deleted) {
                    this.refsDeletionsDetectedSinceGC += update.ids.length;
                }
            }
        }
        const timeLeftPerc = timeBudget.jsTimeBudgetLeft() / timeBudget._frameStartTime;
        const haveMuchTime = timeLeftPerc > 0.75;

        return this.refsDeletionsDetectedSinceGC > DeletionsToTriggerGC.higherBound
            || (haveMuchTime && this.refsDeletionsDetectedSinceGC > DeletionsToTriggerGC.lowerBound)
    }

    runGC(logger: ScopedLogger) {
        if (this.collections.length === 0) {
            logger.warn(`no collections this for`, this);
        }
        if (this.users.length === 0) {
            logger.warn(`no users registered for`, this);
        }

        this.refsDeletionsDetectedSinceGC = 0;
        this.accumulator.consume();// empty out

        const idsToRetain: EntityId<number>[] = [];
        for (const user of this.users) {
            user.getIdsToRetain(idsToRetain);
        }
        IterUtils.sortDedupNumbers(idsToRetain);

        const allIdsPresent = new Set<EntityId<any>>();
        for (const collection of this.collections) {
            collection.getEngineOwnedIds(allIdsPresent);
        }

        for (const idInUse of idsToRetain) {
            allIdsPresent.delete(idInUse);
        }

        if (allIdsPresent.size > 0) {
            for (const collection of this.collections) {
                collection.delete(Array.from(allIdsPresent));
            }
        }
    }

}
