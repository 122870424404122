import type { LazyDerivedAsync, ResultAsync} from 'engine-utils-ts';
import { LazyDerived, Success } from 'engine-utils-ts';
import type { Bim, CostReportRelatedMetrics, LCOE } from '../..';
import { EnergyYieldPropsGroup, EnergyYieldSiteProps } from '../..';
// frontend shows this value as <value>/MWh, so we should flip prefix <M>
export function inverseLcoeResultDiv(inputWh: number) {
    if (inputWh <= 0) {
        return 0;
    }
    for (let i = 0; i < 10; i++) {
        if (inputWh*1e3**i > 1) {
            return inputWh * 1e3**(2*i);
        }
    }
    console.warn('!!')
    return inputWh;
}

export function createLcoeInput(bim: Bim, costMetrics: LazyDerivedAsync<CostReportRelatedMetrics>) {
    const energyMetrics = bim.runtimeGlobals.getAsLazyVersionedByIdent(
        EnergyYieldSiteProps,
        EnergyYieldPropsGroup,
    );

    // lcoe related layout input
    const lcoeLayoutInputLazy = LazyDerived.new2<
        LCOE.LCOELayoutInput,
        ResultAsync<CostReportRelatedMetrics>,
        ResultAsync<EnergyYieldPropsGroup>
    >(
        "lcoeLayoutInput",
        [],
        [costMetrics, energyMetrics],
        ([costMetrics, energyMetrics]) => {
            return {
                annualOutput:
                    energyMetrics instanceof Success
                        ? energyMetrics.value.annual_yield.as("Wh")
                        : 0,
                capitalCost:
                    costMetrics instanceof Success
                        ? costMetrics.value?.construction_total_cost?.value ?? 0
                        : 0,
            };
        },
    );

    return lcoeLayoutInputLazy;
}
