import { CostModel as CM } from 'bim-ts';
import { ColDef } from "ag-grid-enterprise";
import { createModifiableCostCellDefinition } from './common';


export const createLaborTotalColumnDefinition = (): ColDef<CM.FlattenedCostCategoryParams> => ({
    colId: CM.LaborTotalColId,
    headerName: CM.LaborTotalColId,
    cellEditor: 'agNumberCellEditor',
    ...createModifiableCostCellDefinition(x => x.labor?.laborTotal, true),
})

