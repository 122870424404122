import type { BimPatch, IdBimGeo, SceneInstance} from "bim-ts";
import { SceneObjDiff } from "bim-ts";
import { IterUtils, ObjectUtils } from "engine-utils-ts";
import type { Matrix4, Vector3 } from "math-ts";
import { Transform } from "math-ts";
import { ESO, ESO_AnotationFlags } from "./ESO";
import type { ESOsHandlerInputs } from "./ESOsHandlerBase";
import { ESOsHandlerBase } from "./ESOsHandlerBase";
import type { ESOHandle, ESOsCollection } from "../scene/ESOsCollection";
import { ESSO_Interactive } from "./ESSO";
import type { InObjLocalId } from "../scene/EngineSceneIds";
import { InObjFullId } from "../scene/EngineSceneIds";
import type { GeometryCtor } from "../scene/GeometryEditsHandler";


export abstract class ESO_SingleGeo extends ESO {

	constructor(
		sceneInstanceRef: Readonly<SceneInstance>,
	) {
		super(sceneInstanceRef);
	}

	abstract geometryId(): IdBimGeo | undefined;

}

export abstract class ESO_SingleGeoHandler<ESO_T extends ESO_SingleGeo> extends ESOsHandlerBase<ESO_T> {

	constructor(
		identifier: string,
		args: ESOsHandlerInputs,
	) {
		super(identifier, args);
		this._relevantBimUpdatesFlags |= SceneObjDiff.LegacyProps;
		this._annotationsMask |= ESO_AnotationFlags.IsInEdit;
	}

	patchSubObjectsWorldMatrices(
		coll: Readonly<ESOsCollection>,
		patches: [ESOHandle, ESO_T, [InObjFullId, Matrix4][]][],
		outputPatch: BimPatch,
	): void {
		const logger = this.logger.newScope('patch sub objs positions');
		for (const [handle, polygon, perSubObjMatrices] of patches) {
			const geoId = polygon.geometryId()!;
			const geo = this.bimGeos.peekById(geoId);
			if (!geo) {
				continue;
			}
			const geoHandler = this.geometriesEditHandlers.get(geo.constructor as GeometryCtor);
			if (!geoHandler) {
				logger.error('not geometry handler for geo type', geo.constructor);
				continue;
			}
			const wmInversion = polygon.worldMatrix.clone().invert();
			const perSubObjLocalTransforms = new Map<InObjLocalId, Transform>();
			for (const [id, m] of perSubObjMatrices) {
				const localMatrix = m.clone().premultiply(wmInversion);
				perSubObjLocalTransforms.set(id.inObjId, Transform.fromMatrix(localMatrix));
			}

			const geoInOut = ObjectUtils.deepCloneObj(geo);
			const subObjs = coll.subObjects.getChildrenOfByClass(handle, ESSO_Interactive);

			const {} = geoHandler.patchPositions(
				geoInOut,
				this.bimGeos.localIdsCounterFor(geoId),
				subObjs,
				perSubObjLocalTransforms
			);
			if (ObjectUtils.areObjectsEqual(geo, geoInOut)) {
				logger.debug('positions patch: geo didnt change, ignoring', geoId);
				continue;
			}
			outputPatch.geometries.toPatch.push([geoId, geoInOut]);
		}
	}

	cloneInteractiveSubObjects(
		coll: Readonly<ESOsCollection>,
		ids: [ESOHandle, ESO_T, InObjFullId[]][],
		worldSpaceDirectionOfClone: Vector3,
		outputPatch: BimPatch,
	): {newObjects: InObjFullId[], toUseForGesture: InObjFullId[] } {
		const logger = this.logger.newScope('clone sub objs');
		const newObjectsTotal: InObjFullId[] = [];
		const toUseForGestureTotal: InObjFullId[] = [];
		for (const [handle, polygon, idsToClone] of ids) {
			const geoId = polygon.geometryId()!
			const geo = this.bimGeos.peekById(geoId);
			if (!geo) {
				continue;
			}
			const geoHandler = this.geometriesEditHandlers.get(geo.constructor as GeometryCtor);
			if (!geoHandler) {
				logger.error('no geometry handler for geo type', geo.constructor);
				continue;
			}
			const wmInversion = polygon.worldMatrix.clone().invert();
			const localSpaceCloneDirection = worldSpaceDirectionOfClone.clone().applyMatrix4Rotation(wmInversion);

			const geoInOut = ObjectUtils.deepCloneObj(geo);
			const subObjs = coll.subObjects.getChildrenOfByClass(handle, ESSO_Interactive);

			const {added, toUseForGesture} = geoHandler.cloneParts(
				geoInOut,
				this.bimGeos.localIdsCounterFor(geoId),
				subObjs,
				idsToClone.map(id => id.inObjId),
				localSpaceCloneDirection,
			);
			if (ObjectUtils.areObjectsEqual(geo, geoInOut)) {
				logger.debug('positions patch: geo didnt change, ignoring', geoId);
				if (added.length > 0) {
					logger.warn('geo clone returned ids, but geo didnt change, ignoring', added, geoInOut)
				}
				continue;
			}
			outputPatch.geometries.toPatch.push([geoId, geoInOut]);

			IterUtils.extendArray(
				newObjectsTotal,
				IterUtils.iterMap(added, id => InObjFullId.new(handle, id))
			);
			IterUtils.extendArray(
				toUseForGestureTotal,
				IterUtils.iterMap(toUseForGesture ?? added, id => InObjFullId.new(handle, id))
			);
		}
		return {newObjects: newObjectsTotal, toUseForGesture: toUseForGestureTotal};
	}

	deleteInteractiveSubObjects(
		coll: Readonly<ESOsCollection>,
		ids: [ESOHandle, ESO_T, InObjFullId[]][],
		outputPatch: BimPatch
	): void {
		const logger = this.logger.newScope('delete sub objs');
		for (const [handle, polygon, perObjIds] of ids) {
			const geoId = polygon.geometryId()!;
			const geo = this.bimGeos.peekById(geoId);
			if (!geo) {
				continue;
			}
			const geoHandler = this.geometriesEditHandlers.get(geo.constructor as GeometryCtor);
			if (!geoHandler) {
				logger.error('no geometry handler for geo type', geo.constructor);
				continue;
			}

			const geoInOut = ObjectUtils.deepCloneObj(geo);
			const subObjs = coll.subObjects.getChildrenOfByClass(handle, ESSO_Interactive);

			const {} = geoHandler.deleteParts(
				geoInOut,
				this.bimGeos.localIdsCounterFor(geoId),
				subObjs,
				perObjIds.map(id => id.inObjId),
			);
			if (ObjectUtils.areObjectsEqual(geo, geoInOut)) {
				logger.debug('positions patch: geo didnt change, ignoring', geoId);
				continue;
			}
			outputPatch.geometries.toPatch.push([geoId, geoInOut]);
		}
	}
}



