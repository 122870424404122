import { Failure, IterUtils, unitsConverter, WorkerClassPassRegistry } from 'engine-utils-ts';

export class TMY_ColumnHeader {

    name?: string;
    unit?: string;
    raw: string[];

    constructor(args: {
        name?: string;
        unit?: string;
        raw: string[];
    }) {
        this.name = args.name;
        this.unit = args.unit;
        this.raw = args.raw;
        Object.freeze(this);
    }

    equals(other: TMY_ColumnHeader): boolean {
        if (this.name !== other.name) {
            return false;
        }
        if (this.unit !== other.unit) {
            return false;
        }
        if (this.raw.length !== other.raw.length) {
            return false;
        }
        return IterUtils.areArraysEqual(this.raw, other.raw);
    }

    toHeaderString() {
        if (!this.name) {
            return this.raw.join(' ');
        }
        let res = this.name;
        if (this.unit) {
            res += ` (${this.unit})`;
        }
        return res;
    }

    static tryParseAsNameAndUnit(rawStrings: string[]): TMY_ColumnHeader | null {

        for (let i = rawStrings.length - 1; i >= 0; --i) {
            const rawStr = rawStrings[i];
            const unitsGroup = unitFinderRegex.exec(rawStr) as RegExpMatchArray & { indices: Array<[number, number]> };;
            if (!unitsGroup) {
                continue;
            }
            const unitsStr = unitsGroup[0].slice(1, -1).replaceAll('^', '');
            const parsedUnitsResult = unitsConverter.tryParseUnits(unitsStr);
            if (parsedUnitsResult instanceof Failure) {
                continue;
            }
            const indices = unitsGroup.indices[1] as [number, number];

            const strBeforeUnit = rawStr.slice(0, indices[0]).trim();

            const name = rawStrings.slice(0, i).join(' ') + strBeforeUnit;

            return new TMY_ColumnHeader({
                name,
                unit: unitsStr,
                raw: rawStrings
            });
        }

        return null;
    }
}
WorkerClassPassRegistry.registerClass(TMY_ColumnHeader);

const unitFinderRegex = new RegExp(/(\(.+\))/d);