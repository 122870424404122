import { Bim } from "bim-ts";
import {
    APP_ID_PROPERTIES,
    BLOCK_PROPERTIES,
    BLOCK_RECORD_PROPERTIES,
    DICTIONARY_PROPERTIES,
    DIM_STYLE_PROPERTIES,
    DxfPropertyMap,
    ENDBLOCK_PROPERTIES,
    GEODATA_PROPERTIES,
    LAYER_PROPERTIES,
    LINE_TYPE_PROPERTIES,
    TABLE_PROPERTIES,
    TEXT_STYLE_PROPERTIES,
    VPORT_PROPERTIES,
} from "./DxfEntitiesDefaultProperties";
import { BlockTypeFlag } from "./DxfEnums";
import { DxfHandle, DXFNode, DxfProperties } from "./DxfNode";

export type dxfSectionName =
    | "HEADER"
    | "CLASSES"
    | "TABLES"
    | "BLOCKS"
    | "ENTITIES"
    | "OBJECTS";

export type dxfTableType =
    | "VPORT"
    | "LTYPE"
    | "LAYER"
    | "STYLE"
    | "VIEW"
    | "UCS"
    | "APPID"
    | "DIMSTYLE"
    | "BLOCK_RECORD";

export type dxfEntityType =
    | "LWPOLYLINE"
    | "INSERT"
    | "LINE"
    | "POLYLINE"
    | "ARC";

export class DxfFileManager {
    readonly root: DXFNode;

    constructor(node?: DXFNode) {
        if (node !== undefined) {
            if (node.children.some((x) => x.type !== "SECTION")) {
                throw new Error("provided node is nor root Dxf node!");
            }
            this.root = node;
        } else {
            const fileCloseCode = this.createNode("EOF");
            this.root = {
                properties: new DxfProperties(),
                children: [],
                closeNode: fileCloseCode,
            };
        }
    }

    getLayersNames() {
        const tables = this.getSection("TABLES");
        const layers = this.getNodesByProperty(tables, 2, "LAYER")[0];
        const layerNames: string[] = [];
        for (const layer of layers.children) {
            const name = layer.properties.get(2) as string;
            layerNames.push(name);
        }
        return layerNames;
    }

    getLayer(name: string): DXFNode | undefined {
        const tables = this.getSection("TABLES");
        const layers = this.getNodesByProperty(tables, 2, "LAYER")[0];
        for (const layer of layers.children) {
            const layerName = layer.properties.get(2) as string;
            if (layerName === name) {
                return layer;
            }
        }
        return;
    }
    getViewPort(name: string): DXFNode | undefined {
        const tables = this.getSection("TABLES");
        const viewports = this.getNodesByProperty(tables, 2, "VPORT")[0];
        for (const view of viewports.children) {
            const viewName = view.properties.get(2) as string;
            if (viewName === name) {
                return view;
            }
        }
        return;
    }

    getBlocksNames() {
        const blocks = this.getSection("BLOCKS");
        const blockNames: string[] = [];
        for (const block of blocks.children) {
            const name = block.properties.get(2) as string;
            blockNames.push(name);
        }
        return blockNames;
    }

    getBlock(name: string): DXFNode | undefined {
        const blocks = this.getSection("BLOCKS");
        for (const block of blocks.children) {
            const layerName = block.properties.get(2) as string;
            if (layerName === name) {
                return block;
            }
        }
        return;
    }
    getBlockRecord(name: string): DXFNode | undefined {
        const tables = this.getSection("TABLES");

        for (const table of tables.children) {
            const tableName = table.properties.get(2) as string;
            if (tableName !== "BLOCK_RECORD") {
                continue;
            }

            for (const blockRecord of table.children) {
                const blockRecordName = blockRecord.properties.get(2) as string;
                if (blockRecordName !== name) {
                    continue;
                } else {
                    return blockRecord;
                }
            }
        }
        return;
    }

    getSection(name: dxfSectionName) {
        const nodes = this.getNodesByProperty(this.root, 2, name);
        if (nodes.length === 0) {
            throw new Error(`${name} section not found.`);
        }
        return nodes[0];
    }
    getTable(name: dxfTableType): DXFNode {
        const tables = this.getSection("TABLES");
        const table = this.getNodesByProperty(tables, 2, name);
        if (table.length !== 1) {
            throw new Error("Wrong dxf structure!");
        }
        return table[0];
    }
    getDictionaries(): DXFNode[] {
        const dictionaries: DXFNode[] = [];
        const objects = this.getSection("OBJECTS");
        for (let object of objects.children) {
            if (object.type === "DICTIONARY") {
                dictionaries.push(object);
            }
        }
        return dictionaries;
    }

    getDictionaryByName(name: string): DXFNode | undefined {
        const dicts = this.getDictionaries();
        if (!dicts.length) {
            return;
        }

        const dict = dicts[0];
        const dictProps = dict.properties.entries();

        for (let i = 0; i < dictProps.length; i++) {
            const { code, value } = dictProps[i];
            const val = value.toString().toLowerCase();

            if (code === 3 && val === name.toLowerCase()) {
                const { code: dictHandleCode, value: dictHadleValue } =
                    dictProps[i + 1];
                if (dictHandleCode === 350) {
                    const dictionary = dicts.find(
                        (d) =>
                            (d.properties.get(5) as string) ===
                            (dictHadleValue as string)
                    );
                    if (!dictionary) {
                        console.log(`Dictionary ${name} didnt found`);
                    }
                    return dictionary;
                }
            }
        }
        return;
    }
    getNodesByProperty(node: DXFNode, code: number, value: string): DXFNode[] {
        const nodes: DXFNode[] = [];
        for (const child of node.children) {
            const prop = child.properties.get(code);
            if (typeof prop === "string") {
                if (prop.trim() === value) {
                    nodes.push(child);
                }
            }
        }
        return nodes;
    }

    private appendPropValue(node: DXFNode, propCodeToAppend: number) {
        let val = node.properties.get(propCodeToAppend);
        let parseVal = 0;
        if (typeof val === "number") {
            parseVal = val + 1;
        } else if (typeof val === "string") {
            parseVal = Number.parseFloat(val) + 1;
        }
        node.properties.set(propCodeToAppend, parseVal);
    }

    private addNodeToTable(
        tableType: dxfTableType,
        name: string,
        propertyMap: DxfPropertyMap | DxfProperties
    ) {
        let props: DxfProperties;
        if (propertyMap instanceof DxfProperties) {
            props = propertyMap;
        } else {
            props = new DxfProperties(propertyMap);
        }

        props.set(2, name);

        const hCode = tableType === "DIMSTYLE" ? 105 : 5;
        props.set(hCode, DxfHandle.handle());

        const tables = this.getSection("TABLES");
        const table = this.getNodesByProperty(tables, 2, tableType)[0];

        const newNode = this.addNode(table, tableType, props, []);

        this.appendPropValue(table, 70);

        return newNode;
    }

    addBlock(
        name: string,
        type: BlockTypeFlag,
        layer?: string,
        annotative?: boolean,
        ...insertions: DXFNode[]
    ): DXFNode {
        let disallowedCharacters = name.match(/[^a-zA-Z0-9()*$_-]/g);

        if (disallowedCharacters) {
            console.error("Error: The block name contains disallowed characters: ", disallowedCharacters.join(", "));
        }


        if (this.getBlocksNames().includes(name)) {
            return this.getBlock(name)!;
        }

        const layerName = layer ? layer : "0";
        this.addLayer(layerName);

        const blocks = this.getSection("BLOCKS");

        const props = new DxfProperties(BLOCK_PROPERTIES);
        props.set(2, name);
        props.set(3, name);
        props.set(5, DxfHandle.handle());
        props.set(8, layerName);
        if (type) {
            props.set(70, type);
        }

        this.addBlockRecord(name, annotative);

        const endBlock = this.createNode("ENDBLK", ENDBLOCK_PROPERTIES);
        endBlock.properties.set(5, DxfHandle.handle());
        endBlock.properties.set(8, layerName);

        return this.addNode(blocks, "BLOCK", props, insertions, endBlock);
    }

    addBlockRecord(name: string, annotative?: boolean) {
        const blockRecord = this.addNodeToTable(
            "BLOCK_RECORD",
            name,
            BLOCK_RECORD_PROPERTIES
        );
        if (annotative) {
            blockRecord.properties.set(281, 1);
            blockRecord.properties.set(1071, 1);
            blockRecord.properties.set(1001, "AcadAnnotative");
            blockRecord.properties.set(1000, "AnnotativeData");
            blockRecord.properties.set(1002, "{");
            blockRecord.properties.set(1070, 1);
            blockRecord.properties.set(1070, 1, false);
            blockRecord.properties.set(1002, "}", false);
        }

        return blockRecord;
    }

    addDimStyle(bim: Bim, name: string) {
        const isImperial = bim.unitsMapper.isImperial();
        const dimProp = new DxfProperties(DIM_STYLE_PROPERTIES);

        if (isImperial) {
            dimProp.set(277, 2);
        } else {
            dimProp.set(277, 5);
        }

        this.addNodeToTable("DIMSTYLE", name, dimProp);
    }

    addAppId(name: string) {
        this.addNodeToTable("APPID", name, APP_ID_PROPERTIES);
    }

    addTextStyle(name: string) {
        this.addNodeToTable("STYLE", name, TEXT_STYLE_PROPERTIES);
    }

    addLayer(name: string, colour: number = 7): DXFNode {
        const layerNames = this.getLayersNames();
        let layer: DXFNode;
        if (layerNames.includes(name)) {
            layer = this.getLayer(name)!;
        } else {
            layer = this.addNodeToTable("LAYER", name, LAYER_PROPERTIES);
            layer.properties.set(62, colour);
        }

        return layer;
    }

    addViewPort(name: string) {
        this.addNodeToTable("VPORT", name, VPORT_PROPERTIES);
    }

    addLineType(name: string) {
        this.addNodeToTable("LTYPE", name, LINE_TYPE_PROPERTIES);
    }

    addVariable(
        name: string,
        codeOrParameters: number | DxfProperties,
        value?: string | number
    ) {
        const header = this.getSection("HEADER");
        if (!name.startsWith("$")) {
            name = "$" + name;
        }
        if (typeof codeOrParameters === "number" && value != undefined) {
            this.addNode(
                header,
                name,
                new DxfProperties({ code: codeOrParameters, value })
            );
        } else if (typeof codeOrParameters !== "number") {
            this.addNode(header, name, codeOrParameters);
        }
    }

    public createNode(
        type: string,
        props: DxfProperties | DxfPropertyMap = new DxfProperties(),
        children: DXFNode[] = [],
        closeNode?: DXFNode
    ): DXFNode {
        let properties: DxfProperties;
        if (props instanceof DxfProperties) {
            properties = props;
        } else {
            properties = new DxfProperties(props);
        }

        return { type, properties, children, closeNode };
    }

    addNode(
        parent: DXFNode,
        type: string,
        properties: DxfProperties = new DxfProperties(),
        children: DXFNode[] = [],
        closeNode?: DXFNode,
        setHandle: boolean = false
    ) {
        if (setHandle) {
            properties.set(5, DxfHandle.handle());
        }
        
        //TODO:set owner handle 330

        const newNode = this.createNode(type, properties, children, closeNode);
        parent.children.push(newNode);
        return newNode;
    }

    addDictionary(dictProps: DxfProperties, dictName?: string): DXFNode {
        const dictNode = this.createNode("DICTIONARY", dictProps);

        const objects = this.getSection("OBJECTS");
        const index = objects.children.findIndex(
            (obj) => obj.type !== "DICTIONARY"
        );

        if (index !== -1) {
            objects.children.splice(index, 0, dictNode);
        } else {
            objects.children.push(dictNode);
        }

        if (dictName) {
            const rootDict = this.getDictionaries()[0];
            if (rootDict) {
                rootDict.properties.set(3, dictName, false);
                rootDict.properties.set(350, dictNode.properties.get(5) as string, false);
                
                dictNode.properties.set(330, rootDict.properties.get(5) as string)
            }
        }

        return dictNode;
    }

    addClass(classProps: DxfProperties): DXFNode {
        const classNode = this.createNode("CLASS", classProps);
        const classes = this.getSection("CLASSES");
        classes.children.push(classNode);
        return classNode;
    }

    addGeoData(geoDataProps: DxfProperties) {
        const objects = this.getSection("OBJECTS");
        const geodata = this.addNode(objects, "GEODATA", geoDataProps);

        const dictProps = new DxfProperties([
            { code: 5, value: DxfHandle.handle() },
            { code: 330, value: 2 },
            { code: 100, value: "AcDbDictionary" },
            { code: 280, value: 1 },
            { code: 281, value: 1 },
            { code: 3, value: "ACAD_GEOGRAPHICDATA" },
            { code: 360, value: geodata?.properties.get(5) as string },
        ]);

        const geodataDict = this.addDictionary(dictProps);

        const modelSpaceBlockRecord = this.getBlockRecord("*Model_Space");
        if (modelSpaceBlockRecord) {
            const dict = [
                { code: 102, value: "{ACAD_XDICTIONARY" },
                {
                    code: 360,
                    value: `${geodataDict.properties.get(5) as string}`,
                },
                { code: 102, value: "}" },
            ];

            modelSpaceBlockRecord.properties.setAtIndex(1, ...dict);
        }
    }
}
