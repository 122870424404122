import type { Asset, AssetCatalogItemProps, AssetId, Bim, Catalog, CatalogItem, Config, IdConfig, PropertyGroup } from "bim-ts";
import { CatalogItemsReferenceProperty, ConfigUtils, PropertyBase } from "bim-ts";
import type { ScopedLogger, TasksRunner} from "engine-utils-ts";
import { Failure, IterUtils, PollablePromise, Yield } from "engine-utils-ts";
import type { UiBindings } from "ui-bindings";
import { NotificationDescription, NotificationType } from "ui-bindings";
import type { ObjectVersion, ObjectVersionsRange } from "verdata-ts";
import { notificationSource } from '../Notifications';
import type { PropertyPathType } from "bim-ts";


export async function restoreAssets(catalog: Catalog, bim: Bim, types: string[], skipPaths: PropertyPathType[][], ui:UiBindings, tasksRunner: TasksRunner, logger: ScopedLogger){
    const actionName = 'Restore Assets';
    const _logger = logger.newScope(actionName);
    try {
        const task = tasksRunner.newLongTask({
            defaultGenerator: _restoreAssets(catalog, bim, types, skipPaths, _logger)
        });

        ui.addNotification(NotificationDescription.newWithTask({
            type: NotificationType.Info,
            source: notificationSource,
            key: 'restoreAssets',
            taskDescription: {
                task,
            },
            removeAfterMs: 1000,
            addToNotificationsLog: true
        }));
        await task.asPromise();
    } catch (e) {
        _logger.error(e);
        ui.addNotification(NotificationDescription.newBasic({
            type: NotificationType.Error,
            source: notificationSource,
            key: 'restoreAssetsFailed',
            removeAfterMs: 3000,
            addToNotificationsLog: true
        }));
    }
}

function* _restoreAssets(catalog: Catalog, bim: Bim, types: string[], skipPaths: PropertyPathType[][], logger: ScopedLogger) {
    const configs:[IdConfig, Config][] = [];
    for (const type of types) {
        IterUtils.extendArray(configs, bim.configs.peekByType(type));
    }
    const ids = getNotFoundIds(catalog, configs, skipPaths, logger);

    const catalogIdent = 'catalog';
    const assetsIdent = 'assets';
    const syncer = catalog.getSyncer();

    function findObjectsInCollection(collectionIdnt: string, ids:number[]){
        const objectsByVersionRange = syncer.findObjectsInCollection(collectionIdnt, ids);
        if(!objectsByVersionRange){
            throw new Error('objects not found in collection - ' + collectionIdnt);
        }
        return objectsByVersionRange;
    }

    if(ids.catalogItems.length > 0){
        const objectsByVersionRange = findObjectsInCollection(catalogIdent, ids.catalogItems);
        // logger.info('to load', objectsByVersionRange);
        const perVersionCatalogItems = getLastVersions(objectsByVersionRange);

        const itemsResult = yield* PollablePromise.generatorWaitFor(syncer.loadObjects<CatalogItem>(catalogIdent, perVersionCatalogItems));
        if(itemsResult instanceof Failure){
            throw new Error(itemsResult.errorMsg());
        }
        const items = itemsResult.value;

        yield Yield.Asap;

        const assetIds = items.map(([_id, item])=>{
            return item.as<AssetCatalogItemProps>().properties?.asset_id?.value ?? 0;
        });
        const assetsByVersions = findObjectsInCollection(assetsIdent, assetIds);
        const perVersionAssets = getLastVersions(assetsByVersions);
        const assetsResult = yield* PollablePromise.generatorWaitFor(syncer.loadObjects<Asset>(assetsIdent, perVersionAssets));
        if(assetsResult instanceof Failure){
            throw new Error(assetsResult.errorMsg());
        }
        const assets = assetsResult.value;

        yield Yield.Asap;

        catalog.allocate(assets, items);
    }

    if(ids.assets.length > 0){
        const assetsByVersions = findObjectsInCollection(assetsIdent, ids.assets);
        // logger.info('assets', assetsByVersions);
        const perVersionAssets = getLastVersions(assetsByVersions);
        // logger.info('perVersionAssets', perVersionAssets);
        const versions = new Set(perVersionAssets.map(a=>a.projectVersion));
        const collectionsResult = yield* PollablePromise.generatorWaitFor(syncer.loadObjectsVersions<CatalogItem>(
            catalogIdent,
            Array.from(versions),
        ));
        if(collectionsResult instanceof Failure){
            throw new Error(collectionsResult.errorMsg());
        }
        const collections = collectionsResult.value;

        yield Yield.Asap;

        const items: [number, CatalogItem][] = [];
        for (const [version, collection] of collections) {
            const byVersionAssets = perVersionAssets.filter(a=>a.projectVersion == version).map(a=>a.id);
            const notFoundAssts = new Set(byVersionAssets);
            for (const [id, item] of collection) {
                const assetId = item.as<AssetCatalogItemProps>().properties?.asset_id?.value;
                if(!assetId){
                    continue;
                }
                if(notFoundAssts.has(assetId)){
                    // logger.info('catalog item', [version, id, item]);
                    items.push([id, item]);
                }
            }
        }
        const assetsResult = yield* PollablePromise.generatorWaitFor(syncer.loadObjects<Asset>(assetsIdent, perVersionAssets));
        if(assetsResult instanceof Failure){
            throw new Error(assetsResult.errorMsg());
        }
        const assets = assetsResult.value;
        
        yield Yield.Asap;
        
        if(assets.length !== items.length){
            logger.error('not sync assets and catalog', [assets, items]);
        }
        catalog.allocate(assets, items);
    }
}

export function getNotFoundIds(catalog: Catalog, configs: [IdConfig, Config][], skipPaths: PropertyPathType[][], logger: ScopedLogger) {


    const catalogProps: CatalogItemsReferenceProperty[] = [];
    for (const [_, config] of configs) {
        fetchCatalogRefProps(catalogProps, config.properties, skipPaths, []);
    }
    const checkToLoadAssets = new Set<number>();
    const checkToLoadCatalogItems = new Set<number>();
    for (const prop of catalogProps) {
        for (const value of prop.value) {
            if(value.type === 'catalog_item'){
                checkToLoadCatalogItems.add(value.id);
            }else if(value.type === 'asset'){
                checkToLoadAssets.add(value.id);
            }else {
                logger.error('undefined type', value);
            }
        }
    }
    const notFoundCatalogItems:number[] = [];
    const items = catalog.catalogItems.peekByIds(checkToLoadCatalogItems);
    for (const id of checkToLoadCatalogItems) {
        if(!items.has(id)){
            notFoundCatalogItems.push(id);
        }
    }
    const notFoundAssets:number[] = [];
    const assets = new Set<AssetId>();
    for (const [_, item] of catalog.catalogItems.readAll()) {
        const assetId = item.as<AssetCatalogItemProps>().properties?.asset_id?.value;
        if(!assetId){
            continue;
        }
        assets.add(assetId);
    }
    for (const id of checkToLoadAssets) {
        if(!assets.has(id)){
            notFoundAssets.push(id);
        }
    }
    return {
        assets: notFoundAssets,
        catalogItems: notFoundCatalogItems,
    }
}


function getLastVersions(objs: ObjectVersionsRange[]){
    // console.log('verPerIds', verPerIds);
    const perVersion:ObjectVersion[] = [];
    for (const obj of objs) {
        const [_, max] = obj.projectVersionRange;
        perVersion.push({projectVersion: max, id: obj.id});
    }
   return perVersion;
}

function fetchCatalogRefProps(
    properties: CatalogItemsReferenceProperty[],
    propertiesGroup: PropertyGroup,
    skipPaths: PropertyPathType[][],
    path: (string | number)[],
) {
    for (const key in propertiesGroup) {
        const currentPath = path.slice();
        currentPath.push(key);
        if(ConfigUtils.areEqualSomePath(skipPaths, path)){
            continue;
        }
        const prop = propertiesGroup[key];
        if (prop instanceof PropertyBase || prop === null) {
            if (prop instanceof CatalogItemsReferenceProperty) {
                properties.push(prop);
            }
        } else if (Array.isArray(prop) && prop[0] instanceof PropertyBase) {
            for (const p of prop) {
                if (p instanceof CatalogItemsReferenceProperty) {
                    properties.push(p);
                }
            }
        } else if (Array.isArray(prop)) {
            for (let i = 0; i < prop.length; i++) {
                const group = prop[i];
                const newPath = currentPath.slice();
                newPath.push(i);
                fetchCatalogRefProps(properties, group as any, skipPaths, newPath);
            }
        } else {
            fetchCatalogRefProps(properties, prop, skipPaths, currentPath.slice());
        }
    }
}

export function needToRestoreAssets(catalog: Catalog, configs: [IdConfig, Config][], skipPaths: PropertyPathType[][],  logger: ScopedLogger){
    const ids = getNotFoundIds(catalog, configs, skipPaths, logger);
    // logger.info('need-to-restore-assets', ids);
    return ids.assets.length > 0 || ids.catalogItems.length > 0;
}