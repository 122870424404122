export function tooltip(node: HTMLDivElement, value: string) {
    const setTooltip = (title: string) => {
		if (node.offsetWidth < node.scrollWidth) {
        	node.setAttribute("title", title);
		}
    }

	setTooltip(value);

	return {
		update(value: string) {
			setTooltip(value);
		},
		destroy() {
		}
	};
}
