import { navigate } from 'svelte-routing';
import type { Project, ProjectApi } from "./projects";
import { ProjectStatus } from "./projects";
import { BindedStore } from './utils';
import type { VersionedValue } from 'engine-utils-ts';
import { VersionedInvalidator } from 'engine-utils-ts';

export class ProjectHubStore {
    project?: Project;
    name: string = '';
}

export class ProjectHub extends BindedStore<ProjectHubStore> implements VersionedValue {
    private readonly _invalidator: VersionedInvalidator;;

    constructor(readonly _projectApi: ProjectApi) {
        super(new ProjectHubStore());
        this._invalidator = new VersionedInvalidator();
        this.subscribe(s => {
            this._invalidator.invalidate();
        });
    }

    version(): number {
        return this._invalidator.version();
    }

    async getProject(projectid: number) {
        try {
            const result = await this._projectApi.get(projectid);
            this.update(s => (s.project = result, s.name = result.name, s));
        } catch (e) {
            navigate('/bad?text=' + encodeURIComponent(`Can not fetch project ${projectid}` ));
        }
    }

    static getDefaultProject(): Project {
        return {
            id: parseInt(document.location.pathname.split('/').pop() || "0"),
            name: "",
            archived: false,
            createBy: "",
            updatedAt: "",
            createdAt: "",
            company: "none",
            isShared: false,
            status: ProjectStatus.Active
        }
    }

    async updateName() {
        try {
            // no project loaded yet
            if (!this._storeValue.project) {
                return;
            }
            // project name is equal to name input
            if (this._storeValue.project.name === this._storeValue.name) {
                return;
            }
            // else update
            const result = await this._projectApi.updateName(
                this._storeValue.project.id,
                this._storeValue.name
            )
            this.update(s => (s.project = result, s.name = result.name, s));
        } catch (e) {
            console.error(e);
        }
    }

    async updateStatus(newStatus: ProjectStatus) {
        try {
            if (!this._storeValue.project) {
                return;
            }
            if (this._storeValue.project.status === newStatus) {
                return;
            }
            const result = await this._projectApi.updateStatus(
                this._storeValue.project.id,
                newStatus
            )
            this.update(s => (s.project = result, s));
        } catch (e) {
            console.error(e);
        }
    }

    async toggleShared(isShared: boolean) {
        if (!this._storeValue.project) {
            return;
        }
        if (this._storeValue.project.isShared === isShared) {
            return;
        }
        const id = this._storeValue.project.id;
        if (isShared) {
            await this._projectApi.share(id);
        } else {
            await this._projectApi.unshare(id);
        }
        this.update(s => (s.project =  s.project ? {...s.project, isShared: isShared} : undefined, s));
    }

    async reset() {
        this.set({ name: '' });
    }
}
