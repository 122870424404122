<script lang="ts">
    import type {
        MultiSelectorValue,
        PUI_PropertyNodeMultiSelector,
    } from "ui-bindings";
    import SelectorPropertyBase from "./SelectorPropertyBase.svelte";
    import { ObjectUtils } from "engine-utils-ts";
    import flash from "./flash";

    export let property: PUI_PropertyNodeMultiSelector;

    let div: HTMLElement;
    let preValue = property.value;

    $: if (!ObjectUtils.areObjectsEqual(preValue, property.value)) {
        preValue = property.value;
        flash(div);
    }

    // reset value that is not in options
    $: {
        const allowedValues = new Set<number | string>();
        for (const option of property.options) {
            allowedValues.add(option.value);
        }
        const newValue: typeof property.value = [];
        for (const item of property.value) {
            if (allowedValues.has(item.value)) {
                newValue.push(item);
            }
        }
        if (!ObjectUtils.areObjectsEqual(property.value, newValue)) {
            property.value = newValue;
        }
    }

    $: value = property.value.map((option) => option.value);

    function onChange(e: CustomEvent) {
        const selectedOptions: MultiSelectorValue[] =
            property.maxSelect === 1
                ? e.detail === null
                    ? []
                    : [e.detail]
                : e.detail;
        property.value = property.options.filter((option) =>
            selectedOptions.find((selected) => selected.value === option.value)
        );
    }
</script>

<div 
    class="ui-config-property property-row multiselect-prop"
    class:not-active={property.notActive}
    >
    <div bind:this={div} class="property-name text-main-medium">
        <div class="property-name-label mdc-typography--body1">
            {property.name}
        </div>
    </div>
    <SelectorPropertyBase
        {value}
        options={property.options}
        multiSelector={true}
        placeholder={`Select ${property.name?.toLowerCase()}`}
        readonly={property.readonly}
        calculated={property.calculated}
        description={property.description}
        maxSelect={property.maxSelect}
        enableSelectAll={property.enableSelectAll}
        doubleLine={property.doubleLine}
        on:change={onChange}
    />
</div>

<style>
</style>
