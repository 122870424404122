import type { RGBAHex } from "engine-utils-ts";
import { RGBA } from "engine-utils-ts";
import { EngineMaterialId } from "../pools/EngineMaterialId";
import type { RenderJobUpdater, EngineSubmeshDescription, RenderJobOutput } from "../scene/Submeshes2";
import { ESO_AnotationFlags } from "./ESO";
import type { EngineFullGraphicsSettings } from "../GraphicsSettingsFull";
import { ShaderFlags } from "src/shaders/ShaderFlags";

export const DefaultSelectionColor = RGBA.new(1, 0.55, 0, 0.5);

export class SelectHighlightOverlayJobUpdater implements RenderJobUpdater {
	constructor(
		public readonly materialId: EngineMaterialId,
		public readonly disableInEdit: boolean,
		public readonly selectionMaxColor: RGBAHex,
		public readonly blendInitialColor01: number,
		public readonly lineGeoWidth: number = 5,
	) {

	}

	updaterRenderJob(
		submeshDescription: Readonly<EngineSubmeshDescription<Object>>,
		renderSettings: Readonly<EngineFullGraphicsSettings>,
		output: RenderJobOutput
	): void {


		let highlightPower = 0;
		if (submeshDescription.subObjectRef.isHighlighted) {
			highlightPower += 0.25;
		}
		if (submeshDescription.subObjectRef.isSelected) {
			highlightPower += 0.75;
		}
		
		if (highlightPower) {
			if (this.disableInEdit && (submeshDescription.subObjectRef.rootRef.annotationFlags & ESO_AnotationFlags.IsInEdit)) {
				return;
			}

			output.materialId = this.materialId;
			const objColor = submeshDescription.subObjectRef.colorTint;
			let resultColor: RGBAHex;
			if (objColor && this.blendInitialColor01) {
				resultColor = RGBA.lerpColorOnly(this.selectionMaxColor, objColor, this.blendInitialColor01 - highlightPower);
			} else {
				resultColor = RGBA.multiplyRGB(this.selectionMaxColor, highlightPower);
			}
			output.uniforms.push('color', RGBA.RGBAHexToVec4(resultColor));

			let geoFlags = submeshDescription.geometry._gpuRepr?.shaderInfo?.flags;
			if(geoFlags !== undefined && (geoFlags & ShaderFlags.IS_LINE_GEO))
			{
				output.uniforms.push('lineWidth', this.lineGeoWidth);
			}
		}
	}
}


export const SharedSelectHighlightJobUpdater = new SelectHighlightOverlayJobUpdater(EngineMaterialId.Highlight, true, DefaultSelectionColor, 0);