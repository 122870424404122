import type { Catalog } from "../Catalog";
import type { CatalogItemsUiLabels } from "../CatalogItemsUiLabels";
import type { WireSpecCatalogItemPropsBase } from "./WireSpecCatalogItemPropsBase";
import { registerWireSpecBaseCatalogItemUiLabels } from "./WireSpecCatalogItemPropsBase";

export const MvWireSpecCatalogItemTypeIdent = 'mv-wire-spec';

export interface MvWireSpecCatalogItemProps extends WireSpecCatalogItemPropsBase {}

export function registerMvWireSpecCatalogItemUiLabels(
    provider: CatalogItemsUiLabels,
    catalog: Catalog,
) {
    registerWireSpecBaseCatalogItemUiLabels(MvWireSpecCatalogItemTypeIdent, provider, catalog, 'MV Wire Spec');
}
