import { pilesTypes } from "src";
import { StringProperty, NumberProperty } from "../../properties/PrimitiveProps";
import type { PropertyGroup } from "../../properties/PropertyGroup";
import type { UnitsMapper } from "../../UnitsMapper";
import type { Catalog } from "../Catalog";
import { CatalogItem } from "../CatalogItem";
import type { CatalogItemsUiLabels } from "../CatalogItemsUiLabels";
import { CatalogSource } from "../Source";

export const PileCatalogItemTypeIdentifier = 'pile';

export const pileCatalogItemWeightUnit = NumberProperty.new({
    value: 1,
    unit: 'ft',
})

export interface PileCatalogItemProps extends PropertyGroup {
    pile_type_id: StringProperty;
    price_per_weight: NumberProperty;
}

export function registerPileCatalogItemUiLabels(
    labelsProvider: CatalogItemsUiLabels,
    unitsMapper: UnitsMapper
) {
    labelsProvider.register<PileCatalogItemProps>(
        PileCatalogItemTypeIdentifier,
        (pile) => {
            const price = pile.price_per_weight.valueUnitUiString(unitsMapper) +
                '/' + pileCatalogItemWeightUnit.valueUnitUiString(unitsMapper);
            const title = pilesTypes[pile.pile_type_id.value as keyof typeof pilesTypes]
                .description;
            return {
                group: [[PileCatalogItemTypeIdentifier]],
                title,
                price
            };
        }
    );
}

export function importDefaultPiles(catalog: Catalog) {
    const piles = catalog.catalogItems
        .filter(x => x.typeIdentifier === PileCatalogItemTypeIdentifier);
    const pileTypesPresent = new Set<string>();
    for (const [_id, item] of piles) {
        const pile = item.as<PileCatalogItemProps>();
        pileTypesPresent.add(pile.properties.pile_type_id.value);
    }
    for (const [pileTypeId] of Object.entries(pilesTypes)) {
        if (pileTypesPresent.has(pileTypeId)) {
            // skip
            continue;
        }
        const catalogItem = new CatalogItem<PileCatalogItemProps>(
            undefined,
            'pile',
            {
                price_per_weight: NumberProperty.new({
                    value: 0,
                    unit: 'usd',
                    description: 'pile price per weight unit',
                    range: [0, 100000],
                    step: 0.1,
                }),
                pile_type_id: StringProperty.new({
                    value: pileTypeId,
                    isReadonly: true,
                    description: 'wire gauge',
                }),
            },
            new CatalogSource('default'),
        )
        catalog.catalogItems.allocate([[catalog.catalogItems.reserveNewId(), catalogItem]]);
    }
}
