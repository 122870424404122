<script lang="ts">
    import { MenuItemGroup } from "./ToolbarItems";
    import ToolbarMenuItem from "./ToolbarMenuItem.svelte";

    export let menu: MenuItemGroup;

    let subMenuOpen: boolean;
</script>

<div class="root" on:mouseleave={() => (subMenuOpen = false)}>
    <div
        class="label"
        class:label-open={subMenuOpen}
        on:mouseenter={() => (subMenuOpen = true)}
    >
        <slot></slot>
    </div>
    <div class="anchor-inner mdc-menu-surface--anchor">
        <div
            class:mdc-menu-surface--open={subMenuOpen}
            class="mdc-menu-surface"
        >
            <ul
                class="mdc-deprecated-list text-main-dark mdc-typography--body1"
            >
                {#each menu.children as menuItem, index (menuItem.name + index)}
                    <ToolbarMenuItem
                        {menuItem}
                        hasHierarchy={menuItem instanceof MenuItemGroup &&
                            menu.children.filter(
                                (item) => item.name === menuItem.name,
                            ).length > 1}
                    ></ToolbarMenuItem>
                {/each}
            </ul>
        </div>
    </div>
</div>

<style lang="scss">
    .root {
        position: relative;
        & > .label {
            height: var(--height, 32px);
            padding: 0 12px;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        .anchor-inner {
            position: relative;
            div {
                overflow: visible;
                ul {
                    overflow: visible;
                    padding: 10px 0;
                }
            }
        }
        :global(.mdc-deprecated-list-item) {
            height: 30px;
            min-width: 336px;
        }
    }
    .label-open {
        background-color: rgba(black, 0.04);
    }
</style>
