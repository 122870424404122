<svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.80001 16.2002V3.8002H16.2V16.2002H3.80001ZM3.50001 2.2002C2.78204 2.2002 2.20001 2.78223 2.20001 3.5002V16.5002C2.20001 17.2182 2.78204 17.8002 3.50001 17.8002H16.5C17.218 17.8002 17.8 17.2182 17.8 16.5002V3.5002C17.8 2.78222 17.218 2.2002 16.5 2.2002H3.50001ZM8.85002 8.46523C8.85002 7.83011 9.3649 7.31523 10 7.31523C10.6352 7.31523 11.15 7.83011 11.15 8.46523C11.15 8.48125 11.1423 8.56787 10.9957 8.77338C10.8695 8.95018 10.7069 9.12789 10.4952 9.3593L10.4949 9.35954C10.4555 9.40266 10.4143 9.44765 10.3714 9.49485C9.94587 9.96242 9.29374 10.6833 9.1705 11.6448C9.4232 11.5295 9.7041 11.4652 10 11.4652C10.3231 11.4652 10.6282 11.5418 10.8983 11.6778C10.9941 11.3887 11.2336 11.0732 11.6287 10.639C11.6621 10.6023 11.6971 10.5642 11.7332 10.5249L11.7332 10.5249C11.9417 10.2976 12.1874 10.03 12.3794 9.76095C12.6077 9.44102 12.85 9.00151 12.85 8.46523C12.85 6.89122 11.574 5.61523 10 5.61523C8.42601 5.61523 7.15002 6.89122 7.15002 8.46523H8.85002ZM10.85 13.4652C10.85 13.9347 10.4695 14.3152 10 14.3152C9.53058 14.3152 9.15002 13.9347 9.15002 13.4652C9.15002 12.9958 9.53058 12.6152 10 12.6152C10.4695 12.6152 10.85 12.9958 10.85 13.4652Z"
        fill="currentColor"
    />
</svg>
