import { Aabb2, Matrix3, Vector2 } from "math-ts"
import { VectorPrimitive } from "./VectorPrimitives"

export class Object2D {
    name?: string

    parent?: Object2D

    position: Vector2
    rotation: number
    scale: Vector2

    /**
     * Matrix composed of position+rotation+scale of the current object.
     * should be calculated manually after pos/rot/scale was changed with
     * `.recalculateAabb()`
     */
    matrix: Matrix3

    primitives: VectorPrimitive[]
    children: Object2D[]

    zIndex: number

    /**
     * aabb is the combination of the current object primitives aabbs and
     * children aabb * child.matrix.
     * aabb is not multiplied by the current matrix when this.updateMatrix() is called
     *
     * To find aabb in local space: aabb.applyMatrix(this.matrix)
     */
    aabb: Aabb2

    constructor(primitives: VectorPrimitive[] = []) {
        this.primitives = primitives;
        this.children = []
        this.rotation = 0
        this.position = new Vector2()
        this.scale = new Vector2(1, 1)

        this.matrix = new Matrix3()

        this.aabb = Aabb2.empty();

        this.zIndex = 0;
    }

    add(child: Object2D, idx?: number) {
        if (this.children.includes(child)) {
            return;
        }
        child.removeFromParent();
        if (typeof idx === 'number') {
            idx = Math.min(idx, this.children.length);
            this.children.splice(idx, 0, child);
        } else {
            this.children.push(child);
        }
        child.parent = this;
        return this;
    }

    addAndExpandAabb(...childs: Object2D[]) {
        for (const child of childs) {
            this.add(child);
            this.aabb.union(aabbReused.copy(child.aabb).applyMatrix3(child.matrix))
        }
        return this;
    }

    addPrimitive(primitive: VectorPrimitive) {
        this.primitives.push(primitive);
        return this;
    }

    addPrimitiveAndExpandAabb(...primitives: VectorPrimitive[]) {
        for (const primitive of primitives) {
            primitive.recalculateAabb()
            this.primitives.push(primitive);
            if (!primitive.aabb.isEmpty()) {
                this.aabb.union(primitive.aabb);
            }
        }
        return this;
    }

    remove(child: Object2D) {
        const idx = this.children.indexOf(child);
        if (idx < 0) {
            return
        }
        this.children.splice(idx, 1);
        if (child.parent === this) {
            delete child.parent
        }
    }

    removeFromParent() {
        this.parent?.remove(this);
    }

    updateMatrix(recursive = false) {
        this.matrix
            .makeIdentity()
            .scale(this.scale.x, this.scale.y)
            .rotate(this.rotation)
            .translate(this.position.x, this.position.y)
        if (recursive) {
            for (const child of this.children) {
                child.updateMatrix(recursive)
            }
        }
    }

    recalculateAabb(recursive = false, primitive = false) {
        this.aabb.makeEmpty();

        if (recursive) {
            for (const child of this.children) {
                child.recalculateAabb(recursive, primitive)
            }
        }
        if (primitive) {
            for (const primitive of this.primitives) {
                primitive.recalculateAabb();
            }
        }

        for (const primitive of this.primitives) {
            if (!primitive.aabb.isEmpty()) {
                this.aabb.union(primitive.aabb);
            }
        }

        for (const child of this.children) {
            if (child.aabb.isEmpty()) {
                continue;
            }
            const childAabb = aabbReused.copy(child.aabb).applyMatrix3(child.matrix);
            this.aabb.union(childAabb)
        }

        return this.aabb;
    }

    static clone(
        recursive: boolean,
        source: Object2D,
        target?: Object2D
    ): Object2D {
        target = target ?? new Object2D();
        target.primitives = [...source.primitives];
        target.scale.copy(source.scale);
        target.position.copy(source.position);
        target.rotation = source.rotation;
        target.matrix.copy(source.matrix);
        target.aabb.copy(source.aabb);
        target.name = source.name;
        if (recursive) {
            for (const child of source.children) {
                target.add(child.clone())
            }
        }
        return target;
    }

    clone(recursive = false) {
        return Object2D.clone(recursive, this)
    }

}

const aabbReused = Aabb2.empty();
