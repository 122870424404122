import { Aabb, Aabb2, Matrix2, Matrix3, Matrix4, Vector2, Vector3, Vector4 } from "math-ts";
import { WorkerClassPassRegistry } from "./WorkerClassPassRegistry";

// math-ts is the only internal project this library depends on
// register its classes here, because registering them in math-ts itself is not possible
// because that would create circular reference

WorkerClassPassRegistry.registerClass(Vector2);
WorkerClassPassRegistry.registerClass(Vector3);
WorkerClassPassRegistry.registerClass(Vector4);
WorkerClassPassRegistry.registerClass(Aabb2);
WorkerClassPassRegistry.registerClass(Aabb);
WorkerClassPassRegistry.registerClass(Matrix2);
WorkerClassPassRegistry.registerClass(Matrix3);
WorkerClassPassRegistry.registerClass(Matrix4);
