

export type EntityId<TEnum> = number & TEnum;
export type EntityIdAny = EntityId<any>;

// 32 uint integer allows to have:
// 16 types of entities with ids Range of 0x8_000_000 (134_217_728) each
// and 128 types of entities with ids Range of 0x1_000_000 (16_777_216) each

export const PerTypeIdsRangeBig = 0x8_00_00_00; // 1 << 27
export const PerTypeIdsRangeSmall = 0x1_00_00_00; // 1 << 24

const SmallRangesStartType = 16;
const BigRangesMaxId = PerTypeIdsRangeBig * SmallRangesStartType;


export function entityTypeFromId<TEnumType extends number>(id: EntityId<TEnumType>): TEnumType {
	if (id < BigRangesMaxId) {
		return id >> 27 as TEnumType;
	} else {
		return (id - BigRangesMaxId) >> 24 as TEnumType;
	}
}
(globalThis as any)['_entityTypeFromId'] = entityTypeFromId;

export function rangeLowFromType(ty: number | -1): number {
	if (ty === -1 || ty === 0) {
		return 1;
	} else if (ty < SmallRangesStartType) {
		return PerTypeIdsRangeBig * ty;// + 1;
	} else {
		return PerTypeIdsRangeSmall * ty + BigRangesMaxId;// + 1;
	}
}
export function rangeHighFromType(ty: number | -1): number {
	if (ty === -1) {
		return 1 << 30;
	} else if (ty < SmallRangesStartType) {
		return PerTypeIdsRangeBig * (ty + 1);
	} else {
		return PerTypeIdsRangeSmall * (ty + 1) + BigRangesMaxId;
	}
}

export class IdsProvider<TEnum> {
	lowerRangeBound: number;
	upperRangeBound: number;

	private __nextId: number;

	constructor(entityType: number | -1) {
		if (!(entityType >= -1) || !Number.isInteger(entityType) || entityType > 128 + 16) {
			throw new Error('entity type should be integer in range [-1, 31]');
		}
		this.lowerRangeBound = rangeLowFromType(entityType);
		this.upperRangeBound = rangeHighFromType(entityType);
		this.__nextId = this.lowerRangeBound;
	}

	isValidId(id: EntityId<TEnum>) {
		const lowerRangeBound = this.lowerRangeBound;
		const upperRangeBound = this.upperRangeBound;
		return id >= lowerRangeBound && id < upperRangeBound;
	}

	markOccupied(id: EntityId<TEnum>) {
		if (!(id < this.upperRangeBound && id >= this.lowerRangeBound)) {
			throw new Error('range check: invalid id ' + id);
		}
		if (this.__nextId <= id) {
			this.__nextId = id + 1;
		}
	}

	markIdsOccupied(ids: Iterable<EntityId<TEnum>>) {
		let maxId = 0;
		for (const id of ids) {
			maxId = Math.max(maxId, id);
		}
		this.markOccupied(maxId as EntityId<TEnum>);
	}

	reserveNewId(): EntityId<TEnum> {
		const id = this.__nextId;
		if (id >= this.upperRangeBound) {
			throw new Error('ids provider run out of ids');
		}
		(this.__nextId as number) += 1;
		return id as EntityId<TEnum>;
	}

	mapIntoAnotherTypeRange(id: EntityId<TEnum>, anotherType: number) {
		const inRangeId = id - this.lowerRangeBound;
		return rangeLowFromType(anotherType) + inRangeId;
	}
}

export function splitIdsPerType<TEnumType extends number>(ids: EntityId<TEnumType>[]): Map<TEnumType, EntityId<TEnumType>[]> {
	const perType = new Map<TEnumType, EntityId<TEnumType>[]>();
	ids.sort((n1, n2) => n1 - n2);
	let currType: number | null = null;
	let currIds: EntityId<TEnumType>[] | null = null;
	for (const id of ids) {
		const ty = entityTypeFromId<TEnumType>(id);
		if (currType !== ty) {
			currType = ty;
			currIds = []; // because of sort above, this is guaranteed to execute only once per type
			perType.set(ty, currIds);
		}
		currIds!.push(id);
	}
	return perType;
}

export function splitIdsTuplesPerType<T, TEnumType extends number>(ids: Iterable<[EntityId<TEnumType>, T]>)
	: Map<TEnumType, [EntityId<TEnumType>, T][]>
{
	const perType = new Map<TEnumType, [EntityId<TEnumType>, T][]>();
	let currType: TEnumType | null = null;
	let currPerTy: [EntityId<TEnumType>, T][] | null = null;
	for (const [id, v] of ids) {
		const ty = entityTypeFromId<TEnumType>(id);
		if (currType !== ty) {
			currType = ty;
			currPerTy = perType.get(ty) || [];
			perType.set(ty, currPerTy);
		}
		currPerTy!.push([id, v]);
	}
	return perType;
}

