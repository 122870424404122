import { CostModel as CM } from 'bim-ts';
import { CellClassFunc, ColDef, HeaderClass } from "ag-grid-enterprise";
import { createModifiableCostCellDefinition } from './common';


export function createLaborCostPerUnitColumnDefinition(): ColDef<CM.FlattenedCostCategoryParams> {
    const common = createModifiableCostCellDefinition(x => x.labor?.laborCostPerUnit);

    const headerClass: HeaderClass = [
        ...common.headerClass,
        'ag-grid-cell-with-right-separator'
    ];

    const cellClass: CellClassFunc<CM.FlattenedCostCategoryParams> = (params) => {
        const classes = common.cellClass(params);
        return [
            ...classes,
            'ag-grid-cell-with-right-separator'
        ]
    }
    return {
        colId: CM.LaborCostPerUnitColId,
        headerName: CM.LaborCostPerUnitColId,
        cellEditor: 'agNumberCellEditor',

        ...common,

        cellClass,
        headerClass,
    }
}
