import { NumberProperty } from "bim-ts"
import { Action } from "./actions/Action"
import { CustomAmount } from "./actions/custom-amount/CustomAmount"
import { Hide } from "./actions/hide/Hide"
import { Override } from "./actions/override/Override"
import { QuantityTemplate, QuantityTemplateImportConfigTypeIdentifier } from "./actions/quantity-template/QuantityTemplate"
import { Reference, ReferenceType, ReferenceTypeIdentifier } from "./actions/reference/Reference"
import { TableEntry } from "./TableHierarchy"
import { migrateTemplateDescription, replaceCircuitEnergyByCircuitAggregatedCapacityInPath } from "src/quantities-table/Models"

export enum CostReportTemplateVersion {
    Default = 1,
    ReferenceToBothQuantityAndRate,
    AddProjectSpecificOverrides,
    AddOverrideFlagsToEntriesAndDisableFlagToActions,
    AddUserCreatedFlag,
    RenameCircuitEnergyToCircuitAggregatedCapacity
}

export const CostReportTemplateLatestVersion =
    CostReportTemplateVersion.RenameCircuitEnergyToCircuitAggregatedCapacity;

export interface CostReportTemplate {
    id: number
    name: string
    visible: boolean
    version: CostReportTemplateVersion
    actions: Action[]
    projectSpecificOverriders: Array<ProjectOverride>
}

const usedClasses = {
    Object,
    Action,
    Hide,
    Override,
    QuantityTemplate,
    Reference,
    CustomAmount,
    NumberProperty,
    TableEntry,
}

export function serializeCostReport(obj: any) {
  return JSON.stringify(obj, (_key, value) => {
    const constructorName: string | undefined = value?.constructor?.name;
    if (!constructorName || !(constructorName in usedClasses)) {
        return value;
    }
    return {
      ...value,
      __type: value.constructor.name
    }
  });
}

export function deserializeCostReport(str: string) {
  return JSON.parse(str, (_key, value) => {
    if (value && typeof (value) === "object" && value.__type) {
      // @ts-ignore
      const DynamicClass = usedClasses[value.__type] ?? Object
      value = Object.setPrototypeOf(value, DynamicClass.prototype);
      delete value.__type;
    }
    return value;
  });
}

export interface ProjectOverride {
    projectId: number
    actions: Action[]
}

export function migrateCostReportTemplate(template: CostReportTemplate): CostReportTemplate {
    const allQuantityTemplateActions = [
        ...template.projectSpecificOverriders.flatMap(x => x.actions),
        ...template.actions,
    ].filter((x): x is Action<QuantityTemplate> =>
        x.type === QuantityTemplateImportConfigTypeIdentifier
    );

    // migrate inline template descriptions
    for (const action of allQuantityTemplateActions) {
        if (action.props.template) {
            migrateTemplateDescription(action.props.template)
        }
    }

    if (template.version === undefined) {
        template.version = CostReportTemplateVersion.Default;
    }
    if (template.version < CostReportTemplateVersion.ReferenceToBothQuantityAndRate) {
        for (const action of template.actions) {
            if (action.type === ReferenceTypeIdentifier) {
                action.as<Reference>().props.selfType = ReferenceType.Quantity;
            }
        }
    }
    if (template.version < CostReportTemplateVersion.AddProjectSpecificOverrides) {
        if (!template.projectSpecificOverriders) {
            template.projectSpecificOverriders = [];
        }
    }

    if (template.version < CostReportTemplateVersion.AddOverrideFlagsToEntriesAndDisableFlagToActions) {
        const actions = [
            ...template.actions,
            ...template.projectSpecificOverriders.flatMap(x => x.actions)
        ];
        for (const action of actions) {
            // disable flag
            if (action.disable === undefined) {
                action.disable = false;
            }
            // user created flag
            if (action.userCreated === undefined) {
                action.userCreated = false;
            }
            // entry override flags
            let entry: TableEntry | null = null;
            if (action.props instanceof CustomAmount) {
                entry = action.props.entry;
            } else if (action.props instanceof Override) {
                entry = action.props.entry;
            } else if (action.props instanceof Reference) {
                entry = action.props.entry;
            }
            if (entry) {
                if (entry.rateOverride === undefined) {
                    entry.rateOverride = false;
                }
                if (entry.quantityOverride === undefined) {
                    entry.quantityOverride = false;
                }
            }
        }
    }
    if (template.version < CostReportTemplateVersion.AddUserCreatedFlag) {
        const actions = template.projectSpecificOverriders.flatMap(x => x.actions);
        for (const action of actions) {
          action.userCreated = true;
        }
    }

    if (template.version < CostReportTemplateVersion.RenameCircuitEnergyToCircuitAggregatedCapacity) {
        for (const action of allQuantityTemplateActions) {
            action.props.quantityColumnId = replaceCircuitEnergyByCircuitAggregatedCapacityInPath(
                action.props.quantityColumnId
            );
            action.props.rateUnitColumnId = replaceCircuitEnergyByCircuitAggregatedCapacityInPath(
                action.props.rateUnitColumnId
            );
            action.props.rateColumnId = replaceCircuitEnergyByCircuitAggregatedCapacityInPath(
                action.props.rateColumnId
            );
        }
    }

    template.version = CostReportTemplateLatestVersion;

    return template;
}


