import type { Allocator } from './AllocationSynchronizer';
import { LegacyLogger } from 'engine-utils-ts';

export type ManagedDisposeProcedure<T> = (obj:T) => T;


export interface ManagedAllocatorParams<T> {
	readonly dispose?: ManagedDisposeProcedure<T>;
}

export class ManagedAllocator<T> implements Allocator<T> {

	_capacity: number = 0;
	readonly dispose: ManagedDisposeProcedure<T> | null;

	readonly buffer: T[] = [];

	constructor(params: ManagedAllocatorParams<T>) {
		this.dispose = params.dispose || null;
	}

	allocate(index: number, obj: T): boolean {
		if (!(index >= 0)) {
			return false;
		}
		if (index >= this._capacity) {
			return false;
		}
		this.buffer[index] = obj;
		return true;
	}

	free(indexes: number[]): void {
		for(const index of indexes) {
			if (index < this.buffer.length && index >= 0) {
				if (this.dispose) {
					this.buffer[index] = this.dispose(this.buffer[index]);
				}
			} else {
				LegacyLogger.error('trying to free memory out of bounds', index, this.buffer.length);
			}
		}
	}

	changeCapacity(newCapacity:number) {
		if (newCapacity < this._capacity && this.dispose) {
			for (let i = newCapacity; i < this.buffer.length; ++i){
				const obj = this.buffer[i];
				if (obj) { // todo: better capacity changing
					this.buffer[i] = this.dispose(obj);
				}
			}
		}
		// this.buffer.length = newCapacity;
		this._capacity = newCapacity;
	}
}
