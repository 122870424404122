import { DefaultMap } from "./DefaultMap";
import { LruCache } from "./LruCache";
import { ObjectUtils } from "./ObjectUtils";
import { Success, type Result, Failure } from "./Result";
import type { Writeable } from "./TypeUtils";
import type { ValueAndUnit } from "./types";

export interface PrefixDefinition {
    readonly name: string;
    // x km = x*10^3 m
    readonly toBaseUnitMultiplier: number;
}

/**
 * @example
 * {
 *   length: 2,
 *   mass: -2,
 * } = km2/g2
 */
export type UnitDimension = {
    readonly [measure: string]: number;
};

export type UnitDefinition = {
    readonly name: string;
    readonly dimension: UnitDimension;
    readonly prefixes?: string[];
} & (
    | {
          readonly toBaseUnitMultiplier: number;
          // x K = 1*x - 273 C
          readonly toBaseUnitOffset?: number;
          readonly toBaseUnit?: never;
          readonly fromBaseUnit?: never;
      }
    | {
          // x % = atan(x/100) rad
          readonly toBaseUnit: (x: number) => number;
          // x rad = tan(x)*100 %
          readonly fromBaseUnit: (x: number) => number;
          readonly toBaseUnitMultiplier?: never;
          readonly toBaseUnitOffset?: never;
      }
);

interface Unit {
    readonly name: string;
    readonly prefix?: string;
    readonly power: number;
}

export type UnitsGroup = {
    readonly unitNames: string[];
    readonly defaultUnit?: string;
};

type MappingInput = {
    readonly [fromUnit: string]: {
        [toSystem: string]: string;
    };
};

export class UnitConverter {
    protected _measuresIdCounter = 0;
    protected _measureIdProvider = new DefaultMap<string, number>(() => {
        const newMeasureId = this._measuresIdCounter++;
        return newMeasureId;
    });

    /**
     * { length: 2, mass: 2, time: -1 }
     * =>
     * length = 0, mass = 1, time = 2
     * 2e0 + 2e1 + (10 - 1)e2 = 2 + 20 + 900
     */
    protected _calculateDimensionHash = (dimension: UnitDimension): number => {
        let combinedHash = 0;
        const base = 10;
        for (const measure in dimension) {
            let power = dimension[measure];
            power = power % base;
            if (power < 0) {
                power = base + power;
            }
            const hashComponent =
                power * base ** this._measureIdProvider.getOrCreate(measure);
            combinedHash += hashComponent;
        }
        return combinedHash;
    };

    protected _dimensionDedup = new LruCache<UnitDimension, UnitDimension>({
        maxSize: 10000,
        identifier: "dimension-cache",
        hashFn: this._calculateDimensionHash,
        factoryFn: (x) => ObjectUtils.deepFreeze(x),
    });

    protected _unitsDedup = new LruCache<Unit, Unit>({
        hashFn: (x) => {
            let hash = ObjectUtils.combineHashCodes(
                ObjectUtils.stringHash(x.name),
                x.power,
            );
            if (x.prefix) {
                hash = ObjectUtils.combineHashCodes(
                    hash,
                    ObjectUtils.stringHash(x.prefix),
                );
            }
            return hash;
        },
        maxSize: 1000,
        identifier: "unit-cache",
        factoryFn: (x) => ObjectUtils.deepFreeze(x),
    });

    protected prefixes = new Map<string, PrefixDefinition>();
    protected addPrefixes(prefixes: PrefixDefinition[]) {
        for (const x of prefixes) {
            this.prefixes.set(x.name, x);
        }
        return this;
    }

    protected unitDefinitions = new Map<string, UnitDefinition>();
    protected addUnitDefinitions(units: UnitDefinition[]) {
        for (const x of units as Writeable<UnitDefinition>[]) {
            x.dimension = this._dimensionDedup.get(x.dimension);
            this.unitDefinitions.set(x.name, x);
        }
        return this;
    }

    getUnitDefinition(name: string): UnitDefinition | undefined {
        const unitDef = this.unitDefinitions.get(name);
        return unitDef ? ObjectUtils.deepCloneObj(unitDef) : undefined;
    }

    getUnitsByMeasure(measure: string): Map<string, UnitDefinition> {
        const units = new Map<string, UnitDefinition>(); 
        for (const [unitName, unitDef] of this.unitDefinitions) {
            if (unitDef.dimension[measure]) {
                units.set(unitName, unitDef);
            }
        }
        return units;
    }

    /**
     * @example
     * Metric:
     *   { len: 1, time: -1 }: knot
     *   { len: 1 }: mile
     */
    systems = new DefaultMap<string, Map<UnitDimension, UnitsGroup>>(
        () => new Map(),
    );
    addSystem(
        name: string,
        unitGroupPerDimension: Array<[UnitDimension, UnitsGroup]>,
    ) {
        const unitsPerDimensionMap = this.systems.getOrCreate(name);
        unitsPerDimensionMap.clear();
        for (const [dimension, unitGroup] of unitGroupPerDimension) {
            unitsPerDimensionMap.set(
                this._dimensionDedup.get(dimension),
                unitGroup,
            );
        }
        return this;
    }

    protected unitGroupsOverride = new Map<UnitDimension, UnitsGroup>();
    setUnitGroupsOverride(
        unitGroups: Array<[dimention: UnitDimension, unitsGroup: UnitsGroup]>,
    ) {
        this.unitGroupsOverride.clear();
        for (const [dimension, group] of unitGroups) {
            this.unitGroupsOverride.set(
                this._dimensionDedup.get(dimension),
                group,
            );
        }
        this.clearCache();
    }

    /**
     * @example
     * km2:
     *   Imperial: ac
     * in:
     *   Metric: cm
     */
    protected mappings = new DefaultMap<Unit, Map<string, ReadonlyArray<Unit>>>(
        () => new Map(),
    );
    addMappings(mappings: MappingInput) {
        for (const [fromUnitStr, perUnit] of Object.entries(mappings)) {
            for (const [toSystem, toUnitsStr] of Object.entries(perUnit)) {
                const fromUnit = this.parseUnit(fromUnitStr);
                const toUnits = this.parseUnits(toUnitsStr);
                this.mappings.getOrCreate(fromUnit).set(toSystem, toUnits);
            }
        }
        return this;
    }

    private _dimensionsOfUnitsCache = new WeakMap<
        ReadonlyArray<Unit>,
        Readonly<UnitDimension>
    >();

    getDimensionsOfUnits(units: ReadonlyArray<Unit>): Readonly<UnitDimension> {
        let dimensions: Readonly<UnitDimension> | undefined =
            this._dimensionsOfUnitsCache.get(units);
        if (dimensions) {
            return dimensions;
        }
        const resultingDimension = new DefaultMap<string, number>(() => 0);
        for (const unit of units) {
            const unitDef = this.unitDefinitions.get(unit.name);
            if (!unitDef) {
                throw new Error("unit not found " + unit.name);
            }
            for (const [measure, measurePower] of Object.entries(
                unitDef.dimension,
            )) {
                resultingDimension.set(
                    measure,
                    resultingDimension.getOrCreate(measure) +
                        unit.power * measurePower,
                );
            }
        }
        const calculatedDimensions: Writeable<UnitDimension> = {};
        for (const [k, v] of resultingDimension) {
            if (v === 0) {
                continue;
            }
            calculatedDimensions[k] = (calculatedDimensions[k] ?? 0) + v;
        }
        dimensions = this._dimensionDedup.get(calculatedDimensions);
        this._dimensionsOfUnitsCache.set(units, dimensions);
        return dimensions;
    }

    getDimensionsOfUnitsString(unitsStr: string): Readonly<UnitDimension> {
        const units = this.parseUnits(unitsStr);
        return this.getDimensionsOfUnits(units);
    }

    getConverterFnFromTo(from: string, to: string): (v: number) => number {
        if (from == to) {
            return (v) => v;
        }
        from = this.normalizeUnits(from);
        to = this.normalizeUnits(to);
        if (from == to) {
            return (v) => v;
        }

        const fromUnits = this.parseUnits(from);
        const toUnits = this.parseUnits(to);

        if (fromUnits.length === 1 && toUnits.length === 1) {
            const from = fromUnits[0];
            const to = toUnits[0];
            if (from.name === to.name && from.power === to.power) {
                // only prefixes are different, return simple multiplication function
                // let multiplier = 1;
                // if (from.prefix) {
                //     const prefix = this.prefixes.get(from.prefix);
                //     if (!prefix) {
                //         return () => {throw new Error('prefix not found ' + from.prefix)};
                //     }
                //     multiplier *= prefix.toBaseUnitMultiplier;
                // }
                // if (to.prefix) {
                //     const prefix = this.prefixes.get(to.prefix);
                //     if (!prefix) {
                //         return () => {throw new Error('prefix not found ' + from.prefix)};
                //     }
                //     multiplier /= prefix.toBaseUnitMultiplier;
                // }
                // return (v) => v * multiplier;
            }
        }

        return (v) => this.convertValue(v, from, to);
    }

    convertValue(val: number, fromStr: string, toStr: string) {
        if (fromStr === toStr) {
            return val;
        }
        const fromStrNormalized = this.normalizeUnits(fromStr);
        const toStrNormalized = this.normalizeUnits(toStr);
        if (fromStrNormalized === toStrNormalized) {
            return val;
        }

        const fromUnits = this.parseUnits(fromStrNormalized);
        const toUnits = this.parseUnits(toStrNormalized);

        const fromDimensions = this.getDimensionsOfUnits(fromUnits);
        const toDimensions = this.getDimensionsOfUnits(toUnits);

        // dimensions should be equal
        if (fromDimensions !== toDimensions) {
            console.error(
                `dimensions does not match ${fromStr} ${toStr}`,
                fromDimensions,
                toDimensions,
            );
            throw new Error(`dimensions does not match ${fromStr} ${toStr}`);
        }

        if (!fromUnits.length && !toUnits.length) {
            return val;
        }

        // standalone units with power of 1 case.
        // convertion includes offset
        if (fromUnits.length === 1 && toUnits.length === 1)
            standalone: {
                const iters: Array<
                    [
                        units: ReadonlyArray<Unit>,
                        toBase: boolean,
                        fromBase: boolean,
                    ]
                > = [
                    [fromUnits, true, false],
                    [toUnits, false, true],
                ];
                // check if units are valid for standalone case
                for (const [units, toBase, fromBase] of iters) {
                    const unit = units[0];
                    const unitDefinition = this.unitDefinitions.get(unit.name);
                    if (!unitDefinition) {
                        throw new Error(
                            `no unit definition found for ${unit.name}`,
                        );
                    }
                    const measures = Object.keys(unitDefinition.dimension);
                    if (measures.length !== 1) {
                        break standalone;
                    }
                    const power = unitDefinition.dimension[measures[0]];
                    if (power !== 1) {
                        break standalone;
                    }
                    if (unit.power !== 1) {
                        break standalone;
                    }
                    let prefixMultiplier = 1;
                    if (unit.prefix) {
                        const prefixDefinition = this.prefixes.get(unit.prefix);
                        if (!prefixDefinition) {
                            throw new Error(`no prefix found ${unit.prefix}`);
                        }
                        prefixMultiplier =
                            prefixDefinition.toBaseUnitMultiplier;
                    }
                    if (toBase) {
                        // make convertion to base units
                        val *= prefixMultiplier;
                        val = unitDefinition.toBaseUnit
                            ? unitDefinition.toBaseUnit(val)
                            : val * unitDefinition.toBaseUnitMultiplier +
                              (unitDefinition.toBaseUnitOffset ?? 0);
                    } else if (fromBase) {
                        // make convertion to new units
                        val = unitDefinition.fromBaseUnit
                            ? unitDefinition.fromBaseUnit(val)
                            : (val - (unitDefinition.toBaseUnitOffset ?? 0)) /
                              unitDefinition.toBaseUnitMultiplier;
                        val /= prefixMultiplier;
                    }
                }
                return val;
            }

        // convert multi unit group
        const numeratorResult = { val: val };
        const denominatorResult = { val: 1 };
        const iters: Array<
            [units: ReadonlyArray<Unit>, toBase: boolean, fromBase: boolean]
        > = [
            [fromUnits, true, false],
            [toUnits, false, true],
        ];
        for (const [allUnits, toBase, fromBase] of iters) {
            const numeratorUnits: Unit[] = [];
            const denominatorUnits: Unit[] = [];
            for (const unit of allUnits) {
                if (unit.power > 0) {
                    numeratorUnits.push(unit);
                } else {
                    denominatorUnits.push(unit);
                }
            }
            const iters: Array<[result: { val: number }, units: Unit[]]> = [
                [numeratorResult, numeratorUnits],
                [denominatorResult, denominatorUnits],
            ];
            // km2 => 1000 m2
            for (const [result, units] of iters) {
                for (const unit of units) {
                    const unitDef = this.unitDefinitions.get(unit.name)!;
                    const prefix =
                        typeof unit.prefix === "string"
                            ? this.prefixes.get(unit.prefix)
                            : undefined;
                    if (!unitDef.toBaseUnitMultiplier) {
                        throw new Error(
                            "unit " +
                                unit.name +
                                " is not compatible in unit-group conversion",
                        );
                    }
                    const prefixMultiplier = prefix?.toBaseUnitMultiplier ?? 1;
                    const power = Math.abs(unit.power);

                    if (toBase) {
                        result.val *=
                            (unitDef.toBaseUnitMultiplier * prefixMultiplier) **
                            power;
                    } else if (fromBase) {
                        result.val /=
                            (unitDef.toBaseUnitMultiplier * prefixMultiplier) **
                            power;
                    }
                }
            }
        }
        return numeratorResult.val / denominatorResult.val;
    }

    /**
     * m2:
     *   Imperial: in
     * ha:
     *   Metric: m2
     */
    protected convertUnitToSystemCache = new DefaultMap<
        Unit,
        DefaultMap<string, ReadonlyArray<Unit>>
    >(() => new DefaultMap<string, ReadonlyArray<Unit>>(() => []));
    convertUnitsToSystem(unitsStr: string, toSystem: string) {
        if (!unitsStr) {
            return unitsStr;
        }
        const units = this.parseUnits(unitsStr);
        const result: Unit[] = [];
        for (const unit of units) {
            const powerSign = Math.sign(unit.power);
            const positiveUnit = this.parseUnit(
                this.stringifyUnit({
                    ...unit,
                    power: Math.abs(unit.power),
                }),
            );
            const toUnits = this.convertUnitToSystem(positiveUnit, toSystem);
            for (const toUnit of toUnits) {
                result.push({
                    ...toUnit,
                    power: toUnit.power * powerSign,
                });
            }
        }
        return this.stringifyUnits(result);
    }
    protected convertUnitToSystem(
        unit: Unit,
        toSystem: string,
    ): ReadonlyArray<Unit> {
        const backupResult: Unit[] = [unit];
        const cachedPerUnit = this.convertUnitToSystemCache.getOrCreate(unit);
        const cached = cachedPerUnit.get(toSystem);
        if (cached) {
            return cached;
        }
        const unitDef = this.unitDefinitions.get(unit.name);
        if (!unitDef) {
            throw new Error("no unit found " + unit.name);
        }
        const baseSystem = this.systems.get(toSystem);
        if (!baseSystem) {
            throw new Error("no system found " + toSystem);
        }
        const system: typeof baseSystem = new Map();
        baseSystem.forEach((g, d) => system.set(d, g));
        this.unitGroupsOverride.forEach((g, d) => system.set(d, g));
        const sameSystem = !!system
            .get(unitDef.dimension)
            ?.unitNames.includes(unit.name);
        if (sameSystem) {
            // unit is already in correct system
            cachedPerUnit.set(toSystem, backupResult);
            return backupResult;
        }

        // check mappings
        tryMappings: {
            const mappingsPerUnit = this.mappings.get(unit);
            if (!mappingsPerUnit) {
                // if mapping not found, try find mapping for first power of unit
                if (unit.power !== 1) {
                    const subUnit = this._unitsDedup.get({
                        ...unit,
                        power: 1,
                    });
                    const subUnitResult = this.convertUnitToSystem(
                        subUnit,
                        toSystem,
                    );
                    // add power
                    const result: Unit[] = [];
                    for (const x of subUnitResult) {
                        result.push(
                            this._unitsDedup.get({
                                ...x,
                                power: x.power * unit.power,
                            }),
                        );
                    }
                    cachedPerUnit.set(toSystem, result);
                    return result;
                }
                break tryMappings;
            }
            const mappedUnits = mappingsPerUnit.get(toSystem);
            if (!mappedUnits) {
                break tryMappings;
            }
            for (const unit of mappedUnits) {
                const unitDefinition = this.unitDefinitions.get(unit.name);
                if (
                    !unitDefinition ||
                    !system
                        .get(unitDefinition.dimension)
                        ?.unitNames.includes(unit.name)
                ) {
                    throw new Error(
                        "system " +
                            toSystem +
                            " does not have unit " +
                            unit.name,
                    );
                }
            }
            cachedPerUnit.set(toSystem, mappedUnits);
            return mappedUnits;
        }

        // check dimensions from system
        tryDefaultSystemDimension: {
            // if unit exist in system dimensions with convert destinations present
            const dimensionUnits = system.get(unitDef.dimension);
            if (!dimensionUnits) break tryDefaultSystemDimension;
            const defaultUnitStr: string | undefined =
                dimensionUnits.defaultUnit ?? dimensionUnits.unitNames[0];
            if (typeof defaultUnitStr !== "string") {
                break tryDefaultSystemDimension;
            }
            // default unit found
            const defaultUnit = this.parseUnit(defaultUnitStr);
            const result = [defaultUnit];
            cachedPerUnit.set(toSystem, result);
            return result;
        }

        //// if unit dimension is not 1, replace with group
        //// of default units with power for each dimension
        //// { length: -2 } => { length: 1 }**-2
        //standaloneComposed: {
        //    const result: Unit[] = [];
        //    for (const [measure, power] of Object.entries(unitDef.dimension)) {
        //        const dimension: Dimension = { [measure]: 1 };
        //        const defUnits = system.get(dimension);
        //        if (!defUnits) break standaloneComposed;
        //        const newUnitStr: string | undefined =
        //            defUnits.defaultUnit ?? defUnits.unitNames[0];
        //        const newUnit = this.serializer.parseUnit(newUnitStr);
        //        const newDef = this.unitDefinitions.get(newUnit.name);
        //        if (!newDef) break standaloneComposed;
        //        newUnit.power = power;
        //        result.push(newUnit);
        //    }
        //    this.convertUnitToSystemCache.set(cacheKey, result);
        //    return result;
        //}

        // if any other convertion option was unsuccessful, then return as it is
        cachedPerUnit.set(toSystem, backupResult);
        return backupResult;
    }

    convertValueToSystem(
        val: number,
        fromUnitsStr: string,
        toSystem: string,
    ): [
        val: number,
        unitsStr: string,
        //units: Unit[]
    ] {
        const fromUnits = this.parseUnits(fromUnitsStr);
        const result: Unit[] = [];
        for (const fromUnit of fromUnits) {
            const toUnits = this.convertUnitToSystem(fromUnit, toSystem);
            result.push(...toUnits);
        }
        return [
            this.convertValue(val, fromUnitsStr, this.stringifyUnits(result)),
            this.stringifyUnits(result),
            //toUnits,
        ];
    }

    withPredefinedUnits() {
        this.addPrefixes([
            // https://en.wikipedia.org/wiki/Metric_prefix
            {
                name: "G",
                toBaseUnitMultiplier: 1e9,
            },
            {
                name: "M",
                toBaseUnitMultiplier: 1e6,
            },
            {
                name: "k",
                toBaseUnitMultiplier: 1e3,
            },
            {
                name: "d",
                toBaseUnitMultiplier: 1e-1,
            },
            {
                name: "c",
                toBaseUnitMultiplier: 1e-2,
            },
            {
                name: "m",
                toBaseUnitMultiplier: 1e-3,
            },
        ]);
        this.addUnitDefinitions([
            // length
            {
                dimension: { length: 1 },
                name: "m",
                toBaseUnitMultiplier: 1,
                prefixes: ["m", "c", "d", "k"],
            },
            {
                dimension: { length: 1 },
                name: "ft",
                toBaseUnitMultiplier: 0.3048,
                prefixes: ["k"],
            },
            {
                dimension: { length: 1 },
                name: "mi",
                toBaseUnitMultiplier: 1609.344,
            },
            {
                dimension: { length: 1 },
                name: "yd",
                toBaseUnitMultiplier: 0.9144,
            },
            {
                dimension: { length: 1 },
                name: "in",
                toBaseUnitMultiplier: 0.0254,
            },
            // each
            {
                dimension: { each: 1 },
                name: "each",
                toBaseUnitMultiplier: 1,
            },
            // TODO: remove inch unit
            {
                dimension: { length: 1 },
                name: "inch",
                toBaseUnitMultiplier: 0.0254,
            },
            // angle
            {
                dimension: { angle: 1 },
                name: "rad",
                toBaseUnitMultiplier: 1,
            },
            {
                dimension: { angle: 1 },
                name: "deg",
                toBaseUnitMultiplier: (1 / 180) * Math.PI,
            },
            {
                dimension: { angle: 1 },
                name: "grad",
                toBaseUnitMultiplier: (9 / 10 / 180) * Math.PI,
            },
            {
                dimension: { angle: 1 },
                name: "arcmin",
                toBaseUnitMultiplier: (1 / 60 / 180) * Math.PI,
            },
            {
                dimension: { angle: 1 },
                name: "arcsec",
                toBaseUnitMultiplier: (1 / 60 / 60 / 180) * Math.PI,
            },
            {
                dimension: { angle: 1 },
                name: "%",
                toBaseUnit: (x) => Math.atan(x / 100),
                fromBaseUnit: (x) => Math.tan(x) * 100,
            },
            // mass
            {
                dimension: { mass: 1 },
                name: "g",
                toBaseUnitMultiplier: 1,
                prefixes: ["m", "k"],
            },
            {
                dimension: { mass: 1 },
                name: "t",
                toBaseUnitMultiplier: 1e6,
                prefixes: ["k", "M"],
            },
            {
                dimension: { mass: 1 },
                name: "oz",
                toBaseUnitMultiplier: 28.3495,
            },
            {
                dimension: { mass: 1 },
                name: "lb",
                toBaseUnitMultiplier: 453.592,
            },
            {
                dimension: { mass: 1 },
                name: "st",
                toBaseUnitMultiplier: 6350.29,
            },

            //// electricity
            // resistance
            {
                dimension: { resistance: 1 },
                // deprecated
                name: "Om",
                toBaseUnitMultiplier: 1,
                prefixes: ["k", "m", "M"],
            },
            {
                dimension: { resistance: 1 },
                name: "Ohm",
                toBaseUnitMultiplier: 1,
                prefixes: ["k", "m", "M"],
            },
            // power
            {
                dimension: { power: 1 },
                name: "W",
                toBaseUnitMultiplier: 1,
                prefixes: ["k", "m", "M"],
            },
            // energy
            {
                dimension: { energy: 1 },
                name: "Wh",
                toBaseUnitMultiplier: 1,
                prefixes: ["k", "m", "M", "G"],
            },
            // Amp
            {
                dimension: { current: 1 },
                name: "A",
                toBaseUnitMultiplier: 1,
                prefixes: ["k", "m", "M"],
            },
            // volt
            {
                dimension: { voltage: 1 },
                name: "V",
                toBaseUnitMultiplier: 1,
                prefixes: ["k", "m", "M"],
            },
            // temperature
            {
                dimension: { temperature: 1 },
                name: "C",
                toBaseUnitMultiplier: 1,
            },
            {
                dimension: { temperature: 1 },
                name: "K",
                toBaseUnitOffset: -273.15,
                toBaseUnitMultiplier: 1,
            },
            {
                dimension: { temperature: 1 },
                name: "F",
                // (100°F − 32) × 5/9 = 37.778°C
                toBaseUnitOffset: (-32 * 5) / 9,
                toBaseUnitMultiplier: 5 / 9,
            },
            // price
            {
                dimension: { price: 1 },
                name: "usd",
                toBaseUnitMultiplier: 1,
            },
            {
                dimension: { price: 1 },
                name: "eur",
                toBaseUnitMultiplier: 1,
            },
            // area
            {
                dimension: { length: 2 },
                name: "ha",
                toBaseUnitMultiplier: 10000,
            },
            {
                dimension: { length: 2 },
                name: "ac",
                toBaseUnitMultiplier: 4046.86,
            },
            // time
            {
                dimension: { time: 1 },
                name: "s",
                toBaseUnitMultiplier: 1,
            },
        ]);
        this.addSystem("Metric", [
            [{ length: 1 }, { defaultUnit: "m", unitNames: ["m"] }],
            [{ mass: 1 }, { defaultUnit: "kg", unitNames: ["g", "t"] }],
            [{ length: 2 }, { unitNames: ["ha"] }],
            [{ each: 1 }, { unitNames: ["each"] }],
            [{ angle: 1 }, { defaultUnit: "deg", unitNames: ["deg", "%"] }],
            //[{ price: 1 }, { unitNames: ['eur'] }]
        ]);
        this.addSystem("Imperial", [
            [
                { length: 1 },
                {
                    defaultUnit: "ft",
                    unitNames: ["ft", "in", "inch", "yd", "mi"],
                },
            ],
            [{ mass: 1 }, { defaultUnit: "lb", unitNames: ["lb", "oz", "st"] }],
            [{ length: 2 }, { unitNames: ["ac"] }],
            [{ each: 1 }, { unitNames: ["each"] }],
            [{ angle: 1 }, { defaultUnit: "deg", unitNames: ["deg", "%"] }],
            //[{ price: 1 }, { unitNames: ['usd'] }]
        ]);
        this.addMappings({
            // length mappings
            mm: { Imperial: "in" },
            cm: { Imperial: "in" },
            m: { Imperial: "ft" },
            km: { Imperial: "kft" },
            in: { Metric: "cm" },
            // TODO: remove inch unit
            inch: { Metric: "cm" },
            ft: { Metric: "m" },
            kft: { Metric: "km" },
            mi: { Metric: "km" },
            yd: { Metric: "m" },
            // area mappings
            ha: { Imperial: "ac" },
            ac: { Metric: "ha" },
            // volume mappings
            m3: { Imperial: "yd3" },
            // mass mappings
            lb: { Metric: "kg" },
            kg: { Imperial: "lb" },
            t: { Imperial: "st" },
            st: { Metric: "t" },
            oz: { Metric: "g" },
        });
        return this;
    }

    // parsing/stringifing single unit
    protected stringifyUnit(unit: Unit) {
        const power = Math.abs(unit.power);
        return `${unit.prefix ?? ""}${unit.name}${power > 1 ? power : ""}`;
    }
    protected parseUnitCache = new Map<string, Readonly<Unit>>();
    /**
     * @example
     * "<prefix><unit><power>"
     *
     * @example
     * "km3"
     */
    protected parseUnit(unit: string): Readonly<Unit> {
        const originalStr = unit;
        const cached = this.parseUnitCache.get(unit);
        if (cached) {
            return cached;
        }
        // try parse power
        const powerStrResult = new RegExp(/\d+/).exec(unit);
        let power = 1;
        if (powerStrResult) {
            unit = unit.substring(0, powerStrResult.index);
            const parsed = Number.parseInt(powerStrResult[0]);
            if (Number.isInteger(parsed)) power = parsed;
        }

        // find longest-length unit
        let matchingUnitDef!: UnitDefinition | undefined;
        for (const unitDef of this.unitDefinitions.values()) {
            if (unit.endsWith(unitDef.name)) {
                // check length
                if (
                    matchingUnitDef &&
                    matchingUnitDef.name.length > unitDef.name.length
                ) {
                    break;
                }
                matchingUnitDef = unitDef;
            }
        }

        if (!matchingUnitDef) {
            throw new Error("unit is not found: " + unit);
        }

        const result: Writeable<Unit> = {
            power: power,
            name: matchingUnitDef.name,
        };

        unit = unit.substring(0, unit.length - matchingUnitDef.name.length);

        // try parse prefix
        if (unit.length) {
            const prefix = this.prefixes.get(unit)?.name;
            if (!prefix) {
                throw new Error('prefix "' + unit + '" not found');
            }
            result.prefix = prefix;
        }

        const cachedResult = this._unitsDedup.get(result);
        this.parseUnitCache.set(originalStr, cachedResult);
        return cachedResult;
    }

    // parse/stringify numerator units ([km2*Om]-numerator/[c]-denominator)
    protected stringifyNumeratorUnits(units: Unit[]) {
        const result: string[] = [];
        for (const unit of units) {
            result.push(this.stringifyUnit(unit));
        }
        return result.join("*");
    }
    protected parseNumeratorUnitsCache = new Map<string, Readonly<Unit>[]>();
    /**
     * @example
     * "<unit-comp> * <unit-comp>    <unit-comp>*<unit-comp>"
     *
     * @example
     * "km3 * kg    C*W"
     */
    protected parseNumeratorUnits(units: string): Readonly<Unit>[] {
        // unit matches
        if (units === "") {
            return [];
        }
        const matches = units.match(/[^\s^*]+/g);
        if (matches === null) {
            throw new Error("str is not valid unit string " + units);
        }
        const result: Array<Unit> = [];
        for (const match of matches) {
            if (match === "1") {
                continue;
            }
            result.push(this.parseUnit(match));
        }
        return result;
    }

    // parse/stringify general units string km2*s/C
    protected stringifyUnits(units: Iterable<Unit>) {
        const numerator: string[] = [];
        const denominator: string[] = [];
        for (const unit of units) {
            if (unit.power > 0) {
                numerator.push(this.stringifyUnit(unit));
            } else if (unit.power < 0) {
                denominator.push(this.stringifyUnit(unit));
            }
        }
        const numeratorStr =
            !numerator.length && denominator.length ? "1" : numerator.join("*");
        const denominatorStr = !denominator.length
            ? ""
            : "/" + denominator.join("*");
        return numeratorStr + denominatorStr;
    }
    protected parseUnitsCache = new Map<string, Readonly<Unit>[]>();
    /**
     * @example
     * "<unit-line> / <unit-line>"
     *
     * @example
     * "km3 * kg / C*W"
     */
    parseUnits(units: string): ReadonlyArray<Unit> {
        const cached = this.parseUnitsCache.get(units);
        if (cached) {
            return cached;
        }
        const lines = units.split("/");
        if (lines.length < 1 || lines.length > 2) {
            throw new Error(
                "unit string has invalid number of lines: " +
                    lines.length +
                    "; " +
                    units,
            );
        }
        const numerator = lines[0];
        const denominator = lines[1] ?? "1";
        const result: Unit[] = [];
        const numeratorParsed = this.parseNumeratorUnits(numerator);
        result.push(...numeratorParsed);
        const denominatorParsed = this.parseNumeratorUnits(denominator);
        for (const unit of denominatorParsed) {
            result.push(
                this._unitsDedup.get({
                    ...unit,
                    power: -Math.abs(unit.power),
                }),
            );
        }
        this.parseUnitsCache.set(units, result);
        return result;
    }

    tryParseUnits(units: string): Result<ReadonlyArray<Unit>> {
        try {
            return new Success(this.parseUnits(units));
        } catch (e) {
            return new Failure(e.message);
        }
    }

    protected normalizeUnits(units: string) {
        const unitsObjs = this.parseUnits(units);
        const unitsStr = this.stringifyUnits(unitsObjs);
        return unitsStr;
    }

    isValidUnits(units: string) {
        try {
            if (units === "") {
                return new Success(units);
            }
            return new Success(!!this.normalizeUnits(units));
        } catch (e) {
            return new Failure(e.message);
        }
    }

    clearCache() {
        this.convertUnitToSystemCache.clear();
    }

    reverseUnits(unitsStr: string) {
        if (!unitsStr.length) {
            return "";
        }
        const units = this.parseUnits(unitsStr);
        const newUnits: Unit[] = [];
        for (const unit of units) {
            newUnits.push({ ...unit, power: unit.power * -1 });
        }
        return this.stringifyUnits(newUnits);
    }

    combineUnits(aUnits: string, bUnits: string) {
        const commonUnits = new Map<string, Readonly<Unit>>();
        for (const units of [aUnits, bUnits]) {
            if (!units.length) {
                continue;
            }
            for (const unit of this.parseUnits(units)) {
                if (!commonUnits.has(unit.name)) {
                    commonUnits.set(unit.name, unit);
                }
            }
        }
        const units = Array.from(commonUnits.values());
        if (!units.length) {
            return "";
        }
        return this.stringifyUnits(units);
    }

    toShortest(initialValueUnit: ValueAndUnit): ValueAndUnit {
        const initialNumValue = initialValueUnit.value;
        const positiveNumValue = Math.abs(initialNumValue);
        const initialUnitStr = initialValueUnit.unit;

        if (!initialUnitStr) {
            return initialValueUnit;
        }
        if (initialValueUnit.value === 0) {
            return initialValueUnit;
        }

        let initialUnitParsed: Unit | null = null;
        try {
            initialUnitParsed = this.parseUnit(initialUnitStr);
        } catch (e) {
            return initialValueUnit;
        }
        if (!initialUnitParsed) {
            return initialValueUnit;
        }
        const unitDefinition = this.getUnitDefinition(initialUnitParsed.name);
        if (!unitDefinition) {
            return initialValueUnit;
        }
        const unitsToCheck = new Set<string>([unitDefinition.name]);
        {
            for (const systemDimensions of this.systems.values()) {
                const sameDimensionUnitGroup = systemDimensions.get(
                    unitDefinition.dimension,
                );
                if (
                    !sameDimensionUnitGroup?.unitNames.includes(
                        unitDefinition.name,
                    )
                ) {
                    continue;
                }
                sameDimensionUnitGroup.unitNames.forEach((x) =>
                    unitsToCheck.add(x),
                );
            }
        }
        const variants: ValueAndUnit[] = [];
        for (const unitToCheck of unitsToCheck) {
            const unitDefinition = this.getUnitDefinition(unitToCheck);
            if (!unitDefinition) {
                continue;
            }
            for (const prefix of [
                undefined,
                ...(unitDefinition.prefixes ?? []),
            ]) {
                const unit: Unit = {
                    prefix,
                    power: initialUnitParsed.power,
                    name: unitDefinition.name,
                };
                const unitStr = this.stringifyUnit(unit);
                const converted = this.convertValue(
                    positiveNumValue,
                    initialUnitStr,
                    unitStr,
                );
                variants.push({ value: converted, unit: unitStr });
            }
        }
        variants.sort((l, r) => l.value - r.value);
        const moreOrEqualThanOne = variants.filter((x) => x.value >= 1);
        let unit: string;
        if (moreOrEqualThanOne.length) {
            unit = moreOrEqualThanOne[0].unit!;
        } else {
            unit = variants[variants.length - 1].unit!;
        }
        return {
            unit,
            value: this.convertValue(initialNumValue, initialUnitStr, unit),
        };
    }

    static toShortestCurrency(value: number): PrefixedCurrency {
        const prefixes: CurrencyPrefix[] = [
            { multiplier: 1e3, prefixName: "thousand", prefixNameShort: "k" },
            { multiplier: 1e6, prefixName: "million", prefixNameShort: "m" },
            { multiplier: 1e9, prefixName: "billion", prefixNameShort: "b" },
        ];
        const currencyNoPrefixed: PrefixedCurrency = {
            value,
            prefix: { multiplier: 1, prefixName: "", prefixNameShort: "" },
        };
        const variations: PrefixedCurrency[] = [];

        for (const prefix of prefixes) {
            variations.push({ prefix, value: value / prefix.multiplier });
        }

        variations.sort((l, r) => l.value - r.value);
        const moreOrEqualThanOne = variations.filter((x) => x.value >= 1);

        return moreOrEqualThanOne[0] ?? currencyNoPrefixed;
    }
}

export interface CurrencyPrefix {
    multiplier: number;
    prefixName: string;
    prefixNameShort: string;
}

export interface PrefixedCurrency {
    value: number;
    prefix: CurrencyPrefix;
}

export const unitsConverter = new UnitConverter().withPredefinedUnits();

if (typeof window !== "undefined") {
    (window as any).unitsConverter = unitsConverter;
}

export function convertThrow(value: number, from: string, to: string): number {
    //return unitConverter.convert(value).from(from).to(to);
    return unitsConverter.convertValue(value, from, to);
}

export function convertUnits(
    value: number,
    from: string,
    to: string,
): Result<number> {
    if (from == to) {
        return new Success(value);
    }
    try {
        //return new Success(unitConverter.convert(value).from(from).to(to));
        return new Success(unitsConverter.convertValue(value, from, to));
    } catch (e) {
        return new Failure(e);
    }
}

export function doUnitDimensionsMatch(unit1: string, unit2: string): boolean {
    if (unit1 === unit2) {
        return true;
    }
    return (
        unitsConverter.getDimensionsOfUnitsString(unit1) ===
        unitsConverter.getDimensionsOfUnitsString(unit2)
    );
}

export const currenciesCustomSymbols: { [unit: string]: string } = {
    usd: "$",
    eur: "€",
};

export const currencySymbolToName: { [unit: string]: string } = {
    $: "usd",
    "€": "eur",
};

export function replaceCurrencyUnitWithSymbol(unit: string) {
    return currenciesCustomSymbols[unit] ?? unit;
}
