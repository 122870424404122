import type { VersionedValue } from 'engine-utils-ts';
import { LegacyLogger } from 'engine-utils-ts';
import { Unit, UnitType } from './EngineConsts';
import type { UnitsMapper } from 'bim-ts';

export interface EngineUnits {
	length: Unit;
	area: Unit;
}

export const FootLength = 0.3048;
export const InchLength = FootLength / 12;

export class EngineLegacyUiUnits implements VersionedValue {

	lengthUnit: Unit;
	areaUnit: Unit;

	_version: number = 0;

	_lastUnitsMappperVersion: number = -1;

	constructor() {
		this.lengthUnit = Unit.FootInch;
		this.areaUnit = Unit.FootPow2;
	}

	version(): number {
		return this._version;
	}

	isImperial(): boolean {
		return this.lengthUnit == Unit.FootInch;
	}

	updateIfNecessary(unitsMapper: UnitsMapper) {
		if (unitsMapper.version() == this._lastUnitsMappperVersion) {
			return;
		}
		this._lastUnitsMappperVersion = unitsMapper.version();
		const {unit} = unitsMapper.mapToConfigured({value: 1, unit: 'm'});
		if (unit == 'm') {
			this.setUnits({length: Unit.Meter, area: Unit.MeterPow2});
		} else {
			this.setUnits({length: Unit.FootInch, area: Unit.FootPow2});
		}
	}

	getUnits(): EngineUnits {
		return {
			length: this.lengthUnit,
			area: this.areaUnit,
		}
	}

	setUnits(units: Partial<EngineUnits>) {
		if (units['length'] == Unit.Meter || units['length'] == Unit.FootInch) {
			this.lengthUnit = units['length'];
		}
		if (units['area'] == Unit.FootPow2 || units['area'] == Unit.MeterPow2) {
			this.areaUnit = units['area'];
		}
		// if (units.volume == Unit.) {
		// 	this.areaVolume = units.volume;
		// }
		this._version++;
	}

	getRulerSnatchIntervals(): [number, number] {
		if (this.lengthUnit == Unit.FootInch) {
			return [FootLength, InchLength];
		}
		return [1.0, 0.1];
	}

	convertToStringWithUnits(value: number, type: UnitType): string {
		// return `0m1m2m3m4m5m6m7m8m9`;
		// return `'m"mfmtm?m²m³`;
		let resultString: string;
		if (!(value > 0 && value < Infinity)) {
			LegacyLogger.error('unit invalid');
			resultString = '?';
		} else if (type == UnitType.Length) {
			if (this.lengthUnit == Unit.FootInch) {

                const feet = (value / FootLength).toFixed(2);
                return `${feet}'`;

				// const feetInt = Math.floor(value / FootLength);
				// const inchesDecimal = (value - feetInt * FootLength) / InchLength;
				// const feetString = feetInt > 0 ? `${feetInt.toFixed(0)}'` : '';
				// const inchesString = inchesDecimal > 0 ? `${inchesDecimal.toFixed(feetInt >= 5 ? 0 : 1)}"` : '';
				// resultString = feetString + inchesString;
			} else {
				resultString = `${value.toFixed(2)}m`
			}
		} else if (type == UnitType.Area) {
			if (this.areaUnit == Unit.FootPow2) {
				resultString = `${(value / (FootLength * FootLength)).toFixed(2)}ft²`
			} else {
				resultString = `${(value).toFixed(2)}m²`
			}
		} else {
			LegacyLogger.error('unkown unit type');
			resultString = '-';
		}
		return resultString;
	}
}

export interface MeasureLabelCopyArgs {
	valueSi: number;
	type: UnitType;
	uiString: string;
	event: MouseEvent;
}



