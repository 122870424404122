<script lang="ts">
    import type { ItemErrorMsg } from "ui-bindings";
    import { Button, ButtonComponent, ButtonType } from "../../../libui/button";

    export let message: ItemErrorMsg;
</script>

<div class="mdc-typography--body2 text-danger error-message">
    {#each message as msg}
        {#if typeof msg === "string"}
            <span>{msg}</span>
        {:else if typeof msg === "object"}
            <ButtonComponent
                desc={new Button(msg.label, ButtonType.Text, (e) => {
                    if (typeof msg === "object") {
                        msg.action();
                    }
                })}
            />
        {/if}
    {/each}
</div>

<style lang="scss">
    .error-message {
        white-space: pre-line;
        height: auto;
        & :global(.mdc-button) {
            height: 11px;
            font-size: 11px;
            line-height: 16px;
            padding: 0;
            color: var(--color-accent);
        }
    }
</style>
