// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { CatalogItemT } from './catalog-item.js';
import { CatalogItem } from './catalog-item.js';
import type { TypeIdentifierVersionsT } from './type-identifier-versions.js';
import { TypeIdentifierVersions } from './type-identifier-versions.js';


export class CatalogItemCollection implements flatbuffers.IUnpackableObject<CatalogItemCollectionT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):CatalogItemCollection {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsCatalogItemCollection(bb:flatbuffers.ByteBuffer, obj?:CatalogItemCollection):CatalogItemCollection {
  return (obj || new CatalogItemCollection()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsCatalogItemCollection(bb:flatbuffers.ByteBuffer, obj?:CatalogItemCollection):CatalogItemCollection {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new CatalogItemCollection()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

formatVersion():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

ids(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
}

idsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

idsArray():Uint32Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? new Uint32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

collection(index: number, obj?:CatalogItem):CatalogItem|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new CatalogItem()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

collectionLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

propsGroupsVersionsPerTypeIdent(obj?:TypeIdentifierVersions):TypeIdentifierVersions|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new TypeIdentifierVersions()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

customPropsVersionsPerTypeIdent(obj?:TypeIdentifierVersions):TypeIdentifierVersions|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new TypeIdentifierVersions()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startCatalogItemCollection(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addFormatVersion(builder:flatbuffers.Builder, formatVersion:number) {
  builder.addFieldInt32(0, formatVersion, 0);
}

static addIds(builder:flatbuffers.Builder, idsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, idsOffset, 0);
}

static createIdsVector(builder:flatbuffers.Builder, data:number[]|Uint32Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createIdsVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createIdsVector(builder:flatbuffers.Builder, data:number[]|Uint32Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt32(data[i]!);
  }
  return builder.endVector();
}

static startIdsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addCollection(builder:flatbuffers.Builder, collectionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, collectionOffset, 0);
}

static createCollectionVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startCollectionVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addPropsGroupsVersionsPerTypeIdent(builder:flatbuffers.Builder, propsGroupsVersionsPerTypeIdentOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, propsGroupsVersionsPerTypeIdentOffset, 0);
}

static addCustomPropsVersionsPerTypeIdent(builder:flatbuffers.Builder, customPropsVersionsPerTypeIdentOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, customPropsVersionsPerTypeIdentOffset, 0);
}

static endCatalogItemCollection(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishCatalogItemCollectionBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset);
}

static finishSizePrefixedCatalogItemCollectionBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, undefined, true);
}


unpack(): CatalogItemCollectionT {
  return new CatalogItemCollectionT(
    this.formatVersion(),
    this.bb!.createScalarList<number>(this.ids.bind(this), this.idsLength()),
    this.bb!.createObjList<CatalogItem, CatalogItemT>(this.collection.bind(this), this.collectionLength()),
    (this.propsGroupsVersionsPerTypeIdent() !== null ? this.propsGroupsVersionsPerTypeIdent()!.unpack() : null),
    (this.customPropsVersionsPerTypeIdent() !== null ? this.customPropsVersionsPerTypeIdent()!.unpack() : null)
  );
}


unpackTo(_o: CatalogItemCollectionT): void {
  _o.formatVersion = this.formatVersion();
  _o.ids = this.bb!.createScalarList<number>(this.ids.bind(this), this.idsLength());
  _o.collection = this.bb!.createObjList<CatalogItem, CatalogItemT>(this.collection.bind(this), this.collectionLength());
  _o.propsGroupsVersionsPerTypeIdent = (this.propsGroupsVersionsPerTypeIdent() !== null ? this.propsGroupsVersionsPerTypeIdent()!.unpack() : null);
  _o.customPropsVersionsPerTypeIdent = (this.customPropsVersionsPerTypeIdent() !== null ? this.customPropsVersionsPerTypeIdent()!.unpack() : null);
}
}

export class CatalogItemCollectionT implements flatbuffers.IGeneratedObject {
constructor(
  public formatVersion: number = 0,
  public ids: (number)[] = [],
  public collection: (CatalogItemT)[] = [],
  public propsGroupsVersionsPerTypeIdent: TypeIdentifierVersionsT|null = null,
  public customPropsVersionsPerTypeIdent: TypeIdentifierVersionsT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const ids = CatalogItemCollection.createIdsVector(builder, this.ids);
  const collection = CatalogItemCollection.createCollectionVector(builder, builder.createObjectOffsetList(this.collection));
  const propsGroupsVersionsPerTypeIdent = (this.propsGroupsVersionsPerTypeIdent !== null ? this.propsGroupsVersionsPerTypeIdent!.pack(builder) : 0);
  const customPropsVersionsPerTypeIdent = (this.customPropsVersionsPerTypeIdent !== null ? this.customPropsVersionsPerTypeIdent!.pack(builder) : 0);

  CatalogItemCollection.startCatalogItemCollection(builder);
  CatalogItemCollection.addFormatVersion(builder, this.formatVersion);
  CatalogItemCollection.addIds(builder, ids);
  CatalogItemCollection.addCollection(builder, collection);
  CatalogItemCollection.addPropsGroupsVersionsPerTypeIdent(builder, propsGroupsVersionsPerTypeIdent);
  CatalogItemCollection.addCustomPropsVersionsPerTypeIdent(builder, customPropsVersionsPerTypeIdent);

  return CatalogItemCollection.endCatalogItemCollection(builder);
}
}
