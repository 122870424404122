<script lang="ts">
    import { onMount, getContext } from "svelte";

    import { PUI_Lazy, type UiBindings } from "ui-bindings";
    import {
        ButtonComponent,
        Button,
        ButtonType,
        IconButton,
    } from "../libui/button";
    import { IconComponent } from "../libui/icon";
    import {
        ImportStatus,
        type FilesImportsHandler,
    } from "./FilesImportsHandler";
    import { requestUserFileInput } from "./RequestUserFileInput";
    import PUI_Root from "../pui/PUI_Root.svelte";
    import EngineUiPanel from "../ui-panels/EngineUiPanel.svelte";
    import { getCivilPluginInstaller } from "files-converters";
    import { NavbarContext } from "../navbar/Navbar";

    let filesImportsHandler = getContext<FilesImportsHandler>(
        "filesImportsHandler",
    );
    let uiBindings = getContext<UiBindings>("uiBindings");
    const navbar = <NavbarContext>getContext("ui-navbar");
    let dropZone: HTMLDivElement;

    onMount(() => {
        filesImportsHandler.addDragDropListner(dropZone);
        return () => {
            filesImportsHandler.dispose(dropZone);
            filesImportsHandler.clear();
        };
    });

    $: importFilesExtensions = Array.from(
        uiBindings.filesImporters.values(),
    ).flatMap((v) => v[1].fileExtensions);

    $: uploadedFile = $filesImportsHandler.file;
    $: fileSize = Math.round((uploadedFile?.size || 0) / 1000);
    $: fileName = uploadedFile
        ? uploadedFile.filename.slice(0, -uploadedFile.extension.length)
        : "";

    function getPlugin() {
        getCivilPluginInstaller(uiBindings);
    }
    function openGuide() {
        window.open(
            "https://intercom-help.eu/PVFARM/en/articles/96386-how-to-convert-your-existing-dwg-layout-ready-for-pvfarm",
        );
    }
</script>

<EngineUiPanel>
    <div
        class="import-panel mdc-typography--body1"
        on:dragover|stopPropagation|preventDefault={() => null}
        on:drag|stopPropagation|preventDefault={() => null}
    >
        <div
            class="dropzone-container"
            class:uploaded={!!uploadedFile}
            bind:this={dropZone}
        >
            {#if uploadedFile}
                <div class="uploaded-zone">
                    <div class="uploaded-file">
                        <div class="file-info">
                            <span>Uploaded</span>
                            <span>
                                {fileName.length > 40
                                    ? `${fileName.slice(0, 40)}..`
                                    : fileName}{uploadedFile.extension},
                            </span>
                            <span>{fileSize} kB</span>
                        </div>
                        {#if $filesImportsHandler.status !== ImportStatus.Finished}
                            <ButtonComponent
                                desc={new IconButton("Close", () => {
                                    filesImportsHandler.clear();
                                })}
                            />
                        {/if}
                    </div>
                    <div class="mdc-typography--body2 text-main-medium">
                        {#each $filesImportsHandler.messages as message}
                            <div class:text-danger={message.isError}>
                                {message.text}
                            </div>
                        {/each}
                    </div>
                </div>
            {:else}
                <div class="dropzone">
                    <ButtonComponent
                        compact={true}
                        desc={new Button(
                            "Browse",
                            ButtonType.Unelevated,
                            () => {
                                requestUserFileInput(
                                    importFilesExtensions,
                                    filesImportsHandler.importFile,
                                );
                            },
                            false,
                            "Folder",
                        )}
                    />
                    <span>or Drag and drop file here</span>
                </div>
            {/if}
        </div>
        {#if $filesImportsHandler.form}
            <PUI_Root
                configIdentifier={"import-result"}
                puiSource={new PUI_Lazy($filesImportsHandler.form)}
            />
        {:else if $filesImportsHandler.status === ImportStatus.NotStarted}
            <div class="content">
                <span class="mdc-typography--headline5">
                    From another software
                </span>
                <ul class="format-list">
                    <li>
                        DXF with 2D (flat) layout with solar arrays,
                        transformers, roads, and boundaries
                    </li>
                    <li>
                        KML, KMZ with Boundaries (buildable area, include and
                        exclude zones)
                    </li>
                    <li>GeoTIFF with Terrain data</li>
                    <li>PNG with Images</li>
                </ul>
            </div>
            <div class="divider"></div>
            <div class="content">
                <span class="mdc-typography--headline5"> PVFARM file </span>
                <ul class="format-list">
                    <li>
                        <span>PVFM files with terrain data</span>
                        <div
                            class="guide-button mdc-typography--body1-strong"
                            on:click={getPlugin}
                        >
                            <span>
                                Get PVFarm/PVFM plugin for AutoCAD and Civil3D
                            </span>
                            <IconComponent name="Download" />
                        </div>
                        <div
                            class="guide-button mdc-typography--body1-strong"
                            on:click={openGuide}
                        >
                            <span>
                                Check how to prepare your DWG layout for Import
                            </span>
                            <IconComponent name="OpenExternal" />
                        </div>
                    </li>
                    <li>
                        BIMASSET with any objects, from single equipment item to
                        complete layout.<br />
                        Imported equipment will not be available for layout generation
                        and augmentation.
                    </li>
                    <li>
                        PVFARMCOST with customised values for the cost report.
                        Customised costs previously set for this project will be
                        completely replaced.
                    </li>
                </ul>
            </div>
            <div class="divider"></div>
            <div class="content">
                <span class="mdc-typography--headline5">
                    Upload equipment to the catalog
                </span>
                <ul class="format-list">
                    <li style:list-style-type="none">
                        <span>Open </span>
                        <span
                            class="guide-button mdc-typography--body1-strong"
                            on:click={() => {
                                navbar.openTab("View.Catalog");
                            }}
                        >
                            <span>Catalog</span>
                            <IconComponent name="OpenWindow" />
                        </span>
                        <span>
                            to upload equipment and make it available for the
                            layout generation and augmentation in all projects:
                        </span>
                    </li>
                    <li>BIMASSET with the single object only</li>
                    <li>PV Module or Inverter as a PAN/OND file</li>
                </ul>
            </div>
        {/if}
        {#if $filesImportsHandler.status === ImportStatus.Finished}
            <div class="button-container">
                <div class="container-gap" />
                <div class="action-button">
                    <ButtonComponent
                        desc={new Button("Ok", ButtonType.Unelevated, () => {
                            filesImportsHandler.clear();
                        })}
                    />
                </div>
            </div>
        {/if}
    </div>
</EngineUiPanel>

<style lang="scss">
    .dropzone-container {
        min-height: 96px;
        margin: 12px 16px;
        padding: 1px;
        border-radius: 1px;
        border: 2px dashed var(--color-ultralight);
        &.uploaded {
            border-width: 1px;
            border-style: solid;
        }
    }
    .dropzone {
        border-radius: 2px;
        height: 96px;
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        gap: 4px;
        justify-content: center;
        align-items: center;
        background-color: var(--color-alt);
    }
    .uploaded-zone {
        height: 100%;
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .format-list {
        margin: 0;
        padding-left: 20px;
        li {
            margin-top: 8px;
        }
    }
    .uploaded-file {
        display: flex;
        align-items: center;
        gap: 4px;

        & :global(.custom-icon-container) {
            padding: 5px 8px;
            box-sizing: border-box;
        }
    }
    .file-info {
        display: flex;
        line-height: 18px;
        flex-wrap: wrap;
        gap: 4px;
        text-align: start;
    }
    .content {
        margin: 12px 16px 0;
    }

    .button-container {
        display: flex;
        gap: 4px;
        margin: 12px 16px 0;
        .container-gap,
        .action-button {
            flex: 1;
        }
        .action-button :global(button) {
            width: 100%;
        }
    }

    .import-panel :global(.group) {
        background-color: transparent;
    }
    .import-panel :global(.group.root-level) {
        margin-bottom: 0;
    }
    .import-panel :global(.group[data-name="summary"]) {
        position: sticky;
        bottom: 0;
        margin-bottom: 0 !important;
        padding-bottom: 16px;
        background-color: white;
        z-index: 1;
    }
    .import-panel :global(.ui-config-property.property-row .property-value),
    .import-panel :global(.ui-config-property.property-row .property-name),
    .import-panel :global(.ui-config-property.property-row .checkbox-holder),
    .import-panel :global(.ui-config-property.property-row .buttons) {
        flex: 1;
    }

    .guide-button {
        cursor: pointer;
        margin-top: 4px;
        vertical-align: bottom;
        span {
            vertical-align: middle;
        }
        :global(svg) {
            vertical-align: middle;
            margin-left: 2px;
        }
    }
    .divider {
        border-top: 1px solid var(--color-divider);
        margin: 16px;
    }
</style>
