import { Failure, LazyDerived } from "engine-utils-ts";
import { Matrix4 } from "math-ts";
import type { Bim } from "../Bim";
import type { BimPropertyData } from "../bimDescriptions/BimProperty";
import { BimProperty } from "../bimDescriptions/BimProperty";
import { extractValueUnitPropsGroup } from "../bimDescriptions/NamedBimPropertiesGroup";
import type { PropertiesGroupFormatters } from "../bimDescriptions/PropertiesGroupFormatter";
import { PropertiesGroupFormatter } from "../bimDescriptions/PropertiesGroupFormatter";
import type { BimGeometries } from "../geometries/BimGeometries";
import { GraphGeometry } from "../geometries/GraphGeometries";
import type { BasicAnalyticalRepresentation } from "../representation/Representations";
import type { ReactiveSolverBase, SolverInstancePatchResult } from "../runtime/ReactiveSolverBase";
import { SolverObjectInstance } from "../runtime/SolverObjectInstance";
import type { CostsConfigProvider, ExpandLegacyPropsWithCostTableLinks} from "src/cost-model/capital";
import { createFocusLinkOnSample, mergeCostComponents, sumCostComponents } from "src/cost-model/capital";
import { EstimateCostGlobal } from "./EquipmentCommon";
import { getRoadDefaultCostsPerSquareMeter } from "src/cost-model/capital/tables/categories/civil/roads";
import { PUI_GroupNode } from "ui-bindings";

export const RoadTypeIdent = 'road';


export function registerRoad(bim: Bim) {
    bim.instances.archetypes.registerArchetype(
		{
			type_identifier: RoadTypeIdent,
			mandatoryProps: [
                { path: ['cost_bs', 'level 1'], value: "SITE WORK SUBTOTAL", },
                { path: ['cost_bs', 'level 2'], value: "Roads", },
                { path: ['road', 'width'], value: 16, unit: 'ft', numeric_range: [0.01, 100]},
			]
		}
	);

	bim.reactiveRuntimes.registerRuntimeSolver(roadDimensionsSolver(bim.allBimGeometries));
}

function roadDimensionsSolver(geometries: BimGeometries): ReactiveSolverBase {

    const objectsDefaultArgs = {
        representationAnalytical: null as BasicAnalyticalRepresentation | null,
        worldMatrix: new Matrix4(),
        legacyProps: {
            width: BimProperty.NewShared({path: ["road", "width"], value: 0, unit: 'm'}),
        },
    };

    return new SolverObjectInstance({
        solverIdentifier: "road-dimensions",
        objectsIdentifier: "road",
        objectsDefaultArgs,
        cache: false,
        solverFunction: (args, globals) => {

            const repr = args.representationAnalytical;

			let length = 0;

            if (repr) {
                const geo = geometries.peekById(repr.geometryId);
				if (geo instanceof GraphGeometry) {
					for (const [p1, p2] of geo.iterEdgesPoints()) {
						const d = p1.distanceTo(p2);
						length += d;
					}
				}
            }

            const area = length * args.legacyProps.width.as('m');

            return {
                legacyProps: [
                    { path: ["dimensions", "length"], value: length, unit: "m" },
                    { path: ["dimensions", "area"], value: area, unit: "m2" },
                ]
            };
        },
    });
}

export const RoadKeyPropertiesGroupDefinition = {
    width: BimProperty.NewShared({
        path: ['road', 'width'],
        value: 0,
    })
} as const;

export function registerRoadKeyPropertiesGroupFormatter(group: PropertiesGroupFormatters) {
    group.register(
        RoadTypeIdent,
        new PropertiesGroupFormatter(
            RoadKeyPropertiesGroupDefinition,
            (props, unitsMapper) => {
                return `Road ${props.width.valueUnitUiString(unitsMapper)}`
            }
        )
    )
}

export const RoadPricingProps = {
    width: BimProperty.NewShared({
        path: ['road', 'width'],
        value: 0,
        unit: 'ft',
    }),
    area: BimProperty.NewShared({
        path: ['dimensions', 'area'],
        value: 0,
        unit: 'ft2',
    }),
};

interface RoadPricingArg {
    props: typeof RoadPricingProps
}

function createRoadPricePatch(
    instanceRelatedArgs: RoadPricingArg,
    commonArgs: EstimateCostGlobal,
    keyPropsFormatter: PropertiesGroupFormatters,
): SolverInstancePatchResult {
    const props = instanceRelatedArgs.props;
    // find matching road
    const area = props.area.as('m2');


    const costs = mergeCostComponents(commonArgs.estimate?.costs, getRoadDefaultCostsPerSquareMeter());

    const costPerMeter = costs && sumCostComponents(costs) || 0;

    const fullCost = costPerMeter * area;

    const ref = keyPropsFormatter.formatNamedProps(RoadTypeIdent, {
        width: instanceRelatedArgs.props.width,
    } as typeof RoadKeyPropertiesGroupDefinition)

    const patch: BimPropertyData[] = [
        {
            path: ['cost', 'cost_item'],
            value: ref ?? 'unknown road',
            readonly: true,
        },
        {
            path: ['cost', 'total_cost'],
            value: fullCost,
            unit: 'usd',
            readonly: true,
        },
    ];
    return {
        legacyProps: patch,
        removeProps: [
            BimProperty.MergedPath(['cost', 'has_zero_costs']),
            BimProperty.MergedPath(['cost', 'status']),
            BimProperty.MergedPath(['cost', 'per_area_cost']),
        ]
    }
}

export function registerRoadPricingSolver(bim: Bim, costs: CostsConfigProvider) {
    const global = LazyDerived.new1(
        'roadPricingGlobal',
        [bim.unitsMapper],
        [costs.createLazyEstimateById(RoadPricingPerSquareMeterIdent)],
        ([estimate]) => {
            return new EstimateCostGlobal(estimate, bim.unitsMapper);
        }
    )

    const RoadPricingSharedArgIdent = 'road-pricing-shared-arp-ident';

    bim.runtimeGlobals.registerByIdent(
        RoadPricingSharedArgIdent,
        global
    );

    bim.reactiveRuntimes.registerRuntimeSolver(new SolverObjectInstance({
        solverIdentifier: 'road-pricing-solver',
        objectsDefaultArgs: {
            legacyProps: RoadPricingProps,
        },
        objectsIdentifier: RoadTypeIdent,
        globalArgsSelector: {
            [RoadPricingSharedArgIdent]: EstimateCostGlobal,
        },
        solverFunction: (props, globals) => {
            const valueUnitOnlyProps = extractValueUnitPropsGroup(props.legacyProps);
            const shared = globals[RoadPricingSharedArgIdent];
            if (shared instanceof Failure) {
                return {}
            }
            const result = createRoadPricePatch({ props: valueUnitOnlyProps }, shared.value, bim.keyPropertiesGroupFormatter);
            return result;
        }
    }));
}

export const RoadPricingPerSquareMeterIdent = 'road-per-square-meter';

export const expandRoadPropsWithCostTableLinks: ExpandLegacyPropsWithCostTableLinks = (params) => {
    createFocusLinkOnSample({
        costModelFocusApi: params.costModelFocusApi,
        type_identifier: RoadTypeIdent,
        targetPui: PUI_GroupNode.tryGetNestedChild(params.pui, ['cost']),
    })
}
