import type { Result} from './Result';
import { Failure, Success } from './Result';
import { Yield } from './TasksRunner';

export enum PromiseStatus {
    InProgress,
    Done,
    Rejected,
}

export class PollablePromise<T> {

    private _status: PromiseStatus = PromiseStatus.InProgress; // excessive, should remove
    private _result: Result<T> | undefined = undefined;

    constructor(promise: Promise<T>) {
        promise
            .then(r => { this._result = new Success(r); this._status = PromiseStatus.Done })
            .catch((e) => { this._result = new Failure(e); this._status = PromiseStatus.Rejected });
    }

    status(): PromiseStatus {
        return this._status;
    }

    asResult(): Result<T> {
        if (this._result === undefined) {
            throw new Error('Pollable promise is in progress, cant get result');
        }
        return this._result;
    }

    getResult(): T {
        if (!(this._result instanceof Success)) {
            throw new Error('Status is not Done: ' + PromiseStatus[this._status]);
        }
        return this._result.value;
    }

    getError(): any {
        if (!(this._result instanceof Failure)) {
            throw new Error('Status is not Rejected: ' + PromiseStatus[this._status]);
        }
        return this._result!;
    }

    *generatorWaitForResult(): Generator<Yield, Result<T>> {
        while (this.status() == PromiseStatus.InProgress) {
            yield Yield.NextFrame as any; // IterableIterator types union is inconvinient for this function usage
        }
        return this.asResult();
    }

    static *generatorWaitFor<T>(promise: Promise<T>): Generator<Yield, Result<T>> {
        const pollable = new PollablePromise(promise);
        while (pollable.status() == PromiseStatus.InProgress) {
            yield Yield.NextFrame as any; // IterableIterator types union is inconvinient for this function usage
        }
        return pollable.asResult();
    }

    static *generatorWaitForOrThrow<T>(promise: Promise<T>): Generator<Yield, T> {
        const pollable = new PollablePromise(promise);
        while (pollable.status() == PromiseStatus.InProgress) {
            yield Yield.NextFrame as any; // IterableIterator types union is inconvinient for this function usage
        }
        const res = pollable.asResult();
        if (res instanceof Success) {
            return pollable.getResult();
        }
        throw new Error(res.toString());
    }
}
