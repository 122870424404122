import { isInNode } from "../EnvChecks";
import type { WorkerMsg, WorkerMsgEvent } from "./WorkerMsg";
import { NodeWorker } from "./NodeWorker";
import { WebWorker } from "./WebWorker";

export interface WorkerOptions {
    name: string;
}

export interface WorkerWrapper {
    postMessage(message: WorkerMsg[], options?: { transfer: ArrayBufferLike[] }): void;
    onMessage(cb: (msgEvent: WorkerMsgEvent<WorkerMsg | WorkerMsg[]>) => void): void;
    onMessageError(cb: (message: WorkerMsgEvent<WorkerMsg> | Error) => void): void;
    onError(cb: (errorMsg: string) => void): void;

    terminate(): void;
}

export function createWorkerInstance(workerPath: string, options: WorkerOptions): WorkerWrapper | Promise<WorkerWrapper> { 
    if(isInNode()){
        return NodeWorker.new(workerPath, options);
    } else {
        return new WebWorker(workerPath, options);
    }
}

export interface WorkerEventsHandler {
    onMessage: (cb: (msg: WorkerMsg | WorkerMsg[]) => void) => void;
    onMessageError: (cb: (msg: WorkerMsgEvent<WorkerMsg | WorkerMsg[]> | Error) => void) => void;
    postMessage: (msg: WorkerMsg | WorkerMsg[], options?: { transfer: ArrayBufferLike[] }) => void;
}