import type { Bim, EntitiesCollectionUpdates, IdBimScene, SceneInstance} from "bim-ts";
import { handleEntitiesUpdates, SceneObjDiff } from "bim-ts";
import type { ScopedLogger} from "engine-utils-ts";
import { StreamAccumulator } from "engine-utils-ts";
import type { ESO_Any } from "../esos/ESO";
import type { ESOsCollection } from "./ESOsCollection";




export class BimInstancesSyncer {

	readonly _bim: Bim;

	readonly _logger: ScopedLogger;
    readonly _bimInstancesUpdatesAccumulator: StreamAccumulator<EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>>;
	private _engineSceneObjects: ESOsCollection;

	constructor(
		logger: ScopedLogger,
		bim: Bim,
		engineSceneObjects: ESOsCollection,
	) {
		this._logger = logger.newScope('objs-bim-synced');
		this._bim = bim;
		this._engineSceneObjects = engineSceneObjects;
        this._bimInstancesUpdatesAccumulator = new StreamAccumulator(bim.instances.updatesStream);
	}

	
    dispose() {
        this._bimInstancesUpdatesAccumulator.dispose();
        // this._geometriesUpdates.dispose();
    }

	anyPendingUpdates() {
		return !this._bimInstancesUpdatesAccumulator.isEmpty();
	}

	createEngineSceneObjectFor(
        instance: SceneInstance,
    ): ESO_Any {
		for (const handler of this._engineSceneObjects.esosHandlers) {
			const obj = handler.tryCreateESO(instance);
			if (obj) {
				return obj;
			}
		}
		throw new Error('could not create engine object for ' + instance.type_identifier + instance.representation);
    }

	syncToBim() {
        const bimUpdates = this._bimInstancesUpdatesAccumulator.consume();
        if (!bimUpdates) {
            return;
        }
		const bimInstances = this._bim.instances;


        handleEntitiesUpdates(
			bimUpdates,
            (allocatedIds) => {
				const engineObjectsToAlloc: [IdBimScene, ESO_Any][] = [];
                for (const id of allocatedIds) {
                    const state = bimInstances.peekById(id);
                    if (!state) {
                        continue;
                    }
                    const engineObject = this.createEngineSceneObjectFor(state);
					engineObjectsToAlloc.push([id, engineObject]);
                }
				engineObjectsToAlloc.sort((t1, t2) => t1[1].constructor.name.localeCompare(t2[1].constructor.name));
				this._engineSceneObjects.allocate(engineObjectsToAlloc);
            },
            (perIdDiffs) => {

				const ReprChangeFlags = SceneObjDiff.Representation
					| SceneObjDiff.GeometryReferenced
					| SceneObjDiff.RepresentationLowDetail
					| SceneObjDiff.RepresentationAnalytical;


				const toUpdate: [IdBimScene, SceneObjDiff][] = [];
				const toRealloc: [IdBimScene, ESO_Any][] = [];

				const engineObjects = this._engineSceneObjects;
				const bimInstances = this._bim.instances;

                for (const [id, diff] of perIdDiffs) {
					if (diff & ReprChangeFlags) {

                        const currEso = engineObjects.peekById(id);
                        const instance = bimInstances.peekById(id);
						if (!currEso || !instance) {
							continue;
						}
						const eso = this.createEngineSceneObjectFor(instance);

						if (currEso.constructor === eso.constructor) {
							toUpdate.push([id, diff]);
						} else {
							toRealloc.push([id, eso]);
						}
                    } else {
						toUpdate.push([id, diff]);
					}
                }
				if (toRealloc.length) {
					engineObjects.deleteByIds(toRealloc.map(t => t[0]));
					engineObjects.allocate(toRealloc);
				}
				if (toUpdate.length) {
					this._engineSceneObjects.propogateBimUpdatesToEngineObjs(toUpdate);
				}
            },
            (removed) => {
				this._engineSceneObjects.deleteByIds(removed);
            }
        );
    }
}

