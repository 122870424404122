<script lang="ts">
    import { ChevronDown } from "../../libui/icon/svg";
    import { Vector2 } from "math-ts";
    import Lock from '../../libui/icon/svg/lock.svelte';

    export let value: string | undefined;
    export let readonly: boolean = false;
    export let onClick: (position: Vector2) => void;
    export let tag: string = "";

    let div: HTMLElement;
</script>

<div class="property" bind:this={div}>
    <div
        class="read-only"
        class:clickable = {!readonly}
        on:click={() => {
            if (!readonly) {
                const offsets = div.getBoundingClientRect();
                const pos = new Vector2(
                    offsets.x,
                    offsets.y + offsets.height + 2
                );
                onClick(pos);
            }
        }}
    >
        <span class="property-name-label mdc-typography--body1 selected">
            {value ?? ""}
        </span>

        <div class="value-control">
            {#if readonly}
                <Lock />
            {:else if tag}
                <span>{tag}</span>
            {:else}
                <span class="expand_more">
                    <ChevronDown />
                </span>
            {/if}
        </div>
    </div>
</div>

<style lang="scss">
    .value-control {
        display: flex;
        gap: 4px;
        align-items: center;
    }
    .expand_more {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: var(--color-main-dark);
    }
</style>
