import type {
    EventStackFrame, LazyVersioned,
    Observer,
    Result,
    UndoStack
} from 'engine-utils-ts';
import {
    Allocated, Deleted, Failure, IterUtils, LazyDerived, ObjectUtils, OneToMany, Success, Updated
} from 'engine-utils-ts';
import type { EntityId } from 'verdata-ts';

import { EntitiesBase } from '../collections/EntitiesBase';
import type { EntitiesCollectionUpdates } from '../collections/EntitiesCollectionUpdates';
import { EntitiesLazyLists } from '../collections/EntitiesLazyLists';
import { EmptyPropsStub } from '../properties/EmptyPropsStub';
import type { PropertyGroup } from '../properties/PropertyGroup';
import { producePatchedProps, type PropsGroupBase } from '../properties/Props';
import type { IdBimScene, SceneInstances } from '../scene/SceneInstances';
import { ConfigsArchetypes, StateType } from './ConfigsArchetypes';
import { ConfigsResolver } from './ConfigsResolver';
import { registerAugmentSubareasResolver } from './configTypes/AugmentConfig';
import { autoFillBoundariesToBuildableArea } from './configTypes/FarmLayoutConfigType';

export enum ConfigIdType {
	Default = -1,
}

export type IdConfig = EntityId<ConfigIdType>|0;

export class Config {
	constructor(
		public type_identifier: string = '',
        public connectedTo: IdBimScene | 0 = 0,
		public properties: PropertyGroup = {},
        public props: PropsGroupBase = EmptyPropsStub,
	) { }

    get<T extends PropertyGroup>(){
        return this.properties as T;
    }
    
    propsAs<T>(propsClass: { new(args: any): T }): T {
        if (!(this.props instanceof propsClass)) {
            throw new Error(`propsAs: ${this.props} is not an instance of ${propsClass}`);
        }
        return this.props as T;
    }
}

export type ConfigPatch = Omit<Partial<Config>, 'type_identifier' | "connectedTo" | "get" | "propsAs">;
export class ConfigsCollection extends EntitiesBase<
    Config,
    IdConfig,
    number,
    ConfigPatch
> {
    public readonly archetypes: ConfigsArchetypes;
    private readonly connectedToRefs: OneToMany<IdBimScene | 0, IdConfig>;
    public readonly typesRefs: OneToMany<string, IdConfig>;
    private readonly observer: Observer;
    private readonly deleteItemsObserver: Observer;
    private readonly sceneInstances: SceneInstances;
    private readonly connectedInstances: Map<IdBimScene, string>;
    private readonly resolvers: ConfigsResolver;
 
    _lazyLists: EntitiesLazyLists<Config, IdConfig, number>;

    constructor(sceneInstances:SceneInstances, undoStack?: UndoStack) {
        super({
            identifier: "bim-configs",
            idsType: ConfigIdType.Default,
            T_Constructor: Config,
            undoStack,
        });
        this.connectedInstances = new Map();
        this.archetypes = new ConfigsArchetypes(this.logger, sceneInstances.perId);
        this.connectedToRefs = new OneToMany();
        this.typesRefs = new OneToMany();
        this.sceneInstances = sceneInstances;
  
        this.observer = sceneInstances.updatesStream.subscribe({
            settings: { immediateMode: false },
            onNext: (allocated) => {
                this.handleAllocate(allocated);
                autoFillBoundariesToBuildableArea(sceneInstances, this, allocated, this.logger);
            },
        });
        this.deleteItemsObserver = sceneInstances.deletedItemsStream.subscribe({
            settings: { immediateMode: true },
            onNext: (deleted) => {
                this.handleDelete(deleted);
            },
        });

        this._lazyLists = new EntitiesLazyLists({
            entities: this,
            permanentTypeExtractor: (config) => config.type_identifier,
        });

        this.updatesStream.subscribe({
            settings: { immediateMode: true },
            onNext: (obj) => {
                if (obj instanceof Deleted) {
                    // this.logger.info('deleted configs', obj.ids);
                    for (const id of obj.ids) {
                        for (const [from] of this.connectedToRefs._refs) {
                            this.connectedToRefs.remove(from, id);
                        }
                        for (const [from] of this.typesRefs._refs) {
                            this.typesRefs.remove(from, id);
                        }
                    }
                }
                if (obj instanceof Allocated) {
                    const configs = this.peekByIds(obj.ids);
                    // this.logger.info('allocated configs', obj.ids, configs);
                    for (const [id, state] of configs) {
                        this.connectedToRefs.add(state.connectedTo, id);
                        this.typesRefs.add(state.type_identifier, id);
                    }
                }
                if(obj instanceof Updated){
                    // const configs = this.peekByIds(obj.ids);
                    // this.logger.info('updated configs', obj.ids, configs, Object.isFrozen(Array.from(configs.values())[0]));
                }
            },
        });

        this.resolvers = new ConfigsResolver(this, this.logger);
        registerAugmentSubareasResolver(this.resolvers, this.logger);
    }

    dispose(): void {
        super.dispose();
        this.deleteItemsObserver.dispose();
        this.observer.dispose();
        this._lazyLists.dispose();
        this.resolvers.dispose();
    }

    peekByTypeAndConnectedTo(
        configTypeIdentifier: string,
        connectedTo: IdBimScene,
    ) {
        const result: Array<[IdConfig, Config]> = []
        for (const item of this.peekByType(configTypeIdentifier)) {
            if (item[1].connectedTo === connectedTo) {
                result.push(item);
            }
        }
        return result;
    }

    peekByType(type: string): [IdConfig, Config][] {
        const configs: [IdConfig, Config][] = [];
        this.reconcileConfigs();
        for (const id of this.typesRefs.iter(type)) {
            const config = this.peekById(id);
            if (config !== undefined) {
                configs.push([id, config]);
            } else {
                this.logger.error(
                    "typesMap contains removed config with id:",
                    id
                );
            }
        }
        return configs;
    }

    peekSingleton(type: string, ): Config | undefined {
        const archetypeType = this.archetypes.configStateType(type);
        if (archetypeType !== StateType.Singleton && archetypeType !== StateType.OptionalSingleton) {
            this.logger.error(`the config type:${type} is not singleton`);
            return undefined;
        }
        const configs = this.peekByType(type);
        if (configs.length > 1) {
            this.logger.error(`to many configs for ${type}`, configs);
        }
        if (configs.length > 0) {
            return configs[0][1];
        } else {
            this.logger.error(`the config type:${type} is not initialized`);
            return undefined;
        }
    }

    applyPatchToSingleton(
        type: string,
        patch: ConfigPatch,
        event?: Partial<EventStackFrame>
    ) {
        const id = IterUtils.getFirstFromIter(this.typesRefs.iter(type));
        if (id !== undefined) {
            this.applyPatchTo(patch, [id], event);
        } else {
            this.logger.error(`the config type:${type} is absent, cant patch`);
        }
    }

    patchSingletonProps<Props extends PropsGroupBase>(
        typeIdent: string,
        propsCtor: { new(args: any): Props },
        patchFn: (props: Props) => void,
    ) {
        const singleton = this.peekSingleton(typeIdent);
        if (!singleton) {
            this.logger.error(`the config type:${typeIdent} is absent, cant patch`);
            return;
        }
        if (singleton.props.constructor !== propsCtor) {
            this.logger.error(`invalid props class, expected: ${propsCtor.name}`);
            return;
        }
        const props = producePatchedProps(singleton.props as Props, patchFn);
        if (props) {
            this.applyPatchToSingleton(typeIdent, {props: props});
        }
    }

    allocateSingleton(singleton: Partial<Config>): IdConfig | undefined {
		const id = this.idsProvider.reserveNewId();
		const allocated = this.allocate([[id, singleton]]);
		if (allocated.length) {
			return allocated[0];
		}
		return undefined;
	}

    protected _allocate(
        argsPerObject: Iterable<[IdConfig, Partial<Config>]>,
        thisEvent: EventStackFrame
    ): { idsAllocated: IdConfig[]; derivativeUpdates: Map<IdConfig, number> } {
        const allocateConfigs: [IdConfig, Partial<Config>][] = [];
        for (const [id, state] of argsPerObject) {
            const archetype = this.archetypes.perTypeIdent.get(
                state.type_identifier!
            );
            if (
                !state.connectedTo &&
                archetype?.stateType === StateType.CreateByConnection
            ) {
                this.logger.error(
                    `invalid connectedTo: ${state.connectedTo}`,
                    [id, state, archetype]
                );
                continue;
            }
            if (
                state.connectedTo &&
                archetype?.stateType === StateType.CreateByConnection &&
                this.sceneInstances.peekById(state.connectedTo) === undefined
            ) {
                this.logger.error(
                    `instance not found with id: ${state.connectedTo}`,
                    [id, state, archetype]
                );
                continue;
            }

            allocateConfigs.push([id, state]);
        }

        const result = super._allocate(allocateConfigs, thisEvent);

        return result;
    }

    checkForErrors(state: Config, errors: string[]): void {
        if (!state.type_identifier) {
            errors.push("invalid type_identifier");
        }
        if (ObjectUtils.isObjectEmpty(state.properties) && ObjectUtils.isObjectEmpty(state.props)) {
            errors.push("empty properties");
        }
        if (state.connectedTo === undefined) {
            errors.push("invalid connectedTo");
        }
        const propsClass = this.archetypes.getPropsClassFor(state.type_identifier);
        if (propsClass && (!(state.props instanceof propsClass))) {
            errors.push(`invalid props class, expected: ${propsClass.name}`);
        }
    }

    _applyPatchToEntity(
        obj: Config,
        patch: ConfigPatch,
        out: { revert: ConfigPatch | null }
    ): number {
        const p = ObjectUtils.patchObject(obj, patch);
        // this.logger.info("config patch", p);
        if (!p) {
            return 0;
        }
        out.revert = p.revertPatch;
        if (p.revertPatch.type_identifier) {
            this.logger.error("patch changed type_identifier", [
                obj.type_identifier,
                p.revertPatch.type_identifier,
            ]);
        }
        return 0xffff;
    }

    private handleAllocate(
        diff: EntitiesCollectionUpdates<IdBimScene, number>
    ) {
        if (diff instanceof Allocated) {
            if (diff.ids.length > 0) {
                const allTypes = this.archetypes.getConnectionInstanceTypes();

                const instances = this.sceneInstances.peekByIds(diff.ids);
                for (const [id, inst] of instances) {
                    if (!allTypes.has(inst.type_identifier)) {
                        continue;
                    }
                    // this.logger.info(`allocated instance of ${inst.type_identifier} with id:`, id, inst);
                    if (!this.connectedInstances.has(id)) {
                        this.connectedInstances.set(id, inst.type_identifier);
                        this.reconcileConfigs();
                    }
                }
            }
        }
    }

    private reconcileConfigs(){

        // TODO: proper reconciliation
        
        const allocatedConfigs: [IdConfig, Config][] = [];

        const hasAnyConfigWithTypeAndConnectedId = (type:string, connectedId: IdBimScene) => {
            const configsByType = IterUtils.iterToSet(this.typesRefs.iter(type));
            const connectedConfigs = IterUtils.iterToSet(this.connectedToRefs.iter(connectedId));
            return IterUtils.any(connectedConfigs, (configId) => configsByType.has(configId));
        };

        for (const [configType] of this.archetypes.perTypeIdent) {
            if (
                (this.archetypes.configStateType(configType) === StateType.Singleton) &&
                !this.typesRefs.hasAnyRefsFrom(configType)
            ) {
                const config = this.archetypes.newDefaultInstanceForArchetype(configType);
                allocatedConfigs.push([this.reserveNewId(), config]);
            } else if(this.archetypes.isConnected(configType)){
                for (const [connectedId] of this.connectedInstances) {
                    if (
                        !hasAnyConfigWithTypeAndConnectedId(
                            configType,
                            connectedId
                        )
                    ) {
                        const config =
                            this.archetypes.newDefaultInstanceForArchetype(
                                configType,
                                connectedId
                            );
                        allocatedConfigs.push([this.reserveNewId(), config]);
                    }
                }
            }
        }

        if (allocatedConfigs.length > 0) {
            this.allocate(allocatedConfigs, {identifier: `configs-reconciliation`});
        }
    }

    private handleDelete(diff: EntitiesCollectionUpdates<IdBimScene, number>) {
        if (diff instanceof Deleted) {
            // this.logger.info('deleted instances', diff.ids);
            const idsToDelete: IdConfig[] = [];
            for (const id of diff.ids) {
                for (const config of this.connectedToRefs.iter(id)) {
                    idsToDelete.push(config);
                }
                this.connectedInstances.delete(id);
            }
            if (idsToDelete.length > 0 && !this.isLocked()) {
                // this.logger.info('deleted configs', this.peekByIds(idsToDelete));
                this.delete(idsToDelete);
            } else if(idsToDelete.length > 0){
                //Collection are locked
            }
        }
    }

    getLazyListOf(args: {
        type_identifier: string;
    }): LazyVersioned<[IdConfig, Config][]> {
        const lazyList = this._lazyLists.getLazyListOf({
            type_identifier: args.type_identifier,
            relevantUpdateFlags: 0xff_ff_ff_f,
        });
        return LazyDerived.new1(
            "lazy-configs-" + args.type_identifier,
            null,
            [lazyList],
            ([configs]) => {
                if (configs.length === 0) {
                    return this.peekByType(args.type_identifier);
                } else {
                    return configs;
                }
            }
        ).withoutEqCheck();
    }

    getLazySingletonOf(args: {
        type_identifier: string;
    }): LazyVersioned<Config> {
        if(this.archetypes.configStateType(args.type_identifier) !== StateType.Singleton){
            this.logger.error(`the config type:${args.type_identifier} is not singleton`);
        }
        const lazyList = this._lazyLists.getLazyListOf({
            type_identifier: args.type_identifier,
            relevantUpdateFlags: 0xff_ff_ff_f,
        });
        return LazyDerived.new1(
            "lazy-singleton-" + args.type_identifier,
            null,
            [lazyList],
            ([configs]) => {
                if (configs.length > 1) {
                    this.logger.error(`to many configs for ${args.type_identifier}`, configs);
                }
                if (configs.length > 0) {
                    return configs[0][1];
                } else {
                    // this.logger.warn(`created new config with type: ${args.type_identifier}`);
                    return this.peekSingleton(args.type_identifier)!;
                }
            }
        ).withoutEqCheck();
    }

    getLazyOptionalSingletonOf(args: {
        type_identifier: string;
    }): LazyVersioned<Config | undefined> {
        const stateType = this.archetypes.configStateType(args.type_identifier);
        if (stateType === undefined) {
            this.logger.error(`the config type:${args.type_identifier} is not registered`);
        } else if(stateType !== StateType.OptionalSingleton){
            this.logger.error(`the config type:${args.type_identifier} is not OptionalSingleton`);
        }
        const lazyList = this._lazyLists.getLazyListOf({
            type_identifier: args.type_identifier,
            relevantUpdateFlags: 0xff_ff_ff_f,
        });
        return LazyDerived.new1(
            "lazy-OptionalSingleton-" + args.type_identifier,
            null,
            [lazyList],
            ([configs]) => {
                if (configs.length > 1) {
                    this.logger.error(`expected singleton, but got ${configs.length} config of type ${args.type_identifier}`, configs);
                }
                if (configs.length > 0) {
                    return configs[0][1];
                } else {
                    return undefined;
                }
            }
        ).withoutEqCheck();
    }
    getLazyOptionalSingletonProps<Props extends PropsGroupBase>(args: {
        type_identifier: string;
        propsClass: {new(args: any): Props},
    }): LazyVersioned<Result<Props>> {
        const stateType = this.archetypes.configStateType(args.type_identifier);
        if (stateType === undefined) {
            this.logger.error(`the config type:${args.type_identifier} is not registered`);
        } else if(stateType !== StateType.OptionalSingleton){
            this.logger.error(`the config type:${args.type_identifier} is not OptionalSingleton`);
        }
        const registeredPropsClass = this.archetypes.getPropsClassFor(args.type_identifier);
        if (registeredPropsClass && registeredPropsClass !== args.propsClass) {
            throw new Error('props classes mismatch');
        }
        const lazyList = this._lazyLists.getLazyListOf({
            type_identifier: args.type_identifier,
            relevantUpdateFlags: 0xff_ff_ff_f,
        });
        return LazyDerived.new1(
            "lazy-OptionalSingleton-props-" + args.type_identifier,
            null,
            [lazyList],
            ([configs]): Result<Props> => {
                if (configs.length > 1) {
                    this.logger.error(`expected singleton, but got ${configs.length} config of type ${args.type_identifier}`, configs);
                }
                if (configs.length > 0) {
                    return new Success(configs[0][1].props) as Result<Props>;
                } else {
                    return new Failure({msg: `${args.type_identifier} config is absent`});
                }
            }
        ).withoutEqCheck();
    }
    getLazySingletonProps<Props extends PropsGroupBase>(args: {
        type_identifier: string;
        propsClass: {new(args: any): Props},
    }): LazyVersioned<Props> {
        if(this.archetypes.configStateType(args.type_identifier) !== StateType.Singleton){
            throw new Error(`the config type:${args.type_identifier} is not singleton`);
        }
        const lazyList = this._lazyLists.getLazyListOf({
            type_identifier: args.type_identifier,
            relevantUpdateFlags: 0xff_ff_ff_f,
        });
        return LazyDerived.new1(
            "lazy-singleton-props-" + args.type_identifier,
            null,
            [lazyList],
            ([configs]) => {
                if (configs.length > 1) {
                    this.logger.error(`to many configs for ${args.type_identifier}`, configs);
                }
                if (configs.length > 0) {
                    return configs[0][1].props as Props;
                } else {
                    // this.logger.warn(`created new config with type: ${args.type_identifier}`);
                    return this.peekSingleton(args.type_identifier)!.props as Props;
                }
            }
        ).withoutEqCheck();
    }
}
