import type { Id } from "./VerDataSyncerImpl";


export type IdVersionPacked = number; // IDV
export const MaxEntityId: Id = 4294967295;
export const MaxEntityVersion = 65535;

// simple arhithmetic instead of bitwise operations is used for IdVersionPacked creation
// and extraction of id and version back, because Js only supportes signed 32 bit operations
// so instead of limiting ids range
// use arhithmetic * + / operations, which are slower but not terribly so

export function newCompactIDV(id: Id, version: number): IdVersionPacked {
    if (id < 0 || id > MaxEntityId) {
        throw new Error("id out of range: " + id);
    }
    version = version | 0;
    if (version < 1 || version > MaxEntityId) {
        throw new Error("version out of range: " + version);
    }
    return (id + version * MaxEntityId) as IdVersionPacked;
}

export function getIdFromIDV(vid: IdVersionPacked): number {
    const version = (vid / MaxEntityId) | 0; 
    return vid - (version * MaxEntityId);
}

export function getVersionFromIDV(vid: IdVersionPacked): number {
    return (vid / MaxEntityId) | 0; 
}

export function newIdvs(ids: Id[], versions: number[]): Float64Array {
    if (ids.length !== versions.length) {
        throw new Error("ids versions different lengths");
    }
    const idsVersions = new Float64Array(ids.length);
    for (let i = 0; i < ids.length; ++i) {
        const id = ids[i];
        const version = versions[i];
        const cvid = newCompactIDV(id, version);
        idsVersions[i] = cvid;
    }
    return idsVersions;
}


export function getIdsFromIDVs(cvids: Float64Array): Id[] {
    const res = [];
    for (let cvid of cvids) {
        res.push(getIdFromIDV(cvid));
    }
    return res;
}

export function getVersionsFromIDVs(cvids: Float64Array): number[] {
    const res = [];
    for (let cvid of cvids) {
        res.push(getVersionFromIDV(cvid));
    }
    return res;
}

