<script lang="ts">
    export let value: boolean | null | "multiple";
    export let readonly: boolean = false;
    export let onChange: (value: boolean) => void = () => {};
</script>

<svg
    class="checkbox"
    class:readonly
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    on:click={() => {
        if (!readonly) {
            onChange(!value);
        }
    }}
>
    {#if readonly}
        <rect
            x="1"
            y="1"
            width="14"
            height="14"
            rx="2"
            fill="#103A52"
            fill-opacity="0.08"
        />
        <rect
            x="1.75"
            y="1.75"
            width="12.5"
            height="12.5"
            rx="1.25"
            stroke="#101314"
            stroke-opacity="0.12"
            stroke-width="1.5"
        />
    {:else}
        <rect x="1" y="1" width="14" height="14" rx="2" fill="white" />
        <rect
            x="1.75"
            y="1.75"
            width="12.5"
            height="12.5"
            rx="1.25"
            stroke="#101314"
            stroke-opacity="0.69"
            stroke-width="1.5"
        />
    {/if}
    {#if value === true}
        {#if readonly}
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.18079 11.5017C7.07471 11.5017 6.97296 11.4596 6.89795 11.3845L4.0878 8.5744C4.01278 8.49938 3.97064 8.39764 3.97064 8.29156C3.97064 8.18547 4.01278 8.08373 4.0878 8.00871L5.01184 7.08467C5.16805 6.92846 5.42131 6.92846 5.57752 7.08467L7.18079 8.68794L10.7285 5.14023C10.8035 5.06521 10.9053 5.02307 11.0113 5.02307C11.1174 5.02307 11.2192 5.06522 11.2942 5.14023L12.2182 6.06427C12.3744 6.22048 12.3744 6.47374 12.2182 6.62995L7.46364 11.3845C7.38862 11.4596 7.28688 11.5017 7.18079 11.5017Z"
                fill="#101314"
                fill-opacity="0.38"
            />
        {:else}
            <path
                d="M7.0394 11.2431C7.0769 11.2807 7.12777 11.3017 7.18082 11.3017C7.23386 11.3017 7.28473 11.2807 7.32224 11.2431L12.0768 6.48856C12.1549 6.41045 12.1549 6.28382 12.0768 6.20571L11.1528 5.28168C11.1153 5.24417 11.0644 5.2231 11.0114 5.2231C10.9583 5.2231 10.9075 5.24417 10.87 5.28167L7.18082 8.97081L5.43613 7.22612C5.35802 7.14801 5.23139 7.14801 5.15328 7.22612L4.22925 8.15016C4.19174 8.18767 4.17067 8.23854 4.17067 8.29158C4.17067 8.34462 4.19174 8.39549 4.22925 8.433L7.0394 11.2431Z"
                fill="#9C27B0"
                stroke="#9C27B0"
                stroke-width="0.4"
                stroke-linejoin="round"
            />
        {/if}
    {:else if value === "multiple"}
        {#if readonly}
            <path
                d="M10 9C10.2761 9 10.5 8.77614 10.5 8.5V7.5C10.5 7.22386 10.2761 7 10 7L6 7C5.72386 7 5.5 7.22386 5.5 7.5L5.5 8.5C5.5 8.77614 5.72386 9 6 9L10 9Z"
                fill="#101314"
                fill-opacity="0.38"
            />
        {:else}
            <path
                d="M10 8.99994C10.2761 8.99994 10.5 8.77608 10.5 8.49994V7.49994C10.5 7.2238 10.2761 6.99994 10 6.99994L6 6.99994C5.72386 6.99994 5.5 7.2238 5.5 7.49994L5.5 8.49994C5.5 8.77608 5.72386 8.99994 6 8.99994L10 8.99994Z"
                fill="#9C27B0"
            />
        {/if}
    {/if}
</svg>

<style>
    .checkbox {
        min-width: fit-content;
    }
    .checkbox:not(.readonly) {
        cursor: pointer;
    }
</style>
