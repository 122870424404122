<script lang="ts">
import type { CatalogItemsReferenceProperty, SolarArrayConfig } from "bim-ts";
import type { SolarArraysViewContext } from "layout-service";
import { getContext } from "svelte";
import { PUI_CustomPropertyNode } from "ui-bindings";
import type { VersionedStore } from "../../../VersionedStore";
import PropertyView from "../../PropertyView.svelte";

const context = getContext<VersionedStore<SolarArraysViewContext>>('context');

export let config: SolarArrayConfig;

$: presetProp = new PUI_CustomPropertyNode<CatalogItemsReferenceProperty, {}>({
    name: "Preset",
    value: config.preset,
    onChange: (newVal) => {
        const newConfig: SolarArrayConfig = {
            ...config
        }
        newConfig.preset = newVal;
        if (!newVal.value.length) {
            newConfig.module = newConfig.module.withDifferentValue([]);
            newConfig.trackerFrame = newConfig.trackerFrame.withDifferentValue([]);
        }
        $context.updateConfig(config.id.value, newConfig);
    },
    type_ident: 'catalog-items-selector',
    context: {
        doubleLine: true
    },
})

</script>

<PropertyView
    offset={0}
    property={presetProp}
/>

<style lang="scss">
</style>
