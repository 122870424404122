import type { IdBimScene, SceneInstances } from 'bim-ts';
import type { LazyBasic, LazyVersioned, ObservableObject, VersionedValue } from 'engine-utils-ts';
import { LazyDerived, LegacyLogger } from 'engine-utils-ts';
import { Vector2, Vector3 } from 'math-ts';

import type { EngineControlsState } from '../EngineControlsState';
import type { GizmosController } from '../gizmos/GizmosController';
import { GizmosGesturesConsumer } from '../gizmos/GizmosGesturesConsumer';
import type { RectSelectorGizmo } from '../gizmos/RectSelectorGizmo';
import { EntitiesRectSelector } from '../gizmos/RectSelectorGizmo';
import type TeleportGizmo from '../gizmos/TeleportGizmo';
import type { KreoEngineImpl } from '../KreoEngineImpl';
import type { InteractiveObjectsActive } from '../scene/InteractiveSceneObjects';
import type { SceneInt, SceneRaycaster } from '../scene/SceneRaycaster';
import type { EditModeControls } from './EditControls';
import { EditControlsGesturesConsumer } from './EditControls';
import { HtmlUtils } from './HtmlUtils';
import { InteractiveEntitiesClickSelector } from './InteractiveEntitiesClickSelector';
import type {
    GesturesButtons, MouseClickConsumer, MouseDragConsumer, MouseDragInfo,
    MouseEventData, MouseGestureConsumer
} from './MouseGesturesBase';
import { GesturesMousePos, getMouseEventData
} from './MouseGesturesBase';
import type { KrCamera, MovementControls } from './MovementControls';
import { MovementMouseConsumer } from './MovementMouseConsumer';
import { SizeLabelsClicksConsumer } from './SizeLabelsClicksConsumer';

export const enum MouseButton {
	Left = 1,
	Right = 2,
	Middle = 4,
	Back = 8,
	Forward = 16,
}

const doubleClickInterval = 500;

//todo: stop flying on blur


type mouseEventHandler = (event: PointerEvent) => void;
type keyboardEventHandler = (event: KeyboardEvent) => void;

interface HtmlEventDescription {
	readonly name: string;
	readonly global: boolean;
}

let inputTransactionId: Symbol | undefined = undefined;

export class InputController {


	readonly domElement: HTMLElement;

	readonly rectSelector: RectSelectorGizmo;

	readonly htmlUtils: HtmlUtils;

	readonly mousePos: LazyBasic<GesturesMousePos>;

	readonly movementControls: MovementControls;
	readonly editModeControls: EditModeControls;
	readonly raycaster: SceneRaycaster;
	readonly sceneInstances: SceneInstances;
	readonly gizmos: GizmosController;
	readonly teleportGizmo: TeleportGizmo;

	controlsState: ObservableObject<EngineControlsState>;

    _hoverInt: LazyVersioned<SceneInt>;
    _cursorStyleAndHoverHandler: LazyVersioned<string>;

	readonly mouseEventListeners = new Map<HtmlEventDescription, mouseEventHandler>();
	readonly keyboardEventListeners = new Map<HtmlEventDescription, keyboardEventHandler>();

	readonly mouseClickDragConsumers: Set<MouseGestureConsumer> = new Set();
	readonly activeDragConsumers: ActiveDragConsumers = new ActiveDragConsumers();

	isMouseOverEngine: boolean = false;

	readonly mouseDownClient: Vector2 = new Vector2();
	readonly prevMouseUpClient: Vector2 = new Vector2();

	prevMouseUpTime: number = 0;
	prevMouseDrag: boolean = false;

	isMouseDragging: boolean = false;
	interactiveObjects: InteractiveObjectsActive;


	constructor(
		domElement: HTMLElement,
		engine: KreoEngineImpl,
	) {
		this.domElement = domElement;
		domElement.setAttribute('tabindex', '0'); // necessary for keyboard focus
		domElement.style.outline = 'none';

		this.mousePos = engine.mousePos;

		this.movementControls = engine.movementControls;
		this.editModeControls = engine.editModeControls;
		this.raycaster = engine.sceneRaycaster;

		this.sceneInstances = engine.bim.instances;
		this.gizmos = engine.gizmosController;
		this.teleportGizmo = engine.gizmosController.gizmos[2];
		this.rectSelector = engine.rectSelector;

		this.controlsState = engine.controlsState;
		this.interactiveObjects = engine.interactiveObjectsActive;


        this._hoverInt = LazyDerived.new1(
            'hoverIntersection',
            [this.activeDragConsumers],
            [this.raycaster.sceneIntersection],
            ([sceneInt]) => {
                if (!this.activeDragConsumers.anyActive()) {
                    return sceneInt.closest();
                } else {
                    return null;
                }
            }
        );
        this._cursorStyleAndHoverHandler = LazyDerived.new2(
            'cursorStyle',
            [this.activeDragConsumers],
            [this._hoverInt, this.teleportGizmo.state],
            ([hoverInt, teleportState]): string => {
                let cursorStyle: string | null = null;

                if (this.activeDragConsumers.anyActive()) {
                    const dragCursorStyle = this.activeDragConsumers.getDragStyle();
                    if (dragCursorStyle) {
                        cursorStyle = dragCursorStyle;
                    }
                } else {
                    for (const it of this.mouseClickDragConsumers) {
                        if (it.onHover && it.isEnabled()) {
                            const r = it.onHover(hoverInt);
                            if (r) {
                                cursorStyle = r.cursorStyleToSet;
                            }
                        }
                    }
                }
                if (teleportState.enabled) {
                    cursorStyle = 'crosshair';
                }
                return cursorStyle ?? 'default';
            }
        )


		// document.addEventListener('pointerlockchange', (e) => console.warn('lock change', e));
		// document.addEventListener('pointerlockerror', (e) => console.warn('lock error', e));

        this.mouseClickDragConsumers.add(
			this.editModeControls.editOperators,
		);

		this.mouseClickDragConsumers.add(
			new SizeLabelsClicksConsumer(this.sceneInstances, engine.namedEvents, engine.uiUnits),
		);
        this.mouseClickDragConsumers.add(
            new EditControlsGesturesConsumer(
                this.editModeControls,
            ),
        );
		this.mouseClickDragConsumers.add(
			new GizmosGesturesConsumer(
				engine.gizmosController,
				engine.sceneRaycaster,
				this.sceneInstances,
				engine.movementControls
			),
		);

		// this.mouseClickDragConsumers.add(
		// 	new SiteMarkupGesturesConsumer(engine.siteTilesMarkupControls),
		// );
		this.mouseClickDragConsumers.add(
			new InteractiveEntitiesClickSelector(this.interactiveObjects, engine.snappingSettings),
		);
		this.mouseClickDragConsumers.add(
			new EntitiesRectSelector(
				engine.interactiveObjectsActive,
				engine.sceneRaycaster,
				engine.rectSelector
			),
		);
		this.mouseClickDragConsumers.add(
			new MovementMouseConsumer(engine.movementControls, engine.gizmosController.gizmos[4], 
			() => { 
				const selection = this.interactiveObjects.getSelected();
				if (selection.length !== 0) {
					const ids = selection.map(id => id as IdBimScene);
					const bbox = engine._calcBoundsForHandlesOrVisibleScene(ids);
					return bbox.getCenter_t();
				} else {
					return undefined;
				}
			}),
		);

		this.htmlUtils = new HtmlUtils(domElement);


		const onContextMenu = (event: MouseEvent) => {
			event.preventDefault();
		}

		const onMouseDownLocal = (mouseEvent: PointerEvent): void => {
			this.prevMouseDrag = this.isMouseDragging;
			this.isMouseDragging = false;
			const e = mouseEvent as MouseEvent;
			e.preventDefault();
			this.resetState();
			domElement.focus();

			this.domElement.setPointerCapture(mouseEvent.pointerId);

			this.mouseDownClient.x = e.clientX;
			this.mouseDownClient.y = e.clientY;

			const mousePos = GesturesMousePos.newFromEvent(e, this.domElement.getBoundingClientRect());
			this.mousePos.replaceWith(mousePos);
			const med = getMouseEventData(e, mousePos, this.raycaster.mouseCone, this.movementControls.camera);

			const sceneInts = this.raycaster.sceneIntersection.poll();
			const sceneInt = sceneInts.closest();

			this.activeDragConsumers.clear();
			for (const c of this.mouseClickDragConsumers) {
				if (c.isEnabled() && c.dragConsumers.length) {
					for (const cc of c.dragConsumers) {
						if (cc.buttons === med.buttons && cc.sceneRaycastTypeguard(sceneInt)) {
							if (cc.onButtonDown(sceneInt, med)) {
								this.activeDragConsumers.addNewActive(cc, sceneInt, med);
								break;
							}
						}
					}
				}
			}
		}

		const onMouseMoveGlobal = (e: PointerEvent) => {
			const mousePos = GesturesMousePos.newFromEvent(e, this.domElement.getBoundingClientRect());

			if (this.htmlUtils.lockRequested && mousePos.deltaNormalized.length() > 0.2) {
				return; // chrome bug, spikes movementX after getting lock, ignore
			}

			this.mousePos.replaceWith(mousePos);
			const med = getMouseEventData(e, mousePos, this.raycaster.mouseCone, this.movementControls.camera);

			if (this.isMouseOverEngine) {
				e.preventDefault();
			}

			if (!this.isMouseDragging) {
				const pixelsMinDiff = 2;
				const percentageMinDiff = 0.25 / 100;
				const averageInPixelsX = (pixelsMinDiff + percentageMinDiff * engine.htmlSize.x) * 0.5;
				const averageInPixelsY = (pixelsMinDiff + percentageMinDiff * engine.htmlSize.y) * 0.5;

				// todo: better click - drag differentitationr
				if ((Math.abs(e.clientX - this.mouseDownClient.x) > averageInPixelsX)
					|| (Math.abs(e.clientY - this.mouseDownClient.y) > averageInPixelsY)
				) {
					this.isMouseDragging = true;
					this.activeDragConsumers.startDrag(med);
					domElement.setPointerCapture(e.pointerId);
					// leave only drag events
				}
			}


			if (!this.isMouseDragging) {
				return;
			}

			if (this.activeDragConsumers.anyActive()) {
				const { requestPointerLock } = this.activeDragConsumers.onDrag(med, this.movementControls.camera);
				if (requestPointerLock) {
					this.htmlUtils.requestPointerLock();
				}
			}
		}

		const onMouseUpGlobal = (e: PointerEvent) => {
			const mousePos = GesturesMousePos.newFromEvent(e, this.domElement.getBoundingClientRect());
			this.mousePos.replaceWith(mousePos);
			const med = getMouseEventData(e, mousePos, this.raycaster.mouseCone, this.movementControls.camera);

			if (this.isMouseOverEngine) {
				e.preventDefault();
			}

			this.htmlUtils.unlockPointerIfLocked();

			const now = performance.now();
			const event = e as MouseEvent;

			const isDoubleClick =
				!this.isMouseDragging
				&& !this.prevMouseDrag
				&& (now - this.prevMouseUpTime < doubleClickInterval);

			this.prevMouseUpClient.x = event.clientX;
			this.prevMouseUpClient.y = event.clientY;

			if (isDoubleClick) {
				if (event.button === 0) {
					engine.focusCameraByType(engine.bim.instances.getHighlighted());
				}
				this.prevMouseUpTime = 0; // prevent false double clicks with consecutive clicking

			} else {
				this.prevMouseUpTime = now;

				if (this.isMouseDragging) {
					this.activeDragConsumers.onMouseUpAfterDrag(med);
				} else {
					const closest = this.raycaster.sceneIntersection.poll().closest();
					const ch = this._getAppropirateClickHandler(med.buttons, closest);
					ch?.clickHandler(closest, med);
				}
			}
			this.activeDragConsumers.clear();
		}

		const onMouseLeave = (_event: PointerEvent) => {
			this.isMouseOverEngine = false;
		}

		const onMouseOver = (_event: PointerEvent) => {
			this.isMouseOverEngine = true;
		}

		const onMouseWheel = (event: MouseEvent) => {
			if (event.ctrlKey || event.altKey || event.shiftKey) {
				return;
			}
			const wheelEvent = event as WheelEvent;
			event.preventDefault();
			this.movementControls.stopFocusingRotation();
			this.movementControls.stopFlying();
			let mousePosOffset = undefined;
			if (engine.renderSettings.poll().zoom_to_cursor) {
				const domRect = this.domElement.getBoundingClientRect();
				const mousePos = GesturesMousePos.newFromEvent(event, domRect);
				mousePosOffset = new Vector2(0.5, 0.5).sub(mousePos.mousePosNormalized);
			}
			let delta = wheelEvent.deltaY;
			// todo: unusable with touchpad, FIX
			// https://github.com/goxjs/glfw/issues/10
			// if (delta === WheelEvent.DOM_DELTA_PIXEL) {
			// 	console.log('delta in pixels', wheelEvent.deltaY); 
			// } else if (delta === WheelEvent.DOM_DELTA_LINE) {
			// 	console.log('delta in pixels', wheelEvent.deltaY); 
			// } else {
			// 	console.log('delta in pages', wheelEvent.deltaY);
			// }
			this.movementControls.handleMouseWheel(delta, mousePosOffset);
		}

		this.mouseEventListeners.set({name: 'pointerdown', 	global:false }, onMouseDownLocal);
		this.mouseEventListeners.set({name: 'pointerleave', global:false }, onMouseLeave);
		this.mouseEventListeners.set({name: 'pointerover', 	global:false }, onMouseOver);
		this.mouseEventListeners.set({name: 'wheel', 		global:false }, onMouseWheel);

		this.mouseEventListeners.set({ name: 'pointermove', global: false },  onMouseMoveGlobal);
		this.mouseEventListeners.set({ name: 'pointerup', 	global: false },  onMouseUpGlobal);
		this.mouseEventListeners.set({ name: 'contextmenu', global: false }, onContextMenu);

		// this.keyboardEventListeners.set({ name: 'keydown', 	global: true }, wrapEventHandlerIntoSourceChangeSetter(onKeyDownGlobal));
		// this.keyboardEventListeners.set({ name: 'keyup', 	global: true }, wrapEventHandlerIntoSourceChangeSetter(onKeyUpGlobal));

		this.addListeners();
	}

	_shouldHandleKeyboardEvent(e: KeyboardEvent): boolean {
		return !(e.target instanceof HTMLInputElement);
	}

	_getAppropirateClickHandler(buttons: GesturesButtons, sceneInt: SceneInt): MouseClickConsumer | null {
		for (const c of this.mouseClickDragConsumers) {
			if (c.isEnabled() && c.clickConsumers) {
				for (const cc of c.clickConsumers) {
					if ((cc.buttons == buttons)
						&& cc.sceneRaycastTypeguard(sceneInt)
					) {
						return cc;
					}
				}
			}
		}
		return null;
	}

	private addListeners() {
		for (const [eventDescription, handler] of this.mouseEventListeners) {
			const dom = eventDescription.global ? window : this.domElement;
			dom.addEventListener(eventDescription.name, handler as ((e:Event) => void), {passive:false})
		}
		for (const [eventDescription, handler] of this.keyboardEventListeners) {
			const dom = eventDescription.global ? window : this.domElement;
			dom.addEventListener(eventDescription.name, handler as ((e:Event) => void), {passive:false})
		}
	}

	private removeListeners() {
		for (const [eventDescription, handler] of this.mouseEventListeners) {
			const dom = eventDescription.global ? window : this.domElement;
			dom.removeEventListener(eventDescription.name, handler as ((e:Event) => void));
		}
		for (const [eventDescription, handler] of this.keyboardEventListeners) {
			const dom = eventDescription.global ? window : this.domElement;
			dom.removeEventListener(eventDescription.name, handler as ((e:Event) => void));
		}
	}



	resetState () {
		this.teleportGizmo.toggleVisibility(false);

		// should refactor bullshit below to smth more systematic and robust
		this.movementControls.obs_state.forceResetThrottle();
		// this.engine.transformGizmo.gizmoState.state.forceResetThrottle();
	}


	update() {

        const cursorStyle = this._cursorStyleAndHoverHandler.poll();

        this.htmlUtils.setCursorStyle(cursorStyle);

		if (!document.hasFocus()) {
			// if (this.states !== States.None) {
			// 	this.resetState();
			// }
			// this.controls.stopFlying();
		}
	}

	getInfoForMark(): { point: number[]; type: string; } {
		const int = this.raycaster.sceneIntersection.poll();
		const point = int?.closest()?.point;
		if (point) {
			return {
				'point': point.toArray([], 0) as number[],
				'type': 'point'
			}
		} else {
			const point = this.movementControls.camera.position.clone().lerpTo(this.movementControls.target, 0.5);
			return {
				'point': point.toArray([], 0) as number[],
				'type': 'view'
			}
		}
	}

	activateTeleport() {
		this.teleportGizmo.toggleVisibility(true);
	}

	teleport() {
		if (this.isMouseOverEngine) {
			this.teleportGizmo.teleport(this.movementControls);
		};
		this.teleportGizmo.toggleVisibility(false);
	}

	isCameraInProjectionTransition(): boolean {
		const matrix = this.movementControls.camera.projectionMatrix;
		const isInTransition = matrix.elements[15] !== 0 && matrix.elements[15] !== 1;
		return isInTransition;
	}

	dispose() {
		this.removeListeners();
	}
}

type ActiveMouseConsumer = [c: MouseDragConsumer<any>, sceneInt: SceneInt, med: MouseEventData, state:Object | null];

class ActiveDragConsumers implements VersionedValue {

    _version: number = 0;

	readonly _consumers: ActiveMouseConsumer[] = [];

    version(): number {
        return this._version;
    }

	clear() {
		this._filter(() => false);
	}

	_filter(f: (c: ActiveMouseConsumer) => boolean) {
		for (let i = 0; i < this._consumers.length; ++i) {
			const t = this._consumers[i];
			if (!f(t)) {
				t[0].stop();
				this._consumers.splice(i, 1);
				i -= 1;
                this._version += 1;
			}
		}
	}

	addNewActive<T>(consumer: MouseDragConsumer<T>, sceneInt: SceneInt, med: MouseEventData) {
		const alreadyActiveState = this._consumers.find((c) => c[0] === consumer);
		if (alreadyActiveState) {
			LegacyLogger.warn('this gestures consumer is already active', consumer, alreadyActiveState);
		}
		this._consumers.push([consumer, sceneInt, med, null]);
        this._version += 1;
	}

	anyActive(): boolean {
		return this._consumers.length > 0;
	}

	startDrag(med: MouseEventData) {
		let handled = false;
		let requestPointerLock: boolean = false;
		// on first drag, filter out every event except the one that handled event first
		this._filter((t) => {
			const c = t[0];
			if (handled) {
				return false;
			}

			const screenSpaceDirection = med.pos.mousePos.clone().sub(t[2].pos.mousePos).normalize();

			const worldSpaceDirection = new Vector3(0, 0, 1);

			const prevMouseRay = t[2].mouseCone;
			if (med.mouseCone && prevMouseRay) {
				const thisRayPoint = med.mouseCone.raySection.ray.at(50, new Vector3());
				const prevRayPoint = prevMouseRay.raySection.ray.at(50, new Vector3());
				worldSpaceDirection.subVectors(thisRayPoint, prevRayPoint).normalize();
			}

			const dragInfo: MouseDragInfo = {
				med: t[2],
				sceneInt: t[1],
				worldSpaceDirection,
				screenSpaceDirection
			}
			const dragState = c.tryStartDrag(med, dragInfo);
			if (dragState) { // on the first suceccfull drag, filter out the rest
				handled = true;
				t[3] = dragState;
				requestPointerLock = !!(c.lockPointerOnDrag && c.lockPointerOnDrag(dragState));
				return true;
			}
			return false;
		});
        this._version += 1;
		return { requestPointerLock };
	}

	onDrag(me: MouseEventData, camera: KrCamera): { requestPointerLock: boolean }{
		if (!this._consumers.length) {
			return {requestPointerLock: false};
		}
		let requestPointerLock: boolean = false;
		// on first drag, filter out every event except the one that handled event first
		console.assert(this._consumers.length === 1, 'consumers should have been filtered by start drag');
		const [c, _int, _med, state] = this._consumers[0];
		if (state !== null && c.handleDrag(state, me, camera)) {
			requestPointerLock = !!(c.lockPointerOnDrag && c.lockPointerOnDrag(state));
		} else {
			this.clear();
		}
        this._version += 1;
		return {requestPointerLock};
	};

	getDragStyle(): string | undefined {
		for (const [c, s] of this._consumers) {
			if (c.cursorDragStyle) {
				return c.cursorDragStyle(s);
			}
		}
		return undefined;
	}

    onMouseUpAfterDrag(me: MouseEventData) {
		for (const [c, _int, _med, state] of this._consumers) {
			c.onButtonUp(state, me);
		}
	};
}
