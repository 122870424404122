export interface Disposable {
    dispose(): void;
}

export const nameof = <T>(name: keyof T) => name;

export const ResolvedPromise = Promise.resolve();

export const EmptyIterator: Iterable<any> = [][Symbol.iterator]();

export { requestExecutionFrame } from './ExecutionFrame';

export function noop() { };

export * from './TypeUtils';

export { IsInWebworker, isInNode } from './EnvChecks';

export type { RGBAHex } from './RGBAHex';
export { RGBA, RgbaPalette, DefaultRgbaPalette } from './RGBAHex';

export type { ResultAsync, Result } from './Result';
export {
	InProgress, Success, Failure,
	resultify, isResult, isResultAsync, getResultValueOrThrow, getResultValueOr
} from './Result';

export { CachedCalculations } from './CachedCalculations';

export { EnumUtils } from './EnumUtils';

export { ObjectUtils } from './ObjectUtils';

export { OneToMany } from './OneToMany';

export { RefsCounter } from './RefsCounter';

export { ErrorUtils } from './ErrorUtils';

export { IterUtils } from './IterUtils';

export { FetchUtils } from './FetchUtils';

export { findMappedPathFor } from './PathsMappings';

export { startWorker } from './workers/Worker';

export { LongTask, TasksRunner, Yield } from './TasksRunner';

export { TinyLru } from './TinyLru';

export { LruCache } from './LruCache';

export { CompressionUtils, GzipExecutor, GunzipExecutor } from './CompressionUtils';

export { CompressibleNumbersArray, ComprArrOrderingType } from './CompressibleNumbersArray';

export type { NetworkLoaderConfig } from './ProjectNetworkClient';
export { ProjectNetworkClient } from './ProjectNetworkClient';
export type { RemoteMsgHub } from './stateSync/MsgHub';
export { WebSocketHub } from './stateSync/WebSocketHub';
export {
	RemoteSyncHub as SyncHub, SyncEventIdents,
} from './stateSync/RemoteSyncHub';
export { Globals } from './Globals';

export type { ObjectSerializer } from './ObjectSerializer';
export { JsonObjectSerializer, JsonSerializer } from './ObjectSerializer';

export { CachingNetworkClient } from './CachingNetworkClient';

export { ObjectsSet } from './ObjectsSet';
export { DefaultMap } from './DefaultMap';
export { DefaultMapWeak } from './DefaultMapWeak';
export type { ObjectUniquePrimitiveHash } from './DefaultMapObjectKey';
export { DefaultMapObjectKey } from './DefaultMapObjectKey';
export { DeferredPromise } from './DeferredPromise';
export { LazyMap } from './LazyMap';
export { LazyMapBulk } from './LazyMapBulk';

export type { LazyValue, LazyVersioned, VersionedValue } from './stateSync/LazyVersioned';
export type { LazyVersionedPollingCache, PollWithVersionResult } from "./stateSync/LazyVersioned";
export { LazyVersionedPollingCacheImpl } from "./stateSync/LazyVersionedPollingCacheImpl";
export { LazyBasic, LazyDerived, VersionedInvalidator } from './stateSync/LazyVersioned';

export * from './stateSync/LazyVersionedAsync';

export { LazyLambda } from './LazyLambda';

export { LazyOnce } from './LazyOnce';

export type { ObservableEntities, Observer, ObserverSettings, SvelteStore } from './stateSync/Observables';
export { getUniqueEntityIdentifier } from './stateSync/Observables';

export type { ObservableObjectParams, PatchCallback, StatePatchTransformer } from './stateSync/ObservableObject';
export { ObservableObject } from './stateSync/ObservableObject';

export { type IStreamAccumulator, StreamAccumulator, StreamTransformedAccumulator } from './stateSync/StreamAccumulator';

export type { BasicCollectionUpdates } from './stateSync/BasicCollectionUpdates';
export { Allocated, Deleted, Updated } from './stateSync/BasicCollectionUpdates';
export type { BasicDataSource } from './stateSync/BasicDataSource';
export { CollectionBasic } from './stateSync/CollectionBasic';
export {
	MappedCollectionBasic, RecalcScheduleType,
} from './stateSync/MappedCollectionBasic';
export { JoinedCollectionBasic } from './stateSync/JoinedCollectionBasic';
export {
	MappedCollectionParallel,
} from './stateSync/MappedCollectionParallel';

export { LogLevel, ScopedLogger } from './ScopedLogger';
export { LegacyLogger } from './LegacyLogger';

export { TypedNumbersVec } from './TypedNumbersVec';

export { PollablePromise, PromiseStatus } from './PollablePromise';

export { Registry } from './Registry';

export type { ArgsMerger, UndoAction, UndoStackState } from './UndoStack';
export { UndoStack } from './UndoStack';

export { NamedEvents, ObservableStream } from './stateSync/ObservableStream';

export { LocalNotifier } from './stateSync/LocalNotifier';

export {
	EventStackFrame, isCurrentEventEqualTo, peekCurrentEventFrame,
	unsafePopFromEventStackFrame, unsafePushToEventStackFrame, withEventFrame,
	withOptionalEventFrame,
} from './stateSync/EventsStackFrame';


export { WorkerPool } from './workers/WorkerPool';
export type { JobsArgsCachingDescription } from './workers/JobExecutor';
export { ExecutionThreadPreference, JobExecutor, registerExecutor } from './workers/JobExecutor';
export { WorkerClassPassRegistry } from './workers/WorkerClassPassRegistry';

export { FilesUtils } from './FilesUtils';

export type {
    ObjectHash, ObjectUniqueHashValue, MakeOptional,
    MakeRequired, DeepRequired,
} from './types';

export { Base64Binary } from './Base64Binary';

export { PngConverter } from './PngConverter'

export { MapObjectKey } from './MapObjectKey';

export { NumericIdsRange } from './NumericIdsRange';

export type { UnitsGroup, UnitDefinition, PrefixDefinition, UnitDimension } from './UnitsConverter';
export { currenciesCustomSymbols, currencySymbolToName, replaceCurrencyUnitWithSymbol } from './UnitsConverter';
export { UnitConverter, unitsConverter, convertThrow, convertUnits, doUnitDimensionsMatch } from './UnitsConverter';

import './workers/MathClassesRegistry';

export { StringUtils } from './StringUtils';

export type { JsonClassSerializer } from './JsonClasses';
export { JsonClassesParse, JsonClassesStringify } from './JsonClasses';

export { Immer } from './ImmerWrap';

export type { ValueAndUnit } from './types';
