import { Failure, IterUtils, LazyDerived, ObjectUtils } from "engine-utils-ts";
import { Matrix4 } from "math-ts";
import type { Bim } from "../Bim";
import type { BimPropertyData } from "../bimDescriptions/BimProperty";
import { BimProperty } from "../bimDescriptions/BimProperty";
import { extractValueUnitPropsGroup } from "../bimDescriptions/NamedBimPropertiesGroup";
import type { BimGeometries } from "../geometries/BimGeometries";
import { GraphGeometry } from "../geometries/GraphGeometries";
import { PolylineGeometry } from "../geometries/PolylineGeometries";
import type { BasicAnalyticalRepresentation } from "../representation/Representations";
import type { ReactiveSolverBase, SolverInstancePatchResult } from "../runtime/ReactiveSolverBase";
import { SolverObjectInstance } from "../runtime/SolverObjectInstance";
import type { CostsConfigProvider, ExpandLegacyPropsWithCostTableLinks} from "src/cost-model/capital";
import { createFocusLinkOnSample, mergeCostComponents, sumCostComponents } from "src/cost-model/capital";
import { InstanceCostsGlobal } from "./EquipmentCommon";
import { createTrenchingDefaultCostsPerQubicMeter } from "src/cost-model/capital/tables/categories/electrical-subtotal/trenching";
import { PUI_GroupNode } from "ui-bindings";

export const TrenchTypeIdent = 'trench';

export function registerTrench(bim: Bim) {
    bim.instances.archetypes.registerArchetype(
		{
			type_identifier: TrenchTypeIdent,
			mandatoryProps: [
                {path: ['cost_bs', 'level 1'], value: "ELECTRICAL SUBTOTAL"},
                {path: ['cost_bs', 'level 2'], value: "Trenching"},
                {path: ['dimensions', 'width'], value: 1, unit: 'ft', numeric_range: [0.01, 50]},
                {path: ['dimensions', 'depth'], value: 2, unit: 'ft', numeric_range: [0.01, 50]},
			],
		}
	);
	bim.reactiveRuntimes.registerRuntimeSolver(trenchDimensionsSolver(bim.allBimGeometries));
}

function trenchDimensionsSolver(geometries: BimGeometries): ReactiveSolverBase {
    const objectsDefaultArgs = {
        representationAnalytical: null as BasicAnalyticalRepresentation | null,
        worldMatrix: new Matrix4(),
        legacyProps: {
            width: BimProperty.NewShared({path: ["dimensions", "width"], value: 0, unit: 'm'}),
            depth: BimProperty.NewShared({path: ["dimensions", "depth"], value: 0, unit: 'm'}),
        },
    };

    return new SolverObjectInstance({
        solverIdentifier: "trench-dimensions",
        objectsIdentifier: "trench",
        objectsDefaultArgs,
        cache: false,
        solverFunction: (args) => {
            const repr = args.representationAnalytical;

			let length = 0;

            if (repr) {
                const geo = geometries.peekById(repr.geometryId);
				if (geo instanceof PolylineGeometry) {
                    length += geo.length();
				}
				if (geo instanceof GraphGeometry) {
					for (const [p1, p2] of geo.iterEdgesPoints()) {
						const d = p1.distanceTo(p2);
						length += d;
					}
				}
            }
            const volume = length * args.legacyProps.depth.as('m') * args.legacyProps.width.as('m');
            return {
                legacyProps: [
                    { path: ["dimensions", "length"], value: length, unit: "m" },
                    { path: ["dimensions", "volume"], value: volume, unit: "m3" },
                ]
            };
        },
    });
}

export const TrenchKeyProps = {
    width: BimProperty.NewShared({
        path: ['dimensions', 'width'],
        value: 0,
        unit: 'in',
    }),
    volume: BimProperty.NewShared({
        path: ['dimensions', 'volume'],
        value: 0,
        unit: 'yd3',
    }),
    wiringType: BimProperty.NewShared({
        path: ['wiring | wire_type'],
        value: '',
    })
}

type TrenchPricingArg = {
    props: typeof TrenchKeyProps,
}

function createTrenchPricePatch(
    instanceRelatedArgs: TrenchPricingArg,
    commonArgs: InstanceCostsGlobal,
): SolverInstancePatchResult {
    const idProps: typeof TrenchIdProps = {
        wiringType: instanceRelatedArgs.props.wiringType
    }
    const costPerCubicMeter = sumCostComponents(mergeCostComponents(
        commonArgs.costs.find(x => ObjectUtils.areObjectsEqual(x.props, idProps))?.costs,
        createTrenchingDefaultCostsPerQubicMeter(),
    ))

    const volumeMeters = instanceRelatedArgs.props.volume.as('m3');

    const fullCost = volumeMeters * costPerCubicMeter;

    const ref = instanceRelatedArgs.props.wiringType.asText();

    const patch: BimPropertyData[] = [
        {
            path: ['cost', 'cost_item'],
            value: ref ?? 'unknown trench',
            readonly: true,
        },
        {
            path: ['cost', 'total_cost'],
            value: fullCost,
            unit: 'usd',
            readonly: true,
        },
    ];
    return {
        legacyProps: patch,
        removeProps: [
            BimProperty.MergedPath(['cost', 'status']),
            BimProperty.MergedPath(['cost', 'has_zero_costs']),
            BimProperty.MergedPath(['cost', 'volume_unit']),
            BimProperty.MergedPath(['cost', 'per_CY_cost']),
        ]
    }
}

export function registerTrenchPricingSolver(bim: Bim, costs: CostsConfigProvider) {

    const TrenchesPricingSharedArgIdent = 'trench-price-shared-arg-ident';

    const global = LazyDerived.new1(
        'trenchPricingGlobal',
        [bim.unitsMapper],
        [costs.lazyInstanceCostsByType(TrenchTypeIdent)],
        ([costs]) => {
            return new InstanceCostsGlobal(costs, bim.unitsMapper)
        }
    )

    bim.runtimeGlobals.registerByIdent(TrenchesPricingSharedArgIdent, global);

    bim.reactiveRuntimes.registerRuntimeSolver(new SolverObjectInstance({
        solverIdentifier: 'trench-pricing-solver',
        objectsDefaultArgs: {
            legacyProps: TrenchKeyProps,
        },
        objectsIdentifier: TrenchTypeIdent,
        globalArgsSelector: {
            [TrenchesPricingSharedArgIdent]: InstanceCostsGlobal,
        },
        solverFunction: (props, globals) => {
            const valueUnitOnlyProps = extractValueUnitPropsGroup(props.legacyProps);
            const shared = globals[TrenchesPricingSharedArgIdent];
            if (shared instanceof Failure) {
                return {}
            }
            const result = createTrenchPricePatch({ props: valueUnitOnlyProps }, shared.value);
            return result;
        }
    }));
}

export const TrenchIdProps = {
    wiringType: BimProperty.NewShared({
        path: ['wiring | wire_type'],
        value: '',
    })
}

export const expandTrenchLegacyPropsWithCostTableLinks: ExpandLegacyPropsWithCostTableLinks = (params) => {
    const sis = Array.from(params.bim.instances.peekByIds(params.ids).values());
    const groupByModule = Array.from(IterUtils.groupBy(
        sis,
        (o) => {
            const props = o.properties.extractPropertiesGroup(TrenchIdProps)
            return props.wiringType.asText();
        },
    ))
    if (groupByModule.length !== 1) {
        return;
    }
    createFocusLinkOnSample({
        costModelFocusApi: params.costModelFocusApi,
        sample: sis[0],
        targetPui: PUI_GroupNode.tryGetNestedChild(params.pui, ['cost']),
        label: 'Setup trench costs'
    })
}
