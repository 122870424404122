import type { Bim, IdBimScene } from "src"
import type { PUI_GroupNode } from "ui-bindings"
import type { MatchesCostCategoryParams } from ".."


export type FocusOnCostModel = (params: MatchesCostCategoryParams) => void
export type FocusOnTable = (tableId: string) => void

export interface CostModelFocusApi {
    focusOnCostModel: FocusOnCostModel,
    focusOnTable: FocusOnTable,
}

export type ExpandLegacyPropsWithCostTableLinks = (params: {
    ids: IdBimScene[],
    bim: Bim,
    pui: PUI_GroupNode,
    costModelFocusApi: CostModelFocusApi,
}) => void;

export class FocusOnTableCommand {
    constructor(public tableId: string) {}
}

export class FocusOnCategoryThatMatchesTheInstance_Command {
    constructor(public params: MatchesCostCategoryParams) {}
}

export type CostModelTableFocusCommand =
    FocusOnCategoryThatMatchesTheInstance_Command |
    FocusOnTableCommand


