import type { SceneInstanceShapeMigration } from "../../scene/SceneInstancesArhetypes";
import type { CsvPropsMigration, ValueTypeCsv} from "../pv-module/migrations/EnergyPvModulePropsMigration";
import { applyPatchIfNotExist, extractProps, makeHash, parseCSVTable, tryFindByManufactureAndPower } from "../pv-module/migrations/EnergyPvModulePropsMigration";


let migration: CsvPropsMigration | undefined = undefined;

export function addEnergyInverterProps_ShapeMigration(toVersion: number)
    : SceneInstanceShapeMigration
{
    return {
        toVersion,
        validation: {
            updatedProps: [],
            deletedProps: [],
        },
        patch: (inst, unitMapper) => {
            if (!migration) {
                migration = parseCSVTable({
                    csv: migrationTable,
                    typePerHeader: valueTypes,
                    unitsMapper: unitMapper,
                    matchPropsPaths: matchProps,
                    bestMathProps: {
                        manufacturerPath: 'commercial | manufacturer',
                        powerPath: 'inverter | max_power',
                    }
                });
            }
            const maxPowerPath = 'inverter | max_power';
            const maxPower = inst.properties.get(maxPowerPath)?.as("W");
            if(!maxPower) {
                const modelManufacturerPropsPaths = [
                    ['commercial', 'manufacturer'],
                    ['commercial', 'model'],
                ];
                const hash = makeHash(
                    extractProps(inst.properties, modelManufacturerPropsPaths),
                    unitMapper
                );
                for (const row of migration.perHash.values()) {
                    const rowHash = makeHash(
                        extractProps(row.allProps, modelManufacturerPropsPaths),
                        unitMapper
                    );
                    if(hash === rowHash) {
                        const maxPower = row.allProps.get(maxPowerPath);
                        if(maxPower) {
                            //console.warn("Inverter has no max_power. Set max power for", maxPower, inst);
                            inst.properties.applyPatch([[maxPowerPath, maxPower]]);
                        }
                        break;
                    }
                }
            }
            
            const hash = makeHash(
                extractProps(inst.properties, migration.matchPropPaths),
                unitMapper
            );

            if(!hash) {
                console.warn("Inverter has invalid props. Set generic inverter for", inst);
                inst.properties.applyPatch(migration.defaultMatchPropsPatch);
                inst.properties.applyPatch(migration.defaultUpdatePropsPatch);
                return;
            }
            const propertiesPatch = migration.perHash.get(hash);
            if(!propertiesPatch) {
                const row = tryFindByManufactureAndPower(inst.properties, migration);
                let updatePropsPatch = migration.defaultUpdatePropsPatch;
                if(row) {
                    updatePropsPatch = row.updatePropsPatch;
                    console.warn("Inverter doesn't found in migration table. Set properties from", row, hash, inst);
                } else {
                    console.warn("Inverter doesn't found in migration table. Set generic energy properties for", hash, inst);
                }
                applyPatchIfNotExist(inst.properties, updatePropsPatch);
                return;
            }
            applyPatchIfNotExist(inst.properties, propertiesPatch.updatePropsPatch);
        },
    }
}

const matchProps = [
    ['commercial | model'],
    ['inverter | max_power'],
    ['inverter | modules_count']
];

const valueTypes = new Map<string, ValueTypeCsv>([
    ['commercial | manufacturer', "string"],
    ['commercial | model', "string"],
    ['inverter | max_power', "unit"],
    ['inverter | modules_count', "number"],
    ['inverter | fxmr', "boolean"],
    ['splitter', 'splitter'],
    ['inverter | efficiency | max_efficiency', 'optional-number'],
    ['inverter | efficiency | cec_efficiency', 'optional-number'],
    ['inverter | efficiency | euro_efficiency', 'optional-number'],
]);

const migrationTable = `
commercial | manufacturer;commercial | model;inverter | max_power;inverter | modules_count;inverter | fxmr;splitter;inverter | efficiency | max_efficiency;inverter | efficiency | cec_efficiency;inverter | efficiency | euro_efficiency
Generic;Central 3605;3605 kW;1;FALSE;;0,989;0,985;
Generic;Central Inverter;3605 kW;1;FALSE;;0,989;0,985;
Sungrow;Central SG1100UD;1100 kW;1;FALSE;;0,989;0,985;
Sungrow;Central SG3600UD;3600 kW;1;FALSE;;0,989;0,985;
Sungrow;Central SG4400UD;4400 kW;1;FALSE;;0,99;;0,987
Sungrow;Central SG6600UD-2000V;6600 kW;1;FALSE;;0,989;0,985;
TMEIC;Central Solar Ware 840 - PVU-L0840;840 kW;1;FALSE;;0,989;0,985;
TMEIC;Central Solar Ware 840 - PVU-L0840;4200 kW;5;FALSE;;0,989;0,985;
TMEIC;Central Solar Ware 840 - PVU-L0840;2520 kW;3;FALSE;;0,989;0,985;
TMEIC;Central Solar Ware 840 - PVU-L0840;1680 kW;2;FALSE;;0,989;0,985;
TMEIC;Central Solar Ware 840 - PVU-L0840;3360 kW;4;FALSE;;0,989;0,985;
TMEIC;Central Solar Ware 840 - PVU-L0840;5040 kW;6;FALSE;;0,989;0,985;
TMEIC;Central Solar Ware 840 - PVU-L0840;5880 kW;7;FALSE;;0,989;0,985;
TMEIC;Central Solar Ware 840 - PVU-L0840;6720 kW;8;FALSE;;0,989;0,985;
TMEIC;Central Solar Ware 840 - PVU-L0840;7560 kW;9;FALSE;;0,989;0,985;
Chint Power Systems (CPS);CPS SCH275KTL-DO/US-800 (275kVA) (24);275 kW;1;FALSE;;0,99;0,985;
Gamesa Electric;PV4300 UEP_v7.2_CEC_60Hz;4299 kW;1;FALSE;;0,994;0,9914;
FIMER;PVS980-CS 2200kW;2200 kW;1;FALSE;;0,988;;0,986
Sungrow;SG3150U;3150 kW;1;FALSE;;0,988;0,985;0
Sungrow;SG3150UD-MV-US;3150 kW;1;FALSE;;0,988;0,985;0
Sungrow;SG350HX-20A;320 kW;1;FALSE;;0,9902;;0,989
Sungrow;SG4400UD;4400 kW;1;FALSE;;0,99;;0,987
Sungrow;SG4400UD-MV-US;4400 kW;1;FALSE;;0,99;;0,987
Ginlong Technologies;Solis-125K-EHV-5G-US-PLUS;125 kW;1;FALSE;;0,99;0,985;
Ginlong Technologies;Solis-185K-EHV-5G-US-PLUS;185 kW;1;FALSE;;0,99;0,985;
Ginlong Technologies;Solis-255K-EHV-5G-US-PLUS;255 kW;1;FALSE;;0,99;0,985;
Sungrow;String SG250HX;250 kW;1;FALSE;;0,97;;0,95
Sungrow;String SG250HX;350 kW;1;FALSE;;0,97;;0,95
Sungrow;String SG250HX;700 kW;2;FALSE;;0,97;;0,95
SMA;Sunny Central 2500_EV;2500 kW;1;FALSE;;0,986;;0,984
SMA;Sunny Central 2500_EV;5000 kW;2;FALSE;;0,986;;0,984
SMA;Sunny Central 2500-EV;2500 kW;1;FALSE;;0,986;;0,984
SMA;Sunny Central 2500-EV;5000 kW;2;FALSE;;0,986;;0,984
SMA;Sunny Central 4200 UP_1500 m;4200 kW;1;FALSE;;0,987;;0,986
SMA;Sunny Central 4400 UP_1.4;4400 kW;1;FALSE;;0,987;;0,986
SMA;Sunny Central 4400 UP-US (CEC)_1.3;4400 kW;1;FALSE;;0,987;;0,986
SMA;Sunny Central 4400 UP-US (CEC)_1.3;3620 kW;1;FALSE;;0,987;;0,986
Sungrow;Central SG8800UD-2000V;8800 kW;1;FALSE;;0,99;;0,987
`;
