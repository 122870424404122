import { PileWeightClass, PileMotorType, PileDamperType, PileStrengthModifier } from '../anyTracker/PilesFeatures';

function wrapInPath(d: string) {
    return `<path fill-rule="evenodd" clip-rule="evenodd" fill="currentColor" ${d}/>`;
}
const standardMotor = wrapInPath('d="M12.0007 0.400024L21.2812 12.0008L12.0007 23.6016L2.71997 12.0008L12.0007 0.400024ZM5.28123 12.0008L12.0007 20.4L18.72 12.0008L12.0007 3.60159L5.28123 12.0008Z"');

const heavyMotor = wrapInPath('d="M21.2805 12L12 0.39917L2.71924 12L12 23.6007L21.2805 12ZM12 20.3992L5.2805 12L12 3.60073L18.7193 12L12 20.3992ZM16.25 12L12.0001 6.33337L7.75 12L12.0001 17.6667L16.25 12ZM12 14.3334L10.25 12L12 9.66671L13.75 12L12 14.3334Z"');

const heavyDefault = wrapInPath('d="M12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7ZM9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12Z"');

const defaultWithoutDamper = wrapInPath('d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"');

const defaultWithDamper = `
<path
    d="M12 4C10.5164 4 9.12709 4.40386 7.93607 5.10764L6.48522 3.65679C8.06609 2.60975 9.96184 2 12 2C14.0382 2 15.9339 2.60975 17.5148 3.65679L16.0639 5.10764C14.8729 4.40386 13.4836 4 12 4Z"
    fill="currentColor"
/>
<path
    d="M5.10764 16.0639C4.40386 14.8729 4 13.4836 4 12C4 10.5164 4.40386 9.12709 5.10764 7.93607L3.65679 6.48522C2.60975 8.06609 2 9.96184 2 12C2 14.0382 2.60975 15.9339 3.65679 17.5148L5.10764 16.0639Z"
    fill="currentColor"
/>
<path
    d="M6.48522 20.3432L7.93607 18.8924C9.12709 19.5961 10.5164 20 12 20C13.4836 20 14.8729 19.5961 16.0639 18.8924L17.5148 20.3432C15.9339 21.3902 14.0382 22 12 22C9.96184 22 8.06609 21.3902 6.48522 20.3432Z"
    fill="currentColor"
/>
<path
    d="M18.8924 7.93607L20.3432 6.48522C21.3902 8.06609 22 9.96184 22 12C22 14.0382 21.3902 15.9339 20.3432 17.5148L18.8924 16.0639C19.5961 14.8729 20 13.4836 20 12C20 10.5164 19.5961 9.12709 18.8924 7.93607Z"
    fill="currentColor"
/>
`;

const standardCantilever = wrapInPath('d="M12.0002 7.0501L8.0357 3.08557L3.08521 3.086L3.08626 8.03563L7.05048 11.9998L3.08598 15.9643V20.9141H8.03573L12.0002 16.9496L15.9647 20.9141H20.9145V15.9643L16.95 11.9998L20.9144 8.03541V3.086L15.9648 3.08557L12.0002 7.0501ZM12.0001 9.17149L7.41436 4.58569L4.58545 4.58594L4.58605 7.41424L9.17172 11.9999L4.58591 16.5857V19.4142H7.41433L12.0001 14.8283L16.586 19.4142H19.4144V16.5857L14.8286 11.9999L19.4143 7.41416V4.58594L16.5859 4.58569L12.0001 9.17149Z"');

const heavyCantilever = wrapInPath('d="M8.0357 3.08557L12.0002 7.0501L15.9648 3.08557L20.9144 3.086V8.03541L16.95 11.9998L20.9145 15.9643V20.9141H15.9647L12.0002 16.9496L8.03573 20.9141H3.08598V15.9643L7.05048 11.9998L3.08626 8.03563L3.08521 3.086L8.0357 3.08557ZM7.41436 4.58569L12.0001 9.17149L16.5859 4.58569L19.4143 4.58594V7.41416L14.8286 11.9999L19.4144 16.5857V19.4142H16.586L12.0001 14.8283L7.41433 19.4142H4.58591V16.5857L9.17172 11.9999L4.58605 7.41424L4.58545 4.58594L7.41436 4.58569ZM10.0902 3.09009L12.0003 4.99995L13.9101 3.09009H10.0902ZM3.09009 13.91L5.00009 12L3.09009 10.09V13.91ZM19.0001 12L20.91 10.09V13.9099L19.0001 12ZM10.0901 20.9101L12.0003 19L13.9102 20.9101H10.0901Z"');

const heavyEdge = wrapInPath('d="M21 7H7V17H17V7ZM9 15V9H15V15H9Z"');

const edgeWithoutDamper = wrapInPath('d="M3 3H21V21H3V3ZM5 5V19H19V5H5Z"');

const edgeWithDamper = `
<path
    d="M18.1717 3H5.82843L7.82843 5H16.1717L18.1717 3Z"
    fill="currentColor"
/>
<path
    d="M19 16.1716V7.82854L21 5.82854V18.1716L19 16.1716Z"
    fill="currentColor"
/>
<path
    d="M7.82855 19H16.1716L18.1716 21H5.82854L7.82855 19Z"
    fill="currentColor"
/>
<path
    d="M5 7.82843V16.1717L3 18.1717V5.82843L5 7.82843Z"
    fill="currentColor"
/>
`;

const damperSign = `
<path
    d="M12.0002 10.5857L17.0002 5.58569L18.4144 5.58582V6.99991L13.4144 11.9999L18.4144 16.9999V18.4141H17.0002L12.0002 13.4141L7.00015 18.4141H5.58594V16.9999L10.586 11.9999L5.58599 6.99991L5.58569 5.58582L7.00021 5.58569L12.0002 10.5857Z"
    fill="#090D0F"
    fill-opacity="0.94"
/>
`;

export function getPileTypeIcon(weight: PileWeightClass, motor: PileMotorType, damper: PileDamperType, strengthModifier: PileStrengthModifier, color?: string) {
    return `<svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        style="color:${color ?? "#FFFFFF"};vertical-align:middle;"
    >
        ${motor === PileMotorType.Motor ?
            `${standardMotor} ${weight === PileWeightClass.Heavy ? heavyMotor : ''}` :
            `${strengthModifier === PileStrengthModifier.None ?
                `
                    ${weight === PileWeightClass.Heavy ? heavyDefault : ''}
                    ${damper === PileDamperType.None ? defaultWithoutDamper : defaultWithDamper}
                ` :
                ""
            }
            ${strengthModifier === PileStrengthModifier.Cantilever ?
                `
                    ${weight === PileWeightClass.Heavy ? heavyCantilever : standardCantilever}
                ` :
                ""
            }
            ${strengthModifier === PileStrengthModifier.Edge ?
                `
                    ${weight === PileWeightClass.Heavy ? heavyEdge : ''}
                    ${damper === PileDamperType.None ? edgeWithoutDamper : edgeWithDamper}
                ` :
                ""
            }
            ${damper === PileDamperType.Damper ? damperSign : ''}
            `
        }
    </svg>`;
}