import {
    CompressibleNumbersArray, IterUtils, WorkerClassPassRegistry,
    Failure, Success, ObjectUtils
} from 'engine-utils-ts';
import type { TMY_ColumnDates } from '../meteo/TMY_ColumnDates';
import { NumberProperty } from '../properties/PrimitiveProps';
import type { PropValueTotalHash } from '../properties/Props';
import { PropertyBase, PropsGroupBase, PropsGroupField } from '../properties/Props';
import { getPropertyReferenceId } from '../properties/PropsRefsIds';
import { NumbersArrayProperty } from '../properties/spreadsheets/NumbersArrayProperty';
import { type EnergyStageUiWarning } from './EnergyStageWarning';
import { type EnergyResult } from './EnergyResult';
import type { EnergyStageChart} from './EnergyStageCharts';
import type { ShadingFactorsTable } from 'src/trackers/shading/ShadingFactorsTable';


export const EnergyStagesNames = [
    'horizontal_irradiance',
    'tilt',
    'shading',
    'bifaciality',
    'soiling',
    'incidence_angle',
    'pv_conversion',
    'temperature',
    'spectral_correction',
    'module_quality',
    'module_mismatch',
    'strings_mismatch',
    'dc_wiring',
    'inverter_voltage_clipping',
    'inverter_efficiency',
    'inverter_power_clipping',
] as const;
Object.freeze(EnergyStagesNames);

export type EnergyStageName = typeof EnergyStagesNames[number];


export class EnergyPipelineStage {
    constructor(
        public readonly name: EnergyStageName,
        public readonly energy: EnergyResult<EnergyStageChart>,
    ) {
        Object.freeze(this);
    }

    description(): string {
        return '';
    }

    equals(other: EnergyPipelineStage): boolean {
        if (this === other) {
            return true;
        }
        if (this.energy.constructor !== other.energy.constructor) {
            return false;
        }
        if (this.energy instanceof Failure) {
            return ObjectUtils.areObjectsEqual(this.energy, other.energy);
        }
        return this.name === other.name
            && this.energy.value.equals(((other.energy as Success<EnergyStageChart>).value))
    }

    energyTotal(unit: string): EnergyResult<number> {
        if (this.energy instanceof Failure) {
            return this.energy;
        }
        return new Success(this.energy.value.energyTotal(unit), this.energy.warnings);
    }

    toEnergyStageWarnings(): EnergyStageUiWarning[] {
        const res: EnergyStageUiWarning[] = [];
        if (this.energy instanceof Failure) {
            res.push(...this.energy.toEnergyStageWarnings('error'));
        } else if (this.energy.warnings) {
            for (const f of this.energy.warnings) {
                res.push(...f.toEnergyStageWarnings('warning'));
            }
        }
        return res;
    }
}
WorkerClassPassRegistry.registerClass(EnergyPipelineStage);

export class EnergyPipelineProperty extends PropertyBase {

    constructor(
        public readonly stages: EnergyPipelineStage[],
        public readonly dates: TMY_ColumnDates | null,
        public readonly nominal_power: CompressibleNumbersArray,
        public readonly meteo_context: AdditionalEnergyYieldMeteoContext | null,
        public readonly dc_nominal_power: number,
        public readonly shaded_modules_area: number,
        public readonly shading_factors: ShadingFactorsTable|null,
    ) {
        super();
        stages.sort((s1, s2) => EnergyStagesNames.indexOf(s1.name) - EnergyStagesNames.indexOf(s2.name));
        Object.freeze(this.stages);
        Object.freeze(this);
    }

    performanceRatio(): number {
        const lastStage = this.stages.at(-1);
        if (!(lastStage?.energy instanceof Success)) {
            return 0;
        }
        const output = lastStage.energy.value.energyTotal('Wh');
        const nominalOutput = this.nominal_power.sum('W');
        return output / nominalOutput;
    }

    uniqueValueHash(): PropValueTotalHash {
        return getPropertyReferenceId(this);
    }
    equals(other: PropertyBase): boolean {
        if (this === other) {
            return true;
        }
        if (!(other instanceof EnergyPipelineProperty)) {
            return false;
        }
        return IterUtils.areArraysEqual(this.stages, other.stages, (a, b) => a.equals(b));
    }

    energyTotal(unit: string): number {
        const lastStage = this.stages.at(-1);
        if (!(lastStage?.energy instanceof Success)) {
            return 0;
        }
        return lastStage.energy.value.energyTotal(unit);
    }

    lossPercentPerStage(): Map<EnergyStageName, number> {
        const res = new Map<EnergyStageName, number>();
        if (this.stages.length === 0) {
            return res;
        }
        let prevStageEnergy = this.stages[0].energyTotal('Wh');
        for (let i = 1; i < this.stages.length; i++) {
            const stage = this.stages[i];
            const energy = stage.energyTotal('Wh');

            if (prevStageEnergy instanceof Success && energy instanceof Success) {
                const loss = (energy.value - prevStageEnergy.value) / prevStageEnergy.value;
                res.set(stage.name, loss * 100);
                prevStageEnergy = energy;
            } else {
                res.set(stage.name, 0);
            }

            if (energy instanceof Success) {
                prevStageEnergy = energy;
            }
        }
        return res;
    }
    
    pipelineCharts(): Map<string, CompressibleNumbersArray> {
        const res = new Map<string, CompressibleNumbersArray>();
        let prevPowerChart: CompressibleNumbersArray | null = null;

        for (let i = 0; i < this.stages.length; ++i) {

            const stage = this.stages[i];
            if (stage.energy instanceof Failure) {
                continue;
            }
            const energy = stage.energy.value;

            const output_power = CompressibleNumbersArray.newFromValues('MW', energy.toPowerChart('MW'));
            let power_loss: CompressibleNumbersArray | null = null;
            if (prevPowerChart) {
                power_loss = CompressibleNumbersArray.map2(
                    prevPowerChart,
                    output_power,
                    'MW',
                    (v1, v2) => v1 - v2,
                );
            }
            prevPowerChart = output_power;

            res.set(stage.name + ' | output_power', output_power);
            if (power_loss) {
                res.set(stage.name + ' | power_loss', power_loss);
            }

            const additional_charts = energy.additional_charts();
            for (const [key, chart] of Object.entries(additional_charts)) {
                res.set(stage.name + ' | ' + key, chart);
            }
        }

        if (this.meteo_context) {
            for (const [key, value] of Object.entries(this.meteo_context)) {
                if (value instanceof NumbersArrayProperty) {
                    res.set('meteo_context | ' + key, value.value);
                }
            }
        }
        return res;
        
    }
}
WorkerClassPassRegistry.registerClass(EnergyPipelineProperty);


export class AdditionalEnergyYieldMeteoContext extends PropsGroupBase {

    [key: string]: NumbersArrayProperty | NumberProperty | PropsGroupField | Function;

    constructor(args: Partial<AdditionalEnergyYieldMeteoContext>) {
        super();

        for (const [key, value] of Object.entries(args)) {
            if (value instanceof NumberProperty || value instanceof NumbersArrayProperty) {
                this[key] = value;
            } else if (value) {
                console.warn(`invalid additional energy yield data value ${key}`, value);
            }
        }
        Object.freeze(this);
    }
}
WorkerClassPassRegistry.registerClass(AdditionalEnergyYieldMeteoContext);



