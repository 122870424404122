import * as echarts from 'echarts/core';

import {
    BarChart, type BarSeriesOption,
    BoxplotChart, type BoxplotSeriesOption,
    // FunnelChart, type FunnelSeriesOption,
    LineChart, type LineSeriesOption,
    HeatmapChart, type HeatmapSeriesOption,
    PieChart, type PieSeriesOption,
    // TreemapChart, type TreemapSeriesOption,
    // SankeyChart, type SankeySeriesOption,
    CustomChart, type CustomSeriesOption, ScatterChart,
} from 'echarts/charts';

// Import the tooltip, title, rectangular coordinate system, dataset and transform components
import {
    BrushComponent, type BrushComponentOption,
    // CalendarComponent, type CalendarComponentOption,
    DatasetComponent, type DatasetComponentOption,
    DataZoomComponent, type DataZoomComponentOption,
    GridComponent, type GridComponentOption,
    LegendComponent, type LegendComponentOption,
    PolarComponent, type PolarComponentOption,
    TitleComponent, type TitleComponentOption,
    TooltipComponent, type TooltipComponentOption,
    TransformComponent,
    VisualMapComponent, type VisualMapComponentOption, ToolboxComponent
} from 'echarts/components';

// Features like Universal Transition and Label Layout
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import type { ComposeOption } from 'echarts/core';
import type { ContinousVisualMapOption, PiecewiseVisualMapOption } from 'echarts/types/dist/shared';
import type { ScatterSeriesOption, ToolboxComponentOption } from 'echarts';
import { KrMath } from 'math-ts';
// import type {c
//     CustomSeriesRenderItem,
//     CustomSeriesRenderItemAPI,
// } from 'echarts';

// Register the required components
echarts.use([
    BarChart,
    BoxplotChart,
    // FunnelChart,
    BrushComponent,
    // CalendarComponent,
    LineChart,
    HeatmapChart,
    PieChart,
    // TreemapChart,
    // SankeyChart,
    ScatterChart,
    CustomChart,


    DatasetComponent,
    DataZoomComponent,
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    PolarComponent,
    ToolboxComponent,
    TransformComponent,
    VisualMapComponent,

    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
]);

type EChartOptions = ComposeOption<
    | BarSeriesOption
    | BoxplotSeriesOption
    // | FunnelSeriesOption
    | LineSeriesOption
    | HeatmapSeriesOption
    | PieSeriesOption
    | ToolboxComponentOption
    // | TreemapSeriesOption
    // | SankeySeriesOption
    | ScatterSeriesOption
    | CustomSeriesOption
> & {dataZoomSourceObject?: Object};

type ECompOptions =
    | BrushComponentOption
    // | CalendarComponentOption
    | DatasetComponentOption
    | DataZoomComponentOption
    | LegendComponentOption
    | GridComponentOption
    | PolarComponentOption
    | TitleComponentOption
    | TooltipComponentOption
    | VisualMapComponentOption
    | ContinousVisualMapOption
    | PiecewiseVisualMapOption

export {echarts}; 

export type {
    EChartOptions,
    ECompOptions,

    BarSeriesOption, BoxplotSeriesOption, LineSeriesOption, PieSeriesOption,
    HeatmapSeriesOption,
    //TreemapSeriesOption, SankeySeriesOption,
    ScatterSeriesOption, CustomSeriesOption,

    // CalendarComponentOption,
    BrushComponentOption, DatasetComponentOption,
    DataZoomComponentOption,
    LegendComponentOption, GridComponentOption, PolarComponentOption,
    TitleComponentOption, TooltipComponentOption, VisualMapComponentOption,
    ContinousVisualMapOption, PiecewiseVisualMapOption,
};


export * from './CHD_Converters';



export function chooseRoundedChartMinMaxForNumbers(args: {
    values: number[],
    preferZeroMin: boolean,
}): [number, number] {

    let distrRealMin: number = Infinity;
    let distrRealMax: number = -Infinity;
    for (const v of args.values) {
        if (isFinite(v)) {
            distrRealMin = Math.min(distrRealMin, v);
            distrRealMax = Math.max(distrRealMax, v);
        }
    }

    if (!(distrRealMin <= distrRealMax)) {
        return [0, 1];
    }

    let chartMin = KrMath.floorToPowerOf(distrRealMin * 0.99, 10);
    let chartMax = KrMath.ceilToPowerOf(distrRealMax + 0.0001, 10);

    for (const roundingCoeff of chartMinMaxRoundingCoeffs) {
        if (distrRealMax < chartMax * roundingCoeff) {
            chartMax = chartMax * roundingCoeff;
            break;
        }
    }

    if (chartMin > 0) {
        if (args.preferZeroMin || chartMin < chartMax * 0.26) {
            chartMin = 0;
        }
    } else {
        for (const roundingCoeff of chartMinMaxRoundingCoeffs) {
            if (distrRealMin > chartMin * roundingCoeff) {
                chartMin = chartMin * roundingCoeff;
                break;
            }
        }
    }

    return [chartMin, chartMax];
}

const chartMinMaxRoundingCoeffs = [0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.9];
