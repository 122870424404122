// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

export class InputFileDescription {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):InputFileDescription {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsInputFileDescription(bb:flatbuffers.ByteBuffer, obj?:InputFileDescription):InputFileDescription {
  return (obj || new InputFileDescription()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsInputFileDescription(bb:flatbuffers.ByteBuffer, obj?:InputFileDescription):InputFileDescription {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new InputFileDescription()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

id():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

version():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

initialFileName():string|null
initialFileName(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
initialFileName(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

filePath():string|null
filePath(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
filePath(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

textDescription():string|null
textDescription(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
textDescription(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startInputFileDescription(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addId(builder:flatbuffers.Builder, id:number) {
  builder.addFieldInt32(0, id, 0);
}

static addVersion(builder:flatbuffers.Builder, version:number) {
  builder.addFieldInt32(1, version, 0);
}

static addInitialFileName(builder:flatbuffers.Builder, initialFileNameOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, initialFileNameOffset, 0);
}

static addFilePath(builder:flatbuffers.Builder, filePathOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, filePathOffset, 0);
}

static addTextDescription(builder:flatbuffers.Builder, textDescriptionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, textDescriptionOffset, 0);
}

static endInputFileDescription(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createInputFileDescription(builder:flatbuffers.Builder, id:number, version:number, initialFileNameOffset:flatbuffers.Offset, filePathOffset:flatbuffers.Offset, textDescriptionOffset:flatbuffers.Offset):flatbuffers.Offset {
  InputFileDescription.startInputFileDescription(builder);
  InputFileDescription.addId(builder, id);
  InputFileDescription.addVersion(builder, version);
  InputFileDescription.addInitialFileName(builder, initialFileNameOffset);
  InputFileDescription.addFilePath(builder, filePathOffset);
  InputFileDescription.addTextDescription(builder, textDescriptionOffset);
  return InputFileDescription.endInputFileDescription(builder);
}
}
