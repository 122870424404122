import type { Bim, Config, FarmLayoutConfig, IdBimScene, IdConfig, SceneInstance, SiteArea } from "bim-ts";
import { FarmLayoutConfigType, SceneInstances, SceneInstancesProperty, SceneObjDiff } from "bim-ts";
import type { ResultAsync, ScopedLogger } from "engine-utils-ts";
import { DefaultMap, Failure, InProgress, LazyDerived, LazyDerivedAsync, Success, Yield } from "engine-utils-ts";
import { createContext } from "../panels-config-ui/GeneratePanelUiBindings";

export interface LayoutProperties {
    perZone: SiteAreaProperties[];
    roads: IdBimScene[];
}

export interface SiteAreaProperties {
    areaId: IdBimScene | undefined,
    areaName: string,
    acPowerKW: number,
    dcPowerKW: number,
    numberOfBlocks: number;
    blocks: DefaultMap<string | number, BlockProperties[]>;
}

export class BlockProperties {
    constructor(
        public id: string | number,
        public transformer: TransformerProperties,
        public inverters: InverterProperties[],
    ){}

    static createBlockId(id: number | undefined, transformerModel: string, inverterModel: string): number | string {
        return id ? `[${id}][${transformerModel}][${inverterModel}]` : `[${transformerModel}][${inverterModel}]`;
    }
}

export interface InverterProperties {
    id: IdBimScene,
    model: string,
    dcPowerKw: number;
    maxDcPowerKw: number;
    dcRatio: number;
}

export interface TransformerProperties {
    id: IdBimScene,
    areaIndex: number | undefined,
    model: string,
    powerKw: number,
    acPowerKW: number,
    dcPowerKW: number,
}

export function createLazyLayoutMetrics(bim: Bim, type: string, logger: ScopedLogger){
    const context = createContext(bim, type);
    const substationsLazyList = bim.instances.getLazyListOf({
        type_identifier: 'substation', 
        relevantUpdateFlags: SceneObjDiff.LegacyProps | SceneObjDiff.NewProps
    });
    const roadsLazyList = bim.instances.getLazyListOf({
        type_identifier: 'road', 
    });
    const lazyMetricsAsync = LazyDerivedAsync.new3(
        'substation-properties',
        null, 
        [substationsLazyList, roadsLazyList, context.configObserver], 
        ([substations, roads, configs]) => {
        const metrics = extractMetricsFromLayout(bim, substations, roads, configs, logger);
        return metrics;
    });
    const lazyMetrics = LazyDerived.new1<Map<IdBimScene, LayoutProperties>, ResultAsync<Map<IdBimScene, LayoutProperties>>>(
        'lazy-metrics', 
        null, 
        [lazyMetricsAsync], 
        ([metrics], prevResult) => {
        if(metrics instanceof Success){
            return metrics.value;
        } else if((metrics instanceof Failure)){
            logger.error(metrics.msg);
        } else if(metrics instanceof InProgress) {

        } else {
            logger.error("Raw status", metrics);
        }
        return prevResult ?? new Map();
    });

    return lazyMetrics;
}

function* extractMetricsFromLayout(
    bim: Bim, 
    substations:[IdBimScene, SceneInstance][],
    roads:[IdBimScene, SceneInstance][],
    configs: [IdConfig, Config][],
    logger: ScopedLogger,
    ): Generator<Yield, Map<IdBimScene, LayoutProperties>> {
    const substationProperties = new Map<IdBimScene, LayoutProperties>();
    const timeStart = Date.now();

    for (const [substationId, substationInst] of substations) {
        const ids = bim.instances.spatialHierarchy.gatherIdsWithSubtreesOf({ids: [substationId]});
        const instances = bim.instances.peekByIds(ids);

        const transformersInstances:[IdBimScene, SceneInstance][] = [];

        for (const [childId, childInst] of instances) {
            if(childInst.type_identifier === "transformer"){
                transformersInstances.push([childId, childInst]);
            }
        }

        yield Yield.Asap;

        const blocksLayout = new DefaultMap<string | number, BlockProperties[]>(() => []);
        for (const [transformerId, transformerInst] of transformersInstances) {
            const transformerModel = transformerInst.properties.get('commercial | model')?.asText();
            let label = transformerInst.properties.get('circuit | position | block_label')?.asNumber();
            if(!transformerModel){
                continue;
            }
            if(!transformerInst.representation){
                continue;
            }

    
            const transformer: TransformerProperties = {
                id: transformerId,
                areaIndex: transformerInst.properties.get('circuit | position | area_index')?.asNumber(),
                model: transformerModel,
                powerKw: transformerInst.properties.get('output | power')?.as('kW') ?? 0,
                acPowerKW: transformerInst.properties.get('circuit | block_capacity | ac_power')?.as('kW') ?? 0,
                dcPowerKW: transformerInst.properties.get('circuit | block_capacity | dc_power')?.as('kW') ?? 0,
            };

            const childIds = bim.instances.spatialHierarchy.gatherIdsWithSubtreesOf({ids: [transformerId]});
            let inverterModel = "";
            const inverters: InverterProperties[] = [];
            for (const childId of childIds) {
                const childInst = instances.get(childId);
                if(!childInst){
                    continue;
                }
                if(childInst.type_identifier === "inverter") {
                    const model = childInst.properties.get('commercial | model')?.asText();
                    if(!model){
                        continue;
                    }
                    inverterModel = model;
                    const dcPowerKw = childInst.properties.get('circuit | aggregated_capacity | dc_power')?.as('kW') ?? 0;
                    const maxDcPowerKw = childInst.properties.get('inverter | max_power')?.as('kW') ?? 0;
                    const inverter: InverterProperties = {
                        id: childId,
                        model: model,
                        dcPowerKw,
                        maxDcPowerKw,
                        dcRatio: dcPowerKw && maxDcPowerKw ? Math.round(100 * dcPowerKw / maxDcPowerKw) / 100 : 0,
                    };
                    inverters.push(inverter);
                }
            }
            const id = BlockProperties.createBlockId(label, transformerModel, inverterModel) ;
            const blocks = blocksLayout.getOrCreate(id);
            blocks.push(new BlockProperties(id, transformer, inverters));
        }
        const areaInst = bim.instances.peekById(substationId)!;

        const allSiteArea: SiteAreaProperties = {
            areaId: substationId,
            areaName: SceneInstances.uiNameFor(substationId, areaInst),
            numberOfBlocks: substationInst.properties.get('circuit | equipment | transformers_count')?.asNumber() ?? 0,
            acPowerKW: substationInst.properties.get('circuit | aggregated_capacity | ac_power')?.as('kW') ?? 0,
            dcPowerKW: substationInst.properties.get('circuit | aggregated_capacity | dc_power')?.as('kW') ?? 0,
            blocks: blocksLayout,
        };
        yield Yield.Asap;

        const selectedConfig = configs.find(([_id, config]) => config.connectedTo === substationId)?.[1];

        const allBlocks = new Map<IdBimScene, BlockProperties>();
        for (const blocks of allSiteArea.blocks.values()) {
            for (const block of blocks) {
                allBlocks.set(block.transformer.id, block);
            }
        }
        // logger.info("allBlocks", Array.from(blocksLayout));
        const perZone: SiteAreaProperties[] = [];
        const isSubarea = (a: SiteArea) => a.equipmentBoundaries instanceof SceneInstancesProperty;
        const isAllSiteArea = (a: SiteArea) => a.boundaries instanceof SceneInstancesProperty;
        if(selectedConfig && selectedConfig.type_identifier === FarmLayoutConfigType){
            const site_areas = selectedConfig.get<FarmLayoutConfig>().site_areas;
            for (let areaIndex = 0; areaIndex < site_areas.length; areaIndex++) {
                const zone = site_areas[areaIndex];
                if(isSubarea(zone) && zone.equipmentBoundaries instanceof SceneInstancesProperty){
                    const blocks = new DefaultMap<string | number, BlockProperties[]>(() => []);
                    let acPowerKW = 0;
                    let dcPowerKW = 0;
                    let blocksCount = 0;
                    for (const block of allBlocks.values()) {
                        if(block.transformer.areaIndex === areaIndex){
                            const array = blocks.getOrCreate(block.id);
                            blocksCount++;
                            array.push(block);
                            allBlocks.delete(block.transformer.id);
                            acPowerKW += block.transformer.acPowerKW;
                            dcPowerKW += block.transformer.dcPowerKW;
                        }
                    }
                    perZone[areaIndex] = {
                        areaId: undefined,
                        areaName: zone.name.value,
                        blocks,
                        numberOfBlocks: blocksCount,
                        acPowerKW,
                        dcPowerKW,
                    }
                } else if(isAllSiteArea(zone)){
                    perZone[areaIndex] = allSiteArea;
                } else {
                    //unlocated zone
                }
            }
            //unlocated zone = all - zones
            for (let i = 0; i < site_areas.length; i++) {
                const zone = site_areas[i];
                if(!isAllSiteArea(zone) && !isSubarea(zone) ){
                    const blocks = new DefaultMap<string|number, BlockProperties[]>(() => []);
                    let acPowerKW = 0;
                    let dcPowerKW = 0;
                    let blocksCount = 0;
                    for (const block of allBlocks.values()) {
                        const array = blocks.getOrCreate(block.id);
                        array.push(block);
                        blocksCount++;
                        allBlocks.delete(block.transformer.id);
                        acPowerKW += block.transformer.acPowerKW;
                        dcPowerKW += block.transformer.dcPowerKW;
                    }
                    perZone[i] = {
                        areaId: undefined,
                        areaName: zone.name.value,
                        blocks,
                        numberOfBlocks: blocksCount,
                        acPowerKW,
                        dcPowerKW,
                    }
                }
            }
        }
        
        substationProperties.set(substationId, { perZone, roads: roads.map(r => r[0]) });

        yield Yield.Asap;
    }
    // logger.info("farm metrics", Date.now() - timeStart, substationProperties);
    return substationProperties;
}