import { Aabb } from 'math-ts';
import { OrthographicCamera } from '../3rdParty/three';
import { FrustumExt, ShadowCullBit } from '../structs/FrustumExt';
import type { TotalBounds } from '../TotalBounds';

export const ShadowCastersHeightMax = 10;

export const GroundOverhang = 3;

export class GroundShadowFrustum {
	
	readonly frustum: FrustumExt = new FrustumExt(ShadowCullBit, new OrthographicCamera(-1, 1, 1, -1, 0, 1));
	readonly bounds: Aabb = Aabb.allocateSizeOne();
	readonly _prevBounds: Aabb = Aabb.allocateSizeOne();
	
	constructor() {
	}

	update(tb: TotalBounds) {
		if (tb.updated) {
			const bounds = this.bounds;
			bounds.copy(tb.bounds);
			bounds.elements[5] = this.bounds.elements[2] + ShadowCastersHeightMax;
			bounds.elements[0] -= GroundOverhang;
			bounds.elements[1] -= GroundOverhang;
			bounds.elements[3] += GroundOverhang;
			bounds.elements[4] += GroundOverhang;

			if (bounds.equals(this._prevBounds)) {
				return;
			}
			this._prevBounds.copy(bounds);

			const camera = this.frustum.camera as OrthographicCamera;

			// camera.position.set(totalBounds.centerX(), totalBounds.miny()-0.01, totalBounds.centerZ());
			// camera.up.set(0, 0, 1);
			camera.position.set(0, 0, 0);
			camera.up.set(0, 1, 0);
			camera.lookAt(0, 0, 10);

			camera.near = bounds.minz() -0.01;
			camera.far = bounds.maxz();
			camera.left = -bounds.minx();
			camera.right = -bounds.maxx();
			camera.bottom = bounds.miny();
			camera.top = bounds.maxy();

			camera.updateMatrixWorld(true);
			camera.updateProjectionMatrix();

			this.frustum.update(camera);
		} else {
			// renderer sets dirty to false
			// this.frustum.isDirty = false;
		}
	}
}
