<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path d="M2 6H6V2" stroke="currentColor" stroke-width="1.6" />
    <path d="M18 14H14V18" stroke="currentColor" stroke-width="1.6" />
    <circle cx="10" cy="10" r="2" fill="currentColor" />
    <circle cx="10" cy="16" r="0.75" fill="currentColor" />
    <circle cx="4" cy="16" r="0.75" fill="currentColor" />
    <circle cx="4" cy="10" r="0.75" fill="currentColor" />
    <circle cx="10" cy="4" r="0.75" fill="currentColor" />
    <circle cx="16" cy="4" r="0.75" fill="currentColor" />
    <circle cx="16" cy="10" r="0.75" fill="currentColor" />
</svg>
