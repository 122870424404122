import type { Bim, IdBimScene, PropertiesGroupFormatters, SceneInstance} from "src";
import { NumberProperty, SceneObjDiff } from "src";
import type { LazyVersioned} from "engine-utils-ts";
import { DefaultMap, IterUtils, LazyDerivedAsync, LegacyLogger, ObjectUtils, Yield, preferPreviousOverInProgressWrapper, replaceCurrencyUnitWithSymbol } from "engine-utils-ts";
import { TransformerIdent, TransformerKeyProps } from "src/archetypes/transformer/Transformer";
import type { CostComponents, CostComponentsNonNullable, CostsConfigProvider, EstimateCost, InstanceCost, SingleEstimateProvider} from "src/cost-model/capital";
import { CostHierarchy, createEmptyCostComponents, createEmptyCostComponentsNonNullable, createMiscCategory, fillModelBasedCostCategory, fillModelBasedTopLevelCategory } from "src/cost-model/capital";

export function create_PowerConversionSystem_Transformers(
    bim: Bim,
    provider: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>
) {
    const instances = bim.instances.getLazyListOf({ type_identifier: TransformerIdent, relevantUpdateFlags: SceneObjDiff.NewProps | SceneObjDiff.LegacyProps })
    const groupedInstances = createGroupedInstances(instances, bim.keyPropertiesGroupFormatter);


    const result = LazyDerivedAsync.new4<
        CostHierarchy,
        GroupedInvertes,
        InstanceCost[],
        EstimateCost[],
        NumberProperty
    >(
        'transformers',
        [bim.unitsMapper],
        [groupedInstances, provider.allInstanceCosts, provider.allEstimateCosts, totalDC],
        function* ([groupedCables, costs, estimates, totalDC]) {
            const costUnit = bim.unitsMapper.mapToConfigured({ value: 0, unit: 'usd' }).unit!;

            // extract final result
            const hierarchy = new CostHierarchy()

            for (const { manufacturer, uniqueGroups } of groupedCables.manufacturerGroups) {
                const sub = new CostHierarchy();

                for (const { name, props, ids } of uniqueGroups) {
                    let overrides = costs
                        .find(x => ObjectUtils.areObjectsEqual(x.props, props))?.costs
                        ?? createEmptyCostComponents();
                    const defaults = getTransformerDefaultCostsPerEach(props);

                    const nameCategory = sub.add({
                        description: { value: name },
                        costUnit: {
                            options: [replaceCurrencyUnitWithSymbol(costUnit) + '/each' ],
                            index: 0,
                        },

                        quantity: {
                            value: NumberProperty.new({ value: ids.size }),
                            integer: true,
                        },
                        relatedSceneInstanceIds: ids,
                        matchesSceneInstance: (query) => {
                            if (query.si?.type_identifier !== TransformerIdent) {
                                return false;
                            }
                            const sampleProps = query.si.properties.extractPropertiesGroup(TransformerKeyProps, { valueUnitOnly: true })
                            const result = ObjectUtils.areObjectsEqual(props, sampleProps);
                            return result;
                        }
                    })

                    nameCategory[1].matchesSceneInstance = (query) => {
                        if (query.si?.type_identifier !== TransformerIdent) {
                            return false;
                        }
                        const uniqueProps = query.si.properties.extractPropertiesGroup(TransformerKeyProps, { valueUnitOnly: true })
                        const result = ObjectUtils.areObjectsEqual(uniqueProps, props);
                        return result;
                    }


                    const updateCosts = (newCosts: CostComponents) => {
                        provider.findAndUpdateInstanceCost(
                            (prev) => (prev.costs = newCosts, prev),
                            { instance_type: TransformerIdent, props }
                        )
                    }


                    fillModelBasedCostCategory(nameCategory[1], overrides, defaults, updateCosts, ids.size);
                }

                // create root category
                {
                    const category = sub.addRoot({ description: { value: manufacturer } })
                    sub.sumupChildren(category[0]);
                }

                hierarchy.merge(sub);
            }

            // create misc cable misc
            {
                const costId = 'transformers-misc';
                const overrides = estimates.find(x => x.id.value === costId);
                const state: SingleEstimateProvider = {
                    id: costId,
                    defaults: createEmptyCostComponentsNonNullable(),
                    value: overrides,
                    update: (fn) => provider.findAndUpdateEstimateCost(costId, fn),
                }
                hierarchy.add(createMiscCategory(state, bim.unitsMapper, totalDC))
            }

            // create model root category
            {
                const root = hierarchy.addRoot({ description: { value: 'Transformers' } });
                const costId = 'pcs-transformers-benchmark';
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.materialCost = NumberProperty.new({ value: 0.010 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    hierarchy,
                    costId,
                    estimates,
                    provider.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                )
            }


            return hierarchy
        }
    )
    return result;
}


function createGroupedInstances(
    instances: LazyVersioned<Array<[id: IdBimScene, si: SceneInstance]>>,
    keyPropsGroupFormatter: PropertiesGroupFormatters,
) {
    const result = preferPreviousOverInProgressWrapper(LazyDerivedAsync.new1(
        'transformer-group',
        [],
        [instances],
        function* ([instances]) {
            type Manufacturer = string
            type Name = string
            const groups = new DefaultMap<
                Manufacturer,
                DefaultMap<Name, UniqueTransformerGroup>
            >(
                () => new DefaultMap(
                    (name) => ({
                        name: name,
                        props: TransformerKeyProps,
                        ids: new Set(),
                    })
                )
            )

            for (const chunk of IterUtils.splitIterIntoChunks(instances, 10e3)) {
                yield Yield.Asap;
                const grouped = IterUtils.groupObjects(
                    chunk,
                    (o) => {
                        const props = o[1].properties.extractPropertiesGroup(TransformerKeyProps);
                        return props.model.asText() + props.power.as('W');
                    },
                    (l, r) => ObjectUtils.areObjectsEqual(
                        l[1].properties.extractPropertiesGroup(TransformerKeyProps, { valueUnitOnly: true }),
                        r[1].properties.extractPropertiesGroup(TransformerKeyProps, { valueUnitOnly: true })
                    ),
                )
                for (const members of grouped) {
                    const sample = members[0][1]
                    const props = sample.properties.extractPropertiesGroup(TransformerKeyProps, { valueUnitOnly: true });
                    const name = keyPropsGroupFormatter.format(TransformerIdent, members[0][1].properties, sample.props)
                    if (!name) {
                        LegacyLogger.warn('Invalid wire group ' + name + '. Skipping');
                        continue;
                    }
                    const manufacturerGroup = groups.getOrCreate(props.model.asText());
                    const group = manufacturerGroup.getOrCreate(name);
                    group.props = props;
                    members.forEach(x => group.ids.add(x[0]));
                }
            }

            const result: GroupedInvertes = {
                manufacturerGroups: []
            }
            for (const [manufacturer, manufGroup] of groups) {
                const group: TransformerManufacturerGroup = {
                    manufacturer,
                    uniqueGroups: Array.from(manufGroup.values())
                }
                result.manufacturerGroups.push(group)
            }
            return result;
        }
    ));
    return result;
}


interface TransformerManufacturerGroup {
    manufacturer: string
    uniqueGroups: UniqueTransformerGroup[]
}

interface UniqueTransformerGroup {
    name: string
    props: typeof TransformerKeyProps
    ids: Set<IdBimScene>
}

interface GroupedInvertes {
    manufacturerGroups: TransformerManufacturerGroup[],
}

export function getTransformerDefaultCostsPerEach(props: typeof TransformerKeyProps): CostComponentsNonNullable {
    const result: CostComponentsNonNullable = {
        ...createEmptyCostComponentsNonNullable(),
        materialCost: NumberProperty.new({ value: props.power.as('W') * 0.015, unit: 'usd' }),
    };

    return result;
}

