<script lang="ts">
    import { Bim, EditTrackerPilesContext, NumberProperty } from "bim-ts";
    import { getContext, onDestroy } from "svelte";
    import {
        PilePositionStateManger as PilePositionsStateManager,
        WindLoadVariantSettingsContext,
    } from "./PilePositions";
    import { VersionedStore } from "../VersionedStore";
    import SelectorProperty from "../pui/SelectorProperty.svelte";
    import {
        ContextMenuConfig,
        PUI_ActionsNode,
        PUI_CustomPropertyNode,
        PUI_PropertyNodeNumber,
        PUI_PropertyNodeSelector,
        PUI_PropertyNodeString,
        UiBindings,
        UiCustomNode,
    } from "ui-bindings";
    import ModulesGapProperty from "./ModulesGapProperty.svelte";
    import SwitchButton from "./SwitchButton.svelte";
    import NumberPropertyBase from "../pui/custom-components/NumberPropertyWithOption/NumberPropertyBase.svelte";
    import NumberPropertyComponent from "../pui/NumberProperty.svelte";
    import ActionsProperty from "../pui/ActionsProperty.svelte";
    import StringProperty from "../pui/StringProperty.svelte";
    import WindLoadVariantsSelector from "./WindLoadVariantsSelector.svelte";
    import Scheme from "./tracker-scheme/TrackerScheme.svelte";
    import Divider from "../pui/custom-components/Divider.svelte";
    import CustomGroupName from "../pui/custom-components/CustomGroupName.svelte";
    import Icon from "../libui/icon/Icon.svelte";
    import { LazyBasic } from "engine-utils-ts";

    export let context: EditTrackerPilesContext;
    const bim = getContext<Bim>("bim");
    const ui = getContext<UiBindings>("uiBindings");

    const stateManager = new PilePositionsStateManager(bim, context);
    $: state = new VersionedStore(stateManager.state);

    function showHalfOffset(prop: NumberProperty){
        const valueUnit = NumberProperty.new({...prop, value: prop.value / 2})
            .valueUnitUiString(bim.unitsMapper, 2);
        return `(2 × ${valueUnit})`;
    }

    const trackersCountTag = new LazyBasic("trackers-count", "");
    $: {
        trackersCountTag.replaceWith(`${$state.selectedTrackers.length} trackers`);
    }

    let windLoadConfig: ContextMenuConfig | undefined = undefined;

    onDestroy(() => {
        if (windLoadConfig) {
            windLoadConfig.close();
            windLoadConfig = undefined;
    }
    });
    const decimals = 4;
</script>

<div class="root">
    {#if !context.objectDetailsMode}
        <SelectorProperty
            property={new PUI_PropertyNodeSelector({
                name: "Tracker model",
                value: $state.trackerName,
                defaultValue: "None",
                options: $state.trackersGroupNames,
                tag: trackersCountTag,
                onChange: (value) => {
                    stateManager.selectTracker(value);
                },
            })}
        />
        <SelectorProperty
            property={new PUI_PropertyNodeSelector({
                name: " ",
                value: $state.modulesCount,
                defaultValue: "None",
                options: $state.modulesCountGroupsNames,
                onChange: (value) => {
                    stateManager.selectModule(value);
                },
            })}
        />
        <Divider />
    {/if}
    <span class="group-name"> 
        <span class="mdc-typography--overline text-main-dark">Tracker frame</span>
        <span class="mdc-typography--body1 text-main-dark">{`${$state.props?.modulesCount ?? 0} modules`}</span>
    </span>
    {#if $state.props}
        {@const props = $state.props}
        <ModulesGapProperty
            sourceProperty={new PUI_PropertyNodeNumber({
                name: "Piles",
                value: props.motorPilesCount.value,
                unit: props.motorPilesCount.unit,
                minMax: props.motorPilesCount.range,
                step: props.motorPilesCount.step,
                onChange: (value) => {
                    stateManager.applyPatch({
                        motorPilesCount:
                            props.motorPilesCount.withDifferentValue(value),
                    });
                },
            })}
            decimals={0}
        >
            <div slot="body">
                <div class="input-value text-main-dark edit-field-container">
                    <NumberPropertyBase
                        sourceProperty={new PUI_PropertyNodeNumber({
                            name: "other_piles",
                            value: props.otherPilesCount.value,
                            unit: props.otherPilesCount.unit,
                            minMax: props.otherPilesCount.range,
                            step: props.otherPilesCount.step,
                            onChange: (value) => {
                                stateManager.applyPatch({
                                    otherPilesCount:
                                        props.otherPilesCount.withDifferentValue(
                                            value,
                                        ),
                                });
                            },
                        })}
                        unitsMapper={bim.unitsMapper}
                        decimals={0}
                    />
                    <span class="mdc-typography--body1 text-main-light tip"
                        >Other</span
                    >
                </div>
            </div>
            <span class="mdc-typography--body1 text-main-light tip" slot="tip">
                Motor
            </span>
        </ModulesGapProperty>
        <ModulesGapProperty
            sourceProperty={new PUI_PropertyNodeNumber({
                name: "Modules gap",
                value: props.modulesGapBase.value,
                unit: props.modulesGapBase.unit,
                minMax: props.modulesGapBase.range,
                step: props.modulesGapBase.step,
                onChange: (value) => {
                    stateManager.applyPatch({
                        modulesGapBase:
                            props.modulesGapBase.withDifferentValue(value),
                    });
                },
            })}
        >
            <div slot="body">Base</div>
        </ModulesGapProperty>
        <ModulesGapProperty
            sourceProperty={new PUI_PropertyNodeNumber({
                name: " ",
                value: props.modulesGapOnMotorPiles.value,
                unit: props.modulesGapOnMotorPiles.unit,
                minMax: props.modulesGapOnMotorPiles.range,
                step: props.modulesGapOnMotorPiles.step,
                onChange: (value) => {
                    stateManager.applyPatch({
                        modulesGapOnMotorPiles:
                            props.modulesGapOnMotorPiles.withDifferentValue(
                                value,
                            ),
                    });
                },
            })}
            showValue={props.modulesGapOnMotorPilesActive}
        >
            <div class="group-name" slot="body">
                <SwitchButton
                    name={"On motor piles"}
                    value={props.modulesGapOnMotorPilesActive}
                    onChange={(v) => {
                        stateManager.applyPatch({
                            modulesGapOnMotorPilesActive: v,
                            modulesGapOnOtherPilesActive: !v ? v : undefined,
                        });
                    }}
                />
            </div>
            <span class="mdc-typography--body1 text-main-light tip" slot="tip">
                {showHalfOffset(props.modulesGapOnMotorPiles)}
            </span>
        </ModulesGapProperty>
        <ModulesGapProperty
            sourceProperty={new PUI_PropertyNodeNumber({
                name: " ",
                value: props.modulesGapOnOtherPiles.value,
                unit: props.modulesGapOnOtherPiles.unit,
                minMax: props.modulesGapOnOtherPiles.range,
                step: props.modulesGapOnOtherPiles.step,
                onChange: (value) => {
                    stateManager.applyPatch({
                        modulesGapOnOtherPiles:
                            props.modulesGapOnOtherPiles.withDifferentValue(
                                value,
                            ),
                    });
                },
            })}
            showValue={props.modulesGapOnOtherPilesActive}
        >
            <div class="sub-switch-holder" slot="body">
                <span class="icon-holder">
                    <Icon name="HierarchyDown" />
                </span>
                <SwitchButton
                    name={"On other"}
                    value={props.modulesGapOnOtherPilesActive}
                    onChange={(v) => {
                        stateManager.applyPatch({
                            modulesGapOnOtherPilesActive: v,
                        });
                    }}
                    disabled={!props.modulesGapOnMotorPilesActive}
                />
            </div>
            <span class="mdc-typography--body1 text-main-light tip" slot="tip">
                {showHalfOffset(props.modulesGapOnOtherPiles)}
            </span>
        </ModulesGapProperty>
        <NumberPropertyComponent
            sourceProperty={new PUI_PropertyNodeNumber({
                name: "Modules row length",
                value: props.modulesRowLength.value,
                unit: props.modulesRowLength.unit,
                minMax: props.modulesRowLength.range,
                step: props.modulesRowLength.step,
                calculated: true,
                readonly: true,
                onChange: () => {
                    // readonly
                    console.error("Should not be called");
                },
            })}
        />
        <ModulesGapProperty
            sourceProperty={new PUI_PropertyNodeNumber({
                name: "Modules row offsets",
                value: props.overhangSouth.value,
                unit: props.overhangSouth.unit,
                minMax: props.overhangSouth.range,
                step: props.overhangSouth.step,
                onChange: (value) => {
                    stateManager.applyPatch({
                        overhangSouth:
                            props.overhangSouth.withDifferentValue(value),
                    });
                },
            })}
            decimals={decimals}
        >
            <div slot="body">
                <div class="input-value text-main-dark edit-field-container">
                    <NumberPropertyBase
                        sourceProperty={new PUI_PropertyNodeNumber({
                            name: "Modules row offsets",
                            value: props.overhangNorth.value,
                            unit: props.overhangNorth.unit,
                            minMax: props.overhangNorth.range,
                            step: props.overhangNorth.step,
                            onChange: (value) => {
                                stateManager.applyPatch({
                                    overhangNorth:
                                        props.overhangNorth.withDifferentValue(
                                            value,
                                        ),
                                });
                            },
                        })}
                        unitsMapper={bim.unitsMapper}
                        decimals={decimals}
                    />
                    <span class="mdc-typography--body1 text-main-light tip"
                        >North</span
                    >
                </div>
            </div>
            <span class="mdc-typography--body1 text-main-light tip" slot="tip">
                South
            </span>
        </ModulesGapProperty>
        <NumberPropertyComponent
            sourceProperty={new PUI_PropertyNodeNumber({
                name: "Total Length",
                value: props.trackerLength.value,
                unit: props.trackerLength.unit,
                minMax: props.trackerLength.range,
                step: props.trackerLength.step,
                calculated: true,
                readonly: true,
                onChange: () => {
                    // readonly
                    console.error("Should not be called");
                },
            })}
        />
        <Divider />
        <span/>
        <span>
            <span class="mdc-typography--overline text-main-dark">Piles</span>
        </span>
        {#if props.isAdvanceMode}
            <WindLoadVariantsSelector
                name={"Wind load variants"}
                value={$state.selectedWindLoadPosition}
                variants={props.activeWindLoadVariants}
                onChange={(value) => {
                    stateManager.selectWindLoadPosition(value);
                }}
                configure={(p) => {
                    windLoadConfig = new ContextMenuConfig({
                        identity: "wind-load-variant-settings",
                        header: `${$state.trackerName} ${$state.modulesCount} wind load variants`,
                        viewSource: new UiCustomNode({
                            context: new WindLoadVariantSettingsContext(
                                stateManager,
                            ),
                        }),
                        positionPx: p,
                        widthPx: 430,
                    });
                    ui.addContextMenuView(windLoadConfig);
                }}
            />
        {/if}
        <StringProperty
            property={new PUI_PropertyNodeString({
                name: "Piles configuration",
                value: props.modulesConfiguration.value,
                onChange: (value) => {
                    stateManager.applyPatch({
                        modulesConfiguration: props.modulesConfiguration.withDifferentValue(
                            value,
                        ),
                    });
                },
                readonly: props.isAdvanceMode,
                calculated: props.isAdvanceMode,
            })}
        />
        <CustomGroupName 
            property = {new PUI_CustomPropertyNode({
                name: " ",
                value: props.pilesConfiguration.value,
                onChange: () => {
                    console.error("Should not be called");
                },
                type_ident: "CustomGroupName",
                context: {
                    isProperty: true,
                    multiline: true,
                }
            })}
        />
        {#if !props.isAdvanceMode}
            <ActionsProperty
                property={new PUI_ActionsNode({
                    name: "convert to advanced piles setup",
                    actions: [
                        {
                            label: "Convert to advanced piles setup",
                            style: {
                                type: "text",
                                icon: "AddPlus",
                            },
                            action: () => {
                                stateManager.applyPatch({
                                    isAdvanceMode: true,
                                });
                            },
                        },
                    ],
                    context: null,
                })}
            />
        {:else}
            <Scheme
                stateManager = {stateManager}
                props = {props}
                trackerBins = {$state.trackerBins ?? undefined}
            />
        {/if}
    {/if}
</div>

<style lang="scss">
    .root {
        padding-top: 15px;
        padding-left: 14px;
        width: calc(100% - 30px);
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .tip {
        cursor: default;
    }
    .group-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .sub-switch-holder {
        display: flex;
        flex-direction: row;
        gap: 4px;
    }
</style>
