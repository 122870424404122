import { Vector2 } from "math-ts";
import { Anchor2D, QuadraticBezier, VectorPrimitiveCircle, VectorPrimitivePath, VectorPrimitivePathDescription } from "vector-graphic";

export function createJumper2D(params: { strokeWidth: number }) {
    const jumper = new Anchor2D();
    jumper.point.set(params.strokeWidth * 10, 0)
    const circle1 = new VectorPrimitiveCircle({
        cx: 0,
        cy: 0,
        radius: params.strokeWidth * 1.5,
        strokeWidth: params.strokeWidth,
        stroke: 'black',
        fill: 'white'
    })
    const circle2 = new VectorPrimitiveCircle({
        cx: jumper.point.x,
        cy: jumper.point.y,
        radius: params.strokeWidth * 1.5,
        strokeWidth: params.strokeWidth,
        stroke: 'black',
        fill: 'white'
    })
    circle1.zIndex = 2
    circle2.zIndex = 2
    jumper.addPrimitiveAndExpandAabb(circle1);
    jumper.addPrimitiveAndExpandAabb(circle2);

    // create path
    const bezier = new QuadraticBezier(
        new Vector2(0, 0),
        new Vector2(jumper.point.x / 2, -jumper.point.x / 2),
        jumper.point.clone(),
    )

    const curve = new VectorPrimitivePath({
        paths: [new VectorPrimitivePathDescription([bezier])],
        strokeWidth: params.strokeWidth,
    })
    jumper.addPrimitiveAndExpandAabb(curve);

    return jumper;
}
