import type { TypedArray } from '../3rdParty/three';
import type { RawDataGateway } from '../memory/RawDataGateway';

export class EnumsGateway<EnumT extends number, BufferT extends TypedArray> implements RawDataGateway<EnumT, BufferT> {

	readonly name: string;

	buffer: BufferT = null!;
	readonly itemLength: number = 1;

	constructor(name: string) {
		this.name = name;
	}

	get(index:number): EnumT {
		return this.buffer[index] as EnumT;
	}

	set(index:number, value: EnumT): void {
		this.buffer[index] = value;
	}

	setInternalBuffer(buffer: BufferT) {
		this.buffer = buffer;
	}

	toBuffer(obj: EnumT, index: number) {
		this.buffer[index] = obj;
	}

	toStruct(index: number, _struct: EnumT): EnumT {
		return this.buffer[index] as EnumT;
	}

	getStruct(index: number): any {
		return this.buffer[index];
	}
	

}