import type { Vector2 } from "./Vector2";
import { Vec3Z, Vector3 } from "./Vector3";

export class Segment2 {
	readonly a: Vector2;
	readonly b: Vector2;

	constructor( a: Vector2, b: Vector2 ) {
		this.a = a.clone();
		this.b = b.clone();
	}

    set(a: Vector2, b: Vector2) {
        this.a.copy(a);
        this.b.copy(b);
        return this;
    }

    clone() {
        return new Segment2(this.a.clone(), this.b.clone());
    }

    equals(other: Segment2) {
		return this.a.distanceToSquared(other.a) < 1e-12 && this.b.distanceToSquared(other.b) < 1e-12;
	}

    getCenter() {
        return this.a.clone().add(this.b).multiplyScalar(0.5);
    }

    /**
     * 
     * @returns 1d coordinate (between 0 and 1) of intersection point
     */
    intersectSegment(s: Segment2): number | null {
        const den = (this.a.x - this.b.x) * (s.a.y - s.b.y) - (this.a.y - this.b.y) * (s.a.x - s.b.x);
        if (den === 0) {
            return null;
        }

        const t = ((this.a.x - s.a.x) * (s.a.y - s.b.y) - (this.a.y - s.a.y) * (s.a.x - s.b.x)) / den;
        if (t < 0 || t > 1) {
            return null;
        }
        const u = ((this.a.x - s.a.x) * (this.a.y - this.b.y) - (this.a.y - s.a.y) * (this.a.x - this.b.x)) / den;
        if (u < 0 || u > 1) {
            return null;
        }

        return t;
    }

    segmentPolygonPoints(width: number): Vector2[] {
		const center = this.a.clone().add(this.b).multiplyScalar(0.5);

		const points: Vector2[] = [];
		for (const segmentEndPoint of [this.a, this.b]) {
			const dirToCenter = Vector3.fromVec2(segmentEndPoint.clone().sub(center));

			const dirRight = Vector3.crossVectors(dirToCenter, Vec3Z).xy().normalize();
			const dirleft = dirRight.clone().multiplyScalar(-1);

			points.push(dirRight.multiplyScalar(width * 0.5).add(segmentEndPoint));
			points.push(dirleft.multiplyScalar(width * 0.5).add(segmentEndPoint));
		}

		return points;
	}

    getVector(): Vector2 {
        return this.b.clone().sub(this.a);
    }
}