import type { CostReport } from "../../CostReport";
import { ObjectUtils } from "engine-utils-ts";
import { TableEntry } from "../../TableHierarchy";
import type { Bim } from "bim-ts";
import { fixRateByRateUnit } from "../Action";

export const CustomAmountTypeIdentifier = 'custom_amount'

export class CustomAmount {
    constructor(
        public entry = new TableEntry(),
    ) {}
}

export function registerCustomAmountAction(costReport: CostReport, bim: Bim) {
    costReport.actionSolvers.registerActionSolver<CustomAmount>(
        CustomAmountTypeIdentifier,
        (action, hierarchy, actionIdx) => {
            const exist = hierarchy.getEntry(action.props.entry.path);
            if (exist) {
              console.warn('entry already exist. skipping custom amount');
              return
            }
            const entry = ObjectUtils.deepCloneObj(action.props.entry);
            entry.order = actionIdx;
            hierarchy.addEntry(entry);
            fixRateByRateUnit(entry, bim.unitsMapper);
            hierarchy.leafRelactulation(entry.path);
        }
    )
}

