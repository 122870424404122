import { FetchUtils } from 'engine-utils-ts';

import type { AssetRef } from './';

export class BimUrls {


    static assetReferenceUrl(assetRef: AssetRef): string {
        let url = FetchUtils.combineURLs('asset', assetRef.path);
        if (assetRef.inAssetId) {
            url = FetchUtils.combineURLs(url, assetRef.inAssetId);
        }
        return url;
    }

    
}

