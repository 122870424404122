import type { LongTask, ScopedLogger, LazyVersioned, Yield } from 'engine-utils-ts';
import type { ProjectNetworkClient } from 'engine-utils-ts';
import type { KreoEngine } from 'engine-ts';
import {
    NotificationDescription,
    NotificationType,
    type PUI_GroupNode,
    type FileImporter,
    type FileImporterContext,
    type FileToImport,
    type UiBindings,
    GroupedNotificationGenerator
} from 'ui-bindings';
import { BindedStore } from '../utils';
import { requestSettingsViaDialog } from './RequestSettingsViaDialog';
import { notificationSource } from '../Notifications';
import type { NavbarContext } from '../navbar/Navbar';
import type { IdBimScene } from 'bim-ts';

export interface FilePromised {
    filename: string;
    filePromise: Promise<ArrayBuffer>;
    size: number;
}

export enum ImportStatus {
    NotStarted,
    Finished
}

const TimeoutIntervalMs = 1000 * 60 * 10;

interface ImportMessage {
    text: string;
    isError?: boolean;
}
class FilesImportStore {
    file: FileToImport | null = null;
    status: ImportStatus = ImportStatus.NotStarted;
    messages: ImportMessage[] = [];
    form: LazyVersioned<PUI_GroupNode> | null = null;
}

export class FilesImportsHandler extends BindedStore<FilesImportStore> {

    _dispose: Map<HTMLElement, (() => void)[]> = new Map();
    _importTask: LongTask<void> | null = null;
    _notificationGroup: GroupedNotificationGenerator | null = null;

    constructor(
        readonly logger: ScopedLogger,
        readonly networkClient: ProjectNetworkClient,
        readonly engine: KreoEngine,
        readonly uiBindings: UiBindings,
        readonly navbar: NavbarContext
    ) {
        super(new FilesImportStore);

        this.importFile = this.importFile.bind(this);
        this.onFinish = this.onFinish.bind(this);
    }

    dragOver(e: DragEvent) {
        if (
          (e.dataTransfer?.items && e.dataTransfer.items[0] && e.dataTransfer.items[0].kind === 'file') ||
          (e.dataTransfer?.files && e.dataTransfer.files[0])
        ) {
            e.stopPropagation();
            e.preventDefault();
            e.dataTransfer!.dropEffect = 'copy';
        }
    };

    async drop(e: DragEvent) {
        e.stopPropagation();
        e.preventDefault();
        

        if (e.dataTransfer!.files.length > 0) {
            // similar to upload via input - select only one file
            const file = e.dataTransfer!.files[0];
            const filePromised: FilePromised = {
                filename: file.name,
                filePromise: file.arrayBuffer(),
                size: file.size,
            }

            this.navbar.openTab('Add.Import');

            await this.importFile(filePromised);
        }
    };

    addDragDropListner(domElement: HTMLElement) {
        const dragOver = this.dragOver.bind(this);
        const drop = this.drop.bind(this);
        domElement.addEventListener('dragover', dragOver);

        domElement.addEventListener('drop', drop);
        this._dispose.set(domElement, [
            () => domElement.removeEventListener('dragover', dragOver),
            () => domElement.removeEventListener('drop', drop)
        ]);
    }

    uploadFile(file: FileToImport) {
        this.update(s => (s.file = file, s));
    }

    finishImport() {
        this.update(s => (
            s.status = ImportStatus.Finished,
            s.messages = [{
                text: "File successfully imported",
            }],
            s.form = null,
            s
        ));
        this.navbar.closeTab('Add.Import');
    }

    clear() {
        this.update(s => (
            s.file = null,
            s.status = ImportStatus.NotStarted,
            s.messages = [],
            s.form = null,
            s
        ));
    }

	dispose(domElement: HTMLElement) {
        const listners = this._dispose.get(domElement) ?? [];
		for (const f of listners) {
			f();
		}
        this._dispose.delete(domElement);
    }

    disposeAll() {
		for (const elem of this._dispose.keys()) {
            this.dispose(elem);
		}
        this._dispose.clear();
    }

    async importFile(filePromised: FilePromised){

        const ab = await filePromised.filePromise;
        const file: FileToImport = {
            filename: filePromised.filename,
            fileArrayBuffer: ab,
            extension: '',
            size: filePromised.size
        };

        const allImporters = Array.from(this.uiBindings.filesImporters.values()).map(t => t[1]);
        const logger = this.logger.newScope(`engine-file-drop-import`);

        const importer = allImporters.find(
            imp => {
                const extensionMatch = imp.fileExtensions.find(ext => 
                    file.filename.toLocaleLowerCase().endsWith(ext.toLocaleLowerCase())
                );
                if (!extensionMatch) {
                    return false;
                }
                file.extension = extensionMatch;
                if (imp.additionalFileCheck) {
                    return imp.additionalFileCheck(file);
                }
                return true;
            }
        );

        if (!importer) {
            this.uiBindings.addNotification(NotificationDescription.newBasic({
                source: notificationSource,
                key: 'unsupportedFormat',
                descriptionArg: file.filename,
                type: NotificationType.Error,
                addToNotificationsLog: true
            }));
            logger.error('could not import', file.filename);
            return;
        }
        
        this.importFileWith(importer, file);
    }

    importFileWith(importer: FileImporter, file: FileToImport) {
        this.uploadFile(file);

        const logger = this.logger.newScope(`importing ${file.filename}`);
        const setMessage = (text: string, isError: boolean) => {
            this.update(s => (s.messages.push({ text, isError }), s));
        };
        this._notificationGroup = new GroupedNotificationGenerator('File import');
        const context: FileImporterContext = {
            logger,
            network: this.networkClient,
            setMessage,
            getPositionInFrontOfEngineCamera: () => {
                return this.engine.getPositionInFrontOfCameraForImport();
            },
            requestSettings: (args, getDialog) => {
                return requestSettingsViaDialog({
                    header: 'Import ' + file.filename,
                    ...args,
                    uiBindings: this.uiBindings,
                    cancel: () => {
                        if (this._importTask) {
                            this._importTask.reject('cancel');
                        } else {
                            logger.error('no import task to cancel');
                        }
                    }
                }, getDialog);
            },
            sendNotification: (notification: NotificationDescription): void => {
                this.uiBindings.addNotification(notification);
            },
            importInstances: (importGenerator: Generator<Yield, void>) => {
                const task = this.engine.tasksRunner.newLongTask({
                    defaultGenerator: importGenerator,
                    taskTimeoutMs: TimeoutIntervalMs,
                });
                this.uiBindings.addNotification(
                    this._notificationGroup!.addNotification(
                        NotificationDescription.newWithTask({
                            source: notificationSource,
                            key: 'importInstances',
                            type: NotificationType.Info,
                            taskDescription: { task },
                            addToNotificationsLog: true
                        })
                    )
                );
            },
            setForm: (form: LazyVersioned<PUI_GroupNode>) => {
                this.update(s => (s.form = form, s));
            },
            onFinish: this.onFinish,
        };

        this.startImport(importer, context, file);
    }

    startImport(importer: FileImporter, context: FileImporterContext, file: FileToImport) {
        const importGenerator = importer.startImport(context, file);
        this._importTask = this.engine.tasksRunner.newLongTask({
            defaultGenerator: importGenerator,
            taskTimeoutMs: TimeoutIntervalMs,
        });
        this.uiBindings.addNotification(
            this._notificationGroup!.addRootNotification(
                NotificationDescription.newWithTask({
                    source: notificationSource,
                    key: 'importFile',
                    descriptionArg: file.filename,
                    type: NotificationType.Info,
                    taskDescription: { task: this._importTask },
                    removeAfterMs: 3000,
                    addToNotificationsLog: true
                })
            )
        );
        this._importTask
            .asPromise()
            .catch(() => {
                const errors = this._storeValue.messages.filter(message => message.isError);
                this.update(s => (
                    s.status = ImportStatus.Finished,
                    s.messages = errors.length ? errors : [{ text: "Import failed", isError: true }],
                    s.form = null,
                    s
                ))
            });
    }
    *onFinish(ids: IdBimScene[]) {
        this.finishImport();
        yield* this.engine.waitForEngineSceneToBeReady();
        this.engine.focusCamera(ids);
    }
}
