import { NumberProperty, BooleanProperty } from "../../properties/PrimitiveProps";
import type { PropertyGroup } from "../../properties/PropertyGroup";
import { ObjectsSet, ObjectUtils } from "engine-utils-ts";
import type { UnitsMapper } from "../../UnitsMapper";
import type { Catalog } from "../Catalog";
import { CatalogItem } from "../CatalogItem";
import type { CatalogItemsUiLabels } from "../CatalogItemsUiLabels";
import { CatalogSource } from "../Source";

export const TrenchCatalogItemTypeIdentifier = 'trench';
export const trenchCatalogItemVolumeUnit = NumberProperty.new({
    value: 1,
    unit: 'yd3',
})


export interface TrenchCatalogItemProps extends PropertyGroup {
    width: NumberProperty;
    is_dc: BooleanProperty;
    price_per_CY: NumberProperty;
}


export function registerTrenchCatalogItemUiLabels(
    labelsProvider: CatalogItemsUiLabels,
    unitsMapper: UnitsMapper
) {
    labelsProvider.register<TrenchCatalogItemProps>(
        TrenchCatalogItemTypeIdentifier,
        (trench) => {
            const price = trench.price_per_CY.valueUnitUiString(unitsMapper) +
                '/'+ (trenchCatalogItemVolumeUnit.valueUnitUiString(unitsMapper));
            const title =
                `${trench.width.valueUnitUiString(unitsMapper)} w trench`;
            const group = [[`Trench ${trench.is_dc.value ? 'LV' : 'MV'}`]];
            return {
                title,
                group,
                price
            };
        }
    );
}

export function importDefaultTrenches(catalog: Catalog) {
    const trenchs = catalog.catalogItems
        .filter(x => x.typeIdentifier === TrenchCatalogItemTypeIdentifier) ;
    const trenchPresent = new ObjectsSet<{ width: number, isDc: boolean }>(
        (obj) => obj.width * 10 + (obj.isDc ? 1 : 0)
    )
    for (const [_id, item] of trenchs) {
        const trench = item.as<TrenchCatalogItemProps>();
        trenchPresent.add(ObjectUtils.deepFreeze({
            width: trench.properties.width.as('in'),
            isDc: trench.properties.is_dc.value,
        }));
    }
    // trenches
    const configs: Array<[isDc: boolean, fromWidth: number, toWidth: number]> = [
        [true, 24, 300],
        [false, 24, 48],
    ]
    const trenchWidthStep = 12
    for (const [isDc, fromWidth, toWidth] of configs) {
        for (let width = fromWidth; width <= toWidth; width += trenchWidthStep) {
            const key = ObjectUtils.deepFreeze({ isDc, width });
            if (trenchPresent.has(key)) {
                // skip
                continue;
            }
            // create
            const trench = new CatalogItem<TrenchCatalogItemProps>(
                undefined,
                'trench',
                {
                    is_dc: BooleanProperty.new({
                        value: isDc,
                        isReadonly: true,
                    }),
                    width: NumberProperty.new({
                        value: width,
                        unit: 'in',
                        range: [10, 30],
                        step: 1,
                        description: 'trench width',
                        isReadonly: true,
                    }),
                    price_per_CY: NumberProperty.new({
                        value: 0,
                        unit: 'usd',
                        range: [0, 100000],
                        step: 0.1,
                        description: 'price per unit of area',
                    })

                },
                new CatalogSource('default'),
            );
            catalog.catalogItems.allocate([[catalog.catalogItems.reserveNewId(), trench]])
        }
    }
}
