// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { CompressionType } from '../wire/compression-type.js';


export class BatchContainer {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):BatchContainer {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsBatchContainer(bb:flatbuffers.ByteBuffer, obj?:BatchContainer):BatchContainer {
  return (obj || new BatchContainer()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsBatchContainer(bb:flatbuffers.ByteBuffer, obj?:BatchContainer):BatchContainer {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new BatchContainer()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

formatVersion():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

guid():string|null
guid(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
guid(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

collectionIdent():string|null
collectionIdent(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
collectionIdent(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

payloadCompression():CompressionType {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : CompressionType.None;
}

objectsPayload(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index) : 0;
}

objectsPayloadLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

objectsPayloadArray():Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? new Uint8Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

static startBatchContainer(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addFormatVersion(builder:flatbuffers.Builder, formatVersion:number) {
  builder.addFieldInt32(0, formatVersion, 0);
}

static addGuid(builder:flatbuffers.Builder, guidOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, guidOffset, 0);
}

static addCollectionIdent(builder:flatbuffers.Builder, collectionIdentOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, collectionIdentOffset, 0);
}

static addPayloadCompression(builder:flatbuffers.Builder, payloadCompression:CompressionType) {
  builder.addFieldInt32(3, payloadCompression, CompressionType.None);
}

static addObjectsPayload(builder:flatbuffers.Builder, objectsPayloadOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, objectsPayloadOffset, 0);
}

static createObjectsPayloadVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset {
  builder.startVector(1, data.length, 1);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt8(data[i]!);
  }
  return builder.endVector();
}

static startObjectsPayloadVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(1, numElems, 1);
}

static endBatchContainer(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishBatchContainerBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset);
}

static finishSizePrefixedBatchContainerBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, undefined, true);
}

static createBatchContainer(builder:flatbuffers.Builder, formatVersion:number, guidOffset:flatbuffers.Offset, collectionIdentOffset:flatbuffers.Offset, payloadCompression:CompressionType, objectsPayloadOffset:flatbuffers.Offset):flatbuffers.Offset {
  BatchContainer.startBatchContainer(builder);
  BatchContainer.addFormatVersion(builder, formatVersion);
  BatchContainer.addGuid(builder, guidOffset);
  BatchContainer.addCollectionIdent(builder, collectionIdentOffset);
  BatchContainer.addPayloadCompression(builder, payloadCompression);
  BatchContainer.addObjectsPayload(builder, objectsPayloadOffset);
  return BatchContainer.endBatchContainer(builder);
}
}
