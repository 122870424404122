import { IsInWebworker } from "../EnvChecks";
import type { Yield } from '../TasksRunner';

export enum ExecutionThreadPreference {
    None,
    MainThread,
    WorkerThread
}

export type AnyJobExecutor = JobExecutor<any, any>;

export abstract class JobExecutor<Args, T> {
    abstract execute(args: Args): T | Generator<Yield, T>;

    executionPreference(_args: Args): ExecutionThreadPreference {
        return ExecutionThreadPreference.WorkerThread;
    }

    argsCacheKey (_args: Args): JobsArgsCachingDescription<Args> | null {
        return null;
    }

    estimateTaskDurationMs(args: Args): number {
        return 100;
    }

    transferToWorker(args: Args): (ArrayBuffer)[] | null {
        return null;
    }
    
    // argsAndResultsMayShareObjects(): boolean {
    //     return false;
    // }
}

export interface JobsArgsCachingDescription<T> {
    byRef: (keyof T)[];
    byEq: (keyof T)[];
}



let __executors_registry: Map<string, AnyJobExecutor> = new Map();

if ((globalThis as any)['__executors_registry']) {
    if (!IsInWebworker()) {
        console.error('__executors_registry double initialization, bundling is wrong');
    }
    __executors_registry = (globalThis as any)['__executors_registry'];
} else {
    (globalThis as any)['__executors_registry'] = __executors_registry;
}

        
export function registerExecutor<Args extends Object, Result>(jobExecutorCtor: {new(): JobExecutor<Args, Result>}) {
    const identifier = jobExecutorCtor.name;
    if (__executors_registry.has(identifier)) {
        console.warn(`executor ${identifier} already registered`);
        return;
    }
    const executor = new jobExecutorCtor();
    __executors_registry.set(identifier, executor);
}

export function getStaticExecutorFromRegistry(identifer: string): AnyJobExecutor | undefined {
    return __executors_registry.get(identifer)
}
