<script lang="ts">
    import { EnergyStagesNames, type EnergyPipelineProperty } from "bim-ts";
    import EnergyReportChartPortion from "./EnergyReportChartPortion.svelte";

    export let property: EnergyPipelineProperty;

    $: stages = property.stages;

    const splitAfterStageIndex = EnergyStagesNames.indexOf("pv_conversion");

    $: stagesUpToPv = stages.filter(
        (stage) => EnergyStagesNames.indexOf(stage.name) <= splitAfterStageIndex
    );
    $: stagesAfterPv = stages.filter(
        (stage) => EnergyStagesNames.indexOf(stage.name) >= splitAfterStageIndex
    );
    let lastEnergyLength: number;
</script>

<div class="root">
    <EnergyReportChartPortion
        stages={stagesUpToPv}
        bind:lastEnergyLength
    />
    <EnergyReportChartPortion
        stages={stagesAfterPv}
        prevEnergyLength={lastEnergyLength}
        showResultBar={true}
    />
    <div class="border-solid" />
</div>

<style lang="scss">
    .root {
        padding: 12px 0;
    }
    .border-solid {
        margin: 8px 8px 0;
        border-top: 1px solid var(--color-divider);
    }
</style>
