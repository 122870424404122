import type { Observer } from "engine-utils-ts";
import { Allocated, Deleted, LogLevel, Updated, type ScopedLogger } from "engine-utils-ts";
import type { EntitiesCollectionUpdates } from "src/collections/EntitiesCollectionUpdates";
import type { ConfigPatch, ConfigsCollection, IdConfig } from "./ConfigsCollection";
import type { Config } from "./ConfigsCollection";
import type { BimCollectionPatch } from "src/collections/BimCollection";

export interface ConfigResolver {
    identifier: string;
    dependentTypes: string | string[];

    onAllocated?(collection: ReadonlyMap<IdConfig, Config>, id: IdConfig): BimCollectionPatch<Config, IdConfig, ConfigPatch> | undefined;
    onUpdated?(collection: ReadonlyMap<IdConfig, Config>, id: IdConfig): BimCollectionPatch<Config, IdConfig, ConfigPatch> | undefined;
    onRemoved?(collection: ReadonlyMap<IdConfig, Config>, id: IdConfig, type_ident: string): BimCollectionPatch<Config, IdConfig, ConfigPatch> | undefined;
}

class ConfigResolverWrapper implements ConfigResolver { 
    identifier: string;
    dependentTypes: string | string[];
    onAllocated?: (collection: ReadonlyMap<IdConfig, Config>, id: IdConfig) => BimCollectionPatch<Config, IdConfig, ConfigPatch> | undefined;
    onUpdated?: (collection: ReadonlyMap<IdConfig, Config>, id: IdConfig) => BimCollectionPatch<Config, IdConfig, ConfigPatch> | undefined;
    onRemoved?: (collection: ReadonlyMap<IdConfig, Config>, id: IdConfig, type_ident: string) => BimCollectionPatch<Config, IdConfig, ConfigPatch> | undefined;

    constructor(resolver: ConfigResolver) { 
        this.identifier = resolver.identifier;
        this.dependentTypes = resolver.dependentTypes;
        this.onAllocated = resolver.onAllocated;
        this.onUpdated = resolver.onUpdated;
        this.onRemoved = resolver.onRemoved;
    }
    
    match(type_ident: string) {
        return Array.isArray(this.dependentTypes) 
            ? this.dependentTypes.includes(type_ident)
            : this.dependentTypes === type_ident;
    }
}

export class ConfigsResolver {
    private readonly _configs: ConfigsCollection;
    readonly logger: ScopedLogger;
    private readonly _perTypeIdentity = new Map<IdConfig, string>();

    private readonly _resolversIdentities = new Set<string>();
    private readonly _resolvers: ConfigResolverWrapper[] = [];

    private readonly _observer: Observer;
    
    constructor(configs: ConfigsCollection, logger: ScopedLogger) {
        this._configs = configs;
        this.logger = logger.newScope('configs-resolver', LogLevel.Default);
        this._observer = this._configs.updatesStream.subscribe({
            onNext: (update) => {
                if(update instanceof Allocated) {
                    const configs = this._configs.peekByIds(update.ids);
                    for (const [id, config] of configs) {
                        this._perTypeIdentity.set(id, config.type_identifier);
                    }
                }

                this._resolve(update);

                if(update instanceof Deleted) {
                    for(const id of update.ids) {
                        this._perTypeIdentity.delete(id);
                    }
                }
            },
            settings: {
                immediateMode: true,
            }
        });
    }

    register(resolver: ConfigResolver) {
        if(this._resolversIdentities.has(resolver.identifier)) {
            throw new Error(`Resolver for ${resolver.identifier} already registered`);
        }
        if(!resolver.onAllocated && !resolver.onUpdated && !resolver.onRemoved){
            throw new Error(`Resolver ${resolver.identifier} has no resolve method`)
        }

        this._resolversIdentities.add(resolver.identifier);
        this._resolvers.push(new ConfigResolverWrapper(resolver));
    }

    private _resolve(update: EntitiesCollectionUpdates<IdConfig, number>) { 
        for(const id of update.ids) {
            const type_ident = this._perTypeIdentity.get(id);
            if(!type_ident) {
                this.logger.error(`No type for id ${id}`);
                continue;
            }

            for (const resolver of this._resolvers) {
                if(!resolver.match(type_ident)) {
                    continue;
                }

                let patch: BimCollectionPatch<Config, IdConfig, ConfigPatch> | undefined;
                if (update instanceof Allocated) {
                    patch = resolver.onAllocated ? resolver.onAllocated(this._configs.perId, id) : undefined;
                } else if (update instanceof Updated) {
                    patch = resolver.onUpdated ? resolver.onUpdated(this._configs.perId, id) : undefined;
                } else if (update instanceof Deleted) {
                    patch = resolver.onRemoved ? resolver.onRemoved(this._configs.perId, id, type_ident) : undefined;
                } else {
                    this.logger.error(`Resolver ${resolver.identifier} does not support update type `, [update, resolver]);
                }

                if (patch) {
                    this.logger.debug('Applying patches', patch);
                    this._configs.applyCollectionPatch(patch);
                }
            }
        }
    }

    dispose() { 
        this._observer.dispose();
    }
}