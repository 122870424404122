import type {
    AllSiteArea,
    Bim,
    Boundary2DDescription,
    FarmLayoutConfig,
    IdBimScene,
    IdConfig,
    RepresentationBase,
    SceneInstance,
    SiteSubarea
} from "bim-ts";
import {
    BasicAnalyticalRepresentation,
    BimProperty,
    BoundaryType,
    BoundaryTypeIdent,
    ExtrudedPolygonGeometry,
    NumberProperty,
    SceneInstances,
    SceneInstancesProperty,
    SceneObjDiff
} from "bim-ts";
import type { LazyVersioned, RGBAHex, ResultAsync, ScopedLogger, TasksRunner } from "engine-utils-ts";
import { DefaultMap, Failure, Immer, InProgress, IterUtils, LazyDerived, LazyDerivedAsync, RGBA, Success, Yield, convertThrow } from "engine-utils-ts";
import type {
    Aabb,
    Vector2
} from "math-ts";
import {
    Aabb2,
    Clipper,
    KrMath,
    Matrix4,
    PolygonUtils,
} from "math-ts";
import { orderContour, orderContours, orderHoles } from "../LayoutUtils";
import type { FarmConfigProperties } from "./FarmLayoutViews";

export function* createVirtualZoneFromBoundaries(
    bim: Bim,
    farmConfig: FarmLayoutConfig,
    selectedIndex: number,
): Generator<Yield, Boundary2DDescription[]> {
    const areas = convertFarmConfigToSiteAreas(farmConfig);
    const allBoundaries = getBoundariesLazyList(bim).poll();
    return yield* createVirtualZone(bim, areas.siteAreas, allBoundaries, selectedIndex);
}
interface SiteAreas {
    selectedIndex: number;
    siteAreas: SiteAreaType[];
}
type SiteAreaType = AllSiteAreaBoundaries | SubareaBoundaries | UnlocatedArea;
class AllSiteAreaBoundaries {
    constructor(
        public readonly boundaries: IdBimScene[],
    ) {}
}
class SubareaBoundaries {
    constructor(
        public readonly priority: number,
        public readonly zones: IdBimScene[],
        public readonly equipmentBoundaries: IdBimScene[],
    ) {}
}

class UnlocatedArea {}

const BoundaryEquipmentType = 'equipment';

function convertFarmConfigToSiteAreas(farmConfig: FarmLayoutConfig): SiteAreas {
    const areas: SiteAreas = {
        selectedIndex: farmConfig.selected_area.value,
        siteAreas: [],
    }
    for (const area of farmConfig.site_areas) {
        if(area.zones instanceof SceneInstancesProperty){
            const subarea = area as SiteSubarea;
            areas.siteAreas.push(new SubareaBoundaries(
                subarea.priority.value, 
                subarea.zones.value, 
                subarea.equipmentBoundaries.value
            ));
        } else if(area.boundaries instanceof SceneInstancesProperty){
            const allSiteArea = area as AllSiteArea;
            areas.siteAreas.push(new AllSiteAreaBoundaries(allSiteArea.boundaries.value));
        } else {
            areas.siteAreas.push(new UnlocatedArea());
        }
    }
    return areas;
}

function* createVirtualZone(
    bim: Bim,
    siteAreas: SiteAreaType[],
    allBoundaries: Map<IdBimScene, Boundary2DDescription>,
    selectedIndex: number,
): Generator<Yield, Boundary2DDescription[]> {
    yield Yield.NextFrame;
    const selectedArea = siteAreas[selectedIndex];
    let allSiteArea: AllSiteAreaBoundaries | undefined = undefined;
    for (const area of siteAreas) {
        if(!(area instanceof AllSiteAreaBoundaries)){
            continue;
        }
        allSiteArea = area;
        break;
    }
    
    let boundaries: Boundary2DDescription[] = [];
    if (selectedArea instanceof SubareaBoundaries) {
        const allSiteAreaBoundaries = allSiteArea?.boundaries ?? [];
        const zoneIds = getZonesIds(selectedArea.zones, allSiteAreaBoundaries);
        const zoneBoundariesIds = selectedArea.zones.filter(id => !zoneIds.has(id));
        const zoneContours = IterUtils.filterMap(zoneIds, (id) => allBoundaries.get(id)?.pointsWorldSpace);

        const excludePolygons = new Map<IdBimScene, Boundary2DDescription>();
        function filterBoundariesByType(ids: IdBimScene[], type: BoundaryType = BoundaryType.Exclude): Boundary2DDescription[]{
            const filterBoundaries:Boundary2DDescription[] = [];
            for (const id of ids) {
                const boundary = allBoundaries.get(id);
                if(boundary && boundary.boundaryType === type){
                    filterBoundaries.push(boundary);
                }
            }
            return filterBoundaries;
        }
        const excludeBounds = filterBoundariesByType(zoneBoundariesIds);
        
        for (const b of excludeBounds) {
            excludePolygons.set(b.bimObjectId, b);
        };

        if(allSiteArea instanceof AllSiteAreaBoundaries){
            const excludeBounds = filterBoundariesByType(allSiteArea.boundaries);
            for (const b of excludeBounds) {
                excludePolygons.set(b.bimObjectId, b);
            };
        } else {
            console.error('AllSiteArea not found', selectedArea);
        }
        const reusedAabb1 = Aabb2.empty();
        const reusedAabb2 = Aabb2.empty();
        function isIntersectWithContour(contour: Vector2[], contours: Vector2[][]): boolean{
            const orderedContour = orderContour(contour);
            reusedAabb1.setFromPoints(orderedContour);
            return orderContours(contours).some(c => {
                reusedAabb2.setFromPoints(c);
                return reusedAabb1.intersectsBox2(reusedAabb2) && Clipper.intersectsPolygons(c, orderedContour)
            });
        }

        const includeBounds = new Map<IdBimScene, Boundary2DDescription>();
        for (const id of allSiteAreaBoundaries) {
            const b = allBoundaries.get(id);
            if(b?.boundaryType !== BoundaryType.Include){
                continue;
            }
            if(isIntersectWithContour(b.pointsWorldSpace, zoneContours)){
                includeBounds.set(id, b);
            }
        }
        const includeBoundsFromSite = filterBoundariesByType(zoneBoundariesIds, BoundaryType.Include);
        const singleIncludeBoundaries: Boundary2DDescription[] = [];
        for (const b of includeBoundsFromSite) {
            if(includeBounds.has(b.bimObjectId)){
                includeBounds.delete(b.bimObjectId);
            }
            singleIncludeBoundaries.push(b);
        }

        for (const area of siteAreas) {
            if(!(area instanceof SubareaBoundaries)){
                continue;
            }
            if(selectedArea.priority <= area.priority){
                continue;
            }

            for (const id of area.equipmentBoundaries) {
                const boundary = allBoundaries.get(id);
                if(!boundary){
                    continue;
                }
                excludePolygons.set(boundary.bimObjectId, boundary);
            }
            yield Yield.Asap;
        }
        const zoneIntersections:Vector2[][] = [];
        for (const bound of includeBounds.values()) {
            zoneIntersections.push(bound.pointsWorldSpace);
        }
        const solution: Vector2[][] = [];
        for (const zoneContour of zoneContours) {
            const polygons = [zoneContour];
            IterUtils.extendArray(polygons, zoneIntersections);
            const result = Clipper.calculatePolygonsIntersections(orderContours(polygons));
            IterUtils.extendArray(solution, result);
            yield Yield.Asap;
        }

        const resultBoundaries: Boundary2DDescription[] = solution.map(contour => ({
            pointsWorldSpace: contour,
            boundaryType: BoundaryType.Include,
            bimObjectId: 0,
            minElevation: 0,
        }));
        IterUtils.extendArray(resultBoundaries, singleIncludeBoundaries);
        IterUtils.extendArray(resultBoundaries, excludePolygons.values());
        boundaries = resultBoundaries;
    } else if(selectedArea instanceof AllSiteAreaBoundaries){
        for (const id of selectedArea.boundaries) {
            const sourceType = bim.instances.peekById(id)?.properties.get('boundary | source_type')?.asText();
            if(sourceType === "equipment"){
                continue;
            }
            
            const boundary = allBoundaries.get(id);
            if(boundary){
                boundaries.push(boundary);
            }
        }
    } else {
        const equipmentBoundaries = new Map<IdBimScene, Boundary2DDescription>();
        for (const area of siteAreas) {
            if(area instanceof SubareaBoundaries){
                for (const id of area.equipmentBoundaries) {
                    const boundary = allBoundaries.get(id);
                    if(!boundary){
                        continue;
                    }
                    equipmentBoundaries.set(boundary.bimObjectId, boundary);
                }
            }
        }
        const resultBoundaries: Boundary2DDescription[] = [];
        if(allSiteArea instanceof AllSiteAreaBoundaries){
            IterUtils.extendArray(
                resultBoundaries, 
                IterUtils.filterMap(allSiteArea.boundaries, (b) => allBoundaries.get(b))
            );
        }
        IterUtils.extendArray(resultBoundaries, equipmentBoundaries.values());
        boundaries = resultBoundaries;
    }
    boundaries = boundaries.filter(b => b.pointsWorldSpace.length > 2);
    yield Yield.Asap;
    return boundaries;
}

export interface ZoneBoundaryProps {
    boundaries: Boundary2DDescription[];
    includeArea: NumberProperty;
    excludeArea: NumberProperty;
    availableArea: NumberProperty;
    message: string;
    errorMessage?: string;
}

export function createSubareasMetricsCalculator(bim: Bim, logger: ScopedLogger, lazyConfig: LazyVersioned<FarmLayoutConfig | undefined>){
    const boundariesLazy = getBoundariesLazyList(bim);
    const siteAreasLazy = LazyDerived.new1(
        'site-areas-data-lazy',
        null,
        [lazyConfig],
        ([config]) => {
            if(!config){
                return;
            }
            return convertFarmConfigToSiteAreas(config).siteAreas;
        },
    );
    const reusedAabb1 = Aabb2.empty();
    const reusedAabb2 = Aabb2.empty();
    const lazyPropsAsync = LazyDerivedAsync.new2(
        'site-areas-data-async',
        null,
        [siteAreasLazy, boundariesLazy],
        function* ([siteAreas, allBoundaries]) {
            yield Yield.Asap;
            const props: ZoneBoundaryProps[] = [];
            if(!siteAreas){
                logger.debug("site areas is undefined");
                return props; 
            }
        
            const calcArea = (boundaries: Vector2[][]) => Math.abs(IterUtils.sum(boundaries, (b) => PolygonUtils.area(b)));
            for (let i = 0; i < siteAreas.length; i++) {
                const boundaries = yield* createVirtualZone(bim, siteAreas, allBoundaries, i);
                if(boundaries.some(b => b === undefined)){
                    logger.error('some boundaries is undefined', boundaries, siteAreas[i], i, siteAreas);
                }
                const getContours = (boundaries: Boundary2DDescription[], type: BoundaryType) => {
                    const filtered = boundaries.filter(b => b?.boundaryType === type).map(b => b.pointsWorldSpace);
                    return type === BoundaryType.Include ? orderContours(filtered) : orderHoles(filtered); 
                }
                const includeAreaBounds = getContours(boundaries, BoundaryType.Include);
                const excludeAreaBounds = getContours(boundaries, BoundaryType.Exclude);
                const resultIncludeArea: Vector2[][] = [];
                const resultExcludeArea: Vector2[][] = [];
                // logger.warn('includeAreaBounds', includeAreaBounds);
                // logger.warn('excludeAreaBounds', excludeAreaBounds);
                for(let j = 0; j < includeAreaBounds.length; j++){                    
                    if (j % 10 === 0) {
                        yield Yield.Asap;
                    }
                    const inBound = includeAreaBounds[j];
                    reusedAabb1.setFromPoints(inBound);
                    const excludePolygonsFiltered: Vector2[][] = [];
                    for (const bound of excludeAreaBounds) {
                        reusedAabb2.setFromPoints(bound);
                        if(reusedAabb1.intersectsBox2(reusedAabb2)){
                            excludePolygonsFiltered.push(bound);
                        }
                    }

                    const subs = Clipper.subtractPolygons(inBound, excludePolygonsFiltered);
                    if(subs.length === 1){
                        resultIncludeArea.push(subs[0]);
                    } else {
                        for (const sub of subs) {
                            if(PolygonUtils.isClockwiseInner(sub)){
                                resultExcludeArea.push(sub);
                            } else {
                                resultIncludeArea.push(sub);
                            }
                        }
                    }
                }
                // logger.info('include', resultIncludeArea);
                // logger.info('exclude', resultExcludeArea);
        
                const allIncludeArea = calcArea(includeAreaBounds);
                const includeArea = calcArea(resultIncludeArea);
                const excludeArea = calcArea(resultExcludeArea) + allIncludeArea - includeArea;
                const available = includeArea - calcArea(resultExcludeArea);
                const convertM2ToHa = (area: number) => convertThrow(area, 'm2', 'ha');
                props.push({
                    boundaries: boundaries,
                    includeArea: NumberProperty.new({value: convertM2ToHa(allIncludeArea), unit: 'ha'}),
                    excludeArea: NumberProperty.new({value: convertM2ToHa(excludeArea), unit: 'ha'}),
                    availableArea: NumberProperty.new({value: convertM2ToHa(available), unit: 'ha'}),
                    message: '',
                });
            }
        
            return props;
        }
    );

    return lazyPropsAsync;
}

export function createLazySiteAreasData(bim: Bim, logger: ScopedLogger, lazyConfig: LazyDerived<FarmConfigProperties>){
    const lazyFarmConfig = LazyDerived.new1(
        "farm-config-lazy",
        null,
        [lazyConfig],
        ([config]) => {
            if(!config){
                return;
            }
            return config.config;
        }
    );
    const lazyPropsAsync = createSubareasMetricsCalculator(bim, logger, lazyFarmConfig);

    const lazyProps = LazyDerived.new1<ZoneBoundaryProps[], ResultAsync<ZoneBoundaryProps[]>>(
        'site-areas-data',
        [bim.unitsMapper], 
        [lazyPropsAsync], 
        ([props], prevResult) => {

        if(props instanceof Success){
            const messagesByZone: ZoneBoundaryProps[] = [];
            for (const prop of props.value) {
                const available = prop.availableArea.toConfiguredUnits(bim.unitsMapper);
                const include = prop.includeArea.toConfiguredUnits(bim.unitsMapper);
                const exclude = prop.excludeArea.toConfiguredUnits(bim.unitsMapper);
                const round = (value: number) => KrMath.roundTo(value, 1);
                const message = `${round(available.value)} ${available.unit} available ` +
                 (exclude.value !== 0 
                 ? `(${round(exclude.value)} of ${round(include.value)} ${include.unit} excluded)`
                 : `(0 ${include.unit} excluded)`);
                 const minAreaAc = 25;
                 const mapToConfigured = (val: number) => {
                     const valUnit = bim.unitsMapper.mapToConfigured({value: val, unit: 'ac'});
                     return round(valUnit.value);
                    };
                let errorMessage: string | undefined;
                if(convertThrow(available.value, available.unit, 'ac') <= minAreaAc && include.value > 0){
                    errorMessage = `The selected area is insufficient for layout generation. ` +
                    `Each MW of solar arrays requires ${mapToConfigured(4)}-${mapToConfigured(6)} ${available.unit} of land, ` +
                    `meaning a typical 5 MW block would need around ${mapToConfigured(25)} ${available.unit}.` +
                    '<br/> Please select more include boundaries.'
                }
                messagesByZone.push({
                    boundaries: prop.boundaries,
                    message,
                    availableArea: available,
                    excludeArea: exclude,
                    includeArea: include,
                    errorMessage: errorMessage
                });
            }
            return messagesByZone;
        } else if((props instanceof Failure)){
            logger.error(props.msg);
        } else if(props instanceof InProgress) {
            //
        } else {
            logger.error("Raw status", props);
        }
        return prevResult ?? [];
    });

    return lazyProps;
}

export async function updateEquipmentBoundaries(
    bim: Bim, 
    tasksRunner: TasksRunner,
    substationId: IdBimScene, 
    configId: IdConfig,
    additionalUpdate?: (farmLayout: FarmLayoutConfig) => FarmLayoutConfig
){
    const farmConfig = bim.configs.peekById(configId);
    if(!farmConfig){
        console.error('farmConfig not found', configId);
        return;
    }
    const props = farmConfig.get<FarmLayoutConfig>();
    const equipmentBoundaries = await tasksRunner.newLongTask({
        defaultGenerator: createBoundariesAroundEquipment(bim, substationId, props),
    }).asPromise();

    let updated = props;

    updated = Immer.produce(updated, (draft) => {
        for (const [idx, boundaries] of equipmentBoundaries) {
            draft.site_areas[idx].equipmentBoundaries = boundaries;
        }
    });

    if(additionalUpdate){
        updated = additionalUpdate(updated);
    }

    bim.configs.applyPatches([
        [configId, { properties: updated }],
    ]);
}

function* createBoundariesAroundEquipment(
    bim: Bim, 
    substation: IdBimScene,
    config: FarmLayoutConfig, 
    ): Generator<Yield, [number, SceneInstancesProperty][]> {
    yield Yield.Asap;
    const result: [number, SceneInstancesProperty][] = [];
    const goemetriesAabbs = bim.allBimGeometries.aabbs.poll(); 
    const reprsBboxes = new DefaultMap<RepresentationBase, Aabb>(r => r.aabb(goemetriesAabbs));

    const equipmentByAreas = new DefaultMap<number, [IdBimScene, SceneInstance][]>(() => []);
    const allIds = bim.instances.spatialHierarchy.gatherIdsWithSubtreesOf({ids: [substation]});
    const instances = bim.instances.peekByIds(allIds);
    for (const [id, inst] of instances) {
        const areaId = inst.properties.get('circuit | position | area_index')?.asNumber();
        if(areaId === undefined){
            continue;
        }
        const idsInArea = equipmentByAreas.getOrCreate(areaId);
        idsInArea.push([id, inst]);
    }
    const toDeleteIds: IdBimScene[] = [];
    const toAllocate: [IdBimScene, Partial<SceneInstance>][] = [];
    for (let areaIndex = 0; areaIndex < config.site_areas.length; areaIndex++) {
        
        const area = config.site_areas[areaIndex];
        const settings = area.settings;
        if(!(area.zones instanceof SceneInstancesProperty)){
            continue;
        }
    
        const subarea = area as SiteSubarea;
        const equipmentInstances = equipmentByAreas.getOrCreate(areaIndex);
        IterUtils.extendArray(toDeleteIds, subarea.equipmentBoundaries.value);
        let prevColor: RGBAHex | undefined = undefined;
        for (const [_, inst] of bim.instances.peekByIds(subarea.equipmentBoundaries.value)) {
            const color = inst.colorTint;
            if(color){
                prevColor = color;
                break;
            }
        }
        if(equipmentInstances.length === 0){
            continue;
        }

        const instancesBoundaries: Vector2[][] = [];
        let minElevation = Infinity;
        for (const [_, inst] of equipmentInstances) {
            if (!inst.representation) {
                continue;
            }
            const reprAabb = reprsBboxes.getOrCreate(inst.representation);
            if (reprAabb.isEmpty()) {
                continue;
            }
            const bbox = reprAabb.get2DCornersAtZ(reprAabb.minz());
            
            const bbox2d: Vector2[] = [];
            for (const p of bbox) {
                p.applyMatrix4(inst.worldMatrix);
                minElevation = Math.min(p.z, minElevation);
                bbox2d.push(p.xy());
            }
    
            instancesBoundaries.push(bbox2d);
        }
        yield Yield.Asap;
    
        if(instancesBoundaries.length > 0){
            const equipmentContours =  Clipper.calculateBoundariesWithJoinTolerance2D(
                orderContours(instancesBoundaries), 
                settings.spacing.equipment_glass_to_glass.as('m'), 
                settings.offsets.tracker_offset.as('m'),
            );
            const outerContours = equipmentContours.filter(c => !PolygonUtils.isClockwiseInner(c));
            yield Yield.Asap;
            const newInstances: [IdBimScene, Partial<SceneInstance>][] = [];
            for (const equipmentContour of outerContours) {
                if(Math.abs(PolygonUtils.area(equipmentContour)) < 1){
                    console.warn('equipmentContour area < 1', equipmentContour);
                    continue;
                }
                const newInstance = makeNewBoundary(
                    bim, 
                    equipmentContour, 
                    minElevation,
                    () => {
                        const inst = bim.instances.archetypes.newDefaultInstanceForArchetype('boundary');
                        inst.colorTint = prevColor ? prevColor : getColor(areaIndex);
                        const excludeProp = BimProperty.NewShared({
                            path: ['boundary', 'boundary_type'], 
                            discrete_variants: ['include', 'exclude'], 
                            value: 'exclude',
                        });
    
                        const equipmentProp = BimProperty.NewShared({
                            path: ['boundary', 'source_type'],
                            discrete_variants: ['origin', 'equipment'],
                            value: BoundaryEquipmentType,
                            readonly: true,
                        });
                        inst.properties.applyPatch([
                            [ excludeProp._mergedPath, excludeProp],
                            [ equipmentProp._mergedPath, equipmentProp],
                        ]);
                        inst.name = subarea.name.value ? subarea.name.value + ' equipment boundary': 'Equipment boundary';
                        return inst;
                    },
                    substation,
                );
                newInstances.push(newInstance);
                toAllocate.push(newInstance);
            }
            const newProp = subarea.equipmentBoundaries.withDifferentValue(
                newInstances.map(([id, _]) => id)
            );
    
            result.push([areaIndex, newProp]);
        }
    }

    for (const [id, inst] of instances) {
        if(inst.type_identifier !== BoundaryTypeIdent){
            continue;
        }
        const source_type = inst.properties.get('boundary | source_type')?.asText();
        if(source_type !== BoundaryEquipmentType){
            continue
        }
        toDeleteIds.push(id);
    }

    bim.instances.allocate(toAllocate);
    bim.instances.delete(toDeleteIds);

    return result;
}

function getZonesIds(ids: IdBimScene[], allSiteAreaIds: IdBimScene[]){
    const allSiteAreaIdsSet = new Set(allSiteAreaIds);
    const zones = new Set<IdBimScene>();
    for (const id of ids) {
        if (allSiteAreaIdsSet.has(id)) {
            continue;
        }
        zones.add(id);
    }
    return zones;
}

function makeNewBoundary(
    bim: Bim,
    contour: Vector2[],
    elevation: number,
    createDefault: (id: IdBimScene) => SceneInstance,
    parent: IdBimScene | undefined
): [IdBimScene, Partial<SceneInstance>] {
    const basePoint2d = contour[0].clone();

    const objectWorldMatrix = new Matrix4().setPosition(
        basePoint2d.x,
        basePoint2d.y,
        elevation
    );
    const inverseWorldMatrix = new Matrix4().getInverse(objectWorldMatrix);
    const transformedContour = contour.map((p) => {
        const copy = p.clone();
        copy.applyMatrix4(inverseWorldMatrix);
        return copy;
    });

    const boundaryGeo = ExtrudedPolygonGeometry.newWithAutoIds(
        transformedContour,
        undefined,
        0,
        10
    );

    const geoId = bim.extrudedPolygonGeometries.idsProvider.reserveNewId();
    bim.extrudedPolygonGeometries.allocate([[geoId, boundaryGeo]]);

    const instanceId = bim.instances.idsProvider.reserveNewId();
    const instance = createDefault(instanceId);
    instance.representationAnalytical = new BasicAnalyticalRepresentation(
        geoId
    );

    const parentInst = parent ? bim.instances.peekById(parent) : undefined;
    instance.localTransform = SceneInstances.getLocalTransformRelativeToParentMatrix(parentInst?.worldMatrix, objectWorldMatrix);

    if (parent !== undefined) {
        instance.spatialParentId = parent;
    }

    return [instanceId, instance];
}

export function getBoundariesLazyList(bim: Bim){
    const boundariesLazyList = bim.instances.getLazyListOf({
        type_identifier: 'boundary',
        relevantUpdateFlags: 
            SceneObjDiff.LegacyProps 
            | SceneObjDiff.NewProps 
            | SceneObjDiff.GeometryReferenced 
            | SceneObjDiff.WorldPosition
    });

    return LazyDerived.new0(
        "boundaries-lazy-list",
        [boundariesLazyList],
        () => {
            const boundaries = IterUtils.filterMap<
                Boundary2DDescription,
                [IdBimScene, Boundary2DDescription]
            >(bim.extractBoundaries(), (b) => {
                const boundary = simplifyContour(b);
                if (!boundary) {
                    return;
                }
                return [b.bimObjectId, boundary];
            });
            const allBoundaries = IterUtils.newMapFromTuples(boundaries);
            return allBoundaries;
        }
    );
}

function simplifyContour(b: Boundary2DDescription): Boundary2DDescription | undefined{
    const eps = 5e-5;
    if(b.pointsWorldSpace.length < 3){
        return;
    }
    let pts = PolygonUtils.deleteDuplicates(b.pointsWorldSpace);
    pts = PolygonUtils.simplifyContour(pts, eps);
    if(pts.length < 3){
        return;
    }
    return {
        bimObjectId: b.bimObjectId,
        boundaryType: b.boundaryType,
        minElevation: b.minElevation,
        pointsWorldSpace: pts,
    }
}
  
function newRGB(r: number, g: number, b: number){
    return RGBA.newRGB(r/255, g/255, b/255);
}

export const palette: ReadonlyArray<RGBAHex> = [
    newRGB(96, 203, 205),
    newRGB(153, 46, 160),
    newRGB(234, 68, 61),
    newRGB(213, 213, 19),
    newRGB(91, 171, 73),
];

function getColor(index: number): RGBAHex {
    return palette[index % palette.length];
}
