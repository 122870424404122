import type { Euler } from './Euler';
import { KrMath } from './KrMath';
import type { Matrix4 } from './Matrix4';
import { Vector3 } from './Vector3';

//mostly taken from three.js math lib

export class Quaternion {
	x: number = 0;
	y: number = 0;
	z: number = 0;
	w: number = 1;

	
	constructor( x = 0, y = 0, z = 0, w = 1 ) {

		this.x = x;
		this.y = y;
		this.z = z;
		this.w = w;

	}

	toString() {
		return `(${this.x}:${this.y}:${this.z}-${this.w})`;
	}

	static slerp( qa: Quaternion, qb: Quaternion, qm: Quaternion, t: number ) {

		console.warn( 'THREE.Quaternion: Static .slerp() has been deprecated. Use qm.slerpQuaternions( qa, qb, t ) instead.' );
		return qm.slerpQuaternions( qa, qb, t );

	}

	rotateTowards( q: Quaternion, step: number ) {

		const angle = this.angleTo( q );

		if ( angle === 0 ) return this;

		const t = Math.min( 1, step / angle );

		this.slerp( q, t );

		return this;

	}

	identity() {

		return this.set( 0, 0, 0, 1 );

	}

	invert() {

		// quaternion is assumed to have unit length

		return this.conjugate();

	}


	lengthSq() {

		return this.x * this.x + this.y * this.y + this.z * this.z + this.w * this.w;

	}

	
	premultiply( q: Quaternion ) {

		return this.multiplyQuaternions( q, this );

	}

	multiplyQuaternions( a: Quaternion, b: Quaternion ) {

		// from http://www.euclideanspace.com/maths/algebra/realNormedAlgebra/quaternions/code/index.htm

		const qax = a.x, qay = a.y, qaz = a.z, qaw = a.w;
		const qbx = b.x, qby = b.y, qbz = b.z, qbw = b.w;

		this.x = qax * qbw + qaw * qbx + qay * qbz - qaz * qby;
		this.y = qay * qbw + qaw * qby + qaz * qbx - qax * qbz;
		this.z = qaz * qbw + qaw * qbz + qax * qby - qay * qbx;
		this.w = qaw * qbw - qax * qbx - qay * qby - qaz * qbz;

		return this;
	}

	slerp( qb: Quaternion, t: number ) {

		if ( t === 0 ) return this;
		if ( t === 1 ) return this.copy( qb );

		const x = this.x, y = this.y, z = this.z, w = this.w;

		// http://www.euclideanspace.com/maths/algebra/realNormedAlgebra/quaternions/slerp/

		let cosHalfTheta = w * qb.w + x * qb.x + y * qb.y + z * qb.z;

		if ( cosHalfTheta < 0 ) {

			this.w = - qb.w;
			this.x = - qb.x;
			this.y = - qb.y;
			this.z = - qb.z;

			cosHalfTheta = - cosHalfTheta;

		} else {

			this.copy( qb );

		}

		if ( cosHalfTheta >= 1.0 ) {

			this.w = w;
			this.x = x;
			this.y = y;
			this.z = z;

			return this;

		}

		const sqrSinHalfTheta = 1.0 - cosHalfTheta * cosHalfTheta;

		if ( sqrSinHalfTheta <= Number.EPSILON ) {

			const s = 1 - t;
			this.w = s * w + t * this.w;
			this.x = s * x + t * this.x;
			this.y = s * y + t * this.y;
			this.z = s * z + t * this.z;

			this.normalize();

			return this;

		}

		const sinHalfTheta = Math.sqrt( sqrSinHalfTheta );
		const halfTheta = Math.atan2( sinHalfTheta, cosHalfTheta );
		const ratioA = Math.sin( ( 1 - t ) * halfTheta ) / sinHalfTheta,
			ratioB = Math.sin( t * halfTheta ) / sinHalfTheta;

		this.w = ( w * ratioA + this.w * ratioB );
		this.x = ( x * ratioA + this.x * ratioB );
		this.y = ( y * ratioA + this.y * ratioB );
		this.z = ( z * ratioA + this.z * ratioB );

		return this;

	}

	slerpQuaternions( qa: Quaternion, qb: Quaternion, t: number ) {

		this.copy( qa ).slerp( qb, t );

	}

	equals( quaternion: Quaternion ) {

		return ( quaternion.x === this.x ) && ( quaternion.y === this.y ) && ( quaternion.z === this.z ) && ( quaternion.w === this.w );

	}

	fromArray( array: number[], offset = 0 ) {

		this.x = array[ offset ];
		this.y = array[ offset + 1 ];
		this.z = array[ offset + 2 ];
		this.w = array[ offset + 3 ];

		return this;

	}

	toArray( array: number[] = [], offset = 0 ) {

		array[ offset ] = this.x;
		array[ offset + 1 ] = this.y;
		array[ offset + 2 ] = this.z;
		array[ offset + 3 ] = this.w;

		return array;

	}

	fromBufferAttribute( attribute: any, index: number ) {

		this.x = attribute.getX( index );
		this.y = attribute.getY( index );
		this.z = attribute.getZ( index );
		this.w = attribute.getW( index );

		return this;

	}

	static identity(): Quaternion {
		return new Quaternion();
	}

	static fromUnitVectors(vFrom:Vector3, vTo:Vector3) {
		const q = Quaternion.identity();
		q.setFromUnitVectors(vFrom, vTo);
		return q;
	}

	static fromAxisAngle(axis: Vector3, angle: number): Quaternion {
		return Quaternion.identity().setFromAxisAngle(axis, angle);
	}

	set(x: number, y: number, z: number, w: number) {
		this.x = x;
		this.y = y;
		this.z = z;
		this.w = w;
		return this;
	}

	copy(rhs: Quaternion) {
		this.x = rhs.x;
		this.y = rhs.y;
		this.z = rhs.z;
		this.w = rhs.w;
		return this;
	}

	clone(): Quaternion {
		return new Quaternion().set(this.x, this.y, this.z, this.w);
	}

	multiply( q:Quaternion ) {
		Quaternion.multiply(this, q, this);
		return this;
	}

	static multiply ( a:Quaternion, b:Quaternion, target:Quaternion ) {
		// from http://www.euclideanspace.com/maths/algebra/realNormedAlgebra/quaternions/code/index.htm
		const qax = a.x, qay = a.y, qaz = a.z, qaw = a.w;
		const qbx = b.x, qby = b.y, qbz = b.z, qbw = b.w;

		target.x = qax * qbw + qaw * qbx + qay * qbz - qaz * qby;
		target.y = qay * qbw + qaw * qby + qaz * qbx - qax * qbz;
		target.z = qaz * qbw + qaw * qbz + qax * qby - qay * qbx;
		target.w = qaw * qbw - qax * qbx - qay * qby - qaz * qbz;
	}

	length(): number {
		return Math.sqrt( this.x * this.x + this.y * this.y + this.z * this.z + this.w * this.w );
	}

	normalize(): Quaternion {
		const l = this.length();

		if ( l === 0 ) {

			this.x = 0;
			this.y = 0;
			this.z = 0;
			this.w = 1;

		} else {

			const lRev = 1 / l;

			this.x = this.x * lRev;
			this.y = this.y * lRev;
			this.z = this.z * lRev;
			this.w = this.w * lRev;
		}
		return this;
	}

	setFromUnitVectors ( vFrom:Vector3, vTo:Vector3 ) {
		const EPS = 0.000001;
		let r = vFrom.dot( vTo ) + 1;
		const v1 = Vector3.zero();
			
		if (r < EPS) {
			r = 0;
			if ( Math.abs( vFrom.x ) > Math.abs( vFrom.z ) ) {
				v1.set( - vFrom.y, vFrom.x, 0 );
			} else {
				v1.set( 0, - vFrom.z, vFrom.y );
			}
		} else {
			v1.copy(vFrom).cross(vTo);
		}

		this.x = v1.x;
		this.y = v1.y;
		this.z = v1.z;
		this.w = r;

		return this.normalize();
	}

	
	setFromRotationMatrix(m: Matrix4) {

		// http://www.euclideanspace.com/maths/geometry/rotations/conversions/matrixToQuaternion/index.htm
		// assumes the upper 3x3 of m is a pure rotation matrix (i.e, unscaled)
		const te = m.elements,

			m11 = te[0], m12 = te[4], m13 = te[8],
			m21 = te[1], m22 = te[5], m23 = te[9],
			m31 = te[2], m32 = te[6], m33 = te[10],

			trace = m11 + m22 + m33;
		
		let s;

		if ( trace > 0 ) {

			s = 0.5 / Math.sqrt( trace + 1.0 );

			this.w = 0.25 / s;
			this.x = ( m32 - m23 ) * s;
			this.y = ( m13 - m31 ) * s;
			this.z = ( m21 - m12 ) * s;

		} else if ( m11 > m22 && m11 > m33 ) {

			s = 2.0 * Math.sqrt( 1.0 + m11 - m22 - m33 );

			this.w = ( m32 - m23 ) / s;
			this.x = 0.25 * s;
			this.y = ( m12 + m21 ) / s;
			this.z = ( m13 + m31 ) / s;

		} else if ( m22 > m33 ) {

			s = 2.0 * Math.sqrt( 1.0 + m22 - m11 - m33 );

			this.w = ( m13 - m31 ) / s;
			this.x = ( m12 + m21 ) / s;
			this.y = 0.25 * s;
			this.z = ( m23 + m32 ) / s;

		} else {

			s = 2.0 * Math.sqrt( 1.0 + m33 - m11 - m22 );

			this.w = ( m21 - m12 ) / s;
			this.x = ( m13 + m31 ) / s;
			this.y = ( m23 + m32 ) / s;
			this.z = 0.25 * s;

		}
		return this;
	}

	setFromAxisAngle( axis:Vector3, angle:number ) {
		// http://www.euclideanspace.com/maths/geometry/rotations/conversions/angleToQuaternion/index.htm
		// assumes axis is normalized
		var halfAngle = angle / 2, s = Math.sin( halfAngle );
		this.x = axis.x * s;
		this.y = axis.y * s;
		this.z = axis.z * s;
		this.w = Math.cos( halfAngle );
		return this;
	}

	setFromEuler(euler: Euler) {
		const x = euler.x, y = euler.y, z = euler.z;

		// http://www.mathworks.com/matlabcentral/fileexchange/
		// 	20696-function-to-convert-between-dcm-euler-angles-quaternions-and-euler-vectors/
		//	content/SpinCalc.m

		const cos = Math.cos;
		const sin = Math.sin;

		const c1 = cos( x / 2 );
		const c2 = cos( y / 2 );
		const c3 = cos( z / 2 );

		const s1 = sin( x / 2 );
		const s2 = sin( y / 2 );
		const s3 = sin(z / 2);
		
		this.x = s1 * c2 * c3 + c1 * s2 * s3;
		this.y = c1 * s2 * c3 - s1 * c2 * s3;
		this.z = c1 * c2 * s3 + s1 * s2 * c3;
		this.w = c1 * c2 * c3 - s1 * s2 * s3;

		return this;
	}

	dot(rhs: Quaternion): number {
		return this.x * rhs.x + this.y * rhs.y + this.z * rhs.z + this.w * rhs.w;

	}

	angleTo(rhs: Quaternion): number {
		return 2 * Math.acos( Math.abs( KrMath.clamp( this.dot( rhs ), - 1, 1 ) ) );
	}

	inverse() {
		// quaternion is assumed to have unit length
		return this.conjugate();
	}

	conjugate() {
		this.x *= - 1;
		this.y *= - 1;
		this.z *= - 1;
		return this;
	}

	isFinite(): boolean {
		return isFinite(this.x) && isFinite(this.y) && isFinite(this.z) && isFinite(this.w);
	}
}
