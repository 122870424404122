<script lang="ts">
    import { getContext } from "svelte";
    import { ButtonComponent, IconButton } from "src/libui/button";
    import { CheckboxStandard } from "src/libui/checkbox";
    import { Group } from "./DxfGroupsUtils";
    import { DialogsListener } from "../../../dialogs/DialogsListener";

    export let name: string;
    export let group: Group;
    export let singleExport: boolean = false;
    export let itemsType: string;

    const dialogsListener = getContext<DialogsListener>("dialogsListener");

    function changeValue(val: boolean) {
        group.onChange(val);
        group.selected = val;
    }
    $: total = group.total;
    $: disabled = !total;

    $: {
        if (disabled && group.selected) {
            changeValue(false);
        }
    }

    function exportGroup() {
        group.export();
        dialogsListener.closeCurrentDialog();
    }
</script>

<div class="group-wrapper">
    <div class="export-group" class:text-main-light={disabled}>
        <span class="checkbox-container">
            <CheckboxStandard
                value={!disabled && group.selected}
                onChange={changeValue}
                readonly={disabled}
            />
        </span>

        <div class="export-group-name mdc-typography--subheader2">
            <span>{name}</span>
        </div>
        <div class="export-group-items">
            <div class="mdc-typography--body1-semi-strong">
                <span class="export-item-count">
                    {#if total}
                        × {total}
                    {:else}
                        No
                    {/if}
                </span>
                <span>{total === 1 ? itemsType : itemsType + "s"}</span>
            </div>
            {#if !disabled}
                <slot></slot>
            {/if}
        </div>
        {#if singleExport && !disabled}
            <ButtonComponent desc={new IconButton("Export", exportGroup)} />
        {/if}
    </div>
</div>

<style lang="scss">
    .group-wrapper {
        &:not(:last-child) {
            border-bottom: 1px solid var(--color-divider);
        }
        :global(.group-wrapper) {
            border-bottom: 0 !important;
        }
        :global(.group-wrapper .export-group) {
            padding-top: 0;
        }
    }
    .export-group {
        display: flex;
        gap: 8px;
        padding: 8px 0 6px;

        :global(.custom-icon-container svg) {
            height: 16px;
        }
    }
    .checkbox-container {
        height: 16px;
        width: 16px;
        padding-top: 1px;
    }
    .export-group-name {
        width: 140px;
        flex-shrink: 0;
    }
    .export-group-items {
        overflow: hidden;
        flex: 1;
    }
</style>
