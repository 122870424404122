<script lang="ts">
    import {  getPileFeaturesWithUndulation, getPileUndulationType, getPileWeightClass, PileFeaturesFlags, PileTypeSettings, PileUndulationType, TrackerBins, unpackPileFeatures } from "bim-ts";
    import SelectorPropertyBase from "../../pui/SelectorPropertyBase.svelte";
    import { onDestroy } from "svelte";
    import { MultiSelectorValue } from "ui-bindings";
    import PileFeatures from "./PileFeatures.svelte";
    import UndulationTypeOption from "./UndulationTypeOption.svelte";

    export let selected: PileFeaturesFlags;
    export let settingsGroup: TrackerBins;
    export let onChange: (value: PileFeaturesFlags) => void;
    export let width: number;
    export let offset: number = 0;
    
    const undulationId = "undulation";
    const disposeCallbacks: (() => void)[]  = [];
    function dispose() {
        for (const cb of disposeCallbacks) {
            cb();
        }
        disposeCallbacks.length = 0;
    }

    function newOption(features: PileFeaturesFlags, shortName: string, longName?: string) :MultiSelectorValue {
        const div = document.createElement("div");
        const pileFeaturesComp = new PileFeatures({
            target: div,
            props: {
                pileFeatures: features,
                shortName: shortName,
                longName: longName,
            },
        });

        disposeCallbacks.push(() => {
            pileFeaturesComp.$destroy();
            div.remove();
        });

        return {value: features.toString(), label: div.innerHTML, group: getPileWeightClass(features) ? "Heavy" : "Standard" };
    }

    function addUndulationOption(undulationType: PileUndulationType) : MultiSelectorValue {
        const div = document.createElement("div");
        const pileFeaturesComp = new UndulationTypeOption({
            target: div,
            props: {
                undulationType,
            },
        });

        disposeCallbacks.push(() => {
            pileFeaturesComp.$destroy();
            div.remove();
        });

        return {value: undulationId, label: div.innerHTML, group: " " };
    }

    $: value = [getPileFeaturesWithUndulation(
            selected,
            PileUndulationType.Undulated
        ).toString()];
    $: undulation = getPileUndulationType(selected);
    
    let options:MultiSelectorValue[] = [];
    $:{
        dispose();
        const newOptions: MultiSelectorValue[] = [];
        for (const settings of settingsGroup.pileTypes) {
            if(!(settings instanceof PileTypeSettings)) {
                continue;
            }
            const feature = settings.pileFeatures.value as PileFeaturesFlags;
            const longName = settingsGroup.getPileFullName(feature);
            const option = newOption(feature, settings.shortName.value, longName);
            newOptions.push(option);
        }
        const withoutUndulatedFlag = getPileFeaturesWithUndulation(
            selected,
            PileUndulationType.Undulated
        );
        const settings = settingsGroup.getTypeByFeaturesKey(withoutUndulatedFlag);
        if(!settings) {
            const option = newOption(withoutUndulatedFlag, "Default", undefined);
            newOptions.push(option);
        }
        const undulationType = getPileUndulationType(selected);
        const undulationOption = addUndulationOption(undulationType);
        newOptions.push(undulationOption);

        options = newOptions.sort((a, b) => b.group!.localeCompare(a.group!) || a.label!.localeCompare(b.label!));
    }

    function updateValue(value: any){
        const newSelected = parseInt(value);
        if(isFinite(newSelected)) {
            const withUndulatedFlag = getPileFeaturesWithUndulation(
                newSelected,
                getPileUndulationType(selected)
            );
            onChange(withUndulatedFlag);
        } else if(value === undulationId) {
            const withNewUndulatedFlag = getPileFeaturesWithUndulation(
                selected,
                getPileUndulationType(selected) === PileUndulationType.Undulated ? PileUndulationType.Rigid : PileUndulationType.Undulated
            );
            onChange(withNewUndulatedFlag);
        } else {
            throw new Error(`Invalid value: ${value}`);
        }
    }
    
    onDestroy(() => {
        dispose();
    });

</script>

{#key selected}
    <SelectorPropertyBase
        value = {value}
        options = {options}
        placeholder={`Select pile type`}
        ignoreScrollContainer={true}
        on:change={(e) => {
            const value = e.detail && e.detail.value;
            updateValue(value);
        }}
        customContainerWidth={width}
        customLeftOffset={offset}
    />
{/key}

<style lang="scss">
    :global(.pile-type-prop .custom-svelecte .sv-content) {
        padding: 0;
        margin-left: 4px;
    }
</style>