import { LegacyLogger } from './';

export type ObjectUniquePrimitiveHash = string | number | Symbol;

export class DefaultMapObjectKey<K extends object, V> /*implements Map<K, V>*/ {

    private _unique_hash: (obj: K) => ObjectUniquePrimitiveHash;

    private _perObjectsHashesWeakCache = new WeakMap<K, ObjectUniquePrimitiveHash>();
    private _perUniqueHash = new Map<ObjectUniquePrimitiveHash, [K, V]>();
    private _valuesFactory: (key: K) => V;

    constructor(params: {
        unique_hash?: (key: K) => ObjectUniquePrimitiveHash,
        valuesFactory: (key: K) => V,
    }) {
        this._unique_hash = params.unique_hash ?? JSON.stringify;
        this._valuesFactory = params.valuesFactory;
    }

    _hashOf(key: K): ObjectUniquePrimitiveHash {
        let hash = this._perObjectsHashesWeakCache.get(key);
        if (hash === undefined) {
            hash = this._unique_hash(key);
            if (!Object.isFrozen(key)) {
                LegacyLogger.deferredError('object map key should be frozen', key);
            }
            this._perObjectsHashesWeakCache.set(key, hash);
        }
        return hash;
    }

    clear(): void {
        this._perUniqueHash.clear();
    }
    delete(key: K): boolean {
        const hash = this._hashOf(key);
        // this._perObjectsHashesWeakCache.delete(key);
        return this._perUniqueHash.delete(hash);
    }
    get(key: K): V | undefined {
        const hash = this._hashOf(key);
        let saved = this._perUniqueHash.get(hash);
        if (saved !== undefined) {
            return saved[1];
        }
        return undefined;
    }
    getOrCreate(key: K): V {
        const hash = this._hashOf(key);
        let saved = this._perUniqueHash.get(hash);
        if (saved !== undefined) {
            return saved[1];
        }
        const value = this._valuesFactory(key);
        this._perUniqueHash.set(hash, [key, value]);
        return value;
    }
    has(key: K): boolean {
        const hash = this._hashOf(key);
        return this._perUniqueHash.has(hash);
    }
    get size(): number {
        return this._perUniqueHash.size;
    }
    entries(): IterableIterator<[K, V]> {
        return this._perUniqueHash.values();    
    }
    *keys(): IterableIterator<K> {
        for (const kv of this._perUniqueHash.values()) {
            yield kv[0];
        }
    }
    *values(): IterableIterator<V> {
        for (const kv of this._perUniqueHash.values()) {
            yield kv[1];
        }
    }
    [Symbol.iterator](): IterableIterator<[K, V]> {
        return this._perUniqueHash.values();    
    }
}
