import type { Font } from "../components/Font";


/**
Job:
- Computing glyphs dimensions according to this component's font and content
- Create the text Mesh (call MSDFGlyph for each letter)

Knows:
- The Text component for which it creates Meshes
- The parameters of the text mesh it must return
 */

export function getGlyphDimensions( glyph: string, font: Font, fontSize: number ) {
	const SCALE_MULT = fontSize / font.info.size;

	//

	const charOBJ = font.chars.find( charOBJ => charOBJ.char === glyph );

	let width = charOBJ ? charOBJ.width * SCALE_MULT : fontSize / 3;

	let height = charOBJ ? charOBJ.height * SCALE_MULT : 0;

	// handle exported whitespaces
	if ( width === 0 ) {
		// if this whitespaces in is the charset, use its xadvance value
		// or fallback to fontSize
		width = charOBJ ? charOBJ.xadvance * SCALE_MULT : fontSize;
	}

	if ( height === 0 ) height = fontSize * 0.7;

	if ( glyph === '\n' ) width = 0;

	const xadvance = charOBJ ? charOBJ.xadvance * SCALE_MULT : width;
	const xoffset = charOBJ ? charOBJ.xoffset * SCALE_MULT : 0;

	// world-space length between lowest point and the text cursor position
	// const anchor = charOBJ ? ( ( charOBJ.yoffset + charOBJ.height - FONT.common.base ) * FONT_SIZE ) / FONT.common.lineHeight : 0;

	const anchor = charOBJ ? charOBJ.yoffset * SCALE_MULT : 0;

	// console.log( lineHeight )

	return {
		// lineHeight,
		width,
		height,
		anchor,
		xadvance,
		xoffset
	};
}


/**
 * Try to find the kerning amount of a
 */
export function getGlyphPairKerning( font: Font, glyphPair: string ): number {
	const KERNINGS = font._kernings!;
	const result = KERNINGS.get( glyphPair );
	return result ? result : 0;
}