import type { Bim } from "bim-ts";
import type { LazyVersioned, Yield } from "engine-utils-ts";
import type { ExportedFileDescription, FileExporter, FileExporterContext } from "ui-bindings";
import { CommonExportSettings, getExportProjectName } from "../CommonExportSettings";
import { convertTrackers, convertToSHDFile } from "./DownloadPvSystFile";
import { ProjectInfo, VerdataSyncerType } from "src";


export class PvSystFileExporter implements FileExporter<CommonExportSettings> {

    constructor(
        readonly bim: Bim,
        readonly projectInfo: LazyVersioned<ProjectInfo | undefined>,
        readonly projectVerdataSyncer: VerdataSyncerType,
    ) {
    }

    initialSettings() {
        return {defaultValue: new CommonExportSettings()};
    }

    *startExport(settings: CommonExportSettings, context: FileExporterContext): Generator<Yield, ExportedFileDescription[]> {
        
        const name = getExportProjectName(this.projectVerdataSyncer, this.projectInfo.poll()) + "_Layout";
        const trackers = convertTrackers(this.bim, settings, context);
        const txt = convertToSHDFile(trackers, name);
        const encoded = new TextEncoder().encode(txt);
        

        return [{extension: 'SHD', name, file: encoded}];
    }

}
