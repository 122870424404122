import { NumberProperty, BooleanProperty } from "../../properties/PrimitiveProps";
import type { PropertyGroup } from "../../properties/PropertyGroup";
import type { UnitsMapper } from "../../UnitsMapper";
import type { Catalog } from "../Catalog";
import { CatalogItem } from "../CatalogItem";
import type { CatalogItemsUiLabels } from "../CatalogItemsUiLabels";
import { CatalogSource } from "../Source";

export const CutFillCatalogItemTypeIdentifier = 'cut_fill';
export const cutFillCatalogItemVolumeUnit = NumberProperty.new({
    value: 1,
    unit: 'yd3',
})

export interface CutFillCatalogItemProps extends PropertyGroup {
    price_per_volume: NumberProperty;
    is_fill: BooleanProperty;
}

export function registerCutFillCatalogItemUiLabels(
    labelsProvider: CatalogItemsUiLabels,
    unitsMapper: UnitsMapper
) {
    labelsProvider.register<CutFillCatalogItemProps>(
        CutFillCatalogItemTypeIdentifier,
        (cutFill) => {
            const price = cutFill.price_per_volume.valueUnitUiString(unitsMapper) +
                '/' + cutFillCatalogItemVolumeUnit.valueUnitUiString(unitsMapper);
            const title = cutFill.is_fill.value ? 'fill' : 'cut';
            return {
                group: [['Cut & Fill']],
                title,
                price
            };
        }
    );
}

export function importDefaultCutAndFill(catalog: Catalog) {
    let cutExist = false;
    let fillExist = false;
    const cutFillItems = catalog.catalogItems
        .filter(x => x.typeIdentifier === CutFillCatalogItemTypeIdentifier);
    for (const [_id, item] of cutFillItems) {
        const cutFill = item.as<CutFillCatalogItemProps>();
        if (cutFill.properties.is_fill.value) {
            fillExist = true;
        } else if (cutFill.properties.is_fill.value === false) {
            cutExist = true;
        }
    }

    // cut
    if (!cutExist) {
        const catalogItem = new CatalogItem<CutFillCatalogItemProps>(
            'cut',
            'cut_fill',
            {
                is_fill: BooleanProperty.new({
                    value: false,
                    isReadonly: true,
                }),
                price_per_volume: NumberProperty.new({
                    value: 0,
                    unit: 'usd',
                    description: 'cut&fill price per terrain volume unit',
                    range: [0, 100000],
                    step: 0.1,
                }),

            },
            new CatalogSource('default'),
        );
        catalog.catalogItems.allocate([[catalog.catalogItems.reserveNewId(), catalogItem]]);
    }


    // fill
    if (!fillExist) {
        const catalogItem = new CatalogItem<CutFillCatalogItemProps>(
            'fill',
            'cut_fill',
            {
                is_fill: BooleanProperty.new({
                    value: true,
                    isReadonly: true,
                }),
                price_per_volume: NumberProperty.new({
                    value: 0,
                    unit: 'usd',
                    description: 'cut&fill price per terrain volume unit',
                    range: [0, 100000],
                    step: 0.1,
                }),

            },
            new CatalogSource('default'),
        );
        catalog.catalogItems.allocate([[catalog.catalogItems.reserveNewId(), catalogItem]]);
    }

}

