// automatically generated by the FlatBuffers compiler, do not modify

import { PropertiesGroup } from './properties-group.js';
import { PropertiesGroupArray } from './properties-group-array.js';
import { Property } from './property.js';
import { PropertyArray } from './property-array.js';


export enum ConfigNode {
  NONE = 0,
  PropertiesGroup = 1,
  Property = 2,
  PropertyArray = 3,
  PropertiesGroupArray = 4
}

export function unionToConfigNode(
  type: ConfigNode,
  accessor: (obj:PropertiesGroup|PropertiesGroupArray|Property|PropertyArray) => PropertiesGroup|PropertiesGroupArray|Property|PropertyArray|null
): PropertiesGroup|PropertiesGroupArray|Property|PropertyArray|null {
  switch(ConfigNode[type]) {
    case 'NONE': return null; 
    case 'PropertiesGroup': return accessor(new PropertiesGroup())! as PropertiesGroup;
    case 'Property': return accessor(new Property())! as Property;
    case 'PropertyArray': return accessor(new PropertyArray())! as PropertyArray;
    case 'PropertiesGroupArray': return accessor(new PropertiesGroupArray())! as PropertiesGroupArray;
    default: return null;
  }
}

export function unionListToConfigNode(
  type: ConfigNode, 
  accessor: (index: number, obj:PropertiesGroup|PropertiesGroupArray|Property|PropertyArray) => PropertiesGroup|PropertiesGroupArray|Property|PropertyArray|null, 
  index: number
): PropertiesGroup|PropertiesGroupArray|Property|PropertyArray|null {
  switch(ConfigNode[type]) {
    case 'NONE': return null; 
    case 'PropertiesGroup': return accessor(index, new PropertiesGroup())! as PropertiesGroup;
    case 'Property': return accessor(index, new Property())! as Property;
    case 'PropertyArray': return accessor(index, new PropertyArray())! as PropertyArray;
    case 'PropertiesGroupArray': return accessor(index, new PropertiesGroupArray())! as PropertiesGroupArray;
    default: return null;
  }
}
