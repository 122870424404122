// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { BatchDescription } from '../wire/batch-description.js';
import { CollectionVersion } from '../wire/collection-version.js';


export class CollectionHistory {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):CollectionHistory {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsCollectionHistory(bb:flatbuffers.ByteBuffer, obj?:CollectionHistory):CollectionHistory {
  return (obj || new CollectionHistory()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsCollectionHistory(bb:flatbuffers.ByteBuffer, obj?:CollectionHistory):CollectionHistory {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new CollectionHistory()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

formatVersion():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

collectionIdent():string|null
collectionIdent(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
collectionIdent(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

allBatches(index: number, obj?:BatchDescription):BatchDescription|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new BatchDescription()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

allBatchesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

versions(index: number, obj?:CollectionVersion):CollectionVersion|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new CollectionVersion()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

versionsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startCollectionHistory(builder:flatbuffers.Builder) {
  builder.startObject(4);
}

static addFormatVersion(builder:flatbuffers.Builder, formatVersion:number) {
  builder.addFieldInt32(0, formatVersion, 0);
}

static addCollectionIdent(builder:flatbuffers.Builder, collectionIdentOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, collectionIdentOffset, 0);
}

static addAllBatches(builder:flatbuffers.Builder, allBatchesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, allBatchesOffset, 0);
}

static createAllBatchesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startAllBatchesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addVersions(builder:flatbuffers.Builder, versionsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, versionsOffset, 0);
}

static createVersionsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startVersionsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endCollectionHistory(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishCollectionHistoryBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset);
}

static finishSizePrefixedCollectionHistoryBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, undefined, true);
}

static createCollectionHistory(builder:flatbuffers.Builder, formatVersion:number, collectionIdentOffset:flatbuffers.Offset, allBatchesOffset:flatbuffers.Offset, versionsOffset:flatbuffers.Offset):flatbuffers.Offset {
  CollectionHistory.startCollectionHistory(builder);
  CollectionHistory.addFormatVersion(builder, formatVersion);
  CollectionHistory.addCollectionIdent(builder, collectionIdentOffset);
  CollectionHistory.addAllBatches(builder, allBatchesOffset);
  CollectionHistory.addVersions(builder, versionsOffset);
  return CollectionHistory.endCollectionHistory(builder);
}
}
