import type { IntersectionType } from './GeometryUtils';
import { GeometryUtils } from './GeometryUtils';
import type { GeometryGpuRepr } from './KrBufferGeometry';
import type { Plane } from 'math-ts';
import { Vector3 } from 'math-ts';
import { EngineBimGeometry } from "./EngineGeometry";
import type { PolylineGeometries} from 'bim-ts';
import { PolylineGeometry } from 'bim-ts';
import { GeometryGenerator } from './GeometryGenerator';
import { Success, type ScopedLogger} from 'engine-utils-ts';
import type { Result } from 'engine-utils-ts';
import { BimEngineGeometriesSyncBase } from '../resources/BimGeometriesSync';
import { EngineGeoType } from './AllEngineGeometries';


export class EngineGeoPolyline extends EngineBimGeometry<PolylineGeometry> {

    constructor(
        sourcePolyline: PolylineGeometry = new PolylineGeometry(),
    ) {
        super(sourcePolyline);
    }

	_calcGpuRepr(): GeometryGpuRepr {
        return GeometryGenerator.generatePolyline({radius: this.bimGeo.radius, points: Vector3.arrayFromFlatArray(this.bimGeo.points3d)});
	}

    *snappingEdges(): IterableIterator<[Vector3, Vector3]> {
        const points: [Vector3, Vector3] = [Vector3.zero(), Vector3.zero()];
        for (let i = 3; i < this.bimGeo.points3d.length; i += 3) {
            points[0].setFromArray(this.bimGeo.points3d, i - 3);
            points[1].setFromArray(this.bimGeo.points3d, i);
            yield points;
        }
    }
    intersectPlanes(planes: Plane[]): IntersectionType {
        return GeometryUtils.checkPlanesWithPolylinePointsIntersection(this.bimGeo.points3d, planes);
    }
}

export class EnginePolylinesGeosSynced extends BimEngineGeometriesSyncBase<EngineGeoPolyline, PolylineGeometry> {

    constructor(
        logger: ScopedLogger,
        bimGeos: PolylineGeometries,
    ) {
        super(
			bimGeos,
			{
				logger,
				identifier: 'engine-polylines',
				idsType: EngineGeoType.Polyline,
				T_Constructor: EngineGeoPolyline,
			}
		);
    }

    checkForErrors(t: EngineGeoPolyline): boolean {
        return !t.aabb().isEmpty() && t.bimGeo.points3d.length > 0;
    }

    convertFromBim(bimGeo: PolylineGeometry): Result<EngineGeoPolyline> {
		return new Success(new EngineGeoPolyline(bimGeo));
    }
}
