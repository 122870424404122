import { WorkerClassPassRegistry } from './workers/WorkerClassPassRegistry';

export type Result<R, F extends Failure = Failure> = Success<R, F> | F;
export type ResultAsync<R, F extends Failure = Failure> = Result<R, F> | InProgress<R>;

export function resultify<T>(obj: T | Result<T> | ResultAsync<T>): Result<T> {
        if (obj instanceof Success) {
            return obj;
        }
        if (obj instanceof Failure) {
            return  obj;
        }
        if (obj instanceof InProgress) {
            return new Failure({msg: obj.ident + 'async result is in progress'});
        }
        return new Success(obj);
}

export function isResult(obj: any): obj is Result<any> {
    return obj instanceof Success || obj instanceof Failure;
}

export function isResultAsync(obj: any): obj is ResultAsync<any> {
    return obj instanceof Success || obj instanceof Failure || obj instanceof InProgress;
}

export function getResultValueOrThrow<T>(res: ResultAsync<T>, errorMsg?: string): T {
    if (res instanceof Success) {
        return res.value;
    }
    if (res instanceof Failure) {
        throw new Error(errorMsg ?? res.errorMsg());
    }
    if (res instanceof InProgress) {
        throw new Error(errorMsg ?? `${res.ident} is in progress`);
    }
    throw new Error('unexpected result type: ' + res);
}

export function getResultValueOr<T>(res: ResultAsync<T>, defaultValue: T): T {
    if (res instanceof Success) {
        return res.value;
    }
    if (res instanceof Failure || res instanceof InProgress) {
        return defaultValue;
    }
    throw new Error('unexpected result type: ' + res);
}


export class Success<R, F = Failure> {
    constructor(
        public readonly value: R,
        public readonly warnings?: F[],
    ){
    }
}
WorkerClassPassRegistry.registerClass(Success);

export class Failure {

    public readonly msg?: string;
    public readonly uiMsg?: string;
    public readonly inner?: Failure[];
    public readonly exception?: Error;

    constructor( args: {
        msg?: string,
        uiMsg?: string,
        inner?: Failure[],
        exception?: Error,
    }) {
        this.msg = args.msg;
        this.uiMsg = args.uiMsg;
        this.inner = args.inner;
        this.exception = args.exception;
    }

    errorMsg(): string {
        return this.msg || this.uiMsg || this.exception?.message || '';
    }

    toString() {
        let resultStr = ``;
        if (this.msg) {
            resultStr += `errorMsg: ${this.msg}\n`;
        }
        if (this.exception) {
            resultStr += `exception: ${this.exception}\n`;
        }
        if (this.inner) {
            resultStr += `inner: ${this.inner.toString()}\n`;
        }
        if (this.uiMsg) {
            resultStr += `uiErrorMsg: ${this.uiMsg}\n`;
        }
        return resultStr;
    }

    asError(args: {msg?: string, uiMsg?: string}): Error {
        let failure: Failure = this;
        if (args.uiMsg || args.msg) {
            failure = new Failure({...args, inner: [this]});
        }
        return new Error(failure.errorMsg(), {cause: failure});
    }

    static fromException(e: Error) {
        return new Failure({msg: e.message, exception: e});
    }
}
WorkerClassPassRegistry.registerClass(Failure);

export class InProgress<R = any> {
    constructor(
        public readonly ident: string,
        public readonly waitingForDependencies?: InProgress[],
        public readonly lastSuccessful?: R,
    ) {
    }
}
