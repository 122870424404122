import type { Result} from "engine-utils-ts";
import { Success } from "engine-utils-ts";
import type { Bim, IdBimScene } from "src";
import { createTransformerObject2DWithChilds } from "./templates/transformer";
import type { Object2D } from "vector-graphic";
import { createTransformerDescriptionFromScene } from "./utils";

export function generateSLDForTransformer(
    bim: Bim,
    transformerId: IdBimScene
): Result<Object2D> {
    const description = createTransformerDescriptionFromScene(bim, transformerId);
    const obj = createTransformerObject2DWithChilds(description)
    return new Success(obj);
}
