// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { ImageFormat } from './image-format.js';


export class InlineImage implements flatbuffers.IUnpackableObject<InlineImageT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):InlineImage {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsInlineImage(bb:flatbuffers.ByteBuffer, obj?:InlineImage):InlineImage {
  return (obj || new InlineImage()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsInlineImage(bb:flatbuffers.ByteBuffer, obj?:InlineImage):InlineImage {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new InlineImage()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

width():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

height():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

format():ImageFormat {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : ImageFormat.RgbaByte;
}

rawData(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index) : 0;
}

rawDataLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

rawDataArray():Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? new Uint8Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

static startInlineImage(builder:flatbuffers.Builder) {
  builder.startObject(4);
}

static addWidth(builder:flatbuffers.Builder, width:number) {
  builder.addFieldInt32(0, width, 0);
}

static addHeight(builder:flatbuffers.Builder, height:number) {
  builder.addFieldInt32(1, height, 0);
}

static addFormat(builder:flatbuffers.Builder, format:ImageFormat) {
  builder.addFieldInt32(2, format, ImageFormat.RgbaByte);
}

static addRawData(builder:flatbuffers.Builder, rawDataOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, rawDataOffset, 0);
}

static createRawDataVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset {
  builder.startVector(1, data.length, 1);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt8(data[i]!);
  }
  return builder.endVector();
}

static startRawDataVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(1, numElems, 1);
}

static endInlineImage(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createInlineImage(builder:flatbuffers.Builder, width:number, height:number, format:ImageFormat, rawDataOffset:flatbuffers.Offset):flatbuffers.Offset {
  InlineImage.startInlineImage(builder);
  InlineImage.addWidth(builder, width);
  InlineImage.addHeight(builder, height);
  InlineImage.addFormat(builder, format);
  InlineImage.addRawData(builder, rawDataOffset);
  return InlineImage.endInlineImage(builder);
}

unpack(): InlineImageT {
  return new InlineImageT(
    this.width(),
    this.height(),
    this.format(),
    this.bb!.createScalarList<number>(this.rawData.bind(this), this.rawDataLength())
  );
}


unpackTo(_o: InlineImageT): void {
  _o.width = this.width();
  _o.height = this.height();
  _o.format = this.format();
  _o.rawData = this.bb!.createScalarList<number>(this.rawData.bind(this), this.rawDataLength());
}
}

export class InlineImageT implements flatbuffers.IGeneratedObject {
constructor(
  public width: number = 0,
  public height: number = 0,
  public format: ImageFormat = ImageFormat.RgbaByte,
  public rawData: (number)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const rawData = InlineImage.createRawDataVector(builder, this.rawData);

  return InlineImage.createInlineImage(builder,
    this.width,
    this.height,
    this.format,
    rawData
  );
}
}
