<script lang="ts">
    import { getContext } from "svelte";
    import NumberPropertyBase from "../../pui/custom-components/NumberPropertyWithOption/NumberPropertyBase.svelte";
    import flash from "../../pui/flash";
    import { VersionedStore } from "src/VersionedStore";
    import { UnitsMapper } from "bim-ts";
    import { PUI_PropertyNodeNumber } from "ui-bindings";
    import { valueUnitToUiValue } from "../PilePositions";

    export let numberProperty: PUI_PropertyNodeNumber;
    export let description: string | undefined;
    export let decimals: number = 3;
    export let readonly: boolean = false;

    let div: HTMLElement;

    const unitsMapper = getContext<VersionedStore<UnitsMapper>>("unitsMapper");

    let prevValue = numberProperty.value;
    $: if (prevValue != numberProperty.value) {
        prevValue = numberProperty.value;
        if (div) {
            flash(div);
        }
    }
</script>

<div class="ui-config-property property-row">
    <div class="property-value text-main-dark">
        {#if readonly}
            {@const valueUnit = valueUnitToUiValue({value: numberProperty.value ?? 0, unit: numberProperty.unit}, $unitsMapper, decimals)}
            <span class="mdc-typography--body1 readonly container">
                {valueUnit}
            </span>
        {:else}
            <div
            class="edit-field-container container"
            >
                <NumberPropertyBase
                    bind:sourceProperty={numberProperty}
                    unitsMapper={$unitsMapper}
                    {decimals}
                />
            </div>
        {/if}
        {#if description}
            <span bind:this={div} class="text-main-dark">{description}</span>
        {/if}
    </div>
</div>

<style lang="scss">
    .readonly {
        padding: 0 8px;
        height: 26px;
        display: flex;
        align-items: center;
    }
    .container {
        min-width: 50px;
        max-width: 65px;
    }
    .property-value {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
    }
</style>
