<script lang="ts">
    import { navigate } from "svelte-routing";
    import type { Project } from "../projects";
    import { getContext, onMount } from "svelte";
    import type { AuthHub } from "../auth";
    import ProjectTileButtons from "./ProjectTileButtons.svelte";
    import ProjectScreenshot from "./ProjectScreenshot.svelte";
    import type { Dashboard } from "./Dashboard";

    let auth = getContext<AuthHub>("authHub");
    const dashboard = getContext<Dashboard>("dashboard");

    export let project: Project;
    export let onRemove: (id: number) => void;

    $: isOwner = $auth.authData?.id === project.createBy;
    $: version = $dashboard.versions.get(project.id) || {};

    let cardElement: HTMLDivElement;

    onMount(() => {
        let observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    dashboard.getProjectVersion(project.id);
                }
            });
        });
        observer.observe(cardElement);
        return () => {
            observer.disconnect();
        };
    });
</script>

<div
    on:click={() => navigate(project.id.toString())}
    bind:this={cardElement}
    class="mdc-typography text-main-dark"
    style="
        height: 150px;
        background: #eaebed;
        padding-top: 10px;
        cursor: pointer;
        overflow: hidden;
        position: relative;
        z-index: 0;
    "
>
    <div class="screenshot">
        <ProjectScreenshot
            projectId={project.id}
            versionId={version.id}
            size={140}
        />
    </div>
    <div class="topbar">
        {#if isOwner}
            <ProjectTileButtons {project} openDialog={onRemove} />
        {/if}
    </div>
    <div class="mdc-typography--overline-small project-id">
        {project.id}
    </div>
    <p
        class="mdc-typography--subtitle1"
        style="
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            height: 22px;
            padding: 3px 8px;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0;
            overflow: hidden;
            word-break: break-all;
            background-color: rgba(221, 221, 221, 0.8);
        "
    >
        {project.name}
    </p>
</div>

<style>
    .topbar {
        position: absolute;
        /* pointer-events: none; */
        left: 0;
        right: 0;
        top: 0;
        height: 25px;
        background-color: rgba(232, 232, 232, 0.8);
        display: flex;
        justify-content: flex-end;
    }
    .project-id {
        position: absolute;
        left: 9px;
        top: 0px;
        line-height: 25px;
    }
    .screenshot {
        display: flex;
        justify-content: center;
    }
</style>
