import type { UnitsMapper } from '../UnitsMapper';
import type { PropertyBase } from './Props';


export interface BasicPropertyValue {
    readonly value: string|number|boolean|number[]|string[],
    readonly unit?: string,
}


export enum PropertyViewBasicTypes {
    None = 0,
    Numeric = 1,
    String = 2,
    Boolean = 4,
    NumericArray = 8,
    StringArray = 16,
}


export interface BasicViewFormatters {
    readonly unitsMapper: UnitsMapper,
}

export type PropertyToBasicTypesViewTransformer<P extends PropertyBase> = {
    basicTypes: PropertyViewBasicTypes | { [key: string]: PropertyViewBasicTypes },
    toBasicValues(formatters: BasicViewFormatters, property: P, key?: string): BasicPropertyValue|undefined; 
}


export function getPropertyAllBasicValues<P extends PropertyBase>(
    formatters: BasicViewFormatters,
    transformer: PropertyToBasicTypesViewTransformer<P>,
    property: P,
): Map<string, BasicPropertyValue> | BasicPropertyValue | undefined {
    // TODO: add type checking
    if (typeof transformer.basicTypes === 'number') {
        return transformer.toBasicValues(formatters, property);
    }
    const result = new Map<string, BasicPropertyValue>();
    for (const key in transformer.basicTypes) {
        const value = transformer.toBasicValues(formatters, property, key);
        if (value) {
            result.set(key, value);
        }
    }
    return result;
}

export function basicPropertyValueToString(pv: BasicPropertyValue, formatters: BasicViewFormatters): string {
    let res = '';
    if (Array.isArray(pv.value)) {
        for (let i = 0; i < pv.value.length; ++i) {
            let v = pv.value[i];
            res += primitiveValueToString(v, pv.unit, formatters);
            if (i !== pv.value.length - 1) {
                res += ' ';
            }
        }
    } else {
        res += primitiveValueToString(pv.value, pv.unit, formatters);
        if (pv.unit) {
            res += ` ${pv.unit}`;
        }
    }
    return res;
}

function primitiveValueToString(value: number|string|boolean, unit: string|undefined, formatters: BasicViewFormatters): string {
    if (typeof value === 'number') {
        return numericValueToString(value, unit, formatters);
    } else {
        return `${value}`;
    }
}

function numericValueToString(value: number, unit: string|undefined, formatters: BasicViewFormatters): string {

    if (unit) {
        const mapped = formatters.unitsMapper.mapToConfigured({value, unit});
        unit = mapped.unit;
        value = mapped.value;
    }
    let res: string;
    if (Number.isInteger(value)) {
        res = `${value}`;
    } else {
        res = `${value.toFixed(2)}`;
    }
    if (unit) {
        res += `${unit}`;
    }
    return res;
}


export class BasicPropertyDescription {
    
    readonly label: string;
    
    constructor(
        readonly mergedPath: string,    
        readonly path: (string|number)[],
        readonly basicTypes: PropertyViewBasicTypes,
        readonly typeIdentsFoundIn: string[],
        // readonly units: string[],
        // readonly unitsDimensions: UnitDimension[],
    ) {
        let pathToUseAsLabel = path;
        if (path[0] === 'props') {
            pathToUseAsLabel = path.slice(1);
        } else if (path[0] === 'properties') {
            pathToUseAsLabel = [" ", ...path.slice(1)];
        }
        this.label = pathToUseAsLabel.join(' | ');
    }

    isFoundInTypeIdent(typeIdent: string): boolean {
        return this.typeIdentsFoundIn.includes(typeIdent);
    }
    isFoundInTypeIdends(typeIdents: string[]): boolean {
        for (const typeIdent of typeIdents) {
            if (this.typeIdentsFoundIn.includes(typeIdent)) {
                return true;
            }
        }
        return false;
    }

    static new(
        path: (string|number)[],
        basicTypes: PropertyViewBasicTypes,
        typeIdentsFoundIn: string[],
    ) {
        return new BasicPropertyDescription(
            path.join(' | '),
            path,
            basicTypes,
            typeIdentsFoundIn,
        );
    }
}

