<script lang="ts">
    import { NumberPropertyWithOptions, UnitsMapper } from "bim-ts";

    import { SelectLayoutGenerationMode } from "layout-service";
    import {
        PUI_CustomPropertyNode,
        PUI_PropertyNodeNumber,
        PUI_PropertyNodeSwitcher,
        UiBindings,
    } from "ui-bindings";
    import Divider from "../Divider.svelte";
    import RadioButtonOption from "../../../libui/radio-buttons/RadioButtonOption.svelte";
    import { CheckboxStandard } from "../../../libui/checkbox";
    import { Immer } from "engine-utils-ts";
    import NumberSpinner from "../NumberPropertyWithOption/NumberPropertyBase.svelte";
    import SelectPowerMode from "./SelectPowerMode.svelte";
    import { PowerModeType } from "./SelectPowerModeTypes";
    import { getContext } from "svelte";
    import { IconComponent } from "src/libui/icon";
    import { Vector2 } from "math-ts";

    export let property: PUI_CustomPropertyNode<SelectLayoutGenerationMode>;
    export let unitsMapper: UnitsMapper;

    const uiBindings: UiBindings = getContext("uiBindings");

    $: dcCapacityProperty = property.value.settings.capacity.total_dc_power;
    $: maxRowToSpace = property.value.settings.spacing.row_to_row_space;

    $: numberBlocksProp = property.value.settings.capacity.number_of_blocks;

    $: isReadonlyCombineBlocks = maxRowToSpace.selectedOption === "find max";
    $: isReadOnlySetBlocks =
        isReadonlyCombineBlocks ||
        numberBlocksProp.selectedOption === "ignore" ||
        numberBlocksProp.selectedOption === "mixed" ||
        areEquipmentRoadsExistedMode;

    $: areEquipmentRoadsExistedMode =
        property.value.settings.roads.equipment_roads_options.value ===
        "Select existing";

    $: numberBlocksPropUi = new PUI_PropertyNodeNumber({
        name: "set number blocks",
        value: numberBlocksProp.value,
        unit: numberBlocksProp.unit,
        minMax: numberBlocksProp.range,
        step: numberBlocksProp.step,
        onChange: (newVal) => {
            patchNumberBlocks(newVal, numberBlocksProp.selectedOption);
        },
        readonly:
            numberBlocksProp.selectedOption !== "set" ||
            areEquipmentRoadsExistedMode,
    });

    function patchNumberBlocks(numberBlocks: number, newOption: string) {
        const updated = Immer.produce(property.value.settings, (draft) => {
            draft.capacity.number_of_blocks = NumberPropertyWithOptions.new({
                ...numberBlocksProp,
                value: numberBlocks,
                selectedOption: newOption,
            });
            const updatedProp = draft.capacity.number_of_blocks;
            if (
                updatedProp.selectedOption === "set" ||
                updatedProp.selectedOption === "auto"
            ) {
                const value = draft.capacity.number_of_blocks.value;
                for (const block of draft.electrical.blocks_equipment) {
                    block.number_of_inverters = NumberPropertyWithOptions.new({
                        ...block.number_of_inverters,
                        value:
                            updatedProp.selectedOption === "set"
                                ? value
                                : block.number_of_inverters.value,
                        selectedOption: updatedProp.selectedOption,
                    });
                }
            }
        });

        property.value.patchConfig(updated);
    }

    $: dcCapacityPui = new PUI_PropertyNodeSwitcher({
        name: "target dc",
        value: dcCapacityProperty.selectedOption,
        options: [],
        onChange: (v) => {
            const updated = Immer.produce(property.value.settings, (draft) => {
                draft.capacity.total_dc_power =
                    dcCapacityProperty.withDifferentOption(v as string);
                if (v === "find max") {
                    draft.spacing.row_to_row_space =
                        draft.spacing.row_to_row_space.withDifferentOption(
                            "set",
                        );
                }
            });
            property.value.patchConfig(updated);
        },
    });

    $: options = [
        new PowerModeType({ value: "find max", label: "Maximum DC capacity" }),
        new PowerModeType({
            label: "Target DC capacity",
            value: "target",
            disabled: areEquipmentRoadsExistedMode,
            option: {
                label: "Maximise row to row space",
                value: maxRowToSpace.selectedOption === "find max",
                onClick: (v) => {
                    const updated = Immer.produce(
                        property.value.settings,
                        (draft) => {
                            draft.spacing.row_to_row_space =
                                maxRowToSpace.withDifferentOption(
                                    v ? "find max" : "set",
                                );
                        },
                    );
                    property.value.patchConfig(updated);
                },
                disabled: !(
                    dcCapacityProperty.selectedOption === "target" &&
                    numberBlocksProp.selectedOption === "ignore"
                ),
            },
            inputValue:
                dcCapacityProperty.selectedOption === "target"
                    ? new PUI_PropertyNodeNumber({
                          name: "set target power dc",
                          value: dcCapacityProperty.value,
                          unit: dcCapacityProperty.unit,
                          minMax: dcCapacityProperty.range,
                          step: dcCapacityProperty.step,
                          onChange: (newVal) => {
                              const updated = Immer.produce(
                                  property.value.settings,
                                  (draft) => {
                                      draft.capacity.total_dc_power =
                                          dcCapacityProperty.withDifferentValue(
                                              newVal,
                                          );
                                  },
                              );
                              property.value.patchConfig(updated);
                          },
                          readonly:
                              dcCapacityProperty.selectedOption !== "target",
                      })
                    : undefined,
        }),
    ];

    $: isReadonlySetNumberBlocks = numberBlocksProp.selectedOption !== "set";

    $: areMultipleBlocksEquipment =
        property.value.settings.electrical.blocks_equipment.length > 1;

    let errorMessage: string | null = null;
    $: if (
        property.value.settings.spacing.row_to_row_space.selectedOption ===
        "find max"
    ) {
        errorMessage =
            "Option to generate layout with the Maximised row to row space and solar arrays blocking is not available. Once layout is generated combine solar arrays into blocks using the Layout augmentation.";
    } else if (areEquipmentRoadsExistedMode) {
        errorMessage = `Options to generate layout with the target DC capacity or specific number of blocks are not available with the existing roads selected for layout generation. 
                        <br/>
                        To set number of blocks or find the maximum possible row-to-row offset, please select the "Generate Equipment Roads" option in the <br/>
                        `;
    } else if (areMultipleBlocksEquipment) {
        errorMessage =
            "Multiple block equipment sets are selected. Please choose the number of blocks for each set in the ";
    } else {
        errorMessage = null;
    }

    $: colorize = property.value.settings.electrical.colorize;
</script>

<div class="root">
    <div class="ui-config-property property-row">
        <div class="property-value">
            <div class="switcher-property">
                <SelectPowerMode
                    groupName={dcCapacityPui.name}
                    {options}
                    bind:value={dcCapacityPui.value}
                    {unitsMapper}
                />
            </div>
        </div>
    </div>
    <div class="property-view prop-offset">
        <span class="checkbox">
            <CheckboxStandard
                value={numberBlocksProp.selectedOption !== "ignore"}
                readonly={isReadonlyCombineBlocks}
                onChange={(newVal) => {
                    const genMode =
                        numberBlocksProp.selectedOption === "mixed"
                            ? "mixed"
                            : "auto";
                    patchNumberBlocks(
                        numberBlocksProp.value,
                        newVal ? genMode : "ignore",
                    );
                }}
            />
        </span>
        <span
            class="mdc-typography--body1"
            class:text-main-dark={!isReadonlyCombineBlocks}
            class:text-main-medium={isReadonlyCombineBlocks}
        >
            {"Combine solar arrays into blocks"}
        </span>
    </div>
    <div class="property-view prop-offset">
        <div class="ui-config-property property-row bool-prop">
            <div class="property-value property-order">
                <RadioButtonOption
                    name={"Set number of blocks"}
                    value={numberBlocksProp.selectedOption === "set"}
                    readonly={isReadOnlySetBlocks}
                    onClickCheckbox={(v) => {
                        patchNumberBlocks(
                            numberBlocksProp.value,
                            v ? "set" : "auto",
                        );
                    }}
                />
                {#if numberBlocksProp.selectedOption === "set"}
                    <div
                        class="input-value text-main-dark number-value"
                        class:read-only={isReadonlySetNumberBlocks}
                        class:edit-field-container={!isReadonlySetNumberBlocks}
                    >
                        <NumberSpinner
                            bind:sourceProperty={numberBlocksPropUi}
                            {unitsMapper}
                            decimals={numberBlocksProp.decimals}
                            formatter={(v) => `${v} blocks`}
                        />
                    </div>
                {/if}
            </div>
        </div>
    </div>
    <div class="property-view prop-offset">
        <div class="ui-config-property property-row bool-prop">
            <div class="property-value property-order">
                <RadioButtonOption
                    name={"Colorize blocks"}
                    value={colorize.value}
                    readonly={colorize.isReadonly}
                    onClickCheckbox={(v) => {
                        const updated = Immer.produce(property.value.settings, (draft) => {
                            draft.electrical.colorize = colorize.withDifferentValue(v);
                        });
                        property.value.patchConfig(updated);
                    }}
                />
            </div>
        </div>
    </div>
    <Divider />
    {#if errorMessage}
        <div class="ui-config-property property-row">
            <div class="property-name text-main-medium">
                <div class="property-name-label mdc-typography--body1">
                    {@html errorMessage}
                    {#if areEquipmentRoadsExistedMode}
                        <span
                            class="open-window-button"
                            on:click={(e) => {
                                const contextMenuConfig =
                                    property.value.openRoadsSettings(
                                        new Vector2(e.x, e.y),
                                    );
                                uiBindings.addContextMenuView(
                                    contextMenuConfig,
                                );
                            }}
                        >
                            <span>Roads settings</span>
                            <IconComponent name="OpenWindow" />
                        </span>
                    {:else if areMultipleBlocksEquipment}
                        <span
                            class="open-window-button"
                            on:click={(e) => {
                                const contextMenuConfig =
                                    property.value.openBlocksEquipmentSettings(
                                        new Vector2(e.x, e.y),
                                    );
                                uiBindings.addContextMenuView(
                                    contextMenuConfig,
                                );
                            }}
                        >
                            <span>Block equipment settings</span>
                            <IconComponent name="OpenWindow" />
                        </span>
                    {/if}
                </div>
            </div>
        </div>
    {/if}
</div>

<style lang="scss">
    .root {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .number-value {
        width: 185px;
    }
    .property-order {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .input-value {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: left;
    }
    .prop-offset {
        display: flex;
        gap: 4px;
        padding-left: 10px;
    }
    .open-window-button {
        color: var(--color-accent);
        font-weight: 700;
        cursor: pointer;
        vertical-align: bottom;
        span {
            vertical-align: middle;
        }
        :global(svg) {
            vertical-align: middle;
            margin-left: 2px;
        }
    }
</style>
