import type { Bim} from '..';
import { EnergyStageSettingsConfig } from '..';
import { registerInverterEnergyYieldSolver } from './EnergyYieldInverter';
import { registerStringEnergySolvers } from './EnergyYieldPerString';
import { registerSiteEnergyPropsGroupGlobal } from './EnergyYieldSiteProps';
import { registerEnergyYieldSubstationProps } from './EnergyYieldSubstation';
import { EnergyCalculationsEnabled } from './EnergyCalculationsEnabled';


export function registerEnergyRuntimes(
    bim: Bim
) {
    EnergyCalculationsEnabled.register(bim);
    EnergyStageSettingsConfig.register(bim);

    registerStringEnergySolvers(bim);
    registerInverterEnergyYieldSolver(bim);
    registerEnergyYieldSubstationProps(bim);
    
    registerSiteEnergyPropsGroupGlobal(bim);
}



