
import type { Vector3 } from 'math-ts';
import { KeyModifiersFlags } from 'ui-bindings';
import type { CameraRotationPointGizmo } from '../gizmos/CameraRotationPointGizmo';

import type { SceneInt } from '../scene/SceneRaycaster';
import { MouseButton } from './InputController';
import type { MouseClickConsumer, MouseDragConsumer, MouseEventData, MouseGestureConsumer
} from './MouseGesturesBase';
import {
    GesturesButtons
} from './MouseGesturesBase';
import type { MovementControls } from './MovementControls';

export interface S {
}

export class MovementMouseConsumer implements MouseGestureConsumer {

    readonly clickConsumers: MouseClickConsumer[] = [];
    readonly dragConsumers: MouseDragConsumer<any>[] = [];

    readonly controls: MovementControls;

    readonly cameraRotationPoint: CameraRotationPointGizmo;

    constructor(controls: MovementControls, cameraRotationPoint: CameraRotationPointGizmo, getRotationCenter: () => Vector3 | undefined) {
        this.controls = controls;
        this.cameraRotationPoint = cameraRotationPoint;

        this.dragConsumers.push(
            new MovementDragConsumer(
                this.controls,
                GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.None),
                (int, me) => { this.controls.stopFocusing(); return {} },
                (s, me) => {
                    cameraRotationPoint.visible = true;
                    this.controls.handleMouseMoveRotate(me.pos.delta, getRotationCenter());
                    return true
                },
                'grabbing',
                (s:S ) => this.controls.isFirstPerson(),
                () => cameraRotationPoint.visible = false
            ),
            new MovementDragConsumer(
                this.controls,
                GesturesButtons.newShared(MouseButton.Middle, KeyModifiersFlags.Shift),
                (int, me) => { this.controls.stopFocusing(); return {} },
                (s, me) => {
                    cameraRotationPoint.visible = true;
                    this.controls.handleMouseMoveRotate(me.pos.delta, getRotationCenter());
                    return true
                },
                'grabbing',
                (s:S ) => this.controls.isFirstPerson(),
                () => cameraRotationPoint.visible = false
            ),
        );

        this.dragConsumers.push(
            new MovementDragConsumer(
                this.controls,
                GesturesButtons.newShared(MouseButton.Right, KeyModifiersFlags.None),
                (int, me) => { this.controls.stopFocusing(); return {} },
                (s, me) => { this.controls.handleMouseMovePan(me.pos.deltaNormalized); return true },
                'all-scroll',
            ),
            new MovementDragConsumer(
                this.controls,
                GesturesButtons.newShared(MouseButton.Middle, KeyModifiersFlags.None),
                (int, me) => { this.controls.stopFocusing(); return {} },
                (s, me) => { this.controls.handleMouseMovePan(me.pos.deltaNormalized); return true },
                'all-scroll',
            )
        );

        this.dragConsumers.push(
            new MovementDragConsumer(
                this.controls,
                GesturesButtons.newShared(MouseButton.Middle, KeyModifiersFlags.None),
                (int, me) => { this.controls.stopFocusing(); return {} },
                (s, me) => { this.controls.handleMouseMoveDolly(me.pos.deltaNormalized); return true },
                'ns-resize',
            )
        );
    }

    isEnabled(): boolean {
        return true;
    }
}

class MovementDragConsumer implements MouseDragConsumer<S> {
    buttons: GesturesButtons;
    handleDrag: (s: S, me: MouseEventData) => boolean;
    controls: MovementControls;
    cursorDragStyle: () => string;
    lockPointerOnDrag?: (s: S) => boolean;

    onStop?: () => void;
    tryStartDrag: (med: MouseEventData, mouseDownInfo: {sceneInt: SceneInt, med: MouseEventData}, ) => S | null;

    constructor(
        controls: MovementControls,
        buttons: GesturesButtons,
        tryStartDrag: (med: MouseEventData, mouseDownInfo: {sceneInt: SceneInt, med: MouseEventData}, ) => S | null,
        onDrag: (s: S, me: MouseEventData) => boolean,
        cursorDragStyle: string,
        lockPointerOnDrag?: (s:S) => boolean,
        onStop?: () => void
    ) {
        this.controls = controls;
        this.buttons = buttons;
        this.tryStartDrag = tryStartDrag;
        this.handleDrag = onDrag;
        this.cursorDragStyle = () => cursorDragStyle;
        this.lockPointerOnDrag = lockPointerOnDrag;
        this.onStop = onStop;
    }

    onButtonDown() {
        return true;
    }

    sceneRaycastTypeguard(closest: SceneInt): boolean {
        return true;
    }

    onButtonUp(s: S, me: MouseEventData): void {
        this.controls.stopFocusing();
    }

    stop(): void {
        if (this.onStop !== undefined) {
            this.onStop();
        }
    }

}

