<script lang="ts">
    import type { InvertersGroup } from "layout-service";

    export let minMax: [min: number, max: number];
    export let inverterGroups: InvertersGroup[] = [];
    export let onClick: ((pos: { x: number; y: number }) => void) | undefined;

    $: allValues = [] as [number, [min: number, max: number]][];
    $: minValue = Number.MAX_VALUE;
    $: maxValue = Number.MIN_VALUE;

    $: {
        const newValues: [number, [min: number, max: number]][] = [];
        let min = Number.MAX_VALUE;
        let max = Number.MIN_VALUE;
        for (const group of inverterGroups) {
            for (const inv of group.inverters) {
                const value = inv.dcRatio;
                min = Math.min(min, value);
                max = Math.max(max, value);
                newValues.push([value, group.minMax]);
            }
        }
        allValues = newValues;
        minValue = min;
        maxValue = max;
        // console.log(inverterGroups, allValues);
    }



    let width = 0;
    $: dividerOffset = 4;
    $: outOfRangeOffset = 2;


    function calcTickPosition(
        totalWidth: number,
        value: number,
        [min, max]: [number, number]
    ) {
        const tickOffset = (totalWidth - dividerOffset * 2) * Math.abs((value - min) / (max - min)) + dividerOffset;
        return tickOffset;
    }
    function handleClick(pos: { x: number; y: number }) {
        if (onClick) {
            onClick(pos);
        }
    }
</script>

<div class="container" bind:offsetWidth={width} on:click={handleClick}>
    {#if minValue < minMax[0]}
        <div
            class="tick out-range"
            style="--position: {outOfRangeOffset}px;"
        />
    {/if}
    <div class="divider" style="--position: {dividerOffset}px;" />
    {#each allValues as [value, localMinMax]}
        {#if value >= minMax[0] && value <= minMax[1]}
            <div
                class="tick"
                class:out-range={value < localMinMax[0] ||
                    value > localMinMax[1]}
                style="--position: {calcTickPosition(
                    width,
                    value,
                    minMax
                )}px;"
            />
        {/if}
    {/each}


    <div class="divider" style="--position: {width - dividerOffset}px;" />
    {#if maxValue > minMax[1]}
        <div class="tick out-range" style="--position: {width - outOfRangeOffset}px;" />
    {/if}
</div>

<style lang="scss">
    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 32px;
        height: 100%;
        width: 100%;
        cursor: pointer;
        position: relative;
    }

    .divider {
        position: absolute;
        height: 20px;
        width: 1px;
        border-radius: 1px;
        background: var(--color-divider);
        transform: translateX(var(--position));
    }

    .tick {
        position: absolute;
        width: 1px;
        height: 16px;
        background: var(--color-main-light);
        border-radius: 1px;
        transform: translateX(var(--position));
    }

    .out-range {
        background: var(--color-danger);
    }
</style>
