import type { Bim, IdBimScene, SceneInstance, SceneInstancePatch,  TrackerPositionsConfig} from "bim-ts";
import { PilesConfigsPerWindPosition, TrackerWindPosition, URLS } from "bim-ts";
import { BimProperty, FixedTiltTypeIdent, producePropsPatch, StringProperty, TrackerPositionType, TrackerTypeIdent } from "bim-ts";
import type { LongTask, TasksRunner } from "engine-utils-ts";
import { DefaultRgbaPalette, EnumUtils, IterUtils, RgbaPalette, ScopedLogger, Yield } from "engine-utils-ts";
import { Vector2 } from "math-ts";
import type { UiBindings } from "ui-bindings";
import { NotificationDescription, NotificationType } from "ui-bindings";
import { notificationSource } from '../Notifications';
import type { Tracker } from "./solver";
import { Label, solve } from "./solver";
import { AnyTrackerProps } from 'bim-ts';

export interface TrackerPositionInput {
    trackerIds: IdBimScene[];
    gapXMeter: number;
    offsetXMeter: number;
    gapYMeter: number;
    offsetYMeter: number;
    exterior_arrays: boolean,
    edge_arrays: boolean,
    edge_top_bot_arrays: boolean,
    colorize: boolean;
}
export const TrackerTypes = [TrackerTypeIdent, FixedTiltTypeIdent, 'any-tracker'];
const PositionPropertyLegacyPath = ["position", "load_wind_position"];

export async function runTrackerWindPositionSolver(
    input: Readonly<TrackerPositionInput>,
    taskRunner: TasksRunner,
    uiBindings: UiBindings,
    bim: Bim,
) {
    const logger = new ScopedLogger('tracker-positions');

    const TIMEOUT = 100_000;
    try {
        const task: LongTask<any> = taskRunner.newLongTask<any>({
            defaultGenerator: _setTrackerWindPositions(
                input, bim, logger
            ),
            taskTimeoutMs: TIMEOUT,
        });

        uiBindings.addNotification(
            NotificationDescription.newWithTask({
                source: notificationSource,
                key: 'assignTrackerPosition',
                taskDescription: { task },
                type: NotificationType.Info,
                addToNotificationsLog: true
            })
        );
        await task.asPromise();
    } catch (e) {
        console.error(e);
    }
}

export function* setTrackerWindPositions({
    bim,
    logger,
}: {
    bim: Bim;
    logger: ScopedLogger;
}) {
    const config = bim.configs.peekSingleton(TrackerPositionType);
    if (!config) {
        return;
    }
    const props = config.get<TrackerPositionsConfig>();
    const trackers = bim.instances
        .peekByTypeIdents(TrackerTypes)
        .map((x) => x[0]);

    const input: TrackerPositionInput = {
        trackerIds: trackers,
        gapXMeter: props.parameters.gapX.as("m"),
        offsetXMeter: props.parameters.offsetX.as('m'),
        gapYMeter: props.parameters.gapY.as("m"),
        offsetYMeter: props.parameters.offsetY.as('m'),
        exterior_arrays: props.settings.exterior_arrays.value,
        edge_arrays: props.settings.edge_arrays.value,
        edge_top_bot_arrays: props.settings.edge_top_bot_arrays.value,
        colorize: false,
    };
    yield* _setTrackerWindPositions(input, bim, logger);
}

function* _setTrackerWindPositions(
    input: TrackerPositionInput,
    bim: Bim,
    logger: ScopedLogger
) {
    const trackers: [IdBimScene, Tracker][] = [];
    for (const [id, inst] of bim.instances.peekByIds(input.trackerIds)) {
        if(!TrackerTypes.includes(inst.type_identifier)){
            continue;
        }
        // if(!inst.representation){
        //     logger.error(`Tracker ${id} has no representation`, inst);
        //     continue;
        // }

        const tracker = getTrackerOfInstance(inst);
        trackers.push([id, tracker]);
    }
    yield Yield.NextFrame;
    const args = {
        trackers: trackers.map(tr => tr[1]),
        gapXMeter: input.gapXMeter,
        offsetXMeter: input.offsetXMeter,
        gapYMeter: input.gapYMeter,
        offsetYMeter: input.offsetYMeter,
    }
    if (!URLS.isProduction()) {
        console.log("tracker wind position request", JSON.stringify(args));
    }
    const positions = solve(
        args.trackers,
        args.gapXMeter,
        args.offsetXMeter,
        args.gapYMeter,
        args.offsetYMeter,
    );
    yield Yield.NextFrame;

    const palette = new RgbaPalette(DefaultRgbaPalette.slice());

    const patches: [IdBimScene, SceneInstancePatch][] = [];
    const colorMap = new Map<TrackerWindPosition, number>();
    const ALLOCATION_SIZE = 10_000;
    for (let i = 0; i < positions.length; i++) {
        const [id] = trackers[i];
        const instance = bim.instances.peekById(id);
        if (!instance) {
            continue;
        }
        const pos = positions[i];
        let positionType = positionConverter(pos);
        positionType = remapWindPositionIfDisabled(positionType, input);
        let colorIndex = colorMap.get(positionType);
        if (colorIndex === undefined) {
            colorIndex = colorMap.size;
            colorMap.set(positionType, colorIndex);
        }
        const colorTint = input.colorize ? palette.get(colorIndex) : undefined;

        let patch: SceneInstancePatch;
        if (instance.props instanceof AnyTrackerProps) {
            const propsPatch = producePropsPatch(instance.props, props => props.position.wind_load_position = StringProperty.new({value: positionType}));
            if (!propsPatch) {
                continue;
            }
            patch = {
                props: propsPatch,
                colorTint,
            };
        } else {
            patch = {
                properties: [
                    [
                        BimProperty.MergedPath(["position", "load_wind_position"]),
                        {
                            path:PositionPropertyLegacyPath,
                            value: positionType,
                            discrete_variants: EnumUtils.getAllEnumConstsStringValues(TrackerWindPosition), 
                        },
                    ],
                ],
                colorTint,
            };
        }

        patches.push([
            id,
            patch,
        ]);
    }
    yield Yield.NextFrame;

    for (const chunk of IterUtils.splitArrayIntoChunks(patches, ALLOCATION_SIZE)) {
        bim.instances.applyPatches(chunk);
        yield Yield.Asap;
    }
}

function positionConverter(positionEnum: Label):TrackerWindPosition{
    switch (positionEnum) {
        case Label.Edge:
            return TrackerWindPosition.Edge;
        case Label.EdgeBottom:
            return TrackerWindPosition.EdgeBot;
        case Label.EdgeTop:
            return TrackerWindPosition.EdgeTop;
        case Label.Interior:
            return TrackerWindPosition.Interior;
        case Label.Exterior:
        case Label.ExteriorLeft:
        case Label.ExteriorRight:
            return TrackerWindPosition.Exterior;
        default:
            throw Error(`Undefined tracker position: ${positionEnum}`);
    }
}
function remapWindPositionIfDisabled(pos: TrackerWindPosition, input: TrackerPositionInput){
    let converted = pos;
    if((converted === TrackerWindPosition.EdgeBot || converted === TrackerWindPosition.EdgeTop) && !input.edge_top_bot_arrays){
        converted = tryToRemapWindPosition(converted);
    }
    if(converted === TrackerWindPosition.Edge && !input.edge_arrays){
        converted = tryToRemapWindPosition(converted);
    }
    if(converted === TrackerWindPosition.Exterior && !input.exterior_arrays){
        converted = tryToRemapWindPosition(converted);
    }

    return converted;
}

function tryToRemapWindPosition(pos: TrackerWindPosition){
    const nextPos = PilesConfigsPerWindPosition._nextToTry(pos);
    if(nextPos != null){
        return nextPos;
    }
    return pos;
}


function getTrackerOfInstance(instance: Readonly<SceneInstance>): Tracker {
    const trackerLine: [Vector2, Vector2] = [new Vector2(), new Vector2()];
    let tracker_width = 0;

    if (instance.props instanceof AnyTrackerProps) {

        const length = instance.props.tracker_frame.dimensions.length!.as('m');
        tracker_width = instance.props.tracker_frame.dimensions.max_width!.as('m');
        
        trackerLine[0].y = -length / 2;
        trackerLine[1].y = length / 2;

    } else if (instance.type_identifier === "fixed-tilt") {
        const length = instance.properties.get("dimensions | length")?.as('m')!;
        tracker_width = instance.properties.get("dimensions | width")?.as('m')!;

        trackerLine[0].x = -length / 2;
        trackerLine[1].x = length / 2;
    } else {
        const length = instance.properties.get("tracker-frame | dimensions | length")?.as('m')!;
        tracker_width = instance.properties.get("tracker-frame | dimensions | max_width")?.as('m')!;
        
        trackerLine[0].y = -length / 2;
        trackerLine[1].y = length / 2;
    }

    trackerLine[0].applyMatrix4(instance.worldMatrix);
    trackerLine[1].applyMatrix4(instance.worldMatrix);

    return {
        fst: trackerLine[0],
        snd: trackerLine[1],
        w: tracker_width,
    };
}

export function colorizeTrackersByWindPosition(ids: Iterable<IdBimScene>, bim: Bim){
    const patches: [IdBimScene, SceneInstancePatch][] = [];
    const colorMap = new Map<string, number>();
    const palette = new RgbaPalette(DefaultRgbaPalette.slice());
    for (const id of ids) {
        const inst = bim.instances.peekById(id);
        if(inst && TrackerTypes.includes(inst.type_identifier)){
            let position: string|undefined;
            if (inst.props instanceof AnyTrackerProps) {
                position = inst.props.position.wind_load_position?.value;
            } else {
                position = inst.properties.get(BimProperty.MergedPath(PositionPropertyLegacyPath))?.asText();
            }
            if (!position) {
                continue;
            }
            let colorIndex = colorMap.get(position);
            if (colorIndex === undefined) {
                colorIndex = colorMap.size;
                colorMap.set(position, colorIndex);
            }
            patches.push([
                id,
                {
                    colorTint: palette.get(colorIndex),
                },
            ]);
        }
    }

    bim.instances.applyPatches(patches);
}
