import { LegacyLogger } from 'engine-utils-ts';
import type { index } from '../utils/Utils';

/*
inspired by https://floooh.github.io/2018/06/17/handles-vs-pointers.html
handle stores index along with unique pattern to access data in centralized systems
unique pattern in this implementation is actually generational index
that is incremented each time new handle to the same index is created 
*/

export const enum Handle { }; // hack to forbid implicit number and other handle types casts

// 31 bits total
export const HandleIndexMask			= 0x01FFFFFF; // 25 bits for handle
export const HandleEnabledMask 			= 0x02000000; // 1 bit for enabled/disabled
export const HandleGenerationMask 		= 0x7C000000; // 5 bits for generation (32 generations)

export function indexFromHandle<TEnum extends number>(handle: TEnum): index {
	return handle & HandleIndexMask;
}

export function createHandle(index: number): Handle {
	if (index > HandleIndexMask) {
		throw new Error('out of bounds index, index of this size is not allowed');
	}
	return nextGenHandle(index as Handle);
}

export function nextGenHandle(prevHandle: Handle): Handle {
	LegacyLogger.assert((prevHandle & HandleEnabledMask) === 0, 'next-gen handle should be created from disabled one');

	const index = prevHandle & HandleIndexMask;
	const gen = (prevHandle & HandleGenerationMask) >> 26;
	const newGen = gen + 1;

	return <Handle>(index | ((newGen << 26) & HandleGenerationMask) | HandleEnabledMask);
}

export function isHandleEnabled(handle: Handle): boolean {
	return !!(handle & HandleEnabledMask);
}

export function disabledHandle(prevHandle: Handle): Handle {
	LegacyLogger.assert((prevHandle & HandleEnabledMask) === HandleEnabledMask, 'disabling should be done on enabled handle');

	return <Handle>(prevHandle & (~HandleEnabledMask));
}

export function compareHandles(h1: Handle, h2: Handle): number {
	// swap index and generation, so that handles with the same indices would be neighbors
	// otherwise, if handles compared as number
	// handles with larger generation will be sorted after the younger handles
	// which will be suboptimal for further iteration of collection via them 

	const ind1 = h1 & HandleIndexMask;
	const ind2 = h2 & HandleIndexMask;
	const enabledAndGen1 = (h1 & (HandleEnabledMask | HandleGenerationMask)) >> 25;
	const enabledAndGen2 = (h2 & (HandleEnabledMask | HandleGenerationMask)) >> 25;
	return ((ind1 << 6) + enabledAndGen1) - ((ind2 << 6) + enabledAndGen2);
}

export function sortDedupHandles<H extends Handle>(handles: H[]): H[] {
	if (handles.length <= 1) {
		return handles;
	}
	handles.sort(compareHandles);
	let nextRead = 1;
	let nextWrite = 1;
	let len = handles.length;
	while (nextRead < len) {
		let handle = handles[nextRead];
		let prevW = handles[nextWrite - 1];
		if (handle !== prevW) {
			if (nextRead != nextWrite) {
				handles[nextWrite] = handle;
			}
			nextWrite += 1;
		}
		nextRead += 1;
	}
	handles.length = nextWrite;
	return handles;
}
