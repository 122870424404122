import { JsonClassesBuiltInSerializers } from './JsonClassesBuilltIns';


export interface JsonClassSerializer<T> {
    class: {new(...args: any[]): T};
    toSerializable(obj: T): any;
    fromSerializable(prim: any, formatVersion: number): T;
    currentFormatVersion?: number;
    classIdentifier?: string;
}


export function JsonClassesStringify(obj: any, classesRegistry?: JsonClassSerializer<any>[]): string {

    if (!builtInPerClassRegistry) {
        const builtInsMap = new Map();
        for (const builtin of builtInsSerializers) {
            builtInsMap.set(builtin.class, builtin);
        }
        builtInPerClassRegistry = builtInsMap;
    }

    return JSON.stringify(obj, (_key: string, value: any) => {
        if (typeof value !== 'object') {
            return value;
        }
        if (value.constructor === Object || value.constructor === Array) {
            return value;
        }
        if (classesRegistry) {

        }
        let supportedSerializer = builtInPerClassRegistry!.get(value.constructor);
        if (!supportedSerializer && classesRegistry) {
            for (const serializer of classesRegistry) {
                if (serializer.class === value.constructor) {
                    supportedSerializer = serializer;
                    break;
                }
            }
        }
        if (!supportedSerializer) {
            throw new Error('no supported class found for: ' + value.constructor.name);
        }
        const wrapper: JsonStringifyClassWrapper = {
            _c_: supportedSerializer.classIdentifier ?? value.constructor.name,
            _v_: supportedSerializer.toSerializable(value),
            _fv_: supportedSerializer.currentFormatVersion,
        }
        return wrapper;

    });
}

export function JsonClassesParse(str: string, classesRegistry?: JsonClassSerializer<any>[]): any {
    if (!builtInPerTypeIdentRegistry) {
        const builtInsMap = new Map();
        for (const builtin of builtInsSerializers) {
            builtInsMap.set(builtin.classIdentifier ?? builtin.class.name, builtin);
        }
        builtInPerTypeIdentRegistry = builtInsMap;
    }
    return JSON.parse(str, (_key: string, value: any) => {
        if (value.constructor !== Object) {
            return value;
        }
        if (!value._c_ || !value._v_) {
            return value;
        }
        const wrapper = value as JsonStringifyClassWrapper;
        let supportedSerializer = builtInPerTypeIdentRegistry!.get(wrapper._c_);
        if (!supportedSerializer && classesRegistry) {
            for (const serializer of classesRegistry) {
                let ident = serializer.classIdentifier ?? serializer.class.name;
                if (ident === wrapper._c_) {
                    supportedSerializer = serializer;
                    break;
                }
            }
        }
        if (!supportedSerializer) {
            throw new Error('no supported class found for: ' + wrapper._c_);
        }
        return supportedSerializer.fromSerializable(wrapper._v_, wrapper._fv_ ?? 0);
    });
}




interface JsonStringifyClassWrapper {
    _c_: string;
    _v_: any;
    _fv_?: number;
}


const builtInsSerializers = JsonClassesBuiltInSerializers();

let builtInPerClassRegistry: Map<{new(...args: any[]): any}, JsonClassSerializer<any>> | undefined = undefined;
let builtInPerTypeIdentRegistry: Map<string, JsonClassSerializer<any>> | undefined = undefined;
