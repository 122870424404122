import { BimUiBindings } from 'bim-ts';
import type { PUI_GroupNode} from 'ui-bindings';
import { buildPuiFromObject, PUI_ConfigBasedBuilderParams } from 'ui-bindings';
import type { EnergyViewData } from './EnergyViewDataGenerator';


export function createTotalEnergyShortStatsUi(
    energyData: EnergyViewData
): PUI_GroupNode {
    const configBuilderParams = PUI_ConfigBasedBuilderParams.new(
        BimUiBindings.propsToPuiNodesTransformers(),
    );
    return buildPuiFromObject({
        sourceObject: energyData.basic_props,
        onAnyChange: (newValue, path, node) => {
            console.error('unexpected energy pui value change callback', newValue, path);
        },
        configBuilderParams,
        puiBuilderParams: {rootName: 'energy-stats', sortChildrenDefault: false},
    });
}
