import type { Bim, IdBimGeo, IdBimScene, IdInEntityLocal} from 'bim-ts';
import {
    BasicAnalyticalRepresentation, GraphGeometry, LocalIdsCounter, SegmentInterpLinearG
} from 'bim-ts';
import type { LazyVersioned, UndoStack , Result } from 'engine-utils-ts';
import { IterUtils, LazyBasic,  ObjectUtils, ObservableObject, Success } from 'engine-utils-ts';
import { Vector3 } from 'math-ts';
import type { EngineScene } from '../scene/EngineScene';
import type { InteractiveObjectsActive } from '../scene/InteractiveSceneObjects';

import type { SceneInt } from '../scene/SceneRaycaster';
import { InObjFullId } from '../scene/EngineSceneIds';
import type { EditModeControls } from './EditControls';
import { MouseButton } from './InputController';
import type { EditInteractionResult, InteractiveEditOperator, StartOptions } from './InteractiveEditOperator';
import type { MouseEventData } from './MouseGesturesBase';
import { EngineControlsMode } from '../EngineConsts';
import type { MenuPath } from 'ui-bindings';
import { EditActionResult } from 'ui-bindings';

interface RoadAdderState {
    instanceId: IdBimScene | 0;
    geoId: IdBimGeo,
	instanceOwned: boolean,
	startGeometry: GraphGeometry,
}
interface RoadAdderSetting {
}
export class InteractiveRoadAdder implements InteractiveEditOperator<RoadAdderState | null, RoadAdderSetting> {

    readonly menuPath: MenuPath = ['Add', 'Road'];
	readonly priority: number = 2;
    readonly canStart: LazyVersioned<boolean>;
    readonly config: ObservableObject<RoadAdderSetting>;

    constructor(
        readonly bim: Bim,
		readonly engineScene: EngineScene,
		readonly allInteractiveObjects: InteractiveObjectsActive,
        readonly editControls: EditModeControls,
        undoStack: UndoStack,
    ) {
        this.canStart = new LazyBasic('', true);
        this.config = new ObservableObject({
            identifier: this.menuPath.join(),
            initialState: {},
			undoStack,
            throttling: {onlyFields: []}
        });
    }

    start(options?: StartOptions): Result<RoadAdderState | null> {
		if (options?.resetSelection) {
			this.bim.instances.setSelected([]);
		}
        return new Success(null)
    }
    cancel(state: RoadAdderState) {
		console.log('cancel road adder', state);
		if (state !== null) {
			if (state.instanceId && state.instanceOwned) {
				this.bim.instances.delete([state.instanceId]);
			} else {
				this.bim.graphGeometries.applyPatches([[state.geoId, state.startGeometry]]);
			}
		}
    }
	finish(state: Readonly<RoadAdderState>|null) : EditActionResult | undefined {
		const result = state?.instanceId ? new EditActionResult([state.instanceId]) : undefined;
		return result;
	}
    handleConfigPatch(
        patch: Partial<RoadAdderSetting>,
        prevState: RoadAdderState | null,
    ): EditInteractionResult<RoadAdderState | null> {
        throw new Error('Method not implemented.');
    }

    onHover (int: SceneInt): {cursorStyleToSet: string} | null {
        return {cursorStyleToSet: "crosshair"};
    }

	_getNewGeometryFor(point: Vector3) {
		const graphGeo = new GraphGeometry(
			new Map([[1 as IdInEntityLocal, point.clone()]]),
			new Map(),
		);
		return graphGeo;
	}

    handleClick(
        sceneInt: SceneInt,
        me: MouseEventData,
        state: RoadAdderState | null
    ) : EditInteractionResult<RoadAdderState | null> {
        if (me.buttons.mouseButton === MouseButton.Right) {
            return {
                state: state,
                done: true,
            }
        }

		const point = sceneInt?.point ?? me.mouseCone?.raySection.ray.at(10, new Vector3()) ?? new Vector3();

		const bimInstances = this.bim.instances;
		const selectedIds = bimInstances.getSelected();

		console.log('point add', point.toArray([], 0).join());


		if (state === null
			&& selectedIds.length === 1
			&& bimInstances.peekById(selectedIds[0])?.type_identifier === 'road'
		) {
			const instanceId = selectedIds[0];
			const roadInstance = bimInstances.peekById(instanceId)!;

			if (roadInstance.representationAnalytical instanceof BasicAnalyticalRepresentation
				&& this.bim.graphGeometries.peekById(roadInstance.representationAnalytical.geometryId)
			) {
				const geoId = roadInstance.representationAnalytical.geometryId;
				const graphGeo =  this.bim.graphGeometries.peekById(geoId)!;
				state = {
					geoId,
					startGeometry: graphGeo,
					instanceOwned: false,
					instanceId,
				}
			}
		}

        if (state === null) {

			let geoId: IdBimGeo;
			let graphGeo: GraphGeometry;
			let instanceId: IdBimScene;


			geoId = this.bim.graphGeometries.idsProvider.reserveNewId();
			graphGeo = this._getNewGeometryFor(point);

			this.bim.graphGeometries.allocate([[geoId, graphGeo]]);

			instanceId = this.bim.instances.idsProvider.reserveNewId();
			const instance = this.bim.instances.archetypes.newDefaultInstanceForArchetype('road');
			instance.representationAnalytical = new BasicAnalyticalRepresentation(geoId);
			this.bim.instances.allocate([[instanceId, instance]]);
			this.bim.instances.setSelected([instanceId]);

            this.editControls.controlsState.applyPatch({patch: {controlsMode: EngineControlsMode.Edit}});

            return {
                state: { instanceId, geoId, instanceOwned: true, startGeometry: graphGeo },
                done: false,
            };
        } else {
            const geo = this.bim.graphGeometries.peekById(state.geoId);
            if (!geo) {
                return {
                    state: null,
                    done: false,
                }
            }

			let pointToConnectTo: IdInEntityLocal | null = null;

			let connectedToSelected: boolean = false;
			const selected = this.allInteractiveObjects.getSelected();
			if (selected.length === 1 && selected[0] instanceof InObjFullId) {
				const selectedSubObj = selected[0];
				const id = this.engineScene.esos.idOf(selectedSubObj.objHandle);
				if (id === state.instanceId && geo.points.has(selectedSubObj.inObjId.localId as IdInEntityLocal)) {
					pointToConnectTo = selectedSubObj.inObjId.localId as IdInEntityLocal;
					connectedToSelected = true;
				}
			}
			if (pointToConnectTo === null) {
				const youngestPoint = IterUtils.maxBy(geo.points, t => t[0]);
				if (youngestPoint) {
					pointToConnectTo = youngestPoint[0];
				}
			}

			const newGeo = ObjectUtils.deepCloneObj(geo);
			const newPointId = this.bim.graphGeometries.localIdsCounterFor(state.geoId).nextId();

			newGeo.points.set(newPointId, point);

			if (pointToConnectTo !== null) {
				const newEdge = LocalIdsCounter.newEdge(newPointId, pointToConnectTo);
				newGeo.edges.set(newEdge, SegmentInterpLinearG);
			}

            this.bim.graphGeometries.applyPatches([[state.geoId, newGeo]]);

			if (connectedToSelected) {
				this.allInteractiveObjects.setSelected([]);
			}

            return {
                state: { ...state },
                done: false,
            }
        }
    }

}

