import { KrMath } from 'math-ts';
import { createNotificationSource } from 'ui-bindings'

export const notificationSource = createNotificationSource({
    importDefaultAssets: {
        header: 'Importing default assets pack',
        description: ''
    },
    selectingWithinBoundaries: {
        header: 'Selection within selected boundaries',
        description: ''
    },
    loadAsset: {
        header: 'Loading asset',
        description: ''
    },
    replaceProperties: {
        header: 'Replacing properties',
        description: ''
    },
    placeEquipment: {
        header: 'Placing of equipment',
        description: ''
    },
    heightmapNotFound: {
        header: 'Terrain heightmap not found',
        description: ''
    },
    heightmapRotation: {
        header: "Terrain heightmap doesn't support rotation",
        description: 'Set rotation to 0'
    },
    heightmapScale: {
        header: "Terrain heightmap doesn't support scale",
        description: 'Set scale to 1'
    },
    placeMethod: {
        header: 'Placing of equipment failed',
        description: (name: string) => `Place method not implemented - ${name}`,
    },
    solverResultsIncorrect: {
        header: 'The solver results are incorrect',
        description: 'Please try to change the parameters and recalculate'
    },
    equipmentOnSurface: {
        header: 'Not all equipment are on the surface',
        description: ''
    },
    resetTerrain: {
        header: 'Reseting of terrain',
        description: ''
    },
    pileOptimization: {
        header: "Invalid pile reveal",
        description: ([allPilesCount, notSuccessPilesCount]) => `Min-max reveal matched for ${allPilesCount-notSuccessPilesCount} piles, ${notSuccessPilesCount} piles (${KrMath.roundTo((notSuccessPilesCount / allPilesCount)*100, 0.01)}%) have reveal out of target range`,
    },
    importSurfacePointsFilteredOut: {
        header: 'Import surface',
		description: ([pointsCount]) => `${pointsCount} surface points were filtered out`
    },
    parseProjectionMethod: {
        header: "Parsing projection",
        description: (method: string) => `Unsupported projection method: ${method}. Please, contact the support team`
    },
    parseProjectionParameters: {
        header: "Parsing projection",
        description: (key: string[]) => `Parameters ${key} are invalid`
    },
});
