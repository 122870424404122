import type { LazyVersioned, LongTask, Result } from "engine-utils-ts";
import type { PUI_PropertyNode } from './PUI_Nodes';

export const AnyKey = 'Any Key';
export interface ActionKeysCombination {
    modifiers?: KeyModifiersFlags,
    keyCode: string,
}

export interface DynamicArgsDescription<Args extends (string | number | string[] | number[])> {
    hint: string,
    validator: (args: Args) => Result<Args>,
    autoComplete: (userInput: string) => Result<Args>,
}

export enum ActionVisibilityFlags {
    None   = 0,
    Search = 1,
    Menu   = 2,
    ContextMenu = 4,
	HideUnavailable = 8,
    SelectionMenu = 16,
    Toolbar = 32
}

export class EditActionResult {
    constructor(public ids: number[]) {}
}

interface BasicParams {
    name: string[];
    keyCombinations?: ActionKeysCombination[];
    priority?: number;
    divider?: boolean;
}

class DescriptionBase {
    readonly name: string[];
    readonly keyCombinations?: ActionKeysCombination[];
    readonly priority: number;
    readonly divider: boolean;

    constructor(params: BasicParams) {
        this.name = params.name;
        this.keyCombinations = params.keyCombinations;
        this.priority = params.priority ?? 0;
        this.divider = params.divider ?? false;
    }

    hotkeysString(): string {
        if (!this.keyCombinations) {
            return '';
        }
        let strings: string[] = [];
        for (const kc of this.keyCombinations) {
            let key = kc.keyCode;
            if (key.startsWith('Key')) {
                key = key.slice(3);
            } else if (key.startsWith('Digit')) {
                key = key.slice(5);
            }
            const modif = keyModifiersToString(kc.modifiers);
            if (modif) {
                strings.push(`${modif}+${key}`);
            } else {
                strings.push(key);
            }
        }
        return strings.join(' | ')
    }
}

export interface ActionParams<Args extends (string | number | string[] | number[] | boolean | undefined) = undefined> extends BasicParams {
    canUseNow: LazyVersioned<boolean>,
    visibility?: ActionVisibilityFlags,
    activationHint?: string,
    dynamicArgsDescription?: DynamicArgsDescription<string | number | string[] | number[]>,
    action?: (dynamicArgs: Args) => Promise<any | EditActionResult> | LongTask<any> | void,
    actionOnUp?: (dynamicArgs: Args) => Promise<any> | LongTask<any> | void,
}

export class ActionDescription<Args extends (string | number | string[] | number[] | boolean | undefined) = undefined> extends DescriptionBase {
    readonly canUseNow: LazyVersioned<boolean>;
    readonly visibility: ActionVisibilityFlags;
    readonly activationHint?: string;
    readonly dynamicArgsDescription?: DynamicArgsDescription<string | number | string[] | number[]>;
    readonly action?: (dynamicArgs: Args) => Promise<any> | LongTask<any> | void;
    readonly actionOnUp?: (dynamicArgs: Args) => Promise<any> | LongTask<any> | void;

    constructor(params: ActionParams<Args>) {
        super(params);
        this.canUseNow = params.canUseNow;
        this.visibility = params.visibility ?? ActionVisibilityFlags.Search;
        this.activationHint = params.activationHint;

        this.dynamicArgsDescription = params.dynamicArgsDescription;
        this.action = params.action;
        this.actionOnUp = params.actionOnUp;
    }
}

export interface SettingParams extends BasicParams {
    property: PUI_PropertyNode<any>;
}

export class SettingDescription extends DescriptionBase {
    readonly property: PUI_PropertyNode<any>;

    constructor(params: SettingParams) {
        super(params);
        this.property = params.property;
    }
}


export enum KeyModifiersFlags {
	None = 0,
	Shift = 1,
	Ctrl = 2,
	Alt = 4
}

export function keyModifiersToString(flags?: KeyModifiersFlags) {
    if (flags === undefined) {
        return "";
    }
    let strings: string[] = [];
    if (flags & KeyModifiersFlags.Ctrl) {
        strings.push('Ctrl');
    }
    if (flags & KeyModifiersFlags.Shift) {
        strings.push('Shift');
    }
    if (flags & KeyModifiersFlags.Alt) {
        strings.push('Alt');
    }
    return strings.join(' ');
}

const isMac: boolean = !!(globalThis as unknown as Window)?.navigator?.platform.match("Mac");

export function getModifierFlagsFromKeyboardEvent(e: KeyboardEvent): KeyModifiersFlags {
    let flags = KeyModifiersFlags.None;
    if (e.ctrlKey) {
        flags |= KeyModifiersFlags.Ctrl;
    } else if (isMac && e.metaKey) {
        flags |= KeyModifiersFlags.Ctrl;
    }
    if (e.altKey) {
        flags |= KeyModifiersFlags.Alt;
    }
    if (e.shiftKey) {
        flags |= KeyModifiersFlags.Shift;
    }
    return flags;
}

export function hotkeysString(actionDescr: ActionDescription<any>): string | null {
    if (!actionDescr.keyCombinations?.length) {
        return null;
    }
    let strings: string[] = [];
    for (const kc of actionDescr.keyCombinations) {
        let key = kc.keyCode;
        if (key.startsWith("Key")) {
            key = key.slice(3);
        }
        strings.push([keyModifiersToString(kc.modifiers), key].join(' '));
    }
    return strings.join(' | ')
}


