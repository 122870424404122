<script lang="ts">
import 'ag-grid-enterprise/styles/ag-theme-balham.min.css';
import { getContext, onDestroy, onMount } from "svelte";
import { GridApi, createGrid } from 'ag-grid-enterprise';
import { Bim, CostModel as CM } from 'bim-ts';
import { VersionedStore } from "src/VersionedStore";
import { LazyBasic, LazyDerived, LazyDerivedAsync, Success, preferPreviousOverInProgressWrapper } from "engine-utils-ts";
import { createGridConfig } from "./table";
import { UiPersistentState } from 'src/UiPersistentState';
import { ContextMenu } from 'src/libui/context-menu';
import {  createTableSettingsMenu } from './TableSettingsMenu';
import { UiSettings } from './types';
import { GridApiType, GridConfigType } from './table/types';
import { populateAgGridTable } from './table/utils';
import { CostModelContext, CostModelMenuCommand, getCostModelContext } from './CostModelContext';
import { createIsCostModelBeingCalculated } from './utils';

export let isLoading: boolean
export let isReadonly: boolean
export let onMenuClick: undefined | ((e: MouseEvent) => void)

const bim = getContext<Bim>('bim');
const contextMenu = getContext<ContextMenu>('context-menu');
const costModelContext = getCostModelContext();

const costModelLazyRowWithoutFocusApi = getContext<LazyDerivedAsync<CM.CostModel>>('costModelLazy')
const costModelLazyRow = LazyDerivedAsync.new1<
    CM.CostModel,
    CM.CostModel
>(
    'costModelLazyRowWithFocusApi',
    [],
    [costModelLazyRowWithoutFocusApi],
    function* ([costModelLazyRow]) {
        costModelLazyRow.focusApi = costModelContext.focusApi;
        return costModelLazyRow
    }
)

const costModelLazy = preferPreviousOverInProgressWrapper(costModelLazyRow)
$: costModel = $MergedStore.costModel instanceof Success ? $MergedStore.costModel.value : undefined;

const uiSettings = new UiPersistentState<UiSettings>('capital-cost-ui-settings', {}).store;

const selectedTableLazy = new LazyBasic<CM.CostTable | undefined>("selectedTable", undefined);

let gridRootRef: HTMLElement;
let gridConfig: GridConfigType
let gridApi: GridApiType

let focusOnCategory: CM.IdCostCategory | undefined;

onMount(() => {
    gridConfig = createGridConfig(bim);
    gridApi = createGrid(gridRootRef, gridConfig.options);
})

onDestroy(() => {
    gridApi.destroy()
})


function onCostModelCalculation(params: {
    costModel?: CM.CostModel,
    selectedTableId?: string
}) {
    if (!params.selectedTableId && params.costModel?.tables.length) {
        $uiSettings.selectedTableId = params.costModel.tables[0].id;
        return
    }
}

$: onCostModelCalculation({ costModel, selectedTableId: $uiSettings.selectedTableId })


function populateAgGridTableReactive(params: {
    costModel?: CM.CostModel,
    uiSettings: UiSettings,
    gridConfig: GridConfigType,
    gridApi: GridApi<CM.FlattenedCostCategoryParams>,
    focusOnCategory?: CM.IdCostCategory,
}) {
    if (params.costModel && params.uiSettings.selectedTableId && params.gridConfig && params.gridApi) {
        const selectedTable = params.costModel.tables.find(x => x.id === params.uiSettings.selectedTableId)
        if (selectedTable) {
            populateAgGridTable({
                costModel: params.costModel,
                uiSettings: params.uiSettings,
                selectedTable,
                gridConfig: params.gridConfig,
                gridApi: params.gridApi,
                focusOnCategory: params.focusOnCategory
            })
            focusOnCategory = undefined;
        }
        selectedTableLazy.forceUpdate(selectedTable);
    }
}

$: if (typeof isReadonly === 'boolean' && gridApi) {
    gridApi.setGridOption('rowData', []);
    gridApi.setGridOption('pinnedBottomRowData', []);
}

$: populateAgGridTableReactive({
    gridApi,
    gridConfig,
    costModel,
    uiSettings: {
        isReadonly,
        selectedTableId: $uiSettings.selectedTableId
    },
    focusOnCategory
})

const tableSettingsMenuLazy = createTableSettingsMenu(selectedTableLazy, costModelLazy);
function _onMenuClick(e: MouseEvent) {
    contextMenu.openMenuAtPosition(e.clientX, e.clientY, tableSettingsMenuLazy)
}

$: {
    onMenuClick =  $MergedStore.tableSettingsMenu.items.length ? _onMenuClick : undefined
}


function focusOnCategoryReactive(params: {
    cmds: CostModelMenuCommand[],
    costModel?: CM.CostModel,
    context: CostModelContext
}): undefined | {
    focusOnCategory?: CM.IdCostCategory,
    selectedTableId: string
} {
    if (params.cmds.length <= 0) {
        return;
    }
    if (!params.costModel) {
        return;
    }
    const api = params.context;
    const latestCmd = api.popLatestCommand();
    if (!latestCmd) {
        return;
    }
    if (latestCmd instanceof CM.FocusOnTableCommand) {
        return {
            selectedTableId: latestCmd.tableId,
            focusOnCategory: undefined,
        }
    } else if (latestCmd instanceof CM.FocusOnCategoryThatMatchesTheInstance_Command) {
        const matchingPlace = params.costModel.findSceneInstanceMatchingCategories(latestCmd.params).at(0);
        if (!matchingPlace) {
            return;
        }
        return {
            focusOnCategory: matchingPlace[1],
            selectedTableId: matchingPlace[0],
        }
    } else {
        return;
    }
}

$: {
    const reply = focusOnCategoryReactive({ cmds: $MergedStore.api, context: costModelContext, costModel })
    if (reply) {
        $uiSettings.selectedTableId = reply.selectedTableId;
        focusOnCategory = reply.focusOnCategory;
    }
}


const MergedStore = new VersionedStore(
    LazyDerived.new4(
        'merged-store',
        [],
        [
            costModelLazy,
            costModelContext.cmds,
            createIsCostModelBeingCalculated(costModelLazyRow),
            tableSettingsMenuLazy,
        ],
        ([costModel, api, isCalculating, tableSettingsMenu]) => {
            return {
                costModel,
                api,
                isCalculating,
                tableSettingsMenu,
            }
        }
    ),
    200
)

$: {
    isLoading = $MergedStore.isCalculating;
}

</script>

<div
    class="container"
    on:keyup|stopPropagation on:keydown|stopPropagation
>
    {#if costModel?.tables?.length}
        <div class="CM-toolbar">
            <div class="CM-tabs">
                {#each costModel.tables as table}
                    <div
                        class="mdc-typography--headline5 item"
                        class:selected={table.id === $uiSettings.selectedTableId}
                        on:click={() => {
                            gridApi.ensureIndexVisible(0);
                            $uiSettings.selectedTableId = table.id
                            gridApi.setGridOption('rowData', []);
                            gridApi.setGridOption('pinnedBottomRowData', []);
                        }}
                    >
                        {table.name}
                    </div>
                {/each}
            </div>
        </div>
    {/if}

    <div
        bind:this={gridRootRef}
        class="cost-model-table ag-theme-balham"
    />
</div>


<style lang="scss">
.container {
    display: flex;
    height: 100%;
    flex-direction: column;
    transition-delay: 0s;
    transition: opacity .5s;
}
</style>

