import { Builder, ByteBuffer } from 'flatbuffers';
import type { ObjectsPickingSerializer } from 'verdata-ts';

import { BimMaterial, BimMaterialStdRenderParams } from '../BimMaterials';
import { BimMaterial as BimMaterialF } from '../schema/bim-material';
import { BimMaterialCollection } from '../schema/bim-material-collection';
import { BimMaterialStdRenderParams as BimMaterialStdRenderParamsF } from '../schema/bim-material-std-render-params';
import { FlatbufCommon } from './FlatbufCommon';

export class BimMaterialsSerializer implements ObjectsPickingSerializer<BimMaterial> {

    serialize(objects: [number, BimMaterial][]): Uint8Array {
        const builder = new Builder(objects.length * 100);
        const root = BimMaterialCollection.createBimMaterialCollection(
            builder,
            0,
            FlatbufCommon.writeInt32Vector(builder, objects.map(t => t[0])),
            BimMaterialCollection.createCollectionVector(
                builder, objects.map((t) => writeObj(builder, t[1])),
            ),
        );
        builder.finish(root);
        return builder.asUint8Array().slice();
    }
    deserialize(buffer: Uint8Array, idsToDeserialize: Set<number>): [number, BimMaterial][] {
        const rts = BimMaterialCollection.getRootAsBimMaterialCollection(new ByteBuffer(buffer));
        const res: [number, BimMaterial][] = [];
        for (let i = 0, il = rts.idsLength(); i < il; ++i) {
            const id = rts.ids(i)!;
            if (!idsToDeserialize.has(id)) {
                continue;
            }
            const t = rts.collection(i)!;
            res.push([
                id,
                readObj(t)
            ]);
        }
        return res;
    }
}
function writeObj(builder: Builder, g: BimMaterial): number {
    const nameOffset = g.name.length ? builder.createString(g.name) : 0;
    const rpo = BimMaterialStdRenderParamsF.createBimMaterialStdRenderParams(
        builder,
        g.stdRenderParams.color ? builder.createString(g.stdRenderParams.color) : 0,
        g.stdRenderParams.transparency,
        g.stdRenderParams.metalness,
        g.stdRenderParams.roughness,
        g.stdRenderParams.colorImageId,
        g.stdRenderParams.normalsImageId,
        g.stdRenderParams.metalnessImageId,
        g.stdRenderParams.roughnessImageId
    );
    BimMaterialF.startBimMaterial(builder);
    BimMaterialF.addName(builder, nameOffset);
    BimMaterialF.addRenderParams(builder, rpo);
    return BimMaterialF.endBimMaterial(builder);
}
function readObj(pl: BimMaterialF): BimMaterial {
    const rp = pl.renderParams();
    const r = rp ? new BimMaterialStdRenderParams(
        rp.color(),
        rp.transparency(),
        rp.metalness(),
        rp.roughness(),
        rp.colorImageId(),
        rp.normalMapId(),
        rp.metalnessImageId(),
        rp.roughnessImageId(),
    ) : undefined;
    return new BimMaterial(
        pl.name()!,
        r
    );
}
