import type { LazyVersioned } from 'engine-utils-ts';

import type { Camera } from '../3rdParty/three';
import type { GpuResources } from '../composer/GpuResources';
import type { RenderJobsGenerator} from '../composer/RenderLists';
import {
	ProgressiveRenderList
} from '../composer/RenderLists';
import type { RenderJobsMerged } from '../geometries/RenderJobsMerged';
import type { GraphicsSettings } from '../GraphicsSettings';
import type { ProgressiveCulling } from '../scene/BoundsSceneWrap';
import { LodMask } from '../scene/Submeshes2';
import type { BudgetLimitForPass, RenderPass, RenderResult } from './RenderPass';
import type { RenderTargets, RTIdent } from './RenderTargets';

const reusedArray: RenderJobsMerged[] = [];

interface PassLodSettings {
    meshesLodsToRender: LodMask|0,
    edgesLodsToRender: LodMask|0,
}

export abstract class ProgressivePass implements RenderPass {

	renderList: ProgressiveRenderList;

    enabled: boolean = true;
    identifier: string;
    inputs: RTIdent[];
	outputs: RTIdent[];
    renderEdges: (cam: Camera) => boolean;
    lodSettings: PassLodSettings;

    constructor(
        identifier: string,
        inputs: RTIdent[],
        outputs: RTIdent[],
        renderList: ProgressiveRenderList,
        renderEdges: (cam: Camera) => boolean,
        lodSettings?: PassLodSettings
    ) {
        this.identifier = identifier;
        this.inputs = inputs;
        this.outputs = outputs;
        this.renderList = renderList;
        this.renderEdges = renderEdges;
        this.lodSettings = lodSettings ?? { meshesLodsToRender: LodMask.All, edgesLodsToRender: LodMask.Lod0 };
    }

    version(): number {
        return this.renderList.version();
    }

    reset(): void {
        this.renderList.reset();
    }

    render(
        camera: Readonly<Camera>,
        gpuRes: GpuResources,
        renderTargets: RenderTargets,
        budget: BudgetLimitForPass,
        anyInputsIncomplete: boolean,
    ): RenderResult {

        const meshesTarget = Math.max(5, budget.unitsToRender);

        const mjs = this.renderList.getMore(camera, meshesTarget);
        
        gpuRes.renderer.uboBuffers.initTransformsUbo(mjs);

        const forcedEdges = reusedArray;
        forcedEdges.length = 0;
        
        const renderer = gpuRes.renderer;
        const materials = gpuRes.materials;

        
        if (this.lodSettings.meshesLodsToRender !== 0) {

            if (this.outputs.length > 2) {
                renderTargets.bindByIdents([this.outputs[0], this.outputs[2]]);
            } else {
                renderTargets.bindByIdent(this.outputs[0]);
            }

            renderer.renderJobs(materials, camera, mjs, this.lodSettings.meshesLodsToRender);
        }

        if (this.lodSettings.edgesLodsToRender !== 0 && this.renderEdges(camera)) {

            renderTargets.bindByIdent(this.outputs[1]);
            
            renderer.renderJobsEdges(materials, camera, mjs, this.lodSettings.edgesLodsToRender);
        }

        return { unitsRenderered: mjs.jobsCount, finished: !this.renderList.hasMore() };
    }

}


export class TranspPass extends ProgressivePass {

    constructor(
        inout: RTIdent[],
        cb: ProgressiveCulling,
		jobsProvider: RenderJobsGenerator,
        settings: LazyVersioned<GraphicsSettings>
    ) {
        super(
            'std_transp',
            inout,
            inout,
            new ProgressiveRenderList(
                'std_transp',
                cb,
                jobsProvider,
                [settings],
                { allowRenderJobsSorting: false, reverseDrawOrder: true },
            ),
            (cam) => settings.poll().shouldRenderEdges(cam),
            {edgesLodsToRender: 0, meshesLodsToRender: LodMask.All}
        );
    }
}

export class OpaquePass extends ProgressivePass {

    constructor(
        inout: RTIdent[],
        cb: ProgressiveCulling,
		jobsProvider: RenderJobsGenerator,
        settings: LazyVersioned<GraphicsSettings>
    ) {
        super(
            'std_opaque',
            inout,
            inout,
            new ProgressiveRenderList(
                'std_opaque',
                cb,
                jobsProvider,
                [settings],
                { allowRenderJobsSorting: true, reverseDrawOrder: false },
            ),
            (cam) => settings.poll().shouldRenderEdges(cam),
            {edgesLodsToRender: LodMask.Lod0, meshesLodsToRender: LodMask.All}
        );
    }
}


export class OverlayPass extends ProgressivePass {
    constructor(
        inout: RTIdent[],
        cb: ProgressiveCulling,
		jobsProvider: RenderJobsGenerator,
        settings: PassLodSettings,
    ) {
        super(
            'overlay',
            inout,
            inout,
            new ProgressiveRenderList(
                'overlay',
                cb,
                jobsProvider,
                [],
                { allowRenderJobsSorting: true, reverseDrawOrder: false }
            ),
            () => true,
            settings
        );
    }
}
