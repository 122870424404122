import type { NumberProperty } from "../../properties/PrimitiveProps";
import type { Catalog } from "../Catalog";
import type { CatalogItemsUiLabels } from "../CatalogItemsUiLabels";
import type { WireSpecCatalogItemPropsBase } from "./WireSpecCatalogItemPropsBase";
import { registerWireSpecBaseCatalogItemUiLabels } from "./WireSpecCatalogItemPropsBase";

export const LvWireSpecCatalogItemTypeIdent = 'lv-wire-spec';

export interface LvWireSpecCatalogItemProps extends WireSpecCatalogItemPropsBase {
    [key: string]: NumberProperty;
}

export function registerLvWireSpecCatalogItemUiLabels(
    provider: CatalogItemsUiLabels,
    catalog: Catalog,
) {
    registerWireSpecBaseCatalogItemUiLabels(LvWireSpecCatalogItemTypeIdent, provider, catalog, 'LV Wire Spec');
}
