import type { BimGeometryType, RegularHeightmapGeometries, RegularHeightmapGeometry } from "bim-ts";
import type { ScopedLogger, EventStackFrame, Result} from "engine-utils-ts";
import { DefaultMapObjectKey, IterUtils, Success } from "engine-utils-ts";
import { BimEngineGeometriesSyncBase } from "../resources/BimGeometriesSync";
import type { IdEngineGeo } from "./AllEngineGeometries";
import { EngineGeoType } from "./AllEngineGeometries";
import { EngineBimGeometrySharedGpu } from "./EngineGeometry";
import type { GeometryGpuRepr, PerGeoShaderInfo } from "./KrBufferGeometry";
import type { EntityId } from "verdata-ts";
import type { PlaneGeneratorParams } from "./GeometryGenerator";
import { GeometryGenerator } from "./GeometryGenerator";
import type { Matrix4, Plane} from "math-ts";
import { Aabb, Vec3Z, Vector2, Vector3 } from "math-ts";
import type { TextureDataType} from "../3rdParty/three";
import { ClampToEdgeWrapping, DataTexture, FloatType, LinearFilter, NearestFilter, RedFormat, UnsignedByteType, UVMapping } from "../3rdParty/three";
import { Texture } from "../3rdParty/three";
import type { UniformsFlat } from "../composer/DynamicUniforms";
import { ShaderFlags } from "../shaders/ShaderFlags";
import type { KrCamera } from "../controls/MovementControls";
import type { RaySection } from "../structs/RaySection";
import type { GeometryIntersection} from "./GeometryUtils";
import { GeometryUtils, IntersectionType } from "./GeometryUtils";


export class EngineGeoTerrainRegular extends EngineBimGeometrySharedGpu<RegularHeightmapGeometry> {

    constructor(
        source: RegularHeightmapGeometry,
        gpuRepr: GeometryGpuRepr,
		additionalShaderInfo: PerGeoShaderInfo,
    ) {
        super(source, gpuRepr, additionalShaderInfo);
    }

    *snappingEdges(): IterableIterator<[Vector3, Vector3]> {
    }

    intersectPlanes(planes: Plane[]): IntersectionType {
        const bimGeo = this.bimGeo;
        let pointsInside = 0;
        let pointsOutside = 0;
        const point = new Vector3();
        for (let iy = 0; iy <= bimGeo.ySegmentsCount; ++iy) {
            point.y = iy * bimGeo.segmentSizeInMeters;

            for (let ix = 0; ix <= bimGeo.xSegmentsCount; ++ix) {
                const elevation = bimGeo.readElevationAtInds(ix, iy);
                if (!Number.isFinite(elevation)) {
                    continue;
                }
                point.x = ix * bimGeo.segmentSizeInMeters;
                point.z = elevation;
                if (GeometryUtils.planesVolumeContainsPoint(planes, point)) {
                    pointsInside += 1;
                } else {
                    pointsOutside += 1;
                }

                if (pointsInside > 0 && pointsOutside > 0) {
                    return IntersectionType.Partial;
                }
            }
        }
        if (pointsOutside > 0) {
            return IntersectionType.Outside;
        }
        return IntersectionType.Full;
    }

    raycast(ray: RaySection, modelMatrix: Matrix4, _camera: KrCamera): GeometryIntersection[] {

        const result: GeometryIntersection[] = [];

        const bimGeo = this.bimGeo;
        const quadAabb = Aabb.empty();
        const localSpaceRay = ray.ray.clone();
        localSpaceRay.applyMatrix4(modelMatrix.clone().invert())

        const minHeight = bimGeo.elevationsBaseInCm * 0.01;
        const maxHeight = IterUtils.max(bimGeo.elevationsInCmRelative)! * 0.01 + minHeight;

        const rayIntersection = new Vector3();

        const triangleReused = [new Vector3(), new Vector3(), new Vector3()];


        for (let iy = 0; iy < bimGeo.ySegmentsCount; ++iy) {

            quadAabb.elements[2] = minHeight;
            quadAabb.elements[5] = maxHeight;

            quadAabb.elements[1] = (iy + 0) * bimGeo.segmentSizeInMeters;
            quadAabb.elements[4] = (iy + 1) * bimGeo.segmentSizeInMeters;
            
            { // try to skip the whole row
                quadAabb.elements[0] = 0;
                quadAabb.elements[3] = bimGeo.xSegmentsCount * bimGeo.segmentSizeInMeters;
                if (!localSpaceRay.intersectBox(quadAabb, rayIntersection)) {
                    continue;
                }
            }

            for (let ix = 0; ix < bimGeo.xSegmentsCount; ++ix) {

                const z00 = bimGeo.readElevationAtInds(ix + 0, iy + 0);
                const z10 = bimGeo.readElevationAtInds(ix + 1, iy + 0);
                const z01 = bimGeo.readElevationAtInds(ix + 0, iy + 1);
                const z11 = bimGeo.readElevationAtInds(ix + 1, iy + 1);
                if (!Number.isFinite(z00 + z10 + z01 + z11)) {
                    continue;
                }

                const zMin = Math.min(z00, z10, z01, z11);
                const zMax = Math.max(z00, z10, z01, z11);
                
                quadAabb.elements[0] = (ix + 0) * bimGeo.segmentSizeInMeters;
                quadAabb.elements[3] = (ix + 1) * bimGeo.segmentSizeInMeters;

                quadAabb.elements[2] = zMin;
                quadAabb.elements[5] = zMax;
                
                if (!localSpaceRay.intersectBox(quadAabb, rayIntersection)) {
                    continue;
                }

                // now find intersection with triangles
                triangleReused[0].set(quadAabb.elements[0], quadAabb.elements[1], z00);
                triangleReused[1].set(quadAabb.elements[3], quadAabb.elements[1], z10);
                triangleReused[2].set(quadAabb.elements[0], quadAabb.elements[4], z01);

                let intersection = localSpaceRay.intersectTriangle(
                    triangleReused[0],
                    triangleReused[1],
                    triangleReused[2],
                    false,
                    rayIntersection,
                );

                if (!intersection) {
                    triangleReused[0].set(quadAabb.elements[0], quadAabb.elements[4], z01);
                    triangleReused[1].set(quadAabb.elements[3], quadAabb.elements[4], z11);
                    triangleReused[2].set(quadAabb.elements[3], quadAabb.elements[1], z10);

                    intersection = localSpaceRay.intersectTriangle(
                        triangleReused[0],
                        triangleReused[1],
                        triangleReused[2],
                        false,
                        rayIntersection,
                    );
                }


                if (intersection) {
                    intersection.applyMatrix4(modelMatrix);
                    result.push({
                        distance: intersection.distanceTo(ray.ray.origin),
                        normal: Vec3Z,
                        point: intersection.clone(),
                    })
                }

            }
        }
        return result;
    }



}


export class EngineTerrainGeosRegSynced
    extends BimEngineGeometriesSyncBase<EngineGeoTerrainRegular, RegularHeightmapGeometry> {

    _gridGeometriesShared: DefaultMapObjectKey<GridGeometryDescription, GeometryGpuRepr>;
    
    _lodIdsMap = new Map<IdEngineGeo, GridGeoLodDescr>();

    constructor(
        logger: ScopedLogger,
        bimGeos: RegularHeightmapGeometries,
    ) {
        super(bimGeos, {
            identifier: 'engine-terrain-geos',
            logger: logger,
            idsType: EngineGeoType.TerrainRegular,
            T_Constructor: EngineGeoTerrainRegular as any, // we never use patches on engine geos, emptry constructor should never be called
        });

        this._gridGeometriesShared = new DefaultMapObjectKey({
            valuesFactory: (descr) => {
                return GeometryGenerator.generatePlane(Object.freeze<PlaneGeneratorParams>({
                    height: descr.ySegmentsCount * descr.segmentSize,
                    width: descr.ySegmentsCount * descr.segmentSize,
                    centerLocalSpace: new Vector2(0, 0),
                    heightSegments: descr.ySegmentsCount,
                    widthSegments: descr.xSegmentsCount,
                }));
            }
        })
    }

    checkForErrors(t: EngineGeoTerrainRegular): boolean {
        return true;
    }

    getEngineOwnedIds(result: Set<IdEngineGeo>): void {
        // do nothing, lod geometries are collected by collection itself
    }

    convertFromBim(bimGeo: RegularHeightmapGeometry, id: EntityId<BimGeometryType>): Result<EngineGeoTerrainRegular> {
        const geo = this._gridGeometriesShared.getOrCreate(Object.freeze({
            segmentSize: bimGeo.segmentSizeInMeters,
            xSegmentsCount: bimGeo.xSegmentsCount,
            ySegmentsCount: bimGeo.ySegmentsCount,
        }));

        let textureType: TextureDataType;
        let elevationsShaderMutliplier: number;
        let textureElevationsBuffer: Uint8Array | Float32Array;
        if (bimGeo.elevationsInCmRelative instanceof Uint8Array) {
            textureType = UnsignedByteType;
            textureType = FloatType;
            elevationsShaderMutliplier =  0.01;
            textureElevationsBuffer = new Float32Array(bimGeo.elevationsInCmRelative)
        } else if (bimGeo.elevationsInCmRelative instanceof Uint16Array
            || bimGeo.elevationsInCmRelative instanceof Uint32Array
        ) {
            textureType = FloatType;
            elevationsShaderMutliplier = 0.01;
            textureElevationsBuffer = new Float32Array(bimGeo.elevationsInCmRelative);
        } else {
            this.logger.error('unexpected bim regular geo elevations array type', bimGeo);
            textureType = UnsignedByteType;
            elevationsShaderMutliplier = 0;
            textureElevationsBuffer = bimGeo.elevationsInCmRelative;
        }

        const elevationTextureSizeX = bimGeo.xSegmentsCount + 1;
        const elevationTextureSizeY = bimGeo.ySegmentsCount + 1;

        //when elevation is 0 find max elevation from neighbour points to write it into elevation texture
        //to avoid unwanted linear interpolation of vertices on the edges of terrain due to texture LinearFilter
        for (let iy = 0; iy < elevationTextureSizeY; ++iy) {
            for (let ix = 0; ix <elevationTextureSizeX; ++ix) {
               const elevationValue = textureElevationsBuffer[ix + iy * elevationTextureSizeX];

                if(elevationValue === 0) {
                    let maxElevation = Math.max(
                        this.readRelativeElevationOrZero(bimGeo, ix + 1, iy),
                        this.readRelativeElevationOrZero(bimGeo, ix, iy + 1),
                        this.readRelativeElevationOrZero(bimGeo, ix +1, iy + 1),
                        this.readRelativeElevationOrZero(bimGeo, ix - 1, iy),
                        this.readRelativeElevationOrZero(bimGeo, ix, iy - 1),
                        this.readRelativeElevationOrZero(bimGeo, ix -1, iy -1),
                        this.readRelativeElevationOrZero(bimGeo, ix + 1, iy - 1),
                        this.readRelativeElevationOrZero(bimGeo, ix -1, iy + 1));

                    textureElevationsBuffer[ix + iy * elevationTextureSizeX] = maxElevation;
                }
            }
        }

        const heightmapTexture = new DataTexture(
            textureElevationsBuffer, elevationTextureSizeX, elevationTextureSizeY,
            RedFormat, textureType, UVMapping, ClampToEdgeWrapping, ClampToEdgeWrapping,
            LinearFilter, LinearFilter
        );
        // heightmapTexture.addEventListener('')

        let shaderFlags: ShaderFlags = 0;
        let geoUniforms: UniformsFlat = [
            'heightmap', heightmapTexture,
            'heightOffsetMultiplier', new Vector2(bimGeo.elevationsBaseInCm * 0.01, elevationsShaderMutliplier),
        ];
        
        if (bimGeo.hasHoles()) {
            // make visibility texture not for points but for quads
            // xSegmentCount * ySegmentCountSize
            const visibilityForQuads = new Uint8Array(bimGeo.xSegmentsCount * bimGeo.ySegmentsCount);

            for (let iy = 0; iy < bimGeo.ySegmentsCount; ++iy) {
                let sharedZ00 = bimGeo.readElevationAtInds(0, iy + 0);
                let sharedZ01 = bimGeo.readElevationAtInds(0, iy + 1)

                for (let ix = 0; ix < bimGeo.xSegmentsCount; ++ix) {
                    const z10 = bimGeo.readElevationAtInds(ix + 1, iy + 0);
                    const z11 = bimGeo.readElevationAtInds(ix + 1, iy + 1);
                    const sum = sharedZ00 + z10 + sharedZ01 + z11;
                    visibilityForQuads[ix + iy * bimGeo.xSegmentsCount] = Number.isFinite(sum) ? 0xFF : 0;

                    sharedZ00 = z10;
                    sharedZ01 = z11;
                }
            }

            const visibilityTexture = new DataTexture(
                visibilityForQuads, bimGeo.xSegmentsCount, bimGeo.ySegmentsCount,
                RedFormat, UnsignedByteType, UVMapping, ClampToEdgeWrapping, ClampToEdgeWrapping,
                NearestFilter, LinearFilter
            )
            shaderFlags |= ShaderFlags.VISIBILITY_FROM_TEXTURE;
            geoUniforms.push('visibilityTexture', visibilityTexture);
        }

        const shaderInfo: PerGeoShaderInfo = {
            flags: shaderFlags,
            uniforms: geoUniforms,
        }
        return new Success(new EngineGeoTerrainRegular(bimGeo, geo, shaderInfo));
    }

    allocate(argsPerObject: [EntityId<EngineGeoType>, Partial<EngineGeoTerrainRegular>][], e?: Partial<EventStackFrame> | undefined): EntityId<EngineGeoType>[] {
        const allocated = super.allocate(argsPerObject, e);
        // generate lods for every geometry

        const lodsToAlloc: [IdEngineGeo, EngineGeoTerrainRegular][] = allocated.flatMap(id => {
            const geo = this.peekById(id)!;
            const bimGeo = geo.bimGeo;

            let lod1Geo: EngineGeoTerrainRegular;
            {
                const Decimation = 4;
                const lod1SegmentsCount = Math.round(bimGeo.xSegmentsCount / Decimation);
                const lod1SemgnetSize = (bimGeo.xSegmentsCount * bimGeo.segmentSizeInMeters) / lod1SegmentsCount;
    
                const lod1GeoGpu = this._gridGeometriesShared.getOrCreate(Object.freeze({
                    segmentSize: lod1SemgnetSize,
                    xSegmentsCount: lod1SegmentsCount,
                    ySegmentsCount: Math.round(bimGeo.ySegmentsCount / Decimation),
                }));
                lod1Geo = new EngineGeoTerrainRegular(bimGeo, lod1GeoGpu, geo.additionalShaderInfo);
            }
            let lod2Geo: EngineGeoTerrainRegular;
            {
                const Decimation = 16;
                const lod2SegmentsCount = Math.round(bimGeo.xSegmentsCount / Decimation);
                const lod2SemgnetSize = (bimGeo.xSegmentsCount * bimGeo.segmentSizeInMeters) / lod2SegmentsCount;
    
                const lod2GeoGpu = this._gridGeometriesShared.getOrCreate(Object.freeze({
                    segmentSize: lod2SemgnetSize,
                    xSegmentsCount: lod2SegmentsCount,
                    ySegmentsCount: Math.round(bimGeo.ySegmentsCount / Decimation),
                }));
                lod2Geo = new EngineGeoTerrainRegular(bimGeo, lod2GeoGpu, geo.additionalShaderInfo);
            }

            const lod1GeoId = this.reserveEngineOnlyId();
            const lod2GeoId = this.reserveEngineOnlyId();
            this._lodIdsMap.set(id, {detailSize: bimGeo.segmentSizeInMeters, lod1GeoId, lod2GeoId});
            return [[lod1GeoId, lod1Geo], [lod2GeoId, lod2Geo]];
        });
        const lodsAllocated = super.allocate(lodsToAlloc);
        return allocated.concat(lodsAllocated);
    }

    delete(idsToDelete: EntityId<EngineGeoType>[], e?: Partial<EventStackFrame> | undefined): [EntityId<EngineGeoType>, Readonly<EngineGeoTerrainRegular>][] {
        const lodIds = [];
        for (const id of idsToDelete) {
            const lod = this._lodIdsMap.get(id);
            if (lod !== undefined) {
                lodIds.push(lod.lod1GeoId);
                lodIds.push(lod.lod2GeoId);
                this._lodIdsMap.delete(id);
            }
        }

        const res = super.delete(idsToDelete.concat(lodIds), e);
        for (const [id, geo] of res) {
            for (let i = 1; i < geo.additionalShaderInfo.uniforms.length; i += 2) {
                const uniform = geo.additionalShaderInfo.uniforms[i];
                if (uniform instanceof Texture) {
                    uniform.dispose();
                }
            }
        }
        return res;
    }

    getLodGeoId(sourceId: IdEngineGeo) {
        return this._lodIdsMap.get(sourceId);
    }

    readRelativeElevationOrZero(sourceGeo: RegularHeightmapGeometry, ix: number, iy: number): number {
        if (ix < 0 || ix > sourceGeo.xSegmentsCount) {
            return 0;
        }
        if (iy < 0 || iy > sourceGeo.ySegmentsCount) {
            return 0;
        }
        const eCm = sourceGeo.elevationsInCmRelative[ix + iy * (sourceGeo.xSegmentsCount + 1)];
        return eCm;
    }
}

export interface GridGeoLodDescr {
    detailSize: number,
    lod1GeoId: IdEngineGeo,
    lod2GeoId: IdEngineGeo,
}

interface GridGeometryDescription {
    xSegmentsCount: number,
    ySegmentsCount: number,
    segmentSize: number,
}

