import type { LazyVersioned, PollWithVersionResult} from 'engine-utils-ts';
import {
    Allocated, Deleted, IterUtils, ObservableStream, OneToMany, ScopedLogger, StreamAccumulator, VersionedInvalidator} from 'engine-utils-ts';
import { IdsProvider } from 'verdata-ts';
import type { Bim } from '../Bim';
import type { Catalog, CatalogItem, CatalogItemId, PileCatalogItemProps} from '../catalog';
import { PileCatalogItemTypeIdentifier } from '../catalog';
import type { EntitiesCollectionUpdates} from '../collections/EntitiesCollectionUpdates';
import { EntitiesUpdated, handleEntitiesUpdates } from '../collections/EntitiesCollectionUpdates';
import type { ICollection } from '../ICollection';
import type { IdBimScene, SceneInstancePatch} from '../scene/SceneInstances';
import { SceneObjDiff } from '../scene/SceneObjDiff';
import { BimProperty } from '../bimDescriptions/BimProperty';
import type { PileType} from './common';
import { pileTypesFullNameToShortName, pilesTypes, } from './common';
import { AnyTrackerProps } from '../anyTracker/AnyTracker';
import { SmallNumericArrayProperty } from '../properties/SmallNumberArrayProperty';
import { producePropsPatch } from '../properties/Props';
import { FixedTiltProps } from '../archetypes/fixed-tilt/FixedTilt';
import { extractPilesFromTrackerProps, extractPilesFromFixedTracker, extractPilesFromLegacyTracker, TrackerPile } from './TrackerPile';
import { PileProfileType, PilesProfilesProperty } from '../anyTracker/PileProfileType';
import { DefaultPileLength } from '../anyTracker/PilesProps';
import { TrackerProps } from '../trackers/Tracker';


export enum IdPile {};

export class TrackerPilesCollection implements LazyVersioned<ReadonlyMap<IdPile, TrackerPile>>, ICollection<IdPile, TrackerPile, number> {
    private readonly bim: Bim;
    private readonly logger: ScopedLogger;
    private readonly _idsProvider: IdsProvider<IdPile>;
    private readonly _relevantObjectsDiff: SceneObjDiff;

    private readonly _bimInstancesStreamAccumulator: StreamAccumulator<EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>>;
    private readonly _catalogPilePricesStreamAccumulator: StreamAccumulator<EntitiesCollectionUpdates<CatalogItemId, number>>;

    public readonly updatesStream: ObservableStream<EntitiesCollectionUpdates<IdPile, number>>;

    private _invalidator: VersionedInvalidator;

    readonly perId: Map<IdPile, TrackerPile>;
    readonly pilesPerTrackerId: OneToMany<IdBimScene, IdPile>;

    constructor(bim: Bim, idsType: number, catalog: Catalog) {
        this.bim = bim;
        this.logger = new ScopedLogger('TrackerPilesCollection');
        this.perId = new Map();
        this.pilesPerTrackerId = new OneToMany();

        this._idsProvider = new IdsProvider(idsType);
        this._relevantObjectsDiff = SceneObjDiff.Hidden | SceneObjDiff.LegacyProps | SceneObjDiff.NewProps | SceneObjDiff.SpatialParentRef;

        this.updatesStream = new ObservableStream({
            identifier: "tracker-piles-collection-updates-stream",
            defaultValueForNewSubscribersFactory: () => {
                return new Allocated(Array.from(this.perId.keys()));
            },
        });

        this._bimInstancesStreamAccumulator = new StreamAccumulator(
            bim.instances.updatesStream,
            (update) => {
                if (update instanceof EntitiesUpdated) {
                    if ((update.allFlagsCombined & this._relevantObjectsDiff) === 0) {
                      return false;
                    }
                }
                return true;
            }
        );

        this._catalogPilePricesStreamAccumulator = new StreamAccumulator(
            catalog.catalogItems.updatesStream,
            (item) => {
              for (const id of item.ids) {
                  if (
                      catalog.catalogItems.peekById(id)?.typeIdentifier ===
                      PileCatalogItemTypeIdentifier
                  ) {
                        return true;
                  }
                }
                return false;
            }
        );

        this._invalidator = new VersionedInvalidator([
            this._bimInstancesStreamAccumulator,
            this._catalogPilePricesStreamAccumulator,
        ]);
    }

    dispose(): void {
        this._bimInstancesStreamAccumulator.dispose();
        this.updatesStream.dispose();
    }

    pollWithVersion(): PollWithVersionResult<
        Readonly<ReadonlyMap<number, TrackerPile>>
    > {
        return { value: this.poll(), version: this.version() };
    }

    poll() {
        handleEntitiesUpdates(
            this._bimInstancesStreamAccumulator.consume(),
            (allocated) => {
                this.handle(new Allocated(Array.from(allocated)));
            },
            (updated) => {
                this.handle(new EntitiesUpdated(Array.from(updated.keys()), Array.from(updated.values())));
            },
            (deleted) => {
                this.handle(new Deleted(Array.from(deleted)));
            }
        );
        const catalogEvents = this._catalogPilePricesStreamAccumulator.consume();
        if (catalogEvents && catalogEvents.length) {
            // catalog prices changed, recalculate all prices
            const allTrackers = Array.from(this.pilesPerTrackerId.iterOnes());
            this.handle(new Deleted(allTrackers));
            this.handle(new Allocated(allTrackers));
        }
        return this.perId;
    }

    version(): number {
        return this._invalidator.version();
    }

	allIds(): IterableIterator<IdPile> {
		return this.perId.keys();
	}

    peekById(id: IdPile): Readonly<TrackerPile> | undefined {
        return this.perId.get(id);
    }

    peekByIds(ids: Iterable<IdPile>): Map<IdPile, TrackerPile> {
        const res = new Map<IdPile, TrackerPile>();
        for (const id of ids) {
            const t = this.perId.get(id);
            if (t !== undefined) {
                res.set(id, t);
            }
        }
        return res;
    }

    applyLengthPatchTo(pilesIds: IdPile[], lengthInMeters: number): { unsupportedTrackerTypeIds: IdBimScene[]; } {
        if (pilesIds.length === 0) {
            return {unsupportedTrackerTypeIds: []};
        }
        const unsupportedTrackerTypeIds: IdBimScene[] = [];

        const piles = this.peekByIds(pilesIds);
        
        const groupedByParent = IterUtils.groupBy(
            piles,
            ([id, pile]) => pile.parentId,
        );


        const instancesPatches: [IdBimScene, SceneInstancePatch][] = [];

        for (const [trackerId, piles] of groupedByParent) {
            const parent = this.bim.instances.peekById(trackerId);
            if (!parent) {
                this.logger.batchedWarn('parent not found', trackerId);
                continue;
            }
            if (parent.type_identifier !== `any-tracker`) {
                unsupportedTrackerTypeIds.push(trackerId);
                continue;
            }


            const props = parent.props as AnyTrackerProps;
            
            let newLengthsArray: number[];

            if (props.piles.lengths) {
                newLengthsArray = props.piles.lengths.values.slice();
            } else if (props.piles.active_configuration) {
                newLengthsArray = IterUtils.newArray(props.piles.active_configuration.features.length, () => DefaultPileLength);
            } else {
                newLengthsArray = [];
            }

            for (const [pileId, pile] of piles) {
                const pileIndex = pile.inArrayIndex;
                if (pileIndex> newLengthsArray.length) {
                    for (let i = newLengthsArray.length; i < pileIndex; ++i) {
                        newLengthsArray.push(DefaultPileLength);
                    }
                }
                newLengthsArray[pileIndex] = lengthInMeters;
            }

            const newLengthProperty = new SmallNumericArrayProperty({values: newLengthsArray, unit: 'm'});

            const propsPatch = producePropsPatch(props, (props) => {
                props.piles.lengths = newLengthProperty; 
            });

            if (propsPatch) {
                instancesPatches.push([trackerId, {props: propsPatch}]);
            }
        }

        this.bim.instances.applyPatches(instancesPatches);

        if (unsupportedTrackerTypeIds.length) {
            this.logger.error('only any tracker piles patching is allowed', unsupportedTrackerTypeIds);
        };
        return {
            unsupportedTrackerTypeIds,
        }
    }

    applyProfilePatchTo(pilesIds: IdPile[], profile: PileProfileType): { unsupportedTrackerTypeIds: IdBimScene[]; } {
        if (pilesIds.length === 0) {
            return {unsupportedTrackerTypeIds: []};
        }
        const unsupportedTrackerTypeIds: IdBimScene[] = [];

        const piles = this.peekByIds(pilesIds);
        
        const groupedByParent = IterUtils.groupBy(
            piles,
            ([id, pile]) => pile.parentId,
        );

        const instancesPatches: [IdBimScene, SceneInstancePatch][] = [];

        for (const [trackerId, piles] of groupedByParent) {
            const parent = this.bim.instances.peekById(trackerId);
            if (!parent) {
                this.logger.batchedWarn('parent not found', trackerId);
                continue;
            }
            if (parent.type_identifier !== `any-tracker`) {
                unsupportedTrackerTypeIds.push(trackerId);
                continue;
            }

            const props = parent.props as AnyTrackerProps;
            
            let newProfilesArray: PileProfileType[];

            const defaultProfile =  PileProfileType.None;

            if (props.piles.profiles) {
                newProfilesArray = props.piles.profiles.values.slice();
            } else if (props.piles.active_configuration) {
                newProfilesArray = IterUtils.newArray(props.piles.active_configuration.features.length, () => defaultProfile);
            } else {
                newProfilesArray = [];
            }

            for (const [pileId, pile] of piles) {
                const pileIndex = pile.inArrayIndex;
                if (pileIndex > newProfilesArray.length) {
                    continue;
                }
                newProfilesArray[pileIndex] = profile;
            }

            const newProfilesProperty = PilesProfilesProperty.new(newProfilesArray);

            const propsPatch = producePropsPatch(props, (props) => {
                props.piles.profiles = newProfilesProperty; 
            });

            if (propsPatch) {
                instancesPatches.push([trackerId, {props: propsPatch}]);
            }
        }
        this.bim.instances.applyPatches(instancesPatches);

        if (unsupportedTrackerTypeIds.length) {
            this.logger.error('only any tracker piles patching is allowed', unsupportedTrackerTypeIds);
        };
        return {
            unsupportedTrackerTypeIds,
        }
    }

    private handle(diff: EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>) {
		if (diff instanceof Allocated) {
			const delta = this.handleAllocate(diff.ids);
			if (delta) {
				this.updatesStream.pushNext(delta);
			}

		} else if (diff instanceof EntitiesUpdated) {
            if ((diff.allFlagsCombined & this._relevantObjectsDiff) === 0) {
                return;
            }
			const updates = this.handleUpdate(diff.ids);
			for (const u of updates) {
				this.updatesStream.pushNext(u);
			}

		} else if (diff instanceof Deleted) {
			const delta = this.handleDelete(diff.ids);
			if (delta) {
				this.updatesStream.pushNext(delta);
			}
		}
    }

    private handleUpdate(ids: IdBimScene[]): EntitiesCollectionUpdates<IdPile, number>[] {
        const allDeletedIds: IdPile[] = [];
        const allNewIds: IdPile[] = [];
        for (const instanceId of ids) {

			const newPiles = this.createPiles(instanceId);
            const prevPiles = this.peekByIds(this.pilesPerTrackerId.iter(instanceId));

			if (IterUtils.areOptionalArraysEqual<TrackerPile>(Array.from(prevPiles.values()), newPiles, TrackerPile.equals)) {
				continue;
			}

            for (const id of this.pilesPerTrackerId.iter(instanceId)) {
                allDeletedIds.push(id);
                this.perId.delete(id);
            }
			if (newPiles) {
				for (const pile of newPiles) {
					const id = this._idsProvider.reserveNewId();
					this.perId.set(id, pile);
					allNewIds.push(id);
                    this.pilesPerTrackerId.add(instanceId, id);
				}
			}
        }
		let res: EntitiesCollectionUpdates<IdPile, number>[] = [];
		if (allDeletedIds.length) {
			res.push(new Deleted(allDeletedIds));
		}
		if (allNewIds.length) {
			res.push(new Allocated(allNewIds));
		}
		return res;
    }

    private handleDelete(ids: Iterable<IdBimScene>) {
        const deletedPiles: IdPile[] = [];
        const pilesIds: IdPile[] = [];
        for (const id of ids) {
            for (const pileId of this.pilesPerTrackerId.iter(id)) {
                deletedPiles.push(pileId);
                this.perId.delete(pileId);
                pilesIds.push(pileId);
            }
            for (const pileId of pilesIds) {
                this.pilesPerTrackerId.remove(id, pileId);
            }
            pilesIds.length = 0;
            if(this.pilesPerTrackerId.hasAnyRefsFrom(id)){
                this.logger.error('tracker still has refs', id);
            }
        }

        return deletedPiles.length ? new Deleted(deletedPiles) : null;
    }

    private handleAllocate(ids: IdBimScene[]) {
        const newPiles: IdPile[] = [];
        for (const trackerId of ids) {
            try {
                const piles = this.createPiles(trackerId);
                if (piles) {
                    for (const pile of piles) {
                        const id = this._idsProvider.reserveNewId();
                        this.perId.set(id, pile);
                        this.pilesPerTrackerId.add(trackerId, id);
                        newPiles.push(id);
                    }
                }
            } catch (e) {
                this.logger.batchedError('failed to allocate piles', e);
            }

        }
        return newPiles.length ? new Allocated(newPiles) : null;
    }

    private createPiles(id: IdBimScene): readonly TrackerPile[] | null {
		const inst = this.bim.instances.peekById(id);
        if (!inst) {
            return null;
        }
        if (inst.props instanceof AnyTrackerProps) {
            return extractPilesFromTrackerProps(id, inst.isHidden, inst.props, inst.worldMatrix);
        } else if (inst.props instanceof FixedTiltProps) {
            return extractPilesFromFixedTracker(id, inst);
        } else if (inst.props instanceof TrackerProps) {
            return extractPilesFromLegacyTracker(id, inst);
        }
        return null;
    }
}




export function createPricePropsForPiles(
    props: Map<string, BimProperty>,
    pileTypeToCatalogItem: Map<string, Readonly<CatalogItem<PileCatalogItemProps>>>,
  ) {
      const pileTypeFull = props.get('pile | position');
      const pileWeight = props.get('pile | weight');
      if (!pileTypeFull?.isText() || !pileWeight?.isNumeric()) {
          return;
      }
      const _pileType = pileTypesFullNameToShortName[pileTypeFull.value];
      if (_pileType === undefined) {
          return;
      }
      const pileType = _pileType as PileType;
      const pile = pileTypeToCatalogItem.get(pileType);
      if (pile === undefined) {
          return;
      }
      const cost = pileWeight.as('lb') * pile.properties.price_per_weight.value;
      const catalogItemProp = BimProperty.NewShared({
          path: ["pile", "catalog item"],
          value: pilesTypes[pileType].description
      });
      props.set(catalogItemProp._mergedPath, catalogItemProp);
      const priceProp = BimProperty.NewShared({
          path: ["pile", "price"],
          value: cost,
          unit: pile.properties.price_per_weight.unit,
      });
      props.set(priceProp._mergedPath, priceProp);
}
  
