import type { NumberProperty, Bim } from "bim-ts";
import { Failure, ObjectUtils } from "engine-utils-ts";
import type { CostReport } from "../../CostReport";
import { TableEntry } from "../../TableHierarchy";
import { fixRateByRateUnit, remapNumber, type QuantityRemapper } from "../Action";

export enum ReferenceType {
    Quantity,
    Rate,
    TotalCost
}

export const ReferenceTypeIdentifier = 'reference';

export class Reference {
    constructor(
        public entry = new TableEntry(),
        public targetPath: string[] = [],
        public targetType: ReferenceType = ReferenceType.Quantity,
        public selfType: ReferenceType = ReferenceType.Quantity,
        public remapper: QuantityRemapper | null = null,
    ) {}
}

export function registerReferenceAction(costReport: CostReport, bim: Bim) {
    costReport.actionSolvers.registerActionSolver<Reference>(
        ReferenceTypeIdentifier,
        (action, hierarchy, actionIdx) => {
            const target = hierarchy.getEntry(action.props.targetPath);
            if (!target) {
              console.warn('no entry found for reference')
              return;
            }

            const existingEntry = hierarchy.getEntry(action.props.entry.path);
            if (existingEntry) {
              console.warn('entry already exist')
              return;
            }

            const entry = ObjectUtils.deepCloneObj(action.props.entry)
            let refValue: NumberProperty | null = null;
            const targetType = action.props.targetType;
            if (targetType === ReferenceType.Quantity) {
                refValue = ObjectUtils.deepCloneObj(target.quantity);
            } else if (targetType === ReferenceType.Rate) {
                refValue = ObjectUtils.deepCloneObj(target.rate);
            } else if (targetType === ReferenceType.TotalCost) {
                refValue = ObjectUtils.deepCloneObj(target.totalCost);
            }
            if (!refValue) {
              console.warn('no entry found for reference')
              return;
            }

            if (action.props.remapper) {
              const remapped = remapNumber(refValue, action.props.remapper)
              if (remapped instanceof Failure) {
                console.error('can not remap');
                return;
              }
              refValue= remapped.value;
            }

            if (action.props.selfType === ReferenceType.Quantity) {
                entry.quantity = refValue;
            } else if (action.props.selfType === ReferenceType.Rate) {
                entry.rate = refValue;
            } else {
                entry.totalCost = refValue;
            }
            fixRateByRateUnit(entry, bim.unitsMapper);

            hierarchy.addEntry(entry);
            hierarchy.leafRelactulation(entry.path);
        }
    )

}
