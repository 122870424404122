import type { Bim, } from 'bim-ts';

export class EnergyViewContext {

    constructor(
        readonly bim: Bim,
    ) {
    }

}

