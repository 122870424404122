// automatically generated by the FlatBuffers compiler, do not modify

import type * as flatbuffers from 'flatbuffers';



export class Quaternion implements flatbuffers.IUnpackableObject<QuaternionT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Quaternion {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

x():number {
  return this.bb!.readFloat64(this.bb_pos);
}

y():number {
  return this.bb!.readFloat64(this.bb_pos + 8);
}

z():number {
  return this.bb!.readFloat64(this.bb_pos + 16);
}

w():number {
  return this.bb!.readFloat64(this.bb_pos + 24);
}

static sizeOf():number {
  return 32;
}

static createQuaternion(builder:flatbuffers.Builder, x: number, y: number, z: number, w: number):flatbuffers.Offset {
  builder.prep(8, 32);
  builder.writeFloat64(w);
  builder.writeFloat64(z);
  builder.writeFloat64(y);
  builder.writeFloat64(x);
  return builder.offset();
}


unpack(): QuaternionT {
  return new QuaternionT(
    this.x(),
    this.y(),
    this.z(),
    this.w()
  );
}


unpackTo(_o: QuaternionT): void {
  _o.x = this.x();
  _o.y = this.y();
  _o.z = this.z();
  _o.w = this.w();
}
}

export class QuaternionT implements flatbuffers.IGeneratedObject {
constructor(
  public x: number = 0.0,
  public y: number = 0.0,
  public z: number = 0.0,
  public w: number = 0.0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return Quaternion.createQuaternion(builder,
    this.x,
    this.y,
    this.z,
    this.w
  );
}
}
