import type { CompressibleNumbersArray} from 'engine-utils-ts';
import type { Result } from 'engine-utils-ts';
import { DefaultMap, Failure, LazyDerived, Success } from 'engine-utils-ts';
import type { Bim } from '../Bim';
import { StateType } from '../bimConfigs/ConfigsArchetypes';
import { NumberProperty} from '../properties/PrimitiveProps';
import { StringProperty } from '../properties/PrimitiveProps';
import { PropsGroupBase } from '../properties/Props';
import { PropsFieldArrayOf, PropsFieldFlags, PropsFieldOneOf } from '../properties/PropsGroupComplexDefaults';
import { PropsGroupsRegistry } from '../properties/PropsGroupsRegistry';
import { BasicSpreadsheetCellsProperty } from '../properties/spreadsheets/BasicSpreadsheetCellsProperty';
import { TMY_ColumnDates } from './TMY_ColumnDates';
import { TMY_ColumnGeneral } from './TMY_ColumnGeneral';
import { WGSCoord } from '..';
import { TMY_ColumnHeader } from './TMY_ColumnHeader';
import { generateSolarPositionsYearlyData } from '../energy/POA_Irradiance';


const TMY_ColumnSelectors = {
    Global_Horizontal_Irradiance: {variants: ['Global Horizontal Irradiance', 'Global Horizontal Radiation', 'GHI (W/m^2)', 'GHI']},
    Direct_Normal_Irradiance: {variants: ['Direct Normal Irradiance', 'Direct Normal Radiation', 'DNI (W/m^2))', 'DNI']},
    Diffuse_Horizontal_Irradiance: {variants: ['Diffuse Horizontal Irradiance', 'Diffuse Horizontal Radiation', 'DHI (W/m^2)', 'DHI']},
    Ambient_Temperature: {variants: ['Ambient Temperature', 'Dry Bulb Temperature', 'Dry-bulb (C)', 'Temp']},
    Wind_Speed: {variants: ['Wind Speed', 'Wspd (m/s)', 'WS']},
    Relative_Humidity: {variants: ['Relative Humidity', 'RH (%)', 'RH']},
} satisfies {[key:string]: TMY_DataFinder};

export type TMY_ColumnSelector = keyof typeof TMY_ColumnSelectors;

export class TmyLocationContext extends PropsGroupBase {
    geoLocation: StringProperty;
    altitude: NumberProperty; 
    timeZone: NumberProperty; 

    constructor(args: Partial<TmyLocationContext>) {
        super();
        this.geoLocation = args.geoLocation ?? StringProperty.new({value: ''});
        this.altitude = args.altitude ?? NumberProperty.new({value: 0, range: [-1000, 10000]});
        this.timeZone = args.timeZone ?? NumberProperty.new({value: 0, range: [-12, 12], step: 1});
    }

    tryGetWgsCoord(): Result<{lat: number, long: number, alt: number}> {
        if (!this.geoLocation.value) {
            return new Failure({uiMsg: 'Meteo data geographical location is not set'});
        }
        const parsed = WGSCoord.tryParseLatLongFromString(this.geoLocation.value);
        if (!parsed) {
            return new Failure({uiMsg: 'Meteo data geographical location is not valid'});
        }
        return new Success({
            lat: parsed.latitude,
            long: parsed.longitude,
            alt: this.altitude.value,
        });
    }
}
PropsGroupsRegistry.register({
    class: TmyLocationContext,
    complexDefaults: {
        geoLocation: new PropsFieldOneOf(
            PropsFieldFlags.None,
            StringProperty as any,
            null,
        ),
        altitude: new PropsFieldOneOf(
            PropsFieldFlags.None,
            NumberProperty as any,
            null,
        ),
        timeZone: new PropsFieldOneOf(
            PropsFieldFlags.None,
            NumberProperty as any,
            null,
        ),
    },
});

export class TMY_Props extends PropsGroupBase {

    readonly fileName: StringProperty;
    readonly prefixMetadata: BasicSpreadsheetCellsProperty;
    readonly datesColumns: TMY_ColumnDates[];
    readonly dataColumns: TMY_ColumnGeneral[];

    readonly locationContext: TmyLocationContext;

    #_columnsBySelector: DefaultMap<TMY_ColumnSelector, TMY_ColumnGeneral | null>;
    #_arraysBySelector: DefaultMap<TMY_ColumnSelector, CompressibleNumbersArray | null>;

    constructor(args: Partial<TMY_Props>) {
        super();
        this.fileName = args.fileName ?? StringProperty.new({value: '', isReadonly: true});
        this.prefixMetadata = args.prefixMetadata ?? new BasicSpreadsheetCellsProperty([]);
        this.datesColumns = args.datesColumns ?? [];
        this.dataColumns = args.dataColumns ?? [];

        this.locationContext = args.locationContext ?? new TmyLocationContext({});

        const columnsHeaders = this.dataColumns.map(c => c.header.toHeaderString());

        this.#_columnsBySelector = new DefaultMap(ty => {
            const dataFinder = TMY_ColumnSelectors[ty] as TMY_DataFinder;
            let bestIndexYet: number = -1;
            let bestScoreYet: number = -1;
            for (let i = 0; i < columnsHeaders.length; ++i) {
                let score = scoreDataNameColumntSimilarity(columnsHeaders[i], dataFinder.variants, dataFinder.exclude);
                if (score > 0.5 && score > bestScoreYet) {
                    bestIndexYet = i;
                    bestScoreYet = score;
                    if (score === 1) {
                        break;
                    }
                }
            }
            if (bestIndexYet >= 0) {
                return this.dataColumns[bestIndexYet];
            }
            if (dataFinder.fallbackCalculator) {
                try {
                    return dataFinder.fallbackCalculator(this);
                } catch (e) {
                    console.error(e);
                }
            }

            return null;
        });

        this.#_arraysBySelector = new DefaultMap(ty => {
            const col = this.#_columnsBySelector.getOrCreate(ty);
            if (col) {
                return col.mapToCompressibleArray(0);
            }
            return null;
        
        })

        Object.freeze(this.datesColumns);
        Object.freeze(this.dataColumns);
    }
    
    getDataColumn(col: TMY_ColumnSelector): TMY_ColumnGeneral | null {
        return this.#_columnsBySelector.getOrCreate(col);
    }

    getDataColumnAsCompressibleArray(col: TMY_ColumnSelector, defaultValue: number = 0): CompressibleNumbersArray | null {
        return this.#_arraysBySelector.getOrCreate(col);
    }

    static registerAsGlobalVar(bim: Bim) {
        const tmy_config = bim.configs.getLazyOptionalSingletonProps({type_identifier: 'typical-meteo-year', propsClass: TMY_Props});
        const config_or_default = LazyDerived.new1<Result<TMY_Props>, Result<TMY_Props>>(
            'TMY_Props.name',
            null,
            [tmy_config],
            ([configResult]) => {
                if (configResult instanceof Success) {
                    return configResult;
                }
                return new Success(syntheticDefaultTmy());
            }
        )
        // register as global variable
        bim.runtimeGlobals.registerByIdent<Result<TMY_Props>>(TMY_Props.name, config_or_default);
    }
}

PropsGroupsRegistry.register({
    class: TMY_Props,
    complexDefaults: {
        datesColumns: new PropsFieldArrayOf(
            {allowedLengthRange: [0, 10], userChangableLength: false},
            TMY_ColumnDates
        ),
        dataColumns: new PropsFieldArrayOf(
            {allowedLengthRange: [0, 100], userChangableLength: false},
            TMY_ColumnGeneral,
        ),
    },
});

export function registerTMY_Config(bim: Bim) {

    bim.configs.archetypes.registerArchetype({
        properties: () => ({}),
        stateType: StateType.OptionalSingleton,
        type_identifier: 'typical-meteo-year',
        propsClass: TMY_Props,
    });
    TMY_Props.registerAsGlobalVar(bim);
    
}


interface TMY_DataFinder {
    variants: string[];
    exclude?: string[];
    fallbackCalculator?: (tmy: TMY_Props) => TMY_ColumnGeneral | null;
}

function scoreDataNameColumntSimilarity(name: string, variants: string[], exclude?: string[]): number {

    const variantsSplit = variants.map(splitIntoCleanWords).filter(s => s.length > 0);
    const nameSplitClean = splitIntoCleanWords(name);

    let bestIndexYet: number = -1;
    let bestScoreYet: number = -1;
    for (let i = 0; i < variants.length; ++i) {
        const variantWords = variantsSplit[i];
        let wordsMatch = 0;
        let wordsMiss = 0;
        for (const word of variantWords) {
            if (nameSplitClean.includes(word)) {
                wordsMatch += 1;
            } else {
                wordsMiss += 1;
            }
        }
        for (const word of nameSplitClean) {
            if (word.length > 2 && !variantWords.includes(word)) {
                wordsMiss += 0.5;
            }
        }
        const score = wordsMatch / (wordsMatch + wordsMiss);
        // const score = StringUtils.fuzzyScore(name, variants[i]);
        if (score > bestScoreYet) {
            bestIndexYet = i;
            bestScoreYet = score;
            if (bestScoreYet === 1) {
                break;
            }
        }
    }
    return bestScoreYet;
}

function splitIntoCleanWords(s: string): string[] {
    if (s.includes('_')) {
        s = s.replace(/_/g, ' ');
    }
    const splitCamelCase = s.replace(/([a-z])([A-Z])/g, '$1 $2');
    const splitWords = splitCamelCase.split(/(?=[.\s]|\b)/).map(s => s.toLowerCase().trim()).filter(s => s.length > 1);
    return splitWords;
}

export const SytheticDefaultTmyName = 'Synthetic Default TMY';

function syntheticDefaultTmy(): TMY_Props {

    const geoLocation = [39.4954, -31.2754];

    const locationContext = new TmyLocationContext({
        altitude: NumberProperty.new({value: 0, isReadonly: true}),
        geoLocation: StringProperty.new({value: geoLocation.join(','), isReadonly: true}),
        timeZone: NumberProperty.new({value: -1, step: 1, isReadonly: true}),
    });

    const sun_calculations_result = generateSolarPositionsYearlyData({
        altitude: locationContext.altitude.value,
        latitude: geoLocation[0],
        longitude: geoLocation[1],
        timeZone: locationContext.timeZone.value,
    });

    if (sun_calculations_result instanceof Failure) {
        throw new Error('failed to generate baisc solar data for synthetic TMY');
    }

    const sun_calculations = sun_calculations_result.value;

    const scalerMax = sun_calculations.sun_height.max()!;
    const softer_scaler_normalized = sun_calculations.sun_height.asArrayMapped(v => Math.pow(v / scalerMax, 0.6));
    const softer_scaler_normalized_2 = softer_scaler_normalized.map((v, i) => ((i/24)|0) % 2 === 0 ? v : v * 0.1);

    type MapValues<K, V> = {[key in keyof K]: V};

    const dataColumns: MapValues<typeof TMY_ColumnSelectors, TMY_ColumnGeneral> = {
        Global_Horizontal_Irradiance: generateTmyNumbersColumn('Global Horizontal Irradiance', 'W/m2', softer_scaler_normalized_2, 1050, 0),
        Direct_Normal_Irradiance: generateTmyNumbersColumn('Direct Normal Irradiance', 'W/m2', softer_scaler_normalized_2, 950, 0),
        Diffuse_Horizontal_Irradiance: generateTmyNumbersColumn('Diffuse Horizontal Irradiance', 'W/m2', softer_scaler_normalized_2, 400, 0),
        Ambient_Temperature: generateTmyNumbersColumn('Ambient Temperature', 'C', softer_scaler_normalized, 25, 15),
        Wind_Speed: generateTmyNumbersColumn('Wind Speed', 'm/s', softer_scaler_normalized, 20, 1),
        Relative_Humidity: generateTmyNumbersColumn('Relative Humidity', '%', softer_scaler_normalized, 80, 40),
    };

    return new TMY_Props({
        fileName: new StringProperty({value: SytheticDefaultTmyName, isReadonly: true}),
        prefixMetadata: new BasicSpreadsheetCellsProperty([]),
        locationContext,
        dataColumns: Object.values(dataColumns),
        datesColumns: [generateTmyDatesColumn("Date/Time", Date.UTC(1511, 0, 1, 0))],
    })
}

function generateTmyNumbersColumn(
    name: string,
    unit: string,
    scaleBy: Float32Array,
    desiredHourlyMax: number,
    minValue: number,
): TMY_ColumnGeneral {
    const values: number[] = [];

    for (let i = 0; i < 8760; ++i) {
        const dailyMultiplier = scaleBy.at(i)!;
        const value = dailyMultiplier * desiredHourlyMax;
        values.push(Math.max(value, minValue));
    }
    return TMY_ColumnGeneral.newFromValues(
        new TMY_ColumnHeader({
            name: name,
            unit: unit,
            raw: [name, unit],
        }),
        values,
    );
}

function generateTmyDatesColumn(
    name: string,
    startingDateUc: number,
): TMY_ColumnDates {
    const header = new TMY_ColumnHeader({name: name, raw: [name]});
    const dates = new Array(8760).fill(0).map((_, i) => startingDateUc + i * 3_600_000);
    return new TMY_ColumnDates(header, new Float64Array(dates));
}
