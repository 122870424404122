import { WGSCoord, type Bim, TMY_Props, type TMY_ColumnSelector, producePatchedProps } from 'bim-ts';
import type { LazyVersioned, ResultAsync} from 'engine-utils-ts';
import { Failure, LazyDerived, Success } from 'engine-utils-ts';
import { PUI_CustomPropertyNode, PUI_GroupNode, PUI_PropertyNodeNumber, PUI_PropertyNodeString, type PUI_PropertyNodeStringArgs } from 'ui-bindings';


export function lazySiteMeteoFileName(bim: Bim): LazyVersioned<string> {
    return LazyDerived.new1(
        'meteo file name',
        [],
        [
            bim.runtimeGlobals.getAsLazyVersionedByIdent(TMY_Props.name, TMY_Props),
        ],
        ([runtimeTmy]) => {
            if (runtimeTmy instanceof Success) {
                return runtimeTmy.value.fileName.value
            }
            return '';
        }
    ).withoutEqCheck();
}


export function meteoFileDetailsUi(bim: Bim, meteoFileParsingResultLazy: LazyVersioned<MeteoPropertyParseResult[]>) {

    return LazyDerived.new2(
        'meteo location context ui',
        [],
        [
            bim.runtimeGlobals.getAsLazyVersionedByIdent(TMY_Props.name, TMY_Props),
            meteoFileParsingResultLazy
        ],
        ([runtimeTmy, fileParsingResult]) => {
            const pui = new PUI_GroupNode({name: 'geo-location', sortChildren: false});

            if (!(runtimeTmy instanceof Success)) {
                return pui;
            }
            const tmy = runtimeTmy.value;

            const tmyLocation = tmy.locationContext;

            const geoLcationStringNodeParams: PUI_PropertyNodeStringArgs = {
                name: 'Geo coordinates',
                parent: pui,
                value: tmyLocation.geoLocation.value,
                readonly: tmyLocation.geoLocation.isReadonly,
                typeSortKeyOverride: 1,
                validator: (newValue: string | null) => {
                    if (newValue == '') {
                        return new Failure({msg:'Invalid format'});
                        // return new Success(null);
                    }
                    if (newValue == null) {
                        return new Failure({msg:'Invalid format'});
                    }
                    const newOrigin = WGSCoord.tryParseLatLongFromString(newValue);
                    if (newOrigin) {
                        return new Success(newValue);
                    } else {
                        return new Failure({msg:'Invalid format'});
                    }
                },
                onChange: (newValue: string | null) => {
                    const newOrigin = WGSCoord.tryParseLatLongFromString(newValue ?? '');
                    if (!newOrigin) {
                        return;
                    }
                    const propsPatch = producePatchedProps(tmy, (tmyProps) => {
                        tmyProps.locationContext.geoLocation = tmyProps.locationContext.geoLocation.withDifferentValue(newValue!);
                    });
                    if (!propsPatch) {
                        return;
                    }
                    if (newOrigin) {
                        bim.configs.applyPatchToSingleton(
                            'typical-meteo-year',
                            {props: propsPatch},
                        )
                    }
                },
            };
            const locationPropsGroup = new PUI_GroupNode({
                name: 'location props',
                showTitle: false,
                collapsible: false
            });
            pui.addMaybeChild(locationPropsGroup);
            locationPropsGroup.addMaybeChild(new PUI_PropertyNodeString(geoLcationStringNodeParams));

            locationPropsGroup.addMaybeChild(new PUI_PropertyNodeNumber({
                name: 'Altitude',
                value: tmy.locationContext.altitude.value,
                readonly: tmy.locationContext.altitude.isReadonly,
                minMax: tmy.locationContext.altitude.range,
                unit: 'm',
                step: 1,
                typeSortKeyOverride: 3,
                onChange: (newValue) => {
                    const propsPatch = producePatchedProps(tmy, (tmyProps) => {
                        tmyProps.locationContext.altitude = tmyProps.locationContext.altitude.withDifferentValue(newValue);
                    });
                    if (!propsPatch) {
                        return;
                    }
                    bim.configs.applyPatchToSingleton(
                        'typical-meteo-year',
                        {props: propsPatch},
                    )
                }
            }));

            locationPropsGroup.addMaybeChild(new PUI_PropertyNodeNumber({
                name: 'Timezone',
                value: tmy.locationContext.timeZone.value,
                readonly: tmy.locationContext.timeZone.isReadonly,
                minMax: tmy.locationContext.timeZone.range,
                step: 1,
                typeSortKeyOverride: 2,
                onChange: (newValue) => {
                    const propsPatch = producePatchedProps(tmy, (tmyProps) => {
                        tmyProps.locationContext.timeZone = tmyProps.locationContext.timeZone.withDifferentValue(newValue);
                    });
                    if (!propsPatch) {
                        return;
                    }
                    bim.configs.applyPatchToSingleton(
                        'typical-meteo-year',
                        {props: propsPatch},
                    )
                }
            }));

            const meteoPropsGroup = new PUI_GroupNode({
                name: 'meteo props',
                showTitle: false,
                collapsible: false,
                sortChildren: false
            });
            pui.addMaybeChild(meteoPropsGroup);

            fileParsingResult.forEach(element => {
                const propName = element.context.propName;
                meteoPropsGroup.addMaybeChild(new PUI_PropertyNodeString({
                    name: propName,
                    value: element.value,
                    calculated: true,
                    onChange: () => {},
                    validator: (newValue: string | null) => {
                        if (element.error) {
                            return new Failure({uiMsg:`Values in ${element.context.columnName} column are out of range`});
                        }
                        return new Success(newValue);
                    },
                }));

                if (element.warning || element.error) {
                    meteoPropsGroup.addMaybeChild(new PUI_CustomPropertyNode({
                        name: propName + '_error',
                        type_ident: 'error_message',
                        value: element.error || element.warning,
                        context: {
                            color: element.warning ? 'text-main-medium' : 'text-danger'
                        },
                        onChange: () => {}
                    }));
                }
            });

            return pui;
        }
    ).withoutEqCheck();
}

interface MeteoPropertyInfo {
    columnName: TMY_ColumnSelector;
    propName: string;
    lossName: string;
    acceptableRange?: number[];
    requiredRange: number[];
    unit: string;
    required: boolean;
}

const MeteoFileProps: MeteoPropertyInfo[] = [
    {
        columnName: "Global_Horizontal_Irradiance",
        propName: 'Horizontal irradiance',
        lossName: 'Horizontal irradiance',
        requiredRange: [0, 5000],
        unit: 'W/m2',
        required: true
    }, {
        columnName: "Direct_Normal_Irradiance",
        propName: 'Direct irradiance',
        lossName: 'Horizontal irradiance',
        requiredRange: [0, 5000],
        unit: 'W/m2',
        required: true
    }, {
        columnName: "Diffuse_Horizontal_Irradiance",
        propName: 'Diffuse irradiance',
        lossName: 'Horizontal irradiance',
        requiredRange: [0, 5000],
        unit: 'W/m2',
        required: true
    }, {
        columnName: "Ambient_Temperature",
        propName: 'Temperature',
        lossName: 'Themral',
        acceptableRange: [-30, 60],
        requiredRange: [-100, 100],
        unit: 'C',
        required: false
    }, {
        columnName: "Wind_Speed",
        propName: 'Wind speed',
        lossName: 'Themral',
        acceptableRange: [0, 30],
        requiredRange: [0, 100],
        unit: 'm/s',
        required: false
    }, {
        columnName: "Relative_Humidity",
        propName: 'Humidity',
        lossName: 'Spectral',
        requiredRange: [0, 100],
        unit: '%',
        required: false
    }
];

interface MeteoPropertyParseResult {
    context: MeteoPropertyInfo;
    value: string;
    warning?: string;
    error?: string;
}

export function parseMeteoFile (
    tmyProps: LazyVersioned<ResultAsync<TMY_Props>>,
): LazyVersioned<MeteoPropertyParseResult[]> {
    return LazyDerived.new1(
        'meteo-validate-result',
        [],
        [tmyProps],
        ([tmy]) => {
            if (tmy instanceof Success) {
                return MeteoFileProps.map(prop => parseData(tmy.value, prop));
            }
            return [];
        });
}

function parseData(tmy: TMY_Props, context: MeteoPropertyInfo): MeteoPropertyParseResult {
    const {columnName, lossName, acceptableRange, requiredRange, unit} = context;
    const columnData = tmy.getDataColumnAsCompressibleArray(columnName);
    
    if (columnData?.length) {
        const requiredMin = requiredRange[0];
        const requiredMax = requiredRange[1];
        const acceptableMin = acceptableRange ? acceptableRange[0] : 0;
        const acceptableMax = acceptableRange ? acceptableRange[1] : 0;

        const min = columnData.min()!;
        const max = columnData.max()!;
        const valueStr = `${min.toFixed(2)} — ${max.toFixed(2)} ${unit}`;
        
        if (min >= (acceptableMin || requiredMin) && max <= (acceptableMax || requiredMax)) {
            return {
                context,
                value: valueStr,
            }
        }

        let outOfAcceptableRange = 0;
        let outOfRequiredRange = 0;
        const values = columnData.toArray();

        for (let i = 0; i < values.length; i++) {
            const val = values[i];
            if (val < requiredMin || val > requiredMax) {
                outOfRequiredRange++;
            } else if (acceptableRange && (val < acceptableMin || val > acceptableMax)) {
                outOfAcceptableRange++;
            }
        }
        const error = outOfRequiredRange
            ? `${outOfRequiredRange} of ${columnData.length} records are outside of required ${requiredMin} — ${requiredMax} ${unit} range. 
                ${lossName} loss can't be calculated.`
            : undefined;
        const warning = !error && acceptableRange && outOfAcceptableRange
            ? `${outOfAcceptableRange} of ${columnData.length} records are outside of the reasonable ${acceptableMin} — ${acceptableMax} ${unit} range. 
                 Meteo data might be corrupted or not reliable.`
            : undefined;
        return {
            context,
            value: valueStr,
            warning,
            error,
        }
    }

    return {
        context,
        value: '—',
        error: `No data. ${context.lossName} loss can't be calculated.`
    }
}
