import type { LazyVersioned, ResultAsync, ScopedLogger, VersionedValue, Result, LazyVersionedPollingCache} from 'engine-utils-ts';
import { Failure, InProgress, LazyBasic, LazyDerived, LazyDerivedAsync, ObservableObject, ObservableStream, Success, VersionedInvalidator, Yield, isResult } from 'engine-utils-ts';


type SharedDependencyClass<SD extends Object> = {new (...args: any): SD};
type AnyPropsGroupCtor = SharedDependencyClass<any>;

export interface GlobalArgsSelector {
    [key: string]: AnyPropsGroupCtor;
}

export type SharedGlobalsInput<T extends GlobalArgsSelector> = {
    [P in keyof T]:  Result<InstanceType<T[P]>>;
};

export type AnySharedDependenciesInput = SharedGlobalsInput<GlobalArgsSelector>;

export class RuntimeGlobals implements VersionedValue {

    readonly logger: ScopedLogger;
    readonly _dependenciesByIdent: Map<string, AnyHoldedDependency> = new Map();

    private readonly _invalidator = new VersionedInvalidator();

    readonly updatesStream: ObservableStream<string> = new ObservableStream({
        identifier: 'RuntimeSharedDependencies.updatesStream',
    });


    constructor(logger: ScopedLogger) {
        this.logger = logger.newScope('shar-deps');
    }

    version(): number {
        return this._invalidator.version();
    }

    allRegisteredIdents(): Iterable<string> {
        return this._dependenciesByIdent.keys();
    }

    registerByIdent<T extends object>(ident: string, producer: LazyVersioned<T | Result<T>>) {
        if (producer instanceof LazyDerivedAsync) {
            throw new Error('use registerAsyncByIdent for async producers');
        }
        this.logger.assert(this._dependenciesByIdent.has(ident) === false, `singleton already registered: ${ident}`);
        const dependency = new HoldedDependency(producer);
        this._invalidator.addDependency(dependency);
        this._dependenciesByIdent.set(ident, dependency);
    }

    registerAsyncByIdent<T extends object>(ident: string, producer: LazyVersioned<ResultAsync<T>>) {
        this.logger.assert(this._dependenciesByIdent.has(ident) === false, `singleton already registered: ${ident}`);
        const dependency = new HoldedAsyncDependency(producer);
        this._invalidator.addDependency(dependency);
        this._dependenciesByIdent.set(ident, dependency);
    }
    
    tryGetAsObservable<T extends object>(ident: string): ObservableObject<T> | null {
        const dep = this._dependenciesByIdent.get(ident);
        if (dep instanceof HoldedDependency && dep.producer instanceof ObservableObject) {
            return dep.producer;
        }
        return null;
    }

    _hasPendingUpdates() {
        for (const dep of this._dependenciesByIdent.values()) {
            if (dep.isUpdatePending()) {
                return true;
            }
        }
        return false;
    }

    getDeferredInvalidatorOf<Deps extends GlobalArgsSelector>(deps: Deps): VersionedInvalidator {
        // this invalidator does not proactively polls dependencies

        const invalidators: VersionedValue[] = [];
        for (const ident in deps) {
            const dep = this._dependenciesByIdent.get(ident);
            if (dep instanceof HoldedDependency) {
                invalidators.push(dep);
            } else if (dep instanceof HoldedAsyncDependency) {
                invalidators.push(dep);
            } else {
                this.logger.error(`getLazyInvalidatorFor: missing dependency: ${ident}`);
            }
        }
        return new VersionedInvalidator(invalidators);
    }

    *acquireTypedObj<Deps extends GlobalArgsSelector>(deps: Deps): Generator<Yield, SharedGlobalsInput<Deps>> {

        const result = yield* this.acquireByIdents(Object.keys(deps));

        for (const ident in deps) {
            const valueRes = result[ident];
            if (valueRes === undefined) {
                const errorMsg = `dependency was not acquired for ident: ${ident}, impl error`;
                this.logger.error(errorMsg);
                result[ident] = new Failure({msg: errorMsg})
            } else if (valueRes instanceof Success) {
                const value = valueRes.value;
                const expectedType = deps[ident];
                if (!(value instanceof expectedType)) {
                    const errorMsg = `unexpected result type: ${value} != ${expectedType}`;
                    this.logger.error(errorMsg);
                    result[ident] = new Failure({msg: errorMsg})
                }
            }
        }

        return result as SharedGlobalsInput<Deps>;
    }

    // polling of dependencies happens only inside this routine
    // if dependency version changes, its identifier is pushed to update stream
    *acquireByIdents(idents: string[]): Generator<Yield, {[key: string]: Result<Object>}> {
        const results: {[key: string]: Result<Object>} = {} as any;

        const asyncIdentsLeft: string[] = [];
        const syncIdents: string[] = [];

        for (const ident of idents) {
            const dep = this._dependenciesByIdent.get(ident);
            if (dep instanceof HoldedDependency) {
                syncIdents.push(ident);
            } else if (dep instanceof HoldedAsyncDependency) {
                asyncIdentsLeft.push(ident);
            } else {
                results[ident] = new Failure({msg: `missing dependency: ${ident}`});
            }
        }

        while (asyncIdentsLeft.length > 0) {
            for (let i = asyncIdentsLeft.length - 1; i >= 0; i--) {
                const ident = asyncIdentsLeft[i];
                const dep = this._dependenciesByIdent.get(ident as string) as HoldedAsyncDependency<Object>;

                const versionBeforePoll = dep.version();
                const res = dep.poll();
                if (dep.version() !== versionBeforePoll) {
                    this.updatesStream.pushNext(ident);
                    yield Yield.Asap;
                }

                let result: Result<any>;
                if (isResult(res)) {
                    result = res;
                } else if (res instanceof InProgress) {
                    continue;
                } else {
                    result = new Failure({msg: `unexpected result type: ${res}`});
                }

                results[ident] = result;
                asyncIdentsLeft.splice(i, 1);
            }
            if (asyncIdentsLeft.length > 0) {
                yield Yield.NextFrame;
            }
        }

        for (const ident of syncIdents) {
            const dep = this._dependenciesByIdent.get(ident as string) as HoldedDependency<Object>;
            
            const versionBeforePoll = dep.version();
            let result = dep.pollResult();
            if (dep.version() !== versionBeforePoll) {
                this.updatesStream.pushNext(ident);
                yield Yield.Asap;
            }
            results[ident] = result;
        }

        return results;
    }

    getAsLazyVersionedByIdent<T>(ident: string, ctor?: {new (args: any): T}): LazyVersioned<ResultAsync<T>> {
        const dep = this._dependenciesByIdent.get(ident);
        if (dep === undefined) {
            return new LazyBasic(ident, new Failure({msg: 'missing'}));
        }
        if (dep instanceof HoldedDependency) {
            const lazy = LazyDerived.new1<ResultAsync<T>, Object>(
                ident + '-derived-by-ident',
                null,
                [dep.producer],
                ([produced]) => {
                    if (isResult(produced)) {
                        if (produced instanceof Success) {
                            if (ctor) {
                                if (!(produced.value instanceof ctor)) {
                                    return new Failure({msg: `unexpected type: ${produced} != ${ctor}`});
                                }
                            }
                        }
                        return produced;
                    }
                    if (ctor) {
                        if (!(produced instanceof ctor)) {
                            return new Failure({msg: `unexpected type: ${produced} != ${ctor}`});
                        }
                    }
                    return new Success(produced as T);
                }
            );
            if (dep.producer instanceof ObservableObject) {
                lazy.withoutEqCheck();
            }
            return lazy;
        } else if (dep instanceof HoldedAsyncDependency) {
            return dep.producer as LazyVersioned<ResultAsync<T>>;
        } else {
            throw new Error(`unexpected dependency type`);
        }
    }

}

type AnyHoldedDependency = HoldedDependency<Object> | HoldedAsyncDependency<Object>;

class HoldedDependency<T extends Object> {

    readonly producer: LazyVersioned<T>;

    _lastVersion: number = -1;
    _lastResult: Result<T> | null = null;

    constructor(
        producer: LazyVersioned<T>,
    ) {
        this.producer = producer;
    }

    isUpdatePending() {
        const areVersionsDifferent = this._lastVersion !== this.producer.version();
        return areVersionsDifferent;
    }

    version() {
        return this._lastVersion;
    }

    pollResult(): Result<T> {
        const { value: res, version } = this.producer.pollWithVersion();
        if (this._lastVersion !== version) {
            this._lastVersion = version;
            let result: Result<T>;
            if (isResult(res)) {
                result = res;
            } else if (res !== undefined) {
                result = new Success(res);
            } else {
                result = new Failure({msg: `unexpected result type: ${res}`});
            }
            this._lastResult = result;
        }
        return this._lastResult!;
    }
}

class HoldedAsyncDependency<T extends Object> {

    producer: LazyVersioned<ResultAsync<T>>;

    _lastVersion: number = -1;
    _lastResult: ResultAsync<T> | null = null;

    constructor(
        producer: LazyVersioned<ResultAsync<T>>,
    ) {
        this.producer = producer;
    }

    isUpdatePending() {
        return this._lastResult === null
            || this._lastResult instanceof InProgress
            || this._lastVersion !== this.producer.version();
    }

    version() {
        return this._lastVersion;
    }

    poll(cache?: LazyVersionedPollingCache): ResultAsync<T> {
        const res = this.producer.pollWithVersion(cache);
        this._lastVersion = res.version;
        this._lastResult = res.value;
        return res.value;
    }

}