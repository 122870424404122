// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class Range implements flatbuffers.IUnpackableObject<RangeT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Range {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsRange(bb:flatbuffers.ByteBuffer, obj?:Range):Range {
  return (obj || new Range()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsRange(bb:flatbuffers.ByteBuffer, obj?:Range):Range {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Range()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

min():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

max():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

static startRange(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addMin(builder:flatbuffers.Builder, min:number) {
  builder.addFieldFloat64(0, min, 0.0);
}

static addMax(builder:flatbuffers.Builder, max:number) {
  builder.addFieldFloat64(1, max, 0.0);
}

static endRange(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createRange(builder:flatbuffers.Builder, min:number, max:number):flatbuffers.Offset {
  Range.startRange(builder);
  Range.addMin(builder, min);
  Range.addMax(builder, max);
  return Range.endRange(builder);
}

unpack(): RangeT {
  return new RangeT(
    this.min(),
    this.max()
  );
}


unpackTo(_o: RangeT): void {
  _o.min = this.min();
  _o.max = this.max();
}
}

export class RangeT implements flatbuffers.IGeneratedObject {
constructor(
  public min: number = 0.0,
  public max: number = 0.0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return Range.createRange(builder,
    this.min,
    this.max
  );
}
}
