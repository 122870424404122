import { joinAnchorsWithThreeDotsSymbol } from "src/sld/templates/common";
import { blocksYOffset } from "src/sld/templates/config";
import type { EquipmentDescription } from "src/sld/types";
import type { AnyAnchor2D } from "vector-graphic";
import { Anchors2D } from "vector-graphic";

export function positionChildrensShortend<T extends EquipmentDescription>(
    childDescriptions: T[][],
    createObject2D: (x: T, index: number) => AnyAnchor2D
): Anchors2D {
    let childCount = 0;
    const anchors: Anchors2D[] = []
    for (const group of childDescriptions) {
        if (!group.length) {
            continue;
        }
        // add first group
        const descriptions: Array<[T, index: number]> = [[group[0], childCount]];

        // add last group
        if (group.length > 1) {
            descriptions.push([group.at(-1)!, childCount + group.length - 1]);
        }

        const objects = descriptions.map(([x, index]) => createObject2D(x, index));

        if (group.length > 2) {
            anchors.push(joinAnchorsWithThreeDotsSymbol(objects));
        } else {
            anchors.push(Anchors2D.stackAnchorsAndMerge(objects, { yOffset: blocksYOffset }));
        }
        childCount += group.length;
    }
    const object = Anchors2D.stackAnchorsAndMerge(anchors, { yOffset: blocksYOffset * 2 });
    return object;
}
