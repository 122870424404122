import { PropertyViewBasicTypes, type Bim, type BasicPropertyDescription } from 'bim-ts';
import { ObservableObject, type LazyVersioned, LazyDerived } from 'engine-utils-ts';
import { PUI_GroupNode, PUI_PropertyNodeBool, PUI_PropertyNodeMultiSelector, PUI_PropertyNodeNumber, PUI_PropertyNodeSelector } from 'ui-bindings';


export interface PropsChartParams {
    object_types: string[];
    property_path: string | null;
    divide_by: string | null;
    round_min_max: boolean;
    auto_bin_count: boolean;
    bins_count: number;
}

export function createPropsChartParams(
    bim: Bim,
): [
    ObservableObject<PropsChartParams>,
    LazyVersioned<PUI_GroupNode>,
] {

    const chartParamsObs = new ObservableObject<PropsChartParams>({
        identifier: 'props-chart',
        initialState: {
            object_types: [],
            property_path: null,
            divide_by: null,
            round_min_max: true,
            auto_bin_count: true,
            bins_count: 7,
        }
    });
    chartParamsObs.observeObject({
        settings: {doNotNotifyCurrentState: true, immediateMode: true},
        onPatch: ({currentValueRef}) => {
            try {
                localStorage.setItem('props-charts-settings', JSON.stringify(currentValueRef));
            } catch (e) {
                console.error(e);
            }
        },
    });

    try {
        const settings = localStorage.getItem('props-charts-settings');
        if (settings) {
            const asObj = JSON.parse(settings) as PropsChartParams;
            chartParamsObs.applyPatch({
                patch: asObj
            })
        }
    } catch (e) {
        console.error(e);
    }

    const validObjsTypes = bim.instances.getLazyKnownTypes();


    const chartParamsUi = LazyDerived.new3<PUI_GroupNode, PropsChartParams, string[], BasicPropertyDescription[]>(
        'propsChartParamsUi',
        [],
        [chartParamsObs, validObjsTypes, bim.instances.basicPropsView.asLazyVersioned()],
        ([chartParams, validObjsTypes, validProps]) => {

            const node = new PUI_GroupNode({
                name: 'params',
                sortChildren: false,
            });
            node.addMaybeChild(new PUI_PropertyNodeMultiSelector({
                name: 'object type',
                options: validObjsTypes.map(ty => ({value: ty})),
                value: chartParams.object_types.filter(t => validObjsTypes.includes(t)).map(ty => ({value: ty})),
                onChange: (newValue) => {
                    const newValues = newValue.map(t => t.value) as string[];
                    
                    const allowedPropsForSelectedTypes = newValues.length > 0 ?
                        validProps.filter(t => t.isFoundInTypeIdends(newValues)) : validProps;
                    const numericProps = allowedPropsForSelectedTypes.filter(t => t.basicTypes & PropertyViewBasicTypes.Numeric).map(t => t.label);
                    const isCountByPropertyAllowed = numericProps.includes(chartParams.divide_by!);
                    
                    chartParamsObs.applyPatch({
                        patch: {
                            object_types: newValues,
                            divide_by: isCountByPropertyAllowed ? chartParams.divide_by! : null,
                        },
                    });
                },
            }));

            const knownPropertyDescription = validProps.find(d => d.label == chartParams.property_path);

            const isPropertyNumeric = !!(knownPropertyDescription?.basicTypes! &
                (PropertyViewBasicTypes.Numeric | PropertyViewBasicTypes.NumericArray)
            );

            const allowedPropsForSelectedTypes = chartParams.object_types.length > 0 ?
                validProps.filter(t => t.isFoundInTypeIdends(chartParams.object_types)) : validProps;

            node.addMaybeChild(new PUI_PropertyNodeSelector({
                name: 'property',
                value: knownPropertyDescription ? chartParams.property_path : null,
                options: allowedPropsForSelectedTypes.map(t => t.label),
                onChange: (newValue) => {
                    chartParamsObs.applyPatch({
                        patch: {
                            property_path: newValue,
                        },
                    });
                },
            }));


            if (!isPropertyNumeric) {
                return node;
            }
            
            const numericProps = allowedPropsForSelectedTypes.filter(t => t.basicTypes & PropertyViewBasicTypes.Numeric).map(t => t.label);

            const noDivideOption = 'none';
            const options = [noDivideOption, ...numericProps];

            const isCountByPropertyAllowed = numericProps.includes(chartParams.divide_by!);


            node.addMaybeChild(new PUI_PropertyNodeSelector({
                name: 'divide by',
                value: isCountByPropertyAllowed ? chartParams.divide_by : "none",
                options: options,
                onChange: (newValue) => {
                    chartParamsObs.applyPatch({
                        patch: {
                            divide_by: newValue == noDivideOption ? null : newValue,
                        },
                    });
                },
            }));

            node.addMaybeChild(new PUI_PropertyNodeBool({
                name: 'round min max',
                value: chartParams.round_min_max,
                onChange: (newValue) => {
                    chartParamsObs.applyPatch({
                        patch: {
                            round_min_max: newValue,
                        },
                    });
                },
            }));

            node.addMaybeChild(new PUI_PropertyNodeBool({
                name: 'auto bin count',
                value: chartParams.auto_bin_count,
                onChange: (newValue) => {
                    chartParamsObs.applyPatch({
                        patch: {
                            auto_bin_count: newValue,
                        },
                    });
                },
            }));

            if (!chartParams.auto_bin_count) {
                node.addMaybeChild(new PUI_PropertyNodeNumber({
                    name: 'bins count',
                    value: chartParams.bins_count,
                    step: 1,
                    readonly: chartParams.auto_bin_count,
                    calculated: chartParams.auto_bin_count,
                    minMax: [1, 100],
                    onChange: (newValue) => {
                        chartParamsObs.applyPatch({
                            patch: {
                                bins_count: newValue,
                            },
                        });
                    },
                }));
            }
            return node;
        }
    );

    return [
        chartParamsObs,
        chartParamsUi,
    ]
}