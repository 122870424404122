export interface Sample {
    v: number; // value
    o: number; // offset
    h: number; // height
}


interface Pattern {
    indices: number[];
    h: number;
    v: number;
}


function make_patterns(samples: Sample[], max_size: number): Pattern[] {
    const n = samples.length;
    const stack: Pattern[] = [{ indices: [], h: 0, v: 0 }];
    const patterns: Pattern[] = [];
    while (stack.length) {
        const pattern = stack.pop()!;
        if (pattern.v > 0) {
            patterns.push(pattern);
        }
        for (let i = (pattern.indices.at(-1) ?? -1) + 1; i < n; ++i) {
            const m = max_size - pattern.indices.length;
            for (let j = 1; j <= m; ++j) {
                stack.push({
                    indices: pattern.indices.concat(Array<number>(j).fill(i)),
                    h: pattern.h + j * samples[i].h,
                    v: pattern.v + j * samples[i].v
                });
            }
        }
    }
    patterns.sort((a, b) => a.h === b.h ? b.v - a.v : a.h - b.h);
    const result: Pattern[] = [{ indices: [], h: 0, v: 0 }];
    let v_min = 0;
    for (let pattern of patterns) {
        if (pattern.v > v_min) {
            result.push(pattern);
            v_min = pattern.v;
        }
    }
    return result;
}


function bisect_left<T>(
    array: T[],
    value: number,
    key: (arg: T) => number
): number {
    let lo = 0, hi = array.length;
    while (lo < hi) {
        const mid = lo + ((hi - lo) >>> 1);
        if (key(array[mid]) < value) {
            lo = mid + 1;
        } else {
            hi = mid;
        }
    }
    return lo;
}


function bisect_right<T>(
    array: T[],
    value: number,
    key: (arg: T) => number
): number {
    let lo = 0, hi = array.length;
    while (lo < hi) {
        const mid = lo + ((hi - lo) >>> 1);
        if (value < key(array[mid])) {
            hi = mid;
        } else {
            lo = mid + 1;
        }
    }
    return lo;
}


interface Item {
    y_min: number;
    y: number;
    y_max: number;
    score: number;
}


function make_items(
    pairs: [number, number][],
    y: number,
    offset: number,
    patterns: Pattern[],
    find_score: (value: number) => number,
    lines: [number, number, number][],
): Item[] {
    const nr = patterns.length, nc = pairs.length, m = lines.length;
    const fst = Array.from({ length: nr }, () => Array<number>(nc).fill(0));
    const snd = Array.from({ length: nr }, () => Array<number>(nc).fill(0));
    for (const [c, [x_min, x_max]] of pairs.entries()) {
        const beg = bisect_left(lines, x_min, line => line[0]);
        for (let i = beg; i < m; ++i) {
            const [x, y_min, y_max] = lines[i];
            if (x_max < x) { break; }
            for (const [r, pattern] of patterns.entries()) {
                let lo = Math.max(y_min, y - offset - pattern.h);
                let hi = Math.min(y_max, y - offset);
                let k = bisect_right(patterns,
                    (1 + 1e-6) * (hi - lo), arg => arg.h);
                if (k) { fst[r][c] += patterns[k - 1].v; }
                lo = Math.max(y_min, y + offset);
                hi = Math.min(y_max, y + offset + pattern.h);
                k = bisect_right(patterns,
                    (1 + 1e-6) * (hi - lo), arg => arg.h);
                if (k) { snd[r][c] += patterns[k - 1].v; }
            }
        }
    }
    const result: Item[] = [];
    for (const [i1, v1] of fst.entries()) {
        const y_min = y - offset - patterns[i1].h;
        for (const [i2, v2] of snd.entries()) {
            const y_max = y + offset + patterns[i2].h;
            let score = .0;
            for (let c = 0; c < nc; ++c) {
                score += find_score(v1[c] + v2[c]);
            }
            if (score) { result.push({ y_min, y, y_max, score }); }
        }
    }
    return result;
}


function select_disjoint_items(
    items: { y_min: number, y_max: number, score: number }[],
    gap: number
): number[] {
    const n = items.length;
    if (n == 0) { return []; }
    const indices = [...Array(n).keys()];
    indices.sort((a, b) => items[a].y_min - items[b].y_min);
    interface Record {
        index: number;
        next: number;
        score: number;
    }
    let index = indices[n - 1];
    const records: Record[] = [{
        index, next: n, score: items[index].score
    }];
    for (let i = n - 2; i >= 0; i--) {
        let record = records[records.length - 1];
        index = indices[i];
        let score = items[index].score;
        const j = bisect_left(indices, items[index].y_max + gap,
            (i) => items[i].y_min);
        if (j < n) { score += records[n - 1 - j].score; }
        if (score > record.score) { record = { index, next: j, score }; }
        records.push(record)
    }
    records.reverse();
    const result: number[] = [];
    let i = 0;
    while (i < n) {
        result.push(records[i].index);
        i = records[i].next;
    }
    return result;
}


export interface Tracker {
    x: number;
    y: number;
    s: number; // sample's index
    r: number; // tracker's row
}


function make_trackers(
    x_min: number,
    x_max: number,
    y_min: number,
    y: number,
    y_max: number,
    offset: number,
    patterns: Pattern[],
    samples: Sample[],
    lines: [number, number, number][]
): Tracker[] {
    const result: Tracker[] = []
    const n = lines.length;
    for (let i = bisect_left(lines, x_min, arg => arg[0]); i < n; ++i) {
        const [x, y0, y1] = lines[i];
        if (x_max < x) { break; }
        if (y1 < y_min || y0 > y_max) { continue; }
        let lo = Math.max(y_min, y0), hi = Math.min(y - offset, y1)
        let j = bisect_right(patterns, (1 + 1e-6) * (hi - lo), arg => arg.h);
        if (j) {
            const p = patterns[j - 1];
            const k = bisect_right(patterns,
                (1 + 1e-6) * Math.max(y - offset - lo, 0), arg => arg.h);
            if (k) { hi = Math.min(hi, y - offset - patterns[k - 1].h + p.h); }
            for (const [r, s] of p.indices.entries()) {
                hi -= samples[s]['h'];
                result.push({ x, y: hi, s, r: -(r + 1) });
            }
        }
        lo = Math.max(y + offset, y0), hi = Math.min(y_max, y1);
        j = bisect_right(patterns, (1 + 1e-6) * (hi - lo), arg => arg.h);
        if (j) {
            const p = patterns[j - 1];
            const k = bisect_right(patterns,
                (1 + 1e-6) * Math.max(hi - y - offset, 0), arg => arg.h);
            if (k) { lo = Math.max(lo, y + offset + patterns[k - 1].h - p.h); }
            for (const [r, s] of p.indices.entries()) {
                result.push({ x, y: lo, s, r: (r + 1) });
                lo += samples[s]['h'];
            }
        }
    }
    return result;
}


export interface Point {
    x: number;
    y: number;
}


function is_ccw(a: Point, b: Point, c: Point): boolean {
    return (b.x - a.x) * (c.y - b.y) - (c.x - b.x) * (b.y - a.y) > 0;
}


function vclip(x: number, polygon: Point[][]): [number, number][] {
    const values: number[] = [];
    for (const contour of polygon) {
        const n = contour.length;
        if (n < 3) { continue; }
        for (let i = 0; i < n; ++i) {
            const a = contour.at(i - 1)!, b = contour[i];
            if ((a.x < x && x < b.x) || (b.x < x && x < a.x)) {
                values.push((b.y * (x - a.x) + a.y * (b.x - x)) / (b.x - a.x));
            } else if (a.x == x) {
                const c = contour.at(i - 2)!;
                if ((c.x < x && x < b.x) || (b.x < x && x < c.x) ||
                    ((x == b.x || x == c.x) && is_ccw(c, a, b))) {
                    values.push(a.y);
                }
            }
        }
    }
    values.sort((a, b) => a - b);
    const result: [number, number][] = []
    for (let i = 0; i < values.length; i += 2) {
        result.push([values[i], values[i + 1]]);
    }
    return result;
}


function find_x_min(polygon: Point[][]): number {
    let result = Infinity;
    for (const contour of polygon) {
        for (const point of contour) {
            result = Math.min(result, point.x);
        }
    }
    return result;
}


function find_x_max(polygon: Point[][]): number {
    let result = -Infinity;
    for (const contour of polygon) {
        for (const point of contour) {
            result = Math.max(result, point.x);
        }
    }
    return result;
}


function make_vlines(
    polygon: Point[][],
    tracker_width: number,
    step: number
): [number, number, number][] {
    const x_min = find_x_min(polygon);
    const size = find_x_max(polygon) - x_min;
    const n = Math.floor((size - tracker_width) / step);
    let x = x_min + (size - n * step) / 2;
    const result: [number, number, number][] = [];
    for (let _ = 0; _ <= n; ++_) {
        const fst = vclip(x - tracker_width / 2, polygon);
        const snd = vclip(x + tracker_width / 2, polygon);
        let i = 0, j = 0;
        while (i < fst.length && j < snd.length) {
            const lhs = Math.max(fst[i][0], snd[j][0]);
            const rhs = Math.min(fst[i][1], snd[j][1]);
            if (lhs < rhs) { result.push([x, lhs, rhs]); }
            if (fst[i][1] > snd[j][1]) {
                j += 1;
            } else {
                i += 1;
            }
        }
        x += step;
    }
    return result;
}


function hclip(y: number, polygon: Point[][]): [number, number][] {
    const values: number[] = [];
    for (const contour of polygon) {
        const n = contour.length;
        if (n < 3) { continue; }
        for (let i = 0; i < n; ++i) {
            const a = contour.at(i - 1)!, b = contour[i];
            if ((a.y < y && y < b.y) || (b.y < y && y < a.y)) {
                values.push((b.x * (y - a.y) + a.x * (b.y - y)) / (b.y - a.y));
            } else if (a.y == y) {
                const c = contour.at(i - 2)!;
                if ((c.y < y && y < b.y) || (b.y < y && y < c.y) ||
                    ((y == b.y || y == c.y) && is_ccw(c, a, b))) {
                    values.push(a.x);
                }
            }
        }
    }
    values.sort((a, b) => a - b);
    const result: [number, number][] = []
    for (let i = 0; i < values.length; i += 2) {
        result.push([values[i], values[i + 1]]);
    }
    return result;
}


function find_y_min(polygon: Point[][]): number {
    let result = Infinity;
    for (const contour of polygon) {
        for (const point of contour) {
            result = Math.min(result, point.y);
        }
    }
    return result;
}


function find_y_max(polygon: Point[][]): number {
    let result = -Infinity;
    for (const contour of polygon) {
        for (const point of contour) {
            result = Math.max(result, point.y);
        }
    }
    return result;
}


function group_pairs(
    pairs: [number, number][],
    gap: number
): [number, number][][] {
    const n = pairs.length;
    if (n == 0) { return []; }
    const result: [number, number][][] = [];
    let i = 0;
    for (let j = 1; j < n; ++j) {
        if (pairs[j - 1][1] + gap < pairs[j][0]) {
            result.push(pairs.slice(i, j));
            i = j;
        }
    }
    result.push(pairs.slice(i));
    return result
}


function offset_pairs(
    pairs: [number, number][],
    offset: number
): [number, number][] {
    const n = pairs.length;
    if (n === 0) { return []; }
    const result: [number, number][] = [];
    const gap = 2 * offset;
    let i = 0;
    for (let j = 1; j < n; ++j) {
        if (pairs[j - 1][1] + gap < pairs[j][0]) {
            result.push([pairs[i][0] - offset, pairs[j - 1][1] + offset]);
            i = j;
        }
    }
    result.push([pairs[i][0] - offset, pairs.at(-1)![1] + offset]);
    return result;
}


export interface Layout {
    trackers: Tracker[];
    lines: [Point, Point][];
}


function fill(
    contours: Point[][],
    samples: Sample[],
    max_rank: number,
    tracker_width: number,
    row_to_row: number,
    row_to_line: number,
    offset: number,
    gap: number,
    step: number,
    min_block_value: number,
    max_block_value: number
): Layout[] {
    const y_min = find_y_min(contours);
    const size = find_y_max(contours) - y_min;
    const n = Math.floor(size / step);
    let y = y_min + (size - (n - 1) * step) / 2;
    const vlines = make_vlines(contours, tracker_width, row_to_row);
    const patterns = make_patterns(samples, max_rank);
    const find_score = (value: number) => Math.min(
        Math.floor(value / min_block_value) * max_block_value, value);
    const items: Item[] = [];
    for (let i = 0; i < n; ++i) {
        const pairs = offset_pairs(hclip(y, contours), offset);
        for (let item of make_items(pairs, y, row_to_line, patterns,
            find_score, vlines)) {
            items.push(item);
        }
        y += step;
    }
    const indices = select_disjoint_items(items, gap);
    const result: Layout[] = [];
    for (const index of indices) {
        const item = items[index];
        const pair_groups = group_pairs(hclip(item.y, contours), 2 * offset);
        for (const pairs of pair_groups) {
            const trackers = make_trackers(pairs[0][0] - offset,
                pairs[pairs.length - 1][1] + offset, item.y_min, item.y,
                item.y_max, row_to_line, patterns, samples, vlines);
            const lines: [Point, Point][] = pairs.map((pair) => {
                return [{ x: pair[0], y: item.y }, { x: pair[1], y: item.y }];
            });
            result.push({ trackers, lines });
        }
    }
    return result;
}


function shift_and_fill(
    contours: Point[][],
    samples: Sample[],
    max_rank: number,
    tracker_width: number,
    row_to_row: number,
    row_to_line: number,
    offset: number,
    gap: number,
    step: number,
    tan: number,
    min_block_value: number,
    max_block_value: number
): Layout[] {
    if (tan == 0.0) {
        return fill(contours, samples, max_rank, tracker_width, row_to_row,
            row_to_line, offset, gap, step, min_block_value, max_block_value);
    }

    function shift<T extends Point>(src: T, k: number): T {
        return { ...src, y: src.y + k * src.x };
    }

    const sec = Math.hypot(1, tan);
    const layouts = fill(
        contours.map((contour) => contour.map((point) => shift(point, -tan))),
        samples, max_rank, tracker_width, row_to_row, row_to_line * sec,
        offset, gap * sec, step * sec, min_block_value, max_block_value);
    return layouts.map((layout) => {
        return {
            trackers: layout.trackers.map((tracker) => shift(tracker, tan)),
            lines: layout.lines.map((line) => [shift(line[0], tan),
            shift(line[1], tan)])
        };
    });
}


export interface Input {
    contours: Point[][];
    samples: Sample[];
    max_rank: number;
    tracker_width: number;
    row_to_row: number;
    row_to_line: number;
    offset: number;
    gap: number;
    step: number;
    tan: number;
    min_block_value: number;
    max_block_value: number
}


export function solve({
    contours,
    samples,
    max_rank,
    tracker_width,
    row_to_row,
    row_to_line,
    offset,
    gap,
    step,
    tan,
    min_block_value,
    max_block_value
}: Input): Layout[] {
    const indices: number[] = [];
    for (const [i, sample] of samples.entries()) {
        if (sample.o == 0) {
            indices.push(i);
        }
    }
    if (indices.length == 0) {
        return [];
    }
    const layouts = shift_and_fill(contours, indices.map((i) => samples[i]),
        max_rank, tracker_width, row_to_row, row_to_line, offset, gap, step,
        tan, min_block_value, max_block_value);
    return layouts.map((layout) => {
        const trackers = layout.trackers.map((tracker) => {
            return { ...tracker, s: indices[tracker.s] };
        });
        return { ...layout, trackers };
    });
}


function signed_area(a: Point, b: Point, c: Point): number {
    return (b.x - a.x) * (c.y - a.y) - (b.y - a.y) * (c.x - a.x);
}

function overlapped(a: number, b: number, c: number, d: number): boolean {
    if (a > b) { [a, b] = [b, a]; }
    if (c > d) { [c, d] = [d, c]; }
    return Math.max(a, c) <= Math.min(b, d);
}


function crossed(a: Point, b: Point, c: Point, d: Point): boolean {
    return overlapped(a.x, b.x, c.x, d.x) &&
        overlapped(a.y, b.y, c.y, d.y) &&
        signed_area(a, b, c) * signed_area(a, b, d) <= 0 &&
        signed_area(c, d, a) * signed_area(c, d, b) <= 0;
}


function inner(p: Point, contour: Point[]): boolean {
    let cntr = 0, j = contour.length - 1;
    for (let i = 0; i < contour.length; ++i) {
        const a = contour[j], b = contour[i];
        if ((((b.y > p.y) != (a.y > p.y)) && (p.x < (a.x - b.x) * (p.y - b.y) /
            (a.y - b.y) + b.x))) {
            ++cntr;
        }
        j = i;
    }
    return cntr % 2 == 1;
}


function test(
    contour: Point[],
    x_min: number,
    y_min: number,
    x_max: number,
    y_max: number
): boolean {
    const box: Point[] = [{ x: x_min, y: y_min }, { x: x_max, y: y_min },
    { x: x_max, y: y_max }, { x: x_min, y: y_max }];
    for (let i2 = 0, i1 = box.length - 1; i2 < box.length; i2++) {
        const a = box[i1];
        const b = box[i2];
        for (let j2 = 0, j1 = contour.length - 1; j2 < contour.length; ++j2) {
            const c = contour[j1];
            const d = contour[j2];
            j1 = j2;
            if (crossed(a, b, c, d)) { return true; }
        }
        i1 = i2;
    }
    for (let p of box) {
        if (inner(p, contour)) { return true; }
    }
    for (let p of contour) {
        if (inner(p, box)) { return true; }
    }
    return false;
}


export function trim_tracker(
    contour: Point[],
    tracker: Tracker,
    width: number,
    samples: { v: number, h: number }[]
): Tracker | undefined {
    const x = tracker.x;
    const x_min = x - width / 2;
    const x_max = x + width / 2;
    const y_min = tracker.y;
    const h = samples[tracker.s].h;
    const y_max = y_min + h;
    let y: number | undefined = undefined;
    let index: number | undefined = undefined;
    let record = 0;
    for (let i = 0; i < samples.length; i++) {
        const sample = samples[i];
        if (h < sample.h || record > sample.v) { continue; }
        if (!test(contour, x_min, y_min, x_max, y_min + sample.h)) {
            y = y_min;
        } else if (!test(contour, x_min, y_max - sample.h, x_max, y_max)) {
            y = y_max - sample.h;
        } else {
            continue;
        }
        index = i;
        record = sample.v;
    }
    if (index !== undefined) {
        return { ...tracker, y: y as number, s: index };
    }
    return undefined;
}