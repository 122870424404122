<script lang="ts">
    //@ts-ignore
    import { itemActions } from "svelecte/item";
    import { IconComponent } from "../icon";
    import { tooltip } from "./tooltip";
    import type { SelectOption, DropdownOption } from "./types";

    export let inputValue: string;
    export let index = -1;
    export let item: DropdownOption;
    export let isSelected: boolean = false;
    export let isMultiple: boolean = true;
    export let disableHighlight: boolean = true;
    export let isDisabled: boolean = false;
    export let formatter: (
        item: SelectOption,
        isSelected: boolean,
        inputValue: string,
        disableHighlight: boolean,
    ) => string;
</script>

{#if item.$isGroupHeader}
    <div
        class="optgroup-header mdc-typography--headline6 text-main-medium"
        on:mousedown|preventDefault
    >
        {item.label}
    </div>
{:else}
    <div
        class="sv-item custom-sv-item"
        use:itemActions={{ item, index }}
        class:is-selected={isSelected}
        class:is-disabled={isDisabled}
        on:select
        on:deselect
        on:hover
    >
        <div
            class="sv-item-content"
            class:item-invalid={item.invalid}
            use:tooltip={item.text}
        >
            {formatter(item, isSelected, inputValue, disableHighlight)}
        </div>
        {#if isSelected && isMultiple}
            <div class="sv-item-btn" tabindex="-1" data-action="deselect">
                <IconComponent name="Close" />
            </div>
        {/if}
    </div>
{/if}

<style lang="scss">
    .is-selected {
        &.custom-sv-item {
            border-radius: 1px;
            padding: 1px 2px 1px 4px;
            cursor: pointer;
        }
        .item-invalid {
            color: var(--color-danger);
        }
    }
    :global(.svelecte-control .has-multiSelection .sv-item-content) {
        padding: 0;
    }
    .sv-item-btn {
        cursor: pointer;
        color: var(--color-main-light);
        align-self: center;
        height: 16px;
        &:hover {
            background-color: var(--color-danger-light);
            color: var(--color-danger);
        }
    }
    .optgroup-header {
        padding: 8px 8px 0;
    }
</style>
