
import type { ObservableStream, VersionedValue } from 'engine-utils-ts';
import { LegacyLogger, ObservableObject } from 'engine-utils-ts';
import type { Vector3 } from 'math-ts';
import { Aabb, KrMath } from 'math-ts';

import { ClipboxMoveDurMult } from '../Constants';
import type { CommonArgs } from '../KreoEngineImpl';
import type { Time } from '../time/TIme';
import type { TotalBounds } from '../TotalBounds';
import { Easing } from '../utils/Easing';
import type { DeepReadonly } from '../utils/Utils';
import type { ClipboxSettings } from './ClipboxApi';

export const enum ClipboxDirtyFlags {
	None = 0,
	Position = 2,
}

interface ClipboxInternalState extends ClipboxSettings {
	isActive: boolean,
	target: Aabb;
}

export class ClipBox implements VersionedValue {

	positionsVersion: number = 0;

	readonly state: ObservableObject<ClipboxInternalState>;

	_prevMaxed: boolean = false;

	readonly minBoxSide: number = 0.01;
	readonly legacyStateEvent: ObservableStream<any>;
	readonly time: Time;
	// readonly bimElements: BimElements;

	readonly current: Aabb = Aabb.empty();

	animStartTime: number = 0;
	animDuration: number = 1;
	_version: number = 0;

	constructor(
		basics: CommonArgs,
		totalBounds: TotalBounds/*, bims: BimElements*/
	) {
		this.legacyStateEvent = basics.namedEvents.registerEvent('clipbox');
		this.state = new ObservableObject<ClipboxInternalState>({
			identifier: 'clipbox',
			initialState: {
				isActive: false,
				target: Aabb.empty(),
			},
			throttling: {
				onlyFields: ['target'],
			},
			undoStack: basics.undoStack,
		});
		this.state.observeObject({
			settings: {immediateMode: true, doNotNotifyCurrentState: true },
			onPatch: ({ patch, currentValueRef }) => {
				if (patch.isActive != undefined) {
					this.positionsVersion += 1;
					this.legacyStateEvent.notify_later_legacy(currentValueRef.isActive, 'ghost');
				}
				if (patch.target) {
					this.animStartTime = this.time.animFrameStart;
					this.animDuration = this._calcAnimDuration(patch.target.elements);
					if (basics.undoStack.isUndoingOrRedoing()) {
						this.animDuration *= 0.5;
					}
				}
			}
		});

		// this.state.addPatchValidator('target', (box: KrBox3) => {
		// 	return this._getValidTarget(KrBox3.allocFromArray(box.elements), );
		// });
		// if (renderType & MeshRenderTypeSelectables) {
		// 	LegacyLogger.error('can not set ' + renderTypeToString(renderType) + ' rendermode for clipbox outside');
		// 	renderType = MeshRenderType.None;
		// }
		this.time = basics.time;
	}

	version(): number {
		return this.state.version() + this._version;
	}

	getBounds(): Aabb {
		if (this._state().isActive) {
			return this.current.clone();
		}
		// const size = 1_000_000_000;
		// const krBounds = KrBox3.allocFromArray([-size, -size, -size, size, size, size]);
		return Aabb.infinite();
	}

	_state(): DeepReadonly<ClipboxInternalState> {
		return this.state.poll();
	}

	update(totalBounds: TotalBounds): void {
		if (totalBounds.updated && (this._prevMaxed !== this.isMaxedOut(totalBounds) || this._prevMaxed)) {
			this.state.applyPatch({
				patch: {
					target: totalBounds.bounds
				},
				event: {
					identifier: 'clipbox_update',
                    isEventDerived: true,
				}
			});
		}
		this._prevMaxed = this.isMaxedOut(totalBounds);

		if (!this.current.equals(this._state().target as Readonly<Aabb>)) {
			const currDuration = (this.time.animFrameStart - this.animStartTime);
			let perc = KrMath.clamp(currDuration / this.animDuration, 0, 1);
			perc = Easing.easeInQuad(perc);
			if (!(perc < 0.99)) {
				this.current.copy(this._state().target  as Readonly<Aabb>);
			} else {
				this.current.lerpTo(this._state().target as Readonly<Aabb>, perc);
			}
			if (!this.current.areNumbersReal()) { // safe guard
				LegacyLogger.warn('clipbox invalid values, reset');
				this.current.copy(totalBounds.bounds);
				this.state.applyPatch({
                    patch: { target: totalBounds.bounds },
                    event: { identifier: 'clipbox_update', isEventDerived: true }
                });
			}
			this.positionsVersion += 1;
			this._version += 1;
		}
	}

	isMaxedOut(totalBounds: TotalBounds): boolean {
		const thisBounds = this.state.poll().target.clone();
		thisBounds.expandByScalar(0.001);
		const isMaxed = thisBounds.containsBox(totalBounds.bounds);
		return isMaxed;
	}

	_getValidTarget(target: Aabb, totalBounds: TotalBounds): Aabb {

		target.clampTo(totalBounds.bounds);

		const targetMax_t = target.getMax_t();
		const targetMin_t = target.getMin_t();

		const currentMax_t = this.current.getMax_t();
		const currentMin_t = this.current.getMin_t();

		const size = target.getSize();

		for (let i = 0; i < 3; ++i) {
			if (size.getComponent(i) < this.minBoxSide) {

				const maxI = targetMax_t.getComponent(i);
				const minI = targetMin_t.getComponent(i);

				if (currentMax_t.getComponent(i) === maxI) {

					targetMin_t.setComponent(i, maxI - this.minBoxSide);

				} else if (currentMin_t.getComponent(i) === minI) {

					targetMax_t.setComponent(i, minI + this.minBoxSide);

				} else {
					const d = this.minBoxSide - (maxI - minI);
					targetMin_t.setComponent(i, minI - d * 0.5);
					targetMax_t.setComponent(i, maxI + d * 0.5);
				}
			}
		}
		target.setMinFrom(targetMin_t);
		target.setMaxFrom(targetMax_t);
		return target;
	}

	_clampAndSetTarget(target: Aabb): void {
		if (!target.areNumbersReal()) {
			LegacyLogger.error('wrong clipbox values');
		}
	}

	isEnabled(): boolean {
		return this._state().isActive;
	}

	setImmediately(target: Aabb) {
		this.state.applyPatch({ patch: { target: target } })
		this.current.copy(this.state.poll().target);
		this.positionsVersion += 1;
	}

	setTarget(target: Aabb) {
		this.state.applyPatch({ patch: { target } });

		const duration = this._calcAnimDuration(target.elements);
		this.animDuration = duration;

		if (duration > 0 && duration < Infinity) {
			this.animStartTime = this.time.animFrameStart;
		} else {
			this.current.copy(this._state().target as Readonly<Aabb>);
			this.positionsVersion += 1;
		}
	}

	_calcAnimDuration(target: number[]): number {
		const dist = KrMath.distance(target, this.current.elements);
		const duration = Math.pow(dist, 0.3) * ClipboxMoveDurMult;
		return duration;
	}

	move_t(offset:Vector3, totalBounds: TotalBounds): Vector3 {
		// find offset limits
		const maxOffset = totalBounds.bounds.getMax_t().sub(this.current.getMax_t());
		const minOffset = totalBounds.bounds.getMin_t().clone().sub(this.current.getMin_t());

		offset = offset.clone();
		offset.min(maxOffset);
		offset.max(minOffset);

		const target = this.current.clone();
		target.translate(offset);

		this.setImmediately(target);
		return offset;
	}

	setState(isEnabled:boolean) {
		this.state.applyPatch({ patch: { isActive: isEnabled } });
	}
}


