<svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g clip-path="url(#clip0_32_67)">
        <path
            d="M10.2167 13.3993C8.70895 13.3993 7.43057 12.8742 6.38153 11.8241C5.3325 10.7739 4.80798 9.49866 4.80798 7.99836C4.80798 6.49075 5.3325 5.21267 6.38153 4.16413C7.43057 3.11558 8.70895 2.59131 10.2167 2.59131C11.7191 2.59131 12.9946 3.11521 14.0431 4.16301C15.0917 5.21082 15.616 6.48946 15.616 7.99893C15.616 9.50109 15.0917 10.7767 14.0431 11.8257C12.9946 12.8748 11.7191 13.3993 10.2167 13.3993ZM10.2116 12.1449C11.3616 12.1449 12.3408 11.7421 13.1491 10.9365C13.9574 10.1309 14.3616 9.15261 14.3616 8.00171C14.3616 6.85082 13.9588 5.87043 13.1532 5.06053C12.3475 4.25063 11.3693 3.84568 10.2184 3.84568C9.0675 3.84568 8.0871 4.24908 7.2772 5.05589C6.46729 5.8627 6.06233 6.8424 6.06233 7.99498C6.06233 9.14494 6.46574 10.1241 7.27255 10.9324C8.07936 11.7408 9.05906 12.1449 10.2116 12.1449ZM11.7754 10.421L12.6544 9.54203L10.8558 7.73086L10.8392 5.34276H9.58478V8.24711L11.7754 10.421ZM1.0507 5.95581V4.70146H4.14133V5.95581H1.0507ZM0.384033 8.62248V7.36812H4.14133V8.62248H0.384033ZM1.0507 11.2891V10.0348H4.14133V11.2891H1.0507Z"
            fill="#0A76F5"
        />
    </g>
    <defs>
        <clipPath id="clip0_32_67">
            <rect width="16" height="16" fill="white" />
        </clipPath>
    </defs>
</svg>
