import { createInverterObject2D as createInverterObject2DBase } from "src/sld/templates/inverter";
import type { EquipmentDescription } from "src/sld/types";
import { VectorPrimitiveText } from "vector-graphic";

export function createInverterObject2D(
    desc: EquipmentDescription,
    params?: { index?: string },
) {
    const inverter = createInverterObject2DBase()

    // draw inverter label
    {
        const aabb = inverter.aabb;
        const inverterLabelPrimitive = new VectorPrimitiveText({
            x: aabb.centerX(),
            y: aabb.max.y + 20,
            fontSize: 20,
            text: desc.text + (params?.index === undefined ? '' : ` #${params.index}`),
            anchor: 'middle',
            verticalAlignment: 'hanging',
        })
        inverter.addPrimitive(inverterLabelPrimitive);
    }
    return inverter;
}
