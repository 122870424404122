import type { Bim, IdBimGeo, IdBimScene} from 'bim-ts';
import {
	BasicAnalyticalRepresentation, ExtrudedPolygonGeometry, ExtrudedPolygonMaxHeight} from 'bim-ts';
import type { LazyVersioned, RGBAHex, UndoStack , Result } from 'engine-utils-ts';
import { LazyBasic, ObservableObject, Success } from 'engine-utils-ts';
import { KrMath, Vector2, Vector3 } from 'math-ts';
import type { MenuPath } from 'ui-bindings';
import { EditActionResult } from 'ui-bindings';
import { EngineControlsMode } from '../EngineConsts';

import type { SceneInt } from '../scene/SceneRaycaster';
import type { EditModeControls } from './EditControls';
import { MouseButton } from './InputController';
import type { EditInteractionResult, InteractiveEditOperator } from './InteractiveEditOperator';
import type { MouseEventData } from './MouseGesturesBase';

interface BoundaryAdderState {
    instanceId: IdBimScene | 0;
    geoId: IdBimGeo,
}
interface BoundaryAdderSettings {
}
export abstract class InteractiveBoundaryAdderBase implements InteractiveEditOperator<BoundaryAdderState | null, BoundaryAdderSettings> {

    readonly canStart: LazyVersioned<boolean>;
    readonly config: ObservableObject<BoundaryAdderSettings>;

    constructor(
        readonly bim: Bim,
        readonly editControls: EditModeControls,
        undoStack: UndoStack,
        readonly menuPath: MenuPath,
        readonly typeIdentifier: string,
        readonly defaultColor?: RGBAHex,
    ) {
        this.canStart = new LazyBasic('', true);
        this.config = new ObservableObject({
            identifier: this.menuPath.join(),
            initialState: {},
			undoStack,
            throttling: {onlyFields: []}
        });
    }

    start(): Result<BoundaryAdderState | null> {
        return new Success(null)
    }
    cancel(state: BoundaryAdderState | null) {
        if (state?.instanceId) {
            this.bim.instances.delete([state.instanceId]);
        }
    }
    finish(state: BoundaryAdderState | null) : EditActionResult | undefined{
        const result = state?.instanceId ? new EditActionResult([state.instanceId]) : undefined;
        return result;
    };
    handleConfigPatch(
        patch: Partial<BoundaryAdderSettings>,
        state: BoundaryAdderState | null,
    ): EditInteractionResult<BoundaryAdderState | null> {
        throw new Error('Method not implemented.');
    }

    onHover (int: SceneInt): {cursorStyleToSet: string} | null {
        return {cursorStyleToSet: "crosshair"};
    }

    handleClick(
        sceneInt: SceneInt,
        me: MouseEventData,
        previousResult: BoundaryAdderState | null
    ) : EditInteractionResult<BoundaryAdderState | null> {
        if (me.buttons.mouseButton === MouseButton.Right) {
            return {
                state: previousResult,
                done: true,
            }
        }
        if (previousResult === null) {
            // allocate new boundary

            const point = sceneInt?.point ?? me.mouseCone?.raySection.ray.at(10, new Vector3()) ?? new Vector3();

            const boundaryGeo = ExtrudedPolygonGeometry.newWithAutoIds(
                [new Vector2(point.x, point.y)],
                undefined,
                point.z,
                point.z,
            );
            const geoId = this.bim.extrudedPolygonGeometries.idsProvider.reserveNewId();

            this.bim.extrudedPolygonGeometries.allocate([[geoId, boundaryGeo]]);

            const instanceId = this.bim.instances.idsProvider.reserveNewId();
            const instance = this.bim.instances.archetypes.newDefaultInstanceForArchetype(this.typeIdentifier);
            instance.colorTint = this.defaultColor ?? 0;
            instance.representationAnalytical = new BasicAnalyticalRepresentation(geoId);
            this.bim.instances.allocate([[instanceId, instance]]);

            this.bim.instances.setSelected([instanceId]);

            this.editControls.controlsState.applyPatch({patch: {controlsMode: EngineControlsMode.Edit}});

            return {
                state: { instanceId, geoId },
                done: false,
            };
        } else {
            const geo = this.bim.extrudedPolygonGeometries.peekById(previousResult.geoId);
            if (!geo) {
                return {
                    state: null,
                    done: false,
                }
            }

            const point = sceneInt?.point ?? me.mouseCone?.raySection.ray.at(10, new Vector3()) ?? new Vector3();

            const newGeo = ExtrudedPolygonGeometry.newWithAutoIds(
                geo.outerShell.points.concat(new Vector2(point.x, point.y)),
                undefined,
                Math.min(geo.baseElevation, point.z),
                KrMath.clamp(Math.max(geo.topElevation, point.z), geo.baseElevation, geo.baseElevation + ExtrudedPolygonMaxHeight),
            );
            this.bim.extrudedPolygonGeometries.applyPatchTo(newGeo, [previousResult.geoId]);

            return {
                state: { instanceId: previousResult.instanceId, geoId: previousResult.geoId,},
                done: false,
            }
        }
    }

}




