<script lang="ts">
    import { onMount } from "svelte";
    import { MDCFormField } from "@material/form-field";
    import { MDCRadio } from "@material/radio";
    import type { RadioButton } from "./types";

    export let groupName: string;
    export let input: RadioButton;
    export let formValue: string | number;

    let formElement: HTMLDivElement;
    let buttonElement: HTMLDivElement;

    onMount(() => {
        const radio = new MDCRadio(buttonElement);
        const formField = new MDCFormField(formElement);
        formField.input = radio;
    });
</script>

<div
    class="mdc-form-field mdc-typography--body1"
    class:text-main-dark={!input.disabled}
    class:text-main-medium={input.disabled}
    bind:this={formElement}
>
    <div
        class="mdc-radio"
        bind:this={buttonElement}
        class:mdc-radio--disabled={input.disabled}
    >
        <input
            class="mdc-radio__native-control"
            type="radio"
            id={input.id}
            name={groupName}
            bind:group={formValue}
            value={input.value}
            disabled={input.disabled}
        />
        <div class="mdc-radio__background">
            <div class="mdc-radio__outer-circle" />
            <div class="mdc-radio__inner-circle" />
        </div>
    </div>
    <label for={input.id}>{input.label}</label>
</div>

<style lang="scss">
    .mdc-radio {
        padding: 0px;
        width: 16px;
        height: 16px;
    }
    .mdc-radio .mdc-radio__native-control {
        width: 16px;
        height: 16px;
    }
    .mdc-radio__background {
        width: 16px;
        height: 16px;
    }
    .mdc-radio__inner-circle {
        border-width: 8px;
    }
</style>
