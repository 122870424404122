import type { EntitiesCollectionUpdates, IdBimScene, SceneInstance } from 'bim-ts';
import { Allocated, ObservableStream } from 'engine-utils-ts';

import type { EntityId } from "verdata-ts";
import type { IdPile, TrackerPile, ICollection } from "bim-ts";
import type { LayoutMetricsConfig } from './QuantityItemsLazy';

export type CommonIdType = IdBimScene | IdPile | number;
export type CommonType = SceneInstance | TrackerPile | LayoutMetricsConfig;

class MergeCollection<TId extends number, T> implements ICollection<TId, T> {
    readonly updatesStream: ObservableStream<
        EntitiesCollectionUpdates<EntityId<TId>, number>
    >;
    readonly _collections: ICollection<TId, T>[];

    constructor(identifier: string, collections: ICollection<TId, T>[]) {
        this._collections = collections;

        this.updatesStream = new ObservableStream({
            identifier: `${identifier}-updates-stream`,
            defaultValueForNewSubscribersFactory: () => {
                return new Allocated(Array.from(this.allIds()));
            },
        });

        for (const c of collections) {
            this.updatesStream.mergeFrom(c.updatesStream);
        }
    }

	*allIds(): IterableIterator<TId> {
		for (const collection of this._collections) {
			yield *collection.allIds();
		}
	}

    peekById(id: TId): Readonly<T> | undefined {
        for (const collection of this._collections) {
            const item = collection.peekById(id);
            if (item !== undefined) {
                return item;
            }
        }
        return;
    }

    peekByIds(ids: Iterable<TId>): Map<TId, T> {
        const items = new Map<TId, T>();
        for (const collection of this._collections) {
            const itemsByCollection = collection.peekByIds(ids);
            for (const [id, item] of itemsByCollection) {
                if (item !== undefined) {
                    items.set(id, item);
                }
            }
        }
        return items;
    }

    dispose() {
        this.updatesStream.dispose();
    }
}

export class QuantitiesItemsCollection extends MergeCollection<
    CommonIdType,
    CommonType
> {
    constructor(collections: ICollection<CommonIdType, CommonType>[]) {
        super("quantities-items-collection", collections);
    }
}
