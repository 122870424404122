import type { EquipmentDescription,  ModuleTableDescription } from "../../types";
import { EquipmentWithChildren, EquipmentWithGroupedChildren } from "../../types";

export class InverterDescrription extends EquipmentWithChildren  {
    moduleTables: ModuleTableDescription[]

    constructor(params: {
        moduleTables: ModuleTableDescription[]
    } & ConstructorParameters<typeof EquipmentWithChildren>[0]) {
        super(params);
        this.moduleTables = params.moduleTables;
    }

    getChildren(): ModuleTableDescription[] {
        return this.moduleTables;
    }
}

export class CombinerBoxDescription
    extends EquipmentWithGroupedChildren<InverterDescrription>
{
    inverters: InverterDescrription[][]

    constructor(params: {
        inverters: InverterDescrription[][],
    } & ConstructorParameters<typeof EquipmentWithGroupedChildren>[0]) {
        super(params);
        this.inverters = params.inverters;
    }

    getGroupedChildren(): InverterDescrription[][] {
        return this.inverters;
    }

}

export class TransformerDescription
    extends EquipmentWithGroupedChildren<CombinerBoxDescription>
{
    combinerBoxes: CombinerBoxDescription[][]

    constructor(params: {
        combinerBoxes: CombinerBoxDescription[][],
    } & ConstructorParameters<typeof EquipmentDescription>[0]) {
        super(params);
        this.combinerBoxes = params.combinerBoxes;
    }

    getGroupedChildren(): CombinerBoxDescription[][] {
        return this.combinerBoxes;
    }

}
