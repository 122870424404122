import type {
	AnyBimGeometry, IdBimGeo,
	SceneInstance} from 'bim-ts';
import {
	BasicAnalyticalRepresentation, BimGeometryType, SceneObjDiff,
} from 'bim-ts';
import type { RGBAHex } from 'engine-utils-ts';
import { IterUtils, Success, RGBA } from 'engine-utils-ts';
import { Matrix4 } from 'math-ts';
import { entityTypeFromId } from 'verdata-ts';

import { EngineMaterialId } from '../pools/EngineMaterialId';
import {
	InObjFullId, InObjIdType, InObjLocalId,
} from '../scene/EngineSceneIds';
import type { ESOHandle } from '../scene/ESOsCollection';
import { newLodGroupLocalIdent } from '../scene/LodGroups';
import type { SubmeshAllocArgs } from '../scene/Submeshes2';
import { LodMask } from '../scene/Submeshes2';
import { ESO_AnotationFlags } from './ESO';
import {
	BasicAnalyticalRenderJobUpdater, ESSO_BasicAnalyticalSubOBj,
} from './ESO_BasicAnalytical';
import { ESO_Diff } from './ESO_Diff';
import type { ESOsHandlerInputs } from './ESOsHandlerBase';
import type { ESSO_Any, SubmeshesCreationOutput, SubmeshesCreationResources} from './ESSO';
import {
	ESSO
} from './ESSO';
import { SharedSelectHighlightJobUpdater } from './ESSO_HighlightUpdaters';
import { SharedStdRenderJobUpdater } from './SubmeshesStd';
import type { GeometryCtor } from '../scene/GeometryEditsHandler';
import { ESO_GraphTriangulated, ESO_SingleGeoWidth } from './ESO_GraphTriangulated';
import type { IdEngineGeo } from '../geometries/AllEngineGeometries';


const IdentityMatrix = Object.freeze(new Matrix4());

export class ESO_Road extends ESO_SingleGeoWidth {

	width: number;

	constructor(
		sceneInstanceRef: Readonly<SceneInstance>,
	) {
		super(sceneInstanceRef);
		this.width = roadWidth(sceneInstanceRef) ?? 1;
	}

	geometryId(): IdBimGeo | undefined {
		return this.sceneInstanceRef.representationAnalytical?.geometryId;
	}
}
function roadWidth(sceneInstance: SceneInstance): number {
	const width = sceneInstance.properties.get('road | width');
	return width?.as('m') ?? 1;
}

export interface ESSO_Road_Repr {
	geometriesRefs: IdEngineGeo[];
}
class ESSO_RoadTriangulation extends ESSO<ESSO_Road_Repr> {

	get parentWorldMatrix(): Readonly<Matrix4> {
		return IdentityMatrix; // triangulation is done in world space
	}

	get colorTint(): 0 | RGBAHex {
		let color = super.colorTint;
		if (!color) {
			return RGBA.new(0.4, 0.4, 0.4, 0.8);
		}
		return color;
	}

	createSubmeshes(resoures: SubmeshesCreationResources, output: SubmeshesCreationOutput): void {
		const isEditMode = this.rootRef.annotationFlags & ESO_AnotationFlags.IsInEdit;
		for (const geoId of this.repr.geometriesRefs) {
			const allocArgs: SubmeshAllocArgs = {
				id: this.id,
				lodMask: LodMask.All,
				lodGroupLocalIdent: newLodGroupLocalIdent(0, 0),
				subObjectRef: this,
				descr: {
					geoId,
					localTransforms: null,
					materialId: isEditMode ? EngineMaterialId.BasicAnalyticalTransparent : EngineMaterialId.Default,
					mainRenderJobUpdater: SharedStdRenderJobUpdater,
					overlayRenderJobUpdater: SharedSelectHighlightJobUpdater
				}
			};
			output.submeshes.push(allocArgs);
		}
	}
}


export class ESSO_RoadAnalytical extends ESSO_BasicAnalyticalSubOBj {
	createSubmeshes(resoures: SubmeshesCreationResources, output: SubmeshesCreationOutput): void {
		for (const [geoId, transforms] of this.reprToEngineGeometries(resoures)) {
			output.submeshes.push({
				id: this.id,
				lodMask: LodMask.All,
				lodGroupLocalIdent: newLodGroupLocalIdent(0, 0),
				subObjectRef: this,
				descr: {
					geoId: geoId,
					materialId: EngineMaterialId.BasicAnalyticalTransparent,
					localTransforms: transforms,
					mainRenderJobUpdater: new BasicAnalyticalRenderJobUpdater(),
					overlayRenderJobUpdater: SharedSelectHighlightJobUpdater
				}
			});
		}
	}
}

export class ESO_RoadHandler extends ESO_GraphTriangulated<ESO_Road> {

	constructor(
		identifier: string,
		args: ESOsHandlerInputs,
	) {
		super(identifier, args);
		this._relevantBimUpdatesFlags |= SceneObjDiff.LegacyProps;
	}

	applyBimDiffToESO(obj: ESO_Road, diff: SceneObjDiff, handle: ESOHandle): ESO_Diff {
		let appliedDiff = super.applyBimDiffToESO(obj, diff, handle);
		const width = roadWidth(obj.sceneInstanceRef);
		if (!Object.is(width, obj.width)) {
			obj.width = width;
			appliedDiff |= ESO_Diff.RepresentationBreaking;
		}
		if (appliedDiff & ESO_Diff.Position) {
			appliedDiff |= ESO_Diff.RepresentationBreaking;
		}
		if (appliedDiff & ESO_Diff.RepresentationBreaking) {
			this.markForTriangulationUpdate(handle);
		}
		return appliedDiff;
	}

	tryCreateESO(instance: SceneInstance): ESO_Road | undefined {
		if (instance.type_identifier !== 'road') {
			return undefined;
		}
		if (!(instance.representationAnalytical instanceof BasicAnalyticalRepresentation)) {
			return;
		}
		if (entityTypeFromId<BimGeometryType>(
			instance.representationAnalytical.geometryId
			) !== BimGeometryType.GraphGeometry
		) {
			return;
		}
		return new ESO_Road(instance);
	}
	esosTypesToHandle(): Iterable<new (...args: any[]) => ESO_Road> {
		return [ESO_Road];
	}

	createSubObjectsFor(objs: [ESOHandle, ESO_Road][]): Iterable<[ESOHandle, ESSO<any>[]]> {
		const triangulations = this.triangulationCalculations.peekByIds(objs.map(t => t[0]));
		const subObjects = IterUtils.mapIter(
			objs,
			([handle, obj]) => {
				let subobjs: ESSO<any>[] = [];

				const triGeometriesIds = triangulations.get(handle);
				if (triGeometriesIds instanceof Success) {
					const repr = new ESSO_RoadTriangulation(
						obj,
						InObjFullId.new(handle, InObjLocalId.new(InObjIdType.ObjSelf, 0)),
						{geometriesRefs: triGeometriesIds.value},
						null
					);
					subobjs.push(repr);
				}

				if (obj.annotationFlags & ESO_AnotationFlags.IsInEdit) {
					const geoId = obj.geometryId()!;

					const bimGeo = this.bimGeos.peekById(geoId);
					if (bimGeo) {
						const editshandler = this.geometriesEditHandlers.get(bimGeo.constructor as GeometryCtor);
						if (editshandler) {
							editshandler.createEditRepr(handle, obj, bimGeo, subobjs);
						} else {
							this.logger.error('no geo edit handler for', bimGeo);
						}

						this.createAuxiluaryEditESSOs(handle, obj, geoId, bimGeo, subobjs);
					}
				}

				return [handle, subobjs] as [ESOHandle, ESSO<any>[]];
			}
		);
		return subObjects;
	}

	createAuxiluaryEditESSOs(handle: ESOHandle, obj: ESO_Road, geoId: IdBimGeo, bimGeo: AnyBimGeometry, result: ESSO_Any[]): void {
		result.push(new ESSO_RoadAnalytical(
			obj,
			InObjFullId.new(handle, InObjLocalId.new(InObjIdType.ObjSelfAux, 0)),
			[geoId, bimGeo],
			null,
		));
	}
}



