
import { Vector3 } from 'math-ts';

import type { Handle } from '../memory/Handle';
import type { InObjFullId } from './EngineSceneIds';
import type { ESOHandle } from './ESOsCollection';

export type BoundsDistance<THandle extends Handle> = [THandle, number]; // index and distance

export interface RayIntersection {
	readonly distance: number;
	readonly point: Vector3;
	readonly normal: Vector3;
}

export abstract class InteractiveObjectIntersection implements RayIntersection {
	constructor(
		readonly distance : number,
		readonly point : Vector3,
		readonly normal : Vector3,
	) {
	}
}

export class BimObjectSceneIntersection extends InteractiveObjectIntersection {
	constructor(
		distance : number,
		point : Vector3,
		normal : Vector3,
		readonly esoHandle: ESOHandle,
	) {
		super(distance, point, normal);
	}
}

export class EditObjectIntersection extends InteractiveObjectIntersection {
	constructor(
		distance : number,
		point : Vector3,
		normal : Vector3,
		readonly id: InObjFullId,
	) {
		super(distance, point, normal);
	}
}



export class EditGizmoIntersection implements RayIntersection {
	readonly distance : number;
	readonly point : Vector3;
	readonly normal : Vector3;
	// readonly gizmoSubState: GizmoObjectSubmeshState<any, any>;
	readonly isSeeThrough: boolean = true;

	constructor(
		point: Vector3,
		// gizmoSubState: GizmoObjectSubmeshState<any, any>,
		distance: number,
	) {
		this.distance = distance;
		this.point = point.clone();
		// this.gizmoSubState = gizmoSubState;
		this.normal = new Vector3(0, 0, 1);
	}
}


