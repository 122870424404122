// automatically generated by the FlatBuffers compiler, do not modify

import type * as flatbuffers from 'flatbuffers';

import type { QuaternionT } from './quaternion.js';
import { Quaternion } from './quaternion.js';
import type { Vector3T } from './vector3.js';
import { Vector3 } from './vector3.js';


export class Transform implements flatbuffers.IUnpackableObject<TransformT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Transform {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

offset(obj?:Vector3):Vector3|null {
  return (obj || new Vector3()).__init(this.bb_pos, this.bb!);
}

rotation(obj?:Quaternion):Quaternion|null {
  return (obj || new Quaternion()).__init(this.bb_pos + 24, this.bb!);
}

scale(obj?:Vector3):Vector3|null {
  return (obj || new Vector3()).__init(this.bb_pos + 56, this.bb!);
}

static sizeOf():number {
  return 80;
}

static createTransform(builder:flatbuffers.Builder, offset_x: number, offset_y: number, offset_z: number, rotation_x: number, rotation_y: number, rotation_z: number, rotation_w: number, scale_x: number, scale_y: number, scale_z: number):flatbuffers.Offset {
  builder.prep(8, 80);
  builder.prep(8, 24);
  builder.writeFloat64(scale_z);
  builder.writeFloat64(scale_y);
  builder.writeFloat64(scale_x);
  builder.prep(8, 32);
  builder.writeFloat64(rotation_w);
  builder.writeFloat64(rotation_z);
  builder.writeFloat64(rotation_y);
  builder.writeFloat64(rotation_x);
  builder.prep(8, 24);
  builder.writeFloat64(offset_z);
  builder.writeFloat64(offset_y);
  builder.writeFloat64(offset_x);
  return builder.offset();
}


unpack(): TransformT {
  return new TransformT(
    (this.offset() !== null ? this.offset()!.unpack() : null),
    (this.rotation() !== null ? this.rotation()!.unpack() : null),
    (this.scale() !== null ? this.scale()!.unpack() : null)
  );
}


unpackTo(_o: TransformT): void {
  _o.offset = (this.offset() !== null ? this.offset()!.unpack() : null);
  _o.rotation = (this.rotation() !== null ? this.rotation()!.unpack() : null);
  _o.scale = (this.scale() !== null ? this.scale()!.unpack() : null);
}
}

export class TransformT implements flatbuffers.IGeneratedObject {
constructor(
  public offset: Vector3T|null = null,
  public rotation: QuaternionT|null = null,
  public scale: Vector3T|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return Transform.createTransform(builder,
    (this.offset?.x ?? 0),
    (this.offset?.y ?? 0),
    (this.offset?.z ?? 0),
    (this.rotation?.x ?? 0),
    (this.rotation?.y ?? 0),
    (this.rotation?.z ?? 0),
    (this.rotation?.w ?? 0),
    (this.scale?.x ?? 0),
    (this.scale?.y ?? 0),
    (this.scale?.z ?? 0)
  );
}
}
