import type { ScopedLogger } from 'engine-utils-ts';
import { IterUtils, ObservableStream } from 'engine-utils-ts';

import { AllocationSynchronizer } from '../memory/AllocationSynchronizer';
import type { Handle} from '../memory/Handle';
import { HandleIndexMask } from '../memory/Handle';
import { createAndBindManagedAllocator } from '../memory/MemoryUtils';


export interface ECollObj {
	// id: TId,
}

export class EngineCollection<THandle extends Handle, Obj extends ECollObj, TId, TDiff> {

	readonly identifier: string;
	readonly logger: ScopedLogger;

	readonly allocSyncer: AllocationSynchronizer<THandle, [TId, Obj]>;
	readonly idsToHandles: Map<TId, THandle> = new Map();
	readonly perId: Map<TId, Obj> = new Map();
	readonly idsPerHandleIndex: TId[];
	readonly objsPerHandleIndex: (Obj | null)[];

	readonly updatesStream: ObservableStream<Readonly<ECollUpdate<THandle, TId, TDiff>>>;

    constructor(
		identifier: string,
		logger: ScopedLogger,
		// engineGeometries: AllEngineGeometries,
		// engineMaterials: EngineStdMaterials,
		// sceneEntitiesRenderSettings: ObservableObject<RendererSetttings>,
	) {
		this.identifier = identifier;
		this.logger = logger.newScope(identifier);

		this.allocSyncer = new AllocationSynchronizer(`${identifier}-alloc`, this.logger);
		this.objsPerHandleIndex = createAndBindManagedAllocator<Obj | null, [TId, Obj]>(
			this.allocSyncer,
			args => args[1],
			(_) => null
		);
		this.idsPerHandleIndex = createAndBindManagedAllocator(
			this.allocSyncer,
			t => t[0],
		);

		this.updatesStream = new ObservableStream({
			identifier: `${identifier}-updates`,
		});
	}

	allocate(newObjects: [TId, Obj][]) {
		const objectsToAlloc: [TId, Obj][] = IterUtils.filterMap(newObjects, (t) => {
			if (this.idsToHandles.has(t[0])) {
				this.logger.batchedError('element already exists', t[0]);
				return undefined;
			}
			return t;
		});
		const handles = this.allocSyncer.allocate(objectsToAlloc);
		this.logger.assert(handles.length === objectsToAlloc.length, 'allocation sanity check');
		for (let i = 0; i < handles.length; ++i) {
			const handle = handles[i];
			const [id, obj] = objectsToAlloc[i];
			this.idsToHandles.set(id, handle);
			this.perId.set(id, obj);
		}
		if (handles.length) {
			this.updatesStream.pushNext(new ECollAllocated(handles));
		}
		return handles;
	}
	delete(handles: Iterable<THandle>) {
		const deleted: [handle: THandle, id: TId][] = [];
		for (const h of handles) {
			const index = this.allocSyncer.tryGetIndex(h);
			if (index >= 0) {
				const id = this.idsPerHandleIndex[h & HandleIndexMask];
				this.idsToHandles.delete(id);
				this.perId.delete(id);
				deleted.push([h, id]);
			}
		}
		const {removed} = this.allocSyncer.delete(IterUtils.asArray(handles));
		console.assert(removed.length === deleted.length, `engine collection removal sanity check`, removed, deleted);
		if (deleted.length) {
			this.updatesStream.pushNext(new ECollDeleted(deleted));
		}
		return deleted;
	}
    deleteByIds(ids: Iterable<TId>) {
		const handles = IterUtils.filterMap(ids, id => this.idsToHandles.get(id));
		return this.delete(handles);
	}

	peekByHandles(handles: THandle[]) {
		const res: [THandle, Obj][] = [];
		for (const handle of handles) {
			const index = this.allocSyncer.tryGetIndex(handle);
			if (index >= 0) {
				res.push([
					handle,
					this.objsPerHandleIndex[index]!,
				])
			}
		}
		return res;
	}

	peekById(id: TId): Readonly<Obj> | undefined {
		return this.perId.get(id);
	}

	peek(h: THandle): Readonly<Obj> | undefined {
		const ind = this.allocSyncer.tryGetIndex(h);
		if (ind >= 0) {
			return this.objsPerHandleIndex[ind] ?? undefined;
		}
		return undefined;
	}
	idOf(h: THandle): TId | undefined {
		const ind = this.allocSyncer.tryGetIndex(h);
		if (ind >= 0) {
			return this.idsPerHandleIndex[ind] ?? undefined;
		}
		return undefined;
	}
	idsOf(handles: Iterable<THandle>, absentHandles?: THandle[]): TId[] {
		const res: TId[] = [];
		for (const h of handles) {
			const ind = this.allocSyncer.tryGetIndex(h);
			if (ind >= 0) {
				res.push(this.idsPerHandleIndex[ind]);
			} else {
				absentHandles?.push(h);
			}
		}
		return res;
	}

	handlesOf(ids: Iterable<TId>, absentIds?: TId[]): THandle[] {
		const res: THandle[] = [];
		for (const id of ids) {
			const h = this.idsToHandles.get(id);
			if (h !== undefined) {
				res.push(h);
			} else {
				absentIds?.push(id);
			}
		}
		return res;
	}
}


export class ECollAllocated<THandle> {
	constructor(
		public readonly allocated: THandle[],
	) {}
}

export class ECollPatched<THandle, TDIff> {
	constructor(
		public readonly updated: [handle: THandle, diff: TDIff][],
	) {}
}

export class ECollDeleted<THandle, TId> {
	constructor(
		public readonly deleted: [handle: THandle, id: TId][],
	) {}
}

export type ECollUpdate<THandle, TId, TDIff> =
	ECollAllocated<THandle> | ECollPatched<THandle, TDIff> | ECollDeleted<THandle, TId>;


