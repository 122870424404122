<script lang="ts">
    import { CheckboxStandard } from "../libui/checkbox";

    export let label: string = ""
    export let checked:boolean;
</script>

<div class="ui-config-property property-row">
    <div class="checkbox-holder container">
        <CheckboxStandard
            value={checked}
            onChange={() => { checked = !checked }}
            readonly={false}
        />
    </div>
    <div class="mdc-typography mdc-typography--body1 container">
        {label}
    </div>
</div>

<style lang="scss">
    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        user-select: none;
    }
</style>

