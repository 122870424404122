<script lang="ts">
    import MultiSelectorProperty from '../MultiSelectorProperty.svelte'

    import type { AssetCatalogItemProps, Catalog, CatalogItemId, AssetId, CatalogPropertyValue, CatalogItemsReferenceProperty, SceneInstance } from 'bim-ts';
    import { getContext } from 'svelte';
    import { LazyBasic, LazyDerived } from 'engine-utils-ts';
    import { VersionedStore } from '../../VersionedStore';
    import { PUI_PropertyNodeMultiSelector, type MultiSelectorValue } from 'ui-bindings';

    export let name:string = "";

    export let property: CatalogItemsReferenceProperty;
    export let readonly: boolean = false;
    export let enableSelectAll: boolean = false;
    export let doubleLine: boolean = false;
    export let notActive: boolean | undefined = undefined;
    export let formatter: ((assetSi: Readonly<SceneInstance>) => {group: string, name: string}) | undefined = undefined;

    const catalog = getContext<Catalog>('catalog');
    const lazyTypesSet = new LazyBasic<Set<string>|null>('lazy-types', null);
    const lazyAssetTypesSet = new LazyBasic<Set<string>|null>('lazy-types', null);
    const lazyVersionedValues = new LazyBasic('ver-value', property.value.slice());
    $:{
        const typesSet = property.types || property.assetsTypes
            ? new Set([...(property.types ?? []), ...(property.assetsTypes ?? [])])
            : null;
        const assetTypesSet = property.assetsTypes ? new Set(property.assetsTypes) : null;

        lazyTypesSet.replaceWith(typesSet);
        lazyAssetTypesSet.replaceWith(assetTypesSet);
        lazyVersionedValues.replaceWith(property.value.slice());
    }

    interface CatalogItemDescription {
        id: number,
        group?: string,
        label: string,
        notFound: boolean;
    }
    const catalogItemsVersioned = LazyDerived.new2(
        'catalog-items-versioned',
        [
            catalog.catalogItems,
            catalog.assets,
            catalog.assets.sceneInstancePerAsset.assetIdToSceneInstanceId
        ],
        [lazyTypesSet, lazyAssetTypesSet],
        ([typesSet, assetTypesSet]) => {
            const catalogItemsNameMap = new Map<CatalogItemId, CatalogItemDescription>();
            const perAssetCatalogItem = new Map<AssetId, CatalogItemId>();
            for (const [id, item] of catalog.catalogItems.readAll()) {
                if(typesSet != null && !typesSet.has(item.typeIdentifier)){
                    continue;
                }

                const assetId = item.as<AssetCatalogItemProps>().properties?.asset_id?.value ?? 0;
                const assetSi = catalog.assets.sceneInstancePerAsset.getAssetAsSceneInstance(assetId);
                const assetType = assetSi?.type_identifier ?? '';
                if(assetTypesSet !== null && !assetTypesSet.has(assetType)){
                    continue;
                }

                let label = item.name;
                let data = { name: "" };
                if(assetTypesSet !== null){
                    const asset = catalog.assets.peekById(assetId);
                    data = formatter && assetSi ? formatter(assetSi) : {
                         name: catalog.catalogItemsUiLabels.solve(
                                item.typeIdentifier,
                                item.properties,
                            )?.title ?? label
                    }
                    label = asset && asset.name.length > 0 ? asset.name : data.name;
                }

                catalogItemsNameMap.set(id, {id, label, ...data, notFound: false });
                if(assetId){
                    perAssetCatalogItem.set(assetId, id);
                }
            }

            return {
                catalogItemsNameMap,
                perAssetCatalogItem,
            }
        }
    );

    interface CatalogItemSelectorValue extends MultiSelectorValue {
        type: 'catalog_item' | 'asset';
        label:string;
        value: number;
    }
    const lazyPropertyInput = LazyDerived.new2(
        'property-input-from-catalog',
        null,
        [catalogItemsVersioned, lazyVersionedValues],
        ([catalogItems, verValues]) => {
            property = property.withDifferentValue(verValues.slice());
            const selectedValues = new Map<string, CatalogItemSelectorValue>();
            const options = new Map<string, CatalogItemSelectorValue>();
            function addCatalogItemInMap(items: Map<string, CatalogItemSelectorValue>, item: CatalogItemSelectorValue){
                if(items.has(item.label)){
                    const label = item.label + ' ';
                    items.set(label, {...item, label});
                } else {
                    items.set(item.label, item);
                }
            }
            const notFoundIds: number[] = [];
            for (const verValue of verValues) {
                let migratedId: CatalogPropertyValue = verValue;
                if(migratedId.type === 'asset' && catalogItems.perAssetCatalogItem.has(migratedId.id)){
                    const catalogId = catalogItems.perAssetCatalogItem.get(migratedId.id)!;
                    migratedId = {id: catalogId, type: 'catalog_item' };
                }
                let label = migratedId.type === 'catalog_item'
                    ? catalogItems.catalogItemsNameMap.get(migratedId.id)?.label
                    : undefined;
                if(!label){
                    label = `${migratedId.type} id ${migratedId.id} not found`;
                    addCatalogItemInMap(options, {label, value: migratedId.id, type: migratedId.type});
                    notFoundIds.push(migratedId.id);
                }

                addCatalogItemInMap(selectedValues, {label,  value: migratedId.id, type: migratedId.type});
            }

            for (const [id, item] of catalogItems.catalogItemsNameMap) {
                const itemInMap: CatalogItemSelectorValue = {label: item.label, value: id, type: 'catalog_item' };
                if (item.group) {
                    itemInMap.group = item.group;
                }
               addCatalogItemInMap(options, itemInMap);
            }

            return {
                options: Array.from(options.values())
                .sort(sortFn),
                selectedValues: Array.from(selectedValues.values()).sort(sortFn),
                notFoundIds: notFoundIds,
            }
        }
    );
    function sortFn(a: CatalogItemSelectorValue, b: CatalogItemSelectorValue){
        const aValue = parseInt(a.label);
        const bValue = parseInt(b.label);
        if(isFinite(aValue) && isFinite(bValue)){
            return aValue - bValue;
        }
        if (a.label == b.label) {
            return 0;
        }
        if (a.group && b.group) {
            return a.group.localeCompare(b.group) || a.label.localeCompare(b.label);
        }
        return a.label.localeCompare(b.label);
    }

    $:propertyInputStore = new VersionedStore(lazyPropertyInput);

    $:multiProperty = new PUI_PropertyNodeMultiSelector({
        name: name,
        value: $propertyInputStore.selectedValues,
        options: $propertyInputStore?.options ?? [],
        readonly: readonly,
        maxSelect: property.maxCount || undefined,
        description: property.description,
        enableSelectAll: enableSelectAll,
        doubleLine: doubleLine,
        onChange: (changed) => {
            const converted:CatalogPropertyValue[] = [];
            for (const o of changed) {
                const option = o as CatalogItemSelectorValue;
                if(typeof option.value !== 'number'){
                    console.error('not supported type', option);
                    continue;
                }
                if(option.type === undefined){
                    console.error('type field not exist', option);
                    continue;
                }

                converted.push({
                    id: option.value,
                    type: option.type
                });
            };
            // console.log('onChange', changed, converted);

            lazyVersionedValues.replaceWith(converted);
        },
        notActive: notActive,
    });
</script>

<style>
    .show-action {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
</style>



<div class="show-action">
    <MultiSelectorProperty
        property = {multiProperty}
    />
</div>
