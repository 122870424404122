import type { Bim } from '../../Bim';
import { SceneInstancesProperty } from '../../properties/ObjectRefProps';
import { SelectorProperty, NumberProperty, BooleanProperty, NumberPropertyWithOptions } from '../../properties/PrimitiveProps';
import type { PropertyGroup } from '../../properties/PropertyGroup';
import type { ConfigShapeMigration} from '../ConfigsArchetypes';
import { StateType } from '../ConfigsArchetypes';

export const CutFillConfigType = 'cut-and-fill';

export interface CutFillConfig extends PropertyGroup {
    selected_area: SelectorProperty;
    boundary: SceneInstancesProperty;
    equipment: SceneInstancesProperty;
    terrain: BooleanProperty;

    updates_terrain_counter: NumberProperty | null;

    cut_fill_limits: {
        update_within_selection: BooleanProperty;
        north_slope: NumberProperty;
        south_slope: NumberProperty;
        east_west_slope: NumberProperty;
        tolerance: NumberProperty;
        net_balance: NumberPropertyWithOptions;
        timeout: NumberProperty;
    },
    solar_arrays_limits: {
        slope_along_axis: NumberProperty;
        slope_change_bay_to_bay: NumberProperty;
        cumulative: NumberProperty;
    },
    piles: {
        min_pile_reveal: NumberProperty;
        min_pile_embedment: NumberProperty;
    }
}

export function registerCutFillConfig(bim: Bim) {

    const properties: CutFillConfig = {
        terrain: BooleanProperty.new({
            value: true,
        }),
        selected_area: SelectorProperty.new({
            value: "Boundary",
            options: [
                "Boundary",
                "Equipment",
            ],
        }),
        boundary: new SceneInstancesProperty({
            value: [],
            types: ["boundary"],
            description: "select bounding boundaries form the model",
        }),
        equipment: new SceneInstancesProperty({
            value: [],
            types: [
                'any-tracker',
                'tracker',
                'fixed-tilt',
                'substation',
                'transformer',
                'sectionalizing-cabinet',
                'combiner-box',
                'inverter',
                'wire',
                'lv-wire',
                'trench',
                'road',
            ],
            description: "select equipment form the model",
        }),
        updates_terrain_counter: null,
        cut_fill_limits: {
            update_within_selection: BooleanProperty.new({
                value: true,
            }),
            north_slope: NumberProperty.new({
                value: 6.1,
                unit: "%",
                range: [0, Number.MAX_VALUE],
            }),
            south_slope: NumberProperty.new({
                value: 6.1,
                unit: "%",
                range: [0, Number.MAX_VALUE],
            }),
            east_west_slope: NumberProperty.new({
                value: 6.1,
                unit: "%",
                range: [0, Number.MAX_VALUE],
            }),
            tolerance: NumberProperty.new({
                value: 0.5,
                unit: "ft",
                range: [0.00, Number.MAX_VALUE],
            }),
            net_balance: NumberPropertyWithOptions.new({
                value: 0,
                unit: "yd3",
                selectedOption: 'auto',
                options: ['set', 'auto'],
            }),
            timeout: NumberProperty.new({
                value: 30,
                range: [0, Number.MAX_VALUE],
                step: 1,
                unit: "sec"
            }),
        },
        solar_arrays_limits: {
            slope_along_axis: NumberProperty.new({
                value: 4.1,
                unit: "%",
                range: [0, Number.MAX_VALUE],
            }),
            slope_change_bay_to_bay: NumberProperty.new({
                value: 0,
                unit: "%",
                range: [0, Number.MAX_VALUE],
            }),
            cumulative: NumberProperty.new({
                value: 0,
                unit: "%",
                range: [0, Number.MAX_VALUE],
            }),
        },
        piles: {
            min_pile_reveal: NumberProperty.new({
                value: 4,
                unit: "ft",
                range: [0, Number.MAX_VALUE],
            }),
            min_pile_embedment: NumberProperty.new({
                value: 4,
                unit: "ft",
                range: [0, Number.MAX_VALUE],
            })
        },
    };

	bim.configs.archetypes.registerArchetype(
		{
			type_identifier: CutFillConfigType,
            properties: () => properties,
            stateType: StateType.Singleton,
            shapeMigrations: getMigrations()
		}
	);
}

function getMigrations(): ConfigShapeMigration[] {
    return [
        {
            toVersion: 1,
            validation: {
                updatedProps: [
                    { path: ["install_equipment", "select_mode"]},
                    { path: ["install_equipment", "boundary"]},
                ],
                deletedProps: [],
            },
            patch: (c)=>{
                const props = c.get<CutFillConfig>();
                //@ts-ignore
                props.install_equipment.select_mode = SelectorProperty.new({
                    value: "equipment",
                    options: [
                        "all equipment",
                        "equipment in boundary",
                        "equipment",
                    ],
                });
                //@ts-ignore
                props.install_equipment.boundary = new SceneInstancesProperty({
                    value: [],
                    types: ["boundary"],
                    description: "select bounding boundaries form the model",
                });
            }
        },
        {
            toVersion: 2,
            validation: {
                updatedProps: [
                    { path: ["install_equipment", "max_distance_between_equipment"]},
                ],
                deletedProps: [
                    { path: ["install_equipment", "max_distance_between_groups"] },
                ],
            },
            patch: (c) => {
                const props = c.get<CutFillConfig>();
                //@ts-ignore
                const max_dist_between_equipment = props.install_equipment.max_distance_between_equipment;
                //@ts-ignore
                props.install_equipment.max_distance_between_equipment = NumberProperty.new({
                    ...max_dist_between_equipment,
                    range: [3, Number.MAX_VALUE],
                });
                //@ts-ignore
                delete props.install_equipment.max_distance_between_groups;
            }
        },
        {
            toVersion: 3,
            validation: {
                updatedProps: [
                    { path: ["install_equipment", "zones_join_delta"] },
                ],
                deletedProps: [],
            },
            patch: (c) => {
                const props = c.get<CutFillConfig>();
                //@ts-ignore
                props.install_equipment.zones_join_delta = NumberProperty.new({
                    value: 65,
                    unit: "ft",
                    range: [0, Number.MAX_VALUE],
                });
            }
        },
        {
            toVersion: 4,
            validation: {
                updatedProps: [
                    { path: ["install_equipment", "cut_fill_limits", "net_balance"] },
                    { path: ["install_equipment", "cut_fill_limits", "tolerance"] },
                    { path: ["install_equipment", "cut_fill_limits", "east_west_slope_max"] },
                    { path: ["install_equipment", "cut_fill_limits", "north_south_slope_max"] },
                    { path: ["install_equipment", "ground_mounting_system_limits", "north_slope_max"] },
                    { path: ["install_equipment", "ground_mounting_system_limits", "south_slope_max"] },
                    { path: ["install_equipment", "ground_mounting_system_limits", "east_west_slope_max"] },
                ],
                deletedProps: [
                    { path: ["install_equipment", "max_angle_between_groups"] },
                    { path: ["install_equipment", "max_distance_between_equipment"] },
                    { path: ["install_equipment", "max_height_between_equipment"] },
                    { path: ["install_equipment", "zones_join_delta"] },
                    { path: ["install_equipment", "reveal_tolerance"] },
                    { path: ["install_equipment", "net_cut_and_fill"] },
                    { path: ["install_equipment", "north_max_slope"] },
                    { path: ["install_equipment", "south_max_slope"] },
                ],
            },
            patch: (c) => {
                const props = c.get<CutFillConfig>();
                //@ts-ignore
                props.install_equipment.cut_fill_limits = {
                    //@ts-ignore
                    net_balance: props.install_equipment.net_cut_and_fill,
                    //@ts-ignore
                    tolerance: props.install_equipment.reveal_tolerance,
                    east_west_slope_max:  NumberProperty.new({
                        value: 6.1,
                        unit: "%",
                        range: [0, Number.MAX_VALUE],
                    }),
                    north_south_slope_max: NumberProperty.new({
                        value: 6.1,
                        unit: "%",
                        range: [0, Number.MAX_VALUE],
                    }),
                };
                //@ts-ignore
                props.install_equipment.ground_mounting_system_limits = {
                    //@ts-ignore
                    north_slope_max: props.install_equipment.north_max_slope,
                    //@ts-ignore
                    south_slope_max: props.install_equipment.south_max_slope,
                    east_west_slope_max:  NumberProperty.new({
                        value: 6.1,
                        unit: "%",
                        range: [0, Number.MAX_VALUE],
                    }),
                };
                //@ts-ignore
                delete props.install_equipment.max_angle_between_groups;
                //@ts-ignore
                delete props.install_equipment.max_distance_between_equipment;
                //@ts-ignore
                delete props.install_equipment.max_height_between_equipment;                
                //@ts-ignore
                delete props.install_equipment.zones_join_delta;                
                //@ts-ignore
                delete props.install_equipment.reveal_tolerance;
                //@ts-ignore
                delete props.install_equipment.net_cut_and_fill;
                //@ts-ignore
                delete props.install_equipment.north_max_slope;
                //@ts-ignore
                delete props.install_equipment.south_max_slope;
                
            }
        },
        {
            toVersion: 5,
            validation: {
                updatedProps: [
                    { path: ["install_equipment", "solver"] },
                ],
                deletedProps: [ ],
            },
            patch: (c) => {
                const props = c.get<CutFillConfig>();
                //@ts-ignore;
                props.install_equipment = {
                    //@ts-ignore;
                    ...props.install_equipment,
                    solver: SelectorProperty.new({
                        value: "Victor",
                        options: [
                            "Victor",
                            "Aliaksei",
                        ],
                    }),
                };
            }
        },
        {
            toVersion: 6,
            validation: {
                updatedProps: [
                    { path: ["install_equipment", "grid_factor"] },
                ],
                deletedProps: [ ],
            },
            patch: (c) => {
                const props = c.get<CutFillConfig>();
                //@ts-ignore;
                props.install_equipment = {
                    //@ts-ignore;
                    ...props.install_equipment,
                    grid_factor: NumberProperty.new({
                        value: 8,
                        range: [1, 100],
                        step: 1,
                    }),
                };
            }
        },
        {
            toVersion: 8,
            validation: {
                updatedProps: [
                    { path: ["install_equipment", "placement_method"] },
                    { path: ["install_equipment", "select_mode"] },
                ],
                deletedProps: [ ],
            },
            patch: (c) => {
                const props = c.get<CutFillConfig>();
                //@ts-ignore                
                const selectMode = props.install_equipment.select_mode;
                const selectModeValue = selectMode.value === 'all equipment' 
                    ? 'All equipment' 
                    : selectMode.value === 'equipment in boundary' 
                        ? 'Equipment in boundary' 
                        : 'Select equipment';
                const selectModeOptions = [
                    "All equipment",
                    "Equipment in boundary",
                    "Select equipment",
                ];
                //@ts-ignore
                props.install_equipment.select_mode = SelectorProperty.new({
                    value: selectModeOptions.includes(selectMode.value) ? selectMode.value : selectModeValue,
                    options: selectModeOptions,
                });
                //@ts-ignore
                const placementMethod = props.install_equipment.placement_method;
                const placementOptions: string[] = [
                    "Place on existing terrain",
                    "Optimise piles on existing terrain",
                    "Cut & Fill terrain",
                ];
                const placementMethodValue = placementMethod.value === 'basic' 
                    ? 'Place on existing terrain' 
                    : placementMethod.value === 'cut&fill' 
                        ? 'Cut & Fill terrain' 
                        : 'Optimise piles on existing terrain';
                    //@ts-ignore
                    props.install_equipment.placement_method = SelectorProperty.new({
                    value: placementOptions.includes(placementMethod.value) ? placementMethod.value : placementMethodValue,
                    options: placementOptions,
                });
            }
        },
        {
            toVersion: 9,
            validation: { 
                updatedProps: [
                    { path:['reset_cut_and_fill', 'reset'] },
                    { path: ["install_equipment", "placement_method"] },
                ],
                deletedProps: [{path:['reset_cut_and_fill', 'boundary']}],
            },
            patch: (c) => {
                const props = c.get<CutFillConfig>();
                //@ts-ignore
                props.reset_cut_and_fill.reset = BooleanProperty.new({value: false});
                //@ts-ignore
                delete props.reset_cut_and_fill.boundary;
                //@ts-ignore
                const placementMethod = props.install_equipment.placement_method;
                const placementMethodValue = placementMethod.value === 'Cut & Fill terrain' 
                    ? 'Optimise terrain with Cut & Fill' 
                    : placementMethod.value;
                const placementOptions: string[] = [
                    "Place on existing terrain",
                    "Optimise piles on existing terrain",
                    "Optimise terrain with Cut & Fill",
                ];
                //@ts-ignore
                props.install_equipment.placement_method = SelectorProperty.new({
                    value: placementMethodValue,
                    options: placementOptions,
                });
            }
        },
        {
            toVersion: 10,
            validation: { 
                updatedProps: [{ path:['reset_cut_and_fill', 'boundary'] }],
                deletedProps: [],
            },
            patch: (c) => {
                const props = c.get<CutFillConfig>();
                //@ts-ignore
                props.reset_cut_and_fill.boundary = new SceneInstancesProperty({
                    value: [],
                    types: ["boundary"],
                });
            }
        },
        {
            toVersion: 11,
            validation: { 
                updatedProps: [
                    { path: ["install_equipment", "cut_fill_limits", "tolerance"] },
                ],
                deletedProps: [],
            },
            patch: (c) => {
                const props = c.get<CutFillConfig>();
                //@ts-ignore
                props.install_equipment.cut_fill_limits.tolerance = NumberProperty.new({
                    //@ts-ignore
                    value: props.install_equipment.cut_fill_limits.tolerance.as('ft') + 0.05,
                    unit: "ft",
                    range: [0.05, Number.MAX_VALUE],
                });
            }
        },
        {
            toVersion: 12,
            validation: { 
                updatedProps: [
                    { path: ["terrain"] },
                    { path: ["selected_area"] },
                    { path: ["cut_fill_limits"] },
                    { path: ["solar_arrays_limits"] },
                    { path: ["piles"] },
                ],
                deletedProps: [
                    { path: ["install_equipment"] },
                    { path: ["reset_cut_and_fill"] },
                ],
            },
            patch: (c) => {
                const props = c.get<CutFillConfig>();

                props.terrain = BooleanProperty.new({
                    value: true,
                });
                //@ts-ignore
                props.boundary = props.install_equipment.boundary;
                //@ts-ignore
                props.equipment = props.install_equipment.equipment;

                props.selected_area = SelectorProperty.new({
                    value: "Boundary",
                    options: [
                        "All equipment",
                        "Boundary",
                        "Equipment",
                    ],
                });
                //@ts-ignore
                props.cut_fill_limits = {
                    //@ts-ignore
                    grid_factor: props.install_equipment.grid_factor,
                    //@ts-ignore
                    north_slope: props.install_equipment.cut_fill_limits.north_south_slope_max,
                    //@ts-ignore
                    south_slope: props.install_equipment.cut_fill_limits.north_south_slope_max,
                    //@ts-ignore
                    east_west_slope: props.install_equipment.cut_fill_limits.east_west_slope_max,
                    //@ts-ignore
                    tolerance: props.install_equipment.cut_fill_limits.tolerance,
                    //@ts-ignore                   
                    net_balance: props.install_equipment.cut_fill_limits.net_balance,
                    update_within_selection: BooleanProperty.new({ value: true }),
                };
                props.piles = {
                    //@ts-ignore
                    min_pile_reveal: props.install_equipment.min_pile_reveal,
                    //@ts-ignore
                    max_pile_reveal: props.install_equipment.max_pile_reveal,
                    //@ts-ignore
                    min_pile_embedment: props.install_equipment.min_pile_embedment,
                };
                props.solar_arrays_limits = {
                    //@ts-ignore
                    slope_along_axis: props.install_equipment.max_slope,
                    slope_change_bay_to_bay: NumberProperty.new({
                        value: 6.1,
                        unit: "deg",
                    }),
                    cumulative: NumberProperty.new({
                        value: 8,
                        unit: "deg",
                    }),
                };
                //@ts-ignore;
                delete props.install_equipment;
                //@ts-ignore;
                delete props.reset_cut_and_fill;
            }
        },
        {
            toVersion: 13,
            validation: { 
                updatedProps: [
                    { path: ["solar_arrays_limits", "slope_change_bay_to_bay"] },
                    { path: ["solar_arrays_limits", "cumulative"] },
                ],
                deletedProps: [
                    { path: ["piles", "max_pile_reveal"] },
                ],
            },
            patch: (c) => {
                const props = c.get<CutFillConfig>();
                props.selected_area = SelectorProperty.new({
                    ...props.selected_area,
                    options: [
                        "Boundary",
                        "Equipment",
                    ],
                });
                props.cut_fill_limits.tolerance = NumberProperty.new({
                    ...props.cut_fill_limits.tolerance,
                    range: [0.5, Number.MAX_VALUE],
                });
                props.solar_arrays_limits.slope_change_bay_to_bay = NumberProperty.new({
                    value: 0,
                    unit: "%",
                    range: [0, Number.MAX_VALUE],
                });
                props.solar_arrays_limits.cumulative = NumberProperty.new({
                    value: 0,
                    unit: "%",
                    range: [0, Number.MAX_VALUE],
                });
                props.piles = {
                    min_pile_embedment: props.piles.min_pile_embedment,
                    min_pile_reveal: props.piles.min_pile_reveal,
                };
                
            }
        },
        {
            toVersion: 14,
            validation: { 
                updatedProps: [
                    { path: ["cut_fill_limits", "tolerance"] },
                ],
                deletedProps: [
                ],
            },
            patch: (c) => {
                const props = c.get<CutFillConfig>();
                props.cut_fill_limits.tolerance = NumberProperty.new({
                    ...props.cut_fill_limits.tolerance,
                    range: [0.05, Number.MAX_VALUE],
                });
            }
        },
        {
            toVersion: 15,
            validation: { 
                updatedProps: [
                    { path: ["cut_fill_limits", "timeout"] },
                    { path: ["cut_fill_limits", "grid_size"] },
                ],
                deletedProps: [
                    { path: ["cut_fill_limits", "grid_factor"] }
                ],
            },
            patch: (c) => {
                const props = c.get<CutFillConfig>();
                props.cut_fill_limits.timeout = NumberProperty.new({
                    value: 60,
                    range: [0, Number.MAX_VALUE],
                    step: 1,
                    unit: "sec"
                });
                //@ts-ignore
                props.cut_fill_limits.grid_size = NumberProperty.new({
                    value: 4,
                    range: [2, 16],
                    step: 2,
                });
                props.cut_fill_limits.tolerance = NumberProperty.new({
                    ...props.cut_fill_limits.tolerance,
                    range: [0.0, Number.MAX_VALUE],
                });
            }
        },
        {
            toVersion: 16,
            validation: { 
                updatedProps: [
                    { path: ["cut_fill_limits", "timeout"] },
                ],
                deletedProps: [
                    { path: ["cut_fill_limits", "grid_size"] },
                    { path: ["cut_fill_limits", "grid_factor"] }
                ],
            },
            patch: (c) => {
                const props = c.get<CutFillConfig>();

                props.cut_fill_limits = {
                    update_within_selection: props.cut_fill_limits.update_within_selection,
                    north_slope: props.cut_fill_limits.north_slope,
                    south_slope: props.cut_fill_limits.south_slope,
                    east_west_slope: props.cut_fill_limits.east_west_slope,
                    tolerance: props.cut_fill_limits.tolerance,
                    net_balance: props.cut_fill_limits.net_balance,
                    timeout: NumberProperty.new({
                        ...props.cut_fill_limits.timeout,
                        value: 30,
                    }),
                };
            }
        },
        {
            toVersion: 17,
            validation: { 
                updatedProps: [
                    { path: ["updates_terrain_counter"] },
                ],
                deletedProps: [],
            },
            patch: (c) => {
                const props = c.get<CutFillConfig>();
                props.updates_terrain_counter = NumberProperty.new({value: 1, step: 1});
            }
        },
        {
            toVersion: 18,
            validation: { 
                updatedProps: [
                    { path: ["equipment"] },
                ],
                deletedProps: [],
            },
            patch: (c) => {
                const props = c.get<CutFillConfig>();
                props.equipment = new SceneInstancesProperty({
                    value: [],
                    types: [
                        'any-tracker',
                        'tracker',
                        'fixed-tilt',
                        'substation',
                        'transformer',
                        'sectionalizing-cabinet',
                        'combiner-box',
                        'inverter',
                        'wire',
                        'lv-wire',
                        'trench',
                        'road',
                    ],
                    description: "select equipment form the model",
                });
            }
        },
        {
            toVersion: 19,
            validation: { 
                updatedProps: [
                    { path: ["cut_fill_limits", "net_balance"] },
                ],
                deletedProps: [],
            },
            patch: (c) => {
                const props = c.get<CutFillConfig>();
                props.cut_fill_limits.net_balance = NumberPropertyWithOptions.new({
                    value: props.cut_fill_limits.net_balance.value,
                    unit: props.cut_fill_limits.net_balance.unit,
                    selectedOption: 'set',
                    options: ['set', 'auto'],
                });
            }
        },
    ]
}
