import type { IdBimScene, SceneInstances } from 'bim-ts';
import { SceneInstanceFlags } from 'bim-ts';
import type { EventStackFrame, VersionedValue } from 'engine-utils-ts';
import { VersionedInvalidator } from 'engine-utils-ts';
import type { Aabb, Vector3 } from 'math-ts';
import { Matrix4 } from 'math-ts';
import type { GesturesMousePos } from '../controls/MouseGesturesBase';
import type { KrCamera } from '../controls/MovementControls';
import { InObjIdType } from './EngineSceneIds';
import type { InteractiveObjectIntersection } from './Raycasts';
import { BimObjectSceneIntersection } from './Raycasts';
import type { SubmeshesRaycasts } from './SubmeshesRaycasts';
import type { EngineScene } from './EngineScene';
import type { InteractiveSceneObjects, IdEngineObject} from './InteractiveSceneObjects';
import { TransformsProviderType, calcOriginsCenterFromMatrices } from './InteractiveSceneObjects';
import type { Submeshes2 } from '../scene/Submeshes2';
import type { ESOsCollection, ESOHandle } from './ESOsCollection';
import type { SnappingSettings } from '../SnappingSettings';


export class InteractiveSceneObjectsBim implements InteractiveSceneObjects<IdBimScene, BimObjectSceneIntersection> {

	readonly selectionInvalidator: VersionedValue;
	readonly repr_invalidator: VersionedValue;

	readonly scene: EngineScene;
	readonly bimObjects: SceneInstances;
	readonly engineObjects: ESOsCollection;
	readonly submeshes: Submeshes2;
	readonly raycasts: SubmeshesRaycasts;

	constructor(scene: EngineScene) {
		this.scene = scene;
		this.bimObjects = scene.bim.instances;
		this.engineObjects = scene.esos;
		this.submeshes = scene.submeshes;
		this.raycasts = scene.submeshesRaycasts;
		this.selectionInvalidator = scene.bim.instances.selectHighlight.getInvalidatorOf(SceneInstanceFlags.isSelected);
		this.repr_invalidator = new VersionedInvalidator([
			scene.submeshes.hash,
			// scene.bimSceneGizmos.objects.repr_hash
		]);
	}

	type(): TransformsProviderType {
		return TransformsProviderType.Bim;
	}

	getVisible(): IdBimScene[] {
		return this.bimObjects.getVisible();
	}

	getHighlighted(): IdBimScene[] {
		return this.bimObjects.getHighlighted();
	}
	setHighlighted(ids: IdBimScene[]): void {
		this.bimObjects.setHighlighted(ids);
	}

	anySelected(): boolean {
		return this.bimObjects.anySelected();
	}
	getSelected(): IdBimScene[] {
		return this.bimObjects.getSelected();
	}
	setSelected(ids: IdBimScene[]): void {
		this.bimObjects.setSelected(ids);
	}
	toggleSelected(isSelected: boolean, ids: IdBimScene[]): void {
		return this.bimObjects.toggleSelected(isSelected, ids);
	}

	cloneObjects(ids: IdBimScene[], worldSpaceDirectionOfClone: Vector3) {
		const newIds = this.bimObjects.clone(ids);
		this.scene.syncWithBim();
		return { newObjects: newIds, toUseForGesture: newIds };
	}
	deleteObjects(ids: IdBimScene[]): void {
		this.bimObjects.delete(ids);
	}

	getWorldMatricesOf(ids: IdBimScene[]): Map<IdBimScene, Matrix4> {
		return this.bimObjects.getWorldMatricesOf(ids);
	}
	patchWorldMatrices(patches: Map<IdBimScene, Matrix4>, e: Partial<EventStackFrame>): void {
		this.bimObjects.patchWorldMatrices(patches, e);
	}

	gatherIdsWithSubtreesOf(params: { ids: IdBimScene[]; sortParentFirst?: boolean; }): IdEngineObject[] {
		return this.bimObjects.spatialHierarchy.gatherIdsWithSubtreesOf(params);
	}
	getChildrenOf(ids: IdBimScene[]): IdBimScene[] {
		const res = [];
		for (const id of ids) {
			for (const ch of this.bimObjects.spatialHierarchy.iteratorOfChildrenOf(id)) {
				res.push(ch);
			}
		}
		return res;
	}
	getParentsOf(ids: IdBimScene[]): Map<IdEngineObject, IdEngineObject> {
		const res = new Map();
		for (const id of ids) {
			const state = this.bimObjects.peekById(id);
			if (state) {
				res.set(id, state.spatialParentId);
			}
		}
		return res;
	}

	calcLocalOrientationFor(ids: IdBimScene[]): Matrix4 {
		const rotation = new Matrix4();
		const lastSelected = ids[ids.length - 1];
		if (lastSelected != undefined) {
			const m = this.bimObjects.peekWorldMatrix(lastSelected);
			if (m) {
				rotation.extractRotation(m);
			}
		}
		return rotation;
	}
	calcBboxOf(ids: IdBimScene[]): Aabb {
		const bbox = this.scene.calcBoundsByIds(ids);
		return bbox;
	}
	calcOriginsCenterOf(ids: IdBimScene[]): Vector3 {
		return calcOriginsCenterFromMatrices(this.getWorldMatricesOf(ids).values());
	}

	findIntersectingFrustum(frustumMatrix: Matrix4, near: number, far: number, strictInside: boolean): IdBimScene[] {
		const esoHandles = this.raycasts.findIntersectingFrustum<ESOHandle>(
			frustumMatrix,
			near,
			far,
			strictInside,
			s => s.fullId.inObjId.ty === InObjIdType.ObjSelf,
			(h) => this.submeshes.getParentEsoHandle(h)!,
			(esoHandle) => this.submeshes.getByParentEsoHandle(esoHandle),

		);
		return this.engineObjects.idsOf(esoHandles);
	}

	findPointToSnap(snapNearPosition: Vector3, skipIds: Set<IdEngineObject>, camera: KrCamera, settings: SnappingSettings): Vector3 | null {
		return this.raycasts.findPointToSnap(snapNearPosition, skipIds, camera, settings);
	}

	intersectRay(camera: KrCamera, mousePos: GesturesMousePos): BimObjectSceneIntersection | null {
		return this.raycasts.intersectRay(
			camera,
			mousePos,
			(s) => {
				return s.fullId.inObjId.ty === InObjIdType.ObjSelf;
			},
			(int, s) => {
				return new BimObjectSceneIntersection(
					int.distance,
					int.point,
					int.normal,
					s.fullId.objHandle,
				);
			}
		)
	}
	getIdsFromIntersection(int: InteractiveObjectIntersection): IdBimScene[] {
		if (int instanceof BimObjectSceneIntersection) {
			const id = this.engineObjects.idOf(int.esoHandle);
			if (id) {
				return [id];
			}
		}
		return [];
	}

}
