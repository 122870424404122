<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        d="M5.49997 7.95361L1.06692 13.8643C1.00092 13.9523 0.965785 14.0596 0.966947 14.1696L0.994782 16.8053C0.997677 17.0793 1.22067 17.3 1.49475 17.3H17.6653C18.0774 17.3 18.3126 16.8296 18.0653 16.5L17.6154 15.9002C17.5209 15.7743 17.3728 15.7002 17.2154 15.7002H11.3099L5.49997 7.95361Z"
        fill="currentColor"
    />
    <path
        d="M12.41 14L8.68751 9.03671L12 4.62012L18.4349 13.2C18.6821 13.5296 18.4469 14 18.0349 14H12.41Z"
        fill="currentColor"
    />
</svg>
