<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.50001 5.8002H6.80001V18.8002H5.20001V5.8002H3.50001C2.78204 5.8002 2.20001 5.21817 2.20001 4.5002V2.5002C2.20001 1.78223 2.78204 1.2002 3.50001 1.2002H8.50001C9.21798 1.2002 9.80001 1.78223 9.80001 2.5002V4.5002C9.80001 5.21817 9.21798 5.8002 8.50001 5.8002ZM3.80001 4.2002V2.8002H8.20001V4.2002H3.80001Z"
        fill="currentColor"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2 10.5H8V8.5H10.2C10.2001 7.78212 10.7821 7.2002 11.5 7.2002H16.5C17.218 7.2002 17.8 7.78223 17.8 8.5002V10.5002C17.8 11.2182 17.218 11.8002 16.5 11.8002H11.5C10.782 11.8002 10.2 11.218 10.2 10.5ZM11.8 10.2002V8.8002H16.2V10.2002H11.8Z"
        fill="currentColor"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2997 14C10.4957 13.5303 10.9593 13.2002 11.5 13.2002H16.5C17.218 13.2002 17.8 13.7822 17.8 14.5002V16.5002C17.8 17.2182 17.218 17.8002 16.5 17.8002H11.5C10.782 17.8002 10.2 17.2182 10.2 16.5002V16H8V14H10.2997ZM11.8 14.8002V16.2002H16.2V14.8002H11.8Z"
        fill="currentColor"
    />
</svg>
