import type { ScopedLogger } from "engine-utils-ts";
import { ErrorUtils, ObjectUtils, WorkerClassPassRegistry } from "engine-utils-ts";
import type { PropertyBase } from "./Props";
import type { PropertyToBasicTypesViewTransformer } from './BasicPropsView';



export type PropertyRegistrationParams<P extends PropertyBase> = {
    class: {new (...args: any): P},
    constructFromPartial: {new (args: Partial<P>): P} | ((args: Partial<P>) => P),
    serializedTypeIdent?: string,
    serializerClass?: { new(logger: ScopedLogger): CustomPropertySerializer<P> },
    basicTypesView?: PropertyToBasicTypesViewTransformer<P>,
};

type CustomPropCtor = {new (...args: any): PropertyBase};

export type CustomPropertySerializerCtor<T extends PropertyBase> = { new(logger: ScopedLogger): CustomPropertySerializer<T> };

export class _CustomPropsRegistry {

    readonly _serializedTypeIdentPerCtor = new Map<CustomPropCtor, string>();
    readonly _classPerSerializedTypeIdent = new Map<string, CustomPropCtor>();
    readonly _serializersCtorsPerPropCtor = new Map<CustomPropCtor, CustomPropertySerializerCtor<any>>();

    readonly _constructFromPartialFuncs = new Map<CustomPropCtor, (args: Partial<PropertyBase>) => PropertyBase>();

    readonly _basicTypesViewPerCtor = new Map<CustomPropCtor, PropertyToBasicTypesViewTransformer<any>>();

    register<P extends PropertyBase>(args: PropertyRegistrationParams<P>) {

        const serializedTypeIdent = args.serializedTypeIdent || args.class.name;
        const ctor = args.class as unknown as CustomPropCtor;

        if (this._classPerSerializedTypeIdent.has(serializedTypeIdent)) {
            console.error(`CUSTOM PROPERTY WITH SERIALIZED NAME ${serializedTypeIdent} IS already registered`);
        }

        this._serializedTypeIdentPerCtor.set(ctor,serializedTypeIdent);
        this._classPerSerializedTypeIdent.set(serializedTypeIdent, ctor);

        if (args.serializerClass) {
            this._serializersCtorsPerPropCtor.set(ctor, args.serializerClass);
        }

        const propFromPartialConstructor = args.constructFromPartial;
        if (ObjectUtils.isClassConstructor(propFromPartialConstructor)) {
            this._constructFromPartialFuncs.set(ctor, (params) => new propFromPartialConstructor(params as any));
        } else {
            this._constructFromPartialFuncs.set(ctor, propFromPartialConstructor as any);
        }

        if (args.basicTypesView) {
            this._basicTypesViewPerCtor.set(ctor, args.basicTypesView);
        }
            
        WorkerClassPassRegistry.registerClass(args.class);
    }

    tryGetSerializerForTypeIdent(classTypeIdent: string): CustomPropertySerializerCtor<PropertyBase> | undefined {
        const ctor = this._classPerSerializedTypeIdent.get(classTypeIdent);
        if (!ctor) {
            ErrorUtils.logThrow('no class registered for type ident', classTypeIdent);
        }
        return this.tryGetSerializerForClass(ctor);
    }

    tryGetSerializerForClass(propClass: CustomPropCtor): CustomPropertySerializerCtor<PropertyBase> | undefined {
        const ctor = this._serializersCtorsPerPropCtor.get(propClass);
        if (!ctor) {
            ErrorUtils.logThrow('no class registered for type ident', propClass);
        }
        return ctor;
    }

    getSerializationTypeIdentifierOf<T extends PropertyBase>(property: T): string {
        const ctor = property.constructor as CustomPropCtor;
        const ident = this._serializedTypeIdentPerCtor.get(ctor);
        if (!ident) {
            ErrorUtils.logThrow('no type ident registered for class', ctor.name, property);
        }
        return ident;
    }

    constructPropertyFromPartialArgs<T>(ctor: CustomPropCtor, args: Partial<T>): T {
        const constructFromPartial = this._constructFromPartialFuncs.get(ctor);
        if (!constructFromPartial) {
            ErrorUtils.logThrow('no constructFromPartial function registered for class', ctor.name, args);
        }
        return constructFromPartial(args as any) as T;
    }

    tryGetBasicTypesViewForClass<P extends PropertyBase>(propClass: CustomPropCtor): PropertyToBasicTypesViewTransformer<P> | undefined {
        return this._basicTypesViewPerCtor.get(propClass) as any;
    }
}

export const CustomPropsRegistry = new _CustomPropsRegistry();

// if both string and binary serialization methods are present, binary will be used in verdata and string in assets
export interface CustomPropertySerializer<P extends PropertyBase> {
    readonly currentFormatVersion: number;
    serializeToBinary?(p: P): Uint8Array;
    serializeToString?(p: P): string;
    deserializeFromBinary?(formatVersion: number, serialized: Uint8Array): P | null;
    deserializeFromString?(formatVersion: number, serialized: string): P | null;
}
