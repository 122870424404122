<script lang="ts">
    import { ContextMenuConfig, PUI_Lazy, UiBindings } from "ui-bindings";
    import { LazyDerived, ObservableObject } from "engine-utils-ts";
    import ToolbarButton from "./ToolbarButton.svelte";
    import { getContext } from "svelte";
    import { ProjectHub } from "../ProjectHub";
    import { IconComponent } from "../libui/icon";
    import { VerDataSyncStatus, VerDataSyncer } from "verdata-ts";
    import { ProjectVersionParams } from "../verdata/ProjectVersionParams";
    import { saveProjectVersionUi } from "../verdata/VersionParams";
    import { Vector2 } from "math-ts";

    export let uiBindings: UiBindings;

    const projectHub = getContext<ProjectHub>("projectHub");
    const projectId = getContext<number>("projectId");
    const verDataSyncer = getContext<VerDataSyncer>("verDataSyncer");
    const projectParams = getContext<ProjectVersionParams>("projectParams");

    function openSaveDialog(e: Event) {
        const buttonRect = (
            e.currentTarget as HTMLElement
        ).getBoundingClientRect();
        const paramsObs = new ObservableObject<{ description: string }>({
            identifier: "version-props",
            initialState: {
                description: "",
            },
        });
        
        const config = new ContextMenuConfig({
            identity: `save-project-version`,
            header: "Save new project version",
            widthPx: 464,
            positionPx: new Vector2(buttonRect.left - 12, buttonRect.bottom),
            viewSource: new PUI_Lazy(
                saveProjectVersionUi(verDataSyncer, paramsObs),
            ),
            action: {
                name: "Save",
                actionFn: () => {
                    projectParams.saveVersionWithParams(paramsObs.poll().description);
                },
                isEnabled: LazyDerived.fromMutatingObject<boolean>(() => verDataSyncer.canStartSaveNow()),
            },
        });
        uiBindings.addContextMenuView(config);
    }

    const isSaveProjectEnabled = LazyDerived.new1(
        'saveEnabled',
        [verDataSyncer],
        [verDataSyncer.status],
        ([status]) => status.syncStatus === VerDataSyncStatus.Loaded && verDataSyncer.haveUpdatesForSave(),
    );
    $: projectName = $projectHub.project?.name;
</script>

<div class="project-info text-main-dark">
    <span class="mdc-typography--body1"
        >{`Project: ${$projectHub.project?.name || '#' + projectId}`}</span
    >
    <ToolbarButton onClick={openSaveDialog} visible={isSaveProjectEnabled}>
        <svelte:fragment>
            <IconComponent name="Save"></IconComponent>
            <span style="margin-left: 4px;">Save</span>
        </svelte:fragment>
    </ToolbarButton>
</div>

<style lang="scss">
    .project-info {
        display: flex;
        align-items: center;
        gap: 16px;
    }
</style>
