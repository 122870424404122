<script lang="ts">
    import { ButtonComponent, IconButton } from '../button';
    import type { DialogInput } from "./types";

    export let dialogInput: DialogInput;
    export let close: (() => void) | undefined = undefined;
</script>

    <div class="mdc-dialog__surface mdc-typography text-main-dark"
      role="alertdialog"
      aria-modal="true"
      aria-labelledby="my-dialog-title"
      aria-describedby="my-dialog-content"
    >
        {#if dialogInput.title !== undefined}
          <div class="mdc-dialog__header">
            <span class="mdc-typography--headline4" id="my-dialog-title">
                {dialogInput.title}
            </span>
            {#if close}
              <div tabindex="0" style="outline: none; padding-top: 2px;">
                <ButtonComponent desc={new IconButton("Close", close)}/>
              </div>
            {/if}
          </div>
        {/if}
        {#if dialogInput.message !== undefined}
          <div class="custom-dialog__content mdc-typography--body1 text-main-medium" id="my-dialog-content">
            {@html dialogInput.message}
          </div>
        {/if}
        <slot name="body"></slot>
        {#if $$slots.actions}
          <div class="mdc-dialog__actions">
              <slot name="actions"></slot>
          </div>
        {/if}
    </div>

<style lang="scss">
  .mdc-dialog__header {
    padding: 12px 20px 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
    overflow-wrap: anywhere;
  }
  .custom-dialog__content {
    padding: 8px 16px;
  }
  .mdc-dialog__actions {
    padding: 16px;
    gap: 4px;
  }
  .mdc-dialog__actions:empty {
    display: none;
  }
</style>
