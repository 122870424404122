<script lang="ts">
import { ButtonComponent, Button, IconButton } from "../button";

import type { Variants } from "./types";

import { Icon } from "../icon";
import type { ContextMenu} from "../context-menu";
import { ContextMenuStructure } from "../context-menu";
import { Checkbox, CheckBoxComponent } from "../checkbox";
import { getContext } from "svelte";

let contextMenu = getContext<ContextMenu>('context-menu');

export let variant: Variants;

function handleContextMenuClick(e: MouseEvent) {
    contextMenu.openMenuAtPosition(
        e.clientX,
        e.clientY,
        variant as ContextMenuStructure,
    )
}
</script>

{#if variant instanceof Icon}
    <ButtonComponent desc={new IconButton(variant.name, undefined, true)} />
{:else if variant instanceof IconButton}
    <ButtonComponent desc={variant} />
{:else if variant instanceof ContextMenuStructure}
    <ButtonComponent desc={new IconButton('Menu', handleContextMenuClick)} />
{:else if variant instanceof Checkbox}
    <CheckBoxComponent input={variant} />
{:else if typeof variant === 'string'}
    <span style="pointer-events: none">
        <ButtonComponent desc={new Button(variant, undefined, undefined, true)} />
    </span>
{/if}


<style lang="scss">
</style>
