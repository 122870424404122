<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.00001 2.2H2.20001V3V6.5H3.80001V4.93137L6.43433 7.56568L7.5657 6.43431L4.93138 3.8H6.50001V2.2H3.00001Z"
        fill="#E72C03"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0686 16.2L12.4343 13.5657L13.5657 12.4343L16.2 15.0686V13.5H17.8V17V17.8H17H13.5V16.2H15.0686Z"
        fill="#E72C03"
    />
    <circle cx="4" cy="10" r="1.25" fill="white" />
    <circle cx="4" cy="16" r="1.25" fill="white" />
    <circle cx="10" cy="10" r="1.25" fill="#E72C03" />
    <circle cx="10" cy="16" r="1.25" fill="white" />
    <circle cx="10" cy="4" r="1.25" fill="white" />
    <circle cx="16" cy="10" r="1.25" fill="white" />
    <circle cx="16" cy="4" r="1.25" fill="white" />
</svg>
