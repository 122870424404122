import type { IdBimScene } from 'bim-ts';
import type { EventStackFrame, VersionedValue } from 'engine-utils-ts';
import { LazyBasic, VersionedInvalidator } from 'engine-utils-ts';
import type { Aabb, Matrix4} from 'math-ts';
import { Vector3 } from 'math-ts';
import type { GesturesMousePos } from '../controls/MouseGesturesBase';
import type { KrCamera } from '../controls/MovementControls';
import type { SnappingSettings } from '../SnappingSettings';
import type { InObjFullId } from './EngineSceneIds';
import type { InteractiveObjectIntersection, RayIntersection } from './Raycasts';
import type { SceneInt } from './SceneRaycaster';


export interface InteractiveSceneObjects<Id, SceneIntersection extends InteractiveObjectIntersection> {

    type(): TransformsProviderType;

    repr_invalidator: VersionedValue;
    selectionInvalidator: VersionedValue;
	
    setHighlighted(ids: Id[]): void;
    getHighlighted(): Id[];
    anySelected(): boolean;
    getSelected(): Id[];
    getVisible(): Id[];
    setSelected(ids: Id[]): void;
    toggleSelected(isSelected: boolean, ids: Id[]): void;

    cloneObjects(ids: Id[], worldSpaceDirectionOfClone: Vector3): {newObjects: Id[], toUseForGesture: Id[]};
    deleteObjects(ids: Id[]): void;

	getChildrenOf(ids: Id[]): Id[];
    getParentsOf(ids: Id[]): Map<IdEngineObject, IdEngineObject>;

    getWorldMatricesOf(ids: Id[]): Map<Id, Matrix4>;
    patchWorldMatrices(patches: Map<IdEngineObject, Matrix4>, eventParams: Partial<EventStackFrame>): void;

    gatherIdsWithSubtreesOf(params: {ids: IdEngineObject[], sortParentFirst?: boolean}): IdEngineObject[];
    calcLocalOrientationFor(ids: Id[]): Matrix4;
    calcBboxOf(ids: Id[]): Aabb;
    calcOriginsCenterOf(ids: Id[]): Vector3;

    findIntersectingFrustum(frustumMatrix: Matrix4, near: number, far: number, strictInside: boolean): Id[];
    findPointToSnap(snapNearPosition: Vector3, skipIds: Set<IdEngineObject>, camera: KrCamera, settings: SnappingSettings): Vector3 | null;
	intersectRay(camera: KrCamera, mousePos: GesturesMousePos): SceneIntersection | null;
	getIdsFromIntersection(int: SceneInt): Id[];

}


export type IdEngineObject = IdBimScene | InObjFullId;

export enum TransformsProviderType {
    None,
    Bim,
    EditMeshes,
}

export class InteractiveObjectsActive implements InteractiveSceneObjects<IdEngineObject, InteractiveObjectIntersection> {

    _type: LazyBasic<TransformsProviderType>;

    allProviders: InteractiveSceneObjects<IdEngineObject, RayIntersection>[];

    _activeProvider: InteractiveSceneObjects<IdEngineObject, RayIntersection>;

    selectionInvalidator: VersionedValue;
    repr_invalidator: VersionedValue;

    constructor(
        allProviders: InteractiveSceneObjects<IdEngineObject, InteractiveObjectIntersection>[]
    ) {
        this._type = new LazyBasic('engine-objects-active-type', allProviders[0].type());
        this.allProviders = allProviders;
        this._activeProvider = allProviders[0];
        this.selectionInvalidator = new VersionedInvalidator(
            this.allProviders.map(p => p.selectionInvalidator).concat([this._type])
        );
        this.repr_invalidator = new VersionedInvalidator(
            this.allProviders.map(p => p.repr_invalidator).concat([this._type])
        );
    }

	type(): TransformsProviderType {
        return this._type.poll();
    }

    switchProvider(providerType: TransformsProviderType) {
        if (this.type() === providerType) {
            return;
        }
        const p = this.allProviders.find(p => p.type() === providerType);
        if (!p) {
            throw new Error('could not switch objects provider ' + providerType);
        }
        this._type.replaceWith(providerType);
        this._activeProvider = p;
    }

    getVisible(): IdEngineObject[] {
        return this._activeProvider.getVisible();
    }
    selectVisible(): void {
        this.setSelected(this.getVisible());
    }
    getHighlighted(): IdEngineObject[] {
        return this._activeProvider.getHighlighted();
    }
    setHighlighted(ids: IdEngineObject[]): void {
		for (const provider of this.allProviders) {
			if (provider === this._activeProvider) {
				provider.setHighlighted(ids);
			} else {
				provider.setHighlighted([]);
			}
		}
    }

    anySelected(): boolean {
        return this._activeProvider.anySelected();
    }
    getSelected(): IdEngineObject[] {
        return this._activeProvider.getSelected();
    }
    setSelected(ids: IdEngineObject[]): void {
        return this._activeProvider.setSelected(ids);
    }
    toggleSelected(isSelected: boolean, ids: IdEngineObject[]): void {
        return this._activeProvider.toggleSelected(isSelected, ids);
    }

    invertSelection() {
        const visible = this.getVisible();
        const selected = new Set(this.getSelected());
        const toSelect = visible.filter((id) => !selected.has(id));
        this.setSelected(toSelect);
    }

	
    getChildrenOf(ids: IdEngineObject[]): IdEngineObject[] {
        return this._activeProvider.getChildrenOf(ids);
    }
    gatherIdsWithSubtreesOf(params: {ids: IdEngineObject[], sortParentFirst?: boolean}): IdEngineObject[] {
        return this._activeProvider.gatherIdsWithSubtreesOf(params);
    }
    getParentsOf(ids: IdEngineObject[]): Map<IdEngineObject, IdEngineObject> {
        return this._activeProvider.getParentsOf(ids);
    }


    cloneObjects(ids: IdEngineObject[], worldSpaceDirectionOfClone: Vector3) {
        return this._activeProvider.cloneObjects(ids, worldSpaceDirectionOfClone);
    }
    deleteObjects(ids: IdEngineObject[]): void {
        this._activeProvider.deleteObjects(ids);
    }

    getWorldMatricesOf(ids: IdEngineObject[]): Map<IdEngineObject, Matrix4> {
        return this._activeProvider.getWorldMatricesOf(ids);
    }
    patchWorldMatrices(patches: Map<IdEngineObject, Matrix4>, eventParams: Partial<EventStackFrame>): void {
        return this._activeProvider.patchWorldMatrices(patches, eventParams);
    }
    calcLocalOrientationFor(ids: IdEngineObject[]): Matrix4 {
        return this._activeProvider.calcLocalOrientationFor(ids);
    }
    calcBboxOf(ids: IdEngineObject[]): Aabb {
        const aabb = this._activeProvider.calcBboxOf(ids);
        return aabb;
    }
    calcOriginsCenterOf(ids: IdEngineObject[]): Vector3 {
        return this._activeProvider.calcOriginsCenterOf(ids);
    }

    findIntersectingFrustum(frustumMatrix: Matrix4, near: number, far: number, strictInside: boolean): IdEngineObject[] {
        return this._activeProvider.findIntersectingFrustum(frustumMatrix, near, far, strictInside);
    }

    findPointToSnap(snapNearPosition: Vector3, skipIds: Set<IdEngineObject>, camera: KrCamera, settings: SnappingSettings): Vector3 | null {
        return this._activeProvider.findPointToSnap(snapNearPosition, skipIds, camera, settings);
    }

	intersectRay(camera: KrCamera, mousePos: GesturesMousePos): RayIntersection | null {
		return this._activeProvider.intersectRay(camera, mousePos);
	}

	getIdsFromIntersection(int: SceneInt): IdEngineObject[] {
		return this._activeProvider.getIdsFromIntersection(int);
	}

}

export function calcOriginsCenterFromMatrices(matrices: Iterable<Matrix4>): Vector3 {
	const sum = Vector3.zero();
	let count = 0;
	const reusedPosVec = Vector3.zero();
	for (const m of matrices) {
		count += 1;
		reusedPosVec.setFromMatrixColumn(m, 3);
		sum.add(reusedPosVec);
	}
	if (count > 0) {
		sum.divideScalar(count);
	}
	return sum;
}


