import type { RGBAHex} from 'engine-utils-ts';
import { RGBA } from 'engine-utils-ts';
import { Vector4 } from 'math-ts';
import type { Side, ShaderMaterial} from '../3rdParty/three';
import { KrMaterial } from '../materials/KrMaterial';
import type { ShaderBase } from './ShaderBase';


export function newBasicMaterial(rgba: RGBAHex, side?: Side): ShaderMaterial {
	const mat = new KrMaterial(BasicShader, 0, 0);
	RGBA.RGBAHexToVec4(rgba, mat.uniforms['colorOpacity'].value);
	mat.transparent = true;
	mat.depthWrite = false;
	mat.depthTest = false;
	if (side !== undefined) {
		mat.side = side;
	}
	return mat;
};

export const BasicShader: ShaderBase = {

	
	name: "basic",

	uniforms: {
		"colorOpacity": { value: new Vector4() },
	},

	vertexShader: `

	#include <common>
	#include <color_pars_vertex>
	
	void main() {
	
		gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
	
	}
	
	`,

	fragmentShader: `
	
	uniform vec4 colorOpacity;
	
	#include <common>
	#include <color_pars_fragment>
	
	void main() {
	
		gl_FragColor = colorOpacity;
	
		#include <premultiplied_alpha_fragment>
	
	}
	

	`

};