import { RGBA } from "engine-utils-ts";
import { KrMath } from "math-ts";
import type { Bim } from "../../Bim";
import { NumberProperty, ColorProperty } from "../../properties/PrimitiveProps";
import type { PropertyGroup } from "../../properties/PropertyGroup";
import { StateType } from "../ConfigsArchetypes";

export const TerrainAnalysisTypeIdent = "TerrainAnalysisConfig";

export const TerrainPaletteMaxRowCount = 10;

export interface TerrainPaletteSlice extends PropertyGroup {
    min: NumberProperty;
    max: NumberProperty;
    color: ColorProperty;
}

export interface TerrainPalette extends PropertyGroup {
    slices: TerrainPaletteSlice[];
}

export interface TerrainAnalysisConfig extends PropertyGroup {
    elevation_palette: TerrainPalette;
    slope_palette: TerrainPalette;
    cut_fill_palette: TerrainPalette;
}

export function generateTerrainConfigProps(args: {
    paletteSize: number,
    elevationRangeMeters: [min: number, max: number],
    cutfillRangeMeters: [min: number, max: number],
    slopeRangePercentagesExtents: number,
    defaultOffset: number,
}): TerrainAnalysisConfig {

    const paletteSize = KrMath.clamp(args.paletteSize, 1, TerrainPaletteMaxRowCount);

    const defaultHeightSlices: TerrainPaletteSlice[] = [];
    {
        const [minElevation, maxElevation] = args.elevationRangeMeters;
        const colors = [
            RGBA.newRGB(68 / 255, 1 / 255, 84 / 255), 
            RGBA.newRGB(1 / 255, 148 / 255, 143 / 255), 
            RGBA.newRGB(253 / 255, 231 / 255, 37 / 255)
        ];
        for (let i = 0; i < paletteSize; ++i) {
            const min =  KrMath.lerp(minElevation, maxElevation, i / paletteSize);
            const max =  KrMath.lerp(minElevation, maxElevation, (i + 1) / paletteSize);
            const color = RGBA.lerpFromArray(
                colors, (i * (colors.length - 1)) / (paletteSize - 1), RGBA.lerpColorOnly
            );

            defaultHeightSlices.push({
                min: NumberProperty.new({ value: min, unit: "m" }),
                max: NumberProperty.new({ value: max, unit: "m" }),
                color: ColorProperty.new({ value: color}),
            });
        }
    }

    const defaultSlopeSlices: TerrainPaletteSlice[] = [];
    {
        const [minSlope, maxSlope] = [
            -1 * Math.sqrt(args.slopeRangePercentagesExtents),
            Math.sqrt(args.slopeRangePercentagesExtents)
        ];
        const colors = [ RGBA.newRGB(0, 0.0, 0.9), RGBA.newRGB(0.4, 0.4, 0.4), RGBA.newRGB(0.0, 0.8, 0) ];

        for (let i = 0; i < paletteSize; ++i) {

            let min =  KrMath.lerp(minSlope, maxSlope, i / paletteSize);
            let max =  KrMath.lerp(minSlope, maxSlope, (i + 1) / paletteSize);
            min = KrMath.roundTo(Math.sign(min) * Math.pow(min, 2), 0.1);
            max = KrMath.roundTo(Math.sign(max) * Math.pow(max, 2), 0.1);

            const color = RGBA.lerpFromArray(colors, i * (colors.length - 1) / (paletteSize - 1), RGBA.betterlerp);

            defaultSlopeSlices.push({
                min: NumberProperty.new({ value: min, unit: "%" }),
                max: NumberProperty.new({ value: max, unit: "%" }),
                color: ColorProperty.new({ value: color }),
            });
        }
    }


    const defaultCutFillSlices: TerrainPaletteSlice[] = [];
    {
        const [minCutfill, maxCutfill] = args.cutfillRangeMeters;
        const midCutfill = 0;
        const lerpMidPoint = KrMath.clamp((midCutfill - minCutfill) / (maxCutfill - minCutfill), 0, 1);
        const midIndex = KrMath.roundTo((paletteSize - 1) * lerpMidPoint, 1);
        const slices:[number, number][] = [];
        for (let i = 0; i < paletteSize; ++i) {
            const min = lerpWithMidPoint(minCutfill, maxCutfill, midCutfill, lerpMidPoint, paletteSize, i, midIndex, args.defaultOffset);
            if(i === paletteSize - 1){
                slices.push([min, maxCutfill]);
            } else {
                const max = lerpWithMidPoint(minCutfill, maxCutfill, midCutfill, lerpMidPoint, paletteSize, i + 1, midIndex, args.defaultOffset);
                slices.push([min, max]);
            }
        }
        const colors = autoFillCutFillColors(slices, midCutfill);
        for (let i = 0; i < slices.length; i++) {
            const [min, max] = slices[i];
            defaultCutFillSlices.push({
                min: NumberProperty.new({ value: min, unit: "m" }),
                max: NumberProperty.new({ value: max, unit: "m" }),
                color: colors[i],
            });
        }
    }


    const properties: TerrainAnalysisConfig = {
        elevation_palette: {
            slices: defaultHeightSlices,
        },
        slope_palette: {
            slices: defaultSlopeSlices,
        },
        cut_fill_palette: {
            slices: defaultCutFillSlices
        }
    };

    return properties;
}

export function autoFillCutFillColors(slices: [number, number][], middlePos: number = 0): ColorProperty[]{
    const minColor = RGBA.newRGB(0.8, 0.0, 0.0);
    const maxColor = RGBA.newRGB(0.0, 0.0, 0.9);
    const midColor = RGBA.newRGB(1.0, 1.0, 1.0);
    let midIndex: number = 0;
    for (let i = 0; i < slices.length; i++) {
        const [min, max] = slices[i];
        if(min <= middlePos && max >= middlePos){
            midIndex = i;
            break;
        }
    }
    const colors: ColorProperty[] = [];
    const midPoint = slices.length > 2 ? midIndex / (slices.length - 1) : undefined;
    for (let i = 0; i < slices.length; i++) {
        const color = RGBA.lerpCustomMidPoint(
            minColor, 
            maxColor, 
            i  / (slices.length - 1), 
            midColor, 
            midPoint
        );
        colors.push(ColorProperty.new({value: color}));
    }

    return colors
}

function lerpWithMidPoint(from: number, to: number, mid: number, midT: number, paletteSize: number, idx: number, midIdx: number, offset: number): number {
    const t = idx / paletteSize;

    if (midT < 0 || midT > 1 || paletteSize < 3) {
        return KrMath.lerp(from, to, t);
    }
    if(idx === midIdx) {
        return mid - offset;
    }
    if(idx === midIdx + 1) {
        return mid + offset;
    }
    if (t < midT) {
        return KrMath.lerp(from, KrMath.clamp(mid, from, mid), t / midT);
    }
    if (t > midT) {
        return KrMath.lerp(KrMath.clamp(mid, mid, to), to, (t - midT) / (1 - midT));
    }
    return mid;
}

export function registerTerrainAnalysisConfig(bim: Bim) {
    bim.configs.archetypes.registerArchetype({
        type_identifier: TerrainAnalysisTypeIdent,
        properties: () => generateTerrainConfigProps({
            paletteSize: TerrainPaletteMaxRowCount - 1,
            elevationRangeMeters: [0, 50],
            slopeRangePercentagesExtents: 20,
            cutfillRangeMeters: [-10, 10],
            defaultOffset: bim.unitsMapper.isImperial() ? 0.003048 : 0.01,
        }),
        stateType: StateType.Singleton,
    });
}
