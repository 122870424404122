import type { Bim, IdBimScene} from "src";
import { NumberProperty, SceneObjDiff } from "src";
import type { LazyVersioned} from "engine-utils-ts";
import { DefaultMapObjectKey, IterUtils, LazyDerivedAsync, ObjectUtils, Yield, preferPreviousOverInProgressWrapper, replaceCurrencyUnitWithSymbol } from "engine-utils-ts";
import type { CostComponents, CostComponentsNonNullable, CostsConfigProvider, EstimateCost, InstanceCost, SingleEstimateProvider} from "src/cost-model/capital";
import { CostHierarchy, createEmptyCostComponents, createEmptyCostComponentsNonNullable, createMiscCategory, fillModelBasedCostCategory, fillModelBasedTopLevelCategory, multiplyCosts } from "src/cost-model/capital";
import { TrenchIdProps, TrenchKeyProps, TrenchTypeIdent } from "src/archetypes/Trench";

export function create_Electrical_Trenching(
    bim: Bim,
    provider: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>,
) {
    const groupedInstances = createGroupedInstances(bim);

    const result = LazyDerivedAsync.new4<
        CostHierarchy,
        TrenchGroup[],
        InstanceCost[],
        EstimateCost[],
        NumberProperty
    >(
        'trenching',
        [bim.unitsMapper],
        [groupedInstances, provider.lazyInstanceCostsByType(TrenchTypeIdent), provider.allEstimateCosts, totalDC],
        function* ([groupedInstances, costs, estimates, totalDC]) {
            const quantityUnit = bim.unitsMapper.mapToConfigured({ value: 0, unit: 'm3' }).unit!;
            const costUnit = bim.unitsMapper.mapToConfigured({ value: 0, unit: 'usd' }).unit!;
            const quantityUnitInMeters = NumberProperty.new({ value: 1, unit: quantityUnit }).as('m3');

            // extract final result
            const hierarchy = new CostHierarchy()

            for (const { idProps, totalVolumeMeters, trenchesIds } of groupedInstances) {
                let overrides = costs.find(x => ObjectUtils.areObjectsEqual(x.props, idProps))?.costs ?? createEmptyCostComponents();
                const defaults = multiplyCosts(createTrenchingDefaultCostsPerQubicMeter(), quantityUnitInMeters);

                const quantityInMeters = NumberProperty.new({ value: totalVolumeMeters, unit: 'm3' });
                const category = hierarchy.add({
                    description: { value: idProps.wiringType.asText() || 'unknown' },
                    costUnit: {
                        options: [replaceCurrencyUnitWithSymbol(costUnit) + '/' + quantityUnit ],
                        index: 0,
                    },
                    quantity: { value: quantityInMeters },
                    relatedSceneInstanceIds: new Set(trenchesIds),
                    matchesSceneInstance: (query) => {
                        if (query.si?.type_identifier !== TrenchTypeIdent) {
                            return false;
                        }
                        const sampleProps = query.si.properties.extractPropertiesGroup(TrenchIdProps, { valueUnitOnly: true })
                        const result = ObjectUtils.areObjectsEqual(idProps, sampleProps);
                        return result;
                    }
                })

                const updateCosts = (newCosts: CostComponents) => {
                    provider.findAndUpdateInstanceCost(
                        (prev) => (prev.costs = newCosts, prev),
                        { instance_type: TrenchTypeIdent, props: idProps }
                    )
                }
                fillModelBasedCostCategory(category[1], overrides, defaults, updateCosts, quantityInMeters.as(quantityUnit));
            }

            // add subroot
            {
                const category = hierarchy.addRoot({ description: { value: 'MV/LV Trenching' } });
                hierarchy.sumupChildren(category[0])
            }

            // create misc cable misc
            //{
            //    const costId = 'trenching-boring-misc';
            //    const overrides = estimates.find(x => x.id.value === costId);
            //    const state: SingleEstimateProvider = {
            //        id: costId,
            //        defaults: createEmptyCostComponentsNonNullable(),
            //        value: overrides,
            //        update: (fn) => provider.findAndUpdateEstimateCost(costId, fn),
            //    }
            //    hierarchy.add(createMiscCategory(state, bim.unitsMapper, totalDC, { name: 'Boring' }));
            //}

            // create misc cable misc
            {
                const costId = 'trenching-misc';
                const overrides = estimates.find(x => x.id.value === costId);
                const state: SingleEstimateProvider = {
                    id: costId,
                    defaults: createEmptyCostComponentsNonNullable(),
                    value: overrides,
                    update: (fn) => provider.findAndUpdateEstimateCost(costId, fn),
                }
                hierarchy.add(createMiscCategory(state, bim.unitsMapper, totalDC));
            }

            // create model root category
            {
                const root = hierarchy.addRoot({ description: { value: 'Trenching' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.materialCost = NumberProperty.new({ value: 0.052 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    hierarchy,
                    'trenching-benchmark',
                    estimates,
                    provider.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
            }


            return hierarchy;
        }

    )
    return result;
}


function createGroupedInstances(bim: Bim) {
    const instances = bim.instances.getLazyListOf({
        type_identifier: TrenchTypeIdent,
        relevantUpdateFlags: SceneObjDiff.NewProps | SceneObjDiff.LegacyProps
    })

    const result = preferPreviousOverInProgressWrapper(LazyDerivedAsync.new1(
        'trenching-grouping',
        [],
        [instances],
        function* ([instances]) {
            const groups = new DefaultMapObjectKey<
                typeof TrenchIdProps,
                {
                    totalVolumeMeters: number,
                    trenchesIds: IdBimScene[]
                }
            >({
                unique_hash: k => k.wiringType.asText(),
                valuesFactory: () => ({ totalVolumeMeters: 0, trenchesIds: [] })
            })
            for (const chunk of IterUtils.splitIterIntoChunks(instances, 10e3)) {
                yield Yield.Asap;
                const grouped = IterUtils.groupObjects(
                    chunk,
                    (o) => {
                        const props = o[1].properties.extractPropertiesGroup(TrenchIdProps);
                        return props.wiringType.asText() ?? 'unknown';
                    },
                    (l, r) => ObjectUtils.areObjectsEqual(
                        l[1].properties.extractPropertiesGroup(TrenchIdProps, { valueUnitOnly: true }),
                        r[1].properties.extractPropertiesGroup(TrenchIdProps, { valueUnitOnly: true })
                    ),
                )
                for (const members of grouped) {
                    const sample = members[0][1];
                    const idProps = sample.properties.extractPropertiesGroup(TrenchIdProps);
                    const group = groups.getOrCreate(Object.freeze(idProps));
                    for (const member of members) {
                        const keyProps = member[1].properties.extractPropertiesGroup(TrenchKeyProps);
                        group.totalVolumeMeters += keyProps.volume.as('m3');
                        group.trenchesIds.push(member[0]);
                    }
                }
            }

            const result: TrenchGroup[] = Array.from(groups.entries())
                .map(([k, v]) => ({ idProps: k, ...v }))
            return result;
        }
    ));
    return result;
}


interface TrenchGroup {
    idProps: typeof TrenchIdProps
    totalVolumeMeters: number
    trenchesIds: IdBimScene[]
}

export function createTrenchingDefaultCostsPerQubicMeter() {
    const costs: CostComponentsNonNullable = {
        ...createEmptyCostComponentsNonNullable(),
        materialCost: NumberProperty.new({ value: 70, unit: 'usd' }),
    }
    return costs;
}
