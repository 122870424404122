<script lang="ts">
    import {
        getPileTypeIcon,
        PileFeaturesFlags,
        unpackPileFeatures,
    } from "bim-ts";

    export let pileFeatures: PileFeaturesFlags;
    export let shortName: string;
    export let longName: string | undefined = undefined;

    $: flags = unpackPileFeatures(pileFeatures);
</script>

<span class="root" title={longName ?? shortName}>
    <span class="icon-holder">
        {@html getPileTypeIcon(
            flags.weight_class,
            flags.motor,
            flags.damper,
            flags.modifier,
            "#0F1314B2",
        )}
    </span>
    <span class="short-name">{shortName}</span>
    {#if longName}
        <span class="long-name">{longName}</span>
    {/if}
</span>

<style lang="scss">
    .root {
        display: flex;
        align-items: center;
        gap: 4px;
        width: 100%;
        justify-content: left;
    }

    .icon-holder {
        width: 24px;
        height: 24px;
    }

    .short-name {
        min-width: 34px;
        max-width: 34px;
    }
    .long-name {
        max-width: fit-content;
    }
</style>
