<svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g clip-path="url(#clip0_10_61)">
        <path
            d="M7.99999 15.0239C7.8338 15.0239 7.67092 14.9903 7.51134 14.923C7.35177 14.8557 7.20596 14.7603 7.07389 14.637L1.36302 8.92609C1.23965 8.79402 1.14431 8.6482 1.07702 8.48862C1.00972 8.32906 0.976074 8.16618 0.976074 7.99999C0.976074 7.8338 1.00924 7.66905 1.07557 7.50574C1.14192 7.34242 1.23774 7.19847 1.36302 7.07389L7.07389 1.36302C7.20596 1.22844 7.35177 1.1303 7.51134 1.06861C7.67092 1.00692 7.8338 0.976074 7.99999 0.976074C8.16618 0.976074 8.33093 1.0074 8.49424 1.07004C8.65756 1.13269 8.80151 1.23035 8.92609 1.36302L14.637 7.07389C14.7696 7.19847 14.8673 7.34242 14.9299 7.50574C14.9926 7.66905 15.0239 7.8338 15.0239 7.99999C15.0239 8.16618 14.9931 8.32906 14.9314 8.48862C14.8697 8.6482 14.7715 8.79402 14.637 8.92609L8.92609 14.637C8.80151 14.7622 8.65756 14.8581 8.49424 14.9244C8.33093 14.9907 8.16618 15.0239 7.99999 15.0239ZM7.39636 8.72317H8.60362V4.72317H7.39636V8.72317ZM7.99999 10.7638C8.16708 10.7638 8.30946 10.7049 8.42712 10.5873C8.54479 10.4696 8.60362 10.3288 8.60362 10.1648C8.60362 10.0008 8.54479 9.85838 8.42712 9.73764C8.30946 9.61688 8.16708 9.55651 7.99999 9.55651C7.8329 9.55651 7.69052 9.61688 7.57286 9.73764C7.45519 9.85838 7.39636 10.0008 7.39636 10.1648C7.39636 10.3288 7.45519 10.4696 7.57286 10.5873C7.69052 10.7049 7.8329 10.7638 7.99999 10.7638Z"
            fill="#F39912"
        />
    </g>
    <defs>
        <clipPath id="clip0_10_61">
            <rect width="16" height="16" fill="white" />
        </clipPath>
    </defs>
</svg>
