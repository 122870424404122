import type { ColDef } from 'ag-grid-enterprise';
import { NumberProperty, type UnitsMapper } from 'bim-ts';
import {convertUnits, Success, type RGBAHex, currenciesCustomSymbols} from 'engine-utils-ts';
import { KrMath } from 'math-ts';
import { ActionRenderer } from './ActionRender';

import { ColorCellEditor, ColorCellRender } from './ColorCellRender';
import { PUI_PropertyNodeNumber, PUI_PropertyNodeColor, PUI_PropertyNodeString, PUI_ActionsNode, type PUI_Node } from 'ui-bindings';
import { NumberEditor } from './NumberEditor';

export class NumberAndConfig {
    constructor(
        public property: NumberProperty,
        public config: PUI_PropertyNodeNumber
    ) {}
}

export type CellValueType = number | string | ActionDescription | NumberProperty | NumberAndConfig

export interface TableRowType {
    [key:string]: CellValueType
}


export interface ActionDescription {
    name: string;
    action: () => void;
}

export function updateProperty(
    cell:PUI_Node,
    newValue: CellValueType,
    unitMapper: UnitsMapper
) {
    if(cell instanceof PUI_PropertyNodeNumber){
        if (typeof newValue === 'number') {
            let value = newValue;
            const mappedValue = unitMapper.mapToConfigured({
                value: cell.value!,
                unit: cell.unit
            });
            if(mappedValue.unit && cell.unit){
                const result = convertUnits(newValue, mappedValue.unit, cell.unit);
                value = result instanceof Success? result.value: value;
            }
            cell.value = value;
        } else if (newValue instanceof NumberAndConfig) {
            cell.setValueUnit(newValue.property.value, newValue.property.unit);
        }
    } else if(cell instanceof PUI_PropertyNodeColor || cell instanceof PUI_PropertyNodeString) {
        cell.value = newValue as string;
    } else if(cell instanceof PUI_ActionsNode){
        // readonly
    } else {
        console.error('Not implemented yet:', typeof cell);
    }
}

export function addRowValue(
    cell:PUI_Node,
    unitsMapper: UnitsMapper,
    callBack:(
        name: string,
        v: number | string | RGBAHex | ActionDescription | NumberProperty | NumberAndConfig
    ) => void
) {
    if(cell instanceof PUI_PropertyNodeNumber){
        const {value, unit} = unitsMapper.mapToConfigured({value: cell.value!, unit: cell.unit});
        callBack(
            cell.name,
            new NumberAndConfig(
                NumberProperty.new({
                    value,
                    unit: unit ?? '',
                    isReadonly: cell.readonly,
                }),
                cell,
            )
        )
    } else if(cell instanceof PUI_PropertyNodeColor || cell instanceof PUI_PropertyNodeString) {
        callBack(cell.name, cell.value!);
    } else if(cell instanceof PUI_ActionsNode){
        callBack(cell.name, {name: cell.actions[0]?.label ?? "", action: ()=>{
            for (const action of cell.actions) {
                action.action(cell.context);
            }
        }});
    } else {
        console.error('Not implemented yet:', JSON.stringify(cell));
    }
}

export function addColumn(
    cell: PUI_Node,
    unitsMapper: UnitsMapper,
    callBack: (c: ColDef) => void
) {
    if (cell instanceof PUI_PropertyNodeNumber) {
        // const { value, unit } = unitsMapper.mapToConfigured({
        //     value: cell.value!,
        //     unit: cell.unit,
        // });
        callBack({
            //headerName: unit ? `${cell.name}, ${unit}` : cell.name,
            headerName: cell.name,
            field: cell.name,
            flex: 1,
            editable: (params) => {
                const cell = params.data[params.colDef.field ?? '']
                if (cell instanceof NumberAndConfig) {
                    return !cell.property.isReadonly
                }
                return false;
            },
            cellEditorPopup: false,
            singleClickEdit: true,
            valueFormatter: (params) => {
                const value: NumberAndConfig = params.data[cell.name];
                if (value === undefined || !(value instanceof NumberAndConfig)) {
                    return "";
                }
                const prop = NumberProperty.new({
                    unit: value.property.unit,
                    value: value.property.value,
                });
                if (isNaN(prop.value)) {
                    return " ";
                }
                const symbol = currenciesCustomSymbols[prop.unit];
                if (symbol) {
                    const beforePoint = prop.value.toLocaleString().split('.')[0];
                    const afterPoint = prop.value.toFixed(4).split('.')[1];
                    return symbol + ' ' + beforePoint + '.' + afterPoint
                }
                return prop.valueUnitUiStringPrecise(unitsMapper);
            },
            cellEditor: NumberEditor,
            cellEditorParams: {
                unitsMapper,
            },
            cellStyle: ({ value }) => {
                const styles = {
                    background: 'inherit',
                }
                if (!(value instanceof NumberAndConfig)) {
                    return styles;
                }
                if (value.config.valueRenderFormatter) {
                    styles.background = value.config.valueRenderFormatter(null)
                }
                return styles;
            }
        });
    } else if (cell instanceof PUI_PropertyNodeColor) {
        callBack({
            headerName: `${cell.name}`,
            field: cell.name,
            editable: !cell.readonly,
            cellEditor: ColorCellEditor,
            cellRenderer: ColorCellRender,
            cellEditorPopup: false,
            singleClickEdit: true,
            resizable: false,
            width: 52,
        });
    } else if (cell instanceof PUI_PropertyNodeString) {
        const params = cell.name === 'id' ? {
            resizable: false,
            suppressMenu: true,
            width: 42,
        } : {
            flex: 1
        };
        callBack({
            headerName: `${cell.name}`,
            field: cell.name,
            editable: !cell.readonly,
            ...params,
        });
    } else if (cell instanceof PUI_ActionsNode) {
        callBack({
            headerName: `${cell.name}`,
            field: cell.name,
            flex: 1,
            cellRenderer: ActionRenderer,
            editable: false,
        });
    } else {
        console.error("Not implemented yet:", JSON.stringify(cell));
    }
}

export function autoRange(
    newvalue: number,
    position: number,
    curState: number[]
): [number, number][] {
    let min = curState[0];
    let max = curState[curState.length - 1];
    const range = Math.abs((max-min)/(curState.length-1));
    min = min > newvalue ? newvalue - range : min;
    max = max < newvalue ? newvalue + range : max;

    const newState = curState.slice();
    const steps = newState.length - (position + 1);
    for (let i = 0; i < newState.length; i++) {
        if (i > position) {
            if(curState[position+1] === undefined || curState[position+1] > newvalue){
                continue;
            }
            newState[i] = KrMath.lerp(newvalue, max, (i - position) / steps);
        } else if (i < position) {
            if(curState[position-1] === undefined || curState[position-1] < newvalue){
                continue;
            }
            newState[i] = KrMath.lerp(min, newvalue, i / position);
        } else {
            newState[position] = newvalue;
        }
    }

    const slices: [number, number][] = [];
    for (let i = 0; i < newState.length - 1; i++) {
        slices.push([newState[i], newState[i + 1]]);
    }

    return slices;
}

function roundNumber(value:number, step: number){
    const digits = getDigitsFromNumber(step);
    const multiplier = Math.pow(10, digits);
    const roundedValue = Math.round(value * multiplier) / multiplier;
    return roundedValue;
}

function getDigitsFromNumber(step: number) {
    return `${1 / step}`.length - 1;
}
