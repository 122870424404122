import { ErrorUtils, IterUtils } from "engine-utils-ts";
import type { PropertyGroup } from "../properties/PropertyGroup";
import { PropertyBase } from "../properties/Props";


export class AllArrayItems {
    private constructor() {}
    
    static new() {
        return new AllArrayItems();
    }

    toString() {
        return '[*]';
    }
}

export type PropertyPathType = string | number | AllArrayItems;

export class ConfigUtils {
    static traverseByProperties(
        source: PropertyGroup,
        callBack: (prop: PropertyBase, path: (string | number)[], source: PropertyGroup | PropertyGroup[] | PropertyBase[]) => void,
        skipPaths: PropertyPathType[][] = []
    ): void {
        traverseByPropertyGroup(
            source,
            (prop, path, source) => {
                if (prop instanceof PropertyBase) {
                    callBack(prop, path, source);
                }
            },
            skipPaths
        );
    }

    static traverseByPropertyGroup(
        source: PropertyGroup, 
        callback: (
            prop: PropertyBase | PropertyBase[] | PropertyGroup | PropertyGroup[] | null, 
            path: (string | number)[], 
            currSourceRef: PropertyGroup | PropertyGroup[] | PropertyBase[]
        ) => void,
        skipPaths: PropertyPathType[][] = [],
    ): void {
        traverseByPropertyGroup(
            source,
            (prop, path, source) => {
                callback(prop, path, source);
            },
            skipPaths
        );
    }

    static extractProperties(
        source: PropertyGroup,
        path: PropertyPathType[]
    ): (PropertyBase | PropertyGroup)[] {
        const result: (PropertyBase | PropertyGroup)[] = [];
        traverseByPropertyGroup(
            source,
            (prop, currentPath) => {
                if (areEqualSomePath([path], currentPath)) {
                    if(prop === null){
                        //skip
                    } else if (Array.isArray(prop)) {
                        IterUtils.extendArray(result, prop);
                    } else {
                        result.push(prop);
                    }
                }
            },
            []
        );
        return result;
    }

    static areEqualSomePath(
        skipPaths: PropertyPathType[][],
        currentPath: (string | number)[]
    ): boolean {
        return areEqualSomePath(skipPaths, currentPath);
    }

    static copyTo<TSrc extends T, T extends PropertyGroup>(source: Partial<TSrc>, target?: T): T {
        const cloned: PropertyGroup = target ?? {};
        for (const fieldName in source) {
            const currField = source[fieldName];
            if (currField instanceof PropertyBase || currField === null) {
                if(cloned[fieldName] !== undefined){ 
                    ErrorUtils.logThrow(`unexpected field [${fieldName}]`, [cloned[fieldName], currField]);
                }
                cloned[fieldName] = currField;
                continue;
            } else if (Array.isArray(currField) && currField[0] instanceof PropertyBase) {
                if(cloned[fieldName] !== undefined){ 
                    ErrorUtils.logThrow(`unexpected field [${fieldName}]`, [cloned[fieldName], currField]);
                }
                const clonedArray: PropertyBase[] = [];
                for (const arrayItemKey in currField) {
                    const arrayItem = currField[arrayItemKey];
                    if (arrayItem instanceof PropertyBase) {
                        clonedArray.push(arrayItem);
                    } else {
                        ErrorUtils.logThrow("unexpected type", arrayItem);
                    }
                }
                cloned[fieldName] = clonedArray;
            } else if (Array.isArray(currField)) {
                if(cloned[fieldName] !== undefined){ 
                    ErrorUtils.logThrow(`unexpected field [${fieldName}]`, [cloned[fieldName], currField]);
                }
                const clonedArray: PropertyGroup[] = [];
                for (const arrayItemKey in currField) {
                    const arrayItem = currField[arrayItemKey];
                    if (!(arrayItem instanceof PropertyBase)) {
                        clonedArray.push(ConfigUtils.copyTo(arrayItem));
                    } else {
                        ErrorUtils.logThrow("unexpected type", arrayItem);
                    }
                }
                cloned[fieldName] = clonedArray;
            } else {
                const clonedField = cloned[fieldName];
                if(Array.isArray(clonedField) || clonedField instanceof PropertyBase || clonedField === null){
                    ErrorUtils.logThrow(`type doesn't match in source and target objects`, [cloned[fieldName], currField]);
                }
                if(currField !== undefined){ 
                    cloned[fieldName] = ConfigUtils.copyTo(currField, clonedField);
                }
            }
        }

        return cloned as T;
    }
}

function traverseByPropertyGroup(
    source: PropertyGroup, 
    callback: (
        prop: PropertyBase | PropertyBase[] | PropertyGroup | PropertyGroup[] | null, 
        path: (string | number)[], 
        currSourceRef: PropertyGroup | PropertyGroup[] | PropertyBase[]
    ) => void,
    skipPaths: PropertyPathType[][] = [],
    path: (string | number)[] = [],
) {
    callback(source, path, source);
    for (const key in source) {
        const currentPath = path.slice();
        currentPath.push(key);
        if(areEqualSomePath(skipPaths, currentPath)){
            continue;
        }
        const prop = source[key];
        if (prop instanceof PropertyBase || prop === null) {
            callback(prop, currentPath, source);
        } else if (Array.isArray(prop) && prop[0] instanceof PropertyBase) {
            callback(prop, currentPath, source);
            for (const p of prop) {
                if(p instanceof PropertyBase){
                    callback(p, currentPath, prop);
                } else {
                    console.error('unexpected type', p);
                }
            }
        } else if (Array.isArray(prop)) {
            callback(prop, currentPath, prop);
            for (let i = 0; i < prop.length; i++) {
                const group = prop[i];
                const newPath = currentPath.slice();
                newPath.push(i);
                traverseByPropertyGroup(group as any, callback, skipPaths, newPath);
            }
        } else {
            traverseByPropertyGroup(prop, callback, skipPaths, currentPath.slice());
        }
    }
}

function areEqualSomePath(skipPaths: PropertyPathType[][], currentPath: (string | number)[]):boolean{
    for (const skipPath of skipPaths) {
        if(skipPath.length !== currentPath.length){
            continue;
        }
        let areEqualArray = true;
        for (let i = 0; i < skipPath.length; i++) {
            const p1 = skipPath[i];
            const p2 = currentPath[i];
            if(p1 instanceof AllArrayItems && typeof p2 === 'number'){
                continue;
            }
            if(p1 !== p2){
                areEqualArray = false;
                break;
            }
        }
        if(areEqualArray){
            return true;
        }
    }
    return false;
}
