import { type Observer, type ObservableStream, type Result, Success } from "engine-utils-ts";
import type { NotificationDescription, NotificationType, NotificationActionDescription, NotificationTaskDescription } from "ui-bindings";
import type { NotificationsLog, NotificationsLogItem } from './NotificationsLogStore';

export class NotificationsHandler {

    notificationsContext: SvelteNotificationsContext;
    streamObserver: Observer | null = null;

    _isDisposed = false;

    _notificationsSortedByRemovalTime: [number, NotificationDescription][] = [];

    logContext?: NotificationsLog;


    constructor(
        notificationsContext: SvelteNotificationsContext,
        logContext?: NotificationsLog
    ) {
        this.notificationsContext = notificationsContext;
        this.logContext = logContext;
    }

    checkNotifications(time: number) {

    }

    updateStream(stream: ObservableStream<NotificationDescription>) {
        this.streamObserver?.dispose();
        this.streamObserver = stream.subscribe({
            onNext: (descr) => {
                this.onNotification(descr);
                if (this.logContext && descr.addToNotificationsLog) {
                    this.logContext.addNotification(descr);
                }
            }
        });
    }

    onNotification(description: NotificationDescription) {
        const svelteNotif: DefaultNotificationOptions = {
            header: description.header,
            message: description.message,
            id: description.uniqueIdent,
            position: 'bottom-center',
            type: description.type,
            actionDescription: description.actionDescription,
            taskDescription: description.taskDescription,
            onClose: description.onClose,
        };
        const removeNotificationFn = () => this.notificationsContext.removeNotification(description.uniqueIdent);
        if (description.taskDescription) {
            description.taskDescription.task.asPromise().finally(
                async () => {
                    const result = description.taskDescription!.task.asFinalizedResult();
                    const delay = result instanceof Success ? description.removeAfterMs * 0.5 : description.removeAfterMs * 2;
                    setTimeout(removeNotificationFn, delay);
                });
        } else if (description.removeAfter) {
            setTimeout(removeNotificationFn, description.removeAfterMs);
        }
        this.notificationsContext.addNotification(svelteNotif);
    }

    dispose() {
        this._isDisposed = true;
        this.streamObserver?.dispose();
    }

}


export interface DefaultNotificationOptions {
    id?: string;
    header: string;
    message?: string;
    position: 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';
    type: NotificationType;
    actionDescription?: NotificationActionDescription<any>;
    taskDescription?: NotificationTaskDescription;
    onClose?: () => void;
}

interface SvelteNotificationsContext {
    addNotification: (notification: DefaultNotificationOptions | Record<string, any>) => void;
    removeNotification: (notificationId: string) => void;
    clearNotifications: () => void;
}

export type NotificationItem = DefaultNotificationOptions & NotificationsLogItem

export function setHeaderFromTaskResult(notification: NotificationItem, result: Result<any>) {
    const headerGetter = notification.taskDescription?.resultHeaderGetter;
    if (headerGetter) {
        try {
            return headerGetter(result);
        } catch (e) {
            console.error(`error inside headerGetter for task`, e);
            return '';
        }
    } else {
        return `${notification.header} ${result instanceof Success ? 'done' : 'failed'}`
    };
}
