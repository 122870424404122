import { InverterTypeIdent, type Bim, type IdBimScene, TransformerIdent } from "..";

export function getValueOrThrow<T extends object>(value: T | undefined | null): T {
    if (!value) {
        throw new Error(`value not provided(${value})`);
    }
    return value;
}

export function isTrackerConnected(id: IdBimScene, bim: Bim) {
    let connected: boolean = false;
    let hasParentInverter: boolean = false;

    bim.instances.spatialHierarchy.traverseLeaveToRoot(id, (parentId) => {
        const type_ident = bim.instances.peekTypeIdentOf(parentId);
        if (type_ident === InverterTypeIdent) {
            hasParentInverter = true;
        } else if (type_ident === TransformerIdent) {
            connected = hasParentInverter;
            return false;
        }
        return true;
    }, true);

    return connected;
}
