<script lang="ts">
    import { getContext, onDestroy } from 'svelte';
    import type { TextButtonSettings } from "../../custom-components/SelectArea/SelectedArea";
    import { getPluralDisplayName, SelectObjectsStore } from '../SelectObjectsUtils';
    import TextButtonsGroup from "./TextButtonsGroup.svelte";
    import { ContextMenuConfig, PUI_Builder, PUI_Lazy, SceneInstancesSelectorValue, UiBindings } from 'ui-bindings';
    import { Vector2 } from 'math-ts';
    import { LazyDerived } from 'engine-utils-ts';

    export let store: SelectObjectsStore;
    export let additionalActions: TextButtonSettings[] = [];

    $: displayName = getPluralDisplayName(store.types);

    const uiBindings: UiBindings = getContext("uiBindings");

    let buttons: TextButtonSettings[] = [];
    let contextMenuConfig: ContextMenuConfig | null = null;

    $: if (displayName) {
        const commonAction: TextButtonSettings = {
            label: `Select ${displayName} from list`,
            iconBefore: "MultipleMain",
            action: (pos: Vector2) => {
                const config = createSelectObjectsContextMenuUi(pos);
                contextMenuConfig = config;
                uiBindings.addContextMenuView(contextMenuConfig);
            },
        };

        buttons = [commonAction, ...additionalActions];
    }

    function createSelectObjectsContextMenuUi(pos: Vector2): ContextMenuConfig {
        closeContextMenu();
        const puiRootLazy = LazyDerived.new1(
            "select-bim-objects-ui",
            null,
            [store.selected],
            ([listInput]) => {
                const builder = new PUI_Builder({});

                builder.addCustomProp<
                    SceneInstancesSelectorValue[],
                    {
                        store: SelectObjectsStore
                    }
                >({
                    name: "select-scene-objs",
                    type_ident: "select-scene-objs",
                    value: listInput.slice(),
                    context: {
                        store,
                    },
                    onChange: (items) => {
                        store.updateSelected(items);
                    },
                });
                return builder.finish();
            }
        ).withoutEqCheck();

        const config = new ContextMenuConfig({
            identity: `select-${store.types.join(", ")}-from-list`,
            viewSource: new PUI_Lazy(puiRootLazy),
            action: { name: "Ok" },
            positionPx: pos,
            header: `Select ${displayName}`,
            maxHeightPx: 400,
            widthPx: store.menuWidth,
        });
        return config;
    }

    function closeContextMenu() {
        contextMenuConfig?.close();
        contextMenuConfig = null;
    }

    onDestroy(() => {
        closeContextMenu();
    });
</script>

<div class="ui-config-property property-row multiselect-prop">
    <div class="property-name text-main-medium" />

    <div class="property-value">
        <div class="mdc-typography--body1 actions-property">
            <TextButtonsGroup {buttons} />
        </div>
    </div>
</div>

<style lang="scss">
</style>
