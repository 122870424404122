<script lang="ts">
import panzoom, { PanZoom } from 'panzoom';
import { createEventDispatcher, onDestroy, onMount } from 'svelte';

type Events = {
    'close': {}
}

const dispatch = createEventDispatcher<Events>()

let contentEl: HTMLSpanElement;
let containerEl: HTMLDivElement;
let panZoomInstance: PanZoom;

function keyup(e: KeyboardEvent) {
    if (e.code === 'Escape' || e.key === 'Escape') {
        dispatch('close', {})
        e.stopPropagation();
    }
}

onMount(() => {
    document.body.appendChild(containerEl)
    panZoomInstance = panzoom(contentEl, {
        maxZoom: 20,
        minZoom: 1,
        zoomSpeed: 0.1,
        autocenter: true,
        smoothScroll: false,
        //bounds: true,
        //boundsPadding: 0.05,
    })
    document.addEventListener('keyup', keyup)
})

onDestroy(() => {
    panZoomInstance.dispose();
    document.removeEventListener('keyup', keyup)
    document.body.removeChild(containerEl)
})

</script>

<div
    on:click={() => dispatch('close', {})}
    class="fullscreen-container"
    bind:this={containerEl}
>
    <span
        on:click|stopPropagation
        bind:this={contentEl}
        class="content-holder"
    >
        <slot></slot>
    </span>
</div>

<style lang="scss">
.fullscreen-container {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10000;
}
.content-holder {
    display: inline-block;
}
</style>
