<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M8.01124 14.2355C9.73671 14.2355 11.2076 13.6277 12.4239 12.4119C13.6403 11.1962 14.2441 9.72001 14.2354 7.98333H12.9246C12.9173 9.34899 12.4371 10.5124 11.484 11.4735C10.5309 12.4346 9.37268 12.9152 8.00934 12.9152C6.63542 12.9152 5.47274 12.4385 4.52129 11.485C3.56985 10.5315 3.09412 9.37038 3.09412 8.00158C3.09412 6.63279 3.56985 5.47113 4.52129 4.5166C5.47274 3.56205 6.63542 3.08478 8.00934 3.08478C8.7167 3.08478 9.38556 3.23382 10.0159 3.53188C10.6463 3.82995 11.2021 4.23961 11.6832 4.76087H10.1478V5.88333H13.8123V2.23767H12.6992V3.90072C12.0915 3.22438 11.3824 2.69769 10.5719 2.32063C9.76144 1.94359 8.90859 1.75507 8.01334 1.75507C7.14474 1.75507 6.33115 1.91968 5.57258 2.2489C4.814 2.57813 4.15351 3.02455 3.59112 3.58817C3.02875 4.15178 2.5837 4.81199 2.25599 5.5688C1.92827 6.32562 1.76441 7.13663 1.76441 8.00182C1.76441 8.86699 1.92902 9.67719 2.25824 10.4324C2.58748 11.1876 3.03253 11.8472 3.59339 12.4112C4.15427 12.9752 4.81453 13.4203 5.57418 13.7464C6.33383 14.0725 7.14619 14.2355 8.01124 14.2355Z"
        fill="currentColor"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.20001 4.5H8.80001V7.66863L10.5657 9.43431L9.43433 10.5657L7.20001 8.33137V4.5Z"
        fill="currentColor"
    />
</svg>
