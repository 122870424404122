import { ProjectNetworkClient } from "engine-utils-ts";
import { deserializeCostReport, migrateCostReportTemplate, serializeCostReport, type CostReportTemplate } from "./CostReportTemplate";

export interface CostReportTemplateGetReply {
    global: CostReportTemplate[];
    company: CostReportTemplate[];
}

export class CostReportTemplatesStorageApi {
    network: ProjectNetworkClient;
    constructor(network: ProjectNetworkClient) {
        this.network = new ProjectNetworkClient({
            ...network.config,
            basePath: 'api/cost-report-templates'
        });
    }
    async saveTemplate(template: CostReportTemplate) {
        const formData = new FormData();
        formData.append(
            "file",
            new Blob([serializeCostReport(template)]),
            this.getFileName(template)
        );
        await this.network.postFormData('', formData);
    }

    async getAllTemplates() {
        const response = await this.network.get('');
        const text: string = await response.text();
        const json: CostReportTemplateGetReply = deserializeCostReport(text);
        json.company.forEach(t => migrateCostReportTemplate(t));
        json.global.forEach(t => migrateCostReportTemplate(t));
        return json; 
    }

    async deleteTemplate(template: CostReportTemplate) {
        await this.network.delete('file/' + this.getFileName(template));
    }

    getFileName(template: CostReportTemplate){
        return `${template.id}.json`;
    }

}