<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM6 14C6 13.4477 6.44772 13 7 13H8C8.55229 13 9 13.4477 9 14C9 14.5523 8.55228 15 8 15H7C6.44772 15 6 14.5523 6 14ZM7 9C6.44772 9 6 9.44771 6 10C6 10.5523 6.44771 11 7 11H17C17.5523 11 18 10.5523 18 10C18 9.44771 17.5523 9 17 9H7ZM15 14C15 13.4477 15.4477 13 16 13H17C17.5523 13 18 13.4477 18 14C18 14.5523 17.5523 15 17 15H16C15.4477 15 15 14.5523 15 14ZM11.5 13C10.9477 13 10.5 13.4477 10.5 14C10.5 14.5523 10.9477 15 11.5 15H12.5C13.0523 15 13.5 14.5523 13.5 14C13.5 13.4477 13.0523 13 12.5 13H11.5Z"
        fill="currentColor"
    />
</svg>
