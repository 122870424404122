import type {
	EntitiesBase, EntitiesCollectionUpdates, IdBimGeo} from 'bim-ts';
import { handleEntitiesUpdates,
	PolyEntitiesBase,
} from 'bim-ts';
import type { ScopedLogger} from 'engine-utils-ts';
import { StreamAccumulator } from 'engine-utils-ts';
import type { EntityId} from 'verdata-ts';
import { entityTypeFromId } from 'verdata-ts';

import { EntitiesBimSynced } from '../resources/EntitiesBimSynced';
import {
	EntitiesBimSyncedInterned,
} from '../resources/EntitiesBimSyncedInterned';
import type { EngineAnalytPolylinesGeosSynced } from './EngineGeoAnalytPolyline';
import type { EngineCubeGeosSynced } from './EngineGeoCube';
import type { EngineExtrPolygonGeosSynced } from './EngineGeoExtrudedPolygon';
import type { EngineGeometry } from './EngineGeometry';
import type { EnginePlaneGeometries } from './EngineGeoPlane';
import type { EnginePolylinesGeosSynced } from './EngineGeoPolyline';
import type { EngineSpriteGeometries } from './EngineGeoSprite';
import type { EngineTriGeosSynced } from './EngineGeoTriangle';
import type { GpuGeometries, GpuUploadUrgency } from './GpuGeometries';
import type { EngineTerrainGeosIrregSynced } from './EngineGeoTerrainIrregular';
import type { GCedEntitiesCollection, EngineResourcesGC } from '../scene/EngineResourcesGC';
import type { EngineTerrainGeosRegSynced } from './EngineGeoTerrainRegular';
import type { EngineTextBlockGeometries } from './EngineGeoTextBlock';
import type { EngineTrackerPileBillboardGeometries } from './EngineGeoTrackerPileBillboard';

// reserve ids types that are used by bim geo-types already,
// this allows using bim geometires ids directly as engine ids
// and remapping into another free ranges what else is necessary 
// this way we can avoid having to to have totally different set of ids on engine side and bim side
// and remapping them back and forth through hashmaps
export enum EngineGeoType {
    Triangle = 0,
    Polyline = 1,
    Cube = 2,
    ExtrudedPolygon = 3,

    AnalytPolyline = 11,


    TerrainRegular = 16,
  	TerrainIrregular = 17,

	TextBlock = 20,

    PlaneGeometry = 50,
    SpriteGeometry = 51,
	TrackerPileBillboardGeometry = 52,
};


export type IdEngineGeo = EntityId<EngineGeoType>;


export class AllEngineGeometries
	extends PolyEntitiesBase<EngineGeometry, EngineGeoType>
	implements GCedEntitiesCollection<EngineGeoType>
{
    logger: ScopedLogger;
    gpuGeometries: GpuGeometries;

    _updatesAccumulator: StreamAccumulator<EntitiesCollectionUpdates<IdEngineGeo, number>>;
    
	readonly triGeometries: EngineTriGeosSynced;
	readonly polylineGeometries: EnginePolylinesGeosSynced;
	readonly analytPolylines: EngineAnalytPolylinesGeosSynced;
	readonly cubeGeometries: EngineCubeGeosSynced;
	readonly extrudedPolygonGeometries: EngineExtrPolygonGeosSynced;
	readonly planeGeometries: EnginePlaneGeometries;
	readonly spriteGeometries: EngineSpriteGeometries;
	readonly trackerPileBillboardGeometries : EngineTrackerPileBillboardGeometries;
	readonly terrainIrregular: EngineTerrainGeosIrregSynced;
	readonly terrainRegular: EngineTerrainGeosRegSynced;
	readonly textAnnotations: EngineTextBlockGeometries;

    constructor(params: {
        logger: ScopedLogger,
        gpuGeos: GpuGeometries,
		gc: EngineResourcesGC,
		subtypes: {
			triGeometries: EngineTriGeosSynced;
			polylineGeometries: EnginePolylinesGeosSynced;
			analytPolylines: EngineAnalytPolylinesGeosSynced;
			cubeGeometries: EngineCubeGeosSynced;
			extrudedPolygonGeometries: EngineExtrPolygonGeosSynced;
			planeGeometries: EnginePlaneGeometries;
			spriteGeometries: EngineSpriteGeometries;
			trackerPileBillboardGeometries: EngineTrackerPileBillboardGeometries;
			terrainIrregular: EngineTerrainGeosIrregSynced;
			terrainRegular: EngineTerrainGeosRegSynced;
			textAnnotations: EngineTextBlockGeometries;
		}
    }) {
        super('engine-geometries', Object.values(params.subtypes) as EntitiesBase<EngineGeometry, EngineGeoType>[]);
        this.logger = params.logger.newScope('all-engine-geos');
        this.gpuGeometries = params.gpuGeos;
        this._updatesAccumulator = new StreamAccumulator(this.updatesStream);

		this.triGeometries = params.subtypes.triGeometries;
		this.polylineGeometries = params.subtypes.polylineGeometries;
		this.analytPolylines = params.subtypes.analytPolylines;
		this.cubeGeometries = params.subtypes.cubeGeometries;
		this.extrudedPolygonGeometries = params.subtypes.extrudedPolygonGeometries;
		this.planeGeometries = params.subtypes.planeGeometries;
		this.spriteGeometries = params.subtypes.spriteGeometries;
		this.trackerPileBillboardGeometries = params.subtypes.trackerPileBillboardGeometries;
		this.terrainIrregular = params.subtypes.terrainIrregular;
		this.terrainRegular = params.subtypes.terrainRegular;
		this.textAnnotations = params.subtypes.textAnnotations;

		params.gc.registerGCedEntitiesCollection('geometries', this);
    }

    mapBimIdToEngineId(idBimGeo: IdBimGeo): IdEngineGeo | undefined {
        const ty = entityTypeFromId(idBimGeo as unknown as IdEngineGeo);
        if (ty === EngineGeoType.Triangle) {
            return idBimGeo as unknown as IdEngineGeo;
        }
        const coll = this.entitiesByType.get(ty) as 
            (EntitiesBimSynced<any, any, any, any> | EntitiesBimSyncedInterned<any, any, any, any>);
        return coll?.mapBimIdToEngineId(idBimGeo);
    }

    getEngineOwnedIds(result: Set<IdEngineGeo>): void {
		for (const c of this.entitiesByType.values()) {
			const geometriesHolder: GCedEntitiesCollection<EngineGeoType> | undefined =
				(c as unknown as GCedEntitiesCollection<EngineGeoType> | undefined);
			if (geometriesHolder?.getEngineOwnedIds) {
				geometriesHolder.getEngineOwnedIds(result);
			} else {
				this.logger.error(`${c.identifier} does not implement UnmanagedEntitiesCollection interface`);
			}
		}

	}

    update(gpuUploadUrgency: GpuUploadUrgency) {
        const updates = this._updatesAccumulator.consume();
        if (updates) {
			handleEntitiesUpdates(
				updates,
                (allocatedIds) => {
					const toUpload = new Map<IdEngineGeo, EngineGeometry>();
                    for (const geoId of allocatedIds) {
                        const geo = this.peekById(geoId);
                        if (geo) {
                            toUpload.set(geoId, geo as EngineGeometry)
                        }
                    }
					this.gpuGeometries.addForUpload(toUpload);

                },
                (perIdDiffs) => {
					const toUpdate: [IdEngineGeo, EngineGeometry][] = [];
					const toDelete: IdEngineGeo[] = [];
					for (const id of perIdDiffs.keys()) {
						const geo = this.peekById(id);
						if (geo) {
							toUpdate.push([id, geo as EngineGeometry]);
						} else {
							toDelete.push(id);
						}
					}
					if (toUpdate.length) {
						this.gpuGeometries.update(toUpdate);
					}
					if (toDelete.length) {
						this.gpuGeometries.delete(toDelete);
					}
                },
                (deleted) => {
                    this.gpuGeometries.delete(deleted);
                }
            )

        }
		this.gpuGeometries.handleUploads(gpuUploadUrgency);
    }

	sync() {
		for (const coll of this.entitiesByType.values()) {
			if (coll instanceof EntitiesBimSynced || coll instanceof EntitiesBimSyncedInterned) {
				coll.sync();
			}
		}
	}
}

