import { DefaultMap, DefaultMapObjectKey, ObjectUtils } from "engine-utils-ts";
import { NumberProperty } from "../properties/PrimitiveProps";


export interface WireAmpacity {
    temperature: NumberProperty,
    buriedAmpacity: NumberProperty,
    freeAirAmpacity: NumberProperty,
}

export interface WireGaugeData {
    name: string;
    material: string;
    diameter: NumberProperty;
    stc: {
        temperature: NumberProperty;
        dcResistivity: NumberProperty;
        acResistivity: NumberProperty;
        reactance: NumberProperty;
    }
    ampacities: Array<WireAmpacity>;
};

export interface WireGauge extends WireGaugeData {}
export class WireGauge {
    constructor(data: WireGaugeData) {
        Object.assign(this, data);
    }
    get _resistivityCoef1() {
        let coef = 228.1;
        if (this.material === 'copper') {
            coef = 234.5;
        } else if (this.material === 'aluminum') {
        } else {
            console.warn('invalid material to calculate dcResistivity. using default one');
        }
        return coef
    }
    getFullName() {
        return Array.from(new Set([this.name, this.material])).join(' ');
    }
    approxDcResistivityByTemperature(temperature: NumberProperty): NumberProperty {
        const coef1 = this._resistivityCoef1;
        const nominalTemperatureCelsius = this.stc.temperature.as('C');
        const currentTemperatureCelsius = temperature.as('C');
        const resultingResistivity = this.stc.dcResistivity.value *
            (coef1 + currentTemperatureCelsius) / (coef1 + nominalTemperatureCelsius);
        return this.stc.dcResistivity.withDifferentValue(resultingResistivity);
    }
    approxAcResistivityByTemperature(temperature: NumberProperty): NumberProperty {
        const coef1 = this._resistivityCoef1;
        const coefPowerFactor = 0.95;
        const nominalTemperatureCelsius = this.stc.temperature.as('C');
        const currentTemperatureCelsius = temperature.as('C');
        const resistivityNoReactance = this.stc.acResistivity.value *
            (coef1 + currentTemperatureCelsius) / (coef1 + nominalTemperatureCelsius);
        const reactanceInStdUnits = this.stc.reactance.as(this.stc.acResistivity.unit);
        const resultingResistivity = (
            resistivityNoReactance * coefPowerFactor +
            reactanceInStdUnits * Math.sin(Math.acos(coefPowerFactor))
        );
        return this.stc.acResistivity.withDifferentValue(resultingResistivity);
    }
    approxAmpacityByTemperature(
        temperature: NumberProperty,
        isBuried: boolean,
    ): NumberProperty | null {
        if (this.ampacities.length === 0) return null;
        const stdUnitForAmp = this.ampacities[0].buriedAmpacity.unit;
        const stdUnitForTemp = temperature.unit;
        const tempInStdUnit = temperature.value;

        let amps: Array<{ temp: number, amp: number }> = [];
        if (isBuried) {
            amps = this.ampacities
                .filter(x => x.buriedAmpacity.value !== 0)
                .map(x => ({
                    temp: x.temperature.as(stdUnitForTemp),
                    amp: x.buriedAmpacity.as(stdUnitForAmp),
                }));
        } else {
            amps = this.ampacities
                .filter(x => x.freeAirAmpacity.value !== 0)
                .map(x => ({
                    temp: x.temperature.as(stdUnitForTemp),
                    amp: x.freeAirAmpacity.as(stdUnitForAmp),
                }));
        }
        if (!amps.length) return null;

        // sort amps by temperature
        amps.sort((l, r) => l.temp - r.temp);

        // if exact match found, return it
        const exact = amps.find(x => x.temp === tempInStdUnit);
        if (exact) {
            return NumberProperty.new({
                value: exact.amp,
                unit: stdUnitForAmp,
            });
        }

        // find last element that is smaller than given temperature
        const smaller = [...amps].reverse() .find(x => x.temp < tempInStdUnit);
        // find first element that is bigger than given temperature
        const bigger = [...amps].find(x => x.temp > tempInStdUnit);

        // if no smaller nor bigger present return
        // just return nominal amp
        if (!smaller && !bigger) {
            throw new Error(['no amp found for', this.name, this.material].join(' '));
        }

        // if one of smaller or bigger not present, then return another one
        // meaning temperature is outside of the range, so limit is taken
        if (!smaller || !bigger)
            return NumberProperty.new({
                value: (smaller || bigger)!.amp,
                unit: stdUnitForAmp,
            })

        // both `smaller` and `bigger` present
        // use lineal interpolation to find a point within the range
        const dT = bigger.temp - smaller.temp;
        const dA = bigger.amp - smaller.amp;
        const dt = temperature.as('C') - smaller.temp
        const da = dt*dA/dT;
        const result = smaller.amp + da;
        return NumberProperty.new({
            value: result,
            unit: stdUnitForAmp,
        })
    }
}

const ampacitiesPerGaugeAndMaterial = new DefaultMapObjectKey<
    { gauge: string, material: string },
    Array<{ temp: NumberProperty, buried: NumberProperty, freeAir: NumberProperty }>
>({
    valuesFactory: () => [],
    unique_hash: key => JSON.stringify(key),
})


const ampacities = new DefaultMapObjectKey<
    { gauge: string, material: string, isLv: boolean },
    Array<{ temp: NumberProperty, buried: NumberProperty, freeAir: NumberProperty }>
>({
    valuesFactory: () => [],
    unique_hash: key => JSON.stringify(key),
})


const iters: Array<{
    csv: string,
    isLv: boolean,
    material: string,
    colms: Array<{
        isBuried: boolean,
        temp: number,
        index: number
    }>
}> = [
    {
        // copper lv wiring ampacity
        // Wire Size,Buried Ampacity 60C,Buried Ampacity 75C,Buried Ampacity 90C,Free-Air Ampacity 60C,Free-Air Ampacity 75C,Free-Air Ampacity 90C
        csv: `
#14 AWG,20,25,25,25,30,35
#12 AWG,25,35,30,30,35,40
#10 AWG,30,-,40,40,50,55
#8 AWG,40,50,55,60,70,80
#6 AWG,55,65,75,80,95,105
#4 AWG,70,85,95,105,125,140
#3 AWG,85,100,110,120,145,165
#2 AWG,95,115,130,140,170,190
#1 AWG,110,130,150,165,195,220
#1/0 AWG,125,150,170,195,230,260
#2/0 AWG,145,175,195,225,265,300
#3/0 AWG,165,200,225,260,310,350
#4/0 AWG,195,230,260,300,360,405
250 kcmil,215,255,290,340,405,455
300 kcmil,240,285,320,375,445,505
350 kcmil,260,310,350,420,505,570
400 kcmil,280,335,380,455,545,615
500 kcmil,320,380,430,515,620,700
600 kcmil,355,420,475,575,690,780
700 kcmil,385,460,520,630,755,855
750 kcmil,400,475,535,655,785,885
1000 kcmil,455,545,615,780,935,1055
1250 kcmil,495,590,665,890,1065,1200
1500 kcmil,520,625,705,980,1175,1325
1750 kcmil,545,650,735,1070,1280,1445
2000 kcmil,560,665,750,1155,1385,1560
`,
        colms: [
            { index: 1, isBuried: true, temp: 60 },
            { index: 2, isBuried: true, temp: 75 },
            { index: 3, isBuried: true, temp: 90 },
            { index: 4, isBuried: false, temp: 60 },
            { index: 5, isBuried: false, temp: 75 },
            { index: 6, isBuried: false, temp: 90 },
        ],
        isLv: true,
        material: 'copper',
    },
    {
        // aluminum lv wiring ampacity
        // Wire Size,Buried Ampacity 60C,Buried Ampacity 75C,Buried Ampacity 90C,Free-Air Ampacity 60C,Free-Air Ampacity 75C,Free-Air Ampacity 90C
        csv: `
#14 AWG,-,-,-,-,-,-
#12 AWG,20,20,25,25,30,35
#10 AWG,25,30,35,35,40,40
#8 AWG,30,40,45,45,55,60
#6 AWG,40,50,60,60,75,80
#4 AWG,55,65,75,80,100,110
#3 AWG,65,75,85,95,115,130
#2 AWG,75,90,100,110,135,150
#1 AWG,85,100,115,130,155,175
#1/0 AWG,100,120,135,150,180,205
#2/0 AWG,115,135,150,175,210,235
#3/0 AWG,130,155,175,200,240,275
#4/0 AWG,150,180,205,235,280,315
250 kcmil,170,205,230,265,315,355
300 kcmil,190,230,255,290,350,395
350 kcmil,210,250,280,330,395,445
400 kcmil,225,270,305,355,425,480
500 kcmil,260,310,350,405,485,545
600 kcmil,285,340,385,455,540,615
700 kcmil,310,375,420,500,595,675
750 kcmil,320,385,435,515,620,700
1000 kcmil,375,445,500,625,750,845
1250 kcmil,405,485,545,710,855,960
1500 kcmil,435,520,585,795,950,1075
1750 kcmil,455,545,615,875,1050,1185
2000 kcmil,470,560,630,960,1150,1335
`,
        colms: [
            { index: 1, isBuried: true, temp: 60 },
            { index: 2, isBuried: true, temp: 75 },
            { index: 3, isBuried: true, temp: 90 },
            { index: 4, isBuried: false, temp: 60 },
            { index: 5, isBuried: false, temp: 75 },
            { index: 6, isBuried: false, temp: 90 },
        ],
        isLv: true,
        material: 'aluminum',
    },
    {
        // copper mv wiring ampacity
        //Wire Size,Buried Ampacity 90C,Buried Ampacity 105C,Free-Air Ampacity 90C,Free-Air Ampacity 105C
        csv: `
#6 AWG,105,115,100,110
#4 AWG,140,150,130,140
#2 AWG,175,190,170,195
#1 AWG,200,215,195,225
#1/0 AWG,225,240,225,255
#2/0 AWG,255,275,260,295
#3/0 AWG,290,315,300,340
#4/0 AWG,325,350,345,390
250 kcmil,355,380,380,430
350 kcmil,425,455,470,525
500 kcmil,510,545,580,650
750 kcmil,615,660,730,820
1000 kcmil,690,745,850,950
1250 kcmil,740,797,910,1017
1500 kcmil,844,845,971,1086
`,
        colms: [
            { index: 1, isBuried: true, temp: 90 },
            { index: 2, isBuried: true, temp: 105 },
            { index: 3, isBuried: false, temp: 90 },
            { index: 4, isBuried: false, temp: 105 },
        ],
        isLv: false,
        material: 'copper',
    },
    {
        // aluminum mv wiring ampacity
        //Wire Size,Buried Ampacity 90C,Buried Ampacity 105C,Free-Air Ampacity 90C,Free-Air Ampacity 105C
        csv: `
#6 AWG,85,90,75,84
#4 AWG,105,115,100,110
#2 AWG,135,145,130,150
#1 AWG,155,170,150,175
#1/0 AWG,175,190,175,200
#2/0 AWG,200,215,200,230
#3/0 AWG,225,245,230,265
#4/0 AWG,255,275,270,305
250 kcmil,280,300,300,335
350 kcmil,335,360,370,415
500 kcmil,405,435,460,515
750 kcmil,485,525,590,660
1000 kcmil,565,605,700,780
1250 kcmil,598,643,745,832
1500 kcmil,635,683,798,890
`,
        colms: [
            { index: 1, isBuried: true, temp: 90 },
            { index: 2, isBuried: true, temp: 105 },
            { index: 3, isBuried: false, temp: 90 },
            { index: 4, isBuried: false, temp: 105 },
        ],
        isLv: false,
        material: 'aluminum',
    },
];

for (const iter of iters) {
    for (const lineStr of iter.csv.split('\n').filter(x => x)) {
        const components = lineStr.split(',');
        const componentsAsNumbers = components.map(x => Number.isFinite(+x) ? +x : 0);
        if (components.length !== iter.colms.length + 1) {
            //console.error('invalid format, skipping line', lineStr);
            continue;
        }
        const gaugeName = components[0];
        if (!iter.colms.length) {
            //console.error('no columns present')
        }
        const ampMap = new DefaultMap<number, { buried: number, freeAir: number }>(
            () => ({ freeAir: 0, buried: 0 }),
        )
        for (const colm of iter.colms) {
            const amp = ampMap.getOrCreate(colm.temp);
            if (colm.isBuried) {
                amp.buried = componentsAsNumbers[colm.index];
            } else {
                amp.freeAir = componentsAsNumbers[colm.index];
            }
        }
        for (const [k, v] of ampMap) {
            const amps = ampacities.getOrCreate(ObjectUtils.deepFreeze({
                gauge: gaugeName,
                isLv: iter.isLv,
                material: iter.material,
            }));
            if (!v.buried || !v.freeAir) continue;
            amps.push({
                buried: NumberProperty.new({ value: v.buried, unit: 'A' }),
                freeAir: NumberProperty.new({ value: v.freeAir, unit: 'A' }),
                temp: NumberProperty.new({ value: k, unit: 'C' }),
            })
        }
    }
}


const reactancePerGauge = new Map<string, NumberProperty>();
const reactivityPerGaugeAndMaterial = new DefaultMapObjectKey<
    { gauge: string, material: string },
    { acReactivity: NumberProperty, dcReactivity: NumberProperty, inLv: boolean, inMv: boolean }
>({
    valuesFactory: () => ({
        acReactivity: NumberProperty.new({ value: NaN, unit: '' }),
        dcReactivity: NumberProperty.new({ value: NaN, unit: '' }),
        inLv: false,
        inMv: false,
    }),
    unique_hash: key => key.gauge + key.material,
});
{
    //Wire Gauge,Copper Wire,,,,Aluminum Wire,,,,"Reactance, Om/kft"
    //,"DC Resistivity, Om/kft","AC Resistivity, Om/kft",lv default pack,mv default pack,"DC Resistivity, Om/kft","AC Resistivity, Om/kft",lv default pack,mv default pack,
    const stcCSV = `
#14 AWG,2.62,2.5071,,,,,,,0.058
#12 AWG,1.65,1.5844,,,2.7,2.5819,,,0.054
#10 AWG,1.04,1.0036,yes,,1.7,1.6306,,,0.05
#8 AWG,0.654,0.6375,yes,,1.07,1.0327,yes,,0.052
#6 AWG,0.41,0.4054,,,0.674,0.6562,yes,,0.051
#4 AWG,0.259,0.261,,,0.424,0.4178,yes,,0.048
#3 AWG,0.205,0.2091,,,0.336,0.3336,,,0.046
#2 AWG,0.162,0.168,,,0.267,0.2677,,,0.045
#1 AWG,0.129,0.1369,,,0.211,0.2148,,,0.046
#1/0 AWG,0.102,0.1106,,,0.168,0.1733,,,0.044
#2/0 AWG,0.0811,0.0905,,,0.133,0.1398,,,0.043
#3/0 AWG,0.0642,0.0741,,,0.105,0.1129,,,0.042
#4/0 AWG,0.0509,0.0612,,,0.0836,0.0922,yes,yes,0.041
250 kcmil,0.0431,0.0537,,,0.0708,0.0801,yes,,0.041
300 kcmil,0.036,0.047,,,0.059,0.0689,,,0.041
350 kcmil,0.0308,0.0417,,,0.0505,0.0605,yes,yes,0.04
400 kcmil,0.027,0.0381,,,0.0442,0.0545,,,0.04
500 kcmil,0.0216,0.0327,,,0.0354,0.0458,yes,yes,0.039
600 kcmil,0.018,0.0293,,,0.0295,0.0402,yes,,0.039
700 kcmil,0.0154,0.0265,,,0.0253,0.0359,,,0.038
750 kcmil,0.0144,0.0255,,,0.0236,0.0343,yes,yes,0.038
1000 kcmil,0.0108,0.0218,,,0.0177,0.0284,yes,yes,0.037
1250 kcmil,0.0086,0.0194,,,0.0142,0.0247,,yes,0.036
1500 kcmil,0.00719,0.0178,,,0.0118,0.0221,,yes,0.035
1750 kcmil,0.00616,0.0165,,,0.0101,0.0202,,,0.034
2000 kcmil,0.00539,0.0154,,,0.0089,0.0187,,,0.033
`;
    for (const lineStr of stcCSV.split('\n').filter(x => x)) {
        const components = lineStr.split(',');
        if (components.length !== 10) {
            //console.error('invalid format, skipping', lineStr);
            continue;
        }
        const gaugeName = components[0];
        const numbers = components.slice(1).map(x => +x);
        const [cuDcR, cuAcR, _1, _2,  alDcR, alAcR, _3, _4, reactance] = numbers;
        reactance: {
            if (!Number.isFinite(reactance) || reactance <= 0) break reactance;
            reactancePerGauge.set(
                gaugeName,
                NumberProperty.new({ value: reactance, unit: 'Om/kft' })
            );
        }
        copper: {
            if (![cuAcR, cuDcR].every(x => Number.isFinite(x) && x > 0)) break copper;
            const stat = reactivityPerGaugeAndMaterial
                .getOrCreate(Object.freeze({ gauge: gaugeName, material: 'copper' }))
            stat.acReactivity = NumberProperty.new({ value: cuAcR, unit: 'Om/kft' });
            stat.dcReactivity = NumberProperty.new({ value: cuDcR, unit: 'Om/kft' });
            stat.inLv = components[3] === 'yes';
            stat.inMv = components[4] === 'yes';
        }
        aluminum: {
            if (![alAcR, alDcR].every(x => Number.isFinite(x) && x > 0)) break aluminum;
            const stat = reactivityPerGaugeAndMaterial
                .getOrCreate(Object.freeze({ gauge: gaugeName, material: 'aluminum' }))
            stat.acReactivity = NumberProperty.new({ value: alAcR, unit: 'Om/kft' });
            stat.dcReactivity = NumberProperty.new({ value: alDcR, unit: 'Om/kft' });
            stat.inLv = components[7] === 'yes';
            stat.inMv = components[8] === 'yes';
        }
    }
}

const diametersPerGauge = new Map<string, NumberProperty>();
{
    const diametersInches: Array<[gauge: string, diameter: number]> = [
        ["#14 AWG", 0.0641],
        ["#12 AWG", 0.0808],
        ["#10 AWG", 0.1019],
        ["#8 AWG", 0.1285],
        ["#6 AWG", 0.162],
        ["#4 AWG", 0.2043],
        ["#3 AWG", 0.2294],
        ["#2 AWG", 0.2576],
        ["#1 AWG", 0.2893],
        ["#1/0 AWG",0.3249],
        ["#2/0 AWG",0.3648],
        ["#3/0 AWG",0.4096],
        ["#4/0 AWG",0.46],
        ["250 kcmil", 0.5],
        ["300 kcmil", 0.548],
        ["350 kcmil", 0.592],
        ["400 kcmil", 0.632],
        ["500 kcmil", 0.707],
        ["600 kcmil", 0.775],
        ["700 kcmil", 0.837],
        ["750 kcmil", 0.866],
        ["1000 kcmil", 1],
        ["1250 kcmil", 1.118],
        ["1500 kcmil", 1.225],
        ["1750 kcmil", 1.323],
        ["2000 kcmil", 1.414],
    ];
    diametersInches.forEach(([gauge, diameter]) => {
        diametersPerGauge.set(gauge, NumberProperty.new({ value: diameter, unit: 'inch' }));
    })
}

const lvGauges: WireGauge[] = [];
const mvGauges: WireGauge[] = [];
const defaultLvGauges: WireGauge[] = [];
const defaultMvGauges: WireGauge[] = [];
const temperatureAtStcNew = NumberProperty.new({ value: 25, unit: 'C' });
for (
    const [
        { gauge, material },
        { acReactivity, dcReactivity, inLv, inMv }
    ] of reactivityPerGaugeAndMaterial
) {
    const reactance = reactancePerGauge.get(gauge);
    const diameter = diametersPerGauge.get(gauge);
    if (!reactance || !diameter) continue;
    for (const isLv of [true, false]) {
        const ampacity = ampacities.get(ObjectUtils.deepFreeze({ gauge, isLv, material }));
        if (!ampacity) continue;
        const gaugeInstance: WireGauge = new WireGauge({
            name: gauge,
            material,
            diameter: diameter,
            stc: {
                reactance,
                acResistivity: acReactivity,
                dcResistivity: dcReactivity,
                temperature: temperatureAtStcNew,
            },
            ampacities: ampacity.map(x => ({
                buriedAmpacity: x.buried,
                freeAirAmpacity: x.freeAir,
                temperature: x.temp,
            })),
        });
        if (isLv) {
            lvGauges.push(gaugeInstance);
            if (inLv) {
                defaultLvGauges.push(gaugeInstance);
            }
        } else {
            mvGauges.push(gaugeInstance);
            if (inMv) {
                defaultMvGauges.push(gaugeInstance);
            }
        }
    }
}

export const AWG_LV_GAUGES = lvGauges;
export const AWG_MV_GAUGES = mvGauges;
export const AWG_DEFAULT_LV_GAUGES = defaultLvGauges
export const AWG_DEFAULT_MV_GAUGES = defaultMvGauges
