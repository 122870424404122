import { Vector2 } from 'math-ts';
import { Anchor2D, VectorPrimitivePath, VectorPrimitivePathDescription, VectorPrimitiveRectangle, createAabbLabel } from 'vector-graphic';
import { wireWidth } from './config';

export function createResistance(label: string): Anchor2D {
    const resistance = new Anchor2D();

    const rectWidth = 50

    const pt1 = new Vector2();
    const pt2 = new Vector2(pt1.x + rectWidth, pt1.y);
    const pt3 = new Vector2(pt2.x + rectWidth, pt2.y);
    const pt4 = new Vector2(pt3.x + rectWidth, pt3.y);

    // draw resistance
    const rect = new VectorPrimitiveRectangle({
        cx: pt2.x + rectWidth / 2,
        cy: pt2.y,
        height: rectWidth / 2,
        width: rectWidth,
        stroke: 'black',
        strokeWidth: wireWidth,
        fill: 'white',
    });
    rect.zIndex = 2;

    const wire = new VectorPrimitivePath({
        paths: [new VectorPrimitivePathDescription([pt1, pt4])],
        strokeWidth: wireWidth,
    })
    resistance.addPrimitiveAndExpandAabb(rect, wire)

    // draw text
    const label2D= createAabbLabel({
        aabb: resistance.aabb,
        fontSize: 20,
        side: 'bottom',
        text: label,
    })
    resistance.addPrimitive(label2D);

    resistance.point.copy(pt3)

    return resistance;
}
