<script lang="ts">
    export let isOpen: boolean;
    export let onChange: (value: boolean) => void = () => {};
</script>

<svg
    class="hierarchy"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    on:click={() => {
        onChange(!isOpen);
    }}
>
    {#if isOpen}
        <path
            d="M2 7L3 6L6.0223 9L9 6L10 7L6.0223 11L2 7Z"
            fill="#101314"
            fill-opacity="0.69"
        />
    {:else}

        <path
            d="M5 12L4 11L7 7.9777L4 5L5 4L9 7.9777L5 12Z"
            fill="#101314"
            fill-opacity="0.69"
        />
    {/if}
</svg>

<style lang="scss">
    .hierarchy {
        cursor: pointer;
    }
</style>
