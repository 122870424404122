<script lang="ts">
    import { ButtonComponent, IconButton } from '../../libui/button';
    import { TemplateDescription } from '../Models';

    export let label: string = "";
    export let equipmentInputs: TemplateDescription[] = [];
    export let action: (() => void) | undefined = undefined;
    export let readonly = false;
    export let editSelection: (template: TemplateDescription) => void;
    export let removeFromSelect: (template: TemplateDescription) => void = function(t: TemplateDescription){
        equipmentInputs = equipmentInputs.filter(x => x.id != t.id);
    };
    export let selectObject: (id: number)=>void = (id:number)=>{};
    export let maxItemes:{count:number, alwaysShow:boolean} = {count:5, alwaysShow:false};
    export let showDeleteAll = true;
    export let selectedId:number|undefined = undefined;
    $:showAddButton = !!action;
    
    $:countItems = equipmentInputs.length > 0 ? `${equipmentInputs.length}` : "";

    function removeAll():void {
        equipmentInputs = [];
    }
    const lineHeight = 19;
    const padding = 4;
    const totalHeight = lineHeight + 2*padding;
</script>

<style lang="scss">
    span {
        opacity: 0.6;
        cursor: default;
        text-overflow: ellipsis;
    }

    .root {
        display: flex;
        flex-direction: column;
        background: #ddd;
        border-radius: 5px;
        padding: 0px;
        overflow:hidden;
    }

    p {
        margin: 0;
    }
    .list {
        background: #eee;
    }
    .text-list-item {
        overflow: hidden;
        white-space: normal;
        justify-content: center;
        display: flex;
            &.list-item {
                cursor: default;
                padding: 3px 15px;
                justify-content: space-between;

                &:nth-child(even) {
                    background: #dde;
                }
                &.selected-list-item {
                    background-color: rgba(0,0,0,0.3);
                }
            }
    }
    .add-btn {
        background: #eee;
        cursor: pointer;
        &:hover {
            filter: sepia(0.3);
        }
    }


  .all-items {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #ddd;
        border-radius: 5px;
        padding: 0px;
        overflow: hidden;
        padding: 3px 15px 3px 5px;
    }

    .items-container{
        overflow: auto;
    }

</style>

<div class="root mdc-typography mdc-typography-body1">
    {#if label}
        <div class="all-items mdc-typography mdc-typography-body1">
            {#if (equipmentInputs.length > 0 && showDeleteAll)}
                <span></span>
                <span>{`${label} ${countItems}`}</span>
                <ButtonComponent desc={new IconButton("Close", removeAll)} />
            {:else}
                <span></span>
                <span>{`${label} ${countItems}`}</span>
                <span></span>
            {/if}
        </div>
    {/if}
    <div 
        class="list items-container custom-scrollbar"
        style="height:{maxItemes.alwaysShow
            ? maxItemes.count * totalHeight
            : maxItemes.count > equipmentInputs.length ? equipmentInputs.length * totalHeight : maxItemes.count * totalHeight
        }px;"
    >
        {#each equipmentInputs as selected}
            <p 
                class="list-item text-list-item"
                class:selected-list-item="{selectedId===selected.id}"
                style="height: {lineHeight}px;"
                on:click={() => selectObject(selected.id)}
                title = {selected.label}
            >
                {selected.label}
                {#if !readonly}
                    <span style="display: flex; align-items: center;">
                        <ButtonComponent desc={new IconButton("Edit", () => editSelection(selected))} />
                        <ButtonComponent desc={new IconButton("Close", () => removeFromSelect(selected))} />
                    </span>
                {/if}
            </p>
        {/each}
    </div>
    {#if showAddButton}
    <p class="add-btn text-list-item" on:click={action}>{"add"}&nbsp;<b>+</b></p>
    {/if}
</div>