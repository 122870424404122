import { Builder, ByteBuffer } from "flatbuffers";
import type { ObjectsPickingSerializer } from "verdata-ts";
import { dateFromFlatbufLong, dateToFlatbufLong } from "verdata-ts";
import { CatalogItemCollection } from "../schema/catalog-item-collection";
import { CatalogItem as CatalogItemFB } from '../schema/catalog-item'
import { CatalogItem } from "./CatalogItem";
import { buildSourceFB, parseSourceFB } from "./AssetsSerializer";
import type { PropertyGroup } from "..";
import { DC_CNSTS, FlatbufCommon, LvWireSpecCatalogItemTypeIdent } from "..";
import { NumberProperty } from "../properties/PrimitiveProps";
import { PropsGroupsDeserializer, PropsGroupsSerializer } from "../persistence/PropsGroupsSerializer";
import { ScopedLogger } from "engine-utils-ts";
import { PropsDeserializer } from "../persistence/PropsSerializer";

export enum CatalogItemVersion {
    DEFAULT,
    USE_QY_PRICING_FOR_TRENCHES,
    ADD_PRICE_UNITS,
    PropsGroupsArraysFix,
    FIX_PRICE_UNIT_IN_WIRING,
    LV_WIRE_ADD_PRICE_SUFFIX = 6,
    FIX_PRICE_PER_LV_TYPE,
}

export const CatalogItemLatestVersion = CatalogItemVersion.FIX_PRICE_PER_LV_TYPE;

export class CatalogItemsSerializer implements ObjectsPickingSerializer<CatalogItem> {
    serialize(objects: [number, CatalogItem][]): Uint8Array {
        const logger = new ScopedLogger('catalog-ser');
        const builder = new Builder();
        const ids = CatalogItemCollection.createIdsVector(builder, objects.map(([id]) => id));
        const propsSerializer = new PropsGroupsSerializer({logger, builder, strictGroupsTypes: false});
        const objectsOffset = CatalogItemCollection.createCollectionVector(
            builder, objects.map(([_id, asset]) => {
                const name = builder.createString(asset.name)
                const typeIdentifier = builder.createString(asset.typeIdentifier)
                const propsOffset = propsSerializer.serializePropertyGroup(asset.properties);
                const source = buildSourceFB(builder, asset.source);

                // create catalog item
                CatalogItemFB.startCatalogItem(builder);
                CatalogItemFB.addName(builder, name);
                CatalogItemFB.addTypeIdentifier(builder, typeIdentifier);
                CatalogItemFB.addProperties(builder, propsOffset);
                CatalogItemFB.addSource(builder, source);
                CatalogItemFB.addCreatedAt(builder, dateToFlatbufLong(asset.createdAt));
                CatalogItemFB.addUpdatedAt(builder, dateToFlatbufLong(asset.updatedAt));
                const offset = CatalogItemFB.endCatalogItem(builder);
                return offset;
            })
        );

        const groupsVersionsPerTypeOffset = 0; // we are not using typed groups in catalog yet
        const customPropsVersionsPerTypeOffset = propsSerializer.propsSerializer.serializeCustomTypesVersions();

        CatalogItemCollection.startCatalogItemCollection(builder);
        CatalogItemCollection.addFormatVersion(builder, CatalogItemLatestVersion);
        CatalogItemCollection.addIds(builder, ids);
        CatalogItemCollection.addCollection(builder, objectsOffset);
        CatalogItemCollection.addPropsGroupsVersionsPerTypeIdent(builder, groupsVersionsPerTypeOffset);
        CatalogItemCollection.addCustomPropsVersionsPerTypeIdent(builder, customPropsVersionsPerTypeOffset);
        const root = CatalogItemCollection.endCatalogItemCollection(builder);
        builder.finish(root);
        return builder.asUint8Array().slice();
    }

    deserialize(buffer: Uint8Array, idsToDeserialize: Set<number>): [number, CatalogItem][] {
        const itemsEncoded = CatalogItemCollection
            .getRootAsCatalogItemCollection(new ByteBuffer(buffer));

        const version = itemsEncoded.formatVersion();

        const customPropsTypesVersionsFlat = itemsEncoded.customPropsVersionsPerTypeIdent();
        const customPropsTypesVersions = customPropsTypesVersionsFlat ? FlatbufCommon.readTypeIdentsVersions(customPropsTypesVersionsFlat) : new Map<string, number>();
        const propsDeserializer = new PropsDeserializer({
            logger: new ScopedLogger('catalog-props'),
            customTypesVersions: customPropsTypesVersions,
        });


        const result: [number, CatalogItem][] = [];
        const propsGroupsDeserializer = new PropsGroupsDeserializer({
            logger: new ScopedLogger('catalog-props'),
            groupsTypesVersions: new Map(),
            expectGroupsAraysLegacyBug: version < CatalogItemVersion.FIX_PRICE_UNIT_IN_WIRING,
            propsDeserializer: propsDeserializer,
        });
        for (let i = 0; i < itemsEncoded.idsLength(); i++) {
            const id = itemsEncoded.ids(i);
            if (id === null || !idsToDeserialize.has(id)) {
                continue;
            }
            const catalogItemEncoded = itemsEncoded.collection(i);
            if (catalogItemEncoded === null) {
                continue;
            }
            const source = parseSourceFB(catalogItemEncoded.source() ?? undefined);
            const createdAt = dateFromFlatbufLong(catalogItemEncoded.createdAt());
            const updatedAt = dateFromFlatbufLong(catalogItemEncoded.updatedAt());
            const props = propsGroupsDeserializer.deserializePropertyGroup(catalogItemEncoded.properties());
            const catalogItem = new CatalogItem(
                catalogItemEncoded.name() ?? undefined,
                catalogItemEncoded.typeIdentifier() ?? undefined,
                props ?? {},
                source,
                createdAt,
                updatedAt,
            )
            const migratedObj = CatalogItemsSerializer.migrationFn(catalogItem, version);
            result.push([id, migratedObj])
        }
        return result;
    }

    static migrationFn(
        inst: CatalogItem,
        version: CatalogItemVersion,
    ): CatalogItem {
        let migratedInst = inst;
        if (
            version < CatalogItemVersion.USE_QY_PRICING_FOR_TRENCHES &&
            inst.typeIdentifier === 'trench' &&
            inst.properties['price_per_LF']
        ) {
            const newProps = {
                ...migratedInst.properties,
            }
            newProps['price_per_CY'] = newProps['price_per_LF'];
            delete newProps['price_per_LF'];
            migratedInst.properties = newProps;
        }
        if (version < CatalogItemVersion.ADD_PRICE_UNITS) {
            let newProps: PropertyGroup = {};
            for (const [k, v] of Object.entries(migratedInst.properties)) {
                if (
                    !(v instanceof NumberProperty) ||
                    !k.startsWith('price_')
                ) {
                    newProps = { ...newProps, [k]: v };
                    continue;
                }
                const newValue = NumberProperty.new({
                    ...v, unit: 'usd',
                });
                newProps = { ...newProps, [k]: newValue };
            }
            migratedInst.properties = newProps;
        };
        if (version < CatalogItemVersion.FIX_PRICE_UNIT_IN_WIRING) migrate: {
            if (migratedInst.typeIdentifier !== LvWireSpecCatalogItemTypeIdent) {
                break migrate;
            }
            const keysOfInterest = new Set([
                'AcFeeder',
                'DcFeeder',
                'Extender',
                'MultiHarness',
                'TrunkBus',
                'Whip',
            ]);
            let newProps: PropertyGroup = {};
            for (const [k, v] of Object.entries(migratedInst.properties)) {
                if (
                    !(v instanceof NumberProperty) ||
                    !keysOfInterest.has(k)
                ) {
                    newProps = { ...newProps, [k]: v };
                    continue;
                }
                const newValue = NumberProperty.new({
                    ...v, unit: 'usd',
                });
                newProps = { ...newProps, [k]: newValue };
            }
            migratedInst.properties = newProps;
        }
        if (version < CatalogItemVersion.LV_WIRE_ADD_PRICE_SUFFIX) migrate: {
            if (migratedInst.typeIdentifier !== 'lv-wire-spec') {
                break migrate;
            }
            let newProps: PropertyGroup  = {}
            for (const [k, v] of Object.entries(migratedInst.properties)) {
                if (
                    !(k in DC_CNSTS.ConductorType) ||
                    !(v instanceof NumberProperty)
                ) {
                    newProps = { ...newProps, [k]: v };
                    continue;
                }

                const newKey = 'price_per_length_of_' + k;
                newProps = { ...newProps, [newKey]: v };
            }
            migratedInst.properties = newProps;
        }
        if (version < CatalogItemVersion.FIX_PRICE_PER_LV_TYPE) migrate: {
            if (migratedInst.typeIdentifier !== 'lv-wire-spec') {
                break migrate;
            }
            let newProps: PropertyGroup  = {}
            const keysToFix = Object.values(DC_CNSTS.ConductorType)
              .map(x => 'price_' + x);
            for (const [k, v] of Object.entries(migratedInst.properties)) {
                if (
                    !(keysToFix.includes(k)) ||
                    !(v instanceof NumberProperty)
                ) {
                    newProps = { ...newProps, [k]: v };
                    continue;
                }
                const type = k.split('_').at(-1);
                if (!type) {
                  newProps = { ...newProps, [k]: v };
                  continue;
                }
                const newKey = 'price_per_length_of_' + type;
                newProps = { ...newProps, [newKey]: v };
            }
            migratedInst.properties = newProps;
        }

        return migratedInst;
    }

}


