import type { TransformerDescription } from "../types";
import { createManyToOneConnection } from "src/sld/templates/connections";
import type { Anchors2D } from 'vector-graphic';
import { createInverterObject2DWithChilds } from "./inverter";
import { createTransformer } from "../../templates/transformer";
import { positionChildrensShortend } from "../../templates/common";


export function createTransformerObject2DWithChilds(transformerDesc: TransformerDescription)
    : Anchors2D
{
    // create children
    const object = positionChildrensShortend(
        transformerDesc.inverters,
        (cb, index) => createInverterObject2DWithChilds(cb, { index: (index + 1).toString() })
    )

    // create transformer base
    const transformer = createTransformer(transformerDesc)

    // add children wiring
    const wiring = createManyToOneConnection({
        sources: object.points,
        sourceOffset: 100,
        targetOffset: 100,
    })
    object.addAndExpandAabb(wiring);

    // position transformer
    if (!object.aabb.isEmpty() && wiring.points.length) {
        transformer.position.set(
            wiring.points[0].x - transformer.aabb.min.x,
            wiring.points[0].y
        );
        transformer.updateMatrix()
    }

    object.addAndExpandAabb(transformer);

    object.points = [];
    object.recalculateAabb();

    return object;
}
