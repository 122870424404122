<script lang="ts">
    //@ts-ignore
    import NumberSpinner from "svelte-number-spinner";
    import type { UnitsMapper } from "bim-ts";
    import type { PUI_PropertyNodeNumber } from "ui-bindings";
    import { ButtonComponent, IconButton } from 'src/libui/button';
    import { IconName } from 'src/libui/icon';
    import { currenciesCustomSymbols } from "engine-utils-ts";

    export let unitsMapper: UnitsMapper;
    export let sourceProperty: PUI_PropertyNodeNumber;
    export let formatter: (value: number, unit: string) => string = addUnit;
    export let parser: (value: string, prevValue: number, unit: string) => number | string | null = removeUnit;
    export let valueColorStyle: string = "black";
    export let decimals: number | undefined = undefined;

    
    $: property = unitsMapper.getMappedBoundProperty(sourceProperty);
    $: numberPropDecimals = decimals ?? property.decimals;

    $: isCurrencyType = !!(
        property.unit && property.unit in currenciesCustomSymbols
    );
    
    function addUnit(val: number) {
        // currency custom formatting
        if (isCurrencyType) {
            const beforePoint = val.toLocaleString().split(".")[0];
            const afterPoint = val.toFixed(numberPropDecimals).split(".")[1];
            const symbol = currenciesCustomSymbols[property.unit!];
            return symbol + " " + beforePoint + "." + afterPoint;
        }
        const valAsStr = val.toFixed(numberPropDecimals);
        const res = property.unit ? `${valAsStr} ${property.unit}` : valAsStr;
        return res;
    }

    function removeUnit(str: string): number | string | null {
        // currency custom formatting
        if (isCurrencyType) {
            const valStr = str.split(" ").at(-1);
            if (valStr) {
                return valStr.toLocaleString().replace(/,/g, "");
            }
        }
        if (!property.unit) {
            return str;
        }
        const num = parseFloat(str);
        if (isNaN(num)) {
            return property.value;
        }

        return num;
    }

    function format(v: number) {
        return formatter(v, property.unit ?? "");
    }
    function parse(v: string) {
        return parser(v, property.value ?? 0, property.unit ?? "");
    }

    $: icon = sourceProperty.icon ? new IconButton(
        sourceProperty.icon.iconName as IconName,
        sourceProperty.icon.onClick
    ) : undefined;
</script>

{#if sourceProperty.readonly}
    <span
        class="
        config-ui-input-field
        mdc-typography--body1 readonly
        "
        style="color:{valueColorStyle};"
    >
        {property.value !== null ? formatter(property.value, property.unit ?? "") : ""}
    </span>
{:else}
    <NumberSpinner
        bind:value={property.value}
        mainStyle={`color:${valueColorStyle}; width: 100%;`}
        type="number"
        format={format}
        parse={parse}
        class="
            config-ui-input-field
            mdc-typography--body1
        "
        step={property.step}
        speed={property.sliderSpeed}
        min={property.minMax[0]}
        max={property.minMax[1]}
    />
    <div class="ctl-buttons">
        {#if icon}
            <ButtonComponent desc={icon} />
        {/if}
    </div>
{/if}

<style lang="scss">

    .readonly {
        width: 100%;
        display: flex;
        cursor: default;
        overflow: hidden;
    }
</style>
