import { unitsConverter } from "engine-utils-ts";
import type { CostCategory } from ".";


export const wattDimension = unitsConverter.getDimensionsOfUnitsString('W');
export const emptyDimension = unitsConverter.getDimensionsOfUnitsString('');

export function findCostCategoryTotalCost(category: CostCategory) {
    const totalCost =
        (category.labor?.laborTotal?.value ?? 0) +
        (category.material?.materialTotal?.value ?? 0) +
        (category.subService?.subServiceTotal?.value ?? 0) +
        (category.equipment?.equipmentTotal?.value ?? 0);
    return totalCost;
}
