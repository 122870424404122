import type { BasicCollectionUpdates, IStreamAccumulator, LazyVersioned, ObjectSerializer, VersionedValue} from 'engine-utils-ts';
import { StreamTransformedAccumulator, peekCurrentEventFrame, unsafePopFromEventStackFrame, unsafePushToEventStackFrame} from 'engine-utils-ts';
import { VersionedInvalidator } from 'engine-utils-ts';
import type {
    Id,
	IdsProvider, ObjectsPickingSerializer, VerdataCollectionPatch,
	VerdataCollectionUpdates,
	VerDataPersistedCollection,
} from 'verdata-ts';

import type { Config} from '../';
import { ConfigsCollection, SceneInstances } from '../';
import type { EntitiesBase } from '../collections/EntitiesBase';
import type {
	CollectionAdditionalContext} from './CollectionAdditionalContext';
import { CollectionAdditionalContextSerializer,
} from './CollectionAdditionalContext';

export const VerdataApplyPatchEventName = 'verdata-apply-patch';

export class EntitiesPersisted<TState extends Object, TVersionedData extends Object = TState>
    implements VerDataPersistedCollection<TVersionedData, CollectionAdditionalContext | null>
{
    readonly _entities: EntitiesBase<TState, any>;
    readonly _serializer: ObjectsPickingSerializer<TVersionedData>;

    private _serializeInWorkerPool: boolean;


    // implement VerDataPersistedCollection
    updates: IStreamAccumulator<VerdataCollectionUpdates<Id>>;
    additionalContext: LazyVersioned<CollectionAdditionalContext | null> | undefined;
    stateInvalidator: VersionedValue;

    constructor(params: {
        entities: EntitiesBase<TState, any, any, any>,
        serializer: ObjectsPickingSerializer<TVersionedData>,
        versionedPortionFromState?: (state: TState) => TVersionedData,
        versionedPortionToState?: (vd: TVersionedData) => Partial<TState>,
        dataEq?: ((d1: TVersionedData, d2: TVersionedData) => boolean) | undefined,
        serializeInWorkerPool?: boolean,
        updates?: IStreamAccumulator<VerdataCollectionUpdates<Id>>,
    }) {
        this._entities = params.entities;
        this._serializer = params.serializer;
        
        this.versionedPortionFromState = params.versionedPortionFromState ?? ((t) => t as any);
        this.versionedPortionToState = params.versionedPortionToState ?? ((t) => t as any);
        this.dataEq = params.dataEq;

        this.updates = params.updates ?? new StreamTransformedAccumulator<BasicCollectionUpdates<any>, VerdataCollectionUpdates<Id>>(
            this._entities.updatesStream,
            (update) => {
                const e = peekCurrentEventFrame();
                if (e.isEventDerived && !e.doesInvalidatePersistedState) {
                    return null;
                }
                return {update, isCausedByVerdataApply: e.identifier === VerdataApplyPatchEventName}
            },
        );
        this.additionalContext = this._entities.getCollectionContextLazy();
        
        const stateInvalidator = new VersionedInvalidator([this.updates]);
        if (this.additionalContext) {
            stateInvalidator.addDependency(this.additionalContext);
        }
        this.stateInvalidator = stateInvalidator;

        this._serializeInWorkerPool = params.serializeInWorkerPool ?? false;
    }
    onBeforeSyncReverse?: (() => void) | undefined;

    getContextSerializer(): ObjectSerializer<CollectionAdditionalContext | null> {
        return new CollectionAdditionalContextSerializer();
    }
    
    getIdsProvider(): IdsProvider<any> {
        return this._entities.idsProvider;
    }

    onAfterSyncReverse?: (() => void) | undefined;

    versionedPortionFromState: (state: TState) => TVersionedData;
    versionedPortionToState: (vd: TVersionedData) => Partial<TState>;

    dataEq?: ((d1: TVersionedData, d2: TVersionedData) => boolean) | undefined;
    versionById?: ((id: number) => number) | undefined;

    onBeforeSync(): void {
        // this._entities.update();
    }
    // onBeforeSyncReverse(): void {
    //     this._entities.runGC();
    // }
    onAfterSync(): void {
    }

    toggleLock(locked: boolean): void {
        this._entities.toggleLock(locked);
    }
    
    showDiff(ids: number[], _dependenciesDiff: Map<string, number[]>): void {
        if (this._entities instanceof SceneInstances) {
            this._entities.setSelected(ids);
        }
    }

    getAllIds(): number[] {
        const all = Array.from(this._entities.perId.keys());
        return all;
    }

    getObjects(ids: number[]): [number, Readonly<TVersionedData>][] {
        const res: [number, TVersionedData][] = [];
        const te = this._entities;
        for (const id of ids) {
            const t = te.perId.get(id);
            if (t !== undefined) {
                const v = this.versionedPortionFromState(t);
                res.push([id, v]);
            } else {
                throw new Error('invalid id:' + id);
            }
        }
        return res;
    }

	applyPatch(patch: VerdataCollectionPatch<TVersionedData, CollectionAdditionalContext | null>): {toDeleteLater: number[]} {
		const event = unsafePushToEventStackFrame({identifier: VerdataApplyPatchEventName});
        try {
            if (patch.toAlloc) {
                const allocArgs: [number, Partial<TState>][] = patch.toAlloc.map(
                    t => [t[0], this.versionedPortionToState(t[1]) ]
                );
                this._entities.allocate(allocArgs);
    
                if (patch.collectionContext?.sharedEntitiesIds) {
                    this._entities.makeShared(patch.collectionContext.sharedEntitiesIds);
                }
            }
            if (patch.toPatch) {
                const fullObjects: [number, Partial<TState>][] = patch.toPatch.map(
                    t => [t[0], this.versionedPortionToState(t[1]) ]
                );
                if (this._entities instanceof ConfigsCollection) {
                    const toDelete: number[] = fullObjects.map(([id]) => id);
                    this._entities.delete(toDelete);
                    this._entities.allocate(fullObjects as [number, Partial<Config>][]);
                } else {
                    this._entities.applyPatches(fullObjects);
                }
            }
            return {toDeleteLater: patch.toDelete ?? []};
        } finally {
            unsafePopFromEventStackFrame(event);
        }
	}
    deleteObjects(ids: number[]): void {
        this._entities.delete(ids, {identifier: VerdataApplyPatchEventName});
    }

    markObjectsIdsOccupiedAlready(ids: Set<number>): void {
        this._entities.idsProvider.markIdsOccupied(ids);
    }

    getObjectsPickingSerializer(): ObjectsPickingSerializer<TVersionedData> {
        return this._serializer;
    }

    serializeInWorkerPool() {
        return this._serializeInWorkerPool;
    }
}

