import type { JsonClassSerializer } from './JsonClasses';

export function JsonClassesBuiltInSerializers(): JsonClassSerializer<any>[] {

    const result: JsonClassSerializer<any>[] = [];
    

    for (const arrClass of [
        Uint8Array,
        Uint16Array,
        Uint32Array,
        Int8Array,
        Int16Array,
        Int32Array,
        Float32Array,
        Float64Array,
    ]) {
        result.push({
            class: arrClass,
            toSerializable: Array.from,
            fromSerializable: arrClass.from as any,
        });
    }

    result.push({
        class: Date,
        toSerializable: (date: Date) => date.valueOf(),
        fromSerializable: (mlsecondsSince1970: number) => new Date(mlsecondsSince1970),
    });

    result.push({
        class: Map,
        toSerializable: Array.from,
        fromSerializable: (arr: [any, any][]) => new Map(arr),
    } as JsonClassSerializer<Map<any, any>>);

    result.push({
        class: Set,
        toSerializable: Array.from,
        fromSerializable: (arr: [any, any][]) => new Set(arr),
    } as JsonClassSerializer<Set<any>>);

    return result;
}

