<script lang="ts">
  import { getContext } from 'svelte'
  import { TextField } from "../libui/fields";
  import More from './More.svelte'
  import type { DataModel, SceneExplorerObjectsList } from './SceneExplorer';
  import type { VersionedStore } from '../VersionedStore';
  import CheckBoxComponent from '../reusable/CheckBoxComponent.svelte';

  export let sceneExplorerList: SceneExplorerObjectsList;
  let sceneList: VersionedStore<DataModel> = getContext('sceneManager')
  let searchVal: string = ''

  $: {
    if (searchVal !== $sceneList.search) {
      $sceneList.changeSearch(searchVal)
    }
  }
</script>

<div class="action-panel container">
  <TextField bind:value={searchVal} leadingIcon="Search" labelText="Search Scene" />
  <CheckBoxComponent  bind:checked = {sceneExplorerList.areSortByBlocks} label={"Sort by blocks"}/>
  <More/>
</div>

<style lang="scss">
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0 8px;
  }
</style>
