import type { LazyVersioned, VersionedValue } from 'engine-utils-ts';
import { LazyBasic, VersionedInvalidator } from 'engine-utils-ts';

import type { Camera } from '../3rdParty/three';
import type { GpuResources } from '../composer/GpuResources';
import type { BudgetLimitForPass, RenderPass, RenderResult } from './RenderPass';
import type { RenderTargets, RTIdent } from './RenderTargets';

export class ClearPass implements RenderPass {
    enabled: boolean = true;
    identifier: string;
    inputs: RTIdent[] = [];
    outputs: RTIdent[];
    clearColor: LazyVersioned<number>;
    clearAlpha: LazyVersioned<number>;
    
    _invalidator: VersionedValue;
    
    constructor(
        outputs: RTIdent[],
        clearColor: LazyVersioned<number> | number,
        clearAlpha: LazyVersioned<number> | number,
        additionalClearingTriggers: VersionedValue[],
    ) {
        this.identifier = 'clear_' + outputs.map(id => id.ident).join();
        this.outputs = outputs;
        this.clearColor = typeof clearColor == 'number' ? new LazyBasic<number>('', clearColor) : clearColor;
        this.clearAlpha = typeof clearAlpha == 'number' ? new LazyBasic<number>('', clearAlpha) : clearAlpha;
        this._invalidator = new VersionedInvalidator(
            [...additionalClearingTriggers, this.clearColor, this.clearAlpha]
        );
    }

    version() {
        return this._invalidator.version();
    }

    render(
        camera: Readonly<Camera>,
        gpuRes: GpuResources,
        renderTargets: RenderTargets,
        budget: BudgetLimitForPass
    ): RenderResult {
        for (const ident of this.outputs) {
            const rt = renderTargets.getRT(ident);
            renderTargets.bind(rt);
            // console.log('clear ', this.identifier, rt);
			gpuRes.renderer.setClearColor(this.clearColor.poll(), this.clearAlpha.poll());
            gpuRes.renderer.clear(true, ident.depth == 'own', false);
        }
        return { finished: true, unitsRenderered: this.outputs.length };
    }
    
}
