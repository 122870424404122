import type { CostReport } from "../../CostReport";

export const HideTypeIdentifier = 'hide';

export class Hide {
    constructor(
        public path: string[] = [],
        public deleteTopLevel: boolean = false,
    ) {}
}


export function registerHideAction(costReport: CostReport) {
    costReport.actionSolvers.registerActionSolver<Hide>(
        HideTypeIdentifier,
        (hide, hierarchy) => {
            const exactEntry = hierarchy.getEntry(hide.props.path);
            const subEntries = hierarchy.getEntriesWithPrefix(hide.props.path)
                .filter(x => x !== exactEntry);
            for (const entry of subEntries) {
                hierarchy.delete(entry.path);
            }
            if (hide.props.deleteTopLevel && exactEntry) {
                hierarchy.delete(exactEntry.path)
            }
        }
    )
}
