import type { PropValueTotalHash } from "src";
import { PropertyBase, TransformerIdent } from "src";
import { SolverObjectInstance } from "src/runtime/SolverObjectInstance";
import { TransformerProps } from "./Transformer";

export class TransformerSLDViewerProperty extends PropertyBase {
    uniqueValueHash(): PropValueTotalHash {
        return 0;
    }
    equals(): boolean {
        return false;
    }
}

export function registerTransformerSLDRuntime() {
    return new SolverObjectInstance({
        solverIdentifier: 'attach-transformer-sld-viewer-property',
        objectsDefaultArgs: {
            propsInOut: new TransformerProps({})
        },
        objectsIdentifier: TransformerIdent,
        solverFunction: (props) => {
            props.propsInOut.singleLineDiagramViewer = new TransformerSLDViewerProperty()
            return {}
        }
    });
}
