import type { Bim, Catalog, LvWiringConfig} from 'bim-ts';
import { GaugePack, LvWiringConfigType } from 'bim-ts';
import type {
    PollWithVersionResult,
    TasksRunner} from 'engine-utils-ts';
import {
    Failure,
    LazyBasic,
    ScopedLogger,
} from 'engine-utils-ts';
import { _arrangeLvWiring } from 'layout-service';
import type { Cable, LowVoltageCables } from './low-voltage-cables';
import type { Circuit } from './models/circuit';
import { createCircuitFromBim, parseCircuitToCables } from './utils';
import { type UiBindings, NotificationDescription, NotificationType } from 'ui-bindings';
import { notificationSource } from '../Notifications';
import { getAllTransformerIds } from './create/bim-operations';
import { Matrix4 } from 'math-ts';
import { groupRoutes } from './route-grouping';
import type { Route } from './models/route';

export class CircuitHub extends LazyBasic<Circuit | null> {
    constructor(
        private readonly bim: Bim,
        private readonly lwCables: LowVoltageCables,
        private readonly catalog: Catalog,
    ) {
        super('circuit hub', null);
    }
    pollWithVersion(): PollWithVersionResult<Circuit | null> {
        return {
            value: this.poll(),
            version: this.version(),
        };
    }

    private updateLwCableCollection(cables: Cable[]) {
        this.lwCables.deleteAllExcept(new Set(), {});
        this.lwCables.allocate(cables.map(x => [
            this.lwCables.idsProvider.reserveNewId(),
            x,
        ]));
    }

    private getTransformerIds() {
        const configLazy = this.bim.configs.getLazySingletonOf({ type_identifier: LvWiringConfigType })
        const config = configLazy.poll().get<LvWiringConfig>();
        let trackerIds = config.transformers.value;
        if (!trackerIds.length) {
            trackerIds = Array.from(getAllTransformerIds(this.bim));
        }
        return trackerIds.filter(x => this.bim.instances.spatialHierarchy
            ._allObjects.get(x)?.children?.length);
    }

    async calculate(
        taskRunner: TasksRunner,
        uiBindings: UiBindings,
    ) {
        const gaugePack = new GaugePack(this.catalog);
        const self = this;
        const task = taskRunner
            .newLongTask<[circuit: Circuit[], cables: Cable[][]]>({
                defaultGenerator: (function*(bim: Bim) {
                    const transformers = self.getTransformerIds();
                    let circuits: Circuit[] = [];
                    let cablesArray: Cable[][] = [];
                    for (const id of transformers) {
                        const circuit = yield* createCircuitFromBim(bim, gaugePack, [id]);
                        const cables = yield* parseCircuitToCables(circuit, gaugePack);
                        circuits.push(circuit);
                        cablesArray.push(cables);
                        const rotateMatrix = new Matrix4().makeRotationZ(circuit.bimOps.globalTrackerAngle);

                        for (const config of circuit.bimOps.trackerConfigs.values()) {
                            const tracker = config.tracker;
                            tracker.hints = tracker.hints.map(x => x.clone())
                            tracker.hints.forEach(x => x.applyMatrix4(rotateMatrix));
                            tracker.maxPoint = tracker.maxPoint.clone();
                            tracker.maxPoint.applyMatrix4(rotateMatrix)
                            tracker.minPoint = tracker.minPoint.clone();
                            tracker.minPoint.applyMatrix4(rotateMatrix)
                        }

                        for (const x of cables) {
                            if (!x.polyline) {
                                continue;
                            }
                            x.polyline = x.polyline.map(x => x.clone())
                            x.polyline.forEach(x => x.applyMatrix4(rotateMatrix));
                        }
                        for (const x of circuit.connections.items.values()) {
                            let routes: Route[] = [];

                            if (x.route.isPairingSet) {
                                routes = x.route.pairing.routes;
                            } else {
                                routes = [x.route];
                            }
                            for (const route of routes) {
                                route.points = route.points.map(x => x.clone())
                                route.points.forEach(x => x.applyMatrix4(rotateMatrix));
                            }
                        }
                        for (const x of circuit.nodes.items.values()) {
                            if (x._actualPosition) {
                                x._actualPosition = x._actualPosition.clone();
                                x._actualPosition.applyMatrix4(rotateMatrix);
                            }
                            if (x.hints) {
                                x.hints = x.hints.map(x => x.clone())
                                x.hints.forEach(x => x.applyMatrix4(rotateMatrix));
                            }

                        }
                    }

                    return [circuits, cablesArray];
                })(this.bim),
                taskTimeoutMs: 600_000,
            });
        const bim = this.bim;
        try {
            uiBindings.addNotification(
                NotificationDescription.newWithTask({
                    source: notificationSource,
                    key: 'lowVoltage',
                    taskDescription: { task },
                    type: NotificationType.Info,
                    addToNotificationsLog: true
                }),
            );
            const [circuits, cableArray] = await task.asPromise();
            const [circuit, ...otherCircuits] = circuits;
            for (const otherCircuit of otherCircuits) {
                circuit.nodes.add(Array.from(otherCircuit.nodes.items.values()))
                for (const conn of otherCircuit.connections.items.values()) {
                    circuit.connections.add(conn);
                }
                for (const config of otherCircuit.bimOps.trackerConfigs) {
                    circuit.bimOps.trackerConfigs.set(...config);
                }
            }
            circuit.bimOps._globalTrackerAngle = 0;
            await taskRunner.newLongTask({ defaultGenerator: groupRoutes(circuit) }).asPromise();
            this.forceUpdate(circuit);
            this.updateLwCableCollection(cableArray.flat());
            await Promise.resolve();
            const cablesPairs = this.lwCables.readAll();
            const logger = new ScopedLogger('lv-wiring-scene-gen');
            for (const circuit of circuits.slice(0, 1)) {
                const root = Array.from(circuit.nodes.roots)[0];
                if (!root || !root.si)
                    throw new Error('substation is not found');
                await taskRunner
                    .newLongTask({
                        defaultGenerator: _arrangeLvWiring(cablesPairs, root.si.id, bim, logger),
                        taskTimeoutMs: 600_000,
                    }).asPromise();
            }

        } catch (e) {
            const taskResult = task.asResult();
            let errorMessage: string | undefined = undefined;
            if (!(taskResult instanceof Failure)) {
                errorMessage = e.message;
            } else {
                errorMessage = taskResult.errorMsg();
            }
            uiBindings.addNotification(
                NotificationDescription.newBasic({
                    source: notificationSource,
                    key: 'lowVoltageError',
                    descriptionArg: errorMessage,
                    type: NotificationType.Error,
                    removeAfterMs: 5_000,
                    addToNotificationsLog: true
                }),
            );
        }
    }
}

