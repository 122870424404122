import type { OidcClientSettings } from 'oidc-client';
import { UserManager } from 'oidc-client';
//@ts-ignore
import { UserInfoService } from 'oidc-client/src/UserInfoService';
import * as constants from './constants';

export class LoginGuard {
    _userManager: UserManager;
    _userInfoService: UserInfoService;
    _config: OidcClientSettings;

    constructor() {
        const oidcClientSettings: OidcClientSettings = {
            ...constants.OIDC_CONFIG,
            authority: env.authorithy,
        }
        this._config = oidcClientSettings;
        this._userInfoService = new UserInfoService(oidcClientSettings);
        this._userManager = new UserManager(oidcClientSettings);
        this._handleUserLoaded = this._handleUserLoaded.bind(this);
        this._handleUserUnloaded = this._handleUserUnloaded.bind(this);
        this._handleAccessTokenExpiring = this._handleAccessTokenExpiring.bind(this);
        this._handleAccessTokenExpired = this._handleAccessTokenExpired.bind(this);
        this._handleSilentRenewError = this._handleSilentRenewError.bind(this);
        this._handleUserSignedIn = this._handleUserSignedIn.bind(this);
        this._handleUserSignedOut = this._handleUserSignedOut.bind(this);
        this._handleUserSessionChanged = this._handleUserSessionChanged.bind(this);
        this._onInit();
    }

    _onInit() {
        const currentPath = window.location.href;
        if (currentPath.includes(
            constants.SIGNUP_REDIRECT_CALLBACK
        )) {
            if (!this._config.authority || !this._config.client_id) {
                document.location.replace('/bad');
                return
            }
            const url = this._config.authority + "/protocol/openid-connect/registrations";
            const params = new URLSearchParams()
            params.set("client_id", this._config.client_id);
            params.set('redirect_uri', document.location.origin)
            params.set('response_type', "code")
            const redirectToRegistration = url + "?" + params
            document.location.replace(redirectToRegistration);
        } else if (currentPath.includes(
            constants.LOGIN_REDIRECT_CALLBACK_SUCCESS
        )) {
            this._handleLoginRedirect();
        } else if (currentPath.includes(
            constants.LOGIN_SILENT_REDIRECT_CALLBACK_SUCCESS
        )) {
            this._handleSilentLoginRedirect();
        } else if (currentPath.includes(
            constants.LOGOUT_REDIRECT_CALLBACK
        )) {
            this._handleLogoutRedirect();
        }
    }

    logout() {
        this._userManager.signoutRedirect();
    }

    async checkLogin() {
        try {
            await this._userManager.signinSilent();
            const user = await this._userManager.getUser()
            if (!user) {
                throw new Error('user not found');
            }
            const profile = await this._userInfoService.getClaims(user.access_token);
            if (!profile) {
                throw new Error('user profile not loaded');
            }
            user.profile = profile;
            return user;
        } catch (e) {
            localStorage.removeItem('previousProject');
            await this._userManager.removeUser();
            await this._userManager.signinRedirect({
                state: {
                    fromUrl:
                        document.location.pathname +
                        document.location.search,
                },
            });
            // code should not go till here
            throw new Error(e);
        }

    }

    ////////////////////////
    // REDIRECT CALLBACKS //
    ////////////////////////

    _handleLogoutRedirect() {
        document.location.replace('/dashboard');
    }

    _handleSilentLoginRedirect() {
        this._userManager.signinSilentCallback();
    }

    _handleLoginRedirect() {
        this._userManager.signinCallback().then(user => {
            return window.location.replace(
                user.state.fromUrl ?? "/dashboard"
            );
        });
    }


    ////////////////////
    // OIDC CALLBACKS //
    ////////////////////

    _handleUserLoaded() {
        console.log('addUserLoaded')
    }

    _handleUserUnloaded() {
        console.log('userUnloaded')
    }

    _handleAccessTokenExpiring() {
        console.log('accessTokenExpiring')
    }

    _handleAccessTokenExpired() {
        console.log('accessTokenExpired')
    }

    _handleSilentRenewError() {
        console.log('silentRenewError')
    }

    _handleUserSignedIn() {
        console.log('userSignedIn')
    }

    _handleUserSignedOut() {
        console.log('userSignedOut')
    }

    _handleUserSessionChanged() {
        console.log('userSessionChanged')
    }

}
