<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <mask
        id="mask0_382_6822"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
    >
        <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_382_6822)">
        <path
            d="M3.92029 13.5884C3.50049 13.5884 3.14276 13.4406 2.84711 13.1449C2.55145 12.8493 2.40363 12.4915 2.40363 12.0717V9.82859H3.92029V12.0717H12.0717V9.82859H13.5884V12.0717C13.5884 12.4915 13.4406 12.8493 13.1449 13.1449C12.8493 13.4406 12.4916 13.5884 12.0717 13.5884H3.92029ZM8.00001 10.555L4.47536 7.03439L5.54023 5.94418L7.24168 7.64961V2.29199H8.75834V7.64961L10.4598 5.94418L11.5247 7.03439L8.00001 10.555Z"
            fill="currentColor"
        />
    </g>
</svg>
