import type { LazyVersioned, ResultAsync } from "engine-utils-ts";
import { LazyDerivedAsync } from "engine-utils-ts";
import { type Bim, NumberProperty } from "src";
import { create_ElectricalSubtotal_CombinerBoxs } from "./combiners";
import { create_ElectricalSubtotal_SectionalizingCabinets } from "./sc";
import type { CostComponentsNonNullable, CostsConfigProvider, EstimateCost} from "src/cost-model/capital";
import { CostHierarchy, ElectricalTopCategoryName, createEmptyCostComponentsNonNullable, fillModelBasedTopLevelCategory } from "src/cost-model/capital";
import { create_ElectricalSubtotal_Electrical_Cable } from "./electrical-cables";
import { create_Electrical_Trenching } from "./trenching";
import { create_EndOfRow } from "./end-of-row";

export function create_ElectricalSubtotal(
    bim: Bim,
    costs: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>,
) : LazyVersioned<ResultAsync<CostHierarchy>>
{
    const electricalCables = create_ElectricalSubtotal_Electrical_Cable(bim, costs, totalDC);
    const combiners = create_ElectricalSubtotal_CombinerBoxs(bim, costs, totalDC);
    const sc = create_ElectricalSubtotal_SectionalizingCabinets(bim, costs, totalDC);
    const trenching = create_Electrical_Trenching(bim, costs, totalDC);
    const endOfRow = create_EndOfRow(bim, costs, totalDC);

    const electricalSubtotal = LazyDerivedAsync.new7<
        CostHierarchy,
        { lv: CostHierarchy, mv: CostHierarchy },
        CostHierarchy,
        CostHierarchy,
        CostHierarchy,
        CostHierarchy,
        EstimateCost[],
        NumberProperty
    >(
        'electrical subtotal',
        [bim.unitsMapper],
        [electricalCables, combiners, sc, trenching, endOfRow, costs.allEstimateCosts, totalDC],
        function* ([electricalCables, combiners, sc, trenching, endOfRow, estimates, totalDC]) {
            const hierarchy = new CostHierarchy().merge(electricalCables.lv, endOfRow, combiners, electricalCables.mv);

            //{
            //    const costId = 'mv-electrical-cables-misc';
            //    const overrides = estimates.find(x => x.id.value === costId);
            //    const state: SingleEstimateProvider = {
            //        id: costId,
            //        defaults: createEmptyCostComponentsNonNullable(),
            //        value: overrides,
            //        update: (fn) => costs.findAndUpdateEstimateCost(costId, fn),
            //    }
            //    hierarchy.add(createMiscCategory(state, bim.unitsMapper, totalDC, { name: 'MV Elect Cable' }))
            //}

            hierarchy.merge(sc, trenching);

            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'Commisioning & Testing' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.laborCost = NumberProperty.new({ value: 0.001 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'commisioning-testing-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'SCADA' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.laborCost = NumberProperty.new({ value: 0.001 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'scada-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'Electrical General Conditions' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.laborCost = NumberProperty.new({ value: 0.001 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'Electrical General Conditions Benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'Electrical Logistics & Misc.' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.laborCost = NumberProperty.new({ value: 0.01 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'electrical-logistics',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }


            // create root category
            {
                const root = hierarchy.addRoot({ description: { value: ElectricalTopCategoryName } });
                hierarchy.categoryWithSceneInstances.add(root[0])
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.materialCost = NumberProperty.new({ value: 0.12 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    hierarchy,
                    'electrical-subtotal-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
            }

            return hierarchy;
        }
    );

    return electricalSubtotal;
}

export function getElectricalSubtotalBenchmardDefault(): CostComponentsNonNullable {
    const result: CostComponentsNonNullable = {
        equipmentCost: NumberProperty.new({ value: 0, unit: 'usd' }),
        laborCost: NumberProperty.new({ value: 0, unit: 'usd' }),
        laborTimeUnitCost: NumberProperty.new({ value: 0, unit: 'usd' }),
        laborTimeUnits: NumberProperty.new({ value: 0, unit: 'usd' }),
        serviceCost: NumberProperty.new({ value: 0, unit: 'usd' }),
        materialCost: NumberProperty.new({ value: 0, unit: 'usd' }),
    };

    return result;
}
