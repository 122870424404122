<script lang="ts">
    import flash from "../../flash";
    import { Hierarchy } from "../../../libui/icon";
    import SelectedItem from "./SelectItem.svelte";
    import {
        getPluralDisplayName,
        type DisplayItem,
        SelectObjectsStore,
    } from "../SelectObjectsUtils";
    import type { ItemErrorMsg } from "ui-bindings";
    import {
        ObjectUtils,
        type LazyVersioned,
        StringUtils,
        LazyBasic,
        IterUtils,
        LazyDerived,
    } from "engine-utils-ts";
    import { VersionedStore } from "../../../VersionedStore";
    import VirtualList from "svelte-tiny-virtual-list";
    import ErrorMessage from "./ErrorMessage.svelte";

    export let name: string;
    export let store: SelectObjectsStore;
    export let defaultMessage: string = "No instances selected";
    export let customSelectedItemsMessage: ((selected: number[], allItems: number[]) => string) | undefined = undefined;
    export let hasError: LazyVersioned<Map<number, ItemErrorMsg>> | undefined = undefined;
    export let hasErrorInGroups:
        | LazyVersioned<Map<string, ItemErrorMsg>>
        | undefined = undefined;

    $: errorsStore = hasError
        ? new VersionedStore<Map<number, ItemErrorMsg>>(hasError)
        : undefined;
    $: itemsErrors = errorsStore ? $errorsStore : undefined;

    $: errorsInGroupStore = hasErrorInGroups
        ? new VersionedStore<Map<string, ItemErrorMsg>>(hasErrorInGroups)
        : undefined;
    $: errorsInGroup = errorsInGroupStore ? $errorsInGroupStore : undefined;

    const isOpenLazy = new LazyBasic("is-open-versioned", false);
    let div: HTMLElement;
    $: items = new VersionedStore(store.selectedMap);

    let preValue = store.selectedMap.poll();
    $: if (!ObjectUtils.areObjectsEqual(preValue, $items)) {
        preValue = $items;
        flash(div);
    }

    $: allItems = new VersionedStore<DisplayItem[]>(store.viewList);

    const displayListLazy = LazyDerived.new2<DisplayItem[], boolean, DisplayItem[]>(
        "display-list",
        null,
        [isOpenLazy, store.viewList],
        ([isOpen, allItems], prevValue) => {
            if (isOpen) {
                const prevListItemsIds = prevValue
                    ? new Set<number>(prevValue.map(i => i.id))
                    : new Set<number>();
                const newDisplayList = IterUtils.filterMap(allItems, (i) => {
                    if (prevListItemsIds.has(i.id) || i.value) {
                        return i;
                    }
                    return;
                });

                return newDisplayList;
            } else {
                return allItems.filter(i => i.value);
            }
        }
    );

    $: displayList = new VersionedStore<DisplayItem[]>(displayListLazy);
    $: isOpen = new VersionedStore<boolean>(isOpenLazy);

    const openHierarchy = () => {
        isOpenLazy.replaceWith(!isOpenLazy.poll());
    };

    $: displayType = StringUtils.capitalizeFirstLatterInWord(
        getPluralDisplayName(store.types)
    );
    $: customMessage = customSelectedItemsMessage ? customSelectedItemsMessage(Array.from($items.keys()), Array.from($allItems.keys())) : undefined;
    $: collapsedMessage = customMessage ? customMessage : `${$items.size} ${displayType}`;

    $: noItemsSelectedMessage = customMessage ? customMessage : defaultMessage;

    const itemHeightPx = 24;
    const maxCount = 10;
    $: itemsCount = $displayList.length;
    $: closedHeight = itemHeightPx;
    $: openedHeight =
        itemsCount > maxCount
            ? (maxCount - 0.5) * itemHeightPx
            : itemsCount * itemHeightPx;
    $: groupError = errorsInGroup?.get(name);
</script>

<div class="ui-config-property property-row">
    <div class="property-name text-main-medium" on:click={openHierarchy}>
        <span
            class="hierarchy-holder property-name-label mdc-typography--body1"
        >
            {#if itemsCount > 1}
                <div class="hierarchy-icon">
                    <Hierarchy isOpen={$isOpen} />
                </div>
            {/if}
            <span bind:this={div}>
                {name}
            </span>
        </span>
    </div>

    <div class="property-value value-holder">
        <div
            class="mdc-typography--body1 read-only calculated actions-property scene-items-list"
            class:has-scroll={itemsCount > maxCount}
        >
            {#if itemsCount == 0}
                <span>{noItemsSelectedMessage}</span>
            {:else if $isOpen || itemsCount === 1}
                <VirtualList
                    height={openedHeight}
                    itemCount={itemsCount}
                    itemSize={itemHeightPx}
                >
                    <div
                        class="virtual list"
                        slot="item"
                        let:index
                        let:style
                        {style}
                    >
                        {@const item = $displayList[index]}
                        <SelectedItem
                            name={item.displayName}
                            value={item.value}
                            onClickCheckbox={(isSelected) => {
                                let changedItems = Array.from($items.values());
                                if (isSelected) {
                                    changedItems.push({
                                        value: item.id,
                                        readonly: item.isReadonly,
                                    });
                                } else {
                                    changedItems = changedItems.filter(
                                        (i) => i.value != item.id,
                                    );
                                }
                                store.updateSelected(changedItems);
                            }}
                            readonly={item.isReadonly}
                            danger={!!itemsErrors?.get(item.id)}
                        >
                            <slot {item} isError={!!itemsErrors?.get(item.id)}></slot>
                        </SelectedItem>
                    </div>
                </VirtualList>
            {:else}
                <VirtualList
                    height={closedHeight}
                    itemCount={1}
                    itemSize={closedHeight}
                >
                    <div class="virtual list collapsed-value" slot="item" let:style {style}>
                        <SelectedItem
                            name={collapsedMessage}
                            value={true}
                            onClickCheckbox={() => {
                                store.updateSelected([]);
                            }}
                            danger={!!groupError}
                        >
                            <slot
                                name="selected"
                                ids={Array.from($items.keys())}
                            ></slot>
                        </SelectedItem>
                    </div>
                </VirtualList>
            {/if}
            {#if groupError}
                <ErrorMessage message={groupError} />
            {/if}
        </div>
    </div>
</div>

<style lang="scss">
    ::placeholder {
        color: var(--color-main-light);
        opacity: 1;
    }

    .hierarchy-holder {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: fit-content;
    }

    .value-holder {
        align-items: center;
    }

    .hierarchy-icon {
        display: flex;
        align-items: center;
        height: 18px;
    }

    .scene-items-list :global(.virtual-list-wrapper) {
        overflow-x: hidden;
        box-sizing: content-box;
    }
    .has-scroll :global(.tag) {
        padding-right: 8px;
    }
    .property-row {
        position: relative;
    }
</style>
