import type { NonMethodsOnly } from 'engine-utils-ts';
import { IterUtils } from 'engine-utils-ts';
import type { CustomPropertySerializer} from '../CustomPropsRegistry';
import { CustomPropsRegistry } from '../CustomPropsRegistry';
import type { PropValueTotalHash } from '../Props';
import { PropertyBase } from '../Props';
import { getPropertyReferenceId } from '../PropsRefsIds';

export class BasicSpreadsheetCellsProperty extends PropertyBase {

    constructor(
        public readonly rows: (number | string)[][],
    ) {
        super();
    }

    uniqueValueHash(): PropValueTotalHash {
        return getPropertyReferenceId(this);
    }
    
    equals(other: PropertyBase): boolean {
        if (!(other instanceof BasicSpreadsheetCellsProperty)) {
            return false;
        }
        return IterUtils.areArraysEqual(
            this.rows,
            other.rows,
            IterUtils.areArraysEqual
        );
    }
}

class BasicSpreadsheetCellsPropertySerializer implements CustomPropertySerializer<BasicSpreadsheetCellsProperty> {
    currentFormatVersion: number = 0;

    serializeToString?(p: BasicSpreadsheetCellsProperty): string {
        return JSON.stringify(p);
    }
    deserializeFromString?(formatVersion: number, serialized: string): BasicSpreadsheetCellsProperty {
        const deserialized = JSON.parse(serialized) as NonMethodsOnly<BasicSpreadsheetCellsProperty>;
        return new BasicSpreadsheetCellsProperty(deserialized.rows);
    }

}


CustomPropsRegistry.register({
    class: BasicSpreadsheetCellsProperty,
    serializerClass: BasicSpreadsheetCellsPropertySerializer,
    constructFromPartial: (params: Partial<BasicSpreadsheetCellsProperty>) => {
        return new BasicSpreadsheetCellsProperty(params.rows || []);
    },
});
