import type {
    EventStackFrame} from 'engine-utils-ts';
import { IterUtils, ObservableStream, unsafePopFromEventStackFrame, unsafePushToEventStackFrame
} from 'engine-utils-ts';
import type { EntityId} from 'verdata-ts';
import { entityTypeFromId, splitIdsPerType, splitIdsTuplesPerType } from 'verdata-ts';

import type { BimCollection, BimCollectionPatch } from './BimCollection';
import type { CollectionReferenced, EntitiesBase } from './EntitiesBase';
import type { EntitiesCollectionUpdates } from './EntitiesCollectionUpdates';

// CT - CommonType, CVT - CommonViewType, ET - EntityType enum
// combines multiple entities collection in polymorphism-like way
// specific types separated via non-overlapping id ranges from IdsProvider
export class PolyEntitiesBase<CT, IdT extends number>
    implements BimCollection<CT, IdT>
{
    readonly entitiesByType: Map<IdT, EntitiesBase<CT, IdT>>;

	updatesStream: ObservableStream<EntitiesCollectionUpdates<EntityId<IdT>, number>>;

    constructor(
        identitifer: string,
        subtypesCollections: EntitiesBase<CT, IdT>[],
    ) {
        this.entitiesByType = IterUtils.newMapFromIter(subtypesCollections, c => c.idsType as IdT, c => c);

        this.updatesStream = new ObservableStream({
			identifier: `${identitifer}-updates-stream`,
		});
        for (const c of subtypesCollections) {
            this.updatesStream.mergeFrom(c.updatesStream);
        }
    }

    dispose() {
		this.updatesStream.dispose();
	}

    _addExternalRefsFrom(cr: CollectionReferenced<any, any>): void {
		for (const c of this.entitiesByType.values()) {
			c._addExternalRefsFrom(cr);
		}
	}

    getCollectionByType<T>(type: IdT) {
        return this.entitiesByType.get(type) as unknown as EntitiesBase<T, IdT, any, any>;
    }

    getCollectinoOfId<T>(id: EntityId<IdT>) {
        const ty = entityTypeFromId(id);
        return this.entitiesByType.get(ty) as unknown as EntitiesBase<T, IdT, any, any>;
    }

	*allIds(): IterableIterator<EntityId<IdT>> {
		for (const coll of this.entitiesByType.values()) {
			yield *coll.allIds();
		}
	}
	peekByIds(ids: Iterable<EntityId<IdT>>): Map<EntityId<IdT>, CT> {
		const res = new Map();
		const perTypeIds = splitIdsPerType(IterUtils.asArray(ids));
		for (const [ty, ids] of perTypeIds) {
			const coll = this.entitiesByType.get(ty);
			if (coll) {
				for (const id of ids) {
					const obj = coll.perId.get(id);
					if (obj !== undefined) {
						res.set(id, obj);
					}
				}
			} else {
				console.error('unexpected ids type', ty);
			}
		}
		return res;
	}
    peekById(id: EntityId<IdT>): Readonly<CT> | undefined {
        const ty = entityTypeFromId(id);
        const collection = this.entitiesByType.get(ty);
        return collection?.peekById(id);
    }

    applyCollectionPatch(diff: BimCollectionPatch<CT, IdT, CT>, eventParams?: Partial<EventStackFrame>) {
		if (diff.toDelete.length) {
			this.delete(diff.toDelete, eventParams);
		}
		if (diff.toPatch.length) {
			this.applyPatches(diff.toPatch, eventParams);
		}
		if (diff.toAlloc) {
			this.allocate(diff.toAlloc, eventParams);
		}
	}

    allocate(argsPerObject: [EntityId<IdT>, Partial<CT>][], event?: Partial<EventStackFrame>): EntityId<IdT>[] {
        if (argsPerObject.length == 0) {
            return [];
        }
        const allocatedIds: EntityId<IdT>[] = [];
        const e = event ? unsafePushToEventStackFrame(event) : null;
        try {
            const perT = splitIdsTuplesPerType(argsPerObject);
            for (const [ty, tuples] of perT) {
                const se = this.entitiesByType.get(ty)!;
                const perTyAllocated = se.allocate(tuples);
                IterUtils.extendArray(allocatedIds, perTyAllocated);
            }
        } finally {
			if (e) { unsafePopFromEventStackFrame(e); }
		}
        return allocatedIds;
    }

    clone(ids: EntityId<IdT>[]): EntityId<IdT>[] {
        const perT = splitIdsPerType(ids);
        const allocatedIds: EntityId<IdT>[] = [];
        for (const [ty, ids] of perT) {
            const se = this.entitiesByType.get(ty)!;
            const perTyAllocated = se.clone(ids);
            IterUtils.extendArray(allocatedIds, perTyAllocated);
        }
        return allocatedIds;
    }

    delete(ids: EntityId<IdT>[], event?: Partial<EventStackFrame>)
        : readonly [EntityId<IdT>, Readonly<CT>][]
    {
		if (ids.length == 0) {
			return [];
        }
        const removed: [EntityId<IdT>, Readonly<CT>][] = [];
        const e = event ? unsafePushToEventStackFrame(event) : null;
        try {
            const perT = splitIdsPerType(ids);
            for (const [ty, ids] of perT) {
                const se = this.entitiesByType.get(ty)!;
                const deleted = se.delete(ids);
                IterUtils.extendArray(removed, deleted);
            }
        } finally {
			if (e) { unsafePopFromEventStackFrame(e); }
		}
        return Object.freeze(removed);
    }
    
    applyPatchTo(patch: CT, ids: EntityId<IdT>[], event?: Partial<EventStackFrame>): void {
		const perIdPatch: [EntityId<IdT>, CT][] = Array.from(ids).map(id => [id, patch]);
        this.applyPatches(perIdPatch, event);
	}
	applyPatches(perId: [EntityId<IdT>, CT][], event?: Partial<EventStackFrame>): void {
        const e = event ? unsafePushToEventStackFrame(event) : null;
        try {
			const perT = splitIdsTuplesPerType(perId);
            for (const [ty, tuples] of perT) {
                const se = this.entitiesByType.get(ty)!;
                se.applyPatches(tuples);
            }
		} finally {
			if (e) { unsafePopFromEventStackFrame(e); }
		}
    }

    reserveNewIdForType(ty: IdT) {
        const e = this.entitiesByType.get(ty)!;
        return e.reserveNewId();
    }
}
