<script lang="ts">
    import { getContext } from "svelte";
    import { PUI_SceneInstancesSelectorPropertyNode } from "ui-bindings";

    import SelectedItemsList from "./common/SelectedItems.svelte";
    import SelectSceneItemActions from "./common/SelectItemsActions.svelte";
    import { getPluralDisplayName, VersionsStore } from "./SelectObjectsUtils";
    import { VerDataSyncer } from "verdata-ts";

    export let property: PUI_SceneInstancesSelectorPropertyNode;

    const vds = getContext<VerDataSyncer>("verDataSyncer");

    $: {
        itemsStore.selected.replaceWith(property.value);
    }

    const itemsStore = new VersionsStore({
        projectVerdataSyncer: vds,
        types: property.types,
        selected: property.value,
        updateSelected: (selected) => {
            property.value = selected;
        },
    });

    $: displayName = getPluralDisplayName(property.types);
</script>

<div class="body-container">
    <SelectedItemsList
        name={property.name}
        store={itemsStore}
        defaultMessage={`No ${displayName} selected`}
        hasError={property.hasItemsError}
        hasErrorInGroups={property.hasErrorInGroups}
        customSelectedItemsMessage={property.customSelectedItemsMessage}
        let:item
    >
        <span class:text-main-light={!item.tag}>
            {item.tag || "No Description"}
        </span>
    </SelectedItemsList>
</div>
<SelectSceneItemActions store={itemsStore} />

<style lang="scss">
    .body-container :global(.list:not(.collapsed-value) .name),
    :global(.mdc-menu .version-list .virtual-list-wrapper .name) {
        width: 72px;
        flex: none;
    }
    :global(.mdc-menu .version-list .virtual-list-wrapper .mdc-tooltip) {
        position: absolute;
        top: auto !important;
        left: 80px !important;
        bottom: 0;
        right: 8px;
        text-wrap: wrap;
        width: auto;
    }
    :global(.mdc-menu .version-list .mdc-tooltip__surface) {
        max-width: 500px;
    }
    :global(.mdc-menu .version-list .virtual-list-wrapper .rest-info .wrapper) {
        text-overflow: ellipsis;
        overflow: hidden;
    }
</style>
