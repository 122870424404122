<script lang="ts">
import { NumberProperty } from "bim-ts";
import NumAndUnitEditor from "./NumAndUnitEditor.svelte";

export let num: NumberProperty | null;
export let change: (newVal: null | NumberProperty) => void

function toggle() {
    if (num) {
        change(null);
    } else {
        change(NumberProperty.new({ unit: '', value: 0 }));
    }
}

function onChange(newVal: NumberProperty) {
    return change(newVal);
}

</script>

<input
    type="checkbox"
    checked={!!num}
    on:change={toggle}
/>

{#if num}
    <NumAndUnitEditor change={onChange} value={num}/>
{/if}

<style lang="scss">
</style>
