import { Root } from 'engine-ui';
import { findMappedPathFor, WorkerPool } from 'engine-utils-ts';

// Error.stackTraceLimit = 1000;

const workerScriptPath = findMappedPathFor('worker.js');

if (workerScriptPath) {
    WorkerPool.start(workerScriptPath);
} else {
    console.error('could not retreive worker.js script path');
}
// WorkerPool.importWorkerCodeFrom('./bim-ts.js');
// WorkerPool.importWorkerCodeFrom('./engine-in-worker.js');

const start = async function () {
	window.env = await fetch("/api/client-environment-variables").then(x => x.json());
	window.eui = new Root({
		target: document.getElementById("kreo-engine"),
	});
};

const devEnvs = ['solar-dev', 'solar-stage', 'localhost'];
const isDevEnv = devEnvs.some(env => location.hostname.includes(env));
window.IsDevEnv = isDevEnv;

if (isDevEnv) {
    const links = document.querySelectorAll("link[rel~='icon']");
    links.forEach(link => {
        link.href = link.href.replace('favicon', 'dev-favicon');
    });
}

start();

