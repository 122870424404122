import type { Bim, IdBimGeo, IdBimScene, RepresentationBase, TerrainHeightMapRepresentation, TerrainTileId} from 'bim-ts';
import {
	BasicAnalyticalRepresentation, BimPatch, ContourPolygonsType, ExtrudedPolygonGeometry,
	TerrainGeoVersionSelector,
	TerrainInstanceTypeIdent,
	calculatePolygons
} from 'bim-ts';
import type { LazyVersioned, UndoStack, Result} from 'engine-utils-ts';
import { ObservableObject, ObjectUtils, IterUtils, Success, LazyDerived, DefaultMap } from 'engine-utils-ts';
import type { Aabb} from 'math-ts';
import { Vector3 } from 'math-ts';
import type { MenuPath, PUI_GroupNode} from 'ui-bindings';
import { PUI_Builder} from 'ui-bindings';
import { EditActionResult } from 'ui-bindings';
import type { SceneInt } from '../scene/SceneRaycaster';
import type { EditModeControls } from './EditControls';
import { MouseButton } from './InputController';
import type { EditInteractionResult, InteractiveEditOperator } from './InteractiveEditOperator';
import type { MouseEventData } from './MouseGesturesBase';
import { type TerrainDisplayEngineSettings } from 'src/TerrainDisplayEngineSettings';


export class BoundaryByTerrainElevationSetting {
	min_elevation: number = -5000;
	max_elevation: number = 5000;
	ignore_areas_less_than: number = 0.405;
}

type InstancesDescr =  [IdBimScene, IdBimGeo, ExtrudedPolygonGeometry][];

interface BoundaryTerrainState {
	appliedSettings: BoundaryByTerrainElevationSetting | null;
	resultBoundariesAfterJoin: Vector3[][];
	resultObjects: InstancesDescr;
}


export class InteractiveBoundaryByTerrainElevation implements InteractiveEditOperator<BoundaryTerrainState, BoundaryByTerrainElevationSetting> {

	readonly menuPath: MenuPath = ['Add', 'Boundary', 'Based on the elevation of the terrain'];
	readonly priority: number = 1;
	readonly canStart: LazyVersioned<boolean>;
	readonly config: ObservableObject<BoundaryByTerrainElevationSetting>;
	readonly terrainDisplaySettings: ObservableObject<TerrainDisplayEngineSettings>;

	constructor(
		readonly bim: Bim,
		readonly editControls: EditModeControls,
		undoStack: UndoStack,
		terrainDisplaySettings: ObservableObject<TerrainDisplayEngineSettings>,
	) {
		this.canStart = LazyDerived.new1(
			'isTerrainExists',
			[],
			[bim.instances.getLazyListOf({ type_identifier: TerrainInstanceTypeIdent })],
			([s]) => s.length > 0
		);

		this.config = new ObservableObject({
			identifier: this.menuPath.join(),
			initialState: new BoundaryByTerrainElevationSetting(),
			undoStack,
			throttling: { onlyFields: [] }
		});

		this.terrainDisplaySettings = terrainDisplaySettings;
	}

	buildUi(patchCallback: (patch: Partial<BoundaryByTerrainElevationSetting>) => void): PUI_GroupNode {
		const terrain = this.bim.instances.peekByTypeIdent(TerrainInstanceTypeIdent)[0][1];
		const goemetriesAabbs = this.bim.allBimGeometries.aabbs.poll();
		const reprsBboxes = new DefaultMap<RepresentationBase, Aabb>(r => r.aabb(goemetriesAabbs));
		const terrainAabb = reprsBboxes.getOrCreate(terrain.representation!);
		
		const uiBuilder = new PUI_Builder({ sortChildrenDefault: false });
		uiBuilder.addNumberProp({
			name: "Slice min elevation",
			minMax: [terrainAabb.minz(), terrainAabb.maxz()],
			value: this.config.currentValue().min_elevation,
			step: 0.1,
			unit: "m",
			onChange: (newValue: number) => patchCallback({ min_elevation: newValue }),
		});
		uiBuilder.addNumberProp({
			name: "Slice max elevation",
			minMax: [terrainAabb.minz(), terrainAabb.maxz()],
			value: this.config.currentValue().max_elevation,
			step: 0.1,
			unit: "m",
			onChange: (newValue: number) => patchCallback({ max_elevation: newValue }),
		});
		uiBuilder.addNumberProp({
			name: "Ignore areas less than",
			minMax: [0, 100],
			value: this.config.currentValue().ignore_areas_less_than,
			step: 0.01,
			unit: "ha",
			onChange: (newValue: number) => patchCallback({ ignore_areas_less_than: newValue }),
		});
		return uiBuilder.finish();
	};


	start(): Result<BoundaryTerrainState> {
		let state: BoundaryTerrainState = {
			appliedSettings: null,
			resultBoundariesAfterJoin: [],
			resultObjects: [],
		};
		state = this._reconcileStateWithConfig(state);
		return new Success(state);
	}
	finish(state: Readonly<BoundaryTerrainState>) : EditActionResult | undefined {
		const ids = state.resultObjects.map(t => t[0]);
		this.bim.instances.setSelected(ids);
		return new EditActionResult(ids);
	}
	cancel(state: BoundaryTerrainState) {
		this.bim.instances.delete(state.resultObjects.map(t => t[0]));
	}
	handleConfigPatch(
		patch: Partial<BoundaryByTerrainElevationSetting>,
		state: Readonly<BoundaryTerrainState>
	): EditInteractionResult<BoundaryTerrainState> {
		const newState = this._reconcileStateWithConfig(state);
		return {
			done: false,
			state: newState
		};
	}

	onHover(int: SceneInt): { cursorStyleToSet: string; } | null {
		return null;
	}

	handleClick(
		sceneInt: SceneInt,
		me: MouseEventData,
		previousResult: BoundaryTerrainState
	): EditInteractionResult<BoundaryTerrainState> {
		if (me.buttons.mouseButton === MouseButton.Right) {
			return {
				state: previousResult,
				done: true,
			};
		}
		return {
			done: false,
			state: previousResult
		};
	}


	_reconcileStateWithConfig(prevState: Readonly<BoundaryTerrainState>): BoundaryTerrainState {
		const config = this.config.poll();
		let state: BoundaryTerrainState = {
			appliedSettings: prevState.appliedSettings,
			resultBoundariesAfterJoin: prevState.resultBoundariesAfterJoin,
			resultObjects: prevState.resultObjects,
		};

		if (state.appliedSettings === null || !ObjectUtils.areObjectsEqual(config, state.appliedSettings)) {
			state.appliedSettings = ObjectUtils.deepCloneObj(config);

			const terrain = this.bim.instances.peekByTypeIdent(TerrainInstanceTypeIdent)[0][1];
			const worldMatrix = terrain.worldMatrix;
			const terrainRepr = terrain.representation as TerrainHeightMapRepresentation;
			const terrainDisplaySettings = this.terrainDisplaySettings.poll();
			const tiles = new Map<TerrainTileId, IdBimGeo>(
				IterUtils.mapIter(terrainRepr.tiles, t => 
					[t[0], t[1].selectGeoId(TerrainGeoVersionSelector.Latest)]
				)
			);
			const boundariesPoints = calculatePolygons(
				ContourPolygonsType.Elevation,
				config.min_elevation, config.max_elevation, config.ignore_areas_less_than,
				this.bim.regularHeightmapGeometries, tiles
			);

			state.resultBoundariesAfterJoin = [];
			for (const points of boundariesPoints) {
				state.resultBoundariesAfterJoin.push(points.map(p => Vector3.fromVec2(p).applyMatrix4(worldMatrix)));
			}

			const objsToDeleteCount = state.resultObjects.length - state.resultBoundariesAfterJoin.length;
			if (objsToDeleteCount > 0) {
				const toDelete = state.resultObjects.splice(state.resultBoundariesAfterJoin.length);
				const ids = toDelete.map(t => t[0]);
				this.bim.instances.delete(ids);
			}

			const bimPatch = new BimPatch();

			for (let i = 0; i < state.resultBoundariesAfterJoin.length; ++i) {

				const boundaryPoints = state.resultBoundariesAfterJoin[i];
				const geometryFromBoundary = ExtrudedPolygonGeometry.newWithAutoIds(
					boundaryPoints.map(p => p.xy()),
					undefined,
					0,
					5
				);


				if (state.resultObjects[i] === undefined) {
					// allocate new
					const geoId = this.bim.extrudedPolygonGeometries.reserveNewId();
					const instanceId = this.bim.instances.reserveNewId();

					state.resultObjects[i] = [instanceId, geoId, geometryFromBoundary];

					bimPatch.geometries.toAlloc.push([geoId, geometryFromBoundary]);

					const instance = this.bim.instances.archetypes.newDefaultInstanceForArchetype('boundary');
					instance.representationAnalytical = new BasicAnalyticalRepresentation(geoId);
					bimPatch.instances.toAlloc.push([instanceId, instance]);

				} else {

					// patch exisings
					const [id, geoId, geo] = state.resultObjects[i];

					if (ObjectUtils.areObjectsEqual(geo, geometryFromBoundary)) {
						console.log('geo didnt change');
						continue;
					}

					state.resultObjects[i][2] = geometryFromBoundary;
					bimPatch.geometries.toPatch.push([geoId, geometryFromBoundary]);
				}
			}

			bimPatch.applyTo(this.bim);
			this.bim.instances.setSelected(state.resultObjects.map(o => o[0]));
		}

		return state;
	}
}



