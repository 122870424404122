import { produce as produceInner, setAutoFreeze, createDraft, finishDraft } from "immer";

setAutoFreeze(false);

export type IProduce = typeof produceInner;

export class Immer {
    static readonly produce: IProduce = produceInner;
    static readonly createDraft = createDraft;
    static readonly finishDraft = finishDraft;
}
