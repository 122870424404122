<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.20001 19V17.8002H3.50001C2.78204 17.8002 2.20001 17.2182 2.20001 16.5002V3.5002C2.20001 2.78223 2.78204 2.2002 3.50001 2.2002H8.20001V1H9.80001V19H8.20001ZM8.20001 10.6627V16.2002H3.80001V15.796L8.20001 10.6627ZM8.20001 8.20415V3.8002H3.80001V13.3375L8.20001 8.20415Z"
        fill="currentColor"
    />
    <path
        d="M16.5 17.8002H11.8V9.2041L16.2 14.3374V3.8002H11.8V2.2002H16.5C17.218 2.2002 17.8 2.78222 17.8 3.5002V16.5002C17.8 17.2182 17.218 17.8002 16.5 17.8002Z"
        fill="currentColor"
    />
</svg>
