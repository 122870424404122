// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { IdentifierVersion } from '../wire/identifier-version.js';
import { ProjectVersionMetric } from '../wire/project-version-metric.js';


export class ProjectVersionAdditionalContext {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ProjectVersionAdditionalContext {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsProjectVersionAdditionalContext(bb:flatbuffers.ByteBuffer, obj?:ProjectVersionAdditionalContext):ProjectVersionAdditionalContext {
  return (obj || new ProjectVersionAdditionalContext()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsProjectVersionAdditionalContext(bb:flatbuffers.ByteBuffer, obj?:ProjectVersionAdditionalContext):ProjectVersionAdditionalContext {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ProjectVersionAdditionalContext()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

versionPerIdentifier(index: number, obj?:IdentifierVersion):IdentifierVersion|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new IdentifierVersion()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

versionPerIdentifierLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

metrics(index: number, obj?:ProjectVersionMetric):ProjectVersionMetric|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new ProjectVersionMetric()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

metricsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startProjectVersionAdditionalContext(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addVersionPerIdentifier(builder:flatbuffers.Builder, versionPerIdentifierOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, versionPerIdentifierOffset, 0);
}

static createVersionPerIdentifierVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startVersionPerIdentifierVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addMetrics(builder:flatbuffers.Builder, metricsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, metricsOffset, 0);
}

static createMetricsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startMetricsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endProjectVersionAdditionalContext(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createProjectVersionAdditionalContext(builder:flatbuffers.Builder, versionPerIdentifierOffset:flatbuffers.Offset, metricsOffset:flatbuffers.Offset):flatbuffers.Offset {
  ProjectVersionAdditionalContext.startProjectVersionAdditionalContext(builder);
  ProjectVersionAdditionalContext.addVersionPerIdentifier(builder, versionPerIdentifierOffset);
  ProjectVersionAdditionalContext.addMetrics(builder, metricsOffset);
  return ProjectVersionAdditionalContext.endProjectVersionAdditionalContext(builder);
}
}
