import { type Bim } from 'bim-ts';
import { Yield } from 'engine-utils-ts';
import type { FileImporter, FileImporterContext, FileToImport } from 'ui-bindings';
import { decodeDem} from './DemReader';
import { convertTerrainElevationsGridToTerrainHeightmapInstance } from 'src/TerrainToRegularGridConverter';


export class DemImporter implements FileImporter {

    fileExtensions = ['.dem'];
    

    constructor(
        readonly bim: Bim
    ) {
    }

    *startImport(context: FileImporterContext, file: FileToImport): Generator<Yield, void> {
        const bim = this.bim;
        const buffer = file.fileArrayBuffer;

        const demData = decodeDem(context, buffer);
    
        const bimPatch = yield* convertTerrainElevationsGridToTerrainHeightmapInstance({
            context:context,
            bim: bim,
            elevationsInMeters: demData.elevations,
            pointsCountX: demData.poitsCountX,
            pointsCountY: demData.pointsCountY,
            minMaxCoords: [demData.minMaxCoords[0], demData.minMaxCoords[1], demData.minMaxCoords[3], demData.minMaxCoords[4]],
            isMinMaxGeo: false,
            pixelIsArea: false,
            projectionInfo: undefined,
            fileName: file.filename,
            sendNotification: context.sendNotification
        });

        const ids = bimPatch.applyTo(bim);

        bim.instances.setSelected(ids);
        yield* context.onFinish(ids);
    }
}
