import { Failure, FilesUtils, PollablePromise, ProjectNetworkClient, type Result} from "engine-utils-ts";
import type { Bim} from "../..";
import { BimPatch} from "../..";
import type { FileImporterContext} from "ui-bindings";
import { parseAssetOrigin, type BimAssetDescription } from './BimAssetDescription';
import { parseBimAsset } from './BimAssetParser';

export function* parseBimAssetLegacy(
    unzip: Map<string, Uint8Array>,
    bim: Bim,
    context?: FileImporterContext,
) {
    const network = context?.network;
    const logger = context?.logger;

    if (network) {
        // TODO: What is this code doing
        for (const [key, file] of unzip) {
            if (key.endsWith('.png') || key.endsWith('.webp')) {
                const pr = saveAssets(network, [{ name: key, asset: file }]);
                const bpResult: Result<void> = yield* PollablePromise.generatorWaitFor<void>(pr);
                if (bpResult instanceof Failure) {
                    logger?.error(`import failed`, bpResult);
                }
            }
        }
}
    const resultPatch = new BimPatch();
    for (const [key, file] of unzip) {
        if (key.includes('.json')) {
            const asStr = new TextDecoder().decode(file);
            const asObj = JSON.parse(asStr) as BimAssetDescription;
            
            const bimImport = parseBimAsset(asObj, bim);

            const batchPromise = bimImport.toBimPatch({network});

            const bpResult = yield* PollablePromise.generatorWaitFor(batchPromise);

            if (bpResult instanceof Failure) {
                throw new Error("Import failed");
            }
            const assetOrigin = parseAssetOrigin(asObj.origin);
            if (assetOrigin) {
                bpResult.value.sceneOrigin = assetOrigin
            }

            resultPatch.mergeWith(bpResult.value);
        }
    }
    return resultPatch;
}

// export function* make_AddAssetToBim_PatchesLegacy(
//     unzip: Map<string, Uint8Array>,
//     bim: Bim,
//     context?: FileImporterContext,
// ): Generator<Yield, BimPatch, unknown> {
//     const network = context?.network;
//     const logger = context?.logger;

//     if (network) {
//         // TODO: What is this code doing
//         for (const [key, file] of unzip) {
//             if (key.endsWith('.png') || key.endsWith('.webp')) {
//                 const pr = saveAssets(network, [{ name: key, asset: file }]);
//                 const bpResult: Result<void> = yield* PollablePromise.generatorWaitFor<void>(pr);
//                 if (bpResult instanceof Failure) {
//                     logger?.error(`import failed`, bpResult);
//                 }
//             }
//         }
//     }
//     const resultPatch = new BimPatch();
//     for (const [key, file] of unzip) {
//         if (key.includes('.json')) {
//             const asStr = new TextDecoder().decode(file);
//             const asObj = JSON.parse(asStr) as BimAssetDescription;
//             const patchResult = yield* makeFromBimAssetDescriptionPatchesToBim(asObj, bim, context, network);
//             resultPatch.mergeWith(patchResult);
//         }
//     }
//     return resultPatch;
// }


// export function* makeFromBimAssetDescriptionPatchesToBim(
//     asObj: BimAssetDescription, 
//     bim: Bim, 
//     context: FileImporterContext | undefined, 
//     network: ProjectNetworkClient | undefined
// ) {
//     const bimImport = parseBimAsset(asObj, bim);
//     const assetOrigin = parseAssetOrigin(asObj.origin);

//     const sceneOrigin = bim.instances.getSceneOrigin();

//     const compSettings = yield* requestOriginCompensationSettings({
//         context: context,
//         assetOrigin: assetOrigin,
//         sceneOrigin,        
//     });

//     const bimPatchPromise = bimImport.toBimPatch({
//         network: network,
//         bimOrigin: sceneOrigin,
//         assetOrigin: assetOrigin,
//         compensateOriginsDifference: compSettings.compensateOriginsDifferenceOnImport,
//         replaseProjectOrigin: compSettings.replaceProjectCoordinateSystem
//     });

//     const bpResult: Result<BimPatch> = yield* PollablePromise.generatorWaitFor<BimPatch>(bimPatchPromise);

//     if (bpResult instanceof Failure) {
//         throw new Error("Import failed");
//     }
//     return bpResult.value;
// }


export interface AssetDescription {
    name: string,
    asset: string | ArrayBuffer | Uint8Array
}

export async function saveAssets(
    network: ProjectNetworkClient,
    assets: AssetDescription[]
) {
    const _network = new ProjectNetworkClient({
        ...network.config,
        basePath: "/api/assets",
    });
    const formData = new FormData();
    for (const asset of assets) {
        formData.append(
            "file",
            new Blob([asset.asset]),
            FilesUtils.cleanedUpName(asset.name)
        );
    }
    await _network.postFormData('', formData);
}


