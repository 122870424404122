import { Vector2 } from "math-ts";
import { normalTextFontSize, wireWidth } from "src/sld/templates/config";
import { createTransformer as createTransformerBase } from "src/sld/templates/transformer";
import type { EquipmentDescription } from "src/sld/types";
import { VectorPrimitivePath, VectorPrimitivePathDescription, VectorPrimitiveText, createTextLabelWithPointer } from "vector-graphic";

export function createTransformer(desc: EquipmentDescription) {
    const transformer = createTransformerBase()

    // draw transformer label
    transformer.addPrimitiveAndExpandAabb(new VectorPrimitiveText({
        x: transformer.aabb.centerX(),
        y: transformer.aabb.max.y + 20,
        fontSize: normalTextFontSize,
        text: desc.text,
        anchor: 'middle',
        verticalAlignment: 'hanging',
    }))

    // draw output wire
    const pt1 = transformer.point.clone();
    const pt2 = new Vector2(pt1.x + 200, pt1.y);
    transformer.point.copy(pt2);
    transformer.addPrimitiveAndExpandAabb(new VectorPrimitivePath({
        paths: [new VectorPrimitivePathDescription([pt1, pt2])],
        strokeWidth: wireWidth
    }))

    // to substation text
    const toSubstationText = createTextLabelWithPointer({
        fontSize: 20,
        text: "MV Cable",
        offset: 40,
        pointerWidth: 3,
    })
    toSubstationText.position.copy(pt2);
    toSubstationText.updateMatrix();
    transformer.addAndExpandAabb(toSubstationText);

    return transformer;
}
