import { Vector2 } from 'math-ts';
import { AdditiveBlending } from '../3rdParty/three';
import type { ShaderBase } from './ShaderBase';


export const EdgeShader: ShaderBase = Object.freeze({
	
	name: 'edge',

	params: {
		transparent: true,
		blending: AdditiveBlending,
	},

	uniforms: {
		opacityMinMax: { value: new Vector2(0.5, 1.0) }
	},

	vertexShader:
	`
	#include <kr_std_attributes>
	#include <kr_std_uniforms>
	#include <kr_std_vars>

	
	void main() {
	
		#include <kr_std_vars_calc>
	}
	`,

	fragmentShader:
	`
	
	#include <kr_std_uniforms>
	#include <kr_std_vars>

	#include <packing>
	
	uniform vec2 opacityMinMax;

	void main() {

		#include <kr_std_pixel>

		float depth = pow(gl_FragCoord.z, 10.0);


		gl_FragColor = vec4( 1.0 /*mix(opacityMinMax.x, opacityMinMax.y, 1.0 - depth)*/ );
	
	}
	
	`,
});
