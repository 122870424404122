import type { Camera} from '../3rdParty/three';
import { PerspectiveCamera, OrthographicCamera } from '../3rdParty/three';
import { LegacyLogger } from 'engine-utils-ts';
import { MathUtils } from '../MathUtils';
import type { GeometryIntersection } from '../geometries/GeometryUtils';
import { GeometrySide } from '../geometries/GeometryUtils';
import type { Vector2 } from 'math-ts';
import { Vector3, Matrix3, Matrix4, Ray } from 'math-ts';
import type { KrCamera } from '../controls/MovementControls';


export class RaySection {
	
	ray: Ray = new Ray(Vector3.zero(), Vector3.zero());
	near: number = 0;
	far: number = 1;
	intersectGeometry!: (this: RaySection, positions: ArrayLike<number>, trisIndices: ArrayLike<number>, trisRange: IndicesRange, side:GeometrySide, matrixWorld: Matrix4, result: GeometryIntersection[]) => void;

	static fromCameraMousePosHtml(camera: KrCamera, posGlCoords: Vector2): RaySection | null {
		if (Math.abs(posGlCoords.x) > 1 || Math.abs(posGlCoords.y) > 1) {
			return null;
		}
		return new RaySection().setFromCamera(camera, posGlCoords.x, posGlCoords.y);
	}
	
	setFromCamera ( camera:Camera, x: number, y: number ) {

		if ( camera instanceof OrthographicCamera ) {

			this.ray.origin.copy(
				MathUtils.unproject(new Vector3(x, y, (camera.near + camera.far) / (camera.near - camera.far)), camera)
			); // set origin in plane of camera
			this.ray.direction.setFromMatrixColumn(camera.matrixWorld, 2).multiplyScalar(-1);

		} else if ( camera instanceof PerspectiveCamera ) {

			this.ray.origin.setFromMatrixColumn( camera.matrixWorld as any, 3 );
			this.ray.direction.copy(MathUtils.unproject(new Vector3(x, y, 1), camera).sub(this.ray.origin).normalize());

		} else {

			LegacyLogger.error( 'raySet: unkown camera type' );

		}
		this.near = camera.near;
		this.far = camera.far;
		return this;
	}

	copy(rhs: RaySection) {
		this.ray.copy(rhs.ray);
		this.near = rhs.near;
		this.far = rhs.far;
	}

	clone() {
		const rs = new RaySection();
		rs.ray = this.ray.clone();
		rs.near = this.near;
		rs.far = this.far;
		return rs;
	}
}

export interface IndicesRange {
    start: number;
    count: number;
}

RaySection.prototype.intersectGeometry = (function () {
	
	const inverseMatrix = new Matrix4();
	const normalMatrix = new Matrix3();
	const geometrySpaceRay = new Ray(Vector3.zero(), Vector3.zero());

	const vA = Vector3.zero();
	const vB = Vector3.zero();
	const vC = Vector3.zero();

	const intersPoint = Vector3.zero();

	const intersectionPointWorld = Vector3.zero();


	return function (this:RaySection, position: ArrayLike<number>, index: ArrayLike<number>, drawRange: IndicesRange, side:GeometrySide, matrixWorld: Matrix4, result: GeometryIntersection[]): void {

		// Checking boundingSphere distance to ray

		inverseMatrix.getInverse( matrixWorld );
		geometrySpaceRay.copy(this.ray);
		geometrySpaceRay.applyMatrix4(inverseMatrix);
		
		const start = Math.max( drawRange.start, 0 );
		const end = Math.min( index.length, ( drawRange.start + drawRange.count ) );

		for (let i = start; i < end; i += 3 ) {

			const a = index[i];
			const b = index[i + 1];
			const c = index[i + 2];
			
			vA.setFromArray( position, a * 3 );
			vB.setFromArray( position, b * 3 );
			vC.setFromArray( position, c * 3 );
			
			let point_t: Vector3 | null = null;
			if ( side === GeometrySide.BackSide ) {
				point_t = geometrySpaceRay.intersectTriangle( vC, vB, vA, true, intersPoint );
			} else {
				point_t = geometrySpaceRay.intersectTriangle( vA, vB, vC, side !== GeometrySide.DoubleSide, intersPoint );
			}
			if (point_t === null) {
				continue;
			};

			intersectionPointWorld.copy( point_t );
			intersectionPointWorld.applyMatrix4( matrixWorld );

			let distance = this.ray.origin.distanceTo( intersectionPointWorld );

			if (distance < this.near || distance > this.far) {
				continue;
			};

			let normal_t = MathUtils.getTriangleNormal_t(vA, vB, vC);
			
			// not correct, but the same as in the shaders
			normalMatrix.setFromMatrix4(matrixWorld as any);
			normal_t.applyMatrix3(normalMatrix);
			normal_t.multiplyScalar(Math.sign(normalMatrix.determinant()));

			result.push({
				distance: distance,
				point: intersectionPointWorld.clone(),
				normal: normal_t.clone()

			});

		}
	}
}());

// KrRaySection.prototype.intersectFEMGeometry = (function () {
	
// 	const vA = Vector3.allocateZero();
// 	const vB = Vector3.allocateZero();
// 	const vC = Vector3.allocateZero();
// 	const vOffset = Vector3.allocateZero();
// 	const intersectionPointWorld = Vector3.allocateZero();

// 	return function (this:KrRaySection, geometry: FEMGeometry, side: GeometrySide, result: GeometryIntersection[]): void {

// 		if (!geometry.activeOffsets) {
			
// 			const m4 = reusedKrMat4.identity();
// 			const posUnpack = geometry.positionNormalized.unpack;

// 			m4.elements[0] =  Uint16MaxValue / posUnpack.w;
// 			m4.elements[5] =  Uint16MaxValue / posUnpack.w;
// 			m4.elements[10] = Uint16MaxValue / posUnpack.w;
// 			m4.elements[12] = posUnpack.x;
// 			m4.elements[13] = posUnpack.y;
// 			m4.elements[14] = posUnpack.z;

// 			this.intersectGeometry(geometry, side, m4, result);
// 			return;
// 		}

// 		const index = geometry.index;
// 		const position = geometry.positionNormalized;
// 		const positionUnpack = geometry.positionNormalized.unpack;
// 		const posMult = positionUnpack.w / Uint16MaxValue;

// 		const offset = geometry.activeOffsets;
// 		const offsetUnpack = geometry.activeOffsets!.unpack;
// 		const offsetMult = offsetUnpack.w / Uint16MaxValue;

// 		const drawRange = geometry.drawRange;

// 		const start = Math.max( drawRange.start, 0 );
// 		const end = Math.min( index.count, ( drawRange.start + drawRange.count ) );

// 		for (let i = start; i < end; i += 3 ) {

// 			const a = index.getX( i );
// 			const b = index.getX( i + 1 );
// 			const c = index.getX( i + 2 );
			
// 			vA.setFromArray( position.array!, a * 3 ).multiplyScalar(posMult).addFromVec4(positionUnpack);
// 			vB.setFromArray( position.array!, b * 3 ).multiplyScalar(posMult).addFromVec4(positionUnpack);
// 			vC.setFromArray( position.array!, c * 3 ).multiplyScalar(posMult).addFromVec4(positionUnpack);
			
// 			vOffset.setFromArray(offset.array!, a * 3).multiplyScalar(offsetMult).addFromVec4(offsetUnpack);
// 			vA.add(vOffset);

// 			vOffset.setFromArray(offset.array!, b * 3).multiplyScalar(offsetMult).addFromVec4(offsetUnpack);
// 			vB.add(vOffset);

// 			vOffset.setFromArray(offset.array!, c * 3).multiplyScalar(offsetMult).addFromVec4(offsetUnpack);
// 			vC.add(vOffset);
			
// 			let point_t: Vector3 | null = null;
// 			if ( side === GeometrySide.BackSide ) {
// 				point_t = this.ray.intersectTriangle( vC, vB, vA, true, intersectionPointWorld );
// 			} else {
// 				point_t = this.ray.intersectTriangle( vA, vB, vC, side !== GeometrySide.DoubleSide, intersectionPointWorld );
// 			}
// 			if (point_t === null) {
// 				continue;
// 			};

// 			let distance = this.ray.origin.distanceTo( intersectionPointWorld );

// 			if (distance < this.near || distance > this.far) {
// 				continue;
// 			};

// 			let normal_t = MathUtils.getTriangleNormal_t( vA, vB, vC );

// 			result.push({
// 				distance: distance,
// 				point: intersectionPointWorld.clone(),
// 				normal: normal_t.clone()

// 			});
// 		}
// 	}
// }());

// const reusedKrMat4: Matrix4 = new Matrix4();


