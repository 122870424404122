import type { AnyTrackerProps, Bim, GaugePack, IdBimScene, TrackerPart, TrackerSequenceBuilder } from 'bim-ts';
import { ModulesGapContent, calculateTrackerFlatRepr, trackersPartsCache} from 'bim-ts';
import { DC_CNSTS, TrackerPartType } from 'bim-ts';
import { Circuit } from '../models/circuit';
import { Node } from '../models/node';
import { Connection } from '../models/connection';
import { PatternStepDesc, SceneInstanceDesc } from '../utils';
import { BimOperations } from './bim-operations';
import {
    eqTypeMightHaveMultiPatternSteps,
    findCurrentStepInPattern,
} from '../utils';
import {
    getPropWithThrow,
    getTrackSeqBuildParams,
} from '../../props-gather';
import { Vector2 } from 'math-ts';
import type { AddSiParams, AddSiParamsWithOptionalParent} from './types';
import { equipmentType } from './types';
import { Failure, IterUtils, Success, Yield } from 'engine-utils-ts';

export class CircuitBuilder {
    private readonly circuit: Circuit;
    private readonly bim: Bim;
    private readonly bimOps: BimOperations;
    private readonly gaugePack: GaugePack;
    private readonly transformers: IdBimScene[];
    // buffer to store nodes that are ready for connection
    private unboundNodes: Node[] = [];
    constructor(bim: Bim, gaugePack: GaugePack, transformers: IdBimScene[]) {
        this.bim = bim;
        this.transformers = transformers;
        this.gaugePack = gaugePack;
        this.bimOps = new BimOperations(bim, gaugePack, transformers);
        this.circuit = new Circuit(this.bimOps);
    }

    *build() {
        for (const transformer of this.bimOps.selectedTransformers) {
            this.addTransformer(transformer);
            yield Yield.NextFrame;
        }
        return this.circuit;
    }

    addTransformer(id: IdBimScene) {
        this.bim.instances.spatialHierarchy
            .traverseRootToLeavesDepthFirstFrom(
                id, (id) => {
                    const shouldContinue = this.addSceneInstance(id)
                    return shouldContinue;
                },
            );
    }

    addSceneInstance(id: IdBimScene): boolean {
        const _si = this.bim.instances.perId.get(id);
        if (!_si) return false;
        const si = new SceneInstanceDesc({ i: _si, id });
		const ty = equipmentType(si.i);
        if (ty === null) return false;
        const isSiMultiStep = eqTypeMightHaveMultiPatternSteps
            .includes(ty);
        let repeat = 0;
        do {
            const success = this.addSubSceneInstance(si, repeat);
            if (!success) {
                // Return success because this is not the first iteration.
                // Some sub scene instances have already be added.
                if (repeat > 0) return true;
                // First sub scene instanec fail. Return fail.
                return false;
            }
            if (!isSiMultiStep)
                return true;
        } while (++repeat < 20);
        throw new Error(`Infinite loop while adding sub scene instance ${id}.`);
    }

    addSubSceneInstance(
        si: SceneInstanceDesc,
        subIndex: number,
    ): boolean {
        const patternConfig =
            this.bimOps.patternConfigs.getOrCreate(si.id);
        const currentPatternStepIndex =
            findCurrentStepInPattern(si.i, patternConfig, subIndex);
        if (currentPatternStepIndex === null) return false;
        const step = new PatternStepDesc({
            patternConfig, idx: currentPatternStepIndex,
        });
        const reply = this.circuit.nodes
            .findParentByPatternStep(step) ?? undefined;
        if (reply instanceof Failure) {
            if (reply.noImmediateParent) {
                console.warn('smth wrong with hierarchy, skipping current branch');
                return false;
            }
        }

        if (reply instanceof Success) {

        }
        const parent = reply instanceof Success ? reply.value : undefined;
        if (this.unboundNodes.length)
            throw new Error('Some nodes were not connected.');
        const params: AddSiParamsWithOptionalParent = { si, parent, step };
        if (params.parent) {
            this.addSubSceneInstanceWithParent(params as AddSiParams);
        }
        if (!this.unboundNodes.length) {
            this.addGenericPatternStep(params);
        }
        this.handleUnboundNodes();
        return true;
    }

    private addEndOfTracker(params: AddSiParams) {
        const trackerConfig = this.bimOps.trackerConfigs
            .getOrCreate(params.si.id);
        const hints = trackerConfig.tracker.hints;
        const endOfTracker = this.circuit.nodes
            .add(new Node({ hints, ...params }));
        this.unboundNodes.push(endOfTracker);
    }

    private addSubSceneInstanceWithParent(params: AddSiParams) {
        const type = params.step.patternStepType;
        if (type === 'string_exit') {
            const parentStep = params.step.getParentStep();
            if (parentStep?.patternStepType === 'end_of_multiharness') {
                const grandParentStep = parentStep.getParentStep();
                if (grandParentStep?.patternStepType === 'end_of_group')
                    this.addStringExitWithMultiharnessAndGrouping(params);
                else
                    this.addStringExitWithMultiharness(params);
            } else {
                this.addStringExit(params);
            }
        } else if (type === 'end_of_tracker') {
            this.addEndOfTracker(params);
        }
    }

    private addGenericPatternStep(params: AddSiParamsWithOptionalParent) {
        const pos = params.si.enginePosition.clone();
        this.bimOps.globalAngleFix(pos);
        const added = this.circuit.nodes
            .add(new Node({ ...params, hints: [pos] }));
        this.unboundNodes.push(added);
    }

    private addStringExitWithMultiharness(params: AddSiParams) {
        // create strings, end of multiharnesses
        const strings = this.createStringExitsFromTracker(params);
        const endOfMulHarns = this.createEndOfMultiharness(params);
        // manually connect them in correct order
        // 1) connect strings to multiharnesses
        this.connectStringExitsToMultiharnesses(strings, endOfMulHarns);
        // push to nodes, unbound
        this.circuit.nodes.add(endOfMulHarns);
        this.circuit.nodes.add(strings);
        this.unboundNodes.push(...endOfMulHarns);
        this.unboundNodes.push(...strings);
    }


    private addStringExitWithMultiharnessAndGrouping(params: AddSiParams) {
        // create string, end of multiharnesses, grouping nodes
        const strings = this.createStringExitsFromTracker(params);
        const endOfMulHarns = this.createEndOfMultiharness(params);
        const groupingNodes = this.createGroupingNodes(params);
        // manually connect them in correct order:
        // 1) connect each string to nearest endOfMulHarns
        this.connectStringExitsToMultiharnesses(strings, endOfMulHarns);
        // 2) connect each endOfMulHarn to groupingNode
        this.connectEndOfMultiharnesToGroupingNode(
            endOfMulHarns,
            groupingNodes,
        );
        // push nodes to unbound
        this.circuit.nodes.add(groupingNodes);
        this.circuit.nodes.add(endOfMulHarns);
        this.circuit.nodes.add(strings);
        this.unboundNodes.push(...groupingNodes);
        this.unboundNodes.push(...endOfMulHarns);
        this.unboundNodes.push(...strings);
    }


    private connectEndOfMultiharnesToGroupingNode(
        endOfMulHarns: Node[], groupingNodes: Node[],
    ) {
        if (endOfMulHarns.length !== groupingNodes.length)
            throw new Error([
                'Different length of end of multiharnesses',
                'and grouping nodes',
            ].join(' '));
        for (const [idx, groupingNode] of groupingNodes.entries())
            endOfMulHarns[idx].parent = groupingNode;
    }


    private connectStringExitsToMultiharnesses(
        strings: Node[], harnesses: Node[],
    ) {
        const _strings = [...strings];
        const _harnesses = [...harnesses];
        let harness: Node | undefined = _harnesses.shift();
        let string: Node | undefined = _strings.shift();
        while (harness && string) {
            // get middle of the string;
            const stringMidPoint = string.averageHint;
            if (!stringMidPoint || harness.hints.length !== 2)
                throw new Error([
                    'bad harness/string configuration',
                    `for string ${string.id} and multiharness ${harness.id}`,
                ].join(' '));
            const midYCoord = stringMidPoint.y;
            // if mid is outside of multiharnes
            // then move to the next multiharness
            // except when this is the last multiharness
            if (midYCoord < harness.hints[1].y || !_harnesses.length) {
                string.parent = harness;
                string = _strings.shift();
                continue;
            }
            harness = _harnesses.shift();
        }
    }

    private createGroupingNodes(params: AddSiParams) {
        const multiharness = params.step.patternConfig.multiharness;
        const parentStep = params.step.getParentStep();
        const grandParentStep = parentStep?.getParentStep();
        const groupingNodeHints = this.bimOps.trackerGroupingHints
            .getOrCreate(params.si.id);
        if (
            !multiharness ||
            !grandParentStep ||
            grandParentStep.patternStepType !== 'end_of_group'
        )
            throw new Error('no config found for grouping nodes');
        const endOfGroups: Node[] = [];
        for (let i = 0; i < multiharness.divs.length; i++) {
            const endOfGroup = new Node({
                step: grandParentStep, parent: params.parent, si: params.si,
                hints: groupingNodeHints,
            });
            endOfGroups.push(endOfGroup);
        }
        return endOfGroups;
    }

    private createEndOfMultiharness(params: AddSiParams) {
        const multiharness = params.step.patternConfig.multiharness;
        const parentStep = params.step.getParentStep();
        const trackerConfig = this.bimOps.trackerConfigs
            .getOrCreate(params.si.id);
        if (
            !multiharness ||
            parentStep?.patternStepType !== 'end_of_multiharness'
        )
            throw new Error('No configs found for multiharness');
        const amountOfPartsTrackerIsDividedByMulHarn =
            multiharness.divs.reduce((acc, cur) => acc+cur, 0);
        const lengthOfEachPart =
            trackerConfig.tracker.length /
            amountOfPartsTrackerIsDividedByMulHarn;
        const mulHarnLengths = multiharness.divs.map(x => x * lengthOfEachPart);
        let offset = trackerConfig.tracker.minPoint;
        const endOfMulHarns: Node[] = [];
        for (const harnLen of mulHarnLengths) {
            const p1 = offset;
            const p2 = new Vector2(p1.x, p1.y + harnLen);
            offset = p2.clone();
            const hints = [p1, p2];
            const mulHarn = new Node({
                step: parentStep, si: params.si, hints, parent: params.parent,
            });
            endOfMulHarns.push(mulHarn);
        }
        return endOfMulHarns;
    }

    private findModuleSlices(builder: TrackerSequenceBuilder): TrackerPart[][] {
        let slices: TrackerPart[][] = [];
        let totalModuleCount = 0;
        let modules: TrackerPart[] = [];
        const lastPart = builder.parts.at(-1);
        for (const part of builder.parts) {
            const isLastPart = lastPart === part;
            // start tracking string
            if (part.ty === TrackerPartType.ModulesColumn) {
                modules.push(part);
                totalModuleCount++;
            } else if (isLastPart) {
            } else {
                continue;
            }

            const moduleGap = builder._gaps[totalModuleCount];
            // finish string
            const isEndOfString =
                isLastPart ||
                (moduleGap & (ModulesGapContent.StringGap | ModulesGapContent.Edge));
            if (isEndOfString) {
                slices.push(modules.slice());
                modules.length = 0;
            }
        }
        return slices;
    }

    private getModuleOffsetsFromLegacyTracker(params: AddSiParams) {
        const trackerBuilder = trackersPartsCache.acquire(getTrackSeqBuildParams(params.si.i));
        const trackerConfig = this.bimOps.trackerConfigs
            .getOrCreate(params.si.id);
        const stringStartOffsets: number[] = [];
        const stringEndOffsets: number[] = [];
        const moduleSlices = this.findModuleSlices(trackerBuilder);
        for (const slice of moduleSlices) {
            if (slice.length < 2) {
                continue;
            }
            const from = slice[0]
            const to = slice[slice.length - 1];
            stringStartOffsets
                .push(from.centerOffset - trackerConfig.moduleWidth / 2);
            stringEndOffsets
                .push(to.centerOffset + trackerConfig.moduleWidth / 2);
        }
        return {
            stringStartOffsets,
            stringEndOffsets,
        }
    }

    private getModuleOffsetsForAnyTracker(params: AddSiParams): ReturnType<typeof this.getModuleOffsetsFromLegacyTracker> {
        const anyTrackerParams = params.si.i.props as AnyTrackerProps;
        const repr = calculateTrackerFlatRepr(anyTrackerParams);
        const length = anyTrackerParams.tracker_frame.dimensions.length?.as('m');
        if (!length) {
            throw new Error('tracker length is not available');
        }
        const stringModuleCountX = anyTrackerParams.tracker_frame.string.modules_count_x.value;
        const moduleSlices = IterUtils.splitArrayIntoChunks(repr.modules_rows_offsets, stringModuleCountX)
        const stringStartOffsets: number[] = [];
        const stringEndOffsets: number[] = [];
        for (const slice of moduleSlices) {
            if (slice.length < 2) {
                continue;
            }
            const from = slice[0] + length/2
            const to = slice[slice.length - 1] + length/2;
            stringStartOffsets
                .push(from);
            stringEndOffsets
                .push(to);
        }
        return {
            stringStartOffsets,
            stringEndOffsets,
        }
    }

    private getModuleOffsets(params: AddSiParams) {
        if (params.si.i.type_identifier === 'tracker') {
            return this.getModuleOffsetsFromLegacyTracker(params);
        } else if (params.si.i.type_identifier === 'any-tracker') {
            return this.getModuleOffsetsForAnyTracker(params);
        } else if (params.si.i.type_identifier === 'fixed-tilt'){
            return this.getModuleOffsetsFromLegacyFixedTilt(params);
        } else {
            console.error('tracker not supported', params)
            throw new Error('tracker not supported')
        }
    }

    private getModuleOffsetsFromLegacyFixedTilt(params: AddSiParams) {
        const trackerConfig = this.bimOps.trackerConfigs
            .getOrCreate(params.si.id);
        const modulesGap = getPropWithThrow(params.si.i, 'dimensions | modules_gap').as('m');

        const stringStartOffsets: number[] = [];
        const stringEndOffsets: number[] = [];
        for (let stringI = 0; stringI < trackerConfig.tracker.strCnt; stringI++) {
            const stringStartX = stringI * trackerConfig.horizontalModulesInString * (trackerConfig.moduleWidth + modulesGap);
            const stringEndX =  (stringI + 1) * trackerConfig.horizontalModulesInString * (trackerConfig.moduleWidth + modulesGap) - modulesGap;
            stringStartOffsets.push(stringStartX);
            stringEndOffsets.push(stringEndX);
        }
        return {
            stringStartOffsets,
            stringEndOffsets,
        }
    }

    private createStringExitsFromTracker(params: AddSiParams): Node[] {
        const strings: Node[] = [];
        const trackerConfig = this.bimOps.trackerConfigs
            .getOrCreate(params.si.id);
        const flipped = trackerConfig.tracker.maxPoint.y < trackerConfig.tracker.minPoint.y;

        const {
            stringEndOffsets,
            stringStartOffsets,
        } = this.getModuleOffsets(params);

        const conductorToParent = params.step.conductorToParent;
        if (!conductorToParent)
            throw new Error('Conductor to parent not found');
        const isConductorToParentMerging =
            DC_CNSTS.merginCableTypes.includes(conductorToParent.type);
        const trackerWidth = trackerConfig.tracker.maxWidth;
        const virtualTrackerWidth = trackerWidth * 0.5;
        const stepX = virtualTrackerWidth / (stringStartOffsets.length + 1);
        const baseX = isConductorToParentMerging
            ? trackerConfig.tracker.minPoint.x
            : trackerConfig.tracker.minPoint.x - virtualTrackerWidth/2;


        const baseY = trackerConfig.tracker.minPoint.y;
        for (let i = 0; i < stringStartOffsets.length; i++) {
            const x = isConductorToParentMerging ? baseX : baseX + stepX*(i+1);
            const startOffset = stringStartOffsets[i];
            const endOffset = stringEndOffsets[i];
            const firstEdge =
                new Vector2(x, baseY + startOffset * (flipped ? -1 : 1));
            const secondEdge =
                new Vector2(x, baseY + endOffset * (flipped ? -1 : 1));
            const stringLengthHalf = new Vector2().copy(secondEdge)
                .sub(firstEdge).multiplyScalar(0.5);
            const stringCenter = new Vector2().copy(firstEdge)
                .add(stringLengthHalf);
            const stringLengthHalfAdjusted = new Vector2().copy(stringLengthHalf).multiplyScalar(0.95);
            const firstEdgeAdjusted = new Vector2().copy(stringCenter)
                .sub(stringLengthHalfAdjusted);
            const secondEdgeAdjusted = new Vector2().copy(stringCenter)
                .add(stringLengthHalfAdjusted);

            const hints = [firstEdgeAdjusted, secondEdgeAdjusted];
            const string = new Node({
                ...params, hints,
                desc: `string ${i+1} of tracker ${params.parent.desc}`,
            });
            strings.push(string);
        }
        return strings;
    }

    private addStringExit(params: AddSiParams) {
        const strings = this.createStringExitsFromTracker(params);
        this.circuit.nodes.add(strings);
        this.unboundNodes.push(...strings);
    }


    private connectNodes(from: Node, to: Node) {
        const request = this.circuit.connections
            .add(new Connection({ from, to }));
        // set node's req references
        from.toChildren.push(request);
        to.toParent = request;
        // check to in correct place acording to pattern
        this.checkPatternStepsOrdering(to);
    }

    private checkPatternStepsOrdering(node: Node) {
        if (!node.si) return;
        const parentWithSi = node.firstParentWithSi;
        if (!parentWithSi || !parentWithSi.si) return;
        if (parentWithSi.si.id === node.si.id) return;
        if (parentWithSi.si.id === node.si.i.spatialParentId) return;
        throw new Error('Pattern step ordering is wrong');
    }

    private handleUnboundNodes() {
        if (!this.unboundNodes.length) return;
        while (this.unboundNodes.length) {
            const node = this.unboundNodes[0];
            this.unboundNodes.shift();
            const prevStep = node.step.getParentStep();
            const parentNode = node.parent;
            if (!parentNode || !prevStep) continue;
            if (prevStep.patternStepType === 'utility') {
                // insert utility between 2 nodes
                const prevPrevStep = prevStep.getParentStep();
                if (!prevPrevStep)
                    throw new Error('No step and node found after utility node');
                const utility = this.circuit.nodes.add(
                    new Node({ step: prevStep, parent: parentNode }),
                );
                // reparent node, so its child of utility
                node.parent = utility;
                this.connectNodes(parentNode, utility);
                this.connectNodes(utility, node);
            } else
                this.connectNodes(parentNode, node);
        }
    }

}
