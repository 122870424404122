import { RGBA } from "engine-utils-ts";
import { KrMath } from "math-ts";
import type { Bim } from "../../Bim";
import { NumberProperty, ColorProperty } from "../../properties/PrimitiveProps";
import type { PropertyGroup } from "../../properties/PropertyGroup";
import { StateType } from "../ConfigsArchetypes";

export const EquipmentSlopeAnalysisType = "EquipmentSlopeAnalysisType";

export const EquipmentSlopeAnalysisPaletteMaxRowCount = 30;

export interface PaletteSlice extends PropertyGroup {
    min: NumberProperty;
    max: NumberProperty;
    color: ColorProperty;
}

export interface EquipmentSlopeAnalysisPaletteConfig extends PropertyGroup {
    slices: PaletteSlice[];
}

export function generateEquipmentSlopeAnalysisConfigProps(args: {
    paletteSize: number,
    slopeRangePercentagesExtents: [min: number, max: number],
}): EquipmentSlopeAnalysisPaletteConfig {

    const paletteSize = KrMath.clamp(args.paletteSize, 1, EquipmentSlopeAnalysisPaletteMaxRowCount);

    const defaultSlopeSlices: PaletteSlice[] = [];
    {
        const [minSlope, maxSlope] = args.slopeRangePercentagesExtents;
        const colors = [ RGBA.newRGB(0.1, 0.2, 0.9), RGBA.newRGB(1, 1, 1), RGBA.newRGB(0.9, 0.2, 0.1) ];
        for (let i = 0; i < paletteSize; ++i) {

            const min =  KrMath.lerp(minSlope, maxSlope, i / paletteSize);
            const max =  KrMath.lerp(minSlope, maxSlope, (i + 1) / paletteSize);

            const color = RGBA.lerpFromArray(colors, i * (colors.length - 1) / (paletteSize - 1), RGBA.betterlerp);

            defaultSlopeSlices.push({
                min: NumberProperty.new({ value: min, unit: "%" }),
                max: NumberProperty.new({ value: max, unit: "%" }),
                color: ColorProperty.new({ value: color }),
            });
        }
    }


    const properties: EquipmentSlopeAnalysisPaletteConfig = {
        slices: defaultSlopeSlices,
    };

    return properties;
}

export function registerEquipmentSlopeAnalysisConfig(bim: Bim) {

    bim.configs.archetypes.registerArchetype({
        type_identifier: EquipmentSlopeAnalysisType,
        properties: () => generateEquipmentSlopeAnalysisConfigProps({
            paletteSize: EquipmentSlopeAnalysisPaletteMaxRowCount,
            slopeRangePercentagesExtents: [0, 30],
        }),
        stateType: StateType.Singleton,
    });
}
