import { LazyVersionedPollingCacheImpl, isInNode } from "..";
import { EnumUtils } from "../EnumUtils";
import type {
    LazyVersioned,
    LazyVersionedPollingCache,
    PollWithVersionResult,
    VersionedValue,
} from "./LazyVersioned";
import { LazyDerived } from "./LazyVersioned";
import { LegacyLogger } from "../LegacyLogger";
import { ObjectUtils } from "../ObjectUtils";
import { Yield } from "../TasksRunner";
import type { Result, ResultAsync } from "../Result";
import { Failure, Success, InProgress } from "../Result";

// export abstract class LazyVersionedAsync<T> implements LazyVersioned<ResultAsync<T>> {
//     constructor(
//         public readonly name: string,
//     ) {
//     }
//     abstract poll(): ResultAsync<T>;
//     abstract version(): number; // when result changes, for instance from InProgress to Sucess, version should increase
//     abstract pollWithVersion(target?: (number | Readonly<ResultAsync<T>>)[] | undefined): (number | Readonly<ResultAsync<T>>)[];
//     abstract _runExecution(performanceNowTimeLimit: number): LazyAsyncExecutionStepResult;
//     abstract dispose(): void;
// }

export type LazyAsyncDependency<T> = LazyVersioned<T | ResultAsync<T>>;

export class LazyDerivedAsync<T> implements LazyVersioned<ResultAsync<T>> {
    public readonly name: string;

    readonly deps: LazyAsyncDependency<any>[];
    readonly deriveFn: (args: any[]) => Iterator<Yield, ResultAsync<T> | T> | ResultAsync<T>;
    readonly nonArgInvalidators: VersionedValue[] | null;

    private _disposeFn?: (val: T) => void;

    private _lastCalcDepsVersionSum: number = -1;
    private _selfVersionOffset = 0;
    private _lastCalculatedValue: ResultAsync<any> | undefined = undefined;
    private _calculationGeneratorToExecute:
        | Iterator<Yield, ResultAsync<T> | T>
        | undefined = undefined;

    private _throttleIntervalMs: number = 0;
    private _lastExecutionStart: number = 0;

    private _lastSuccessfullValue: T | undefined = undefined;

    private constructor(
        name: string,
        deps: LazyAsyncDependency<any>[],
        calculator: (
            args: any[],
        ) => Iterator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
        nonArgInvalidators: VersionedValue[] | null,
    ) {
        this.name = name;
        this.deps = deps;
        this.deriveFn = calculator;
        this.nonArgInvalidators = nonArgInvalidators;
    }
    withDispose(fnName: keyof T) {
        this._disposeFn = (val) => {
            if (typeof val[fnName] == "function") {
                (val[fnName] as unknown as Function)();
            } else {
                console.error(`dispose fn name (${String(fnName)}) is invalid`);
            }
        };
        return this;
    }
    withCustomDisposeFn(disposingFn: (val: T) => void) {
        this._disposeFn = disposingFn;
        return this;
    }

    withThrottling(throttleIntervalMs: number = 2000) {
        this._throttleIntervalMs = throttleIntervalMs;
        return this;
    }

    dispose() {
        if (this._disposeFn && this._lastCalculatedValue instanceof Success) {
            try {
                this._disposeFn(this._lastCalculatedValue.value);
            } catch (e) {
                console.error(`error disposing LazyDerived`, this, e);
            }
        }

        // this._lastCalculatedValue = undefined;
        this._selfVersionOffset += 1;
        this._setIteratorToExecute(undefined);
    }

    peekLastValue(): ResultAsync<T> | undefined {
        return this._lastCalculatedValue;
    }

    *waitTillCompletion(
        timeoutMs: number = 10_000,
    ): Generator<Yield, Result<T>> {
        const start = performance.now();
        while (performance.now() - start < timeoutMs) {
            const value = this.poll();
            if (value instanceof Success || value instanceof Failure) {
                return value;
            } else if (value instanceof InProgress) {
                yield Yield.NextFrame;
            } else {
                return new Failure({ msg: "unexpected state" });
            }
        }

        return new Failure({ msg: "timeout" });
    }

    private _setIteratorToExecute(
        iterator: Iterator<Yield, ResultAsync<T> | T> | undefined,
    ) {
        this._calculationGeneratorToExecute = iterator;
        if (iterator) {
            GlobalAsyncsExecutor.addForExecution(this);
        } else {
            GlobalAsyncsExecutor.removeFromExecution(this);
        }
    }

    pollWithVersion(
        cache?: LazyVersionedPollingCache,
    ): PollWithVersionResult<ResultAsync<T>> {
        const args: any[] = new Array(this.deps.length);

        let dependenciesVersionsSum = 0;

        if (this.nonArgInvalidators) {
            for (const inv of this.nonArgInvalidators) {
                dependenciesVersionsSum += cache?.getOrCreate(inv).version ?? inv.version(cache);
            }
        }

        let errorsCount = 0;
        let inProgressCount = 0;

        for (let i = 0; i < this.deps.length; ++i) {
            const dep = this.deps[i];
            const result = cache?.getOrCreate(dep) ?? dep.pollWithVersion(cache);
            let res = result.value;
            dependenciesVersionsSum += result.version;

            if (res instanceof Failure) {
                errorsCount += 1;
            } else if (res instanceof InProgress) {
                inProgressCount += 1;
            } else if (res instanceof Success) {
                res = res.value;
            }
            if (res === undefined) {
                res = new Failure({ msg: `undefined value returned by ${i}`});
                errorsCount += 1;
                LegacyLogger.deferredWarn(`${this.name} async: undefined argument returned by ${i} dependency (${(dep as any).name})`, result);
            }
            args[i] = res;
        }

        if (dependenciesVersionsSum === this._lastCalcDepsVersionSum) {
            return {
                value: this._lastCalculatedValue!,
                version: this._selfVersionOffset + this._lastCalcDepsVersionSum,
            };
        }

        this._lastCalcDepsVersionSum = dependenciesVersionsSum;
        this._setIteratorToExecute(undefined);
        let result: ResultAsync<T>;
        if (errorsCount > 0) {
            result = new Failure({
                msg: `dependency error`,
                inner: args.filter((arg) => arg instanceof Failure),
            });
        } else if (inProgressCount > 0) {
            result = new InProgress(
                this.name,
                args.filter((arg) => arg instanceof InProgress),
                this._lastSuccessfullValue,
            );
        } else {
            // at this point all args should be ready
            let derived;
            try {
                const nowMs = performance.now();
                if (
                    this._throttleIntervalMs > 0 &&
                    this._lastExecutionStart + this._throttleIntervalMs > nowMs
                ) {
                    derived = throttledExecutionWrapper(
                        this.deriveFn,
                        args,
                        this._throttleIntervalMs + this._lastExecutionStart,
                    );
                } else {
                    derived = this.deriveFn(args);
                }
                this._lastExecutionStart = nowMs;
            } catch (e) {
                const errorMsg = `exception during LazyDerivedAsync(${this.name}) generator start`;
                console.error(errorMsg, e);
                derived = new Failure({ msg: errorMsg, exception: e });
            }
            if (derived instanceof Success) {
                result = derived;
            } else if (derived instanceof InProgress) {
                result = new InProgress(
                    this.name,
                    [derived],
                    this._lastSuccessfullValue,
                );
            } else if (derived instanceof Failure) {
                result = derived;
            } else if (ObjectUtils.isGenerator(derived)) {
                result = new InProgress(
                    this.name,
                    undefined,
                    this._lastSuccessfullValue,
                );
                this._setIteratorToExecute(derived);
            } else {
                result = new Failure({ msg: "invalid returned value" });
                console.error(
                    "unexpected lazy async function returned value, expected iterator/generator or ResultAsync",
                    derived,
                );
            }
        }
        this._updateLastResult(result);
        const lastVersion = this._selfVersionOffset + this._lastCalcDepsVersionSum;
        return {
            value: result,
            version: lastVersion,
        };
    }

    version(cache?: LazyVersionedPollingCache): number {
        const result = this.pollWithVersion(cache);
        return result.version;
    }

    poll(cache?: LazyVersionedPollingCache): ResultAsync<T> {
        const result = this.pollWithVersion(cache);
        return result.value;
    }

    private _updateLastResult(newResult: ResultAsync<T>) {
        // if (ObjectUtils.areObjectsEqual(newResult, this._lastCalculatedValue)) {
        //     return;
        // }
        if (this._lastCalculatedValue instanceof Success) {
            if (this._disposeFn) {
                try {
                    this._disposeFn(this._lastCalculatedValue.value);
                } catch (e) {
                    console.error(
                        `error disposing LazyDerivedAsync value`,
                        this,
                        e,
                    );
                }
            } else {
                this._lastSuccessfullValue = this._lastCalculatedValue.value;
            }
        }
        this._lastCalculatedValue = newResult;
        this._selfVersionOffset += 1;
    }

    _runExecution(
        performanceNowTimeLimit: number,
    ): LazyAsyncExecutionStepResult {
        if (this._calculationGeneratorToExecute) {
            do {
                try {
                    const res = this._calculationGeneratorToExecute.next();
                    if (res.done) {
                        let result: ResultAsync<T>;
                        const calculatedResult = res.value;
                        if (calculatedResult instanceof Success) {
                            result = calculatedResult;
                            if (result.value === undefined) {
                                LegacyLogger.deferredWarn(`${this.name} async: produced Sucess with undefined value`, this);
                                result = new Failure({
                                    msg: "undefined result",
                                });
                            }
                        } else if (calculatedResult instanceof Failure) {
                            result = calculatedResult;
                        } else if (calculatedResult instanceof InProgress) {
                            result = new Failure({
                                msg: "generator returned InProgress status",
                            });
                        } else if (calculatedResult === undefined){
                            LegacyLogger.deferredWarn(`${this.name} async: produced undefined value`, this);
                            result = new Failure({
                                msg: "undefined result",
                            });
                        } else {
                            result = new Success(calculatedResult);
                        }
                        this._updateLastResult(result);
                        return LazyAsyncExecutionStepResult.Done;
                    }
                    if (res.value === Yield.NextFrame) {
                        return LazyAsyncExecutionStepResult.YieldTillNextFrame;
                    }
                    if (res.value !== Yield.Asap) {
                        LegacyLogger.deferredWarn(
                            `invalid lazy generator interm returned value ${res.value}, should be one of`,
                            EnumUtils.getAllEnumConstsNames(Yield),
                        );
                    }
                } catch (e) {
                    console.error(e);
                    this._updateLastResult(
                        new Failure({
                            msg: `exception during lazy generator execution`,
                            exception: e,
                        }),
                    );
                    return LazyAsyncExecutionStepResult.Done;
                }
            } while (performance.now() < performanceNowTimeLimit);
            return LazyAsyncExecutionStepResult.RunBackAsap;
        } else {
            console.error(
                `unexepcted attempt of execution (${this.name})`,
                this,
            );
            return LazyAsyncExecutionStepResult.Done;
        }
    }

    static new0<T>(
        name: string,
        nonArgInvalidators: VersionedValue[] | null,
        calculator: (
            args: [],
        ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
    ): LazyDerivedAsync<T> {
        return new LazyDerivedAsync(
            name,
            [],
            calculator as any,
            nonArgInvalidators,
        );
    }
    static new1<T, Arg1>(
        name: string,
        nonArgInvalidators: VersionedValue[] | null,
        args: [LazyAsyncDependency<Arg1>],
        calculator: (
            args: [arg1: Arg1],
        ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
    ): LazyDerivedAsync<T> {
        return new LazyDerivedAsync(
            name,
            args,
            calculator as (
                args: any[],
            ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
            nonArgInvalidators,
        );
    }
    static new2<T, Arg1, Arg2>(
        name: string,
        nonArgInvalidators: VersionedValue[] | null,
        args: [LazyAsyncDependency<Arg1>, LazyAsyncDependency<Arg2>],
        calculator: (
            args: [arg1: Arg1, arg1: Arg2],
        ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
    ): LazyDerivedAsync<T> {
        return new LazyDerivedAsync(
            name,
            args,
            calculator as (
                args: any[],
            ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
            nonArgInvalidators,
        );
    }
    static new3<T, Arg1, Arg2, Arg3>(
        name: string,
        nonArgInvalidators: VersionedValue[] | null,
        args: [
            LazyAsyncDependency<Arg1>,
            LazyAsyncDependency<Arg2>,
            LazyAsyncDependency<Arg3>,
        ],
        calculator: (
            args: [arg1: Arg1, arg2: Arg2, arg3: Arg3],
        ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
    ): LazyDerivedAsync<T> {
        return new LazyDerivedAsync(
            name,
            args,
            calculator as (
                args: any[],
            ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
            nonArgInvalidators,
        );
    }
    static new4<T, Arg1, Arg2, Arg3, Arg4>(
        name: string,
        nonArgInvalidators: VersionedValue[] | null,
        args: [
            LazyAsyncDependency<Arg1>,
            LazyAsyncDependency<Arg2>,
            LazyAsyncDependency<Arg3>,
            LazyAsyncDependency<Arg4>,
        ],
        calculator: (
            args: [arg1: Arg1, arg2: Arg2, arg3: Arg3, arg4: Arg4],
        ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
    ): LazyDerivedAsync<T> {
        return new LazyDerivedAsync(
            name,
            args,
            calculator as (
                args: any[],
            ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
            nonArgInvalidators,
        );
    }
    static new5<T, Arg1, Arg2, Arg3, Arg4, Arg5>(
        name: string,
        nonArgInvalidators: VersionedValue[] | null,
        args: [
            LazyAsyncDependency<Arg1>,
            LazyAsyncDependency<Arg2>,
            LazyAsyncDependency<Arg3>,
            LazyAsyncDependency<Arg4>,
            LazyAsyncDependency<Arg5>,
        ],
        calculator: (
            args: [arg1: Arg1, arg2: Arg2, arg3: Arg3, arg4: Arg4, arg5: Arg5],
        ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
    ): LazyDerivedAsync<T> {
        return new LazyDerivedAsync(
            name,
            args,
            calculator as (
                args: any[],
            ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
            nonArgInvalidators,
        );
    }
    static new6<T, Arg1, Arg2, Arg3, Arg4, Arg5, Arg6>(
        name: string,
        nonArgInvalidators: VersionedValue[] | null,
        args: [
            LazyAsyncDependency<Arg1>,
            LazyAsyncDependency<Arg2>,
            LazyAsyncDependency<Arg3>,
            LazyAsyncDependency<Arg4>,
            LazyAsyncDependency<Arg5>,
            LazyAsyncDependency<Arg6>,
        ],
        calculator: (
            args: [
                arg1: Arg1,
                arg2: Arg2,
                arg3: Arg3,
                arg4: Arg4,
                arg5: Arg5,
                arg6: Arg6,
            ],
        ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
    ): LazyDerivedAsync<T> {
        return new LazyDerivedAsync(
            name,
            args,
            calculator as (
                args: any[],
            ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
            nonArgInvalidators,
        );
    }
    static new7<T, Arg1, Arg2, Arg3, Arg4, Arg5, Arg6, Arg7>(
        name: string,
        nonArgInvalidators: VersionedValue[] | null,
        args: [
            LazyAsyncDependency<Arg1>,
            LazyAsyncDependency<Arg2>,
            LazyAsyncDependency<Arg3>,
            LazyAsyncDependency<Arg4>,
            LazyAsyncDependency<Arg5>,
            LazyAsyncDependency<Arg6>,
            LazyAsyncDependency<Arg7>,
        ],
        calculator: (
            args: [
                arg1: Arg1,
                arg2: Arg2,
                arg3: Arg3,
                arg4: Arg4,
                arg5: Arg5,
                arg6: Arg6,
                arg7: Arg7,
            ],
        ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
    ): LazyDerivedAsync<T> {
        return new LazyDerivedAsync(
            name,
            args,
            calculator as (
                args: any[],
            ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
            nonArgInvalidators,
        );
    }
    static new8<T, Arg1, Arg2, Arg3, Arg4, Arg5, Arg6, Arg7, Arg8>(
        name: string,
        nonArgInvalidators: VersionedValue[] | null,
        args: [
            LazyAsyncDependency<Arg1>,
            LazyAsyncDependency<Arg2>,
            LazyAsyncDependency<Arg3>,
            LazyAsyncDependency<Arg4>,
            LazyAsyncDependency<Arg5>,
            LazyAsyncDependency<Arg6>,
            LazyAsyncDependency<Arg7>,
            LazyAsyncDependency<Arg8>,
        ],
        calculator: (
            args: [
                arg1: Arg1,
                arg2: Arg2,
                arg3: Arg3,
                arg4: Arg4,
                arg5: Arg5,
                arg6: Arg6,
                arg7: Arg7,
                arg8: Arg8,
            ],
        ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
    ): LazyDerivedAsync<T> {
        return new LazyDerivedAsync(
            name,
            args,
            calculator as (
                args: any[],
            ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
            nonArgInvalidators,
        );
    }
    static new9<T, Arg1, Arg2, Arg3, Arg4, Arg5, Arg6, Arg7, Arg8, Arg9>(
        name: string,
        nonArgInvalidators: VersionedValue[] | null,
        args: [
            LazyAsyncDependency<Arg1>,
            LazyAsyncDependency<Arg2>,
            LazyAsyncDependency<Arg3>,
            LazyAsyncDependency<Arg4>,
            LazyAsyncDependency<Arg5>,
            LazyAsyncDependency<Arg6>,
            LazyAsyncDependency<Arg7>,
            LazyAsyncDependency<Arg8>,
            LazyAsyncDependency<Arg9>,
        ],
        calculator: (
            args: [
                arg1: Arg1,
                arg2: Arg2,
                arg3: Arg3,
                arg4: Arg4,
                arg5: Arg5,
                arg6: Arg6,
                arg7: Arg7,
                arg8: Arg8,
                arg9: Arg9,
            ],
        ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
    ): LazyDerivedAsync<T> {
        return new LazyDerivedAsync(
            name,
            args,
            calculator as (
                args: any[],
            ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
            nonArgInvalidators,
        );
    }
    static fromArr<T, Arg>(
        name: string,
        nonArgInvalidators: VersionedValue[] | null,
        args: LazyAsyncDependency<Arg>[],
        calculator: (
            args: Arg[],
        ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
    ): LazyDerivedAsync<T> {
        return new LazyDerivedAsync(
            name,
            args,
            calculator as (
                args: any[],
            ) => Generator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
            nonArgInvalidators,
        );
    }
}

export enum LazyAsyncExecutionStepResult {
    Done,
    RunBackAsap,
    YieldTillNextFrame,
}

interface ExecutionInfo {
    source: LazyDerivedAsync<any>;
    sourceVersionAddedWith: number|null;
    addedAtMs: number;
}

const DefaultPollingInterval = 50;
const DefaultPollingExecutionTime = 35;

export class LazyAsyncExecutor {
    _asyncsToExecute = new Map<LazyDerivedAsync<any>, ExecutionInfo>();

    _isRunning: boolean = false;

    _loopCallback: () => void;

    constructor() {
        this._loopCallback = () => {
            if (this._asyncsToExecute.size === 0 && this._isRunning) {
                this._isRunning = false;
            }
            if (this._isRunning) {
                setTimeout(this._loopCallback, DefaultPollingInterval);
                this.runAsyncs(DefaultPollingExecutionTime);
            }
        };
    }

    _tryStart() {
        if (this._asyncsToExecute.size > 0 && !this._isRunning) {
            this._isRunning = true;
            setTimeout(this._loopCallback, DefaultPollingInterval);
        }
    }

    addForExecution(iteratorSource: LazyDerivedAsync<any>) {
        this._asyncsToExecute.set(iteratorSource, {
            source: iteratorSource,
            sourceVersionAddedWith: null,
            addedAtMs: performance.now(),
        });
        this._tryStart();
    }

    removeFromExecution(iteratorSource: LazyDerivedAsync<any>) {
        this._asyncsToExecute.delete(iteratorSource);
    }

    runAsyncs(timeLimitMs: number) {
        const timeToEnd = performance.now() + timeLimitMs;

        let toRun: ExecutionInfo[] = [];

        const cache = new LazyVersionedPollingCacheImpl();

        for (const lv of this._asyncsToExecute.keys()) { // iterate through keys only
            const _resultVersion = cache.getOrCreate(lv);
            // calling to version() may add, delete or replace lazy versioned objects added to executor
            // so we should call and cache each version() call first, to make sure that
            // this.__asyncsToExecute map is not mutated on the second iteration
        }

        for (const [lv, descr] of this._asyncsToExecute) { // iterate through keys only
            const currentVersion = cache.getOrCreate(lv).version;

            if (descr.sourceVersionAddedWith === null) {
                descr.sourceVersionAddedWith = currentVersion;
            } else if (descr.sourceVersionAddedWith !== currentVersion) {
                this._asyncsToExecute.delete(lv);
                continue;
            }
            toRun.push(descr);
        }

        toRun.sort((l, r) => l.addedAtMs - r.addedAtMs);

        do {
            const timePerGenerator = Math.max(
                (timeToEnd - performance.now()) / toRun.length,
                0,
            );
            const generatorsToRerunIfHaveTime = [];
            perAsync: for (const info of toRun) {
                pollVersionCache.clear();
                const startT = performance.now();
                const endT = startT + timePerGenerator;
                const res = info.source._runExecution(endT);
                if (res === LazyAsyncExecutionStepResult.RunBackAsap) {
                    generatorsToRerunIfHaveTime.push(info);
                } else if (res === LazyAsyncExecutionStepResult.Done) {
                    this._asyncsToExecute.delete(info.source);
                } else if (res === LazyAsyncExecutionStepResult.YieldTillNextFrame) {

                } else {
                    console.error(
                        `unexpected lazy-async(${info.source.name}) execution result`,
                        res,
                    );
                }
            }
            toRun = generatorsToRerunIfHaveTime;
        } while (toRun.length > 0 && performance.now() < timeToEnd);
    }
}

export let GlobalAsyncsExecutor = new LazyAsyncExecutor();

if (!isInNode()) {
    if ((self as any)["__globalAsyncsExecutor"] !== undefined) {
        GlobalAsyncsExecutor = (self as any)["__globalAsyncsExecutor"];
        console.error(
            "double initialization of global asyncs executor, erroneous bundling",
        );
    } else {
        (self as any)["__globalAsyncsExecutor"] = GlobalAsyncsExecutor;
    }
}

function* throttledExecutionWrapper<T>(
    executionFn: (args: any[]) => Iterator<Yield, ResultAsync<T> | T> | ResultAsync<T>,
    executionArgs: any[],
    throttleTillMs: number,
): Generator<Yield, ResultAsync<T> | T> {
    while (performance.now() < throttleTillMs) {
        yield Yield.NextFrame;
    }

    const derived = executionFn(executionArgs);
    if (!ObjectUtils.isIterable(derived)) {
        return derived as ResultAsync<T> | T;
    }

    return yield* derived as Generator<Yield, ResultAsync<T> | T>;
}

export function preferPreviousOverInProgressWrapper<T>(
    input: LazyDerived<ResultAsync<T>> | LazyDerivedAsync<T>,
): LazyVersioned<ResultAsync<T>> {
    const wrapper = LazyDerived.new1<ResultAsync<T>, ResultAsync<T>>(
        input.name + " wrapper",
        [],
        [input],
        ([input], prevResult) => {
            if (input instanceof InProgress && prevResult !== undefined && !(prevResult instanceof InProgress)) {
                return prevResult;
            }
            return input;
        },
    );
    return wrapper;
}

const pollVersionCache = new Map<LazyDerivedAsync<any>, [number, any]>();
//requestAnimationFrame(() => {
//    pollVersionCache.clear();
//})
