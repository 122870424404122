<script lang="ts">
    import {
        getContext,
        onDestroy,
        onMount,
    } from "svelte";
    import type {
        ColDef,
        ColGroupDef,
        GetContextMenuItemsParams,
        GetServerSideGroupLevelParamsParams,
        GridApi,
        GridOptions,
        IFiltersToolPanel,
        IServerSideDatasource,
        IsServerSideGroupOpenByDefaultParams,
        MenuItemDef,
        ServerSideGroupLevelParams} from "ag-grid-enterprise";
    import {
        createGrid
    } from "ag-grid-enterprise";
    import "ag-grid-enterprise";
    import "ag-grid-enterprise/styles/ag-grid.css";
    import "ag-grid-enterprise/styles/ag-theme-alpine.css";
    import type { VersionedStore } from "../../VersionedStore";
    import type { QuantitiesItems } from "../QuantityItemsLazy";
    import SaveState from './SelectStateComponent.svelte';
    import type { GroupTypesMap } from "../GroupTypesMap";
    import { NotificationDescription, NotificationType, UiBindings } from "ui-bindings";
    import { ObjectUtils, ProjectNetworkClient } from "engine-utils-ts";
    import { TemplatesStorageApi, type TemplateGetReply } from "../TemplatesStorageApi";
    import { notificationSource } from '../../Notifications';
    import type { KreoEngine } from "engine-ts";


    const uiBindings: UiBindings = getContext("uiBindings");
    const engine: KreoEngine = getContext("engine");

    export let columnDefs: (ColDef | ColGroupDef)[];
    let secondColumnDefs: (ColDef | ColGroupDef)[] | null;
    export let theme = "alpine";
    export let bimItems: VersionedStore<QuantitiesItems>;

    $: version = $bimItems.version;
    let columnsHash: number = -1;

    let themeUrl = `https://unpkg.com/ag-grid-community/dist/styles/ag-theme-${theme}.css`;
    let ref: HTMLElement;
    let api: GridApi;
    let groupsMap: GroupTypesMap|undefined;
    const maxItemCount = 200_000;
    const gridOptions: GridOptions = {
        rowModelType: "serverSide",
        columnDefs,

        defaultColDef: {
            sortable: true,
            enableValue: true,
            enableRowGroup: true,
            allowedAggFuncs: ["sum", "min", "max", "count", "avg", "first", "last"],
            resizable: true,
            enablePivot: true,
            useValueFormatterForExport: true,
        },
        sideBar: true,
        //groupDefaultExpanded:-1,
        // debug: true,
        cacheBlockSize: 500,
        maxBlocksInCache: 3,
        getServerSideGroupLevelParams: (params: GetServerSideGroupLevelParamsParams): ServerSideGroupLevelParams => {
            const noGroupingActive = params.rowGroupColumns.length == 0;
            // console.log('params', params);

            let res: ServerSideGroupLevelParams;
            if (noGroupingActive) {
                res = {
                    // infinite scrolling
                    suppressInfiniteScroll: false,
                    // 100 rows per block
                    cacheBlockSize: 500,
                    // purge blocks that are not needed
                    maxBlocksInCache: 3,
                };
            } else {
                //const topLevelRows = params.level == 0;
                res = {
                    suppressInfiniteScroll: false,
                    // cacheBlockSize: params.level == 1 ? 5 : 2,
                    // maxBlocksInCache: -1, // never purge blocks
                    cacheBlockSize: maxItemCount,
                    maxBlocksInCache: 1,
                };
            }
            // console.log('res', res);

            return res;
        },

        multiSortKey: "ctrl",
        pivotPanelShow: 'always',
        rowGroupPanelShow: "always",
        // purgeClosedRowNodes: true,
        maxConcurrentDatasourceRequests: 2,
        // blockLoadDebounceMillis: 1000,
        getContextMenuItems: getContextMenuItems,
        selection: {
            mode: "cell"
        },
        enableCharts: true,
        serverSideSortAllLevels: true,
        isServerSideGroupOpenByDefault,
    };

    function getContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
        const result: (string | MenuItemDef)[] = [
            'autoSizeAll',
            //'expandAll',
            {
                name: 'Expand All',
                action: expandAllGroups,
            },
            'contractAll',
            'copy',
            'copyWithHeaders',
            'copyWithGroupHeaders',
            'resetColumns',
            'chartRange',
            'pivotChart',
            'separator',
            'export'
        ];

        return result;
    }

    $: if (version) {
        tryRefreshCache();
    }

    function getColumnsHash(allColumns:ColDef[]){
        let hash:number = 0;
        for (const col of allColumns) {
            if(col.field){
                hash += ObjectUtils.stringHash(col.field);
            }
        }
        for (const def of allColumns) {
            if(def.filterParams){
                for (const value of def.filterParams.values) {
                    hash += ObjectUtils.stringHash(value);
                }
            }
        }

        return hash;
    }

    function isServerSideGroupOpenByDefault(
        params: IsServerSideGroupOpenByDefaultParams
    ) {
        const route = params.rowNode.getRoute();
        if (!route || groupsMap === undefined) {
            return false;
        }

        return groupsMap.has(route);
    }



    async function expandAllGroups(){
        const state = api.getRowGroupColumns();
        const filter = api.getFilterModel();
        const groupColumns = state.map(c => c.getId());
        const getGroupTask = engine.tasksRunner.newLongTask({
            identifier: "expendAllGroups",
            defaultGenerator: $bimItems.getGroupsRows(groupColumns, filter, api.isPivotMode()),
        });
        uiBindings.addNotification(
            NotificationDescription.newWithTask({
                type: NotificationType.Info,
                source: notificationSource,
                key: 'expendGroups',
                addToNotificationsLog: true,
                taskDescription: {
                    task: getGroupTask,
                },
            })
        );
        const groups = await getGroupTask.asPromise();

        // console.log('Count items: ', groups.count, state, groups);

        if(groups.count > maxItemCount){
            uiBindings.addNotification(
                NotificationDescription.newBasic({
                    type: NotificationType.Warning,
                    source: notificationSource,
                    key: 'openManyGroups',
                    removeAfterMs: 5_000,
                    addToNotificationsLog: true,
                })
            );
            return;
        }

        groupsMap = groups;
        api.refreshServerSide({ purge: false });
    }

    const datasource: () => IServerSideDatasource = () => ({
        getRows: (params) => {
            const responseTask = $bimItems.getRowsWithColumnsAsync(params.request);
            responseTask
                .then((response) => {
                    params.success(response.params);
                    return response;
                })
                .then((response) => {
                    columnDefs = response.columnsDefs
                    secondColumnDefs = response.secondaryColDefs;
                })
                .catch((error) => {
                    console.error(error);
                    params.fail();
                });

            return responseTask;
        },
    });

    $: {
        tryUpdateColumns(columnDefs);
        api?.setPivotResultColumns(secondColumnDefs)
    }

    onMount(() => {
        api = createGrid(ref, gridOptions);
        api.setGridOption('serverSideDatasource', datasource());
        tryRefreshCache();
    });


    function tryRefreshCache(route?: string[]) {
        if (api) {
            const state = api.getServerSideGroupLevelState();
            if(state.length > 0 && state[0].rowCount === 0){
                api.setGridOption('serverSideDatasource', datasource());
            } else {
                api.refreshServerSide({ route: route, purge: false });
            }
            // console.warn(`version ${version} refreshed`);
        } else {
            console.warn(`version ${version} doesn't refresh`);
        }
    }

    function tryUpdateColumns(columnsDefs: ColDef[]){
        if(api){
            const hash = getColumnsHash(columnsDefs);
            if(columnsHash !== hash){
                // console.log(
                //     `update columns version ${version}, columns: ${hash}`
                // );
                api.setGridOption('columnDefs', columnsDefs);
                api.onFilterChanged();

                const filters:string[] = [];
                const filterModel = api.getFilterModel();
                for (const filter in filterModel) {
                    filters.push(filter);
                }

                (api!.getToolPanelInstance('filters')! as any as IFiltersToolPanel).expandFilters(filters);
                columnsHash = hash;
            }
        } else {
            console.warn(`update columns version ${version} doesn't refresh`);
        }
    }

    onDestroy(() => {
        api?.destroy()
    });

    let templatesInput: TemplateGetReply = {
        company: [],
        global: [],
    };
    $: allTemplates = [...templatesInput.company, ...templatesInput.global];
    let network = getContext<ProjectNetworkClient>('network');
    let templateApi = new TemplatesStorageApi(network);
    templateApi.getAllTemplates().then((result) => {
        templatesInput = result;
    })

    let selectedTemplateId: number = -1;

    $: selectedTemplate = allTemplates.find(x => x.id === selectedTemplateId);
    $: {
        console.log('selectedTemplate', selectedTemplate);
    }

    export function exportExcel() {
        api.exportDataAsExcel();
    }

</script>

<svelte:head>
    {#if theme !== "alpine" && !Object.values(document.styleSheets).some((styleSheet) => styleSheet.href === themeUrl)}
        <link rel="stylesheet" href={themeUrl} />
    {/if}
</svelte:head>
<div class="root" style="position: relative">
    <div
        bind:this={ref}
        style="height: 100%; width:100%"
        class="ag-theme-{theme}"
    />
    <div class="saveStateContainer">
        <SaveState
            label="Templates"
            bind:gridApi={api}
        />
    </div>
</div>

<style lang="scss">
    .select-param {
        display: flex;
        align-items: center;
    }
    .root {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    .saveStateContainer {
        width: 240px;
        padding: 0 5px;
        height: 100%;
    }
</style>
