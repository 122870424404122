import { datadogRum } from '@datadog/browser-rum';
import type { Catalog } from "bim-ts";
import { URLS } from "bim-ts";
import { getContext } from "svelte";
import type { VerDataSyncer } from "verdata-ts";
import type { IAuthHub } from "./auth";

export function initDatadogLogs() {
    if(!URLS.isProduction()) {
        return;
    }
    
    datadogRum.init({
        applicationId: 'cce2911b-f445-46a7-a610-bf4ecf9a668e',
        clientToken: 'pubcdc1ab382608ee4deb51475932c1c4c2',
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'datadoghq.com',
        service: 'bim-app',
        env: 'production',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackUserInteractions: false,
        trackResources: false,
        trackLongTasks: false,
        
        defaultPrivacyLevel: 'mask-user-input',
    });

    const hub = getContext<IAuthHub>("authHub");
    hub?.subscribe((store) => {
        const user = store.authData;
        datadogRum.clearUser();
        datadogRum.setUser({
            id: user?.id,
            company: user?.company,
            admin: user?.isAdmin,
            email: user?.email,
            name: user?.name,
        });
    });
}

export function updateDatadogLogsContext(){
    if(!URLS.isProduction()) {
        return;
    }
    
    const projectId = getContext<number>("projectId");
    if(projectId) {
        datadogRum.setGlobalContextProperty("projectId", projectId);
    } else {
        datadogRum.removeGlobalContextProperty("projectId");
    }

    function setVerDataContext(verDataSyncer: VerDataSyncer | undefined, propertyKey: string){
        if(!verDataSyncer) {
            datadogRum.removeGlobalContextProperty(propertyKey);
            return;
        }

        datadogRum.setGlobalContextProperty(propertyKey, vds.getCurrentVersionId());

        vds.addListener('afterSync', () => {
            datadogRum.setGlobalContextProperty(propertyKey, vds.getCurrentVersionId());
        });

        vds.addListener('dispose', () => {
            datadogRum.removeGlobalContextProperty(propertyKey);
        });
    }

    const vds = getContext<VerDataSyncer>("verDataSyncer");
    setVerDataContext(vds, "projectVersionId");

    const catalog = getContext<Catalog>("catalog");
    setVerDataContext(catalog?.getSyncer(), "catalogVersionId");
}
