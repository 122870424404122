

import Root from './Root.svelte';
import dayjs from 'dayjs';

import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

(window as any).process = {} as any;


export { Root };