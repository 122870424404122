

export enum SceneInstanceFlags {
	None 					= 0,
	isHidden 				= 1,
	isSelected 				= 2,

	isHighlighted 			= 4,
    // higher patch bits indicate which flags changed when set in state notification
	// and indicate which flags should be changed, when used with patch
	// consider object having flags state = (IsVisible | IsSelected)
	// if we want to patch object, so that IsSelected flag is removed,
	// and all other flags are untouched, we should construct flagsPatch = (0 | IsVisiblePatch)
	// if we want to set isSelected flag, and do not touch others
	// patch should be construct to be equal (IsVisible | IsVisiblePatch)_

	// lower bits indicate flags state, higher bits indicate which flags should be modified
	// multiple patches can be combined with | operator
	// construction helper function is defined below
}

export const FlagsMask = 0x000_FFF;
export const FlagsPatchMask = 0xFFF_000;

export const NoUndoFlags = (
	SceneInstanceFlags.isHighlighted
);
export const NoUndoFlagsPatch = NoUndoFlags << 12;


export function newFlagsPatch<Flags extends number>(flag: Flags, enabled: boolean): Flags {
	if ((flag & FlagsMask) === 0) {
		console.error('object flags patch is invalid');
		return 0 as Flags;
	}
	const flagValue = enabled ? flag : 0;
	const patchFlag = flag << 12;
	return (flagValue | patchFlag) as Flags;
}

export function applyFlagsPatch<Flags extends number>(currentValue: Flags, patch: Flags): Flags {
	const flagsToPatch = ((patch & FlagsPatchMask) >> 12) & FlagsMask;
	if (flagsToPatch == 0) {
		console.warn('invalid flags patch');
		return currentValue;
	}
	return ((currentValue & (~flagsToPatch)) | (patch & FlagsMask)) as Flags;
}


// _flags: SceneInstanceFlags = SceneInstanceFlags.None;

// public get isHidden() { return !!(this._flags & SceneInstanceFlags.isHidden); }
// public get isSelected() { return !!(this._flags & SceneInstanceFlags.isSelected); }
// public get isHighlighted() { return !!(this._flags & SceneInstanceFlags.isHighlighted); }
// public set isHidden(value: boolean) {
//     if (value) { this._flags |= SceneInstanceFlags.isHidden }
//     else { this._flags &= (~SceneInstanceFlags.isHidden) };
// }
// public set isSelected(value: boolean) {
//     if (value) { this._flags |= SceneInstanceFlags.isSelected }
//     else { this._flags &= (~SceneInstanceFlags.isSelected) };
// }
// public set isHighlighted(value: boolean) {
//     if (value) { this._flags |= SceneInstanceFlags.isHighlighted }
//     else { this._flags &= (~SceneInstanceFlags.isHighlighted) };
// }
