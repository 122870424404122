

export class Allocated<IdT> {
	constructor(
		public readonly ids: IdT[]
	){
		Object.freeze(this.ids);
	}
}
export class Updated<IdT> {
	constructor(
		public readonly ids: IdT[]
	){
		Object.freeze(this.ids);
	}
}
export class Deleted<IdT> {
	constructor(
		public readonly ids: IdT[]
	){
		Object.freeze(this.ids);
	}
}

export type BasicCollectionUpdates<IdT> = Allocated<IdT> | Updated<IdT> | Deleted<IdT>;
