import type { LazyVersionedPollingCache, LazyVersioned, VersionedValue, PollWithVersionResult } from './LazyVersioned';


export class LazyVersionedPollingCacheImpl implements LazyVersionedPollingCache {

    map = new Map<LazyVersioned<any> | VersionedValue, { result: PollWithVersionResult<any>; stats: { readCount: number; }; }>();

    constructor() {
    }

    getOrCreate(x: VersionedValue): PollWithVersionResult<undefined>;
    getOrCreate<T>(x: LazyVersioned<T>): PollWithVersionResult<T>;
    getOrCreate<T>(x: LazyVersioned<T> | VersionedValue): PollWithVersionResult<T> {
        const existing = this.map.get(x);
        if (existing) {
            existing.stats.readCount += 1;
            return existing.result;
        }
        let result: PollWithVersionResult<T>;
        if ('pollWithVersion' in x) {
            result = x.pollWithVersion(this);
        } else {
            result = { value: undefined as any, version: x.version(this) };
        }
        this.map.set(x, {
            result,
            stats: { readCount: 1 },
        });
        return result;
    }
}
