import { PUI_GroupNode, RuntimeSystemExecutionStatus, type RuntimeSystemUiDescription } from "ui-bindings";
import { ListItem } from "../libui/list";
import { EnumUtils, LazyDerived, type LazyVersioned, type VersionedValue } from "engine-utils-ts";
import { Success } from '../libui/icon/svg';


export enum RuntimeSystemsCombinedStatus {
    InProgress,
    Done,
    Disabled,
    Error,
    Mixed,
}

export class RuntimeSystemsUiContext implements VersionedValue {

    constructor(
        readonly runtimeSystems: RuntimeSystemUiDescription[]
    ) {
        this.runtimeSystems.sort((l, r) => {
            const groupCompare = r.group_sort_key.localeCompare(l.group_sort_key);
            if (groupCompare !== 0) {
                return groupCompare;
            }
            return l.name.localeCompare(r.name);
        })
    }

    version(): number {
        let sum = 0;
        for (const rs of this.runtimeSystems) {
            sum += rs.executionStatus.version();
        }
        return sum;
    }

    totalStatusType(): LazyVersioned<RuntimeSystemsCombinedStatus> {
        return LazyDerived.fromMutatingObject<RuntimeSystemsCombinedStatus>(() => {
            let disabled = 0;
            for (const rs of this.runtimeSystems) {
                switch (rs.executionStatus.poll()) {
                    case RuntimeSystemExecutionStatus.Waiting:
                    case RuntimeSystemExecutionStatus.InProgress:
                        return RuntimeSystemsCombinedStatus.InProgress;
                    case RuntimeSystemExecutionStatus.Done:
                        break;
                    case RuntimeSystemExecutionStatus.Disabled:
                        disabled++;
                        break;
                }
            }
            if (disabled === this.runtimeSystems.length) {
                return RuntimeSystemsCombinedStatus.Disabled;
            } else {
                return RuntimeSystemsCombinedStatus.Done;
            }
        });
    }


    getUi(): LazyVersioned<PUI_GroupNode> {

        const runtimeSystemsWithUi = this.runtimeSystems.filter(rs => rs.ui !== undefined);

        return LazyDerived.fromArr(
            'runtime-systems-ui',
            runtimeSystemsWithUi.map(rs => rs.executionStatus),
            runtimeSystemsWithUi.map(rs => rs.ui!),
            (runtimesUis) => {
                const root = new PUI_GroupNode({
                    name: 'runtime',
                    sortChildren: false,
                });
                for (let i = 0; i < runtimesUis.length; i++) {
                    const rs = runtimeSystemsWithUi[i];

                    const statusStringRes = EnumUtils.enumStringFromKey(
                        RuntimeSystemExecutionStatus,
                        rs.executionStatus.poll(),
                        ''
                    );

                    let statusString = '';
                    if (statusStringRes instanceof Success) {
                        statusString = ' (' + statusString + ')';
                    }

                    const rsUi = new PUI_GroupNode({
                        name: rs.name + statusString,
                        sortChildren: false,
                    });
                    root.addMaybeChild(rsUi);

                    const ui = runtimesUis[i];
                    if (ui.children.size > 0) {
                        for (const [key, ch] of ui.children) {
                            if (!rsUi.children.has(key)) {
                                rsUi.addMaybeChild(ch);
                            }
                        }
                        // rsUi.addMaybeChild(ui);
                    }
                }
                return root;
            }
        );
    }

    static runtimeSystemAsListItem(runtimeSystem: RuntimeSystemUiDescription): ListItem {

        return new ListItem(
            runtimeSystem.name.replaceAll('-', ' ').toUpperCase(),
            undefined,
            // EnumUtils.enumStringFromKey(RuntimeSystemStatusType, runtimeSystem.status.poll().statusType).value,
            undefined,
            EnumUtils.enumStringFromKey(RuntimeSystemExecutionStatus, runtimeSystem.executionStatus.poll(), '').value,
            undefined,
            undefined,
            undefined,
            undefined,
        );
    }

}
