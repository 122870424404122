import { Aabb2, KrMath, Vec2X, Vec2Zero, Vector2 } from "math-ts";
import type { BlockData, BlocksResponse, LayoutInput, ObjectPosition, PatternName, RawLayout, RoadDef, RoadLineData, TrackerData, TrackersLayout} from "./LayoutAlgorithms";
import { EquipmentRoadsOption, getBlocks } from "./LayoutAlgorithms";
import { closestDistTo, isEqual } from "../LayoutUtils";
import { TransformerIdent, type MathSolversApi } from "bim-ts";
import type { BlockAlgorithmRequest, BlockExecuterAlgorithmResponse, BlockLayoutAlgorithmResponse, BlockSiCbTResponse, HtbCbCiAlgorithmRequest, LayoutAlgorithmResponse, RoadLine, Row, SiCbBlock, SiCbTBlockRequest, SiCbTResponse } from "./LayoutSolversTypes";
import { calcCiPixelValue, calcSiCbClusterValue, calcSiCbPixelValue } from "./ElectricalPatternFormulas";

export interface PatternSolver {
    solve(args: PatternSolverArgs, blockExecuterResponse: BlocksResponse, mathSolverApi: MathSolversApi): Promise<RawLayout>;
}

export interface PatternSolverArgs {
    pattern: PatternName | "";
    trackersLayout: TrackersLayout;
    input: LayoutInput;
    maxTrimPowers: number[];
}

export class ElectricalPatternSolvers {
    private readonly patterns: Map<PatternName | "", PatternSolver>;
    private readonly mathSolversApi: MathSolversApi;

    constructor(mathSolversApi: MathSolversApi, patterns: Iterable<[PatternName | "", PatternSolver]>) {
        this.mathSolversApi = mathSolversApi;
        this.patterns = new Map();
        for (const [pattern, solver] of patterns) {
            if (this.patterns.has(pattern)) {
                throw new Error(`Pattern ${pattern} already exists`);
            }
            this.patterns.set(pattern, solver);
        }
    }

    async solve(args: PatternSolverArgs): Promise<RawLayout> {
        const solver = this.patterns.get(args.pattern)!;
        if (!solver) {
            throw new Error(`Pattern ${args.pattern} not found`);
        }

        let blockExecuterResponse: BlocksResponse;
        if (args.input.equipmentRoadsOption === EquipmentRoadsOption.Generate &&
            !args.input.invertersIgnore && args.input.shiftTan && Math.abs(args.input.shiftTan) >= 100
        ) {
            blockExecuterResponse = {
                blocks: args.trackersLayout.sites.map(s => ({
                    trackers: s.trackers,
                    lines: s.lines,
                    label: 0,
                })),
                emptyBlockIndexes: [],
            }
        } else {
            blockExecuterResponse = await getBlocks(
                args.input, this.mathSolversApi, args.trackersLayout.sites, args.input.rowToRowSpace, args.maxTrimPowers
            );
        }

        const layout = solver.solve(args, blockExecuterResponse, this.mathSolversApi);
        return layout;
    }
}

export class CIHorTrunkbusSolver implements PatternSolver {
    async solve(args: PatternSolverArgs, blockExecuterResponse: BlocksResponse, mathSolverApi: MathSolversApi): Promise<RawLayout> {
        const { pixel_value } = calcElectricalParams(args.input);
        const blockResponse = await mathSolverApi.callSolver<
            HtbCbCiAlgorithmRequest,
            BlockLayoutAlgorithmResponse
        >({
            solverName: "htb-cb-ci-block",
            solverType: "single",
            request: {
                blocks: blockExecuterResponse.blocks,
                align_boxes: false,
                pixel_value,
                road_side: args.input.road_side,
                box_offset: args.input.outerOffset,
                inverter_offset: args.input.inverter_offset,
            },
        });
        const type1Layouts = { blocks: [blockResponse.blocks] };
        const filteredRoads = filterRoads(blockExecuterResponse, args.trackersLayout);
        const selectFn = (power: number, index:number) => selectBlockEquipmentFn(power, index, blockExecuterResponse, args.input);
        const layout = convertLayout(args.input, type1Layouts, selectFn, 
            filteredRoads.equipment_roads, filteredRoads.support_roads);
        return layout;
    }
}

export class CIHorTrunkbusTrenchSolver implements PatternSolver {
    async solve(args: PatternSolverArgs, blockExecuterResponse: BlocksResponse, mathSolverApi: MathSolversApi): Promise<RawLayout> {
        const { pixel_value } = calcElectricalParams(args.input);
        const blockResponse = await mathSolverApi.callSolver<
            HtbCbCiAlgorithmRequest,
            BlockLayoutAlgorithmResponse
        >({
            solverName: "htb-cb-ci-block",
            solverType: "single",
            request: {
                blocks: blockExecuterResponse.blocks,
                align_boxes: true,
                pixel_value,
                road_side: args.input.road_side,
                box_offset: args.input.outerOffset,
                inverter_offset: args.input.inverter_offset,
            },
        });
        const type2Layouts ={blocks:[blockResponse.blocks] };
        const filteredRoads = filterRoads(blockExecuterResponse, args.trackersLayout);
        const selectFn =(power:number, index:number)=> selectBlockEquipmentFn(power, index, blockExecuterResponse, args.input);
        const layout = convertLayout(args.input, type2Layouts, selectFn, 
            filteredRoads.equipment_roads, filteredRoads.support_roads);
        return layout;
    }
}

export class CIMultVertTrunkbusSolver implements PatternSolver {
    async solve(args: PatternSolverArgs, blockExecuterResponse: BlocksResponse, mathSolverApi: MathSolversApi): Promise<RawLayout> {
        const { pixel_value } = calcElectricalParams(args.input);
        const blockResponse = await mathSolverApi.callSolver<
            BlockAlgorithmRequest,
            BlockLayoutAlgorithmResponse
        >({
            solverName: "vtb-cb-ci-block",
            solverType: "single",
            request: {
                blocks: blockExecuterResponse.blocks,
                pixel_value,
                road_side: args.input.road_side,
                box_offset: args.input.outerOffset,
                inverter_offset: args.input.inverter_offset,
            },
        });
        const type3Layouts = { blocks:[blockResponse.blocks] };
        const filteredRoads = filterRoads(blockExecuterResponse, args.trackersLayout);
        const selectFn =(power:number, index:number) => selectBlockEquipmentFn(power, index, blockExecuterResponse, args.input);
        const layout = convertLayout(args.input, type3Layouts, selectFn, 
            filteredRoads.equipment_roads, filteredRoads.support_roads);
        return layout;
    }
}

export class CIVertTrunkbusSolver extends CIMultVertTrunkbusSolver  implements PatternSolver {

}

export class CIMultiharnessSolver implements PatternSolver {
    async solve(args: PatternSolverArgs, blockExecuterResponse: BlocksResponse, mathSolverApi: MathSolversApi): Promise<RawLayout> {
        const { pixel_value } = calcElectricalParams(args.input);
        const blockResponse = await mathSolverApi.callSolver<
            BlockAlgorithmRequest,
            BlockLayoutAlgorithmResponse
        >({
            solverName: "cb-ci-block",
            solverType: "single",
            request: {
                blocks: blockExecuterResponse.blocks,
                pixel_value,
                road_side: args.input.road_side,
                box_offset: args.input.outerOffset,
                inverter_offset: args.input.inverter_offset,
            },
        });
        
        const type4Layouts ={blocks:[blockResponse.blocks]};
        const filteredRoads = filterRoads(blockExecuterResponse, args.trackersLayout);
        const selectFn =(power:number, index:number) => selectBlockEquipmentFn(power, index, blockExecuterResponse, args.input);
        const layout = convertLayout(args.input, type4Layouts, selectFn, 
            filteredRoads.equipment_roads, filteredRoads.support_roads);
        return layout;
    }
}

export class SIMultiharnessSolver implements PatternSolver {
    async solve(args: PatternSolverArgs, blockExecuterResponse: BlocksResponse, mathSolverApi: MathSolversApi): Promise<RawLayout> {
        const { firstTracker } = calcElectricalParams(args.input);

        const blocks: SiCbBlock[] = [];
        for (const block of blockExecuterResponse.blocks) {
            const blockSettings = args.input.blockCases[block.label];
            
            const cluster_value = calcSiCbClusterValue(
                args.input.combinerBox.current,
                blockSettings.inverter.max_current_output,
                blockSettings.inverter.max_current_input,
                firstTracker.string_max_current,
                firstTracker.string_power,
                args.input.necMultiplier
            );

            blocks.push({
                trackers: block.trackers,
                lines: block.lines,
                label: block.label,
                number_string_inverters: blockSettings.number_of_inverters,
                min_pixel_value: calcSiCbPixelValue(blockSettings.inverter.max_power, blockSettings.ilr_min),
                max_pixel_value: calcSiCbPixelValue(blockSettings.inverter.max_power, blockSettings.ilr_max),
                max_cluster_value: cluster_value,
            });
        }

        const blockResponse = await mathSolverApi.callSolver<
            SiCbTBlockRequest,
            BlockSiCbTResponse
        >({
            solverName: "si-cb-t-block",
            solverType: "single",
            request: {
                blocks: blocks,
                road_side: args.input.road_side,
                box_offset: args.input.outerOffset,
                inverter_offset: args.input.inverter_offset,
                transformer_offset: args.input.transformer_offset,
                pixel_cross_road: false,
            },
        });
        const type5Layouts = {
            blocks: [blockResponse.blocks],
        };
        const selectFn = (power: number, index: number) =>
            selectBlockEquipmentFn(
                power,
                index,
                blockExecuterResponse,
                args.input
            );
        const filteredRoads = filterRoads(blockExecuterResponse, args.trackersLayout);
        const layout = convertSbCiTLayout(args.input, type5Layouts, selectFn, 
            filteredRoads.equipment_roads, filteredRoads.support_roads);
        return layout;
    }
}

function selectBlockEquipmentFn(
    _power: number, 
    index: number, 
    response: BlockExecuterAlgorithmResponse, 
    input: LayoutInput
){
    const label = response.blocks[index].label;
    const blockData = input.blockCases[label];
    return blockData;
}

export function filterRoads(blocks: BlocksResponse, trackersLayout: TrackersLayout): 
{ equipment_roads: RoadLineData[], support_roads: RoadLineData[] } {
    if(blocks.emptyBlockIndexes.length > 0){
        const filteredIndexes = new Set(blocks.emptyBlockIndexes);
        return { 
            equipment_roads: trackersLayout.equipment_roads.filter(r => !filteredIndexes.has(r.patchIndex)), 
            support_roads: trackersLayout.support_roads.filter(r => !filteredIndexes.has(r.patchIndex)) 
        };
    } else {
        return { 
            equipment_roads: trackersLayout.equipment_roads.slice(), 
            support_roads: trackersLayout.support_roads.slice() 
        };
    }
}

function convertLayout(
    input: LayoutInput,
    rawLayout: LayoutAlgorithmResponse,
    selectEquipmentFn:(blockPower:number, index:number) => BlockData,
    equipment_lines: RoadLine[],
    support_lines: RoadLine[],
): RawLayout {
    const trackers: (ObjectPosition & { r: number, s: number })[] = [];
    const combinerBoxes: ObjectPosition[] = [];
    const stringInverters: ObjectPosition[] = [];
    const centralInverters: ObjectPosition[] = [];
    const transformers: ObjectPosition[] = [];
    const roads: RoadDef[] = equipment_lines.map(l => ({
        width: input.equipmentRoadWidth, 
        polyline: [ new Vector2(l.lhs.x, l.lhs.y), new Vector2(l.rhs.x, l.rhs.y)]
    }));
    let counter: number = 0;

    for (const blocks of rawLayout.blocks) {
        for (let index = 0; index < blocks.length; index++) {
            const block = blocks[index];

            const transformerId = ++counter;
            const centralInverterId = ++counter;

            let total_power:number = 0;

            for (const pixel of block.pixels) {
                counter++;
                const combinerBoxId = counter;
                const comniberBoxPos = new Vector2(pixel.combiner_box.x, pixel.combiner_box.y);
                combinerBoxes.push({
                    id: combinerBoxId,
                    type: input.combinerBox.src.type_identifier,
                    src: input.combinerBox.src,
                    position: comniberBoxPos,
                    parentId: centralInverterId,
                    rotateDeg: 90 + getAngle(roads, comniberBoxPos, input)
                });
                for (const tracker of pixel.trackers) {
                    counter++;
                    total_power += tracker.v
                    trackers.push(
                        convertTracker(counter, input.trackers, tracker, combinerBoxId)
                    );
                }
            }

            const [result] = correctIntersectionWithRoads({
                supportRoadWidth: input.supportRoadWidth,
                supportRoads: support_lines,
                equipment: {
                    center: new Vector2(block.central_inverter.x, block.central_inverter.y), 
                    height: input.max_length,
                    width: input.max_length,
                    rotateDeg: getAngle(roads, new Vector2(block.central_inverter.x, block.central_inverter.y), input),
                    additionalOffset: input.max_length * 2,
                },
            });
            const transformer = result.center.clone().add(result.dir.clone().multiplyScalar(input.max_length));

            const blockData = selectEquipmentFn(total_power, index);
            centralInverters.push({
                id: centralInverterId,
                type: blockData.inverter.src.type_identifier,
                src: blockData.inverter.src,
                position: result.center,
                size: new Vector2(blockData.inverter.width, blockData.inverter.length),
                parentId: transformerId,
                rotateDeg: getAngle(roads, new Vector2(block.central_inverter.x, block.central_inverter.y), input),
            });
            transformers.push({
                type: TransformerIdent,
                id: transformerId,
                src: blockData.transformer.src,
                position: transformer,
                size: new Vector2(blockData.transformer.width, blockData.transformer.length),
                rotateDeg: getAngle(roads, transformer, input),
                label: blockData.label,
            });
        }
    }

    for (const l of support_lines) {
        roads.push({
            width: input.supportRoadWidth, 
            polyline: [ new Vector2(l.lhs.x, l.lhs.y), new Vector2(l.rhs.x, l.rhs.y)]
        });
    }

    return {
        trackers,
        combinerBoxes,
        stringInverters,
        centralInverters,
        transformers,
        roads,
    };
}

function convertSbCiTLayout(
    input: LayoutInput,
    rawLayout: SiCbTResponse,
    selectEquipmentFn:(blockPower:number, index: number) => BlockData,
    equipment_lines: RoadLine[],
    support_lines: RoadLine[],
): RawLayout {
    const trackers: (ObjectPosition & { r: number, s: number })[] = [];
    const combinerBoxes: ObjectPosition[] = [];
    const stringInverters: ObjectPosition[] = [];
    const centralInverters: ObjectPosition[] = [];
    const transformers: ObjectPosition[] = [];
    const roads: RoadDef[] = equipment_lines.map(l => ({
        width: input.equipmentRoadWidth, 
        polyline: [ new Vector2(l.lhs.x, l.lhs.y), new Vector2(l.rhs.x, l.rhs.y)]
    }));

    let counter: number = 0;
    for (const blocks of rawLayout.blocks) {
        for (let i = 0; i < blocks.length; i++) {
            const block = blocks[i];
            counter++;
            const transformerId = counter;
            const blockData = selectEquipmentFn(0, i);
            for (const cluster of block.clusters) {
                counter++;
                const combinerBoxesId = counter;
                const comniberBoxPos = new Vector2(cluster.combiner_box.x, cluster.combiner_box.y);
                combinerBoxes.push({
                    id: combinerBoxesId,
                    type: input.combinerBox.src.type_identifier,
                    src: input.combinerBox.src,
                    position: comniberBoxPos,
                    parentId: transformerId,
                    rotateDeg: 90 + getAngle(roads, comniberBoxPos, input)
                });
                for (const pixel of cluster.pixels) {
                    counter++;
                    const inverterId = counter;
                    const inverterBoxPos = new Vector2(pixel.string_inverter.x, pixel.string_inverter.y);
                    stringInverters.push({
                        id: inverterId,
                        type: blockData.inverter.src.type_identifier,
                        src: blockData.inverter.src,
                        position: inverterBoxPos,
                        parentId: combinerBoxesId,
                        rotateDeg: getAngle(roads, inverterBoxPos, input)
                    });
                    for (const tracker of pixel.trackers) {
                        counter++;
                        trackers.push(
                            convertTracker(counter, input.trackers, tracker, inverterId)
                        );
                    }
                }
            }
            const [result] = correctIntersectionWithRoads({
                supportRoadWidth: input.supportRoadWidth,
                supportRoads: support_lines,
                equipment: {
                    center: new Vector2(block.transformer.x, block.transformer.y), 
                    height: input.max_length,
                    width: input.max_length,
                    rotateDeg: 0,
                },
            });
            transformers.push({
                type: TransformerIdent,
                id: transformerId,
                src: blockData.transformer.src,
                position: result.center,
                size: new Vector2(blockData.transformer.width, blockData.transformer.length),
                rotateDeg: getAngle(roads, result.center, input),
                label: blockData.label,
            });
        }
    }

    for (const l of support_lines) {
        roads.push({
            width: input.supportRoadWidth, 
            polyline: [ new Vector2(l.lhs.x, l.lhs.y), new Vector2(l.rhs.x, l.rhs.y)]
        })
    }

    return {
        trackers,
        combinerBoxes,
        stringInverters,
        centralInverters,
        transformers,
        roads
    };
}

function getAngle(lines:RoadDef[], pos: Vector2, input: LayoutInput): number{
    let shiftAngle = input.shiftDeg ? input.shiftDeg : 0;
    if (input.shiftDeg === undefined && lines.length > 0) {
        let minDist = Number.MAX_VALUE;
        let line:[Vector2, Vector2] = [
            new Vector2(lines[0].polyline[0].x, lines[0].polyline[0].y),
            new Vector2(lines[0].polyline[1].x, lines[0].polyline[1].y),
        ];
        for (const { polyline } of lines) {
            const lineVec:[Vector2, Vector2] = [
                new Vector2(polyline[0].x, polyline[0].y),
                new Vector2(polyline[1].x, polyline[1].y),
            ];
            const dist = closestDistTo(lineVec, new Vector2(pos.x, pos.y));
            if(minDist > dist){
                minDist = dist;
                line = lineVec;
            }
        }
        if(minDist < (input.equipmentGlassToGlass * 0.5)){
            shiftAngle = new Vector2().subVectors(line[1], line[0]).angle() * 180 / Math.PI;
        }
    }

    return shiftAngle;
}

export function convertTracker(
    id: number,
    trackersData: TrackerData[],
    trackerRow: Row,
    parentId?: number
) : ObjectPosition & { r: number, s: number } {
    //TODO: Add tracker identifier to math solver
    const trackerData = trackersData.find((t) => 
        isEqual(t.dc_power, trackerRow.v) 
        && t.length <= trackerRow.h 
        && t.width <= trackerRow.w
    );
    if (!trackerData) {
        const message = `Tracker with power ${trackerRow.v} and length ${trackerRow.h} not found`;
        console.error(message, trackerRow, trackersData)
        throw new Error(message);
    }
    const center = getTrackerPosition(
        trackerRow.x,
        trackerRow.y,
        trackerRow.w,
        trackerRow.h,
    );
    return {
        id,
        type: trackerData.src.type_identifier,
        src: trackerData.src,
        position: center,
        size: new Vector2(trackerData.width, trackerData.length),
        parentId: parentId,
        rotateDeg: trackerData.isFixed ? 90 : 0,
        r: trackerRow.r,
        s: trackerRow.s
    };
}

function getTrackerPosition(xMin: number, yMin: number, width: number, height: number): Vector2 {
    const center = new Vector2(xMin, yMin).add(
        new Vector2(width * 0.5, height * 0.5)
    );
    return center;
}

function correctIntersectionWithRoads(args:{
    supportRoadWidth: number,
    supportRoads: RoadLine[],
    equipment: { center: Vector2, height: number, width: number, rotateDeg: number, additionalOffset?: number },
}) {
    const equipment = args.equipment;
    const roadsBoxes: Aabb2[] = [];
    const offset = new Vector2(args.supportRoadWidth * 0.5, 0);
    for (const road of args.supportRoads) {
        roadsBoxes.push(Aabb2.empty().setFromPoints([ 
            new Vector2(road.lhs.x, road.lhs.y).sub(offset),
            new Vector2(road.rhs.x, road.rhs.y).sub(offset),
            new Vector2(road.rhs.x, road.rhs.y).add(offset),
            new Vector2(road.lhs.x, road.lhs.y).add(offset),
        ]));
    }
    
    offset.set(0, 0);
    const offsetDir = Vec2X.clone().rotateAround(Vec2Zero, KrMath.degToRad(equipment.rotateDeg));
    const result: { center: Vector2, dir: Vector2 }[] = [];
    for (const roadBox of roadsBoxes) {
        if(equipment.center.y < roadBox.min.y || equipment.center.y > roadBox.max.y){
            continue;
        }
        const dist = roadBox.distanceToPoint(equipment.center);
        const equipmentOffset = equipment.width * 0.5 + 1;
        
        if((equipment.additionalOffset !== undefined && dist < equipment.additionalOffset) || dist < equipmentOffset){
            const dir = new Vector2().subVectors(equipment.center, roadBox.getCenter()).normalize();
            if (dir.dot(offsetDir) < 0) {
                offsetDir.negate();
            }
        }
        if (dist < equipmentOffset) {
            const offsetFromRoad = roadBox.containsPoint(equipment.center) 
                ?  Math.min(equipment.center.x - roadBox.min.x, roadBox.max.x - equipment.center.x) 
                : 0;
            offset.copy(offsetDir).multiplyScalar(equipmentOffset - dist + offsetFromRoad);
            break;
        }

    }
    result.push({
        center: equipment.center.clone().add(offset),
        dir: offsetDir.normalize(),
    });

    return result;
}

function calcElectricalParams(input: LayoutInput) {
    const firstTracker = input.trackers[0];
    if (input.rowToRowSpace < firstTracker.width) {
        throw new Error(
            `Row to row space - ${input.rowToRowSpace}m can't be less tracker width - ${firstTracker.width} m`
        );
    }

    const pixel_value = calcCiPixelValue(
        input.combinerBox.current, 
        firstTracker.string_power, 
        firstTracker.string_max_current, 
        input.necMultiplier
    );

    return { pixel_value, firstTracker };
}