import type { FilePromised } from "./FilesImportsHandler";

export function requestUserFileInput(
    allowedExtensions: Iterable<string>,
    onUpload: (file: FilePromised) => void
) {
    requestFileFromUser(allowedExtensions, (files) => {
        if (files.length > 0) {
            onUpload(files[0]);
        }
    });
}

export function requestFileFromUser(allowedExtensions: Iterable<string>, callback: (files: FilePromised[]) => void) {
    const input = document.createElement("input");
    input.id = 'importFile'
    input.type = "file";
    input.accept = Array.from(new Set(allowedExtensions)).sort().join(',');

    input.click();
    input.addEventListener("change", () => {
        if (!input.files) {
            return;
        }
        const files: FilePromised[] = [];
        for (let i = 0; i < input.files.length; i++) {
            const file = input.files[i];
            files.push({filename: file.name, filePromise: file.arrayBuffer(), size: file.size});
        }
        if (files.length > 0) {
            callback(files);
        }
        input.remove();
    });
}
