import type { LazyVersioned, PollWithVersionResult, ResultAsync, VersionedValue, Writeable } from 'engine-utils-ts';
import { DefaultMap, Deleted, Failure, InProgress, LazyDerived, ObjectUtils, Success, Yield } from 'engine-utils-ts';
import { ScopedLogger, StreamAccumulator } from 'engine-utils-ts';
import type { IdBimScene, SceneInstance, SceneInstances} from './SceneInstances';
import { SceneObjDiff } from './SceneObjDiff';
import { Euler, Matrix4 } from 'math-ts';
import type { EntitiesCollectionUpdates} from 'src/collections/EntitiesCollectionUpdates';
import { EntitiesUpdated } from 'src/collections/EntitiesCollectionUpdates';
import type { PropertiesCollection } from 'src/bimDescriptions/PropertiesCollection';
import { BimProperty } from 'src/bimDescriptions/BimProperty';
import { PropertyBase, PropsGroupBase } from 'src/properties/Props';
import { BasicPropertyDescription, PropertyViewBasicTypes, type BasicPropertyValue, type BasicViewFormatters } from '../properties/BasicPropsView';
import { CustomPropsRegistry } from '../properties/CustomPropsRegistry';
import type { UnitsMapper } from '../UnitsMapper';


const RecalcThrottleIntervalMs = 3_000;

export class InstancesBasicPropsView implements LazyVersioned<ResultAsync<BasicPropertyDescription[]>> {

    readonly logger: ScopedLogger;
    readonly sceneInstances: SceneInstances;

    private readonly _updates: StreamAccumulator<EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>>;
    private readonly _perTypeIdentKnownProps: DefaultMap<string, KnownPropsR> = new DefaultMap(() => {
        const newTypeMap: KnownPropsR = new Map();
        newTypeMap.set('props', new Map());
        newTypeMap.set('properties', new Map());
        return newTypeMap;
    });

    private _version: number = 0;
    
    private _lastPolledVersion: number = 0;

    private _lastCalcualted = new Map<string, BasicPropertyDescription>();
    private _lastReturnedValue: Success<BasicPropertyDescription[]> = new Success([]);
    private _lastRecalcTimsMs = 0;

    private readonly _dirtyIds: Set<IdBimScene> = new Set();

    readonly formatters: BasicViewFormatters;

    constructor(
        sceneInstances: SceneInstances,
        unitsMapper: UnitsMapper,
    ) {
        this.logger = new ScopedLogger('known-props');
        this.sceneInstances = sceneInstances;
        this._updates = new StreamAccumulator(sceneInstances.updatesStream, (update) => {
            if (update instanceof Deleted) {
                return false;
            }
            if (update instanceof EntitiesUpdated) {
                if ((update.allFlagsCombined & (
                    SceneObjDiff.LegacyProps | SceneObjDiff.NewProps
                )) === 0) {
                    return false;
                }
            }
            return true;
        });

        this.formatters = {unitsMapper};
    }

    private _asLazyVersioned: LazyVersioned<BasicPropertyDescription[]> | null = null;
    asLazyVersioned(): LazyVersioned<BasicPropertyDescription[]> {
        if (!this._asLazyVersioned) {
            this._asLazyVersioned = LazyDerived.new1<BasicPropertyDescription[], ResultAsync<BasicPropertyDescription[]>>(
                'known-props-lazyVersioned',
                null,
                [this],
                ([propsAsync]) => {
                    if (propsAsync instanceof Success) {
                        return propsAsync.value;
                    } else if (propsAsync instanceof InProgress && propsAsync.lastSuccessful) {
                        return propsAsync.lastSuccessful;
                    }
                    return [];
                }
            )
        }
        return this._asLazyVersioned;
    }

    dispose() {
        this._updates.dispose();
    }

    getPropsValuesInvalidator(): VersionedValue {
        return this.sceneInstances.getLazyListOfCollection({relevantUpdateFlags: SceneObjDiff.LegacyProps | SceneObjDiff.NewProps | SceneObjDiff.WorldPosition});
    }


    version(): number {
        return this._version + this._updates.version();
    }
    poll(): ResultAsync<BasicPropertyDescription[]> {
        if (this._lastPolledVersion === this.version()) {
            return this._lastReturnedValue;
        }

        const startTime = performance.now();

        const updates = this._updates.consume();
        if (updates) {
            for (const update of updates) {
                for (const id of update.ids) {
                    this._dirtyIds.add(id);
                }
            }
        }

        if (this._dirtyIds.size > 0 && performance.now() - this._lastRecalcTimsMs < RecalcThrottleIntervalMs) {
            return new InProgress(this.sceneInstances.identifier, undefined, this._lastReturnedValue.value);
        }
        
        for (const id of this._dirtyIds) {
            this._dirtyIds.delete(id);
            const instance = this.sceneInstances.peekById(id);
            if (!instance) {
                continue;
            }
            const knownPerTy = this._perTypeIdentKnownProps.getOrCreate(instance.type_identifier);

            const knownLegacyProps = knownPerTy.get('properties') as KnownPropsR;
            this._reusedPropsPath.length = 1;
            this._reusedPropsPath[0] = 'properties';
            const v1 = this._extractKnownLegacyProps(knownLegacyProps, instance.properties);

            const knownProps = knownPerTy.get('props') as KnownPropsR;
            this._reusedPropsPath.length = 1;
            this._reusedPropsPath[0] = 'props';
            const v2 = this._extractKnownPropsDescriptionsFromProps_r(knownProps, instance.props, this._reusedPropsPath);

            if (v1 || v2) {
                this._version += 1;
            }

            if (performance.now() > startTime + 10) {
                break;
            }
        }

        this._lastPolledVersion = this.version();

        for (const [typeIdent, knownPerTy] of this._perTypeIdentKnownProps) {
            if (this._mergePropsDescriptions_r(typeIdent, knownPerTy, this._lastCalcualted)) {
                this._version += 1;
            }
        }

        if (this.version() !== this._lastPolledVersion) {
            this._lastPolledVersion = this.version();
            
            const newReturnedValue = [];
            const allKnownInstanceTypes = new Set<string>();
            for (const prop of this._lastCalcualted.values()) {
                newReturnedValue.push(prop);
                for (const typeIdent of prop.typeIdentsFoundIn) {
                    allKnownInstanceTypes.add(typeIdent);
                }
            }
            newReturnedValue.push(...transformKnownProps([...allKnownInstanceTypes]));

            newReturnedValue.sort((a, b) => a.label.localeCompare(b.label));
            Object.freeze(newReturnedValue);
            this._lastReturnedValue = new Success(newReturnedValue);
        }

        if (this._dirtyIds.size > 0) {
            this._version += 1;
            return new InProgress(this.sceneInstances.identifier, undefined, this._lastReturnedValue.value);
        }

        return this._lastReturnedValue;
    }
    pollWithVersion(): PollWithVersionResult<Readonly<ResultAsync<BasicPropertyDescription[]>>> {
        return {
            value: this.poll(),
            version: this.version()
        };
    }

    peekBasicPropertyValue(id: IdBimScene, knownProp: BasicPropertyDescription): BasicPropertyValue|undefined {
        const instance = this.sceneInstances.peekById(id);
        if (!instance) {
            return undefined;
        }
        return readKnownPropValueFromSceneInstance(instance, knownProp.path, this.formatters);
    }
    peekKnownPropertyValueByPath(id: IdBimScene, path: (string|number)[]): BasicPropertyValue|undefined {
        const instance = this.sceneInstances.peekById(id);
        if (!instance) {
            return undefined;
        }
        return readKnownPropValueFromSceneInstance(instance, path, this.formatters);
    }

    *waitTillCompletion(timeoutMs: number = 3_000) {
        const start = performance.now();
        while (performance.now() - start < timeoutMs) {
            const value = this.poll();
            if (value instanceof Success || value instanceof Failure) {
                return value;
            } else if (value instanceof InProgress) {
                yield Yield.NextFrame;
            } else {
                return new Failure({ msg: "unexpected state" });
            }
        }

        return new Failure({ msg: "timeout" });
    }

    private _mergePropsDescriptions_r(
        instanceTypeIdent: string,
        descriptions: KnownPropsR,
        result: Map<string, BasicPropertyDescription>,
    ): boolean {
        let changed = false;
        for (const descr of descriptions.values()) {
            if (descr instanceof KnownPropertyDescriptionPerType) {
                if (descr.typeFlags === 0 || descr.mergedPath.includes(' | _')) {
                    continue;
                }
                let known: Writeable<BasicPropertyDescription> | undefined = result.get(descr.mergedPath);

                if (!known) {
                    known = new BasicPropertyDescription(descr.mergedPath, descr.path, 0, []);
                    result.set(descr.mergedPath, known);
                }
                if (!known.typeIdentsFoundIn.includes(instanceTypeIdent)) {
                    known.typeIdentsFoundIn.push(instanceTypeIdent);
                    changed = true;
                }
                if ((known.basicTypes & descr.typeFlags) !== descr.typeFlags) {
                    known.basicTypes |= descr.typeFlags;
                    changed = true;
                }

            } else if (this._mergePropsDescriptions_r(instanceTypeIdent, descr, result)) {
                changed = true;
            }
        }
        return changed;
    }

    private _extractKnownLegacyProps(knownPaths: KnownPropsR, legacyProps: PropertiesCollection) {
        let versionChange = 0;
        for (const prop of legacyProps.values()) {
            let flags = PropertyViewBasicTypes.None;
            if (prop.isNumeric()) {
                flags |= PropertyViewBasicTypes.Numeric;
            } else if (prop.isText()) {
                flags |= PropertyViewBasicTypes.String;
            } else if (prop.isBoolean()) {
                flags |= PropertyViewBasicTypes.Boolean;
            }

            let descr = knownPaths.get(prop._mergedPath);

            if (!descr) {
                const fullPath = ['props', ...prop.path];
                const fullPathMerged = fullPath.join(' | ');
                descr = new KnownPropertyDescriptionPerType(fullPathMerged, fullPath, flags, [BimProperty]);
                knownPaths.set(fullPathMerged, descr);
                versionChange += 1;
            } else if (descr instanceof KnownPropertyDescriptionPerType && descr.typeFlags !== flags) {
                descr.typeFlags |= flags;
                versionChange += 1;
            }
        }
        return versionChange;
    }

    _reusedPropsPath: (string|number)[] = [];

    private _extractKnownPropsDescriptionsFromProps_r(
        knownPaths: KnownPropsR,
        pg: PropsGroupBase,
        path: (string|number)[],
    ) {
        let versionChange = 0;
        for (const key of ObjectUtils.keysIncludingGetters(pg)) {
            const v = pg[key];
            let known = knownPaths.get(key);

            if (v instanceof PropertyBase) {
                if (known instanceof Map) {
                    continue; // not supported atm, skip
                }
                if (!known) {
                    versionChange += 1;
                    const propFullPath = [...path, key];
                    const mergedPath = BimProperty.MergedPath(propFullPath);
                    known = new KnownPropertyDescriptionPerType(
                        mergedPath,
                        propFullPath,
                        PropertyViewBasicTypes.None,
                        []
                    )
                    knownPaths.set(key, known);
                }
                if (!(known.ctors.includes(v.constructor as any))) {
                    known.ctors.push(v.constructor as any);
                    versionChange += 1;
                    const basicTypes = extractBasicPropsViewFromProperty(v);

                    if (typeof basicTypes === 'number') {
                        known.typeFlags |= basicTypes;
                    } else if (typeof basicTypes === 'object') {
                        for (const [subKey, subType] of Object.entries(basicTypes)) {
                            const subPath = [...known.path, subKey];
                            const subMergedPath = subPath.join(' | ');
                            const subKnown = new KnownPropertyDescriptionPerType(
                                subMergedPath,
                                subPath,
                                subType,
                                known.ctors,
                            );
                            knownPaths.set(subKey, subKnown);
                        }
                    }

                    // // ONLY CHECK TYPE ONCE PER PROPERTY CONSTRUCTOR
                    // if (typeof (v as any).value === 'number') {
                    //     known.typeFlags |= PropertyViewBasicTypes.Numeric;
                    // } else if (typeof (v as any).value === 'string') {
                    //     known.typeFlags |= PropertyViewBasicTypes.String;
                    // } else if (typeof (v as any).value === 'boolean') {
                    //     known.typeFlags |= PropertyViewBasicTypes.Boolean;
                    // }
                    // else if (v instanceof NumericArrayPropertyBase) {
                    //     known.typeFlags |= PropertyViewBasicTypes.NumericArray;
                    // }
                }
            } else if (v instanceof PropsGroupBase) {
                if (known instanceof KnownPropertyDescriptionPerType) {
                    this.logger.batchedWarn('props types collision, cant handle yet', key);
                    continue; // not supported atm, skip
                }
                if (!known) {
                    versionChange += 1;
                    known = new Map();
                    knownPaths.set(key, known);
                }
                path.push(key);
                versionChange += this._extractKnownPropsDescriptionsFromProps_r(
                    known,
                    v,
                    path,
                );
                path.pop();
            } else if (Array.isArray(v)) {
                // TODO: support array of props
            }
        }
        return versionChange;
    }

}

function transformKnownProps(instanceTypes: string[]): BasicPropertyDescription[] {
    return  [
        BasicPropertyDescription.new(['transform', 'position', 'x'], PropertyViewBasicTypes.Numeric, instanceTypes),
        BasicPropertyDescription.new(['transform', 'position', 'y'], PropertyViewBasicTypes.Numeric, instanceTypes),
        BasicPropertyDescription.new(['transform', 'position', 'z'], PropertyViewBasicTypes.Numeric, instanceTypes),
        BasicPropertyDescription.new(['transform', 'rotation', 'x'], PropertyViewBasicTypes.Numeric, instanceTypes),
        BasicPropertyDescription.new(['transform', 'rotation', 'y'], PropertyViewBasicTypes.Numeric, instanceTypes),
        BasicPropertyDescription.new(['transform', 'rotation', 'z'], PropertyViewBasicTypes.Numeric, instanceTypes),
    ];
}


export function readKnownPropValueFromSceneInstance(
    instance: SceneInstance,
    path: (string|number)[],
    formatters: BasicViewFormatters,
): BasicPropertyValue|undefined {
    const field = path[0];
    let value: BasicPropertyValue|undefined = undefined;
    if (field === 'props') {

        // might be legacy property
        const legacyProp = instance.properties.get(BimProperty.MergedPath(path.slice(1)));
        if (legacyProp) {
            return {
                value: legacyProp.value,
                unit: legacyProp.unit ?? undefined,
            };
        }

        let prop = instance.props.getAtPath(path, 1);
        
        if (prop instanceof PropertyBase) {
            return extractBasicPropertyValue(formatters, prop, undefined);
        }
        prop = instance.props.getAtPath(path.slice(1, -1), 0);
        if (prop instanceof PropertyBase) {
            return extractBasicPropertyValue(formatters, prop, path[path.length - 1] as string);
        }
        return undefined;

    } else if (field === 'transform') {
        if (path[1] === 'position') {
            if (path[2] === 'x') {
                value = { value: instance.worldMatrix.elements[12 + 0], unit: 'm' };
            } else if (path[2] === 'y') {
                value = { value: instance.worldMatrix.elements[12 + 1], unit: 'm' };
            } else if (path[2] === 'z') {
                value = { value: instance.worldMatrix.elements[12 + 2], unit: 'm' };
            }
        } else if (path[1] === 'rotation') {
            reusedM.extractRotation(instance.worldMatrix);
            reusedEuler.setFromRotationMatrix(reusedM);
            if (path[2] === 'x') {
                value = { value: reusedEuler.x, unit: 'rad' };
            } else if (path[2] === 'y') {
                value = { value: reusedEuler.y, unit: 'rad' };
            } else if (path[2] === 'z') {
                value = { value: reusedEuler.z, unit: 'rad' };
            }
        }
    }

    return value;
}
const reusedM = new Matrix4();
const reusedEuler = new Euler();


function extractBasicPropsViewFromProperty(p: PropertyBase): PropertyViewBasicTypes | { [key: string]: PropertyViewBasicTypes }|undefined {
    const basicTypes = CustomPropsRegistry.tryGetBasicTypesViewForClass(p.constructor as any);
    if (!basicTypes) {
        return undefined;
    }
    return basicTypes.basicTypes
}

function extractBasicPropertyValue(formatters: BasicViewFormatters, p: PropertyBase, path?: string): BasicPropertyValue|undefined {
    const basicTypes = CustomPropsRegistry.tryGetBasicTypesViewForClass(p.constructor as any);
    if (!basicTypes) {
        return undefined;
    }
    return basicTypes.toBasicValues(formatters, p, path);
}


type PropertyCtor = {new(args: any): PropertyBase | BimProperty}

class KnownPropertyDescriptionPerType {
    constructor(
        readonly mergedPath: string,    
        readonly path: (string|number)[],
        public typeFlags: PropertyViewBasicTypes,
        readonly ctors: PropertyCtor[],
        readonly units: string[] = [],
    ) {
    }
}


type KnownPropsR = Map<string, KnownPropertyDescriptionPerType | Map<string, KnownPropertyDescriptionPerType>>;
