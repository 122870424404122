import { Vector2, Vector4 } from 'math-ts';
import { DoubleSide, NormalBlending } from '../3rdParty/three'
import type { ShaderBase } from './ShaderBase';

export const HighlightShader: ShaderBase = {

    name: 'hgihlight',
	
    uniforms: {
		color: { value: new Vector4() },
		resolution: { value: new Vector2(100, 100) },
    },

    defines: {
        'NEEDS_NORMALS': true
    },
    
    params: {
        transparent: true,
        blending: NormalBlending,
        premultipliedAlpha: true,
        side: DoubleSide,
        // polygonOffsetFactor: -2,
        // polygonOffsetUnits: 2,
        // polygonOffset: true,

    },

	vertexShader: `

	
		#include <kr_std_uniforms>
		#include <kr_std_attributes>
		#include <kr_std_vars>
		#include <common_math>
		#include <sdfs>
	
		void main() {

			#include <kr_std_vars_calc>
			
		}

	`,

	fragmentShader: `

		#include <kr_std_uniforms>
		#include <kr_std_vars>
		#include <sdfs>

		uniform vec4 color;
		
		void main() {

			#include <kr_std_pixel>

			gl_FragColor = color;
			#include <kr_alpha_test>
			gl_FragColor.rgb *= gl_FragColor.a;
		}

	`
};