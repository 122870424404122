import { Matrix3, Vector2 } from "math-ts";
import { Object2D } from "./Object2D";
import { VectorPrimitiveCircle, VectorPrimitiveRectangle, VectorPrimitiveText } from ".";
import { CubicBezier, VectorPrimitive, VectorPrimitivePath } from "./VectorPrimitives";

export function createSVGFromObject2D(object: Object2D) {
    const svg = tag<SVGSVGElement>('svg');
    svg.setAttribute('width', '100%');
    svg.setAttribute('xmlns', "http://www.w3.org/2000/svg")
    svg.setAttribute('preserveAspectRatio', '');

    const groupElement = createSVGGElementFromObject2D(object);

    const aabb = object.aabb.clone().expandByScalar(200);
    svg.appendChild(groupElement);
    svg.setAttribute(
        'viewBox',
        `${aabb.min.x} ${aabb.min.y} ${aabb.width()} ${aabb.height()}`
    )
    return svg;
}

export function createSVGGElementFromObject2D(object: Object2D) {
    const group = tag<SVGGElement>('g');
    group.setAttribute('_object_name', object.name ?? '')
    if (!object.matrix.isIdentity()) {
        group.setAttribute('transform', formatMatrix(object.matrix));
    }

    // sort by rendering order
    const sortedItems: Array<Object2D | VectorPrimitive> = [
        ...object.primitives,
        ...object.children,
    ].reverse().sort((l, r) => l.zIndex - r.zIndex)

    for (const item of sortedItems) {
        if (item instanceof VectorPrimitive) {
            const primitive = item;
            let element: SVGElement;
            if (primitive instanceof VectorPrimitiveRectangle) {
                element = createSvgRectangle(primitive);
            } else if (primitive instanceof VectorPrimitiveText) {
                element = createSvgText(primitive);
            } else if (primitive instanceof VectorPrimitiveCircle) {
                element = createSvgCircle(primitive);
            } else if (primitive instanceof VectorPrimitivePath) {
                element = createSvgPath(primitive);
            } else {
                console.warn('unsupported vector primitive', primitive)
                continue;
            }
            if (primitive.matrix && !primitive.matrix.isIdentity()) {
                element.setAttribute('transform', formatMatrix(primitive.matrix))
            }
            group.appendChild(element);
        } else {
            //// render children primitives
            const child = item;
            const childGroup = createSVGGElementFromObject2D(child)
            group.appendChild(childGroup);
        }
    }

    return group;
}

function createSvgRectangle(primitive: VectorPrimitiveRectangle) {
    const rect = tag<SVGRectElement>('rect')
    const cx = primitive.cx ?? 0;
    const cy = primitive.cy ?? 0;
    rect.setAttribute('x', cx - primitive.width / 2 + '')
    rect.setAttribute('y', cy - primitive.height / 2 + '')
    rect.setAttribute('width', primitive.width + '')
    rect.setAttribute('height', primitive.height + '')
    rect.setAttribute('fill', primitive.fill ?? 'none')
    if (primitive.stroke) {
        rect.setAttribute('stroke', primitive.stroke)
    } else {
        rect.setAttribute('stroke', primitive.strokeWidth ? 'black' : 'none')
    }
    rect.setAttribute('stroke-width', (primitive.strokeWidth ?? 0) + '')
    return rect;
}

function createSvgText(primitive: VectorPrimitiveText) {
    const text = tag<SVGTextElement>('text');
    const fontSize = primitive.fontSize;
    text.setAttribute('font-size', fontSize + '');
    text.setAttribute('text-anchor', primitive.anchor ?? 'middle');
    text.setAttribute('x', (primitive.x ?? 0) + '')
    text.setAttribute('y', (primitive.y ?? 0) + '')
    text.setAttribute('width', 5 + '')
    text.setAttribute('height', fontSize + '')
    text.setAttribute('alignment-baseline', primitive.verticalAlignment ?? 'auto')
    text.innerHTML = primitive.text
    return text;
}

function createSvgCircle(primitive: VectorPrimitiveCircle) {
    const circle = tag<SVGCircleElement>('circle')
    circle.setAttribute('fill', primitive.fill ?? 'none')
    circle.setAttribute('stroke', primitive.stroke ?? 'none')
    circle.setAttribute('stroke-width', (primitive.strokeWidth ?? 0) + '')
    circle.setAttribute('r', primitive.radius + '')
    circle.setAttribute('cx', primitive.cx + '');
    circle.setAttribute('cy', primitive.cy + '');
    return circle;
}

function createSvgPath(primitive: VectorPrimitivePath) {
    const path = tag<SVGPathElement>('path');
    path.setAttribute('fill', primitive.fill ?? 'none');
    if (primitive.stroke) {
        path.setAttribute('stroke', primitive.stroke);
    } else if (primitive.strokeWidth) {
        path.setAttribute('stroke', 'black')
    }
    path.setAttribute('stroke-width', (primitive.strokeWidth ?? 0) + '')

    const pathStrComponents: string[] = []
    for (const path of primitive.paths) {
        const [firstItem] = path.items;
        if (!firstItem) {
            continue
        }
        let fromPoint: Vector2
        if (firstItem instanceof Vector2) {
            fromPoint = firstItem
        } else if (firstItem instanceof CubicBezier) {
            fromPoint = firstItem.from
        } else {
            fromPoint = firstItem.from
        }
        pathStrComponents.push(`M ${fromPoint.x} ${fromPoint.y}`)
        for (const item of path.items) {
            if (item instanceof Vector2) {
                pathStrComponents.push(`L ${item.x} ${item.y}`)
            } else if (item instanceof CubicBezier) {
                pathStrComponents.push(`L ${item.from.x} ${item.from.y}`)
                pathStrComponents.push(`C ${item.fromDir.x} ${item.fromDir.y}, ${item.toDir.x} ${item.toDir.y}, ${item.to.x}, ${item.to.y}`);
            } else {
                pathStrComponents.push(`L ${item.from.x} ${item.from.y}`)
                pathStrComponents.push(`Q ${item.control.x} ${item.control.y}, ${item.to.x}, ${item.to.y}`);
            }
        }
        if (path.closedPath) {
            pathStrComponents.push('Z')
        }
    }

    path.setAttribute('d', pathStrComponents.join(' '))
    return path;
}

export function tag<T>(tag: string): T {
    return document.createElementNS("http://www.w3.org/2000/svg", tag) as T;
}

function formatMatrix(m: Matrix3) {
    return 'matrix(' +
        (m.elements[0]).toFixed(4) + ' ' +
        (m.elements[1]).toFixed(4) + ' ' + // -
        (m.elements[3]).toFixed(4) + ' ' + // -
        (m.elements[4]).toFixed(4) + ' ' +
        (m.elements[6]).toFixed(4) + ' ' +
        (m.elements[7]).toFixed(4) +
    ')'
}

