import { EmptyIterator, IterUtils, LegacyLogger } from 'engine-utils-ts';

import type { IdBimScene } from '../';

const ALLOWED_DEPTH: number = 100

export class InstanceHierarchyData {

    constructor(
        public readonly id: IdBimScene,
        public parentId: IdBimScene | 0,
        public parentRef: Readonly<InstanceHierarchyData> | null,
        public children: null | InstanceHierarchyData[] = null,
        public sortKey: number,
    ) {

    }

    depth(): number {
        let depth = 0;
        let h: Readonly<InstanceHierarchyData> = this;
        while (h.parentRef) {
            depth += 1;
            h = h.parentRef;
            if (depth > ALLOWED_DEPTH) {
                throw new Error(`depth is ${depth}, this is probably erroneous cycle`);
            }
        }
        return depth;
    }

    isDescendantOf(id: IdBimScene, allObjects: Map<IdBimScene, InstanceHierarchyData>) {
        let h: InstanceHierarchyData = this;
        while (h.parentId) {
            if (h.parentId === id) {
                return true;
            }
            const parentH = allObjects.get(h.parentId);
            if (parentH) {
                h = parentH;
            } else {
                break;
            }
        }
        return false;
    }
}

export enum HierarchyDirtyFlags {
    // Self = 1,
    Parent = 2,
    Children = 4,
    ChildrenSort = 8,
}

export class Hierarchy {

    _rootObjects = new Map<IdBimScene, InstanceHierarchyData>();

    _allObjects = new Map<IdBimScene, InstanceHierarchyData>();

    _dirtyFlags = new Map<IdBimScene, HierarchyDirtyFlags>();

    constructor(

    ) {

    }

    consumeDirtyFlags(): Map<IdBimScene | 0, HierarchyDirtyFlags> {
        const flags = this._dirtyFlags;
        this._dirtyFlags = new Map();
        return flags;
    }

    gatherIdsWithSubtreesOf(params: {ids: IdBimScene[], sortParentFirst?: boolean}): IdBimScene[] {
		const depthPerId = new Map<IdBimScene, number>();// save depth

        const ids = params.ids;

        function addChild(childId: IdBimScene, depth: number): boolean {
            const size = depthPerId.size;
            depthPerId.set(childId, depth);
            return size != depthPerId.size;
        }
		for (const id of ids) {
			this.traverseRootToLeavesDepthFirstFrom(id, addChild);
		}

        if (params.sortParentFirst) {
            const tuples = Array.from(depthPerId);
            tuples.sort((l, r) => l[1] - r[1]);
            return tuples.map(t => t[0]);
        }
        return Array.from(depthPerId.keys());
	}

    getNonOverlappingIdsFrom(ids: IdBimScene[]): Set<IdBimScene> {
        const result = new Set<IdBimScene>();
        this.sortByDepth(ids);

        outer:
        for (const id of ids) {
            const h = this._allObjects.get(id);
            if (!h) {
                continue;
            }
            for (const rId of result) {
                if (h.isDescendantOf(rId, this._allObjects)) {
                    continue outer;
                }
            }
            result.add(id);
        }
        return result;
    }

    findAmongAscendandsOf(id: IdBimScene, check: (asendantId: IdBimScene) => boolean): IdBimScene | undefined {
        const h = this._allObjects.get(id);
        if (!h?.parentId) {
            return undefined;
        }
        if (check(h.parentId)) {
            return h.parentId;
        }
        return this.findAmongAscendandsOf(h.parentId, check);
    }

    traverseChildrenDepthFirst<Arg>(
        id: IdBimScene,
        callback: (
            id: IdBimScene,
            arg: Readonly<Arg>,
            depth: number,
            childrenCount: number,
            siblingsCount: number
        ) => Arg,
        arg: Readonly<Arg>,
    ) {
        const h = this._allObjects.get(id);
        if (h) {
            const siblingsCount = h.parentRef?.children?.length ?? 0;
            Hierarchy._traverseChildrenDepthFirst_r(id, h, siblingsCount, callback, h.depth(), arg);
        }
    }

    static _traverseChildrenDepthFirst_r<Arg>(
        id: IdBimScene,
        h: InstanceHierarchyData,
        siblingsCount: number,
        callback: (
            id: IdBimScene,
            arg: Readonly<Arg>,
            depth: number,
            childrenCount: number,
            siblingsCount: number
        ) => Arg,
        depth: number,
        arg: Readonly<Arg>,
    ) {
        if (depth > ALLOWED_DEPTH) {
            throw new Error(`recursive traversing gone too far, propably an error ${depth} ${callback.name}`);
        }
        arg = callback(id, arg, depth, h.children?.length ?? 0, siblingsCount);
        if (h.children?.length) {
            for (const ch of h.children) {
                Hierarchy._traverseChildrenDepthFirst_r(ch.id, ch, h.children.length, callback, depth + 1, arg);
            }
        }
    }

    /**
     * @param {Function} callback if callback function returns false,
     * no more iterations will be done
     */
    traverseLeaveToRoot(
        leaveId: IdBimScene,
        callback: (id: IdBimScene) => boolean,
        skipFirst: boolean = false,
    ) {
        let depth = 0;
        let curNodeId = leaveId;
        while (true) {
            if (depth > ALLOWED_DEPTH) {
                throw new Error(`depth while traversing leave to root is ${depth}, this is probably erroneous cycle`);
            }
            let curr = this._allObjects.get(curNodeId);
            if (!curr) {
                return;
            }
            let shouldContinue = true;
            if (skipFirst && depth === 0) {
            } else {
                shouldContinue = callback(curNodeId);
            }
            if (!shouldContinue || !curr.parentRef){
                return;
            }
            curNodeId = curr.parentId;
            depth += 1;
        }
    }

    traverseRootToLeavesDepthFirstFrom(
        rootId: IdBimScene,
        callback: (id: IdBimScene, depth: number) => boolean,
        skipFirst: boolean = false,
    ) {
        const hierObj = this._allObjects.get(rootId)
        if (!hierObj) {
            return;
        }
        if (skipFirst) {
            if (!hierObj.children) {
                return;
            }
            for (const h of hierObj.children) {
                this._traverseRootToLeavesDepthFirstFrom(h, callback, hierObj.depth() + 1);
            }
        } else {
            this._traverseRootToLeavesDepthFirstFrom(hierObj, callback, hierObj.depth());
        }
    }

    _traverseRootToLeavesDepthFirstFrom(
        hierObj: InstanceHierarchyData,
        callback: (id: IdBimScene, depth: number) => boolean,
        depth: number
    ) {
        if (!callback(hierObj.id, depth)) {
            return;
        }
        if (!hierObj.children) {
            return;
        }
        for (const h of hierObj.children) {
            this._traverseRootToLeavesDepthFirstFrom(h, callback, depth + 1);
        }
    }

    isValidParentFor(id: IdBimScene, parentId: IdBimScene | 0): boolean {
        for (let i = 0; parentId != 0; ++i) {
            if (id === parentId) {
                return false;
            }
            if (i === ALLOWED_DEPTH) {
                return false;
            }
            const parent = this._allObjects.get(parentId);
            if (!parent) {
                return false;
            }
            parentId = parent.parentId;
        }
        return true;
    }

    *iteratorOfChildrenOf(id: IdBimScene): Iterable<IdBimScene> {
        const s = this._allObjects.get(id);
        if (s && s.children) {
            for (const ch of s.children) {
                yield ch.id;
            }
        } else {
            return EmptyIterator;
        }
    }

    sortByDepth(ids: IdBimScene[]) {
        ids.sort((lid, rid) => {
            const lh = this._allObjects.get(lid)?.depth() ?? 0;
            const rh = this._allObjects.get(rid)?.depth() ?? 0;
            return lh - rh;
        })
    }
    sortByDepthDescending(ids: IdBimScene[]) {
        ids.sort((lid, rid) => {
            const lh = this._allObjects.get(lid)?.depth() ?? 0;
            const rh = this._allObjects.get(rid)?.depth() ?? 0;
            return rh - lh;
        })
    }

    sortByDepthAndSortKey(ids: IdBimScene[]) {
        ids.sort((lid, rid) => {
            const lh = this._allObjects.get(lid);
            const rh = this._allObjects.get(rid);
            const ld = lh?.depth() ?? 0;
            const rd = rh?.depth() ?? 0;
            const lSortKey = lh?.sortKey ?? 0;
            const rSortKey = rh?.sortKey ?? 0;
            return ld === rd ? lSortKey - rSortKey : ld - rd;
        });
    }

    depthOf(id: IdBimScene): number | undefined {
        return this._allObjects.get(id)?.depth();
    }

    markDirty(id: IdBimScene, flags: HierarchyDirtyFlags) {
        this._dirtyFlags.set(id, (this._dirtyFlags.get(id) as number | 0) | flags);
    }

    _addChildTo(
        parent: InstanceHierarchyData,
        child: InstanceHierarchyData
    ) {
        if (!parent.children) {
            parent.children = [];
        } else {
            for (const ch of parent.children) {
                if (ch.id === child.id) {
                    LegacyLogger.deferredError(`attempt to add child second time to the same parent`, [parent.id, child.id]);
                    return;
                }
            }
        }
        parent.children.push(child);
        child.parentId = parent.id;
        child.parentRef = parent;
        this.markDirty(parent.id, HierarchyDirtyFlags.Children | HierarchyDirtyFlags.ChildrenSort);
        this.markDirty(child.id, HierarchyDirtyFlags.Parent);
    }

    removeChildFrom(from: IdBimScene, childId: IdBimScene) {
        const parent = this._allObjects.get(from);
        if (!parent) {
            return false;
        }
        const child = this._allObjects.get(childId);
        if (child && parent.children) {
            const childIndex = parent.children?.indexOf(child);
            if (childIndex >= 0) {
                parent.children.splice(childIndex, 1);
            }
        }
        if (child) {
            this.markDirty(from, HierarchyDirtyFlags.Children);
            this.markDirty(childId, HierarchyDirtyFlags.Parent);
        } else {
            LegacyLogger.deferredError('parent child removal: not here', childId);
        }
        return true;
    }

    addNew(id: IdBimScene, parentId: IdBimScene | 0, sortKey: number): boolean {
        if (this._allObjects.has(id)) {
            LegacyLogger.deferredWarn('tried to add hierarchy, but it already exists', id);
            return false;
        }
        let parent: InstanceHierarchyData | null = null;
        if (parentId) {
            parent = this._allObjects.get(parentId) ?? null;
            if (!parent) {
                LegacyLogger.deferredWarn('tried to add hierarchy with invalid parent', id);
                return false;
            }
        }

        const newData = new InstanceHierarchyData(id, parentId, parent, null, sortKey);
        this._allObjects.set(id, newData);
        if (parent) {
            this._addChildTo(parent, newData);
        } else {
            this._rootObjects.set(id, newData);
        }
        // this.markDirty(id, HierarchyDirtyFlags.Self);
        return true;
    }

    delete(id: IdBimScene) {
        const h = this._allObjects.get(id);
        if (!h) {
            return;
        }
        if (h.parentId) {
            this.removeChildFrom(h.parentId, id);
        } else {
            this._rootObjects.delete(id);
        }
        this._allObjects.delete(id);
    }

    updateParentOf(childId: IdBimScene, newParentId: IdBimScene | 0): boolean {
        if (childId == newParentId) {
            LegacyLogger.deferredWarn('attempt to parent itself', childId);
            return false;
        }
        const child = this._allObjects.get(childId);
        if (!child) {
            return false;
        }
        if (child.parentId) {
            this.removeChildFrom(child.parentId, childId);
        } else {
            this._rootObjects.delete(childId)
        }
        const newParent = this._allObjects.get(newParentId);
        if (newParent) {
            this._addChildTo(newParent, child);
        } else {
            child.parentId = 0;
            child.parentRef = null;
            this._rootObjects.set(childId, child);
        }
        return true;
    }

    updateSortKeyOf(childId: IdBimScene, sortKey: number) {
        const child = this._allObjects.get(childId);
        if (child) {
            child.sortKey = sortKey;
            this.markDirty(child.parentId, HierarchyDirtyFlags.ChildrenSort);
        }
    }

    sortChildrenOf(id: IdBimScene | 0) {
        if (id === 0) { // special case for root objects
            // to sort root objects, reinsert them back into dictionary in correct order
            const rootObjects = Array.from(this._rootObjects.values());
            rootObjects.sort(childrenSortFn);
            this._rootObjects.clear();
            for (const obj of rootObjects) {
                this._rootObjects.set(obj.id, obj);
            }
        } else {
            const h = this._allObjects.get(id);
            if (h?.children) {
                h.children.sort(childrenSortFn)
            }
        }
    }

    getIdsInTheSameBucketAs(id: IdBimScene): IdBimScene[] {
        const h = this._allObjects.get(id);
        if (!h) {
            return [];
        }
        if (h.parentRef) {
            this.sortChildrenOf(h.parentRef.id);
            return h.parentRef.children!.map(ch => ch.id);
        } else {
            this.sortChildrenOf(0);
            return IterUtils.mapIter(this._rootObjects.values(), h => h.id);
        }
    }
    getPreviousIdInHierarchyBucketWith(id: IdBimScene): IdBimScene | 0 {
        const sameBucketIds = this.getIdsInTheSameBucketAs(id);
        const indexOf = sameBucketIds.indexOf(id);
        if (indexOf < 0) {
            LegacyLogger.error('prevId: unexpected absence of id in bucket', id);
            return 0;
        }
        return indexOf > 0 ? sameBucketIds[indexOf - 1] : 0;
    }
    getNextIdInHierarchyBucketWith(id: IdBimScene): IdBimScene | 0 {
        const sameBucketIds = this.getIdsInTheSameBucketAs(id);
        const indexOf = sameBucketIds.indexOf(id);
        if (indexOf < 0) {
            LegacyLogger.error('nextId: unexpected absence of id in bucket', id);
            return 0;
        }
        return indexOf < sameBucketIds.length - 1 ? sameBucketIds[indexOf + 1] : 0;
    }
    getTopmostParent(id: IdBimScene): IdBimScene | 0 {
        const node = this._allObjects.get(id);
        if (!node || !node.parentId) {
            return id;
        }
        return this.getTopmostParent(node.parentId);
    }
}

function childrenSortFn(lhs: InstanceHierarchyData, rhs: InstanceHierarchyData) {
    return lhs.sortKey - rhs.sortKey;
}

export function sortBatchByParentFirst<T extends { spatialParentId?: IdBimScene }>(
    entities: [IdBimScene, T][],
	currentHierarchy: Readonly<Hierarchy>,
): [IdBimScene, Partial<T>][]
{
    // sort to make sure that parent objects are allocated before child ones

	const hierarchyObjects = currentHierarchy._allObjects;

    const sortedResult = new Map<IdBimScene, Partial<T>>();

    let toAddLater: [IdBimScene, Partial<T>][] = [];
    // first add all of the objects without parents
    for (const t of entities) {
        const spatialParentId = t[1].spatialParentId;
        if (!spatialParentId
			|| sortedResult.has(spatialParentId)
			|| hierarchyObjects.has(spatialParentId)
		) {
            sortedResult.set(t[0], t[1]);
        } else {
            toAddLater.push(t);
        }
    }

    // now iterate through what's left, as many times as necessary
    let toRetryNextIteration: [IdBimScene, Partial<T>][] = []
    do {
        toRetryNextIteration.length = 0;
        for (const t of toAddLater) {
            if (sortedResult.has(t[1].spatialParentId!)) {
                sortedResult.set(t[0], t[1]);
            } else {
                toRetryNextIteration.push(t);
            }
        }
        if (toRetryNextIteration.length == 0 || toRetryNextIteration.length == toAddLater.length) {
            break;
        }
        [toAddLater, toRetryNextIteration] = [toRetryNextIteration, toAddLater];

    } while (toAddLater.length > 0);

    for (const t of toAddLater) {
        sortedResult.set(t[0], t[1]);
    }
    if (sortedResult.size > entities.length) {
        throw new Error('sort by parent is broken, result larger than input');
    }
    return Array.from(sortedResult);
}
