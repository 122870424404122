import type { Result, LongTask } from 'engine-utils-ts';
import { DeferredPromise, getUniqueEntityIdentifier } from 'engine-utils-ts';

function defaultDelayFor(type: NotificationType) {
    switch (type) {
        case NotificationType.Debug: return 3000;
        case NotificationType.Info: return 3000;
        case NotificationType.Success: return 2000;
        case NotificationType.Warning: return 5000;
        case NotificationType.Error: return 7000;
        default: console.warn('unexpected notification type'); return 5000;
    }
}

export enum NotificationType {
    Debug,
    Info,
    Success,
    Warning,
    Error
}

export interface NotificationActionDescription<T extends any[]> {
    name: string;
    actionArgs: T;
    action: (...args: T) => void;
}

export interface NotificationTaskDescription {
    task: LongTask<any>;
    resultHeaderGetter?: (result: Result<any>) => string;
}

interface GroupSettings {
    processId: number;
    groupName: string;
    isRootInGroup: boolean;
}

interface NotificationSourceItem {
    header: string | ((arg: any) => string);
    description: string | ((arg: any) => string);
}
export interface NotificationSource {
    [key: string]: NotificationSourceItem
}
export function createNotificationSource<NS extends NotificationSource>(source: NS): NS {
    return source;
}

interface NotificationBasicParams<NS extends NotificationSource> {
    type: NotificationType;
    source: NS;
    key: keyof NS;
    headerArg?: any;
    descriptionArg?: any;
    onClose?: () => void;
    removeAfter?: boolean;
    removeAfterMs?: number;
    addToNotificationsLog: boolean;
}

interface NotificationWithTaskParams<NS extends NotificationSource> extends NotificationBasicParams<NS> {
    taskDescription: NotificationTaskDescription;
}

interface NotificationWithActionParams<ActionArgs extends any[], NS extends NotificationSource> extends NotificationBasicParams<NS> {
    actionDescription: NotificationActionDescription<ActionArgs>;
}

function getNotificationText<NS extends NotificationSource>(params: NotificationBasicParams<NS> ): { header: string; message: string } {
    const data = params.source[params.key];

    let header: NotificationSourceItem['header'];
    let description: NotificationSourceItem['description'];
    if (typeof data === 'object' && data && 'header' in data && 'description' in data) {
        header = data.header as NotificationSourceItem['header'];
        description = data.description as NotificationSourceItem['description'];
    } else {
        header = '';
        description = '';
        console.warn('unexpected notification source data', params.key, data, params.source);
    }

    return {
        header: header instanceof Function ? header(params.headerArg) : header,
        message: description instanceof Function ? description(params.descriptionArg) : description
    }
}

export class NotificationDescription {

	readonly uniqueIdent: string;

    readonly type: NotificationType;
    readonly header: string;
    readonly message?: string;
    readonly taskDescription?: NotificationTaskDescription;
    readonly actionDescription?: NotificationActionDescription<any>;
    readonly onClose?: () => void;
    readonly removeAfter: boolean;
    readonly removeAfterMs: number;
    readonly addToNotificationsLog: boolean;
    public group?: GroupSettings;

    private constructor(params: {
        type: NotificationType;
        header: string;
        message?: string;
        onClose?: () => void;
        removeAfter?: boolean;
        removeAfterMs?: number;
        taskToTrack?: NotificationTaskDescription;
        actionDescription?: NotificationActionDescription<any>;
        addToNotificationsLog: boolean;
    }) {
        this.uniqueIdent = getUniqueEntityIdentifier('notification', params.header);
        this.type = params.type;
        this.header = params.header;
        this.message = params.message;
        this.taskDescription = params.taskToTrack;
        this.actionDescription = params.actionDescription;
        this.onClose = params.onClose;
        this.removeAfter = params.removeAfter ?? true;
        this.removeAfterMs = params.removeAfterMs ?? defaultDelayFor(params.type);
        this.addToNotificationsLog = params.addToNotificationsLog;
    }

    static newBasic<NS extends NotificationSource>(params: NotificationBasicParams<NS>) {
        const delay = params.removeAfterMs ?? defaultDelayFor(params.type);
        const { header, message } = getNotificationText(params);

        return new NotificationDescription({
            type: params.type,
            header,
            message,
            onClose: params.onClose,
            removeAfter: params.removeAfter,
            removeAfterMs: delay,
            addToNotificationsLog: params.addToNotificationsLog
        })
    }

    static newWithTask<NS extends NotificationSource>(params: NotificationWithTaskParams<NS>) {
        const delay = params.removeAfterMs ?? defaultDelayFor(params.type);
        const { header, message } = getNotificationText(params);

        return new NotificationDescription({
            type: params.type,
            header,
            message,
            onClose: params.onClose,
            removeAfterMs: delay,
            taskToTrack: params.taskDescription,
            addToNotificationsLog: params.addToNotificationsLog
        })
    }

    static newWithActionToStart<ActionArgs extends any[], NS extends NotificationSource>(
        params: NotificationWithActionParams<ActionArgs, NS>
    ) {
        const delay = params.removeAfterMs ?? defaultDelayFor(params.type);
        const { header, message } = getNotificationText(params);
        
        const { action, actionArgs } = params.actionDescription;

        return new NotificationDescription({
            type: params.type,
            header,
            message,
            onClose: params.onClose,
            removeAfter: params.removeAfter,
            removeAfterMs: delay,
            addToNotificationsLog: params.addToNotificationsLog,
            actionDescription: {
                name: params.actionDescription.name,
                actionArgs: [],
                action: () => action(...actionArgs)
            },
        })
    }

    static newWithActionToCancel<ActionArgs extends any[], NS extends NotificationSource>(params: NotificationWithActionParams<ActionArgs, NS>) {
        const delay = params.removeAfterMs ?? defaultDelayFor(params.type);
        const { header, message } = getNotificationText(params);
        const promise = DeferredPromise.delay(delay);
        const { action, actionArgs } = params.actionDescription;
        promise.promise.then(() => action(...actionArgs));
        return new NotificationDescription({
            type: params.type,
            header,
            message,
            onClose: params.onClose,
            removeAfterMs: delay,
            addToNotificationsLog: params.addToNotificationsLog,
            actionDescription: {
                name: 'Cancel',
                actionArgs: [],
                action: () => promise.reject('cancel_action')
            },
        })
    }
}

