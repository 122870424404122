<script lang="ts">
import { getContext } from 'svelte';
import { navigate } from 'svelte-routing';
import type { AuthHub } from './auth';

import Topbar from './dashboard/Topbar.svelte'

let auth = getContext<AuthHub>('authHub');

$: {
    if ($auth.authData?.company) {
        setTimeout(() => navigate('dashboard'), 100);
    }
}

</script>

<Topbar />
<div
    style="
        flex: 1;
        flex-direction: column;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 200px;
    "
>
    <p
        style="opacity: 0.2; font-size: 48px;"
        class="mdc-typography mdc-typography--headline1"
    >
        Thank you for registering!
    </p>
    <p
        style="text-align: center"
        class="mdc-typography mdc-typography--headline2"
    >
        To get access, please reach out to us at info@pvfarm.io
    </p>
</div>

