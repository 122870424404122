import { Vector2 } from 'math-ts';
import type { ShaderBase } from '../shaders/ShaderBase';
import { AtlasPixelSize, GlyphUvHeight } from './CharAtlasData';


export const TextShader: ShaderBase = Object.freeze({
	
	name: 'textShader',

	extensions: {
		derivatives: true,
	},

	uniforms: {
		'atlasTextureSize': { value: new Vector2(AtlasPixelSize, AtlasPixelSize) },
		'pxRange': { value: 12 },
		'fontAtlas': { value: null },
		// "glyphColor": { value: Vector4.allocate(0.0, 0.0, 0.0, 0.5) },
		'backgroundOpacity': { value: 0.0 },
	},

	vertexShader: `
	varying vec2 vUv;
	uniform vec2 upside;
	varying float vHeight;
	
	void main() {
		vUv = uv;
		vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
		vHeight = position.y * upside.y / ${GlyphUvHeight} + 0.5;
		gl_Position = projectionMatrix * mvPosition;
	}
	
	`,

	fragmentShader: `
	
	uniform sampler2D fontAtlas;
	uniform vec2 atlasTextureSize;
	uniform float pxRange;
	uniform vec4 glyphColor;
	uniform float backgroundOpacity;
	
	#include <common>
	varying vec2 vUv;
	varying float vHeight;
	uniform vec2 upside;

	float median(float r, float g, float b) {
		return max(min(r, g), min(max(r, g), b));
	}

	float sample_dist_field(vec2 uv, vec2 msdfUnit) {
		vec3 s = texture2D(fontAtlas, uv).rgb;
		float sigDist = median(s.r, s.g, s.b) - 0.5;
		sigDist *= dot(msdfUnit, 1.5/fwidth(uv));
		return sigDist + 0.5;
	}

	float sample_font_opacity(vec2 uv, vec2 msdfUnit) {
		float sigDist = sample_dist_field(uv, msdfUnit);

		// float dscale = 0.354; // half of 1/sqrt2; you can play with this
		// vec2 duv = dscale * (dFdx(uv) + dFdy(uv));
		// vec4 box = vec4(uv-duv, uv+duv);
		// float distSum = 	  sample_dist_field( box.xy, msdfUnit )
		// 					+ sample_dist_field( box.zw, msdfUnit )
		// 					+ sample_dist_field( box.xw, msdfUnit )
		// 					+ sample_dist_field( box.zy, msdfUnit );
		// sigDist = (sigDist + distSum * 0.5) / 3.0;

		const float smoothing = 0.1;
		float opacity = smoothstep(0.5 - smoothing, 0.5 + smoothing, sigDist);
		// float opacity = clamp(sigDist, 0.0, 1.0);
		return opacity;
	}


	float underline() {
		const float underlineWidth = 0.1;
		float aa = length(fwidth(vHeight));
		float underline = smoothstep(0.5 * (underlineWidth + aa), 0.5 * (underlineWidth - aa), vHeight);
		return underline * upside.x;
	}

	// float udRoundBox( vec2 p, vec2 b, float r ) {
	// 	return length(max(abs(p)-b+r,0.0))-r;
	// }

	void main() {

		vec2 msdfUnit = pxRange/vec2(atlasTextureSize);
		float sigDist = sample_dist_field(vUv, msdfUnit);

		float opacity = sample_font_opacity(vUv, msdfUnit);
		float dscale = 0.354;
		vec2 duv = dscale * (dFdx(vUv) + dFdy(vUv));
		vec4 box = vec4(vUv-duv, vUv+duv);
		float opacity_sum = sample_font_opacity( box.xy, msdfUnit )
							+ sample_font_opacity( box.zw, msdfUnit )
							+ sample_font_opacity( box.xw, msdfUnit )
							+ sample_font_opacity( box.zy, msdfUnit );
		opacity = (opacity + opacity_sum) / 5.0;
		opacity = max(opacity, underline() * 0.7);

		vec4 color = glyphColor;
		if (opacity < backgroundOpacity){
			color *= backgroundOpacity;
		} else {
			color.a *= opacity;
		}

		gl_FragColor = color;
	}
	`,
});
