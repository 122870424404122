// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { FlatBuffersContourCoordinates } from '../flat-buffers/flat-buffers-contour-coordinates.js';


export class FlatBuffersSurfaceModel {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):FlatBuffersSurfaceModel {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsFlatBuffersSurfaceModel(bb:flatbuffers.ByteBuffer, obj?:FlatBuffersSurfaceModel):FlatBuffersSurfaceModel {
  return (obj || new FlatBuffersSurfaceModel()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsFlatBuffersSurfaceModel(bb:flatbuffers.ByteBuffer, obj?:FlatBuffersSurfaceModel):FlatBuffersSurfaceModel {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new FlatBuffersSurfaceModel()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

name():string|null
name(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
name(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

contoursPoints3d(index: number, obj?:FlatBuffersContourCoordinates):FlatBuffersContourCoordinates|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new FlatBuffersContourCoordinates()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

contoursPoints3dLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

innerPoints3d(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readFloat64(this.bb!.__vector(this.bb_pos + offset) + index * 8) : 0;
}

innerPoints3dLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

innerPoints3dArray():Float64Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? new Float64Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

linearCoordsMinMax2d(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readFloat64(this.bb!.__vector(this.bb_pos + offset) + index * 8) : 0;
}

linearCoordsMinMax2dLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

linearCoordsMinMax2dArray():Float64Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? new Float64Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

geographicCoordsMinMax2d(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.readFloat64(this.bb!.__vector(this.bb_pos + offset) + index * 8) : 0;
}

geographicCoordsMinMax2dLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

geographicCoordsMinMax2dArray():Float64Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? new Float64Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

static startFlatBuffersSurfaceModel(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addName(builder:flatbuffers.Builder, nameOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, nameOffset, 0);
}

static addContoursPoints3d(builder:flatbuffers.Builder, contoursPoints3dOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, contoursPoints3dOffset, 0);
}

static createContoursPoints3dVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startContoursPoints3dVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addInnerPoints3d(builder:flatbuffers.Builder, innerPoints3dOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, innerPoints3dOffset, 0);
}

static createInnerPoints3dVector(builder:flatbuffers.Builder, data:number[]|Float64Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createInnerPoints3dVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createInnerPoints3dVector(builder:flatbuffers.Builder, data:number[]|Float64Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(8, data.length, 8);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addFloat64(data[i]!);
  }
  return builder.endVector();
}

static startInnerPoints3dVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(8, numElems, 8);
}

static addLinearCoordsMinMax2d(builder:flatbuffers.Builder, linearCoordsMinMax2dOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, linearCoordsMinMax2dOffset, 0);
}

static createLinearCoordsMinMax2dVector(builder:flatbuffers.Builder, data:number[]|Float64Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createLinearCoordsMinMax2dVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createLinearCoordsMinMax2dVector(builder:flatbuffers.Builder, data:number[]|Float64Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(8, data.length, 8);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addFloat64(data[i]!);
  }
  return builder.endVector();
}

static startLinearCoordsMinMax2dVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(8, numElems, 8);
}

static addGeographicCoordsMinMax2d(builder:flatbuffers.Builder, geographicCoordsMinMax2dOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, geographicCoordsMinMax2dOffset, 0);
}

static createGeographicCoordsMinMax2dVector(builder:flatbuffers.Builder, data:number[]|Float64Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createGeographicCoordsMinMax2dVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createGeographicCoordsMinMax2dVector(builder:flatbuffers.Builder, data:number[]|Float64Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(8, data.length, 8);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addFloat64(data[i]!);
  }
  return builder.endVector();
}

static startGeographicCoordsMinMax2dVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(8, numElems, 8);
}

static endFlatBuffersSurfaceModel(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createFlatBuffersSurfaceModel(builder:flatbuffers.Builder, nameOffset:flatbuffers.Offset, contoursPoints3dOffset:flatbuffers.Offset, innerPoints3dOffset:flatbuffers.Offset, linearCoordsMinMax2dOffset:flatbuffers.Offset, geographicCoordsMinMax2dOffset:flatbuffers.Offset):flatbuffers.Offset {
  FlatBuffersSurfaceModel.startFlatBuffersSurfaceModel(builder);
  FlatBuffersSurfaceModel.addName(builder, nameOffset);
  FlatBuffersSurfaceModel.addContoursPoints3d(builder, contoursPoints3dOffset);
  FlatBuffersSurfaceModel.addInnerPoints3d(builder, innerPoints3dOffset);
  FlatBuffersSurfaceModel.addLinearCoordsMinMax2d(builder, linearCoordsMinMax2dOffset);
  FlatBuffersSurfaceModel.addGeographicCoordsMinMax2d(builder, geographicCoordsMinMax2dOffset);
  return FlatBuffersSurfaceModel.endFlatBuffersSurfaceModel(builder);
}
}
