import type { Disposable, VersionedValue } from '../';
import { Globals, IterUtils, LocalNotifier, peekCurrentEventFrame, ResolvedPromise } from '../';
import type { EventStackFrame} from './EventsStackFrame';
import { withEventFrame } from './EventsStackFrame';
import type { Observer, ObserverSettings } from './Observables';
import { getUniqueEntityIdentifier } from './Observables';

export class ObservableStream<T> implements VersionedValue {

	readonly identifier: string;
	readonly uniqueIdent: string;
	readonly subscribers = new Map<string, StreamObserver<any>>();
	readonly immmidiateSubscribers = new Map<string, StreamObserver<any>>();
	
	private _holdLastValueForNewSubscribers: boolean;
	private _lastValue: T | undefined = undefined;
	private _disposeListeners?: ((self: ObservableStream<T>) => void)[];
	private _defaultValueForNewSubscribersFactory?: () => T | undefined;

	private _version: number = 0; // increments with every pushed value

	// if we have first value to call subsriber immidieately with
	// do not do this inside the subscribe method
	// because immidate callback even before subscribe method returned
	// may crash initialization/constructor in some external code
	// defer callback, and call it either through promise, after callstack been unwound
	// or the next time pushNext() is called, whatever is earlier
	private _deferredFirstValuesWaitingForCall: [StreamObserver<any>, EventStackFrame, T][] | null = null;

	private _callDeferredFirstValues() {
		if (this._deferredFirstValuesWaitingForCall) {
			const values = this._deferredFirstValuesWaitingForCall;
			this._deferredFirstValuesWaitingForCall = null;
			for (const [obs, ev, args] of values) {
				if (this.immmidiateSubscribers.has(obs.uniqueIdent) || this.subscribers.has(obs.uniqueIdent)) {
					withEventFrame(ev, () => {
						obs.notifCallback(args);
					})
				}
			}
		}
	}

	constructor(params: {
		identifier: string,
		holdLastValueForNewSubscribers?: boolean,
		defaultValueForNewSubscribersFactory?: () => T | undefined,
		disposeListener?: (self: ObservableStream<T>) => void
	}) {
		this.identifier = params.identifier;
		this.uniqueIdent = getUniqueEntityIdentifier('stream-listener', this.identifier);
		this._holdLastValueForNewSubscribers = params.holdLastValueForNewSubscribers ?? false;
		this._defaultValueForNewSubscribersFactory = params.defaultValueForNewSubscribersFactory;
		if (params.disposeListener) {
			this.withDisposeListener(params.disposeListener);
		}
	}

	version(): number {
		return this._version;
	}

	withDisposeListener(disposeListener: (self: ObservableStream<T>) => void) {
		if (!this._disposeListeners) {
			this._disposeListeners = [];
		}
		this._disposeListeners.push(disposeListener);
	}

	notify_later_legacy(...args: any[]) {
		this.pushNext({legacy_args: args} as any)
	}

	pushNext(args: T) {
		this._version += 1;
		this._callDeferredFirstValues();
		if (this._holdLastValueForNewSubscribers) {
			this._lastValue = args;
		}
		if (this.hasSubscibers()) {
			const notifId = Globals.getNotifier().addToQueue(args, this);
			if (this.immmidiateSubscribers.size > 0) {
				LocalNotifier._notifyObservers(this.immmidiateSubscribers, args, peekCurrentEventFrame(), notifId);
			}
		}
	}

	lastValue(): T | undefined {
		return this._lastValue;
	}

	hasSubscibers() {
		return this.subscribers.size > 0 || this.immmidiateSubscribers.size > 0;
	}

	subscribe(args: {
		onNext: (args: T) => void,
		settings?: Partial<ObserverSettings>,
	}): Observer {
		const isImmediateMode = args.settings?.immediateMode ?? false;
		const subs = isImmediateMode ? this.immmidiateSubscribers : this.subscribers;
		const sub = new StreamObserver(
			`${this.identifier}_listener`,
			args.onNext,
			subs,
			isImmediateMode
		);
		if (!args.settings?.doNotNotifyCurrentState) {
			let firstValueToCallBack: T | undefined = undefined;
			if (this._defaultValueForNewSubscribersFactory) {
				try {
					firstValueToCallBack = this._defaultValueForNewSubscribersFactory();
				} catch (e) {
					console.error(`${this.identifier} stream default value factory error`, e);
				} 
			} else if (this._holdLastValueForNewSubscribers) {
				firstValueToCallBack = this.lastValue();
			} else {
				// console.warn(`${this.identifier} does not hold previous values of stream, current value will not be provided`);
			}
			if (firstValueToCallBack !== undefined) {
				const arr = this._deferredFirstValuesWaitingForCall ?? (this._deferredFirstValuesWaitingForCall = []);
				arr.push([sub, peekCurrentEventFrame(), firstValueToCallBack]);
				ResolvedPromise.then(() => {
					this._callDeferredFirstValues();
				})
			}
		}
		return sub;
	}

	public mergeFrom(stream: ObservableStream<T>) {
		if (stream === this) {
			throw new Error(`attempt to merge stream with itself ${this.identifier}`);
		}
		const l = stream.subscribe({
			settings: { doNotNotifyCurrentState: true, immediateMode: true },
			onNext: (args) => {
				this.pushNext(args);
			},
		});
		this.withDisposeListener(() => l.dispose());
	}

	dispose() {
		IterUtils.disposeMap(this.subscribers);
		IterUtils.disposeMap(this.immmidiateSubscribers);
	}
}

export class NamedEvents {
	readonly _events: Map<string, ObservableStream<any>> = new Map();

	registerEvent<T>(eventName:string): ObservableStream<T> {
		if (this._events.get(eventName)) {
			console.error(`event ${eventName} is already registered, overwriting`);
		}
		this._events.set(eventName, new ObservableStream({ identifier: eventName + '_legacy_named_events_notifications' }));
		return this._events.get(eventName)!;
	}

	legacy_subscribe(eventName:string, callback:Function): Disposable {
		if (!(callback instanceof Function)) {
			throw new Error('argument exception: callback should be a function');
		}
		let sub: Observer;
		if (!this._events.get(eventName)) {
			const registeredEventsNames = Object.keys(this._events).join();
			console.error(`${eventName} event is not registered for subscribtions, allowed events are: [ ${registeredEventsNames} ]`);
			sub = new StreamObserver(eventName + '_dummy_subscription', () => { }, new Map(), false);
		} else {
			const e = this._events.get(eventName)!;
			sub = e.subscribe({ onNext: ({ legacy_args }) => callback(...legacy_args) });
		}
		return sub;
	}
	
	clear() {
		this._events.clear();
	}
}



export class StreamObserver<T> implements Observer {
	readonly name: string;
	readonly uniqueIdent: string;
	readonly isImmediateMode: boolean;

	readonly _notificationsStartId: number = Globals.getNotifier()._notificationsCounter;

	readonly notifCallback: (args: T) => void;
	
	private _disposeUnsubscribe: Function | null;
	private _disposeListeners?: ((self: Observer) => void)[];

	constructor(
		name: string,
		notifCallback: (args: T) => void,
		onDisposeRemoveFrom: Map<string, StreamObserver<T>>,
		isImmediateMode: boolean
	) {
		this.name = name;
		this.uniqueIdent = getUniqueEntityIdentifier('stream-listener', this.name);
		this.isImmediateMode = isImmediateMode;
		this.notifCallback = notifCallback;
		onDisposeRemoveFrom.set(this.uniqueIdent, this);
		this._disposeUnsubscribe = () => {
			if (!onDisposeRemoveFrom.delete(this.uniqueIdent)) {
				console.warn(`${this.name} listener not contained in stream listeners on disposal`);
			}
		}
	}

	withUnsubListener(disposeListner: (self: Observer) => void) {
		if (!this._disposeListeners) {
			this._disposeListeners = [];
		}
		this._disposeListeners.push(disposeListner);
	}

	// unsubscibe can be automagically used with svelte stores
	// see Svelte: Store contract
	unsubscribe() {
		if (this._disposeUnsubscribe) {
			this._disposeUnsubscribe();
			this._disposeUnsubscribe = null;
			while (this._disposeListeners?.length) {
				const l = this._disposeListeners.shift()!;
				try {
					l(this);
				} catch (e) {
					console.error('error in stream_listener dispose listener', e);
				}
			}
		} else {
			console.warn(`disposing ${this.name} subscription multiple times`);
		}
	}

	dispose() {
		this.unsubscribe();
	}
}


