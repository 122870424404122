import { ErrorUtils, Failure, IterUtils, WorkerClassPassRegistry } from 'engine-utils-ts';
import { Builder, ByteBuffer } from 'flatbuffers';
import { Delaunator, Vector2, Vector3 } from 'math-ts';
import type { ObjectsPickingSerializer } from 'verdata-ts';

import type {
	IdInEntityLocal, LocalIdsEdge} from '../collections/LocalIds';
import { LocalIdsCounter
} from '../collections/LocalIds';
import { CubeGeometry } from '../geometries/CubeGeometries';
import {
	ExtrudedPolygonGeometry, Points2D,
} from '../geometries/ExtrudedPolygonGeometries';
import type { SegmentInterp} from '../geometries/GraphGeometries';
import {
	GraphGeometry, SegmentInterpLinearG,
} from '../geometries/GraphGeometries';
import {
	IrregularHeightmapGeometry,
} from '../geometries/IrregularHeightmapGeometries';
import { PolylineGeometry } from '../geometries/PolylineGeometries';
import {
	RegularHeightmapGeometry,
} from '../geometries/RegularHeightmapGeometry';
import { TriGeometry } from '../geometries/TriGeometries';
import { Cube as CubeF } from '../schema/cube';
import { CubeCollection } from '../schema/cube-collection';
import {
	ExtrudedPolygon as ExtrudedPolygonF,
} from '../schema/extruded-polygon';
import {
	ExtrudedPolygonCollection,
} from '../schema/extruded-polygon-collection';
import { GraphGeometry as GraphGeometryF } from '../schema/graph-geometry';
import { GraphGeometryCollection } from '../schema/graph-geometry-collection';
import {
	HeightmapIrregular as HeightmapIrregularF,
} from '../schema/heightmap-irregular';
import {
	HeightmapIrregularCollection,
} from '../schema/heightmap-irregular-collection';
import {
	HeightmapRegular as HeightmapRegularF,
} from '../schema/heightmap-regular';
import {
	HeightmapRegularCollection,
} from '../schema/heightmap-regular-collection';
import { Indices } from '../schema/indices';
import { Points2D as Points2DF } from '../schema/points2-d';
import { Polyline as PolylineF } from '../schema/polyline';
import { PolylineCollection } from '../schema/polyline-collection';
import { TriGeometry as TriGeometryF } from '../schema/tri-geometry';
import { TriGeometryCollection } from '../schema/tri-geometry-collection';
import { FlatbufCommon } from './FlatbufCommon';

export class TriGeosSerializer implements ObjectsPickingSerializer<TriGeometry> {

    serialize(objects: [number, TriGeometry][]): Uint8Array {
        const builder = new Builder(objects.length * 10000);
        const root = TriGeometryCollection.createTriGeometryCollection(
            builder,
            0,
            FlatbufCommon.writeInt32Vector(builder, objects.map(t => t[0])),
            TriGeometryCollection.createCollectionVector(builder, objects.map((t) => writeTriGeo(builder, t[1]))),
        );
        builder.finish(root);
        return builder.asUint8Array().slice();
    }

    deserialize(buffer: Uint8Array, idsToDeserialize: Set<number>): [number, TriGeometry][] {
        const rts = TriGeometryCollection.getRootAsTriGeometryCollection(new ByteBuffer(buffer));
        const res: [number, TriGeometry][] = [];
        for (let i = 0, il = rts.idsLength(); i < il; ++i) {
            const id = rts.ids(i)!;
            if (!idsToDeserialize.has(id)) {
                continue;
            }
            const t = rts.collection(i)!;
            res.push([
                id,
                readTriGeo(t)
            ]);
        }
        return res;
    }
}
function writeTriGeo(builder: Builder, g: TriGeometry): number {
    const positionsOffset = FlatbufCommon.writeFloats(builder, g.positions);
    const normalsOffset = FlatbufCommon.writeInt8s(builder, g.normals);
    const uvs = g.uvs;
    const uvsOffset = uvs ? FlatbufCommon.writeFloats(builder, uvs) : 0;
    const indicesOffset = writeIndices(builder, g.indices);
    const edgesIndexOffset = g.edgeindices ? writeIndices(builder, g.edgeindices) : 0;
    TriGeometryF.startTriGeometry(builder);
    TriGeometryF.addPositions(builder, positionsOffset);
    TriGeometryF.addNormals(builder, normalsOffset);
    TriGeometryF.addUvs(builder, uvsOffset);
    TriGeometryF.addIndices(builder, indicesOffset);
    TriGeometryF.addEdgeIndices(builder, edgesIndexOffset);
    return TriGeometryF.endTriGeometry(builder);
}
function readTriGeo(pl: TriGeometryF): TriGeometry {
    return new TriGeometry(
        pl.positionsArray()!,
        pl.normalsArray()!,
        pl.uvsArray(),
        readIndices(pl.indices())!,
        readIndices(pl.edgeIndices())
    )
}
function writeIndices(builder: Builder, indices: Uint32Array | Uint16Array): number {
    let shortInds: number;
    let intInds: number;
    if (indices instanceof Uint16Array || indices instanceof Uint8Array) {
        shortInds = FlatbufCommon.writeInt16Vector(builder, indices);
        intInds = 0;
    } else {
        intInds = FlatbufCommon.writeInt32Vector(builder, indices);
        shortInds = 0;
    }
    return Indices.createIndices(
        builder,
        intInds,
        shortInds
    );
}
function readIndices(indices: Indices | null): Uint32Array | Uint16Array | null {
    if (!indices) {
        return null;
    }
    return indices.ushortIndsArray() || indices.uintIndsArray()!;
}

enum PolylineGeosVersions {
    None,
    PointsIdsAdded,
}
export class PolylinesSerializer implements ObjectsPickingSerializer<PolylineGeometry> {
    serialize(objects: [number, PolylineGeometry][]): Uint8Array {
        const builder = new Builder(objects.length * 100);
        const root = PolylineCollection.createPolylineCollection(
            builder,
            PolylineGeosVersions.PointsIdsAdded,
            FlatbufCommon.writeInt32Vector(builder, objects.map(t => t[0])),
            PolylineCollection.createCollectionVector(
                builder,
                objects.map(([_, t]) => writePolyline(builder, t)),
            )
        );
        builder.finish(root);
        return builder.asUint8Array().slice();
    }

    deserialize(buffer: Uint8Array, idsToDeserialize: Set<number>): [number, PolylineGeometry][] {
        const rts = PolylineCollection.getRootAsPolylineCollection(new ByteBuffer(buffer));
        const formatVersion = rts.formatVersion();
        const res: [number, PolylineGeometry][] = [];
        for (let i = 0, il = rts.idsLength(); i < il; ++i) {
            const id = rts.ids(i)!;
            if (!idsToDeserialize.has(id)) {
                continue;
            }
            const t = rts.collection(i)!;
            res.push([
                id,
                readPolyline(t, formatVersion)
            ])
        }
        return res;
    }
}
function writePolyline(builder: Builder, pl: PolylineGeometry): number {
    const posOffset = FlatbufCommon.writeDoubles(builder, pl.points3d);
    const idsOffset = FlatbufCommon.writeInt32Vector(builder, pl.pointsLocalIds);
    return PolylineF.createPolyline(
        builder,
        posOffset,
        pl.radius,
        idsOffset,
    );
}
function readPolyline(pl: PolylineF, formatVersion: PolylineGeosVersions): PolylineGeometry {
    const points =  Vector3.arrayFromFlatArray(pl.pointsArray()!);

    let ids: number[];
    if (formatVersion < PolylineGeosVersions.PointsIdsAdded) {
        ids = IterUtils.newArrayWithIndices(0, points.length);
    } else {
        ids = [...pl.pointsLocalIdsArray()!];
    }

    return new PolylineGeometry(
        pl.pointsArray()!.slice(),
        ids as IdInEntityLocal[],
        pl.radius()
    )
}

export class CubesSerializer implements ObjectsPickingSerializer<CubeGeometry> {
    serialize(objects: [number, CubeGeometry][]): Uint8Array {
        const builder = new Builder(objects.length * 100);
        const root = CubeCollection.createCubeCollection(
            builder,
            0,
            FlatbufCommon.writeInt32Vector(builder, objects.map(t => t[0])),
            CubeCollection.createCollectionVector(
                builder,
                objects.map(([_, t]) => writeCube(builder, t)),
            )
        );
        builder.finish(root);
        return builder.asUint8Array().slice();
    }

    deserialize(buffer: Uint8Array, idsToDeserialize: Set<number>): [number, CubeGeometry][] {
        const rts = CubeCollection.getRootAsCubeCollection(new ByteBuffer(buffer));
        const res: [number, CubeGeometry][] = [];
        for (let i = 0, il = rts.idsLength(); i < il; ++i) {
            const id = rts.ids(i)!;
            if (!idsToDeserialize.has(id)) {
                continue;
            }
            const t = rts.collection(i)!;
            res.push([
                id,
                readCube(t)
            ])
        }
        return res;
    }
}
function writeCube(builder: Builder, pl: CubeGeometry): number {
    CubeF.startCube(builder);
    CubeF.addSize(builder, FlatbufCommon.writeVec3(builder, pl.size));
    CubeF.addCenter(builder, FlatbufCommon.writeVec3(builder, pl.center));
    return CubeF.endCube(builder);
}
function readCube(pl: CubeF): CubeGeometry {
    return new CubeGeometry(
        FlatbufCommon.readVec3(pl.size()!),
        FlatbufCommon.readVec3(pl.center()!),
    );
}

enum ExtrudedPolygonsVersions {
    None,
    PointsIdsAdded,
}
export class ExtrudedPolygonsSerializer implements ObjectsPickingSerializer<ExtrudedPolygonGeometry> {
    serialize(objects: [number, ExtrudedPolygonGeometry][]): Uint8Array {
        const builder = new Builder(objects.length * 100);
        const root = ExtrudedPolygonCollection.createExtrudedPolygonCollection(
            builder,
            ExtrudedPolygonsVersions.PointsIdsAdded,
            FlatbufCommon.writeInt32Vector(builder, objects.map(t => t[0])),
            ExtrudedPolygonCollection.createCollectionVector(
                builder,
                objects.map(([_, t]) => writeExtrudedPolygon(builder, t)),
            )
        );
        builder.finish(root);
        return builder.asUint8Array().slice();
    }

    deserialize(buffer: Uint8Array, idsToDeserialize: Set<number>): [number, ExtrudedPolygonGeometry][] {
        const rts = ExtrudedPolygonCollection.getRootAsExtrudedPolygonCollection(new ByteBuffer(buffer));
        const formatVersion = rts.formatVersion();
        const res: [number, ExtrudedPolygonGeometry][] = [];
        for (let i = 0, il = rts.idsLength(); i < il; ++i) {
            const id = rts.ids(i)!;
            if (!idsToDeserialize.has(id)) {
                continue;
            }
            const t = rts.collection(i)!;
            res.push([
                id,
                readExtrudedPolygon(t, formatVersion)
            ]);
        }
        return res;
    }
}
function writeExtrudedPolygon(builder: Builder, pl: ExtrudedPolygonGeometry): number {
    return ExtrudedPolygonF.createExtrudedPolygon(
        builder,
        Points2DF.createPoints2D(
            builder,
            FlatbufCommon.writeVec2ArrAsDoubles(builder, pl.outerShell.points),
            FlatbufCommon.writeInt32Vector(builder, pl.outerShell.pointsLocalIds),
        ),
        ExtrudedPolygonF.createHolesVector(
            builder,
            pl.holes.map(h => Points2DF.createPoints2D(
                builder,
                FlatbufCommon.writeVec2ArrAsDoubles(builder, h.points),
                FlatbufCommon.writeInt32Vector(builder, h.pointsLocalIds),
            )),
        ),
        pl.baseElevation,
        pl.topElevation,
    );
}

function readPoints2D(p: Points2DF): Points2D {
    return new Points2D(
        Vector2.arrayFromFlatArray(p.pointsArray()!),
        [...p.pointsLocalIdsArray()!] as IdInEntityLocal[],
    )
}
function readExtrudedPolygon(pl: ExtrudedPolygonF, formatVersion: number): ExtrudedPolygonGeometry {
    if (formatVersion < ExtrudedPolygonsVersions.PointsIdsAdded) {
        const holes: Vector2[][] = [];
        for (let i = 0; i < pl.holesLength(); ++i) {
            const h = pl.holes(i)!;
            const points = Vector2.arrayFromFlatArray(h.pointsArray()!);
            holes.push(points);
        };
        return ExtrudedPolygonGeometry.newWithAutoIds(
            Vector2.arrayFromFlatArray(pl.outer()!.pointsArray()!),
            holes,
            pl.baseElevation(),
            pl.topElevation()
        )
    } else {
        const holes: Points2D[] = [];
        for (let i = 0; i < pl.holesLength(); ++i) {
            const h = pl.holes(i)!;
            holes.push(readPoints2D(h));
        };
        return new ExtrudedPolygonGeometry(
            readPoints2D(pl.outer()!),
            holes,
            pl.baseElevation(),
            pl.topElevation()
        );
    }
}

enum RegularGeometryFormatVersion {
    Initial,
    RelativeHeightsInCm,
}
export class RegularHeightmapsSerializer implements ObjectsPickingSerializer<RegularHeightmapGeometry> {
    serialize(objects: [number, RegularHeightmapGeometry][]): Uint8Array {
        const builder = new Builder(objects.length * 100);
        const root = HeightmapRegularCollection.createHeightmapRegularCollection(
            builder,
            RegularGeometryFormatVersion.RelativeHeightsInCm,
            FlatbufCommon.writeInt32Vector(builder, objects.map(t => t[0])),
            HeightmapRegularCollection.createCollectionVector(
                builder,
                objects.map(([_, t]) => writeHeightmapRegular(builder, t)),
            )
        );
        builder.finish(root);
        return builder.asUint8Array().slice();
    }

    deserialize(buffer: Uint8Array, idsToDeserialize: Set<number>): [number, RegularHeightmapGeometry][] {
        const rts = HeightmapRegularCollection.getRootAsHeightmapRegularCollection(new ByteBuffer(buffer));
        const res: [number, RegularHeightmapGeometry][] = [];
        for (let i = 0, il = rts.idsLength(); i < il; ++i) {
            const id = rts.ids(i)!;
            if (!idsToDeserialize.has(id)) {
                continue;
            }
            const t = rts.collection(i)!;
            res.push([
                id,
                readHeightmapRegular(t, rts.formatVersion())
            ])
        }
        return res;
    }
}
WorkerClassPassRegistry.registerClass(RegularHeightmapsSerializer);

function writeHeightmapRegular(builder: Builder, pl: RegularHeightmapGeometry): number {
    return HeightmapRegularF.createHeightmapRegular(
        builder,
        pl.xSegmentsCount,
        pl.ySegmentsCount,
        0,
        pl.segmentSizeInMeters,
        FlatbufCommon.writeInt8s(builder, new Uint8Array(pl.elevationsInCmRelative.buffer)), // write as uint8Array,
        pl.elevationsBaseInCm,
    );
}
function readHeightmapRegular(pl: HeightmapRegularF, formatVersion: RegularGeometryFormatVersion): RegularHeightmapGeometry {
    let xSegmentsCount = pl.xSegmentsCount();
    let ySegmentsCount = pl.ySegmentsCount();
    let segmentSize = pl.segmentSizeM();
    if (formatVersion < RegularGeometryFormatVersion.RelativeHeightsInCm) {
        const floatHeights = pl.deprecatedHeightsArray()!;
        const regularGeo = RegularHeightmapGeometry.newFromMetersAndNaNs(
            xSegmentsCount, ySegmentsCount, segmentSize, floatHeights
        );
        if (regularGeo instanceof Failure) {
            console.error('error deserializing regular goemetry', regularGeo.toString());
            return new RegularHeightmapGeometry();
        }
        return regularGeo.value;
    }
    let elevationsBaseCm = pl.elevationsBaseCm();
    let relativeElevationsBuffer = pl.relativeElevationsCmArray()!;
    const pointsCount = (xSegmentsCount + 1) * (ySegmentsCount + 1);
    let relativeElevationsInCmView: Uint8Array | Uint16Array | Uint32Array;
    if (relativeElevationsBuffer.length === pointsCount) {
        relativeElevationsInCmView = relativeElevationsBuffer;
    } else if (relativeElevationsBuffer.length === pointsCount * 2) {
        relativeElevationsInCmView = new Uint16Array(relativeElevationsBuffer.buffer, relativeElevationsBuffer.byteOffset, pointsCount);
    } else if (relativeElevationsBuffer.length === pointsCount * 4) {
        relativeElevationsInCmView = new Uint32Array(relativeElevationsBuffer.buffer, relativeElevationsBuffer.byteOffset, pointsCount);
    } else {
        ErrorUtils.logThrow(`unexpected length of relative elevations buffer ${relativeElevationsBuffer.length}`);
    }

    return new RegularHeightmapGeometry(
        xSegmentsCount,
        ySegmentsCount,
        segmentSize,
        elevationsBaseCm,
        relativeElevationsInCmView.slice(),
    );
}

enum IrregularHeightmapVersion {
    Zero,
    TriangulationIndicesAdded,
}
export class IrregularHeightmapsSerializer implements ObjectsPickingSerializer<IrregularHeightmapGeometry> {
    serialize(objects: [number, IrregularHeightmapGeometry][]): Uint8Array {
        const builder = new Builder(objects.length * 100);
        const root = HeightmapIrregularCollection.createHeightmapIrregularCollection(
            builder,
            IrregularHeightmapVersion.TriangulationIndicesAdded,
            FlatbufCommon.writeInt32Vector(builder, objects.map(t => t[0])),
            HeightmapIrregularCollection.createCollectionVector(
                builder,
                objects.map(([_, t]) => writeHeightmapIrregular(builder, t)),
            )
        );
        builder.finish(root);
        return builder.asUint8Array().slice();
    }

    deserialize(buffer: Uint8Array, idsToDeserialize: Set<number>): [number, IrregularHeightmapGeometry][] {
        const rts = HeightmapIrregularCollection.getRootAsHeightmapIrregularCollection(new ByteBuffer(buffer));
        const res: [number, IrregularHeightmapGeometry][] = [];
        for (let i = 0, il = rts.idsLength(); i < il; ++i) {
            const id = rts.ids(i)!;
            if (!idsToDeserialize.has(id)) {
                continue;
            }
            const t = rts.collection(i)!;
            res.push([
                id,
                readHeightmapIrregular(t, rts.formatVersion())
            ])
        }
        return res;
    }
}
function writeHeightmapIrregular(builder: Builder, geo: IrregularHeightmapGeometry): number {
    return HeightmapIrregularF.createHeightmapIrregular(
		builder,
		FlatbufCommon.writeDoubles(builder, geo.points3d),
        FlatbufCommon.writeInt32Vector(builder, geo.trianglesIndices),
    );
}
function readHeightmapIrregular(geo: HeightmapIrregularF, version: IrregularHeightmapVersion): IrregularHeightmapGeometry {
    const points = geo.pointsArray()!.slice();
    let indices: Uint32Array;
    if (version < IrregularHeightmapVersion.TriangulationIndicesAdded) {
        const points2d = points.filter((_v, ind) => (ind + 1) % 3 !== 0);
        const triang = new Delaunator(points2d);
        indices = triang.triangles.slice();
    } else {
        indices = geo.trisIndsArray()!.slice();
    }
	return new IrregularHeightmapGeometry(
		points,
        indices,
	);
}



export class GraphsSerializer implements ObjectsPickingSerializer<GraphGeometry> {
    serialize(objects: [number, GraphGeometry][]): Uint8Array {
        const builder = new Builder(objects.length * 100);
        const root = GraphGeometryCollection.createGraphGeometryCollection(
            builder,
            0,
            FlatbufCommon.writeInt32Vector(builder, objects.map(t => t[0])),
            GraphGeometryCollection.createCollectionVector(
                builder,
                objects.map(([_, t]) => writeGraph(builder, t)),
            )
        );
        builder.finish(root);
        return builder.asUint8Array().slice();
    }

    deserialize(buffer: Uint8Array, idsToDeserialize: Set<number>): [number, GraphGeometry][] {
        const rts = GraphGeometryCollection.getRootAsGraphGeometryCollection(new ByteBuffer(buffer));
        const formatVersion = rts.formatVersion();
        const res: [number, GraphGeometry][] = [];
        for (let i = 0, il = rts.idsLength(); i < il; ++i) {
            const id = rts.ids(i)!;
            if (!idsToDeserialize.has(id)) {
                continue;
            }
            const t = rts.collection(i)!;
            res.push([
                id,
                readGraph(t),
            ])
        }
        return res;
    }
}
function writeGraph(builder: Builder, pl: GraphGeometry): number {
    const posOffset = FlatbufCommon.writeVec3sAsDoubles(builder, Array.from(pl.points.values()));
    const idsOffset = FlatbufCommon.writeInt32Vector(builder, Array.from(pl.points.keys()));
    const edgesOffset = FlatbufCommon.writeInt32Vector(builder, pl.graphEdgesAsFlatTuples());
    return GraphGeometryF.createGraphGeometry(
        builder,
        posOffset,
        idsOffset,
        edgesOffset,
    );
}
function readGraph(pl: GraphGeometryF): GraphGeometry {
    const points =  Vector3.arrayFromFlatArray(pl.pointsArray()!);
    const ids = pl.pointsLocalIdsArray()!;
    const edgesFlat = pl.edgesTuplesFlatArray()!;
    const edges = new Map<LocalIdsEdge, SegmentInterp>();
    for (let i = 1; i < edgesFlat.length; i += 2) {
        const id1 = edgesFlat[i - 1] as IdInEntityLocal;
        const id2 = edgesFlat[i] as IdInEntityLocal;
        edges.set(LocalIdsCounter.newEdge(id1, id2), SegmentInterpLinearG);
    }

    return new GraphGeometry(
        IterUtils.newMapFromTuples(
            IterUtils.map2(ids, points, (id, p) => [id as IdInEntityLocal, p])
        ),
        edges,
    )
}

