// automatically generated by the FlatBuffers compiler, do not modify

import type * as flatbuffers from 'flatbuffers';



export class Vector2 implements flatbuffers.IUnpackableObject<Vector2T> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Vector2 {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

x():number {
  return this.bb!.readFloat64(this.bb_pos);
}

y():number {
  return this.bb!.readFloat64(this.bb_pos + 8);
}

static sizeOf():number {
  return 16;
}

static createVector2(builder:flatbuffers.Builder, x: number, y: number):flatbuffers.Offset {
  builder.prep(8, 16);
  builder.writeFloat64(y);
  builder.writeFloat64(x);
  return builder.offset();
}


unpack(): Vector2T {
  return new Vector2T(
    this.x(),
    this.y()
  );
}


unpackTo(_o: Vector2T): void {
  _o.x = this.x();
  _o.y = this.y();
}
}

export class Vector2T implements flatbuffers.IGeneratedObject {
constructor(
  public x: number = 0.0,
  public y: number = 0.0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return Vector2.createVector2(builder,
    this.x,
    this.y
  );
}
}
