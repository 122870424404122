import type {
    Bim, GaugePack, IdBimScene, SceneInstance} from 'bim-ts';
import { DC_CNSTS, NumberProperty
} from 'bim-ts';
import { DefaultMap, IterUtils, Yield } from 'engine-utils-ts';
import { DISTANCE_EPSILON } from '../constants';
import { deleteByParents } from 'layout-service';
import { sumReduce } from '../utils';
import { CircuitBuilder } from './create/circuit-builder';
import { EquipmentType, equipmentType } from './create/types';
import { Writer } from './cirtuit-to-bim-writer';
import { Cable } from './low-voltage-cables';
import type { Circuit } from './models/circuit';
import { Node } from './models/node';
import type { CablePairingType, Route } from './models/route';
import { groupRoutes } from './route-grouping';
import { RouteSolver } from './solve/route-solver';
import type { Connection } from './models/connection';

export const eqTypeMightHaveMultiPatternSteps = [
    EquipmentType.Tracker,
];

export const instanceTypeToPatternNodesMapping: {
    [key in DC_CNSTS.NodeName]: EquipmentType[];
} = {
    string_exit: [EquipmentType.Tracker],
    inverter: [EquipmentType.Inverter],
    cb_si_circuit_exit: [
        EquipmentType.CombinerBox,
        EquipmentType.Inverter,
    ],
    ac_combiner_exit: [EquipmentType.CombinerBox],
    block_circuit_exit: [EquipmentType.Transformer],
    end_of_multiharness: [],
    end_of_tracker: [EquipmentType.Tracker],
    end_of_group: [],
    utility: [],
};


export const findCurrentStepInPattern = (
    si: SceneInstance,
    patternConfig: DC_CNSTS.PatternConfig,
    siSeenBefore: number,
): number | null => {
    const startFromIndex = patternConfig.pattern.nodes.length - 1;
    // TODO: start not from the last item, but from the index of the parent
    // start from its index in reverse pattern node order
    for (let i = startFromIndex, nodesSkipped = 0 ; i >= 0; i--) {
        const patternStep = patternConfig.pattern.nodes[i];
        const patternStepType = DC_CNSTS.getNodeNameByValue(patternStep);
        const relatedEquipment =
            instanceTypeToPatternNodesMapping[patternStepType];
		const ty = equipmentType(si);
        if (ty === null || !relatedEquipment.includes(ty)) {
			continue;
		}
        if (nodesSkipped >= siSeenBefore)
            return i;
        nodesSkipped++;
    }
    return null;
};

export interface PatternStepDescDataModel {
    idx: number;
    patternConfig: DC_CNSTS.PatternConfig;
}
export interface PatternStepDesc extends PatternStepDescDataModel {}
export class PatternStepDesc {
    private patternStep: DC_CNSTS.Node;
    patternStepType: DC_CNSTS.NodeName;
    conductorToParent?: DC_CNSTS.ConductorInPattern;
    conductorToChildren?: DC_CNSTS.ConductorInPattern;
    constructor(params: PatternStepDescDataModel) {
        Object.assign(this, params);
        if (this.idx >= this.patternConfig.pattern.nodes.length)
            throw new Error([
                'Pattern step index is out of',
                'pattern step count',
            ].join(' '));
        this.patternStep = this.patternConfig.pattern.nodes[this.idx];
        this.patternStepType = DC_CNSTS.getNodeNameByValue(this.patternStep);
        this.conductorToParent =
            this.patternConfig.pattern.conductors[this.idx];
        this.conductorToChildren =
            this.patternConfig.pattern.conductors[this.idx - 1];
    }

    getParentStep(): PatternStepDesc | null {
        const idx = this.idx + 1;
        const step = this.patternConfig.pattern.nodes[idx];
        if (!step) return null;
        return new PatternStepDesc({
            patternConfig: this.patternConfig,
            idx,
        });
    }

    getChildStep(): PatternStepDesc | null {
        const idx = this.idx - 1;
        const step = this.patternConfig.pattern.nodes[idx];
        if (!step) return null;
        return new PatternStepDesc({
            patternConfig: this.patternConfig,
            idx,
        });
    }

}


export type SceneInstanceDescDataModel = {
    id: IdBimScene,
    i: SceneInstance,
};

export interface SceneInstanceDesc extends SceneInstanceDescDataModel {}
export class SceneInstanceDesc {
    get enginePosition() {
        return this.i.worldMatrix.extractPosition().xy();
    }
    constructor(params: SceneInstanceDescDataModel) {
        Object.assign(this, params);
    }
}

/**
 * @param length
 * In meters. Full cable length. If its +/- then length should be
 * equal to doubled length, if 3-phase, then tripled.
 */
export function calculateLosses(
    current: number,
    resistivity: number,
    length: number,
) {
    let result = current * current;
    result *= resistivity;
    // length is included in KFeet
    result *= length * 3.28 / 1000;
    return result;
}

interface GetFirstParamsWithoutThrow {
    ignore?: EquipmentType[];
    skipCurrent?: boolean;
    throw?: boolean;
}
type GetFirstParamsWithThrow = GetFirstParamsWithoutThrow &
    { throw: true };

export type GetFirstParams =
    GetFirstParamsWithThrow |
    GetFirstParamsWithoutThrow;

const defaultGetFirstParams: Required<GetFirstParams> = {
    ignore: [],
    skipCurrent: false,
    throw: false,
};

// Deprecated
//export function createSearchForFirstNodeWithSi(
//    this: Node,
//    getNext: (x: Node) => Node | undefined,
//) {
//    function fn(this: Node, params: GetFirstParamsWithThrow): NodeWithSi;
//    function fn(this: Node, params?: GetFirstParamsWithoutThrow):
//        NodeWithSi | null;
//    function fn(this: Node, _params?: GetFirstParams): NodeWithSi | null {
//        const params = { ...defaultGetFirstParams, ...(_params || {}) };
//        if (
//            !this.isMatchingEquipment(params.ignore) &&
//            !params.skipCurrent &&
//            this.hasSi()
//        )
//            return this;
//        const next = getNext(this);
//        if (!next) {
//            if (params.throw)
//                throw new Error(
//                    'While traversing, next item was not found, but had to',
//                );
//            return null;
//        }
//        return next.getFirstParentAssociatedWithSceneInstance({
//            ...params,
//            skipCurrent: false,
//        });
//    }
//    return fn.bind(this) as typeof fn;
//}

export abstract class WiresDetailsCommon {
    totalLength = 0;
    abstract calculateStats(): void;
}

export class DetailsPerConductorLength extends WiresDetailsCommon {
    constructor(readonly routes: Route[] = []) { super(); }
    calculateStats() {
        this.totalLength = sumReduce(this.routes, x => x.length);
    }
}

export class DetailsPerPairingType extends WiresDetailsCommon {
    constructor(
        readonly detailsPerLength:
            DefaultMap<number, DetailsPerConductorLength> =
                new DefaultMap(() => new DetailsPerConductorLength()),
    ) { super(); }
    calculateStats(): void {
        for (const x of this.detailsPerLength.values()) {
            x.calculateStats();
            this.totalLength += x.totalLength;
        }
    }

}

export class DetailsPerConductorGauge extends WiresDetailsCommon {
    constructor(
        readonly detailsPerPairingType:
            DefaultMap<CablePairingType, DetailsPerPairingType> =
                new DefaultMap(() => new DetailsPerPairingType()),
    ) { super(); }
    calculateStats(): void {
        for (const x of this.detailsPerPairingType.values()) {
            x.calculateStats();
            this.totalLength += x.totalLength;
        }
    }
}

export class DetailsPerConductorType extends WiresDetailsCommon {
    constructor(
        readonly detailsPerGauge:
            DefaultMap<number | string, DetailsPerConductorGauge> =
                new DefaultMap(() => new DetailsPerConductorGauge()),
    ) { super(); }
    calculateStats(): void {
        for (const x of this.detailsPerGauge.values()) {
            x.calculateStats();
            this.totalLength += x.totalLength;
        }
    }
}

export class TotalCondDetails extends WiresDetailsCommon {
    constructor(
        readonly detailsPerCondType:
            DefaultMap<DC_CNSTS.ConductorType, DetailsPerConductorType> =
                new DefaultMap(() => new DetailsPerConductorType()),
    ) { super(); }
    calculateStats(): void {
        for (const x of this.detailsPerCondType.values()) {
            x.calculateStats();
            this.totalLength += x.totalLength;
        }
    }
}


export function calculateLossesOnNode(node: Node, gaugePack: GaugePack) {
    Node.traverseBranch({
        node, afterEach: x => {
            x.calculateLosses(gaugePack);
            return false;
        },
    });
}

export function* createCircuitFromBim(
    bim: Bim,
    gaugePack: GaugePack,
    transformerIds: IdBimScene[],
) {
    const circuit = yield* new CircuitBuilder(bim, gaugePack, transformerIds).build();
    deleteByParents(
      Array.from(circuit.nodes.roots)
        .map(x => x.si?.id)
        .filter((x): x is IdBimScene => x !== undefined),
      bim,
      ["lv-wire"],
    )
    MultVertTrunkbusFix1: {
        for (const node of circuit.nodes.items.values()) {
            if (
                node.step.patternConfig.patternId !== 'CI_MultVertTrunkbus' ||
                node.step.patternStepType !== 'cb_si_circuit_exit' ||
                !node.toChildren.length
            ) {
                continue;
            }
            const newToUtilities: Connection[] = [];
            const uniqueUtilities: Node[] = []
            outer: for (const toUtility of node.toChildren.slice()) {
                const utility = toUtility.to;
                const utilityAverageHint = utility.averageChildrenHint!;
                for (const uniqueUtility of uniqueUtilities) {
                    const uniqueUtilityAverageHint = uniqueUtility.averageChildrenHint!;
                    if (Math.abs(utilityAverageHint.x - uniqueUtilityAverageHint.x) < DISTANCE_EPSILON) {
                        const reqToString = utility.toChildren[0];
                        const string = reqToString.to;
                        reqToString.from = uniqueUtility;
                        uniqueUtility.toChildren.push(reqToString);
                        string.parent = uniqueUtility
                        circuit.connections.items.delete(toUtility.id)
                        circuit.nodes.items.delete(utility.id);
                        circuit.nodes.perStepName.getOrCreate('utility').delete(utility);
                        continue outer;
                    }
                }
                uniqueUtilities.push(utility);
                newToUtilities.push(toUtility);
            }
            node.toChildren = newToUtilities;
        }
    }
    yield* new RouteSolver(circuit).solveCircuit();
    if (circuit.nodes.genId() < 4)
        throw new Error('circuit has too little nodes');


    for (const chunk of IterUtils.splitIterIntoChunks(
        circuit.nodes.roots, 4,
    )) {
        for (const root of chunk) {
            calculateLossesOnNode(root, gaugePack);
        }
        yield Yield.NextFrame;
    }
    yield* groupRoutes(circuit);
    yield* new Writer(bim, circuit, gaugePack).writeProps();
    return circuit;
}

export function* parseCircuitToCables(
    circuit: Circuit,
    gaugePack: GaugePack,
) {
    const result: Cable[] = [];
    for (const chunk of IterUtils.splitIterIntoChunks(
        circuit.connections.items.values(),
        30_000,
    )) {
        for (const conn of chunk) {
            if (conn.conductorType === DC_CNSTS.ConductorType.BusBars) {
                continue;
            }
            if (conn.isNotMainInMergingGroup()) continue;
            const routes = conn.route.getPairingRoutes(true);
            const gauge = gaugePack.gauges.get(conn.gauge);
            if (!gauge) continue;
            const maxAmp = gauge.approxAmpacityByTemperature(
                NumberProperty.new({ unit: 'C', value: conn.temperatureParams.temperature }),
                conn.temperatureParams.isBuried,
            )
            const dcResist = gauge.approxDcResistivityByTemperature(
                NumberProperty.new({ unit: 'C', value: conn.temperatureParams.temperature }),
            )
            if (!maxAmp || !dcResist) continue;
            for (const route of routes) {
                if (route.length < DISTANCE_EPSILON) {
                  continue;
                }
                let parentNodeWithSi = conn.from.firstParentWithSi;
                if (conn.conductor.bimConnectTo === 'in') {
                    parentNodeWithSi = conn.to.firstParentWithSi;
                } else if (conn.conductor.bimConnectTo === 'inin') {
                    const inin = conn.to.toChildren[0]?.to.firstParentWithSi;
                    if (inin) {
                        parentNodeWithSi = inin.firstParentWithSi;
                    }
                }
                WHIP_HOR_VERT_SHOULD_BE_SINGLE_CABLE: {
                    // skip suplemental whip
                    const prevConn = conn.from.toParent as Connection | undefined;
                    const nextConn = conn.to.toChildren.at(0)
                    if (
                        prevConn?.conductorType === DC_CNSTS.ConductorType.Whip &&
                        conn.constraints.includes(DC_CNSTS.Constraints.Vertical) &&
                        prevConn?.constraints.includes(DC_CNSTS.Constraints.Horizontal)
                    ) {
                        // skip suplemental whip
                        continue;
                    }

                    // filter non matching cables
                    if (
                        conn.conductorType !== DC_CNSTS.ConductorType.Whip ||
                        conn.from.step.patternStepType === 'utility' ||
                        conn.to.step.patternStepType !== 'utility' ||
                        nextConn?.conductorType !== DC_CNSTS.ConductorType.Whip ||
                        !conn.constraints.includes(DC_CNSTS.Constraints.Horizontal) ||
                        !nextConn.constraints.includes(DC_CNSTS.Constraints.Vertical)
                    ) {
                        break WHIP_HOR_VERT_SHOULD_BE_SINGLE_CABLE;
                    }

                    const nextRoute = nextConn.route.pairing.routes.find(x => x.pairing.type === route.pairing.type);
                    if (!nextRoute) {
                        break WHIP_HOR_VERT_SHOULD_BE_SINGLE_CABLE;
                    }
                    // create merged cable
                    const cable = new Cable(
                        conn.conductorType,
                        conn.gauge,
                        gauge.getFullName(),
                        route.pairing.type,
                        [],
                        conn.temperatureParams.temperature,
                        parentNodeWithSi.si.id,
                        route.lossDrop.losses + nextRoute.lossDrop.losses,
                        (route.lossDrop.drop + nextRoute.lossDrop.drop)/2,
                        [...route.points, ...nextRoute.points.slice(1)],
                        route.length + nextRoute.length,
                        maxAmp.as('A'),
                        dcResist.as('Om/kft'),
                        gauge,
                    );
                    cable.power = conn.power;
                    cable.drop = cable.losses / cable.power;
                    cable.reactance = gauge.stc.reactance.as('Om/kft');
                    cable.maxCurrent = conn.maxCurrent;
                    cable.operatingCurrent = conn.operatingCurrent;
                    result.push(cable);
                    continue;
                }
                const cable = new Cable(
                    conn.conductorType,
                    conn.gauge,
                    gauge.getFullName(),
                    route.pairing.type,
                    conn.constraints,
                    conn.temperatureParams.temperature,
                    parentNodeWithSi.si.id,
                    route.lossDrop.losses,
                    route.lossDrop.drop,
                    route.points,
                    route.length,
                    maxAmp.as('A'),
                    dcResist.as('Om/kft'),
                    gauge,
                );
                cable.power = conn.power;
                cable.reactance = gauge.stc.reactance.as('Om/kft');
                cable.maxCurrent = conn.maxCurrent;
                cable.operatingCurrent = conn.operatingCurrent;
                result.push(cable);
            }
        }
        yield Yield.NextFrame;
    }
    return result;
}
