<script lang="ts">
    import { Vector2 } from "math-ts";
    import { ObjectUtils } from "engine-utils-ts";
    import flash from "../flash";
    import Icon from "../../libui/icon/Icon.svelte";
    import { IconName } from "../../libui/icon";
    import Lock from '../../libui/icon/svg/lock.svelte';

    export let name: string;
    export let options: string[] = [];
    export let onClick: ((pos: Vector2) => void) | undefined;
    export let iconName: IconName|undefined = undefined;
    export let readonly: boolean | undefined = undefined;
    export let notActive: boolean | undefined = undefined;

    $: value = options.map((o) => ({ label: o, value: o }));

    let div: HTMLElement;
    let preValue = value;

    $: if (!ObjectUtils.areObjectsEqual(preValue, value)) {
        preValue = value;
        flash(div);
    } 
</script>

<div 
    class="ui-config-property property-row multiselect-prop"
    class:not-active={!!notActive}
>
    <div bind:this={div} class="property-name text-main-medium">
        <div class="property-name-label mdc-typography--body1">
            {name}
        </div>
    </div>

    <div class="property-value">
        <div
            class="edit-field-container multiselect-property"
            class:input-area = {!readonly}
            class:readonly = {readonly}
            on:click={(e) => onClick?.(new Vector2(e.x, e.y))}
        >
            <div
                class="config-ui-input-field mdc-typography--body1 selected-items"
            >
                {#if value.length === 0}
                    <div class="text-main-light">
                        {`Select ${name.toLowerCase()}`}
                    </div>
                {:else}
                    {#each value as value}
                        <div>
                            {@html value.label ?? value.value}
                        </div>
                    {/each}
                {/if}
            </div>
            {#if iconName}
                <span class="expand_more text-main-light">
                    <Icon name={iconName}/>
                </span>
            {:else if readonly}
                <span class="expand_more text-main-light">
                    <Lock />
                </span>
            {/if}
        </div>
    </div>
</div>

<style lang="scss">
    .selected-items {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
        flex: 1;
        padding: 4px 0px;
    }
    .expand_more {
        display: flex;
        justify-content: center;
    }
    .input-area {
        cursor: pointer;
    }
    .readonly {
        cursor: default;
    }
</style>
