import type { EntitiesBase, EntitiesCollectionUpdates} from 'bim-ts';
import { handleEntitiesUpdates } from 'bim-ts';
import type { ObjectUniqueHashValue, ScopedLogger} from 'engine-utils-ts';
import { IterUtils, StreamAccumulator } from 'engine-utils-ts';
import type { EntityId } from 'verdata-ts';

import { EntitiesInterned } from './EntitiesInterned';

export abstract class EntitiesBimSyncedInterned<
    T, IdT extends number, SourceT extends object, IdS extends number
>
    extends EntitiesInterned<T, IdT, SourceT, IdS>
{
    bimCollectionSource: EntitiesBase<SourceT, IdS>;
    _bimUpdates: StreamAccumulator<EntitiesCollectionUpdates<EntityId<IdS>, number>>;

    constructor(params: {
        identifier: string;
        idsType: IdT | number;
        logger?: ScopedLogger;
        T_Constructor: { new(): T; };
        uniqueReducerFn: (obj: SourceT) => ObjectUniqueHashValue,
        sourceCollection: EntitiesBase<SourceT, IdS>
    }) {
        super(params);
        this.bimCollectionSource = params.sourceCollection;
        this._bimUpdates = new StreamAccumulator(this.bimCollectionSource.updatesStream);
    }

    dispose() {
        this._bimUpdates.dispose();
    }

    mapBimIdToEngineId(bimId: EntityId<IdS>): EntityId<IdT> | undefined {
        return this._toEngineIdsRemap.get(bimId);
    }

    sync() {
        const deltas = this._bimUpdates.consume();
        if (!deltas) {
            return;
        }
        handleEntitiesUpdates(
			deltas,
            (allocatedIds) => {
                const objects = this.bimCollectionSource.peekByIds(allocatedIds);
                this.allocateOrReference(IterUtils.filterMap(objects, ([id, obj]) => {
					if (obj !== undefined) {
						return [id, obj];
					}
					return undefined;
				}));
            },
            (perIdDiffs) => {
                for (const id of perIdDiffs.keys()) {
                    this._toEngineIdsRemap.delete(id);
                }
                const objects = this.bimCollectionSource.peekByIds(perIdDiffs.keys());
                this.allocateOrReference(IterUtils.filterMap(objects, ([id, obj]) => {
					if (obj !== undefined) {
						return [id, obj];
					}
					return undefined;
				}));
            },
            (removed) => {
                for (const id of removed) {
                    this._toEngineIdsRemap.delete(id);
                }
            }
        )
    }

}


