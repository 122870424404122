import type { SceneInstances, BasicPropertyDescription } from 'bim-ts';
import { PropertyViewBasicTypes, readKnownPropValueFromSceneInstance, SceneObjDiff, type UnitsMapper } from 'bim-ts';
import { type IdBimScene, type Bim, type SceneInstancePatch } from 'bim-ts';
import type { Result} from 'engine-utils-ts';
import { Success, Failure, LazyDerived} from 'engine-utils-ts';
import { type UnitDimension, type RGBAHex, DefaultMap, IterUtils, RGBA, type LazyVersioned, convertUnits, StringUtils } from 'engine-utils-ts';
import { chooseRoundedChartMinMaxForNumbers } from 'ui-charts';
import type { PropsChartParams } from './PropsChartsParams';
import type { BasicPropertyValue } from 'bim-ts';

export type PropsDataset = HistogramDataset | CategoryDataset;

export interface DatasetShortStats {
    perObjMin: number;
    perObjMax: number;
    valuesUnit: string | null;

    averagePerObject: number;
    objectsCount: number;
}

export class HistogramDataset {

    constructor(
        public readonly xAxisName: string = '',
        public readonly yAxisName: string = '',
        public readonly bins: HistogramBin[] = [],
        public readonly valuesUnit: string = '',
        public readonly valuesName: string = '',
        public readonly chartMin: number = 0,
        public readonly chartMax: number = 0,
        public readonly binSize: number = 1,
        public readonly shortStats: DatasetShortStats | null = null,
    
        public readonly filteredOutByVisibility: IdBimScene[] = [],
        public readonly erroneousInstances: IdBimScene[] = [],
    ) {
        // adjust bar heights to be at minimum 2% of maximum for better visibility
        let maxYValue = 0;
        for (const bin of bins) {
            maxYValue = Math.max(maxYValue, bin.yValue);
        }
        for (const bin of bins) {
            if ((bin.barHeight > 0) && bin.barHeight < maxYValue * 0.02) {
                bin.barHeight = maxYValue * 0.02;
            }
        }
    }
}
export interface HistogramBin {
    xValue: number;
    yValue: number;
    barHeight: number;
    color: RGBAHex;
    binSize: number;

    ids: IdBimScene[];
    values: number[];
    unit: string;
}


export class CategoryDataset {
    constructor(
        public readonly bins: CategoryDatasetBin[] = [],
        public readonly filteredOutByVisibility: IdBimScene[] = [],
        public totalIdsCount: number = 0,
    ) {
        for (const bin of bins) {
            this.totalIdsCount += bin.ids.length;
        }
        Object.freeze(this);
    }
}
export interface CategoryDatasetBin {
    color: RGBAHex;
    ids: IdBimScene[];
    value: string;
}


export function datasetShortStatsToString(s: DatasetShortStats): string[] {
    const res: string[] = [];

    function numbersStatsStrings(
        min: number,
        max: number,
        average: number,
        units: string,
    ): string[] {
        const res: string[] = [];

        if (min !== max) {
            let rangeStr = `range: <b>${StringUtils.roundedForUi(min)}</b> to <b>${StringUtils.roundedForUi(max)}</b>`;
            if (units) {
                rangeStr += ` ${units}`;
            }
            res.push(rangeStr);

            let averageStr = `average: <b>${StringUtils.roundedForUi(average)}</b>`;
            if (units) {
                averageStr += ` ${units}`;
            }
            res.push(averageStr);
        } else {
            let rangeStr = `= <b>${StringUtils.roundedForUi(min)}</b>`;
            if (units) {
                rangeStr += ` ${units}`;
            }
            res.push(rangeStr);
        }

        return res;
    }

    res.push(...numbersStatsStrings(
        s.perObjMin, s.perObjMax, s.averagePerObject, s.valuesUnit ?? ''
    ));
    res.push(`of ${s.objectsCount} objects`);
    return res;
}

export function createPropsDatasetGenerator(
    chartOptions: LazyVersioned<PropsChartParams>,
    instances: SceneInstances,
    unitsMapper: UnitsMapper,
): LazyDerived<Result<PropsDataset | null>> {

    const visibilityInvalidator = instances.getLazyListOfCollection({relevantUpdateFlags: SceneObjDiff.Hidden})
    const histogramGenerator = LazyDerived.new2(
        'dataset generator',
        [visibilityInvalidator, unitsMapper, instances.basicPropsView.getPropsValuesInvalidator()],
        [chartOptions, instances.basicPropsView.asLazyVersioned()],
        ([chartOptions, knownProps]): Result<PropsDataset | null> => {

            if (!chartOptions.property_path) {
                return new Success(null);
            }
            const knownProp = knownProps.find(t => t.label == chartOptions.property_path);
            if (!knownProp) {
                return new Failure({msg: 'known property not found'});
            }
            let divideByKnownProp: BasicPropertyDescription|null = null;
            if (chartOptions.divide_by) {
                divideByKnownProp = knownProps.find(t => t.label == chartOptions.divide_by) ?? null;
                if (!divideByKnownProp) {
                    return new Failure({msg: 'divideBy known property not found'});
                }
            }

            if (knownProp.basicTypes & (PropertyViewBasicTypes.Numeric | PropertyViewBasicTypes.NumericArray)) {
                return new Success(createHistogramDataset(knownProp, divideByKnownProp, chartOptions.object_types, instances, unitsMapper, chartOptions));
            }
            return new Success(createCategoryDataset(knownProp, instances, chartOptions));
        }
    );
    return histogramGenerator;
    
}

export function createHistogramDataset(
    propDescr: BasicPropertyDescription,
    divideByPropDescr: BasicPropertyDescription|null,
    insstanceTypesAllowed: string[],
    instances: SceneInstances,
    unitsMapper: UnitsMapper,
    chartOptions: PropsChartParams,
): HistogramDataset {

    const perDimensionsDatapoints = new DefaultMap<UnitDimension | null, InitialDataPoint[]>(() => []);

    const erroneousInstances: IdBimScene[] = [];
    const filteredOutByVisibility: IdBimScene[] = [];
    const propsFormatters = instances.basicPropsView.formatters;

    for (const [instanceId, instance] of instances.perId) {
        if (insstanceTypesAllowed.length > 0 && !insstanceTypesAllowed.includes(instance.type_identifier)) {
            continue;
        }
        if (instance.isHidden) {
            filteredOutByVisibility.push(instanceId);
            continue;
        }
        
        // find property
        const propValueUnit = readKnownPropValueFromSceneInstance(instance, propDescr.path, propsFormatters);

        if (!propValueUnit) {
            erroneousInstances.push(instanceId);
            continue;
        }

        if (Array.isArray(propValueUnit.value)) {

            for (const value of propValueUnit.value) {
                if (typeof value !== 'number' || !Number.isFinite(value)) {
                    erroneousInstances.push(instanceId);
                    continue;
                };
                const initialDataPoint: InitialDataPoint = {
                    instanceId,
                    value: value,
                    valueUnit: propValueUnit.unit ?? '',
                    divideByValueUnit: undefined,
                };
                const dimensions = propValueUnit.unit ? unitsMapper.converter.getDimensionsOfUnitsString(propValueUnit.unit) : null;
                perDimensionsDatapoints.getOrCreate(dimensions).push(initialDataPoint);
            }

        } else if (typeof propValueUnit.value === 'number') {
            
            let divideByValueUnit: BasicPropertyValue|undefined = undefined;
            if (divideByPropDescr) {
                divideByValueUnit = readKnownPropValueFromSceneInstance(instance, divideByPropDescr.path, propsFormatters);
                if (!divideByValueUnit || typeof divideByValueUnit.value !== 'number' || !Number.isFinite(divideByValueUnit.value)) {
                    erroneousInstances.push(instanceId);
                    continue;
                }
            }
    
            const initialDataPoint: InitialDataPoint = {
                instanceId,
                value: propValueUnit.value,
                valueUnit: propValueUnit.unit ?? '',
                divideByValueUnit: divideByValueUnit ? [divideByValueUnit.value as number, divideByValueUnit.unit ?? ''] : undefined,
            };
            const dimensions = propValueUnit.unit ? unitsMapper.converter.getDimensionsOfUnitsString(propValueUnit.unit) : null;
            perDimensionsDatapoints.getOrCreate(dimensions).push(initialDataPoint);
        }


    }

    let histogramDataPoints: HistogramDataPoint[];
    let histogramValuesUnit: string;
    const perDimensionsDataPoints = [...perDimensionsDatapoints.entries()];
    if (perDimensionsDataPoints.length > 0) {
        // if there are multiple dimensions, choose the one with the most data points
        const [dimensions, initialDataPoints] = IterUtils.maxBy(
            perDimensionsDataPoints,
            ([_dimensions, dataPoints]) => dataPoints.length
        )!;

        // mark other dimensions as erroneous
        for (const [dimension, dps] of perDimensionsDataPoints) {
            if (dimension !== dimensions) {
                for (const dp of dps) {
                    erroneousInstances.push(dp.instanceId);
                }
            }
        }

        let mostUsedValueUnit: string;
        let mostUsedDivideByUnit: string;
        {
            const valueUnitsCount = new Map<string, number>();
            const divideByUnitsCount = new Map<string, number>();
            for (const dp of initialDataPoints) {
                valueUnitsCount.set(dp.valueUnit, (valueUnitsCount.get(dp.valueUnit) ?? 0) + 1);
                if (dp.divideByValueUnit) {
                    divideByUnitsCount.set(dp.divideByValueUnit[1], (divideByUnitsCount.get(dp.divideByValueUnit[1]) ?? 0) + 1);
                }
            }
            mostUsedValueUnit = IterUtils.maxBy(valueUnitsCount, ([_unit, count]) => count)?.[0] ?? '';
            mostUsedDivideByUnit = IterUtils.maxBy(divideByUnitsCount, ([_unit, count]) => count)?.[0] ?? '';
        }


        const initialValuesUnit = unitsMapper.mapUnitToConfigured(mostUsedValueUnit);
        const divideByUnit = unitsMapper.mapUnitToConfigured(mostUsedDivideByUnit);

        histogramValuesUnit = divideByUnit ? initialValuesUnit + '/' + divideByUnit : initialValuesUnit;

        histogramDataPoints = [];
        for (const dp of initialDataPoints) {

            let mappedDataPoint: HistogramDataPoint;

            const valueConverted = convertUnits(dp.value, dp.valueUnit, initialValuesUnit);
            if (valueConverted instanceof Failure) {
                erroneousInstances.push(dp.instanceId);
                continue;
            }
            if (dp.divideByValueUnit) {
                const divideByConverted = convertUnits(dp.divideByValueUnit[0], dp.divideByValueUnit[1], divideByUnit);
                if (divideByConverted instanceof Failure) {
                    erroneousInstances.push(dp.instanceId);
                    continue;
                }
                const valueDivided = valueConverted.value / divideByConverted.value;
                mappedDataPoint = {
                    instanceId: dp.instanceId,
                    value: Number.isFinite(valueDivided) ? valueDivided : 0,
                    valueUnit: histogramValuesUnit
                }
            } else {
                mappedDataPoint = {
                    instanceId: dp.instanceId,
                    value: valueConverted.value,
                    valueUnit: initialValuesUnit,
                }
            }
            histogramDataPoints.push(mappedDataPoint);
        }
    } else {
        histogramDataPoints = [];
        histogramValuesUnit = '';
    }

    let xAxisName = '→ ' + getCleanedUpPropertyNameForHistogram(propDescr.mergedPath ?? '');
    if (histogramValuesUnit) {
        if (divideByPropDescr) {
            xAxisName += " / " + getCleanedUpPropertyNameForHistogram(divideByPropDescr.mergedPath);
        }
        if (histogramValuesUnit) {
            xAxisName += ` (${histogramValuesUnit})`;
        }
    }
    xAxisName += ' →';            

    const yAxisName: string = '↑ count ↑'

    const histogram = createHistogramDatasetImpl({
        xAxisName,
        yAxisName,
        dataPoints: histogramDataPoints,
        valuesName: propDescr.mergedPath,
        valuesUnit: histogramValuesUnit,
        colorsGradient: [
            RGBA.newRGB(0.05, 0.8, 0.05),
            RGBA.newRGB(0.05, 0.05, 0.99),
            RGBA.newRGB(0.9, 0.05, 0.05),
        ],
        filteredOutByVisibility: filteredOutByVisibility,
        erroneousInstances,
        roundMinMax: chartOptions.round_min_max,
        binsCount: chartOptions.auto_bin_count ? undefined : chartOptions.bins_count,
    });

    return histogram;
}

interface InitialDataPoint {
    value: number;
    valueUnit: string;
    instanceId: IdBimScene,
    divideByValueUnit: [number, string]|undefined;
}

interface HistogramDataPoint {
    value: number;
    valueUnit: string;
    instanceId: IdBimScene,
}


function createHistogramDatasetImpl(args: {
    xAxisName: string,
    yAxisName: string,

    dataPoints: HistogramDataPoint[],
    valuesUnit: string,
    valuesName: string;
    binsCount?: number;
    colorsGradient: [RGBAHex, RGBAHex, RGBAHex];
    filteredOutByVisibility: IdBimScene[];
    erroneousInstances: IdBimScene[];

    roundMinMax: boolean;
}): HistogramDataset {
    
    if (args.dataPoints.length === 0) {
        return new HistogramDataset();
    }
    const allPointsValues: number[] = [];
    const mergedByValue = new DefaultMap<number, HistogramDataPoint[]>(() => []);
    {
        for (const dp of args.dataPoints) {
            mergedByValue.getOrCreate(dp.value).push(dp);
            allPointsValues.push(dp.value);
        }
    }

    const allUniqueValues = Array.from(mergedByValue.keys()).sort((a, b) => a - b);

    let chartMin: number;
    let chartMax: number;
    
    if (args.roundMinMax) {
        [chartMin, chartMax] = chooseRoundedChartMinMaxForNumbers({values: allUniqueValues, preferZeroMin: false});
    } else {
        chartMin = allUniqueValues[0];
        chartMax = allUniqueValues.at(-1)!;
    }

    const binsCount: number = args.binsCount || chooseBinsCount({
        valuesCount: allPointsValues.length,
        uniqueValuesCount: allUniqueValues.length,
        min: allUniqueValues[0],
        max: allUniqueValues.at(-1)!,
    });

    if (!args.roundMinMax) {
        const binSize = ((chartMax - chartMin) || 1) / binsCount;
        
        if (chartMin >= 0) {
            chartMin = Math.max(0, chartMin - binSize * 0.25);
        }
        chartMax += binSize * 0.25;
    }

    const binSize = ((chartMax - chartMin) || 1) / binsCount;

    const chartParams: HistogramChartSubdivisions = {
        chartMin: chartMin,
        binsCount: binsCount,
        binSize,
    }

    const globalMin = chartParams.chartMin;
    const globalMax = chartParams.chartMin + chartParams.binSize * chartParams.binsCount;

    const binned: HistogramDataPoint[][] = IterUtils.newArrayWithIndices(0, binsCount).map(() => []);

    const pointsLowerThanMin: HistogramDataPoint[] = [];
    const pointsHigherThanMax: HistogramDataPoint[] = [];


    for (const [value, dps] of mergedByValue) {

        if (value < globalMin) {
            pointsLowerThanMin.push(...dps);
        } else if (value > globalMax) {
            pointsHigherThanMax.push(...dps);
        } else {
            const binIndex = Math.max(0, Math.floor((value - globalMin) / binSize));
            if (binIndex >= 0 && binIndex < binned.length) {
                binned[binIndex].push(...dps);
            } else {
                console.error('inavlid bin index calculated', binIndex, dps);
            }
        }
    };

    if (pointsLowerThanMin.length > 0) {
        console.error('pointsLowerThanMin', pointsLowerThanMin)
    }
    if (pointsHigherThanMax.length > 0) {
        console.error('pointsHigherThanMax', pointsHigherThanMax)
    }

    let firstNonEmptyIndex = binned.findIndex(b => b.length > 0);
    let lastNonEmptyIndex = binned.indexOf(IterUtils.findBackToFront(binned, b => b.length > 0)!);

    const bins: HistogramBin[] = binned.map((dps, i) => {
        const min = globalMin + i * binSize;
        const max = globalMin + (i + 1) * binSize;

        const color = RGBA.lerpCustomMidPoint(
            args.colorsGradient[0],
            args.colorsGradient[2],
            ((i - firstNonEmptyIndex) / (lastNonEmptyIndex - firstNonEmptyIndex)) || 0,
            args.colorsGradient[1],
        );

        const ids: IdBimScene[] = [];
        const values: number[] = [];
        for (const dp of dps) {
            ids.push(dp.instanceId);
            values.push(dp.value);
        }

        const yValue = dps.length;

        return {
            xValue: (min + max) / 2,
            yValue: yValue,
            barHeight: yValue,
            binSize,
            color,
            ids,
            values,
            unit: args.valuesUnit,
        };
    });

    IterUtils.sortDedupNumbers(args.erroneousInstances);

    return new HistogramDataset(
        args.xAxisName,
        args.yAxisName,
        bins,
        args.valuesUnit,
        args.valuesName,
        globalMin,
        globalMax,
        binSize,
        generateShortStatsFromNumbers(
            allPointsValues, args.valuesUnit,
        ),
        args.filteredOutByVisibility,
        args.erroneousInstances,
    );
}

function generateShortStatsFromNumbers(
    values: number[],
    valuesUnit: string,
): DatasetShortStats | null {
    if (values.length === 0) {
        return null;
    }
    values.sort((a, b) => a - b);
    const perObjMin = IterUtils.min(values)!;
    const perObjMax = IterUtils.max(values)!;
    const valuesSum = values.reduce((a, b) => a + b, 0);
    const averagePerObject = valuesSum / values.length;

    return {
        perObjMin,
        perObjMax,
        valuesUnit,

        averagePerObject,
        objectsCount: values.length,
    }
}

const ObjTypesToSkipColoringOutsideHierarchy = ['road', 'boundary'];

class PerColorCounts {
    colorsCounts: [RGBAHex, number][] = [];

    constructor() {
    }

    add(color: RGBAHex) {
        const index = this.colorsCounts.findIndex(t => t[0] == color);
        if (index === -1) {
            this.colorsCounts.push([color, 1]);
        } else {
            this.colorsCounts[index][1] += 1;
        }
    }

    getResultColor(): RGBAHex|undefined {
        if (this.colorsCounts.length === 0) {
            return undefined;
        }
        if (this.colorsCounts.length === 1) {
            return this.colorsCounts[0][0];
        }
        const sorted = this.colorsCounts.sort((a, b) => a[1] - b[1]);
        return sorted.at(-1)![0]
        // const totalCount = this.colorsCounts.reduce((a, b) => a + b[1], 0);
        // let resultColor = sorted[0][0];
        // for (let i = 0; i < sorted.length; i++) {
        //     const mixPower = sorted[i][1] / totalCount;
        //     resultColor = RGBA.betterlerp(resultColor, sorted[i][0], mixPower);
        // }
        // return resultColor;
    }
}

export function colorizeFromDataset(bim: Bim, dataset: HistogramDataset | CategoryDataset) {

    const colorsPerId = new DefaultMap<IdBimScene, PerColorCounts>(() => new PerColorCounts());
    for (const bin of dataset.bins) {
        for (const instanceId of bin.ids) {
            const colorCount = colorsPerId.getOrCreate(instanceId);
            colorCount.add(bin.color);
        }
    }
    const patches: [IdBimScene, SceneInstancePatch][] = [];
    for (const [id, instance] of bim.instances.perId) {
        let colors = colorsPerId.get(id);
        if (colors === undefined && ObjTypesToSkipColoringOutsideHierarchy.includes(instance.type_identifier)) {
            continue;
        }
        let resultColor: RGBAHex;
        if (colors === undefined) {
            resultColor = 0 as RGBAHex;
        } else {
            resultColor = colors.getResultColor() ?? 0 as RGBAHex;
        }
        if (instance.colorTint !== resultColor) {
            patches.push([id, { colorTint: resultColor }]);
        }
    }
    bim.instances.applyPatches(patches);
}

export function colorizeFromDatasetHierarchically(bim: Bim, histogram: HistogramDataset | CategoryDataset) {
    const parentsColors = new Map<IdBimScene, RGBAHex>();
    for (const bin of histogram.bins) {
        for (const instanceId of bin.ids) {
            parentsColors.set(instanceId, bin.color);
        }
    }
    const parentIdsSorted = Array.from(parentsColors.keys());
    bim.instances.spatialHierarchy.sortByDepth(parentIdsSorted);
    const childrenColors = new Map<IdBimScene, RGBAHex>();

    for (const id of parentIdsSorted) {
        const parentColor = parentsColors.get(id)!;
        bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(
            id,
            (id, _depth): boolean => {
                childrenColors.set(id, parentColor);
                return true;
            }
        );
    }
    const patches: [IdBimScene, SceneInstancePatch][] = [];
    for (const [id, instance] of bim.instances.perId) {
        let colorToSet = parentsColors.get(id) ?? childrenColors.get(id);
        if (colorToSet === undefined && ObjTypesToSkipColoringOutsideHierarchy.includes(instance.type_identifier)) {
            continue;
        }
        colorToSet = colorToSet ?? (0 as RGBAHex);

        if (instance.colorTint !== colorToSet) {
            patches.push([id, { colorTint: colorToSet }]);
        }
    }
    bim.instances.applyPatches(patches);
}

interface HistogramChartSubdivisions {
    chartMin: number;
    binSize: number;
    binsCount: number;
}

function chooseBinsCount(args: {
    valuesCount: number,
    uniqueValuesCount: number,
    min: number,
    max: number,
}): number {
    let count = Math.ceil((Math.sqrt(args.valuesCount) + Math.sqrt(args.uniqueValuesCount)) / 2);
    if (args.min / args.max > 0.8) {
        count = Math.ceil(count * 0.7);
    } else if (args.min / args.max > 0.5) {
        count = Math.ceil(count * 0.85);
    }
    return count;

}

export function getCleanedUpPropertyNameForHistogram(path: string) {
    const LengthLimit = 20;
    if (path.length < LengthLimit) {
        return path;
    }
    const parts = path.split(' | ');

    let res = parts.pop()!;
    while (res.length < LengthLimit && parts.length > 0) {
        const nextPortion = parts.pop()!;
        if (res.length + nextPortion.length + 3 < LengthLimit) {
            res = nextPortion + '_' + res;
        } else {
            break;
        }
    }
    return res;
}




export function createCategoryDataset(
    propDescr: BasicPropertyDescription,
    instances: SceneInstances,
    chartOptions: PropsChartParams,
): CategoryDataset {

    const filteredOutByVisibility: IdBimScene[] = [];

    const perValueIds = new DefaultMap<string, IdBimScene[]>(() => []);
    const propsFormatters = instances.basicPropsView.formatters;
    for (const [instanceId, instance] of instances.perId) {
        if (chartOptions.object_types.length > 0 && !chartOptions.object_types.includes(instance.type_identifier)) {
            continue;
        }
        if (instance.isHidden) {
            filteredOutByVisibility.push(instanceId);
            continue;
        }
        
        // find property
        const propValue = readKnownPropValueFromSceneInstance(instance, propDescr.path, propsFormatters);

        // TODO: erroneous instances
        if (propValue) {
            if (Array.isArray(propValue.value)) {
                for (const value of propValue.value) {
                    perValueIds.getOrCreate(value + '').push(instanceId);
                }
            } else {
                perValueIds.getOrCreate(propValue.value + '').push(instanceId);
            }
        }
    }

    const colorsGradient = [
        RGBA.newRGB(0.05, 0.8, 0.05),
        RGBA.newRGB(0.05, 0.05, 0.99),
        RGBA.newRGB(0.9, 0.05, 0.05),
    ];

    const binsCount = perValueIds.size;
    let binIndex = 0;

    const bins: CategoryDatasetBin[] = [...perValueIds]
        .sort((t1, t2) => t1[0].localeCompare(t2[0]))
        .map(([value, ids]) => {

            const color = RGBA.lerpCustomMidPoint(
                colorsGradient[0],
                colorsGradient[2],
                (binIndex / (binsCount - 1)) || 0,
                colorsGradient[1],
            );
            binIndex += 1;
    
            return {
                color,
                ids,
                value,
            }
        }
    );


    return new CategoryDataset(
        bins,
        filteredOutByVisibility,
    );
}