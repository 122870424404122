import type { LazyValue } from 'engine-utils-ts';
import type { Vector3 } from 'math-ts';
import { Vector2 } from 'math-ts';
import { KeyModifiersFlags } from 'ui-bindings';
import type { MouseRayCone } from '../scene/BoundsSceneWrap';

import type { SceneInt } from '../scene/SceneRaycaster';
import type { MouseButton } from './InputController';
import type { KrCamera } from './MovementControls';

const UniqueGesturesButtons: GesturesButtons[] = [];



export interface MouseGestureConsumer {
    isEnabled(): boolean;
    clickConsumers: MouseClickConsumer[];
    dragConsumers: MouseDragConsumer<any>[];
    onHover? (int: SceneInt | null): {cursorStyleToSet: string} | null;

}

export interface MouseClickConsumer {
	buttons: GesturesButtons;
    sceneRaycastTypeguard: (closest: SceneInt) => boolean;

	clickHandler: (sceneInt: SceneInt, me: MouseEventData) => void;
}
export interface MouseDragConsumer<S> {
	buttons: GesturesButtons;
	sceneRaycastTypeguard(closest: SceneInt): boolean;
    onButtonDown(sceneInt: SceneInt, me: MouseEventData): boolean;
    tryStartDrag(med: MouseEventData, mouseDownInfo: MouseDragInfo, ): S | null;
	handleDrag(s: S, med: MouseEventData, camera: KrCamera): boolean;
	onButtonUp(s: S, med: MouseEventData): void;
    stop(): void;
    cursorDragStyle: (s: S) => string;
    lockPointerOnDrag?: (s: S) => boolean;
}

export class GesturesButtons {
    readonly mouseButton: MouseButton;
    readonly modifierKeys: KeyModifiersFlags;

    equals(rhs: GesturesButtons): boolean {
        return this.modifierKeys === rhs.modifierKeys && this.mouseButton == rhs.mouseButton;
    }

    private constructor(
        mouseButtons: MouseButton,
        modifierKeys: KeyModifiersFlags,
    ) {
        this.mouseButton = mouseButtons;
        this.modifierKeys = modifierKeys;
        Object.freeze(this);
    }


    static newShared(
        mouseButtons: MouseButton,
        modifierKeys?: KeyModifiersFlags,
    ): GesturesButtons {
        const gb = new GesturesButtons(mouseButtons, modifierKeys ?? KeyModifiersFlags.None);
        for (const sgb of UniqueGesturesButtons) {
            if (gb.equals(sgb)) {
                return sgb;
            }
        }
        UniqueGesturesButtons.push(gb);
        return gb;
    }
}


export interface MouseDragInfo {
    sceneInt: SceneInt,
    med: MouseEventData,
    worldSpaceDirection: Vector3,
    screenSpaceDirection: Vector2,
}

export interface MouseEventData {
    buttons: GesturesButtons,
    pos: GesturesMousePos;
    mouseCone: MouseRayCone | null,
    camera: KrCamera;
    source: MouseEvent;
}


export function getMouseEventData(
    e: MouseEvent,
    pos: GesturesMousePos,
    mouseRay: LazyValue<MouseRayCone | null>,
    camera: KrCamera,
): MouseEventData {

    let buttons: GesturesButtons;
    if (e.type === 'pointerup' || e.type === 'mouseup') {
        // buttons property on mouse up events will not contain button that was released
        // remap button property
        let buttonPow = e.button;
        if (buttonPow === 1) {
            buttonPow = 2;
        } else if (buttonPow === 2) {
            buttonPow = 1;
        }
        const buttonFlags = Math.round(Math.pow(2, buttonPow));
        buttons = GesturesButtons.newShared(buttonFlags, getModifiers(e));
    } else {
        buttons = GesturesButtons.newShared(e.buttons, getModifiers(e));
    }
	return {
        buttons,
        pos,
        mouseCone: mouseRay.poll(),
		camera,
		source: e,
	}
}

export function getModifiers(event: MouseEvent | KeyboardEvent): KeyModifiersFlags {
	let flags: KeyModifiersFlags = 0;
	if (event.shiftKey) { flags |= KeyModifiersFlags.Shift; }
	if (event.ctrlKey) { flags |= KeyModifiersFlags.Ctrl; }
	if (event.altKey) { flags |= KeyModifiersFlags.Alt; }
	return flags;
}

export class GesturesMousePos {
    readonly mousePos: Vector2;
    readonly mousePosNormalized: Vector2;

    readonly delta: Vector2;
    readonly deltaNormalized: Vector2;

    constructor(mousePos: Vector2, posNormalized: Vector2, delta: Vector2, deltaNormalized: Vector2) {
        this.mousePos = mousePos;
        this.mousePosNormalized = posNormalized;
        this.delta = delta;
        this.deltaNormalized = deltaNormalized;
    }

	posNormalizedGlCoords(): Vector2 {
		return new Vector2(
			this.mousePosNormalized.x * 2 - 1,
			-this.mousePosNormalized.y * 2 + 1,
		)
	}

    static newFromEvent(e: MouseEvent, rect: DOMRect): GesturesMousePos {

        const notRealWidth = (rect.width + rect.height) / 2;
        const mousePos = new Vector2(
            (e.clientX - rect.left) / notRealWidth, //use this for both, to get consistent sensitivity
            (e.clientY - rect.top) / notRealWidth,
        );

        const mousePosNormalized = new Vector2(
            (e.clientX - rect.left) / rect.width,
            (e.clientY - rect.top) / rect.height,
        );


        const mousePosDelta = new Vector2(
            e.movementX / notRealWidth,
            e.movementY / notRealWidth
        );

        const mousePosNormalizedDelta = new Vector2(
            e.movementX / rect.width,
            e.movementY / rect.height
        );

        return new GesturesMousePos(mousePos, mousePosNormalized, mousePosDelta, mousePosNormalizedDelta);
    }
}
