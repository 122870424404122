import type { Bim, Catalog } from 'bim-ts';
import type {
    Disposable, LazyVersioned, ObservableObject, ProjectNetworkClient, RGBAHex, TasksRunner, UndoStack, Yield
} from 'engine-utils-ts';

import type { PUI_GroupNode, UiBindings } from 'ui-bindings';
import type { VerDataSyncer } from 'verdata-ts';

import type { ClipboxSettings } from './clipbox/ClipboxApi';
import type { CameraSettings } from './controls/ControlsApi';
import type { Screenshot } from './data/Screenshot';
import type { EdgesRenderMode, TransformOrientationMode, TransformPivotMode } from './EngineConsts';
import { engineEditSelectionCustomPropsUi } from './EngineUiBindings';
import { KreoEngineImpl } from './KreoEngineImpl';
import type { TerrainDisplayEngineSettings } from './TerrainDisplayEngineSettings';
import type { EngineUnits } from './EngineLegacyUiUnits';
import type { Aabb2, Vector2 } from 'math-ts';
import type { AnnotationsSettings } from './annotations/AnnotationsSettingsUiBindings';
import type { TrackersPilesMarkupSettings } from './TrackersPilesMarkupSettingsUiBindings';

export interface EngineConstructionParams {
	container: HTMLElement,
	bim: Bim,
	catalog: Catalog,
	network: ProjectNetworkClient,
	texturesUrl: string,
	undoStack?: UndoStack,
}


export {
	TerrainDisplayMode, TerrainDisplayEngineSettings
}from './TerrainDisplayEngineSettings';


export interface TransformGizmoSettings {
	// isEnabled: boolean;
	isActive: boolean;
	moveOnlyParents: boolean,
	pivot: TransformPivotMode,
	orientation: TransformOrientationMode,
}

export interface EngineGraphicsSettings {
	backgroundColor: RGBAHex;
	floorGridOpacity: number;
	floorGridCellSize: Vector2;
	floorGridCellSizeAuto: boolean;
}

export class KreoEngine {
	private _impl: KreoEngineImpl;

	undoStack: UndoStack;
	tasksRunner: TasksRunner;

	uiBindings: UiBindings;

    editSelectionCustomPropsUi(): LazyVersioned<PUI_GroupNode> {
        return engineEditSelectionCustomPropsUi(this._impl);
    }

	clipboxState: ObservableObject<ClipboxSettings>;
	cameraState: ObservableObject<CameraSettings>;
	terrainDisplaySettings: ObservableObject<TerrainDisplayEngineSettings>;
	annotationsSettings: ObservableObject<AnnotationsSettings>;
	transformGizmoSettings: ObservableObject<TransformGizmoSettings>;
	graphicsSettings: ObservableObject<EngineGraphicsSettings>;
	trackersPilesMarkupSettings: ObservableObject<TrackersPilesMarkupSettings>;

	connectToVerdata(syncer: VerDataSyncer) {
		const syncedCollection = this._impl.createPersistedCollections();
		syncer.attachCollections(syncedCollection);
		this._impl.connectToVerdataEvents(syncer);
	}

	static async newAsync(params: EngineConstructionParams): Promise<KreoEngine> {
		const impl = await KreoEngineImpl.newAsync(params);
		return new KreoEngine(impl);
	}

	private constructor(engine: KreoEngineImpl) {
		if (!(engine instanceof KreoEngineImpl)) {
			//maybe insert stub to not generate exceptions on every occasion later?
			throw new Error('Use KreoEngine.newAsync({container, texturesUrl, wasmUrl}): Promise<KreoEngine> to construct engine, old sync constructor is no longer supported');
		}
		this._impl = engine;
		(window as any)['___engine'] = this;
		this.undoStack = this._impl.undoStack;
		this.tasksRunner = this._impl.tasksRunner;
		this.uiBindings = this._impl.uiBindings;

		this.terrainDisplaySettings = this._impl.terrainDisplaySettings;
		this.annotationsSettings = this._impl.annotationsSettings;
		this.trackersPilesMarkupSettings = this._impl.trackersPilesMarkupSettings;

		this.clipboxState = this._impl.clipBox.state.newChainedSubsetObservable(
			['isActive']
		);
		this.cameraState = this._impl.movementControls.obs_state.newChainedSubsetObservable(
			['projectionMode']
		);

		this.transformGizmoSettings = this._impl.transformGizmo.gizmoState.state.newChainedSubsetObservable([
			'isActive',
			'moveOnlyParents',
			'pivot',
			'orientation',
		]);

		this.graphicsSettings = this._impl.renderSettings.newChainedSubsetObservable([
			'backgroundColor',
			'floorGridOpacity',
			'floorGridCellSize',
			'floorGridCellSizeAuto',
		]);
	}
	getPositionInFrontOfCameraForImport() {
		return this._impl.getPositionInFrontOfCameraForImport();
	}

	dispose(): void {
		this._impl.dispose();
		(window as any)['___engine'] = null;
	}

	addListener(eventName: string, callback: Function): Disposable {
		return this._impl.addListener(eventName, callback);
	}

	focusCamera(ids?: number[]): void {
		return this._impl.focusCamera(ids);
	}
	focusFrom(ids: number[] | null, verticalAngle: number, horizontalAngle: number, durationMultiplier?: number): boolean {
		return this._impl.focusFrom(ids, verticalAngle, horizontalAngle, durationMultiplier);
	}
	cameraToHome(): void {
		return this._impl.cameraToHome();
	}
	toggleParallelProjection(enabled: boolean): void {
		return this._impl.toggleParallelProjection(enabled);
	}
	toggleFirstPerson(isEnabled: boolean): void {
		return this._impl.toggleFirstPerson(isEnabled);
	}
	calculateBoundsCenter(bimsIds: number[]): [number, number, number] {
		return this._impl.calculateBoundsCenter(bimsIds);
	}

	setEdgesRenderMode(edgesRenderMode: EdgesRenderMode): void {
		return this._impl.setEdgesRenderMode(edgesRenderMode);
	}
	toggleClipbox(isEnabled: boolean): void {
		return this._impl.toggleClipbox(isEnabled);
	}
	focusClipbox(ids?: number[]): void {
		return this._impl.focusClipbox(ids);
	}
	getScreenCoordsOfPoint(coords: number[]): number[] | null {
		return this._impl.getScreenCoordsOfPoint(coords);
	}
	isPointVisible(point: number[]): boolean {
		return this._impl.isPointVisible(point);
	}
	isAnyInvisible(): boolean {
		return this._impl.isAnyInvisible();
	}
	isAnyColorTinted(): boolean {
		return this._impl.isAnyColorTinted();
	}
	isColorTinted(id: number): boolean | null {
		return !!this._impl.isColorTinted(id);
	}
	toggleCanvasTransparency(enabled: boolean): void {
		return this._impl.toggleCanvasTransparency(enabled);
	}
	takeScreenshotRawPng(width: number, height: number, renderSettings?: Partial<EngineGraphicsSettings>): Promise<ArrayBuffer> {
		return this._impl.takeScreenshotRawPng(width, height, renderSettings);
	}
	takeHighResScreenshotPng(renderSettings?: Partial<EngineGraphicsSettings>): Promise<ArrayBuffer> {
		return this._impl.takeHighResScreenshotPng(renderSettings);
	}
	takecreenshotTopdown(wsCoords: Aabb2, screenshotSize: Vector2, renderSettings?: Partial<EngineGraphicsSettings>) {
		return this._impl.takecreenshotTopdown(wsCoords, screenshotSize, renderSettings);
	}
	takeScreenshotBase64(width: number, height: number, renderSettings?: Partial<EngineGraphicsSettings>): Promise<Screenshot> {
		return this._impl.takeScreenshot(width, height, renderSettings);
	}

	getUiUnits(): EngineUnits {
		return this._impl.getUiUnits();
	}
	setUiUnits(units: Partial<EngineUnits>): void {
		return this._impl.setUiUnits(units);
	}
	resize(width?: number, height?: number): void {
		return this._impl.resize(width, height);
	}
	clear(): void {
		return this._impl.clear();
	}

	getDomContainer(): HTMLElement {
		return this._impl.container;
	}
	
	*waitForEngineSceneToBeReady(): Generator<Yield, void> {
		yield* this._impl.waitForEngineSceneToBeReady();
	}
}

export type TypedArray = Int8Array | Uint8Array | Uint8ClampedArray
	| Int16Array | Uint16Array | Int32Array | Uint32Array | Float32Array | Float64Array;
