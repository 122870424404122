<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        d="M9.6875 10.37L12 7.28677L16.8849 13.8C16.9793 13.9259 17.1275 14 17.2849 14H18.0349C18.4469 14 18.6821 13.5296 18.4349 13.2L12 4.62012L8.6875 9.03671L9.6875 10.37Z"
        fill="currentColor"
    />
    <path
        d="M1.56515 13.2L5.49996 7.95361L9.43477 13.2C9.68199 13.5296 9.44679 14 9.03477 14H8.28478C8.1274 14 7.97921 13.9259 7.88478 13.8L5.49996 10.6202L3.11514 13.8C3.02072 13.9259 2.87252 14 2.71514 14H1.96515C1.55313 14 1.31794 13.5296 1.56515 13.2Z"
        fill="currentColor"
    />
    <path
        d="M1.5349 17.3C1.12286 17.3 0.887676 16.8296 1.13493 16.5L1.58487 15.9002C1.6793 15.7743 1.82748 15.7002 1.98484 15.7002H17.2154C17.3728 15.7002 17.5209 15.7743 17.6154 15.9002L18.0653 16.5C18.3126 16.8296 18.0774 17.3 17.6653 17.3H1.5349Z"
        fill="currentColor"
    />
</svg>
