import { VectorPrimitiveCircle } from "vector-graphic";
import { Aabb2, Vector2 } from "math-ts";
import { createSCLeftSideWiring, createSCObject2DFromDescription } from "./cabinet";
import { createTransformerObject2DFromDescription } from "./transformer";
import type { SCDescription, TransformerDescription } from "../types";
import { createManyToOneConnection } from "src/sld/templates/connections";
import { Anchors2D } from "vector-graphic";
import { wireWidth } from "src/sld/templates/config";

export function createTransformerChainObject2D(transformer: TransformerDescription)
    : Anchors2D
{
    if (!transformer.childTransformer && !transformer.childSC) {
        const obj = createTransformerObject2DFromDescription(transformer);
        const chain = new Anchors2D()
        chain.name = 'chain'
        chain.addAndExpandAabb(obj);
        chain.points = [obj.rightConnection.clone()]
        const emptyExit = new VectorPrimitiveCircle({
            fill: 'white',
            stroke: 'black',
            cx: obj.leftConnection.x,
            cy: obj.leftConnection.y,
            strokeWidth: wireWidth / 2,
            radius: wireWidth,
        })
        chain.addPrimitiveAndExpandAabb(emptyExit)

        emptyExit.zIndex = 1

        return chain;
    }

    let chain: Anchors2D;
    if (transformer.childTransformer) {
        chain = createTransformerChainObject2D(transformer.childTransformer)
    } else {
        chain = createSCChainObject2D(transformer.childSC!)
    }

    const obj = createTransformerObject2DFromDescription(transformer);
    obj.position.set(
        chain.points[0].x - obj.leftConnection.x,
        chain.points[0].y - obj.leftConnection.y,
    );
    obj.updateMatrix()
    chain.addAndExpandAabb(obj);
    chain.points = [obj.rightConnection.clone().applyMatrix3(obj.matrix)];

    return chain;
}


export function createSCChainObject2D(cabin: SCDescription): Anchors2D {
    // if last child, then return simple Cabinet
    if (!cabin.childTransformers.length && !cabin.childSCs.length) {
        const obj = createSCObject2DFromDescription(cabin);
        const chain = new Anchors2D()
        chain.name = 'chain'
        chain.addAndExpandAabb(obj);
        chain.points = [obj.rightConnection];
        return chain;
    }

    // calculate all children Objects
    const chain = Anchors2D.stackAnchorsAndMerge([
        ...cabin.childTransformers.map(createTransformerChainObject2D),
        ...cabin.childSCs.map(createSCChainObject2D)
    ], { yOffset: 100 });
    const chainMidY = chain.aabb.centerY();

    // create cabinet
    const cabinet = createSCObject2DFromDescription(cabin);

    // create wiring
    const childrenWiring = createManyToOneConnection({
        sources: chain.points,
        sourceOffset: 100,
        wireOffset: 20,
        targetHeight: cabinet.aabb.height() * 0.7,
    })
    chain.addAndExpandAabb(childrenWiring)

    // position cabinet
    if (!chain.aabb.isEmpty() && childrenWiring.points.length) {
        const aabb = aabbReused.setFromPoints(childrenWiring.points);

        cabinet.position.set(
            aabb.centerX() - cabinet.aabb.min.x,
            aabb.centerY(),
        );

        cabinet.updateMatrix()
    }
    chain.add(cabinet);


    // create cabinet internal wiring
    {
        const cabinetZeroWorld = new Vector2().applyMatrix3(cabinet.matrix);
        if (childrenWiring.points.length) {
            const internalWiring = createSCLeftSideWiring(
                childrenWiring.points,
                cabinetZeroWorld
            )
            chain.addAndExpandAabb(internalWiring);
        }
    }

    chain.points = [cabinet.rightConnection.clone().applyMatrix3(cabinet.matrix)];

    chain.recalculateAabb();
    return chain;
}

const aabbReused = Aabb2.empty();
