import { Vector4, Vector3 } from 'math-ts';
import { DoubleSide, UniformsUtils } from '../3rdParty/three';
import { ShaderMaterial } from '../3rdParty/three';
import type { GlobalUniforms } from '../materials/GlobalUniforms';
import { MaterialsFactory } from '../materials/MaterialsFactory';
import type { ShaderBase } from '../shaders/ShaderBase';
import type { TextGeometries } from '../text/TextGeometry';
import { TextShader } from '../text/TextShader';



export function generateSnatchMaterial(g_uniforms: GlobalUniforms): ShaderMaterial {
	const edgeMaterial = MaterialsFactory.createThreeMaterial(SnatchedRulerLineShader);
	edgeMaterial.side = DoubleSide;
	edgeMaterial.uniforms['snatchIntervals'] = g_uniforms.snatchedRulerIntervals;
	return edgeMaterial;
}



let sharedRulerLineMaterial: ShaderMaterial | null = null;
export function createSnatchMaterial(g_uniforms: GlobalUniforms) {
	if (sharedRulerLineMaterial == null) {
		sharedRulerLineMaterial = generateSnatchMaterial(g_uniforms);
		// sharedRulerLineMaterial.depthTest = true;
		const background = sharedRulerLineMaterial.uniforms['colorBackground'].value as Vector4;
		const intervalsHeight = sharedRulerLineMaterial.uniforms['intervalsHeight'].value as Vector3;
		const intervalsOpacity = sharedRulerLineMaterial.uniforms['intervalsOpacity'].value as Vector3;

		// background.set(0, 0, 0, 0.2);
		intervalsHeight.set(1, 1, 1);
		intervalsOpacity.set(0.8, 0.8, 0.9);
		background.set(0.4, 0.4, 0.4, 0.6);
	}
	return sharedRulerLineMaterial;
}

let sharedTextLineMaterial: ShaderMaterial | null = null;
export function createTextMaterial(textGeometries: TextGeometries) {
	if (sharedTextLineMaterial == null) {
		const tm = new ShaderMaterial(TextShader);
		tm.uniforms = UniformsUtils.clone(tm.uniforms);
		tm.transparent = true;
		tm.side = DoubleSide;
		tm.depthTest = false;
		tm.depthWrite = false;
		tm.polygonOffset = true;
		tm.polygonOffsetUnits = -3.0;
		tm.polygonOffsetFactor = -3.0;

		// tm.blending = SubtractiveBlending;
		tm.uniforms['fontAtlas'].value = textGeometries.charAtlas.texture;
		tm.uniforms['backgroundOpacity'].value = 0.15;
		// tm.uniforms['backgroundOpacity'].value = 0.1;
		tm.name = 'fontMaterial';
		sharedTextLineMaterial = tm;
	}
	return sharedTextLineMaterial;
}


export const SnatchedRulerLineShader: ShaderBase = Object.freeze({
	
	name: 'snatchedEdgeLine',

	extensions: {
		derivatives: true,
	},


	uniforms: {
		// "right_v": 	{ value: Vector3.allocate(1, 0, 0) },
		// "up_v": 		{ value: Vector3.allocate(0, 1, 0) },

		// "colorOpacity": { value: Vector4.allocate(0.2, 0.2, 0.2, 0.9) },

		'colorBackground': { value: Vector4.allocate(0, 0, 0, 0.0) },
		'snatchIntervals': { value: Vector4.allocate(1, 0.5, 0.1, 0.01) },
		'intervalsHeight': { value: Vector3.allocate(1, 0.5, 0.5) },
		'intervalsOpacity': { value: Vector3.allocate(1, 0.75, 0.6) },
	},

	vertexShader: `

	attribute vec2 position2d;
	uniform vec3 right_v;
	uniform vec3 up_v;

	varying vec2 rulerValues;
	
	void main() {
	
		vec3 right = position2d.x * right_v;
		vec3 up = position2d.y * up_v;
		vec3 transformed = right + up;
		vec4 mvPosition = modelViewMatrix * vec4( transformed, 1.0 );

		float totalLength = length(right);
		rulerValues = vec2(totalLength, position2d.y);

		gl_Position = projectionMatrix * mvPosition;
	}
	
	`,

	fragmentShader: `
	
	uniform vec4 colorOpacity;
	uniform vec4 colorBackground;
	uniform vec4 snatchIntervals;
	uniform vec3 intervalsHeight;
	uniform vec3 intervalsOpacity;
	
	#include <common>
	varying vec2 rulerValues;


	float snatchValue(float rulerValue, float snatchInterval, float snatchWidth, float aa) {
		float nextSnatch = ceil(rulerValue / snatchInterval) * snatchInterval;
		float prevSnatch = floor(rulerValue / snatchInterval) * snatchInterval;
		float distFromSnatch = min(nextSnatch - rulerValue, rulerValue - prevSnatch);
		// float distFromSnatch = abs(mod(abs(rulerValue), snatchInterval) - snatchInterval);
		float alpha = smoothstep(0.5 * (snatchWidth + aa), 0.5 * (snatchWidth - aa), distFromSnatch);
		return alpha;
	}

	float cutAlphaByHeight(float rulerHeight, float cutOutHeight) {
		float aa = length(fwidth(rulerHeight));
		float stepWidth = cutOutHeight * 0.1;
		float toEdgeDistance = rulerHeight - cutOutHeight;
		toEdgeDistance = clamp(toEdgeDistance, 0.0, stepWidth);
		float alpha = smoothstep(0.5 * (stepWidth + aa), 0.5 * (stepWidth - aa), toEdgeDistance);
		return max(alpha, 0.3);
	}
	
	float underline() {
		float underlineWidth1 = 0.2;
		float underlineWidth2 = 0.1;
		float aa = length(fwidth(rulerValues.y));
		float underline = smoothstep(0.5 * (underlineWidth1 + aa), 0.5 * (underlineWidth1 - aa), rulerValues.y);
		return underline;
	}
		
	void main() {

		float aa = length(fwidth(rulerValues.x));
		float lineWidth = snatchIntervals.w;

		float line = abs(fract(rulerValues.x - 0.5) - 0.5) / fwidth(rulerValues.x);

		float opacity1 = snatchValue(rulerValues.x, snatchIntervals.x, lineWidth, aa) * intervalsOpacity.x;

		float opacity2 = snatchValue(rulerValues.x, snatchIntervals.y, lineWidth, aa) * intervalsOpacity.y;
		opacity2 *= cutAlphaByHeight(rulerValues.y, intervalsHeight.y);

		float opacity3 = snatchValue(rulerValues.x, snatchIntervals.z, lineWidth, aa) * intervalsOpacity.z;
		opacity3 *= cutAlphaByHeight(rulerValues.y, intervalsHeight.z);

		float opacity =  max(max(opacity1, opacity2), opacity3);

		opacity = max(opacity, underline());

		vec4 color = mix(colorBackground, colorOpacity, opacity);

		gl_FragColor = color;
	}
	`,
});
