import type { Bim, BimImageType } from 'bim-ts';
import { AssetRef, BimImage, SceneImageRepresentation } from 'bim-ts';
import type { Result, Yield } from 'engine-utils-ts';
import { Failure, FilesUtils, PollablePromise, ProjectNetworkClient, Success } from 'engine-utils-ts';
import { Vector2, Vector3 } from 'math-ts';
import type { FileImporter, FileImporterContext, FileToImport } from 'ui-bindings';
import { newDefaultImageInstance } from './PngImageImporter';
import { requestSettings } from '../SettingsFormUi';

export class WebpImageImportSettings {
    scale: number = 1;
    origin: Vector3 = new Vector3(0, 0, 0);
}

export class WebpImageImporter implements FileImporter {

    fileExtensions = ['.webp'];

    constructor(
        readonly bim: Bim
    ) {
    }

    *startImport(context: FileImporterContext, file: FileToImport): Generator<Yield, void> {

        const _network = new ProjectNetworkClient({
            ...context.network?.config,
            basePath: "/api/assets",
        });
        const bim = this.bim;

            const size = calculateSize(new Uint8Array(file.fileArrayBuffer));
            if(!size){
                context.logger.error(`import failed webp format not valid!`);
                throw new Error("Import failed");
            }
            const formData = new FormData();
            formData.append(
                "file",
                new Blob([file.fileArrayBuffer]),
                FilesUtils.cleanedUpName(file.filename)
            );

            const pr = _network.postFormData('', formData);
            const bpResult: Result<Response> = yield * PollablePromise.generatorWaitFor<Response>(pr);
            if(bpResult instanceof Failure){
                context.logger.error(`import failed`, bpResult.errorMsg());
                throw new Error("Import failed");
            }

            if(bpResult instanceof Success){
                const response = bpResult.value;
                if(response.status !== 200){
                    context.logger.error(`import failed`, response);
                    throw new Error("Import failed");
                }
            }

            const imageId = bim.bimImages.reserveNewId();
            const bimImage = new BimImage(new AssetRef(file.filename), null);

            const allocated = bim.bimImages.allocate([[imageId, bimImage]]);
            if (allocated[0] != imageId) {
                throw new Error('bim image alloc unsucessfull');
            };

            requestSettings({
                ident: 'webp-image-import-settings',
                defaultValue: new WebpImageImportSettings(),
                context,
                submit: (params) => context.importInstances(this.importInstances(context, file, params, size, imageId))
            });
    }

    *importInstances(
        context: FileImporterContext,
        file: FileToImport,
        settings: WebpImageImportSettings,
        size: {width: number, height: number},
        imageId: BimImageType
    ) {
            var imageRepresentation = new SceneImageRepresentation(
                imageId,
                new Vector2(size.width, size.height).multiplyScalar(settings.scale)
            );

            const siId = this.bim.instances.idsProvider.reserveNewId();
            const instance = newDefaultImageInstance('image', file.filename, imageRepresentation);
            instance.worldMatrix.setPositionV(settings.origin);
            const instancesIds = this.bim.instances.allocate([[
                siId,
                instance
            ]]);
        this.bim.instances.setSelected(instancesIds);
        context.onFinish(instancesIds);
    }
}







const SIG_RIFF = str2arr('RIFF');
const SIG_WEBP = str2arr('WEBP');


function parseVP8(data:Uint8Array, offset:number) {
  if (data[offset + 3] !== 0x9D || data[offset + 4] !== 0x01 || data[offset + 5] !== 0x2A) {
    // bad code block signature
    return;
  }

  return {
    width:  readUInt16LE(data, offset + 6) & 0x3FFF,
    height: readUInt16LE(data, offset + 8) & 0x3FFF,
    type:   'webp',
    mime:   'image/webp',
    wUnits: 'px',
    hUnits: 'px'
  };
}


function parseVP8L(data:Uint8Array, offset:number) {
  if (data[offset] !== 0x2F) return;

  var bits = readUInt32LE(data, offset + 1);

  return {
    width:  (bits & 0x3FFF) + 1,
    height: ((bits >> 14) & 0x3FFF) + 1,
    type:   'webp',
    mime:   'image/webp',
    wUnits: 'px',
    hUnits: 'px'
  };
}


function parseVP8X(data:Uint8Array, offset:number) {
  return  {
    // TODO: replace with `data.readUIntLE(8, 3) + 1`
    //       when 0.10 support is dropped
    width:  ((data[offset + 6] << 16) | (data[offset + 5] << 8) | data[offset + 4]) + 1,
    height: ((data[offset + 9] << offset) | (data[offset + 8] << 8) | data[offset + 7]) + 1,
    type:   'webp',
    mime:   'image/webp',
    wUnits: 'px',
    hUnits: 'px'
  };
}


function calculateSize (data:Uint8Array) {
  if (data.length < 16) return;

  // check /^RIFF....WEBPVP8([ LX])$/ signature
  if (!sliceEq(data, 0, SIG_RIFF) && !sliceEq(data, 8, SIG_WEBP)) return;

  var offset = 12;
  var result = null;
  var fileLength = readUInt32LE(data, 4) + 8;

  if (fileLength > data.length) return;

  while (offset + 8 < fileLength) {
    if (data[offset] === 0) {
      // after each chunk of odd size there should be 0 byte of padding, skip those
      offset++;
      continue;
    }

    var header = String.fromCharCode.apply(null, data.slice(offset, offset + 4));
    var length = readUInt32LE(data, offset + 4);

    if (header === 'VP8 ' && length >= 10) {
      result = result || parseVP8(data, offset + 8);
    } else if (header === 'VP8L' && length >= 9) {
      result = result || parseVP8L(data, offset + 8);
    } else if (header === 'VP8X' && length >= 10) {
      result = result || parseVP8X(data, offset + 8);
    } else if (header === 'EXIF') {

      // exif is the last chunk we care about, stop after it
      offset = Infinity;
    }

    offset += 8 + length;
  }

  if (!result) return;



  return result;
};

function readUInt16LE (data:Uint8Array, offset:number) {
    return data[offset] | (data[offset + 1] << 8);
  };



function readUInt32LE(data:Uint8Array, offset:number) {
    return data[offset] |
      (data[offset + 1] << 8) |
      (data[offset + 2] << 16) |
      (data[offset + 3] * 0x1000000);
  };



function sliceEq(src:Uint8Array, start:number, dest:number[]) {
    for (var i = start, j = 0; j < dest.length;) {
      if (src[i++] !== dest[j++]) return false;
    }
    return true;
  };

function str2arr (str:string, format?:string) {
    var arr:number[] = [], i = 0;

    if (format && format === 'hex') {
      while (i < str.length) {
        arr.push(parseInt(str.slice(i, i + 2), 16));
        i += 2;
      }
    } else {
      for (; i < str.length; i++) {
        /* eslint-disable no-bitwise */
        arr.push(str.charCodeAt(i) & 0xFF);
      }
    }

    return arr;
  };
