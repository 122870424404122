<script lang="ts">
    import type { ItemErrorMsg } from "ui-bindings";
    import { CheckboxStandard } from "../../../libui/checkbox";
    import ErrorMessage from "./ErrorMessage.svelte";

    export let name: string;
    export let value: boolean | "multiple";
    export let readonly: boolean = false;
    export let onClickCheckbox: ((newValue: boolean) => void) | undefined =
        undefined;
    export let errorMsg: ItemErrorMsg | undefined = undefined;
    export let danger: boolean = false;

    $: isError = errorMsg !== undefined || danger;
    $: isCheckBox = onClickCheckbox !== undefined;
</script>

<div class="selected-item-root">
    <div class="selected-item">
        <span class="name">
            <span class:checkbox={isCheckBox}>
                {#if isCheckBox}
                    <CheckboxStandard
                        {value}
                        {readonly}
                        onChange={(newVal) => {
                            if (onClickCheckbox) {
                                onClickCheckbox(newVal);
                            }
                        }}
                    />
                {/if}
            </span>
            <span
                class="item-name"
                class:text-main-dark={!isError}
                class:text-danger={isError}>{name}</span
            >
        </span>
        <span class="rest-info">
            <slot></slot>
        </span>
    </div>
    {#if errorMsg !== undefined}
        <ErrorMessage message={errorMsg} />
    {/if}
</div>

<style lang="scss">
    .selected-item-root {
        width: 100%;
    }
    .selected-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }
    .name {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
        flex-shrink: 0;
    }
    .checkbox {
        height: fit-content;
        padding-right: 4px;
        padding-top: 3px;
    }

    .item-name {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .rest-info {
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
    }
</style>
