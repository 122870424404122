import { Bim, importBimassetToBim } from "..";
import type { PropertyGroup } from "../properties/PropertyGroup";
import type { Asset } from "./Asset";
import type { Catalog } from "./Catalog";
import { registerAssetCatalogItemUiLabels, registerCutFillCatalogItemUiLabels, registerLvWireSpecCatalogItemUiLabels, registerMvWireSpecCatalogItemUiLabels, registerPileCatalogItemUiLabels, registerRoadCatalogItemUiLabels, registerTrackerPositionMultiperCatalogItemUiLabels, registerTrenchCatalogItemUiLabels } from "./catalog-subtypes";
import type { PropertiesStringTemplate } from "./SceneInstanceHierarchyPropsRegistry";
import type { PropertiesGroupFormatters } from '../bimDescriptions/PropertiesGroupFormatter';

export interface CatalogItemUiLabels {
    title: string;
    group: PropertiesStringTemplate[];
    price?: string;
}

export type CatalogItemUiLabelsSolver<T extends PropertyGroup> = (item: T) => CatalogItemUiLabels;

export class CatalogItemsUiLabels {
    private solvers = new Map<string, CatalogItemUiLabelsSolver<any>>();
    register<T extends PropertyGroup>(
        identifier: string,
        solver: CatalogItemUiLabelsSolver<T>
    ) {
        this.solvers.set(identifier, solver);
    }
    solve(identifier: string, item: PropertyGroup): CatalogItemUiLabels | null {
        return this.solvers.get(identifier)?.(item) ?? null;
    }
}

export function createCatalogItemsUiLabels(catalog: Catalog) {
    const provider = new CatalogItemsUiLabels();
    registerTrackerPositionMultiperCatalogItemUiLabels(provider);
    registerRoadCatalogItemUiLabels(provider, catalog.unitsMapper);
    registerAssetCatalogItemUiLabels(provider, catalog);
    registerCutFillCatalogItemUiLabels(provider, catalog.unitsMapper);
    registerPileCatalogItemUiLabels(provider, catalog.unitsMapper);
    registerLvWireSpecCatalogItemUiLabels(provider, catalog);
    registerMvWireSpecCatalogItemUiLabels(provider, catalog);
    registerTrenchCatalogItemUiLabels(provider, catalog.unitsMapper);
    return provider;
}

export function* getAssetGeneratedName(
    asset: Asset,
    siLabelFormatter: PropertiesGroupFormatters,
) {
    if (asset.name) {
        return asset.name;
    }
    const bim = new Bim({});
    const ids = yield* importBimassetToBim(
        asset.bimasset,
        bim,
    )
    yield* bim.runUpdatesTillCompletion({forceRun: true});
    const si = bim.instances.peekById(ids[0]);
    if (!si) {
        return null
    }
    const label = siLabelFormatter.format(si.type_identifier, si.properties, si.props);
    return label;
}
