<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <mask
        id="mask0_382_6826"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
    >
        <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_382_6826)">
        <path
            d="M4.04781 14.7903C3.63072 14.7903 3.27367 14.6418 2.97666 14.3448C2.67964 14.0478 2.53113 13.6907 2.53113 13.2736V6.70261C2.53113 6.28553 2.67964 5.92848 2.97666 5.63146C3.27367 5.33445 3.63072 5.18594 4.04781 5.18594H4.60686V3.96819C4.60686 3.02472 4.93718 2.22133 5.59781 1.55804C6.25843 0.894755 7.05916 0.56311 7.99998 0.56311C8.9408 0.56311 9.74152 0.894755 10.4021 1.55804C11.0628 2.22133 11.3931 3.02472 11.3931 3.96819V5.18594H11.9521C12.3692 5.18594 12.7263 5.33445 13.0233 5.63146C13.3203 5.92848 13.4688 6.28553 13.4688 6.70261V13.2736C13.4688 13.6907 13.3203 14.0478 13.0233 14.3448C12.7263 14.6418 12.3692 14.7903 11.9521 14.7903H4.04781ZM4.04781 13.2736H11.9521V6.70261H4.04781V13.2736ZM7.99998 11.3215C8.36664 11.3215 8.68053 11.1909 8.94164 10.9298C9.20276 10.6687 9.33331 10.3548 9.33331 9.98813C9.33331 9.62146 9.20276 9.30757 8.94164 9.04646C8.68053 8.78535 8.36664 8.65479 7.99998 8.65479C7.63331 8.65479 7.31942 8.78535 7.05831 9.04646C6.7972 9.30757 6.66665 9.62146 6.66665 9.98813C6.66665 10.3548 6.7972 10.6687 7.05831 10.9298C7.31942 11.1909 7.63331 11.3215 7.99998 11.3215ZM6.12353 5.18594H9.87643V3.96819C9.87643 3.44364 9.69461 2.99777 9.33096 2.63058C8.96731 2.26339 8.52364 2.07979 7.99998 2.07979C7.47631 2.07979 7.03265 2.26339 6.66899 2.63058C6.30535 2.99777 6.12353 3.44364 6.12353 3.96819V5.18594Z"
            fill="currentColor"
        />
    </g>
</svg>
