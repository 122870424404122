import { DeferredPromise } from '../DeferredPromise';
import { FetchUtils } from '../FetchUtils';
import { IsInWebworker} from '../EnvChecks';
import type { AnyJobExecutor} from './JobExecutor';
import { ExecutionThreadPreference } from './JobExecutor';
import type { WorkerMsg, WorkerMsgEvent, WorkerTaskResult } from './WorkerMsg';
import type { ClassIdentWrap} from './WorkerClassPassRegistry';
import { WorkerClassPassTransformer } from './WorkerClassPassRegistry';
import type { Result} from '../Result';
import { Failure, Success } from '../Result';
import { JobsResultsCache } from './JobsResultsCache';
import { ScopedLogger } from '../ScopedLogger';
import type { TypedArray } from 'math-ts';
import { KrMath } from 'math-ts';
import { requestExecutionFrame } from '../ExecutionFrame';
import { createWorkerInstance, type WorkerWrapper } from './WorkerWrapper';
import { Yield } from '../TasksRunner';

interface TaskToExecute<Args> {
    readonly executor: AnyJobExecutor,
    readonly args: Args,
    readonly executionPreference: ExecutionThreadPreference,
    readonly estimatedDurationMs: number,
    readonly resultPromise: DeferredPromise<any>,
}

interface TaskExecuting<Args> {
    executor: AnyJobExecutor,
    argsCache: string | null,
    resultPromise: DeferredPromise<any>,
}


export class WorkerPoolImpl {
    private _jobsCache: JobsResultsCache = new JobsResultsCache('worker-pool-cache', new ScopedLogger('worker-pool'), 250);

    private _isDisposed: boolean = false;

    private _workerScriptPath: string;
    private _maxNumberOfWorkers: number;
    private _workers: WorkerRefHandler[] = [];

    private _taskToExecutePerGuid = new Map<string, TaskToExecute<any>>();
    private _tasksExecutingPerGuid = new Map<string, TaskExecuting<any>>();

    private _loopCallback = () => this._loop();

    _waitingForFreeWorkersIterationsCount: number = 0;
    _frameCounter = 0;

    _init(workerScriptPath: string) {
        if (this._workerScriptPath && workerScriptPath != this._workerScriptPath) {
            console.error(`current worker script of worker pool is ${this._workerScriptPath}, attempt to to update to ${this._workerScriptPath}`);
            return;
        }
        if (this._workerScriptPath == workerScriptPath) {
            return;
        }
        this._workerScriptPath = workerScriptPath;
        this._spawnWorkersIfNot();
    }

    static start(workerScriptPath: string) {
        WorkerPoolImpl.instance()._init(workerScriptPath);
    }

    static instance(): WorkerPoolImpl {
        if (!(globalThis as any)['__wp']) {
            (globalThis as any)['__wp'] = new WorkerPoolImpl();
        }
        return (globalThis as any)['__wp'];
    }

    constructor() {
        if (IsInWebworker()) {
            this._workerScriptPath = '-1';
            this._maxNumberOfWorkers = 0;
            this._loopCallback = null as any;
        } else {
            this._workerScriptPath = '';
            this._maxNumberOfWorkers = KrMath.clamp(navigator.hardwareConcurrency / 2, 2, 6);
            requestExecutionFrame(this._loopCallback);
        }
    }

    dispose() {
        this._isDisposed = true;
        const workers = this._workers.slice();
        this._workers.length = 0;
        for (const w of workers) {
            w.dispose();
        }
        for (const t of this._taskToExecutePerGuid.values()) {
            t.resultPromise.reject('worker pool disposed');
        }
        this._taskToExecutePerGuid.clear();
        for (const pr of this._tasksExecutingPerGuid.values()) {
            pr.resultPromise.reject('worker pool disposed');
        }
        this._taskToExecutePerGuid.clear();
    }

    forceRestart() {
        for (const w of this._workers) {
            if (w._scheduledTasksPerGuid.size === 0) {
                w._restartWorker();
            } else {
                w._forceRestartAfterCurrentTask = true;
            }
        }
    }

    private _onWorkerCalculatedTaskResult(taskGuid: string, taskResult: Result<any>, argsCacheKey: string | null) {
        const taskDescr = this._tasksExecutingPerGuid.get(taskGuid);
        if (taskDescr) {
            this._tasksExecutingPerGuid.delete(taskGuid);
            if (taskResult instanceof Success) {
                taskDescr.resultPromise.resolve(taskResult.value);
            } else {
                taskDescr.resultPromise.reject(taskResult);
            }
        }
        // if (argsCacheKey) {
        //     this._jobsCache.addToCache(argsCacheKey, taskResult);
        // }
    }

    _clearCache() {
        this._jobsCache.dispose();
    }

    executeWith<TArgs, TResult>(executorInstance: AnyJobExecutor, args: TArgs, taskGuid: string): Promise<TResult> {
        {
            const alreadySchdeuledTaskWithTheSameGuid = this._taskToExecutePerGuid.get(taskGuid);
            if (alreadySchdeuledTaskWithTheSameGuid) {
                const pr = alreadySchdeuledTaskWithTheSameGuid.resultPromise;
                pr.reject('displaced by another task with the same guid');
                this._taskToExecutePerGuid.delete(taskGuid);
            }
        }
        {
            const alreadyExecutingTaskWithTheSameGuid = this._tasksExecutingPerGuid.get(taskGuid);
            if (alreadyExecutingTaskWithTheSameGuid) {
                alreadyExecutingTaskWithTheSameGuid.resultPromise.reject('displaced by another task with the same guid');
                this._tasksExecutingPerGuid.delete(taskGuid);
                if (alreadyExecutingTaskWithTheSameGuid.argsCache) {
                    this._jobsCache.clearCacheFor(alreadyExecutingTaskWithTheSameGuid.argsCache);
                }
            }
        }

        let estimatedDurationMs = 100;
        try {
            estimatedDurationMs = executorInstance.estimateTaskDurationMs(args);
            if (!(estimatedDurationMs > 0 && estimatedDurationMs < 1_000_000)) {
                console.warn('executor returned invalid estimated duration', estimatedDurationMs, args, executorInstance.constructor.name);
                estimatedDurationMs = 100;
            }
        } catch (e) {
            console.error(`error trying to estimate task duration`, e);
        }


        const dp = new DeferredPromise<TResult>();
        this._taskToExecutePerGuid.set(taskGuid, {
            args: args,
            executionPreference: executorInstance.executionPreference(args),
            executor: executorInstance,
            estimatedDurationMs,
            resultPromise: dp,
        });
        return dp.promise;
    }

    private _tryScheduleTasksForExecution(toExecuteInMainThread: [AnyJobExecutor, any, DeferredPromise<any>][]) {
        let scheduledTasksCount = 0;
        workersSchedulingLoop: for (const [taskGuid, { executor, executionPreference, args, estimatedDurationMs, resultPromise }] of this._taskToExecutePerGuid) {
            try {
                const worker = this._bestWorkerForTheJob(executor, estimatedDurationMs);
                if (executionPreference !== ExecutionThreadPreference.MainThread
                    && (worker === null)
                    && this._workers.length > 0
                ) {
                    if (this._waitingForFreeWorkersIterationsCount < 500) {
                        this._waitingForFreeWorkersIterationsCount += 1;
                    } else {
                        console.warn(
                            `waiting for free workers for execution more than 500 cycles in a row, may be a very long execution or a bug`,
                            this._tasksExecutingPerGuid.size
                        );
                    }
                    break;
                }
                this._waitingForFreeWorkersIterationsCount = 0;

                this._taskToExecutePerGuid.delete(taskGuid);
                scheduledTasksCount += 1;

                if (worker == null || executionPreference === ExecutionThreadPreference.MainThread) {
                    toExecuteInMainThread.push([executor, args, resultPromise]);

                } else {
                    const argsCacheEntryString = JobsResultsCache.getCacheKeyForArgs(args, executor);

                    if (argsCacheEntryString) {
                        const cachedPromise = this._jobsCache.tryGetFromCacheAsPromise(argsCacheEntryString);
                        if (cachedPromise) {
                            cachedPromise.then(
                                (res: any) => { resultPromise.resolve(res) },
                                (err: any) => { resultPromise.reject(err) },
                            )
                            continue workersSchedulingLoop;
                        } else {
                            this._jobsCache.addPromiseOfResult(argsCacheEntryString, resultPromise.promise);
                        }
                    }

                    worker.scheduleTaskInWorker(executor, args, taskGuid, argsCacheEntryString, estimatedDurationMs);
                    this._tasksExecutingPerGuid.set(taskGuid, {
                        executor,
                        argsCache: argsCacheEntryString,
                        resultPromise
                    });
                }

            } catch (e) {
                console.error(e);
                resultPromise.reject(e);
            }
        }
        return scheduledTasksCount;
    }

    private _handleIncomingMessagesFor(duration: number) {
        const timeEnd = performance.now() + duration;
        for (const worker of this._workers) {
            if (worker._workerMsgsToHandle.length > 0) {
                worker.handleIncomingMessages(timeEnd);
            }
        }
    }

    private _loop() {
        if (this._isDisposed) {
            return;
        }
        setTimeout(this._loopCallback, 5);
        
        this._frameCounter += 1;
        const startT = performance.now();
        
        const toExecuteInMainThread: [AnyJobExecutor, any, DeferredPromise<any>][] = [];

        let scheduledTasks = this._tryScheduleTasksForExecution(toExecuteInMainThread);
        if (scheduledTasks < 2 && this._taskToExecutePerGuid.size > 0) {
            this._handleIncomingMessagesFor(10);
            scheduledTasks += this._tryScheduleTasksForExecution(toExecuteInMainThread);
        }

        for (const worker of this._workers) {
            worker.sendScheduledTasks();
        }

        for (const [executor, args, dp] of toExecuteInMainThread) {
            try {
                const result = executor.execute(args);
                if (isGenerator(result)) {
                    const value = executeGenerator(result);
                    dp.resolve(value);
                } else {
                    dp.resolve(result);
                }
            } catch (e) {
                dp.reject(e);
            }
        }

        this._handleIncomingMessagesFor(20);

        const duration = performance.now() - startT;
        if (duration > 100) {
            console.log('long worker pool frame', duration);
        }
    }

    private _spawnWorkersIfNot() {
        if (!this._workerScriptPath) {
            return;
        }
        while (this._maxNumberOfWorkers < Infinity && this._workers.length < this._maxNumberOfWorkers) {
            const w = new WorkerRefHandler(
                this._workerScriptPath,
                this._workers.length,
                (taskGuid, taskResult, cacheKey) => this._onWorkerCalculatedTaskResult(taskGuid, taskResult, cacheKey),
            );
            this._workers.push(w);
        }
    }

    private _bestWorkerForTheJob(jobExecutor: AnyJobExecutor, durationEstimateMs: number): WorkerRefHandler | null {
        this._spawnWorkersIfNot();
        let leastTimeScheduledAlready = Infinity;
        let bestMatchWorker: WorkerRefHandler | null = null;

        for (const w of this._workers) {
            let scheduledTimeAlready = w.scheduledWorkEstimatedTimeMs();
            if (!(scheduledTimeAlready < WorkerSchedulingLimitMs)) {
                continue;
            }
            if (durationEstimateMs > WorkerSchedulingLimitMs && w._scheduledTasksPerGuid.size > 1) {
                continue;
            }
            if ( durationEstimateMs < WorkConsideredSmallLimit && w.lastScheduledExecutorTypeIdent() === jobExecutor.constructor.name) {
                // executers of the same type have higher probability of sharing some data in between tasks
                // make sure to group them together in the same worker
                // if the scheduled time estimation is small enough
                scheduledTimeAlready = 0;
            }
            if (scheduledTimeAlready === 0) {
                return w;
            }
            if (scheduledTimeAlready < leastTimeScheduledAlready) {
                leastTimeScheduledAlready = scheduledTimeAlready;
                bestMatchWorker = w;
            }
        }
        return bestMatchWorker;
    }
}


const WorkerSchedulingLimitMs = 120;
const WorkConsideredSmallLimit = 15;

interface ScheduledTaskState {
    executorIdent: string;
    taskGuid: string;
    argsCacheKey: string | null;
    estimatedDurationMs: number;
    msgToSendToWorker: WorkerMsg | null; // set to null after sent to worker
    transferables: (ArrayBuffer|TypedArray)[] | null;
}

class WorkerRefHandler {

    _worker!: WorkerWrapper | Promise<WorkerWrapper>;
    _id: number;
    _onTaskFinished: (taskGuid: string, taskResult: Result<any>, argsCacheKey: string | null) => void;

    _scheduledTasksPerGuid: Map<string, ScheduledTaskState> = new Map();

    _workerMsgsToHandle: WorkerMsgEvent<WorkerMsg | WorkerMsg[]>[] = [];

    _forceRestartAfterCurrentTask: boolean = false;
    
    // first load of workers can take a while
    // which can force main thread to start executing big chunkgs of work freezing main thread
    // allow to schudule work to workers immidiately after they are created
    // before their readiness is confirmed
    _isWorkerReady = true;

    _lastScheduledExecutorTypeIdent: string | undefined = undefined;
    // _scheduledWorkEstimatedTimeMs: number = 0;

    _argsTransformer = new WorkerClassPassTransformer();

    constructor(
        readonly workerPath: string,
        readonly id: number,
        onTaskFinished: (taskGuid: string, taskResult: Result<any>, argsCacheKey: string | null) => void,
    ) {
        this._id = id;
        this._onTaskFinished = onTaskFinished;
        this._startWorker();
    }

    dispose() {
        this._isWorkerReady = false;
        if(this._worker instanceof Promise) {
            this._worker.then((w) => {
                w.terminate();
            });
        } else {
            this._worker.terminate();
        }
        this._scheduledTasksPerGuid.clear();
        // rejecting of promises is done in workerPool
    }

    async _startWorker() {
        this._worker = createWorkerInstance(this.workerPath, { name: `${this.workerPath}_${this.id}` });
        if(this._worker instanceof Promise) {
            this._worker.then((w) => {
                this._worker = w;
                this._setCallbacks(w);
            }).catch((e) => {
                console.error(`error starting worker(${this.id})`, e);
            });
        } else {
            this._setCallbacks(this._worker);
        }
    }

    private _setCallbacks(worker: WorkerWrapper) {
        worker.onMessageError((errorMsg) => {
            if ('data' in errorMsg && errorMsg?.data?.msgGuid) {
                console.error(`pool worker(${this._id}) msg error: ${errorMsg.data}`);
                this._reject(errorMsg.data?.msgGuid, errorMsg.data?.msgContent);
            } else {
                console.error(`pool worker(${this._id}) msg error: ${errorMsg}`);
            }
        });
        worker.onError((errorMsg) => {
            console.error(`pool worker(${this._id}) msg error: ${errorMsg}`);
        });
        worker.onMessage((msgEvent: WorkerMsgEvent<WorkerMsg | WorkerMsg[]>) => {
            this._workerMsgsToHandle.push(msgEvent);
        });
    }

    handleIncomingMessages(timeEndMs: number) {
        while (this._workerMsgsToHandle.length > 0 && performance.now() <= timeEndMs) {
            const msg = this._workerMsgsToHandle.shift()!;
            const msgData = msg.data;
            if (Array.isArray(msgData)) {
                for (const msg of msgData) {
                    this._handleWorkerMsg(msg);
                }
            } else {
                this._handleWorkerMsg(msgData);
            }
        }
        this._argsTransformer.clearWrappersToClassesCache();
    }

    private _handleWorkerMsg(msg: WorkerMsg) {
        let result: any = undefined;
        let error: any = undefined;
        let durationMs: number = 0;
        switch (msg.ty) {
            case 'worker-started': {
                this._isWorkerReady = true;
                return;
            };
            case 'result': {
                ({ error, result, durationMs } = (msg.msgContent as WorkerTaskResult));
            }; break;
            default: error = `unexpected response type ${msg.ty}`
        }
        if (result === undefined) {
            this._reject(msg.msgGuid, error);
        } else {
            if (typeof result === 'object') {
                result = this._argsTransformer.transformWrappersToClasses(result);
            }
            this._resolve(msg.msgGuid, result, durationMs);
        }
    }

    _restartWorker() {
        if (this._scheduledTasksPerGuid.size !== 0) {
            console.error('attempt to restart worker while it is executing tasks', ...this._scheduledTasksPerGuid);
            return;
        }
        this._lastScheduledExecutorTypeIdent = undefined;
        this._forceRestartAfterCurrentTask = false;
        this.dispose();
        this._startWorker();
    }

    private _reject(msgGuid: string, error: any) {
        const t = this._scheduledTasksPerGuid.get(msgGuid);
        if (t) {
            this._scheduledTasksPerGuid.delete(msgGuid);
            const { taskGuid, argsCacheKey } = t;
            this._onTaskFinished(taskGuid, new Failure(error), argsCacheKey);
        } else {
            console.error('attempt to reject unexpected msg', msgGuid, error);
        }
        if (this._forceRestartAfterCurrentTask) {
            this._restartWorker();
        }
    }
    private _resolve(msgGuid: string, result: any, realDurationMs: number) {
        const t = this._scheduledTasksPerGuid.get(msgGuid);
        if (t) {
            // LegacyLogger.deferredWarn(t.executorIdent, [t.estimatedDurationMs, realDurationMs]);
            const { taskGuid, argsCacheKey } = t;
            this._scheduledTasksPerGuid.delete(msgGuid);
            this._onTaskFinished(taskGuid, new Success(result), argsCacheKey);
        }
        if (this._forceRestartAfterCurrentTask) {
            this._restartWorker();
        }
    }

    lastScheduledExecutorTypeIdent(): string|undefined {
        if (!this._isWorkerReady) {
            return undefined;
        }
        if (this._forceRestartAfterCurrentTask) {
            return undefined;
        }
        return this._lastScheduledExecutorTypeIdent;
    }

    scheduledWorkEstimatedTimeMs(): number {
        if (this._forceRestartAfterCurrentTask || this._worker instanceof Promise) {
            return Infinity;
        }
        let executingTasksDurEstimation = 0;
        for (const msgDescr of this._scheduledTasksPerGuid.values()) {
            executingTasksDurEstimation += msgDescr.estimatedDurationMs;
        }
        return executingTasksDurEstimation;
    }

    scheduleTaskInWorker<TArgs extends Object>(executorInstance: AnyJobExecutor, args: TArgs, taskGuid: string, argsCacheKey: string | null, estimatedDurationMs: number) {
        if (this._forceRestartAfterCurrentTask) {
            throw new Error('worker expects restart, no tasks should be added at this point');
        }
        
        let argsWithExplicitClasses: ClassIdentWrap<TArgs> | TArgs = args;
        if (typeof args === 'object' && args) {
            try {
                argsWithExplicitClasses = this._argsTransformer.transformClassesToWrappers<TArgs>(args);
            } catch (e) {
                console.error(`error trying to preserve classes of work arguments`, e);
            }
        }

        if (this._argsTransformer.arrayBuffersToSendCombinedLength() > 1024 * 1024 * 20) {
            this._forceRestartAfterCurrentTask = true;
            console.warn('worker should be restarted after task', this.id);
        }

        const executorIdent = executorInstance.constructor.name;

        const msg: WorkerMsg = {
            msgGuid: FetchUtils.generateGuid(),
            ty: 'task',
            msgContent: {
                taskGuid,
                executorIdent,
                args: argsWithExplicitClasses,
            }
        }
        const toTransfer = executorInstance.transferToWorker(args);
        this._scheduledTasksPerGuid.set(msg.msgGuid, { executorIdent, taskGuid, argsCacheKey, estimatedDurationMs: estimatedDurationMs, msgToSendToWorker: msg, transferables: toTransfer });
        this._lastScheduledExecutorTypeIdent = executorIdent;
    }

    sendScheduledTasks() {
        if (this._scheduledTasksPerGuid.size === 0) {
            return;
        }

        if(this._worker instanceof Promise) {
            return;
        }

        const toSend: WorkerMsg[] = [];
        const transfer: ArrayBufferLike[] = [];
        for (const msg of this._scheduledTasksPerGuid.values()) {
            if (msg.msgToSendToWorker) {
                toSend.push(msg.msgToSendToWorker);
                msg.msgToSendToWorker = null;
                if (msg.transferables) {
                    for (const t of msg.transferables) {
                        transfer.push(t);
                    }
                    msg.transferables = null;
                }
            }
        }
        if (toSend.length === 0) {
            return;
        }

        this._argsTransformer.clearClassesToWrappersCache();

        this._worker.postMessage(toSend, { transfer });

    }
}

export function isGenerator(obj: any): boolean {
    return obj instanceof Object && obj.next instanceof Function && obj.throw instanceof Function;
}

export function executeGenerator<TR = any>(gen: Generator<any, TR>): TR {
    let value: IteratorResult<any, TR> = gen.next();
    while (!value.done) {
        value = gen.next();
        if(value.value === Yield.NextFrame) {
            throw new Error('Yield.NextFrame is not supported in worker');
        }
    }
    return value.value;
}