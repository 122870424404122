// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { ProjectVersionAdditionalContext } from '../wire/project-version-additional-context.js';


export class ProjectVersionDescription {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ProjectVersionDescription {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsProjectVersionDescription(bb:flatbuffers.ByteBuffer, obj?:ProjectVersionDescription):ProjectVersionDescription {
  return (obj || new ProjectVersionDescription()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsProjectVersionDescription(bb:flatbuffers.ByteBuffer, obj?:ProjectVersionDescription):ProjectVersionDescription {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ProjectVersionDescription()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

formatVersion():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

id():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

date():bigint {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readUint64(this.bb_pos + offset) : BigInt('0');
}

textDescription():string|null
textDescription(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
textDescription(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

additionalContext(obj?:ProjectVersionAdditionalContext):ProjectVersionAdditionalContext|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new ProjectVersionAdditionalContext()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

createdBy():string|null
createdBy(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
createdBy(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startProjectVersionDescription(builder:flatbuffers.Builder) {
  builder.startObject(6);
}

static addFormatVersion(builder:flatbuffers.Builder, formatVersion:number) {
  builder.addFieldInt32(0, formatVersion, 0);
}

static addId(builder:flatbuffers.Builder, id:number) {
  builder.addFieldInt32(1, id, 0);
}

static addDate(builder:flatbuffers.Builder, date:bigint) {
  builder.addFieldInt64(2, date, BigInt('0'));
}

static addTextDescription(builder:flatbuffers.Builder, textDescriptionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, textDescriptionOffset, 0);
}

static addAdditionalContext(builder:flatbuffers.Builder, additionalContextOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, additionalContextOffset, 0);
}

static addCreatedBy(builder:flatbuffers.Builder, createdByOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, createdByOffset, 0);
}

static endProjectVersionDescription(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

}
