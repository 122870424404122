import { EnumUtils, LegacyLogger, ObjectUtils } from 'engine-utils-ts';
import type { SceneInstance, SceneInstances } from './scene/SceneInstances';
import { BasicPropertyDescription, PropertyViewBasicTypes } from './properties/BasicPropsView';
import { BimProperty } from './bimDescriptions/BimProperty';
import { PropertyBase, PropsGroupBase } from './properties/Props';



export class BimUtils {
    
    
    public static generatePropsDescriptionFromKnownProps(instances: SceneInstances, type: string) {
        const knownProps = instances.basicPropsView.asLazyVersioned().poll().filter(t => {
            return t.typeIdentsFoundIn.includes(type);
        });

        const propsDescriptionResult: KnownPropsDescription = {};
        perProp:
        for (const prop of knownProps) {
            const path = prop.path.flatMap((p): (string|number)[] => {
                if (typeof p === 'string') {
                    return p.split(' | ');
                }
                return [p];
            });
            let currentPropsGroup = propsDescriptionResult;
            for (let i = 0; i < path.length - 1; ++i) {
                const p = path[i];
                if (!currentPropsGroup[p]) {
                    currentPropsGroup[p] = {};
                }
                if (currentPropsGroup[p] instanceof BasicPropertyDescription) {
                    LegacyLogger.deferredError('props types collision, cant handle yet', p);
                    continue perProp;
                }
                currentPropsGroup = currentPropsGroup[p] as KnownPropsDescription;
            }
            currentPropsGroup[path[path.length - 1]] = EnumUtils.enumFlagsToString(PropertyViewBasicTypes, prop.basicTypes);
        }
        return propsDescriptionResult;
    }

    public static convertLegacyPropsIntoJsonLikeObjs(instance: SceneInstance) {
        
        const result: Record<string, any> = {};
        
        const propsMap = instance.properties._props;
        for (const [key, prop] of propsMap) {
            if (prop.isComputedBy || prop.readonly) {
                continue;
            }
            let objToWriteInto = result;
            for (let p of prop.path) {
                p = p.replaceAll('-', '_');
                if (!objToWriteInto[p]) {
                    objToWriteInto[p] = {};
                }
                objToWriteInto = objToWriteInto[p];
            }
            for (const key in prop) {
                const v = (prop as any)[key];
                if (v !== undefined && typeof v !== 'function') {
                    objToWriteInto[key] = v;
                }
            }
        }
        return result;
    }

    public static convertPropsIntoJsonLikeObjs(props: PropsGroupBase): Record<string, any>|undefined {
        const result: Record<string, any> = {};
        
        for (const [key, field] of Object.entries(props)) {
            if (Array.isArray(field)) {
                continue;
            }
            if (field instanceof PropertyBase) {
                if ((field as any).readonly || (field as any).isComputedBy || (field as any).isReadonly) {
                    continue;
                }
                const v = (field as any).value;
                const unit = (field as any).unit;
                if (ObjectUtils.isPrimitive(v)) {
                    result[key] = {value: v, unit};
                }
            } else if (field instanceof PropsGroupBase) {
                const asJson = this.convertPropsIntoJsonLikeObjs(field);
                if (asJson) {
                    result[key] = asJson;
                }
            }
        }
        return ObjectUtils.isObjectEmpty(result) ? undefined : result;
    }

    public static getByMergedPathAsBimProperty(instance: SceneInstance, mergedPath: string): BimProperty | undefined {
        const path = mergedPath.split(' | ');
        return BimUtils.getByPathAsBimProperty(instance, path);
    }
    public static getByPathAsBimProperty(instance: SceneInstance, path: string[]): BimProperty | undefined {
        let obj = instance;
        const oldProperty = instance.properties.get(BimProperty.MergedPath(path));
        if (oldProperty) {
            return oldProperty;
        }
        const newProperty = instance.props.getAtPath(path);
        if (!newProperty) {
            return undefined;
        }
        const value = (newProperty as any).value;
        if (typeof value === 'number' || typeof value === 'string' || typeof value === 'boolean') {
            const unit = (newProperty as any).unit;
            return BimProperty.NewShared({
                path,
                value,
                unit,
            });
        }
        return undefined;
    }
}

export interface KnownPropsDescription {
    [key: string|number]: string | KnownPropsDescription;
}

(globalThis as any).BimUtils = BimUtils;
