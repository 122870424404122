import { DC_CNSTS, NumberProperty } from "..";
import type { PropertiesGroupFormatters } from "../bimDescriptions/PropertiesGroupFormatter";
import type { AssetId, LvWireSpecCatalogItemProps } from "../catalog";
import { sceneInstanceHierarchyPropsRegistry } from "../catalog/SceneInstanceHierarchyPropsRegistry";
import type { AssetBasedCatalogItemCreators } from "../catalog/CatalogItemCollection";
import { commonWireSpecAssetToCatalogItem, registerWireSpecCommonKeyPropertiesGroupFormatter, WireSpecCommonHierarchyProps } from "./WireSpecCommon";

export const LvWireSpecTypeIdent = 'lv-wire-spec';

export function registerLvWireSpecKeyPropertiesGroupFormatter(group: PropertiesGroupFormatters) {
    registerWireSpecCommonKeyPropertiesGroupFormatter(LvWireSpecTypeIdent, group);
}

export function registerLvWireSpecAssetToCatalogItem(group: AssetBasedCatalogItemCreators) {
    group.register(
        LvWireSpecTypeIdent,
        (assetId: AssetId) => {
            const item = commonWireSpecAssetToCatalogItem(LvWireSpecTypeIdent, assetId)
                .as<LvWireSpecCatalogItemProps>();
            Object.keys(DC_CNSTS.ConductorType).forEach(type => {
                if (type === 'BusBars') {
                    return;
                }
                item.properties['price_per_length_of_' + type] = NumberProperty.new({
                    value: 0,
                    unit: 'usd',
                    step: 0.1,
                    range: [0, 100000],
                })
            })
            return item;
        }
    )
}

sceneInstanceHierarchyPropsRegistry.set(LvWireSpecTypeIdent, WireSpecCommonHierarchyProps);
