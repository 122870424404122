// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { IndicesT } from './indices.js';
import { Indices } from './indices.js';


export class TriGeometry implements flatbuffers.IUnpackableObject<TriGeometryT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):TriGeometry {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsTriGeometry(bb:flatbuffers.ByteBuffer, obj?:TriGeometry):TriGeometry {
  return (obj || new TriGeometry()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsTriGeometry(bb:flatbuffers.ByteBuffer, obj?:TriGeometry):TriGeometry {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new TriGeometry()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

positions(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readFloat32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
}

positionsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

positionsArray():Float32Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? new Float32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

normals(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readInt8(this.bb!.__vector(this.bb_pos + offset) + index) : 0;
}

normalsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

normalsArray():Int8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? new Int8Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

uvs(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readFloat32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
}

uvsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

uvsArray():Float32Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? new Float32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

indices(obj?:Indices):Indices|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new Indices()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

edgeIndices(obj?:Indices):Indices|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new Indices()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startTriGeometry(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addPositions(builder:flatbuffers.Builder, positionsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, positionsOffset, 0);
}

static createPositionsVector(builder:flatbuffers.Builder, data:number[]|Float32Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createPositionsVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createPositionsVector(builder:flatbuffers.Builder, data:number[]|Float32Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addFloat32(data[i]!);
  }
  return builder.endVector();
}

static startPositionsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addNormals(builder:flatbuffers.Builder, normalsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, normalsOffset, 0);
}

static createNormalsVector(builder:flatbuffers.Builder, data:number[]|Int8Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createNormalsVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createNormalsVector(builder:flatbuffers.Builder, data:number[]|Int8Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(1, data.length, 1);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt8(data[i]!);
  }
  return builder.endVector();
}

static startNormalsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(1, numElems, 1);
}

static addUvs(builder:flatbuffers.Builder, uvsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, uvsOffset, 0);
}

static createUvsVector(builder:flatbuffers.Builder, data:number[]|Float32Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createUvsVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createUvsVector(builder:flatbuffers.Builder, data:number[]|Float32Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addFloat32(data[i]!);
  }
  return builder.endVector();
}

static startUvsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addIndices(builder:flatbuffers.Builder, indicesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, indicesOffset, 0);
}

static addEdgeIndices(builder:flatbuffers.Builder, edgeIndicesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, edgeIndicesOffset, 0);
}

static endTriGeometry(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): TriGeometryT {
  return new TriGeometryT(
    this.bb!.createScalarList<number>(this.positions.bind(this), this.positionsLength()),
    this.bb!.createScalarList<number>(this.normals.bind(this), this.normalsLength()),
    this.bb!.createScalarList<number>(this.uvs.bind(this), this.uvsLength()),
    (this.indices() !== null ? this.indices()!.unpack() : null),
    (this.edgeIndices() !== null ? this.edgeIndices()!.unpack() : null)
  );
}


unpackTo(_o: TriGeometryT): void {
  _o.positions = this.bb!.createScalarList<number>(this.positions.bind(this), this.positionsLength());
  _o.normals = this.bb!.createScalarList<number>(this.normals.bind(this), this.normalsLength());
  _o.uvs = this.bb!.createScalarList<number>(this.uvs.bind(this), this.uvsLength());
  _o.indices = (this.indices() !== null ? this.indices()!.unpack() : null);
  _o.edgeIndices = (this.edgeIndices() !== null ? this.edgeIndices()!.unpack() : null);
}
}

export class TriGeometryT implements flatbuffers.IGeneratedObject {
constructor(
  public positions: (number)[] = [],
  public normals: (number)[] = [],
  public uvs: (number)[] = [],
  public indices: IndicesT|null = null,
  public edgeIndices: IndicesT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const positions = TriGeometry.createPositionsVector(builder, this.positions);
  const normals = TriGeometry.createNormalsVector(builder, this.normals);
  const uvs = TriGeometry.createUvsVector(builder, this.uvs);
  const indices = (this.indices !== null ? this.indices!.pack(builder) : 0);
  const edgeIndices = (this.edgeIndices !== null ? this.edgeIndices!.pack(builder) : 0);

  TriGeometry.startTriGeometry(builder);
  TriGeometry.addPositions(builder, positions);
  TriGeometry.addNormals(builder, normals);
  TriGeometry.addUvs(builder, uvs);
  TriGeometry.addIndices(builder, indices);
  TriGeometry.addEdgeIndices(builder, edgeIndices);

  return TriGeometry.endTriGeometry(builder);
}
}
