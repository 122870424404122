

export enum SceneObjDiff {
	None = 0,
	Hidden = 1 << 0,
	Selected = 1 << 1,
	Highlighted = 1 << 2,

	Name = 1 << 4,

	LocalTransform = 1 << 5,
	WorldPosition = 1 << 6,

	LegacyProps = 1 << 7,

	Representation = 1 << 8,
	RepresentationLowDetail = 1 << 9,
	RepresentationAnalytical = 1 << 10,

	SpatialParentRef = 1 << 11,
	SpatialChildrenList = 1 << 12,
	SpatialDescendants = 1 << 13,

	ElectricalParentRef = 1 << 14,
	ElectricalChildrenList = 1 << 15,

	GeometryReferenced = 1 << 16,

	ColorTint = 1 << 20,

	HierarchySortKey = 1 << 21,
	ChildrenSortOrder = 1 << 22,

	ConnectedTo = 1 << 23,

	NewProps = 1 << 24,

	All = 0xFFFFFFF
}
