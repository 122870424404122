<script lang="ts">
    import { Switch } from "../libui/checkbox";

    export let name: string;
    export let value: boolean;
    export let onChange: (v: boolean) => void;
    export let disabled: boolean = false;
    export let multiline: boolean = false;
</script>

<button
    class="unstyled-button"
    class:multiline
    class:disabled
    on:click={() => {
        if (!disabled) {
            onChange(!value);
        }
    }}
>
    <span class="icon-wrap">
        <Switch {value} readonly={disabled} />
    </span>
    <span
        class="mdc-typography--body1"
        class:text-main-dark={!disabled}
        class:text-main-light={disabled}
        class:name={!multiline}
    >
        {name}
    </span>
</button>

<style lang="scss">
    .icon-wrap {
        display: flex;
        align-items: center;
        min-width: fit-content;
    }
    .unstyled-button {
        display: flex;
        gap: 5px;
        align-items: center;
        text-align: justify;
        transition: opacity 0.5s;

        &.multiline {
            padding: 0 2px;
            align-items: flex-start;
        }
    }
    .name {
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
    }
</style>
