import type { TMY_Props } from 'bim-ts';
import type { ResultAsync} from 'engine-utils-ts';
import { EnumUtils, IterUtils, LazyDerived, ObservableObject, ScopedLogger, type LazyVersioned, Success } from 'engine-utils-ts';
import { PUI_GroupNode, PUI_PropertyNodeBool, PUI_PropertyNodeMultiSelector, PUI_PropertyNodeSelector } from 'ui-bindings';
import { DataVizType } from '../tmy-data-viz-utils/TmyDataVizGenerators';



export interface SiteMeteoVizParams {
    visualization_type: DataVizType;
    data_selector: string[];
    show_meteo_data_header: boolean;
}

export function createMeteoVizSettings() {
    const meteoVizParamsObs = new ObservableObject<SiteMeteoVizParams>({
        identifier: 'meteo-viz-params',
        initialState: {
            visualization_type: DataVizType.None,
            data_selector: [],
            show_meteo_data_header: false,
        }
    });
    meteoVizParamsObs.observeObject({
        settings: {doNotNotifyCurrentState: true},
        onPatch: ({currentValueRef}) => {
            try {
                localStorage.setItem('meteo-viz-settings', JSON.stringify(currentValueRef));
            } catch (e) {
            console.error(e);
            }
        }
    });
    try {
        const settings = localStorage.getItem('meteo-viz-settings');
        if (settings) {
            const asObj = JSON.parse(settings) as SiteMeteoVizParams;
            meteoVizParamsObs.applyPatch({
                patch: asObj
            })
        }
    } catch (e) {
        console.error(e);
    }
    return meteoVizParamsObs;
}

export function meteoVizSettingsUiGenerator (
    meteoVizParamsObs: ObservableObject<SiteMeteoVizParams>,
    tmyProps: LazyVersioned<ResultAsync<TMY_Props>>,
): LazyDerived<PUI_GroupNode> {
    const logger = new ScopedLogger('createPropsSiteMeteoVizParams');

    const meteoVizParamsUi = LazyDerived.new2(
        'propsMeteoVizParamsUi',
        [],
        [meteoVizParamsObs, tmyProps],
        ([meteoVizParams, tmy]) => {

            const root = new PUI_GroupNode({
                name: 'meteo-viz-params',
                sortChildren: false,
            });

            const columnsNames: string[] = [];

            if (tmy instanceof Success) {
                columnsNames.push(...tmy.value.dataColumns.map(c => c.header.toHeaderString()));
            }

            if (columnsNames.length === 0) {
                return root;
            }

            root.addMaybeChild(new PUI_PropertyNodeSelector({
                name: 'Analysis chart',
                options: EnumUtils.getAllEnumConstsNames(DataVizType),
                value: DataVizType[meteoVizParams.visualization_type],
                onChange: (newValue) => {
                    const asNumber = EnumUtils.enumNumberValueFromString(
                        DataVizType, newValue
                    );
                    if (asNumber instanceof Success) {
                        meteoVizParamsObs.applyPatch({
                            patch: {
                                visualization_type: asNumber.value,
                            },
                        });
                    } else {
                        logger.error('invalid column name selected: ' + newValue);
                    }
                },
            }));

            if (meteoVizParams.visualization_type == DataVizType.None || columnsNames.length === 0) {
                return root;
            }

            const filteredOutDataSelector: string[] = [];
            const filteredDataSelector: string[] = [];

            for (const cname of meteoVizParams.data_selector) {
                if (columnsNames.includes(cname)) {
                    filteredDataSelector.push(cname);
                }
            }
            root.addMaybeChild(new PUI_PropertyNodeMultiSelector({
                name: 'Meteo data',
                options: columnsNames.map((s) => {return {value: s}}),
                value: filteredDataSelector.map(v => {return {value: v}}),
                onChange: (newValue) => {
                    const newStrings = IterUtils.filterMap(newValue, t => {
                        if (columnsNames.includes(t.value as string)) {
                            return t.value as string;
                        };
                        return undefined;
                    });
                    meteoVizParamsObs.applyPatch({
                        patch: {
                            data_selector: newStrings.concat(filteredOutDataSelector),
                        },
                    });
                },
            }));

            if (tmy) {
                root.addMaybeChild(new PUI_PropertyNodeBool({
                    name: 'show meteo data header',
                    value: meteoVizParams.show_meteo_data_header,
                    onChange: (newValue) => {
                        meteoVizParamsObs.applyPatch({
                            patch: {
                                show_meteo_data_header: newValue,
                            },
                        });
                    },
                }));
            }
            return root;
        });

    return meteoVizParamsUi;
}
