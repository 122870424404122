
export enum ESSO_Diff {
	None = 0,
	// values are the same as in ESO_Diff
	Position = 1 << 1,

	RepresentationSoft = 1 << 2,
	RepresentationOverlaySoft = 1 << 3,

	RepresentationBreaking = 1 << 4,
	// IsEditable = 1 << 5,
	ColorTint = 1 << 6,

	All = ESSO_Diff.Position | ESSO_Diff.RepresentationSoft | ESSO_Diff.RepresentationOverlaySoft | ESSO_Diff.ColorTint
}
