import type { TransformerDescription } from "../types";
import { createCombinerBoxObject2DWithChilds } from "./combiner-box";
import { Anchor2D } from "vector-graphic";
import { createManyToOneConnection } from "src/sld/templates/connections";
import { createTransformer } from "../../templates/transformer";
import { positionChildrensShortend } from "../../templates/common";

export function createTransformerObject2DWithChilds(transformerDesc: TransformerDescription)
    : Anchor2D
{
    // create children
    const object = positionChildrensShortend(
        transformerDesc.combinerBoxes,
        (cb, index) => createCombinerBoxObject2DWithChilds(cb, { index: (index + 1).toString() })
    )

    // create base transformer
    const transformer = createTransformer(transformerDesc)

    // add children wiring
    const wiring = createManyToOneConnection({
        sources: object.points,
        sourceOffset: 100,
        targetOffset: 100,
    })
    object.addAndExpandAabb(wiring)

    // position transformer
    if (!object.aabb.isEmpty() && wiring.points.length) {
        transformer.position.set(
            wiring.points[0].x - transformer.aabb.min.x,
            wiring.points[0].y
        );
        transformer.updateMatrix()
    }
    object.addAndExpandAabb(transformer);


    // set out point
    const anchor = new Anchor2D()
    anchor.addAndExpandAabb(object);
    anchor.point.copy(transformer.point)
        .applyMatrix3(transformer.matrix);

    return anchor;
}

