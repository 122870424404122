// automatically generated by the FlatBuffers compiler, do not modify

import { ErrorMsg } from '../wire/error-msg.js';
import { ObjectState } from '../wire/object-state.js';
import { ObjectStateQuery } from '../wire/object-state-query.js';
import { ObjectUpdate } from '../wire/object-update.js';
import { UpdateAccepted } from '../wire/update-accepted.js';


export enum MsgType {
  NONE = 0,
  ErrorMsg = 1,
  ObjectStateQuery = 2,
  ObjectState = 3,
  ObjectUpdate = 4,
  UpdateAccepted = 5
}

export function unionToMsgType(
  type: MsgType,
  accessor: (obj:ErrorMsg|ObjectState|ObjectStateQuery|ObjectUpdate|UpdateAccepted) => ErrorMsg|ObjectState|ObjectStateQuery|ObjectUpdate|UpdateAccepted|null
): ErrorMsg|ObjectState|ObjectStateQuery|ObjectUpdate|UpdateAccepted|null {
  switch(MsgType[type]) {
    case 'NONE': return null; 
    case 'ErrorMsg': return accessor(new ErrorMsg())! as ErrorMsg;
    case 'ObjectStateQuery': return accessor(new ObjectStateQuery())! as ObjectStateQuery;
    case 'ObjectState': return accessor(new ObjectState())! as ObjectState;
    case 'ObjectUpdate': return accessor(new ObjectUpdate())! as ObjectUpdate;
    case 'UpdateAccepted': return accessor(new UpdateAccepted())! as UpdateAccepted;
    default: return null;
  }
}

export function unionListToMsgType(
  type: MsgType, 
  accessor: (index: number, obj:ErrorMsg|ObjectState|ObjectStateQuery|ObjectUpdate|UpdateAccepted) => ErrorMsg|ObjectState|ObjectStateQuery|ObjectUpdate|UpdateAccepted|null, 
  index: number
): ErrorMsg|ObjectState|ObjectStateQuery|ObjectUpdate|UpdateAccepted|null {
  switch(MsgType[type]) {
    case 'NONE': return null; 
    case 'ErrorMsg': return accessor(index, new ErrorMsg())! as ErrorMsg;
    case 'ObjectStateQuery': return accessor(index, new ObjectStateQuery())! as ObjectStateQuery;
    case 'ObjectState': return accessor(index, new ObjectState())! as ObjectState;
    case 'ObjectUpdate': return accessor(index, new ObjectUpdate())! as ObjectUpdate;
    case 'UpdateAccepted': return accessor(index, new UpdateAccepted())! as UpdateAccepted;
    default: return null;
  }
}
