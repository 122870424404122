import type { Matrix4 } from './Matrix4';
import { Quaternion } from './Quaternion';
import { Vector3 } from './Vector3';

export const TransformFloats = 16;

export class Transform {

	constructor(
		public readonly position = Vector3.zero(),
		public readonly rotation = Quaternion.identity(),
		public readonly scale = Vector3.allocate(1, 1, 1),
	) {
	}

	toString() {
		return `tr(${this.position.toString()}:${this.rotation.toString()}:${this.scale.toString()})`;
	}

	freeze() {
		Object.freeze(this.position);
		Object.freeze(this.rotation);
		Object.freeze(this.scale);
		return this;
	}

	static fromMatrix(matrix: Matrix4): Transform {
		const tr = new Transform();
		tr.setFromMatrix4(matrix);
		return tr;
	}

	withOffset(x:number, y:number, z:number): Transform {
		this.position.set(x, y, z);
		return this;
	}

	static offsetTransform(offset: Vector3): Transform {
		const tr = new Transform();
		tr.position.copy(offset);
		return tr;
	}
	
	addOffset(x: number, y: number, z: number): Transform {
		this.position.x += x;
		this.position.y += y;
		this.position.z += z;
		return this;
	}

	makeIdentity() {
		this.position.set(0, 0, 0);
		this.rotation.set(0, 0, 0, 1);
		return this;
	}

	transformVec(vec: Vector3) {
		vec.multiply(this.scale).applyQuaternion(this.rotation).add(this.position);
	}

	inverseTransformVec(vec: Vector3): Vector3 {
		vec.sub(this.position);
		vec.applyInverseOfQuaternion(this.rotation);
		vec.divide(this.scale);
		return vec;
	}

	equals(rhs: Transform): boolean {
		return this.position.equals(rhs.position)
			&& this.rotation.angleTo(rhs.rotation) < Math.PI / 1800 // 0.1 degree 
			&& this.scale.equals(rhs.scale);
	}

	copy(rhs: Transform) {
		this.position.copy(rhs.position);
		this.rotation.copy(rhs.rotation);
		this.scale.copy(rhs.scale);
	}

	clone() {
		const tr = new Transform();
		tr.copy(this);
		return tr;
	}

	static fromArray(arr: ArrayLike<number>, offset: number): Transform {
		return new Transform().withOffset(arr[offset + 0], arr[offset + 1], arr[offset + 2]);
	}

	toMatrix4(m: Matrix4): Matrix4 {
		return m.compose(this.position, this.rotation, this.scale);
	}

	setFromMatrix4(m: Matrix4) {
		m.decompose(this.position, this.rotation, this.scale);
	}

	

	// rotationScaleToMatrix4(out: Matrix4): Matrix4 {
	// 	const scale = this.scale.clone_t();
	// 	scale.setSigns();
	// 	out.compose(Vector3.zero_t(), this.rotation, scale);
	// 	return out;
	// }
}
export const EmptyTransform = Object.freeze(new Transform()); 
