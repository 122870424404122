import { CostModel as CM } from 'bim-ts';
import { ColDef } from "ag-grid-enterprise";
import { createModifiableCostCellDefinition } from './common';


export function createLoadedWageRateColumnDefinition(): ColDef<CM.FlattenedCostCategoryParams> {
    return {
        colId: CM.LoadedWageRateColId,
        headerName: CM.LoadedWageRateColId,
        cellEditor: 'agNumberCellEditor',
        ...createModifiableCostCellDefinition(x => x.labor?.loadedWageRate),
    }
}


