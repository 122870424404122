<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <rect
        x="2"
        y="2"
        width="1"
        height="12"
        rx="0.5"
        fill="#1A1E1F"
        fill-opacity="0.4"
    />
    <path
        d="M5 3C5 2.44772 5.44772 2 6 2H13C13.5523 2 14 2.44772 14 3V9C14 9.55228 13.5523 10 13 10H6C5.44772 10 5 9.55228 5 9V3Z"
        fill="#1A1E1F"
        fill-opacity="0.4"
    />
    <path
        d="M5 12C5 11.4477 5.44772 11 6 11H13C13.5523 11 14 11.4477 14 12V13C14 13.5523 13.5523 14 13 14H6C5.44772 14 5 13.5523 5 13V12Z"
        fill="currentColor"
    />
</svg>
