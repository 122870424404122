<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        d="M16.6607 17.6464C16.4654 17.8417 16.1489 17.8417 15.9536 17.6464L12.6429 14.3357L11.8205 16.8192C11.7437 17.0512 11.4139 17.0465 11.3437 16.8124L9 9L16.8124 11.3437C17.0465 11.4139 17.0512 11.7437 16.8192 11.8205L14.3357 12.6429L17.6464 15.9536C17.8417 16.1489 17.8417 16.4654 17.6464 16.6607L16.6607 17.6464Z"
        fill="currentColor"
    />
    <path
        d="M3 6V5C3 3.89543 3.89543 3 5 3H6"
        stroke="currentColor"
        stroke-width="1.6"
    />
    <path
        d="M17 7V5C17 3.89543 16.1046 3 15 3V3"
        stroke="currentColor"
        stroke-width="1.6"
    />
    <path
        d="M3 15V15C3 16.1046 3.89543 17 5 17H7"
        stroke="currentColor"
        stroke-width="1.6"
    />
    <path d="M3 8V13" stroke="currentColor" stroke-width="1.6" />
    <path d="M8 3H13" stroke="currentColor" stroke-width="1.6" />
</svg>
