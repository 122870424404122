import { CostModel as CM } from "bim-ts";
import { CellStyle, ColDef, HeaderValueGetterFunc } from "ag-grid-enterprise";
import { v4 as uuid } from "uuid";
import {
    commonFontColor,
    commonFontSize,
    commonFontWeight,
    getContext,
} from "./common";
import { formatQuantityToShortest } from "./LCOEInput";
import { ContextPlaceholder } from "../types";
import { defaultCellStyle } from "src/cost-model/capital/table/column-definitions/common";

export const createPerYearColumnDefinition = (args: {
    yearSequenceNumber: number;
    title?: string;
}): ColDef<CM.FlattenedCostCategoryParams> => {
    return {
        colId: uuid(),
        headerValueGetter: (params) => {
            const context = params.context as ContextPlaceholder;
            const yearSpan = context.lcoe?.input.lcoe.projectUsefulLifeYears;
            let headerTitle = "Year " + (args.yearSequenceNumber + 1);
            if (args.yearSequenceNumber === 0) {
                headerTitle = "First year";
            } else if (
                yearSpan !== undefined &&
                args.yearSequenceNumber === yearSpan
            ) {
                headerTitle = yearSpan + " Years net";
            }
            return headerTitle;
        },
        valueGetter: (params) => {
            const { category, unitsMapper } = getContext(params);

            const target = category?.costPerYear?.[args.yearSequenceNumber];
            if (!target || !target.value || !target.value.value) {
                return "";
            }

            return formatQuantityToShortest(target.value, unitsMapper);
        },
        type: ["perYear"],
        headerClass: ["ag-grid-header-align-right"],
        cellStyle: (params) => {
            const styles: CellStyle = {
                ...defaultCellStyle,
                ["justify-content"]: "flex-end",
            };
            const context = getContext(params);
            commonFontSize(styles, context);
            commonFontWeight(styles, context);
            commonFontColor(styles, context);
            return styles;
        },
    };
};
