import type { LazyVersioned} from "engine-utils-ts";
import type {
    Bim} from "src";
import {
    NumberProperty,
    PropsFieldFlags,
    PropsFieldOneOf,
    PropsGroupBase,
    PropsGroupsRegistry,
} from "src";
import { StateType } from "src/bimConfigs/ConfigsArchetypes";

export class LcoeDebtCostSettings extends PropsGroupBase {
    debtTermYears: NumberProperty | null;
    interestRateOnDebtPercent: NumberProperty | null;
    debtSizeAsPercentOfCapitalCost: NumberProperty | null;
    lenderFeePercentOfDebt: NumberProperty | null;

    constructor(args: Partial<LcoeDebtCostSettings>) {
        super();
        this.debtTermYears = args.debtTermYears ?? null;
        this.interestRateOnDebtPercent = args.interestRateOnDebtPercent ?? null;
        this.debtSizeAsPercentOfCapitalCost =
            args.debtSizeAsPercentOfCapitalCost ?? null;
        this.lenderFeePercentOfDebt = args.lenderFeePercentOfDebt ?? null;
    }
}

PropsGroupsRegistry.register({
    class: LcoeDebtCostSettings,
    complexDefaults: {
        debtTermYears: new PropsFieldOneOf(
            PropsFieldFlags.None,
            null,
            NumberProperty,
        ),
        interestRateOnDebtPercent: new PropsFieldOneOf(
            PropsFieldFlags.None,
            null,
            NumberProperty,
        ),
        debtSizeAsPercentOfCapitalCost: new PropsFieldOneOf(
            PropsFieldFlags.None,
            null,
            NumberProperty,
        ),
        lenderFeePercentOfDebt: new PropsFieldOneOf(
            PropsFieldFlags.None,
            null,
            NumberProperty,
        ),
    },
});

export class LcoePropertiesSettings extends PropsGroupBase {
    annualElectricityOutputWattHour: NumberProperty | null;
    annualProductionDegradationPercent: NumberProperty | null;
    projectUsefulLifeYears: NumberProperty | null;
    discountRatePercent: NumberProperty | null;
    operationGrowthRatePercent: NumberProperty | null;

    constructor(args: Partial<LcoePropertiesSettings>) {
        super();
        this.annualElectricityOutputWattHour =
            args.annualElectricityOutputWattHour ?? null;
        this.annualProductionDegradationPercent =
            args.annualProductionDegradationPercent ?? null;
        this.projectUsefulLifeYears = args.projectUsefulLifeYears ?? null;
        this.discountRatePercent = args.discountRatePercent ?? null;
        this.operationGrowthRatePercent =
            args.operationGrowthRatePercent ?? null;
    }
}

PropsGroupsRegistry.register({
    class: LcoePropertiesSettings,
    complexDefaults: {
        annualElectricityOutputWattHour: new PropsFieldOneOf(
            PropsFieldFlags.None,
            null,
            NumberProperty,
        ),
        annualProductionDegradationPercent: new PropsFieldOneOf(
            PropsFieldFlags.None,
            null,
            NumberProperty,
        ),
        projectUsefulLifeYears: new PropsFieldOneOf(
            PropsFieldFlags.None,
            null,
            NumberProperty,
        ),
        discountRatePercent: new PropsFieldOneOf(
            PropsFieldFlags.None,
            null,
            NumberProperty,
        ),
        operationGrowthRatePercent: new PropsFieldOneOf(
            PropsFieldFlags.None,
            null,
            NumberProperty,
        ),
    },
});

export class OperatingCostSettings extends PropsGroupBase {
    operationAndMaintenanceCostPerWattPerYear: NumberProperty | null;
    generalAndAdministrativePerWattPerYear: NumberProperty | null;
    insuranceCostAsPercentOfCapitalCostPerYear: NumberProperty | null;
    propertyTaxAsPercentOfCapitalCostPerYear: NumberProperty | null;
    landLeaseCostPerAcrePerYear: NumberProperty | null;

    constructor(args: Partial<OperatingCostSettings>) {
        super();
        this.operationAndMaintenanceCostPerWattPerYear =
            args.operationAndMaintenanceCostPerWattPerYear ?? null;
        this.generalAndAdministrativePerWattPerYear =
            args.generalAndAdministrativePerWattPerYear ?? null;
        this.insuranceCostAsPercentOfCapitalCostPerYear =
            args.insuranceCostAsPercentOfCapitalCostPerYear ?? null;
        this.propertyTaxAsPercentOfCapitalCostPerYear =
            args.propertyTaxAsPercentOfCapitalCostPerYear ?? null;
        this.landLeaseCostPerAcrePerYear =
            args.landLeaseCostPerAcrePerYear ?? null;
    }
}

PropsGroupsRegistry.register({
    class: OperatingCostSettings,
    complexDefaults: {
        operationAndMaintenanceCostPerWattPerYear: new PropsFieldOneOf(
            PropsFieldFlags.None,
            null,
            NumberProperty,
        ),
        generalAndAdministrativePerWattPerYear: new PropsFieldOneOf(
            PropsFieldFlags.None,
            null,
            NumberProperty,
        ),
        insuranceCostAsPercentOfCapitalCostPerYear: new PropsFieldOneOf(
            PropsFieldFlags.None,
            null,
            NumberProperty,
        ),
        propertyTaxAsPercentOfCapitalCostPerYear: new PropsFieldOneOf(
            PropsFieldFlags.None,
            null,
            NumberProperty,
        ),
        landLeaseCostPerAcrePerYear: new PropsFieldOneOf(
            PropsFieldFlags.None,
            null,
            NumberProperty,
        ),
    },
});

export class CapitalCost extends PropsGroupBase {
    totalCost: NumberProperty | null;

    constructor(args: Partial<CapitalCost>) {
        super();
        this.totalCost = args.totalCost ?? null;
    }
}

PropsGroupsRegistry.register({
    class: CapitalCost,
    complexDefaults: {
        totalCost: new PropsFieldOneOf(
            PropsFieldFlags.None,
            null,
            NumberProperty,
        ),
    },
});

export class LayoutSettings extends PropsGroupBase {
    areaAcres: NumberProperty | null;

    constructor(args: Partial<LayoutSettings>) {
        super();
        this.areaAcres = args.areaAcres ?? null;
    }
}

PropsGroupsRegistry.register({
    class: LayoutSettings,
    complexDefaults: {
        areaAcres: new PropsFieldOneOf(
            PropsFieldFlags.None,
            null,
            NumberProperty,
        ),
    },
});

export class LcoeSettings extends PropsGroupBase {
    debtCost: LcoeDebtCostSettings;
    lcoe: LcoePropertiesSettings;
    operatingCost: OperatingCostSettings;
    layout: LayoutSettings;

    constructor(args: Partial<LcoeSettings>) {
        super();
        this.debtCost = args.debtCost ?? new LcoeDebtCostSettings({});
        this.lcoe = args.lcoe ?? new LcoePropertiesSettings({});
        this.operatingCost =
            args.operatingCost ?? new OperatingCostSettings({});
        this.layout = args.layout ?? new LayoutSettings({});
    }
}

PropsGroupsRegistry.register({
    class: LcoeSettings,
    complexDefaults: {},
});

export function createLcoeSettingsDefault(): LcoeSettings {
    const lcoeSettings = new LcoeSettings({
        debtCost: new LcoeDebtCostSettings({
            debtTermYears: NumberProperty.new({ value: 15 }),
            interestRateOnDebtPercent: NumberProperty.new({ value: 5 / 100 }),
            debtSizeAsPercentOfCapitalCost: NumberProperty.new({
                value: 80 / 100,
            }),
            lenderFeePercentOfDebt: NumberProperty.new({ value: 2 / 100 }),
        }),
        lcoe: new LcoePropertiesSettings({
            annualProductionDegradationPercent: NumberProperty.new({
                value: 0.4 / 100,
            }),
            projectUsefulLifeYears: NumberProperty.new({ value: 30 }),
            discountRatePercent: NumberProperty.new({ value: 2 / 100 }),
            operationGrowthRatePercent: NumberProperty.new({ value: 4 / 100 }),
        }),
        operatingCost: new OperatingCostSettings({
            operationAndMaintenanceCostPerWattPerYear: NumberProperty.new({
                value: 0.009,
            }),
            generalAndAdministrativePerWattPerYear: NumberProperty.new({
                value: 0.002,
            }),
            insuranceCostAsPercentOfCapitalCostPerYear: NumberProperty.new({
                value: 0.5 / 100,
            }),
            propertyTaxAsPercentOfCapitalCostPerYear: NumberProperty.new({
                value: 0.85 / 100,
            }),
            landLeaseCostPerAcrePerYear: NumberProperty.new({ value: 750 }),
        }),
        layout: new LayoutSettings({
            areaAcres: NumberProperty.new({ value: 956 }),
        }),
    });
    return lcoeSettings;
}

export function registerLcoeSettings(bim: Bim) {
    bim.configs.archetypes.registerArchetype({
        type_identifier: "lcoe-settings",
        propsClass: LcoeSettings,
        properties: () => ({}),
        stateType: StateType.Singleton,
    });
}

export class LcoeSettingsProvider {
    private bim: Bim;

    public readonly lcoeSettings: LazyVersioned<LcoeSettings>;

    constructor(bim: Bim) {
        this.bim = bim;
        this.lcoeSettings = bim.configs.getLazySingletonProps({
            type_identifier: "lcoe-settings",
            propsClass: LcoeSettings,
        });
    }

    updateLcoeSettings(updater: CostsConfigUpdater) {
        const state = this.lcoeSettings.poll();
        //const copy = ObjectUtils.deepCloneObj(state);
        //updater(copy);
        this.bim.configs.patchSingletonProps(
            "lcoe-settings",
            LcoeSettings,
            updater,
        );
    }
}

export type CostsConfigUpdater = (prev: LcoeSettings) => void;
export type UpdateCostsConfig = (updater: CostsConfigUpdater) => void;
