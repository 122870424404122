import { LazyBasic, type ObservableStream, type Observer} from "engine-utils-ts";
import { ScopedLogger } from "engine-utils-ts";
import type { ContextMenuConfig } from "ui-bindings";

export class ContextMenuViewsListener {

    logger: ScopedLogger;

    contextMenuViewsToShow: LazyBasic<ContextMenuConfig[]>;
    configs: Map<string, ContextMenuConfig>;
    

    _streamSubscription: Observer | null = null;

    constructor() {
        this.logger = new ScopedLogger('ui-context-menu-listener');
        this.contextMenuViewsToShow = new LazyBasic<ContextMenuConfig[]>('context-view-to-show', []);
        this.configs = new Map();
    }

    updateSubscription(stream: ObservableStream<ContextMenuConfig>) {
        this._streamSubscription?.dispose();
        this._streamSubscription = stream.subscribe({
            onNext: (config) => this._handleIncomingConfig(config),
            settings: {immediateMode: true}
        })
    }

    private _handleIncomingConfig(config: ContextMenuConfig) {
        if (this.configs.has(config.identity)) {
            this.logger.debug('context menu is already open, skip', config);
            return;
        }
        this.logger.debug('handling config', config.identity);
        config.withDisposeFn(() => this.closeView(config.identity));
        this.configs.set(config.identity, config);
        this._update();
    }

    closeView(ident: string) {
        if(!this.configs.delete(ident)){
            this.logger.debug('context menu is already closed', ident);
        }else {
            this.logger.debug('config deleted', ident);
            this._update();
        }
    }

    private _update(){
        this.contextMenuViewsToShow.forceUpdate(Array.from(this.configs.values()))

    }

    dispose() {
        this._streamSubscription?.dispose();
        this.configs.clear();
        this.contextMenuViewsToShow.forceUpdate([]);
    }
}

