import type { EquipmentArea, MathSolverQueuePriorityType, MathSolversApi, ProjectMetrics, UnitsMapper } from "bim-ts";
import { AreaTypeEnum, MetricsGroup, URLS, deserializeProjectMetricsResponse, mergeMetricsToTable } from "bim-ts";
import type { LazyVersioned, ResultAsync, TasksRunner } from "engine-utils-ts";
import { DeferredPromise, IterUtils, LazyBasic, LazyDerived, LogLevel, PollablePromise, ProjectNetworkClient, ScopedLogger, Success } from "engine-utils-ts";
import type { UiBindings } from "ui-bindings";
import { NotificationDescription, NotificationType, PUI_Builder, PUI_Lazy } from "ui-bindings";
import type { VerDataSyncer } from "verdata-ts";
import { notificationSource } from "../Notifications";
import { MetricGroupNode, MetricPropertyNode } from "../project-panel/metrics-view/MetricProperty";
import { convertTableToUiNodes } from "../project-panel/ProjectOverviewPUI";
import { getVersionDescription } from "../project-panel/ProjectPanel";
import type { Project } from "../projects";
import type { ComparisonItemView } from "./ComparisonMode";
import { ComparisonItem, ComparisonMode, CurrentVersion } from "./ComparisonMode";

interface PrepareMetricsRequest {
    projectVersion: number,
}

interface BuildVersionDescription {
    version: string,
}

class TempFilesApi {
    private readonly _logger: ScopedLogger = new ScopedLogger("temp-files-api", LogLevel.Info);
    private readonly _networkClient: ProjectNetworkClient;

    constructor(
        baseNetwork: ProjectNetworkClient,
    ) {
        this._networkClient = new ProjectNetworkClient({
            ...baseNetwork.config,
            basePath: "api",
        });
    }

    async getFile(filePath: string): Promise<ArrayBuffer | undefined> {
        try {
            const query = this._createQueryParams({filePath});
            const response = await this._networkClient.get(query);
            if(response.status === 200){
                return await response.arrayBuffer();
            }
        } catch (error) {
            if(error.status !== 404){
                throw error;
            }
        }
 
        return;
    }

    async saveFile(filePath: string, data: ArrayBuffer): Promise<boolean> {
        const formData = new FormData();
        formData.append(
            "request",
            new Blob([data]),
        );
        const query = this._createQueryParams({filePath});
        const response = await this._networkClient.postFormData(query, formData);
        return response.status === 200;
    }

    async deleteFile(filePath: string): Promise<boolean> {
        const query = this._createQueryParams({filePath});
        const response = await this._networkClient.delete(query);
        return response.status === 200;
    }

    private _createQueryParams(params: Record<string, string | number | boolean>): string {
        const searchParams = new URLSearchParams();
        for (const [key, value] of Object.entries(params)) {
            searchParams.append(key, value.toString());
        }
        return `temp-files?${searchParams.toString()}`;
    }
}

class MetricsCalculator {
    private readonly _logger: ScopedLogger = new ScopedLogger("metrics-calculator", LogLevel.Info);
    private readonly _networkClient: ProjectNetworkClient;
    private _buildVersion: string | null = null;
    private _buildVersionPromise: Promise<string | null> | null = null;
    private readonly _metricsSolverName = "prepare_project_metrics";
    private readonly _cacheFilesApi: TempFilesApi;

    constructor(
        private readonly projectId: number,
        private readonly solverApi: MathSolversApi, 
        networkClient: ProjectNetworkClient,
    ) {
        this._networkClient = new ProjectNetworkClient({
            ...networkClient.config,
            basePath: "dist"
        });
        this._cacheFilesApi = new TempFilesApi(networkClient);
    }

    async calculate(request: PrepareMetricsRequest, priority?: MathSolverQueuePriorityType) {
        const computedMetrics = await this.fetchComputedMetrics({projectId: this.projectId, projectVersion: request.projectVersion});
        if(computedMetrics){
            const response = deserializeProjectMetricsResponse(
                new Uint8Array(computedMetrics), 
                this._logger
            );

            this._logger.debug("Cached metrics response", response);
            return response;
        }

        let metricsRaw: ArrayBuffer | undefined;
        const response = await this.solverApi.callSolver<PrepareMetricsRequest, MetricsGroup[]>({
            solverName: this._metricsSolverName,
            solverType: "multi",
            request: request,
            priority,
            deserialize: (body) => {
                const arr = new Uint8Array(body);
                metricsRaw = arr.buffer;
                this._logger.debug("Metrics response", body);
                this._logger.debug("Metrics response", arr);
                const response = deserializeProjectMetricsResponse(arr, this._logger);                
                this._logger.debug("Metrics response", response);
                return response;
            }
        });

        if(metricsRaw){
            const hash = await this.createHash({projectId: this.projectId, projectVersion: request.projectVersion});
            await this._cacheFilesApi.saveFile(hash, metricsRaw);
        }

        return response;
    }

    async fetchComputedMetrics(args:{ projectId: number, projectVersion: number}) {
        const hash = await this.createHash(args);
        const metrics = await this._cacheFilesApi.getFile(hash);
        return metrics;
    }

    async createHash(args:{ projectId: number, projectVersion: number}): Promise<string> {
        const buildVersion = await this._getBuildVersionCached();
        if(!buildVersion){
            throw new Error("Failed to get build version");
        }

        const dependencies = [buildVersion, args.projectId, this._metricsSolverName, args.projectVersion];
        this._logger.debug("Hash of dependencies", dependencies);
        return dependencies.join("/");
    }
    
    private async _getBuildVersionCached() {
        if(this._buildVersionPromise){
            const result = await this._buildVersionPromise;
            this._buildVersionPromise = null;
            return result;
        }
        const pr = this._getBuildVersion();
        this._buildVersionPromise = pr;
        return pr;
    }

    private async _getBuildVersion() { 
        if(this._buildVersion){
            return this._buildVersion;
        }
        try {
            const promise = this._networkClient.get("version.json");

            const response = await promise
            const json = await response.json() as BuildVersionDescription;
            if(!json.version){
                this._logger.error("Failed to get build version", json);
                return null;
            }
            this._buildVersion = json.version;
    
            return this._buildVersion;
        } catch (e) {
            if(URLS.isLocalOrigin()){
                return "local";
            }
            this._logger.error("Failed to get build version", e);
            throw e;
        }
    }
}

export class ProjectComparisonItems {
    private _logger: ScopedLogger;
    private _catalogSyncer: VerDataSyncer;
    private _projectSyncer: VerDataSyncer;
    private _taskRunner: TasksRunner;
    private _comparisonMode: ComparisonMode;
    private _metrics: ProjectMetrics;
    private _project: Project;
    private _metricsCalculator: MetricsCalculator;
    private _uiBindings: UiBindings;

    private _notificationTimeoutMs: number = 15*60*1000;
    private _calculatingPromise: DeferredPromise<any> | undefined = undefined;

    private _inProgressTasks = new Map<string, {projectVersion: number}>();
    private _backgroundTasks = new Map<string, Promise<void>>(); 

    private readonly catalogVersion = -1;// Metrics not affected by catalog

    readonly isRelativeMode = new LazyBasic<boolean>(`is-relative-mode`, false);
    readonly selectedRelativeItemIdx = new LazyBasic<number>(`selected-relative-item-idx`, 0);

    constructor(args: {
        networkClient: ProjectNetworkClient;
        catalogSyncer: VerDataSyncer;
        projectSyncer: VerDataSyncer;
        taskRunner: TasksRunner;
        solverApi: MathSolversApi;
        comparisonMode: ComparisonMode;
        metrics: ProjectMetrics;
        project: Project,
        uiBindings: UiBindings,
    }) {
        this._logger = new ScopedLogger("project-comparison-items", LogLevel.Info);
        this._catalogSyncer = args.catalogSyncer;
        this._projectSyncer = args.projectSyncer;
        this._taskRunner = args.taskRunner;
        this._comparisonMode = args.comparisonMode;
        this._metrics = args.metrics;
        this._project = args.project;
        this._metricsCalculator = new MetricsCalculator(this._project.id, args.solverApi, args.networkClient);

        this._uiBindings = args.uiBindings;

        this._projectSyncer.addListener('afterSaveNewVersion', async () => {
            if(URLS.isLocalOrigin()){
                return;
            }
            const projectVersion = this._projectSyncer.getCurrentVersionId();
            this._logger.debug("Project version saved", projectVersion);
            const taskId = ComparisonMode.createItemId(this._project.id, projectVersion, this.catalogVersion);
            const calcMetrics = async () => { 
                try {
                    await this._metricsCalculator.calculate({projectVersion}, "low");
                } catch (error) {
                    this._logger.error("Failed to calculate metrics", error);
                }
            };

            const pr = calcMetrics().finally(() => {
                this._backgroundTasks.delete(taskId);
            });

            this._backgroundTasks.set(taskId, pr);
            await pr;
        });
    }

    isAlreadyCalculating(projectVersion: number) {
        const taskId = ComparisonMode.createItemId(this._project.id, projectVersion, this.catalogVersion);
        return this._inProgressTasks.has(taskId);
    }

    async addComparisonItemFromVersion(projectVersion: number) {
        const taskId = ComparisonMode.createItemId(this._project.id, projectVersion, this.catalogVersion);
        if(this._comparisonMode.items.has(taskId)){
            this._uiBindings.addNotification(NotificationDescription.newBasic({
                type: NotificationType.Warning,
                source: notificationSource,
                key: "addToComparisionFailed",
                descriptionArg: `Item already added to comparison with project version ${projectVersion}`,
                addToNotificationsLog: false,
            }));
            
            return;
        }

        if(!this._calculatingPromise){
            this._calculatingPromise = new DeferredPromise(this._notificationTimeoutMs);
            this.addCalculationNotification(this._calculatingPromise.promise);
        }
        this._inProgressTasks.set(taskId, {projectVersion});
        await this._backgroundTasks.get(taskId);
        await this._addComparisonItemFromVersion(projectVersion)
            .finally(() => {
                this._inProgressTasks.delete(taskId);
                if (this._inProgressTasks.size === 0) {
                    this._calculatingPromise?.resolve(null);
                    this._calculatingPromise = undefined;
                }
        });
    }


    private async _addComparisonItemFromVersion(projectVersion: number, priority?: MathSolverQueuePriorityType) {
        try {
            const request: PrepareMetricsRequest = {
                projectVersion,
            }
            const response = await this._metricsCalculator.calculate(request, priority);
    
            const responseLazy = new LazyBasic<MetricsGroup[]>(
                `project-metrics-${request.projectVersion}`, 
                response
            );
    
            this._addComparisonItem(responseLazy, projectVersion, this.catalogVersion);
        } catch (e) {
            this._uiBindings.addNotification(NotificationDescription.newBasic({
                type: NotificationType.Error,
                source: notificationSource,
                key: "addToComparisionFailed",
                addToNotificationsLog: true,
            }));
            console.error(e);
        }
    }

    private addCalculationNotification<T>(promise: Promise<T>) {
        const task = this._taskRunner.newLongTask({
            defaultGenerator: function*() { 
                yield* PollablePromise.generatorWaitFor(promise);
            }(),
            taskTimeoutMs: 600_000,
        });
        this._uiBindings.addNotification(NotificationDescription.newWithTask({
            source: notificationSource,
            key: 'addToComparision',
            taskDescription: { task },
            type: NotificationType.Info,
            addToNotificationsLog: true
        }));
    }

    addComparisonItemFromCurrentState(){
        const projectVer = new CurrentVersion(new LazyBasic("current-ver-descr", "Current"));
        const catalogVer = new CurrentVersion(new LazyBasic("current-ver-descr", "Current"));
        const id = ComparisonMode.createItemId(this._project.id, projectVer.id, catalogVer.id);
        if(this._comparisonMode.items.has(id)){
            this._uiBindings.addNotification(NotificationDescription.newBasic({
                type: NotificationType.Warning,
                source: notificationSource,
                key: "addToComparisionFailed",
                descriptionArg: `Current metrics already added to comparison`,
                addToNotificationsLog: false,
            }));
            
            return;
        }
        const metrics = LazyDerived.new1<
            MetricsGroup[] | null,
            ResultAsync<MetricsGroup[]>
        >(
            "project-metrics", 
            null, 
            [this._metrics], 
            ([metricsGroupsResult], prev) => {
            let metricsGroups: MetricsGroup[] | null = prev ?? null;
            if(metricsGroupsResult instanceof Success){
                metricsGroups = metricsGroupsResult.value;
            }

            return metricsGroups;
        });
        const totalMetrics = LazyDerived.new1(
            "total-metrics",
            null,
            [metrics],
            ([metricsGroups]) => {
                return metricsGroups?.find(m => m.type === AreaTypeEnum.Total)?.metrics ?? null; 
            }
        );
        const item = new ComparisonItem(
            this._project,
            projectVer,
            catalogVer,
            totalMetrics
        );

        this._comparisonMode.add(item);
    }

    private _addComparisonItem(metrics: LazyVersioned<MetricsGroup[] | null>, projectVersion: number, catalogVersion: number){
        const totalMetrics = LazyDerived.new1(
            "total-metrics",
            null,
            [metrics],
            ([metricsGroups]) => {
                return metricsGroups?.find(m => m.type === AreaTypeEnum.Total)?.metrics ?? null; 
            }
        );

        const item = new ComparisonItem(
            this._project,
            getVersionDescription(this._projectSyncer, projectVersion),
            getVersionDescription(this._catalogSyncer, catalogVersion),
            totalMetrics
        );

        this._comparisonMode.add(item);
    }

    get comparisonVersions(): Set<number> {
        const set = new Set<number>();
        for (const item of this._comparisonMode.items.values()) {
            set.add(item.projectVersion.id);
        }
        for (const item of this._inProgressTasks.values()) {
            set.add(item.projectVersion);
        }
        return set;
    }

    removeComparisonItem(projectVersion: number) {
        const id = ComparisonMode.createItemId(this._project.id, projectVersion, this.catalogVersion);
        this._comparisonMode.remove(id);
    }
}

export function convertComparisonItemsToUiNodes(
    comparisonItems: ComparisonItemView[],
    unitsMapper: UnitsMapper,
    currentProjectVersion: number,
): MetricGroupNode {
    const logger = new ScopedLogger('convert-comparison-items-to-ui-nodes', LogLevel.Info);
    const rootNode = new MetricGroupNode({
        name: "Comparison items",
    });
    const versions = new MetricPropertyNode({
        name: "   ", 
        value: comparisonItems.map(c => c.projectVersion > 0 ? `V${c.projectVersion}` : `V${currentProjectVersion}` ), 
        isHeader: true,
    });
    rootNode.children.set("project version", versions);
    const header = new MetricPropertyNode({name: "   ", value: comparisonItems.map(c => c.header), isHeader: true});
    rootNode.children.set("header", header);
    const metricsGroups = comparisonItems.map(item => 
        new MetricsGroup({id: item.id, type: AreaTypeEnum.Total, metrics: item.metrics, areaIndex: undefined})
    );
    const filteredAreas = comparisonItems.map<EquipmentArea>(item => ({id: item.id, name: item.header, type: AreaTypeEnum.Total, equipment: []}));  

    const table = mergeMetricsToTable(metricsGroups, filteredAreas, unitsMapper, logger);
    convertTableToUiNodes(table.rows, rootNode);

    return rootNode;
}

const selectedVerdataVersions = new LazyBasic<number[]>("selected-versions", []);

export function createSelectVersionPui(
    comparisonItems: ProjectComparisonItems,
): PUI_Lazy {
    const currentStateId = -1;
    const lazyUi = LazyDerived.new2(
        "comparison-ui-lazy",
        null,
        [comparisonItems.isRelativeMode, selectedVerdataVersions],
        ([mode, selectedVersions]) => {
            const builder = new PUI_Builder({
                sortChildrenDefault: false,
                rootName: "Comparison Mode",
            });
            const addDivider = (name: string) => {
                builder.addCustomProp({
                    name,
                    context: {},
                    value: {},
                    onChange: () => {},
                    type_ident: "divider",
                });
            };

            builder.addSelectorProp({
                name: "Cost display",
                value: mode ? "Relative" : "Absolute",
                onChange: (v) => { comparisonItems.isRelativeMode.replaceWith(v === "Relative"); },
                options: ["Absolute", "Relative"],
            });

            addDivider("Selection of versions start");

            builder.addSceneInstancesSelectorProp({
                name: "Selected versions",
                value: selectedVersions.map(v => ({ value: v })),
                onChange: async (newSelectedVersions) => {
                    const currentSelected = comparisonItems.comparisonVersions;
                    const newSelected = new Set(newSelectedVersions.map(v => v.value));

                    const versionsDiff = IterUtils.setsDiff(currentSelected, newSelected);
                    const tasks: Promise<void>[] = [];
                    for (const projectVersion of versionsDiff.absentInL) {
                        if (!projectVersion) {
                            console.error("No version selected");
                            continue;
                        }
                        if(comparisonItems.isAlreadyCalculating(projectVersion)){
                            continue;
                        }
                        if (projectVersion === currentStateId) {
                            comparisonItems.addComparisonItemFromCurrentState();
                        } else {
                            const task = comparisonItems.addComparisonItemFromVersion(projectVersion);
                            tasks.push(task);
                        }
                    }
                    await Promise.all(tasks);

                    for (const projectVersion of versionsDiff.absentInR) {
                        if (!projectVersion) {
                            console.error("No version selected");
                            continue;
                        }
                        comparisonItems.removeComparisonItem(projectVersion);
                    }

                    selectedVerdataVersions.forceUpdate(Array.from(comparisonItems.comparisonVersions));
                },
                types: ['version'],
            });

            addDivider("Selection of versions end");

            return builder.finish();
        }
    ).withoutEqCheck();
    
    return new PUI_Lazy(lazyUi);
}
