import type { ScopedLogger} from 'engine-utils-ts';
import { Yield} from 'engine-utils-ts';
import { DefaultMap } from 'engine-utils-ts';
import type { Aabb, Vector2, Vector3 } from 'math-ts';
import type { RepresentationBase } from '../../representation/Representations';
import type { SceneInstance, IdBimScene } from '../../scene/SceneInstances';
import type { BoundaryWsPolygon } from '../BoundariesUtils';
import type { ElevationSample } from '../TerrainElevation';
import type { TerrainLocalUpdate } from '../TerrainSurfaceUpdates';
import type { Bim } from '../../Bim';


export type TerrainCutFillFunction = (
    logger: ScopedLogger,
    boundaryPointsWs: Vector2[],
) => Promise<Vector3[][]>


export interface PerSceneInstanceArgsBase {
    pointsToSampleTerrainAt: Vector2[],
}

export type PerInstanceArgsExtractor<Args extends PerSceneInstanceArgsBase>
    = (instance: SceneInstance, id: IdBimScene) => Args | null;

export interface PerSceneInstanceFullArgs<Args extends PerSceneInstanceArgsBase> {
    extracedArgs: Args;
    elevations: ElevationSample[];
}

export interface TerrainUpdateCalculationArgs<Args extends PerSceneInstanceArgsBase> {
    perInstanceTypeExtractors: Map<string, PerInstanceArgsExtractor<Args>>;
    calculateTerrainUpdate: (allSceneArgs: PerSceneInstanceFullArgs<Args>[]) => TerrainLocalUpdate[]; 
}

export class PointsToSampleAggregator {
    pointsToSample: Vector2[] = [];

    addPoints2dToSample(points: Vector2[]): number[] {
        const res = [];
        for (const point of points) {
            res.push(this.addPoint2dToSample(point));
        }
        return res;
    }
    addPointToSample(point: Vector3): number {
        let index = this.pointsToSample.length;
        this.pointsToSample.push(point.xy());
        return index;
    }
    addPoint2dToSample(point: Vector2): number {
        let index = this.pointsToSample.length;
        this.pointsToSample.push(point.clone());
        return index;
    } 
}

export function* findInstancesIntersectedWithPolygons(
    polygons: BoundaryWsPolygon[],
    bim: Bim,
    filter: (id: IdBimScene, instance: SceneInstance) => boolean,
): Generator<Yield, [IdBimScene, SceneInstance][], unknown> {
    const goemetriesAabbs = bim.allBimGeometries.aabbs.poll();
    const reprsBboxes = new DefaultMap<RepresentationBase, Aabb>(r => r.aabb(goemetriesAabbs));

    const relevantInstances: [IdBimScene, SceneInstance][] = [];
    let counter = 0;
    perInstance: for (const [id, instance] of bim.instances.perId) {
        const repr = instance.representation ?? instance.representationAnalytical;
        if (!repr) {
            continue;
        }
        if (!filter(id, instance)) {
            continue;
        }
        const localBbox = reprsBboxes.getOrCreate(repr);
        if (localBbox.isEmpty()) {
            continue;
        }

        for (const polygon of polygons) {
            if (polygon.intersectsWithBbox(localBbox, instance.worldMatrix)) {
                relevantInstances.push([id, instance]);
                continue perInstance;
            }
        }
        if(counter % 1000  === 0) {
            yield Yield.Asap;
        }
        counter++;
    }
    return relevantInstances;
}