import { IterUtils } from 'engine-utils-ts';
import { TrackerTypeIdent, expandTrackerLegacyPropsWithCostTableLinks } from '../../../trackers/Tracker';
import { SubstationTypeIdent, create_expandSubstationPropsWithCostTableLinks } from 'src/archetypes/substation/Substation';
import { CombinerBoxTypeIdent, FixedTiltTypeIdent, InverterTypeIdent, RoadTypeIdent, SectionalizingCabinetIdent, TerrainInstanceTypeIdent, TransformerIdent } from 'src';
import { create_expandTransformerPropsWithCostTableLinks } from 'src/archetypes/transformer/Transformer';
import { create_expandCombinerBoxPropsWithCostTableLinks } from 'src/archetypes/CombinerBox';
import { create_expandInverterPropsWithCostTableLinks } from 'src/archetypes/Inverter/Inverter';
import type { ExpandLegacyPropsWithCostTableLinks } from './types';
import { expandFixedTiltLegacyPropsWithCostTableLinks } from 'src/archetypes/fixed-tilt/FixedTilt';
import { create_expandSectionalizingCabinetPropsWithCostTableLinks } from 'src/archetypes/SectionalizingCabinet';
import { LvWireTypeIdent, expandLvLegacyPropsWithCostTableLinks } from 'src/archetypes/LvWire';
import { MvWireTypeIdent, expandMvLegacyPropsWithCostTableLinks } from 'src/archetypes/MvWire';
import { TrenchTypeIdent, expandTrenchLegacyPropsWithCostTableLinks } from 'src/archetypes/Trench';
import { expandTerrainLegacyPropsWithCostTableLinks } from 'src/terrain/Terrain';
import { expandRoadPropsWithCostTableLinks } from 'src/archetypes/Road';
import { expandAnyTrackerLegacyPropsWithCostTableLinks } from 'src/anyTracker/AnyTrackerPricingSolver';

export * from './types';
export * from './utils';

export function create_expandLegacyPropsWithCostTableLinks(): ExpandLegacyPropsWithCostTableLinks {
    const forSubstation = create_expandSubstationPropsWithCostTableLinks();
    const forTransformer = create_expandTransformerPropsWithCostTableLinks();
    const forCombinerBox = create_expandCombinerBoxPropsWithCostTableLinks();
    const forInverter = create_expandInverterPropsWithCostTableLinks();
    const forSc = create_expandSectionalizingCabinetPropsWithCostTableLinks();


    return (params) => {
        const type_identifiers = IterUtils.mapIter(params.bim.instances.peekByIds(params.ids).values(), x => x.type_identifier);
        if (!type_identifiers.length) {
            return;
        }
        const type_identifier = type_identifiers[0]
        const allIdentsTheSame = type_identifiers.every(x => x === type_identifier);
        if (!allIdentsTheSame) {
            return;
        }
        if (type_identifier === TrackerTypeIdent) {
            expandTrackerLegacyPropsWithCostTableLinks(params);
        } else if (type_identifier === FixedTiltTypeIdent) {
            expandFixedTiltLegacyPropsWithCostTableLinks(params);
        } else if (type_identifier === 'any-tracker') {
            expandAnyTrackerLegacyPropsWithCostTableLinks(params);
        } else if (type_identifier === SubstationTypeIdent) {
            forSubstation(params);
        } else if (type_identifier === TransformerIdent) {
            forTransformer(params);
        } else if (type_identifier === CombinerBoxTypeIdent) {
            forCombinerBox(params);
        } else if (type_identifier === InverterTypeIdent) {
            forInverter(params);
        } else if (type_identifier === SectionalizingCabinetIdent) {
            forSc(params);
        } else if (type_identifier === LvWireTypeIdent) {
            expandLvLegacyPropsWithCostTableLinks(params);
        } else if (type_identifier === MvWireTypeIdent) {
            expandMvLegacyPropsWithCostTableLinks(params);
        } else if (type_identifier === TrenchTypeIdent) {
            expandTrenchLegacyPropsWithCostTableLinks(params);
        } else if (type_identifier === TerrainInstanceTypeIdent) {
            expandTerrainLegacyPropsWithCostTableLinks(params);
        } else if (type_identifier === RoadTypeIdent) {
            expandRoadPropsWithCostTableLinks(params);
        }
    }

}
