<script lang="ts">
import { serializeCostReport, type CostReportTemplate, type ProjectOverride } from "../CostReportTemplate";
import ActionListEditor from "./ActionListEditor.svelte";
import ProjectOverrideEditor from './ProjectOverrideEditor.svelte';

export let template: CostReportTemplate;
export let close: () => void;

let selectedOverride: ProjectOverride | undefined;
let overrideEditorMode = false;
let actionsEditorMode = false;

function addOverride() {
    overrideEditorMode = true;
    selectedOverride = undefined;
}

function exitOverrideEditorMode(newOverride?: ProjectOverride) {
    overrideEditorMode = false;
    selectedOverride = undefined;
    if (newOverride) {
        template.projectSpecificOverriders.push(newOverride);
    }
    template = template;
}

function exitActionsEditorMode() {
    actionsEditorMode = false;
}

function removeOverride(toRemove: ProjectOverride) {
    template.projectSpecificOverriders =
        template.projectSpecificOverriders.filter(x => x !== toRemove);
}


function enterOverrideEditor(toEdit: ProjectOverride) {
     selectedOverride = toEdit;
     overrideEditorMode = true; 
     actionsEditorMode = false;
}

function enterActionsEditorMode() {
    actionsEditorMode = true;
    selectedOverride = undefined;
    overrideEditorMode = false;
}

function downloadTemplate() {
    onDownload();
}


function download(content: string, fileName: string, contentType: string) {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
}

function onDownload(){
    if (template) {
        download(serializeCostReport(template), "template.json", "text/plain");
    }
}



</script>

{#if actionsEditorMode}
    <ActionListEditor
        close={exitActionsEditorMode}
        bind:actions={template.actions}
    />
{:else if overrideEditorMode}
    <ProjectOverrideEditor
        exit={exitOverrideEditorMode}
        config={selectedOverride}
    />
{:else}
    <a class="basic-interact" on:click={close}>{'<'}</a>
    <button class="basic-interact" on:click={downloadTemplate}>download template</button>
    <h3>Report template(cost report tab name)</h3>
    <input class="basic-interact" bind:value={template.name} />
    <h3>is template visible in cost report</h3>
    <p>
        (
            this option should be checked to show current template as a tab in cost report.
            unchecked by default
        )
    </p>
    <input class="basic-interact" type="checkbox" bind:checked={template.visible} />
    <h3>actions(count {template.actions.length})</h3>
    <button on:click={enterActionsEditorMode}>
        edit actions
    </button>
    <h3>project overriders:</h3>
    <p>(each project might have separate action override items)</p>
    <ul>
        {#each template.projectSpecificOverriders as override}
            <li
                class="basic-interact"
                on:click={() => enterOverrideEditor(override)}
            >
                {override.projectId}&nbsp;&nbsp;
                <span
                    class="basic-interact"
                    on:click|stopPropagation={() => removeOverride(override)}
                >|del</span>
            </li>
        {/each}
    </ul>
    <span class="basic-interact" on:click={addOverride}>+add</span>
{/if}

<style lang="scss">
</style>
