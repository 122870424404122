<script lang="ts">
import type { ButtonVariants} from "./types";
import { Button, IconButton } from "./types";

import ButtonComponent from './Button.svelte';
import IconButtonComponent from './IconButton.svelte';

export let desc: ButtonVariants;
export let compact: boolean = false;
</script>

{#if desc instanceof Button}
  <ButtonComponent data={desc} {compact} />
{:else if desc instanceof IconButton}
  <IconButtonComponent data = {desc} />
{/if}

<style lang="scss">
</style>
