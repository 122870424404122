import type { Bim } from "../../Bim";
import { SceneInstancesProperty } from "../../properties/ObjectRefProps";
import { NumberProperty, BooleanProperty } from "../../properties/PrimitiveProps";
import type { PropertyGroup } from "../../properties/PropertyGroup";
import type { ConfigShapeMigration} from "../ConfigsArchetypes";
import { StateType } from "../ConfigsArchetypes";

export const TrackerPositionType = 'tracker-position-panel-config';

export interface TrackerPositionsConfig extends PropertyGroup {
    equipment:{
        trackers: SceneInstancesProperty;
    },
    parameters:{
        gapX: NumberProperty;
        gapY: NumberProperty;
        offsetX: NumberProperty;
        offsetY: NumberProperty;
    },
    settings:{
        colorize: BooleanProperty;
        exterior_arrays: BooleanProperty;
        edge_arrays: BooleanProperty;
        edge_top_bot_arrays: BooleanProperty;
    }
}

export function registerTrackerPositionsConfig(bim: Bim) {

	bim.configs.archetypes.registerArchetype({
        type_identifier: TrackerPositionType,
        properties: () => {
            const properties: TrackerPositionsConfig = {
                equipment:{
                    trackers: new SceneInstancesProperty({
                        value: [],
                        types: ["tracker", "fixed-tilt", "any-tracker"],
                        maxCount: 1e9,
                    }),
                },
                parameters:{
                    gapX: NumberProperty.new({
                        value: 30,
                        unit: "ft",
                        range: [0, Number.MAX_VALUE],
                    }),
                    gapY: NumberProperty.new({
                        value: 30,
                        unit: "ft",
                        range: [0, Number.MAX_VALUE],
                    }),
                    offsetX: NumberProperty.new({
                        value: 15,
                        unit: "ft",
                        range: [0, Number.MAX_VALUE],
                    }),
                    offsetY: NumberProperty.new({
                        value: 15,
                        unit: "ft",
                        range: [0, Number.MAX_VALUE],
                    }),
                },
                settings:{
                    colorize: BooleanProperty.new({value: true}),
                    exterior_arrays: BooleanProperty.new({value: true}),
                    edge_arrays: BooleanProperty.new({value: true}),
                    edge_top_bot_arrays: BooleanProperty.new({value: true}),
                }
            };
            
            return properties;
        },
        stateType: StateType.Singleton,
        shapeMigrations: migrations(),
	});
}

function migrations(): ConfigShapeMigration[] {
    return [
        {
            toVersion: 1,
            patch: (config) => {
                const props = config.get<TrackerPositionsConfig>();
                
                const gapX = NumberProperty.new({
                    //@ts-ignore
                    value: props.offsets.dx.as('ft'),
                    unit: "ft",
                    range: [0, Number.MAX_VALUE],
                });
                const gapY = NumberProperty.new({
                    //@ts-ignore
                    value: props.offsets.dy.as('ft'),
                    unit: "ft",
                    range: [0, Number.MAX_VALUE],
                });
                const offsetX = NumberProperty.new({
                    value: 15,
                    unit: "ft",
                    range: [0, Number.MAX_VALUE],
                });
                const offsetY = NumberProperty.new({
                    value: 15,
                    unit: "ft",
                    range: [0, Number.MAX_VALUE],
                });

                props.parameters = {
                    gapX, gapY,
                    offsetX, offsetY,
                };

                //@ts-ignore;
                delete props.offsets;
            },
            validation: {
                updatedProps: [
                    { path: ["parameters", "gapX"] }, 
                    { path: ["parameters", "gapY"] }, 
                    { path: ["parameters", "offsetX"] }, 
                    { path: ["parameters", "offsetY"] }
                ],
                deletedProps: [{ path: ["offsets", "dx"] }, { path: ["offsets", "dy"] }],
            },
        },
        {
            toVersion: 2,
            patch: (config) => {
                const props = config.get<TrackerPositionsConfig>();
                
                props.settings.exterior_arrays = BooleanProperty.new({value: true});
                props.settings.edge_arrays = BooleanProperty.new({value: true});
                props.settings.edge_top_bot_arrays = BooleanProperty.new({value: true});
            },
            validation: {
                updatedProps: [
                    { path: ["settings", "exterior_arrays"] }, 
                    { path: ["settings", "edge_arrays"] }, 
                    { path: ["settings", "edge_top_bot_arrays"] }, 
                ],
                deletedProps: [],
            },
        },
        {
            toVersion: 3,
            patch: (config) => {
                const props = config.get<TrackerPositionsConfig>();
                
                props.equipment.trackers = new SceneInstancesProperty({
                    ...props.equipment.trackers,
                    types: ["tracker", "fixed-tilt", "any-tracker"],
                })
            },
            validation: {
                updatedProps: [
                    { path: ["equipment", "trackers"] }, 
                ],
                deletedProps: [],
            },
        }
    ];
}
