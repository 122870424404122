<script lang="ts">
    import flash from "../pui/flash";
    import { Vector2Like } from "math-ts";
    import { TrackerWindPosition } from "bim-ts";
    import { SegmentedButtons } from "../libui/segmented-buttons";
    import { ButtonComponent, IconButton } from "src/libui/button";

    export let name: string;
    export let value: TrackerWindPosition;
    export let variants: TrackerWindPosition[];
    export let onChange: (value: TrackerWindPosition) => void;
    export let configure: (p: Vector2Like) => void;

    type WindLoadVariant = "EXT" | "INT" | "EDGE" | "TOP" | "BOT";
    const WindLoadVariants: TrackerWindPosition[] = [
        TrackerWindPosition.Exterior,
        TrackerWindPosition.Interior,
        TrackerWindPosition.Edge,
        TrackerWindPosition.EdgeTop,
        TrackerWindPosition.EdgeBot,
    ] as const;

    const WindPositionPerLabel: Record<TrackerWindPosition, WindLoadVariant> = {
        [TrackerWindPosition.Exterior]: "EXT",
        [TrackerWindPosition.Interior]: "INT",
        [TrackerWindPosition.Edge]: "EDGE",
        [TrackerWindPosition.EdgeTop]: "TOP",
        [TrackerWindPosition.EdgeBot]: "BOT",
    };

    let div: HTMLElement;

    let prevValue = value;
    $: if (prevValue != value && div) {
        prevValue = value;
        flash(div);
    }
    $: visibleVariants = WindLoadVariants.filter((variant) =>
        variants.includes(variant),
    );
</script>

<div class="ui-config-property property-row">
    <div bind:this={div} class="property-name text-main-medium">
        <div class="property-name-label mdc-typography--body1">
            {name}
        </div>
    </div>

    <div class="property-value">
        <div class="edit-field-container number-with-options-property">
            <div class="input-value">
                <SegmentedButtons
                    options={visibleVariants}
                    selected={value}
                    on:change={(e) => {
                        if (variants.includes(e.detail?.option)) {
                            onChange(e.detail.option);
                        }
                    }}
                >
                    <span let:option slot="button">
                        {WindPositionPerLabel[option]}
                    </span>
                    <span slot="additional-buttons" style:padding="4px">
                        <ButtonComponent
                            desc={new IconButton("Settings", (e) =>
                                configure({ x: e.clientX, y: e.clientY }),
                            )}
                        />
                    </span>
                </SegmentedButtons>
            </div>
        </div>
    </div>
</div>

<style lang="scss">
    .input-value {
        width: 100%;
        height: 100%;
    }
</style>
