import { ResolvedPromise } from '../';
import type {
    EventStackFrame} from './EventsStackFrame';
import { peekCurrentEventFrame, unsafePopFromEventStackFrame, unsafePushToEventStackFrame
} from './EventsStackFrame';
import type { ObservableStream, StreamObserver } from './ObservableStream';

export class LocalNotifier {
	_notificationsCounter: number = 0;

	private readonly _notificationsQueue: [any[] | any, EventStackFrame, ObservableStream<any>, number][];
	private _scheduledNotification: boolean = false;

	constructor() {
		this._notificationsQueue = [];
	}

	addToQueue(args: any, stream: ObservableStream<any>): number {
		this._notificationsCounter += 1;
		this._notificationsQueue.push([args, peekCurrentEventFrame(), stream, this._notificationsCounter]);
		if (!this._scheduledNotification) {
			this._scheduledNotification = true;
			ResolvedPromise.then(() => this.notifyQueued());
		}
		return this._notificationsCounter;
	}

	notifyQueued() {
		this._scheduledNotification = false;

		for (const [args, event, sender, notificationId] of this._notificationsQueue) {
			let pushedEvent = unsafePushToEventStackFrame(event);
			try {
				LocalNotifier._notifyObservers(sender.subscribers, args, event, notificationId);
			} catch (e) {
				console.error(`error during ${sender.identifier} event, subscription callback throwed exception: ${e}`, e);
			} finally {
				unsafePopFromEventStackFrame(pushedEvent);
			}
		}
		this._notificationsQueue.length = 0;
	}

	static _notifyObservers(observers: Map<string, StreamObserver<any>>, args: any, event: EventStackFrame, notificationId: number) {
		for (const s of observers.values()) {
			// skip notification if subscription occured after it was queued
			// otherwise notifyInitialState may cause going out of sync
			if (s._notificationsStartId >= notificationId || s.uniqueIdent == event.skipObserverWithIdent) {
				continue;
			}
			const now = performance.now();
			try {
				
				s.notifCallback(args);

				const callbackDuration = performance.now() - now;
				if (callbackDuration > 200) {
					console.warn(s.name + ' handler is slow, callback took ' + callbackDuration + 'ms');
				}
			} catch (e) {
				console.error(`error during ${s.name} observer callback throwed exception: ${e}`, e);
			}
		}
	}
}

