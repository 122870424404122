import { currenciesCustomSymbols } from "engine-utils-ts";

export class NumberSpinnerUtils {
    
    private static isCurrencyType(unit: string | undefined):boolean {
        return Boolean(unit && currenciesCustomSymbols[unit]);
    }
    
    static addUnit(val: number | null, isNotNumber: boolean, decimals: number, unit?: string) {
        // currency custom formatting
        if (this.isCurrencyType(unit)) {
            const symbol = currenciesCustomSymbols[unit!];
            let valAsStr;
            if (isNotNumber || val === null) {
                valAsStr = '-'
            } else {
                const beforePoint = val.toLocaleString('en-GB').split('.')[0];
                const afterPoint = val.toFixed(decimals).split('.')[1];
                valAsStr = ' ' + beforePoint + '.' + afterPoint;
            }
            return symbol + valAsStr;
        }
        const valAsStr = isNotNumber || val === null ? '-' : val.toFixed(decimals);
        return unit ? `${valAsStr} ${unit}` : valAsStr;
    }
    
    static removeUnit(str: string, unit?: string) {
        // currency custom formatting
        if (this.isCurrencyType(unit)) {
            const valStr = str.split(' ').at(-1);
            if (valStr) {
                return valStr.toLocaleString().replace(/,/g, '');
            }
        }
        if (!unit) {
            return str;
        }
        const num = parseFloat(str);
        if (isNaN(num)) {
            return str;
        }
        return "" + num;
    }

}