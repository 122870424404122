

export class DefaultMapWeak<K extends Object, V> {

	private readonly _map: WeakMap<K, V> = new Map();

	private readonly _factory: (key: K) => V;

	constructor(
		defaultFactory: (key: K) => V,
	) {
		this._factory = defaultFactory;
	}

	static newIdsCounter<T extends Object>(initialId: number = 1): DefaultMapWeak<T, number> {
		let currentId = initialId;
		return new DefaultMapWeak(v => currentId++)
	}

	getOrCreate(key: K): V {
		let val = this._map.get(key);
		if (val === undefined) {
			val = this._factory(key);
			this._map.set(key, val);
		}
		return val;
	}

	get(key: K): V | undefined {
		return this._map.get(key);
	}

	set(key: K, val: V) {
		this._map.set(key, val);
	}

	has(key: K): boolean {
		return this._map.has(key);
	}

	delete(key: K): boolean {
		return this._map.delete(key);
	}
}
