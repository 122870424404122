export enum ShaderFlags {
	None 							= 0,
	FLAT_NORMALS					= 1 << 0,
	UV_FROM_LOCAL_POSITION			= 1 << 1,
	UV_TRANSFORM					= 1 << 2,
	IS_SPRITE_GEO					= 1 << 3,
	IS_LINE_GEO						= 1 << 4,
	COLOR_CLIPPING 					= 1 << 5,
	PLANE_CLIPPING 					= 1 << 6,
	BOX_CLIPPING 					= 1 << 7,
	BOX_CLIP_INSIDE 				= 1 << 8,
	MRT_NORMALS						= 1 << 9,
	VISIBILITY_FROM_TEXTURE			= 1 << 10,
	IS_TRACKER_PILE_BILLBOARD_GEO	= 1 << 11,
	USE_DASHES_FOR_LINE_GEO			= 1 << 12,
	ALIGN_TEXT_GEO_WITH_CAMERA_UP	= 1 << 13,
};
