import type { Camera} from '../3rdParty/three';
import { OrthographicCamera, Scene } from '../3rdParty/three';
import type { GpuResources } from '../composer/GpuResources';
import type { GizmosController } from '../gizmos/GizmosController';
import type { BudgetLimitForPass, RenderPass, RenderResult } from './RenderPass';
import type { RenderTargets, RTIdent } from './RenderTargets';

export class GizmosPass implements RenderPass {

    enabled: boolean = true;
    readonly identifier: string;
    readonly inputs: RTIdent[];
    readonly outputs: RTIdent[];
    
    readonly gizmos: GizmosController;
    	
	readonly worldSCene: Scene = new Scene();
	
	readonly postCamera = new OrthographicCamera(- 1, 1, 1, - 1, 0, 1);

    constructor(
        inout: RTIdent | RTIdent[],
        gizmos: GizmosController,
    ) {
        this.identifier = 'gizmos';
        this.inputs = Array.isArray(inout) ? inout : [inout];
        this.outputs = this.inputs;

        this.gizmos = gizmos;
    }

    version(): number {
        return this.gizmos.version();
    }

    render(
        camera: Readonly<Camera>,
        gpuRes: GpuResources,
        renderTargets: RenderTargets,
        budget: BudgetLimitForPass
    ): RenderResult {
        this.worldSCene.children.length = 0;
		this.gizmos.getActiveGizmos(this.worldSCene);

        const renderer = gpuRes.renderer;
		const oldSort = renderer.sortObjects;
        // renderer.sortObjects = true;
        const rt = renderTargets.getRT(this.outputs[0]);
		if (this.worldSCene.children.length > 0) {
			renderer.setRenderTarget(rt.wrt);
            renderer.render(this.worldSCene, camera);
		}
		renderer.sortObjects = oldSort;

        return { finished: true, unitsRenderered: this.worldSCene.children.length };
    }
    
    
}
