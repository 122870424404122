import { Builder, ByteBuffer } from 'flatbuffers';
import type { ObjectsPickingSerializer } from 'verdata-ts';

import { BimImage, BimRawImage } from '../BimImages';
import { BimImage as BimImageF } from '../schema/bim-image';
import { BimImageCollection } from '../schema/bim-image-collection';
import { InlineImage as InlineImageF } from '../schema/inline-image';
import { readAssetRef, writeAssetRef } from './AssetRefSerializer';
import { FlatbufCommon } from './FlatbufCommon';

export enum BimImageVersions{
    None,
    ConvertToWebp,
}
export class BimImagesSerializer implements ObjectsPickingSerializer<BimImage> {
    private readonly migrationFn;

    constructor(migrationFn:(img:BimImage, version:BimImageVersions)=>BimImage){
        this.migrationFn = migrationFn;
    }

    serialize(objects: [number, BimImage][]): Uint8Array {
        const builder = new Builder(objects.length * 100);
        const root = BimImageCollection.createBimImageCollection(
            builder,
            BimImageVersions.ConvertToWebp,
            FlatbufCommon.writeInt32Vector(builder, objects.map(t => t[0])),
            BimImageCollection.createCollectionVector(
                builder, objects.map((t) => writeObj(builder, t[1])),
            ),
        );
        builder.finish(root);
        return builder.asUint8Array().slice();
    }
    
    deserialize(buffer: Uint8Array, idsToDeserialize: Set<number>): [number, BimImage][] {
        const rts = BimImageCollection.getRootAsBimImageCollection(new ByteBuffer(buffer));
        const version = rts.formatVersion();
        const res: [number, BimImage][] = [];
        for (let i = 0, il = rts.idsLength(); i < il; ++i) {
            const id = rts.ids(i)!;
            if (!idsToDeserialize.has(id)) {
                continue;
            }
            const t = rts.collection(i)!;
            const obj = readObj(t);
            const migratedObj = this.migrationFn(obj, version);
            res.push([id, migratedObj]);
        }
        return res;
    }
}
function writeObj(builder: Builder, g: BimImage): number {
    const assetOffset = g.assetRef ? writeAssetRef(builder, g.assetRef) : 0;
    const inlineOffset = g.inline ? InlineImageF.createInlineImage(
        builder,
        g.inline.width, g.inline.height, g.inline.format,
        InlineImageF.createRawDataVector(builder, g.inline.rawData)
    ) : 0;
    BimImageF.startBimImage(builder);
    BimImageF.addAssetRef(builder, assetOffset);
    BimImageF.addInline(builder, inlineOffset);
    return BimImageF.endBimImage(builder);
}
function readObj(pl: BimImageF): BimImage {
    const ao = pl.assetRef();
    const asset = ao ? readAssetRef(ao) : null;
    const ro = pl.inline();
    const inline = ro ? new BimRawImage(
        ro.width(),
        ro.height(),
        ro.format(),
        ro.rawDataArray()!.slice(),
    ) : null;
    return new BimImage(
        asset, inline
    );
}