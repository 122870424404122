<script lang="ts">
import { EnumUtils, Failure, ObjectUtils } from "engine-utils-ts";
import OptionalNumAndUnit from "../../components/OptionalNumAndUnit.svelte";
import PathEditor from "../../components/PathEditor.svelte";
import RemapperEditor from "../../components/RemapperEditor.svelte";
import { Action } from "../Action";
import { Reference, ReferenceType, ReferenceTypeIdentifier } from "./Reference";

export let exit: (action?: Action<Reference>) => void;
export let action: Action<Reference> | undefined

let editableAction = action
    ? ObjectUtils.deepCloneObj(action)
    : new Action('reference action', ReferenceTypeIdentifier, new Reference());

function save() {
    if (action) {
        Object.assign(action, editableAction);
        return exit()
    }
    return exit(editableAction);
}

function getRefTypeName(type: ReferenceType) {
  const result = EnumUtils.enumStringFromKey(ReferenceType, type)
  if (result instanceof Failure) {
    return 'unknown';
  }
  return result.value;
}
</script>

<a class="basic-interact" on:click={() => exit()}>{'<'}</a>

<h3>Reference editor</h3>

<p>disable</p>
<input bind:checked={editableAction.disable} type="checkbox" />

<p>name</p>
<input bind:value={editableAction.name} />

<p>path</p>
<PathEditor bind:path={editableAction.props.entry.path} />

<p>target path</p>
<PathEditor bind:path={editableAction.props.targetPath} />

<p>target type(where to take reference from)</p>
<select name="target type" bind:value={editableAction.props.targetType}>
    {#each EnumUtils.getAllEnumConstsValues(ReferenceType) as type}
        <option value={type}>{getRefTypeName(type)}</option>
    {/each}
</select>

<p>self type(where to put reference value in creating object: to qty or to rate)</p>
<select name="target type" bind:value={editableAction.props.selfType}>
    {#each EnumUtils.getAllEnumConstsValues(ReferenceType) as type}
        <option value={type}>{getRefTypeName(type)}</option>
    {/each}
</select>

<p>rate</p>
<OptionalNumAndUnit
    num={editableAction.props.entry.rate}
    change={(val) => { editableAction.props.entry.rate = val }}
/>

<p>quantity</p>
<OptionalNumAndUnit
    num={editableAction.props.entry.quantity}
    change={(val) => { editableAction.props.entry.quantity = val }}
/>

<RemapperEditor
    value={editableAction.props.remapper}
    change={val => editableAction.props.remapper = val}
/>


<br />
<br />
<a class="basic-interact" on:click={save}>Done</a>
