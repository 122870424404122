import { ObjectUtils } from "engine-utils-ts";
import { combineHashCodes } from "math-ts";
import { BimProperty } from "./BimProperty";

export type NamedBimPropertiesGroup = { [key: string]: BimProperty }

export type NamedBimPropertiesGroups = {
    [propsGroupName: string]: NamedBimPropertiesGroup 
}

export function flattenNamedPropsGroups<T extends NamedBimPropertiesGroups>(groups: T):
    [
        flattened: NamedBimPropertiesGroup,
        unflatten: (flattened: NamedBimPropertiesGroup) => T
    ]
{
	const flattened: NamedBimPropertiesGroup = {};
	for (const group of Object.values(groups)) {
		for (const prop of Object.values(group)) {
			flattened[BimProperty.MergedPath(prop.path)] = prop;
		}
	}
    function unflatten(flattened: NamedBimPropertiesGroup) {
        return unflattenNamedPropsGroups(groups, flattened);
    }
    return [flattened, unflatten];
}

function unflattenNamedPropsGroups<T extends NamedBimPropertiesGroups>(
	groupsDefinition: T,
	flattened: NamedBimPropertiesGroup
): T {
	const result: T = ObjectUtils.deepCloneObj(groupsDefinition);
	for (const group of Object.values(result)) {
		for (const [propName, prop] of Object.entries(group)) {
			const assosiatedProp = flattened[BimProperty.MergedPath(prop.path)];
			if (!assosiatedProp) {
				throw new Error('assosiated property was not found')
			}
			group[propName] = assosiatedProp;
		}
	}
	return result
}


export function extractValueUnitPropsGroup<T extends NamedBimPropertiesGroup>(propsGroup: T) {
    const result: NamedBimPropertiesGroup = {};
    for (const [name, prop] of Object.entries(propsGroup)) {
        result[name] = BimProperty.NewShared({
            path: prop.path,
            value: prop.value,
            unit: prop.unit,
        });
    }
    return result as T;
}

export function hashNamedBimProperties(
    props: NamedBimPropertiesGroup,
    includeNames = false
) {
    let hash: number = 0;
    for (const [name, prop] of Object.entries(props)) {
        if (includeNames) {
            hash = combineHashCodes(ObjectUtils.stringHash(name), hash);
        }
        hash = combineHashCodes(prop.valueUnitHash, hash);
    }
    return hash;
};
