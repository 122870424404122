<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path d="M7 15H5.50001V10.5H1V9L7 9.00002V15Z" fill="currentColor" />
    <path d="M9.00001 1H10.5V5.5H15V7L9.00001 6.99998V1Z" fill="currentColor" />
</svg>
