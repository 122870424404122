import { BooleanProperty, NumberProperty } from "bim-ts";
import { TableRowValueToPropertyType, type RowModel, type RowValue } from "./Models";
import { Success } from 'engine-utils-ts';

function obIx(obj: SortFieldType[], ix: number): string | boolean {
    if (ix < obj.length) {
        return obj[ix].field;
    }

    return false;
}

function keySort(a: RowValue, b: RowValue, d?: number): number {
    d = d !== undefined ? d : 1;
    const A = TableRowValueToPropertyType(a);
    const B = TableRowValueToPropertyType(b);
    let compareResult = NaN;
    if (A instanceof NumberProperty && B instanceof NumberProperty) {
        // number compare
        const result = NumberProperty.unitBasedCompare(A, B)
        if (result instanceof Success) {
            compareResult = result.value;
        }
    } else if (A instanceof BooleanProperty && B instanceof BooleanProperty) {
        // boolean compare
        compareResult= Math.sign((A.value ? 1 : 0) - (B.value ? 1 : 0));
    }

    if (isNaN(compareResult)) {
        // fallback: compare as string
        compareResult = A.value.toString().localeCompare(B.value.toString());
    }
    return Math.sign(compareResult * d);
}

function sortWight(sort: "desc" | "asc") {
    return sort === "desc" ? -1 : sort === "asc" ? 1 : 0;
}

function sortFn(a: RowModel, b: RowModel, keys: SortFieldType[]) {
    let sorted = 0;
    let ix = 0;
    while (sorted === 0 && ix < keys.length) {
        const k = obIx(keys, ix);
        if (k && typeof k === "string") {
            const dir = sortWight(keys[ix].sort);
            const l = a[k];
            const r = b[k];
            if (l !== undefined && r !== undefined) {
                sorted = keySort(a[k], b[k], dir);
            }
            ix++;
        }
    }
    return sorted;
}

export type SortFieldType = { field: string; sort: "desc" | "asc" };


export function orderRows(rows: RowModel[], keys: SortFieldType[]): RowModel[] {
    if (keys && keys.length > 0) {
        return rows.sort((a, b) => sortFn(a, b, keys));
    }

    return rows;
}
