<script lang="ts">
    import { getContext } from "svelte";
    import { NavbarContext } from "../../navbar/Navbar";
    import { activeTabLazy } from "layout-service";

    export let notConnectedDc: string;
    export let totalDc: string;

    const navbar = getContext<NavbarContext>("ui-navbar");

    function openTab(name: string) {
        activeTabLazy.replaceWith(name);
        navbar.openTab("Generate.Farm Layout");
    }
</script>

<div>
    Solar arrays with {notConnectedDc} of a total {totalDc} capacity are not connected
    to the inverter or transformer and can't be included into the energy report.
    Please run
    <span class="link text-accent" on:click={() => openTab("Generate")}>
        layout generation
    </span>
    to generate a new layout with the correct electrical hierarchy, or run
    <span class="link text-accent" on:click={() => openTab("Augment")}>
        layout augmentation
    </span>
    to add inverters for the existing solar arrays.
</div>

<style lang="scss">
    .link {
        font-weight: 600;
        white-space: nowrap;
    }
</style>
