import { ErrorUtils } from "engine-utils-ts";
import type { Vector2, Vector3 } from "math-ts";
import { Aabb2, KrMath } from "math-ts";

export type LinearCoord = number;
const _reusedCoorsArray: number[] = [];

export class InBoundsLinearIndexer {

    readonly minx: number;
    readonly miny: number;

    readonly cellSize: number;
    readonly xCount: number;
    readonly yCount: number;

    constructor(totalBounds: Aabb2, cellSize: number) {
        const boundsSize = totalBounds.getSize();

        this.minx = totalBounds.min.x;
        this.miny = totalBounds.min.y;

        this.cellSize = cellSize;
        this.xCount = Math.ceil(boundsSize.x / cellSize);
        this.yCount = Math.ceil(boundsSize.y / cellSize);
    }

    maxCoordsCount(): number {
        return this.xCount * this.yCount;
    }

    getLinearCoordsForPoint(x: number, y: number) {
        const localX = x - this.minx;
        const localY = y - this.miny;

        const xCoord = KrMath.clamp((localX / this.cellSize) | 0, 0, this.xCount - 1);
        const yCoord = KrMath.clamp((localY / this.cellSize) | 0, 0, this.yCount - 1);

        return xCoord * this.yCount + yCoord;
    }

    getLinearCoordsForBounds_t(bounds: Aabb2): Readonly<LinearCoord[]> {
        return this.getLinearCoordsForBoundsIndividual_t(bounds.min.x, bounds.min.y, bounds.max.x, bounds.max.y);
    }

    getLinearCoordsForBoundsIndividual_t(minx: number, miny: number, maxx: number, maxy: number): Readonly<LinearCoord[]> {
        const xMinCoord = KrMath.clamp(((minx - this.minx) / this.cellSize) | 0, 0, this.xCount - 1);
        const yMinCoord = KrMath.clamp(((miny - this.miny) / this.cellSize) | 0, 0, this.yCount - 1);

        const xMaxCoord = KrMath.clamp(((maxx - this.minx) / this.cellSize) | 0, 0, this.xCount - 1);
        const yMaxCoord = KrMath.clamp(((maxy - this.miny) / this.cellSize) | 0, 0, this.yCount - 1);

        const arr = _reusedCoorsArray;
        arr.length = 0;
        for (let xCoord = xMinCoord; xCoord <= xMaxCoord; xCoord += 1) {
            for (let yCoord = yMinCoord; yCoord <= yMaxCoord; yCoord += 1) {
                const coord = xCoord * this.yCount + yCoord;
                arr.push(coord);
            }
        }
        return arr;
    }

}

export class InBoundsSearch<V extends Vector2 | Vector3> {

    readonly aabb2: Aabb2;
    readonly indexer: InBoundsLinearIndexer;

    readonly cells: (V[] | undefined)[];

    constructor(
        readonly points: V[],
        cellSize?: number,
    ) {
        this.aabb2 = Aabb2.empty();
        for (const p of points) {
            this.aabb2.expandByPoint(p);
        }

        if (points.length === 0) {
            throw new Error('empty points array');
        }
        if (this.aabb2.isEmpty()) {
            throw new Error('empty points bounds');
        }

        cellSize = cellSize ?? this.aabb2.maxSide() / 1000;

        this.indexer = new InBoundsLinearIndexer(this.aabb2, cellSize);
        const cellsCount = this.indexer.maxCoordsCount();
        if (cellsCount < 0 || !Number.isInteger(cellsCount)) {
            ErrorUtils.logThrow('invalid cells count', cellsCount);
        }
        this.cells = new Array(this.indexer.maxCoordsCount());

        for (const p of points) {
            const cellIndex = this.indexer.getLinearCoordsForPoint(p.x, p.y);
            let cell = this.cells[cellIndex];
            if (cell === undefined) {
                cell = [];
                this.cells[cellIndex] = cell;
            }
            cell.push(p);
        }
    }

    getPointsInBounds(bounds: Aabb2): V[] {
        const res: V[] = [];
        for (const cellIndex of this.indexer.getLinearCoordsForBoundsIndividual_t(bounds.min.x, bounds.min.y, bounds.max.x, bounds.max.y)) {
            const cell = this.cells[cellIndex];
            if (cell !== undefined) {
                for (const p of cell) {
                    if (bounds.containsPoint(p)) {
                        res.push(p);
                    }
                }
            }
        }
        return res;
    }
}
