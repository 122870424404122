import { Quaternion, Transform, Vector3 } from 'math-ts';

import type { Bim} from '../..';
import { BimMaterialStdRenderParams, StdMeshRepresentation, StdSubmeshRepresentation } from '../..';
import { BimProperty } from '../../bimDescriptions/BimProperty';
import type { ReactiveSolverBase, SolverInstancePatchResult } from '../ReactiveSolverBase';
import { SolverObjectInstance } from '../SolverObjectInstance';

const DefaultInverterMeshGenInput = {
    legacyProps: {
        module_width: BimProperty.NewShared({path: ['dimensions', 'width'], value: 0.5, unit: 'm'}),
        module_length: BimProperty.NewShared({path: ['dimensions', 'length'], value: 0.5, unit: 'm'}),
        module_height: BimProperty.NewShared({path: ['dimensions', 'height'], value: 0.5, unit: 'm'}),
        modules_count: BimProperty.NewShared({path: ['inverter', 'modules_count'], value: 1}),
    }
}

export function solarInverterMeshGenerator(bim: Bim): ReactiveSolverBase {
    return new SolverObjectInstance({
        solverIdentifier: 'inverter-mesh-gen',
        objectsDefaultArgs: DefaultInverterMeshGenInput,
        objectsIdentifier: 'inverter',
        cache: true,
        solverFunction: (inputObj): SolverInstancePatchResult => {
            const module_size_x = inputObj.legacyProps.module_width.as('m');
            const module_size_y = inputObj.legacyProps.module_length.as('m');
            const module_size_z = inputObj.legacyProps.module_height.as('m');
            const modules_count = inputObj.legacyProps.modules_count.asNumber();

            const unitCubeGeoId = bim.cubeGeometries.shared!.get({
                size: new Vector3(1, 1, 1), // use unit cube geo, and scale it's transform according to props later, for better goemetry reuse
                center: new Vector3(0, 0, 0.5)
            })!;
            const moduleMatId = bim.bimMaterials.shared!.get({
                name: '---',
                stdRenderParams: new BimMaterialStdRenderParams('#EEEEEE', 0, 0.85, 0.4 )
            })!;


            const submeshes: StdSubmeshRepresentation[] = [];

            const betweenModulesGap = 0.1;
            const moduleSizeWithGap = module_size_x + betweenModulesGap;

            // add modules geometries
            for (let i = 0; i < modules_count; ++i) {
                const position = new Vector3(moduleSizeWithGap * i, 0, 0);
                submeshes.push(new StdSubmeshRepresentation(
                    unitCubeGeoId,
                    moduleMatId,
                    new Transform(position, new Quaternion(), new Vector3(module_size_x, module_size_y, module_size_z))
                ))
            }

            //add common bottom
            const bottomMatId = bim.bimMaterials.shared!.get({
                name: '---',
                stdRenderParams: new BimMaterialStdRenderParams('#444444', 0, 0.85, 0.9 )
            })!;
            const bottomWidth = 0.2;
            submeshes.push(new StdSubmeshRepresentation(
                unitCubeGeoId,
                bottomMatId,
                new Transform(
                    new Vector3(moduleSizeWithGap*0.5*(modules_count-1), 0, -bottomWidth),
                    new Quaternion(),
                    new Vector3(moduleSizeWithGap * modules_count + betweenModulesGap, module_size_y + betweenModulesGap, bottomWidth)
                )
            ))

            return {
                repr: new StdMeshRepresentation(
                    submeshes,
                    null,
                    true,
                )
            }
        }
    })
}
