<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        d="M10 11.6667C9.54167 11.6667 9.14931 11.5035 8.82292 11.1771C8.49653 10.8507 8.33333 10.4583 8.33333 10C8.33333 9.54167 8.49653 9.14931 8.82292 8.82292C9.14931 8.49653 9.54167 8.33333 10 8.33333C10.4583 8.33333 10.8507 8.49653 11.1771 8.82292C11.5035 9.14931 11.6667 9.54167 11.6667 10C11.6667 10.4583 11.5035 10.8507 11.1771 11.1771C10.8507 11.5035 10.4583 11.6667 10 11.6667ZM9.16667 6.66667V2.5H10.8333V6.66667H9.16667ZM9.16667 17.5V13.3333H10.8333V17.5H9.16667ZM13.3333 10.8333V9.16667H17.5V10.8333H13.3333ZM2.5 10.8333V9.16667H6.66667V10.8333H2.5Z"
        fill="currentColor"
    />
</svg>
