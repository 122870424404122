import { FetchUtils } from 'engine-utils-ts';

export class VerdataUrls {

    static formatDate(d: Date): string {
        const date = (d.getDate()).toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const hours = (d.getHours()).toString().padStart(2, '0');
        const minutes = (d.getMinutes()).toString().padStart(2, '0');
        // const seconds = (d.getSeconds()).toString().padStart(2, '0');
        return `${d.getFullYear()}-${month}-${date}-${hours}--${minutes}`;
    }

    static batchFileName(ident: string, date: Date, guid: string): string {
        return `batch-${ident}-${guid}.verdata`;
    }

    static batchUrl(batchFileName: string) {
        return FetchUtils.combineURLs('batch', batchFileName);
    }

    static projectImageUrl(versionId: number) {
        return FetchUtils.combineURLs('proj-ver-thumb', versionId.toString() + '.png');
    }
        
    static projectHistorySyncIdentifier() {
        return 'project-history.verdata';
    }

    static verDataStateIdentifier() {
        return 'verdata-state.verdata';
    }

    static collectionHistorySyncIdentifier(identifier: string) {
        return `collection-history-${identifier}.verdata`;
    }

    static uploadFiles() {
        return `upload-files`
    }

    static importFile(path: string) {
        return `import-file/${path}`;
    }

    static debugZip() {
        return `zip`;
    }
}

