import type { ScopedLogger } from "engine-utils-ts";
import { DefaultMapObjectKey } from "engine-utils-ts";
import { Vector4 } from "math-ts";
import { DoubleSide } from "../3rdParty/three";
import { EngineMaterialId } from "../pools/EngineMaterialId";
import { ShaderFlags } from "../shaders/ShaderFlags";
import type { GlobalUniforms } from "./GlobalUniforms";
import { KrMaterial } from './KrMaterial';
import type { MaterialDescr } from "./MaterialDescr";




function materialDescrUniqueHash(descr: MaterialDescr): string  {

	const base = descr.material;
	const options = descr.options;

	let uniqueString: string = base.name;

	if (options !== undefined) {
		// could sort keys first, and then add to string
		// but its probably doesnt matter
		// materials descriptions with the same sets of options are not expected
		// to have keys in different order from one another
		
		if (options.defines) {
			for (const defineKey in options.defines) {
				const defineValue = options.defines[defineKey];
				if (base.defines == undefined || base.defines[defineKey] != defineValue) {
					uniqueString += `[${defineKey}=${defineValue}]`;
				}
			}
		}
		if (options.params) {
			for (const uniformName in options.uniforms) {
				const value = options.uniforms[uniformName];
				if (descr.material.uniforms[uniformName]?.value != value) {
					uniqueString += `[${uniformName}=${value}]`;
				}
			}
		}
		if (options.flags) {
			const diff = (base.flags || 0) ^ options.flags;
			if (diff) {
				uniqueString += `h-${diff | 0}`;
			}
		}
		if (options.texturesPaths) {
			for (const key in options.texturesPaths) {
				uniqueString += `[${key}=${options.texturesPaths[key]}]`;
			}
		}
		if (options.envMap) {
			uniqueString += '[envmap]';
		}
	}
	return uniqueString;
}

export type EMatId = number & MaterialsRegistry;

export class MaterialsRegistry {

	_idsCounter: number = EngineMaterialId.AutoGeneratedIdsStart;

	readonly materialReducer: DefaultMapObjectKey<MaterialDescr, KrMaterial>;
	readonly materialsPerId: Map<EMatId, KrMaterial> = new Map(); // populated by lru cache
	readonly globalUniforms: GlobalUniforms;

	constructor(logger: ScopedLogger, globalUniforms: GlobalUniforms) {
		this.globalUniforms = globalUniforms;

		this.materialReducer = new DefaultMapObjectKey({
			unique_hash:  materialDescrUniqueHash,
			valuesFactory: (descr) => {
				let id = (this._idsCounter += 1);
				const mat = KrMaterial.newFromDescription(descr, id);
				this._addFeatures(mat);
				this.materialsPerId.set(id as EMatId, mat);
				return mat;
			},
		});
	}


	getMaterialIdFor(materialDescr: MaterialDescr, flags: ShaderFlags) {
		const m = this.materialReducer.getOrCreate(materialDescr);
		

	}

	_addFeatures(mat: KrMaterial) {
		const defines = mat.defines;
		const uniforms = mat.uniforms;
		const shaderFlags = mat.shaderFlags;
		for (let i = 0; i < 15; ++i) {
			const flag = 1 << i;
			if (shaderFlags & flag) {
				const flagString = ShaderFlags[flag];
				if (flagString) {
					defines[flagString] = true;
				}
			}
		}
		if (shaderFlags & ShaderFlags.COLOR_CLIPPING) {
			uniforms.colorClippingPlane = { value: new Vector4(0, 0, 0, 0) }
			uniforms.colorBelow = { value: new Vector4(0, 0, 0, 0) }
			uniforms.colorAbove = { value: new Vector4(0, 0, 0, 0) }
		}
		if (shaderFlags & (ShaderFlags.BOX_CLIPPING | ShaderFlags.BOX_CLIP_INSIDE)) {
			uniforms.clipBoxMin = this.globalUniforms.clipboxMin;
			uniforms.clipBoxMax = this.globalUniforms.clipboxMax;
		}
		if (shaderFlags & ShaderFlags.PLANE_CLIPPING) {
			uniforms.clippingPlane = { value: new Vector4(0, 0, 0, 0) }
		}
		if (mat.transparent) {
			mat.defines['TRANSPARENT'] = true;
		} else {
			mat.side = DoubleSide;
			if (shaderFlags & (ShaderFlags.BOX_CLIP_INSIDE | ShaderFlags.BOX_CLIPPING | ShaderFlags.PLANE_CLIPPING)) {
				mat.defines['GRAY_BACKFACE'] = true;
			}
		}

		mat.defines['CUSTOM_STD_ATTRIBUTES'] = true;
		mat.defines['CUSTOM_STD_UNIFORMS'] = true;
		mat.defines['NEEDS_WORLD_SPACE'] = true;

		if (shaderFlags & (ShaderFlags.COLOR_CLIPPING | ShaderFlags.PLANE_CLIPPING
			| ShaderFlags.BOX_CLIP_INSIDE | ShaderFlags.BOX_CLIPPING)) {
			mat.defines['NEEDS_WORLD_SPACE'] = true;
		}

		if (mat.uniforms['resolution']) {
			mat.uniforms.resolution = this.globalUniforms.resolution;
		}
		
		
	}
}


