import { Quaternion, Vec3X, Vector3 } from "math-ts";
import { FixedTiltTypeIdent } from "../../archetypes/fixed-tilt/FixedTilt";
import { BimProperty } from "../../bimDescriptions/BimProperty";
import { PropertiesCollection } from "../../bimDescriptions/PropertiesCollection";
import { getSolarTrackerProps } from "../../terrain/cut-fill/CutFillService";
import { TrackerPartType, TrackerTypeIdent, segmentsAnglesFromString, trackersPartsCache } from "../../trackers/Tracker";
import type { SceneInstancesPatchesAfterMigrations} from "../SceneInstancesSerializer";
import { SceneInstanceSerializable, SceneInstancesVersions } from "../SceneInstancesSerializer";
import type { IdBimScene } from "../../scene/SceneInstances";
import type { DefaultMap } from "engine-utils-ts";


export function sceneInstanceMigration(
    inst: SceneInstanceSerializable,
    version: SceneInstancesVersions,
    id: IdBimScene,
    patchesToApply: DefaultMap<SceneInstancesVersions, SceneInstancesPatchesAfterMigrations<any>>
): SceneInstanceSerializable {
    let migratedInst = migrateToRenameTypeIdentifier(inst, version);
    migratedInst = migrateToAddCostBSToLWDebugWire(migratedInst, version);
    //migratedInst = migrateToAddMaxSlopeToTrackerAndFixedTilt(migratedInst, version);
    migratedInst = migrateToAddMinEmbedmentToTrackerAndFixedTilt(migratedInst, version);
    migratedInst = migrateToAddFXMRAndSkid(migratedInst, version);
    migratedInst = migrateToRenameLvWireType(migratedInst, version);
    migratedInst = migrateToAddAssetSelectToMinWiringOnTransformers(migratedInst, version);
    migratedInst = migrateToFixInvalidLocalTransform(migratedInst, version);

    const patchesData = patchesToApply.getOrCreate(SceneInstancesVersions.RecenterTracker).postMigrationPatchesData;
    migratedInst = migrateToRecenterTracker(migratedInst, version, id, patchesData);

    migratedInst = moduleEnergyCoeffsFixes(version, migratedInst);
    migratedInst = renameEnergyProperties(version, migratedInst);

    migratedInst = removeAutoGeneratedRepresentations(version, migratedInst);
    migratedInst = migrateToRemoveFXMRAndSkid(migratedInst, version);

    return migratedInst;
}

function moduleEnergyCoeffsFixes(version: SceneInstancesVersions, inst: SceneInstanceSerializable): SceneInstanceSerializable {
    let migratedInst = inst;
    if (version < SceneInstancesVersions.ModuleEnergyCoeffsFixes) {
        if (migratedInst.type_identifier === 'tracker' || migratedInst.type_identifier === 'fixed-tilt' || migratedInst.type_identifier === 'pv-module') {
            const temp_coeff_power = inst.properties.get('module | temp_coeff_power');
            const temp_coeff_voltage = inst.properties.get('module | temp_coeff_voltage');
            const temp_coeff_current = inst.properties.get('module | temp_coeff_current');

            const propsPatch: [string, BimProperty][] = [];
            
            let powerCoeff = temp_coeff_power?.asNumber() ?? 0;
            if (Math.abs(powerCoeff) > 0.1) {
                powerCoeff /= 100;
            }
            propsPatch.push(['module | temp_coeff_power', BimProperty.NewShared({
                path: ['module', 'temp_coeff_power'],
                value: powerCoeff,
                unit: 'W/C',
            })]);
            
            propsPatch.push(['module | temp_coeff_voltage', BimProperty.NewShared({
                path: ['module', 'temp_coeff_voltage'],
                value: temp_coeff_voltage?.asNumber() ?? 0,
                unit: 'V/C',
            })]);

            propsPatch.push(['module | temp_coeff_current', BimProperty.NewShared({
                path: ['module', 'temp_coeff_current'],
                value: temp_coeff_current?.asNumber() ?? 0,
                unit: 'A/C',
            })]);

            migratedInst.properties.applyPatch(propsPatch);
        }
    }
    return migratedInst;
}

function removeAutoGeneratedRepresentations(version: SceneInstancesVersions, inst: SceneInstanceSerializable): SceneInstanceSerializable {
    if (version > SceneInstancesVersions.DeprecateSavingAutoGeneratedRepresentation2) {
        return inst;
    }
    if (inst.type_identifier === 'tracker'
        || inst.type_identifier === 'fixed-tilt'
        || inst.type_identifier === 'transformer'
        || inst.type_identifier === 'inverter'
        || inst.type_identifier === 'combiner-box'
        || inst.type_identifier === 'sectionalizing-cabinet'
        || inst.type_identifier === 'substation'
    ) {
        inst.representation = null;
        return inst;
    }
    return inst;
}

function migrateToRenameTypeIdentifier(
    inst: SceneInstanceSerializable,
    version: SceneInstancesVersions,
) {
    let migratedInst = inst;
    if (version < SceneInstancesVersions.RenameTypeIdentifier) {
        if(inst.type_identifier === 'solar-tracker'){
            migratedInst = SceneInstanceSerializable.withDifferentFields(inst, {type_identifier: 'tracker'});
        }else if(inst.type_identifier === 'tracker-fixed'){
            migratedInst = SceneInstanceSerializable.withDifferentFields(inst, {type_identifier: 'fixed-tilt'});
        }else if(inst.type_identifier === 'solar-transformer'){
            migratedInst = SceneInstanceSerializable.withDifferentFields(inst, {type_identifier: 'transformer'});
        }else if(inst.type_identifier === 'solar-inverter'){
            migratedInst = SceneInstanceSerializable.withDifferentFields(inst, {type_identifier: 'inverter'});
        }else if(inst.type_identifier === 'solar-combiner-box'){
            migratedInst = SceneInstanceSerializable.withDifferentFields(inst, {type_identifier: 'combiner-box'});
        }else if(inst.type_identifier === 'sectionalized-cabinet'){
            migratedInst = SceneInstanceSerializable.withDifferentFields(inst, {type_identifier: 'sectionalizing-cabinet'});
        }

    }

    return migratedInst;
}

function setNewProperty(sourceProperties: BimProperty[], newProperty:BimProperty){
    const properties = sourceProperties.slice() ?? [];

    const index = properties.findIndex(p => p._mergedPath === newProperty._mergedPath);
    if(index === -1){
        properties.push(newProperty);
    } else {
        properties[index] = newProperty;
    }

    return properties;
}

function migrateToAddCostBSToLWDebugWire(inst: SceneInstanceSerializable, version: SceneInstancesVersions) {
    let migratedInst = inst;
    if (version < SceneInstancesVersions.AddCostBSToLWDebugWire) {
        if (migratedInst.type_identifier === 'lv-debug-wire') {
            const properties = migratedInst.properties?.asArray() ?? [];
            properties.push(
                BimProperty.NewShared({
                    path: ['cost_bs', 'level 1'],
                    value: 'ELECTRICAL SUBTOTAL',
                    readonly: true,
                }),
                BimProperty.NewShared({
                    path: ['cost_bs', 'level 2'],
                    value: 'DC',
                    readonly: true,
                })
            );
            migratedInst = SceneInstanceSerializable.withDifferentFields(migratedInst, { properties: new PropertiesCollection(properties) });

        }
    }
    return migratedInst;
}

function migrateToAddMaxSlopeToTrackerAndFixedTilt(inst: SceneInstanceSerializable, version: SceneInstancesVersions): SceneInstanceSerializable {
    let migratedInst = inst;

    if (version < SceneInstancesVersions.AddMaxSlopeToTrackerAndFixedTilt) {
        if(migratedInst.type_identifier === 'tracker' || migratedInst.type_identifier === 'fixed-tilt'){
            const max_slope = BimProperty.NewShared({
                path: ['position', 'max_slope'],
                value: migratedInst.type_identifier === 'tracker' ? 6.1 : 20,
                unit: '%',
                readonly: true,
                numeric_step: 0.1,
            });
            let properties = migratedInst.properties?.asArray() ?? [];
            properties = setNewProperty(properties, max_slope);

            migratedInst = SceneInstanceSerializable.withDifferentFields(migratedInst, { properties: new PropertiesCollection(properties)});
        }
    }
    return migratedInst;

}

function migrateToAddMinEmbedmentToTrackerAndFixedTilt(inst: SceneInstanceSerializable, version: SceneInstancesVersions): SceneInstanceSerializable {
    let migratedInst = inst;

    if (version < SceneInstancesVersions.AddMinEmbedmentToTrackerAndFixedTilt) {
        const min_embedment = BimProperty.NewShared({
            path: ['piles', 'min_embedment'],
            value: 1.2192,
            unit: 'm',
            numeric_range: [0, 100],
        });
        const max_reveal = BimProperty.NewShared({
            path: ['piles', 'max_reveal'],
            value: 2.44,
            unit: 'm',
            numeric_range: [0, 100],
        });
        if (
            migratedInst.type_identifier === "tracker" ||
            migratedInst.type_identifier === "fixed-tilt"
        ) {
            let properties = migratedInst.properties?.asArray() ?? [];
            const removeProps = new Set([
                'piles | reveal',
                'piles | total_length',
                'piles | min_reveal',
                'dimensions | pile_reveal'
            ]);
            properties = properties.filter(p => !removeProps.has(p._mergedPath));
            properties = setNewProperty(properties, min_embedment);
            properties = setNewProperty(properties, max_reveal);
            migratedInst = SceneInstanceSerializable.withDifferentFields(
                migratedInst,
                { properties: new PropertiesCollection(properties) }
            );
        }
    }

    return migratedInst;

}

function migrateToRemoveFXMRAndSkid(inst: SceneInstanceSerializable, version: SceneInstancesVersions) {
    let migratedInst = inst;

    if (version < SceneInstancesVersions.RemoveFXMRAndSkid) {
        const fxmrProp = BimProperty.NewShared({
            path: ['inverter', 'fxmr'],
            value: false,
        });
        const skidProp = BimProperty.NewShared({
            path: ['transformer', 'skid'],
            value: false,
        });
        if (migratedInst.type_identifier === 'transformer') {
            let properties = migratedInst.properties?.asArray() ?? [];
            properties = properties.filter(x => x._mergedPath !== skidProp._mergedPath)
            migratedInst = SceneInstanceSerializable.withDifferentFields(migratedInst, { properties: new PropertiesCollection(properties) });
        } else if (migratedInst.type_identifier === 'inverter') {
            let properties = migratedInst.properties?.asArray() ?? [];
            properties = properties.filter(x => x._mergedPath !== fxmrProp._mergedPath)
            migratedInst = SceneInstanceSerializable.withDifferentFields(migratedInst, { properties: new PropertiesCollection(properties) });
        }
    }
    return migratedInst;
}

function migrateToAddFXMRAndSkid(inst: SceneInstanceSerializable, version: SceneInstancesVersions){
    let migratedInst = inst;

    if (version < SceneInstancesVersions.AddFXMRAndSkid) {
        const fxmrProp = BimProperty.NewShared({
            path: ['inverter', 'fxmr'],
            value: false,
        });
        const skidProp = BimProperty.NewShared({
            path: ['transformer', 'skid'],
            value: false,
        });
        if (migratedInst.type_identifier === 'transformer') {
            let properties = migratedInst.properties?.asArray() ?? [];
            properties = setNewProperty(properties, skidProp)
            migratedInst = SceneInstanceSerializable.withDifferentFields(
                migratedInst,
                { properties: new PropertiesCollection(properties) }
            );
        } else if (migratedInst.type_identifier === 'inverter') {
            let properties = migratedInst.properties?.asArray() ?? [];
            properties = setNewProperty(properties, fxmrProp)
            migratedInst = SceneInstanceSerializable.withDifferentFields(
                migratedInst,
                { properties: new PropertiesCollection(properties) }
            );
        }
    }
    return migratedInst;
}

function migrateToRenameLvWireType(inst: SceneInstanceSerializable, version: SceneInstancesVersions){
    let migratedInst = inst;

    if (version < SceneInstancesVersions.RenameLvWireType) {
        if(migratedInst.type_identifier === 'lv-debug-wire'){
            migratedInst = SceneInstanceSerializable.withDifferentFields(migratedInst, {type_identifier: 'lv-wire'});
        }
    }
    return migratedInst;
}

function migrateToAddAssetSelectToMinWiringOnTransformers(inst: SceneInstanceSerializable, version: SceneInstancesVersions){
    let migratedInst = inst;
    if (version < SceneInstancesVersions.AddAssetSelectToMinWiringOnTransformers) migrate: {
        if (migratedInst.type_identifier !== 'transformer') break migrate;
        let properties = migratedInst.properties?.asArray() ?? [];
        const minWireBasePath = ['circuit', 'pattern', 'min_wiring'];
        const anyMinWireProp = properties.find(
            x => x._mergedPath.startsWith(BimProperty.MergedPath(minWireBasePath))
        )
        if (!anyMinWireProp) break migrate;
        if (anyMinWireProp.description && anyMinWireProp.description.startsWith('=asset=')) {
            break migrate;
        }
        // otherwise min-wiring has invalid format. removing all min-wiring props
        properties = properties.filter(
            x => !x._mergedPath.startsWith(BimProperty.MergedPath(minWireBasePath))
        );
        migratedInst = SceneInstanceSerializable.withDifferentFields(inst, { properties: new PropertiesCollection(properties) })
    }
    return migratedInst;
}

function migrateToFixInvalidLocalTransform(
    inst: SceneInstanceSerializable,
    version: SceneInstancesVersions
) {
    let migratedInst = inst;

    if (version < SceneInstancesVersions.FixInvalidLocalTransform) {
        if(!migratedInst.localTransform.position.isFinite()){
            migratedInst.localTransform.position.setScalar(0);
        }
        if(!migratedInst.localTransform.rotation.isFinite()){
            migratedInst.localTransform.rotation.identity();
        }
        if(!migratedInst.localTransform.scale.isFinite()){
            migratedInst.localTransform.scale.setScalar(0);
        }
    }
    return migratedInst;
}

function migrateToRecenterTracker(
    inst: SceneInstanceSerializable,
    version: SceneInstancesVersions,
    id: IdBimScene,
    toApply: Map<IdBimScene, Vector3>
) {
    if (version >= SceneInstancesVersions.RecenterTracker || 
        (inst.type_identifier !== TrackerTypeIdent && inst.type_identifier !== FixedTiltTypeIdent)) {
        return inst;
    }

    let migratedInst = inst;

    const props = getSolarTrackerProps(inst);
    const pilesEmbedment = inst.properties.get("tracker-frame | piles | min_embedment")?.as('m')!;
    
    let generalRotation = new Quaternion();
    const slopeDirection = inst.properties.get("position | slope-direction")?.asText();
    const slope = inst.properties.get("position | slope_first_to_last")?.as('rad');

    let currentCoords: Vector3;
    if (props.typeIdentifier === "fixed-tilt") {
        if (slopeDirection !== undefined && slope !== undefined) {
            const generalRotationAngle = (slopeDirection === 'east-facing' ? -1 : 1) * slope;
            if (generalRotationAngle < 0.7854 && generalRotationAngle > -0.7854) {
                generalRotation.setFromAxisAngle(Vec3X, generalRotationAngle);
            }
        }
        
        const length = inst.properties.get("dimensions | length")?.as('m')!;
        currentCoords = new Vector3(length / 2, 0, 0);
    } else {
        if (slopeDirection !== undefined && slope !== undefined) {
            const generalRotationAngle = (slopeDirection === 'north-facing' ? 1 : -1) * slope;
            if (generalRotationAngle < 0.7854 && generalRotationAngle > -0.7854) {
                generalRotation.setFromAxisAngle(Vec3X, generalRotationAngle);
            }
        }

        const parts = trackersPartsCache.acquire(props).parts;
        
        const segments_slopes = inst.properties.get("position | _segments-slopes")?.asText();
        if (segments_slopes !== undefined && segments_slopes !== "") {
            const rotations: Quaternion[] = [];
            const rotationsAngles = segmentsAnglesFromString(segments_slopes);
            for (const rotationAngle of rotationsAngles) {
                rotations.push(new Quaternion().setFromAxisAngle(Vec3X, rotationAngle));
            }

            const trackerCenterOffset = 0.5 * (parts[0].centerOffset + parts.at(-1)!.centerOffset);

            currentCoords = new Vector3();

            let pilesCount = 0;
            let lastPileCenterOffset = parts[0].centerOffset;
            for (const part of parts) {
                if (part.ty & TrackerPartType.Pile) {
                    if (part.centerOffset >= trackerCenterOffset) {
                        const offset = new Vector3(0, trackerCenterOffset - lastPileCenterOffset, 0);
                        if (pilesCount > 0) {
                            offset.applyQuaternion(rotations[pilesCount - 1]);
                        } else {
                            offset.applyQuaternion(rotations[pilesCount]);
                        }
                        currentCoords.add(offset);
        
                        break;
                    } else {
                        const offset = new Vector3(0, part.centerOffset - lastPileCenterOffset, 0);
                        if (pilesCount > 0) {
                            offset.applyQuaternion(rotations[pilesCount - 1]);
                        } else {
                            offset.applyQuaternion(rotations[pilesCount]);
                        }
                        currentCoords.add(offset);
        
                        lastPileCenterOffset = part.centerOffset;
        
                        pilesCount++;
                    }
                }
            }
        } else {
            currentCoords = new Vector3(0, 0.5 * (parts[0].centerOffset + parts.at(-1)!.centerOffset), 0);
        }
    }

    const offset = new Vector3(0, 0, pilesEmbedment).applyQuaternion(generalRotation);
    currentCoords.add(offset);

    toApply.set(id, currentCoords);

    return migratedInst;
}

function renameEnergyProperties(version: SceneInstancesVersions, inst: SceneInstanceSerializable): SceneInstanceSerializable {
    let migratedInst = inst;

    if (version < SceneInstancesVersions.RenameEnergyProperties) {
        if (
            migratedInst.type_identifier === "tracker" ||
            migratedInst.type_identifier === "fixed-tilt" ||
            migratedInst.type_identifier === "transformer" ||
            migratedInst.type_identifier === "inverter" ||
            migratedInst.type_identifier === "combiner-box" ||
            migratedInst.type_identifier === "substation" ||
            migratedInst.type_identifier === "sectionalizing-cabinet" ||
            migratedInst.type_identifier === "wire"
        ) {
            const propsToRemove = [
                "ac_power", "dc_power", "dc/ac_ratio", "max_current", "max_voltage", "operating_current", "operating_voltage",
                "output_max_current", "output_operating_current", "output_operating_voltage"
            ];
            const propsPatch: [string, null][] = propsToRemove.map(p => (["circuit | energy | " + p, null]));

            if (migratedInst.type_identifier === 'transformer') {
                const transformerProps: [string, null][] = propsToRemove.map(p => (["circuit | block_energy | " + p, null]));
                propsPatch.push(...transformerProps);
            }
            migratedInst.properties.applyPatch(propsPatch);
        }
    }

    return migratedInst;
}