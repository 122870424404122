<script lang="ts">

    export let message: string;
    export let color: string = "";

</script>

<div class="ui-config-property property-row">
    <div class="property-name text-main-medium">
        <div class="property-name-label mdc-typography--body1 {color}">
            {@html message}
        </div>
    </div>
</div>

<style lang="scss">
</style>
