import { AreaTypeEnum, Bim, BoundaryTypeIdent, Catalog, IdBimScene, MetricsGroup, NumberProperty, NumberRangeProperty, ProjectMetricsType, RoadTypeIdent, SubstationTypeIdent } from "bim-ts";
import { DefaultMap, IterUtils, ScopedLogger, Yield } from "engine-utils-ts";
import jsPDF from "jspdf";
import { Aabb2, Vector2 } from "math-ts";
import { AnnotationBuilder } from "../AnnotationsBuilder";
import { addLatoFonts } from "../fonts/fonts";
import { DrawColor, GridStep } from "../LayoutDrawing";
import { convertPxToMm, EquipmentDefaultColor, ExcludeBoundaryBorderColor, ExcludeBoundaryColor, IncludeBoundaryColor, PalettePerType, RoadColor, SolarArraysTypes, SubstationColor } from "../PdfCommon";
import { PageFrameOffsets, PdfElement, PdfReportContext } from "../PdfReportBuilder";
import { TextBuilder } from "../TextBuilder";
import { drawLayoutOnPageByTemplate1 } from "./OverveiwPage";

export class LayoutMetricsTable extends PdfElement {

    constructor(
        readonly bim: Bim,
        readonly metrics: MetricsGroup<Partial<ProjectMetricsType>>[],
        readonly pallete: PalettePerType,
        readonly logger: ScopedLogger,
    ) {
        super();
    }

    *draw({page, pageFrameOffsets}: PdfReportContext): Generator<Yield, void, unknown> {
        yield Yield.Asap;
        drawLayoutMetrics(page, this.bim, this.metrics, pageFrameOffsets, this.pallete, this.logger);
    }

    calculateContentSize(pageSize: Vector2, {pageFrameOffsets}: PdfReportContext): Aabb2 {
        const jspdf = new jsPDF({unit: 'mm'});
        addLatoFonts(jspdf);
        const builder = drawLayoutMetrics(jspdf, this.bim, this.metrics, pageFrameOffsets, this.pallete, this.logger);
        return calculateContentSize(pageSize, builder, pageFrameOffsets);
    }
}

export function calculateContentSize(pageSize: Vector2, builder: TextBuilder | undefined, offsets: PageFrameOffsets): Aabb2 {
    const textOffset = builder?.offsetY ? builder.offsetY + 5 : 0;
    return Aabb2.empty().setFromPoints([
        new Vector2(0, 0),
        new Vector2(pageSize.x, textOffset + convertPxToMm(offsets.downOffsetPx + 10))
    ]);
}

function drawLayoutMetrics(page: jsPDF, bim: Bim, metrics: MetricsGroup<Partial<ProjectMetricsType>>[], offsets: PageFrameOffsets, pallete: PalettePerType, logger: ScopedLogger){
    const total = metrics.find((x) => x.type === AreaTypeEnum.Total);
    if (!total) {
        return;
    }

    const textBuilder = new TextBuilder(
        bim.unitsMapper,
        page,
        offsets.upOffsetPx, 
        offsets.leftRightOffsetPx,
        total.metrics,
        logger,
        convertPxToMm
    );
    
    textBuilder.addRow({text: 'Site area', headerLevel: 1, values: [], style: 'bold'});
    textBuilder.addFromMetric('buildable_area');
    textBuilder.addFromMetric('footprint');
    textBuilder.addOffset();
    textBuilder.addOffset();
    textBuilder.addOffset();
    textBuilder.addFromMetric('roads_total_area', {name: 'Roads'});
    textBuilder.addFromMetric('roads_total_length', {name: ' '});
    textBuilder.addFromMetric('equipment_roads_width_range', {name: "Equipment roads width"});
    textBuilder.addFromMetric('support_roads_width_range', {name: 'Support roads width'});
    textBuilder.addOffset();
    textBuilder.addOffset();
    textBuilder.addOffset();
    const solarArrays = total.metrics?.['solar_array_row_height'] ?? {};
    const solarArraysAvg:[key: string, NumberProperty][] = [];
    for (const key in solarArrays) {
        const prop = solarArrays[key];
        if(prop instanceof NumberProperty){
            solarArraysAvg.push([key, prop]);
        } else {
            logger.error("unsupported type", prop)
        }
    }
    if(solarArraysAvg.length){
        textBuilder.addRow({
            text: "Solar arrays rows",
            values: [
                {
                    value: IterUtils.sum(solarArraysAvg, h => h[1].value).toFixed(),
                }
            ]
        });
        for (const [key, prop] of solarArraysAvg) {
            textBuilder.addFromNumberProp(key, prop);
        }
    }

    textBuilder.addGroupedFromMetric("PV modules", "dc_per_pv_module",  'pv_modules', undefined, {headerLevel: 1}); 
    textBuilder.addFromMetric('gcr', {name: "GCR"});

    textBuilder.addGroupedFromMetric("Trackers", "dc_per_tracker",  'trackers', pallete, {headerLevel: 1});
    textBuilder.addGroupedFromMetric("Any trackers", "dc_per_tracker",  'any_trackers', pallete, {headerLevel: 1});
    textBuilder.addGroupedFromMetric("Fixed-tilt", "dc_per_tracker",  'fixed_tilt', pallete, {headerLevel: 1});  

    const row_to_row = total.metrics?.['row_to_row_range'];
    if(row_to_row instanceof NumberRangeProperty){
        const v = row_to_row.toConfiguredUnits(bim.unitsMapper);
        let value = `${v.value[0].toFixed(2)}-${v.value[1].toFixed(2)}`;
        if(v.value[0] === v.value[1]){
            value = `${v.value[0].toFixed(2)}`;
        }
        textBuilder.addRow({
            text: 'Row to row',
            values: [{value: `${value}`, unit: v.unit}]
        });
    }
    textBuilder.addGroupedFromMetric("Combiner boxes", '', "combiner_boxes", undefined, { headerLevel: 1});
    textBuilder.addGroupedFromMetric("Inverters", '', "inverters", undefined, { headerLevel: 1});  
    textBuilder.addGroupedFromMetric("Transformers", '', "transformers", undefined, { headerLevel: 1});

    return textBuilder;
}

export class ScreenshotByTracker extends PdfElement {
    constructor(
        readonly bim: Bim,
        readonly catalog: Catalog,
        readonly pallete: PalettePerType,
        readonly logger: ScopedLogger,
    ){
        super();
    }

    *draw({page, layoutDrawing, pageFrameOffsets}: PdfReportContext) {
        const types = [
            RoadTypeIdent,
            BoundaryTypeIdent,
            SubstationTypeIdent,
            ...SolarArraysTypes
        ];

        // const focusIds = this.bim.instances.peekByTypeIdents(trackerTypes);
        const allIds = IterUtils.filterMap(this.bim.instances.peekByTypeIdents(types), ([id, si]) => {
            if (si.type_identifier !== BoundaryTypeIdent) {
                return id;
            }
            const boundaryType = si.properties.get('boundary | source_type')?.asText();
            if(boundaryType === 'origin' || boundaryType == undefined){
                return id;
            }
            return undefined;
        })

        const allTypes = new DefaultMap<string, IdBimScene[]>(() => []);
        const instances = this.bim.instances.peekByIds(allIds);
        const undefinedType = 'unknown';
        for (const [id, si] of instances) {
            const name = SolarArraysTypes.includes(si.type_identifier)
                ? this.catalog.keyPropertiesGroupFormatters.format(
                    si.type_identifier,
                    si.properties,
                    si.props,
                )
                : undefined;
            if (name) {
                allTypes.getOrCreate(name).push(id);
            } else {
                allTypes.getOrCreate(undefinedType).push(id);
            }
        }
        
        const idPerColor = new Map<IdBimScene, DrawColor>();
        const allTypesArray = Array.from(allTypes.entries()).sort((a, b) => a[0].localeCompare(b[0]));
        for (const [name, ids] of allTypesArray) {
            const colorTint = name === undefinedType 
                ? EquipmentDefaultColor 
                : this.pallete.getNext(name);
            for (const id of ids) {
                const typeIdent = this.bim.instances.peekTypeIdentOf(id);
                if(typeIdent === BoundaryTypeIdent){ 
                    idPerColor.set(id, getBoundaryColor(this.bim, id));
                } else if(typeIdent === RoadTypeIdent){
                    idPerColor.set(id, { borderColor: RoadColor });
                } else if(typeIdent === SubstationTypeIdent) {
                    idPerColor.set(id, { borderColor: SubstationColor });
                } else {
                    idPerColor.set(id, { borderColor: colorTint });
                }
            }
        }

        yield* drawLayoutOnPageByTemplate1({
            bim: this.bim,
            page, 
            ids: allIds, 
            layoutDrawing, 
            offsets: pageFrameOffsets,
            colorize: (id) => {
                const color = idPerColor.get(id);
                return color ?? { borderColor: EquipmentDefaultColor };
            },
            grid: true,
        });

        addLayoutImageAnnotations(page, this.pallete, pageFrameOffsets, layoutDrawing.getLastCalculatedGrid());
    }
}

function getBoundaryColor(bim: Bim, id: IdBimScene): DrawColor {
    const si = bim.instances.peekById(id);
    const boundaryType = si?.properties.get('boundary | boundary_type')?.asText();
    if(boundaryType === 'exclude'){
        return {borderColor: ExcludeBoundaryBorderColor, backgroundColor: ExcludeBoundaryColor};
    } else {
        return {borderColor: IncludeBoundaryColor};
    }
}   


function addLayoutImageAnnotations(
    page: jsPDF, 
    palette: PalettePerType, 
    offsets: PageFrameOffsets, 
    grid?: GridStep
){ 
    const annotations = new AnnotationBuilder(page, offsets);
    annotations.addBoxes({
        text: "Solar arrays",
        colors: palette.getUsedColors(),
    });
    annotations.addBoxes({
        text: 'Equipment',
        colors: [EquipmentDefaultColor]
    });
    annotations.addBoxes({
        text: 'Substation',
        colors: [SubstationColor]
    });
    annotations.addLine({
        text: 'Road',
        color: RoadColor
    });
    annotations.addLine({
        text: 'Include boundary',
        color: IncludeBoundaryColor
    });
    annotations.addLineWithBox({
        text: "Exclude boundary",
        colorBox: ExcludeBoundaryColor,
        colorLine: ExcludeBoundaryBorderColor,
    });

    if(grid){
        annotations.addGrid({
            text: `${grid.step} ${grid.unit}`,
            width: grid.widthLocalSystem,
        });
    }
}

