import { ShaderMaterial, UniformsUtils } from '../3rdParty/three';
import type { ShaderBase } from '../shaders/ShaderBase';

export class MaterialsUtils {

	static createShaderMaterial(shader: ShaderBase): ShaderMaterial {
		return new ShaderMaterial({
			defines: shader.defines || {},
			uniforms: UniformsUtils.clone(shader.uniforms),
			vertexShader: shader.vertexShader,
			fragmentShader: shader.fragmentShader,
			depthWrite: false,
			depthTest: true,
			transparent: true,
			name: shader.name,
		});
	}

}
