import type { Disposable,
	VersionedValue} from 'engine-utils-ts';
import { LazyDerived, LegacyLogger, TypedNumbersVec
} from 'engine-utils-ts';
import type { Ray} from 'math-ts';
import { Aabb, DEG2RAD, KrMath, Matrix4, Vector3 } from 'math-ts';

import type { ProgressiveCullListWasm} from '../../../engine_wasm/dist/engine_wasm';
import {
	BoundsScene
} from '../../../engine_wasm/dist/engine_wasm';
import { OrthographicCamera, PerspectiveCamera } from '../3rdParty/three';
import type { ClipBox} from '../clipbox/ClipBox';
import type { GesturesMousePos } from '../controls/MouseGesturesBase';
import type { KrCamera } from '../controls/MovementControls';
import type { Handle} from '../memory/Handle';
import { HandleIndexMask } from '../memory/Handle';
import type { EnumsGateway } from '../structs/EnumsGateway';
import type { FrustumExt } from '../structs/FrustumExt';
import { RaySection } from '../structs/RaySection';
import type { index } from '../utils/Utils';
import type { LodGroupHandle, LodGroupLocalIdent, LodGroups,
	LodGroupSubmeshRef} from './LodGroups';
import {
	getLodGroupDetailSize, lodMaskFromSubmeshLodRef, submeshIndexFromSubmeshLodRef,
} from './LodGroups';
import type { Submeshes2 } from './Submeshes2';
import { LodMask } from './Submeshes2';

export type ClipboxPositionsBuf = Uint8Array;

export interface SceneUpdateParams {
	frustums: FrustumExt[],
}

export interface SceneUpdateOutput {
	dirtyClipboxBatches: number[]
}

export type AnyBoundsScene = BoundsSceneWrap<number>;

export class BoundsSceneWrap<THandle extends Handle> implements VersionedValue {

	readonly wasmScene: BoundsScene;
	objectsBounds: Float64Array = new Float64Array();
	_clipboxPositions!: Uint8Array;

	readonly clipbox: ClipBox;

	bounds: Aabb = Aabb.empty();

	isCulling: boolean = true;
	frustums_versions: WeakMap<FrustumExt, number> = new WeakMap();

	private _version: number = 1;

	constructor(
		clipbox: ClipBox,
		cullSize: number = 1/300,
	) {
		this.wasmScene = new BoundsScene();
		this.clipbox = clipbox;

		this.bounds.elements = this.wasmScene.get_total_bounds() as any;
	}

	version(): number {
		return this._version;
	}

	clear() {
		this.wasmScene.clear_objects();
	}

	dispose() {
		this.wasmScene.free();
	}

	reallocateBoundsBuffer(newCapacity: number): Float64Array {
		LegacyLogger.debugAssert(newCapacity % 6 === 0, 'bounds capacity %6 check');
		const wasm_capacity = newCapacity / 6;
		this.objectsBounds = this.wasmScene.reallocate_bounds_buffer(wasm_capacity);
		return this.objectsBounds;
	}

	reallocateClipboxRelativePositionsBuffer(newCapacity: number): Uint8Array {
		this._clipboxPositions = this.wasmScene.reallocate_clipbox_positions_buffer(newCapacity);
		return this._clipboxPositions;
	}

	clipboxPositions(): Uint8Array {
		return this._clipboxPositions;
	}


	markDirty(index: number | THandle) {
		index = index & HandleIndexMask;
		this.wasmScene.mark_dirty(index);
		this._version += 1;
	}

	raycast(ray: Ray): IndexDistanceTuplesF64 {
		let wasm_raycast: Float64Array = this.wasmScene.raycast(
			ray.origin.x, ray.origin.y, ray.origin.z,
			ray.direction.x, ray.direction.y, ray.direction.z,
		);
		return wasm_raycast;
	}

	find_close_to_point(point: Vector3, maxDistance: number): IndexDistanceTuplesF64 {
		let wasm_raycast: Float64Array = this.wasmScene.find_close_to_point(
			point.x, point.y, point.z, maxDistance
		);
		return wasm_raycast;
	}

    coneCast(mouseRayCone: MouseRayCone): ConeCastResults {
        const ray = mouseRayCone.raySection.ray;
		let wasm_raycast: Float64Array = this.wasmScene.cone_cast(
            ray.origin.x, ray.origin.y, ray.origin.z,
            ray.direction.x, ray.direction.y, ray.direction.z,
            mouseRayCone.coneRadius0, mouseRayCone.coneRadius1
        );
        return new ConeCastResults(wasm_raycast);
    }

	updateWasmSceneHierarchy() {
		this.wasmScene.update();
	}

	_lastUpdateVersion: number = this._version;
	_lastClipboxPosVersion: number = 0;

	prepareForCulling(params: SceneUpdateParams) {
		if (this._lastClipboxPosVersion != this.clipbox.positionsVersion || this._lastUpdateVersion != this._version) {
			this._lastClipboxPosVersion = this.clipbox.positionsVersion;
			const clipbox = this.clipbox.getBounds();
			const ids = this.wasmScene.udpate_clipbox_positions(
				clipbox.elements[0], clipbox.elements[1], clipbox.elements[2],
				clipbox.elements[3], clipbox.elements[4], clipbox.elements[5],
			);

			if (ids.length > 0) {
				this._version += 1;
			}
			this._lastUpdateVersion = this._version;
		}
	}

	// _cull(fr: FrustumExt, cb: CulledRenderList2) {
	// 	const fm = new Matrix4();
	// 	fm.multiplyMatrices(fr.camera.projectionMatrix as any, fr.camera.matrixWorldInverse  as any);
	// 	cb.reallocIfNecessary(this._clipboxPositions.length);
	// 	this.wasmScene.cull2(
	// 		cb._wasmBuffer!, fr.camera.near, fr.camera.far,
	// 		fm.elements[0], fm.elements[1], fm.elements[2], fm.elements[3],
	// 		fm.elements[4], fm.elements[5], fm.elements[6], fm.elements[7],
	// 		fm.elements[8], fm.elements[9], fm.elements[10], fm.elements[11],
	// 		fm.elements[12], fm.elements[13], fm.elements[14], fm.elements[15],
	// 	);
	// 	cb.updateView();
	// }

	intersectFrustum(fm: Matrix4, near: number, far: number): FrustumSceneIntersection {

		const [inside, partial]: [number[], number[]] = this.wasmScene.intersect_frustum(
			near, far,
			fm.elements[0], fm.elements[1], fm.elements[2], fm.elements[3],
			fm.elements[4], fm.elements[5], fm.elements[6], fm.elements[7],
			fm.elements[8], fm.elements[9], fm.elements[10], fm.elements[11],
			fm.elements[12], fm.elements[13], fm.elements[14], fm.elements[15],
		) as any;
		return {
			inside: new Set(inside),
			partial
		}
	}
}

export type IndexDistanceTuplesF64 = Float64Array;
export type IndexDistanceTuples = number[];

interface FrustumSceneIntersection {
	inside: Set<index>,
	partial: index[]
}

export interface ProgressiveCulling extends Disposable, VersionedValue {
	hasMoreAfter(indexInList: number): boolean ;

	getIndiciesIncluded(
		renderListFlag: number,
		flagsPerIndex: EnumsGateway<number, any>,
		nextIndex: number,
		maxCount: number,
		reverseOrder: boolean
	): { nextIndex: number, indices: CulledIndices };
}

export class ProgressiveLoddedList implements ProgressiveCulling {

	_frustumRef: FrustumExt;
	_lodGroupsRef: LodGroups;
	_lodGroupsScene: BoundsSceneWrap<LodGroupHandle>;
	_lodsCulledListWasm: LazyDerived<{lodsCulledList: ProgressiveCullListWasm, submeshesIndicesCulled: TypedNumbersVec<Int32Array>}>;

	constructor(
		fr: FrustumExt,
		submeshes: Submeshes2,
	) {
		this._frustumRef = fr;
		this._lodGroupsRef = submeshes.lodGroups;
		this._lodGroupsScene = submeshes.lodGroups.scene;
		this._lodsCulledListWasm = LazyDerived.new0('', [fr, this._lodGroupsScene, submeshes.scene], () => {
			const fm = new Matrix4();
			fm.multiplyMatrices(fr.camera.projectionMatrix, fr.camera.matrixWorldInverse);
			return {
				lodsCulledList: this._lodGroupsScene.wasmScene.new_list(
					0, // TODO: remove cull size
					fr.camera.near, fr.camera.far,
					fm.elements[0], fm.elements[1], fm.elements[2], fm.elements[3],
					fm.elements[4], fm.elements[5], fm.elements[6], fm.elements[7],
					fm.elements[8], fm.elements[9], fm.elements[10], fm.elements[11],
					fm.elements[12], fm.elements[13], fm.elements[14], fm.elements[15],
				),
				submeshesIndicesCulled: new TypedNumbersVec((size) => new Int32Array(size), 10000),
			}

		})
		.withCustomDisposeFn(t => t.lodsCulledList.free())
		.withoutEqCheck();
	}

	dispose() {
		this._lodsCulledListWasm.dispose();
	}

	version(): number {
		return this._lodsCulledListWasm.version();
	}

	_submeshesPerLodGroup: number = 2;
	_updateSubmeshesPerLodGroup(ratio: number) {
		if (ratio > 0 && ratio < Infinity) {
			this._submeshesPerLodGroup = KrMath.clamp(this._submeshesPerLodGroup * 0.8 + ratio * 0.2, 1, 10_000);
		} else {
			console.warn('progressive list: submeshes per lod group ratio erroneous', ratio);
		}
	}

	hasMoreAfter(indexInList: number): boolean {
		const {lodsCulledList, submeshesIndicesCulled} = this._lodsCulledListWasm.poll();
		const hasMoreLods = lodsCulledList.has_more_after(lodsCulledList.current_count());
		const hasMoreAlreadyCulled = (submeshesIndicesCulled.length / 2) > indexInList;
		const hasMore = hasMoreLods || hasMoreAlreadyCulled;
		return hasMore;
	}

	getIndiciesIncluded(
		renderListFlag: number,
		flagsPerIndex: EnumsGateway<number, any>,
		nextIndex: number,
		maxCount: number,
		reverseOrder: boolean
	): { nextIndex: number, indices: CulledIndices } {

		const {lodsCulledList, submeshesIndicesCulled} = this._lodsCulledListWasm.poll();

		const lodsCountBefore = lodsCulledList.current_count();

		const totalSubmeshesResultTarget = nextIndex + maxCount;

		if (reverseOrder) {
			// in reverse mode, cull everything right away
			// should be culled already anyway, transparent pass goes after opaque
			lodsCulledList.cull_more(this._lodGroupsScene.wasmScene, 0xFFFFFFF);
		} else if (submeshesIndicesCulled.length < totalSubmeshesResultTarget * 2) {
			const newSubmeshesCountRequested = totalSubmeshesResultTarget - submeshesIndicesCulled.length / 2;
			const lodsCountEstimation = newSubmeshesCountRequested / this._submeshesPerLodGroup * 1.3;
			lodsCulledList.cull_more(this._lodGroupsScene.wasmScene, lodsCountEstimation);
		}

		const culledDepthMultiplier = lodsCulledList.depth_multiplier();
		const toRealDepthMultiplier = 1 / culledDepthMultiplier;

		let lodCalcutor: (detailSize: number, depth: number) => LodMask;
		const camera = this._frustumRef.camera;
		if (camera instanceof OrthographicCamera) {
			const cameraSize = (camera.top - camera.bottom) / camera.zoom;
			const MinDetailSize = 1 / 1500;

			lodCalcutor = (detailSize: number, _depth: number) => {
				const relativeDetailSize = detailSize / cameraSize;
				if (relativeDetailSize < MinDetailSize * 0.2) {
					return LodMask.Lod2;
				}
				if (relativeDetailSize < MinDetailSize) {
					return LodMask.Lod1;
				}
				return LodMask.Lod0;
			}
		} else if (camera instanceof PerspectiveCamera) {
			const MinDetailSize = 1 / 1000;
			const CamerFov = camera.fov;
			const CameraFovTan2 = 2 * Math.tan(CamerFov * 0.5 * DEG2RAD);
			lodCalcutor = (detailSize: number, depth: number) => {
				const relativeDetailSize = detailSize * CameraFovTan2 / Math.abs(depth);
				if (relativeDetailSize < MinDetailSize * 0.2) {
					return LodMask.Lod2;
				}
				if (relativeDetailSize < MinDetailSize) {
					return LodMask.Lod1;
				}
				return LodMask.Lod0;
			}
		} else {
			throw new Error('invalid camera type');
		}

		const lodGroupRefs = this._lodGroupsRef.submeshesRefs;
		{
			let lodGroupsCulled: number;
			const submeshesCulledLengthBeforeGroupsCulling = submeshesIndicesCulled.length / 2;
			const lodIndicesCulledList = lodsCulledList.current_result_temp_view();
			const lodsCulledCount = lodIndicesCulledList.length / 2;

			let lodNextIndex = lodsCountBefore;
			lodGroupsCulled = lodsCulledCount - lodNextIndex;
			for (; lodNextIndex < lodsCulledCount; ++lodNextIndex) {
				const lodIndex = lodIndicesCulledList[lodNextIndex * 2];
				const depth = lodIndicesCulledList[lodNextIndex * 2 + 1] * toRealDepthMultiplier;

				// const groupLod: LodMask =  
				const groupRefs = lodGroupRefs[lodIndex];
				if (groupRefs.length > 1) {
					const groupInfo = groupRefs[0] as LodGroupLocalIdent;
					const lodGroupDetailSize = getLodGroupDetailSize(groupInfo);
					const groupLod = lodCalcutor(lodGroupDetailSize, depth);

					for (let i = 1; i < groupRefs.length; ++i) {
						const submeshRef = groupRefs[i] as LodGroupSubmeshRef;
						const submeshIndex = submeshIndexFromSubmeshLodRef(submeshRef);
						const submeshLodMask = lodMaskFromSubmeshLodRef(submeshRef);

						if (submeshLodMask & groupLod) {
							submeshesIndicesCulled.push(newIndexLod(submeshIndex, groupLod));
							submeshesIndicesCulled.push(depth);
						}
					}
				} else {
					console.warn('empty lod refs group', groupRefs);
				}
			}
			const newSubmeshesCulledFromGroups = (submeshesIndicesCulled.length / 2) - submeshesCulledLengthBeforeGroupsCulling;
			if (newSubmeshesCulledFromGroups > 0 && lodsCulledCount > 0) {
				const submeshesPerLodGroupRatio = newSubmeshesCulledFromGroups / lodsCulledCount;
				this._updateSubmeshesPerLodGroup(submeshesPerLodGroupRatio);
				// console.log(`culling submeshes per lod ratio: ${submeshesPerLodGroupRatio}, requestedSubmeshes: ${maxCount}, culled=${newSubmeshesCulledFromGroups}`);
			}

		}

		const renderListCulledResult = new TypedNumbersVec((size) => new Uint32Array(size), maxCount * 2);
		// int32 -> uint32
		// int32 depth will get converted into 2 complementary representation, in case of negative numbers

		
		const tuplesLength = submeshesIndicesCulled.length / 2;
		const indicesCulledList = submeshesIndicesCulled.buffer as Int32Array;
		const renderListsFlags = flagsPerIndex.buffer;

		if (reverseOrder) {
			nextIndex = tuplesLength - nextIndex;
			for (; nextIndex >= 0 && renderListCulledResult.length < maxCount * 2; --nextIndex) {
				const indexLod = indicesCulledList[nextIndex * 2];
				const depth = indicesCulledList[nextIndex * 2 + 1];
				if (renderListsFlags[indexLod & 0x0FFFFFFF] & renderListFlag) {
					renderListCulledResult.push2(indexLod, depth * -1);
				}
			}
			nextIndex = tuplesLength - nextIndex;
		} else {
			for (; nextIndex < tuplesLength && renderListCulledResult.length < maxCount * 2; ++nextIndex) {
				const indexLod = indicesCulledList[nextIndex * 2];
				const depth = indicesCulledList[nextIndex * 2 + 1];

				if (renderListsFlags[indexLod & 0x0FFFFFFF] & renderListFlag) {
					renderListCulledResult.push2(indexLod, depth);
				}
			}
		}

		return {indices: new CulledIndices(renderListCulledResult), nextIndex}
	}

}


export class MouseRayCone {

	constructor(
		public raySection: RaySection,
		public coneRadius0: number,
		public coneRadius1: number
	) {
	}


	static newForInCameraPos(camera: KrCamera, mousePos: GesturesMousePos): MouseRayCone | null {
		const glCoords = mousePos.posNormalizedGlCoords();
		const ray = RaySection.fromCameraMousePosHtml(camera, glCoords);
		const MaxGizmoExtensionsGlSpace = 0.1;
		const ray2 = RaySection.fromCameraMousePosHtml(
			camera,
			glCoords.clone().addScaledVector(glCoords.clone().multiplyScalar(-1), MaxGizmoExtensionsGlSpace)
		);
		if (!ray || !ray2) {
			return null;
		}		
		const coneTipRadius0m = ray.ray.origin.distanceTo(ray2.ray.origin);
		const cone1mRadius1m = ray.ray.at(1, new Vector3()).distanceTo(ray2.ray.at(1, new Vector3));
		return new MouseRayCone(ray, coneTipRadius0m, cone1mRadius1m);
	}
}

export class ConeCastResults {
	constructor(
		public readonly raw: Float64Array
	) {

	}
}


export type IndexLod = number & ProgressiveLoddedList;

export function newIndexLod(index: number, lod: LodMask) {
	// attention: bitmasks are different from rust indexLod legacy code
	return (index & 0x0FFFFFFF) | ((lod & 0xF) << 28);
}

export class CulledIndices {
	vec: TypedNumbersVec<Uint32Array>;

	constructor(vec: TypedNumbersVec<Uint32Array>) {
		this.vec = vec;
		LegacyLogger.debugAssert(this.vec.length % 2 == 0, 'culled indices sanity check, should be multiple of 2');
	}

	iter(depthPrecition: DepthPrecision, fn: (index: number, lod: LodMask, depth: number) => void) {
		const inds = this.vec.buffer as Uint32Array;
		LegacyLogger.debugAssert(inds instanceof Uint32Array, 'santiy check culled inds raw array type', inds.buffer);
		let depthPow: number;
		switch (depthPrecition) {
			case DepthPrecision.Max: depthPow = 1; break;
			case DepthPrecision.High: depthPow = 0.4; break;
			case DepthPrecision.Low: depthPow = 0.1; break;
		}

		for (let i = 0, il = this.vec.length; i < il; i += 2) {
			const indexLod = inds[i];
			const depthInital = inds[i + 1];
			const depthSign = Math.sign(depthInital);
			const depth = depthSign * Math.pow(Math.abs(depthInital), depthPow) | 0;
			const index = indexLod & 0xFFFFFFF;
			const lod = (indexLod & 0xF0000000) >> 28;
			fn(index, lod, depth);
		}
	}

	len() {
		return this.vec.length / 2;
	}
}

export enum DepthPrecision {
	Max,
	High,
	Low
}

