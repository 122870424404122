

import { Vector3 } from 'math-ts';
import { DoubleSide } from '../3rdParty/three';

import type { ShaderBase } from './ShaderBase';

export const SpatialShader: ShaderBase = {

	name: 'Spatial',

	params: {
		transparent: true,
		depthTest: true,
		depthWrite: false,
		side: DoubleSide,
	},
	
	uniforms: {
		'alphaPerAxis': { value: new Vector3(0.5, 0.5, 0.5) },
	},

	defines: {
	},

vertexShader: `

#include <kr_std_uniforms>
#include <kr_std_attributes>
#include <kr_std_vars>

void main() {
	#include <kr_std_vars_calc>
}

`,

fragmentShader: `
	
uniform vec3 alphaPerAxis;

#include <kr_std_uniforms>
#include <kr_std_vars>


void main() {

	#include <kr_std_pixel>

	vec3 fdx = vec3( dFdx( vWorldPosition.x ), dFdx( vWorldPosition.y ), dFdx( vWorldPosition.z ) );
	vec3 fdy = vec3( dFdy( vWorldPosition.x ), dFdy( vWorldPosition.y ), dFdy( vWorldPosition.z ) );
	
    vec3 blendWeights = abs(cross( fdx, fdy ));
    blendWeights = blendWeights / (blendWeights.x + blendWeights.y + blendWeights.z);
	
	float alpha = dot(alphaPerAxis, blendWeights); 
	
    vec4 resultColor = vec4(colorTint.xyz, alpha);

    if (resultColor.a < 0.01) {
        discard;
    }
    gl_FragColor = resultColor;

}
`
};