import type { Bim, EntitiesCollectionUpdates, IdBimScene} from "bim-ts";
import { EntitiesUpdated, SceneObjDiff, handleEntitiesUpdates } from "bim-ts";
import type { ObservableObject, ScopedLogger} from "engine-utils-ts";
import { StreamAccumulator } from "engine-utils-ts";
import type { ESOsCollection } from "../scene/ESOsCollection";
import type { AnnotationsSettings } from "./AnnotationsSettingsUiBindings";


const relevantObjDiffs = SceneObjDiff.Representation
	| SceneObjDiff.GeometryReferenced
	| SceneObjDiff.WorldPosition
	| SceneObjDiff.LegacyProps
	| SceneObjDiff.NewProps
	| SceneObjDiff.SpatialParentRef
	| SceneObjDiff.SpatialDescendants;


function annotationUpdatesFilter(update: EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>): boolean {
	return !(update instanceof EntitiesUpdated &&
		(update.allFlagsCombined & relevantObjDiffs) === 0);
}


export class BimAnnotationsSyncer {
	readonly _bim: Bim;

	readonly _logger: ScopedLogger;
    readonly _bimInstancesUpdatesAccumulator: StreamAccumulator<EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>>;
	private _engineSceneObjects: ESOsCollection;
	private _settingsObservable: ObservableObject<AnnotationsSettings>;

	private lastUpdatesCount: number = 0;
	private framesWithoutUpdates: number = 0;
	private timeOfLastUpdate: number = new Date().getTime();
	private oldSettings: AnnotationsSettings = { showAnnotations: true };

	constructor(
		logger: ScopedLogger,
		bim: Bim,
		engineSceneObjects: ESOsCollection,
		annotationSettings: ObservableObject<AnnotationsSettings>
	) {
		this._logger = logger.newScope('annotations-bim-synced');
		this._bim = bim;
		this._engineSceneObjects = engineSceneObjects;
        this._bimInstancesUpdatesAccumulator = new StreamAccumulator(
			bim.instances.updatesStream,
			annotationUpdatesFilter
		);
		this._settingsObservable = annotationSettings;
	}

    dispose() {
        this._bimInstancesUpdatesAccumulator.dispose();
    }

	syncToBim() {
		const bimUpdates = this._bimInstancesUpdatesAccumulator._events;
		if ((bimUpdates === undefined || bimUpdates.length === 0) &&
			Object.is(this._settingsObservable.currentValue(), this.oldSettings)
		) {
			return;
		}
		
		if (bimUpdates.length > this.lastUpdatesCount) {
			this.lastUpdatesCount = bimUpdates.length;
			this.timeOfLastUpdate = new Date().getTime();
			return;
		} else {
			++this.framesWithoutUpdates;
		}
		
		if (this.framesWithoutUpdates > 2 && new Date().getTime() - this.timeOfLastUpdate > 300) {
			this.framesWithoutUpdates = 0;
			this.lastUpdatesCount = 0;

			this._bimInstancesUpdatesAccumulator.consume();
			handleEntitiesUpdates(
				bimUpdates,
				(allocatedIds) => {
					this._engineSceneObjects.markAllocated(allocatedIds);
				},
				(perIdDiffs) => {
					this._engineSceneObjects.markUpdated(perIdDiffs);
				},
				(removed) => {
					this._engineSceneObjects.markDeleted(removed);
				}
			);
			this._engineSceneObjects.updateAnnotations();
		}
    }
}

