
export class FetchUtils {

	static generateUniqueUrl(prefix: string, postfix?: string): string {
		const r = FetchUtils.combineURLs(prefix, FetchUtils.generateGuid());
		if (!postfix) {
			return r; 
		}
		return r + postfix;
	}

    static combineURLs(baseURL: string, relativeURL:string): string {
		return relativeURL
		  ? baseURL.replace(/\/+$/, '') + '/' + relativeURL.replace(/^\/+/, '')
		  : baseURL;
	};

	static generateGuid(): string {
		const lut = _lut || (_lut = generateLut());
		crypto.getRandomValues(_randomValues);
		const d0 = _randomValues[0];
		const d1 = _randomValues[1];
		const d2 = _randomValues[2];
		const d3 = _randomValues[3];
		return lut[d0&0xff]+lut[d0>>8&0xff]+lut[d0>>16&0xff]+lut[d0>>24&0xff]+'-'+
			lut[d1&0xff]+lut[d1>>8&0xff]+'-'+lut[d1>>16&0x0f|0x40]+lut[d1>>24&0xff]+'-'+
			lut[d2&0x3f|0x80]+lut[d2>>8&0xff]+'-'+lut[d2>>16&0xff]+lut[d2>>24&0xff]+
			lut[d3&0xff]+lut[d3>>8&0xff]+lut[d3>>16&0xff]+lut[d3>>24&0xff];
	}

	static newImageFromSrcUrl(url: string): HTMLImageElement {
		const image = new Image();
		if (url) {
			image.src = url;
		}
		return image;
	}

    /**
     * @returns
     * Constructs
     * `?key1=value1&key2=value2`
     * from
     * `{ "key1": "value1", "key2": "value2" }`
     */
    static createQueryStringFromObject(obj: object) {
        const components: string[] = [];
        for (let [key, val] of Object.entries(obj)) {
            if (!['number', 'string', 'boolean'].includes(typeof val)) {
                continue;
            }
            components.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
        }
        if (components.length === 0) {
            return '';
        }
        const encodedQueryString = '?' + components.join('&');
        return encodedQueryString;
    }

    /**
     * @returns
     * Constructs
     * `{ "key1": "value1", "key2": "value2" }`
     * from
     * `?key1=value1&key2=value2`
     */
    static createObjectFromQueryString(queryStr: string): {[key in string]: string} {
        const result: { [key in string]: string } = {}
        if (queryStr[0] === '?')
            queryStr = queryStr.slice(1)
        const pairs = queryStr.split('&')
        for (const pair of pairs) {
            const components = pair.split('=');
            if (components.length !== 2)
                continue
            const [key, value] = components.map(decodeURIComponent);
            result[key] = value;
        }
        return result;
    }
}

const _randomValues = new Uint32Array(4);
let _lut: string[] | null = null;
function generateLut() {
	const lut = [];
	for (var i = 0; i < 256; i++) {
		lut[i] = (i < 16 ? '0' : '') + (i).toString(16);
	}
	return lut;        
};
