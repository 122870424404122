import { ProjectNetworkClient } from "engine-utils-ts";
import type { TemplateDescription } from "./Models";

export interface TemplateGetReply {
    global: TemplateDescription[];
    company: TemplateDescription[];
}

export class TemplatesStorageApi {
    network: ProjectNetworkClient;
    constructor(network: ProjectNetworkClient) {
        this.network = new ProjectNetworkClient({
            ...network.config,
            basePath: 'api/templates'
        });
    }
    async saveTemplate(template: TemplateDescription) {
        const formData = new FormData();
        formData.append(
            "file",
            new Blob([JSON.stringify(template)]),
            this.getFileName(template)
        );
        await this.network.postFormData('', formData);
    }

    async getAllTemplates() {
        const response = await this.network.get('');
        const json: TemplateGetReply = await response.json();
        return json; 
    }

    async deleteTemplate(template: TemplateDescription) {
        await this.network.delete('file/' + this.getFileName(template));
    }

    getFileName(template:TemplateDescription){
        return `${template.id}.json`;
    }

}