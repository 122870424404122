import { Vector2 } from 'math-ts';
import type { ShaderBase } from '../shaders/ShaderBase';

export const ESO_SceneImageShader: ShaderBase = Object.freeze({
	
	name: 'scene_image',
    
    defines: {
		// 'NEEDS_NORMALS': true
    },
    
	uniforms:
	{
		opacity: { value: new Vector2(1, 1) },
		image: { value: null },
	}
	,

	vertexShader:
	`

	#include <kr_std_attributes>
	#include <kr_std_uniforms>
	#include <kr_std_vars>

	#include <common>
	
	void main() {
		#include <kr_std_vars_calc>
	}
	`,

	fragmentShader:
	
	`

	#include <common>
	#include <packing>

	#include <color_pars_fragment>
	#include <map_pars_fragment>
	
	#include <kr_std_uniforms>
	#include <kr_std_vars>

	#include <normals_packing>

	uniform vec2 opacity;
	uniform sampler2D image;


	#if defined(MRT_NORMALS)
		layout(location = 1) out lowp vec4 fragData1;
	#endif


	void main() {
		#include <kr_std_pixel>

		gl_FragColor = texture2D( image, vec2(vUv.x, vUv.y) );

		#ifdef MRT_NORMALS 
			fragData1 = vec4(1.0);//vec4( encode_normal( normal ),  1.0, 1.0 );
		#endif

	}
	`,
});
