// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class PropertyMultiSelectionValue implements flatbuffers.IUnpackableObject<PropertyMultiSelectionValueT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):PropertyMultiSelectionValue {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsPropertyMultiSelectionValue(bb:flatbuffers.ByteBuffer, obj?:PropertyMultiSelectionValue):PropertyMultiSelectionValue {
  return (obj || new PropertyMultiSelectionValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsPropertyMultiSelectionValue(bb:flatbuffers.ByteBuffer, obj?:PropertyMultiSelectionValue):PropertyMultiSelectionValue {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new PropertyMultiSelectionValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value(index: number):string
value(index: number,optionalEncoding:flatbuffers.Encoding):string|Uint8Array
value(index: number,optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
}

valueLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

options(index: number):string
options(index: number,optionalEncoding:flatbuffers.Encoding):string|Uint8Array
options(index: number,optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
}

optionsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

maxSelect():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

static startPropertyMultiSelectionValue(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, valueOffset, 0);
}

static createValueVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startValueVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addOptions(builder:flatbuffers.Builder, optionsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, optionsOffset, 0);
}

static createOptionsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startOptionsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addMaxSelect(builder:flatbuffers.Builder, maxSelect:number) {
  builder.addFieldInt32(2, maxSelect, 0);
}

static endPropertyMultiSelectionValue(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createPropertyMultiSelectionValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset, optionsOffset:flatbuffers.Offset, maxSelect:number):flatbuffers.Offset {
  PropertyMultiSelectionValue.startPropertyMultiSelectionValue(builder);
  PropertyMultiSelectionValue.addValue(builder, valueOffset);
  PropertyMultiSelectionValue.addOptions(builder, optionsOffset);
  PropertyMultiSelectionValue.addMaxSelect(builder, maxSelect);
  return PropertyMultiSelectionValue.endPropertyMultiSelectionValue(builder);
}

unpack(): PropertyMultiSelectionValueT {
  return new PropertyMultiSelectionValueT(
    this.bb!.createScalarList<string>(this.value.bind(this), this.valueLength()),
    this.bb!.createScalarList<string>(this.options.bind(this), this.optionsLength()),
    this.maxSelect()
  );
}


unpackTo(_o: PropertyMultiSelectionValueT): void {
  _o.value = this.bb!.createScalarList<string>(this.value.bind(this), this.valueLength());
  _o.options = this.bb!.createScalarList<string>(this.options.bind(this), this.optionsLength());
  _o.maxSelect = this.maxSelect();
}
}

export class PropertyMultiSelectionValueT implements flatbuffers.IGeneratedObject {
constructor(
  public value: (string)[] = [],
  public options: (string)[] = [],
  public maxSelect: number = 0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const value = PropertyMultiSelectionValue.createValueVector(builder, builder.createObjectOffsetList(this.value));
  const options = PropertyMultiSelectionValue.createOptionsVector(builder, builder.createObjectOffsetList(this.options));

  return PropertyMultiSelectionValue.createPropertyMultiSelectionValue(builder,
    value,
    options,
    this.maxSelect
  );
}
}
