import type { BimGeometryType, IrregularHeightmapGeometries} from "bim-ts";
import { IrregularHeightmapGeometry } from "bim-ts";
import { Success, type ScopedLogger} from "engine-utils-ts";
import type { Result } from "engine-utils-ts";
import { BimEngineGeometriesSyncBase } from "../resources/BimGeometriesSync";
import { EngineGeoType } from "./AllEngineGeometries";
import { EngineBimGeometry } from "./EngineGeometry";
import type { GeometryGpuRepr } from "./KrBufferGeometry";
import type { EntityId } from "verdata-ts";
import { CreateKrGeometryFromData } from "./GeometryUtils";


export class EngineGeoTerrainIrregular extends EngineBimGeometry<IrregularHeightmapGeometry> {

    constructor(
        source: IrregularHeightmapGeometry = new IrregularHeightmapGeometry(),
    ) {
        super(source);
    }

	_calcGpuRepr(): GeometryGpuRepr {
        return CreateKrGeometryFromData({
            indices: this.bimGeo.trianglesIndices,
            edgesInds: undefined,
            normals: undefined,
            vertices: new Float32Array(this.bimGeo.points3d),
            uvs: undefined,
        });
	}

    // *snappingEdges(): IterableIterator<[Vector3, Vector3]> {
    //     const points: [Vector3, Vector3] = [Vector3.zero(), Vector3.zero()];
    //     for (let i = 1; i < this.sourceCube.points.length; ++i) {
    //         points[0].copy(this.sourceCube.points[i - 1]);
    //         points[1].copy(this.sourceCube.points[i - 0]);
    //         yield points;
    //     }
    // }
    // intersectPlanes(planes: Plane[]): IntersectionType {
    //     return GeometryUtils.checkPlanesWithCubePointsIntersection(this.sourceCube.points, planes);
    // }
}


export class EngineTerrainGeosIrregSynced
    extends BimEngineGeometriesSyncBase<EngineGeoTerrainIrregular, IrregularHeightmapGeometry> {

    constructor(
        logger: ScopedLogger,
        bimGeos: IrregularHeightmapGeometries,
    ) {
        super(bimGeos, {
            identifier: 'engine-terrain-geos',
            idsType: EngineGeoType.TerrainIrregular,
            T_Constructor: EngineGeoTerrainIrregular,
        });
    }

    checkForErrors(t: EngineGeoTerrainIrregular): boolean {
        return true;
    }

    convertFromBim(bimObj: IrregularHeightmapGeometry, id: EntityId<BimGeometryType>): Result<EngineGeoTerrainIrregular> {
        return new Success(new EngineGeoTerrainIrregular(bimObj));
    }
}

