<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V6H20C21.1046 6 22 6.89543 22 8V13H15C15 11.8954 14.1046 11 13 11H11C9.89543 11 9 11.8954 9 13H2V8C2 6.89543 2.89543 6 4 6H8V4ZM14 4V6H10V4H14Z"
        fill="currentColor"
    />
    <path
        d="M2 15V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V15H15C15 16.1046 14.1046 17 13 17H11C9.89543 17 9 16.1046 9 15H2Z"
        fill="currentColor"
    />
    <path d="M11 13H13V15H11V13Z" fill="currentColor" />
</svg>
