import { BasicSpreadsheetCellsProperty, TMY_Props, TMY_ColumnDates, TMY_ColumnGeneral, TMY_ColumnHeader, type Bim, StringProperty } from 'bim-ts';
import { IterUtils, Result, StringUtils, type ScopedLogger, Failure, Success, LogLevel } from 'engine-utils-ts';
import Papa from 'papaparse';
import { tryReadMeteoLocationData } from './TMY_ParserUtils';


//https://designbuilder.co.uk/cahelp/Content/EnergyPlusWeatherFileFormat.htm

export class MeteoParsingResult {
    errors: string[] = [];
    warnings: string[] = [];
    data: TMY_Props | null = null;
}

export function parseMeteoEDW(args: {
    logger: ScopedLogger,
    fileName: string,
    fileContent: string,
}): MeteoParsingResult {
    
    const logger = args.logger;

    const result = new MeteoParsingResult();

    const parsedAsCsv = Papa.parse<string[]>(args.fileContent);

    console.warn('AS CSV', parsedAsCsv);

    logger.debug('parsedAsCsv', parsedAsCsv);

    const DatesStartRow = 8;
    const filePrefixMetadata: string[][] = parsedAsCsv.data.slice(0, DatesStartRow);

    console.warn('filePrefixMetadata', filePrefixMetadata);

    const dataRowsRaw = parsedAsCsv.data.slice(DatesStartRow);

    if (dataRowsRaw.length < 365*24) {
        result.errors.push('not enough data rows');
        return result;
    }
    if (dataRowsRaw.length > 365*24) {
        const removed = dataRowsRaw.splice(365*24).map(row => row.some(s => s));
        if (removed.length) {
            result.errors.push(`removed ${removed.length} rows`);
        }
    }

    let datesColumn: TMY_ColumnDates;
    let dataRowsSorted: string[][];
    {
        const dates: Date[] = [];
        for (const row of dataRowsRaw) {
            const dateResult = parseDateFromRow(row);
            if (dateResult instanceof Failure) {
                result.errors.push(dateResult.toString());
                return result;
            }
            dates.push(dateResult.value);
        }

        // now make sure hours are consecutive 0 - 23
        {
            for (let i = 1; i < dates.length; ++i) {
                const prevDate = dates[i - 1];
                const date = dates[i];
                if (date.getUTCHours() % 24 !== (prevDate.getUTCHours() + 1) % 24) {
                    result.errors.push(`hours in rows ${i} and ${i-1} are not consecutive`);
                }
            }
        }

        const rowsPerDate: [Date, string[]][] = IterUtils.map2(dates, dataRowsRaw, (date, row) => [date, row]);
        // sometimes data doesnt start at the jan1 00:00
        // find the first row with that date, and splice data to make it aligned
        {
            const jan1_00_00_row_index = rowsPerDate.findIndex(([date]) => {
                const month = date.getUTCMonth();
                const day = date.getUTCDate();
                const hour = date.getUTCHours();
                return month == 0 && day === 1 && hour === 0;
            });

            if (jan1_00_00_row_index === -1) {
                result.errors.push('failed to find jan1 00:00 row');
            } else {
                logger.info('found jan1 00:00 row', jan1_00_00_row_index);
                // const rowsBefore = rowsPerDate.slice(0, jan1_00_00_row_index);
                // const rowsAfter = rowsPerDate.slice(jan1_00_00_row_index);
                // rowsPerDate.splice(0, rowsPerDate.length, ...rowsAfter, ...rowsBefore);

                // const firstDate = rowsPerDate[0][0];
                // logger.assert(
                //     firstDate.getUTCMonth() == 0
                //     && firstDate.getUTCDate() == 1
                //     && firstDate.getUTCHours() == 0
                //     , 'first date is jan1 00:00'
                // );
            }
        }
        logger.debug('rowsPerDate', rowsPerDate);
        // we should have all the data rows in correct order now
        dataRowsSorted = rowsPerDate.map(([_date, row]) => row);

        if (dataRowsSorted.length > 365*24) {
            result.warnings.push(`trimmed ${dataRowsSorted.length - 365*24} rows to have exactly 365 days`);
            dataRowsSorted.splice(365*24);
        }

        datesColumn = new TMY_ColumnDates(
            new TMY_ColumnHeader({name: 'Date/Time', raw: ['Date/Time']}),
            new Float64Array(dates.map(d => d.valueOf())),
        )
        
    }

    
    const columns: TMY_ColumnGeneral[] = [];
    const headerWarnings: TMY_ColumnHeader[] = [];
    for (const columntDescr of allEpwColumnDescriptions) {

        const columnData = dataRowsSorted.map(row => row[columntDescr.columnIndex]);

        const parsedData: (number | string)[] = columnData.map(gridValue => {
            const asNumber = parseFloat(gridValue);
            if (!Number.isFinite(asNumber)) {
                return gridValue;
            }
            if (asNumber == columntDescr.missingValue) {
                return NaN;
            }
            const value = columntDescr.valueTransformer ? columntDescr.valueTransformer(asNumber) : asNumber;
            return value;
        });

        const header = new TMY_ColumnHeader({
            name: columntDescr.name,
            unit: columntDescr.unit,
            raw: [columntDescr.name, columntDescr.unit].filter(s => s != undefined) as string[],
        });

        const column = TMY_ColumnGeneral.newFromValues(header, parsedData);
        if (column.valuesPalette.length < 3) {
            headerWarnings.push(header);
            continue;
        }
        columns.push(column);
    }
    if (headerWarnings.length) {
        const headers = headerWarnings.map(h => `${h.name} ${h.toHeaderString()}`).join(', ');
        result.warnings.push(`Columns [${headers}] have too few unique values`);
    }

    result.data = new TMY_Props({
        fileName: StringProperty.new({isReadonly: true, value: args.fileName}),
        prefixMetadata: new BasicSpreadsheetCellsProperty(
            filePrefixMetadata.map(row => row.map(StringUtils.tryParseAsNumberIfExact)),
        ),
        datesColumns: [datesColumn],
        dataColumns: columns,
        locationContext: tryReadMeteoLocationData({
            lat: filePrefixMetadata[0][6],
            long: filePrefixMetadata[0][7],
            timeZone: filePrefixMetadata[0][8],
            alt: filePrefixMetadata[0][9],
        })
    });
    return result;
}


function parseDateFromRow(row: string[]): Result<Date> {
    const year = parseInt(row[0]);
    const month = parseInt(row[1]) // 1 - 12
    const day = parseInt(row[2]) // 1 - 31
    const hour = parseInt(row[3]) // 1 - 24
    // const minute = parseInt(row[4]); // 0 - 60

    if (!Number.isFinite(year) || year < 1900) {
        return new Failure({msg: 'invalid year ' + year})
    }
    if (!Number.isFinite(month) || month < 1 || month > 12) {
        return new Failure({msg: 'invalid day ' + day})
    }
    if (!Number.isFinite(day) || day < 1 || day > 31) {
        return new Failure({msg: 'invalid day ' + day})
    }
    if (!Number.isFinite(hour) || hour < 1 || hour > 24) {
        return new Failure({msg: 'invalid hour ' + hour})
    }
    const utcDate = Date.UTC(
        year,
        month - 1,
        day,
        hour - 1,
    );
    return new Success(new Date(utcDate));
}


interface EpwColumnDescription {
    shortName?: string;
    name?: string;
    unit?: string;
    columnIndex: number;
    missingValue?: number;
    validRange?: [number, number];
    valueTransformer?: (value: number) => number;
}

const DryBulbTempColumnDescription: EpwColumnDescription = {
    name: 'Dry Bulb Temperature',
    unit: 'C',
    columnIndex: 6,
    missingValue: 99.9,
    validRange: [-70, 70],
}

const DewPointTempColumnDescription: EpwColumnDescription = {
    name: 'Dew Point Temperature',
    unit: 'C',
    columnIndex: 7,
    missingValue: 99.9,
    validRange: [-70, 70],
}

const RelativeHumidityColumnDescription: EpwColumnDescription = {
    name: 'Relative Humidity',
    unit: '%',
    columnIndex: 8,
    missingValue: 999,
    validRange: [0, 110],
}

const AtmosphericStationPressureColumnDescription: EpwColumnDescription = {
    name: 'Atmospheric Station Pressure',
    unit: 'Pa',
    columnIndex: 9,
    missingValue: 999999,
    validRange: [31000, 120000],
}

const ExtraterrestrialHorizontalRadiationColumnDescription: EpwColumnDescription = {
    name: 'Extraterrestrial Horizontal Radiation',
    unit: 'Wh/m2',
    columnIndex: 10,
    missingValue: 9999,
}

const ExtraterrestrialDirectNormalRadiationColumnDescription: EpwColumnDescription = {
    name: 'Extraterrestrial Direct Normal Radiation',
    unit: 'Wh/m2',
    columnIndex: 11,
    missingValue: 9999,
}

const HorizontalInfraredRadiationIntensityColumnDescription: EpwColumnDescription = {
    name: 'Horizontal Infrared Radiation Intensity',
    unit: 'Wh/m2',
    columnIndex: 12,
    missingValue: 9999,
}

const GlobalHorizontalRadiationColumnDescription: EpwColumnDescription = {
    name: 'Global Horizontal Radiation',
    unit: 'Wh/m2',
    columnIndex: 13,
    missingValue: 9999,
}

const DirectNormalRadiationColumnDescription: EpwColumnDescription = {
    name: 'Direct Normal Radiation',
    unit: 'Wh/m2',
    columnIndex: 14,
    missingValue: 9999,
}

const DiffuseHorizontalRadiationColumnDescription: EpwColumnDescription = {
    name: 'Diffuse Horizontal Radiation',
    unit: 'Wh/m2',
    columnIndex: 15,
    missingValue: 9999,
}

const GlobalHorizontalIlluminanceColumnDescription: EpwColumnDescription = {
    name: 'Global Horizontal Illuminance',
    unit: 'lux',
    columnIndex: 16,
    missingValue: 999999,

}

const DirectNormalIlluminanceColumnDescription: EpwColumnDescription = {
    name: 'Direct Normal Illuminance',
    unit: 'lux',
    columnIndex: 17,
    missingValue: 999999,
}

const DiffuseHorizontalIlluminanceColumnDescription: EpwColumnDescription = {
    name: 'Diffuse Horizontal Illuminance',
    unit: 'lux',
    columnIndex: 18,
    missingValue: 999999,
}

const ZenithLuminanceColumnDescription: EpwColumnDescription = {
    name: 'Zenith Luminance',
    unit: 'Cd/m2',
    columnIndex: 19,
    missingValue: 9999,
}

const WindDirectionColumnDescription: EpwColumnDescription = { 
    name: 'Wind Direction', //North = 0.0, East = 90.0, South = 180.0, West = 270.0.
    unit: '°',
    columnIndex: 20,
    missingValue: 999,
    validRange: [0, 360],
}

const WindSpeedColumnDescription: EpwColumnDescription = {
    name: 'Wind Speed',
    unit: 'm/s',
    columnIndex: 21,
    missingValue: 999,
    validRange: [0, 40],
}

const TotalSkyCoverColumnDescription: EpwColumnDescription = {
    name: 'Total Sky Cover',
    unit: '%',
    columnIndex: 22,
    missingValue: 99,
    valueTransformer: (valueInTenths: number) => {
        return valueInTenths * 10;
    }
}

const OpaqueSkyCoverColumnDescription: EpwColumnDescription = {
    name: 'Opaque Sky Cover',
    unit: '%',
    columnIndex: 23,
    missingValue: 99,
    valueTransformer: (valueInTenths: number) => {
        return valueInTenths * 10;
    }
}

const VisibilityColumnDescription: EpwColumnDescription = {
    name: 'Visibility',
    unit: 'km',
    columnIndex: 24,
    missingValue: 9999,
}

const PrecipitableWaterDescription: EpwColumnDescription = {
    name: 'Precipitable Water',
    unit: 'mm',
    columnIndex: 28,
    missingValue: 999,
}

const AerosolOpticalDepthColumnDescription: EpwColumnDescription = {
    name: 'Aerosol Optical Depth',
    columnIndex: 29,
    missingValue: 999,
}

const SnowDepthColumnDescription: EpwColumnDescription = {
    name: 'Snow Depth',
    unit: 'cm',
    columnIndex: 30,
    missingValue: 999,
}

const AlbedoColumnDescription: EpwColumnDescription = {
    name: 'Albedo',
    columnIndex: 32,
}

const LiquidPrecipitationDepthColumnDescription: EpwColumnDescription = {
    name: 'Liquid Precipitation Depth',
    unit: 'mm',
    columnIndex: 33,
}

const allEpwColumnDescriptions: EpwColumnDescription[] = [
    DryBulbTempColumnDescription,
    DewPointTempColumnDescription,
    RelativeHumidityColumnDescription,
    AtmosphericStationPressureColumnDescription,
    ExtraterrestrialHorizontalRadiationColumnDescription,
    ExtraterrestrialDirectNormalRadiationColumnDescription,
    HorizontalInfraredRadiationIntensityColumnDescription,
    GlobalHorizontalRadiationColumnDescription,
    DirectNormalRadiationColumnDescription,
    DiffuseHorizontalRadiationColumnDescription,
    GlobalHorizontalIlluminanceColumnDescription,
    DirectNormalIlluminanceColumnDescription,
    DiffuseHorizontalIlluminanceColumnDescription,
    ZenithLuminanceColumnDescription,
    WindDirectionColumnDescription,
    WindSpeedColumnDescription,
    TotalSkyCoverColumnDescription,
    OpaqueSkyCoverColumnDescription,
    VisibilityColumnDescription,
    PrecipitableWaterDescription,
    AerosolOpticalDepthColumnDescription,
    SnowDepthColumnDescription,
    AlbedoColumnDescription,
    LiquidPrecipitationDepthColumnDescription,
];
