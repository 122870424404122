import { RGBA } from 'engine-utils-ts';
import { KrMath, Vector4 } from 'math-ts';

// export const AnimationColors : [Color, number][] = [
// 	[new Color('#00ff00').multiplyScalar(/*brightness*/ 0.35), 0.9],
// 	[new Color('#0066ee').multiplyScalar(/*brightness*/ 0.50), 0.9],
// 	[new Color('#5500ff').multiplyScalar(/*brightness*/ 0.31), 0.9],
// 	[new Color('#ff0000').multiplyScalar(/*brightness*/ 0.40), 0.9],
// ]

export const CameraFOVSum = 140;
export const CameraMaxFOV = 110;
export const ScreenshotCameraFOV = 50;
export const CameraProjChangeTime = 0.25;

export const CameraRotationSpeed3rdPerson = 0.5;
export const CameraRotationSpeed1stPerson = 0.5;

export const FlyingMaxSpeed = 4;
export const FlyingStartSpeed = 1;
export const FlyingAccelSpeed = 1;
export const ShiftSpeedMultiplier = 2;
export const PersonHeight = 1.7;

export const TeleportGroundGizmoDiameter = 1;
export const TeleportNotGroundGizmoDiameter = 0.3;

export const DefaultBackgroundColor = 0xF0F1F3;
export const DefaultGhostColor = '#000000';

export const BetweenWorksColorMultiply = 0.4;

export const SectionBoxColor = RGBA.new(0.9, 0.9, 0.9, 0.9);

export const TeleportRingColor = RGBA.new(1, 1, 1, 1);

export const ColorTintPower = 0.85;

export const FocusDurationMultiplier = 0.7;

export const MinOrthoSize = 1;

export const ClipboxMoveDurMult = 0.07;

export const HomeCameraPhiOrtho = KrMath.degToRad(75);
export const HomeCameraPhiPersp = KrMath.degToRad(100);
export const HomeCameraTheta =  KrMath.degToRad(-45);
// export const DefaultControlsTheta =  _Math.degToRad(-45);


export const MaxScreenshotSideSize = 8192;
export const MinScreenshotSideSize = 32;

export const GammaFactor = 2.0;

export const BrighGizmoColor = Vector4.allocate(0.95, 0.45, 0.0, 0.8);

