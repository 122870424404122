// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { CompressionType } from '../wire/compression-type.js';


export class CompressedIntegers {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):CompressedIntegers {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsCompressedIntegers(bb:flatbuffers.ByteBuffer, obj?:CompressedIntegers):CompressedIntegers {
  return (obj || new CompressedIntegers()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsCompressedIntegers(bb:flatbuffers.ByteBuffer, obj?:CompressedIntegers):CompressedIntegers {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new CompressedIntegers()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

compression():CompressionType {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : CompressionType.None;
}

payload(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index) : 0;
}

payloadLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

payloadArray():Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? new Uint8Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

static startCompressedIntegers(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addCompression(builder:flatbuffers.Builder, compression:CompressionType) {
  builder.addFieldInt32(0, compression, CompressionType.None);
}

static addPayload(builder:flatbuffers.Builder, payloadOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, payloadOffset, 0);
}

static createPayloadVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset {
  builder.startVector(1, data.length, 1);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt8(data[i]!);
  }
  return builder.endVector();
}

static startPayloadVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(1, numElems, 1);
}

static endCompressedIntegers(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createCompressedIntegers(builder:flatbuffers.Builder, compression:CompressionType, payloadOffset:flatbuffers.Offset):flatbuffers.Offset {
  CompressedIntegers.startCompressedIntegers(builder);
  CompressedIntegers.addCompression(builder, compression);
  CompressedIntegers.addPayload(builder, payloadOffset);
  return CompressedIntegers.endCompressedIntegers(builder);
}
}
