import { DefaultMap, ObjectUtils, type Registry, type VersionedValue } from 'engine-utils-ts';

// @ts-ignore
import gridHelp from 'svelte-grid/build/helper/index';

import type { UiViewSource, UiBindings, PanelViewDescription, PanelViewPosition } from 'ui-bindings';

export const UI_LAYOUT_COLUMNS = 100; // changing this will corrupt saved layout
export const predefinedLayout = {"Generate.Electrical Losses":{"id":"Generate.Electrical Losses","isVisible":false,"rect":{"x":40,"y":9,"w":44,"h":14},"isFixed":false},"View.Versions":{"id":"View.Versions","isVisible":false,"rect":{"x":86,"y":13,"w":14,"h":7},"isFixed":false},"View.Properties":{"id":"View.Properties","isVisible":true,"rect":{"x":15,"y":10,"w":15,"h":10},"isFixed":false},"View.Quantities":{"id":"View.Quantities","isVisible":false,"rect":{"x":46,"y":0,"w":38,"h":15},"isFixed":true},"Generate.Electrical Routes":{"id":"Generate.Electrical Routes","isVisible":false,"rect":{"x":42,"y":8,"w":42,"h":15},"isFixed":false},"Generate.Trackers Layout":{"id":"Generate.Trackers Layout","isVisible":false,"rect":{"x":79,"y":0,"w":21,"h":12},"isFixed":false},"View.Model Explorer":{"id":"View.Model Explorer","isVisible":true,"rect":{"x":0,"y":0,"w":15,"h":10},"isFixed":false},"Generate.Farm Layout":{"id":"Generate.Farm Layout","isVisible":true,"rect":{"x":81,"y":0,"w":19,"h":13},"isFixed":false},"View.Settings":{"id":"View.Settings","isVisible":true,"rect":{"x":0,"y":10,"w":15,"h":10},"isFixed":false},"View.Assets":{"id":"View.Assets","isVisible":true,"rect":{"x":15,"y":0,"w":15,"h":10},"isFixed":false},"View.Electrical Patterns":{"id":"View.Electrical Patterns","isVisible":false,"rect":{"x":30,"y":13,"w":16,"h":7},"isFixed":false}}

const sizeMinMaxDragger = {
    min: { w: 2, h: 1 },
    max: { w: 100, h: 100 },
    customDragger: true,
    customResizer: true,
};

export interface EnginePanelUiDescription {
    id: string;
    isVisible: boolean;
    isFixed: boolean;
    rect: { x: number, y: number, w: number, h: number };
    priority: number;
}

export class EngineUiLayout implements VersionedValue {

    viewProviders: Registry<PanelViewDescription> | null = null;

    public readonly panelsLayout: DefaultMap<string, EnginePanelUiDescription>;

    private _version: number = 0;

    constructor() {
        this.panelsLayout = new DefaultMap<string, EnginePanelUiDescription>((identifier) => {

            const rect = { x:3, y:3, w: 20, h: 10 };

            return {
                id: identifier,
                isVisible: false,
                rect,
                isFixed: false,
                priority: 0,
            };
        })
    }

    version() {
        return this._version + this.panelsLayout.version();
    }

    isPanelEnabled(identifier: string): boolean {
        return this.panelsLayout.get(identifier)?.isVisible ?? false;
    }

    togglePanelLocked(identifier: string) {
        const panel = this.panelsLayout.getOrCreate(identifier);
        this.panelsLayout.set(identifier, { ...panel, isFixed: !panel.isFixed });
    }

    togglePanelEnabled(identifier: string) {
        const panel = this.panelsLayout.getOrCreate(identifier);
        this.panelsLayout.set(identifier, { ...panel, isVisible: !panel.isVisible });
    }

    focusPanel(identifier: string) {
        if(!this.isPanelEnabled(identifier)) {
            this.togglePanelEnabled(identifier);
        } else {
            const item = this.panelsLayout.getOrCreate(identifier);
            const oldPriority = item.priority;
            for (const [ident, svelteGridItem] of this._svelteGridLayout.entries()) {
                if (svelteGridItem.priority > oldPriority) {
                    svelteGridItem.priority = Math.max(0, svelteGridItem.priority - 1);
                }
                if (ident === identifier) {
                    svelteGridItem.priority = this._svelteGridLayout.size;
                }
            }
            this.panelsLayout.set(identifier, { ...item, priority: this._svelteGridLayout.size });
        }
    }

    

    updateProviders(uiBindings: UiBindings) {
        this.viewProviders = uiBindings.views;
        for (const ident of this.panelsLayout.keys()) {
            if (!uiBindings.views.has(ident)) {
                this.panelsLayout.delete(ident);
            }
        }
    }

    updateLayoutFromSvelteGrid() {
        for (const [ident, val] of this._svelteGridLayout) {
            const l = this.panelsLayout.get(ident);
            if (l) {
                l.priority = val.priority;
                ObjectUtils.patchObject(l.rect, val[UI_LAYOUT_COLUMNS]);
            }
        }
    }

    private stateStorageKey = "engine-ui-layout-2"

    saveToLocalStorage() {
        const oldPanelDictRaw = localStorage.getItem(this.stateStorageKey)
        let newPanelDict = Object.fromEntries(this.panelsLayout.entries())
        if (oldPanelDictRaw) {
            const parsed = JSON.parse(oldPanelDictRaw)
            if (typeof parsed === 'object')
                newPanelDict = {
                    ...parsed,
                    ...newPanelDict,
                }
        }
        localStorage.setItem(this.stateStorageKey, JSON.stringify(newPanelDict));
    }

    restoreFromLocalStorage() {
        let saved = localStorage.getItem(this.stateStorageKey);
        if (!saved) saved = JSON.stringify(predefinedLayout)
        if (saved) {
            const layoutSaved: {[key: string]: EnginePanelUiDescription} = JSON.parse(saved);
            for (const [_id, layoutItem] of Object.entries(layoutSaved)) {
                if (typeof layoutItem.id != undefined) {
                    const panel = this.panelsLayout.getOrCreate(layoutItem.id);
                    ObjectUtils.cloneInto(panel, layoutItem);
                }
            }
        }
        return this.svelteGridLayout([], false);
    }

    private _svelteGridLayout: Map<string ,SvelteGridLayoutItem> = new Map();

    svelteGridLayout(previous: SvelteGridLayoutItem[], addHighestPriority: boolean = true): SvelteGridLayoutItem[] {
        if (this.viewProviders == null) {
            return [];
        }

        for (const l of previous) {
            const sgl = this._svelteGridLayout.get(l.id);
            if (sgl) {
                ObjectUtils.patchObject(sgl[UI_LAYOUT_COLUMNS], l[UI_LAYOUT_COLUMNS]);
            }
        }

        for (const createdAlready of this._svelteGridLayout.keys()) {
            if (!this.viewProviders.has(createdAlready)) {
                this._svelteGridLayout.delete(createdAlready);
            }
        }

        for (const [ident, _v] of this.viewProviders.entries()) {
            const layoutItem = this.panelsLayout.getOrCreate(ident);
            if (!layoutItem.isVisible) {
                this._svelteGridLayout.delete(ident)
                continue
            }
            if (this._svelteGridLayout.has(ident)) {
                const svelteGridItem = this._svelteGridLayout.get(ident)!;
                const layoutItem = this.panelsLayout.getOrCreate(ident);
                svelteGridItem.isVisible = layoutItem.isVisible;
                layoutItem.priority = svelteGridItem.priority;
                ObjectUtils.patchObject(layoutItem.rect, svelteGridItem[UI_LAYOUT_COLUMNS]);

            } else if (this.isPanelEnabled(ident)) {
                const layoutItem = this.panelsLayout.getOrCreate(ident);
                const [_path, value] = this.viewProviders.get(ident)!;
                const view = value.viewSource;
                const rect = layoutItem.rect
                if (rect.x > UI_LAYOUT_COLUMNS)
                    rect.x = Math.floor(UI_LAYOUT_COLUMNS / 2)
                const windowRowsCnt = Math.floor(window.innerHeight / 40)
                if (rect.y > windowRowsCnt)
                    rect.y = Math.floor(windowRowsCnt / 2)
                let min = sizeMinMaxDragger.min;
                if (value.minWidth) {
                    const perPx = window.innerWidth / UI_LAYOUT_COLUMNS;
                    const minSizeCount = Math.ceil(value.minWidth / perPx);
                    min = {
                        ...min,
                        w: minSizeCount
                    }
                    if (minSizeCount > rect.w) {
                        rect.w = minSizeCount;
                    }
                }

                this._svelteGridLayout.set(ident, {
                    id: layoutItem.id,
                    isVisible: layoutItem.isVisible,
                    position: value.position,
                    uiNodeDescription: view,
                    name: value.name,
                    customDragger: true,
                    customResizer: true,
                    priority: addHighestPriority ? this._svelteGridLayout.size : layoutItem.priority,
                    [UI_LAYOUT_COLUMNS]: gridHelp.item({
                        ...sizeMinMaxDragger,
                        ...rect,
                        min,
                        fixed: true,
                        draggable: !layoutItem.isFixed,
                        resizable: !layoutItem.isFixed,
                    })
                })
            }
        }

        return Array.from(this._svelteGridLayout.values());
    }
}

export interface SvelteGridLayoutItem {
    id: string;
    isVisible: boolean,
    uiNodeDescription: UiViewSource<any>;
    customDragger: true;
    customResizer: true;
    priority: number;
    name: string;
    position: PanelViewPosition;

    // stupid fucking layout properties naming
    // because of svelte-grid
    // name of the grid item property in each layout object
    // is number of columns in layout total
    [UI_LAYOUT_COLUMNS]: { x: number, y: number, w: number, h: number } & any,
}
