import type { ScopedLogger} from "engine-utils-ts";
import { IterUtils, DefaultMapObjectKey, DefaultMap } from "engine-utils-ts";
import type { PointsInPolygonChecker } from "math-ts";
import { KrMath, Vector3, Quaternion, Vector2, Aabb, Vec3Y, Vec3X, Matrix4, Transform } from "math-ts";
import { BimCollectionPatch } from "../collections/BimCollection";
import type { IdInEntityLocal, LocalIdsEdge } from "../collections/LocalIds";
import { LocalIdsCounter } from "../collections/LocalIds";
import type { SegmentInterp} from "../geometries/GraphGeometries";
import { GraphGeometry, SegmentInterpLinearG } from "../geometries/GraphGeometries";
import { PolylineGeometry } from "../geometries/PolylineGeometries";
import type { RepresentationBase} from "../representation/Representations";
import { BasicAnalyticalRepresentation } from "../representation/Representations";
import type { IdBimScene, SceneInstance, SceneInstancePatch } from "../scene/SceneInstances";
import { PointsToSampleAggregator } from "./cut-fill/CutFillBase";
import type { TerrainVersion } from "./Terrain";
import type { ElevationSample } from "./TerrainElevation";
import { TerrainElevation } from "./TerrainElevation";
import type { Bim } from "../Bim";
import type { IdBimGeo } from "../geometries/BimGeometries";


export enum OnTerrainPlacementType {
    Basic1Point,
    AlongLongestAxisPoints,
    LinerGeometry,
}


const OffsetLinerGeometryMeter = 0.1;
const StepMeter = 2;
const MinAngleRad = KrMath.degToRad(1);

export interface OnTerrainPlacementConfig {
    terrainVersion: TerrainVersion,
    minEmbedment: number,
}

interface EquipmentToPlace {
    id: IdBimScene;
    instance: SceneInstance;
    placement: OnTerrainPlacementType;
    wsObjectAxis: Vector3;
    rotationAroundZ: Quaternion;
    lsObjectCenter: Vector3;
    wsObjectCenter: Vector3;
    localBoundsSize: Vector3;
    pointsToSample: Vector2[];
    pointsInds: number[];
}


export function placeInstancesOnTheGroundByType(
    logger: ScopedLogger,
    bim: Bim,
    ids: IdBimScene[],
    equipmentTypeIdents: Map<string, OnTerrainPlacementType>,
    config: OnTerrainPlacementConfig
) {
    const bimEquipmentInstances = IterUtils.filterMap(ids, (id) => {
        const instance = bim.instances.perId.get(id);
        if (!instance?.type_identifier) {
            return undefined;
        }
        const placement = equipmentTypeIdents.get(instance.type_identifier);
        if (placement !== undefined) {
            return [id, instance, placement] as const;
        }
        return undefined;
    });

    const reusableAabb = Aabb.empty();

    const equipmentToPlace: EquipmentToPlace[] = [];

    const pointsToSampleAggregator = new PointsToSampleAggregator();

    const goemetriesAabbs = bim.allBimGeometries.aabbs.poll();

    const reprsBboxes = new DefaultMap<RepresentationBase, Aabb>(r => r.aabb(goemetriesAabbs));

    for (const [id, instance, placement] of bimEquipmentInstances) {
        const representation = instance.representation;
        if (!representation) {
            continue;
        }
        reusableAabb.makeEmpty();

        const reprAabb = reprsBboxes.getOrCreate(representation);
        if (!reprAabb.isEmpty()) {
            const localBoundsSize = reprAabb.getSize();
            const centerLocal = reprAabb.getCenter_t();
            centerLocal.z = reprAabb.minz();
            const localObjectAxis =
                localBoundsSize.y > localBoundsSize.x ? Vec3Y : Vec3X;
            const sizeAlongAxis = localBoundsSize.dot(localObjectAxis);

            const centerGlobal = centerLocal
                .clone()
                .applyMatrix4(instance.worldMatrix);

            const wsObjectAxis3d = localObjectAxis
                .clone()
                .applyMatrix4Rotation(instance.worldMatrix);
            wsObjectAxis3d.z = 0;
            wsObjectAxis3d.normalize();
            if (wsObjectAxis3d.lengthSq() < 0.5) {
                // if we get degenerate 0 axis
                logger.batchedWarn(
                    "invalid derived ws axis, reset",
                    wsObjectAxis3d.clone()
                );
                wsObjectAxis3d.copy(localObjectAxis);
            }
            const rotationAroundZ = Quaternion.fromUnitVectors(
                localObjectAxis,
                wsObjectAxis3d
            );

            const wsObjectAxis = wsObjectAxis3d.xy();
            const pointsToSampleWs: Vector2[] = [];

            if (placement === OnTerrainPlacementType.AlongLongestAxisPoints) {
                pointsToSampleWs.push(
                    centerGlobal
                        .xy()
                        .addScaledVector(wsObjectAxis, sizeAlongAxis * -0.4)
                );
                pointsToSampleWs.push(
                    centerGlobal
                        .xy()
                        .addScaledVector(wsObjectAxis, sizeAlongAxis * 0.4)
                );
            } else {
                pointsToSampleWs.push(centerGlobal.xy());
            }

            equipmentToPlace.push({
                id,
                instance,
                placement,
                wsObjectAxis: wsObjectAxis3d,
                rotationAroundZ: rotationAroundZ,
                lsObjectCenter: centerLocal,
                wsObjectCenter: centerGlobal,
                localBoundsSize: localBoundsSize,
                pointsToSample: pointsToSampleWs,
                pointsInds:
                    pointsToSampleAggregator.addPoints2dToSample(
                        pointsToSampleWs
                    ),
            });
        }
    }

    const elevationSamples = TerrainElevation.sampleFromBim(
        logger.newScope("placement-sampling"),
        bim,
        pointsToSampleAggregator.pointsToSample,
        config.terrainVersion
    );

    const positionsPatch = new Map<IdBimScene, Matrix4>();
    for (const e2p of equipmentToPlace) {
        const sampledPoints: Vector3[] = [];
        const sampledPointsMinBound = new Vector3(Infinity, Infinity, Infinity);
        for (let i = 0; i < e2p.pointsToSample.length; ++i) {
            const samplePoint = e2p.pointsToSample[i];
            const sampleIndex = e2p.pointsInds[i];
            let elevation = elevationSamples[sampleIndex].elevation;
            if (elevation === null) {
                elevation = 0;
            } else {
            }
            const samplePoint3d = new Vector3(
                samplePoint.x,
                samplePoint.y,
                elevation
            );
            sampledPoints.push(samplePoint3d);
            sampledPointsMinBound.min(samplePoint3d);
        }

        const newGlobalTransform = new Transform(
            e2p.wsObjectCenter,
            e2p.rotationAroundZ
        );

        if (sampledPoints.length === 2) {
            const startSample = sampledPoints[0];
            const endSample = sampledPoints[1];
            const calculatedCenterElevation =
                (startSample.z + endSample.z) * 0.5;
            newGlobalTransform.position.z = calculatedCenterElevation - config.minEmbedment;
        } else {
            const sample = sampledPoints[0];
            newGlobalTransform.position.z = sample.z;
        }

        if (sampledPoints.length === 2) {
            const start = sampledPoints[0];
            const end = sampledPoints[1];
            const endStartDir = end.clone().sub(start).normalize();

            // if (endStartDir.angleTo(Vec3Y) < (Math.PI / 4)
            //     || endStartDir.angleTo(Vec3YNeg) < (Math.PI / 4)
            // ) {
            //     logger.batchedWarn('angle is too vertical, reset to default');
            // }

            const calculatedTiltQuat = Quaternion.fromUnitVectors(
                e2p.wsObjectAxis,
                endStartDir
            );
            newGlobalTransform.rotation.premultiply(calculatedTiltQuat);
        }

        const centerOffsetCompensation = e2p.lsObjectCenter.clone();
        centerOffsetCompensation.applyQuaternion(newGlobalTransform.rotation);
        newGlobalTransform.position.sub(centerOffsetCompensation);

        const m = newGlobalTransform.toMatrix4(new Matrix4());
        positionsPatch.set(e2p.id, m);
    }

    return positionsPatch;
}


type LinerObjectToPlace =  {
    id: IdBimScene;
    instance: SceneInstance;
    geometry: PolyGeomType | GraphGeomType;
}

interface LinerGeomType {
    id: IdBimGeo,
    pointsToSample: Vector2[];
    pointsElevationIds: number[];
    splittedEdgesWithPointIds: number[][];
    extraPoints: Map<number, number[]>;
}

type PolyGeomType  = LinerGeomType & {
    type: 'polyline_geo';
    radius: number;
}

type GraphGeomType = LinerGeomType & {
    type: 'graph_geo';
}

type LinerGeometryType = PolylineGeometry | GraphGeometry;


export function patchLinerObjectsGeometry(
    logger: ScopedLogger,
    bim: Bim,
    ids: IdBimScene[],
    checker: PointsInPolygonChecker,
    equipmentTypeIdents: Map<string, OnTerrainPlacementType>,
    config: OnTerrainPlacementConfig
) {

    const bimEquipmentInstances = IterUtils.filterMap(ids, (id) => {
        const instance = bim.instances.perId.get(id);
        if (!instance?.type_identifier) {
            return undefined;
        }
        const placement = equipmentTypeIdents.get(instance.type_identifier);
        if (placement !== undefined) {
            return [id, instance] as const;
        }
        return undefined;
    });

    const pointsToSampleAggregator = new PointsToSampleAggregator();
    const linerObjects: LinerObjectToPlace[] = [];
    for (const [id, instance] of bimEquipmentInstances) {
        const representation = instance.representationAnalytical;

        if (!representation) {
            continue;
        }

        let geometry: PolyGeomType | GraphGeomType | undefined = undefined;
        const geomId = instance.representationAnalytical?.geometryId ?? 0;
        const geom = bim.allBimGeometries.peekById(geomId);
        if (geom instanceof PolylineGeometry) {
            geometry = {
                id: geomId,
                type: "polyline_geo",
                pointsToSample: [],
                pointsElevationIds: [],
                splittedEdgesWithPointIds: [],
                radius: geom.radius,
                extraPoints: new Map(),
            };

            if(!geom.points3d.length){
                logger.error(
                    "invalid geomentry for id " + id,
                    instance.type_identifier,
                    [geomId, geom]
                );
                continue;
            }

            let counter = 0;
            const initialPoints = Vector3.arrayFromFlatArray(geom.points3d).map(v => v.applyMatrix4(instance.worldMatrix).xy());
            for (let i = 1; i < initialPoints.length; i++) {
                const start2d = initialPoints[i - 1];
                const end2d = initialPoints[i];
                const newPoints = splitEdge(start2d, end2d);
                const indexes = IterUtils.newArrayWithIndices(
                    counter,
                    newPoints.length + 2
                );
                counter += indexes.length - 1;
                geometry.splittedEdgesWithPointIds.push(indexes);

                const newEdge = [start2d, ...newPoints];
                if (i === initialPoints.length - 1) {
                    newEdge.push(end2d);
                }

                const ids = pointsToSampleAggregator.addPoints2dToSample(newEdge);
                geometry.pointsElevationIds.push(...ids);
                geometry.pointsToSample.push(...newEdge);
            }
        } else if (geom instanceof GraphGeometry) {
            geometry = {
                id: geomId,
                type: "graph_geo",
                pointsToSample: [],
                pointsElevationIds: [],
                splittedEdgesWithPointIds: [],
                extraPoints: new Map(),
            };

            if(!geom.edges.size || !geom.points.size){
                logger.error(
                    "invalid geomentry for id " + id,
                    instance.type_identifier,
                    [geomId, geom]
                );
                continue;
            }

            let counter = 0;
            for (const [start, end] of geom.iterSegments()) {
                const start2d = start
                    .clone()
                    .applyMatrix4(instance.worldMatrix)
                    .xy();
                const end2d = end
                    .clone()
                    .applyMatrix4(instance.worldMatrix)
                    .xy();
                const newPoints = splitEdge(start2d, end2d);
                const newEdge = [start2d, ...newPoints, end2d];
                const indexes = IterUtils.newArrayWithIndices(
                    counter,
                    newPoints.length + 2
                );
                counter += indexes.length;
                geometry.splittedEdgesWithPointIds.push(indexes);

                const ids =
                    pointsToSampleAggregator.addPoints2dToSample(newEdge);
                geometry.pointsElevationIds.push(...ids);
                geometry.pointsToSample.push(...newEdge);
            }
        } else {
            logger.warn(
                "geometry is not supported for",
                instance.type_identifier
            );
            continue;
        }

        const width =
            instance.properties.get("dimensions | width")?.as("m") ??
            instance.properties.get("road | width")?.as("m");

        if (width) {
            for (const edge of geometry.splittedEdgesWithPointIds) {
                const startIdx = edge[0];
                const endIdx = edge[edge.length - 1];
                const start = geometry.pointsToSample[startIdx];
                const end = geometry.pointsToSample[endIdx];
                const dir = end.clone().sub(start.clone());
                const perpendicular = new Vector2(dir.y, -dir.x).normalize();
                for (const index of edge) {
                    const p = geometry.pointsToSample[index];
                    const p1 = p
                        .clone()
                        .add(perpendicular.clone().multiplyScalar(width * 0.5));
                    const p2 = p
                        .clone()
                        .add(perpendicular.clone().multiplyScalar(width * -0.5));
                    const sampledIndexes =
                        pointsToSampleAggregator.addPoints2dToSample([p1, p2]);
                    geometry.extraPoints.set(index, sampledIndexes);
                }
            }
        }

        linerObjects.push({
            id,
            instance,
            geometry,
        });
    }

    const elevationSamples = TerrainElevation.sampleFromBim(
        logger.newScope("placement-sampling"),
        bim,
        pointsToSampleAggregator.pointsToSample,
        config.terrainVersion
    );
    const geomPatch = new BimCollectionPatch<
        LinerGeometryType,
        IdBimGeo,
        LinerGeometryType
    >("liner-geometry-patch");
    const patchInstances: [IdBimScene, Partial<SceneInstancePatch>][] = [];
    const worldMatrixPatch: [IdBimScene, Matrix4][] = [];
    const geomRefs = getAnalyticalGeomReferences(bim);
    for (const obj of linerObjects) {
        if (obj.geometry.type === "polyline_geo") {
            const points: Vector3[] = [];
            for (let i = 0; i < obj.geometry.pointsToSample.length; i++) {
                const point = setElevationToPoint(
                    obj.geometry,
                    elevationSamples,
                    i,
                    checker
                );
                points.push(point);
            }

            const transform = new Transform(points[0].clone());
            const mtx = transform.toMatrix4(new Matrix4());
            worldMatrixPatch.push([obj.id, mtx]);

            const inverseMtx = new Matrix4().getInverse(mtx);
            for (const p of points) {
                p.applyMatrix4(inverseMtx);
            }

            const refs = geomRefs.get(obj.geometry.id);
            const updatedPoints = removeRedundantPointsInPolyline(
                points,
                obj.geometry.splittedEdgesWithPointIds
            );
            const newGeometry = PolylineGeometry.newWithAutoIds(
                updatedPoints,
                obj.geometry.radius
            );

            if (refs?.size === 1) {
                geomPatch.toPatch.push([obj.geometry.id, newGeometry]);
            } else {
                const newGeoId = bim.polylineGeometries.reserveNewId();
                patchInstances.push([
                    obj.id,
                    {
                        representationAnalytical:
                            new BasicAnalyticalRepresentation(newGeoId),
                    },
                ]);
                geomPatch.toAlloc.push([newGeoId, newGeometry]);
            }
        } else if (obj.geometry.type === "graph_geo") {
            const globalGeom = convertGraphGeometry(
                obj.geometry,
                elevationSamples,
                checker
            );

            const points: [IdInEntityLocal, Vector3][] = [];
            for (const point of globalGeom.points) {
                points.push(point);
            }
            const [_, position] = points[0];
            const transform = new Transform(position.clone());
            const mtx = transform.toMatrix4(new Matrix4());
            worldMatrixPatch.push([obj.id, mtx]);

            const inverseMtx = new Matrix4().getInverse(mtx);
            const localPoints: [IdInEntityLocal, Vector3][] = [];
            for (const [id, point] of points) {
                localPoints.push([id, point.clone().applyMatrix4(inverseMtx)]);
            }

            const localGeom = new GraphGeometry(
                new Map(localPoints),
                globalGeom.edges
            );

            const refs = geomRefs.get(obj.geometry.id);
            if (refs?.size === 1) {
                geomPatch.toPatch.push([obj.geometry.id, localGeom]);
            } else {
                const newGeoId = bim.graphGeometries.reserveNewId();

                patchInstances.push([
                    obj.id,
                    {
                        representationAnalytical:
                            new BasicAnalyticalRepresentation(newGeoId),
                    },
                ]);
                geomPatch.toAlloc.push([newGeoId, localGeom]);
            }
        }
    }

    bim.allBimGeometries.applyCollectionPatch(geomPatch);
    bim.instances.applyPatches(patchInstances);

    return worldMatrixPatch;
}

function removeRedundantPointsInPolyline(polyline:Vector3[], edges:number[][]){
    const updated:Vector3[] = [];
    for (let i = 0; i < edges.length; i++) {
        const edge = edges[i];
        const points: Vector3[] = [];
        for (const idx of edge) {
            points.push(polyline[idx]);
        }
        const updatedEdge = removeRedundantPointsInLine(points);
        if (i < edges.length - 1) {
            updatedEdge.pop();
        }
        updated.push(...updatedEdge);
    }

    return updated;
}

function removeRedundantPointsInLine(line: Vector3[]){
    if (line.length < 3) {
        return line.slice();
    }

    const start2d = line[0].xy();

    function createVector(beg: Vector3, end: Vector3) {
        return new Vector2(start2d.distanceTo(end.xy()), end.z).sub(
            new Vector2(start2d.distanceTo(beg.xy()), beg.z)
        );
    }

    function checkSlope(p1:Vector3, p2:Vector3, p3: Vector3){
        const v1 = createVector(p1, p2);
        const v2 = createVector(p2, p3);
        return v1.smallestAngleTo(v2) < MinAngleRad;
    }

    const updated: Vector3[] = [];
    for (let i = 0; i < line.length; i++) {
        const pos = line[i].clone();
        const isNotLast = i < line.length - 1;

        const prev = isNotLast ? updated[updated.length - 1] : updated[updated.length - 2];
        const current = isNotLast ? pos : updated[updated.length - 1];
        const next = isNotLast ? line[i + 1] : pos;

        if (updated.length > 1 && checkSlope(prev, current, next)) {
            updated[updated.length - 1] = pos;
        } else {
            updated.push(pos);
        }
    }

    return updated;
}

function setElevationToPoint(
    geometry: LinerGeomType, 
    elevationSamples: ElevationSample[], 
    index: number,
    checker: PointsInPolygonChecker
): Vector3 {
    const p = geometry.pointsToSample[index];
    
    if (!checker.isPointInside(p)) {
        return new Vector3(p.x, p.y, 0);
    }
    
    const idx = geometry.pointsElevationIds[index];
    const elevation = elevationSamples[idx].elevation;

    let localPosition = elevation === null ? 0 : elevation;

    const extraPoints = geometry.extraPoints.get(index);
    if(extraPoints){
        for (const i of extraPoints) {
            const elevation = elevationSamples[i].elevation;
            if(elevation === null){
                continue;
            }
            localPosition = Math.max(elevation, localPosition);
        }
    }

    const newPoint = new Vector3(p.x, p.y, localPosition + OffsetLinerGeometryMeter);
    return newPoint;
}

function convertGraphGeometry(
    geometry: GraphGeomType, elevationSamples: ElevationSample[], checker: PointsInPolygonChecker
): GraphGeometry {
    const counter = new LocalIdsCounter();
    const pointsMap = new DefaultMapObjectKey<Vector3, IdInEntityLocal>({
        valuesFactory: (key) => counter.nextId(),
        unique_hash: (v) => v.toString()
    });
    const graphEdges = new Map<LocalIdsEdge, SegmentInterp>();
    for (let i = 0; i < geometry.splittedEdgesWithPointIds.length; ++i) {
        const e = geometry.splittedEdgesWithPointIds[i];
        const pointWithElevation = e.map(idx => setElevationToPoint(geometry, elevationSamples, idx, checker));
        const updatedEdge = removeRedundantPointsInLine(pointWithElevation);
        for (let j = 1; j < updatedEdge.length; j++) {
            const st = updatedEdge[j-1];
            const en = updatedEdge[j];
            Object.freeze(st);
            Object.freeze(en);
            const stId = pointsMap.getOrCreate(st);
            const enId = pointsMap.getOrCreate(en);
            const edge = LocalIdsCounter.newEdge(stId, enId);
            graphEdges.set(edge, SegmentInterpLinearG);
        }
    }

    const graphPoints = new Map<IdInEntityLocal, Vector3>();
    for (const [point, id] of pointsMap.entries()) {
        graphPoints.set(id, point);
    }

    const newGeometry = new GraphGeometry(
        graphPoints,
        graphEdges
    );

    return newGeometry;
}

function splitEdge(start: Vector2, end: Vector2) {
    const length = start.distanceTo(end);
    const countEdges = Math.ceil(length / StepMeter);
    const newStep = length / countEdges;

    const dir = end.clone().sub(start).normalize().multiplyScalar(newStep);
    let lastPoint = start;
    const newPoints: Vector2[] = [];
    for (let j = 0; j < countEdges - 1; j++) {
        const newPos = lastPoint.clone().add(dir);
        lastPoint = newPos;
        newPoints.push(new Vector2(newPos.x, newPos.y));
    }

    return newPoints;
}

function getAnalyticalGeomReferences(bim:Bim){
    const refs = new Map<IdBimGeo, Set<IdBimScene>>();
    for (const [id, inst] of bim.instances.readAll()) {
        if(inst.representationAnalytical){
            let instances = refs.get(inst.representationAnalytical.geometryId);
            if(!instances){
                instances = new Set();
                refs.set(inst.representationAnalytical.geometryId, instances);
            }
            instances.add(id);
        }
    }
    return refs;
}
