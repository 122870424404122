import type { ShadingDataInput, TrackerCatalogItem, SkidCatalogItem } from "./LayoutParser";

export interface GeneralSelector<T> {
    name: string;
    selected?: T;
    count: number;
    importData?: ShadingDataInput;
}

interface LayoutImportGroupArgs<T> {
    count: number;
    type: string;
    options: T[];
    items: Record<string, GeneralSelector<T>>;
    onChange: (name: string, newValue: GeneralSelector<T>) => void;
}

export class LayoutImportGroupUiContext<T> {
    private count: number;
    private type: string;
    public options: T[];
    public items: Record<string, GeneralSelector<T>>;
    public onChange: (name: string, newValue: GeneralSelector<T>) => void;

    constructor(args: LayoutImportGroupArgs<T>) {
        this.count = args.count;
        this.type = args.type;
        this.options = args.options;
        this.items = args.items;
        this.onChange = args.onChange;
    }

    get name() {
        return `&times; ${this.count} ${this.type} found in the file`;
    };
}

export class TrackersSelectionContext extends LayoutImportGroupUiContext<TrackerCatalogItem> {}

export class SkidSelectionContext extends LayoutImportGroupUiContext<SkidCatalogItem> {}
