import type { Aabb } from './Aabb';
import type { Matrix4 } from './Matrix4';
import type { Plane } from './Plane';
import { Vector3 } from './Vector3';

//mostly taken from three.js math lib

export class Ray {

	readonly origin: Vector3
	readonly direction: Vector3;
	intersectTriangle!: (this: Ray, a: Vector3, b: Vector3, c: Vector3, backfaceCulling: boolean, target: Vector3) => Vector3 | null;
	distanceSqToSegment!: (v0: Vector3, v1: Vector3, pointOnRay?: Vector3, pointOnSegment?: Vector3) => number;

	constructor(origin:Vector3, direction:Vector3) {
		this.origin = origin.clone();
		this.direction = direction.clone();
	}

	set( origin: Vector3, direction: Vector3 ) {
		this.origin.copy( origin );
		this.direction.copy( direction );
		return this;
	}

	clone() {
		const r = new Ray(this.origin, this.direction);
		return r;
	}

	copy(ray: Ray) {
		this.origin.copy(ray.origin);
		this.direction.copy(ray.direction);
	}

	equals(other: Ray) {
		return this.origin.equals(other.origin) && this.direction.equals(other.direction);
	}

	closestPointToPoint( point: Vector3, target?: Vector3 ) {
		target = target ?? new Vector3();
		target.subVectors( point, this.origin );
		const directionDistance = target.dot( this.direction );
		if ( directionDistance < 0 ) {
			return target.copy( this.origin );
		}
		return target.copy( this.direction ).multiplyScalar( directionDistance ).add( this.origin );
	}

	distanceToSegment(v0: Vector3, v1: Vector3, pointOnRay?: Vector3, pointOnSegment?: Vector3): number {
		return Math.sqrt(this.distanceSqToSegment(v0, v1, pointOnRay, pointOnSegment));
	}

	at ( t:number, target:Vector3 ): Vector3 {
		return target.copy( this.direction ).multiplyScalar( t ).add( this.origin );
	}

	intersectBox ( box:Aabb, target:Vector3 ): Vector3 | null {

		let tmin, tmax, tymin, tymax, tzmin, tzmax;

		const invdirx = 1 / this.direction.x;
		const invdiry = 1 / this.direction.y;
		const invdirz = 1 / this.direction.z;

		const origin = this.origin;
		const boxElements = box.elements;

		if ( invdirx >= 0 ) {

			tmin = ( boxElements[0] - origin.x ) * invdirx;
			tmax = ( boxElements[3] - origin.x ) * invdirx;

		} else {

			tmin = ( boxElements[3] - origin.x ) * invdirx;
			tmax = ( boxElements[0] - origin.x ) * invdirx;

		}

		if ( invdiry >= 0 ) {

			tymin = ( boxElements[1] - origin.y ) * invdiry;
			tymax = ( boxElements[4] - origin.y ) * invdiry;

		} else {

			tymin = ( boxElements[4] - origin.y ) * invdiry;
			tymax = ( boxElements[1] - origin.y ) * invdiry;

		}

		if ( ( tmin > tymax ) || ( tymin > tmax ) ) return null;

		// These lines also handle the case where tmin or tmax is NaN
		// (result of 0 * Infinity). x !== x returns true if x is NaN

		if ( tymin > tmin || tmin !== tmin ) tmin = tymin;

		if ( tymax < tmax || tmax !== tmax ) tmax = tymax;

		if ( invdirz >= 0 ) {

			tzmin = ( boxElements[2] - origin.z ) * invdirz;
			tzmax = ( boxElements[5] - origin.z ) * invdirz;

		} else {

			tzmin = ( boxElements[5] - origin.z ) * invdirz;
			tzmax = ( boxElements[2] - origin.z ) * invdirz;

		}

		if ( ( tmin > tzmax ) || ( tzmin > tmax ) ) return null;

		if ( tzmin > tmin || tmin !== tmin ) tmin = tzmin;

		if ( tzmax < tmax || tmax !== tmax ) tmax = tzmax;

		//return point closest to the ray (positive side)

		if ( tmax < 0 ) return null;

		return this.at( tmin >= 0 ? tmin : tmax, target );

	}

	intersectsBox ( box:Aabb ) {
		return this.intersectBox( box, Vector3.zero() ) !== null;
	}

	distanceToPoint( point:Vector3 ): number {
		return Math.sqrt(this.distanceSqToPoint(point));
	}

	distanceSqToPoint( point:Vector3 ): number {
		let directionDistance_t = Vector3.subVectors(point, this.origin, _vt).dot(this.direction);
		// point behind the ray
		if ( directionDistance_t < 0 ) {
			return this.origin.distanceToSquared( point );
		}
		return _vt.copy(this.direction).multiplyScalar( directionDistance_t ).add( this.origin )
			.distanceToSquared( point );
	}

	distanceToPlane ( plane:Plane ): number | null {
		var denominator = plane.normal.dot(this.direction);
		if ( denominator === 0 ) {
			// line is coplanar, return origin
			if ( plane.distanceToPoint( this.origin ) === 0 ) {
				return 0;
			}
			// Null is preferable to undefined since undefined means.... it is undefined
			return null;
		}
		var t = - ( this.origin.dot( plane.normal ) + plane.constant ) / denominator;
		// Return if the ray never intersects the plane
		return t >= 0 ? t : null;
	}

	intersectPlane_t(plane: Plane, target:Vector3): Vector3 | null {
		var t = this.distanceToPlane( plane );
		if ( t === null ) {
			return null;
		}
		return this.at( t, target );
	}

	intersectsPlane (plane:Plane):boolean {
		// check if the ray lies on the plane first
		var distToPoint = plane.distanceToPoint( this.origin );
		if ( distToPoint === 0 ) {
			return true;
		}
		var denominator = plane.normal.dot( this.direction );
		if ( denominator * distToPoint < 0 ) {
			return true;
		}
		// ray origin is behind the plane (and is pointing behind it)
		return false;
	}

	intersectsSphere (center:Vector3, radius:number): boolean {
		return this.distanceSqToPoint( center ) <= ( radius * radius );
	}

	applyMatrix4(matrix4: Matrix4) {
		this.origin.applyMatrix4( matrix4 );
		this.direction.transformDirection( matrix4 );
	}

}

Ray.prototype.intersectTriangle = function () {
	const edge1 = Vector3.zero();
	const edge2 = Vector3.zero();
	const normal = Vector3.zero();
	const diff = Vector3.zero();
	const temp = Vector3.zero();

	return function (this: Ray, a: Vector3, b: Vector3, c: Vector3, backfaceCulling: boolean, target: Vector3) {
		// from http://www.geometrictools.com/GTEngine/Include/Mathematics/GteIntrRay3Triangle3.h
		edge1.copy(b).sub(a);
		edge2.copy(c).sub(a);
		normal.copy(edge1).cross(edge2);

		// Solve Q + t*D = b1*E1 + b2*E2 (Q = kDiff, D = ray direction,
		// E1 = kEdge1, E2 = kEdge2, N = Cross(E1,E2)) by
		//   |Dot(D,N)|*b1 = sign(Dot(D,N))*Dot(D,Cross(Q,E2))
		//   |Dot(D,N)|*b2 = sign(Dot(D,N))*Dot(D,Cross(E1,Q))
		//   |Dot(D,N)|*t = -sign(Dot(D,N))*Dot(Q,N)
		let DdN = this.direction.dot(normal);
		let sign: number;

		if (DdN > 0) {

			if (backfaceCulling) return null;
			sign = 1;

		} else if (DdN < 0) {

			sign = - 1;
			DdN = - DdN;

		} else {
			return null;
		}

		diff.copy(this.origin).sub(a);
		const DdQxE2 = sign * this.direction.dot(temp.copy(diff).cross(edge2));

		// b1 < 0, no intersection
		if (DdQxE2 < 0) {
			return null;
		}

		const DdE1xQ = sign * this.direction.dot(temp.copy(edge1).cross(diff));

		// b2 < 0, no intersection
		if (DdE1xQ < 0) {
			return null;
		}

		// b1+b2 > 1, no intersection
		if (DdQxE2 + DdE1xQ > DdN) {
			return null;
		}

		// Line intersects triangle, check if ray does.
		const QdN = - sign * diff.dot(normal);

		// t < 0, no intersection
		if (QdN < 0) {
			return null;
		}
		// Ray intersects triangle.
		return this.at(QdN / DdN, target);
	}
}();

Ray.prototype.distanceSqToSegment = function () {
	const segCenter = Vector3.zero();
	const segDir = Vector3.zero();
	const diff = Vector3.zero();

	return function distanceSqToSegment(this: Ray, v0: Vector3, v1: Vector3, optionalPointOnRay?: Vector3, optionalPointOnSegment?: Vector3) {

		// from http://www.geometrictools.com/GTEngine/Include/Mathematics/GteDistRaySegment.h
		// It returns the min distance between the ray and the segment
		// defined by v0 and v1
		// It can also set two optional targets :
		// - The closest point on the ray
		// - The closest point on the segment

		segCenter.copy(v0).add(v1).multiplyScalar(0.5);
		segDir.copy(v1).sub(v0).normalize();
		diff.copy(this.origin).sub(segCenter);

		let segExtent = v0.distanceTo(v1) * 0.5;
		let a01 = - this.direction.dot(segDir);
		let b0 = diff.dot(this.direction);
		let b1 = - diff.dot(segDir);
		let c = diff.lengthSq();
		let det = Math.abs(1 - a01 * a01);
		let s0: number, s1: number, sqrDist: number, extDet: number;

		if (det > 0) {

			// The ray and segment are not parallel.

			s0 = a01 * b1 - b0;
			s1 = a01 * b0 - b1;
			extDet = segExtent * det;

			if (s0 >= 0) {

				if (s1 >= - extDet) {

					if (s1 <= extDet) {

						// region 0
						// Minimum at interior points of ray and segment.

						var invDet = 1 / det;
						s0 *= invDet;
						s1 *= invDet;
						sqrDist = s0 * (s0 + a01 * s1 + 2 * b0) + s1 * (a01 * s0 + s1 + 2 * b1) + c;

					} else {

						// region 1

						s1 = segExtent;
						s0 = Math.max(0, - (a01 * s1 + b0));
						sqrDist = - s0 * s0 + s1 * (s1 + 2 * b1) + c;

					}

				} else {

					// region 5

					s1 = - segExtent;
					s0 = Math.max(0, - (a01 * s1 + b0));
					sqrDist = - s0 * s0 + s1 * (s1 + 2 * b1) + c;

				}

			} else {

				if (s1 <= - extDet) {

					// region 4

					s0 = Math.max(0, - (- a01 * segExtent + b0));
					s1 = (s0 > 0) ? - segExtent : Math.min(Math.max(- segExtent, - b1), segExtent);
					sqrDist = - s0 * s0 + s1 * (s1 + 2 * b1) + c;

				} else if (s1 <= extDet) {

					// region 3

					s0 = 0;
					s1 = Math.min(Math.max(- segExtent, - b1), segExtent);
					sqrDist = s1 * (s1 + 2 * b1) + c;

				} else {

					// region 2

					s0 = Math.max(0, - (a01 * segExtent + b0));
					s1 = (s0 > 0) ? segExtent : Math.min(Math.max(- segExtent, - b1), segExtent);
					sqrDist = - s0 * s0 + s1 * (s1 + 2 * b1) + c;

				}

			}

		} else {

			// Ray and segment are parallel.

			s1 = (a01 > 0) ? - segExtent : segExtent;
			s0 = Math.max(0, - (a01 * s1 + b0));
			sqrDist = - s0 * s0 + s1 * (s1 + 2 * b1) + c;

		}

		if (optionalPointOnRay) {

			optionalPointOnRay.copy(this.direction).multiplyScalar(s0).add(this.origin);

		}

		if (optionalPointOnSegment) {

			optionalPointOnSegment.copy(segDir).multiplyScalar(s1).add(segCenter);

		}

		return sqrDist;

	};
}();


const _vt = new Vector3();
