
import type { UiBindings, PanelViewDescription } from 'ui-bindings';
import { PanelViewVisibility, PanelViewPosition } from 'ui-bindings';
import { BindedStore } from '../utils';
import { DefaultMap } from "engine-utils-ts";
import type { EngineUiLayout } from "../ui-panels/EngineUiLayout";
import type { IconName } from '../libui/icon';

export class NavbarItemGroup {

    constructor(
        public readonly name: string,
        public readonly children: NavbarItem[],
    ) {
	}
}

export class NavbarItem {
	readonly name: string;
	readonly iconName: IconName;
    readonly sortOrder: number;

    constructor(
        readonly id: string,
		readonly view: PanelViewDescription,
        readonly navbarContext: NavbarContext,
        
    ) {
        this.name = view.name;
		this.iconName = view.iconName as IconName;
		this.sortOrder = view.sortOrder!;
        this.toggle = this.toggle.bind(this);
    }

    open() {
        if (this.view.position === PanelViewPosition.Overlay) {
            this.navbarContext.openOverlayPanel(this.id);
        } else {
            this.navbarContext.openTab(this.id);
        }
    }

    close() {
        this.navbarContext.closeTab(this.id);
    }

    toggle(isActive: boolean) {
        if (isActive) {
            this.open();
        } else {
            this.close();
        }
    }

    isActive(panel: PanelDescription) {
        return panel.open && this.id === panel.views[0];
    }

    isSecondary(panel: PanelDescription, isVisible?: boolean) {
        return panel.open && this.id === panel.views[1] ||
            !!isVisible && this.navbarContext._storeValue.views[this.id] === ViewPosition.Float;
    }
}

export function getNavItemsByGroup(items:NavbarItem[], views: Record<string, ViewPosition>) {
    const rootGroups = new DefaultMap<string, NavbarItemGroup>((name) => new NavbarItemGroup(name, []));

	rootGroups.getOrCreate('General');
	rootGroups.getOrCreate('Analysis');

    const catalogGroup = new NavbarItemGroup('Catalog', []);

    items.forEach((item) => {
        const position = views[item.id];
        if (position !== ViewPosition.RightTop && position !== ViewPosition.RightBottom) {
            if (item.view.group === catalogGroup.name) {
                catalogGroup.children.push(item);
            } else {
                const rootGroup = rootGroups.getOrCreate(item.view.group!);
                rootGroup.children.push(item);
            }
		}
    })

    return [...rootGroups.values(), catalogGroup];
}

export function navItemFromUiBindings(u: UiBindings, navbarContext: NavbarContext) {
    const items = [];

    for (const [mergedPath, [_path, view]] of u.views.entries()) {
		if (view.visibility === PanelViewVisibility.Navbar) {
			const item = new NavbarItem(mergedPath, view, navbarContext);
            items.push(item);
		}
    }

    return items.sort((a, b) => a.sortOrder - b.sortOrder);
}

export function rightNavItemFromUiBindings(items:NavbarItem[], views: Record<string, ViewPosition>) {
    return items.filter(item => {
        const position = views[item.id];
        return position === ViewPosition.RightTop || position === ViewPosition.RightBottom;
    });
}

export enum PanelPosition {
    Left,
    LeftSecond,
    Right
}

export enum ViewPosition {
    LeftTop,
    LeftBottom,
    LeftSecond,
    RightTop,
    RightBottom,
    Float
}

const MapViewPositionToPanel = {
    [ViewPosition.RightTop]: PanelPosition.Right,
    [ViewPosition.RightBottom]: PanelPosition.Right,
    [ViewPosition.LeftSecond]: PanelPosition.LeftSecond,
    [ViewPosition.LeftTop]: PanelPosition.Left,
    [ViewPosition.LeftBottom]: PanelPosition.Left,
}

export interface PanelDescription {
    open: boolean;
    width: number;
    height?: number;
    views: Array<string | undefined>
    collapsed: boolean;
}

export class NavbarStore {
    expanded: boolean = true;
    panels = new DefaultMap<PanelPosition, PanelDescription>(() => ({
        open: false,
        width: 520,
        views: [],
        collapsed: false
    }));
    views: Record<string, ViewPosition> = {};
    overlayPanel?: string;
    dragViewId: string | null = null;
}

export class NavbarContext extends BindedStore<NavbarStore> {
    private _stateStorageKey = "ui-navbar";

    constructor(readonly layout: EngineUiLayout) {
        super(new NavbarStore);
        this.restoreFromLocalStorage();

        this.toggle = this.toggle.bind(this);
    }

    restoreFromLocalStorage() {
        const navbarState = localStorage.getItem(this._stateStorageKey);
        if (navbarState) {
            const storageData = JSON.parse(navbarState);
            const panels: [string, PanelDescription][] = Object.entries(storageData.panels);
            for (const [position, panelInfo] of panels) {
                const pos = position as keyof typeof PanelPosition;
                this._storeValue.panels.set(PanelPosition[pos], panelInfo);
            }
            let views = storageData.views;
            if (!views) {
                views = this.migrateToViews(storageData);
            }
            this.set({
                ...storageData,
                panels: this._storeValue.panels,
                views: views
            });
        }
    }

    saveToLocalStorage() {
        const storageData = {
            expanded: this._storeValue.expanded,
            views: this._storeValue.views,
            panels: Object.fromEntries(
                [...this._storeValue.panels.entries()]
                    .map(([key, value]) => ([PanelPosition[key], value]))
            )
        };

        localStorage.setItem(this._stateStorageKey, JSON.stringify(storageData));
    }

    migrateToViews(storageData: any) {
        const views: Record<string, ViewPosition> = {};
        storageData.float?.forEach((id: string) => {
            views[id] = ViewPosition.Float;
        });
        storageData.right?.forEach((id: string) => {
            views[id] = ViewPosition.RightTop;
        });
        Object.entries(MapViewPositionToPanel).forEach(([position, panel]) => {
            const viewPosition = Number(position);
            if (viewPosition !== ViewPosition.RightTop) {
                const viewIndex = Number(
                    viewPosition === ViewPosition.RightBottom ||
                    viewPosition === ViewPosition.LeftBottom
                );
                const viewId = this._storeValue.panels.getOrCreate(panel).views[viewIndex];
                if (viewId) {
                    views[viewId] = viewPosition;
                }
            }
        });
        return views;
    }

    updateAndSave(updater: (s: NavbarStore) => NavbarStore): void {
        this.update(updater);
        this.saveToLocalStorage();
    }

    toggle() {
        const expanded = !this._storeValue.expanded;
        this.updateAndSave(s => (s.expanded = expanded, s));
    }

    isExpanded() {
        return this._storeValue.expanded;
    }

    syncTabsPosition(tabs: NavbarItem[]) {
        const views = this._storeValue.views;
        const toUpdate: Record<string, ViewPosition> = {};
        tabs.forEach(tab => {
            if (views[tab.id] === undefined) {
                toUpdate[tab.id] = tab.view.position === PanelViewPosition.None || tab.view.position === PanelViewPosition.Float
                    ? ViewPosition.Float
                    : ViewPosition.LeftTop;
            } else if (tab.view.position === PanelViewPosition.Fixed && views[tab.id] !== ViewPosition.LeftTop) {
                this.closeTab(tab.id);
                toUpdate[tab.id] = ViewPosition.LeftTop;
            }
        });
        if (Object.keys(toUpdate)) {
            this.updateAndSave(s => (s.views = { ...s.views, ...toUpdate }, s));
        }
    }

    getPathByPosition(position: Exclude<ViewPosition, ViewPosition.Float>): {panel: PanelPosition, view: number} {
        return {
            panel: MapViewPositionToPanel[position],
            view: Number(position === ViewPosition.RightBottom || position === ViewPosition.LeftBottom)
        };
    }
    private openViewInPanel(id: string, position: ViewPosition, cbFn: (fn: (s: NavbarStore) => NavbarStore) => void) {
        if (position === ViewPosition.Float) {
            this.layout.focusPanel(id);
            return;
        }
        const { panel: currentPanel, view: currentView } = this.getPathByPosition(position);
        cbFn((s) => {
            const panel = s.panels.getOrCreate(currentPanel);
            panel.views[currentView] = id;
            panel.open = true;
            if (currentView) {
                panel.collapsed = false;
            }
            return s;
        });
    }

    openTab(id: string) {
        this.openViewInPanel(id, this._storeValue.views[id], (fn) => this.updateAndSave(s => fn(s)));
    }

    closeTab(id: string) {
        const position = this._storeValue.views[id];
        if (position === ViewPosition.Float) {
            this.layout.togglePanelEnabled(id);
        } else {
            this.updateAndSave(s => {
                this._resetCurrentPosition(s, position);
                return s;
            });
        }
    }

    toggleLayout(id: string) {
        this.layout.togglePanelEnabled(id);
    }

    changeViewPosition(id: string, position: ViewPosition) {
        this.updateAndSave(s => {
            const currentPosition = this._storeValue.views[id];
            if (currentPosition === ViewPosition.Float) {
                this.layout.togglePanelEnabled(id);
            } else {
                this._resetCurrentPosition(s, currentPosition);
            }
            s.views[id] = position;
            this.openViewInPanel(id, position, (fn) => fn(s));
            return s;
        });
    }

    private _resetCurrentPosition(s: NavbarStore, position: Exclude<ViewPosition, ViewPosition.Float>) {
        const { panel: currentPanel, view: currentView } = this.getPathByPosition(position);
        const panelToClose = s.panels.getOrCreate(currentPanel);
        panelToClose.views[currentView] = undefined;
        if (panelToClose.views.every(v => !v)) {
            panelToClose.open = false;
        }
    }

    closePanel(panel: PanelPosition) {
        this.updateAndSave(s => {
            const panelInfo = s.panels.getOrCreate(panel);
            panelInfo.open = false;
            panelInfo.views = [];
            return s;
        });
    }

    toggleView(panel: PanelPosition) {
        const collapsed = this._storeValue.panels.getOrCreate(panel).collapsed;
        this.updateAndSave(s => (s.panels.get(panel)!.collapsed = !collapsed, s));
    }

    setPanelHeight(position: PanelPosition, height: number) {
        this.updateAndSave(s => (s.panels.getOrCreate(position).height = height, s));
    }

    setPanelWidth(position: PanelPosition, width: number) {
        this.updateAndSave(s => (s.panels.getOrCreate(position).width = width, s));
    }

    openOverlayPanel(id?: string) {
        this.update(s => (s.overlayPanel = id, s));
    }

    dragView(id: string | null) {
        this.update(s => (s.dragViewId = id, s));
    }
}
