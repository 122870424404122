import type { Bim, IdBimGeo, IdBimScene, IdInEntityLocal, LocalIdsEdge, SegmentInterp} from 'bim-ts';
import {
	BasicAnalyticalRepresentation, BimPatch, GraphGeometry, LocalIdsCounter, SceneInstanceFlags, SegmentInterpLinearG
} from 'bim-ts';
import type { LazyVersioned, UndoStack , Result } from 'engine-utils-ts';
import { Failure, IterUtils, LazyDerived, nameof, ObjectUtils, ObservableObject, Success } from 'engine-utils-ts';
import type { Vector3 } from 'math-ts';
import { Clipper } from 'math-ts';
import type { MenuPath} from 'ui-bindings';
import { EditActionResult, PUI_ConfigBasedBuilderParams, PUI_ConfigPropertyTransformer } from 'ui-bindings';
import type { SceneInt } from '../scene/SceneRaycaster';
import { calcuateObjectBoundaryPoints } from '../utils/BimObjectBoundary';
import type { EditModeControls } from './EditControls';
import { MouseButton } from './InputController';
import type { EditInteractionResult, InteractiveEditOperator } from './InteractiveEditOperator';
import type { MouseEventData } from './MouseGesturesBase';

export class RoadOffsetSetting {
	offset: number = 0.3048 * 5;
	zonesJoinDelta: number = 0.3048 * 10;
}

type InstancesDescr =  [IdBimScene, IdBimGeo, GraphGeometry][];

export interface RoadOffsetState {
	sourceObjects: IdBimScene[];
	appliedSettings: RoadOffsetSetting | null;
	resultBoundariesAfterJoin: Vector3[][];
	resultObjects: InstancesDescr;
}


export class InteractiveRoadAround implements InteractiveEditOperator<RoadOffsetState, RoadOffsetSetting> {

	readonly menuPath: MenuPath = ['Add', 'Road', 'Around selected'];
	readonly priority: number = 3;
	readonly canStart: LazyVersioned<boolean>;
	readonly config: ObservableObject<RoadOffsetSetting>;

	constructor(
		readonly bim: Bim,
		readonly editControls: EditModeControls,
		undoStack: UndoStack
	) {
		this.canStart = LazyDerived.new1(
			'isSelected',
			[],
			[bim.instances.selectHighlight.getVersionedFlagged(SceneInstanceFlags.isSelected)],
			([s]) => s.length > 0
		);

		this.config = new ObservableObject({
			identifier: this.menuPath.join(),
			initialState: new RoadOffsetSetting(),
			undoStack,
			throttling: { onlyFields: [] }
		});
	}

	configBuilderSettings(): PUI_ConfigBasedBuilderParams {
		return PUI_ConfigBasedBuilderParams.new([
			[
				nameof<RoadOffsetSetting>("offset"),
				PUI_ConfigPropertyTransformer.numberProp({
					minMax: [-500, 500],
					unit: "m"
				}),
			],
			[
				nameof<RoadOffsetSetting>("zonesJoinDelta"),
				PUI_ConfigPropertyTransformer.numberProp({
					minMax: [0, 500],
					unit: "m"
				}),
			],

			// [
			//     (_name, value, _path) => value instanceof Vector3,
			//     ConfigPropertyTransformer.vector3({unit: 'm'})
			// ]
		]);
	};


	start(): Result<RoadOffsetState> {
		const selected = this.bim.instances.getSelected();
		if (selected.length === 0) {
			return new Failure({msg: 'nothing selected'});
		}
		let state: RoadOffsetState = {
			sourceObjects: selected,
			appliedSettings: null,
			resultBoundariesAfterJoin: [],
			resultObjects: [],
		};
		state = this._reconcileStateWithConfig(state);
		return new Success(state);
	}
	finish(state: Readonly<RoadOffsetState>): EditActionResult | undefined {
		const ids = state.resultObjects.map(t => t[0]);
		this.bim.instances.setSelected(ids);
		return new EditActionResult(ids);
	}
	cancel(state: RoadOffsetState) {
		this.bim.instances.delete(state.resultObjects.map(t => t[0]));
	}
	handleConfigPatch(
		patch: Partial<RoadOffsetSetting>,
		state: Readonly<RoadOffsetState>
	): EditInteractionResult<RoadOffsetState> {
		const newState = this._reconcileStateWithConfig(state);
		return {
			done: false,
			state: newState
		};
	}

	onHover(int: SceneInt): { cursorStyleToSet: string; } | null {
		return null;
	}

	handleClick(
		sceneInt: SceneInt,
		me: MouseEventData,
		previousResult: RoadOffsetState
	): EditInteractionResult<RoadOffsetState> {
		if (me.buttons.mouseButton === MouseButton.Right) {
			return {
				state: previousResult,
				done: true,
			};
		}
		return {
			done: false,
			state: previousResult
		};
	}


	_reconcileStateWithConfig(prevState: Readonly<RoadOffsetState>): RoadOffsetState {
		const config = this.config.poll();
		let state: RoadOffsetState = {
			sourceObjects: prevState.sourceObjects,
			appliedSettings: prevState.appliedSettings,
			resultBoundariesAfterJoin: prevState.resultBoundariesAfterJoin,
			resultObjects: prevState.resultObjects,
		};

		if (state.appliedSettings === null || !ObjectUtils.areObjectsEqual(config, state.appliedSettings)) {
			state.appliedSettings = ObjectUtils.deepCloneObj(config);

			const instanceToCalcAround = Array.from(this.bim.instances.peekByIds(state.sourceObjects).values());
			const instancesBoundaries = IterUtils.filterMap(instanceToCalcAround, (inst) => {
				const RoadPoins = calcuateObjectBoundaryPoints(inst, this.bim);
				return RoadPoins.length > 0 ? RoadPoins : undefined;
			});

			state.resultBoundariesAfterJoin = Clipper.calculateBoundariesWithJoinTolerance(instancesBoundaries, config.zonesJoinDelta, config.offset);

			const objsToDeleteCount = state.resultObjects.length - state.resultBoundariesAfterJoin.length;
			if (objsToDeleteCount > 0) {
				const toDelete = state.resultObjects.splice(state.resultBoundariesAfterJoin.length);
				const ids = toDelete.map(t => t[0]);
				this.bim.instances.delete(ids);
			}

			const bimPatch = new BimPatch();

			for (let i = 0; i < state.resultBoundariesAfterJoin.length; ++i) {

				const points = state.resultBoundariesAfterJoin[i];


				const pointsPerId = new Map<IdInEntityLocal, Vector3>();
				for (let i = 0; i < points.length; ++i) {
					pointsPerId.set(i as IdInEntityLocal, points[i]);
				}
				const edges = new Map<LocalIdsEdge, SegmentInterp>();
				for (let i = 0; i < points.length; ++i) {
					const id1 = i;
					const id2 = (i + 1) % points.length;
					const edge = LocalIdsCounter.newEdge(id1 as IdInEntityLocal, id2 as IdInEntityLocal);
					edges.set(edge, SegmentInterpLinearG);
				}
				const newGeo = new GraphGeometry(pointsPerId, edges);

				if (state.resultObjects[i] === undefined) {
					// allocate new
					const geoId = this.bim.graphGeometries.reserveNewId();
					const instanceId = this.bim.instances.reserveNewId();

					state.resultObjects[i] = [instanceId, geoId, newGeo];

					bimPatch.geometries.toAlloc.push([geoId, newGeo]);

					const instance = this.bim.instances.archetypes.newDefaultInstanceForArchetype('road');
					instance.representationAnalytical = new BasicAnalyticalRepresentation(geoId);
					bimPatch.instances.toAlloc.push([instanceId, instance]);

				} else {
					const [id, geoId, prevGeo] = state.resultObjects[i];

					if (ObjectUtils.areObjectsEqual(prevGeo, newGeo)) {
						continue;
					}

					state.resultObjects[i][2] = newGeo;
					bimPatch.geometries.toPatch.push([geoId, newGeo]);
				}
			}

			bimPatch.applyTo(this.bim);
		}

		return state;
	}
}



