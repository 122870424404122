import { CachingNetworkClient } from './CachingNetworkClient';
import type { ProjectNetworkClient } from './ProjectNetworkClient';
import { LogLevel, ScopedLogger } from './ScopedLogger';
import { LocalNotifier } from './stateSync/LocalNotifier';
import { RemoteSyncHub } from './stateSync/RemoteSyncHub';
import { WebSocketHub } from './stateSync/WebSocketHub';

const networkGlobals: WeakMap<ProjectNetworkClient, Map<string, any>> = new WeakMap();

// const otherGlobals: Map<string, any> = new Map();

const _LocalNotifier = new LocalNotifier();
export class Globals {

    private static _getPerProjCache(pnc: ProjectNetworkClient): Map<string, any> {
        let cached = networkGlobals.get(pnc);
        if (!cached) {
            cached = new Map();
            networkGlobals.set(pnc, new Map());
        }
        return cached;
    }
    
    static getWebsocketHub(projectNetworkClient: ProjectNetworkClient, key: string): WebSocketHub {
        const cached = this._getPerProjCache(projectNetworkClient);
        const cacheKey = 'webscoket_hub' + key;
        if (!cached.has(cacheKey)) {
            const fullUrl = projectNetworkClient.getFullUrl(key);
            const hub = new WebSocketHub(new ScopedLogger('ws_hub-' + key, LogLevel.Debug), fullUrl);
            cached.set(cacheKey, hub);
            projectNetworkClient.withDisposeListener(() => {
                hub.dispose();
                cached.delete(cacheKey);
            });
        }
        return cached.get(cacheKey)!;
    }

    static getSyncHub(projectNetworkClient: ProjectNetworkClient): RemoteSyncHub {
        const cached = this._getPerProjCache(projectNetworkClient);
        const cacheKey = 'default_sync_hub';
        if (!cached.has(cacheKey)) {
            const wsHub = Globals.getWebsocketHub(projectNetworkClient, 'ws-sync');
            const syncHub = new RemoteSyncHub(new ScopedLogger('sync_hub', LogLevel.Default), wsHub);
            cached.set(cacheKey, syncHub);
            projectNetworkClient.withDisposeListener(() => {
                networkGlobals.delete(projectNetworkClient);
                wsHub.dispose();
                cached.delete(cacheKey);
            })
        }
        return cached.get(cacheKey)!;
    }
    
    static getCachingClient(projectNetworkClient: ProjectNetworkClient): CachingNetworkClient {
        const cached = this._getPerProjCache(projectNetworkClient);
        const cacheKey = 'caching_net_client';
        if (!cached.has(cacheKey)) {
            cached.set(cacheKey, new CachingNetworkClient(projectNetworkClient));
        }
        return cached.get(cacheKey);
    }

    static getNotifier(): LocalNotifier {
        return _LocalNotifier;
    }

    // static getGlobalUndo(projectNetworkClient: ProjectNetworkClient): CachingNetworkClient {
    //     const cached = this._getPerProjCache(projectNetworkClient);
    //     const cacheKey = 'global_undo';
    //     if (!cached.has(cacheKey)) {
    //         cached.set(cacheKey, new UndoStack('global_undo'));
    //     }
    //     return cached.get(cacheKey);
    // }
}

