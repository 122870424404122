import { EquipmentArea, MetricTableValues, MetricsGroup, MetricsRows, MetricsTableValueType, UnitsMapper, mergeMetricsToTable } from "bim-ts";
import { ErrorUtils, IterUtils, PollablePromise, Result, ScopedLogger, Yield } from "engine-utils-ts";
import Excel, { Style } from "exceljs";


const headerStyle: Partial<Style> = { font: { bold: true, size: 9  }, fill: {type: 'pattern', pattern: 'solid', fgColor: { argb: 'F2F2F2' } }};
const rowStyle: Partial<Style> = { font: { size: 8 } };
const groupStyles:Partial<Style>[] = [
    { font: { bold: true, size: 10 }}, 
    { font: { bold: true, size: 8 }, fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BFBFBF' } }},
    { font: { bold: true, size: 8 } },
    { font: { bold: true, size: 8 } },
];


const defaultWidthPerHeader: Record<string, number> = {
    Metric: 40,
    Unit: 5,
};

function getGroupStyle(level: number): Partial<Style> {
    return groupStyles[level] ?? groupStyles[groupStyles.length - 1];
}


export function* convertMetricsToXlsx(
    unitsMapper: UnitsMapper,
    metrics: MetricsGroup[], 
    areas: EquipmentArea[], 
    logger: ScopedLogger,
    ): Generator<Yield, Result<ArrayBuffer>, unknown>  {

    yield Yield.Asap;
    const table = mergeMetricsToTable(metrics, areas, unitsMapper, logger);

    const headers: string[] = ["Metric", "Unit"];
    IterUtils.extendArray(headers, table.headers);

    // Flatten the PropertyGroup object
    const flattenedData = flattenPropertyGroup(table.rows);

    // Create a new workbook
    const workbook = new Excel.Workbook();

    // Add a worksheet
    const worksheet = workbook.addWorksheet('Project Metrics');
    worksheet.properties.defaultColWidth = 15;


    // Add headers
    worksheet
        .addRow(headers)
        .eachCell((cell, colIndex) => {
            cell.style = headerStyle;
            cell.value = cell.value?.toString().toUpperCase();
            const width = defaultWidthPerHeader[headers[colIndex-1]];
            if(width){
                worksheet.getColumn(colIndex).width = width;
            }
        });

    // Add data rows
    for (const row of flattenedData) {
        const style = row.groupLevel != undefined ? getGroupStyle(row.groupLevel) : rowStyle;
        const newRow = worksheet.addRow([row.key, row.unit, ...row.value]);
        const cellStyle: Partial<Excel.Style> = {
            ...style,
            numFmt: row.decimals ? '0.00' : undefined,
        }
        for (let i = 1; i <= headers.length; i++) {
            newRow.getCell(i).style = cellStyle;
        }
    }

    const workbookBufferPromise = workbook.xlsx.writeBuffer();
    
    const buffer = yield* PollablePromise.generatorWaitFor(workbookBufferPromise);
    return buffer;
}

export function flattenPropertyGroup(table:MetricsRows, groupLevel?:number) {
    let rows:{key:string, value: MetricsTableValueType[], unit?: string, decimals?: number, groupLevel?: number}[] = [];
    for (const [_key, prop] of table.rows) {
        if (prop instanceof MetricTableValues) {
            rows.push({ key: prop.name, value: prop.value, unit: prop.displayUnit, decimals: prop.decimals });
        } else if(prop instanceof MetricsRows) {
            const level: number = groupLevel ? groupLevel + 1 : 1;
            const total = prop.tryCalculateTotal();
            rows.push({ 
                key: prop.name, 
                value: total?.value ?? [], 
                unit: total?.displayUnit, 
                decimals: total?.decimals, 
                groupLevel: level
            }); 
            rows = rows.concat(flattenPropertyGroup(prop, level));
        } else {
            ErrorUtils.logThrow('unexpected type', prop);
        }
    }

    return rows;
}
