import type { LazyVersioned, VersionedValue } from 'engine-utils-ts';
import { DefaultMap, LazyDerived, LegacyLogger } from 'engine-utils-ts';
import { KrMath } from 'math-ts';

export class SparseFlaggedSets<Id, Flags extends number> {

    flagsMask: Flags;

    perIdFlags: Map<Id, Flags> = new Map();

    _perFlagVersions: DefaultMap<Flags, PerFlagVersion>;
    _flaggedArrays: DefaultMap<Flags, LazyDerived<Id[]>>;

    _dirtyFlags: Flags = 0 as Flags;



    constructor(flagsMask: Flags) {
        this.flagsMask = flagsMask;
        this._perFlagVersions = new DefaultMap((flag) => new PerFlagVersion(flag, this));

        this._flaggedArrays = new DefaultMap(
            flag => LazyDerived.new0(
                `versioend flaged (${flag}) array`,
                [this.getInvalidatorOf(flag)],
                () => Object.freeze(this.flagged(flag)) as Id[],
            )
        );
    }

    getInvalidatorOf(flag: Flags) {
        LegacyLogger.assert(KrMath.isPowerOfTwo(flag), 'check that single entity flag is requested');
        LegacyLogger.assert((this.flagsMask & flag) != 0, 'check that requested flag is contained here');
        return this._perFlagVersions.getOrCreate(flag);
    }

    getVersionedFlagged(flag: Flags): LazyVersioned<Id[]> {
        return this._flaggedArrays.getOrCreate(flag);
    }

    anyFlagged(flag: Flags): boolean {
        LegacyLogger.debugAssert((flag & this.flagsMask) != 0, 'flagged ids sanity check', flag);
        for (const flags of this.perIdFlags.values()) {
            if (flags & flag) {
                return true;
            }
        }
        return false;
    }
    flagged(flag: Flags): Id[] {
        LegacyLogger.debugAssert((flag & this.flagsMask) != 0, 'flagged ids sanity check', flag);
        const res: Id[] = [];
        for (const [id, flags] of this.perIdFlags) {
            if (flags & flag) {
                res.push(id);
            }
        }
        return res;
    }
    lastFlagged(flag: Flags): Id | undefined {
        LegacyLogger.debugAssert((flag & this.flagsMask) != 0, 'flagged ids sanity check', flag);
        let res: Id | undefined = undefined;
        for (const [id, flags] of this.perIdFlags) {
            if (flags & flag) {
                res = id
            }
        }
        return res;
    }

    diffToMakeFlagEnabledOnlyFor<Patch>(
        flag: Flags,
        ids: Id[],
        patchToEnable: Patch,
        patchToDisable: Patch,
    ): [Id, Patch][] {
        const res: [Id, Patch][] = [];
        const idsToBeEnabled = new Set(ids);
        for (const [id, flags] of this.perIdFlags) {
            const isEnabled = flags & flag;
            const toBeEnabled = idsToBeEnabled.has(id);
            if (toBeEnabled) {
                if (!isEnabled) {
                    res.push([id, patchToEnable]);
                }
                idsToBeEnabled.delete(id);
            } else if (isEnabled) {
                res.push([id, patchToDisable]);
            }
        }
        // enable what is left
        for (const id of idsToBeEnabled) {
            res.push([id, patchToEnable]);
        }
        return res;
    }

    updateSceneInstanceFlagsFor(id: Id, newFlags: Flags) {
        newFlags = (this.flagsMask & newFlags) as Flags;
        const oldFlags = this.perIdFlags.get(id) as number | 0;
        const flagsDiff = newFlags ^ oldFlags;
        this._dirtyFlags = (this._dirtyFlags | flagsDiff) as Flags;
        if (newFlags) {
            this.perIdFlags.set(id, newFlags);
        } else {
            this.perIdFlags.delete(id);
        }
    }

	clearFor(id: Id) {
		const oldFlags = this.perIdFlags.get(id) as number | 0;
		if (oldFlags !== undefined) {
        	this._dirtyFlags = (this._dirtyFlags | oldFlags) as Flags;
			this.perIdFlags.delete(id);
		}
	}

    clear() {
        // this._version += 1;
        this.perIdFlags.clear();
    }
}

class PerFlagVersion implements VersionedValue {

    readonly flag: number;
    readonly _sparseSceneInstanceFlagsRef: SparseFlaggedSets<any, any>;
    _version = 0;

    constructor(flag: number, sparseSceneInstanceFlags: SparseFlaggedSets<any, any>) {
        LegacyLogger.debugAssert(KrMath.isPowerOfTwo(flag), 'check that single entity flag is used, overlapping Flags combinations will cause bugs in invalidation');
        this.flag = flag;
        LegacyLogger.debugAssert((sparseSceneInstanceFlags.flagsMask & this.flag) != 0, 'flag sanity check')
        this._sparseSceneInstanceFlagsRef = sparseSceneInstanceFlags;
    }

    version(): number {
        const dirtySceneInstanceFlags = this._sparseSceneInstanceFlagsRef._dirtyFlags;
        if (dirtySceneInstanceFlags & this.flag) {
            this._sparseSceneInstanceFlagsRef._dirtyFlags &= (~this.flag);
            this._version += 1;
        }
        return this._version;
    }

}

