import { DefaultMapWeak } from 'engine-utils-ts';
import type { Plane, TypedArray} from 'math-ts';
import { Aabb, Matrix4, Ray, Vector3 } from 'math-ts';
import { GeometriesUtils } from 'bim-ts';

import type { BufferGeometry } from '../3rdParty/three';
import { BufferAttribute } from '../3rdParty/three';
import type { KrCamera } from '../controls/MovementControls';
import { scaleForCamera } from '../gizmos/GizmoUtils';
import type { RaySection } from '../structs/RaySection';
import { GeometryGpuRepr } from './KrBufferGeometry';

export function createIndexArrayOfSize(size:number, verticesCount:number) {
	return verticesCount < 65535 ? new Uint16Array(size) : new Uint32Array(size);
}

export interface GeometryData {
	vertices: Float32Array;
	normals?: Int8Array;
	uvs?: Float32Array | null;
	indices: Uint16Array | Uint32Array;
	edgesInds?: Uint16Array | Uint32Array | null;
}


export function CreateKrGeometryFromData(data: GeometryData): GeometryGpuRepr {
	const uvs = data.uvs ? new BufferAttribute(data.uvs, 2) : undefined;
	const edgeIndex = data.edgesInds ?? GeometriesUtils.findSurfacesBorderEdgesIndices(data.vertices, data.indices);
	const g = new GeometryGpuRepr(
		{
			position: new BufferAttribute(data.vertices, 3),
			normal: data.normals ? new BufferAttribute(data.normals, 3, true) : undefined,
			uv: uvs,
		},
		new BufferAttribute(data.indices, 1),
		new BufferAttribute(edgeIndex, 1),
	);
	return g;
}


export function calculateUvs(positions: Float32Array, normals: Int8Array): Float32Array {
    const uvs = new Float32Array(positions.length * 2 / 3);
	
	const vertsNumber = positions.length / 3;

	for (let i = 0; i < vertsNumber; ++i) {
        const i3 = i * 3;

        const x = normals[i3 + 0];
        const y = normals[i3 + 1];
        const z = normals[i3 + 2];
		
		const ax = Math.abs(x);
		const ay = Math.abs(y);
		const az = Math.abs(z);

        const max = Math.max(ax, ay, az);
        let u;
        let v;
        if (max === ax) {
            u = positions[i3 + 2] * Math.sign(x);
            v = positions[i3 + 1];
        } else if (max === ay) {
            if (ax < az) {
				u = positions[i3 + 0] * -Math.sign(y);
				v = positions[i3 + 2];
            } else {
				u = positions[i3 + 2];
				v = positions[i3 + 0] * -Math.sign(y);
            }
		} else {
			u = positions[i3 + 0] * -Math.sign(z);
			v = positions[i3 + 1];
		}

		uvs[i * 2] = u;
		uvs[i * 2 + 1] = v;
	}
	return uvs;
}


export const enum GeometrySide { // values are equal to corresponding three.js constants
	FrontSide = 0,
	BackSide = 1,
	DoubleSide = 2
}

export const enum IntersectionType {
	Outside = 0b0,
	Partial = 0b1,
	Full 	= 0b11,
}

export interface GeometryIntersection {
	distance: number;
	point: Vector3; // world space
	normal: Vector3
}

export class GeometryUtils {

	static getClosestIntersection(ints: Iterable<GeometryIntersection>): GeometryIntersection | null {
		let minDistanceYet = Infinity;
		let closesetIntersYet: GeometryIntersection | null = null;
		for (const int of ints) {
			if (int.distance < minDistanceYet) {
				minDistanceYet = int.distance;
				closesetIntersYet = int;
			}
		}
		return closesetIntersYet;
	}

	static planesVolumeContainsPoint(planes:Plane[], point: Vector3): boolean {
		for (const p of planes) {
			const distance = p.distanceToPoint(point);
			if (distance < -0.000001 || isNaN(distance)) {
				return false;
			}
		}
		return true;
	}

	static trianglePlanesIntersection = (function () {

		const edgeIntersResult = Vector3.zero();

		return function (planes: Plane[], a: Vector3, b: Vector3, c: Vector3): IntersectionType {
			
			let pointsInside = 0b111;
	
			for (const p of planes) {
				const pointsAtIterationStart = pointsInside;

				if ((pointsInside & 0b001) && p.distanceToPoint(a) < 0) {
					pointsInside &= 0b110;
				}
				if ((pointsInside & 0b010) && p.distanceToPoint(b) < 0) {
					pointsInside &= 0b101;
				}
				if ((pointsInside & 0b100) && p.distanceToPoint(c) < 0) {
					pointsInside &= 0b011;
				}
				if (pointsInside === 0) {
					if (pointsAtIterationStart === 0b111) { // early exit, all points are definitely outside of this one plane
						return IntersectionType.Outside;
					}
					break;
				}
			}
			if (pointsInside === 0b111) {
				return IntersectionType.Full;
			}
			if (pointsInside > 0) {
				return IntersectionType.Partial;
			}

			// now try to find plane that cuts out all 3 vertices
			for (const p of planes) {
				if ((p.distanceToPoint(a) < 0) && (p.distanceToPoint(b) < 0) && (p.distanceToPoint(c) < 0)) {
					return IntersectionType.Outside;
				}
			}
			// no luck, now check edges for intersection points inside this volumes again
			for (const p of planes) {
				let lineInt = p.intersectLine(a, b, edgeIntersResult);
				if (lineInt !== null && GeometryUtils.planesVolumeContainsPoint(planes, lineInt)) {
					return IntersectionType.Partial;
				}
				lineInt = p.intersectLine(a, c, edgeIntersResult);
				if (lineInt !== null && GeometryUtils.planesVolumeContainsPoint(planes, lineInt)) {
					return IntersectionType.Partial;
				}
				lineInt = p.intersectLine(b, c, edgeIntersResult);
				if (lineInt !== null && GeometryUtils.planesVolumeContainsPoint(planes, lineInt)) {
					return IntersectionType.Partial;
				}
			}
			// at this point, triangle could be intersecting volume (frustum completely inside big triangle)
			// but fuck it, edges intersection check is good enough for now
			return IntersectionType.Outside;
		}
	})();


	// note: can be used to check geometry intersection with volume described by array of planes
	// for instance frusutm, clipbox, or both simultaneously
	static checkPlanesVolumeIntersection = (function () {
	
		const vA = Vector3.zero();
		const vB = Vector3.zero();
		const vC = Vector3.zero();

		return function (positions: TypedArray, trianglesIndices: number[] | Uint16Array | Uint32Array, geometrySpacePlanes:Plane[]): IntersectionType {

			let insideCount = 0;
			let outsideCount = 0;
	
			for (let i = 0; i < trianglesIndices.length; i += 3) {
	
				const a = trianglesIndices[i];
				const b = trianglesIndices[i + 1];
				const c = trianglesIndices[i + 2];
			
				vA.setFromArray(positions, a * 3);
				vB.setFromArray(positions, b * 3);
				vC.setFromArray(positions, c * 3);
	
				const triangleIntersection = GeometryUtils.trianglePlanesIntersection(geometrySpacePlanes, vA, vB, vC);

				if (triangleIntersection === IntersectionType.Partial) {
					return IntersectionType.Partial;
				} else if (triangleIntersection === IntersectionType.Outside) {
					if (insideCount > 0) {
						return IntersectionType.Partial;
					}
					outsideCount += 1;
				} else if (triangleIntersection == IntersectionType.Full) {
					if (outsideCount > 0) {
						return IntersectionType.Partial;
					}
					insideCount += 1;
				}
			}
			if (insideCount) {
				return IntersectionType.Full;
			}
			return IntersectionType.Outside;
		}
	})();

	static checkPlanesWithPolylinePointsIntersection = (function () {
	
		const prev = new Vector3();
		const curr = new Vector3();


		return function (points: Float32Array | Float64Array, geometrySpacePlanes:Plane[]): IntersectionType {

			let insideCount = 0;
			let outsideCount = 0;

	
			for (let i = 3; i < points.length; i += 3) {
				prev.setFromArray(points, i - 3);
				curr.setFromArray(points, i);
				
				const int = GeometryUtils.trianglePlanesIntersection(geometrySpacePlanes, prev, curr, prev); //todo, no need for triangle here
				if (int === IntersectionType.Partial) {
					return IntersectionType.Partial;
				} else if (int === IntersectionType.Outside) {
					if (insideCount > 0) {
						return IntersectionType.Partial;
					}
					outsideCount += 1;
				} else if (int == IntersectionType.Full) {
					if (outsideCount > 0) {
						return IntersectionType.Partial;
					}
					insideCount += 1;
				}
			}
			if (insideCount) {
				return IntersectionType.Full;
			}
			return IntersectionType.Outside;
		}
	})();

	static raycastGeometry (
		geometry: BufferGeometry,
		modelMatrix: Matrix4,
		raySection: RaySection,
	): GeometryIntersection[] {


		const result: GeometryIntersection[] = [];

		const index = geometry.index.array;
		const position = geometry.attributes.position.array;

		if (!index) {
			console.warn('no indices in geometry', geometry);
			return result;
		}
		if (!position) {
			console.warn('no positions in geomemtry', geometry);
			return result;
		}


		if (index.length > GeometryIndexSizeToSpeedUpIntersection) {

			_inverseMatrix.getInverse( modelMatrix );
			_geometrySpaceRay.copy(raySection.ray);
			_geometrySpaceRay.applyMatrix4(_inverseMatrix);

			const subranges = geometriesSubrangesForFasterRaycasts.getOrCreate(geometry);
			for (const r of subranges) {
				if (_geometrySpaceRay.intersectsBox(r.aabb)) {
					raySection.intersectGeometry(position, index, r.drawRange, GeometrySide.DoubleSide, modelMatrix, result);
				}
			}
		} else {
			raySection.intersectGeometry(position, index, geometry.drawRange, GeometrySide.DoubleSide, modelMatrix, result);
		}


		return result;
	
			// let minDistanceYet = Infinity;
			// let closesetIntersYet: GeometryIntersection | null = null;
			
			// let clipPlane: Plane | null = null;
			// // let isShaderClipped = GetMaterialFlagsFromIDF(rj.materialIDF) & ShaderFlags.PlaneClipping;
			// // if (isShaderClipped) {
			// // 	const uni = rj.dynamicUniforms.map.get('clippingPlane');
			// // 	if (uni) {
			// // 		const v = uni as Vector4;
			// // 		clipPlane = KrPlane.allocateZero().setComponents(v.x, v.y, v.z, v.w);
			// // 	}
			// // }
	
			// for (let i = 0; i < reusedGeometryIntersections.length; ++i){
			// 	const int = reusedGeometryIntersections[i];
				
			// 	const isInsideBox = clippingBox.containsPoint(int.point);
			// 	if (!(isInsideBox)) {
			// 		continue;
			// 	}
	
			// 	// if (clipPlane) {
			// 	// 	const projected = clipPlane.projectPoint_t(int.point);
			// 	// 	const above = projected.sub(int.point).dot(clipPlane.normal);
			// 	// 	if (above > 0) {
			// 	// 		continue;
			// 	// 	}
			// 	// }
			// 	if (int.distance < minDistanceYet) {
			// 		minDistanceYet = int.distance;
			// 		closesetIntersYet = int;
			// 	}
			// }
	
			// return closesetIntersYet;
	}


	static makePolylinePointsClosed(points: Vector3[]) {
		if (points.length > 1) {
			if (!points[0].equals(points[points.length - 1])) {
				points.push(points[0].clone());
			}
		}
	}

	
	static intersectPolylne(
		ray: Ray,
		points: Vector3[],
		matrixWorld: Matrix4,
		camera: KrCamera
	): {point: Vector3, distance: number, distanceCameraScaled: number} | undefined {
		let minDistanceScaled = Infinity;
		let minDistance = Infinity;
		let point = new Vector3();
		for (let i = 1; i < points.length; ++i) {
			const p1 = points[i-1].clone().applyMatrix4(matrixWorld);
			const p2 = points[i].clone().applyMatrix4(matrixWorld);
			const rayPoint = new Vector3();
			const segmentPoint = new Vector3();
			const distSq = ray.distanceSqToSegment(p1, p2, rayPoint, segmentPoint);
			const distance = Math.sqrt(distSq);
			const gizmoScale = scaleForCamera(segmentPoint, camera);
			const distScaled = distance / gizmoScale;
			if (distScaled < minDistanceScaled && distance < minDistance) {
				minDistanceScaled = distScaled;
				minDistance = distance;
				point = segmentPoint;
			}
		}
		if (minDistance < Infinity) {
			return {point, distance: minDistance, distanceCameraScaled: minDistanceScaled};
		}
		return undefined;
	}

}

const _inverseMatrix = new Matrix4();
const _geometrySpaceRay = new Ray(Vector3.zero(), Vector3.zero());

const GeometryIndexSizeToSpeedUpIntersection = 10_000;
const GeometryIndexChunksToCalcAabbsFor = 999;


export interface IndicesRange {
    start: number;
    count: number;
}

interface GeometryIndicesRangesBboxes {
	drawRange: IndicesRange,
	aabb: Aabb,
}

const geometriesSubrangesForFasterRaycasts = new DefaultMapWeak<BufferGeometry, GeometryIndicesRangesBboxes[]>((geo) => {
	
	const res: GeometryIndicesRangesBboxes[] = [];

	const index = geo.index.array;
	const position = geo.attributes.position.array;

	if (!index || !position) {
		console.warn('aabbsRanges calc: invalid geometry', geo);
		return res;
	}

	const v = new Vector3();
	const aabb = Aabb.empty();

	console.assert(index.length % 3 === 0, 'geometry triangle indices should be multiple of 3')

	let indexStart = 0;
	for (let i = 0; i < index.length; ++i) {
		const a = index[i];
		v.setFromArray( position, a * 3 );
		aabb.expandByPoint(v);

		if (i > 0 && (i % GeometryIndexChunksToCalcAabbsFor === 0 || (i === index.length - 1))) {
			res.push({
				aabb: aabb.clone(),
				drawRange: {start: indexStart, count: i - indexStart},
			});
			aabb.makeEmpty();
			indexStart = i;
		}
	}

	// TODO: merge subranges with similar aabbs
	
	return res;
})
