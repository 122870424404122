// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { SceneOriginT } from './scene-origin.js';
import { SceneOrigin } from './scene-origin.js';
import type { TypeIdentifierVersionT } from './type-identifier-version.js';
import { TypeIdentifierVersion } from './type-identifier-version.js';


export class CollectionAdditionalContext implements flatbuffers.IUnpackableObject<CollectionAdditionalContextT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):CollectionAdditionalContext {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsCollectionAdditionalContext(bb:flatbuffers.ByteBuffer, obj?:CollectionAdditionalContext):CollectionAdditionalContext {
  return (obj || new CollectionAdditionalContext()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsCollectionAdditionalContext(bb:flatbuffers.ByteBuffer, obj?:CollectionAdditionalContext):CollectionAdditionalContext {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new CollectionAdditionalContext()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

sharedEntitiesIds(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
}

sharedEntitiesIdsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

sharedEntitiesIdsArray():Uint32Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? new Uint32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

sceneOrigin(obj?:SceneOrigin):SceneOrigin|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new SceneOrigin()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

versionPerTypeIdentifier(index: number, obj?:TypeIdentifierVersion):TypeIdentifierVersion|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new TypeIdentifierVersion()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

versionPerTypeIdentifierLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startCollectionAdditionalContext(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addSharedEntitiesIds(builder:flatbuffers.Builder, sharedEntitiesIdsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, sharedEntitiesIdsOffset, 0);
}

static createSharedEntitiesIdsVector(builder:flatbuffers.Builder, data:number[]|Uint32Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createSharedEntitiesIdsVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createSharedEntitiesIdsVector(builder:flatbuffers.Builder, data:number[]|Uint32Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt32(data[i]!);
  }
  return builder.endVector();
}

static startSharedEntitiesIdsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addSceneOrigin(builder:flatbuffers.Builder, sceneOriginOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, sceneOriginOffset, 0);
}

static addVersionPerTypeIdentifier(builder:flatbuffers.Builder, versionPerTypeIdentifierOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, versionPerTypeIdentifierOffset, 0);
}

static createVersionPerTypeIdentifierVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startVersionPerTypeIdentifierVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endCollectionAdditionalContext(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishCollectionAdditionalContextBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset);
}

static finishSizePrefixedCollectionAdditionalContextBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, undefined, true);
}


unpack(): CollectionAdditionalContextT {
  return new CollectionAdditionalContextT(
    this.bb!.createScalarList<number>(this.sharedEntitiesIds.bind(this), this.sharedEntitiesIdsLength()),
    (this.sceneOrigin() !== null ? this.sceneOrigin()!.unpack() : null),
    this.bb!.createObjList<TypeIdentifierVersion, TypeIdentifierVersionT>(this.versionPerTypeIdentifier.bind(this), this.versionPerTypeIdentifierLength())
  );
}


unpackTo(_o: CollectionAdditionalContextT): void {
  _o.sharedEntitiesIds = this.bb!.createScalarList<number>(this.sharedEntitiesIds.bind(this), this.sharedEntitiesIdsLength());
  _o.sceneOrigin = (this.sceneOrigin() !== null ? this.sceneOrigin()!.unpack() : null);
  _o.versionPerTypeIdentifier = this.bb!.createObjList<TypeIdentifierVersion, TypeIdentifierVersionT>(this.versionPerTypeIdentifier.bind(this), this.versionPerTypeIdentifierLength());
}
}

export class CollectionAdditionalContextT implements flatbuffers.IGeneratedObject {
constructor(
  public sharedEntitiesIds: (number)[] = [],
  public sceneOrigin: SceneOriginT|null = null,
  public versionPerTypeIdentifier: (TypeIdentifierVersionT)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const sharedEntitiesIds = CollectionAdditionalContext.createSharedEntitiesIdsVector(builder, this.sharedEntitiesIds);
  const sceneOrigin = (this.sceneOrigin !== null ? this.sceneOrigin!.pack(builder) : 0);
  const versionPerTypeIdentifier = CollectionAdditionalContext.createVersionPerTypeIdentifierVector(builder, builder.createObjectOffsetList(this.versionPerTypeIdentifier));

  CollectionAdditionalContext.startCollectionAdditionalContext(builder);
  CollectionAdditionalContext.addSharedEntitiesIds(builder, sharedEntitiesIds);
  CollectionAdditionalContext.addSceneOrigin(builder, sceneOrigin);
  CollectionAdditionalContext.addVersionPerTypeIdentifier(builder, versionPerTypeIdentifier);

  return CollectionAdditionalContext.endCollectionAdditionalContext(builder);
}
}
