import type { SceneInstance, Bim} from "bim-ts";
import { BimGeometryType } from "bim-ts";
import { Vector3 } from "math-ts";
import { entityTypeFromId } from "verdata-ts";


export function calcuateObjectBoundaryPoints(obj: SceneInstance, bim: Bim): Vector3[] {
	const reprPoints = getRepresentationBoundaryPoints(obj, bim);
	for (const p of reprPoints) {
		p.applyMatrix4(obj.worldMatrix);
	}
	return reprPoints;
}

function getRepresentationBoundaryPoints(obj: SceneInstance, bim: Bim): Vector3[] {
	const geometiresAabbs = bim.allBimGeometries.aabbs.poll();

	const res: Vector3[] = [];
	if (obj.representationAnalytical) {

		const geoId = obj.representationAnalytical.geometryId;
		if (entityTypeFromId<BimGeometryType>(geoId) === BimGeometryType.ExtrudedPolygon) {
			const geo = bim.extrudedPolygonGeometries.peekById(geoId);
			if (geo) {
				for (const p2d of geo.outerShell.points) {
					res.push(new Vector3(p2d.x, p2d.y, geo.baseElevation));
				}
			}
		} else {
			const bbox = geometiresAabbs.get(geoId);
			if (bbox && (!bbox.isEmpty())) {
				const cornerPointsLow = bbox.get2DCornersAtZ(bbox.minz());
				for (const p of cornerPointsLow) {
					res.push(p);
				}
			}
		}
	} else if (obj.representation) {
		const bbox = obj.representation.aabb(geometiresAabbs);
		if (!bbox.isEmpty()) {
			const cornerPointsLow = bbox.get2DCornersAtZ(bbox.minz());
			for (const p of cornerPointsLow) {
				res.push(p);
			}
		}
	}
	return res;
}


