import { BimProperty } from "../bimDescriptions/BimProperty";
import type { NamedBimPropertiesGroup } from "../bimDescriptions/NamedBimPropertiesGroup";
import { NumberProperty } from "../properties/PrimitiveProps";
import type { SceneInstance } from "../scene/SceneInstances";
import type { UnitsMapper } from "../UnitsMapper";

export type PropertiesStringTemplateComponent = BimProperty | string | number | NumberProperty;
export type PropertiesStringTemplate = Array<PropertiesStringTemplateComponent>

export function stringifyProps(
    components: PropertiesStringTemplate,
    unitsMapper: UnitsMapper,
) {
    const stringComponents: Array<string> = [];
    for (const component of components) {
        if (component instanceof BimProperty) {
            stringComponents.push(component.valueUnitUiString(unitsMapper))
        } else if (component instanceof NumberProperty) {
            stringComponents.push(component.valueUnitUiString(unitsMapper));
        } else {
            stringComponents.push(component.toString());
        }
    }
    return stringComponents.join('');
}



export class BimPropertiesFormatter<T extends NamedBimPropertiesGroup> {
    constructor(
        public properties: T,
        public format: (properties: T) => PropertiesStringTemplate,
    ) {}
}

export const sceneInstanceHierarchyPropsRegistry = new Map<string, BimPropertiesFormatter<any>[]>();

export function extractInstanceHierarchyGroup(si: SceneInstance) {
    const propsFormatters = sceneInstanceHierarchyPropsRegistry.get(si.type_identifier);
    if (!propsFormatters) {
        return null;
    }
    const result: PropertiesStringTemplate[] = [];
    for (const formatter of propsFormatters) {
        const props = si.properties.extractPropertiesGroup(
            formatter.properties,
            { valueUnitOnly: true }
        );
        const template = formatter.format(props);
        result.push(template);
    }
    return result;
}
