<script lang="ts">

    import { getContext } from 'svelte';
    import { PUI_Lazy } from 'ui-bindings';
    import PUI_Root from '../pui/PUI_Root.svelte';
    import { bimInstancesCommonProps } from './PropertiesViewPuiGathering';
    import { SceneInstanceFlags, type Bim, Catalog, CostModel as CM } from 'bim-ts';
    import { VersionedStore } from '../VersionedStore';
    import { LazyDerived } from 'engine-utils-ts';
    import type { KreoEngine } from 'engine-ts';
    import EngineUiPanel from '../ui-panels/EngineUiPanel.svelte';
    import { getCostModelContext } from 'src/cost-model/capital/CostModelContext';

    const bim = getContext('bim') as Bim;
    const engine = getContext('engine') as KreoEngine;
    const catalog = getContext('catalog') as Catalog;
    const costModelContext = getCostModelContext();

    const idsLazy = bim.instances.selectHighlight.getVersionedFlagged(SceneInstanceFlags.isSelected);

    $: bimInstancesIdsSelection = new VersionedStore(idsLazy);

    const engineEditObjectsUi = engine.editSelectionCustomPropsUi();

    const selectionUisLazy = bimInstancesCommonProps(
        idsLazy,
        bim,
        catalog,
        engine.transformGizmoSettings,
    );

    const simplePropsUiLazy = LazyDerived.new1('', null, [selectionUisLazy], ([s]) => s.simplePropsUi).withoutEqCheck();
    const transformPropsUiLazy = LazyDerived.new1('', null, [selectionUisLazy], ([s]) => s.transformUi).withoutEqCheck();
    const propsUiLazy = LazyDerived.new1('', null, [selectionUisLazy], ([s]) => s.propsPui).withoutEqCheck();
    const legacyPropsUiLazyRow = LazyDerived.new1('', null, [selectionUisLazy], ([s]) => s.legacyPropertiesPui).withoutEqCheck();

    const expandLegacyPropsWithCostTableLinks = CM.create_expandLegacyPropsWithCostTableLinks();
    const legacyPropsUiWithCostModelLinksLazy = LazyDerived.new2(
        '',
        [],
        [legacyPropsUiLazyRow, idsLazy],
        ([pui, ids]) => {
            expandLegacyPropsWithCostTableLinks({ ids, bim, pui, costModelFocusApi: costModelContext.focusApi })
            return pui;
        }
    ).withoutEqCheck();

    const legacyPropsUiLazy = legacyPropsUiWithCostModelLinksLazy;

    // $: bimSelectionProps = new VersionedStore<SceneInstancesSelectionProps>(selectionPropsLazy);
</script>

<style lang="scss">
    .placeholder-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: inherit;
        p {
            text-align: center;
        }
    }
    .container {
        width: 100%;
        right: 0;
        left: 0;
        display: block;
        background-color: white;
    }
    .container :global(.container:not(:nth-child(2)) .group.root-level) {
        margin: 0;
    }
</style>

<EngineUiPanel showPositionMenu={true}>
<div class="container">
    {#if $bimInstancesIdsSelection.length === 0}
        <div class="placeholder-container">
            <p class="mdc-typography--body1 text-main-light">
                Select <b>Scene Object</b> to view its properties
            </p>
        </div>
    {:else}

        <PUI_Root
            configIdentifier={'engine-edit-objects-selection-props'}
            puiSource={new PUI_Lazy(engineEditObjectsUi)}
        ></PUI_Root>

        <PUI_Root
            configIdentifier={'selected-instances-basic-props'}
            puiSource={new PUI_Lazy(simplePropsUiLazy)}
        ></PUI_Root>

        <PUI_Root
            configIdentifier={'selected-instances-transform-props'}
            puiSource={new PUI_Lazy(transformPropsUiLazy)}
        ></PUI_Root>

        <PUI_Root
            configIdentifier={'selected-instances-props'}
            puiSource={new PUI_Lazy(propsUiLazy)}
        ></PUI_Root>

        <PUI_Root
            configIdentifier={'selected-instances-legacy-props'}
            puiSource={new PUI_Lazy(legacyPropsUiLazy)}
        ></PUI_Root>

    {/if}
</div>
</EngineUiPanel>



