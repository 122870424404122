import { BufferAttribute, BufferGeometry } from '../3rdParty/three';

export default class TrianglePostProcGeom extends BufferGeometry {
    constructor(flipUVsVertically = false) {
        super();

        const verts = new Float32Array(9);
        verts[0] = -1; verts[1] = -1; verts[2] = 0;
        verts[3] = 3; verts[4] = -1; verts[5] = 0;
        verts[6] = -1; verts[7] = 3; verts[8] = 0;

		const uvs = new Float32Array(6);
		if (flipUVsVertically) {
			uvs[0] = 0; uvs[1] = 1;
			uvs[2] = 2; uvs[3] = 1;
			uvs[4] = 0; uvs[5] = -1;
		} else {
			uvs[0] = 0; uvs[1] = 0;
			uvs[2] = 2; uvs[3] = 0;
			uvs[4] = 0; uvs[5] = 2;
		}

        const norms = new Float32Array(9);
        norms[0] = 0; norms[1] = 0; norms[2] = 1;
        norms[3] = 0; norms[4] = 0; norms[5] = 1;
        norms[6] = 0; norms[7] = 0; norms[8] = 1;

        this.setAttribute('position', new BufferAttribute(verts, 3));
        this.setAttribute('normal', new BufferAttribute(norms, 3));
        this.setAttribute('uv', new BufferAttribute(uvs, 2));
    }
}