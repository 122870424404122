<script lang="ts">
    import { getContext } from "svelte";
    import { PUI_Lazy, UiBindings } from "ui-bindings";
    import PUI_Root from "../pui/PUI_Root.svelte";
    import EngineUiPanel from "../ui-panels/EngineUiPanel.svelte";
    import { ButtonComponent, IconButton } from "../libui/button";
    import {
        Action,
        ContextMenu,
        ContextMenuStructure,
    } from "src/libui/context-menu";
    import { openBoundariesHelp, openLayoutHelp } from "./HelpDialog";

    export let puiSource: PUI_Lazy<any>;

    const uiBindings: UiBindings = getContext("uiBindings");

    const contextMenuStructure = new ContextMenuStructure([
        new Action("Layout geometry and offsets", () => {
            openLayoutHelp(uiBindings);
        }),
        new Action("Boundaries and buildable area source", () => {
            openBoundariesHelp(uiBindings);
        }),
    ]);

    const contextMenu = getContext<ContextMenu>("context-menu");
    const menuButton = new IconButton("Info", (e) =>
        contextMenu.openMenuAtPosition(
            e.clientX,
            e.clientY,
            contextMenuStructure,
        ),
    );
</script>

<EngineUiPanel>
    <svelte:fragment slot="buttons">
        <ButtonComponent desc={menuButton} />
    </svelte:fragment>
    <PUI_Root configIdentifier="farm-layout-view" {puiSource}></PUI_Root>
</EngineUiPanel>

<style lang="scss">
</style>
