import { TransformerIdent } from 'src/archetypes/transformer/Transformer';
import type { Bim } from '../../Bim';
import type { PatternName} from '../../constants/dc-patterns';
import { ConductorType, patternNames, PATTERNS } from '../../constants/dc-patterns';
import { SceneInstancesProperty } from '../../properties/ObjectRefProps';
import { AssetsProperty, CatalogItemsReferenceProperty } from '../../properties/ObjectRefProps';
import { NumberProperty, MultiSelectorProperty, BooleanProperty } from '../../properties/PrimitiveProps';
import type { PropertyGroup } from '../../properties/PropertyGroup';
import type { ConfigShapeMigration} from '../ConfigsArchetypes';
import { StateType } from '../ConfigsArchetypes';
import { fromAssetToCatalogProperty } from './MigrationUtils';

export const LvWiringConfigType = "lv-wiring-panel-config";

export interface LvWiringConfig extends PropertyGroup {
    transformers: SceneInstancesProperty,
    max_voltage_drop: NumberProperty;
    conductor_temperature: NumberProperty;
    pattern: Record<PatternName, LvWiringPatternSettings>;
}

interface LvWiringPatternSettings extends PropertyGroup {
    min_wires: PatternWires;
    multiharness: {
        qty_by_tracker: NumberProperty;
        div: NumberProperty[];
        same_length: BooleanProperty;
    };
}

interface PatternWires extends PropertyGroup {
    [wire: string]: CatalogItemsReferenceProperty;
}

export function registerLvWiringConfig(bim: Bim) {
    bim.configs.archetypes.registerArchetype({
        type_identifier: LvWiringConfigType,
        properties: () => getDefaultLvWiringConfig(),
        stateType: StateType.Singleton,
        shapeMigrations: migrations()
    });
}

export function getDefaultLvWiringConfig(): LvWiringConfig { 
    const pattern: Record<PatternName, LvWiringPatternSettings> = {
        'CI_HorTrunkbus': getDefaultLvWiringSettings('CI_HorTrunkbus'),
        'CI_MultVertTrunkbus': getDefaultLvWiringSettings('CI_MultVertTrunkbus'),
        'CI_HorTrunkbus_Trench': getDefaultLvWiringSettings('CI_HorTrunkbus_Trench'),
        'CI_Multiharness': getDefaultLvWiringSettings('CI_Multiharness'),
        'SI_Multiharness': getDefaultLvWiringSettings('SI_Multiharness'),
    };
    for (const patternName of patternNames) {
        pattern[patternName] = getDefaultLvWiringSettings(patternName);
    }
    const config: LvWiringConfig = {
        transformers: new SceneInstancesProperty({ value: [], types: [TransformerIdent] }),
        max_voltage_drop: NumberProperty.new({
            value: 1.5,
            unit: "%",
            range: [0, 100],
            description: "max voltage drop",
        }),
        conductor_temperature: NumberProperty.new({
            value: 75,
            unit: "C",
            range: [60, 90],
        }),
        pattern: pattern,
    };

    return config;
}

export function getDefaultLvWiringSettings(pattern: PatternName): LvWiringPatternSettings {
    const conductors = PATTERNS[pattern].conductors;
    const wires: PatternWires = {};
    for (let i = 0; i < conductors.length; i++) {
        const conductor = conductors[i];
        const wireName = `wire_${i + 1}_${conductor.type}`;

        wires[wireName] = new CatalogItemsReferenceProperty({
            value: [],
            types: ["lv-wire-spec"],
            assetsTypes:  ["lv-wire-spec"],
            maxCount: 1,
            isReadonly: conductor.type === ConductorType.BusBars
        });
    }

    const maxMultiharnesByTracker = 5;
    const settings: LvWiringPatternSettings = {

        min_wires: wires,
        multiharness: {
            div: [],
            qty_by_tracker: NumberProperty.new({
                value: 1,
                step: 1,
                unit: "",
                range: [1, maxMultiharnesByTracker],
            }),
            same_length: BooleanProperty.new({
                value: true,
            }),
        },
    };

    for (let i = 0; i < maxMultiharnesByTracker; i++) {
        settings.multiharness.div.push(NumberProperty.new({
            value: 1,
            step: 1,
            unit: "",
            range: [0, 100],
        }));
    }

    return settings;
}

function migrations(): ConfigShapeMigration[] {
    return [
        {
            toVersion: 1,
            patch: (config) => {
                const props = config.get<LvWiringConfig>();
                //@ts-ignore
                for (const pattern in props.wires.min_wires) {
                    //@ts-ignore
                    const wires = props.wires.min_wires[pattern as PatternName];
                    if(!wires){
                        continue;
                    }
                    for (const wire in wires) {
                        const prop = wires[wire];
                        if (prop instanceof AssetsProperty) {
                            wires[wire] = fromAssetToCatalogProperty(prop);
                        } else {
                            console.error('expected asset props in lv wiring 0 version');
                        }
                    }
                }
            },
            validation: {
                updatedProps: [],
                deletedProps: [],
            },
        },
        {
            toVersion: 2,
            patch: (config) => {
                const props = config.get<LvWiringConfig>();
                //@ts-ignore
                props.pattern.type = MultiSelectorProperty.new({
                    //@ts-ignore
                    ...props.pattern.type,
                    //@ts-ignore
                    value: props.pattern.type.value
                    //@ts-ignore
                        .filter(x => x !== 'CI_MultVertTrunkbus'),
                    //@ts-ignore
                    options: props.pattern.type.options
                    //@ts-ignore
                        .filter(x => x !== 'CI_MultVertTrunkbus')
                })
            },
            validation: {
                updatedProps: [],
                deletedProps: [],
            },
        },
        // rename CI_VertTrunkbus to CI_MultVertTrunkbus
        {
            toVersion: 3,
            patch: (config) => {
                const props = config.get<LvWiringConfig>();
                const from = 'CI_VertTrunkbus';
                const to = 'CI_MultVertTrunkbus';
                //@ts-ignore
                const newValue = props.pattern.type.options.slice();
                //@ts-ignore
                const replaceIndex = props.pattern.type.value.indexOf(from);
                if (replaceIndex > 0) {
                    newValue.splice(replaceIndex, 1, to)
                }
                //@ts-ignore
                const newOptions = props.pattern.type.options.slice()
                //@ts-ignore
                    .filter(x => x !== from && x !== to);
                newOptions.push(to);
                //@ts-ignore
                props.pattern.type = MultiSelectorProperty.new({
                //@ts-ignore
                    ...props.pattern.type,
                    value: newValue,
                    options: newOptions,
                })
            },
            validation: {
                updatedProps: [],
                deletedProps: [],
            },
        },
    ];
}
