<script lang="ts">
    import type { Bim, NumbersArrayProperty, UnitsMapper } from "bim-ts";
    import { numbersArrayCharts } from './NumbersArrayPropertyCharts';
    import AnyEchart from '../../charts/AnyEchart.svelte';
    import { getContext } from 'svelte';
    import type { Readable } from 'svelte/store';

    export let sourceProperty: NumbersArrayProperty;

    const unitsMapper = getContext('unitsMapper') as Readable<UnitsMapper>;

    const charts = numbersArrayCharts($unitsMapper, sourceProperty);

</script>

<div class="root">
    <!-- <div class="table">
        <TableUi
            lazyTableData = {new LazyBasic('', shadingTableView)}
        />
    </div> -->

    {#each charts as chart}
        <div class="chart">
            <AnyEchart
                echartOptions={chart.createChart()}
            ></AnyEchart>
        </div>
    {/each}



</div>

<style>
    .root {
        display: flex;
        flex-direction: column;
    }
    .chart {
        width: 1000px;
        height: 600px;
        display: flex;
        margin: 0 auto;
    }
</style>
