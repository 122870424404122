import type {
    AgPromise,
    CellClassRules,
    GridApi,
    GridOptions,
    HeaderValueGetterParams,
    ICellEditorComp,
    ICellEditorParams,
    ICellRendererParams,
    ValueGetterParams,
    ValueSetterParams,
} from "ag-grid-enterprise";
import type { Bim, BinTable, BinTableItem, TrackerPilesCollection, BinsBuilder } from "bim-ts";
import { PileProfileType, UnitsMapper, pileCrossSectionToString } from "bim-ts";
import PileProfileDropdown from './PileProfileDropdown.svelte';
import { unitsConverter, EnumUtils, RGBA } from 'engine-utils-ts';
import { DefaultGridOptions, pileIconCellRenderer } from '../../pui/custom-components/pile-table/GridConfig';


const cellClassRules: CellClassRules<BinTableItem> = {
    "editable": (params) => params.colDef.editable instanceof Function ? params.colDef.editable(params) : !!params.colDef.editable
}

interface GridContext {
    items: number;
    total: number;
    binTable?: BinTable;
    unitsMapper: UnitsMapper;
    pilesCollection: TrackerPilesCollection;
    binsBuilder: BinsBuilder;
}

function appendHeaderUnit(p: HeaderValueGetterParams<BinTableItem>) {
    return `${p.colDef.headerName}, ${p.context.unitsMapper.mapUnitToConfigured('ft')}`;
}

export function getGridOptions(bim: Bim, binsBuilder: BinsBuilder, pilesCollection: TrackerPilesCollection, isOutliersTable: boolean): GridOptions<BinTableItem> {
    const defaultGridContext: GridContext = {
        items: 0,
        total: 0,
        unitsMapper: bim.unitsMapper,
        pilesCollection,
        binsBuilder,
    };
    return {
        rowData: [],
        context: defaultGridContext,
        columnDefs: [
            {
                headerName: "Count",
                maxWidth: 70,
                headerClass: ["mdc-typography--body1", "text-main-dark", "header-cell-visible"],
                valueGetter: (params) => params.data?.pileIds.length,
                valueFormatter: (p) => `× ${p.value}`,
                headerValueGetter: (p) => `× ${p.context.items}, ${(p.context.items/p.context.total*100).toFixed(2)}%`,
            },
            {
                maxWidth: 26,
                cellClass: "ag-cell-icon",
                cellRenderer: (params: ICellRendererParams<BinTableItem>) => pileIconCellRenderer(params.data!.type, RGBA.toHexRgbString(params.data!.color)),
            },
            {
                maxWidth: 40,
                valueGetter: (params) => params.data?.name,
            },
            {
                headerName: "Pile profile",
                colId: "profile",
                headerClass: "mdc-typography--overline-small",
                editable: true,
                cellClassRules,
                cellEditor: PileProfileEditor,
                hide: isOutliersTable,
                valueGetter: (params) => {
                    return params.data ? pileCrossSectionToString(params.data.profile) : "";
                },
                valueSetter: (params: ValueSetterParams<BinTableItem, PileProfileType>) => {
                    const context: GridContext = params.context;
                    if (params.newValue == null || params.newValue === undefined || !context.binTable) {
                        return false;
                    }
                    context.binsBuilder.updateBin(context.binTable, params.data.updatePileTypeProfile(params.newValue));
                    callSceneColumnSetter(params, "profile_scene");
                    return true;
                },
            },
            {
                valueFormatter: () => `×`,
                maxWidth: 9,
                hide: isOutliersTable,
                cellStyle: {
                    padding: 0
                }
            },
            { 
                headerName: "Length",
                colId: "length",
                headerClass: "mdc-typography--overline-small",
                cellClassRules,
                editable: !isOutliersTable,
                hide: isOutliersTable,
                valueGetter: (params) => params.context.unitsMapper.mapToConfigured({ value: params.data?.length, unit: 'm' }).value.toFixed(2),
                headerValueGetter: appendHeaderUnit,
                valueSetter: (params: ValueSetterParams<BinTableItem, string>) => {
                    const context: GridContext = params.context;
                    const parsedValue = Number.parseFloat(params.newValue ?? '');
                    if (!parsedValue || !context.binTable) {
                        return false;
                    }
                    const fromUnit = unitsConverter.convertUnitsToSystem('m', context.unitsMapper.currentSystemOfUnits);
                    const value = UnitsMapper.mapTo({ value: parsedValue, unit: fromUnit}, 'm');
                    if (context.binTable.maxReveal.as('m') > value) {
                        return false;
                    }
                    context.binsBuilder.updateBin(context.binTable, params.data.updatePileTypeLength(value));
                    callSceneColumnSetter(params, "length_scene");
                    return true;
                },
            },
            {
                headerName: "Profile (scene)",
                colId: "profile_scene",
                headerClass: "mdc-typography--overline-small",
                editable: (params) => !!params.data?.pileIds.length,
                cellClassRules,
                cellEditor: PileProfileEditor,
                valueGetter: (params) => {
                    return params.data?.profileInScene ? pileCrossSectionToString(params.data.profileInScene) : "";
                },
                valueSetter: (params: ValueSetterParams<BinTableItem, PileProfileType>) => {
                    if (params.data.pileIds.length) {
                        params.context.pilesCollection.applyProfilePatchTo(params.data.pileIds, params.newValue);
                    }
                    return true;
                },
            },
            {
                valueFormatter: () => `×`,
                maxWidth: 9,
                hide: !isOutliersTable,
                cellStyle: {
                    padding: 0
                }
            },
            { 
                headerName: "Length (scene)",
                colId: "length_scene",
                headerClass: "mdc-typography--overline-small",
                cellClassRules,
                editable: (params) => !isOutliersTable && !!params.data?.pileIds.length,
                valueGetter: (params) => rangeFormatter(params.data?.lengthInScene!, params),
                headerValueGetter: appendHeaderUnit,
                valueSetter: (params: ValueSetterParams<BinTableItem, string>) => {
                    const parsedValue = Number.parseFloat(params.newValue ?? '');
                    if (!parsedValue) {
                        return false;
                    }
                    const fromUnit = unitsConverter.convertUnitsToSystem('m', params.context.unitsMapper.currentSystemOfUnits);
                    const value = UnitsMapper.mapTo({ value: parsedValue, unit: fromUnit}, 'm');
                    params.context.pilesCollection.applyLengthPatchTo(params.data.pileIds, value);
                    return true;
                },
            },
            {
                headerName: "Reveal",
                headerClass: "mdc-typography--overline-small",
                valueGetter: (params) => rangeFormatter(params.data?.reveal!, params),
                headerValueGetter: appendHeaderUnit,
            },
            {
                headerName: "Embedment",
                minWidth: 86,
                // editable: (params) => isOutliersTable && !!params.data?.pileIds.length,
                headerClass: "mdc-typography--overline-small",
                valueGetter: (params) => rangeFormatter(params.data?.embedment!, params),
                headerValueGetter: appendHeaderUnit,
                cellClassRules,
            },
        ],
        ...DefaultGridOptions,
        getRowClass: (params) => {
            const nextRow = params.api.getDisplayedRowAtIndex(params.rowIndex + 1);
            if ((nextRow && nextRow.data?.weight !== params.node.data?.weight)
                || params.rowIndex === params.api.getDisplayedRowCount() - 1) {
                return 'divider';
            }
            return;
        },
    };
}

export type GridApiType = GridApi<BinTableItem>;

export function setTableData(params: {
    gridApi: GridApiType|undefined,
    binTable: BinTable,
    binsBuilder: BinsBuilder,
}) {
    if (!params.gridApi) {
        return;
    }

    const isLastInfiniteBin = params.binTable.readonly;

    const showInSceneProfiles = params.binTable.pilesWithNonComformingProfile.length > 0 || isLastInfiniteBin;
    const showInSceneLengths = params.binTable.pilesWithNonConformingLength.length > 0 || isLastInfiniteBin;
    params.gridApi.setColumnsVisible(['profile_scene'], showInSceneProfiles);
    params.gridApi.setColumnsVisible(['length_scene'], showInSceneLengths);

    params.gridApi.setGridOption("rowData", params.binTable.getSortedItems());
    params.gridApi.setGridOption("context", {
        ...params.gridApi.getGridOption("context"),
        binTable: params.binTable,
        binsBuilder: params.binsBuilder
    });
    params.gridApi.refreshCells({
        force: true,
        suppressFlash: true,
    });
}

const profileOptions = EnumUtils.getAllEnumConstsStringValues(PileProfileType).map(
    (value) => {
        const typeValue = PileProfileType[value as keyof typeof PileProfileType];
        return {
            value: typeValue,
            label: pileCrossSectionToString(typeValue),
        };
    },
);

class PileProfileEditor implements ICellEditorComp {
    div!: HTMLDivElement;
    component!: PileProfileDropdown;
    onChangeValue?: PileProfileType;
    getValue() {
        return this.onChangeValue;
    }
    isPopup(): boolean {
        return true;
    }
    getPopupPosition(): "over" | "under" | undefined {
        return 'under';
    }
    getGui() {
        return this.div;
    }
    destroy() {
        this.component.$destroy();
    }
    init(params: ICellEditorParams<BinTableItem>): void | AgPromise<void> {
        this.div = document.createElement('div');
        this.component = new PileProfileDropdown({
            target: this.div,
            props: {
                options: profileOptions,
                onChange: (value: PileProfileType) => {
                    this.onChangeValue = value;
                    params.stopEditing()
                }
            }
        })
    }
}

function rangeFormatter(value: [number, number], params: ValueGetterParams<BinTableItem>): string {
    if (!params.data?.pileIds.length) {
        return '';
    }

    const min = params.context.unitsMapper.mapToConfigured({value: value[0], unit: 'm'}).value.toFixed(2);
    const max = params.context.unitsMapper.mapToConfigured({value: value[1], unit: 'm'}).value.toFixed(2);
    if (min !== max) {
        return `${min} - ${max}`;
    }
    return min;
}

export function updateTableHeader(gridApi: GridApiType|undefined, context: Partial<GridContext>) {
    if (gridApi) {
        const currentContext = gridApi.getGridOption("context");
        gridApi.setGridOption("context", {...currentContext, ...context});
        gridApi.refreshHeader();
    }
}

function callSceneColumnSetter(params: ValueSetterParams, columnId: string) {
    const sceneColumnDef = params.api.getColumnDef(columnId);
    if (sceneColumnDef && sceneColumnDef.valueSetter instanceof Function) {
        sceneColumnDef.valueSetter(params);
    }
}
