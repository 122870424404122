import type { Observer} from "..";
import { IterUtils, RecalcScheduleType, ResolvedPromise } from "..";
import type { LazyVersioned, PollWithVersionResult } from "./LazyVersioned";
import type { ScopedLogger } from "../ScopedLogger";
import type { BasicCollectionUpdates} from "./BasicCollectionUpdates";
import { Allocated } from "./BasicCollectionUpdates";
import type { BasicDataSource } from "./BasicDataSource";
import { ObservableStream } from "./ObservableStream";



export class JoinedCollectionBasic<T, IdT>
	implements LazyVersioned<ReadonlyMap<IdT, T>>, BasicDataSource<T, IdT>
{
	readonly updatesStream: ObservableStream<BasicCollectionUpdates<IdT>>;

	readonly scheduleType: RecalcScheduleType;

	readonly logger: ScopedLogger;
	readonly dataSources: BasicDataSource<T, IdT>[];

	private _currentResults = new Map<IdT, T>();
	private _updatesAccumulated: [BasicDataSource<T, IdT>, BasicCollectionUpdates<IdT>][] = [];
	private readonly _subcriptions: Observer[];


	constructor(args: {
		identifier: string,
		logger: ScopedLogger,
		dataSources: BasicDataSource<T, IdT>[],
		schedule?: RecalcScheduleType,
	}) {
		this.updatesStream = new ObservableStream({
			identifier: args.identifier,
			holdLastValueForNewSubscribers: false,
			defaultValueForNewSubscribersFactory: () => new Allocated<IdT>(Array.from(this.poll().keys())),
		});
		this.logger = args.logger.newScope(args.identifier);
		this.dataSources = args.dataSources;

		this.scheduleType = args.schedule ?? RecalcScheduleType.Microtask;

		this._subcriptions = args.dataSources.map(ds => ds.updatesStream.subscribe({
			settings: {immediateMode: true},
			onNext: (delta) => {
				this._updatesAccumulated.push([ds, delta]);
				this._scheduleRecalc();
				this.updatesStream.pushNext(delta);
			}
		}));

	}
	
	dispose() {
		this.updatesStream.dispose();
		IterUtils.disposeArray(this._subcriptions);
	}

	poll(): ReadonlyMap<IdT, T> {
		this._recalculateDirty();
		return this._currentResults;
	}
	pollWithVersion(): PollWithVersionResult<Readonly<ReadonlyMap<IdT, T>>> {
		return { value: this.poll(), version: this.version() };
	}
	version(): number {
		let sum = 0;
		for (const s of this.dataSources) {
			sum += s.version();
		}
		return sum;
	}

	peekById(id: IdT): T | undefined {
		this._recalculateDirty();
		return this._currentResults.get(id);
	}

	peekByIds(ids: Iterable<IdT>): Map<IdT, T> {
		this._recalculateDirty();
		const res = new Map();
		for (const id of ids) {
			const obj = this._currentResults.get(id);
			if (obj !== undefined) {
				res.set(id, obj);
			}
		}
		return res;
	}
	allIds(): IterableIterator<IdT> {
		return this._currentResults.keys();
	}


	private _updateScheduled: boolean = false;
	private _scheduleRecalc() {
		if (this.scheduleType === RecalcScheduleType.Immidiate) {
			this._recalculateDirty();
		} else if (this.scheduleType === RecalcScheduleType.Microtask) {
			if (!this._updateScheduled) {
				this._updateScheduled = true;
				ResolvedPromise.then(() => {
					this._updateScheduled = false;
					this._recalculateDirty();
				});
			}
		} else if (this.scheduleType === RecalcScheduleType.OnDemand) {
			// call poll to get synced results
		} else {
			this.logger.error('unknown schedule type', this.scheduleType);
		}
	}

	private _recalculateDirty() {
		for (const s of this.dataSources) {
			s.poll();
		}
		if (this._updatesAccumulated.length === 0) {
			return;
		}
		const updates = this._updatesAccumulated.slice();
		this._updatesAccumulated.length = 0;

		for (const [dataSrouce, update] of updates) {
			const data = dataSrouce.peekByIds(update.ids);
			for (const [id, obj] of data) {
				if (obj !== undefined) {
					this._currentResults.set(id, obj);
				} else {
					this._currentResults.delete(id);
				}
			}
		}
	}
}
