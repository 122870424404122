
export class GeometriesUtils {

    static findSurfacesBorderEdgesIndices(
        positions: ArrayLike<number>,
        trianglesIndicies: ArrayLike<number>
    ): Uint32Array {
        const uniqueEdges: number[] = [];
        {
            const edgesRepeatCountInTriangles = new Map<number, number>();
            for (let i = 0; i < trianglesIndicies.length; i += 3) {
        
                const ind1 = trianglesIndicies[i + 0];
                const ind2 = trianglesIndicies[i + 1];
                const ind3 = trianglesIndicies[i + 2];
        
                const edge1 = edgeToPrimitive(ind1, ind2);
                const edge2 = edgeToPrimitive(ind2, ind3);
                const edge3 = edgeToPrimitive(ind1, ind3);
        
                edgesRepeatCountInTriangles.set(edge1, (edgesRepeatCountInTriangles.get(edge1) ?? 0) + 1);
                edgesRepeatCountInTriangles.set(edge2, (edgesRepeatCountInTriangles.get(edge2) ?? 0) + 1);
                edgesRepeatCountInTriangles.set(edge3, (edgesRepeatCountInTriangles.get(edge3) ?? 0) + 1);
            }
            for (const [edge, count] of edgesRepeatCountInTriangles) {
                if (count === 1) {
                    uniqueEdges.push(edge);
                }
            }
        }
    
        const perPositionHashUniqueEdges = new Set<number>();
    
        const addedPositionsHashesPerIndex = new Map<number, number | string>();
        const addedIndicesPerHash = new Map<number | string, number>();
    
        // now we should remove duplicate edges
        const edgesInds: [number, number] = [0, 0];
        for (const edge of uniqueEdges) {
            edgeToTuple(edge, edgesInds);
    
            let ind1 = edgesInds[0];
            let ind2 = edgesInds[1];
    
            let h1 = addedPositionsHashesPerIndex.get(ind1);
            let h2 = addedPositionsHashesPerIndex.get(ind2);
    
            if (h1 === undefined) {
                h1 = positionToPrimitive(positions, ind1);
                addedPositionsHashesPerIndex.set(ind1, h1);
            }
            if (h2 === undefined) {
                h2 = positionToPrimitive(positions, ind2);
                addedPositionsHashesPerIndex.set(ind2, h2);
            }
    
            let ind1replacement = addedIndicesPerHash.get(h1);
            if (ind1replacement === undefined) {
                addedIndicesPerHash.set(h1, ind1);
            } else if (
                ind1replacement !== ind1
                && positionsEqual(positions, ind1, ind1replacement)
            ) {
                ind1 = ind1replacement;
            }
    
            let ind2replacement = addedIndicesPerHash.get(h2);
            if (ind2replacement === undefined) {
                addedIndicesPerHash.set(h2, ind2);
            } else if (
                ind2replacement !== ind2
                && positionsEqual(positions, ind2, ind2replacement)
            ) {
                ind2 = ind2replacement;
            }
    
            const newEdge = edgeToPrimitive(ind1, ind2);
            perPositionHashUniqueEdges.add(newEdge);
        }
    
        const edgesIndsResult = new Uint32Array(perPositionHashUniqueEdges.size * 2);
    
        let counter = 0;
        for (const edge of perPositionHashUniqueEdges) {
            edgeToTuple(edge, edgesInds);
            let ind1 = edgesInds[0];
            let ind2 = edgesInds[1];
            edgesIndsResult[counter + 0] = ind1;
            edgesIndsResult[counter + 1] = ind2;
            counter += 2;
        }
        return edgesIndsResult;
    }
}


function edgeToPrimitive(ind1: number, ind2: number): number {
	if (ind2 < ind1) {
		let t = ind2;
		ind2 = ind1;
		ind1 = t;
	}
	if (ind2 > 0xFF_FFFF) { // 24 bits maximum per number
		throw new Error('edge id is too large ' + ind1);
	}
	return ((ind1 & 0xFF_FFFF) * 0x100_0000 + (ind2 & 0xFF_FFFF));
}

function edgeToTuple(edge: number, out: [number, number]) {
	const id2 = (edge & 0xFF_FFFF);
	const id1 = ((edge - id2) / 0x100_0000);
	out[0] = id1;
	out[1] = id2;
}

function positionToPrimitive(positions: ArrayLike<number>, index: number) {
	const x = positions[index * 3 + 0];
	const y = positions[index * 3 + 1];
	const z = positions[index * 3 + 2];
	return `${x}-${y}-${z}`;
}
function positionsEqual(positions: ArrayLike<number>, ind1: number, ind2:number) {
	const x1 = positions[ind1 * 3 + 0];
	const y1 = positions[ind1 * 3 + 1];
	const z1 = positions[ind1 * 3 + 2];
	const x2 = positions[ind2 * 3 + 0];
	const y2 = positions[ind2 * 3 + 1];
	const z2 = positions[ind2 * 3 + 2];
	return x1 === x2 && y1 === y2 && z1 === z2;
}