import type { BimGeometryBase } from "bim-ts";
import { Success, type ScopedLogger} from "engine-utils-ts";
import type { Result } from "engine-utils-ts";
import type { Matrix4, Plane} from "math-ts";
import { Aabb, Vec3Zero, Vector2, Vector3 } from "math-ts";
import { BufferAttribute, Float32BufferAttribute } from "../3rdParty/three";
import type { KrCamera } from "../controls/MovementControls";
import { EntitiesInterned } from "../resources/EntitiesInterned";
import { ShaderFlags } from "../shaders/ShaderFlags";
import type { RaySection } from "../structs/RaySection";
import { EngineGeoType } from "./AllEngineGeometries";
import { EngineBimGeometry } from "./EngineGeometry";
import type { GeometryIntersection} from "./GeometryUtils";
import { GeometryUtils, IntersectionType } from "./GeometryUtils";
import { GeometryGpuRepr } from "./KrBufferGeometry";

const MmSizeAabb = Object.freeze(Aabb.empty().setFromCenterAndSize(Vec3Zero, Vector3.fromScalar(0.001)));

export class GeoSprite implements BimGeometryBase {

	constructor(
		public readonly sizeExtents: Vector2 = new Vector2(),
		public readonly radius: number = 0,
	) {
	}

	calcAabb(): Aabb {
		// return Aabb.calcFromArray([-this.radius, -this.radius, -this.radius, this.radius, this.radius, this.radius]);
		return MmSizeAabb;
	}
	checkForErrors(errors: string[]): void {
		if (this.sizeExtents.x <= 0 || this.sizeExtents.y <= 0) {
			errors.push('invalid size ' + Vector2.asString(this.sizeExtents));
		}
	}

	toString() {
		return `${this.sizeExtents}:r${this.radius}`;
	}
}

export const DefaultUnitSprite = new GeoSprite(new Vector2(10, 10), 10);

export const DefaultSpriteGeos = {
	small: new GeoSprite(new Vector2(8, 8), 8),
	medium: new GeoSprite(new Vector2(10, 10), 6),
}




export class EngineGeoSprite extends EngineBimGeometry<GeoSprite> {

    constructor(
        sourcePolyline: GeoSprite = new GeoSprite(),
    ) {
        super(sourcePolyline);
    }

	isViewDependent() {
		return true;
	}

	calcAabb(): Aabb {
		return this.bimGeo.calcAabb();
	}

	_calcGpuRepr(): GeometryGpuRepr {

		const offset = this.bimGeo.sizeExtents;
		const radius = this.bimGeo.radius;

		// 3rd position coordiante is not used in shader, write radius in there
		const position = new Float32Array([
			-offset.x, -offset.y, radius,
			 offset.x, -offset.y, radius,
			 offset.x,  offset.y, radius,
			-offset.x,  offset.y, radius,
		]);

		const uv = new Float32Array([
			0, 0,
			1, 0,
			1, 1,
			0, 1
		]);

		return new GeometryGpuRepr(
			{
				position: new Float32BufferAttribute(position, 3),
				uv: new Float32BufferAttribute(uv, 2),
			},
			new BufferAttribute(new Uint16Array([0, 1, 2, 0, 2, 3]), 1),
			new BufferAttribute(new Uint16Array([0, 1, 1, 2, 2, 3, 3, 0]), 1),
			{
				flags: ShaderFlags.IS_SPRITE_GEO,
				uniforms: []
			}
		)
	}

	
	intersectPlanes(planes: Plane[]): IntersectionType {
		if (GeometryUtils.planesVolumeContainsPoint(planes, Vec3Zero)) {
			return IntersectionType.Full;
		}
		return IntersectionType.Outside;
	}
	*snappingEdges(): IterableIterator<[Vector3, Vector3]> {
		return [Vec3Zero, Vec3Zero];
	}

	raycast(ray: RaySection, modelMatrix: Matrix4, camera: KrCamera): GeometryIntersection[] {
		const point = modelMatrix.extractPosition();
		const closestOnRayPoint = ray.ray.closestPointToPoint(point);

		const pointScrSpace = point.clone().applyMatrix4(camera.mvpMatrix);
		const rayPointScrSPace = closestOnRayPoint.clone().applyMatrix4(camera.mvpMatrix);

		const res: GeometryIntersection[] = [];
		if (pointScrSpace.distanceTo(rayPointScrSPace) < this.bimGeo.sizeExtents.length() * 0.001) {
			res.push({
				point,
				distance: closestOnRayPoint.distanceTo(camera.position),
				normal: new Vector3().setFromMatrixColumn(camera.matrixWorld, 2),
			})
		}
		return res;
	}
}

export class EngineSpriteGeometries extends EntitiesInterned<EngineGeoSprite, EngineGeoType, GeoSprite, number>{

    constructor(
        logger: ScopedLogger,
    ) {
        super({
            logger,
            identifier: 'engine-Sprite-geos',
            idsType: EngineGeoType.SpriteGeometry,
            uniqueReducerFn: (Sprite) => JSON.stringify(Sprite),
            T_Constructor: EngineGeoSprite,
        });
    }

    convertFromInternedType(sprite: GeoSprite): Result<EngineGeoSprite> {
        return new Success(new EngineGeoSprite(sprite));
    }

    checkForErrors(t: EngineGeoSprite, errors: string[]): void {
        if (t.aabb().isEmpty()) {
            errors.push('empty aabb');
        }
    }
}


