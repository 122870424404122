<script lang="ts">
    import { getContext, onMount } from "svelte";
    import Tabs from "../libui/tabs/Tabs.svelte";
    import EngineUiPanel from "../ui-panels/EngineUiPanel.svelte";
    import PileBins from "./pile-bins/PileBins.svelte";
    import { NavbarContext, PanelPosition } from "src/navbar/Navbar";
    import PilePositions from "../piles-positions/PilePositions.svelte";
    import {
        Bim,
        EditTrackerPilesContext,
        FixedTiltTypeIdent,
        SceneObjDiff,
        TrackerTypeIdent,
    } from "bim-ts";
    import { Button, ButtonComponent, ButtonType } from "src/libui/button";
    import { VersionedStore } from "src/VersionedStore";
    import { UiPersistentState } from "../UiPersistentState";

    const tabs = [
        {
            key: "positions",
            name: "Piles position",
        },
        {
            key: "bins",
            name: "Pile reveal bins",
        },
    ];
    const uiState = new UiPersistentState<{ tab: string }>(
        "piles-view-panel-tab", 
        { tab: tabs[0].key },
    ).store;

    $: activeTab = $uiState.tab;
    $: activeIndex = Math.max(tabs.findIndex((tab) => tab.key === activeTab), 0);
    function changeTab(e: CustomEvent) {
        $uiState.tab = e.detail.tab.key;
    }

    const navBar = getContext<NavbarContext>("ui-navbar");
    const bim = getContext<Bim>("bim");

    const trackers = new VersionedStore(
        bim.instances.getLazyListOfTypes({
            type_identifiers: [FixedTiltTypeIdent, TrackerTypeIdent],
            relevantUpdateFlags: SceneObjDiff.None,
        }),
    );
    const anyTrackers = new VersionedStore(
        bim.instances.getLazyListOf({
            type_identifier: "any-tracker",
            relevantUpdateFlags: SceneObjDiff.None,
        }),
    );

    onMount(() => {
        const originalWidth = $navBar.panels.getOrCreate(
            PanelPosition.Left,
        ).width;
        navBar.setPanelWidth(PanelPosition.Left, 520);
        return () => {
            if ($navBar.panels.getOrCreate(PanelPosition.Left).width === 520) {
                navBar.setPanelWidth(PanelPosition.Left, originalWidth);
            }
        };
    });
</script>

<EngineUiPanel>
    <div class="piles-container">
        <div class="tabs">
            <Tabs {tabs} compact={true} on:change={changeTab} firstIndexToActivate = {activeIndex} />
        </div>
        {#if $anyTrackers.length}
            {#if activeTab === "bins"}
                <PileBins />
            {:else if activeTab === "positions"}
                <PilePositions context={new EditTrackerPilesContext()} />
            {/if}
        {:else}
            <div class="warning-container">
                {#if $trackers.length}
                    <div>
                        <span style:vertical-align="middle">
                            To configure pile bins please
                        </span>
                        <ButtonComponent
                            compact={true}
                            desc={new Button(
                                "Update existing trackers to a new type (any-tracker)",
                                ButtonType.Text,
                                () => bim.convertToAnyTrackers(),
                                false,
                                "Update",
                            )}
                        />
                    </div>
                    <div>
                        Advanced piles configuration can't be applied to the
                        fixed-tilt solar arrays.
                    </div>
                {:else}
                    <div>There are no tracker models in the project.</div>
                    <div>
                        Please, import layout or generate a new one to add
                        trackers. As an alternative add one from the catalog
                        manually.
                    </div>
                {/if}
            </div>
        {/if}
    </div>
</EngineUiPanel>

<style lang="scss">
    .tabs {
        margin: 0 16px;
        position: relative;
    }
    .piles-container {
        padding-bottom: 192px;
    }
    .warning-container {
        padding: 12px 16px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        :global(.mdc-button.mdc-button--icon-leading) {
            padding: 0;
        }
    }
</style>
