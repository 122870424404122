// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { PropertyNumberRangeT } from './property-number-range.js';
import { PropertyNumberRange } from './property-number-range.js';


export class PropertyNumericValue implements flatbuffers.IUnpackableObject<PropertyNumericValueT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):PropertyNumericValue {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsPropertyNumericValue(bb:flatbuffers.ByteBuffer, obj?:PropertyNumericValue):PropertyNumericValue {
  return (obj || new PropertyNumericValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsPropertyNumericValue(bb:flatbuffers.ByteBuffer, obj?:PropertyNumericValue):PropertyNumericValue {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new PropertyNumericValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

step():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

range(obj?:PropertyNumberRange):PropertyNumberRange|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new PropertyNumberRange()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

unit():string|null
unit(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
unit(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startPropertyNumericValue(builder:flatbuffers.Builder) {
  builder.startObject(4);
}

static addValue(builder:flatbuffers.Builder, value:number) {
  builder.addFieldFloat64(0, value, 0.0);
}

static addStep(builder:flatbuffers.Builder, step:number) {
  builder.addFieldFloat64(1, step, 0.0);
}

static addRange(builder:flatbuffers.Builder, rangeOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, rangeOffset, 0);
}

static addUnit(builder:flatbuffers.Builder, unitOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, unitOffset, 0);
}

static endPropertyNumericValue(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): PropertyNumericValueT {
  return new PropertyNumericValueT(
    this.value(),
    this.step(),
    (this.range() !== null ? this.range()!.unpack() : null),
    this.unit()
  );
}


unpackTo(_o: PropertyNumericValueT): void {
  _o.value = this.value();
  _o.step = this.step();
  _o.range = (this.range() !== null ? this.range()!.unpack() : null);
  _o.unit = this.unit();
}
}

export class PropertyNumericValueT implements flatbuffers.IGeneratedObject {
constructor(
  public value: number = 0.0,
  public step: number = 0.0,
  public range: PropertyNumberRangeT|null = null,
  public unit: string|Uint8Array|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const range = (this.range !== null ? this.range!.pack(builder) : 0);
  const unit = (this.unit !== null ? builder.createString(this.unit!) : 0);

  PropertyNumericValue.startPropertyNumericValue(builder);
  PropertyNumericValue.addValue(builder, this.value);
  PropertyNumericValue.addStep(builder, this.step);
  PropertyNumericValue.addRange(builder, range);
  PropertyNumericValue.addUnit(builder, unit);

  return PropertyNumericValue.endPropertyNumericValue(builder);
}
}
