<script lang="ts">
import type { PUI_CustomPropertyNode } from 'ui-bindings';
import GroupProperties from './custom-components/GroupProperties.svelte';
import AssetsProperty from './custom-components/AssetsProperty.svelte';
import CatalogItemsReferenceProperty from './custom-components/CatalogItemsReferenceProperty.svelte';
import ILRProperty from './custom-components/ILRProperty/ILRProperty.svelte';
import { ILRInverterGroups } from './custom-components/ILRProperty/ILRInverterGroups';
import IlrByInverterView from './custom-components/ILRProperty/ILRByInverterView.svelte';
import { TrackerShadingFactorsProperty as TrackerShadingPropertyBim, NumberPropertyWithOptions, UnitsMapper, NumbersArrayProperty, EnergyPipelineProperty, SubstationSLDViewerProperty, TransformerSLDViewerProperty, EditTrackerPilesContext, TrackerBins, CustomPropsRegistry } from 'bim-ts';
import TrackerShadingPropertyShort from './custom-components/TrackerShadingPropertyShort.svelte';
import NumberPropertyWithOptionsComponent from './custom-components/NumberPropertyWithOption/NumberPropertyWithOptions.svelte';
import { getContext } from 'svelte';
import type { Readable } from 'svelte/store';
import Message from './custom-components/Message.svelte';
import TextMessage from './custom-components/TextMessage.svelte';
import NumbersArrayPropertyShort from './numbers-array-property/NumbersArrayPropertyShort.svelte';
import SelectOptionProperty from './custom-components/SelectOptionProperty.svelte';
import SelectObjectFromTheScene from './SelectObjects/common/SelectItemsFromList.svelte';
import Divider from './custom-components/Divider.svelte';
import ResultingPattern from './custom-components/ResultingPattern.svelte';
import { CompressibleNumbersArray } from 'engine-utils-ts';
import GenericInputProperty from './custom-components/GenericInputProperty.svelte';
import RangeProperty from './custom-components/RangeProperty.svelte';
import SelectTerrainProperty from './custom-components/SelectTerrainProperty.svelte';
import CustomGroupName from './custom-components/CustomGroupName.svelte';
import EnergyPipelineShortView from '../energy-view/EnergyPipelineShortView.svelte';
import SelectAugmentationModePropertyComponent from './custom-components/SelectLayoutGenerationMode/SelectAugumentationMode.svelte';
import SelectLayoutGenerationModeComponent from './custom-components/SelectLayoutGenerationMode/SelectLayoutGenerationMode.svelte';
import { SelectAugmentationModeProperty, SelectLayoutGenerationMode } from 'layout-service';
import TransformerSLDViewer from './custom-components/sld/TransformerSLDViewer.svelte';
import SubstationSLDViewer from './custom-components/sld/SubstationSLDViewer.svelte';
import EditTrackerPilesProperty from './custom-components/EditTrackerPilesProperty.svelte';
import PileTypeTable from './custom-components/pile-table/PileTypeTable.svelte';
import { DxfExportContext } from 'files-converters';
import DxfExport from './custom-components/dxf-export/DxfExport.svelte';

export let property: PUI_CustomPropertyNode<any, any>;

const unitsMapper = getContext<Readable<UnitsMapper>>('unitsMapper');

</script>

{#if property.type_ident == 'assets-selector'}
    <AssetsProperty
        bind:value={property.value}
        name={property.name}
        maxSelect={property.context.maxCount}
        disabled={property.readonly}
        types={property.context.types}
        description={property.description}
    />
{:else if property.type_ident == 'catalog-items-selector'}
    <CatalogItemsReferenceProperty
        name={property.name}
        bind:property={property.value}
        enableSelectAll={property.context.enableSelectAll}
        doubleLine={property.context.doubleLine}
        formatter={property.context.formatter}
        readonly={property.readonly}
        notActive={property.notActive}
    />
{:else if property.type_ident == 'group_properties'}
    <GroupProperties
        name={property.name}
        options={property.value}
        onClick={property.context.onClick}
        iconName={property.context?.iconName}
        readonly={property.readonly}
        notActive={property.notActive}
    />
{:else if property.type_ident == 'ilr_property'}
    <ILRProperty
        bind:value={property.value}
        step={property.context.step}
        minMax={property.context.minMax}
        name={property.context.name}
        inverters={property.context.inverters}
        openBlocks={property.context.openBlocks}
        readonly={property.readonly}
        notActive={property.notActive}
    />
{:else if property.value instanceof TrackerShadingPropertyBim}
    <TrackerShadingPropertyShort
        bind:property={property}
    />
{:else if property.value instanceof NumbersArrayProperty || property.value instanceof CompressibleNumbersArray}
    <NumbersArrayPropertyShort
        bind:property={property}
    />
{:else if property.value instanceof EnergyPipelineProperty}
    <EnergyPipelineShortView
        bind:pui_property={property}
    />
{:else if property.value instanceof NumberPropertyWithOptions}
    <NumberPropertyWithOptionsComponent
        unitsMapper={$unitsMapper}
        name={property.name}
        bind:sourceProperty={property.value}
        context={property.context}
        notActive={property.notActive}
    />
{:else if property.type_ident === 'error_message'}
    <Message {property} />
{:else if property.type_ident === 'wide_message'}
    <TextMessage message={property.value} color={property.context?.message} />
{:else if property.value instanceof ILRInverterGroups}
    <IlrByInverterView value={property.value} />
{:else if property.type_ident === 'select_option'}
    <SelectOptionProperty
        name={property.name}
        readonly = {property.readonly}
        value = {property.value}
        calculated = {property.calculated}
        onClick = {property.context.onClick}
        tag={property.context.tag}
        hasError={property.context.hasError}
        selectedOptionData={property.context.selectedOptionData}
    />
{:else if property.type_ident === 'select-scene-objs'}
    <SelectObjectFromTheScene
        bind:selected={property.value}
        store={property.context.store}
    />
{:else if property.type_ident === 'range-property'}
    <RangeProperty
        bind:value = {property.value}
        name = {property.name}
        readonly = {property.readonly}
        unit = {property.context.unit}
        minMax = {property.context.minMax}
        step = {property.context.step}
        minTag = {property.context.minTag}
        maxTag = {property.context.maxTag}
        minReadonly = {property.context.minReadonly}
        maxReadonly = {property.context.maxReadonly}
        colorMin = {property.context.colorMin}
        colorMax = {property.context.colorMax}
        decimals = {property.context.decimals}
        notActive = {property.notActive}
    />
{:else if property.type_ident === 'select-terrain-property'}
    <SelectTerrainProperty
        bind:value = {property.value}
        name = {property.name}
        options = {property.context.options}
    />
{:else if property.type_ident === 'custom-group-name'}
    <CustomGroupName
        bind:property = {property}
    />
{:else if property.type_ident === 'resulting-pattern'}
    <ResultingPattern 
        name={property.name}
        value={property.value}
    />
{:else if property.type_ident === 'divider'}
    <Divider/>
{:else if property.type_ident === 'generic-input-prop'}
    <GenericInputProperty property = {property}/>
{:else if property.value instanceof SubstationSLDViewerProperty}
    <SubstationSLDViewer />
{:else if property.value instanceof TransformerSLDViewerProperty}
    <TransformerSLDViewer />
{:else if property.value instanceof SelectAugmentationModeProperty}
    <SelectAugmentationModePropertyComponent bind:property = {property.value}/>
{:else if property.value instanceof SelectLayoutGenerationMode}
    <SelectLayoutGenerationModeComponent bind:property = {property} unitsMapper = {$unitsMapper}/>
{:else if property.value instanceof EditTrackerPilesContext}
    <EditTrackerPilesProperty property={property} />
{:else if property.value instanceof TrackerBins}
    <PileTypeTable property={property} />
{:else if property.context instanceof DxfExportContext}
    <DxfExport context={property.context}/>
{:else}
    <div class="ui-config-property property-row custom-prop">
        <div class="custom-holder">
            {property?.name}
        </div>
    </div>
{/if}

<style lang="scss">
    .custom-holder {
        width: 200px;
        padding: 5px 0px;
    }
    .custom-prop {
        display: flex;
        justify-content: center;
    }
</style>
