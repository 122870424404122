import { NumberProperty } from "../../properties/PrimitiveProps";
import type { PropertyGroup } from "../../properties/PropertyGroup";
import type { Catalog } from "../Catalog";
import { CatalogItem } from "../CatalogItem";
import type { CatalogItemsUiLabels, CatalogItemUiLabels } from "../CatalogItemsUiLabels";

export const TrackerPositionMultiplierIdent = 'tracker position price multiplier';

export interface TrackerPositionMultiplier extends PropertyGroup {
    interior: NumberProperty;
    exterior: NumberProperty;
    edge: NumberProperty;
    edge_bot: NumberProperty;
    edge_top: NumberProperty;
}

export function registerTrackerPositionMultiperCatalogItemUiLabels(
    labelsProvider: CatalogItemsUiLabels,
) {
    labelsProvider.register<TrackerPositionMultiplier>(
        TrackerPositionMultiplierIdent,
        (item) => {
            const strs = [];
            for (const [k, v] of Object.entries(item)) {
                if (v instanceof NumberProperty) {
                } else {
                    continue;
                }
                strs.push(`${k} ${v.value.toPrecision(2)}`)
            }
            const rootGroup = [TrackerPositionMultiplierIdent];
            const result: CatalogItemUiLabels = {
                group: [rootGroup],
                title: strs.join(' ') || 'tracker position multiplier not presetnt',
            };
            return result;
        }
    );
}

export function importDefaultTrackerPositionMultiplier(catalog: Catalog) {
    const existing = catalog.catalogItems
        .filter(x => x.typeIdentifier === TrackerPositionMultiplierIdent);
    if (!existing.length) {
        const catalogItem = new CatalogItem<TrackerPositionMultiplier>(
            undefined,
            TrackerPositionMultiplierIdent,
            {
                edge: NumberProperty.new({
                    value: 1,
                    unit: '',
                    range: [0, 10000000],
                    step: 0.1,
                }),
                edge_bot: NumberProperty.new({
                    value: 1,
                    unit: '',
                    range: [0, 10000000],
                    step: 0.1,
                }),
                edge_top: NumberProperty.new({
                    value: 1,
                    unit: '',
                    range: [0, 10000000],
                    step: 0.1,
                }),
                exterior: NumberProperty.new({
                    value: 1,
                    unit: '',
                    range: [0, 10000000],
                    step: 0.1,
                }),
                interior: NumberProperty.new({
                    value: 1,
                    unit: '',
                    range: [0, 10000000],
                    step: 0.1,
                }),
            }
        );
        catalog.catalogItems.allocate([[catalog.catalogItems.reserveNewId(), catalogItem]]);
    }
}
