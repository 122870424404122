// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { MapProjectionOriginT } from './map-projection-origin.js';
import { MapProjectionOrigin } from './map-projection-origin.js';
import type { Vector3T } from './vector3.js';
import { Vector3 } from './vector3.js';


export class SceneOrigin implements flatbuffers.IUnpackableObject<SceneOriginT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SceneOrigin {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSceneOrigin(bb:flatbuffers.ByteBuffer, obj?:SceneOrigin):SceneOrigin {
  return (obj || new SceneOrigin()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSceneOrigin(bb:flatbuffers.ByteBuffer, obj?:SceneOrigin):SceneOrigin {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SceneOrigin()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

civilSceneOriginLocal(obj?:Vector3):Vector3|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new Vector3()).__init(this.bb_pos + offset, this.bb!) : null;
}

mapProjectionOrigin(obj?:MapProjectionOrigin):MapProjectionOrigin|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new MapProjectionOrigin()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startSceneOrigin(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addCivilSceneOriginLocal(builder:flatbuffers.Builder, civilSceneOriginLocalOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, civilSceneOriginLocalOffset, 0);
}

static addMapProjectionOrigin(builder:flatbuffers.Builder, mapProjectionOriginOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, mapProjectionOriginOffset, 0);
}

static endSceneOrigin(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): SceneOriginT {
  return new SceneOriginT(
    (this.civilSceneOriginLocal() !== null ? this.civilSceneOriginLocal()!.unpack() : null),
    (this.mapProjectionOrigin() !== null ? this.mapProjectionOrigin()!.unpack() : null)
  );
}


unpackTo(_o: SceneOriginT): void {
  _o.civilSceneOriginLocal = (this.civilSceneOriginLocal() !== null ? this.civilSceneOriginLocal()!.unpack() : null);
  _o.mapProjectionOrigin = (this.mapProjectionOrigin() !== null ? this.mapProjectionOrigin()!.unpack() : null);
}
}

export class SceneOriginT implements flatbuffers.IGeneratedObject {
constructor(
  public civilSceneOriginLocal: Vector3T|null = null,
  public mapProjectionOrigin: MapProjectionOriginT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const mapProjectionOrigin = (this.mapProjectionOrigin !== null ? this.mapProjectionOrigin!.pack(builder) : 0);

  SceneOrigin.startSceneOrigin(builder);
  SceneOrigin.addCivilSceneOriginLocal(builder, (this.civilSceneOriginLocal !== null ? this.civilSceneOriginLocal!.pack(builder) : 0));
  SceneOrigin.addMapProjectionOrigin(builder, mapProjectionOrigin);

  return SceneOrigin.endSceneOrigin(builder);
}
}
