import type { PUI_PropertyNodeSliderArgs, PUI_PropertyNodeSwitcherArgs } from '.';
import { PUI_PropertyNodeSlider, PUI_PropertyNodeSwitcher } from '.';
import type { PUI_ActionsNodeArgs, PUI_CustomPropertyNodeArgs, PUI_GroupNodeArgs, PUI_PropertyNodeBoolArgs, PUI_PropertyNodeColorArgs, PUI_PropertyNodeMultiSelectorArgs, PUI_PropertyNodeNumberArgs, PUI_PropertyNodeSelectorArgs, PUI_PropertyNodeStringArgs, PUI_PropertyNodeVec2Args, PUI_PropertyNodeVec3Args, PUI_CustomGroupNodeArgs, PUI_CustomGroupNodeChildren, PUI_SceneInstancesSelectorPropertyNodeArgs} from "./PUI_Nodes";
import { PUI_ActionsNode, PUI_CustomPropertyNode, PUI_GroupNode, PUI_PropertyNodeBool, PUI_PropertyNodeColor, PUI_PropertyNodeMultiSelector, PUI_PropertyNodeNumber, PUI_PropertyNodeSelector, PUI_PropertyNodeString, PUI_PropertyNodeVec2, PUI_PropertyNodeVec3, PUI_CustomGroupNode, PUI_SceneInstancesSelectorPropertyNode } from "./PUI_Nodes";
import type { PUI_Node } from "./PUI_Nodes";


export interface PUI_BuilderParams {
	rootName?: string,
	sortChildrenDefault?: boolean,
	callbacks?: PUI_BuilderCallbacks,
}
export class PUI_BuilderCallbacks {
	onAfterNodeAdded: ((b: PUI_Builder, node: PUI_Node) => void)[] = [];

	addAfterEveryNodeCallback(
		callback: (b: PUI_Builder, node: PUI_Node) => void
	) {
		this.onAfterNodeAdded.push(callback);
	}

	addTypeFilteredAfterNodeCallback<N extends PUI_Node, Params>(
		ctor: {new(params: Params): N},
		callback: (b: PUI_Builder, node: N) => void,
	) {
		this.onAfterNodeAdded.push((builder, node) => {
			if (node instanceof ctor) {
				callback(builder, node);
			}
		})
	}
}

export class PUI_Builder {

	_groupsStack: PUI_GroupNode[];
	_sortChildrenDefault?: boolean;
	_callbacks?: PUI_BuilderCallbacks;

    constructor(builderParams: PUI_BuilderParams) {

		this._sortChildrenDefault = builderParams.sortChildrenDefault;
		this._callbacks = builderParams.callbacks;

		const rootNode = new PUI_GroupNode({
			name: '',
			sortChildren: builderParams.sortChildrenDefault
		});
		this._groupsStack = [
			rootNode,
		];
		this._callEventsAfterNodeAdded(rootNode);
	}

	inGroup(
		groupParams: PUI_GroupNodeArgs,
		inGroupCode: () => void,
	) {
		if (groupParams.name.startsWith('_')) {
			return;
		}
		if (this._sortChildrenDefault !== undefined && groupParams.sortChildren === undefined) {
			groupParams.sortChildren = this._sortChildrenDefault;
		}
		const newGroup = this.addNode(PUI_GroupNode, groupParams);
		this._groupsStack.push(newGroup);
		try {
			inGroupCode();
		} catch (e) {
			console.error(e);
		};
		console.assert(newGroup === this._groupsStack.pop(), 'groups stack sanity check');
	}

	addCustomGroup<Children extends PUI_CustomGroupNodeChildren, Context>(
		params: PUI_CustomGroupNodeArgs<Children, Context>
	) {
		this.addNode(PUI_CustomGroupNode, params);
	}

	addBoolProp(params: PUI_PropertyNodeBoolArgs) {
		this.addNode(PUI_PropertyNodeBool, params);
	}
	addNumberProp(params: PUI_PropertyNodeNumberArgs) {
		this.addNode(PUI_PropertyNodeNumber, params);
	}
	addStringProp(params: PUI_PropertyNodeStringArgs) {
		this.addNode(PUI_PropertyNodeString, params);
	}
	addVec2Prop(params: PUI_PropertyNodeVec2Args) {
		this.addNode(PUI_PropertyNodeVec2, params);
	}
	addVec3Prop(params: PUI_PropertyNodeVec3Args) {
		this.addNode(PUI_PropertyNodeVec3, params);
	}
	addColorProp(params: PUI_PropertyNodeColorArgs) {
		this.addNode(PUI_PropertyNodeColor, params);
	}
	addSelectorProp(params: PUI_PropertyNodeSelectorArgs) {
		this.addNode(PUI_PropertyNodeSelector, params);
	}
	addMultiSelectorProp(params: PUI_PropertyNodeMultiSelectorArgs) {
		this.addNode(PUI_PropertyNodeMultiSelector, params);
	}
	addCustomProp<T, Context>(params: PUI_CustomPropertyNodeArgs<T, Context>) {
		this.addNode(PUI_CustomPropertyNode, params);
	}
	addSwitcherProp(params: PUI_PropertyNodeSwitcherArgs){
		this.addNode(PUI_PropertyNodeSwitcher, params);
	}
	addSceneInstancesSelectorProp(params: PUI_SceneInstancesSelectorPropertyNodeArgs){
		this.addNode(PUI_SceneInstancesSelectorPropertyNode, params);
	}
	addSliderProp(params: PUI_PropertyNodeSliderArgs){
		this.addNode(PUI_PropertyNodeSlider, params);
	}

	addActionsNode<C>(params: PUI_ActionsNodeArgs<C>) {
		this.addNode(PUI_ActionsNode, params);
	}

	finish(): PUI_GroupNode {
		console.assert(this._groupsStack.length === 1);
		const rootGroup = this._groupsStack.pop()!;
		return rootGroup as PUI_GroupNode;
	}

	addNode<N extends PUI_Node, Params>(
		ctor: { new(params: Params): N },
		params: Params,
	): N {
		const currGroup = this._groupsStack.at(-1)!;
		const node = new ctor({
			...params,
			parent: currGroup,
		});
		currGroup.addMaybeChild(node);
		this._callEventsAfterNodeAdded(node);
		return node;
	}

	addChild<N extends PUI_Node>(node: N) {
		const currGroup = this._groupsStack.at(-1)!;
		currGroup.addMaybeChild(node);
		this._callEventsAfterNodeAdded(node);
	}

	_callEventsAfterNodeAdded(node: PUI_Node) {
		if (this._callbacks?.onAfterNodeAdded.length) {
			for (const c of this._callbacks.onAfterNodeAdded) {
				try {
					c(this, node);
				}
				catch (e) {
					console.error('error in builder after_node_add callback', e);
				}
			}
		}
	}
}



