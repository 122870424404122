import type { Aabb, Vector3 } from "math-ts";
import type { Bim, IdBimScene, SceneObjDiff } from "bim-ts";
import type { ESOHandle, ESOsCollection } from "../scene/ESOsCollection";
import type { ESOsHandlerBase } from "../esos/ESOsHandlerBase";
import type { ESO } from "../esos/ESO";
import type { ObservableObject } from "engine-utils-ts";
import type { AnnotationsSettings } from "./AnnotationsSettingsUiBindings";
import type { TextBlockGeometry } from "../geometries/EngineGeoTextBlock";


export interface AnnotationRepr {
    position: Vector3,
    textRepr: TextBlockGeometry
}


export interface AnnotationsMetrics {
    bbox: Aabb
}

export const enum AnnotationDirtyFlags {
    None = 0,
    Props = 1,
    Geo = 2,
    ChildrenProps = 4,
    ChildrenGeo = 8,
    ChildrenList = 16,
    All = 0xFF,
}


export abstract class AnnotationsCalculatorBase {
    readonly bim: Bim;
    readonly esos: ESOsCollection;
    readonly annotationsSettings: ObservableObject<AnnotationsSettings>;

    abstract parentBlocksMetrics: Map<IdBimScene, AnnotationsMetrics>;

    abstract parentsDescendants: Map<IdBimScene, IdBimScene[]>;
    
    abstract dirtyParents: Map<IdBimScene, AnnotationDirtyFlags>;

    abstract currentAnnotations: Map<ESOHandle, AnnotationRepr>;
    
    constructor(
        bim: Bim,
        esos: ESOsCollection,
        annotationsSettings: ObservableObject<AnnotationsSettings>
    ) {
        this.bim = bim;
        this.esos = esos;
        this.annotationsSettings = annotationsSettings;

        this.annotationsSettings.observeObject({
            settings: { immediateMode: true },
			onPatch: ({ patch, currentValueRef }) => {
                if (patch.showAnnotations !== undefined) {
                    if (currentValueRef.showAnnotations) {
                        this.markAllocated(new Set(this.bim.instances.allIds()));
                    }
                }/* else if (patch.annotationsMode !== undefined) {
                    this.markUpdated(new Map(
                        Array.from(this.bim.instances.allIds()).map(id => [id, SceneObjDiff.SpatialDescendants]), 
                    ));
                }*/
				return null;
			},
        });
    }

    abstract markAllocated(ids: Set<IdBimScene>): void;

    abstract markUpdated(perIdDiffs: Map<IdBimScene, SceneObjDiff>): void;

    abstract markDeleted(ids: Set<IdBimScene>): void;

    abstract updateAnnotations(handler: ESOsHandlerBase<ESO>): void;
}