<script lang="ts">
    import { Bim } from "bim-ts";
    import PUI_Root from '../pui/PUI_Root.svelte';
    import { ProjectHub } from "src/ProjectHub";
    import { getContext } from "svelte";
    import { PUI_Lazy } from "ui-bindings";
    import { createProjectSettingsUi } from "./ProjectSettings";

    const bim = getContext<Bim>('bim');
    const projectHub = getContext<ProjectHub>('projectHub');
    const projectId = getContext<number>('projectId');  

    const projectSettingsUi = createProjectSettingsUi(bim, projectHub, projectId);

</script>

<PUI_Root configIdentifier='project settings' puiSource={new PUI_Lazy(projectSettingsUi)} />
