import { Vector4 } from "math-ts";
import { ShaderFlags } from "./ShaderFlags";

export const TrackersPilesBillboardShader = Object.freeze({
	
	name: 'trackers-piles-billboard-shader',
	flags: ShaderFlags.IS_TRACKER_PILE_BILLBOARD_GEO,

	uniforms: {
		billboardImage: { value: null },
		color: { value: new Vector4(1, 1, 1, 1) },
	},

	vertexShader:
	`
	#include <kr_std_attributes>
	#include <kr_std_uniforms>
	#include <kr_std_vars>
	#include <common>
	
	void main() {
		#include <kr_std_vars_calc>
	}
	`,

	fragmentShader:
	`
	#include <kr_std_uniforms>
	#include <kr_std_vars>
	#include <common>

	uniform vec3 color;

	#if defined(MRT_NORMALS)
		layout(location = 1) out lowp vec4 fragData1;
	#endif

	void main() {
		#include <kr_std_pixel>

		#ifdef MRT_NORMALS 
			fragData1 = vec4(1.0);
		#endif

		gl_FragColor = texture2D(billboardImage, vUv.xy);
		
		if(gl_FragColor.a < 0.5) discard;

		gl_FragColor.rgb *= color.rgb;
		gl_FragColor.rgb /= gl_FragColor.a;
	}
	`,
});