import { registerCostsConfig } from "src/cost-model/capital/cost-configs/CostsConfig";
import type { Bim } from "../..";
import { registerAugmentConfig } from "./AugmentConfig";
import { registerCostAnalysisConfig } from "./CostAnalysis";
import { registerCutFillConfig } from "./CutFillConfig";
import { registerEquipmentSlopeAnalysisConfig } from "./EquipmentSlopeAnalysis";
import { registerFarmLayoutConfig } from "./FarmLayoutConfigType";
import { registerLvWiringConfig } from "./LvWiringConfig";
import { registerMvWiringConfig } from "./MvWiringConfigType";
import { registerSystemOfUnits } from "./SystemOfUnitsConfigType";
import { registerTerrainAnalysisConfig } from "./TerrainAnalysisConfig";
import { registerTrackerPositionsConfig } from "./TrackerPositionsConfigType";
import { registerTrenchesConfig } from "./TrenchesConfigType";
import { registerPileBinsConfig } from "../../piles/PileBinsConfig";
import { registerLcoeSettings } from "src/cost-model/lcoe/LcoeSettings";

export function registerConfigArchetypes(bim: Bim) {
    registerTrenchesConfig(bim);
    registerTrackerPositionsConfig(bim);
    registerMvWiringConfig(bim);
    registerFarmLayoutConfig(bim);
    registerTerrainAnalysisConfig(bim);
    registerEquipmentSlopeAnalysisConfig(bim);
    registerCutFillConfig(bim);
    registerLvWiringConfig(bim);
    registerCostAnalysisConfig(bim);
    registerSystemOfUnits(bim);
    registerAugmentConfig(bim);
    registerCostsConfig(bim);
    registerPileBinsConfig(bim);
    registerLcoeSettings(bim);
}
