//@ts-ignore
import ClipperLib from '@doodle3d/clipper-lib';

import type {
	AnyBimGeometry, Bim, IdBimGeo, IdBimScene, SceneInstance
} from 'bim-ts';
import {
	BasicAnalyticalRepresentation, ExtrudedPolygonGeometry, SceneInstanceFlags
} from 'bim-ts';
import type { LazyVersioned, UndoStack , Result } from 'engine-utils-ts';
import { Failure, IterUtils, LazyDerived, nameof, ObjectUtils, ObservableObject, Success } from 'engine-utils-ts';
import type { ClipperPoint} from 'math-ts';
import { Clipper, Vector3 } from 'math-ts';
import type { MenuPath} from 'ui-bindings';
import { PUI_ConfigBasedBuilderParams, PUI_ConfigPropertyTransformer } from 'ui-bindings';
import type { SceneInt } from '../scene/SceneRaycaster';
import type { EditModeControls } from './EditControls';
import { MouseButton } from './InputController';
import type { EditInteractionResult, InteractiveEditOperator } from './InteractiveEditOperator';
import type { MouseEventData } from './MouseGesturesBase';

export class BoundaryOffsetSetting {
	offset: number = 1;
	clone: boolean = false;
}

type InstancesDescr =  [IdBimScene, IdBimGeo, ExtrudedPolygonGeometry][];

class InstancesPerSetting {

	constructor(
		public offset: number,
		public instances: InstancesDescr,
	) {
	}

	shallowClone() {
		return new InstancesPerSetting(this.offset, this.instances.slice());
	}

	reconcileOffset(offset: number, sourceGeometries: [IdBimGeo, ExtrudedPolygonGeometry][], bim: Bim) {
		if (this.offset == offset) {
			return;
		}
		this.offset = offset;
		const geometriesPatched = sourceGeometries.map(t => createOffsettedPolygonGEo(offset, t[1]));
		const thisGeoIds = this.instances.map(t => t[1]);
		const geometriesPatch = IterUtils.map2(thisGeoIds, geometriesPatched, (id, geo) => [id, geo] as [IdBimGeo, ExtrudedPolygonGeometry]);
		bim.allBimGeometries.applyPatches(geometriesPatch);
	}

}

export interface BoundaryOffsetState {
	initialGeometries: [IdBimGeo, ExtrudedPolygonGeometry][];
	selected: InstancesPerSetting;
	cloned: InstancesPerSetting,
}


export class InteractiveBoundaryOffset implements InteractiveEditOperator<BoundaryOffsetState, BoundaryOffsetSetting> {

	readonly menuPath: MenuPath = ['Edit', 'Offset boundary'];
	readonly priority: number = 1;
	readonly canStart: LazyVersioned<boolean>;
	readonly config: ObservableObject<BoundaryOffsetSetting>;

	constructor(
		readonly bim: Bim,
		readonly editControls: EditModeControls,
		undoStack: UndoStack
	) {
		this.canStart = LazyDerived.new1(
			'isBoundarySelected',
			[],
			[bim.instances.selectHighlight.getVersionedFlagged(SceneInstanceFlags.isSelected)],
			([s]) => s.some(id => bim.instances.peekById(id)?.type_identifier === 'boundary')
		);
		this.config = new ObservableObject({
			identifier: this.menuPath.join(),
			initialState: new BoundaryOffsetSetting(),
			undoStack,
			throttling: { onlyFields: [] }
		});
	}

	configBuilderSettings(): PUI_ConfigBasedBuilderParams {
		return PUI_ConfigBasedBuilderParams.new([
				[
					nameof<BoundaryOffsetSetting>("offset"),
					PUI_ConfigPropertyTransformer.numberProp({
						minMax: [-500, 500],
						step: 0.01,
						unit: "m"
					}),
				],
			]);
	};


	start(): Result<BoundaryOffsetState> {
		const selected = this.bim.instances.getSelected();
		if (selected.length === 0) {
			return new Failure({msg: 'nothing selected'});
		}
		const sourceInstances: [IdBimScene, IdBimGeo, ExtrudedPolygonGeometry][] = [];
		for (const id of selected) {
			const instance = this.bim.instances.peekById(id);
			if (instance?.type_identifier !== 'boundary') {
				continue;
			}
			const bimGeoId = instance.representationAnalytical?.geometryId!;
			const bimGeo = this.bim.allBimGeometries.peekById(bimGeoId);
			if (!(bimGeo instanceof ExtrudedPolygonGeometry)) {
				continue;
			}
			sourceInstances.push([id, bimGeoId, bimGeo]);
		}
		if (sourceInstances.length === 0) {
			return new Failure({msg: 'nothing to offset'});
		}
		let state: BoundaryOffsetState = {
			initialGeometries: sourceInstances.map(t => [t[1], ObjectUtils.deepCloneObj(t[2])]),
			selected: new InstancesPerSetting(0, sourceInstances),
			cloned: new InstancesPerSetting(0, []),
		};
		state = this._reconcileStateWithConfig(state);
		return new Success(state);
	}
	cancel(state: BoundaryOffsetState) {
		this.bim.instances.delete(state.cloned.instances.map(t => t[0]));
		state.selected.reconcileOffset(0, state.initialGeometries, this.bim);
	}
	handleConfigPatch(
		patch: Partial<BoundaryOffsetSetting>,
		state: Readonly<BoundaryOffsetState>
	): EditInteractionResult<BoundaryOffsetState> {
		const newState = this._reconcileStateWithConfig(state);
		return {
			done: false,
			state: newState
		};
	}

	onHover(int: SceneInt): { cursorStyleToSet: string; } | null {
		return { cursorStyleToSet: "crosshair" };
	}

	handleClick(
		sceneInt: SceneInt,
		me: MouseEventData,
		previousResult: BoundaryOffsetState
	): EditInteractionResult<BoundaryOffsetState> {
		if (me.buttons.mouseButton === MouseButton.Right) {
			return {
				state: previousResult,
				done: true,
			};
		}
		return {
			done: false,
			state: previousResult
		};
	}


	_reconcileStateWithConfig(prevState: Readonly<BoundaryOffsetState>): BoundaryOffsetState {
		const config = this.config.poll();
		let state: BoundaryOffsetState = {
			initialGeometries: prevState.initialGeometries,
			selected: prevState.selected.shallowClone(),
			cloned: prevState.cloned.shallowClone(),
		};


		if (config.clone == false && state.cloned.instances.length > 0) {
			this.bim.instances.delete(state.cloned.instances.map(t => t[0]));
			state.cloned.instances.length = 0;
		} else if (config.clone && state.cloned.instances.length === 0) {
			const toAllocGeometries: [IdBimGeo, AnyBimGeometry][] = [];
			const toAllocInstances: [IdBimScene, Partial<SceneInstance>][] = [];
			for (let i = state.cloned.instances.length; i < state.selected.instances.length; ++i) {
				const sourceInstanceId = state.selected.instances[i][0];
				const sourceGeo = state.initialGeometries[i][1];

				const newGeoId = this.bim.extrudedPolygonGeometries.reserveNewId();
				const newGeo = createOffsettedPolygonGEo(config.offset, sourceGeo);
				toAllocGeometries.push([newGeoId, newGeo]);

				const sourceInstance = this.bim.instances.peekById(sourceInstanceId);
				if (!sourceInstance) {
					continue;
				}
				const newInstId = this.bim.instances.reserveNewId();
				const newInstance: Partial<SceneInstance> = {
					colorTint: sourceInstance.colorTint,
					spatialParentId: sourceInstance.spatialParentId,
					localTransform: sourceInstance.localTransform.clone(),
					type_identifier: sourceInstance.type_identifier,
					properties: sourceInstance.properties.clone(),
					name: sourceInstance.name + "(offset clone)",
					representationAnalytical: new BasicAnalyticalRepresentation(newGeoId)
				};

				toAllocInstances.push([newInstId, newInstance]);

				state.cloned.instances.push([newInstId, newGeoId, newGeo]);
			}

			this.bim.allBimGeometries.allocate(toAllocGeometries);
			this.bim.instances.allocate(toAllocInstances);
		}

		const selectedOffset = config.clone ? 0 : config.offset;
		state.selected.reconcileOffset(selectedOffset, state.initialGeometries, this.bim);

		state.cloned.reconcileOffset(config.offset, state.initialGeometries, this.bim);

		return state;
	}
}

export function createOffsettedPolygonGEo(offset: number, sourceGeo: ExtrudedPolygonGeometry) {
	if (!offset) {
		return sourceGeo;
	}
	const points = sourceGeo.outerShell.points.map(p => new Vector3(p.x, p.y, 0));
	if (!points.length) {
		return sourceGeo;
	}
	const {mapToClipper, mapFromClipper, scaleToClipper} = Clipper.getClipperPointsMappingFuncs3D(points[0], 0.01);
	const clipperPath = points.map(mapToClipper);
	const co = new ClipperLib.ClipperOffset();
	co.AddPath(clipperPath, ClipperLib.JoinType.jtSquare, ClipperLib.EndType.etClosedPolygon);
	const solution: ClipperPoint[][] = new ClipperLib.Paths();
	const mappedOffset = scaleToClipper(offset);
	co.Execute(solution, mappedOffset);

	if (solution.length > 0) {
		const solutionPoints = IterUtils.maxBy(solution, (s) => s.length)!;
		const offsetPoints: Vector3[] = solutionPoints.map(mapFromClipper);
		const newGeo = ExtrudedPolygonGeometry.newWithAutoIds(
			offsetPoints.map(p => p.xy()),
			undefined,
			sourceGeo.baseElevation,
			sourceGeo.topElevation
		);
		console.log('geo offset', points.map(p => p.xy()), offsetPoints.map(p => p.xy()));
		return newGeo;
	} else {
		console.error('no offset solution', mappedOffset, offset);
		return sourceGeo;
	}


}
