import { DC_CNSTS } from 'bim-ts';
import type { Vector2 } from 'math-ts';
import { Euler } from 'math-ts';
import type { Circuit } from '../models/circuit';
import type { NodeId } from '../models/node';
import { Node } from '../models/node';
import type { Connection } from '../models/connection';
import { Route } from '../models/route';
import { checkMergingConnectionsStartingFromSamePoint } from './merging';
import { makePairingForNode } from './pairing';
import { RotateUtils } from './rotate-utils';
import { Wrapper } from './wrap-routes';
import { findPolylineLength } from '../../utils';
import { ANGLE_RAD_EPSILON, DISTANCE_EPSILON } from '../../constants';
import { DefaultMapObjectKey, ObjectUtils, Yield } from 'engine-utils-ts';


export class RouteSolver {
    private readonly seenNodesForRouteSolving: Set<NodeId> = new Set();
    private _rotateUtils?: RotateUtils;
    private get rotateUtils() {
        if (!this._rotateUtils)
            throw new Error('No rotate utils found.');
        return this._rotateUtils;
    }
    constructor(private readonly circuit: Circuit) {}

    *solveCircuit() {
        for (const root of this.circuit.nodes.roots) {
            this.solveRoot(root);
            yield Yield.NextFrame;
        }
    }

    private solveRoot(node: Node) {
        node.assertsSi();
        let fullRotation =
            new Euler().setFromRotationMatrix(node.si.i.worldMatrix).z;
        fullRotation %= 2 * Math.PI;
        if (Math.abs(Math.PI/2 - fullRotation) < ANGLE_RAD_EPSILON)
            fullRotation = 0;
        if (Math.abs(Math.PI*3/2 - fullRotation) < ANGLE_RAD_EPSILON)
            fullRotation = 0;
        const tiltRotation =
            fullRotation - this.circuit.bimOps.globalTrackerAngle;
        // reinit rotateUtils for current root based on its rotation.
        this._rotateUtils = new RotateUtils(tiltRotation);
        const wrapper = new Wrapper(node, this.rotateUtils);
        const connectionsByStartPoint =
            new DefaultMapObjectKey<Vector2, Connection[]>({
                valuesFactory: () => [],
                unique_hash: JSON.stringify,
            });
        Node.traverseBranch({ node,
            beforeEach: node => {
                return !this.solveNode(node);
            },
            afterEach: node => {
                makePairingForNode(node);
                wrapper.addNodeStats(node);
                const conns = connectionsByStartPoint
                    .getOrCreate(ObjectUtils.deepFreeze(node.actualPosition.clone()));
                conns.push(...node.toChildren);
                return false;
            },
        });
        for (const conns of connectionsByStartPoint.values()) {
            checkMergingConnectionsStartingFromSamePoint(conns);
        }
        wrapper.wrap();
    }

    /**
     * @returns
     * Was operation successful
     */
    private solveNode(node: Node): boolean {
        if (this.seenNodesForRouteSolving.has(node.id)) return true;
        for (const request of node.toChildren) {
            const to = request.to;
            if (to.hints.length)
                this.solveConnectionFromFixedToSemifixed(request);
            else
                this.solveConnectionToUtility(request);
        }
        return true;
    }


    private solveConnectionFromFixedToSemifixed(req: Connection) {
        const { from, to } = req;
        this.setActualPositionBasedOnDistanceToFixedNode(from, to);
        const {
            from: { actualPosition: fromPt },
            to: { actualPosition: toPt },
        } = req;
        if (this.rotateUtils.arePointsOrtagonal(fromPt, toPt)) {
            req.route = new Route({
                connection: req, points: [fromPt.clone(), toPt.clone()],
            });
            return;
        }
        const shortestIntermid = this.rotateUtils
            .findOrtagonalIntermidPoints(fromPt, toPt)
            .horizontalThenVertical;
        req.route = new Route({
            points: [
                fromPt.clone(),
                shortestIntermid.clone(),
                toPt.clone(),
            ], connection: req,
        });
    }

    private solveConnectionToUtility(req: Connection) {
        const from = req.from;
        const utility = req.to;
        //if (utility.toChildren.length !== 1)
        //    throw new Error('Utility should have single request to children');
        for (const reqFromUtility of utility.toChildren) {
            const to = reqFromUtility.to;
            this.setActualPositionBasedOnDistanceToFixedNode(from, to);
            const intermidPoints = this.rotateUtils.findOrtagonalIntermidPoints(
                from.actualPosition,
                to.actualPosition,
            );
            utility.actualPosition = intermidPoints.horizontalThenVertical;
            if (
                req.constraints.includes(DC_CNSTS.Constraints.Vertical) ||
                reqFromUtility.constraints.includes(DC_CNSTS.Constraints.Horizontal)
            ) utility.actualPosition = intermidPoints.verticalThenHorizontal;
            this.solveConnectionFromFixedToSemifixed(req);
            this.solveConnectionFromFixedToSemifixed(reqFromUtility);
        }
    }


    /**
     * from should have actualPosition set
     */
    private setActualPositionBasedOnDistanceToFixedNode(
        fromFixedNode: Node,
        toSemifixedNode: Node,
    ) {
        if (toSemifixedNode.isActualPositionCalculated) return;
        if (!toSemifixedNode.hints.length)
            throw new Error('No hints found to calculate actual position from');
        const fromPos = fromFixedNode.actualPosition;
        const hintsSortedByDistance = [...toSemifixedNode.hints]
            .sort((a, b) => {
                const distanceWithA = this.rotateUtils
                    .findOrtagonalIntermidPoints(a, fromPos).distance;
                const distanceWithB = this.rotateUtils
                    .findOrtagonalIntermidPoints(b, fromPos).distance;
                return distanceWithA - distanceWithB;
            });
        const childrenAvHint = toSemifixedNode.averageChildrenHint;
        if (childrenAvHint !== null) {
            toSemifixedNode.actualPosition =
                this.findActualPointBasedOnNextChildPosition(
                    fromPos,
                    hintsSortedByDistance,
                    childrenAvHint,
                );
            return;
        }

        toSemifixedNode.actualPosition = hintsSortedByDistance[0];
    }

    private findActualPointBasedOnNextChildPosition(
        fromPosition: Vector2,
        childrenSortedHints: Vector2[],
        grandChildAvHint: Vector2,
    ) {
        const fromToChildIntermid = this.rotateUtils
            .findOrtagonalIntermidPoints(fromPosition, grandChildAvHint);
        for (const hint of childrenSortedHints) {
            const fromToHintIntermid = this.rotateUtils
                .findOrtagonalIntermidPoints(fromPosition, hint);
            const directDistance = findPolylineLength([
                fromPosition,
                fromToChildIntermid.verticalThenHorizontal,
                grandChildAvHint,
            ]);
            const distanceThroughHint = findPolylineLength([
                fromPosition,
                fromToHintIntermid.verticalThenHorizontal,
                fromToChildIntermid.verticalThenHorizontal,
                grandChildAvHint,
            ]);
            if (distanceThroughHint - directDistance < DISTANCE_EPSILON)
                return hint;
        }
        return childrenSortedHints[0];
    }

}

