import type { Bim, SceneInstance} from "bim-ts";
import { TerrainElevation, TerrainHeightMapRepresentation } from "bim-ts";
import { Vector2, Vector3, Matrix4 } from "math-ts";
import type { ScopedLogger} from "engine-utils-ts";
import { IterUtils, Result, Success, Yield } from "engine-utils-ts";
import type { DemHeader, DemProfile } from "./DemSurface";

export function *ConvertTerrainToDem(bim: Bim, logger: ScopedLogger, terrainInstance: SceneInstance, moduleSize:number) {

    const terrain = terrainInstance.representation as TerrainHeightMapRepresentation;
    const step = moduleSize;
    const demProfiles = yield* SurfaceToProfiles(bim, logger, terrainInstance, step);

    const name = terrain ? terrain.geometriesIdsReferences.name : "NoName"
    const bounds = GetSurfaceBB(bim, terrainInstance);

    const demheader: DemHeader = {
        QuadrangleName: name,
        DemLevelCode: 1,
        PatternCode: 1,
        PlanimetricReferenceSystemCode: 0,
        ZoneCode: 0,
        ProjectParameters: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        UnitsCodePlanimetric: 2,
        UnitsCodeElevation: 2,
        PoligonSides: 4,
        DemBounds: bounds,
        MinElevation:demProfiles.minElevation,
        MaxElevation:demProfiles.maxElevation,
        Angle: 0,
        AccuracyCode: 1,
        SpatalResolution: { width: step, length: step, height: step },
        Profiles: { rows: 1, columns: demProfiles.numberOfProfiles }
    }
    let sb = DemHeaderToString(demheader);
    sb+=demProfiles.profiles;

    return sb;
}


function *SurfaceToProfiles(bim: Bim, logger: ScopedLogger, terrainInstance: SceneInstance, step: number) {

    const baseElevation = 0;

    if (!(terrainInstance.representation instanceof TerrainHeightMapRepresentation)) {
        throw new Error(`unrecognized terrain representation`);
    }

    let sb = '';
    let minElevation = Infinity;
    let maxElevation = -Infinity;

    const terrain = terrainInstance.representation as TerrainHeightMapRepresentation;
    const instanceOffset3d = terrainInstance.worldMatrix;
    
    const aabbs = bim.allBimGeometries.aabbs.poll();
    const repr = terrain.aabb(aabbs).applyMatrix4(instanceOffset3d)
    const xy = repr.xy()
    const origin3d = bim.instances.getSceneOrigin()?.cartesianCoordsOrigin ?? new Vector3(0, 0, 0);
    
    const min = xy.min;
    const max = xy.max;

    const localTiles = new Map(
        IterUtils.mapIter(
            terrainInstance.representation.tiles,
            ([tileId, tile]) => {
                if (tile.updatedGeo !== 0) {
                    return [tileId, tile.updatedGeo];
                } else {
                    return [tileId, tile.initialGeo];
                }
            }
        )
    );

    let i = 1;
    for (let x = min.x; x <= max.x; x += step) {
        const profilePoints: Vector2[] = [];
        for (let y = min.y; y <= max.y; y += step) {
            profilePoints.push(new Vector2(x, y));
        }

        const heightData = TerrainElevation.sampleFromTiles({
            logger:logger, 
            tileSize: terrainInstance.representation.tileSize, 
            localTiles:localTiles, 
            bim: bim,
            worldMatrix: terrainInstance.worldMatrix,
            positionsToSampleAtWs: profilePoints
        });

        const countElevations: number[] = [];
        let minProfileElevation = Infinity;
        let maxProfileElevation = - Infinity;
        const heights = heightData.flatMap((v, i, arr) => {
            if (v.elevation === null) {
                //Set the height of the neareast known elevation 
                //const elev = arr.slice(0, i).reverse().find(item => item.elevation !== null)?.elevation ?? 0;
                return baseElevation;
            } else {
                const elevation = v.elevation;
                minProfileElevation = elevation < minProfileElevation ? elevation:minProfileElevation;
                maxProfileElevation = elevation > maxProfileElevation ? elevation:maxProfileElevation;
                return elevation
            }
        });

        if(minProfileElevation===Infinity || maxProfileElevation===-Infinity){
            throw new Error(`Can't find min/max height in ${i} profile!`)
        }

        const demProfile: DemProfile = {
            Row: 1,
            Column: i,
            NumberOfElevations: heights.length,
            ProfileColumns: 1,
            Coordinate: { x: x+origin3d.x, y: profilePoints[0].y+origin3d.y },
            LokalElevation: 0,
            MinProfileElevation: minProfileElevation,
            MaxProfileElevation: maxProfileElevation,
            Elevations: heights,
        };
        
        if(minProfileElevation < minElevation){
            minElevation = minProfileElevation;
        }
        if(maxProfileElevation > maxElevation){
            maxElevation = maxProfileElevation;
        }

        sb+= DemProfileToString(demProfile);
        i++;
        yield Yield.Asap;     
    }

    return {profiles:sb, minElevation:minElevation, maxElevation:maxElevation, numberOfProfiles:i-1};
}

function GetSurfaceBB(bim: Bim, instance: SceneInstance): Vector2[] {
    const terrainRepresentation = instance.representation as TerrainHeightMapRepresentation;
    if(terrainRepresentation == null){
        throw new Error(`unrecognized terrain representation`);
    }

    const geosAabbs = bim.allBimGeometries.aabbs.poll();
    const origin3d = bim.instances.getSceneOrigin()?.cartesianCoordsOrigin ?? new Vector3(0, 0, 0);
    const instanceOffset3d = instance.worldMatrix;

    const terrainAabb = terrainRepresentation
        .aabb(geosAabbs)
        .applyMatrix4(instanceOffset3d)
        .translate(origin3d);

    const points = Array.from(terrainAabb.xy().cornerPoints())

    return points;
}

function DemHeaderToString(demHeader: DemHeader): string {

    let res = ResizeStringLength(demHeader.QuadrangleName, 144, false);

    res += ResizeStringLength(demHeader.DemLevelCode.toString(), 6, true);
    res += ResizeStringLength(demHeader.PatternCode.toString(), 6, true);
    res += ResizeStringLength(demHeader.PlanimetricReferenceSystemCode.toString(), 6, true);
    res += ResizeStringLength(demHeader.ZoneCode.toString(), 6, true);

    demHeader.ProjectParameters.forEach(p => {
        let num = convertToFortranNotation(p, 15);
        res += ResizeStringLength(num, 24, true);
    });

    res += ResizeStringLength(demHeader.UnitsCodeElevation.toString(), 6, true);
    res += ResizeStringLength(demHeader.UnitsCodePlanimetric.toString(), 6, true);
    res += ResizeStringLength(demHeader.PoligonSides.toString(), 6, true);

    demHeader.DemBounds.forEach(b => {
        res += ResizeStringLength(convertToFortranNotation(b.x, 15), 24, true);
        res += ResizeStringLength(convertToFortranNotation(b.y, 15), 24, true);
    });

    res += ResizeStringLength(convertToFortranNotation(demHeader.MinElevation, 15), 24, true);
    res += ResizeStringLength(convertToFortranNotation(demHeader.MaxElevation, 15), 24, true);
    res += ResizeStringLength(convertToFortranNotation(demHeader.Angle, 15), 24, true);

    const resolution = demHeader.SpatalResolution;
    const acuracyCode = "0";
    let r = acuracyCode;
    r += convertToFortranNotation(resolution.width, 6, false);
    r += convertToFortranNotation(resolution.length, 6, false);
    r += convertToFortranNotation(resolution.height, 6, false);

    res += ResizeStringLength(r, 42, true);

    const profiles = demHeader.Profiles;

    res += ResizeStringLength(profiles.rows.toString(), 6, true)
    res += ResizeStringLength(profiles.columns.toString(), 6, true)
    res += " ".repeat(160);

    return res;
}


function DemProfileToString(demProfile: DemProfile): string {

    let sb = ResizeStringLength(demProfile.Row.toString(), 6, true);
    sb += ResizeStringLength(demProfile.Column.toString(), 6, true);
    sb += ResizeStringLength(demProfile.Elevations.length.toString(), 6, true);
    sb += ResizeStringLength(demProfile.ProfileColumns.toString(), 6, true);
    sb += ResizeStringLength(convertToFortranNotation(demProfile.Coordinate.x, 15), 24, true);
    sb += ResizeStringLength(convertToFortranNotation(demProfile.Coordinate.y, 15), 24, true);
    sb += ResizeStringLength(convertToFortranNotation(demProfile.LokalElevation, 15), 24, true);
    sb += ResizeStringLength(convertToFortranNotation(demProfile.MinProfileElevation, 15), 24, true);
    sb += ResizeStringLength(convertToFortranNotation(demProfile.MaxProfileElevation, 15), 24, true);

    for (let i = 0; i < demProfile.Elevations.length; i++) {
        let h = demProfile.Elevations[i].toFixed(0)
        sb += ResizeStringLength(h, 6, true);
    }
    return ChunkSubstr(sb, 1020);
}


function ResizeStringLength(st: string, length: number, toEndPosition: boolean) {

    let sb = "";
    let i = 0;

    st = toEndPosition ? ReverseString(st) : st;

    while (sb.length < length) {

        if (i < st.length) {
            sb += st[i];
        } else {
            sb += " ";
        }
        i++;
    }

    sb = toEndPosition ? ReverseString(sb) : sb;

    return sb;
}

function ReverseString(str: string) {
    return str.split("").reverse().join("");
}

function ChunkSubstr(str: string, size: number) {
    const numChunks = Math.ceil(str.length / size)
    const chunks = new Array(numChunks)
    
    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
        let chunk = ""
        let subStr = str.substring(o, o + size);

        for(let i=0;i<1024;i++){
            if(i<subStr.length){
                chunk+=subStr[i];
            }else{
                chunk+=' ';
            }
        }

        chunks[i] = chunk;
    }

    return chunks.join('');
}

function convertToFortranNotation(value:number, precision:number, isFortranNotation?:boolean):string{
    if(!Number.isFinite(value)){
        value = 0;
    }
    let isFortran = isFortranNotation ?? true;
    let power = 0;
    let num = value;
    while (num >= 1) {
        num /= 10;
        power++;
    }

    if(value<0){
        precision-=1
    }

    const coefficient = num.toFixed(precision);
    const base = isFortran ? 16 : 10;

    let baseExponent = power.toString(base).toUpperCase();

    if(baseExponent.length<2){
        baseExponent = "0"+baseExponent;
    }
    
    if(power>0 && value<1){
        power = power*-1;
    }

    const exp = isFortran?"D":"E";

    const fortranNotation = `${coefficient}${exp}${power >= 0 ? '+' : ''}${baseExponent}`;

    return fortranNotation;
}
