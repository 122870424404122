import type { Disposable, LazyVersioned, Result, VersionedValue} from "engine-utils-ts";
import { Allocated, Deleted, IterUtils, LazyBasic, LazyDerived, LogLevel, ScopedLogger, StreamAccumulator, VersionedInvalidator, Yield } from "engine-utils-ts";
import type { Bim } from "../Bim";
import type { PUI_GroupNode, RuntimeSystemUiDescription } from "ui-bindings";
import { RuntimeSystemExecutionStatus } from "ui-bindings";
import type { EntitiesCollectionUpdates } from "../collections/EntitiesCollectionUpdates";
import type { IdBimScene } from "../scene/SceneInstances";
import type { SceneObjDiff } from 'src/scene/SceneObjDiff';
import type { GlobalArgsSelector, SharedGlobalsInput } from './RuntimeGlobals';
import { DurationTimer, type SolversExecutionMetrics } from './DurationTimer';


export interface BimCustomRuntime<GAS extends GlobalArgsSelector> extends VersionedValue, Disposable {

    readonly executionOrder: number;
    readonly name: string;

    readonly globalArgsSelector?: GlobalArgsSelector;

    readonly ui?: LazyVersioned<PUI_GroupNode>;
    // readonly settings: LazyVersioned<Settings>;
    executionStatus(): RuntimeSystemExecutionStatus;

    invalidateFromBimUpdates(args: {
        bim: Bim,
        updates: EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>[],
    }): void;

    invalidateFromSharedDependenciesUpdates(ident: keyof GAS): void;

    applyQuickPreWorkUpdates(args: {bim: Bim}): void;

    asyncWorkToRun(args: {
        bim: Bim,
        backendBasedCalculator: BackendBasedCalculationsInvoker<any, any>,
        globalArgs?: Readonly<SharedGlobalsInput<GAS>>
    }): CustomRuntimeAsyncWork<any> | null;

    applyQuickPostWorkUpdates(args: {
        bim: Bim,
    }): void;
}

export interface CustomRuntimeAsyncWork<GeneratorResult> {
    generator: Generator<Yield, GeneratorResult>;
    onSucess?:(res:GeneratorResult) => void;
    onError?: (e: Error) => void;
    finally: () => void;
}

export interface BackendCalculationArgs<Request> {
    apiPath: string;
    requestObject: Request
}

export interface BackendBasedCalculationsInvoker<Request, Response> {
    makeRequest(args: BackendCalculationArgs<Request>): Generator<Yield, Result<Response>>;
}


export class BimCustomRuntimes implements VersionedValue {

    readonly logger: ScopedLogger;
    readonly bim: Bim;

    readonly runtimes: BimCustomRuntime<GlobalArgsSelector>[] = [];
    readonly _runtimesPerSharedDepIdent: Map<string, BimCustomRuntime<GlobalArgsSelector>[]> = new Map();
    readonly mainThreadExecutionTimePerRuntime: Map<string, number> = new Map();

    readonly instancesUpdatesAccumulated: StreamAccumulator<EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>>;

    private _durationTimer = new DurationTimer();
    private _invalidator = new VersionedInvalidator();

    constructor(
        bim: Bim
    ) {
        this.logger = new ScopedLogger('custom-runtimes', LogLevel.Info);
        this.bim = bim;
        this.instancesUpdatesAccumulated = new StreamAccumulator(this.bim.instances.updatesStream);

        bim.runtimeGlobals.updatesStream.subscribe({
            settings: {immediateMode: true},
            onNext: (ident) => {
                const srs = this._runtimesPerSharedDepIdent.get(ident);
                if (srs) {
                    for (const rt of srs) {
                        rt.invalidateFromSharedDependenciesUpdates(ident);
                    }
                }
            }
        });
    }

    version(): number {
        return this._invalidator.version();
    }

    dispose() {
        for (const r of this.runtimes) {
            try {
                r.dispose();
            } catch (e) {
                this.logger.error('error during custom runtime disposing', e);
            }
        }
        this.runtimes.length = 0;
        this.instancesUpdatesAccumulated.dispose();
    }

    forceMarkDirtyInstancesIfCalculated(ids: IdBimScene[]) {
        // not to extend custom runtime interface with this additional invalidation method
        // force invalidation from patches via delete+alloc combination
        for (const r of this.runtimes) {
            r.invalidateFromBimUpdates({
                bim: this.bim,
                updates: [
                    new Deleted(ids),
                    new Allocated(ids),
                ]
            })
        }
    }

    registerCustomRuntime<SID extends GlobalArgsSelector>(runtime: BimCustomRuntime<SID>) {
        if (this.runtimes.find(r => r.name === runtime.name)) {
            this.logger.error(`runtime with identifier ${runtime.name} is already registered`);
            return;
        }
        this.runtimes.push(runtime);
        this._invalidator.addDependency(runtime);
        this.runtimes.sort((a, b) => a.executionOrder - b.executionOrder);
        if (runtime.globalArgsSelector) {
            const sharedDepIdents = Object.keys(runtime.globalArgsSelector);
            for (const ident of sharedDepIdents) {
                let runtimes = this._runtimesPerSharedDepIdent.get(ident);
                if (!runtimes) {
                    runtimes = [];
                    this._runtimesPerSharedDepIdent.set(ident, runtimes);
                }
                runtimes.push(runtime);
            }
        }
    }

    _hasPendingUpdates() {
        for (const rt of this.runtimes) {
            const status = rt.executionStatus();
            if (status === RuntimeSystemExecutionStatus.InProgress || status === RuntimeSystemExecutionStatus.Waiting) {
                return true;
            }
        }
        return false;
    }

    _addExecutionTimeFor(runtimeIdent: string, time: number) {
        this.mainThreadExecutionTimePerRuntime.set(
            runtimeIdent,
            (this.mainThreadExecutionTimePerRuntime.get(runtimeIdent) ?? 0) + time
        );
    }


    runUpdate(timeEndLimit: number) {
        const instancesUpdates = this.instancesUpdatesAccumulated.consume();

        const timer = this._durationTimer;
        if (instancesUpdates) {
            for (const runtime of this.runtimes.values()) {
                try {
                    timer.reset();
                    runtime.invalidateFromBimUpdates({bim: this.bim, updates: instancesUpdates});
                    this._addExecutionTimeFor(runtime.name, timer.consumeDuration());
                } catch (e) {
                    this.logger.error('error during custom runtime update', e);
                }
            }
        }
        if (performance.now() >= timeEndLimit) {
            return;
        }

        allRuntimesLoop:
        for (const runtime of this.runtimes.values()) {
            const status = runtime.executionStatus();
            if (status === RuntimeSystemExecutionStatus.Done || status === RuntimeSystemExecutionStatus.Disabled) {
                continue;
                // invalidation from globals will be done inside the bim runtime loop, we dont have to poll them here
            }

            let globalArgs: SharedGlobalsInput<any> | undefined = undefined;
            if (runtime.globalArgsSelector) {
                const globalsGenerator = this.bim.runtimeGlobals.acquireTypedObj(runtime.globalArgsSelector);
                do {
                    const res = globalsGenerator.next();
                    if (res.done) {
                        globalArgs = res.value;
                        break;
                    } else if (res.value === Yield.NextFrame) {
                        continue allRuntimesLoop;
                    }
                } while (performance.now() < timeEndLimit);
            }

            timer.reset();
            try {
                runtime.applyQuickPreWorkUpdates({bim: this.bim});
                this._addExecutionTimeFor(runtime.name, timer.consumeDuration());
            } catch (e) {
                this.logger.error('error during custom runtime pre work update', e);
            }

            const toRun = runtime.asyncWorkToRun({
                bim: this.bim,
                backendBasedCalculator: {} as any,
                globalArgs,
            });
            if (!toRun) {
                continue;
            }
            while (true) {
                try {
                    const {value, done} = toRun.generator.next();
                    this._addExecutionTimeFor(runtime.name, timer.consumeDuration());
                    if (done) {
                        if (toRun.onSucess) {
                            toRun.onSucess(value);
                        }
                        toRun.finally();
                        break;
                    }
                    if (value === Yield.NextFrame) {
                        break;
                    }
                    if (performance.now() >= timeEndLimit) {
                        break allRuntimesLoop;
                    }

                } catch (e) {
                    this.logger.error('error during custom runtime update', runtime.name, e);
                    if (toRun.onError) {
                        toRun.onError(e);
                    }
                    toRun.finally();
                    break;
                }
            }
            try {
                runtime.applyQuickPostWorkUpdates({bim: this.bim});
            } catch (e) {
                this.logger.error('error during custom runtime post work update', e);
            }
        }
    }

    getLazyExecutionStatusOf(runtimeTypeIdent: string): LazyVersioned<RuntimeSystemExecutionStatus> {

        const runtime = this.runtimes.find(r => r.name === runtimeTypeIdent);

        if (!runtime) {
            this.logger.error('could not find runtime with ident', runtimeTypeIdent);
            return new LazyBasic<RuntimeSystemExecutionStatus>(
                runtimeTypeIdent + '-status',
                RuntimeSystemExecutionStatus.Disabled,
            );
        }

        return LazyDerived.new0(
            runtimeTypeIdent + '-status',
            [runtime],
            () => {
                return runtime.executionStatus();
            }
        );
    }

    solversUiBindings(): RuntimeSystemUiDescription[] {
        return IterUtils.mapIter(this.runtimes.values(), runtime => {
            return {
                name: runtime.name,
                group_sort_key: runtime.executionOrder.toString(),
                executionStatus: LazyDerived.new0(
                    '',
                    [runtime],
                    (): RuntimeSystemExecutionStatus => {
                        return runtime.executionStatus();
                    }
                ),
                ui: runtime.ui,
            }
        });
    }

    getExecutionTimes(): SolversExecutionMetrics[] {
        const res: SolversExecutionMetrics[] = [];
        for (const [ident, time] of this.mainThreadExecutionTimePerRuntime.entries()) {
            res.push({
                ident,
                time,
                count: 0,
            });
        }
        return res;
    }
}
