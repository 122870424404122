import type { Box3, Camera, TypedArray } from './3rdParty/three';
import { PerspectiveCamera, OrthographicCamera } from './3rdParty/three';
import type { RectPortion } from './structs/RectPortion';
import type { Matrix3, Ray, Spherical} from 'math-ts';
import { Aabb, Vector3, Matrix4, DEG2RAD } from 'math-ts';

export class MathUtils {

	isCameraInProjectionTransition(camera: Camera): boolean {
		const matrix = camera.projectionMatrix;
		const isInTransition = matrix.elements[15] !== 0 && matrix.elements[15] !== 1;
		return isInTransition;
	}

	static unpackFloat(offset: number, scale: number, valueNormalized: number): number {
		return valueNormalized * scale + offset;
	}

	static getArrayMinMax(numbers: number[] | TypedArray): { min: number, max: number }{
		let min = Infinity;
		let max = -Infinity;
		for (const n of numbers) {
			if (n < min) {
				min = n;
			}
			if (n > max) {
				max = n;
			}
		}
		return { min, max };
	}

	static unpackVec3InPlace(offset: Vector3, scale: number, value: Vector3): Vector3 {
		value.x = value.x * scale + offset.x;
		value.y = value.y * scale + offset.y;
		value.z = value.z * scale + offset.z;
		return value;
	}

	static setRotMatToMatrix4(rot: Matrix3, matrix4: Matrix4): Matrix4 {
		const m3 = rot.elements;
		const m4 = matrix4.elements;
		m4[0] = m3[0];
		m4[1] = m3[1];
		m4[2] = m3[2];
		m4[4] = m3[3];
		m4[5] = m3[4];
		m4[6] = m3[5];
		m4[8] = m3[6];
		m4[9] = m3[7];
		m4[10] = m3[8];

		return matrix4;
	}

	static setPosToMatrix(pos: Vector3, matrix4: Matrix4): Matrix4 {
		const m4 = matrix4.elements;
		m4[12] = pos.x;
		m4[13] = pos.y;
		m4[14] = pos.z;
		return matrix4;
	}

	static addPosToMatrix(pos: Vector3, matrix4: Matrix4) {
		const m4 = matrix4.elements;
		m4[12] += pos.x;
		m4[13] += pos.y;
		m4[14] += pos.z;
	}

	static subPosFromMatrix(pos: Vector3, matrix4: Matrix4) {
		const m4 = matrix4.elements;
		m4[12] -= pos.x;
		m4[13] -= pos.y;
		m4[14] -= pos.z;
	}

	static box3ToKrBox3(threeBox: Box3): Aabb {
		const krBox = new Aabb(
			threeBox.min.x, threeBox.min.y, threeBox.min.z,
			threeBox.max.x, threeBox.max.y, threeBox.max.z
		);
		return krBox;
	}

	static getTriangleNormal_t(a:Vector3, b:Vector3, c:Vector3) {

		const cb = Vector3.subVectors( c, b );
		const ab = Vector3.subVectors( a, b );
		const result = Vector3.crossVectors(cb, ab);

		const targetLengthSq = result.lengthSq();
		if ( targetLengthSq > 0 ) {

			return result.multiplyScalar( 1 / Math.sqrt( targetLengthSq ) );

		}

		return result.set( 0, 0, 0 );
	}
	
	// Closest point between two rays http://morroworks.com
	// http://morroworks.palitri.com/Content/Docs/Rays%20closest%20point.pdf
	static closestPointsOnTwoRays(ray1: Ray, ray2: Ray): { distance1: number, distance2: number } | null {
		
		const c_t = Vector3.subVectors(ray1.origin, ray2.origin);

		const p = ray1.direction.dot(ray2.direction);
		const q = ray1.direction.dot(c_t);
		const r = ray2.direction.dot(c_t);
		const s = ray1.direction.dot(ray1.direction);
		const t = ray2.direction.dot(ray2.direction);

		if (s === 0 || t === 0 || s * t === p * p) {
			return null;
		}

		const d = (-p * r + q * t) / (s * t - p * p);
		const e = (-p * r + q * t) / (s * t - p * p);

		return { distance1: d, distance2: e };
	}
	
	static sphericalsDistance(s1: Spherical, s2: Spherical) {
		const v1 = Vector3.zero();
		const v2 = Vector3.zero();
		v1.setFromSphericalCoords(s1.radius, s1.phi, s1.theta);
		v2.setFromSphericalCoords(s2.radius, s2.phi, s2.theta);
		const dist = v1.distanceTo(v2);
		return dist;
	}

	
	static unproject = function () {

		var matrix = new Matrix4();

		return function unproject(v:Vector3, camera:Camera ): Vector3 {

			return v.clone().applyMatrix4(matrix.getInverse(camera.projectionMatrix) as any)
				.applyMatrix4(camera.matrixWorld as any);

		};

	}()

	static makeSubFrustumProjectionMatrix(camera: Camera, frustumPart: RectPortion): Matrix4 {
		
		if (camera instanceof PerspectiveCamera) {

			let top = camera.near * Math.tan(DEG2RAD * 0.5 * camera.fov) / camera.zoom;
			let height = 2 * top;
			let width = camera.aspect * height;
			let left = - 0.5 * width;
		
			top -= frustumPart.start.y * height;
			left += (frustumPart.start.x) * width;
			
			width *= frustumPart.size.x;
			height *= frustumPart.size.y;
		
			return new Matrix4().makePerspective(left, left + width, top, top - height, camera.near, camera.far);

		} else if (camera instanceof OrthographicCamera) {

			const dx = ( camera.right - camera.left ) / ( 2 * camera.zoom );
			const dy = ( camera.top - camera.bottom ) / ( 2 * camera.zoom );
			const cx = ( camera.right + camera.left ) / 2;
			const cy = ( camera.top + camera.bottom ) / 2;

			let left = cx - dx;
			let right = cx + dx;
			let top = cy + dy;
			let bottom = cy - dy;

			const height = top - bottom;
			const width = right - left;

			top -= frustumPart.start.y * height;
			left += (frustumPart.start.x) * width;

			right = left + width * frustumPart.size.x;
			bottom = top - height * frustumPart.size.y;

			return new Matrix4().makeOrthographic( left, right, top, bottom, camera.near, camera.far );
		} else {
			throw new Error('unknown camera type');
		}

	}
}
