<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M7.99999 8.49998C7.35555 8.49998 6.81388 8.28054 6.37499 7.84165C5.9361 7.40276 5.71666 6.86109 5.71666 6.21665C5.71666 5.57221 5.9361 5.03054 6.37499 4.59165C6.81388 4.15276 7.35555 3.93332 7.99999 3.93332C8.64443 3.93332 9.1861 4.15276 9.62499 4.59165C10.0639 5.03054 10.2833 5.57221 10.2833 6.21665C10.2833 6.86109 10.0639 7.40276 9.62499 7.84165C9.1861 8.28054 8.64443 8.49998 7.99999 8.49998ZM7.99999 7.49998C8.37777 7.49998 8.6861 7.38054 8.92499 7.14165C9.16388 6.90276 9.28332 6.59443 9.28332 6.21665C9.28332 5.83887 9.16388 5.53054 8.92499 5.29165C8.6861 5.05276 8.37777 4.93332 7.99999 4.93332C7.62221 4.93332 7.31388 5.05276 7.07499 5.29165C6.8361 5.53054 6.71666 5.83887 6.71666 6.21665C6.71666 6.59443 6.8361 6.90276 7.07499 7.14165C7.31388 7.38054 7.62221 7.49998 7.99999 7.49998ZM7.99999 14.65C6.44443 14.2611 5.16666 13.3583 4.16666 11.9416C3.16666 10.525 2.66666 8.97221 2.66666 7.28332V3.31665L7.99999 1.31665L13.3333 3.31665V7.28332C13.3333 8.97221 12.8333 10.525 11.8333 11.9416C10.8333 13.3583 9.55555 14.2611 7.99999 14.65ZM7.99999 2.38332L3.66666 4.01665V7.28332C3.66666 7.98332 3.76388 8.65276 3.95832 9.29165C4.15277 9.93054 4.42777 10.5222 4.78332 11.0666C5.29443 10.7889 5.81388 10.5805 6.34166 10.4416C6.86943 10.3028 7.42221 10.2333 7.99999 10.2333C8.57777 10.2333 9.13055 10.3028 9.65832 10.4416C10.1861 10.5805 10.7055 10.7889 11.2167 11.0666C11.5722 10.5222 11.8472 9.93054 12.0417 9.29165C12.2361 8.65276 12.3333 7.98332 12.3333 7.28332V4.01665L7.99999 2.38332ZM7.99999 11.2333C7.56666 11.2333 7.13332 11.2889 6.69999 11.4C6.26666 11.5111 5.83888 11.6778 5.41666 11.9C5.77221 12.2889 6.16666 12.6305 6.59999 12.925C7.03332 13.2194 7.49999 13.45 7.99999 13.6166C8.49999 13.45 8.96666 13.2194 9.39999 12.925C9.83332 12.6305 10.2278 12.2889 10.5833 11.9C10.1611 11.6778 9.73332 11.5111 9.29999 11.4C8.86666 11.2889 8.43332 11.2333 7.99999 11.2333Z"
        fill="currentColor"
    />
</svg>
