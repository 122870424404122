import { Vector2, Vector4 } from 'math-ts';
import { DoubleSide } from '../3rdParty/three';

import type { ShaderBase } from '../shaders/ShaderBase';
import { ShaderFlags } from '../shaders/ShaderFlags';

export const TerrainShaderMaxSlices = 10;

export const TerrainegularShader: ShaderBase = Object.freeze({
	
	name: 'terrain',

	params: {
		transparent: false,
		side: DoubleSide,
	},

    defines: {
		'FLAT_NORMALS': true,
		'NEEDS_NORMALS': true,
		'NEEDS_VIEW_SPACE': true,
    },

	flags: ShaderFlags.MRT_NORMALS,
    
	uniforms: {
		heatmapInCm: { value: null },
		heightmap: { value: null },
		heightOffsetMultiplier: { value: new Vector2() },
		visibility: { value: null },

		paletteColors: {value: Array(TerrainShaderMaxSlices).fill(0).map((_, index) => new Vector4().setScalar(index / 10))},
		paletteRanges: {value: Array(TerrainShaderMaxSlices).fill(0).map((_, index) => new Vector2().setScalar(0))},
		angleToVectorAndSource: {value: new Vector4(0, 1, 0, 0)},

		gridStep: {value: new Vector2(1, 1)},
		// lowColorValue: {}
	}
	,
	vertexShader:
	`

	#include <kr_std_attributes>
	#include <kr_std_uniforms>
	#include <kr_std_vars>

	#include <common>
	
	uniform sampler2D heightmap;
	uniform vec2 heightOffsetMultiplier;

	varying vec3 vWorldPosRelative;

	void main() {

		#include <kr_std_uvs_vertex>

		vec4 localPosition = vec4( position, 1.0 );

		float elevation = heightOffsetMultiplier.x + texture2D( heightmap, vUv ).r * heightOffsetMultiplier.y;
		localPosition.z = elevation;

		mat4 worldMatrix = transforms[gl_InstanceID + instanceOffset].matrix;
		vWorldPosition = worldMatrix * localPosition;

		gl_Position = viewProjMatrix * vWorldPosition;

		colorTint = transforms[gl_InstanceID + instanceOffset].colorTint;

		vWorldPosRelative = vWorldPosition.xyz;

		if (isOrthographic == false) {
			vWorldPosRelative -= cameraPosition;
		}

		vWorldPosRelative *= 100.0;
	}
	`,

	fragmentShader:
	
	`

	#include <common>
	#include <packing>

	#include <kr_std_uniforms>
	#include <kr_std_vars>

	#include <normals_packing>

	// uniform sampler2D heightmap;
	// uniform vec2 heightOffsetMultiplier;

	uniform sampler2D heatmapInCm;

	#include <visibility_texture_pars_fragment>

	varying vec3 vWorldPosRelative;

	uniform vec4[${TerrainShaderMaxSlices}] paletteColors; 
	uniform vec2[${TerrainShaderMaxSlices}] paletteRanges; 
	uniform vec4 angleToVectorAndSource;

	uniform vec2 gridStep;


	#if defined(MRT_NORMALS)
		layout(location = 1) out lowp vec4 fragData1;
	#endif

	float getColorInterpValueFromAngle() {

		// float elevation = heightOffsetMultiplier.x + texture2D( heightmap, vUv ).r * heightOffsetMultiplier.y;
		// elevation = elevation * 100.0;
		// vec3 vPos = vec3(vWorldPosRelative.xy, elevation);
		vec3 vPos = vWorldPosRelative;

		vec3 dFdxPos = dFdx( vPos );
		vec3 dFdyPos = dFdy( vPos );
		vec3 faceNormal = normalize( cross(dFdxPos,dFdyPos ));

		float faceDirection = gl_FrontFacing ? 1.0 : - 1.0;
		faceNormal.xyz *= faceDirection;

		float d = dot(faceNormal, angleToVectorAndSource.xyz);

		float angle = acos(d) - PI * 0.5;

		return angle;
	}

	float getColorInterpValueFromHeatmap(vec3 worldPos) {
		float heatmapValue = texture2D( heatmapInCm, vUv ).r;
		return heatmapValue * 0.01;
	}


	float checkersTextureGradBox( in vec2 p, in vec2 ddx, in vec2 ddy )
	{
		// filter kernel
		vec2 w = max(abs(ddx), abs(ddy)) + 0.01;  
		// analytical integral (box filter)
		vec2 i = 2.0*(abs(fract((p-0.5*w)/2.0)-0.5)-abs(fract((p+0.5*w)/2.0)-0.5))/w;
		// xor pattern
		return 0.5 - 0.5*i.x*i.y;                  
	}

	#include <sample_grid_fn>

	void main() {
		#include <kr_std_pixel>

		#include <visibility_texture_fragment>

		float colorSource = angleToVectorAndSource.w;

		float valueToCheck = vWorldPosition.z;
		if (colorSource == 2.) {
			valueToCheck = getColorInterpValueFromAngle();
		}
		if (colorSource == 3.) {
			valueToCheck = getColorInterpValueFromHeatmap(vWorldPosition.xyz);
		}

		int resultColorIndex = -1;

		#pragma unroll_loop_start
		for (int i = 0; i < ${TerrainShaderMaxSlices}; ++i) {
			vec2 range = paletteRanges[i];
			if (clamp(valueToCheck, range.x, range.y) == valueToCheck) {
				resultColorIndex = i;
			}
		}
		#pragma unroll_loop_end

		vec4 resultColor;
		if (resultColorIndex >= 0) {
			resultColor = paletteColors[resultColorIndex];
		} else {
			vec2 checkersInput = vWorldPosition.xy * 0.1;
			float checkers = checkersTextureGradBox(checkersInput.xy, dFdx( checkersInput.xy ), dFdy(checkersInput.xy)) * 0.6 + 0.4;
			resultColor = vec4(checkers);
		}

		resultColor.w = 1.0;

		// float gridPower = sampleGrid(vWorldPosition.xy, gridStep.xy, 0.01);
		// gridPower /= length(fwidth(vWorldPosition.xy)) * 2.0;
		// resultColor.rgb = mix(resultColor.rgb, vec3(0.95), gridPower);

		gl_FragColor = resultColor;
		
		// for tiles borders visualization
		// if (vUv.x < 0.01 || vUv.y < 0.01 || vUv.x > 0.99 || vUv.y > 0.99) {
		//     gl_FragColor.xyz = vec3(0.0);
		// }

		#ifdef MRT_NORMALS 
			fragData1 = vec4( encode_normal( normal ),  1.0, 1.0 );
		#endif

	}
	`,
});

