import { isInNode, IsInWebworker } from "./EnvChecks";


const PathMappingsNodeId = 'paths-mappings.json';

export function findMappedPathFor(originalAssetPath: string): string {
    if (IsInWebworker() || isInNode()) {
        return originalAssetPath;
    }
    const mappingsNode = document.getElementById(PathMappingsNodeId);
    if (!mappingsNode) {
        console.error(`could not load ${PathMappingsNodeId} node, that should have been added by app root builder`);
        return originalAssetPath;
    }
    if (!mappingsNode.textContent) {
        console.error(`could not load ${PathMappingsNodeId} node, empty text contentr`);
        return originalAssetPath;
    }
    const jsonParsed = JSON.parse(mappingsNode.textContent);
    const mapped = jsonParsed[originalAssetPath];
    if (mapped) {
        return mapped;
    } else {
        console.error(`mapped path for ${originalAssetPath} not found`);
        return originalAssetPath;
    }
}
