<script lang="ts">
    import { RGBA, type RGBAHex } from "engine-utils-ts";

    export let value: RGBAHex | 0;
    export let onChangeColor: (color: RGBAHex) => void;
    export let disabled: boolean = false;

    let wrapper: HTMLLabelElement;
    const defaultColor = "#000000";
</script>

<div class="holder order-items">
    <label
        class="color-wrapper order-items"
        class:active={!disabled}
        bind:this={wrapper}
        style="--input-color: {value
            ? RGBA.toHexRgbString(value)
            : defaultColor}; "
    >
        <input
            class="color-input-field"
            type="color"
            {disabled}
            value={value ? RGBA.toHexRgbString(value) : defaultColor}
            on:change={(e) => {
                const target = e.target;
                if (target instanceof HTMLInputElement) {
                    wrapper.style.backgroundColor = target.value;
                    onChangeColor(RGBA.parseFromHexString(target.value));
                }
            }}
        />
    </label>
</div>

<style lang="scss">
    .order-items {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .holder {
        margin: 1px;
        padding: 1px;
        width: 10px;
        height: 10px;
        border-radius: 2px;
        border: 1px solid;
        border-color: var(--dark, #101314);
    }

    .color-wrapper {
        width: 100%;
        height: 100%;
        background-color: var(--input-color);
        &.active {
            cursor: pointer;
        }
    }

    .color-input-field {
        width: 100%;
        height: 100%;
        padding: 0;
        border: none;
        visibility: hidden;
        position: relative;
    }
</style>
