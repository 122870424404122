import type { ProjectNetworkClient } from 'engine-utils-ts';
import { VerdataUtils } from './VerdataUtils';
import type { ProjectHistory} from './WireProjectHistory';
import { ProjectHistorySerializer } from './WireProjectHistory';

export class VerdataApi {
    static async getProjectHistory(networkClient: ProjectNetworkClient): Promise<ProjectHistory> {
        const historyFetch = networkClient.get('history');

        const history = VerdataUtils.deserializeAsync<ProjectHistory>(
            new ProjectHistorySerializer(),
            VerdataUtils.bytesFromResponse(historyFetch)
        );
        return history;
    }
}
