<script lang="ts">
    import type {
        MultiSelectorValue,
        PUI_PropertyNodeSelector,
    } from "ui-bindings";
    import SelectorPropertyBase from "./SelectorPropertyBase.svelte";
    import { ObjectUtils } from "engine-utils-ts";
    import flash from "./flash";
    import { VersionedStore } from "../VersionedStore";
    import { SegmentedButtons } from "../libui/segmented-buttons";

    export let property: PUI_PropertyNodeSelector;

    let div: HTMLElement;
    let preValue = property.value;

    $: if (!ObjectUtils.areObjectsEqual(preValue, property.value)) {
        preValue = property.value;
        flash(div);
    }

    $: options = property.options as MultiSelectorValue[];
    $: value = property.value !== null ? [property.value] : [];
    const tag = property.tag ? new VersionedStore(property.tag) : undefined;
</script>

<div
    class="ui-config-property property-row multiselect-prop"
    class:not-active={property.notActive}
>
    <div bind:this={div} class="property-name text-main-medium">
        <div class="property-name-label mdc-typography--body1">
            {property.name}
        </div>
    </div>
    {#if !property.showAsButtons}
        <SelectorPropertyBase
            {value}
            {options}
            placeholder={`Select ${property.name?.toLowerCase()}`}
            readonly={property.readonly}
            calculated={property.calculated}
            description={property.description}
            tag={tag ? $tag : ""}
            on:change={(e) => {
                property.value = e.detail && e.detail.value;
            }}
        />
    {:else if property.options.length > 0}
        {@const selectedOption = property.options.find(
            (o) => o.value === property.value,
        )}
        <div class="property-value">
            <div class="edit-field-container number-with-options-property">
                <SegmentedButtons
                    options={property.options}
                    selected={selectedOption || property.options[0]}
                    on:change={(e) => {
                        property.value = e.detail?.option.value;
                    }}
                >
                    <span slot="button" let:option>
                        {option.label || option.value}
                    </span>
                </SegmentedButtons>
            </div>
        </div>
    {/if}
</div>

<style>
</style>
