import { AreaTypeEnum, Bim, ColorProperty, CutFillConfig, CutFillConfigType, getTrackerModel, IdBimScene, IdPile, MetricsGroup, NumberProperty, NumberRangeProperty, PileBinsConfig, ProjectMetricsType, RoadTypeIdent, SubstationTypeIdent, TrackerPilesCollection } from "bim-ts";
import { DefaultMap, IterUtils, RGBA, RGBAHex, ScopedLogger, Yield } from "engine-utils-ts";
import jsPDF from "jspdf";
import { convertPxToMm, createPalette, EquipmentDefaultColor, RoadColor, SolarArraysTypes, SubstationColor } from "../PdfCommon";
import { PageFrameOffsets, PdfElement, PdfReportContext } from "../PdfReportBuilder";
import { setTextStyle, TextBuilder } from "../TextBuilder";
import { drawLayoutOnPageByTemplate1 } from "./OverveiwPage";
import { drawLine, printTableHeader } from "./BlocksSchedulePage";
import { Vector2, Aabb2 } from "math-ts";
import { addLatoFonts } from "../fonts/fonts";
import { AnnotationBuilder } from "../AnnotationsBuilder";

interface PilesByRevealTableHeader {
    description: string | (string | null)[];
    widthPx: number,
    key: keyof PilesByRevealProps;
    unit?: string;
    title?: string;
    convertUnit?: string;
}

interface PilesByRevealProps {
    color: ColorProperty;
    range: NumberRangeProperty;
    piles: NumberProperty;
    pilesShare: NumberProperty;
    arrays: NumberProperty;
    arraysShare: NumberProperty;
    lengthAvg: NumberProperty;
    arrayIds: Set<IdBimScene>;
}

export class PilesByRevealTable extends PdfElement {
    private readonly _rowHeightPx = 22;
    private readonly _headerHeightPx = 46;

    constructor(
        readonly bim: Bim,
        readonly trackerBins: TrackerBinsTable,
        readonly metrics: MetricsGroup<Partial<ProjectMetricsType>>[],
        readonly logger: ScopedLogger,
    ) {
        super();
    }

    *draw({page, pageFrameOffsets}: PdfReportContext): Generator<Yield, void, unknown> {
        yield Yield.Asap;

        const textBuilder = this.drawTargets(page, pageFrameOffsets);

        this.drawPilesByRevealTable(page, textBuilder);
    }

    private drawTargets(page: jsPDF, frame: PageFrameOffsets){
        const totalMetrics = this.metrics.find((x) => x.type === AreaTypeEnum.Total);

        const textBuilder = new TextBuilder(
            this.bim.unitsMapper,
            page, 
            frame.upOffsetPx, 
            frame.leftRightOffsetPx,
            totalMetrics?.metrics ?? null,
            this.logger,
            convertPxToMm
        );

        const config = this.bim.configs.peekSingleton(CutFillConfigType)?.get<CutFillConfig>();
        if(!config){
            this.logger.error("Terrain analysis config not found");
            return textBuilder;
        }
        textBuilder.addRow({text: "Targets", headerLevel: 1, values: [], style: 'bold'});
        const gradientWindow = NumberRangeProperty.new({
            value: [
                config.cut_fill_limits.tolerance.value,
                config.cut_fill_limits.tolerance.value * 2,
            ],
            unit: config.cut_fill_limits.tolerance.unit,
        });
        textBuilder.addOffset();
        textBuilder.addFromNumberRangeProp("Gradient window", gradientWindow);
        textBuilder.addOffset();
        textBuilder.addOffset();
        textBuilder.addOffset();
        function formatValuePercent(value: number, postfix: string){
            return {value: `${value.toFixed(2)}`, unit: `%, ${postfix}`};
        }
        const postFix = "max";
        textBuilder.addRow({text: "Solar arrays slope along axis", values: [formatValuePercent(config.solar_arrays_limits.slope_along_axis.value, postFix)]});
        textBuilder.addRow({text: "Slope change, Bay to bay", values: [formatValuePercent(config.solar_arrays_limits.slope_change_bay_to_bay.value, postFix)]});
        textBuilder.addRow({text: "Slope change, Cumulative", values: [formatValuePercent(config.solar_arrays_limits.cumulative.value, postFix)]});
        textBuilder.addOffset();
        textBuilder.addOffset();
        textBuilder.addOffset();

        textBuilder.addRow({text: "Piles by reveal", headerLevel: 1, values: [], style: 'bold'});
        textBuilder.addRow({text: "* each array mentioned multiple times in different piles length groups", values: null, style: 'underline'});
        textBuilder.addOffset();

        return textBuilder;
    }

    private drawPilesByRevealTable(page: jsPDF, textBuilder: TextBuilder){
        const headers: PilesByRevealTableHeader[] = [
            {description: ["Reveal", null], widthPx: 40, key: 'color', title: `${this.bim.unitsMapper.mapToConfigured({value: 1, unit: 'm'}).unit}, from-to`, convertUnit: 'm'},
            {description: " ", widthPx: 90, key: 'range', convertUnit: 'm'},
            {description: ["Units", null], widthPx: 62, key: 'piles'},
            {description: ["/ Share", null], widthPx: 62, key: 'pilesShare', unit: "%", convertUnit: "%"},
            {description: ["Length", null], widthPx: 62, key: 'lengthAvg', unit: `${this.bim.unitsMapper.mapToConfigured({value: 1, unit: 'm'}).unit}, avg`, convertUnit: 'm'},
            {description: ["Arrays", null], widthPx: 62, key: 'arrays'},
            {description: ["/ Share", null], widthPx: 62, key: 'arraysShare', unit: "%", convertUnit: "%"},
        ];
        const fontSize = 10;
        const upOffsetPx = 10;
        const {contentRightOffsetPx} = printTableHeader(textBuilder.leftOffsetPx, textBuilder.offsetYPx  + upOffsetPx, fontSize, headers, page);
        
        let offsetYPx = textBuilder.offsetYPx + this._headerHeightPx + upOffsetPx;
        function drawRowText(row: PilesByRevealProps, rowHeightPx: number, isTotal?: boolean){
            const fontSize = 10;
            offsetYPx += rowHeightPx;
            const style = isTotal ? 'bold' : 'normal';
            setTextStyle(page, style, fontSize);
            const textHeight = page.getTextDimensions("1").h;
            const localOffsetY = convertPxToMm(offsetYPx) - (convertPxToMm(rowHeightPx) - textHeight) * 0.5
            let offsetXPx = textBuilder.leftOffsetPx;
            for (const header of headers) {
                const prop = row[header.key];
                offsetXPx += header.widthPx;
                let valueStr = '';
                if(prop instanceof NumberRangeProperty){
                    const digits = 2;
                    const unit = header.convertUnit ?? prop.unit;
                    const [value1, value2] = header.unit ? prop.as(unit) : prop.value;
                    const configured1 = textBuilder.unitsMapper.mapToConfigured({value: value1, unit: unit });
                    const configured2 = textBuilder.unitsMapper.mapToConfigured({value: value2, unit: unit });
                    const valueStr1 = configured1.value.toFixed(digits);
                    const valueStr2 = configured2.value.toFixed(digits);
                    valueStr = `${valueStr1}  —  ${valueStr2}`;
                } else if(prop instanceof NumberProperty){
                    const digits = header.convertUnit ? 2 : 0;
                    const value = header.convertUnit ? prop.as(header.convertUnit) : prop.value;
                    const configured = textBuilder.unitsMapper.mapToConfigured({value, unit: header.convertUnit });
                    valueStr = configured.value.toFixed(digits);
                } else if(prop instanceof ColorProperty){
                    const [r, g, b] = RGBA.toRgbArray(prop.value);
                    page.setFillColor(r, g, b);
                    const upBotOffsetPx = 5;
                    const height = convertPxToMm(rowHeightPx - upBotOffsetPx*2);
                    const width = convertPxToMm(header.widthPx-8);
                    const round = convertPxToMm(1);
                    const compensationY = (height - textHeight) * 0.5;
                    page.roundedRect(
                        convertPxToMm(offsetXPx - header.widthPx), 
                        localOffsetY - (height) + compensationY, 
                        width, 
                        height,
                        round,
                        round,
                        'F'
                    );
                    
                } else if(prop === null){
                    valueStr = '—';
                }
                
                const textWidth = page.getTextWidth(valueStr);
                page.text(valueStr, convertPxToMm(offsetXPx - contentRightOffsetPx) - textWidth, localOffsetY);
            }
        }
        function drawGroupText(text: string, rowHeightPx: number){
            const fontSize = 10;
            offsetYPx += rowHeightPx;
            const style = 'bold';
            setTextStyle(page, style, fontSize);
            const textHeight = page.getTextDimensions("1").h;
            const localOffsetY = convertPxToMm(offsetYPx) - (convertPxToMm(rowHeightPx) - textHeight) * 0.5
            let offsetXPx = textBuilder.leftOffsetPx;
           
            page.text(text, convertPxToMm(offsetXPx), localOffsetY);
        }
        const lineLengthPx = IterUtils.sum(headers, x => x.widthPx);

        for (const bin of this.trackerBins.bins) {
            const min = bin.rows[0].range;
            const max = bin.rows[bin.rows.length - 1].range;
            const minReveal = this.bim.unitsMapper.mapToConfigured({value: min.value[0], unit: min.unit });
            const maxReveal = this.bim.unitsMapper.mapToConfigured({value: max.value[1], unit: max.unit });
            const text = bin.isBin ? `${minReveal.value.toFixed(2)} - ${maxReveal.value.toFixed(2)} ${minReveal.unit} bin` : 'No bin';
            drawGroupText(text, 14);
            drawLine(page, textBuilder.leftOffsetPx, offsetYPx, lineLengthPx);
            for (const row of bin.rows) {
                drawLine(page, textBuilder.leftOffsetPx, offsetYPx, lineLengthPx);
                drawRowText(row, this._rowHeightPx);
            }
        }

        return offsetYPx;
    }

    calculateContentSize(pageSize: Vector2, {pageFrameOffsets}: Readonly<PdfReportContext>): Aabb2 {
        const page = new jsPDF({unit: 'mm'});
        addLatoFonts(page);
        const builder = this.drawTargets(page, pageFrameOffsets);
        const offsetYPx = this.drawPilesByRevealTable(page, builder);
        return Aabb2.empty().setFromPoints([
            new Vector2(0, 0),
            new Vector2(pageSize.x, convertPxToMm(offsetYPx + pageFrameOffsets.downOffsetPx + 10))
        ]);
    }
}

export interface TrackerBinsTable {
    arrayType: string;
    trackerPerMaxReveal: Map<IdBimScene, number>;
    bins: {isBin: boolean, rows:PilesByRevealProps[]}[];
}

export function createPilesBinsTableRows(bim: Bim, pilesCollection: TrackerPilesCollection, logger: ScopedLogger): TrackerBinsTable[] {
    const binConfig = bim.configs.peekSingleton(PileBinsConfig.name)?.propsAs(PileBinsConfig);
    const groups: TrackerBinsTable[] = [];
    const trackerGroups = new DefaultMap<string, Map<IdBimScene, number>>(() => new Map());
    const arrayInstances = bim.instances.peekByTypeIdents(SolarArraysTypes);
    for (const [id, inst] of arrayInstances) {
        const name = getTrackerModel(inst);
        if(!name){
            continue;
        }
        const maxReveal = extractPileMaxRevealMeter(id, pilesCollection);
        if(maxReveal === 0){
            console.info("maxReveal", id, inst);
        }
        trackerGroups.getOrCreate(name).set(id, maxReveal);
    }

    if(binConfig?.trackerBins) {
        const processedPiles = new Set<IdPile>();
        for (const [trackerType, group] of trackerGroups) {
            const trackerBins = binConfig.trackerBins.find(b => b.tracker.value === trackerType);
            const trackerRows: {isBin: boolean, rows: PilesByRevealProps[]}[] = [];
            if(!trackerBins){
                logger.debug(`Tracker group not found: ${trackerType}`);
            }
            const maxReveal = IterUtils.max(group.values()) ?? 0;
            const bins = trackerBins?.bins ?? [{maxReveal: NumberProperty.new({value: Infinity, unit: 'm'})}];
            const haveBins = trackerBins?.bins && trackerBins.bins.length > 1;
            const rowsCount = haveBins ? 4 : 12;
            let prevReveal: number | undefined = IterUtils.min(group.values());
            for (let i = 0; i < bins.length; i++) {
                const bin = bins[i];
                const isBin = isFinite(bin.maxReveal.as("m"));
                const reveal = isBin ? bin.maxReveal.as("m") : maxReveal;
                const nextReveal = prevReveal != undefined ? Math.max(reveal, prevReveal) : reveal;
                const rows = createBinRows([prevReveal, nextReveal], rowsCount, pilesCollection, group, processedPiles);
                trackerRows.push({rows, isBin});
                prevReveal = reveal;
            }

            groups.push({
                trackerPerMaxReveal: group,
                arrayType: trackerType,
                bins: trackerRows,
            });
        }
    }

    for (const group of groups) {
        const paletteSize = IterUtils.sum(group.bins, b => b.rows.length);
        const colorPalette = createPalette(paletteSize);
        for (const bin of group.bins) {
            for (const row of bin.rows) {
                const color = colorPalette.getNext(row.range.hash())
                row.color = ColorProperty.new({value: color});
            }
        }
    }

    return groups;
}

function createBinRows(
    range: [number | undefined, number], 
    maxRowCount: number, 
    pileCollection: TrackerPilesCollection, 
    group: Map<IdBimScene, number>,
    processedPiles: Set<IdPile>
){
    const allPiles = pileCollection.poll();
    const min = range[0] ?? 0;
    const max = range[1];

    const allSteps = IterUtils.newArray(maxRowCount, (i) => (max - min) / (i + 1));
    const rowCount = Math.max(IterUtils.findIndexBackToFront(allSteps, s => s > 1e-2) + 1, 1);
    const step = (max - min) / rowCount;
    const rows: PilesByRevealProps[] = [];
    for (let i = 0; i < rowCount; i++) {
        const minReveal = min + step * i;
        const maxReveal = min + step * (i + 1);
        let pilesCount = 0;
        const parentIds = new Set<IdBimScene>();
        let lengthSum = 0;
        for (const [pileId, pile] of allPiles) {
            const isRevealInRange = pile.reveal >= minReveal && (i === rowCount - 1 ? pile.reveal <= maxReveal : pile.reveal < maxReveal);
            if(group.has(pile.parentId) && isRevealInRange && !processedPiles.has(pileId)){
                pilesCount++;
                lengthSum += pile.length;
                parentIds.add(pile.parentId);
                processedPiles.add(pileId);
            }
        }
        
        rows.push({
            arrayIds: parentIds,
            color: ColorProperty.new({value: RGBA.newRGB(0, 0, 0)}),
            range: NumberRangeProperty.new({value: [minReveal, maxReveal], unit: "m"}),
            piles: NumberProperty.new({value: pilesCount}),
            pilesShare: NumberProperty.new({value: pilesCount ? pilesCount / allPiles.size * 100 : 0, unit: "%"}),
            arrays: NumberProperty.new({value: parentIds.size}),
            arraysShare: NumberProperty.new({value: parentIds.size / pileCollection.pilesPerTrackerId.size() * 100, unit: "%"}),
            lengthAvg: NumberProperty.new({value: lengthSum ? lengthSum / pilesCount : 0, unit: "m"}),
        });
    }

    return rows;
}


export class ScreenshotByPileLength extends PdfElement {
    constructor(
        readonly bim: Bim,
        readonly trackerBins: TrackerBinsTable,
        readonly logger: ScopedLogger,
    ){
        super();
    }

    *draw({page, layoutDrawing, pageFrameOffsets}: PdfReportContext) {
        const types = [
            RoadTypeIdent,
            SubstationTypeIdent,
            ...SolarArraysTypes
        ];

        const instances = this.bim.instances.peekByTypeIdents(types);
        const colorPerId = new Map<IdBimScene, RGBAHex>();
        for (const bin of this.trackerBins.bins) {
            for (const row of bin.rows) {
                for (const id of row.arrayIds) {
                    colorPerId.set(id, row.color.value);
                }
            }
        }

        yield * drawLayoutOnPageByTemplate1({
            bim: this.bim,
            page,
            header: "Solar arrays map by the longest pile in array",
            ids: instances.map(([id]) => id),
            layoutDrawing,
            offsets: pageFrameOffsets,
            colorize: (id) => {
                if (
                    this.bim.instances.peekTypeIdentOf(id) === RoadTypeIdent
                ) {
                    return { borderColor: RoadColor };
                } else if (
                    this.bim.instances.peekTypeIdentOf(id) ===
                    SubstationTypeIdent
                ) {
                    return { borderColor: SubstationColor };
                } else {
                    const arrayColor = colorPerId.get(id);
                    return arrayColor
                        ? { borderColor: arrayColor }
                        : { borderColor: EquipmentDefaultColor };
                }
            },
            grid: true,
        });

        const grid = layoutDrawing.getLastCalculatedGrid();

        if(grid){
            const annotations = new AnnotationBuilder(page, pageFrameOffsets);
            annotations.addGrid({
                text: `${grid.step} ${grid.unit}`,
                width: grid.widthLocalSystem,
            });
        }
    }
}

function extractPileMaxRevealMeter(id: IdBimScene, pilesCollection: TrackerPilesCollection): number {
    let reveal: number = 0;
    const piles = pilesCollection.poll();
    for (const pileId of pilesCollection.pilesPerTrackerId.iter(id)) {
        const pile = piles.get(pileId);
        if(!pile){
            continue;
        }
        reveal = Math.max(reveal, pile.reveal);
    }

    return reveal;
}

