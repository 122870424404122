import type { LazyVersioned } from 'engine-utils-ts';
import { LazyDerived } from 'engine-utils-ts';
import { Plane, Vector3 } from 'math-ts';
import { MouseRayCone } from './BoundsSceneWrap';

import type { ClipBox } from '../clipbox/ClipBox';
import type { GesturesMousePos } from '../controls/MouseGesturesBase';
import type { KrCamera } from '../controls/MovementControls';
import type { ClipBoxGizmo } from '../gizmos/ClipboxGizmo';
import type { GizmoIntersection } from '../gizmos/GizmoIntersection';
import type { TransformGizmo } from '../gizmos/TransformGizmo';
import type {
    BimObjectSceneIntersection, EditGizmoIntersection, InteractiveObjectIntersection
} from './Raycasts';

import type { RaySection } from '../structs/RaySection';
import type { TotalBounds } from '../TotalBounds';
import type { EngineScene } from './EngineScene';
import type { InteractiveObjectsActive } from './InteractiveSceneObjects';

export class SceneRaycaster {

    // private _submeshesRaycaster: SubmeshesRaycaster;
    // private _bimGizmosRaycaster: SceneGizmosRaycaster;
    // private _editGizmosRaycaster: SceneGizmosRaycaster;

    readonly mouseCone: LazyDerived<MouseRayCone | null>;
   	readonly interactiveObjectsIntersection: LazyDerived<InteractiveObjectIntersection | null>;

    readonly sceneIntersection: LazyDerived<SceneIntersections>;

    readonly _totalBounds: TotalBounds;
    readonly _clipBox: ClipBox;
    readonly _gizmos: [TransformGizmo, ClipBoxGizmo];
    readonly _groundPlane: Plane = new Plane(Vector3.allocate(0, 0, 1), 0);

    constructor(
        mousePos: LazyVersioned<GesturesMousePos>,
        camera: LazyVersioned<KrCamera>,
        engineScene: EngineScene,
        gizmos: [TransformGizmo, ClipBoxGizmo],
		totalBounds: TotalBounds,
		clipbox: ClipBox,
		interactiveObjects: InteractiveObjectsActive
    ) {
        this._totalBounds = totalBounds;
        this._clipBox = clipbox;

        this._gizmos = gizmos;

        this.mouseCone = LazyDerived.new2<MouseRayCone | null, GesturesMousePos, KrCamera>(
            'mouseRay', null,
            [mousePos, camera],
            ([mousePos, camera]) => {
				return MouseRayCone.newForInCameraPos(camera, mousePos);
            },
        );

		this.interactiveObjectsIntersection = LazyDerived.new2(
			'interactive-objects-raycast',
			[interactiveObjects.repr_invalidator],
			[camera, mousePos],
			([camera, mousePos]) => {
				return interactiveObjects.intersectRay(camera, mousePos);
			}
		)

        this.sceneIntersection = LazyDerived.new1(
            'scene intersections',
            [this.mouseCone],
            [this.interactiveObjectsIntersection],
            ([std]) => {
                const ray = this.mouseCone.poll()?.raySection ?? null;
                const resultInt = new SceneIntersections(
                    std,
                    this._groundIntersection(ray),
                    ray && this._raycastGizmos(ray), // TODOray ? this._gi
                );
                return resultInt;
            },
        );
    }

    clear() {
		// this._groundPlane.constant = 0;
    }

    _groundIntersection(r: RaySection | null): GroundIntersection | null {
        if (!r) {
            return null;
        }
        const ray = r.ray;
        let groundInters : Vector3 | null = null;
        const totalBounds = this._totalBounds.bounds;

        if (ray.origin.z > totalBounds.getMin_t().z) {
        	const lowPoint = totalBounds.getMin_t();
        	this._groundPlane.setFromNormalAndCoplanarPoint(this._groundPlane.normal, lowPoint);
        	groundInters = ray.intersectPlane_t(this._groundPlane, Vector3.zero());
        }
        // if intersects ground
        if (groundInters) {
            return new GroundIntersection(groundInters.clone(), ray.origin.distanceTo(groundInters));
        }
        return null;
    }

    _raycastGizmos(ray: RaySection): GizmoIntersection | null {
        let minDistanceYet = Infinity;
        let raytraced: GizmoIntersection | null = null;
		for (const g of this._gizmos) {
            if (g.visible) {
                let res: GizmoIntersection | null = g.intersectRay(ray);
				if (res && (res.distance < minDistanceYet)) {
					minDistanceYet = res.distance;
					raytraced = res;
				}
			}
		}
		return raytraced;
    }
}

export type SceneInt = BimObjectSceneIntersection | EditGizmoIntersection | GizmoIntersection | GroundIntersection | InteractiveObjectIntersection | null;


export class SceneIntersections {

    sceneObjectsIntersection: InteractiveObjectIntersection | null;
    groundIntersection: GroundIntersection | null;
    gizmosIntersection: GizmoIntersection | null;

    constructor(
        interactiveIntersection: InteractiveObjectIntersection | null,
        groundIntersection: GroundIntersection | null,
        gizmosIntersection: GizmoIntersection | null,
    ) {
        this.sceneObjectsIntersection = interactiveIntersection;
        this.groundIntersection = groundIntersection;
        this.gizmosIntersection = gizmosIntersection;
    }

    closest(): SceneInt {
        let minDistYet = Infinity;
        let minInt: SceneInt | null = null;


        if (this.gizmosIntersection && this.gizmosIntersection.distance < minDistYet) {
            minDistYet = this.gizmosIntersection.distance;
            minInt = this.gizmosIntersection;
        }
        if (this.sceneObjectsIntersection && this.sceneObjectsIntersection.distance < minDistYet) {
            minDistYet = this.sceneObjectsIntersection.distance;
            minInt = this.sceneObjectsIntersection;
        }

        if (minInt == null) {
            return this.groundIntersection;
        }
        return minInt;
    }
}

export class GroundIntersection {
    point: Vector3;
    distance: number;
    normal: Vector3;

    constructor(point: Vector3, distance: number) {
        this.point = point;
        this.distance = distance;
        this.normal = new Vector3(0, 0, 1);
    }
}
