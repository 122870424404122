import type { ScopedLogger } from "engine-utils-ts";
import type { Aabb, Matrix4} from "math-ts";
import { Aabb2, PointToPolygon, PolygonUtils, Segment2, Vector2, Vector3 } from "math-ts";
import type { Bim} from "..";
import { BasicAnalyticalRepresentation, ExtrudedPolygonGeometry } from "..";
import type { IdBimScene} from "../scene/SceneInstances";



export class BoundaryWsPolygon {
    aabb: Aabb2;
    area: number;

    constructor(
        readonly sourceBoundaryId: IdBimScene,
        readonly points: Vector2[]
    ) {
        this.aabb = Aabb2.empty().setFromPoints(points);
        this.area = PolygonUtils.area(points);
    }

    containsPoint(point: Vector2 | Vector3) {
        if (!this.aabb.containsPoint(point)) {
            return false;
        }
        _v2Reused.x = point.x;
        _v2Reused.y = point.y;
        if (PolygonUtils.isPointInsidePolygon(this.points, _v2Reused) === PointToPolygon.Outside) {
            return false;
        }
        return true;
    }

    containsBbox(localBbox: Aabb, wm: Matrix4) {
        localBbox.getCenter(_vCenterReused);
        _vCenterReused.applyMatrix4(wm);
        if (!this.containsPoint(_vCenterReused)) {
            return false;
        }
        // find any bounds corner that is also inside
        for (const cp of localBbox.getCornerPoints()) {
            cp.applyMatrix4(wm);
            if (this.containsPoint(cp)) {
                return true;
            }
        }
        return false;
    }

    intersectsWithBbox(localBbox: Aabb, wm: Matrix4) {
        const bboxCorners = localBbox.get2DCornersAtZ(0).map(p => p.applyMatrix4(wm).xy());
        const aabb2 = _aabb2Reused.setFromPoints(bboxCorners);
        if (!this.aabb.intersectsBox2(aabb2)) {
            return false;
        }

        if(this.containsBbox(localBbox, wm)){
            return true;
        }

        for (let i = 0; i < this.points.length; i++) {
            const polygonEdgeStart = this.points[i];
            const polygonEdgeEnd = this.points[(i + 1) % this.points.length];
            const polygonSegment = _segment1Reused.set(polygonEdgeStart, polygonEdgeEnd);
            for (let j = 0; j < bboxCorners.length; j++) {
                const corner = bboxCorners[j];
                const nextCorner = bboxCorners[(j + 1) % bboxCorners.length];
                const bboxSegment = _segment2Reused.set(corner, nextCorner);
                if (polygonSegment.intersectSegment(bboxSegment)) {
                    return true;
                }
            }
        }
        
        return false;
    }
}

const _segment1Reused = new Segment2(new Vector2(), new Vector2());
const _segment2Reused = new Segment2(new Vector2(), new Vector2());
const _aabb2Reused = Aabb2.empty();
const _vCenterReused = new Vector3();
const _v2Reused = new Vector2();

export function mapBoundaryInstancesToPolygons(
    logger: ScopedLogger,
    boundaries: Iterable<IdBimScene>,
    bim: Bim,
) {
    const resultPolygons: BoundaryWsPolygon[] = [];
    for (const id of new Set(boundaries)) {
        const instance = bim.instances.perId.get(id);
        if (instance?.type_identifier !== 'boundary') {
            continue;
        }
        if (!(instance.representationAnalytical instanceof BasicAnalyticalRepresentation)) {
            logger.batchedWarn('expected boundary to have basic analytical representation', instance.representation);
            continue;
        }
        const geo = bim.allBimGeometries.peekById(instance.representationAnalytical.geometryId);
        if (!(geo instanceof ExtrudedPolygonGeometry)) {
            logger.batchedWarn('expected boundary to have extruded polygon geo', geo);
            continue;
        }


        let pointsWs2D = geo.outerShell.points.map(p => p.clone());
        if (!instance.worldMatrix.isIdentity()) {
            for (const p of pointsWs2D) {
                p.applyMatrix4(instance.worldMatrix);
            }
        }
        pointsWs2D = PolygonUtils.simplifyContour(pointsWs2D, 0.01);
        pointsWs2D = PolygonUtils.deleteDuplicates(pointsWs2D);

        if (pointsWs2D.length < 3) {
            logger.batchedWarn('expected boundary to have 3 or more points', pointsWs2D);
            continue;
        }

        const polygon = new BoundaryWsPolygon(id, pointsWs2D);
        resultPolygons.push(polygon);
    }
    return resultPolygons;
}
