<script lang="ts">

import { UiCustomNode, type UiViewSource } from "ui-bindings";
import PUI_Root from "../pui/PUI_Root.svelte";

import TempSchemeWrapper from '../temp-scheme-wrapper/Wrapper.svelte'
import { EnergyPipelineProperty, NumbersArrayProperty, TrackerShadingFactorsProperty } from "bim-ts";
import TrackerShadingFactorsFull from "../pui/custom-components/TrackerShadingFactorsFull.svelte";
import { RuntimeSystemsUiContext } from "../runtime-systems-ui/RuntimeSystemsUiContext";
import RuntimeSystemsUiPanel from "../runtime-systems-ui/RuntimeSystemsUiPanel.svelte";
import { SelectByPropsContext } from '../select-by-props/SelectByPropsContext';
import SelectByPropsUi from '../select-by-props/SelectByPropsUi.svelte';
import NumbersArrayPropertyFull from '../pui/numbers-array-property/NumbersArrayPropertyFull.svelte';
import EnergyOverviewDialog from '../energy-view/EnergyOverviewDialog.svelte';
import HelpDialogUi from '../help-dialog/HelpDialogUi.svelte';
    import NotificationsPanel from '../notifications/NotificationsPanel.svelte';

export let identifier: string = "";
export let uiNodeDescription: UiViewSource<any>;

</script>

{#if uiNodeDescription instanceof UiCustomNode }
    {#if uiNodeDescription.context instanceof TrackerShadingFactorsProperty}
        <TrackerShadingFactorsFull sourceProperty={uiNodeDescription.context} />
    {:else if uiNodeDescription.context instanceof NumbersArrayProperty}
        <NumbersArrayPropertyFull sourceProperty={uiNodeDescription.context} />
    {:else if uiNodeDescription.context instanceof RuntimeSystemsUiContext}
        <RuntimeSystemsUiPanel context={uiNodeDescription.context} />
    {:else if uiNodeDescription.context instanceof EnergyPipelineProperty}
        <EnergyOverviewDialog energy={uiNodeDescription.context} />
    {:else if uiNodeDescription.context instanceof SelectByPropsContext}
        <SelectByPropsUi context={uiNodeDescription.context} />
    {:else if identifier == 'Electrical Scheme'}
        <TempSchemeWrapper/>
    {:else if identifier == 'help-dialog'}
        <HelpDialogUi context={uiNodeDescription.context}/>
    {:else if identifier == 'notifications'}
        <NotificationsPanel/>
    {:else}
        {console.error('unrecognized ui identifier', identifier)}
    {/if}
{:else }
    <PUI_Root
        configIdentifier={identifier}
        puiSource={uiNodeDescription}
    ></PUI_Root>
{/if}
