// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { BatchPartialReference } from '../wire/batch-partial-reference.js';
import { CompressionType } from '../wire/compression-type.js';


export class CollectionVersion {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):CollectionVersion {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsCollectionVersion(bb:flatbuffers.ByteBuffer, obj?:CollectionVersion):CollectionVersion {
  return (obj || new CollectionVersion()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsCollectionVersion(bb:flatbuffers.ByteBuffer, obj?:CollectionVersion):CollectionVersion {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new CollectionVersion()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

formatVersion():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

id():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

projectVersionId():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

date():bigint {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readUint64(this.bb_pos + offset) : BigInt('0');
}

batchesRefs(index: number, obj?:BatchPartialReference):BatchPartialReference|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new BatchPartialReference()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

batchesRefsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

additionalContextPayloadCompression():CompressionType {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : CompressionType.None;
}

additionalContextPayload(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index) : 0;
}

additionalContextPayloadLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

additionalContextPayloadArray():Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? new Uint8Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

static startCollectionVersion(builder:flatbuffers.Builder) {
  builder.startObject(7);
}

static addFormatVersion(builder:flatbuffers.Builder, formatVersion:number) {
  builder.addFieldInt32(0, formatVersion, 0);
}

static addId(builder:flatbuffers.Builder, id:number) {
  builder.addFieldInt32(1, id, 0);
}

static addProjectVersionId(builder:flatbuffers.Builder, projectVersionId:number) {
  builder.addFieldInt32(2, projectVersionId, 0);
}

static addDate(builder:flatbuffers.Builder, date:bigint) {
  builder.addFieldInt64(3, date, BigInt('0'));
}

static addBatchesRefs(builder:flatbuffers.Builder, batchesRefsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, batchesRefsOffset, 0);
}

static createBatchesRefsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startBatchesRefsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addAdditionalContextPayloadCompression(builder:flatbuffers.Builder, additionalContextPayloadCompression:CompressionType) {
  builder.addFieldInt32(5, additionalContextPayloadCompression, CompressionType.None);
}

static addAdditionalContextPayload(builder:flatbuffers.Builder, additionalContextPayloadOffset:flatbuffers.Offset) {
  builder.addFieldOffset(6, additionalContextPayloadOffset, 0);
}

static createAdditionalContextPayloadVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset {
  builder.startVector(1, data.length, 1);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt8(data[i]!);
  }
  return builder.endVector();
}

static startAdditionalContextPayloadVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(1, numElems, 1);
}

static endCollectionVersion(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createCollectionVersion(builder:flatbuffers.Builder, formatVersion:number, id:number, projectVersionId:number, date:bigint, batchesRefsOffset:flatbuffers.Offset, additionalContextPayloadCompression:CompressionType, additionalContextPayloadOffset:flatbuffers.Offset):flatbuffers.Offset {
  CollectionVersion.startCollectionVersion(builder);
  CollectionVersion.addFormatVersion(builder, formatVersion);
  CollectionVersion.addId(builder, id);
  CollectionVersion.addProjectVersionId(builder, projectVersionId);
  CollectionVersion.addDate(builder, date);
  CollectionVersion.addBatchesRefs(builder, batchesRefsOffset);
  CollectionVersion.addAdditionalContextPayloadCompression(builder, additionalContextPayloadCompression);
  CollectionVersion.addAdditionalContextPayload(builder, additionalContextPayloadOffset);
  return CollectionVersion.endCollectionVersion(builder);
}
}
