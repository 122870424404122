import type { PropertyBase, SceneInstance,
    MathSolversApi,
    PropertyPathType,
    UnitsMapper} from 'bim-ts';
import {
    type AssetId,
    type Bim, type Catalog, type CatalogItemId, type Config, type IdBimScene, type IdConfig, type PropertyGroup,
    type AssetCatalogItemProps,
    SceneInstancesProperty,
    ConfigUtils,
    createPropertyGroupPuiTransformers
} from 'bim-ts';
import {   
    SceneInstanceFlags,
    SceneInstances, SceneObjDiff, SelectorProperty} from 'bim-ts';
import type { VersionedValue } from 'engine-utils-ts';
import { type ScopedLogger, type TasksRunner, type LazyVersioned, LazyDerived, Immer } from 'engine-utils-ts';

import type { PUI_ConfigBasedBuilderParams, DefaultMessage, PUI_BuilderParams} from 'ui-bindings';
import { UiBindings, type PUI_Builder, type PUI_Node, type PUI_Lazy, buildFromLazyConfigObjectWithGlobalContext, NotificationDescription, NotificationType } from 'ui-bindings';

import { createCutFillUi } from '../cut-fill/CutFillUi';
import { createLvWiringUi } from '../wiring/LvWiringUi';
import { createMvWiringUi } from '../wiring/MvWiringUi';
import { createTrenchesUi } from '../trenches/TrenchesPanelUi';
import { createFarmLayoutUi } from '../farm-layout/FarmLayoutUi';
import type { CableDef } from '../trenches/TrenchService';
import { getNotFoundIds, restoreAssets } from './RestoreAssets';
import { getDefaultAssets } from './DefaultAssets';
import { notificationSource } from '../Notifications';
import type { VerDataSyncer } from 'verdata-ts';
import { VerDataSyncStatus } from 'verdata-ts';
import type { LayoutProperties} from '../farm-layout/FarmLayoutMetrics';
import { createLazyLayoutMetrics } from '../farm-layout/FarmLayoutMetrics';

export interface KreoEngineDescr {
    focusCamera(ids: IdBimScene[]): void;
}

export function createPanelsUiBindings(
    logger: ScopedLogger,
    tasksRunner: TasksRunner,
    mathSolversApi: MathSolversApi,
    bim: Bim,
    cablesCollection: [number, CableDef][],
    catalog: Catalog,
    dcCircuitHub: {calculate:(tasksRunner: TasksRunner, ui: UiBindings)=> Promise<void>},
    vds: VerDataSyncer,
    engine: KreoEngineDescr,
    openUiPanel: (id: string) => void,
    unitsMapper: UnitsMapper,
) {
    const ui = new UiBindings(logger);
    const configBuilderSettings = createPropertyGroupPuiTransformers();
    createFarmLayoutUi(ui, configBuilderSettings, mathSolversApi, tasksRunner, logger.newScope('farm-layout'), bim, catalog, vds, unitsMapper);
    createTrenchesUi(ui, configBuilderSettings, tasksRunner, logger.newScope('trenches'), bim, cablesCollection, catalog, vds);
    createMvWiringUi(ui, configBuilderSettings, mathSolversApi, tasksRunner, logger.newScope('mv-wiring'), bim, cablesCollection, catalog, vds);
    createCutFillUi(ui, configBuilderSettings, mathSolversApi, tasksRunner, logger.newScope('cut&fill'), bim, engine, catalog);
    createLvWiringUi(ui, configBuilderSettings, tasksRunner, logger.newScope('lv-wiring'), bim, catalog, engine, dcCircuitHub, openUiPanel);

    return ui;
}

export interface Context {
    propertyId: IdConfig;
    configType: string;
    connectedTo: IdBimScene;
    previousConfig: PropertyGroup;
    substations: Map<string, IdBimScene>;
    layoutMetrics: LayoutProperties | undefined;
    restoreAssets: boolean;
    notFoundCatalogItems: Set<number>;
    defaultAssets?: Map<string, number[]>;
}

export interface GlobalContext{
    configObserver:LazyVersioned<[IdConfig, Config][]>;
    selectionObserver:LazyVersioned<SelectedSceneInstances>;
}

interface SelectedSceneInstances {
    instancesByName: Map<string, IdBimScene>;
    selected?: SelectedInstance;
}

interface SelectedInstance {
    id: IdBimScene,
    name: string,
}

type ConfigObject = Partial<PropertyGroup > & { Substations: SelectorProperty | undefined};

export function createLazyUiConfig(
{ configBuilderParams, puiBuilderParams, onAfterRootNodeCallback, bim, logger, type, ui, tasksRunner, catalog, skipPaths, vds, otherInvalidator }: {
    configBuilderParams: PUI_ConfigBasedBuilderParams; 
    puiBuilderParams?: Omit<PUI_BuilderParams, "callbacks">;
    onAfterRootNodeCallback: (
        builder: PUI_Builder,
        root: PUI_Node,
        context: Context,
        ownedContext: GlobalContext
    ) => void;
    bim: Bim; 
    logger: ScopedLogger; 
    type: string; 
    ui: UiBindings; 
    tasksRunner: TasksRunner;
    skipPaths?: PropertyPathType[][],
    catalog?: Catalog;
    vds: VerDataSyncer;
    otherInvalidator?: VersionedValue
},
): PUI_Lazy<GlobalContext> {
    const selectedIdsLazy = getSceneInstancesInvalidator(bim, type, skipPaths ?? []);
    
    const disposeContext = (c: GlobalContext) => {

    };

    const catalogInvalidation:VersionedValue[] = [];
    if(catalog){
        catalogInvalidation.push(catalog.catalogItems);
        catalogInvalidation.push(catalog.assets);
    }
    
    const createProps = (context: GlobalContext) => {
        const layoutMetricsLazy = createLazyLayoutMetrics(bim, type, logger.newScope('metrics'));

        const notFoundCatalogItemsLazy = LazyDerived.new1(
            "not-found-catalog-items-lazy", 
            catalogInvalidation, 
            [context.configObserver], 
            ([configs]) => {
                const ids = catalog ? getNotFoundIds(catalog, configs, skipPaths ?? [], logger): undefined;
                return {
                    restoreAssets: ids ? ids.assets.length > 0 || ids.catalogItems.length > 0 : false,
                    notFoundCatalogItems: new Set(ids?.catalogItems),
                };
            }
        );

        const defaultAssets = LazyDerived.new0('default-assets-lazy', catalogInvalidation, () => {
            return catalog ? getDefaultAssets(catalog) : undefined
        });

        const lazyContext = LazyDerived.new4(
            'lazy-context', 
            null, 
            [notFoundCatalogItemsLazy, defaultAssets, layoutMetricsLazy, selectedIdsLazy], 
            ([notFoundIds, defaultAssets, metrics, selectedIds]) => {
                return {
                    restoreAssets: notFoundIds.restoreAssets,
                    notFoundCatalogItems: notFoundIds.notFoundCatalogItems,
                    defaultAssets: defaultAssets,
                    layoutMetrics: metrics,
                    selectedIds: selectedIds,
                }
            }
        );

        const lazyProps = LazyDerived.new3(
            "Properties",
            [...catalogInvalidation, otherInvalidator]
                .filter((x): x is VersionedValue => !!x), 
            [context.selectionObserver, context.configObserver, lazyContext],
            ([lastSelected, configs, context]) => {
                const lastSelectedId = lastSelected.selected?.id;
                const selectedConfig = configs.find(
                    ([_id, c]) => c.connectedTo === lastSelectedId
                );

                let config: ConfigObject = { Substations: undefined};
                if (selectedConfig && lastSelected.selected) {
                    const [_id, prop] = selectedConfig;
                    const cloned = ConfigUtils.copyTo(prop.properties);
                    config = {
                        Substations: SelectorProperty.new({
                            value: lastSelected.selected.name,
                            options: Array.from(
                                lastSelected.instancesByName.keys()
                            ),
                        }),
                        ...cloned,
                    };
                }

                const propertyId: IdConfig = selectedConfig
                    ? selectedConfig[0]
                    : 0 as IdConfig;
                const previousConfig = selectedConfig?.[1].properties ?? {};

                return {
                    configSample: config,
                    context: {
                        propertyId,
                        previousConfig,
                        connectedTo: lastSelectedId ?? 0 as IdBimScene,
                        layoutMetrics: lastSelected.selected ? context.layoutMetrics.get(lastSelected.selected.id): undefined,
                        substations: lastSelected.instancesByName,
                        configType: selectedConfig ? selectedConfig[1].type_identifier  : "",
                        defaultAssets: context.defaultAssets,
                        restoreAssets: context.restoreAssets,
                        notFoundCatalogItems: context.notFoundCatalogItems,
                        selectedIds: context.selectedIds,
                    },
                };
            }
        ).withoutEqCheck();
        return lazyProps;
    };



    function restoreActionCallback(
        builder: PUI_Builder,
        root: PUI_Node,
        context: Context,
        ownedContext: GlobalContext,
        skipPaths: PropertyPathType[][]
    ) {
        if(catalog && context.restoreAssets){
            builder.addActionsNode({
                name: "restore assets",
                context: undefined,
                typeSortKeyOverride: 900,
                actions: [
                    {
                        label: "restore assets",
                        hint: "click for restore",
                        action: () => {restoreAssets(catalog, bim, [context.configType], skipPaths ?? [], ui, tasksRunner, logger);},
                    }
                ]
            });
        }
    }

    const defaultMessage: DefaultMessage<Map<string, number[]>|undefined> = {
        message: "Create <b>Substation</b><br>to view its config",
        action: {
            context: LazyDerived.new1('default-assets-lazy', catalog ? [catalog.catalogItems, catalog.assets] : [], [vds.status], ([status]) => {
                if(status.syncStatus !== VerDataSyncStatus.Loaded){                    
                    return;
                }
                const defaultAssets = catalog ? getDefaultAssets(catalog) : undefined;
                return defaultAssets;
            }),
            actionDescr: { 
                action: (defaultAssets) => {
                const substations = defaultAssets?.get('substation');
                const substationId = substations && substations.length > 0 ? substations[0] : 0;
                const item = catalog?.catalogItems.peekById(substationId);
                const assetId = item?.as<AssetCatalogItemProps>().properties?.asset_id?.value ?? 0;
                const assetInst = catalog?.assets.sceneInstancePerAsset.getAssetAsSceneInstance(assetId);
                if(assetInst){
                    const newId: IdBimScene = bim.instances.idsProvider.reserveNewId();
                    bim.instances.allocate([[newId, {
                        name: assetInst.name,
                        type_identifier: assetInst.type_identifier,
                        properties: assetInst.properties,
                        props: assetInst.props,
                    }]]);
                } else {
                    ui.addNotification(
                        NotificationDescription.newBasic({
                            type: NotificationType.Warning,
                            source: notificationSource,
                            key: 'genericSubstationNotFound',
                            removeAfterMs: 5_000,
                            addToNotificationsLog: true
                        })
                    );
                }
            },
            label: 'Create substation',
            style: {
                type: 'outlined',
            }}
        },
    }

    return buildFromLazyConfigObjectWithGlobalContext({
        createConfigObj: createProps,
        configBuilderParams: configBuilderParams,
        afterRootNodeCallback: [onAfterRootNodeCallback, (b, r, c, o) => restoreActionCallback(b, r, c, o, skipPaths ?? [])],
        createContext: () => {return createContext(bim, type)},
        disposeContext,
        puiBuilderParams,
        patchCallback: (patch, context) => {
            if (patch) {
                const config = context.previousConfig as PropertyGroup;
                const newProps = Immer.produce(config, (draft) => {
                    for (const patchKey in patch) {
                        const patchValue = patch[patchKey];
                        if (
                            patchValue !== undefined &&
                            context.previousConfig[patchKey] !== undefined
                        ) {
                            draft[patchKey] = patchValue;
                        }
                    }
                });
                bim.configs.applyPatchTo(
                    {
                        properties: newProps,
                    },
                    [context.propertyId]
                );
                if (patch.Substations?.value) {
                    const selectedSubstation = context.substations.get(
                        patch.Substations.value
                    )!;
                    bim.instances.setSelected([selectedSubstation]);
                }
            } else {
                logger.batchedError("the config is not patched", [
                    patch,
                    context,
                ]);
            }
        },
        defaultMessage
    });
}

export function createContext(bim: Bim, type: string): GlobalContext {
    return {
        configObserver: bim.configs.getLazyListOf({
            type_identifier: type,
        }),
        selectionObserver: LazyDerived.new1(
            "substation-observable",
            [
                bim.instances.selectHighlight.getVersionedFlagged(
                    SceneInstanceFlags.isSelected
                ),
            ],
            [
                bim.instances.getLazyListOf({
                    type_identifier: "substation",
                    relevantUpdateFlags: SceneObjDiff.Name,
                }),
            ],
            ([instances], prevResult) => {
                let selected: SelectedInstance | undefined;
                let firstFromAll: SelectedInstance | undefined;
                let prevSelected = prevResult?.selected;
                const instancesByName = new Map<string, IdBimScene>();
                let isPrevSelectedExist = false;
                for (const [id, inst] of instances) {
                    const name = SceneInstances.uiNameFor(id, inst);
                    instancesByName.set(name, id);

                    if (inst.isSelected && !selected) {
                        selected = { id, name };
                    }
                    if (!firstFromAll) {
                        firstFromAll = { id, name };
                    }
                    if (prevSelected && prevSelected.id === id) {
                        isPrevSelectedExist = true;
                        prevSelected.name = name;
                    }
                }
                if (!isPrevSelectedExist) {
                    prevSelected = undefined;
                }

                return {
                    instancesByName,
                    selected: selected ?? prevSelected ?? firstFromAll,
                };
            }
        ),
    };
}


export type PropertyObject = PropertyGroup | PropertyGroup[] | PropertyBase | PropertyBase[];

export function patchObject<T extends PropertyGroup>(config:T, changePath:(string | number)[], value: PropertyObject|undefined, patchCallBack:(props:T) => void){
    if(value === undefined){
        console.error('invalid value for path:', changePath);
    }
    if(config){
        const updatedConfig = patchConfigProperty<T>(config, changePath, value);
        patchCallBack(updatedConfig);
    }else{
        console.warn(`value ${value} with path ${changePath} not patched`);
    }
}

export function patchConfigProperty<T extends PropertyGroup = PropertyGroup>(config: T, path:(string | number)[], value: PropertyObject|undefined):T{
    const updatedConfig = Immer.produce(config, (draft) => {
        let objToReplaceFieldIn = draft;
        if (path.length > 1) {
            for (let i = 0; i < path.length - 1; ++i) {
                const key = path[i];
                objToReplaceFieldIn = (objToReplaceFieldIn as any)[key];
                if (objToReplaceFieldIn === undefined) {
                    console.log('erroneous patch path', [config, path, value, objToReplaceFieldIn]);
                }
            }
        }
        const lastKey = path[path.length - 1];
        (objToReplaceFieldIn as any)[lastKey] = value;
    });
    return updatedConfig;
}

export function getAssets(catalog: Catalog){
    const perCatalogItems = new Map<CatalogItemId, SceneInstance>;
    const perAsset = new Map<AssetId, SceneInstance>;
    if(catalog){
        for (const [id, item] of catalog.catalogItems.readAll()) {
            const assetId = item?.as<AssetCatalogItemProps>().properties?.asset_id?.value ?? 0;
            if(!assetId){
                continue;
            }

            const instance = catalog.assets.sceneInstancePerAsset
                .getAssetAsSceneInstance(assetId)

            if(!instance){
                continue
            }

            perCatalogItems.set(id, instance);
            perAsset.set(assetId, instance);
        }
    }
    return {perCatalogItems, perAsset};
}

export function getSceneInstancesInvalidator(
    bim: Bim,
    configType: string,
    skipPaths: PropertyPathType[][] = []
): LazyVersioned<Set<IdBimScene>> {
    const config = bim.configs.archetypes.newDefaultInstanceForArchetype(configType);
    const allUsedTypes = new Set<string>();
    ConfigUtils.traverseByProperties(
        config.get<PropertyGroup>(),
        (prop) => {
            if (prop instanceof SceneInstancesProperty) {
                for (const type of prop.types) {
                    allUsedTypes.add(type);
                }
            }
        },
        skipPaths
    );

    const lazyObjsList = bim.instances.getLazyListOfTypes({
        type_identifiers: Array.from(allUsedTypes),
        relevantUpdateFlags: SceneObjDiff.None,
    });

    const lazyResult = LazyDerived.new1(
        "listOfItems",
        null,
        [lazyObjsList],
        ([arr]) => {
            return new Set(arr.map(([id]) => id));
        }
    );

    return lazyResult;
}