import type { ObjectSerializer } from 'engine-utils-ts';
import { ErrorUtils } from 'engine-utils-ts';
import { ByteBuffer } from 'flatbuffers';

export class VerdataUtils {


    static async bytesFromResponse(response: Promise<Response>): Promise<Uint8Array> {
        const resp = await response;
        const arraybuffer = await resp.arrayBuffer();
        if (arraybuffer.byteLength > 0) {
            return new Uint8Array(arraybuffer);
        }
        return Promise.reject("empty array buffer");
    }
    
    static async flatbufFromResponse(response: Promise<Response>): Promise<ByteBuffer> {
        const bytes = await VerdataUtils.bytesFromResponse(response);
        return new ByteBuffer(bytes);
    }

    static async deserializeAsync<T>(s: ObjectSerializer<T>, bytesPr: Promise<Uint8Array>): Promise<T> {
        const bytes = await bytesPr;
        return s.deserialize(bytes);
    }

    static checkedSerialize<T>(s: ObjectSerializer<T>, data: T): Uint8Array {
        const bytes = s.serialize(data);
        const de = s.deserialize(bytes);
        const bytes2 = s.serialize(de);
        let isRoundTripValid: boolean = true;
        if (bytes.length != bytes2.length) {
            isRoundTripValid = false;
        } else {
            for (let i = 0; i < bytes.length; ++i) {
                if (bytes[i] !== bytes2[i]) {
                    isRoundTripValid = false;
                    break;
                }
            }
        }
        if (!isRoundTripValid) {
            ErrorUtils.logThrow('checked serialization roundtrip failed', s, bytes, bytes2);
        }

        // if (!ObjectUtils.areObjectsEqual(data, de)) {
        //     console.error('checked serialization failure', data, de);
        //     throw new Error('serialization failure');
        // }
        return bytes;
    }

    // static checkedSerializeMultiple<T, S extends BinSerializer<T>>(s: S, data: T[]): Uint8Array[] {

    // }

}
