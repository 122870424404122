import { IterUtils, Yield } from 'engine-utils-ts';
import type { Circuit } from './models/circuit';
import type { Route } from './models/route';
import { TotalCondDetails } from './utils';

export function* groupRoutes(
    circuit: Circuit,
) {
    const map = new TotalCondDetails();
    for (const chunk of IterUtils.splitIterIntoChunks(
        circuit.connections.items.values(),
        60_000,
    )) {
        for (const req of chunk) {
            // skip minor merging requests
            if (req.mergingToReq && req.mergingToReq !== req)
                continue;
            const route = req.route;
            // skip short routes
            if (req.length < 0.5) continue;
            const perTypeMap = map.detailsPerCondType
                .getOrCreate(req.conductorType);
            const perGaugeMap = perTypeMap.detailsPerGauge
                .getOrCreate(req.label);
            const pairedRoutes: Route[] = route.getPairingRoutes(true);
            for (const route of pairedRoutes) {
                const perPairing = perGaugeMap.detailsPerPairingType
                    .getOrCreate(route.pairing.type);
                const perLength = perPairing.detailsPerLength
                    .getOrCreate(route.length);
                perLength.routes.push(route);
            }
        }
        yield Yield.NextFrame;
    }
    // recalculate stats
    map.calculateStats();
    circuit.groupingRoutes = map;
}

