
import type { IdInEntityLocal} from "bim-ts";
import { GraphGeometry, LocalIdsCounter, SegmentInterpLinearG } from "bim-ts";
import { IterUtils } from "engine-utils-ts";
import type { Vector3 , Transform } from "math-ts";
import { Matrix4 } from "math-ts";
import type { ESO_Any } from "../esos/ESO";
import type { ESSO_Any, ESSO_InteractiveAny } from "../esos/ESSO";
import { ESSO_EditPoint } from "../esos/ESSO_EditPoint";
import { InObjFullId, InObjIdType, InObjLocalId } from "./EngineSceneIds";
import type { ESOHandle } from "./ESOsCollection";
import type { GeometryCtor} from "./GeometryEditsHandler";
import { GeometryEditsHandler } from "./GeometryEditsHandler";

export interface GraphPointContext {
}

export class ESSO_EditPointGraph extends ESSO_EditPoint<GraphPointContext> {

}


export class GraphGeometryEditsHandler extends GeometryEditsHandler<GraphGeometry> {

	geometryCtor(): GeometryCtor {
		return GraphGeometry;
	}

	createEditReprImpl(
		handle: ESOHandle,
		obj: ESO_Any,
		geometryRef: Readonly<GraphGeometry>,
		result: ESSO_Any[],
	): void {

		for (const [[localId, point], index] of IterUtils.indexed(geometryRef.points)) {
			const pRepr = new ESSO_EditPointGraph(
				obj,
				InObjFullId.new(handle, InObjLocalId.new(InObjIdType.EditPoint, localId)),
				{},
				new Matrix4().setPositionV(point),
				{},
			);
			result.push(pRepr);
		}
	}

	getEditPoints(subObjs: ESSO_Any[]): Map<IdInEntityLocal, ESSO_EditPointGraph> {
		const res = new Map<IdInEntityLocal, ESSO_EditPointGraph>();
		for (const subObj of subObjs) {
			if (subObj instanceof ESSO_EditPointGraph) {
				res.set(subObj.id.inObjId.localId as IdInEntityLocal, subObj);
			}
		}
		return res;
	}

	patchPositions(
		geometryInOut: GraphGeometry,
		localIdsGenerator: LocalIdsCounter,
		subobjs: ESSO_InteractiveAny[],
		positionsPatch: Map<InObjLocalId, Transform>,
	): {
	} {

		for (const [id, patch] of positionsPatch) {
			const point = geometryInOut.points.get(id.localId as IdInEntityLocal);
			if (point !== undefined) {
				point.copy(patch.position);
			}
		}
		return {};
	}
	
	cloneParts(
		geometryInOut: GraphGeometry,
		localIdsGenerator: LocalIdsCounter,
		subobjs: ESSO_InteractiveAny[],
		idsToClone: InObjLocalId[],
		localSpaceDirection: Vector3,
	): {
		added: InObjLocalId[],
        toUseForGesture?: InObjLocalId[],
    } {

        if (idsToClone.length == 1) {
			const idToClone = idsToClone[0].localId as IdInEntityLocal;
			const pointToClone = geometryInOut.points.get(idToClone)!.clone();
			// decide, clone 
			let closestDirPoint: [dotProduct: number, id: IdInEntityLocal] | null = null;
			for (const connectedPointId of geometryInOut.getPointsConnectedToPoint(idToClone)) {
				const point = geometryInOut.points.get(connectedPointId)!;
				const diff = point.clone().sub(pointToClone);
				const cloneDirectionDot = diff.normalize().dot(localSpaceDirection);
				const minDirectionDot = Math.cos(Math.PI / 3);
				if (cloneDirectionDot > minDirectionDot
					&& (closestDirPoint == null || (cloneDirectionDot > closestDirPoint[0]))
				) {
					closestDirPoint = [cloneDirectionDot, connectedPointId];
				}
			}
			const newPointId = localIdsGenerator.nextId();

			if (closestDirPoint) {
				const closesPointId = closestDirPoint[1];
				// insert into edge
				const edgeToInsertInto = LocalIdsCounter.newEdge(closesPointId, idToClone);
				
				const edgeInterpPrev = geometryInOut.edges.get(edgeToInsertInto);
				if (!edgeInterpPrev) {
					this.logger.error('unexpected edge abscense ', [closesPointId, idToClone]);
					return { added: [] };
				}
				geometryInOut.edges.delete(edgeToInsertInto);
				// add 2 edges
				geometryInOut.edges.set(
					LocalIdsCounter.newEdge(newPointId, closesPointId),
					edgeInterpPrev
				);
				geometryInOut.edges.set(
					LocalIdsCounter.newEdge(newPointId, idToClone),
					edgeInterpPrev
				);
				geometryInOut.points.set(newPointId, pointToClone);
				
			} else {
				geometryInOut.edges.set(
					LocalIdsCounter.newEdge(newPointId, idToClone),
					SegmentInterpLinearG
				);
				geometryInOut.points.set(newPointId, pointToClone)
			}

			return {
				added: [InObjLocalId.new(InObjIdType.EditPoint, newPointId)],
			}
        }
        return {added: []};
	}

	deleteParts(
		geometryInOut: GraphGeometry,
		localIdsGenerator: LocalIdsCounter,
		subobjs: Readonly<ESSO_InteractiveAny>[],
		idsToDelete: InObjLocalId[],
	): {} {
		for (const id of idsToDelete) {
			for (const edge of geometryInOut.edges.keys()) {
				if (LocalIdsCounter.edgeIncludes(edge, id.localId as IdInEntityLocal)) {
					geometryInOut.edges.delete(edge);
				}
			}
			geometryInOut.points.delete(id.localId as IdInEntityLocal);
		}
		return {};
	}
}

