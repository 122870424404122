import type { Bim } from '../../Bim';
import type { SceneInstance } from '../../scene/SceneInstances';

export function addSolarPrimaryLabels(bim: Bim) {

    const unitsMapper = bim.unitsMapper;
    const instances = bim.instances;

    const wiresLabel = (s: Readonly<SceneInstance>) => {
        const voltageDrop = s.properties.get('circuit | mv_wiring | avarage_voltage_drop');
        if (voltageDrop) {
            return `${voltageDrop.valueUnitUiString(unitsMapper)} (Drop)`;
        }
        return undefined;
    };
    const acPowerLabel = (s: Readonly<SceneInstance>) => {
        const acPower = s.properties.get('circuit | aggregated_capacity | ac_power');
        if (acPower) {
            return `${acPower.valueUnitUiString(unitsMapper)} (AC)`;
        }
        return undefined;
    };
    const acPowerTransformerLabel = (s: Readonly<SceneInstance>) => {
        const blockAcPower = s.properties.get('circuit | block_capacity | ac_power');
        if (blockAcPower) {
            return `${blockAcPower.valueUnitUiString(unitsMapper)} (AC)`;
        }
        return undefined;
    };
    const dcPowerLabel = (s: Readonly<SceneInstance>) => {
        const acPower = s.properties.get('circuit | aggregated_capacity | dc_power');
        if (acPower) {
            return `${acPower.valueUnitUiString(unitsMapper)} (DC)`;
        }
        return undefined;
    };
    instances.registerPrimaryPropertyLabelProvider('substation', acPowerLabel);
    instances.registerPrimaryPropertyLabelProvider('sectionalizing-cabinet', acPowerLabel);
    instances.registerPrimaryPropertyLabelProvider('transformer', acPowerTransformerLabel);
    instances.registerPrimaryPropertyLabelProvider('inverter', dcPowerLabel);
    instances.registerPrimaryPropertyLabelProvider('combiner-box', dcPowerLabel);
    instances.registerPrimaryPropertyLabelProvider('any-tracker', dcPowerLabel);
    instances.registerPrimaryPropertyLabelProvider('tracker', dcPowerLabel);
    instances.registerPrimaryPropertyLabelProvider('fixed-tracker', dcPowerLabel);
    instances.registerPrimaryPropertyLabelProvider('wire', wiresLabel);

    instances.registerPrimaryPropertyLabelProvider('', (s) => {
        const electrPattern = s.properties.get('circuit | pattern');
        if (electrPattern) {
            return `${electrPattern.value} (DC)`;
        }
        return undefined;
    });
}
