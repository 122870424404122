<script lang="ts">
    //@ts-ignore
    import { NumberSpinner } from "../reusable";
    import type { UnitsMapper } from "bim-ts";
    import flash from "./flash";
    import type { PUI_PropertyNodeNumber } from "ui-bindings";
    import { getContext } from 'svelte';
    import type { Readable } from 'svelte/store';
    import { NumberSpinnerUtils } from "./NumberSpinnerUtils";
    import { VersionedStore } from "../VersionedStore";
    import { LazyBasic } from "engine-utils-ts";
    import { ButtonComponent, IconButton } from "../libui/button";
    import type { IconName } from "../libui/icon";
    import Lock from '../libui/icon/svg/lock.svelte';

    $:unitsMapper = getContext('unitsMapper') as Readable<UnitsMapper>;

    export let sourceProperty: PUI_PropertyNodeNumber;

    $: property = $unitsMapper.getMappedBoundProperty(sourceProperty);
    $: startValue = parseFloat(
        ((property.minMax[0] + property.minMax[1]) / 2).toFixed(
            -Math.log10(property.step)
        )
    );
    
    $:tag = new VersionedStore(sourceProperty.tag ?? new LazyBasic("default-lazy", undefined));
    $:icon = sourceProperty.icon ? {
        iconName: sourceProperty.icon.iconName as IconName,
        onClick: sourceProperty.icon.onClick
    } : undefined;

    let div: HTMLElement;

    let prevValue = sourceProperty.value;
    $: {
        if (prevValue != sourceProperty.value) {
            prevValue = sourceProperty.value;
            flash(div);
        }
    }

    $: valueColorStyle = sourceProperty.valueRenderFormatter
        ? sourceProperty.valueRenderFormatter(sourceProperty.value)
        : "inherit";

    function getNumberValue(value: number | null) {
        return value === null ? startValue : value;
    }

    function normalize(val: number) {
        const [min, max] = property.minMax;
        val = Math.max(min, val);
        val = Math.min(max, val);
        return val;
    }
    function inc() {
        if (property.value === undefined) {
            return;
        }
        property.value = normalize(
            getNumberValue(property.value) + property.step
        );
    }
    function dec() {
        if (property.value === undefined) {
            return;
        }
        property.value = normalize(
            getNumberValue(property.value) - property.step
        );
    }

    function addUnit(val: number) {
        const isNotNumber = property.value === null;
        return NumberSpinnerUtils.addUnit(val, isNotNumber, property.decimals, property.unit);
    }

    function removeUnit (str:string) {
        return NumberSpinnerUtils.removeUnit(str, property.unit);
    }
</script>

<div 
    class="ui-config-property property-row"
    class:not-active = {sourceProperty.notActive}
>
    <div bind:this={div} class="property-name text-main-medium">
        <div
            title={sourceProperty.hint}
            class="property-name-label mdc-typography--body1"
        >
            {sourceProperty.name}
        </div>
    </div>

    <div class="property-value text-main-dark">
        {#if sourceProperty.readonly || sourceProperty.calculated}
            <div
                class="read-only mdc-typography--body1"
                class:calculated={sourceProperty.calculated}
                style="color:{valueColorStyle};"
            >
                {property.value !== null ? addUnit(property.value) : ""}
                <span class="icon-holder">
                    {#if $tag}
                        <span title={$tag} tabindex="-1" class="calculated mdc-typography--body1 text-main-light tag-holder ">
                        {$tag}
                        </span>
                    {/if}
                    {#if !sourceProperty.calculated}
                        <Lock />
                    {/if}
                </span>
            </div>
        {:else}
            <div class="edit-field-container">
                {#key property.step}
                <NumberSpinner
                    value={property.value}
                    on:change={(e)=> {
                        property.value = e.detail;
                    }}
                    mainStyle={`color:${valueColorStyle}`}
                    type="number"
                    format={addUnit}
                    parse={removeUnit}
                    class="
                    config-ui-input-field
                    mdc-typography--body1
                "
                    step={property.step}
                    speed={property.sliderSpeed}
                    min={property.minMax[0]}
                    max={property.minMax[1]}
                    {startValue}
                />
                {/key}
                <div class="ctl-buttons">
                    {#if $tag || sourceProperty.icon}
                        {#if $tag}
                            <span tabindex="-1" class="calculated mdc-typography--body1 text-main-light tag-holder">
                                {$tag}
                            </span>
                        {/if}
                        {#if icon}
                            <ButtonComponent desc={new IconButton(icon.iconName, icon.onClick)} />
                        {/if}
                    {:else}
                        <div class="ctl-btn">
                            <ButtonComponent desc={new IconButton("Minus", () => dec())} />
                        </div>
                        <div class="ctl-btn">
                            <ButtonComponent desc={new IconButton("AddPlus", () => inc())} />
                        </div>
                    {/if}
                </div>
            </div>
        {/if}
    </div>
</div>
