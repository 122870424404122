<script lang="ts">
//@ts-ignore
import { type EngineUiLayout, type SvelteGridLayoutItem } from './EngineUiLayout';
import { ObjectUtils } from "engine-utils-ts";
import EngineUiPanelWrapper from './EngineUiPanelWrapper.svelte';

export let layout: EngineUiLayout;

let svelteGridLayout = layout.restoreFromLocalStorage();
$: {
    svelteGridLayout = layout.svelteGridLayout(svelteGridLayout);
    layout.updateLayoutFromSvelteGrid();
    layout.saveToLocalStorage();
}

function onLayoutChanged(item: SvelteGridLayoutItem) {
    const id = item.id;
    const prev = svelteGridLayout.find(x => x.id === id);
    if (!prev) {
      return;
    }
    if (!ObjectUtils.areObjectsEqual(prev, item)) {
        ObjectUtils.patchObject(prev, item);
        svelteGridLayout = [...svelteGridLayout]
    }
}

function focus(item: SvelteGridLayoutItem) {
    const oldPriority = item.priority;
    for (const item of svelteGridLayout) {
        if (item.priority > oldPriority) {
            item.priority = Math.max(0, item.priority - 1);
        }
    }
    item.priority = svelteGridLayout.length;
    svelteGridLayout = [...svelteGridLayout]
}

</script>


<div class="engine-ui-panels-container">
    {#each svelteGridLayout as item (item.id)}
        <div style="--priority: {item.priority + 1}" class="wrapper-container">
            <EngineUiPanelWrapper
                item={item}
                onLayoutChanged={onLayoutChanged}
                onClose={() => layout.togglePanelEnabled(item.id)}
                focus={() => focus(item)}
            />
        </div>
    {/each}
</div>

<style>

.wrapper-container {
    z-index: var(--priority);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    pointer-events: none;
}

:global(.svlt-grid-shadow) {
    /* Back shadow */
    background: rgba(0, 0, 0, 0.1) !important;
    border-color: white !important;
}
:global(.svlt-grid-resizer::after) {
    /* Resizer color */
    border-color: white !important;
}
.engine-ui-panels-container {
    margin-top: 33px;
    position: relative;
}

</style>

