import { KrMath, Vector3 } from "math-ts";

export const EarthRadius = 6371e3;

export class WGSCoord { // geographic coordinate system

	latitude: number;
	longitude: number;
	altitude?: number; // above surface, legacy

	constructor(latitude: number, longitude: number, altitude?: number) {
		this.latitude = latitude;
		this.longitude = longitude;
		this.altitude = altitude;
		Object.freeze(this);
	}

	static new(latitude: number | undefined, longitude: number | undefined, altitude?: number): WGSCoord | null {
		if (latitude == null || longitude == null) {
			return null;
		}
		if (Math.abs(latitude) <= 90 && Math.abs(longitude) <= 180) {
			return new WGSCoord(latitude, longitude, altitude);
		} else {
			console.error("Wrong WGS origin: { latitude = " + latitude + ", longitude = " + longitude + " }");
			return null;
		}
	}

	static tryParseLatLongFromString(str: string): WGSCoord | null {
		const parts = str.split(',');
		if (parts.length !== 2) {
			return null;
		}
		const latitude = parseFloat(parts[0]);
		const longitude = parseFloat(parts[1]);
		if (isNaN(latitude) || isNaN(longitude)) {
			return null;
		}
		return WGSCoord.new(latitude, longitude, 0);
	}

	withDifferentAltitude(altitude: number) {
		return new WGSCoord(this.latitude, this.longitude, altitude);
	}

	equals(c: WGSCoord): boolean {
		return this.latitude === c.latitude && this.longitude === c.longitude && this.altitude === c.altitude;
	}
	clone() {
		return new WGSCoord(this.latitude, this.longitude, this.altitude);
	}

	to3d(): Vector3 {
		const lat = KrMath.degToRad(this.latitude);
		const lon = KrMath.degToRad(this.longitude);
		return new Vector3(
			EarthRadius * Math.cos(lon) * Math.cos(lat),
			EarthRadius * Math.sin(lon) * Math.cos(lat),
			EarthRadius * Math.sin(lat),
		);
	}

	distanceTo(wgs: WGSCoord) {
		// haversine formula to calculate the great-circle distance between two points
		//http://www.movable-type.co.uk/scripts/latlong.html
		const lat1 = KrMath.degToRad(this.latitude);
		const lon1 = KrMath.degToRad(this.longitude);

		const lat2 = KrMath.degToRad(wgs.latitude);
		const lon2 = KrMath.degToRad(wgs.longitude);

		const lonDelta = lon2 - lon1;
		const latDelta = lat2 - lat1;

		const a = Math.sin(latDelta/2) * Math.sin(latDelta/2) +
			Math.cos(lat1) * Math.cos(lat2) * Math.sin(lonDelta/2) * Math.sin(lonDelta / 2);
		
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

		return EarthRadius * c;
	}
}
