import type { NamedBimPropertiesGroup as PropsGroup } from "src/bimDescriptions/NamedBimPropertiesGroup";
import type { CostsConfigProps } from "./CostsConfig";
import { CostsConfigIdentifier, type CostComponents, type CostComponentsNonNullable, type InstanceCost as InstanceCostPersistent, type NamedBimPropertiesGroupRepresentation } from "./CostsConfig";
import type { InstanceCost } from "./CostConfigProvider";
import type { Bim, PropertyBase} from "../../..";
import { BimProperty, BooleanProperty, NumberProperty, StringProperty } from "../../..";
import { LazyDerived, ObjectUtils } from "engine-utils-ts";

export function InstanceCostFromPersistent<T extends PropsGroup = PropsGroup>(x: InstanceCostPersistent)
    : InstanceCost<T>
{
    return {
        props: PrimitivePropsToNamedBimPropsGroup(x.props),
        costs: x.costs,
        instance_type: x.instance_type.value,
        name: x.name.value,
    }
}
export function InstanceCostToPersistent<T extends PropsGroup = PropsGroup>(x: InstanceCost<T>)
    : InstanceCostPersistent
{
    return {
        props: NamedBimPropsGroupToPrimitiveProps(x.props),
        costs: x.costs,
        instance_type: StringProperty.new({ value: x.instance_type }),
        name: StringProperty.new({ value: x.name }),
    }
}


export function NamedBimPropsGroupToPrimitiveProps<T extends PropsGroup>(props: T)
    : NamedBimPropertiesGroupRepresentation
{
    const result: NamedBimPropertiesGroupRepresentation = {}
    for (const [key, prop] of Object.entries(props)) {
        const path = prop.path.map(x => StringProperty.new({ value: x }));
        let newProp: PropertyBase | null = null
        if (typeof prop.value === 'number') {
            newProp = NumberProperty.new({
                unit: prop.unit ?? '',
                value: prop.value,
            })
        } else if (typeof prop.value === 'string') {
            newProp = StringProperty.new({
                value: prop.value,
            })
        } else if (typeof prop.value === 'boolean') {
            newProp = BooleanProperty.new({
                value: prop.value,
            })
        }
        if (!newProp) {
            throw new Error('not supported bim property type ' + key + ' ' + prop)
        }
        result[key] = {
            property: newProp,
            propertyPath: path,
        }
    }
    return result;
}

export function PrimitivePropsToNamedBimPropsGroup<T extends PropsGroup>(
    representation: NamedBimPropertiesGroupRepresentation,
): T {
    const result: PropsGroup = {}
    for (const [key, repr] of Object.entries(representation)) {
        const path = repr.propertyPath.map(x => x.value);
        const prop = repr.property;
        let newProp: BimProperty | null = null;
        if (prop instanceof NumberProperty) {
            newProp = new BimProperty({ unit: prop.unit, value: prop.value, path })
        } else if (prop instanceof StringProperty) {
            newProp = new BimProperty({ value: prop.value, path })
        } else if (prop instanceof BooleanProperty) {
            newProp = new BimProperty({ value: prop.value, path })
        }
        if (!newProp) {
            throw new Error('not supported property type' + prop)
        }
        result[key] = newProp
    }
    return result as T;
}

export function multiplyCosts<T extends CostComponents>(costs: T, coef: number): T {
    const newCosts = ObjectUtils.deepCloneObj(costs);
    if (costs.equipmentCost) {
        newCosts.equipmentCost = NumberProperty.new({ value: costs.equipmentCost.value * coef });
    }
    if (costs.laborTimeUnits) {
        newCosts.laborTimeUnits = NumberProperty.new({ value: costs.laborTimeUnits.value * coef });
    }
    if (costs.materialCost) {
        newCosts.materialCost = NumberProperty.new({ value: costs.materialCost.value * coef });
    }
    if (costs.laborTimeUnitCost) {
        newCosts.laborTimeUnitCost = NumberProperty.new({ value: costs.laborTimeUnitCost.value * coef });
    }
    if (costs.laborCost) {
        newCosts.laborCost = NumberProperty.new({ value: costs.laborCost.value * coef });
    }
    if (costs.serviceCost) {
        newCosts.serviceCost = NumberProperty.new({ value: costs.serviceCost.value * coef });
    }
    return newCosts
}

export function createEmptyCostComponents(): CostComponents {
    return {
        laborTimeUnits: null,
        laborTimeUnitCost: null,
        laborCost: null,
        materialCost: null,
        serviceCost: null,
        equipmentCost: null,
    }
}
export function createEmptyCostComponentsNonNullable(): CostComponentsNonNullable {
    return {
        laborTimeUnits: NumberProperty.new({ value: 0 }),
        laborTimeUnitCost: NumberProperty.new({ value: 0 }),
        laborCost: NumberProperty.new({ value: 0 }),
        materialCost: NumberProperty.new({ value: 0 }),
        serviceCost: NumberProperty.new({ value: 0 }),
        equipmentCost: NumberProperty.new({ value: 0 }),
    }
}

export function createCostConfigIsEmpty(bim: Bim) {
    const costsConfig = bim.configs.getLazySingletonOf({
        type_identifier: CostsConfigIdentifier
    })
    const isCostConfigEmpty = LazyDerived.new1(
        'isCostModelEmpty',
        [],
        [costsConfig],
        ([config]) => {
            const props = config.get<CostsConfigProps>();
            return !(props.instances.length || props.estimates.length);
        }
    );
    return isCostConfigEmpty;
}

export function sumCostComponents(cost: CostComponents) {
    let total = 0;
    total += cost.equipmentCost?.value ?? 0;
    total += cost.materialCost?.value ?? 0;
    total += cost.serviceCost?.value ?? 0;
    if (cost.laborCost) {
        total += cost.laborCost?.value ?? 0;
    } else {
        const laborCost =
            (cost.laborTimeUnits?.value ?? 0) *
            (cost.laborTimeUnitCost?.value ?? 0);
        total += laborCost;
    }
    return total;
}

export function isCostComponentsEmpty(cost: CostComponents | CostComponentsNonNullable | undefined | null) {
    if (!cost) {
        return true;
    }
    if (typeof cost?.equipmentCost?.value === 'number') {
        return false
    }
    if (typeof cost?.laborTimeUnits?.value === 'number') {
        return false
    }
    if (typeof cost?.materialCost?.value === 'number') {
        return false
    }
    if (typeof cost?.laborTimeUnitCost?.value === 'number') {
        return false
    }
    if (typeof cost?.laborCost?.value === 'number') {
        return false
    }
    if (typeof cost?.serviceCost?.value === 'number') {
        return false
    }
    return true;
}

export function mergeCostComponents(
    costs: CostComponents | undefined | null,
    defaults: CostComponentsNonNullable,
): CostComponents {
    if (!costs) {
        return defaults;
    }
    const result = createEmptyCostComponents();

    result.equipmentCost = costs.equipmentCost ?? defaults.equipmentCost;
    result.serviceCost = costs.serviceCost ?? defaults.serviceCost;
    result.materialCost = costs.materialCost ?? defaults.materialCost;
    result.laborCost = costs.laborCost ?? defaults.laborCost;
    result.laborTimeUnitCost = costs.laborTimeUnitCost ?? defaults.laborTimeUnitCost;
    result.laborTimeUnits = costs.laborTimeUnits ?? defaults.laborTimeUnits;

    if (
        (costs.laborTimeUnits && result.laborTimeUnitCost) ||
        (costs.laborTimeUnitCost && result.laborTimeUnits)
    ){
        result.laborCost = null;
    }
    return result;
}
