import type { Bim, IdBimGeo, IdBimScene, IdInEntityLocal} from 'bim-ts';
import {
    BasicAnalyticalRepresentation, PolylineGeometry} from 'bim-ts';
import type { LazyVersioned, UndoStack, Result } from 'engine-utils-ts';
import { LazyBasic, ObservableObject, Success } from 'engine-utils-ts';
import { Vector3 } from 'math-ts';
import type { EngineScene } from '../scene/EngineScene';
import type { InteractiveObjectsActive } from '../scene/InteractiveSceneObjects';

import type { SceneInt } from '../scene/SceneRaycaster';
import { InObjFullId } from '../scene/EngineSceneIds';
import type { EditModeControls } from './EditControls';
import { MouseButton } from './InputController';
import type { EditInteractionResult, InteractiveEditOperator, StartOptions } from './InteractiveEditOperator';
import type { MouseEventData } from './MouseGesturesBase';
import { EngineControlsMode } from '../EngineConsts';
import type { MenuPath } from 'ui-bindings';
import { EditActionResult } from 'ui-bindings';

interface TrenchAdderState {
    instanceId: IdBimScene | 0;
    geoId: IdBimGeo,
	instanceOwned: boolean,
	startGeometry: PolylineGeometry,
}
interface TrenchAdderSetting {
}
export class InteractiveTrenchAdder implements InteractiveEditOperator<TrenchAdderState | null, TrenchAdderSetting> {

    readonly menuPath: MenuPath = ['Add', 'Trench'];
    readonly priority: number = 4;
    readonly canStart: LazyVersioned<boolean>;
    readonly config: ObservableObject<TrenchAdderSetting>;

    constructor(
        readonly bim: Bim,
		readonly engineScene: EngineScene,
		readonly allInteractiveObjects: InteractiveObjectsActive,
        readonly editControls: EditModeControls,
        undoStack: UndoStack,
    ) {
        this.canStart = new LazyBasic('', true);
        this.config = new ObservableObject({
            identifier: this.menuPath.join(),
            initialState: {},
			undoStack,
            throttling: {onlyFields: []}
        });
    }

    start(options?: StartOptions): Result<TrenchAdderState | null> {
        if (options?.resetSelection) {
            this.bim.instances.setSelected([]);
        }
        return new Success(null)
    }
    cancel(state: TrenchAdderState) {
        if (state !== null) {
            if (state.instanceId && state.instanceOwned) {
                this.bim.instances.delete([state.instanceId]);
            } else {
                this.bim.polylineGeometries.applyPatches([[state.geoId, state.startGeometry]]);
            }
        }
    }
    finish(state: TrenchAdderState | null): EditActionResult | undefined {
        return state?.instanceId ? new EditActionResult([state.instanceId]) : undefined;
    };
    handleConfigPatch(
        patch: Partial<TrenchAdderSetting>,
        prevState: TrenchAdderState | null,
    ): EditInteractionResult<TrenchAdderState | null> {
        throw new Error('Method not implemented.');
    }

    onHover (int: SceneInt): {cursorStyleToSet: string} | null {
        return {cursorStyleToSet: "crosshair"};
    }

	_getNewGeometryFor(point: Vector3) {
		const graphGeo = PolylineGeometry.newWithAutoIds(
			[point],
		);
		return graphGeo;
	}

    handleClick(
        sceneInt: SceneInt,
        me: MouseEventData,
        state: TrenchAdderState | null
    ) : EditInteractionResult<TrenchAdderState | null> {
        if (me.buttons.mouseButton === MouseButton.Right) {
            return {
                state: state,
                done: true,
            }
        }

		const point = sceneInt?.point ?? me.mouseCone?.raySection.ray.at(10, new Vector3()) ?? new Vector3();

		const bimInstances = this.bim.instances;
		const selectedIds = bimInstances.getSelected();



		if (state === null
			&& selectedIds.length === 1
			&& bimInstances.peekById(selectedIds[0])?.type_identifier === 'trench'
		) {
			const instanceId = selectedIds[0];
			const instance = bimInstances.peekById(instanceId)!;

			if (instance.representationAnalytical instanceof BasicAnalyticalRepresentation
				&& this.bim.polylineGeometries.peekById(instance.representationAnalytical.geometryId)
			) {
				const geoId = instance.representationAnalytical.geometryId;
				const geo =  this.bim.polylineGeometries.peekById(geoId)!;
				state = {
					geoId,
					startGeometry: geo,
					instanceOwned: false,
					instanceId,
				}
			}
		}

        if (state === null) {

			const geoId = this.bim.polylineGeometries.idsProvider.reserveNewId();
			const geo = this._getNewGeometryFor(point);
			const instanceId = this.bim.instances.idsProvider.reserveNewId();

			this.bim.polylineGeometries.allocate([[geoId, geo]]);

			const instance = this.bim.instances.archetypes.newDefaultInstanceForArchetype('trench');
			instance.representationAnalytical = new BasicAnalyticalRepresentation(geoId);
			this.bim.instances.allocate([[instanceId, instance]]);
			this.bim.instances.setSelected([instanceId]);


            this.editControls.controlsState.applyPatch({patch: {controlsMode: EngineControlsMode.Edit}});

            return {
                state: { instanceId, geoId, instanceOwned: true, startGeometry: geo },
                done: false,
            };
        } else {
            const geo = this.bim.polylineGeometries.peekById(state.geoId);
            if (!geo) {
                return {
                    state: null,
                    done: false,
                }
            }

			let pointToConnectTo: IdInEntityLocal | null = null;
			let connectedToSelected: boolean = false;
			const selected = this.allInteractiveObjects.getSelected();
			if (selected.length === 1 && selected[0] instanceof InObjFullId) {
				const selectedSubObj = selected[0];
				const id = this.engineScene.esos.idOf(selectedSubObj.objHandle);
				if (id === state.instanceId && geo.pointsLocalIds.includes(selectedSubObj.inObjId.localId as IdInEntityLocal)) {
					pointToConnectTo = selectedSubObj.inObjId.localId as IdInEntityLocal;
					connectedToSelected = true;
				}
			}
			if (pointToConnectTo === null) {
				pointToConnectTo = geo.pointsLocalIds.at(-1) ?? null;
			}


			const newPointId = this.bim.polylineGeometries.localIdsCounterFor(state.geoId).nextId();

			const newPointIndex = geo.pointsLocalIds.indexOf(pointToConnectTo!) + 1;

            const geoPoints = Vector3.arrayFromFlatArray(geo.points3d);
            const geoLocalIds = geo.pointsLocalIds.slice();

			geoPoints.splice(newPointIndex, 0, point);
			geoLocalIds.splice(newPointIndex, 0, newPointId);

			const newGeo = new PolylineGeometry(
                Vector3.arrToDoubleArr(geoPoints),
                geoLocalIds,
                geo.radius
            );

            this.bim.polylineGeometries.applyPatches([[state.geoId, newGeo]]);

			if (connectedToSelected) {
				this.allInteractiveObjects.setSelected([]);
			}

            return {
                state: { ...state },
                done: false,
            }
        }
    }

}

