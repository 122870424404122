// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { CubeT } from './cube.js';
import { Cube } from './cube.js';


export class CubeCollection implements flatbuffers.IUnpackableObject<CubeCollectionT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):CubeCollection {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsCubeCollection(bb:flatbuffers.ByteBuffer, obj?:CubeCollection):CubeCollection {
  return (obj || new CubeCollection()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsCubeCollection(bb:flatbuffers.ByteBuffer, obj?:CubeCollection):CubeCollection {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new CubeCollection()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

formatVersion():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

ids(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
}

idsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

idsArray():Uint32Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? new Uint32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

collection(index: number, obj?:Cube):Cube|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new Cube()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

collectionLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startCubeCollection(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addFormatVersion(builder:flatbuffers.Builder, formatVersion:number) {
  builder.addFieldInt32(0, formatVersion, 0);
}

static addIds(builder:flatbuffers.Builder, idsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, idsOffset, 0);
}

static createIdsVector(builder:flatbuffers.Builder, data:number[]|Uint32Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createIdsVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createIdsVector(builder:flatbuffers.Builder, data:number[]|Uint32Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt32(data[i]!);
  }
  return builder.endVector();
}

static startIdsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addCollection(builder:flatbuffers.Builder, collectionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, collectionOffset, 0);
}

static createCollectionVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startCollectionVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endCubeCollection(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createCubeCollection(builder:flatbuffers.Builder, formatVersion:number, idsOffset:flatbuffers.Offset, collectionOffset:flatbuffers.Offset):flatbuffers.Offset {
  CubeCollection.startCubeCollection(builder);
  CubeCollection.addFormatVersion(builder, formatVersion);
  CubeCollection.addIds(builder, idsOffset);
  CubeCollection.addCollection(builder, collectionOffset);
  return CubeCollection.endCubeCollection(builder);
}

unpack(): CubeCollectionT {
  return new CubeCollectionT(
    this.formatVersion(),
    this.bb!.createScalarList<number>(this.ids.bind(this), this.idsLength()),
    this.bb!.createObjList<Cube, CubeT>(this.collection.bind(this), this.collectionLength())
  );
}


unpackTo(_o: CubeCollectionT): void {
  _o.formatVersion = this.formatVersion();
  _o.ids = this.bb!.createScalarList<number>(this.ids.bind(this), this.idsLength());
  _o.collection = this.bb!.createObjList<Cube, CubeT>(this.collection.bind(this), this.collectionLength());
}
}

export class CubeCollectionT implements flatbuffers.IGeneratedObject {
constructor(
  public formatVersion: number = 0,
  public ids: (number)[] = [],
  public collection: (CubeT)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const ids = CubeCollection.createIdsVector(builder, this.ids);
  const collection = CubeCollection.createCollectionVector(builder, builder.createObjectOffsetList(this.collection));

  return CubeCollection.createCubeCollection(builder,
    this.formatVersion,
    ids,
    collection
  );
}
}
