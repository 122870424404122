// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class PropertyColorValue implements flatbuffers.IUnpackableObject<PropertyColorValueT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):PropertyColorValue {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsPropertyColorValue(bb:flatbuffers.ByteBuffer, obj?:PropertyColorValue):PropertyColorValue {
  return (obj || new PropertyColorValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsPropertyColorValue(bb:flatbuffers.ByteBuffer, obj?:PropertyColorValue):PropertyColorValue {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new PropertyColorValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

static startPropertyColorValue(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addValue(builder:flatbuffers.Builder, value:number) {
  builder.addFieldFloat64(0, value, 0.0);
}

static endPropertyColorValue(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createPropertyColorValue(builder:flatbuffers.Builder, value:number):flatbuffers.Offset {
  PropertyColorValue.startPropertyColorValue(builder);
  PropertyColorValue.addValue(builder, value);
  return PropertyColorValue.endPropertyColorValue(builder);
}

unpack(): PropertyColorValueT {
  return new PropertyColorValueT(
    this.value()
  );
}


unpackTo(_o: PropertyColorValueT): void {
  _o.value = this.value();
}
}

export class PropertyColorValueT implements flatbuffers.IGeneratedObject {
constructor(
  public value: number = 0.0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return PropertyColorValue.createPropertyColorValue(builder,
    this.value
  );
}
}
