import { DC_CNSTS } from 'bim-ts';
import { DefaultMap, IterUtils } from 'engine-utils-ts';
import type { Vector2 } from 'math-ts';
import type { Connection } from '../models/connection';

/**
 * Some conductor types have to be considered as a merging routes.
 * ex Multiharnesses that are coming from a single node to multiple utility
 * nodes actually is a single phisical cable. So only the longest one should
 * be measured.
 */
export function checkMergingConnectionsStartingFromSamePoint(
    conns: Connection[],
) {
    const reqPerCondTypeMap: DefaultMap<
        DC_CNSTS.ConductorType,
        Set<Connection>
    > = new DefaultMap(() => new Set());
    for (const req of conns) {
        const set = reqPerCondTypeMap.getOrCreate(req.conductorType);
        set.add(req);
    }
    for (const [type, group] of reqPerCondTypeMap) {
        if (!DC_CNSTS.merginCableTypes.includes(type)) continue;
        checkMergingRequestsByConductorType(Array.from(group.values()));
    }
}

interface AngledRequest {
    req: Connection;
    angle: number;
    dir: Vector2;
}

function incLooped(prev: number, size: number) {
    const next = prev + 1;
    if (next >= size)
        return 0;
    return next;
}
function decLooped(prev: number, size: number) {
    const next = prev - 1;
    if (next >= 0)
        return next;
    return size - 1;
}

/**
 * calculate merging requests for same type requests
 */
export function checkMergingRequestsByConductorType(conns: Connection[]) {
    let groups = [conns]
    const sample = conns[0];
    HORIZONTAL_TRUNKBUS_FIX: {
        if (
            !sample ||
            sample.conductor.type !== DC_CNSTS.ConductorType.TrunkBus ||
            !sample.constraints.includes(DC_CNSTS.Constraints.Horizontal)
        ) {
            break HORIZONTAL_TRUNKBUS_FIX;
        }
        // trunkbus which is placed horizontally should only be merged inside same parent
        groups = Array.from(IterUtils.groupBy(conns, x => x.from.id)).map(x => x[1])
    }
    for (const conns of groups) {
        const angleReqSorted = conns
            .map(x => ({ req: x, dir: x.route.getNthSegmentDirection(0) }))
            .map(x => ({ ...x, angle: x.dir.angle() }))
            .sort((a, b) => a.angle - b.angle) as AngledRequest[];
        const seenReqs: Set<AngledRequest> = new Set();
        const size = angleReqSorted.length;
        const iters = [
            (i: number) => incLooped(i, size),
            (i: number) => decLooped(i, size),
        ];
        for (const [idx, baseAngleReq] of angleReqSorted.entries()) {
            if (seenReqs.has(baseAngleReq)) continue;
            let longest = baseAngleReq.req.route;
            const group: Set<AngledRequest> = new Set();
            group.add(baseAngleReq);
            seenReqs.add(baseAngleReq);
            for (const next of iters) {
                let latest = baseAngleReq;
                let i = next(idx);
                while (i !== idx) {
                    const cur = angleReqSorted[i];
                    const route = cur.req.route;
                    if (seenReqs.has(cur)) break;
                    if (cur.dir.smallestAngleTo(latest.dir) < Math.PI / 20) {
                        seenReqs.add(cur);
                        latest = cur;
                        group.add(cur);
                        if (route.length > longest.length)
                            longest = route;
                        i = next(i);
                    } else break;
                }
            }
            group.forEach(x => x.req.mergingToReq = longest.connection);
        }
    }
}
