<script lang="ts">
    import { LazyBasic, LazyDerived } from "engine-utils-ts";
    import { getContext } from "svelte";
    import {
        ComparisonMode,
        ZeroVersionIdentifier,
        VerdataVersionDescription,
        ComparisonItemView,
        CurrentVersion,
    } from "./ComparisonMode";
    import type { Bim } from "bim-ts";
    import EngineUiPanel from "../ui-panels/EngineUiPanel.svelte";
    import PUI_Root from '../pui/PUI_Root.svelte'
    import { ProjectComparisonItems, createSelectVersionPui, convertComparisonItemsToUiNodes } from "./ProjectComparisonItems";
    import MetricsRoot from "../project-panel/metrics-view/MetricsRoot.svelte";
    import { MetricGroupNode, RelativeModeType } from "../project-panel/metrics-view/MetricProperty";
    import { VerDataSyncer } from "verdata-ts";

    const comparison = getContext<ComparisonMode>("comparison");
    const verdata = getContext<VerDataSyncer>("verDataSyncer");
    const unitsMapper = getContext<Bim>("bim").unitsMapper;
    const projectComparison = getContext<ProjectComparisonItems>(
        "projectComparisonItems",
    );

    const metricsUiNodesLazy = LazyDerived.new0<MetricGroupNode>(
        "metrics-ui-nodes", 
        [comparison, verdata],
        () => {
            const itemsArray = Array.from(comparison.items.values());

            const displayItems: ComparisonItemView[] = [];
            for (let i = 0; i < itemsArray.length; i++) {
                const item = itemsArray[i];
                const getVersionDescr = (
                    ver: VerdataVersionDescription | ZeroVersionIdentifier | CurrentVersion,
                ) => {
                    return ver instanceof ZeroVersionIdentifier
                        ? ""
                        : ver?.textDescription?.poll() || "";
                };
                const header = `${getVersionDescr(item.projectVersion)}`; //| Catalog ${getVersionDescr(item.catalogVersion)}

                displayItems.push({
                    id: item.id,
                    projectVersion: item.projectVersion.id,
                    catalogVersion: item.catalogVersion.id,
                    projectName:
                        item.project.name || item.project.id.toString(),
                    header,
                    metrics: item.metrics.poll(),
                });
            }
            displayItems.sort((a, b) => {
                if (a.projectVersion < 0 && b.projectVersion >= 0) {
                    return 1;
                }
                if (a.projectVersion >= 0 && b.projectVersion < 0) {
                    return -1;
                }
                return a.projectVersion - b.projectVersion;
            });
            const currentProjectVersion = verdata.getCurrentVersionId();

            const metricsRootNode = convertComparisonItemsToUiNodes(displayItems, unitsMapper, currentProjectVersion);
            return metricsRootNode;
        }
    )

    const comparisonItemsUiLazy = LazyDerived.new3<
        MetricGroupNode,
        boolean,
        number,
        MetricGroupNode
    >(
        "comparison-items-ui-lazy",
        null,
        [projectComparison.isRelativeMode, projectComparison.selectedRelativeItemIdx, metricsUiNodesLazy],
        ([isRelativeMode, checkedIdx, metricsUi]) => {
            if (checkedIdx >= comparison.items.size) {
                projectComparison.selectedRelativeItemIdx.replaceWith(0);
            }
            const relativeMode: RelativeModeType | undefined = isRelativeMode
                ? {
                    units: Array.from(unitsMapper.converter.getUnitsByMeasure("price").keys()),
                    baseValueIdx: checkedIdx,
                    updateBaseValueIdx: selectRelativeItem,
                }
                : undefined;
            
            return relativeMode ? metricsUi.newWithRelativeMode(relativeMode) : metricsUi;
        },
    );

    function selectRelativeItem(idx: number) {
        projectComparison.selectedRelativeItemIdx.replaceWith(idx);
    }

    const selectVersion = createSelectVersionPui(projectComparison);
</script>

<EngineUiPanel>
    <div class="button-wrapper">
        <PUI_Root configIdentifier='edit catalogItem' puiSource={selectVersion} />
    </div>
    <MetricsRoot metricsUi={comparisonItemsUiLazy} />
</EngineUiPanel>

<style lang="scss">
    .button-wrapper {
        padding: 0 8px;
    }
</style>
