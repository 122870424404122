import type { TypeIdentifier } from "./dataModels";

export interface PVObject{
    Flags: string;
}

export class pvShading implements PVObject{
    Flags:string;
    Comment:string;
    Version:string;
    NOrient_Shd:number;
    Precision:number;
    FracOmbreMod:number;
    FracOmbreThin:number;
    OrientTolerance:number;
    IsBacktracking:string;
    Pitch:number;
    LargCapt:number;
    LargHaut:number;
    LargBas:number;
    BTNoTrackers:string;
    GCROffset:number;
    DisplayedRef:string;
    ListeObjets:Array<PVObject> | null;

    constructor(projectName:string, trackers:Array<PVObject>){
       
        this.Comment = projectName;
        this.Version = "7.2.8";
        this.Flags = "$00";
        this.NOrient_Shd = 0;
        this.Precision = 1;
        this.FracOmbreMod = 1.0000000;
        this.FracOmbreThin = 0.4000000;
        this.OrientTolerance = 1.0;
        this.IsBacktracking = "$00";
        this.Pitch = 6.600;
        this.LargCapt = 3.000;
        this.LargHaut = 0.020;
        this.LargBas = 0.020;
        this.BTNoTrackers = "-1,-1,";
        this.GCROffset = 1.0;
        this.DisplayedRef = "refGeographical";
        this.ListeObjets = trackers;
    }
}

//Array of shields
export class pvShdArraySunShield implements PVObject{
    Flags: string;
    Comment:string;
    Version:string;
    // element index
    NoObjCreated:number;
    NElements:number;
    // Border color
    ColorTechn:PVColor;
    ColorBord:PVColor;
    ColorSurf:PVColor;
    DOrigine:Coordinate;
    DInclin:number;
    // Rotation of the array of panels
    DAzimut:number;
    Source:string;
    // number of trackers
    NElemChamps:number;
    NoOrient:number;
    // Width of the panel
    LongShed:number;
    // Length of the panel
    LargShed:number;
    // Frame offset of panel
    Cadre:Frame;
    // Type of axis pivot
    TypeOrigine:Axis;
    // Distance between panels in ns direction
    PitchNS:number;
    // Distance between panels in ew direction
    PitchEW:number;
    PitchSlope:number;

    constructor(i:number, arrayData:ArrayData){
        
        let frame = new Frame();

        this.Comment = "Sun-Shield field";
        this.Version = "7.2.8";
        this.Flags = "$02200014";
        this.NoObjCreated = i;
        this.NElements = 6;
        this.ColorTechn = arrayData.Color;
        this.ColorSurf = arrayData.Color;
        this.ColorBord = new PVColor(80, 80, 80);
        this.DOrigine = arrayData.Coordinate;
        this.DInclin = arrayData.Tilt;
        this.DAzimut = arrayData.Angle;
        this.Source = "osPVsyst";
        this.NElemChamps = arrayData.NElemChamps;
        this.NoOrient = 0;
        this.LongShed = arrayData.Length;
        this.LargShed = arrayData.Width;
        this.Cadre = frame;
        this.TypeOrigine = Axis.RectangleCener;
        this.PitchNS = arrayData.Pitch;
        this.PitchEW = 0;
        this.PitchSlope = arrayData.Slope;
    }
}

//Array of tables
export class pvShdArrayShed implements PVObject{
    Flags: string;
    Comment:string;
    Version:string;
    // element index
    NoObjCreated:number;
    NElements:number;
    // Border color
    ColorTechn:PVColor;
    ColorBord:PVColor;
    ColorSurf:PVColor;
    DOrigine:Coordinate;
    DInclin:number;
    // Rotation of the array of panels
    DAzimut:number;
    Source:string;
    // number of trackers
    NElemChamps:number;
    NoOrient:number;
    // Width of the panel
    LongShed:number;
    // Length of the panel
    LargShed:number;
    // Frame offset of panel
    Cadre:Frame;
    // Type of axis pivot
    TypeOrigine:Axis;
    // Distance between panels in ns direction
    PitchNS:number;
    // Distance between panels in ew direction
    PitchEW:number;
    //Shed to shed slope
    BaseSlope:number;

    constructor(i:number, arrayData:ArrayData){
        
        let frame = new Frame();

        this.Comment = "Shed field";
        this.Version = "7.2.8";
        this.Flags = "$02200004";
        this.NoObjCreated = i;
        this.NElements = 6;
        this.ColorTechn = arrayData.Color;
        this.ColorSurf = arrayData.Color;
        this.ColorBord = new PVColor(80, 80, 80);
        this.DOrigine = arrayData.Coordinate;
        this.DInclin = arrayData.Tilt;
        this.DAzimut = arrayData.Angle;
        this.Source = "osPVsyst";
        this.NElemChamps = arrayData.NElemChamps;
        this.NoOrient = 0;
        this.LongShed = arrayData.Length;
        this.LargShed = arrayData.Width;
        this.Cadre = frame;
        this.TypeOrigine = Axis.RectangleCener;
        this.PitchNS = arrayData.Pitch;
        this.PitchEW = 0;
        this.BaseSlope = arrayData.Slope;
    } 
}

//Array of trackers
export class pvShdArrayTracking implements PVObject{
    Flags:string;
    Comment:string;
    Version:string;
    // Index of panel array
    NoObjCreated:number;
    NElements:number;
    // Color of the border
    ColorTechn:PVColor;
    ColorSurf:PVColor;
    ColorBord:PVColor;
    DOrigine:Coordinate;
    DInclin:number;
    // Rotation of the array of panels
    DAzimut:number;
    Source:string;
    // number of trackers
    NElemChamps:number;
    NoOrient:number;
    // Width of the panel
    LongShed:number;
    // Length of the panel
    LargShed:number;
    // Frame offset of panel
    Cadre:Frame;
    // Type of axis pivot
    TypeOrigine:Axis;
    //  Distance between panels in ns direction
    PitchNS:number;
    // Distance between panels in ew direction
    PitchEW:number;
    PVObject:PVObject;

    constructor(i:number, arrayData:ArrayData){

        let frame = new Frame();
        let plane = new pvCollPlane();
        plane.InclAxe = arrayData.Slope;
        
        this.Comment = "Array of trackers";
		this.Version = "7.2.8";
        this.Flags = "$02200014";
        this.NoObjCreated = i;
        this.NElements = 9;
        this.ColorTechn = arrayData.Color;
        this.ColorSurf = new PVColor(153, 153, 255);
        this.ColorBord = new PVColor(80, 80, 80);
        this.DOrigine = arrayData.Coordinate;
        this.DInclin = arrayData.Tilt;
        this.DAzimut = arrayData.Angle;
        this.Source = "osPVsyst";
        this.NElemChamps = arrayData.NElemChamps;
        this.NoOrient = 0;
        this.LongShed = arrayData.Width;
        this.LargShed = arrayData.Length;
        this.Cadre = frame;
        this.TypeOrigine = Axis.RectangleCener;
        this.PitchNS = 0;
        this.PitchEW = arrayData.Pitch;
        this.PVObject = plane;
    }
}


//Plane for trackers
class pvCollPlane implements PVObject{
    Flags: string = "$0014";
    FieldType:string = "TrackTiltedAxis";
    IsDefined:boolean = true;
    TranspositionAlgorithm:string = "CircSolSeparate";
    InclMin:number = 10;
    InclMax:number = 80;
    AzimMin:number = -60;
    AzimMax:number = 60;
    // tilt angle in degrees
    InclAxe:number = 0;
    // Azimuth angle of each panel
    AzimAxe:number = 0;


}

//color class
export class PVColor {
    R: number = 255;
    G: number = 255;
    B: number = 255;

    constructor(r: number, g: number, b: number) {
        this.R = r;
        this.G = g;
        this.B = b;
    }

    toString(): string {
        return `$00${this.hexColour(this.B)}${this.hexColour(this.G)}${this.hexColour(this.R)}`;
    }

    hexColour(c: number): string {
        if (c < 256) {
            return Math.abs(c).toString(16);
        }
        return '0';
    }
    equals(other: PVColor): boolean {
        return this.R === other.R && this.G === other.G && this.B === other.B;
    }
}

class Frame{
    Left:number = 0;
    Right:number = 0;
    Top:number = 0;
    Bottom:number = 0;

    toString():string{
        let data =[this.Left.toFixed(3), 
                   this.Right.toFixed(3), 
                   this.Top.toFixed(3), 
                   this.Bottom.toFixed(3)];
        return data.join(",")+",";
    }
}


export class Coordinate{
    X:number = 0;
    Y:number = 0;
    Z:number = 0;

    constructor(x:number, y:number, z:number){
        this.X = -x;
        this.Y = -y;
        this.Z = z;
    }

    toString():string{
        let data = [this.X.toFixed(3), 
                    this.Y.toFixed(3), 
                    this.Z.toFixed(3)];

        return data.join(",")
    }
}


enum Axis{
    LeftLowerCorner = 1,
        LowerCenter,
        RightLowerCorner,
        LeftUpperCorner,
        UpperCeter,
        RightUpperCorner,
        RectangleCener
}

enum Orientation {
    moLandScape,
    moPortrait
}


export interface ArrayData {
    TypeIdentifier:TypeIdentifier;
    // Distanse between elements in East-Weast direction
    //PitchEW:number;
    // Distanse between elements in North-South direction
    //PitchNS:number;
    //Distanse between elements
    Pitch:number;

    //
    Tilt:number;

    // Number of elements
    NElemChamps:number;

    // Array origin
    Coordinate:Coordinate;

    // Array rotation angle
    Angle:number;

    // Tracker width
    Width:number;

    // Tracker length
    Length:number;

    //Tracker color
    Color:PVColor;

    Slope:number;
}

export class Describer{
    Shading:PVObject;

    constructor(obj:PVObject){
        this.Shading = obj;
    }

    toString(index:string, level:number):string{

        const div = ' ';

        let result = `${div.repeat(level)}PVObject_${index}=${this.Shading.constructor.name}\n`;

        for (let [key, value] of Object.entries(this.Shading)) {
            //let propType = Object.getOwnPropertyDescriptor(this.Shading, key)?.value;

            if(value.constructor.name === 'pvCollPlane'){
                let temDescriber = new Describer(value as PVObject);
                //TODO chech if null
                result += temDescriber.toString('PlanSuiv',level*2);

                result +=`${div.repeat(level)}End of PVObject pvCollPlane\n`;

            } else if(Array.isArray(value)){

                let elements:PVObject[] = value as PVObject[];
                result +='\n\n';     

                let pos = 1;
                let trackersDiscription = ''
                
                for(let i in elements){

                    const shd = elements[i] as PVObject;
                    const name = shd.constructor.name
                    if(name === 'pvShdArrayTracking' || name === 'pvShdArrayShed' || name === 'pvShdArraySunShield'){
                        trackersDiscription += '\n';
                        let arrDescriber = new Describer(shd);
                        trackersDiscription += arrDescriber.toString(pos.toString(), level*2);
                        trackersDiscription += `${div.repeat(level*2)}End of PVObject ${name}`;
                        trackersDiscription += '\n';
                        pos+=1;              
                    }
                }

                result += `${div.repeat(level)}${key}, list of=${pos} TShdObjec\n`;
                result += trackersDiscription;
                result +='\n'
                result +=`${div.repeat(level)}End of ListeObjets`;

            
            }else{               
                const val = value.toString();
                result += `${div.repeat(level)}${key}=${val}\n` 
            }            
          }
        

        return result;
    }
}