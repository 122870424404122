import type { Matrix4} from "math-ts";
import { Vector3 } from "math-ts";
import type { ScopedLogger} from "engine-utils-ts";
import { IterUtils, ObjectUtils, PollablePromise, Success, WorkerClassPassRegistry, WorkerPool, Yield } from "engine-utils-ts";
import { TerrainHeightMapRepresentation } from "../../representation/Representations";
import type { AnyBimGeometry } from "../../geometries/BimGeometries";
import type { TerrainGeoVersionSelector, TerrainTileId } from "../TerrainTile";
import { IrregularHeightmapGeometry } from "../../geometries/IrregularHeightmapGeometries";
import { RegularHeightmapGeometry } from "../../geometries/RegularHeightmapGeometry";
import { TerrainMetricsIrregular } from "./TerrainMetricsIrregular";
import { TerrainMetricsRegular } from "./TerrainMetricsRegular";
import type { Bim } from "../../Bim";
import type { IdBimScene, SceneInstance } from "../../scene/SceneInstances";
import type { CutFillHeatmapCalcJobArgs} from "../cut-fill/CutFillHeatmapCalculationExecutor";
import { CutFillHeatmapCalculationExecutor } from "../cut-fill/CutFillHeatmapCalculationExecutor";


export class TerrainMetricsRanges {
    constructor(
        public readonly type: TerrainMetricsType,
        public readonly units: string,
        public readonly slices: number[],
    ) {
    }
}

interface Range {
    min: number;
    max: number;
}

export enum TerrainMetricsType {
    Elevation,
    Slope,
    CutFill,
}

export enum TerrainDisplaySlopeSelector {
	NS,
	EW,
}



export class TerrainMetrics {

    public readonly palette: TerrainMetricsRanges;
    public readonly perSliceArea: number[];
    public readonly totalArea: number;
    public readonly fullRange: Range;

    public readonly perSliceVolume: number[] | undefined;
    public readonly totalVolume: number | undefined;

    constructor(
        palette: TerrainMetricsRanges,
        perSliceArea: number[],
        totalArea: number,
        fullRange: Range,
        perSliceVolume?: number[],
        totalVolume?: number
    ) {
        this.palette = palette;
        this.perSliceArea = perSliceArea;
        this.perSliceVolume = perSliceVolume;
        this.totalArea = totalArea;
        this.totalVolume = totalVolume;
        this.fullRange = fullRange;
    }

    static emptyForPalette(palette: TerrainMetricsRanges) {
        return new TerrainMetrics(palette, palette.slices.map(s => 0), 0, {min: 0, max: 0});
    }

    mergedWith(metrics: TerrainMetrics) {
        if (!ObjectUtils.areObjectsEqual(metrics.palette, this.palette)) {
            throw new Error(`attempt to merge metrics of differing palettes`);
        }

        let perSliceVolume: number[] | undefined = undefined, totalVolume: number | undefined = undefined;
        if (this.perSliceVolume !== undefined && this.totalVolume !== undefined) {
            perSliceVolume = this.perSliceVolume;
            totalVolume = this.totalVolume;
            if (metrics.perSliceVolume !== undefined && metrics.totalVolume !== undefined) {
                perSliceVolume = perSliceVolume.map((volume, index) => volume + metrics.perSliceVolume![index]);
                totalVolume += metrics.totalVolume;
            }
        } else if (metrics.perSliceVolume !== undefined && metrics.totalVolume !== undefined) {
            perSliceVolume = metrics.perSliceVolume;
            totalVolume = metrics.totalVolume;
        }

        const fullRange = {
            min: metrics.fullRange.min < this.fullRange.min ? metrics.fullRange.min : this.fullRange.min,
            max: metrics.fullRange.max > this.fullRange.max ? metrics.fullRange.max : this.fullRange.max
        };

        return new TerrainMetrics(
            this.palette,
            this.perSliceArea.map((area, index) => area + metrics.perSliceArea[index]),
            this.totalArea + metrics.totalArea,
            fullRange,
            perSliceVolume,
            totalVolume
        );
    }

    static calcuateBimObjsAreaMetricsForElevation = function* (
        logger: ScopedLogger,
        palette: TerrainMetricsRanges,
        bim: Bim,
        objs: [IdBimScene, SceneInstance][],
        geoSelector: TerrainGeoVersionSelector,
    ): Generator<Yield, TerrainMetrics> {
        const usableGeometries = TerrainMetrics._mapBimObjsToUsableGeometries(
            logger,
            bim,
            objs,
            geoSelector
        );
        yield Yield.Asap;
        let totalMetrics = TerrainMetrics.emptyForPalette(palette);
        for (const {worldMatrix, tileSize, perTileGeometries} of usableGeometries) {
            for (const [tileId, geo] of perTileGeometries) {
                let metricsForGeo: TerrainMetrics;
                if (geo instanceof RegularHeightmapGeometry) {
                    metricsForGeo = TerrainMetricsRegular.calcuateAreaMetricsForElevation(
                        palette, geo, worldMatrix
                    );
                } else if (geo instanceof IrregularHeightmapGeometry) {
                    metricsForGeo = TerrainMetricsIrregular.calcAreaMetricsForElevation(
                        palette, geo, worldMatrix
                    );
                } else {
                    continue;
                }
                totalMetrics = totalMetrics.mergedWith(metricsForGeo);
                yield Yield.Asap;
            }
        }
        return totalMetrics;
    }

    static calcuateBimObjsAreaMetricsForSlope = function* (
        logger: ScopedLogger,
        palette: TerrainMetricsRanges,
        bim: Bim,
        objs: [IdBimScene, SceneInstance][],
        geoSelector: TerrainGeoVersionSelector,
        slopeSelector: TerrainDisplaySlopeSelector
    ): Generator<Yield, TerrainMetrics> {
        
        const usableGeometries = TerrainMetrics._mapBimObjsToUsableGeometries(
            logger,
            bim,
            objs,
            geoSelector
        );
        yield Yield.Asap;
        let totalMetrics = TerrainMetrics.emptyForPalette(palette);
        for (const {worldMatrix, tileSize, perTileGeometries} of usableGeometries) {
            for (const [tileId, geo] of perTileGeometries) {
                let metricsForGeo: TerrainMetrics;
                if (geo instanceof RegularHeightmapGeometry) {
                    metricsForGeo = TerrainMetricsRegular.calcuateAreaMetricsForSlopeTo(
                        palette, geo, worldMatrix, slopeSelector
                    );
                } else if (geo instanceof IrregularHeightmapGeometry) {
                    metricsForGeo = TerrainMetricsIrregular.calculateAreaMetricsForSlope(
                        palette, geo, worldMatrix, slopeSelector
                    );
                } else {
                    continue;
                }
                totalMetrics = totalMetrics.mergedWith(metricsForGeo);
                yield Yield.Asap;
            }
        }
        return totalMetrics;
    }

    static areaMetricsFromCutfillHeatmap(
        heatmap: PerTileCutFillHeatmap,
        palette: TerrainMetricsRanges,
    ): TerrainMetrics {
        
        const perSliceArea = palette.slices.map(_ => 0);
        const perSliceVolume = palette.slices.map(_ => 0);
        let totalArea = 0, totalVolume = 0, currRange = {min: Infinity, max: -Infinity};
        const segmentArea = heatmap.stepSizeMeters * heatmap.stepSizeMeters;

        for (let iy = 0; iy < heatmap.heatmapSize; iy++) {
            for (let ix = 0; ix < heatmap.heatmapSize; ix++) {
                const flatIndex = iy * heatmap.heatmapSize + ix;
                
                if (heatmap.areasMult[flatIndex] === 0) {
                    continue;
                }

                const cutFillInM = 0.01 * heatmap.cutFillInCm[flatIndex];
                const area = heatmap.realArea(flatIndex);
                const volume = Math.abs(cutFillInM) * segmentArea;
                const slicesIndices = paletteSlicesIndicesFromValue(cutFillInM, palette.slices);

                if (slicesIndices instanceof Array) {
                    perSliceArea[slicesIndices[0]] += 0.5 * area;
                    perSliceArea[slicesIndices[1]] += 0.5 * area;
                    
                    perSliceVolume[slicesIndices[0]] += 0.5 * volume;
                    perSliceVolume[slicesIndices[1]] += 0.5 * volume;
                } else if (slicesIndices >= 0) {
                    perSliceArea[slicesIndices] += area;
                    perSliceVolume[slicesIndices] += volume;
                }

                totalArea += area;
                totalVolume += volume;
                
                if (cutFillInM < currRange.min) {
                    currRange.min = cutFillInM;
                }

                if (cutFillInM > currRange.max) {
                    currRange.max = cutFillInM;
                }
            }
        }

        return new TerrainMetrics(palette, perSliceArea, totalArea, currRange, perSliceVolume, totalVolume);
    }
    
    static calcuateBimObjsMetricsForCutfill = function* (
        logger: ScopedLogger,
        palette: TerrainMetricsRanges,
        bim: Bim,
        objs: [IdBimScene, SceneInstance][],
    ): Generator<Yield, TerrainMetrics> {
        
        const objsGeometries = IterUtils.filterMap(objs, ([id, tObj]) => {
            if (tObj.representation instanceof TerrainHeightMapRepresentation) {
                const tilesGeometries: [TerrainTileId, Readonly<AnyBimGeometry>, Readonly<AnyBimGeometry>][] = [];
                for (const [tileId, tile] of tObj.representation.tiles) {
                    if (!tile.updatedGeo) {
                        continue;
                    }
                    const geo1 = bim.allBimGeometries.peekById(tile.initialGeo);
                    const geo2 = bim.allBimGeometries.peekById(tile.updatedGeo);
                    if (!geo1 || !geo2) {
                        continue;
                    }
                    tilesGeometries.push([tileId, geo1, geo2]);
                }
                return {
                    id: id,
                    worldMatrix: tObj.worldMatrix,
                    tileSize: tObj.representation.tileSize,
                    tilesGeometries: tilesGeometries,
                };
            } else {
                logger.batchedError(`invalid obj representation`, tObj.representation);
                return undefined;
            }
        });
        yield Yield.Asap;


        const cutFillHeatmapsPromises = objsGeometries.flatMap(({id, worldMatrix, tileSize, tilesGeometries}) => {
            const heatmapsPromises: [IdBimScene, PollablePromise<PerTileCutFillHeatmap | null>][] = [];
            for (const [tileId, geo1, geo2] of tilesGeometries) {
                const heatmapCalcArgs: CutFillHeatmapCalcJobArgs = {
                    tileMaxAabb: tileId.aabb(tileSize),
                    initialGeo: geo1 as RegularHeightmapGeometry | IrregularHeightmapGeometry,
                    updatedGeo: geo2 as RegularHeightmapGeometry | IrregularHeightmapGeometry,
                };
                const heatmapPromise = WorkerPool.execute(CutFillHeatmapCalculationExecutor, heatmapCalcArgs);
                heatmapsPromises.push([id, new PollablePromise(heatmapPromise)]);
            }
            return heatmapsPromises;
        })
        yield Yield.Asap;

        let totalMetrics = TerrainMetrics.emptyForPalette(palette);
        
        for (const [instanceId, promise] of cutFillHeatmapsPromises) {

            const promiseResult = yield* promise.generatorWaitForResult();

            if (promiseResult instanceof Success) {
                const heatmap = promiseResult.value;
                if (heatmap) {
                    const metricsForTile = TerrainMetrics.areaMetricsFromCutfillHeatmap(heatmap, palette);
                    totalMetrics = totalMetrics.mergedWith(metricsForTile);
                }
            } else {
                logger.error(promiseResult.errorMsg());
            }
            yield Yield.Asap;
        }

        return totalMetrics;
    }

    private static _mapBimObjsToUsableGeometries(
        logger: ScopedLogger,
        bim: Bim,
        objs: [IdBimScene, SceneInstance][],
        geoSelector: TerrainGeoVersionSelector,
    ): { worldMatrix: Matrix4; tileSize: number; perTileGeometries: [TerrainTileId, Readonly<AnyBimGeometry>][];  }[] {
        const objsGeometries = IterUtils.filterMap(objs, ([id, tObj]) => {
            if (tObj.representation instanceof TerrainHeightMapRepresentation) {
                const perTileGeometries: [TerrainTileId, Readonly<AnyBimGeometry>][] = [];
                for (const [tileId, tile] of tObj.representation.tiles) {
                    const geoId = tile.selectGeoId(geoSelector);
                    const geo = bim.allBimGeometries.peekById(geoId);
                    if (!geo) {
                        continue;
                    }
                    perTileGeometries.push([tileId, geo]);
                }
                return {
                    worldMatrix: tObj.worldMatrix,
                    tileSize: tObj.representation.tileSize,
                    perTileGeometries: perTileGeometries,
                };
            } else {
                logger.batchedError(`invalid obj representation`, tObj.representation);
                return undefined;
            }
        });
        return objsGeometries;
    }
}

export class PerTileCutFillHeatmap {

    constructor(
        public readonly stepSizeMeters: number,
        public readonly sideSizeMeters: number,
        public readonly heatmapSize: number,
        public readonly cutFillInCm: Int8Array | Int16Array | Int32Array,
        public readonly areasMult: Uint16Array
        // realArea[i] = stepSizeMeters * stepSizeMeters * (0.9999 + 0.0001 * areasMult[i])
        // 0 means absence
    ) {
    }

    static newFromFloats(
        stepSizeMeters: number,
        sideSizeMeters: number,
        heatmapSize: number,
        cutFillInMeters: Float32Array,
        averageAreas: Float32Array
    ) {
        let maxAbs = 0;
        for (const v of cutFillInMeters) {
            if (Number.isFinite(v)) {
                maxAbs = Math.max(maxAbs, Math.round(Math.abs(v * 100)));
            }
        }
        let resultInCM: Int8Array | Int16Array | Int32Array;
        if (maxAbs <= ((0xFF / 2) | 0)) {
            resultInCM = new Int8Array(cutFillInMeters.length);

        } else if (maxAbs <= ((0xFFFF / 2) | 0)) {
            resultInCM = new Int16Array(cutFillInMeters.length);

        } else if (maxAbs <= ((0xFFFFFFFF / 2) | 0)) {
            resultInCM = new Int32Array(cutFillInMeters.length);

        } else {
            throw new Error('could not choose cutt fill array type, heatmap is invalid')
        }

        for (let i = 0; i < cutFillInMeters.length; ++i) {
            const v = cutFillInMeters[i];
            if (Number.isFinite(v)) {
                resultInCM[i] = Math.round(v * 100);
            }
        }

        const baseArea = stepSizeMeters * stepSizeMeters;
        let areasCompressed = new Uint16Array(averageAreas.length);
        for (let i = 0; i < averageAreas.length; ++i) {
            const a = averageAreas[i];
            if (Number.isFinite(a)) {
                areasCompressed[i] = Math.min(Math.round(10000 * ((a / baseArea) - 0.9999)), 0xFFFF);
            } else {
                areasCompressed[i] = 0;
            }
        }

        return new PerTileCutFillHeatmap(
            stepSizeMeters,
            sideSizeMeters,
            heatmapSize,
            resultInCM,
            areasCompressed
        );
    }

    realArea(index: number): number {
        return this.stepSizeMeters * this.stepSizeMeters * (0.9999 + 0.0001 * this.areasMult[index]);
    }
}
WorkerClassPassRegistry.registerClass(PerTileCutFillHeatmap);


export function paletteSlicesIndicesFromValue(value: number, slices: number[]): number | [number, number] {
    if (value < slices[0]) {
        return -1;
    }

    let max;
    for (let sliceI = 0; sliceI < slices.length - 1; ++sliceI) {
        max = slices[sliceI + 1];
        if (value < max) {
            return sliceI;
        } else if (value === max) {
            if (sliceI === slices.length - 2) {
                return sliceI;
            } else {
                return [sliceI, sliceI + 1];
            }
        }
    }

    return -1;
}


export function calcTriangleAreaByPoints(p1: Vector3, p2: Vector3, p3: Vector3) {
    const v12 = p2.clone().sub(p1);
    const v13 = p3.clone().sub(p1);

    return calcTriangleAreaByVectors(v12, v13);
}


export function calcTriangleAreaByVectors(a: Vector3, b: Vector3) {
    return 0.5 * Vector3.crossVectors(a, b).length();
}