import { CostModel as CM, StringProperty } from 'bim-ts';
import { LazyDerived, LazyVersioned, ResultAsync, Success, Immer } from "engine-utils-ts";
import { ContextMenuStructure, NamedCheckbox } from "src/libui/context-menu";
import { Checkbox } from 'src/libui/checkbox';

export const createTableSettingsMenu = (
    costTable: LazyVersioned<CM.CostTable | undefined>,
    costModel: LazyVersioned<ResultAsync<CM.CostModel>>,
) => {
    const tableMenu = LazyDerived.new2<
        ContextMenuStructure,
        CM.CostTable | undefined,
        ResultAsync<CM.CostModel>
    >(
        'tableMenu',
        [],
        [costTable, costModel],
        ([selectedTable, costModelResult]) => {
            const structure = new ContextMenuStructure([], false);
            const table = selectedTable;

            const settings = selectedTable?.settings;
            if (!settings || !(costModelResult instanceof Success) || !table) {
                return structure;
            }

            const costModel = costModelResult.value;
            const items = createHiddableColumnGroupHierarchy(
                selectedTable.hiddableColumns,
                1,
                new Set(table.alwaysHiddenColumns),
                settings,
                costModel
            );
            structure.items.push(...items);

            return structure;
        }
    )
    return tableMenu
}

function createHiddableColumnGroupHierarchy(
    groupIds: string[],
    indent: number,
    alwaysHiddenColumns: Set<string>,
    settings: CM.TableSettings,
    costModel: CM.CostModel,
) {
    const menuItems: NamedCheckbox[] = [];
    for (const id of groupIds) {
        const group = CM.HiddableColumnGroups.get(id);
        if (!group) {
            continue;
        }
        if (group.columns.every(x => alwaysHiddenColumns.has(x))) {
            continue;
        }
        const checked = !settings.hiddenColumnGroupIds.find(x => x.value === id);
        menuItems.push(new NamedCheckbox(
            ' '.repeat(indent) + group.display,
            new Checkbox(
                checked,
            ),
            () => costModel.updateTableSettings(
                settings.id.value,
                () => Immer.produce(settings, draft => {
                    const shouldAdd = checked;
                    if (shouldAdd) {
                        draft.hiddenColumnGroupIds.push(StringProperty.new({ value: id }))
                    } else {
                        draft.hiddenColumnGroupIds = draft.hiddenColumnGroupIds.filter(x => x.value !== id)
                    }
                })
            )
        ))
        if (group.subMenuItems.length) {
            const subItems = createHiddableColumnGroupHierarchy(group.subMenuItems, indent + 1, alwaysHiddenColumns, settings, costModel);
            menuItems.push(...subItems);
        }
    }
    return menuItems;
}

