import type { ICellRendererComp, ICellRendererParams } from 'ag-grid-enterprise';

export class ActionRenderer implements ICellRendererComp {
  eGui!: HTMLDivElement;
  eButton: any;
  eventListener!: () => void;

  // gets called once before the renderer is used
  init(params: ICellRendererParams) {
    // create the cell
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `
            <button class="btn-simple"></button>
       `;
    this.eGui.style.height = "100%";
    this.eGui.style.width = "100%";
    this.eGui.style.display = "flex";

    // get references to the elements we want
    this.eButton = this.eGui.querySelector('.btn-simple');
    this.eButton.style.height = "100%";
    this.eButton.style.width = "100%";
    this.eButton.style.border = '0';
    this.eButton.style.textAlign = "start";
    this.eButton.style.background = "rgba(0,0,0,0)";
    
    // set value into cell
    this.eButton.innerHTML = params.value?.name;
    
    // add event listener to button
    this.eventListener = () => params.value?.action();
    this.eButton.addEventListener('click', this.eventListener);
  }

  getGui() {
    return this.eGui;
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams) {
    // set value into cell again
    this.eButton.innerHTML = params.value?.name;

    // return true to tell the grid we refreshed successfully
    return true;
  }

  // gets called when the cell is removed from the grid
  destroy() {
    // do cleanup, remove event listener from button
    if (this.eButton) {
      // check that the button element exists as destroy() can be called before getGui()
      this.eButton.removeEventListener('click', this.eventListener);
    }
  }
}