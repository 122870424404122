<script lang="ts">
    import type { PUI_PropertyNodeString } from "ui-bindings";
    import Lock from '../libui/icon/svg/lock.svelte';
    import flash from "./flash";
    import { VersionedStore } from 'src/VersionedStore';

    export let property: PUI_PropertyNodeString;

    const tag = property.tag ? new VersionedStore(property.tag) : undefined;
    let div: HTMLElement;
    let preValue = property.value;
    $: visibleValue = property.value ?? "-";
    $: {
        if (preValue != property.value) {
            preValue = property.value;
            flash(div);
        }
    }
    $: isTextarea = property.isTextarea;
    
    $: error = property.hasError();
</script>

<div 
    class="ui-config-property property-row" 
    class:red-border={!property.calculated && !!error} 
    class:not-active = {property.notActive}
    >
    <div bind:this={div} class="property-name text-main-medium">
        <div class="property-name-label mdc-typography--body1">
            {property.name}
        </div>
    </div>

    <div class="property-value">
        {#if property.readonly || property.calculated}
            <div
                class="mdc-typography--body1 read-only"
                class:text-danger={!!error}
                class:calculated={property.calculated}
                class:multiline={isTextarea}
            >
                {visibleValue}
                <div class="icon-holder">
                    {#if tag}
                        <span title={$tag} class="text-main-light tag-holder">
                            {$tag}
                        </span>
                    {/if}
                    {#if !property.calculated}
                        <Lock />
                    {/if}
                </div>
            </div>
        {:else}
            <div
                class="edit-field-container"
                class:edit-textarea-container={isTextarea}
            >
                {#if !isTextarea}
                    <input
                        value={visibleValue}
                        on:input={(e) => {
                            property.value = e.currentTarget.value;
                        }}
                        class="
                            config-ui-input-field
                            mdc-typography--body1
                        "
                    />
                    {#if $tag}
                        <span title={$tag} class="text-main-light tag-holder">
                            {$tag}
                        </span>
                    {/if}
                {:else}
                    <textarea
                        class="mdc-typography--body1 text-main-dark"
                        rows="3"
                        placeholder={property.placeholder}
                        bind:value={property.value}
                    />
                {/if}
            </div>
        {/if}
    </div>
</div>

<style lang="scss">
    ::placeholder {
        color: var(--color-main-light);
        opacity: 1;
    }
    .red-border {
        border-style: solid;
        border-color: rgb(255, 0, 0, 0.5);
    }
</style>
