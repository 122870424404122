<script lang="ts">
import type { CatalogItemManager, CatalogItemManagerHierarchy, CatalogItemView } from "./CatalogItemManager";
import ListItemContent from '../libui/list/ListItem.svelte';
import { ListItem } from "../libui/list";
import { Action, ContextMenuStructure } from "../libui/context-menu";
import { stringifyProps, type AssetCatalogItemProps, type AssetRelatedCatalogItem, type Catalog, type UnitsMapper } from "bim-ts";
import { getContext } from "svelte";
import { downloadFile } from "../exports/verdata/DownloadFile";
import { ChipsInput } from "../libui/chips";
import type { VersionedStore } from "../VersionedStore";
import { Icon } from "../libui/icon";

const catalogItemManager = getContext<VersionedStore<CatalogItemManager>>('catalogItem-manager');
const unitsMapper = getContext<VersionedStore<UnitsMapper>>('unitsMapper');

const catalog = getContext<Catalog>('catalog');

export let hierarchy: CatalogItemManagerHierarchy;

$: isTopLevelHierarchy = hierarchy.path.length === 0;

let children: Array<{
    title: string,
    child: CatalogItemManagerHierarchy;
}> = [];

$: {
    const newChildren: typeof children = [];
    for (const [key, child] of hierarchy.children) {
        newChildren.push({
            title: stringifyProps(key, $unitsMapper),
            child,
        })
    }
    newChildren.sort((l, r) => l.title.localeCompare(r.title));
    children = newChildren;
}

function CatalogItemViewToListItem(item: CatalogItemView) {
    let contextMenu: ContextMenuStructure | undefined = undefined;
    const chips: string[] = [];
    const isAssetRelated = 'asset_id' in item.catalogItem.properties; 
    const assetId = !isAssetRelated
        ? undefined
        : item.catalogItem.as<AssetRelatedCatalogItem>().properties.asset_id.value;
    if (assetId) {
        const asset = catalog.assets.perId.get(assetId);
        contextMenu = new ContextMenuStructure([
            new Action('remove', () => {
                if (item.catalogItem.typeIdentifier === 'asset') {
                    const assetProps = item.catalogItem.as<AssetCatalogItemProps>();
                    catalog.removeAssets([assetProps.properties.asset_id.value]);
                } else {
                    catalog.removeCatalogItems([item.id]);
                }
                if (
                    $catalogItemManager.catalogItemEditMode &&
                    $catalogItemManager.catalogItemEditMode.id === item.id
                ) {
                    $catalogItemManager.cancelEditMode();
                }
            }),
            new Action('download', async () => {
                const assetCatalogItem = item.catalogItem.as<AssetCatalogItemProps>();
                const asset = catalog.assets.perId.get(
                    assetCatalogItem.properties.asset_id.value
                );
                if (!asset) {
                    return;
                }
                const name =
                    asset.name ||
                    catalog.catalogItemsUiLabels.solve(
                        assetCatalogItem.typeIdentifier,
                        assetCatalogItem.properties
                    )?.title ||
                    "asset";
                const fileName = name.replace(/\.bimasset/g, '') + '.bimasset';
                downloadFile(fileName, asset.bimasset);
            }),
        ]);
        if (asset) {
            if (asset?.source.type === 'default') {
                chips.push('default');
            } else if (asset.source.type === 'user') {
                chips.push("company's");
            } else {
                chips.push(asset.source.type);
            }
            if (!asset.name) {
                chips.push('autoname')
            }
            if ($catalogItemManager.createExtraMenuForAsset) {
                contextMenu.merge($catalogItemManager.createExtraMenuForAsset(assetId));
            }
        }
    }
    //if (item.catalogItemUiInfo.price) {
    //    chips.push(item.catalogItemUiInfo.price);
    //}
    return new ListItem(
        item.catalogItemUiInfo.title || '',
        undefined,//catalogItem.name,
        undefined,
        contextMenu,
        () => $catalogItemManager.setCatalogItemInEditMode(item.id),
        undefined,
        chips.length ? new ChipsInput(chips) : undefined,
        !assetId ? undefined : () => $catalogItemManager.itemDoubleClickAction?.(assetId),
    )
}


function CreateGroupListItem(item: CatalogItemManagerHierarchy, unitsMapper: UnitsMapper) {
    let name = stringifyProps(item.path.at(-1) ?? [], unitsMapper);

    return new ListItem(
        name,
        undefined,
        new Icon(item.hide ? 'ChevronRight' : 'ChevronDown'),
        undefined,
        () => $catalogItemManager.toggleHierarchyVisibility(item.path),
    )
}

function CreateGroupSingleItem(
    hierarchy: CatalogItemManagerHierarchy,
    unitsMapper: UnitsMapper
) {
    const item = hierarchy.allItems[0];
    const name = stringifyProps(hierarchy.path.at(-1) ?? [], unitsMapper);
    const overrideItem: typeof item = {
        ...item,
        catalogItemUiInfo: {
            ...item.catalogItemUiInfo,
            title: item.catalogItemUiInfo.title,
        }
    }
    return CatalogItemViewToListItem(overrideItem);
}

const offsetMultiplier = 15;


//function sortCatalogItemManagerHierarchy(
//    l: CatalogItemManagerHierarchy,
//    r: CatalogItemManagerHierarchy,
//) {
//    const lName = l.path.at(-1) ?? ''
//    const rName = l.path.at(-1) ?? ''
//    return lName.
//}

$: groupingItems = Array.from(hierarchy.children.values()).map(
    child => {
        const groupItem = CreateGroupListItem(child, $unitsMapper);
        return {
            groupItem,
            child,
        }
    }
).sort((l, r) => l.groupItem.text.localeCompare(r.groupItem.text));

function createItemComponents(items: CatalogItemView[]) {
    return items.map(x => ({
        itemComponent: CatalogItemViewToListItem(x),
    })).sort((l, r) => l.itemComponent.text.localeCompare(r.itemComponent.text));
}


</script>

{#each groupingItems as { child, groupItem }}
    <ListItemContent
      componentType={isTopLevelHierarchy ? 'h2' : 'h3'}
      item={groupItem}
      secondLine={false}
      offset={(child.path.length - 1) * offsetMultiplier}
    />
    <li role="separator" class="mdc-deprecated-list-divider" />
    {#if child.children.size}
        {#if !child.hide}
            <svelte:self hierarchy={child} />
        {/if}
    {:else if child.items.length}
        {#if !child.hide}
            {#each createItemComponents(child.items) as { itemComponent }}
                <ListItemContent
                  item={itemComponent}
                  secondLine={false}
                  offset={((child.path.length - 1) + 1) * offsetMultiplier}
                />
                <li role="separator" class="mdc-deprecated-list-divider" />
            {/each}
        {/if}
    {/if}
{/each}

<style lang="scss">
</style>
