import type {
    ArgsMerger, BasicDataSource, EventStackFrame, LazyVersioned, RefsCounter, UndoStack, VersionedValue
} from 'engine-utils-ts';

import { DefaultMap, LazyDerived, LogLevel, ObjectUtils, ObservableStream, peekCurrentEventFrame,
    ScopedLogger, unsafePopFromEventStackFrame, unsafePushToEventStackFrame
} from 'engine-utils-ts';
import type { EntityId, EntityIdAny} from 'verdata-ts';
import { IdsProvider, splitIdsPerType } from 'verdata-ts';

import { PolyEntitiesBase } from './PolyEntitiesBase';
import type { CollectionAdditionalContext } from '../persistence/CollectionAdditionalContext';
import { Allocated, Deleted } from 'engine-utils-ts';
import type { BimCollection, BimCollectionPatch } from './BimCollection';
import type { EntitiesCollectionUpdates} from './EntitiesCollectionUpdates';
import { EntitiesUpdated } from './EntitiesCollectionUpdates';
import { LocalIdsCounter } from './LocalIds';
import { SharedEntitiesInterner } from './SharedEntitiesInterner';

export interface CollectionReferenced<T, TReferenced> {
	collection: BimCollection<TReferenced, any> & {_addExternalRefsFrom: (cr: CollectionReferenced<any, any>) => void},
	idsFromState: (state: T, idsResult: EntityIdAny[]) => void,
	externalRefsChecker: RefsCounter<EntityIdAny>,
}
export interface CollectionExternalReferences {
	hasAnyRefsTo: (id: EntityIdAny) => boolean,
}

export interface EntitiesBaseConstructorParams<T, IdT, Diff extends number = number> {
	identifier: string,
	idsType: IdT | number,
	undoStack?: UndoStack,
	logger?: ScopedLogger,
	T_Constructor: { new(): T },
	diffFlagsToNotUndo?: Diff,
	collectionsRerenced?: CollectionReferenced<T, any>[],
	interner?: (entity: T) => string,
	internerIdsReserver?: () => EntityId<IdT>,
	localIdsMaximumExtractor?: (entity: T) => number,
}


export abstract class EntitiesBase<T, IdT, Diff extends number = number, Patch = Partial<T>>
	implements BimCollection<T, IdT, Patch>, BasicDataSource<T, EntityId<IdT>>, VersionedValue
{
	readonly logger: ScopedLogger;
	readonly undoStack: UndoStack | null;

	readonly identifier: string;
	readonly idsType: number;
	readonly idsProvider: IdsProvider<IdT>;

	readonly perId: Map<EntityId<IdT>, Readonly<T>>;

    readonly shared?: SharedEntitiesInterner<T, IdT> = undefined;

	readonly _localIdsMaximumExtractor: ((entity: T) => number) | undefined = undefined;
	readonly _localIdsCounters: DefaultMap<IdT, LocalIdsCounter> | undefined = undefined;

	protected _isLocked: boolean = false;
	readonly lockEvents: ObservableStream<boolean>;

	protected _version: number = 0;

	readonly diffFlagsToNotUndo: Diff;

	readonly updatesStream: ObservableStream<EntitiesCollectionUpdates<EntityId<IdT>, Diff>>;

	readonly _T_Contstrutor: { new(): T };

	private readonly collectionsReferenced: CollectionReferenced<T, any>[] | undefined;

	protected readonly referencedBaseCollectionsIdentifiers: Readonly<string[]>;

	protected _externalsRefsToThis: CollectionExternalReferences[] = [];
	

	protected _patchesRevertsMerge: ((earlyRevert: [EntityId<IdT>, Partial<T>][], laterRevert: [EntityId<IdT>, Partial<T>][])
		=> [EntityId<IdT>, Partial<T>][]) | undefined = undefined;

	constructor(params: EntitiesBaseConstructorParams<T, IdT, Diff>) {
		this.logger = params.logger ?
			params.logger.newScope(params.identifier) : new ScopedLogger(params.identifier, LogLevel.Default);
		this.undoStack = params.undoStack ?? null;
		this.identifier = params.identifier;
		this.idsType = params.idsType as number;
		this.idsProvider = new IdsProvider(params.idsType as number);
		this.perId = new Map();
		this.diffFlagsToNotUndo = params.diffFlagsToNotUndo ?? 0 as Diff;
		if (params.interner) {
			this.shared = new SharedEntitiesInterner(
				this, params.interner,
				params.internerIdsReserver ?? (() => this.idsProvider.reserveNewId())
			);
		}
		this.updatesStream = new ObservableStream({
			identifier: `${this.identifier}-updates-stream`,
			defaultValueForNewSubscribersFactory: () => {
				return new Allocated(Array.from(this.perId.keys()))
			}
		});
		this._T_Contstrutor = params.T_Constructor;
		this.collectionsReferenced = params.collectionsRerenced;
		this.referencedBaseCollectionsIdentifiers = this.allBaseCollectionsReferencedFirstHand().map(c => c.identifier);

		if (this.collectionsReferenced?.length) {
			for (const c of this.collectionsReferenced) {
				c.collection._addExternalRefsFrom(c);
			}
		}

		if (params.localIdsMaximumExtractor) {
			this._localIdsMaximumExtractor = params.localIdsMaximumExtractor;
			this._localIdsCounters = new DefaultMap(_ => { return new LocalIdsCounter()});
		}
		this.lockEvents = new ObservableStream({
			identifier: `${this.identifier}-lock-events`,
			defaultValueForNewSubscribersFactory: () => this._isLocked,
		});
	}

	toggleLock(locked: boolean) {
		this._isLocked = locked;
		this.lockEvents.pushNext(locked);
	}
	isLocked() {
		return this._isLocked;
	}

	_addExternalRefsFrom(cr: CollectionReferenced<any, any>) {
		this._externalsRefsToThis.push(cr.externalRefsChecker);
	}

	poll(): ReadonlyMap<EntityId<IdT>, T> {
		return this.perId;
	}

	version(): number {
		return this._version;
	}

	getCollectionContextLazy(): LazyVersioned<CollectionAdditionalContext> | undefined {
		if (!this.shared) {
			return undefined;
		}
		return LazyDerived.new0(
			this.identifier + '-lazy-context',
			[this.shared],
			() => {
				const res: CollectionAdditionalContext = {};
				if (this.shared) {
					res.sharedEntitiesIds = this.shared.getAllIds();
				};
				return res;
			}
		)
	}

	dispose() {
		this.clear();
		this.updatesStream.dispose();
	}

	fullFromPartial(partialState: Partial<T>): T | null {
		if (partialState instanceof this._T_Contstrutor) {
			return partialState as T;
		} else {
			const obj = new this._T_Contstrutor();
			for (const key in partialState) {
				const patchF = partialState[key];
				const objF = obj[key];
				if (objF !== undefined && patchF !== undefined) {
					(obj as any)[key] = partialState[key];
				}
			}
			return obj;
		}
	}

	makeShared(ids: Iterable<EntityId<IdT>>) {
		if (!this.shared) {
			throw new Error(`${this.identifier} makeShared unavailable`);
		}
		for (const id of ids) {
			const state = this.perId.get(id);
			if (state) {
				this.shared.markShared(id, state);
			}
		}
	}

	localIdsCounterFor(id: IdT) {
		if (this._localIdsCounters) {
			return this._localIdsCounters.getOrCreate(id);
		} else {
			this.logger.batchedError(`local ids counters are initialized`, id);
			return new LocalIdsCounter();
		}
	}

	_validateExternalReferences(t: T): boolean {
		if (this.collectionsReferenced) {
			reusedIdsArray.length = 0;
			for (const cr of this.collectionsReferenced) {
				let startIndex = reusedIdsArray.length;
				cr.idsFromState(t, reusedIdsArray);
				for (let i = startIndex; i < reusedIdsArray.length; ++i) {
					const refId = reusedIdsArray[i];
					const obj = cr.collection.peekById(refId);
					if (obj == undefined && refId !== 0) {
						this.logger.batchedError('invalid reference to', [cr.collection, refId, obj]);
						return false;
					}
				}
			}
		}
		return true;
	}
	_decrementExternalReferences(t: T): void {
		reusedIdsArray.length = 0;
		for (const cr of this.collectionsReferenced!) {
			let startIndex = reusedIdsArray.length;
			cr.idsFromState(t, reusedIdsArray);
			for (let i = startIndex; i < reusedIdsArray.length; ++i) {
				const refId = reusedIdsArray[i];
				cr.externalRefsChecker.decrement(refId);
			}
		}
	}
	_incrementExternalReferences(t: T): void {
		reusedIdsArray.length = 0;
		for (const cr of this.collectionsReferenced!) {
			let startIndex = reusedIdsArray.length;
			cr.idsFromState(t, reusedIdsArray);
			for (let i = startIndex; i < reusedIdsArray.length; ++i) {
				const refId = reusedIdsArray[i];
				cr.externalRefsChecker.increment(refId);
			}
		}
	}

	collectAllExternalBaseReferences(): {collection: EntitiesBase<any, any>, idsReferenced: EntityIdAny[]}[] {
		if (!this.collectionsReferenced) {
			return [];
		}
		const refs: {collection: BimCollection<any, any>, idsReferenced: EntityIdAny[]}[] =
			this.collectionsReferenced.map(cr => { return {collection: cr.collection, idsReferenced: []}});
		for (const state of this.perId.values()) {
			for (let i = 0; i < this.collectionsReferenced.length; ++i) {
				const cr = this.collectionsReferenced[i];
				const refsResult = refs[i];
				cr.idsFromState(state, refsResult.idsReferenced);
			}
		}
		const result:  {collection: EntitiesBase<any, any>, idsReferenced: EntityIdAny[]}[] = [];
		for (const ref of refs) {
			if (ref.collection instanceof EntitiesBase) {
				result.push({collection: ref.collection, idsReferenced: ref.idsReferenced});
			} else if (ref.collection instanceof PolyEntitiesBase) {
				const idsSplit = splitIdsPerType(ref.idsReferenced);
				for (const [ty, baseCollection] of ref.collection.entitiesByType) {
					const ids = idsSplit.get(ty) ?? [];
					result.push({collection: baseCollection, idsReferenced: ids});
				}
			} else {
				this.logger.error('unrecognized collection type', ref.collection);
			}
		}
		return result;
	}
	allBaseCollectionsReferencedFirstHand(): EntitiesBase<any, any>[] {
		const res: EntitiesBase<any, any>[] = [];
		if (!this.collectionsReferenced) {
			return res;
		}
		for (const {collection} of this.collectionsReferenced) {
			if (collection instanceof EntitiesBase) {
				res.push(collection);
			} else if (collection instanceof PolyEntitiesBase) {
				for (const cc of collection.entitiesByType.values()) {
					res.push(cc);
				}
			} else {
				this.logger.error('unrecognized collection type', collection);
			}
		}
		return res;
	}

	abstract checkForErrors(t: T, errors: string[]): void;

	_applyPatchToEntity(obj: T, patch: Patch, out: {revert: Patch | null}): Diff {
		const p = ObjectUtils.patchObject(obj as Object, patch as Partial<T>);
		if (!p) {
			return 0 as Diff;
		}
		out.revert = p.revertPatch as Patch;
		return 0xFFFF as Diff;
	}

	reserveNewId() {
		return this.idsProvider.reserveNewId();
	}

	allIds(): IterableIterator<EntityId<IdT>> {
		return this.perId.keys();
	}

	peekById(id: EntityId<IdT>): Readonly<T> | undefined {
		return this.perId.get(id);
	}

	peekByIds(ids: Iterable<EntityId<IdT>>): Map<EntityId<IdT>, T> {
		const res = new Map<EntityId<IdT>, T>();
		for (const id of ids) {
			const item = this.perId.get(id);
			if (item !== undefined) {
				res.set(id, item);
			}
		}
		return res;
	}

	applyCollectionPatch(diff: BimCollectionPatch<T, IdT, Patch>, eventParams?: Partial<EventStackFrame>): {
		allocated: EntityId<IdT>[], deleted: [EntityId<IdT>, Readonly<T>][]
	} {
		const allocated = diff.toAlloc.length ? this.allocate(diff.toAlloc, eventParams) : [];
		if (diff.toPatch.length) {
			this.applyPatches(diff.toPatch, eventParams);
		}
		const deleted = diff.toDelete.length ? this.delete(diff.toDelete, eventParams) : [];
		return {allocated, deleted};
	}

	allocate(argsPerObject: [EntityId<IdT>, Partial<T>][], e?: Partial<EventStackFrame>): EntityId<IdT>[] {
		if (argsPerObject.length === 0) {
			return [];
		}
		let thisEvent: Readonly<EventStackFrame> | undefined = undefined;
		if (e) {
			thisEvent = unsafePushToEventStackFrame(e);
		}
		try {
			const {idsAllocated, derivativeUpdates} = this._allocate(argsPerObject, peekCurrentEventFrame());
			if (idsAllocated.length) {
				this.updatesStream.pushNext(new Allocated(idsAllocated));
			}
			if (derivativeUpdates.size > 0) {
				const derivedEvent = unsafePushToEventStackFrame({isEventDerived: true});
				try {
					this.updatesStream.pushNext(EntitiesUpdated.fromTuples(derivativeUpdates));
				} finally {
					unsafePopFromEventStackFrame(derivedEvent);
				}

			}
			return idsAllocated;
		} finally {
			if (thisEvent) {
				unsafePopFromEventStackFrame(thisEvent);
			}
		}
	}

	clone(ids: EntityId<IdT>[]): EntityId<IdT>[] {
		const toAlloc: [EntityId<IdT>, T][] = [];
		for (const id of ids) {
			const s = this.perId.get(id);
			if (s !== undefined) {
				toAlloc.push([this.idsProvider.reserveNewId(), ObjectUtils.deepCloneObj(s)])
			}
		}
		return this.allocate(toAlloc);
	}

	protected _allocate(argsPerObject: Iterable<[EntityId<IdT>, Partial<T>]>, thisEvent: EventStackFrame):
		{ idsAllocated: EntityId<IdT>[], derivativeUpdates: Map<EntityId<IdT>, Diff> }
	{
		if (this._isLocked) {
			throw new Error(`${this.identifier}| cant _allocate, collection is locked`);
		}
		const toUndoDeleteIds: EntityId<IdT>[] = [];
		// const allocatedNotification: TView[] = [];
		const allocated: EntityId<IdT>[] = [];

        let validationErrors: string[] = [];

		for (const [id, partialState] of argsPerObject) {
			if (this.perId.has(id)) {
				this.logger.batchedError(`entity id is already occupied`, id);
				continue;
			}
			if (!this.idsProvider.isValidId(id)) {
				this.logger.batchedError(`invalid id`, id);
				continue;
			}
			const state = this.fullFromPartial(partialState);
			if (state == null) {
				this.logger.batchedError('invalid state to allocate', partialState);
				continue;
			}
			if (!this._validateExternalReferences(state)) {
				this.logger.batchedError('state references are invalid', [id, state]);
				continue;
			}
            this.checkForErrors(state, validationErrors);
			if (validationErrors.length) {
				this.logger.batchedError('invalid state, cant allocate', [id, state, ...validationErrors]);
                validationErrors = [];
				continue;
			}
			this.perId.set(id, state);
			if (this._localIdsMaximumExtractor) {
				const maxLocalId = this._localIdsMaximumExtractor(state);
				const idsCounter = this._localIdsCounters!.getOrCreate(id);
				idsCounter._nextId = Math.max(idsCounter._nextId, maxLocalId + 1);
			}
			allocated.push(id);
			toUndoDeleteIds.push(id);
			this.idsProvider.markOccupied(id);
		}
		if (allocated.length) {
			this._version += 1;
		}
		this._version += 1;
		if (this.undoStack && toUndoDeleteIds.length > 0) {
			this.undoStack.addUndoAction({
				actionName: `allocate`,
				sourceIdentifier: this.identifier,
				args: toUndoDeleteIds,
				act: (ids) => { this.delete(ids, {}) },
			});
		}
		return { idsAllocated: allocated, derivativeUpdates: new Map() };
	}

	deleteAllExcept(idsSet: Set<EntityId<IdT>>, e: Partial<EventStackFrame>): [EntityId<IdT>, Readonly<T>][] {
		const toDelete = [];
		for (const tId of this.perId.keys()) {
			if (!idsSet.has(tId)) {
				toDelete.push(tId);
			}
		}
		return this.delete(toDelete, e)
	}

	delete(idsToDelete: EntityId<IdT>[], e?: Partial<EventStackFrame>): [EntityId<IdT>, Readonly<T>][] {
		if (idsToDelete.length == 0) {
			return [];
		}
		const thisEvent: Readonly<EventStackFrame> | null = e ? unsafePushToEventStackFrame(e) : null;
		try {
			const { removed, derivativeUpdates } = this._deleteByIds(idsToDelete);
			this.updatesStream.pushNext(new Deleted(removed.map((t) => t[0])));
			if (derivativeUpdates.size > 0) {
				this.updatesStream.pushNext(EntitiesUpdated.fromTuples(derivativeUpdates));
			}
			return removed;
		} finally {
			if (thisEvent) {
				unsafePopFromEventStackFrame(thisEvent);
			}
		}
	}

	protected _deleteByIds(ids: EntityId<IdT>[])
		: { removed: [EntityId<IdT>, Readonly<T>][], derivativeUpdates: Map<EntityId<IdT>, Diff> }
	{
		if (this._isLocked) {
			throw new Error(`${this.identifier}| cant delete, collection is locked`);
		}
		if (ids.length == 0) {
			return { removed: [], derivativeUpdates: new Map() };
		}
		const externalRefs = this._externalsRefsToThis;
		const removed: [EntityId<IdT>, Readonly<T>][] = [];
		perId:
		for (const id of ids) {
			const state = this.perId.get(id);
			if (state === undefined) {
				this.logger.batchedWarn('cant remove entity, already empty', id);
				continue;
			}
			if (externalRefs.length) {
				for (let i = 0; i < externalRefs.length; ++i) {
					if (externalRefs[i].hasAnyRefsTo(id)) {
						this.logger.batchedError('cant remove entity, it is referenced', id);
						continue perId;
					}
				}
			}
			this.perId.delete(id);
			removed.push([id, state]);
		}
		Object.freeze(removed);
		if (removed.length > 0) {
			this._version += 1;

			if (this.shared) {
				this.shared.delete(new Set(ids));
			}

			if (this.undoStack) {
				this.undoStack.addUndoAction({
					actionName: 'delete',
					sourceIdentifier: this.identifier,
					args: removed,
					act: (allocArgs) => this.allocate(allocArgs, {}),
				});
			}
		}
		return { removed, derivativeUpdates: new Map() };
	}

	clear() {
		this.delete(Array.from(this.perId.keys()), {});
	}

	readAll(): [IdT, T][] {
		return Array.from(this.perId.entries());
	}

	protected _applyPerIdPatch(perId: Iterable<[EntityId<IdT>, Patch]>, thisEvent: EventStackFrame): EntitiesUpdated<EntityId<IdT>, Diff> {
		if (this._isLocked) {
			throw new Error(`${this.identifier}| cant _applyPerIdPatch, collection is locked`);
		}

		const revert: [EntityId<IdT>, Patch][] = [];
		const patchOut = { revert: null as (Patch | null) };

		const ids: EntityId<IdT>[] = [];
		const diffs: Diff[] = [];

        let validationErrors: string[] = [];

		for (const [id, patch] of perId) {
			const state = this.perId.get(id);
			if (!state) {
				this.logger.batchedWarn('entity patch, invalid id', id);
				continue;
			}
			patchOut.revert = null;
			const diffFlags = this._applyPatchToEntity(state, patch, patchOut);

			if (!this._validateExternalReferences(state)) {
				this._applyPatchToEntity(state, patchOut.revert!, patchOut);
				this.logger.batchedError('state references are invalid', id);
				continue;
			}
            this.checkForErrors(state, validationErrors);
			if (validationErrors.length) {
				this._applyPatchToEntity(state, patchOut.revert!, patchOut);
				this.logger.batchedError('invalid entity patch, reverting', [id, ...validationErrors]);
                validationErrors = [];
				continue;
			}
            if (this._localIdsMaximumExtractor) {
				const maxLocalId = this._localIdsMaximumExtractor(state);
				const idsCounter = this._localIdsCounters!.getOrCreate(id);
				idsCounter._nextId = Math.max(idsCounter._nextId, maxLocalId + 1);
			}
			if (diffFlags && patchOut.revert) {
				if (diffFlags & (~(this.diffFlagsToNotUndo))) {
					revert.push([id, patchOut.revert]);
				}
			}
			if (diffFlags) {
				ids.push(id);
				diffs.push(diffFlags);
			}
		}
		if (ids.length > 0) {
			this._version += 1;
		}
		if (revert.length > 0) {
			this._version += 1;

			if (this.undoStack) {
				this.undoStack.addUndoAction({
					actionName: 'update',
					sourceIdentifier: this.identifier,
					args: revert,
					act: (patch) => this.applyPatches(patch),
					//TODO: FIX AND RESTORE PATCHES MERGER
				// 	argsMerger: new BasicPatchesMerger<IdT, Patch>(
                //         this._T_Contstrutor as unknown as {new(): Patch},
                //         this.referencedBaseCollectionsIdentifiers
                //     ),
				});
			}
		}
		return new EntitiesUpdated(ids, diffs);
	}

	applyPatchTo(patch: Patch, ids: EntityId<IdT>[], event?: Partial<EventStackFrame>): void {
		const perIdPatch: [EntityId<IdT>, Patch][] = Array.from(ids).map(id => [id, patch]);
		return this.applyPatches(perIdPatch, event);
	}
	applyPatches(perId: [EntityId<IdT>, Patch][], event?: Partial<EventStackFrame>): void {
		if (perId.length == 0) {
			return;
		}
		const e = event ? unsafePushToEventStackFrame(event) : null;
		try {
			const delta = this._applyPerIdPatch(perId, peekCurrentEventFrame());
			if (delta.ids.length > 0) {
				this.updatesStream.pushNext(delta);
			}
		} finally {
			if (e) {
				unsafePopFromEventStackFrame(e);
			}
		}
	}

	some(fn: (obj: T) => boolean): boolean {
		for (const v of this.perId.values()) {
			if (fn(v)) {
				return true;
			}
		}
		return false;
	}

	filter(fn: (obj: T) => boolean): [EntityId<IdT>, T][] {
		const res: [EntityId<IdT>, T][] = [];
		for (const [id, v] of this.perId) {
			if (fn(v)) {
				res.push([id, v]);
			}
		}
		return res;
	}

}

export class BasicPatchesMerger<IdT, Patch extends Object> implements ArgsMerger<[EntityId<IdT>, Patch][]> {

    constructor(
        readonly fullPatchConstructor: { new(): Patch } | null,
        public dependsOnSourceIdentifiers: readonly string[],
    ) {

    }

    mergePatches(earlyRevert: Patch, laterRevert: Patch): Patch {
        if (this.fullPatchConstructor) {
            if (earlyRevert instanceof this.fullPatchConstructor) {
                return earlyRevert;
            } else {
                const res = new this.fullPatchConstructor();
                for (const key in earlyRevert) {
                    const p = earlyRevert[key];
                    if (p !== undefined) {
                        res[key] = earlyRevert[key];
                    }
                }
                for (const key in laterRevert) {
                    const p = laterRevert[key];
                    if (earlyRevert[key] === undefined) {
                        res[key] = p;
                    }
                }
                return res;
            }
        } else {
            const res = {...laterRevert, ...earlyRevert};
            return res;
        }
    }

    argsMerger(
        earlierRevert: [EntityId<IdT>, Patch][],
        laterRevert: [EntityId<IdT>, Patch][],
    ): [EntityId<IdT>, Patch][]
    {
		// if (laterRevert.length < (earlierRevert.length * 0.1)) {
		// 	return laterRevert.concat(earlierRevert);
		// }
        const result: [EntityId<IdT>, Patch][] = [];

        const mapOfLast = new Map<EntityId<IdT>, [EntityId<IdT>, Patch]>();

        // if there are per id collisions, rewrite in map but add to result array
        for (const t of earlierRevert) {
            result.push(t);
            mapOfLast.set(t[0], t);
        }

        for (const t2 of laterRevert) {

            const [id2, revert2] = t2;
            const prevTuple = mapOfLast.get(id2);

            if (prevTuple === undefined) {
                // nothing to merge, just add
                result.push(t2);
                mapOfLast.set(id2, t2);

            } else {
                const prevObject = prevTuple[1];
                if (this.fullPatchConstructor
                    && prevObject instanceof this.fullPatchConstructor
                    && revert2 instanceof this.fullPatchConstructor
                ) {
                    // this looks like replacement with new class instance, just skip new object, no need to merge
                } else {
                    prevTuple[1] = this.mergePatches(prevObject, revert2);
                }
            }
        }
        return result;
    }
}

const reusedIdsArray: EntityIdAny[] = [];
