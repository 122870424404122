<script lang="ts">
    export let id:string = '';
    export let label:string = '';
    export let checked:boolean|null|undefined = false;
    export let action:()=>void;
</script>

<div class="mdc-form-field">
    <div class="mdc-checkbox">
      <input
        type="checkbox"
        class="mdc-checkbox__native-control"
        {id}
        {checked}
        on:change={action}
      />
      <div class="mdc-checkbox__background">
        <svg class="mdc-checkbox__checkmark"
          viewBox="0 0 24 24">
          <path class="mdc-checkbox__checkmark-path"
            fill="none"
            d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
        </svg>
        <div class="mdc-checkbox__mixedmark"></div>
      </div>
      <div class="mdc-checkbox__ripple"></div>
    </div>
    <label for={id}>{label}</label>
  </div>