import type { LazyVersioned, ObservableObject} from 'engine-utils-ts';
import { DefaultMapWeak, LazyDerived, RGBA } from 'engine-utils-ts';
import { KrMath, Matrix4, Plane, Transform, Vector3, Vector4 } from 'math-ts';

import type { BufferGeometry, Camera, MeshIntersection, ShaderMaterial
} from '../3rdParty/three';
import {
    Box3, CylinderBufferGeometry, DoubleSide, FrontSide, Group, Mesh,
    Object3D, Raycaster, RingBufferGeometry
} from '../3rdParty/three';
import { MouseButton } from '../controls/InputController';
import { GeometryGenerator } from '../geometries/GeometryGenerator';
import { MaterialsFactory } from '../materials/MaterialsFactory';
import type { InteractiveObjectsActive } from '../scene/InteractiveSceneObjects';
import type { SceneInt } from '../scene/SceneRaycaster';
import { RotationGizmoShader } from '../shaders/GizmoShaderRotation';
import type { RayIntersection } from '../scene/Raycasts';
import { GizmoBrightness } from '../SizeLabelMesh';
import type { FrustumExt } from '../structs/FrustumExt';
import type { RaySection } from '../structs/RaySection';
import { GizmoBase } from './GizmoBase';
import { scaleForCamera } from './GizmoUtils';
import type { DraggingState} from './TransformGizmoStateController';
import {
    DragAction, MovementConstraint, TransformGizmoStateController, TransformMoveControlInters
} from './TransformGizmoStateController';
import { newBasicMaterial } from '../shaders/BasicShader';
import type { KrCamera } from '../controls/MovementControls';
import type { SnappingSettings } from '../SnappingSettings';
import { TransformPivotMode } from '../EngineConsts';
import { KeyModifiersFlags } from 'ui-bindings';

export class TransformGizmo extends GizmoBase<TransformMoveControlInters> {

	readonly gizmoState: TransformGizmoStateController;

	arrowsMesh: TransformGizmoMesh;

	_updatesPerFrustum: DefaultMapWeak<FrustumExt, LazyVersioned<void>>;

	constructor(interactiveObjects: InteractiveObjectsActive, snapping: ObservableObject<SnappingSettings>) {
		super();

		this.gizmoState = new TransformGizmoStateController(interactiveObjects, snapping);

		this.visible = false;

		this.arrowsMesh = new TransformGizmoMesh();
		this.add(this.arrowsMesh);
		// this.movementLine = new Mesh(
		// 	GeometryGenerator.generatePolyline(new Polyline([Vec3Zero.clone(), Vec3Z.clone()], 0.02), 0),
		// 	MaterialsFactory.createBasicMaterial(0xFFFFFF, 0.5, FrontSide)
		// );
		// this.add(this.movementLine);

		this._updatesPerFrustum = new DefaultMapWeak<FrustumExt, LazyVersioned<void>>((fr) => {
			const lv = LazyDerived.new2(
				`transform-gizmo-lazy-updates`,
				[fr],
				[this.gizmoState.state, this.gizmoState.dragState],
				([]) => {
					this.arrowsMesh.updateRepresentationForCamera(fr.camera, this.gizmoState);
				}).withoutEqCheck();
			return lv;
		})

		// this.isDirty = new VersionedValuesDirtyPerFrame(this.time, [
		// 	this.gizmoState.state,
		// 	this.gizmoState.dragState,
		// 	this.gizmoState.gizmoTransform
		// ]);
	}

	version(): number {
		return this.gizmoState.version();
	}


	update(fr: FrustumExt) {
		this.version();// trigger lazy updates if any
		this._updatesPerFrustum.getOrCreate(fr).poll();
		this.visible = this.arrowsMesh.visible;
	}

	toggleActive(active: boolean): void {
		this.gizmoState.toggleActive(active);
	}

	isActiveTotal() {
		return this.gizmoState.isActive() && this.gizmoState.transformsProvider.anySelected();
	}

	isDragging() {
		return !!this.gizmoState.dragState;
	}

	intersectRay(raycaster: RaySection): TransformMoveControlInters | null {
		if (!this.visible) {
			return null;
		}
		const threeCaster = new Raycaster(
			new Vector3().copy(raycaster.ray.origin as any),
			new Vector3().copy(raycaster.ray.direction as any),
			raycaster.near, raycaster.far
		);
		let result = null;
		const intersections = threeCaster.intersectObjects(this.children, true);
		if (intersections.length) {
			const int = intersections[0];
			if (int.object instanceof TransformGizmoSubmesh) {
				result = new TransformMoveControlInters(
					new Vector3(int.point.x, int.point.y, int.point.z),
					0,
					int.object.constraint,
					raycaster.clone(),
				)
			}
		}
		return result;
	}

	handleMouseClick(
		int: SceneInt,
		camera: KrCamera,
		ray: RaySection,
		button: MouseButton,
		keyMods: KeyModifiersFlags,
	) {
		if (int instanceof TransformMoveControlInters) {
			if (button == MouseButton.Left && int.constraint.action == 'mirror') {
				this.startDrag(int, camera, button, keyMods, new Vector3(0, 0, 1));
				this.handleDrag(ray, camera);
			}
		} else if (int !== null && button == MouseButton.Right && keyMods == KeyModifiersFlags.None) {
			this.moveTo(int)
		}

		this.gizmoState.stopDrag();
	}

	moveTo(int: RayIntersection) {
		const currTr = this.gizmoState.getGizmoPosition();
		if (currTr) {
			currTr.withOffset(int.point.x, int.point.y, int.point.z);
			this.gizmoState.state.applyPatch({
				patch: {
					pivot: TransformPivotMode.Manual,
					_transform: currTr,
				},
				event: {
					identifier: 'engine-transform_move',
				}
			});
		}
	}

	startDrag(
		inters: TransformMoveControlInters,
		camera: Camera,
		button: MouseButton,
		keyMods: KeyModifiersFlags,
		worldSpaceDirection: Vector3,
	): boolean {
		let action: DragAction | undefined = undefined;
		if (button == MouseButton.Left && this.gizmoState.transformsProvider.anySelected()) {
			if (keyMods == 0 || keyMods === KeyModifiersFlags.Alt) {
				action = DragAction.MoveObjects;
			} else if (keyMods == KeyModifiersFlags.Shift) {
			} else if (keyMods == KeyModifiersFlags.Ctrl) {
				action = DragAction.CloneAndMoveObjects;
			}

		} else if (button == MouseButton.Right) {
			action = DragAction.MoveGizmoItself;
		}
		if (action !== undefined) {
			return this.gizmoState.startDrag(inters, camera, action, worldSpaceDirection, keyMods);
		}
		return false;
	}

	handleDrag(
		raycaster: RaySection,
		camera: KrCamera,
	): boolean {
		return this.gizmoState.handleDrag(raycaster, camera);
	}

	setHover(int: TransformMoveControlInters | null) {
		if (int) {
			this.arrowsMesh.makeBright(int?.constraint || null, GizmoBrightness.Highlighted);
		} else {
			this.arrowsMesh.resetMaterials();
		}
	}

	setContrastColor(white: number): void {
	}

	dispose(): void {
		//throw new Error("Method not implemented.");
	}
}



class TransformGizmoMesh extends Object3D {
	meshes: TransformGizmoSubmesh[] = [];
	scalableChild: Group;

	resetMaterials() {
		this.makeBright(null, GizmoBrightness.Default);
	}

	makeBright(constraint: MovementConstraint | null, brighntess: GizmoBrightness) {
		for (const m of this.meshes) {
			if (!m.visible) {
				continue;
			}
			if (m.constraint === constraint) {
				m.setBrightness(brighntess);
			} else {
				m.setBrightness(GizmoBrightness.Default);
			}
		}
	}

	updateRepresentationForCamera(camera: Camera, gizmoState: TransformGizmoStateController) {

		const gizmoTransform = gizmoState.getGizmoPosition();

		if (!gizmoTransform) {
			this.visible = false;
			return;
		}
		this.visible = true;


		const cameraDirection = Vector3.zero();
		cameraDirection.set(camera.position.x, camera.position.y, camera.position.z);
		cameraDirection.sub(gizmoTransform.position).normalize();
		const dragState = gizmoState.dragState.poll();

		for (const m of this.meshes) {
			m.update(camera, dragState);

			if (m.constraint.isInScreenPlane) {
				m.visible = true;

			} else if (dragState) {
				let visible: boolean = m.constraint == dragState.constraint;
				if (dragState.constraint.action == 'rotate') {
					// if (m.constraint.action == 'move' && !m.constraint.isAxisAlignedWith(dragState.constraint)) {
					// 	visible = true;
					// }
				}
				m.visible = visible;

			} else if (m.constraint.guide instanceof Vector3) {
				const meshDirection = m.constraint.guide.clone();
				meshDirection.applyQuaternion(gizmoTransform.rotation);
				const dot = Math.abs(meshDirection.dot(cameraDirection));
				m.visible = dot < 0.90;
				m.colorOpacity.w = DEFAULT_OPACITY * (1.0 - Math.pow(dot, 3));

			} else if (m.constraint.guide instanceof Plane) {
				const meshDirection = m.constraint.guide.normal.clone();
				const cutoffValue = meshDirection.maxAbsComponentIndex() == 2 ? 0.2588 : 0.965;
				meshDirection.applyQuaternion(gizmoTransform.rotation);
				const dot = Math.abs(cameraDirection.dot(meshDirection));
				m.visible = dot > cutoffValue;
				m.colorOpacity.w = DEFAULT_OPACITY * Math.pow(dot, 1/3);

			} else {
				m.visible = true;
			}
		}

		// this.movementLine.visible = false;

		if (dragState) {
			// const gizmoStartPoint = this.state.dragState.gizmoStartPoint;
			// const distanceFromStart = this.state.center!.distanceTo(gizmoStartPoint);
			// if (this.state.dragState.constraint < 3
			// 	&& distanceFromStart > 0.01
			// ) {
			// 	this.movementLine.visible = true;
			// 	const vec4 = (this.movementLine.material as any).uniforms['colorOpacity'].value;
			// 	SetColorFromConstraint(vec4, this.state.dragState.constraint);
			// 	vec4.w *= 0.5;

			// 	this.movementLine.lookAt(gizmoStartPoint.asThree());
			// 	this.movementLine.scale.set(distanceFromStart, distanceFromStart, distanceFromStart);

			// 	const axis = Vector3.zero_t();
			// 	// axis.setComponent(this.state.dragState.constraint, 1);
			// 	// this.movementLine.rotation.setFromVector3(axis as any);
			// }

		} else {
		}

		if (dragState) {
			this.makeBright(dragState.constraint, GizmoBrightness.Selected);
		}
		const scaleMult = scaleForCamera(gizmoTransform.position, camera) * 1;

		this.position.copy(gizmoTransform.position as any);
		this.quaternion.copy(gizmoTransform.rotation as any);

		this.scalableChild.scale.set(Math.sign(gizmoTransform.scale.x), Math.sign(gizmoTransform.scale.y), Math.sign(gizmoTransform.scale.z));

		this.scale.set(Math.abs(gizmoTransform.scale.x), Math.abs(gizmoTransform.scale.y), Math.abs(gizmoTransform.scale.z));
		this.scale.multiplyScalar(scaleMult);
		this.updateMatrixWorld(false);
	}

	constructor() {
		super();

		const mat = newBasicMaterial(RGBA.new(1, 1, 1, 0.5), FrontSide);

		const centerMesh = new TransformGizmoSubmesh(
			new RingBufferGeometry(0.15, 0.2, 20, 1),
			mat,
			new MovementConstraint(new Plane(Vector3.allocate(0, 1, 0), 1), 'move', true),
		);
		this.meshes.push(centerMesh);

		{
			const rotationMaterial = MaterialsFactory.createThreeMaterial(RotationGizmoShader);
			rotationMaterial.side = DoubleSide;

			const innerRadius = 0.85;
			const outerRadius = 1.0;
			const rotationCircleScale = 2;
			const rotationMeshXY = new TransformGizmoSubmesh(
				new RingBufferGeometry(innerRadius, outerRadius, 32, 1, 0, Math.PI * 2),
				rotationMaterial,
				new MovementConstraint(new Plane(Vector3.allocate(0, 0, 1), 1), 'rotate'),
			)
			rotationMeshXY.rotateZ(Math.PI / 2);
			rotationMeshXY.scale.setScalar(rotationCircleScale);
			this.meshes.push(rotationMeshXY);

			const rotationMeshXZ = rotationMeshXY.clone_c(
				new MovementConstraint(new Plane(Vector3.allocate(0, 1, 0), 1), 'rotate')
			);
			rotationMeshXZ.rotateX(-Math.PI / 2);
			rotationMeshXZ.scale.setScalar(rotationCircleScale);
			this.meshes.push(rotationMeshXZ);

			const rotationMeshYZ = rotationMeshXY.clone_c(
				new MovementConstraint(new Plane(Vector3.allocate(1, 0, 0), 1), 'rotate')
			);
			rotationMeshYZ.rotateZ(-Math.PI);
			rotationMeshYZ.rotateY(-Math.PI / 2);
			rotationMeshYZ.scale.setScalar(rotationCircleScale);
			this.meshes.push(rotationMeshYZ);
		}

		{
			const addRotatedAroundAxis = (sourceMesh: TransformGizmoSubmesh, axis: Vector3) => {
				for (const angle of [Math.PI / 2, Math.PI, Math.PI / 2 * 3]) {
					sourceMesh.updateMatrix();
					const m = new TransformGizmoSubmesh(sourceMesh.geometry!, sourceMesh.material as ShaderMaterial, sourceMesh.constraint);
					m.matrix.copy(sourceMesh.matrix);
					m.matrix.premultiply(new Matrix4().makeRotationAxis(axis, angle));
					m.matrix.decompose(m.position, m.quaternion, m.scale);
					this.meshes.push(m);
				}
			}

			const mat = newBasicMaterial(RGBA.new(1, 1, 1, 0.5), DoubleSide);

			const geo = GeometryGenerator.generateCornerGeo({inner: 0.35, outer: 0.4})

			const xyMesh = new TransformGizmoSubmesh(
				geo,
				mat,
				new MovementConstraint(new Plane(new Vector3(0, 0, 1), 0), 'move'),
			);
			xyMesh.position.set(0.2, 0.2, 0.0);
			this.meshes.push(xyMesh);
			addRotatedAroundAxis(xyMesh, new Vector3(0, 0, 1));

			const xzMesh = xyMesh.clone_c(new MovementConstraint(new Plane(new Vector3(0, 1, 0), 0), 'move'));
			xzMesh.rotateX(Math.PI / 2);
			xzMesh.position.set(0.2, 0.0, 0.2);
			this.meshes.push(xzMesh);
			addRotatedAroundAxis(xzMesh, new Vector3(0, 1, 0));

			const yzMesh = xyMesh.clone_c(new MovementConstraint(new Plane(new Vector3(1, 0, 0), 0), 'move'));
			yzMesh.rotateY(-Math.PI / 2);
			yzMesh.position.set(0, 0.2, 0.2);
			this.meshes.push(yzMesh);
			addRotatedAroundAxis(yzMesh, new Vector3(1, 0, 0));
		}

		{
			let arrowBase = new CylinderBufferGeometry(0.02, 0.02, 1, 5, 1, false) as unknown as BufferGeometry
			let arrowTop = new CylinderBufferGeometry(0, 0.12, 0.5, 10, 1, false) as unknown as BufferGeometry;

			const arrowGeometry = GeometryGenerator.mergeBufferGeometries([
				{geo: arrowBase, tr: new Transform(new Vector3(0, 0.5, 0))},
				{geo: arrowTop, tr: new Transform(new Vector3(0, 1.25, 0))}
			])!;

			const yMesh = new TransformGizmoSubmesh(
				arrowGeometry,
				mat,
				new MovementConstraint(Vector3.allocate(0, 1, 0), 'move'),
			);
			yMesh.position.set(0.0, 0.25, 0.0);
			this.meshes.push(yMesh);

			const xMesh = yMesh.clone_c(new MovementConstraint(Vector3.allocate(1, 0, 0), 'move'));
			xMesh.rotateZ(-Math.PI / 2);
			xMesh.position.set(0.25, 0.0, 0.0);
			this.meshes.push(xMesh);

			const zMesh = yMesh.clone_c(new MovementConstraint(Vector3.allocate(0, 0, 1), 'move'));
			zMesh.rotateX(Math.PI / 2);
			zMesh.position.set(0.0, 0.0, 0.25);
			this.meshes.push(zMesh);
		}


		// {
		// 	const scaleGeo = new BoxBufferGeometry(0.3, 0.5, 0.5, 2, 1, 1);
		// 	const positions = scaleGeo.attributes.position.array!;
		// 	for (let i = 0; i < positions.length; i += 3) {
		// 		const x = Math.abs(positions[i + 0]);
		// 		const y = Math.abs(positions[i + 1]);
		// 		const z = Math.abs(positions[i + 2]);

		// 		if (Math.min(Math.min(x, y), z) < 0.1) {
		// 			positions[i + 0] = positions[i + 0] * 0.7;
		// 			positions[i + 1] = positions[i + 1] * 0.7;
		// 			positions[i + 2] = positions[i + 2] * 0.7;
		// 		}
		// 	}
		// 	const xScale = new TransformGizmoMesh(
		// 		scaleGeo,
		// 		mat,
		// 		new MovementConstraint(Vector3.allocate(1, 0, 0), 'mirror'),
		// 	);
		// 	xScale.position.set(-1.25, 0.0, 0.0);
		// 	xScale.scale.set(1.0, 1.0, 1.0);
		// 	this.meshes.push(xScale);

		// 	const yScaleMesh = xScale.clone_c(new MovementConstraint(Vector3.allocate(0, 1, 0), 'mirror'));
		// 	yScaleMesh.position.set(0.0, -1.25, 0.0);
		// 	yScaleMesh.scale.set(1.0, 1.0, 1.0);
		// 	yScaleMesh.rotateZ(Math.PI / 2);
		// 	this.meshes.push(yScaleMesh);

		// 	const zScaleMesh = xScale.clone_c(new MovementConstraint(Vector3.allocate(0, 0, 1), 'mirror'));
		// 	zScaleMesh.position.set(0.0, 0.0, -1.25);
		// 	zScaleMesh.scale.set(1.0, 1.0, 1.0);
		// 	zScaleMesh.rotateY(Math.PI / 2);
		// 	this.meshes.push(zScaleMesh);
		// }


		const scalableChild = new Group();
		this.add(scalableChild);
		this.scalableChild = scalableChild;
		for (const m of this.meshes) {
			if (m.constraint.isInScreenPlane) {
				this.add(m);
			} else {
				scalableChild.add(m);
			}
		}
	}
}



const boxReused = new Box3();

class TransformGizmoSubmesh extends Mesh {

	constraint: MovementConstraint;

	colorOpacity: Vector4 = Vector4.allocate(1, 1, 1, 1);
	dynamicUniforms: Map<string, Vector4 | Vector3> = new Map();

	constructor(geo: BufferGeometry, mat: ShaderMaterial, movementConstraing: MovementConstraint) {
		super(geo, mat);
		this.constraint = movementConstraing;

		if (this.constraint.isInScreenPlane) {
			// this.matrixAutoUpdate = false;
			// const p = this.parent;
			// p.remove(this);
			// p.children.push(this);

		}

		this.dynamicUniforms.set('colorOpacity', this.colorOpacity);
		if (movementConstraing.action == 'rotate') {
			this.dynamicUniforms.set('activeRange', new Vector4(0, 0, 0, 1));
		}
		setColorFromConstraint(this.colorOpacity, this.constraint);
	}

	update(camera: Camera, dragState: DraggingState | null) {
		if (this.constraint.isInScreenPlane) {
			this.lookAt(camera.position);
		}
		if (this.constraint.action == 'rotate') {
			const rangeUniform = this.dynamicUniforms.get('activeRange') as Vector4;
			if (dragState?.constraint == this.constraint) {
				let angleStart = Math.round((dragState.intersStart.angle) * 57.295779) + 180;
				let angleEnd = Math.round((dragState.intersLast?.angle ?? dragState.intersStart.angle) * 57.295779) + 180;


				// if (Math.abs((angleStart + anglesDiff + 360) % 360) - angleEnd < 0.5) {
				// 	angleEnd = (angleStart - anglesDiff + 360) % 360;
				// }

				if (angleEnd < angleStart) {
					[angleEnd, angleStart] = [angleStart, angleEnd];
				}
				const anglesDiff = 180 - Math.abs(Math.abs(angleStart - angleEnd) - 180);

				const angleMiddle: number = angleEnd - angleStart < 180 ?
					((angleStart + angleEnd) * 0.5) : ((angleEnd + (angleStart + 360)) * 0.5) % 360;

				let linesMult = 0.8;
				if (anglesDiff > 0) {
					if ((((anglesDiff | 0) % 45) | 0) === 0) {
						linesMult = 0.0;
					}
				}
				rangeUniform.set(angleMiddle, anglesDiff * 0.5, linesMult, 0.1);
			} else {
				rangeUniform.set(0, 0, 0, 1.0);
			}
		}
	}

	raycast(raycaster: Raycaster, intersects: MeshIntersection[]): void {
		if (this.constraint.action == 'rotate') {
			super.raycast(raycaster, intersects);
		} else {
			boxReused.setFromObject(this);
			if (boxReused.isEmpty()) {
				// console.warn('raycast box empty', boxReused.isEmpty(), boxReused);
				return;
			}
			const int = raycaster.ray.intersectBox(boxReused, new Vector3());
			if (int?.isFinite()) {
				intersects.push({
					distance: int.distanceTo(raycaster.ray.origin),
					faceIndex: 0,
					index: 0,
					object: this,
					point: int
				});
			}
		}
	}

	setBrightness(brighntess: GizmoBrightness) {
		setColorFromConstraint(this.colorOpacity, this.constraint);
		if (brighntess & GizmoBrightness.Highlighted) {
			this.colorOpacity.multiplyScalar(1.2);
		}
		if (brighntess & GizmoBrightness.Selected) {
			this.colorOpacity.multiplyScalar(1.2);
		}
	}

	clone_c(constaint: MovementConstraint): TransformGizmoSubmesh {
		const m = new TransformGizmoSubmesh(this.geometry as any, this.material as any, constaint);
		m.position.copy(this.position);
		return m;
	}
}


export function setColorFromConstraint(color: Vector4, c: MovementConstraint) {
	if (c.isInScreenPlane) {
		color.set(250 / 255, 250 / 255, 250 / 255, DEFAULT_OPACITY * 0.9);
		return;
	}
	let component = 0;
	if (c.guide instanceof Plane) {
		component = c.guide.normal.maxAbsComponentIndex();
		setColorFromAxisIndex(component, color);
		color.x = KrMath.lerp(color.x, 0, 0.15);
		color.y = KrMath.lerp(color.y, 0, 0.15);
		color.z = KrMath.lerp(color.z, 0, 0.15);
	} else {
		component = c.guide.maxAbsComponentIndex();
		setColorFromAxisIndex(component, color);
	}
}

const DEFAULT_OPACITY = 0.95;
export function setColorFromAxisIndex(axis: number, color: Vector4) {
	switch (axis) {
		case 0: color.set(231/255, 65/255, 90/255, DEFAULT_OPACITY); break;
		case 1: color.set(131/255, 198/255, 17/255, DEFAULT_OPACITY); break;
		case 2: color.set(59 / 255, 146 / 255, 240 / 255, DEFAULT_OPACITY); break;
		default: color.set(131/255, 198/255, 17/255, DEFAULT_OPACITY)
	}
}

