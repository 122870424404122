<script lang="ts">
    import { createUiState } from "./PUI_RuntimeState";
    import { IconComponent } from "../libui/icon";

    export let name: string;
    const depth: number = 1;

    const persistentUiState = createUiState();
    const uiState = persistentUiState.store;

    $: curExpanded = $uiState.expanded;
    $: curIndex = curExpanded.findIndex(
        (x) => x.depth === depth && x.name === name,
    );
    $: expanded = curIndex == -1;
    function toggleExpand() {
        let newExpanded = [...curExpanded];
        if (curIndex === -1) {
            newExpanded.push({
                depth: depth,
                name: name,
            });
        } else {
            newExpanded.splice(curIndex, 1);
        }
        uiState.update((x) => ({ ...x, expanded: newExpanded }));
    }
</script>

<div class="group">
    <div class="group-title-container" on:click={toggleExpand}>
        <IconComponent name={expanded ? "ChevronDown" : "ChevronRight"} />
        <span
            class="
                    mdc-typography--overline
                    overflow-clip
                    overflow-hidden
                "
        >
            {name}
        </span>
    </div>
    {#if $$slots.info}
        <div class="group-info">
            <slot name="info" />
        </div>
    {/if}
</div>
{#if expanded}
    <div class="group-content">
        <slot name="content" />
    </div>
{/if}

<style lang="scss">
    .group {
        display: flex;
        gap: 4px;
        padding: 4px 16px;
    }
    .group-title-container {
        cursor: default;
        flex: 3;
        display: flex;
        gap: 4px;
    }
    .group-info {
        flex: 4;
        > :global(*) {
            margin: 0 8px;
        }
    }
    .group-content :global(.property-name-label) {
        --offset: 24px;
    }
    .group-content > :global(.container:first-child .group) {
        margin-top: 4px;
    }
</style>
