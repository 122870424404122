<script lang="ts">
    import { Dialog } from '../libui/dialog';
    import type { UiBindings } from "ui-bindings";

    import { VersionedStore } from '../VersionedStore';
    import { DialogConfigToShow, DialogsListener } from "./DialogsListener";
    import { onDestroy, onMount, setContext } from "svelte";
    import EngineViewsConstructors from '../ui-panels/ViewsConstructors.svelte';
    import DialogButton from "./DialogButton.svelte";

	export let uiBindings: UiBindings;

    let dialog: Dialog;

    let dialogsListener = new DialogsListener();
    $: {
        dialogsListener.updateSubscription(uiBindings.dialogsStream);
    }
    setContext('dialogsListener', dialogsListener);

    $: showDialog = new VersionedStore(dialogsListener.showDialog);
    $: dialogToShow = new VersionedStore(dialogsListener.dialogToShow);

    $: {
        $showDialog;// invalidate with boolean
        dialogsListener.update();
    }

    let keyboardListener: ((e: KeyboardEvent) => void) | null = null;

    onMount(() => {
        keyboardListener = (e: KeyboardEvent) => {
            if (!dialog) {
                return;
            }
            if (e.key === 'Escape') {
                onClosed();
                e.preventDefault();
                e.stopPropagation();
            }
        }
        window.addEventListener('keydown', keyboardListener, {capture: true});
        return () => {
            dialogsListener.dispose();
            if (keyboardListener) {
                window.removeEventListener('keydown', keyboardListener);
                keyboardListener = null;
            }
        }
    });

    $: if ($dialogToShow instanceof DialogConfigToShow) {
        dialog?.open()
    }

    function onClosed(){
        dialog?.close();
        $dialogToShow?.close();
    }

    $: dialogInput = {
        title: $dialogToShow?.name,
        message: $dialogToShow?.message,
        onClosed
    }

</script>


<style>
</style>

{#if $dialogToShow instanceof DialogConfigToShow}
    <Dialog
        dialogInput={dialogInput}
        compact={$dialogToShow.compact}
        bind:this={dialog}
    >
        <div slot="body">
            {#if $dialogToShow.uiSource}
                <EngineViewsConstructors
                    bind:identifier={$dialogToShow.id}
                    bind:uiNodeDescription={$dialogToShow.uiSource}
                ></EngineViewsConstructors>
            {/if}
        </div>
        <svelte:fragment slot="actions">
            {#each $dialogToShow.buttons as buttonDescription}
                <DialogButton buttonDescription={buttonDescription} />
            {/each}
        </svelte:fragment>
    </Dialog>
{/if}

<slot></slot>
