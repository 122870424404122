<script lang="ts">
    //@ts-ignore
    import NumberSpinner from "svelte-number-spinner";
    import flash from "../flash";
    import { ObjectUtils } from "engine-utils-ts";
    import { getContext } from "svelte";
    import type { UnitsMapper } from "bim-ts";
    import { RangeProperty } from "./RangeProperty";
    import { NumberSpinnerUtils } from "../NumberSpinnerUtils";
    import type { Readable } from "svelte/store";
    import type { PUI_PropertyRenderColor } from "ui-bindings";
    import Lock from '../../libui/icon/svg/lock.svelte';

    export let name: string;
    export let value: [number | null, number | null];
    export let minMax: [number, number];
    export let unit: string | undefined;
    export let step: number;
    export let readonly: boolean = false;
    export let minTag: string | undefined;
    export let maxTag: string | undefined;
    export let decimals: number | undefined;
    export let minReadonly: boolean = false;
    export let maxReadonly: boolean = false;
    export let notActive: boolean | undefined = undefined;
    export let colorMin: PUI_PropertyRenderColor | undefined = "inherit";
    export let colorMax: PUI_PropertyRenderColor | undefined = "inherit";
    
    $: unitsMapper = getContext("unitsMapper") as Readable<UnitsMapper>;

    let div: HTMLElement;
    let preValue = value;
    $: if (!ObjectUtils.areObjectsEqual(preValue, value)) {
        preValue = value;
        flash(div);
    }

    $: property = new RangeProperty({
        name,
        value,
        minMax,
        step,
        readonly,
        onChange: (newValue: [number | null, number | null]) => {
            value = newValue;
        },
        unitsMapper: $unitsMapper,
        unit,
    });

    function addUnit(val: number) {
        const valueDecimals = decimals ?? property.decimals;
        return NumberSpinnerUtils.addUnit(
            val,
            val == null,
            valueDecimals,
            property.unit
        );
    }

    function removeUnit(str: string) {
        return NumberSpinnerUtils.removeUnit(str, property.unit);
    }
    
</script>

<div 
    class="ui-config-property property-row"
    class:not-active = {notActive}
    >
    <div bind:this={div} class="property-name">
        <div class="property-name-label mdc-typography--body1 text-main-medium">
            {property.name}
        </div>
    </div>
    <div class="property-value">
        <div class="range-property">
            {#if readonly || minReadonly}
                <div class="read-only mdc-typography--body1">
                    <span style="color: var({colorMax});"> 
                        {property.minValue !== null ? addUnit(property.minValue) : "—"}
                    </span>
                    <span class="icon-holder">
                        {#if minTag}
                            <span
                                tabindex="-1"
                                class="calculated mdc-typography--body1 text-main-light"
                            >
                                {minTag}
                            </span>
                        {/if}
                        {#if minReadonly}
                            <Lock />
                        {/if}
                    </span>
                </div>
            {:else}
                <div class="edit-field-container">
                    {#key property.step}
                        <NumberSpinner
                            bind:value={property.minValue}
                            type="number"
                            format={addUnit}
                            mainStyle={`color: var(${colorMin});`}
                            class="
                        config-ui-input-field
                        mdc-typography--body1
                        "
                            step={property.step}
                            speed={property.sliderSpeed}
                            min={property.minMax[0]}
                            max={property.minMax[1]}
                        />
                    {/key}
                    <div class="ctl-buttons">
                        {#if minTag}
                            <span
                                tabindex="-1"
                                class="calculated mdc-typography--body1 text-main-light"
                            >
                                {minTag}
                            </span>
                        {/if}
                    </div>
                </div>
            {/if}
            <span
                class="range-prop-divider mdc-typography--body1 text-main-light"
            >
                {"/"}
            </span>
            {#if readonly || maxReadonly}
                <div class="read-only mdc-typography--body1">
                    <span style="color: var({colorMax});"> 
                        {property.maxValue !== null ? addUnit(property.maxValue) : "—"}
                    </span>
                    <span class="icon-holder">
                        {#if maxTag}
                            <span
                                tabindex="-1"
                                class="calculated mdc-typography--body1 text-main-light"
                            >
                                {maxTag}
                            </span>
                        {/if}
                        <Lock />
                    </span>
                </div>
            {:else}
                <div class="edit-field-container">
                    {#key property.step}
                        <NumberSpinner
                            bind:value={property.maxValue}
                            type="number"
                            format={addUnit}
                            parse={removeUnit}
                            mainStyle={`color: var(${colorMax});`}
                            class="
                            config-ui-input-field
                            mdc-typography--body1
                            "
                            step={property.step}
                            speed={property.sliderSpeed}
                            min={property.minMax[0]}
                            max={property.minMax[1]}
                        />
                    {/key}
                    <div class="ctl-buttons">
                        {#if maxTag}
                            <span
                                tabindex="-1"
                                class="calculated mdc-typography--body1 text-main-light"
                            >
                                {maxTag}
                            </span>
                        {/if}
                    </div>
                </div>
            {/if}
        </div>
    </div>
</div>

<style lang="scss">
    
    .range-property {
        display: grid;
        grid-template-columns: 1fr 8px 1fr;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
    }

    .range-prop-divider {
        text-align: center;
    }
</style>
