<script lang="ts">
    import { PileProfileType } from "bim-ts";

    export let options: Array<{ value: PileProfileType; label: string }>;
    export let onChange: (value: PileProfileType) => void;
</script>

<div class="container">
    {#each options as option}
        <div class="option" on:click={() => onChange(option.value)}>
            <div class="option-content">
                <p class="option-title">
                    {option.label}
                </p>
            </div>
        </div>
    {/each}
</div>

<style lang="scss">
    .container {
        padding: 8px 16px;
        background: white;
        box-shadow: var(--dropdownsimple-box-shadow);
        max-height: 200px;
        overflow: auto;
        box-sizing: border-box;
    }
    .option {
        margin: 0 -16px;
        padding: 2px 16px;
        &:hover {
            background: rgba(0, 0, 0, 0.05);
        }
        .option-content {
            cursor: pointer;
            .option-title {
                font-size: 14px;
                margin: 0;
            }
        }
    }
</style>
