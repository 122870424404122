import type { Bim, IdBimScene, SceneInstance} from "src";
import { CombinerBoxTypeIdent, InverterTypeIdent, TrackerTypeIdent } from "src";
import { CombinerBoxDescription, InverterDescrription, TransformerDescription } from "./types";
import type { ConnectionDescription} from "src/sld/types";
import { ModuleTableDescription, groupByLooseComparison } from "src/sld/types";
import { LvWireTypeIdent } from "src/archetypes/LvWire";
import { extractConnectionDescription } from "../utils";
import { extractModuleTablesDescriptions } from "src/sld/utils";

export function createTransformerDescriptionFromScene(bim: Bim, id: IdBimScene) {
    const transformerSi = bim.instances.peekById(id)!;

    const title = transformerSi.properties.get("cost | cost_item")?.asText() ?? "unknown"
    const stringsDivision = transformerSi.properties
        .getPropStartingWith('circuit | pattern | multiharness | div')?.map(x => x.asNumber())
        ?? [1];

    const description = new TransformerDescription({
        combinerBoxes: [],
        sceneId: id,
        text: title,
    });

    const children = bim.instances.spatialHierarchy._allObjects.get(id)?.children ?? [];
    const cbs: CombinerBoxDescription[] = [];
    let connection: ConnectionDescription | null = null;
    for (const { id } of children) {
        const child = bim.instances.peekById(id);
        if (!child) {
            continue;
        }
        if (child.type_identifier === CombinerBoxTypeIdent) {
            const cbDescription = extractCBDescription(id, child, bim, stringsDivision);
            cbs.push(cbDescription)
        } else if (!connection && child.type_identifier === LvWireTypeIdent) {
            connection = extractConnectionDescription(id, child);
        }
    }

    if (connection) {
        for (const cb of cbs) {
            cb.connectionToParent = connection;
        }
    }

    description.combinerBoxes = groupByLooseComparison(cbs);
    return description;
}

function extractCBDescription(
    id: IdBimScene,
    si: SceneInstance,
    bim: Bim,
    stringsDivision: number[],
): CombinerBoxDescription {
    const title = si.properties.get("cost | cost_item")?.asText() ?? "unknown";

    const descripiton = new CombinerBoxDescription({
        sceneId: id,
        inverters: [],
        text: title,
    })

    const children = bim.instances.spatialHierarchy._allObjects.get(id)?.children ?? [];
    const inverters: InverterDescrription[] = [];
    let inverterConnection: ConnectionDescription | null = null;
    for (const { id } of children) {
        const child = bim.instances.peekById(id);
        if (!child) {
            continue;
        }
        if (child.type_identifier === InverterTypeIdent) {
            const inverterDescription = extractInverterDescription(id, child, bim, stringsDivision);
            inverters.push(inverterDescription);
        } else if (!inverterConnection && child.type_identifier === LvWireTypeIdent) {
            inverterConnection = extractConnectionDescription(id, child);
        }
    }

    if (inverterConnection) {
        for (const inverter of inverters) {
            inverter.connectionToParent = inverterConnection;
        }
    }

    descripiton.inverters = Array.from(groupByLooseComparison(inverters));

    return descripiton;
}

function extractInverterDescription(
    id: IdBimScene,
    si: SceneInstance,
    bim: Bim,
    stringDivision: number[],
): InverterDescrription {
    const title = si.properties.get("cost | cost_item")?.asText() ?? "unknown";

    const descripiton = new InverterDescrription({
        sceneId: id,
        moduleTables: [],
        text: title,
    })

    const tableDescriptions = extractModuleTablesDescriptions(id, bim, stringDivision);

    // configure wiring
    const inverterId = id
    let whip: ConnectionDescription | null = null
    let firstTrackerId: IdBimScene | null = null
    for (const { id } of bim.instances.spatialHierarchy._allObjects.get(inverterId)?.children ?? []) {
        if (firstTrackerId && whip) {
            break;
        }
        const child = bim.instances.peekById(id);
        if (!child) {
            continue;
        }
        if (!firstTrackerId || child.type_identifier === TrackerTypeIdent) {
            firstTrackerId = id;
            continue;
        }
        if (child.type_identifier === LvWireTypeIdent) {
            const lvWiringType = child.properties.get('specification | type')?.asText()
            if (lvWiringType === 'Whip') {
                whip = extractConnectionDescription(id, child)
                continue;
            }
        }
    }

    let harness: ConnectionDescription | null = null
    if (firstTrackerId) {
        for (const { id } of bim.instances.spatialHierarchy._allObjects.get(firstTrackerId)?.children ?? []) {
            const child = bim.instances.peekById(id);
            if (!child || child.type_identifier !== LvWireTypeIdent) {
                continue;
            }
            const lvWiringType = child.properties.get('specification | type')?.asText()
            if (lvWiringType === 'MultiHarness') {
                harness = extractConnectionDescription(id, child)
                break;
            }
        }
    }
    if (whip) {
        for (const table of tableDescriptions) {
            table.connectionToParent = whip;
        }
    }
    if (harness) {
        for (const table of tableDescriptions) {
            for (const matrix of table.matrices) {
                matrix.connectionToParent = harness;
            }
        }
    }
    const grouped = ModuleTableDescription.group(tableDescriptions);
    descripiton.moduleTables.push(...grouped);

    return descripiton;
}
