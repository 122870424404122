import { Quaternion, Transform, Vector3 } from 'math-ts';

import type {
    Bim} from '../..';
import { BimMaterialStdRenderParams, StdMeshRepresentation,
    StdSubmeshRepresentation
} from '../..';
import { BimProperty } from '../../bimDescriptions/BimProperty';
import type { ReactiveSolverBase, SolverInstancePatchResult } from '../ReactiveSolverBase';
import { SolverObjectInstance } from '../SolverObjectInstance';

const DefaultSubstationMeshGenInput = {
    legacyProps: {
        width: BimProperty.NewShared({path: ["dimensions", "width"], value: 0.5, unit: 'm'}),
        length: BimProperty.NewShared({path: ["dimensions", "length"], value: 0.5, unit: 'm'}),
        height: BimProperty.NewShared({path: ["dimensions", "height"], value: 0.5, unit: 'm'}),
    }
}


export function substationMeshGenerator(bim: Bim): ReactiveSolverBase {
    return new SolverObjectInstance({
        solverIdentifier: 'substation-mesh-gen',
        objectsDefaultArgs: DefaultSubstationMeshGenInput,
        objectsIdentifier: 'substation',
        cache: true,
        solverFunction: (inputObj): SolverInstancePatchResult => {
            const module_size_x = inputObj.legacyProps.width.as("m");
            const module_size_y = inputObj.legacyProps.length.as("m");
            const module_size_z = inputObj.legacyProps.height.as("m");

            const unitCubeGeoId = bim.cubeGeometries.shared!.get({
                size: new Vector3(1, 1, 1), // use unit cube geo, and scale it's transform according to props later, for better goemetry reuse
                center: new Vector3(0, 0, 0.5),
            })!;
            const moduleMatId = bim.bimMaterials.shared!.get({
                name: "---",
                stdRenderParams: new BimMaterialStdRenderParams("red", 0, 0.85, 0.4),
            })!;

            const submeshes: StdSubmeshRepresentation[] = [];

            const position = new Vector3(0, 0, 0);
            submeshes.push(
                new StdSubmeshRepresentation(
                    unitCubeGeoId,
                    moduleMatId,
                    new Transform(position, new Quaternion(), new Vector3(module_size_x, module_size_y, module_size_z))
                )
            );

            return {
                repr: new StdMeshRepresentation(submeshes, null, true),
            };
        }
    })
}
