import { ShaderChunk } from '../3rdParty/three';

ShaderChunk['kr_pbr_pars_frag'] =
`
#ifdef USE_ENVMAP
    const float envMapIntensity = 1.1;
    const int maxMipLevel = 8;
    #ifdef ENVMAP_TYPE_CUBE
        uniform samplerCube envMap;
    #else
        uniform sampler2D envMap;
    #endif
    
#endif
`

ShaderChunk['kr_pbr_frag'] =
`

struct GeometricContext {
	// vec3 position;
	vec3 normal;
	vec3 viewDir;
#ifdef CLEARCOAT
	vec3 clearcoatNormal;
#endif
};

struct PhysicalMaterial {
    vec3 diffuseColor;
    float specularRoughness;
    vec3 specularColor;
    #ifdef CLEARCOAT
        float clearcoat;
        float clearcoatRoughness;
    #endif
};

const float toneMappingExposure = 1.4;
vec3 RRTAndODTFit( vec3 v ) {
    vec3 a = v * ( v + 0.0245786 ) - 0.000090537;
    vec3 b = v * ( 0.983729 * v + 0.4329510 ) + 0.238081;
    return a / b;
}
vec3 ACESFilmicToneMapping( vec3 color ) {
    const mat3 ACESInputMat = mat3(
        vec3( 0.59719, 0.07600, 0.02840 ),
        vec3( 0.35458, 0.90834, 0.13383 ),
        vec3( 0.04823, 0.01566, 0.83777 )
    );
    const mat3 ACESOutputMat = mat3(
        vec3(  1.60475, -0.10208, -0.00327 ),
        vec3( -0.53108, 1.10813, -0.07276 ),
        vec3( -0.07367, -0.00605, 1.07602 )
    );
    color *= toneMappingExposure / 0.6;
    color = ACESInputMat * color;
    color = RRTAndODTFit( color );
    color = ACESOutputMat * color;
    return saturate( color );
}

vec3 toneMapping( vec3 color ) {
    return ACESFilmicToneMapping( color );
}

#define MAXIMUM_SPECULAR_COEFFICIENT 0.16
#define DEFAULT_SPECULAR_COEFFICIENT 0.04


#ifdef ENVMAP_TYPE_CUBE_UV
    #define cubeUV_maxMipLevel 8.0
    #define cubeUV_minMipLevel 4.0
    #define cubeUV_maxTileSize 256.0
    #define cubeUV_minTileSize 16.0
    float getFace( vec3 direction ) {
        vec3 absDirection = abs( direction );
        float face = - 1.0;
        if ( absDirection.x > absDirection.z ) {
            if ( absDirection.x > absDirection.y )
            face = direction.x > 0.0 ? 0.0 : 3.0;
            else
            face = direction.y > 0.0 ? 1.0 : 4.0;
        }
        else {
            if ( absDirection.z > absDirection.y )
            face = direction.z > 0.0 ? 2.0 : 5.0;
            else
            face = direction.y > 0.0 ? 1.0 : 4.0;
        }
        return face;
    }
    vec2 getUV( vec3 direction, float face ) {
        vec2 uv;
        if ( face == 0.0 ) {
            uv = vec2( direction.z, direction.y ) / abs( direction.x );
        }
        else if ( face == 1.0 ) {
            uv = vec2( - direction.x, - direction.z ) / abs( direction.y );
        }
        else if ( face == 2.0 ) {
            uv = vec2( - direction.x, direction.y ) / abs( direction.z );
        }
        else if ( face == 3.0 ) {
            uv = vec2( - direction.z, direction.y ) / abs( direction.x );
        }
        else if ( face == 4.0 ) {
            uv = vec2( - direction.x, direction.z ) / abs( direction.y );
        }
        else {
            uv = vec2( direction.x, direction.y ) / abs( direction.z );
        }
        return 0.5 * ( uv + 1.0 );
    }
    vec3 bilinearCubeUV( sampler2D envMap, vec3 direction, float mipInt ) {
        float face = getFace( direction );
        float filterInt = max( cubeUV_minMipLevel - mipInt, 0.0 );
        mipInt = max( mipInt, cubeUV_minMipLevel );
        float faceSize = exp2( mipInt );
        float texelSize = 1.0 / ( 3.0 * cubeUV_maxTileSize );
        vec2 uv = getUV( direction, face ) * ( faceSize - 1.0 );
        vec2 f = fract( uv );
        uv += 0.5 - f;
        if ( face > 2.0 ) {
            uv.y += faceSize;
            face -= 3.0;
        }
        uv.x += face * faceSize;
        if ( mipInt < cubeUV_maxMipLevel ) {
            uv.y += 2.0 * cubeUV_maxTileSize;
        }
        uv.y += filterInt * 2.0 * cubeUV_minTileSize;
        uv.x += 3.0 * max( 0.0, cubeUV_maxTileSize - 2.0 * faceSize );
        uv *= texelSize;
        vec3 tl = envMapTexelToLinear( texture2D( envMap, uv ) ).rgb;
        uv.x += texelSize;
        vec3 tr = envMapTexelToLinear( texture2D( envMap, uv ) ).rgb;
        uv.y += texelSize;
        vec3 br = envMapTexelToLinear( texture2D( envMap, uv ) ).rgb;
        uv.x -= texelSize;
        vec3 bl = envMapTexelToLinear( texture2D( envMap, uv ) ).rgb;
        vec3 tm = mix( tl, tr, f.x );
        vec3 bm = mix( bl, br, f.x );
        return mix( tm, bm, f.y );
    }
    #define r0 1.0
    #define v0 0.339
    #define m0 - 2.0
    #define r1 0.8
    #define v1 0.276
    #define m1 - 1.0
    #define r4 0.4
    #define v4 0.046
    #define m4 2.0
    #define r5 0.305
    #define v5 0.016
    #define m5 3.0
    #define r6 0.21
    #define v6 0.0038
    #define m6 4.0
    float roughnessToMip( float roughness ) {
        float mip = 0.0;
        if ( roughness >= r1 ) {
            mip = ( r0 - roughness ) * ( m1 - m0 ) / ( r0 - r1 ) + m0;
        }
        else if ( roughness >= r4 ) {
            mip = ( r1 - roughness ) * ( m4 - m1 ) / ( r1 - r4 ) + m1;
        }
        else if ( roughness >= r5 ) {
            mip = ( r4 - roughness ) * ( m5 - m4 ) / ( r4 - r5 ) + m4;
        }
        else if ( roughness >= r6 ) {
            mip = ( r5 - roughness ) * ( m6 - m5 ) / ( r5 - r6 ) + m5;
        }
        else {
            mip = - 2.0 * log2( 1.16 * roughness );
        }
        return mip;
    }
    vec4 textureCubeUV( sampler2D envMap, vec3 sampleDir, float roughness ) {
        float mip = clamp( roughnessToMip( roughness ), m0, cubeUV_maxMipLevel );
        float mipF = fract( mip );
        float mipInt = floor( mip );
        vec3 color0 = bilinearCubeUV( envMap, sampleDir, mipInt );
        if ( mipF == 0.0 ) {
            return vec4( color0, 1.0 );
        }
        else {
            vec3 color1 = bilinearCubeUV( envMap, sampleDir, mipInt + 1.0 );
            return vec4( mix( color0, color1, mipF ), 1.0 );
        }
    
    }
#endif

#if defined( USE_ENVMAP )

    vec3 getLightProbeIndirectIrradiance( const in GeometricContext geometry, const in int maxMIPLevel ) {
        vec3 worldNormal = inverseTransformDirection( geometry.normal, viewMatrix );
        worldNormal = vec3(worldNormal.x, -worldNormal.z, worldNormal.y);
        #ifdef ENVMAP_TYPE_CUBE
            vec3 queryVec = vec3( -worldNormal.x, worldNormal.yz );
            #ifdef TEXTURE_LOD_EXT
                vec4 envMapColor = textureCubeLodEXT( envMap, queryVec, float( maxMIPLevel ) );
            #else
                vec4 envMapColor = textureCube( envMap, queryVec, float( maxMIPLevel ) );
            #endif
            envMapColor.rgb = envMapTexelToLinear( envMapColor ).rgb;
        #elif defined( ENVMAP_TYPE_CUBE_UV )
            vec4 envMapColor = textureCubeUV( envMap, worldNormal, 1.0 );
        #else
            vec4 envMapColor = vec4( 0.0 );
        #endif
        return PI * envMapColor.rgb * envMapIntensity;
    }
    float getSpecularMIPLevel( const in float roughness, const in int maxMIPLevel ) {
        float maxMIPLevelScalar = float( maxMIPLevel );
        float sigma = PI * roughness * roughness / ( 1.0 + roughness );
        float desiredMIPLevel = maxMIPLevelScalar + log2( sigma );
        return clamp( desiredMIPLevel, 0.0, maxMIPLevelScalar );
    }
    vec3 getLightProbeIndirectRadiance( const in vec3 viewDir, const in vec3 normal, const in float roughness, const in int maxMIPLevel ) {
        #ifdef ENVMAP_MODE_REFLECTION
            vec3 reflectVec = reflect( -viewDir, normal );
            reflectVec = normalize( mix( reflectVec, normal, roughness * roughness) );
        #else
            vec3 reflectVec = refract( -viewDir, normal, refractionRatio );
        #endif
        reflectVec = inverseTransformDirection( reflectVec, viewMatrix );
        reflectVec = vec3(reflectVec.x, -reflectVec.z, reflectVec.y);
        float specularMIPLevel = getSpecularMIPLevel( roughness, maxMIPLevel );
        #ifdef ENVMAP_TYPE_CUBE
            vec3 queryReflectVec = vec3( -reflectVec.x, reflectVec.yz );
            #ifdef TEXTURE_LOD_EXT
                vec4 envMapColor = textureCubeLodEXT( envMap, queryReflectVec, specularMIPLevel );
            #else
                vec4 envMapColor = textureCube( envMap, queryReflectVec, specularMIPLevel );
            #endif
            envMapColor.rgb = envMapTexelToLinear( envMapColor ).rgb;
        #elif defined( ENVMAP_TYPE_CUBE_UV )
            vec4 envMapColor = textureCubeUV( envMap, reflectVec, roughness );
        #endif

        return envMapColor.rgb * envMapIntensity;
    }
#endif

vec3 getAmbientLightIrradiance( const in vec3 worldNormal ) {
    
    vec3 skyColor = vec3(0.9, 0.90, 1.0);
    vec3 groundColor = vec3(0.7, 0.6, 0.7);

    vec3 upDownColor = mix(groundColor, skyColor, (worldNormal.z + 1.0) * 0.5);

    return mix(vec3(1.0, 1.0, 1.0), vec3(0.6, 0.7, 0.6), abs(worldNormal.x) - abs(worldNormal.y)) * 0.7;
}

vec3 BRDF_Diffuse_Lambert( const in vec3 diffuseColor ) {
    return RECIPROCAL_PI * diffuseColor;
}

void RE_IndirectDiffuse_Physical( const in vec3 irradiance, const in GeometricContext geometry, const in PhysicalMaterial material, inout ReflectedLight reflectedLight ) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert( material.diffuseColor );
}

vec2 integrateSpecularBRDF( const in float dotNV, const in float roughness ) {
    const vec4 c0 = vec4( - 1, - 0.0275, - 0.572, 0.022 );
    const vec4 c1 = vec4( 1, 0.0425, 1.04, - 0.04 );
    vec4 r = roughness * c0 + c1;
    float a004 = min( r.x * r.x, exp2( - 9.28 * dotNV ) ) * r.x + r.y;
    return vec2( -1.04, 1.04 ) * a004 + r.zw;
}
vec3 BRDF_Specular_GGX_Environment( const in vec3 viewDir, const in vec3 normal, const in vec3 specularColor, const in float roughness ) {
    float dotNV = saturate( dot( normal, viewDir ) );
    vec2 brdf = integrateSpecularBRDF( dotNV, roughness );
    return specularColor * brdf.x + brdf.y;
}
vec3 F_Schlick_RoughnessDependent( const in vec3 F0, const in float dotNV, const in float roughness ) {
    float fresnel = exp2( ( -5.55473 * dotNV - 6.98316 ) * dotNV );
    vec3 Fr = max( vec3( 1.0 - roughness ), F0 ) - F0;
    return Fr * fresnel + F0;
}
void BRDF_Specular_Multiscattering_Environment( const in GeometricContext geometry, const in vec3 specularColor, const in float roughness, inout vec3 singleScatter, inout vec3 multiScatter ) {
    float dotNV = saturate( dot( geometry.normal, geometry.viewDir ) );
    vec3 F = F_Schlick_RoughnessDependent( specularColor, dotNV, roughness );
    vec2 brdf = integrateSpecularBRDF( dotNV, roughness );
    vec3 FssEss = F * brdf.x + brdf.y;
    float Ess = brdf.x + brdf.y;
    float Ems = 1.0 - Ess;
    vec3 Favg = specularColor + ( 1.0 - specularColor ) * 0.047619;
    vec3 Fms = FssEss * Favg / ( 1.0 - Ems * Favg );
    singleScatter += FssEss;
    multiScatter += Fms * Ems;
}

void RE_IndirectSpecular_Physical( const in vec3 radiance, const in vec3 irradiance, const in vec3 clearcoatRadiance, const in GeometricContext geometry, const in PhysicalMaterial material, inout ReflectedLight reflectedLight) {
    #ifdef CLEARCOAT
        float ccDotNV = saturate( dot( geometry.clearcoatNormal, geometry.viewDir ) );
        reflectedLight.indirectSpecular += clearcoatRadiance * material.clearcoat * BRDF_Specular_GGX_Environment( geometry.viewDir, geometry.clearcoatNormal, vec3( DEFAULT_SPECULAR_COEFFICIENT ), material.clearcoatRoughness );
        float ccDotNL = ccDotNV;
        float clearcoatDHR = material.clearcoat * clearcoatDHRApprox( material.clearcoatRoughness, ccDotNL );
    #else
        float clearcoatDHR = 0.0;
    #endif
    float clearcoatInv = 1.0 - clearcoatDHR;
    vec3 singleScattering = vec3( 0.0 );
    vec3 multiScattering = vec3( 0.0 );
    vec3 cosineWeightedIrradiance = irradiance * RECIPROCAL_PI;
    BRDF_Specular_Multiscattering_Environment( geometry, material.specularColor, material.specularRoughness, singleScattering, multiScattering );
    vec3 diffuse = material.diffuseColor.xyz * ( 1.0 - ( singleScattering + multiScattering ) );
    reflectedLight.indirectSpecular += clearcoatInv * radiance * singleScattering;
    reflectedLight.indirectSpecular += multiScattering * cosineWeightedIrradiance;
    reflectedLight.indirectDiffuse += diffuse * cosineWeightedIrradiance;
}

vec3 kr_pbr(PhysicalMaterial material, GeometricContext geometry) {
    ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );

    vec3 iblIrradiance = vec3( 0.0 );
    vec3 irradiance = getAmbientLightIrradiance( inverseTransformDirection( geometry.normal, viewMatrix ) );

    vec3 radiance = vec3( 0.0 );
    vec3 clearcoatRadiance = vec3( 0.0 );

    #if defined( USE_ENVMAP )
        iblIrradiance += getLightProbeIndirectIrradiance( geometry, maxMipLevel );
        radiance += getLightProbeIndirectRadiance( geometry.viewDir, geometry.normal, material.specularRoughness, maxMipLevel );
        #ifdef CLEARCOAT
            clearcoatRadiance += getLightProbeIndirectRadiance( geometry.viewDir, geometry.clearcoatNormal, material.clearcoatRoughness, maxMipLevel );
        #endif
    #endif
    
    RE_IndirectDiffuse_Physical( irradiance, geometry, material, reflectedLight );
    RE_IndirectSpecular_Physical( radiance, iblIrradiance, clearcoatRadiance, geometry, material, reflectedLight );
    
    vec3 totalDiffuse = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse;
    vec3 totalSpecular = reflectedLight.directSpecular + reflectedLight.indirectSpecular;

    vec3 outgoingLight = totalDiffuse + totalSpecular/* + totalEmissiveRadiance*/;
    return toneMapping( outgoingLight );
}

`

