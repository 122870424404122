import type {
    Bim, Config, ConfigPatch, EquipmentSlopeAnalysisPaletteConfig,
    IdBimScene, PropertyBase, SceneInstance, SceneInstancePatch} from 'bim-ts';
import { AnyTrackerProps, EquipmentSlopeAnalysisType, FixedTiltTypeIdent, SceneObjDiff, TrackerTypeIdent
} from 'bim-ts';
import { ObjectUtils, LazyDerived, Immer } from 'engine-utils-ts';
import { PUI_GroupNode, PUI_PropertyNodeString, PUI_PropertyNodeNumber, PUI_ActionsNode, PUI_PropertyNodeColor } from 'ui-bindings';

import { autoRange } from '../grid-table-ui/TableUi';
import { patchConfigProperty } from 'layout-service';

interface EquipmentMetrics {
    ids: IdBimScene[][],
    totalNumber: number;
}

export function getData(
    bim: Bim,
    config: Config,
    metrics: EquipmentMetrics
): PUI_GroupNode[] {
    const paletteProps = getProperties(config);

    function patchProperty<T>(value: PropertyBase, path: string[]) {
        const newProps = patchConfigProperty(paletteProps, path, value);
        bim.configs.applyPatchToSingleton(EquipmentSlopeAnalysisType, {
            properties: newProps,
        });
    }

    function patchRange(
        newValue: number,
        position: number,
        prop: "min" | "max"
    ) {
        const patchedProps = ObjectUtils.deepCloneObj(paletteProps);
        const curState: number[] = [];
        for (const s of paletteProps.slices) {
            curState.push(s.min.value);
        }
        curState.push(
            paletteProps.slices[paletteProps.slices.length - 1].max.value
        );
        const ranges = autoRange(
            newValue,
            prop === "min" ? position : position + 1,
            curState
        );
        const newProps = Immer.produce(patchedProps, (draft) => {
            const slices = [];
            for (let i = 0; i < paletteProps.slices.length; i++) {
                const [min, max] = ranges[i];
                const slice = paletteProps.slices[i];
                slices.push({
                    min: slice.min.withDifferentValue(min),
                    max: slice.max.withDifferentValue(max),
                    color: slice.color,
                });
            }
            draft.slices = slices;
        });

        bim.configs.applyPatchToSingleton(EquipmentSlopeAnalysisType, {
            properties: newProps,
        });
    }
    const groups: PUI_GroupNode[] = [];
    const path: string[] = ["slices"];
    for (let i = 0; i < paletteProps.slices.length; i++) {
        const slice = paletteProps.slices[i];
        const sliceId = `${i + 1}`;
        const group = new PUI_GroupNode({
            name: sliceId,
            typeSortKeyOverride: i,
        });
        group.addMaybeChild(
            new PUI_PropertyNodeString({
                name: "id",
                value: `${i + 1}`,
                readonly: true,
                typeSortKeyOverride: 1,
                onChange: () => {},
            })
        );
        group.addMaybeChild(
            new PUI_PropertyNodeNumber({
                name: "min slope",
                value: slice.min.value,
                readonly: slice.min.isReadonly,
                description: slice.min.description,
                unit: slice.min.unit,
                typeSortKeyOverride: 2,
                step: slice.min.step,
                minMax: slice.min.range ?? undefined,
                onChange: (v) => {
                    patchRange(v, i, "min");
                },
            })
        );
        group.addMaybeChild(
            new PUI_PropertyNodeNumber({
                name: "max slope",
                value: slice.max.value,
                readonly: slice.max.isReadonly,
                description: slice.max.description,
                unit: slice.max.unit,
                typeSortKeyOverride: 3,
                step: slice.max.step,
                minMax: slice.max.range ?? undefined,
                onChange: (v) => {
                    patchRange(v, i, "max");
                },
            })
        );
        group.addMaybeChild(
            new PUI_ActionsNode({
                name: "quantity",
                context: undefined,
                typeSortKeyOverride: 4,
                actions: [
                    {
                        label: metrics.ids[i].length.toString(),
                        action: () => {
                            bim.instances.setSelected(metrics.ids[i]);
                        },
                    },
                ],
            })
        );
        group.addMaybeChild(
            new PUI_PropertyNodeNumber({
                name: "share",
                value: (metrics.ids[i].length / metrics.totalNumber || 0) * 100,
                unit: "%",
                readonly: true,
                typeSortKeyOverride: 5,
                step: 0.01,
                onChange: () => {},
            })
        );
        group.addMaybeChild(
            new PUI_PropertyNodeColor({
                name: "color",
                value: slice.color.value,
                readonly: slice.color.isReadonly,
                description: slice.color.description,
                typeSortKeyOverride: 6,
                onChange: (v) => {
                    patchProperty(slice.color.withDifferentValue(v), [
                        ...path,
                        i.toString(),
                        "color",
                    ]);
                },
            })
        );
        groups.push(group);
    }

    return groups;
}

export function getProperties(config:Config):EquipmentSlopeAnalysisPaletteConfig{
    const settings = config.get<EquipmentSlopeAnalysisPaletteConfig>();
    if (!settings?.slices) {
        console.error(
            "the config doesn't belong to the type " + EquipmentSlopeAnalysisType,
            JSON.stringify([config])
        );
        return { slices: [] };
    } else {
        return settings;
    }
}


export function patchConfig(bim:Bim, patch: ConfigPatch) {
    bim.configs.applyPatchToSingleton(EquipmentSlopeAnalysisType, patch);
}

const solarArraysTypes = ["any-tracker", FixedTiltTypeIdent, TrackerTypeIdent];

export function createLazyUiRowData(bim: Bim) {

    const lazyConfig = bim.configs.getLazySingletonOf({type_identifier: EquipmentSlopeAnalysisType});

    const solarArraysInstancesList = bim.instances.getLazyListOfTypes({
        type_identifiers: solarArraysTypes,
        relevantUpdateFlags: SceneObjDiff.LegacyProps | SceneObjDiff.NewProps
    });

    const lazyMetrics = LazyDerived.new2(
        'terrain-metrics',
        null,
        [lazyConfig, solarArraysInstancesList],
        ([config, solarArraysInstances]) => {
            const paletteProps = getProperties(config);
            const equipment = getEquipments(solarArraysInstances);

            const updateEquipmentColor:[IdBimScene, Partial<SceneInstancePatch>][] = [];

            const palette: EquipmentMetrics = {ids: [], totalNumber: equipment.length};
            for (let i = 0; i < paletteProps.slices.length; i++) {
                const slice = paletteProps.slices[i];
                const min = slice.min.as('%');
                const max = slice.max.as('%');
                const ids: IdBimScene[] = [];
                for (const [id, slope] of equipment) {
                    if (i < paletteProps.slices.length - 1 && slope >= min && slope < max) {
                        updateEquipmentColor.push([id, {colorTint: slice.color.value}]);
                        ids.push(id);
                    }else if(slope >= min && slope <= max){
                        updateEquipmentColor.push([id, {colorTint: slice.color.value}]);
                        ids.push(id);
                    }
                }

                palette.ids.push(ids);
            }

            bim.instances.applyPatches(updateEquipmentColor);

            return palette;
        }
    )

    return LazyDerived.new2(
        "surface-analysis",
        null,
        [lazyMetrics, lazyConfig],
        ([metrics, config]) => {
            return getData(bim, config, metrics);
        }
    ).withoutEqCheck();
}

export function getEquipments(instances: [IdBimScene, SceneInstance][]): [id: IdBimScene, slope: number][] {
    const equipmentWithSlope: [id: IdBimScene, slope: number][]=[];
    for (const [id, inst] of instances) {
        let maxSlope: number | undefined = undefined;
        if(inst.type_identifier === 'any-tracker'){
            const props = inst.propsAs(AnyTrackerProps);
            maxSlope = props.position.max_slope_per_bay.as("%");
        } else {
            maxSlope = inst.properties.get('position | max_slope_per_bay')?.as("%");
        }

        if (maxSlope != undefined) {
            equipmentWithSlope.push([id, maxSlope]);
        }
    }

    return equipmentWithSlope.sort((a, b)=>a[1]-b[1]);
}

export function getSlopesRange(bim: Bim): [min: number, max: number] {
    const instances = bim.instances.peekByTypeIdents(solarArraysTypes);
    const equipment = getEquipments(instances);
    let min = Number.MAX_VALUE;
    let max = Number.MIN_VALUE;
    for (const [_, slope] of equipment) {
        min = Math.min(min, slope);
        max = Math.max(max, slope);
    }

    return [min, max];
}
