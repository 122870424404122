import { CostModel as CM } from 'bim-ts';
import { ColDef } from "ag-grid-enterprise";
import { createModifiableCostCellDefinition } from './common';


export const createSubServiceTotalColumnDefinition = (): ColDef<CM.FlattenedCostCategoryParams> => ({
        colId: CM.SubServiceTotalColId,
        headerName: CM.SubServiceTotalColId,
        cellEditor: 'agNumberCellEditor',

        ...createModifiableCostCellDefinition(x => x.subService?.subServiceTotal, true),
    })
