import type { NumberProperty } from "../../properties/PrimitiveProps";
import type { Catalog } from "../Catalog";
import type { CatalogItemsUiLabels, CatalogItemUiLabels } from "../CatalogItemsUiLabels";
import { extractInstanceHierarchyGroup } from "../SceneInstanceHierarchyPropsRegistry";
import type { AssetRelatedCatalogItem } from "./AssetRelatedCatalogItem";

export interface WireSpecCatalogItemPropsBase extends AssetRelatedCatalogItem {
    length_unit: NumberProperty;
    price_per_length: NumberProperty;
}


export function registerWireSpecBaseCatalogItemUiLabels(
    subtype: string,
    provider: CatalogItemsUiLabels,
    catalog: Catalog,
    group?: string,
) {
    const unitsMapper = catalog.unitsMapper;
    provider.register<WireSpecCatalogItemPropsBase>(
        subtype,
        (wire) => {
            const assetId = wire.asset_id.value;
            const asset = catalog.assets.peekById(assetId);
            const si = catalog.assets.sceneInstancePerAsset.getAssetAsSceneInstance(assetId);
            const result: CatalogItemUiLabels = {
                group: [[group ?? subtype]],
                title: 'unknown wire spec',
            }
            if (si) {
                const title = asset?.name || catalog.keyPropertiesGroupFormatters.format(
                    si.type_identifier,
                    si.properties,
                    si.props,
                );
                if (title) {
                    result.title = title;
                }
                const hierarchyProps = extractInstanceHierarchyGroup(si)
                if (hierarchyProps) {
                    result.group.push(...hierarchyProps);
                }
            }
            result.price = wire.price_per_length
                .valueUnitUiString(unitsMapper) + '/' +
                wire.length_unit.valueUnitUiString(unitsMapper);
            return result;
        }
    );
}

