import type { ValueFormatterParams } from "ag-grid-community";
import type { UnitsMapper} from "bim-ts";
import { NumberProperty, PropertyBase, type PropsGroupBase, type PropsGroupField } from "bim-ts";
import { TableRowValueToPropertyType } from "./Models";
import { ObjectUtils } from "engine-utils-ts";


export function meterToFeet(value:number){
    return value * 3.28084;
}

export function round(value: number) {
    if (value === 0 || isNaN(value)) {
        return 0;
    }
    return Math.round(value * 1e3) / 1e3;
}

export function valueFormatter(params: ValueFormatterParams, unitsMapper: UnitsMapper): string {
    if(params.value == null){
        return '';
    }
    const v = TableRowValueToPropertyType(params.value);
    return v instanceof NumberProperty ? v.valueUnitUiString(unitsMapper) : v.value.toString();
}

export function traverseByProperties(
    source: PropsGroupBase,
    callback: (
        prop: PropsGroupField,
        path: (string | number)[],
    ) => void,
    path: (string | number)[] = [],
) {
    callback(source, path);
    for (const key of ObjectUtils.keysIncludingGetters(source)) {
        const currentPath = path.slice();
        currentPath.push(key);

        const prop = source[key];
        if (prop === null || prop instanceof PropertyBase) {
            callback(prop, currentPath);
        } else if (Array.isArray(prop) && prop[0] instanceof PropertyBase) {
            callback(prop, currentPath);
            for (const p of prop) {
                if(p instanceof PropertyBase){
                    callback(p, currentPath);
                } else {
                    console.error('unexpected type', p);
                }
            }
        } else if (Array.isArray(prop)) {
            callback(prop, currentPath);
            for (let i = 0; i < prop.length; i++) {
                const group = prop[i];
                const newPath = currentPath.slice();
                newPath.push(i);
                traverseByProperties(group as any, callback, newPath);
            }
        }else if(typeof prop === 'function') {
            continue;
        } else {
            traverseByProperties(prop, callback, currentPath.slice());
        }
    }
}
