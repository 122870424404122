import { ObjectUtils, ObservableObject } from 'engine-utils-ts';
import { DxfExporterSettings } from './DxfFileExporter';

export interface DxfSettingsProperties extends DxfExporterSettings {};

export class DxfExportContext {
    constructor(
        private obsSettings: ObservableObject<DxfExporterSettings>,
        private submit: () => void,
    ) {
    }

    getSettings() {
        return ObjectUtils.cloneOnlyPrimitives(
            this.obsSettings.poll(),
        ) as DxfSettingsProperties;
    }

    updateSettings(patch: Partial<DxfExporterSettings>) {
        this.obsSettings.applyPatch({ patch });
    }

    exportGroup(name: keyof DxfExporterSettings) {
        this.updateSettings(
            DxfExporterSettings.withSettings({[name]: true}),
        );
        this.submit();
    }
}