// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class ErrorMsg implements flatbuffers.IUnpackableObject<ErrorMsgT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ErrorMsg {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsErrorMsg(bb:flatbuffers.ByteBuffer, obj?:ErrorMsg):ErrorMsg {
  return (obj || new ErrorMsg()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsErrorMsg(bb:flatbuffers.ByteBuffer, obj?:ErrorMsg):ErrorMsg {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ErrorMsg()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

debugComment():string|null
debugComment(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
debugComment(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startErrorMsg(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addDebugComment(builder:flatbuffers.Builder, debugCommentOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, debugCommentOffset, 0);
}

static endErrorMsg(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createErrorMsg(builder:flatbuffers.Builder, debugCommentOffset:flatbuffers.Offset):flatbuffers.Offset {
  ErrorMsg.startErrorMsg(builder);
  ErrorMsg.addDebugComment(builder, debugCommentOffset);
  return ErrorMsg.endErrorMsg(builder);
}

unpack(): ErrorMsgT {
  return new ErrorMsgT(
    this.debugComment()
  );
}


unpackTo(_o: ErrorMsgT): void {
  _o.debugComment = this.debugComment();
}
}

export class ErrorMsgT implements flatbuffers.IGeneratedObject {
constructor(
  public debugComment: string|Uint8Array|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const debugComment = (this.debugComment !== null ? builder.createString(this.debugComment!) : 0);

  return ErrorMsg.createErrorMsg(builder,
    debugComment
  );
}
}
