// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class HeightmapRegular implements flatbuffers.IUnpackableObject<HeightmapRegularT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):HeightmapRegular {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsHeightmapRegular(bb:flatbuffers.ByteBuffer, obj?:HeightmapRegular):HeightmapRegular {
  return (obj || new HeightmapRegular()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsHeightmapRegular(bb:flatbuffers.ByteBuffer, obj?:HeightmapRegular):HeightmapRegular {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new HeightmapRegular()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

xSegmentsCount():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

ySegmentsCount():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

deprecatedHeights(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readFloat32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
}

deprecatedHeightsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

deprecatedHeightsArray():Float32Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? new Float32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

segmentSizeM():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

relativeElevationsCm(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index) : 0;
}

relativeElevationsCmLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

relativeElevationsCmArray():Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? new Uint8Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

elevationsBaseCm():number {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
}

static startHeightmapRegular(builder:flatbuffers.Builder) {
  builder.startObject(6);
}

static addXSegmentsCount(builder:flatbuffers.Builder, xSegmentsCount:number) {
  builder.addFieldInt32(0, xSegmentsCount, 0);
}

static addYSegmentsCount(builder:flatbuffers.Builder, ySegmentsCount:number) {
  builder.addFieldInt32(1, ySegmentsCount, 0);
}

static addDeprecatedHeights(builder:flatbuffers.Builder, deprecatedHeightsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, deprecatedHeightsOffset, 0);
}

static createDeprecatedHeightsVector(builder:flatbuffers.Builder, data:number[]|Float32Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createDeprecatedHeightsVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createDeprecatedHeightsVector(builder:flatbuffers.Builder, data:number[]|Float32Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addFloat32(data[i]!);
  }
  return builder.endVector();
}

static startDeprecatedHeightsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addSegmentSizeM(builder:flatbuffers.Builder, segmentSizeM:number) {
  builder.addFieldFloat64(3, segmentSizeM, 0.0);
}

static addRelativeElevationsCm(builder:flatbuffers.Builder, relativeElevationsCmOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, relativeElevationsCmOffset, 0);
}

static createRelativeElevationsCmVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset {
  builder.startVector(1, data.length, 1);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt8(data[i]!);
  }
  return builder.endVector();
}

static startRelativeElevationsCmVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(1, numElems, 1);
}

static addElevationsBaseCm(builder:flatbuffers.Builder, elevationsBaseCm:number) {
  builder.addFieldInt32(5, elevationsBaseCm, 0);
}

static endHeightmapRegular(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createHeightmapRegular(builder:flatbuffers.Builder, xSegmentsCount:number, ySegmentsCount:number, deprecatedHeightsOffset:flatbuffers.Offset, segmentSizeM:number, relativeElevationsCmOffset:flatbuffers.Offset, elevationsBaseCm:number):flatbuffers.Offset {
  HeightmapRegular.startHeightmapRegular(builder);
  HeightmapRegular.addXSegmentsCount(builder, xSegmentsCount);
  HeightmapRegular.addYSegmentsCount(builder, ySegmentsCount);
  HeightmapRegular.addDeprecatedHeights(builder, deprecatedHeightsOffset);
  HeightmapRegular.addSegmentSizeM(builder, segmentSizeM);
  HeightmapRegular.addRelativeElevationsCm(builder, relativeElevationsCmOffset);
  HeightmapRegular.addElevationsBaseCm(builder, elevationsBaseCm);
  return HeightmapRegular.endHeightmapRegular(builder);
}

unpack(): HeightmapRegularT {
  return new HeightmapRegularT(
    this.xSegmentsCount(),
    this.ySegmentsCount(),
    this.bb!.createScalarList<number>(this.deprecatedHeights.bind(this), this.deprecatedHeightsLength()),
    this.segmentSizeM(),
    this.bb!.createScalarList<number>(this.relativeElevationsCm.bind(this), this.relativeElevationsCmLength()),
    this.elevationsBaseCm()
  );
}


unpackTo(_o: HeightmapRegularT): void {
  _o.xSegmentsCount = this.xSegmentsCount();
  _o.ySegmentsCount = this.ySegmentsCount();
  _o.deprecatedHeights = this.bb!.createScalarList<number>(this.deprecatedHeights.bind(this), this.deprecatedHeightsLength());
  _o.segmentSizeM = this.segmentSizeM();
  _o.relativeElevationsCm = this.bb!.createScalarList<number>(this.relativeElevationsCm.bind(this), this.relativeElevationsCmLength());
  _o.elevationsBaseCm = this.elevationsBaseCm();
}
}

export class HeightmapRegularT implements flatbuffers.IGeneratedObject {
constructor(
  public xSegmentsCount: number = 0,
  public ySegmentsCount: number = 0,
  public deprecatedHeights: (number)[] = [],
  public segmentSizeM: number = 0.0,
  public relativeElevationsCm: (number)[] = [],
  public elevationsBaseCm: number = 0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const deprecatedHeights = HeightmapRegular.createDeprecatedHeightsVector(builder, this.deprecatedHeights);
  const relativeElevationsCm = HeightmapRegular.createRelativeElevationsCmVector(builder, this.relativeElevationsCm);

  return HeightmapRegular.createHeightmapRegular(builder,
    this.xSegmentsCount,
    this.ySegmentsCount,
    deprecatedHeights,
    this.segmentSizeM,
    relativeElevationsCm,
    this.elevationsBaseCm
  );
}
}
