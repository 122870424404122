<script lang="ts">
    import type { UiBindings } from "ui-bindings";
    import { IconComponent } from "../libui/icon";
    import ToolbarItemGroup from "./ToolbarItemGroup.svelte";
    import { menuActionsFromUiBindings } from "./ToolbarItems";
    import { VersionedStore } from "../VersionedStore";
    import { SimpleTooltip } from "src/libui/tooltip";

    export let uiBindings: UiBindings;

    const menuItems = menuActionsFromUiBindings(uiBindings);
    $: menu = new VersionedStore(menuItems);
</script>

<div class="toolbar bg-main-dark text-white-medium">
    {#each $menu.menu as menuItem (menuItem.name)}
        <ToolbarItemGroup menu={menuItem} --height="36px">
            <IconComponent name={menuItem.iconName}></IconComponent>
        </ToolbarItemGroup>
    {/each}
    <div class="divider" />
    {#each $menu.buttons as button (button.name)}
        <SimpleTooltip id={button.name}>
            <svelte:fragment slot="text">
                <span>{button.name}</span>
                <span class="text-white-medium" style:margin-left="8px">
                    {button.hotkeysString}
                </span>
            </svelte:fragment>
            <div class="toolbar-button" on:click={button.action}>
                {#if button.iconName}
                    <IconComponent name={button.iconName}></IconComponent>
                {/if}
            </div>
        </SimpleTooltip>
    {/each}
</div>

<style lang="scss">
    .toolbar {
        width: 44px;
        border-radius: 4px 0px 0px 4px;
        padding: 8px 0px;
        box-sizing: border-box;
        .divider {
            border-top: 2px solid rgba(white, 0.16);
            margin: 8px 12px;
        }
        :global(.mdc-menu-surface) {
            top: -43px;
            transform: translateX(-100%);
        }
        :global(.label-open) {
            color: white;
            background-color: transparent;
        }
        :global(.mdc-tooltip__surface) {
            max-width: 250px;
        }
    }
    .toolbar-button {
        height: 20px;
        padding: 8px 12px;
        cursor: pointer;
        &:hover {
            color: white;
        }
    }
</style>
