<script lang="ts">
    import { getContext } from "svelte";
    import dayjs from "dayjs";
    import type { NotificationsLog } from "./NotificationsLogStore";
    import NotificationsPanelGroup from "./NotificationsPanelGroup.svelte";

    let log = getContext<NotificationsLog>("notificationsLog");

    function convertDateTime(timestamp: number) {
        const logTime = dayjs(timestamp);
        
        const currentTime = dayjs();

        if (logTime.isSame(currentTime, "day")) {
            return logTime.format("HH:mm:ss");
        }
        if (logTime.isSame(currentTime.subtract(1, "days"), "days")) {
            return "Yesterday";
        }

        return logTime.format("DD MMMM");
    }
</script>

<div class="panel">
    <div class="panel-content">
        <div class="panel-header">
            <span class="mdc-typography--subheader2">Notifications</span>
        </div>
        <div class="panel-body mdc-typography--body2 text-main-medium">
            {#if $log.items.length}
                {#each $log.items as group (group.groupId)}
                    <NotificationsPanelGroup
                        {group}
                        {convertDateTime}
                        updateNotification={log.updateNotification.bind(log)}
                    />
                {/each}
            {:else}
                <div class="group">No events</div>
            {/if}
        </div>
    </div>
</div>

<style lang="scss">
    .panel {
        padding: 8px 6px 8px 8px;

        &-content {
            max-height: 544px;
            overflow: auto;
        }
        &-header {
            padding: 0 8px 8px;
            border-bottom: 1px solid var(--color-divider);
            margin-right: 2px;
            display: flex;
            height: 20px;
        }
        &-body {
            min-height: 88px;
        }
    }

    .group {
        padding: 12px 8px;
        margin-right: 2px;
    }
</style>
