<script lang="ts">
    import { getContext } from "svelte";
    import type { Dashboard } from "./Dashboard";

    export let projectId: number;
    export let versionId: number;
    export let size: number;
    const dashboard = getContext<Dashboard>("dashboard");
</script>

<div class="project-screenshot" style="--size:{size}px">
    {#if versionId}
        {#await dashboard.getScreenshot(projectId, versionId) then data}
            <img src={data} class="preview" />
        {:catch}
            <div />
        {/await}
    {/if}
</div>

<style lang="scss">
    .project-screenshot {
        width: var(--size);
        height: var(--size);
        position: relative;
        flex-shrink: 0;
    }
    .preview {
        mix-blend-mode: darken;
        border-radius: 4px;
        text-indent: -10000px;
        width: inherit;
    }
</style>
