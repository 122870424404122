import { Bim, WgsProjectionOrigin } from "bim-ts";
import { DICTIONARY_PROPERTIES, GEODATA_PROPERTIES, LAYOUT_PROPERTIES, TABLE_PROPERTIES, VIEWPORT_PROPERTIES } from "./DxfEntitiesDefaultProperties";
import { DxfFileManager, dxfSectionName, dxfTableType } from "./DxfFileManager";
import { DxfHandle, DxfProperties } from "./DxfNode";
import { BlockTypeFlag } from "./DxfEnums";
import { MetersToFeet, Vector3ToFeet } from "./DxfUnitsConverters";
import { XMLBuilder } from "fast-xml-parser";
import { DXFNodeFactory } from "./DxfFileFactory";

const pileLayer = "pvfarm_piles";

// Root Dictionary interface
export interface Dictionary {
    "@_version": string;
    "@_xmlns":string;
    ProjectedCoordinateSystem: ProjectedCoordinateSystem;
    GeodeticDatum: GeodeticDatum;
  }
  
  export interface ProjectedCoordinateSystem {
    "@_id": string;
    DatumId: string;
    Conversion: Conversion;
  }
  
  interface Conversion {
    Projection: Projection;
  }
  
  interface Projection {
    OperationMethodId: string;
    ParameterValue: ParameterValue[];
  }
  
  export interface ParameterValue {
    OperationParameterId: string;
    Value: {
      "@_uom": string;
      "#text": string;
    };
  }
  
  interface GeodeticDatum {
    "@_id": string;
    PrimeMeridianId: string;
    EllipsoidId: string;
  }

export function getDefaultDxfFile(bim:Bim, filename?:string):DxfFileManager{
    const manager = new DxfFileManager();
    initilizeSections(manager);
    initializeTables(manager);
    setFileDefaultVariables(manager, bim, filename);

    manager.addViewPort("*ACTIVE");
    //Set linetypes
    manager.addLineType("ByBlock");
    manager.addLineType("ByLayer");
    manager.addLineType("Continuous");

    //Set layers
    manager.addLayer("0");
    manager.addLayer(pileLayer);

    //Set TextStyles
    manager.addTextStyle("Arial");

    //Add app id
    manager.addAppId("ACAD");
    manager.addAppId("AcadAnnotative");

    //Add dimension style
    manager.addDimStyle(bim, "Dim");
    manager.addDimStyle(bim, "Standard");

    addDictionaries(manager);

    addLayout(manager);

    addRasterWariables(manager);

    const geodataProps = getGeodataProperties(bim);
    if(geodataProps){
        manager.addGeoData(geodataProps)
    }

    return manager;
}

function addLayout(manager:DxfFileManager){

    const modelspace = manager.addBlock("*Model_Space", BlockTypeFlag.AnonymousBlock);
    const paperspace = manager.addBlock("*Paper_Space", BlockTypeFlag.AnonymousBlock);

    const modelLayoutHandle = DxfHandle.handle();
    const dictHandle = DxfHandle.handle();

    //const paperSpaceBlockDefHandle = manager.getBlockRecord("*Paper_Space")?.properties.get(5) as string;
    const modelSpaceBlockDefHandle = manager.getBlockRecord("*Model_Space")?.properties.get(5) as string;


    const modelLayoutProps = new DxfProperties(LAYOUT_PROPERTIES);
    modelLayoutProps.set(5, modelLayoutHandle);
    modelLayoutProps.set(1, "Model");
    modelLayoutProps.set(330, dictHandle);
    modelLayoutProps.set(330, modelSpaceBlockDefHandle, false);

    const layoutDict = new DxfProperties([
        { code: 5, value: dictHandle },
        { code: 330, value: "A" },
        { code: 100, value: "AcDbDictionary" },
        { code: 281, value: 1 },
        { code: 3, value: "Model"},
        { code: 350, value: modelLayoutHandle }, //model space handle 

    ]);

    manager.addDictionary(layoutDict, "ACAD_LAYOUT");


    
    /* const entities = manager.getSection("ENTITIES");
    const viewPorthandle = DxfHandle.handle();
    const viewPortProps = new DxfProperties(VIEWPORT_PROPERTIES);
    viewPortProps.set(5, viewPorthandle);
    viewPortProps.set(330, paperSpaceBlockDefHandle);
    viewPortProps.set(10, 1110);
    viewPortProps.set(20, 666);
    viewPortProps.set(40, 2000);
    viewPortProps.set(41, 999);
    manager.addNode(entities, "VIEWPORT", viewPortProps);

    const dictProps = new DxfProperties(DICTIONARY_PROPERTIES)
    dictProps.set(5, DxfHandle.handle());
    dictProps.set(330, viewPorthandle);
    dictProps.set(281, 1);

    manager.addDictionary(dictProps); */

}

function addRasterWariables(manager:DxfFileManager){
    const wariableHandle = DxfHandle.handle();
    const rasterWariablesProps = new DxfProperties()
    rasterWariablesProps.set(5, wariableHandle);
    rasterWariablesProps.set(330, "A");
    rasterWariablesProps.set(100, "AcDbRasterVariables");
    rasterWariablesProps.set(90, 0);
    rasterWariablesProps.set(70, 1);
    rasterWariablesProps.set(71, 1);
    rasterWariablesProps.set(72, 6);

    const objects = manager.getSection("OBJECTS");
    manager.addNode(objects, "RASTERVARIABLES", rasterWariablesProps)

    const dictionary = manager.getDictionaries()[0];
    if(dictionary){
        dictionary.properties.set(3, "ACAD_IMAGE_VARS", false);
        dictionary.properties.set(350, wariableHandle, false);
    }
}

function getGeodataProperties( bim:Bim){

    const sceneOrigin = bim.instances.getSceneOrigin();
    const projectGeoDataInfo = sceneOrigin?.wgsProjectionOrigin;

    if(!projectGeoDataInfo){
        return;
    }
    const isImperial = bim.unitsMapper.isImperial();
    const cartesianOrigin = sceneOrigin?.cartesianCoordsOrigin;

    const {longitude, latitude, altitude} = projectGeoDataInfo.wgsOriginLatLong;
    let refPointOrigin = projectGeoDataInfo.wgsOriginCartesianCoords.clone();

    if(cartesianOrigin){
        refPointOrigin.add(cartesianOrigin);
    }

    if(isImperial){
        refPointOrigin = Vector3ToFeet(refPointOrigin);
    }
    

    const options = {
        ignoreAttributes: false,
        attributeNamePrefix: "@_",
    };

    // Create geodata xml
    const builder = new XMLBuilder(options);
    const projectGeoData = createDxfGeodata(projectGeoDataInfo);
    const geodataString = builder.build({Dictionary:projectGeoData});
    const convertedXmlString = proccessXmlString(geodataString, 256);

    const geopoint = `<georss:point>${latitude} ${longitude}</georss:point>`;

    const scaleX = isImperial ? 0.3048:1

    const geoDataProps = new DxfProperties(GEODATA_PROPERTIES);
    geoDataProps.set(5, DxfHandle.handle());
    geoDataProps.set(10, refPointOrigin.x);
    geoDataProps.set(20, refPointOrigin.y);
    geoDataProps.set(30, 0);
    geoDataProps.set(11, longitude);
    geoDataProps.set(21, latitude);
    geoDataProps.set(31, 0);
    geoDataProps.set(40, scaleX);
    geoDataProps.set(41, scaleX);
    
    for(let i=0; i<convertedXmlString.length; i++){
        const xmlStringPart = convertedXmlString[i];
        if(i===0){
            geoDataProps.set(303, xmlStringPart);
        }else if(i===convertedXmlString.length-1){
            geoDataProps.set(301, xmlStringPart);
        }else{
            geoDataProps.set(303, xmlStringPart, false);
        }
    }

    geoDataProps.set(302, geopoint);
    geoDataProps.set(302, "");
    geoDataProps.set(305, "");
    geoDataProps.set(306, "");
    geoDataProps.set(307, "");

    return geoDataProps;
}


function initilizeSections(manager:DxfFileManager) {
    const sections: dxfSectionName[] = [
        "HEADER",
        "CLASSES",
        "TABLES",
        "BLOCKS",
        "ENTITIES",
        "OBJECTS",
    ];

    for (const name of sections) {
        const sectionCloseNode = manager.createNode("ENDSEC");
        manager.addNode(
            manager.root,
            "SECTION",
            new DxfProperties({ code: 2, value: name }),
            [],
            sectionCloseNode
        );
    }
}

function initializeTables(manager:DxfFileManager):void {
    const tables: dxfTableType[] = [
        "VPORT",
        "LTYPE",
        "LAYER",
        "STYLE",
        "VIEW",
        "UCS",
        "APPID",
        "DIMSTYLE",
        "BLOCK_RECORD",
    ];

    const tablesSection = manager.getSection("TABLES");

    for (const name of tables) {
        const sectionCloseNode = manager.createNode("ENDTAB");
        const props: DxfProperties = new DxfProperties(TABLE_PROPERTIES);
        props.set(2, name);
        props.set(5, DxfHandle.handle());

        if (name === "DIMSTYLE") {
            props.set(100, "AcDbDimStyleTable", false);
            props.set(71, 0);
        }

        manager.addNode(tablesSection, "TABLE", props, [], sectionCloseNode);
    }
}

function setFileDefaultVariables(manager:DxfFileManager, bim?: Bim, fileName?:string) {
    const isImperial = bim?.unitsMapper.isImperial();
    const name = fileName ? fileName : "Solar farm"
    //AutocadVersion
    manager.addVariable("ACADVER", 1, "AC1032");

    manager.addVariable("PROJECTNAME", 1, name);
    manager.addVariable("LUNITS", 70, isImperial ? 5: 2); //set units to feet
    manager.addVariable("INSUNITS", 70, isImperial ? 2:6); //set units to feet
    manager.addVariable("MEASUREMENT", 70, isImperial ? 0:1); //set units to feet

    manager.addVariable("TEXTSTYLE", 7, "Arial");
    manager.addVariable("DIMSTYLE", 2, "Dim");
    manager.addVariable("HANDSEED", 5, 1000000);

    //Common dimention variables
    manager.addVariable("DIMDLI", 40, 0.4);
    manager.addVariable("DIMEXE", 40, 0.2);
    manager.addVariable("DIMTXT", 40, 0.25);
    manager.addVariable("DIMCEN", 40, 0.1);
    manager.addVariable("DIMTIH", 70, 0);
    manager.addVariable("DIMTOH", 70, 0);
    manager.addVariable("DIMTAD", 70, 1);
    manager.addVariable("DIMALTF", 40, 25.0);
    manager.addVariable("DIMGAP", 40, 0.1);
    manager.addVariable("DIMLUNIT", 70, 5);
    manager.addVariable("DIMTXSTY", 7, "Arial");

    manager.addVariable("NORTHDIRECTION", 40, 0);
    manager.addVariable("TIMEZONE", 70, 1);

    const referencePointLonLat = bim?.instances.getSceneOrigin()?.wgsProjectionOrigin?.wgsOriginLatLong;

    if(!referencePointLonLat){
        return;
    }

    const {longitude, latitude, altitude} = referencePointLonLat;

    if(Number.isFinite(longitude) && Number.isFinite(latitude)){
        manager.addVariable("LATITUDE", 40, latitude);
        manager.addVariable("LONGITUDE", 40, longitude); 
    }
}

function addDictionaries(manager:DxfFileManager) {
    
    const rootDictHandle = DxfHandle.handle();
    const props1 = new DxfProperties([
        { code: 5, value: rootDictHandle},
        { code: 330, value: 0 },
        { code: 100, value: "AcDbDictionary" },
        { code: 281, value: 1 },

    ]);

    const d1 = manager.addDictionary(props1);

    const props2 = new DxfProperties([
        { code: 5, value: DxfHandle.handle() },
        { code: 330, value: rootDictHandle },
        { code: 100, value: "AcDbDictionary" },
        { code: 281, value: 1 },
    ]);

    const d2 = manager.addDictionary(props2, "ACAD_GROUP");

    return [d1, d2];
}

function proccessXmlString(xmlString: string, maxLength: number = 256): string[] {

    const modifiedString = xmlString.replace(/\n/g, "^J");

    const result: string[] = [];
    let currentSlice = "";
  
    for (let i = 0; i < modifiedString.length; i++) {

      currentSlice += modifiedString[i];
  
      if (currentSlice.length >= maxLength || i === modifiedString.length - 1) {

        if (currentSlice.endsWith("^") && i < modifiedString.length - 1 && modifiedString[i + 1] === "J") {

          currentSlice = currentSlice.slice(0, -1);
          result.push(currentSlice);
          currentSlice = "^";
        } else {

          result.push(currentSlice);
          currentSlice = "";
        }
      }
    }
  
    return result;
  }


function createDxfGeodata(geodataInfo: WgsProjectionOrigin, isImperial:boolean = true) {
    const projectionData = geodataInfo.projectionInfo;

    const parametersMap = projectionData.parameters;

    const projectionId = parametersMap.get("ProjectionId") || "";
    const datumId = parametersMap.get("GeoteticDatumId") || "NAD83";
    const ellipsoidName = parametersMap.get("EllipsoidName") || "GRS1980";
    const primeMeridianId = parametersMap.get("PrimeMeridianId") || "Greenwich";



    // Remove the extracted keys from the map
    const parameterValues: ParameterValue[] = Array.from(parametersMap)
        .filter(
            ([key]) =>
                ![
                    "ProjectionId",
                    "GeoteticDatumId",
                    "EllipsoidName",
                    "PrimeMeridianId",
                    "SemiMajorAxis",
                    "SemiMinorAxis",
                    "geoteticDatum",
                    "PrimeMeridianId"

                ].includes(key)
        )
        .map(([key, value]) => {
            let units = "degree";
            let valueInUnits = value;
            if(key === "False easting" || key === "False northing"){
                units = isImperial ? "FOOT" : "METER"
                valueInUnits = isImperial ? MetersToFeet(parseFloat(value) || 0).toString() : value;           
            }
            return {
                OperationParameterId: key,
                Value: {
                    "@_uom": units,
                    "#text": valueInUnits,
                },
            };
        });

      
    const projectGeoData: Dictionary = {
        "@_version": "1.0",
        "@_xmlns": "http://www.osgeo.org/mapguide/coordinatesystem",
        ProjectedCoordinateSystem: {
            "@_id": projectionId,
            DatumId: datumId,
            Conversion: {
                Projection: {
                    OperationMethodId: projectionData.method,
                    ParameterValue:parameterValues,
                },
            },
        },
        GeodeticDatum: {
            "@_id": datumId,
            PrimeMeridianId:primeMeridianId,
            EllipsoidId: ellipsoidName,
        },
    };

    return projectGeoData;
}








