

import type { IdBimScene} from 'bim-ts';
import { SceneInstanceFlags } from 'bim-ts';
import type {
	Disposable, LazyVersioned, ObservableObject, ScopedLogger,  UndoStack,
    VersionedValue
} from 'engine-utils-ts';
import {
    LazyDerived
} from 'engine-utils-ts';
import type { Matrix4, Plane} from 'math-ts';
import { Vector3 } from 'math-ts';

import type { EngineControlsState } from '../EngineControlsState';
import type { CommonArgs } from '../KreoEngineImpl';
import type { EngineScene } from '../scene/EngineScene';
import type { InteractiveObjectsActive} from '../scene/InteractiveSceneObjects';
import { TransformsProviderType } from '../scene/InteractiveSceneObjects';
import type { GraphicsSettings } from '../GraphicsSettings';
import type { SceneInt } from '../scene/SceneRaycaster';
import { EditGizmoIntersection } from '../scene/Raycasts';
import type { FrustumExt } from '../structs/FrustumExt';
import type { RaySection } from '../structs/RaySection';
import { EditOperators } from './EditOperators';
import { MouseButton } from './InputController';
import { InteractiveBoundaryAdder } from './InteractiveBoundary';
import { InteractiveBoundaryAround } from './InteractiveBoundaryAround';
import { InteractiveBoundaryOffset } from "./InteractiveBoundaryOffset";
import { InteractiveObjectsCloneArray } from './InteractiveObjectsCloneArray';
import { InteractiveRoadAdder } from './InteractiveRoadAdder';
import { InteractiveRoadAround } from './InteractiveRoadAround';
import { InteractiveTrenchAdder } from './InteractiveTrenchAdder';
import type { MouseClickConsumer, MouseDragConsumer, MouseDragInfo, MouseEventData, MouseGestureConsumer
} from './MouseGesturesBase';
import {
    GesturesButtons
} from './MouseGesturesBase';
import type { InObjFullId } from '../scene/EngineSceneIds';
import { EngineControlsMode } from '../EngineConsts';
import type { UiBindings} from 'ui-bindings';
import { KeyModifiersFlags } from 'ui-bindings';
import { InteractiveRoadsBoundariesTrim } from './InteractiveRoadsBoundariesTrim';
import { InteractiveBoundaryByTerrainElevation } from './InteractiveBoundaryByTerrainElevation';
import type { TerrainDisplayEngineSettings } from 'src/TerrainDisplayEngineSettings';
import { InteractiveBoundaryByTerrainSlope } from './InteractiveBoundaryByTerrainSlope';

export class EditModeState {
	isEditActivated: boolean = false;
}

export class EditModeControls implements VersionedValue {

    // readonly editGizmoObjects: SceneGizmoObjects;
	readonly controlsState: ObservableObject<EngineControlsState>;

	readonly editModeState: ObservableObject<EditModeState>;

    readonly undoStack: UndoStack;
    readonly logger: ScopedLogger;

    _version: number = 0;
	engineScene: EngineScene;
	uiBindings: UiBindings;

    editOperators: EditOperators;

	_editableObjectsIds: LazyVersioned<IdBimScene[]>;
	_editableObjectsSetter: LazyDerived<void>;
	interactiveObjectsWrapper: InteractiveObjectsActive;

	_toDispose: Disposable[] = [];

	constructor(
		renderSettings: ObservableObject<GraphicsSettings>,
		controlsState: ObservableObject<EngineControlsState>,
		engineScene: EngineScene,
		commonArgs: CommonArgs,
		uiBindings: UiBindings,
		interactiveObjectsWrapper: InteractiveObjectsActive,
		terrainDisplaySettings: ObservableObject<TerrainDisplayEngineSettings>,
	) {
        this.undoStack = commonArgs.undoStack;
        this.logger = commonArgs.logger.newScope('edit-controls');
		this.controlsState = controlsState;
		this.engineScene = engineScene;
		this.uiBindings = uiBindings;

		this.interactiveObjectsWrapper = interactiveObjectsWrapper;

        this.editOperators = new EditOperators(commonArgs.logger, commonArgs.undoStack);
        this.editOperators.registerHandler(new InteractiveObjectsCloneArray(this.engineScene, commonArgs.undoStack));
        this.editOperators.registerHandler(new InteractiveRoadAdder(this.engineScene.bim, this.engineScene, interactiveObjectsWrapper, this, this.undoStack));
        this.editOperators.registerHandler(new InteractiveRoadAround(this.engineScene.bim, this, this.undoStack));
        this.editOperators.registerHandler(new InteractiveRoadsBoundariesTrim(this.engineScene.bim, this.engineScene, this.undoStack));
		this.editOperators.registerHandler(new InteractiveTrenchAdder(this.engineScene.bim, this.engineScene, interactiveObjectsWrapper, this, this.undoStack));
        this.editOperators.registerHandler(new InteractiveBoundaryAdder(this.engineScene.bim, this, this.undoStack));
		this.editOperators.registerHandler(new InteractiveBoundaryOffset(this.engineScene.bim, this, this.undoStack));
		this.editOperators.registerHandler(new InteractiveBoundaryAround(this.engineScene.bim, this, this.undoStack));
		this.editOperators.registerHandler(new InteractiveBoundaryByTerrainElevation(this.engineScene.bim, this, this.undoStack, terrainDisplaySettings));
		this.editOperators.registerHandler(new InteractiveBoundaryByTerrainSlope(this.engineScene.bim, this, this.undoStack, terrainDisplaySettings));

		this.editModeState = this.controlsState.newChainedObservable(
			(patch) => {
				if (patch.controlsMode !== undefined) {
					return {isEditActivated: patch.controlsMode === EngineControlsMode.Edit }
				}
				return {};
			},
		);
		this.editModeState.observeObject({
			settings: { immediateMode: true, doNotNotifyCurrentState: true },
			onPatch: ({patch}) => {
				if (patch.isEditActivated !== undefined) {
					this._chooseAndSwitchProvider();
				}
			}
		});

		this._toDispose.push((engineScene.bim.instances.lockEvents.subscribe({
			settings: {immediateMode: true},
			onNext: () => {
				this._chooseAndSwitchProvider();
			}
		})))

		this._editableObjectsIds = LazyDerived.new2(
			'editable-objects-getter',
			[],
			[this.editModeState, engineScene.bim.instances.selectHighlight.getVersionedFlagged(SceneInstanceFlags.isSelected)],
			([editState, selectIds]) => {
				if (!editState.isEditActivated) {
					return [] as IdBimScene[];
				}
				return selectIds;
			}
		);
		this._editableObjectsSetter = LazyDerived.new1(
			'editable-objects-flags-setter',
			[this.editModeState],
			[this._editableObjectsIds],
			([ids]) => {
				engineScene.esos.setEditableObjects(ids);
			}
		);
	}

	syncEditables() {
		this._editableObjectsSetter.poll();
	}

	_chooseAndSwitchProvider() {
		let provider: TransformsProviderType;
		if (this.engineScene.bim.instances.isLocked()) {
			provider = TransformsProviderType.None;
		} else if (this.editModeState.poll().isEditActivated) {
			provider = TransformsProviderType.EditMeshes;
		} else {
			provider = TransformsProviderType.Bim;
		}
		if (this.interactiveObjectsWrapper.type() !== provider) {
			this._version += 1;

			const activeOperator = this.editOperators.getActiveOperator();
			if (provider !== TransformsProviderType.EditMeshes && activeOperator) {
				this.editOperators.finishEdit();
			}

			this.interactiveObjectsWrapper.switchProvider(provider);
		}
	}

	version(): number {
		return this._version;
	}

	isEnabled() {
		return this.controlsState.poll().controlsMode == EngineControlsMode.Edit;
	}

	clear() {
		// this.activeRulerId = null;
		this._version += 1;
	}

	update(frustum: FrustumExt, _ray: RaySection): boolean {

		let updated = false;

		if (!this.isEnabled()) {
			return updated;
		}

		return true;
	}

	dispose(): void {
		this.clear();
	}
}

export class EditControlsGesturesConsumer implements MouseGestureConsumer {

	readonly clickConsumers: MouseClickConsumer[] = [];
	readonly dragConsumers: MouseDragConsumer<any>[] = [];

    constructor(
        readonly editControls: EditModeControls,
    ) {
        this.dragConsumers.push(new EditControlsMouseDragConsumer(
            editControls,
            GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.None),
        ));
        this.dragConsumers.push(new EditControlsMouseDragConsumer(
            editControls,
            GesturesButtons.newShared(MouseButton.Left, KeyModifiersFlags.Ctrl),
        ));
    }

    isEnabled(): boolean {
        return this.editControls.isEnabled();
    }
}

interface GizmoEditDragState {
    readonly dragPlane: Plane;
    readonly intersStartWS: Vector3;
	readonly startMatrices: Map<InObjFullId, Matrix4>;
}

class EditControlsMouseDragConsumer implements MouseDragConsumer<GizmoEditDragState> {

    constructor(
        readonly editModeControls: EditModeControls,
        readonly buttons: GesturesButtons,
    ) {
        this.buttons = buttons;
	}

    onButtonDown(sceneInt: SceneInt, me: MouseEventData): boolean {
        return true;
    }

    tryStartDrag(
        med: MouseEventData, mouseDragInfo: MouseDragInfo
    ): GizmoEditDragState | null
    {
        const intersPoint = mouseDragInfo.sceneInt?.point;
        if (!intersPoint) {
            return null;
        }
		return null;

        // const normal = new Vector3().setFromMatrixColumn(med.camera.matrixWorld, 2);
        // const plane = Plane.allocateZero().setFromNormalAndCoplanarPoint(normal, intersPoint);

        // const objId = (mouseDragInfo.sceneInt as EditGizmoIntersection).gizmoSubState.id;
        // const objects = this.editModeControls.interactiveEditObjects;

        // let idsToUse: InObjFullId[];

        // if (this.buttons.modifierKeys === KeyModifiersFlags.Ctrl) {
        //     const cloned = objects.cloneObjects([objId], mouseDragInfo.worldSpaceDirection);
        //     idsToUse = cloned.newObjects;
        // } else {
        //     idsToUse = objects.getSelected();
        //     if (!idsToUse.includes(objId)) {
        //         idsToUse = [objId];
        //     }
        // }

        // if (idsToUse.length === 0) {
        //     return null;
        // }
        // objects.setSelected(idsToUse);

        // const startMatrices = objects.getWorldMatricesOf(idsToUse);

        // return {
        //     dragPlane:plane,
        //     intersStartWS: intersPoint,
        //     startMatrices,
        // }
    }
	handleDrag(dragState: GizmoEditDragState, me: MouseEventData): boolean {

        const newIntersPoint = me.mouseCone?.raySection.ray.intersectPlane_t(dragState.dragPlane, new Vector3());
        if (!newIntersPoint) {
            return false;
        }
        const intersectionsDiff = newIntersPoint.clone().sub(dragState.intersStartWS);
		const transformsReplacement = new Map<InObjFullId, Matrix4>();

        for (let [id, tr] of dragState.startMatrices) {
            tr = tr.clone();
            tr.addToPosition(intersectionsDiff);
            transformsReplacement.set(id, tr);
        }

        // this.editModeControls.interactiveEditObjects.patchWorldMatrices(transformsReplacement, {});
		return true;
	}

    cursorDragStyle() {
        return 'grabbing'
    }
    sceneRaycastTypeguard(closest: SceneInt): boolean {
        return closest instanceof EditGizmoIntersection;
    }

	onButtonUp(s: GizmoEditDragState, me: MouseEventData): void {
        // this.controls.tilesets.startNewDefferedUndo();
    }

	stop(): void {
    }

}

