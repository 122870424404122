import type { CompressibleNumbersArray} from 'engine-utils-ts';
import { Failure } from 'engine-utils-ts';
import type { NumberProperty } from '../properties/PrimitiveProps';
import type { NumbersArrayProperty } from '../properties/spreadsheets/NumbersArrayProperty';
import { EnergyYieldPropsGroup } from './EnergyYieldPropsGroup';
import { EnergyFailure, energySucess } from './EnergyResult';
import { EnergyStageChartSimpleLoss } from './EnergyStageCharts';
import type { EnergyStageName} from './EnergyPipelineProperty';
import { EnergyPipelineStage, EnergyPipelineProperty, AdditionalEnergyYieldMeteoContext } from './EnergyPipelineProperty';
import type { ShadingFactorsTable } from 'src/trackers/shading/ShadingFactorsTable';


export class EnergyPipelineBuilder {

    nominal_dc_power: number;
    nominal_power_chart: CompressibleNumbersArray | null = null;
    stages: EnergyPipelineStage[] = [];
    additional_context: { [key: string]: NumbersArrayProperty | NumberProperty; } = {};
    modules_area: number;
    shading_factors: any;

    constructor(args: {
        nominal_dc_power: number,
        modules_area: number,
        shading_factors: ShadingFactorsTable|null, 
    }) {
        this.nominal_dc_power = args.nominal_dc_power;
        this.modules_area = args.modules_area;
        this.shading_factors = args.shading_factors;
    }

    addContext(key: string, value: NumbersArrayProperty | NumberProperty) {
        this.additional_context[key] = value;
    }

    addStage(stage: EnergyPipelineStage) {
        this.stages.push(stage);
    }

    addNominalPowerChart(nominalPower: CompressibleNumbersArray) {
        if (this.nominal_power_chart) {
            throw new Error('nominal power chart already set');
        }
        this.nominal_power_chart = nominalPower;
    }

    addPrevStageBasicMultiplier(stageIdent: EnergyStageName, multiplier: number) {
        for (let i = this.stages.length - 1; i >= 0; --i) {
            const prevStage = this.stages[i];
            if (prevStage.energy instanceof Failure) {
                continue;
            }
            const chart = new EnergyStageChartSimpleLoss(prevStage.energy.value, multiplier);
            const newStage = new EnergyPipelineStage(stageIdent, energySucess(chart));
            this.stages.push(newStage);
            return;
        }
        this.stages.push(new EnergyPipelineStage(stageIdent, EnergyFailure.new('InvalidPreviousStage')));
    }

    finishGroup(): EnergyYieldPropsGroup {
        if (!this.nominal_power_chart) {
            throw new Error('nominal power chart not set');
        }
        const group = new EnergyYieldPropsGroup({
            pipeline: new EnergyPipelineProperty(
                this.stages,
                null,
                this.nominal_power_chart,
                new AdditionalEnergyYieldMeteoContext(this.additional_context),
                this.nominal_dc_power,
                this.modules_area,
                this.shading_factors,
            ),
        });
        return group;
    }
}
