<script lang="ts">
import type { List} from "./types";
import { ListItem } from "./types";
import { MDCList } from '@material/list'
import ListItemComponent from './ListItemContent.svelte';
import { onMount } from "svelte";

export let list: List;

let listEl: HTMLElement;
let mdcList: MDCList;

onMount(() => {
  mdcList = new MDCList(listEl);
  mdcList.singleSelection = false;
})

$: secondLinePresent = !!list.items
  .find(x => x instanceof ListItem && typeof x.secondary === 'string');
</script>

<ul
  bind:this={listEl}
  class="mdc-deprecated-list mdc-deprecated-list--dense"
  class:mdc-deprecated-list--two-line={secondLinePresent}
  style="--item-size:{list.options.listItemSize}px;"
>
  {#each list.items as item, index}
    <ListItemComponent
      {item}
      {index}
      secondLine={secondLinePresent}
      showSeparator={!!list.options.itemsSeparated}
    />
  {/each}
</ul>

<style lang="scss">
  .mdc-deprecated-list--dense :global(.mdc-deprecated-list-item) {
    height: var(--item-size);
  }
</style>
