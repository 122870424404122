import type { LazyVersioned } from "engine-utils-ts";
import { LazyDerivedAsync } from "engine-utils-ts";
import type { Bim, TrackerPilesCollection } from "src";
import { NumberProperty } from "src";
import type { CostsConfigProvider, EstimateCost, } from "src/cost-model/capital";
import { CostHierarchy, StructuralTopCategoryName, createEmptyCostComponentsNonNullable, fillModelBasedTopLevelCategory } from "src/cost-model/capital";
import { create_Racking } from "./racking";
import { create_Piles } from "./piles";

export function create_StructuralSubtotal(
    bim: Bim,
    costs: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>,
    piles: TrackerPilesCollection,
) {
    const unitsMapper = bim.unitsMapper;

    const racking = create_Racking(bim, costs, totalDC);
    const pilesHier = create_Piles(bim, costs, totalDC, piles);

    const result = LazyDerivedAsync.new4<
        CostHierarchy,

        CostHierarchy,
        CostHierarchy,

        EstimateCost[],
        NumberProperty
    >(
        'StructuralSubtotal',
        [unitsMapper],
        [racking, pilesHier, costs.allEstimateCosts, totalDC],
        function* ([racking, piles, estimates, totalDC]) {
            const hierarchy = new CostHierarchy().merge(racking, piles);

            // misc
            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'Structural General Conditions' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.laborCost = NumberProperty.new({ value: 0.01 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'structural-general-condition-misc',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            // misc
            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'Structural Logistics & Misc' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.laborCost = NumberProperty.new({ value: 0.02 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'structural-logistic-misc',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }


            // create module install each category
            {
                const root = hierarchy.addRoot({ description: { value: StructuralTopCategoryName } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.materialCost = NumberProperty.new({ value: 0.09 + 0.06 });
                defaults.laborCost = NumberProperty.new({ value: 0.02 + 0.01 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    hierarchy,
                    'structural-subtotal-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
            }

            return hierarchy;
        }
    )

    return result;
}
