// automatically generated by the FlatBuffers compiler, do not modify

import type * as flatbuffers from 'flatbuffers';



export class WgsCoord implements flatbuffers.IUnpackableObject<WgsCoordT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):WgsCoord {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

lat():number {
  return this.bb!.readFloat64(this.bb_pos);
}

lon():number {
  return this.bb!.readFloat64(this.bb_pos + 8);
}

alt():number {
  return this.bb!.readFloat64(this.bb_pos + 16);
}

static sizeOf():number {
  return 24;
}

static createWgsCoord(builder:flatbuffers.Builder, lat: number, lon: number, alt: number):flatbuffers.Offset {
  builder.prep(8, 24);
  builder.writeFloat64(alt);
  builder.writeFloat64(lon);
  builder.writeFloat64(lat);
  return builder.offset();
}


unpack(): WgsCoordT {
  return new WgsCoordT(
    this.lat(),
    this.lon(),
    this.alt()
  );
}


unpackTo(_o: WgsCoordT): void {
  _o.lat = this.lat();
  _o.lon = this.lon();
  _o.alt = this.alt();
}
}

export class WgsCoordT implements flatbuffers.IGeneratedObject {
constructor(
  public lat: number = 0.0,
  public lon: number = 0.0,
  public alt: number = 0.0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return WgsCoord.createWgsCoord(builder,
    this.lat,
    this.lon,
    this.alt
  );
}
}
