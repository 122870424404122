import type { EventStackFrame, RGBAHex, UndoStack, Observer, LazyVersioned, Result} from 'engine-utils-ts';
import { RefsCounter, StringUtils} from 'engine-utils-ts';
import {
	DefaultMap, IterUtils, LegacyLogger, ObjectUtils, ObservableStream,
	RGBA, RgbaPalette, Deleted, ObservableObject, LazyDerived, Failure, Success, DefaultRgbaPalette
} from 'engine-utils-ts';
import type { Aabb} from 'math-ts';
import { Matrix4, Transform, Vector3 } from 'math-ts';
import type { EntityId, EntityIdAny} from 'verdata-ts';
import { entityTypeFromId } from 'verdata-ts';

import type { PropertiesPatch} from '../bimDescriptions/PropertiesCollection';
import {
	PropertiesCollection
} from '../bimDescriptions/PropertiesCollection';
import { BasicPatchesMerger, EntitiesBase } from '../collections/EntitiesBase';
import type { EntitiesCollectionUpdates} from '../collections/EntitiesCollectionUpdates';
import { EntitiesUpdated } from '../collections/EntitiesCollectionUpdates';
import { EntitiesLazyLists } from '../collections/EntitiesLazyLists';
import { SparseFlaggedSets } from '../collections/SparseFlaggedSets';
import type { AnyBimGeometry, BimGeometries, IdBimGeo } from '../geometries/BimGeometries';
import type {
	CollectionAdditionalContext,
} from '../persistence/CollectionAdditionalContext';
import { EmptyPropsStub } from '../properties/EmptyPropsStub';
import { PropsGroupBase, PropsPatch, applyPatchToProps } from '../properties/Props';
import { PropsFieldFlags } from '../properties/PropsGroupComplexDefaults';
import type {
	ObjectRepresentation} from '../representation/Representations';
import {
	BasicAnalyticalRepresentation,
	StdGroupedMeshRepresentation,
	StdMeshRepresentation,
} from '../representation/Representations';
import { BimSceneOrigin } from './BimSceneOrigin';
import {
	Hierarchy, HierarchyDirtyFlags, sortBatchByParentFirst,
} from './Hierarchy';
import {
	applyFlagsPatch, FlagsMask, FlagsPatchMask, newFlagsPatch, NoUndoFlags,
	NoUndoFlagsPatch, SceneInstanceFlags,
} from './SceneInstanceFlags';
import type { SceneInstancePropsShapeType} from './SceneInstancesArhetypes';
import { SceneInstancesArchetypes } from './SceneInstancesArhetypes';
import { SceneInstancesPropsShapeHooks } from './SceneInstanesShapeHooks';
import { InstancesBasicPropsView } from './InstancesBasicPropsView';
import type { BimMaterials } from '../BimMaterials';
import type { BimImages } from '../BimImages';
import type { UnitsMapper } from '../UnitsMapper';
import { LvWireTypeIdent } from '../archetypes/LvWire';
import { SceneObjDiff } from './SceneObjDiff';

export enum SceneIdType {
	Default = -1,
}


export type IdBimScene = EntityId<SceneIdType> | 0

export class SceneInstance implements SceneInstancePropsShapeType {

	constructor(
		// -- persisted state --
		public type_identifier: string = '',
		public name: string = '',
		public localTransform: Transform = new Transform(),

		public spatialParentId: IdBimScene = 0,
		public electricalParentId: IdBimScene = 0,

		public properties: PropertiesCollection = new PropertiesCollection(),

        public representation: Readonly<ObjectRepresentation> | null = null,
        public representationAnalytical: Readonly<BasicAnalyticalRepresentation> | null = null,

		public flags: SceneInstanceFlags = SceneInstanceFlags.None,
		public colorTint: RGBAHex | 0 = 0,
		public readonly worldMatrix: Matrix4 = new Matrix4(),

		public hierarchySortKey: number = 0,
		public connectedTo: Readonly<IdBimScene[]> | null = null,

        public props: PropsGroupBase = EmptyPropsStub,
	) {
	}


	get isHidden() { return (this.flags & SceneInstanceFlags.isHidden) !== 0; }
	get isSelected() { return (this.flags & SceneInstanceFlags.isSelected) !== 0; }
	get isHighlighted() { return (this.flags & SceneInstanceFlags.isHighlighted) !== 0; }

    static geometriesIdsReferences(self: SceneInstance, result: EntityIdAny[]): void {
        self.representation?.geometriesIdsReferences(result);
		self.representationAnalytical?.geometriesIdsReferences(result);
    }
    static materialIdsReferences(self: SceneInstance, result: EntityIdAny[]): void {
        self.representation?.materialIdsReferences(result);
    }
    static imageIdsReferences(self: SceneInstance, result: EntityIdAny[]): void {
        self.representation?.imagesReferenced(result);
    }

    propsAs<PropsClass extends PropsGroupBase>(propsClass: {new(...args: any[]): PropsClass}): PropsClass {
        if (this.props instanceof propsClass) {
            return this.props as PropsClass;
        }
        throw new Error(`invalid props class requested for ${this.type_identifier}: ${propsClass.name} vs ${this.props.constructor.name}`)
    }
}


export type SceneInstancePatch = Partial<
	Omit<SceneInstance, 'type_identifier' | 'properties' | 'components' | 'props'> & {
        properties: PropertiesPatch,
		props: PropsPatch | PropsGroupBase
    }
>;


export class SceneInstances extends EntitiesBase<SceneInstance, IdBimScene, SceneObjDiff, SceneInstancePatch> {

	// reduntant sets of selected and hightlighted objects
	// to allow fast operations for setSelected, setHighlighted, etc
	readonly selectHighlight = new SparseFlaggedSets<IdBimScene, SceneInstanceFlags>(
		SceneInstanceFlags.isSelected | SceneInstanceFlags.isHighlighted
	);
	readonly spatialHierarchy: Hierarchy;
	readonly electricalHierarchy: Hierarchy;

	readonly perIdTypeIdent: Map<IdBimScene, string>;
	readonly basicPropsView: InstancesBasicPropsView;

	private _sceneOrigin: ObservableObject<BimSceneOrigin>;

    readonly archetypes: SceneInstancesArchetypes;

	private readonly _propsShapeHooks: SceneInstancesPropsShapeHooks;

	readonly deletedItemsStream: ObservableStream<EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>>;

	readonly _bimGeometries: BimGeometries;

	readonly _geometriesChangesSub: Observer;

	readonly _lazyLists: EntitiesLazyLists<SceneInstance, IdBimScene, SceneObjDiff>;

	constructor(
		bimGeometries: BimGeometries,
        bimMaterials: BimMaterials,
        bimImages: BimImages,
		unitsMapper: UnitsMapper,
		undoStack?: UndoStack,
	) {
		super({
			identifier: "bim-scene-objects",
			idsType: SceneIdType.Default,
			undoStack,
			T_Constructor: SceneInstance,
			diffFlagsToNotUndo: SceneObjDiff.Highlighted,
			collectionsRerenced: [
                {
                    collection: bimGeometries,
                    idsFromState: SceneInstance.geometriesIdsReferences,
					externalRefsChecker: new RefsCounter(),
                },
                {
                    collection: bimMaterials,
                    idsFromState: SceneInstance.materialIdsReferences,
					externalRefsChecker: new RefsCounter(),
                },
                {
                    collection: bimImages,
                    idsFromState: SceneInstance.imageIdsReferences,
					externalRefsChecker: new RefsCounter(),
                }
            ]
		});
		this.perIdTypeIdent = new Map();

		this._sceneOrigin = new ObservableObject({
			identifier: 'scene-origin',
			undoStack: this.undoStack ?? undefined,
			initialState: new BimSceneOrigin(),
		})

		this.spatialHierarchy = new Hierarchy(); // spatial hierarchy us also used for calculating world matrices
		this.electricalHierarchy = new Hierarchy();

        this.archetypes = new SceneInstancesArchetypes(this.logger, unitsMapper);

		this._propsShapeHooks = new SceneInstancesPropsShapeHooks(this.logger);

		this.deletedItemsStream = new ObservableStream({
            identifier: "bim-scene-objects-deletions-stream",
        });

		this._bimGeometries = bimGeometries;
		this._geometriesChangesSub = bimGeometries.updatesStream.subscribe({
			settings: {immediateMode: true},
			onNext: (delta) => {
				if (delta instanceof EntitiesUpdated && delta.ids.length > 0) {
					const patchedGeometriesIds: Set<IdBimGeo> = new Set(
						delta.ids
					);
					//TODO: make this faster
					const objectsWithDirtyGeos: IdBimScene[] = [];
					const geoIds: IdBimGeo[] = [];
					for (const [id, state] of this.perId) {
						geoIds.length = 0;
						SceneInstance.geometriesIdsReferences(state, geoIds);
						for (const geoId of geoIds) {
							if (patchedGeometriesIds.has(geoId)) {
								(objectsWithDirtyGeos.push(id));
							}
						}
					}
					IterUtils.sortDedupNumbers(objectsWithDirtyGeos);
					this.updatesStream.pushNext(
						EntitiesUpdated.fromSingleFlag(
							SceneObjDiff.GeometryReferenced,
							objectsWithDirtyGeos
						)
					);
				}
			}
		});

		this._lazyLists = new EntitiesLazyLists({
			entities: this,
			permanentTypeExtractor: (instance) => instance.type_identifier,
			ignoreFlags: SceneObjDiff.Highlighted | SceneObjDiff.Selected, // probably no one should need them
		});

		this.basicPropsView = new InstancesBasicPropsView(this, unitsMapper);
	}

	dispose(): void {
		super.dispose();
		this.deletedItemsStream.dispose();
		this._geometriesChangesSub.dispose();
		this._lazyLists.dispose();
	}

	checkForErrors(state: SceneInstance, errors: string[]): void {
		if (state.spatialParentId) {
			if (!this.perId.has(state.spatialParentId)) {
				errors.push(`invalid parent id ${state.spatialParentId}`);
			}
		}
		if (state.connectedTo?.length) {
			for (const id of state.connectedTo) {
				if (!this.perId.has(id)) {
                    errors.push(`invalid connectedTo ${id}`);
                }
			}
		}
		if(state.localTransform){
			if(!state.localTransform.position.isFinite()){
				errors.push(`invalid localTransform position ${state.type_identifier}: ${state.localTransform.position.toString()}`);
			}
			if(!state.localTransform.rotation.isFinite()){
				errors.push(`invalid localTransform rotation ${state.type_identifier}: ${state.localTransform.rotation.toString()}`);
			}
			if(!state.localTransform.scale.isFinite()){
				errors.push(`invalid localTransform scale ${state.type_identifier}: ${state.localTransform.scale.toString()}`);
			}
		}
        const propsClass = this.archetypes.getPropsClassFor(state.type_identifier);
        if (propsClass) {
            if (!(state.props instanceof propsClass)) {
                errors.push(`invalid props class for ${state.type_identifier}: ${state.props.constructor.name}`);
            }
        }
	}

	// Copy of EntitisBase _deleteByIds + SceneInstances extra sync
	protected _deleteByIds(ids: IdBimScene[])
		: { removed: [IdBimScene, Readonly<SceneInstance>][], derivativeUpdates: Map<IdBimScene, SceneObjDiff> }
	{
		if (this._isLocked) {
			throw new Error(`${this.identifier}| cant _deleteByIds, collection is locked`);
		}
		const removed: [IdBimScene, SceneInstance][] = [];
		ids = this.spatialHierarchy.gatherIdsWithSubtreesOf({
			ids, sortParentFirst: true
		});
		for (const id of ids) {
			const state = this.perId.get(id);
			if (state == undefined) {
				this.logger.batchedWarn('cant remove, id is empty', id);
				continue;
			}
			this.perId.delete(id);
			this.perIdTypeIdent.delete(id);
			// SceneInstances extra sync
			this.spatialHierarchy.delete(id);
			this.electricalHierarchy.delete(id);
			this.selectHighlight.updateSceneInstanceFlagsFor(id, 0);
			this._decrementExternalReferences(state);
			// End of SceneInstances extra sync
			removed.push([id, state]);
		}
		Object.freeze(removed);
		if (removed.length > 0) {
			this._version += 1;
			this.deletedItemsStream.pushNext(new Deleted(removed.map(i=>(i[0]))));

			if (this.undoStack) {
				this.undoStack.addUndoAction({
					actionName: 'delete',
					sourceIdentifier: this.identifier,
					args: removed,
					act: (allocArgs) => this.allocate(allocArgs, {}),
				});
			}
		}
		const derivativeUpdates = this._generateAdditionalDiffNotifications([]);
		if (this.perId.size === 0) {
			// if scene is cleared clear origin as well
			this.patchSceneOrigin({cartesianCoordsOrigin:new Vector3(), wgsProjectionOrigin: null});
		}
		this._propsShapeHooks.deleteCachesOf(ids);
		return { removed, derivativeUpdates };
	}

	getObservableSceneOrigin(): ObservableObject<BimSceneOrigin> {
		return this._sceneOrigin;
	}

	getSceneOrigin(): Readonly<BimSceneOrigin> | null {
		return this._sceneOrigin.poll();
	}
	patchSceneOrigin(originPatch: Partial<BimSceneOrigin>) {
		if (ObjectUtils.deepPatchEqualsToObject(originPatch, this._sceneOrigin.poll())) {
			this.logger.debug('patch of scene origin did nothing');
			return;
		}
		this._sceneOrigin.applyPatch({
			patch: originPatch,
		});
		// hack to insert merge barrier
		this.undoStack?.addUndoAction({
			actionName: 'scene origin patch',
			sourceIdentifier: this.identifier,
			isBarrierForMerges: true,
			args: [],
			act: () => {},
		});
	}

	fullFromPartial(partialState: Partial<SceneInstance>): SceneInstance | null {
		if (partialState instanceof SceneInstance) {
			return partialState;
		} else {

			let props = partialState.props;
			if (!props && partialState.type_identifier) {
				const propsClass = this.archetypes.getPropsClassFor(partialState.type_identifier);
				if (propsClass) {
					props = new propsClass({});
				}
			}
			const obj = new SceneInstance(
				partialState.type_identifier,
				partialState.name,
				partialState.localTransform,
				partialState.spatialParentId,
				partialState.electricalParentId,
				partialState.properties,
				partialState.representation,
				partialState.representationAnalytical,
				partialState.flags,
				partialState.colorTint,
				partialState.worldMatrix,
				partialState.hierarchySortKey,
				partialState.connectedTo,
				props?.freeze()
			);
			return obj;
		}
	}

	// Copy of EntitisBase _allocate + SceneInstances extra sync
	protected _allocate(argsPerObject: Iterable<[IdBimScene, Partial<SceneInstance>]>, thisEvent: EventStackFrame):
		{ idsAllocated: IdBimScene[], derivativeUpdates: Map<IdBimScene, SceneObjDiff> }
	{
		if (this._isLocked) {
			throw new Error(`${this.identifier}| cant _allocate, collection is locked`);
		}
		const dirtyPositions: IdBimScene[] = [];
		const toUndoDeleteIds: IdBimScene[] = [];
		// const allocatedNotification: TView[] = [];
		const allocated: IdBimScene[] = [];

        let validationErrors: string[] = [];

		argsPerObject = sortBatchByParentFirst(Array.from(argsPerObject), this.spatialHierarchy);

		const shapesHooks = this._propsShapeHooks;

		for (const [id, partialState] of argsPerObject) {
			if (this.perId.has(id)) {
				this.logger.batchedError(`allocate: entity id is already occupied`, id);
				continue;
			}
			if (!this.idsProvider.isValidId(id)) {
				this.logger.batchedError(`allocate: invalid id`, id);
				continue;
			}
			const state = this.fullFromPartial(partialState);
			if (state == null) {
				this.logger.batchedError('allocate: invalid state to allocate', partialState);
				continue;
			}
			if (!this._validateExternalReferences(state)) {
				this.logger.batchedError('allocate: state references are invalid', id);
				continue;
			}
            this.checkForErrors(state, validationErrors);
			if (validationErrors.length) {
				this.logger.batchedError('allocate: invalid state, cant allocate', [id, state, validationErrors]);
                validationErrors = [];
				continue;
			}
			this._incrementExternalReferences(state);
			shapesHooks.validatePropertiesShape(
				id,
				state.type_identifier,
				state.properties,
				[],
				thisEvent,
			);
			this.perId.set(id, state);
			this.perIdTypeIdent.set(id, state.type_identifier);
			allocated.push(id);
			toUndoDeleteIds.push(id);
			this.idsProvider.markOccupied(id);
			// SceneInstances extra sync
			this.selectHighlight.updateSceneInstanceFlagsFor(id, state.flags);
			this.spatialHierarchy.addNew(id, state.spatialParentId, state.hierarchySortKey);
			this.electricalHierarchy.addNew(id, state.electricalParentId, state.hierarchySortKey);
			dirtyPositions.push(id);
			// End of SceneInstances extra sync
		}
		const derivativeUpdates = this._generateAdditionalDiffNotifications(dirtyPositions);

		if (allocated.length) {
			this._version += 1;
		}

		if (this.undoStack && toUndoDeleteIds.length > 0) {
			this.undoStack.addUndoAction({
				actionName: `${this.identifier}_templates_delete",`,
				sourceIdentifier: this.identifier,
				args: toUndoDeleteIds,
				act: (ids) => { this.delete(ids, {}) },
			});
		}
		return { idsAllocated: allocated, derivativeUpdates };
	}

	protected _applyPerIdPatch(perId: Iterable<[IdBimScene, SceneInstancePatch]>, thisEvent: EventStackFrame): EntitiesUpdated<IdBimScene, SceneObjDiff> {
		if (this._isLocked) {
			throw new Error(`${this.identifier}| cant _applyPerIdPatch, collection is locked`);
		}

		const dirtyPositions: IdBimScene[] = [];
		const revert: [IdBimScene, SceneInstancePatch][] = [];
		const patchOut = { revert: null as (SceneInstancePatch | null) };

		let allDiffFlags: SceneObjDiff = 0;

		const ids: IdBimScene[] = [];
		const diffs: SceneObjDiff[] = [];

        let validationErrors: string[] = [];

		const shapesHooks = this._propsShapeHooks;

		const NonValidatedUpdateFlags = SceneObjDiff.LegacyProps
			| SceneObjDiff.Selected | SceneObjDiff.Highlighted | SceneObjDiff.Hidden
			| SceneObjDiff.ColorTint;

		for (const [id, patch] of perId) {
			const state = this.perId.get(id);
			if (!state) {
				this.logger.batchedWarn('entity patch, invalid id', id);
				continue;
			}
			patchOut.revert = null;

			let patchHasExternalRefsChanges = patch.representation !== undefined || patch.representationAnalytical !== undefined;

			if (patchHasExternalRefsChanges) {
				this._decrementExternalReferences(state);
			}

			const diffFlags = this._applyPatchToEntity(state, patch, patchOut);

			let anyErrors: boolean = false;

			if ((diffFlags & NonValidatedUpdateFlags) !== 0) {

				if (patch.spatialParentId && !this.spatialHierarchy.isValidParentFor(id, patch.spatialParentId)) {
					this.logger.batchedError('patch: invalid parent, cant patch', id);
					anyErrors = true;
				}

				if (patchHasExternalRefsChanges && !this._validateExternalReferences(state)) {
					this.logger.batchedError('patch: state references are invalid', id);
					anyErrors = true;
				}

				this.checkForErrors(state, validationErrors);

				if (validationErrors.length) {
					this.logger.batchedError('patch: invalid instanse patch, reverting', [id, validationErrors]);
					validationErrors = [];
					anyErrors = true;
				}
			}

			if (anyErrors) {
				this._applyPatchToEntity(state, patchOut.revert!, patchOut);
				if (patchHasExternalRefsChanges) {
					this._incrementExternalReferences(state);
				}
				continue;
			}

			if (patchHasExternalRefsChanges) {
				this._incrementExternalReferences(state);
			}

			// when properties change make sure props shape stays in sync
			if (diffFlags & SceneObjDiff.LegacyProps) {
				const shapeDefiningProps = shapesHooks.shapeDefiningPropsMergedPathsFor(state.type_identifier);
				if (shapeDefiningProps) {
					let shapeNeedsValidation = false;
					for (const t of patchOut.revert!.properties!) {
						const path = t[0];
						if (shapeDefiningProps.includes(path)) {
							// props shape needs recheck
							shapeNeedsValidation = true;
							break;
						}
					}
					if (shapeNeedsValidation) {
						shapesHooks.validatePropertiesShape(
							id,
							state.type_identifier,
							state.properties,
							patchOut.revert!.properties!,
							thisEvent,
						);
					}
				}

			}

			if (diffFlags & SceneObjDiff.SpatialParentRef) {
				this.spatialHierarchy.updateParentOf(id, state.spatialParentId);
				dirtyPositions.push(id);
			}
			if (diffFlags & SceneObjDiff.ElectricalParentRef) {
				this.electricalHierarchy.updateParentOf(id, state.electricalParentId);
			}
			if (diffFlags & SceneObjDiff.LocalTransform) {
				dirtyPositions.push(id);
			}
			if (diffFlags & SceneObjDiff.HierarchySortKey) {
				this.spatialHierarchy.updateSortKeyOf(id, state.hierarchySortKey);
			}
			if (diffFlags & (SceneObjDiff.Highlighted | SceneObjDiff.Selected)) {
				this.selectHighlight.updateSceneInstanceFlagsFor(id, state.flags)
			}

			// End of SceneInstance extra sync

			if (diffFlags && patchOut.revert) {
				if (diffFlags & (~(this.diffFlagsToNotUndo))) {
					revert.push([id, patchOut.revert]);
				}
			}

			ids.push(id);
			diffs.push(diffFlags);
			allDiffFlags |= diffFlags;
		}

		if (ids.length) {
			this._version += 1;
		}

		if (revert.length > 0 && !thisEvent.isEventDerived) {
			// we may have many revert objects, that are identical
			// reduce instances to decrease memory pressure
			const reducableFlags = SceneObjDiff.ColorTint | SceneObjDiff.Hidden | SceneObjDiff.Selected | SceneObjDiff.Highlighted;
            const shouldReduceUniquePatchObjects = ((allDiffFlags & reducableFlags) === allDiffFlags) && revert.length > 1;
			if (shouldReduceUniquePatchObjects)  {
				const lru: SceneInstancePatch[] = [];
				let foundReplacementCount = 0;
				for (const t of revert) {
					const patch = t[1];
					let foundPatch: SceneInstancePatch | undefined = undefined;
					for (let i = 0; i < lru.length; ++i) {
						if (ObjectUtils.areObjectsEqual(lru[i], patch)) {
							foundPatch = lru[i];
							break;
						}
					}
					if (foundPatch) {
						t[1] = foundPatch;
						foundReplacementCount += 1;
					} else {
						lru.push(patch);
						if (lru.length > 10) {
							lru.shift();
						}
					}
				}
				this.logger.debug(`patches objects instances reduce ${foundReplacementCount} of ${revert.length}`);
			}

            const shouldTryMergePatches = (allDiffFlags & SceneObjDiff.SpatialParentRef) === 0 && !shouldReduceUniquePatchObjects;

			if (this.undoStack) {
				this.undoStack.addUndoAction({
					actionName: 'update',
					sourceIdentifier: this.identifier,
					args: revert,
					act: (patch) => this.applyPatches(patch),
					argsMerger: shouldTryMergePatches ? new SceneInstancePatchesMerger(
                        null,
                        this.referencedBaseCollectionsIdentifiers, //todo: depending on allDiffFlags, dependencies can be eliminated
                    ) : undefined,
				});
			}
		}
		const additionalDiffNotifications = this._generateAdditionalDiffNotifications(dirtyPositions);
		for (const [id, diff] of additionalDiffNotifications) {
			ids.push(id);
			diffs.push(diff);
		}

		return new EntitiesUpdated(ids, diffs);
	}

	private _generateAdditionalDiffNotifications(dirtyPositions: IdBimScene[]): Map<IdBimScene, SceneObjDiff> {

		const updatesPerId = new Map<IdBimScene, SceneObjDiff>();

		if (dirtyPositions.length > 0) {
			// console.time('matrices udpate');
			const positionsToUpdate = this.spatialHierarchy.gatherIdsWithSubtreesOf({
				ids: dirtyPositions, sortParentFirst: true
			});
			for (const idToUpdatePosition of positionsToUpdate) {
				const s = this.perId.get(idToUpdatePosition)!;
				const parentS = this.perId.get(s.spatialParentId);

				s.localTransform.toMatrix4(s.worldMatrix);
				if (parentS) {
					s.worldMatrix.premultiply(parentS.worldMatrix);
				}

				updatesPerId.set(idToUpdatePosition, SceneObjDiff.WorldPosition);
			}
		}

		const parentsDiffSpatialDescendants = new Set<IdBimScene>();
		for (const [id, flag] of this.spatialHierarchy.consumeDirtyFlags()) {
			if (flag & HierarchyDirtyFlags.Children) {
				updatesPerId.set(
					id,
					(updatesPerId.get(id) ?? 0) | SceneObjDiff.SpatialChildrenList
				);

				this.spatialHierarchy.traverseLeaveToRoot(id, parentId => {
					if (parentsDiffSpatialDescendants.has(parentId)) {
						return false;
					}
					parentsDiffSpatialDescendants.add(parentId);
					updatesPerId.set(
						parentId,
						(updatesPerId.get(parentId) ?? 0) | SceneObjDiff.SpatialDescendants
					);
					return true;
				}, false);
			}
			if (flag & HierarchyDirtyFlags.ChildrenSort) {
				updatesPerId.set(
					id,
					(updatesPerId.get(id) ?? 0) | SceneObjDiff.ChildrenSortOrder
				);
				this.spatialHierarchy.sortChildrenOf(id);
			}
		}

		for (const [id, flag] of this.electricalHierarchy.consumeDirtyFlags()) {
			if (flag & HierarchyDirtyFlags.Children) {
				updatesPerId.set(
					id,
					(updatesPerId.get(id) ?? 0) | SceneObjDiff.ElectricalChildrenList
				);
			}
		}

		return updatesPerId;
	}


	_applyPatchToEntity(
		currState: SceneInstance,
		patch: SceneInstancePatch,
		out: { revert: SceneInstancePatch | null }
	): SceneObjDiff {
		let dirtyFlags = SceneObjDiff.None;
		out.revert = {};

		if (patch.name !== undefined && patch.name != currState.name) {
			out.revert.name = currState.name;
			currState.name = patch.name;
			dirtyFlags |= SceneObjDiff.Name;
		}
		if (patch.flags) {
			const currFlags = currState.flags;
			const newFlags = applyFlagsPatch(currFlags, patch.flags);
			if (currFlags !== newFlags) {
				currState.flags = newFlags;
				const currentFlagsChanged = (currFlags ^ newFlags) & FlagsMask;
				const flagsRevertMask = (currentFlagsChanged << 12)
				const flagsOld = currFlags & currentFlagsChanged;
				const revertFlags = flagsOld | flagsRevertMask;

				if (flagsRevertMask & (~(NoUndoFlags | NoUndoFlagsPatch))) {
					out.revert.flags = revertFlags & (~(NoUndoFlags | NoUndoFlagsPatch));
				}
				if (currentFlagsChanged & SceneInstanceFlags.isSelected) {
					dirtyFlags |= SceneObjDiff.Selected;
					// selection is special, also mark selected ids in allSceneEntities Set
				}
				if (currentFlagsChanged & SceneInstanceFlags.isHidden) {
					dirtyFlags |= SceneObjDiff.Hidden;
				}
				if (currentFlagsChanged & SceneInstanceFlags.isHighlighted) {
					dirtyFlags |= SceneObjDiff.Highlighted;
				}
			}
		}
		if (patch.spatialParentId !== undefined && patch.spatialParentId != currState.spatialParentId) {
			dirtyFlags |= SceneObjDiff.SpatialParentRef;
			out.revert.spatialParentId = currState.spatialParentId;
			currState.spatialParentId = patch.spatialParentId;
		}
		if (patch.electricalParentId !== undefined && patch.electricalParentId != currState.electricalParentId) {
			dirtyFlags |= SceneObjDiff.ElectricalParentRef;
			out.revert.electricalParentId = currState.electricalParentId;
			currState.electricalParentId = patch.electricalParentId;
		}
		if (patch.localTransform && !currState.localTransform.equals(patch.localTransform)) {
			dirtyFlags |= SceneObjDiff.LocalTransform;
			out.revert.localTransform = currState.localTransform.clone();
			currState.localTransform.copy(patch.localTransform as Transform);
		}
		if (patch.properties) {
			if (patch.properties instanceof PropertiesCollection) { // hack for persisted collection updates
				patch.properties = currState.properties.asPatch(patch.properties._props?.values() ?? []) ?? [];
			}
            const revert = currState.properties.applyPatch(patch.properties);
            if (revert) {
                out.revert.properties = revert;
                dirtyFlags |= SceneObjDiff.LegacyProps;
            }
		}
        if (patch.representation !== undefined && patch.representation !== currState.representation) {
            out.revert.representation = currState.representation;
            currState.representation = patch.representation;
            dirtyFlags |= SceneObjDiff.Representation;
        }
		if (patch.representationAnalytical !== undefined && patch.representationAnalytical !== currState.representationAnalytical) {
            out.revert.representationAnalytical = currState.representationAnalytical;
            currState.representationAnalytical = patch.representationAnalytical;
            dirtyFlags |= SceneObjDiff.RepresentationAnalytical;
        }
		if (patch.colorTint !== undefined && patch.colorTint != currState.colorTint) {
			if (typeof patch.colorTint != 'number') {
				this.logger.batchedError(`color patch should be of type number`, patch.colorTint);
			} else {
				out.revert.colorTint = currState.colorTint;
				currState.colorTint = patch.colorTint;
				dirtyFlags |= SceneObjDiff.ColorTint;
			}
        }
		if (patch.hierarchySortKey !== undefined) {
			out.revert.hierarchySortKey = currState.hierarchySortKey;
			currState.hierarchySortKey = patch.hierarchySortKey;
			dirtyFlags |= SceneObjDiff.HierarchySortKey;
		}
		if (patch.connectedTo !== undefined) {
			out.revert.connectedTo = currState.connectedTo;
			currState.connectedTo = patch.connectedTo;
			dirtyFlags |= SceneObjDiff.ConnectedTo;
		}
        if (patch.props) {
			if (patch.props instanceof PropsPatch) {
				const patched = applyPatchToProps(patch.props, currState.props);
				if (patched) {
					currState.props = patched[0];
					out.revert.props = patched[1];
				}
			} else if (patch.props instanceof PropsGroupBase) {
				out.revert.props = currState.props as PropsGroupBase;
				currState.props = patch.props.freeze();
			}
            dirtyFlags |= SceneObjDiff.NewProps;
        }
		return dirtyFlags;
	}

	getCollectionContextLazy(): LazyVersioned<CollectionAdditionalContext> {
		return LazyDerived.new1(
			this.identifier + '-context-lazy',
			null,
			[ this._sceneOrigin],
			([origin]) => {
				const res: CollectionAdditionalContext = {};
				if (origin?.cartesianCoordsOrigin) {
					res.civilCoordsOrigin = origin.cartesianCoordsOrigin.clone();
				}
				if (origin.wgsProjectionOrigin) {
					res.projectionOrigin = origin.wgsProjectionOrigin.clone();
				}
		
				return res;
			},
		)
	}

	patchFlagForIds(ids: IdBimScene[], flag: SceneInstanceFlags, enabled: boolean) {
		const flagsPatch = newFlagsPatch(flag, enabled);
		this.applyPatchTo({ flags: flagsPatch }, ids);
	}

	getVisible(): IdBimScene[] {
		const result = [];
		for (const [id, instance] of this.perId) {
			if (!instance.isHidden) {
				result.push(id);
			}
		}
		return result;
	}
	toggleVisibility(isVisible: boolean, ids?: IdBimScene[]) {
		const flagsPatch = newFlagsPatch(SceneInstanceFlags.isHidden, !isVisible);
		const patch: SceneInstancePatch = {
			flags: flagsPatch,
		};
        this.applyPatchTo(patch, ids ?? Array.from(this.perId.keys()));
	}

	getSelected(): IdBimScene[] {
        return this.selectHighlight.flagged(SceneInstanceFlags.isSelected);
    }
    getLastSelected(): IdBimScene | undefined {
        return this.selectHighlight.lastFlagged(SceneInstanceFlags.isSelected);
    }
    anySelected(): boolean {
        return this.selectHighlight.anyFlagged(SceneInstanceFlags.isSelected);
    }
    setSelected(ids?: IdBimScene[]) {
        const diff = this.selectHighlight.diffToMakeFlagEnabledOnlyFor(
			SceneInstanceFlags.isSelected,
			ids ?? [],
			{ flags: newFlagsPatch(SceneInstanceFlags.isSelected, true) },
			{ flags: newFlagsPatch(SceneInstanceFlags.isSelected, false) },
		);
        this.applyPatches(diff);
	}
	toggleSelected(isSelected: boolean, ids: IdBimScene[]) {
		const flagsPatch = newFlagsPatch(SceneInstanceFlags.isSelected, isSelected);
		this.applyPatchTo({
			flags: flagsPatch,
		}, ids);
    }

    getHighlighted(): IdBimScene[] {
        return this.selectHighlight.flagged(SceneInstanceFlags.isHighlighted);
    }
    setHighlighted(ids?: IdBimScene[] | null) {
		const diff = this.selectHighlight.diffToMakeFlagEnabledOnlyFor(
			SceneInstanceFlags.isHighlighted,
			ids ?? [],
			{ flags: newFlagsPatch(SceneInstanceFlags.isHighlighted, true) },
			{ flags: newFlagsPatch(SceneInstanceFlags.isHighlighted, false) },
		);
        this.applyPatches(diff);
	}
	setColorTint(ids: IdBimScene[], newColor: RGBAHex): void {
		this.applyPatchTo({
			colorTint: newColor,
		}, ids);
	}

	getIdsExcept(toExclude?: IdBimScene[] | Set<IdBimScene>): IdBimScene[] {
        if (!toExclude) {
			return Array.from(this.perId.keys());
		}
		if (Array.isArray(toExclude)) {
			toExclude = new Set(toExclude);
		}
		const res: IdBimScene[] = [];
		for (const id of this.perId.keys()) {
			if (!toExclude.has(id)) {
				res.push(id);
			}
		}
		return res;
	}

	clone(ids: IdBimScene[]): IdBimScene[] {
		const instancesToAlloc: [IdBimScene, Partial<SceneInstance>][] = [];
		this.spatialHierarchy.sortByDepth(ids);
		const newIds = new Map<IdBimScene, IdBimScene>();
		const geometriesToAlloc: [IdBimGeo, Partial<AnyBimGeometry>][] = [];
		for (const id of ids) {
			const s = this.perId.get(id);
			if (s == undefined) {
				continue;
			}
			// state.flags = state.flags as number) ElementFlags.isSelected;
			const newId = this.idsProvider.reserveNewId();
			newIds.set(id, newId);

			let reprA: BasicAnalyticalRepresentation | null = s.representationAnalytical;

			if (s.representationAnalytical instanceof BasicAnalyticalRepresentation) {
				const geo = this._bimGeometries.peekById(s.representationAnalytical.geometryId);
				if (!geo) {
					this.logger.batchedError('analytical geometry referenced is abscent, cant clone', id);
					continue;
				}
				const geometryId = this._bimGeometries.reserveNewIdForType(
					entityTypeFromId(s.representationAnalytical.geometryId)
				);
				geometriesToAlloc.push([geometryId, ObjectUtils.deepCloneObj(geo)]);
				reprA = new BasicAnalyticalRepresentation(geometryId);
			} else {
				reprA = ObjectUtils.deepCloneObj(s.representationAnalytical);
			}
			const state: Partial<SceneInstance> = {
				colorTint: s.colorTint,
				flags: s.flags,

				type_identifier: s.type_identifier,
				name: s.name,
				localTransform: s.localTransform.clone(),
				properties: s.properties.clone(),
				representation: s.representation,

				representationAnalytical: reprA,

				electricalParentId: s.electricalParentId,
				spatialParentId: s.spatialParentId,

                props: s.props.cloneWithoutFlags(PropsFieldFlags.SkipClone),
			};
			if (state.spatialParentId) {
				const newParent = newIds.get(state.spatialParentId);
				if (newParent) {
					state.spatialParentId = newParent;
				}
			}
			if (state.electricalParentId) {
				const newParent = newIds.get(state.electricalParentId);
				if (newParent) {
					state.electricalParentId = newParent;
				}
			}
			instancesToAlloc.push([newId, state]);
		}
		if (geometriesToAlloc.length) {
			this._bimGeometries.allocate(geometriesToAlloc);
		}
		return this.allocate(instancesToAlloc);
    }

	peekWorldMatrix(id: IdBimScene | 0): Readonly<Matrix4> | undefined {
		return this.perId.get(id)?.worldMatrix;
        // if (this._dirtyPositionsIds.size) {
        //     this.updateDirtyMatrices();
        // }
        // return this._allTransforms.get(id)?.worldMatrix;
    }
    getWorldMatricesOf(ids: IdBimScene[]): Map<IdBimScene, Matrix4> {
        // this.updateDirtyMatrices();
        const res = new Map<IdBimScene, Matrix4>();
        for (const id of ids) {
            const m = this.perId.get(id)?.worldMatrix;
            if (m) {
                res.set(id, m.clone());
            }
        };
        return res;
    }
	patchWorldMatricesOfParentsOnly(transformsPerId: Map<IdBimScene, Matrix4>, e?: Partial<EventStackFrame>) {
		const patchWithDirectChildrenCompensation = new Map<IdBimScene, Matrix4>(transformsPerId);
		for (const id of transformsPerId.keys()) {
			for (const childId of this.spatialHierarchy.iteratorOfChildrenOf(id)) {
				if (!patchWithDirectChildrenCompensation.has(childId)) {
					const currentWm = this.perId.get(childId)?.worldMatrix;
					if (currentWm) {
						patchWithDirectChildrenCompensation.set(childId, currentWm.clone());
					}
				}
			}
		}
		this.patchWorldMatrices(patchWithDirectChildrenCompensation, e);
	}
	
    patchWorldMatrices(transformsPerId: Map<IdBimScene, Matrix4>, e?: Partial<EventStackFrame>) {
        // convert world positions patches to local transforms patches
		
		const depthPerId = IterUtils.mapIter(
			transformsPerId.keys(),
			(id) => [id, this.spatialHierarchy.depthOf(id) ?? 0],
		);

		depthPerId.sort((l, r) => l[1] - r[1]); // sort to start from the root

		let currentDepth = NaN;
		let currentPatch: [IdBimScene, SceneInstancePatch][] = [];
		for (const [id, depth] of depthPerId) {

			const state = this.perId.get(id);

			if (!state) {
				this.logger.batchedError('attempt to patch non existing instance', id);
				continue;
			}
			
			const shouldApplyPreviousPatch = currentDepth !== depth
				|| (state.spatialParentId && !transformsPerId.has(state.spatialParentId));

			if (shouldApplyPreviousPatch) {
				this.applyPatches(currentPatch, e);
				currentPatch = [];
				currentDepth = depth;
			}

			const desiredWm = transformsPerId.get(id)!;

			let parentMatrix: Matrix4 | undefined;
			if (state.spatialParentId) {
				parentMatrix = transformsPerId.get(state.spatialParentId)
					?? this.perId.get(state.spatialParentId)?.worldMatrix;
			} else {
				parentMatrix = undefined;
			}

			const lt = SceneInstances.getLocalTransformRelativeToParentMatrix(
				parentMatrix,
				desiredWm,
			);
			currentPatch.push([id, { localTransform: lt }]);
		}
		this.applyPatches(currentPatch, e);

    }
    static getLocalTransformRelativeToParentMatrix(parentMatrix: Matrix4 | undefined, worldPosition: Matrix4): Transform {
        const localTransform = new Transform();
        if (parentMatrix) {
            reusedM4.getInverse(parentMatrix);
            reusedM4.multiply(worldPosition);
            localTransform.setFromMatrix4(reusedM4);
        } else {
            localTransform.setFromMatrix4(worldPosition);
        }
        return localTransform;
    }

	extractHierarchySortKeysRangeToInsertAfter(idAfter: IdBimScene | 0): {min: number, max: number} {
		const inFrontOf: IdBimScene | 0 = this.spatialHierarchy.getNextIdInHierarchyBucketWith(idAfter);
		return this._extractHierarchySortKeysRangeToInsertInBetween(idAfter, inFrontOf);
	}
	extractHierarchySortKeysRangeToInsertInFrontOf(inFrontOf: IdBimScene | 0): {min: number, max: number} {
		const idAfter: IdBimScene | 0 = this.spatialHierarchy.getPreviousIdInHierarchyBucketWith(inFrontOf);
		return this._extractHierarchySortKeysRangeToInsertInBetween(idAfter, inFrontOf);
	}
	private _extractHierarchySortKeysRangeToInsertInBetween(after: IdBimScene | 0, inFrontOf: IdBimScene | 0): {min: number, max: number} {

		const stateAfter = this.perId.get(after);
		const stateinFrontOf = this.perId.get(inFrontOf);

		if (!stateinFrontOf) {
			if (!stateAfter) {
				return {min: 0, max: 1_000_000};
			} else {
				return {min: stateAfter.hierarchySortKey + 10, max: stateAfter.hierarchySortKey + 10_000};

			}
		}

		let nextKey = stateinFrontOf.hierarchySortKey;
		let prevKey = stateAfter?.hierarchySortKey ?? 0;

		if (nextKey - prevKey > 1) {
			return {max: nextKey - 0.1, min: prevKey + 0.1};
		}

		// patch sort keys in hierarchy bucket, to make room for new sort keys to insert
		const bucketIds = this.spatialHierarchy.getIdsInTheSameBucketAs(inFrontOf);
		const patches: [IdBimScene, SceneInstancePatch][] = bucketIds.map(
			(id, index) => [id, {hierarchySortKey: (index + 1) * 10}]
		);
		this.applyPatches(patches);

		// now sort keys should have room for insertion
		nextKey = stateinFrontOf.hierarchySortKey;
		prevKey = stateAfter?.hierarchySortKey ?? 0;
		LegacyLogger.debugAssert(nextKey - prevKey > 1, 'hierarchy sort keys modification sanity check');
		return {max: nextKey - 0.1, min: prevKey + 0.1};
	}
	
	colorizeHierarchiesOf(
		ids: IdBimScene[], 
		params: {
			resetNotSelected?: boolean,
			excludeTypes?: Set<string>,
			byBlocks?: boolean,
		} = {excludeTypes: new Set(['road', 'trench', 'boundary']), byBlocks: true,}
	) {
		const idPerColor = this.getColorsOfHierarchies(ids, params);
		const patches:[IdBimScene, SceneInstancePatch][] = [];
		for (const [id, color] of idPerColor) {
			patches.push([id, {colorTint: color}]);
		}
		this.applyPatches(patches);
	}

	getColorsOfHierarchies(
		ids: IdBimScene[], 
		params: {
			resetNotSelected?: boolean,
			excludeTypes?: Set<string>,
			byBlocks?: boolean,
		} = {excludeTypes: new Set(['road', 'trench', 'boundary']), byBlocks: true,}
	): Map<IdBimScene, RGBAHex | 0> { 
		const nonOverlappingIds = this.spatialHierarchy.getNonOverlappingIdsFrom(ids);
		const initialPalette = new RgbaPalette(DefaultRgbaPalette.slice());
		const derivedPalettes = new DefaultMap<RGBAHex, RgbaPalette>((rgba) => {
			return new RgbaPalette(RGBA.shadesOf(rgba, 5));
		});

		const PaletteDepthLimit = 2;

		const colorInfo: {
			color?: RGBAHex, 
			palette: RgbaPalette, 
			paletteDepth: number
		} = {palette: initialPalette, paletteDepth: 0};
		const patches = new Map<IdBimScene, RGBAHex | 0>();
		for (const rootId of nonOverlappingIds) {

			this.spatialHierarchy.traverseChildrenDepthFirst(
				rootId,
				(id, c, _depth, childrenCount, siblingsCount) => {

					let palette = c.palette;
					let paletteDepth: number = c.paletteDepth;
					let color = c.color;

					if (params.byBlocks && !nonOverlappingIds.has(id) && this.peekTypeIdentOf(id) === "transformer") {
						paletteDepth = 0;
						palette = initialPalette;
						color = palette.getNext();
					} else if (!nonOverlappingIds.has(id) && siblingsCount > 1 && c.paletteDepth < PaletteDepthLimit) {
						color = c.palette.getNext();
					}
					if(color != undefined){
						patches.set(id, color);
					}

					if (color != undefined && childrenCount > 1 && c.paletteDepth < PaletteDepthLimit) {
						paletteDepth += 1;
						palette = derivedPalettes.getOrCreate(color);
					}
					return {color, palette, paletteDepth};
				},
				colorInfo,
			);
		}

		if(params.byBlocks){
			const wiresPalette = new RgbaPalette(DefaultRgbaPalette.slice());
			for (const rootId of nonOverlappingIds) {
				this.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(rootId, (id) => {
					const typeIdent = this.peekTypeIdentOf(id);
					if(typeIdent === "wire"){
						const circuitIdx = this.peekById(id)?.properties.get('computed_result | circuit_index')?.asNumber() ?? 0;
						const color = wiresPalette.get(circuitIdx);
						patches.set(id, color);
					}
					return true;
				});
			}
		}

		if(params.excludeTypes){
			for (const [id, s] of this.perId) {
				if (params.excludeTypes.has(s.type_identifier)) {
					patches.delete(id);
				}
			}
		}

		if(params.resetNotSelected){
			for (const [id, s] of this.perId) {
				if (s.colorTint && !patches.has(id)) {
					patches.set(id, 0);
				}
			}
		}
		
		return patches;
	}

	static uiNameFor(id: IdBimScene, s: Readonly<SceneInstance>): string {
		if (s.name) {
			return `${s.name} ${id}`;
		}

		if (s.type_identifier === LvWireTypeIdent) {
			const type = s.properties.get('specification | type')?.asText();
			if(type){
				return `${type} ${id}`;
			}
		}

		if (s.type_identifier) {
			let label = StringUtils.capitalizeFirstLatterInWord(s.type_identifier);
			return `${label} ${id}`;
		}

		return id.toString();
	}

	private readonly _anyObjectsPrimaryPropsProviders: ((s: Readonly<SceneInstance>) => string | undefined)[] = [];
	private readonly _primaryPropertiesProviders = new Map<string | '', (s: Readonly<SceneInstance>) => string | undefined>();

	registerPrimaryPropertyLabelProvider(
		type_ident: string | '',
		provider: (s: Readonly<SceneInstance>) => string | undefined
	) {
		if (type_ident) {
			this._primaryPropertiesProviders.set(type_ident, provider);
		} else {
			this._anyObjectsPrimaryPropsProviders.push(provider);
		}
	}
	primaryPropertyLabel(s: Readonly<SceneInstance>): string | undefined {
		let prov = this._primaryPropertiesProviders.get(s.type_identifier);
		let label = prov ? prov(s) : undefined;
		if (label != undefined) {
			return label;
		}
		for (const anyTypeProvider of this._anyObjectsPrimaryPropsProviders) {
			label = anyTypeProvider(s);
			if (label != undefined) {
				return label;
			}
		}
		return undefined;
	}

	generateShorDescriptionsForUi(): string[] {
		const primaryPropertiesPerTy = new DefaultMap<string, [IdBimScene, SceneInstance, string][]>(() => []);

		for (const [id, instance] of this.perId) {
			if (!instance.type_identifier) {
				continue;
			}
			const primaryProp = this.primaryPropertyLabel(instance);
			if (primaryProp) {
				const arr = primaryPropertiesPerTy.getOrCreate(instance.type_identifier);
				arr.push([id, instance, primaryProp]);
			}
		}

		const alllPerTy = Array.from(primaryPropertiesPerTy.values());
		alllPerTy.sort((arr1, arr2) => arr1.length - arr2.length);

		return alllPerTy.map(arr => {
			arr.sort((t1, t2) => t1[0] - t2[0]);
			let typeStr = arr[0][1].type_identifier;
			if (typeStr.startsWith('solar-')) {
				typeStr = typeStr.slice(6);
			}

			let resultString = typeStr;
			resultString += ` [${arr.length}] `;

			if (arr.length <= 3) {
				const descriptions = arr.map(([id, inst, primaryProp]) => {
					const name = inst.name ? `${inst.name}_${id}` : `${id}`;
					const shortDescr = `\n ${name} (${primaryProp})`
					return shortDescr;
				})
				resultString += `[${descriptions.join(';')}\n]`;
			} else {
			}
			return resultString;
		});
	}

	registerPropsShapeHook(...args: Parameters<SceneInstancesPropsShapeHooks['addShapeHookFor']>) {
		this._propsShapeHooks.addShapeHookFor(...args);
	}

	getLazyListOf(args: {type_identifier: string, relevantUpdateFlags?: SceneObjDiff}): LazyVersioned<[IdBimScene, SceneInstance][]> {
		const flagsMask = args.relevantUpdateFlags ?? (SceneObjDiff.All & this._lazyLists.globalFlagsFilter);
		return this._lazyLists.getLazyListOf({type_identifier: args.type_identifier, relevantUpdateFlags: flagsMask});
	}

	getLazyListOfTypes(args: {type_identifiers: string[], relevantUpdateFlags?: SceneObjDiff}): LazyVersioned<[IdBimScene, SceneInstance][]> {
		const flagsMask = args.relevantUpdateFlags ?? (SceneObjDiff.All & this._lazyLists.globalFlagsFilter);
		const perTypeLazyLists: LazyVersioned<[IdBimScene, SceneInstance][]>[] = [];
		for (const type_identifier of args.type_identifiers) {
			const lazyList = this._lazyLists.getLazyListOf({type_identifier: type_identifier, relevantUpdateFlags: flagsMask});
			perTypeLazyLists.push(lazyList);
		}
		const mergedList = LazyDerived.fromArr(
			`${args.type_identifiers.join("-")} lazy-lists`, 
			null, 
			perTypeLazyLists, 
			(perType) => {
			const listItems: [IdBimScene, SceneInstance][] = [];
			for (const arr of perType) {
				IterUtils.extendArray(listItems, arr);
			}
			return listItems;
		}).withoutEqCheck();
		return mergedList;
	}

	getLazyListOfCollection(args?: {relevantUpdateFlags?: SceneObjDiff}): LazyVersioned<[IdBimScene, SceneInstance][]> {
		const flagsMask = args?.relevantUpdateFlags ?? (SceneObjDiff.All & this._lazyLists.globalFlagsFilter);
		return this._lazyLists.getLazyListOfAll({relevantUpdateFlags: flagsMask});
	}

	getLazyKnownTypes(): LazyVersioned<string[]> {
		return this._lazyLists.getLazyKnownTypes();
	}

	peekByTypeIdent(typeIdent: string): [IdBimScene, Readonly<SceneInstance>][] {
		return IterUtils.filter(this.perId, (t) => t[1].type_identifier === typeIdent);
	}

	peekByTypeIdents(typeIdents: string[]): [IdBimScene, Readonly<SceneInstance>][] {
		return IterUtils.filter(this.perId, (t) => typeIdents.includes(t[1].type_identifier));
	}

	peekTypeIdentOf(id: IdBimScene): string | undefined {
		return this.perIdTypeIdent.get(id);
	}

	getClosestParentOfTypeFor(id: IdBimScene, typeIdent: string, skipFirst: boolean = false): IdBimScene | null {
		let parentId: IdBimScene | null = null;

		this.spatialHierarchy.traverseLeaveToRoot(id, pId => {
			const type = this.peekTypeIdentOf(pId);
			if (type === typeIdent) {
				parentId = pId;
				return false;
			} else {
				return true;
			}
		}, skipFirst);

		return parentId;
	}
}


export class LocalTransformsCalculator {
    readonly sceneInstances: SceneInstances;
    readonly desiredPositions: Map<IdBimScene, Matrix4>;

    constructor(bimInstances: SceneInstances) {
        this.sceneInstances = bimInstances;
        this.desiredPositions = new Map();
    }

    calcPositionPatch(id: IdBimScene, worldPosition: Matrix4, parentId: IdBimScene | 0): Transform {
        const parentMatrix = this.desiredPositions.get(parentId)
            ?? this.sceneInstances.peekById(parentId)?.worldMatrix;


        const localTransform = new Transform();
        if (parentMatrix) {
            reusedM4.getInverse(parentMatrix);
            reusedM4.multiply(worldPosition);
            localTransform.setFromMatrix4(reusedM4);
        } else {
            localTransform.setFromMatrix4(worldPosition);
        }

        this.desiredPositions.set(id, worldPosition);

        return localTransform;
    }
}

class SceneInstancePatchesMerger extends BasicPatchesMerger<IdBimScene, SceneInstancePatch> {

    mergePatches(earlyRevert: SceneInstancePatch, laterRevert: SceneInstancePatch): SceneInstancePatch {
        const r = {...laterRevert, ...earlyRevert};
        if (laterRevert.flags && earlyRevert.flags) {
            // merge flags
	        const flagsToPatchEarly = ((earlyRevert.flags & FlagsPatchMask) >> 12) & FlagsMask;
	        const flagsToPatchLater = ((laterRevert.flags & FlagsPatchMask) >> 12) & FlagsMask;

            const flagsPatchDiff = flagsToPatchEarly ^ flagsToPatchLater;

            r.flags = (laterRevert.flags & (~flagsPatchDiff)) | earlyRevert.flags;
        }
        return r;
    }
}

const reusedM4 = new Matrix4();

export interface ObjectsAllocated<TStateView> {
	allocPerId: [IdBimScene, TStateView][],
	event: EventStackFrame,
}

export interface ObjectsPatched<TStateView> {
	patchPerId: [IdBimScene, Partial<TStateView>][],
	event: EventStackFrame,
}

export interface ObjectsRemoved {
	ids: IdBimScene[],
	event: EventStackFrame,
}

export function calculateStdRepresentationLocalBBox(repr: Readonly<ObjectRepresentation> | null, geometries: BimGeometries): Result<Aabb> {
	if (!repr) {
		return new Failure({msg: 'representation is absent'});
	}
	if (!(repr instanceof StdMeshRepresentation) && !(repr instanceof StdGroupedMeshRepresentation)) {
		return new Failure({msg: 'expected std representation'});
	}
    const aabbs = geometries.aabbs.poll();
    const aabb = repr.aabb(aabbs);
    if (aabb.isEmpty()) {
        return new Failure({msg: 'empty aabb'});
    }
    return new Success(aabb);
}
