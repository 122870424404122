import { NumberProperty, type UnitsMapper } from "bim-ts";
import { NumberAndConfig } from "./TableUi";
import { Failure } from 'engine-utils-ts';

export class NumberEditor {
    valid = false;
    initValue!: NumberAndConfig;

    input = document.createElement('input');
    unitsMapper!: UnitsMapper;

    private constructor() {}

    init(params: {
        value?: any,
        unitsMapper: UnitsMapper,
    }) {
        if (!(params.value instanceof NumberAndConfig)) {
            throw new Error('value of incorrect type')
        }
        this.initValue = params.value;
        this.unitsMapper = params.unitsMapper;

        this.input.style.background = 'none';
        this.input.style.height = 'calc(100% - 5px)';
        this.input.style.width = 'calc(100% - 5px)';

        this.input.value = this.initValue.property
            .valueUnitUiStringPrecise(params.unitsMapper);
    }

    /* Component Editor Lifecycle methods */
    // gets called once when grid ready to insert the element
    getGui() {
        return this.input;
    }

    valueParser() {
        const [val, ...units] = (this.input.value).split(' ');
        const num = parseFloat(val);
        const unitsStr = units.join(' ');
        if (
            !Number.isFinite(num) ||
            this.unitsMapper?.converter.isValidUnits(unitsStr) instanceof Failure
        ) {
            return this.initValue;
        }
        const newProp = NumberProperty.new({ unit: unitsStr, value: num });
        return new NumberAndConfig(
            newProp,
            this.initValue.config,
        );
    }

    // the final value to send to the grid, on completion of editing
    getValue() {
        // this simple editor doubles any value entered into the input
        return this.valueParser();
    }

    // Gets called once before editing starts, to give editor a chance to
    // cancel the editing before it even starts.
    isCancelBeforeStart() {
        return false;
    }

    // Gets called once when editing is finished (eg if Enter is pressed).
    // If you return true, then the result of the edit will be ignored.
    isCancelAfterEnd() {
        const value = this.valueParser();
        const newProp = value.property;
        const prevProp = this.initValue.property;
        if (newProp && prevProp) {
          const isChanged =
            newProp.unit !== prevProp.unit ||
            newProp.value.toFixed(4) !== prevProp.value.toFixed(4);
          if (isChanged) {
            return false;
          }
        }
        return true;
    }

    // after this component has been created and inserted into the grid
    afterGuiAttached() {
        this.input.focus();
    }
}
