export enum TrackerWindPosition {
    Interior = "interior",
    Exterior = "exterior",
    Edge = "edge",
    EdgeTop = "edge_top",
    EdgeBot = "edge_bot",
}


export enum TrackerFacing {
    North = "north",
    South = "south",
    East = "east",
    West = "west",
}