import type { BasicCollectionUpdates} from "./BasicCollectionUpdates";
import { Allocated, Deleted, Updated } from "./BasicCollectionUpdates";
import type { BasicDataSource } from "./BasicDataSource";
import { ObservableStream } from "./ObservableStream";

export class CollectionBasic<T, IdT> implements BasicDataSource<T, IdT> {

    readonly perId = new Map<IdT, T>();

    readonly updatesStream: ObservableStream<BasicCollectionUpdates<IdT>>;

    constructor(
        identifier: string,
    ) {
        this.updatesStream = new ObservableStream({
            identifier: `${identifier}-updates`,
            defaultValueForNewSubscribersFactory: () => {
                if (this.perId.size === 0) {
					return undefined;
				}
				return new Allocated<IdT>(Array.from(this.perId.keys()));
            }
        })
    }

    poll(): ReadonlyMap<IdT, T> {
        return this.perId;
    }
    allIds(): IterableIterator<IdT> {
        return this.perId.keys();
    }
    peekByIds(ids: Iterable<IdT>): Map<IdT, T | undefined> {
		const res = new Map<IdT, T>();
		for (const id of ids) {
			const obj = this.perId.get(id);
			if (obj !== undefined) {
				res.set(id, obj);
			}
		}
		return res;
    }
    dispose(): void {
    }
    version(): number {
        return this.updatesStream.version();
    }


    allocateOrUpdate(perId: Iterable<[IdT, T]>) {
        const updated: IdT[] = [];
        const allocated: IdT[] = [];
        for (const [id, obj] of perId) {
            if (this.perId.has(id)) {
                updated.push(id);
            } else {
                allocated.push(id);
            }
            this.perId.set(id, obj);
        }
        if (allocated.length) {
            this.updatesStream.pushNext(new Allocated(allocated));
        }
        if (updated.length) {
            this.updatesStream.pushNext(new Updated(updated));
        }
    }

    delete(ids: Iterable<IdT>) {
        const deleted: IdT[] = [];
        for (const id of ids) {
            if (this.perId.delete(id)) {
                deleted.push(id);
            }
        }
        if (deleted.length) {
            this.updatesStream.pushNext(new Deleted(deleted));
        }
    }
    
}
