import type { IconButton } from "../button";
import type { Checkbox } from "../checkbox";
import type { ChipsInput } from "../chips";
import type { ContextMenuStructure } from "../context-menu";
import type { Icon } from "../icon"

export type Variants = string | ContextMenuStructure | Checkbox | Icon | IconButton;

export class ListItem {
    constructor(
        public text: string,
        public secondary?: string,
        public before?: Variants,
        public after?: Variants,
        public onClick?: () => void,
        public selected: boolean = false,
        public chips?: ChipsInput,
        public onDoubleClick?: () => void,
        public hasHtmlContent: boolean = false,
        public disabled: boolean = false,
    ) {}
}

export class ListSeparator {}

export class ListOptions {
    public itemsSeparated: boolean;
    public virtualListHeight: number;
    public listItemSize: number;
    constructor({
        itemsSeparated = false,
        virtualListHeight = 0,
        listItemSize = 32,
    }) {
        this.itemsSeparated = itemsSeparated
        this.virtualListHeight = virtualListHeight
        this.listItemSize = listItemSize
    }
}

export class List {
    constructor(
        public items: Array<ListItem | ListSeparator>,
        public options: ListOptions = new ListOptions({})
    ) {}
}

export class TitledList {
    constructor(
        public title: string,
        public list: List,
    ) {}
}

export class ListGroup {
    constructor(
        public lists: Array<TitledList | List | ListSeparator>
    ) {}
}

export class ListInput {
    constructor(
        public display: ListGroup | List,
    ) {}
}

