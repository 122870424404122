import type { Bim} from "../..";
import { CombinerBoxKeyProps, CombinerBoxTypeIdent } from "../../archetypes/CombinerBox";
import { InverterKeyProps, InverterTypeIdent } from "../../archetypes/Inverter/Inverter";
import { registerMvWirePriceSovler } from "../../archetypes/MvWire";
import { SectionalizingCabinetIdent, SectionalizingCabinetKeyProps } from "../../archetypes/SectionalizingCabinet";
import { SubstationKeyProps, SubstationTypeIdent } from "../../archetypes/substation/Substation";
import { TransformerIdent, TransformerKeyProps } from "../../archetypes/transformer/Transformer";
import { registerTrenchPricingSolver } from "../../archetypes/Trench";
import { registerTerrainPriceSolver } from "../../terrain/Terrain";
import { registerEquipmentPriceSolver } from '../../archetypes/EquipmentCommon';
import { registerFixedTrackerPricingSolver } from '../../archetypes/fixed-tilt/FixedTiltPricingSolver';
import { registerSolarTrackerPricingSolver } from '../../trackers/pricing/TrackerPricingSolver';
import { registerLvWirePriceSolver } from '../../archetypes/LvWire';
import { blockCostAggregator, groupCostAggregator } from '../../runtime/solar/SolarPropsSolvers';
import { type CostsConfigProvider } from "src/cost-model/capital";
import { registerRoadPricingSolver } from "src/archetypes/Road";
import { getInverterDefaultCostsPerEach } from "src/cost-model/capital/tables/categories/power-conversion-system/inverters";
import { getSubstationDefaultCostsPerEach } from "src/cost-model/capital/tables/categories/power-conversion-system/substations";
import { getTransformerDefaultCostsPerEach } from "src/cost-model/capital/tables/categories/power-conversion-system/transformers";
import { getCombinerBoxDefaultCostsPerEach } from "src/cost-model/capital/tables/categories/electrical-subtotal/combiners";
import { getSectionalizingCabinetDefaultCostsPerEach } from "src/cost-model/capital/tables/categories/electrical-subtotal/sc";
import { registerAnyTrackerPricingSolver } from "src/anyTracker/AnyTrackerPricingSolver";

export function registerPricingRuntime(bim: Bim, costs: CostsConfigProvider) {

    registerSolarTrackerPricingSolver(bim, costs);

    registerFixedTrackerPricingSolver(bim);

    registerRoadPricingSolver(bim, costs);

    registerTrenchPricingSolver(bim, costs);

    registerLvWirePriceSolver(bim, costs);

    registerMvWirePriceSovler(bim, costs);

    registerTerrainPriceSolver(bim, costs);

    registerEquipmentPriceSolver(CombinerBoxTypeIdent, CombinerBoxKeyProps, bim, costs, getCombinerBoxDefaultCostsPerEach)
    registerEquipmentPriceSolver(InverterTypeIdent, InverterKeyProps, bim, costs, getInverterDefaultCostsPerEach)
    registerEquipmentPriceSolver(SectionalizingCabinetIdent, SectionalizingCabinetKeyProps, bim, costs, getSectionalizingCabinetDefaultCostsPerEach)
    registerEquipmentPriceSolver(SubstationTypeIdent, SubstationKeyProps, bim, costs, getSubstationDefaultCostsPerEach)
    registerEquipmentPriceSolver(TransformerIdent, TransformerKeyProps, bim, costs, getTransformerDefaultCostsPerEach)

    bim.reactiveRuntimes.registerRuntimeSolver(groupCostAggregator());
    bim.reactiveRuntimes.registerRuntimeSolver(blockCostAggregator())

    registerAnyTrackerPricingSolver(bim, costs);

}

