import type { IdBimScene } from "src";
import { NumberProperty } from "src";
import {
    CostSourceType,
    type CostCategory,
    type FlattenedCostCategoryParams,
    type IdCostCategory,
} from ".";
import { sumValueUnitLike } from "src/UnitsMapper";
import { Success } from "engine-utils-ts";

export class CostHierarchy {
    private roots: IdCostCategory[] = [];
    readonly categories: Map<IdCostCategory, CostCategory> = new Map();
    readonly ignored: Set<IdCostCategory> = new Set();
    readonly disableEdit: Set<IdCostCategory> = new Set();
    readonly categoryWithSceneInstances: Set<IdCostCategory> = new Set();

    merge(...sources: CostHierarchy[]) {
        for (const source of sources) {
            for (const entry of source.categories) {
                this.categories.set(...entry);
            }
            for (const id of source.ignored) {
                this.ignored.add(id);
            }
            for (const id of source.roots) {
                this.roots.push(id);
            }
            for (const item of source.categoryWithSceneInstances) {
                this.categoryWithSceneInstances.add(item);
            }
        }
        return this;
    }

    static clone(source: CostHierarchy) {
        const result = new CostHierarchy();
        result.merge(source);
        return result;
    }

    getRootCategories(): [id: IdCostCategory, root: CostCategory][] {
        return this.roots.map((x) => [x, this.categories.get(x)!]);
    }

    getRelatedSceneInstance(categoryId: IdCostCategory) {
        const relatedInstances = new Set<IdBimScene>();
        this.recursiveChildren(
            categoryId,
            (id, category) => {
                if (!this.categoryWithSceneInstances.has(id)) {
                    return true;
                }
                category.relatedSceneInstanceIds?.forEach((x) =>
                    relatedInstances.add(x),
                );
                return false;
            },
            { includeParent: true, shouldStopOnlyCurrentBranch: true },
        );
        return relatedInstances;
    }

    addRoot(category: CostCategory): [id: IdCostCategory, root: CostCategory] {
        const id = reserveCostCategoryId();
        this.categories.set(id, category);
        if (category.children?.length) {
            throw new Error("category for addRoot can not have children");
        }
        if (this.roots.length) {
            if (
                category.relatedSceneInstanceIds?.size ||
                this.roots.some((x) => this.categoryWithSceneInstances.has(x))
            ) {
                this.categoryWithSceneInstances.add(id);
            }
            category.children = this.roots;
        }
        this.roots = [id];
        return [id, category];
    }

    add(category: CostCategory): [id: IdCostCategory, category: CostCategory] {
        const id = reserveCostCategoryId();
        this.categories.set(id, category);
        this.roots.push(id);
        if (category.relatedSceneInstanceIds?.size) {
            this.categoryWithSceneInstances.add(id);
        }
        return [id, category];
    }

    flattenCostHierarchy() {
        const hierarchy = this;
        const flattened: FlattenedCostCategoryParams[] = [];
        type QueueItem = {
            level: number;
            id: IdCostCategory;
            path: string[];
        };
        const queue: QueueItem[] = [];
        for (const [id, category] of hierarchy.getRootCategories()) {
            queue.push({ id, level: 0, path: [category.description.value] });
        }

        while (queue.length) {
            const { id, level, path } = queue.splice(0, 1)[0];
            const category = hierarchy.categories.get(id);
            if (!category) {
                continue;
            }

            flattened.push({
                categoryId: id,
                isBottom: !category.children?.length,
                nestLevel: level,
                path,
            });

            if (category.children?.length) {
                const children: QueueItem[] = [];
                for (const id of category.children) {
                    const category = hierarchy.categories.get(id);
                    if (!category) {
                        continue;
                    }
                    children.push({
                        id,
                        level: level + 1,
                        path: [...path, category.description.value],
                    });
                }
                queue.splice(0, 0, ...children);
            }
        }

        return flattened;
    }

    recursiveChildren(
        parentId: IdCostCategory,
        callback: (
            childId: IdCostCategory,
            child: CostCategory,
        ) => any | boolean,
        params?: {
            includeParent?: boolean;
            shouldStopOnlyCurrentBranch?: boolean;
        },
    ) {
        const hierarchy = this;
        const queue: IdCostCategory[] = [];
        if (params?.includeParent) {
            queue.push(parentId);
        } else {
            const parent = hierarchy.categories.get(parentId);
            if (parent?.children?.length) {
                queue.push(...parent.children);
            }
        }
        while (queue.length) {
            const id = queue.splice(0, 1)[0];
            const category = hierarchy.categories.get(id);
            if (!category) {
                continue;
            }
            const shouldStop = callback(id, category);
            if (shouldStop === true) {
                if (params?.shouldStopOnlyCurrentBranch) {
                    continue;
                } else {
                    break;
                }
            }
            if (category.children?.length) {
                queue.splice(0, 0, ...category.children);
            }
        }
    }

    sumupChildren(
        targetId: IdCostCategory,
        params?: { includeQuantity?: boolean },
    ) {
        const target = this.categories.get(targetId);
        if (!target) {
            return;
        }
        const categories: CostCategory[] = [];
        for (const childId of target.children ?? []) {
            const child = this.categories.get(childId);
            if (child) {
                categories.push(child);
            }
        }
        const result = target;
        let laborTotal = 0;
        let materialTotal = 0;
        let subServiceTotal = 0;
        let equipmentTotal = 0;
        const quantities: NumberProperty[] = [];
        for (const category of categories) {
            laborTotal += category.labor?.laborTotal?.value ?? 0;
            materialTotal += category.material?.materialTotal?.value ?? 0;
            subServiceTotal += category.subService?.subServiceTotal?.value ?? 0;
            equipmentTotal += category.equipment?.equipmentTotal?.value ?? 0;

            if (category.quantity?.value) {
                quantities.push(category.quantity.value);
            }
        }
        result.labor = {
            laborTotal: { value: laborTotal },
        };
        result.material = {
            materialTotal: { value: materialTotal },
        };
        result.subService = {
            subServiceTotal: { value: subServiceTotal },
        };
        result.equipment = {
            equipmentTotal: { value: equipmentTotal },
        };
        quantity: if (
            params?.includeQuantity &&
            quantities.length === categories.length
        ) {
            const totalQuantity = sumValueUnitLike(quantities, {
                takeZeroValue: true,
            });
            if (!(totalQuantity instanceof Success)) {
                break quantity;
            }
            const prop = NumberProperty.new(totalQuantity.value);
            result.quantity = {
                value: prop,
            };
        }
        return result;
    }

    static categoryHasCustomValues(c: CostCategory) {
        if (
            c.labor?.laborPerUnit?.flags?.overriden ||
            c.labor?.loadedWageRate?.flags?.overriden ||
            c.labor?.laborCostPerUnit?.flags?.overriden ||
            c.material?.materialCostPerUnit?.flags?.overriden ||
            c.equipment?.equipmentCostPerUnit?.flags?.overriden ||
            c.subService?.subServiceCostPerUnit?.flags?.overriden
        ) {
            return true;
        }
        return false;
    }

    static categoryHasSomePrice(c: CostCategory) {
        if (
            typeof c.labor?.laborPerUnit?.value === "number" ||
            typeof c.labor?.loadedWageRate?.value === "number" ||
            typeof c.labor?.laborCostPerUnit?.value === "number" ||
            typeof c.material?.materialCostPerUnit?.value === "number" ||
            typeof c.equipment?.equipmentCostPerUnit?.value === "number" ||
            typeof c.subService?.subServiceCostPerUnit?.value === "number"
        ) {
            return true;
        }
        return false;
    }

    static groupCategoriesByCostSource(
        hierarchies: CostHierarchy[],
    ): CategoryGroupsByCostSource {
        const seenCategories = new Set<IdCostCategory>();
        const result: CategoryGroupsByCostSource = {
            customModelBasedCosts: [],
            defaultModelBasedCosts: [],
            modelBasedOverridenWithCustomBenchmark: [],
            modelBasedOverridenWithDefaultBenchmark: [],
            customBenchmarkOnly: [],
            defaultBenchmarkOnly: [],
        };
        for (const hierarchy of hierarchies) {
            for (const [id, c] of hierarchy.categories) {
                if (seenCategories.has(id)) {
                    continue;
                }
                seenCategories.add(id);
                if (hierarchy.ignored.has(id)) {
                    continue;
                }
                const costSource =
                    c.costSource?.options?.[c.costSource.index ?? -1];
                if (
                    costSource === CostSourceType.GroupSum ||
                    costSource === CostSourceType.ModelBasedLumpSum
                ) {
                    continue;
                }
                const hasCustomPrices =
                    CostHierarchy.categoryHasCustomValues(c);
                if (c.relatedSceneInstanceIds?.size) {
                    // bottom level model based
                    if (hasCustomPrices) {
                        // custom price
                        result.customModelBasedCosts.push(c);
                    } else {
                        // default price
                        result.defaultModelBasedCosts.push(c);
                    }
                } else {
                    if (
                        costSource === CostSourceType.BenchmarkLumpSum ||
                        costSource === CostSourceType.BenchmarkPerQuantity
                    ) {
                        // benchmark
                        if (!hierarchy.categoryWithSceneInstances.has(id)) {
                            // benchmark-only
                            if (hasCustomPrices) {
                                // custom price
                                result.customBenchmarkOnly.push(c);
                            } else {
                                // default price
                                result.defaultBenchmarkOnly.push(c);
                            }
                        } else {
                            // model-based overriden by benchmark
                            if (hasCustomPrices) {
                                // custom price
                                result.modelBasedOverridenWithCustomBenchmark.push(
                                    c,
                                );
                            } else {
                                // default price
                                result.modelBasedOverridenWithDefaultBenchmark.push(
                                    c,
                                );
                            }
                        }
                    }
                }
            }
        }
        return result;
    }
}

let costCategoryCount = 1;
export function reserveCostCategoryId(): IdCostCategory {
    return costCategoryCount++;
}

interface CategoryGroupsByCostSource {
    defaultModelBasedCosts: CostCategory[];
    customModelBasedCosts: CostCategory[];
    modelBasedOverridenWithDefaultBenchmark: CostCategory[];
    modelBasedOverridenWithCustomBenchmark: CostCategory[];
    customBenchmarkOnly: CostCategory[];
    defaultBenchmarkOnly: CostCategory[];
}
