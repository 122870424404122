import type { Bim, IdBimGeo, IdBimScene} from 'bim-ts';
import {
	BasicAnalyticalRepresentation, BimPatch, ExtrudedPolygonGeometry, SceneInstanceFlags
} from 'bim-ts';
import type { LazyVersioned, UndoStack, Result} from 'engine-utils-ts';
import { ObservableObject, nameof, ObjectUtils, IterUtils, Failure, Success, LazyDerived } from 'engine-utils-ts';
import type { Vector3 } from 'math-ts';
import { Clipper } from 'math-ts';
import type { MenuPath} from 'ui-bindings';
import { EditActionResult, PUI_ConfigBasedBuilderParams, PUI_ConfigPropertyTransformer } from 'ui-bindings';
import type { SceneInt } from '../scene/SceneRaycaster';
import { calcuateObjectBoundaryPoints } from '../utils/BimObjectBoundary';
import type { EditModeControls } from './EditControls';
import { MouseButton } from './InputController';
import type { EditInteractionResult, InteractiveEditOperator } from './InteractiveEditOperator';
import type { MouseEventData } from './MouseGesturesBase';

export class BoundaryOffsetSetting {
	offset: number = 0.3048 * 5;
	zonesJoinDelta: number = 0.3048 * 10;
}

type InstancesDescr =  [IdBimScene, IdBimGeo, ExtrudedPolygonGeometry][];

export interface BoundaryOffsetState {
	sourceObjects: IdBimScene[];
	appliedSettings: BoundaryOffsetSetting | null;
	resultBoundariesAfterJoin: Vector3[][];
	resultObjects: InstancesDescr;
}


export class InteractiveBoundaryAround implements InteractiveEditOperator<BoundaryOffsetState, BoundaryOffsetSetting> {

	readonly menuPath: MenuPath = ['Add', 'Boundary', 'Around selected'];
	readonly priority: number = 1;
	readonly canStart: LazyVersioned<boolean>;
	readonly config: ObservableObject<BoundaryOffsetSetting>;

	constructor(
		readonly bim: Bim,
		readonly editControls: EditModeControls,
		undoStack: UndoStack
	) {
		this.canStart = LazyDerived.new1(
			'isSelected',
			[],
			[bim.instances.selectHighlight.getVersionedFlagged(SceneInstanceFlags.isSelected)],
			([s]) => s.length > 0
		);

		this.config = new ObservableObject({
			identifier: this.menuPath.join(),
			initialState: new BoundaryOffsetSetting(),
			undoStack,
			throttling: { onlyFields: [] }
		});
	}

	configBuilderSettings(): PUI_ConfigBasedBuilderParams {
		return PUI_ConfigBasedBuilderParams.new([
			[
				nameof<BoundaryOffsetSetting>("offset"),
				PUI_ConfigPropertyTransformer.numberProp({
					minMax: [-500, 500],
					step: 0.01,
					unit: "m"
				}),
			],
			[
				nameof<BoundaryOffsetSetting>("zonesJoinDelta"),
				PUI_ConfigPropertyTransformer.numberProp({
					minMax: [0, 500],
					unit: "m"
				}),
			],
		]);
	};


	start(): Result<BoundaryOffsetState> {
		const selected = this.bim.instances.getSelected();
		if (selected.length === 0) {
			return new Failure({msg: 'nothing selected'});
		}
		let state: BoundaryOffsetState = {
			sourceObjects: selected,
			appliedSettings: null,
			resultBoundariesAfterJoin: [],
			resultObjects: [],
		};
		state = this._reconcileStateWithConfig(state);
		return new Success(state);
	}
	finish(state: Readonly<BoundaryOffsetState>) : EditActionResult | undefined {
		const ids = state.resultObjects.map(t => t[0]);
		this.bim.instances.setSelected(ids);
		return new EditActionResult(ids);
	}
	cancel(state: BoundaryOffsetState) {
		this.bim.instances.delete(state.resultObjects.map(t => t[0]));
	}
	handleConfigPatch(
		patch: Partial<BoundaryOffsetSetting>,
		state: Readonly<BoundaryOffsetState>
	): EditInteractionResult<BoundaryOffsetState> {
		const newState = this._reconcileStateWithConfig(state);
		return {
			done: false,
			state: newState
		};
	}

	onHover(int: SceneInt): { cursorStyleToSet: string; } | null {
		return null;
	}

	handleClick(
		sceneInt: SceneInt,
		me: MouseEventData,
		previousResult: BoundaryOffsetState
	): EditInteractionResult<BoundaryOffsetState> {
		if (me.buttons.mouseButton === MouseButton.Right) {
			return {
				state: previousResult,
				done: true,
			};
		}
		return {
			done: false,
			state: previousResult
		};
	}


	_reconcileStateWithConfig(prevState: Readonly<BoundaryOffsetState>): BoundaryOffsetState {
		const config = this.config.poll();
		let state: BoundaryOffsetState = {
			sourceObjects: prevState.sourceObjects,
			appliedSettings: prevState.appliedSettings,
			resultBoundariesAfterJoin: prevState.resultBoundariesAfterJoin,
			resultObjects: prevState.resultObjects,
		};

		if (state.appliedSettings === null || !ObjectUtils.areObjectsEqual(config, state.appliedSettings)) {
			state.appliedSettings = ObjectUtils.deepCloneObj(config);

			const instanceToCalcAround = Array.from(this.bim.instances.peekByIds(state.sourceObjects).values());
			const instancesBoundaries = IterUtils.filterMap(instanceToCalcAround, (inst) => {
				const boundaryPoins = calcuateObjectBoundaryPoints(inst, this.bim);
				return boundaryPoins.length > 0 ? boundaryPoins : undefined;
			});

			state.resultBoundariesAfterJoin = Clipper.calculateBoundariesWithJoinTolerance(instancesBoundaries, config.zonesJoinDelta, config.offset);

			const objsToDeleteCount = state.resultObjects.length - state.resultBoundariesAfterJoin.length;
			if (objsToDeleteCount > 0) {
				const toDelete = state.resultObjects.splice(state.resultBoundariesAfterJoin.length);
				const ids = toDelete.map(t => t[0]);
				this.bim.instances.delete(ids);
			}

			const bimPatch = new BimPatch();

			for (let i = 0; i < state.resultBoundariesAfterJoin.length; ++i) {

				const boundaryPoints = state.resultBoundariesAfterJoin[i];
				const geometryFromBoundary = ExtrudedPolygonGeometry.newWithAutoIds(
					boundaryPoints.map(p => p.xy()),
					undefined,
					0,
					5
				);


				if (state.resultObjects[i] === undefined) {
					// allocate new
					const geoId = this.bim.extrudedPolygonGeometries.reserveNewId();
					const instanceId = this.bim.instances.reserveNewId();

					state.resultObjects[i] = [instanceId, geoId, geometryFromBoundary];

					bimPatch.geometries.toAlloc.push([geoId, geometryFromBoundary]);

					const instance = this.bim.instances.archetypes.newDefaultInstanceForArchetype('boundary');
					instance.representationAnalytical = new BasicAnalyticalRepresentation(geoId);
					bimPatch.instances.toAlloc.push([instanceId, instance]);

				} else {

					// patch exisings
					const [id, geoId, geo] = state.resultObjects[i];

					if (ObjectUtils.areObjectsEqual(geo, geometryFromBoundary)) {
						console.log('geo didnt change');
						continue;
					}

					state.resultObjects[i][2] = geometryFromBoundary;
					bimPatch.geometries.toPatch.push([geoId, geometryFromBoundary]);
				}
			}

			bimPatch.applyTo(this.bim);
		}

		return state;
	}
}



