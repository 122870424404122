import type { InverterDescription } from "../types";
import { Aabb2, Vector2 } from "math-ts";
import { createManyToOneConnection } from "src/sld/templates/connections";
import { Anchor2D } from 'vector-graphic';
import { createCombinerBoxObject2DWithChilds } from "./combiner-box";
import { createInverterObject2D } from "../../templates/inverter";
import { createJumper2D } from "src/sld/templates/jumper";
import { wireOffset, wireWidth } from "src/sld/templates/config";
import { positionChildrensShortend } from "../../templates/common";

export function createInverterObject2DWithChilds(
    inverterDesc: InverterDescription,
    params: { index: string }
) : Anchor2D {
    // create children
    const object = positionChildrensShortend(
        inverterDesc.combinerBoxes,
        (x, index) => createCombinerBoxObject2DWithChilds(
            x, { index: (index + 1) + "" }
        )
    )

    // create base inverter
    const inverter = createInverterObject2D(inverterDesc, params)

    // create children wiring
    const wiring = createManyToOneConnection({
        sources: object.points,
        sourceOffset: 100,
        wireOffset: wireOffset,
        targetOffset: 100,
        targetHeight: inverter.aabb.height() * 0.4,
    })
    object.addAndExpandAabb(wiring);

    // position inberter
    if (!object.aabb.isEmpty() && wiring.points.length) {
        const aabb = aabbReused1.setFromPoints(wiring.points);
        inverter.position.set(aabb.centerX() - inverter.aabb.min.x, aabb.centerY());
        inverter.updateMatrix()
    }
    object.addAndExpandAabb(inverter);

    const inverterAabbWorld = aabbReused1
        .copy(inverter.aabb)
        .applyMatrix3(inverter.matrix)

    const exitPoint = new Vector2(inverterAabbWorld.max.x, inverterAabbWorld.centerY());

    // add wiring before jumper
    const wiringBeforeJumper = createManyToOneConnection({
        sources: [exitPoint],
        sourceOffset: 100,
        targetY: exitPoint.y,
    });
    object.addAndExpandAabb(wiringBeforeJumper)

    const jumper = createJumper2D({ strokeWidth: wireWidth })
    jumper.zIndex = 1;
    object.addAndExpandAabb(jumper);
    jumper.position.copy(wiringBeforeJumper.points[0]);
    jumper.updateMatrix();

    const result = new Anchor2D();
    result.addAndExpandAabb(object);
    result.point.copy(jumper.point).applyMatrix3(jumper.matrix);

    return result;
}

const aabbReused1 = Aabb2.empty();
