// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { TaskDescription } from '../wire/task-description.js';
import { Tasks } from '../wire/tasks.js';


export class VerDataTask {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):VerDataTask {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsVerDataTask(bb:flatbuffers.ByteBuffer, obj?:VerDataTask):VerDataTask {
  return (obj || new VerDataTask()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsVerDataTask(bb:flatbuffers.ByteBuffer, obj?:VerDataTask):VerDataTask {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new VerDataTask()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

taskType():Tasks {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : Tasks.NONE;
}

task<T extends flatbuffers.Table>(obj:any):any|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
}

description(obj?:TaskDescription):TaskDescription|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new TaskDescription()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startVerDataTask(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addTaskType(builder:flatbuffers.Builder, taskType:Tasks) {
  builder.addFieldInt8(0, taskType, Tasks.NONE);
}

static addTask(builder:flatbuffers.Builder, taskOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, taskOffset, 0);
}

static addDescription(builder:flatbuffers.Builder, descriptionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, descriptionOffset, 0);
}

static endVerDataTask(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

}
