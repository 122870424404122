<script lang="ts">
    import { LazyVersioned } from "engine-utils-ts";
    import { VersionedStore } from "../VersionedStore";

    export let name: LazyVersioned<string>;
    export let collapsed: boolean;
    export let active: boolean;
    export let onClick: (isActive: boolean) => void;

    const label = new VersionedStore(name);
</script>

<div
    class="nav-button"
    class:collapsed
    class:active
    on:click={() => onClick(!active)}
>
    <div class="dynamic-label">
        {@html $label}
    </div>
</div>

<style lang="scss">
    .nav-button {
        padding: min(0.5vh, 6px) 8px;
        height: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        margin-top: -2px;

        &:not(:last-child) {
            margin-bottom: 6px;
        }

        &.collapsed :global(.status) {
            display: none;
        }
    }
    .active {
        z-index: 1;
        background-color: white;
        clip-path: inset(0px -2px 0px 4px);
        &:after {
            content: "";
            display: block;
            max-height: 34px;
            height: 100%;
            width: calc(100% - 6px);
            position: absolute;
            right: -1px;
            border-right: 2px solid var(--mdc-theme-primary);
            border-radius: 2px 0 0 2px;
        }
    }
    .dynamic-label {
        display: flex;
        line-height: 12px;
        font-weight: 500;
        :global(.version) {
            background-color: var(--color-main-medium);
        }
        :global(.text-good .version) {
            background-color: var(--color-good);
        }
        :global(.text-danger .version) {
            background-color: var(--color-danger);
        }
        :global(.version) {
            padding: 1px 4px;
            border-radius: 4px;
            color: white;
            font-size: 10px;
            font-weight: 700;
        }
        :global(div > span:first-child) {
            width: 24px;
            display: flex;
            justify-content: center;
        }
        :global(div) {
            display: flex;
            gap: 8px;
            align-items: center;
        }
    }
</style>
