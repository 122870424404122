
export type TypedNumbersArr = Uint8Array | Int8Array | Uint16Array | Int16Array | Uint32Array | Int32Array | Float32Array | Float64Array;

export class TypedNumbersVec<Arr extends TypedNumbersArr> {

    _allocator: (size: number) => Arr;
    buffer: Arr;
    length: number = 0;

    constructor(allocator: (size: number) => Arr, initialCapacity?: number) {
        this._allocator = allocator;
        this.buffer = allocator(initialCapacity ?? 10);
    }

    static empty<Arr extends TypedNumbersArr>(arr: {new(capacity?: number): Arr}) {
        return new TypedNumbersVec<Arr>(() => new arr(), 0);
    }

    get(index: number) {
        if (this.length <= index) {
            throw new Error(`invalid get index ${index}, curr length: ${this.length}`);
        }
        return this.buffer[index];
    }

    push(value: number) {
        if (this.buffer.length <= this.length) {
            this.reserve(this.length + 1);
        }
        this.buffer[this.length] = value;
        this.length += 1;
    }
    push2(value1: number, value2: number) {
        if (this.buffer.length <= this.length + 1) {
            this.reserve(this.length + 2);
        }
        this.buffer[this.length] = value1;
        this.buffer[this.length + 1] = value2;
        this.length += 2;
    }
    push3(value1: number, value2: number, value3: number) {
        if (this.buffer.length <= this.length + 2) {
            this.reserve(this.length + 3);
        }
        this.buffer[this.length] = value1;
        this.buffer[this.length + 1] = value2;
        this.buffer[this.length + 2] = value3;
        this.length += 3;
    }

    extendWith(source: TypedNumbersArr | Array<number>) {
        const count = source.length;
        if (!(count > 0 && count < Infinity)) {
            throw new Error(`invalid count ${count}`);
        }
        if (this.buffer.length - this.length < count) {
            this.reserve(this.length + count);
        }
        this.buffer.set(source, this.length);
        this.length += count;
    }

    clear() {
        this.buffer.fill(0, 0, this.length);
        this.length = 0;
    }

    view(): Arr {
        return this.buffer.subarray(0, this.length) as Arr;
    }

    reserve(minCapacity: number) {
        const newCapacity = Math.max(32, this.length * 2, minCapacity);
        if (newCapacity <= this.buffer.length) {
            return;
        }
        const oldBuf = this.buffer;
        this.buffer = this._allocator(newCapacity);
        this.buffer.set(oldBuf.subarray(0, this.length), 0);
    }
}
