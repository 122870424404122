<script lang="ts">
            
    import { getContext, onDestroy } from "svelte";
    import type { UnitsMapper } from 'bim-ts';
    import type { SelectByPropsContext } from './SelectByPropsContext';
    import { PUI_Lazy } from 'ui-bindings';
    import PUI_Root from '../pui/PUI_Root.svelte';
    import type { Readable } from 'svelte/store';
    import { createSelectByPropParamsUi } from './SelectByPropsParams';
            
    export let context: SelectByPropsContext;

    const bim = context.bim;
    const unitsMapper = getContext('unitsMapper') as Readable<UnitsMapper>;

    const [_selectionParamsObs, paramsPui] = createSelectByPropParamsUi(bim, $unitsMapper);

</script>

<div class="root">
    </div>
    <div>
        
    <PUI_Root
        configIdentifier={'props-charts-params'}
        puiSource={new PUI_Lazy(paramsPui)}
    ></PUI_Root>

</div>

<style>
    .root {
        min-width: 400px;
        display: flex;
        flex-direction: column;
    }
</style>

    