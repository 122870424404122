import type { BasicAnalyticalRepresentation, ObjectRepresentation, SceneInstance} from "bim-ts";
import { SceneInstanceFlags, SceneObjDiff } from "bim-ts";
import type { RGBAHex } from "engine-utils-ts";
import type { Matrix4 } from "math-ts";

export type ESO_Any = ESO;

export enum ESO_AnotationFlags {
	None = 0,
	IsInEdit = 1 << 0,
	// ShowAnnotations = 1 << 1,
}

export abstract class ESO {

	bimFlags: SceneInstanceFlags;
	colorTint: RGBAHex | 0;
	annotationFlags: ESO_AnotationFlags = ESO_AnotationFlags.None;
	readonly worldMatrix: Readonly<Matrix4>;
	readonly sceneInstanceRef: Readonly<SceneInstance>;

	constructor(
		sceneInstanceRef: Readonly<SceneInstance>,
	) {
		this.bimFlags = sceneInstanceRef.flags;
		this.colorTint = sceneInstanceRef.colorTint;
		this.worldMatrix = sceneInstanceRef.worldMatrix;
		this.sceneInstanceRef = sceneInstanceRef;
	}
	
	get isHidden() { return this.bimFlags & SceneInstanceFlags.isHidden }
	get isSelected() { return this.bimFlags & SceneInstanceFlags.isSelected }
	get isHighlighted() { return this.bimFlags & SceneInstanceFlags.isHighlighted }
	
	bimRepresentation(): ObjectRepresentation | BasicAnalyticalRepresentation | null {
		const inst = this.sceneInstanceRef;
		return inst.representation ?? inst.representationAnalytical;
	}

}

export const BimFlagsObjDiff = SceneObjDiff.Selected | SceneObjDiff.Highlighted | SceneObjDiff.Hidden;


