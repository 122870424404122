import { EnergyPipelineProperty, NumberProperty, NumbersArrayProperty, PropsGroupBase } from 'bim-ts';
import type { EnergyYieldPropsGroup} from 'bim-ts';
import type { CompressibleNumbersArray} from 'engine-utils-ts';

export interface EnergyViewBasicProps {
    [key: string]: EnergyViewBasicProps | NumberProperty | undefined;
}

export class EnergyViewData {
    toChart: Map<string, CompressibleNumbersArray> = new Map();
    basic_props: EnergyViewBasicProps = {};
}

export function generateEnergyViewData(energyData: EnergyYieldPropsGroup): EnergyViewData {
    
    const result: EnergyViewData = new EnergyViewData();

    for (const key in energyData) {
        const energy_prop = energyData[key];

        if (!(energy_prop instanceof Object)) {
            continue;
        }
        if (energy_prop instanceof NumberProperty) {
            result.basic_props[key] = energy_prop;

        } else if (energy_prop instanceof EnergyPipelineProperty) {
            const charts = energy_prop.pipelineCharts();

            for (const [chartKey, chart] of charts) {
                result.toChart.set(chartKey, chart);
            }

        } else if (energy_prop instanceof NumbersArrayProperty) {

            result.toChart.set(key, energy_prop.value);
            
        } else if (energy_prop instanceof PropsGroupBase) {

            // result.toChart.set(key + '_power_chart', tmyPowerProp);
            // const additionalStats = tmyPowerProp.acquireAdditionalStats();
            for (const [key2, value2] of Object.entries(energy_prop)) {
                if (!(value2 instanceof Object)) {
                    continue;
                }
                if (value2 instanceof NumbersArrayProperty) {
                    result.toChart.set([key, key2].join('|'), value2.value);
                }
            }
        } else {
            console.warn('property not recognized', key, energy_prop);
        }
    }
    return result;
}
