import { Vector3 } from 'math-ts';
import { NotificationDescription, NotificationType, PUI_BuilderCallbacks, PUI_GroupNode, type FileImporterContext } from 'ui-bindings';
import { Yield } from 'engine-utils-ts';
import { notificationSource } from 'src/Notifications';
import { Bim, BimPatch, BimSceneOrigin, WgsProjectionOrigin } from 'bim-ts';
import { WGSConverter } from '../WGSConverter';

export const heightLimit = 5000; //max height
export const lengthLimit = 100000; //max distance in meters from the start point


export enum ImportMode {
    Add,
    Replace
}


export function checkDistanceToImportLayoutCenter(
    cartesianSceneOrigin: Vector3, 
    layoutCartesianOrigin: Vector3,
    sendNotification: (notification: NotificationDescription) => void
) {
    const difference = cartesianSceneOrigin.clone().sub(layoutCartesianOrigin);

    if (difference.length() >= lengthLimit) {
        sendNotification(NotificationDescription.newBasic({
            source: notificationSource,
            key: 'coordinatesAreTooFarFromProjectOrigin',
            type: NotificationType.Error,
            addToNotificationsLog: true,
        }));
        throw new Error("Objects are too far from the project origin!");
    } else {
        sendNotification(NotificationDescription.newBasic({
            source: notificationSource,
            key: 'objectsMayBeMisaligned',
            type: NotificationType.Warning,
            addToNotificationsLog: true,
        }));
    }
}


export function getBimPatchOrigin(
    bim: Bim,
    importMode: ImportMode,
    layoutCartesianOrigin: Vector3 | null | undefined,
    layoutWgsOrigin: WgsProjectionOrigin | null | undefined,
    sendNotification: (notification: NotificationDescription) => void
):{origin:BimSceneOrigin, correction: Vector3} {
    
    if(!bim.instances.getSceneOrigin()){
        bim.instances.patchSceneOrigin(new BimSceneOrigin())
    }

    const sceneOrigin = bim.instances.getSceneOrigin()!
    const layoutBimOrigin = new BimSceneOrigin();
    const correction = layoutCartesianOrigin ? layoutCartesianOrigin.clone() : Vector3.zero();

    // Helper to set the layout BIM origin
    function setLayoutBimOrigin(cartesianOrigin?: Vector3, wgsOrigin?: WgsProjectionOrigin | null) {
        layoutBimOrigin.cartesianCoordsOrigin = cartesianOrigin ? cartesianOrigin : layoutCartesianOrigin ? layoutCartesianOrigin : new Vector3();
        layoutBimOrigin.wgsProjectionOrigin = wgsOrigin ? wgsOrigin : layoutWgsOrigin ? layoutWgsOrigin : null;
    }
    
    // Handle ImportMode.Replace: Directly replace the BIM origin
    if (importMode === ImportMode.Replace) {
        setLayoutBimOrigin();
        if (layoutBimOrigin.wgsProjectionOrigin) {
            layoutBimOrigin.wgsProjectionOrigin.wgsOriginCartesianCoords.sub(correction);
        }
        return {origin:layoutBimOrigin, correction};
    }

    const isProjectEmpty =
        !sceneOrigin.cartesianCoordsOrigin?.length() &&
        bim.instances.perId.size === 0;

    // Handle cases based on the scene origin
    if (!isProjectEmpty && sceneOrigin.wgsProjectionOrigin) {
        // Scene origin has a WGS projection origin
        setLayoutBimOrigin(sceneOrigin.cartesianCoordsOrigin!, sceneOrigin.wgsProjectionOrigin);
        if(layoutWgsOrigin === null){
            checkDistanceToImportLayoutCenter(sceneOrigin.cartesianCoordsOrigin!, correction, sendNotification);
        }

    } else if (!isProjectEmpty && !sceneOrigin.wgsProjectionOrigin) {
        // Scene origin only has a Cartesian origin
        setLayoutBimOrigin(sceneOrigin.cartesianCoordsOrigin!, layoutWgsOrigin)
        checkDistanceToImportLayoutCenter(sceneOrigin.cartesianCoordsOrigin!, correction, sendNotification);
        layoutBimOrigin.wgsProjectionOrigin?.wgsOriginCartesianCoords.sub(sceneOrigin.cartesianCoordsOrigin!)
    } else {
        // Fallback case for no valid origins
        setLayoutBimOrigin();
        layoutBimOrigin.wgsProjectionOrigin?.wgsOriginCartesianCoords.sub(correction)
    }

    // Calculate the correction vector
    if(sceneOrigin.wgsProjectionOrigin && layoutWgsOrigin){
        const sceneWgsOrigin = sceneOrigin.wgsProjectionOrigin;

        const projectionDifference2d = WGSConverter.getCartesianVector(layoutWgsOrigin, sceneWgsOrigin.wgsOriginLatLong);
        const projectionDifference = Vector3.fromVec2(projectionDifference2d.negate());
        const sceneWgsPosition = sceneWgsOrigin.wgsOriginCartesianCoords.clone();
        const layoutWgsPosition = layoutWgsOrigin.wgsOriginCartesianCoords.clone();
        const difference = ((sceneWgsPosition.clone().add(projectionDifference)).sub(layoutWgsPosition));
        correction.copy(difference.negate());

    }else if(!isProjectEmpty){
        correction.copy(sceneOrigin.cartesianCoordsOrigin!)
    }

    return {origin:layoutBimOrigin, correction};
}


export function applyOriginCompensationToPatch(args: {
    bimPatch: BimPatch,
    bim: Bim,
    compensateOriginsDifference?: boolean
    replaseProjectOrigin?: boolean,
    sendNotification: (notification: NotificationDescription) => void,
}) {

    const bimAssetOrigin = args.bimPatch.sceneOrigin;  
    
    const importMode = args.replaseProjectOrigin ? ImportMode.Replace : ImportMode.Add;

    const data = getBimPatchOrigin(
        args.bim,
        importMode,
        bimAssetOrigin?.cartesianCoordsOrigin,
        bimAssetOrigin?.wgsProjectionOrigin,
        args.sendNotification,
    );

    args.bimPatch.sceneOrigin = data.origin;
    
    if (args.compensateOriginsDifference) {
        args.bimPatch.translateNewSceneObjects(data.correction.negate());  
    }
}

export function* requestOriginCompensationSettings(args: {
    sceneOrigin: BimSceneOrigin | null,
    assetOrigin: BimSceneOrigin | null,
    context?: FileImporterContext | undefined, 
}) {

    const sceneOrigin = args.sceneOrigin;
    const assetOrigin = args.assetOrigin

    const assetHasOrigin = (
        assetOrigin != null
        && (assetOrigin.cartesianCoordsOrigin != null || assetOrigin.wgsProjectionOrigin != null)
    );

    const sceneHasOrigin = (
        sceneOrigin != null
        && (sceneOrigin.cartesianCoordsOrigin != null || sceneOrigin.wgsProjectionOrigin != null)
    );

    let civilOriginsNotEqual = (
        assetOrigin?.cartesianCoordsOrigin != null
        && sceneOrigin?.cartesianCoordsOrigin != null
        && !assetOrigin.civilOriginEquals?.(sceneOrigin)
    );

    let wgsOriginsNotEqual = (
        assetOrigin?.wgsProjectionOrigin != null
        && sceneOrigin?.wgsProjectionOrigin != null
        && !assetOrigin.civilOriginEquals?.(sceneOrigin)
    );

    let replaceProjectCoordinateSystem = false;
    let compensateOriginsDifferenceOnImport = true;
    const builderCallbacks = new PUI_BuilderCallbacks();

    if (civilOriginsNotEqual || wgsOriginsNotEqual) {

        builderCallbacks.addTypeFilteredAfterNodeCallback(PUI_GroupNode, (builder, groupNode) => {
            const options = ["import into project's coordinate system",
                "replace project's coordinate system",
                "ignore coordinates difference"];
            builder.addSelectorProp({
                name: "origins collisions",
                options: options,
                defaultValue: options[0],
                onChange: (newValue: string) => {
                    if (newValue === "import into project's coordinate system") {
                        replaceProjectCoordinateSystem = false;
                        compensateOriginsDifferenceOnImport = true;
                    } else if (newValue === "replace project's coordinate system") {
                        replaceProjectCoordinateSystem = true;
                        compensateOriginsDifferenceOnImport = false;
                    } else if (newValue === "ignore coordinates difference") {
                        replaceProjectCoordinateSystem = false;
                        compensateOriginsDifferenceOnImport = false;
                    }
                }
            });
        });

        if (args.context) {
            yield Yield.NextFrame;
            yield Yield.NextFrame;
            const settings = yield* args.context.requestSettings({
                ident: 'asset and project origins differ',
                defaultValue: {},
                uiBuilderParams: {
                    puiBuilderParams: {
                        callbacks: builderCallbacks,
                    }
                }
            });
        }

    } else if (!sceneHasOrigin && assetHasOrigin) {

        if (args.context) {
            yield Yield.NextFrame;
            yield Yield.NextFrame;
            const settings = yield* args.context.requestSettings({
                ident: 'Import project coordinate system from bimasset',
                defaultValue: { 'import coordinate system': true }
            });

            replaceProjectCoordinateSystem = settings["import coordinate system"];
            compensateOriginsDifferenceOnImport = false;
        }
    }

    return { replaceProjectCoordinateSystem, compensateOriginsDifferenceOnImport };
}