// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class HeightmapIrregular implements flatbuffers.IUnpackableObject<HeightmapIrregularT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):HeightmapIrregular {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsHeightmapIrregular(bb:flatbuffers.ByteBuffer, obj?:HeightmapIrregular):HeightmapIrregular {
  return (obj || new HeightmapIrregular()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsHeightmapIrregular(bb:flatbuffers.ByteBuffer, obj?:HeightmapIrregular):HeightmapIrregular {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new HeightmapIrregular()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

points(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readFloat64(this.bb!.__vector(this.bb_pos + offset) + index * 8) : 0;
}

pointsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

pointsArray():Float64Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? new Float64Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

trisInds(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
}

trisIndsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

trisIndsArray():Uint32Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? new Uint32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

static startHeightmapIrregular(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addPoints(builder:flatbuffers.Builder, pointsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, pointsOffset, 0);
}

static createPointsVector(builder:flatbuffers.Builder, data:number[]|Float64Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createPointsVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createPointsVector(builder:flatbuffers.Builder, data:number[]|Float64Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(8, data.length, 8);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addFloat64(data[i]!);
  }
  return builder.endVector();
}

static startPointsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(8, numElems, 8);
}

static addTrisInds(builder:flatbuffers.Builder, trisIndsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, trisIndsOffset, 0);
}

static createTrisIndsVector(builder:flatbuffers.Builder, data:number[]|Uint32Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createTrisIndsVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createTrisIndsVector(builder:flatbuffers.Builder, data:number[]|Uint32Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt32(data[i]!);
  }
  return builder.endVector();
}

static startTrisIndsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endHeightmapIrregular(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createHeightmapIrregular(builder:flatbuffers.Builder, pointsOffset:flatbuffers.Offset, trisIndsOffset:flatbuffers.Offset):flatbuffers.Offset {
  HeightmapIrregular.startHeightmapIrregular(builder);
  HeightmapIrregular.addPoints(builder, pointsOffset);
  HeightmapIrregular.addTrisInds(builder, trisIndsOffset);
  return HeightmapIrregular.endHeightmapIrregular(builder);
}

unpack(): HeightmapIrregularT {
  return new HeightmapIrregularT(
    this.bb!.createScalarList<number>(this.points.bind(this), this.pointsLength()),
    this.bb!.createScalarList<number>(this.trisInds.bind(this), this.trisIndsLength())
  );
}


unpackTo(_o: HeightmapIrregularT): void {
  _o.points = this.bb!.createScalarList<number>(this.points.bind(this), this.pointsLength());
  _o.trisInds = this.bb!.createScalarList<number>(this.trisInds.bind(this), this.trisIndsLength());
}
}

export class HeightmapIrregularT implements flatbuffers.IGeneratedObject {
constructor(
  public points: (number)[] = [],
  public trisInds: (number)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const points = HeightmapIrregular.createPointsVector(builder, this.points);
  const trisInds = HeightmapIrregular.createTrisIndsVector(builder, this.trisInds);

  return HeightmapIrregular.createHeightmapIrregular(builder,
    points,
    trisInds
  );
}
}
