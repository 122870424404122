import { DefaultMapObjectKey, ObjectUtils } from "engine-utils-ts";
import { Vector2 } from "math-ts";

export class PointsSet<T extends {x: number, y: number}> {
    private readonly _pointsMap: DefaultMapObjectKey<T, number>;
    private _lastIdx: number;

    constructor() {
        this._lastIdx = 0;
        this._pointsMap = new DefaultMapObjectKey<T, number>({
            unique_hash: (p) => `${round(p.x)}:${round(p.y)}`,
            valuesFactory: () => this._lastIdx++,
        });
    } 

    public IdxArray(items: T[]): number[] {
        return items.map((i) => this.Idx(i));
    }

    public Idx(item: T): number {
        ObjectUtils.deepFreeze(item);
        const idx = this._pointsMap.getOrCreate(item);
        return idx;
    }

    public ToArray(): T[] {
        return Array.from(this._pointsMap.keys());
    }

    public ToVector2Array(): Vector2[] {
        const res: Vector2[] = [];
        for (const p of this._pointsMap.keys()) {
            res.push(new Vector2(p.x, p.y));
        }
        return res;
    }
}

function round(n: number){
    return Math.ceil(n * 1000);
} 