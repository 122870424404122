import type { LazyVersioned } from "engine-utils-ts";
import { IterUtils, LazyDerivedAsync, Immer, replaceCurrencyUnitWithSymbol } from "engine-utils-ts";
import type { Bim} from "src";
import { FixedTiltTypeIdent, NumberProperty, StringProperty, TrackerTypeIdent, calculateGlobalRowsHeights } from "src";
import { CostHierarchy, CostSourceType, createMiscCategory, fillModelBasedCostCategory, fillModelBasedTopLevelCategory } from "../..";
import type { CostComponents, CostsConfigProvider, EstimateCost, SingleEstimateProvider} from "src/cost-model/capital";
import { createEmptyCostComponents, createEmptyCostComponentsNonNullable } from "src/cost-model/capital";

export function create_EndOfRow(
    bim: Bim,
    provider: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>
) {
    const endOfRows = getEndOfRowAmount(bim);

    const result = LazyDerivedAsync.new3<
        CostHierarchy,
        number,
        EstimateCost[],
        NumberProperty
    >(
        'eor',
        [bim.unitsMapper],
        [endOfRows, provider.allEstimateCosts, totalDC],
        function* ([endOfRows, estimates, totalDC]) {
            const hierarchy = new CostHierarchy();
            const costUnit = bim.unitsMapper.mapToConfigured({ value: 0, unit: 'usd' }).unit!;

            // add end of row
            {
                const costId = 'end-of-row-each';
                const emptyOverrides: EstimateCost = {
                    costs: createEmptyCostComponents(),
                    id: StringProperty.new({ value: costId }),
                    costSource: StringProperty.new({ value: CostSourceType.BenchmarkPerQuantity }),
                }
                const overrides = estimates.find(x => x.id.value === costId)?.costs ?? createEmptyCostComponents();
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.materialCost = NumberProperty.new({ value: 120, unit: 'usd' });
                const category = hierarchy.add({
                    description: { value: 'End Of Row' },
                    costUnit: {
                        options: [replaceCurrencyUnitWithSymbol(costUnit) + '/each' ],
                        index: 0,
                    },
                    quantity: { value: NumberProperty.new({ value: endOfRows }) },
                })[1];
                const updateCosts = (newCosts: CostComponents) => {
                    provider.findAndUpdateEstimateCost(
                        costId,
                        (prev) => Immer.produce(prev ?? emptyOverrides, draft => { draft.costs = newCosts }),
                    )
                }
                fillModelBasedCostCategory(category, overrides, defaults, updateCosts, endOfRows);
            }

            // add misc
            {
                const costId = 'end-of-row-misc';
                const overrides = estimates.find(x => x.id.value === costId);
                const state: SingleEstimateProvider = {
                    id: costId,
                    defaults: createEmptyCostComponentsNonNullable(),
                    value: overrides,
                    update: (fn) => provider.findAndUpdateEstimateCost(costId, fn),
                }
                hierarchy.add(createMiscCategory(state, bim.unitsMapper, totalDC))
            }

            // add root
            {
                const root = hierarchy.addRoot({ description: { value: 'End Of Row' } })
                hierarchy.categoryWithSceneInstances.add(root[0])
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.materialCost = NumberProperty.new({ value: 0.001 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    hierarchy,
                    'design-and-engineering-benchmark',
                    estimates,
                    provider.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC,
                );
            }

            return hierarchy;
        }
    )
    return result;
}

export function getEndOfRowAmount(bim: Bim) {
    const trackers = bim.instances.getLazyListOfTypes({ type_identifiers: [FixedTiltTypeIdent, TrackerTypeIdent] })

    const endOfRowAmount = LazyDerivedAsync.new1(
        'endOfRowAmount',
        [],
        [trackers],
        function* ([trackers]) {
            const totalHeights = yield* calculateGlobalRowsHeights(bim, trackers.map(x => x[0]));
            const result = IterUtils.sum(Array.from(totalHeights.values()), x => x);
            return result;
        }
    )

    return endOfRowAmount;
}
