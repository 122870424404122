<script lang="ts">
import type { CatalogItemManagerHierarchy } from "./CatalogItemManager";
import HierarchyContent from "./HierarchyContent.svelte";

export let hierarchy: CatalogItemManagerHierarchy;

</script>

<ul class="mdc-deprecated-list mdc-deprecated-list--dense">
    <li role="separator" class="mdc-deprecated-list-divider" />
    <HierarchyContent hierarchy={hierarchy} />
</ul>

<style lang="scss">
.mdc-deprecated-list :global(li.mdc-deprecated-list-item) {
    height: 32px;
}
.mdc-deprecated-list :global(h3.mdc-deprecated-list-item) {
    height: 38px;
    margin-top: 0;
    margin-bottom: 0;
}
.mdc-deprecated-list :global(h2.mdc-deprecated-list-item) {
    height: 44px;
    margin-top: 0;
    margin-bottom: 0;
    text-transform: capitalize;
}
</style>
