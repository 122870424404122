<script lang="ts">
    import { Button, ButtonComponent, ButtonType } from "../../../libui/button";
    import { VersionedStore } from "../../../VersionedStore";
    import { LazyBasic, LazyDerived } from "engine-utils-ts";
    import { Vector2 } from "math-ts";
    import type { TextButtonSettings } from "../../custom-components/SelectArea/SelectedArea";

    export let buttons: TextButtonSettings[] = [];

    $: isDisabledLazy = buttons.map((b) => {
        if (b.isEnabled == undefined) {
            return new LazyBasic("isDisabled", false);
        }
        return b.isEnabled;
    });

    $: isDisabled = new VersionedStore<boolean[]>(
        LazyDerived.fromArr("isDisabled", null, isDisabledLazy, (values) => {
            return values;
        })
    );
</script>

<div class="buttons-group">
    {#each buttons as button, idx}
        <ButtonComponent
            desc={new Button(
                button.label,
                ButtonType.Text,
                (e) => {
                    button.action(new Vector2(e.x, e.y));
                },
                $isDisabled[idx],
                button.iconBefore
            )}
        />
    {/each}
</div>

<style lang="scss">
    .buttons-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 8px;
        padding-top: 8px;
    }

    .buttons-group :global(.mdc-button) {
        height: 16px;
    }
</style>
