<script lang="ts">
    import { IconComponent } from "../libui/icon";
    import { Tooltip } from "../libui/tooltip";

    export let content: string | undefined = undefined;
    export let name: string;

    let container: HTMLDivElement;
</script>

<div class="icon-container text-main-light" bind:this={container}>
    <Tooltip id="{name}-tooltip">
        <IconComponent name="Info" slot="anchor" />
        <svelte:fragment slot="content">
            {#if content}
                {@html content}
            {:else}
                <slot></slot>
            {/if}
        </svelte:fragment>
    </Tooltip>
</div>

<style lang="scss">
    .icon-container {
        padding: 1px;
        height: 18px;
        width: 18px;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
            color: var(--mdc-theme-primary);
        }
    }
</style>
