import { DefaultMap, DefaultMapObjectKey, IterUtils, ObjectUtils, Yield } from "engine-utils-ts";
import { Aabb, Aabb2, Clipper, KrMath, Vector2, Vector3 } from "math-ts";
import { IrregularHeightmapGeometry } from "../geometries/IrregularHeightmapGeometries";
import { TerrainTileId } from "./TerrainTile";
import { calcInTriangleZAt } from "./TriangleUtils";

const maxInGroupDistance = 0.01;
const doubleMaxInGroupDistance = 2 * maxInGroupDistance;
const maxInGroupDistanceSquared = maxInGroupDistance * maxInGroupDistance;

function distanceToBucket(point: Vector3, bucket: Vector2) {
    return Math.max(Math.abs(point.x - bucket.x), Math.abs(point.y - bucket.y));
}

export function *cutTerrainIntoTiles(args: {
    indices: ArrayLike<number>, 
    positionsFlat3d: ArrayLike<number>,
    tileSize: number,
}): Generator<Yield, Map<TerrainTileId, IrregularHeightmapGeometry>>  {

    const indices = Array.from(args.indices);
    const positionsFlat = Array.from(args.positionsFlat3d);

    const perTileTriangles = new DefaultMap<TerrainTileId, {inTileTriangles: number[][]}>(
        () => {return {inTileTriangles: [], borderTriangles: []}}
    );

    const triangleReused = new Triangle();
    const tileIdsReused = new Set<TerrainTileId>();
    const tileAabb = Aabb2.empty();
    for (let i = 0; i < indices.length; i += 3) {
        tileIdsReused.clear();
        triangleReused.setFromIndices(indices, positionsFlat, i);

        
        for (const p of triangleReused.points) {
            const tileId = TerrainTileId.newFromPoint(p, args.tileSize);
            tileIdsReused.add(tileId);
        }


        if (tileIdsReused.size === 1) {
            // if all triangle points lie in single tile, triangle is completely inside
            const tileId = IterUtils.getFirstFromIter(tileIdsReused)!;
            perTileTriangles.getOrCreate(tileId).inTileTriangles.push(triangleReused.indices.slice());
        } else {
            //otherwise we should calculate intersections of this triangle with all the tiles it intersects

            let tileCoordsAabb = Aabb2.empty();
            tileCoordsAabb.setFromPoints(Array.from(tileIdsReused));
            if (tileCoordsAabb.isEmpty()) {
                continue;
            }
            for (let tileX = tileCoordsAabb.min.x; tileX <= tileCoordsAabb.max.x; ++tileX) {
                for (let tileY = tileCoordsAabb.min.y; tileY <= tileCoordsAabb.max.y; ++tileY) {
                    const tileId = TerrainTileId.new(tileX, tileY);
                    tileId.toAabb(tileAabb, args.tileSize);

                    const intersections = Clipper.calculatePolygonsIntersections(
                        [
                            tileAabb.cornerPoints() as Vector2[],
                            triangleReused.points.map(v => v.xy()),
                        ],
                        0.001,
                    );
                    if (intersections.length === 0) {
                        continue;
                    }

                    if (intersections.length > 1) {
                        console.warn('more than 1 polygon resulted in intersection of triangle and square', ObjectUtils.deepCloneObj(triangleReused));
                    }

                    const polygonPointIndices: number[] = [];
                    for (const polygon of intersections) {
                        for (const p of polygon) {
                            const elevationZ = calcInTriangleZAt(triangleReused.v0, triangleReused.v1, triangleReused.v2, p);
                            if (elevationZ !== null) {
                                polygonPointIndices.push(positionsFlat.length / 3);
                                positionsFlat.push(
                                    p.x,
                                    p.y,
                                    elevationZ,
                                );
                            }
                        }
                    }

                    const perTile = perTileTriangles.getOrCreate(tileId);
                    for (let i = 2; i < polygonPointIndices.length; ++i) {
                        perTile.inTileTriangles.push([
                            polygonPointIndices[0],
                            polygonPointIndices[i-1],
                            polygonPointIndices[i],
                        ]);
                    }

                }
            }
        }
    }

    yield Yield.Asap;

    const result = new Map<TerrainTileId, IrregularHeightmapGeometry>();

    //rebuild positions array and indices array for every tile
    for (const [tileId, perTile] of perTileTriangles) {

        const perOldIndexVectors = new Map<number, Vector3>();
        const perClosesRoundedNodeVectorsRefs = new DefaultMapObjectKey<Vector2, Vector3[]>({
            valuesFactory: () => [],
            unique_hash: Vector2.asString,
        });
        for (const triangleInds of perTile.inTileTriangles) {
            for (const index of triangleInds) {
                if (!perOldIndexVectors.has(index)) {
                    const v = new Vector3().setFromArray(positionsFlat, index * 3);
                    perOldIndexVectors.set(index, v);
                    
                    perClosesRoundedNodeVectorsRefs.getOrCreate(
                        Object.freeze(new Vector2(
                            KrMath.floorTo(v.x, doubleMaxInGroupDistance) + maxInGroupDistance, 
                            KrMath.floorTo(v.y, doubleMaxInGroupDistance) + maxInGroupDistance
                        ))
                    ).push(v);
                    perClosesRoundedNodeVectorsRefs.getOrCreate(
                        Object.freeze(new Vector2(
                            KrMath.roundTo(v.x, doubleMaxInGroupDistance), 
                            KrMath.floorTo(v.y, doubleMaxInGroupDistance) + maxInGroupDistance
                        ))
                    ).push(v);
                    perClosesRoundedNodeVectorsRefs.getOrCreate(
                        Object.freeze(new Vector2(
                            KrMath.floorTo(v.x, doubleMaxInGroupDistance) + maxInGroupDistance, 
                            KrMath.roundTo(v.y, doubleMaxInGroupDistance)
                        ))
                    ).push(v);
                    perClosesRoundedNodeVectorsRefs.getOrCreate(
                        Object.freeze(new Vector2(
                            KrMath.roundTo(v.x, doubleMaxInGroupDistance), 
                            KrMath.roundTo(v.y, doubleMaxInGroupDistance)
                        ))
                    ).push(v);
                }
            }
        }

        const smallerGroups = IterUtils.mapIter(perClosesRoundedNodeVectorsRefs.entries(), ([bucket, points]) => {
            const smallerGroups: Vector3[][] = [];
            
            perPoint: for (let point of points) {
                if (distanceToBucket(point, bucket) < maxInGroupDistance) {
                    for (const gr of smallerGroups) {
                        if (gr[0].xyDistanceToSquared(point) < maxInGroupDistanceSquared) {
                            gr.push(point);
                            point = new Vector3(Infinity, Infinity, Infinity);
                            continue perPoint;
                        }
                    }
                    smallerGroups.push([point]);
                    point = new Vector3(Infinity, Infinity, Infinity);
                }
            }
            return smallerGroups;
        }).flat();

        // now inside each group, find average, and copy it into every point
        for (const group of smallerGroups) {
            if (group.length < 2) {
                continue;
            }
            const mean = new Vector3();
            for (const p of group) {
                mean.add(p);
            }
            mean.divideScalar(group.length);
            for (const p of group) {
                p.copy(mean);
            }
        }

        const oldNewIndexRemapping = new Map<number, number>();

        // now we will have the same indices for points with equals values
        // because we already copied the same averaged out value into each
        const newIndices: DefaultMapObjectKey<Vector3, number> = new DefaultMapObjectKey({
            valuesFactory: () => newIndices.size,
            unique_hash: Vector3.asString,
        });
        for (const [index, position] of perOldIndexVectors) {
            let remapped = oldNewIndexRemapping.get(index);
            if (remapped === undefined) {
                Object.freeze(position);
                remapped = newIndices.getOrCreate(position);
                oldNewIndexRemapping.set(index, remapped);
            }
        }

        const newPositions = IterUtils.mapIter(newIndices.keys(), v => v.clone());
        const trianglesIndices: number[] = [];
        for (const tr of perTile.inTileTriangles) {

            const ind0 = oldNewIndexRemapping.get(tr[0])!;
            const ind1 = oldNewIndexRemapping.get(tr[1])!;
            const ind2 = oldNewIndexRemapping.get(tr[2])!;
            if (ind0 === ind1 || ind0 === ind2 || ind1 === ind2) {
                continue;
            }
            trianglesIndices.push(ind0, ind1, ind2);
        }

        const geo = new IrregularHeightmapGeometry(
            new Float64Array(Vector3.arrToArr(newPositions)),
            new Uint32Array(trianglesIndices),
        )

        result.set(tileId, geo);

        yield Yield.Asap;
    }
    
    return result;
}




class Triangle {
    readonly v0 = new Vector3();
    readonly v1 = new Vector3();
    readonly v2 = new Vector3();

    readonly points = [this.v0, this.v1, this.v2];
    readonly indices = [0, 0, 0];

    readonly aabb2 = Aabb2.empty();
    readonly aabb = Aabb.empty();


    setFromIndices(inds: ArrayLike<number>, positionsFlat3d: ArrayLike<number>, indsOffset: number) {

        this.indices[0] = inds[indsOffset + 0];
        this.indices[1] = inds[indsOffset + 1];
        this.indices[2] = inds[indsOffset + 2];

        this.v0.setFromArray(positionsFlat3d, inds[indsOffset + 0] * 3);
        this.v1.setFromArray(positionsFlat3d, inds[indsOffset + 1] * 3);
        this.v2.setFromArray(positionsFlat3d, inds[indsOffset + 2] * 3);

        this.aabb2.setFromPoints([this.v0, this.v1, this.v2]);

        this.aabb.setFromPoints([this.v0, this.v1, this.v2]);
    }


    intersectsAabb2(aabb2: Aabb2) {
        for (const p of this.points) {
            if (aabb2.containsPoint(p)) {
                return true;
            }
        }
        for (const p of aabb2.cornerPoints()) {
            if (this.containsPoint2d(p)) {
                return true;
            }
        }
        return false;
    }

    getBarycoord( point: Vector3, target: Vector3): Vector3 {
        return Triangle.getBarycoord(point, this.v0, this.v1, this.v2, target);
    }

    static getBarycoord = function () {

		const v0 = new Vector3();
		const v1 = new Vector3();
		const v2 = new Vector3();

		return function getBarycoord( point: Vector3, a: Vector3, b: Vector3, c: Vector3, target: Vector3 ): Vector3 {

			v0.subVectors( c, a );
			v1.subVectors( b, a );
			v2.subVectors( point, a );

			const dot00 = v0.dot( v0 );
			const dot01 = v0.dot( v1 );
			const dot02 = v0.dot( v2 );
			const dot11 = v1.dot( v1 );
			const dot12 = v1.dot( v2 );

			const denom = ( dot00 * dot11 - dot01 * dot01 );

			// collinear or singular triangle
			if ( denom === 0 ) {

				// arbitrary location outside of triangle?
				// not sure if this is the best idea, maybe should be returning undefined
				return target.set( - 2, - 1, - 1 );

			}

			const invDenom = 1 / denom;
			const u = ( dot11 * dot02 - dot01 * dot12 ) * invDenom;
			const v = ( dot00 * dot12 - dot01 * dot02 ) * invDenom;

			// barycentric coordinates must always sum to 1
			return target.set( 1 - u - v, v, u );
		};

	}();


    static containsPoint( point: Vector3, a: Vector3, b: Vector3, c: Vector3) {
        Triangle.getBarycoord( point, a, b, c, _v1 );
        return ( _v1.x >= 0 ) && ( _v1.y >= 0 ) && ( ( _v1.x + _v1.y ) <= 1 );

    };

    containsPoint(point: Vector3) {
        Triangle.getBarycoord( point, this.v0, this.v1, this.v2, _v1 );
        return ( _v1.x >= 0 ) && ( _v1.y >= 0 ) && ( ( _v1.x + _v1.y ) <= 1 );
    }

    containsPoint2d(p: Vector2) {
        const s = (this.v0.x - this.v2.x) * (p.y - this.v2.y) - (this.v0.y - this.v2.y) * (p.x - this.v2.x);
        const t = (this.v1.x - this.v0.x) * (p.y - this.v0.y) - (this.v1.y - this.v0.y) * (p.x - this.v0.x);
        if ((s < 0) != (t < 0) && s != 0 && t != 0) {
            return false;
        }
        const d = (this.v2.x - this.v1.x) * (p.y - this.v1.y) - (this.v2.y - this.v1.y) * (p.x - this.v1.x);
        return d == 0 || (d < 0) == (s + t <= 0);
    }
}

const _v1 = new Vector3();