<script lang="ts">
    import { MDCTabBar } from "@material/tab-bar";
    import { onMount, createEventDispatcher } from "svelte";
    import type { Tab } from "./types";

    export let tabs: Tab[];
    export let firstIndexToActivate: number = 0;
    export let compact: boolean = false;

    let domElement: HTMLElement | undefined;
    let mdcTabBar: MDCTabBar | undefined;

    const dispatch = createEventDispatcher();
    function handleActivedTab(event: Event) {
        dispatch("change", {
            tab: tabs[(event as CustomEvent).detail.index],
        });
    }

    onMount(() => {
        if (domElement) {
            mdcTabBar = new MDCTabBar(domElement);
            mdcTabBar.focusOnActivate = false;
            mdcTabBar.activateTab(firstIndexToActivate);
            domElement.addEventListener(
                "MDCTabBar:activated",
                handleActivedTab
            );
        }
        return () => {
            if (domElement) {
                domElement.removeEventListener("keydown", handleActivedTab);
            }
        };
    });
</script>

<div class="mdc-tab-bar" role="tablist" bind:this={domElement}>
    <div class="mdc-tab-scroller">
        <div class="mdc-tab-scroller__scroll-area">
            <div class="mdc-tab-scroller__scroll-content">
                {#each tabs as tab, i}
                    <button
                        class="mdc-tab"
                        class:mdc-tab--active={!i}
                        class:mdc-tab--min-width={compact}
                        role="tab"
                        aria-selected="true"
                        tabindex="0"
                    >
                        <span class="mdc-tab__content">
                            <span class="mdc-tab__text-label">{tab.name}</span>
                            <span
                                class="mdc-tab-indicator"
                                class:mdc-tab-indicator--active={!i}
                            >
                                <span
                                    class="mdc-tab-indicator__content mdc-tab-indicator__content--underline"
                                />
                            </span>
                        </span>
                        <!-- <span class="mdc-tab__ripple" /> -->
                    </button>
                {/each}
            </div>
        </div>
    </div>
</div>

<style lang="scss">
    .mdc-tab-bar {
        border-bottom: 1px solid var(--color-divider);
        box-sizing: border-box;
    }

    .mdc-tab--min-width {
        min-width: auto;
        padding: 0;
        margin-right: 24px;
    }

    .mdc-tab {
        --mdc-theme-primary: var(--color-main-dark);
        --mdc-typography-button-letter-spacing: 0.14px;
    }

    .mdc-tab-indicator .mdc-tab-indicator__content--underline {
        border-top-width: 3px;
        border-color: var(--color-main-medium);
    }
</style>
