import { type ScopedLogger, type IStreamAccumulator, Deleted } from 'engine-utils-ts';
import type { Id } from './VerDataSyncerImpl';
import type { VerdataCollectionUpdates } from '.';


export interface VerdataObject<D> {
    id: Id;
    version: number;
    isDirty: boolean;
    data: D;
}

export class RuntimeObjectsVersionsProvider<D> {

    private _nextFreeVersions: Map<Id, number> = new Map(); // it is very important to not reuse old versions for the same ids, if objects are different
    
    private _insertedByVerdataVersions: Map<Id, number> = new Map();

    private _updates: IStreamAccumulator<VerdataCollectionUpdates<Id>>;
    private _dirtyIds: Set<Id> = new Set();

    constructor(
        readonly ident: string,
        updates: IStreamAccumulator<VerdataCollectionUpdates<Id>>,
    ) {
        this._updates = updates;
    }

    version() {
        return this._updates.version();
    }

    dispose() {
        this._nextFreeVersions.clear();
        this._updates.dispose();
        this._dirtyIds.clear();
    }

    handleUpdates(optionalKnownInsertedByVerdataVersions: Map<Id, number> | undefined) {
        const updates = this._updates.consume();
        if (!updates) {
            return;
        }
        for (const update of updates) {
            if (update.update instanceof Deleted) {
                for (const id of update.update.ids) {
                    this._dirtyIds.delete(id);
                    this._insertedByVerdataVersions.delete(id);
                }
                continue;
            }
            if (update.isCausedByVerdataApply) {
                if (!optionalKnownInsertedByVerdataVersions) {
                    throw new Error('unexpectedly no known inserted by verdata versions from verdata apply event');
                }
                for (const id of update.update.ids) {
                    const knownVersion = optionalKnownInsertedByVerdataVersions.get(id);
                    if (knownVersion !== undefined) {
                        this._insertedByVerdataVersions.set(id, knownVersion);
                        this._dirtyIds.delete(id);

                        const nextFree = this._nextFreeVersions.get(id);
                        if (nextFree === undefined || nextFree <= knownVersion) {
                            this._nextFreeVersions.set(id, knownVersion + 1);
                        }
                    } else {
                        this._dirtyIds.add(id);
                    }
                }

            } else {
                for (const id of update.update.ids) {
                    this._dirtyIds.add(id);
                }
            }
        }
    }

    markVersionOccupied(id: Id, version: number): void {
        const nextFree = this._nextFreeVersions.get(id);
        if (nextFree === undefined || nextFree <= version) {
            this._nextFreeVersions.set(id, version + 1);
        }
    }

    getVerdataVersionForEachObject(logger: ScopedLogger, objects: [Id, D][]): VerdataObject<D>[] {
        this.handleUpdates(undefined);
        const result: VerdataObject<D>[] = [];
        for (const [id, dataToFindVersionFor] of objects) {

            let objectVerdataVersion: number;

            const isDirty = this._dirtyIds.has(id);
            if (isDirty) {
                objectVerdataVersion = this._nextFreeVersions.get(id) ?? 1;
            } else {
                let insertedVersion = this._insertedByVerdataVersions.get(id);
                if (insertedVersion === undefined) {
                    logger.batchedError('unexpectedly not inserted and not dirty object', id);
                    insertedVersion = 1;
                }
                objectVerdataVersion = insertedVersion;
            }
            if (!(objectVerdataVersion > 0)) {
                logger.batchedError('unexpectedly object version is not positive', objectVerdataVersion);
                objectVerdataVersion = 1;
            }
            this.markVersionOccupied(id, objectVerdataVersion);
            result.push({id, version: objectVerdataVersion, isDirty, data: dataToFindVersionFor});
        }
        return result;
    }
}


