// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

export class IdentifierVersion {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):IdentifierVersion {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsIdentifierVersion(bb:flatbuffers.ByteBuffer, obj?:IdentifierVersion):IdentifierVersion {
  return (obj || new IdentifierVersion()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsIdentifierVersion(bb:flatbuffers.ByteBuffer, obj?:IdentifierVersion):IdentifierVersion {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new IdentifierVersion()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

identifier():string|null
identifier(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
identifier(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

version():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

static startIdentifierVersion(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addIdentifier(builder:flatbuffers.Builder, identifierOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, identifierOffset, 0);
}

static addVersion(builder:flatbuffers.Builder, version:number) {
  builder.addFieldInt32(1, version, 0);
}

static endIdentifierVersion(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createIdentifierVersion(builder:flatbuffers.Builder, identifierOffset:flatbuffers.Offset, version:number):flatbuffers.Offset {
  IdentifierVersion.startIdentifierVersion(builder);
  IdentifierVersion.addIdentifier(builder, identifierOffset);
  IdentifierVersion.addVersion(builder, version);
  return IdentifierVersion.endIdentifierVersion(builder);
}
}
