import { Vector2 } from 'math-ts';
import type { ShaderMaterial } from '../3rdParty/three';
import type { ShaderBase } from './ShaderBase';

export const DepthLimitedBlurShader: ShaderBase = {
	name: 'DepthLimitBlur',
	defines: {
		'KERNEL_RADIUS': 3,
		'PERSPECTIVE_CAMERA': 1
	},
	uniforms: {
		'tDiffuse': { value: null },
		'sampleUvOffsets': { value: [ new Vector2( 0, 0 ) ] },
		'sampleWeights': { value: [ 1.0 ] },
		'depthCutoff': { value: 0.01 },
	},
	vertexShader:
	`
		#include <common>

		uniform sampler2D tDiffuse;

		varying vec2 vUv;
		varying vec2 vInvSize;

		void main() {
			vUv = uv;
			ivec2 size = textureSize(tDiffuse, 0);
			vInvSize = vec2(1.0 / float(size.x), 1.0 / float(size.y));

			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
		}
	`,

	fragmentShader:
	`
		#define EDGE_SHARPNESS     (5.0)
		
		#include <common>
		#include <packing>

		uniform sampler2D tDiffuse;

		uniform float depthCutoff;

		uniform vec2 sampleUvOffsets[ KERNEL_RADIUS + 1 ];
		uniform float sampleWeights[ KERNEL_RADIUS + 1 ];

		varying vec2 vUv;
		varying vec2 vInvSize;

		float unpackKey(vec2 p) {
			return p.x * (256.0 / 257.0) + p.y * (1.0 / 257.0);
		}

		void main() {
			vec3 sampled = texture2D( tDiffuse, vUv ).rgb;
			gl_FragColor.gb = sampled.gb;
			float depth = unpackKey(sampled.gb);
			if( depth >= ( 1.0 - EPSILON ) ) {
				discard;
			}

			float centerViewZ = depth;

			float weightSum = sampleWeights[0];
			float diffuseSum = sampled.r * weightSum;

			for( int i = 1; i <= KERNEL_RADIUS; i ++ ) {

				float sampleWeight = sampleWeights[i];
				vec2 sampleUvOffset = sampleUvOffsets[i] * vInvSize;

				vec2 sampleUv = vUv + sampleUvOffset;
				sampled = texture2D( tDiffuse, sampleUv).rgb;
				float viewZ = unpackKey( sampled.gb );

				float dz = viewZ - centerViewZ;
				sampleWeight *= max(0.0, 1.0 - (EDGE_SHARPNESS * 500.0) * abs(dz));

				diffuseSum += sampled.r * sampleWeight;
				weightSum += sampleWeight;
				
				sampleUv = vUv - sampleUvOffset;
				sampled = texture2D( tDiffuse, sampleUv).rgb;
				viewZ = unpackKey( sampled.gb );
	
				dz = viewZ - centerViewZ;
				diffuseSum += sampled.r * sampleWeight;
				weightSum += sampleWeight;

			}

			gl_FragColor.r = diffuseSum / (weightSum + 0.00001);
		}
	`
};

export const BlurShaderUtils = {

	createSampleWeights: function ( kernelRadius: number, stdDev: number ) {

		var gaussian = function ( x: number, stdDev: number ) {

			return Math.exp( - ( x * x ) / ( 2.0 * ( stdDev * stdDev ) ) ) / ( Math.sqrt( 2.0 * Math.PI ) * stdDev );

		};

		var weights = [];

		for ( var i = 0; i <= kernelRadius; i ++ ) {

			weights.push( gaussian( i, stdDev ) );

		}

		return weights;

	},

	createSampleOffsets: function ( kernelRadius: number, uvIncrement: Vector2 ) {

		var offsets = [];
		for ( var i = 0; i <= kernelRadius; i ++ ) {
			offsets.push( uvIncrement.clone().multiplyScalar( i ) );
		}
		return offsets;

	},

	configure: function ( material: ShaderMaterial, kernelRadius: number, stdDev: number, uvIncrement: Vector2 ) {

		material.defines[ 'KERNEL_RADIUS' ] = kernelRadius;
		material.uniforms[ 'sampleUvOffsets' ].value = BlurShaderUtils.createSampleOffsets( kernelRadius, uvIncrement );
		material.uniforms[ 'sampleWeights' ].value = BlurShaderUtils.createSampleWeights( kernelRadius, stdDev );
		material.needsUpdate = true;

	}

};
