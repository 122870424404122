<script lang="ts">
    import type { KreoEngine } from "engine-ts";
    import { VersionedStore } from "./VersionedStore";
    import { UnitsMapper } from "bim-ts";
    import { getContext } from "svelte";

    export let engine: KreoEngine;

    const unitsMapper = getContext<VersionedStore<UnitsMapper>>("unitsMapper");
    const settings = new VersionedStore(engine.graphicsSettings);

    $: size = $unitsMapper.mapToConfigured({
        value: $settings.floorGridCellSize.x,
        unit: "m",
    });
    $: sizeUnit =
        size.value < 1000
            ? size.unit!
            : $unitsMapper.isImperial()
              ? "mi"
              : "km";
    $: sizeValue =
        size.value < 1000 ? size.value : UnitsMapper.mapTo(size, sizeUnit);
</script>

<div class="grid-label">
    <svg width="12" height="12" fill="none">
        <rect
            x="3.33325"
            y="3.33301"
            width="5.33333"
            height="5.33333"
            stroke="white"
            stroke-width="1.5"
        />
        <path d="M0 3.33333H3.33333V0" stroke="white" stroke-width="1.5" />
        <path d="M12 3.33333H8.66667V0" stroke="white" stroke-width="1.5" />
        <path d="M0 8.66667H3.33333V12" stroke="white" stroke-width="1.5" />
        <path d="M12 8.66667H8.66667V12" stroke="white" stroke-width="1.5" />
    </svg>

    <span class="mdc-typography--body2-strong size-text">
        {sizeValue}
        {sizeUnit}
    </span>
</div>

<style>
    .grid-label {
        position: absolute;
        bottom: 18px;
        right: 145px;
        width: 56px;
        box-sizing: border-box;
        border-radius: 14px;
        background: #a6a8a9;
        color: white;
        display: flex;
        align-items: center;
        padding: 2px 2px 2px 6px;
    }
    .size-text {
        flex: 1;
        text-align: center;
    }
</style>
