
let _isInWebworker: boolean | undefined = undefined;

declare class WorkerGlobalScope {};

export function IsInWebworker() {
    if (_isInWebworker === undefined) {
        _isInWebworker = typeof (WorkerGlobalScope as any) !== 'undefined' && self instanceof WorkerGlobalScope;
    }
    return _isInWebworker;
}

export function isInNode() {
    //@ts-ignore
    return Boolean(globalThis.process?.release?.name);
}
