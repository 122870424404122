<script lang="ts">
    //@ts-ignore
    import Svelecte from "svelecte";
    import { onMount } from "svelte";
    import { IconComponent } from "../icon";
    import MultiSelectItem from "./MultiSelectItem.svelte";
    import NewSelectorPropertyButtons from "./ActionButtons.svelte";
    import type {
        SelectedValue,
        Options,
        OptionsGroup,
        SelectOption,
    } from "./types";
    import { ButtonComponent, IconButton } from "../button";

    export let value: SelectedValue[];
    export let options: Options;
    export let disabled: boolean = false;
    export let actionButtons: boolean = false;
    export let placeholder: string;
    export let maxSelect: number = 0;

    const overrideI18n = {
        empty: "No items",
        nomatch: "No matches found",
    };

    let hasDropdownRendered = false;
    let setSelection: (values: SelectedValue[], trigger: boolean) => void;
    let selectElement: HTMLDivElement;
    export let selectControl: HTMLDivElement | null;

    onMount(() => {
        selectControl = selectElement.querySelector("#multiSelectId");
        selectControl?.setAttribute("autocomplete", "off");
    });

    function renderActionButtons() {
        if (!hasDropdownRendered) {
            new NewSelectorPropertyButtons({
                props: {
                    onSelect: () => {
                        const allOptions = options.some((o) => "options" in o)
                            ? options.flatMap(
                                  (group) => (group as OptionsGroup).options
                              )
                            : <SelectOption[]>options;
                        setSelection(
                            allOptions
                                .filter((o) => !o.disabled)
                                .map((o) => o.value),
                            true
                        );
                        selectControl?.blur();
                    },
                    onDeselect: () => {
                        setSelection([], true);
                        selectControl?.blur();
                    },
                },
                target: selectElement.querySelector(".sv-dropdown")!,
            });
            hasDropdownRendered = true;
        }
    }
</script>

<div
    class="custom-multiselect mdc-typography--body1 custom-scrollbar-light"
    class:empty-select={!value?.length}
    bind:this={selectElement}
    on:click={!disabled && actionButtons ? renderActionButtons : null}
>
    <Svelecte
        {options}
        class="svelecte-control custom-svelecte-control"
        inputId="multiSelectId"
        bind:value
        {placeholder}
        {disabled}
        disabledField="disabled"
        labelField="text"
        valueField="value"
        searchField="text"
        clearable={!disabled}
        multiple={true}
        max={maxSelect}
        disableHighlight={true}
        controlItem={MultiSelectItem}
        dropdownItem={MultiSelectItem}
        bind:setSelection
        on:change
        i18n={overrideI18n}
    >
        <div slot="control-end" class:lock-icon={disabled}>
            {#if disabled}
                <IconComponent name="Lock" />
            {/if}
        </div>
        <svelte:fragment slot="clear-icon" />
        <svelte:fragment slot="indicator-icon" let:hasDropdownOpened>
            {#if !disabled}
                <ButtonComponent
                    desc={new IconButton("Dropdown", () => {
                        if (!hasDropdownOpened) {
                            selectControl?.blur();
                        }
                    })}
                />
            {/if}
        </svelte:fragment>
    </Svelecte>
</div>

<style lang="scss" global>
    .custom-multiselect {
        .custom-svelecte-control {
            .sv-content {
                gap: 2px;
                padding: 3px 0 3px 4px;
                &.sv-input-row {
                    flex-direction: column;
                    align-items: stretch;
                    flex-wrap: nowrap;
                }
            }
            .indicator-container.close-icon {
                display: none;
            }
            .sv-control .indicator-separator {
                display: block;
            }
        }

        &.empty-select .sv-control:not(.is-active) .indicator-separator {
            display: none;
        }
        .sv-control.is-active .sv-content {
            --sv-item-selected-bg: var(--color-white);
        }
        .sv-control.is-disabled .sv-item-btn {
            display: none;
        }
        .svelecte-control .inputBox:first-child {
            padding-left: 4px;
        }
        .sv-control:not(.is-active) .inputBox:not(:first-child) {
            height: 0;
        }
    }
</style>
