<script lang="ts">
	import type { Bim} from "bim-ts";
	import { SceneObjDiff } from "bim-ts";
  import { getContext, setContext } from "svelte";

  import { SceneExplorerObjectsList } from './SceneExplorer'
  import type { DataModel } from './SceneExplorer'
  import ActionPanel from './ActionPanel.svelte'
  import List from './List.svelte'
  import { VersionedStore } from '../VersionedStore';
  import { ButtonComponent, IconButton } from '../libui/button';
  import EngineUiPanel from '../ui-panels/EngineUiPanel.svelte';

	export const header: string = 'Model Explorer';
	export const status: string = '';
  let listComp: List

	let bim: Bim = getContext('bim');

  let sceneExplorerList = new SceneExplorerObjectsList({
    bim,
    relevantObjectsDiff: SceneObjDiff.Hidden | SceneObjDiff.Selected
    | SceneObjDiff.SpatialParentRef | SceneObjDiff.SpatialChildrenList
    | SceneObjDiff.ElectricalParentRef | SceneObjDiff.ElectricalChildrenList
    | SceneObjDiff.HierarchySortKey,
  })

  $: objectsList = new VersionedStore<DataModel>(sceneExplorerList);
  $: setContext('sceneManager', objectsList)

</script>

<EngineUiPanel showPositionMenu={true}>
<div
    class="container"
    on:dragover|stopPropagation|preventDefault={() => null}
    on:drag|stopPropagation|preventDefault={() => null}
>
  <ActionPanel sceneExplorerList={sceneExplorerList}  />
  <div class="action-panel-2">
    <ButtonComponent desc={new IconButton("UnfoldLess", () => {sceneExplorerList.closeAllNodes()})} />
  </div>
  <List
    bind:this={listComp}
    sceneExplorerList={sceneExplorerList}
    sceneExplorerData={$objectsList}
  />
</div>
</EngineUiPanel>


<style lang="scss">
    .action-panel-2 {
        display: flex;
        align-self: flex-start;
        margin-left: 5px;
        margin-top: 2px;
        margin-bottom: 2px;
    }
  .container {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 3px;
    bottom: 0;
    top: 0;
  }
</style>
