// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class AssetRef implements flatbuffers.IUnpackableObject<AssetRefT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):AssetRef {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsAssetRef(bb:flatbuffers.ByteBuffer, obj?:AssetRef):AssetRef {
  return (obj || new AssetRef()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsAssetRef(bb:flatbuffers.ByteBuffer, obj?:AssetRef):AssetRef {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new AssetRef()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

assetPath():string|null
assetPath(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
assetPath(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

inAssetId():string|null
inAssetId(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
inAssetId(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startAssetRef(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addAssetPath(builder:flatbuffers.Builder, assetPathOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, assetPathOffset, 0);
}

static addInAssetId(builder:flatbuffers.Builder, inAssetIdOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, inAssetIdOffset, 0);
}

static endAssetRef(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createAssetRef(builder:flatbuffers.Builder, assetPathOffset:flatbuffers.Offset, inAssetIdOffset:flatbuffers.Offset):flatbuffers.Offset {
  AssetRef.startAssetRef(builder);
  AssetRef.addAssetPath(builder, assetPathOffset);
  AssetRef.addInAssetId(builder, inAssetIdOffset);
  return AssetRef.endAssetRef(builder);
}

unpack(): AssetRefT {
  return new AssetRefT(
    this.assetPath(),
    this.inAssetId()
  );
}


unpackTo(_o: AssetRefT): void {
  _o.assetPath = this.assetPath();
  _o.inAssetId = this.inAssetId();
}
}

export class AssetRefT implements flatbuffers.IGeneratedObject {
constructor(
  public assetPath: string|Uint8Array|null = null,
  public inAssetId: string|Uint8Array|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const assetPath = (this.assetPath !== null ? builder.createString(this.assetPath!) : 0);
  const inAssetId = (this.inAssetId !== null ? builder.createString(this.inAssetId!) : 0);

  return AssetRef.createAssetRef(builder,
    assetPath,
    inAssetId
  );
}
}
