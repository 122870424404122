import type { ScopedLogger } from "engine-utils-ts";
import { LogLevel } from "engine-utils-ts";
import type { PropertyGroup } from "../properties/PropertyGroup";
import type { PropsGroupBase } from "../properties/Props";
import { PropsGroupsRegistry } from '../properties/PropsGroupsRegistry';
import type { IdBimScene, SceneInstance } from "../scene/SceneInstances";
import { Config } from "./ConfigsCollection";
import type { PropertyPathType } from "./ConfigUtils";
import { ConfigUtils } from "./ConfigUtils";

export interface ConfigArchetypeContext {
    instances: ReadonlyMap<IdBimScene, SceneInstance>;
}
export interface ConfigArchetype {
    type_identifier: string;
    properties: (context: ConfigArchetypeContext) => PropertyGroup;
    connectionToType?:string;
    stateType: StateType;
    shapeMigrations?: ConfigShapeMigration[];
    propsClass?: { new(args: any): PropsGroupBase };
}

export class ConfigArchetypeImpl {

    properties: (context: ConfigArchetypeContext) => PropertyGroup;
    connectionToType?:string;
    stateType: StateType;
    shapeMigrations: ConfigShapeMigration[];

    constructor(a: ConfigArchetype) {
        this.properties = a.properties;
        this.connectionToType = a.connectionToType;
        this.stateType = a.stateType;

        this.shapeMigrations =
            a.shapeMigrations
                ?.slice()
                .sort((a, b) => a.toVersion - b.toVersion) ?? [];
    }

    getVersion(): number {
        let version = DefaultConfigShapeVersion.None;
        for (const migration of this.shapeMigrations) {
            version = Math.max(version, migration.toVersion);
        }
        return version;
    }
}

export const enum DefaultConfigShapeVersion {
	None = 0,
}

export interface ConfigShapeMigration {
    toVersion: number;
    patch: (config: Config) => void;
    validation: ConfigValidation;
}

export type PropertyValidation = {
    path: PropertyPathType[],
}

interface ConfigValidation {
    updatedProps: PropertyValidation[];
    deletedProps: PropertyValidation[];
}

export enum StateType{
    None = 0,
    OptionalSingleton,
    Singleton,
    CreateByConnection,
}

export class ConfigsArchetypes {
    readonly logger: ScopedLogger;
    readonly perTypeIdent = new Map<string, ConfigArchetypeImpl>();
    readonly propsClassPerTypeIdent = new Map<string, { new(args: any): PropsGroupBase }>();

    constructor(logger: ScopedLogger, private readonly instances: ReadonlyMap<IdBimScene, SceneInstance>) {
        this.logger = logger.newScope("configs-archetypes", LogLevel.Info);
    }

    getPropsClassFor(type_ident: string): { new(args: any): any } | undefined {
        return this.propsClassPerTypeIdent.get(type_ident);
    }

    registerArchetype(arch: ConfigArchetype) {
        if (this.perTypeIdent.has(arch.type_identifier)) {
            this.logger.error(
                `${arch.type_identifier} archetype already registered, overriding`
            );
        }
        if(arch.shapeMigrations){
            const versions =  new Set<number>();
            for (const migration of arch.shapeMigrations) {
                versions.add(migration.toVersion);
            }
            if(versions.size !== arch.shapeMigrations.length){
                throw new Error(`${arch.type_identifier} archetype has duplicate migrations`);
            }
        }
        if (arch.propsClass) {
            this.propsClassPerTypeIdent.set(arch.type_identifier, arch.propsClass);
        }
        this.perTypeIdent.set(arch.type_identifier, new ConfigArchetypeImpl(arch));
    }

    newDefaultInstanceForArchetype(
        type_identifier: string,
        connectedTo?: IdBimScene
    ): Config {
        const config = new Config(type_identifier, connectedTo);
        const archetype = this.perTypeIdent.get(type_identifier);
        if (!archetype) {
            this.logger.error("unrecognized type identifier", type_identifier);
        } else {
            const context: ConfigArchetypeContext = { instances: this.instances}
            config.properties = archetype.properties(context);
        }
        const newPropsClassExpected = this.getPropsClassFor(type_identifier);
        if (newPropsClassExpected) {
            config.props = new newPropsClassExpected({});
        }
        return config;
    }

    getVersionPerTypeIdentifier():[string, number][] {
        const versionPerTypeIdentifier:[string, number][] = [];
        for (const [ident, arch] of this.perTypeIdent) {
            const version = arch.getVersion();
            if (version !== DefaultConfigShapeVersion.None) {
                versionPerTypeIdentifier.push([ident, version]);
            }
        }

        return Array.from(versionPerTypeIdentifier);
    }

    configStateType(typeIdentifier: string): StateType | undefined {
        const archetype = this.perTypeIdent.get(typeIdentifier);
        return archetype?.stateType;
    }

    isConnected(type: string) {
        const archetype = this.perTypeIdent.get(type);
        return archetype?.stateType === StateType.CreateByConnection;
    }

    getConnectionInstanceTypes() {
        const allTypes = new Map<string, Set<string>>();

        for (const [archType, arch] of this.perTypeIdent) {
            if (
                arch.connectionToType &&
                arch.stateType === StateType.CreateByConnection
            ) {
                const connectionType = allTypes.get(arch.connectionToType);
                if (connectionType) {
                    connectionType.add(archType);
                } else {
                    allTypes.set(arch.connectionToType, new Set([archType]));
                }
            }
        }
        return allTypes;
    }

    migrate(config: Config, version: number): void {
        const archetype = this.perTypeIdent.get(config.type_identifier);
        if (!archetype?.shapeMigrations.length) {
            return;
        }

        let updatedVersion = DefaultConfigShapeVersion.None;
        const propertiesShouldBe = new Map<string, PropertyValidation>();
        const propertiesShouldNotBe = new Map<string, PropertyValidation>();

        for (const migration of archetype.shapeMigrations) {
            const currentMigrationProps = new Set<string>();
            for (const prop of migration.validation.updatedProps) {
                const mergedPath = prop.path.join(' ');
                currentMigrationProps.add(mergedPath);
                propertiesShouldBe.set(mergedPath, prop);
                propertiesShouldNotBe.delete(mergedPath);
            }
            for (const prop of migration.validation.deletedProps) {
                const mergedPath = prop.path.join(' ');
                if(currentMigrationProps.has(mergedPath)){
                    this.logger.error('There are duplicate paths in migration', migration);
                }
                propertiesShouldNotBe.set(mergedPath, prop);
                for (const path of propertiesShouldBe.keys()) {
                    if(path.startsWith(mergedPath)){
                        propertiesShouldBe.delete(path);
                    }
                }
            }
            if (version >= migration.toVersion) {
                continue;
            }
            try {
                migration.patch(config);
            } catch (e) {
                this.logger.error(`migration of ${config.type_identifier} failed`, [migration.toVersion, e]);
            }
            updatedVersion = migration.toVersion;
        }
        this.logger.debug('migration '+ config.type_identifier + ' from: '+ version, [updatedVersion, config]);
        this.logger.debug('deleted', Array.from(propertiesShouldNotBe), 'updated', Array.from(propertiesShouldBe));
        for (const [_, check] of propertiesShouldNotBe) {
            const props = ConfigUtils.extractProperties(config.properties, check.path);
            // this.logger.debug('propertiesShouldNotBe', [config.type_identifier, check.path, props]);
            if(props.length > 0){
                this.logger.error("Property should been removed with path", [config.type_identifier, check.path, props, config.properties]);
            }
        }
        for (const [_, check] of propertiesShouldBe) {
            const props = ConfigUtils.extractProperties(config.properties, check.path);
            // this.logger.debug('propertiesShouldBe', [config.type_identifier, check.path, props]);
            if(props.length === 0){
                this.logger.error("Property not found with path", [config.type_identifier, check.path]);
                continue;
            }
        }

        const newPropsClassExpected = this.getPropsClassFor(config.type_identifier);
        if (newPropsClassExpected) {
            if (!(config.props instanceof newPropsClassExpected)) {
                this.logger.batchedError("Props class mismatch", [config.props, newPropsClassExpected.name]);
                config.props = PropsGroupsRegistry.newGroupInstanceChecked(newPropsClassExpected, config.props ?? {});
            }
        }
    }
}