<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M3 14C2.73333 14 2.5 13.9 2.3 13.7C2.1 13.5 2 13.2667 2 13V3C2 2.73333 2.1 2.5 2.3 2.3C2.5 2.1 2.73333 2 3 2H7.85V3H3V13H7.85V14H3ZM11.1 10.9167L10.3833 10.2L12.0833 8.5H6.25V7.5H12.05L10.35 5.8L11.0667 5.08333L14 8.01667L11.1 10.9167Z"
        fill="currentColor"
    />
</svg>
