<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M3.64785 13.7356C3.2647 13.7356 2.93836 13.6008 2.66881 13.3312C2.39927 13.0617 2.2645 12.7354 2.2645 12.3522V3.64788C2.2645 3.26473 2.39927 2.93839 2.66881 2.66884C2.93836 2.3993 3.2647 2.26453 3.64785 2.26453H7.00003V3.64788H3.64785V12.3522H12.3522V9.00006H13.7355V12.3522C13.7355 12.7354 13.6008 13.0617 13.3312 13.3312C13.0617 13.6008 12.7353 13.7356 12.3522 13.7356H3.64785ZM6.57356 10.4L5.60001 9.42649L11.3786 3.64788H9.00003V2.26453H13.7355V7.00006H12.3522V4.62143L6.57356 10.4Z"
        fill="currentColor"
    />
</svg>
