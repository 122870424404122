// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { PropertyT } from './property.js';
import { Property } from './property.js';


export class PropertyArray implements flatbuffers.IUnpackableObject<PropertyArrayT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):PropertyArray {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsPropertyArray(bb:flatbuffers.ByteBuffer, obj?:PropertyArray):PropertyArray {
  return (obj || new PropertyArray()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsPropertyArray(bb:flatbuffers.ByteBuffer, obj?:PropertyArray):PropertyArray {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new PropertyArray()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

properties(index: number, obj?:Property):Property|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new Property()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

propertiesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startPropertyArray(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addProperties(builder:flatbuffers.Builder, propertiesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, propertiesOffset, 0);
}

static createPropertiesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startPropertiesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endPropertyArray(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createPropertyArray(builder:flatbuffers.Builder, propertiesOffset:flatbuffers.Offset):flatbuffers.Offset {
  PropertyArray.startPropertyArray(builder);
  PropertyArray.addProperties(builder, propertiesOffset);
  return PropertyArray.endPropertyArray(builder);
}

unpack(): PropertyArrayT {
  return new PropertyArrayT(
    this.bb!.createObjList<Property, PropertyT>(this.properties.bind(this), this.propertiesLength())
  );
}


unpackTo(_o: PropertyArrayT): void {
  _o.properties = this.bb!.createObjList<Property, PropertyT>(this.properties.bind(this), this.propertiesLength());
}
}

export class PropertyArrayT implements flatbuffers.IGeneratedObject {
constructor(
  public properties: (PropertyT)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const properties = PropertyArray.createPropertiesVector(builder, builder.createObjectOffsetList(this.properties));

  return PropertyArray.createPropertyArray(builder,
    properties
  );
}
}
