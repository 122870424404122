<script lang="ts">
    import { StringUtils } from "engine-utils-ts";
    import SelectorPropertyBase from "../../SelectorPropertyBase.svelte";

    export let selected: string | undefined;
    export let values: string[] = [];
    export let readonly: boolean = false;
    export let onChange: (option: string) => void;

    $: value = selected !== undefined ? [selected] : [];
    $: options = values.map((v) => ({
        value: v,
        label: StringUtils.capitalizeFirstLatterInWord(v),
    }));
</script>

<div class="select">
    <SelectorPropertyBase
        {value}
        {options}
        placeholder="Select option"
        {readonly}
        searchable={false}
        on:change={(e) => {
            const value = e.detail && e.detail.value;
            if (value) {
                onChange(value);
            }
        }}
    />
</div>

<style lang="scss">
    .select {
        width: 108px;
        min-width: 108px;
        :global(.property-value) {
            width: 100% !important;
        }
    }
</style>
