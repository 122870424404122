import type { LazyVersioned, ScopedLogger } from "engine-utils-ts";
import { DefaultMap, LazyDerived } from "engine-utils-ts";
import { KrMath } from "math-ts";
import type { GpuResources } from "../composer/GpuResources";
import type { GraphicsSettings } from "../GraphicsSettings";
import type { FrustumExt } from "../structs/FrustumExt";
import { PassRenderPerformance } from "./PassRenderPerformance";
import { ProgressivePass } from "./ProgressivePass";
import type { RenderPass, RenderResult } from "./RenderPass";
import type { RenderTargets, RenderTargetIdent, RTIdent } from "./RenderTargets";
import type { RenderTimeBudget } from "./RenderTimeBudget";
import type { WebglTimer } from "./WebglTimer";

export interface WebglTimerDescr {
    GPU_DISJOINT_EXT: number,
    QUERY_COUNTER_BITS_EXT: number,
    TIMESTAMP_EXT: number,
    TIME_ELAPSED_EXT: number,
}


export interface RenderBlockResult {
    finished: boolean;
    renderedAnything: boolean;
}

export class ComposerRenderBlock {
    logger: ScopedLogger;

    frustum: FrustumExt;

    renderTargets: RenderTargets;
    performanceInfo = new DefaultMap<RenderPass, PassRenderPerformance>(() => new PassRenderPerformance());

    graphicsSettings: LazyVersioned<GraphicsSettings>;

    passes: RenderPass[];
    _passStates: DefaultMap<RenderPass, PassRenderState> = new DefaultMap(() => new PassRenderState());
    _fromFrustumInvalidtor: LazyDerived<void>;

    constructor(
        ident: string,
        logger: ScopedLogger,
        renderTargets: RenderTargets,
        renderSettings: LazyVersioned<GraphicsSettings>,
        passes: RenderPass[],
        frustum: FrustumExt,
    ) {
        this.logger = logger.newScope(ident);
        this.renderTargets = renderTargets;
        this.passes = passes;
        this.graphicsSettings = renderSettings;
        this.frustum = frustum;


        this._fromFrustumInvalidtor = LazyDerived.new0('invalidate from frustum', [this.frustum], () => {
            this._invalidateAll();
        });
        this._invalidateAll();
    }


    _rtsVersionsSum(rts: RTIdent[]): number {
        let sum = 0;
		for (const rt of rts) {
			const v  = this.renderTargets.getVersionOf(rt.ident);
			sum += v;
		}
		return sum;
    }

    _inputsOnlyVersionsSum(pass: RenderPass): number {
		let sum = 0;
		for (const rt of pass.inputs) {
            if (pass.outputs.includes(rt)) {
                continue;
            }
			const v  = this.renderTargets.getVersionOf(rt.ident);
			sum += v;
		}
		return sum;
	}

    _invalidateAll() {
        for (const [p, ps] of this._passStates) {
            ps.version = 0;
			ps.finished = false;

            // HACK
            // some code intializing different rendertargets with shared textures/depth buffers is broken
            // first render of std pipeline always goes to shit (transparent black screen)
            // initialize everything beforehand to make sure this doesn't happen
            this.renderTargets.resetBinding();
            for (const rt of p.inputs) {
                this.renderTargets.bindByIdent(rt);
            }
            for (const rt of p.outputs) {
                this.renderTargets.bindByIdent(rt);
            }
            this.renderTargets.resetBinding();
        }
    }

    render(timeBudget: RenderTimeBudget, webglTimer: WebglTimer | null, gpuResources: GpuResources): RenderBlockResult {

        const frustum = this.frustum;
        this._fromFrustumInvalidtor.poll();

		const passesToRedraw = this._collectPassesToRedraw();

		if (passesToRedraw.length) {
			let minimumPostProcTimeToExpectByTheEndOfFrame: number = 0;
			for (const pass of passesToRedraw) {
				if (!(pass instanceof ProgressivePass)) {
					const perf = this.performanceInfo.getOrCreate(pass);
					const minRendererdUnits = perf.getMinRendererdUnitsOfLastKnown();
					if (perf.gpuRenderSpeed && minRendererdUnits) {
						const gpuMinTime = minRendererdUnits / perf.gpuRenderSpeed;
						minimumPostProcTimeToExpectByTheEndOfFrame += gpuMinTime;
					}
				}
			}
			const reducedTimeBudget = timeBudget._webglTimeBudgetLeft - minimumPostProcTimeToExpectByTheEndOfFrame;

			timeBudget._webglTimeBudgetLeft = KrMath.lerp(
				timeBudget._webglTimeBudgetLeft,
				Math.max(0, reducedTimeBudget),
				0.7
			);
		}

		const incompleteRTs = new Set<RenderTargetIdent>();

		if (passesToRedraw.length) {
			if (timeBudget.gpuTimeBudgetLeft() < 7 || timeBudget.jsTimeBudgetLeft() < 7) {
				// console.warn('low time budget', timeBudget.gpuTimeBudgetLeft(), timeBudget.jsTimeBudgetLeft());
			}
		}

        for (const pass of passesToRedraw) {
            if (!pass.enabled) {
                continue;
            }

            const perf = this.performanceInfo.getOrCreate(pass);
            perf.readQueriesThatAreDone(gpuResources.renderer.context, webglTimer);

            const passState =  this._passStates.getOrCreate(pass);

			if (pass instanceof ProgressivePass) {
				if (passState.inputsRtsVersionsSum !== this._rtsVersionsSum(pass.inputs)) {
					this.logger.debug('reset pass based on inputs versions', pass.identifier);
					pass.reset();
				}
			}

            const anyInputsIncomplete = pass.inputs.some(inp => incompleteRTs.has(inp.ident));

            if (!(pass instanceof ProgressivePass && anyInputsIncomplete)) {

                // console.log('render', pass.identifier);
                const startT = performance.now();

                let timerQuery: WebGLQuery | null = null;
                if (webglTimer) {
                    timerQuery = perf.startNewQuery(webglTimer, gpuResources.renderer.context);
                }
                let passRendResult: RenderResult | null = null;
                try {
                    gpuResources.renderer.resetRenderState();
                    this.renderTargets.resetBinding();

                    let timeBudgetForPass = perf.maxJobsToRenderInBudget(timeBudget);

                    for (let i = 0; i < 3; ++i) {
                        // multiple render attempts for progressive passes,
                        // in case culled list doesn't contain much of specific mesh types
                        // but we have much time

                        const renderedAttemptResult = pass.render(
                            frustum.camera,
                            gpuResources,
                            this.renderTargets,
                            timeBudgetForPass,
                            anyInputsIncomplete
                        );
                        if (!passRendResult) {
                            passRendResult = renderedAttemptResult;
                        } else {
                            passRendResult.unitsRenderered += renderedAttemptResult.unitsRenderered;
                            passRendResult.finished = renderedAttemptResult.finished;
                        }
                        if (renderedAttemptResult.finished || !(pass instanceof ProgressivePass)) {
                            break;
                        }
                        const newBudget = perf.maxJobsToRenderInBudget(timeBudget);
                        if (newBudget.unitsToRender <= timeBudgetForPass.unitsToRender / 2) {
                            break;
                        }
                        timeBudgetForPass = newBudget;
                    }
                    this.renderTargets.resetBinding();

                } finally {
                    if (timerQuery) {
                        perf.markQueryEnd(
                            webglTimer!,
                            gpuResources.renderer.context,
                            timerQuery,
                            passRendResult!?.unitsRenderered
                        );
                    }
                }
				
				if (passRendResult!.unitsRenderered > 0) {
					for (const output of pass.outputs) {
						this.renderTargets.invalidateRt(output.ident);
					}
				}

                passState.finished = passRendResult!.finished;
				passState.version = pass.version();
				passState.inputsStrictRtsVersionsSum = this._inputsOnlyVersionsSum(pass);
				passState.inputsRtsVersionsSum = this._rtsVersionsSum(pass.inputs);

                const duration = performance.now() - startT;
                perf.updateJsSpeed(duration, passRendResult!.unitsRenderered);
                timeBudget.decreaseGpuBudgetLeftBy(passRendResult!.unitsRenderered / perf.gpuRenderSpeed)
            }

            if (!passState.finished || anyInputsIncomplete) {
                for (const out of pass.outputs) {
                    incompleteRTs.add(out.ident);
                }
            }
        }

        return {
            finished: incompleteRTs.size === 0,
            renderedAnything: passesToRedraw.length > 0,
        }
    }

    
	_collectPassesToRedraw(): RenderPass[] {

		class PerRenderTargetPasses {
			producers: RenderPass[] = [];
			consumers: RenderPass[] = [];
		}
		const perRenderTargetPasses = new DefaultMap<RenderTargetIdent, PerRenderTargetPasses>(() => new PerRenderTargetPasses());
		for (const p of this.passes) {
			for (const inp of p.inputs) {
				perRenderTargetPasses.getOrCreate(inp.ident).consumers.push(p);
			}
			for (const out of p.outputs) {
				perRenderTargetPasses.getOrCreate(out.ident).producers.push(p);
			}
		}


		const passesToRelaunch = new Set<RenderPass>();
		const dirtyOutputsAfterRelaunch = new Set<RenderTargetIdent>();

		const addPassForRelaunch = (pass: RenderPass, addAllProducersOfTheSameOutputs: boolean) => {
			if (passesToRelaunch.has(pass)) {
				return;
			}
			passesToRelaunch.add(pass);
			for (const out of pass.outputs) {
				dirtyOutputsAfterRelaunch.add(out.ident);
			}
			for (const passOutp of pass.outputs) {
				const consumpersOfPassOutputs = perRenderTargetPasses.get(passOutp.ident)!.consumers;
				for (const consumerPass of consumpersOfPassOutputs) {
					addPassForRelaunch(consumerPass, true);
				}
				if (addAllProducersOfTheSameOutputs) {
					const producersOfPassOutputs = perRenderTargetPasses.get(passOutp.ident)!.producers;
					for (const prodPass of producersOfPassOutputs) {
						addPassForRelaunch(prodPass, true);
					}
				}

			}
		}

		for (const p of this.passes) {
			const state = this._passStates.getOrCreate(p);
			
			if (state.version != p.version()) {
				this.logger.debug(p.identifier, 'dirty: by version');
				addPassForRelaunch(p, true);
            
            } else if (this._inputsOnlyVersionsSum(p) !== state.inputsStrictRtsVersionsSum) {
				this.logger.debug(p.identifier, 'dirty: by inputs version sum');
				addPassForRelaunch(p, false);

            } else if (p.inputs.some(inp => dirtyOutputsAfterRelaunch.has(inp.ident))) {
				this.logger.debug(p.identifier, 'dirty: by input proxy');
				addPassForRelaunch(p, false);
			} else if (!state.finished) {
				this.logger.debug(p.identifier, 'dirty: is not finished');
				addPassForRelaunch(p, false);
			}
		}

		if (passesToRelaunch.size === 0) {
			return [];
		}

		const result = Array.from(passesToRelaunch).sort((p1, p2) => this.passes.indexOf(p1) - this.passes.indexOf(p2));
		const passesNotToRedraw = this.passes.filter(p => !result.includes(p));

		this.logger.debug('passes to relaunch', result);
		this.logger.debug('not redrawing passes', passesNotToRedraw);

		return result;
	}

    freeGLQueriesOnContextLoss (gl: WebGL2RenderingContext) {
        for(const [, value] of this.performanceInfo) {
            value.freeGLQueriesOnContextLoss(gl);
        }
    }

    static findOutBlockInputsOutputsFromPasses(passes: RenderPass[]) {
        // find inputs and outputs of this block
        const rtsProducedInsideBlockItself = new Set<RTIdent>();
        const allInputs = new Set<RTIdent>();
        for (const p of passes) {
            for (const rt of p.outputs) {
                allInputs.add(rt);
                if (!p.inputs.includes(rt)) {
                    rtsProducedInsideBlockItself.add(rt);
                }
            }
        }
        const blockInputs = [...allInputs].filter(rt => !rtsProducedInsideBlockItself.has(rt));
        const blockOutputs = [...rtsProducedInsideBlockItself];
        return {inputs: blockInputs, outputs: blockOutputs};
    }

}


class PassRenderState {
    version: number = 0;
    finished: boolean = false;

	inputsStrictRtsVersionsSum: number = 0;
	inputsRtsVersionsSum: number = 0;

    constructor() {
    }
}

