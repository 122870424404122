import type { Disposable} from 'engine-utils-ts';
import { LegacyLogger, ObjectUtils } from 'engine-utils-ts';
import type { Matrix3, Matrix4, Plane, Spherical, Vector3, Vector4 } from 'math-ts';

import type { Box3, Color, TypedArray } from '../3rdParty/three';
import { Math as _Math, OrthographicCamera, Sphere } from '../3rdParty/three';
import type { KrCamera } from '../controls/MovementControls';

export type ObjectFactory<TArgs, TObj> = (args: TArgs) => TObj;
export type IndexedFactory<T> = (index: number) => T;
export type KeyedFactory<T> = (key: string) => T;
export type FactoryMethod<T> = () => T;
export type ObjectHandleMethod<T> = (t:T) => void;
export type Func2RetBool<T1, T2> = (a1: T1, a2: T2) => boolean;
export type Func<T1, T2> = (arg: T1) => T2;
export type Procedure0 = () => void;
export type Procedure2<T1, T2> = (a1: T1, a2:T2) => void;
export type Procedure3<T1, T2, T3> = (a1: T1, a2:T2, a3:T3) => void;
export type ObjReturnFunc<T> = () => T;

export type index = number;

export type DeepReadonly<T> =
	T extends (infer R)[] ? DeepReadonlyArray<R> :
	T extends Function ? T :
	T extends object ? DeepReadonlyObject<T> :
	T;

interface DeepReadonlyArray<T> extends ReadonlyArray<DeepReadonly<T>> { }

type DeepReadonlyObject<T> = {
	readonly [P in keyof T]: DeepReadonly<T[P]>;
};


export type Option<T> = T | null;

export interface IEquatable<T> {
	equals(obj: T): boolean;
}

export interface IClonable<T> {
	clone(): T;
}

export interface IComparator<T> {
	getHashcode(obj: T): number;
	equals(obj1: T, obj2: T): boolean;
}

export interface DictionaryStr<ValueType>{
	[key: string]: ValueType;
}

export interface DictionaryNum<ValueType>{
	[key: string]: ValueType;
}

export const Uint16MaxValue = 65535;

export default class Utils {

	static sortNumbersAscend(n1: number, n2: number) {
		return n1 - n2;
	}
	
	
	static areOptionalArraysEqualSorted<T>(arr1: T[] | null | undefined, arr2: T[] | null | undefined): boolean {
		if (arr1 == arr2) {
			return true;
		}
		if (arr1 && arr2) {
			return Utils.areArraysEqual(arr1.sort(), arr2.sort());
		}
		return false;
	}

	static areObjectArraysEqual<T>(arr1: ReadonlyArray<IEquatable<T>>, arr2: ReadonlyArray<T>) : boolean {
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; ++i){
            if (!arr1[i].equals(arr2[i])) {
                return false;
            }
        }
        return true;
	}


    static areArraysEqual<T>(arr1: ArrayLike<T>|null, arr2: ArrayLike<T>|null, comparator?: (lhs:T, rhs: T) => boolean) : boolean {
		if (arr1 == arr2) {
			return true;
		}
		if (!arr1 || !arr2) {
			return false;
		}
		if (arr1.length !== arr2.length) {
            return false;
		}
		comparator = comparator || Object.is;
        for (let i = 0; i < arr1.length; ++i){
            if (!comparator(arr1[i], arr2[i])) {
                return false;
            }
        }
        return true;
	}

	static isEqualToSet<T>(set: Set<T>, seq: Iterable<T>): boolean {
		let i = 0;
		for (const item of seq) {
			if (!set.has(item)) {
				return false;
			}
			++i;
		}
		if (i !== set.size) {
			return false;
		}
		return true;
	}

	static lastInsertedToSet<T>(set: Set<T>): T | undefined {
		let lastElement: T | undefined = undefined;
		for (const o of set) { // iteration in insertion order is guaranteed by the spec
			lastElement = o;
		}
		return lastElement;
	}
	
	static addToSet<T>(set: Set<T>, objects: Iterable<T>) {
		for (const o of objects) {
			set.add(o);
		}
	}

	static removeFromSet<T>(set: Set<T>, objects: Iterable<T>) {
		for (const o of objects) {
			set.delete(o);
		}
	}

	static areSetsEqual<T>(set1: Set<T>, set2: Set<T>): boolean {
		if (set1.size !== set2.size) {
			return false;
		}
		for (const v of set1) {
			if (!set2.has(v)) {
				return false;
			}
		}
		return true;

	}

	static intersectsSet<T>(iter: Iterable<T>, set: Set<T>) {
		for (const it of iter) {
			if (set.has(it)) {
				return true;
			}
		}
		return false;
	}

	static diffOfSets<T>(set1: Set<T>, set2: Set<T>): T[] {
		const res: T[] = [];
		for (const it of set1) {
			if (!set2.has(it)) {
				res.push(it);
			}
		}
		for (const it of set2) {
			if (!set1.has(it)) {
				res.push(it);
			}
		}
		return res;
	}

	static getFirstFromIter<T>(iter: Iterable<T>): T | undefined {
		for (const it of iter) {
			return it;
		}
		return undefined;
	}

	static areFloatArraysEqual(arr1: ArrayLike<number> | null | undefined, arr2: ArrayLike<number> | null | undefined, eps = 0.0001): boolean {
		if (arr1 == arr2) {
			return true;
		}
        if ((!arr1) || (!arr2)) {
            return false;
        }
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; ++i){
            if (Math.abs(arr1[i] - arr2[i]) > eps) {
                return false;
            }
        }
        return true;
	}
	
	static createArray<T>(length: number, factoryMethod: IndexedFactory<T>) : T[] {
		const arr: T[] = [];
		for (let i = 0; i < length; ++i){
			arr.push(factoryMethod(i));
		}
		return arr;
	}

	static ensureArrayLength<T>(arr: T[], length: number, defaultValue: T) {
		if (arr.length > length) {
			arr.length = length;
		} else if (arr.length < length) {
			for (let i = arr.length; i < length; ++i){
				arr[i] = defaultValue;
			}
		}
	}

	static getMaxFromArray(arr: number[]): number {
		return Math.max.apply(null, arr);
	}

	static getMinFromArray(arr: number[]): number {
		return Math.min.apply(null, arr);
	}

	static extendArray<T>(arrayToExtend: T[], elements:Iterable<T>) {
		for (const e of elements) {
			arrayToExtend.push(e);
		}
	}

	static extendArrayWithIter<T>(arrayToExtend: T[], elements:IterableIterator<T>) {
		for (const e of elements) {
			arrayToExtend.push(e);
		}
	}

	static copyArray(count: number, source: ReadonlyArray<number> | TypedArray, sourceOffset: number, target: Array<number> | TypedArray, targetOffset: number) {
		for (let i = 0; i < count; ++i){
			target[targetOffset + i] = source[sourceOffset + i];
		}
	}

	static cloneExcluding<T>(source: T[], elementsToExclude: Iterable<T>): T[] {
		const substractionSet = new Set(elementsToExclude);
		return source.filter(v => !substractionSet.has(v));
	}

	static extendSet<T>(set: Set<T>, elements: T[]) {
		for (const e of elements) {
			set.add(e);
		}
	}

	static any<T>(sequence:Iterable<T>, predicate: (item:T) => boolean): boolean {
		for (const v of sequence) {
			if (predicate(v)) {
				return true;
			}
		}
		return false;
	}

	static isEmpty<T>(sequence:Iterable<T>): boolean {
		return !!sequence[Symbol.iterator]().next().done;
	}

	static removeFirstOccurence<T>(array: T[], obj:T): T | undefined {
		const index = array.indexOf(obj);
		if (index >= 0) {
			const el = array[index];
			array.splice(index, 1);
			return el;
		}
		return undefined;
	}

	static splitToChunksLoosely<T>(array: T[], chunkSize: number): T[][] {
		if (!(chunkSize > 0)) {
			throw new Error('chunk size should be positive ' + chunkSize);
		}
		const result: T[][] = [];
		for (let i = 0; i < array.length; i += chunkSize){
			if (array.length - i < chunkSize * 2) {
				result.push(array.slice(i));
				break;
			} else {
				result.push(array.slice(i, i + chunkSize));
			}
		}
		LegacyLogger.assert(result.reduce((prev, curr) => prev + curr.length, 0) === array.length, 'chunks length sum equal to initial array length');
		return result;
	}

	static areMapsEqual<T extends number | string>(map1: ReadonlyMap<T, T[]>, map2: ReadonlyMap<T, T[]>): boolean {
		if (map1.size !== map2.size) {
			return false;
		}
		for (const [key1, vals1] of map1) {
			const vals2 = map2.get(key1);
			if (!vals2 || !Utils.areArraysEqual(vals1, vals2)) {
				return false;
			}
		}
		return true;
	}

	static arePrimitiveMapsEqual<T extends number | string>(map1: ReadonlyMap<T, T>, map2: ReadonlyMap<T, T>) {
		if (map1.size !== map2.size) {
			return false;
		}
		for (const [key1, vals1] of map1) {
			const vals2 = map2.get(key1);
			if (vals1 !== vals2) {
				return false;
			}
		}
		return true;
	}


	static vectorMinComponentIndex(vector3: Vector3) {
		let min = 0;
		if (vector3.y < vector3.x) {
			min = 1;
		}
		if (vector3.z < vector3.getComponent(min)) {
			min = 2;
		}
		return min;
	}

	static getRandomNumber(min = 0, max = 1) {
		return Math.random() * (max - min) + min;
	}

	static getClosestLimit(value:number, limit1:number, limit2:number) {
		const d1 = Math.abs(value - limit1);
		const d2 = Math.abs(value - limit2);
		return d1 < d2 ? limit1 : limit2;
	}

	static setMatrix3ToMatrix4(m3:Matrix3, m4:Matrix4) {
		const m3s = m3.elements;
		const m4s = m4.elements;
		m4s[0] = m3s[0]; m4s[4] = m3s[3]; m4s[8]  = m3s[6];
		m4s[1] = m3s[1]; m4s[5] = m3s[4]; m4s[9]  = m3s[7];
		m4s[2] = m3s[2]; m4s[6] = m3s[5]; m4s[10] = m3s[8];
	}

	static copyPlaneToVector4(plane: Plane, vector4: Vector4) {
		vector4.set(plane.normal.x, plane.normal.y, plane.normal.z, plane.constant);
	}

	static lerpArrays(fromArr:number[], toArr:number[], resArr:number[], t:number) {
		const length = fromArr.length;
		for (let i = 0; i < length; ++i){
			resArr[i] = _Math.lerp(fromArr[i], toArr[i], t);
		}
	}

	static vector4ColorToUint24(vec4Col:Vector4 | null) : number {
		if (!vec4Col) {
			return 0;
		}
		return ((vec4Col.x * 255) | 1) << 16 ^ ((vec4Col.y * 255) | 1) << 8 ^ ((vec4Col.z * 255) | 1) << 0;
	}

	static colorToXYZ<T extends Vector3 | Vector4 | Vector3 | Vector4>(color:Color, vector: T) : T {
		vector.x = color.r;
		vector.y = color.g;
		vector.z = color.b;
		return vector;
	}

	static applyLinearToGamma(colorVector: Vector3 | Vector4, gammaFactor: number) {
		var safeInverse = ( gammaFactor > 0 ) ? ( 1.0 / gammaFactor ) : 2.0;

		colorVector.x = Math.pow( colorVector.x, safeInverse );
		colorVector.y = Math.pow( colorVector.y, safeInverse );
		colorVector.z = Math.pow( colorVector.z, safeInverse );
	}

	static disposeArrayObjects(arr:Disposable[]) {
		for (let i = 0; i < arr.length; ++i){
			arr[i].dispose();
		}
		arr.length = 0;
	}

	static disposeFields(obj: any, searchDepth: number = 0) {
		Utils._disposeFields(obj, 0, searchDepth);
	}
	private static _disposeFields(obj: any, recursionLevel: number, recursionLimit: number) {
		if (recursionLevel > recursionLimit) {
			return;
		}
		const asIter = ObjectUtils.asIterable(obj);
		if (asIter) {
			for (const it of asIter) {
				Utils._disposeFields(obj, recursionLevel + 1, recursionLimit);
			}
		} else {
			for (const key in obj) {
				if (key != 'dispose') {
					continue;
				}
				const p = obj[key];
				if (typeof p == 'function') {
					p();
				}
			}
		}
	}

	static isNumber(n: any) {
		return !isNaN(n) && isFinite(n);
	}

	static isNumbersArray(arr: any[]) {
		if (!(arr && arr.length > 0)) {
			return false;
		}
		for (const n of arr) {
			if (!Utils.isNumber(n)) {
				return false;
			}
		}
		return true;
	}

	static safePositiveNumber(number:number) : number {
		if (number > 0 && number < Infinity) {
			return number;
		}
		LegacyLogger.error('number should be positive', number);
		return 1.0;
	}

	static safeNonNegativeNumber(number:number) : number {
		if (number >= 0 && number < Infinity) {
			return number;
		}
		return 0.0;
	}

	static cloneArray<T extends IClonable<T>>(arr: T[]): T[] {
		const res = [];
		for (const e of arr) {
			res.push(e.clone())
		}
		return res;
	}

	static clearAndCloneFrom<T>(cloneTo: T[], sourceArray: Iterable<T>) {
		cloneTo.length = 0;
		Utils.extendArray(cloneTo, sourceArray);
	}

	static clearAndAddTo<T>(addTo: Set<T>, source: Iterable<T>) {
		addTo.clear();
		for (const item of source) {
			addTo.add(item);
		}
	}

	static isVectorOK(v: Vector3) {
		return v && Utils.isNumber(v.x) && Utils.isNumber(v.y) && Utils.isNumber(v.z);
	}

	static isNotNull<T>(obj: T | null | undefined): obj is T {
		return obj !== null && obj !== undefined;
	}

	static isString(obj: any) {
		return typeof obj === 'string' || obj instanceof String;
	}

	static validateNumberAndLog(obj: any, name:string): number {
		if (Utils.isNumber(obj)) {
			return obj;
		}
		LegacyLogger.warn(`${obj} is not valid number for ${name}`);
		return 0;
	}

	static deepFreeze<T>(obj: T): Readonly<T> {
		Object.freeze(obj);

		if (Array.isArray(obj)) {
			for (const o of obj) {
				if (typeof o == 'object') {
					Utils.deepFreeze(o);
				}
			}
		} else if (typeof obj == 'object') {
			for (const key in obj) {
				const f = obj[key];
				if (typeof f !== 'function') {
					Utils.deepFreeze(f);
				}
			}
		}
		return obj;
	}

	static sphericalsEqual(s1: Spherical, s2: Spherical) {
		return s1.radius == s2.radius && s1.phi == s2.phi && s1.theta == s2.theta;
	}

	static computeBoundingSphere(bounds: Box3, vertices: Float32Array, from:number, count:number): Sphere {
		const sph = new Sphere();
		bounds.getCenter(sph.center);
		
		let maxRadiusSq = 0.0;
		const to = Math.min(from + count, vertices.length);
		for ( let i = from; i < to; i += 3 ) {
			const x = vertices[i + 0];
			const y = vertices[i + 1];
			const z = vertices[i + 2];
	
			maxRadiusSq = Math.max(maxRadiusSq, x * x + y * y + z * z);
		}
		sph.radius = Math.sqrt( maxRadiusSq );
	
		LegacyLogger.assert(Utils.isNumber( sph.radius ), 'computed bounding sphere radius should be valid number', sph);
		return sph;
	}

	static getCameraAspectRatio(camera: KrCamera) {
		if (camera instanceof OrthographicCamera) {
			return (camera.right - camera.left) / (camera.top - camera.bottom);
		} else {
			return camera.aspect;
		}
	}

	static ColorsArrayToBinary(colors: Color[]): Uint8Array {
		const colorData = new Uint8Array(colors.length * 3);
		for (let i = 0; i < colors.length; ++i){
			const color = colors[i];
			const i3 = i * 3;
			colorData[i3 + 0] = color.r * 255;
			colorData[i3 + 1] = color.g * 255;
			colorData[i3 + 2] = color.b * 255;
		}
		return colorData;
	}

	static combineURLs(baseURL: string, relativeURL:string): string {
		return relativeURL
		  ? baseURL.replace(/\/+$/, '') + '/' + relativeURL.replace(/^\/+/, '')
		  : baseURL;
	};
}

