<svg
    width="87"
    height="12"
    viewBox="0 0 87 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g clip-path="url(#clip0_802_235)">
        <path
            d="M9.85302 4.12748C9.85302 6.49238 8.02669 8.25399 5.61791 8.25399H3.59634V11.9999H1V0.000976562H5.61682C8.0223 0.000976562 9.85192 1.75929 9.85192 4.12748H9.85302ZM7.22267 4.12748C7.22267 3.13041 6.53382 2.40536 5.50384 2.40536H3.59634V5.84851H5.50384C6.53382 5.84851 7.22267 5.12456 7.22267 4.12639V4.12748Z"
            fill="#394851"
        />
        <path
            d="M21.7455 0.000976562L17.2077 11.9999H14.7605L10.0669 0.000976562H12.9331L15.9671 8.48324L18.9122 0.000976562H21.7466H21.7455Z"
            fill="#394851"
        />
        <path
            d="M25.9291 2.41304V5.05436H30.0534V7.405H25.9291V11.9999H23.3328V0.000976562H30.4988V2.41304H25.928H25.9291Z"
            fill="#394851"
        />
        <path
            d="M38.8078 9.72493H34.033L33.2093 11.9999H30.4539L35.1562 0.000976562H37.6078L42.4615 11.9999H39.6447L38.8067 9.72493H38.8078ZM34.8436 7.46204H37.9917L36.4089 3.17209L34.8425 7.46204H34.8436Z"
            fill="#394851"
        />
        <path
            d="M48.2465 7.9042H46.5814V11.9989H43.9851V0H48.8279C51.2312 0 53.0224 1.65521 53.0224 3.96855C53.0224 5.53821 52.1624 6.82048 50.8352 7.46106L53.4897 11.9978H50.5895L48.2454 7.9031L48.2465 7.9042ZM46.5814 5.5064H48.6579C49.7438 5.5064 50.3921 4.85375 50.3921 3.9532C50.3921 3.05265 49.7438 2.4 48.6579 2.4H46.5814V5.5075V5.5064Z"
            fill="#394851"
        />
        <path
            d="M67.373 11.9999L66.1894 4.17026L62.6125 11.9779H61.9741L58.396 4.17136L57.2103 11.9999H54.5657L56.4447 0.000976562H59.0454L62.29 7.35673L65.551 0.000976562H68.1584L70.0176 11.9999H67.373Z"
            fill="#394851"
        />
    </g>
    <defs>
        <clipPath id="clip0_802_235">
            <rect width="87" height="12" fill="white" />
        </clipPath>
    </defs>
</svg>
