import type { Bim, IdBimScene} from 'bim-ts';
import { SparseFlaggedSets } from 'bim-ts';
import type { EventStackFrame,
	UndoStack} from 'engine-utils-ts';
import {
	DefaultMap, IterUtils, StreamAccumulator
} from 'engine-utils-ts';
import { Matrix4 } from 'math-ts';

import type { ClipBox } from '../clipbox/ClipBox';
import type { ESO_Diff } from '../esos/ESO_Diff';
import type {
	ESSO, ESSO_Any, ESSO_Patch, SubmeshesCreationResources} from '../esos/ESSO';
import { ESSO_Flags, ESSO_Interactive,
	SubmeshesCreationOutput
} from '../esos/ESSO';
import { ESSO_Diff } from '../esos/ESSO_Diff';
import type { AllEngineGeometries } from '../geometries/AllEngineGeometries';
import type { EngineImages } from '../materials/EngineImages';
import type { Handle} from '../memory/Handle';
import { HandleIndexMask } from '../memory/Handle';
import type { Submeshes2 } from '../scene/Submeshes2';
import type { ECollUpdate} from './EngineCollection';
import {
	ECollAllocated, ECollDeleted, ECollPatched, EngineCollection,
} from './EngineCollection';
import type { InObjFullId } from './EngineSceneIds';
import type { ESOHandle, ESOsCollection, SubObjsSparseFlags} from './ESOsCollection';


import { OneToManyHandles } from './OneToManyHandles';

export type ESSOHandle = ESSOsCollection & Handle;

export type EssoConstructor = {new(...args: any[]): ESSO<any>};


export class ESSOsCollection extends EngineCollection<ESSOHandle, ESSO_Any, InObjFullId, ESSO_Diff> {

	readonly _typesIdsGenerator: DefaultMap<EssoConstructor, number>; // should never delete from here

	readonly parentRefs: OneToManyHandles<ESOHandle, ESSOHandle> = new OneToManyHandles();
	
	readonly selectHighlight: SubObjsSparseFlags = new SparseFlaggedSets<InObjFullId, ESSO_Flags>(
		ESSO_Flags.isHighlighted | ESSO_Flags.isSelected
	);

	readonly submeshes: Submeshes2;
	readonly undoStack: UndoStack;

	readonly _selfUpdatesAccumulator: StreamAccumulator<Readonly<ECollUpdate<ESSOHandle, InObjFullId, ESSO_Diff>>>;
	readonly submeshesCreationResoures: SubmeshesCreationResources;

	constructor(
		esos: ESOsCollection,
		undoStack: UndoStack,
		clipbox: ClipBox,
		bim: Bim,
		staticSubmeshes: Submeshes2,
		engineGeometries: AllEngineGeometries,
		engineImages: EngineImages,
	) {
		super(
			'essos-s',
			esos.logger,
		);
		this.undoStack = undoStack;
		this.submeshes = staticSubmeshes;
		this._typesIdsGenerator = new DefaultMap(c => {
			const id = this._typesIdsGenerator.size;
			if (id > 255) {
				throw new Error('to many types, should increase per id space, uint8 array type is not enough');
			}
			return id;
		});

		esos.updatesStream.subscribe({
			settings: {immediateMode: true},
			onNext: (delta: ECollUpdate<ESOHandle, IdBimScene, ESO_Diff>) => {
				if (delta instanceof ECollAllocated) {
					// esos typed handlers are responsible for allocating subobjects
				} else if (delta instanceof ECollPatched) {
					const selfUpdate: [ESSOHandle, ESSO_Diff][] = [];
					for (const [handle, diff] of delta.updated) {
						const essoDiff = diff & ESSO_Diff.All;
						if (essoDiff) {
							for (const essoHandle of this.parentRefs.getReferenced(handle)) {
								// const id = this.idsPerHandleIndex[essoHandle & HandleIndexMask];
								selfUpdate.push([essoHandle, essoDiff]);
							}
						}
					}
					this.updatesStream.pushNext(new ECollPatched(selfUpdate));

				} else if (delta instanceof ECollDeleted) {
					const handles = this.parentRefs.gatherReferencedBy(delta.deleted.map(t => t[0]));
					this.delete(handles);
				} else {
					this.logger.error('unrecognized esos update type', delta);
				}
			}
		});

		const resoures: SubmeshesCreationResources = {
			geometries: engineGeometries,
			images: engineImages,
			logger: this.logger,
			materials: staticSubmeshes.engineMaterials,
			bimGeometries: bim.allBimGeometries,
		};
		this.submeshesCreationResoures = resoures;
		
		this._selfUpdatesAccumulator = new StreamAccumulator(
			this.updatesStream
		);

		// this.updatesStream.subscribe({
		// 	settings: {immediateMode: true},
		// 	onNext: (delta) => {
		// 		let boundsToUpdateFor: ESSOHandle[];
		// 		if (delta instanceof ECollAllocated) {
		// 			boundsToUpdateFor = delta.allocated;
		// 		} else if (delta instanceof ECollPatched) {
		// 			boundsToUpdateFor = [];
		// 			for (const [h, diff] of delta.updated) {
		// 				if (diff & (ESSO_Diff.RepresentationBreaking | ESSO_Diff.Position)) {
		// 					boundsToUpdateFor.push(h);
		// 				}
		// 			}
		// 		} else if (delta instanceof ECollDeleted) {
		// 			return;
		// 		} else {
		// 			this.logger.error('unrecognized esos update type', delta);
		// 			return;
		// 		}

		// 		if (boundsToUpdateFor.length) {
		// 			const reusedAabb = Aabb.empty();
		// 			const boundsCalArgs: ESSO_AabbCalcArgs = {
		// 				bimGeometries: this.submeshes.bimGeometries,
		// 				engineGeometries: this.submeshes.engineGeometries,
		// 				bimReprBboxes: this._esosReprsBboxes.poll(),
		// 			}
		// 			for (const h of boundsToUpdateFor) {
		// 				const esso = this.peek(h);
		// 				if (esso) {
		// 					reusedAabb.makeEmpty();
		// 					esso.aabb(reusedAabb, boundsCalArgs);
		// 					this.bounds.toBuffer(reusedAabb, h & HandleIndexMask);
		// 				}
		// 			}
		// 		}
		// 	}
		// })
	}

	allocate(newObjects: [InObjFullId, ESSO_Any][]): ESSOHandle[] {
		const allocated = super.allocate(newObjects);
		for (const handle of allocated) {
			const state = this.objsPerHandleIndex[handle & HandleIndexMask]!;
			this.parentRefs.add(state.id.objHandle, handle);
			if (state instanceof ESSO_Interactive && state.flags) {
				this.selectHighlight.updateSceneInstanceFlagsFor(state.id, state.flags);
			}
		}
		return allocated;
	}

	delete(handles: Iterable<ESSOHandle>): [handle: ESSOHandle, id: InObjFullId][] {
		const deleted = super.delete(handles);
		for (const t of deleted) {
			this.parentRefs.freeChildRef(t[0]);
			this.selectHighlight.clearFor(t[1]);
		}
		return deleted;
	}

	applyUpdatesToSubmeshes() {
		
		const updates = this._selfUpdatesAccumulator.consume();

		if (!updates) {
			return;
		}

		const submeshes = this.submeshes;
		for (const delta of updates) {
			if (delta instanceof ECollAllocated) {

				const submeshesOutput: SubmeshesCreationOutput = new SubmeshesCreationOutput();
				for (const h of delta.allocated) {
					const state = this.objsPerHandleIndex[h & HandleIndexMask];
					if (!state) {
						this.logger.batchedError('allocated object doesnt exist, sync is broken', h);
						continue;
					}
					state.createSubmeshes(this.submeshesCreationResoures, submeshesOutput);
				}
				submeshesOutput.apply(submeshes, this.submeshesCreationResoures);
				
			} else if (delta instanceof ECollPatched) {
				const toDelete: InObjFullId[] = [];
				const toUpdate: [InObjFullId, ESSO_Diff][] = [];
				const submeshesOutput: SubmeshesCreationOutput = new SubmeshesCreationOutput();
				for (const [h, diff] of delta.updated) {
					if (diff & ESSO_Diff.RepresentationBreaking) {
						const obj = this.peek(h);
						if (obj) {
							toDelete.push(obj.id);
							obj.createSubmeshes(this.submeshesCreationResoures, submeshesOutput);
						}
					} else {
						const id = this.idOf(h);
						if (id) {
							toUpdate.push([id, diff]);
						}
					}
				}
				if (toDelete.length) {
					submeshes.deleteByFullIds(toDelete);
				}
				if (toUpdate.length) {
					submeshes.markDirtyBytDiffs(toUpdate);
				}
				submeshesOutput.apply(submeshes, this.submeshesCreationResoures);
				
			} else if (delta instanceof ECollDeleted) {
				submeshes.deleteByFullIds(delta.deleted.map(t => t[1]));
			
			} else {
				this.logger.error('unrecognized esos update type', delta);
			}
		}


	}

	applyPatchesFor(patches: [InObjFullId, ESSO_Patch][]) {
		const patchesPerHandle: [ESSOHandle, ESSO_Patch][] = IterUtils.filterMap(patches, ([id, patch]) => {
			const handle = this.idsToHandles.get(id);
			if (handle === undefined) {
				return undefined;
			}
			return [handle, patch];
		})
		this.applyPatches(patchesPerHandle);
	}

	applyPatches(patches: [ESSOHandle, ESSO_Patch][]) {
		let updates: [ESSOHandle, ESSO_Diff][] = [];
		let revert: [ESSOHandle, ESSO_Patch][] = [];
		const patchOut = { revert: null as (ESSO_Patch | null) };
		for (const [handle, p] of patches) {
			const obj = this.peek(handle);
			patchOut.revert = null;
			if (obj) {
				const diff = obj.applyPatch(p, patchOut);
				if (diff) {
					updates.push([handle, diff]);
					if (patchOut.revert) {
						revert.push([handle, patchOut.revert]);
					}
					if (obj instanceof ESSO_Interactive) {
						this.selectHighlight.updateSceneInstanceFlagsFor(obj.id, obj.flags);
					}
				}
			}
		}
		if (revert.length) {
			this.undoStack.addUndoAction({
				sourceIdentifier: this.identifier,
				actionName: 'flags-update',
				args: revert,
				act: (args) => this.applyPatches(args),
				isBarrierForMerges: true,
			})
		}
		if (updates.length) {
			this.updatesStream.pushNext(new ECollPatched(updates));
		}
	}

	getChildrenOf(parentHandle: ESOHandle): ESSO_Any[] {
		const res: ESSO_Any[] = [];
		for (const h of this.parentRefs.getReferenced(parentHandle)) {
			const obj = this.objsPerHandleIndex[h & HandleIndexMask];
			if (obj) {
				res.push(obj);
			}
		}
		return res;
	}

	getChildrenOfByClass<T>(parentHandle: ESOHandle, ctor: Function & {
        prototype: T}): T[] {
		const res: T[] = [];
		for (const h of this.parentRefs.getReferenced(parentHandle)) {
			const obj = this.objsPerHandleIndex[h & HandleIndexMask];
			if (obj && obj instanceof ctor) {
				res.push(obj as unknown as T);
			}
		}
		return res;
	}

	applyFlagsPatch(patches: [InObjFullId, ESSO_Flags][]) {
		const fullPatches: [ESSOHandle, ESSO_Patch][] = [];
		for (const [id, flagsPatch] of patches) {
			const h = this.idsToHandles.get(id);
			if (h) {
				fullPatches.push([h, {flags: flagsPatch}]);
			}
		}
		this.applyPatches(fullPatches);

		// const undoDiff: [InObjFullId, ESSO_Flags][] = [];
		// const dirtyFlags: [ESSOHandle, ESSO_Diff][] = [];
		// for (const [id, flagsPatch] of patches) {
		// 	const obj = this.perId.get(id);
		// 	if (obj === undefined || !(obj instanceof ESSO_Interactive)) {
		// 		continue;
		// 	}
		// 	const currFlags = obj.flags;
		// 	const newFlags = applyFlagsPatch(currFlags, flagsPatch);
		// 	if (currFlags !== newFlags) {
		// 		obj.flags = newFlags;
		// 		this.selectHighlight.updateSceneInstanceFlagsFor(id, obj.flags);

		// 		const currentFlagsChanged = (currFlags ^ newFlags) & FlagsMask;
		// 		const flagsRevertMask = (currentFlagsChanged << 12)
		// 		const flagsOld = currFlags & currentFlagsChanged;
		// 		const revertFlags = flagsOld | flagsRevertMask;
		// 		if (flagsRevertMask & (~(NoUndoFlagsTotal))) {
		// 			undoDiff.push([id, revertFlags & (~(NoUndoFlagsTotal))]);
		// 		}
				
		// 		dirtyFlags.push([this.idsToHandles.get(id)!, ESSO_Diff.RepresentationOverlaySoft]);
		// 	}
		// }
		// if (undoDiff.length) {
		// 	this.undoStack.addUndoAction({
		// 		sourceIdentifier: this.identifier,
		// 		actionName: 'flags-update',
		// 		args: undoDiff,
		// 		act: (args) => this.applyFlagsPatch(args),
		// 		isBarrierForMerges: true,
		// 	})
		// }
		// if (dirtyFlags.length) {
		// 	this.updatesStream.pushNext(new ECollPatched(dirtyFlags));
		// }
	}

	peekWorldMatrix(id: InObjFullId): Readonly<Matrix4> | undefined {
		const obj = this.perId.get(id);
		if (obj) {
			const m = new Matrix4();
			obj.calcWorldMatrix(m);
			return m;
		}
		return undefined;
	}

	patchWorldMatrices(patches: Map<InObjFullId, Matrix4>, eventParams: Partial<EventStackFrame>): void {
		const perRootObj = IterUtils.groupBy(patches, ([id, m]) => id);
	}
}


const NoUndoFlags = ESSO_Flags.isHighlighted;
const NoUndoFlagsPatch = NoUndoFlags << 12;
const NoUndoFlagsTotal = NoUndoFlags | NoUndoFlagsPatch;



