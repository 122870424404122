// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class BoolValue implements flatbuffers.IUnpackableObject<BoolValueT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):BoolValue {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsBoolValue(bb:flatbuffers.ByteBuffer, obj?:BoolValue):BoolValue {
  return (obj || new BoolValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsBoolValue(bb:flatbuffers.ByteBuffer, obj?:BoolValue):BoolValue {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new BoolValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

static startBoolValue(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addValue(builder:flatbuffers.Builder, value:boolean) {
  builder.addFieldInt8(0, +value, +false);
}

static endBoolValue(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createBoolValue(builder:flatbuffers.Builder, value:boolean):flatbuffers.Offset {
  BoolValue.startBoolValue(builder);
  BoolValue.addValue(builder, value);
  return BoolValue.endBoolValue(builder);
}

unpack(): BoolValueT {
  return new BoolValueT(
    this.value()
  );
}


unpackTo(_o: BoolValueT): void {
  _o.value = this.value();
}
}

export class BoolValueT implements flatbuffers.IGeneratedObject {
constructor(
  public value: boolean = false
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return BoolValue.createBoolValue(builder,
    this.value
  );
}
}
