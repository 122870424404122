<script lang="ts">
    export let value: boolean;
    export let readonly: boolean | null = false;
    export let onChange: (value: boolean) => void = () => {};
    const color = "#101314";
    const readonlyColor = "#9C27B0";
</script>

<svg
    class="checkbox"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    on:click={() => {
        if (!readonly) {
            onChange(!value);
        }
    }}
>
    <rect x="1" y="1" width="14" height="14" rx="7" fill="white" />
    <rect
        x="1.75"
        y="1.75"
        width="12.5"
        height="12.5"
        rx="6.25"
        stroke={color}
        stroke-opacity={readonly ? "0.38" : "0.69"}
        stroke-width="1.5"
    />
    {#if value}
        <rect
            x="5"
            y="5"
            width="6"
            height="6"
            rx="3"
            fill={readonly ? color : readonlyColor}
            fill-opacity={readonly ? "0.38" : undefined}
        />
    {/if}
</svg>

<style>
    .checkbox {
        cursor: pointer;
    }
</style>
