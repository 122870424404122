import { Vector2, Vector4 } from 'math-ts';
import { DoubleSide } from '../3rdParty/three';

import type { ShaderBase } from '../shaders/ShaderBase';
import { ShaderFlags } from './ShaderFlags';

export const SpriteShader : ShaderBase = {
    name: 'sprite-shader',
	flags: ShaderFlags.IS_SPRITE_GEO,
    uniforms: {
        resolution: { value: new Vector2(100, 100) },
        color: { value: new Vector4(0, 0, 0, 0) },
    },

	params: {
		transparent: true,
		depthTest: false,
		depthWrite: false,
		side: DoubleSide,
	},


vertexShader:
`


#include <kr_std_uniforms>
#include <kr_std_attributes>
#include <kr_std_vars>


void main() {
	
	#include <kr_std_vars_calc>

}
`,

fragmentShader:
`

#include <kr_std_uniforms>
#include <kr_std_vars>
#include <sdfs>

uniform vec4 color;

#if defined(MRT_NORMALS)
	layout(location = 1) out highp vec4 fragData1;
#endif

void main() {

	// #include <kr_std_pixel>

    gl_FragColor = color;

	#include <kr_alpha_test>

	#ifdef MRT_NORMALS 
		fragData1 = vec4( packNormalToRGB( vec3(0., 0., 1.) ),  1.0 );
	#endif
}
`
}
 