<script lang="ts">
    import type { Writable } from 'svelte/store'
    import PropertyView from './PropertyView.svelte';
    import type { UiState } from './PUI_RuntimeState';
    import ApplyProps from '../components/apply-props/ApplyProps.svelte';
    import { getPathFromConfigUiNode, matchPathForApplyProps } from '../components/apply-props/utils';
    import ActionsProperty from './ActionsProperty.svelte';
    import { PUI_ActionsNode, PUI_GroupNode, PUI_CustomGroupNode, PUI_PropertyNode, } from 'ui-bindings';
    import CustomGroupNode from './CustomGroupNode.svelte';
    import { IconComponent } from '../libui/icon';


    export let group: PUI_GroupNode;
    export let depth: number;
    export let uiState: Writable<UiState>
    $: path = getPathFromConfigUiNode(group);
    $: isMatchingForApplyProps = matchPathForApplyProps(path);

    $: children = group.childrenOrdered();

    $: curExpanded = $uiState.expanded
    $: curIndex = curExpanded.findIndex(x =>
        x.depth === depth &&
        x.name === group.name
    )
    $: expanded = curIndex == -1 || !group.collapsible;
    function toggleExpand() {
        let newExpanded = [...curExpanded]
        if (curIndex === -1) {
            newExpanded.push({
                depth: depth,
                name: group.name
            })
        } else {
            newExpanded.splice(curIndex, 1)
        }
        uiState.update(x => ({ ...x, expanded: newExpanded }))
    }
    $: offset = Math.max(0, 16 * (depth - 1));
</script>


<style lang="scss">
    .odd-bg {
        background: rgb(241, 241, 241);
    }
    .even-bg {
        background: rgb(246, 246, 246);
    }
    .group {
        &.root-level {
            margin: 12px 0;
        }
        &.first-level {
            margin: 0 -8px 8px;
            padding: 0 8px;
            border-radius: 1px;

            &:not(.collapsible) {
                margin-bottom: 16px;
            }
        }
        &.collapsible:not(.root-level){
            display: flex;
            flex-direction: column;
        }
        &.collapsible .group-content {
            padding-top: 4px;
        }
        &:not(.collapsible) {
            background-color: transparent;
        }
    }
    .group-title-container {
        position: relative;
        cursor: default;
        display: flex;
        gap: 4px;
        align-items: center;
        padding: 4px 0 4px var(--padding);
    }
    .group-content:not(.first-level-group) {
        padding-left: 8px;
        padding-right: 8px;
        margin-left: -8px;
        margin-right: -8px;
    }
</style>


{#if children.length}
<div
    class="group"
    class:first-level={depth == 1}
    class:root-level={depth == 0}
    class:odd-bg={depth%2 == 1 && depth > 0}
    class:even-bg={depth%2 == 0 && depth > 0}
    class:collapsible={group.collapsible && depth != 0}
    data-name={group.name}
>
    {#if group.name && depth != 0 && group.showTitle}
        <div
            on:click={group.collapsible ? toggleExpand : null}
            class="group-title-container"
            class:first-level-group={depth == 1}
            class:even-bg={depth%2 == 1 && depth > 1}
            class:odd-bg={depth%2 == 0 && depth > 1}
            style="--padding:{offset}px"
        >
            {#if group.collapsible}
                <IconComponent name={expanded ? 'ChevronDown' : 'ChevronRight'} />
            {/if}
            <span
                class="
                    mdc-typography--overline
                    property-group-name
                    overflow-clip
                    overflow-hidden
                "
            >
                {group.name}
        </span>
            {#if isMatchingForApplyProps}
                <ApplyProps path={path} />
            {/if}
        </div>
    {/if}

    {#if expanded}
    {@const contentDepth = group.collapsible ? depth : depth - 1}
    {@const contentOffset = contentDepth ? Math.max(0, 16 * (contentDepth - 1) + 24) : 0}
    <div
        class="group-content"
        class:odd-bg={depth%2 == 1 && depth > 1}
        class:even-bg={depth%2 == 0 && depth > 1}
        class:first-level-group={depth == 1}
    >
        {#each children as childNode (childNode.name)}
            {#if childNode instanceof PUI_PropertyNode}
                <PropertyView
                    bind:property={childNode}
                    offset={contentOffset}
                ></PropertyView>
            {:else if childNode instanceof PUI_GroupNode}
                <svelte:self
                    bind:group={childNode}
                    bind:uiState={uiState}
                    depth={depth + 1}
                ></svelte:self>
            {:else if childNode instanceof PUI_CustomGroupNode}
                <CustomGroupNode bind:group = {childNode}/>
            {:else if childNode instanceof PUI_ActionsNode }
                <ActionsProperty bind:property={childNode}/>
            {:else}
                {console.warn('unknown node type', childNode.constructor)}
            {/if}
        {/each}
    </div>
    {/if}
</div>
{/if}
