import { Vector2 } from "math-ts";
import type { ConnectionDescription } from "../types";
import { Anchor2D, Anchors2D, VectorPrimitivePath, VectorPrimitivePathDescription, createTextLabelWithPointer, createTreeDots } from "vector-graphic";
import { blocksYOffset, helperLineWidth, normalTextFontSize, wireWidth } from "./config";
import { IterUtils } from "engine-utils-ts";
import type { AnyAnchor2D } from "vector-graphic/dist/Anchors";

export function createOutConnectionWithLabel(conn: ConnectionDescription) {
    const obj = new Anchor2D()
    const pt1 = new Vector2();
    const pt2 = new Vector2(pt1.x + 40, pt1.y)
    const outConnLabel = createTextLabelWithPointer({
        fontSize: normalTextFontSize,
        offset: 40,
        pointerWidth: helperLineWidth,
        text: `${conn.text}(${conn.sceneId})`
    })
    outConnLabel.position.copy(pt2);
    outConnLabel.updateMatrix();
    obj.addAndExpandAabb(outConnLabel);
    obj.addPrimitiveAndExpandAabb(new VectorPrimitivePath({
        paths: [new VectorPrimitivePathDescription([pt1, pt2])],
        strokeWidth: wireWidth,
    }));
    return obj;
}

function create3DotVerticalSymbol() {
    const threeDotSymbol = new Anchor2D()
    threeDotSymbol.addPrimitiveAndExpandAabb(...createTreeDots({
        length: 20,
        x: 0,
        y: 0,
        vertical: true,
    }))
    threeDotSymbol.point.set(150, 0);
    threeDotSymbol.scale.set(2, 2);
    return threeDotSymbol;
}

export const threeDotSymbolTemplate = create3DotVerticalSymbol();

export function joinAnchorsWithThreeDotsSymbol(anchors: Array<AnyAnchor2D>) {
    const joined = IterUtils.withItemSeparator(anchors, () => threeDotSymbolTemplate.clone()).flat()

    const object = Anchors2D.stackAnchorsAndMerge(joined, { yOffset: blocksYOffset });

    object.points.length = 0;
    for (const anchor of anchors) {
        const points = anchor instanceof Anchor2D ? [anchor.point] : anchor.points;
        for (const point of points) {
            object.points.push(point.applyMatrix3(anchor.matrix))
        }
    }
    return object;
}
