import { RegularHeightmapGeometry, TerrainTileId } from "bim-ts";
import { ExecutionThreadPreference, JobExecutor, Success, registerExecutor } from "engine-utils-ts";
import { Vector2 } from "math-ts";
import { WGSConverter } from "./WGSConverter";


export class TilesRowToRegularHeightmapExecutor extends JobExecutor<{ 
    tilesYId: number, 
    tilesXIds: {min: number, max: number},
    tileSegmentsCount: number,
    segmentSizeMeters: number,
    geoMin: Vector2,
    geoToPixelRate: Vector2,
    datum: string | undefined,
    sourceGeo: RegularHeightmapGeometry
    }, { tileId: TerrainTileId, geometry: RegularHeightmapGeometry }[] | null> {

    execute(args: { 
        tilesYId: number, 
        tilesXIds: {min: number, max: number},
        tileSegmentsCount: number,
        segmentSizeMeters: number,
        geoMin: Vector2,
        geoToPixelRate: Vector2,
        datum: string | undefined,
        sourceGeo: RegularHeightmapGeometry 
    }): { tileId: TerrainTileId, geometry: RegularHeightmapGeometry }[] | null {

        const TileSizeMeters = args.segmentSizeMeters * args.tileSegmentsCount;
		const tileElevations = new Float32Array((args.tileSegmentsCount + 1) * (args.tileSegmentsCount + 1));
        const result: { tileId: TerrainTileId, geometry: RegularHeightmapGeometry }[] = [];

        for (let tileXId = args.tilesXIds.min; tileXId <= args.tilesXIds.max; ++tileXId) {
            let point = new Vector2(tileXId * TileSizeMeters, args.tilesYId * TileSizeMeters), imageCoord: Vector2;
            for (let iy = 0; iy <= args.tileSegmentsCount; ++iy, point.y += args.segmentSizeMeters) {
                for (let ix = 0; ix <= args.tileSegmentsCount; ++ix, point.x += args.segmentSizeMeters) {
                    if (args.datum !== undefined) {
                        const wgsCoord = WGSConverter.projectFlatMapToWgs(point, args.datum);
                        imageCoord = new Vector2(wgsCoord.longitude, wgsCoord.latitude).sub(args.geoMin).divide(args.geoToPixelRate);
                    } else {
                        imageCoord = point.clone().sub(args.geoMin).divide(args.geoToPixelRate);
                    }
                    const elevation = args.sourceGeo.sampleInLocalSpaceSingular(imageCoord);
                    let z: number;
                    if (elevation.distToRealSample > 0.001) {
                        z = NaN;
                    } else {
                        z = elevation.elevation!;
                    }
                    tileElevations[ix + iy * (args.tileSegmentsCount + 1)] = z;
                }
                point.x = tileXId * TileSizeMeters;
            }

            if (tileElevations.some(e => Number.isFinite(e))) {
                const tileGeoRes = RegularHeightmapGeometry.newFromMetersAndNaNs(
                    args.tileSegmentsCount,
                    args.tileSegmentsCount,
                    args.segmentSizeMeters,
                    tileElevations
                );
                if (tileGeoRes instanceof Success) {
                    result.push({ tileId: TerrainTileId.new(tileXId, args.tilesYId), geometry: tileGeoRes.value });
                }
            }
        }

        if (result.length > 0) {
            return result;
        } else {
            return null;
        }
        
	}

	executionPreference(args: { 
        tilesYId: number, 
        tilesXIds: {min: number, max: number},
        tileSegmentsCount: number,
        segmentSizeMeters: number,
        geoMin: Vector2,
        geoToPixelRate: Vector2,
        datum: string | undefined,
        sourceGeo: RegularHeightmapGeometry
    }): ExecutionThreadPreference {
        return ExecutionThreadPreference.WorkerThread;     
	}
}
registerExecutor(TilesRowToRegularHeightmapExecutor);