<svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 3.99992L1.53035 0.530273L0.469688 1.59093L5 6.12124L9.5303 1.59093L8.46964 0.530274L5 3.99992Z"
        fill="currentColor"
    />
</svg>
