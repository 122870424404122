import type { Result} from 'engine-utils-ts';
import { Failure, ObjectUtils, Success } from 'engine-utils-ts';

import { NumberProperty, StringProperty } from '../properties/PrimitiveProps';

import type { PvModuleMandatoryProps } from '../archetypes/pv-module/PVModule';
import type { InverterMandatoryProps } from '../archetypes/Inverter/Inverter';

export interface Mapping {
    sourceName: string | string[]; // array - if has alternative name
    getRegExp: (src: string) => RegExp;
    unit?: string;
    convertToTargetUnit?: (value: number, unit: string, parsedProps: Record<string, Result<NumberProperty| StringProperty>>) => Result<NumberProperty | StringProperty>;
    defaultValue?: (parsedProps: Record<string, Result<NumberProperty| StringProperty>>) => Result<StringProperty | NumberProperty>;
}

function getRegExp(src: string) {
    const reg = new RegExp(`^\ *${src}=(.+)$`, "m");
    return reg;
}

export type ParsedPropsType = Record<string, Result<NumberProperty | StringProperty>>;

type PVModuleParsedProps =  ParsedPropsType & {
    [key in keyof typeof PvModuleMandatoryProps]: Result<NumberProperty | StringProperty>
}

function extractProp<T extends NumberProperty | StringProperty>(
    parsedProps: ParsedPropsType,
    key: string
): T | undefined {
    const prop = parsedProps[key];
    if (prop instanceof Success) {
        return (prop as Success<T>).value;
    }
    return;
}

type ModulePropsMappingsType = {[key in keyof PVModuleParsedProps]: Mapping};

const pvModuleParseInput: ModulePropsMappingsType = {
    manufacturer: {
        sourceName: "Manufacturer",
        getRegExp,
        defaultValue: () =>
            new Success(
                StringProperty.new({ value: "General" }), 
                [new Failure({ msg: "No value for Model, using default" })]
            ),
    },
    current: { sourceName: "Imp", getRegExp, unit: "A" },
    voltage: { sourceName: "Vmp", getRegExp, unit: "V" },
    short_circuit_current: {
        sourceName: "Isc",
        getRegExp,
        unit: "A",
        defaultValue: (props) => {
            const imp = extractProp<NumberProperty>(props, "current");
            if(imp){
                return new Success(
                    NumberProperty.new({
                        value: imp.as("A") + imp.as("A") * 0.06,
                        unit: "A",
                    }),
                    [new Failure({msg: "No value for Short Circuit Current, using default"})]
                );
            }
            return new Failure({msg: "No value for Short Circuit Current"});
        }
    },
    maximum_power: {
        sourceName: "PNom",
        getRegExp,
        unit: "W",
        defaultValue: (parsedProps) => {
            const imp = extractProp<NumberProperty>(parsedProps, "current");
            const vmp = extractProp<NumberProperty>(parsedProps, "voltage");
            if (imp && vmp) {
                return new Success(
                    NumberProperty.new({
                        value: imp.as("A") * vmp.as("V"),
                        unit: "W"
                    }),
                    [
                        new Failure({
                            msg: "No value for Maximum Power, using default",
                        }),
                    ]
                );
            }
            return new Failure({ msg: "No value for Maximum Power" });
        },
    },
    model: {
        sourceName: "Model",
        getRegExp,
        defaultValue: (parsedProps) => {
            const power = extractProp<NumberProperty>(parsedProps, "maximum_power");
            if (power) {
                return new Success(
                    StringProperty.new({
                        value: `Unknown General ${power.as("W").toFixed(0)}`,
                    }),
                    [
                        new Failure({
                            msg: "No value for Model, using default",
                        }),
                    ]
                );
            }
            return new Failure({ msg: "No value for Model" });
        },
    },
    max_system_voltage: {
        sourceName: ["VMaxUL", "VMaxIEC"],
        getRegExp,
        unit: "V",
        defaultValue: () =>
            new Success(
                NumberProperty.new({ value: 1500, unit: "V" }), [
                new Failure({
                    msg: "No value for Max System Voltage, using default",
                }),
            ]),
    },
    width: {
        sourceName: "Width",
        getRegExp,
        unit: "m",
        defaultValue: () =>
            new Success(
                NumberProperty.new({ value: 3.7, unit: "m" }), [
                new Failure({ msg: "No value for Width, using default" }),
            ]),
    },
    length: {
        sourceName: "Height",
        getRegExp,
        unit: "m",
        defaultValue: () =>
            new Success(
                NumberProperty.new({ value: 7.8, unit: "m" }), [
                new Failure({ msg: "No value for Width, using default" }),
            ]),
    },
    open_circuit_voltage: {
        sourceName: "Voc",
        getRegExp,
        unit: "V",
        defaultValue: (parsedProps) => {
            const vmp = extractProp<NumberProperty>(parsedProps, "voltage");
            if(vmp){
                return new Success(
                    NumberProperty.new({ value: vmp.as("V") + vmp.as("V")*0.02, unit: "V" }), [
                    new Failure({ msg: "No value for Voc, using default" }),
                ])
            }
            return new Failure({msg: "Cannot calculate Open Circuit Voltage"});
        }
    },
    temp_coeff_voltage: {
        sourceName: "muVocSpec",
        getRegExp,
        unit: "V/C",
        convertToTargetUnit: (value, unit, matchedProps) => { 
            const voltage = extractProp<NumberProperty>(matchedProps, "voltage");
            if(voltage){
                return new Success(NumberProperty.new({
                    value: value / 1000 / voltage.as("V"),
                    unit: "V/C",
                }));
            }
            return new Failure({msg: "Cannot calculate Temp Coeff Voltage"});
        },
        defaultValue: () => {
            return new Success(
                NumberProperty.new({
                    value: -0.0028,
                    unit: "V/C",
                }),
                [new Failure({msg: "No value for Temp Coeff Voltage, using default"})]
            );
        }
    },
    temp_coeff_current: {
        sourceName: "muISC",
        getRegExp,
        unit: "A/C",
        convertToTargetUnit(value, unit, parsedProps) {
            const current = extractProp<NumberProperty>(parsedProps, 'current');
            if(current){
                return new Success(NumberProperty.new({
                    value: value / 1000 / current.as("A"),
                    unit: "A/C",
                }));
            }
            return new Failure({msg: "Cannot calculate Temp Coeff Current"});
        },
        defaultValue: () => {
            return new Success(
                NumberProperty.new({
                    value: 0.0005,
                    unit: "A/C",
                }),
                [new Failure({msg: "No value for Temp Coeff Current, using default"})]
            );
        }
    },
    temp_coeff_power: {
        sourceName: "muPmpReq",
        getRegExp,
        unit: "W/C",
        convertToTargetUnit: (value: number) => { return new Success(NumberProperty.new({ value: value / 100, unit: "W/C" })); },
        defaultValue: () => {
            return new Success(
                NumberProperty.new({
                    value: -0.0034,
                    unit: "W/C",
                }),
                [new Failure({msg: "No value for Temp Coeff Power, using default"})]
            );
        }
    },
    bifaciality_factor: {
        sourceName: "BifacialityFactor",
        getRegExp,
        unit: "",
        defaultValue: () => new Success(NumberProperty.new({ value: 0 }), [new Failure({msg: "No value for Bifaciality Factor, using default"})]),
    },
    technology: { 
        sourceName: "Technol", 
        getRegExp,
        defaultValue: (props) => {
            const voltage = extractProp<NumberProperty>(props, "voltage");
            if(voltage){
                return new Success(StringProperty.new({ 
                    value: voltage.as("V") < 80 ? "mtSiMono" : "mtCdTe" }), 
                    [new Failure({msg: "No value for Technology, using default"})]
                );
            }

            return new Failure({msg: "No value for Technology"});
        }
    },
    standard: {
        sourceName: "Mounting",
        getRegExp,
        defaultValue: () => new Success(StringProperty.new({ value: "" })),
    },
    first_year_power_degradation: {
        sourceName: "first_year_power_degradation",
        getRegExp,
        defaultValue: () => new Success(NumberProperty.new({ value: 0.02 }), [new Failure({msg: "No value for First Year Power Degradation, using default"})]),
    },
    power_degradation: {
        sourceName: "power_degradation",
        getRegExp,
        defaultValue: () => new Success(NumberProperty.new({ value: 0.005 }), [new Failure({msg: "No value for Power Degradation, using default"})]),
    },
};

export function parsePanToPVModule(fileContent: string): Result<PVModuleParsedProps> {
    const result = getMatchedProps<ModulePropsMappingsType, PVModuleParsedProps>(fileContent, pvModuleParseInput);
    return result;
}

type ParsedInverterMandatoryProps = Omit<typeof InverterMandatoryProps, "fxmr" | "modules_count">;
type InverterParsedProps = {
    [key in keyof ParsedInverterMandatoryProps]: Result<NumberProperty | StringProperty>
} & ParsedPropsType;

type InverterPropsMappingsType = {[key in keyof InverterParsedProps]: Mapping};

const InverterPropsMappings: InverterPropsMappingsType = {
    manufacturer: {
        sourceName: "Manufacturer",
        getRegExp,
        defaultValue: () =>
            new Success(
                StringProperty.new({ value: "General" }), 
                [new Failure({ msg: "No value for Model, using default" })]
            ),
    },
    max_power: { sourceName: 'PNomConv', getRegExp, unit: "kW" },  
    model: { 
        sourceName: 'Model', 
        getRegExp,
        defaultValue: (parsedProps) =>{
            const power = extractProp<NumberProperty>(parsedProps, "max_power");
            const model = power ? `General ${power.as("kW").toFixed(2)} kW` : "General"
            return new Success(StringProperty.new({
                value: model}), 
                [new Failure({msg: "No value for Model, using default"})]
            );
        }
    },
    max_voltage_input: { 
        sourceName: 'VAbsMax', 
        getRegExp, 
        unit: "V",
        defaultValue: () => 
            new Success(NumberProperty.new({value: 1500, unit: "V"}), [
            new Failure({msg: "No value for Max Voltage Input, using default"})
        ])
    },
    nominal_ac_voltage: { 
        sourceName: 'VOutConv', 
        getRegExp, 
        unit:'V',
        defaultValue: () => {
            return new Success(NumberProperty.new({value: 630, unit: "V"}), [
                new Failure({msg: "No value for Nominal AC Voltage, using default"})]
            );
        }
    },
    dc_inputs_number: { sourceName: 'NbInputs', getRegExp, unit: '' },
    max_mppt_voltage: { 
        sourceName: 'VMPPMax', 
        getRegExp, 
        unit: "V",
        defaultValue: (parsedProps) => {
            const maxVoltageInput = extractProp<NumberProperty>(parsedProps, "max_voltage_input");
            if(maxVoltageInput){
                return new Success(maxVoltageInput, [new Failure({msg: "No value for Max Mppt Voltage, using default"})]);
            }
            return new Failure({msg: "Cannot calculate Max Mppt Voltage"});
        }
    },
    min_mppt_voltage: { 
        sourceName: 'VMppMin', 
        getRegExp, 
        unit: "V",
        defaultValue: (parsedProps) => {
            const maxVoltageInput = extractProp<NumberProperty>(parsedProps, "max_mppt_voltage");
            if(maxVoltageInput){
                return new Success(
                    NumberProperty.new({value: maxVoltageInput.as("V")*0.4, unit: "V"}), 
                    [new Failure({msg: "No value for Min Mppt Voltage, using default"})]
                );
            }
            return new Failure({msg: "Cannot calculate Min Mppt Voltage"});
        }
    },
    width: { 
        sourceName: 'Width', 
        getRegExp, 
        unit: "m",
        defaultValue: (parsedProps) =>{
            const power = extractProp<NumberProperty>(parsedProps, "max_power");
            if(power){
                return new Success(
                    NumberProperty.new({value: 1.5 * power.as("kW"), unit: "mm" }), 
                    [new Failure({msg: "No value for Width, using default"})]
                );
            }
            return new Failure({msg: "Cannot calculate Width"})
        }
    },
    length: { 
        sourceName: 'Depth', 
        getRegExp, 
        unit: "m",
        defaultValue: (parsedProps) =>{
            const power = extractProp<NumberProperty>(parsedProps, "max_power");
            if(power){
                return new Success(
                    NumberProperty.new({value: 0.5 * power.as("kW"), unit: "mm" }), 
                    [new Failure({msg: "No value for Length, using default"})]
                );
            }
            return new Failure({msg: "Cannot calculate Length"})
        }
    },
    height: { 
        sourceName: 'Height', 
        getRegExp, 
        unit: "m",
        defaultValue: (parsedProps) =>{
            const power = extractProp<NumberProperty>(parsedProps, "max_power");
            if(power){
                return new Success(
                    NumberProperty.new({value: 2 * power.as("kW"), unit: "mm" }), 
                    [new Failure({msg: "No value for Height, using default"})]
                );
            }
            return new Failure({msg: "Cannot calculate Height"})
        }
    },
    max_efficiency: { 
        sourceName: 'EfficMax', 
        getRegExp, 
        unit: "", 
        defaultValue: () => new Success(
            NumberProperty.new({value: 0.99}), 
            [new Failure({msg: "No value for Max Efficiency, using default"})]
        )},
    cec_efficiency: { 
        sourceName: 'CECEuro', 
        getRegExp, unit: "", 
        defaultValue: () => new Success(
            NumberProperty.new({value: 0}), 
            [new Failure({msg: "No value for CEC Efficiency, using default"})]
        )},
    euro_efficiency: { 
        sourceName: 'EfficEuro', 
        getRegExp, unit: "", 
        defaultValue: () => new Success(
            NumberProperty.new({value: 0.98}), 
            [new Failure({msg: "No value for Euro Efficiency, using default"})]
        )},
    max_current_output: { 
        sourceName: 'IMaxAC', 
        getRegExp, 
        unit: "A",
        defaultValue: (parsedProps) => {
            const power = extractProp<NumberProperty>(parsedProps, "max_power");
            const voltage = extractProp<NumberProperty>(parsedProps, "nominal_ac_voltage");
            if(power instanceof Success && power.value && voltage instanceof Success && voltage.value){
                return new Success(
                    NumberProperty.new({ value: power.value.as("W") / voltage.value.as("V"), unit: "A" }),
                    [new Failure({msg: "No value for Max Current Output, using default"})]
                );
            }
            return new Failure({msg: "Cannot calculate Max Current Output"});
        },
    },
    max_current_input: { 
        sourceName: 'IDCMax', 
        getRegExp, 
        unit: "A",
        defaultValue: (parsedProps) => {
            const currentOutput = extractProp<NumberProperty>(parsedProps, "max_current_output");
            if(currentOutput){
                return new Success(
                    NumberProperty.new({ value: currentOutput.as("A") * 1.732, unit: "A" }),
                    [new Failure({msg: "No value for Max Current Input, using default"})]
                );
            }
            return new Failure({msg: "Cannot calculate Max Current Input"});
        }
     },
};

export function parseOndToInverter(fileContent: string): Result<InverterParsedProps> {
    const result = getMatchedProps<InverterPropsMappingsType, InverterParsedProps>(fileContent, InverterPropsMappings);

    return result;
}


function getMatchedProps<
    T extends Record<string, Mapping>,
    RT extends Record<keyof T, Result<NumberProperty | StringProperty>>>
    (
    fileContent: string, 
    mappings: T
) : Result<RT> {
    try {
        const parsedProps: Record<string, Result<NumberProperty | StringProperty>> = {};
        for (const key in mappings) {
            const mapping = mappings[key];
            let matchStr: RegExpMatchArray | null = null;
            if(Array.isArray(mapping.sourceName)){
                for (const name of mapping.sourceName) {
                    matchStr = fileContent.match(mapping.getRegExp(name));
                    if(matchStr){
                        break;
                    }
                }
            } else {
                matchStr = fileContent.match(mapping.getRegExp(mapping.sourceName));
            }
    
            if (!matchStr && !mapping.defaultValue) {
                parsedProps[key] = new Failure({msg: "No match for " + mapping.sourceName});
                continue;
            };
            let value: Result<StringProperty | NumberProperty> | undefined;
            if(matchStr === null && mapping.defaultValue){
                value = mapping.defaultValue(parsedProps);
            } else if (mapping.unit === undefined && matchStr !== null) {
                const str = StringProperty.new({value: matchStr[1]});
                value =  new Success(str);
            } else if(matchStr !== null) {
                let matchAsFloat: number = parseFloat(matchStr[1]);
                if(isFinite(matchAsFloat)){
                    value = mapping.convertToTargetUnit 
                        ? mapping.convertToTargetUnit(matchAsFloat, mapping.unit!, parsedProps) 
                        : new Success(NumberProperty.new({value: matchAsFloat, unit: mapping.unit}));
                } else {
                    value = new Failure({msg: "No value for " + mapping.sourceName});
                }
            }
    
            if(value){
                parsedProps[key] = value;
            } else {
                parsedProps[key] = new Failure({msg: "No value for " + mapping.sourceName});
            }
        }

        if(ObjectUtils.isObjectEmpty(parsedProps)){
            return new Failure({msg: "No properties found"});
        } else {
            return new Success(parsedProps as RT);
        }

    } catch(e){
        return new Failure({msg: e.message});
    }
}
