import type { Bim} from "bim-ts";
import { exportToBimAsset, TerrainInstanceTypeIdent } from "bim-ts";
import type { Yield } from "engine-utils-ts";
import type { ExportedFileDescription, FileExporter, FileExporterContext } from "ui-bindings";
import { CommonExportSettings } from "../CommonExportSettings";

export class BimAssetExporter implements FileExporter<CommonExportSettings> {

    constructor(
        readonly bim: Bim,
    ) {
    }

    initialSettings() {
        return {defaultValue: new CommonExportSettings()};
    }

    *startExport(settings: CommonExportSettings, context: FileExporterContext): Generator<Yield, ExportedFileDescription[]> {
        const bimInstancesIds = settings.export_only_selected ? this.bim.instances.getSelected(): Array.from(this.bim.instances.allIds());
        let name = `project-${window.location.pathname.slice(1)}`;
        if (bimInstancesIds.length === 1) {
            const si = this.bim.instances.peekById(bimInstancesIds[0])!;
            const res = this.bim.keyPropertiesGroupFormatter.format(
                si.type_identifier,
                si.properties,
                si.props,
            );
            if (res !== null) {
                name = res;
            }
        }
        if (!context.network) {
            throw new Error('network client is not provided');
        }

        const exportOriginResult = yield* context.requestSettings({ 
            ident: 'asset-origin-settings', 
            defaultValue: {'Export with coordinate system': bimInstancesIds.some((elId) => {
                const indetifier = this.bim.instances.peekById(elId)?.type_identifier;
                return indetifier?.includes(TerrainInstanceTypeIdent) || indetifier?.includes('boundary')
            })}
        });

        const file = yield* exportToBimAsset(
            this.bim, 
            bimInstancesIds, 
            exportOriginResult["Export with coordinate system"]
        );

        return [{extension: 'bimasset', name, file}];
    }

}

