import { Aabb2, Vector2 } from "math-ts";
import { CubicBezier, Anchor2D, VectorPrimitivePath, VectorPrimitivePathDescription, VectorPrimitiveRectangle } from "vector-graphic";

export function createInverterObject2D(): Anchor2D {
    const inverter = new Anchor2D()
    inverter.name = 'inverter'

    const frame = new VectorPrimitiveRectangle({
        width: 240,
        height: 240,
        strokeWidth: 8,
        stroke: 'black',
    })
    inverter.addPrimitiveAndExpandAabb(frame);

    const paths: VectorPrimitivePathDescription[] = [];
    // diagonal
    paths.push(new VectorPrimitivePathDescription([
        new Vector2(frame.aabb.min.x, frame.aabb.max.y),
        new Vector2(frame.aabb.max.x, frame.aabb.min.y),
    ]))

    // dcSymbol
    {
        const dcSymbolAabb = Aabb2.fromCenterAndSize(
            new Vector2(-frame.aabb.width() / 4, -frame.aabb.height() / 4),
            new Vector2(frame.aabb.width() / 3, frame.aabb.height() / 3),
        );
        const centerY = dcSymbolAabb.centerY();
        const offsetY = dcSymbolAabb.height() / 8;
        // bottom line
        paths.push(new VectorPrimitivePathDescription([
            new Vector2(dcSymbolAabb.min.x, centerY + offsetY),
            new Vector2(dcSymbolAabb.max.x, centerY + offsetY),
        ]))

        //top line
        const width = dcSymbolAabb.width();
        const pt1 = new Vector2(dcSymbolAabb.min.x, centerY - offsetY);
        const pt2 = new Vector2(dcSymbolAabb.min.x + width * 0.2, pt1.y);
        const pt3 = new Vector2(dcSymbolAabb.min.x + width * 0.25, pt1.y);
        const pt4 = new Vector2(dcSymbolAabb.min.x + width * 0.6, pt1.y);
        const pt5 = new Vector2(dcSymbolAabb.min.x + width * 0.7, pt1.y);
        const pt6 = new Vector2(dcSymbolAabb.max.x, pt1.y);
        paths.push(
            new VectorPrimitivePathDescription([pt1, pt2]),
            new VectorPrimitivePathDescription([pt3, pt4]),
            new VectorPrimitivePathDescription([pt5, pt6]),
        )
    }

    // ac symbol
    {
        const acSymbolAabb = Aabb2.fromCenterAndSize(
            new Vector2(frame.aabb.width() / 4, frame.aabb.height() / 4),
            new Vector2(frame.aabb.width() / 3, frame.aabb.height() / 3),
        );
        const centerY = acSymbolAabb.centerY();
        const offsetY = acSymbolAabb.height() / 16;
        const centerX = acSymbolAabb.centerX();

        const pt1 = new Vector2(acSymbolAabb.min.x, centerY - offsetY);
        // control 1
        const pt2 = new Vector2(centerX, acSymbolAabb.min.y - acSymbolAabb.height() / 4)

        // control 2
        const pt3 = new Vector2(centerX, acSymbolAabb.max.y + acSymbolAabb.height() / 4)
        const pt4 = new Vector2(acSymbolAabb.max.x, centerY + offsetY);

        const bezier = new CubicBezier(pt1, pt2, pt3, pt4);
        paths.push(new VectorPrimitivePathDescription([bezier]))

    }

    const pathPrimitive = new VectorPrimitivePath({
        strokeWidth: 4,
        paths: paths,
    })
    inverter.addPrimitiveAndExpandAabb(pathPrimitive)

    inverter.point.set(frame.aabb.max.x, frame.aabb.centerY());

    return inverter
}


