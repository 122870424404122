import { MeshRenderType } from '../EngineConsts';
import { LegacyLogger } from 'engine-utils-ts';

export const MeshRenderTypeSelectables = MeshRenderType.Standard | MeshRenderType.Simple | MeshRenderType.Animated;

export function renderTypeFromString(str: string) : MeshRenderType  {
	switch (str) {
		case 'none': 		return MeshRenderType.None;
		case 'standard': 	return MeshRenderType.Standard;
		case 'ghost': 		return MeshRenderType.Ghost;
		case 'animated': 	return MeshRenderType.Animated;
		case 'simple': 		return MeshRenderType.Simple;
		case 'heightmap': 	return MeshRenderType.HeightMap;
		default: LegacyLogger.error('unkown rendermode', str); return MeshRenderType.None;
	}
}

export function renderTypeToString (val: number) {
	switch (val) {
		case MeshRenderType.None: 			return 'none';
		case MeshRenderType.Standard: 		return 'standard';
		case MeshRenderType.Ghost: 			return 'ghost';
		case MeshRenderType.Animated: 		return 'animated';
		case MeshRenderType.Simple: 		return 'simple';
		case MeshRenderType.HeightMap: 		return 'heightmap';
	}
	return 'none';
}
