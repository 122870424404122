// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { InputFileDescription } from '../wire/input-file-description.js';
import { ProjectVersionDescription } from '../wire/project-version-description.js';


export class ProjectHistory {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ProjectHistory {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsProjectHistory(bb:flatbuffers.ByteBuffer, obj?:ProjectHistory):ProjectHistory {
  return (obj || new ProjectHistory()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsProjectHistory(bb:flatbuffers.ByteBuffer, obj?:ProjectHistory):ProjectHistory {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ProjectHistory()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

formatVersion():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

versions(index: number, obj?:ProjectVersionDescription):ProjectVersionDescription|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new ProjectVersionDescription()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

versionsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

inputFiles(index: number, obj?:InputFileDescription):InputFileDescription|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new InputFileDescription()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

inputFilesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startProjectHistory(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addFormatVersion(builder:flatbuffers.Builder, formatVersion:number) {
  builder.addFieldInt32(0, formatVersion, 0);
}

static addVersions(builder:flatbuffers.Builder, versionsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, versionsOffset, 0);
}

static createVersionsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startVersionsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addInputFiles(builder:flatbuffers.Builder, inputFilesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, inputFilesOffset, 0);
}

static createInputFilesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startInputFilesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endProjectHistory(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishProjectHistoryBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset);
}

static finishSizePrefixedProjectHistoryBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, undefined, true);
}

static createProjectHistory(builder:flatbuffers.Builder, formatVersion:number, versionsOffset:flatbuffers.Offset, inputFilesOffset:flatbuffers.Offset):flatbuffers.Offset {
  ProjectHistory.startProjectHistory(builder);
  ProjectHistory.addFormatVersion(builder, formatVersion);
  ProjectHistory.addVersions(builder, versionsOffset);
  ProjectHistory.addInputFiles(builder, inputFilesOffset);
  return ProjectHistory.endProjectHistory(builder);
}
}
