import type { VersionedValue} from 'engine-utils-ts';
import { Allocated, Deleted, IterUtils, LazyBasic, LazyDerived, ScopedLogger, StreamAccumulator, VersionedInvalidator, Yield } from 'engine-utils-ts';

import type { Bim } from '../Bim';
import type { IdBimMaterial } from '../BimMaterials';
import type { EntitiesCollectionUpdates} from '../collections/EntitiesCollectionUpdates';
import { EntitiesUpdated, handleEntitiesUpdates } from '../collections/EntitiesCollectionUpdates';
import type { ReactiveSolverBase } from './ReactiveSolverBase';
import { ReactiveSolverRunner } from './ReactiveSolverRunner';
import type { RuntimeSystemExecutionStatus, RuntimeSystemUiDescription } from 'ui-bindings';
import { PUI_GroupNode } from 'ui-bindings';
import type { RuntimeGlobals, SharedGlobalsInput } from './RuntimeGlobals';
import { DurationTimer, type SolversExecutionMetrics } from './DurationTimer';
import type { IdBimScene, SceneInstance, SceneInstances} from '../scene/SceneInstances';
import { SceneObjDiff } from 'src/scene/SceneObjDiff';
import type { IdBimGeo } from '../geometries/BimGeometries';


export class BimReactiveRuntime implements VersionedValue {

    readonly logger;

    readonly _solversRunners: ReactiveSolverRunner[] = [];
    readonly _solversRunnersPerSharedDepIdent: Map<string, ReactiveSolverRunner[]> = new Map();

    readonly instances: SceneInstances;
    readonly runtimeSharedDeps: RuntimeGlobals;

    readonly _instancesUpdates: StreamAccumulator<EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>>;
    readonly _geometriesUpdates: StreamAccumulator<EntitiesCollectionUpdates<IdBimGeo, number>>;
    readonly _materialsUpdates: StreamAccumulator<EntitiesCollectionUpdates<IdBimMaterial, number>>;

    private _durationTimer = new DurationTimer();
    readonly executionTimePerRuntime: Map<string, number> = new Map();

    private readonly _invalidator = new VersionedInvalidator();

    constructor(bim: Bim) {
        this.logger = new ScopedLogger('bim-runtime');
        this.instances = bim.instances;
        this.runtimeSharedDeps = bim.runtimeGlobals;

        const relevantInstancesFlags = ~(SceneObjDiff.Selected | SceneObjDiff.Highlighted | SceneObjDiff.Hidden | SceneObjDiff.ColorTint);
        this._instancesUpdates = new StreamAccumulator(this.instances.updatesStream, (u) => {
            if (u instanceof EntitiesUpdated && !(u.allFlagsCombined & relevantInstancesFlags)) {
                return false;
            }
            return true;
        });
        this._geometriesUpdates = new StreamAccumulator(bim.allBimGeometries.updatesStream);
        this._materialsUpdates = new StreamAccumulator(bim.bimMaterials.updatesStream);

        this.runtimeSharedDeps.updatesStream.subscribe({
            settings: {immediateMode: true},
            onNext: (ident) => {
                const srs = this._solversRunnersPerSharedDepIdent.get(ident);
                if (srs) {
                    for (const rt of srs) {
                        rt.markDirtyFromSharedArgsUpdate(ident);
                    }
                }
            }
        });
    }

    version(): number {
        return this._invalidator.version();
    }

    dispose() {
        this._instancesUpdates.dispose();
        this._geometriesUpdates.dispose();
        this._materialsUpdates.dispose();
        for (const s of this._solversRunners) {
            s.dispose();
        }
        this._invalidator.invalidate();
    }

    registerRuntimeSolver(runtime: ReactiveSolverBase) {
        const alreadyRegisterd = this._solversRunners.find(r => r.identifier === runtime.identifier);
        if (alreadyRegisterd) {
            throw new Error(`solver already registered ${runtime.identifier}`);
        }
        const solverRunner = new ReactiveSolverRunner(this.logger, runtime);
        this._solversRunners.push(solverRunner);
        this._invalidator.addDependency(solverRunner);
        if (solverRunner.globalArgsSelector) {
            for (const ident in solverRunner.globalArgsSelector) {
                let arr = this._solversRunnersPerSharedDepIdent.get(ident);
                if (!arr) {
                    arr = [];
                    this._solversRunnersPerSharedDepIdent.set(ident, arr);
                }
                arr.push(solverRunner);
            }
        }
    }

    _addExecutionTimeFor(runtimeIdent: string, time: number) {
        this.executionTimePerRuntime.set(runtimeIdent, (this.executionTimePerRuntime.get(runtimeIdent) ?? 0) + time);
    }

    _hasPendingUpdates() {
        if (this._haveAccumulatedUpdates()) {
            return true;
        }
        for (const runtime of this._solversRunners) {
            if (runtime.hasAnythingToRecalculate()) {
                return true;
            }
        }
        return false;
    }

    _haveAccumulatedUpdates() {
        const accumulatedAnyUpdates = !(this._instancesUpdates.isEmpty()
            && this._geometriesUpdates.isEmpty()
            && this._materialsUpdates.isEmpty()
        );
        return accumulatedAnyUpdates
    }

    runUpdates(timeEndLimit: number) {
        const haveAccumulatedUpdates = this._haveAccumulatedUpdates();

        const geometriesUpdates = this._geometriesUpdates.consume();
        const materialsUpdates = this._materialsUpdates.consume();
        if (geometriesUpdates || materialsUpdates) {// invalidate caches

            const geometriesIds = new Set<IdBimGeo>();
            const materialsIds = new Set<IdBimMaterial>();

			if (geometriesUpdates) {
				for (const update of geometriesUpdates) {
					if (update instanceof Allocated) {
					} else if (update instanceof EntitiesUpdated) {
						IterUtils.extendSet(geometriesIds, update.ids);
					} else if (update instanceof Deleted) {
                    	IterUtils.extendSet(geometriesIds, update.ids);
					} else {
						console.error('unrecognized update type, ignoring', update);
					}
				}
			}
			if (materialsUpdates) {
				for (const update of materialsUpdates) {
					if (update instanceof Allocated) {
					} else if (update instanceof EntitiesUpdated) {
						IterUtils.extendSet(materialsIds, update.ids);
					} else if (update instanceof Deleted) {
                    	IterUtils.extendSet(materialsIds, update.ids);
					} else {
						console.error('unrecognized update type, ignoring', update);
					}
				}
			}
            for (const runner of this._solversRunners.values()) {
                runner.invalidateCaches({
                    geometriesIds,
                    materialsIds,
                });
            }
        }

        const instancesUpdates = this._instancesUpdates.consume();
        if (instancesUpdates) {
            handleEntitiesUpdates(
                instancesUpdates,
                (allocatedIds) => this._addNewObjects(allocatedIds),
                (perIdDiffs) => this._markInstancesUpdated(perIdDiffs),
                (deletedIds) => this._deleteObjects(deletedIds),
            )
        }

        const versionBeforeRunSolvers = this._invalidator.version();
        let didRunAnySolver = false;
        outerLoop:
        for (let i = 0; i < this._solversRunners.length; i++) {
            if (performance.now() > timeEndLimit) {
                break;
            }
            const solver = this._solversRunners[i];
            if (!solver.hasAnythingToRecalculate()) {
                continue;
            }
            const recalcStart = performance.now();

            didRunAnySolver = true;
            let sharedArgs: SharedGlobalsInput<any> | null = null;

            if (solver.globalArgsSelector) {
                const generator = this.runtimeSharedDeps.acquireTypedObj(solver.globalArgsSelector);

                while (true) {
                    const {value, done} = generator.next();
                    if (done) {
                        sharedArgs = value;
                        break;
                    }
                    if (performance.now() >= timeEndLimit - 2) {
                        break outerLoop;
                    }
                    if (value === Yield.NextFrame) {
                        continue outerLoop; // could be subpoptimal
                    }
                }
            }
            solver.recalculateDirty(this.instances, sharedArgs, timeEndLimit);
            this._addExecutionTimeFor(solver.identifier, performance.now() - recalcStart);
        }
        
        if(versionBeforeRunSolvers === this._invalidator.version() && haveAccumulatedUpdates) {
            this._invalidator.invalidate();
        }
    }

    _addNewObjects(ids: Iterable<IdBimScene>) {
        const statePerId = this.instances.peekByIds(ids);
        for (const s of this._solversRunners.values()) {
            s.addNewFittingObjects(statePerId);
        }
    }
    _deleteObjects(ids: Iterable<IdBimScene>) {
        for (const s of this._solversRunners.values()) {
            s.removeObjects(ids);
        }
    }
    _markInstancesUpdated(updates: Iterable<[IdBimScene, SceneObjDiff]>) {
        const updatesFiltered: [IdBimScene, SceneObjDiff, Readonly<SceneInstance>][] = [];

        const diffAffectingResult = SceneObjDiff.LegacyProps
            | SceneObjDiff.NewProps
            | SceneObjDiff.ConnectedTo
            | SceneObjDiff.Representation | SceneObjDiff.RepresentationAnalytical
            | SceneObjDiff.WorldPosition
            | SceneObjDiff.GeometryReferenced
            | SceneObjDiff.SpatialChildrenList
            | SceneObjDiff.SpatialParentRef;

        for (const [id, diff] of updates) {
            if (diff & diffAffectingResult) {
                const state = this.instances.peekById(id);
                if (state) {
                    updatesFiltered.push([id, diff, state]);
                }
            }
        }
        if (updatesFiltered.length === 0) {
            return;
        }
        const t = this._durationTimer.reset();
        for (const s of this._solversRunners.values()) {
            s.markDirtyFromUpdates(updatesFiltered);
            this._addExecutionTimeFor(s.identifier, t.consumeDuration());
        }
    }


    // *_recalculationGeneratorFor(solver: ReactiveSolverRunner): Generator<Yield, void, unknown> {
    //     let sharedArgs: SharedDependenciesInput<any> | null = null;
    //     if (solver.sharedInputsDescription) {
    //         sharedArgs = yield* this.runtimeSharedDeps.acquireTypedObj(solver.sharedInputsDescription);
    //         yield Yield.Asap;
    //     }
    //     solver.recalculateDirty(this.instances, sharedArgs);
    // }

    solversUiBindings(): RuntimeSystemUiDescription[] {
        const emptyUi = new LazyBasic('', new PUI_GroupNode({name: 'empty'}));
        return IterUtils.mapIter(this._solversRunners.values(), basicRuntime => {
            return {
                name: basicRuntime.identifier,
                group_sort_key: '0',
                executionStatus: LazyDerived.new0(
                    '',
                    [basicRuntime],
                    (): RuntimeSystemExecutionStatus => basicRuntime.status()
                ),
                ui: emptyUi
            }
        });
    }

    getExecutionTimes(): SolversExecutionMetrics[] {
        const res: SolversExecutionMetrics[] = [];
        let totalTimeSum = 0;
        for (const s of this._solversRunners) {
            const totalTime = this.executionTimePerRuntime.get(s.identifier) ?? 0;
            res.push({
                ident: s.identifier,
                time: totalTime,
                count: s.calculationsCount
            });
            totalTimeSum += totalTime;
        }
        res.sort((a, b) => b.time - a.time);
        return res;
    }
}

