<script lang="ts">
    import { getContext } from "svelte";
    import {
        convertThrow,
        LazyDerived,
        LazyDerivedAsync,
        ResultAsync,
        ScopedLogger,
        Success,
    } from "engine-utils-ts";
    import type { SelectedAreaData } from "layout-service";
    import { UiBindings } from "ui-bindings";
    import { Button, ButtonComponent, ButtonType } from "../../libui/button";
    import { openBoundariesHelp } from "../../layout/HelpDialog";
    import {
        Bim,
        LayoutMetricsUtils,
        NumberProperty,
        SceneObjDiff,
    } from "bim-ts";
    import { VersionedStore } from "src/VersionedStore";
    import { IconComponent } from "src/libui/icon";
    import { NavbarContext } from "src/navbar/Navbar";
    import { KrMath } from "math-ts";
    import { UiPersistentState } from "src/UiPersistentState";

    export let areaData: SelectedAreaData;
    $: isBlockingLayout = areaData.isBlockingLayout;

    const bim = getContext<Bim>("bim");
    const uiBindings = getContext<UiBindings>("uiBindings");
    const navbar = getContext<NavbarContext>("ui-navbar");
    const minIncludedAreaAc = 5;
    const minAreaAc = 25;

    function convertToAc(prop: NumberProperty) {
        return convertThrow(prop.value, prop.unit, "ac");
    }

    function mapToConfigured(val: number) {
        const valUnit = bim.unitsMapper.mapToConfigured({
            value: val,
            unit: "ac",
        });
        return KrMath.roundTo(valUnit.value, 1);
    }

    const boundaryList = bim.instances.getLazyListOf({
        type_identifier: "boundary",
        relevantUpdateFlags:
            SceneObjDiff.NewProps |
            SceneObjDiff.LegacyProps |
            SceneObjDiff.WorldPosition |
            SceneObjDiff.GeometryReferenced |
            SceneObjDiff.RepresentationAnalytical,
    });

    const layoutMetrics = new LayoutMetricsUtils(
        bim,
        new ScopedLogger("layout-metrics"),
    );
    const totalSiteArea = LazyDerivedAsync.new1(
        "buildable-area",
        [],
        [boundaryList],
        function* ([instances]) {
            return yield* layoutMetrics.calculateBuildableArea(instances);
        },
    );
    const showTotalWarning = new VersionedStore(
        LazyDerived.new1<
            boolean,
            ResultAsync<{
                buildable_area: NumberProperty;
                site_area: NumberProperty;
            }>
        >(
            "total-site-area",
            [],
            [totalSiteArea],
            function ([total], prevValue) {
                if (total instanceof Success) {
                    return (
                        convertToAc(total.value.site_area) <=
                            minIncludedAreaAc ||
                        (convertToAc(total.value.buildable_area) <= minAreaAc &&
                            isBlockingLayout)
                    );
                }
                return prevValue ?? false;
            },
        ),
    );

    $: showSelectedAreaWarning =
        convertToAc(areaData.zoneProps.includeArea) <= minIncludedAreaAc ||
        (convertToAc(areaData.zoneProps.availableArea) <= minAreaAc &&
            isBlockingLayout);

    $: showWarning = $showTotalWarning || showSelectedAreaWarning;
    $: header = $showTotalWarning
        ? "Add boundaries to generate layout"
        : "Select more boundaries to generate layout";

    const showWarningsState = new UiPersistentState<{ selectedArea: boolean }>(
        "show-warnings",
        { selectedArea: true },
    ).store;
    function toggleWarning() {
        $showWarningsState.selectedArea = !$showWarningsState.selectedArea;
    }

    const openGuideButton = new Button(
        "Check our quick guide on boundaries",
        ButtonType.Text,
        () => {
            openBoundariesHelp(uiBindings);
        },
        false,
        "Info",
    );

    const openImportButton = new Button(
        "Import new boundaries",
        ButtonType.Text,
        () => {
            navbar.openTab("Add.Import");
        },
        false,
        "Import",
    );
</script>

{#if showWarning}
    <div class="warning-notification">
        <div class="warning-header" on:click={toggleWarning}>
            <div class="mdc-typography--body1-strong">
                {header}
            </div>
            <IconComponent
                name={$showWarningsState.selectedArea ? "Close" : "ChevronDown"}
            />
        </div>
        <div
            class="content-wrapper"
            class:collapsed={!$showWarningsState.selectedArea}
        >
            <div class="content">
                <p>
                    The selected area is insufficient for layout generation.
                    Each MW of solar arrays requires {mapToConfigured(
                        4,
                    )}-{mapToConfigured(6)}
                    {areaData.zoneProps.availableArea.unit} of land, meaning a typical
                    5 MW block would need around {mapToConfigured(25)}
                    {areaData.zoneProps.availableArea.unit}.
                </p>
                {#if $showTotalWarning}
                    <ul>
                        <li>
                            Draw and export polygons using <span
                                class="link-button mdc-typography--body1-strong"
                                on:click={() => {
                                    window.open("https://earth.google.com/");
                                }}
                            >
                                <span>Google Earth</span><IconComponent
                                    name="OpenExternal"
                                />
                            </span>
                        </li>
                        <li>
                            Export data from AutoCAD/Civil3D or other
                            professional tools as DXF
                        </li>
                        <li>Check the scale of the imported boundaries</li>
                    </ul>
                {:else if showSelectedAreaWarning}
                    <p>
                        Please select more Include boundaries.
                        {#if isBlockingLayout}
                            As an option set “Number of blocks” to “Ignore” to
                            add solar arrays layout without blocking. Analyse
                            resulting DC capacity to make decision on maximum
                            possible block size and equipment.
                        {/if}
                    </p>
                {/if}
            </div>
            {#if $showTotalWarning}
                <ButtonComponent compact={true} desc={openImportButton} />
            {/if}
            <ButtonComponent compact={true} desc={openGuideButton} />
        </div>
    </div>
    <div class="divider"></div>
{/if}

<style lang="scss">
    .warning-notification {
        padding-bottom: 32px;
        :global(svg) {
            width: 16px;
            height: 16px;
        }
        :global(button) {
            margin-bottom: 4px;
        }
        p {
            margin: 8px 0;
        }
        ul {
            padding-inline-start: 24px;
            margin: 8px 0;
        }
        li:not(:last-child) {
            margin-bottom: 8px;
        }
    }
    .warning-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 8px;
        padding: 0 8px;
        cursor: pointer;
        :global(svg) {
            margin-top: 1px;
            flex-shrink: 0;
            color: var(--color-main-medium);
        }
        &:hover :global(svg) {
            color: var(--color-main-dark);
        }
    }
    .content-wrapper {
        &.collapsed {
            display: none;
        }
    }
    .content {
        padding: 0 8px 8px 8px;
    }
    .divider {
        border-bottom: 1px solid var(--color-divider);
        position: absolute;
        left: 0px;
        bottom: 24px;
        width: 100%;
    }
    .link-button {
        cursor: pointer;
        display: inline-flex;
        gap: 2px;
        align-items: center;
    }
</style>
