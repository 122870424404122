import type {
    Bim,
    IdBimScene,
    SceneInstance
} from 'bim-ts';
import {
    BoundaryTypeIdent,
    EntitiesUpdated,
    FixedTiltTypeIdent,
    RoadTypeIdent,
    SceneInstances,
    SceneObjDiff,
    TrackerTypeIdent,
    TransformerIdent
} from 'bim-ts';
import { IterUtils, LazyBasic, LazyDerived, LogLevel, ScopedLogger, StringUtils } from 'engine-utils-ts';
import {
    NotificationDescription,
    NotificationType
} from 'ui-bindings';
import type { SceneInstancesSelectorValue, UiBindings } from "ui-bindings";

import type { KreoEngine } from "engine-ts";
import type { LazyVersioned } from "engine-utils-ts";
import { notificationSource } from '../../Notifications';
import type { VerDataSyncer } from 'verdata-ts';

function getUiName(item: SceneInstancesSelectorValue, instance: SceneInstance | undefined, multiTypes: boolean) {
    if(instance?.type_identifier === TransformerIdent && !multiTypes) { 
        const blockNumber = instance.properties.get('circuit | position | block_number')?.asNumber();
        if(blockNumber != undefined){
            return 'Block ' + blockNumber;
        }
    }
    
    if(item.label) {
        return `${item.label} ${item.value}`;
    }
    if (instance) {
        return SceneInstances.uiNameFor(item.value, instance);
    }
    return item.value.toString();
}

function getSortKeyFromInstance(id: IdBimScene, instance: SceneInstance | undefined): number | undefined {
    if(instance?.type_identifier === TransformerIdent){
        return instance.properties.get('circuit | position | block_number')?.asNumber() ?? id;
    }

    return undefined;
}

const typeIdentitiesDisplayNames = new Map<string, string>([
    [TrackerTypeIdent, 'trackers'],
    [FixedTiltTypeIdent, 'fixed-tilt'],
    [BoundaryTypeIdent, 'boundaries'],
    [RoadTypeIdent, 'roads'],
    [TransformerIdent, 'blocks']
]);

export function getPluralDisplayName(typeIdentities: string[]){
    const typeIdent = typeIdentities[0];
    if(!typeIdent || typeIdentities.length > 1){
        return 'equipment';
    }
    const displayName = typeIdentitiesDisplayNames.get(typeIdent);
    if(displayName){
        return displayName;
    }
    return typeIdent + 's';
}

export function showType(typeIdentity: string){
    return StringUtils.capitalizeFirstLatterInWord(typeIdentity);
}

function extractTag(inst: SceneInstance) {
    if(inst.type_identifier === BoundaryTypeIdent){
        const tag = inst.properties.get("boundary | boundary_type")?.asText();
        
        return tag ? StringUtils.capitalizeFirstLatterInWord(tag) : undefined;
    }
    return;
}

export interface DisplayItem {
    id: IdBimScene | number;
    value: boolean;
    isReadonly?: boolean;
    sortKey?: number;
    tag?: string;
    displayName: string;
}

function sortDisplayItems(a: DisplayItem, b: DisplayItem){
    if(a.sortKey && isFinite(a.sortKey) && b.sortKey && isFinite(b.sortKey)){
        return a.sortKey - b.sortKey;
    } else if(a.tag && b.tag){
        return b.tag.localeCompare(a.tag) || a.displayName.localeCompare(b.displayName);
    } else {
        return a.displayName.localeCompare(b.displayName);
    }
}

export abstract class SelectObjectsStore {
    public readonly isToggleActive = new LazyBasic("is-toggle-active", false);
    public readonly menuWidth: number = 300;
    public readonly selected: LazyBasic<SceneInstancesSelectorValue[]>;
    public readonly selectedMap: LazyVersioned<ReadonlyMap<IdBimScene, SceneInstancesSelectorValue>>;
    abstract readonly viewList: LazyDerived<DisplayItem[]>;
    public readonly types: string[];
    private _updateSelected: (selected: SceneInstancesSelectorValue[]) => void;

    constructor(args: {
        types: string[];
        selected: SceneInstancesSelectorValue[];
        updateSelected: (selected: SceneInstancesSelectorValue[]) => void;
    }) {
        this.types = args.types;
        this._updateSelected = args.updateSelected;
        this.selected = new LazyBasic("lazy-ids", args.selected);
        this.selectedMap = LazyDerived.new1(
            "selected",
            null,
            [this.selected],
            ([selected]) => {
                const result = new Map<IdBimScene, SceneInstancesSelectorValue>();
                for (const item of selected) {
                    result.set(item.value, item);
                }
                return result;
            }
        );
    }

    updateSelected(selected: SceneInstancesSelectorValue[]) {
        this._updateSelected(selected);
    }

    abstract get toggleName(): string;
    abstract onToggle(active: boolean): void;
    abstract onDestroy(): void;
}

export class SceneObjectsStore extends SelectObjectsStore {
    private readonly bim: Bim;
    private readonly engine: KreoEngine;
    private readonly uiBindings: UiBindings;
    private maxSelect: number | null;
    private filter?: (id: IdBimScene) => boolean;
    private readonly _logger: ScopedLogger;
    private _visibleInstances: IdBimScene[] = [];

    public readonly viewList: LazyDerived<DisplayItem[]>;

    constructor(args: {
        bim: Bim;
        engine: KreoEngine;
        uiBindings: UiBindings;
        types: string[];
        maxSelect: number | null;
        selected: SceneInstancesSelectorValue[];
        updateSelected: (selected: SceneInstancesSelectorValue[]) => void;
        filter?: (id: IdBimScene) => boolean;
    }) {
        super(args);
        this._logger = new ScopedLogger("SceneObjectsStore", LogLevel.Info);
        this._logger.debug("Creating SceneObjectsStore");
        this.bim = args.bim;
        this.engine = args.engine;
        this.uiBindings = args.uiBindings;
        this.maxSelect = args.maxSelect;
        this.filter = args.filter;

        const allItems = args.bim.instances.getLazyListOfTypes({
            type_identifiers: args.types,
            relevantUpdateFlags: SceneObjDiff.Name | SceneObjDiff.ColorTint | SceneObjDiff.LegacyProps | SceneObjDiff.NewProps,
        });

        this.viewList = LazyDerived.new2(
            "viewList",
            null,
            [allItems, this.selectedMap],
            ([listItems, selected]) => {
                const viewList: DisplayItem[] = [];
                for (const [id, instance] of listItems) {
                    const isSkipItem = this.filter ? this.filter(id) : false;
                    if (isSkipItem) {
                        continue;
                    }
                    const selectedItem = selected.get(id) ?? {value: id};
                    viewList.push({
                        id: id,
                        value: selected.has(id),
                        displayName: getUiName(selectedItem, instance, this.types.length > 1),
                        tag: extractTag(instance),
                        sortKey: getSortKeyFromInstance(id, instance),
                        isReadonly: selected.get(id)?.readonly,
                    });
                }

                viewList.sort(sortDisplayItems);
                return viewList;
            }
        );
    }

    focusSceneInstances(ids: IdBimScene[]) {
        this.bim.instances.setSelected(ids);
        this.engine.focusCamera(ids);
    }

    get toggleName() {
        return `Isolate ${getPluralDisplayName(this.types)}`;
    }

    // setIsolated
    onToggle(isIsolate: boolean): void {
        this.isToggleActive.replaceWith(isIsolate);
        if (isIsolate) {
            this._visibleInstances = this.bim.instances.getVisible();
            const ids = new Set(this.viewList.poll().map(a => a.id));
            const restIds = Array.from(this.bim.instances.perId.keys()).filter(id => !ids.has(id));
            this.bim.instances.toggleVisibility(true, Array.from(ids));
            this.bim.instances.toggleVisibility(false, restIds);
        } else {
            const ids = new Set(this._visibleInstances);
            const restIds = Array.from(this.bim.instances.perId.keys()).filter(id => !ids.has(id));
            this.bim.instances.toggleVisibility(true, this._visibleInstances);
            this.bim.instances.toggleVisibility(false, restIds);
            this._visibleInstances = [];
        }
    }

    onDestroy() {
        if (this.isToggleActive.poll()){
            this.onToggle(false);
        }
    }

    private _checkInstance(id: IdBimScene, inst: SceneInstance): boolean{
        if(this.filter){
            return this.types.includes(inst.type_identifier) && !this.filter(id);
        } else {
            return this.types.includes(inst.type_identifier);
        }
    }

    subscribeToSelectEvent() {
        const multiselect = this.maxSelect ? this.maxSelect > 1 : true;

        const subscription = this.bim.instances.updatesStream.subscribe({
            settings: { immediateMode: true },
            onNext: (diffs) => {
                if (!(diffs instanceof EntitiesUpdated)) {
                    return;
                }
                if (!(diffs.allFlagsCombined & SceneObjDiff.Selected)) {
                    return;
                }
                const newIds: SceneInstancesSelectorValue[] = [];
                const selectedIds = this.selectedMap.poll();
                for (let idx = 0; idx < diffs.ids.length; ++idx) {
                    const id = diffs.ids[idx];
                    const diff = diffs.diffs[idx];
                    if (!(diff & SceneObjDiff.Selected) || selectedIds.has(id)) {
                        continue;
                    }
                    const inst = this.bim.instances.peekById(id);
                    if(!inst || !inst.isSelected || !this._checkInstance(id, inst)){
                        continue;
                    }
   
                    newIds.push({value: id});
                }

                if(newIds.length === 0){
                    return;
                }

                if (multiselect) {
                    const newValue = Array.from(selectedIds.values());
                    IterUtils.extendArray(newValue, newIds)
                    this.updateSelected(newValue);
                } else {
                    this.updateSelected([newIds[0]]);
                }
                const objsCount = newIds.length;
                if (this.maxSelect && newIds.length > this.maxSelect) {
                    const currentSelected = Array.from(selectedIds.values());
                    this.updateSelected(currentSelected.slice(0, currentSelected.length - objsCount));

                    this.uiBindings.addNotification(
                        NotificationDescription.newBasic({
                            type: NotificationType.Warning,
                            source: notificationSource,
                            key: "selectedTooMany",
                            headerArg: this.types.join(", "),
                            removeAfterMs: 5_000,
                            addToNotificationsLog: true,
                        })
                    );
                }
            },
        });

        return subscription;
    }
}

export class VersionsStore extends SelectObjectsStore {
    public readonly viewList: LazyDerived<DisplayItem[]>;
    public readonly menuWidth: number = 600;

    constructor(args: {
        projectVerdataSyncer: VerDataSyncer,
        types: string[];
        selected: SceneInstancesSelectorValue[];
        updateSelected: (selected: SceneInstancesSelectorValue[]) => void;
    }) {
        super(args);

        this.viewList = LazyDerived.new3(
            "versions-view-list",
            null,
            [this.selectedMap,  args.projectVerdataSyncer.history, this.isToggleActive],
            ([selected, projectVersions, withDescriptionsOnly]) => {
                const viewList: DisplayItem[] = [];
                for (const v of projectVersions.versions) {
                    const isSkipItem = withDescriptionsOnly ? !v.textDescription : false;
                    if (isSkipItem) {
                        continue;
                    }
                    viewList.push({
                        id: v.id,
                        value: selected.has(v.id),
                        displayName: `v ${v.id}`,
                        tag: v.textDescription,
                    });
                }
                viewList.push({id: -1, value: selected.has(-1), displayName: "", tag: "Current version"});
                viewList.reverse();
                return viewList;
            }
        );
    }
    get toggleName() {
        return "Versions with description only";
    }
    
    onToggle(active: boolean): void {
        this.isToggleActive.replaceWith(active);
    }

    onDestroy(): void {
    }
}