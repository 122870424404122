import type { Bim } from '../../Bim';
import { BimProperty } from "../../bimDescriptions/BimProperty";
import type { PropertiesGroupFormatters } from "../../bimDescriptions/PropertiesGroupFormatter";
import { PropertiesGroupFormatter } from "../../bimDescriptions/PropertiesGroupFormatter";
import type { AssetBasedCatalogItemCreators } from "../../catalog/CatalogItemCollection";
import { EnergyYieldPropsGroup } from '../../energy/EnergyYieldPropsGroup';
import { PropsGroupBase } from '../../properties/Props';
import { PropsFieldFlags, PropsFieldOneOf } from '../../properties/PropsGroupComplexDefaults';
import { PropsGroupsRegistry } from '../../properties/PropsGroupsRegistry';
import type { SceneInstanceShapeMigration } from '../../scene/SceneInstancesArhetypes';
import { create_expandCommonEquipmentPropsWithCostTableLinks, registerEquipmentCommonAssetToCatalogItem } from "../EquipmentCommon";
import { NestedLvWires } from '../LvWire';
import { removeEnergyPropsMigration } from '../transformer/Transformer';
import { SubstationSLDViewerProperty, registerSubstationSLDRuntime } from './sld';

export const SubstationTypeIdent = 'substation';

export class SubstationProps extends PropsGroupBase {

    energy: EnergyYieldPropsGroup | null;

    singleLineDiagramViewer: SubstationSLDViewerProperty | null;

    lv_wiring: NestedLvWires | null;

    constructor(args: Partial<SubstationProps>) {
        super();
        this.energy = args.energy ?? null;
        this.singleLineDiagramViewer = args.singleLineDiagramViewer ?? null;
        this.lv_wiring = args.lv_wiring ?? null;
    }
}
PropsGroupsRegistry.register({
    class: SubstationProps,
    complexDefaults: {
        energy: new PropsFieldOneOf(
            PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization,
            null,
            EnergyYieldPropsGroup,
        ),
        singleLineDiagramViewer: new PropsFieldOneOf(
            PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization,
            null,
            SubstationSLDViewerProperty,
        ),
        lv_wiring: new PropsFieldOneOf(
            PropsFieldFlags.SkipClone | PropsFieldFlags.SkipSerialization,
            null,
            NestedLvWires,
        ),
    }
});


export const SubstationKeyProps = {
    acPower: BimProperty.NewShared({
        path: ['commercial', 'ac_power'],
        value: 'unknown',
    }),
};
export function registerSubstationKeyPropertiesGroupFormatter(group: PropertiesGroupFormatters) {
    group.register(
        SubstationTypeIdent,
        new PropertiesGroupFormatter(
            SubstationKeyProps,
            (props, unitsMapper) => {
                return props.acPower.valueUnitUiString(unitsMapper);
            }
        )
    )
}

export function registerSubstationAssetToCatalogItem(group: AssetBasedCatalogItemCreators) {
    registerEquipmentCommonAssetToCatalogItem(SubstationTypeIdent, group);
}

export function registerSubstation(bim: Bim) {
    bim.instances.archetypes.registerArchetype(
		{
			type_identifier: SubstationTypeIdent,
            propsClass: SubstationProps,
			mandatoryProps: [
                {path: ['dimensions', 'width'], value: 3, unit: 'ft', numeric_range: [0.01, 50]},
                {path: ['dimensions', 'depth'], value: 3, unit: 'ft', numeric_range: [0.01, 50]},
                {path: ['dimensions', 'length'], value: 3, unit: 'ft', numeric_range: [0.01, 50]},
			],
            propsShapeMigrations: migrations()
		}
	);

    bim.reactiveRuntimes.registerRuntimeSolver(registerSubstationSLDRuntime())
}

function migrations():SceneInstanceShapeMigration[]{
    return [
        {
			toVersion: 1,
			validation: {
				updatedProps: [],
				deletedProps: [],
			},
			patch: (inst)=>{
                const mv_props = inst.properties.createPatchToRemovePropsStartingWith("circuit | mv_wiring");
                if(mv_props){
                    inst.properties.applyPatch(mv_props);
                }
			}
        },
        removeEnergyPropsMigration(2),
    ]
}


export function create_expandSubstationPropsWithCostTableLinks() {
    return create_expandCommonEquipmentPropsWithCostTableLinks(SubstationKeyProps);
}
