import { AssetRef } from "../../AssetRef";
import { BimImage } from "../../BimImages";
import { BimImageVersions } from "../BimImagesSerializer";

export function bimImageMigration(
    img: BimImage,
    version: BimImageVersions
): BimImage {
    let inline = img.inline;
    let ref = img.assetRef;
    if (version === BimImageVersions.None) {
        if (img.assetRef) {
            ref = new AssetRef(
                img.assetRef.path.replace(".png", ".webp"),
                img.assetRef.inAssetId
            );
        }
    }
    return new BimImage(ref, inline);
}