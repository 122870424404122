import {
    CellStyle,
    ColDef,
    ICellRendererComp,
    ICellRendererParams,
} from "ag-grid-enterprise";
import { CostModel as CM } from "bim-ts";
import { ContextPlaceholder as GridContext } from "../types";
import DescriptionCell from "../../../capital/DescriptionCell.svelte";
import { defaultCellStyle } from "src/cost-model/capital/table/column-definitions/common";
import {
    commonFontColor,
    commonFontSize,
    commonFontWeight,
    getContext,
} from "./common";

export const createDescriptionColumnDefinition =
    (): ColDef<CM.FlattenedCostCategoryParams> => ({
        colId: CM.DescriptionColId,
        headerName: CM.DescriptionColId,
        flex: 2,
        minWidth: 250,
        maxWidth: 400,
        pinned: "left",

        cellStyle: (params) => {
            if (!params.data) {
                return null;
            }
            const styles: CellStyle = {
                ...defaultCellStyle,
                "text-align": "left",
                "margin-left": '7px',
            };
            if (params.data.nestLevel) {
                let indent: number = Math.max(0, params.data.nestLevel - 2);
                styles["padding-left"] = `${indent * 15}px`;
            }
            const context = getContext(params);
            commonFontSize(styles, context);
            commonFontWeight(styles, context);
            commonFontColor(styles, context);
            return styles;
        },

        valueGetter: (params) => {
            //const {category} = getContext(params);
            //if (!category) {
            //    return;
            //}
            //return category.description.value
            const desc = params.data?.path.at(-1) ?? "unknown";
            return desc;
        },

        cellClass: (params) => {
            if (!params.data) {
                return null;
            }
            //let isMainCategory =
            //    params.data.nestLevel <= 1 || !params.data.isBottom;
            //const classes = new Set(cfmmonCellClasses(getContext(params), x => x.description));
            const classes = new Set<string>();
            classes.add("ag-grid-cell-with-right-separator");
            //if (isMainCategory) {
            //    classes.add("ag-grid-cell-bold");
            //}
            return Array.from(classes);
        },

        //valueFormatter: ((params) => {
        //    if (!params.data || typeof params.value !== "string") {
        //        return;
        //    }
        //    debugger
        //    const nest = params.data.nestLevel;
        //    if (nest <= 2) {
        //        return params.value;
        //    } else {
        //        return "@".repeat((params.data.nestLevel - 2) * 4) + params.value;
        //    }
        //}) as ValueFormatterFunc<CM.FlattenedCostCategoryParams, string>,

        cellRenderer: CellRenderer,

        headerClass: [
            "ag-grid-cell-with-right-separator",
            "ag-grid-header-align-left",
        ],
    });

class CellRenderer
    implements ICellRendererComp<CM.FlattenedCostCategoryParams>
{
    root!: HTMLSpanElement;
    svelteComp!: DescriptionCell;

    getGui() {
        return this.root;
    }
    destroy() {
        this.svelteComp.$destroy();
    }
    init(
        params: ICellRendererParams<
            CM.FlattenedCostCategoryParams,
            string,
            GridContext
        >,
    ) {
        //const context = getContext(params);
        this.root = document.createElement("span");
        let disableHover = true;
        //if (params.data?.categoryId) {
        //    disableHover = !context.globalContext.hierarchy.categoryWithSceneInstances.has(params.data.categoryId);
        //}
        this.svelteComp = new DescriptionCell({
            target: this.root,
            props: {
                text: params.value ?? "",
                disableHover,
                onClick: () => {
                    if (!params.data?.categoryId) {
                        return;
                    }

                    //const {globalContext} = getContext(params);

                    //const ids = globalContext.hierarchy.getRelatedSceneInstance(params.data?.categoryId)
                    //globalContext.bim.instances.setSelected(Array.from(ids))
                    console.log("clicked");
                },
                secondAction: () => {
                    console.log("second action clicked");
                },
            },
        });
    }
    refresh() {
        return false;
    }
}
