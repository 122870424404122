<script lang="ts">

    import { type EChartOptions, echartOptionsFromCHD_PolarHeatmapChart } from 'ui-charts';
    import type { CHD_PolarHeatmapChart } from 'ui-bindings';
    import AnyEchart from './AnyEchart.svelte';

    export let chartDescription: CHD_PolarHeatmapChart;

    let echartOptions: EChartOptions | undefined = undefined;

    $: if (chartDescription) {
        try {
            echartOptions = echartOptionsFromCHD_PolarHeatmapChart(chartDescription);
        } catch (e) {
            console.error(e)
        }
    }

</script>

<AnyEchart
    bind:echartOptions={echartOptions}
></AnyEchart>
