import type { Checkbox } from "../checkbox";
import type { IconName } from "../icon";

export class ContextMenuStructure {
    constructor(
        public items: Array<Action | NamedCheckbox>,
        public closeOnInteraction: boolean = false,
    ) {}
    merge(other: ContextMenuStructure): this {
      this.items.push(...other.items);
      return this;
    }
}

export class Action {
  constructor(
    public name: string,
    public action: () => void,
    public hint?: string,
    public icon?: IconName,
    public disabled?: boolean,
  ) {}
}

export class NamedCheckbox {
  constructor(
    public name: string,
    public checkboxInput: Checkbox,
    public onClick?: () => void,
  ) {}
}
