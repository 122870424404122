<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
>
    <path
        d="M18.274 11.6333C18.6237 11.2058 18.5607 10.5758 18.1332 10.2261C17.7058 9.87635 17.0758 9.93935 16.726 10.3668C15.7053 11.6144 14.9866 11.851 14.4981 11.8596C13.9153 11.8698 13.3382 11.5849 12.4741 11.1196L12.3921 11.0753C11.6387 10.6688 10.6228 10.1205 9.46683 10.1408C8.17406 10.1635 6.95528 10.8644 5.72604 12.3668C5.37631 12.7942 5.43932 13.4243 5.86676 13.774C6.29421 14.1237 6.92423 14.0607 7.27396 13.6333C8.29472 12.3857 9.01344 12.1491 9.50192 12.1405C10.0847 12.1303 10.6618 12.4152 11.5259 12.8805L11.6079 12.9247C12.3613 13.3313 13.3772 13.8795 14.5332 13.8593C15.8259 13.8366 17.0447 13.1357 18.274 11.6333Z"
        fill="currentColor"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
        fill="currentColor"
    />
</svg>
