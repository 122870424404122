// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class PropertyNumberRange implements flatbuffers.IUnpackableObject<PropertyNumberRangeT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):PropertyNumberRange {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsPropertyNumberRange(bb:flatbuffers.ByteBuffer, obj?:PropertyNumberRange):PropertyNumberRange {
  return (obj || new PropertyNumberRange()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsPropertyNumberRange(bb:flatbuffers.ByteBuffer, obj?:PropertyNumberRange):PropertyNumberRange {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new PropertyNumberRange()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

min():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

max():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

static startPropertyNumberRange(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addMin(builder:flatbuffers.Builder, min:number) {
  builder.addFieldFloat64(0, min, 0.0);
}

static addMax(builder:flatbuffers.Builder, max:number) {
  builder.addFieldFloat64(1, max, 0.0);
}

static endPropertyNumberRange(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createPropertyNumberRange(builder:flatbuffers.Builder, min:number, max:number):flatbuffers.Offset {
  PropertyNumberRange.startPropertyNumberRange(builder);
  PropertyNumberRange.addMin(builder, min);
  PropertyNumberRange.addMax(builder, max);
  return PropertyNumberRange.endPropertyNumberRange(builder);
}

unpack(): PropertyNumberRangeT {
  return new PropertyNumberRangeT(
    this.min(),
    this.max()
  );
}


unpackTo(_o: PropertyNumberRangeT): void {
  _o.min = this.min();
  _o.max = this.max();
}
}

export class PropertyNumberRangeT implements flatbuffers.IGeneratedObject {
constructor(
  public min: number = 0.0,
  public max: number = 0.0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return PropertyNumberRange.createPropertyNumberRange(builder,
    this.min,
    this.max
  );
}
}
