import type { ScopedLogger } from "engine-utils-ts";
import { DefaultMap, IterUtils } from "engine-utils-ts";
import type { Matrix4} from "math-ts";
import { Aabb, Aabb2, KrMath, Vector2, Vector3 } from "math-ts";
import type { Bim } from "../Bim";
import type { IdBimGeo } from "../geometries/BimGeometries";
import { IrregularHeightmapGeometry } from "../geometries/IrregularHeightmapGeometries";
import { RegularHeightmapGeometry } from "../geometries/RegularHeightmapGeometry";
import { TerrainHeightMapRepresentation } from "../representation/Representations";
import { InBoundsLinearIndexer, InBoundsSearch } from "./InBoundsSearch";
import { TerrainVersion, TerrainInstanceTypeIdent } from "./Terrain";
import type { TerrainTileId } from "./TerrainTile";
import { tryCalcInTriangleZAt } from "./TriangleUtils";


export class ElevationSample {
    elevation: number | null = null;
    distToRealSample: number = Infinity;

    tryUdpateSample(elevation: number, distToRealSample: number) {
        if (this.distToRealSample < distToRealSample) {
            return;
        }
        if (this.elevation === null
            || (this.distToRealSample > distToRealSample)
            || (this.distToRealSample == distToRealSample && this.elevation < elevation)
        ) {
            this.elevation = elevation;
            this.distToRealSample = distToRealSample;
        }
    }
}

const PointSampleSearchMaxRelevantDistance = 1;

export class TerrainElevation {

    static rectangleGridFromPointsTriangulation(
        terrainPoints: Float64Array,
        terrainTriangulation: ArrayLike<number>,
        gridAabb: Aabb2,
        gridSegmentSize: number
    ): ElevationSample[][] {
        
        const halfSegmentSize = 0.5 * gridSegmentSize;
        const xCount = 1 + Math.floor((gridAabb.width() + halfSegmentSize) / gridSegmentSize);
        const yCount = 1 + Math.floor((gridAabb.height() + halfSegmentSize) / gridSegmentSize);

        const elevations: ElevationSample[][] = new Array(yCount);

        let point = new Vector2(0, 0), p0 = new Vector3(), p1 = new Vector3(), p2 = new Vector3();
        for (let trI = 0; trI < terrainTriangulation.length; trI += 3) {
            const i0 = 3 * terrainTriangulation[trI];
            const i1 = 3 * terrainTriangulation[trI + 1];
            const i2 = 3 * terrainTriangulation[trI + 2];

            p0.set(terrainPoints[i0], terrainPoints[i0 + 1], terrainPoints[i0 + 2]);
            p1.set(terrainPoints[i1], terrainPoints[i1 + 1], terrainPoints[i1 + 2]);
            p2.set(terrainPoints[i2], terrainPoints[i2 + 1], terrainPoints[i2 + 2]);

            const trMinX = Math.min(p0.x, p1.x, p2.x) - 0.001;
            const trMaxX = Math.max(p0.x, p1.x, p2.x) + 0.001;

            const trMinY = Math.min(p0.y, p1.y, p2.y) - 0.001;
            const trMaxY = Math.max(p0.y, p1.y, p2.y) + 0.001;

            const trMinIX = Math.max(0, Math.ceil((trMinX - gridAabb.min.x) / gridSegmentSize));
            if (trMinIX >= xCount) {
                continue;
            }
            const trMaxIX = Math.min(xCount - 1, Math.floor((trMaxX - gridAabb.min.x) / gridSegmentSize));
            if (trMaxIX < 0) {
                continue;
            }

            const trMinIY = Math.max(0, Math.ceil((trMinY - gridAabb.min.y) / gridSegmentSize));
            if (trMinIY >= yCount) {
                continue;
            }
            const trMaxIY = Math.min(yCount - 1, Math.floor((trMaxY - gridAabb.min.y) / gridSegmentSize));
            if (trMaxIY < 0) {
                continue;
            }

            for (let iy = trMinIY; iy <= trMaxIY; ++iy) {
                point.y = gridAabb.min.y + iy * gridSegmentSize;
                
                for (let ix = trMinIX; ix <= trMaxIX; ++ix) {
                    if (elevations[iy] === undefined) {
                        elevations[iy] = new Array(xCount);
                    }
                    if (elevations[iy][ix] === undefined) {
                        elevations[iy][ix] = new ElevationSample();
                    }
                    const nodeElevation = elevations[iy][ix];

                    if (nodeElevation?.distToRealSample === 0) {
                        continue;
                    }

                    point.x = gridAabb.min.x + ix * gridSegmentSize;
                    
                    let inTriangleZ = tryCalcInTriangleZAt(p0, p1, p2, point);
                    if (inTriangleZ) {
                        nodeElevation.tryUdpateSample(inTriangleZ.z, inTriangleZ.distToSample);
                    }
                }
            }
        }

        return elevations;
    }

    static sampleFromPointsTriangulation(
        terrainPoints: Vector3[],
        terrainTriangulation: ArrayLike<number>,
        pointsToSample: Vector2[],
    ): ElevationSample[] {
        
        const terrainPointsBounds = Aabb.empty().setFromPoints(terrainPoints);
        const terrainBounds2d = terrainPointsBounds.xy();
        const pointsToSampleBounds = Aabb2.empty().setFromPoints(pointsToSample);

        const relevantBounds = new Aabb2(
            terrainBounds2d.min.clone().max(pointsToSampleBounds.min),
            terrainBounds2d.max.clone().min(pointsToSampleBounds.max),
        ).expandByScalar(PointSampleSearchMaxRelevantDistance);
        
        const cellSize = KrMath.clamp(relevantBounds.maxSide() / Math.pow(terrainPoints.length, 1/3), 1, 50);

        const inTileCoordsGenerator = new InBoundsLinearIndexer(relevantBounds, cellSize);
        const trianglesPerInTileCoord: Vector3[][] = IterUtils.newArrayWithIndices(0, inTileCoordsGenerator.maxCoordsCount()).map(() => []);
        // const pointsSearch = 
        const pointsInsideRelevantBounds = terrainPoints.filter(p => relevantBounds.containsPoint(p));
        const pointsSearch = pointsInsideRelevantBounds.length > 0 ? new InBoundsSearch(
            pointsInsideRelevantBounds,
            Math.max(cellSize, 3)
        ): null;

        const reusedTriangleAabb2 = Aabb2.empty();
        for (let trI = 0; trI < terrainTriangulation.length; trI += 3) {
            const ind0 = terrainTriangulation[trI + 0];
            const ind1 = terrainTriangulation[trI + 1];
            const ind2 = terrainTriangulation[trI + 2];

            const p0 = terrainPoints[ind0];
            const p1 = terrainPoints[ind1];
            const p2 = terrainPoints[ind2];

            const trMinX = Math.min(p0.x, p1.x, p2.x) - 0.001;
            const trMaxX = Math.max(p0.x, p1.x, p2.x) + 0.001;

            const trMinY = Math.min(p0.y, p1.y, p2.y) - 0.001;
            const trMaxY = Math.max(p0.y, p1.y, p2.y) + 0.001;

            reusedTriangleAabb2.min.x = trMinX;
            reusedTriangleAabb2.min.y = trMinY;
            reusedTriangleAabb2.max.x = trMaxX;
            reusedTriangleAabb2.max.y = trMaxY;

            if (!relevantBounds.intersectsBox2(reusedTriangleAabb2)) {
                continue;
            }

            for (const inTileCoord of inTileCoordsGenerator.getLinearCoordsForBoundsIndividual_t(
                trMinX, trMinY, trMaxX, trMaxY
            )) {
                const trianglesPerCoord = trianglesPerInTileCoord[inTileCoord];
                trianglesPerCoord.push(p0, p1, p2);
            }
        }

        const result: ElevationSample[] = pointsToSample.map(s => new ElevationSample());
        for (let pi = 0; pi < pointsToSample.length; ++pi) {
            const point = pointsToSample[pi];
            const sampleDescr = result[pi];
            if (!terrainBounds2d.containsPoint(point)) {
                continue;
            }
            const pointInTileCoord = inTileCoordsGenerator.getLinearCoordsForPoint(point.x, point.y);
            const trianglesPerCoord = trianglesPerInTileCoord[pointInTileCoord];
            if (trianglesPerCoord.length > 0) {
                for (let i = 0; i < trianglesPerCoord.length; i += 3) {
                    const p0 = trianglesPerCoord[i + 0];
                    const p1 = trianglesPerCoord[i + 1];
                    const p2 = trianglesPerCoord[i + 2];

                    let inTriangleZ = tryCalcInTriangleZAt(p0, p1, p2, point);
                    if (inTriangleZ) {
                        sampleDescr.tryUdpateSample(
                            inTriangleZ.z,
                            inTriangleZ.distToSample
                        )
                        if (inTriangleZ.distToSample === 0) {
                            break;
                        }
                    }
                }
            } else {
            }

            if (sampleDescr.distToRealSample > 0.1 && pointsSearch != null) {
                // find closest point of this triangles
                // use its height as our sample
                // we search only closest point inside current tile, which is not strictly closest point
                // but it should be good enough for now, its not really valid z anyways, just approximation for abscent data
                const searchBounds = new Aabb2(
                    point.clone().addScalar(-PointSampleSearchMaxRelevantDistance),
                    point.clone().addScalar(PointSampleSearchMaxRelevantDistance)
                );
                const pointsNearby = pointsSearch.getPointsInBounds(searchBounds);
                for (const p of pointsNearby) {
                    const dx = point.x - p.x;
                    const dy = point.y - p.y;
                    const distance2d = Math.sqrt(dx * dx + dy * dy);
                    sampleDescr.tryUdpateSample(p.z, distance2d);
                }
            } else {

            }
        }
        return result;
    }

    static sampleFromIrregularGeometry(
        geometry: IrregularHeightmapGeometry,
        worldMatrix: Matrix4,
        pointsToSampleWS: Vector2[],
    ) {
        const geoPointsInWorldSpace = Vector3.arrayFromFlatArray(geometry.points3d);
        if (!worldMatrix.isIdentity()) {
            for (const p of geoPointsInWorldSpace) {
                p.applyMatrix4(worldMatrix);
            }
        }
        return TerrainElevation.sampleFromPointsTriangulation(
            geoPointsInWorldSpace, geometry.trianglesIndices, pointsToSampleWS
        );
    }
    static sampleFromRegularGeometry(
        tileId: TerrainTileId,
        tileSize: number,
        geometry: RegularHeightmapGeometry,
        worldMatrix: Matrix4,
        pointsToSampleWS: Vector2[],
    ) {
        const tileOffset = tileId.localOffset(tileSize);
        const inversionMatrix = worldMatrix.isIdentity() ? null : worldMatrix.clone().invert();
        const pointsInLocalCoords = pointsToSampleWS.map(v => {
            v = v.clone();
            if (inversionMatrix) { // strictly speaking its wrong
                v.applyMatrix4(inversionMatrix);
            }
            v.sub(tileOffset);
            return v;
        });
        const localCoords = geometry.sampleInLocalSpace(pointsInLocalCoords);
        const point3d = new Vector3();
        const isMatrixIdent = worldMatrix.isIdentity();
        const globalCoords = localCoords.map((c, index)=> {
            if(c.elevation === null || isMatrixIdent){
                return c;
            }
            const point2d = pointsInLocalCoords[index];
            const global = point3d
                .set(point2d.x + tileOffset.x, point2d.y + tileOffset.y, c.elevation)
                .applyMatrix4(worldMatrix);
            c.elevation = global.z;
            return c;
        })
        return globalCoords;
    }

    static sampleFromTiles(args: {
        logger: ScopedLogger,
        tileSize: number,
        localTiles: ReadonlyMap<TerrainTileId, IdBimGeo>,
        bim: Bim,
        worldMatrix: Matrix4,
        positionsToSampleAtWs: Vector2[],
        mergeInto?: ElevationSample[],
    }): ElevationSample[] {

        const tileSize = args.tileSize;

        const isWorldMatrixIdentity = args.worldMatrix.isIdentity();
        const wm = args.worldMatrix;

        const totalBounds2d = Aabb2.empty();

        const pointsToSampleAabb2 = Aabb2.empty().setFromPoints(args.positionsToSampleAtWs);

        const geometriesAabbs = args.bim.allBimGeometries.aabbs.poll();
        const tiles = IterUtils.newMapFromTuples<TerrainTileId, [IrregularHeightmapGeometry | RegularHeightmapGeometry, Aabb2]>(
            IterUtils.filterMap(args.localTiles, ([tileId, tileGeoId]) => {
                const tileGeo = args.bim.allBimGeometries.peekById(tileGeoId);
                if (!tileGeo) {
                    args.logger.warn('no geometry for terrain tile exists', tileId);
                    return undefined;
                }
                const geoBounds = geometriesAabbs.get(tileGeoId);
                if (!geoBounds) {
                    args.logger.warn('no aabb for terrain tile exists', tileId);
                    return undefined;
                }
                if (!(tileGeo instanceof IrregularHeightmapGeometry) && !(tileGeo instanceof RegularHeightmapGeometry)) {
                    args.logger.warn('unexpected terrain geometry type', tileGeo.constructor.name);
                    return undefined;
                }
                const wsBounds = geoBounds.clone();
                if (tileGeo instanceof RegularHeightmapGeometry) {
                    tileId.offsetAabb(wsBounds, tileSize);
                }
                if (isWorldMatrixIdentity === false) {
                    wsBounds.applyMatrix4(wm);
                }
                const bounds2dWorldSpace = wsBounds.xy();
                if (!bounds2dWorldSpace.intersectsBox2(pointsToSampleAabb2)) {
                    return undefined;
                }
                totalBounds2d.union(bounds2dWorldSpace);
                return [tileId, [tileGeo, bounds2dWorldSpace]];
            }),
        );

        const tilesIndsGenerator = new InBoundsLinearIndexer(totalBounds2d, tileSize / 8);

        const tilesIdsPerLinearCoord = new DefaultMap<number, TerrainTileId[]>(() => []);
        for (const [tileId, [_tileGeo, wsBounds2d]] of tiles) {
            for (const tileIndexLinear of tilesIndsGenerator.getLinearCoordsForBounds_t(wsBounds2d)) {
                const ids = tilesIdsPerLinearCoord.getOrCreate(tileIndexLinear);
                ids.push(tileId);
            }
        }

        interface PointToSample {
            pointIndex: number,
            point: Vector2,
        }

        const perTileIdPointsToSample = new DefaultMap<TerrainTileId, PointToSample[]>(() => []);

        for (let pointIndex = 0; pointIndex < args.positionsToSampleAtWs.length; ++pointIndex) {

            const point = args.positionsToSampleAtWs[pointIndex];

            if (!totalBounds2d.containsPoint(point)) {
                continue;
            }
            const pointLinearCoord = tilesIndsGenerator.getLinearCoordsForPoint(point.x, point.y);

            const tilesAtPoint = tilesIdsPerLinearCoord.get(pointLinearCoord);
            if (tilesAtPoint !== undefined) {
                for (const tileId of tilesAtPoint) {
                    perTileIdPointsToSample.getOrCreate(tileId).push({ pointIndex, point });
                }
            } else {
                args.logger.batchedWarn('no tiles for point', pointLinearCoord);
            }
        }

        if (args.mergeInto && args.mergeInto.length < args.positionsToSampleAtWs.length) {
            throw new Error(`invalid results length (${args.mergeInto.length}), should equal to input points length (${args.positionsToSampleAtWs.length})`);
        }
        const results = args.mergeInto ? args.mergeInto : args.positionsToSampleAtWs.map(_ => new ElevationSample());

        for (const [tileId, pointsToSampleForThisTile] of perTileIdPointsToSample) {
            const [tileGeo, tileBounds] = tiles.get(tileId)!;
            const pointsToSample = pointsToSampleForThisTile.map(p => p.point);

            let elevations: ElevationSample[];

            if (tileGeo instanceof RegularHeightmapGeometry) {
                elevations = TerrainElevation.sampleFromRegularGeometry(
                    tileId, tileSize, tileGeo, wm, pointsToSample
                );
            } else if (tileGeo instanceof IrregularHeightmapGeometry) {
                elevations = TerrainElevation.sampleFromIrregularGeometry(tileGeo, wm, pointsToSample);
            } else {
                throw new Error(`unexpected geo type`);
            }

            for (let i = 0; i < elevations.length; ++i) {
                const perTileSample = elevations[i];
                if (perTileSample.elevation === null) {
                    continue;
                }
                const globalSampleIndex = pointsToSampleForThisTile[i].pointIndex;
                const globalSample = results[globalSampleIndex];
                globalSample.tryUdpateSample(perTileSample.elevation, perTileSample.distToRealSample)
            }

        }
        return results;
    }

    static sampleFromBim(logger: ScopedLogger, bim: Bim, pointsToSampleAtWs: Vector2[], version: TerrainVersion): ElevationSample[] {

        const terrainInstances = bim.instances.peekByTypeIdent(TerrainInstanceTypeIdent);

        const elevationSamples = pointsToSampleAtWs.map(p => new ElevationSample());

        for (const [id, terrainInstance] of terrainInstances) {
            if (!(terrainInstance.representation instanceof TerrainHeightMapRepresentation)) {
                logger.error(`unrecognized terrain representation`, terrainInstance.representation);
                continue;
            }
            const localTiles = new Map(
                IterUtils.mapIter(
                    terrainInstance.representation.tiles,
                    ([tileId, tile]) => {
                        if (version === TerrainVersion.Latest && tile.updatedGeo !== 0) {
                            return [tileId, tile.updatedGeo];
                        } else {
                            return [tileId, tile.initialGeo];
                        }
                    }
                )
            );
            TerrainElevation.sampleFromTiles({
                logger: logger,
                bim: bim,
                localTiles: localTiles,
                worldMatrix: terrainInstance.worldMatrix,
                positionsToSampleAtWs: pointsToSampleAtWs,
                tileSize: terrainInstance.representation.tileSize,
                mergeInto: elevationSamples,
            });
        }

        return elevationSamples;
    }
}



