import { type TasksRunner, type LongTask, PollablePromise, Failure } from "engine-utils-ts";
import { type UiBindings, NotificationDescription, NotificationType } from "ui-bindings";
import type { VerDataSyncer } from "verdata-ts";
import { notificationSource } from '../../Notifications';
import { downloadFile } from './DownloadFile';


export function downloadVerData(syncer:VerDataSyncer, uiBindings: UiBindings, tasksRunner:TasksRunner) {
    try {
        const task: LongTask<any> = tasksRunner.newLongTask<any>({
            defaultGenerator: downloadVerDataZip(syncer),
            taskTimeoutMs: 60 * 60 * 1000
        });
        uiBindings.addNotification(
            NotificationDescription.newWithTask({
                source: notificationSource,
                key: 'downloadVerdata',
                taskDescription: { task },
                type: NotificationType.Info,
                removeAfterMs: 3000,
                addToNotificationsLog: true
            })
        );
    } catch (e) {
        console.error(e);
        uiBindings.addNotification(NotificationDescription.newBasic({
            source: notificationSource,
            key: 'downloadVerdataFailed',
            type: NotificationType.Error,
            removeAfterMs: 3000,
            addToNotificationsLog: true
        }));
    }
}

export function* downloadVerDataZip(syncer:VerDataSyncer){
    const result = yield* PollablePromise.generatorWaitFor(syncer.getDebugZip());
    if(result instanceof Failure){
        throw new Error(result.errorMsg());
    }

    downloadFile(`verdata-${window.location.pathname.slice(1)}.zip`, result.value);
}
