import type { IdBimScene } from 'bim-ts';
import type { EventStackFrame, ScopedLogger, VersionedValue } from 'engine-utils-ts';
import { VersionedInvalidator } from 'engine-utils-ts';
import { Aabb, Matrix4, Vector3 } from 'math-ts';
import type { GesturesMousePos } from '../controls/MouseGesturesBase';
import type { KrCamera } from '../controls/MovementControls';
import type { BimObjectSceneIntersection, InteractiveObjectIntersection } from './Raycasts';
import type { InteractiveSceneObjects, IdEngineObject } from './InteractiveSceneObjects';
import { TransformsProviderType } from './InteractiveSceneObjects';
import type { SnappingSettings } from '../SnappingSettings';


export class InteractiveSceneObjectsNone implements InteractiveSceneObjects<IdBimScene, BimObjectSceneIntersection> {

	
	readonly selectionInvalidator: VersionedValue;
	readonly repr_invalidator: VersionedValue;
	
	readonly logger: ScopedLogger;

	constructor(logger: ScopedLogger) {
		this.selectionInvalidator = new VersionedInvalidator();;
		this.repr_invalidator = new VersionedInvalidator();

		this.logger = logger.newScope('InteractiveSceneObjectsNone');
	}

	type(): TransformsProviderType {
		return TransformsProviderType.None;
	}

	getVisible(): IdBimScene[] {
		return [];
	}

	getHighlighted(): IdBimScene[] {
		return [];
	}
	setHighlighted(ids: IdBimScene[]): void {
		this.logger.debug('setHighlighted', ids);
	}

	anySelected(): boolean {
		return false;
	}
	getSelected(): IdBimScene[] {
		return [];
	}
	setSelected(ids: IdBimScene[]): void {
		this.logger.debug('setSelected', ids);
	}
	toggleSelected(isSelected: boolean, ids: IdBimScene[]): void {
	}

	cloneObjects(ids: IdBimScene[], worldSpaceDirectionOfClone: Vector3) {
		this.logger.warn('attempt to clone objects in InteractiveSceneObjectsNone');
		return { newObjects: [], toUseForGesture: [] };
	}
	deleteObjects(ids: IdBimScene[]): void {
		this.logger.warn('attempt to delete objects in InteractiveSceneObjectsNone');
	}

	getWorldMatricesOf(ids: IdBimScene[]): Map<IdBimScene, Matrix4> {
		return new Map();
	}
	patchWorldMatrices(patches: Map<IdBimScene, Matrix4>, e: Partial<EventStackFrame>): void {
		this.logger.warn('attempt to patch world matrices in InteractiveSceneObjectsNone');
	}

	gatherIdsWithSubtreesOf(params: { ids: IdBimScene[]; sortParentFirst?: boolean; }): IdEngineObject[] {
		return [];
	}
	getChildrenOf(ids: IdBimScene[]): IdBimScene[] {
		return [];
	}
	getParentsOf(ids: IdBimScene[]): Map<IdEngineObject, IdEngineObject> {
		return new Map();
	}

	calcLocalOrientationFor(ids: IdBimScene[]): Matrix4 {
		return new Matrix4();
	}
	calcBboxOf(ids: IdBimScene[]): Aabb {
		return Aabb.empty();
	}
	calcOriginsCenterOf(ids: IdBimScene[]): Vector3 {
		return new Vector3();
	}

	findIntersectingFrustum(frustumMatrix: Matrix4, near: number, far: number, strictInside: boolean): IdBimScene[] {
		return [];
	}

	findPointToSnap(snapNearPosition: Vector3, skipIds: Set<IdEngineObject>, camera: KrCamera, settings: SnappingSettings): Vector3 | null {
		return null;
	}

	intersectRay(camera: KrCamera, mousePos: GesturesMousePos): BimObjectSceneIntersection | null {
		return null;
	}
	getIdsFromIntersection(int: InteractiveObjectIntersection): IdBimScene[] {
		return [];
	}
}
