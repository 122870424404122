import type { WHITE_SPACE } from "../utils/inline-layout/Whitespace";

export class Inline {
    width: number;
    height: number;

    offsetX: number = 0;
    offsetY: number = 0;

    anchor: number;
    glyph: string;
    lineBreak?: string;
    lineHeight: number;
    lineBase: number;
    fontSize: number;
    kerning: number = 0;

    xoffset: number;

    xadvance: number;

    constructor( 
        height: number, width: number, 
        anchor: number, xadvance: number, xoffset: number, 
        lineBreak: string | undefined, glyph: string, 
        fontSize: number, lineHeight: number, lineBase: number ) { 

        this.height = height;
        this.width = width;
        this.anchor = anchor;
        this.xadvance = xadvance;
        this.xoffset = xoffset;
        this.lineBreak = lineBreak;
        this.glyph = glyph;
        this.fontSize = fontSize;
        this.lineHeight = lineHeight;
        this.lineBase = lineBase;
    }
}

export class Line {
    x: number = 0;
    y: number = 0;

    width: number = 0;
    height: number = 0;

    inlines: Inline[] = [];

    lineHeight: number = 0;
    lineBase: number = 0;

    constructor(
        readonly whiteSpace: WHITE_SPACE
    ) {};
}

export class Lines {
    height: number = 0;
    width: number = 0;
    
    lines: Line[] = [ ];

    constructor(
        readonly interline: number,
    ) { };
}