import { LoginGuard } from './LoginGuard';
import type { UserAuthData } from './types';
import type { User } from 'oidc-client';
import { BindedStore } from '../utils';
import { navigate } from 'svelte-routing';

export class AuthHubStore {
    authData?: UserAuthData
}

export interface IAuthHub extends BindedStore<AuthHubStore> {
    getAuthToken(): Promise<string | null>;
    onLogOut(callback: () => void): void;
}


export class AuthHub extends BindedStore<AuthHubStore> implements IAuthHub {
    _loginGuard: LoginGuard = new LoginGuard();

    _logoutCallbacks: (() => void)[] = [];

    constructor() {
        super(new AuthHubStore());
        this._putUserInStore = this._putUserInStore.bind(this);
        this._loginGuard.checkLogin().then(this._putUserInStore);
    }

    onLogOut(callback: () => void): void {
        this._logoutCallbacks.push(callback);
    }

    logout() {
        for (const cb of this._logoutCallbacks) {
            try {
                cb();
            } catch (e) {
                console.error(e);
            }
        }
        this._loginGuard.logout();
    }

    _putUserInStore(user?: User) {
        if (!user) {
            return this.update(s => {
                s.authData = undefined;
                return s;
            })
        }
        const authData = this._transformUserToUserAuthData(user)
        const currentPathName = '/contact-us';
        if (authData.company === undefined && window.location.pathname !== currentPathName) {
            navigate(currentPathName);
        }
        this.update(s => {
            s.authData = authData;
            return s;
        })
    }

    _transformUserToUserAuthData(user: User): UserAuthData {
        const data: UserAuthData = {
            id: user.profile.sub,
            email: user.profile.email,
            name: user.profile.name,
            isAdmin: user.profile['isAdmin'] === true,
        }
        if (user.profile['company']) {
            data.company = user.profile['company'];
        }
        return data;
    }

    async getAuthToken(): Promise<string | null> {
        const user = await this._loginGuard._userManager.getUser();
        if (!user) {
            this._putUserInStore();
            return null;
        }
        return user.token_type + ' ' + user.access_token;
    }


}
