import { Object2D, VectorPrimitivePath, VectorPrimitivePathDescription, VectorPrimitiveRectangle, VectorPrimitiveText, createTextLabelWithPointer } from "vector-graphic";
import { Aabb2, Vector2 } from "math-ts";
import { arrowTemplate, labelFontSize } from "./config";
import type { SCDescription } from "../types";
import { helperLineWidth, wireWidth } from "src/sld/templates/config";

export function createSCObject2DFromDescription(cabinet: SCDescription): SCObject2D {
    const obj = new SCObject2D();
    obj.name = 'sectionalizing-cabinet'

    // add frame
    const frame = new VectorPrimitiveRectangle({
        strokeWidth: 8,
        width: 200,
        height: 200,
    })
    obj.addPrimitiveAndExpandAabb(frame);
    obj.frameAabb.copy(frame.aabb);

    // add label
    const text = new VectorPrimitiveText({
        x: 0,
        y: frame.aabb.max.y + labelFontSize,
        fontSize: labelFontSize,
        text: cabinet.text,
        anchor: 'middle',
        verticalAlignment: 'hanging',
    })
    obj.addPrimitiveAndExpandAabb(text);


    // add right-side wiring
    const aabb = obj.recalculateAabb()
    const A = new Vector2(aabb.width() * 5 / 6, 0);
    {
        const arrowStartPos = new Vector2(aabb.width() / 4);
        const arrow = arrowTemplate.clone(true);
        obj.add(arrow);
        arrow.position.copy(arrowStartPos);
        arrow.rotation = Math.PI
        arrow.updateMatrix();

        const arrowEndPoint = arrow.point.clone().applyMatrix3(arrow.matrix);

        const path = new VectorPrimitivePath({
            paths: [
                new VectorPrimitivePathDescription([new Vector2(), arrowEndPoint]),
                new VectorPrimitivePathDescription([arrowStartPos, A])
            ],
            strokeWidth: wireWidth,
        })
        obj.addPrimitiveAndExpandAabb(path);
    }

    // add connection pointer label
    if (cabinet.connectionToParent) {
        const label = createTextLabelWithPointer({
            text: cabinet.connectionToParent.text,
            fontSize: labelFontSize,
            offset: 50,
            pointerWidth: helperLineWidth,
        })
        obj.add(label);
        label.position.copy(A);
        label.updateMatrix()
    }

    obj.rightConnection.copy(A);

    obj.recalculateAabb()

    return obj;
}

export function createSCLeftSideWiring(sources: Vector2[], target: Vector2) {
    const object = new Object2D()
    // create wires
    const paths: VectorPrimitivePathDescription[] = [];
    const width = Math.abs(sources[0].x - target.x);
    for (const source of sources) {
        const pt1 = source;
        const arrowStart = new Vector2(source.x + width / 2, source.y);
        const arrow = arrowTemplate.clone(true);
        arrow.position.copy(arrowStart);
        arrow.updateMatrix()
        object.addAndExpandAabb(arrow);
        const arrowEnd = arrow.point.clone().applyMatrix3(arrow.matrix);
        const pt3 = new Vector2(target.x, arrowEnd.y);
        paths.push(new VectorPrimitivePathDescription([pt1, arrowStart]))
        paths.push(new VectorPrimitivePathDescription([arrowEnd, pt3, target]))
    }
    const wiresPrimitive = new VectorPrimitivePath({
        paths,
        strokeWidth: wireWidth,
    })
    object.addPrimitiveAndExpandAabb(wiresPrimitive);

    return object;
}


export class SCObject2D extends Object2D {
    rightConnection: Vector2
    frameAabb: Aabb2
    constructor() {
        super()
        this.rightConnection = new Vector2()
        this.frameAabb = Aabb2.empty();
    }

    static clone(
        recursive: boolean,
        source: SCObject2D,
        target?: SCObject2D
    ): SCObject2D {
        target = target ?? new SCObject2D();
        target.rightConnection.copy(source.rightConnection);
        target.frameAabb.copy(source.frameAabb);
        Object2D.clone(recursive, source, target);
        return target;
    }

    clone(recursive = false) {
        return SCObject2D.clone(recursive, this)
    }
}

