import { BasicAnalyticalRepresentation, Bim, BimGeometryType, ExtrudedPolygonGeometry, GraphGeometry, IdBimMaterial, IdBimScene, IdInEntityLocal, LocalIdsCounter, PolylineGeometry, RoadTypeIdent, SceneInstance, SceneInstances, StdGroupedMeshRepresentation, StdMeshRepresentation } from "bim-ts";
import { DefaultMap, IterUtils, RGBA, RGBAHex, ScopedLogger, Yield, convertThrow } from "engine-utils-ts";
import jsPDF, { GState } from "jspdf";
import { Aabb, Matrix4, Vector2, Vector3 } from "math-ts";
import { entityTypeFromId } from "verdata-ts";
import { calcTotalBounds, ScreenshotFocusSettings } from "./ScreenshotMaker";
import { setTextStyle } from "./TextBuilder";

export interface AnnotationDescription {
    metrics:  string[];
    fontSizes?: number[];
    aabb: Aabb;
}

export interface LayoutDrawingArgs { 
    page: jsPDF;
    imageSize: Vector2;
    position: Vector2;
    ids: IdBimScene[];
    focusSettings?: ScreenshotFocusSettings;
    annotations?: AnnotationDescription[];
    grid?: boolean;
    colorize?: (id: IdBimScene) => DrawColor;
}

export interface DrawColor {
    borderColor: RGBAHex | 0;
    backgroundColor?: RGBAHex | 0;
}
export type GridStep = { step: number, unit: string, widthLocalSystem: number };
export class LayoutDrawing {
    private logger: ScopedLogger;
    private _drawingElements: DefaultMap<IdBimScene, PdfDrawingElement>;
    private _colorTintsToRgbColor: DefaultMap<RGBAHex|0, [number, number, number]>;
    private _lastCalculatedGrid: GridStep | undefined;

    constructor(
        readonly bim: Bim,
        logger: ScopedLogger,
    ) {
        this.logger = logger.newScope("layout-drawing");
        this._colorTintsToRgbColor = new DefaultMap<RGBAHex | 0, [number, number, number]>(
            (k) => {
                if (k === 0) {
                    return [0, 0, 0];
                }
                const rgbArr = RGBA.toRgbArray(k);
                return rgbArr;
            }
        );
        this._drawingElements = new DefaultMap((id) => {
            return extractGeometry(this.bim, this.logger, id);
        });
    }

    *drawLayout(args: LayoutDrawingArgs) {
        const imageSize = calcTotalBounds(this.bim, args.focusSettings ?? { ids:args.ids }, this.logger);
        const toDraw: [IdBimScene, PdfDrawingElement][] = [];
        for (let i = 0; i < args.ids.length; i++) {
            const id = args.ids[i];
            const drawElement = this._drawingElements.getOrCreate(id);
            toDraw.push([id, drawElement]);
            if(i % 100 === 0){
                yield Yield.Asap;
            }
        }

        toDraw.sort((a, b) => a[1].renderingOrder - b[1].renderingOrder);

        const worldBoundsSize = imageSize.wsCoords.getSize();

        const worldToPageScale = Math.min(
            args.imageSize.x / worldBoundsSize.x,
            args.imageSize.y / worldBoundsSize.y
        );

        if(args.grid){
            this._drawGrid(args.page, args.imageSize, args.position, worldToPageScale);
        }

        const position = imageSize.wsCoords
            .getCenter_t()
            .sub(new Vector3(args.position.x, -args.position.y).divideScalar(worldToPageScale))
            .multiplyScalar(-1);
        const wmToPageMatrix = new Matrix4()
            .addToPosition(position)
            .premultiply(
                new Matrix4().makeScale(
                    worldToPageScale,
                    -worldToPageScale,
                    1
                )
            );
        for (let i = 0; i < toDraw.length; i++) {
            const [id, obj] = toDraw[i];
            const defaultColor = getColorFromSceneInstance(this.bim, this.bim.instances.peekById(id)!);
            const color = args.colorize?.(id);
            const borderColor = this._colorTintsToRgbColor.getOrCreate(color?.borderColor ?? defaultColor);
            const backgroundColor = color?.backgroundColor ? this._colorTintsToRgbColor.getOrCreate(color.backgroundColor) : undefined;

            obj.draw(
                args.page,
                wmToPageMatrix,
                worldToPageScale,
                {
                    borderColor,
                    backgroundColor,
                }
            );
            if(i % 100 === 0){
                yield Yield.Asap;
            }
        }

        if(args.annotations){
            this._drawAnnotations(args.page, args.annotations, wmToPageMatrix, worldToPageScale);
        }
    }

    private _drawGrid(page: jsPDF, layoutAreaSize: Vector2, position: Vector2, worldToPageScale: number){
        const worldSize = layoutAreaSize.maxComponent() / worldToPageScale;
        const grid = findOptimalStepForGrid(worldSize, worldToPageScale, this.bim.unitsMapper.isImperial());
        const step = grid.widthLocalSystem;
        const startX = (layoutAreaSize.x % step) / 2 - layoutAreaSize.x / 2  + position.x;
        const startY = (layoutAreaSize.y % step) / 2 - layoutAreaSize.y / 2  + position.y;

        page.setLineWidth(0.1);
        page.setFillColor(16, 58, 82);
        page.setGState(new GState({opacity: 0.08})); 

        for (let x = startX; x < position.x + layoutAreaSize.x / 2; x += step) { 
            page.moveTo(x, -layoutAreaSize.y / 2 + position.y);
            page.lineTo(x, layoutAreaSize.y / 2 + position.y);
            page.fill();
        }

        for (let y = startY; y < position.y + layoutAreaSize.y / 2; y += step) { 
            page.moveTo(-layoutAreaSize.x / 2 + position.x, y);
            page.lineTo(layoutAreaSize.x / 2 + position.x, y);
            page.fill();
        }

        page.setGState(new GState({opacity: 1})); 
        
        this._lastCalculatedGrid = grid
    }

    getLastCalculatedGrid() {
        return this._lastCalculatedGrid;
    }

    private _drawAnnotations(
        page: jsPDF,
        annotations: AnnotationDescription[], 
        wmToPageMatrix: Matrix4,
        wmToPageScale: number
    ){
        const defaultFontSizes = [70, 30, 35];
        const reused = Aabb.empty();
        page.setDrawColor(255, 255, 255);
        page.setFillColor(0, 0, 0);
        const scaleFont = 3 * wmToPageScale;
        for (const annotation of annotations) {
            const localAabb = reused.copy(annotation.aabb).applyMatrix4(wmToPageMatrix);
            let totalHeight = 0;
            let maxWidth = 0;
            let maxHeight = 0;
            const rows = annotation.metrics.map((m, idx) => {
                const fontSizes = annotation.fontSizes ?? defaultFontSizes;
                const fontSize = idx === 0 ? fontSizes[0] : idx === annotation.metrics.length - 1 ? fontSizes[2] : fontSizes[1];
                const localFontSize = fontSize * scaleFont;
                const dimensions = page.getTextDimensions(m, {fontSize: localFontSize});
                totalHeight += dimensions.h;
                maxWidth = Math.max(maxWidth, dimensions.w);
                maxHeight = Math.max(maxHeight, dimensions.h);
                return {
                    text: m,
                    fontSize: localFontSize,
                    dimensions: dimensions
                };
            });
            const offset = 5 * wmToPageScale;
            totalHeight += (rows.length - 1) * offset;

            const maxTotalHeight = 13;

            const fontSizeMultiplier = localAabb.width() > maxWidth && maxHeight <= maxTotalHeight && localAabb.depth() > totalHeight
                ? 1 
                : Math.min(localAabb.width() / maxWidth, maxTotalHeight / maxHeight, localAabb.depth() / totalHeight);
            const x = localAabb.centerX();
            let y = localAabb.centerY() - totalHeight / 2 * fontSizeMultiplier;
            for (const row of rows) {
                const fontSize = row.fontSize * fontSizeMultiplier;
                setTextStyle(page, "bold", fontSize);
                page.setLineWidth(0.07 * fontSize * wmToPageScale);
                page.text(row.text, x, y, {align: "center", baseline: "top", renderingMode: 'fillThenStroke'});
                const dimensions = page.getTextDimensions(row.text, {fontSize});
                y += dimensions.h + offset * fontSizeMultiplier;
            }
        }
    }
}

function extractGeometry(bim: Bim, logger: ScopedLogger, id: IdBimScene): PdfDrawingElement {
    const geosAabbs = bim.allBimGeometries.aabbs.poll();
    const inst = bim.instances.peekById(id);
    if(!inst){
        throw new Error(`Instance with id ${id} not found`);
    }
    
    const r = inst.representationAnalytical ?? inst.representation;
    let objectToDraw: PdfDrawingElement | undefined = undefined;
    const name = SceneInstances.uiNameFor(id, inst);

    let width = 0.1;
    if (inst.type_identifier === RoadTypeIdent) {
        width = inst.properties.get("road | width")?.as("m") ?? 5;
    }

    if (
        r instanceof StdGroupedMeshRepresentation ||
        r instanceof StdMeshRepresentation
    ) {
        let localAabb: Aabb;

        // trackers should have lods
        if (r.lod1) {
            // find out
            localAabb = r.lod1.aabb(geosAabbs);
        } else {
            localAabb = r.aabb(geosAabbs);
        }

        objectToDraw = new EquipmentBboxDrawing(
            name,
            localAabb,
            inst.worldMatrix.clone(),
            logger
        );
    } else if (r instanceof BasicAnalyticalRepresentation) {
        if (
            entityTypeFromId(r.geometryId) === BimGeometryType.Polyline
        ) {
            const geo = bim.polylineGeometries.peekById(
                r.geometryId
            )!;
            objectToDraw = new PolylineDrawing(
                name,
                r.aabb(geosAabbs),
                inst.worldMatrix.clone(),
                geo,
                logger,
                width
            );
        } else if (
            entityTypeFromId(r.geometryId) === BimGeometryType.GraphGeometry
        ) {
            const geo = bim.graphGeometries.peekById(
                r.geometryId
            )!;

            objectToDraw = new GraphDrawing(
                name,
                r.aabb(geosAabbs),
                inst.worldMatrix.clone(),
                geo,
                logger,
                width
            );
        } else if (
            entityTypeFromId(r.geometryId) ===
            BimGeometryType.ExtrudedPolygon
        ) {
            const geo = bim.extrudedPolygonGeometries.peekById(
                r.geometryId
            )!;
            const points = new Float64Array(
                geo.outerShell.points.length * 3
            );
            for (let i = 0; i < geo.outerShell.points.length; ++i) {
                points[i * 3 + 0] = geo.outerShell.points[i].x;
                points[i * 3 + 1] = geo.outerShell.points[i].y;
            }
            objectToDraw = new ExtrudedPolygonDrawing(
                name,
                r.aabb(geosAabbs),
                inst.worldMatrix.clone(),
                geo,
                logger,
            );
        } else {
            logger.error(`Unsupported geometry type ` + entityTypeFromId(r.geometryId), r);
            throw new Error(`Unsupported geometry type ${r}`);
        }
    } else {
        logger.error(`Unknown representation type`, r);
        throw new Error(`Unknown representation type ${r}`);
    }

    return objectToDraw;
}


interface PdfElementColor {
    borderColor: [number, number, number];
    backgroundColor?: [number, number, number];
}
abstract class PdfDrawingElement {
    constructor(
        readonly name: string,
        readonly localAabb: Aabb,
        readonly worldMatrix: Matrix4,
        readonly renderingOrder: number,
    ) {}

    abstract draw(
        page: jsPDF,
        wmToPageMatrix: Matrix4,
        wmToPageScale: number,
        color: PdfElementColor
    ): void;
}



class EquipmentBboxDrawing extends PdfDrawingElement {
    constructor(
        name: string,
        localAabb: Aabb,
        worldMatrix: Matrix4,
        readonly logger: ScopedLogger,
    ) {
        super(name, localAabb, worldMatrix, 50);
    }

    draw(page: jsPDF, wmToSvgMatrix: Matrix4, wmToPageScale: number, color: PdfElementColor): void {
        try {
            const corners = this.localAabb.get2DCornersAtZ(0);
            for (const p of corners) {
                p.applyMatrix4(this.worldMatrix).applyMatrix4(wmToSvgMatrix);
            }
    
            const [r, g, b] = color.backgroundColor ?? color.borderColor;
            page.setFillColor(r, g, b);
            page.setLineWidth(wmToPageScale * 0.1);
            for (let i = 0; i <= corners.length; i++) {
                const corner = corners[i % corners.length];
                if(i === 0){ 
                    page.moveTo(corner.x, corner.y);
                } else {
                    page.lineTo(corner.x, corner.y);
                }
            }
            page.close();
            page.fill();
        } catch (e) {
            this.logger.error(e);
        }
        if (this.localAabb.isEmpty()) {
            return;
        }
    }
}

class PolylineDrawing extends PdfDrawingElement {
    private readonly _vReused = new Vector3();

    constructor(
        name: string,
        localAabb: Aabb,
        worldMatrix: Matrix4,
        readonly geo: PolylineGeometry,
        readonly logger: ScopedLogger,
        readonly width: number,
    ) {
        super(name, localAabb, worldMatrix, 20);
    }

    draw(page: jsPDF, wmToPageMatrix: Matrix4, wmToPageScale: number, color: PdfElementColor): void {
        try {
            if (this.geo.points3d.length < 3 * 2) {
                return;
            }
            const [r, g, b] = color.borderColor;
            page.setDrawColor(r, g, b);
            page.setLineWidth(wmToPageScale * 0.1);
            for (let i = 0; i < this.geo.points3d.length; i += 3) {
                this._vReused.setFromArray(this.geo.points3d, i);
                this._vReused.applyMatrix4(this.worldMatrix).applyMatrix4(wmToPageMatrix);
                if (i === 0) {
                    page.moveTo(this._vReused.x, this._vReused.y);
                } else {
                    page.lineTo(this._vReused.x, this._vReused.y);
                }
            }
            page.stroke();
        } catch (e) {
            this.logger.error(e);
        }
    }
}

class ExtrudedPolygonDrawing extends PdfDrawingElement {
    private _vReused = new Vector3();

    constructor(
        name: string,
        localAabb: Aabb,
        worldMatrix: Matrix4,
        readonly geo: ExtrudedPolygonGeometry,
        readonly logger: ScopedLogger,
    ) {
        super( name, localAabb, worldMatrix, 0);
    }

    draw(page: jsPDF, wmToPageMatrix: Matrix4, wmToPageScale: number, color: PdfElementColor): void {
        if (this.geo.outerShell.points.length < 3) {
            return;
        }
        try {
            const width = color.backgroundColor ? 0.6 : 0.3;
            page.setLineWidth(width);
            const [r, g, b] = color.borderColor;
            page.setDrawColor(r, g, b);
            this._drawPolygon(page, wmToPageMatrix);
            page.stroke();

            if(color.backgroundColor){
                this._drawPolygon(page, wmToPageMatrix);
                const [r, g, b] = color.backgroundColor;
                page.setFillColor(r, g, b);
                page.fill();
            }
        } catch (e) {
            this.logger.error(e);
        }
    }

    private _drawPolygon(page: jsPDF, wmToPageMatrix: Matrix4): void { 
        for (let i = 0; i <= this.geo.outerShell.points.length; i += 1) {
            const p2d = this.geo.outerShell.points[i % this.geo.outerShell.points.length];
            this._vReused.set(p2d.x, p2d.y, 0);
            this._vReused.applyMatrix4(this.worldMatrix).applyMatrix4(wmToPageMatrix);
            if (i === 0) {
                page.moveTo(this._vReused.x, this._vReused.y);
            } else {
                page.lineTo(this._vReused.x, this._vReused.y);
            }
        }
        page.close();
    }
}

class GraphDrawing extends PdfDrawingElement {
    private readonly _vReused1 = new Vector3();
    private readonly _vReused2 = new Vector3();

    constructor(
        name: string,
        localAabb: Aabb,
        worldMatrix: Matrix4,
        readonly geo: GraphGeometry,
        readonly logger: ScopedLogger,
        readonly width: number,
    ) {
        super( name, localAabb, worldMatrix, 20);
    }

    draw(page: jsPDF, wmToPageMatrix: Matrix4, wmToPageScale: number, color: PdfElementColor): void {
        if (this.geo.points.size < 2) {
            return;
        }
        try {
            const [r, g, b] = color.borderColor;
            page.setDrawColor(r, g, b);
            page.setLineWidth(wmToPageScale * this.width);
            let i = 0;
            const prevEdge = new Set<IdInEntityLocal>();
            for (const [p1, p2, edge] of this.geo.iterEdgesPoints()) {
                const currentEdgePoints = LocalIdsCounter.edgeToTuple(edge);
                if(prevEdge.size > 0 && !IterUtils.intersectsSet(prevEdge, currentEdgePoints)){ 
                    i = 0;
                    page.stroke();
                    prevEdge.clear();
                }
                this._vReused1.set(p1.x, p1.y, p1.z);
                this._vReused1.applyMatrix4(this.worldMatrix).applyMatrix4(wmToPageMatrix);
                this._vReused2.set(p2.x, p2.y, p2.z);
                this._vReused2.applyMatrix4(this.worldMatrix).applyMatrix4(wmToPageMatrix);
                if (i === 0) {
                    page.moveTo(this._vReused1.x, this._vReused1.y);
                    page.lineTo(this._vReused2.x, this._vReused2.y);
                } else {
                    page.lineTo(this._vReused1.x, this._vReused1.y);
                    page.lineTo(this._vReused2.x, this._vReused2.y);
                }
                i++;
                prevEdge.clear();
                IterUtils.extendSet(prevEdge, currentEdgePoints);
            }
            page.stroke();
        } catch (e) {
            this.logger.error(e);
        }
    }
}

function getColorFromSceneInstance(bim: Bim, instance: SceneInstance): RGBAHex | 0 {
    if (instance.colorTint) {
        return instance.colorTint;
    }
    let materialId: IdBimMaterial | 0 = 0;
    if (
        instance.representation instanceof StdMeshRepresentation ||
        instance.representation instanceof StdGroupedMeshRepresentation
    ) {
        if (instance.representation.lod1) {
            materialId =
                instance.representation.lod1.submeshes[0].materialId;
        }
    }
    if (materialId) {
        const material = bim.bimMaterials.peekById(materialId);
        if (material?.stdRenderParams.color) {
            return RGBA.parseFromHexString(material.stdRenderParams.color);
        }
    }
    return 0;
}


function findOptimalStep(maxSide: number) {
    const targetSegments = 25;
    const minSegments = 15;
    const maxSegments = 35;
    
    const steps = [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 50000, 100000, 200000, 500000, 1000000];
    
    let idealStep = maxSide / targetSegments;
    
    function findClosestStep(ideal: number) {
        let closest = steps[0];
        for (let i = 1; i < steps.length; i++) {
            if (Math.abs(steps[i] - ideal) < Math.abs(closest - ideal)) {
                closest = steps[i];
            }
        }
        return closest;
    }
    
    let roundedStep = findClosestStep(idealStep);
    
    let segments = maxSide / roundedStep;
    
    if (segments < minSegments) {
        while (segments < minSegments) {
            let currentIndex = steps.indexOf(roundedStep);
            if (currentIndex > 0) {
                roundedStep = steps[currentIndex - 1];
            } else {
                break;
            }
            segments = maxSide / roundedStep;
        }
    } else if (segments > maxSegments) {
        while (segments > maxSegments) {
            let currentIndex = steps.indexOf(roundedStep);
            if (currentIndex < steps.length - 2) {
                roundedStep = steps[currentIndex + 1];
            } else {
                break; 
            }
            segments = maxSide / roundedStep;
        }
    }
    // console.log(`Ideal step: ${idealStep}, rounded step: ${roundedStep}`, maxSide, segments);
    return roundedStep;
}

export function findOptimalStepForGrid(maxWorldSizeMeter: number, worldToPageScale: number, isImperial: boolean): GridStep {
    const maxSide = isImperial 
        ? convertThrow(maxWorldSizeMeter, "m", "ft")
        : maxWorldSizeMeter;
    const stepWorld = findOptimalStep(maxSide);
    const step = isImperial 
        ? convertThrow(stepWorld, "ft", "m") * worldToPageScale 
        : stepWorld * worldToPageScale;

    return {
        step: stepWorld,
        unit: isImperial ? "ft" : 'm',
        widthLocalSystem: step, 
    }
}
