<script lang="ts">
import { ObjectUtils } from "engine-utils-ts";
import OptionalNumAndUnit from "../../components/OptionalNumAndUnit.svelte";
import PathEditor from "../../components/PathEditor.svelte";
import { Action } from "../Action";
import { OverrideTypeIdentifier, Override } from "./Override";

export let exit: (action?: Action<Override>) => void;
export let action: Action<Override> | undefined;

let editableAction = action
    ? ObjectUtils.deepCloneObj(action)
    : new Action('override action', OverrideTypeIdentifier, new Override());

function save() {
    if (action) {
        Object.assign(action, editableAction);
        return exit();
    }

    return exit(editableAction);
}
</script>

<a class="basic-interact" on:click={() => exit()}>{'<'}</a>

<h3>override editor</h3>

<p>disable</p>
<input bind:checked={editableAction.disable} type="checkbox" />

<p>name</p>
<input bind:value={editableAction.name} />

<p>path</p>
<PathEditor bind:path={editableAction.props.path} />

<p>topLevel</p>
<input bind:value={editableAction.props.entry.path[0]} />

<p>rate</p>
<OptionalNumAndUnit
    num={editableAction.props.entry.rate}
    change={(val) => { editableAction.props.entry.rate = val }}
/>

<p>quantity</p>
<OptionalNumAndUnit
    num={editableAction.props.entry.quantity}
    change={(val) => { editableAction.props.entry.quantity = val }}
/>

<p>total cost</p>
<OptionalNumAndUnit
    num={editableAction.props.entry.totalCost}
    change={(val) => { editableAction.props.entry.totalCost = val }}
/>
<br/>
<br/>

<a class="basic-interact" on:click={save}>Done</a>
