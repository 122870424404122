<script lang="ts">
    import PropertyHelp from "../../pui/PropertyHelp.svelte";
    import RadioButtonComponent from "./RadioButton.svelte";
    import RadioButtonOption from "./RadioButtonOption.svelte";
    import type { RadioButton } from "./types";

    export let groupName: string;
    export let options: RadioButton[];
    export let value: string | number;
</script>

<div class="radio-buttons-container">
    {#each options as option}
        <div class="radio-button-option">
            <RadioButtonComponent
                {groupName}
                input={option}
                bind:formValue={value}
            />
            {#if option.tooltip}
                <PropertyHelp
                    content={option.tooltip}
                    name={groupName}
                />  
            {/if}
        </div>
        {#if option.option}
            <RadioButtonOption
                name={option.option.label}
                readonly={option.disabled || value !== option.value || option.option.disabled}
                value={option.option.value}
                onClickCheckbox={option.option.onClick}
            />
        {/if}
    {/each}
</div>

<style>
    .radio-button-option {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .radio-buttons-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
</style>
