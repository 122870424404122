import { Collection } from './collection';
import type { Connection, ConnectionId } from './connection';

export class Connections extends Collection<Connection, ConnectionId> {
    add(request: Connection) {
        if (isNaN(request.id))
            request.id = this.genId();
        this.items.set(request.id, request);
        return request;
    }
}
