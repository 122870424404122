<script lang="ts">
    import GroupNode from './GroupNode.svelte';
    import type { PUI_Lazy } from 'ui-bindings';
    import { createPUIStore } from './PUI_StoreCtor';
    import { createUiState } from './PUI_RuntimeState'
    import { onDestroy } from 'svelte';
    import ActionProperty from './ActionProperty.svelte';
    import { VersionedStore } from '../VersionedStore';

    export let configIdentifier: string;
    export let inDialog: boolean = false;

    export const header: string = configIdentifier;

    export let puiSource: PUI_Lazy<any>;

    $: uiContext = puiSource?.createContext ? puiSource.createContext(): undefined;
    $: ui = typeof puiSource.ui === 'function'? puiSource.ui(uiContext): puiSource.ui;

    $: config = createPUIStore(configIdentifier, ui);

    const persistentUiState = createUiState();
    const store = persistentUiState.store;
    $: actionContext = puiSource.defaultMessage?.action ? new VersionedStore(puiSource.defaultMessage.action.context) : undefined;
        
    onDestroy(() => {
        if(puiSource.disposeContext){
            puiSource.disposeContext(uiContext);
        }
    });
</script>

<style lang="scss">
    .placeholder-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: inherit;
        padding: 0 16px;
        p {
            text-align: center;
        }
    }
    .group-holder {
        padding: 0 16px;
    }
    .in-dialog {
        min-width: 400px;
    }
    .container {
        width: 100%;
        right: 0;
        left: 0;
        display: block;
        background-color: white;
    }
</style>


<div
    class="container"
    class:absolute={!inDialog}
    class:in-dialog={inDialog}
>
    {#if $config.children.size == 0}
        <div class="placeholder-container">
            {#if puiSource?.defaultMessage?.message !== undefined}
                <p class="mdc-typography--body1 text-main-light">
                    {@html puiSource.defaultMessage.message}
                </p>
            {/if}
            {#if puiSource?.defaultMessage?.action !== undefined && actionContext !== undefined && $actionContext !== undefined }
                <ActionProperty
                    bind:actionDescr = { puiSource.defaultMessage.action.actionDescr }
                    context = { $actionContext }
                />
            {/if}
        </div>
    {:else}
        <div class="group-holder">
            <GroupNode uiState={store} group={$config} depth = {0}/>
        </div>
    {/if}
</div>