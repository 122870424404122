<script lang="ts">
    import type { PUI_FailureNode } from 'ui-bindings';

    export let property: PUI_FailureNode;
    
</script>

<div class="ui-config-property property-row bool-prop">
    <div class="property-name text-main-medium">
        <div class="property-name-label mdc-typography--body1 red-underline">
            {property.name}
        </div>
    </div>

    <div class="property-name-label mdc-typography--body1 red-underline">
        FAILED
    </div>
</div>

<style lang="css">
    .property-row {
        margin: 4px 0;
    }
    .red-underline {
        text-decoration: underline;
        text-decoration-color: red;
    }
</style>