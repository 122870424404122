import { Failure, WorkerClassPassRegistry, type Result, type ResultAsync, Success, LegacyLogger, IterUtils } from 'engine-utils-ts';
import { EnergyErrorMsgs } from './EnergyErrorMsgs';
import { EnergyStageUiWarning, EnergyStageAction, EnergyStageUiActionMerged } from './EnergyStageWarning';
import { type Bim, type IdBimScene } from '..';
import type { UiBindings } from 'ui-bindings';

export type EnergyResult<T> = Result<T, EnergyFailure>;
export type EnergyResultAsync<T> = ResultAsync<T, EnergyFailure>;

export function energySucess<T>(value: T, warnings?: EnergyFailure[]): Success<T, EnergyFailure> {
    return new Success(value, warnings);
}



export class EnergyFailure extends Failure {
    readonly errorMsgIdent: keyof typeof EnergyErrorMsgs;
    readonly _actions?: EnergyStageAction[];
    readonly _affectedInstanceIds?: IdBimScene[];
    
    constructor(args: {
        msg?: string,
        uiMsg?: string,
        inner?: Failure[],
        exception?: Error,
        errorMsgIdent: keyof typeof EnergyErrorMsgs,
        actions?: EnergyStageAction[]|EnergyStageAction,
        affectedInstanceIds?: IdBimScene[],
    }) {
        super(args);
        this.errorMsgIdent = args.errorMsgIdent;
        if (args.actions) {
            this._actions = Array.isArray(args.actions) ? args.actions : [args.actions];
        }
        this._affectedInstanceIds = args.affectedInstanceIds;
        Object.freeze(this);
    }

    equalExceptIds(e: EnergyFailure): boolean {
        if (this.errorMsgIdent != e.errorMsgIdent
            || this.msg != e.msg
            || this.uiMsg != e.uiMsg
        ) {
            return false;
        }
        if (!(IterUtils.areOptionalArraysEqual(this._actions, e._actions))) {
            return false;
        }
        if (!(IterUtils.areOptionalArraysEqual(this.inner, e.inner))) {
            return false;
        }
        return true;
    }

    static merged(errors: EnergyFailure[]): EnergyFailure {
        if (errors.length === 0) {
            throw new Error('errors is empty');
        }
        if (errors.length === 1) {
            return errors[0];
        }
        const mergedErrors = new Map<keyof typeof EnergyErrorMsgs, EnergyFailure[]>();
        for (const error of errors) {
            error._mergeFailuresByIdent_R(mergedErrors);
        }
        const mergedErrorsArr = Array.from(mergedErrors.values());
        const mergedErrorsFlat = mergedErrorsArr.flat();

        return new EnergyFailure({
            errorMsgIdent: mergedErrorsFlat[0].errorMsgIdent,
            actions: mergedErrorsFlat[0]._actions,
            affectedInstanceIds: mergedErrorsFlat[0]._affectedInstanceIds,
            inner: mergedErrorsFlat.slice(1),
        });
    }

    private static _flatmapActions(errors: EnergyFailure[]): EnergyStageAction[] {
        const actions: EnergyStageAction[] = [];
        for (const error of errors) {
            if (error._actions) {
                actions.push(...error._actions);
            }
        }
        return actions;
    }
    private static _flatmapAffectedInstanceIds(errors: EnergyFailure[]): IdBimScene[] {
        const ids: IdBimScene[] = [];
        for (const error of errors) {
            if (error._affectedInstanceIds) {
                ids.push(...error._affectedInstanceIds);
            }
        }
        return ids;
    }

    static new(error: keyof typeof EnergyErrorMsgs, actions?: EnergyStageAction|EnergyStageAction[], affectedInstanceIds?: IdBimScene[]): EnergyFailure {
        return new EnergyFailure({errorMsgIdent: error, actions, affectedInstanceIds});
    }

    withAffectedInstancesIds(ids: IdBimScene[]|undefined): EnergyFailure {
        if (ids == undefined || ids.length === 0) {
            return this;
        }
        if (this._affectedInstanceIds && this._affectedInstanceIds.length > 0) {
            LegacyLogger.deferredError('EnergyFailure.withAffectedInstancesIds', 'affectedInstanceIds already set');
        }
        return new EnergyFailure({
            msg: this.msg,
            uiMsg: this.uiMsg,
            inner: this.inner,
            exception: this.exception,
            errorMsgIdent: this.errorMsgIdent,
            actions: this._actions,
            affectedInstanceIds: ids,
        });
    }

    toEnergyStageWarnings(type: 'error'|'warning'): EnergyStageUiWarning[] {
        const mergedErrors = new Map<keyof typeof EnergyErrorMsgs, EnergyFailure[]>();
        this._mergeFailuresByIdent_R(mergedErrors);

        return IterUtils.mapIter(mergedErrors.entries(), ([key, errors]) => {
            const actionsByName: Map<string,  {action: EnergyStageAction, instancesIds: IdBimScene[]}[]> = new Map();
            const allAffectedInstancesIds: IdBimScene[] = [];
            for (const e of errors) {
                if (e._actions) {
                    for (const action of e._actions) {
                        let actions = actionsByName.get(action.name);
                        if (!actions) {
                            actions = [];
                            actionsByName.set(action.name, actions);
                        }
                        actions.push({action, instancesIds: e._affectedInstanceIds ?? []});
                    }
                }
                if (e._affectedInstanceIds) {
                    allAffectedInstancesIds.push(...e._affectedInstanceIds);
                }
            };
            const resActions: EnergyStageUiActionMerged[] = [];
            for (const [key, actions] of actionsByName) {
                resActions.push(new EnergyStageUiActionMerged(actions));
            }
            if (allAffectedInstancesIds.length > 0) {
                resActions.push(new EnergyStageUiActionMerged([{
                    action: new SelectAffectedInstancesAction('select affected'), instancesIds: allAffectedInstancesIds
                }]));
            }
            let errorMsg = EnergyErrorMsgs[key];
            if (typeof errorMsg !== 'string') {
                errorMsg = errorMsg.uiMsg;
            }
            return new EnergyStageUiWarning(
                errorMsg,
                type,
                resActions,
            );
        });
    }

    private _mergeFailuresByIdent_R(output: Map<keyof typeof EnergyErrorMsgs, EnergyFailure[]>) {
        let outputArr = output.get(this.errorMsgIdent);
        if (!outputArr) {
            outputArr = [];
            output.set(this.errorMsgIdent, outputArr);
        }
        outputArr.push(this);
        if (this.inner?.length) {
            for (const inner of this.inner) {
                if (inner instanceof EnergyFailure) {
                    inner._mergeFailuresByIdent_R(output);
                } else {
                    LegacyLogger.deferredWarn('energy: unexpected failure type to merge with', inner);
                }
            }
        }
    }

    equals(other: EnergyFailure): boolean {
        return this.errorMsgIdent === other.errorMsgIdent;
    }
}
WorkerClassPassRegistry.registerClass(EnergyFailure);

export class TrackerConnectionFailure extends EnergyFailure {}
WorkerClassPassRegistry.registerClass(TrackerConnectionFailure);

class SelectAffectedInstancesAction extends EnergyStageAction {
    constructor(
        name: string,
    ) {
        super(name);
    }
    execute(bim: Bim, uiBindings: UiBindings, ids: IdBimScene[]): void {
        bim.instances.setSelected(ids);
    }
}
WorkerClassPassRegistry.registerClass(SelectAffectedInstancesAction);

