import { Vector4 } from 'math-ts';
import { NormalBlending } from '../3rdParty/three'
import type { ShaderBase } from './ShaderBase';

export const OverlayBlendShader: ShaderBase = {

	name: 'overlay-blend',
	
	uniforms: {
        "tDiffuse": { value: null },
        "colorMultiplier": { value: new Vector4(1, 1, 1, 1) },
	},

	defines: {
    },
    
    params: {
        depthWrite: false,
        depthTest: false,
        blending: NormalBlending,
        transparent: true,
        premultipliedAlpha: true
    },

	vertexShader: `

	
		varying vec2 vUv;

		varying vec2 size;
		
		void main() {
			vUv = uv;
        	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }

	`,

	fragmentShader: `

        uniform sampler2D tDiffuse;
        uniform vec4 colorMultiplier;

        varying vec2 vUv;

        void main() {
            vec4 texel = texture2D( tDiffuse, vUv ).rgba;
            gl_FragColor = colorMultiplier * texel;
        }

	`
};