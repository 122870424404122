import { CostModel as CM } from 'bim-ts';
import { ColDef, ValueGetterFunc, ValueParserFunc, ValueSetterParams } from "ag-grid-enterprise";
import { canEditCommonCell, getContext, numberValueFormatter } from './common';
import { commonCellClasses } from './common';


export function createLaborPerUnitColumnDefinition(): ColDef<CM.FlattenedCostCategoryParams> {
    return {
        colId: CM.LaborPerUnitColId,
        headerName: CM.LaborPerUnitColId,
        cellEditor: 'agNumberCellEditor',

        valueGetter: ((params) => {
            const context = getContext(params);
            const value = context.category?.labor?.laborPerUnit?.value;
            if (value === undefined) {
                return;
            }
            return +value.toFixed(3);
        }) as ValueGetterFunc<CM.FlattenedCostCategoryParams, number>,

        valueFormatter: numberValueFormatter,

        valueParser: ((params) => {
            const newValue = Number.parseFloat(params.newValue)
            if (!Number.isFinite(newValue)) {
                return null;
            }
            return newValue;
        }) as ValueParserFunc<CM.FlattenedCostCategoryParams, number>,

        editable: (params) => {
            const context = getContext(params);
            if (!canEditCommonCell(context, context.category?.labor?.laborPerUnit)) {
                return false;
            }
            return !!context.category?.labor?.laborPerUnit?.update;
        },

        valueSetter: (params: ValueSetterParams<CM.FlattenedCostCategoryParams, number>) => {
            const context = getContext(params);
            const mutate = context.category?.labor?.laborPerUnit?.update;
            if (!mutate) {
                return false;
            }
            mutate(params.newValue ?? undefined);
            //if (context.category?.labor?.laborPerUnit) {
            //    context.category.labor.laborPerUnit.value = params.newValue ?? 0;
            //}
            return true;
        },

        cellClass: (params) => commonCellClasses(getContext(params), x => x.labor?.laborPerUnit),

        headerClass: [
            'ag-grid-header-align-right'
        ],

    }
}
