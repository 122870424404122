import { gunzipSync, gzipSync, unzipSync, zipSync, type ZipOptions } from 'fflate';

import { ErrorUtils } from './ErrorUtils';
import { ExecutionThreadPreference, JobExecutor, registerExecutor } from './workers/JobExecutor';

export class CompressionUtils {    
    static unzip(data: Uint8Array | ArrayBuffer): Map<string, Uint8Array>{
        if (data instanceof Uint8Array) {
            const unzipped = unzipSync(new Uint8Array(data)); 
            
            return new Map(Object.entries(unzipped));
        } else if (data instanceof ArrayBuffer) {
            const unzipped = unzipSync(new Uint8Array(data)); 
            
            return new Map(Object.entries(unzipped));

        } else {
            throw new Error('gzip invalid argument type');
        }
    }

    static zip(files:{name:string, file:Uint8Array}[], opts?: ZipOptions){
        const data:{[key:string]: Uint8Array}={};
        for (const file of files) {
            data[file.name] = file.file;
        }
        return zipSync(data, opts);
    }

    static gzip(data: Uint8Array | ArrayBuffer): Uint8Array {
        if (data instanceof Uint8Array) {
            return gzipSync(data);
        } else if (data instanceof ArrayBuffer) {
            return gzipSync(new Uint8Array(data));
        } else {
            throw new Error('gzip invalid argument type');
        }
    }

    static gunzip(data: Uint8Array | ArrayBuffer): Uint8Array {
        if (data instanceof Uint8Array) {
            return gunzipSync(data);
        } else if (data instanceof ArrayBuffer) {
            return gunzipSync(new Uint8Array(data));
        } else {
            ErrorUtils.logThrow('gunzip invalid argument type', data);
        }
    }
}

export class GzipExecutor extends JobExecutor<Uint8Array, Uint8Array> {
    execute(args: Uint8Array | ArrayBuffer): Uint8Array {
        return CompressionUtils.gzip(args);
    }
    executionPreference(data: Uint8Array): ExecutionThreadPreference {
        if (data.byteLength < 1024 * 10) {
            return ExecutionThreadPreference.None;
        }
        return ExecutionThreadPreference.WorkerThread;
    }
    estimateTaskDurationMs(data: Uint8Array) {
        return data.byteLength / 1_000;
    }
    transferToWorker(args: Uint8Array): (ArrayBuffer)[] | null {
        return [args.buffer];
    }
}
registerExecutor(GzipExecutor);


export class GunzipExecutor extends JobExecutor<Uint8Array, Uint8Array> {
    execute(args: Uint8Array | ArrayBuffer): Uint8Array {
        return CompressionUtils.gunzip(args);
    }
    executionPreference(data: Uint8Array): ExecutionThreadPreference {
        if (data.byteLength < 1024 * 10) {
            return ExecutionThreadPreference.None;
        }
        return ExecutionThreadPreference.WorkerThread;
    }
    estimateTaskDurationMs(data: Uint8Array) {
        return data.byteLength / 5_000;
    }
    transferToWorker(args: Uint8Array): (ArrayBuffer)[] | null {
        return [args.buffer];
    }
}
registerExecutor(GunzipExecutor);
