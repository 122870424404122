// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

export class ImportFile {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ImportFile {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsImportFile(bb:flatbuffers.ByteBuffer, obj?:ImportFile):ImportFile {
  return (obj || new ImportFile()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsImportFile(bb:flatbuffers.ByteBuffer, obj?:ImportFile):ImportFile {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ImportFile()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

importPath():string|null
importPath(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
importPath(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startImportFile(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addImportPath(builder:flatbuffers.Builder, importPathOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, importPathOffset, 0);
}

static endImportFile(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createImportFile(builder:flatbuffers.Builder, importPathOffset:flatbuffers.Offset):flatbuffers.Offset {
  ImportFile.startImportFile(builder);
  ImportFile.addImportPath(builder, importPathOffset);
  return ImportFile.endImportFile(builder);
}
}
