import type { TriGeometries} from 'bim-ts';
import { TriGeometry } from 'bim-ts';
import type { ScopedLogger , Result } from 'engine-utils-ts';
import { IterUtils, Success } from 'engine-utils-ts';
import type { Plane, Transform, Vector3 } from 'math-ts';
import { Aabb, EmptyTransform, Matrix4 } from 'math-ts';

import { BimEngineGeometriesSyncBase } from '../resources/BimGeometriesSync';
import { EngineGeoType } from './AllEngineGeometries';
import { EngineGeometry } from "./EngineGeometry";
import { GeometryGenerator } from './GeometryGenerator';
import { CreateKrGeometryFromData, GeometryUtils, IntersectionType } from './GeometryUtils';
import type { GeometryGpuRepr } from './KrBufferGeometry';
import { KrEdgedGeoEmpty } from './KrBufferGeometry';

const EmptryTriGeo = new TriGeometry();

export class EngineGeoTriangleBase extends EngineGeometry {
	calcAabb(): Aabb {
		console.error('requesting aabb from triangle base stub is a mistake');
		return Aabb.empty();
	}

	_calcGpuRepr(): GeometryGpuRepr {
		console.error('requesting gpu geo from triangle base stub is a mistake');
		return KrEdgedGeoEmpty;
	}
}

export class EngineGeoTriangle extends EngineGeoTriangleBase {


    constructor(
		public readonly sourceGeo: TriGeometry = EmptryTriGeo,
    ) {
		super();
    }

	calcAabb(): Aabb {
		return this.sourceGeo.calcAabb();
	}

	_calcGpuRepr(): GeometryGpuRepr {
		return CreateKrGeometryFromData({
            indices: this.sourceGeo.indices,
            edgesInds: this.sourceGeo.edgeindices,
            normals: this.sourceGeo.normals.length ? this.sourceGeo.normals : undefined,
            vertices: this.sourceGeo.positions,
            uvs: this.sourceGeo.uvs
        });
	}

	intersectPlanes(planes: Plane[]): IntersectionType {
		return GeometryUtils.checkPlanesVolumeIntersection(this.sourceGeo.positions, this.sourceGeo.indices, planes);
	}
}

export class EngineGeoTriangleMerged extends EngineGeoTriangleBase {

    constructor(
		public readonly sources: {geo: EngineGeometry, tr: Transform | null}[],
    ) {
		super();
    }

	calcAabb(): Aabb {
		const resultAabb = Aabb.empty();
		for (const {geo, tr} of this.sources) {
			const aabb = geo.calcAabb();
			if (tr) {
				aabb.applyTransform(tr);
			}
			resultAabb.union(aabb);
		}
		return resultAabb;
	}


	_calcGpuRepr(): GeometryGpuRepr {
		const geo = GeometryGenerator.mergeBufferGeometries(this.sources.map(it => { return {
            geo: it.geo.asGpuRepr(),
            tr: it.tr ?? EmptyTransform,
        }}));
        if (!geo) {
            console.error('could not merge geos, return empty geometry', this.sources);
            return KrEdgedGeoEmpty;
        }
        return geo;
	}

	*snappingEdges(): IterableIterator<[Vector3, Vector3]> {
		for (const {geo, tr} of this.sources) {
			const m = tr ? tr.toMatrix4(new Matrix4()) : null;
			for (const [p1, p2] of geo.snappingEdges()) {
				if (m) {
					p1.applyMatrix4(m);
					p2.applyMatrix4(m);
				}
				yield [p1, p2];
			}
		}
	}
		
	intersectPlanes(planes: Plane[]): IntersectionType {
		let allIntersections = new Set<IntersectionType>();
		for (const {geo, tr} of this.sources) {
			const planesTransformed = tr ? planes.map(p => p.applyMatrix4(tr.toMatrix4(new Matrix4()))) : planes;
			const int = geo.intersectPlanes(planesTransformed);
			allIntersections.add(int);
			if (allIntersections.size >= 2) {
				return IntersectionType.Partial;
			}
		}
		return IterUtils.getFirstFromIter(allIntersections) ?? IntersectionType.Outside;
	}
}


export class EngineTriGeosSynced extends BimEngineGeometriesSyncBase<EngineGeoTriangleBase, TriGeometry> {

    constructor(
        logger: ScopedLogger,
        bimTriGeos: TriGeometries,
    ) {
        super(bimTriGeos, {
			identifier: 'tri-geos-synced',
			idsType: EngineGeoType.Triangle,
			T_Constructor: EngineGeoTriangleBase,
			logger: logger,
		});
    }

    checkForErrors(t: EngineGeoTriangleBase): boolean {
        // return !t.aabb.isEmpty() && t.gpuRepr !== KrEdgedGeoEmpty;
		return true;
    }

    convertFromBim(bimGeo: TriGeometry): Result<EngineGeoTriangleBase> {
        return new Success(new EngineGeoTriangle(bimGeo));
    }
}
