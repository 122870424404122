<script lang="ts">
    import RadioButtonOption from "../../../libui/radio-buttons/RadioButtonOption.svelte";
    import { PowerModeType } from "./SelectPowerModeTypes";
    import NumberSpinner from "../NumberPropertyWithOption/NumberPropertyBase.svelte";
    import { UnitsMapper } from "bim-ts";
    import { RadioButtonComponent } from "../../../libui/radio-buttons";

 
    export let groupName: string;
    export let options: PowerModeType[];
    export let value: string | number;
    export let unitsMapper: UnitsMapper;

    
</script>

<div class="radio-buttons-container">
    {#each options as option}
        <div class="row-order">
            <RadioButtonComponent
                {groupName}
                input={option}
                bind:formValue={value}
            />
            {#if option.inputValue}
                <div
                class="input-value text-main-dark number-value"
                class:read-only={option.inputValue.readonly}
                class:edit-field-container={!option.inputValue.readonly}
                >
                    <NumberSpinner
                        bind:sourceProperty = {option.inputValue}
                        unitsMapper = {unitsMapper}
                    />
                </div>
            {/if}
        </div>
        {#if option.option}
            <RadioButtonOption
                name={option.option.label}
                readonly={option.disabled || value !== option.value || option.option.disabled}
                value={option.option.value}
                onClickCheckbox={option.option.onClick}
            />
        {/if}
    {/each}
</div>

<style>

    .radio-buttons-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .number-value{
        width: 185px;
    }

    .row-order{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
</style>
