import { WorkerClassPassRegistry } from 'engine-utils-ts';


export type EnergyStageCalcConfig = EnergyStageCalculateConfig<any> | EnergyStageOverrideConfig | null;

export class EnergyStageCalculateConfig<CalcSettings = null> {
    constructor(
        readonly settings: CalcSettings,
    ) {
    }
}
WorkerClassPassRegistry.registerClass(EnergyStageCalculateConfig);


export class EnergyStageOverrideConfig {
    constructor(
        readonly multiplier: number,
    ) {
    }
}
WorkerClassPassRegistry.registerClass(EnergyStageOverrideConfig);