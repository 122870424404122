<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <rect
        x="13"
        y="2"
        width="1"
        height="6"
        rx="0.5"
        fill="#1A1E1F"
        fill-opacity="0.4"
    />
    <rect
        x="13"
        y="9"
        width="1"
        height="2"
        rx="0.5"
        fill="#1A1E1F"
        fill-opacity="0.4"
    />
    <path
        d="M2 3C2 2.44772 2.44772 2 3 2H10C10.5523 2 11 2.44772 11 3V13C11 13.5523 10.5523 14 10 14H3C2.44772 14 2 13.5523 2 13V3Z"
        fill="currentColor"
    />
</svg>
