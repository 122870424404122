import type { Connection } from './connection';
import type { MakeOptional } from 'engine-utils-ts';
import type { Vector2 } from 'math-ts';
import type { LossDropStats } from './utils';
import { findPolylineLength } from '../../utils';
import { METER_TO_FEET_RATION } from '../../constants';

export enum CablePairingType {
    Positive = 'positive',
    Negative = 'negative',
    Phase1 = 'phase 1',
    Phase2 = 'phase 2',
    Phase3 = 'phase 3',
}

export interface Pairing {
    type: CablePairingType,
    routes: Route[],
}

export interface RouteDataModel {
    points: Vector2[];
    pairing?: Pairing;
    connection: Connection;
    losses: number;
    power: number;
    drop: number;
}

type RouteOptionalKeys = 'losses' | 'power' | 'drop'
export type RouteInput = MakeOptional<RouteDataModel, RouteOptionalKeys>;
type RouteDefaults = Pick<RouteDataModel, RouteOptionalKeys>;

export interface Route extends RouteDataModel {}
export class Route {

    constructor(params: RouteInput) {
        const defaults: RouteDefaults = {
            losses: 0, drop: 0, power: 0,
        };
        Object.assign(this, defaults);
        Object.assign(this, params);
        this._points = params.points;
        this._pairing = params.pairing;
    }

    copy() {
        return new Route({
            ...this,
            points: this.points.map(x => x.clone()),
            pairing: this.pairing && { ...this.pairing },
        });
    }

    get lossDrop(): LossDropStats {
        const totalLength = this.connection.length;
        const rootStats = this.connection.lossDrop;
        const stats: LossDropStats = {
            drop: rootStats.drop * this.length / totalLength,
            losses: rootStats.losses * this.length / totalLength,
        };
        return stats;
    }


    ///////////////////////////
    // LENGTH/POLYLINE LOGIC //
    ///////////////////////////

    length = 0;

    private _points: Vector2[];
    get points() {
        return this._points;
    }
    set points(val: typeof this._points) {
        this._points = val;
        this.recalculateLength();
    }

    recalculateLength() {
        const newLength = findPolylineLength(this.points);
        // round in feets
        this.length = Math.ceil(newLength * METER_TO_FEET_RATION);
        this.length /= METER_TO_FEET_RATION;
    }

    /**
     * @param rev
     * Find direction in reversed order. Default is `false`.
     *
     * @param i
     * Index of segment
     */
    getNthSegmentDirection(i: number, rev = false) {
        if (i > this.points.length - 2)
            throw new Error('Segment index if out of polyline.');
        const points = [...this.points];
        if (rev) points.reverse();
        return points[i+1].clone().sub(points[i]).normalize();
    }

    ///////////////////////////
    // PAIRING RELATED LOGIC //
    ///////////////////////////

    private _pairing?: Pairing;

    get pairing() {
        if (!this._pairing)
            throw new Error('Pairing are not available.');
        return this._pairing;
    }
    set pairing(val: Pairing) {
        this._pairing = val;
    }

    get isPairingSet() {
        return !!this._pairing;
    }

    /**
     * @param includeCurrent
     * default is `false`
     */
    getPairingRoutes(includeCurrent = false) {
        let result = [...this.pairing.routes];
        if (!includeCurrent) {
            result = result.filter(x => x !== this);
        }
        return result;
    }

    initPairing(selfType: CablePairingType) {
        this.pairing = {
            routes: [this],
            type: selfType,
        };
        return this;
    }

    /**
     * @returns
     * this
     */
    setPairingType(type: CablePairingType) {
        this.pairing.type = type;
        return this;
    }

    /**
     * @returns
     * New pairing route.
     */
    addPairing(type: CablePairingType): Route {
        const pair: Route = this.copy();
        this.pairing.routes.push(pair);
        pair.pairing.type = type;
        return pair;
    }

}
