<script lang="ts">
    import { getContext } from "svelte";
    import type { Dashboard } from "./Dashboard";
    import { ButtonComponent, Button, ButtonType } from "../libui/button";
    import { Dialog } from "../libui/dialog";

    let dashboard = getContext<Dashboard>("dashboard");

    let dialog: Dialog;
    const dialogInput = {
        title: "Delete project and all project's data?",
        message:
            "It's not possible to restore projects deleted from the archive. Are you sure want to delete all project data forever?",
    };
    let selectedProjectId: number;
    export function openDialog(id: number) {
        selectedProjectId = id;
        dialog.open();
    }
</script>

<Dialog bind:this={dialog} {dialogInput} compact={true}>
    <svelte:fragment slot="actions">
        <ButtonComponent
            desc={new Button("Cancel", ButtonType.Outlined, () => {
                dialog.close();
            })}
            compact={true}
        />
        <ButtonComponent
            desc={new Button("Delete forever", ButtonType.Unelevated, () => {
                dialog.close();
                dashboard.remove(selectedProjectId);
            })}
            compact={true}
        />
    </svelte:fragment>
</Dialog>

<style lang="scss">
</style>
