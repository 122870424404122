import { Matrix4, Vector3 } from 'math-ts';

import type { Camera} from './3rdParty/three';
import { Object3D, OrthographicCamera, PerspectiveCamera } from './3rdParty/three';
import { UnitType } from './EngineConsts';
import type { GlobalUniforms } from './materials/GlobalUniforms';
import type { SnatchLineMesh } from './SnatchedRulerLine';
import { createSnatchLineMesh as createSnatchLineMesh } from './SnatchedRulerLine';
import type { TextGeometries, TextGeometry } from './text/TextGeometry';
import { TextMesh } from './TextMesh';
import type { EngineLegacyUiUnits } from './EngineLegacyUiUnits';

export class SizeLabelMesh extends Object3D {
	readonly textMesh: TextMesh;
	readonly rulerMesh: SnatchLineMesh;
	snatchMeshVertSize: number = 0.04;

	readonly edgeFrom: Vector3 = Vector3.zero();
	readonly edgeTo: Vector3 = Vector3.zero();

	vert_size_cap: number = 0.0;
	hor_size_cap: number = 0.0;
	camera_size_mult: number = 0.9;

	readonly upOutFrom: Vector3 = Vector3.zero();

	readonly _textGeometries: TextGeometries;
	readonly _uiUnits: EngineLegacyUiUnits;

	_prevLength: number = 0;
	_unitsVersion: number;

	constructor(glob_uniforms:GlobalUniforms, ui_units:EngineLegacyUiUnits, textGeometries: TextGeometries) {
		super();
		this.matrixAutoUpdate = false;
		const rulerMesh = createSnatchLineMesh(glob_uniforms);
		const textMaterial = textGeometries.textMaterial;
		const textMesh = new TextMesh(textGeometries.getGeometryUntilReturned(''), textMaterial);

		this.rulerMesh = rulerMesh;
		this.textMesh = textMesh;
		this.add(rulerMesh, textMesh);

		this._textGeometries = textGeometries;
		this._uiUnits = ui_units;
		this._unitsVersion = ui_units._version;
	}

	draw2TimesDepthTested() {
		this.textMesh.draw2TimesDepthTested();
		this.rulerMesh.draw2TimesDepthTested();
	}

	updateForCamera(camera: Camera, localSpaceCameraMatrix: Matrix4) {

		const udpate_text = (this._unitsVersion !== this._uiUnits._version)
			|| this._prevLength !== Vector3.distBetween(this.edgeFrom, this.edgeTo);

		if (udpate_text) {
			this._unitsVersion = this._uiUnits._version;
			this._prevLength = Vector3.distBetween(this.edgeFrom, this.edgeTo);

			const text = this._uiUnits.convertToStringWithUnits(this._prevLength, UnitType.Length);
			const textGeo = this.textMesh.geometry as TextGeometry;
			if (textGeo.text != text) {
				this._textGeometries.markUnused(textGeo);
				this.textMesh.geometry = this._textGeometries.getGeometryUntilReturned(text);
			}
		} else {
		}

		const edgeLength = this._prevLength;

		const edgeCenterLocal_t = this.edgeFrom.clone().lerpTo(this.edgeTo, 0.5);

		let vec_to_camera_t: Vector3;

		if (camera instanceof PerspectiveCamera) {
			vec_to_camera_t = Vector3.zero();
			vec_to_camera_t.setFromMatrixColumn(localSpaceCameraMatrix as any, 3); // position
			vec_to_camera_t.sub(edgeCenterLocal_t).normalize();
		} else {
			vec_to_camera_t = Vector3.zero();
			vec_to_camera_t.setFromMatrixColumn(localSpaceCameraMatrix as any, 2);
		}

		const snatchLIne = this.rulerMesh;
		{
			snatchLIne.localRightVec.copy(this.edgeTo).sub(this.edgeFrom)

			if (Math.abs(snatchLIne.localRightVec.clone().normalize().dot(vec_to_camera_t)) > 0.98) {
				this.visible = false;
				return;
			}
			this.visible = true;
			this.edgeFrom.copyTo(snatchLIne.position);

			snatchLIne.localUpVec.copy(vec_to_camera_t).cross(snatchLIne.localRightVec).normalizeTo(this.snatchMeshVertSize)

			// edge up vector should point outwards of bounds center
			if (edgeCenterLocal_t.clone().sub(this.upOutFrom).dot(snatchLIne.localUpVec) < 0) {
				snatchLIne.localUpVec.multiplyScalar(-1);
			}
			snatchLIne.matrix.setPositionV(snatchLIne.position);
			snatchLIne.matrixWorld.multiplyMatrices(snatchLIne.matrix, snatchLIne.parent!.matrixWorld);
		}

		const textMesh = this.textMesh;

		let maxVerticalSize =
			scaleForCamera(edgeCenterLocal_t.clone().applyMatrix4(this.matrixWorld as any), camera) * 0.6
			+ scaleForCamera(this.upOutFrom.clone().applyMatrix4(this.matrixWorld as any), camera) * 0.2;
		if (this.camera_size_mult) {
			maxVerticalSize *= this.camera_size_mult;
		}
		if (this.vert_size_cap) {
			maxVerticalSize = Math.min(maxVerticalSize, this.vert_size_cap);
		}
		const maxHorizSize = this.hor_size_cap ? this.hor_size_cap : edgeLength;
		textMesh.adjustScaleToFit(maxHorizSize, maxVerticalSize);

		const text_right_t = snatchLIne.localRightVec.clone().normalize();
		const text_up_t = snatchLIne.localUpVec.clone().normalize();

		const textPos = edgeCenterLocal_t.clone();
		textPos.add(text_up_t.clone().multiplyScalar(textMesh.sizeVert() * 0.5));
		textPos.add(snatchLIne.localUpVec);

		// textPos.applyMatrix4(this.matrixWorld);
		// text_right_t.applyMatrix4Rotation(this.matrixWorld);
		// text_up_t.applyMatrix4Rotation(this.matrixWorld);
		textMesh.updatePosition(textPos, text_right_t, text_up_t, localSpaceCameraMatrix);
	}

	setContrastColor(white: number) {
		this.rulerMesh.setContrastColor(white);
		this.textMesh.setContrastColor(white);
	}

	setBrightness(b: GizmoBrightness) {
		this.rulerMesh.setBrightness(b);
		this.textMesh.setBrightness(b);
	}

	dispose() {
		this._textGeometries.markUnused(this.textMesh.geometry as TextGeometry);
	}
}

export const enum GizmoBrightness {
	Default 	= 0b00,
	Highlighted = 0b01,
	Selected    = 0b10,
}

function scaleForCamera(position: Vector3, camera: Camera): number {
	if (camera instanceof OrthographicCamera) {
		const camSizeH = (camera.right - camera.left) / camera.zoom;
		const camSizeV = (camera.top - camera.bottom) / camera.zoom;
		return (camSizeH + camSizeV) * 0.015;
	}
	return Math.pow(position.distanceTo(camera.position), 0.8) / 13;
}

const mReused = new Matrix4();
