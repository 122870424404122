<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M2 14V12.7333L3 11.7333V14H2ZM4.75 14V10.0667L5.75 9.06667V14H4.75ZM7.5 14V9.06667L8.5 10.0833V14H7.5ZM10.25 14V10.0833L11.25 9.08333V14H10.25ZM13 14V7.4L14 6.4V14H13ZM2 10.0667V8.65L6.66667 4.01667L9.33333 6.68333L14 2V3.41667L9.33333 8.1L6.66667 5.43333L2 10.0667Z"
        fill="currentColor"
    />
</svg>
