import { GridOptions } from "ag-grid-enterprise";
import { Bim, CostModel as CM } from "bim-ts";
import { defaultCellStyle } from "./column-definitions/common";
import { createDescriptionColumnDefinition } from "./column-definitions/Description";
import { createLaborPerUnitColumnDefinition } from "./column-definitions/LaborPerUnit";
import { createLaborCostPerUnitColumnDefinition } from "./column-definitions/LaborCostPerUnit";
import { createLoadedWageRateColumnDefinition } from "./column-definitions/LoadedWageRate";
import { createLaborTotalColumnDefinition } from "./column-definitions/LaborTotal";
import { createMaterialCostPerUnitColumnDefinition } from "./column-definitions/MaterialCostPerUnit";
import { createMaterialTotalColumnDefinition } from "./column-definitions/MaterialTotal";
import { createCostUnitColumnDefinition } from "./column-definitions/CostUnit";
import { createSubServiceCostPerUnitColumnDefinition } from "./column-definitions/SubServiceCostPerUnit";
import { createEquipmentCostPerUnitColumnDefinition } from "./column-definitions/EquipmentCostPerUnit";
import { createSubServiceTotalColumnDefinition } from "./column-definitions/SubServiceTotal";
import { createEquipmentTotalColumnDefinition } from "./column-definitions/EquipmentTotal";
import { createTotalCostColumnDefinition } from "./column-definitions/TotalCost";
import { createCostPerWattColumnDefinition } from "./column-definitions/CostPerWatt";
import { createQuantityColumnDefinition } from "./column-definitions/Quantity";
import { createCostSourceColumnDefinition } from "./column-definitions/CostSource";
import { createCostStateColumnDefinition } from "./column-definitions/CostState";
import { GridContext, RowDataType } from "./types";
import { createChartsRowGridOptions } from "./ChartsRow";

export function createGridConfig(bim: Bim): {
    options: GridOptions<CM.FlattenedCostCategoryParams>;
    context: GridContext;
} {
    const defaultGridContext: GridContext = {
        hierarchy: new CM.CostHierarchy(),
        totalDCWatt: 0,
        unitsMapper: bim.unitsMapper,
        bim,
        tableWidth: 0,
        echarts: [],
    };
    const gridOptions: GridOptions<RowDataType> = {
        context: defaultGridContext as GridContext,
        columnDefs: [
            createDescriptionColumnDefinition(),
            createCostSourceColumnDefinition(),
            createCostStateColumnDefinition(),
            createCostUnitColumnDefinition(),

            createQuantityColumnDefinition(),

            createLaborPerUnitColumnDefinition(),
            createLoadedWageRateColumnDefinition(),
            createLaborCostPerUnitColumnDefinition(),

            createMaterialCostPerUnitColumnDefinition(),
            createSubServiceCostPerUnitColumnDefinition(),
            createEquipmentCostPerUnitColumnDefinition(),

            createLaborTotalColumnDefinition(),
            createMaterialTotalColumnDefinition(),
            createSubServiceTotalColumnDefinition(),
            createEquipmentTotalColumnDefinition(),

            createTotalCostColumnDefinition(),
            createCostPerWattColumnDefinition(),
        ],

        onCellClicked: (params) => {
            params.api.deselectAll();
        },

        ...createCommonAgGridTableOptions(),

        ...createChartsRowGridOptions(),
    };
    const context = defaultGridContext;
    return {
        options: gridOptions,
        context,
    };
}

export function createCommonAgGridTableOptions(): GridOptions<CM.FlattenedCostCategoryParams> {
    return {
        rowData: [],
        animateRows: true,
        enableRangeSelection: false,
        suppressCellFocus: false,
        singleClickEdit: true,
        suppressContextMenu: true,
        getRowClass: (params) => {
            const classes: string[] = [];
            if (params.data) {
                if (params.data.nestLevel === 0) {
                    classes.push("ag-grid-row-with-top-border");
                }
            }
            return classes;
        },
        defaultColDef: {
            // cell related
            suppressMovable: true,
            flex: 1,
            suppressMenu: true,
            minWidth: 90,
            maxWidth: 200,
            cellStyle: defaultCellStyle,
            enableCellChangeFlash: true,
            useValueFormatterForExport: true,

            // header related
            resizable: true,
            suppressColumnsToolPanel: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            sortable: false,
        },
        //rowSelection: 'single',
        getRowId: (params) => {
            if (!params.data) {
                return "";
            }
            return params.data.path.join(" | ");
        },
    };
}
