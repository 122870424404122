// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

export class ProjectVersionMetric {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ProjectVersionMetric {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsProjectVersionMetric(bb:flatbuffers.ByteBuffer, obj?:ProjectVersionMetric):ProjectVersionMetric {
  return (obj || new ProjectVersionMetric()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsProjectVersionMetric(bb:flatbuffers.ByteBuffer, obj?:ProjectVersionMetric):ProjectVersionMetric {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ProjectVersionMetric()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

name():string|null
name(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
name(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

value():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

unit():string|null
unit(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
unit(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startProjectVersionMetric(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addName(builder:flatbuffers.Builder, nameOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, nameOffset, 0);
}

static addValue(builder:flatbuffers.Builder, value:number) {
  builder.addFieldFloat64(1, value, 0.0);
}

static addUnit(builder:flatbuffers.Builder, unitOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, unitOffset, 0);
}

static endProjectVersionMetric(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createProjectVersionMetric(builder:flatbuffers.Builder, nameOffset:flatbuffers.Offset, value:number, unitOffset:flatbuffers.Offset):flatbuffers.Offset {
  ProjectVersionMetric.startProjectVersionMetric(builder);
  ProjectVersionMetric.addName(builder, nameOffset);
  ProjectVersionMetric.addValue(builder, value);
  ProjectVersionMetric.addUnit(builder, unitOffset);
  return ProjectVersionMetric.endProjectVersionMetric(builder);
}
}
