// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { RulerMeasureType } from './ruler-measure-type.js';


export class RulerRepresentation implements flatbuffers.IUnpackableObject<RulerRepresentationT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):RulerRepresentation {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsRulerRepresentation(bb:flatbuffers.ByteBuffer, obj?:RulerRepresentation):RulerRepresentation {
  return (obj || new RulerRepresentation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsRulerRepresentation(bb:flatbuffers.ByteBuffer, obj?:RulerRepresentation):RulerRepresentation {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new RulerRepresentation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

ty():RulerMeasureType {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : RulerMeasureType.PointsPolygon;
}

points(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readFloat64(this.bb!.__vector(this.bb_pos + offset) + index * 8) : 0;
}

pointsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

pointsArray():Float64Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? new Float64Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

static startRulerRepresentation(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addTy(builder:flatbuffers.Builder, ty:RulerMeasureType) {
  builder.addFieldInt32(0, ty, RulerMeasureType.PointsPolygon);
}

static addPoints(builder:flatbuffers.Builder, pointsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, pointsOffset, 0);
}

static createPointsVector(builder:flatbuffers.Builder, data:number[]|Float64Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createPointsVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createPointsVector(builder:flatbuffers.Builder, data:number[]|Float64Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(8, data.length, 8);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addFloat64(data[i]!);
  }
  return builder.endVector();
}

static startPointsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(8, numElems, 8);
}

static endRulerRepresentation(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createRulerRepresentation(builder:flatbuffers.Builder, ty:RulerMeasureType, pointsOffset:flatbuffers.Offset):flatbuffers.Offset {
  RulerRepresentation.startRulerRepresentation(builder);
  RulerRepresentation.addTy(builder, ty);
  RulerRepresentation.addPoints(builder, pointsOffset);
  return RulerRepresentation.endRulerRepresentation(builder);
}

unpack(): RulerRepresentationT {
  return new RulerRepresentationT(
    this.ty(),
    this.bb!.createScalarList<number>(this.points.bind(this), this.pointsLength())
  );
}


unpackTo(_o: RulerRepresentationT): void {
  _o.ty = this.ty();
  _o.points = this.bb!.createScalarList<number>(this.points.bind(this), this.pointsLength());
}
}

export class RulerRepresentationT implements flatbuffers.IGeneratedObject {
constructor(
  public ty: RulerMeasureType = RulerMeasureType.PointsPolygon,
  public points: (number)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const points = RulerRepresentation.createPointsVector(builder, this.points);

  return RulerRepresentation.createRulerRepresentation(builder,
    this.ty,
    points
  );
}
}
