import type { Readable } from "svelte/store";
import type { LazyVersioned} from "engine-utils-ts";
import { LazyBasic, } from "engine-utils-ts";
import { VersionedStore } from "../VersionedStore";
import { PUI_GroupNode } from "ui-bindings";

export function createPUIStore(configIdentifier: string, ui: LazyVersioned<PUI_GroupNode>): Readable<PUI_GroupNode> {
    if (ui?.pollWithVersion !== undefined) {
        return new VersionedStore(
            ui
        );
    } else {
        console.error('unknown config type', ui);
        return new VersionedStore(
            new LazyBasic('type-error-stub', new PUI_GroupNode({name: configIdentifier}))
        );
    }
}
