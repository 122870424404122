<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M4.5 11.5L8.00001 7.99998M8.00001 7.99998L4.5 4.5M8.00001 7.99998L11.5 4.5M8.00001 7.99998L11.5 11.5"
        stroke="currentColor"
        stroke-width="1.5"
    />
</svg>
