//https://docs.google.com/spreadsheets/d/1ICV595gJuHqvQpS_v5MTUcl0YXRyM8zGlvUFygYAEPs/edit#gid=0

export const lead = ['lead','свинец','plomo','blei','plomb','plomb','kurşun']
export const iron = ['iron','железо','hierro','eisen','fer','fer','demir']
export const silver = ['silver','серебро','plata','silber','argent','argent','gümüş']
export const calcium = ['calcium','кальций','calcio','calcium','calcium','calcium','kalsiyum']
export const aluminum = ['aluminum','алюминий','aluminio','aluminium','aluminium','aluminium','alüminyum']
export const chrome = ['chrome','хром','cromo','chrom','chrome','chrome','krom']
export const copper = ['copper','медь','cobre','kupfer','cuivre','cuivre','bakır']
export const nickel = ['nickel','никель','níquel','nickel','nickel','nickel','metal nikel']
export const brass = ['brass','латунь','latón','messing','laiton','laiton','pirinç']
export const zinc = ['zinc','цинк','zinc','zink','zinc','zinc','metal çinko']
export const steel = ['steel','сталь','acero','metall stahl','acier métallique','acier métallique','metal çelik']
export const tin = ['tin','олово','estaño','zinn','étain','étain','kalay']
export const bronze = ['bronze','бронза','bronce','bronze','bronze','bronze','bronz']
export const foil = ['foil','фольга','papel metálico','metallfolie','feuille','feuille','folyo']
export const metal = ['metal','металл','metal','metall','métal','métal','metal']

export const brick = ['brick','кирпич','ladrillo','ziegel','brique','brique','tuğla']
export const masonry = ['masonry','кладка','albañilería','mauerwerk','maçonnerie','maçonnerie','duvarcılık']
export const block = ['block','блок','bloque','block','bloc','bloc','block']
export const thermalite = ['thermalite','thermalite','thermalite','thermalite','thermalite','thermalite','thermalite']

export const fiberglass = ['fiberglass','стеклопластик','fibra de vidrio','fiberglas','fibre','fibre','fiberglas']
export const polyester = ['polyester','полиэстер','poliéster','polyester','polyester','polyester','polyester']
export const acrylic = ['acrylic','акрил','acrílico','acryl','acrylique','acrylique','akrilik']
export const polymer = ['polymer','полимер','polímero','polymer','polymère','polymère','polimer']
export const polythene = ['polythene','полиэтилен','polietileno','polythene','polythene','polythene','polietilen']
export const plastic = ['plastic', 'пластик', 'plástico', 'kunststoff', 'plastique', 'plastique', 'plastik']
export const neoprene = ['neoprene','неопрен','neopreno','neopren','néoprène','néoprène','neopren']
export const caulking = ['caulking','затыкая','calafateo','stemmen','matage','matage','doldurmayı']
export const polypropylene = ['polypropylene','полипропилен','polipropileno','polypropylen','polypropylène','polypropylène','polipropilen']
export const plastisol = ['plastisol','пластизоль','plastisol','plastisol','plastisol','plastisol','plastisol']
export const vinyl = ['vinyl','винил','vinilo','vinyl','vinyle','vinyle','vinil']
export const polycarbonate = ['polycarbonate','поликарбонат','policarbonato','polycarbonat','polycarbonate','polycarbonate','polikarbonat']
export const polyisocyanurate = ['polyisocyanurate','полиизоциануратный','poliisocianurato','polyisocyanurat','polyisocyanurate','polyisocyanurate','poliizosiyanürat']

export const epoxy = ['epoxy','эпоксидная','epoxi','epoxy','époxy','époxy','epoksi']
export const sealer = ['sealer','герметик','sellador','sealer','scellant','scellant','sızdırmazlık']
export const resin = ['resin','смола','resina','harz','résine','résine','reçine']
export const rubber = ['rubber','резина','caucho','gummi','caoutchouc','caoutchouc','kauçuk']
export const silicon = ['silicon','кремний','silicio','silizium','silicium','silicium','silikon']
export const asphalt = ['asphalt','асфальт','asfalto','asphalt','asphalte','asphalte','asfalt']
export const latex = ['latex','латекс','látex','latex','latex','latex','lateks']
export const bitum = ['bitum','битум','bitum','bitum','bitum','bitum','bitüm']
export const nitrile = ['nitrile','нитрильный','nitrilo','nitrilkautschuk','nitrile','nitrile','nitril']

export const geotextile = ['geotextile','геотекстиль','geotextil','geotextil','géotextile','géotextile','geo tekstil']
export const geosynthetic = ['geosynthetic','геосинтетические','geosintético','geosynthetische','géosynthétique','géosynthétique','geosentetik']
export const geomembrane = ['geomembrane','геомембраны','geomembrana','geomembrane','géomembrane','géomembrane','geo zar']
export const geocomposite = ['geocomposite','геокомпозит','geocompuesto','geocomposite','géocomposite','géocomposite','geo']
export const geocell = ['geocell','георешетки','geocelda','geocell','géocellules','géocellules','geocell\'in']

export const hoggin = ['hoggin','гравий','hoggin','hoggin','hoggin','hoggin','kum ve çakıl karışımı']
export const sand = ['sand','песок','arena','sand','le sable','le sable','kum']
export const soil = ['soil','почва','suelo','boden','sol','sol','toprak']
export const clay = ['clay','глина','arcilla','lehm','argile','argile','toprak']
export const gravel = ['gravel','гравий','grava','kies','gravier','gravier','çakıl']

export const slag = ['slag','шлак','escoria','schlacke','scories','scories','cüruf']
export const stone = ['stone','камень','piedra','stein','pierre','pierre','taş']
export const marble = ['marble','мрамор','mármol','marmor','marbre','marbre','mermer']
export const granite = ['granite','гранит','granito','granit','granit','granit','granit']

export const concrete = ['concrete','бетон','hormigón','beton','béton','béton','beton']
export const cast_in_situ = ['cast in situ','отливать на месте','fundido in situ','guss in situ','coulé in situ','coulé in situ','yerinde dökme']
export const cast_in_place = ['cast in place','заливаются','ejecutadas in situ','guss an ort und stelle','coulé en place','coulé en place','yerinde dökme']
export const cement_screed = ['cement screed','цементная стяжка','solado de cemento','zementestrich','chape de ciment','chape de ciment','çimento şap']
export const precast = ['precast','сборный','prefabricados','fertig','éléments préfabriqués','éléments préfabriqués','prekast']
export const slab = ['slab','плита','losa','platte','dalle','dalle','kütük']
export const aquapanel = ['aquapanel','аквапанель','aquapanel','aqua','aquapanel','aquapanel','aquapanel']
export const cement = ['cement','цемент','cemento','zement','ciment','ciment','çimento']
export const panel = ['panel','панель','panel','panel','panneau','panneau','panel']

export const wood = ['wood','дерево','madera','holz','bois','bois','ahşap']
export const cedar = ['cedar','кедр','madera de cedro','zeder','cèdre','cèdre','sedir']
export const pine = ['pine','сосна','pino','kiefer','pin','pin','çam']
export const redwood = ['redwood','красное дерево','madera roja','redwood','bois rouge','bois rouge','redwood']
export const fir = ['fir','ель','abeto','tanne','sapin','sapin','köknar']
export const yew = ['yew','тис','tejo','eibe','ifs','ifs','porsuk']
export const juniper = ['juniper','можжевельник','enebro','wacholder','genièvre','genièvre','ardıç']
export const spruce = ['spruce','ель','abeto','fichte','épinette','épinette','ladin']
export const lumber = ['lumber','пиломатериалы','madera de construcción','holz','bois','bois','kereste']
export const hardwood = ['hardwood','лиственные породы','madera dura','hartholz','bois','bois','parke']
export const hickory = ['hickory','гикори','nogal','hickory','noyer','noyer','hikori']
export const maple = ['maple','клен','arce','ahorn','érable','érable','akçaağaç']
export const teak = ['teak','тик','madera de teca','teak','teck','teck','tik']
export const balsa = ['balsa','пробковые','madera de balsa','balsa','balsa','balsa','balza']
export const mahogany = ['mahogany','красное дерево','caoba','mahagoni','acajou','acajou','maun']
export const birch = ['birch','береза','abedul','birke','bouleau','bouleau','huş']
export const oak = ['oak','дуб','madera de roble','eiche','chêne','chêne','meşe']
export const alder = ['alder','ольха','aliso','erle','aulne','aulne','kızılağaç']
export const walnut = ['walnut','орех','nogal','walnuss','noyer','noyer','ceviz']
export const cherry = ['cherry','вишня','cereza','kirsche','cerise','cerise','kiraz']
export const cork = ['cork','пробка','corcho','kork','liège','liège','mantar']
export const veneer = ['veneer','шпон','chapa de madera','furnier','vernis à bois','vernis à bois','kaplama']
export const plywood = ['plywood','фанера','madera contrachapada','sperrholz','contreplaqué','contreplaqué','kontrplak']
export const timber = ['timber', 'пиломатериалы', 'madera', 'holz', 'bois', 'bois', 'ahşap kereste']

export const grass = ['grass','трава','césped','gras','herbe','herbe','çim']
export const vegetation = ['vegetation','растительность','vegetación','vegetation','végétation','végétation','bitki örtüsü']

export const render = ['render','гипсовая штукатурка воздай','render','render','rendre','rendre','işlemek']
export const stucco = ['stucco','штукатурка','estuco','stuck','stuc','stuc','sıva']
export const plaster = ['plaster','штукатурка','yeso','putz','plâtre','plâtre','sıva']
export const gypsum = ['gypsum','гипс','yeso','gips','gypse','gypse','alçı']

export const mastic = ['mastic','мастика','masilla','mastix','mastic','mastic','sakız']
export const adhesive = ['adhesive','клей','adhesivo','klebe','adhésif','adhésif','yapışkan']
export const glue = ['glue','клей','pegamento','kleber','colle','colle','tutkal']
export const sealant = ['sealant','герметик','sellador','dichtungsmittel','matériau d\'étanchéité','matériau d\'étanchéité','mastik']
export const primer = ['primer','грунтовка','cebador','primer','amorce','amorce','primer']

export const board = ['board','доска','tablero','tafel','planche','planche','yazı tahtası']
export const blockboard = ['blockboard','брусковые','listones,','tischler','blockboard','blockboard','blockboard']
export const matchboard = ['matchboard','брусья','matchboard','spund','lambris','lambris','lambriler']
export const beadboard = ['beadboard','beadboard','beadboard','beadboard','beadboard','beadboard','beadboard']
export const baseboard = ['baseboard','плинтус','zócalo','fußleiste','plinthe','plinthe','süpürgelik']
export const softboard = ['softboard','softboard','softboard','softboard','softboard','softboard','softboard']
export const windowboard = ['windowboard','windowboard','windowboard','fensterbank','windowboard','windowboard','windowboard']
export const weatherboard = ['weatherboard','наветренная','weatherboard','wetterschenkel','weatherboard','weatherboard','bindirme']
export const corkboard = ['corkboard','пробковый','panel de corcho','corkboard','corkboard','corkboard','corkboard']
export const plasterboard = ['plasterboard','гипсокартон','cartón yeso','gipskarton','plaques de plâtre','plaques de plâtre','alçıpan']
export const chipboard = ['chipboard','дсп','aglomerado','spanplatten','aggloméré','aggloméré','sunta']
export const particleboard = ['particleboard','дсп','tablero de partículas','span','agglomérée','agglomérée','yonga']
export const hardboard = ['hardboard','оргалит','el panel duro','hartfaserplatte','panneaux durs','panneaux durs','sunta']

export const parquet = ['parquet','паркет','parquet','parkett','parquet','parquet','parke']
export const rubble = ['rubble','щебень','escombros','schutt','décombres','décombres','moloz']
export const paving = ['paving','мощение','pavimentación','pflasterung','pavage','pavage','kaldırım']
export const laminate = ['laminate','ламинат','laminado','laminieren','stratifié','stratifié','laminant']
export const cobblestone = ['cobblestone','брусчатка','guijarro','pflasterstein','pavé','pavé','parke taşı']

export const paint = ['paint','краска','pintura','farbe','peinture','peinture','boya']
export const linoleum = ['linoleum','линолеум','linóleo','linoleum','linoléum','linoléum','linolyum']
export const carpet = ['carpet','ковровое покрытие','alfombra','teppich','moquette','moquette','halı']
export const membrane = ['membrane','мембрана','membrana','membran','membrane','membrane','membran']
export const fiber = ['fiber','волокно','fibra','faser','fibre','fibre','elyaf']
export const trim = ['trim','отделка','recortar','trimmen','réduire','réduire','döşeme']
export const sash = ['sash','створка','faja','schärpe','ceinture','ceinture','kuşak']
export const carbon = ['carbon','углеродистая','carbón','kohlenstoff','carbone','carbone','karbon']
export const porcelain = ['porcelain','фарфор','porcelana','porzellan','porcelaine','porcelaine','porselen']

export const tile = ['tile','плитка','baldosas','fliesen','carrelage','carrelage','karo']
export const ceramic = ['ceramic','керамика','cerámico','keramik','céramique','céramique','seramik']
export const wool = ['wool','шерсть','lana','wolle','la laine','la laine','yün']
export const insulation = ['insulation','изоляция','aislamiento','isolierung','isolation','isolation','yalıtım']
export const batt = ['batt','войлок','napa','batt','batt','batt','keçe']

export const glass = ['glass','стекло','cristal','glas','vidrio','verre','verre','cam']
export const light = ['light','свет','luz','licht','la lumière','la lumière','işık']
export const air = ['air','воздух','aire','luft','air','air','hava']
export const glazing = ['glazing','остекление','acristalamiento','verglasung','vitrage','vitrage','cam']

export const painted = ['painted','окрашенный','pintado','lackiert','peint','peint','boyalı']
export const glossy = ['glossy','глянцевая','brillante','glänzend','brillant','brillant','parlak']
export const matte = ['matte','матовый','mate','matt','mat','mat','mat']
export const galvanized = ['galvanized','оцинкованные','galvanizado','verzinkt','galvanisé','galvanisé','galvanizli']
export const powder_coating = ['powder coating','порошковое покрытие','revestimiento en polvo','pulverbeschichtung','revêtement en poudre','revêtement en poudre','toz kaplama']
export const heavy = ['heavy','тяжелый','pesado','schwer','lourd','lourd','ağır']
export const light_easy = ['light','легкий','ligero','licht','lumière','lumière','işık']
export const frosted = ['frosted','матовое','escarchado','mattiert','givré','givré','buzlu']

export const siding = ['siding','сайдинг','vía muerta','abstellgleis','bardage','bardage','dış cephe kaplaması']
export const elevator = ['elevator','лифт','ascensor','aufzug','ascenseur','ascenseur','asansör']
export const button = ['button','кнопка','botón','taste','bouton','bouton','buton']
export const window = ['window','окно','ventana','fenster','fenêtre','fenêtre','pencere']
export const floor = ['floor','пол','piso','fußboden','sol','sol','zemin']
export const ceiling = ['ceiling','потолок','techo','decke','plafond','plafond','tavan']
export const wall = ['wall','стена','pared','mauer','mur','mur','duvar']
export const pipe = ['pipe','труба','tubo','rohr','tuyau','tuyau','boru']
export const door = ['door','дверь','puerta','tür','porte','porte','kapı']
export const chimney = ['chimney','дымовая труба','tubo de lámpara','kamin','cheminée','cheminée','baca']
export const fence = ['fence','забор','cerca','zaun','clôture','clôture','çit']
export const table = ['table','таблица','mesa','tabelle','table','table','tablo']
export const frame = ['frame','рамка','marco','rahmen','cadre','cadre','çerçeve']
export const kitchen = ['kitchen','кухня','cocina','küche','cuisine','cuisine','mutfak']
export const roof = ['roof', 'крыша', 'techo', 'dach', 'toit', 'toit', 'çatı']
export const roofing = ['roofing', 'кровля', 'material para techos', 'bedachungs', 'toitures', 'toitures', 'çatı']
export const flooring = ['flooring', 'пол', 'suelos', 'fußboden', 'revêtement de sol', 'revêtement de sol', 'döşeme']
export const coating = ['coating', 'покрытие', 'recubrir', 'beschichtungs', 'le revêtement', 'le revêtement', 'kaplanması']
export const finish = ['finish', 'отделка', 'acabado', 'oberfläche', 'finition', 'finition', 'bitirmek']
export const shingles = ['shingles', 'черепица', 'tejas del techo', 'dachziegel', 'bardeaux', 'bardeaux', 'zona']
export const PVC = ['PVC','пвх','pvc','pvc','pvc','pvc','malzeme, plastik pvc']
export const slate = ['slate', 'шифер', 'pizarra', 'schiefer', 'ardoise', 'ardoise', 'barut']
export const tower = ['tower','башня','torre','turm','la tour','la tour','kule']

export const yellow = ['yellow','желтый','amarillo','gelb','jaune','jaune','sarı']
export const blue = ['blue','синий','azul','blau','bleu','bleu','mavi']
export const golden = ['golden','золотой','dorado','golden','d\'or','d\'or','altın']
export const white = ['white','белый','blanco','weiß','blanc','blanc','beyaz']
export const grey = ['grey','серый','gris','grau','gris','gris','gri']
export const black = ['black','черный','negro','schwarz','noir','noir','siyah']
export const chocolate = ['chocolate','шоколад','chocolate','schokolade','chocolat','chocolat','çikolata']
export const red = ['red','красный','rojo','rot','rouge','rouge','kırmızı']
export const purple = ['purple','пурпурный','púrpura','lila','violet','violet','mor']
export const quarry = ['quarry','карьер','cantera','steinbruch','carrière','carrière','ocağı']