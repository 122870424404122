import type { LazyVersioned } from "engine-utils-ts";
import { LazyDerivedAsync } from "engine-utils-ts";
import type { Bim } from "src";
import { NumberProperty } from "src";
import { create_PowerConversionSystem_Inverters } from "./inverters";
import { create_PowerConversionSystem_Transformers } from "./transformers";
import { create_PowerConversionSystem_Substations } from "./substations";
import type { CostsConfigProvider, EstimateCost} from "src/cost-model/capital";
import { CostHierarchy, PCSTopCategoryName, createEmptyCostComponentsNonNullable, fillModelBasedTopLevelCategory } from "src/cost-model/capital";

export function create_PowerConversionSystem(
    bim: Bim,
    costs: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>,
) {
    const provider = costs;
    const unitsMapper = bim.unitsMapper;

    const inverters = create_PowerConversionSystem_Inverters(bim, costs, totalDC);
    const transformers = create_PowerConversionSystem_Transformers(bim, costs, totalDC);
    const substations = create_PowerConversionSystem_Substations(bim, costs, totalDC);

    const result = LazyDerivedAsync.new5<
        CostHierarchy,
        CostHierarchy,
        CostHierarchy,
        CostHierarchy,
        EstimateCost[],
        NumberProperty
    >(
        'PCS',
        [unitsMapper],
        [inverters, transformers, substations, provider.allEstimateCosts, totalDC],
        function* ([inverters, transformers, substations, estimates, totalDC]) {
            const hierarchy = new CostHierarchy().merge(inverters, transformers, substations);

            // create foundation installation
            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'Foundation Installation' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.001 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'pcs-foundation-installation-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            // create crane cost
            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'Crane cost' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.001 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'pcs-crane-cost-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            // create BESS cost
            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'BESS' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.001 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'pcs_bess-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            // create PCS General Conditions
            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'PCS General Conditions' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.001 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'pcs-general-conditions-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            // create PCS Logistics & Misc
            {
                const sub = new CostHierarchy();
                const root = sub.add({ description: { value: 'PCS Logistics & Misc' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.001 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    sub,
                    'pcs-logistic-misc-benchmark',
                    estimates,
                    costs.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
                hierarchy.merge(sub);
            }

            // create root category
            {
                const root = hierarchy.addRoot({ description: { value: PCSTopCategoryName } });
                hierarchy.categoryWithSceneInstances.add(root[0])
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.serviceCost = NumberProperty.new({ value: 0.028 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    hierarchy,
                    'pcs-benchmark',
                    estimates,
                    provider.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
            }

            return hierarchy;
        }
    )

    return result;
}

export interface ElectricalCables {
    hierarchy: CostHierarchy
    isEmpty: boolean
}
