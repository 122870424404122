
import type { ShaderBase } from './ShaderBase';

export const DepthShader: ShaderBase = Object.freeze({
	
	name: 'depth',

	uniforms: {
		// cameraNearFar: { value: new Vector2(1, 100) },
	},

	vertexShader:
	`
	#define DEPTH_VERT
	#include <kr_std_attributes>
	#include <kr_std_uniforms>
	#include <kr_std_vars>

	#include <common>

	void main() {
		#include <kr_std_vars_calc>
	}
	`,

	fragmentShader:
	`
	#define DEPTH_FRAG
	#include <common>
	#include <packing>

	#include <kr_std_uniforms>
	#include <kr_std_vars>
	// uniform vec2 cameraNearFar;

	void main() {

		#include <kr_std_pixel>
		// float z =  (gl_FragCoord.z - cameraNearFar.x) / cameraNearFar.y;
		gl_FragColor.rgba =  vec4(gl_FragCoord.z);
	}
	
	`,
});
