import type { ResultAsync } from 'engine-utils-ts';
import { LazyDerived, RGBA, StringUtils, type LazyVersioned, Success, InProgress } from 'engine-utils-ts';
import { KrMath } from 'math-ts';
import type { EChartOptions } from 'ui-charts';
import type { PropsChartParams } from './PropsChartsParams';
import type { CategoryDataset, CategoryDatasetBin } from './PropsDatasetGenerator';
import { type HistogramBin, type PropsDataset, HistogramDataset } from './PropsDatasetGenerator';



export function createPropsChartFromDataset(
    params: LazyVersioned<PropsChartParams>,
    dataset: LazyVersioned<ResultAsync<PropsDataset | null>>,
): LazyDerived<{echartOptions: EChartOptions, heightPx: number} | null> {
    return LazyDerived.new2(
        'propsChart',
        [],
        [dataset, params],
        ([dataset, params]) => {
            let chart: PropsDataset | null = null;
            if (dataset instanceof Success) {
                chart = dataset.value;
            } else if (dataset instanceof InProgress && dataset.lastSuccessful ) {
                chart = dataset.lastSuccessful;
            }
            if (!chart) {
                return null;
            }
            if (chart instanceof HistogramDataset) {
                return createPropsChartFromHistogramDataset(params, chart);
            }
            return createPropsChartFromPiechartDataset(params, chart);
        }
    );
}

export function createPropsChartFromHistogramDataset(
    params: PropsChartParams,
    histogram: HistogramDataset,
): {echartOptions: EChartOptions, heightPx: number} {

    const xAxisName = params.property_path + ' ' + histogram.valuesUnit;

    const echartOptions: EChartOptions = {
        brush: {
            toolbox: ['lineX', 'clear'],
            xAxisIndex: 'all',
            outOfBrush: {
                colorAlpha: 0.5
            },
        },
        grid: {
            bottom:'6px',
            top: '34px',
            left: '6x',
            right: '12px',
            containLabel: true
        },
        dataset: [
            {
                source: histogram.bins,
                dimensions: [
                    'xValue',
                    'barHeight',
                ]
            },
        ],
        tooltip: {
            trigger: 'axis',
            snap: false,
            axisPointer: {
                type: 'line'
            },
            renderMode: 'html',
            // appendToBody: true,
            confine: true,
            formatter: (params: any) => {
                if (Array.isArray(params)) {
                    params = params[0];
                }
                const dp = params.data as HistogramBin;
                if (dp.values.length === 0) {
                    return `0`;
                }

                const values = Array.from(dp.values).sort((n1, n2) => n1 - n2);
                let result: string = '';

                
                result += `${dp.ids.length} objects`;
                result += `<br>(click to select)`;

                if (values[0] === values.at(-1)) {
                    const value = values[0];
                    result += `= <b>${StringUtils.roundedForUi(value)}</b> ${histogram.valuesUnit}`;
                } else {
                    const realMin = StringUtils.roundedForUi(values[0]);
                    const realMax = StringUtils.roundedForUi(values.at(-1)!);
                    result += `[<b>${realMin}</b> to <b>${realMax}</b>] ${histogram.valuesUnit}`;
                }


                return result;
            },
        },
        xAxis: {
            type: 'value',
            // name: '→ ' + xAxisName,
            // nameLocation: 'middle',
            // nameGap: 25,
            // interval: histogram.binSize,
            // axisLabel: {
            //     margin: 8
            // },
            // nameTextStyle: {
            //     fontSize: 14,
            // },
            // position: 'bottom',
            min: histogram.chartMin,
            max: histogram.chartMax,
        },
        yAxis: {
            // min: -100,
            name: histogram.yAxisName,
            type: 'value',
            // nameRotate: 45,
            nameTextStyle: {
                fontSize: 12,
            }
        },
        color: histogram.bins.map(b => RGBA.toHexRgbString(b.color)),
        series: [
            {
                type: 'bar',
                name: xAxisName,
                barWidth: '95%',
                colorBy: 'data',
                itemStyle: {
                },
                select: {
                    label: {
                        show: true,
                    },
                    itemStyle: {
                        borderColor: 'orange',

                    }
                },
                label: {

                    // show: true,//histogram.bins.length < 30,
                    // formatter: (params: any) => {
                    //     const data = params.data as HistogramBin;
                    //     if (data.dataPoints.length === 0) {
                    //         return '';
                    //     }
                    //     return `${data.dataPoints.length}`;
                    // },
                    position: 'top'
                },
                animationDuration: 200,
                animationDurationUpdate: 200,
            },
            // {
            //     name: 'histogram',
            //     type: 'bar',
            //     xAxisIndex: 2,
            //     yAxisIndex: 2,
            //     barWidth: '99.3%',
            //     label: {
            //     show: true,
            //     position: 'right'
            //     },
            //     encode: { x: 1, y: 0, itemName: 4 },
            //     datasetIndex: 2
            // }
        ]
    }
    return {echartOptions, heightPx: 350};
}


export function createPropsChartFromPiechartDataset(
    params: PropsChartParams,
    dataset: CategoryDataset,
): {echartOptions: EChartOptions, heightPx: number} {

    const echartOptions: EChartOptions = {
        dataset: {
            source: dataset.bins.map(b => {
                return {
                    label: b.value,
                    count: b.ids.length,
                    perc: (b.ids.length / dataset.totalIdsCount * 100 || 0).toFixed(2) + '%',
                };
            }),
        },
        tooltip: {
            renderMode: 'html',
            // appendToBody: true,
            confine: true,
            trigger: 'axis',
            axisPointer: {
                type: 'line'
            },
            formatter: (params: any) => {
                if (Array.isArray(params)) {
                    params = params[0];
                }
                const dp = dataset.bins[params.dataIndex] as CategoryDatasetBin;
                let result: string = dp.value + '';
                result += `<br>${dp.ids.length} objects`;
                result += `<br>(click to select)`;
                return result;
            },
        },
        grid: { containLabel: true },
        xAxis: { },
        yAxis: { type: 'category', inverse:true },
        color: dataset.bins.map(b => RGBA.toHexRgbString(b.color)),
        series: [{
            colorBy: 'data',
            type: 'bar',
            barMinHeight: 3,
            encode: {
                x: 'count',
                y: 'label',
            },
            label: {
                show: true,
                position: 'right',
                formatter: '{@perc}',
            }
        }]
    };
    
    return {echartOptions, heightPx: KrMath.clamp(dataset.bins.length * 35, 300, 4000)};
}

