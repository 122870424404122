export class Base64Binary {
	
	static decodeArrayBuffer(base64Str: string): ArrayBuffer {
		const str = atob(base64Str);
		const arrayBuffer = new Uint8Array(str.length);
		for (var i = 0, strLen = str.length; i < strLen; i++) {
			arrayBuffer[i] = str.charCodeAt(i);
		}
		return arrayBuffer.buffer as ArrayBuffer;
	}

	static uint8ToBase64(u8Arr:Uint8Array){
		const CHUNK_SIZE = 0x8000; //arbitrary number
		let index = 0;
		const length = u8Arr.length;
		let result = '';
		while (index < length) {
			const slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length)); 
			result += String.fromCharCode.apply(null, slice as unknown as number[]);
			index += CHUNK_SIZE;
		}
		return btoa(result);
	}

}
