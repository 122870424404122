
import type { WorkerWrapper } from "./WorkerWrapper";
import type { WorkerMsg, WorkerMsgEvent } from "./WorkerMsg";
import type { Worker } from "worker_threads";


export class NodeWorker implements WorkerWrapper {
    private _worker: Worker;

    private constructor(worker: Worker) {
        this._worker = worker;
    }

    static async new(workerPath: string, options: WorkerOptions): Promise<WorkerWrapper> {
        const { Worker } = await import("worker_threads");
        const worker = new Worker(workerPath, { name: options.name });
        return new NodeWorker(worker);
    }

    postMessage(message: WorkerMsg[], options?: { transfer: ArrayBufferLike[] }): void {
        this._worker.postMessage(message, options?.transfer ?? []);
    }

    onMessage(cb: (message: WorkerMsgEvent<WorkerMsg | WorkerMsg[]>) => void): void {
        this._worker.on("message", (msg) => cb({data: msg}));
    }

    onMessageError(cb: (msg: WorkerMsgEvent<WorkerMsg> | Error) => void): void {
        this._worker.on("messageerror", (error) => {
            cb(error);
        });
    }

    onError(callBack: (errorMsg: string) => void): void {
        this._worker.on("error", (error) => {
            callBack(error.message);
        });
    }

    terminate(): void {
        this._worker.terminate();
    }
}