import { IterUtils, ResolvedPromise } from "..";
import type {
    LazyVersioned,
    PollWithVersionResult,
} from "../stateSync/LazyVersioned";
import type { ScopedLogger } from "../ScopedLogger";
import type { BasicCollectionUpdates } from "./BasicCollectionUpdates";
import { Allocated, Deleted, Updated } from "./BasicCollectionUpdates";
import type { BasicDataSource } from "./BasicDataSource";
import { ObservableStream } from "./ObservableStream";
import { StreamAccumulator } from "./StreamAccumulator";



export enum RecalcScheduleType {
	Immidiate,
	Microtask,
	OnDemand,
}

export class MappedCollectionBasic<T, IdT, TSource>
	implements LazyVersioned<ReadonlyMap<IdT, T>>, BasicDataSource<T, IdT>
{
	// does not preserve order of updates of source collection
	readonly updatesStream: ObservableStream<BasicCollectionUpdates<IdT>>;

	readonly scheduleType: RecalcScheduleType;

	readonly logger: ScopedLogger;
	readonly dataSource: BasicDataSource<TSource, IdT>;

	private _currentResults = new Map<IdT, T>();
	private _mapFn: (sourceObj: TSource) => T;
	private _disposeFn: ((sourceObj: T) => void) | null;
	private _version: number = 0;
	private _sourceUpdatesAccumulator: StreamAccumulator<BasicCollectionUpdates<IdT>>;

	constructor(args: {
		identifier: string,
		logger: ScopedLogger,
		dataSource: BasicDataSource<TSource, IdT>,
		schedule?: RecalcScheduleType,
		mapFn: (sourceObj: TSource) => T,
		disposeFn?: (obj: T) => void,
	}) {
		this.logger = args.logger.newScope(args.identifier);
		this.dataSource = args.dataSource;

		this.scheduleType = args.schedule ?? RecalcScheduleType.Microtask;

		this._mapFn = args.mapFn;
		this._disposeFn = args.disposeFn ?? null;

		this.updatesStream = new ObservableStream({
			identifier: args.identifier,
			holdLastValueForNewSubscribers: false,
			defaultValueForNewSubscribersFactory: () => new Allocated<IdT>(Array.from(this.poll().keys())),
		});
		this._sourceUpdatesAccumulator = new StreamAccumulator(args.dataSource.updatesStream, (_update) => {
			this._scheduleRecalc();
			return true;
		});
	}
	
	dispose() {
		this._sourceUpdatesAccumulator.dispose();
		if (this._disposeFn) {
			for (const [id, obj] of this._currentResults) {
				try {
					this._disposeFn(obj);
				} catch (e) {
					this.logger.error('error during disposing obj', id, e);
				}
			}
		}
		this._currentResults.clear();
	}

	poll(): ReadonlyMap<IdT, T> {
		this._recalculateDirty();
		return this._currentResults;
	}
    pollWithVersion(): PollWithVersionResult<Readonly<ReadonlyMap<IdT, T>>> {
        return { value: this.poll(), version: this._version };
    }
	version(): number {
		return this._version + this._sourceUpdatesAccumulator.version();
	}

	peekByIds(ids: Iterable<IdT>): Map<IdT, T> {
		this._recalculateDirty();
		const res = new Map();
		for (const id of ids) {
			const obj = this._currentResults.get(id);
			if (obj !== undefined) {
				res.set(id, obj);
			}
		}
		return res;
	}
	allIds(): IterableIterator<IdT> {
		return this.poll().keys();
	}


	private _updateScheduled: boolean = false;
	private _scheduleRecalc() {
		if (this.scheduleType === RecalcScheduleType.Immidiate) {
			this._recalculateDirty();
		} else if (this.scheduleType === RecalcScheduleType.Microtask) {
			if (!this._updateScheduled) {
				this._updateScheduled = true;
				ResolvedPromise.then(() => {
					this._updateScheduled = false;
					this._recalculateDirty();
				});
			}
		} else if (this.scheduleType === RecalcScheduleType.OnDemand) {
			// call poll to get synced results
		} else {
			this.logger.error('unknown schedule type', this.scheduleType);
		}
	}

	private _recalculateDirty() {
		const updates = this._sourceUpdatesAccumulator.consume();
		if (!updates) {
			return;
		}
		const allIdsAffected: IdT[] = [];
		for (const update of updates) {
			IterUtils.extendArray(allIdsAffected, update.ids);
		}

		let dirtyIdsUnique: Iterable<IdT>;
		if (typeof allIdsAffected[0] === 'number') {
			dirtyIdsUnique = allIdsAffected;
			IterUtils.sortDedupNumbers(dirtyIdsUnique as unknown[] as number[]);
		} else {
			dirtyIdsUnique = new Set(allIdsAffected);
		}

		const newIds: IdT[] = [];
		const updatedIds: IdT[] = [];
		const removedIds: IdT[] = [];

		const dataSource = this.dataSource;

		for (const [id, sourceObj] of dataSource.peekByIds(dirtyIdsUnique)) {
			const currResult = this._currentResults.get(id);
			if (sourceObj === undefined) {
				if (currResult !== undefined) {
					this._currentResults.delete(id);
					removedIds.push(id);
					if (this._disposeFn) {
						try {
							this._disposeFn(currResult);
						} catch (e) {
							this.logger.error('error during object disposal', id, e);
						}
					}					
				}

			} else {
				try {
					const calculated = this._mapFn(sourceObj);
					
					// in the future, may compare with prev result
					// but in that case should make sure result is frozen/readonly
					if (currResult !== undefined) {
						updatedIds.push(id);
						if (this._disposeFn) {
							try {
								this._disposeFn(currResult);
							} catch (e) {
								this.logger.error('error during object disposal', id, e);
							}
						}
					} else {
						newIds.push(id);
					}

					this._currentResults.set(id, calculated);
				} catch (e) {
					this.logger.error('error calculating result for', id, e);
					// should delete prev result ?
				}
			}
		}
		if (newIds.length) {
			this.updatesStream.pushNext(new Allocated(newIds));
		}
		if (updatedIds.length) {
			this.updatesStream.pushNext(new Updated(updatedIds));
		}
		if (removedIds.length) {
			this.updatesStream.pushNext(new Deleted(removedIds));
		}
	}
}
