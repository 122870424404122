

export class LazyMapBulk<K, V> {

	readonly _map: Map<K, V> = new Map();

	private readonly factory: (keys: Iterable<K>) => [K, V][];

	constructor(factory: (keys: Iterable<K>) => [K, V][]) {
		this.factory = factory;
	}
	
    getBulk(keys: Iterable<K>): Map<K, V> {
        const result = new Map<K, V>();
        const toGenerate: K[] = [];
        const thisMap = this._map;
        for (const k of keys) {
            if (thisMap.has(k)) {
                result.set(k, thisMap.get(k)!);
            } else {
                toGenerate.push(k);
            }
        }
        if (toGenerate.length > 0) {
            const generated = this.factory(toGenerate);
            for (const t of generated) {
                thisMap.set(t[0], t[1]);
                result.set(t[0], t[1]);
            }
        }
        return result;
    }
    
	set(key: K, val: V) {
		this._map.set(key, val);
	}

	size(): number {
		return this._map.size;
	}
}


