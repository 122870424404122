import { CostModel as CM } from 'bim-ts';
import { ColDef } from "ag-grid-enterprise";
import { createModifiableCostCellDefinition } from './common';


export const createEquipmentCostPerUnitColumnDefinition = (): ColDef<CM.FlattenedCostCategoryParams> => ({
    colId: CM.EquipmentCostPerUnitColId,
    headerName: CM.EquipmentCostPerUnitColId,
    cellEditor: 'agNumberCellEditor',

    ...createModifiableCostCellDefinition(x => x.equipment?.equipmentCostPerUnit),
})

