<script lang="ts">
    import { getContext } from "svelte";
    import {
        SelectItem,
        HierarchyDown,
        OpenExternal,
        Delete,
    } from "../../libui/icon/svg";
    import {
        RadioButton,
        RadioButtonComponent,
    } from "../../libui/radio-buttons";
    import {
        Action,
        ContextMenuStructure,
        type ContextMenu,
    } from "../../libui/context-menu";
    import type { NavbarContext } from "../../navbar/Navbar";

    interface SelectedMode {
        value: string | number;
        label: string;
        disabled?: boolean;
        selectTerrains?: () => void;
        openSiteContext?: boolean;
        options: (
            | {
                  label: string;
                  type: "clearData";
                  disabled?: boolean;
                  cleanSelectedArea?: () => void;
                  cleanAllData: () => void;
              }
            | {
                  label: string;
                  type: "terrain";
                  disabled?: boolean;
                  selectTerrain: () => void;
              }
        )[];
    }
    export let name: string;
    export let value: number;
    export let options: SelectedMode[];

    const contextMenu = getContext<ContextMenu>("context-menu");
    const navbar = getContext<NavbarContext>("ui-navbar");


    function openContextMenu(
        x: number,
        y: number,
        cleanAllData: () => void,
        cleanSelectedArea?: () => void
    ) {
        const newMenuStructure = new ContextMenuStructure([]);

        if(cleanSelectedArea){
            newMenuStructure.items.push(
                new Action(
                    "Clean Within Selected Area",
                    cleanSelectedArea ?? (() => {}),
                )
            );
        }
        newMenuStructure.items.push(new Action("Clean All Data", cleanAllData));
        contextMenu.openMenuAtPosition(x, y, newMenuStructure);
    }

    function openSiteContext() {
        const identifier = ['View', 'Site Context'].join('.');
        navbar.openTab(identifier);
    }
</script>

<div class="ui-config-property property-row">
    <div class="property-name text-main-medium">
        <div class="property-name-label mdc-typography--body1">
            {name}
        </div>
    </div>
    <div class="property-value">
        <div class="switcher-property radio-buttons-container">
        {#each options as mainOption}
            <div class="selected-item">
                <RadioButtonComponent
                    groupName={name}
                    input={new RadioButton(
                        mainOption.value,
                        mainOption.label,
                        mainOption.disabled
                    )}
                    bind:formValue={value}
                />
                <span>
                    <!-- {#if mainOption.openSiteContext}
                        <span
                            class="icon-holder"
                            on:click={openSiteContext}
                        >
                            <OpenExternal />
                        </span>
                    {/if} -->
                    {#if mainOption.selectTerrains}
                        <span
                            class="icon-holder"
                            on:click={mainOption.selectTerrains}
                        >
                            <SelectItem />
                        </span>
                    {/if}
                </span>
            </div>
            {#each mainOption.options as option}
                <div class="selected-item-root">
                    <div class="selected-item">
                        <span class="name">
                            {#if option.type === "clearData"}
                                <div class="hierarchy-holder">
                                    <HierarchyDown />
                                </div>
                            {:else}
                                <div class="text-offset">
                                </div>
                            {/if}
                            <span
                                class="mdc-typography--body1"
                                class:text-main-dark={!(
                                    mainOption.disabled || option.disabled
                                )}
                                class:text-main-medium={mainOption.disabled ||
                                    option.disabled}
                                >{option.label}
                            </span>
                        </span>
                        <div class="icon-group">
                            {#if option.type === "terrain"}
                                <span
                                    class="icon-holder"
                                    on:click={() => {
                                        if (
                                            option.type === "terrain" &&
                                            !option.disabled
                                        ) {
                                            option.selectTerrain();
                                        }
                                    }}
                                >
                                    <SelectItem />
                                </span>
                            {/if}
                            {#if option.type === "clearData"}
                                <span
                                    class="icon-holder"
                                    on:click={(e) => {
                                        if (option.type === "clearData") {
                                            openContextMenu(
                                                e.clientX,
                                                e.clientY,
                                                option.cleanAllData,
                                                option.cleanSelectedArea
                                            );
                                        } else {
                                            console.error(
                                                "Unknown option type",
                                                option
                                            );
                                        }
                                    }}
                                >
                                    <Delete />
                                </span>
                            {/if}
                        </div>
                    </div>
                </div>
            {/each}
        {/each}
        </div>
    </div>
</div>

<style lang="scss">
    .selected-item-root {
        width: 100%;
    }
    .selected-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .icon-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
    }
    .name {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }
    .hierarchy-holder {
        padding: 0 0 2px 1px;
    }
    .text-offset {
        padding-left: 23px;
    }
    .icon-holder {
        height: fit-content;
        padding-right: 4px;
        padding-top: 4px;
        cursor: pointer;
        color: var(--color-main-medium);
    }
    .radio-buttons-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
</style>
