import type { Bim} from "../..";
import { BimProperty, DC_CNSTS } from "../..";
import type { GroupsShapesToDefine } from "../../scene/SceneInstanesShapeHooks";

const MAX_MULTIHARNESS_QTY = 5;
const MAX_MULTIHARNESS_DIV_VALUE = 100;


// properties paths
const circuitPath = ['circuit'];
const patternPath = [...circuitPath, 'pattern'];
const maxVoltDropPath = [...circuitPath, 'lv_wiring', 'max_voltage_drop'];
const patternTypePath = [...patternPath, 'type'];
const minWiringPath = [...patternPath, 'min_wiring'];
const multiharnessPath = [...patternPath, 'multiharness'];
const qtyPath = [...multiharnessPath, 'qty_by_tracker'];
const multiharnessSameLengthPath = [...multiharnessPath, 'same_length'];

export const createLowVoltagePropsGroupShaper = (bim: Bim) => {
    // register shaper for transformer
    bim.instances.registerPropsShapeHook(
        'transformer',
        {
            dc_pattern: BimProperty.NewShared({
                value: 'Not set',
                path: patternTypePath,
            }),
            multiharness_qty_by_tracker: BimProperty.NewShared({
                value: 1,
                path: qtyPath,
            }),
        },
        args => {
            const result: GroupsShapesToDefine = {
                propertiesToHave: new Map(),
                propertiesToOverride: new Map(),
                groupsToDefinesMergedPrefixes: [],
            };
/////////////////////////////////////////
// resetting indent to save some space //
/////////////////////////////////////////
// Configure dc pattern related props  //
/////////////////////////////////////////
// configure pattern type default
result.propertiesToHave.set(
    BimProperty.MergedPath(patternTypePath),
    BimProperty.NewShared({
        path: patternTypePath,
        value: 'Not set',
        discrete_variants: [...DC_CNSTS.patternNames, 'Not set'],
    }),
);
// configure max voltage drop default
result.propertiesToHave.set(
    BimProperty.MergedPath(maxVoltDropPath),
    BimProperty.NewShared({
        path: maxVoltDropPath,
        value: 1.5,
        unit: '%',
        numeric_range: [0.01, 100],
        numeric_step: 0.01,
    }),
);
const patternName = args['dc_pattern'].property.asText();
const pattern = DC_CNSTS.PATTERNS[patternName as DC_CNSTS.PatternName]
// configure min wiring
dcMinWiring:
do {
    if (!args['dc_pattern'].override)
        break dcMinWiring;
    // Add controlled prop groups
    result.groupsToDefinesMergedPrefixes
        .push(BimProperty.MergedPath(minWiringPath));
    if (!pattern) {
        // if pattern is overriden but not valid, just break.
        // it will remove all related props
        break dcMinWiring;
    }
    // set default pattern materials
    for (const [idx, conductor] of pattern.conductors.entries()) {
        const isBusBars = conductor.type === DC_CNSTS.ConductorType.BusBars;
        const wirePath = [...minWiringPath, `wire_${idx + 1}_${conductor.type}`];
        result.propertiesToOverride.set(
            BimProperty.MergedPath(wirePath),
            BimProperty.NewShared({
                value: -1,
                path: wirePath,
                description: '=asset=lv-wire-spec=',
                readonly: isBusBars,
            },
        ));
    }
} while (0)
//configure multiharness props
const patternWithMultiharness = pattern?.conductors
    .find(x => x.type === DC_CNSTS.ConductorType.MultiHarness) ?? null;
dcMultiharness:
do {
    // set controlled group
    result.groupsToDefinesMergedPrefixes
        .push(BimProperty.MergedPath(multiharnessPath))
    // if pattern is not valid, or pattern does not have multiharness, break
    if (!pattern || !patternWithMultiharness)
        break dcMultiharness;
    // here pattern is valid and has multiharness
    // get multiharness qty current value or default
    const qtyProp = args['multiharness_qty_by_tracker'].property;
    const qty = qtyProp.asNumber();
    // add qty to propsToHave
    result.propertiesToHave.set(
        BimProperty.MergedPath(qtyPath),
        BimProperty.NewShared({
            path: qtyPath,
            value: qty,
            numeric_step: 1,
            numeric_range: [1, MAX_MULTIHARNESS_QTY],
        }),
    )
    // add same length to propsToHave
    result.propertiesToHave.set(
        BimProperty.MergedPath(multiharnessSameLengthPath),
        BimProperty.NewShared({
            path: multiharnessSameLengthPath,
            value: true,
        }),
    )
    // add divs to propsToHave
    for (let i = 0; i < qty; i++) {
        const path = [...multiharnessPath, `div_${i + 1}`];
        result.propertiesToHave.set(
            BimProperty.MergedPath(path),
            BimProperty.NewShared({
                path,
                value: 1,
                numeric_step: 1,
                numeric_range: [1, MAX_MULTIHARNESS_DIV_VALUE],
            }),
        )
    }
    break dcMultiharness;
} while (0)
/////////////////////////////////////
// End of dc pattern related props //
/////////////////////////////////////
            return result;
        }
    )
}

