<script lang="ts">
    import { VersionedStore } from "../VersionedStore";

    import type { MenuItemAction } from "./ToolbarItems";
    import Hierarchy from "../libui/icon/svg/hierarchy_down.svelte";

    export let menu: MenuItemAction;
    export let hierarchy: boolean = false;

    $: isActive = new VersionedStore(menu.canUseNow);

    function onClick() {
        if ($isActive) {
            menu.action();
        }
    }
</script>

<li class="mdc-deprecated-list-item" tabindex="0" on:click={onClick}>
    <span class="mdc-deprecated-list-item__ripple"></span>
    {#if hierarchy}
        <span class="mdc-list-item__graphic">
            <Hierarchy />
        </span>
    {/if}
    <span
        class="mdc-deprecated-list-item__text label-left"
        class:text-main-light={!$isActive}
    >
        {menu.name}
    </span>
    <span
        class="mdc-deprecated-list-item__text annotaion-right text-main-medium"
        class:text-main-light={!$isActive}>{menu.hotkeysString}</span
    >
</li>

<style lang="scss">
    .label-left {
        margin-right: auto;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .annotaion-right {
        margin-left: auto;
    }
    .mdc-list-item__graphic {
        margin-right: 10px;
    }
</style>
