<script lang="ts">
import { CostModel as CM } from 'bim-ts';
import AnyEchart from '../../charts/AnyEchart.svelte';

export let echarts: CM.TableEChartIntegration[] | undefined
export let chartWidth: number
export let chartHeight: number

</script>

<div class="chart-container">
    {#each echarts ?? [] as echart (echart.id)}
        <div
            class="chart-item"
            style="width: {chartWidth}px; height: {chartHeight}px"
        >
            <AnyEchart
                echartOptions={echart.options}
                onEchartInit={() => {}}
            />
        </div>
    {/each}
</div>

<style lang="scss">
.chart-container {
    overflow: auto;

    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    padding: 15px;

    .chart-item {
        box-sizing: border-box;
        padding: 20px 0px;
    }

}
</style>
