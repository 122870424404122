import type { BimGeometryBase } from 'bim-ts';
import { ErrorUtils } from 'engine-utils-ts';
import type { Aabb, Matrix4, Plane} from 'math-ts';
import { Vector3 } from 'math-ts';
import type { KrCamera } from '../controls/MovementControls';
import type { RaySection } from '../structs/RaySection';
import type { GeometryIntersection} from './GeometryUtils';
import { GeometryUtils, IntersectionType } from './GeometryUtils';
import type { GeometryGpuRepr, PerGeoShaderInfo } from './KrBufferGeometry';


export abstract class EngineGeometry {

	_gpuRepr: GeometryGpuRepr | undefined = undefined;
	_aabb: Aabb | undefined = undefined;

	constructor(
	) {
	}

	isViewDependent(): boolean {
		return false;
	}

	abstract calcAabb(): Aabb;
	aabb(): Readonly<Aabb> {
		if (!this._aabb) {
			this._aabb = this.calcAabb();
		}
		return this._aabb;
	}


	protected abstract _calcGpuRepr(): GeometryGpuRepr;
	asGpuRepr(): GeometryGpuRepr {
		if (this._gpuRepr === undefined) {
			this._gpuRepr = this._calcGpuRepr();
		}
		return this._gpuRepr;
	}

	intersectPlanes(planes: Plane[]): IntersectionType {
		const triGeo = this.asGpuRepr();
		const positions = triGeo.positionsF();
		const index = triGeo.index.array as Uint16Array | Uint32Array;
		if (positions && index) {
			return GeometryUtils.checkPlanesVolumeIntersection(positions, index, planes);
		} else {
			console.error('geometry should have positions and index to intersect planes, override this method', this);
			return IntersectionType.Outside;
		}

	}
	*snappingEdges(): IterableIterator<[Vector3, Vector3]> {
		const triGeo = this.asGpuRepr();
		const positions = triGeo.positionsF()!;
		const draw_range = triGeo.edgesDrawRange;
		const draw_end = Math.min(triGeo.edgesIndex.count, (draw_range.start + draw_range.count));
		const index = triGeo.edgesIndex.array as Uint32Array;
		const points: [Vector3, Vector3] = [Vector3.zero(), Vector3.zero()];
		for (let i = draw_range.start; i < draw_end; i += 2) {
			const ind1 = index[i];
			const ind2 = index[i + 1];
			const ind31 = ind1 * 3;
			const ind32 = ind2 * 3;
			points[0].set(positions[ind31 + 0], positions[ind31 + 1], positions[ind31 + 2]);
			points[1].set(positions[ind32 + 0], positions[ind32 + 1], positions[ind32 + 2]);
			yield points;
		}
	}

	raycast(ray: RaySection, modelMatrix: Matrix4, _camera: KrCamera): GeometryIntersection[] {
		const gpuRepr = this.asGpuRepr();
		const positions = gpuRepr.positionsF();
		if (!positions) {
			console.error('no positions in geo gpu repr, should be overriden');
			return [];
		}
		return GeometryUtils.raycastGeometry(gpuRepr, modelMatrix, ray);
	}

}


// for geometries that share vertex buffers
export abstract class EngineGeometrySharedGpu extends EngineGeometry {
	
	readonly additionalShaderInfo: PerGeoShaderInfo;

	constructor(
		gpuRepr: GeometryGpuRepr,
		additionalShaderInfo: PerGeoShaderInfo,
	) {
		super();
		this._gpuRepr = gpuRepr;
		this.additionalShaderInfo = additionalShaderInfo;
	}

	protected _calcGpuRepr(): GeometryGpuRepr {
		ErrorUtils.logThrow('calcGpuRepr should never be called for shared geo gpu', this);
	}
}


export abstract class EngineBimGeometry<Geo extends BimGeometryBase> extends EngineGeometry {

	constructor(
		public readonly bimGeo: Geo
	) {
		super(
		)
	}

	calcAabb(): Aabb {
		return this.bimGeo.calcAabb();
	}
}

export abstract class EngineBimGeometrySharedGpu<Geo extends BimGeometryBase> extends EngineGeometrySharedGpu {

	constructor(
		public readonly bimGeo: Geo,
		gpuRepr: GeometryGpuRepr,
		additionalShaderInfo: PerGeoShaderInfo,
	) {
		super(gpuRepr, additionalShaderInfo);
	}

	calcAabb(): Aabb {
		return this.bimGeo.calcAabb();
	}
}
