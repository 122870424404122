<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21 4C21 3.44772 20.5523 3 20 3H4C3.44772 3 3 3.44771 3 4L3.00001 20.0001C3.00001 20.5524 3.44772 21.0001 4.00001 21.0001H20C20.5523 21.0001 21 20.5524 21 20.0001V4ZM8.99999 5.00019L19 5.00017V7.00017H15C14.4477 7.00017 14 7.44788 14 8.00017V9.00017H11C11 8.44788 10.5523 8.00017 9.99999 8.00017H8.99999V5.00019ZM16 9.00017H19V19.0001L16 19.0001V9.00017ZM14 11.0002H11V19.0001L14 19.0001V11.0002ZM6.99999 5.0002L4.99999 5.00021V19.0002L8.99999 19.0001V10.0002H7.99999C7.44771 10.0002 6.99999 9.55245 6.99999 9.00017V5.0002Z"
        fill="currentColor"
    />
</svg>
