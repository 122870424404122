<script lang="ts">
    import { getContext } from "svelte";
    import type { ProjectVersion, VerDataSyncer } from "verdata-ts";
    import { PUI_Lazy } from "ui-bindings";
    import PUI_Root from "../pui/PUI_Root.svelte";
    import { DialogForm } from "../libui/dialog";
    import { editVersionUi } from "./VersionParams";

    export let cancel: () => void;
    export let versionDescription: Readonly<ProjectVersion>;

    const verDataSyncer = getContext("verDataSyncer") as VerDataSyncer;

    const paramsPui = editVersionUi(verDataSyncer, versionDescription, cancel);
</script>

<DialogForm dialogInput={{ title: "Edit description" }} close={cancel}>
    <div slot="body">
        <PUI_Root
            configIdentifier={"props-edit-version-params"}
            puiSource={new PUI_Lazy(paramsPui)}
        />
    </div>
</DialogForm>

<style lang="scss">
</style>
