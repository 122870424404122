import { XmlElement, XmlText, parseXml } from "@rgrove/parse-xml";
import { WGSCoord } from "bim-ts";
import { KmlFolder, KmlParseResult, KmlPolygon, KmlPolyline, KmlStyle } from "./kmlDataModel";
import { FileImporterContext, FileToImport } from "ui-bindings";
import { CompressionUtils } from "engine-utils-ts";
import { heightLimit } from "src/bim-assets/BimAssetsOriginHandling";


let elementIdCounter = 0;

function generateId() {
    return `id_${elementIdCounter++}`
}


function parseStyleNode(styleNode: XmlElement, kmlDataModel: KmlParseResult): string | undefined {

    if (!styleNode) {
        return;
    }
    let styleId = styleNode.attributes['id'];
    if (!styleId) {
        styleId = generateId();
    }

    const lineStyleNode = findChildXmlNode(styleNode, 'LineStyle');

    if (!lineStyleNode) {
        return;
    }

    const lineColorNode = findChildXmlNode(lineStyleNode, 'color');

    if (!lineColorNode) {
        return;
    }

    let colorString = normalizeColorString(lineColorNode.text);

    if (colorString == null) {
        console.error(`${styleId} has wrong color format!`)
        return;
    }

    const style: KmlStyle = { id: styleId, lineColor: colorString };
    kmlDataModel.styles[style.id] = style;

    return styleId;
}

function normalizeColorString(colorString: string): string | null {
    if (colorString.length === 6 || colorString.length === 8) {
        if (!colorString.startsWith('#')) {
            return '#' + colorString;
        } else {
            return colorString;
        }
    } else if ((colorString.length === 7 || colorString.length === 9) && colorString.startsWith('#')) {
        return colorString;
    } else {
        return null
    }
}

function getTextContent(node: XmlElement): string {
    const textContent = node.children.find(n => (n as XmlText)) as XmlText;
    return textContent?.text;
}

function findChildXmlNode(node: XmlElement, childName: string): XmlElement {
    const childNod = node.children.find((node) => (node as XmlElement).name === childName) as XmlElement;
    return childNod;
}

function filterChildXmlNode(node: XmlElement, childName: string): XmlElement[] {
    const childNod = node.children.filter(node => (node as XmlElement).name === childName) as XmlElement[];
    return childNod;
}

function getNodeName(node: XmlElement): string {
    const nameNode = findChildXmlNode(node, 'name');
    const name = nameNode ? getTextContent(nameNode) : "NoName";
    return name;
}


export function parseFile(file: FileToImport, context: FileImporterContext): KmlParseResult {
    if (file.extension === ".kmz") {
        try {
            const unzipedFiles = CompressionUtils.unzip(
                file.fileArrayBuffer
            );
            for (const [filename, fileData] of unzipedFiles) {
                if (!filename.endsWith(".kml")) {
                    continue;
                }
                return parseKml(fileData, file.filename);
            }
        } catch (e) {
            const t = `The file is not a valid KMZ/KML document. Most likely it was damaged or saved with the 
            options that are not yet complaint with the KMZ/KML standard. Please, try to save file again 
            without special/exotic options or send it to our support team for investigation.`;
            context.setMessage(t, true);
        }

        throw new Error('invalid file ' + file.filename);

    } else if (file.extension === ".kml") {
        return parseKml(file.fileArrayBuffer, file.filename);
    } else {
        throw new Error('invalid filename ' + file.filename);
    }
}

export function parseKml(arrayBuffer: ArrayBuffer, fileName: string) {

    const decoder = new TextDecoder("utf-8");
    const textData = decoder.decode(arrayBuffer);
    const doc = parseXml(textData, { preserveCdata: false, preserveComments: false, preserveDocumentType: true, preserveXmlDeclaration: true });
    const kmlNode = doc.children.find(
        (node) => node.type === "element" && (node as XmlElement).name === "kml"
    ) as XmlElement;

    if (!kmlNode) throw new Error("Invalid KML format.");

    const parseResult: KmlParseResult = { name: fileName, styles: {}, elements: [] }

    parseKmlNode(kmlNode, parseResult);

    return parseResult;
}

export function parseKmlNode(
    docNode: XmlElement,
    parseResult: KmlParseResult,
    folder?: KmlFolder,
    styleId?: string,

) {
    const children = docNode.children.filter(n => n instanceof XmlElement) as XmlElement[];

    for (const childEl of children) {

        const nodeName = childEl.name.toLocaleLowerCase();
        switch (nodeName) {
            case "folder": {
                const folderName = getNodeName(childEl);

                const subfolder: KmlFolder = {
                    name: folderName,
                    id: generateId(),
                    parentId: folder?.id ?? '',
                    children: [],
                    type: 'folder',
                };

                parseKmlNode(childEl, parseResult, subfolder);

                if (!subfolder.children.length) {
                    break;
                }

                if (folder === undefined) {
                    parseResult.elements.push(subfolder);
                } else {
                    folder.children.push(subfolder);
                }

                break;
            }
            case "document": {
                const styleNodes = filterChildXmlNode(childEl, 'Style');
                for (const styleNode of styleNodes) {
                    parseStyleNode(styleNode, parseResult);
                }

                const folderName = getNodeName(childEl);

                const subfolder: KmlFolder = {
                    name: folderName,
                    id: generateId(),
                    parentId: folder?.id ?? '',
                    children: [],
                    type: 'folder',
                };

                parseKmlNode(childEl, parseResult, subfolder);
                if (!subfolder.children.length) {
                    break;
                }
                if (folder === undefined) {
                    parseResult.elements.push(subfolder);
                } else {
                    folder.children.push(subfolder);
                }
                break;
            }
            case "placemark": {
                let placemarkStyleId: string | undefined = undefined;
                const placemarkStyleNode = findChildXmlNode(childEl, 'Style');
                if (placemarkStyleNode != null) {
                    placemarkStyleId = parseStyleNode(placemarkStyleNode, parseResult);
                } else {
                    const plasemarkUrlStyleNode = findChildXmlNode(childEl, 'styleUrl');
                    if (plasemarkUrlStyleNode != null) {
                        placemarkStyleId = getTextContent(plasemarkUrlStyleNode);
                    }
                }

                parseKmlNode(childEl, parseResult, folder, placemarkStyleId);
                break;
            }

            case "multigeometry": {

                const subfolder: KmlFolder = {
                    name: 'multigeometry',
                    id: generateId(),
                    parentId: folder?.id ?? '',
                    children: [],
                    type: 'folder',
                };

                parseKmlNode(childEl, parseResult, subfolder, styleId);
                if (!subfolder.children.length) {
                    break;
                }
                if (folder == null) {
                    parseResult.elements.push(subfolder);
                } else {
                    folder.children.push(subfolder);
                }
                break;
            }
            case "linestring": {
                const stringPoints = parseGeometryCoordinates(childEl);
                if (stringPoints?.length) {
                    if (stringPoints.length > 1) {
                        const name: string = 'polyline';

                        const polyline: KmlPolyline = {
                            name: name,
                            id: generateId(),
                            coordinates: stringPoints,
                            styleId: styleId,
                            parentId: folder?.id ?? '',
                            type: 'polyline'
                        }

                        if (folder == null) {
                            parseResult.elements.push(polyline);
                        } else {
                            folder.children.push(polyline);
                        }
                        break;

                    }
                }
                break;
            }

            case "polygon": {
                let stringPoints = parseGeometryCoordinates(childEl);
                if (stringPoints?.length) {
                    if (stringPoints.length > 2) {
                        if (
                            stringPoints[0].equals(
                                stringPoints[stringPoints.length - 1]
                            )
                        ) {
                            stringPoints.splice(stringPoints.length - 1);
                        }
                        const name: string = 'polygon';
                        const polygon: KmlPolygon = {
                            name: name,
                            id: generateId(),
                            coordinates: stringPoints,
                            styleId: styleId,
                            parentId: folder?.id ?? '',
                            type: 'polygon'
                        }

                        if (folder == null) {
                            parseResult.elements.push(polygon);
                        } else {
                            folder.children.push(polygon);
                        }
                        break;
                    }
                }
                break;
            }
        }
    }
}

export function parseKmlCoordinatesString(content: string) {
    const coords: WGSCoord[] = [];
    const numbers = content
        .split(/[\n\s]+/)
        .map((x) => x.split(",").map(Number));

    for (let i = 0; i < numbers.length; i++) {
        if (numbers[i].length < 2) {
            continue;
        }
        let alt = 0;
        if (numbers[i].length == 3) {
            alt = numbers[i][2];
            if(alt >= heightLimit){
                throw Error("Point elevation is too high!")
            }
        }

        const lon = numbers[i][0];
        const lat = numbers[i][1];

        const coord = WGSCoord.new(lat, lon, alt);

        if (coord !== null) {
            coords.push(coord);
        }
    }

    return coords;
}

export function parseGeometryCoordinates(poligonNode: XmlElement): WGSCoord[] | null {

    const children = poligonNode.children.filter(n => n instanceof XmlElement) as XmlElement[];
    for (const child of children) {
        if (child.name == 'coordinates') {
            const cont = child.text;
            const parsedCoordinates = parseKmlCoordinatesString(cont);
            return parsedCoordinates;
        } else {
            const result = parseGeometryCoordinates(child);
            if (result != null) {
                return parseGeometryCoordinates(child);
            }
        }
    }
    return null;
}

