import type { GridOptions, CellClassRules, ValueSetterParams, GridApi, ICellRendererComp, ICellRendererParams } from 'ag-grid-enterprise';
import type {
    BinsBuilder,
    PileTypeSettings,
    TrackerBins
} from 'bim-ts';
import { getPileWeightClass } from 'bim-ts';
import { DefaultGridOptions, pileIconCellRenderer } from './GridConfig';
import Switch from '../../../libui/checkbox/Switch.svelte';

interface GridContext {
    binsBuilder: BinsBuilder;
}

const cellClassRules: CellClassRules<PileTypeSettings> = {
    editable: (params) => params.colDef.editable instanceof Function ? params.colDef.editable(params) : false,
    readonly: (params) => params.colDef.editable instanceof Function ? !params.colDef.editable(params) : false,
    "text-main-medium": (params) => !params.data?.enabled.value,
}

export function getTypesGridOptions(data: TrackerBins, binsBuilder: BinsBuilder): GridOptions<PileTypeSettings> {
    const context: GridContext = {
        binsBuilder,
    }
    return {
        rowData: data.pileTypes,
        context,
        columnDefs: [
            {
                maxWidth: 26,
                cellClass: "ag-cell-icon",
                cellRenderer: (params: ICellRendererParams<PileTypeSettings>) => pileIconCellRenderer(params.data!.pileFeatures.value, "#0F1314B2"),
            },
            {
                maxWidth: 70,
                editable: (params) => !!params.data?.enabled.value,
                cellClassRules,
                valueGetter: (params) => params.data?.shortName.value,
                valueSetter: (params: ValueSetterParams<PileTypeSettings>) => {
                    if (!params.newValue || params.node === null || params.node.rowIndex === null) {
                        return false;
                    }
                    const builder: BinsBuilder = params.context.binsBuilder;
                    builder.saveChangesToConfig(
                        params.data.shortName.withDifferentValue(params.newValue),
                        ["pileTypes", params.node.rowIndex, "shortName"]
                    );
                    return true;
                },
            },
            {
                cellClassRules,
                valueGetter: (params) => {
                    if (!params.data) {
                        return;
                    }
                    const builder: BinsBuilder = params.context.binsBuilder;
                    return builder.getSelectedBinsConfig()?.getPileFullName(params.data.pileFeatures.value);
                },
            },
            {
                headerName: "Toggle",
                maxWidth: 50,
                cellClass: "toggle-cell",
                cellRenderer: TypeToggle,
            },
        ],
        ...DefaultGridOptions,
        getRowClass: (params) => {
            const nextRow = params.api.getDisplayedRowAtIndex(params.rowIndex + 1);
            if (nextRow && getPileWeightClass(nextRow.data!.pileFeatures.value) !== getPileWeightClass(params.node.data!.pileFeatures.value)
                || params.rowIndex === params.api.getDisplayedRowCount() - 1) {
                return 'divider';
            }
            return;
        },
    };
}

export function updateTableData(gridApi: GridApi<PileTypeSettings> | undefined, trackerBins: TrackerBins) {
    if (gridApi) {
        gridApi.setGridOption("rowData", trackerBins.pileTypes);
        gridApi.refreshCells({
            force: true,
            suppressFlash: true,
        });
    }
}

class TypeToggle implements ICellRendererComp<PileTypeSettings> {
    div!: HTMLDivElement;
    component!: Switch;
    getGui() {
        return this.div;
    }
    destroy() {
        this.component.$destroy();
    }
    init(params: ICellRendererParams<PileTypeSettings>) {
        if (!params.data) {
            return;
        }
        this.div = document.createElement("div");
        this.component = new Switch({
            target: this.div,
            props: {
                value: params.data.enabled.value,
                onChange: (value: boolean) => {
                    if (params.data && params.node.rowIndex !== null) {
                        const builder: BinsBuilder = params.context.binsBuilder;
                        builder.saveChangesToConfig(
                            params.data.enabled.withDifferentValue(value),
                            ["pileTypes", params.node.rowIndex, "enabled"]
                        );
                    }
                },
            },
        });
    }
    refresh() {
        return false;
    }
}