import { AreaTypeEnum, Bim, BoundaryTypeIdent, CombinerBoxTypeIdent, IdBimScene, InverterTypeIdent, MetricsGroup, NumberProperty, NumberRangeProperty, ProjectMetricsType, RoadTypeIdent, SceneInstance, SectionalizingCabinetIdent, StdGroupedMeshRepresentation, StdMeshRepresentation, SubstationTypeIdent, TMY_Props, TransformerIdent } from "bim-ts";
import { DefaultMap, IterUtils, RGBAHex, ScopedLogger, Yield } from "engine-utils-ts";
import jsPDF from "jspdf";
import { Aabb, Aabb2, Vector2 } from "math-ts";
import { AnnotationBuilder } from "../AnnotationsBuilder";
import { addLatoFonts } from "../fonts/fonts";
import { AnnotationDescription, DrawColor, GridStep, LayoutDrawing } from "../LayoutDrawing";
import { convertPxToMm, EquipmentDefaultColor, ExcludeBoundaryBorderColor, ExcludeBoundaryColor, IncludeBoundaryColor, RoadColor, SolarArraysTypes, SubstationColor } from "../PdfCommon";
import { PageFrameOffsets, PdfElement, PdfReportContext } from "../PdfReportBuilder";
import { setTextStyle, TextBuilder } from "../TextBuilder";
import { calculateContentSize } from "./LayoutPage";
import { calcTotalBounds } from "../ScreenshotMaker";

export class OverviewMetricsTable extends PdfElement {

    constructor(
        readonly bim: Bim,
        readonly metrics: MetricsGroup<Partial<ProjectMetricsType>>[],
        readonly logger: ScopedLogger,
    ) {
        super();
    }

    *draw({page, pageFrameOffsets}: PdfReportContext): Generator<Yield, void, unknown> {
        yield Yield.Asap;
        drawOverviewMetrics(page, this.bim, this.metrics, pageFrameOffsets, this.logger);
    }

    calculateContentSize(pageSize: Vector2, {pageFrameOffsets}: PdfReportContext): Aabb2 {
        const jspdf = new jsPDF({unit: 'mm'});
        addLatoFonts(jspdf);
        const builder = drawOverviewMetrics(jspdf, this.bim, this.metrics, pageFrameOffsets, this.logger);
        return calculateContentSize(pageSize, builder, pageFrameOffsets);
    }
}

function drawOverviewMetrics(page: jsPDF, bim: Bim, metrics: MetricsGroup<Partial<ProjectMetricsType>>[], offsets: PageFrameOffsets, logger: ScopedLogger) {
    const meteoDataConfig = bim.configs.peekSingleton('typical-meteo-year');
    const meteoData = meteoDataConfig?.propsAs(TMY_Props);
    const total = metrics.find((x) => x.type === AreaTypeEnum.Total);
    if (!total) {
        return;
    }

    const textBuilder = new TextBuilder(
        bim.unitsMapper,
        page, 
        offsets.upOffsetPx, 
        offsets.leftRightOffsetPx,
        total.metrics,
        logger,
        convertPxToMm
    );
    
    textBuilder.addRow({text: 'Site area', headerLevel: 1, values: [], style: 'bold'});
    textBuilder.addRow({
            text: 'Location', 
            values: [
                {value: meteoData ? convertLocation(meteoData?.locationContext.geoLocation.value) : 'n/a', style: meteoData ? 'normal' : 'transparent'}, 
                {value: meteoData ? "Meteodata " : "No meteodata", style: meteoData ? 'normal' : 'transparent', icon: meteoData ? "mark_selected" : undefined}
            ]
        });
    textBuilder.addFromMetric('buildable_area');
    textBuilder.addFromMetric('footprint');

    textBuilder.addRow({text: 'Equipment', headerLevel: 1, values: [], style: 'bold'});

    textBuilder.addGroupedFromMetric("PV modules", "dc_per_pv_module",  'pv_modules'); 

    textBuilder.addFromMetric('gcr', {name: "GCR", style: 'underline'});
    textBuilder.addOffset();
    
    textBuilder.addGroupedFromMetric("Trackers", "dc_per_tracker",  'trackers');
    textBuilder.addGroupedFromMetric("Any trackers", "dc_per_tracker",  'any_trackers');
    textBuilder.addOffset();
    textBuilder.addGroupedFromMetric("Fixed-tilt", "dc_per_tracker",  'fixed_tilt');  

    const row_to_row = total.metrics?.['row_to_row_range'];
    if(row_to_row instanceof NumberRangeProperty){
        const v = row_to_row.toConfiguredUnits(bim.unitsMapper);
        let value = `${v.value[0].toFixed(2)}-${v.value[1].toFixed(2)}`;
        if(v.value[0] === v.value[1]){
            value = `${v.value[0].toFixed(2)}`;
        }
        textBuilder.addRow({
            text: 'Row to row',
            style: 'underline',
            values: [{value: `${value}`, unit: v.unit, style: 'underline'}]
        });
    }
    textBuilder.addOffset();
    textBuilder.addGroupedFromMetric("Combiner boxes", '', "combiner_boxes");
    textBuilder.addOffset();
    textBuilder.addGroupedFromMetric("Inverters", '', "inverters");
    textBuilder.addOffset();  
    textBuilder.addGroupedFromMetric("Transformers", '', "transformers");

    textBuilder.addRow({text: 'Energy', fontSize: 15, headerLevel: 1, values: [], style: 'bold'});
    textBuilder.addFromMetric('dc_total', {name: "DC total"});

    if(total.metrics?.not_connected_to_transformer_dc){
        textBuilder.addFromMetric('not_connected_to_transformer_dc', { name: "Solar arrays not connected to the inverter or transformer" });
    }

    textBuilder.addFromMetric('ac_total', {name: "AC total"});
    textBuilder.addFromMetric('dc_ac_ratio', {name: "DC/AC ratio"});
    textBuilder.addOffset();
    const lv_loss = total.metrics?.['lv_loss'];
    const lv_voltage_drop = total.metrics?.['average_lv_voltage_drop'];
    if(lv_loss instanceof NumberProperty && lv_voltage_drop instanceof NumberProperty){ 
        const lossUnit = lv_loss.toConfiguredUnits(bim.unitsMapper);
        const voltageUnit = lv_voltage_drop.toConfiguredUnits(bim.unitsMapper);
        textBuilder.addRow({
            text: 'LV voltage drop',
            values: [
                {value: lossUnit.value.toFixed(2), unit: lossUnit.unit},
                {value: voltageUnit.value.toFixed(2), unit: voltageUnit.unit}
            ]
        });
    }
    const mv_loss = total.metrics?.['mv_loss'];
    const mv_voltage_drop = total.metrics?.['average_mv_voltage_drop'];
    if(mv_loss instanceof NumberProperty && mv_voltage_drop instanceof NumberProperty){ 
        const lossUnit = mv_loss.toConfiguredUnits(bim.unitsMapper);
        const voltageUnit = mv_voltage_drop.toConfiguredUnits(bim.unitsMapper);
        textBuilder.addRow({
            text: 'MV voltage drop',
            values: [
                {value: lossUnit.value.toFixed(2), unit: lossUnit.unit},
                {value: voltageUnit.value.toFixed(2), unit: voltageUnit.unit}
            ]
        });
    }
    textBuilder.addOffset();
    const energy_performance = total.metrics?.['energy_performance'];
    const energy_yield_total = total.metrics?.['energy_yield_total'];
    if(energy_performance instanceof NumberProperty && energy_yield_total instanceof NumberProperty){
        const performanceUnit = energy_performance.toConfiguredUnits(bim.unitsMapper);
        const yieldUnit = energy_yield_total.toConfiguredUnits(bim.unitsMapper);
        textBuilder.addRow({
            text: 'Year 1 energy yield and performance',
            values: [
                {value: yieldUnit.value.toFixed(2), unit: yieldUnit.unit},
                {value: performanceUnit.value.toFixed(2), unit: performanceUnit.unit},
            ]
        });
    }
    textBuilder.addFromMetric('specific_annual_yield');
    textBuilder.addRow({text: 'Civil', headerLevel: 1, values: [], style: 'bold'});
    const trenches_volume = total.metrics?.['trenches_volume'];
    const trenches_length = total.metrics?.['trenches_length'];
    if(trenches_volume instanceof NumberProperty && trenches_length instanceof NumberProperty){
        const volumeUnit = trenches_volume.toConfiguredUnits(bim.unitsMapper);
        const lengthUnit = trenches_length.toConfiguredUnits(bim.unitsMapper);
        textBuilder.addRow({
            text: 'Trenches',
            values: [
                {value: volumeUnit.value.toFixed(0), unit: volumeUnit.unit},
                {value: lengthUnit.value.toFixed(0), unit: lengthUnit.unit},
            ]
        });
    } else {
        textBuilder.addRow({
            text: 'Trenches',
            values: [{value: 'n/a', style: 'transparent'}]
        });
    }
    textBuilder.addFromMetric('cut_fill_total');
    textBuilder.addFromMetric('cut_fill_net_balance');

    return textBuilder;
}

export class ScreenshotByBlock extends PdfElement {
    constructor(
        readonly bim: Bim,
        readonly logger: ScopedLogger,
    ){
        super();
    }

    *draw({page, layoutDrawing, pageFrameOffsets}: PdfReportContext) {
        const types = [
            RoadTypeIdent,
            'wire',
            CombinerBoxTypeIdent,
            InverterTypeIdent,
            TransformerIdent,
            ...SolarArraysTypes,
            SectionalizingCabinetIdent,
            SubstationTypeIdent,
        ];
        const instances = this.bim.instances.peekByTypeIdents([...types, BoundaryTypeIdent]);

        const allIds = IterUtils.filterMap<[IdBimScene, SceneInstance], IdBimScene>(instances, ([id, si]) => {
            if (si.type_identifier !== BoundaryTypeIdent) {
                return id;
            }
            const boundaryType = si.properties.get('boundary | source_type')?.asText();
            if(boundaryType === 'origin' || boundaryType == undefined){
                return id;
            }
            return;
        });
        const hierarchyColorsMap = this.bim.instances.getColorsOfHierarchies(allIds);
        const idPerColor = new Map<IdBimScene, DrawColor>();
        for (const [id, inst] of instances) {
            if(inst.type_identifier === RoadTypeIdent) {
                idPerColor.set(id, {borderColor:RoadColor});
            } else if(inst.type_identifier === SubstationTypeIdent) {
                idPerColor.set(id, {borderColor: SubstationColor});
            } else if(inst.type_identifier === BoundaryTypeIdent) { 
                const type = inst.properties.get('boundary | boundary_type')?.asText();
                if(type === 'include'){
                    idPerColor.set(id, {borderColor:IncludeBoundaryColor});
                } else {
                    idPerColor.set(id, {borderColor:ExcludeBoundaryBorderColor, backgroundColor: ExcludeBoundaryColor});
                }
            } else {
                const color = hierarchyColorsMap.get(id);
                idPerColor.set(id, {
                    borderColor: color ?? EquipmentDefaultColor,
                });
            }
        }
        const transformers = this.bim.instances.peekByTypeIdents([TransformerIdent]);
        const metrics: {
            blockNumber: number | undefined,
            aabb: Aabb,
            dcPowerMW: number,
            metrics: DefaultMap<number, {trackerCount: number}>,
        }[] = [];
        const reused = Aabb.empty();
        const geos = this.bim.allBimGeometries.aabbs.poll();
        for (const [id, inst] of transformers) {
            const blockNumber = inst.properties.get("circuit | position | block_number")?.asNumber();
            const dcPowerMW = inst.properties.get("circuit | block_capacity | dc_power")?.as("MW") ?? 0;
            const modulesCountPerTrackerCount = new DefaultMap<number, {trackerCount: number}>(() => ({trackerCount: 0}));
            const totalBounds = Aabb.empty();
            for (const child of this.bim.instances.spatialHierarchy.iteratorOfChildrenOf(id)) {
                const childInst = this.bim.instances.peekById(child);
                if(!child || childInst?.type_identifier === 'wire'){
                    continue;
                }
                this.bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(
                    child, 
                    (childId) => {
                        const child = this.bim.instances.peekById(childId);
                        if(child && SolarArraysTypes.includes(child.type_identifier)){
                            const modulesCount = child.properties.get("circuit | equipment | modules_count")?.asNumber();
                            if(modulesCount) {
                                modulesCountPerTrackerCount.getOrCreate(modulesCount).trackerCount++;
                            }
                        }
                        const representation = child?.representation;
                        if(!representation){
                            return true;
                        }
                        let localAabb: Aabb | undefined;
                        if (
                            representation instanceof StdGroupedMeshRepresentation ||
                            representation instanceof StdMeshRepresentation
                        ) {
                            // trackers should have lods
                            if (representation.lod1) {
                                // find out
                                localAabb = representation.lod1.aabb(geos);
                            } else {
                                localAabb = representation.aabb(geos);
                            }
                            
                        } else {
                            this.logger.warn('Unknown representation type', representation);
                        }
                        if(localAabb) {
                            reused.copy(localAabb).applyMatrix4(child.worldMatrix);
                            totalBounds.union(reused);                    
                        }
                
                        return true;
                    }, 
                );
            }
            if(dcPowerMW > 0 && !totalBounds.isEmpty()){
                metrics.push({blockNumber, aabb: totalBounds, dcPowerMW, metrics: modulesCountPerTrackerCount});
            }
        }
        let maxBlockNumber = IterUtils.max(metrics.filter(m => m.blockNumber !== undefined).map(m => m.blockNumber ?? 0)) ?? 0;

        const annotations: AnnotationDescription[] = [];
        for (const metric of metrics) {
            const desc: AnnotationDescription = {
                aabb: metric.aabb,
                metrics: [
                    `${metric.blockNumber ?? ++maxBlockNumber}`,
                ]
            }
            for (const [modulesCount, {trackerCount}] of metric.metrics) {
                desc.metrics.push(`${modulesCount} / ${trackerCount}`);
            }
            desc.metrics.push(`${metric.dcPowerMW.toFixed(3)} MW`);
            annotations.push(desc);
        }

        yield* drawLayoutOnPageByTemplate1({
            bim: this.bim,
            page, 
            ids: allIds, 
            layoutDrawing, 
            offsets: pageFrameOffsets,
            colorize: (id) => {
                return idPerColor.get(id) ?? {borderColor: 0};
            },
            grid: true,
            annotations,
        });
        yield Yield.Asap;
        const colors = new Set<RGBAHex>();
        for (const [id] of transformers) {
            const color = idPerColor.get(id)?.borderColor;
            if(color){
                colors.add(color);
            }
        }
        addOverviewImageAnnotations(page, colors, pageFrameOffsets, layoutDrawing.getLastCalculatedGrid());
    }
}

function addOverviewImageAnnotations(
    page: jsPDF, 
    colors: Set<RGBAHex>, 
    offsets: PageFrameOffsets, 
    grid?: GridStep
){
    const annotations = new AnnotationBuilder(page, offsets);
    annotations.addBoxes({
        text: "Block",
        colors: Array.from(colors).sort(),
    });
    annotations.addBoxes({
        text: 'Substation',
        colors: [SubstationColor]
    });
    annotations.addLine({
        text: 'Road',
        color: RoadColor
    });
    annotations.addLine({
        text: 'Include boundary',
        color: IncludeBoundaryColor
    });
    annotations.addLineWithBox({
        text: "Exclude boundary",
        colorBox: ExcludeBoundaryColor,
        colorLine: ExcludeBoundaryBorderColor,
    });

    if(grid){
        annotations.addGrid({
            text: `${grid.step} ${grid.unit}`,
            width: grid.widthLocalSystem,
        });
    }
}

function convertDegree(degree: string): string {
    const num = parseFloat(degree);
    if(!isFinite(num)){
        return "";
    }
    const deg = 0 | num;
    const min = Math.abs(0 | (num - deg) * 60);
    const sec = Math.abs(0 | (0 | ((((num - deg - min/60) * 60) % 1) * 6000)) / 100);
    return `${deg}° ${min}' ${sec}"`;
}

function convertLocation(value: string){
    const [lat, long] = value.split(',');
    if(!lat || !long){
        return 'n/a';
    }
    return `${convertDegree(lat)} ${convertDegree(long)}`;
}

export function* drawLayoutOnPageByTemplate1(args: {
    bim: Bim,
    page: jsPDF, 
    layoutDrawing: LayoutDrawing,
    header?: string,
    ids: IdBimScene[], 
    focusIds?: IdBimScene[], 
    offsets: PageFrameOffsets,
    colorize: (id: IdBimScene) => DrawColor;
    grid?: boolean;
    annotations?: AnnotationDescription[],
    align?: 'mid' | 'top'
}){
    const headerOffsetPx = 15;
    const align = args.align ?? 'mid';
    const logger = new ScopedLogger("draw layout");
    const pageWidth = args.page.internal.pageSize.getWidth();
    const pageHeight = args.page.internal.pageSize.getHeight();
    const downAdditionOffsetPx = 50;
    const rightPartWidthRation = 1 - args.offsets.leftPartContentWidthRatio;
    const headerOffset = args.header ? convertPxToMm(headerOffsetPx + args.offsets.topContentOffsetPx + 15) : 0;
    const maxHeight = pageHeight 
        - convertPxToMm(args.offsets.upOffsetPx) 
        - convertPxToMm(args.offsets.downOffsetPx)
        - convertPxToMm(args.offsets.topContentOffsetPx)
        - convertPxToMm(downAdditionOffsetPx)
        - headerOffset;
    const maxWidth = pageWidth * rightPartWidthRation 
        - convertPxToMm(args.offsets.leftRightOffsetPx);
    
    const imageSize = calcTotalBounds(args.bim, args.focusIds ? {ids: args.focusIds } : { ids: args.ids }, logger);
    const worldBoundsSize = imageSize.wsCoords.getSize();

    const worldToPageScale = Math.min(
        maxWidth / worldBoundsSize.x,
        maxHeight / worldBoundsSize.y
    );
    const realImageHeight = worldBoundsSize.y * worldToPageScale;
    const yOffset = align === 'mid' 
        ? maxHeight * 0.5 + headerOffset
        : realImageHeight * 0.5 + headerOffset;
    const drawingCenter = new Vector2(
        pageWidth * args.offsets.leftPartContentWidthRatio + maxWidth * 0.5,
        convertPxToMm(args.offsets.upOffsetPx) + convertPxToMm(args.offsets.topContentOffsetPx) + yOffset
    );

    yield* args.layoutDrawing.drawLayout({
        page: args.page,
        imageSize: new Vector2(maxWidth, maxHeight),
        position: drawingCenter,
        ids: args.ids,
        focusSettings: args.focusIds ? { ids: args.focusIds } : undefined,
        colorize: args.colorize,
        grid: args.grid,
        annotations: args.annotations,
    });

    if(args.header){
        const posX = pageWidth * args.offsets.leftPartContentWidthRatio;
        const fontSize = 15;
        const upOffsetPx = args.offsets.upOffsetPx + fontSize + headerOffsetPx;
        setTextStyle(args.page, "bold", fontSize);
        args.page.text(args.header, posX, convertPxToMm(upOffsetPx));
    }
}