import type { Bim, PropertyGroup} from "bim-ts";
import { SceneInstancesProperty } from "bim-ts";
import type { ScopedLogger } from "engine-utils-ts";
import { patchConfigProperty } from "./GeneratePanelUiBindings";
import type { PropertyPathType} from "bim-ts";
import { ConfigUtils } from "bim-ts";

export function removeDeletedSceneInstances<T extends PropertyGroup>(
    config: T,
    bim: Bim,
    logger: ScopedLogger, 
    skipPaths: PropertyPathType[][], 
    onUpdate:(props: T) => void
    ){
    let updatedProps = config;
    let haveUpdates = false;
    const instances = new Map(bim.instances.readAll());
    ConfigUtils.traverseByProperties(
        config, 
        (prop, path) => {
            if(prop instanceof SceneInstancesProperty){
                const filteredValues = prop.value.filter(id => instances.has(id));
                if(filteredValues.length !== prop.value.length){
                    const updatedProp = new SceneInstancesProperty({
                        ...prop,
                        value: filteredValues,
                    });
                    updatedProps = patchConfigProperty(updatedProps, path, updatedProp);
                    haveUpdates = true;
                }
            }
        },
        skipPaths ?? [], 
    );
    
    if(haveUpdates){
        onUpdate(updatedProps);
    }
}