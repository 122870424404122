// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { TransformT } from './transform.js';
import { Transform } from './transform.js';


export class StdSubmeshRepresentation implements flatbuffers.IUnpackableObject<StdSubmeshRepresentationT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):StdSubmeshRepresentation {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsStdSubmeshRepresentation(bb:flatbuffers.ByteBuffer, obj?:StdSubmeshRepresentation):StdSubmeshRepresentation {
  return (obj || new StdSubmeshRepresentation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsStdSubmeshRepresentation(bb:flatbuffers.ByteBuffer, obj?:StdSubmeshRepresentation):StdSubmeshRepresentation {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new StdSubmeshRepresentation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

geometryId():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

materialId():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

transform(obj?:Transform):Transform|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new Transform()).__init(this.bb_pos + offset, this.bb!) : null;
}

static startStdSubmeshRepresentation(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addGeometryId(builder:flatbuffers.Builder, geometryId:number) {
  builder.addFieldInt32(0, geometryId, 0);
}

static addMaterialId(builder:flatbuffers.Builder, materialId:number) {
  builder.addFieldInt32(1, materialId, 0);
}

static addTransform(builder:flatbuffers.Builder, transformOffset:flatbuffers.Offset) {
  builder.addFieldStruct(2, transformOffset, 0);
}

static endStdSubmeshRepresentation(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): StdSubmeshRepresentationT {
  return new StdSubmeshRepresentationT(
    this.geometryId(),
    this.materialId(),
    (this.transform() !== null ? this.transform()!.unpack() : null)
  );
}


unpackTo(_o: StdSubmeshRepresentationT): void {
  _o.geometryId = this.geometryId();
  _o.materialId = this.materialId();
  _o.transform = (this.transform() !== null ? this.transform()!.unpack() : null);
}
}

export class StdSubmeshRepresentationT implements flatbuffers.IGeneratedObject {
constructor(
  public geometryId: number = 0,
  public materialId: number = 0,
  public transform: TransformT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  StdSubmeshRepresentation.startStdSubmeshRepresentation(builder);
  StdSubmeshRepresentation.addGeometryId(builder, this.geometryId);
  StdSubmeshRepresentation.addMaterialId(builder, this.materialId);
  StdSubmeshRepresentation.addTransform(builder, (this.transform !== null ? this.transform!.pack(builder) : 0));

  return StdSubmeshRepresentation.endStdSubmeshRepresentation(builder);
}
}
