import type { IdBimGeo, IdInEntityLocal, SceneInstance} from "bim-ts";
import { GraphGeometry, LocalIdsCounter, PolylineGeometry, TriGeometry } from "bim-ts";
import earcut from "earcut";
import type { Result} from "engine-utils-ts";
import { CollectionBasic, Failure, IterUtils, MappedCollectionParallel, Success } from "engine-utils-ts";
import type { IdEngineGeo } from "../geometries/AllEngineGeometries";
import { EngineGeoTriangle } from "../geometries/EngineGeoTriangle";
import type { ESOHandle, ESOsCollection } from "../scene/ESOsCollection";
import { ESO } from "./ESO";
import type { ESOsHandlerInputs } from "./ESOsHandlerBase";
import { ESO_Diff } from "./ESO_Diff";
import { ESO_SingleGeoHandler } from "./ESO_SingleGeo";
import type { SegmentTriangulationRepr} from "engine-in-worker-ts";
import { SegmentAnalyt, GraphTriangulationJobExecutor } from "engine-in-worker-ts";
import { Vector3 } from 'math-ts';


export abstract class ESO_SingleGeoWidth extends ESO {

	abstract width: number;

	constructor(
		sceneInstanceRef: Readonly<SceneInstance>,
	) {
		super(sceneInstanceRef);
	}

	abstract geometryId(): IdBimGeo | undefined;

}

export abstract class ESO_GraphTriangulated<ESO_T extends ESO_SingleGeoWidth> extends ESO_SingleGeoHandler<ESO_T> {

	readonly objectsCollectionBasic: CollectionBasic<ESO_T, ESOHandle>; // TODO: make whole eso handler basic collection??

	readonly triangulationCalculations: MappedCollectionParallel<
		IdEngineGeo[], ESOHandle, ESO_T, SegmentAnalyt[], Map<number, SegmentTriangulationRepr>
	>;
	readonly _objsToUpdateTriangulationsFor = new Set<ESOHandle>();

	constructor(
		identifier: string,
		args: ESOsHandlerInputs,
	) {
		super(identifier, args);

		this.objectsCollectionBasic = new CollectionBasic(`${identifier}-objs-basic`);

		this.triangulationCalculations = new MappedCollectionParallel({
			identifier: 'graph-triang-geos',
			logger: args.logger,
			dataSource: this.objectsCollectionBasic,
			mapExecutorCtor: GraphTriangulationJobExecutor,
			toJobArgsConverter: (obj) => this._extractTriangulationArgsFor(obj),
			fromJobResultConverter: (polygons) => {
				const res: IdEngineGeo[] = [];
				const geosToAlloc: [IdEngineGeo, EngineGeoTriangle][] = [];
				for (const [id, polygonsPoints] of polygons) {
					for (const polygon of polygonsPoints.polygons) {
						const geo = new EngineGeoTriangle(polygonPointsToTriangulation(polygon));
						const geoId = this.geometries.triGeometries.reserveEngineOnlyId();
						geosToAlloc.push([geoId, geo]);
						res.push(geoId);
					}
				}
				this.geometries.allocate(geosToAlloc);
				return res;
			},
		});
		this.triangulationCalculations.updatesStream.subscribe({
			settings: {immediateMode: true},
			onNext: (update) => {
				for (const id of update.ids) {
					this.markDirty(id, ESO_Diff.RepresentationBreaking);
				}
			}
		});
	}

    isSynced() {
        if (this._dirtyObjects.size > 0) {
            return false;
        }
        if (this._objsToUpdateTriangulationsFor.size > 0) {
            return false;
        }
        if (!this.triangulationCalculations.isSynced()) {
            return false;
        }
        return true;
    }

	markForTriangulationUpdate(handle: ESOHandle) {
		this._objsToUpdateTriangulationsFor.add(handle);
	}

	onAllocated(handles: Iterable<ESOHandle>): void {
		super.onAllocated(handles);
		IterUtils.extendSet(this._objsToUpdateTriangulationsFor, handles);
	}
	onDeleted(handles: ESOHandle[]): void {
		super.onDeleted(handles);
		this.objectsCollectionBasic.delete(handles);
	}


	_extractTriangulationArgsFor(obj: ESO_T): Result<SegmentAnalyt[]> {
		const geoId = obj.geometryId()!;
		const geo = this.bimGeos.peekById(geoId);
		if (!geo) {
			return new Failure({msg: 'absent geometry'});
		}

		const allSegments: SegmentAnalyt[] = [];
		if (geo instanceof PolylineGeometry) {
			const wsPoints = Vector3.arrayFromFlatArray(geo.points3d);
			for (const p of wsPoints) {
				p.applyMatrix4(obj.worldMatrix);
			}
			for (let i = 1; i < wsPoints.length; ++i) {
				const p1ws = wsPoints[i - 1];
				const p2ws = wsPoints[i + 0];
				allSegments.push(new SegmentAnalyt(
					LocalIdsCounter.newEdge((i-1) as IdInEntityLocal, i as IdInEntityLocal),
					p1ws,
					p2ws,
					obj.width
				));
			}
		} else if (geo instanceof GraphGeometry) {
			for (const [p1, p2, edge, interp] of geo.iterEdgesPoints()) {
				if (p1.xy().distanceToSquared(p2.xy()) < 0.0001) {
					continue;
				}
				const p1ws = p1.clone().applyMatrix4(obj.worldMatrix);
				const p2ws = p2.clone().applyMatrix4(obj.worldMatrix);
				allSegments.push(new SegmentAnalyt(
					edge,
					p1ws,
					p2ws,
					obj.width
				));
			}
		} else {
			return new Failure({msg: 'unsupported geo type'});
		}
		return new Success(allSegments);
	}


	applySelfImposedUpdates(coll: Readonly<ESOsCollection>): void {
		if (this._objsToUpdateTriangulationsFor.size > 0) {
			try {
				const toUpdate: [ESOHandle, ESO_T][] = [];
				for (const handle of this._objsToUpdateTriangulationsFor) {
					const obj = coll.peek(handle);
					if (obj === undefined) {
						continue;
					}
					toUpdate.push([handle, obj as ESO_T]);
				}
				this.objectsCollectionBasic.allocateOrUpdate(toUpdate);
			} catch (e) {
				this.logger.error(e);
			} finally {
				this._objsToUpdateTriangulationsFor.clear();
			}
		}
		// this.triangulationCalculations.poll();
	}

}


export function polygonPointsToTriangulation(points3d: Float32Array): TriGeometry {
	const positionsFlat2d = new Float32Array(points3d.length / 3 * 2);
	const normals3D = new Int8Array(points3d.length);
	for (let i = 0; i < points3d.length; i += 3) {
		const ind = i / 3;
		positionsFlat2d[ind * 2 + 0] = points3d[i + 0];
		positionsFlat2d[ind * 2 + 1] = points3d[i + 1];

		normals3D[ind * 3 + 2] = 127;

	}
	const indices = earcut([...positionsFlat2d]);
	const geo = new TriGeometry(
		points3d,
		normals3D,
		null,
		new Uint32Array(indices),
		new Uint16Array([]),
	);
	return geo;
}

