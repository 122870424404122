import { requestExecutionFrame } from './ExecutionFrame';
import { DeferredPromise } from './DeferredPromise';
import type { Result} from './Result';
import { resultify } from './Result';
import type { ScopedLogger } from './ScopedLogger';
import type {
    EventStackFrame} from './stateSync/EventsStackFrame';
import { unsafePopFromEventStackFrame, unsafePushToEventStackFrame
} from './stateSync/EventsStackFrame';
import { ObservableStream } from './stateSync/ObservableStream';
import { Failure, Success } from './Result';

let _task_ids: number = 0;

export class LongTask<T> {

	identifier: string;
	id: number;
	defferedPromise: DeferredPromise<T>;

	tasksRunner: TasksRunner;
	eventParams: Partial<EventStackFrame> | undefined;

	private _createdAt: number = performance.now();
	private _finishedAt: number = 0;
	
	private _result: Result<T>;
	private _logger: ScopedLogger;
	private _defaultGenerator: Generator<Yield, T> | undefined;

	constructor(
		defaultValue: T | undefined,
		identifier: string,
		logger: ScopedLogger,
		resultPromise: DeferredPromise<T>,
		defaultRoutine: Generator<Yield, T> | undefined,
		eventParams: Partial<EventStackFrame> | undefined,
		TasksRunner: TasksRunner
	) {
		this.identifier = identifier;
		this.id = _task_ids += 1;
		this.defferedPromise = resultPromise;
		this.tasksRunner = TasksRunner;
		this._defaultGenerator = defaultRoutine;
		this._result = defaultValue !== undefined ? new Success(defaultValue) : new Failure({msg: 'no default was provided'});
		this._logger = logger.newScope(`task_${this.id}_${this.identifier}`);
		this.eventParams = eventParams;
	}

	totalDurationSeconds(): number {
		if (this._finishedAt) {
			return (this._finishedAt - this._createdAt) / 1000;
		}
		return (performance.now() - this._createdAt) / 1000;
	}

	asPromise(): Promise<T> {
		return this.defferedPromise.promise;
	}

	asResult(): Result<T> {
		return this._result;
	}

	asFinalizedResult(): Result<T> {
		if (!this.defferedPromise.isFinalized()) {
			throw new Error(`task(${this.identifier}) should be finalized before result is ready`);
		}
		if (!(this._result instanceof Success || this._result instanceof Failure)) {
			return new Failure({msg: 'invalid result type'});
		}
		return this._result;
	}

	isFinalized(): boolean {
		return this.defferedPromise.isFinalized();
	}

	reject(reason?: any) {
		this._result = new Failure({msg: reason});
		this.defferedPromise.reject(reason);
		this._finishedAt = performance.now();
	}

	static *_runTaskRoutine<T>(task: LongTask<T>) {
		if (task._defaultGenerator) {
			try {
				const nextValue =  yield* task._defaultGenerator;
				task._result = resultify(nextValue);
			} catch (e) {
				task._logger.error(e);
				task._result = new Failure({msg: '' + e});
			}
		}
		task._finishedAt = performance.now();
	}

}


export enum Yield {
	Asap = 0,
	NextFrame = 1
}

export class TaskExecutionState {
	startTimeMs: number = performance.now();
	activeExecutionTimeMs: number = 0;

	constructor() {
	}
}


export class TasksRunner {

	logger: ScopedLogger;

	newLongTasksStream: ObservableStream<{ task: LongTask<any>, taskStateStream: ObservableStream<TaskExecutionState> }>;

	private _tasks: GeneratorExecutionWrapper<any>[] = [];

	private disposed = false;
	private isSelfRunning = false;
	private selfRunningIntervalMs = 10;


	constructor(logger: ScopedLogger) {
		this.logger = logger.newScope('generators');
		this.newLongTasksStream = new ObservableStream({ identifier: this.logger.scopeMsg + 'new_long_tasks' });
	}

	dispose() {
		this.disposed = true;
	}

	withSelfRunning(intervalMs?: number) {
		if (intervalMs) {
			this.selfRunningIntervalMs = intervalMs;
		}
		this.loop();
		return this;
	}

	private loop = () => {
		if (this.disposed && !this.isSelfRunning) {
			return;
		}
		this.run(this.selfRunningIntervalMs);
		requestExecutionFrame(this.loop);
	}

	// deprecated, prefer newLongTask
	scheduleGenerator<T>(generator: Generator<Yield, T>, resultTypeChecker?: (res: any | T) => boolean): Promise<T> {
		const t = this.newLongTask<T>({
			defaultGenerator: generator,
			resultTypeChecker
		})
		return t.asPromise();
	}

	newLongTask<T>(params: {
		defaultResult?: T,
		defaultGenerator?: Generator<Yield, T>,
		taskTimeoutMs?: number,
		identifier?: string,
		eventParams?: Partial<EventStackFrame>,
		resultTypeChecker?: (res: any | T) => boolean
	}): LongTask<T> {
		const resultPromise = new DeferredPromise<T>(params.taskTimeoutMs)
		const task = new LongTask(
			params.defaultResult,
			params.identifier ?? 'unnamed',
			this.logger,
			resultPromise,
			params.defaultGenerator,
			params.eventParams,
			this
		);

		const state: GeneratorExecutionWrapper<T> = {
			task,
			generator: LongTask._runTaskRoutine(task),
			yieldTime: Yield.Asap,
			resultTypeChecker: params.resultTypeChecker,
			taskState: new TaskExecutionState(),
			taskStateStream: new ObservableStream({ identifier: `${task.identifier}-state-stream` }),
		};
		task.asPromise().finally(() => state.taskStateStream.dispose());

		this._tasks.push(state);

		this.newLongTasksStream.pushNext({ task, taskStateStream: state.taskStateStream });
		return task;
	}

	run(durationLimit: number) {
		const runStartT = performance.now();

		for (const c of this._tasks) {
			c.yieldTime = Yield.Asap;
		}
		let routinesToExecuteThisTimeLeft: boolean;

		do {
			routinesToExecuteThisTimeLeft = false;

			for (let i = 0; i < this._tasks.length; ++i) {
				const c = this._tasks[i];

				if (c.task.isFinalized()) {
					this._tasks.splice(i--, 1);
					continue;
				}

				if (c.yieldTime === Yield.NextFrame) {
					continue;
				}

				routinesToExecuteThisTimeLeft = true;


				let customEventPushed: Readonly<EventStackFrame> | null = null;
				if (c.task.eventParams) {
					customEventPushed = unsafePushToEventStackFrame(c.task.eventParams);
				}

				const startT = performance.now();
				try {
					const res = c.generator.next();
					if (res.done) {
						const resultTask = c.task.asResult();
						if (resultTask instanceof Success && (!c.resultTypeChecker || c.resultTypeChecker(resultTask.value))) {
							c.task.defferedPromise.resolve(resultTask.value);
						} else {
							c.task.defferedPromise.reject([
								'generator result type check failed',
								c.resultTypeChecker?.toString(),
								resultTask,
							]);
						}
					} else {
						if (res.value === Yield.NextFrame) {
							c.yieldTime = Yield.NextFrame;
						}
					}
				} catch (error) {
					c.task.reject(error);
				} finally {
					if (customEventPushed) {
						unsafePopFromEventStackFrame(customEventPushed);
					}
					const duration = performance.now() - startT;
					c.taskState.activeExecutionTimeMs += duration;
					c.taskStateStream.pushNext(c.taskState);
				}
			}
		} while (routinesToExecuteThisTimeLeft && performance.now() - runStartT < durationLimit);
	}

	clear(reject: boolean) {
		if (reject) {
			for (const c of this._tasks) {
				c.task.reject('disposing generators runner');
			}
		}
		this._tasks.length = 0;
	}
}


interface GeneratorExecutionWrapper<T> {
	task: LongTask<T>
	generator: Generator<Yield, void>;
	yieldTime: Yield;
	resultTypeChecker?: (res: any) => boolean;
	taskState: TaskExecutionState;
	taskStateStream: ObservableStream<TaskExecutionState>;
}

