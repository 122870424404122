import { AgPromise, ColDef, ICellEditorComp, ICellEditorParams, ValueFormatterFunc, ValueSetterParams } from "ag-grid-enterprise";
import { CostModel as CM, UnitsMapper } from 'bim-ts';
import { getContext, commonCellClasses, canEditCommonCell } from "./common";
import CostSourceDropdown from '../../CostSourceDropdown.svelte';
import { GridContext } from "../types";


export const createCostSourceColumnDefinition = (): ColDef<CM.FlattenedCostCategoryParams> => ({
    colId: CM.CostSourceColId,
    headerName: CM.CostSourceColId,
    valueGetter: (params) => {
        const { category } = getContext(params)
        if (!category) {
            return;
        }
        const index = category.costSource?.index
        if (index === undefined) {
            return;
        }
        return category.costSource?.options?.[index];
    },

    editable: (params) => {
        const context = getContext(params);
        if (!context) {
            return false;
        }
        if (!canEditCommonCell(context, context?.category?.costSource)) {
            return false
        }
        return !!context.category?.costSource?.update;
    },

    valueSetter: (params: ValueSetterParams<CM.FlattenedCostCategoryParams, string>) => {
        const context = getContext(params);
        if (typeof params.newValue !== 'string') {
            return false;
        }
        const newIndex = context.category?.costSource?.options?.findIndex(x => params.newValue === x)
        if (newIndex === undefined) {
            return false;
        }
        context.category?.costSource?.update?.(newIndex);
        //if (context.category?.costSource) {
        //    context.category.costSource.index = newIndex;
        //}
        return true;
    },

    valueFormatter: ((params) => {
        switch (params.value) {
            case (CM.CostSourceType.BenchmarkLumpSum):
            case (CM.CostSourceType.BenchmarkPerQuantity): {
                return "Benchmark";
            }
            case (CM.CostSourceType.GroupSum): {
                return "Group sum";
            }
            default: {
                return;
            }
        }
    }) as ValueFormatterFunc<CM.FlattenedCostCategoryParams, CM.CostSourceType>,

    minWidth: 95,

    cellClass: (params) => {
        const classes = commonCellClasses(getContext(params), x => x.costSource);
        if (params.value) {
            classes.push('ag-grid-dropdown-cell', 'ag-grid-dropdown-cell-right-aligned')
        }
        return classes;
    },

    //headerClass: [
    //    'ag-grid-header-align-right',
    //],

    cellEditor: CostSourceEditor,
})

class CostSourceEditor implements ICellEditorComp<CM.FlattenedCostCategoryParams, CM.CostSourceType, GridContext> {
    div!: HTMLDivElement;
    comp!: CostSourceDropdown;
    unitsMapper!: UnitsMapper
    onChangeValue?: CM.CostSourceType;

    getValue() {
        return this.onChangeValue;
    }
    isPopup(): boolean {
        return true;
    }
    getPopupPosition(): "over" | "under" | undefined {
        return 'under'
    }
    getGui() {
        return this.div;
    }
    destroy() {
        this.comp.$destroy();
    }
    init(params: ICellEditorParams): void | AgPromise<void> {
        const context = getContext(params);
        const category = context.category
        if (!category?.costSource?.options?.length) {
            return;
        }
        const currentCostSource = category.costSource.options[category.costSource.index ?? - 1];
        if (!currentCostSource) {
            return;
        }
        this.div = document.createElement('div');
        this.unitsMapper = context.unitsMapper;
        this.comp = new CostSourceDropdown({
            target: this.div,
            props: {
                initialValue: currentCostSource,
                initialOptions: category.costSource.options,
                onChange: (value: CM.CostSourceType) => {
                    this.onChangeValue = value;
                    params.stopEditing()
                }
            },
            context: new Map([
                ['unitsMapper', this.unitsMapper]
            ])
        })
    }
}
