import { BimProperty, BooleanProperty, CatalogItemsReferenceProperty, ConfigUtils, DC_CNSTS, LvWiringConfigType, MultiSelectorProperty, NumberProperty, SceneObjDiff, TransformerIdent, getDefaultLvWiringSettings, type AssetCatalogItemProps, type Bim, type BimPropertyData, type Catalog, type IdBimScene, type LvWiringConfig, type SceneInstancePatch } from 'bim-ts';

import { DefaultMap, Immer, IterUtils, LazyBasic, LazyDerived, ObjectUtils, StringUtils, type ScopedLogger, type TasksRunner } from 'engine-utils-ts';
import { NotificationDescription, NotificationType, PUI_ConfigBasedBuilderParams, PUI_GroupNode, PanelViewPosition, buildFromLazyConfigObject, type PUI_Builder, type UiBindings } from 'ui-bindings';
import { notificationSource } from '../Notifications';
import type { KreoEngineDescr } from '../panels-config-ui/GeneratePanelUiBindings';
import { addProperty } from '../panels-config-ui/PropertyBuilders';
import { deleteWiresByParents } from './WiringService';

type PatternType = DC_CNSTS.PatternName;
const LvWireType = 'lv-wire';

const Patterns = new Set<string>(DC_CNSTS.patternNames);
interface LvWiringContext {
    config: LvWiringConfig;
    transformersPerPattern: [PatternType | undefined, IdBimScene[]][];
    lvWires: IdBimScene[];
}

export function createLvWiringUi(
    ui: UiBindings,
    builderSettings: PUI_ConfigBasedBuilderParams,
    tasksRunner: TasksRunner,
    logger: ScopedLogger,
    bim: Bim,
    catalog: Catalog,
    engine: KreoEngineDescr,
    dcCircuitHub: {calculate:(tasksRunner: TasksRunner, ui: UiBindings) => Promise<void>},
    openUiPanel: (id: string) => void,
) {
    const configType = LvWiringConfigType;
    const lazyLvWiringConfig = bim.configs.getLazySingletonOf({type_identifier: configType});
    const transformersLazyList = bim.instances.getLazyListOf({
        type_identifier: TransformerIdent,
        relevantUpdateFlags: SceneObjDiff.LegacyProps
    });
    const lvWiresLazyList = bim.instances.getLazyListOf({type_identifier: LvWireType});
    const lazyConfig = LazyDerived.new2(
        'Lv-Wiring-Ui-Props',
        [bim.unitsMapper, lvWiresLazyList],
        [lazyLvWiringConfig, transformersLazyList],
        ([config, transformers]) => {
            const prop = config?.get<LvWiringConfig>();

            const configSample: Partial<LvWiringConfig> = ObjectUtils.isObjectEmpty(prop) ? {} : ConfigUtils.copyTo(prop);
            const wires:IdBimScene[] = [];
            const transformersPerPattern = new DefaultMap<string | undefined, IdBimScene[]>(() => []);
            const selectedIds = new Set(prop.transformers.value);
            const selectedTransformers = selectedIds.size > 0
                ? transformers.filter(([id]) => selectedIds.has(id))
                : transformers;
            for (const [id, inst] of selectedTransformers) {
                const patternName = inst.properties.get('circuit | pattern | type')?.asText();
                const pattern = patternName && Patterns.has(patternName) ? patternName : undefined;
                transformersPerPattern.getOrCreate(pattern).push(id);
                bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(id, (childId) => {
                    const ident = bim.instances.peekTypeIdentOf(childId);
                    if(ident === LvWireType){
                        wires.push(childId);
                    }
                    return true;
                })
            }

            const context: LvWiringContext = {
                config: prop,
                transformersPerPattern: Array.from(transformersPerPattern) as [PatternType | undefined, IdBimScene[]][],
                lvWires: wires,
            };
            return { 
                configSample, 
                context 
            };
        }
    );

    function patchConfig(newProps: LvWiringConfig){
        bim.configs.applyPatchToSingleton(configType, {properties: newProps});
    }

    const isGeneratingLazy = new LazyBasic<boolean>('is-generating', false);

    let verifyWireValues = true;

    async function generateFn(context: LvWiringContext) {
        const props = context.config;
        if (props) {
            const patches = new DefaultMap<string, SceneInstancePatch>(() => ({properties: []}));

            function addToPatch({pattern, path, property: prop}:{
                pattern: string,
                path:string[],
                property: NumberProperty | CatalogItemsReferenceProperty | MultiSelectorProperty | BooleanProperty | null
            }): void {
                let propPatch: [string, Partial<BimPropertyData> | null] | undefined;

                if (prop instanceof NumberProperty) {
                    propPatch = [
                        BimProperty.MergedPath(path),
                        {
                            path,
                            value: prop.value,
                            unit: prop.unit ? prop.unit : undefined,
                            description: prop.description
                                ? prop.description
                                : undefined,
                            numeric_range: prop.range,
                            numeric_step: prop.step,
                            readonly: prop.isReadonly,
                        },
                    ];
                } else if (prop instanceof CatalogItemsReferenceProperty) {
                    const value = prop.value[0];
                    let assetId: number = -1;
                    if(value?.type === 'asset'){
                        assetId = value.id;
                    } else if(value?.type === 'catalog_item'){
                        const configItem = catalog.catalogItems.peekById(value.id);
                        assetId = configItem?.as<AssetCatalogItemProps>().properties?.asset_id?.value ?? -1;
                    } else if(value === undefined){
                        //not selected wire
                    } else {
                        logger.error('undefined type ', value);
                    }
                    propPatch = [
                        BimProperty.MergedPath(path),
                        {
                            path,
                            value: assetId,
                            description: "=asset=lv-wire-spec=",
                            readonly: prop.isReadonly,
                        },
                    ];
                } else if (prop instanceof MultiSelectorProperty) {
                    propPatch = [
                        BimProperty.MergedPath(path),
                        {
                            path,
                            value: prop.value[0] ? prop.value[0] : 'Not set',
                            discrete_variants:  prop.value[0] ? prop.options : ['Not set', ...prop.options],
                            description: prop.description
                                ? prop.description
                                : undefined,
                            readonly: prop.isReadonly,
                        },
                    ];
                } else if (prop instanceof BooleanProperty) {
                    propPatch = [
                        BimProperty.MergedPath(path),
                        {
                            path,
                            value: prop.value,
                            description: prop.description
                                ? prop.description
                                : undefined,
                            readonly: prop.isReadonly,
                        },
                    ];
                } else if(prop === null) {
                    propPatch = [BimProperty.MergedPath(path), null];
                }else{
                    logger.error("patch not implemented for ", [path, prop]);
                }

                if (propPatch) {
                    patches.getOrCreate(pattern).properties?.push(propPatch);
                } else {
                    logger.error("patch not implemented for ", [path, prop]);
                }
            }
            for (const [pattern] of context.transformersPerPattern) {
                if(!pattern){
                    continue;
                }
                addToPatch({
                    pattern: pattern,
                    path: ["circuit", "pattern", "min_wiring"],
                    property: null,
                }),
                addToPatch({
                    pattern: pattern,
                    path: ["circuit", "pattern", "multiharness"],
                    property: null,
                });
                addToPatch({
                    pattern: pattern,
                    path: ["circuit", "lv_wiring", "max_voltage_drop"],
                    property: props.max_voltage_drop,
                });
                // addPatch({
                //     path: ["circuit", "pattern", "type"],
                //     property: props.pattern.type,
                // });
                addToPatch({
                    pattern: pattern,
                    path: ["circuit", "pattern", "conductor_temperature"],
                    property: props.conductor_temperature,
                });
                const wires = props.pattern[pattern].min_wires;
                if(wires){
                    for (const key in wires) {
                        addToPatch({
                            pattern: pattern,
                            path: ["circuit", "pattern", "min_wiring", key],
                            property: wires[key],
                        });
                    }
                }
    
                if(pattern === 'CI_Multiharness' || pattern === 'SI_Multiharness'){
                    addToPatch({
                        pattern: pattern,
                        path: [
                            "circuit",
                            "pattern",
                            "multiharness",
                            "same_length",
                        ],
                        property: props.pattern[pattern].multiharness.same_length,
                    });
                    const qty_by_tracker = props.pattern[pattern].multiharness.qty_by_tracker;
                    addToPatch({
                        pattern: pattern,
                        path: [
                            "circuit",
                            "pattern",
                            "multiharness",
                            "qty_by_tracker",
                        ],
                        property: qty_by_tracker,
                    });
    
                    for (let i = 0; i < qty_by_tracker.value; i++) {
                        addToPatch({
                            pattern: pattern,
                            path: [
                                "circuit",
                                "pattern",
                                "multiharness",
                                `div_${(i + 1).toString()}`,
                            ],
                            property: props.pattern[pattern].multiharness.div[i],
                        });
                    }
                }
            }

            const patchPerId: [IdBimScene, SceneInstancePatch][] = [];
            for (const [pattern, ids] of context.transformersPerPattern) {
                if(!pattern){
                    continue;
                }
                const patch = patches.get(pattern);
                if (!patch) {
                    continue;
                }
                for (const id of ids) {
                    patchPerId.push([id, patch]);
                }
            }

            bim.instances.applyPatches(patchPerId, {
                allowPropsShapeHookToResetExistingPropsToDefaultValues: false,
            });

            await Promise.resolve()
                .then(() => {
                    isGeneratingLazy.replaceWith(true);
                })
                .then(() => {
                    dcCircuitHub.calculate(tasksRunner, ui);
                })
                .finally(() => {
                    isGeneratingLazy.replaceWith(false);
                });
        } else {
            ui.addNotification(
                NotificationDescription.newBasic({
                    type: NotificationType.Error,
                    source: notificationSource,
                    key: 'configNotFound',
                    removeAfterMs: 5_000,
                    addToNotificationsLog: true
                })
            );
            return;
        }
    };

    function isGenerationAvailable(
        context: LvWiringContext
    ) {
        return LazyDerived.new1(
            "lv-wiring-poller",
            [lazyConfig],
            [isGeneratingLazy],
            ([isGenerating]) => {
                if(isGenerating){
                    return false;
                }
                if (context?.config && context?.transformersPerPattern.length > 0) {
                    const result = context.transformersPerPattern
                        .some(([pattern, ids]) => pattern != undefined && ids.length > 0);

                    return result;
                }
                return false;
            }
        );
    }

    function puiCallback(
        builder: PUI_Builder,
        context: LvWiringContext,
    ) {
        const config = context.config;

        if (ObjectUtils.isObjectEmpty(config)) {
            return;
        }

        let order = 20;
        const addProp = (path: string[], name?: string) => {
            addProperty({logger, config, patch: patchConfig, builder, path, sortKey: ++order, name });
        };
        const addDivider = () => {
            const sortKey = ++order;
            builder.addCustomProp({
                name: 'divider' + sortKey,
                value: null,
                type_ident: "divider",
                typeSortKeyOverride: sortKey,
                onChange: () =>{ },
                readonly: true,
                context: undefined,
            });
        }

        builder.addStringProp({
            name: "Selected area",
            value: "All site area",
            onChange: () => {},
            typeSortKeyOverride: ++order,
            readonly: true,
            notActive: true,
        });

        builder.addStringProp({
            name: "Substation",
            value: "All substations",
            onChange: () => {},
            typeSortKeyOverride: ++order,
            readonly: true,
            notActive: true,
        });

        const transformers = context.config.transformers;
        builder.addSceneInstancesSelectorProp({
            name: "Selected blocks",
            typeSortKeyOverride: ++order,
            value: transformers.value.map(v => ({value: v, label: "block " + v })),
            onChange: (v) => {
                const updatedConfig = Immer.produce(config, (draft) => {
                    draft.transformers = transformers.withDifferentValue(v.map(x => x.value));
                });
                patchConfig(updatedConfig);
            },
            customSelectedItemsMessage: (selected, allItems) => {
                if(allItems.length === 0){
                    return 'No Blocks';
                } else if(selected.length === allItems.length || selected.length === 0){
                    return "All blocks";
                } else if(selected.length === 1) {
                    const instance = bim.instances.peekById(selected[0]);
                    const blockNumber = instance?.properties.get("circuit | position | block_number")?.asNumber() ?? selected[0];
                    const name = `Block ${blockNumber}`;
                    return name;
                } else {
                    return `${selected.length} Blocks`;
                }
            },
            types: transformers.types,
        });

        addDivider();
        addProp(["max_voltage_drop"]);
        addProp(["conductor_temperature"]);

        const allTransformers = context.transformersPerPattern.flatMap(t => t[1]);
        const perPattern = context.transformersPerPattern.filter(([pattern]) => pattern).sort((a, b) =>  a[0]!.localeCompare(b[0]!));
        if(perPattern.length > 0) {
            addDivider();
        }
        for (const [idx, [patternType, ids]] of perPattern.entries()) {
            if(!patternType || !context.config.pattern[patternType]){
                continue;
            }
            const uniqueName = IterUtils.newArray(idx, () => " ").join("")
            const settings = context.config.pattern[patternType];
                builder.addCustomProp({
                name: `Pattern for ${ids.length}/${allTransformers.length} blocks` + uniqueName,
                type_ident: "custom-group-name",
                value: DC_CNSTS.PATTERNS[patternType].displayName,
                typeSortKeyOverride: ++order,
                onChange: () => {},
                context: {
                    multiline: true,
                },
            });
            const wires = settings.min_wires;
            if (patternType && wires) {
                for (const wire in wires) {
                    addProp(['pattern', patternType, "min_wires", wire], StringUtils.capitalizeFirstLatterInWord(wire) + uniqueName);
                }
            }
            if(patternType === 'CI_Multiharness' || patternType === 'SI_Multiharness'){
                addProp(['pattern', patternType, 'multiharness', "same_length"], "Same length multiharness" + uniqueName);
                addProp(['pattern', patternType, 'multiharness', "qty_by_tracker"], "Qty by tracker" + uniqueName);
                for (let i = 0; i < config.pattern[patternType].multiharness.qty_by_tracker.value; i++) {
                    addProp(['pattern', patternType, 'multiharness', "div", i.toString()], `Div ${i+1}` + uniqueName);
                }
            }
        }
        const transformersWithoutPattern = context.transformersPerPattern
            .filter(([pattern]) => !pattern)
            .flatMap(t => t[1]);
        if(transformersWithoutPattern.length > 0){
            addDivider();
            builder.addStringProp({
                name: `Pattern for ${transformersWithoutPattern.length}/${allTransformers.length} blocks` + IterUtils.newArray(context.transformersPerPattern.length, () => " ").join(""),
                value: `No electrical pattern assigned, please, run Augmentation or Generate layout again.`,
                readonly: true,
                calculated: true,
                onChange: () => { },
                isTextarea: true,
                typeSortKeyOverride: ++order,
            });
        }
        addDivider();
        const hasWires = context.lvWires.length > 0;
        const existingLVwiresMsg = hasWires
            ? context.lvWires.length + " Objects within selected blocks"
            : "No wires within selected blocks";
        builder.addCustomProp({
            name: "Existing LV wires",
            type_ident: "custom-group-name",
            value: existingLVwiresMsg,
            typeSortKeyOverride: ++order,
            onChange: () => {},
            context: {
                isProperty: true,
                iconAfter: hasWires 
                ? [
                    {
                        iconName: "Delete",
                        onClick: () => {
                            const transformers = bim.instances.peekByTypeIdent(TransformerIdent);
                            if(transformers.length > 0){
                                const transformersIds = transformers.map(t => t[0]);
                                deleteWiresByParents(transformersIds, bim, [LvWireType]);
                            }
                        },
                    },
                    {
                        iconName: "SelectItem",
                        onClick: () => {
                            bim.instances.setSelected(context.lvWires);
                            engine.focusCamera(context.lvWires);
                        },
                    }
                ]
                : undefined,
            },
            notActive: !hasWires,
        });
        addDivider();

        builder.addActionsNode({
            name: "generateAction",
            context: undefined,
            typeSortKeyOverride: 999,
            actions: [
                {
                    label: "Generate new LV wires",
                    isEnabled: isGenerationAvailable(context),
                    action: () => {
                        generateFn(context);
                    },
                    style: {
                        type: "primary",
                    },
                },
            ],
        });
        builder.addActionsNode({
            name: "openMap",
            context: undefined,
            typeSortKeyOverride: 999,
            actions: [
                {
                    label: "Low Voltage Wiring 2D map",
                    action: () => {
                        openUiPanel('Generate.LV Wiring 2D');
                    },
                    style: {
                        type: "text",
                        icon: "OpenWindow"
                    },
                },
            ],
        });

        // set default min wires
        if (verifyWireValues) {

            const emptyPatternTypes = IterUtils.filterMap(
                DC_CNSTS.patternNames,
                (patternName) => {
                    if(!config.pattern[patternName]){
                        return patternName;
                    }
                    const isNotEmpty = Object.values(config.pattern[patternName].min_wires ?? {})
                        .some((wire) => !wire.isReadonly && wire.value.length && wire.value[0].id !== undefined);
                    return isNotEmpty ? null : patternName;
                }
            );
            
            if (emptyPatternTypes.length) {
                setDefaultWires(config, catalog, emptyPatternTypes, patchConfig);
            } else {
                verifyWireValues = false;
            }
        }
    }

    const builderParams = PUI_ConfigBasedBuilderParams.new(
        [],
        [
            "transformers",
            "conductor_temperature",
            "max_voltage_drop",
            "pattern",
        ]
    ).mergedWith(builderSettings);

    ui.addViewToNavbar(
        ["Generate", "LV Wiring"],
        buildFromLazyConfigObject({
            configObj: lazyConfig,
            configBuilderParams: builderParams,
            puiBuilderParams: {
                onTypeFilteredAfterNodeCallbacks: [
                {
                    ctor: PUI_GroupNode,
                    callBack: (builder, node: PUI_GroupNode, context)=> {
                        if (node.hasParent()) {
                            return;
                        }
                        puiCallback(builder, context)
                    },
                }
            ],
            },
            patchCallback: (patch, context) => {
                if (patch) {
                    const newProps = Immer.produce(context.config, (draft) => {
                        for (const key in patch) {
                            const value = patch[key];
                            if (context.config[key] !== undefined && value !== undefined) {
                                draft[key] = patch[key] as any;
                            }
                        }
                    });
                    logger.error("new props", [patch, context.config, newProps]);
                    patchConfig(newProps);
                } else {
                    logger.batchedError('the config is not patched', [patch, context]);
                }
            }
        }),
        {
            name: 'Low voltage',
            iconName: 'Dc',
            group: 'General',
            sortOrder: 3,
            position: PanelViewPosition.Fixed
        }
    );
}


function setDefaultWires(config: LvWiringConfig, catalog: Catalog, patternNames: PatternType[], patchConfig: (props: LvWiringConfig) => void) {
    const wires = catalog.catalogItems.getLazyListOf('lv-wire-spec').poll();

    const updatedPatterns = Immer.produce(config.pattern, draft => {
        for (const pattern of patternNames) {
            const conductors = DC_CNSTS.PATTERNS[pattern].conductors;
            for (const pattern of patternNames) {
                if(!draft[pattern]){
                    draft[pattern] = getDefaultLvWiringSettings(pattern);
                }
            }
            for (let i = 0; i < conductors.length; i++) {
                const conductor = conductors[i];
                const defaultWire = conductor.defaultWire;
                if (defaultWire) {
                    const wire = wires.find(([_id, item]) => {
                        const assetSi = catalog.assets.sceneInstancePerAsset.getAssetAsSceneInstance(
                            item.as<AssetCatalogItemProps>().properties.asset_id.value
                        );

                        return assetSi?.properties.get('wire | gauge')?.asText() === defaultWire.gauge
                            && assetSi?.properties.get('wire | material')?.asText() === defaultWire.material;
                    });
                    if (wire) {
                        const wireName = `wire_${i + 1}_${conductor.type}`;
                        draft[pattern].min_wires[wireName] = draft[pattern].min_wires[wireName].withDifferentValue(
                            [{id: wire[0], type: "catalog_item"}]
                        );
                    }
                }
            }
        }
    });

    if (!ObjectUtils.areObjectsEqual(config.pattern, updatedPatterns)) {
        patchConfig({
            ...config,
            pattern: updatedPatterns,
        });
    }
}