import { combineHashCodes } from 'math-ts';
import { CustomPropsRegistry, type CustomPropertySerializer } from '../properties/CustomPropsRegistry';
import {  LegacyLogger, LruCache, StringUtils, type NonMethodsOnly } from 'engine-utils-ts';
import { PropertyBase, type PropsGroupField, type PropValueTotalHash } from '../properties/Props';
import { toArrayWithExactCapacity } from '../properties/SmallNumberArrayProperty';
import { TrackerWindPosition } from './TrackerFeatures';
import { PropertyViewBasicTypes, type BasicPropertyValue, type BasicViewFormatters } from '../properties/BasicPropsView';



export class PileFeaturesAndOffsets {
    weight_class: PileWeightClass;
    modifier: PileStrengthModifier;
    motor: PileMotorType;
    damper: PileDamperType;
    undulation: PileUndulationType;
    offset_in_modules: number|null;
    offset_in_meters: number; // from the start of the tube, or from the center of in_between modules gap

    constructor(args: Partial<PileFeaturesAndOffsets>) {
        this.weight_class = args.weight_class ?? PileWeightClass.Standard;
        this.modifier = args.modifier ?? PileStrengthModifier.None;
        this.motor = args.motor ?? PileMotorType.None;
        this.damper = args.damper ?? PileDamperType.None;
        this.undulation = args.undulation ?? PileUndulationType.Undulated;
        this.offset_in_modules = args.offset_in_modules ?? null;
        this.offset_in_meters = args.offset_in_meters ?? 0;
    }

    toPacked(): [PileFeaturesFlags, PileFrameOffset] {
        return [
            newPileFeatures(this.weight_class, this.modifier, this.motor, this.damper, this.undulation),
            newPileOffset(this.offset_in_modules, this.offset_in_meters),
        ]
    }

    static fromPacked(packed: [PileFeaturesFlags, PileFrameOffset]): PileFeaturesAndOffsets {

        const features = packed[0];
        const weight_class = getPileWeightClass(features);
        const modifier = getPileStrengthModifier(features);
        const motor = getPileMotorType(features);
        const damper = getPileDamperType(features);
        const undulation = getPileUndulationType(features);

        const offset = packed[1];
        const offset_in_modules = getPileOffsetInModules(offset);
        const offset_in_meters = getPileOffsetInMeters(offset);

        return new PileFeaturesAndOffsets({
            weight_class,
            modifier,
            motor,
            damper,
            undulation,
            offset_in_modules,
            offset_in_meters,
        });
    }
}

export function unpackPileFeatures(features: PileFeaturesFlags) {
    return {
        weight_class: getPileWeightClass(features),
        modifier: getPileStrengthModifier(features),
        motor: getPileMotorType(features),
        damper: getPileDamperType(features),
        undulation: getPileUndulationType(features),
    };
}

export enum PileWeightClass {
    Standard,
    Heavy,
}
export enum PileStrengthModifier {
    None, // Pier, Std
    Edge, // heavier
    Cantilever, // lighter
}
export enum PileMotorType {
    None,
    Motor,
}
export enum PileDamperType {
    None,
    Damper,
}
export enum PileUndulationType {
    Undulated,
    Rigid,
}

export enum PileFeaturesFlags {};

export function newPileFeatures(
    weight_class: PileWeightClass,
    strength_modifier: PileStrengthModifier,
    motor: PileMotorType,
    damper: PileDamperType,
    undulation: PileUndulationType,
): PileFeaturesFlags {
    const res = (
              (0b000001 & ((weight_class & 0b1)         << 0)) // 1 bit
            | (0b000110 & ((strength_modifier & 0b11)   << 1)) // 2 bits
            | (0b001000 & ((motor & 0b1)                << 3)) // 1 bit
            | (0b010000 & ((damper & 0b1)               << 4)) // 1 bit
            | (0b100000 & ((undulation & 0b1)           << 5)) // 1 bit
    ) as PileFeaturesFlags;
    return res;
}

export function getPileWeightClass(features: PileFeaturesFlags): PileWeightClass {
    return (features & 0b000001) as PileWeightClass;
}
export function getPileStrengthModifier(features: PileFeaturesFlags): PileStrengthModifier {
    return ((features & 0b000110) >>> 1) as PileStrengthModifier;
}
export function getPileMotorType(features: PileFeaturesFlags): PileMotorType {
    return ((features & 0b001000) >>> 3) as PileMotorType;
}
export function getPileDamperType(features: PileFeaturesFlags): PileDamperType {
    return ((features & 0b010000) >>> 4) as PileDamperType;
}
export function getPileUndulationType(features: PileFeaturesFlags): PileUndulationType {
    return ((features & 0b100000) >>> 5) as PileUndulationType;
}

export function getPileFeaturesWithUndulation(features: PileFeaturesFlags, undulation: PileUndulationType): PileFeaturesFlags {
    return newPileFeatures(
        getPileWeightClass(features),
        getPileStrengthModifier(features),
        getPileMotorType(features),
        getPileDamperType(features),
        undulation,
    );
}

export function getPileFeaturesDefaultAbbreviatedName(features: PileFeaturesFlags): string {
    const weight_class = getPileWeightClass(features);
    const modifier = getPileStrengthModifier(features);
    const motor = getPileMotorType(features);
    const damper = getPileDamperType(features);

    let res = weight_class === PileWeightClass.Standard ? 'S' : 'H';
    if (modifier === PileStrengthModifier.Edge) {
        res += 'E';
    } else if (modifier === PileStrengthModifier.Cantilever) {
        res += 'C';
    }
    if (motor === PileMotorType.Motor) {
        res += 'M';
    }
    if (damper === PileDamperType.Damper) {
        res += 'D';
    }
    return res;
}
export function getPileUnduluationAbbreviatedName(undulation: PileUndulationType): string {
    return undulation === PileUndulationType.Undulated ? 'U' : 'R';
}
export function getPileUnduluationAbbreviatedNameFromFeatures(features: PileFeaturesFlags): string {
    return getPileUnduluationAbbreviatedName(getPileUndulationType(features));
}

export enum PileFrameOffset {
    // 1 bit indicate relative offset presence
    // 9 bits for relative offset
    // 1 bit for absolute offset sign
    // 21 bits for absolute offset in mm
};
const OffsetInModulesMask = 0x1FF;  // 9 bits
const OffsetInSubMmMask = 0x1FFFFF; // 21 bits

export function newPileOffset(inModulesOffset: number|null, metersOffset: number): PileFrameOffset {
    if (inModulesOffset === null) {
        return newPileOffsetAbsolute(metersOffset);
    }
    return newPileOffsetRelative(inModulesOffset, metersOffset);
}
export function newPileOffsetAbsolute(absoluteOffset: number): PileFrameOffset {
    const as01mm = Math.round(absoluteOffset * 10_000);
    if (!(as01mm >= -OffsetInSubMmMask && as01mm <= OffsetInSubMmMask)) {
        throw new Error(`Pile offset must be in range [0,${OffsetInSubMmMask}] 0.1mm, but passed value is: ${as01mm}`);
    }
    const sign = as01mm < 0 ? 1 : 0;
    return (sign << 10) | (Math.abs(as01mm) << 11);
}
export function newPileOffsetRelative(inModulesOffset: number, relativeMetersOffset: number): PileFrameOffset {
    if (!(inModulesOffset >= 0 && inModulesOffset <= OffsetInModulesMask && Number.isInteger(inModulesOffset))) {
        throw new Error(`Pile offset_in_modules must be integer in range [0,${OffsetInModulesMask}] m, but passed value is: ${inModulesOffset}`);
    }
    let result = 0b1 | (inModulesOffset << 1);
    if (relativeMetersOffset != 0) {
        const absoluteOffset = newPileOffsetAbsolute(relativeMetersOffset);
        result |= absoluteOffset;
    }
    return result;
}
export function getPileOffsetInModules(featuresAndOffset: PileFrameOffset): number|null {
    if ((featuresAndOffset & 0b1) === 0) {
        return null;
    }
    return (featuresAndOffset >>> 1) & OffsetInModulesMask;
}
export function getPileOffsetInMeters(featuresAndOffset: PileFrameOffset): number {
    const sign = ((featuresAndOffset >>> 10) & 1) ? -1 : 1;
    return ((featuresAndOffset >>> 11) & OffsetInSubMmMask) / 10_000 * sign;
}

export function pileOffsetToString(offset: PileFrameOffset, formatters: BasicViewFormatters): string {
    const inModules = getPileOffsetInModules(offset);
    const inMeters = getPileOffsetInMeters(offset);
    const offsetInConfigured = formatters.unitsMapper.mapToConfigured({value: inMeters, unit: 'm'});
    let res = ``;
    if (inModules != null) {
        res = `${inModules}M`;
        if (inMeters !== 0) {
            res += `${StringUtils.numberToSignedString(offsetInConfigured.value, 2)}${offsetInConfigured.unit}`;
        }
    } else {
        res += `${offsetInConfigured.value.toFixed(2)}${offsetInConfigured.unit}`;
    }
    if (inMeters !== 0) {
    }
    return res;
}

export class PilesFeaturesAndOffsetsProperty extends PropertyBase {

    public readonly features: PileFeaturesFlags[];
    public readonly offsets: PileFrameOffset[];

    get count(): number {
        return this.features.length;
    }

    protected constructor(args: Partial<PilesFeaturesAndOffsetsProperty>) {
        super();
        if (args.features && args.features.length > 0) {
            this.features = toArrayWithExactCapacity(args.features);
        } else {
            this.features = [];
        }
        if (args.offsets && args.offsets.length > 0) {
            this.offsets = toArrayWithExactCapacity(args.offsets);
        } else {
            this.offsets = [];
        }
        if (this.features.length !== this.offsets.length) {
            throw new Error(`features and offsets must have the same length`);
        }
        Object.freeze(this.features);
        Object.freeze(this.offsets);
        Object.freeze(this);
    }
    
    static new(args: Partial<PilesFeaturesAndOffsetsProperty>): PilesFeaturesAndOffsetsProperty {
        if (!args.features || !args.offsets) {
            throw new Error(`both features and offsets must be provided`);
        }
        return piles_descriptions_dedup.get({features: args.features, offsets: args.offsets});
    }
    static newFromTuples(args: [PileFeaturesFlags, PileFrameOffset][]): PilesFeaturesAndOffsetsProperty {
        const features: PileFeaturesFlags[] = [];
        const offsets: PileFrameOffset[] = [];
        for (const [f, o] of args) {
            features.push(f);
            offsets.push(o);
        }
        return piles_descriptions_dedup.get({features, offsets});
    }
    static newFromDescriptions(args: PileFeaturesAndOffsets[]): PilesFeaturesAndOffsetsProperty {
        const features: PileFeaturesFlags[] = [];
        const offsets: PileFrameOffset[] = [];
        for (const d of args) {
            const [f, o] = d.toPacked();
            features.push(f);
            offsets.push(o);
        }
        return piles_descriptions_dedup.get({features, offsets});
    }

    toDescriptions(): PileFeaturesAndOffsets[] {
        const res: PileFeaturesAndOffsets[] = [];
        for (let i = 0; i < this.features.length; ++i) {
            res.push(PileFeaturesAndOffsets.fromPacked([this.features[i], this.offsets[i]]));
        }
        return res
    }


    equals(other: PropsGroupField): boolean {
        if (!(other instanceof PilesFeaturesAndOffsetsProperty)) {
            return false;
        }
        if (this.features.length !== other.features.length) {
            return false;
        }
        for (let i = 0; i < this.features.length; ++i) {
            if (!Object.is(this.features[i], other.features[i])) {
                return false;
            }
        }
        for (let i = 0; i < this.offsets.length; ++i) {
            if (!Object.is(this.offsets[i], other.offsets[i])) {
                return false;
            }
        }
        return true;
    }

    hash(): number | string {
        let hash = 2;
        for (const v of this.features) {
            hash = combineHashCodes(v, hash);
        }
        for (const v of this.offsets) {
            hash = combineHashCodes(v, hash);
        }
        return hash;
    }

    uniqueValueHash(): PropValueTotalHash {
        return `${this.features.join()}|${this.offsets.join()}`;
    }
}
class PilesFeaturesAndOffsetsPropertySerializer implements CustomPropertySerializer<PilesFeaturesAndOffsetsProperty> {
    currentFormatVersion: number = 0;

    serializeToString(p: PilesFeaturesAndOffsetsProperty): string {
        const asJson = JSON.stringify({features: p.features, offsets: p.offsets});
        return asJson;
    }
    deserializeFromString(formatVersion: number, serialized: string): PilesFeaturesAndOffsetsProperty | null {
        const asJson = JSON.parse(serialized) as NonMethodsOnly<PilesFeaturesAndOffsetsProperty>;
        
        return PilesFeaturesAndOffsetsProperty.new({
            features: asJson.features,
            offsets: asJson.offsets,
        });
    }
}
CustomPropsRegistry.register({
    class: PilesFeaturesAndOffsetsProperty as any,
    constructFromPartial: PilesFeaturesAndOffsetsProperty.new,
    serializerClass: PilesFeaturesAndOffsetsPropertySerializer,
    basicTypesView: {
        basicTypes: {
            types: PropertyViewBasicTypes.StringArray,
            offsets: PropertyViewBasicTypes.StringArray,
            undulation: PropertyViewBasicTypes.StringArray,
        },
        toBasicValues(formatters, property, key): BasicPropertyValue|undefined {
            if (key === 'types') {
                return {value: property.features.map(getPileFeaturesDefaultAbbreviatedName)};
            } else if (key === 'offsets') {
                return {value: property.offsets.map(p => pileOffsetToString(p, formatters))};
            } else if (key === 'undulation') {
                return {value: property.features.map(getPileUnduluationAbbreviatedNameFromFeatures)};
            } else {
                LegacyLogger.deferredError('unknown key', key);
                return undefined;
            }
        },
    }
});


const piles_descriptions_dedup = new LruCache<{features: PileFeaturesFlags[], offsets: PileFrameOffset[]}, PilesFeaturesAndOffsetsProperty>({
    identifier: 'piles_descriptions_dedup',
    maxSize: 200,
    eqFunction: (a, b) => {
        if (a === b) {
            return true;
        }
        if (a.features.length !== b.features.length) {
            return false;
        }
        for (let i = 0; i < a.features.length; i++) {
            if (a.features[i] !== b.features[i]) {
                return false;
            }
        }
        for (let i = 0; i < a.offsets.length; i++) {
            if (a.offsets[i] !== b.offsets[i]) {
                return false;
            }
        }
        return true;        
    },
    hashFn: (a) => {
        return `${a.features.join()}|${a.offsets.join()}`
    },
    factoryFn: (args) => {
        return new (PilesFeaturesAndOffsetsProperty as any)(args);
    },
});


// enum PileInTrackerPositionType {
//     Arrow,
//     Motor,
//     PreEdge,
//     Edge,
// }

// export function calculatePileInTrackerPositionType(
//     pileMotor: PileMotorType,
//     index: number,
//     trackerPosition: TrackerWindPosition,
//     pilesCount: number,
// ): PileInTrackerPositionType {
//     let inTrackerPosition: PileInTrackerPositionType;
//     if (pileMotor) {
//         inTrackerPosition = PileInTrackerPositionType.Motor;
//     } else if (index === 0 || index == pilesCount - 1) {
//         inTrackerPosition = PileInTrackerPositionType.Edge;
//     } else if (checkEdgePile(index, trackerPosition, pilesCount)) {
//         inTrackerPosition = PileInTrackerPositionType.PreEdge;
//     } else {
//         inTrackerPosition = PileInTrackerPositionType.Arrow;
//     }
//     return inTrackerPosition;
// }
// function checkEdgePile(index: number, trackerPosition:TrackerWindPosition, pilesCount: number ) {
//     if (trackerPosition === TrackerWindPosition.EdgeTop) {
//         return pilesCount > 3 && index === 1;
//     } else if (trackerPosition === TrackerWindPosition.EdgeBot) {
//         return pilesCount > 3 && index === pilesCount - 2;
//     } else {
//         return pilesCount > 3 && (index === 1 || index === pilesCount - 2);
//     }
// }

export function getDefaultPileFeaturesFor(
    windPosition: TrackerWindPosition|null,
    pileIndex: number,
    pilesCount: number,
    motor: PileMotorType,
    undulation: PileUndulationType,
): PileFeaturesFlags {

    const weight_class = windPosition === TrackerWindPosition.Exterior ? PileWeightClass.Heavy : PileWeightClass.Standard;
    const damper = (pileIndex === 0 || pileIndex === pilesCount - 1) && motor !== PileMotorType.Motor 
        ? PileDamperType.Damper 
        : PileDamperType.None;

    let strength = PileStrengthModifier.None;
    if(motor === PileMotorType.Motor){
        strength = PileStrengthModifier.None;
    } else if ((pileIndex === 1 || pileIndex === pilesCount - 2) && (windPosition === TrackerWindPosition.Edge || windPosition === TrackerWindPosition.Exterior)) {
        strength = PileStrengthModifier.Edge;
    } else if (pileIndex === 1 && windPosition === TrackerWindPosition.EdgeTop) {
        strength = PileStrengthModifier.Edge;
    } else if (pileIndex === pilesCount - 2 && windPosition === TrackerWindPosition.EdgeBot) {
        strength = PileStrengthModifier.Edge;
    }

    return newPileFeatures(weight_class, strength, motor, damper, undulation);
}

