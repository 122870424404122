import type { Vector3} from "math-ts";
import { Vector2, Aabb } from "math-ts";


export class RectPortion {
	readonly start: Vector2;
	readonly size: Vector2;

	constructor() {
		this.start = new Vector2(0, 0);
		this.size = new Vector2(0, 0);
	}

	setFromPoints(p1: Vector2, p2: Vector2) {
		this.start.x = Math.min(p1.x, p2.x);
		this.start.y = Math.min(p1.y, p2.y);

		this.size.x = Math.max(p1.x, p2.x) - this.start.x;
		this.size.y = Math.max(p1.y, p2.y) - this.start.y;
	}


	static from_points_xy(points: Vector3[]): RectPortion {
		let box = Aabb.empty();
		box.setFromPoints(points);
		let rect = new RectPortion();
		rect.start.x = box.minx();
		rect.start.y = box.miny();
		rect.size.x = box.maxx() - box.minx();
		rect.size.y = box.maxy() - box.miny();
		return rect;
	}
}