import type {
    LazyVersioned,
    PollWithVersionResult,
} from "./stateSync/LazyVersioned";


 // ??? todo: remove this
 // -> LazyOnce.ts
export class LazyLambda<T> implements LazyVersioned<T> {

    private _valueFactory: () => T;
    private _computedValue: T | undefined = undefined;
    private _version = 0;

    constructor(
        valueFactory: () => T
    ){
        this._valueFactory = valueFactory;
    }

    pollWithVersion(): PollWithVersionResult<Readonly<T>> {
        return { value: this.poll(), version: this._version };
    }
    
    poll(): Readonly<T> {
        if (this._valueFactory) {
            const f = this._valueFactory;
            const v = f(); 
            if (v !== this._computedValue) {
                this._computedValue = v;
                this._version += 1;
            }
        }
        return this._computedValue!;
    }
    
    version(): number {
        return this._version;
    }

    peek(): T | undefined {
        return this._computedValue;
    }

    clear() {
        if (this._computedValue !== undefined) {
            this._computedValue = undefined;
            this._version += 1;
        }
    }
}
