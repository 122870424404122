// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class ObjectState implements flatbuffers.IUnpackableObject<ObjectStateT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ObjectState {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsObjectState(bb:flatbuffers.ByteBuffer, obj?:ObjectState):ObjectState {
  return (obj || new ObjectState()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsObjectState(bb:flatbuffers.ByteBuffer, obj?:ObjectState):ObjectState {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ObjectState()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

identifier():string|null
identifier(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
identifier(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

hash():string|null
hash(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
hash(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

value(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index) : 0;
}

valueLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

valueArray():Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? new Uint8Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

static startObjectState(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addIdentifier(builder:flatbuffers.Builder, identifierOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, identifierOffset, 0);
}

static addHash(builder:flatbuffers.Builder, hashOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, hashOffset, 0);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, valueOffset, 0);
}

static createValueVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset {
  builder.startVector(1, data.length, 1);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt8(data[i]!);
  }
  return builder.endVector();
}

static startValueVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(1, numElems, 1);
}

static endObjectState(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createObjectState(builder:flatbuffers.Builder, identifierOffset:flatbuffers.Offset, hashOffset:flatbuffers.Offset, valueOffset:flatbuffers.Offset):flatbuffers.Offset {
  ObjectState.startObjectState(builder);
  ObjectState.addIdentifier(builder, identifierOffset);
  ObjectState.addHash(builder, hashOffset);
  ObjectState.addValue(builder, valueOffset);
  return ObjectState.endObjectState(builder);
}

unpack(): ObjectStateT {
  return new ObjectStateT(
    this.identifier(),
    this.hash(),
    this.bb!.createScalarList<number>(this.value.bind(this), this.valueLength())
  );
}


unpackTo(_o: ObjectStateT): void {
  _o.identifier = this.identifier();
  _o.hash = this.hash();
  _o.value = this.bb!.createScalarList<number>(this.value.bind(this), this.valueLength());
}
}

export class ObjectStateT implements flatbuffers.IGeneratedObject {
constructor(
  public identifier: string|Uint8Array|null = null,
  public hash: string|Uint8Array|null = null,
  public value: (number)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const identifier = (this.identifier !== null ? builder.createString(this.identifier!) : 0);
  const hash = (this.hash !== null ? builder.createString(this.hash!) : 0);
  const value = ObjectState.createValueVector(builder, this.value);

  return ObjectState.createObjectState(builder,
    identifier,
    hash,
    value
  );
}
}
