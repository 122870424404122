// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { FlatBufferProjectionInfoT } from './flat-buffer-projection-info.js';
import { FlatBufferProjectionInfo } from './flat-buffer-projection-info.js';
import type { Vector3T } from './vector3.js';
import { Vector3 } from './vector3.js';
import type { WgsCoordT } from './wgs-coord.js';
import { WgsCoord } from './wgs-coord.js';


export class MapProjectionOrigin implements flatbuffers.IUnpackableObject<MapProjectionOriginT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):MapProjectionOrigin {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsMapProjectionOrigin(bb:flatbuffers.ByteBuffer, obj?:MapProjectionOrigin):MapProjectionOrigin {
  return (obj || new MapProjectionOrigin()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsMapProjectionOrigin(bb:flatbuffers.ByteBuffer, obj?:MapProjectionOrigin):MapProjectionOrigin {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new MapProjectionOrigin()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

point(obj?:WgsCoord):WgsCoord|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new WgsCoord()).__init(this.bb_pos + offset, this.bb!) : null;
}

ProjectionTypeDeprecated():string|null
ProjectionTypeDeprecated(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
ProjectionTypeDeprecated(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

wgsOriginLinearOffset(obj?:Vector3):Vector3|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new Vector3()).__init(this.bb_pos + offset, this.bb!) : null;
}

projectionInfo(obj?:FlatBufferProjectionInfo):FlatBufferProjectionInfo|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new FlatBufferProjectionInfo()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startMapProjectionOrigin(builder:flatbuffers.Builder) {
  builder.startObject(4);
}

static addPoint(builder:flatbuffers.Builder, pointOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, pointOffset, 0);
}

static add_projectionTypeDeprecated(builder:flatbuffers.Builder, ProjectionTypeDeprecatedOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, ProjectionTypeDeprecatedOffset, 0);
}

static addWgsOriginLinearOffset(builder:flatbuffers.Builder, wgsOriginLinearOffsetOffset:flatbuffers.Offset) {
  builder.addFieldStruct(2, wgsOriginLinearOffsetOffset, 0);
}

static addProjectionInfo(builder:flatbuffers.Builder, projectionInfoOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, projectionInfoOffset, 0);
}

static endMapProjectionOrigin(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): MapProjectionOriginT {
  return new MapProjectionOriginT(
    (this.point() !== null ? this.point()!.unpack() : null),
    this.ProjectionTypeDeprecated(),
    (this.wgsOriginLinearOffset() !== null ? this.wgsOriginLinearOffset()!.unpack() : null),
    (this.projectionInfo() !== null ? this.projectionInfo()!.unpack() : null)
  );
}


unpackTo(_o: MapProjectionOriginT): void {
  _o.point = (this.point() !== null ? this.point()!.unpack() : null);
  _o.ProjectionTypeDeprecated = this.ProjectionTypeDeprecated();
  _o.wgsOriginLinearOffset = (this.wgsOriginLinearOffset() !== null ? this.wgsOriginLinearOffset()!.unpack() : null);
  _o.projectionInfo = (this.projectionInfo() !== null ? this.projectionInfo()!.unpack() : null);
}
}

export class MapProjectionOriginT implements flatbuffers.IGeneratedObject {
constructor(
  public point: WgsCoordT|null = null,
  public ProjectionTypeDeprecated: string|Uint8Array|null = null,
  public wgsOriginLinearOffset: Vector3T|null = null,
  public projectionInfo: FlatBufferProjectionInfoT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const ProjectionTypeDeprecated = (this.ProjectionTypeDeprecated !== null ? builder.createString(this.ProjectionTypeDeprecated!) : 0);
  const projectionInfo = (this.projectionInfo !== null ? this.projectionInfo!.pack(builder) : 0);

  MapProjectionOrigin.startMapProjectionOrigin(builder);
  MapProjectionOrigin.addPoint(builder, (this.point !== null ? this.point!.pack(builder) : 0));
  MapProjectionOrigin.add_projectionTypeDeprecated(builder, ProjectionTypeDeprecated);
  MapProjectionOrigin.addWgsOriginLinearOffset(builder, (this.wgsOriginLinearOffset !== null ? this.wgsOriginLinearOffset!.pack(builder) : 0));
  MapProjectionOrigin.addProjectionInfo(builder, projectionInfo);

  return MapProjectionOrigin.endMapProjectionOrigin(builder);
}
}
