import type { Bim, IdBimScene, SceneInstance} from "src";
import { TransformerIdent } from "src";
import { SectionalizingCabinetIdent } from "src/archetypes/SectionalizingCabinet";
import { MvWireTypeIdent } from "src/archetypes/MvWire";
import { SCDescription, SubstationDescription, TransformerDescription } from "./types";
import type { EquipmentDescription } from "../types";
import { ConnectionDescription } from "../types";
import type { Result} from "engine-utils-ts";
import { Failure, Success } from "engine-utils-ts";

export function createSubstationDescriptionFromScene(
    bim: Bim,
    substationId: IdBimScene,
): Result<SubstationDescription> {
    const substation = bim.instances.peekById(substationId);
    if (!substation) {
        return new Failure({ msg: 'Substation not found' })
    }

    const substationDescription = extractSubstationDescriptionFromInstance(substationId, substation);

    const toProcess: EquipmentDescription[] = [substationDescription]

    while (toProcess.length) {
        const desc = toProcess.splice(0, 1)[0];
        const children = createChildEquipmentDescriptions(desc.sceneId, bim);
        for (const child of children) {
            toProcess.push(child);
            if (child instanceof TransformerDescription) {
                if (desc instanceof TransformerDescription) {
                    desc.childTransformer = child;
                    break;
                } else if (desc instanceof SCDescription) {
                    desc.childTransformers.push(child);
                }
            } else if (child instanceof SCDescription) {
                if (desc instanceof TransformerDescription) {
                    desc.childSC = child;
                    break;
                } else if (desc instanceof SCDescription) {
                    desc.childSCs.push(child);
                }
            }
        }
    }

    if (
        !substationDescription.childSCs.length &&
        !substationDescription.childTransformers.length
    ) {
        return new Failure({ msg: 'Incorrect Hierarchy. Is mv-wiring present?' })
    }

    return new Success(substationDescription);
}


function createChildEquipmentDescriptions(id: IdBimScene, bim: Bim) {
    const result: EquipmentDescription[] = []
    const hier = bim.instances.spatialHierarchy._allObjects.get(id);
    if (!hier || !hier.children) {
        return result;
    }
    const mvWires: [id: IdBimScene, si: SceneInstance][] = []
    for (const children of hier.children) {
        const id = children.id;
        const si = bim.instances.peekById(id);
        if (si?.type_identifier === MvWireTypeIdent) {
            mvWires.push([id, si]);
        }
    }
    for (const [wireId, wire] of mvWires) {
        const hier = bim.instances.spatialHierarchy._allObjects.get(wireId);
        if (!hier || !hier.children || hier.children.length !== 1) {
            continue;
        }
        const childId = hier.children[0].id;
        const child = bim.instances.peekById(childId);
        if (!child) {
            continue;
        }
        const connectionDescription = extractConnectionDescriptionFromWireInstance(wireId, wire);
        if (child.type_identifier === SectionalizingCabinetIdent) {
            const newCabinet = extractSCDescriptionFromInstance(childId, child)
            newCabinet.connectionToParent = connectionDescription
            result.push(newCabinet)
        } else if (child.type_identifier === TransformerIdent) {
            const newTransformer = extractTransformerDescriptionFromInstance(childId, child);
            newTransformer.connectionToParent = connectionDescription;
            result.push(newTransformer)
        }
    }
    return result;
}

function extractConnectionDescriptionFromWireInstance(id: IdBimScene, si: SceneInstance) {
    const result = new ConnectionDescription({
        sceneId: id,
        text: 'Unknown'
    })
    if (si.type_identifier !== MvWireTypeIdent) {
        return result;
    }

    result.text = 'Wire'

    const costTitle = si.properties.get('cost | cost_item')?.asText();
    if (costTitle) {
        result.text = costTitle;
    }

    return result
}

function extractSubstationDescriptionFromInstance(id: IdBimScene, si: SceneInstance) {
    const substationDescription = new SubstationDescription({
        text: si.name ?? 'Substation',
        childSCs: [],
        childTransformers: [],
        connectionToParent: new ConnectionDescription({ text: '', sceneId: -1 }),
        sceneId: id,
    });
    const costTitle = si.properties.get('cost | cost_item')?.asText();
    if (costTitle) {
        substationDescription.text = costTitle;
    }
    return substationDescription;
}

function extractTransformerDescriptionFromInstance(id: IdBimScene, si: SceneInstance) {
    const newTransformer = new TransformerDescription({
        text: 'Transformer',
        sceneId: id,
        connectionToParent: new ConnectionDescription({ text: '', sceneId: -1 }),
    });
    const costTitle = si.properties.get('cost | cost_item')?.asText();
    if (costTitle) {
        newTransformer.text = costTitle;
    }
    return newTransformer;
}

function extractSCDescriptionFromInstance(id: IdBimScene, si: SceneInstance) {
    const newSC = new SCDescription({
        text: 'Sectionalizing Cabinet',
        sceneId: id,
        connectionToParent: new ConnectionDescription({ text: '', sceneId: -1 }),
        childSCs: [],
        childTransformers: [],
    });
    const costTitle = si.properties.get('cost | cost_item')?.asText();
    if (costTitle) {
        newSC.text = costTitle;
    }
    return newSC;
}
