<script lang="ts">
import { type Bim, type Catalog, ProjectMetrics, MetricsGroup, CostReportRelatedMetrics } from "bim-ts";
import PUI_Root from '../pui/PUI_Root.svelte';

import { getContext, onDestroy, onMount } from "svelte";
import { VersionedStore } from "../VersionedStore";

import type { ProjectVersion, VerDataSyncer } from "verdata-ts";
import { NotificationDescription, NotificationType, PUI_Lazy, UiBindings } from "ui-bindings";
import { LazyDerived, type ResultAsync, DeferredPromise, PollablePromise, InProgress, LazyVersioned } from "engine-utils-ts";
import { notificationSource } from '../Notifications';
import { PanelPosition, type NavbarContext } from '../navbar/Navbar';
import type { KreoEngine } from "engine-ts";
import { createProjectOverviewUi, projectOverviewSettings, convertMetricsPropsToUiNodes, VerDataCurrentVersions, resizePanel } from "./ProjectOverviewPUI";
import { MetricGroupNode} from "./metrics-view/MetricProperty";
import MetricsRoot from "./metrics-view/MetricsRoot.svelte";

export let currentVersionsLazy: LazyVersioned<VerDataCurrentVersions>;
export let layoutMetricsByArea: LazyVersioned<MetricsGroup[] | undefined>;

const vds = getContext<VerDataSyncer>('verDataSyncer');
const uiBindings = getContext<UiBindings>('uiBindings');
const layoutMetricLazy = getContext<ProjectMetrics>('layoutMetricsLazy');
const engine = getContext<KreoEngine>('engine');

const navbar = <NavbarContext>getContext("ui-navbar");
const catalog = getContext<Catalog>('catalog');
const bim = getContext<Bim>('bim');

const unsubscribeNavbarStore = navbar.subscribe(store => {
    const panel = store.panels.get(PanelPosition.Left);
    const countItems = projectOverviewSettings.poll().selectedAreas.length;
    if(panel && countItems < 2){
        projectOverviewSettings.applyPatch({patch: { initWidthPanelPx: panel.width }});
    }
});

const projectMetricsUiLazy = LazyDerived.new3(
    'project-metrics-ui-lazy',
    null,
    [layoutMetricsByArea, layoutMetricLazy.areasContext, projectOverviewSettings],
    ([metrics, areas, settings]) => {
        return metrics
            ? convertMetricsPropsToUiNodes(settings, areas, metrics, bim.unitsMapper)
            : new MetricGroupNode({name: 'project-metrics'});
    }
).withoutEqCheck();

const areMetricsComplied = new VersionedStore(layoutMetricLazy.getCalculationStatus(), 2000);

const timeout_ms = 10*60*1000;
let calculatingPromise: DeferredPromise<boolean> | null = null;
$:if(!$areMetricsComplied && !calculatingPromise){
    calculatingPromise = new DeferredPromise(timeout_ms);
    const task = engine.tasksRunner.newLongTask({
        defaultGenerator: PollablePromise.generatorWaitFor(calculatingPromise.promise)
    })
    uiBindings.addNotification(
        NotificationDescription.newWithTask({
            source: notificationSource,
            key: 'updateLayoutMetrics',
            taskDescription: { task },
            type: NotificationType.Info,
            removeAfterMs: 100,
            addToNotificationsLog: false
        })
    );
} else if($areMetricsComplied && calculatingPromise){
    resolveCalculatingPromise();
} else {
    const message = "unexpected state";
    calculatingPromise?.reject(message);
    calculatingPromise = null;
    console.error(message);
}

function resolveCalculatingPromise(){
    calculatingPromise?.resolve(true);
    calculatingPromise = null;
}

onDestroy(() => {
    resolveCalculatingPromise();
});

const lastVerData = LazyDerived.new3(
    "last-ver-data",
    [vds, catalog.getSyncer()],
    [vds.history, catalog.getSyncer().history, currentVersionsLazy],
    ([projectHistory, catalogHistory, currentVersions]) => {
        function create(currentVersion: number, versions: ProjectVersion[], haveUnsavedChanges: boolean){
            const versionIdMsg = versions.length > 0 ? `${currentVersion} of ${versions[versions.length - 1].id}` : `${currentVersion}`;

            return `${versionIdMsg}${haveUnsavedChanges ? ', Has unsaved changes' : ''}`;
        }
    return {
        projectMessage: create(currentVersions.projectVersion.id, projectHistory.versions, vds.haveUpdatesForSave()),
        catalogMessage: create(currentVersions.catalogVersion.id, catalogHistory.versions, catalog.getSyncer().haveUpdatesForSave()),
    };
});

const overviewUI = createProjectOverviewUi(bim, layoutMetricLazy.areasContext, projectOverviewSettings, lastVerData, navbar);

onMount(() => {
    const countAreas = projectOverviewSettings.poll().selectedAreas.length;
    resizePanel(navbar, countAreas);
});

onDestroy(() => {
    resizePanel(navbar, 0);
    unsubscribeNavbarStore();
});

</script>

{#if overviewUI}
    <PUI_Root
        configIdentifier='project-overview-pui'
        puiSource={new PUI_Lazy(overviewUI)}
    />
{/if}

<MetricsRoot metricsUi={projectMetricsUiLazy} />

<style lang="scss">

</style>
