import { DefaultMap, Yield } from "engine-utils-ts";
import type { Aabb} from "math-ts";
import { calculateRows, Types } from "./RowsCalculator";
import type { Bim } from "src/Bim";
import type { IdBimScene, SceneInstance } from "src/scene/SceneInstances";
import type { RepresentationBase } from "src/representation/Representations";
import { TransformerIdent } from "src/archetypes/transformer/Transformer";


export function calculateRowsHeights(bim: Bim, id: IdBimScene): Map<number, number> {
    const root = bim.instances.spatialHierarchy._allObjects.get(id);
    let group: [IdBimScene, SceneInstance][] = [], scI;
    for (const ch of root?.children ?? []) {
        scI = bim.instances.perId.get(ch.id);
        if (scI && Types.includes(scI.type_identifier)) {
            group.push([ch.id, scI]);
        }
    }

    const goemetriesAabbs = bim.allBimGeometries.aabbs.poll();
    const reprsBboxes = new DefaultMap<RepresentationBase, Aabb>(r => r.aabb(goemetriesAabbs));
    
    const allRows = calculateRows(group, reprsBboxes, false);
    
    const rowsHeights = new Map<number, number>();
    for (const rowsGroup of allRows) {
        for (const row of rowsGroup) {
            let rowsCount = rowsHeights.get(row.length);
            rowsCount = rowsCount ? rowsCount : 0;
            rowsHeights.set(row.length, rowsCount + 1);
        }
    }
    
    return rowsHeights;
}

export function* calculateGlobalRowsHeights(
    bim: Bim, ids: IdBimScene[], approxMaxSpaceInRow?: number
): Generator<Yield, Map<number, number>>  {
    const groups = new Map<IdBimScene, [IdBimScene, SceneInstance][]>();
    const instances = bim.instances.peekByIds(ids);
    for (const [id, instance] of instances) {
        if (!Types.includes(instance.type_identifier)) {
            continue;
        }
        
        const transformerId = bim.instances.getClosestParentOfTypeFor(id, TransformerIdent, true);
        if (!transformerId) {
            continue;
        }

        const group = groups.get(transformerId);
        if (group === undefined) {
            groups.set(transformerId, [[id, instance]]);
        } else {
            group.push([id, instance]);
        }
    };
    
    yield Yield.Asap;

    const goemetriesAabbs = bim.allBimGeometries.aabbs.poll();
    const reprsBboxes = new DefaultMap<RepresentationBase, Aabb>(r => r.aabb(goemetriesAabbs));

    yield Yield.Asap;

    const rowsHeights = new Map<number, number>();
    for (const [_, group] of groups) {
        const allRows = calculateRows(group, reprsBboxes, true, approxMaxSpaceInRow);
        for (const rowsGroup of allRows) {
            for (const row of rowsGroup) {
                let rowsCount = rowsHeights.get(row.length);
                rowsCount = rowsCount ? rowsCount : 0;
                rowsHeights.set(row.length, rowsCount + 1);
            }
        }
    }
    
    return rowsHeights;
}