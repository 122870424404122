// automatically generated by the FlatBuffers compiler, do not modify

import { HeightmapRepresentation } from './heightmap-representation.js';
import { RulerRepresentation } from './ruler-representation.js';
import { SceneImageRepresentation } from './scene-image-representation.js';
import { StdGroupedMeshRepresentation } from './std-grouped-mesh-representation.js';
import { StdMeshRepresentation } from './std-mesh-representation.js';
import { TerrainHeightMapRepresentation } from './terrain-height-map-representation.js';


export enum SceneObjectRepresentation {
  NONE = 0,
  StdMeshRepresentation = 1,
  SceneImageRepresentation = 2,
  HeightmapRepresentation = 3,
  RulerRepresentation = 4,
  TerrainHeightMapRepresentation = 5,
  StdGroupedMeshRepresentation = 6
}

export function unionToSceneObjectRepresentation(
  type: SceneObjectRepresentation,
  accessor: (obj:HeightmapRepresentation|RulerRepresentation|SceneImageRepresentation|StdGroupedMeshRepresentation|StdMeshRepresentation|TerrainHeightMapRepresentation) => HeightmapRepresentation|RulerRepresentation|SceneImageRepresentation|StdGroupedMeshRepresentation|StdMeshRepresentation|TerrainHeightMapRepresentation|null
): HeightmapRepresentation|RulerRepresentation|SceneImageRepresentation|StdGroupedMeshRepresentation|StdMeshRepresentation|TerrainHeightMapRepresentation|null {
  switch(SceneObjectRepresentation[type]) {
    case 'NONE': return null; 
    case 'StdMeshRepresentation': return accessor(new StdMeshRepresentation())! as StdMeshRepresentation;
    case 'SceneImageRepresentation': return accessor(new SceneImageRepresentation())! as SceneImageRepresentation;
    case 'HeightmapRepresentation': return accessor(new HeightmapRepresentation())! as HeightmapRepresentation;
    case 'RulerRepresentation': return accessor(new RulerRepresentation())! as RulerRepresentation;
    case 'TerrainHeightMapRepresentation': return accessor(new TerrainHeightMapRepresentation())! as TerrainHeightMapRepresentation;
    case 'StdGroupedMeshRepresentation': return accessor(new StdGroupedMeshRepresentation())! as StdGroupedMeshRepresentation;
    default: return null;
  }
}

export function unionListToSceneObjectRepresentation(
  type: SceneObjectRepresentation, 
  accessor: (index: number, obj:HeightmapRepresentation|RulerRepresentation|SceneImageRepresentation|StdGroupedMeshRepresentation|StdMeshRepresentation|TerrainHeightMapRepresentation) => HeightmapRepresentation|RulerRepresentation|SceneImageRepresentation|StdGroupedMeshRepresentation|StdMeshRepresentation|TerrainHeightMapRepresentation|null, 
  index: number
): HeightmapRepresentation|RulerRepresentation|SceneImageRepresentation|StdGroupedMeshRepresentation|StdMeshRepresentation|TerrainHeightMapRepresentation|null {
  switch(SceneObjectRepresentation[type]) {
    case 'NONE': return null; 
    case 'StdMeshRepresentation': return accessor(index, new StdMeshRepresentation())! as StdMeshRepresentation;
    case 'SceneImageRepresentation': return accessor(index, new SceneImageRepresentation())! as SceneImageRepresentation;
    case 'HeightmapRepresentation': return accessor(index, new HeightmapRepresentation())! as HeightmapRepresentation;
    case 'RulerRepresentation': return accessor(index, new RulerRepresentation())! as RulerRepresentation;
    case 'TerrainHeightMapRepresentation': return accessor(index, new TerrainHeightMapRepresentation())! as TerrainHeightMapRepresentation;
    case 'StdGroupedMeshRepresentation': return accessor(index, new StdGroupedMeshRepresentation())! as StdGroupedMeshRepresentation;
    default: return null;
  }
}
