<script lang="ts">
import type { Catalog, CatalogItemId, CatalogItemIdType, SolarArrayConfig } from "bim-ts";
import type { SolarArraysViewContext } from "layout-service";
import { getContext } from "svelte";
import { PUI_PropertyNodeMultiSelector, PUI_PropertyNodeNumber, type MultiSelectorValue } from "ui-bindings";
import { VersionedStore } from "../../../VersionedStore";
import PropertyView from "../../PropertyView.svelte";
import FinalFrameSelector from "./FinalFrameSelector.svelte";
import { createTrackerFrameAndModuleVariations, type FrameSeriesDescription } from "./FrameModuleMatcher";
import ModulesCountSettings from "./ModulesCountSettings.svelte";
import ModuleSelector from "./ModuleSelector.svelte";

const catalog = getContext<Catalog>('catalog');

const context = getContext<VersionedStore<SolarArraysViewContext>>('context');

const frameVariationsStore = new VersionedStore(createTrackerFrameAndModuleVariations(catalog));

export let config: SolarArrayConfig;

$: initialTrackerFrameVariation = $frameVariationsStore.frames.get(config.trackerFrame.value.at(0)?.id as CatalogItemId);
$: initialFrameCatalogItemId = (config.trackerFrame.value.at(0)?.id) as CatalogItemIdType;


$: initialPvModuleVariation = $frameVariationsStore.modules.get(config.module.value.at(0)?.id as CatalogItemId);
$: initialPvModuleCatalogItemId = (config.module.value.at(0)?.id) as CatalogItemIdType;

$: {
    selectedFrame = initialFrameCatalogItemId;
    selectedModule = initialPvModuleCatalogItemId;
}


let rackSeries: MultiSelectorValue | undefined = undefined;
let rackSeriesOptions: MultiSelectorValue[] = [];
$: {
    const initialSeries = initialTrackerFrameVariation?.frameSeries;
    rackSeries = initialSeries ? {
        value: initialSeries,
        label: initialSeries,
    } : undefined;
}
$: rackSeriesSelection = new PUI_PropertyNodeMultiSelector({
    name: 'Rack series',
    value: rackSeries ? [rackSeries] : [],
    maxSelect: 1,
    onChange: (newValue) => {
        rackSeries = newValue.at(0);
    },
    options: rackSeriesOptions,
});


$: {
    const newRackSeriesOptions: MultiSelectorValue[] = []
    for (const series of $frameVariationsStore.frameSeriesDescriptions.keys()) {
        newRackSeriesOptions.push({
            value: series,
            label: series,
        })
    }
    rackSeriesOptions = newRackSeriesOptions;
}

let frameSeriesDescription: FrameSeriesDescription | null = null;
$: {
    const series = rackSeries?.value;
    const seriesDescription = typeof series !== 'string' 
        ? undefined
        : $frameVariationsStore.frameSeriesDescriptions.get(series);
    frameSeriesDescription = seriesDescription ?? null;
}

let framesFilteredByModuleCount: CatalogItemIdType[] = [];

let selectedModule: CatalogItemIdType | undefined;

let selectedFrames: CatalogItemIdType[] = [];
$: if (selectedModule !== undefined) {
    const frames: CatalogItemIdType[] = [];
    for (const frameId of framesFilteredByModuleCount) {
        const matchingModules = $frameVariationsStore.modulesPerFrame.get(frameId)
        if (!matchingModules) {
            continue;
        }
        if (matchingModules.includes(selectedModule)) {
            frames.push(frameId);
        }
    }
    selectedFrames = frames;
}
let selectedFrame: CatalogItemIdType | undefined;

$: selectedFrameTotalLength = selectedFrame &&
    $frameVariationsStore.frames.get(selectedFrame)?.frameTotalLength;

$: selectedFrameWidthNode = new PUI_PropertyNodeNumber({
    name: '',
    onChange: () => {},
    readonly: true,
    value: selectedFrameTotalLength?.value ?? 0,
    unit: selectedFrameTotalLength?.unit ?? '',
});

$: if (
    selectedFrame && selectedModule &&
    (
        selectedFrame !== initialFrameCatalogItemId ||
        selectedModule !== initialPvModuleCatalogItemId
    )
) {
    const newConfig: SolarArrayConfig = {
        ...config
    }
    newConfig.module = newConfig.module.withDifferentValue([{
        id: selectedModule,
        type: "catalog_item",
    }]);
    newConfig.trackerFrame = newConfig.trackerFrame.withDifferentValue([{
        id: selectedFrame,
        type: 'catalog_item',
    }]);
    newConfig.preset = newConfig.preset.withDifferentValue([]);

    $context.updateConfig(config.id.value, newConfig);
}

</script>

<PropertyView offset={0} property={rackSeriesSelection} />

{#if frameSeriesDescription}
    <ModulesCountSettings
        initialFrameVariation={initialTrackerFrameVariation}
        bind:resultingTrackerFrameIds={framesFilteredByModuleCount}
        seriesDescription={frameSeriesDescription}
    />
{/if}

<ModuleSelector
    initialModuleCatalogItemId={initialPvModuleCatalogItemId}
    matchingFrames={framesFilteredByModuleCount}
    matchingContext={$frameVariationsStore}
    bind:selectedModule={selectedModule}
/>

<FinalFrameSelector
    frames={selectedFrames}
    bind:frame={selectedFrame}
    defaultFrame={initialFrameCatalogItemId}
/>

{#if selectedFrame}
    <PropertyView offset={0} property={selectedFrameWidthNode} />
{/if}

<style lang="scss">
</style>
