import type { Success} from "engine-utils-ts";
import { Failure, type TasksRunner } from "engine-utils-ts";
import type { UiBindings } from "ui-bindings";
import type { Bim, MathSolversApi } from "bim-ts";
import type { CbCiBlockImportedSolverOutput, CiBlockImportedInputBase, CiBlockImportedSolverInputBase } from "./cb_ci_block_imported";
import { applyCbCiSolverResultToScene, calculate_CI_pixel_value_watt } from "./cb_ci_block_imported";
import { gatherBlockDimensions, resetSceneZCoord } from "./common";

export async function htb_cb_ci_block_imported(
    // input
    input: HtbCbCiBlockImportedInput,

    // dependencies
    bim: Bim,
    mathSolversApi: MathSolversApi,
    tasksRunner: TasksRunner,
    uiBindings: UiBindings,
) {
    await resetSceneZCoord(bim, input.blocks.map(x => x[0]), tasksRunner, uiBindings);

    const pixel_value_res: Success<number>|Failure = calculate_CI_pixel_value_watt(
        input.blocks.map(x => x[0]),
        input.combinerBox,
        input.necMultiplier,
        bim,
    );

    if (pixel_value_res instanceof Failure) {
        throw pixel_value_res.asError({msg: 'pixel_value calculation'});
    }

    const pixel_value = pixel_value_res.value;

    // compose solver input model
    const solverInput: HtbCbCiBlockImportedSolverInput = {
        blocks: gatherBlockDimensions(input.blocks.map(x => x[0]), bim),
        pixel_value: pixel_value,
        align_boxes: input.align_boxes,
    }

    // calculate solver
    const solverOutput = await mathSolversApi.callSolver<
        HtbCbCiBlockImportedSolverInput,
        CbCiBlockImportedSolverOutput 
    >({
        solverName: 'htb_cb_ci_block_imported',
        request: solverInput,
        solverType: 'single',
    })

    // apply to scene
    applyCbCiSolverResultToScene(input, solverInput, solverOutput, bim);
}


interface HtbCbCiBlockImportedInput extends CiBlockImportedInputBase {
    necMultiplier: number,
    align_boxes: boolean,
}

interface HtbCbCiBlockImportedSolverInput extends CiBlockImportedSolverInputBase {
    align_boxes: boolean,
}

