import type { LogLevel, VersionedValue } from 'engine-utils-ts';
import { ScopedLogger, TypedNumbersVec } from 'engine-utils-ts';
import { Vec3Zero } from 'math-ts';

import type { Camera } from '../3rdParty/three';
import type {
	MergedJobs, RenderJobsMerged, RJsMergeSettings} from '../geometries/RenderJobsMerged';
import { RenderJobsMergedPool
} from '../geometries/RenderJobsMerged';
import type { CulledIndices, ProgressiveCulling } from '../scene/BoundsSceneWrap';
import type { EnumsGateway } from '../structs/EnumsGateway';
import type { FrustumExt } from '../structs/FrustumExt';

export interface ProgressiveRenderListProvider extends VersionedValue {
	getMore(
		fr: FrustumExt,
		resultList: RenderJobsMerged[],
		meshesNumberToTryToGet: number,
		rjsPool: RenderJobsMergedPool
	): number; // must return true if at least one mesh was added, otherwise - false
	hasMore(): boolean;
	reset(): void;
}

export interface SimpleRenderListProvider extends VersionedValue {
	produceRenderList(fr: FrustumExt, resultList: RenderJobsMerged[], rjsPool: RenderJobsMergedPool): void;
}

// export type CullingData = Uint32Array;
// // first 16 bits is distance, last 15! bits is size

export interface RenderList extends VersionedValue {
}

export class SimpleRenderList implements RenderList {

	readonly list: RenderJobsMerged[] = [];

	_listVersion: number = 0;

	readonly rjsPool: RenderJobsMergedPool = new RenderJobsMergedPool(500);
	readonly frustum: FrustumExt;
	_listProvider: SimpleRenderListProvider;
	
	constructor(provider: SimpleRenderListProvider, fr: FrustumExt) {
		this._listProvider = provider;
		this.frustum = fr;
	}

	version(): number {
		return this.frustum.version() + this._listProvider.version();
	}
	
	updateIfDirty(): boolean {
		if (this.version() != this._listVersion) {
			this._listVersion = this.version();
			this.list.length = 0;
			this.rjsPool.reset(this.frustum.camera);
			this._listProvider.produceRenderList(this.frustum, this.list, this.rjsPool);
			return true;
		}
		return false;
	}
}


export interface ProgressivePassSettings {
    reverseDrawOrder: boolean,
    allowRenderJobsSorting: boolean,
}

export class ProgressiveRenderList implements RenderList {
	
	logger: ScopedLogger | undefined;
	readonly rjsPool: RenderJobsMergedPool = new RenderJobsMergedPool(500);

	readonly jobsProvider: RenderJobsGenerator;

	_culledList: ProgressiveCulling;
	_currentIndex: number = 0;
	
	_additionalInvalidators: VersionedValue[];

	_settings: ProgressivePassSettings;

	constructor(
		ident: string,
		cb: ProgressiveCulling,
		provider: RenderJobsGenerator,
		invalidators: VersionedValue[],
		settings: ProgressivePassSettings,
		logLevel?: LogLevel,
	) {
		this._culledList = cb;
		this.jobsProvider = provider;
		this._additionalInvalidators = invalidators;
		this._settings = settings;
		if (logLevel !== undefined) {
			this.logger = new ScopedLogger(`rend-list-${ident}`, logLevel);
		}
	}

	prevJobsVersion: number = 0;
	prevCulledListVersion: number = 0;
	prevAdditionalInvalidation: number = 0;

	version(): number {
		const jobsVersion = this.jobsProvider.version();
		const culledListVersin = this._culledList.version();
		
		let additionalInvalidation = 0;
		for (const v of this._additionalInvalidators) {
			additionalInvalidation += v.version();
		}

		if (jobsVersion != this.prevJobsVersion) {
			this.prevJobsVersion = jobsVersion;
			this.logger?.debug('jobs version invalidation');
		}
		if (culledListVersin !== this.prevCulledListVersion) {
			this.prevCulledListVersion = culledListVersin;
			this.logger?.debug('culled list version invalidation');
		}
		if (additionalInvalidation !== this.prevAdditionalInvalidation) {
			this.prevAdditionalInvalidation = additionalInvalidation;
			this.logger?.debug('additional external version invalidation');
		}
		return jobsVersion + culledListVersin + additionalInvalidation;
	}

	reset() {
		this._currentIndex = 0;
	}

	hasMore(): boolean {
		const hasMore = this.jobsProvider.isEnabled() && this._culledList.hasMoreAfter(this._currentIndex);
		return hasMore;
	}

	getMore(camera: Readonly<Camera>, count: number): MergedJobs  {
		if (!this.jobsProvider.isEnabled()) {
			return {
				jobsCount: 0, mergedJobs: [], transforms: TypedNumbersVec.empty(Float32Array), worldOrigin: Vec3Zero
			};
		}
		const {indices, nextIndex } = this._culledList.getIndiciesIncluded(
			this.jobsProvider.renderListFlag,
			this.jobsProvider.renderListFlags(),
			this._currentIndex,
			count,
			this._settings.reverseDrawOrder
		);
		this._currentIndex = nextIndex;
		this.rjsPool.reset(camera);
		const mjs = this.jobsProvider.getRenderJobsOf(
			indices, this.rjsPool, { sort: this._settings.allowRenderJobsSorting }
		);
		return mjs;
	}

	getAll(camera: Readonly<Camera>): MergedJobs {
		return this.getMore(camera, Infinity);
	}
}


export interface RenderJobsGenerator extends VersionedValue {
	readonly renderListFlag: RenderListFlags;
	renderListFlags(): EnumsGateway<RenderListFlags, any>;
	getRenderJobsOf(
		indicies: CulledIndices,
		pool: RenderJobsMergedPool,
		s: RJsMergeSettings,
	): MergedJobs;
	markDirty(): void;
	toggleEnabled(enabled: boolean): void;
	isEnabled(): boolean;
}


export const enum RenderListFlags {
	None 		= 0,
	Opaque 		= 1,
	Transp 		= 2,
	Overlay 	= 4,
}


export class StaticRenderList {
	
}