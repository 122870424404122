// @ts-ignore
import * as jsclipper from "js-clipper";
import type { PointsInPolygonChecker} from "math-ts";
import { Aabb2, Segment2, Vector2 } from "math-ts";
import type { IdBimScene } from "../scene/SceneInstances";
import type { Point, Sample } from "./regular-filler/solver";
import { IterUtils } from "engine-utils-ts";
import type { Layout } from "./irregular-filler/solver";


export interface PolygonFitterArgs {
    contours: Point[][],
    samples: Sample[],
    max_tracker_width: number,
    row_to_row: number,
    column_height: number,
    support_roads: boolean,
    global_support_roads: boolean,
    roads: { points: Point[]; edges: { id: IdBimScene, fst: number, snd: number, width: number }[] },
    equipment_road_offset: number,
    ext: number,
    support_road_offset: number,
    support_roads_step: number
    gap: number,
    angle: number | undefined,
    min_block_value: number,
    max_block_value: number,
}

export interface PolygonFitterResult {
    layouts: Layout[];
    vRoads: [Point, Point][];
}


export interface MaximizeRowToRowArgs {
    contours: Point[][],
    samples: Sample[],
    max_tracker_width: number,
    min_row_to_row: number,
    column_height: number,
    equipment_road_offset: number,
    gap: number,
    ext: number,
    min_total_value: number,
    angle: number | undefined,
    min_block_value: number,
    max_block_value: number,
    support_roads: boolean,
    support_road_offset: number,
    global_support_roads: boolean,
    roads: { points: Point[]; edges: { id: IdBimScene, fst: number, snd: number, width: number }[] },
    road_step: number,
}

export interface MaximizeRowToRowResult {
    layouts: Layout[];
    vRoads: [Point, Point][];
    row_to_row: number;
}


export interface SolverWithNSRoadsArgs {
    contours: Point[][],
    tracker_values: number[],
    tracker_heights: number[],
    tracker_width: number,
    ver_gap: number,
    hor_gap: number,
    num_rows: number,
    num_cols: number,
    block_offset: number,
    ver_line_width: number,
    hor_line_width: number,
    block_values: [number, number][],
    aligned: boolean,
}


interface Tracker {
    x: number;
    y: number;
    i: number; // sample's index
    r: number; // tracker's row
    с: number; // tracker's column (for pixeling)
}

export interface SolverWithNSRoadsResult {
    blocks: Tracker[][],
    ver_lines: [Point, Point][],
    hor_lines: [Point, Point][],
}



function is_ccw(a: Point, b: Point, c: Point): boolean {
    return (b.x - a.x) * (c.y - b.y) - (c.x - b.x) * (b.y - a.y) > 0;
}


function vclip(x: number, polygon: Point[][]): [number, number][] {
    const y: number[] = [];
    for (const contour of polygon) {
        const n = contour.length;
        if (n < 3) { continue; }
        for (let i = 0; i < n; ++i) {
            const a = contour.at(i - 1)!, b = contour[i];
            if ((a.x < x && x < b.x) || (b.x < x && x < a.x)) {
                y.push((b.y * (x - a.x) + a.y * (b.x - x)) / (b.x - a.x));
            } else if (a.x == x) {
                const c = contour.at(i - 2)!;
                if ((c.x < x && x < b.x) || (b.x < x && x < c.x) ||
                    ((x == b.x || x == c.x) && is_ccw(c, a, b))) {
                    y.push(a.y);
                }
            }
        }
    }
    y.sort((a, b) => a - b);
    const result: [number, number][] = []
    for (let i = 0; i < y.length; i += 2) {
        result.push([y[i], y[i + 1]]);
    }
    return result;
}


function find_signed_area(
    contour: Point[]
): number {
    const n = contour.length;
    let result = 0;
    for (let i = 0, j = contour.length - 1; i < n; j = i++) {
        const a = contour[j], b = contour[i];
        result += (a.x + b.x) * (b.y - a.y);
    }
    return result / 2;
}


function scale_to_clipper(
    point: Point,
    scale: number = 1.0e+3
): { X: number, Y: number } {
    return {
        X: Math.round(point.x * scale),
        Y: Math.round(point.y * scale)
    };
}


function scale_from_clipper(
    point: { X: number, Y: number },
    scale: number = 1.0e+3
): Point {
    return { x: point.X / scale, y: point.Y / scale };
}


function exclude_holes(contours: Point[][]): Point[][] {
    const clipper = new jsclipper.Clipper();
    for (let contour of contours) {
        const area = find_signed_area(contour);
        if (area == 0) {
            continue;
        }
        const path = area > 0 ? contour : [...contour].reverse();
        const pt_type = area > 0 ? jsclipper.PolyType.ptSubject :
            jsclipper.PolyType.ptClip;
        clipper.AddPath(path.map(p => scale_to_clipper(p)), pt_type, true);
    }
    let paths: { X: number, Y: number }[][] = new jsclipper.Paths();
    clipper.Execute(jsclipper.ClipType.ctDifference, paths,
        jsclipper.PolyFillType.pftNonZero, jsclipper.PolyFillType.pftNonZero);
    return paths.map(path => path.map(p => scale_from_clipper(p)));
}


function is_inner_point(p: Point, contour: Point[]): boolean {
    let cntr = 0, j = contour.length - 1;
    for (let i = 0; i < contour.length; ++i) {
        const a = contour[j], b = contour[i];
        if ((((b.y > p.y) != (a.y > p.y)) && (p.x < (a.x - b.x) * (p.y - b.y) /
            (a.y - b.y) + b.x))) {
            ++cntr;
        }
        j = i;
    }
    return cntr % 2 == 1;
}


export function make_polygons(
    contours: Point[][]
): Point[][][] {
    contours = exclude_holes(contours);
    const groups: number[][] = [];
    const holes: number[] = [];
    const areas: number[] = [];
    for (let [i, contour] of contours.entries()) {
        const area = find_signed_area(contour);
        if (area > 0.0) {
            groups.push([i]);
            areas.push(area);
        } else if (area < 0.0) {
            holes.push(i);
        }
    }
    for (let hole of holes) {
        let index = -1, record = Infinity, p = contours[hole][0];
        for (let i = 0; i < groups.length; ++i) {
            if (record < areas[i]) {
                continue;
            }
            if (is_inner_point(p, contours[groups[i][0]])) {
                [index, record] = [i, areas[i]];
            }
        }
        if (index != -1) {
            groups[index].push(hole);
        }
    }
    return groups.map(group => group.map(i => contours[i]));
}


function find_x_min(polygon: Point[][]): number {
    let result = Infinity;
    for (const contour of polygon) {
        for (const point of contour) {
            result = Math.min(result, point.x);
        }
    }
    return result;
}


function find_x_max(polygon: Point[][]): number {
    let result = -Infinity;
    for (const contour of polygon) {
        for (const point of contour) {
            result = Math.max(result, point.x);
        }
    }
    return result;
}


export function prepare_polygons(
    contours: Point[][],
    roads: { points: Point[]; edges: { id: IdBimScene, fst: number, snd: number, width: number }[] },
    ns_roads: boolean,
    global_ns_roads: boolean,
    road_step: number,
    support_road_offset: number,
    max_tracker_width: number,
    row_to_row: number
): { polygons: Point[][][], v_roads: [Point, Point][] } {
    let polygons = make_polygons(contours);
    const v_roads: [Point, Point][] = [];
    if (ns_roads) {
        if (global_ns_roads) {
            let bbox = Aabb2.empty();
            for (const group of polygons) {
                for (const polygon of group) {
                    bbox.union(Aabb2.empty().setFromPoints(polygon));
                }
            }
            const roadsCount = Math.ceil(bbox.width() / road_step);
            const nsRoadsSteps = bbox.width() / roadsCount;
            for (let x = bbox.min.x + nsRoadsSteps / 2; x < bbox.max.x; x += nsRoadsSteps) {
                let y_min = Infinity, y_max = -Infinity;
                for (const polygon of polygons) {
                    const vlines = vclip(x, polygon);
                    if (vlines.length > 0) {
                        if (vlines[0][0] < y_min) {
                            y_min = vlines[0][0];
                        }
                        if (vlines[vlines.length - 1][1] > y_max) {
                            y_max = vlines[vlines.length - 1][1];
                        }
                    }
                }
                if (Number.isFinite(y_min) && Number.isFinite(y_max)) {
                    v_roads.push([ { x: x, y: y_min }, { x: x, y: y_max } ]);
                }
            }
            for (let i = 0; i < polygons.length; i += 1) {
                for (let x = bbox.min.x + nsRoadsSteps / 2; x < bbox.max.x; x += nsRoadsSteps) {
                    polygons[i].push([
                        { x: x - support_road_offset, y: bbox.min.y }, 
                        { x: x - support_road_offset, y: bbox.max.y }, 
                        { x: x + support_road_offset, y: bbox.max.y }, 
                        { x: x + support_road_offset, y: bbox.min.y }
                    ]);
                }
                polygons[i] = make_polygons(polygons[i]).flat();
            }
        } else {
            for (let i = 0; i < polygons.length; i += 1) {
                const radius = max_tracker_width / 2;
                const begin = Math.ceil((find_x_min(polygons[i]) + radius) / row_to_row);
                const end = Math.floor((find_x_max(polygons[i]) - radius) / row_to_row);
                const width = (end - begin) * row_to_row;
                const n = end - begin + 1;

                const roadsCount = Math.ceil(width / road_step);
                const vRoadsSteps = Math.floor((width / roadsCount) / row_to_row);
                const vRoadsDelta = Math.floor(2 * support_road_offset / row_to_row);
                const vRoadsDeltaFlag = 
                    (vRoadsDelta + 1) * row_to_row - 2 * support_road_offset > max_tracker_width ? 
                        (vRoadsDelta + 1) % 2 :
                        vRoadsDelta % 2;
                const leftDelta = vRoadsSteps % 2 === vRoadsDeltaFlag ? 
                    vRoadsSteps / 2 : (vRoadsSteps + 1) / 2;

                for (let j = leftDelta; j < n; j += vRoadsSteps) {
                    const x = (begin + j) * row_to_row;
                    const vlines = vclip(x, polygons[i]);
                    if (vlines.length > 0) {
                        v_roads.push([ { x: x, y: vlines[0][0] }, { x: x, y: vlines[vlines.length - 1][1] } ]);
                    }
                    polygons[i].push([
                        { x: x - support_road_offset, y: vlines[0][0] }, 
                        { x: x - support_road_offset, y: vlines[vlines.length - 1][1] }, 
                        { x: x + support_road_offset, y: vlines[vlines.length - 1][1] }, 
                        { x: x + support_road_offset, y: vlines[0][0] }
                    ]);
                }
                polygons[i] = make_polygons(polygons[i]).flat();
            }
        }
    } else {
        const userRoads: Point[][] = [];
        for (const edge of roads.edges) {
            const p1 = roads.points[edge.fst];
            const p2 = roads.points[edge.snd];
            const v = new Vector2(p2.x - p1.x, p2.y - p1.y);
            const dx = -v.y * support_road_offset / v.length();
            const dy = v.x * support_road_offset / v.length();
            const cross = Math.sign(v.x * dy - v.y * dx);
            userRoads.push([
                { x: p1.x - cross * dx, y: p1.y - cross * dy }, 
                { x: p1.x + cross * dx, y: p1.y + cross * dy }, 
                { x: p2.x + cross * dx, y: p2.y + cross * dy }, 
                { x: p2.x - cross * dx, y: p2.y - cross * dy }
            ]);
        }
        if (userRoads.length > 0) {
            for (let i = 0; i < polygons.length; i += 1) {
                polygons[i] = make_polygons(polygons[i].concat(userRoads)).flat();
            }
        }
    }
    return { polygons, v_roads };
}


export function find_total_value(layouts: Layout[], samples: Sample[]): number {
    return layouts.reduce((sum, layout) => sum +
        layout.trackers.reduce((sum, tracker) => sum +
            samples[tracker.s].v, 0), 0);
}


function is_cw(a: Point, b: Point, c: Point): boolean {
    return (b.x - a.x) * (c.y - b.y) - (c.x - b.x) * (b.y - a.y) < 0;
}


function make_convex_hull(polygon: Point[][]): Point[] {
    const points = polygon.flat();
    const n = points.length;
    points.sort((a, b) => a.x == b.x ? a.y - b.y : a.x - b.x);
    let beg = points[0], end = points[n - 1];
    const up = [beg], down = [beg];
    for (let i = 1; i < n; ++i) {
        if (i == n - 1 || is_cw(beg, points[i], end)) {
            while (2 <= up.length && !is_cw(up.at(-2)!,
                up.at(-1)!, points[i])) { up.pop(); }
            up.push(points[i]);
        }
        if (i == n - 1 || is_ccw(beg, points[i], end)) {
            while (2 <= down.length && !is_ccw(down.at(-2)!,
                down.at(-1)!, points[i])) { down.pop(); }
            down.push(points[i])
        }
    }
    for (let i = down.length - 2; i > 0; --i) { up.push(down[i]); }
    return up;
}


export function find_best_tan(polygon: Point[][]): number {
    const hull = make_convex_hull(polygon);
    const n = hull.length;
    if (n <= 1) { return 0; }
    let result = 0, record = Infinity;
    for (let i = 0, j = hull.length - 1; i < n; j = i++) {
        const fst = hull[j], snd = hull[i];
        let ux = snd.x - fst.x;
        if (ux == 0) { continue; }
        let uy = snd.y - fst.y;
        const d = Math.hypot(ux, uy);
        ux /= d;
        uy /= d;
        if (ux < 0) {
            ux = -ux;
            uy = -uy;
        }
        const values = hull.map(p => p.y * ux - p.x * uy);
        const size = (Math.max(...values) - Math.min(...values)) / ux;
        if (size < record) {
            record = size;
            result = uy / ux;
        }
    }
    return result;
}


export function trimSupportRoads(
    roads: [Point, Point][], contoursSegments: Segment2[], pointsInContourChecker: PointsInPolygonChecker
) { 
    const newVRoads: [Point, Point][] = [];
    for (const [fst, snd] of roads) {
        const road = new Segment2(new Vector2(fst.x, fst.y), new Vector2(snd.x, snd.y));
        const trimmedRoads = trimRoadByContour(road, contoursSegments, pointsInContourChecker);
        IterUtils.extendArray(newVRoads, trimmedRoads);
    }

    return newVRoads;
}


export function trimRoadByContour(roadSegment: Segment2, contoursSegments: Segment2[], pointsInContourChecker: PointsInPolygonChecker) {
    const newRoads: [Point, Point][] = [];

    const edgeIntersections: number[] = [];

    for (const contourSegment of contoursSegments) {
        const t = roadSegment.intersectSegment(contourSegment);
        if (t !== null) {
            edgeIntersections.push(t);
        }
    }

    if (edgeIntersections.length === 0) {
        if (pointsInContourChecker.isPointInside(roadSegment.a)) {
            newRoads.push([
                { x: roadSegment.a.x, y: roadSegment.a.y }, 
                { x: roadSegment.b.x, y: roadSegment.b.y }
            ]);
            return newRoads;
        } else {
            return [];
        }
    } else {
        edgeIntersections.sort((a, b) => a - b);
    }

    let subSegment = [
        roadSegment.a,
        roadSegment.a.clone().add(roadSegment.b.clone().sub(roadSegment.a).multiplyScalar(edgeIntersections[0]))
    ];
    let centralPoint = subSegment[0].clone().add(subSegment[1]).divideScalar(2);

    if (pointsInContourChecker.isPointInside(centralPoint)) {
        newRoads.push([
            { x: subSegment[0].x, y: subSegment[0].y }, 
            { x: subSegment[1].x, y: subSegment[1].y }
        ]);
    }

    for (let i = 0; i < edgeIntersections.length - 1; ++i) {
        subSegment = [
            roadSegment.a.clone().add(roadSegment.b.clone().sub(roadSegment.a).multiplyScalar(edgeIntersections[i])),
            roadSegment.a.clone().add(roadSegment.b.clone().sub(roadSegment.a).multiplyScalar(edgeIntersections[i+1])),
        ];
        centralPoint = subSegment[0].clone().add(subSegment[1]).divideScalar(2);

        if (pointsInContourChecker.isPointInside(centralPoint)) {
            newRoads.push([
                { x: subSegment[0].x, y: subSegment[0].y }, 
                { x: subSegment[1].x, y: subSegment[1].y }
            ]);
        }
    }

    subSegment = [
        roadSegment.a.clone().add(roadSegment.b.clone().sub(roadSegment.a)
            .multiplyScalar(edgeIntersections[edgeIntersections.length - 1])),
        roadSegment.b
    ];
    centralPoint = subSegment[0].clone().add(subSegment[1]).divideScalar(2);

    if (pointsInContourChecker.isPointInside(centralPoint)) {
        newRoads.push([
            { x: subSegment[0].x, y: subSegment[0].y }, 
            { x: subSegment[1].x, y: subSegment[1].y }
        ]);
    }

    return newRoads;
}


export function unionHRoads(
    patches: Layout[], pointsInContourChecker: PointsInPolygonChecker
) {
    for (let i = 0; i < patches.length; ++i) {
        const newHRoads: [Point, Point][] = [];
        let currRoad = patches[i].lines[0];
        for (let j = 1; j < patches[i].lines.length; ++j) {
            const point = new Vector2(
                0.5 * (currRoad[1].x + patches[i].lines[j][0].x),
                0.5 * (currRoad[1].y + patches[i].lines[j][0].y)
            );
            if (pointsInContourChecker.isPointInside(point)) {
                currRoad[1] = patches[i].lines[j][1];
            } else {
                newHRoads.push(currRoad);
                currRoad = patches[i].lines[j];
            }
        }
        newHRoads.push(currRoad);
        patches[i].lines = newHRoads;
    }
}