// automatically generated by the FlatBuffers compiler, do not modify

import { CustomSerializedProperty } from './custom-serialized-property.js';
import { NumberPropertyWithOptions } from './number-property-with-options.js';
import { NumericRangeProperty } from './numeric-range-property.js';
import { PropertyAssetsValue } from './property-assets-value.js';
import { PropertyBoolValue } from './property-bool-value.js';
import { PropertyCatalogItemsValue } from './property-catalog-items-value.js';
import { PropertyColorValue } from './property-color-value.js';
import { PropertyMultiSelectionValue } from './property-multi-selection-value.js';
import { PropertyNumericValue } from './property-numeric-value.js';
import { PropertySceneInstancesValue } from './property-scene-instances-value.js';
import { PropertySelectionValue } from './property-selection-value.js';
import { PropertyStringValue } from './property-string-value.js';


export enum PropertyValue {
  NONE = 0,
  PropertyNumericValue = 1,
  PropertyStringValue = 2,
  PropertyBoolValue = 3,
  PropertySceneInstancesValue = 4,
  PropertySelectionValue = 5,
  PropertyMultiSelectionValue = 6,
  PropertyAssetsValue = 7,
  PropertyColorValue = 8,
  PropertyCatalogItemsValue = 9,
  CustomSerializedProperty = 10,
  NumericRangeProperty = 11,
  NumberPropertyWithOptions = 12
}

export function unionToPropertyValue(
  type: PropertyValue,
  accessor: (obj:CustomSerializedProperty|NumberPropertyWithOptions|NumericRangeProperty|PropertyAssetsValue|PropertyBoolValue|PropertyCatalogItemsValue|PropertyColorValue|PropertyMultiSelectionValue|PropertyNumericValue|PropertySceneInstancesValue|PropertySelectionValue|PropertyStringValue) => CustomSerializedProperty|NumberPropertyWithOptions|NumericRangeProperty|PropertyAssetsValue|PropertyBoolValue|PropertyCatalogItemsValue|PropertyColorValue|PropertyMultiSelectionValue|PropertyNumericValue|PropertySceneInstancesValue|PropertySelectionValue|PropertyStringValue|null
): CustomSerializedProperty|NumberPropertyWithOptions|NumericRangeProperty|PropertyAssetsValue|PropertyBoolValue|PropertyCatalogItemsValue|PropertyColorValue|PropertyMultiSelectionValue|PropertyNumericValue|PropertySceneInstancesValue|PropertySelectionValue|PropertyStringValue|null {
  switch(PropertyValue[type]) {
    case 'NONE': return null; 
    case 'PropertyNumericValue': return accessor(new PropertyNumericValue())! as PropertyNumericValue;
    case 'PropertyStringValue': return accessor(new PropertyStringValue())! as PropertyStringValue;
    case 'PropertyBoolValue': return accessor(new PropertyBoolValue())! as PropertyBoolValue;
    case 'PropertySceneInstancesValue': return accessor(new PropertySceneInstancesValue())! as PropertySceneInstancesValue;
    case 'PropertySelectionValue': return accessor(new PropertySelectionValue())! as PropertySelectionValue;
    case 'PropertyMultiSelectionValue': return accessor(new PropertyMultiSelectionValue())! as PropertyMultiSelectionValue;
    case 'PropertyAssetsValue': return accessor(new PropertyAssetsValue())! as PropertyAssetsValue;
    case 'PropertyColorValue': return accessor(new PropertyColorValue())! as PropertyColorValue;
    case 'PropertyCatalogItemsValue': return accessor(new PropertyCatalogItemsValue())! as PropertyCatalogItemsValue;
    case 'CustomSerializedProperty': return accessor(new CustomSerializedProperty())! as CustomSerializedProperty;
    case 'NumericRangeProperty': return accessor(new NumericRangeProperty())! as NumericRangeProperty;
    case 'NumberPropertyWithOptions': return accessor(new NumberPropertyWithOptions())! as NumberPropertyWithOptions;
    default: return null;
  }
}

export function unionListToPropertyValue(
  type: PropertyValue, 
  accessor: (index: number, obj:CustomSerializedProperty|NumberPropertyWithOptions|NumericRangeProperty|PropertyAssetsValue|PropertyBoolValue|PropertyCatalogItemsValue|PropertyColorValue|PropertyMultiSelectionValue|PropertyNumericValue|PropertySceneInstancesValue|PropertySelectionValue|PropertyStringValue) => CustomSerializedProperty|NumberPropertyWithOptions|NumericRangeProperty|PropertyAssetsValue|PropertyBoolValue|PropertyCatalogItemsValue|PropertyColorValue|PropertyMultiSelectionValue|PropertyNumericValue|PropertySceneInstancesValue|PropertySelectionValue|PropertyStringValue|null, 
  index: number
): CustomSerializedProperty|NumberPropertyWithOptions|NumericRangeProperty|PropertyAssetsValue|PropertyBoolValue|PropertyCatalogItemsValue|PropertyColorValue|PropertyMultiSelectionValue|PropertyNumericValue|PropertySceneInstancesValue|PropertySelectionValue|PropertyStringValue|null {
  switch(PropertyValue[type]) {
    case 'NONE': return null; 
    case 'PropertyNumericValue': return accessor(index, new PropertyNumericValue())! as PropertyNumericValue;
    case 'PropertyStringValue': return accessor(index, new PropertyStringValue())! as PropertyStringValue;
    case 'PropertyBoolValue': return accessor(index, new PropertyBoolValue())! as PropertyBoolValue;
    case 'PropertySceneInstancesValue': return accessor(index, new PropertySceneInstancesValue())! as PropertySceneInstancesValue;
    case 'PropertySelectionValue': return accessor(index, new PropertySelectionValue())! as PropertySelectionValue;
    case 'PropertyMultiSelectionValue': return accessor(index, new PropertyMultiSelectionValue())! as PropertyMultiSelectionValue;
    case 'PropertyAssetsValue': return accessor(index, new PropertyAssetsValue())! as PropertyAssetsValue;
    case 'PropertyColorValue': return accessor(index, new PropertyColorValue())! as PropertyColorValue;
    case 'PropertyCatalogItemsValue': return accessor(index, new PropertyCatalogItemsValue())! as PropertyCatalogItemsValue;
    case 'CustomSerializedProperty': return accessor(index, new CustomSerializedProperty())! as CustomSerializedProperty;
    case 'NumericRangeProperty': return accessor(index, new NumericRangeProperty())! as NumericRangeProperty;
    case 'NumberPropertyWithOptions': return accessor(index, new NumberPropertyWithOptions())! as NumberPropertyWithOptions;
    default: return null;
  }
}
