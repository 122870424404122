// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class PropertyStringValue implements flatbuffers.IUnpackableObject<PropertyStringValueT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):PropertyStringValue {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsPropertyStringValue(bb:flatbuffers.ByteBuffer, obj?:PropertyStringValue):PropertyStringValue {
  return (obj || new PropertyStringValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsPropertyStringValue(bb:flatbuffers.ByteBuffer, obj?:PropertyStringValue):PropertyStringValue {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new PropertyStringValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value():string|null
value(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
value(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startPropertyStringValue(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, valueOffset, 0);
}

static endPropertyStringValue(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createPropertyStringValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset):flatbuffers.Offset {
  PropertyStringValue.startPropertyStringValue(builder);
  PropertyStringValue.addValue(builder, valueOffset);
  return PropertyStringValue.endPropertyStringValue(builder);
}

unpack(): PropertyStringValueT {
  return new PropertyStringValueT(
    this.value()
  );
}


unpackTo(_o: PropertyStringValueT): void {
  _o.value = this.value();
}
}

export class PropertyStringValueT implements flatbuffers.IGeneratedObject {
constructor(
  public value: string|Uint8Array|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const value = (this.value !== null ? builder.createString(this.value!) : 0);

  return PropertyStringValue.createPropertyStringValue(builder,
    value
  );
}
}
