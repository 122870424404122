<script lang="ts">
    import { onMount, onDestroy, getContext, setContext } from 'svelte';
    import { navigate } from 'svelte-routing';
    import EngineUi from './EngineUi.svelte';
    import {
        ProjectNetworkClient,
        UndoStack,
    } from 'engine-utils-ts';
    import type { Catalog, UnitsMapper } from 'bim-ts';
    import { Bim } from 'bim-ts';
    import { KreoEngine } from 'engine-ts';
    import { VerDataSyncer, VerDataIdentifier} from 'verdata-ts';
    import type { AuthHub } from './auth';
    import type { ProjectHub } from './ProjectHub';
    import type { Readable } from 'svelte/store';
    import { NavbarContext } from './navbar/Navbar';
    import { EngineUiLayout } from './ui-panels/EngineUiLayout';
    import EngineRoot from './EngineRoot.svelte';
    import { extractVerdataVersionFromUrl } from './utils/UrlUils';



    export let projectId: number | undefined;

    $: setContext<number>('projectId', projectId ?? -1);


    const catalog = getContext<Catalog>('catalog');
    (window as any).catalog = catalog;

    let engineDomElement: HTMLDivElement;
    let authHub = getContext<AuthHub>("authHub");
    let projectHub = getContext<ProjectHub>('projectHub');

    let dispose: () => void;
    let bim: Bim;
    let engine: KreoEngine;
    let vds: VerDataSyncer;
    let baseNetwork = getContext<ProjectNetworkClient>('network');
    let network: ProjectNetworkClient;

    let unitsMapper = getContext<Readable<UnitsMapper>>('unitsMapper');

    const navbarContext = new NavbarContext(new EngineUiLayout());
    setContext("ui-navbar", navbarContext);

    const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
        if (vds && vds.haveUpdatesForSave() || catalog.getSyncer().haveUpdatesForSave()) {
            event.preventDefault();
            // Included for legacy support, e.g. Chrome/Edge < 119
            event.returnValue = true;
        }
    };

    onMount(async () => {
        if (projectId === undefined) {
        	const prev = localStorage.getItem('previousProject');
            if (prev === null) {
                return navigate('/dashboard');
            }
            const id = parseInt(prev);
        	return navigate(!Number.isInteger(id) ? '/dashboard' : id.toString());
        }
        window.localStorage.setItem('previousProject', projectId + '');

        if (!env.desktopMode) {
            projectHub.getProject(projectId);
            window.addEventListener('beforeunload', beforeUnloadHandler);
        }

        network = new ProjectNetworkClient({
            ...baseNetwork.config,
            basePath: 'api/verdata/' + projectId,
        });
        // @ts-ignore
        window.network = network;

        const globalUndoStack = new UndoStack('global-undo', 300);
        bim = new Bim({undoStack: globalUndoStack, unitsMapper: $unitsMapper});
        const engine_promise = KreoEngine.newAsync({
            container: engineDomElement,
            bim,
            catalog,
            network,
            texturesUrl: '/static/textures/',
        });

        // engine_promise.then(e => e.dispose());

        engine = await engine_promise;
        // @ts-ignore
        window['engine'] = engine;
        // @ts-ignore
        window['e'] = engine._impl;
        // @ts-ignore
        window['bim'] = bim;
        // @ts-ignore
        vds = await VerDataSyncer.newAsync({
            identifier: VerDataIdentifier.Project,
        	undoStack: globalUndoStack,
        	verbose: false,
        });
        vds.addListener('afterSync', () => globalUndoStack.clear());
        // vds.addListener('afterSync', () => { bim.convertToAnyTrackers(); });
        
        // @ts-ignore
        window.vds = vds;
        await bim.connectToVerdata(vds);
        await engine.connectToVerdata(vds);
        vds.addDependency(catalog.getSyncer());
        await vds.init(network);

        const versionToLoad = extractVerdataVersionFromUrl('project-version');
        if (versionToLoad) {
            await vds.loadVersion(versionToLoad);
        } else {
            await vds.loadLastVersion();
        }
        if (vds.history.currentValue().versions.length == 0) {
        	console.log('empty project');
        }
        dispose = () => {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
            engine.dispose();
            bim.dispose();
            vds.dispose();
            globalUndoStack.dispose();
            network.dispose();
            // @ts-ignore
            delete window.network;
            // @ts-ignore
            delete window.engine;
            // @ts-ignore
            delete window.e;
            // @ts-ignore
            delete window.bim;
            // @ts-ignore
            delete window.chartsRange;
            // @ts-ignore
            delete window.vds;
        }
        return engine;
    });

    onDestroy(() => {
        dispose && dispose();
    });

    let leftNavWidth: number;
    let rightNavWidth: number;
</script>

<EngineRoot {engine} {leftNavWidth} {rightNavWidth} bind:engineDomElement />
{#if bim && engine && vds && network}
    <div>
        <EngineUi
            {bim}
            {engineDomElement}
            {engine}
            verDataSyncer={vds}
            projectNetworkClient={network}
            {navbarContext}
            bind:leftNavWidth
            bind:rightNavWidth
         />
    </div>
{/if}

<style>
</style>
