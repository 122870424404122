import type { ProjectNetworkClient} from 'engine-utils-ts';
import { FetchUtils } from 'engine-utils-ts'
import type { Project, PaginatedProjects, ProjectSearchParams, ProjectStatus } from './types';

export class ProjectApi {

    constructor(readonly _network: ProjectNetworkClient) {}

    async updateName(projectId: number, newName: string) {
        const result: Project = await this._network.postJson(
            `/api/projects/${projectId}/update-name`,
            { name: newName }
        ).then(x => x.json());
        return result
    }

    async updateStatus(projectId: number, status: ProjectStatus) {
        const result: Project = await this._network.postJson(
            `/api/projects/${projectId}/update-status`,
            { status }
        ).then(x => x.json());
        return result;
    }

    async reserveProject() {
        const result: Project = await this._network
            .post('/api/projects').then(x => x.json());
        return result;
    }

    async get(projectId: number) {
        const project: Project = await this._network
            .get('/api/projects/' + projectId).then(x => x.json());
        return project;
    }

    async search(params: ProjectSearchParams) {
        const queryString = FetchUtils.createQueryStringFromObject(params)
        const result: PaginatedProjects = await this._network
            .get('/api/projects' + queryString).then(x => x.json());
        return result;
    }

    async archive(projectId: number) {
        const result: boolean = await this._network
            .post(`/api/projects/${projectId}/archive`).then(x => x.json());
        return result;
    }

    async restore(projectId: number) {
        const result: boolean = await this._network
            .post(`/api/projects/${projectId}/restore`).then(x => x.json());
        return result;
    }

    async share(projectId: number) {
        const result: boolean = await this._network
            .post(`/api/projects/${projectId}/share`).then(x => x.json());
        return result;
    }

    async unshare(projectId: number) {
        const result: boolean = await this._network
            .post(`/api/projects/${projectId}/unshare`).then(x => x.json());
        return result;
    }
}
