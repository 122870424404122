<script lang="ts">
    import type { PUI_ActionsNode } from 'ui-bindings';
    import ActionProperty from './ActionProperty.svelte';

    export let property: PUI_ActionsNode;

</script>

<div
  class="ui-config-property property-row"
>
    {#if property.actions.length < 3}
        <div class="property-name"/>
    {/if}
    <div class="buttons" class:buttons-wrap={!(property.actions.length < 3)}>
        {#each property.actions as actionDescr}
            <ActionProperty
                bind:actionDescr={actionDescr}
                bind:context={property.context}
            />
        {/each}
    </div>
</div>

<style lang="css">
    .property-row {
        margin: 4px 0;
    }
    .buttons {
        display: flex;
        gap: 12px;
        flex: 4;
        white-space: nowrap;
        text-overflow: ellipsis;
        
    }
    .buttons-wrap {
        gap: 8px;
    }
</style>
