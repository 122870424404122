// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { CompressedIntegers } from '../wire/compressed-integers.js';


export class BatchDescription {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):BatchDescription {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsBatchDescription(bb:flatbuffers.ByteBuffer, obj?:BatchDescription):BatchDescription {
  return (obj || new BatchDescription()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsBatchDescription(bb:flatbuffers.ByteBuffer, obj?:BatchDescription):BatchDescription {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new BatchDescription()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

formatVersion():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

guid():string|null
guid(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
guid(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

filename():string|null
filename(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
filename(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

ids(obj?:CompressedIntegers):CompressedIntegers|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new CompressedIntegers()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

versions(obj?:CompressedIntegers):CompressedIntegers|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new CompressedIntegers()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startBatchDescription(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addFormatVersion(builder:flatbuffers.Builder, formatVersion:number) {
  builder.addFieldInt32(0, formatVersion, 0);
}

static addGuid(builder:flatbuffers.Builder, guidOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, guidOffset, 0);
}

static addFilename(builder:flatbuffers.Builder, filenameOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, filenameOffset, 0);
}

static addIds(builder:flatbuffers.Builder, idsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, idsOffset, 0);
}

static addVersions(builder:flatbuffers.Builder, versionsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, versionsOffset, 0);
}

static endBatchDescription(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

}
