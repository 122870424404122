import type {
    AnyTrackerProps,
    SceneInstance, TrackerBuilderInfo,
} from 'bim-ts';
import {
    DC_CNSTS, TrackerTypeIdent
} from 'bim-ts';
import { Euler, Vector2 } from 'math-ts';


export function getPropWithThrow(
    si: SceneInstance,
    name: string,
) {
    const prop = si.properties.get(name);
    if (typeof prop === 'undefined')
        throw new Error(
            `requested property ${name} not exist on ${si.name}`,
        );
    return prop;
}

export function getTrackSeqBuildParams(
    si: SceneInstance,
): TrackerBuilderInfo {
    const trackerConfig = getTrackerConfig(si);
    const moduleSize = trackerConfig.moduleWidth;
    const modulesPerStringCountHorizontal =
        trackerConfig.horizontalModulesInString;
    const modulesGap = getPropWithThrow(si, 'tracker-frame | dimensions | modules_gap').as('m');
    const stringGap = getPropWithThrow(si, 'tracker-frame | dimensions | strings_gap').as('m');
    const motorGap = getPropWithThrow(si, 'tracker-frame | dimensions | motor_gap').as('m');
    const pileGap =
        getPropWithThrow(si, 'tracker-frame | dimensions | pile_bearings_gap').as('m');
    const motorPlacementCoefficient =
        getPropWithThrow(si, 'tracker-frame | dimensions | motor_placement').asNumber();
    const moduleBayCount =
        getPropWithThrow(si, 'tracker-frame | dimensions | module_bay_size').asNumber();
    const stringsPerTrackerCount =
        getPropWithThrow(si, 'tracker-frame | dimensions | strings_count').asNumber();
    const modulesRow = getPropWithThrow(si, "tracker-frame | dimensions | modules_row").asText();
    const useModulesRow = getPropWithThrow(si, "tracker-frame | dimensions | use_modules_row").asBoolean();

    return {
        useModulesRow,
        modulesRow,
        modulesPerStringCountHorizontal,
        stringsPerTrackerCount,
        moduleBayCount,
        moduleSize,
        motorPlacementCoefficient,
        pileGap,
        motorGap,
        stringGap,
        modulesGap,
    };
}

export type TrackerConfig = ReturnType<typeof getTrackerConfigForLegacyTracker>;
export function getTrackerConfigForLegacyTracker(si: SceneInstance) {
    const moduleWidth = getPropWithThrow(si, 'module | width').as('m');
    const horizontalModulesInString =
        getPropWithThrow(si, 'tracker-frame | string | modules_count_x').asNumber();
    const strCnt =
        getPropWithThrow(si, 'tracker-frame | dimensions | strings_count').asNumber();
    const stringWidth = horizontalModulesInString * moduleWidth;
    const trackerLenght = getPropWithThrow(si, 'tracker-frame | dimensions | length').as('m');
    const rotationAroundZRad =
        new Euler().setFromRotationMatrix(si.worldMatrix).z;
    const direction = new Vector2(0, 1)
        .rotateAround(new Vector2(0,0), rotationAroundZRad);
    const minPoint = si.worldMatrix.extractPosition().xy()
        .addScaledVector(direction.clone(), -trackerLenght / 2);
    const maxPoint = minPoint.clone().addScaledVector(
        direction.clone(), trackerLenght,
    );
    const maxWidth = getPropWithThrow(si, 'tracker-frame | dimensions | max_width').as('m');
    const hints = [minPoint, maxPoint];
    return {
        tracker: {
            minPoint,
            maxPoint,
            maxWidth,
            length: trackerLenght,
            strCnt,
            hints,
        },
        moduleWidth,
        horizontalModulesInString,
        stringWidth,
        rotationZ: rotationAroundZRad,
    };
}

export const getTrackerConfigForAnyTracker: typeof getTrackerConfigForLegacyTracker = (si) => {
    const props = si.props as AnyTrackerProps;
    const moduleWidth = props.module.width.as('m');
    const horizontalModulesInString = props.tracker_frame.string.modules_count_x.value;
    const strCnt = props.tracker_frame.dimensions.strings_count.value;

    const stringWidth = horizontalModulesInString * moduleWidth;
    const trackerLength = props.tracker_frame.dimensions.length?.as('m') ?? 0;

    // Assuming si is equivalent to the world matrix operations in this case
    const rotationAroundZRad = new Euler().setFromRotationMatrix(si.worldMatrix).z;
    const direction = new Vector2(0, 1)
        .rotateAround(new Vector2(0, 0), rotationAroundZRad);
    const minPoint = si.worldMatrix.extractPosition().xy()
        .addScaledVector(direction.clone(), -trackerLength / 2);
    const maxPoint = minPoint.clone().addScaledVector(
        direction.clone(), trackerLength
    );

    const maxWidth = props.tracker_frame.dimensions.max_width?.as('m') ?? 0;
    const hints = [minPoint, maxPoint];
    return {
        tracker: {
            minPoint,
            maxPoint,
            maxWidth,
            length: trackerLength,
            strCnt,
            hints,
        },
        moduleWidth,
        horizontalModulesInString,
        stringWidth,
        rotationZ: rotationAroundZRad,
    };
}

export function getTrackerConfigForLegacyFixedTilt(si: SceneInstance): TrackerConfig {
    const isPlacementHorizontal = getPropWithThrow(si, "dimensions | modules_horizontal_placement").asBoolean() ?? false;

    const moduleWidth = isPlacementHorizontal
        ? getPropWithThrow(si, 'module | length').as('m')
        : getPropWithThrow(si, 'module | width').as('m');

    const strCnt =
        getPropWithThrow(si, 'dimensions | strings_count').asNumber();
    const horizontalModulesInString =
        getPropWithThrow(si, 'string | modules_count_x').asNumber();
    const trackerLenght = getPropWithThrow(si, 'dimensions | length').as('m');

    const stringWidth = horizontalModulesInString * moduleWidth;

    const rotationAroundZRad =
        new Euler().setFromRotationMatrix(si.worldMatrix).z - Math.PI/2;
    const direction = new Vector2(0, 1)
        .rotateAround(new Vector2(0,0), rotationAroundZRad);
    const minPoint = si.worldMatrix.extractPosition().xy()
        .addScaledVector(direction.clone(), -trackerLenght / 2);
    const maxPoint = minPoint.clone().addScaledVector(
        direction.clone(), trackerLenght,
    );
    const maxWidth = getPropWithThrow(si, 'dimensions | width').as('m');
    const hints = [minPoint, maxPoint];
    return {
        tracker: {
            minPoint,
            maxPoint,
            maxWidth,
            length: trackerLenght,
            strCnt,
            hints,
        },
        moduleWidth,
        horizontalModulesInString,
        stringWidth,
        rotationZ: rotationAroundZRad,
    };
}
export function getTrackerConfig(si: SceneInstance) {
    if (si.type_identifier === TrackerTypeIdent) {
        return getTrackerConfigForLegacyTracker(si);
    } else if (si.type_identifier === 'any-tracker') {
        return getTrackerConfigForAnyTracker(si)
    } else if (si.type_identifier === 'fixed-tilt') {
        return getTrackerConfigForLegacyFixedTilt(si)
    } else {
        throw new Error('not supported');
    }
}



export function parseMultiHarnessConfigFromInstance(
    si: SceneInstance,
): DC_CNSTS.MultiharnessConfig | null {
    const multiharnessConfigBasePath = 'circuit | pattern | multiharness';
    const divs = si.properties.getPropStartingWith(
        [multiharnessConfigBasePath, 'div'].join(' | '),
    ).map(x => x.asNumber()).filter((x): x is number => typeof x === 'number');
    if (divs.length === 0)
        return null;
    return {
        divs,
        same_length: getPropWithThrow(
            si,
            multiharnessConfigBasePath + ' | same_length',
        ).asBoolean(),
    };
}


export function parsePatternFromInstance(
    si: SceneInstance,
): DC_CNSTS.PatternConfig | null {
    const patternId = si.properties.get('circuit | pattern | type')?.asText();
    const pattern = DC_CNSTS.PATTERNS[patternId as DC_CNSTS.PatternName];
    if (!pattern || typeof patternId === 'undefined')
        return null;
    const multiharness = parseMultiHarnessConfigFromInstance(si) ??
        undefined;
    const materialProps = si.properties
        .getPropStartingWith('circuit | pattern | min_wiring')
        .sort((l, r) => l._mergedPath.localeCompare(r._mergedPath));
    const parsed = materialProps
        .map(x => x.asNumber())
        .filter(x => Number.isFinite(x));
    //if (parsed.length !== pattern.conductors.length) {
    //    throw new Error(
    //        'cable material props length != pattern conductor length',
    //    );
    //}
    const temperature = si.properties
        .get('circuit | pattern | conductor_temperature')?.asNumber() || 75;
    return {
        patternId: patternId as DC_CNSTS.PatternName,
        pattern: pattern,
        multiharness,
        defaultCables: parsed.map(x => ({ gauge: x })),
        temperature,
        patternInstance: si,
    };
}
