<script lang="ts">
            
    import { getContext } from "svelte";
    import type { IdBimScene, UnitsMapper } from 'bim-ts';
    import type { PropsChartsContext } from './PropsChartsContext';
    import { VersionedStore } from '../VersionedStore';
    import AnyEchart from '../charts/AnyEchart.svelte';
    import type { echarts } from 'ui-charts';
    import ButtonComponent from "../libui/button/Button.svelte";
    import { PUI_Lazy } from 'ui-bindings';
    import PUI_Root from '../pui/PUI_Root.svelte';
    import { createPropsChartFromDataset, createPropsChartParams, CategoryDataset, HistogramDataset, colorizeFromDataset, colorizeFromDatasetHierarchically, createPropsDatasetGenerator, datasetShortStatsToString, type PropsDataset } from 'files-converters';
    import type { Readable } from 'svelte/store';
    import { Success, type ResultAsync } from 'engine-utils-ts';
    import EngineUiPanel from '../ui-panels/EngineUiPanel.svelte';
        
    export let context: PropsChartsContext;

    const bim = context.bim;
    const unitsMapper = getContext('unitsMapper') as Readable<UnitsMapper>;

    const [propsChartsParams, paramsPui] = createPropsChartParams(bim);

    const datasetLazy = createPropsDatasetGenerator(
        propsChartsParams,
        bim.instances,
        $unitsMapper,
    );

    $: dataset = new VersionedStore<ResultAsync<PropsDataset|null>>(datasetLazy, 1000);

    const echartOptionsLazy = createPropsChartFromDataset(propsChartsParams, datasetLazy);

    $:chartDescription = new VersionedStore(echartOptionsLazy, 1000);

    let echartInstance: echarts.ECharts | undefined = undefined;

    function onEchartInit(chart: echarts.ECharts) {
        echartInstance = chart;
        chart.on('selectchanged', (params: any) => {
            const dataset = datasetLazy.peekLastValue();
            if (!(dataset instanceof Success) || !dataset.value) {
                return;
            }

            const index = params.fromActionPayload.dataIndexInside;
            const selectedBin = dataset.value.bins[index];
            if (!selectedBin) {
                console.warn('selected bin invalid index', index)
                return;
            }
            bim.instances.setSelected(selectedBin.ids);
        });
        chart.on('brushselected', (params: any) => {
            const dataset = datasetLazy.peekLastValue();
            if (!(dataset instanceof Success) || !dataset.value) {
                return;
            }
            const indices = params.batch[0].selected[0].dataIndex as number[];
            const ids: IdBimScene[] = [];
            for (const index of indices) {
                const bin = dataset.value.bins[index];
                if (!bin) {
                    console.warn('selected bin invalid index', index)
                    return;
                }
                for (const id of bin.ids) {
                    ids.push(id);
                }
            }
            bim.instances.setSelected(ids);
        })
        // chart.on('brushend', (params) => {
        //     console.log('brushend', params);
        // })
    }

    function applyColors() {
        const dataset = datasetLazy.peekLastValue();
        if (!(dataset instanceof Success) || !dataset.value) {
            return;
        }
        colorizeFromDataset(bim, dataset.value);
    }
    function applyColorHierarchically() {
        const dataset = datasetLazy.peekLastValue();
        if (!(dataset instanceof Success) || !dataset.value) {
            return;
        }
        colorizeFromDatasetHierarchically(bim, dataset.value);
    }

    function selectErroneousObjects() {
        const dataset = datasetLazy.peekLastValue();
        if (dataset instanceof HistogramDataset) {
            const ids = dataset.erroneousInstances;
            bim.instances.setSelected(ids);
        }
    }

</script>

<EngineUiPanel showPositionMenu={true}>
<div class="root">

    <PUI_Root
        configIdentifier={'props-charts-params'}
        puiSource={new PUI_Lazy(paramsPui)}
    ></PUI_Root>

    <span class="actions-group">
        <ButtonComponent
            data={{ label: "colorize objects", onClick: applyColors }}
        />
        <ButtonComponent
            data={{ label: "colorize hierarchies", onClick: applyColorHierarchically }}
        />
    </span>

    {#if $chartDescription}
        {#if $dataset instanceof Success}
            {#key $dataset.value?.constructor}
                <div class="chart" style="height:{$chartDescription.heightPx}px;">
                    <AnyEchart
                        echartOptions={$chartDescription.echartOptions}
                        onEchartInit={onEchartInit}
                    ></AnyEchart>
                </div>
            {/key}
        {/if}

    {/if}

    {#if $dataset instanceof Success}
        {#if $dataset.value instanceof HistogramDataset}
            <div class="actions-group">
                <div class="mdc-typography mdc-typography--body1" >
                    {$dataset.value.xAxisName}
                </div>
            </div>

            {#if $dataset.value.filteredOutByVisibility.length}
                <div class="actions-group">
                    <div class="mdc-typography mdc-typography--body1" >
                        filtered out (hidden) count: {$dataset.value.filteredOutByVisibility.length}
                    </div>
                </div>
            {/if}
            {#if $dataset.value.erroneousInstances.length}
                <div class="actions-group">
                    <div class="mdc-typography mdc-typography--body1" >
                        could not convert into stats count: {$dataset.value.erroneousInstances.length}
                    </div>
                </div>
                <div class="actions-group">
                    <ButtonComponent
                        data={{ label: "select erroneous objects", onClick: selectErroneousObjects }}
                    />
                </div>
            {/if}
            {#if $dataset.value.shortStats}
                {#each datasetShortStatsToString($dataset.value.shortStats) as statStr}
                    <div class="actions-group">
                        <div class="mdc-typography mdc-typography--body1 " >
                            {@html statStr}
                        </div>
                    </div>
                {/each}
            {/if}

        {:else if $dataset.value instanceof CategoryDataset}
            {#if $dataset.value.filteredOutByVisibility.length}
                <div class="actions-group">
                    <div class="mdc-typography mdc-typography--body1" >
                        filtered out (hidden) count: {$dataset.value.filteredOutByVisibility.length}
                    </div>
                </div>
            {/if}
        {/if}

    {/if}
</div>
</EngineUiPanel>

<style>
    .root {
        display: flex;
        flex-direction: column;
    }
    .chart {
        width: 99%;
        display: flex;
        margin: 0 auto;
    }
    .actions-group {
        padding: 2px;
        display: flex;
        justify-content: center;
        text-align: center;
    }
</style>

    