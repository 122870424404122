// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class UpdateAccepted implements flatbuffers.IUnpackableObject<UpdateAcceptedT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):UpdateAccepted {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsUpdateAccepted(bb:flatbuffers.ByteBuffer, obj?:UpdateAccepted):UpdateAccepted {
  return (obj || new UpdateAccepted()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsUpdateAccepted(bb:flatbuffers.ByteBuffer, obj?:UpdateAccepted):UpdateAccepted {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new UpdateAccepted()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

identifier():string|null
identifier(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
identifier(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

hash():string|null
hash(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
hash(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startUpdateAccepted(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addIdentifier(builder:flatbuffers.Builder, identifierOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, identifierOffset, 0);
}

static addHash(builder:flatbuffers.Builder, hashOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, hashOffset, 0);
}

static endUpdateAccepted(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createUpdateAccepted(builder:flatbuffers.Builder, identifierOffset:flatbuffers.Offset, hashOffset:flatbuffers.Offset):flatbuffers.Offset {
  UpdateAccepted.startUpdateAccepted(builder);
  UpdateAccepted.addIdentifier(builder, identifierOffset);
  UpdateAccepted.addHash(builder, hashOffset);
  return UpdateAccepted.endUpdateAccepted(builder);
}

unpack(): UpdateAcceptedT {
  return new UpdateAcceptedT(
    this.identifier(),
    this.hash()
  );
}


unpackTo(_o: UpdateAcceptedT): void {
  _o.identifier = this.identifier();
  _o.hash = this.hash();
}
}

export class UpdateAcceptedT implements flatbuffers.IGeneratedObject {
constructor(
  public identifier: string|Uint8Array|null = null,
  public hash: string|Uint8Array|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const identifier = (this.identifier !== null ? builder.createString(this.identifier!) : 0);
  const hash = (this.hash !== null ? builder.createString(this.hash!) : 0);

  return UpdateAccepted.createUpdateAccepted(builder,
    identifier,
    hash
  );
}
}
