import { LegacyLogger } from '../LegacyLogger';
import type { NonMethodsOnly } from "../TypeUtils";

export interface ClassIdentWrap<T> {
    __classIdent: string;
    _v: NonMethodsOnly<T>;
    _fr: boolean;
}

export class WorkerClassPassRegistry {

    static instance(): WorkerClassPassRegistry {
        if (!(globalThis as any)['__wcpr']) {
            (globalThis as any)['__wcpr'] = new WorkerClassPassRegistry();
        }
        return (globalThis as any)['__wcpr'];
    }

    static registerClass<T extends Object>(ctor: {new(...args: any[]): T}, errorOnDuplicate: boolean = true) {
        const instance = WorkerClassPassRegistry.instance();
        instance.registerClass(ctor, errorOnDuplicate);
    }

    static registerClassExtended<T extends Object>(
        identifier: string,
        ctor: (arg: NonMethodsOnly<T>) => T,
        errorOnDuplicate: boolean = true
    ) {
        const instance = WorkerClassPassRegistry.instance();
        instance.registerClassExtended(identifier, ctor, errorOnDuplicate);
    }

    _ctorsPerIdent = new Map<string, {new(...args: any[]): Object}>();
    _privateCtorsPerIdent = new Map<string, (arg: NonMethodsOnly<any>) => Object>();

    registerClass<T extends Object>(ctor: {new(...args: any[]): T}, errorOnDuplicate: boolean = true) {
        const identifier = ctor.name;
        if (this._ctorsPerIdent.has(identifier) && errorOnDuplicate) {
            console.error(`class with identifier ${identifier} is already registered`, this._ctorsPerIdent.get(identifier));
        } else {
            this._ctorsPerIdent.set(identifier, ctor);
        }
    }

    registerClassExtended<T extends Object>(
        identifier: string,
        ctor: (arg: NonMethodsOnly<T>) => T,
        errorOnDuplicate: boolean = true
    ) {
        if (this._privateCtorsPerIdent.has(identifier) && errorOnDuplicate) {
            console.error(`private class with identifier ${identifier} is already registered`, this._ctorsPerIdent.get(identifier));
        } else {
            this._privateCtorsPerIdent.set(identifier, ctor);
        }
    }


}


export class WorkerClassPassTransformer {

    _ctorsPerIdent: Map<string, {new(...args: any[]): Object}>;
    _privateCtorsPerIdent: Map<string, (arg: NonMethodsOnly<any>) => Object>;


    _alreadyTransformedToWrappers = new Map<Object, Object>;
    _arrayBuffersFoundDuringTransformToWrappers = new Set<ArrayBuffer>();

    _alreadyTransformedToClasses = new WeakMap<Object, Object>();
    _arrayBuffersFoundDuringTransformToClasses = new Set<ArrayBuffer>();

    constructor() {
        this._ctorsPerIdent = WorkerClassPassRegistry.instance()._ctorsPerIdent;
        this._privateCtorsPerIdent = WorkerClassPassRegistry.instance()._privateCtorsPerIdent;
    }

    arrayBuffersToSendCombinedLength(): number {
        let sum = 0;
        for (const it of this._arrayBuffersFoundDuringTransformToWrappers) {
            sum += it.byteLength;
        }
        return sum;
    }

    clearClassesToWrappersCache() {
        this._alreadyTransformedToWrappers.clear();
        this._arrayBuffersFoundDuringTransformToWrappers.clear();
    }

    transformClassesToWrappers<T extends Object>(obj: T): T | ClassIdentWrap<T> {
        if (obj == null || typeof obj !== 'object') {
            return obj;
        }

        const alreadyTransformedValue = this._alreadyTransformedToWrappers.get(obj);
        if (alreadyTransformedValue) {
            return alreadyTransformedValue as T | ClassIdentWrap<T>;
        }

        let toReturn: Object;

        if (obj instanceof ArrayBuffer) {
            toReturn = obj;
            this._arrayBuffersFoundDuringTransformToWrappers.add(obj);
        } else if (ArrayBuffer.isView(obj)) {
            toReturn = obj;
            this._arrayBuffersFoundDuringTransformToWrappers.add(obj.buffer);
        } else if (Array.isArray(obj)) {
            let arrayClone: Array<any> | null = null;
            for (let i = 0; i < obj.length; ++i) {
                const it = obj[i];
                if (typeof it !== 'object' || it == null) {
                    continue;
                }
                const transformed = this.transformClassesToWrappers(it);
                if (transformed !== it) {
                    arrayClone = arrayClone ?? obj.slice();
                    arrayClone[i] = transformed;
                }
            }
            toReturn = arrayClone ?? obj;
        } else if (obj instanceof Map) {
            const objCopy: Map<any, any> = new Map<any, any>();
            let changed = false;
            for (const [k, v] of obj) {
                let kCopy, vCopy;
                if (typeof k === 'object') {
                    kCopy = this.transformClassesToWrappers(k);
                } else {
                    kCopy = k;
                }
                if (typeof v === 'object' && v != null) {
                    vCopy = this.transformClassesToWrappers(v);
                } else {
                    vCopy = v;
                }
                objCopy.set(kCopy, vCopy);
                changed = changed || kCopy !== k || vCopy !== v;
            }
            toReturn = changed ? objCopy : obj;
        } else {
            let objCopy: T | null = null;
            for (const key in obj) {
                const v = (obj as any)[key];
                if (typeof v !== 'object' || v == null) {
                    continue;
                }
                const transformed = this.transformClassesToWrappers(v);
                if (transformed !== v) {
                    objCopy = objCopy ?? Object.assign({}, obj);
                    (objCopy as any)[key] = transformed;
                }
            }
            objCopy = objCopy ?? obj;
            const ctorName = obj.constructor.name;
            if (this._ctorsPerIdent.has(ctorName) || this._privateCtorsPerIdent.has(ctorName)) {
                toReturn = {
                    __classIdent: ctorName,
                    _v: objCopy,
                    _fr: Object.isFrozen(obj),
                }
            } else {
                toReturn = objCopy;
                if (obj.constructor !== Object) {
                    LegacyLogger.deferredWarn('passing unregistered class to worker', obj.constructor.name);
                }
            }
        }
        if (toReturn != obj) {
            this._alreadyTransformedToWrappers.set(obj, toReturn);
        }
        return toReturn as T | ClassIdentWrap<T>;
    }

    // forceClearWrappersToClassesCache() {
    //     this._alreadyTransformedToBackToClasses = new WeakMap<Object, Object>();
    // }

    clearWrappersToClassesCache() {
        this._arrayBuffersFoundDuringTransformToClasses.clear();
    }

    consumeTransferablesToSendFromWorkerWithWrappers(): ArrayBuffer[] {
        // in case job executor does useless sending back and forth of the same arraybuffers
        // check that arraybuffer was not send in from the main thread
        const transferables: ArrayBuffer[] = [];
        for (const it of this._arrayBuffersFoundDuringTransformToWrappers) {
            if (!this._arrayBuffersFoundDuringTransformToClasses.has(it)) {
                transferables.push(it);
            } else {
                console.warn('SENDING BACK BUFFER THAT WAS SENT FROM MAIN THREAD', it);
            }
        }
        this._arrayBuffersFoundDuringTransformToClasses.clear();
        return transferables;
    }

    transformWrappersToClasses<T extends Object>(obj: T | ClassIdentWrap<T>): T {
        if (typeof obj !== 'object' || obj == null || Object.isFrozen(obj)) {
            return obj as T;
        }

        const alreadyTransformedValue = this._alreadyTransformedToClasses.get(obj);
        if (alreadyTransformedValue) {
            return alreadyTransformedValue as T;
        }

        let toReturn: Object;
        if (ArrayBuffer.isView(obj) || obj instanceof ArrayBuffer) {
            return obj;
            
        } else if (Array.isArray(obj)) {
            toReturn = obj; // transform array in place
            for (let i = 0; i < obj.length; ++i) {
                const it = obj[i];
                if (typeof it !== 'object' || it == null) {
                    continue;
                }
                const transformed = this.transformWrappersToClasses(it);
                obj[i] = transformed;
            }
        } else if (obj instanceof Map) {
            toReturn = obj; // transform map in place
            for (const [k, v] of obj) {
                let kCopy, vCopy;
                if (typeof k === 'object') {
                    kCopy = this.transformWrappersToClasses(k);
                } else {
                    kCopy = k;
                }
                if (typeof v === 'object' && v != null) {
                    vCopy = this.transformWrappersToClasses(v);
                } else {
                    vCopy = v;
                }
                if (kCopy !== k) {
                    obj.delete(k);
                }
                obj.set(kCopy, vCopy);
            }
        } else {
            for (const key in obj) {
                const v = (obj as any)[key];
                if (typeof v === 'object' && v != null) {
                    (obj as any)[key] = this.transformWrappersToClasses(v);
                }
            }
            const asWrap = obj  as unknown as Partial<ClassIdentWrap<T>>;
            if (typeof asWrap.__classIdent === 'string' && typeof asWrap._v === 'object') {
                const registeredCtor = this._ctorsPerIdent.get(asWrap.__classIdent);
                if (registeredCtor !== undefined) {
                    const classInstance = Object.create(registeredCtor.prototype);
                    Object.assign(classInstance, asWrap._v);
                    toReturn = classInstance;
                } else {
                    const registeredPrivateCtor = this._privateCtorsPerIdent.get(asWrap.__classIdent);
                    if (registeredPrivateCtor !== undefined) {
                        const classInstance = registeredPrivateCtor(asWrap._v);
                        toReturn = classInstance;
                    } else {
                        console.error(`unregistered constructor identifier ${asWrap.__classIdent}`, asWrap._v);
                        toReturn = asWrap._v;
                    }
                }
                if (asWrap._fr) {
                    Object.freeze(toReturn);
                }
            } else {
                toReturn = obj;
            }
        }
        if (toReturn !== obj) {
            this._alreadyTransformedToClasses.set(obj, toReturn);
            // if (Object.isFrozen(toReturn)) {
            //     this._alreadyTransformedToClasses.set(toReturn, toReturn);
            // }
        }
        return toReturn as T;
    }

}
