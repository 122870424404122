import { WorkerClassPassRegistry } from 'engine-utils-ts';
import type { Bim, IdBimScene } from '..';
import type { UiBindings } from 'ui-bindings';



export type EnergyWarningType = 'warning' | 'error';

export class EnergyStageUiWarning {
    constructor(
        public readonly description: string,
        public readonly type: EnergyWarningType,
        public readonly actions: EnergyStageUiActionMerged[],
    ) {}
}
WorkerClassPassRegistry.registerClass(EnergyStageUiWarning);

export class EnergyStageUiActionMerged {

    public readonly name: string;
    public readonly actionsToCall: {action: EnergyStageAction, instancesIds: IdBimScene[]}[];

    constructor(
        actionsToCall: {action: EnergyStageAction, instancesIds: IdBimScene[]}[],
    ) {
        this.name = actionsToCall[0]?.action.name ?? '???';
        this.actionsToCall = actionsToCall;

        for (const act of actionsToCall) {
            if (act.action.name !== this.name) {
                throw new Error('attempt tot merge actions with different identifiers: '+ actionsToCall.map(a => a.action.name).join());
            }
        }
    }

    execute(bim: Bim, uiBindings: UiBindings): void {
        for (const a of this.actionsToCall) {
            try {
                a.action.execute(bim, uiBindings, a.instancesIds);
            } catch (e) {
                console.error(e);
            }
        }
    }
}

export abstract class EnergyStageAction {
    constructor(
        public readonly name: string,
    ) {}
    abstract execute(bim: Bim, uiBindings: UiBindings, affectedInstancesIds: IdBimScene[]): void;
}

