
import { Allocated, DefaultMap, Deleted, IterUtils, LegacyLogger, ObservableStream } from 'engine-utils-ts';
import type { TypedArray } from 'math-ts';
import type { BufferAttribute } from '../3rdParty/three';

import type { WebGLBindingStates } from '../3rdParty/three';
import type { RendererExt } from '../composer/RendererExt';
import type { IdEngineGeo } from './AllEngineGeometries';
import type { KrGeoAttrIndex } from './AttributesIndices';
import { getAttrIndexOf } from './AttributesIndices';
import type { EngineGeometry} from './EngineGeometry';
import { EngineGeometrySharedGpu } from './EngineGeometry';
import type { GeometryGpuRepr } from './KrBufferGeometry';

export enum GpuUploadUrgency {
	Default,
	Urgent
}

export const enum GeometryGpuId { }

export function geoGpuIdHash14Bits(geoGpuid: GeometryGpuId) {
	const chunkId = (geoGpuid & GeometryChunkIdMask) >> 12;
	const inChunkId = geoGpuid & InChunkIdMask;
	return ((chunkId & 0x1FF) << 5) | (inChunkId & 0x1F); // 9 bits for chunk_id, 5 bits for in_chunk_id
}


const GeometryChunkIdMask 	= 0xF_FF_F0_00; // 16 bits offset by 12 bits
const InChunkIdMask = 0xF_FF; // lower 12 bits
const InvalIdEngineGeoGpuId = 0xF_FF_FF_FF;
const MaxGeometriesInChunk = InChunkIdMask - 1;
const GeometriesPreferredGpuBatchSize = (MaxGeometriesInChunk / 2) | 0;

export const MaxFramesToWaitForUpload = 20;

export interface GpuGeosUser {
	getGpuGeosWaitingFor(): IdEngineGeo[];
}

interface SharedGpuReprDescr {
	gpuId: GeometryGpuId,
	users: Set<IdEngineGeo>,
}


export class GpuGeometries {


	readonly _waitingForUpload: Map<IdEngineGeo, EngineGeometry> = new Map();

    _uploadDeferFramesCount = 0;

	renderer: RendererExt;

	readonly chunksById: Map<number, GeometriesGpuChunk> = new Map();
	readonly geometriesGpuIds: Map<IdEngineGeo, GeometryGpuId> = new Map();

	readonly _sharedGpuReprsPerId = new Map<IdEngineGeo, GeometryGpuRepr>();
	readonly _sharedDescrPerGeo = new Map<GeometryGpuRepr, SharedGpuReprDescr>();

	readonly _registeredUsers: GpuGeosUser[] = [];


	readonly gpuUploadsStream: ObservableStream<Allocated<IdEngineGeo> | Deleted<IdEngineGeo>>;

	_currChunk: GeometriesGpuChunk | null = null;

	constructor(renderer: RendererExt) {
		this.renderer = renderer;
		this.gpuUploadsStream = new ObservableStream({ identifier: 'engine-gpu-geos-allocs' });
	}

	registerGpuGeosUser(user: GpuGeosUser) {
		this._registeredUsers.push(user);
	}

	tryGetGpuId(geoId: IdEngineGeo): GeometryGpuId | undefined {
		let gpuId = this.geometriesGpuIds.get(geoId);
		return gpuId;
	}

	getByGpuId(gpuId: GeometryGpuId): GeometryGpuRepr | null {
		const chunkId = gpuId & GeometryChunkIdMask;
		const chunk = this.chunksById.get(chunkId);
		if (chunk) {
			chunk.inChunkRanges
			return chunk.inChunkGeometries.get(gpuId) || null;
		}
		return null;
	}

	getFreeChunkId(): number {
		for (let i = 1; i < GeometryChunkIdMask; ++i) {
			const chunkId = i * (InChunkIdMask + 1);
			if (!this.chunksById.has(chunkId)) {
				return chunkId;
			}
		}
		throw new Error('not more free ids for geo gpu chunks');
	}

	handleUploads(urgency: GpuUploadUrgency) {

		const gpuIdsRequested = new Set<IdEngineGeo>();
		for (const user of this._registeredUsers) {
			for (const id of user.getGpuGeosWaitingFor()) {
				gpuIdsRequested.add(id);
			}
		}

		if (gpuIdsRequested.size === 0) {
			return;
		}

        function sortGeosForUpload(t1: [IdEngineGeo, EngineGeometry], t2: [IdEngineGeo, EngineGeometry]): number {

            const isSharedDiff = (Number(t2[1] instanceof EngineGeometrySharedGpu) - Number(t1[1] instanceof EngineGeometrySharedGpu)) * 100;
            if (isSharedDiff) { // shared geometries should go first
                return isSharedDiff;
            }
            // const ctorName1 = t1[1].constructor.name;
            // const ctorName2 = t2[1].constructor.name;
            // if (ctorName1 !== ctorName2) { // sort by constructor to imporve later batching
            //     return ctorName1 < ctorName2 ? -1 : 1;
            // }
            return t1[0] - t2[0];

        }

		const requestedGeometriesToUpload = IterUtils.newMapFromTuples(
			IterUtils.filter(this._waitingForUpload, (t) => gpuIdsRequested.has(t[0]))
            .sort(sortGeosForUpload)
		);

		if (!requestedGeometriesToUpload.size) {
			return;
		}

		let shouldUpload =
			requestedGeometriesToUpload.size >= GeometriesPreferredGpuBatchSize
			|| this._uploadDeferFramesCount > MaxFramesToWaitForUpload
			|| (urgency === GpuUploadUrgency.Urgent && requestedGeometriesToUpload.size > 0);


		if (!shouldUpload) {
			this._uploadDeferFramesCount += 1;
			return;
		}
        const uploadStartTime = performance.now();

		this._uploadDeferFramesCount = 0;

        // first separate shared gpu geos and upload them first
        const result = new Map<IdEngineGeo, GeometryGpuId>();

        const sharedGpuGeosToUpload = new DefaultMap<GeometryGpuRepr, IdEngineGeo[]>(() => []);
        const nonSharedGeosToUpload = new Map<IdEngineGeo, GeometryGpuRepr>();

        for (const [id, geo] of requestedGeometriesToUpload) {
            let gpuRepr: GeometryGpuRepr;
            try {
                gpuRepr = geo.asGpuRepr();
            } catch (e) {
                console.error('error during geo gpu repr', e);
                result.set(id, InvalIdEngineGeoGpuId);
                continue;
            }

            if (geo instanceof EngineGeometrySharedGpu) {
                const sharedGeoCounter = this._sharedDescrPerGeo.get(gpuRepr);
                if (sharedGeoCounter) {
					result.set(id, sharedGeoCounter.gpuId);
                    sharedGeoCounter.users.add(id);
                    this._sharedGpuReprsPerId.set(id, gpuRepr);
                } else {
                    sharedGpuGeosToUpload.getOrCreate(gpuRepr).push(id);
                }
            } else {
                nonSharedGeosToUpload.set(id, gpuRepr);
            }

            if (performance.now() - uploadStartTime > 1000) {
                break;
            }
        }


        { // first try to upload shared geos
            const sharedUploads = this._uploadSome(new Set(sharedGpuGeosToUpload.keys()));
            for (const [gpuRepr, geometryGpuId] of sharedUploads) {
                console.assert(this._sharedDescrPerGeo.has(gpuRepr) === false, 'shared geos upload sanity check');

                const geoIds = sharedGpuGeosToUpload.get(gpuRepr);
                const sharedGeoCounter: SharedGpuReprDescr = {gpuId: geometryGpuId, users: new Set(geoIds)};
                this._sharedDescrPerGeo.set(gpuRepr, sharedGeoCounter);

                if (geoIds) {
                    for (const geoId of geoIds) {
                        result.set(geoId, geometryGpuId);
                        sharedGeoCounter.users.add(geoId);
                        this._sharedGpuReprsPerId.set(geoId, gpuRepr);
                    }
                } else {
                    console.error('geo ids should be in map', gpuRepr);
                }
            }
        }

        // now try to upload non shared geos
        {
            const nonSharedGeosToUploadSet = new Set<GeometryGpuRepr>();
            for (const [id, gpuRepr] of nonSharedGeosToUpload) {
                if (nonSharedGeosToUploadSet.has(gpuRepr)) {
                    console.error('non shared geometries actually share gpu representation, this will lead to allocation errors', id);
                }
                nonSharedGeosToUploadSet.add(gpuRepr);
            }
            const nonSharedUploads = this._uploadSome(nonSharedGeosToUploadSet);
            for (const [geoId, gpuRepr] of nonSharedGeosToUpload) {
                const gpuId = nonSharedUploads.get(gpuRepr);
                if (gpuId !== undefined) {
                    result.set(geoId, gpuId);
                }
            }
        }

		const allUploadedResult: IdEngineGeo[] = [];
        for (const [geoId, gpuId] of result) {
            this.geometriesGpuIds.set(geoId, gpuId);
            this._waitingForUpload.delete(geoId);
            if (gpuId !== InvalIdEngineGeoGpuId) {
                allUploadedResult.push(geoId);
            }
        }

		this.gpuUploadsStream.pushNext(new Allocated(allUploadedResult));
	}

    private _uploadSome(uploadRequest: Set<GeometryGpuRepr>): Map<GeometryGpuRepr, GeometryGpuId> {

        if (uploadRequest.size === 0) {
            return new Map();
        }

		const perLayoutGeometries = new DefaultMap<number, GeometryGpuRepr[]>(() => []);

        // start collecting batches per geometry layout
        for (const gpuGeo of uploadRequest) {
            const batch = perLayoutGeometries.getOrCreate(gpuGeo.layoutBinFlags);
            batch.push(gpuGeo);
        }
        // now upload biggest batch, and try again later


        const batches: [number, GeometryGpuRepr[]][] = [];

        for (const [layout, geometries] of perLayoutGeometries) {
            const geometriesPerBatch = IterUtils.splitArrayIntoChunks(geometries, GeometriesPreferredGpuBatchSize);

            for (const batch of geometriesPerBatch) {
                batches.push([layout, batch]);
            }
        }

        batches.sort((b1, b2) => b2.length - b1.length); // sort large to small

        const result = new Map<GeometryGpuRepr, GeometryGpuId>();

        for (let batchIndex = 0; batchIndex < batches.length; batchIndex += 1) {

            const [layout, geosBatch] =  batches[batchIndex];

            // if ((geosBatch.length < GeometriesPreferredGpuBatchSize / 50)
            //     && (batchIndex > 0)
            // ) {
            //     continue;
            //     // batch is too small, defer uploading it
            //     // but if its the largest batch, upload anyway
            // }

            try {
                this.resetBinding();
                const chunkId = this.getFreeChunkId();
                const [chunk, perGeoIds] = GeometriesGpuChunk.new(
                    this.renderer,
                    chunkId,
                    geosBatch,
                    layout
                );
                this.chunksById.set(chunkId, chunk);

                for (const [gpuRepr, gpuId] of perGeoIds) {
                    result.set(gpuRepr, gpuId);
                }

            } catch(e) {
                console.error('error during batch creation', e);
            } finally {
                for (const geometry of geosBatch) {
                    if (!result.has(geometry)) {
                        LegacyLogger.deferredError('gpu geometry upload failed', geometry);
                        result.set(geometry, InvalIdEngineGeoGpuId);
                    }
                }
            }
        }
		return result;
    }

	addForUpload(geometries: Iterable<[IdEngineGeo, EngineGeometry]>) {
		for (const [id, geo] of geometries) {
			this._waitingForUpload.set(id, geo);
		}
	}

	update(geometries: [IdEngineGeo, EngineGeometry][]) {
		this.delete(geometries.map(t => t[0]));
		this.addForUpload(geometries);
	}

	delete(ids: Iterable<IdEngineGeo>) {
		const deleted: IdEngineGeo[] = [];
		for (const engineId of ids) {
			this._waitingForUpload.delete(engineId);

			const gpuId = this.geometriesGpuIds.get(engineId);
			if (gpuId != undefined) {
				deleted.push(engineId);
				this.geometriesGpuIds.delete(engineId);

				const sharedGeo = this._sharedGpuReprsPerId.get(engineId);
				let gpuGeoShouldBeRemoved: boolean;
				if (sharedGeo !== undefined) {
                    this._sharedGpuReprsPerId.delete(engineId);
					const shareDescr = this._sharedDescrPerGeo.get(sharedGeo);
					if (shareDescr) {
						console.assert(shareDescr.users.delete(engineId), 'should be removed from shared geos description');
						console.assert(shareDescr.gpuId === gpuId, 'shared geo gpu id sanity check');
						gpuGeoShouldBeRemoved = shareDescr.users.size === 0;
					} else {
						console.error('unexpectedly invalid shared gpu reference', sharedGeo);
						gpuGeoShouldBeRemoved = true;
					}
				} else {
					gpuGeoShouldBeRemoved = true;
				}

				if (gpuGeoShouldBeRemoved) {
					if (sharedGeo !== undefined) {
						this._sharedDescrPerGeo.delete(sharedGeo);
					}
					const chunkId = gpuId & GeometryChunkIdMask;
					const chunk = this.chunksById.get(chunkId);
					if (chunk) {
						chunk.free(gpuId);
					}
				}


			}
		}
		for (const [chId, ch] of this.chunksById) {
			if (ch.isEmpty()) {
				ch.dispose(this.renderer);
				this.chunksById.delete(chId);
			}
		}
		this.gpuUploadsStream.pushNext(new Deleted(deleted));
    }

	bindByGpuId(id: GeometryGpuId): GeoInChunkRanges | null {
		if (id === InvalIdEngineGeoGpuId) {
			this.resetBinding();
			return null;
		}
		const chunkId = id & GeometryChunkIdMask;
		const chunk = this.chunksById.get(chunkId);
		if (chunk) {
			if (this._currChunk !== chunk) {
				this._currChunk = chunk;
				chunk.bind(this.renderer);
			}
			return chunk.getRangesFor(id);
		} else {
			this.resetBinding();
			return null;
		}
	}

	resetBinding() {
		this._currChunk = null;
		const state = this.renderer.bindingStates;
		state.initAttributes();
		state.disableUnusedAttributes();
	}

	clear() {
		const chunks = Array.from(this.chunksById.values());
		this.chunksById.clear();
		for (const chunk of chunks) {
			chunk.dispose(this.renderer);
		}
		this.geometriesGpuIds.clear();
	}

	freeChunksBuffersOnContextLoss() {
		for(const chunk of this.chunksById.values()) {
			chunk.freeBuffersOnContextLoss(this.renderer);
		}
	}

	reInitializeChunkBuffersOnContextRestore() {
		for(const chunk of this.chunksById.values()) {
			chunk.reInitializeBuffersOnContextRestore(this.renderer);
		}
	}
}

export interface GeoInChunkRanges {
	inChunkId: number;
	verts: { start: number, count: number };
	index: { start: number, count: number };
	edgesIndex: {start: number, count: number };
}

class GeometriesGpuChunk {

	chunkIndex: WebGLBuffer;

	constructor(
		readonly id: number,
		readonly layout: KrGeoAttrIndex,
		readonly inChunkRanges: Map<number, GeoInChunkRanges> = new Map(),
		readonly inChunkGeometries: Map<GeometryGpuId, GeometryGpuRepr> = new Map(),
		readonly chunkAttrs: {name: string, glBuffer: WebGLBuffer, glType: number, itemSizeCount: number,  normalized: boolean, shaderIndex: number}[],
		readonly positionsCount: number,
		readonly indexesCount: number,
		chunkIndex: WebGLBuffer,
	) {
		this.chunkIndex = chunkIndex;
	}

	static new(
		renderer: RendererExt,
		chunkId: number,
		geometries: GeometryGpuRepr[],
		layout: KrGeoAttrIndex,
	): [GeometriesGpuChunk, Map<GeometryGpuRepr, GeometryGpuId>] {
		// TODO move all of this out of contstructor, this is stupid
		LegacyLogger.assert(geometries.length > 0, 'geometries in chunk count check');
		LegacyLogger.assert(geometries.length < InChunkIdMask, 'geometries in chunk count check');
		LegacyLogger.assert((chunkId & GeometryChunkIdMask) === chunkId, 'geometry chunk id check');
		// LegacyLogger.assert(geometries.every((g) => g.layout === geometries[0].layout), "geometries in chunk layout check");

		if (!layout) {
			throw new Error('geometries should have non empty layout');
		}

		let positionsCount = 0;
		let indexCount = 0;
		const perGeoOffsets = new Map<GeometryGpuRepr, GeoInChunkRanges>();
		const inChunkRanges = new Map<number, GeoInChunkRanges>();
		const inChunkGeometries = new Map<GeometryGpuId, GeometryGpuRepr>();

		const perGeoGpuIds = new Map<GeometryGpuRepr, GeometryGpuId>();
		for (const geo of geometries) {
			const indexStart = indexCount;
			const edgesIndexStart = indexCount + geo.drawRange.count;
			const inChunkId = perGeoOffsets.size;

			const inChunkGeoRanges: GeoInChunkRanges = {
				inChunkId,
				verts: { start: positionsCount, count: geo.attributes.position.count },
				index: { start: indexStart, count: geo.drawRange.count },
				edgesIndex: { start: edgesIndexStart, count: geo.edgesDrawRange.count },
			};

			if (geo.layoutBinFlags !== layout) {
				throw new Error('geometries inside batch should have the same layout');
			}

			perGeoOffsets.set(geo, inChunkGeoRanges);
			inChunkRanges.set(inChunkGeoRanges.inChunkId, inChunkGeoRanges);

			positionsCount += geo.attributes.position.count;
			indexCount += geo.drawRange.count;
			indexCount += geo.edgesDrawRange.count;

			const gpuId = chunkId | inChunkId;
			LegacyLogger.debugAssert((gpuId & InChunkIdMask)  === inChunkId, 'in geo chunk id check');

			perGeoGpuIds.set(geo, gpuId);
			inChunkGeometries.set(gpuId, geo);
		}

		if (!(Number.isSafeInteger(positionsCount)
			&& Number.isSafeInteger(indexCount) )
		) {
			throw new Error('invalid gpu geometries chunk');
		}

		const gl = renderer.context;

		const indexArray = new Uint32Array(indexCount);

		const chunkAttrsTotalArrays: {name: string, glType: number, itemSizeCount: number, normalized: boolean, array: TypedArray}[] = [];

		for (const geo of geometries) {

			if (chunkAttrsTotalArrays.length === 0) {
				// setup attributes on first geometry
				for (const attrName in geo.attributes) {
					const attr = geo.attributes[attrName];
					const TypedArrayConstructor = attr.array!.constructor as {new(count: number): TypedArray};
					const arrayBuffer = new TypedArrayConstructor(attr.itemSize * positionsCount);
					chunkAttrsTotalArrays.push({
						name: attrName,
						array: arrayBuffer,
						glType: glTypeFromBufferAttribute(attr),
						normalized: attr.normalized,
						itemSizeCount: attr.itemSize,
					})
				}
			}

			const offsets = perGeoOffsets.get(geo)!;

			for (const attrDescr of chunkAttrsTotalArrays) {
				const geoAttr = geo.attributes[attrDescr.name];
				if (!geoAttr?.array) {
					console.error('chunk attribute is absent in chunk geo', geo, attrDescr.name);
					continue;
				}
				if (attrDescr.itemSizeCount !== geoAttr.itemSize) {
					console.error('geo attr item size is different from batch, skipping', geo, attrDescr.name, attrDescr.itemSizeCount, geoAttr.itemSize);
					continue;
				}
				if (offsets.verts.count != geoAttr.count) {
					console.error('geo attr count is not the same as in chunk offset suggests', geo, attrDescr.name, offsets.verts.count, geoAttr.count);
					continue;
				}
				attrDescr.array.set(geoAttr.array, offsets.verts.start * attrDescr.itemSizeCount);
			}

			const geoIndex = geo.index.array!;
			for (let i = 0; i < geoIndex.length; ++i) {
				indexArray[offsets.index.start + i] = geoIndex[i] + offsets.verts.start;
			}
			const geoEdgeIndex = geo.edgesIndex.array!;
			for (let i = 0; i < geoEdgeIndex.length; ++i) {
				indexArray[offsets.edgesIndex.start + i] = geoEdgeIndex[i] + offsets.verts.start;
			}
		}
		const state = renderer.bindingStates;
		state.initAttributes();
		state.disableUnusedAttributes();

		const chunkIndex = GeometriesGpuChunk.allocateArrayBuffer(renderer, indexArray, gl.ELEMENT_ARRAY_BUFFER);

		// const vao = gl.createVertexArray();
		// gl.bindVertexArray(vao);

		// gl.bindBuffer(gl.ARRAY_BUFFER, posBuffer);
		// gl.enableVertexAttribArray(0);
		// gl.vertexAttribPointer(0, 3, gl.FLOAT, false, 0, 0);

		// gl.bindBuffer(gl.ARRAY_BUFFER, normalsBuffer);
		// gl.enableVertexAttribArray(1);
		// gl.vertexAttribPointer(1, 3, gl.BYTE, true, 0, 0);

		// gl.bindBuffer(gl.ARRAY_BUFFER, uvsBuffer);
		// gl.enableVertexAttribArray(2);
		// gl.vertexAttribPointer(2, 2, gl.FLOAT, false, 0, 0);


		// gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);

		// // gl.bindVertexArray(null);

		// gl.bindBuffer(gl.ARRAY_BUFFER, null);
		// gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
		// gl.disableVertexAttribArray(0);
		// gl.disableVertexAttribArray(1);
		// gl.disableVertexAttribArray(2);

		const chunkAttrs = chunkAttrsTotalArrays.map(a => {
			return {
				name: a.name,
				glBuffer: this.allocateArrayBuffer(renderer, a.array, gl.ARRAY_BUFFER),
				glType: a.glType,
				itemSizeCount: a.itemSizeCount,
				normalized: a.normalized,
				shaderIndex: getAttrIndexOf(a.name),
			}
		});

		const gpuChunk = new GeometriesGpuChunk(
			chunkId,
			layout,
			inChunkRanges,
			inChunkGeometries,
			chunkAttrs,
			positionsCount,
			indexCount,
			chunkIndex,
		);

		return [gpuChunk, perGeoGpuIds];
	}

	bind(renderer: RendererExt) {
		const gl = renderer.context;
		const state = renderer.bindingStates as WebGLBindingStates;
		state.initAttributes();

		gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);

		for (const attr of this.chunkAttrs) {
			state.enableAttribute(attr.shaderIndex);
			gl.bindBuffer(gl.ARRAY_BUFFER, attr.glBuffer);
			gl.vertexAttribPointer(attr.shaderIndex, attr.itemSizeCount, attr.glType, attr.normalized, 0, 0);
		}
		gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.chunkIndex);

		state.disableUnusedAttributes();
	}

	getRangesFor(geoGpuId: GeometryGpuId): GeoInChunkRanges | null {
		const inChunkId = InChunkIdMask & geoGpuId;
		const range = this.inChunkRanges.get(inChunkId);
		return range || null;
	}

	free(geoGpuId: GeometryGpuId) {
		const geo = this.inChunkGeometries.get(geoGpuId);
		if (geo) {
			this.inChunkGeometries.delete(geoGpuId);
			const inChunkId = InChunkIdMask & geoGpuId;
			this.inChunkRanges.delete(inChunkId); // todo: reuse freed memory
		}
	}

	isEmpty() {
		return this.inChunkGeometries.size === 0;
	}

	static allocateArrayBuffer(renderer: RendererExt, buffer: ArrayBuffer, bufferTarget: number): WebGLBuffer {
		const gl = renderer.context;
		const buf = gl.createBuffer();
		gl.bindBuffer(bufferTarget, buf);
		gl.bufferData(bufferTarget, buffer, gl.STATIC_DRAW);
		gl.bindBuffer(bufferTarget, null);
		return buf!;
	}

	dispose(renderer: RendererExt) {
		const gl = renderer.context;
		gl.deleteBuffer(this.chunkIndex);
		for (const attr of this.chunkAttrs) {
			gl.deleteBuffer(attr.glBuffer);
		}
		this.chunkAttrs.length = 0;
		// gl.deleteVertexArray(this.chunkVao);
	}

	freeBuffersOnContextLoss(renderer: RendererExt) {
		const gl = renderer.context;
		gl.deleteBuffer(this.chunkIndex);
		for (const attr of this.chunkAttrs) {
			gl.deleteBuffer(attr.glBuffer);
		}
	}

	reInitializeBuffersOnContextRestore(renderer: RendererExt) {
		const gl = renderer.context;
		const indexArray = new Uint32Array(this.indexesCount);
		const chunkAttrsTotalArrays: {name: string, itemSizeCount: number, array: TypedArray}[] = [];

		for (const [geoGpuId, geo] of this.inChunkGeometries) {

			if (chunkAttrsTotalArrays.length === 0) {
				// setup attributes on first geometry
				for (const attrName in geo.attributes) {
					const attr = geo.attributes[attrName];
					const TypedArrayConstructor = attr.array!.constructor as {new(count: number): TypedArray};
					const arrayBuffer = new TypedArrayConstructor(attr.itemSize * this.positionsCount);
					chunkAttrsTotalArrays.push({
						name: attrName,
						array: arrayBuffer,
						itemSizeCount: attr.itemSize,
					})
				}
			}

			const inChunkId = InChunkIdMask & geoGpuId;
			const offsets = this.inChunkRanges.get(inChunkId)!;

			for (const attrDescr of chunkAttrsTotalArrays) {
				const geoAttr = geo.attributes[attrDescr.name];
				if (!geoAttr?.array) {
					console.error('chunk attribute is absent in chunk geo', geo, attrDescr.name);
					continue;
				}
				if (attrDescr.itemSizeCount !== geoAttr.itemSize) {
					console.error('geo attr item size is different from batch, skipping', geo, attrDescr.name, attrDescr.itemSizeCount, geoAttr.itemSize);
					continue;
				}
				if (offsets.verts.count != geoAttr.count) {
					console.error('geo attr count is not the same as in chunk offset suggests', geo, attrDescr.name, offsets.verts.count, geoAttr.count);
					continue;
				}
				attrDescr.array.set(geoAttr.array, offsets.verts.start * attrDescr.itemSizeCount);
			}

			const geoIndex = geo.index.array!;
			for (let i = 0; i < geoIndex.length; ++i) {
				indexArray[offsets.index.start + i] = geoIndex[i] + offsets.verts.start;
			}
			const geoEdgeIndex = geo.edgesIndex.array!;
			for (let i = 0; i < geoEdgeIndex.length; ++i) {
				indexArray[offsets.edgesIndex.start + i] = geoEdgeIndex[i] + offsets.verts.start;
			}
		}

		this.chunkIndex = GeometriesGpuChunk.allocateArrayBuffer(renderer, indexArray, gl.ELEMENT_ARRAY_BUFFER);
		this.chunkAttrs.forEach((value, index) =>
			value.glBuffer = GeometriesGpuChunk.allocateArrayBuffer(renderer, chunkAttrsTotalArrays[index].array, gl.ARRAY_BUFFER));
	}
}


function glTypeFromBufferAttribute(buffer: BufferAttribute): number {
	if (buffer.array instanceof Float32Array) {
		return WebGL2RenderingContext.FLOAT;
	}
	if (buffer.array instanceof Int8Array) {
		return WebGL2RenderingContext.BYTE;
	}
	if (buffer.array instanceof Uint8Array) {
		return WebGL2RenderingContext.UNSIGNED_BYTE;
	}
	if (buffer.array instanceof Int16Array) {
		return WebGL2RenderingContext.SHORT;
	}
	if (buffer.array instanceof Uint16Array) {
		return WebGL2RenderingContext.UNSIGNED_SHORT;
	}
	console.error('unrecognized buffer array type ', buffer);
	return 0;
}
// export type GeoHandle = Handle & KrGeometries;

// export class KrGeometries {

// 	readonly allocSyncer: AllocationSynchronizer<GeoHandle, KrEdgedGeometry>;


// 	constructor() {
// 		this.allocSyncer = new AllocationSynchronizer<GeoHandle, KrEdgedGeometry>(allocSyncerParams);;

// 	}


// }
