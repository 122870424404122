<script lang="ts">
    import Notifications from 'svelte-notifications';
    import Notification from './notifications/Notification.svelte';
    import { onDestroy, setContext } from 'svelte';
    import { LogLevel, ObservableStream, ProjectNetworkClient, ScopedLogger } from 'engine-utils-ts';
    import { Router, Route } from "svelte-routing";
    import { DashboardComp } from './dashboard';
    import { AuthHub, MockedAuthHub } from './auth';
    import type { IAuthHub } from './auth';
    import { ProjectApi } from './projects';
    import Project from './Project.svelte';
    import Bad from './BadPage.svelte';
    import { ProjectHub } from './ProjectHub';
    import ContactUs from './ContactUsPage.svelte';
    import { Catalog, UnitsMapper } from 'bim-ts';
    import { ContextMenuProvider } from './libui/context-menu';
    import { ComparisonMode } from './ComparisonMode';
    import { VersionedStore } from './VersionedStore';
    import { setupIntercom } from './Intercom';
    import { PostHogWrapper, configurePosthog } from './Posthog';
    import type { TelemetryEvent } from 'ui-bindings';
    import { activateAgGrid } from './AgGridActivator';
    import { extractVerdataVersionFromUrl } from './utils/UrlUils';
    import GlobalNotifications from './global-notifications/GlobalNotifications.svelte';
    import { initDatadogLogs } from './DataDogLogs';


    // configure auth hub
    let authHub: IAuthHub = env.desktopMode ? new MockedAuthHub() : new AuthHub();
    setContext('authHub', authHub);


    // configure network client
    let network = setContext('network', new ProjectNetworkClient({
        mutateRequest: async (req) => {
            const token = await authHub.getAuthToken();
            if (!token)
                return
            req.headers.set('authorization', token);
        }
    }));
    

    let unitsMapper = setContext('unitsMapper', new VersionedStore(new UnitsMapper()));
    let catalog = setContext('catalog', new Catalog(network, $unitsMapper));
    $: if ($authHub.authData?.company) {
        const versionToLoad = extractVerdataVersionFromUrl("catalog-version");
        catalog.initAsync($authHub.authData.company, versionToLoad);
    }

    // @ts-ignore
    window.catalog = catalog;

    // configure projects api service
    let projectsApi = setContext('projectApi', new ProjectApi(network));

    // @ts-ignore
    window.comparison = setContext('comparison', new ComparisonMode());

    // project page
    setContext('projectHub', new ProjectHub(projectsApi));

    let posthog: PostHogWrapper | undefined = undefined;

    $: if($authHub.authData) {

        const logger = new ScopedLogger('ui-root', LogLevel.Info);
        const savedMode = localStorage.getItem('dashboardMode');

        if (!env.desktopMode) {
            setupIntercom($authHub.authData, savedMode);
            posthog = configurePosthog(logger, $authHub.authData);
            // @ts-ignore
            window.__phg__ = posthog;
        }
    }
    
    initDatadogLogs();
    activateAgGrid();

    authHub.onLogOut(() => {
        posthog?.reset();
    })
    
    const pushState = history.pushState
    history.pushState = () => {
        pushState.apply(history, arguments);
        posthog?.captureRaw('$pageview');
    }

    const telemetryEventsStream: ObservableStream<TelemetryEvent> = new ObservableStream({
        identifier: "telemetry-events-root-stream",
    });
    setContext(telemetryEventsStream.identifier, telemetryEventsStream);
    telemetryEventsStream.subscribe({
        settings: {immediateMode: true},
        onNext: (event) => posthog?.captureEvent(event),
    });

    onDestroy(() => {
        telemetryEventsStream.dispose();
    })

</script>

<Notifications item={Notification}>
    <ContextMenuProvider>
      {#if $authHub.authData}
          <Router>
              <Route path="dashboard" component={DashboardComp} />
              <Route path=":id" let:params>
                  <Project projectId={+params['id']} />
              </Route>
              <Route path="" component={Project} />
              <Route path="/bad" component={Bad} />
              <Route path="/contact-us" component={ContactUs} />
              <!--<Route path="/catalog" component={AssetsAndCatalogPage} />-->
          </Router>
      {/if}
      <GlobalNotifications />
    </ContextMenuProvider>
</Notifications>

<style>
</style>
