import type { SceneInstance } from 'bim-ts';
import { BimProperty } from 'bim-ts';
import type { RGBAHex } from 'engine-utils-ts';
import { RGBA } from 'engine-utils-ts';
import { Vector3 } from 'math-ts';

const BoundaryExcludePropPath = BimProperty.MergedPath(['boundary', 'boundary_type']);

export function sceneInstanceGizmoColor(instance: SceneInstance): RGBAHex | 0 {
    let color: RGBAHex | 0 = 0;
	if (instance.colorTint) {
		color = instance.colorTint;
    } else if (instance.type_identifier == 'boundary') {
        const includeExclude = instance.properties.get(BoundaryExcludePropPath);
        const isInclude = includeExclude?.value == 'include';
        color = isInclude ? RGBA.new(0.2, 0.2, 0.2, 0.6): RGBA.new(0.5, 0, 0, 0.6);
    }
    return color;
}


export class TriangulationMaterialDescr {
    readonly xColor: RGBAHex;
    readonly yColor: RGBAHex;
    readonly zColor: RGBAHex;

    constructor(xColor?:RGBAHex, yColor?:RGBAHex, zColor?:RGBAHex) {
        this.xColor = xColor ?? RGBA.new(0.0, 0.0, 0.0, 0.2);
        this.yColor = yColor ?? RGBA.new(0.1, 0.1, 0.1, 0.2);
        this.zColor = zColor ?? RGBA.new(0.2, 0.2, 0.2, 0.0);
    }

}

export const RaycastAlphaLimit = 0.17;

const DefaultSceneInstanceAlpha = Object.freeze(new Vector3(0.25, 0.25, 0.25));

export function sceneInstanceTriangulationAlpha(instance: SceneInstance):
    Readonly<Vector3>
{
    if (instance.type_identifier == 'boundary') {
        const includeExclude = instance.properties.get(BoundaryExcludePropPath);
        const isInclude = includeExclude?.value == 'include';
        const tr = DefaultSceneInstanceAlpha.clone();
        if (isInclude) {
            tr.z = 0;
        } else {
            tr.z *= 0.6;
        }
        return tr;
    }
    return DefaultSceneInstanceAlpha;
}

