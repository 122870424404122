import { DxfHandle, DXFNode, DxfProperties } from "./DxfNode";
import { AttrFlag, BlockTypeFlag, HorizontalJustification, VerticalJustification } from "./DxfEnums";
import { Vector3, Vector2, Aabb2 } from "math-ts";
import { annotationLayer, DxfEquipmentData, DxfImageData, DxfPolylineData, Table, TableTextCell } from "./DxfDataService";
import { POLYLINE_PROPERTIES, INSERT_PROPERTIES, ATTRIBUTE_DEFINITION_PROPERTIES, SEQEND_PROPERTIES, ATTRIBUTE_PROPERTIES, TEXT_PROPERTIES, LINE_PROPERTIES, CIRCLE_PROPERTIES, ARC_PROPERTIES, GEODATA_PROPERTIES, DICTIONARY_PROPERTIES, FACE3D_PROPERTIES, IMAGE_PROPERTIES, IMAGEDEF_PROPERTIES, LAYOUT_PROPERTIES, VIEWPORT_PROPERTIES, SPLINE_PROPERTIES, PLOTSETTINGS_PROPERTIES } from "./DxfEntitiesDefaultProperties";
import { DxfFileManager } from "./DxfFileManager";
import { getPileBlockName } from "./DxfService";
import exp from "constants";



type PileType = "SAP" | "SAPD" | "SAE" | "SAED" | "SC" | "SCD" | "SMP" | "HAP" | "HAPD" | "HAE" | "HAED" | "HC" | "HCD" | "HMP"
export interface dxfTextData {
    text: string;
    position: Vector2;
    rotation?: number;
    height?: number;
    horizontalJustification?: HorizontalJustification;
    verticalJustification?: VerticalJustification;
    color?: number;
    layer?: string;
}

export interface splineData{
    knots:Vector2[];
    thickness?:number;
    layer?:string;
}

export interface circleData{
    center: Vector2;
    radius: number;
    layer?: string;
    width?:number;
}

export interface attributeInsertData{
    tag: string,
    text: string,
    position: Vector2 | Vector3,
    layer?:string,
    rotation?: number,
    heigh?: number,
    horizontalJustification?:HorizontalJustification,
    verticalJustification?:VerticalJustification,
    attrFlag?:AttrFlag,
}

export interface blockInsertData{
    blockName: string | DXFNode,
    insertPoint: Vector3 | Vector2,
    rotation?: number,
    layer?: string,
    color?: number
}


export class DXFNodeFactory {
    private static getParent(manager: DxfFileManager, blockName?: string) {
        let parent: DXFNode | undefined = undefined;
        if (blockName !== undefined) {
            const block = manager.getBlock(blockName);
            if (block !== undefined) {
                parent = block;
            } else {
                parent = manager.getSection("ENTITIES");
                console.log(
                    `There is no block named ${blockName}! Entity will be add to ENTITIES section!`
                );
            }
        } else {
            parent = manager.getSection("ENTITIES");
        }
        return parent;
    }

    public static createViewPort(
        manager: DxfFileManager,
        owner: DXFNode,
        size: Vector2,
        center: Vector2
    ) {
        const ownerName = owner.properties.get(2) as string;
        const paperSpaceBlockDefHandle = manager
            .getBlockRecord(ownerName)
            ?.properties.get(5) as string;
        const vieportHandle = DxfHandle.handle();
        const vieportDictHandle = DxfHandle.handle();

        const viewPorthandle = vieportHandle;
        const viewPortProps = new DxfProperties(VIEWPORT_PROPERTIES);
        viewPortProps.set(5, viewPorthandle);
        viewPortProps.set(330, paperSpaceBlockDefHandle);
        viewPortProps.set(10, center.x);
        viewPortProps.set(20, center.y);
        viewPortProps.set(40, Math.abs(size.x));
        viewPortProps.set(41, Math.abs(size.y));
        viewPortProps.set(12, center.x);
        viewPortProps.set(22, center.y);
        viewPortProps.set(45, Math.abs(size.x));

        viewPortProps.setAtIndex(2, { code: 360, value: vieportDictHandle });

        if (ownerName === "*Paper_Space") {
            owner = manager.getSection("ENTITIES");
        }
        manager.addNode(owner, "VIEWPORT", viewPortProps);

        const dictProps = new DxfProperties(DICTIONARY_PROPERTIES);
        dictProps.set(5, vieportDictHandle);
        dictProps.set(330, viewPorthandle);
        dictProps.set(281, 1);

        manager.addDictionary(dictProps);
    }

    public static createPaperLayout(manager: DxfFileManager, name: string, sheetSize?:Vector2, limits?:Aabb2, number?:number) {

        function calculateScaleToFitObject(
            objectSize: Vector2,
        ): number {
            const scaleX =  getRealSheetSizeX(shitViewOffset) /objectSize.x;
            const scaleY =  getRealSheetSizeY(shitViewOffset)  / objectSize.y;
            const scale = Math.min(scaleX, scaleY);

            return scale;
        }

        function calculateImageOrigin(limits:Aabb2, scale:number = 1):Vector2{
            const sheetCenter: Vector2 =  new Vector2(getRealSheetSizeX()/2, getRealSheetSizeY()/2);
            const objectCenter = limits.getCenter().clone().multiplyScalar(scale);
            const shift = (sheetCenter.clone().sub(objectCenter))
            shift.divideScalar(scale);
            return shift;
        }

        function getRealSheetSizeX(offset:number = 0){
            return sheetSizeX-leftMargin-rightMargin-2*offset
        }
        function getRealSheetSizeY(offset:number = 0){
            return sheetSizeY-topMargin-bottomMargin-2*offset
        }
        
        const shitViewOffset = 20
        const sheetSizeX = sheetSize ? sheetSize.x : 594;
        const sheetSizeY = sheetSize ? sheetSize.y : 420;

        const leftMargin = 7.5;
        const bottomMargin = 20;
        const rightMargin = 7.5;
        const topMargin = 20;

        const paperspaces = manager
            .getBlocksNames()
            .filter((name) => name.startsWith("*Paper_Space"));

        const blockName = `*Paper_Space${paperspaces.length}`;

        const block = manager.addBlock(blockName, BlockTypeFlag.SimpleBlock);
        const blockRecHandle = manager
            .getBlockRecord(blockName)
            ?.properties.get(5) as string;

        const objects = manager.getSection("OBJECTS");

        const layoutDict = manager.getDictionaryByName("ACAD_LAYOUT");
        if (!layoutDict) {
            console.log(`Layout dictionary didn't find`);
            return block;
        }

        const dictHandle = layoutDict.properties.get(5) as string;

        const layoutHandle = DxfHandle.handle();
        const layoutProps = new DxfProperties(LAYOUT_PROPERTIES);
        const plotSettingsProps = new DxfProperties(PLOTSETTINGS_PROPERTIES)

        plotSettingsProps.set(44, sheetSizeX);
        plotSettingsProps.set(45, sheetSizeY);

        if(limits){

            const customScale = calculateScaleToFitObject(limits.getSize());
            plotSettingsProps.set(147, customScale);

            const imageOrigin = calculateImageOrigin(limits, customScale);

            plotSettingsProps.set(148, imageOrigin.x);
            plotSettingsProps.set(149, imageOrigin.y);

        }
        
        layoutProps.set(5, layoutHandle);
        layoutProps.set(1, name);
        layoutProps.set(330, dictHandle);
        layoutProps.setAtIndex(2, { code: 330, value: dictHandle });
        layoutProps.set(330, blockRecHandle, false);
        layoutProps.set(331, 0, false);
        if(number!==undefined){
            layoutProps.set(71, number)
        }
        const index = layoutProps.entries().map(x => x.code).findIndex(v => v === 100) + 1;
        layoutProps.setAtIndex(index, ...plotSettingsProps.entries())

        layoutDict.properties.set(3, name, false);
        layoutDict.properties.set(350, layoutHandle, false);

        

        manager.addNode(objects, "LAYOUT", layoutProps);

        return block;
    }

    public static createImageInsert(
        manager: DxfFileManager,
        data: DxfImageData
    ): string {
        const imageName = data.name;
        const objects = manager.getSection("OBJECTS");
        const entities = manager.getSection("ENTITIES");

        const dicts = manager.getDictionaries();
        const presentImageDict = dicts.find(
            (dict) =>
                (dict.properties.get(3) as string | undefined) === imageName
        );

        let imageDefHandle: string = DxfHandle.handle();
        const imageHandle = DxfHandle.handle();
        const imageDefReactorHandle = DxfHandle.handle();

        let imageDefinition: DXFNode | undefined = undefined;

        const pixelsizeX = data.worldSize.x / data.width;
        const pixelsizeY = data.worldSize.y / data.heigh;

        if (!presentImageDict) {
            const imageDefProps = new DxfProperties(IMAGEDEF_PROPERTIES);
            imageDefProps.set(5, imageDefHandle);
            imageDefProps.set(1, `./${data.name}`);
            imageDefProps.set(10, data.width);
            imageDefProps.set(20, data.heigh);
            imageDefProps.set(11, pixelsizeX);
            imageDefProps.set(21, pixelsizeY);

            imageDefinition = manager.addNode(
                objects,
                "IMAGEDEF",
                imageDefProps
            );

            const dictHandle = DxfHandle.handle();
            const imagedict = new DxfProperties([
                { code: 5, value: dictHandle },
                { code: 330, value: "A" },
                { code: 100, value: "AcDbDictionary" },
                { code: 281, value: 1 },
                { code: 3, value: imageName },
                { code: 350, value: imageDefHandle },
            ]);

            manager.addDictionary(imagedict, "ACAD_IMAGE_DICT");
        } else {
            imageDefHandle = presentImageDict.properties.get(350) as string;
            imageDefinition = manager
                .getSection("OBJECTS")
                .children.find(
                    (child) =>
                        (child.properties.get(5) as string | undefined) ===
                        imageDefHandle
                );
        }

        const uVector = new Vector3(pixelsizeX * data.scale.x, 0, 0);
        const vVector = new Vector3(0, pixelsizeX * data.scale.y, 0);

        uVector.applyMatrix4Rotation(data.rotation);
        vVector.applyMatrix4Rotation(data.rotation);

        const imageProps = new DxfProperties(IMAGE_PROPERTIES);
        imageProps.set(5, imageHandle);
        imageProps.set(10, data.insertionPoint.x);
        imageProps.set(20, data.insertionPoint.y);
        imageProps.set(30, data.insertionPoint.z);

        imageProps.set(11, uVector.x);
        imageProps.set(21, uVector.y);
        imageProps.set(31, uVector.z);

        imageProps.set(12, vVector.x);
        imageProps.set(22, vVector.y);
        imageProps.set(32, vVector.z);

        imageProps.set(13, data.width); //width in pixels
        imageProps.set(23, data.heigh); //heigh in pixels

        imageProps.set(14, data.width - 0.5);
        imageProps.set(24, data.heigh - 0.5);

        imageProps.set(340, imageDefHandle);
        imageProps.set(360, imageDefReactorHandle);

        const image = manager.addNode(entities, "IMAGE", imageProps);

        const imageDefReactorProps = new DxfProperties();
        imageDefReactorProps.set(5, imageDefReactorHandle);
        imageDefReactorProps.set(330, imageHandle);
        imageDefReactorProps.set(100, "AcDbRasterImageDefReactor");
        imageDefReactorProps.set(90, 2);
        imageDefReactorProps.set(330, imageHandle, false);

        const imageDefReactor = manager.addNode(
            objects,
            "IMAGEDEF_REACTOR",
            imageDefReactorProps
        );

        if (imageDefinition) {
            imageDefinition.properties.setAtIndex(2, {
                code: 330,
                value: imageDefReactorHandle,
            });
        }

        return imageHandle;
    }

    public static createSortenTable(
        manager: DxfFileManager,
        handels: string[]
    ) {
        if (handels.length < 2) {
            return;
        }

        const dictProps = new DxfProperties([
            { code: 5, value: DxfHandle.handle() },
            { code: 330, value: "2" },
            { code: 100, value: "AcDbDictionary" },
            { code: 280, value: 1 },
            { code: 281, value: 1 },
            { code: 3, value: "ACAD_SORTENTS" },
            { code: 360, value: 69 },
        ]);
        manager.addDictionary(dictProps);

        const sortentableProps = new DxfProperties([
            { code: 5, value: DxfHandle.handle() },
            { code: 330, value: "3" },
            { code: 100, value: "AcDbSortentsTable" },
            { code: 330, value: 2 },
        ]);

        for (let i = 0; i < handels.length - 1; i++) {
            sortentableProps.set(331, handels[i], false);
            sortentableProps.set(5, handels[i + 1], false);
            sortentableProps.set(331, handels[i + 1], false);
            sortentableProps.set(5, handels[i], false);
        }

        const objects = manager.getSection("OBJECTS");
        manager.addNode(objects, "SORTENTSTABLE", sortentableProps);
    }

    public static create3dFace(
        manager: DxfFileManager,
        layer: string,
        color: number,
        points: Vector3[],
        parentBlockName?: string,
        
    ) {
        if (points.length < 3 || points.length > 4) {
            return;
        }
        const faceProps = new DxfProperties(FACE3D_PROPERTIES);
        faceProps.set(8, layer);
        faceProps.set(62, color);

        faceProps.set(10, points[0].x);
        faceProps.set(20, points[0].y);
        faceProps.set(30, points[0].z);

        faceProps.set(11, points[1].x);
        faceProps.set(21, points[1].y);
        faceProps.set(31, points[1].z);

        faceProps.set(12, points[2].x);
        faceProps.set(22, points[2].y);
        faceProps.set(32, points[2].z);

        faceProps.set(13, points[2].x);
        faceProps.set(23, points[2].y);
        faceProps.set(33, points[2].z);

        const parent = this.getParent(manager, parentBlockName);
        manager.addNode(
            parent,
            "3DFACE",
            faceProps,
            undefined,
            undefined,
            true
        );
    }

    public static createLine(
        manager: DxfFileManager,
        start: Vector2,
        end: Vector2,
        layer: string = "0",
        parentBlockName?: string
    ): DXFNode {
        const lineProps = new DxfProperties(LINE_PROPERTIES);
        lineProps.set(5, DxfHandle.handle());
        lineProps.set(8, layer);
        lineProps.set(10, start.x);
        lineProps.set(20, start.y);
        lineProps.set(11, end.x);
        lineProps.set(21, end.y);
        const parent = this.getParent(manager, parentBlockName);
        return manager.addNode(parent, "LINE", lineProps);
    }

    public static createCircle(
        manager: DxfFileManager,
        data:circleData,
        parentBlockName?: string
    ) {
        const parent = this.getParent(manager, parentBlockName);
        const ownerHandle: string | undefined = parentBlockName ? manager.getBlockRecord(parentBlockName)?.properties.get(5) as string : undefined;

        const circleLayer = data.layer ?? "0";

        const circleProps = new DxfProperties(CIRCLE_PROPERTIES);
        circleProps.set(5, DxfHandle.handle());
        circleProps.set(8, circleLayer);
        circleProps.set(10, data.center.x);
        circleProps.set(20, data.center.y);
        circleProps.set(40, data.radius);
        if(data.width){
            circleProps.set(39,data.width);
        }

        if(ownerHandle){
            circleProps.set(330, ownerHandle);
        }

        return manager.addNode(parent, "CIRCLE", circleProps);
    }

    public static createPolyLine(
        manager: DxfFileManager,
        props: DxfPolylineData,
        parentBlockName?: string
    ) {
        const parent = this.getParent(manager, parentBlockName);
        const ownerHandle: string | undefined = parentBlockName
        ? (manager
              .getBlockRecord(parentBlockName)
              ?.properties.get(5) as string)
        : undefined;


        const isClosed = props.closed ? 1 : 0;
        if (props.layer) {
            manager.addLayer(props.layer, props.layerColour);
        }

        const polylineProps = new DxfProperties(POLYLINE_PROPERTIES);
        polylineProps.set(5, DxfHandle.handle());
        if (props.layer) {
            polylineProps.set(8, props.layer);
        }

        polylineProps.set(43, props.width);
        for (const vert of props.vertices) {
            polylineProps.set(10, vert.x, false);
            polylineProps.set(20, vert.y, false);
        }
        polylineProps.set(90, props.vertices.length);
        polylineProps.set(70, isClosed);

        if (props.color !== undefined) {
            polylineProps.set(62, props.color);
        }

        if(ownerHandle){
            polylineProps.set(330, ownerHandle);
        }

        const polylineNode = manager.addNode(
            parent,
            "LWPOLYLINE",
            polylineProps
        );
        return polylineNode;
    }

    public static createBlockInsert(
        manager: DxfFileManager,
        blockInsertData: blockInsertData,
        parentBlockName?: string
    ): DXFNode | undefined {

        let blockName: string = "";
        if (typeof blockInsertData.blockName === "string") {
            blockName = blockInsertData.blockName;
        } else {
            blockName = blockInsertData.blockName.properties.get(2) as string;
        }


        const insertProps = new DxfProperties(INSERT_PROPERTIES);

        insertProps.set(5, DxfHandle.handle());
        insertProps.set(2, blockName);
        if (blockInsertData.layer !== undefined) {
            insertProps.set(8, blockInsertData.layer);
        }
        insertProps.set(10, blockInsertData.insertPoint.x);
        insertProps.set(20, blockInsertData.insertPoint.y);
        insertProps.set(30, 0);
        if(blockInsertData.rotation){
            insertProps.set(50, blockInsertData.rotation);
        }
        if(blockInsertData.color!==undefined){
            insertProps.set(62, blockInsertData.color);
        }

        const blockDef = manager.getBlock(blockName);
        if (blockDef) {
            const ifHasAttributes = blockDef.children.some(
                (nodes) => nodes.type === "ATTDEF"
            );
            if (ifHasAttributes) {
                insertProps.set(66, 1);
            }
        }

        const parent = this.getEntityParentNode(manager, parentBlockName);

        if (parent === undefined) {
            return;
        }

        return manager.addNode(parent, "INSERT", insertProps);
    }

    public static createBlock(
        manager: DxfFileManager,
        name: string,
        layer: string = "0",
        type: BlockTypeFlag = BlockTypeFlag.SimpleBlock,
        annotative: boolean = false
    ) {
        return manager.addBlock(name, type, layer, annotative);
    }

    private static getEntityParentNode(
        manager: DxfFileManager,
        blockName?: string
    ): DXFNode | undefined {
        let parent: DXFNode;

        if (blockName !== undefined) {
            const block = manager.getBlock(blockName);
            if (block !== undefined) {
                parent = block;
            } else {
                return;
            }
        } else {
            parent = manager.getSection("ENTITIES");
        }
        return parent;
    }

    public static setView(
        manager: DxfFileManager,
        center: Vector3,
        width?: number,
        height?: number
    ) {
        const vport = manager.getViewPort("*ACTIVE");
        if (vport !== undefined) {
            vport.properties.set(12, center.x);
            vport.properties.set(22, center.y);

            if (width && height) {
                vport.properties.set(40, height);
                vport.properties.set(41, width / height);
            }
        }
    }

    public static createLayer(
        manager: DxfFileManager,
        name: string,
        colour?: number
    ) {
        manager.addLayer(name, colour);
    }

    public static addAttributeDefinition(
        manager: DxfFileManager,
        blockName: string,
        tag: string,
        promt: string,
        origin: Vector2 | Vector3 = new Vector3(),
        rotation: number = 0,
        height: number = 10,
        layer?:string,
        textStyle?: string,
    ): DXFNode | undefined {
        const blocDef = manager.getBlock(blockName);
        if (blocDef === undefined) {
            return;
        }
        blocDef.properties.set(70, BlockTypeFlag.BlockHasAttributes);

        const attDefProps = new DxfProperties(ATTRIBUTE_DEFINITION_PROPERTIES);
        attDefProps.set(5, DxfHandle.handle());
        attDefProps.set(10, origin.x);
        attDefProps.set(20, origin.y);
        attDefProps.set(50, rotation);
        attDefProps.set(2, tag);
        attDefProps.set(3, promt);
        if(textStyle){
            attDefProps.set(7, textStyle);
        }
        if(layer){
            attDefProps.set(8, layer);
        }
        attDefProps.set(40, height);
        attDefProps.set(72, HorizontalJustification.Center);
        attDefProps.set(74, VerticalJustification.Middle);
        attDefProps.set(11, origin.x);
        attDefProps.set(21, origin.y);

        if (!blocDef.children.some((x) => x.type === "SEQEND")) {
            const seqEndprops = new DxfProperties(SEQEND_PROPERTIES);
            seqEndprops.set(5, DxfHandle.handle());

            manager.addNode(blocDef, "SEQEND", seqEndprops);
        }

        return manager.addNode(blocDef, "ATTDEF", attDefProps);
    }

    public static AddSeqEnd(
        manager: DxfFileManager,
        ownerHandle: string,
        block?: string
    ) {
        
        const parent = this.getParent(manager, block)

        const seqEndprops = new DxfProperties(SEQEND_PROPERTIES);
        seqEndprops.set(5, DxfHandle.handle());
        seqEndprops.set(330, ownerHandle);

        manager.addNode(parent, "SEQEND", seqEndprops);
    }

    public static AddAttribute(
        manager: DxfFileManager,
        ownerHandle: string,
        attrData:attributeInsertData,
        parentBlockName?: string
    ) {

        const widthFactor = 0.78; //hardcoded for Arial textStyle

        const height = attrData.heigh ?? 0.8;
        const rotation = attrData.rotation || 0;
        const attrFlag = attrData.attrFlag ?? AttrFlag.Invisible;

        const parent =
            this.getEntityParentNode(manager, parentBlockName) ??
            manager.getSection("ENTITIES");
        const attProps = new DxfProperties(ATTRIBUTE_PROPERTIES);
        attProps.set(5, DxfHandle.handle());
        attProps.set(330, ownerHandle);
        attProps.set(1, attrData.text);
        attProps.set(2, attrData.tag);
        if(attrData.layer){
            attProps.set(8, attrData.layer);
        }

        attProps.set(10, attrData.position.x);
        attProps.set(20, attrData.position.y);
        attProps.set(40, height);
        attProps.set(50, rotation);
        attProps.set(70, attrFlag);


        const positionX = attrData.position.x;
        const positionY = attrData.position.y;

        if (
            attrData.horizontalJustification !== undefined &&
            attrData.horizontalJustification !== HorizontalJustification.Left
        ) {

            // Calculate string length based on text height and width factor
            const textLength = attrData.text.length * height * widthFactor;

            attProps.set(72, attrData.horizontalJustification);

            // Adjust alignment point for non-zero justification
            let alignX = positionX;
            if (
                attrData.horizontalJustification ===
                HorizontalJustification.Center
            ) {
                alignX -= textLength / 2; // Center-aligned
            } else if (
                attrData.horizontalJustification ===
                HorizontalJustification.Right
            ) {
                alignX -= textLength; // Right-aligned
            }

            attProps.set(11, alignX);
            
        }

        if (
            attrData.verticalJustification !== undefined &&
            attrData.verticalJustification !== VerticalJustification.Bottom
        ) {
            attProps.set(74, attrData.verticalJustification);

            let alignY = positionY;

            if(attrData.verticalJustification === VerticalJustification.Middle){
                alignY -= height/2;
            }else if(attrData.verticalJustification === VerticalJustification.Top){
                alignY -= height;
            }

            attProps.set(21, alignY);
        }


        return manager.addNode(parent, "ATTRIB", attProps);
    }

    public static createText(
        manager: DxfFileManager,
        data:dxfTextData,
        parentBlockName?: string
    ) {
        let parent = this.getEntityParentNode(manager, parentBlockName);
        const ownerHandle: string | undefined = parentBlockName
        ? (manager
              .getBlockRecord(parentBlockName)
              ?.properties.get(5) as string)
        : undefined;

        if (parent === undefined) {
            return;
        }

        const textProps = new DxfProperties(TEXT_PROPERTIES);
        textProps.set(5, DxfHandle.handle());
        textProps.set(10, data.position.x);
        textProps.set(20, data.position.y);
        textProps.set(11, data.position.x);
        textProps.set(21, data.position.y);
        textProps.set(31, 0);

        textProps.set(1, data.text);
        if (data.height !== undefined) {
            textProps.set(40, data.height);
        }
        if (data.layer !== undefined) {
            textProps.set(8, data.layer);
        }
        if (data.horizontalJustification!== undefined){ 
            textProps.set(72, data.horizontalJustification);
        }

        if (data.verticalJustification !== undefined){
            textProps.set(73, data.verticalJustification);
        }

        if (data.rotation){
            textProps.set(50, data.rotation); //rotation
        }

        if(data.color !== undefined){
            textProps.setAtIndex(4, {code:62, value:7})
        }
        if(ownerHandle){
            textProps.set(330, ownerHandle);
        }

        textProps.set(7, "Arial");

        return manager.addNode(parent, "TEXT", textProps);
    }

    public static createPileTable(
        manager: DxfFileManager,
        name: string,
        layer: string,
        tableData: Table
    ) {
        const blockName = name;
        this.createBlock(
            manager,
            blockName,
            layer,
            BlockTypeFlag.SimpleBlock,
            true
        );
        const rowHeight = 20;
        let width = tableData[0].reduce((sum, x) => sum + x.width, 0);
        let heigh = tableData.length * rowHeight;

        for (let rowIndex = 0; rowIndex < tableData.length; rowIndex++) {
            let currentX = 0;
            const row = tableData[rowIndex];

            const currentY = -rowHeight * rowIndex;

            const start = new Vector2(0, currentY);
            const end = new Vector2(width, currentY);

            this.createLine(manager, start, end, layer, blockName);

            for (let colIndex = 0; colIndex < row.length; colIndex++) {
                const cell = row[colIndex];
                const x = currentX + cell.width / 2;
                const y = currentY - rowHeight / 2;
                if ("content" in cell) {
                const textData:dxfTextData = {text:cell.content , position:new Vector2(x, y), height:5, layer}

                this.createText(manager, textData, blockName);
                } else {
                    const pileType = cell.symbol as PileType;
                    if (!pileType) {
                        console.log(`Cant identify pile type ${cell.symbol}`);
                        continue;
                    }

                    const blocDefIcon = DXFNodeFactory.createPileIcon(
                        manager,
                        pileType,
                        "pvfarm_piles",
                        cell.color,
                        10
                    );
                    if (blocDefIcon) {

                        const pileInsertData: blockInsertData = {
                            blockName: blocDefIcon, 
                            insertPoint: new Vector2(x, y),
                            layer
                        }

                        this.createBlockInsert(
                            manager,
                            pileInsertData,
                            blockName
                        );
                    }
                }

                const colStart = new Vector2(currentX, currentY);
                const colEnd = new Vector2(currentX, currentY - rowHeight);

                this.createLine(manager, colStart, colEnd, layer, blockName);

                currentX += cell.width;
            }
        }

        this.createLine(
            manager,
            new Vector2(width, 0),
            new Vector2(width, -heigh),
            layer,
            blockName
        );
        this.createLine(
            manager,
            new Vector2(0, -heigh),
            new Vector2(width, -heigh),
            layer,
            blockName
        );
    }

    public static createPileIcon(
        manager: DxfFileManager,
        name: PileType,
        layer: string,
        color: number,
        diameter: number
    ): DXFNode | undefined {
        let node: DXFNode | undefined = undefined;

        const blockIconName = getPileBlockName(name, color);
        if (manager.getBlocksNames().includes(blockIconName)) {
            return manager.getBlock(blockIconName)!;
        }

        switch (name) {
            case "SAP": {
                node = this.addPileIconSAP(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "SAPD": {
                node = this.addPileIconSAPD(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "SAE": {
                node = this.addPileIconSAE(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "SAED": {
                node = this.addPileIconSAED(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "SC": {
                node = this.addPileIconSC(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "SCD": {
                node = this.addPileIconSCD(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "SMP": {
                node = this.addPileIconSMP(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "HAP": {
                node = this.addPileIconHAP(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "HAPD": {
                node = this.addPileIconHAPD(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "HAE": {
                node = this.addPileIconHAE(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "HAED": {
                node = this.addPileIconSMP(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "HC": {
                node = this.addPileIconHAED(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "HCD": {
                node = this.addPileIconHCD(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "HMP": {
                node = this.addPileIconHMP(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }

            default:
                break;
        }

        const codeAttr = DXFNodeFactory.addAttributeDefinition(
            manager,
            blockIconName,
            "PILEROWCODE",
            "Row code",
            new Vector3(0, 1),
            0,
            2,
            annotationLayer,
            "Arial"
        );

        const indexAttr = DXFNodeFactory.addAttributeDefinition(
            manager,
            blockIconName,
            "PILEROWINDEX",
            "Row index",
            new Vector3(0, -1),
            0,
            2,
            annotationLayer,
            "Arial"
        );


        return node;
    }

    public static addPileIconSAP(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.SimpleBlock,
            pileLayer,
            true
        );
        this.addCircle(manager, diameter, 0, color, pileLayer, blockDef);

        return blockDef;
    }

    public static addPileIconSAPD(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.SimpleBlock,
            pileLayer,
            true
        );

        this.addCircle(manager, diameter, 1, color, pileLayer, blockDef);
        this.addCross(manager, diameter, 0, color, pileLayer, blockDef);

        return blockDef;
    }

    public static addPileIconSAE(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.SimpleBlock,
            pileLayer,
            true
        );

        this.addCaps(manager, diameter, color, pileLayer, blockDef, 0);

        return blockDef;
    }

    public static addPileIconSAED(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.SimpleBlock,
            pileLayer,
            true
        );

        this.addCaps(
            manager,
            diameter,
            color,
            pileLayer,
            blockDef,
            diameter / 10
        );
        this.addCross(manager, diameter, 0, color, pileLayer, blockDef);

        return blockDef;
    }

    public static addPileIconSC(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.SimpleBlock,
            pileLayer,
            true
        );
        this.addStar(manager, diameter, color, pileLayer, name);

        return blockDef;
    }

    public static addPileIconSCD(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.SimpleBlock,
            pileLayer,
            true
        );

        this.addStar(manager, diameter, color, pileLayer, name);
        this.addCross(
            manager,
            diameter,
            diameter / 12,
            color,
            pileLayer,
            blockDef
        );

        return blockDef;
    }

    public static addPileIconSMP(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.SimpleBlock,
            pileLayer,
            true
        );

        this.addDiamond(manager, diameter, color, pileLayer, blockDef);
        return blockDef;
    }

    public static addPileIconHAP(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.SimpleBlock,
            pileLayer,
            true
        );

        this.addCircle(manager, diameter, 0, color, pileLayer, blockDef);
        this.addCircle(manager, diameter / 2, 0, color, pileLayer, blockDef);

        return blockDef;
    }

    public static addPileIconHAPD(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.SimpleBlock,
            pileLayer,
            true
        );

        this.addCircle(manager, diameter, 1, color, pileLayer, blockDef);
        this.addCircle(manager, diameter / 1, 1, color, pileLayer, blockDef);

        this.addCross(manager, diameter, 0, color, pileLayer, blockDef);

        return blockDef;
    }

    public static addPileIconHAE(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.SimpleBlock,
            pileLayer,
            true
        );

        this.addCaps(manager, diameter, color, pileLayer, blockDef, 0);
        this.addCaps(manager, diameter / 2, color, pileLayer, blockDef, 0);

        return blockDef;
    }

    public static addPileIconHAED(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.SimpleBlock,
            pileLayer,
            true
        );

        this.addCaps(
            manager,
            diameter,
            color,
            pileLayer,
            blockDef,
            diameter / 10
        );
        this.addCaps(
            manager,
            diameter / 2,
            color,
            pileLayer,
            blockDef,
            diameter / 10
        );
        this.addCross(manager, diameter, 0, color, pileLayer, blockDef);

        return blockDef;
    }

    public static addPileIconHC(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.SimpleBlock,
            pileLayer,
            true
        );
        this.addStar(manager, diameter, color, pileLayer, name);
        this.addCaps(manager, diameter, color, pileLayer, blockDef, 2);

        return blockDef;
    }

    public static addPileIconHCD(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.SimpleBlock,
            pileLayer,
            true
        );

        this.addStar(manager, diameter, color, pileLayer, name);
        this.addCross(
            manager,
            diameter,
            diameter / 12,
            color,
            pileLayer,
            blockDef
        );
        this.addCaps(manager, diameter, color, pileLayer, blockDef, 2);

        return blockDef;
    }

    public static addPileIconHMP(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.SimpleBlock,
            pileLayer,
            true
        );
        this.addDiamond(manager, diameter, color, pileLayer, blockDef);
        this.addDiamond(manager, diameter / 2, color, pileLayer, blockDef);
        return blockDef;
    }

    private static addStar(
        manager: DxfFileManager,
        diameter: number,
        color: number,
        pileLayer: string,
        parent: string
    ) {
        const radius = diameter / 2;
        const sqrt2 = Math.sqrt(2);
        const intersection1 = (radius * sqrt2) / 2;

        const sideWidth = diameter / 6;

        const dxfPolylineData: DxfPolylineData = {
            layer: pileLayer,
            color: color,
            closed: true,
            width: 0,
            vertices: [
                new Vector3(-intersection1, intersection1 - sideWidth),
                new Vector3(-intersection1, intersection1), //vertex 1
                new Vector3(-intersection1 + sideWidth, intersection1),
                new Vector3(0, 0 + sideWidth),
                new Vector3(intersection1 - sideWidth, intersection1),
                new Vector3(intersection1, intersection1), //vertex 2
                new Vector3(intersection1, intersection1 - sideWidth),
                new Vector3(0 + sideWidth, 0),
                new Vector3(intersection1, -intersection1 + sideWidth),
                new Vector3(intersection1, -intersection1), //vertex 3
                new Vector3(intersection1 - sideWidth, -intersection1),
                new Vector3(0, -sideWidth),
                new Vector3(-intersection1 + sideWidth, -intersection1),
                new Vector3(-intersection1, -intersection1), //vertex 4
                new Vector3(-intersection1, -intersection1 + sideWidth),
                new Vector3(-sideWidth, 0),
            ],
        };

        DXFNodeFactory.createPolyLine(manager, dxfPolylineData, parent);
    }

    private static addCross(
        manager: DxfFileManager,
        diameter: number,
        offset: number,
        color: number,
        pileLayer: string,
        blockDef: DXFNode
    ) {
        const radius = diameter / 2;
        const sqrt2 = Math.sqrt(2);
        const intersection1 = (radius * sqrt2) / 2;

        const line1Props = new DxfProperties(LINE_PROPERTIES);
        line1Props.set(39, 2);
        line1Props.set(8, pileLayer);
        line1Props.set(10, -intersection1 + offset);
        line1Props.set(20, -intersection1 + offset);
        line1Props.set(11, intersection1 - offset);
        line1Props.set(21, intersection1 - offset);

        const line2Props = new DxfProperties(LINE_PROPERTIES);
        line2Props.set(39, 2);
        line2Props.set(8, pileLayer);
        line2Props.set(10, intersection1 - offset);
        line2Props.set(20, -intersection1 + offset);
        line2Props.set(11, -intersection1 + offset);
        line2Props.set(21, intersection1 - offset);

        manager.addNode(
            blockDef,
            "LINE",
            line1Props,
            undefined,
            undefined,
            true
        );
        manager.addNode(
            blockDef,
            "LINE",
            line2Props,
            undefined,
            undefined,
            true
        );
    }

    private static addCaps(
        manager: DxfFileManager,
        diameter: number,
        color: number,
        pileLayer: string,
        blockDef: DXFNode,
        offset: number
    ) {
        const radius = diameter / 2;
        const sqrt2 = Math.sqrt(2);
        const intersection1 = (radius * sqrt2) / 2;

        const line1Props = new DxfProperties(LINE_PROPERTIES);
        line1Props.set(39, 2);
        line1Props.set(8, pileLayer);
        line1Props.set(10, -intersection1);
        line1Props.set(20, -intersection1 + offset);
        line1Props.set(11, -intersection1);
        line1Props.set(21, intersection1 - offset);
        line1Props.set(62, color);

        const line2Props = new DxfProperties(LINE_PROPERTIES);
        line2Props.set(39, 2);
        line2Props.set(8, pileLayer);
        line2Props.set(10, -intersection1 + offset);
        line2Props.set(20, intersection1);
        line2Props.set(11, intersection1 - offset);
        line2Props.set(21, intersection1);
        line2Props.set(62, color);

        const line3Props = new DxfProperties(LINE_PROPERTIES);
        line3Props.set(39, 2);
        line3Props.set(8, pileLayer);
        line3Props.set(10, intersection1);
        line3Props.set(20, intersection1 - offset);
        line3Props.set(11, intersection1);
        line3Props.set(21, -intersection1 + offset);
        line3Props.set(62, color);

        const line4Props = new DxfProperties(LINE_PROPERTIES);
        line4Props.set(39, 2);
        line4Props.set(8, pileLayer);
        line4Props.set(10, intersection1 - offset);
        line4Props.set(20, -intersection1);
        line4Props.set(11, -intersection1 + offset);
        line4Props.set(21, -intersection1);
        line4Props.set(62, color);

        manager.addNode(
            blockDef,
            "LINE",
            line1Props,
            undefined,
            undefined,
            true
        );
        manager.addNode(
            blockDef,
            "LINE",
            line2Props,
            undefined,
            undefined,
            true
        );
        manager.addNode(
            blockDef,
            "LINE",
            line3Props,
            undefined,
            undefined,
            true
        );
        manager.addNode(
            blockDef,
            "LINE",
            line4Props,
            undefined,
            undefined,
            true
        );
    }

    private static addDiamond(
        manager: DxfFileManager,
        diameter: number,
        color: number,
        pileLayer: string,
        blockDef: DXFNode
    ) {
        const radius = diameter / 2;
        const sqrt2 = Math.sqrt(2);
        const intersection1 = (radius * sqrt2) / 2;

        const line1Props = new DxfProperties(LINE_PROPERTIES);
        line1Props.set(39, 2);
        line1Props.set(8, pileLayer);
        line1Props.set(10, -intersection1 * (3 / 4));
        line1Props.set(20, 0);
        line1Props.set(11, 0);
        line1Props.set(21, intersection1);
        line1Props.set(62, color);

        const line2Props = new DxfProperties(LINE_PROPERTIES);
        line2Props.set(39, 2);
        line2Props.set(8, pileLayer);
        line2Props.set(10, 0);
        line2Props.set(20, intersection1);
        line2Props.set(11, intersection1 * (3 / 4));
        line2Props.set(21, 0);
        line2Props.set(62, color);

        const line3Props = new DxfProperties(LINE_PROPERTIES);
        line3Props.set(39, 2);
        line3Props.set(8, pileLayer);
        line3Props.set(10, intersection1 * (3 / 4));
        line3Props.set(20, 0);
        line3Props.set(11, 0);
        line3Props.set(21, -intersection1);
        line3Props.set(62, color);

        const line4Props = new DxfProperties(LINE_PROPERTIES);
        line4Props.set(39, 2);
        line4Props.set(8, pileLayer);
        line4Props.set(10, 0);
        line4Props.set(20, -intersection1);
        line4Props.set(11, -intersection1 * (3 / 4));
        line4Props.set(21, 0);
        line4Props.set(62, color);

        manager.addNode(
            blockDef,
            "LINE",
            line1Props,
            undefined,
            undefined,
            true
        );
        manager.addNode(
            blockDef,
            "LINE",
            line2Props,
            undefined,
            undefined,
            true
        );
        manager.addNode(
            blockDef,
            "LINE",
            line3Props,
            undefined,
            undefined,
            true
        );
        manager.addNode(
            blockDef,
            "LINE",
            line4Props,
            undefined,
            undefined,
            true
        );
    }

    private static addCircle(
        manager: DxfFileManager,
        diameter: number,
        offset: number,
        color: number,
        pileLayer: string,
        blockDef: DXFNode
    ) {
        const radius = diameter / 2;
        const angleRadians = Math.asin(offset / radius);
        const angleDegrees = angleRadians * (180 / Math.PI);

        const arcProps1 = new DxfProperties(ARC_PROPERTIES);
        arcProps1.set(40, radius);
        arcProps1.set(8, pileLayer);
        arcProps1.set(50, 45 + angleDegrees);
        arcProps1.set(51, 135 - angleDegrees);
        arcProps1.set(62, color);

        const arcProps2 = new DxfProperties(ARC_PROPERTIES);
        arcProps2.set(40, radius);
        arcProps2.set(8, pileLayer);
        arcProps2.set(50, 135 + angleDegrees);
        arcProps2.set(51, 225 - angleDegrees);
        arcProps2.set(62, color);

        const arcProps3 = new DxfProperties(ARC_PROPERTIES);
        arcProps3.set(40, radius);
        arcProps3.set(8, pileLayer);
        arcProps3.set(50, 225 + angleDegrees);
        arcProps3.set(51, 315 - angleDegrees);
        arcProps3.set(62, color);

        const arcProps4 = new DxfProperties(ARC_PROPERTIES);
        arcProps4.set(40, radius);
        arcProps4.set(8, pileLayer);
        arcProps4.set(50, 315 + angleDegrees);
        arcProps4.set(51, 45 - angleDegrees);
        arcProps4.set(62, color);

        manager.addNode(blockDef, "ARC", arcProps1, undefined, undefined, true);
        manager.addNode(blockDef, "ARC", arcProps2, undefined, undefined, true);
        manager.addNode(blockDef, "ARC", arcProps3, undefined, undefined, true);
        manager.addNode(blockDef, "ARC", arcProps4, undefined, undefined, true);
    }

    public static addRoundHatch(
        manager: DxfFileManager,
        radius: number,
        center: Vector2,
        color: number,
        layer: string,
        parentBlockName?: string
    ) {
        const parent = this.getParent(manager, parentBlockName);
        const ownerHandle: string | undefined = parentBlockName
            ? (manager
                  .getBlockRecord(parentBlockName)
                  ?.properties.get(5) as string)
            : undefined;

        const hatchProps = new DxfProperties();
        hatchProps.set(5, DxfHandle.handle());
        if(ownerHandle){
            hatchProps.set(330, ownerHandle)
        }else{
            hatchProps.set(330, 0);
        }
        
        hatchProps.set(100, "AcDbEntity", false);
        hatchProps.set(67, 0, false); //Space
        hatchProps.set(8, layer);
        hatchProps.set(62, color); //Color
        hatchProps.set(100, "AcDbHatch", false);
        hatchProps.set(10, 0, false);
        hatchProps.set(20, 0, false);
        hatchProps.set(30, 0, false);
        hatchProps.set(210, 0, false);
        hatchProps.set(220, 0, false);
        hatchProps.set(230, 1, false);
        hatchProps.set(2, "SOLID", false);
        hatchProps.set(70, 1, false); //Solid fill flag (solid fill = 1; pattern fill = 0);
        hatchProps.set(71, 0, false); //Associativity flag (associative = 1; non-associative = 0);
        hatchProps.set(91, 1, false); //Number of boundary paths (loops)

        //Boundary Path Data
        hatchProps.set(92, 1, false); //Boundary path type flag (bit coded):    0 = Default; 1 = External; 2 = Polyline; 4 = Derived; 8 = Textbox; 16 = Outermost
        hatchProps.set(93, 1, false); //Number of edges in this boundary path (only if boundary is not a polyline)
        hatchProps.set(72, 2, false); //Edge type (only if boundary is not a polyline): 1 = Line; 2 = Circular arc; 3 = Elliptic arc; 4 = Spline
        hatchProps.set(10, center.x, false);
        hatchProps.set(20, center.y, false);
        hatchProps.set(40, radius, false); //Radius
        hatchProps.set(50, 0, false); //Radius
        hatchProps.set(51, 360, false); //Radius
        hatchProps.set(73, 1, false); //Is counterclockwise flag

        hatchProps.set(97, 0, false); //Number of source boundary objects
        hatchProps.set(75, 1, false); //Hatch style: 0 = Hatch “odd parity” area (Normal style) 1 = Hatch outermost area only (Outer style) 2 = Hatch through entire area (Ignore style)
        hatchProps.set(76, 1, false); //Hatch pattern type: 0 = User-defined; 1 = Predefined; 2 = Custom
        hatchProps.set(98, 1, false); //Number of seed points
        hatchProps.set(10, 0, false); //Seed point (in OCS)
        hatchProps.set(20, 0, false);

        //GradientSettings
        hatchProps.set(450, 0, false); //Indicates solid hatch or gradient

        hatchProps.set(451, 0, false); //reserved
        hatchProps.set(460, 0, false); //Rotation angle in radians for gradients (default = 0, 0)
        hatchProps.set(461, 0, false); //Gradient definition
        hatchProps.set(452, 0, false); //Records how colors were defined and is used only by dialog code: 0 = Two-color gradient, 1 = Single-color gradient
        hatchProps.set(462, 0, false); //Color tint value used by dialog code (default = 0, 0; range is 0.0 to 1.0).
        hatchProps.set(453, 0, false); //Number of colors:0 = Solid hatch, 2 = Gradient
        hatchProps.set(470, "LINEAR", false);

        return manager.addNode(parent, "HATCH", hatchProps);
    }

    public static addRectangleHatch(
        manager: DxfFileManager,
        vertices: Vector2[],
        color: number,
        layer: string,
        parentBlockName?: string
    ) {
        if (vertices.length < 3) {
            return;
        }

        const parent = this.getParent(manager, parentBlockName);
        const ownerHandle: string | undefined = parentBlockName
        ? (manager
              .getBlockRecord(parentBlockName)
              ?.properties.get(5) as string)
        : undefined;

        const hatchProps = new DxfProperties();
        hatchProps.set(5, DxfHandle.handle());
        if(ownerHandle){
            hatchProps.set(330,ownerHandle)
        }else{
           hatchProps.set(330, 0); 
        }
        
        hatchProps.set(100, "AcDbEntity", false);
        hatchProps.set(67, 0, false); //Space

        hatchProps.set(8, layer);
        hatchProps.set(62, color); //Color
        hatchProps.set(100, "AcDbHatch", false);
        hatchProps.set(10, 0, false);
        hatchProps.set(20, 0, false);
        hatchProps.set(30, 0, false);
        hatchProps.set(210, 0, false);
        hatchProps.set(220, 0, false);
        hatchProps.set(230, 1, false);
        hatchProps.set(2, "SOLID", false);
        hatchProps.set(70, 1, false); //Solid fill flag (solid fill = 1; pattern fill = 0);
        hatchProps.set(71, 0, false); //Associativity flag (associative = 1; non-associative = 0);
        hatchProps.set(91, 1, false); //Number of boundary paths (loops)

        //Boundary Path Data
        hatchProps.set(92, 1, false); //Boundary path type flag (bit coded):    0 = Default; 1 = External; 2 = Polyline; 4 = Derived; 8 = Textbox; 16 = Outermost
        hatchProps.set(93, vertices.length, false); //Number of edges in this boundary path (only if boundary is not a polyline)

        for (let i = 0; i < vertices.length; i++) {
            const currentPoint = vertices[i];
            const nextPoint = vertices[(i + 1) % vertices.length]; // Wrap around to the first point at the end

            hatchProps.set(72, 1, false); // Edge type
            hatchProps.set(10, currentPoint.x, false);
            hatchProps.set(20, currentPoint.y, false);
            hatchProps.set(11, nextPoint.x, false);
            hatchProps.set(21, nextPoint.y, false);
        }

        hatchProps.set(97, 0, false); //Number of source boundary objects
        //hatchProps.set(330, 0, false);

        hatchProps.set(75, 1, false); //Hatch style: 0 = Hatch “odd parity” area (Normal style) 1 = Hatch outermost area only (Outer style) 2 = Hatch through entire area (Ignore style)
        hatchProps.set(76, 1, false); //Hatch pattern type: 0 = User-defined; 1 = Predefined; 2 = Custom
        hatchProps.set(98, 1, false); //Number of seed points
        hatchProps.set(10, 0, false); //Seed point (in OCS)
        hatchProps.set(20, 0, false);

        //GradientSettings
        hatchProps.set(450, 0, false); //Indicates solid hatch or gradient

        hatchProps.set(451, 0, false); //reserved
        hatchProps.set(460, 0, false); //Rotation angle in radians for gradients (default = 0, 0)
        hatchProps.set(461, 0, false); //Gradient definition
        hatchProps.set(452, 0, false); //Records how colors were defined and is used only by dialog code: 0 = Two-color gradient, 1 = Single-color gradient
        hatchProps.set(462, 0, false); //Color tint value used by dialog code (default = 0, 0; range is 0.0 to 1.0).
        hatchProps.set(453, 0, false); //Number of colors:0 = Solid hatch, 2 = Gradient
        hatchProps.set(470, "LINEAR", false);

        return manager.addNode(parent, "HATCH", hatchProps);
    }

    public static addSpline(
        manager: DxfFileManager,
        splineData: splineData,
        parentBlockName?: string
    ) {
        const degree = splineData.knots.length > 3 ? 3 : 2;
        const vectorSet = new Set<Vector2>();
        for (const knot of splineData.knots) {    
            vectorSet.add(knot);
        }


        const parent = this.getParent(manager, parentBlockName);
        const ownerHandle: string | undefined = parentBlockName
        ? (manager
              .getBlockRecord(parentBlockName)
              ?.properties.get(5) as string)
        : undefined;

        const splineProps = new DxfProperties(SPLINE_PROPERTIES);
        splineProps.set(5, DxfHandle.handle());
        if (splineData.layer) {
            splineProps.set(8, splineData.layer);
        }
        splineProps.set(70, 8);
        splineProps.set(71, degree);
        splineProps.set(72, vectorSet.size*2);
        splineProps.set(73, vectorSet.size);

        for(let i=0; i<vectorSet.size; i++){
            splineProps.set(40, 0, false);
        }

        for(let i=0; i<vectorSet.size; i++){
            splineProps.set(40, 1, false);
        }

        for(const knot of vectorSet){
            
            splineProps.set(10, knot.x, false);
            splineProps.set(20, knot.y, false);
            splineProps.set(30, 0, false);
        }

        if(ownerHandle){
            splineProps.set(330, ownerHandle);
        }
        return manager.addNode(parent, "SPLINE", splineProps);

    }
}

