<script lang="ts">
    import { getContext } from "svelte";
    import { TextField } from "../libui/fields";
    import type { Dashboard } from "./Dashboard";
    import { ButtonComponent, Button, ButtonType } from "../libui/button";

    let dashboard = getContext<Dashboard>("dashboard");
</script>

<div class="search-container">
    <TextField
        labelText="Search by Project name or ID"
        bind:value={$dashboard.search}
        leadingIcon="Search"
    />
    <ButtonComponent
        desc={new Button(
            "New Project",
            ButtonType.Unelevated,
            () => dashboard.reserveNew(),
            false,
            "AddPlus"
        )}
    />
    <!--<ButtonComponent
        desc={new Button("Catalog & Cost Data", ButtonType.Outlined, () =>
            navigate("catalog")
        )}
    />-->
</div>

<style lang="scss">
    .search-container {
        display: flex;
        align-items: center;
        gap: 16px;
    }
    .search-container :global(.mdc-text-field) {
        width: 400px;
    }
</style>
