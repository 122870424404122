import type { Texture, Material } from '../3rdParty/three';
import { ShaderMaterial, UniformsUtils } from '../3rdParty/three'
import type { ShaderFlags } from '../shaders/ShaderFlags';
import type { ShaderBase } from '../shaders/ShaderBase';
import type { DictionaryStr } from '../utils/Utils';
import { LegacyLogger } from 'engine-utils-ts';
import type { MaterialDescr } from "./MaterialDescr";

export class KrMaterial extends ShaderMaterial {

	readonly materialId: number;
	readonly shaderFlags: ShaderFlags;
	
	forceEdgesDrawing: boolean = false;

	// this props are used by three.js material compiler
	map: Texture | null = null;
	envMap: Texture | null = null;
	normalMap: Texture | null = null;

	edgeMaterial: KrMaterial | null = null;

	constructor(shader: ShaderBase, id:number, shaderFlags: ShaderFlags) {
		super({
			name: shader.name,
			uniforms: UniformsUtils.clone(shader.uniforms),
			vertexShader: shader.vertexShader,
			fragmentShader: shader.fragmentShader
		});
		this.type = shader.name; // threejs webgl program compiler uses type as name in shader code
		this.extensions.derivatives = true;
		if (shader.defines) {
			for (const defName in shader.defines) {
				this.defines[defName] = shader.defines[defName];
			}
		}
		if (shader.defaultAttrs) {
			this.defaultAttributeValues = shader.defaultAttrs;
		}
		if (shader.params) {
			for (const p in shader.params) {
				if ((this as any)[p] !== undefined) {
					(this as any)[p] = (shader.params as any)[p] as any;
				}
			}
		}

		this.materialId = id;
		this.shaderFlags = shaderFlags;
		if (shader.flags) {
			this.shaderFlags |= shader.flags;
		}
	}

	static newFromDescription(descr: MaterialDescr, id: number): KrMaterial {
		const m = new KrMaterial(descr.material, id, 0);
		const options = descr.options;
		if (!options) {
			return m;
		}
		if (options.defines) {
			for (const key in options.defines) {
				m.defines[key] = options.defines[key];
			}
		}
		if (options.flags) {
			(m.shaderFlags as ShaderFlags) |= options.flags;
		}
		if (options.params) {
			for (const p in options.params) {
				const typedKey = p as keyof Material;
				if (m[typedKey] === undefined) {
					console.error('unknown material property', m, typedKey);
				} else {
					(m[typedKey] as any) = options.params[typedKey];
				}
			}
		}
		if (options.uniforms) {
			for (const uniName in options.uniforms) {
				const value = options.uniforms[uniName];
				m.uniforms[uniName] = {value};
			}
		}
		return m;
	}

	static newPostMat(shader: ShaderBase, params?: Partial<KrMaterial>, defines?:DictionaryStr<string | number | boolean>): KrMaterial {
		const m = new KrMaterial(shader, 0, 0);
		m.depthWrite = false;
		m.depthTest = false;
		if (params) {
			for (const key in params) {
				(m as any)[key] = (params as any)[key];
			}
		}
		if (defines) {
			for (const defineName in defines) {
				if (m.defines[defineName] == undefined) {
					LegacyLogger.warn(`unknown define ${defineName} for material ${this.name}`)
				}
				m.defines[defineName] = defines[defineName];
			}
		}
		if (shader.textureGenerator) {
			const [name, tex] = shader.textureGenerator(shader);
			if (shader.uniforms[name] == undefined) {
				LegacyLogger.error(`generated texture uniform is absent (${name})`);
			} else {
				shader.uniforms[name].value = tex;
			}
		}
		return m;
	}
	
}