// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { AssetRefT } from './asset-ref.js';
import { AssetRef } from './asset-ref.js';
import type { InlineImageT } from './inline-image.js';
import { InlineImage } from './inline-image.js';


export class BimImage implements flatbuffers.IUnpackableObject<BimImageT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):BimImage {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsBimImage(bb:flatbuffers.ByteBuffer, obj?:BimImage):BimImage {
  return (obj || new BimImage()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsBimImage(bb:flatbuffers.ByteBuffer, obj?:BimImage):BimImage {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new BimImage()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

inline(obj?:InlineImage):InlineImage|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new InlineImage()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

assetRef(obj?:AssetRef):AssetRef|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new AssetRef()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startBimImage(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addInline(builder:flatbuffers.Builder, inlineOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, inlineOffset, 0);
}

static addAssetRef(builder:flatbuffers.Builder, assetRefOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, assetRefOffset, 0);
}

static endBimImage(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): BimImageT {
  return new BimImageT(
    (this.inline() !== null ? this.inline()!.unpack() : null),
    (this.assetRef() !== null ? this.assetRef()!.unpack() : null)
  );
}


unpackTo(_o: BimImageT): void {
  _o.inline = (this.inline() !== null ? this.inline()!.unpack() : null);
  _o.assetRef = (this.assetRef() !== null ? this.assetRef()!.unpack() : null);
}
}

export class BimImageT implements flatbuffers.IGeneratedObject {
constructor(
  public inline: InlineImageT|null = null,
  public assetRef: AssetRefT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const inline = (this.inline !== null ? this.inline!.pack(builder) : 0);
  const assetRef = (this.assetRef !== null ? this.assetRef!.pack(builder) : 0);

  BimImage.startBimImage(builder);
  BimImage.addInline(builder, inline);
  BimImage.addAssetRef(builder, assetRef);

  return BimImage.endBimImage(builder);
}
}
