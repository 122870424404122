import type { KreoEngine } from "engine-ts";
import { StringUtils } from "engine-utils-ts";
import type { Bim, IdBimScene } from "bim-ts";
import type { InvertersGroup } from 'layout-service';
import { List, ListGroup, ListInput, ListItem, TitledList } from '../../../libui/list';
import { IconButton } from '../../../libui/button';
import { ChipsInput } from '../../../libui/chips';
import type { ContextMenuSettingsStore } from '../../../libui/context-menu';

export class ILRInverterGroups {
    constructor(private invertersByBlock: InvertersGroup[]) {}

    getUiList(contextMenuSettings: ContextMenuSettingsStore, bim: Bim, engine: KreoEngine) {
        const groups = new ListGroup([]);
        for (const block of this.invertersByBlock) {
            const items: ListItem[] = [];
            const sortedByDcRatio = block.inverters.slice()
                .sort((a, b) => a.dcRatio - b.dcRatio || a.model.localeCompare(b.model));
            for (const inverter of sortedByDcRatio) {
                const onClick = () => {
                    const children: IdBimScene[] = [];
                    bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(inverter.id, (id) => {
                        children.push(id);
                        return true;
                    });
                    bim.instances.setSelected([inverter.id]);
                    engine.focusCamera(children);
                    contextMenuSettings.disableAutoClose();
                };
                const inst = bim.instances.peekById(inverter.id)!;
                const item = new ListItem(
                    StringUtils.capitalizeFirstLatterInWord(`${inst.type_identifier} ${inverter.id}`),
                    undefined,
                    undefined,
                    new IconButton("SelectItem", onClick)
                );
                item.onClick = onClick;
                
                item.chips = new ChipsInput([`${inverter.dcRatio}`]);
                items.push(item);
            }
            const list = new List(items);
            const group = new TitledList(block.label, list);
            groups.lists.push(group);
        }
        return new ListInput(groups);
    }
}
