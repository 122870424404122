import { Builder, ByteBuffer } from "flatbuffers";
import { PropsGroupsDeserializer, PropsGroupsSerializer } from "src/persistence/PropsGroupsSerializer";
import type { ScopedLogger } from "engine-utils-ts";
import { PropsDeserializer } from "../persistence/PropsSerializer";
import type { PropertyGroup } from "../properties/PropertyGroup";
import { PropertiesGroup } from "../schema/properties-group";
import { NumberProperty, StringProperty } from "../properties/PrimitiveProps";
import { AreaTypeEnum, MetricsGroup, ProjectMetricsType } from "./ProjectMetrics";

interface PrepareMetricsResponse extends PropertyGroup {
    metricsPerArea: {
        id: StringProperty,
        type: StringProperty,
        metrics: PropertyGroup | null;
        areaIndex: NumberProperty | null;
    }[]
}

export function serializeProjectMetricsResponse(props: MetricsGroup[], logger: ScopedLogger) { 
    const response = convertMetricsGroupToResponse(props);
    const builder = new Builder();
    const propsSerializer = new PropsGroupsSerializer({
        logger,
        builder,
        strictGroupsTypes: false,
    });
    const endOffset = propsSerializer.serializePropertyGroup(response);

    builder.finish(endOffset);
    return builder.asUint8Array();
}

export function deserializeProjectMetricsResponse(body: Uint8Array, logger: ScopedLogger) { 
    const propsDeserializer = new PropsDeserializer({
        logger: logger,
        customTypesVersions: new Map(),
    });
    const deserializer = new PropsGroupsDeserializer({
        logger: logger,
        groupsTypesVersions: new Map(),
        expectGroupsAraysLegacyBug: false,
        propsDeserializer,
    });
    const propGroup = PropertiesGroup.getRootAsPropertiesGroup(new ByteBuffer(body));
    const unpacked = deserializer.deserializePropertyGroup(propGroup);
    if(!unpacked){
        throw Error("Deserializing failed")
    }
    logger.debug("Deserialized metrics response", unpacked);
    const metricsGroup = fromMetricsGroup(unpacked as PrepareMetricsResponse);

    return metricsGroup;
}

function convertMetricsGroupToResponse(props: MetricsGroup[]): PrepareMetricsResponse {
    function convertMetrics(metrics: Partial<ProjectMetricsType>): PropertyGroup {
        const propsGroup: PropertyGroup = {};
        for (const key in metrics) {
            const value = metrics[key];
            if(value === undefined){
                continue;
            }
            propsGroup[key] = value;
        }
        return propsGroup;
    }

    return {
        metricsPerArea: props.map(p => ({
            id: StringProperty.new({value: p.id}),
            type: StringProperty.new({value: p.type}),
            metrics: p.metrics ? convertMetrics(p.metrics) : null,
            areaIndex: p.areaIndex != undefined ? NumberProperty.new({value: p.areaIndex}) : null
        }))
    }
}

function fromMetricsGroup(response: PrepareMetricsResponse): MetricsGroup[] {
    const metrics: MetricsGroup[] = [];
    for (const metricGroup of response.metricsPerArea) {
        metrics.push(new MetricsGroup({
            id: metricGroup.id.value,
            type: metricGroup.type.value as AreaTypeEnum,
            metrics: metricGroup.metrics,
            areaIndex: metricGroup.areaIndex?.value
        }));
    }

    return metrics;
}