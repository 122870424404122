<script lang="ts" generics="T">
    import { createEventDispatcher } from "svelte";

    export let option: T;
    export let active: boolean;

    const dispatch = createEventDispatcher();
    let buttonElem: HTMLButtonElement;

    function onClick() {
        dispatch("change", {
            option: option,
        });
    }
</script>

<button
    class="mdc-typography--body1 unstyled-button"
    class:active
    bind:this={buttonElem}
    on:click={onClick}
>
    <slot></slot>
</button>

<style lang="scss">
    .unstyled-button {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding: 2px;
        border-radius: 1px;
        &.active {
            background-color: white;
        }
    }
</style>
