<script lang="ts">
    export let name: string = "";
    export let count: number | undefined = undefined;
    export let tag: string = "";

    $: isEmpty = count === 0;
</script>

<div class="export-group-item" class:text-main-light={isEmpty}>
    {#if count !== undefined}
        <span class="export-item-count">
            × {count}
        </span>
    {/if}
    <span class="export-item-name">
        {#if isEmpty}
            No
        {/if}
        {name}</span
    >
    {#if !!count && !!tag}
        <span class="export-item-tag">{tag}</span>
    {/if}
</div>

<style lang="scss">
    .export-group-item {
        display: flex;
        gap: 8px;
        margin-top: 8px;
    }
    .export-item-count {
        width: 60px;
        flex-shrink: 0;
    }
    .export-item-name {
        text-overflow: ellipsis;
        overflow: hidden;
        text-wrap: nowrap;
    }
    .export-item-tag {
        margin-left: auto;
        margin-right: 30px;
    }
</style>
