import { DefaultMapWeak } from "engine-utils-ts";
import type { PropertyBase } from "./Props";

export function getPropertyReferenceId(obj: PropertyBase): number {
    return __props_per_obj_weak_ids.getOrCreate(obj);
}
let _objsIdsCounter = 999;
let __props_per_obj_weak_ids = new DefaultMapWeak<PropertyBase, number>((_obj) => _objsIdsCounter += 1);
if ((globalThis as any)['__props_per_obj_weak_ids']) {
    console.error('__props_per_obj_weak_ids double initialization, bundling is wrong');
    __props_per_obj_weak_ids = (globalThis as any)['__props_per_obj_weak_ids'];
} else {
    (globalThis as any)['__props_per_obj_weak_ids'] = __props_per_obj_weak_ids;
}
