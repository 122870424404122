import type { IdBimScene} from 'bim-ts';
import { BimPatch, LocalTransformsCalculator, newFlagsPatch, SceneInstanceFlags } from 'bim-ts';
import type { LazyVersioned, UndoStack
, Result} from 'engine-utils-ts';
import {
    DefaultMap, Failure, LazyDerived,  ObservableObject, Success
} from 'engine-utils-ts';
import type { Matrix4} from 'math-ts';
import { Vector3 } from 'math-ts';
import type { MenuPath} from 'ui-bindings';
import { PUI_ConfigBasedBuilderParams, PUI_ConfigPropertyTransformer } from 'ui-bindings';
import type { EngineScene } from '../scene/EngineScene';
import type { SceneInt } from '../scene/SceneRaycaster';
import { MouseButton } from './InputController';
import type { EditInteractionResult, InteractiveEditOperator } from './InteractiveEditOperator';
import type { MouseEventData } from './MouseGesturesBase';
import { GesturesButtons } from './MouseGesturesBase';

interface CloneArrayState {
    sourceObjectsIds: IdBimScene[];
    clonedObjects: DefaultMap<IdBimScene, IdBimScene[]>; // source to clones ids
}
class CloneArraySettings {
    x = {
        count: 3,
        offset: 1,
    }
    y = {
        count: 1,
        offset: 1,
    }
    relativeToBbox: boolean = true;
}

export class InteractiveObjectsCloneArray implements InteractiveEditOperator<CloneArrayState, CloneArraySettings> {

    menuPath: MenuPath = ['Add', 'Clone selected as array'];
    readonly priority: number = 5;
    readonly divider: boolean = true;
    canStart: LazyVersioned<boolean>;
    readonly config: ObservableObject<CloneArraySettings>;

    constructor(
        readonly engineScene: EngineScene,
        undoStack: UndoStack,
    ) {
        this.canStart = LazyDerived.new1(
            '',
            [],
            [this.engineScene.bim.instances.selectHighlight.getVersionedFlagged(SceneInstanceFlags.isSelected)],
            ([selectedIds]) => {
                return selectedIds.length === 1;
            }
        );
        // return this.bim.instances.selectHighlight.getVersionedFlagged(SceneInstanceFlags.isSelected).poll().length > 0;
        this.config = new ObservableObject({
            identifier: this.menuPath.join(),
            initialState: new CloneArraySettings(),
            undoStack,
            throttling: {onlyFields: []}
        });

    }

    configBuilderSettings(): PUI_ConfigBasedBuilderParams {
        return PUI_ConfigBasedBuilderParams.new([
            [
                "count",
                PUI_ConfigPropertyTransformer.numberProp({
                    minMax: [0, 100],
                    step: 1,
                }),
            ],
            [
                "offset",
                PUI_ConfigPropertyTransformer.numberProp({
                    minMax: [-1000, 1000],
                    unit: 'm'
                }),
            ],
        ]);
    }

    start(): Result<CloneArrayState> {
        const selected = this.engineScene.bim.instances.getSelected();
        if (selected.length === 0) {
            return new Failure({msg: 'nothing selected'});
        }
        const settings = this.config.poll();
        const initialState: CloneArrayState = {
            sourceObjectsIds: selected,
            clonedObjects: new DefaultMap<IdBimScene, IdBimScene[]>(() => []),
        };
        const bimPatch = reconcileObjectsClonesWithBim(initialState, settings, this.engineScene);
        bimPatch.applyTo(this.engineScene.bim);
        return new Success(initialState);
    }
    handleConfigPatch(patch: Readonly<Partial<CloneArraySettings>>, state: Readonly<CloneArrayState>): EditInteractionResult<CloneArrayState> {
        const bimPatch = reconcileObjectsClonesWithBim(state, this.config.poll(), this.engineScene);
        bimPatch.applyTo(this.engineScene.bim);
        return {
            done: false,
            state: state
        }
    }
    cancel(state: CloneArrayState): void {
        const allAllIdsCreated: IdBimScene[] = [];
        for (const ids of state.clonedObjects.values()) {
            allAllIdsCreated.push(...ids);
        }
        this.engineScene.bim.instances.delete(allAllIdsCreated);
    }


    handleClick(sceneInt: SceneInt, me: MouseEventData, previousResult: CloneArrayState)
        : EditInteractionResult<CloneArrayState>
    {
        if (me.buttons == GesturesButtons.newShared(MouseButton.Right)) {
            return {done: true, state: previousResult};
        }
        return {done: false, state: previousResult};
    }
}

function reconcileObjectsClonesWithBim(
        stateInOut: CloneArrayState,
        settings: Readonly<CloneArraySettings>,
        engineScene: EngineScene,
    ): BimPatch {

    const instances = engineScene.bim.instances;

    const sourceIds = stateInOut.sourceObjectsIds;
    instances.spatialHierarchy.sortByDepth(sourceIds);

    const bimPatch = new BimPatch();

    const localTransformsCalculator = new LocalTransformsCalculator(instances);

    for (const sourceId of sourceIds) {
        const sourceState = instances.peekById(sourceId);

        if (!sourceState) {
            continue;
        }

        const xOffset = new Vector3(settings.x.offset, 0, 0);
        const yOffset = new Vector3(0, settings.y.offset, 0);

        if (settings.relativeToBbox) {
            const bbox = engineScene.calcBoundsByIds([sourceId]);
            if (!bbox.isEmpty()) {
                const bboxSize = bbox.getSize();

                for (let i = 0; i < 3; ++i) {
                    {
                        const comp = xOffset.getComponent(i);
                        if (Math.abs(comp) > 0) {
                            xOffset.setComponent(i, bboxSize.getComponent(i) * Math.sign(comp) + comp);
                        }
                    }
                    {
                        const comp = yOffset.getComponent(i);
                        if (Math.abs(comp) > 0) {
                            yOffset.setComponent(i, bboxSize.getComponent(i) * Math.sign(comp) + comp);
                        }
                    }
                }
            }
        }

        const desiredPositions: Matrix4[][] = [[sourceState.worldMatrix.clone()]]; // first is source

        for (let i = 1; i < Math.abs(settings.x.count); ++i) {
            const offset = xOffset.clone().multiplyScalar(i * Math.sign(settings.x.count));
            const desiredMatrix = sourceState.worldMatrix.clone().addToPosition(offset);
            desiredPositions[0].push(desiredMatrix);
        }

        for (let j = 1; j < Math.abs(settings.y.count); ++j) {
            const offset = yOffset.clone().multiplyScalar(j * Math.sign(settings.y.count));
            desiredPositions[j] = desiredPositions[0].map(m => m.clone().addToPosition(offset));
        }

        const positionsFlat = desiredPositions.flatMap(p => p);
        const desiredClonesCount = positionsFlat.length - 1;

        const idsAlreadyCreated = stateInOut.clonedObjects.getOrCreate(sourceId);

        if (idsAlreadyCreated.length > desiredClonesCount) {
            const toRemove = idsAlreadyCreated.splice(desiredClonesCount);
            bimPatch.instances.toDelete.push(...toRemove);
        } else if (idsAlreadyCreated.length < desiredClonesCount) {
            for (let i = idsAlreadyCreated.length; i < desiredClonesCount; ++i) {
                const newInstId = instances.clone([sourceId])[0];
                if (newInstId) {
                    idsAlreadyCreated.push(newInstId);
                }
            }
        }

        for (let i = 1; i < positionsFlat.length; ++i) {

            const desiredMatrix = positionsFlat[i];
            const elementId = idsAlreadyCreated[i - 1];

            const lt = localTransformsCalculator.calcPositionPatch(elementId, desiredMatrix, sourceState.spatialParentId);

            bimPatch.instances.toPatch.push([
                elementId,
                {
                    localTransform: lt,
                    flags: newFlagsPatch(SceneInstanceFlags.isHighlighted, true)
                }
            ]);
        }
    }
    return bimPatch

}

