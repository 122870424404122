import { CompressibleNumbersArray, LazyDerivedAsync, Yield, type Result, Success, IterUtils } from 'engine-utils-ts';
import type { Bim, IdBimScene, SceneInstance, TMY_ColumnDates } from '..';
import { FixedTiltTypeIdent, SceneObjDiff, SubstationProps, TrackerTypeIdent } from '..';
import { EnergyPipelineProperty } from './EnergyPipelineProperty';
import { EnergyPipelinesMerger } from './EnergyPipelinesMerger';
import { EnergyYieldPropsGroup, } from './EnergyYieldPropsGroup';
import { EnergyCalculationsEnabled } from './EnergyCalculationsEnabled';
import { EnergyFailure, TrackerConnectionFailure } from './EnergyResult';
import { isTrackerConnected } from './EnergyUtils';


export const EnergyYieldSiteProps = 'EnergyYieldSiteProps';

const trackersTypeIdents = [TrackerTypeIdent, FixedTiltTypeIdent, 'any-tracker'];

export function registerSiteEnergyPropsGroupGlobal(bim: Bim) {

    const isEnabled = bim.runtimeGlobals.getAsLazyVersionedByIdent<EnergyCalculationsEnabled>(EnergyCalculationsEnabled.name);

    const substationsList = bim.instances.getLazyListOf({
        type_identifier: 'substation',
        relevantUpdateFlags: SceneObjDiff.NewProps,
    });

    const solarArrayList = bim.instances.getLazyListOfTypes({
        type_identifiers: trackersTypeIdents,
        relevantUpdateFlags: SceneObjDiff.SpatialParentRef | SceneObjDiff.NewProps,
    });

    const notConnectedTrackerIds = LazyDerivedAsync.new1(
        'not-connected-trackers',
        [],
        [solarArrayList],
        function*([trackers]) {
            const ids: IdBimScene[] = [];
            for (const chunk of IterUtils.splitIterIntoChunks(trackers, 10e3)) {
                for (const [id, _si] of chunk) {
                    if(!isTrackerConnected(id, bim)) {
                        ids.push(id);
                    }
                }
                yield Yield.Asap;
            }
            return ids;
        }
    )

    const TmySitePowerPropsGenerator = LazyDerivedAsync.new3<
        EnergyYieldPropsGroup,
        EnergyCalculationsEnabled,
        [IdBimScene, SceneInstance][],
        IdBimScene[]
    >(
        EnergyYieldSiteProps + '_generator',
        [],
        [isEnabled, substationsList, notConnectedTrackerIds],
        function*([isEnabled, substationsList, notConnectedTrackerIds]): Generator<Yield, Result<EnergyYieldPropsGroup>> {

            if (!isEnabled.isEnabled) {
                return EnergyFailure.new("_CalculationsDisabled");
            }

            const pipelineMerger = new EnergyPipelinesMerger();
            const nominalPowerCombined = new Float32Array(365 * 24);
            let circuit_dc_power: number = 0;

            let dates: TMY_ColumnDates | null = null;

            for (const [id, substation] of substationsList) {
                const energyProps = substation.propsAs(SubstationProps).energy;
                if (!energyProps) {
                    continue;
                }

                pipelineMerger.mergeIn(energyProps.pipeline, 1, new Map());

                const nominal_input_power = energyProps.pipeline.nominal_power.toArray('MW');
                for (let i = 0; i < nominalPowerCombined.length; ++i) {
                    nominalPowerCombined[i] += nominal_input_power[i];
                }

                circuit_dc_power += substation.properties.getPropNumberAs('circuit | aggregated_capacity | dc_power', 0, 'W');


                yield Yield.Asap;
            }

            const {stages, shaded_modules_area, shading_factors} = pipelineMerger.finish();

            // if (stages.length > 0) {
            //     const gridLimit = siteConfig.propsAs(SiteEnergySettingsConfig).grid_power_limit.as('kW');
            //     const substOutputPower = stages.at(-1)!.energy.toPowerChart('kW').slice();
    
            //     for (let i = 0; i < substOutputPower.length; ++i) {
            //         const inputPower = substOutputPower[i];
            //         if (inputPower > gridLimit) {
            //             substOutputPower[i] = inputPower;
            //         }
            //     }
    
            //     stages.push(new EnergyPipelineStage('grid_limit', new EnergyStageChartW(
            //         CompressibleNumbersArray.newFromValues('kW', substOutputPower),
            //     )));
            // }


            const nominalPowerOutput = CompressibleNumbersArray.newFromValues(
                'MW', nominalPowerCombined
            );

            const pipeline = new EnergyPipelineProperty(
                stages,
                null,
                nominalPowerOutput,
                null,
                circuit_dc_power,
                shaded_modules_area,
                shading_factors,
            );

            const warnings: EnergyFailure[] = [];
            if (notConnectedTrackerIds.length) {
                warnings.push(new TrackerConnectionFailure({errorMsgIdent: 'NotConnectedTrackers', affectedInstanceIds: notConnectedTrackerIds}));
            }

            return new Success(new EnergyYieldPropsGroup({
                pipeline,
            }), warnings);
        }
    )

    bim.runtimeGlobals.registerAsyncByIdent(
        EnergyYieldSiteProps,
        TmySitePowerPropsGenerator
    );
}