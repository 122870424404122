
import type { ShaderFlags } from '../shaders/ShaderFlags';
import type { KrMaterial } from '../materials/KrMaterial';
import type { MaterialsFactory } from '../materials/MaterialsFactory';
import type { EngineMaterialIdFlags} from './EngineMaterialId';
import { GetMaterialIDF, GetMaterialFlagsFromIDF, getMaterialIdFromIDF } from './EngineMaterialId';

export class MaterialsPool {

	readonly materialsFactory : MaterialsFactory;
	readonly sharedMaterials : Map<EngineMaterialIdFlags, KrMaterial> = new Map();

	constructor(materialsFactory: MaterialsFactory) {
		this.materialsFactory = materialsFactory;
	}

	get(materialId: EngineMaterialIdFlags): KrMaterial | undefined {
		return this.getShared(getMaterialIdFromIDF(materialId), GetMaterialFlagsFromIDF(materialId));
	}

	getShared(matIndex: number, shaderFlags: ShaderFlags): KrMaterial | undefined {
		const fullKey = GetMaterialIDF(matIndex, shaderFlags);
		let mat = this.sharedMaterials.get(fullKey);
		if (!mat) {
			mat = this.materialsFactory.createMaterial(matIndex, shaderFlags);
			if (mat) {
				this.sharedMaterials.set(fullKey, mat);
			}
		}
		return mat;
	}

	clear(): void {
		for (const mat of this.sharedMaterials.values()) {
			mat.dispose();
		}
		this.sharedMaterials.clear();

	}
}