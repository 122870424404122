import type {
	AnyBimGeometry, IdBimGeo, SceneInstance} from 'bim-ts';
import { BasicAnalyticalRepresentation, BimGeometryType,
	GraphGeometry, PolylineGeometry, SceneObjDiff,
} from 'bim-ts';
import { DefaultMap, RGBA } from 'engine-utils-ts';
import { entityTypeFromId } from 'verdata-ts';

import type { UniformsFlat } from '../composer/DynamicUniforms';
import type { IdEngineGeo } from '../geometries/AllEngineGeometries';
import { EngineGeoAnalytPolyline } from '../geometries/EngineGeoAnalytPolyline';
import { EngineMaterialId } from '../pools/EngineMaterialId';
import { InObjFullId, SelfIdZero } from '../scene/EngineSceneIds';
import type { ESOHandle } from '../scene/ESOsCollection';
import type { LodGroupLocalIdent} from '../scene/LodGroups';
import { newLodGroupLocalIdent } from '../scene/LodGroups';
import type {
	EngineSubmeshDescription, RenderJobUpdater} from '../scene/Submeshes2';
import { LodMask
} from '../scene/Submeshes2';
import { ShaderFlags } from '../shaders/ShaderFlags';
import { ESO_SingleGeo } from './ESO_SingleGeo';
import { ESO_SingleGeoIndividual } from './ESO_SingleGeoIndividual';
import type { ESOsHandlerInputs } from './ESOsHandlerBase';
import type { ESSO_Any, SubmeshesCreationOutput, SubmeshesCreationResources} from './ESSO';
import {
	ESSO
} from './ESSO';
import { SharedSelectHighlightJobUpdater } from './ESSO_HighlightUpdaters';
import { Quaternion, Vec3X, Vector3 } from 'math-ts';
import type { EngineFullGraphicsSettings } from '../GraphicsSettingsFull';
import { Matrix4 } from 'math-ts';

export class ESO_BasycAnalytical extends ESO_SingleGeo {

	constructor(
		sceneInstanceRef: Readonly<SceneInstance>,
	) {
		super(sceneInstanceRef);
	}

	repr(): BasicAnalyticalRepresentation {
		return this.sceneInstanceRef.representationAnalytical as BasicAnalyticalRepresentation;
	}

	geometryId(): IdBimGeo | undefined {
		return this.repr().geometryId;
	}
}


export class ESO_BasicAnalyticalHandler extends ESO_SingleGeoIndividual<ESO_BasycAnalytical>  {

	constructor(
		identifier: string,
		args: ESOsHandlerInputs,
	) {
		super(identifier, args);
		this._relevantBimUpdatesFlags |= SceneObjDiff.LegacyProps;
	}

	tryCreateESO(instance: SceneInstance): ESO_BasycAnalytical | undefined {
		if (instance.representationAnalytical instanceof BasicAnalyticalRepresentation) {
			return new ESO_BasycAnalytical(instance);
        }
		return undefined;
	}
	esosTypesToHandle(): Iterable<new (...args: any[]) => ESO_BasycAnalytical> {
		return [ESO_BasycAnalytical];
	}

	createSelfESSOs(handle: ESOHandle, obj: ESO_BasycAnalytical, result: ESSO_Any[]): void {
		const bimGeoId = obj.geometryId();
		if (!bimGeoId) {
			this.logger.error('create self esso: unable to get geometry id for', obj.sceneInstanceRef);
			return;
		}
		const bimGeo = this.bimGeos.peekById(bimGeoId);
		if (!bimGeo) {
			this.logger.error('create self esso: unable to get geometry for', obj.sceneInstanceRef);
			return;
		}
		const esso = new ESSO_BasicAnalyticalSubOBj(obj, InObjFullId.new(handle, SelfIdZero), [bimGeoId, bimGeo], null);
		result.push(esso);
	}
}


export class ESSO_BasicAnalyticalSubOBj extends ESSO<[IdBimGeo, AnyBimGeometry]> {

	get colorTint() { return super.colorTint || RGBA.new(0.3, 0.3, 0.3, 1) }

	isRepresentationTheSame(repr: [IdBimGeo, AnyBimGeometry]): boolean {
		for (let i = 0; i < this.repr.length; ++i) {
			if (Object.is(this.repr[i], repr[i])) {
				return false;
			}
		}
		return true;
	}

	reprToEngineGeometries(resoures: SubmeshesCreationResources): DefaultMap<IdEngineGeo, (Matrix4 | null)[]> {
		const result: DefaultMap<IdEngineGeo, (Matrix4 | null)[]> = new DefaultMap(() => []);

		const [bimGeoId, bimGeo] = this.repr;

		if (bimGeo instanceof GraphGeometry) {
			for (const [point1, point2, interpolator] of bimGeo.iterSegments()) {
				const newGeo = new EngineGeoAnalytPolyline(PolylineGeometry.newWithAutoIds(
					[point1, point2],
				));
				const segmentId = resoures.geometries.analytPolylines.shared!.get(newGeo)!;
				result.getOrCreate(segmentId).push(null);
			}
		} else {

			let engineGeoId: IdEngineGeo | undefined;
			let transform: Matrix4 | null = null;
			if (bimGeo instanceof PolylineGeometry) {
				if (bimGeo.points3d.length === 3 * 2) {
					const bimGeoPoints = Vector3.arrayFromFlatArray(bimGeo.points3d);
					engineGeoId = resoures.geometries.analytPolylines.unitXLineId.poll();
					const scale = new Vector3(bimGeo.length(), 1, 1);
					const rotation = Quaternion.fromUnitVectors(
						Vec3X,
						bimGeoPoints[1].clone().sub(bimGeoPoints[0]).normalize()
					);
					const position = bimGeoPoints[0];
					transform = new Matrix4().compose(position, rotation, scale);
				} else {
					engineGeoId = resoures.geometries.analytPolylines.mapBimIdToEngineId(bimGeoId);
				}
			} else {
				engineGeoId = resoures.geometries.mapBimIdToEngineId(bimGeoId);
			}
			if (engineGeoId === undefined) {
				resoures.logger.error('undefined mapped geo id', this);
			} else {
				result.getOrCreate(engineGeoId).push(transform);
			}
		}
		return result;
	}

	createSubmeshes(resoures: SubmeshesCreationResources, output: SubmeshesCreationOutput): void {
		let lodMask: LodMask;
		let lodGroupLocalIdent: LodGroupLocalIdent;

		const geoId = this.repr[0];
		const objType = this.rootRef.sceneInstanceRef.type_identifier;
		if (entityTypeFromId<BimGeometryType>(geoId) === BimGeometryType.Polyline
			&& objType.includes('wire')
			&& objType.includes('lv')
		) {
			lodMask = LodMask.Lod0;
			lodGroupLocalIdent = newLodGroupLocalIdent(0, 1);
		} else {
			lodMask = LodMask.All;
			lodGroupLocalIdent = newLodGroupLocalIdent(0, 0);
		}

		for (const [geoId, transforms] of this.reprToEngineGeometries(resoures)) {
			output.submeshes.push({
				id: this.id,
				lodMask: lodMask,
				lodGroupLocalIdent,
				subObjectRef: this,
				descr: {
					geoId: geoId,
					materialId: EngineMaterialId.BasicAnalytical,
					localTransforms: transforms,
					mainRenderJobUpdater: new BasicAnalyticalRenderJobUpdater(),
					overlayRenderJobUpdater: SharedSelectHighlightJobUpdater
				}
			});
		}
	}
}

export class BasicAnalyticalRenderJobUpdater implements RenderJobUpdater {

    constructor(readonly lineGeoWidth: number = 5, readonly useDashesForLineGeo: boolean = false) {
    }

    updaterRenderJob(
        submeshDescription: Readonly<EngineSubmeshDescription>,
        renderSettings: Readonly<EngineFullGraphicsSettings>,
        output: { flags: ShaderFlags; materialId: EngineMaterialId; uniforms: UniformsFlat; }
    ): void {
		if (submeshDescription.subObjectRef.isHidden) {
			return;
		}
		output.materialId = submeshDescription.localDescr.materialId;
		output.uniforms.push('lineWidth', this.lineGeoWidth);

		if(this.useDashesForLineGeo)
		{
			output.flags = ShaderFlags.USE_DASHES_FOR_LINE_GEO;
		}
    }
}
