<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.99999 4.00002C8.99999 3.44774 9.4477 3.00002 9.99998 3.00002L20 3C20.5523 3 21 3.44771 21 4V6C21 6.55228 20.5523 7 20 7H16C15.4477 7 15 7.44772 15 8C15 8.55229 14.5523 9 14 9H11.5C11.2238 9 11 8.77614 11 8.5C11 8.22386 10.7761 8 10.5 8H9.99999C9.4477 8 8.99999 7.55228 8.99999 7V4.00002ZM17 10C17 9.44772 17.4477 9 18 9H20C20.5523 9 21 9.44772 21 10V20C21 20.5522 20.5523 21 20 21L18 21C17.4477 21 17 20.5522 17 20V10ZM15 12C15 11.4477 14.5523 11 14 11H12C11.4477 11 11 11.4477 11 12V20C11 20.5523 11.4477 21 12 21L14 21C14.5523 21 15 20.5523 15 20V12ZM6.99999 4.00003C6.99999 3.44775 6.55227 3.00003 5.99998 3.00003L3.99998 3.00004C3.4477 3.00004 2.99998 3.44775 2.99998 4.00004V20C2.99998 20.5523 3.4477 21 3.99999 21L7.99999 21C8.55227 21 8.99999 20.5523 8.99999 20V11C8.99999 10.4477 8.55227 10 7.99999 10C7.4477 10 6.99999 9.55229 6.99999 9V4.00003Z"
        fill="currentColor"
    />
</svg>
