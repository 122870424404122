import type { Disposable, VersionedValue } from 'engine-utils-ts';

import type { KrCamera } from '../controls/MovementControls';
import { KrFrustum } from './KrFrustum';

export const ViewCullBit 	= 0b0000_0001; // make this non const for multi-view
export const ShadowCullBit 	= 0b1000_0000;

const _frustum: KrFrustum = new KrFrustum();

export class FrustumExt implements VersionedValue, Disposable {
	camera: KrCamera;
	readonly frustum: KrFrustum = new KrFrustum();
	readonly frustumBit: number;

	_version: number = 1;
	isDisposed: boolean = false;;

	version(): number {
		return this._version;
	}

	constructor(frustumBit: number, camera: KrCamera) {
		this.camera = camera;
		this.frustumBit = frustumBit;
	}

	dispose(): void {
		this.isDisposed = true;
	}

	update(camera: KrCamera) {
		this.camera = camera;
		_frustum.setFromCamera(this.camera);
		if (!_frustum.roughlyEquals(this.frustum)) {
			this.frustum.copy(_frustum);
			this._version += 1;
		}
	}


}
