import { Matrix4, Vector2, Vector4 } from 'math-ts';

import { DataTexture } from '../3rdParty/three';
import type { ShaderBase } from './ShaderBase';

export const SaoOpaqueOutputShader: ShaderBase = {

    name: 'sao_copy',

    defines: {
		PERSPECTIVE_CAMERA: 1
	},

    uniforms: {

        envMap: { value: null },
        
        isOrtho: { value: true },
        cameraNear: { value: 1 },
        cameraFar: { value: 10 },
        cameraWorldMatrix: { value: new Matrix4() },
        cameraProjectionMatrixInverse: { value: new Matrix4() },
        viewProjectionMatrixInverse: { value: new Matrix4() },

        tileDrawingRectWS: { value: new Vector4() },
        tileDrawingColor: { value: new Vector4() },

        tilesTexture: {value: DataTexture },

        gridLevelOpacityInterval: {value: new Vector4()},
        gridLevelOpacityIntervalMinor: {value: new Vector4()},
        gridWidth: { value: new Vector2(0.1, 0.1) },

        tDepth: { value: null },
        tSao: { value: null },
        tColor: { value: null },

    },

    vertexShader:
    `
        varying vec2 vUv;

        varying vec3 cameraRayOrigin;
        varying vec3 cameraRayDirection;

        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }
    `
    ,

    fragmentShader: `

        uniform sampler2D tColor;
        uniform sampler2D tDepth;
        uniform sampler2D tSao;

        uniform bool isOrtho;
        uniform float cameraNear;
		uniform float cameraFar;
		uniform mat4 cameraWorldMatrix;
        uniform mat4 cameraProjectionMatrixInverse;

        uniform mat4 viewProjectionMatrixInverse;

        uniform vec4 gridLevelOpacityInterval;
        uniform vec4 gridLevelOpacityIntervalMinor;
        uniform vec2 gridWidth;

        varying vec2 vUv;

		#include <packing>
        #include <common>
        #include <normals_packing>

        #ifdef TILE_DRAWING_TOOL
        uniform vec4 tileDrawingRectWS;
        uniform vec4 tileDrawingColor;
        #endif

		#if defined(GROUND_MARK_TILED) || defined(OPAQUE_OBJECTS_MARKUP_TILED)
        uniform sampler2D tilesTexture;
		varying vec2 tilesTextureSize;
        #endif

        #include <sample_grid_fn>


        vec3 decodeWSLocation(vec2 texcoord, sampler2D tDepth, out float depthClipSpace) {
			float z = texture2D(tDepth, texcoord).r * 2.0 - 1.0;
			depthClipSpace = z;
			vec2 xy = texcoord * 2.0 - 1.0;
			vec4 clipSpaceLocation = vec4(xy, z, 1.0);
			vec4 homogenousLocation = viewProjectionMatrixInverse * clipSpaceLocation;
			homogenousLocation.xyz /= homogenousLocation.w;
			// homogenousLocation.xz *= mat2(0.7073883,  0.7068252, -0.7068252, 0.7073883 );
			return homogenousLocation.xyz;
		}
       
        bool intersectPlane(vec3 rayOrigin, vec3 rayDirection, vec4 plane, out vec3 point){
            float denominator = dot(plane.xyz, rayDirection);
            float distance = dot(plane.xyz, rayOrigin) + plane.w;
            if (denominator == 0.0) {
                return false;
            }
            if (distance == 0.0) {
                return false;
            }
            float t = - distance / denominator;
            if (t >= 0.0) {
                point = rayOrigin + rayDirection * t;
                return true;
            }
            return false;
        }

        // returns 1.0 if inside the disk
        float rectangle(vec2 r, vec2 bottomLeft, vec2 topRight) {
            float ret;
            float d = 0.005;
            ret = smoothstep(bottomLeft.x-d, bottomLeft.x+d, r.x);
            ret *= smoothstep(bottomLeft.y-d, bottomLeft.y+d, r.y);
            ret *= 1.0 - smoothstep(topRight.y-d, topRight.y+d, r.y);
            ret *= 1.0 - smoothstep(topRight.x-d, topRight.x+d, r.x);
            return ret;
        }

        void main() {
            vec4 colorOutput = texture2D( tColor, vUv );

            float depthClipSpace;
            vec3 worldSpacePosition = decodeWSLocation(vUv, tDepth, depthClipSpace);
            // vec3 normal = normalize(cross(dFdx(worldSpacePosition), dFdy(worldSpacePosition)));

            vec3 cameraRayOrigin, cameraRayDirection;
            if (isOrtho) {
                vec4 ndcRay = vec4( vUv * 2.0 - 1.0, (cameraNear + cameraFar) / (cameraNear - cameraFar), 1.0 );
                cameraRayOrigin = ( cameraWorldMatrix * cameraProjectionMatrixInverse * ndcRay ).xyz;
                cameraRayDirection = normalize(-1.0 * cameraWorldMatrix[2].xyz);

            } else {
                cameraRayOrigin = cameraWorldMatrix[3].xyz;
                vec4 ndcRay = vec4( vUv * 2.0 - 1.0, 1.0, 1.0 );
                vec4 interm = cameraProjectionMatrixInverse * ndcRay;
                vec4 dir = cameraWorldMatrix * vec4(interm.xyz, 1.0);
                cameraRayDirection = normalize( dir.xyz - cameraRayOrigin.xyz);
            }

            if (depthClipSpace >= 1.0 && gridLevelOpacityInterval.y > 0.0 && cameraRayDirection.z < 0.0){
                
                // ray direction in normalized device coordinate
                vec4 groundPlane = vec4(0.0, 0.0, 1.0, -gridLevelOpacityInterval.x);
                vec3 gridWorldSpacePosition = worldSpacePosition;
                if (intersectPlane(cameraRayOrigin, cameraRayDirection, groundPlane, gridWorldSpacePosition)) {

                    float gridPower1 = sampleGrid(gridWorldSpacePosition.xy, gridLevelOpacityInterval.zw, gridWidth.x) * gridLevelOpacityInterval.y;
                    float gridPower2 = sampleGrid(gridWorldSpacePosition.xy, gridLevelOpacityIntervalMinor.zw, gridWidth.y) * gridLevelOpacityIntervalMinor.y;
                    float gridPower = max(gridPower1, gridPower2);

                    colorOutput.xyz = mix(colorOutput.xyz, vec3(0.0, 0.0, 0.0), gridPower);
                }

            } else {
                float sao = texture2D( tSao, vUv ).r;
                
                colorOutput.xyz = mix(colorOutput.xyz, vec3(0.), sao);
            }

            gl_FragColor = colorOutput;
        }

    `,

};