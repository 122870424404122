import { IterUtils } from './IterUtils';
import { LegacyLogger } from './LegacyLogger';
import { ObjectUtils } from './ObjectUtils';
import type { ObjectHash } from './types';

type Counter = number;

const MaxEntriesPerBucketHash = 10;

export class LruCache<K, V> {
    readonly identifier: string;
    readonly maxSize: number;

    _hashFn: (obj: K) => ObjectHash;
    _eqFn: (lhs: K, rhs: K) => boolean;
    _factoryFn: (k: K) => V;

    readonly _bucketsPerHash = new Map<ObjectHash, ObjectEntry<K, V>[]>();
    readonly _entriesBirth = new Map<ObjectEntry<K, V>, Counter>();
    _timeCounter: Counter = 0;
    _disposeFn: ((obj: V) => void) | undefined;

    constructor(params: {
        identifier: string;
        maxSize: number;
        hashFn: (obj: K) => ObjectHash;
        factoryFn: (k: K) => V
        eqFunction?: (lhs: K, rhs: K) => boolean;
        disposeFn?: (obj: V) => void;
    }) {
        this.identifier = params.identifier;
        this.maxSize = params.maxSize;
        this._hashFn = params.hashFn;
        this._eqFn = params.eqFunction ?? ObjectUtils.areObjectsEqual;
        this._factoryFn = params.factoryFn;
        this._disposeFn = params.disposeFn;
    }

    filter(fn: (value: Readonly<V>) => boolean) {
        for (const entry of this._entriesBirth.keys()) {
            if (!fn(entry.obj)) {
                const bucket = this._bucketsPerHash.get(entry.hash);
                if (bucket) {
                    IterUtils.deleteFromArray(bucket, entry);
                    if (bucket.length === 0) {
                        this._bucketsPerHash.delete(entry.hash);
                    }
                }
                this._entriesBirth.delete(entry);
            }
        }
    }

    _insertNew(entry: ObjectEntry<K, V>) {
        if (this._entriesBirth.size === this.maxSize) {
            // remove oldest entry if max size
            let minBirth = Infinity;
            let oldestEntry: ObjectEntry<K, V> | undefined = undefined;
            for (const [entry, birth] of this._entriesBirth) {
                if (birth < minBirth) {
                    minBirth = birth;
                    oldestEntry = entry;
                }
            }
            this._entriesBirth.delete(oldestEntry!);
            const bucket = this._bucketsPerHash.get(oldestEntry!.hash)!;
            if (bucket.length === 1) {
                this._bucketsPerHash.delete(oldestEntry!.hash);
            } else {
                const indexInBucket = bucket.indexOf(oldestEntry!);
                bucket.splice(indexInBucket, 1);
            }
            if (this._disposeFn) {
                this._disposeFn(oldestEntry!.obj);
            }
        }

        let bucket = this._bucketsPerHash.get(entry.hash);
        if (bucket === undefined) {
            this._bucketsPerHash.set(entry.hash, [entry]);
        } else {
            bucket.push(entry);
            if (bucket.length > MaxEntriesPerBucketHash) {
                LegacyLogger.deferredWarn(`${this.identifier} cache: too many entries per hash, trimmin bucket`, entry);

                const countToRemove = (bucket.length - MaxEntriesPerBucketHash - MaxEntriesPerBucketHash * 0.5) | 0;
                const removed = bucket.splice(0, countToRemove);
                for (const entry of removed) {
                    this._entriesBirth.delete(entry);
                }
            }
        }
        this._entriesBirth.set(entry, (this._timeCounter += 1));
    }

    _getEqualInsertedAlready(hash: ObjectHash, keyToFind: K): ObjectEntry<K, V> | undefined {
        let bucket = this._bucketsPerHash.get(hash);
        if (bucket) {
            for (const inBucket of bucket) {
                if (this._eqFn(keyToFind, inBucket.key)) {
                    // found equal, now update birth to make it youngest
                    this._entriesBirth.set(inBucket, (this._timeCounter += 1));

                    return inBucket;
                }
            }
        }
        return undefined;
    }

    get(key: K): V {
        const hash = this._hashFn(key);
        const inserted = this._getEqualInsertedAlready(hash, key);
        if (inserted != undefined) {
            return inserted.obj;
        }
        const obj = this._factoryFn(key);
        this._insertNew(new ObjectEntry(hash, key, obj));
        return obj;
    }

    clear() {
        const toDispose = Array.from(this._entriesBirth.keys());
        this._entriesBirth.clear();
        this._bucketsPerHash.clear();

        if (this._disposeFn) {
            for (const e of toDispose) {
                this._disposeFn(e.obj);
            }
        }
    }
}

class ObjectEntry<K, V> {
    readonly hash: ObjectHash;
    readonly key: K;
    readonly obj: V;

    constructor(hash: ObjectHash, key: K, obj: V) {
        this.hash = hash;
        this.key = key;
        this.obj = obj;
        Object.freeze(this);
    }
}

