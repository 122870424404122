<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
>
    <path
        d="M21 11.1714C21 12.0623 19.9228 12.5085 19.2929 11.8785C19.1053 11.691 19 11.4366 19 11.1714V2.99983C19 2.62108 19.2139 2.27484 19.5527 2.10544C20.2176 1.77291 21 2.25641 21 2.99983V11.1714Z"
        fill="currentColor"
    />
    <path
        d="M5.53 12.6144C5.82239 12.4317 6.00001 12.1112 6.00001 11.7664L6 10.4997C5.99999 9.75629 5.21768 9.2728 4.55278 9.60525C4.214 9.77464 4 10.1209 4 10.4997V11.7664C4 12.5518 4.86396 13.0307 5.53 12.6144Z"
        fill="currentColor"
    />
    <path
        d="M9.34146 15.0949C9.12444 14.905 8.99996 14.6307 8.99996 14.3423V7.99988C8.99996 7.62111 9.21397 7.27485 9.55275 7.10546C10.2177 6.77301 11 7.2565 11 7.99988V14.7419C11 15.0242 10.8546 15.2866 10.6152 15.4362C10.3091 15.6275 9.91383 15.5957 9.64218 15.358L9.34146 15.0949Z"
        fill="currentColor"
    />
    <path
        d="M14 11.5164C14 12.3019 14.8639 12.7807 15.53 12.3644C15.8223 12.1817 16 11.8612 16 11.5164V5.49988C16 4.7565 15.2177 4.27301 14.5528 4.60546C14.214 4.77485 14 5.12111 14 5.49988V11.5164Z"
        fill="currentColor"
    />
    <path
        d="M19.8321 14.4178C18.8443 13.43 17.3054 13.2547 16.1208 13.9951L10.7294 17.3648C10.3555 17.5985 9.87267 17.5597 9.54086 17.2694L7.77615 15.7252C6.78071 14.8542 5.33229 14.738 4.21064 15.439L1.47 17.1519C1.17762 17.3346 1 17.6551 1 17.9999V20.9998C1 21.5521 1.44772 21.9998 2 21.9998H22C22.5523 21.9998 23 21.5521 23 20.9998V17.9999C23 17.7347 22.8946 17.4803 22.7071 17.2928L19.8321 14.4178Z"
        fill="currentColor"
    />
</svg>
