<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.5 2.1001C19.8255 2.1001 20.9 3.17461 20.9 4.5001V7.40402L21.8431 7.2154C22.2764 7.12875 22.6978 7.40972 22.7845 7.84297C22.8711 8.27622 22.5901 8.69768 22.1569 8.78433L20.9 9.0357V19.5001C20.9 20.8256 19.8255 21.9001 18.5 21.9001C17.1745 21.9001 16.1 20.8256 16.1 19.5001V9.9957L14.9 10.2357V19.5001C14.9 20.8256 13.8255 21.9001 12.5 21.9001C11.1745 21.9001 10.1 20.8256 10.1 19.5001V11.1957L8.90001 11.4357V19.5001C8.90001 20.8256 7.82549 21.9001 6.50001 21.9001C5.17452 21.9001 4.10001 20.8256 4.10001 19.5001V12.3957L2.15689 12.7843C1.72364 12.871 1.30218 12.59 1.21553 12.1568C1.12888 11.7235 1.40986 11.302 1.84311 11.2154L4.10001 10.764V6.5001C4.10001 5.17461 5.17452 4.1001 6.50001 4.1001C7.82549 4.1001 8.90001 5.17461 8.90001 6.5001V9.80402L10.1 9.56402V5.5001C10.1 4.17461 11.1745 3.1001 12.5 3.1001C13.8255 3.1001 14.9 4.17461 14.9 5.5001V8.60402L16.1 8.36402V4.5001C16.1 3.17461 17.1745 2.1001 18.5 2.1001ZM18.5 3.7002C18.9418 3.7002 19.3 4.05837 19.3 4.5002V19.5002C19.3 19.942 18.9418 20.3002 18.5 20.3002C18.0582 20.3002 17.7 19.942 17.7 19.5002V4.5002C17.7 4.05837 18.0582 3.7002 18.5 3.7002ZM13.3 5.5002C13.3 5.05837 12.9418 4.7002 12.5 4.7002C12.0582 4.7002 11.7 5.05837 11.7 5.5002V19.5002C11.7 19.942 12.0582 20.3002 12.5 20.3002C12.9418 20.3002 13.3 19.942 13.3 19.5002V5.5002ZM7.3 6.5002C7.3 6.05837 6.94182 5.7002 6.5 5.7002C6.05817 5.7002 5.7 6.05837 5.7 6.5002V19.5002C5.7 19.942 6.05817 20.3002 6.5 20.3002C6.94182 20.3002 7.3 19.942 7.3 19.5002V6.5002Z"
        fill="currentColor"
    />
</svg>
