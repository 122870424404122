import { Color } from '../3rdParty/three';
import { BritishStandard381, BritishStandard4800, RalClassic, AdditionalColors } from './ColorsCollections';

export function validatedColor(color: Color | '' | null) : Color | null {
	if (color && color.r >= 0 && color.r <= 1 && color.g >= 0 && color.g <= 1 && color.b >= 0 && color.b <= 1) {
		return color;
	}
	return null;
}

export function extractHexColor (str: string): Color | null {
	const hexFound = /#[0-9A-Fa-f]{6}(?=(?:[^a-z0-9]|$))/.exec(str);
	if (hexFound !== null) {
		return new Color(hexFound[0]);
	}
	return null;
}

const rgbRegs: [RegExp, string][] = [
	[/\d{1,3}\-\d{1,3}\-\d{1,3}(?=(?:[^0-9]|$))/,	'-'	],
	[/\d{1,3}\,\d{1,3}\,\d{1,3}(?=(?:[^0-9]|$))/, 	','	],
	[/\d{1,3}\, \d{1,3}\, \d{1,3}(?=(?:[^0-9]|$))/,	', '],
	[/\d{1,3}\ \d{1,3}\ \d{1,3}(?=(?:[^0-9]|$))/,	' '],
];
export function extractRGBColor (str: string): Color | null {
	for (const pair of rgbRegs) {
		const reg = pair[0];
		const splitter = pair[1];
		const found = reg.exec(str);
		if (found === null) {
			continue;
		}
		const components = found[0].split(splitter);
		const color = new Color();
		color.r = +components[0] / 255;
		color.g = +components[1] / 255;
		color.b = +components[2] / 255;
		return color;
	}
	return null;
}

function findColorByCode(code: string, collection: string[][]): string | null {
	for (const arr of collection) {
		if (arr[0] === code) {
			return arr[1];
		}
	}
	return null;
}

function findColorByName(str: string, collection:string[][], coloumn: number): string | null {
	for (const arr of collection) {
		const name = arr[coloumn];
		if (str.includes(name)) {
			return arr[1];
		}
	}
	return null;
}

const br381regex = / bs381c?[\ \-\_]{1}[\d]{3}(?=(?:[^0-9]|$))/;
export function extractBritish381ByCode(str: string): Color | null | '' {
	const found = br381regex.exec(str);
	if (found === null) {
		return null;
	}
	const code = str.slice(-3);
	const col = findColorByCode(code, BritishStandard381);
	return col && new Color(col);
}

const br4800regex = / ([\d]{2})[\ \-\_]?([a-z])[\ \-\_]?([\d]{2})(?=(?:[^0-9]|$))/;
export function extractBritish4800ByCode(str: string): Color | null | '' {
	const found = br4800regex.exec(str);
	if (found === null) {
		return null;
	}
	const code = found[1] + '-' + found[2] + '-' + found[3];
	const col = findColorByCode(code, BritishStandard4800);
	return col && new Color(col);
}

const ralRegex = /ral[\ \-\_]{0,1}([\d]{4})(?=(?:[^0-9]|$))/;
export function extractRALClassicByCode (str: string): Color | null | '' {
	const found = ralRegex.exec(str);
	if (found === null) {
		return null;
	}
	const code = found[1];
	const col = findColorByCode(code, RalClassic);
	return col && new Color(col);
}

export function extractBritishColorByName(str: string): Color | null | '' {
	const col =
		findColorByName(str, BritishStandard381, 2) ||
		findColorByName(str, BritishStandard4800, 2);
	return col && new Color(col);
}

export function extractRALClassicByName (str: string): Color | null | '' {
	const col =
		findColorByName(str, RalClassic, 3) || 	// English
		findColorByName(str, RalClassic, 7) || 	// Russian
		findColorByName(str, RalClassic, 2) || 	// Deutsch
		findColorByName(str, RalClassic, 4) || 	// Français
		findColorByName(str, RalClassic, 5) || 	// Español
		findColorByName(str, RalClassic, 6); 	// Italiano
	return col && new Color(col);
}

export function extractAdditinalColors(str: string): Color | null | '' {
	const col =
		findColorByName(str, AdditionalColors, 2) ||
		findColorByName(str, AdditionalColors, 3);
	return col && new Color(col);

}
