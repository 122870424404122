<script lang="ts">
    import type { Bim } from "bim-ts";
    import { getContext } from "svelte";
    import { type VerDataSyncer, VerDataIdentifier } from "verdata-ts";
    import { PUI_Lazy } from "ui-bindings";
    import PUI_Root from "../pui/PUI_Root.svelte";
    import { DialogForm } from "../libui/dialog";
    import type { ProjectHub } from "../ProjectHub";
    import {
        createProjectVersionUi,
        createCatalogVersionUi,
    } from "./VersionParams";
    import type { ProjectVersionParams } from './ProjectVersionParams';

    export let cancel: () => void;

    let verDataSyncer = getContext<VerDataSyncer>("verDataSyncer");
    let projectParams = getContext<ProjectVersionParams>("projectParams");
    const bim = getContext<Bim>("bim");

    let isProject = verDataSyncer.identifier === VerDataIdentifier.Project;
    let projectHub = getContext<ProjectHub>("projectHub");

    const paramsPui = isProject
        ? createProjectVersionUi(
              projectParams,
              verDataSyncer,
              projectHub,
              cancel
          )
        : createCatalogVersionUi(bim, verDataSyncer, cancel);
    $: title = isProject ? "Project details" : "Add catalog version";

    const paramsPuiLazy = new PUI_Lazy(paramsPui);
</script>

<DialogForm
    dialogInput={{
        title,
    }}
    close={cancel}
>
    <div slot="body">
        <PUI_Root
            configIdentifier={"props-new-version-params"}
            puiSource={paramsPuiLazy}
        />
    </div>
</DialogForm>

<style lang="scss">
</style>
