// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { ConfigNode, unionToConfigNode } from './config-node.js';
import type { PropertiesGroupT } from './properties-group.js';
import type { PropertiesGroupArrayT } from './properties-group-array.js';
import type { PropertyT } from './property.js';
import type { PropertyArrayT } from './property-array.js';


export class NamedNode implements flatbuffers.IUnpackableObject<NamedNodeT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):NamedNode {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsNamedNode(bb:flatbuffers.ByteBuffer, obj?:NamedNode):NamedNode {
  return (obj || new NamedNode()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsNamedNode(bb:flatbuffers.ByteBuffer, obj?:NamedNode):NamedNode {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new NamedNode()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

name():string|null
name(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
name(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

valueType():ConfigNode {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : ConfigNode.NONE;
}

value<T extends flatbuffers.Table>(obj:any):any|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
}

static startNamedNode(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addName(builder:flatbuffers.Builder, nameOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, nameOffset, 0);
}

static addValueType(builder:flatbuffers.Builder, valueType:ConfigNode) {
  builder.addFieldInt8(1, valueType, ConfigNode.NONE);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, valueOffset, 0);
}

static endNamedNode(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createNamedNode(builder:flatbuffers.Builder, nameOffset:flatbuffers.Offset, valueType:ConfigNode, valueOffset:flatbuffers.Offset):flatbuffers.Offset {
  NamedNode.startNamedNode(builder);
  NamedNode.addName(builder, nameOffset);
  NamedNode.addValueType(builder, valueType);
  NamedNode.addValue(builder, valueOffset);
  return NamedNode.endNamedNode(builder);
}

unpack(): NamedNodeT {
  return new NamedNodeT(
    this.name(),
    this.valueType(),
    (() => {
      const temp = unionToConfigNode(this.valueType(), this.value.bind(this));
      if(temp === null) { return null; }
      return temp.unpack()
  })()
  );
}


unpackTo(_o: NamedNodeT): void {
  _o.name = this.name();
  _o.valueType = this.valueType();
  _o.value = (() => {
      const temp = unionToConfigNode(this.valueType(), this.value.bind(this));
      if(temp === null) { return null; }
      return temp.unpack()
  })();
}
}

export class NamedNodeT implements flatbuffers.IGeneratedObject {
constructor(
  public name: string|Uint8Array|null = null,
  public valueType: ConfigNode = ConfigNode.NONE,
  public value: PropertiesGroupArrayT|PropertiesGroupT|PropertyArrayT|PropertyT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const name = (this.name !== null ? builder.createString(this.name!) : 0);
  const value = builder.createObjectOffset(this.value);

  return NamedNode.createNamedNode(builder,
    name,
    this.valueType,
    value
  );
}
}
