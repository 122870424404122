export const normalTextFontSize = 20;

export const wireWidth = 4;

export const helperLineWidth = 2;

export const blocksYOffset = 100;

export const wireOffset = 50;

export const indexSeparator = '.'
