import { RGBA } from "engine-utils-ts";
import { KrMath } from "math-ts";
import type { Bim } from "../../Bim";
import { NumberProperty, ColorProperty } from "../../properties/PrimitiveProps";
import type { PropertyGroup } from "../../properties/PropertyGroup";
import { StateType } from "../ConfigsArchetypes";
import type { PaletteSlice } from "./EquipmentSlopeAnalysis";

export const CostAnalysisType = "CostAnalysisType";

export const CostAnalysisPaletteMaxRowCount = 30;

export interface CostAnalysisPalette extends PropertyGroup {
    slices: PaletteSlice[];
}

export interface CostAnalysisConfig extends PropertyGroup {
    [type: string]: CostAnalysisPalette;
}

export function generateCostAnalysisConfigProps(args: {
    paletteSize: number,
    rangeExtents: [min: number, max: number],
    types: string[],
}): CostAnalysisConfig {
    const paletteSize = KrMath.clamp(args.paletteSize, 1, CostAnalysisPaletteMaxRowCount);

    const defaultSlopeSlices: PaletteSlice[] = [];
    {
        const [ minCost, maxCost ] = args.rangeExtents;
        const colors = [ RGBA.newRGB(0.1, 0.2, 0.9), RGBA.newRGB(1.0, 1.0, 1.0), RGBA.newRGB(0.9, 0.2, 0.1) ];
        for (let i = 0; i < paletteSize; ++i) {

            const min =  KrMath.lerp(minCost, maxCost, i / paletteSize);
            const max =  KrMath.lerp(minCost, maxCost, (i + 1) / paletteSize);

            const color = RGBA.lerpFromArray(colors, i * (colors.length - 1) / (paletteSize - 1), RGBA.lerpRGBAHex);

            defaultSlopeSlices.push({
                min: NumberProperty.new({ value: min, unit: "" }),
                max: NumberProperty.new({ value: max, unit: "" }),
                color: ColorProperty.new({ value: color }),
            });
        }
    }


    const properties: CostAnalysisConfig = {};
    for (const type of args.types) {
        properties[type] = { slices: defaultSlopeSlices };
    }

    return properties;
}

export function registerCostAnalysisConfig(bim: Bim) {
    bim.configs.archetypes.registerArchetype({
        type_identifier: CostAnalysisType,
        properties: () => generateCostAnalysisConfigProps({
            paletteSize: 0,
            rangeExtents: [0, 100],
            types: ['nope'],
        }),
        stateType: StateType.Singleton,
    });
}
