<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.8 18.7464L14.2 17.1464V19H15.8V18.7464ZM11.2536 14.2L12 14.9464V15.8H7C5.4536 15.8 4.2 14.5464 4.2 13V8H5.05357L5.8 8.74643V13C5.8 13.6627 6.33726 14.2 7 14.2H11.2536ZM8.74613 5.8L8 5.05387V4.2H13C14.5464 4.2 15.8 5.4536 15.8 7V12H14.9461L14.2 11.2539V7C14.2 6.33726 13.6627 5.8 13 5.8H8.74613ZM5.8 2.85387L4.2 1.25387V1H5.8V2.85387ZM1.25357 4.2L2.85357 5.8H1V4.2H1.25357ZM18.7461 15.8H19V14.2H17.1461L18.7461 15.8Z"
        fill="white"
    />
    <path
        d="M1.66669 1.66666L17.9167 17.9167"
        stroke="#E72C03"
        stroke-width="1.6"
    />
</svg>
