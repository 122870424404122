<script lang='ts'>
import type { Checkbox } from "./types";

export let input: Checkbox;

let checked: boolean;

$: checked = input.checked;

function handleInputOnChange() {
    if (input.onChange) {
        input.onChange(checked);
    }
}
</script>

<div class="mdc-touch-target-wrapper">
  <div class="mdc-checkbox mdc-checkbox--touch">
    <input 
      bind:checked={checked} 
      type="checkbox"
      class="mdc-checkbox__native-control"
      on:change={handleInputOnChange}
    />
    <div class="mdc-checkbox__background">
      <svg class="mdc-checkbox__checkmark"
        viewBox="0 0 24 24">
        <path class="mdc-checkbox__checkmark-path"
          fill="none"
          d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
      </svg>
      <div class="mdc-checkbox__mixedmark"></div>
    </div>
    <div class="mdc-checkbox__ripple"></div>
  </div>
</div>

