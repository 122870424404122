<script lang="ts">
import type { List } from "./types";
import ListItemComponent from "./ListItemContent.svelte";
import VirtualList from "svelte-tiny-virtual-list";

export let list: List;
let listPadding = 4

$: listItemHeight = list.options.listItemSize + 1;
$: fullListHeight = list.items.length * listItemHeight + listPadding * 2;

</script>

<div
  class="virtual-list-container"
  style:--item-size="{list.options.listItemSize}px"
  style:--list-padding="{listPadding}px"
>
  <VirtualList
    height={Math.min(list.options.virtualListHeight, fullListHeight)}
    width="auto"
    itemCount={list.items.length}
    itemSize={listItemHeight}
  >
    <div slot="item" let:index let:style {style}>
      {@const item = list.items[index]}
      <ListItemComponent
        {item}
        {index}
        showSeparator={!!list.options.itemsSeparated}
        elementType="div"
      />
    </div>
  </VirtualList>
</div>

<style lang="scss">
  .virtual-list-container :global(.virtual-list-wrapper) {
    padding: var(--list-padding) 0;
  }

  .virtual-list-container
    :global(.virtual-list-wrapper .mdc-deprecated-list-item) {
    height: var(--item-size);
  }
</style>
