import type { UserManagerSettings } from 'oidc-client';

export const LOGIN_REDIRECT_CALLBACK_SUCCESS =
    '/oidc/signin/redirect/callback';
export const LOGIN_SILENT_REDIRECT_CALLBACK_SUCCESS =
    '/oidc/signin/sileng/callback';
export const LOGOUT_REDIRECT_CALLBACK =
    '/oidc/signout/redirect/callback';
export const SIGNUP_REDIRECT_CALLBACK =
    '/register';

export const SELF_BASE_URL = document.location.origin;

export const OIDC_CONFIG: UserManagerSettings = {
    authority: '<placeholder-url>',
    client_id: 'solar-frontend',
    response_type: 'code',
    scope: 'openid',
    redirect_uri: SELF_BASE_URL + LOGIN_REDIRECT_CALLBACK_SUCCESS,
    silent_redirect_uri: SELF_BASE_URL + LOGIN_SILENT_REDIRECT_CALLBACK_SUCCESS,
    post_logout_redirect_uri: SELF_BASE_URL + LOGOUT_REDIRECT_CALLBACK,
    automaticSilentRenew: true,
    loadUserInfo: false,
};
