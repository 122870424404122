import { Base64Binary } from 'engine-utils-ts';

export interface Screenshot {
	Width: number;
	Height: number;
	PngBase64: string;
}

export function createScreenshot(width: number, height: number, pngBinary: ArrayBuffer): Screenshot {
	const pngbase64 = Base64Binary.uint8ToBase64(new Uint8Array(pngBinary));
	return { Width: width, Height: height, PngBase64: pngbase64 };
}