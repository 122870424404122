
import type { ObjectSerializer, ScopedLogger } from 'engine-utils-ts';
import { IterUtils } from 'engine-utils-ts';
import { Builder, ByteBuffer } from 'flatbuffers';

import { getIdsFromIDVs, getVersionsFromIDVs, type IdVersionPacked, newCompactIDV
} from './CompactVersionId';
import type { Id } from './VerDataSyncerImpl';
import { BatchDescription as WireBatchDescription } from './wire/batch-description';
import { BatchPartialReference as WireBatchPartialReference } from './wire/batch-partial-reference';
import { CollectionHistory as WireCollectionHistory } from './wire/collection-history';
import { CollectionVersion as WireCollectionVersion } from './wire/collection-version';
import type { CompressionType } from './wire/compression-type';
import {
    dateFromFlatbufLong, dateToFlatbufLong} from './WireCommon';
import { CompressedIntegersInMemory } from './CompressedIntegersInMemory';


export class BatchDescription {
    readonly guid: string;
    readonly filename: string;

    readonly compressedIds: CompressedIntegersInMemory;
    readonly compressedVersions: CompressedIntegersInMemory;

    private constructor(
        guid: string,
        filename: string,
        compressedIds: CompressedIntegersInMemory,
        compressedVersions: CompressedIntegersInMemory,
    ) {
        this.guid = guid;
        this.filename = filename;
        this.compressedIds = compressedIds;
        this.compressedVersions = compressedVersions;
        Object.freeze(this);
    }

    idvs(): IdVersionPacked[] {
        const {ids, versions} = this.decompressedIdsVersions();
        const res = ids.slice();
        for (let i = 0; i < res.length; ++i) {
            const idv = newCompactIDV(res[i], versions[i]);
            res[i] = idv;
        }
        return res;
    }

    decompressedIdsVersions(): {ids: number[], versions: number[]} {
        return {
            ids: this.compressedIds.decompressed(),
            versions: this.compressedVersions.decompressed(),
        }
    }

    versionPerId(): Map<Id, number> {
        const {ids, versions} = this.decompressedIdsVersions();
        const res = new Map<Id, number>();
        for (let i = 0; i < ids.length; ++i) {
            const id = ids[i];
            const version = versions[i];
            res.set(id, version);
        }
        return res;
    }

    idsCount(): number {
        return this.compressedIds.decompressed().length;
    }

    static newWithPackedIds(
        guid: string,
        filename: string,
        idsVersions: Float64Array | number[],
    ): Readonly<BatchDescription> {
        if (idsVersions instanceof Float64Array) {
        } else {
            idsVersions = new Float64Array(idsVersions);
        }
        const ids = getIdsFromIDVs(idsVersions);
        const versions = getVersionsFromIDVs(idsVersions);
        return BatchDescription.newSeparateIdsVersions(guid, filename, ids, versions);
    }

    static newSeparateIdsVersions(
        guid: string,
        filename: string,
        ids: number[],
        versions: number[]
    ): Readonly<BatchDescription> {
        if (ids.length !== versions.length) {
            throw new Error(`ids length:${ids.length}, versions length: ${versions.length}`);
        }
        const compressedIds = CompressedIntegersInMemory.fromDecompressed(ids);
        const compressedVersions = CompressedIntegersInMemory.fromDecompressed(versions);
        return new BatchDescription(guid, filename, compressedIds, compressedVersions);
    }

    static parseFromFlatbuf(bd: WireBatchDescription): BatchDescription {
        return new BatchDescription(
            bd.guid()!,
            bd.filename()!,
            CompressedIntegersInMemory.fromFlatbuf(bd.ids()!),
            CompressedIntegersInMemory.fromFlatbuf(bd.versions()!),
        )
    }

    static addToFlatbuf(self: BatchDescription, builder: Builder): number {
        // const ids = getIdsFromIDVs(self.idsVersions);
        // const versions = getVersionsFromIDVs(self.idsVersions);
        // const idsSet = new Set(ids);
        // if (idsSet.size != ids.length) {
        //     throw new Error(self.filename + ' ids should be unique');
        // }
        return BatchDescription._createWireBatchDescription(
            builder,
            0,
            builder.createString(self.guid),
            builder.createString(self.filename),
            self.compressedIds.createFlatbuf(builder),
            self.compressedVersions.createFlatbuf(builder),
        );
    }

    static _createWireBatchDescription(
        builder: Builder,
        formatVersion: number,
        guidOffset: number,
        filenameOffset: number,
        idsOffset: number,
        versionsOffset: number,
    ) {
        WireBatchDescription.startBatchDescription(builder);
        WireBatchDescription.addFormatVersion(builder, formatVersion);
        WireBatchDescription.addGuid(builder, guidOffset);
        WireBatchDescription.addFilename(builder, filenameOffset);
        WireBatchDescription.addIds(builder, idsOffset);
        WireBatchDescription.addVersions(builder, versionsOffset);
        return WireBatchDescription.endBatchDescription(builder);
    }
}

export class BatchPartialReference {
    readonly guid: string;
    readonly idsToExclude: CompressedIntegersInMemory;

    constructor(guid: string, idsToExclude: CompressedIntegersInMemory|null) {
        this.guid = guid;
        this.idsToExclude = idsToExclude ?? CompressedIntegersInMemory.empty();
    }

    static parseFromFlatbuf(bp: WireBatchPartialReference): BatchPartialReference {

        const idsToExclude = bp.idsToExclude();
        const compressedIds = idsToExclude && idsToExclude.payloadLength() > 0
            ? CompressedIntegersInMemory.fromFlatbuf(idsToExclude) : null;
        return new BatchPartialReference(
            bp.batchGuid()!,
            compressedIds
        );
    }

    static addToFlatbuf(self: BatchPartialReference, builder: Builder): number {
        const idsOffset = self.idsToExclude.createFlatbuf(builder);

        const guidOffset = builder.createString(self.guid);
        WireBatchPartialReference.startBatchPartialReference(builder);
        WireBatchPartialReference.addBatchGuid(builder, guidOffset);
        WireBatchPartialReference.addIdsToExclude(builder, idsOffset);
        return WireBatchPartialReference.endBatchPartialReference(builder);
    }
}

export class CollectionVersion {

    id: number;
    projectVersion: number;
    date: Date;
    batchesRefs: BatchPartialReference[];

    additionalContext: [CompressionType, Uint8Array] | null;

    constructor(
        id: number,
        projectVersion: number,
        date: Date,
        batchesRefs: BatchPartialReference[],
        additionalContext: [CompressionType, Uint8Array] | null
    ) {
        this.id = id;
        this.projectVersion = projectVersion;
        this.date = date;
        this.batchesRefs = batchesRefs;
        this.additionalContext = additionalContext;
    }

    static getIdsVersionsInside(logger: ScopedLogger, self: CollectionVersion, bds: Map<string, BatchDescription>)
        : { perIdVersion: Map<Id, number>, perBatchIds: Map<string, Id[]> }
    {
        const perIdVersion = new Map<Id, number>();
        const perBatchIds = new Map<string, Id[]>();
        for (const br of self.batchesRefs) {
            const bd = bds.get(br.guid);
            if (!bd) {
                throw new Error(`batch with provided guid is not saved, smth is broken`);
            }
            const toExclude = new Set(br.idsToExclude?.decompressed() ?? []);
            const idsInside: Id[] = [];
            const {ids, versions} = bd.decompressedIdsVersions();
            for (let i = 0; i < ids.length; ++i) {
                const id = ids[i];
                const version = versions[i];
                if (toExclude.has(id)) {
                    continue;
                }
                if (perIdVersion.has(id)) {
                    logger.batchedError(`collection version has elements with the same id twice`, id);
                }
                perIdVersion.set(id, version);
                idsInside.push(id);
            }
            perBatchIds.set(br.guid, idsInside);
        }
        return { perIdVersion, perBatchIds };
    }

    static parseFromFlatbuf(cv: WireCollectionVersion): CollectionVersion {
        const bps: BatchPartialReference[] = [];
        for (let i = 0, il = cv.batchesRefsLength(); i < il; ++i) {
            bps.push(BatchPartialReference.parseFromFlatbuf(
                cv.batchesRefs(i)!
            ));
        }
        const contextPayload = cv.additionalContextPayloadArray();
        const contextCompression = cv.additionalContextPayloadCompression();

        const context = contextPayload ? [contextCompression, contextPayload] as [CompressionType, Uint8Array] : null;
        return new CollectionVersion(
            cv.id(),
            cv.projectVersionId(),
            dateFromFlatbufLong(cv.date()),
            bps,
            context
        );
    }

    static addToFlatbuf(self: CollectionVersion,builder: Builder): number {
        const batchesOffset = WireCollectionVersion.createBatchesRefsVector(
            builder, self.batchesRefs.map(br => BatchPartialReference.addToFlatbuf(br, builder))
        );
        
        const payloadOffset = self.additionalContext ?
            WireCollectionVersion.createAdditionalContextPayloadVector(builder, self.additionalContext[1]) : 0;


        WireCollectionVersion.startCollectionVersion(builder);

        WireCollectionVersion.addFormatVersion(builder, 1);
        WireCollectionVersion.addId(builder, self.id);
        WireCollectionVersion.addProjectVersionId(builder, self.projectVersion);
        WireCollectionVersion.addDate(builder, dateToFlatbufLong(self.date));
        WireCollectionVersion.addBatchesRefs(builder, batchesOffset);
        WireCollectionVersion.addAdditionalContextPayloadCompression(builder,
            self.additionalContext ? self.additionalContext[0] : 0
        );
        WireCollectionVersion.addAdditionalContextPayload(builder, payloadOffset);

        return WireCollectionVersion.endCollectionVersion(builder);
    }
}

export class CollectionHistory {
    readonly collectionIdent: string;
    readonly allBatchesPerGuid: Map<string, BatchDescription>;
    readonly versions: CollectionVersion[];

    constructor(
        collectionIdent: string,
        allBatches: BatchDescription[],
        versions: CollectionVersion[],
    ) {
        if (!collectionIdent) {
            throw new Error('invalid collection identifier');
        } 
        this.collectionIdent = collectionIdent;
        this.allBatchesPerGuid = IterUtils.newMapFromIter(
            allBatches, b => b.guid, b => b
        );
        this.versions = versions;
        // if (this.versions.length == 0) {
        //     this.versions.push(new CollectionVersion(0, new Date(), []));
        // }
    }

    findVersionForProjectId(projId: number): CollectionVersion | null {
        const found = IterUtils.findBackToFront(this.versions, (v) => v.projectVersion == projId);
    
        if (found) {
            return found;
        }
        const versionsLower = this.versions.filter(v => v.projectVersion < projId);
        versionsLower.sort((lhs, rhs) => lhs.projectVersion - rhs.projectVersion);
        return versionsLower[versionsLower.length - 1] || null;
    }

    duplicateAndUpdate(batchesToAdd: BatchDescription[], versionToAdd: CollectionVersion): CollectionHistory {
        const batches = new Map(this.allBatchesPerGuid);
        for (const b of batchesToAdd) {
            console.assert(batches.get(b.guid) == undefined, 'batch guid collision sanity check');
            batches.set(b.guid, b);
        }
        console.assert(this.versions.find(c => c.id === versionToAdd.id) == undefined, 'versions id collision sanity check');
        const versions = this.versions.slice();
        versions.push(versionToAdd);

        return new CollectionHistory(
            this.collectionIdent,
            Array.from(batches.values()),
            versions
        )
    }

    lastVersion(): CollectionVersion | undefined {
        return this.versions[this.versions.length - 1];
    }
}

export class CollectionHistorySerializer implements ObjectSerializer<CollectionHistory> {
    serialize(data: CollectionHistory): Uint8Array {
        const builder = new Builder(data.versions.length * 1000);
        const root = WireCollectionHistory.createCollectionHistory(
            builder,
            0,
            builder.createString(data.collectionIdent),
            WireCollectionHistory.createAllBatchesVector(
                builder,
                IterUtils.mapIter(data.allBatchesPerGuid.values(), bd => BatchDescription.addToFlatbuf(bd, builder)),
            ),
            WireCollectionHistory.createVersionsVector(
                builder,
                IterUtils.mapIter(data.versions, vd => CollectionVersion.addToFlatbuf(vd, builder)),
            ),
        );
        builder.finish(root);
        return builder.asUint8Array().slice();
    }
    deserialize(bin: Uint8Array): CollectionHistory {
        const buffer = new ByteBuffer(bin);
        const ch = WireCollectionHistory.getRootAsCollectionHistory(buffer);
        
        const batchesDescriptions: BatchDescription[] = [];
        for (let i = 0, il = ch.allBatchesLength(); i < il; ++i) {
            const bd = BatchDescription.parseFromFlatbuf(ch.allBatches(i)!);
            batchesDescriptions.push(bd);
        }

        const collectionVersions: CollectionVersion[] = [];
        for (let i = 0, il = ch.versionsLength(); i < il; ++i) {
            const cvd = CollectionVersion.parseFromFlatbuf(ch.versions(i)!);
            collectionVersions.push(cvd);
        }
        
        return new CollectionHistory(
            ch.collectionIdent()!,
            batchesDescriptions,
            collectionVersions
        );
    }
}



