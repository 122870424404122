
export enum KrGeoAttrIndex {
}

const attrs = new Map<string, number>([
	["position", 0],
	["normal", 1],
	["uv", 2],
]);
export function getAttrIndexOf(key: string): KrGeoAttrIndex {
	let locationIndex = attrs.get(key);
	if (locationIndex === undefined) {
		locationIndex = attrs.size
		attrs.set(key, locationIndex)
	}
	return locationIndex as KrGeoAttrIndex;
}
