import { BufferGeometry, ShaderMaterial, DoubleSide, BufferAttribute, Box3, Sphere } from '../3rdParty/three';
import { TextShader } from './TextShader';
import { LegacyLogger } from 'engine-utils-ts';
import type { CharsAtlas, CharUvBounds} from './CharAtlasData';
import { CreateCharsAtlas } from './CharAtlasData';
import { KrMath, Vector2 } from 'math-ts';

function vertsForString(stringLength: number): number {
	return (stringLength | 0) * 4;
}

function indsForString(stringLength: number): number {
	return (stringLength | 0) * 6;
}

const defaultZeroCharBounds: CharUvBounds = {
	minx: 0,
	miny: 0,
	maxx: 0
};

export class TextGeometry extends BufferGeometry {
	text: string;
	size: Vector2 = new Vector2();

	_usageCount: number = 0;

	constructor(text:string, charCoords:CharsAtlas) {
		super();
		this.text = text;
		this.boundingBox = new Box3();
		this.boundingBox.min.set(0, 0, 0);
		this.boundingSphere = new Sphere();
		this.updateText(text, charCoords);
	}

	updateText(text: string, charsAtlas:CharsAtlas) {
		if (text.length > 32) {
			LegacyLogger.error('text is too long');
			text = '';
		}
		this.text = text;
		const requiredVertsLength = vertsForString(text.length);
		const requiredIndsLength = indsForString(text.length);
		if (!this.attributes['position'] || this.attributes['position'].count < requiredVertsLength) {
			// this.dispose();

			const MinAllocChars = 16;
			const vertsAllocLength = Math.max(requiredVertsLength, vertsForString(MinAllocChars));
			const indsAllocLength = Math.max(requiredIndsLength, indsForString(MinAllocChars));
			const verts = new BufferAttribute(new Float32Array(vertsAllocLength * 3), 3);
			verts.dynamic = true;

			const uvs = new BufferAttribute(new Float32Array(vertsAllocLength * 2), 2);
			uvs.dynamic = true;

			const inds = [0, 1, 2,  0, 2, 3];
			for (let i = 4; inds.length < indsAllocLength; i += 4) {
				inds.push(i + 0);
				inds.push(i + 1);
				inds.push(i + 2);

				inds.push(i + 0);
				inds.push(i + 2);
				inds.push(i + 3);
			}

			this.attributes['position'] = verts;
			this.attributes['uv'] = uvs;
			this.setIndex(inds);
		}
		// now adjust positions of chars
		const positionsAttr = this.attributes['position'];
		const positions = positionsAttr.array as Float32Array;
		const uvsAttr = this.attributes['uv'];
		const uvs = uvsAttr.array as Float32Array;

		let currentGeoX = 0;
		const charHalfHeight = charsAtlas.charsHeight * 0.5;
		for (let i = 0; i < text.length; ++i) {
			let charCoords = charsAtlas.charCoords.get(text[i])
			let charGeoWidth: number;
			if (charCoords) {
				charGeoWidth = KrMath.clamp(charCoords.maxx - charCoords.minx, 0, charsAtlas.charsHeight * 3);
			} else {
				charGeoWidth = charsAtlas.charsHeight * 0.7;
				charCoords = defaultZeroCharBounds;
			}
			const i12 = i * 12;
			positions[i12 + 0] = currentGeoX;
			positions[i12 + 1] = -charHalfHeight;

			positions[i12 + 3] = currentGeoX;
			positions[i12 + 4] = charHalfHeight;

			positions[i12 + 6] = currentGeoX + charGeoWidth;
			positions[i12 + 7] = charHalfHeight;

			positions[i12 + 9] = currentGeoX + charGeoWidth;
			positions[i12 + 10] = -charHalfHeight;

			const i8 = i * 8;
			uvs[i8 + 0] = charCoords.minx;
			uvs[i8 + 1] = charCoords.miny;

			uvs[i8 + 2] = charCoords.minx;
			uvs[i8 + 3] = charCoords.miny + charsAtlas.charsHeight;

			uvs[i8 + 4] = charCoords.maxx;
			uvs[i8 + 5] = charCoords.miny + charsAtlas.charsHeight;

			uvs[i8 + 6] = charCoords.maxx;
			uvs[i8 + 7] = charCoords.miny;

			currentGeoX += charGeoWidth;
		}
		const totalWidth = currentGeoX;
		this.size.set(totalWidth, charsAtlas.charsHeight);

		// now that we know full width, move all chars left half a size, so that text is centered horizontally
		const halfWidth = totalWidth * 0.5;
		for (let i = 0; i < text.length; ++i){
			const i12 = i * 12;
			positions[i12 + 0] -= halfWidth;
			positions[i12 + 3] -= halfWidth;
			positions[i12 + 6] -= halfWidth;
			positions[i12 + 9] -= halfWidth;
		}

		positionsAttr.needsUpdate = true;
		uvsAttr.needsUpdate = true;
		this.drawRange.count = requiredIndsLength;

		// now update char positions and uvs
		this.boundingBox!.min.set(-halfWidth, -charHalfHeight, 0.001);
		this.boundingBox!.max.set( halfWidth, charHalfHeight, 0.001);
		this.boundingSphere!.center.set(0, 0, 0);
		this.boundingSphere!.radius = this.size.length() * 0.5;

	}
}


export class TextGeometries {

	readonly charAtlas: CharsAtlas;
	readonly textMaterial: ShaderMaterial;

	// cache geometries for lines, and mark usages
	// if geometry is not used for 1 update, it's a candidate for update
	// if there are no geometries that fit string, and no candidates for update
	//  - allocate new geometry
	readonly used_geos: Map<string, TextGeometry> = new Map();
	readonly unused_geos: Map<string, TextGeometry> = new Map();

	constructor() {
		this.charAtlas = CreateCharsAtlas();
		this.textMaterial = new ShaderMaterial(TextShader);
		this.textMaterial.transparent = true;
		this.textMaterial.side = DoubleSide;
		this.textMaterial.depthTest = false;
		this.textMaterial.depthWrite = false;
		this.textMaterial.uniforms['fontAtlas'].value = this.charAtlas.texture;
		this.textMaterial.name = 'fontMaterial';
	}

	getGeometryUntilReturned(text: string): TextGeometry {
		const geo = this.getGeometryFor1Frame(text);
		geo._usageCount += 1;
		return geo;
	}

	markUnused(geo: TextGeometry) {
		// console.log('unused: ' + geo.text);
		{
			const saved = this.used_geos.get(geo.text);
			if (saved != geo) {
				LegacyLogger.error('text_geo trying to markUnused geometry, that is not in use currently ' + geo.text);
				return;
			}
		}
		geo._usageCount -= 1;
	}

	getGeometryFor1Frame(text: string): TextGeometry {
		// console.log('get_geo: ' + text);
		let geo = this.used_geos.get(text);
		if (!geo) {
			if (this.unused_geos.has(text)) {
				geo = this.unused_geos.get(text)!;
				this.unused_geos.delete(geo.text);
			} else if (this.unused_geos.size > 0) {
				geo = this.unused_geos.values().next().value as TextGeometry;
				this.unused_geos.delete(geo.text);
				geo.updateText(text, this.charAtlas);
			} else {
				geo = new TextGeometry(text, this.charAtlas);
			}
			this.used_geos.set(text, geo);
		}
		return geo;
	}

	startNewFrame() {
		for (const [name, geo] of this.used_geos.entries()) {
			if (geo._usageCount <= 0) {
				if (geo._usageCount < 0) {
					LegacyLogger.error('text geo usage count is below zero: ', name);
				}
				if (this.unused_geos.has(name)) {
					LegacyLogger.error('duplicated text geometry, something is wrong');
					geo.dispose();
				} else { // usage count is 0
					this.used_geos.delete(name);
					this.unused_geos.set(name, geo);
				}
			}
		}
	}

	dispose() {
		for (const g of this.used_geos.values()) {
			g.dispose();
		}
		this.used_geos.clear();
		for (const g of this.unused_geos.values()) {
			g.dispose();
		}
		this.unused_geos.clear();
	}


}

