import { DefaultMapWeak } from "../DefaultMapWeak";
import { ObjectUtils } from "../ObjectUtils";
import type { Result} from '../Result';
import { Failure, Success } from '../Result';
import type { ScopedLogger } from "../ScopedLogger";
import type { JobExecutor } from "./JobExecutor";


export class JobsResultsCache {

    readonly logger: ScopedLogger;
    readonly cacheSizeInBytes: number;

    private readonly _awaitingResults = new Map<string, Promise<any>>();
    private readonly _cache = new Map<string, CacheEntry>();
    private _birthDates: number = 0;
    private _currentMemoryCostInBytes: number = 0;

    constructor(
        identifier: string,
        logger: ScopedLogger,
        cacheSizeInMb: number,
    ) {
        this.logger = logger.newScope(identifier);
        this.cacheSizeInBytes = cacheSizeInMb * 1024 * 1024;
    }

    dispose() {
        this._cache.clear();
        this._currentMemoryCostInBytes = 0;
    }

    addPromiseOfResult(cacheKey: string, promise: Promise<any>) {
        this._awaitingResults.set(cacheKey, promise);
        promise.then(
            (res) => {
                if (this._awaitingResults.get(cacheKey) === promise) {
                    this._awaitingResults.delete(cacheKey);
                    this._addResultToCache(cacheKey, new Success(res));
                }
            },
            (error) => {
                if (this._awaitingResults.get(cacheKey) === promise) {
                    this._awaitingResults.delete(cacheKey);
                    this._addResultToCache(cacheKey, new Failure(error));
                }
            }
        )
    }

    clearCacheFor(cacheKey: string) {
        this._awaitingResults.delete(cacheKey);
        const cached = this._cache.get(cacheKey);
        if (cached !== undefined) {
            this._cache.delete(cacheKey);
            this._currentMemoryCostInBytes -= cached.entryMemorySizeBytes;
        }
    }

    _addResultToCache(cacheKey: string, result: Result<any>) {

        let entryMemSize = ObjectUtils.fastEvaluateObjectSizeInbytes(result);
        entryMemSize += cacheKey.length * 2;

        if (entryMemSize >= this.cacheSizeInBytes * 0.25) {
            this.logger.warn('entry is too large for this cache, ignoring');
            return;
        }

        let entryBithDate = this._birthDates += 1;

        if (this._cache.has(cacheKey)) {
            const entry = this._cache.get(cacheKey)!;
            this.logger.warn('cache collision', cacheKey, entry, result);
            this._currentMemoryCostInBytes -= entry.entryMemorySizeBytes;
        }
        this._cache.set(cacheKey, {
            cacheKeyStr: cacheKey,
            birthDate: entryBithDate,
            cachedResult: ObjectUtils.deepFreeze(result),
            entryMemorySizeBytes: entryMemSize
        });
        this._currentMemoryCostInBytes += entryMemSize;

        if (this._currentMemoryCostInBytes > this.cacheSizeInBytes) {
            const memoryAmountToBeFreed = this._currentMemoryCostInBytes - this.cacheSizeInBytes;

            this.logger.debug(`clearing at least ${memoryAmountToBeFreed} bytes from cache`);
            this.logger.debug(`cache before clearing: entries_count:${this._cache.size}, cost_kb:${this._currentMemoryCostInBytes / 1024}`);

            const allCachedEntries = Array.from(this._cache.values());
            allCachedEntries.sort((e1, e2) => e1.birthDate - e2.birthDate);

            for (const entry of allCachedEntries) {
                this._cache.delete(entry.cacheKeyStr);
                this._currentMemoryCostInBytes -= entry.entryMemorySizeBytes;
                if (this._currentMemoryCostInBytes < this.cacheSizeInBytes * 0.8) {
                    // clear a little more than necessary, to not run clearing on every addition
                    break;
                }
            }

            this.logger.debug(`cache after clearing: entries_count:${this._cache.size}, cost_kb:${this._currentMemoryCostInBytes / 1024}`);

        }
    }

    tryGetFromCacheAsPromise(cacheStr: string): Promise<any> | undefined {
        const cached = this._cache.get(cacheStr);
        if (cached !== undefined) {
            this.logger.debug('existing cache hit', cacheStr);
            if (cached.cachedResult instanceof Success) {
                return Promise.resolve(cached.cachedResult.value);
            } else {
                return Promise.reject(cached.cachedResult.toString());
            }
        }
        const awaiting = this._awaitingResults.get(cacheStr);
        if (awaiting !== undefined) {
            this.logger.debug('promise cache hit', cacheStr);
            return awaiting;
        }
        return undefined;
    }

    static getCacheKeyForArgs<Targs extends object, R>(args: Targs, executorInstance: JobExecutor<Targs, R>): string | null {
        const descr = executorInstance.argsCacheKey(args);
        if (descr === null) {
            return null;
        }
        let fullCacheKey: string = executorInstance.constructor.name;

        for (const key of descr.byRef) {
            const obj = args[key];
            if (typeof obj === 'object') {
                if (obj !== null) {
                    const id = __jc_per_objs_ids_weak.getOrCreate(obj);
                    fullCacheKey += `(${key as string}:${id})`;
                } else {
                    fullCacheKey += `(${key as string}:${null})`;
                }
            } else {
                console.error(`descr.byRef contains invalid reference`, key, obj);
            }
        }
        for (const key of descr.byEq) {
            const obj = args[key];
            const objStr = ObjectUtils.toString(obj);
            fullCacheKey += `${key as string}:${objStr}`;
        }
        if (fullCacheKey.length > 3000) {
            console.error('resulting cache string is too long, probably an error, first 100:', fullCacheKey.slice(0, 100));
            return null;
        }
        return fullCacheKey;
    }

}

let _objsIdsCounter = 999;
let __jc_per_objs_ids_weak = new DefaultMapWeak<Object, number>((_obj) => _objsIdsCounter += 1);

if ((globalThis as any)['__jc_per_objs_ids_weak']) {
    console.error('__jc_per_objs_ids_weak double initialization, bundling is wrong');
    __jc_per_objs_ids_weak = (globalThis as any)['__jc_per_objs_ids_weak'];
} else {
    (globalThis as any)['__jc_per_objs_ids_weak'] = __jc_per_objs_ids_weak;
}

interface CacheEntry {
    readonly cacheKeyStr: string;
    readonly cachedResult: Readonly<Result<any>>;
    readonly entryMemorySizeBytes: number; // should include cache string size
    birthDate: number; // higher is younger
}
