import type { UndoStack } from 'engine-utils-ts';
import { IterUtils } from 'engine-utils-ts';
import type { Matrix3} from 'math-ts';
import { Aabb, Aabb2, PolygonUtils, Vector2, Vector3 } from 'math-ts';

import type { IdInEntityLocal} from "../collections/LocalIds";
import { LocalIdsCounter } from "../collections/LocalIds";
import { BimGeometryType } from './BimGeometries';
import type { BimGeometryBase } from './BimGeometriesBase';
import { BimGeometriesBase } from './BimGeometriesBase';

export const ExtrudedPolygonMaxHeight = 5000;

export class Points2D {

    constructor(
        public points: Vector2[] = [],
        public pointsLocalIds: IdInEntityLocal[] = [],
    ) {
    }

    static newWithAutoIds(
        points: Vector2[],
    ): Points2D {
        const ids: LocalIdsCounter = new LocalIdsCounter();
        return new Points2D(
            points,
            ids.newIdsArray(points.length),
        );
    }

    checkForErrors(errors: string[]) {
        if (this.points.length != this.pointsLocalIds.length) {
            errors.push(`points length is not equal to local ids length ${this.points.length}, ${this.pointsLocalIds.length}`);
        }
        // quick check of ids
        if (!IterUtils.areArrayItemsUnique(this.pointsLocalIds)) {
            errors.push(`local ids are not unique`);
        }
        for (const p of this.points) {
            if (!p.isFinite()) {
                errors.push(`point is not finite`);
            }
        }
    }

	addAabb2To(result: Aabb2) {
		for (const p of this.points) {
			result.expandByPoint(p);
		}
	}
}

export class ExtrudedPolygonGeometry implements BimGeometryBase {

    constructor(
        public outerShell: Points2D = new Points2D(),
        public holes: Points2D[] = [],
        public baseElevation: number = 0,
        public topElevation: number = 1,
    ) {
    }

	calcAabb(): Aabb {
		const aabb2 = Aabb2.empty();
		this.outerShell.addAabb2To(aabb2);
		for (const hole of this.holes) {
			hole.addAabb2To(aabb2);
		}
		const result = Aabb.empty();
		result.setMinFrom(new Vector3(aabb2.min.x, aabb2.min.y, this.baseElevation));
		result.setMaxFrom(new Vector3(aabb2.max.x, aabb2.max.y, this.topElevation));
		return result;
	}

    static newWithAutoIds(
        outerShell: Vector2[] = [],
        holes: Vector2[][] = [],
        baseElevation: number = 0,
        topElevation: number = 1,
    ): ExtrudedPolygonGeometry {
        const ids: LocalIdsCounter = new LocalIdsCounter();
        return new ExtrudedPolygonGeometry(
            new Points2D(outerShell, ids.newIdsArray(outerShell.length)),
            holes.map(h => new Points2D(h, ids.newIdsArray(h.length))),
            baseElevation,
            topElevation,
        );
    }

    checkForErrors(validationErrors: string[]) {
        const height = this.topElevation - this.baseElevation;
        if (!(height >= 0 && height <= ExtrudedPolygonMaxHeight)) {
            validationErrors.push('height out of bounds ' + height);
        }
        this.outerShell.checkForErrors(validationErrors);
        for (const h of this.holes) {
            h.checkForErrors(validationErrors);
        }
    }

    static localIdsMaxiumum(self: ExtrudedPolygonGeometry): number {
        let max = 0;
        for (const id of self.outerShell.pointsLocalIds) {
            max = Math.max(max, id);
        }
        if (self.holes) {
            for (const h of self.holes) {
                for (const id of h.pointsLocalIds) {
                    max = Math.max(max, id);
                }
            }
        }
        return max;
    }

    area(scaleMatrix: Matrix3): number {
        if (this.holes.length) {
            console.warn('area of polygon, holes not implemented', this.holes);
        }
        return PolygonUtils.area(this.outerShell.points, scaleMatrix);
    }

    perimeter(scaleMatrix: Matrix3): number {
        if (this.holes.length) {
            console.warn('area of polygon, holes not implemented', this.holes);
        }
        return PolygonUtils.perimeter(this.outerShell.points, scaleMatrix);
    }
}

const v1 = new Vector3();
const v2 = new Vector3();


function interner(p: ExtrudedPolygonGeometry): string {
    let res = `${p.baseElevation}|${p.topElevation}|${IterUtils.reduceToString(p.outerShell.points, Vector2.asString)}`;
    for (const h of p.holes) {
        res += IterUtils.reduceToString(h.points, Vector2.asString);
    }
    return res;
}

export class ExtrudedPolygonGeometries extends BimGeometriesBase<ExtrudedPolygonGeometry> {

    constructor(
        undoStack?: UndoStack,
    ) {
        super({
            identifier: "extruded-polygon-geometries",
            idsType: BimGeometryType.ExtrudedPolygon,
            undoStack,
            T_Constructor: ExtrudedPolygonGeometry,
            interner: interner,
            localIdsMaximumExtractor: ExtrudedPolygonGeometry.localIdsMaxiumum
        });
    }
}

