import { combineHashCodes } from 'math-ts';
import { PropertyBase, type PropValueTotalHash, type PropsGroupField } from './Props';
import { DefaultMap, LegacyLogger, LruCache } from 'engine-utils-ts';
import { CustomPropsRegistry, type CustomPropertySerializer } from './CustomPropsRegistry';
import { PropertyViewBasicTypes } from './BasicPropsView';


export class SmallNumericArrayProperty<N extends number = number> extends PropertyBase {
    
    public readonly values: N[];
    public readonly unit: string|undefined;

    get count() {
        return this.values.length;
    }

    at(index: number): N | undefined {
        return this.values[index];
    }

    constructor(args: Partial<SmallNumericArrayProperty<N>>) {
        super();
        if (args.values && args.values.length > 0) {
            this.values = toArrayWithExactCapacity(args.values);
        } else {
            this.values = [];
        }
        this.unit = args.unit ?? undefined;
    }

    // static newInterned<N extends number = number>(values: N[]): SmallNumericArrayProperty<N> {
    //     return small_numbers_descriptions_dedup.get(values) as SmallNumericArrayProperty<N>;
    // }

    hash(): number | string {
        let hash = 2;
        for (const v of this.values) {
            hash = combineHashCodes(v, hash);
        }
        return hash;
    }

    uniqueValueHash(): PropValueTotalHash {
        return this.values.join();
    }
    equals(other: PropsGroupField): boolean {
        if (!(other instanceof SmallNumericArrayProperty)) {
            return false;
        }
        if (this.values.length !== other.values.length) {
            return false;
        }
        for (let i = 0; i < this.values.length; ++i) {
            if (!Object.is(this.values[i], other.values[i])) {
                return false;
            }
        }
        return true;
    }

}
class SmallNumericArrayPropertySerializer implements CustomPropertySerializer<SmallNumericArrayProperty> {
    currentFormatVersion: number = 0;
    serializeToString(property: SmallNumericArrayProperty): any {
        const asJson = JSON.stringify({values: property.values, unit: property.unit});
        return asJson;
    }
    deserializeFromString(fromVersion: number, serialized: string): SmallNumericArrayProperty {
        const {values, unit} = JSON.parse(serialized);
        return new SmallNumericArrayProperty({values, unit});
    }
}
CustomPropsRegistry.register({
    class: SmallNumericArrayProperty,
    constructFromPartial: SmallNumericArrayProperty,
    serializerClass: SmallNumericArrayPropertySerializer,
    basicTypesView: {
        basicTypes: PropertyViewBasicTypes.NumericArray,
        toBasicValues: (formatters, property) => ({value: property.values, unit: property.unit}),
    }
})

const small_numbers_descriptions_dedup = new LruCache<number[], SmallNumericArrayProperty<number>>({
    identifier: 'piles_descriptions_dedup',
    maxSize: 100,
    eqFunction: (a, b) => {
        if (a === b) {
            return true;
        }
        if (a.length !== b.length) {
            return false;
        }
        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
        return true;        
    },
    hashFn: (a) => a.join(),
    factoryFn: (args) => new SmallNumericArrayProperty({values: args}),
});

export function toArrayWithExactCapacity<T extends number>(arr: T[]): T[] {
    if (arr.length > 0 && arr.length <= 25) {
        const copy = SmallArraysWithLengthEqualTo.getOrCreate(arr.length).slice() as T[];
        for (let i = 0; i < arr.length; i++) {
            copy[i] = arr[i];
        }
        return copy;
    }
    return arr;
}

const SmallArraysWithLengthEqualTo = new DefaultMap<number,number[]>(
    (length: number) => {
        let arr: number[];
        switch (length) {
            case 0: arr =  []; break;
            case 1: arr =  [0]; break;
            case 2: arr =  [0, 0]; break;
            case 3: arr =  [0, 0, 0]; break;
            case 4: arr =  [0, 0, 0, 0]; break;
            case 5: arr =  [0, 0, 0, 0, 0]; break;
            case 6: arr =  [0, 0, 0, 0, 0, 0]; break;
            case 7: arr =  [0, 0, 0, 0, 0, 0, 0]; break;
            case 8: arr =  [0, 0, 0, 0, 0, 0, 0, 0]; break;
            case 9: arr =  [0, 0, 0, 0, 0, 0, 0, 0, 0]; break;
            case 10: arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; break;
            case 11: arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; break;
            case 12: arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; break;
            case 13: arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; break;
            case 14: arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; break;
            case 15: arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; break;
            case 16: arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; break;
            case 17: arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; break;
            case 18: arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; break;
            case 19: arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; break;
            case 20: arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; break;
            case 21: arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; break;
            case 22: arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; break;
            case 23: arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; break;
            case 24: arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; break;
            case 25: arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; break;
            default: {
                LegacyLogger.deferredWarn('trying to create small array with no excessive capacity with large size of', length);
                arr = []; // use new Array(length) ??
                for (let i = 0; i < length; i++) {
                    arr.push(0);
                }
            }
        }
        Object.freeze(arr);
        return arr;
    },
)
