// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { BasicAnalyticalRepresentationT } from './basic-analytical-representation.js';
import type { BimPropertyT } from './bim-property.js';
import { BimProperty } from './bim-property.js';
import type { HeightmapRepresentationT } from './heightmap-representation.js';
import type { PropertiesGroupT } from './properties-group.js';
import { PropertiesGroup } from './properties-group.js';
import type { RulerRepresentationT } from './ruler-representation.js';
import type { SceneImageRepresentationT } from './scene-image-representation.js';
import { SceneObjectAnalyticalRepresentation, unionToSceneObjectAnalyticalRepresentation } from './scene-object-analytical-representation.js';
import { SceneObjectRepresentation, unionToSceneObjectRepresentation } from './scene-object-representation.js';
import type { StdGroupedMeshRepresentationT } from './std-grouped-mesh-representation.js';
import type { StdMeshRepresentationT } from './std-mesh-representation.js';
import type { TerrainHeightMapRepresentationT } from './terrain-height-map-representation.js';
import type { TransformT } from './transform.js';
import { Transform } from './transform.js';


export class SceneObjectInstance implements flatbuffers.IUnpackableObject<SceneObjectInstanceT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SceneObjectInstance {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSceneObjectInstance(bb:flatbuffers.ByteBuffer, obj?:SceneObjectInstance):SceneObjectInstance {
  return (obj || new SceneObjectInstance()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSceneObjectInstance(bb:flatbuffers.ByteBuffer, obj?:SceneObjectInstance):SceneObjectInstance {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SceneObjectInstance()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

typeIdentifier():string|null
typeIdentifier(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
typeIdentifier(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

name():string|null
name(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
name(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

localTransform(obj?:Transform):Transform|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new Transform()).__init(this.bb_pos + offset, this.bb!) : null;
}

properties(index: number, obj?:BimProperty):BimProperty|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new BimProperty()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

propertiesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

representationType():SceneObjectRepresentation {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : SceneObjectRepresentation.NONE;
}

representation<T extends flatbuffers.Table>(obj:any):any|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
}

spatialParentId():number {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

electricParentId():number {
  const offset = this.bb!.__offset(this.bb_pos, 18);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

deprecatedRepresentationLowDetailType():SceneObjectRepresentation {
  const offset = this.bb!.__offset(this.bb_pos, 20);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : SceneObjectRepresentation.NONE;
}

deprecatedRepresentationLowDetail<T extends flatbuffers.Table>(obj:any):any|null {
  const offset = this.bb!.__offset(this.bb_pos, 22);
  return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
}

representationAnalyticalType():SceneObjectAnalyticalRepresentation {
  const offset = this.bb!.__offset(this.bb_pos, 24);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : SceneObjectAnalyticalRepresentation.NONE;
}

representationAnalytical<T extends flatbuffers.Table>(obj:any):any|null {
  const offset = this.bb!.__offset(this.bb_pos, 26);
  return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
}

colorTint():number {
  const offset = this.bb!.__offset(this.bb_pos, 28);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

hierarchySortKey():number {
  const offset = this.bb!.__offset(this.bb_pos, 30);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

connectedTo(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 32);
  return offset ? this.bb!.readUint32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
}

connectedToLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 32);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

connectedToArray():Uint32Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 32);
  return offset ? new Uint32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

props(obj?:PropertiesGroup):PropertiesGroup|null {
  const offset = this.bb!.__offset(this.bb_pos, 34);
  return offset ? (obj || new PropertiesGroup()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startSceneObjectInstance(builder:flatbuffers.Builder) {
  builder.startObject(16);
}

static addTypeIdentifier(builder:flatbuffers.Builder, typeIdentifierOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, typeIdentifierOffset, 0);
}

static addName(builder:flatbuffers.Builder, nameOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, nameOffset, 0);
}

static addLocalTransform(builder:flatbuffers.Builder, localTransformOffset:flatbuffers.Offset) {
  builder.addFieldStruct(2, localTransformOffset, 0);
}

static addProperties(builder:flatbuffers.Builder, propertiesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, propertiesOffset, 0);
}

static createPropertiesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startPropertiesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addRepresentationType(builder:flatbuffers.Builder, representationType:SceneObjectRepresentation) {
  builder.addFieldInt8(4, representationType, SceneObjectRepresentation.NONE);
}

static addRepresentation(builder:flatbuffers.Builder, representationOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, representationOffset, 0);
}

static addSpatialParentId(builder:flatbuffers.Builder, spatialParentId:number) {
  builder.addFieldInt32(6, spatialParentId, 0);
}

static addElectricParentId(builder:flatbuffers.Builder, electricParentId:number) {
  builder.addFieldInt32(7, electricParentId, 0);
}

static addDeprecatedRepresentationLowDetailType(builder:flatbuffers.Builder, deprecatedRepresentationLowDetailType:SceneObjectRepresentation) {
  builder.addFieldInt8(8, deprecatedRepresentationLowDetailType, SceneObjectRepresentation.NONE);
}

static addDeprecatedRepresentationLowDetail(builder:flatbuffers.Builder, deprecatedRepresentationLowDetailOffset:flatbuffers.Offset) {
  builder.addFieldOffset(9, deprecatedRepresentationLowDetailOffset, 0);
}

static addRepresentationAnalyticalType(builder:flatbuffers.Builder, representationAnalyticalType:SceneObjectAnalyticalRepresentation) {
  builder.addFieldInt8(10, representationAnalyticalType, SceneObjectAnalyticalRepresentation.NONE);
}

static addRepresentationAnalytical(builder:flatbuffers.Builder, representationAnalyticalOffset:flatbuffers.Offset) {
  builder.addFieldOffset(11, representationAnalyticalOffset, 0);
}

static addColorTint(builder:flatbuffers.Builder, colorTint:number) {
  builder.addFieldInt32(12, colorTint, 0);
}

static addHierarchySortKey(builder:flatbuffers.Builder, hierarchySortKey:number) {
  builder.addFieldFloat64(13, hierarchySortKey, 0.0);
}

static addConnectedTo(builder:flatbuffers.Builder, connectedToOffset:flatbuffers.Offset) {
  builder.addFieldOffset(14, connectedToOffset, 0);
}

static createConnectedToVector(builder:flatbuffers.Builder, data:number[]|Uint32Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createConnectedToVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createConnectedToVector(builder:flatbuffers.Builder, data:number[]|Uint32Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt32(data[i]!);
  }
  return builder.endVector();
}

static startConnectedToVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addProps(builder:flatbuffers.Builder, propsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(15, propsOffset, 0);
}

static endSceneObjectInstance(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): SceneObjectInstanceT {
  return new SceneObjectInstanceT(
    this.typeIdentifier(),
    this.name(),
    (this.localTransform() !== null ? this.localTransform()!.unpack() : null),
    this.bb!.createObjList<BimProperty, BimPropertyT>(this.properties.bind(this), this.propertiesLength()),
    this.representationType(),
    (() => {
      const temp = unionToSceneObjectRepresentation(this.representationType(), this.representation.bind(this));
      if(temp === null) { return null; }
      return temp.unpack()
  })(),
    this.spatialParentId(),
    this.electricParentId(),
    this.deprecatedRepresentationLowDetailType(),
    (() => {
      const temp = unionToSceneObjectRepresentation(this.deprecatedRepresentationLowDetailType(), this.deprecatedRepresentationLowDetail.bind(this));
      if(temp === null) { return null; }
      return temp.unpack()
  })(),
    this.representationAnalyticalType(),
    (() => {
      const temp = unionToSceneObjectAnalyticalRepresentation(this.representationAnalyticalType(), this.representationAnalytical.bind(this));
      if(temp === null) { return null; }
      return temp.unpack()
  })(),
    this.colorTint(),
    this.hierarchySortKey(),
    this.bb!.createScalarList<number>(this.connectedTo.bind(this), this.connectedToLength()),
    (this.props() !== null ? this.props()!.unpack() : null)
  );
}


unpackTo(_o: SceneObjectInstanceT): void {
  _o.typeIdentifier = this.typeIdentifier();
  _o.name = this.name();
  _o.localTransform = (this.localTransform() !== null ? this.localTransform()!.unpack() : null);
  _o.properties = this.bb!.createObjList<BimProperty, BimPropertyT>(this.properties.bind(this), this.propertiesLength());
  _o.representationType = this.representationType();
  _o.representation = (() => {
      const temp = unionToSceneObjectRepresentation(this.representationType(), this.representation.bind(this));
      if(temp === null) { return null; }
      return temp.unpack()
  })();
  _o.spatialParentId = this.spatialParentId();
  _o.electricParentId = this.electricParentId();
  _o.deprecatedRepresentationLowDetailType = this.deprecatedRepresentationLowDetailType();
  _o.deprecatedRepresentationLowDetail = (() => {
      const temp = unionToSceneObjectRepresentation(this.deprecatedRepresentationLowDetailType(), this.deprecatedRepresentationLowDetail.bind(this));
      if(temp === null) { return null; }
      return temp.unpack()
  })();
  _o.representationAnalyticalType = this.representationAnalyticalType();
  _o.representationAnalytical = (() => {
      const temp = unionToSceneObjectAnalyticalRepresentation(this.representationAnalyticalType(), this.representationAnalytical.bind(this));
      if(temp === null) { return null; }
      return temp.unpack()
  })();
  _o.colorTint = this.colorTint();
  _o.hierarchySortKey = this.hierarchySortKey();
  _o.connectedTo = this.bb!.createScalarList<number>(this.connectedTo.bind(this), this.connectedToLength());
  _o.props = (this.props() !== null ? this.props()!.unpack() : null);
}
}

export class SceneObjectInstanceT implements flatbuffers.IGeneratedObject {
constructor(
  public typeIdentifier: string|Uint8Array|null = null,
  public name: string|Uint8Array|null = null,
  public localTransform: TransformT|null = null,
  public properties: (BimPropertyT)[] = [],
  public representationType: SceneObjectRepresentation = SceneObjectRepresentation.NONE,
  public representation: HeightmapRepresentationT|RulerRepresentationT|SceneImageRepresentationT|StdGroupedMeshRepresentationT|StdMeshRepresentationT|TerrainHeightMapRepresentationT|null = null,
  public spatialParentId: number = 0,
  public electricParentId: number = 0,
  public deprecatedRepresentationLowDetailType: SceneObjectRepresentation = SceneObjectRepresentation.NONE,
  public deprecatedRepresentationLowDetail: HeightmapRepresentationT|RulerRepresentationT|SceneImageRepresentationT|StdGroupedMeshRepresentationT|StdMeshRepresentationT|TerrainHeightMapRepresentationT|null = null,
  public representationAnalyticalType: SceneObjectAnalyticalRepresentation = SceneObjectAnalyticalRepresentation.NONE,
  public representationAnalytical: BasicAnalyticalRepresentationT|null = null,
  public colorTint: number = 0,
  public hierarchySortKey: number = 0.0,
  public connectedTo: (number)[] = [],
  public props: PropertiesGroupT|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const typeIdentifier = (this.typeIdentifier !== null ? builder.createString(this.typeIdentifier!) : 0);
  const name = (this.name !== null ? builder.createString(this.name!) : 0);
  const properties = SceneObjectInstance.createPropertiesVector(builder, builder.createObjectOffsetList(this.properties));
  const representation = builder.createObjectOffset(this.representation);
  const deprecatedRepresentationLowDetail = builder.createObjectOffset(this.deprecatedRepresentationLowDetail);
  const representationAnalytical = builder.createObjectOffset(this.representationAnalytical);
  const connectedTo = SceneObjectInstance.createConnectedToVector(builder, this.connectedTo);
  const props = (this.props !== null ? this.props!.pack(builder) : 0);

  SceneObjectInstance.startSceneObjectInstance(builder);
  SceneObjectInstance.addTypeIdentifier(builder, typeIdentifier);
  SceneObjectInstance.addName(builder, name);
  SceneObjectInstance.addLocalTransform(builder, (this.localTransform !== null ? this.localTransform!.pack(builder) : 0));
  SceneObjectInstance.addProperties(builder, properties);
  SceneObjectInstance.addRepresentationType(builder, this.representationType);
  SceneObjectInstance.addRepresentation(builder, representation);
  SceneObjectInstance.addSpatialParentId(builder, this.spatialParentId);
  SceneObjectInstance.addElectricParentId(builder, this.electricParentId);
  SceneObjectInstance.addDeprecatedRepresentationLowDetailType(builder, this.deprecatedRepresentationLowDetailType);
  SceneObjectInstance.addDeprecatedRepresentationLowDetail(builder, deprecatedRepresentationLowDetail);
  SceneObjectInstance.addRepresentationAnalyticalType(builder, this.representationAnalyticalType);
  SceneObjectInstance.addRepresentationAnalytical(builder, representationAnalytical);
  SceneObjectInstance.addColorTint(builder, this.colorTint);
  SceneObjectInstance.addHierarchySortKey(builder, this.hierarchySortKey);
  SceneObjectInstance.addConnectedTo(builder, connectedTo);
  SceneObjectInstance.addProps(builder, props);

  return SceneObjectInstance.endSceneObjectInstance(builder);
}
}
