import { ScopedLogger } from 'engine-utils-ts';
import type { Vector3 } from 'math-ts';
import { Matrix4 } from 'math-ts';

import type { Camera, WebGLProgram, WebGLRendererParameters } from '../3rdParty/three';
import { Scene, WebGLRenderer } from '../3rdParty/three';
import type { GeometryGpuId, GpuGeometries } from '../geometries/GpuGeometries';
import type { MergedJobs, RenderJobsMerged } from '../geometries/RenderJobsMerged';
import type { KrMaterial } from '../materials/KrMaterial';
import type { MaterialsPool } from '../pools/MaterialsPool';
import type { LodMask } from '../scene/Submeshes2';
import { IndexedRenderer } from './IndexedRenderer';
import { UboBuffersAllocator } from './UboAllocator';

export class RendererExt extends WebGLRenderer {

	logger: ScopedLogger;

	_currentProgram: WebGLProgram | null = null;
	_currentMaterialId: number = -1;
	_currentGeometry: number = -1;
	_currentMatrixWorld = new Matrix4();
	// attributes_state: AttributesState;
	bufferRenderer: IndexedRenderer;
	_geometries!: GpuGeometries;
	bindingStates: any;
	uboBuffers: UboBuffersAllocator;

	private glContextLostCallback: () => void;
	private glContextRestoredCallback: () => void;

	constructor(rendererParams: WebGLRendererParameters) {
		super(rendererParams);

		// this.attributes_state = new AttributesState(this.context);
		this.glContextLostCallback = () => this.onGlContextLost();
		this.glContextRestoredCallback = () => this.onGlContextRestored();
		this.addGlContextEventListeners(this.glContextLostCallback, this.glContextRestoredCallback);

		this.bufferRenderer = new IndexedRenderer(this.context, this.info);
		this.uboBuffers = new UboBuffersAllocator(this.context);
		this.logger = new ScopedLogger('renderer');
	}

	startNewFrame() {
		this.uboBuffers.startNewFrame();
        // this.resetCurrentRenderState();
	}

	renderJobs(
		materials: MaterialsPool,
		camera: Camera,
		mjs: MergedJobs,
		lodMask: LodMask,
		from?: number,
		to?: number
	) {
		const jobs = mjs.mergedJobs;
		const worldOrigin = mjs.worldOrigin;
		from = from ?? 0;
		to = to ?? jobs.length;
		this.logger.debugAssert(jobs.length >= to, 'jobs upper bound is valid');

		camera = getCameraWithWorldOriginOffsetApplied(camera, worldOrigin);

		for (let i = from; i < to; ++i){
			const obj = jobs[i];
			// mergeCounts.push(obj.instance_count());
			const mat = materials.get(obj.materialId);
			if (mat && (obj.lod & lodMask)) {
				this.renderJob(camera, obj.geoId, mat, obj, false);
			}
		}

	}
	renderJobsEdges(
		materials: MaterialsPool,
		camera: Camera,
		mjs: MergedJobs,
		lodMask: LodMask,
		from?: number,
		to?: number
	) {
		const jobs = mjs.mergedJobs;
		const worldOrigin = mjs.worldOrigin;
		from = from ?? 0;
		to = to ?? jobs.length;
		this.logger.debugAssert(jobs.length >= to, 'jobs upper bound is valid');

		camera = getCameraWithWorldOriginOffsetApplied(camera, worldOrigin);
		for (let i = from; i < to; ++i){
			const obj = jobs[i];
			const mat = materials.get(obj.materialId);
				if (mat && mat.edgeMaterial && (obj.lod & lodMask)) {
				this.renderJob(camera, obj.geoId, mat.edgeMaterial, obj, true);
			}
		}
}

	renderJob(camera: Camera, geoId: GeometryGpuId, material: KrMaterial, job: RenderJobsMerged, render_edges: boolean) {
		// this.renderBufferDirect(camera, null, geometry, material, object, null);
		// return;

		if (!(this._currentMaterialId === material.id)) {
			var frontFaceCW = false; //( object.isMesh && object.matrixWorld.determinant() < 0 );

			this.state.setMaterial(material, frontFaceCW);

			this._currentMaterialId = material.id;
			this._currentProgram = this.setProgram(camera, material);
			// if (programSet !== program) {
			// 	program = programSet;
			// }
		}

		let program = this._currentProgram;
		const gl = this.context;

		if (!program) {
			this.logger.batchedError(`program not set for`, material.name);
			return;
		}
		// this.uboBuffers.resetBindings();
		const p_uniforms = program.getUniforms();

		if (job.dynamicUniforms.hash !== 0) {
			if (job.dynamicUniforms.hasTextures) {
				this.textures.resetTextureUnits();
			}
			for (const [key, value] of job.dynamicUniforms.map) {
				p_uniforms.setValue(gl, key, value, this.textures);
				// cachedUniforms.updateIfNeeded( key, value, p_uniforms );
			}
		}



		// let updateBuffers = false;

		// if (this._currentGeometry !== geoId ||
		// 	this._currentProgram !== program) {
		// 	this._currentGeometry = geoId;
		// 	this._currentProgram = program;
		// 	// this.cachedUniforms.clear();
		// 	updateBuffers = true;
		// }

		const ranges = this._geometries.bindByGpuId(geoId);
		if (ranges) {
			if (job.ubo_offset < 0) {
				this.logger.warn('empty ubo offset');
				return;
			}

			// const addr = (p_uniforms as any).map['transforms']?.addr;
			// if (addr) {
			// 	this.context.uniformMatrix4fv( addr, false, transforms.buffer as Float32Array, job.transforms_instanced_start, job.instance_count() * 16);
			// } else {
			// 	this.logger.deferredError(`transforms uniform address not found`, material);
			// }

			this.uboBuffers.bind(job, p_uniforms, gl);

			const instance_count = job.instance_count();
			this.bufferRenderer.drawRange(ranges, render_edges, instance_count);
		} else {
			this.logger.batchedWarn('no gpu ranges for geo', [geoId, job, material])
		}
	}

	initBasicThreeState(camera: Camera) {
		this.resetCurrentRenderState();
		// this.setupCurrentRenderStateWithLights(EmptyScene, camera);
	}

	resetRenderState() {
		this.resetCurrentRenderState();
		this._currentGeometry = -1;
		this._currentProgram = null;
		this._currentMaterialId = -9999;
		this._geometries.resetBinding();
	}

	addGlContextEventListeners(contextLostCallback: () => void, contextRestoredCallback: () => void) {
		this.domElement.addEventListener( 'webglcontextlost', contextLostCallback, false );
		this.domElement.addEventListener( 'webglcontextrestored', contextRestoredCallback, false );
	}

	removeGLContextEventListeners(contextLostCallback: () => void, contextRestoredCallback: () => void) {
		this.domElement.removeEventListener( 'webglcontextlost', contextLostCallback, false );
		this.domElement.removeEventListener( 'webglcontextrestored', contextRestoredCallback, false );
	}

	dispose(): void {
		this.removeGLContextEventListeners(this.glContextLostCallback, this.glContextRestoredCallback);
		super.dispose();
	}

	private onGlContextLost() {
		this.uboBuffers.freeBuffersOnContextLoss();
		this._geometries.freeChunksBuffersOnContextLoss();
	}

	private onGlContextRestored() {
		this.bufferRenderer.reInitializeOnContextRestore(this.context, this.info);
		this.uboBuffers.reInitializeOnContextRestore(this.context);
		this._geometries.reInitializeChunkBuffersOnContextRestore();
	}

	// bindGeometry(material: KrMaterial, program: WebGLProgram, geometry:KrEdgedGeometry ): void {

	// 	let gl = this.context;
	// 	let state = this.state;
	// 	state.initAttributes();

	// 	let geometryAttributes = geometry.attributes;
	// 	let programAttributes = program.getAttributes();
	// 	let materialDefaultAttributeValues = material.defaultAttributeValues;

	// 	for ( let name in programAttributes ) {
	// 		let programAttribute = programAttributes[ name ];
	// 		if ( programAttribute >= 0 ) {
	// 			let geometryAttribute = geometryAttributes[ name ];
	// 			if ( geometryAttribute !== undefined ) {
	// 				let normalized = geometryAttribute.normalized;
	// 				let size = geometryAttribute.itemSize;
	// 				let attribute = this.attributes.get( geometryAttribute );
	// 				if ( attribute === undefined ) continue;
	// 				let buffer = attribute.buffer;
	// 				let type = attribute.type;
	// 				state.enableAttribute( programAttribute );
	// 				gl.bindBuffer( gl.ARRAY_BUFFER, buffer );
	// 				gl.vertexAttribPointer( programAttribute, size, type, normalized, 0, 0 );
	// 			} else if ( materialDefaultAttributeValues !== undefined ) {
	// 				let value = materialDefaultAttributeValues[ name ];
	// 				if ( value !== undefined ) {
	// 					switch ( value.length ) {
	// 						case 2:
	// 							gl.vertexAttrib2fv( programAttribute, value );
	// 							break;
	// 						case 3:
	// 							gl.vertexAttrib3fv( programAttribute, value );
	// 							break;
	// 						case 4:
	// 							gl.vertexAttrib4fv( programAttribute, value );
	// 							break;
	// 						default:
	// 							gl.vertexAttrib1fv( programAttribute, value );

	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// 	state.disableUnusedAttributes();
	// }
}

export const EmptyScene = Object.freeze(new Scene());


function getCameraWithWorldOriginOffsetApplied(camera: Camera, worldOriginOffset: Vector3) {
	if (worldOriginOffset.length() > 0) {
		const cameraClone = camera.clone(true);
		cameraClone.position.sub(worldOriginOffset);
		cameraClone.matrix.addToPosition(worldOriginOffset.clone().multiplyScalar(-1));
		cameraClone.matrixWorld.addToPosition(worldOriginOffset.clone().multiplyScalar(-1));
		cameraClone.matrixWorldInverse.copy(cameraClone.matrixWorld).invert();
		cameraClone.mvpMatrix.copy(cameraClone.matrixWorldInverse).premultiply(cameraClone.projectionMatrix);
		return cameraClone;
	} else {
		return camera;
	}
}

// class AttributesState {

// 	gl: WebGL2RenderingContext;
// 	newAttributes: Uint8Array;
// 	enabledAttributes: Uint8Array;
// 	attributeDivisors: Uint8Array;
	
// 	constructor(gl: WebGL2RenderingContext) {
// 		this.gl = gl;
// 		let maxVertexAttributes = gl.getParameter( gl.MAX_VERTEX_ATTRIBS );
// 		this.newAttributes = new Uint8Array( maxVertexAttributes );
// 		this.enabledAttributes = new Uint8Array( maxVertexAttributes );
// 		this.attributeDivisors = new Uint8Array(maxVertexAttributes);
// 	}

// 	initAttributes() {
// 		for ( let i = 0, l = this.newAttributes.length; i < l; i ++ ) {
// 			this.newAttributes[ i ] = 0;
// 		}
// 	}

// 	enableAttribute( attribute: number ) {
// 		this.enableAttributeAndDivisor( attribute, 0 );
// 	}

// 	enableAttributeAndDivisor( attribute: number, meshPerAttribute: number ) {
// 		this.newAttributes[ attribute ] = 1;
// 		if ( this.enabledAttributes[ attribute ] === 0 ) {
// 			this.gl.enableVertexAttribArray( attribute );
// 			this.enabledAttributes[ attribute ] = 1;
// 		}

// 		if ( this.attributeDivisors[ attribute ] !== meshPerAttribute ) {
// 			this.attributeDivisors[ attribute ] = meshPerAttribute;
// 		}
// 	}

// 	disableUnusedAttributes() {
// 		for ( let i = 0, l = this.enabledAttributes.length; i !== l; ++ i ) {
// 			if ( this.enabledAttributes[ i ] !== this.newAttributes[ i ] ) {
// 				this.gl.disableVertexAttribArray( i );
// 				this.enabledAttributes[ i ] = 0;
// 			}
// 		}
// 	}
// }


