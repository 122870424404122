import { UiPersistentState } from '../UiPersistentState';

export interface UiState {
    expanded: Array<{
        depth: number,
        name: string,
    }>
}
export let defaultUiState: UiState = {
    expanded: []
}
export function createUiState() {
    let uiPersistedState = new UiPersistentState<UiState>('config-ui-menu', defaultUiState)
    return uiPersistedState
}
