import { StringUtils } from "engine-utils-ts";
import type { PropertyGroupArgsType } from "../properties/PropertyGroup";
import type { ProjectMetricsType } from "./ProjectMetrics";

export type AggregateMethodType = "sum" | "avg" | "max" | "min" | "none";
type MetricKeyType = keyof PropertyGroupArgsType<ProjectMetricsType>;

interface MetricTemplateBaseArgs {
    key: string;
    name: string;
    unit?: string;
    aggregateMethod?: AggregateMethodType;
}

abstract class MetricTemplateBase {
    key: string;
    name: string;
    unit?: string;
    aggregateMethod?: AggregateMethodType;

    constructor(args: MetricTemplateBaseArgs) {
        this.key = args.key;
        this.name = args.name;
        this.unit = args.unit;
        this.aggregateMethod = args.aggregateMethod;
    }
}

interface ProjectMetricsGroupArgs extends Omit<MetricTemplateBaseArgs, "key"> {
    key?: string;
    children?: MetricTemplateBase[];
}

export class MetricTemplateGroup extends MetricTemplateBase {
    children: MetricTemplateBase[];

    constructor(args: ProjectMetricsGroupArgs) {
        super({ ...args, key: args.key ?? args.name });
        this.children = args.children || [];
    }
}

interface MetricTemplateArgs extends Omit<MetricTemplateBaseArgs, "name"> {
    key: MetricKeyType;
    name?: string;
}

export class MetricTemplateValue extends MetricTemplateBase {
    key: MetricKeyType;

    constructor(args: MetricTemplateArgs) {
        super({
            ...args,
            name:
                args.name ?? StringUtils.capitalizeFirstLatterInWord(args.key),
        });
        this.key = args.key;
    }
}

const BaseTemplate = [
    new MetricTemplateGroup({
        name: "Site area",
        children: [
            new MetricTemplateValue({ key: "site_area" }),
            new MetricTemplateValue({ key: "buildable_area" }),
            new MetricTemplateValue({ key: "footprint" }),
            new MetricTemplateValue({
                key: "roads_total_length",
                name: "Roads",
            }),
            new MetricTemplateValue({ key: "roads_total_area", name: " " }),
            new MetricTemplateValue({ key: "equipment_roads_width" }),
            new MetricTemplateValue({ key: "support_roads_width" }),
            new MetricTemplateValue({
                key: "solar_array_row_height",
                name: "Solar arrays rows",
                aggregateMethod: "sum",
            }),
        ],
    }),
    new MetricTemplateGroup({
        name: "Equipment",
        children: [
            new MetricTemplateValue({
                key: "pv_modules",
                name: "PV modules",
                aggregateMethod: "sum",
            }),
            new MetricTemplateValue({
                key: "pv_modules_area",
                name: "Modules area",
            }),
            new MetricTemplateValue({ key: "gcr", name: "GCR" }),

            new MetricTemplateValue({
                key: "any_trackers",
                aggregateMethod: "sum",
            }),
            new MetricTemplateValue({
                key: "trackers",
                aggregateMethod: "sum",
            }),
            new MetricTemplateValue({ key: "row_to_row" }),
            // new MetricTemplateValue({ key: 'row_to_row', name: 'Trackers Row to row' }),
            new MetricTemplateValue({
                key: "north_facing_trackers",
                name: "North-facing trackers",
                aggregateMethod: "sum",
            }),

            new MetricTemplateValue({
                key: "fixed_tilt",
                name: "Fixed-tilt",
                aggregateMethod: "sum",
            }),
            // new MetricTemplateValue({ key: 'row_to_row', name: 'Fixed-tilt Row to row' }),

            new MetricTemplateValue({
                key: "combiner_boxes",
                aggregateMethod: "sum",
            }),
            new MetricTemplateValue({
                key: "inverters",
                aggregateMethod: "sum",
            }),
            new MetricTemplateValue({
                key: "transformers",
                aggregateMethod: "sum",
            }),
            new MetricTemplateValue({
                key: "sectionalizing_cabinets",
                aggregateMethod: "sum",
            }),
            new MetricTemplateValue({
                key: "substations",
                aggregateMethod: "sum",
            }),
        ],
    }),
    new MetricTemplateGroup({
        name: "Energy",
        children: [
            new MetricTemplateValue({
                key: "max_cb_si_circuit_height",
                name: "Max CI CB circuit length",
                aggregateMethod: "max",
            }),
            new MetricTemplateValue({ key: "string_count", name: "Strings" }),
            new MetricTemplateValue({
                key: "dc_per_tracker",
                name: "DC total",
                aggregateMethod: "sum",
            }),
            new MetricTemplateValue({
                key: "not_connected_to_transformer_dc",
                name: "Solar arrays not connected to the inverter or transformer",
            }),
            new MetricTemplateValue({ key: "ac_total", name: "AC total" }),
            new MetricTemplateValue({
                key: "dc_ac_ratio",
                name: "DC/AC ratio",
            }),
            new MetricTemplateValue({
                key: "lv_loss",
                name: "LV voltage drop",
            }),
            new MetricTemplateValue({
                key: "average_lv_voltage_drop",
                name: " ",
            }),

            new MetricTemplateValue({
                key: "mv_loss",
                name: "MV voltage drop",
            }),
            new MetricTemplateValue({
                key: "average_mv_voltage_drop",
                name: " ",
            }),

            new MetricTemplateValue({
                key: "energy_yield_total",
                name: "Year 1 energy yield, total",
            }),
            new MetricTemplateValue({
                key: "energy_yield_daily",
                name: "Year 1 energy yield, daily",
            }),
            new MetricTemplateValue({
                key: "energy_performance",
                name: "Year 1 energy performance",
            }),
            new MetricTemplateValue({ key: "specific_annual_yield" }),
        ],
    }),
    new MetricTemplateGroup({
        name: "Civil",
        children: [
            new MetricTemplateValue({ key: "trenches_volume" }),
            new MetricTemplateValue({ key: "trenches_length" }),
            new MetricTemplateValue({
                key: "cut_fill_total",
                name: "Cut-fill total",
            }),
            new MetricTemplateValue({ key: "cut" }),
            new MetricTemplateValue({ key: "fill" }),
            new MetricTemplateValue({
                key: "cut_fill_net_balance",
                name: "Cut-fill Net balance",
            }),
            new MetricTemplateValue({ key: "piles_weight" }),
        ],
    }),
];

export const PdfExportTemplate = new MetricTemplateGroup({
    name: "pdf-export-template",
    children: BaseTemplate,
});

export const ProjectMetricsDefaultTemplate = new MetricTemplateGroup({
    name: "default-metrics-template",
    children: [
        new MetricTemplateGroup({
            name: "Cost",
            children: [
                new MetricTemplateValue({
                    key: "construction_cost_breakdown",
                    aggregateMethod: "sum",
                }),
                new MetricTemplateValue({
                    key: "construction_cost_per_DC_Watt",
                    name: "Construction cost per DC Watt",
                    aggregateMethod: "sum",
                }),
                new MetricTemplateValue({
                    key: "lcoe",
                    name: "Levelized cost of Energy",
                    aggregateMethod: "none",
                    unit: "MWh"
                }),
            ],
        }),
        ...BaseTemplate,
    ],
});
