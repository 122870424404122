<script lang="ts">
    import type { MenuDropdownItem } from "./ToolbarItems";
    import EngineViewsConstructors from "../ui-panels/ViewsConstructors.svelte";
    import { VersionedStore } from "../VersionedStore";
    import ToolbarButton from "./ToolbarButton.svelte";
    import { EnumUtils } from "engine-utils-ts";
    import { RuntimeSystemExecutionStatus } from "ui-bindings";

    export let activeDropDownMenu: string | null = null;
    export let menu: MenuDropdownItem;

    const inProgress = EnumUtils.enumStringFromKey(
        RuntimeSystemExecutionStatus,
        RuntimeSystemExecutionStatus.InProgress,
        "",
    ).value;

    $: name = new VersionedStore(menu.dynamicName);

    export let isOpen = false;

    $: active = menu.name === activeDropDownMenu || isOpen;
</script>

<div
    on:mouseleave={() => {
        isOpen = false;
    }}
    class="root"
    class:labelOpen={active}
>
    <div
        on:mouseenter={() => {
            if (!activeDropDownMenu) {
                isOpen = true;
            }
        }}
    >
        <ToolbarButton
            onClick={() => {
                activeDropDownMenu =
                    menu.name !== activeDropDownMenu ? menu.name : null;
            }}
        >
            <slot>
                {#if $name === inProgress}
                    <div class="loader"></div>
                {:else}
                    {@html $name}
                {/if}
            </slot>
        </ToolbarButton>
    </div>
    <div class="anchor">
        <div class="anchor-inner mdc-menu-surface--anchor">
            <div
                class:mdc-menu-surface--open={active}
                class="mdc-menu-surface
                        overflow-auto
                        scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-400
                        scrollbar-thumb-rounded-full scrollbar-track-rounded-full
                        bg-white
                    "
            >
                <div style="max-height: inherit">
                    <EngineViewsConstructors
                        uiNodeDescription={menu.uiNodeDescription}
                        identifier={menu.name}
                    ></EngineViewsConstructors>
                </div>
            </div>
        </div>
    </div>
</div>

<style lang="scss">
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .loader {
        border: 2px solid var(--color-alt);
        border-radius: 50%;
        border-top: 2px solid var(--color-main-medium);
        width: 12px;
        height: 12px;
        animation: spin 1s linear infinite;
    }

    .root {
        position: relative;
        min-width: 42px;

        &.labelOpen :global(.label) {
            background-color: #d4d4d4;
        }

        .anchor {
            .anchor-inner {
                position: relative;
                display: flex;
                div {
                    overflow: visible;
                }
            }
        }
        .mdc-menu-surface--open {
            min-width: 400px;
            // width: 400px;
            max-height: 600px;
            overflow: auto;
        }
    }
</style>
