<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9236 2C15.2325 2 15.4675 2.27743 15.4167 2.5822L14.1805 9.99993H19.6102L9.94204 21.8166C9.84708 21.9327 9.70503 22 9.55506 22H8.08502C7.77195 22 7.53589 21.7156 7.59359 21.4079L8.79507 14.9999H3.5L13.0999 2.2C13.1944 2.0741 13.3426 2 13.4999 2H14.9236Z"
        fill="currentColor"
    />
</svg>
