import type { EventStackFrame, LazyVersioned, ObservableObject, ScopedLogger, VersionedValue, Result} from 'engine-utils-ts';
import { Failure, MapObjectKey, ObjectUtils, resultify, unsafePopFromEventStackFrame, unsafePushToEventStackFrame, Yield } from 'engine-utils-ts';
import type { PUI_GroupNode} from 'ui-bindings';
import { RuntimeSystemExecutionStatus } from 'ui-bindings';
import type { EntitiesCollectionUpdates } from '../collections/EntitiesCollectionUpdates';
import type { IdBimScene } from '../scene/SceneInstances';
import type { SceneObjDiff } from 'src/scene/SceneObjDiff';
import type { BackendBasedCalculationsInvoker, BimCustomRuntime, CustomRuntimeAsyncWork } from './BimCustomRuntimes';
import type { Bim } from '..';
import type { GlobalArgsSelector, SharedGlobalsInput } from './RuntimeGlobals';
import type { MathSolversApi } from '../mathSolversApi/MathSolversApi';

export interface GroupIdent {
    sortKey: string;
    uniqueHash(): string | number;
}

export interface GroupDescription<GI extends GroupIdent> {
    readonly ident: GI;
    inputInstancesIds(): Iterable<IdBimScene>;
}

export interface GroupingSolverSettings {
    enabled: boolean;
    delaySeconds: number;
}

export interface GroupingSolver<
    GI extends GroupIdent,
    GD extends GroupDescription<GI>,
    PerGroupResult,
    Settings extends GroupingSolverSettings,
    SGAS extends GlobalArgsSelector
> {
    readonly name: string;

    readonly calculationsInvalidator: VersionedValue;


    /**
     * If this option is not set to true,
     * invalidateFromBimUpdates method will stop being called as soon
     * as solver rerun is requested 
     */
    readonly alwaysPassAllInvalidationBimUpdates?: boolean; 
    readonly executionOrder: number;
    readonly maxGroupsExecutionParallelism?: number;

    readonly globalArgsSelector?: SGAS;

    readonly settings: ObservableObject<Settings>;
    readonly ui?: LazyVersioned<PUI_GroupNode>;

    invalidate(
        logger: ScopedLogger,
        bim: Bim,
        instancesUpdate: EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>,
        allPrevUsedInstances: ReadonlySet<IdBimScene>,
    ): void;

    invalidateFromSharedDependenciesUpdates?(ident: keyof SGAS): void;

    generateCalculationGroups(args: {
        logger: ScopedLogger,
        bim: Bim,
        globalArgs: SharedGlobalsInput<SGAS>,
    }): Generator<Yield, GD[]>;

    startGroupResultsCalculation(args: {
        logger: ScopedLogger,
        bim: Bim,
        mathSolversApi: MathSolversApi,
        groupDescription: GD,
    }): Generator<Yield, PerGroupResult | Result<PerGroupResult>>;

    applyResultsToBim(args: {
        logger: ScopedLogger,
        bim: Bim,
        groupDescription: GD,
        groupCalcResults: Result<PerGroupResult>,
    }): void;

    dispose?(): void;
}

export class BimCustomGroupedRuntime<
    GI extends GroupIdent,
    GD extends GroupDescription<GI>,
    GroupCalcResults extends Object,
    Settings extends GroupingSolverSettings,
    SGAS extends GlobalArgsSelector
> implements BimCustomRuntime<SGAS> {

    readonly logger: ScopedLogger;

    executionOrder: number = 0;

    settings: ObservableObject<Settings>;
    ui?: LazyVersioned<PUI_GroupNode>;

    globalArgsSelector: SGAS | undefined;

    _lastUsedCalculationsVersion: number = -1;

    readonly _lastCalculatedGroups: MapObjectKey<GI, GD> = new MapObjectKey((gi) => gi.uniqueHash());
    readonly _lastAggregatedInputInstances: Set<IdBimScene> = new Set();
    readonly _groupsToRun: MapObjectKey<GI, Generator<Yield, GroupCalcResults, unknown> | null> = new MapObjectKey((gi) => gi.uniqueHash());

    _rerunRequested: boolean = false;
    _generator: CustomRuntimeAsyncWork<any> | null = null;

    _version: number = 0;

    constructor(
        logger: ScopedLogger,
        public readonly name: string,
        public readonly solver: GroupingSolver<GI, GD, GroupCalcResults, Settings, SGAS>,
    ) {
        logger = logger.newScope(solver.name);
        this.logger = logger;
        this.globalArgsSelector = solver.globalArgsSelector ?? undefined;
        this.settings = solver.settings;
        this.settings.addPatchValidator('delaySeconds', (delay: number) => {
            if (!Number.isFinite(delay)) {
                this.logger.error('invalid delay patch', delay);
                return 1;
            }
            if (delay < 0) {
                this.logger.error('invalid delay patch', delay);
                return 0;
            }
            if (delay > 1000) {
                this.logger.error('invalid delay patch', delay);
                return 1000;
            }
            return delay;
        });
        this.settings.observeObject({
            settings: { immediateMode: true },
            onPatch: ({patch, currentValueRef}) => {
                if (patch.enabled !== undefined) {
                    if (currentValueRef.enabled) {
                        this._startCalculations();
                    } else {
                        this._stopCalculations();
                    }
                } else if (patch.delaySeconds !== undefined) {
                    // nothing to do
                } else {
                    // probably calculation settings changed, invalidate
                    this.logger.debug('invalidating due to settings change', patch);
                    this._rerunRequested = true;
                }
            }
        });
        this.ui = solver.ui;
        // if (solver.ui) {
        //     this.ui = LazyDerived.new1(
        //         this.solver.name + ' grouped ui',
        //         [this],
        //         [this.solver.ui],
        //         ([solverUi]) => {
        //             const ui = new PUI_GroupNode({name: this.name, sortChildren: false});
                    

        //             return ui;
        //         }
        //     )
        // }
    }

    _startCalculations(): void {
        this.logger.debug('starting calculations');
        this._rerunRequested = true;
        this._version += 1;
    }
    _stopCalculations(): void {
        this.logger.debug('stopping calculations');
        this._generator = null;
        this._lastAggregatedInputInstances.clear();
        this._version += 1;
    }

    executionStatus(): RuntimeSystemExecutionStatus {
        if (this.settings.poll().enabled === false) {
            return RuntimeSystemExecutionStatus.Disabled;
        }
        if (this._generator) {
            return RuntimeSystemExecutionStatus.InProgress;
        }
        if (this._rerunRequested) {
            return RuntimeSystemExecutionStatus.Waiting;
        }
        if (this.solver.calculationsInvalidator.version() !== this._lastUsedCalculationsVersion) {
            return RuntimeSystemExecutionStatus.Waiting;
        }
        return RuntimeSystemExecutionStatus.Done;
    }

    invalidateFromBimUpdates(args: {
        bim: Bim,
        updates: EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>[],
    }): void {
        const canSkipUpdatesIfInvalidated = !this.solver.alwaysPassAllInvalidationBimUpdates;
        if (
            (canSkipUpdatesIfInvalidated && this._rerunRequested)
            || this.settings.poll().enabled === false) {
            return;
        }
        const solverInv = this.solver.calculationsInvalidator;
        for (
            let i = 0;
            (i < args.updates.length)
            && (
                !canSkipUpdatesIfInvalidated
                || (this._lastUsedCalculationsVersion === solverInv.version())
            );
            ++i
        ) {
            const update = args.updates[i];
            this.solver.invalidate(this.logger, args.bim, update, this._lastAggregatedInputInstances);
        }

    }

    invalidateFromSharedDependenciesUpdates(ident: keyof SGAS): void {
        if (!this.globalArgsSelector) {
            this.logger.error('unexpected invalidateFromSharedDependenciesUpdates call, no sharedGlobalArgsSelector');
            return;
        }
        if (!this.solver.invalidateFromSharedDependenciesUpdates) {
            this.logger.error('unexpected invalidateFromSharedDependenciesUpdates call, solver does not implement it');
            return;
        }
        this.solver.invalidateFromSharedDependenciesUpdates(ident);
    }

    applyQuickPreWorkUpdates(args: {bim: Bim}): void {
        if (this._rerunRequested || this.settings.poll().enabled === false) {
            return;
        }
        const groupingsInvalidor = this.solver.calculationsInvalidator.version();
        if (groupingsInvalidor !== this._lastUsedCalculationsVersion) {
            this._lastUsedCalculationsVersion = groupingsInvalidor;
            this._rerunRequested = true;
            this._generator = null;
        }
    }
    asyncWorkToRun(args: {
        bim: Bim,
        mathSolversApi: MathSolversApi,
        backendBasedCalculator: BackendBasedCalculationsInvoker<any, any>,
        globalArgs?: Readonly<SharedGlobalsInput<SGAS>>,
    }): CustomRuntimeAsyncWork<any> | null {
        if (this.settings.poll().enabled === false) {
            return null;
        }
        if (this._rerunRequested) {
            this._rerunRequested = false;
            this._version++;
            this._generator = {
                generator: this._groupsRecalcRoutine({
                    bim: args.bim,
                    mathSolversApi: args.mathSolversApi,
                    globalArgs: args.globalArgs,
                }),
                finally: () => {
                    this._generator = null;
                    this._version++;
                }
            };
        }
        return this._generator;
    }

    applyQuickPostWorkUpdates(): void {
        if (this.settings.poll().enabled === false) {
            return;
        }
    }

    version(): number {
        return this.solver.calculationsInvalidator.version() + this._version + this.settings.version();
    }

    dispose(): void {
        this._stopCalculations();
        this.settings.dispose();
        if (this.solver.dispose) {
            this.solver.dispose();
        }
    }

    *_groupsRecalcRoutine(args: {
        bim: Bim,
        mathSolversApi: MathSolversApi,
        globalArgs?: Readonly<SharedGlobalsInput<SGAS>>,
    }): Generator<Yield, void> {

        yield Yield.Asap;
        // this.logger.warn(`START groups recalc routine`, performance.now() / 1000)

        const startTime = performance.now();

        while ((performance.now() - startTime) / 1000 < this.settings.poll().delaySeconds) {
            yield Yield.NextFrame;
        }

        if (this.globalArgsSelector && !args.globalArgs) {
            throw new Error('sharedGlobalArgsSelector is defined, but globalArgs is not provided');
        }

        const freshGroups = yield* this.solver.generateCalculationGroups({
            logger: this.logger,
            bim: args.bim,
            globalArgs: args.globalArgs!,
        });

        freshGroups.sort((a, b) => a.ident.sortKey.localeCompare(b.ident.sortKey))

        yield Yield.Asap;

        const freshGroupsMap = new MapObjectKey<GI, GD>(k => k.uniqueHash());
        for (const group of freshGroups) {
            freshGroupsMap.set(group.ident, group);
        }
        if (freshGroupsMap.size !== freshGroups.length) {
            this.logger.error('generated groups identifiers are not unique', freshGroups.map(g => g.ident));
        }

        for (const gi of this._lastCalculatedGroups.keys()) {
            if (!freshGroupsMap.has(gi)) {
                this._lastCalculatedGroups.delete(gi);
                this._groupsToRun.delete(gi);
            }
        }

        this._lastAggregatedInputInstances.clear();
        for (const [ident, freshGroup] of freshGroupsMap) {
            const prevGroup = this._lastCalculatedGroups.get(ident);

            let groupToSet: GD;
            if (prevGroup && ObjectUtils.areObjectsEqual(prevGroup, freshGroup)) {
                groupToSet = prevGroup;
                this.logger.debug('calculated groups are equal, skipping', ident, freshGroup, prevGroup);
            } else {
                groupToSet = freshGroup;
                this._groupsToRun.set(ident, null);
            }
            this._lastCalculatedGroups.set(ident, groupToSet);
            for (const id of groupToSet.inputInstancesIds()) {
                this._lastAggregatedInputInstances.add(id);
            } 
        }
        freshGroupsMap.clear();
        yield Yield.Asap;

        yield* new GroupsParallelExecutor(
            this.solver.maxGroupsExecutionParallelism ?? 5,
            this.logger,
            args.bim,
            args.mathSolversApi,
            this.solver,
            this._lastCalculatedGroups,
            this._groupsToRun,
        );
        // this.logger.warn(`STOP groups recalc routine ${this._lastCalculatedGroups.size}`, performance.now() / 1000);

    }
}

class GroupsParallelExecutor<
    GI extends GroupIdent,
    GD extends GroupDescription<GI>,
    GroupCalcResults extends Object,
    Settings extends GroupingSolverSettings,
    SGAS extends GlobalArgsSelector
> implements Generator<Yield, void> {

    constructor(
        readonly maxParallelism: number,
        readonly logger: ScopedLogger,
        readonly bim: Bim,
        readonly mathSolversApi: MathSolversApi,
        readonly solver: GroupingSolver<GI, GD, GroupCalcResults, Settings, SGAS>,
        readonly groupsDescriptions: MapObjectKey<GI, GD>,
        readonly groupsToRunConsume: MapObjectKey<GI, Generator<Yield, GroupCalcResults | Result<GroupCalcResults>, unknown> | null>,
    ) {
        if (maxParallelism < 1) {
            throw new Error('invalid max parallelism for ' + solver.name);
        }
    }

    next(): IteratorResult<Yield, void> {
        if (this.groupsToRunConsume.size === 0) {
            return {done: true, value: undefined};
        }
        let parallelism = 0;
        for (let [groupIdent, generator] of this.groupsToRunConsume) {
            ++parallelism;
            if (parallelism > this.maxParallelism) {
                break;
            }
            const groupDescription = this.groupsDescriptions.get(groupIdent);

            if (!groupDescription) {
                this.logger.error('group to run not found', groupIdent);
                this.groupsToRunConsume.delete(groupIdent);
                continue;
            }

            let groupCalcResults: Result<GroupCalcResults>;
            try {
                if (generator === null) {
                    generator = this.solver.startGroupResultsCalculation({
                        logger: this.logger,
                        bim: this.bim,
                        mathSolversApi: this.mathSolversApi,
                        groupDescription,
                    });
                    this.groupsToRunConsume.set(groupIdent, generator);
                }

                const generatorResult = generator.next();
                if (generatorResult.done) {
                    groupCalcResults = resultify(generatorResult.value);
                } else if (generatorResult.value === Yield.NextFrame) {
                    continue;
                } else {
                    return {done: false, value: Yield.Asap};
                }
            } catch(e) {
                console.error(e);
                groupCalcResults = new Failure(e);
            }

            this.groupsToRunConsume.delete(groupIdent);

            let derivedEventFrame: EventStackFrame|null = null;
            try {
                derivedEventFrame = unsafePushToEventStackFrame({isEventDerived: true});
                this.solver.applyResultsToBim({
                    logger: this.logger,
                    bim: this.bim,
                    groupDescription,
                    groupCalcResults,
                });
            } catch (e) {
                this.logger.error('error applying group result', groupIdent, groupCalcResults, e);
            } finally {
                if (derivedEventFrame){
                    unsafePopFromEventStackFrame(derivedEventFrame);
                }
            }
        }
        if (this.groupsDescriptions.size > 0) {
            return {done: false, value: Yield.Asap};
        }
        return {done: true, value: undefined};
    }
    return(value: void): IteratorResult<Yield, void> {
        throw new Error('Method not implemented.');
    }
    throw(e: any): IteratorResult<Yield, void> {
        throw new Error('Method not implemented.');
    }
    [Symbol.iterator](): Generator<Yield, void> {
        return this;
    }

}