import { LegacyLogger } from './LegacyLogger';

export class OneToMany<One, Many> {
    readonly _refs: Map<One, Many | Many[]> = new Map();
    readonly _backRefs: Map<Many, One> = new Map();

    constructor() {}

    add(from: One, to: Many) {
        const saved = this._refs.get(from);
        if (saved === undefined) {
            this._refs.set(from, to);
        } else if (Array.isArray(saved)) {
            LegacyLogger.assert(
                saved.includes(to) == false,
                "one to many, multiple additions check"
            );
            saved.push(to);
        } else {
            this._refs.set(from, [saved, to]);
        }
        this._backRefs.set(to, from);
    }

    getParent(to: Many): One | undefined {
        return this._backRefs.get(to);
    }

    hasAnyRefsFrom(one: One): boolean {
        return this._refs.get(one) != undefined;
    }

    remove(from: One, to: Many) {
        const saved = this._refs.get(from);
        this._backRefs.delete(to);
        if (saved === undefined) {
            return;
        } else if (saved === to) {
            this._refs.delete(from);
        } else if (Array.isArray(saved)) {
            const index = saved.indexOf(to);
            if (index >= 0) {
                saved.splice(index, 1);
            }
            if (saved.length === 0) {
                this._refs.delete(from);
            }
        }
    }

    *iter(from: One): IterableIterator<Many> {
        const saved = this._refs.get(from);
        if (saved === undefined) {
            return;
        }
        if (Array.isArray(saved)) {
            yield* saved.values();
        } else {
            yield saved;
        }
    }

    *iterOnes(): IterableIterator<One> {
        yield* this._refs.keys();
    }

    *iterMapped<R>(from: One, mapFn: (s: Many) => R): IterableIterator<R> {
        const saved = this._refs.get(from);
        if (saved === undefined) {
            return;
        }
        if (Array.isArray(saved)) {
            for (const s of saved) {
                yield mapFn(s);
            }
        } else {
            yield mapFn(saved);
        }
    }

    tryIter(from: One, onEach: (ref: Many) => void): boolean {
        const saved = this._refs.get(from);
        if (saved === undefined) {
            return false;
        }
        if (Array.isArray(saved)) {
            for (const s of saved) {
                onEach(s);
            }
        } else {
            onEach(saved);
        }
        return true;
    }

    countRefs(from: One): number {
        const saved = this._refs.get(from);
        if (saved === undefined) {
            return 0;
        }
        if (Array.isArray(saved)) {
            return saved.length;
        } else {
            return 1;
        }
    }

    size(): number {
        return this._refs.size;
    }
}
