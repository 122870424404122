import { ObjectUtils } from 'engine-utils-ts';

import type { BimPropertyData } from './BimProperty';
import { BimProperty } from './BimProperty';
import type { NamedBimPropertiesGroup } from './NamedBimPropertiesGroup';


export type PropertiesPatch = [string, BimProperty | Partial<BimPropertyData> | null][];

export class PropertiesCollection {

    _props: Map<string, BimProperty> = new Map(); // by _mergedPath

    constructor(
        props?: BimProperty[] | null
    ) {
        if (props?.length) {
            for (const p of props) {
                this._props.set(p._mergedPath, p);
            }
        }
    }

    clone() {
        return new PropertiesCollection(Array.from(this._props.values()));
    }

    asPatch(props: Iterable<BimProperty>): PropertiesPatch | undefined {
        const res: PropertiesPatch = [];
        for (const p of props) {
            const existing = this._props.get(p._mergedPath);
            if (existing == undefined || !ObjectUtils.deepPatchEqualsToObject(
                p,
                existing
            )) {
                res.push([p._mergedPath, p]);
            }
        }
        return res.length ? res : undefined;
    }

    applyPatch(patch: PropertiesPatch): PropertiesPatch | null {
        const revertCOllection: PropertiesPatch = [];
        for (const [mergedpath, patchProp] of patch) {
            const currProp = this._props.get(mergedpath) ?? null;
            let revert: Partial<BimPropertyData> | undefined | null = undefined;
            if (currProp) {
                if (patchProp) {
                    let newProperty = patchProp instanceof BimProperty ? patchProp : BimProperty.NewShared({
                        ...currProp,
                        ...patchProp
                    });
					if (!newProperty.equals(currProp)) {
						revert = currProp;
						this._props.set(mergedpath, newProperty);
					}
                } else {
                    revert = currProp;
                    this._props.delete(mergedpath);
                }
            } else {
                if (patchProp) {
                    revert = null;
                    let newProperty = patchProp instanceof BimProperty ? patchProp : BimProperty.NewShared(patchProp as BimPropertyData);
                    this._props.set(mergedpath, newProperty);
                } else {

                }
            }
            if (revert !== undefined) {
                revertCOllection.push([mergedpath, revert]);
            }

        }
        return revertCOllection.length ? revertCOllection : null;
    }

    getPropNumberAs(path: string, defaultValue: number, unit: string): number {
        const prop = this._props.get(path);
        if (!prop) {
            return defaultValue;
        }
        if (Number.isNaN(prop.value)) {
            return defaultValue;
        }
        if (!unit) {
            return prop.value;
        }
        return prop.as(unit);
    }

    getPropStringValue(path: string, defaultValue: string): string {
        const prop = this._props.get(path);
        if (!prop) {
            return defaultValue;
        }
        if (typeof prop.value !== 'string') {
            return defaultValue;
        }
        return prop.value;
    }

    get(mergedPropertyPath: string): BimProperty | undefined {
        return this._props.get(mergedPropertyPath);
    }

    getOrDefault(p: BimPropertyData, errorOnMissing: boolean = true): BimProperty | undefined {
        const mergedPath = BimProperty.MergedPath(p.path);
        const prop = this.get(mergedPath);
        if (prop) {
            return prop;
        } else if (errorOnMissing){
            console.error('property not found', p);
        }
        return BimProperty.NewShared(p);
    }

    getPropsOrDefaults<T extends { [key: string]: BimPropertyData }>(
        defaults: T, errorOnMissing: boolean = true
    ): {[K in keyof T]: BimProperty} {
        const result: any = {};
        for (const [key, def] of Object.entries(defaults)) {
            result[key] = this.getOrDefault(def, errorOnMissing);
        }
        return result;
    }

    getPropStartingWith(prefix: string): BimProperty[] {
        const result: BimProperty[] = [];
        for (const [path, prop] of this._props) {
            if (path.startsWith(prefix)) {
                result.push(prop);
            }
        }
        return result;
    }

    createPatchToRemovePropsStartingWith(prefix: string): PropertiesPatch | null {
        const result: PropertiesPatch = [];
        for (const [path, prop] of this._props) {
            if (path.startsWith(prefix)) {
                result.push([prop._mergedPath, null]);
            }
        }
        return result;
    }

    values(): Iterable<BimProperty> {
        return this._props.values();
    }

    asArray(): BimProperty[] | null {
        if (this._props.size) {
            return Array.from(this._props.values())
        }
        return null;
    }

	[Symbol.iterator](): IterableIterator<[string, BimProperty]> {
        return this._props.entries();
	}

    extractPropertiesGroup<T extends NamedBimPropertiesGroup>(
        groupDefinition: T,
        params?: {
            /**
             * if `this` properties collection does not have some property defined in
             * `groupDefinition`, use property from `groupDefinition` instead
             */
            skipDefaultValues?: boolean,
            /**
             * Extract only value/unit and path
             */
            valueUnitOnly?: boolean
        }
    ): T {
        const group: NamedBimPropertiesGroup = {};
        for (const [propertyName, definitionProperty] of Object.entries(groupDefinition)) {
            let prop = this.get(BimProperty.MergedPath(definitionProperty.path));
            if (!params?.skipDefaultValues) {
                group[propertyName] = definitionProperty;
            }
            if (!prop) {
                continue;
            }

            if (params?.valueUnitOnly) {
              prop = BimProperty.NewShared(BimProperty.NewShared({
                path: prop.path,
                value: prop.value,
                unit: prop.unit,
              }))
            }

            group[propertyName] = prop;
        }
        return group as T;
    }

}

const emptyArray: [string, BimProperty][] = [];
