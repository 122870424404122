import { Success, type ScopedLogger} from "engine-utils-ts";
import type { Result } from "engine-utils-ts";
import type { Aabb } from "math-ts";
import { EntitiesInterned } from "../resources/EntitiesInterned";
import { EngineGeoType } from "./AllEngineGeometries";
import { EngineGeometry } from "./EngineGeometry";
import type { PlaneGeneratorParams } from "./GeometryGenerator";
import { GeometryGenerator } from "./GeometryGenerator";
import type { GeometryGpuRepr} from "./KrBufferGeometry";
import { KrEdgedGeoEmpty} from "./KrBufferGeometry";

export type PlaneGeo = PlaneGeneratorParams;

export class EngineGeoPlane extends EngineGeometry {


    constructor(
        public readonly sourcePlane: PlaneGeo | null = null,
    ) {
        super();
    }

	calcAabb(): Aabb {
		const gpuRepr = this.asGpuRepr();
		return gpuRepr.aabb;
	}

	_calcGpuRepr(): GeometryGpuRepr {
		if (!this.sourcePlane) {
			return KrEdgedGeoEmpty;
		}
		const triangulation = GeometryGenerator.generatePlane(this.sourcePlane);
        return triangulation
	}
}

export class EnginePlaneGeometries extends EntitiesInterned<EngineGeoPlane, EngineGeoType, PlaneGeo, number>{

    constructor(
        logger: ScopedLogger,
    ) {
        super({
            logger,
            identifier: 'engine-plane-geos',
            idsType: EngineGeoType.PlaneGeometry,
            uniqueReducerFn: (plane) => JSON.stringify(plane),
            T_Constructor: EngineGeoPlane,
        });
    }

    convertFromInternedType(bimObj: PlaneGeneratorParams): Result<EngineGeoPlane> {
        return new Success(new EngineGeoPlane(bimObj));
    }

    checkForErrors(t: EngineGeoPlane, errors: string[]): void {
        if (t.aabb().isEmpty()) {
            errors.push('empty aabb');
        }
    }
}
