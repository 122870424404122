
import type { BimPropertyData, ObjectRepresentation, BasicAnalyticalRepresentation, IdBimGeo, IdBimMaterial } from '../';
import type { PropsGroupBase, PropsPatch } from '../properties/Props';
import type { AnySolverObjectInput, ChildrenSelector, ObjectsSelector, SolverLegacyPropsInput } from './ObjectsSelector';
import type { AnySharedDependenciesInput, GlobalArgsSelector } from './RuntimeGlobals';

export abstract class ReactiveSolverBase  {

    readonly identifier: string;
    readonly objSelector: ObjectsSelector<SolverLegacyPropsInput, PropsGroupBase, AnySolverObjectInput>;
    readonly childrenSelector?: ChildrenSelector;
    readonly globalArgsSelector?: GlobalArgsSelector;
    readonly cache?: boolean;

    constructor(params: {
        identifier: string,
        objFilter: ObjectsSelector<SolverLegacyPropsInput, PropsGroupBase, AnySolverObjectInput>,
        globalArgsSelector?: GlobalArgsSelector,
        childrenFilter?: ChildrenSelector,
        cache?: boolean,
        parallelize?: boolean,
    }) {
        if (params.cache && params.childrenFilter) {
            throw new Error(`caching is supported only for in object solvers`);
        }
        this.objSelector = params.objFilter;
        this.childrenSelector = params.childrenFilter;
        this.globalArgsSelector = params.globalArgsSelector;
        this.identifier = params.identifier;
        this.cache = params.cache;
    }

    abstract compute(
        currObjectInput: Readonly<AnySolverObjectInput>,
        sharedArgs?: Readonly<AnySharedDependenciesInput>,
        children?: Readonly<AnySolverObjectInput>[][],
    ): SolverInstancePatchResult;

    invalidateInnerCache?: (args: {
        geometriesIds: Set<IdBimGeo>,
        materialsIds: Set<IdBimMaterial>,
    }) => void;
}

export interface SolverInstancePatchResult {
    legacyProps?: BimPropertyData[],
    // Use this prop to remove props from the instance
    // it contains merged paths of property marked for
    // removal
    removeProps?: string[],
    removePropsWithPrefixs?: string[],

    // newProps?: PropsGroupBase,
    repr?: ObjectRepresentation | null;
    reprAnalytical?: BasicAnalyticalRepresentation | null;

    propsPatch?: PropsPatch;
}


