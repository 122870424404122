<script lang="ts">
    import type { Writable } from "svelte/store";
    import MetricProperty from "./MetricProperty.svelte";
    import { IconComponent } from "../../libui/icon";
    import { UiState } from "../../pui/PUI_RuntimeState";
    import { MetricGroupNode, MetricPropertyNode } from "./MetricProperty";

    export let group: MetricGroupNode;
    export let depth: number;
    export let uiState: Writable<UiState>;

    $: children = group.children;

    $: curExpanded = $uiState.expanded;
    $: curIndex = curExpanded.findIndex(
        (x) => x.depth === depth && x.name === group.name,
    );
    $: expanded = curIndex == -1;
    function toggleExpand() {
        let newExpanded = [...curExpanded];
        if (curIndex === -1) {
            newExpanded.push({
                depth: depth,
                name: group.name,
            });
        } else {
            newExpanded.splice(curIndex, 1);
        }
        uiState.update((x) => ({ ...x, expanded: newExpanded }));
    }
    $: offset = Math.max(0, 16 * (depth - 1));
</script>

{#if children.size}
    <div
        class="group"
        class:first-level={depth == 1}
        class:root-level={depth == 0}
    >

        {#if group.name && depth != 0}
            <div
                on:click={toggleExpand}
                class="group-title-container"
                class:first-level-group={depth == 1}
                style="--padding:{offset}px"
            >
                <span class="icon-holder">
                    <IconComponent
                        name={expanded ? "ChevronDown" : "ChevronRight"}
                    />
                </span>
   
                {#if group.total}
                    <MetricProperty
                        sourceProperty={group.total}
                        offset={0}
                        isGroup={true}
                    />
                {:else}
                    <span
                        class="
                        mdc-typography--overline
                        property-group-name
                        overflow-clip
                        overflow-hidden
                        "
                        >
                        {group.name}
                    </span>
                {/if}
            </div>
        {/if}

        {#if expanded}
            {@const contentDepth = depth}
            {
                @const contentOffset = contentDepth
                ? Math.max(0, 16 * (contentDepth + 1))
                : 0
            }
            <div class="group-content" class:first-level-group={depth == 1}>
                {#each children as [name, childNode] (name)}
                    {#if childNode instanceof MetricPropertyNode}
                        <MetricProperty
                            sourceProperty={childNode}
                            offset={contentOffset}
                        />
                    {:else if childNode instanceof MetricGroupNode}
                        <svelte:self
                            bind:group={childNode}
                            bind:uiState
                            depth={depth + 1}
                        ></svelte:self>
                    {:else}
                        {console.warn(
                            "unknown node type",
                            childNode.constructor,
                        )}
                    {/if}
                {/each}
            </div>
        {/if}
    </div>
{/if}

<style lang="scss">
    .group {
        &.root-level {
            margin: 12px 0;
        }
        &.first-level {
            margin: 0 -8px 8px;
            padding: 0 8px;
            border-radius: 1px;
        }
    }
    .group-title-container {
        position: relative;
        cursor: default;
        display: flex;
        gap: 4px;
        align-items: center;
        padding-left: var(--padding);
        height: 26px;
    }
    .group-content{
        width: 100%;
    }
    .group-content:not(.first-level-group) {
        padding-left: 8px;
        padding-right: 8px;
        margin-left: -8px;
        margin-right: -8px;
    }
    .icon-holder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
    }
</style>
