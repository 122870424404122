import type { ScopedLogger} from "engine-utils-ts";
import { DefaultMap, IterUtils, LegacyLogger, LogLevel, ObjectsSet, WorkerClassPassRegistry } from "engine-utils-ts";
import type { ClipperPoint} from "math-ts";
import { ClipperLib, Aabb, Clipper, KrMath, Plane, Quaternion, Ray, Vec3Z, Vector2, Vector3 } from "math-ts";


export class SegmentTriangulation {
	constructor(
		readonly points: Vector3[],
	) {
	}
}

type SId = number;

const PointSegmentIntersectionEps = 0.01;
const AngleEps = 0.0001;

export class SegmentAnalyt {

	constructor(
		readonly id: SId,
		readonly from: Vector3,
		readonly to: Vector3,
		readonly width: number,
	) {
		// this.from.roundTo(0.005);
		// this.to.roundTo(0.005);
		this.width = KrMath.clamp(KrMath.roundTo(width, 0.001), 0.01, 100);
	}

	clone(): SegmentAnalyt {
		return new SegmentAnalyt(this.id, this.from.clone(), this.to.clone(), this.width);
	}

	up(): Vector3 {
		let upVector = new Vector3(0, 0, 1);
		const forwardVector = this.to.clone().sub(this.from);
		if (forwardVector.z !== 0) {
			upVector = Vector3.crossVectors(
				forwardVector,
				Vector3.crossVectors(forwardVector, new Vector3(forwardVector.x, forwardVector.y, 0))
			).normalize();
		}
		if (upVector.z < 0) {
			upVector.multiplyScalar(-1);
		}
		return upVector;
	}

	edgeCenter(): Vector3 {
		return this.from.clone().add(this.to).multiplyScalar(0.5);
	}

	plane(): Plane {
		return Plane.allocateZero().setFromNormalAndCoplanarPoint(
			this.up(),
			this.from
		);
	}

	segmentPolygonPoints():Vector3[] {
		const edgeCenter = this.edgeCenter();

		const points: Vector3[] = [];
		for (const segmentEndPoint of [this.from, this.to]) {
			const dirToCenter = segmentEndPoint.clone().sub(edgeCenter);

			const dirRight = Vector3.crossVectors(dirToCenter, Vec3Z).normalize();
			const dirleft = dirRight.clone().multiplyScalar(-1);

			points.push(dirRight.multiplyScalar(this.width * 0.5).add(segmentEndPoint));
			points.push(dirleft.multiplyScalar(this.width * 0.5).add(segmentEndPoint));
		}

		return points;
	}

	centerLineIntersects(other: SegmentAnalyt): Vector3 | null {
		const dir = this.to.clone().sub(this.from);
		const segmentLength = dir.length();
		const ray = new Ray(this.from, dir.normalize());
		const pointOnRay = new Vector3();
		const distance = ray.distanceToSegment(
			other.from,
			other.to,
			pointOnRay,
		);
		if (distance > PointSegmentIntersectionEps) {
			return null;
		}
		const distanceFromRayOrigin = pointOnRay.distanceTo(this.from);
		if (distanceFromRayOrigin > (segmentLength + PointSegmentIntersectionEps)) {
			return null;
		}
		return pointOnRay;
	}

	centerLineIntersectsPoint(point: Vector3): boolean {
		const dir = this.to.clone().sub(this.from);
		const segmentLength = dir.length();
		const ray = new Ray(this.from, dir.normalize());
		const pointOnRay = ray.closestPointToPoint(
			point
		);
		const distance = pointOnRay.distanceTo(point);
		if (distance > PointSegmentIntersectionEps) {
			return false;
		}
		const distanceFromRayOrigin = pointOnRay.distanceTo(this.from);
		if (distanceFromRayOrigin > (segmentLength + PointSegmentIntersectionEps)) {
			return false;
		}
		return true;
	}
}
WorkerClassPassRegistry.registerClass(SegmentAnalyt);

export interface SegmentTriangulationRepr {
	polygons: Float32Array[],
}

export function triangulateGraphEdges(args: {
	logger: ScopedLogger,
	segments: SegmentAnalyt[],
}): Map<SId, SegmentTriangulationRepr> {

	const logger = args.logger.newScope('triangulate', LogLevel.Info);

	const segmentsPerId = IterUtils.newMapFromIter(args.segments, s => s.id, s => s);
	const allSegments = Array.from(segmentsPerId.values());

	allSegments.sort((s1, s2) => s1.id - s2.id);

	if (segmentsPerId.size < args.segments.length) {
		logger.error(`segments ids should be unique, removed ${args.segments.length - segmentsPerId.size} segments`);
	}

	const allIntersections = calculateIntersectionsBetweenSegments(allSegments);

	simplifyPolylines(segmentsPerId, allIntersections);

	const allGroups = calculateGroups(segmentsPerId, allIntersections);

	const allRepresentationsResult = new Map<SId, SegmentTriangulationRepr>();

	for (const groupIds of allGroups) {
		const groupSegments = new Map(IterUtils.mapIter<SId, [SId, [SegmentAnalyt, Vector3[]]]>(
			groupIds, id => [id, [segmentsPerId.get(id)!, segmentsPerId.get(id)!.segmentPolygonPoints()]]
		));

		const thisGroupOrigin = segmentsPerId.get(groupIds.keys().next().value)!.from.clone().roundTo(10);

		const clipperScale = 1000;
		function mapToClipperPoint(point: Vector3): ClipperPoint {
			return new ClipperLib.IntPoint(
				Math.round((point.x - thisGroupOrigin.x) * clipperScale),
				Math.round((point.y - thisGroupOrigin.y) * clipperScale),
				Math.round((point.z - thisGroupOrigin.z) * clipperScale),
			);
		}
		function mapFromClipperPoint(clipperPoint: ClipperPoint) {
			return new Vector3(
				(clipperPoint.X / clipperScale) + thisGroupOrigin.x,
				(clipperPoint.Y / clipperScale) + thisGroupOrigin.y,
				(clipperPoint.Z / clipperScale) + thisGroupOrigin.z,
			)
		}

		const uniqueIntesectionPoints = new ObjectsSet<readonly [number, Vector3]>(
			(v) => v[1].hash(), 
			(v1, v2) => Vector3.equal(v1[1], v2[1])
		);
		for (const t of groupIds) {
			let intersections = allIntersections.get(t);
			if (intersections != undefined) {
				for (const int of intersections) {
					uniqueIntesectionPoints.add(Object.freeze(int));
				}
			}
		}

		const groupAdditionalPolygons = new DefaultMap<SId, Vector3[][]>(() => []);
		const groupCircularSectors = new DefaultMap<SId, Vector3[][]>(() => []);

		for (const [sId, int] of uniqueIntesectionPoints) {
			const allSegments = allIntersections.get(sId)!.filter(i => i[1].equals(int)).map(i => i[0]);
			allSegments.push(sId);

			const intersectingSegments: {id: SId, vector: Vector3, segment: SegmentAnalyt, polygon: Vector3[]}[] = [];
			for (const id of allSegments) {
				const segmentWithPolygon = groupSegments.get(id);
				if (segmentWithPolygon === undefined) {
					continue;
				}

				const segment = segmentWithPolygon[0];
				const polygon = segmentWithPolygon[1];
				if (int.equals(segment.from)) {
					const vector = segment.to.clone().sub(segment.from);
					intersectingSegments.push({ id, vector, segment, polygon });
				} else if (int.equals(segment.to)) {
					const vector = segment.from.clone().sub(segment.to);
					intersectingSegments.push({ id, vector, segment, polygon });
				} else {
					const vector = segment.to.clone().sub(segment.from);
					intersectingSegments.push({ id, vector, segment, polygon });
					intersectingSegments.push({ id, vector: vector.clone().negate(), segment, polygon });
				}
			}
			intersectingSegments.sort((is1, is2) =>
				is1.vector.xy().angle() > is2.vector.xy().angle() ? 1 : -1
			);

			if (intersectingSegments.length === 0) {
				continue;
			}

			let {id, segment} = intersectingSegments[0];
			let up = segment.up();
			let allUpEquals = true;

			for (let i = 1; i < intersectingSegments.length; ++i) {
				let temp = intersectingSegments[i].segment.up();
				if (!(temp.distanceToSquared(up) < 0.000001)) {
					allUpEquals = false;
					break;
				}
			}
			
			let angle: number;
			for (let i = 0; i < intersectingSegments.length - 1; i++) {
				angle = intersectingSegments[i + 1].vector.xy().angle() - intersectingSegments[i].vector.xy().angle();
				if (angle >= Math.PI - AngleEps || (angle < AngleEps && angle >= -Math.PI - AngleEps)) {
					if (Math.abs(Math.abs(angle) - Math.PI) > AngleEps) {
						createCircularSector(i, i + 1);
					}
				} else {
					fitPolygonsIntersection(i, i + 1);
				}
			}
			angle = intersectingSegments[0].vector.xy().angle()
				- intersectingSegments[intersectingSegments.length - 1].vector.xy().angle();
			if (angle >= Math.PI - AngleEps || (angle < AngleEps && angle >= -Math.PI - AngleEps)) {
				if (Math.abs(Math.abs(angle) - Math.PI) > AngleEps) {
					createCircularSector(intersectingSegments.length - 1, 0);
				}
			} else {
				fitPolygonsIntersection(intersectingSegments.length - 1, 0);
			}

			function fitPolygon(polygon: Vector3[], intPoint: Vector3, sidePoint: Vector3): void {
				let i: number, j = -1;
				for (i = 0; i < polygon.length; i++) {
					j = (i + 1 < polygon.length ? i + 1 : 0);
					if (sidePoint.distanceToLine(polygon[i], polygon[j]) < 0.01 &&
						sidePoint.distanceTo(polygon[i]) + sidePoint.distanceTo(polygon[j]) < 0.01 + polygon[i].distanceTo(polygon[j])) {
						break;
					}
				}
				if (i === polygon.length) {
					return;
				}

				const ii = (i > 0 ? i - 1 : polygon.length - 1);
				const jj = (j + 1 < polygon.length ? j + 1 : 0);
				if (intPoint.distanceToLine(polygon[ii], polygon[i]) < 0.01) {
					polygon[i] = sidePoint;
					if (intPoint.distanceToSquared(polygon[ii]) > 0.0001) {
						polygon.splice(i, 0, intPoint);
					}
				} else if (intPoint.distanceToLine(polygon[j], polygon[jj]) < 0.01) {
					polygon[j] = sidePoint;
					if (intPoint.distanceToSquared(polygon[jj]) > 0.0001) {
						polygon.splice(j + 1, 0, intPoint);
					}
				} else {
					const hypo = intPoint.clone().sub(polygon[i]);
					const sideVector = polygon[j].clone().sub(polygon[i]);
					const intPointProjection = polygon[i].clone().add(
						sideVector.multiplyScalar(hypo.dot(sideVector) / sideVector.lengthSq())
					);
					if (intPointProjection.distanceToSquared(polygon[i]) < sidePoint.distanceToSquared(polygon[i])) {
						polygon.splice(i + 1, 0, intPointProjection, intPoint, sidePoint);
					} else {
						polygon.splice(i + 1, 0, sidePoint, intPoint, intPointProjection);
					}
				}
			}

			function fitPolygonsIntersection(i1: number, i2: number): void {
				const { vector: sv1, segment: s1, polygon: poly1 } = intersectingSegments[i1];
				const { vector: sv2, segment: s2, polygon: poly2 } = intersectingSegments[i2];

				const pr1 = new Vector3(sv1.x, sv1.y, 0);
				const pr2 = new Vector3(sv2.x, sv2.y, 0);

				const nsv1 = sv1.clone().multiplyScalar(1 / pr1.length());
				const nsv2 = sv2.clone().multiplyScalar(1 / pr2.length());

				const cosinus = pr1.dot(pr2) / (pr1.length() * pr2.length());
				const sinus = Math.abs(Vector3.crossVectors(pr1, pr2).z) / (pr1.length() * pr2.length());

				const edgeLengths = [0.5 * pr1.length() - (s1.width * cosinus + s2.width) / (2 * sinus),
					0.5 * pr2.length() - (s2.width * cosinus + s1.width) / (2 * sinus)];

				let temp = int.clone().add(nsv1.clone().multiplyScalar(s2.width).add(
					nsv2.clone().multiplyScalar(s1.width)).multiplyScalar(1 / (2 * sinus)));

				if (allUpEquals) {
					fitPolygon(poly1, int, temp);
					fitPolygon(poly2, int, temp);
				} else {
					if (edgeLengths[0] < 0 || edgeLengths[1] < 0) {
						return;
					}

					const additionalPolygon = [int.clone(), int.clone(), temp, int.clone()];

					additionalPolygon[1].add(
						nsv1.clone().multiplyScalar((s1.width * cosinus + s2.width) / (2 * sinus)));
					temp = new Vector3(additionalPolygon[2].x - additionalPolygon[1].x, additionalPolygon[2].y - additionalPolygon[1].y, 0);
					additionalPolygon[1].add(temp);
					additionalPolygon[1].add(nsv1.clone().multiplyScalar(
						Math.min(0.5 * s1.width, edgeLengths[0])));

					additionalPolygon[3].add(
						nsv2.clone().multiplyScalar((s2.width * cosinus + s1.width) / (2 * sinus)));
					temp = new Vector3(additionalPolygon[2].x - additionalPolygon[3].x, additionalPolygon[2].y - additionalPolygon[3].y, 0);
					additionalPolygon[3].add(temp);
					additionalPolygon[3].add(nsv2.clone().multiplyScalar(
						Math.min(0.5 * s2.width, edgeLengths[1])));

					additionalPolygon[2].z = (
						additionalPolygon[0].z * (
							(additionalPolygon[3].x - additionalPolygon[2].x) * additionalPolygon[1].y +
							(additionalPolygon[1].x - additionalPolygon[3].x) * additionalPolygon[2].y +
							(additionalPolygon[2].x - additionalPolygon[1].x) * additionalPolygon[3].y
						) +
						additionalPolygon[1].z * (
							(additionalPolygon[2].x - additionalPolygon[3].x) * additionalPolygon[0].y +
							(additionalPolygon[3].x - additionalPolygon[0].x) * additionalPolygon[2].y +
							(additionalPolygon[0].x - additionalPolygon[2].x) * additionalPolygon[3].y
						) +
						additionalPolygon[3].z * (
							(additionalPolygon[1].x - additionalPolygon[2].x) * additionalPolygon[0].y +
							(additionalPolygon[2].x - additionalPolygon[0].x) * additionalPolygon[1].y +
							(additionalPolygon[0].x - additionalPolygon[1].x) * additionalPolygon[2].y
						)
					) / (
						(additionalPolygon[1].x - additionalPolygon[3].x) * additionalPolygon[0].y +
						(additionalPolygon[3].x - additionalPolygon[0].x) * additionalPolygon[1].y +
						(additionalPolygon[0].x - additionalPolygon[1].x) * additionalPolygon[3].y
					);

					fitPolygon(poly1, int, additionalPolygon[1]);
					fitPolygon(poly2, int, additionalPolygon[3]);

					groupAdditionalPolygons.getOrCreate(id).push(additionalPolygon);
				}
			}

			function createCircularSector(i1: number, i2: number): void {
				const { vector: sv1, segment: s1 } = intersectingSegments[i1];
				const { vector: sv2, segment: s2 } = intersectingSegments[i2];

				const pr1 = new Vector3(sv1.x, sv1.y, 0).normalize();
				const pr2 = new Vector3(sv2.x, sv2.y, 0).normalize();

				const from = new Vector2(-pr1.y, pr1.x).multiplyScalar(0.5 * s1.width);
				const to = new Vector2(pr2.y, -pr2.x).multiplyScalar(0.5 * s2.width);
				let sectorAngle = to.angle() - from.angle();
				if (sectorAngle < 0) {
					sectorAngle += 2 * Math.PI;
				}
				const segmentsCount = Math.round(Math.max(Math.max(s1.width, s2.width) * sectorAngle, 1));
				const lengthDiff = (s2.width - s1.width) / s1.width;
				const tStep = sectorAngle / segmentsCount;

				const circularSector: Vector3[] = [int];
				for (let t = 0; t < sectorAngle + 0.5 * tStep; t += tStep) {
					const cos = Math.cos(t);
					const sin = Math.sin(t);
					const point = new Vector2(from.x * cos - from.y * sin, from.x * sin + from.y * cos)
						.multiplyScalar(1 + t * lengthDiff / sectorAngle);
					circularSector.push(new Vector3(int.x + point.x, int.y + point.y, int.z));
				}

				groupCircularSectors.getOrCreate(id).push(circularSector);
			}
		}

		let solution = new DefaultMap<SId, ClipperPoint[][]>(() => []);
		for (const [id, additionalPolygons] of groupAdditionalPolygons) {
			let sol = solution.getOrCreate(id);
			for (const polygon of additionalPolygons) {
				let polygonClipperPoints = [polygon.map(mapToClipperPoint)];
				IterUtils.extendArray(sol, polygonClipperPoints);
			}
		}

		for (const [id, [segment, polygon]] of groupSegments) {
			const c = new ClipperLib.Clipper();
			c.ZFillFunction = Clipper.DefaultZFillFunction;

			let segmentClipperPoints = [polygon.map(mapToClipperPoint)];

			c.AddPaths(segmentClipperPoints, ClipperLib.PolyType.ptSubject, true);

			for (const [i, [s, _]] of groupSegments) {
				const point = segment.centerLineIntersects(s);
				if (point &&
					point.distanceToSquared(segment.from) > 0.0001 &&
					point.distanceToSquared(segment.to) > 0.0001 &&
					solution.has(i)) {

					c.AddPaths(solution.get(i), ClipperLib.PolyType.ptClip, true);
				}
			}

			const segmentSolution = new ClipperLib.Paths();
			c.Execute(ClipperLib.ClipType.ctDifference, segmentSolution, ClipperLib.PolyFillType.pftNonZero, ClipperLib.PolyFillType.pftNonZero);

			let sol = solution.getOrCreate(id);
			IterUtils.extendArray(sol, segmentSolution);
		}

		for (const [id, circularSectors] of groupCircularSectors.entries()) {
			let sol = solution.getOrCreate(id);
			for (const polygon of circularSectors) {
				let polygonClipperPoints = [polygon.map(mapToClipperPoint)];
				IterUtils.extendArray(sol, polygonClipperPoints);
			}
		}

		for (const [id, polygons] of solution) {
			const polygonsFromClipper = polygons.map(polygon => polygon.map(mapFromClipperPoint));
			allRepresentationsResult.set(id, { polygons: polygonsFromClipper.map(poly => Vector3.arrToFloatArr(poly)) });
		}
	}

	const segmentBoundsReused = Aabb.empty();
	const triangulationBoundsReused = Aabb.empty();
	for (const [id, analytSource] of segmentsPerId) {

		const caclulatedSegment = allRepresentationsResult.get(id);

		let isCalculatedSegmentValid = caclulatedSegment !== undefined;

		if (caclulatedSegment !== undefined) {
			triangulationBoundsReused.makeEmpty();
			for (const polygon of caclulatedSegment.polygons) {
				triangulationBoundsReused.expandByFlatArray(polygon);
			}

			segmentBoundsReused.makeEmpty();
			segmentBoundsReused.expandByPoint(analytSource.from);
			segmentBoundsReused.expandByPoint(analytSource.to);


			if (segmentBoundsReused.getSize().length() / triangulationBoundsReused.getSize().length() > 2) {
				logger.batchedError('erroneous triangulation, triangulation seems too small', [analytSource, caclulatedSegment])
				isCalculatedSegmentValid = false;
			} else {
				segmentBoundsReused.expandByScalar(analytSource.width * 2);
				if (!segmentBoundsReused.containsBox(triangulationBoundsReused)) {
					logger.batchedWarn('erroneous triangulation, bounds too big', [analytSource, caclulatedSegment])
					isCalculatedSegmentValid = false;
				}
			}

		}

		if (!isCalculatedSegmentValid) {
			allRepresentationsResult.set(id, {polygons: [Vector3.arrToFloatArr(analytSource.segmentPolygonPoints())]});
		} else {
			// allRepresentationsResult.delete(id);
		}
	}

	return allRepresentationsResult;
}



function calculateCirclePoints(segmentsN: number, radius: number, center: Vector3, up: Vector3): Vector3[] {
	LegacyLogger.assert(segmentsN > 2, 'should be more than 2 segments in circe');
	const upRotation = Quaternion.fromUnitVectors(Vec3Z, up);
	if (up.z < 0) {
		console.warn('circle up is down', up);
	}
	const points: Vector3[] = [];
	for (let i = 0; i < segmentsN; ++i){
		const angle = - Math.PI * 2 / segmentsN * i;
		const x = Math.sin(angle) * radius;
		const y = Math.cos(angle) * radius;
		const offset = new Vector3(x, y, 0);
		offset.applyQuaternion(upRotation);
		points.push(offset.add(center));
	}
	return points;
}


function calculateIntersectionsBetweenSegments(segments: SegmentAnalyt[]) {
	const allIntersections = new DefaultMap<SId, [SId, Vector3][]>(() => []);

	for (let i = 0; i < segments.length; ++i) {

		const s1 = segments[i];

		for (let j = i + 1; j < segments.length; ++j) {
			const s2 = segments[j];

			let intersectionPoint: Vector3 | null;
			if (s1.from.equals(s2.from) || s1.from.equals(s2.to)) {
				intersectionPoint = s1.from.clone();
			} else if (s1.to.equals(s2.from) || s1.to.equals(s2.to)) {
				intersectionPoint = s1.to.clone();
			} else {
				intersectionPoint = s1.centerLineIntersects(s2);
			}
			
			if (intersectionPoint) {
				allIntersections.getOrCreate(s1.id).push([s2.id, intersectionPoint]);
				allIntersections.getOrCreate(s2.id).push([s1.id, intersectionPoint])
			}
		}
	}

	return allIntersections;
}


function simplifyPolylines(
	segmentsPerId: Map<number, SegmentAnalyt>, 
	allIntersections: DefaultMap<SId, [SId, Vector3][]>
) {
	//console.log(segmentsPerId.size + " road segments total");

	let minDistanceToLine = 0.01;

	while (true) {
		let deletedCount = 0;
		
		for (const [id, intersections] of allIntersections) {
			if (intersections.length !== 2) {
				continue;
			}

			let inters1 = intersections[0];
			let inters2 = intersections[1];

			const segment = segmentsPerId.get(id)!;
			let line: [Vector3, Vector3] = [new Vector3(), new Vector3()];
			let segment1 = segmentsPerId.get(inters1[0])!;
			let segment2 = segmentsPerId.get(inters2[0])!;
			if (inters1[1].equals(segment.from) && inters2[1].equals(segment.to)) {
				if (segment1.from.equals(segment.from)) {
					segment1.to.copyTo(line[0]);
				} else if (segment1.to.equals(segment.from)) {
					segment1.from.copyTo(line[0]);
				} else {
					continue;
				}

				if (segment2.from.equals(segment.to)) {
					segment2.to.copyTo(line[1]);
				} else if (segment2.to.equals(segment.to)) {
					segment2.from.copyTo(line[1]);
				} else {
					continue;
				}
			} else if (inters1[1].equals(segment.to) && inters2[1].equals(segment.from)) {
				if (segment1.from.equals(segment.to)) {
					segment1.to.copyTo(line[1]);
				} else if (segment1.to.equals(segment.to)) {
					segment1.from.copyTo(line[1]);
				} else {
					continue;
				}

				if (segment2.from.equals(segment.from)) {
					segment2.to.copyTo(line[0]);
				} else if (segment2.to.equals(segment.from)) {
					segment2.from.copyTo(line[0]);
				} else {
					continue;
				}

				[segment1, segment2] = [segment2, segment1];
				[inters1, inters2] = [inters2, inters1];
			} else {
				continue;
			}
			
			function updateIntersections(newInters: [number, Vector3][], intersId: number, endPoint: Vector3, exclude: number) {
				const segmentIntersections = allIntersections.get(intersId)!;
				for (const [sId, point] of segmentIntersections) {
					if (sId === id || sId === exclude) {
						continue;
					}

					const oldInt = allIntersections.get(sId)!;
					oldInt.splice(oldInt.findIndex(i => i[0] === intersId), 1);
					
					const newSegmentInt = oldInt.findIndex(i => i[0] === newSegment.id);
					if (newSegmentInt >= 0) {
						oldInt.splice(newSegmentInt, 1);
					}
					
					if (point.equals(endPoint)) {
						newInters.push([sId, point]);
						oldInt.push([newSegment.id, point]);
					} else {
						const s = segmentsPerId.get(sId)!;
						const intersectionPoint = s.centerLineIntersects(newSegment);
						if (intersectionPoint) {
							newInters.push([sId, intersectionPoint]);
							oldInt.push([newSegment.id, intersectionPoint])
						}
					}
				}
				allIntersections.delete(intersId);
			}

			let newSegment: SegmentAnalyt;
			if (segment.from.distanceToLine(line[0], line[1]) < minDistanceToLine && 
				segment.to.distanceToLine(line[0], line[1]) < minDistanceToLine) {

				allIntersections.set(segment.id, []);
				newSegment = new SegmentAnalyt(segment.id, line[0], line[1], segment.width);
				const newInters = allIntersections.get(newSegment.id)!;
				
				updateIntersections(newInters, inters1[0], line[0], inters2[0]);
				updateIntersections(newInters, inters2[0], line[1], inters1[0]);

				segmentsPerId.delete(inters1[0]);
				segmentsPerId.delete(inters2[0]);
				segmentsPerId.set(id, newSegment);

				deletedCount += 2;
			} else if (segment.from.distanceToLine(line[0], segment.to) < minDistanceToLine) {
				allIntersections.set(segment.id, []);
				const distances = [
					segment.to.distanceToSquared(line[0]),
					segment.from.distanceToSquared(line[0]),
					segment.from.distanceToSquared(segment.to)
				];
				if (distances[0] < distances[1]) {
					if (distances[1] < distances[2]) {
						newSegment = new SegmentAnalyt(segment.id, segment.to, segment.from, segment.width);
					} else {
						newSegment = new SegmentAnalyt(segment.id, line[0], segment.from, segment.width);
					}
				} else {
					if (distances[0] < distances[2]) {
						newSegment = new SegmentAnalyt(segment.id, segment.to, segment.from, segment.width);
					} else {
						newSegment = new SegmentAnalyt(segment.id, line[0], segment.to, segment.width);
					}
				}
				newSegment = new SegmentAnalyt(segment.id, line[0], segment.to, segment.width);
				const newInters = allIntersections.get(newSegment.id)!;
				
				updateIntersections(newInters, inters1[0], line[0], inters2[0]);
				
				const oldInt = allIntersections.get(inters2[0])!;

				const segment1Int = oldInt.findIndex(i => i[0] === inters1[0]);
				if (segment1Int >= 0) {
					oldInt.splice(oldInt.findIndex(i => i[0] === inters1[0]), 1);
				}
				
				if (!inters2[1].equals(newSegment.from) && !inters2[1].equals(newSegment.to)) {
					oldInt.splice(oldInt.findIndex(i => i[0] === newSegment.id), 1);
					const intersectionPoint = newSegment.centerLineIntersects(segment2);
					if (intersectionPoint) {
						newInters.push([inters2[0], intersectionPoint]);
						oldInt.push([newSegment.id, intersectionPoint]);
					}
				} else {
					newInters.push([inters2[0], inters2[1]]);
				}				

				segmentsPerId.delete(inters1[0]);
				segmentsPerId.set(id, newSegment);

				deletedCount += 1;
			} else if (segment.to.distanceToLine(segment.from, line[1]) < minDistanceToLine) {
				allIntersections.set(segment.id, []);
				const distances = [
					segment.from.distanceToSquared(line[1]),
					segment.to.distanceToSquared(line[1]),
					segment.from.distanceToSquared(segment.to)
				];
				if (distances[0] < distances[1]) {
					if (distances[1] < distances[2]) {
						newSegment = new SegmentAnalyt(segment.id, segment.from, segment.to, segment.width);
					} else {
						newSegment = new SegmentAnalyt(segment.id, segment.to, line[1], segment.width);
					}
				} else {
					if (distances[0] < distances[2]) {
						newSegment = new SegmentAnalyt(segment.id, segment.from, segment.to, segment.width);
					} else {
						newSegment = new SegmentAnalyt(segment.id, segment.from, line[1], segment.width);
					}
				}
				const newInters = allIntersections.get(newSegment.id)!;
				
				updateIntersections(newInters, inters2[0], line[1], inters1[0]);
				
				const oldInt = allIntersections.get(inters1[0])!;
				
				const segment2Int = oldInt.findIndex(i => i[0] === inters2[0]);
				if (segment2Int >= 0) {
					oldInt.splice(segment2Int, 1);
				}
				
				if (!inters1[1].equals(newSegment.from) && !inters1[1].equals(newSegment.to)) {
					oldInt.splice(oldInt.findIndex(i => i[0] === newSegment.id), 1);
					const intersectionPoint = newSegment.centerLineIntersects(segment1);
					if (intersectionPoint) {
						newInters.push([inters1[0], intersectionPoint]);
						oldInt.push([newSegment.id, intersectionPoint]);
					}
				} else {
					newInters.push([inters1[0], inters1[1]]);
				}

				segmentsPerId.delete(inters2[0]);
				segmentsPerId.set(id, newSegment);

				deletedCount += 1;
			}
		}

		if (deletedCount === 0) {
			if (segmentsPerId.size > 1000) {
				minDistanceToLine *= 2;
			} else {
				return;
			}
		}
		//console.log(deletedCount + " road segments deleted");
	}
}


function calculateGroups(
	segmentsPerId: Map<number, SegmentAnalyt>, 
	allIntersections: DefaultMap<SId, [SId, Vector3][]>
) {
	const allGroups: Set<SId>[] = [];
	const usedSegment = IterUtils.newMapFromIter(segmentsPerId, s => s[1].id, s => false);

	for (const [id, _] of segmentsPerId) {
		if (usedSegment.get(id)!) {
			continue;
		}

		const currGroupIds = new Set<SId>();

		function addAllRelatedSegmentsIds(intersections: [SId, Vector3][]) {
			for (const int of intersections) {
				if (!currGroupIds.has(int[0])) {
					currGroupIds.add(int[0]);
					usedSegment.set(int[0], true);

					let intersections = allIntersections.get(int[0]);
					if (intersections != undefined) {
						addAllRelatedSegmentsIds(intersections);
					}
				}
			}
		};

		currGroupIds.add(id);
		usedSegment.set(id, true);

		let intersections = allIntersections.get(id);
		if (intersections != undefined) {
			addAllRelatedSegmentsIds(intersections);
		}

		allGroups.push(currGroupIds);
	}

	return allGroups;
}