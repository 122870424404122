import { Vector2, Vector4 } from 'math-ts';
import type { ShaderBase } from './ShaderBase';


export const RectSelectorShader: ShaderBase = Object.freeze({
	
	name: 'rectSelection',

	uniforms: {
		'rectStart': { value: new Vector2(0, 0) },
		'rectSize': { value: new Vector2(0, 0) },
		'rectBorderWidth': { value: 6 / 1000 },
		'strokePower': { value: 0 },
		'strokeSize': { value: 20 / 1000 },
		'aspectRatio': { value: 1 },
		'color': { value: new Vector4(0.5, 0.5, 0.5, 0.2) }
	},

	vertexShader:
	`
	uniform vec2 rectStart;
	uniform vec2 rectSize;

	varying vec2 pos;
	
	void main() {
		pos = uv * rectSize + rectStart;
		gl_Position = vec4( pos, 0.5, 1.0 );
	}
	`,

	fragmentShader:
	`
	
	#include <kr_std_uniforms>
	#include <kr_std_vars>

	uniform vec2 rectStart;
	uniform vec2 rectSize;
	uniform float strokeSize;
	uniform float strokePower;
	uniform float aspectRatio;
	uniform float rectBorderWidth;
	uniform vec4 color;

	varying vec2 pos;

	float chessboard(vec2 uv)
	{
		uv = uv / strokeSize;
		float xOffset = step(fract(uv.y), 0.5) * 0.5;
		return step(fract(uv.x + xOffset), 0.5);
	}

	void main() {

		vec2 rectangleCenter = rectStart + rectSize * 0.5;
		vec2 distanceToBorder = abs(abs(pos - rectangleCenter) - rectSize * 0.5);
		float min = min(distanceToBorder.x * aspectRatio, distanceToBorder.y);
		float borderPower = smoothstep(0.0, 1.0, min / rectBorderWidth);

		float stroke = chessboard(vec2(pos.x * aspectRatio, pos.y));
		vec4 borderColor = mix(vec4(0.8), vec4(stroke * 0.8), strokePower);

		vec4 insideColor = vec4(1.0, 1.0, 1.0, 0.1);

		gl_FragColor = mix(borderColor, insideColor, borderPower);

	}
	
	`,
});
