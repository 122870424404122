<script lang="ts">
    import type { PUI_PropertyNodeColor } from "ui-bindings";

    export let property: PUI_PropertyNodeColor;
</script>

<div class="ui-config-property property-row">
    <div class="property-name text-main-medium">
        <div class="property-name-label mdc-typography--body1">
            {property.name}
        </div>
    </div>
    <div class="property-value">
        <div
            style="--color: {property.value}"
            class="edit-field-container colored-property"
        >
            <input
                bind:value={property.value}
                class="config-ui-input-field colored-input"
                type="color"
            />
        </div>
    </div>
</div>
