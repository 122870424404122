import { IterUtils } from 'engine-utils-ts';


export const ModuleTechonologyFactorsB0toB5 = {
    'mtCdTe': [0.86273, -0.038948, -0.012506, 0.098871, 0.084658, -0.0042948],
    'mtSiMono': [0.85914, -0.02088, -0.0058853, 0.12029, 0.026814, -0.001781],
    'mtSiPoly': [0.8409, -0.027539, -0.0079224, 0.1357, 0.038024, -0.0021218],
    'mtCIS': [0.85252, -0.022314, -0.0047216, 0.13666, 0.013342, -0.0008945],
    'mtasiH_Tripple': [1.12094, -0.04762, -0.0083627, -0.10443, 0.098382, -0.0033818],
    'mtasiH_Tandem': [1.12094, -0.04762, -0.0083627, -0.10443, 0.098382, -0.0033818],
    'mtHIT': [0.85914, -0.02088, -0.0058853, 0.12029, 0.026814, -0.001781]
};

let ModuleTechnologyFactorsAverage: number[] | undefined = undefined;

export function choosePvModuleTechnologyCoefssB0toB5(moduleTechnology: string): number[] {
    if (moduleTechnology in ModuleTechonologyFactorsB0toB5) {
        return ModuleTechonologyFactorsB0toB5[moduleTechnology as keyof typeof ModuleTechonologyFactorsB0toB5];
    } else {
        console.warn('unknown module technology', moduleTechnology);
        if (ModuleTechnologyFactorsAverage === undefined) {
            ModuleTechnologyFactorsAverage = IterUtils.map7(
                ModuleTechonologyFactorsB0toB5.mtCIS,
                ModuleTechonologyFactorsB0toB5.mtCdTe,
                ModuleTechonologyFactorsB0toB5.mtHIT,
                ModuleTechonologyFactorsB0toB5.mtSiMono,
                ModuleTechonologyFactorsB0toB5.mtSiPoly,
                ModuleTechonologyFactorsB0toB5.mtasiH_Tandem,
                ModuleTechonologyFactorsB0toB5.mtasiH_Tripple,
                (...args: number[]) => {
                    let sum = 0;
                    for (const v of args) {
                        sum += v;
                    }
                    return sum / args.length;
                }
            );
        }
        return ModuleTechnologyFactorsAverage;
    }
}

export function chooseInverterEfficiencyCoeffs(
    efficiencyStandard: string,
    Inverter_maxEfficiency: number,
    Inverter_Efficiency: number,
): {Ltr: number, Ldr: number, Lrr: number} | null {
    if (efficiencyStandard.toUpperCase() === 'CEC') {
        const Ltr = 1200/677*(Inverter_maxEfficiency - Inverter_Efficiency);
        const Ldr = 1 - Inverter_maxEfficiency - 3200/677*(Inverter_maxEfficiency - Inverter_Efficiency);
        const Lrr = 677/2000/(Inverter_maxEfficiency - Inverter_Efficiency);
        return {Ltr, Ldr, Lrr};
    } else if (efficiencyStandard.toUpperCase() === 'EURO') {
        const Ltr = 3600/5071*(Inverter_maxEfficiency - Inverter_Efficiency);
        const Ldr = 1 - 10/3*Ltr - Inverter_maxEfficiency;
        const Lrr = 36/100/Ltr;
        return {Ltr, Ldr, Lrr};
    }
    return null;
}
