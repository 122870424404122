import { IterUtils } from "./IterUtils";
import type { VersionedValue } from "./stateSync/LazyVersioned";
import { LazyDerived } from "./stateSync/LazyVersioned";

export class CachedCalculations<Args, R> {

	private readonly _calculator:  (args: Args) => R;
	private readonly _cachesInvalidator: LazyDerived<void>;
	private readonly _calculationsCache = new Map<string, R>();
	private readonly _stringiifer: (args: Args) => string;
	
	readonly maxCacheSize: number;

	constructor(params: {
		identifier: string,
		calculator: (args: Args) => R,
		argsStringifer?: (args: Args) => string,
		lazyInvalidation: VersionedValue | VersionedValue[],
		maxCacheSize: number
	}) {
		this._calculator = params.calculator;
		this._cachesInvalidator = LazyDerived.new0(
			params.identifier + '-invalidator',
			Array.isArray(params.lazyInvalidation) ? params.lazyInvalidation : [params.lazyInvalidation],
			() => {
				this._calculationsCache.clear();
			}
		).withoutEqCheck();
		this._stringiifer = params.argsStringifer ?? JSON.stringify;
		if (params.maxCacheSize >= 0) {
			this.maxCacheSize = params.maxCacheSize;
		} else {
			console.error(params.identifier, 'invalid cache size', params.maxCacheSize);
			this.maxCacheSize = 1;
		}
	}

	version() {
		return this._cachesInvalidator.version();
	}

	acquire(args: Args): R {
		this._cachesInvalidator.poll();

		const asString = this._stringiifer(args);
		let saved = this._calculationsCache.get(asString);
		if (saved !== undefined) {
			return saved;
		}
		const result = this._calculator(args);
		if (this._calculationsCache.size === this.maxCacheSize) {
			// just delete first inserted, good enough for now
			this._calculationsCache.delete(IterUtils.getFirstFromIter(this._calculationsCache.keys())!);
		}
		this._calculationsCache.set(asString, result);
		return result;
	}
}

