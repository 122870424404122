
export type IdInEntityLocal = number & LocalIdsCounter;

export type LocalIdsEdge = number & LocalIdsCounter; // (lower id) * 0xFF_FFFF | higher id, 24 bits per each id

export class LocalIdsCounter {

	_nextId: number = 0;

	constructor() {
	}

	newIdsArray(count: number): IdInEntityLocal[] {
		const res: IdInEntityLocal[] = [];
		const startFrom = this._nextId;
		for (let i = 0; i < count; ++i) {
			res.push(i + startFrom as IdInEntityLocal);
		}
		this._nextId += count;
		return res;
	}

	nextId(): IdInEntityLocal {
		const id = this._nextId;
		this._nextId += 1;
		return id as IdInEntityLocal;
	}

	
    static newEdge(p1Id: IdInEntityLocal, p2Id: IdInEntityLocal): LocalIdsEdge {
        if (p2Id < p1Id) {
            let t = p2Id;
            p2Id = p1Id;
            p1Id = t;
        }
        if (p2Id > 0xFF_FFFF) { // 24 bits maximum per number
            throw new Error('edge id is too large ' + p2Id);
        }
        return ((p1Id & 0xFF_FFFF) * 0x100_0000 + (p2Id & 0xFF_FFFF)) as LocalIdsEdge;
    }
    static edgeToTuple(edge: LocalIdsEdge): [IdInEntityLocal, IdInEntityLocal] {
		const id2 = (edge & 0xFF_FFFF) as IdInEntityLocal;
		const id1 = ((edge - id2) / 0x100_0000) as IdInEntityLocal;
        return [id1, id2];
    }
	static edgeIncludes(edge: LocalIdsEdge, id: IdInEntityLocal): boolean {
		const id2 = (edge & 0xFF_FFFF) as IdInEntityLocal;
		const id1 = ((edge - id2) / 0x100_0000) as IdInEntityLocal;
		return id2 === id || id1 === id;
	}
}
