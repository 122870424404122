import type { SubmeshesCreationOutput, SubmeshesCreationResources } from "./ESSO";
import { ESSO } from "./ESSO";
import type { TextBlockGeometry } from "../geometries/EngineGeoTextBlock";
import type { EngineSubmeshDescription, RenderJobUpdater, SubmeshAllocArgs } from "../scene/Submeshes2";
import { LodMask } from "../scene/Submeshes2";
import { newLodGroupLocalIdent } from "../scene/LodGroups";
import { EngineMaterialId } from "../pools/EngineMaterialId";
import type { EngineFullGraphicsSettings } from "../GraphicsSettingsFull";
import type { ShaderFlags } from "../shaders/ShaderFlags";
import type { UniformsFlat } from "../composer/DynamicUniforms";
import type { FONT_ID} from "../three-mesh-ui/content/FontLibrary";
import { getFontTexture } from "../three-mesh-ui/content/FontLibrary";
import type { RGBAHex } from "engine-utils-ts";
import { RGBA } from "engine-utils-ts";
import type { GeometryGpuRepr } from "../geometries/KrBufferGeometry";
import { Vector4 } from 'math-ts';




export class TextStyleOptions {
    constructor(
        readonly color: RGBAHex = RGBA.new(0, 0, 0, 1),
        readonly opacity: number = 1,
        readonly pxRange: number = 4,
        readonly useRGSS: boolean = true
    ) { };

    clone(): TextStyleOptions {
        return new TextStyleOptions(
            this.color,
            this.opacity,
            this.pxRange,
            this.useRGSS
        )
    }
}


export interface ESSO_Text_Repr { 
    frameRepr: GeometryGpuRepr;
    parasReprs: GeometryGpuRepr[]
};

export class ESSO_TextAnnotation extends ESSO<TextBlockGeometry> {
    createSubmeshes(resoures: SubmeshesCreationResources, output: SubmeshesCreationOutput): void {
        const geoId = resoures.geometries.textAnnotations.allocateOrReferenceSingle(this.repr);

        if(geoId === undefined)
        {
            resoures.logger.batchedError('text annotation geo is absent', this.repr);
            return;
        }

        const allocArgs: SubmeshAllocArgs = {
            id: this.id,
            lodMask: LodMask.All,
            lodGroupLocalIdent: newLodGroupLocalIdent(0, 0),
            subObjectRef: this,
            descr: {
                geoId,
                localTransforms: [this.localTransform],
                materialId: EngineMaterialId.Text,
                mainRenderJobUpdater: new TextRenderJobUpdater(
                    this.repr.textOptions.layoutOptions[0].fontId, this.repr.textOptions.styleOptions.color),
                overlayRenderJobUpdater: new TextOverlayRenderJobUpdater(
                    this.repr.textOptions.layoutOptions[0].fontId, this.repr.textOptions.styleOptions.color
                ),
            }
        };

        output.submeshes.push(allocArgs);
    }
}

export class TextRenderJobUpdater implements RenderJobUpdater{

    constructor(
        readonly fontId: FONT_ID,
        readonly color: RGBAHex,
        readonly shaderFlags: ShaderFlags = 0,
    ) {};

    updaterRenderJob(
        submeshDescription: Readonly<EngineSubmeshDescription>,
        renderSettings: Readonly<EngineFullGraphicsSettings>,
        output: {
            flags: ShaderFlags,
            materialId: EngineMaterialId,
            uniforms: UniformsFlat,
        }
    ) {
		if (submeshDescription.subObjectRef.isHidden) {
            return;
		}

        output.materialId = EngineMaterialId.Text;
        output.flags = this.shaderFlags;
        output.uniforms.push(
            "u_texture", getFontTexture(this.fontId),
            "color_tint_mix", BlackColorMixVector,
            "outline_tint_mix", WhiteOutlineMixVector,
            "alpha_multiplier", 1,
        );
    }
}
const BlackColorMixVector = new Vector4(0, 0, 0, 0.95);
const WhiteOutlineMixVector = new Vector4(1, 1, 1, 0.95);

export class TextOverlayRenderJobUpdater implements RenderJobUpdater{

    constructor(
        readonly fontId: FONT_ID,
        readonly color: RGBAHex,
        readonly shaderFlags: ShaderFlags = 0,
    ) {};

    updaterRenderJob(
        submeshDescription: Readonly<EngineSubmeshDescription>,
        renderSettings: Readonly<EngineFullGraphicsSettings>,
        output: {
            flags: ShaderFlags,
            materialId: EngineMaterialId,
            uniforms: UniformsFlat,
        }
    ) {
        let highlightPower = 0;
		if (submeshDescription.subObjectRef.isHighlighted) {
			highlightPower += 0.35;
		}
		if (submeshDescription.subObjectRef.isSelected) {
			highlightPower += 0.35;
		}
        if (highlightPower == 0) {
            return;
        }

        const highlightColor = RGBA.new(1, 0.55, 0, 1);

        output.materialId = EngineMaterialId.Text;
        output.flags = this.shaderFlags;
        output.uniforms.push(
            "u_texture", getFontTexture(this.fontId),
            "color_tint_mix", RGBA.RGBAHexToVec4(highlightColor),
            "outline_tint_mix", RGBA.RGBAHexToVec4(highlightColor),
            "alpha_multiplier", highlightPower * 0.5,
        );
    }
}