import type { IdEngineMaterial } from '../materials/EngineStdMaterials';
import type { ShaderFlags } from '../shaders/ShaderFlags';


export const enum EngineMaterialIdFlags { };


export function getMaterialIdFromIDF(midf: EngineMaterialIdFlags): IdEngineMaterial {
    const flags = (midf / MaxMaterialId) | 0; 
    return midf - (flags * MaxMaterialId) as IdEngineMaterial;
}

export function GetMaterialFlagsFromIDF(matId: EngineMaterialIdFlags): ShaderFlags {
    return (matId / MaxMaterialId) | 0; 
}

export function GetMaterialIDF(id: IdEngineMaterial | EngineMaterialId, flags: ShaderFlags): EngineMaterialIdFlags {
	if (id < 0 || id > MaxMaterialId) {
        throw new Error("id out of range: " + id);
    }
    flags = flags | 0;
    if (flags > MaxFlags) {
        throw new Error("flags out of range: " + flags);
    }
    return (id + flags * MaxMaterialId) as EngineMaterialIdFlags;
}


// export function IsMaterialTransparent(matId: MaterialId | number) {
// 	const index = matId & 0x7FF;
// 	return index > SpecMatsIds.TransparentMaterialsStart && index != SpecMatsIds.SimpleOpaque; 
// }

export function IsMaterialSelectable(matId: EngineMaterialIdFlags): boolean {
	const matIndex = getMaterialIdFromIDF(matId);
	if (matIndex == 0) {
		return false;
	}
	if (matIndex < EngineMaterialId.SpecialMaterialsStart) {
		return true;
	}
	return !NonSelectableMaterials.includes(matIndex as any);
}


export function materialIdFromIDF(midf: EngineMaterialIdFlags): IdEngineMaterial {
    const flags = (midf / MaxMaterialId) | 0; 
    return midf - (flags * MaxMaterialId) as IdEngineMaterial;
}


//PerTypeIdsRange = 134217728
export const MaxMaterialId = 0x8_000_000 * 4;
export const MaxFlags = 65535;


export const enum EngineMaterialId {
	None 					= 0,
	Default 				= 1,
	
	AutoGeneratedIdsStart 	= 0xFFF,

	// ...std materials up to 0xFFF
	
	SpecialMaterialsStart 		= 134217728 * 2 + 2,
	Ghost 						= 134217728 * 2 + 3,
	Highlight 					= 134217728 * 2 + 4,
	Edge 						= 134217728 * 2 + 6,
	SimpleOpaque 				= 134217728 * 2 + 7,
	SimpleTransparent 			= 134217728 * 2 + 8,
	Depth 						= 134217728 * 2 + 9,
	SceneImage 					= 134217728 * 2 + 10,

	Terrain 					= 134217728 * 2 + 11,
	TerrainBasicTransparent 	= 134217728 * 2 + 12,

	TerrainRegular 				= 134217728 * 2 + 13,
	TerrainRegularBasicTransparent 	= 134217728 * 2 + 14,

	Spatial 					= 134217728 * 2 + 15,
	AnalytPolyline 				= 134217728 * 2 + 16,
	Sprite 						= 134217728 * 2 + 17,
	BasicAnalytical 			= 134217728 * 2 + 18,
	BasicAnalyticalTransparent 	= 134217728 * 2 + 19,

	Text						= 134217728 * 2 + 20,
	TrackersPilesBillboard		= 134217728 * 2 + 21,
}

export const NonSelectableMaterials = [
	EngineMaterialId.Ghost,
	EngineMaterialId.Highlight,
	EngineMaterialId.Spatial,
	EngineMaterialId.TerrainBasicTransparent,
	EngineMaterialId.TerrainRegularBasicTransparent,
	// EngineMaterialId.TerrainBasic,
	// EngineMaterialId.TerrainRegularBasic,
];
export const TransparentMaterials = [
	EngineMaterialId.AnalytPolyline,
	EngineMaterialId.BasicAnalyticalTransparent,
	EngineMaterialId.Ghost,
	EngineMaterialId.Highlight,
	EngineMaterialId.SimpleTransparent,
	EngineMaterialId.Spatial,
	EngineMaterialId.Sprite,
	EngineMaterialId.TerrainBasicTransparent,
	EngineMaterialId.TerrainRegularBasicTransparent,
	EngineMaterialId.Text,
	// EngineMaterialId.TerrainBasic,
	// EngineMaterialId.TerrainRegularBasic,
];

