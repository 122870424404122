import { DefaultMap } from 'engine-utils-ts';
import type {
	ActionDescription} from './ActionsBindings';
import { getModifierFlagsFromKeyboardEvent,
} from './ActionsBindings';

export class HotkeysListener {

    readonly actionsPerKey = new DefaultMap<string, WeakRef<ActionDescription<any>>[]>(_ => []);

    keydownHandler: (e: KeyboardEvent) => void;
    keyUpHandler: (e: KeyboardEvent) => void;

    constructor() {

        this.keydownHandler = (e: KeyboardEvent) => {
            const a = this._getAppropriateKeyActionForEvent(
                e,
                a => a.dynamicArgsDescription == undefined && a.action != undefined
            );
            if (!a) {
                return;
            }
            e.preventDefault();
            a.action!(undefined);
        };
        this.keyUpHandler = (e: KeyboardEvent) => {
            const a = this._getAppropriateKeyActionForEvent(
                e,
                a => a.dynamicArgsDescription == undefined && a.actionOnUp != undefined
            );
            if (!a) {
                return;
            }
            e.preventDefault();
            a.actionOnUp!(undefined);
        };
    }

    private _getAppropriateKeyActionForEvent(e: KeyboardEvent, filter: (a: ActionDescription<any>) => boolean) {
        if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
            return undefined;
        }
        const hotkeyString = `${e.code}|${getModifierFlagsFromKeyboardEvent(e)}`;
        // const hotkeyStringWithAny = `${AnyKey}|${getModifierFlagsFromKeyboardEvent(e)}`;
        const wrappedActions = this.actionsPerKey.get(hotkeyString);
        if (!wrappedActions) {
            return undefined;
        }
        e.preventDefault();
        for (let i = wrappedActions.length - 1; i >= 0; --i) {
            const wa = wrappedActions[i];
            const a = wa.deref();
            if (a === undefined) {
                wrappedActions.splice(i, 1);
            } else if (a.canUseNow.poll() && filter(a)) {
                return a;
            }
        }
        return undefined;
    }

    setActions(actions: ActionDescription<any>[]) {
        this.actionsPerKey.clear();
        for (const a of actions) {
            if (!a.keyCombinations?.length) {
                continue;
            }
            for (const k of a.keyCombinations) {
                const hotkeyString = `${k.keyCode}|${k.modifiers ?? 0}`;
                const actionsWrapped = this.actionsPerKey.getOrCreate(hotkeyString);
                if (actionsWrapped.find(aw => aw.deref() === a)) {
                    console.warn('action added for listening second time, ignoring', a);
                } else {
                    actionsWrapped.push(new WeakRef(a));
                }
                actionsWrapped.sort((a1, a2) => (a2.deref()?.priority ?? 0) - (a1.deref()?.priority ?? 0));
            }
        }
    }

    subscribe() {
        (self as Window).addEventListener('keydown', this.keydownHandler);
        (self as Window).addEventListener('keyup', this.keyUpHandler);

    }

    unsubscribe(): void {
        (self as Window).removeEventListener('keydown', this.keydownHandler);
        (self as Window).removeEventListener('keyup', this.keyUpHandler);
    }
}
