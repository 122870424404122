import { Matrix3, Vector2 } from "math-ts";
import { Anchor2D, CubicBezier, Object2D, VectorPrimitivePath, VectorPrimitivePathDescription, VectorPrimitiveRectangle } from "vector-graphic";

export function createTransformer() {
    const transformer = new Anchor2D()
    transformer.name = 'transformer'

    const frame = new VectorPrimitiveRectangle({
        width: 300,
        height: 240,
        strokeWidth: 8,
        stroke: 'black',
    })
    transformer.addPrimitiveAndExpandAabb(frame);

    const coilSeparator = new VectorPrimitiveRectangle({
        width: 10,
        height: 160,
        fill: 'black'
    })
    transformer.addPrimitiveAndExpandAabb(coilSeparator);

    const coil = createTransformerCoilOneSide()
    coil.name = 'coil'
    const coil2 = coil.clone()
    coil2.rotation = Math.PI;
    coil2.updateMatrix()

    transformer.addAndExpandAabb(coil);
    transformer.addAndExpandAabb(coil2);

    transformer.point.set(frame.aabb.max.x, frame.aabb.centerY())

    return transformer
}

function createTransformerCoilOneSide() {
    const strokeWidth = 4
    const coilCyclesCount = 4
    const coilCycleHeight = 40
    const coilCycleWidth = 40
    const beziers: CubicBezier[] = []
    for (let i = 0; i < coilCyclesCount; i++) {
        beziers.push(new CubicBezier(
            new Vector2(0, i * coilCycleHeight),
            new Vector2(coilCycleWidth, i * coilCycleHeight),
            new Vector2(coilCycleWidth, (i + 1) * coilCycleHeight),
            new Vector2(0, (i + 1) * coilCycleHeight),
        ))
    }
    const primitive = new VectorPrimitivePath({
        paths: [new VectorPrimitivePathDescription(beziers)],
        stroke: 'black',
        strokeWidth,
        fill: 'none',
    })
    const totalHeight = coilCycleHeight * coilCyclesCount;
    primitive.matrix = new Matrix3()
        .translate(-coilCycleWidth, -totalHeight / 2)

    const obj = new Object2D();
    obj.addPrimitiveAndExpandAabb(primitive);
    obj.recalculateAabb(false, true);

    return obj
}
