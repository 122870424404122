import { Matrix4, Vector2 } from 'math-ts';
import type { ShaderBase } from './ShaderBase';


export const SAOShader: ShaderBase = {
	name: 'SAOShader',
	defines: {
		NUM_SAMPLES: 16,
		NUM_RINGS: 3,
		NORMAL_TEXTURE: 1,
		VARIATION: 1,
		PERSPECTIVE_CAMERA: 1
	},
	uniforms: {

		tDepth: { value: null },
		tDiffuse: { value: null },
		opaque_normals: { value: null },

		isOrtho: { value: false },
		cameraNear: { value: 1 },
		cameraFar: { value: 300 },
		cameraProjectionMatrix: { value: new Matrix4() },
		cameraProjectionMatrixInverse: { value: new Matrix4() },

		minResolution: { value: 0.0 },
		kernelRadius: { value: 100.0 },
		randomSeed: { value: 0.0 },

		projScale: { value: 50 },  // how many pixels a 1m size 1m away from the camera is
		uIntensity: { value: 0.1 },
		samplesRadiusMinMax: { value: new Vector2(0.1, 30) },
		uBias: { value: 0.000 },
	},

	vertexShader: `
		varying vec2 vUv;
		varying vec2 size;
		varying vec2 resolution;
		uniform sampler2D tDepth;

		void main() {
			size = vec2(float(textureSize(tDepth, 0).x), float(textureSize(tDepth, 0).y));
			resolution = vec2(1.0) / size;
			vUv = uv;

			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

		}

	`,

	fragmentShader: `
		#include <common>
		
		varying vec2 size;
		varying vec2 resolution;
		varying vec2 vUv;

		uniform sampler2D tDepth;
		uniform sampler2D opaque_normals;

		uniform float cameraNear;
		uniform float cameraFar;
		uniform mat4 cameraProjectionMatrix;
		uniform mat4 cameraProjectionMatrixInverse;

		uniform float bias;
		uniform float minResolution;
		uniform float kernelRadius;
		uniform float randomSeed;

		uniform bool isOrtho;

		
		uniform float projScale;
		uniform float uIntensity;
		uniform vec2  uNoiseScale;
		uniform vec2 samplesRadiusMinMax;
		uniform float uBias;

		// RGBA depth
		#include <packing>
		#include <normals_packing>

		float getDepth( const in vec2 screenPosition ) {
			return texture2D( tDepth, screenPosition ).x;
		}

		float getViewZ( const in float depth ) {
			#if PERSPECTIVE_CAMERA == 1
				return perspectiveDepthToViewZ( depth, cameraNear, cameraFar );
			#else
				return orthoDepthToViewZ( depth, cameraNear, cameraFar );
			#endif
		}

		vec3 getViewPosition( const in vec2 screenPosition, const in float depth, const in float viewZ ) {
			float clipW = cameraProjectionMatrix[2][3] * viewZ + cameraProjectionMatrix[3][3];
			vec4 clipPosition = vec4( ( vec3( screenPosition, depth ) - 0.5 ) * 2.0, 1.0 );
			clipPosition *= clipW; // unprojection.

			return ( cameraProjectionMatrixInverse * clipPosition ).xyz;
		}

		#define MOD3 vec3(.1031,.11369,.13787)

		float rand2D(in vec2 co){
			return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
		}

		vec2 hash22(vec2 p)
		{
			vec3 p3 = fract(vec3(p.xyx) * MOD3);
			p3 += dot(p3, p3.yzx+19.19);
			return fract(vec2((p3.x + p3.y)*p3.z, (p3.x+p3.z)*p3.y));
		}

		vec3 getNormal(vec2 uv) {
			return decode_normal(texture2D(opaque_normals, uv).xy);
		}

		vec3 getPositionVS(vec2 uv) {
			float depth = getDepth( uv );
			float centerViewZ = getViewZ( depth );
			return getViewPosition( uv, depth, centerViewZ );
		}
		
		// returns a unit vector and a screen-space radius for the tap on a unit disk 
		// (the caller should scale by the actual disk radius)
		vec2 tapLocation(int sampleNumber, float spinAngle, out float radiusSS) {
			// radius relative to radiusSS
			float alpha = (float(sampleNumber) + 0.5) * (1.0 / float(NUM_SAMPLES));
			float angle = alpha * (float(NUM_RINGS) * 6.28) + spinAngle;
			
			radiusSS = alpha;
			return vec2(cos(angle), sin(angle));
		}
		
		vec3 getOffsetPositionVS(vec2 uv, vec2 unitOffset, float radiusSS) {
			uv = uv + radiusSS * unitOffset * resolution;
			return getPositionVS(uv);
		}
		

		float sampleAO(float radiusWS, vec2 uv, vec3 positionVS, vec3 normalVS, float sampleRadiusSS, int tapIndex, float rotationAngle) {
			const float epsilon = 0.01;
			float radius2 = radiusWS * radiusWS;

			// offset on the unit disk, spun for this pixel
			float radiusSS;
			vec2 unitOffset = tapLocation(tapIndex, rotationAngle, radiusSS);
			radiusSS *= sampleRadiusSS;

			vec3 Q = getOffsetPositionVS(uv, unitOffset, radiusSS);
			vec3 v = Q - positionVS;

			float vv = dot(v, v);
			float vn = dot(v, normalVS) - uBias;

			// #if VARIATION == 0

			// // (from the HPG12 paper)
			// // Note large epsilon to avoid overdarkening within cracks
			// return float(vv < radius2) * max(vn / (epsilon + vv), 0.0);

			// #elif VARIATION == 1 // default / recommended

			// // Smoother transition to zero (lowers contrast, smoothing out corners). [Recommended]
			float f = max(radius2 - vv, 0.0) / radius2;
			return f * f * f * max(vn / (epsilon + vv), 0.0);

			// #elif VARIATION == 2

			// // Medium contrast (which looks better at high radii), no division.  Note that the 
			// // contribution still falls off with radius^2, but we've adjusted the rate in a way that is
			// // more computationally efficient and happens to be aesthetically pleasing.
			// float invRadius2 = 1.0 / radius2;
			// return 4.0 * max(1.0 - vv * invRadius2, 0.0) * max(vn, 0.0);

			// #else

			// // Low contrast, no division operation
			// return 2.0 * float(vv < radius2) * max(vn, 0.0);

			// #endif
		}


		float CSZToKey(float z) {
			return clamp( (z+cameraNear) / (cameraNear-cameraFar), 0.0, 1.0);
		}

		void packKey(float key, out vec2 p) {
			float temp = floor(key * 256.0);
			p.x = temp * (1.0 / 256.0);
			p.y = key * 256.0 - temp;
		}

		void main() {

			float centerDepth = getDepth( vUv );
			if( centerDepth >= ( 1.0 - EPSILON ) ) {
				gl_FragColor = vec4(0.0, 0.0, 0.0, 1.0);
			} else {

				float centerViewZ = getViewZ( centerDepth );
				vec3 originVS = getViewPosition( vUv, centerDepth, centerViewZ );
  
				vec3 normalVS = getNormal(vUv);
				
				float sampleNoise = rand2D(vUv);
				
				float randomPatternRotationAngle = 2.0 * PI * sampleNoise;
				
				float radiusWS;
				float radiusSS;
				if (isOrtho) {
					radiusWS = samplesRadiusMinMax.x;
					radiusSS = projScale * radiusWS; // radius of influence in screen space
				} else {
					radiusWS = mix(samplesRadiusMinMax.x, samplesRadiusMinMax.y, min(-originVS.z * 0.01, 1.0));
					radiusSS = projScale * radiusWS / -originVS.z; // radius of influence in screen space
				}
				
				float occlusion = 0.0;

				for (int i = 0; i < NUM_SAMPLES; ++i) {
					occlusion += sampleAO(radiusWS, vUv, originVS, normalVS, radiusSS, i, randomPatternRotationAngle);
				}
				
				occlusion = occlusion / (4.0 * float(NUM_SAMPLES));

				gl_FragColor.r = occlusion;
				// gl_FragColor.a = 1.0;
				// packKey(CSZToKey(centerViewZ), gl_FragColor.gb);
			}
		}


		
	`
};
