import type { JobExecutor, JobsArgsCachingDescription} from 'engine-utils-ts';
import { CompressionUtils, ExecutionThreadPreference, GunzipExecutor, GzipExecutor, registerExecutor, WorkerPool } from 'engine-utils-ts';
import type * as flatbuffers from 'flatbuffers';
import type { ObjectsPickingSerializer } from './VerDataPersistedCollection';

import { CompressedIntegers } from './wire/compressed-integers';
import { CompressionType } from './wire/compression-type';


export function decompressIntegers(ct: CompressionType, buffer: Uint8Array) {
	if (ct === CompressionType.Gzip) {
		buffer = CompressionUtils.gunzip(buffer);
	} else if (ct !== CompressionType.None) {
		throw new Error('unrecognized comression type:' + ct);
	}
	const integers = new Uint32Array(buffer.buffer, buffer.byteOffset, buffer.byteLength / 4);
	return Array.from(integers);
}

export function compressIntegers(ints: number[]|Uint32Array): { compressionType: CompressionType; buffer: ArrayBuffer; } {
	let compressionType: CompressionType;
	let intsBuffer: ArrayBuffer;
	if (ints.length < 200) {
		compressionType = CompressionType.None;
		intsBuffer = new Uint32Array(ints).buffer;
	} else {
		compressionType = CompressionType.Gzip;
		intsBuffer = CompressionUtils.gzip(new Uint32Array(ints).buffer).buffer;
	} 
	return {compressionType: compressionType, buffer: intsBuffer};
}

export function compressIntegersToFlatbuf(builder: flatbuffers.Builder, ints: number[]): number {
	const {compressionType, buffer} = compressIntegers(ints);
	return CompressedIntegers.createCompressedIntegers(
		builder,
		compressionType,
		CompressedIntegers.createPayloadVector(builder, new Uint8Array(buffer)),
	);
}


export function dateToFlatbufLong(date: Date): bigint {
	return BigInt(date.getTime());
}

export function dateFromFlatbufLong(utcMs: bigint): Date {
	return new Date(Number(utcMs));
}

export function compressBinarySync(binary: Uint8Array): [CompressionType, Uint8Array] {
	if (binary.length > 2000) {
		const compressed = CompressionUtils.gzip(binary);
		return [
			CompressionType.Gzip,
			compressed
		]
	} else {
		return [
			CompressionType.None,
			binary
		]
	};
}

export async function compressBinaryAsync(binary: Uint8Array): Promise<[CompressionType, Uint8Array]> {
	if (binary.length > 2000) {
		const compressed = await WorkerPool.execute(GzipExecutor, binary);
		return [
			CompressionType.Gzip,
			compressed
		]
	} else {
		return [
			CompressionType.None,
			binary
		]
	};
}


export function decompressBinary(ct: CompressionType, binary: Uint8Array): Uint8Array {
	if (ct == CompressionType.Gzip) {
		return CompressionUtils.gunzip(binary);
	} else {
		return binary
	}
}

export function decompressBinaryAsync(ct: CompressionType, binary: Uint8Array): Promise<Uint8Array> {
	if (ct == CompressionType.Gzip) {
		return WorkerPool.execute(GunzipExecutor, binary);
	} else {
		return Promise.resolve(binary);
	}
}

export function decompressAndDeserialize<T>(args: {
	compressionType: CompressionType,
	binary: Uint8Array,
	serializer: ObjectsPickingSerializer<T>,
	objsIdsToDeserialize: number[],
	inWorkerPool: boolean,
}): Promise<Map<number, T|null>> {

	if (args.inWorkerPool) {
		const jobArgs: InWorkerDeserializationArgs<T> = {
			binary: args.binary,
			binaryCompressionType: args.compressionType,
			ids: args.objsIdsToDeserialize,
			serializer: args.serializer,
		};
		return WorkerPool.execute(DeserializationJobExecutor<T>, jobArgs);
	} else {
		const decompressed = decompressBinary(args.compressionType, args.binary);
		const idsSet = new Set(args.objsIdsToDeserialize);
		const tuples = args.serializer.deserialize(decompressed, idsSet);
		return Promise.resolve(new Map(tuples));
	}
}

interface InWorkerDeserializationArgs<T> {
	serializer: ObjectsPickingSerializer<T>;
	binary: Uint8Array;
	binaryCompressionType: CompressionType;
	ids: number[];
}

export class DeserializationJobExecutor<T> implements JobExecutor<InWorkerDeserializationArgs<T>, Map<number, T|null>> {

	execute(args: InWorkerDeserializationArgs<T>): Map<number, T|null> {
		const decompressed = decompressBinary(args.binaryCompressionType, args.binary);
		const deserialized = args.serializer.deserialize(decompressed, new Set(args.ids));
		return new Map(deserialized);
	}
	executionPreference(_args: InWorkerDeserializationArgs<T>): ExecutionThreadPreference {
		return ExecutionThreadPreference.WorkerThread;
	}
	argsCacheKey(_args: InWorkerDeserializationArgs<T>): JobsArgsCachingDescription<InWorkerDeserializationArgs<T>> | null {
		return null;
	}
	estimateTaskDurationMs(args: InWorkerDeserializationArgs<T>): number {
		return args.binary.length / 3_000;
	}
	transferToWorker(args: InWorkerDeserializationArgs<T>) {
		return [args.binary.buffer];
	}
}
registerExecutor(DeserializationJobExecutor);
