// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { BimPropertyValue, unionToBimPropertyValue } from './bim-property-value.js';
import type { BoolValueT } from './bool-value.js';
import type { NumericValueT } from './numeric-value.js';
import type { StringValueT } from './string-value.js';


export class BimProperty implements flatbuffers.IUnpackableObject<BimPropertyT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):BimProperty {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsBimProperty(bb:flatbuffers.ByteBuffer, obj?:BimProperty):BimProperty {
  return (obj || new BimProperty()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsBimProperty(bb:flatbuffers.ByteBuffer, obj?:BimProperty):BimProperty {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new BimProperty()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

path(index: number):string
path(index: number,optionalEncoding:flatbuffers.Encoding):string|Uint8Array
path(index: number,optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
}

pathLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

description():string|null
description(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
description(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

computedBy():string|null
computedBy(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
computedBy(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

valueType():BimPropertyValue {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : BimPropertyValue.NONE;
}

value<T extends flatbuffers.Table>(obj:any):any|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
}

readonly():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

static startBimProperty(builder:flatbuffers.Builder) {
  builder.startObject(6);
}

static addPath(builder:flatbuffers.Builder, pathOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, pathOffset, 0);
}

static createPathVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startPathVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addDescription(builder:flatbuffers.Builder, descriptionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, descriptionOffset, 0);
}

static addComputedBy(builder:flatbuffers.Builder, computedByOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, computedByOffset, 0);
}

static addValueType(builder:flatbuffers.Builder, valueType:BimPropertyValue) {
  builder.addFieldInt8(3, valueType, BimPropertyValue.NONE);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, valueOffset, 0);
}

static addReadonly(builder:flatbuffers.Builder, readonly:boolean) {
  builder.addFieldInt8(5, +readonly, +false);
}

static endBimProperty(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishBimPropertyBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset);
}

static finishSizePrefixedBimPropertyBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, undefined, true);
}

static createBimProperty(builder:flatbuffers.Builder, pathOffset:flatbuffers.Offset, descriptionOffset:flatbuffers.Offset, computedByOffset:flatbuffers.Offset, valueType:BimPropertyValue, valueOffset:flatbuffers.Offset, readonly:boolean):flatbuffers.Offset {
  BimProperty.startBimProperty(builder);
  BimProperty.addPath(builder, pathOffset);
  BimProperty.addDescription(builder, descriptionOffset);
  BimProperty.addComputedBy(builder, computedByOffset);
  BimProperty.addValueType(builder, valueType);
  BimProperty.addValue(builder, valueOffset);
  BimProperty.addReadonly(builder, readonly);
  return BimProperty.endBimProperty(builder);
}

unpack(): BimPropertyT {
  return new BimPropertyT(
    this.bb!.createScalarList<string>(this.path.bind(this), this.pathLength()),
    this.description(),
    this.computedBy(),
    this.valueType(),
    (() => {
      const temp = unionToBimPropertyValue(this.valueType(), this.value.bind(this));
      if(temp === null) { return null; }
      return temp.unpack()
  })(),
    this.readonly()
  );
}


unpackTo(_o: BimPropertyT): void {
  _o.path = this.bb!.createScalarList<string>(this.path.bind(this), this.pathLength());
  _o.description = this.description();
  _o.computedBy = this.computedBy();
  _o.valueType = this.valueType();
  _o.value = (() => {
      const temp = unionToBimPropertyValue(this.valueType(), this.value.bind(this));
      if(temp === null) { return null; }
      return temp.unpack()
  })();
  _o.readonly = this.readonly();
}
}

export class BimPropertyT implements flatbuffers.IGeneratedObject {
constructor(
  public path: (string)[] = [],
  public description: string|Uint8Array|null = null,
  public computedBy: string|Uint8Array|null = null,
  public valueType: BimPropertyValue = BimPropertyValue.NONE,
  public value: BoolValueT|NumericValueT|StringValueT|null = null,
  public readonly: boolean = false
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const path = BimProperty.createPathVector(builder, builder.createObjectOffsetList(this.path));
  const description = (this.description !== null ? builder.createString(this.description!) : 0);
  const computedBy = (this.computedBy !== null ? builder.createString(this.computedBy!) : 0);
  const value = builder.createObjectOffset(this.value);

  return BimProperty.createBimProperty(builder,
    path,
    description,
    computedBy,
    this.valueType,
    value,
    this.readonly
  );
}
}
