<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M8 13V8M8 8H3M8 8V3M8 8H13"
        stroke="currentColor"
        stroke-width="1.75"
    />
</svg>
