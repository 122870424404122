import type { LongTask } from 'engine-utils-ts';
import { LogLevel } from 'engine-utils-ts';
import type { NotificationDescription} from './NotificationDescription';
import { NotificationType } from './NotificationDescription';


declare type Property = any;
declare type Properties = Record<string, Property>;


export enum TelemetryFeatureFlags {
    None = 0,
    InAppNotifications = 1,
}

export interface NotificationTaskTelemetryEventMetadata {
    task_result?: 'done' | 'error' | 'cancelled';
    task_duration?: number,
    message?: string;
}

export class TelemetryEvent {
    readonly logLevel: LogLevel;
    readonly featureFlags: string[];
    readonly name: string;
    readonly metadata: TelemetryEventMetadata;
    

    constructor(
        logLevel: LogLevel,
        featureFlags: string[],
        eventName: string,
        metadata: TelemetryEventMetadata,
    ) {
        this.name = eventName;
        this.logLevel = logLevel;
        this.featureFlags = featureFlags;
        this.metadata = metadata;
    }

    static async fromNotification(args: {
        notification: NotificationDescription,
        featureFlags: string[],
    }): Promise<TelemetryEvent> {
        if (args.notification.taskDescription) {
            return TelemetryEvent.fromTaskResult({
                logLevel: logLevelFromNotificationType(args.notification.type),
                featureFlags: args.featureFlags,
                name: args.notification.header,
                message: args.notification.message,
                task: args.notification.taskDescription.task,
            });
        } else {
            const metadata: NotificationTaskTelemetryEventMetadata = {};
            if (args.notification.message) {
                metadata.message = args.notification.message;
            }
            return new TelemetryEvent(
                logLevelFromNotificationType(args.notification.type),
                args.featureFlags,
                args.notification.header,
                metadata as unknown as TelemetryEventMetadata,
            );
        }
    }

    static async fromTaskResult(args: {
        logLevel: LogLevel,
        featureFlags: string[],
        name: string,
        message: string | undefined,
        task: LongTask<any>,
    }): Promise<TelemetryEvent> {

        let task_result: 'done' | 'error' | 'cancelled';
        try {
            await args.task.asPromise();
            task_result = 'done';
        } catch (e) {
            if (e instanceof Error && e.message.startsWith('cancel')) {
                task_result = 'cancelled';
            } else {
                task_result = 'error';
            }
        }
        const metadata: NotificationTaskTelemetryEventMetadata = {
            task_result: task_result,
            task_duration: args.task.totalDurationSeconds(),
        };
        if (args.message) {
            metadata.message = args.message;
        }
        return new TelemetryEvent(
            args.logLevel,
            args.featureFlags,
            args.name,
            metadata as unknown as TelemetryEventMetadata,
        );
    }

    // static fromAction(action: TelemetryAction) {

    // }
        
}

export interface TelemetryEventMetadata {
    [key: string]: string | number | [string] | number[] | TelemetryEventMetadata | TelemetryEventMetadata[];
}




function logLevelFromNotificationType(nt: NotificationType): LogLevel {
    switch(nt) {
        case NotificationType.Info:
        case NotificationType.Success:
            return LogLevel.Info;
        case NotificationType.Warning:
            return LogLevel.Warn;
        case NotificationType.Error:
            return LogLevel.Error;
        case NotificationType.Debug:
            return LogLevel.Debug;
        default:
            console.warn('unknown notification type', nt);
            return LogLevel.Info;
    }
}