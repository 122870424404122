<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.20001 3.5002C3.20001 2.78223 3.78204 2.2002 4.50001 2.2002H11.7929C12.1377 2.2002 12.4683 2.33716 12.7121 2.58096L16.4193 6.28806C16.663 6.53186 16.8 6.86252 16.8 7.2073V16.5002C16.8 17.2182 16.218 17.8002 15.5 17.8002H4.50001C3.78204 17.8002 3.20001 17.2182 3.20001 16.5002V3.5002ZM15.2 7.33166L12 7.0001L11.6686 3.80029L15.2 7.33166ZM10.8 8.2002V7.2002H9.20001V8.2002H9.00001C8.28204 8.2002 7.70001 8.78223 7.70001 9.5002V11.0002C7.70001 11.7182 8.28204 12.3002 9.00001 12.3002H10.7V13.2002H7.70001V14.8002H9.20001V15.7998H10.8V14.8002H11C11.718 14.8002 12.3 14.2182 12.3 13.5002V12.0002C12.3 11.2822 11.718 10.7002 11 10.7002H9.30001V9.8002H12.3V8.2002H10.8Z"
        fill="currentColor"
    />
</svg>
