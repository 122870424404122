import { Vector2 } from 'math-ts';

export const CopyShader = {

	name: "Copy",

    uniforms: {

        "tDiffuse": { value: null },
        "opacity": { value: 1.0 },
        "portion": { value: new Vector2(1,1) }

    },

	vertexShader:
		
	`
		uniform vec2 portion;

		varying vec2 vUv;
		
		void main() {
			vUv = uv;
			vUv -= 0.5;
			vUv *= portion;
			vUv += 0.5;

        	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }
	`,

	fragmentShader:
		
	`
		uniform float opacity;
		uniform sampler2D tDiffuse;

		varying vec2 vUv;

		void main() {
        	vec4 texel = texture2D( tDiffuse, vUv );
        	gl_FragColor = opacity * texel;
        }
	`

};
