import { Vector3 } from 'math-ts';

export const StdSimpleShader = Object.freeze({
	
	name: 'stdSimple',
	
	uniforms:
	{
		opacity: { value: 1.0 },
		baseColor: { value: new Vector3(1, 1, 1) }
	}
	,
	
	defines: {
		'NEEDS_NORMALS': true
	},

	vertexShader:
	`
	#include <kr_std_attributes>
	#include <kr_std_uniforms>
	#include <kr_std_vars>

	#include <common>
	#include <common_math>
	#include <normals_packing>
	
	void main() {
		#include <kr_std_vars_calc>
	}
	`,

	fragmentShader:
	
	`
	#include <kr_std_uniforms>
	#include <kr_std_vars>

	uniform float opacity;
	uniform vec3 baseColor;

	#include <common_math>
	#include <common>
	#include <packing>

	
	#if defined(MRT_NORMALS)
		layout(location = 1) out highp vec4 fragData1;
	#endif

	void main() {
		#include <kr_std_pixel>

		vec3 viewDirection = normalize(vViewPosition);
		vec3 geometryNormal = normal;

		float reflPower = (1.0 - dot(viewDirection, geometryNormal));
		vec3 reflColor = mix(baseColor, vec3(0.93, 0.93, 1.0), 0.06);
		vec3 color = mix(baseColor * 0.94, reflColor, reflPower); 

		gl_FragColor = linearToOutputTexel(vec4( color, opacity ));
		#ifdef MRT_NORMALS 
			fragData1 = vec4( packNormalToRGB( normal ),  1.0 );
		#endif

		gl_FragColor.rgb = mix(gl_FragColor.rgb, colorTint.xyz, colorTint.w);
	}
	`,
});
