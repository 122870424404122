<script lang="ts">
    import NumberProperty from './NumberProperty.svelte';
    import BoolProperty from './BoolProperty.svelte';
    import StringProperty from './StringProperty.svelte';
    import SelectorProperty from './SelectorProperty.svelte';
    import ColorProperty from './ColorProperty.svelte';
    import Vector3Property from './Vector3Property.svelte';
    import CustomProperty from './CustomProperty.svelte';
    import MultiSelectorProperty from './MultiSelectorProperty.svelte';
    import SwitcherProperty from './SwitcherProperty.svelte';
    import SelectObjectsComponent from "./SelectObjects/SelectObjectsComponent.svelte";
    import InProgressNode from './InProgressNode.svelte';
    import FailureNode from './FailureNode.svelte';

    import {
        PUI_PropertyNode, PUI_PropertyNodeBool, PUI_PropertyNodeNumber, PUI_PropertyNodeString,
        PUI_PropertyNodeSelector, PUI_PropertyNodeColor, PUI_PropertyNodeVec3, PUI_CustomPropertyNode,
        PUI_PropertyNodeMultiSelector, PUI_PropertyNodeSwitcher, PUI_SceneInstancesSelectorPropertyNode,
        PUI_OperationInProgressNode, PUI_FailureNode, PUI_PropertyNodeSlider, PUI_TabSelector,
    } from 'ui-bindings';
    import ApplyProps from '../components/apply-props/ApplyProps.svelte';
    import {
        getPathFromConfigUiNode,
        matchPathForApplyProps,
    } from '../components/apply-props/utils';
    import SliderProperty from './SliderProperty.svelte';
    import TabSelector from './TabSelector.svelte';


    export let property: PUI_PropertyNode<any>;
    export let offset: number;

    $: path = getPathFromConfigUiNode(property);
    $: isMatchingForApplyProps = matchPathForApplyProps(path);
</script>


<style lang="scss">
    .property-view {
        display: flex;
        margin-bottom: 4px;
        width: 100%;
    }
    .apply-props-holder {
        width: 0;
    }
    .property-view :global(.property-name .property-name-label) {
        margin-left: var(--offset);
    }
</style>

<div 
    class="property-view"
    class:is-matching-for-apply-props={property instanceof PUI_SceneInstancesSelectorPropertyNode}
    style="--offset:{offset}px"
    >
    {#if isMatchingForApplyProps}
        <div class="apply-props-holder">
            <ApplyProps path={path} />
        </div>
    {/if}
    {#if property instanceof PUI_CustomPropertyNode }
        <CustomProperty bind:property={property}/>
    {:else if property instanceof PUI_PropertyNodeNumber}
        <NumberProperty bind:sourceProperty={property}/>
    {:else if property instanceof PUI_PropertyNodeBool }
        <BoolProperty bind:property={property}/>
    {:else if property instanceof PUI_PropertyNodeString }
        <StringProperty bind:property={property}/>
    {:else if property instanceof PUI_TabSelector}
        <TabSelector bind:property={property} />
    {:else if property instanceof PUI_PropertyNodeSelector }
        <SelectorProperty bind:property={property}/>
    {:else if property instanceof PUI_PropertyNodeMultiSelector }
        <MultiSelectorProperty bind:property={property}/>
    {:else if property instanceof PUI_PropertyNodeColor }
        <ColorProperty bind:property={property}/>
    {:else if property instanceof PUI_PropertyNodeSwitcher }
        <SwitcherProperty bind:property={property}/>
    <!-- {:else if property instanceof PUI_PropertyNodeVec2 }
        <Vector2Property bind:property={property}/> -->
    {:else if property instanceof PUI_PropertyNodeVec3 }
        <Vector3Property bind:property={property}/>
    {:else if property instanceof PUI_SceneInstancesSelectorPropertyNode}
        <SelectObjectsComponent bind:property={property}/>
    {:else if property instanceof PUI_OperationInProgressNode }
        <InProgressNode bind:property={property}/>
    {:else if property instanceof PUI_FailureNode }
        <FailureNode bind:property={property}/>
    {:else if property instanceof PUI_PropertyNodeSlider}
        <SliderProperty bind:property={property}/>
    {:else }
        {property?.name}
    {/if}
</div>

