import type { Bim, CostModel as CM } from "bim-ts";
import type { LazyVersioned, ResultAsync } from "engine-utils-ts";
import { InProgress, LazyDerived } from "engine-utils-ts";
import type { UiSettings } from "./types";
import { createGridConfig } from "./table";
import { createGrid } from "ag-grid-enterprise";
import { populateAgGridTable } from "./table/utils";

export function createIsCostModelBeingCalculated(
    costModelLazyAsync: LazyVersioned<ResultAsync<CM.CostModel>>,
) {
    return LazyDerived.new1(
        "isCostModelBeingCalculated",
        [],
        [costModelLazyAsync],
        ([costModelResult]) => {
            if (costModelResult instanceof InProgress) {
                return true;
            }
            return false;
        },
    );
}

export function exportCapitalCostsAsExcel(
    bim: Bim,
    costModel: CM.CostModel,
    uiSettings: UiSettings,
) {
    const div = document.createElement("div");
    const gridConfig = createGridConfig(bim);
    const gridApi = createGrid(div, gridConfig.options);
    const spreadsheets: string[] = [];
    for (const table of costModel.tables) {
        populateAgGridTable({
            costModel,
            gridApi,
            gridConfig,
            selectedTable: table,
            uiSettings,
        });
        const sheet = gridApi.getSheetDataForExcel({
            sheetName: table.name,
        });
        if (sheet) {
            spreadsheets.push(sheet);
        }
    }
    gridApi.destroy();
    return spreadsheets;
    gridApi.exportMultipleSheetsAsExcel({
        data: spreadsheets,
        fileName: "Capital Cost.xlsx",
    });
}
