<script lang="ts">
</script>

<div class="notification bg-warning-light">
    <slot></slot>
</div>

<style lang="scss">
    .notification {
        padding: 8px 16px;
        margin-bottom: 12px;
        border-radius: 1px;
        display: flex;
        flex-direction: column;
        & :global(.mdc-button.mdc-button--icon-leading) {
            padding: 0;
            vertical-align: text-bottom;
        }
    }
</style>
