import type { RGBAHex, VersionedValue } from 'engine-utils-ts';

export abstract class GizmoPart {

}

export class Point extends GizmoPart{
    constructor(
        public readonly color: RGBAHex
    ) {
        super();
    }
}

export class Line extends GizmoPart {
    constructor(
        public readonly color: RGBAHex
    ){
        super();
    }
}



export class GizmoPartsToRender implements VersionedValue {


    _perSource = new Map<string, GizmoPart[]>();

    _version: number = 1;

    updateFor(sourceIdentifier: string, gizmos: GizmoPart[]) {

        let prev = this._perSource.get(sourceIdentifier);

        this._perSource.set(sourceIdentifier, gizmos);
        this._version += 1;
    }

    version(): number {
        return this._version;
    }

}
