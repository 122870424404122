import { VectorPrimitiveCircle, VectorPrimitivePath, VectorPrimitivePathDescription, VectorPrimitiveRectangle, VectorPrimitiveText } from 'vector-graphic';
import { createSCChainObject2D, createTransformerChainObject2D } from "./chain";
import { Vector2 } from "math-ts";
import type { SubstationDescription } from '../types';
import { labelFontSize } from './config';
import { Anchors2D } from 'vector-graphic';
import { wireWidth } from 'src/sld/templates/config';

export function createSubstationChain(substation: SubstationDescription) {
    const children = Anchors2D.stackAnchorsAndMerge([
        ...substation.childSCs.map(createSCChainObject2D),
        ...substation.childTransformers.map(createTransformerChainObject2D)
    ], { yOffset: 250 });

    // position substation
    const substationFrame = new VectorPrimitiveRectangle({
        strokeWidth: 10,
        height: children.aabb.height(),
        width: 200,
        cx: children.aabb.max.x + 500,
        cy: children.aabb.centerY(),
    })
    children.addPrimitiveAndExpandAabb(substationFrame);

    const label = new VectorPrimitiveText({
        text: substation.text,
        fontSize: labelFontSize,
        anchor: 'middle',
        verticalAlignment: 'text-after-edge',
        y: substationFrame.aabb.min.y - labelFontSize,
        x: substationFrame.aabb.centerX(),
    })
    children.addPrimitiveAndExpandAabb(label);

    // create wires from each child
    const paths: VectorPrimitivePathDescription[] = [];
    for (const from of children.points) {
        const to = new Vector2(substationFrame.aabb.centerX(), from.y);
        const endOfWire = new VectorPrimitiveCircle({
            fill: 'black',
            radius: wireWidth,
            cx: to.x,
            cy: to.y
        });
        children.addPrimitiveAndExpandAabb(endOfWire);
        paths.push(new VectorPrimitivePathDescription([from, to]))
    }

    // createWires
    const wiresPrimitive = new VectorPrimitivePath({ paths, strokeWidth: wireWidth })
    children.addPrimitiveAndExpandAabb(wiresPrimitive);

    children.position.set(-children.aabb.centerX(), -children.aabb.centerY());
    return children;
}
