import { createNotificationSource } from 'ui-bindings';

export const notificationSource = createNotificationSource({
    calculatePrice: {
        header: 'Calculating scene prices',
        description: ''
    },
    calculatePriceError: {
        header: 'Calculating scene prices',
        description: (message: string) => `${message}`
    },
    bigFile: {
        header: 'Invalid file',
        description: (name: string) => `file ${name} is too big.`,
    },
    generateTable: {
        header: 'Generate table',
        description: (message: string) => `${message}`
    },
    loadCostReport: {
        header: 'Loading cost report templates',
        description: ''
    },
    convertToAsset: {
        header: 'Converting to assets',
        description: (assetName) => `Asset ${assetName} was added to the catalog`,
    },
    convertToAssetError: {
        header: 'Converting to assets failed',
        description: (message) => message 
    },
    downloadVerdata: {
        header: 'Downloading verdata',
        description: ''
    },
    downloadVerdataFailed: {
        header: 'Downloading verdata failed',
        description: ''
    },
    noInstances: {
        header: 'No found instances with ids:',
        description: (ids) => `${ids}`
    },
    exportFile: {
        header: 'File Export',
        description: (path) => `Exporting file ${path}`,
    },
    unsupportedFormat: {
        header: 'Unsupported file format',
        description: (name) => `could not import ${name}`
    },
    importFile: {
        header: 'File Import',
        description: (names) => `Importing file ${names}`,
    },
    importInstances: {
        header: 'Importing items to project',
        description: '',
    },
    addToComparision: {
        header: 'Add to comparison',
        description: ''
    },
    addToComparisionFailed: {
        header: 'Add to comparison failed',
        description: (message: string) => `${message}`
    },
    updateLayoutMetrics: {
        header: 'Updating Project Metrics',
        description: ''
    },
    selectedTooMany: {
        header: 'Selection Error',
        description: (instanceTypes: string[]) => `You have selected too many ${instanceTypes}.`,
    },
    generateCostReport: {
        header: 'Generating cost report',
        description: ''
    },
    openManyGroups: {
        header: 'You are trying to open too many groups.',
        description: ''
    },
    expendGroups: {
        header: 'Expend all',
        description: ''
    },
    takingScreenshot: {
        header: 'Taking screenshot',
        description: 'Please do not interact with the model until its done',
    },
    cantParsePanOndFile: {
        header: 'File parse error',
        description: ([fileName, error]: string[]) =>
            "Can't parse file " + fileName + ". Error " + error,
    },
    fileImportedToCatalog: {
        header: 'Catalog Import',
        description: (fileName) => `File ${fileName} was added to the catalog`,
    },
    blockNumbering: {
        header: 'Block numbering',
        description: ''
    },
    generatingSLD: {
        header: 'Generating SLD',
        description: (msg) => msg,
    },
    pileAdjustmentFailure: {
        header: 'Pile adjustment failure',
        description: `Only any-tracker object type supports granular piles adjustments`
    },
    convertToAnyTrackersSuggestion: {
        header: 'Convert to trackers to any-trackers?',
        description: ``
    },
    chromeSupport: {
        header: 'Please, update your browser',
        description: `PVFarm is optimized for the latest versions of Google Chrome only.
         With the browser you are using some features might not work as expected.
         You may also experience performance degradation`
    },
    keyMetricsCalculation: {
        header: 'Calculating key metrics',
        description: ``
    },
    slowInternet: {
        header: 'PVFarm stopped due to an unstable connection',
        description: `Your internet connection was too slow or unstable. Closing other applications
        that are heavy on internet usage can lead to a better experience. Another option is to switch
        a network or change an internet service provider. Using a VPN may also help in some cases.`
    }
});
