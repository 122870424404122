import { Aabb2, Matrix3, Vector2 } from "math-ts"

export abstract class VectorPrimitive {
    matrix?: Matrix3;
    aabb: Aabb2;
    zIndex: number;

    constructor() {
        this.aabb = Aabb2.empty();
        this.zIndex = 0;
    }

    recalculateAabb() {
        if (this.matrix) {
            this.aabb.applyMatrix3(this.matrix);
        }
        return this.aabb;
    };

}

export class VectorPrimitiveRectangle extends VectorPrimitive {
    cx: number
    cy: number
    width: number
    height: number
    stroke?: string
    fill?: string
    strokeWidth?: number

    constructor(params: {
        cx?: number
        cy?: number
        width: number
        height: number
        stroke?: string
        fill?: string
        strokeWidth?: number
    }) {
        super()
        this.cx = params.cx ?? 0;
        this.cy = params.cy ?? 0;
        this.width = params.width;
        this.height = params.height;
        this.stroke = params.stroke;
        this.fill = params.fill;
        this.strokeWidth = params.strokeWidth;
    }

    recalculateAabb(): Aabb2 {
        this.aabb.setFromCenterAndSize(
            new Vector2(this.cx, this.cy),
            new Vector2(this.width, this.height),
        );
        return super.recalculateAabb();
    }
}

export type TextAnchor = 'start' | 'middle' | 'end';
export type TextVerticalAlignment = 'baseline' | 'text-after-edge' | 'hanging' | 'text-before-edge' | 'middle';
export class VectorPrimitiveText extends VectorPrimitive {
    x?: number;
    y?: number;
    fontSize: number;
    color?: string;
    text: string;
    anchor?: TextAnchor;
    verticalAlignment?: TextVerticalAlignment;

    constructor(params: {
        x?: number,
        y?: number,
        fontSize: number,
        color?: string
        text: string
        anchor?: TextAnchor
        verticalAlignment?: TextVerticalAlignment
    }) {
        super()
        this.x = params.x ?? 0;
        this.y = params.y ?? 0;
        this.fontSize = params.fontSize;
        this.color = params.color;
        this.text = params.text;
        this.anchor = params.anchor;
        this.verticalAlignment = params.verticalAlignment;
    }

    recalculateAabb(): Aabb2 {
        this.aabb.setFromCenterAndSize(
            new Vector2(this.x, this.y),
            new Vector2(this.fontSize, this.fontSize),
        );
        return super.recalculateAabb();
    }
}

export class CubicBezier {
    from: Vector2
    fromDir: Vector2
    to: Vector2
    toDir: Vector2

    constructor(from: Vector2, fromDir: Vector2, toDir: Vector2, to: Vector2) {
        this.from = from
        this.fromDir = fromDir
        this.to = to
        this.toDir = toDir
    }
}

export class QuadraticBezier {
    from: Vector2
    control: Vector2
    to: Vector2

    constructor(from: Vector2, control: Vector2, to: Vector2) {
        this.from = from
        this.to = to
        this.control = control
    }
}

type PathComponent = Vector2 | CubicBezier | QuadraticBezier
export class VectorPrimitivePathDescription {
    items: Array<PathComponent>
    closedPath: boolean
    constructor(items: Array<PathComponent>, closedPath = false) {
        this.items = items;
        this.closedPath = closedPath;
    }
}

export class VectorPrimitivePath extends VectorPrimitive {
    paths: VectorPrimitivePathDescription[]
    fill?: string
    stroke?: string
    strokeWidth?: number

    constructor(params: {
        fill?: string,
        stroke?: string,
        strokeWidth?: number,
        paths: VectorPrimitivePathDescription[],
    }) {
        super();
        this.fill = params.fill;
        this.stroke = params.stroke;
        this.strokeWidth = params.strokeWidth;
        this.paths = params.paths;
    }

    recalculateAabb(): Aabb2 {
        this.aabb.makeEmpty()
        for (const path of this.paths) {
            for (const item of path.items) {
                if (item instanceof Vector2) {
                    this.aabb.expandByPoint(item);
                } else if (item instanceof CubicBezier) {
                    this.aabb.expandByPoints([item.from, item.to, item.fromDir, item.toDir]);
                } else if (item instanceof QuadraticBezier) {
                    this.aabb.expandByPoints([item.control, item.from, item.to]);
                }
            }
        }
        return super.recalculateAabb();
    }

}

export class VectorPrimitiveCircle extends VectorPrimitive {
    cx: number;
    cy: number;
    fill?: string;
    stroke?: string;
    strokeWidth?: number;
    radius: number;

    constructor(params: {
        cx?: number,
        cy?: number,
        fill?: string,
        stroke?: string,
        strokeWidth?: number,
        radius: number
    }) {
        super();
        this.cx = params.cx ?? 0;
        this.cy = params.cy ?? 0;
        this.fill = params.fill;
        this.stroke = params.stroke;
        this.strokeWidth = params.strokeWidth;
        this.radius = params.radius;
    }

    recalculateAabb(): Aabb2 {
        this.aabb.setFromCenterAndSize(
            new Vector2(this.cx, this.cy),
            new Vector2(this.radius * 2, this.radius * 2),
        );
        return super.recalculateAabb();
    }
}
