<script lang="ts">
import { NumberProperty, type UnitsMapper } from "bim-ts";
import { Success } from "engine-utils-ts";
import { getContext } from "svelte";
import type { Readable } from "svelte/store";

export let value: NumberProperty;
export let change: (newValue: NumberProperty) => void;

let unitsMapper = getContext<Readable<UnitsMapper>>('unitsMapper');

let num = value.value;
let unit = value.unit;

let isUnitValid = false;
$: {
    const result = $unitsMapper.mapToConfiguredResult({ value: 1, unit });
    isUnitValid = result instanceof Success;
    if (value.unit !== unit || value.value !== num) {
        change(NumberProperty.new({ unit, value: num }))
    }
}
</script>

<div>
    <input type="number" bind:value={num} />
    <input type="string" bind:value={unit} />({isUnitValid ? 'valid unit' : 'invalid unit'})
</div>

<style lang="scss">
div {
    display:flex;
}
</style>
