import { ObjectUtils } from '../ObjectUtils';

export interface LazyValue<T> {
    poll(cache?: LazyVersionedPollingCache): Readonly<T>;
}

export interface VersionedValue {
    version(cache?: LazyVersionedPollingCache): number; // should monothonically increase
}

export interface LazyVersioned<T> extends VersionedValue, LazyValue<T> {
    pollWithVersion(cache?: LazyVersionedPollingCache): PollWithVersionResult<T>;
    dispose?(): void;
}

export interface PollWithVersionResult<T> {
    version: number;
    value: T;
}

export interface LazyVersionedPollingCache {
    getOrCreate(x: VersionedValue): PollWithVersionResult<undefined>;
    getOrCreate<T>(x: LazyVersioned<T>): PollWithVersionResult<T>;
};



export class VersionedInvalidator implements VersionedValue {
    _version: number = 0;
    _additionalDependencies?: VersionedValue[];

    constructor(additionalDependencies?: VersionedValue[]) {
        this._additionalDependencies = additionalDependencies;
    }

    version(cache?: LazyVersionedPollingCache): number {
        let sum = this._version;
        if (this._additionalDependencies?.length) {
            for (const d of this._additionalDependencies) {
                sum += cache?.getOrCreate(d)?.version ?? d.version();
            }
        }
        return sum;
    }

    addDependency(dep: VersionedValue){
        if(!this._additionalDependencies){
            this._additionalDependencies = [];
        }
        this._additionalDependencies.push(dep);
    }

    removeDependency(dep: VersionedValue){
        if(this._additionalDependencies){
            const idx = this._additionalDependencies.indexOf(dep);
            if(idx > 0){
                const inv = this._additionalDependencies[idx];
                this._version += (1 + inv.version());
                this._additionalDependencies.splice(idx, 1);
            }
        }
    }

    invalidate() {
        this._version += 1;
    }
}

export class LazyBasic<T> implements LazyVersioned<T> {
    readonly name: string;
    private _value: T;
    private _version: number = 1;

    private _disposeFn?: ((val: T) => void);

    constructor(name: string, intial: T) {
        this.name = name;
        this._value = intial;
    }

    withDispose(fnName: keyof NonNullable<T>) {
        this._disposeFn = (val) => {
            if (typeof (val as NonNullable<T>)[fnName] == 'function') {
                ((val as NonNullable<T>)[fnName] as unknown as Function)();
            } else {
                console.error(`dispose fn name (${String(fnName)}) is invalid for ${this.name}`);
            }
        }
        return this;
    }
    withCustomDisposeFn(disposingFn: ((val: T) => void)) {
        this._disposeFn = disposingFn;
        return this;
    }

    dispose() {
        if (this._disposeFn && this._value) {
            try {
                this._disposeFn(this._value);
            } catch (e) {
                console.error(`error disposing LazyDerived`, this, e);
            }
        }
    }

    version(): number {
        return this._version
    }

    poll(): Readonly<T> {
        return this._value;
    }

    pollWithVersion(): PollWithVersionResult<T> {
        const result = { value: this._value, version: this._version };
        return result;
    }

    getForMutation(): T {
        this._version += 1;
        return this._value;
    }

    replaceWith(value: T): boolean {
        if (ObjectUtils.areObjectsEqual(this._value, value)) {
            return false;
        }
        this.dispose();
        this._value = value;
        this._version += 1;
        return true;
    }

    forceUpdate(value: T) {
        if (value !== this._value) {
            this.dispose();
        }
        this._value = value;
        this._version += 1;
    }

}


export class LazyDerived<T> implements LazyVersioned<T> {

    readonly name: string;
    readonly args: LazyVersioned<any>[];
    readonly deriveFn: (args: any[], prevResult: T | undefined) => T;

    private nonArgInvalidators: VersionedValue[] | null;
    private _disposeFn?: ((val: T) => void);

    private _value: T | undefined = undefined;
    private _dependenciesVersionSum: number = -1;
    private _versionOffset: number = 1;
    private _checkEqWithPrevResult: boolean = true;

    private constructor(
        name: string,
        producers: LazyVersioned<any>[],
        calculator: (args: any[]) => T,
        nonArgInvalidators: VersionedValue[] | null
    ) {
        this.name = name;
        this.args = producers;
        this.deriveFn = calculator;
        this.nonArgInvalidators = nonArgInvalidators;
    }
    withDispose(fnName: keyof T) {
        this._disposeFn = (val) => {
            if (typeof val[fnName] == 'function') {
                (val[fnName] as unknown as Function)();
            } else {
                console.error(`dispose fn name (${String(fnName)}) is invalid`);
            }
        }
        return this;
    }
    withCustomDisposeFn(disposingFn: ((val: T) => void)) {
        this._disposeFn = disposingFn;
        return this;
    }
    withoutEqCheck() {
        this._checkEqWithPrevResult = false;
        return this;
    }

    poll(cache?: LazyVersionedPollingCache): Readonly<T> {
        const result = this.pollWithVersion(cache);
        return result.value;
    }

    version(cache?: LazyVersionedPollingCache): number {
        if (this._checkEqWithPrevResult) {
            const result = this.pollWithVersion(cache);
            return result.version;
        } else {
            console.assert(this._versionOffset === 1, 'version offset shoudl be 1 for non eq check lazy derived');
            let dependenciesVersionsSum = 0;
            if (this.nonArgInvalidators) {
                for (const inv of this.nonArgInvalidators) {
                    dependenciesVersionsSum += cache?.getOrCreate(inv).version ?? inv.version(cache);
                }
            }
            for (const p of this.args) {
                dependenciesVersionsSum += cache?.getOrCreate(p).version ?? p.version(cache);
            }
            return dependenciesVersionsSum + this._versionOffset;
        }
    }

    pollWithVersion(cache?: LazyVersionedPollingCache): PollWithVersionResult<T> {
        let dependenciesVersionsSum = 0;
        if (this.nonArgInvalidators) {
            for (const inv of this.nonArgInvalidators) {
                dependenciesVersionsSum += cache?.getOrCreate(inv).version ?? inv.version(cache);
            }
        }

        const args: any[] = [];
        for (const p of this.args) {
            const r = cache?.getOrCreate(p) ?? p.pollWithVersion(cache);
            args.push(r.value);
            const dv = r.version;
            if (!(dv >= 0)) {
                console.error(`invalid version for `, dv, p);
            } else {
                dependenciesVersionsSum += r.version;
            }
        }

        const prevTotalVersion = this._dependenciesVersionSum + this._versionOffset;
        if (dependenciesVersionsSum != this._dependenciesVersionSum) {
            // something changed, recalculate

            this._dependenciesVersionSum = dependenciesVersionsSum;

            try {
                const newVal = this.deriveFn(args, this._value);

                if ((newVal !== undefined || this._value !== undefined) &&
                    (this._checkEqWithPrevResult && ObjectUtils.areObjectsEqual(newVal, this._value))
                ) {
                    // changes in args often do not produce changes in the result
                    // so we actually don't want to increase version() result if result did not change
                    // if dependencies versions changed, recalculate result and compare to previous one
                    this._versionOffset = prevTotalVersion - dependenciesVersionsSum;
                }
                if (newVal !== this._value) {
                    if (this._value !== undefined && this._disposeFn) {
                        try {
                            this._disposeFn(this._value);
                        } catch (e) {
                            console.error(`error disposing LazyDerived`, this, e);
                        }
                    }
                    this._value = newVal;
                }
            } catch (e) {
                console.error(`error during LazyDerived(${this.name}) value calculation`, e);
                this._versionOffset = prevTotalVersion - dependenciesVersionsSum;
            }
        }
        return {
            value: this._value!,
            version: this._dependenciesVersionSum + this._versionOffset,
        };
    }

    dispose() {
        if (this._value == undefined) {
            return;
        }
        const val = this._value;
        this._value = undefined;
        this._dependenciesVersionSum = -1;
        if (this._disposeFn) {
            try {
                this._disposeFn(val);
            } catch (e) {
                console.error(`error disposing LazyDerived`, this, e);
            }
        }
    }

    peekLastValue(): Readonly<T> | undefined {
        return this._value;
    }

    static new0<T>(
        name: string,
        nonArgInvalidators: VersionedValue[] | null,
        calculator: (args: [], prevResult: T | undefined) => T
    ): LazyDerived<T> {
        return new LazyDerived(name, [], calculator as any, nonArgInvalidators);
    }
    static new1<T, Arg1>(
        name: string,
        nonArgInvalidators: VersionedValue[] | null,
        args: [LazyVersioned<Arg1>],
        calculator: (args: [arg1: Arg1], prevResult: T | undefined) => T
    ): LazyDerived<T> {
        return new LazyDerived(name, args, calculator as (args: any[]) => T, nonArgInvalidators);
    }
    static new2<T, Arg1, Arg2>(
        name: string,
        nonArgInvalidators: VersionedValue[] | null,
        args: [LazyVersioned<Arg1>, LazyVersioned<Arg2>],
        calculator: (args: [arg1: Arg1, arg1: Arg2], prevResult: T | undefined) => T
    ): LazyDerived<T> {
        return new LazyDerived(name, args, calculator as (args: any[]) => T, nonArgInvalidators);
    }
    static new3<T, Arg1, Arg2, Arg3>(
        name: string,
        nonArgInvalidators: VersionedValue[] | null,
        args: [LazyVersioned<Arg1>, LazyVersioned<Arg2>, LazyVersioned<Arg3>],
        calculator: (args: [arg1: Arg1, arg1: Arg2, arg1: Arg3], prevResult: T | undefined) => T
    ): LazyDerived<T> {
        return new LazyDerived(name, args, calculator as (args: any[]) => T, nonArgInvalidators);
    }
    static new4<T, Arg1, Arg2, Arg3, Arg4>(
        name: string,
        nonArgInvalidators: VersionedValue[] | null,
        args: [LazyVersioned<Arg1>, LazyVersioned<Arg2>, LazyVersioned<Arg3>, LazyVersioned<Arg4>],
        calculator: (args: [arg1: Arg1, arg1: Arg2, arg1: Arg3, arg4: Arg4], prevResult: T | undefined) => T
    ): LazyDerived<T> {
        return new LazyDerived(name, args, calculator as (args: any[]) => T, nonArgInvalidators);
    }

    static fromArr<T, Arg>(
        name: string,
        nonArgInvalidators: VersionedValue[] | null,
        args: LazyVersioned<Arg>[],
        calculator: (args: Arg[], prevResult: T | undefined) => T
    ): LazyDerived<T> {
        return new LazyDerived(name, args, calculator as (args: any[]) => T, nonArgInvalidators);
    }

    static fromMutatingObject<T>(pollFn: (prevResult: T | undefined) => Readonly<T>): LazyDerived<T> {
        let current = ObjectUtils.deepCloneObj(pollFn(undefined));
        let version = 1;
        const checkValue = () => {
            const p = pollFn(current);
            if (!ObjectUtils.areObjectsEqual(current, p)) {
                current = ObjectUtils.deepCloneObj(p);
                version += 1;
            }
        }
        return new LazyDerived('', [], () => current, [{ version: () => { checkValue(); return version; }}]);
    }

    static fromVersionedObject<T extends VersionedValue>(versionedObj: T): LazyDerived<T> {
        return LazyDerived.new0('', [versionedObj], () => versionedObj);
    }

    static animationStepsByTimeInvalidator(params: {
        animationStepMs: number,
    }): LazyVersioned<LazyAnimationStepInfo> {
        return LazyDerived.fromMutatingObject((prevResult) => {
            const now = performance.now();
            if (!prevResult) {
                return {
                    animationStep: 0,
                    animationStepStartTimeMs: now,
                };
            }
            if (prevResult.animationStepStartTimeMs + params.animationStepMs > now) {
                return prevResult;
            }
            return {
                animationStep: prevResult.animationStep + 1,
                animationStepStartTimeMs: now,
            };
        });
    }
}

export interface LazyAnimationStepInfo {
    animationStep: number;
    animationStepStartTimeMs: number;
}


