<script lang="ts">
    import { MDCTooltip, XPosition } from "@material/tooltip";

    export let id: string;
    export let show: boolean = true;
    export let text: string = "";
    export let delay: number = 100;

    let tooltip: HTMLDivElement;
    let tooltipElem: MDCTooltip;

    $: {
        if (show && tooltip && (!tooltipElem || tooltipElem.root !== tooltip)) {
            tooltipElem = new MDCTooltip(tooltip);
            tooltipElem.setHideDelay(delay);
            tooltipElem.setShowDelay(delay);
            tooltipElem.setTooltipPosition({ xPos: XPosition.END });
        }
    }
</script>

{#if show}
    <div
        {id}
        class="mdc-tooltip"
        role="tooltip"
        aria-hidden="true"
        bind:this={tooltip}
    >
        <div
            class="mdc-tooltip__surface mdc-tooltip__surface-animation mdc-typography--body1"
        >
            {#if $$slots.text}
                <slot name="text"></slot>
            {:else}
                {text}
            {/if}
        </div>
    </div>
    <div aria-describedby={id} class="wrapper">
        <slot />
    </div>
{:else}
    <slot />
{/if}

<style lang="scss">
</style>
