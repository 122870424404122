import type { Vector2 } from './Vector2';

export class Matrix2 {

	elements = [
		1, 0,
		0, 1,
	] as [number, number, number, number];

	constructor() {
	}

	static fromAngle(theta: number) {
		const c = Math.cos(theta);
		const s = Math.sin(theta);
		return new Matrix2().set(
			c, -s,
			s, c
		);
	}

	hash(): number {
		let hash: number = 0;
		for (let i = 0; i < this.elements.length; ++i) {
			hash ^= this.elements[i] * (i + 17);
		}
		return hash;
	}

	set(
		n11: number, n12: number,
		n21: number, n22: number
	 ) {
		const te = this.elements;
		te[ 0 ] = n11; te[ 1 ] = n21;
		te[ 2 ] = n12; te[ 3 ] = n22;
		return this;
	}

	identity() {
		this.set(
			1, 0,
			0, 1,
		);
		return this;
	}

	copy( m: Matrix2 ) {
		const te = this.elements;
		const me = m.elements;

		te[ 0 ] = me[ 0 ];
		te[ 1 ] = me[ 1];
		te[ 2 ] = me[ 2 ];
		te[ 3 ] = me[ 3 ];

		return this;
	}

	equals( matrix:Matrix2 ) {

		const te = this.elements;
		const me = matrix.elements;

		for ( let i = 0; i < 9; i ++ ) {
			if ( te[ i ] !== me[ i ] ) {
                return false;
            }
		}
		return true;

	}

	fromArray( array: number[], offset = 0 ) {

		for ( let i = 0; i < 4; i ++ ) {

			this.elements[ i ] = array[ i + offset ];

		}
		return this;
	}

	clone() {
		return new Matrix2().set(
			this.elements[0], this.elements[1],
			this.elements[2], this.elements[3]
		);
	}

	determinant(): number {
        return this.elements[0] * this.elements[3] - this.elements[1] * this.elements[2];
	}

	invert(): Matrix2 {
		const det = this.determinant();
		if (!Number.isFinite(det)) {
			return this.set(0, 0, 0, 0);
		}
		const te = this.elements;
		const detInv = 1 / det;
		const n11 = te[0];
		te[0] = te[3] * detInv;
		te[1] = -te[1] * detInv;
		te[2] = -te[2] * detInv;
		te[3] = n11 * detInv;
		return this;
	}

	extractBasis(xAxis: Vector2, yAxis: Vector2) {
		xAxis.set( this.elements[0], this.elements[1] );
		yAxis.set( this.elements[2], this.elements[3] );
		return this;
	}
	
	scale(sx: number, sy: number) {
		const te = this.elements;
		te[0] *= sx;
		te[1] *= sx;
		te[2] *= sy;
		te[3] *= sy;
		return this;
	}

	multiply( m: Matrix2 ) {

		return this.multiplyMatrices( this, m );

	}

	premultiply( m: Matrix2 ) {

		return this.multiplyMatrices( m, this );

	}

	multiplyMatrices( a: Matrix2, b: Matrix2 ) {

		const ae = a.elements;
		const be = b.elements;
		const te = this.elements;

		const a11 = ae[ 0 ], a12 = ae[ 2 ];
		const a21 = ae[ 1 ], a22 = ae[ 3 ];

		const b11 = be[ 0 ], b12 = be[ 2 ];
		const b21 = be[ 1 ], b22 = be[ 3 ];

		te[ 0 ] = a11 * b11 + a12 * b21;
		te[ 1 ] = a21 * b11 + a22 * b21;
		te[ 2 ] = a11 * b12 + a12 * b22;
		te[ 3 ] = a21 * b12 + a22 * b22;

		return this;
	}
}

Object.defineProperty(
	Matrix2.prototype,
	'isMatrix2',
	{
		enumerable: false,
		configurable: false,
		value: true,
		writable: false
	}
);

