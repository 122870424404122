<script lang="ts">

    import { getContext, onDestroy, onMount } from 'svelte';
    import { type EChartOptions, echarts } from 'ui-charts';
    import { SharedChartsZoomStates, type ChartSharedZoomState } from '../tmy-data-viz-utils/SharedChartsZoomStates';

    export let echartOptions: EChartOptions | undefined = undefined;

    export let onEchartInit: (chart: echarts.ECharts) => void = () => {};

    const instanceSymbol = Symbol('echart-instance');

    let div: HTMLElement | undefined = undefined;
    let chart: echarts.ECharts | undefined = undefined;

    const sharedZoomStates: SharedChartsZoomStates | undefined = getContext(SharedChartsZoomStates.name);
    let sharedZoomStatesUnsub: (() => void) | undefined;
    if (sharedZoomStates) {
        sharedZoomStatesUnsub = sharedZoomStates.subscibeToZoomStatesUpdates((
            sourceChartInstance: Symbol,
            zoomSouceObject: Object,
            zoomState: ChartSharedZoomState
        ) => {
            if (!chart || !echartOptions || sourceChartInstance === instanceSymbol) {
                return;
            }
            if (echartOptions.dataZoomSourceObject !== zoomSouceObject) {
                return;
            }
            chart?.dispatchAction({
                type: 'dataZoom',
                start: zoomState.start,
                end: zoomState.end,
            });
        });
    } else {
        console.warn('sharedZoomStates is undefined');
    }
    
    $: {
        if (chart && echartOptions) {
        try {
            chart.setOption(echartOptions);

            if (echartOptions.dataZoomSourceObject && sharedZoomStates && 'dataZoom' in echartOptions) {
                let dataZoom = echartOptions['dataZoom'] as any;
                if (!Array.isArray(dataZoom)) {
                    dataZoom = [dataZoom];
                }
                let isStateFound = false;
                for (const dz of dataZoom) {
                    if (dz.type === 'inside') {
                        const zoomState = sharedZoomStates.getStateForZoomSourceObject(echartOptions.dataZoomSourceObject);
                        dz.start = zoomState.start;
                        dz.end = zoomState.end;
                        isStateFound = true;
                    }
                }
                if (isStateFound) {
                    // if we mutate options with dataZoom state before setting it the 1st time
                    // chart will remember this dataZoom state as initial
                    // and restore button will not work properly
                    chart.setOption(echartOptions);
                }
            }
        } catch (e) {
            console.error(e);
        }
        }
    }

    onMount(() => {
        const sizeObserver = new ResizeObserver((entries) => {
            chart?.resize();
        });
        if (div) {
            sizeObserver.observe(div);
        }

        setTimeout(() => {
            if (div) {
                chart = echarts.init(div);
                onEchartInit(chart);
                chart.on('dataZoom', dataZoomChartEventHandler as any);
                chart.on('restore', (e) => {
                    if (!sharedZoomStates || !echartOptions?.dataZoomSourceObject) {
                        return;
                    }
                    sharedZoomStates.pushNewStateForZoomSourceObject(
                        instanceSymbol,
                        echartOptions.dataZoomSourceObject,
                        {start: 0, end: 100}
                    );
                });
            }
        });

        return () => {
            sizeObserver.disconnect();
            if (sharedZoomStatesUnsub) {
                sharedZoomStatesUnsub();
                sharedZoomStatesUnsub = undefined;
            }
            div = undefined;
            chart?.dispose();
        };
    })

    function dataZoomChartEventHandler(e: {
            type: 'datazoom',
            start?: number,
            end?: number,
            startValue?: number,
            endValue?: number,
            batch?: {
                start?: number,
                end?: number,
                startValue?: number,
                endValue?: number,
            }[],
    }) {
        if (!sharedZoomStates || !echartOptions?.dataZoomSourceObject) {
            return;
        }
        let start: number|undefined = e.start;
        let end: number|undefined = e.end;

        let startValue: number|undefined = e.startValue;
        let endValue: number|undefined = e.endValue;

        if (e.batch?.length) {
            const batch = e.batch[0];
            start = start ?? batch.start;
            end = end ?? batch.end;
            startValue = startValue ?? batch.startValue;
            endValue = endValue ?? batch.endValue;
        }

        let sourceObjectDataCount: number = 1;
        {
            const sharedObject = echartOptions.dataZoomSourceObject as any;
            if (sharedObject['length'] !== undefined) {
                sourceObjectDataCount = sharedObject['length'] | 0;
            }
        }

        if (start == undefined && startValue != undefined) {
            start = startValue / sourceObjectDataCount * 100;
        }
        if (end == undefined && endValue != undefined) {
            end = endValue / sourceObjectDataCount * 100;
        }
        if (start == undefined || end == undefined) {
            console.warn('could not extract start end from dataZoom event', e);
            return;
        }
        sharedZoomStates.pushNewStateForZoomSourceObject(
            instanceSymbol,
            echartOptions.dataZoomSourceObject,
            {start: start, end: end}
        );
    }

</script>

<div class="chart" bind:this={div} >
</div>

<style>
    .chart {
        width: 100%;
        height: 100%;
    }
</style>
