<svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M6.99786 11.1746L11.8181 6.35433L10.8631 5.4065L6.99786 9.27171L5.08264 7.3565L4.14422 8.30433L6.99786 11.1746ZM8.00132 14.9022C7.05167 14.9022 6.15719 14.7222 5.31789 14.3622C4.4786 14.0023 3.74541 13.5088 3.11831 12.8817C2.49122 12.2546 1.9977 11.5218 1.63776 10.6834C1.27781 9.84498 1.09784 8.95093 1.09784 8.00128C1.09784 7.04051 1.27781 6.14048 1.63776 5.30118C1.9977 4.46189 2.49073 3.73131 3.11684 3.10945C3.74294 2.48758 4.47543 1.99527 5.31432 1.63251C6.15321 1.26976 7.04775 1.08838 7.99794 1.08838C8.95923 1.08838 9.8599 1.26951 10.6999 1.63178C11.54 1.99405 12.2707 2.4857 12.8921 3.10673C13.5135 3.72776 14.0054 4.45805 14.3679 5.2976C14.7304 6.13714 14.9116 7.03793 14.9116 7.99998C14.9116 8.9505 14.7302 9.84536 14.3675 10.6846C14.0047 11.5238 13.5124 12.2565 12.8906 12.8828C12.2687 13.5092 11.5385 14.0023 10.7001 14.3622C9.86169 14.7222 8.96209 14.9022 8.00132 14.9022Z"
        fill="#4DAF00"
    />
</svg>
