import type { ExtrudedPolygonGeometry } from 'bim-ts';
import { DefaultMap } from 'engine-utils-ts';
import { extrudePolygon } from 'geometry-extrude';
import type { Aabb2, Transform} from 'math-ts';
import { KrMath, Matrix3, Matrix4, Vector2, Vector3 } from 'math-ts';

import { BufferAttribute, BufferGeometry, Float32BufferAttribute, Uint16BufferAttribute } from '../3rdParty/three';
import type { TypedArray } from '../KreoEngine';
import { ShaderFlags } from '../shaders/ShaderFlags';
import type { index } from '../utils/Utils';
import { CreateKrGeometryFromData } from './GeometryUtils';
import type { PerGeoShaderInfo } from './KrBufferGeometry';
import { KrEdgedGeoEmpty, GeometryGpuRepr } from './KrBufferGeometry';
import { generatePolylineGeometry } from './PolylinesGeomGeneration';
import type { BlockOptions } from '../three-mesh-ui/components/Block';
import { Block } from '../three-mesh-ui/components/Block';
import type { TextLayoutOptions } from '../three-mesh-ui/components/Text';
import { Text } from '../three-mesh-ui/components/Text';

export class GeometryGenerator {

	static generatePolyline(line: {radius: number, points: Vector3[]}): GeometryGpuRepr {
		if (line.points.length < 2) {
			console.warn('polyline geo should have 2 or more points');
			return KrEdgedGeoEmpty;
		}
		let segments: number;
		if (line.radius < 0.01) {
			segments = 6;
		} else if (line.radius < 0.03) {
			segments = 8;
		} else if (line.radius < 0.1) {
			segments = 10;
		} else {
			segments = 12;
		}
		return generatePolylineGeometry(line, segments);
	}

	static generateCornerGeo(extents: {inner: number, outer: number}): BufferGeometry {
		const positions = new Float32Array([
			0.0, extents.inner, 0, 		// 0
			0.0, extents.outer,   0, 		// 1
			extents.outer, extents.outer, 0, 	// 2
			extents.outer, 0, 0, 			// 3
			extents.inner, 0, 0, 			// 4
			extents.inner, extents.inner, 0,// 5
		]);
		const inds = new Uint16Array([0,2,1, 0,5,2, 5,4,2, 2,4,3]);

		return new GeometryGpuRepr(
			{
				position: new BufferAttribute(positions, 3),
			},
			new BufferAttribute(inds, 1),
			new BufferAttribute(new Uint16Array(), 1)
		);
	}

	static generateClipboxCorner(): GeometryGpuRepr {
		const inds = new Uint16Array([0,1,3, 1,2,3, 3,4,5, 0,3,5]);
		const positions = new Float32Array([0.0,0.0,0, 0.0,1.0,0, 0.5,1.0,0, 0.5,0.5,0, 1.0,0.5,0, 1.0,0.0,0,]);
		const edges = new Uint16Array([0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 0]);
		return new GeometryGpuRepr(
			{
				position: new BufferAttribute(positions, 3),
			},
			new BufferAttribute(inds, 1),
			new BufferAttribute(edges, 1)
		);
	}

	static generateExtrudedPolygon(polygon: ExtrudedPolygonGeometry): GeometryGpuRepr {

		if (polygon.outerShell.points.length === 0 && polygon.holes.length === 0) {
			return KrEdgedGeoEmpty;
		}

        let pointsAsArray: number[][][];
        if (polygon.outerShell.points.length < 3 && polygon.holes.length === 0) {
            const points = polygon.outerShell.points;
            if (points.length === 0) {
                pointsAsArray = [];
            } else {
                const smallOffset = 0.01;
                const p0 = points[0];
                const p1 = points[1] ?? p0.clone().add(new Vector2(smallOffset, smallOffset));
                const p2 = points[2] ?? p0.clone().lerpTo(p1, 0.5);
                pointsAsArray = [[
                    [p0.x, p0.y],
                    [p1.x, p1.y],
                    [p2.x, p2.y],
                ]];
            }

        } else {
            pointsAsArray = [
                polygon.outerShell.points.map((p) => [p.x, p.y]),
                    ...(polygon.holes.map((p) => p.points.map(h => [h.x, h.y]))),
            ];
        }

		const {indices, position, uv, normal} = extrudePolygon([pointsAsArray], {
			depth: (polygon.topElevation - polygon.baseElevation) || 0.00001
		});
		for (let i = 2; i < position.length; i += 3) {
			position[i] += polygon.baseElevation;
		}
		const normalsInt = new Int8Array(normal.map(v => v * 127));
		return CreateKrGeometryFromData({
			indices: indices,
			edgesInds: null,
			normals: normalsInt,
			vertices: position,
			uvs: uv,
		});
	}

	static generateSphere(
		radius = 1,
		widthSegments = 32,
		heightSegments = 16,
		phiStart = 0,
		phiLength = Math.PI * 2,
		thetaStart = 0,
		thetaLength = Math.PI
	) {
		widthSegments = Math.max( 3, Math.floor( widthSegments ) );
		heightSegments = Math.max( 2, Math.floor( heightSegments ) );

		const thetaEnd = Math.min( thetaStart + thetaLength, Math.PI );

		let index = 0;
		const grid = [];

		const vertex = new Vector3();
		const normal = new Vector3();

		// buffers

		const indices = [];
		const vertices = [];
		const normals = [];
		const uvs = [];

		// generate vertices, normals and uvs

		for ( let iy = 0; iy <= heightSegments; iy ++ ) {

			const verticesRow = [];

			const v = iy / heightSegments;

			// special case for the poles

			let uOffset = 0;

			if ( iy == 0 && thetaStart == 0 ) {

				uOffset = 0.5 / widthSegments;

			} else if ( iy == heightSegments && thetaEnd == Math.PI ) {

				uOffset = - 0.5 / widthSegments;

			}

			for ( let ix = 0; ix <= widthSegments; ix ++ ) {

				const u = ix / widthSegments;

				// vertex

				vertex.x = - radius * Math.cos( phiStart + u * phiLength ) * Math.sin( thetaStart + v * thetaLength );
				vertex.y = radius * Math.cos( thetaStart + v * thetaLength );
				vertex.z = radius * Math.sin( phiStart + u * phiLength ) * Math.sin( thetaStart + v * thetaLength );

				vertices.push( vertex.x, vertex.y, vertex.z );

				// normal

				normal.copy( vertex ).normalize().multiplyScalar(127);
				normals.push( normal.x, normal.y, normal.z );

				// uv

				uvs.push( u + uOffset, 1 - v );

				verticesRow.push( index ++ );

			}

			grid.push( verticesRow );

		}

		// indices

		for ( let iy = 0; iy < heightSegments; iy ++ ) {

			for ( let ix = 0; ix < widthSegments; ix ++ ) {

				const a = grid[ iy ][ ix + 1 ];
				const b = grid[ iy ][ ix ];
				const c = grid[ iy + 1 ][ ix ];
				const d = grid[ iy + 1 ][ ix + 1 ];

				if ( iy !== 0 || thetaStart > 0 ) indices.push( a, b, d );
				if ( iy !== heightSegments - 1 || thetaEnd < Math.PI ) indices.push( b, c, d );

			}

		}

		const g = CreateKrGeometryFromData({
			vertices: new Float32Array(vertices),
			normals: new Int8Array(normals),
			uvs: new Float32Array(uvs),
			indices: new Uint16Array(indices),
			edgesInds: null,
		});
		return g;
	}

	static generateCube(size: Vector3, center: Vector3): GeometryGpuRepr {
		return generateBoxBufferGeometry(
			size.x, size.y, size.z, 1, 1, 1, center
		);
	}

	static generateBox(width: number, height: number, depth: number): GeometryGpuRepr {
		return generateBoxBufferGeometry(width, height, depth, 1, 1, 1, new Vector3(0, 0, 0));
	}

	static generatePlane(params: PlaneGeneratorParams, shaderFlags: ShaderFlags = 0): GeometryGpuRepr {

		const gridX = params.widthSegments ? Math.round(params.widthSegments) : KrMath.clamp(params.width / 4 | 0, 1, 100);
		const gridY = params.heightSegments ? Math.round(params.heightSegments) : KrMath.clamp(params.height / 4 | 0, 1, 100);

		const gridX1 = gridX + 1;
		const gridY1 = gridY + 1;

		let customHeights = params.customHeights;
		if (customHeights && customHeights.length !== gridX1 * gridY1) {
			console.error('custom heights length should be (ws + 1) * (hs + 1)', customHeights, params);
			customHeights = undefined;
		}

		const segment_width = params.width / gridX;
		const segment_height = params.height / gridY;

		const width_offset = KrMath.lerp(0, 1, params.centerLocalSpace?.x ?? 0.5) * params.width;
		const height_offset = KrMath.lerp(0, 1, params.centerLocalSpace?.y ?? 0.5) * params.height;

		const indices = [];
		const positions = [];
		// const normals = [];
		// // const uvs = [];

		for ( let iy = 0; iy < gridY1; iy ++ ) {

			const y = iy * segment_height - height_offset;

			for ( let ix = 0; ix < gridX1; ix ++ ) {

				const x = ix * segment_width - width_offset;

				const z = customHeights ? customHeights[iy * gridX1 + ix] : 0;

				positions.push( x, y, z);

				// normals.push( 0, 0, 1 );

				// uvs.push( ix / gridX, 1 - ( iy / gridY ));
			}

		}

		for ( let iy = 0; iy < gridY; iy ++ ) {

			for ( let ix = 0; ix < gridX; ix ++ ) {

				const a = ix + gridX1 * iy;
				const b = ix + gridX1 * ( iy + 1 );
				const c = ( ix + 1 ) + gridX1 * ( iy + 1 );
				const d = ( ix + 1 ) + gridX1 * iy;


				if ((ix + iy )% 2 == 0) {
					indices.push( a, d, b );
					indices.push( b, d, c );
				} else {
					indices.push( a, d, c );
					indices.push( a, c, b );
				}


			}

		}

		const edgesIndex: number[] = [];

		const uvTransform = new Matrix3();
		uvTransform.scale(1 / params.width, 1 /params.height);

		if (params.uvRect) {
			uvTransform.scale(
				params.uvRect.max.x - params.uvRect.min.x,
				params.uvRect.max.y - params.uvRect.min.y
			);
			uvTransform.translate(
				params.uvRect.min.x,
				params.uvRect.min.y
			);
		} else {
			uvTransform.translate(width_offset / params.width, height_offset / params.height);
		}

		const shaderInfo: PerGeoShaderInfo = {
			flags: ShaderFlags.UV_FROM_LOCAL_POSITION | ShaderFlags.UV_TRANSFORM | shaderFlags,
			uniforms: ['uvTransform', uvTransform]
		};



		// const edgesIndex = new Uint16Array([p0,p1, p1,p2, p2,p3, p3,p0]);
		return new GeometryGpuRepr(
			{
				position: new Float32BufferAttribute(new Float32Array(positions), 3),
			},
			new Uint16BufferAttribute(new Uint16Array(indices), 1),
			new Uint16BufferAttribute(new Uint16Array(edgesIndex), 1),
			shaderInfo
		);
	}

	static generateSplineGeometry(args: {
		points: Vector3[],
		width: number,
		tesselateWhenAppropriate: boolean
	}): GeometryGpuRepr {

		let points: Vector3[];
		if (args.tesselateWhenAppropriate && args.points.length > 1) {
			points = [];
			for (let i = 1; i < args.points.length; ++i) {
				const pPrev = args.points[i - 1];
				const pCurr = args.points[i];
				const dist = pPrev.distanceTo(pCurr);

				if (i >= 2) {
					const pPrevPrev = args.points[i - 2];
					const angleToPrevSegment = Vector3.angleBetweenSegments(pPrevPrev, pPrev, pCurr);
					if (Math.abs(angleToPrevSegment) < Math.PI * 0.7) {
						points.push(pPrev);
					}
				}

				const additionalPointsToInsertInSegment = Math.sqrt(Math.max((dist / 3) - 1, 0)) | 0;

				points.push(pPrev);
				for (let i = 0; i < additionalPointsToInsertInSegment; ++i) {
					const t = (i + 1) / (additionalPointsToInsertInSegment + 2);
					const pInterm = pPrev.clone().lerpTo(pCurr, t);
					points.push(pInterm);
				}

			}
			points.push(args.points[args.points.length - 1]);
		} else {
			points = args.points;
		}

		// modified MeshLine

		const pointsFlatDoubled: number[] = [];
		const distance: number[] = [];
		let distanceSum: number = 0;
		for (let i = 0; i < points.length; ++i) {
			const p = points[i];
			pointsFlatDoubled.push(p.x, p.y, p.z);
			pointsFlatDoubled.push(p.x, p.y, p.z);
			if (i > 0) {
				const segmentLength = p.distanceTo(points[i - 1]);
				distanceSum += segmentLength;
			}
			distance.push(distanceSum, distanceSum);
		}
		const l = pointsFlatDoubled.length / 6;

		const previous = [];
		const next = [];
		const side = [];
		const indices_array = [];

		const width = args.width || 0.001;

		function compareV3(a: number, b: number) {
			var aa = a * 6
			var ab = b * 6
			return (
			  pointsFlatDoubled[aa] === pointsFlatDoubled[ab] &&
			  pointsFlatDoubled[aa + 1] === pointsFlatDoubled[ab + 1] &&
			  pointsFlatDoubled[aa + 2] === pointsFlatDoubled[ab + 2]
			)
		}
		function copyV3(a: number) {
			var aa = a * 6
			return [pointsFlatDoubled[aa], pointsFlatDoubled[aa + 1], pointsFlatDoubled[aa + 2]];
		}

		let v;
		// initial previous points
		if (compareV3(0, l - 1)) {
			v = copyV3(l - 2);
		} else {
			v = copyV3(0);
		}
		previous.push(v[0], v[1], v[2]);
		previous.push(v[0], v[1], v[2]);

		for (let j = 0; j < l; j++) {
			side.push(width);
			side.push(-1 * width);

			if (j < l - 1) {
				// points previous to poisitions
				v = copyV3(j)
				previous.push(v[0], v[1], v[2])
				previous.push(v[0], v[1], v[2])

				// indices
				const n = j * 2
				indices_array.push(n, n + 1, n + 2)
				indices_array.push(n + 2, n + 1, n + 3)
			}
			if (j > 0) {
				// points after poisitions
				v = copyV3(j)
				next.push(v[0], v[1], v[2])
				next.push(v[0], v[1], v[2])
			}
		}

		// last next point
		if (compareV3(l - 1, 0)) {
			v = copyV3(1)
		} else {
			v = copyV3(l - 1)
		}
		next.push(v[0], v[1], v[2])
		next.push(v[0], v[1], v[2])


		const indicesArrayBuffer = pointsFlatDoubled.length / 3 <= 0xFFFF ? 
			new BufferAttribute(new Uint16Array(indices_array), 1) :
			new BufferAttribute(new Uint32Array(indices_array), 1);

		const g = new GeometryGpuRepr(
			{
				position: new BufferAttribute(new Float32Array(pointsFlatDoubled), 3),
				previous: new BufferAttribute(new Float32Array(previous), 3),
				next: new BufferAttribute(new Float32Array(next), 3),
				side: new BufferAttribute(new Float32Array(side), 1),
				distance: new BufferAttribute(new Float32Array(distance), 1),
			},
			indicesArrayBuffer,
			indicesArrayBuffer,
			{
				flags: ShaderFlags.IS_LINE_GEO,
				uniforms: [],
			}
		);
		return g;

	}

	static generateTextBlock(textOptions: TextLayoutOptions[], blockOptions: BlockOptions): GeometryGpuRepr
	{
		const paragraphs: Text[] = [];
		for (const opts of textOptions) {
			paragraphs.push(new Text(opts));
		}
		const block = new Block(blockOptions, paragraphs);
		const textRepr = block.createText();

		return textRepr;
	}

	static mergeBufferGeometries<G extends BufferGeometry | GeometryGpuRepr>( 
		geometriesPlusTransforms: {geo: G, tr: Transform}[] ): G | null
	{
		if (geometriesPlusTransforms.length === 0) {
			return null;
		}

		// originally from three.js buffergoemetry utils, modified

		const isIndexed = geometriesPlusTransforms[ 0 ].geo.index !== null;
		const attributesUsed = new Set( Object.keys( geometriesPlusTransforms[ 0 ].geo.attributes ) );
		const attributes = new DefaultMap<string, BufferAttribute[]>(() => []);
		const mergedGeometry = new BufferGeometry();

		for ( const {geo, tr} of geometriesPlusTransforms) {

			const matrix = tr.toMatrix4(new Matrix4());

			let attributesCount = 0; // ensure that all geometries are indexed, or none

			 // gather attributes, exit early if they're different

			if ( isIndexed !== ( geo.index !== null ) ) {
				console.error( 'All geometries must have compatible attributes; make sure index attribute exists among all geometries, or in none of them.' );
				return null;
			}

			// ensure geometries have the same number of attributes
			for ( const name in geo.attributes ) {
				if ( ! attributesUsed.has( name ) ) {
					console.error( 'All geometries must have compatible attributes; make sure "' + name + '" attribute exists among all geometries, or in none of them.' );
					return null;
				}
				let attr = geo.attributes[ name ];
				if (name === 'position') {
					attr = attr.clone().applyMatrix4(matrix);
				} else {
					//TODO for normals
				}
				attributes.getOrCreate(name).push( attr );
				attributesCount ++;
			}

			if ( attributesCount !== attributesUsed.size ) {
				console.error( 'Make sure all geometries have the same number of attributes.' );
				return null;
			}
		}


		for ( const [name, attrs] of attributes ) {
			const mergedAttribute = this.mergeBufferAttributes(attrs);
			if ( ! mergedAttribute ) {
				console.error( 'BufferGeometryUtils: .mergeBufferGeometries() failed while trying to merge the ' + name + ' attribute.' );
				return null;
			}
			mergedGeometry.setAttribute( name, mergedAttribute );
		}

		// merge indices
		if ( isIndexed ) {
			let indexOffset = 0;
			const mergedIndex: number[] = [];
			for ( const {geo, tr} of geometriesPlusTransforms) {

				const index = geo.index;

				if (tr.scale.x * tr.scale.y * tr.scale.z < 0) {
					// reorder triangles
					console.log('reorder triangles');
					for ( let j = 0; j < index.count; j += 3 ) {
						mergedIndex.push( index.getX( j + 2 ) + indexOffset );
						mergedIndex.push( index.getX( j + 1 ) + indexOffset );
						mergedIndex.push( index.getX( j + 0 ) + indexOffset );
					}
				} else {
					for ( let j = 0; j < index.count; ++ j ) {
						mergedIndex.push( index.getX( j ) + indexOffset );
					}
				}

				indexOffset += geo.attributes.position.count;
			}
			mergedGeometry.setIndex( mergedIndex );
		}


		if (geometriesPlusTransforms[0].geo instanceof GeometryGpuRepr) {
			let indexOffset = 0;
			const mergedEdgesIndex: number[] = [];
			for ( const {geo, tr} of geometriesPlusTransforms) {

				const edgesIndex = (geo as GeometryGpuRepr).edgesIndex;

				for ( let j = 0; j < edgesIndex.count; ++ j ) {
					mergedEdgesIndex.push( edgesIndex.getX( j ) + indexOffset );
				}

				indexOffset += geo.attributes.position.count;
			}

			return new GeometryGpuRepr(
				mergedGeometry.attributes,
				mergedGeometry.index,
				new BufferAttribute(new Uint32Array(mergedEdgesIndex), 1),
			) as G;
		} else {
			return mergedGeometry as G;
		}
	}

	static mergeBufferAttributes( attributes: BufferAttribute[] ) {

		let typedArray: {new(length: number): TypedArray} | undefined = undefined;
		let itemSize: number | undefined = undefined;
		let normalized: boolean | undefined = undefined;
		let arrayLength = 0;

		for ( let i = 0; i < attributes.length; ++ i ) {

			const attribute = attributes[ i ];

			if ( typedArray === undefined ) typedArray = attribute.array?.constructor as any;

			if ( typedArray !== attribute.array?.constructor ) {
				console.error( 'BufferAttribute.array must be of consistent array types across matching attributes.' );
				return null;
			}

			if ( itemSize === undefined ) itemSize = attribute.itemSize;

			if ( itemSize !== attribute.itemSize ) {
				console.error( 'BufferAttribute.itemSize must be consistent across matching attributes.' );
				return null;
			}

			if ( normalized === undefined ){
				normalized = attribute.normalized;
			}

			if ( normalized !== attribute.normalized ) {
				console.error( 'BufferAttribute.normalized must be consistent across matching attributes.' );
				return null;
			}

			arrayLength += attribute.array!.length;

		}

		const array = new typedArray!( arrayLength );
		let offset = 0;

		for ( let i = 0; i < attributes.length; ++ i ) {
			array.set( attributes[i].array!, offset );
			offset += attributes[i].array!.length;
		}
		return new BufferAttribute( array, itemSize!, normalized );
	}

}

export interface SphereGeneratorParams {

}

export interface PlaneGeneratorParams {
	width: number,
	height: number,

	widthSegments?: number,
	heightSegments?: number,

	centerLocalSpace?: Vector2, //(0,0) is top left
	customHeights?: TypedArray

	uvRect?: Aabb2
};


function generateBoxBufferGeometry(
	width: number,
	height: number,
	depth: number,
	widthSegments: number,
	heightSegments: number,
	depthSegments: number,
	center: Vector3
):
	GeometryGpuRepr {

	widthSegments = Math.floor( widthSegments ) || 1;
	heightSegments = Math.floor( heightSegments ) || 1;
	depthSegments = Math.floor( depthSegments ) || 1;

	// todo: calculate buffer size beforehand
	const indices: number[] = [];
	const vertices: number[] = [];
	const normals: number[] = [];
	const uvs: number[] = [];

	// helper constiables

	let numberOfVertices = 0;

	// build each side of the box geometry
	buildPlane( 2, 1, 0, -1, -1, depth, height,  width,  depthSegments, heightSegments ); // px
	buildPlane( 2, 1, 0,  1, -1, depth, height, -width,  depthSegments, heightSegments ); // nx
	buildPlane( 0, 2, 1,  1,  1, width, depth,   height, widthSegments, depthSegments );  // py
	buildPlane( 0, 2, 1,  1, -1, width, depth,  -height, widthSegments, depthSegments );  // ny
	buildPlane( 0, 1, 2,  1, -1, width, height,   depth, widthSegments, heightSegments ); // pz
	buildPlane( 0, 1, 2, -1, -1, width, height,  -depth, widthSegments, heightSegments ); // nz

	function buildPlane( u:index, v:index, w:index, udir:number, vdir:number, width:number, height:number, depth:number, gridX:number, gridY:number ) {

		const segmentWidth = width / gridX;
		const segmentHeight = height / gridY;

		const widthHalf = width / 2;
		const heightHalf = height / 2;
		const depthHalf = depth / 2;

		const gridX1 = gridX + 1;
		const gridY1 = gridY + 1;

		let vertexCounter = 0;


		const vector = Vector3.zero();

		// generate vertices, normals and uvs

		for ( let iy = 0; iy < gridY1; iy ++ ) {

			const y = iy * segmentHeight - heightHalf;

			for ( let ix = 0; ix < gridX1; ix ++ ) {

				const x = ix * segmentWidth - widthHalf;

				// set values to correct vector component

				vector.setComponent( u, x * udir );
				vector.setComponent( v, y * vdir );
				vector.setComponent( w, depthHalf);

				vertices.push( vector.x, vector.y, vector.z );

				vector.setComponent( u, 0);
				vector.setComponent( v, 0);
				vector.setComponent( w, depth > 0 ? 1 : - 1);

				normals.push( vector.x, vector.y, vector.z );

				uvs.push( ix / gridX );
				uvs.push( 1 - ( iy / gridY ) );

				vertexCounter += 1;
			}

		}

		for ( let iy = 0; iy < gridY; iy ++ ) {

			for ( let ix = 0; ix < gridX; ix ++ ) {

				const a = numberOfVertices + ix + gridX1 * iy;
				const b = numberOfVertices + ix + gridX1 * ( iy + 1 );
				const c = numberOfVertices + ( ix + 1 ) + gridX1 * ( iy + 1 );
				const d = numberOfVertices + ( ix + 1 ) + gridX1 * iy;

				indices.push( a, b, d );
				indices.push( b, c, d );
			}
		}
		numberOfVertices += vertexCounter;
	}

	for (let i = 0; i < vertices.length; i += 3) {
		vertices[i + 0] += center.x;
		vertices[i + 1] += center.y;
		vertices[i + 2] += center.z;
	}

	return CreateKrGeometryFromData({
		indices: new Uint16Array(indices),
		edgesInds: null,
		vertices: new Float32Array(vertices),
		normals: new Int8Array(normals.map(n => n * 127)),
		uvs: new Float32Array(uvs),
	});
}


