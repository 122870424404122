import { DialogDescription, type UiBindings, UiCustomNode } from 'ui-bindings';

function openHelpDialog(uiBindings: UiBindings, header: string, context: string) {
    uiBindings.addDialog(
        new DialogDescription({
            id: "help-dialog",
            name: header,
            context: null,
            uiSource: new UiCustomNode({ context }),
        }),
    );
}

export function openLayoutHelp(uiBindings: UiBindings) {
    openHelpDialog(uiBindings, "Layout geometry and offsets", "layout");
}

export function openBoundariesHelp(uiBindings: UiBindings) {
    openHelpDialog(
        uiBindings,
        "Boundaries and buildable area source",
        "buildable-area",
    );
}