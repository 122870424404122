import type { NumberProperty } from "../../properties/PrimitiveProps";
import { ReadableTypeIdentifiers } from "../../ReadableTypeIdentifiers";
import type { Catalog } from "../Catalog";
import type { CatalogItemsUiLabels, CatalogItemUiLabels } from "../CatalogItemsUiLabels";
import { extractInstanceHierarchyGroup } from "../SceneInstanceHierarchyPropsRegistry";
import type { AssetRelatedCatalogItem } from "./AssetRelatedCatalogItem";

export const AssetCatalogItemTypeIdentifier = 'asset';

export interface AssetCatalogItemProps extends AssetRelatedCatalogItem {
    price_each: NumberProperty;
}

export function registerAssetCatalogItemUiLabels(
    labelsProvider: CatalogItemsUiLabels,
    catalog: Catalog,
) {
    labelsProvider.register<AssetCatalogItemProps>(
        AssetCatalogItemTypeIdentifier,
        (item) => {
            const assetId = item.asset_id.value;
            const assetSi = catalog.assets.sceneInstancePerAsset.getAssetAsSceneInstance(assetId);
            const asset = catalog.assets.peekById(assetId);
            let title = 'unknown asset';
            const group: CatalogItemUiLabels['group'] = [];
            if (asset?.name) {
                title = asset.name;
            }
            if (assetSi) {
                const readableTypeIdentifier =
                    ReadableTypeIdentifiers.get(assetSi.type_identifier) ??
                    assetSi.type_identifier;
                const rootGroup = [readableTypeIdentifier];
                group.push(rootGroup);
                if (!asset?.name) {
                    const assetTitle = catalog.keyPropertiesGroupFormatters.format(
                        assetSi.type_identifier,
                        assetSi.properties,
                        assetSi.props,
                    );
                    if (assetTitle) {
                        title = assetTitle;
                    }
                }
                const hierarchyProps = extractInstanceHierarchyGroup(assetSi)
                if (hierarchyProps) {
                    group.push(...hierarchyProps);
                }
            }

            const priceStr = item.price_each
                .valueUnitUiString(catalog.unitsMapper);
            const result: CatalogItemUiLabels = {
                group: group,
                title: title,
                price: priceStr,
            };
            return result;
        }
    );
}

