<script lang="ts">
    import { Bim, EnergyPipelineStage, EnergyStageName } from "bim-ts";
    import EnergySetupBar from "./EnergySetupBar.svelte";
    import EnergyLossChartTransition from "./ChartPortionTransition.svelte";
    import {
        createEnergySetupPui,
        type EnergySetupSettings,
    } from "./EnergySetupSettings";
    import { getContext } from "svelte";
    import { calculateBars } from "./CalculateBarChart";
    import { VersionedStore } from "../../VersionedStore";

    export let stageNames: EnergyStageName[];
    export let allStages: EnergyPipelineStage[];

    export let lastEnergyLength = 0;
    export let prevEnergyLength = 0;
    export let showResultBar = false;

    const bim = getContext<Bim>("bim");
    let stagePropsUi = createEnergySetupPui(bim);
    const lossPropsUi = new VersionedStore<EnergySetupSettings>(stagePropsUi);

    $: stages = stageNames.map((name) =>
        allStages.find((stage) => stage.name === name),
    );
    $: bars = calculateBars(
        stages.filter(
            (s): s is EnergyPipelineStage => s instanceof EnergyPipelineStage,
        ),
    );

    $: {
        lastEnergyLength = lastBar?.energyLength ?? 0;
    }

    $: lastBar = {
        ...bars.get(stageNames.at(-1)!),
        lossLength: 0,
        lossLabel: "",
    };
</script>

<div class="mdc-typography--body1">
    {#each stageNames as name, index}
        {#if index}
            <EnergySetupBar
                {name}
                bar={bars.get(name)}
                stage={stages[index]}
                lossPropsUi={$lossPropsUi[name]}
            />
        {:else if showResultBar}
            <EnergySetupBar name="" bar={bars.get(name)}>
                <EnergyLossChartTransition
                    startEnergyLength={prevEnergyLength}
                    endEnergyLength={bars.get(name)?.energyLength || 0}
                />
            </EnergySetupBar>
        {:else}
            <EnergySetupBar
                {name}
                bar={bars.get(name)}
                stage={stages[index]}
                lossPropsUi={$lossPropsUi[name]}
            />
        {/if}
    {/each}
    <!-- {#if showResultBar && lastBar}
        <EnergySetupBar name="Annual yield" bar={lastBar} />
    {/if} -->
</div>

<style lang="scss">
</style>
