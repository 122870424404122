// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { TerrainTileT } from './terrain-tile.js';
import { TerrainTile } from './terrain-tile.js';
import type { TerrainTileIdT } from './terrain-tile-id.js';
import { TerrainTileId } from './terrain-tile-id.js';


export class TerrainHeightMapRepresentation implements flatbuffers.IUnpackableObject<TerrainHeightMapRepresentationT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):TerrainHeightMapRepresentation {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsTerrainHeightMapRepresentation(bb:flatbuffers.ByteBuffer, obj?:TerrainHeightMapRepresentation):TerrainHeightMapRepresentation {
  return (obj || new TerrainHeightMapRepresentation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsTerrainHeightMapRepresentation(bb:flatbuffers.ByteBuffer, obj?:TerrainHeightMapRepresentation):TerrainHeightMapRepresentation {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new TerrainHeightMapRepresentation()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

tileSize():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

tilesIds(index: number, obj?:TerrainTileId):TerrainTileId|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new TerrainTileId()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

tilesIdsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

tiles(index: number, obj?:TerrainTile):TerrainTile|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new TerrainTile()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

tilesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startTerrainHeightMapRepresentation(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addTileSize(builder:flatbuffers.Builder, tileSize:number) {
  builder.addFieldFloat64(0, tileSize, 0.0);
}

static addTilesIds(builder:flatbuffers.Builder, tilesIdsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, tilesIdsOffset, 0);
}

static createTilesIdsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startTilesIdsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addTiles(builder:flatbuffers.Builder, tilesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, tilesOffset, 0);
}

static createTilesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startTilesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endTerrainHeightMapRepresentation(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createTerrainHeightMapRepresentation(builder:flatbuffers.Builder, tileSize:number, tilesIdsOffset:flatbuffers.Offset, tilesOffset:flatbuffers.Offset):flatbuffers.Offset {
  TerrainHeightMapRepresentation.startTerrainHeightMapRepresentation(builder);
  TerrainHeightMapRepresentation.addTileSize(builder, tileSize);
  TerrainHeightMapRepresentation.addTilesIds(builder, tilesIdsOffset);
  TerrainHeightMapRepresentation.addTiles(builder, tilesOffset);
  return TerrainHeightMapRepresentation.endTerrainHeightMapRepresentation(builder);
}

unpack(): TerrainHeightMapRepresentationT {
  return new TerrainHeightMapRepresentationT(
    this.tileSize(),
    this.bb!.createObjList<TerrainTileId, TerrainTileIdT>(this.tilesIds.bind(this), this.tilesIdsLength()),
    this.bb!.createObjList<TerrainTile, TerrainTileT>(this.tiles.bind(this), this.tilesLength())
  );
}


unpackTo(_o: TerrainHeightMapRepresentationT): void {
  _o.tileSize = this.tileSize();
  _o.tilesIds = this.bb!.createObjList<TerrainTileId, TerrainTileIdT>(this.tilesIds.bind(this), this.tilesIdsLength());
  _o.tiles = this.bb!.createObjList<TerrainTile, TerrainTileT>(this.tiles.bind(this), this.tilesLength());
}
}

export class TerrainHeightMapRepresentationT implements flatbuffers.IGeneratedObject {
constructor(
  public tileSize: number = 0.0,
  public tilesIds: (TerrainTileIdT)[] = [],
  public tiles: (TerrainTileT)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const tilesIds = TerrainHeightMapRepresentation.createTilesIdsVector(builder, builder.createObjectOffsetList(this.tilesIds));
  const tiles = TerrainHeightMapRepresentation.createTilesVector(builder, builder.createObjectOffsetList(this.tiles));

  return TerrainHeightMapRepresentation.createTerrainHeightMapRepresentation(builder,
    this.tileSize,
    tilesIds,
    tiles
  );
}
}
