<script lang="ts">
import { ObjectUtils } from "engine-utils-ts";
import type { TemplateDescription } from "../../../quantities-table/Models";
import PathEditor from "../../components/PathEditor.svelte";
import RemapperEditor from "../../components/RemapperEditor.svelte";
import { Action } from "../Action";
import { QuantityTemplate, QuantityTemplateImportConfigTypeIdentifier } from "./QuantityTemplate";


export let exit: (newTemplate?: Action<QuantityTemplate>) => void;
export let action: Action<QuantityTemplate> | undefined;

let editableAction = action
    ? ObjectUtils.deepCloneObj(action)
    : new Action(
        'quantity template',
        QuantityTemplateImportConfigTypeIdentifier,
        new QuantityTemplate()
    )


function loadTemplateFromQty() {
    const loadedTemplate: TemplateDescription | undefined =
    // @ts-ignore
        window.pullTemplateFromQuantity();
    if (loadedTemplate) {
        editableAction.props.template = loadedTemplate;
    }
}
function applyTemplateToQty() {
    if (editableAction.props.template) {
        // @ts-ignore
        window.setQuantityTemplateState(editableAction.props.template)
    }
}


function save() {
    if (action) {
        Object.assign(action, editableAction);
        return exit();
    }
    return exit(editableAction);
}

$: columnsOptions = [
    '',
    ...(editableAction.props.template?.data.valueCols ?? [])
];

$: template = editableAction.props.template;
</script>

<a class="basic-interact" on:click={() => exit()}>{'<'}</a>

<h3>Quantity template editor</h3>

<p>disable</p>
<input bind:checked={editableAction.disable} type="checkbox" />

<p>name</p>
<input class="basic-interact" bind:value={editableAction.name} />

<h3>template({template ? "loaded" : "not loaded"})</h3>
<button class="basic-interact" on:click={loadTemplateFromQty}>load from qty</button>

{#if template}
    <button class="basic-interact" on:click={applyTemplateToQty}>apply to qty</button>

    <p>grouping columns:&nbsp;<b>{template.data.rowGroupCols.join(' > ')}</b></p>

    <p>filters:&nbsp;<b>{JSON.stringify(template.data.filterModel)}</b></p>

    <p>value columns:&nbsp;<b>{template.data.valueCols.join(', ')}</b></p>


    <h3>display from level idx:</h3>
    <p>
        (
            if grouping is: <b>cost_bs 1 > type > gauge</b>.
            But you want to flatten level 1(<b>cost_bs 1</b>,
            and as a result to get grouping hierarchy: <b>type > gauge</b>,
            use <b>display from level idx = 2</b>.
        )
    </p>
    <input
        class="basic-interact"
        type="number"
        bind:value={editableAction.props.displayFromLevelIdx}
    />

    <h3>quantity column:</h3>
    <p>
        (
            what column should be used as quantity column in cost report.
            skip if you want to set quantity manually
        )
    </p>
    <select class="basic-interact" bind:value={editableAction.props.quantityColumnId}>
        {#each columnsOptions as colId}
            <option value={colId}>
                {colId}
            </option>
        {/each}
    </select>

    <h3>rate column:</h3>
    <p>
        (
            what column should be used as rate column in cost report.
            skip if you want to set rate manually
        )
    </p>
    <select class="basic-interact" bind:value={editableAction.props.rateColumnId}>
        {#each columnsOptions as colId}
            <option value={colId}>
                {colId}
            </option>
        {/each}
    </select>


    <h3>rate unit column:</h3>
    <p>
        (
            set if rate should be applied to multiple of quantity.
            Ex. if quantity is 100, but rate is per 10 items.
            This column is not shown in cost report table. But it affects rate column
        )
    </p>
    <select class="basic-interact" bind:value={editableAction.props.rateUnitColumnId}>
        {#each columnsOptions as colId}
            <option value={colId}>
                {colId}
            </option>
        {/each}
    </select>


    <h3>prefix:</h3>
    <p>
        (
            if grouping is: <b>cost_bs 1 > type > gauge</b>, but you want 
            <b>prefix1 > prefix2 > cost_bs 1 > type > gauge</b>.
            add 2 prefix levels: <b>prefix1</b>, <b>prefix2</b>
        )
    </p>
    <PathEditor bind:path={editableAction.props.prefixLevels} />

    <h3>quantity remapper</h3>
    <RemapperEditor
        change={(newVal) => editableAction.props.quantityRemapper = newVal}
        value={editableAction.props.quantityRemapper}
    />

    <h3>rate remapper</h3>
    <RemapperEditor
        change={(newVal) => editableAction.props.rateRemapper = newVal}
        value={editableAction.props.rateRemapper}
    />

    <br />
    <br />
    <a class="basic-interact" on:click={save}>Done</a>
{/if}
