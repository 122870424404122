import { IterUtils } from 'engine-utils-ts';
import type { Vector2Like } from 'math-ts';
import { Vector2 } from 'math-ts';
import { closestDistTo, equalPoints, isEqual } from "./LayoutUtils";
import { PointsSet } from './PointsSet';

export class SplitEdges<T extends Vector2Like> {
    private readonly points: T[];
    constructor(points: T[]) {
        const pointsCloud = new PointsSet<T>();
        pointsCloud.IdxArray(points);
        this.points = pointsCloud.ToArray().sort((a,b)=>{return a.x-b.x});
    }

    public Split(polyline: T[]): T[] {
        const edges: [T, T][] = [];
        for (let i = 0; i < polyline.length - 1; i++) {
            const fst = polyline[i];
            const snd = polyline[i + 1];
            const splittedEdges = this.SplitEdge([fst, snd]);
            IterUtils.extendArray(edges, splittedEdges);
        }
        const newPolyline: T[] = [];
        for (const [fst, snd] of edges) {
            newPolyline.push(fst);
        }
        const [_, last] = edges[edges.length - 1]
        newPolyline.push(last);
        return newPolyline;
    }

    private SplitEdge([edgeFst, edgeSnd]: [T, T]): [T, T][] {
        let pointsOnEdge: { point: T; dist: number }[] = [];
        const pointsRange = getPoints(this.points, edgeFst, edgeSnd);
        if(pointsRange === undefined){
            return [[edgeFst, edgeSnd]];
        }
        const fst = new Vector2(edgeFst.x, edgeFst.y);
        const snd = new Vector2(edgeSnd.x, edgeSnd.y);
        for (const point of pointsRange) {
            const point2 = new Vector2(point.x, point.y);
            const isPointOnLineRes = isPointOnLine([fst, snd], point2);
            if (isPointOnLineRes) {
                pointsOnEdge.push({ point, dist: fst.distanceTo(point2) });
            }
        }
        pointsOnEdge = pointsOnEdge.sort((a, b) => {
            return a.dist - b.dist;
        });
        const newEdgePoints: T[] = [edgeFst];
        for (const pos of pointsOnEdge) {
            newEdgePoints.push(pos.point);
        }
        newEdgePoints.push(edgeSnd);
        const result: [T, T][] = [];
        for (let i = 0; i < newEdgePoints.length - 1; i++) {
            const edge: [T, T] = [newEdgePoints[i], newEdgePoints[i + 1]];
            result.push(edge);
        }

        return result;
    }
}

function getPoints<T extends Vector2Like>(points: T[], fst: T, snd: T) {
    const minX = fst.x > snd.x ? snd : fst;
    const maxX = fst.x > snd.x ? fst : snd;
    let fstIndex = binarySearch(points, minX);
    if (fstIndex === undefined) {
        console.error("Point not found: ", minX);
        return;
    }

    for (let i = fstIndex - 1; i > 0; i -= 1) {
        const point = points[i];
        if (isEqual(point.x, minX.x)) {
            fstIndex = i;
        } else {
            break;
        }
    }
    let sndIndex = binarySearch(points, maxX);
    if (sndIndex === undefined) {
        console.error("Point not found: ", maxX);
        return;
    }
    for (let i = sndIndex + 1; i < points.length; i++) {
        const point = points[i];
        if (isEqual(point.x, maxX.x)) {
            sndIndex = i;
        } else {
            break;
        }
    }

    return points.slice(fstIndex, sndIndex + 1);
}

function binarySearch(points: Vector2Like[], point: Vector2Like){
    let low = 0;
    let hight = points.length - 1;
    while (low <= hight) {
        const mid = Math.trunc((low + hight) / 2);
        const guess = points[mid];
        if (isEqual(guess.x, point.x)) {
            return mid;
        }
        if (guess.x > point.x) {
            hight = mid - 1;
        } else {
            low = mid + 1;
        }
    }
    return undefined;
}

function isPointOnLine([fst, snd]: [Vector2, Vector2], pos: Vector2):boolean{
    const dist = closestDistTo([fst, snd], pos); 
    const isPointOnLine = isEqual(dist, 0);
    const result = !equalPoints(fst, pos) && !equalPoints(snd, pos) && isPointOnLine;
    return result;
}