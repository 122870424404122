<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        d="M3.80001 3.8002H6.00001V2.2002H3.50001C2.78204 2.2002 2.20001 2.78223 2.20001 3.5002V6.0002H3.80001V3.8002Z"
        fill="currentColor"
    />
    <path
        d="M16.2 3.8002H14V2.2002H16.5C17.218 2.2002 17.8 2.78223 17.8 3.5002V6.0002H16.2V3.8002Z"
        fill="currentColor"
    />
    <path
        d="M6.00001 16.2002H3.80001V14.0002H2.20001V16.5002C2.20001 17.2182 2.78204 17.8002 3.50001 17.8002H6.00001V16.2002Z"
        fill="currentColor"
    />
    <path
        d="M16.2 16.2002H14V17.8002H16.5C17.218 17.8002 17.8 17.2182 17.8 16.5002V14.0002H16.2V16.2002Z"
        fill="currentColor"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.67511 4.26934C9.88197 4.17741 10.1181 4.17741 10.3249 4.26934L14.8249 6.26934C15.1138 6.39774 15.3 6.68424 15.3 7.00039V13.0004C15.3 13.3165 15.1138 13.603 14.8249 13.7314L10.3249 15.7314C10.1181 15.8234 9.88197 15.8234 9.67511 15.7314L5.17511 13.7314C4.88621 13.603 4.70002 13.3165 4.70002 13.0004V7.00039C4.70002 6.68424 4.88621 6.39774 5.17511 6.26934L9.67511 4.26934ZM6.3 8.23123V7.52007L7.46972 7.0002L10 8.12478L12.5303 7.00021L13.7 7.52007V8.23126L10.8 9.52013V13.7691L10 14.1247L9.20003 13.7692V9.52013L6.3 8.23123Z"
        fill="currentColor"
    />
</svg>
