<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path d="M4 4L5 3L8.0223 6L11 3L12 4L8.0223 8L4 4Z" fill="currentColor" />
    <path d="M4 9L5 8L8.0223 11L11 8L12 9L8.0223 13L4 9Z" fill="currentColor" />
</svg>
