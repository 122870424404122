<script lang="ts">
    import { MDCLinearProgress } from "@material/linear-progress";
    import { onMount } from "svelte";

    export let finished: boolean;

    let progressBarEl: HTMLDivElement;
    let progressBar: MDCLinearProgress;
    onMount(() => {
        progressBar = new MDCLinearProgress(progressBarEl);
        progressBar.open();
        progressBar.determinate = false;
        progressBar.progress = 43;
    });

    $: {
        if (progressBar) {
            progressBar.determinate = finished;
        }
    }
</script>

<div
    role="progressbar"
    class="
        mdc-linear-progress
        mdc-linear-progress--indeterminate
    "
    class:determinate={finished}
    aria-label="Example Progress Bar"
    aria-valuemin={0}
    aria-valuemax={1}
    aria-valuenow={0.3}
    bind:this={progressBarEl}
>
    <div class="mdc-linear-progress__buffer">
        <div class="mdc-linear-progress__buffer-bar" />
        <div class="mdc-linear-progress__buffer-dots" />
    </div>
    <div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
        <span class="mdc-linear-progress__bar-inner" />
    </div>
    <div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
        <span class="mdc-linear-progress__bar-inner" />
    </div>
</div>

<style lang="scss">
    @use "@material/linear-progress";

    .mdc-linear-progress {
        @include linear-progress.bar-color(var(--color-accent));

        &.determinate {
            @include linear-progress.bar-color(var(--color-good));
        }
    }
</style>
