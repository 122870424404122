import type { LazyVersioned } from "engine-utils-ts";
import { LazyDerivedAsync } from "engine-utils-ts";
import type { Bim } from "src";
import { NumberProperty } from "src";
import { create_ElectricalSubtotal_AcDcElectricalCable_LV } from "./lv";
import { create_ElectricalSubtotal_AcDcElectricalCable_MV } from "./mv";
import type { CostComponentsNonNullable, CostsConfigProvider } from "src/cost-model/capital";
import { CostHierarchy } from "src/cost-model/capital";

export function create_ElectricalSubtotal_Electrical_Cable(
    bim: Bim,
    costs: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>
) {
    const unitsMapper = bim.unitsMapper;

    const lv = create_ElectricalSubtotal_AcDcElectricalCable_LV(bim, costs, totalDC);
    const mv = create_ElectricalSubtotal_AcDcElectricalCable_MV(bim, costs, totalDC);

    const result = LazyDerivedAsync.new2<
        { lv: CostHierarchy, mv: CostHierarchy },
        CostHierarchy,
        CostHierarchy
    >(
        'ElectricalSubtotal_Electrical_Cable',
        [unitsMapper],
        [lv, mv],
        function* ([lv, mv]) {
            const hierarchy = new CostHierarchy();

            hierarchy.merge(lv);
            hierarchy.merge(mv);

            return { mv, lv };
        }
    )

    return result;
}

export function getAcDcElectricalCablesBenchmarkDefaults(): CostComponentsNonNullable {
    const result: CostComponentsNonNullable = {
        laborTimeUnits: NumberProperty.new({ value: 0 }),
        laborTimeUnitCost: NumberProperty.new({ value: 0 }),
        laborCost: NumberProperty.new({ value: 0 }),
        equipmentCost: NumberProperty.new({ value: 0 }),
        materialCost: NumberProperty.new({ value: 0 }),
        serviceCost: NumberProperty.new({ value: 0 }),
    }
    return result;
}
