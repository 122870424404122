import type { SceneInstance } from "src";
import type { PUI_Node } from "ui-bindings";
import { PUI_ActionsNode, PUI_GroupNode } from "ui-bindings";
import type { CostModelFocusApi } from ".";

export const createFocusLinkOnSample = (params: {
    sample?: SceneInstance,
    type_identifier?: string,
    targetPui?: PUI_Node,
    costModelFocusApi: CostModelFocusApi,
    label?: string
}) => {
    const { targetPui, type_identifier, label } = params;
    if (!targetPui || !(targetPui instanceof PUI_GroupNode)) {
        return;
    }
    const action = new PUI_ActionsNode({
        actions: [
            {
                action: () => params.costModelFocusApi.focusOnCostModel({ si: params.sample, type_identifier }),
                style: { icon: 'Cost', compact: true, type: 'secondary' },
                label: label ?? 'Setup costs',
            }
        ],
        context: {},
        name: '',
        typeSortKeyOverride: 100,

    })
    targetPui.addMaybeChild(action);
}
