
<script lang="ts">
    import { ObjectUtils } from "engine-utils-ts";
    import flash from "../flash";

    export let name: string;
    export let value: {
        angleDeg: number;
        shiftDeg: number;
    };

    let div: HTMLElement;
    let preValue = value;
    $: if (!ObjectUtils.areObjectsEqual(preValue, value) && div) {
        preValue = value;
        flash(div);
    }

    $:_angleDeg = value.shiftDeg;
    $:_shiftDeg = -value.shiftDeg + value.angleDeg;

</script>

<div class="ui-config-property property-row">
    <div bind:this={div} class="property-name text-main-medium">
        <div class="property-name-label mdc-typography--body1">
            {name}
        </div>
    </div>

    <div class="property-value">
        <div class="mdc-typography--body1 read-only calculated multiline">
            <div class="container">
                <svg class="pattern" style="--angleDeg: {_angleDeg}deg" width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="256" height="256" transform="translate(128 128)" fill="white"/>

                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M264.999 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M258.999 0V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M252.999 0V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M325 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M216.999 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M169 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M139 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M109 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M276.999 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M337 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M228.999 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M181 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M151 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M121 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M288.999 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M349 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M240.999 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M246.999 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M192.999 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M300.999 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M361 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M391 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M163 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M133 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M103 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M270.999 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M331 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M223 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M175 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M145 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M115 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M282.999 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M343 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M234.999 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M187 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M157 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M127 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M294.999 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M355 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M385 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M198.999 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M307 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M367 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M397 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M204.999 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M313 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M373 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M403 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M211 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M319 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M379 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M409 -0.000244141V512" stroke="#101314" stroke-opacity="0.24" stroke-width="2"/>

                    <path d="M0 304L512 304" stroke="white" stroke-width="4"/>
                    <path d="M0 208L512 208" stroke="white" stroke-width="4"/>
                    <path d="M0 160L512 160" stroke="white" stroke-width="4"/>
                    <path d="M0 112L512 112" stroke="white" stroke-width="4"/>
                    <path d="M0 64L512 64" stroke="white" stroke-width="4"/>
                    <path d="M0 16L512 16" stroke="white" stroke-width="4"/>
                    <path d="M0 352L512 352" stroke="white" stroke-width="4"/>
                    <path d="M0 400L512 400" stroke="white" stroke-width="4"/>
                    <path d="M0 448L512 448" stroke="white" stroke-width="4"/>
                    <path d="M0 496L512 496" stroke="white" stroke-width="4"/>
                    <path d="M0 256L512 256" stroke="white" stroke-width="28"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M255.999 -0.000244141V512" stroke="white" stroke-width="22"/>
                    <path d="M0 262L512 262" stroke="#0A76F5" stroke-width="2"/>
                    <rect x="160" y="248" width="24" height="8" fill="#101314" fill-opacity="0.69"/>
                    <rect x="288" y="248" width="24" height="8" fill="#101314" fill-opacity="0.69"/>
                    <path class="shift" style="--shiftDeg: {_shiftDeg}deg" d="M255.999 -0.000244141L255.999 512" stroke="#101314" stroke-opacity="0.69" stroke-width="2"/>
                </svg>
            </div>
        </div>

    </div>
</div>

<style lang="scss">
    .container{
        height: 98px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .pattern {
        scale: 2.25;
        transform: rotate(var(--angleDeg));
        transform-origin: 50% 50%;
    }
    .shift {
        transform: rotate(var(--shiftDeg));
        transform-origin: 50% 50%;
    }
</style>