import {
    BasicAnalyticalRepresentation,
    Bim,
    ExtrudedPolygonGeometry,
    IdBimScene,
    RegularHeightmapGeometry,
    TerrainHeightMapRepresentation,
} from "bim-ts";
import { buildConcaveHull, Vector2 } from "math-ts";

export function createTerrainOuterContour(bim: Bim, terrainId: IdBimScene) {
    const terrain = bim.instances.peekById(terrainId);
    if (!terrain || terrain.type_identifier !== "terrain-heightmap") {
        return null;
    }
    if (!(terrain.representation instanceof TerrainHeightMapRepresentation)) {
        return null;
    }
    const points: [number, number][] = [];
    const reusedVector2 = new Vector2();
    for (const [tileId, tile] of terrain.representation.tiles) {
        const tileRepr = bim.allBimGeometries.peekById(tile.initialGeo);
        if (!(tileRepr instanceof RegularHeightmapGeometry)) {
            continue;
        }

        for (let iy = 0; iy < tileRepr.ySegmentsCount; iy++) {
            for (let ix = 0; ix < tileRepr.xSegmentsCount; ix++) {
                const elevation = tileRepr.readElevationAtInds(ix, iy);
                if (elevation === undefined || isNaN(elevation)) {
                    continue;
                }
                const yCoord =
                    iy * tileRepr.segmentSizeInMeters +
                    terrain.representation.tileSize * tileId.y;
                const xCoord =
                    ix * tileRepr.segmentSizeInMeters +
                    terrain.representation.tileSize * tileId.x;
                const pos = reusedVector2.set(xCoord, yCoord);
                pos.applyMatrix4(terrain.worldMatrix);
                points.push([pos.x, pos.y]);
            }
        }
    }
    
    const convexHull = buildConcaveHull(points);
    const terrainContour = convexHull.map(p => new Vector2(p[0], p[1]));
    // debugBoundary(bim, terrainContour, "terrain-outer-polygon " + terrainId);

    return terrainContour;
}

export function debugBoundary(bim: Bim, contour: Vector2[], name: string) {
    const newGeoId = bim.extrudedPolygonGeometries.reserveNewId();
    const geom: Partial<ExtrudedPolygonGeometry> =
        ExtrudedPolygonGeometry.newWithAutoIds(contour, undefined, 0, 2);
    bim.extrudedPolygonGeometries.allocate([[newGeoId, geom]]);
    bim.instances.allocate([
        [
            bim.instances.reserveNewId(),
            {
                name: name,
                type_identifier: "boundary",
                representationAnalytical: new BasicAnalyticalRepresentation(
                    newGeoId
                ),
            },
        ],
    ]);
}
