import '../shaders/shaders_chunks';

import { FetchUtils, LegacyLogger } from 'engine-utils-ts';
import { Vector3, Vector4 } from 'math-ts';

import type {
	Texture, Uniform} from '../3rdParty/three';
import {
	Color, DataTexture, EquirectangularReflectionMapping,
	FileLoader, LessEqualDepth, Math as _Math, RGBEEncoding, ShaderMaterial, UniformsUtils,
} from '../3rdParty/three';
import type { RendererExt } from '../composer/RendererExt';
import { ESO_SceneImageShader } from '../esos/ESO_SceneImage_Shader';
import { PMREMGenerator } from '../loaders/PMREMGenerator';
import { RGBELoader } from '../loaders/RGBELoader';
import { EngineMaterialId } from '../pools/EngineMaterialId';
import { BasicAnalyticalShader } from '../shaders/BasicAnalyticalShader';
import { DepthShader } from '../shaders/DepthShader';
import { EdgeShader } from '../shaders/EdgeShader';
import { GhostShader } from '../shaders/GhostShader';
import { HighlightShader } from '../shaders/HighlightShader';
import type { ShaderBase } from '../shaders/ShaderBase';
import { ShaderFlags } from '../shaders/ShaderFlags';
import { SpatialShader } from '../shaders/SpatialShader';
import { SpriteShader } from '../shaders/SpriteShader';
import { StandardShader } from '../shaders/StandardShader';
import { StdSimpleShader } from '../shaders/StdSimpleShader';
import { TerrainShader } from '../terrain/TerrainShader';
import type { DictionaryNum } from '../utils/Utils';
import Utils from '../utils/Utils';
import {
	extractAdditinalColors, extractBritish381ByCode, extractBritish4800ByCode,
	extractBritishColorByName, extractHexColor, extractRALClassicByCode,
	extractRALClassicByName, extractRGBColor, validatedColor,
} from './ColorsExtractors';
import type {
	EngineStdMaterials, IdEngineMaterial, StdMaterialTemplate} from './EngineStdMaterials';
import {
	TransparencyThreshold,
} from './EngineStdMaterials';
import type { GlobalUniforms, ShaderUniform } from './GlobalUniforms';
import { frosted, glossy, matte } from './Keywords';
import { KrMaterial } from './KrMaterial';
import {
	getMatTemplateFromString, similarityToKeyword,
} from './MaterialsTemplates';
import type { TexturesLoader } from './TexturesLoader';
import { TerrainegularShader } from '../terrain/TerrainRegularShader';
import { TerrainRegularBasicTransparent } from '../terrain/TerrainRegularBasicTransparentShader';
import { TextShader } from '../three-mesh-ui/components/core/TextShaders';
import { TrackersPilesBillboardShader } from '../shaders/TrackersPilesBillboardShader';
import type { WebGLRenderTarget } from 'src/3rdParty/three';

export function getSimpleColorIdFromStdId(id: number) {
	throw new Error('simple colors not impl');
	// LegacyLogger.assert(id >= 0, 'std id should be >= 0');
	// return id + SimpleMaterialsIdsStart;
}


export const PolylinesMaterialsStart = '{{polyline}}'

export const DiffuseDefaultPixel = new Uint8ClampedArray([240, 240, 240, 255]);
export const NormalDefaultPixel = new Uint8ClampedArray([127, 127, 255, 0]);
export const DiffuseTexturePostfix = '_d.png';
export const NormalTexturePostfix = '_n.png';

export class MaterialsFactory {

	dataUrl: string;
	readonly depthPacking: number;

	readonly globalUniforms: GlobalUniforms;
	readonly gammaFactor: number;

	readonly textures: TexturesLoader;
	readonly renderer: RendererExt;
	
	stdMaterialsSharedUniforms: DictionaryNum<StdUniforms>;
	createdMaterialsById: Map<number, KrMaterial[]> = new Map();

	sharedEdgeMaterials: DictionaryNum<KrMaterial> = {};

	polygonOffsetEnabled: boolean;

	engineStdMaterials: EngineStdMaterials;
	stdPhysicalParams: Map<number, MaterialPhysicalParams> = new Map();

	envMapTexture!: DataTexture;
	envMapRT!: WebGLRenderTarget;
	envMapGenerator!: PMREMGenerator;

	private glContextLostCallback: () => void;
	private glContextRestoredCallback: () => void;
	
	constructor(
		engineStdMaterials: EngineStdMaterials,
		globalUniforms: GlobalUniforms,
		dataUrl: string,
		textures: TexturesLoader,
		renderer: RendererExt,
		depthPacking: number,
	) {
		this.engineStdMaterials = engineStdMaterials;

		this.stdMaterialsSharedUniforms = {};

		this.textures = textures;
		this.dataUrl = dataUrl;
		this.depthPacking = depthPacking;
		this.gammaFactor = renderer.gammaFactor;

		this.globalUniforms = globalUniforms;

		this.polygonOffsetEnabled = false;

		this.renderer = renderer;
		this.glContextLostCallback = () => this.onGlContextLost();
		this.glContextRestoredCallback = () => this.onGlContextRestored();
		this.renderer.addGlContextEventListeners(this.glContextLostCallback, this.glContextRestoredCallback);
	}

	loadSkybox(renderer: RendererExt) {
		new FileLoader()
			.setPath( FetchUtils.combineURLs(this.dataUrl, 'skybox/'))
			.setResponseType( 'arraybuffer' )
			.load('round_platform_1k.hdr', file => {
			const rgbeParser = new RGBELoader();
			const textureDescr = rgbeParser.parse(file as ArrayBuffer);
			if (!textureDescr || !textureDescr.data) {
				LegacyLogger.error('could not parse hdr', textureDescr);
				return;
			}
			this.envMapTexture = new DataTexture(
				textureDescr.data,
				textureDescr.width,
				textureDescr.height,
				textureDescr.format,
				textureDescr.type,
				EquirectangularReflectionMapping,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				RGBEEncoding
			);
			// dt.generateMipmaps = true;
			this.envMapTexture.needsUpdate = true;
			this.envMapGenerator = new PMREMGenerator(renderer);
			this.envMapRT = this.envMapGenerator.fromEquirectangular(this.envMapTexture);
			// generator.dispose();
			this.setEnvMap(this.envMapRT.texture);
		});
	}

	onGlContextLost() {
		this.envMapRT.dispose();
	}

	onGlContextRestored() {
		this.envMapRT = this.envMapGenerator.fromEquirectangular(this.envMapTexture);
		this.setEnvMap(this.envMapRT.texture);
	}

	setEnvMap(map: Texture) {
		this.globalUniforms.envMap.value.dispose();
		this.globalUniforms.envMap.value = map;
		for (const [id, mats] of this.createdMaterialsById) {
			if (id >= 0) {
				for (const m of mats) {
					m.needsUpdate = true;
					if (m.envMap) {
						m.envMap = map;
						m.needsUpdate = true;
					}
				}
			}
		}
	}

	getAllById(id: number) {
		const result: KrMaterial[] = [];
		if (this.createdMaterialsById.has(id)) {
			Utils.extendArray(result, this.createdMaterialsById.get(id)!);
		}
		return result;
	}

	static extractColorFromString(str: string): Color | null {
		let col: Color | null = null;
		for (const extractor of [
			extractBritish381ByCode,
			extractBritish4800ByCode,
			extractRALClassicByCode,
			extractBritishColorByName,
			extractRALClassicByName,
			extractAdditinalColors,
			extractHexColor,
			extractRGBColor,
		]) {
			col = validatedColor(extractor(str));
			if (col) {
				break;
			}
		}
		return col;
	}

	static cleanUpMaterialName(str: string) {
		if (str.indexOf(' ') === -1) { // split camel case
			//todo: add other languages to this
			str = str.replace(/([A-Z])(?=[a-z])/g, '_$1').replace(/([a-z])(?=[A-Z])/g, '$1_');
		}
		str = str.toLowerCase();
		str = str.replace(/ё/g, 'е');
		str = str.replace(/[\-\=\_\\\/\"\'\[\]\:\(\)\:\.\,]/g, ' ');
		str = str.replace(/\s\s+/g, ' ');
		return str.trim();
	}

	_createPhysicalParamsFromTemplate(matParams: StdMaterialTemplate): MaterialPhysicalParams {
		const materialName = MaterialsFactory.cleanUpMaterialName(matParams.name);
		let paramColor: Color | null = null;
		if (matParams.color) {
			paramColor = new Color(-1, -1, -1);
			paramColor = validatedColor(paramColor.set(matParams.color));
			if (paramColor === null && typeof matParams.color == 'string') {
				paramColor = MaterialsFactory.extractColorFromString(matParams.color);
			}
			if (paramColor === null) {
				LegacyLogger.warn(`could not parse ${ materialName } color - ${ matParams.color}`);
			}
		}
		// paramColor = new Color(0xFF0000);

		// extract mat type keywords
		const templ = getMatTemplateFromString(materialName);
		let color = paramColor || MaterialsFactory.extractColorFromString(materialName) || (!templ.texName && templ.color) || null;
		let metalness = matParams.metalness !== undefined ? matParams.metalness : templ.metalness;
		let roughness = matParams.roughness !== undefined ? matParams.roughness : templ.roughness;

		let opacity;
		if (matParams.transparency != undefined) {
			opacity = 1 - _Math.clamp(matParams.transparency, 0, 1);
		} else {
			opacity = templ.isTransparent() ? 0.25 : 1.0;
		}
		{
			const tokens = materialName.split(' ');

			for (const kw of [matte, frosted]) {
				for (const t of tokens) {
					const s = similarityToKeyword(t, kw);
					if (s >= 0.9) {
						if (matParams.roughness === undefined) {
							roughness = _Math.lerp(roughness, 1.0, 0.5);
						}
						if (matParams.transparency === undefined) {
							opacity = _Math.lerp(opacity, 1.0, 0.3);
						}
						break;
					}
				}
			}

			if (matParams.roughness === undefined) {
				for (const t of tokens) {
					const s = similarityToKeyword(t, glossy);
					if (s >= 0.9) {
						roughness = _Math.lerp(roughness, 0.0, 0.5);
						break;
					}
				}
			}
		}

		let textPath = '';
		let tiling = 0;
		const isTransparent = opacity < (1 - TransparencyThreshold);
		if (isTransparent) {
			opacity = opacity;
		} else {
			textPath = templ.texName;
			tiling = templ.tiling;

			if (textPath) {
				//start loading textures right away
				this.textures.get(textPath + DiffuseTexturePostfix, DiffuseDefaultPixel);
				this.textures.get(textPath + NormalTexturePostfix, NormalDefaultPixel);
			}
		}

		return new MaterialPhysicalParams(materialName, textPath, roughness, metalness, tiling, opacity, color, templ.color);
	}

	_addFeatures(mat: KrMaterial) {
		const defines = mat.defines;
		const uniforms = mat.uniforms;
		const shaderFlags = mat.shaderFlags;
		for (let i = 0; i < 15; ++i) {
			const flag = 1 << i;
			if (shaderFlags & flag) {
				const flagString = ShaderFlags[flag];
				if (flagString) {
					defines[flagString] = true;
				}
			}
		}
		if (shaderFlags & ShaderFlags.COLOR_CLIPPING) {
			uniforms.colorClippingPlane = { value: new Vector4(0, 0, 0, 0) }
			uniforms.colorBelow = { value: new Vector4(0, 0, 0, 0) }
			uniforms.colorAbove = { value: new Vector4(0, 0, 0, 0) }
		}
		if (shaderFlags & (ShaderFlags.BOX_CLIPPING | ShaderFlags.BOX_CLIP_INSIDE)) {
			uniforms.clipBoxMin = this.globalUniforms.clipboxMin;
			uniforms.clipBoxMax = this.globalUniforms.clipboxMax;
		}
		if (shaderFlags & ShaderFlags.PLANE_CLIPPING) {
			uniforms.clippingPlane = { value: new Vector4(0, 0, 0, 0) }
		}
		if (mat.transparent) {
			mat.defines['TRANSPARENT'] = true;
		} else {
			if (shaderFlags & (ShaderFlags.BOX_CLIP_INSIDE | ShaderFlags.BOX_CLIPPING | ShaderFlags.PLANE_CLIPPING)) {
				mat.defines['GRAY_BACKFACE'] = true;
			}
		}

		mat.defines['CUSTOM_STD_ATTRIBUTES'] = true;
		mat.defines['CUSTOM_STD_UNIFORMS'] = true;
		mat.defines['NEEDS_WORLD_SPACE'] = true;

		if (shaderFlags & (ShaderFlags.COLOR_CLIPPING | ShaderFlags.PLANE_CLIPPING
			| ShaderFlags.BOX_CLIP_INSIDE | ShaderFlags.BOX_CLIPPING)) {
			mat.defines['NEEDS_WORLD_SPACE'] = true;
		}

		if (mat.uniforms['resolution']) {
			mat.uniforms.resolution = this.globalUniforms.resolution;
		}
		
		
	}

	_getStdPhysicalParams(id: IdEngineMaterial): MaterialPhysicalParams | null {
		if (!this.stdPhysicalParams.has(id)) {
			let mt = this.engineStdMaterials.peekById(id);
			if (!mt) {
				if (id == EngineMaterialId.Default as unknown as IdEngineMaterial) {
					mt = { name: 'default', transparency: 0, metalness: 0, roughness: 1, color: '#BBBBBB' };
				} else {
					return null;
				}
			}
			this.stdPhysicalParams.set(id, this._createPhysicalParamsFromTemplate(mt))
		}
		return this.stdPhysicalParams.get(id)!;
	}

	createMaterial(id: number, shaderFlags: ShaderFlags): KrMaterial | undefined {
		let mat: KrMaterial | undefined;
		if (id < EngineMaterialId.SpecialMaterialsStart) { // std material
			mat = this._createStandardMaterialFromIndex(id as IdEngineMaterial, shaderFlags);
			if (!mat) {
				return undefined;
			}
			mat.edgeMaterial = this._getEdgeMaterial(mat, 0);
		} else if (id === EngineMaterialId.Ghost) {
			mat = this._createGhostMaterial(shaderFlags);
			mat.edgeMaterial = this._getEdgeMaterial(mat, id);
			mat.forceEdgesDrawing = true;

		} else if (id === EngineMaterialId.Highlight) {
			mat = this.createHighlightMaterial(shaderFlags);
			mat.edgeMaterial = this._getEdgeMaterial(mat, id);

		} else if (id == EngineMaterialId.SimpleOpaque || id == EngineMaterialId.SimpleTransparent) { 
			mat = this._createSimpleMaterialFromIndex(id, shaderFlags);
			mat.edgeMaterial = this._getEdgeMaterial(mat, 0);

		} else if (id == EngineMaterialId.Depth) {
			mat = this._createShadowMaterial(shaderFlags);
		} else if (id == EngineMaterialId.SceneImage) {
			mat = this._createSceneImageMaterial(shaderFlags);
		} else if (id == EngineMaterialId.Terrain) {
			mat = this._createTerrainMaterial(shaderFlags);
		} else if (id == EngineMaterialId.TerrainRegular) {
			mat = this._createRegularTerrainMaterial(shaderFlags);
		} else if (id == EngineMaterialId.TerrainRegularBasicTransparent) {
			mat = this._createTerrainRegularBasicTransparentMaterial(shaderFlags);
		} else if (id == EngineMaterialId.Spatial) {
			mat = this._createBoundaryMaterial(shaderFlags);
		} else if (id == EngineMaterialId.BasicAnalytical) {
			mat  = this._createBasicAnalyticalMaterial(shaderFlags);
		} else if (id == EngineMaterialId.BasicAnalyticalTransparent) {
			mat  = this._createBasicAnalyticalMaterialTransparent(shaderFlags);
		} else if (id == EngineMaterialId.Sprite) {
			mat  = this._createSpriteMaterial();
		} else if (id == EngineMaterialId.Text) {
			mat  = this._createTextMaterial(shaderFlags);
		} else if (id == EngineMaterialId.TrackersPilesBillboard) {
			mat = this._createTrackersPilesBillboardMaterial(shaderFlags);
		} else {
			console.error('unkown material id ' + id + '--' + shaderFlags);
			return undefined;
		}
		if (mat.edgeMaterial) {
			mat.addEventListener('dispose', (t) => {
				t.target.edgeMaterial.dispose();
			});			
		}
		Object.freeze(mat.uniforms);

		let matsArr = this.createdMaterialsById.get(id);
		if (matsArr === undefined) {
			matsArr = [];
			this.createdMaterialsById.set(id, matsArr);
		}
		matsArr.push(mat);

		return mat;
	}

	_createGhostMaterial(shaderFlags: ShaderFlags) {
		const mat = new KrMaterial(GhostShader, EngineMaterialId.Ghost, shaderFlags);
		mat.transparent = true;
		mat.depthTest = true;
		mat.depthWrite = false;
		mat.name = 'ghostMaterial';
		mat.uniforms.sideColor = this.globalUniforms.ghostSideColor;
		mat.uniforms.frontColor = this.globalUniforms.ghostFrontColor;
		// mat.blending = CustomBlending;
		// mat.blendEquation = AddEquation;
		// mat.blendEquation = SubtractEquation;
		// mat.blendSrc = 205;
		// mat.blendDst = 205;

		shaderFlags &= (ShaderFlags.BOX_CLIP_INSIDE | ShaderFlags.BOX_CLIPPING | ShaderFlags.PLANE_CLIPPING);
		this._addFeatures(mat);

		return mat;
	}

	_getEdgeMaterial(mat: KrMaterial, matTypeId: number): KrMaterial {
		const flagsThatAffectEdges =
			ShaderFlags.None | ShaderFlags.PLANE_CLIPPING
			| ShaderFlags.BOX_CLIPPING | ShaderFlags.BOX_CLIP_INSIDE;
		
		const createEdgeMaterial = (sourceMat: KrMaterial, shaderFlags: ShaderFlags, matTypeId:number) => {
			LegacyLogger.assert((shaderFlags & flagsThatAffectEdges) === shaderFlags, 'edgeMaterial can not use all the shader flags, and should not be created with them to not duplicate materials needlessly');
			const mat = new KrMaterial(EdgeShader, EngineMaterialId.Edge, shaderFlags);
			this._addFeatures(mat);
			mat.name = 'edgeMaterial';
			if (shaderFlags & ShaderFlags.PLANE_CLIPPING) {
				mat.uniforms.clippingPlane = sourceMat.uniforms.clippingPlane;
			}
			if (matTypeId === EngineMaterialId.Highlight) { 
				mat.depthTest = false;
			} else if (matTypeId === EngineMaterialId.Ghost) {
				mat.depthTest = true;
			} else {
				mat.depthTest = true;
			}
			mat.transparent = true;
			mat.depthWrite = false;
			// mat.blending = MultiplyBlending;
			return mat
		}

		const shaderFlags = mat.shaderFlags & flagsThatAffectEdges;
		const key = 'edge_' + matTypeId + '_' + shaderFlags;
		if (!this.sharedEdgeMaterials[key]) {
			this.sharedEdgeMaterials[key] = createEdgeMaterial(mat, shaderFlags, matTypeId);
		}
		return this.sharedEdgeMaterials[key];
	}

	createMaterialFrom(shader: ShaderBase, id:number, shaderFlags: ShaderFlags): KrMaterial {
		const mat = new KrMaterial(shader, id, shaderFlags);
		mat.name = shader.name;
		mat.defines.DEPTH_PACKING = this.depthPacking;
		this._addFeatures(mat);
		return mat;
	}

	createHighlightMaterial(shaderFlags: ShaderFlags): KrMaterial {
		const createHighlightMaterial = (shaderFlags: ShaderFlags) => {
			const mat = new KrMaterial(HighlightShader, EngineMaterialId.Highlight, shaderFlags);

			mat.depthTest = false;
			mat.depthFunc = LessEqualDepth;
			mat.transparent = true;
			mat.depthWrite = false;

			this._addFeatures(mat);
			return mat
		}

		const flagsThatAffectHighlight =
			ShaderFlags.None | ShaderFlags.PLANE_CLIPPING
			| ShaderFlags.BOX_CLIPPING | ShaderFlags.BOX_CLIP_INSIDE
			| ShaderFlags.UV_FROM_LOCAL_POSITION | ShaderFlags.UV_TRANSFORM
			| ShaderFlags.IS_LINE_GEO | ShaderFlags.IS_SPRITE_GEO | ShaderFlags.VISIBILITY_FROM_TEXTURE
			| ShaderFlags.IS_TRACKER_PILE_BILLBOARD_GEO;
	
		const key = 'highlight_' + shaderFlags;
		if (!this.sharedEdgeMaterials[key]) {
			this.sharedEdgeMaterials[key] = createHighlightMaterial(shaderFlags & flagsThatAffectHighlight);
		}
		return this.sharedEdgeMaterials[key];
	}
	
	_createShadowMaterial(shaderFlags: ShaderFlags) {
		const mat = new KrMaterial(DepthShader, EngineMaterialId.Depth, shaderFlags);

		// mat.forceEdgesDrawing = true;
		// const stdColor = (matParams.color || matParams.defaultColor).clone();
		// stdColor.copyGammaToLinear(stdColor, this.gammaFactor);

		// mat.defines['NEEDS_VIEW_SPACE'] = true;
		this._addFeatures(mat);
		mat.colorWrite = false;
		
		return mat;
	}

	_createSceneImageMaterial(shaderFlags: ShaderFlags) {
		const mat = new KrMaterial(ESO_SceneImageShader, EngineMaterialId.SceneImage, shaderFlags);
		mat.depthWrite = true;
		mat.depthFunc = LessEqualDepth;
		mat.defines['MRT_NORMALS'] = true;
		mat.extensions.drawBuffers = true;

		// mat.forceEdgesDrawing = true;
		// const stdColor = (matParams.color || matParams.defaultColor).clone();
		// stdColor.copyGammaToLinear(stdColor, this.gammaFactor);

		// mat.defines['NEEDS_VIEW_SPACE'] = true;
		this._addFeatures(mat);
		
		return mat;
	}

	_createTerrainMaterial(shaderFlags: ShaderFlags) {
		const mat = new KrMaterial(TerrainShader, EngineMaterialId.Terrain, shaderFlags);
		// mat.depthWrite = true;
		mat.depthFunc = LessEqualDepth;


		mat.uniforms.paletteColors = this.globalUniforms.terrain.paletteColors;
		mat.uniforms.paletteRanges = this.globalUniforms.terrain.paletteRanges;
		mat.uniforms.angleToVectorAndSource = this.globalUniforms.terrain.angleToVectorAndSource;

		// mat.forceEdgesDrawing = true;
		// const stdColor = (matParams.color || matParams.defaultColor).clone();
		// stdColor.copyGammaToLinear(stdColor, this.gammaFactor);

		// mat.defines['NEEDS_VIEW_SPACE'] = true;
		this._addFeatures(mat);
		
		return mat;
	}

	
	_createRegularTerrainMaterial(shaderFlags: ShaderFlags) {
		const mat = new KrMaterial(TerrainegularShader, EngineMaterialId.TerrainRegular, shaderFlags);
		mat.depthFunc = LessEqualDepth;

		mat.uniforms.paletteColors = this.globalUniforms.terrain.paletteColors;
		mat.uniforms.paletteRanges = this.globalUniforms.terrain.paletteRanges;
		mat.uniforms.angleToVectorAndSource = this.globalUniforms.terrain.angleToVectorAndSource;
		mat.uniforms.gridStep = this.globalUniforms.terrain.gridStep;
		this._addFeatures(mat);
		
		return mat;
	}

	_createTerrainRegularBasicTransparentMaterial(shaderFlags: ShaderFlags) {
		const mat = new KrMaterial(TerrainRegularBasicTransparent, EngineMaterialId.TerrainRegularBasicTransparent, shaderFlags);

		mat.uniforms.paletteColors = this.globalUniforms.terrain.paletteColors;
		mat.uniforms.paletteRanges = this.globalUniforms.terrain.paletteRanges;
		mat.uniforms.angleToVectorAndSource = this.globalUniforms.terrain.angleToVectorAndSource;

		// mat.uniforms.gridLevelOpacityInterval = this.globalUniforms.gridLevelOpacityIntervalMinor;
		// mat.uniforms.gridLevelOpacityIntervalMinor = this.globalUniforms.gridLevelOpacityIntervalMinor;
		// mat.uniforms.gridWidth = this.globalUniforms.gridWidth;
		mat.uniforms.gridStep = this.globalUniforms.terrain.gridStep;

		// mat.forceEdgesDrawing = true;
		// const stdColor = (matParams.color || matParams.defaultColor).clone();
		// stdColor.copyGammaToLinear(stdColor, this.gammaFactor);

		// mat.defines['NEEDS_VIEW_SPACE'] = true;
		this._addFeatures(mat);
		
		return mat;
	}

	_createBoundaryMaterial(shaderFlags: ShaderFlags) {
		const mat = new KrMaterial(SpatialShader, EngineMaterialId.Spatial, shaderFlags);
		this._addFeatures(mat);
		return mat;
	}

	_createBasicAnalyticalMaterial(shaderFlags: ShaderFlags) {
		const mat = new KrMaterial(BasicAnalyticalShader, EngineMaterialId.BasicAnalytical, shaderFlags);
		mat.depthWrite = true;
		mat.depthFunc = LessEqualDepth;
		mat.defines['MRT_NORMALS'] = true;
		mat.extensions.drawBuffers = true;
		mat.transparent = false;
		this._addFeatures(mat);
		return mat;
	}
	_createBasicAnalyticalMaterialTransparent(shaderFlags: ShaderFlags) {
		const mat = new KrMaterial(BasicAnalyticalShader, EngineMaterialId.BasicAnalyticalTransparent, shaderFlags);
		mat.depthWrite = false;
		mat.depthTest = true;
		mat.transparent = true;
		this._addFeatures(mat);
		return mat;
	}

	_createSpriteMaterial() {
		const mat = new KrMaterial(SpriteShader, EngineMaterialId.Sprite, 0);
		this._addFeatures(mat);
		return mat;
	}

	_createTextMaterial(shaderFlags: ShaderFlags) {
		const mat = new KrMaterial(TextShader, EngineMaterialId.Text, shaderFlags);
		this._addFeatures(mat);
		mat.depthWrite = false;
		mat.polygonOffset = true;
		mat.polygonOffsetUnits = -3.0;
		mat.polygonOffsetFactor = -3.0;
		return mat;
	}

	_createSimpleMaterialFromIndex(index: number, shaderFlags: ShaderFlags) {
		const mat = new KrMaterial(StdSimpleShader, index, shaderFlags);
		if (index === EngineMaterialId.SimpleTransparent) {
			mat.transparent = true;
			mat.opacity = 0.15;
			mat.uniforms.opacity.value = 0.15;
		} else {
			mat.depthWrite = true;
			mat.depthFunc = LessEqualDepth;
			mat.defines['MRT_NORMALS'] = true;
			mat.extensions.drawBuffers = true;
		}
		mat.map = new DataTexture(new Uint8Array([0, 0, 0, 0]), 2, 2);
		// mat.forceEdgesDrawing = true;
		// const stdColor = (matParams.color || matParams.defaultColor).clone();
		// stdColor.copyGammaToLinear(stdColor, this.gammaFactor);
		mat.uniforms.baseColor.value.set(0.5, 0.5, 0.5);

		mat.defines['NEEDS_VIEW_SPACE'] = true;
		this._addFeatures(mat);
		
		return mat;
	}

	_createStandardMaterialFromIndex(id: IdEngineMaterial, shaderFlags: ShaderFlags): KrMaterial | undefined {
		const matParams = this._getStdPhysicalParams(id);
		if (matParams == undefined) {
			LegacyLogger.deferredError('matInfo for index is undefined', id);
			return undefined;
		}
		const isLightMat = false;// && matParams.name.includes('light');
		const mat = new KrMaterial(StandardShader, id, shaderFlags);

		mat.name = matParams.name;

		if (this.stdMaterialsSharedUniforms[id] === undefined) {
			const uniforms: StdUniforms = {
				roughMetalTiling: { value: Vector3.allocate(matParams.roughness, matParams.metalness, matParams.tiling) },
				map: { value: null },
				normalMap: { value: null },
				envMap: isLightMat ? { value: null }: this.globalUniforms.envMap,
				baseColor: { value: Vector4.allocate(1, 1, 1, 0) },
				opacity: { value: matParams.opacity },
				// sunDirection: this.globalUniforms.sunDirection
			}
			if (matParams.color) {
				const color = matParams.color.clone();
				color.copyGammaToLinear(color, this.gammaFactor);

				Utils.colorToXYZ(color, uniforms.baseColor.value);

				let baseColorPower = 1.0;
				{
					const colorizeVector_t = Utils.colorToXYZ(matParams.color, Vector3.zero());
					const baseVector_t = Utils.colorToXYZ(matParams.defaultColor, Vector3.zero());
					const componentsDiff_t = Vector3.subVectors(colorizeVector_t, baseVector_t);
					let distance = componentsDiff_t.length() / Math.max(colorizeVector_t.length(), baseVector_t.length());
					let colorSimiliary = colorizeVector_t.normalize().distanceTo(baseVector_t.normalize());
					baseColorPower = _Math.clamp(Math.sqrt(Math.sqrt(distance + colorSimiliary)), 0.3, 1.0);
				}

				uniforms.baseColor.value.w = baseColorPower;
			}

			this.stdMaterialsSharedUniforms[id] = uniforms;

			if (matParams.texName) {
				uniforms.map.value = this.textures.get(matParams.texName + DiffuseTexturePostfix, DiffuseDefaultPixel);
				uniforms.normalMap.value = this.textures.get(matParams.texName + NormalTexturePostfix, NormalDefaultPixel);
			}
		}
		const sharedUniforms = this.stdMaterialsSharedUniforms[id];

		for (const key in sharedUniforms) {
			const sharedUnif = sharedUniforms[key];
			if (sharedUnif != undefined) {
				mat.uniforms[key] = sharedUnif;
			}
		}

		// if (!isLightMat) {
			mat.defines['NEEDS_VIEW_SPACE'] = true;
		// }

		{
			const uniforms = mat.uniforms;
			mat.map = uniforms.map.value;
			mat.envMap = uniforms.envMap.value;
			mat.normalMap = uniforms.normalMap.value;
		}

		
		if (matParams.isTransparent()) {
			mat.depthWrite = false;
			mat.transparent = true;
			mat.opacity = matParams.opacity;
			mat.uniforms.opacity.value = matParams.opacity;
			// mat.blendSrc = OneFactor;
			// mat.blendSrc = OneMinusSrcAlphaFactor;
			// mat.blending = CustomBlending;
			// const color = mat.uniforms.baseColor.value as Color;
			// mat.uniforms.baseColor.value.multiplyScalar(mat.uniforms.opacity.value);
		} else {
			mat.depthWrite = true;
			mat.depthFunc = LessEqualDepth;
			mat.defines['MRT_NORMALS'] = true;
			mat.extensions.drawBuffers = true;
		}

		this._addFeatures(mat);
		mat.extensions.derivatives = true;

		return mat;
	}

	_createTrackersPilesBillboardMaterial(shaderFlags: ShaderFlags)
	{
		const mat = new KrMaterial(TrackersPilesBillboardShader, EngineMaterialId.TrackersPilesBillboard, shaderFlags);
		mat.defines['MRT_NORMALS'] = true;
		mat.premultipliedAlpha = true; //enbale premultiplied alpha blending (in case of texture is a premultiplied alpha)
		this._addFeatures(mat);
		return mat;
	}

	static createThreeMaterial(shader: ShaderBase):ShaderMaterial {
		const mat = new ShaderMaterial(shader);
		mat.uniforms = UniformsUtils.clone(mat.uniforms);
		mat.transparent = true;
		mat.depthWrite = false;
		mat.depthTest = false;
		return mat;
	}


	clear() {
		this.stdMaterialsSharedUniforms = {};
		for (const mats of this.createdMaterialsById.values()) {
			for (const m of mats) {
				m.dispose();
			}
		}
		this.createdMaterialsById.clear();
		this.stdPhysicalParams.clear();
	}
	
	dispose() {
		this.clear();

		this.globalUniforms.envMap.value.dispose();

		for (const key in this.sharedEdgeMaterials) {
			this.sharedEdgeMaterials[key].dispose();
		}
		this.sharedEdgeMaterials = {};

		this.renderer.removeGLContextEventListeners(this.glContextLostCallback, this.glContextRestoredCallback);
		this.envMapRT.dispose();
		this.envMapTexture.dispose();
		this.envMapGenerator.dispose();
	}
}

interface StdUniforms {
	roughMetalTiling: ShaderUniform<Vector3>,
	map: ShaderUniform<Texture | null>,
	normalMap: ShaderUniform<Texture | null>,
	envMap: ShaderUniform<Texture | null>,
	baseColor: ShaderUniform<Vector4>,
	opacity: ShaderUniform<number>,
	// sunDirection: ShaderUniform<Vector3>

	[index:string] : Uniform | undefined
}

export class MaterialPhysicalParams {
	name: string;
	texName: string;
	roughness: number;
	metalness: number;
	tiling: number;
	opacity: number;
	color: Color | null;
	defaultColor: Color;

	constructor(name: string, texName: string, roughness: number, metalness: number, tiling: number, opacity: number, color: Color | null, defaultColor: Color) {
		this.name = name;
		this.texName = texName;
		this.roughness = roughness;
		this.metalness = metalness;
		this.tiling = tiling;
		this.opacity = opacity;
		this.color = color;
		this.defaultColor = defaultColor;
	}

	isTransparent() {
		return this.opacity < (1 - TransparencyThreshold);
	}
}
