import type { IdBimScene, SceneInstance } from "src";
import type { NumberProperty } from "../../../properties/PrimitiveProps";

export enum CostSourceType {
    BenchmarkPerQuantity = "Benchmark",
    BenchmarkLumpSum = "Lump sum",
    ModelBasedLumpSum = "Model based Lump sum",
    GroupSum = "Group sum",
}

export interface TableCell {
    flags?: TableCellFlags;
}

interface TableCellFlags {
    ignored?: boolean;
    overriden?: boolean;
    editable?: boolean;
}

export interface DescriptionCell extends TableCell {
    value: string;
    onClick?: () => void;
    indent?: number;
}

interface SelectorCell<T> extends TableCell {
    update?: (newIndex: number) => void;
    index?: number;
    options?: T[];
}

type CostSourceCell = SelectorCell<CostSourceType>;

export enum CostDataVariation {
    Default,
    Customized,
    Ignored,
}

interface CostDataCell extends TableCell {
    variation?: CostDataVariation;
}

type UnitCell = SelectorCell<string>;

export interface QuantityCell extends TableCell {
    value?: NumberProperty;
    integer?: boolean;
}

export interface ModifiableNumberCell extends TableCell {
    value?: number;
    update?: (newValue: number | undefined) => void;
}

export type ModifiableCostCell = ModifiableNumberCell;

export interface ReadonlyCostCell extends TableCell {
    value?: number;
}

export interface CostCategory {
    description: DescriptionCell;
    costSource?: CostSourceCell;
    costData?: CostDataCell;
    costUnit?: UnitCell;
    quantity?: QuantityCell;

    labor?: {
        laborPerUnit?: ModifiableCostCell;
        loadedWageRate?: ModifiableCostCell;
        laborCostPerUnit?: ModifiableCostCell;
        laborTotal?: ModifiableCostCell;
    };

    material?: {
        materialCostPerUnit?: ModifiableCostCell;
        materialTotal?: ModifiableCostCell;
    };

    subService?: {
        subServiceCostPerUnit?: ModifiableCostCell;
        subServiceTotal?: ModifiableCostCell;
    };

    equipment?: {
        equipmentCostPerUnit?: ModifiableCostCell;
        equipmentTotal?: ModifiableCostCell;
    };

    totalCost?: ReadonlyCostCell;
    costPerWatt?: ReadonlyCostCell;

    children?: IdCostCategory[];

    relatedSceneInstanceIds?: Set<IdBimScene>;

    matchesSceneInstance?: MatchesCostCategory;
}

export type MatchesCostCategoryParams = {
    si?: SceneInstance;
    type_identifier?: string;
};

export type MatchesCostCategory = (
    params: MatchesCostCategoryParams,
) => boolean;

export enum IdCostCategory {}

export type FlattenedCostCategoryParams = {
    categoryId: IdCostCategory;
    nestLevel: number;
    isBottom: boolean;
    path: string[];
};
