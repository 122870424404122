import type { ScopedLogger } from "engine-utils-ts";
import type { PropsGroupBase, PropValueTotalHash } from "../../properties/Props";
import { PropertyBase } from "../../properties/Props";



export class PropsReadsHistory {

    readonly readKeys: Readonly<string[]>;
    readonly readValues: Readonly<(PropsReadsHistory | PropValueTotalHash)[]>;

    constructor(
        readKeys: Readonly<string[]>,
        readValues: Readonly<(PropsReadsHistory | PropValueTotalHash)[]>,
    ) {
        console.assert(readKeys.length === readValues.length, 'reads and nestedReads must have the same length');
        this.readKeys = readKeys;
        this.readValues = readValues;
        Object.freeze(this.readKeys);
        Object.freeze(this.readValues);
        Object.freeze(this);
    }

    toString() {
        const res: string[] = [];
        for (let i = 0; i < this.readKeys.length; i++) {
            const key = this.readKeys[i] as string;
            const nested = this.readValues[i];
            if (nested) {
                res.push(`${key}:(${nested.toString()})`);
            } else {
                res.push(`.${key}`);
            }
        }
        return res.join(';');
    }

    arePropsValuesTheSame(props: PropsGroupBase | PropertyBase[] | PropsGroupBase[]): boolean {
        for (let i = 0; i < this.readKeys.length; ++i) {
            const key = this.readKeys[i];
            const expectedValue = this.readValues[i];
            const realValue = (props as any)[key] as PropsGroupBase | PropertyBase[] | PropsGroupBase[] | number | string | undefined | null;
            if (expectedValue instanceof PropsReadsHistory) {
                if (!(realValue instanceof Object)) {
                    return false;
                }
                if (realValue instanceof PropertyBase) {
                    return false;
                }
                if (!expectedValue.arePropsValuesTheSame(realValue)) {
                    return false;
                }
            } else {
                const compareTo = (realValue instanceof PropertyBase) ? realValue.uniqueValueHash() : realValue;
                if (!Object.is(expectedValue, compareTo)) {
                    return false;
                }
            }
        }
        return true;
    }
}

export class PropsReadsHistoryInterner {

    private readonly _keysArraysInterned = new Map<string, Readonly<string[]>>();

    constructor(
    ) {
    }

    private _getSharedKeysArray(keys: string[]): Readonly<string[]> {
        const joined = keys.join(';');
        let res = this._keysArraysInterned.get(joined);
        if (!res) {
            res = Object.freeze(keys);
            this._keysArraysInterned.set(joined, res);
        }
        return res;
    }

    newReadsHistory(
        readKeys: string[],
        readValues: (PropsReadsHistory | PropValueTotalHash)[],
    ): PropsReadsHistory {
        const keys = this._getSharedKeysArray(readKeys);
        return new PropsReadsHistory(keys, readValues);
    }
}


export class PropsSolverInvalidationBase<Id> {

    readonly logger: ScopedLogger;
    readonly perObject = new Map<Id, PropsReadsHistory | null>();

    constructor(
        logger: ScopedLogger
    ) {
        this.logger = logger;
    }



}
