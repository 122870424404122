<script lang="ts">
  import { onMount, createEventDispatcher, afterUpdate } from 'svelte'
  import { MDCSelect } from '@material/select'
    import { IconComponent, type IconName } from '../libui/icon';

  export let nonCompact: boolean = false
  export let labelText: string = ""
  export let labelId: string = Math.round(Math.random() * 10 ** 4) + ""
  export let selected: string = ""
  export let values: string[] = []
  export let radius: boolean = false

  /**
   * Property should be equal to the LOWERCASED name of the icon found here:
   * https://fonts.google.com/icons?selected=Material+Icons
   */
  export let leadingIcon: IconName | undefined = undefined;
  let dispatch = createEventDispatcher<{select: string}>()

  $: compact = !nonCompact

  let selectTarget: Element
  let select: MDCSelect

  onMount(() => {
    if (selectTarget) {
      select = new MDCSelect(selectTarget)
    }
  })
  
  afterUpdate(()=>{
    select?.layoutOptions();
    select?.setValue(selected)
  })

  $: if (select)
    select.setValue(selected)
</script>


<style>
    .root {
        flex: 1;
    }
</style>

<!-- svelte-ignore a11y-label-has-associated-control -->
<div
  bind:this={selectTarget}
  class="mdc-select mdc-select--outlined root"
  class:compact={compact}
  class:mdc-select--no-label={!!labelText}
  class:mdc-select--with-leading-icon={!!leadingIcon}
  class:radius={radius}
>
  <div class="mdc-select__anchor" aria-labelledby={labelId}>
    <span class="mdc-notched-outline">
      <span class="mdc-notched-outline__leading"></span>
      {#if !!labelText}
        <span class="mdc-notched-outline__notch">
          <span id={labelId} class="mdc-floating-label">{labelText}</span>
        </span>
      {/if}
      <span class="mdc-notched-outline__trailing"></span>
    </span>
    {#if !!leadingIcon}
      <i class="mdc-select__icon" tabindex="0" role="button">
        <IconComponent name={leadingIcon} />
      </i>
    {/if}
    <span class="mdc-select__selected-text-container">
      <span class="mdc-select__selected-text">{selected}</span>
    </span>
    <span class="mdc-select__dropdown-icon">
      <svg
        class="mdc-select__dropdown-icon-graphic"
        viewBox="7 10 10 5" focusable="false">
        <polygon
          class="mdc-select__dropdown-icon-inactive"
          stroke="none"
          fill-rule="evenodd"
          points="7 10 12 15 17 10">
        </polygon>
        <polygon
          class="mdc-select__dropdown-icon-active"
          stroke="none"
          fill-rule="evenodd"
          points="7 15 12 10 17 15">
        </polygon>
      </svg>
    </span>
  </div>

  <div class="mdc-select__menu mdc-menu mdc-menu-surface mdc-menu-surface--fullwidth">
    <ul class="mdc-list" role="listbox" aria-label="selector">
      {#each values as value}
        <li
          on:click={() => dispatch('select', value)}
          class="mdc-list-item"
          data-value="{value}"
          role="option"
          class:mdc-list-item--selected={value === selected}
        >
          <span class="mdc-list-item__ripple"></span>
          <span class="mdc-list-item__text">
            {value}
          </span>
        </li>
      {/each}
    </ul>
  </div>
</div>
