import type { Matrix4} from 'math-ts';
import { Vector2, Vector3, Vector4 } from 'math-ts';

import type {
    BufferGeometry, Camera, Geometry, Group, Material, Scene, ShaderMaterial,
    WebGLRenderer
} from './3rdParty/three';
import { GreaterDepth, LessDepth, Mesh
} from './3rdParty/three';
import { BrighGizmoColor } from './Constants';
import { GizmoBrightness } from './SizeLabelMesh';
import type { TextGeometry } from './text/TextGeometry';

const blackTextColor = Vector4.allocate(0, 0, 0, 0.55);
const whiteTextColor = Vector4.allocate(1, 1, 1, 0.85);

export class TextMesh extends Mesh {

	textScale: number = 1;

	readonly upside: Vector2 = new Vector2(1, 1);
	readonly glyphColor: Vector4 = Vector4.allocate(0, 0, 0, 0.5);

	readonly dynamicUniforms: Map<string, Vector2 | Vector4> = new Map();

	defaultBlack: Vector4 | null = null;
	defaultWhite: Vector4 | null = null;
	defaultBright: Vector4 | null = null;

	_whiteness: number = 0.5;
	_brightness: GizmoBrightness = GizmoBrightness.Default;

	constructor(geometry: TextGeometry, material: ShaderMaterial) {
		super(geometry, material);
		this.matrixAutoUpdate = false;
		this.matrixWorldNeedsUpdate = false;
		this.material = material;
		this.dynamicUniforms.set('upside', this.upside);
		this.dynamicUniforms.set('glyphColor', this.glyphColor);
	}

	draw2TimesDepthTested() {
		this.material!.depthTest = true;
		this.material!.depthFunc = LessDepth;
		this.onAfterRender = TextOnAfterRender2ndRender;
	}

	adjustScaleToFit(width: number, height: number) {
		const geo = this.geometry as TextGeometry;
		this.textScale = Math.min(width / geo.size.x, height / geo.size.y);
	}

	sizeVert(): number {
		return this.textScale * (this.geometry as TextGeometry).size.y;
	}
		
	setContrastColor(white: number) {
		this._whiteness = white;
		this._updateColor();
	}

	setBrightness(b: GizmoBrightness) {
		this._brightness = b;
		this._updateColor();
	}

	_updateColor() {
		this.glyphColor.copy(this.defaultBlack || blackTextColor)
			.lerpTo(this.defaultWhite || whiteTextColor, this._whiteness);
		
		if (this._brightness & GizmoBrightness.Highlighted) {
			this.glyphColor.lerpTo(this.defaultBright || BrighGizmoColor, 0.4);
		}
		if (this._brightness & GizmoBrightness.Selected) {
			this.glyphColor.lerpTo(this.defaultBright || BrighGizmoColor, 0.9);
		} 
	}

	updatePosition(position: Vector3, right_dir: Vector3, up_dir: Vector3, cameraMatrixWorld: Matrix4) {
		camera_right_t.setFromMatrixColumn(cameraMatrixWorld as any, 0);
		camera_up_t.setFromMatrixColumn(cameraMatrixWorld as any, 1);
		// camera_forw_t.setFromMatrixColumn(camera.matrixWorld, 2);

		// is close to 90 degrees right up
		const isTextRightUp = right_dir.dotAbs(camera_up_t) * 0.05 > right_dir.dotAbs(camera_right_t);
		
		if (isTextRightUp) {
			// if (camera_forw_t.dotAbs(right_dir) > camera_up_t.dotAbs(right_dir)) {
			// 	[camera_forw_t, camera_up_t] = [camera_up_t, camera_forw_t];
			// }
			[camera_up_t, camera_right_t] = [camera_right_t, camera_up_t];
			camera_up_t.multiplyScalar(-1);
		}

		if (camera_up_t.dot(up_dir) < 0) {
			up_dir.multiplyScalar(-1);
			this.upside.y = -1;
		} else {
			this.upside.y = 1;
		}

		if (camera_right_t.dot(right_dir) < 0) {
			right_dir.multiplyScalar(-1);
		}

		const forward_dir = up_dir.clone().cross(right_dir);
		this.matrix.makeBasis(
			right_dir.clone().multiplyScalar(this.textScale) as any,
			up_dir.clone().multiplyScalar(this.textScale) as any,
			forward_dir.multiplyScalar(this.textScale) as any);
		this.matrix.setPositionV(position);
		// this.matrixWorld.copy(this.matrix);
	}
}

function TextOnAfterRender2ndRender(this:TextMesh, renderer: WebGLRenderer, _scene: Scene, camera: Camera, geometry: Geometry | BufferGeometry,
	material: Material, _group: Group): void {
	renderer.state.buffers.depth.setFunc(GreaterDepth);
	const opacityBefore = this.glyphColor.w;
	this.glyphColor.w *= 0.6;
	renderer.renderBufferDirect(camera, null, geometry, material, this, null);
	this.glyphColor.w = opacityBefore;
	renderer.state.buffers.depth.setFunc(LessDepth);
}



let camera_right_t: Vector3 = Vector3.zero();
let camera_up_t: Vector3 = Vector3.zero();
