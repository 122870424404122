import { NumberProperty, type Bim } from "bim-ts";
import { ObjectUtils } from "engine-utils-ts";
import type { CostReport } from "../../CostReport";
import { TableEntry } from "../../TableHierarchy";
import { Action, fixRateByRateUnit } from "../Action";
import { CustomAmount, CustomAmountTypeIdentifier } from "../custom-amount/CustomAmount";

export const OverrideTypeIdentifier = 'override';

export class Override {
    constructor(
        public path: string[] = [],
        public entry = new TableEntry(),
    ) {}
}

export function registerOverrideAction(costReport: CostReport, bim: Bim) {
    costReport.actionSolvers.registerActionSolver<Override>(
        OverrideTypeIdentifier,
        (action, hierarchy, actionIdx) => {
            const exactEntry = hierarchy.getEntry(action.props.path);
            const entries = hierarchy.getEntriesWithPrefix(action.props.path)

            const newPathComponent = action.props.entry.path.at(-1);
            const pathChangeIdx = action.props.path.length - 1;

            if (newPathComponent && pathChangeIdx >= 0) {
                for (const entry of entries) {
                    hierarchy.delete(entry.path);
                    entry.path.splice(pathChangeIdx, 1, newPathComponent);
                    hierarchy.addEntry(entry);
                }
            }

            //
            if (exactEntry && entries.length === 1) {
                if (action.props.entry.totalCost) {
                    exactEntry.totalCost =
                        ObjectUtils.deepCloneObj(action.props.entry.totalCost) ?? exactEntry.totalCost;
                    exactEntry.rate = null;
                    exactEntry.quantity = null;
                } else {
                    const prevExactEntryQuantity = exactEntry.quantity;
                    exactEntry.rate =
                        ObjectUtils.deepCloneObj(action.props.entry.rate) ?? exactEntry.rate;
                    exactEntry.quantity =
                        ObjectUtils.deepCloneObj(action.props.entry.quantity) ?? exactEntry.quantity;
                    const rateUnit = !prevExactEntryQuantity ? undefined : NumberProperty.new({
                      unit: prevExactEntryQuantity.unit,
                      value: 1
                    });
                    fixRateByRateUnit(
                        exactEntry,
                        bim.unitsMapper,
                        rateUnit,
                    );
                    if (action.userCreated) {
                      exactEntry.rateOverride = exactEntry.rateOverride || !!action.props.entry.rate;
                      exactEntry.quantityOverride = exactEntry.quantityOverride || !!action.props.entry.quantity;
                    }
                }
                hierarchy.leafRelactulation(exactEntry.path);
            }

        }
    )
}

