// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class PropertyAssetsValue implements flatbuffers.IUnpackableObject<PropertyAssetsValueT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):PropertyAssetsValue {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsPropertyAssetsValue(bb:flatbuffers.ByteBuffer, obj?:PropertyAssetsValue):PropertyAssetsValue {
  return (obj || new PropertyAssetsValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsPropertyAssetsValue(bb:flatbuffers.ByteBuffer, obj?:PropertyAssetsValue):PropertyAssetsValue {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new PropertyAssetsValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
}

valueLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

valueArray():Uint32Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? new Uint32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

maxCount():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

types(index: number):string
types(index: number,optionalEncoding:flatbuffers.Encoding):string|Uint8Array
types(index: number,optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
}

typesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startPropertyAssetsValue(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, valueOffset, 0);
}

static createValueVector(builder:flatbuffers.Builder, data:number[]|Uint32Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createValueVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createValueVector(builder:flatbuffers.Builder, data:number[]|Uint32Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt32(data[i]!);
  }
  return builder.endVector();
}

static startValueVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addMaxCount(builder:flatbuffers.Builder, maxCount:number) {
  builder.addFieldInt32(1, maxCount, 0);
}

static addTypes(builder:flatbuffers.Builder, typesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, typesOffset, 0);
}

static createTypesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startTypesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endPropertyAssetsValue(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createPropertyAssetsValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset, maxCount:number, typesOffset:flatbuffers.Offset):flatbuffers.Offset {
  PropertyAssetsValue.startPropertyAssetsValue(builder);
  PropertyAssetsValue.addValue(builder, valueOffset);
  PropertyAssetsValue.addMaxCount(builder, maxCount);
  PropertyAssetsValue.addTypes(builder, typesOffset);
  return PropertyAssetsValue.endPropertyAssetsValue(builder);
}

unpack(): PropertyAssetsValueT {
  return new PropertyAssetsValueT(
    this.bb!.createScalarList<number>(this.value.bind(this), this.valueLength()),
    this.maxCount(),
    this.bb!.createScalarList<string>(this.types.bind(this), this.typesLength())
  );
}


unpackTo(_o: PropertyAssetsValueT): void {
  _o.value = this.bb!.createScalarList<number>(this.value.bind(this), this.valueLength());
  _o.maxCount = this.maxCount();
  _o.types = this.bb!.createScalarList<string>(this.types.bind(this), this.typesLength());
}
}

export class PropertyAssetsValueT implements flatbuffers.IGeneratedObject {
constructor(
  public value: (number)[] = [],
  public maxCount: number = 0,
  public types: (string)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const value = PropertyAssetsValue.createValueVector(builder, this.value);
  const types = PropertyAssetsValue.createTypesVector(builder, builder.createObjectOffsetList(this.types));

  return PropertyAssetsValue.createPropertyAssetsValue(builder,
    value,
    this.maxCount,
    types
  );
}
}
