<script lang="ts">
    import CostReportView from "./components/CostReportView.svelte";
    import CostReportEditor from "./components/CostReportEditor.svelte";
    import EngineUiPanel from "../ui-panels/EngineUiPanel.svelte";
    import CostModel from '../cost-model/capital/CostModel.svelte';

    const isAdmin = localStorage.getItem("mode") === "admin";
    let admin = false;

    //const isCostModel = localStorage.getItem('cost-menu')
    const showNewCostModel = true;
</script>


{#if showNewCostModel}
    <CostModel />
{:else}
    <EngineUiPanel>
        {#if isAdmin}
            <button on:click={() => (admin = !admin)}> admin </button>
        {/if}

        <div style="height: {admin ? '50%' : '100%'}">
            <CostReportView />
        </div>

        {#if admin}
            <div style="height: 50%">
                <CostReportEditor close={() => (admin = false)} />
            </div>
        {/if}
    </EngineUiPanel>
{/if}

<style lang="scss">
    button {
        position: absolute;
        right: 2px;
        top: 2px;
        z-index: 100;
    }
</style>
