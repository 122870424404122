import { writable } from "svelte/store";
import type{ Writable } from "svelte/store";

export abstract class BindedStore<T> implements Writable<T> {
    _store: Writable<T>;
    subscribe: typeof this._store['subscribe'];
    set: typeof this._store['set'];
    update: typeof this._store['update'];
    _storeValue: T;
    constructor(initValue: T) {
        this._store = writable(initValue);
        this._storeValue = initValue;
        this.subscribe = this._store.subscribe.bind(this._store);
        this.set = this._store.set.bind(this._store);
        this.update = this._store.update.bind(this._store);
        this.subscribe(s => this._storeValue = s);
    }
}
