import {
	AddEquation, DstAlphaFactor, NormalBlending, ZeroFactor,
} from '../3rdParty/three';
import type { ShaderBase } from './ShaderBase';

export const EdgeBlendShader: ShaderBase = {

	name: 'EdgeBlendShader',

	uniforms: {

		// tColor:   { value: null },
		tStd:   { value: null },
		tSelection:   { value: null },

	},

	params: {
		transparent: true,
		blending: NormalBlending,
		blendEquation: AddEquation,
		blendSrcAlpha: DstAlphaFactor,
		blendDstAlpha: ZeroFactor,
		blendEquationAlpha: AddEquation,
		premultipliedAlpha: true,
	},

	vertexShader: `

        // uniform sampler2D tColor;
		uniform sampler2D tSelection;
		varying vec2 resolutionSel;
		varying vec2 vUv;

		void main() {
			vUv = uv;

			ivec2 sizeSel = textureSize(tSelection, 0);
			resolutionSel = 1.0 / vec2(float(sizeSel.x), float(sizeSel.y));

			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

		}

	`,

	fragmentShader: `
        // uniform sampler2D tColor;
        uniform sampler2D tStd;
        uniform sampler2D tSelection;

		varying vec2 resolutionStd;
		varying vec2 resolutionSel;
		varying vec2 vUv;

		void main() {

			float selEdgeRgba1 = texture2D( tSelection, vUv + vec2(0.4, 0.4) * resolutionSel).r;
			float selEdgeRgba2 = texture2D( tSelection, vUv + vec2(-0.4, -0.4) * resolutionSel).r;

			float selEdgeRgba = (selEdgeRgba1 + selEdgeRgba2) * 0.4;

			vec4 colorRgba = vec4(0.0, 0.0, 0.0, 0.0);
            colorRgba = mix(colorRgba, vec4(1.0, 0.8, 0.0, 1.0), selEdgeRgba);
			
            gl_FragColor = colorRgba;
		}

	`

};
