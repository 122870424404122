import type { PropertyBase} from "src";
import { InverterTypeIdent, type Bim, type NumberProperty, type PropertyGroup, type StringProperty, TransformerIdent } from "../../..";
import type { ConfigShapeMigration} from "src/bimConfigs/ConfigsArchetypes";
import { StateType } from "src/bimConfigs/ConfigsArchetypes";
import { CivilTableId, DesignAndEngineeringTableId, PCSTableId } from "..";

export const CostsConfigIdentifier = 'costs-config';

export function registerCostsConfig(bim: Bim) {
    bim.configs.archetypes.registerArchetype({
        type_identifier: CostsConfigIdentifier,
        properties: () => {
            const properties: CostsConfigProps = {
                instances: [],
                estimates: [],
                tables: [],
            };
            return properties;
        },
        stateType: StateType.Singleton,
        shapeMigrations: getMigrations(),
    });
}

function getMigrations(): ConfigShapeMigration[] {
    return [
        {
            toVersion: 5,
            patch: (config) => {
                config.properties = {
                    estimates: [],
                    instances: [],
                    tables: [],
                } as CostsConfigProps
            },
            validation: {
                updatedProps: [],
                deletedProps: [],
            },
        },
        {
            toVersion: 6,
            patch: (config) => {
                const props = config.get<CostsConfigProps>()
                const pcs = props.tables.find(x => x.id.value === PCSTableId)
                const civil = props.tables.find(x => x.id.value === CivilTableId)
                const designEngineering = props.tables.find(x => x.id.value === DesignAndEngineeringTableId);
                if (pcs) {
                    pcs.hiddenColumnGroupIds = [];
                }
                if (civil) {
                    civil.hiddenColumnGroupIds = [];
                }
                if (designEngineering) {
                    designEngineering.hiddenColumnGroupIds = [];
                }
            },
            validation: {
                updatedProps: [],
                deletedProps: [],
            }
        },
        {
            toVersion: 7,
            patch: (config) => {
                const props = config.get<CostsConfigProps>()
                for (const x of props.instances) {
                    if (x.instance_type.value === InverterTypeIdent) {
                        delete x.props['fxmr']
                    } else if (x.instance_type.value === TransformerIdent) {
                        delete x.props['skid']
                    }
                }
            },
            validation: {
                updatedProps: [],
                deletedProps: [],
            }
        }
    ]
}

export interface CostsConfigProps extends PropertyGroup {
    instances: InstanceCost[]
    estimates: EstimateCost[]
    tables: TableSettings[]
}

export interface InstanceCost extends PropertyGroup {
    instance_type: StringProperty
    props: NamedBimPropertiesGroupRepresentation
    costs: CostComponents
    name: StringProperty
}


export interface TableSettings extends PropertyGroup {
    id: StringProperty
    hiddenColumnGroupIds: StringProperty[]
}

export interface EstimateCost extends PropertyGroup {
    id: StringProperty
    costs: CostComponents
    costSource: StringProperty
}

export interface CostComponentsNonNullable extends CostComponents {
    laborTimeUnits: NumberProperty
    laborTimeUnitCost: NumberProperty
    laborCost: NumberProperty
    materialCost: NumberProperty
    serviceCost: NumberProperty
    equipmentCost: NumberProperty
}

export interface CostComponents extends PropertyGroup {
    laborTimeUnits: NumberProperty | null
    laborTimeUnitCost: NumberProperty | null
    laborCost: NumberProperty | null
    materialCost: NumberProperty | null
    serviceCost: NumberProperty | null
    equipmentCost: NumberProperty | null
}

export interface BimPropertyRepresentation extends PropertyGroup {
    propertyPath: StringProperty[]
    property: PropertyBase
}

export interface NamedBimPropertiesGroupRepresentation extends PropertyGroup {
    [key: string]: BimPropertyRepresentation
}
