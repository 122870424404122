<script lang="ts">
    import { MetricGroupNode, transformGroupToRelative } from "./MetricProperty";
    import { LazyVersioned } from "engine-utils-ts";
    import MetricsGroup from "./MetricsGroup.svelte";
    import { VersionedStore } from "../../VersionedStore";
    import { createUiState } from "../../pui/PUI_RuntimeState";

    export let metricsUi: LazyVersioned<MetricGroupNode>;

    $: metrics = new VersionedStore(metricsUi, 150);

    const persistentUiState = createUiState();
    const store = persistentUiState.store;

    $: countItems = $metrics.countItems();
    $: group = $metrics.relativeMode ? transformGroupToRelative($metrics, $metrics.relativeMode) : $metrics;

    $: widthPx = countItems > 1 ? `${300 + 125 * countItems}px` : "calc(100%-32px)";
</script>

<div class="container" class:group-overflow={countItems > 1}
    style="--widthPx: {widthPx}"
>
    <div class="group-holder" >
        <MetricsGroup uiState={store} group={group} depth={0} />
    </div>
</div>

<style lang="scss">
    .group-holder {
        padding: 0 16px;
        width: var(--widthPx);
    }

    .group-overflow {
        box-sizing: content-box;
        position: relative;
        overflow-x: auto;
    }

    .container {
        width: 100%;
        right: 0;
        left: 0;
        display: block;
        background-color: white;
    }
</style>
