import { CostModel as CM } from 'bim-ts';
import { ColDef } from "ag-grid-enterprise";
import { createModifiableCostCellDefinition } from './common';


export function createMaterialCostPerUnitColumnDefinition(): ColDef<CM.FlattenedCostCategoryParams> {
    return {
        colId: CM.MaterialCostPerUnitColId,
        headerName: CM.MaterialCostPerUnitColId,
        cellEditor: 'agNumberCellEditor',
        ...createModifiableCostCellDefinition(x => x.material?.materialCostPerUnit),
    }
}
