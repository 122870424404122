// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { TypeIdentifierVersionT } from './type-identifier-version.js';
import { TypeIdentifierVersion } from './type-identifier-version.js';


export class TypeIdentifierVersions implements flatbuffers.IUnpackableObject<TypeIdentifierVersionsT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):TypeIdentifierVersions {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsTypeIdentifierVersions(bb:flatbuffers.ByteBuffer, obj?:TypeIdentifierVersions):TypeIdentifierVersions {
  return (obj || new TypeIdentifierVersions()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsTypeIdentifierVersions(bb:flatbuffers.ByteBuffer, obj?:TypeIdentifierVersions):TypeIdentifierVersions {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new TypeIdentifierVersions()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

perTypeVersion(index: number, obj?:TypeIdentifierVersion):TypeIdentifierVersion|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new TypeIdentifierVersion()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

perTypeVersionLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startTypeIdentifierVersions(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addPerTypeVersion(builder:flatbuffers.Builder, perTypeVersionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, perTypeVersionOffset, 0);
}

static createPerTypeVersionVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startPerTypeVersionVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endTypeIdentifierVersions(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishTypeIdentifierVersionsBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset);
}

static finishSizePrefixedTypeIdentifierVersionsBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, undefined, true);
}

static createTypeIdentifierVersions(builder:flatbuffers.Builder, perTypeVersionOffset:flatbuffers.Offset):flatbuffers.Offset {
  TypeIdentifierVersions.startTypeIdentifierVersions(builder);
  TypeIdentifierVersions.addPerTypeVersion(builder, perTypeVersionOffset);
  return TypeIdentifierVersions.endTypeIdentifierVersions(builder);
}

unpack(): TypeIdentifierVersionsT {
  return new TypeIdentifierVersionsT(
    this.bb!.createObjList<TypeIdentifierVersion, TypeIdentifierVersionT>(this.perTypeVersion.bind(this), this.perTypeVersionLength())
  );
}


unpackTo(_o: TypeIdentifierVersionsT): void {
  _o.perTypeVersion = this.bb!.createObjList<TypeIdentifierVersion, TypeIdentifierVersionT>(this.perTypeVersion.bind(this), this.perTypeVersionLength());
}
}

export class TypeIdentifierVersionsT implements flatbuffers.IGeneratedObject {
constructor(
  public perTypeVersion: (TypeIdentifierVersionT)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const perTypeVersion = TypeIdentifierVersions.createPerTypeVersionVector(builder, builder.createObjectOffsetList(this.perTypeVersion));

  return TypeIdentifierVersions.createTypeIdentifierVersions(builder,
    perTypeVersion
  );
}
}
