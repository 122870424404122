import { Bim, IdBimScene, RepresentationBase, SceneInstance, TerrainHeightMapRepresentation } from "bim-ts";
import { DefaultMap, Failure, Success, Yield } from "engine-utils-ts";
import { Aabb, KrMath } from "math-ts";
import { ProjectInfo, VerdataSyncerType } from "src";
import { FileExporterContext, PropertyFitChecker, PUI_ConfigBasedBuilderParams, PUI_ConfigPropertyTransformer, PUI_PropertyNodeSelector, PUI_PropertyNodeSelectorArgs } from "ui-bindings";

export const contoursSimplifyingEps = 2e-3;

export class CommonExportSettings {
    export_only_selected: boolean = true;
}

export function getExportProjectName(projectVerdataSyncer: VerdataSyncerType, projectInfo?:ProjectInfo,): string | undefined {
    let projectNameParts:string[] = [];
    const projectId = window.location.pathname.slice(1);

    if(projectId !== null){
        projectNameParts.push(projectId);
    }

    if(projectInfo!==undefined){
        const projectName = projectInfo.name;
        projectNameParts.push(projectName);
    }

    const version = projectVerdataSyncer.getCurrentVersionId();
    projectNameParts.push(`ver.${version}`);

    return projectNameParts.join("_");
}


export function getPropsForExportOptions(terrains: [IdBimScene, SceneInstance][], bim: Bim) {
    const goemetriesAabbs = bim.allBimGeometries.aabbs.poll();
    const reprsBboxes = new DefaultMap<RepresentationBase, Aabb>(r => r.aabb(goemetriesAabbs));

    let maxSegmentSize = 0;
    let totalArea = 0;
    for (const [_, inst] of terrains) {
        if (!inst.representation) {
            continue;
        }

        const aabb = reprsBboxes.getOrCreate(inst.representation);
        totalArea += aabb.width() * aabb.depth();

        for (const tile of (inst.representation as TerrainHeightMapRepresentation).tiles.values()) {
            const geo = bim.regularHeightmapGeometries.peekById(tile?.initialGeo);
            if (!geo) {
                continue;
            }
            if (geo.segmentSizeInMeters > maxSegmentSize) {
                maxSegmentSize = geo.segmentSizeInMeters;
            }
            break;
        }
    }

    if (maxSegmentSize === 0) {
        maxSegmentSize = KrMath.clamp(KrMath.floorPowerOfTwo(1e-6 * totalArea), 0.5, 2);
    }

    return { maxSegmentSize, totalArea };
}


export function getAvailableSegmentSizes(maxSegmentSize: number, totalArea: number, bytesPerPixel: number): Map<number, string> {
    const options = new Map<number, string>();

    for (let i = 1; i <= 4; i *= 2) {
        const pixelSize = maxSegmentSize * i;
        const totalPixels = totalArea / (pixelSize * pixelSize);
        const fileSizeMB = (totalPixels * bytesPerPixel) / (1024 * 1024);

        const details = `1px = ${pixelSize}m (~${fileSizeMB.toFixed(1)} MB)`;
        options.set(pixelSize, details);
    }

    return options;
}


export function* selectExportResolution(
    context: FileExporterContext, 
    options:Map<number, string>, 
    defaultFieldName: string, 
    defaultValue: number ): Generator<Yield, number> {

    function getValueFromDetails(details: string, options: Map<number, string>) {
        for (let [key, value] of options.entries()) {
            if (value === details) {
                return new Success(key);
            }
        }
        return new Failure({msg: "unexpected export parameter"});
    }

    const defaultValueWithField = { [defaultFieldName]: defaultValue };

    const propertyFitChecker: PropertyFitChecker = (
        propertyName: string | number,
        propertyValue: number,
        propertyPath: (string | number)[]
    ) => {
        return options.has(propertyValue);
    };
    const propertyTransformer = new PUI_ConfigPropertyTransformer<
        number,
        string,
        PUI_PropertyNodeSelector,
        PUI_PropertyNodeSelectorArgs
    >(
        (num) => options.get(num)!,
        (propValue) => getValueFromDetails(propValue, options),
        () => [
            PUI_PropertyNodeSelector,
            {
                options: [...options.values()],
            },
        ]
    );
    const params = yield* context.requestSettings({
        ident: "export-settings",
        defaultValue: defaultValueWithField,
        uiBuilderParams: {
            configBuilderParams: PUI_ConfigBasedBuilderParams.new([
                [propertyFitChecker, propertyTransformer],
            ]),
        },
    });

    return params[defaultFieldName];
}

