import { requestExecutionFrame } from './ExecutionFrame';
import { IterUtils } from './IterUtils';
import { ObjectUtils } from './ObjectUtils';
import type {
    EventStackFrame} from './stateSync/EventsStackFrame';
import { peekCurrentEventFrame, unsafePopFromEventStackFrame, unsafePushToEventStackFrame
} from './stateSync/EventsStackFrame';
import { ObservableObject } from './stateSync/ObservableObject';

export interface UndoStackState {
	canUndo: boolean,
	canRedo: boolean,
}

export interface UndoTransactionParams {
	undoFrameId: number,
}

export interface ArgsMerger<Args> {
	dependsOnSourceIdentifiers: readonly string[],
	argsMerger: (lhs: Args, rhs: Args) => Args;
}

export interface UndoAction<Args> {
	actionName: string;
	sourceIdentifier: string;
	act: (args: Args) => void;
	args: Readonly<Args>;
    isBarrierForMerges?: boolean;
	argsMerger?: ArgsMerger<Args>;
}


export class UndoStack {

	readonly observableState: ObservableObject<UndoStackState>;

	private _frameId: number = 1; // monotonically increasing

	private _undoTransactions: UndoVec = new UndoVec(512);
	private _redoTransactions: UndoVec = new UndoVec(512);

	private _isUndoing: boolean = false;
	private _isRedoing: boolean = false;

	private _ramLimitBytes: number;

	_isDisposed: boolean = false;

	constructor(name: string, ramLimitMb: number) {
		const initialState: UndoStackState = { canUndo: false, canRedo: false };
		this.observableState = new ObservableObject({ identifier: name, initialState });
		this._ramLimitBytes = ramLimitMb * 1024 * 1024;
		if (!(this._ramLimitBytes > 0)) {
			throw new Error('undo stack ram limit is not set ' + this._ramLimitBytes);
		}

		if (globalThis instanceof Window) {
            listenToMouseGestures();
			const frameCallback = () => {
				if (this._isDisposed) {
					return;
				}
				requestExecutionFrame(frameCallback);
				this.endFrame();
			}
			requestExecutionFrame(frameCallback);
		}
	}

	dispose() {
		this._isDisposed = true;
		this.clear();
	}

	updateState() {
		const canRedo = this._redoTransactions.count() > 0;
		if (canRedo != this.observableState.poll().canRedo) {
			this.observableState.applyPatch({
				patch: {
					canRedo,
				}
			});
		}
		const canUndo = this._undoTransactions.count() > 0;
		if (canUndo != this.observableState.poll().canUndo) {
			this.observableState.applyPatch({
				patch: {
					canUndo: this._undoTransactions.count() > 0,
				}
			});
		}
	}

	endFrame() {
		if (this.isRedoing() || this.isUndoing()) {
			console.error('undo stack is corrupted, end frame state is invalid');
		}
		this._frameId += 1;
	}

	isUndoing() {
		return this._isUndoing;
	}
	isRedoing() {
		return this._isRedoing;
	}
	isUndoingOrRedoing() {
		return this.isUndoing() || this.isRedoing();
	}

	addUndoAction<Args>(action: UndoAction<Args>) {
		if (this._isUndoing) {
			this._redoTransactions.add(action, this._frameId);
		} else {
			const {startedNewTransaction} = this._undoTransactions.add(action, this._frameId);
			if (!this._isRedoing && startedNewTransaction) {
				this._redoTransactions.clear();
			}
            if (startedNewTransaction) {
                // console.log('start new undo tr', action.actionName, action.sourceIdentifier);
            }
		}
		this.forceRamLimits();
		this.updateState();
	}

	undo() {
		const event = unsafePushToEventStackFrame({
			identifier: 'undo', transactionId: `${this._frameId}_undo`
		});
		try {
			this._isUndoing = true;
			this._undoTransactions.executeLastSafe();
		} finally {
			this._isUndoing = false;
			unsafePopFromEventStackFrame(event);
		}
		this.updateState();
	}

	redo() {
		const event = unsafePushToEventStackFrame({
			identifier: 'redo', transactionId: `${this._frameId}_redo`
		});
		try {
			this._isRedoing = true;
			this._redoTransactions.executeLastSafe();
		} finally {
			this._isRedoing = false;
			unsafePopFromEventStackFrame(event);
		}
		this.updateState();
	}


	clear() {
		this._undoTransactions.clear();
		this._redoTransactions.clear();
	}

	forceRamLimits() {
		const currentConsumption = this._currentMemoryConsumptionBytes();
		if (currentConsumption <= this._ramLimitBytes) {
			return;
		}
		let bytesLeftToRemove = currentConsumption - this._ramLimitBytes;
		// first try to remove undo transactions
		while (bytesLeftToRemove > 0) {
			const removed = this._undoTransactions.removeOldest();
			if (removed) {
				bytesLeftToRemove -= removed.bytesRam;
			} else {
				break;
			}
		}
		// then try to remove redo
		while (bytesLeftToRemove > 0) {
			const removed = this._redoTransactions.removeOldest();
			if (removed) {
				bytesLeftToRemove -= removed.bytesRam;
			} else {
				break;
			}
		}
		const afterConsumption = this._currentMemoryConsumptionBytes();
	}

	_currentMemoryConsumptionBytes(): number {
		const undoMemory = this._undoTransactions.currentMemoryConsumptionBytes();
		const redoMemory = this._redoTransactions.currentMemoryConsumptionBytes();
		return undoMemory + redoMemory;
	}
}

let transactionsCounter = 0;

interface Transaction {
	frameId: number;
	transactionId: number | string | Symbol;
	actions: UndoActionExt<any>[];
	transactionN: number,
}


class UndoVec {

	private readonly transactions: Transaction[] = [];
	readonly capacity: number;

	constructor(capacity: number) {
		this.capacity = capacity;
	}

	lastundoTransactionCounter(): number {
		if (this.transactions.length > 0) {
			return this.transactions[this.transactions.length - 1].transactionN;
		}
		return Infinity;
	}

	clearRedoTransactionsAfter(transactionN: number) {
		for (let i = this.transactions.length - 1; i >= 0; i -= 1) {
			if (this.transactions[i].transactionN > transactionN) {
				this.transactions.length = i;
			} else {
				break;
			}
		}
	}

	removeOldest(): {bytesRam: number} | null {
		const tr = this.transactions.shift();
		if (tr) {
			const ram = tr.actions.reduce((s, c) => s + c.argsRoughMemSize, 0);
			return {bytesRam: ram};
		}
		return null;
	}

	currentMemoryConsumptionBytes(): number {
		let sum = 0;
		for (const tr of this.transactions) {
			for (const a of tr.actions) {
				sum += a.argsRoughMemSize;
			}
		}
		return sum;
	}

	add(action: UndoAction<any>, frameId: number): {startedNewTransaction: boolean} {
		if (this.transactions.length >= this.capacity) {
			this.transactions.shift();
		}
		const thisTransactionId = peekCurrentEventFrame().transactionId;
		const lastTransaction = this.transactions[this.transactions.length - 1];

		// const transactionToAppendTo = this.transactions.length ? this.transactions[this.transactions.length - 1];

		if (lastTransaction
			&& (lastTransaction.frameId === frameId
				|| (thisTransactionId && lastTransaction.transactionId === thisTransactionId)
                || peekCurrentEventFrame().isEventDerived
            )
		) {
            if (action.argsMerger) { // try find prev transaction to merge with
                const sources = new Set<string | undefined>();
                for (const prevAction of IterUtils.reverseIter(lastTransaction.actions)) {
                    if (prevAction.isBarrierForMerges) {
                        break;
                    }
                    if (prevAction.sourceIdentifier !== action.sourceIdentifier) {
                        sources.add(prevAction.sourceIdentifier);
                        continue;
                    }
                    if (prevAction.actionName !== action.actionName || !prevAction.argsMerger) {
                        break;
                    }
                    if (IterUtils.intersectsSet(sources, action.argsMerger.dependsOnSourceIdentifiers)) {
                        break;
                    }
                    prevAction.args = prevAction.argsMerger.argsMerger(prevAction.args, action.args);
                    prevAction.argsRoughMemSize = ObjectUtils.fastEvaluateObjectSizeInbytes(prevAction.args);
                    return {startedNewTransaction: false};
                }
            }
            const argsRoughMemSize = ObjectUtils.fastEvaluateObjectSizeInbytes(action.args);
            lastTransaction.actions.push({...action, argsRoughMemSize});
            return {startedNewTransaction: false};
		} else {
            const argsRoughMemSize = ObjectUtils.fastEvaluateObjectSizeInbytes(action.args);
			this.transactions.push( {
				frameId,
				transactionId: thisTransactionId ?? -1,
				actions: [{...action, argsRoughMemSize}],
				transactionN: transactionsCounter += 1,
			});
            return {startedNewTransaction: true};
		}
	}

	executeLastSafe() {
		let prevTransaction = this.transactions[this.transactions.length - 1];
		let first = true;
		while (this.transactions.length > 0) {
			let tr = this.transactions[this.transactions.length - 1];
			if (!(
				first
				|| prevTransaction.frameId === tr.frameId
				|| (tr.transactionId !== -1 && tr.transactionId === prevTransaction.transactionId))
			) {
				break;
			}
			prevTransaction = tr;
			this.transactions.length -= 1;
			first = false;
			for (let i = tr.actions.length - 1; i >= 0; --i) {
				const a = tr.actions[i];
				try {
					tr.actions[i].act(a.args);
				} catch (e) {
					console.error('error executing undo ', a.actionName, e);
				}
			}
		}
	}

	clear() {
		this.transactions.length = 0;
	}

	count(): number {
		return this.transactions.length;
	}
}

interface UndoActionExt<T> extends UndoAction<T> {
	argsRoughMemSize: number;
}


let listeningEventsAlready = false;

function listenToMouseGestures() {
    if (listeningEventsAlready) {
        return;
    }
    const window = globalThis instanceof Window ? globalThis : null;
    if (!window) {
        return;
    }
    listeningEventsAlready = true;

    let transactionsCount = 0;
    const eventsPerPointer = new Map<number, EventStackFrame>();
    function pointerDown(e: PointerEvent) {
        const esf = unsafePushToEventStackFrame({transactionId: transactionsCount += 1});
        eventsPerPointer.set(e.pointerId, esf);
    }
    function pointerUp(e: PointerEvent) {
        const esf = eventsPerPointer.get(e.pointerId);
        eventsPerPointer.delete(e.pointerId);
        if (esf) {
            unsafePopFromEventStackFrame(esf);
        }
    }

    window.addEventListener('pointerdown', pointerDown);
    window.addEventListener('pointercancel', pointerUp);
    window.addEventListener('pointerup', pointerUp);
}
