import type { EntityId } from "verdata-ts";
import { EntitiesBase } from "../collections/EntitiesBase";
import { Asset } from "./Asset";
import { SceneInstancePerAsset } from "./SceneInstancePerAsset";

export enum AssetIdType {
    Default = -1,
}
export type AssetId = EntityId<AssetIdType>;

export class AssetCollection extends EntitiesBase<Asset, AssetId> {

    public readonly sceneInstancePerAsset = new SceneInstancePerAsset(this);
    public readonly assetsMatcher = this.sceneInstancePerAsset.assetsMatcher;

    constructor() {
        super({
            identifier: 'assetCollection',
            idsType: AssetIdType.Default,
            T_Constructor: Asset,
        });
    }
    version() {
        return super.version() + this.sceneInstancePerAsset.assetIdToSceneInstanceId.version();
    }
    checkForErrors(_t: Asset, _errors: string[]): void {
        return;
    }
}
