import type { LongTask, Observer} from "engine-utils-ts";
import { CollectionBasic, Deleted, Failure, MappedCollectionBasic, ScopedLogger, TasksRunner, Yield, requestExecutionFrame } from "engine-utils-ts";
import type { Asset, BimPatch, IdBimScene } from "..";
import { Bim, BimProperty, convertBimAssetToBimPatch } from "..";
import type { AssetCollection, AssetId } from "./AssetCollection";
import { AssetsMatcher } from "./AssetsMatcher";

export class SceneInstancePerAsset {
    private logger = new ScopedLogger('SceneInstancePerAsset');

    private createSceneInstanceFromAssetTasks:
        MappedCollectionBasic<LongTask<BimPatch>, AssetId, Asset>;
    private usedTasks = new WeakSet<LongTask<BimPatch>>();

    private tasksRunner = new TasksRunner(this.logger).withSelfRunning();
    
    private assetDeleteObserver: Observer;
    
    public readonly assetIdToSceneInstanceId =
        new CollectionBasic<IdBimScene, AssetId>('assetIdToSceneInstanceId');

    public readonly bim = new Bim({});

    public readonly assetsMatcher: AssetsMatcher;

    constructor(assets: AssetCollection) {
        this.createSceneInstanceFromAssetTasks = new MappedCollectionBasic({
            identifier: 'createSceneInstanceFromAssetTasks',
            dataSource: assets,
            logger: this.logger,
            mapFn: (asset) => {
                const task = this.tasksRunner.newLongTask({
                    identifier: `syncTask`,
                    defaultGenerator: (function*(self: SceneInstancePerAsset) {
                        const bimPatchResult = yield* convertBimAssetToBimPatch(
                            asset.bimasset,
                            self.bim
                        )
                        requestExecutionFrame(self.applyTaskResult);
                        return bimPatchResult;
                    })(this)
                })
                return task;
            },
        });
        this.assetDeleteObserver = assets.updatesStream.subscribe({
            settings: { immediateMode: true },
            onNext: (update) => {
                if (update instanceof Deleted) {
                    this.assetIdToSceneInstanceId.delete(update.ids)
                    for (const id of update.ids) {
                        const siId = this.assetIdToSceneInstanceId.perId.get(id);
                        if (siId !== undefined) {
                            this.bim.instances.delete([siId]);
                        }
                    }
                }
            }
        });
        this.assetsMatcher = new AssetsMatcher(this);
    }


    dispose() {
        this.bim.dispose();
        this.tasksRunner.dispose();
        this.createSceneInstanceFromAssetTasks.dispose();
        this.assetIdToSceneInstanceId.dispose();
        this.assetDeleteObserver.dispose();
    }

    getAssetAsSceneInstance(id: AssetId) {
        const siId = this.assetIdToSceneInstanceId.perId.get(id);
        if (siId === undefined) {
            return null;
        }
        const si = this.bim.instances.peekById(siId);
        return si ?? null;
    }

    private applyTaskResult = () => {
        for (const [assetId, task] of this.createSceneInstanceFromAssetTasks.poll()) {
            // check if task was applied already
            if (this.usedTasks.has(task)) {
                continue;
            }
            if (!task.isFinalized()) {
                continue;
            }
            this.usedTasks.add(task);
            const result = task.asResult();
            // failed tasks
            if (result instanceof Failure) {
                this.logger.error('failed to import asset to bim');
                continue;
            }
            const patch = result.value;

            // clean previous instance
            const oldSiId = this.assetIdToSceneInstanceId.perId.get(assetId);
            if (oldSiId) {
                this.bim.instances.delete([oldSiId]);
            }

            if (patch.instances.toAlloc.length !== 1) {
                this.logger.error('asset has multiple objects. should be one. Might be memory leak');
            }

            const siIds = patch.applyTo(this.bim);
            const metaReferenceToAssetProperty = BimProperty.NewShared({
                path: ['_meta', 'assetId'],
                value: assetId,
            })
            this.bim.instances.applyPatchTo({
                properties: [
                    [
                        BimProperty.MergedPath(metaReferenceToAssetProperty.path),
                        metaReferenceToAssetProperty
                    ]
                ]
            }, siIds);

            let runtimeIterationsCount = 0;
            for (const yielded of this.bim.runUpdatesTillCompletion({forceRun: true})) {
                if (yielded === Yield.NextFrame) {
                    this.logger.error('bim runtime unexpectedly yielded next frame for asset', assetId);
                    break;
                }
                runtimeIterationsCount += 1;
                if (runtimeIterationsCount > 1000) {
                    this.logger.error('bim runtime unexepctedly high iterations count for asset ', assetId);
                    break;
                }
            };

            // invalidate default name map
            this.assetIdToSceneInstanceId.allocateOrUpdate([
                [assetId, siIds[0]],
            ]);

        }
    }

}
