import { KrMath, combineHashCodes, type TypedArray } from './KrMath';
import { type Matrix2 } from './Matrix2';
import { type Matrix3 } from './Matrix3';
import { type Matrix4 } from './Matrix4';

export class Vector2 {
	x: number = 0;
	y: number = 0;

	distanceToLine!: (this: Vector2, v1: Vector2, v2: Vector2) => number;

	constructor( x = 0, y = 0 ) {

		this.x = x;
		this.y = y;

	}

	toString() {
		return `(${this.x}:${this.y})`;
	}

    trulatet() {
    }

	get width() {
		return this.x;
	}

	set width( value ) {
		this.x = value;
	}

	get height() {
		return this.y;
	}

	set height( value ) {this.roundTo
		this.y = value;
	}

	static zero() {
		return new Vector2(0, 0);
	}

	static fromTuples(tuples: number[][]): Vector2[] {
		const res: Vector2[] = [];
		for (const t of tuples) {
			if (t.length == 2) {
				res.push(new Vector2(t[0], t[1]))
			}
		}
		return res;
	}
	static toTuples(vecs: Vector2[]): [number, number][] {
		const res: [number, number][] = [];
		for (const v of vecs) {
			res.push([v.x, v.y]);
		}
		return res;
	}

	static arrayFromFlatArray(arr: ArrayLike<number>): Vector2[] {
		if (arr.length % 2 !== 0) {
			throw new Error('krVec2s from array, should be multiple of 2');
		}
		const res: Vector2[] = [];
		for (let i = 0; i < arr.length; i += 2) {
			res.push(new Vector2(
				arr[i + 0],
				arr[i + 1],
			));
		}
		return res;
	}

	setScalar( scalar: number ) {
		this.x = scalar;
		this.y = scalar;

		return this;
	}

	isFinite(): boolean {
		return isFinite(this.x) && isFinite(this.y);
	}

	setX( x: number ) {
		this.x = x;
		return this;
	}

	setY( y: number ) {
		this.y = y;
		return this;
	}

	setComponent( index: number, value: number ) {
		switch ( index ) {
			case 0: this.x = value; break;
			case 1: this.y = value; break;
			default: throw new Error( 'index is out of range: ' + index );
		}
		return this;
	}

	getComponent( index: number) {
		switch ( index ) {
			case 0: return this.x;
			case 1: return this.y;
			default: throw new Error( 'index is out of range: ' + index );
		}
	}

	addVectors( a: Vector2, b: Vector2 ) {
		this.x = a.x + b.x;
		this.y = a.y + b.y;
		return this;
	}

	addScaledVector( v:Vector2, s: number ) {
		this.x += v.x * s;
		this.y += v.y * s;
		return this;
	}

	sub( v: Vector2) {
		this.x -= v.x;
		this.y -= v.y;
		return this;
	}

	subScalar( s: number ) {
		this.x -= s;
		this.y -= s;
		return this;
	}

	multiply( v: Vector2 ) {
		this.x *= v.x;
		this.y *= v.y;
		return this;
	}

	multiplyScalar( scalar: number ) {
		this.x *= scalar;
		this.y *= scalar;
		return this;
	}

	divide( v: Vector2 ) {
		this.x /= v.x;
		this.y /= v.y;
		return this;
	}

	divideScalar( scalar: number ) {
		return this.multiplyScalar( 1 / scalar );
	}

	applyMatrix2( m: Matrix2 ) {
		const x = this.x, y = this.y;
		const e = m.elements;

		this.x = e[ 0 ] * x + e[ 2 ] * y;
		this.y = e[ 1 ] * x + e[ 3 ] * y;

		return this;
	}

	applyMatrix3( m: Matrix3 ) {
		const x = this.x, y = this.y;
		const e = m.elements;

		this.x = e[ 0 ] * x + e[ 3 ] * y + e[ 6 ];
		this.y = e[ 1 ] * x + e[ 4 ] * y + e[ 7 ];

		return this;
	}

	applyMatrix4( m: Matrix4 ) {
		const x = this.x, y = this.y;
		const e = m.elements;
		const w = 1 / ( e[ 3 ] * x + e[ 7 ] * y + e[ 15 ] );

		this.x = ( e[ 0 ] * x + e[ 4 ] * y + e[ 12 ] ) * w;
		this.y = ( e[ 1 ] * x + e[ 5 ] * y + e[ 13 ] ) * w;
	}

	min( v: Vector2 ) {
		this.x = Math.min( this.x, v.x );
		this.y = Math.min( this.y, v.y );
		return this;
	}

	max( v: Vector2 ) {
		this.x = Math.max( this.x, v.x );
		this.y = Math.max( this.y, v.y );
		return this;
	}


	minComponent(): number {
		return this.x < this.y ? this.x : this.y;
	}

	maxComponent(): number {
		return this.x > this.y ? this.x : this.y;
	}

	clampScalar( minVal: number, maxVal: number ) {
		this.x = Math.max( minVal, Math.min( maxVal, this.x ) );
		this.y = Math.max( minVal, Math.min( maxVal, this.y ) );
		return this;
	}

	clampLength( min: number, max: number ) {
		const length = this.length();
		return this.divideScalar( length || 1 ).multiplyScalar( Math.max( min, Math.min( max, length ) ) );
	}

	floor() {
		this.x = Math.floor( this.x );
		this.y = Math.floor( this.y );
		return this;
	}
	floorTo(floorTo: number) {
		this.x = KrMath.floorTo( this.x, floorTo );
		this.y = KrMath.floorTo( this.y, floorTo );
		return this;
	}

	ceil() {
		this.x = Math.ceil( this.x );
		this.y = Math.ceil( this.y );
		return this;
	}
	ceilTo(ceilTo: number) {
		this.x = KrMath.ceilTo( this.x, ceilTo );
		this.y = KrMath.ceilTo( this.y, ceilTo );
		return this;
	}

	round() {
		this.x = Math.round( this.x );
		this.y = Math.round( this.y );
		return this;
	}

	roundTo(n: number) {
		this.x = KrMath.roundTo( this.x, n );
		this.y = KrMath.roundTo( this.y, n );
		return this;
	}

	roundToZero() {
		this.x = ( this.x < 0 ) ? Math.ceil( this.x ) : Math.floor( this.x );
		this.y = ( this.y < 0 ) ? Math.ceil( this.y ) : Math.floor( this.y );
		return this;
	}

	negate() {
		this.x = - this.x;
		this.y = - this.y;
		return this;
	}

	cross( v: Vector2 ) {
		return this.x * v.y - this.y * v.x;
	}

	lengthSq() {
		return this.x * this.x + this.y * this.y;
	}

	manhattanLength() {
		return Math.abs( this.x ) + Math.abs( this.y );
	}

	normalize() {
		return this.divideScalar( this.length() || 1 );
	}

	angle() {
		// computes the angle in radians with respect to the positive x-axis
		const angle = Math.atan2( - this.y, - this.x ) + Math.PI;
		return angle;
	}

	applyRotationAngle(angle: number) {
		const cos = Math.cos(angle);
		const sin = Math.sin(angle);
		const x = this.x;
		this.x = x * cos - this.y * sin;
		this.y = x * sin + this.y * cos;
		return this;
	}

	distanceTo( v: Vector2 ) {
		return Math.sqrt( this.distanceToSquared( v ) );
	}

	distanceToSquared( v: Vector2 ) {
		const dx = this.x - v.x, dy = this.y - v.y;
		return dx * dx + dy * dy;
	}

	manhattanDistanceTo( v: Vector2 ) {
		return Math.abs( this.x - v.x ) + Math.abs( this.y - v.y );
	}

	setLength( length: number ) {
		return this.normalize().multiplyScalar( length );
	}

	lerp( v: Vector2, alpha: number ) {
		this.x += ( v.x - this.x ) * alpha;
		this.y += ( v.y - this.y ) * alpha;
		return this;
	}

	lerpVectors( v1: Vector2, v2: Vector2, alpha: number ) {
		this.x = v1.x + ( v2.x - v1.x ) * alpha;
		this.y = v1.y + ( v2.y - v1.y ) * alpha;
		return this;
	}

	fromArray( array: number[], offset = 0 ) {
		this.x = array[ offset ];
		this.y = array[ offset + 1 ];
		return this;
	}

	asArray(): [ number, number ] {
		return [ this.x, this.y ];
	}

	fromBufferAttribute(attribute: any, index: number ) {
		this.x = attribute.getX( index );
		this.y = attribute.getY( index );
		return this;
	}

	rotateAround( center: Vector2, angle: number ) {
		const c = Math.cos( angle ), s = Math.sin( angle );

		const x = this.x - center.x;
		const y = this.y - center.y;

		this.x = x * c - y * s + center.x;
		this.y = x * s + y * c + center.y;

		return this;
	}

	random() {
		this.x = Math.random();
		this.y = Math.random();

		return this;
	}

	static fromScalar(scalar: number) {
		return new Vector2(scalar, scalar);
	}

	static fromArray(arr: ArrayLike<number>, offset: number): Vector2 {
		return new Vector2(arr[offset + 0], arr[offset + 1]);
	}

	static fromAngle(angleRadians: number) {
		return new Vector2(Math.cos(angleRadians), Math.sin(angleRadians));
	}

	static parseAlloc(x: string, y: string) {
		return new Vector2(
			parseFloat(x),
			parseFloat(y),
		)
	}

	toArray(arr: Array<number> | TypedArray, offset: number): Array<number> | TypedArray {
		arr[offset + 0] = this.x;
		arr[offset + 1] = this.y;
		return arr;
	}

	hash(): number {
		return combineHashCodes(this.x * 257, this.y * 263);
	}

	equals(other: Vector2) {
		return this.distanceToSquared(other) < 1e-12;
	}

	set(x: number, y: number) {
		this.x = x;
		this.y = y;
		return this;
	}

	copy(v: Vector2) {
		this.x = v.x;
		this.y = v.y;
		return this;
	}

	clone(): Vector2 {
		return new Vector2(this.x, this.y);
	}

	add(v: Vector2): Vector2 {
		this.x += v.x;
		this.y += v.y;
		return this;
	}

	addScalar(s: number): Vector2 {
		this.x += s;
		this.y += s;
		return this;
	}

	subVectors(v1: Vector2, v2: Vector2): Vector2 {
		this.x = v1.x - v2.x;
		this.y = v1.y - v2.y;
		return this;
	}

	aspect() {
		return this.x / this.y;
	}

	mapHtmlToWebglCoords() {
		return new Vector2(
			this.x * 2 - 1,
			this.y * -2 + 1
		);
	}

	mapWebglToHtmlCoords() {
		return new Vector2(
			(this.x + 1) / 2,
			(this.y - 1) / -2
		);
	}

	length(): number {
		return Math.sqrt(this.x * this.x + this.y * this.y);
	}

	setFromArray(array: ArrayLike<number>, offset: number) {
		this.x = array[offset];
		this.y = array[offset + 1];
	}

	lerpTo(v: Vector2, t: number) {
		this.x += ( v.x - this.x ) * t;
		this.y += ( v.y - this.y ) * t;
		return this;
	}

	clamp(min: Vector2, max: Vector2) {
		this.x = Math.max( min.x, Math.min( max.x, this.x ) );
		this.y = Math.max( min.y, Math.min( max.y, this.y ) );
		return this;
	}

	normalizeTo(length: number): Vector2 {
		const l = Math.sqrt(this.x * this.x + this.y * this.y);
		const mult = l / length;
		this.x *= mult;
		this.y *= mult;
		return this;
	}

	dot(v:Vector2): number {
		return this.x * v.x + this.y * v.y;
	}


	static asString(v: Vector2): string {
		return `[${v.x},${v.y}]`;
	}

    /**
     * @returns
     * Smallest angle in rads between `this` vector and `v` vector.
     */
    smallestAngleTo(v: Vector2): number {
        const a = this.angle();
        const b = v.angle();
        const delta = Math.abs(a - b);
        return Math.min(delta, 2 * Math.PI - delta);
    }

	/**
	 * @returns
	 * Rotate the vector counterclockwise
	 */
	static perpendicular(v: Vector2): Vector2 {
		return new Vector2(-v.y, v.x);
	}
}

Object.defineProperty(
	Vector2.prototype,
	'isVector2',
	{
		enumerable: false,
		configurable: false,
		value: true,
		writable: false
	}
);

Vector2.prototype.distanceToLine = function () {
	const diff1 = Vector2.zero();
	const diff2 = Vector2.zero();
    // https://mathworld.wolfram.com/Point-LineDistance2-Dimensional.html

	return function(this: Vector2, v1: Vector2, v2: Vector2): number {
		diff1.copy(this).sub(v1);
		diff2.copy(v2).sub(v1);
		const area = Math.abs(diff1.cross(diff2));
		return area / diff2.length();
	}
}();


export const Vec2Zero = Object.freeze(new Vector2(0.0, 0.0));
export const Vec2One = Object.freeze(new Vector2(1.0, 1.0));

export const Vec2X = Object.freeze(new Vector2(1.0, 0.0));
export const Vec2Y = Object.freeze(new Vector2(0.0, 1.0));


