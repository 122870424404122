<script lang="ts">
import { onMount, onDestroy, setContext } from 'svelte';

import fuzzysort from 'fuzzysort';

import type { ProjectHistory, ProjectVersion, VerDataSyncer } from 'verdata-ts';
import { List, ListComponent, ListInput, ListItem, ListOptions } from '../libui/list';
import { Action, ContextMenuStructure } from '../libui/context-menu';
import { ChipsInput } from '../libui/chips';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import VerDataEdit from './VerDataEdit.svelte';
import { ButtonComponent, Button, ButtonType } from '../libui/button';
import { TextField } from "../libui/fields";
import VerDataNew from './VerDataNew.svelte';
import EngineUiPanel from '../ui-panels/EngineUiPanel.svelte';

dayjs.extend(relativeTime);

export const header: string = 'Versions';
export let syncer: VerDataSyncer;

$: verDataSyncer = syncer;

setContext('verDataSyncer', syncer);

$: history = verDataSyncer.history;

function getHistoryVersionsToShow(searchInput: string, history: ProjectHistory) {
    let allVersions = [...history.versions].reverse();

    if (searchInput) {
        const keys: (keyof ProjectVersion)[] = ['id', 'textDescription'];
        const objs = allVersions.map(v => {return {
            id: v.id.toString(),
            textDescription: v.textDescription,
            _originalObj: v,
        }});
        const filteredResults = fuzzysort.go(searchInput, objs, { keys });
        allVersions = filteredResults.map(r => r.obj._originalObj);
    }
    return allVersions;
}

let searchInput = "";
$: historyVersionsToShow = getHistoryVersionsToShow(searchInput, $history);

function createListItem(x: ProjectVersion) {
    let listItemMenuStructure = new ContextMenuStructure([
        new Action('Load version', async () => {
            verDataSyncer.loadVersion(x.id);
        }),
        new Action('Edit description', () => {
            state = new EditVersionState(x);
        }, 'double click'),
    ])
    const chips:string[] = [dayjs(x.date).fromNow()];
    if(x.additionalContext?.versionPerIdentifier){
        for (const [identifier, version] of x.additionalContext.versionPerIdentifier) {
            chips.unshift(identifier.toUpperCase()[0] + '. v' + version);
        }
    }
    return new ListItem(
        x.textDescription ? (x.id + ' - ' + x.textDescription) : (x.id + ''),
        undefined,
        undefined,
        listItemMenuStructure,
        undefined,
        undefined,
        new ChipsInput(chips),
        () => state = new EditVersionState(x)
    );
}

function createListInput() {
    if (!historyVersionsToShow) {
        return new ListInput(new List([]));
    }
    return new ListInput(
        new List(historyVersionsToShow.map(createListItem),
        new ListOptions({
            itemsSeparated: true,
            virtualListHeight: listContainerHeight ? listContainerHeight - 10 : 534
        })
    ));
}
$: listInput = createListInput();

$: if (historyVersionsToShow) {
    listInput = createListInput();
}

let interval: NodeJS.Timeout;
onMount(() => {
    interval = setInterval(() => {
        listInput = createListInput();
    }, 10e3);
})
onDestroy(() => {
    clearInterval(interval);
})


class NewVersionState {}
class EditVersionState {
    constructor(public val: ProjectVersion) {}
}
class DefaultState {}

let state = new DefaultState();

function cancel() {
    state = new DefaultState();
}
let listContainerHeight: number;

</script>


<EngineUiPanel>
<div class="verdata-container">

    {#if state instanceof EditVersionState}
        <VerDataEdit cancel={cancel} versionDescription={state.val} />
    {:else if state instanceof NewVersionState}
        <VerDataNew cancel={cancel} />
    {/if}


    <div
        class="default-mode-container"
        class:display-none={!(state instanceof DefaultState)}
    >
        <div class="header" >
            <TextField bind:value={searchInput} labelText="search" leadingIcon="Search"/>
            <ButtonComponent desc={new Button(
                'Save version',
                ButtonType.Text,
                () => state = new NewVersionState(),
                false,
                'Save'
            )}/>
        </div>

        <div class="list-container" bind:offsetHeight={listContainerHeight}>
            <ListComponent input={listInput} />
        </div>
    </div>

</div>
</EngineUiPanel>

<style lang="scss">
    .verdata-container {
        max-height: inherit;
        height: 100%;
        .default-mode-container {
            max-height: inherit;
            display: flex;
            height: 100%;
            flex-direction: column;
            &.display-none {
                display: none;
            }
            .header {
                margin-top: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .list-container {
                flex: 1;
                overflow-y: auto;
            }
        }
    }
</style>
