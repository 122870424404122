<script lang="ts">
    import type { PUI_PropertyNodeBool } from "ui-bindings";
    import { CheckboxStandard } from "../libui/checkbox";
    import PropertyHelp from "./PropertyHelp.svelte";

    export let property: PUI_PropertyNodeBool;
</script>

<div class="ui-config-property property-row bool-prop">
    <div class="property-name" />
    <div class="checkbox-holder">
        <CheckboxStandard
            value={property.value}
            readonly={property.readonly}
            onChange={(newVal) => {
                property.value = newVal;
            }}
        />
        <div
            title={property.hint}
            class="
                property-name-label mdc-typography--body1
            "
            class:text-main-dark={!property.readonly}
            class:text-main-medium={property.readonly}
        >
            {property.name}
        </div>

        {#if !!property.description}
            <div class="help-wrapper">
                <PropertyHelp
                    content={property.description}
                    name={property.name}
                />
            </div>
        {/if}
    </div>
</div>

<style lang="scss">
    .property-row {
        .checkbox-holder {
            display: flex;
            flex: 4;
            gap: 4px;
            align-items: center;
        }
        .help-wrapper {
            margin-left: auto;
        }
    }
</style>
