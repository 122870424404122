import { KrMath, Matrix4, Vector3, Vector4 } from 'math-ts';
import { ObjectUtils } from './ObjectUtils';

export type RGBAHex = number & typeof RGBA.new; // typeguard, to differ from normal numbers

// store RGBAHex components in single integer, 31 bits
// 8 bits per color, 7 bits for transparency, alpha = 1.0 - transparency
// 0xFF_FF_FF_FF_7F = R G B T(transparency)

export class RGBA {

    static parseFromHexString(hex: string): RGBAHex {
        if (hex.startsWith("#") && hex.length == 7 || hex.length == 9) {
            const r = parseInt( hex.charAt( 1 ) + hex.charAt( 2 ), 16 ) / 255;
            const g = parseInt( hex.charAt( 3 ) + hex.charAt( 4 ), 16 ) / 255;
            const b = parseInt( hex.charAt( 5 ) + hex.charAt( 6 ), 16 ) / 255;
            const a = hex.length == 9 ? parseInt( hex.charAt( 7 ) + hex.charAt( 8 ), 16 ) / 255 : 1.0;
            return RGBA.new(r, g, b, a);
        } else {
            console.error('only strings starting from # are implemented', hex);
            return 0 as RGBAHex;
        }
    }
    static toHexRgbString(rgba: RGBAHex): string {
        const hexRGB = rgba & 0xFFFFFF;
		return '#' + ( '000000' + hexRGB.toString( 16 ) ).slice( - 6 );
    }

    static newRGB(r: number, g: number, b: number): RGBAHex {
        r = KrMath.clamp(r, 0, 1);
        g = KrMath.clamp(g, 0, 1);
        b = KrMath.clamp(b, 0, 1);

        return ((( r * 255 ) << 16 ^ ( g * 255 ) << 8 ^ ( b * 255 ) << 0) | 0) as RGBAHex;
    }
    static new(r: number, g: number, b: number, a: number): RGBAHex {
        r = KrMath.clamp(r, 0, 1);
        g = KrMath.clamp(g, 0, 1);
        b = KrMath.clamp(b, 0, 1);
        a = KrMath.clamp(a, 0, 1);

        const transparency = 1.0 - a;
        
        const c = ((transparency * 127) << 24 ^ Math.round( r * 255 ) << 16 ^ Math.round( g * 255 ) << 8 ^ Math.round( b * 255 ) << 0);
        return (c | 0) as RGBAHex;
    }
    static withDifferentAlpha(source: RGBAHex, alpha: number): RGBAHex {
        const rgb = source & 0xFFFFFF;
        const transparency = KrMath.clamp(1.0 - alpha, 0, 1);
        return ((transparency * 127) << 24 | rgb) as RGBAHex;
    }

    static isDarkColor(rgba: RGBAHex): boolean {
        const rFrom = ( rgba >> 16 & 255 ) / 255;
        const gFrom = ( rgba >> 8 & 255 ) / 255;
        const bFrom = ( rgba & 255 ) / 255;
        return (rFrom + gFrom + bFrom) < 1.5 && Math.max(rFrom, gFrom, bFrom) < 0.7;
    }

    static multiplyRGB(rgba: RGBAHex, scalar: number): RGBAHex {
        const rFrom = ( rgba >> 16 & 255 ) / 255;
        const gFrom = ( rgba >> 8 & 255 ) / 255;
        const bFrom = ( rgba & 255 ) / 255;
        const aFrom = 1 - ( rgba >> 24 & 127 ) / 127;

        return RGBA.new(
            rFrom,
            gFrom,
            bFrom,
            aFrom,
        )
    }
    
    static lerpColorOnly(from: RGBAHex, to: RGBAHex, t: number) {
        const rFrom = ( from >> 16 & 255 ) / 255;
        const gFrom = ( from >> 8 & 255 ) / 255;
        const bFrom = ( from & 255 ) / 255;
        const aFrom = 1 - ( from >> 24 & 127 ) / 127;
    
        const rTo = ( to >> 16 & 255 ) / 255;
        const gTo = ( to >> 8 & 255 ) / 255;
        const bTo = ( to & 255 ) / 255;
    
        return RGBA.new(
            KrMath.lerp(rFrom, rTo, t),
            KrMath.lerp(gFrom, gTo, t),
            KrMath.lerp(bFrom, bTo, t),
            aFrom,
        )
    }
    
    static lerpRGBAHex(from: RGBAHex, to: RGBAHex, t: number): RGBAHex {
        const rFrom = ( from >> 16 & 255 ) / 255;
        const gFrom = ( from >> 8 & 255 ) / 255;
        const bFrom = ( from & 255 ) / 255;
        const aFrom = 1.0 - ( from >> 24 & 127 ) / 127;
    
        const rTo = ( to >> 16 & 255 ) / 255;
        const gTo = ( to >> 8 & 255 ) / 255;
        const bTo = ( to & 255 ) / 255;
        const aTo = 1.0 - ( to >> 24 & 127 ) / 127;
    
        return RGBA.new(
            KrMath.lerp(rFrom, rTo, t),
            KrMath.lerp(gFrom, gTo, t),
            KrMath.lerp(bFrom, bTo, t),
            KrMath.lerp(aFrom, aTo, t),
        )
    }

    static lerpCustomMidPoint(from: RGBAHex, to: RGBAHex, t: number, midPointColor: RGBAHex, customMidPointT?: number): RGBAHex {
        const midT = customMidPointT ?? 0.5;
        if (midT < 0 || midT > 1) {
            return RGBA.lerpRGBAHex(from, to, t);
        }
        if (t < midT) {
            return RGBA.lerpRGBAHex(from, midPointColor, t / midT);
        }
        if (t > midT) {
            return RGBA.lerpRGBAHex(midPointColor, to, (t - midT) / (1 - midT));
        }
        return midPointColor;
    }

    static colorOnlyFromHex(rgba: RGBAHex): number {
        return rgba & 0xFFFFFF;
    }
    
    static alphaOnlyFromHex(rgba: RGBAHex): number {
        const transparencty = ( rgba >> 24 & 127 ) / 127;
        return 1.0 - transparencty;
    }
    
    static RGBAHexToVec4(rgba: RGBAHex | 0, vec?: Vector4): Vector4 {
    
        const r = ( rgba >> 16 & 255 ) / 255;
        const g = ( rgba >> 8 & 255 ) / 255;
        const b = ( rgba & 255 ) / 255;
        const t = ( rgba >> 24 & 127 ) / 127;
        const a = 1.0 - t;
    
        if (vec) {
            return vec.set(r, g, b, a);
        }
        return new Vector4(r, g, b, a);
    }

    static shadesOf(rgba: RGBAHex, count: number): RGBAHex[] {
        const black = RGBA.lerpColorOnly(rgba, RGBA.new(0, 0, 0, 1), 0.3);
        const white = RGBA.lerpColorOnly(rgba, RGBA.new(1, 1, 1, 1), 0.9);

        const result: RGBAHex[] = [];
        for (let i = 0; i < count; ++i) {
            const middleIndex = (count / 2) | 0;
            if (i === middleIndex) {
                result.push(rgba);
            } else if (i < middleIndex) {
                const lerpPower = (i / middleIndex);
                result.push(RGBA.lerpColorOnly(black, rgba, lerpPower));
            } else {
                const lerpPower = ((count - i) / middleIndex);
                result.push(RGBA.lerpColorOnly(white, rgba, lerpPower));
            }
        }
        return result;
    }

    static betterlerp(from: RGBAHex, to: RGBAHex, t: number): RGBAHex {
        const vecFrom = new Vector3(( from >> 16 & 255 ) / 255, ( from >> 8 & 255 ) / 255, ( from & 255 ) / 255);
        const aFrom = 1.0 - ( from >> 24 & 127 ) / 127;
    
        const vecTo = new Vector3(( to >> 16 & 255 ) / 255, ( to >> 8 & 255 ) / 255, ( to & 255 ) / 255);
        const aTo = 1.0 - ( to >> 24 & 127 ) / 127;
    
        const axis = Vector3.crossVectors(vecFrom, vecTo);
        const angle = vecFrom.angleTo(vecTo);
        const rotation = new Matrix4().makeRotationAxis(axis, angle * t);
        const maxComp = KrMath.lerp(vecFrom.maxComponent(), vecTo.maxComponent(), t);

        const vecRes = vecFrom.clone().applyMatrix4Rotation(rotation);
        vecRes.multiplyScalar(maxComp / vecRes.maxComponent());

        return RGBA.new(vecRes.x, vecRes.y, vecRes.z, KrMath.lerp(aFrom, aTo, t));
    }

    static betterlerp2(from: RGBAHex, to: RGBAHex, t: number): RGBAHex {

        const labFrom = RGBA.rgb2lab(RGBA.toRgbArray(from));
        const labTo = RGBA.rgb2lab(RGBA.toRgbArray(to));

        const lab = [
            KrMath.lerp(labFrom[0], labTo[0], t),
            KrMath.lerp(labFrom[1], labTo[1], t),
            KrMath.lerp(labFrom[2], labTo[2], t),
        ] as [number, number, number];

        const rgb = RGBA.lab2rgb(lab);

        const alpha = KrMath.lerp(RGBA.alphaOnlyFromHex(from), RGBA.alphaOnlyFromHex(to), t);
        
        return RGBA.new(rgb[0] / 255, rgb[1] / 255, rgb[2] / 255, alpha);
    }

    static lab2rgb(lab: [number, number, number]){
        let y = (lab[0] + 16) / 116,
            x = lab[1] / 500 + y,
            z = y - lab[2] / 200,
            r, g, b;
        
        x = 0.95047 * ((x * x * x > 0.008856) ? x * x * x : (x - 16/116) / 7.787);
        y = 1.00000 * ((y * y * y > 0.008856) ? y * y * y : (y - 16/116) / 7.787);
        z = 1.08883 * ((z * z * z > 0.008856) ? z * z * z : (z - 16/116) / 7.787);
        
        r = x *  3.2406 + y * -1.5372 + z * -0.4986;
        g = x * -0.9689 + y *  1.8758 + z *  0.0415;
        b = x *  0.0557 + y * -0.2040 + z *  1.0570;
        
        r = (r > 0.0031308) ? (1.055 * Math.pow(r, 1/2.4) - 0.055) : 12.92 * r;
        g = (g > 0.0031308) ? (1.055 * Math.pow(g, 1/2.4) - 0.055) : 12.92 * g;
        b = (b > 0.0031308) ? (1.055 * Math.pow(b, 1/2.4) - 0.055) : 12.92 * b;
        
        return [Math.max(0, Math.min(1, r)) * 255, 
                Math.max(0, Math.min(1, g)) * 255, 
                Math.max(0, Math.min(1, b)) * 255
        ];
    }
      
    static toRgbArray(color: RGBAHex): [number, number, number] {
        const r = ( color >> 16 & 255 );
        const g = ( color >> 8 & 255 );
        const b = ( color & 255 );
        return [r, g, b];
    }
    
    static rgb2lab(rgb: [number, number, number]){
        let r = rgb[0] / 255,
            g = rgb[1] / 255,
            b = rgb[2] / 255,
            x, y, z;
        
        r = (r > 0.04045) ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
        g = (g > 0.04045) ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
        b = (b > 0.04045) ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
        
        x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
        y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
        z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
        
        x = (x > 0.008856) ? Math.pow(x, 1/3) : (7.787 * x) + 16/116;
        y = (y > 0.008856) ? Math.pow(y, 1/3) : (7.787 * y) + 16/116;
        z = (z > 0.008856) ? Math.pow(z, 1/3) : (7.787 * z) + 16/116;
        
        return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)]
    }

    static lerpFromArray(
        colors: RGBAHex[], 
        t: number, 
        lerpMethod: (from: RGBAHex, to: RGBAHex, t: number) => RGBAHex
    ): RGBAHex {
        const colorMinIndex = Math.floor(t);
        const color = lerpMethod(
            colors[colorMinIndex], 
            colors[colorMinIndex + 1], 
            t - colorMinIndex
        );
        return color;
    }
}
(globalThis as any).RGBA = RGBA;

const errorColor = RGBA.new(1, 0, 1, 1);
export class RgbaPalette {

    _counter: number = 0;

    constructor(
        readonly colors: RGBAHex[]
    ) {
        if (colors.length == 0) {
            console.error(`rgba palette is empty`);
            colors.push(errorColor);
        }
        this._counter = (colors.length / 2) | 0;
    }

    getNext(): RGBAHex {
        const index = (this._counter += 1) % this.colors.length;
        return this.colors[index];
    }

    get(index: number): RGBAHex {
        const i = index % this.colors.length;
        return this.colors[i];
    }
}

export const DefaultRgbaPalette = ObjectUtils.deepFreeze([
    RGBA.newRGB(0.9, 0.0, 0.0),
    RGBA.newRGB(0.8, 0.8, 0.0),
    RGBA.newRGB(0.0, 0.85, 0.0),
    RGBA.newRGB(0.0, 0.8, 0.8),
    RGBA.newRGB(0.0, 0.1, 0.95),
    RGBA.newRGB(0.85, 0.0, 0.85),
]);
