import type { PropertyGroup } from "../properties/PropertyGroup";
import { CatalogSource } from "./Source";

export class CatalogItem<T extends PropertyGroup = PropertyGroup> {
    constructor(
        public name: string = '',
        public typeIdentifier: string = '',
        public properties: T = {} as T,
        public source: CatalogSource = new CatalogSource(),
        public createdAt: Date = new Date(),
        public updatedAt: Date = new Date(),
    ) {}
    as<B extends PropertyGroup = PropertyGroup>(): CatalogItem<B> {
        return (this as unknown) as CatalogItem<B>;
    }
}

