// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class TerrainTile implements flatbuffers.IUnpackableObject<TerrainTileT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):TerrainTile {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsTerrainTile(bb:flatbuffers.ByteBuffer, obj?:TerrainTile):TerrainTile {
  return (obj || new TerrainTile()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsTerrainTile(bb:flatbuffers.ByteBuffer, obj?:TerrainTile):TerrainTile {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new TerrainTile()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

initialGeoId():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

updatedGeoId():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

static startTerrainTile(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addInitialGeoId(builder:flatbuffers.Builder, initialGeoId:number) {
  builder.addFieldInt32(0, initialGeoId, 0);
}

static addUpdatedGeoId(builder:flatbuffers.Builder, updatedGeoId:number) {
  builder.addFieldInt32(1, updatedGeoId, 0);
}

static endTerrainTile(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createTerrainTile(builder:flatbuffers.Builder, initialGeoId:number, updatedGeoId:number):flatbuffers.Offset {
  TerrainTile.startTerrainTile(builder);
  TerrainTile.addInitialGeoId(builder, initialGeoId);
  TerrainTile.addUpdatedGeoId(builder, updatedGeoId);
  return TerrainTile.endTerrainTile(builder);
}

unpack(): TerrainTileT {
  return new TerrainTileT(
    this.initialGeoId(),
    this.updatedGeoId()
  );
}


unpackTo(_o: TerrainTileT): void {
  _o.initialGeoId = this.initialGeoId();
  _o.updatedGeoId = this.updatedGeoId();
}
}

export class TerrainTileT implements flatbuffers.IGeneratedObject {
constructor(
  public initialGeoId: number = 0,
  public updatedGeoId: number = 0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return TerrainTile.createTerrainTile(builder,
    this.initialGeoId,
    this.updatedGeoId
  );
}
}
