<script lang="ts">
    import { StringUtils } from "engine-utils-ts";
    import { IconComponent } from "src/libui/icon";
    import { getStatusLazy } from "./CalculateBarChart";
    import { getContext } from "svelte";
    import { Bim } from "bim-ts";
    import { VersionedStore } from "../../VersionedStore";
    import { RuntimeSystemExecutionStatus } from "ui-bindings";

    export let name: string;
    export let showBar: boolean = true;
    export let energyLength: number | undefined;
    export let energyLabel: string | undefined;
    export let lossLength: number | undefined;
    export let lossLabel: string | undefined;

    $: readableName = StringUtils.capitalizeFirstLatterInWord(name);

    const bim = getContext("bim") as Bim;
    const calcStatusLazy = getStatusLazy(name, bim);
    const calcStatus =
        calcStatusLazy &&
        new VersionedStore<RuntimeSystemExecutionStatus>(calcStatusLazy);

    $: isUpdating = $calcStatus === RuntimeSystemExecutionStatus.InProgress;
</script>

<div class="bar-row">
    <div class="bar-name">
        {#if name}
            <slot name="toggle" />
        {/if}
        <span>{readableName}</span>
    </div>
    <div class="bar-data">
        <div class="chart" class:hide-bar={!showBar || isUpdating}>
            {#if isUpdating}
                <div
                    class="status mdc-typography--body2-strong text-main-light"
                >
                    <IconComponent name="Shading" />
                    <span>Updating...</span>
                </div>
            {/if}
            <div class="bar-group">
                <div
                    class="block energy-block"
                    style="width: {energyLength}%;"
                    title="Energy"
                />
                {#if lossLength && lossLabel}
                    <div
                        class="block"
                        class:bg-good={lossLabel[0] === "+"}
                        class:bg-warning={lossLabel[0] === "-"}
                        style="width: {lossLength}%;"
                        title="Loss"
                    />
                {/if}
                <span class="energy-value">{energyLabel ?? ""}</span>
            </div>
            <div class="info-container">
                <div
                    class="loss-value"
                    class:text-main-light={!Number.parseFloat(lossLabel ?? "")}
                >
                    {@html lossLabel ?? ""}
                </div>
                <slot name="info" />
            </div>
        </div>
        <slot name="warnings" />
    </div>
</div>

<style lang="scss">
    .bar-row {
        padding: 0 16px;
        display: flex;
        gap: 4px;
        margin-bottom: 4px;
    }
    .bar-name {
        font-weight: 600;
        flex: 3;
        overflow: hidden;
        white-space: nowrap;
        line-height: 26px;
    }
    .bar-data {
        flex: 4;
    }
    .chart {
        display: flex;
        gap: 2px;
        align-items: center;
        &.hide-bar {
            .bar-group,
            .loss-value {
                display: none;
            }
        }
    }
    .bar-group {
        flex: 1;
        position: relative;
        display: flex;
        gap: 1px;
    }
    .block {
        height: 26px;
        box-sizing: border-box;
    }
    .energy-block {
        background-color: rgba(77, 175, 0, 0.48);
        min-width: 1px;
    }
    .energy-value {
        line-height: 26px;
        position: absolute;
        left: 8px;
        pointer-events: none;
        white-space: nowrap;
    }
    .loss-value {
        width: 56px;
        flex-shrink: 0;
        text-align: end;
        position: relative;
    }
    .info-container {
        display: flex;
        gap: 8px;
        max-width: 82px;
        justify-content: end;
        box-sizing: border-box;
    }
    .status {
        display: flex;
        gap: 4px;
        margin: 5px 8px;
    }
</style>
