import type { Builder } from 'flatbuffers';
import { CompressionType } from './wire/compression-type';
import { compressIntegers, decompressIntegers } from './WireCommon';
import { CompressedIntegers } from './CollectionHistory_generated';
import { WorkerClassPassRegistry, JobExecutor, type Yield, registerExecutor, ExecutionThreadPreference } from 'engine-utils-ts';
import type { Id } from '.';



export class CompressedIntegersInMemory {

    constructor(
        readonly compressionType: CompressionType,
        readonly buffer: ArrayBuffer
    ) {
        if (this.compressionType !== 0 && buffer.byteLength === 0) {
            this.compressionType = 0;
            console.error('invalid compressed integers compression for 0 array', this.compressionType);
        }
        Object.freeze(this);
    }

    static empty() {
        return new CompressedIntegersInMemory(CompressionType.None, new ArrayBuffer(0));
    }

    static fromFlatbuf(ci: CompressedIntegers) {
        let compression = ci.compression();
        const buffer = ci.payloadArray()?.slice();
        if (compression !== 0 && (buffer == null || buffer.length == 0)) {
            console.warn('invalid compressed integers saved, compressed nothing');
            compression = CompressionType.None;
        }
        return new CompressedIntegersInMemory(
            compression,
            buffer?.buffer ?? new ArrayBuffer(0),
        );
    }

    static fromDecompressed(ints: number[] | Uint32Array) {
        const compressed = compressIntegers(ints);
        return new CompressedIntegersInMemory(compressed.compressionType, compressed.buffer);
    }

    createFlatbuf(builder: Builder) {
        return CompressedIntegers.createCompressedIntegers(
            builder,
            this.compressionType,
            CompressedIntegers.createPayloadVector(builder, new Uint8Array(this.buffer))
        );
    }

    decompressed(): number[] {
        const cached = decompressedIntegersCache.get(this);
        let value: number[]|undefined = undefined;
        if (cached) {
            value = cached.deref();
        }
        if (!value) {
            value = decompressIntegers(this.compressionType, new Uint8Array(this.buffer));
            decompressedIntegersCache.set(this, new WeakRef(value));
        }
        return value;
    }
}
WorkerClassPassRegistry.registerClass(CompressedIntegersInMemory);

const decompressedIntegersCache = new WeakMap<CompressedIntegersInMemory, WeakRef<number[]>>();
(globalThis as any)['decompressedIntegersCache'] = decompressedIntegersCache;

export interface MaxVersionsPerIdJobExecutorArgs {
    idsVersions: {compressedIds: CompressedIntegersInMemory, versions: CompressedIntegersInMemory}[];
}

export class MaxVersionsPerIdJobExecutor extends JobExecutor<MaxVersionsPerIdJobExecutorArgs, Map<Id, number>> {

    execute(args: MaxVersionsPerIdJobExecutorArgs): Map<number, number> | Generator<Yield, Map<number, number>, unknown> {
        const versionPerId = new Map<number, number>();
        for (const bd of args.idsVersions) {
            const ids = bd.compressedIds.decompressed();
            const versions = bd.versions.decompressed();

            for (let i = 0; i < ids.length; ++i) {
                const id = ids[i];
                const version = versions[i];
                let versionPrev = versionPerId.get(id) ?? 0;
                versionPerId.set(id, Math.max(versionPrev, version));
            }
        }
        return versionPerId;
    }

    estimateTaskDurationMs(args: MaxVersionsPerIdJobExecutorArgs): number {
        return args.idsVersions.length * 5 || 1;
    }

    executionPreference(args: MaxVersionsPerIdJobExecutorArgs): ExecutionThreadPreference {
        return args.idsVersions.length > 10
            ? ExecutionThreadPreference.WorkerThread
            : ExecutionThreadPreference.MainThread;
    }
}
registerExecutor(MaxVersionsPerIdJobExecutor);
