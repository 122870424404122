// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

export class FlatBuffersSolarMountingStructure {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):FlatBuffersSolarMountingStructure {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsFlatBuffersSolarMountingStructure(bb:flatbuffers.ByteBuffer, obj?:FlatBuffersSolarMountingStructure):FlatBuffersSolarMountingStructure {
  return (obj || new FlatBuffersSolarMountingStructure()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsFlatBuffersSolarMountingStructure(bb:flatbuffers.ByteBuffer, obj?:FlatBuffersSolarMountingStructure):FlatBuffersSolarMountingStructure {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new FlatBuffersSolarMountingStructure()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

name():string|null
name(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
name(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

origin(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readFloat64(this.bb!.__vector(this.bb_pos + offset) + index * 8) : 0;
}

originLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

originArray():Float64Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? new Float64Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

width():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

length():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

manufacturer():string|null
manufacturer(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
manufacturer(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

transformerId():string|null
transformerId(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
transformerId(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

rotation():number {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

numberOfModulesPerString():number {
  const offset = this.bb!.__offset(this.bb_pos, 18);
  return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
}

numberOfModulesPerRow():number {
  const offset = this.bb!.__offset(this.bb_pos, 20);
  return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
}

moduleModel():string|null
moduleModel(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
moduleModel(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 22);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

modulePower():string|null
modulePower(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
modulePower(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 24);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startFlatBuffersSolarMountingStructure(builder:flatbuffers.Builder) {
  builder.startObject(11);
}

static addName(builder:flatbuffers.Builder, nameOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, nameOffset, 0);
}

static addOrigin(builder:flatbuffers.Builder, originOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, originOffset, 0);
}

static createOriginVector(builder:flatbuffers.Builder, data:number[]|Float64Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createOriginVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createOriginVector(builder:flatbuffers.Builder, data:number[]|Float64Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(8, data.length, 8);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addFloat64(data[i]!);
  }
  return builder.endVector();
}

static startOriginVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(8, numElems, 8);
}

static addWidth(builder:flatbuffers.Builder, width:number) {
  builder.addFieldFloat64(2, width, 0.0);
}

static addLength(builder:flatbuffers.Builder, length:number) {
  builder.addFieldFloat64(3, length, 0.0);
}

static addManufacturer(builder:flatbuffers.Builder, manufacturerOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, manufacturerOffset, 0);
}

static addTransformerId(builder:flatbuffers.Builder, transformerIdOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, transformerIdOffset, 0);
}

static addRotation(builder:flatbuffers.Builder, rotation:number) {
  builder.addFieldFloat64(6, rotation, 0.0);
}

static addNumberOfModulesPerString(builder:flatbuffers.Builder, numberOfModulesPerString:number) {
  builder.addFieldInt32(7, numberOfModulesPerString, 0);
}

static addNumberOfModulesPerRow(builder:flatbuffers.Builder, numberOfModulesPerRow:number) {
  builder.addFieldInt32(8, numberOfModulesPerRow, 0);
}

static addModuleModel(builder:flatbuffers.Builder, moduleModelOffset:flatbuffers.Offset) {
  builder.addFieldOffset(9, moduleModelOffset, 0);
}

static addModulePower(builder:flatbuffers.Builder, modulePowerOffset:flatbuffers.Offset) {
  builder.addFieldOffset(10, modulePowerOffset, 0);
}

static endFlatBuffersSolarMountingStructure(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createFlatBuffersSolarMountingStructure(builder:flatbuffers.Builder, nameOffset:flatbuffers.Offset, originOffset:flatbuffers.Offset, width:number, length:number, manufacturerOffset:flatbuffers.Offset, transformerIdOffset:flatbuffers.Offset, rotation:number, numberOfModulesPerString:number, numberOfModulesPerRow:number, moduleModelOffset:flatbuffers.Offset, modulePowerOffset:flatbuffers.Offset):flatbuffers.Offset {
  FlatBuffersSolarMountingStructure.startFlatBuffersSolarMountingStructure(builder);
  FlatBuffersSolarMountingStructure.addName(builder, nameOffset);
  FlatBuffersSolarMountingStructure.addOrigin(builder, originOffset);
  FlatBuffersSolarMountingStructure.addWidth(builder, width);
  FlatBuffersSolarMountingStructure.addLength(builder, length);
  FlatBuffersSolarMountingStructure.addManufacturer(builder, manufacturerOffset);
  FlatBuffersSolarMountingStructure.addTransformerId(builder, transformerIdOffset);
  FlatBuffersSolarMountingStructure.addRotation(builder, rotation);
  FlatBuffersSolarMountingStructure.addNumberOfModulesPerString(builder, numberOfModulesPerString);
  FlatBuffersSolarMountingStructure.addNumberOfModulesPerRow(builder, numberOfModulesPerRow);
  FlatBuffersSolarMountingStructure.addModuleModel(builder, moduleModelOffset);
  FlatBuffersSolarMountingStructure.addModulePower(builder, modulePowerOffset);
  return FlatBuffersSolarMountingStructure.endFlatBuffersSolarMountingStructure(builder);
}
}
