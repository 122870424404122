import type { Bim, EnergyPipelineStage, EnergyStageChart, EnergyStageName } from "bim-ts";
import { Failure, Success } from 'engine-utils-ts';

export interface BarData {
    energyLength: number;
    energyLabel: string;
    lossLength: number;
    lossLabel: string;
}

export function calculateBars(stages: EnergyPipelineStage[]) {
    const bars = new Map<EnergyStageName, BarData>();

    const energies = stages.map((stage) => stage?.energyTotal("MWh"));
    const losses = energies.map((energy, index) => {
        if (energy instanceof Failure) {
            return 0;
        }
        for (let j = index - 1; j >= 0; j--) {
            const prevEnergy = energies[j];
            if (prevEnergy instanceof Success) {
                return energy.value - prevEnergy.value;
            }
        }
        return 0;
    });

    const maxEnergy = energies.reduce((max, energy, index) => {
        if (energy instanceof Failure) {
            return max;
        }
        const loss = losses[index];
        const sum = loss < 0 ? energy.value - loss : energy.value;
        return Math.max(max, sum);
    }, 0);
    
    stages.forEach((stage, index) => {
        const energy = energies[index];
        const loss = losses[index];
        const total = maxEnergy; //energy + Math.abs(loss);

        let energyLength: number;
        let lossLength: number;
        let energyLabel: string;
        let lossLabel: string;
        
        if (energy instanceof Success) {
            const e = energy.value
            const lossPerc = (loss / (e - loss)) * 100 || 0;
            energyLength = ((loss > 0 ? e - loss : e) / total) * 100;
            lossLength = (Math.abs(loss) / total) * 100;


            const energyStageChartArea = (stage.energy as Success<EnergyStageChart>).value.combinedArea();

            if (energyStageChartArea > 0) {
                const perM2 = e / energyStageChartArea;
                energyLabel = `${perM2.toFixed(3)} MWh/m2`;
            } else {
                energyLabel = `${e.toFixed(2)} MWh`;
            }


            const lossValue = `${lossPerc > 0 ? "+" : ""}${lossPerc.toFixed(2)}`;
            const roundedLossValue = Number(lossValue);
            lossLabel = `${Math.abs(roundedLossValue) === 100 ? roundedLossValue.toString() : lossValue}%`;
        } else {
            energyLength = 0;
            lossLength = 0;
            energyLabel = "N/A";
            lossLabel = "&mdash;";
        }

        if (!index) {
            lossLabel = "";
        }

        bars.set(stage.name, {
            energyLength,
            lossLength,
            energyLabel,
            lossLabel,
        });
    });
    return bars;
}

export function getStatusLazy(name: string, bim: Bim) {
    switch (name) {
        case "shading":
            return bim.customRuntimes.getLazyExecutionStatusOf(
                "shading-runtime",
            )
        default:
            return undefined;
    }
}
