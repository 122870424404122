import type { ObjectUniqueHashValue as ObjectHash } from '.';
import { IterUtils } from '.';
import { ObjectUtils } from './ObjectUtils';
import { LegacyLogger } from './';

class ObjectEntry<V> {
    readonly hash: ObjectHash;
    readonly obj: V;

    constructor(hash: ObjectHash, obj: V) {
        this.hash = hash;
        this.obj = obj;
        Object.freeze(this);
    }
}


/**
 * @example
 * Set operations:
 * ```
 * interface Test {
 *     key: string
 * }
 * const set = new ObjectsSet_Draft<Test>();
 * const o1: Test = { key: '1' };
 *
 * // add element
 * set.add(o1);
 *
 * // check size
 * console.log(set.size) // 1
 * const o2: Test = { key: '2' };
 * // add another element
 * set.add(o2);
 * // check size
 * console.log(set.size) // 2
 * const o1copy: Test = { key: '1' };
 * // check if element exist
 * console.log(set.has(o1copy)); // true
 *
 * // transform to array
 * const arr = Array.from(set);
 * console.log(arr); // [{ key: 'test1' }, { key: 'test2' }]
 *
 * // iterate in for-of-loop
 * for (const o of set) {
 *     console.log(o)
 * }
 *
 * // remove element
 * set.delete(o1);
 *
 * // element should be removed
 * console.log(set.has(o1)) // false
 * console.log(set.size) // 1
 *
 * // clear
 * set.clear();
 * console.log([...set]) // []
 * console.log(set.size) // 0
 * ```
 */
export class ObjectsSet<V extends object> {

    _size: number = 0;

    _hashFn: (obj: V) => ObjectHash;
    _eqFn: (lhs: V, rhs: V) => boolean;

    readonly _bucketsPerHash = new Map<ObjectHash, ObjectEntry<V>[]>();

    constructor(
        hashFn: (obj: V) => ObjectHash,
        eqFunction?: (lhs: V, rhs: V) => boolean,
    ) {
        this._hashFn = hashFn;
        this._eqFn = eqFunction ?? ObjectUtils.areObjectsEqual;
    }

    /**
     * @returns
     * Bool value that indicates if insertion to the set was successful.
     *
     * @remarks
     * If element existed before, returns `false`.
     */
    add(value: V): boolean {
        if (!Object.isFrozen(value)) {
            LegacyLogger.deferredError('object set value should be frozen', value);
        }
        const existing = this._getEntry(value);
        if (existing) {
            return false;
        }
        const hash = this._hashFn(value);
        const newEntry = new ObjectEntry(hash, value);
        const bucket = this._bucketsPerHash.get(hash);
        if (bucket === undefined) {
            this._bucketsPerHash.set(hash, [newEntry]);
        } else {
            bucket.push(newEntry);
        }
        this._size += 1;
        return true;
    }

    _getEntry(value: V): ObjectEntry<V> | null {
        const hash = this._hashFn(value);
        const bucket = this._bucketsPerHash.get(hash);
        if (bucket === undefined) {
            return null;
        }
        for (const entry of bucket) {
            if (this._eqFn(entry.obj, value)) {
                return entry;
            }
        }
        return null;
    }


    /**
     * @returns
     * Bool that indicates if deletion was successful.
     *
     * @remarks
     * If object is not present, returns `false`.
     */
    delete(value: V): boolean {
        const existing = this._getEntry(value);
        if (!existing) {
            return false;
        }
        const bucket = this._bucketsPerHash.get(existing.hash)!;
        IterUtils.deleteFromArray(bucket, existing);
        this._size -= 1;
        if (bucket.length === 0) {
            this._bucketsPerHash.delete(existing.hash);
        }
        return true;
    }

    clear() {
        this._size = 0;
        this._bucketsPerHash.clear();
    }

    get size(): number {
        return this._size;
    }

    has(value: V): boolean {
        const existing = this._getEntry(value)
        return existing !== null;
    }

    /**
     * @example
     * Iterate with for-of
     * ```
     * for (const x of set) {
     *   console.log(x);
     * }
     * ```
     *
     * @example
     * Transform into array
     * ```
     * const arr = [...set];
     * ```
     */
    *[Symbol.iterator]() {
        for (const bucket of this._bucketsPerHash.values()) {
            for (const entry of bucket) {
                yield entry.obj;
            }
        }
    }
}

