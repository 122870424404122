import type { Disposable } from '../index';
import type { EventStackFrame } from './EventsStackFrame';


export interface ObserverSettings {
	doNotNotifyCurrentState?: boolean,
	immediateMode?: boolean;
}

// useless interface, consider removing
export interface IObservableObject<T> {
	identifier: string;
	currentValue(): T;
	observeObject(args: {
		onPatch: (args: { patch: Partial<T>, event: EventStackFrame }) => void,
		settings?: Partial<ObserverSettings>,
	}): Observer;
	applyPatch(args: { patch: Partial<T>, event?: Partial<EventStackFrame>}): Promise<void>;
}

export interface ObservableEntities<TEntity, ID> {
	readAll(): [ID, TEntity][]; // get all currently loaded entities states
	// applyPatchTo(args: { patch: Partial<TEntity>, ids: ID[], event?: Partial<EventStackFrame>}): void;
	// applyPatches(args: { perId: [ID, Partial<TEntity>][], event?: Partial<EventStackFrame> }): void;
	// observeEntities(args: {
	// 	onAlloc: 	(args: { allocPerId: [ID, TEntity][] , 			event: EventStackFrame }) => void,
	// 	onPatch: 	(args: { patchPerId: [ID, Partial<TEntity>][], 	event: EventStackFrame }) => void,
	// 	onRemoval: 	(args: { ids: ID[], 						    event: EventStackFrame }) => void,
	// 	settings?: Partial<ObserverSettings>,
	// }): Observer;
}

let _unique_ids = 0;

export function getUniqueEntityIdentifier(entityName: string, entityIdentifier: string) {
	_unique_ids += 1;
	return `${entityName}-${entityIdentifier}-${_unique_ids}:id-${performance.now()}:ms-${(Math.random() * 1000) | 0}:r`;
}

export interface Observer extends Disposable {
	readonly uniqueIdent: string;
	readonly isImmediateMode: boolean;
	unsubscribe(): void;
}

//Svelte Store contract

export interface SvelteStore<T> {

	// A store must contain a `.subscribe` method, which must accept as its argument a subscription function.
	// This subscription function must be immediately and synchronously called with the store's current value upon calling `.subscribe`
	// All of a store's active subscription functions must later be synchronously called whenever the store's value changes.
	subscribe(callback: (value: T) => void): { unsubscribe: () => void } | (() => void);

	// A store may *optionally* contain a `.set` method, which must accept as its argument a new value for the store,
	// and which SYNCRHONOUSLY calls all of the store's active subscription functions. Such a store is called a *writable store*
	set?(value: T): void;
}

