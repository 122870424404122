import { Vector4 } from 'math-ts';
import type { ShaderBase } from "../../../shaders/ShaderBase";
import { ShaderFlags } from "../../../shaders/ShaderFlags";


export const TextShader : ShaderBase = {
    name: 'text-shader',
	flags: ShaderFlags.None,
    uniforms: {
        u_pxRange: { value: 4 },
        u_useRGSS: { value: true },

        color_tint_mix: { value: new Vector4(0, 0, 0, 1) },
        outline_tint_mix: { value: new Vector4(1, 1, 1, 1) },
        alpha_multiplier: { value: 1 },

    },
    params: {
        transparent: true
    },

vertexShader: 
`
#include <kr_std_uniforms>
#include <kr_std_attributes>
#include <kr_std_vars>


void main() {

    #include <kr_std_vars_calc>
/*
    vUv = uv;
    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    gl_Position = projectionMatrix * mvPosition;
*/
    gl_Position.z -= 0.0001;
}
`,

fragmentShader:
`

#include <kr_std_uniforms>
#include <kr_std_vars>

uniform sampler2D u_texture;

uniform float u_pxRange;
uniform bool u_useRGSS;

uniform vec4 color_tint_mix;
uniform vec4 outline_tint_mix;
uniform float alpha_multiplier;

// functions from the original msdf repo:
// https://github.com/Chlumsky/msdfgen#using-a-multi-channel-distance-field

float median(float r, float g, float b) {
    return max(min(r, g), min(max(r, g), b));
}

float screenPxRange() {
    vec2 unitRange = vec2(u_pxRange)/vec2(textureSize(u_texture, 0));
    vec2 screenTexSize = vec2(1.0)/fwidth(vUv);
    return max(0.5*dot(unitRange, screenTexSize), 1.0);
}

vec2 tap(vec2 offsetUV) {
    vec3 msd = texture( u_texture, offsetUV ).rgb;
    float sd = median(msd.r, msd.g, msd.b);

    float screenPxDistance = screenPxRange() * (sd - 0.5);

    float glyphBorderPx = screenPxRange() * (sd - 0.5);
    float outlineBorderPx = screenPxRange() * (sd - 0.35);

    float alpha = clamp(glyphBorderPx + 0.5, 0.0, 1.0);
    float border = clamp(outlineBorderPx + 0.5, 0.0, 1.0);

    return vec2(alpha, border);
}

void main() {
	#include <kr_std_pixel>

    vec2 alphaBorder;

    if ( u_useRGSS ) {

        // shader-based supersampling based on https://bgolus.medium.com/sharper-mipmapping-using-shader-based-supersampling-ed7aadb47bec
        // per pixel partial derivatives
        vec2 dx = dFdx(vUv);
        vec2 dy = dFdy(vUv);

        // rotated grid uv offsets
        vec2 uvOffsets = 0.354 * (dFdx(vUv) + dFdy(vUv));

        // supersampled using 2x2 rotated grid
        alphaBorder = vec2(0.0);
        alphaBorder += tap(vUv);
        alphaBorder += tap(vUv + uvOffsets.x * dx + uvOffsets.y * dy);
        alphaBorder += tap(vUv - uvOffsets.x * dx - uvOffsets.y * dy);
        alphaBorder += tap(vUv + uvOffsets.y * dx - uvOffsets.x * dy);
        alphaBorder += tap(vUv - uvOffsets.y * dx + uvOffsets.x * dy);
        alphaBorder *= 0.2;

    } else {

        alphaBorder = tap( vUv );

    }

    float alpha = alphaBorder.x;
    float border = alphaBorder.y;

    vec3 objColor = mix(colorTint.rgb, color_tint_mix.rgb, color_tint_mix.a);
    vec3 outlineColor = mix(colorTint.rgb, outline_tint_mix.rgb, outline_tint_mix.a);

    vec3 color = outlineColor * border;
    color = mix(color, objColor, alpha);

    gl_FragColor = vec4(color.rgb, border * alpha_multiplier);

    // this is useful to avoid z-fighting when quads overlap because of kerning
    // if ( alpha < 0.02) discard;
}
`
}