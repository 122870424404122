import type { RawDataGateway } from '../memory/RawDataGateway';
import { LegacyLogger } from 'engine-utils-ts';
import Utils from '../utils/Utils';
import type { KrFrustum } from './KrFrustum';
import { IntersectionType } from '../geometries/GeometryUtils';
import type { Plane, Vector3, Ray } from 'math-ts';
import { Aabb } from 'math-ts';


// could be saved for every box, and maybe become even faster for single camera setup
// but for multicamera setup making this fast is unnecessary complex
let intersectsLastFailInd = 0;

const BoxFloats = 6;

export class KrBoundsGateway implements RawDataGateway<Aabb, Float64Array> {

	readonly name: string;
	buffer!: Float64Array;
	readonly itemLength: number = BoxFloats;

	constructor(name: string) {
		this.name = name;
	}

	static fromBuffer(buffer: Float64Array): KrBoundsGateway {
		const bg = new KrBoundsGateway('fromBuffer');
		bg.buffer = buffer;
		return bg;
	}


	setInternalBuffer(buffer: Float64Array) {
		this.buffer = buffer;
	}

	static createWithSize(name: string, size: number): KrBoundsGateway {
		return this.createFromRawdata(name, new Float64Array(size * BoxFloats));
	}

	static createFromRawdata(name: string, buffer: Float64Array): KrBoundsGateway {
		const bounds = new KrBoundsGateway(name);
		bounds.setInternalBuffer(buffer);
		return bounds;
	}

	toBuffer(obj: Aabb, index: number) {
		this.buffer.set(obj.elements, index * BoxFloats)
		// Utils.copyArray(BoxFloats, obj.elements, 0, this.buffer, index * BoxFloats);
	}

	toBufferEqualityCheck(obj: Aabb, index: number) {
		const b = this.buffer;
		const offset = index * BoxFloats;
		const objElements = obj.elements;
		if (objElements[0] === b[offset + 0]
			&& objElements[1] === b[offset + 1]
			&& objElements[2] === b[offset + 2]
			&& objElements[3] === b[offset + 3]
			&& objElements[4] === b[offset + 4]
			&& objElements[5] === b[offset + 5]
		) {
			return false;
		}
		b.set(obj.elements, offset)
		return true;
	}

	toStruct(index: number, struct: Aabb) {
		Utils.copyArray(BoxFloats, this.buffer, index * BoxFloats, struct.elements, 0);
		return struct;
	}

	getStruct(index: number): Aabb {
		return this.toStruct(index, Aabb.empty());
	}

	emptyOut(index: number) {
		const buffer = this.buffer;
		const offset = index * BoxFloats;
		buffer[offset + 0] = Infinity;
		buffer[offset + 1] = Infinity;
		buffer[offset + 2] = Infinity;
		buffer[offset + 3] = -Infinity;
		buffer[offset + 4] = -Infinity;
		buffer[offset + 5] = -Infinity;
	}

	isEmpty(index:number): boolean {
		const buffer = this.buffer;
		const offset = index * BoxFloats;
		return (buffer[offset + 3] < buffer[offset + 0])
			|| (buffer[offset + 4] < buffer[offset + 1])
			|| (buffer[offset + 5] < buffer[offset + 2]);
	}

	private distanceToPlane(plane: Plane, offset: number) {
		const boxElements = this.buffer;
		const pn = plane.normal;
		const x =  boxElements[offset + (pn.x > 0 ? 3 : 0)];
		const y  = boxElements[offset + (pn.y > 0 ? 4 : 1)];
		const z  = boxElements[offset + (pn.z > 0 ? 5 : 2)];
		return pn.x * x + pn.y * y + pn.z * z + + plane.constant;
	}

	intersectsFrustum (index:number, frustum: KrFrustum) : boolean {
		const planes = frustum.planes;
		const offset = index * BoxFloats;

		if (this.distanceToPlane(planes[intersectsLastFailInd], offset) < 0) {
			return false;
		}
		for (let i = 0; i < 6; i++) {
			if (i === intersectsLastFailInd) {
				continue; // do not check twice the same plane
			}
			if (this.distanceToPlane(planes[i], offset) < 0) {
				intersectsLastFailInd = i;
				return false;
			}
		}

		return true;
	}

	
	intersectPlanesVolume(index:number, planes: Plane[]): IntersectionType {
		let res = IntersectionType.Full;

		const offset = index * BoxFloats;
		const boxElements = this.buffer;

		for (const plane of planes) {
			const planeNormal = plane.normal;
	
			let dotMin, dotMax : number;
	
			if (planeNormal.x > 0) {
				dotMin = planeNormal.x * boxElements[offset + 0];
				dotMax = planeNormal.x * boxElements[offset + 3];
			} else {
				dotMin = planeNormal.x * boxElements[offset + 3];
				dotMax = planeNormal.x * boxElements[offset + 0];
			}
	
			if (planeNormal.y > 0) {
				dotMin += planeNormal.y * boxElements[offset + 1];
				dotMax += planeNormal.y * boxElements[offset + 4];
			} else {
				dotMin += planeNormal.y * boxElements[offset + 4];
				dotMax += planeNormal.y * boxElements[offset + 1];
			}
	
			if (planeNormal.z > 0) {
				dotMin += planeNormal.z * boxElements[offset + 2];
				dotMax += planeNormal.z * boxElements[offset + 5];
			} else {
				dotMin += planeNormal.z * boxElements[offset + 5];
				dotMax += planeNormal.z * boxElements[offset + 2];
			}
			
			if (dotMax + plane.constant < 0) {
				return IntersectionType.Outside; 
			}
			if (dotMin + plane.constant < 0) {
				res = IntersectionType.Partial;
			}
		}
		return res;
	}
	
	isInsideBox3(index: number, box: Aabb): boolean {
		const buffer = this.buffer;
		const offset = index * BoxFloats;
		const boxElements = box.elements;
		return 	buffer[offset + 0] >= boxElements[0] && buffer[offset + 3] <= boxElements[3] &&
				buffer[offset + 1] >= boxElements[1] && buffer[offset + 4] <= boxElements[4] &&
				buffer[offset + 2] >= boxElements[2] && buffer[offset + 5] <= boxElements[5];
	}

	containsBox(index:number, box: Aabb): boolean {
		const buffer = this.buffer;
		const offset = index * BoxFloats;
		const boxElements = box.elements;
		return 	buffer[offset + 0] <= boxElements[0] && boxElements[3] <= buffer[offset + 3] &&
				buffer[offset + 1] <= boxElements[1] && boxElements[4] <= buffer[offset + 4] &&
				buffer[offset + 2] <= boxElements[2] && boxElements[5] <= buffer[offset + 5];
	}

	intersectsBox3(index:number, box: Aabb): boolean {
		const buffer = this.buffer;
		const offset = index * BoxFloats;
		const boxElements = box.elements;
		return 	buffer[offset + 0] <= boxElements[3] && buffer[offset + 3] >= boxElements[0] &&
				buffer[offset + 1] <= boxElements[4] && buffer[offset + 4] >= boxElements[1] &&
				buffer[offset + 2] <= boxElements[5] && buffer[offset + 5] >= boxElements[2];
	}

	getCenter(index:number, target: Vector3) {
		const buffer = this.buffer;
		const offset = index * BoxFloats;
		target.set(
			(buffer[offset + 0] + buffer[offset + 3]) * 0.5,
			(buffer[offset + 1] + buffer[offset + 4]) * 0.5,
			(buffer[offset + 2] + buffer[offset + 5]) * 0.5
		)
	}

	horArea(index: number): number {
		const buffer = this.buffer;
		const offset = index * BoxFloats;
		return (buffer[offset + 3] - buffer[offset + 0]) * (buffer[offset + 5] - buffer[offset + 2]);
	}

	minY(index: number): number {
		const offset = (index * BoxFloats) | 0;
		return this.buffer[offset + 1];
	}

	minZ(index: number): number {
		const offset = (index * BoxFloats) | 0;
		return this.buffer[offset + 2];
	}

	intersectsRay(index: number, ray: Ray, target:Vector3 | null): boolean {
		const offset = index * BoxFloats;
		if (!(offset < this.buffer.length && offset >= 0)) {
			LegacyLogger.error(this.name + ' krBoundsGateway: index out of bounds', index);
			return false;
		}
		
		let tmin: number;
		let tmax: number; 

		const invdirx = 1 / ray.direction.x;
		const invdiry = 1 / ray.direction.y;
		const invdirz = 1 / ray.direction.z;

		const origin = ray.origin;
		const buffer = this.buffer;

		if ( invdirx >= 0 ) {

			tmin = ( buffer[offset + 0] - origin.x ) * invdirx;
			tmax = ( buffer[offset + 3] - origin.x ) * invdirx;

		} else {

			tmin = ( buffer[offset + 3] - origin.x ) * invdirx;
			tmax = ( buffer[offset + 0] - origin.x ) * invdirx;

		}

		let tymin: number;
		let tymax: number;

		if ( invdiry >= 0 ) {

			tymin = ( buffer[offset + 1] - origin.y ) * invdiry;
			tymax = ( buffer[offset + 4] - origin.y ) * invdiry;

		} else {

			tymin = ( buffer[offset + 4] - origin.y ) * invdiry;
			tymax = ( buffer[offset + 1] - origin.y ) * invdiry;

		}

		if ( ( tmin > tymax ) || ( tymin > tmax ) ) return false;

		// These lines also handle the case where tmin or tmax is NaN
		// (result of 0 * Infinity). x !== x returns true if x is NaN

		if ( tymin > tmin || tmin !== tmin ) tmin = tymin;

		if (tymax < tmax || tmax !== tmax) tmax = tymax;
		
		let tzmin: number;
		let tzmax: number;

		if ( invdirz >= 0 ) {

			tzmin = ( buffer[offset + 2] - origin.z ) * invdirz;
			tzmax = ( buffer[offset + 5] - origin.z ) * invdirz;

		} else {

			tzmin = ( buffer[offset + 5] - origin.z ) * invdirz;
			tzmax = ( buffer[offset + 2] - origin.z ) * invdirz;

		}

		if ( ( tmin > tzmax ) || ( tzmin > tmax ) ) return false;

		if ( tzmin > tmin || tmin !== tmin ) tmin = tzmin;

		if ( tzmax < tmax || tmax !== tmax ) tmax = tzmax;

		//return point closest to the ray (positive side)

		if ( tmax < 0 ) return false;

		if (target) {
			target.copy(ray.direction).multiplyScalar(tmin >= 0 ? tmin : tmax).add(ray.origin);
		}
		return true;
	}
	
}