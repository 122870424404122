<script lang="ts">
    import { LazyBasic, LazyVersioned } from "engine-utils-ts";
    import { VersionedStore } from "../VersionedStore";

    export let onClick: (e: Event) => void;
    export let rootClass: string = "";
    export let visible: LazyVersioned<boolean> = new LazyBasic("visible", true);

    $: showButton = new VersionedStore(visible);
</script>

{#if $showButton}
    <div class="label {rootClass}" on:click={onClick}>
        <slot />
    </div>
{/if}

<style lang="scss">
    .label {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 16px;
        background-color: #fbfdff;
        border-radius: 5px;
        height: 24px;
        text-align: center;
        vertical-align: middle;
        word-wrap: break-word;
        white-space: nowrap;
        text-overflow: clip;
        overflow: hidden;
        cursor: pointer;
        user-select: none;
    }
    .label:hover {
        background-color: #e7e7e7;
    }
</style>
