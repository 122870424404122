<script lang="ts">
    import { LazyBasic } from "engine-utils-ts";
    import NumberPropertyComponent from "../../pui/NumberProperty.svelte";
    import { PUI_PropertyNodeNumber } from "ui-bindings";
    import ColorInput from "../../pui/custom-components/SelectArea/ColorInput.svelte";
    import { ButtonComponent, IconButton } from "../../libui/button";
    import { getContext, onMount } from "svelte";
    import { createGrid } from "ag-grid-enterprise";
    import "ag-grid-enterprise/styles/ag-theme-balham.min.css";
    import {
        getGridOptions,
        GridApiType,
        setTableData,
        updateTableHeader,
    } from "./TableConfig";
    import { UnitsMapper, BinTable, BinsBuilder, PileWeightClass, NumberProperty } from "bim-ts";
    import { VersionedStore } from "../../VersionedStore";
    import PileGridWrapper from "../../pui/custom-components/pile-table/PileGridWrapper.svelte";

    type GridConfigType = ReturnType<typeof getGridOptions>;

    export let name: string = "";
    export let min: NumberProperty | undefined;
    export let total: number;
    export let binTable: BinTable;
    export let binsBuilder: BinsBuilder;
    export let gridOptions: GridConfigType;

    const unitsMapper = getContext<VersionedStore<UnitsMapper>>("unitsMapper");

    const maxRevealTag = new LazyBasic("reveal-tag", "max reveal");

    let maxRevealProp: PUI_PropertyNodeNumber;
    $: {
        if (!binTable.readonly) {
            maxRevealProp = new PUI_PropertyNodeNumber({
                value: binTable.maxReveal.value,
                defaultValue: 0,
                name: "Max reveal",
                step: 0.01,
                tag: maxRevealTag,
                unit: binTable.maxReveal.unit,
                onChange: (newVal) => {
                    binsBuilder.updateBin(binTable, binTable.updateReveal(newVal), true);
                },
            });
        }
    }

    let gridRootRef: HTMLElement;
    let gridApi: GridApiType | undefined;

    $: setTableData({
        gridApi,
        binTable,
        binsBuilder,
    });

    $: count = binTable.total;
    $: hideTable = !count && binTable.readonly;

    $: updateTableHeader(gridApi, {
        items: count,
        total: total,
        unitsMapper: $unitsMapper,
    });

    onMount(() => {
        gridApi = createGrid(gridRootRef, gridOptions);
        return () => {
            gridApi?.destroy();
        };
    });
</script>

<div class="container">
    <div class="header">
        <div class="info">
            <div class="mdc-typography--headline5" style:flex="1">{name}</div>
            {#if !hideTable}
                <ColorInput
                    value={binTable.getColor(PileWeightClass.Standard)}
                    disabled={binTable.readonly}
                    onChangeColor={(newColor) => {
                        binsBuilder.updateBin(binTable, binTable.updateColor(newColor, PileWeightClass.Standard));
                    }}
                />
                <ColorInput
                    value={binTable.getColor(PileWeightClass.Heavy)}
                    disabled={binTable.readonly}
                    onChangeColor={(newColor) => {
                        binsBuilder.updateBin(binTable, binTable.updateColor(newColor, PileWeightClass.Heavy));
                    }}
                />
            {/if}
        </div>
        {#if !hideTable}
            <div class="filter">
                <div class="text-info">
                    {#if min}
                        More than {min.valueUnitUiString($unitsMapper, 2)} reveal
                    {/if}
                </div>
                <div class="max-info">
                    {#if maxRevealProp}
                        <NumberPropertyComponent
                            sourceProperty={maxRevealProp}
                        />
                    {/if}
                </div>
                {#if !binTable.readonly}
                    <ButtonComponent desc={new IconButton("Delete", () => {
                        binsBuilder.removeBin(binTable);
                    })} />
                {/if}
            </div>
        {/if}
    </div>
    <PileGridWrapper hide={hideTable}>
        <div bind:this={gridRootRef} class="ag-theme-balham" />
    </PileGridWrapper>
    {#if hideTable}
        <span>
            None of {total} piles have their length or reveal outside of the target
            range.
        </span>
    {/if}
</div>

<style lang="scss">
    .container {
        padding: 0 16px;
        margin-top: 24px;
    }
    .header {
        height: 24px;
        display: flex;
        gap: 8px;
        margin-bottom: 4px;
        .info {
            width: 140px;
            display: flex;
            gap: 4px;
            align-items: center;
            flex-shrink: 0;
        }
        .filter {
            width: calc(100% - 140px);
            display: flex;
            gap: 8px;
            align-items: center;
            overflow: hidden;
            :global(.property-name) {
                display: none;
            }
        }
        .text-info {
            flex: 1;
            padding: 0 8px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .max-info {
            flex: 1;
        }
    }
</style>
