import type { EntityId } from 'verdata-ts';
import { Allocated, Deleted, Updated } from 'engine-utils-ts';

export type EntitiesCollectionUpdates<IdT, Diff extends number> =
	Allocated<IdT> | EntitiesUpdated<IdT, Diff> | Deleted<IdT>;

export class EntitiesUpdated<IdT, Diff extends number> extends Updated<IdT> {

	public readonly diffs: Diff[];
	public readonly allFlagsCombined: Diff;

	constructor(
		ids: IdT[],
		diffs: Diff[],
	){
		super(ids);
		this.diffs = diffs;
		Object.freeze(this.diffs);

		let allFlags = 0;
		for (const diff of this.diffs) {
			allFlags |= (diff as number);
		}
		this.allFlagsCombined = allFlags as Diff;
		if (ids.length > 0 && typeof ids[0] !== 'number') {
			throw new Error('EntitiesUpdated expect ids to be numbers')
		}
		Object.freeze(this);
	}

	static fromTuples<IdT, Diff extends number>(perId: Iterable<[IdT, Diff]>) {
		const ids: IdT[] = [];
		const diffs: Diff[] = [];
		for (const t of perId) {
			ids.push(t[0]);
			diffs.push(t[1]);
		}
		return new EntitiesUpdated<IdT, Diff>(ids, diffs);
	}
	static fromSingleFlag<IdT, Diff extends number>(flag: Diff, ids: IdT[]) {
		return new EntitiesUpdated(
			ids,
			ids.map(_ => flag),
		);
	}
}

export function handleEntitiesUpdates<IdT, Diff extends number>(
	updates: EntitiesCollectionUpdates<EntityId<IdT>, Diff>[] | null | undefined,
	allocationsHandler: (ids: Set<EntityId<IdT>>) => void,
	updatesHandler: (ids: Map<EntityId<IdT>, Diff>) => void,
	removalsHandler: (ids: Set<EntityId<IdT>>) => void,
) {
	if (!updates) {
		return;
	}

	// merge sequence of different of updates to single remove, alloc and update calls

	const toDelete = new Set<EntityId<IdT>>();
	const toAlloc = new Set<EntityId<IdT>>();
	const toUpdate = new Map<EntityId<IdT>, Diff>();

	for (const update of updates) {
		if (update instanceof Allocated) {
			for (const id of update.ids) {
				toAlloc.add(id);
			}
		} else if (update instanceof EntitiesUpdated) {
			// if diffs are simple numbers, can merge
			for (let i = 0; i < update.ids.length; ++i) {
				const id = update.ids[i];
				const diff = update.diffs[i] as number;
				const prevDiff = (toUpdate.get(id) ?? 0) as number;
				toUpdate.set(id, (prevDiff | diff) as Diff);
			}

		} else if (update instanceof Deleted) {
			for (const id of update.ids) {
				toDelete.add(id);
				toAlloc.delete(id);
				toUpdate.delete(id);
			}
		} else {
			console.error('unrecognized update type, ignoring', update);
		}
	}
	
	if (toDelete.size) {
		removalsHandler(toDelete)
	}
	if (toAlloc.size) {
		allocationsHandler(toAlloc);
	}
	if (toUpdate.size) {
		updatesHandler(toUpdate);
	}

}