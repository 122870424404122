import { LegacyLogger } from 'engine-utils-ts';

import { SortableWasmVec } from '../../../engine_wasm/dist/engine_wasm';

export class SortableVecWasm<T> {

    _key2Extractor: (obj: T) => number;
    _objects: T[] = [];
    _wasm: SortableWasmVec;

    constructor(capacity: number, key2Extractor: (obj: T) => number) {
        this._key2Extractor = key2Extractor;
        this._wasm = new SortableWasmVec(capacity);
    }

    dispose() {
        this._wasm.free();
    }

    clear() {
        this._objects.length = 0;
        this._wasm.clear();
    }

    len() {
        return this._objects.length;
    }

    add(object: T, key1: number) {
        const key2 = this._key2Extractor(object);
        LegacyLogger.debugAssert(this._wasm.add2(key1, key2) == this._objects.length, 'sortable vec insert index sanity check');
        this._objects.push(object);
    }

    *consumeIter(sort: boolean): Iterable<T> {
        if (sort) {
            const sortedInds = this._wasm.get_sorted();
            for (const ind of sortedInds) {
                yield this._objects[ind];
            }
        } else {
            yield* this._objects;
        }
        this._objects.length = 0;
        this.dispose();
    }
}

