import { BooleanProperty, NumberProperty, NumberPropertyWithOptions, NumberRangeProperty, SelectorProperty, StringProperty } from '../../properties/PrimitiveProps';
import { CatalogItemsReferenceProperty, SceneInstancesProperty } from '../../properties/ObjectRefProps';
import { fromAssetToCatalogProperty, removeDescriptionMigration } from './MigrationUtils';

import { AllArrayItems } from '../ConfigUtils';
import { AssetCatalogItemTypeIdentifier } from '../../catalog';
import type { Bim } from '../../Bim';
import type { ConfigArchetypeContext, ConfigShapeMigration } from '../ConfigsArchetypes';
import { PVModuleTypeIdent } from '../../archetypes/pv-module/PVModule';
import type { PropertyGroup } from '../../properties/PropertyGroup';
import { StateType } from '../ConfigsArchetypes';
import { TrackerFrameTypeIdentifier } from '../../archetypes/TrackerFrame';
import { patternNames } from '../../constants/dc-patterns';
import { v4 as uuid } from 'uuid';
import type { BasicCollectionUpdates, ScopedLogger } from 'engine-utils-ts';
import { Allocated, Immer, IterUtils, peekCurrentEventFrame } from 'engine-utils-ts';
import type { IdBimScene, SceneInstances } from '../../scene/SceneInstances';
import type { Config, ConfigPatch, ConfigsCollection, IdConfig } from '../ConfigsCollection';
import { BimCollectionPatch } from '../../collections/BimCollection';
import { BoundaryTypeIdent } from '../../archetypes/Boundary';
import { VerdataApplyPatchEventName } from '../../persistence/EntitiesPersisted';

export const FarmLayoutConfigType = 'farm-layout-panel-config';

export const AllSiteAreaId = 'all-site-area-identifier';
export const UnallocatedSubareaId = 'unallocated-subarea-identifier';

const TrackerArrayInstanceTypes = ["tracker", "fixed-tilt", "any-tracker"];

export type SolarArrayConfig = {
    id: StringProperty;
    preset: CatalogItemsReferenceProperty;
    trackerFrame: CatalogItemsReferenceProperty;
    module: CatalogItemsReferenceProperty;
}

export function createNewEmptySolarArrayConfig(): SolarArrayConfig {
    return {
        id: StringProperty.new({ value: uuid() }),
        preset: new CatalogItemsReferenceProperty({
            types: [AssetCatalogItemTypeIdentifier],
            value: [],
            maxCount: 1,
            assetsTypes: TrackerArrayInstanceTypes,
        }),
        module: new CatalogItemsReferenceProperty({
            types: [AssetCatalogItemTypeIdentifier],
            value: [],
            maxCount: 1,
            assetsTypes: [PVModuleTypeIdent],
        }),
        trackerFrame: new CatalogItemsReferenceProperty({
            types: [AssetCatalogItemTypeIdentifier],
            value: [],
            maxCount: 1,
            assetsTypes: [TrackerFrameTypeIdentifier],
        }),
    };
}

export interface SubareasConfig<T extends PropertyGroup> extends PropertyGroup {
    site_areas: SiteAreaBase<T>[];
}

export interface FarmLayoutConfig extends PropertyGroup, SubareasConfig<SiteAreaSettings> {
    selected_area: NumberProperty;
    site_areas: SiteArea[];
}

export type SiteArea = AllSiteArea | UnallocatedSubarea | SiteSubarea;


export interface SiteAreaBase<T extends PropertyGroup> extends PropertyGroup { 
    id: StringProperty;
    last_calculation_hash: StringProperty | null,
    settings: T;
}

export interface AllSiteArea extends SiteAreaBase<SiteAreaSettings> {
    name: StringProperty;
    boundaries: SceneInstancesProperty;
    autoSelect: BooleanProperty | null;  
}

export interface UnallocatedSubarea extends SiteAreaBase<SiteAreaSettings> { 
    name: StringProperty;
}

export interface SiteSubarea extends SiteAreaBase<SiteAreaSettings> {
    name: StringProperty;
    priority: NumberProperty;
    zones: SceneInstancesProperty;
    equipmentBoundaries: SceneInstancesProperty;
}

export interface SiteAreaSettings extends PropertyGroup {
    capacity: {
        total_dc_power: NumberPropertyWithOptions;
        number_of_blocks: NumberPropertyWithOptions;
        ilr_range: NumberRangeProperty;
    };
    spacing: {
        row_to_row_space: NumberPropertyWithOptions;
        max_row_to_row_space_result: NumberProperty;
        max_row_height: NumberProperty;
        align_arrays: BooleanProperty;
        equipment_glass_to_glass: NumberProperty;
        support_glass_to_glass: NumberProperty;
    };
    electrical: {
        /**
         * @deprecated use solar_arrays[i].preset instead of trackers
         */
        trackers: CatalogItemsReferenceProperty;
        combiner_box: CatalogItemsReferenceProperty;
        solar_arrays: SolarArrayConfig[]
        blocks_equipment: BlockEquipment[];
        scheme: SelectorProperty;
        nec_multiplier: NumberProperty;
        road_sharing: BooleanProperty;
        colorize: BooleanProperty;
    };
    roads: {
        equipment_road_width: NumberProperty;
        support_road_width: NumberProperty;

        transformer_roadside: SelectorProperty;
        global_ns_roads: BooleanProperty;

        roads_step: NumberProperty;
        roads: SceneInstancesProperty;

        equipment_roads_angle: NumberPropertyWithOptions;
        tracker_angle: NumberProperty;

        equipment_roads_options: SelectorProperty;
        support_roads_options: SelectorProperty;
    };
    offsets: {
        block_offset: NumberProperty;
        transformer_offset: NumberProperty;
        inverter_offset: NumberProperty;
        combiner_box_offset: NumberProperty;
        tracker_offset: NumberProperty;
    };
}

export interface BlockEquipment extends PropertyGroup {
    id: NumberProperty;
    label: StringProperty;

    transformer: CatalogItemsReferenceProperty;
    inverter: CatalogItemsReferenceProperty;

    number_of_inverters: NumberPropertyWithOptions;
    inverters_per_block: NumberPropertyWithOptions;

    ilr_range: NumberRangeProperty;
}

export function autoFillBoundariesToBuildableArea(
    instances: SceneInstances,
    configs: ConfigsCollection, 
    updates: BasicCollectionUpdates<IdBimScene>, 
    logger: ScopedLogger
){
    const event = peekCurrentEventFrame();
    if(!(updates instanceof Allocated) || configs.isLocked() || event.identifier === VerdataApplyPatchEventName){
        return;
    }
    const allocatedInstances = instances.peekByIds(updates.ids);
    const boundariesIds: IdBimScene[] = [];
    for(const [id, inst] of allocatedInstances){
        if(inst.type_identifier === BoundaryTypeIdent){
            boundariesIds.push(id);
        }
    }
    if(boundariesIds.length === 0){
        return;
    }
    const farmLayoutConfigs = configs.peekByType(FarmLayoutConfigType);
    const patch = new BimCollectionPatch<Config, IdConfig, ConfigPatch>("add-boundaries-patch");
    for (const [id, config] of farmLayoutConfigs) {
        patchConfigWithBoundaries(patch, id, config, boundariesIds, logger);
    }
    configs.applyCollectionPatch(patch);
}

function patchConfigWithBoundaries(
    patch: BimCollectionPatch<Config, IdConfig, ConfigPatch>, 
    id: IdConfig,
    config: Config, 
    boundaries: IdBimScene[], 
    logger: ScopedLogger
) {
    const props = config.get<FarmLayoutConfig>();
    const allSiteAreaIdx = props.site_areas.findIndex(area => area.id.value === AllSiteAreaId);
    if(allSiteAreaIdx < 0){
        return;
    }
    const allSiteArea = props.site_areas[allSiteAreaIdx];
    if(allSiteArea.autoSelect instanceof BooleanProperty && allSiteArea.autoSelect.value){ 
        const boundariesProp = allSiteArea.boundaries as SceneInstancesProperty;
        const prevValue = (allSiteArea.boundaries as SceneInstancesProperty).value.slice();
        const newBoundaries = new Set(prevValue);
        IterUtils.extendSet(newBoundaries, boundaries);
        const updatedValue = Array.from(newBoundaries);
        if(IterUtils.areArraysEqual(prevValue, updatedValue)){
            return;
        }
        const updatedProps = Immer.produce(props, draft => {
            draft.site_areas[allSiteAreaIdx].boundaries = boundariesProp.withDifferentValue(updatedValue);
        })
        patch.toPatch.push([id, {properties: updatedProps}]);
    }
}
function createDefaultFarmLayoutConfigProps(context: ConfigArchetypeContext): FarmLayoutConfig {
    const boundariesIds: IdBimScene[] = IterUtils.filterMap(context.instances, ([id, inst]) => {
        if(inst.type_identifier !== BoundaryTypeIdent){
            return;
        }
        return id;
    });
    
    const properties: FarmLayoutConfig = {
        selected_area: NumberProperty.new({value: 0, step: 1, range: [0, 1e9]}),
        site_areas: [
            {
                id: StringProperty.new({ value: AllSiteAreaId }),
                last_calculation_hash: null,
                allSiteArea: BooleanProperty.new({ value: true, isReadonly: true }),
                name: StringProperty.new({ value: "All site area", isReadonly: true }),
                boundaries: new SceneInstancesProperty({
                    value: boundariesIds,
                    types: ["boundary"],
                }),
                settings: getDefaultSiteAreaSettings(),
                autoSelect: BooleanProperty.new({ value: true }),
            },
            {
                id: StringProperty.new({ value: UnallocatedSubareaId }),
                last_calculation_hash: null,
                unallocated: BooleanProperty.new({ value: true, isReadonly: true }),
                name: StringProperty.new({ value: "Unallocated subarea", isReadonly: true }),
                settings: getDefaultSiteAreaSettings(),
            },
        ],
    };

    return properties;
}

export function registerFarmLayoutConfig(bim: Bim) {
    bim.configs.archetypes.registerArchetype({
        type_identifier: FarmLayoutConfigType,
        properties: createDefaultFarmLayoutConfigProps,
        connectionToType: "substation",
        stateType: StateType.CreateByConnection,
        shapeMigrations: migrations(),
    });
}

export function getDefaultSubarea(args: {
    name: string, 
    priority: number,
    rangePriority?: [number, number],
    settings?: SiteAreaSettings
 }): SiteSubarea {
    return {
        id: StringProperty.new({ value: uuid() }),
        last_calculation_hash: null,
        subArea: BooleanProperty.new({ value: true, isReadonly: true }),
        name: StringProperty.new({ value: args.name }),
        priority: NumberProperty.new({
            value: args.priority,
            step: 1,
            range: args.rangePriority ?? [1, 1e9],
        }),
        equipmentBoundaries: new SceneInstancesProperty({
            value: [],
            types: ["boundary"],
        }),
        zones: new SceneInstancesProperty({
            value: [],
            types: ['boundary'],
        }),
        settings: args.settings ? args.settings : getDefaultSiteAreaSettings(),
    };
}

export function getDefaultSiteAreaSettings(): SiteAreaSettings {
    const settings: SiteAreaSettings = {
        capacity: {
            ilr_range: NumberRangeProperty.new({
                value: [1.05, 1.25],
                unit: "",
                step: 0.01,
                range: [0, 100],
            }),
            number_of_blocks: NumberPropertyWithOptions.new({
                value: 1,
                unit: "",
                step: 1,
                range: [1, 1e9],
                options: ["auto", "set", "ignore"],
                selectedOption: "ignore",
            }),
            total_dc_power: NumberPropertyWithOptions.new({
                value: 1000000,
                unit: "kW",
                step: 1,
                range: [0, 1e9],
                options: ["find max", "target"],
                selectedOption: "find max",
            }),
        },
        spacing: {
            row_to_row_space: NumberPropertyWithOptions.new({
                value: 18,
                unit: "ft",
                range: [0, 1e9],
                options: ["set", "find max"],
                selectedOption: "set",
                step: 0.01,
            }),
            max_row_to_row_space_result: NumberProperty.new({
                value: 0,
                unit: 'ft',
                range: [0, 1e9],
                isReadonly: true,
            }),
            equipment_glass_to_glass: NumberProperty.new({
                value: 60,
                unit: "ft",
                range: [1, 1e9],
            }),
            support_glass_to_glass: NumberProperty.new({
                value: 60,
                unit: "ft",
                range: [1, 1e9],
            }),
            max_row_height: NumberProperty.new({
                value: 2,
                unit: "",
                range: [1, 1e9],
                step: 1,
            }),
            align_arrays: BooleanProperty.new({
                value: false
            })
        },
        electrical: {
            trackers: new CatalogItemsReferenceProperty({
                value: [],
                types: ["asset"],
                assetsTypes: TrackerArrayInstanceTypes,
            }),
            combiner_box: new CatalogItemsReferenceProperty({
                value: [],
                types: ["asset"],
                assetsTypes: ["combiner-box"],
                maxCount: 1,
            }),
            blocks_equipment: [],
            road_sharing: BooleanProperty.new({
                value: false,
            }),
            scheme: SelectorProperty.new({
                value: "CI_Multiharness",
                options: patternNames,
            }),
            nec_multiplier: NumberProperty.new({
                value: 1.5625,
                unit: "",
                range: [0, 1e9],
            }),
            colorize: BooleanProperty.new({
                value: true,
            }),
            solar_arrays: [],
        },
        roads: {
            equipment_roads_options: SelectorProperty.new({
                value: 'Generate equipment roads',
                options: ['Generate equipment roads', 'Select existing'],
            }),
            support_roads_options: SelectorProperty.new({
                value: 'Ignore',
                options: ['Ignore', 'Generate support roads', 'Select existing'],
            }),
            equipment_road_width: NumberProperty.new({
                value: 20,
                unit: "ft",
                range: [0, 1e9],
            }),
            support_road_width: NumberProperty.new({
                value: 20,
                unit: "ft",
                range: [0, 1e9],
            }),
            transformer_roadside: SelectorProperty.new({
                value: "south",
                options: ["north", "south", "optimize"],
            }),
            global_ns_roads: BooleanProperty.new({
                value: false,
            }),
            roads_step: NumberProperty.new({
                value: 1000,
                unit: "ft",
                range: [0, Number.MAX_VALUE],
                step: 1,
            }),
            roads: new SceneInstancesProperty({
                value: [],
                types: ["road"],
            }),
            equipment_roads_angle: NumberPropertyWithOptions.new({
                value: 0,
                unit: "deg",
                range: [-90, 90],
                selectedOption: 'set',
                options: ['set', 'auto'],
            }),
            tracker_angle: NumberProperty.new({
                value: 0,
                unit: "deg",
                range: [-90, 90],
            }),
        },
        offsets: {
            tracker_offset: NumberProperty.new({
                value: 3,
                unit: "ft",
                range: [0, 1e9],
            }),
            transformer_offset: NumberProperty.new({
                value: 20,
                unit: "ft",
                range: [0, 1e9],
            }),
            inverter_offset: NumberProperty.new({
                value: 20,
                unit: "ft",
                range: [0, 1e9],
            }),
            combiner_box_offset: NumberProperty.new({
                value: 27,
                unit: "ft",
                range: [0, 1e9],
            }),
            block_offset: NumberProperty.new({
                value: 15,
                unit: "ft",
                range: [0, 1e9],
            }),
        },
    };

    return settings;
}

function migrations(): ConfigShapeMigration[] {
    return [
        {
            toVersion: 1,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                //@ts-ignore
                props.layout_settings.glass_to_glass = NumberProperty.new({
                    //@ts-ignore
                    ...props.layout_settings.glass_to_glass,
                    range: [1, 1e9],
                });
            },
            validation: {
                updatedProps: [{ path: ["layout_settings", "glass_to_glass"] }],
                deletedProps: [],
            },
        },
        {
            toVersion: 2,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>()
                //@ts-ignore;
                props.equipment_settings.combiner_box = fromAssetToCatalogProperty(props.equipment_settings.combiner_box);
                //@ts-ignore;
                props.equipment_settings.trackers = fromAssetToCatalogProperty(props.equipment_settings.trackers);
                //@ts-ignore
                for (const blockCase of props.electrical_settings.blockCases) {
                    blockCase.inverter = fromAssetToCatalogProperty(blockCase.inverter);
                    blockCase.transformer = fromAssetToCatalogProperty(blockCase.transformer);
                }
            },
            validation: {
                updatedProps: [{ path: ["equipment_settings", "combiner_box"] }, { path: ["equipment_settings", "trackers"] }],
                deletedProps: [],
            },
        },
        {
            toVersion: 3,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                //@ts-ignore
                props.electrical_settings.block_mode.options.push('new');
                //@ts-ignore;
                props.electrical_settings.set_target_power = BooleanProperty.new({value: true});
                //@ts-ignore;
                props.electrical_settings.find_max_power = BooleanProperty.new({value: false});
            },
            validation: {
                updatedProps: [
                    { path: ["electrical_settings", "block_mode"] }, 
                    { path: ["electrical_settings", "set_target_power"] },
                    { path: ["electrical_settings", "find_max_power"] },
                ],
                deletedProps: [],
            },
        },
        {
            toVersion: 4,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                //@ts-ignore
                for (const block of props.electrical_settings.blockCases) {
                    block.ilr_min = NumberProperty.new({
                        value: 1.05,
                        unit: "",
                    });
                    block.ilr_max = NumberProperty.new({
                        value: 1.25,
                        unit: "",
                    });
                    block.number_of_blocks = StringProperty.new({
                        value: "1",
                    });
                    block.number_of_inverters = NumberProperty.new({
                        value: 1,
                        unit: ""
                    });
                }
            },
            validation: {
                updatedProps: [
                    { path: ["electrical_settings", "blockCases"] }, 
                ],                
                deletedProps: [],
            },
        },
        {
            toVersion: 5,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                //@ts-ignore
                props.electrical_settings.blocks_equipment = [];
            },
            validation: {
                updatedProps: [
                    { path: ["electrical_settings", "blockCasesNew"] }, 
                ],
                deletedProps: [],
            },
        },
        {
            toVersion: 6,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                //@ts-ignore;
                props.electrical_settings.blocks_equipment = [];
                //@ts-ignore;
                props.capacity = {
                    ilr_range: NumberRangeProperty.new({
                        value: [1.05, 1.25],
                        unit: "",
                        step: 0.01,
                        range: [0, 10],
                    }),
                    number_of_blocks: NumberPropertyWithOptions.new({
                        value: 1,
                        unit: "",
                        step: 1,
                        range: [0, 1e9],
                        options: ['auto', 'set', 'target', 'mixed', 'ignore'],
                        selectedOption: 'auto',
                    }),
                    total_dc_power: NumberPropertyWithOptions.new({
                        value: 1000000,
                        unit: "kW",
                        step: 1,
                        range: [0, 1e9],
                        options: ['find max', 'target'],
                        selectedOption: 'find max',
                    }),
                }
                //@ts-ignore;
                props.electrical_settings.blocks_equipment = [];
                //@ts-ignore
                props.electrical_settings.scheme = props.electrical_settings.pattern;
                //@ts-ignore
                delete props.electrical_settings.pattern;
            },
            validation: {
                updatedProps: [
                    { path: ["capacity", "ilr_range"], }, 
                    { path: ["capacity", "number_of_blocks"], }, 
                    { path: ["capacity", "total_dc_power"], },
                    { path: ["electrical_settings", "blocks_equipment"] }, 
                    { path: ["electrical_settings", "scheme"] }, 
                ],
                deletedProps: [
                    { path: ["electrical_settings", "blockCasesNew"] }, 
                    { path: ["electrical_settings", "pattern"] }, 

                ],
            },
        },
        {
            toVersion: 7,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                //@ts-ignore;
                const blocksNumber = props.electrical_settings.blocks_equipment.length;
                //@ts-ignore;
                const totalDcPower = props.capacity.total_dc_power;
                //@ts-ignore;
                props.capacity.total_dc_power = NumberPropertyWithOptions.new({
                    ...totalDcPower,
                    selectedOption: totalDcPower.selectedOption === 'ignore' ? 'find max' : totalDcPower.selectedOption, 
                    options: ["find max", "target"],
                });
                //@ts-ignore;
                const numberOfBlocks = props.capacity.number_of_blocks;
                //@ts-ignore;
                props.capacity.number_of_blocks = NumberPropertyWithOptions.new({
                    ...numberOfBlocks,
                    selectedOption: numberOfBlocks.selectedOption === 'target' 
                        ? blocksNumber > 0 ? 'mixed' : "set" 
                        : numberOfBlocks.selectedOption,
                //@ts-ignore; 
                    options: numberOfBlocks.options.filter(o => o !== 'target'),
                });
            },
            validation: {
                updatedProps: [
                    { path: ["capacity", "total_dc_power"] },
                    { path: ["capacity", "number_of_blocks"] },
                ],
                deletedProps: [],
            },
        },
        {
            toVersion: 8,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                //@ts-ignore;
                props.layout_settings.row_to_row_space = NumberPropertyWithOptions.new({
                //@ts-ignore;
                    ...props.layout_settings.row_to_row_space,
                    selectedOption: 'set',
                    options: ['set', 'find max']
                });
                //@ts-ignore;
                props.capacity.number_of_blocks = NumberPropertyWithOptions.new({
                //@ts-ignore;
                    ...props.capacity.number_of_blocks,
                    range: [1, 100],
                });
                //@ts-ignore;
                for (const block of props.electrical_settings.blocks_equipment) {
                    //@ts-ignore
                    block.inverters_per_block = NumberProperty.new({
                        ...block.inverters_per_block,
                        range: [2, 100],
                        value: block.inverters_per_block.value < 2 ? 2 : block.inverters_per_block.value
                    });
                    block.number_of_inverters = NumberPropertyWithOptions.new({
                        ...block.number_of_inverters,
                        range: [1, 100],
                    });
                }
            },
            validation: {
                updatedProps: [
                    { path: ["layout_settings", "row_to_row_space"] },
                    { path: ["capacity", "number_of_blocks"] },
                    { path: ["electrical_settings", "blocks_equipment"] },
                ],
                deletedProps: [],
            },
        },
        {
            toVersion: 9,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                //@ts-ignore;
                for (const block of props.electrical_settings.blocks_equipment) {
                    block.inverters_per_block = NumberPropertyWithOptions.new({
                        ...block.inverters_per_block,
                        options: ['set', 'auto'],
                        selectedOption: 'set',
                    });
                }
            },
            validation: {
                updatedProps: [
                    { path: ["electrical_settings", "blocks_equipment"] },
                ],
                deletedProps: [],
            },
        },
        {
            toVersion: 10,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                //@ts-ignore
                delete props.electrical_settings.blockCases;
                //@ts-ignore
                delete props.electrical_settings.block_mode;
                //@ts-ignore
                delete props.layout_settings.roads.adaptive_roads;
            },
            validation: {
                updatedProps: [],
                deletedProps: [
                    { path: ["electrical_settings", "blockCases"] },
                    { path: ["electrical_settings", "block_mode"] },
                    { path: ["layout_settings", "roads", "adaptive_roads"] },
                ],
            },
        },
        {
            toVersion: 11,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                //@ts-ignore;
                props.layout_settings.roads.global_ns_roads = BooleanProperty.new({value: false});
            },
            validation: {
                updatedProps: [
                    { path: ["layout_settings", "roads", "global_ns_roads"] }
                ],
                deletedProps: [],
            },
        },
        {
            toVersion: 12,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                //@ts-ignore;
                for (const block of props.electrical_settings.blocks_equipment) {
                    block.inverters_per_block = NumberPropertyWithOptions.new({
                        ...block.inverters_per_block,
                        range: [1, 1e9],
                    });
                    block.number_of_inverters = NumberPropertyWithOptions.new({
                        ...block.inverters_per_block,
                        range: [1, 1e9],
                    });
                }
                //@ts-ignore;
                props.capacity.number_of_blocks = NumberPropertyWithOptions.new({
                    //@ts-ignore;
                    ...props.capacity.number_of_blocks,
                    range: [1, 1e9],
                });
            },
            validation: {
                updatedProps: [
                    { path: ["electrical_settings", "blocks_equipment"] },
                    { path: ["capacity", "number_of_blocks"] },
                ],
                deletedProps: [],
            },
        },
        {
            toVersion: 13,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                //@ts-ignore;
                if (!props.equipment_settings.solar_arrays) {
                    //@ts-ignore;
                    props.equipment_settings.solar_arrays = []
                }
            },
            validation: {
                updatedProps: [
                    { path: ["equipment_settings", "solar_arrays"] },
                ],
                deletedProps: [],
            },
        },
        {
            toVersion: 14,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                //@ts-ignore;
                if (!props.equipment_settings.trackers) {
                    return;
                }
                const solarArrays: SolarArrayConfig[] = [];
                //@ts-ignore;
                for (const value of props.equipment_settings.trackers.value) {
                    const solarArray = createNewEmptySolarArrayConfig();
                    solarArray.preset.value.push(value) 
                    solarArrays.push(solarArray);
                }
                //@ts-ignore;
                props.equipment_settings.solar_arrays.push(...solarArrays);
            },
            validation: {
                updatedProps: [
                    { path: ["equipment_settings", "solar_arrays"] },
                ],
                deletedProps: [],
            }
        },
        {
            toVersion: 15,
            patch: (config) => {
                const props = config.get<any>();
                const settings: SiteAreaSettings = {
                    capacity: props.capacity,
                    rotation: props.layout_settings.rotation,
                    shift: props.layout_settings.shift,
                    offsets: props.layout_settings.offsets,
                    electrical: {
                        blocks_equipment: props.electrical_settings.blocks_equipment,
                        trackers: props.equipment_settings.trackers,
                        combiner_box: props.equipment_settings.combiner_box,
                        nec_multiplier:props.electrical_settings.NEC_MULTIPLIER,
                        colorize: props.electrical_settings.colorize,
                        road_sharing: props.electrical_settings.road_sharing,
                        scheme: props.electrical_settings.scheme,
                        solar_arrays: props.equipment_settings.solar_arrays,
                    },
                    roads: {
                        //@ts-ignore
                        create_ew_roads: props.layout_settings.roads.create_ew_roads,
                        create_ns_roads: props.layout_settings.roads.create_ns_roads,
                        global_ns_roads: props.layout_settings.roads.global_ns_roads,
                        roads_step: props.layout_settings.roads.roads_step,
                        roads: props.layout_settings.roads.roads,
                        //@ts-ignore
                        road_width: props.layout_settings.road_width,
                        transformer_roadside: props.electrical_settings.transformer_roadside,
                    },
                    spacing: {
                        //@ts-ignore
                        glass_to_glass: props.layout_settings.glass_to_glass,
                        max_row_height: props.layout_settings.max_row_height,
                        row_to_row_space: props.layout_settings.row_to_row_space,
                        max_row_to_row_space_result: NumberProperty.new({
                            value: 0,
                            unit: 'ft',
                            range: [0, 1e9],
                            isReadonly: true,
                        }),
                    },
                };
                //@ts-ignore
                const allSiteArea: AllSiteArea = {
                    allSiteArea: BooleanProperty.new({ value: true, isReadonly: true }),
                    name: StringProperty.new({ value: "All site area", isReadonly: true }),
                    boundaries: props.layout_settings.boundaries,
                    settings,
                };
                //@ts-ignore
                const unlocated: UnallocatedSubarea = {
                    unallocated: BooleanProperty.new({ value: true, isReadonly: true }),
                    name: StringProperty.new({ value: "Unallocated subarea", isReadonly: true }),
                    settings: getDefaultSiteAreaSettings(),
                };
                props.site_areas = [allSiteArea, unlocated];
                props.selected_area = NumberProperty.new({value: 0, step: 1, range: [0, 1e9]});
                
                delete props.electrical_settings;
                delete props.layout_settings;
                delete props.equipment_settings,
                delete props.capacity;
                
            },
            validation: {
                updatedProps: [
                    { path: ["site_areas"] }
                ],
                deletedProps: [
                    { path: ["electrical_settings"] },
                    { path: ["layout_settings"] },
                    { path: ["equipment_settings"] },
                    { path: ["capacity"] },
                ],
            },
        },
        { 
            toVersion: 16,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();

                for (const area of props.site_areas) {
                    //@ts-ignore;
                    const shift = area.settings?.shift;
                    if(!shift){
                        continue;
                    }
                    //@ts-ignore
                    const isGenEquipmentRoads = area.settings.roads.create_ew_roads.value;
                    //@ts-ignore
                    const isGenSupportRoads = area.settings.roads.create_ns_roads.value;
                    area.settings.roads.equipment_roads_options = SelectorProperty.new({
                        value: isGenEquipmentRoads ? 'Generate equipment roads' : 'Select existing',
                        options: ['Generate equipment roads', 'Select existing'],
                    });
                    area.settings.roads.support_roads_options = SelectorProperty.new({
                        value: isGenEquipmentRoads && isGenSupportRoads ? 'Generate support roads' : isGenEquipmentRoads ? 'Ignore' : 'Select existing',
                        options: ['Ignore', 'Generate support roads', 'Select existing'],
                    });
                    //@ts-ignore
                    delete area.settings.roads.create_ew_roads;
                    //@ts-ignore
                    delete area.settings.roads.create_ns_roads;

                    //@ts-ignore
                    area.settings.spacing.equipment_glass_to_glass = area.settings.spacing.glass_to_glass;              
                    area.settings.spacing.support_glass_to_glass = NumberProperty.new({
                        value: area.settings.spacing.equipment_glass_to_glass.as('ft'),
                        unit: "ft",
                        range: [1, 1e9],
                    });
                    //@ts-ignore
                    delete area.settings.spacing.glass_to_glass;

                    //@ts-ignore
                    area.settings.roads.equipment_road_width = area.settings.roads.road_width;
                    area.settings.roads.support_road_width = NumberProperty.new({
                        value: area.settings.roads.equipment_road_width.value,
                        unit: "ft",
                        range: [0, 1e9],
                    });
                    //@ts-ignore
                    delete area.settings.roads.road_width;

                    //@ts-ignore
                    const shiftProps = shift.shift as NumberProperty;
                    //@ts-ignore
                    const selectedOption = area.settings.shift.auto_shift_detection.value ? 'auto' : 'set';
                    area.settings.roads.equipment_roads_angle = NumberPropertyWithOptions.new({
                        value: shiftProps.value,
                        unit: shiftProps.unit,
                        range: shiftProps.range,
                        step: shiftProps.step,
                        isReadonly: shiftProps.isReadonly,
                        description: shiftProps.description,
                        selectedOption: selectedOption,
                        options: ['set', 'auto'],
                    });
                    //@ts-ignore
                    const angle = area.settings.rotation.angle as NumberProperty;
                    area.settings.roads.tracker_angle = NumberProperty.new({
                        value: angle.value,
                        unit: angle.unit,
                        range: angle.range,
                        step: angle.step,
                        isReadonly: angle.isReadonly,
                        description: angle.description,
                    });
                    //@ts-ignore;
                    delete area.settings.rotation;
                    //@ts-ignore;
                    delete area.settings.shift;
                }
            },
            validation: {
                updatedProps: [
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'roads', 'equipment_roads_angle'] },
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'roads', 'tracker_angle'] },
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'roads', 'equipment_road_width'] },
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'roads', 'support_road_width'] },
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'roads', 'equipment_roads_options'] },
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'roads', 'support_roads_options'] },
                ],
                deletedProps: [
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'rotation'] }, 
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'shift'], },
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'spacing', 'glass_to_glass'], },
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'roads', 'road_width'], },
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'roads', 'create_ew_roads'], },
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'roads', 'create_ns_roads'], },
                ]
            }
        },
        {
            toVersion: 17,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                for (const area of props.site_areas) {
                    if(area.zones instanceof SceneInstancesProperty){
                        area.zones = new SceneInstancesProperty({
                            ...area.zones,
                            types: ['boundary']
                        });
                    }
                }
            },
            validation: {
                updatedProps: [],
                deletedProps: [],
            }
        },
        {
            toVersion: 18,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                for (const area of props.site_areas) {
                    area.settings.roads.equipment_roads_angle = NumberPropertyWithOptions.new({
                        ...area.settings.roads.equipment_roads_angle,
                        range: [-90, 90],
                    });
                }
            },
            validation: {
                updatedProps: [
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'roads', 'equipment_roads_angle'] },
                ],
                deletedProps: [],
            }
        },
        {
            toVersion: 19,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                for (const area of props.site_areas) {
                    area.settings.spacing.max_row_height = NumberProperty.new({
                        value: Math.max(area.settings.spacing.max_row_height.value, 1),
                        unit: "",
                        range: [1, 1e9],
                        step: 1,
                    });
                }
            },
            validation: {
                updatedProps: [
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'spacing', 'max_row_height'] },
                ],
                deletedProps: [],
            }
        },
        {
            toVersion: 20,
            patch: (config) => {
                removeDescriptionMigration(config.properties)
            },
            validation: {
                updatedProps: [],
                deletedProps: [],
            }
        },
        {
            toVersion: 21,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                for (const area of props.site_areas) {
                    //@ts-ignore
                    area.settings.spacing.max_tracker_shift = NumberProperty.new({
                        value: 1000,
                        unit: "ft",
                        range: [0.1, 1000],
                        step: 0.1,
                    });
                    //@ts-ignore
                    area.settings.spacing.driveline_mode = BooleanProperty.new({
                        value: false
                    });
                }
            },
            validation: {
                updatedProps: [
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'spacing', 'max_tracker_shift'] },
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'spacing', 'driveline_mode'] },
                ],
                deletedProps: [],
            }
        },
        {
            toVersion: 22,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                for (const area of props.site_areas) {
                    const oldVal = area.settings.electrical.scheme.value;

                    const newOptions = area.settings.electrical.scheme.options
                        .filter(x => x !== 'CI_MultVertTrunkbus');
                    const newVal = oldVal === 'CI_MultVertTrunkbus'
                        ? (newOptions.at(0) ?? '') : oldVal;

                    area.settings.electrical.scheme = SelectorProperty.new({
                        ...area.settings.electrical.scheme,
                        value: newVal,
                        options: newOptions,
                    })
                }
            },
            validation: {
                updatedProps: [],
                deletedProps: [],
            },
        },
        {
            toVersion: 23,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                for (const area of props.site_areas) {
                    area.settings.spacing.align_arrays = BooleanProperty.new({
                        value: false
                    });
                }
            },
            validation: {
                updatedProps: [
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'spacing', 'align_arrays'] },
                ],
                deletedProps: [
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'spacing', 'max_tracker_shift'] },
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'spacing', 'driveline_mode'] },
                ],
            }
        },
        // rename CI_VertTrunkbus to CI_MultVertTrunkbus
        {
            toVersion: 24,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                const from = 'CI_VertTrunkbus';
                const to = 'CI_MultVertTrunkbus';

                for (const area of props.site_areas) {
                    const oldVal = area.settings.electrical.scheme.value;

                    const newOptions = area.settings.electrical.scheme.options.slice()
                        .filter(x => x !== from && x !== to)
                    newOptions.push(to);

                    const newVal = oldVal === from ? to : oldVal

                    area.settings.electrical.scheme = SelectorProperty.new({
                        ...area.settings.electrical.scheme,
                        value: newVal,
                        options: newOptions,
                    })
                }
            },
            validation: {
                updatedProps: [],
                deletedProps: [],
            },
        },
        {
            toVersion: 25,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                for (const area of props.site_areas) {
                    area.settings.spacing = {
                        align_arrays: area.settings.spacing.align_arrays,
                        row_to_row_space: area.settings.spacing.row_to_row_space,
                        max_row_to_row_space_result: area.settings.spacing.max_row_to_row_space_result,
                        max_row_height: area.settings.spacing.max_row_height,
                        equipment_glass_to_glass: area.settings.spacing.equipment_glass_to_glass,
                        support_glass_to_glass: area.settings.spacing.support_glass_to_glass,
                    }
                }
            },
            validation: {
                updatedProps: [],
                deletedProps: [
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'spacing', 'max_tracker_shift'] },
                    { path: ['site_areas', AllArrayItems.new(), 'settings', 'spacing', 'driveline_mode'] },
                ],
            }
        },
        {
            toVersion: 26,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                for (const area of props.site_areas) {
                    if(!(area.boundaries instanceof SceneInstancesProperty) && !(area.equipmentBoundaries instanceof SceneInstancesProperty)){
                        area.id = StringProperty.new({ value: UnallocatedSubareaId });
                    } else if(area.boundaries instanceof SceneInstancesProperty){
                        area.id = StringProperty.new({ value: AllSiteAreaId })
                    } else if(area.equipmentBoundaries instanceof SceneInstancesProperty){
                        area.id = StringProperty.new({ value: uuid() });
                    } else {
                        console.error('Invalid site area type', area);
                    }
                }
            },
            validation: {
                updatedProps: [
                    { path: ['site_areas', AllArrayItems.new(), 'id'] },
                ],
                deletedProps: [],
            }
        },
        {
            toVersion: 27,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                for (const area of props.site_areas) {
                    const selectRoads = area.settings.roads.equipment_roads_options.value === "Select existing";
                    const targetDc = area.settings.capacity.total_dc_power;
                    const numberBlocks = area.settings.capacity.number_of_blocks;
                    if(selectRoads && targetDc.selectedOption === "target") {
                        area.settings.capacity.total_dc_power = targetDc.withDifferentOption("find max");
                    }
                    if(selectRoads && numberBlocks.selectedOption === "set"){
                        area.settings.capacity.number_of_blocks = numberBlocks.withDifferentOption("auto");
                    }
                }
            },
            validation: {
                updatedProps: [
                    { path: ['site_areas', AllArrayItems.new(), 'id'] },
                ],
                deletedProps: [],
            }
        },
        {
            toVersion: 28,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();
                for (const area of props.site_areas) {
                    if(area.id.value === AllSiteAreaId){ 
                        area.autoSelect = null;
                    }
                }
            },
            validation: {
                updatedProps: [],
                deletedProps: [],
            }
        },
        {
            toVersion: 29,
            patch: (config) => {
                const props = config.get<FarmLayoutConfig>();

                for (const area of props.site_areas) {
                    for (const solarArray of area.settings.electrical.solar_arrays) {
                        solarArray.preset = new CatalogItemsReferenceProperty({
                            ...solarArray.preset,
                            assetsTypes: TrackerArrayInstanceTypes,
                        });
                    }
                }
            },
            validation: {
                updatedProps: [],
                deletedProps: [],
            }
        },
    ];
}
