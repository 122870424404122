// export const
// bigInt: () => Promise<boolean>,
// bulkMemory: () => Promise<boolean>,
// exceptions: () => Promise<boolean>,
// multiValue: () => Promise<boolean>,
// mutableGlobals: () => Promise<boolean>,
// referenceTypes: () => Promise<boolean>,
// saturatedFloatToInt: () => Promise<boolean>,
// signExtensions: () => Promise<boolean>,
// simd: () => Promise<boolean>,
// tailCall: () => Promise<boolean>,
// threads: () => Promise<boolean>;

import { bulkMemory, saturatedFloatToInt } from 'wasm-feature-detect';


export interface BrowserFeatureAvailable {
}

function browserCompatibilityMessage(error: string): string {
	return error + '. Please use latest versions of Chrome/Edge/Opera and retry.';
}


export async function browserCompatibilityTest(): Promise<BrowserFeatureAvailable> {
	if (!window.WebGL2RenderingContext) {
		return Promise.reject(browserCompatibilityMessage('Webgl2 is not supported'));
	}

	// if (!await threads()) {
	// 	return Promise.reject(browserCompatibilityMessage("WebAssembly threads feature is not supported"));
	// }

	if (!await bulkMemory()) {
		return Promise.reject(browserCompatibilityMessage('WebAssembly BulkMemory feature is not supported'));
	}

	if (!await saturatedFloatToInt()) {
		return Promise.reject(browserCompatibilityMessage('WebAssembly SaturatedFloatToInt feature is not supported'));
	}

	return Promise.resolve({});
}
