import type { LazyVersioned, Yield } from "engine-utils-ts";
import type { Bim } from "src";
import type { ConfigsSerializer } from "src/persistence/ConfigSerializer";
import type {
    ExportedFileDescription,
    FileExporter,
    FileImporter,
    FileImporterContext,
    FileToImport,
} from "ui-bindings";
import { CostsConfigIdentifier } from ".";

export class CostModelImportExporter implements FileExporter<{}>, FileImporter {
    fileExtensions = [".PVFARMCOST"];

    private readonly bim: Bim;
    private readonly serializer: ConfigsSerializer;
    private readonly projectIdLazy: LazyVersioned<number | undefined>;

    constructor(
        bim: Bim,
        serializer: ConfigsSerializer,
        projectIdLazy: LazyVersioned<number | undefined>,
    ) {
        this.bim = bim;
        this.serializer = serializer;
        this.projectIdLazy = projectIdLazy;
    }

    *startImport(
        _context: FileImporterContext,
        file: FileToImport,
    ): Generator<Yield, void, unknown> {
        const parsedCapitalCosts = this.serializer.deserialize(
            new Uint8Array(file.fileArrayBuffer),
            new Set([1]),
        );
        const parsedLcoe = this.serializer.deserialize(
            new Uint8Array(file.fileArrayBuffer),
            new Set([2]),
        );
        const config = parsedCapitalCosts[0]?.[1];
        const lcoeConfig = parsedLcoe[0]?.[1];
        if (config) {
            this.bim.configs.applyPatchToSingleton(
                CostsConfigIdentifier,
                config,
            );
        }
        if (lcoeConfig) {
            this.bim.configs.applyPatchToSingleton("lcoe-settings", lcoeConfig);
        }
        return;
    }

    initialSettings() {
        return { defaultValue: {} };
    }

    *startExport(): Generator<Yield, ExportedFileDescription[]> {
        const config = this.bim.configs
            .getLazySingletonOf({
                type_identifier: CostsConfigIdentifier,
            })
            .poll();
        const lcoeConfig = this.bim.configs
            .getLazySingletonOf({
                type_identifier: "lcoe-settings",
            })
            .poll();

        const projectId = this.projectIdLazy.poll();
        const projectIdStr = projectId ? `#${projectId}` : "";

        const bytes = this.serializer.serialize([
            [1, config],
            [2, lcoeConfig],
        ]);
        const result: ExportedFileDescription = {
            name: "CostSetup" + projectIdStr,
            file: bytes,
            extension: "PVFARMCOST",
        };
        return [result];
    }
}
