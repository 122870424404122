import type { Object3D } from '../3rdParty/three';
import { Group } from '../3rdParty/three';
import type { FrustumExt } from '../structs/FrustumExt';
import type { RaySection } from '../structs/RaySection';
import type { GizmoIntersection } from './GizmoIntersection';


export abstract class GizmoBase<TInters extends GizmoIntersection> extends Group {
	
	constructor() {
		super();
	}

	abstract version(): number;

	getRenderList(objs: Object3D[]) {
		objs.push(this);
	}

	update(_camera:FrustumExt, _ray:RaySection) {
	}

	resetMaterials() {
	}

	setHover(ints: TInters | null): void {
		
	}

	abstract setContrastColor(white:number): void;

	abstract dispose(): void;
	
}