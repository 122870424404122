import type { DC_CNSTS } from "..";
import { BimProperty } from "../bimDescriptions/BimProperty";
import type { WireAmpacity } from "../constants/awg-gauges";
import { WireGauge } from "../constants/awg-gauges";
import { NumberProperty } from "../properties/PrimitiveProps";
import type { SceneInstance } from "../scene/SceneInstances";
import type { AssetId } from "./AssetCollection";
import type { Catalog } from "./Catalog";

export class GaugePack {
    gauges = new Map<AssetId, WireGauge>();
    gaugeIds = new WeakMap<WireGauge, AssetId>();
    sortedGauges: WireGauge[] = [];

    constructor(
        public catalog: Catalog,
        public wireSpecType: string | string[] = 'lv-wire-spec',
    ) {
        const siTypes = Array.isArray(wireSpecType) ? new Set(wireSpecType) : new Set([wireSpecType]);
        // gather all wires
        for (const assetId of catalog.assets.perId.keys()) {
            const instanceType = catalog.assets.sceneInstancePerAsset
                .getAssetAsSceneInstance(assetId)?.type_identifier
            if (!siTypes.has(instanceType ?? '')) continue;
            const assetSi = catalog.assets.sceneInstancePerAsset.getAssetAsSceneInstance(
                assetId
            )
            if (!assetSi) continue;
            const wire = GaugePack.parseSiToWireGauge(assetSi);
            if (!wire) continue;
            this.gauges.set(assetId, wire);
            this.gaugeIds.set(wire, assetId);
            this.sortedGauges.push(wire);
        }
        // sort by material and resistivity
        this.sortedGauges.sort((l, r) => {
            const comparedByMaterial = l.material.localeCompare(r.material);
            if (comparedByMaterial !== 0) return comparedByMaterial;
            const comparedByDcResistivity =
                r.stc.acResistivity.as(l.stc.acResistivity.unit) -
                l.stc.acResistivity.as(l.stc.acResistivity.unit);
            return comparedByDcResistivity;
        })
    }
    getNextGauge(gaugeId: number): [gaugeId: number, gauge: WireGauge] | null {
        const gauge = this.gauges.get(gaugeId);
        if (!gauge) return null;
        const curIndex = this.sortedGauges.indexOf(gauge);
        if (curIndex < 0) return null;
        const nextGauge = this.sortedGauges[curIndex + 1];
        const nextGaugeId = this.gaugeIds.get(nextGauge);
        if (
            !nextGauge ||
            nextGauge.material !== gauge.material ||
            nextGaugeId === undefined
        ) return null;

        return [nextGaugeId, nextGauge];
    }
    getGaugeBasedOnCurrentAndMaterial(
        minCurrentAmper: number,
        initialGauge: AssetId,
        temperatureParams: DC_CNSTS.TemperatureConductorParams,
    ): [AssetId, WireGauge] {
        let gaugeId: AssetId = initialGauge;
        while (true) {
            const curGauge = this.gauges.get(gaugeId);
            if (!curGauge) throw new Error('gauge not found');
            const amp = curGauge.approxAmpacityByTemperature(
                NumberProperty.new({
                    value: temperatureParams.temperature,
                    unit: 'C',
                }),
                temperatureParams.isBuried
            )
            if (!amp) throw new Error('amp is not found');
            if (gaugeId && curGauge && amp.as('A') > minCurrentAmper)
                break;
            const nextGauge = this.sortedGauges[this.sortedGauges.indexOf(curGauge) + 1];
            if (!nextGauge || nextGauge.material !== curGauge.material)
                break;
            const result = this.gaugeIds.get(nextGauge);
            if (result === undefined) throw new Error('gauge not found');
            return [result, nextGauge];
        }
        const gauge = this.gauges.get(gaugeId);
        if (!gauge) throw new Error('gauge not found');
        return [gaugeId, gauge];
    }
    static parseSiToWireGauge(si: SceneInstance): WireGauge | null {
        const gauge = si.properties.get('wire | gauge')?.asText();
        const material = si.properties.get('wire | material')?.asText();
        const diameter = si.properties.get('wire | diameter');
        const stcTemperature = si.properties.get('wire | stc | temperature');
        const stcReactance = si.properties.get('wire | stc | reactance');
        const stcDcResistivity = si.properties.get('wire | stc | dc resistivity');
        const stcAcResistivity = si.properties.get('wire | stc | ac resistivity');
        const amps: WireAmpacity[] = [];

        const ampLength = si.properties.get('wire | ampacity | length')?.asNumber() ?? 0;

        for (let i = 0; i < ampLength; i++) {
            const basePath = ['wire', 'ampacity', 'items', '' + (i + 1)]
            const buried = si.properties.get(BimProperty.MergedPath([...basePath, 'buried']));
            const freeAir = si.properties.get(BimProperty.MergedPath([...basePath, 'free air']));
            const temperature = si.properties.get(BimProperty.MergedPath([...basePath, 'temperature']));
            if (!buried || !freeAir || !temperature) return null;
            amps.push({
                buriedAmpacity: NumberProperty.new({
                    unit: buried.unit ?? '',
                    value: buried.asNumber(),
                }),
                freeAirAmpacity: NumberProperty.new({
                    unit: freeAir.unit ?? '',
                    value: freeAir.asNumber(),
                }),
                temperature: NumberProperty.new({
                    unit: temperature.unit ?? '',
                    value: temperature.asNumber(),
                }),
            })
        }

        if (
            gauge === undefined || material === undefined || !diameter || !stcAcResistivity ||
            !stcDcResistivity || !stcTemperature || !stcReactance
        ) return null;
        return new WireGauge({
            diameter: NumberProperty.new({
                unit: diameter.unit ?? '',
                value: diameter.value,
            }),
            stc: {
                acResistivity: NumberProperty.new({
                    unit: stcAcResistivity.unit ?? '',
                    value: stcAcResistivity.asNumber(),
                }),
                dcResistivity: NumberProperty.new({
                    unit: stcDcResistivity.unit ?? '',
                    value: stcDcResistivity.asNumber(),
                }),
                reactance : NumberProperty.new({
                    unit: stcReactance.unit ?? '',
                    value: stcReactance.asNumber(),
                }),
                temperature: NumberProperty.new({
                    unit: stcTemperature.unit ?? '',
                    value: stcTemperature.asNumber(),
                })
            },
            name: gauge,
            ampacities: amps,
            material,
        });
    }
}
