import { LazyDerived, LazyDerivedAsync, ScopedLogger, Success, preferPreviousOverInProgressWrapper } from "engine-utils-ts"
import type { Bim, IdBimScene, LayoutEquipment, MetricsGroup, PowerMetricsType, SceneInstance} from "src";
import { AreaTypeEnum, BimProperty, NumberProperty, PowerMetricsCalculator, SceneObjDiff } from "src"
import type { NamedBimPropertiesGroup } from "src/bimDescriptions/NamedBimPropertiesGroup"

const StationProps = {
    dcPower: BimProperty.NewShared({
        path: ['circuit', 'aggregated_capacity', 'dc_power'],
        value: 0,
        unit: 'W',
    })
} satisfies NamedBimPropertiesGroup

export function createTotalDCLazy(bim: Bim) {
    const lazyListOfInstances = bim.instances.getLazyListOfCollection({
        relevantUpdateFlags: SceneObjDiff.NewProps | SceneObjDiff.LegacyProps
    });
    const layoutEquipmentLazy = LazyDerived.new1<
        LayoutEquipment,
        Array<[IdBimScene, SceneInstance]>
    >(
        'layoutEquipmentLazy',
        [],
        [lazyListOfInstances],
        ([instances]) => {
            return {
                areas: [{
                    type: AreaTypeEnum.Total,
                    equipment: instances.map(x => x[0]),
                    id: '<empty-id>',
                    name: '<empty-name>',
                }]
            }
        }
    )

    const powerMetrics = new PowerMetricsCalculator(
        layoutEquipmentLazy,
        bim,
        bim.keyPropertiesGroupFormatter,
        new ScopedLogger('totalDC'),
    );
    const totalDCAsync = preferPreviousOverInProgressWrapper(LazyDerivedAsync.new1<
        NumberProperty,
        MetricsGroup<PowerMetricsType>[]
    >(
        'totalDC',
        [],
        [powerMetrics.calculator],
        function* ([powerMetrics]) {
            const value = powerMetrics
            const result = value[0].metrics?.dc_total;
            return NumberProperty.new({ value: result?.as('W') ?? 0, unit: 'W' });
        }
    ))
    const totalDC = LazyDerived.new1(
        'totalDC',
        [],
        [totalDCAsync],
        ([totalDCAsync]) => {
            if (!(totalDCAsync instanceof Success)) {
                return NumberProperty.new({ value: 0, unit: 'W' });
            }
            return totalDCAsync.value;
        }
    )
    return totalDC;
}
