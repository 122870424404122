import type {
	Disposable, EventStackFrame, ObservableStream} from 'engine-utils-ts';
import { LegacyLogger, NamedEvents,
	unsafePopFromEventStackFrame, unsafePushToEventStackFrame,
} from 'engine-utils-ts';
import type { Vector3, Vector4 } from 'math-ts';
import { Matrix4, Vector2 } from 'math-ts';

import type { Camera, WebGLRendererParameters } from './3rdParty/three';
import { Mesh } from './3rdParty/three';
import { RendererExt } from './composer/RendererExt';
import type { KrMaterial } from './materials/KrMaterial';
import { Time } from './time/TIme';

export abstract class EngineLoopBase {

	readonly namedEvents: NamedEvents = new NamedEvents();
	readonly dispose_notification: ObservableStream<void>;
	
	readonly container: HTMLElement;
	private readonly _onWindowResize: EventListener;

	readonly htmlSize: Vector2 = new Vector2();
	
	readonly time = new Time();

	_framesCounter: number = 0;

	private _isDisposed: boolean = false;

	erroneousFramesCounter: number = 0;
	
	private readonly _resizeEvent: ObservableStream<any>;
	private readonly _engineLoopEventsSource: Readonly<Partial<EventStackFrame>> = Object.freeze({
		identifier: 'engine_loop',
	});

	_reqAnimCallback: (t: number) => void;
	protected lastRequestedAnimationFrameId: number;
	
	constructor(domContainer: HTMLElement) {
		this.container = domContainer;
		this.dispose_notification = this.namedEvents.registerEvent('dispose');
		this._resizeEvent = this.namedEvents.registerEvent('resize');

		this._onWindowResize = (event?:Event) => {
			const containerWidth = this.container.clientWidth;
			const containerHeight = this.container.clientHeight;
			this._callResizeEvent(containerWidth, containerHeight);
		};
		window.addEventListener('resize', this._onWindowResize);

		this._reqAnimCallback = (t: number) => this.mainLoop(t);
		// bounce resize update to next frame to allow inherited constructor to initialize engine instance
		requestAnimationFrame(_ => this._onWindowResize(null!));
		this.lastRequestedAnimationFrameId = requestAnimationFrame(this._reqAnimCallback);
	}

	protected abstract _loopImpl(timeStamp: number): void;

	mainLoop(timeStamp: number) {
		if (this._isDisposed) {
			return;
		}
		this._framesCounter += 1;
		if (this.erroneousFramesCounter > 20) {
			LegacyLogger.error('HARDWIRED TO SELF-DESTRUCT \\m/');
			this.dispose();
		}
		const pushed = unsafePushToEventStackFrame(this._engineLoopEventsSource);
		this.lastRequestedAnimationFrameId = requestAnimationFrame(this._reqAnimCallback);

		try {
			this.time.updateTime(timeStamp);
			this._loopImpl(timeStamp);
			this.time.startNewFrame();
		} catch (e) {
			this.erroneousFramesCounter += 1;
			LegacyLogger.error('engine frame error ', e);
		} finally {
			unsafePopFromEventStackFrame(pushed);
		}
	}

	addListener(eventName: string, callback: Function): Disposable {
		return this.namedEvents.legacy_subscribe(eventName, callback);
	}

	private _callResizeEvent(x: number, y: number) {
		if (x == this.htmlSize.x && y == this.htmlSize.y) {
			LegacyLogger.log('engine resize: size is the same as current, doing nothing');
		}
		this.htmlSize.set(x, y);
		this._resizeEvent.notify_later_legacy(x, y);
	}

	resize(x_opt?: number, y_opt?: number) {
		
		if (x_opt && y_opt && (x_opt > 0) && (y_opt > 0)) {
			x_opt = Math.round(x_opt);
			y_opt = Math.round(y_opt);
			this._callResizeEvent(x_opt, y_opt);
		} else {
			this._onWindowResize(null!);
		}
	}

	abstract clear() : void;

	dispose() {
		if (this._isDisposed) {
			return;
		}
		this._isDisposed = true;
		try {
			this.clear();
		} catch (e) {
			LegacyLogger.error('error during clear', e);
		}
		window.removeEventListener('resize', this._onWindowResize);
		this._dispose();
		this.dispose_notification.notify_later_legacy();
		this.namedEvents.clear();
	}

	abstract _dispose() : void;

	isDisposed() {
		return this._isDisposed;
	}
}

export abstract class RenderingEngineBase extends EngineLoopBase {

	readonly renderer: RendererExt;
	superSamplingFactor: number = 1;

	private readonly glContextLostCallback: () => void;
	private readonly glContextRestoredCallback: () => void;

	constructor(domContainer: HTMLElement, rendererParams: WebGLRendererParameters) {
		super(domContainer);

		this.renderer = new RendererExt(rendererParams);
		this.renderer.autoClear = false;
		// this.renderer.autoClearColor = false;
		this.renderer.autoClearDepth = false;
		// this.renderer.autoClearStencil = false;

		this.glContextLostCallback = () => this.onGlContextLost();
		this.glContextRestoredCallback = () => this.onGlContextRestored();
		this.renderer.addGlContextEventListeners(this.glContextLostCallback, this.glContextRestoredCallback);
		this.container.appendChild(this.renderer.domElement);

		this.namedEvents.legacy_subscribe('resize', (width: number, height: number) => {
			this.renderer.domElement.style.width = width + 'px';
			this.renderer.domElement.style.height = height + 'px';
	
			const { x, y } = this.getResolutionConsideringDevicePixelRatio(width, height);
			this.renderer.setSize(x, y, false);
		});
	}

	
	getResolutionConsideringDevicePixelRatio(x: number, y: number): { x: number, y: number } {
		const devicePixelRatio = (window.devicePixelRatio || 1);

		const trueX = (x * devicePixelRatio) | 0;
		const trueY = (y * devicePixelRatio) | 0;

		let supersampling: number;
		if (devicePixelRatio >= 3) {
			supersampling = 0.5;
		} else if (devicePixelRatio >= 2) {
			supersampling = 0.75;
		} else if (devicePixelRatio >= 1.5) {
			supersampling = 0.8;
		} else if (devicePixelRatio >= 1.2) {
			supersampling = 1.0;
		} else {
			supersampling = 1.2;
		}

		this.superSamplingFactor = supersampling;

		x = (trueX * this.superSamplingFactor) | 0;
		y = (trueY * this.superSamplingFactor) | 0;
		return { x, y };
	}

	_dispose() {
		this.renderer.removeGLContextEventListeners(this.glContextLostCallback, this.glContextRestoredCallback);
		this.renderer.dispose();
		this.renderer.forceContextLoss();
		this.container.removeChild(this.renderer.domElement);
	}

	protected onGlContextLost() {
		cancelAnimationFrame(this.lastRequestedAnimationFrameId);
	}

	protected onGlContextRestored() {
		this.lastRequestedAnimationFrameId = requestAnimationFrame(this._reqAnimCallback);
	}
}

EngineLoopBase.prototype['addListener'] = EngineLoopBase.prototype.addListener;
EngineLoopBase.prototype['resize'] = EngineLoopBase.prototype.resize;
EngineLoopBase.prototype['clear'] = EngineLoopBase.prototype.clear;
EngineLoopBase.prototype['dispose'] = EngineLoopBase.prototype.dispose;



export class MeshDyno extends Mesh {

	material: KrMaterial | null = null;
	modelViewProjMatrix : Matrix4 = new Matrix4();

	dynamicUniforms: Map<string, Vector2 | Vector3 | Vector4> = new Map();

	constructor() {
		super(null, null);
	}
}

export function updateMeshesMatrices(camera: Camera, objects: MeshDyno[]) {
	const defaultMvp = new Matrix4();
	defaultMvp.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);

	for (const obj of objects) {
		obj.modelViewMatrix.multiplyMatrices( camera.matrixWorldInverse, obj.matrixWorld );
		obj.normalMatrix.getNormalMatrix( obj.modelViewMatrix );
		obj.modelViewProjMatrix.multiplyMatrices( camera.projectionMatrix, obj.modelViewMatrix);
	}
}


