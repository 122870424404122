<script lang="ts">
    import type { UnitsMapper, ValueAndUnit } from "bim-ts";
    import { getContext } from 'svelte';
    import type { Readable } from 'svelte/store';
    import { ObjectUtils } from "engine-utils-ts";
    import flash from "../../pui/flash";
    import { MetricPropertyNode, formatMetricValue } from "./MetricProperty";
    import { CheckboxStandard } from "../../libui/checkbox";


    export let sourceProperty: MetricPropertyNode;
    export let offset: number;
    export let isGroup: boolean = false;
 
    $:unitsMapper = getContext('unitsMapper') as Readable<UnitsMapper>;


    $:mappedValues = sourceProperty.value.map(v => {
        if(typeof v === 'number'){
            return $unitsMapper.mapToConfigured({value: v, unit: sourceProperty.unit})
        }
        return v;
    });
    function isUnitValue(value: any): boolean {
        return typeof value === 'object' 
            && value != null 
            && 'value' in value 
            && typeof value.value === 'number';
    }
    $:perValueColor = mappedValues.map(value => {
        if(sourceProperty.colorRelativeIndex === undefined){
            return undefined;
        }
        const baseValue = mappedValues[sourceProperty.colorRelativeIndex];
        if(isUnitValue(value) && isUnitValue(baseValue)){
            const v1 = (value as ValueAndUnit).value;
            const v2 = (baseValue as ValueAndUnit).value;
            if(v1 === v2){
                return undefined;
            }
            return v1 > v2 ? 'green' : 'red';
        }
        return undefined;
    });

    let div: HTMLElement;

    let prevValue = sourceProperty.value;
    $: if (!ObjectUtils.areObjectsEqual(prevValue, sourceProperty.value)) {
        prevValue = sourceProperty.value;
        flash(div);
    }

    $:areMultipleValues = mappedValues.length > 1;
    $:boldText = sourceProperty.isHeader || isGroup;
</script>

<style lang="scss">
    .metric-value-group {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
        text-align: end;
        width: 100%;
        &.multi-columns {
            min-width: 120px;
            max-width: 120px;
        }
    }

    .metrics-value {
        text-align: end;
    }

    .header {
        text-transform: uppercase;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .metrics-view {
        width: 100%;
    }

    .property-group-label {
        width: 100%;
    }

    .property-name-multi {
        margin-left: var(--offset);
        max-width: 230px;
        min-width: 230px;
    }

    .property-value-single {
        width: 100%;
    }

    .property-value-multi {
        display: flex;
        align-content: space-between;
        width: fit-content; 
        gap: 1px;
    }

    .metrics-view :global(.ui-config-property.property-row .property-value .read-only) {
        padding: 0px;
        gap: 10px;
    }

    .metrics-view :global(.ui-config-property.property-row .property-value) {
        display: flex;
        gap: 0px;
        flex-direction: row-reverse;
        width: 100%;
    }
</style>

<div
    class="metrics-view"
    style="--offset:{offset}px;"
>
    <div 
        class="ui-config-property property-row" 
        >
        <div bind:this={div} 
        class="property-name"
        class:text-main-medium={!boldText}
        class:property-name-multi={true}
        >
            <div
                class:property-name-label={!isGroup}
                class:property-group-label={isGroup}
                class:mdc-typography--body1={!boldText}
                class:mdc-typography--overline={boldText}
            >
                {sourceProperty.name}
            </div>
        </div>
    
        <div class="property-value">
            <div
                class="read-only calculated"
                class:mdc-typography--body1 ={!boldText}
                class:mdc-typography--overline ={boldText}
                class:property-value-multi={areMultipleValues}
                class:property-value-single={!areMultipleValues}
            >
                {#each mappedValues as valueUnit, idx}
                    {@const color = perValueColor[idx]}
                    <span
                        class="metric-value-group"
                        class:multi-columns = {areMultipleValues}
                    >
                        {#if sourceProperty.onClickCheckBox}
                            <CheckboxStandard 
                                value={idx === sourceProperty.colorRelativeIndex}
                                onChange={() => sourceProperty.onClickCheckBox?.(idx)}
                            />
                        {/if}
                        <span 
                            class="metrics-value"
                            class:header={sourceProperty.isHeader}
                            style="color: {color || 'inherit'}"
                        >
                            {formatMetricValue(valueUnit, sourceProperty.decimals)}
                        </span>
                    </span>
                {/each}
            </div>
        </div>
    </div>
</div>
