import type { ScopedLogger } from 'engine-utils-ts';
import { Aabb, EmptyBox, KrMath } from 'math-ts';

import type { ClipBox } from '../clipbox/ClipBox';
import { AllocationSynchronizer } from '../memory/AllocationSynchronizer';
import type { Handle} from '../memory/Handle';
import { HandleIndexMask } from '../memory/Handle';
import {
	createAndBindBinaryAllocator, createAndBindManagedAllocator,
} from '../memory/MemoryUtils';
import type { EnumsGateway } from '../structs/EnumsGateway';
import { KrBoundsGateway } from '../structs/KrBoundsGateway';
import { BoundsSceneWrap } from './BoundsSceneWrap';
import type { OneToManyHandles } from './OneToManyHandles';
import type { LodMask, Submeshes2, SubmeshHandle } from './Submeshes2';

export type LodGroupHandle = Handle & LodGroups;

export type LodGroupSubmeshRef = number; // index and lod mask

function newLodGroupSubmeshRef(submeshHandle: SubmeshHandle, submeshLodMask: LodMask): LodGroupSubmeshRef {
	const submeshIndex = submeshHandle & HandleIndexMask;
	const lodMask = (submeshLodMask & 0xF) << 26;
	return submeshIndex | lodMask;
}
export function lodMaskFromSubmeshLodRef(lodRef: LodGroupSubmeshRef): LodMask {
	return ((lodRef & (0xF << 26)) >> 26) & 0xF;
}
export function submeshIndexFromSubmeshLodRef(lodRef: LodGroupSubmeshRef): number {
	return lodRef & HandleIndexMask;
}

export type LodGroupAndSubmeshes = [LodGroupLocalIdent, ...LodGroupSubmeshRef[]];



export class LodGroups {
	
	readonly logger: ScopedLogger;

	readonly allocSyncer: AllocationSynchronizer<LodGroupHandle, LodGroupFullId>;

	readonly scene: BoundsSceneWrap<LodGroupHandle>;
	readonly bounds: KrBoundsGateway;

	readonly idsPerHandleIndex: LodGroupFullId[];

	readonly submeshesRefs: LodGroupAndSubmeshes[];

	readonly idsToHandles: Map<LodGroupFullId, LodGroupHandle> = new Map();

	readonly _submeshesBoundsRef: Readonly<KrBoundsGateway>;
	readonly _submeshesLodsGroupsRefs: OneToManyHandles<LodGroupFullId, SubmeshHandle>;
	readonly _submeshesLodMasks: EnumsGateway<LodMask|0, Uint8Array>;

	_toRecheck: LodGroupFullId[] = [];

	constructor(
		submeshes: Submeshes2,
		clipbox: ClipBox,
	) {
		this.logger = submeshes._logger.newScope('lod-groups');
		this.allocSyncer = new AllocationSynchronizer('lod-groups', this.logger);

		this.scene = new BoundsSceneWrap(clipbox, 0.);
		this.bounds = new KrBoundsGateway('lods-bounds');
		createAndBindBinaryAllocator<Aabb, Float64Array, KrBoundsGateway, LodGroupFullId>(
			this.allocSyncer,
			this.bounds,
			_args => EmptyBox,
			size => this.scene.reallocateBoundsBuffer(size),
			(b, i) => b.emptyOut(i),
		);

		this.idsPerHandleIndex = createAndBindManagedAllocator(
			this.allocSyncer,
			t => t,
			t => LodGroupFullIdInvalid,
		);
		this.submeshesRefs = createAndBindManagedAllocator(
			this.allocSyncer,
			(id) => [id.groupIdent] as LodGroupAndSubmeshes,
			(groupDescr) => {groupDescr.length = 0; return groupDescr},
		);

		this._submeshesBoundsRef = submeshes.bounds;
		this._submeshesLodsGroupsRefs = submeshes.lodGroupsRefs;
		this._submeshesLodMasks = submeshes.lodMasks;

	}

	getLodGroupId(parent: Handle, groupIdent: LodGroupLocalIdent): LodGroupFullId {
		const fullId = LodGroupFullId.new(parent, groupIdent);
		this.idsToHandles.get(fullId);
		this._toRecheck.push(fullId);
		return fullId;
	}

	markDirtyById(id: LodGroupFullId) {
		this._toRecheck.push(id);
	}

	applyUpdates() {
		if (this._toRecheck.length === 0) {
			return;
		}

		const toAlloc = new Set<LodGroupFullId>();
		const toUpdate = new Map<LodGroupFullId, LodGroupHandle>();

		for (const id of this._toRecheck) {
			const h = this.idsToHandles.get(id);
			if (h === undefined) {
				toAlloc.add(id);
			} else {
				toUpdate.set(id, h);
			}
		}
		this._toRecheck.length = 0;

		const toDelete: LodGroupHandle[] = [];

		const submeshBoundsReused = Aabb.empty();
		const groupBoundsReused = Aabb.empty();
		const updateLodGroupDescr = (h: LodGroupHandle, submeshesHandles: SubmeshHandle[]) => {
			const groupDescr = this.submeshesRefs[h & HandleIndexMask];
			groupDescr.length = 1;
			groupBoundsReused.makeEmpty();
			let allSubmeshesMasks: LodMask|0 = 0;
			for (const sh of submeshesHandles) {
				submeshBoundsReused.makeEmpty();
				this._submeshesBoundsRef.toStruct(sh & HandleIndexMask, submeshBoundsReused);
				groupBoundsReused.union(submeshBoundsReused);
				const lodMask = this._submeshesLodMasks.buffer[sh & HandleIndexMask];
				const submeshRef = newLodGroupSubmeshRef(sh, lodMask);
				groupDescr.push(submeshRef);
				allSubmeshesMasks |= lodMask;
			}
			// if ((allSubmeshesMasks & LodMask.All) !== LodMask.All) {
			// 	LegacyLogger.deferredWarn('lods group doesnt have submeshes for all lods', submeshesHandles);
			// }
			this.bounds.toBuffer(groupBoundsReused, h & HandleIndexMask);
			this.scene.markDirty(h);
		}

		for (const [id, h] of toUpdate) {
			const submeshesHandles = this._submeshesLodsGroupsRefs.getReferencedAsArray(id);

			if (submeshesHandles.length === 0) {
				toDelete.push(h);
			} else {
				updateLodGroupDescr(h, submeshesHandles);
			}

		}

		if (toDelete.length > 0) {
			const idsPerHandle = new Map<LodGroupHandle, LodGroupFullId>();
			for (const h of toDelete) {
				const id = this.idsPerHandleIndex[h & HandleIndexMask]
				idsPerHandle.set(h, id);
			}
			const {removed} = this.allocSyncer.delete(toDelete);
			for (const h of removed) {
				this.scene.markDirty(h);
				const id = idsPerHandle.get(h)!;
				this.idsToHandles.delete(id);
			}
		}

		const toAllocIds: LodGroupFullId[] = [];
		for (const id of toAlloc) {
			const submeshes = this._submeshesLodsGroupsRefs.getReferencedAsArray(id);
			if (submeshes.length === 0) {
				continue;
			}
			toAllocIds.push(id);
		}

		const handles = this.allocSyncer.allocate(toAllocIds);

		for (const h of handles) {
			const id = this.idsPerHandleIndex[h & HandleIndexMask];
			this.idsToHandles.set(id, h);

			const submeshesHandles = this._submeshesLodsGroupsRefs.getReferencedAsArray(id);
			updateLodGroupDescr(h, submeshesHandles);
		}
		
		this.scene.updateWasmSceneHierarchy();
	}

}

export type LodGroupLocalIdent = number; // group id and detail size combined

const LodToIntDiv = 0.05; // 5cm discrete

export function newLodGroupLocalIdent(groupLocalId: number, detailSize: number): LodGroupLocalIdent {

	const detailSize12bits = KrMath.clamp(detailSize / LodToIntDiv,  1, 0xFFF); // 12 bits on lod size
	const groupId = groupLocalId & 0xFFFF; // 16 bits for group id

	return (groupId << 12) | detailSize12bits;

}
export function getLodGroupDetailSize(lodGroup: LodGroupLocalIdent): number {
	return (lodGroup & 0xFFF) * LodToIntDiv;
}

export class LodGroupFullId {

	private constructor(
		readonly parent: Handle,
		readonly groupIdent: LodGroupLocalIdent,
	) {
	}

	public static new(
		objHandle: Handle,
		groupIdent: number,
	) {
		if (_lastFullId && (_lastFullId.groupIdent === groupIdent && _lastFullId.parent === objHandle)) {
			return _lastFullId;
		}
		const asStr = `${objHandle | 0}-${groupIdent | 0}`;
		let fullId = _fullIdsStringCache.get(asStr);
		if (fullId === undefined) {
			fullId = new LodGroupFullId(objHandle, groupIdent);
			_fullIdsStringCache.set(asStr, fullId);
		}
		_lastFullId = fullId;
		return fullId;
	}
}
let _lastFullId: LodGroupFullId | null = null;
const _fullIdsStringCache = new Map<string, LodGroupFullId>();

const LodGroupFullIdInvalid = LodGroupFullId.new(-1, -1);
