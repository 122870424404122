import type { ScopedLogger } from 'engine-utils-ts';
import { DefaultMap, LazyLambda, LegacyLogger, ObjectUtils } from 'engine-utils-ts';
import { Vector2 } from 'math-ts';

import type { WebGLRenderTargetOptions
} from '../3rdParty/three';
import {
    DepthTexture, FloatType, LinearFilter, NearestFilter, RedFormat, RGBAFormat, RGBFormat,
    WebGLRenderTarget
} from '../3rdParty/three';
import type { RendererExt } from '../composer/RendererExt';
import Utils from '../utils/Utils';

export type RenderTargetIdent = string
    | 'screen'
    | 'pre_screen'
    | 'pre_screen_2'
    | 'opaque_normals'
    | 'opaque_color'
    | 'opaque_lit'
    | 'edges_std'
    | 'edges_transp'
    | 'overlay'
    | 'edges_overlay'
    | 'temp_rgba_0'
    | 'temp_rgba_1'
    | 'temp_rgba_2'
    | 'temp_rgb_0'
    | 'temp_rgb_1'
	| 'temp_rgb_2'
	| 'temp_r_1'
    | 'temp_r_2';


const stdOptionsPerRTIdent = new LazyLambda<Map<string, Partial<WebGLRenderTarget>>>(() => {
	const optionsPerRTIdent = new Map<string, Partial<WebGLRenderTarget>>();

	{
		optionsPerRTIdent.set('scr', {});
	}

	{
		const options: Partial<WebGLRenderTargetOptions> = {
			minFilter: LinearFilter,
			magFilter: LinearFilter,
			format: RGBAFormat,
			stencilBuffer: false,
			generateMipmaps: false,
		};
		optionsPerRTIdent.set('pre_screen', options);
	}

	{
		const options: Partial<WebGLRenderTargetOptions> = {
			minFilter: NearestFilter,
			magFilter: NearestFilter,
			format: RGBAFormat,
			stencilBuffer: false,
			generateMipmaps: false,
			
		};
		optionsPerRTIdent.set('opaque_color', options);
		optionsPerRTIdent.set('overlay', options);
	}

	{
		const options: Partial<WebGLRenderTargetOptions> = {
			minFilter: NearestFilter,
			magFilter: NearestFilter,
			format: RGBAFormat,
			stencilBuffer: false,
			generateMipmaps: false,
		};
		optionsPerRTIdent.set('opaque_normals', options);
		optionsPerRTIdent.set('opaque_lit', options);
		optionsPerRTIdent.set('aa_accumulator', options);
	}

	
	{
		const options: Partial<WebGLRenderTargetOptions> = {
			minFilter: NearestFilter,
			magFilter: NearestFilter,
			format: RGBAFormat,
			type: FloatType,
			stencilBuffer: false,
			generateMipmaps: false,
		};
		optionsPerRTIdent.set('aa_accumulator', options);
	}

	{
		const options: Partial<WebGLRenderTargetOptions> = {
			minFilter: LinearFilter,
			magFilter: LinearFilter,
			// format: ident.includes('overlay') ? RGBAFormat : RedFormat,
			format: RedFormat,
			stencilBuffer: false,
			generateMipmaps: false,
		};
		optionsPerRTIdent.set('edges_', options);
	}

	{
		const options: Partial<WebGLRenderTargetOptions> = {
			minFilter: NearestFilter,
			magFilter: NearestFilter,
			format: RGBAFormat,
			stencilBuffer: false,
			generateMipmaps: false,
		};
		optionsPerRTIdent.set('temp_rgba_', options);
	}

	{
		const options: Partial<WebGLRenderTargetOptions> = {
			minFilter: NearestFilter,
			magFilter: NearestFilter,
			format: RGBFormat,
			stencilBuffer: false,
			generateMipmaps: false,
		};
		optionsPerRTIdent.set('temp_rgb_', options);
	}

	{
		const options: Partial<WebGLRenderTargetOptions> = {
			minFilter: NearestFilter,
			magFilter: NearestFilter,
			format: RedFormat,
			stencilBuffer: false,
			generateMipmaps: false,
		};
		optionsPerRTIdent.set('temp_r_', options);
	}

	return optionsPerRTIdent;
});


function getParamsByIdent(ident: RenderTargetIdent): Partial<WebGLRenderTargetOptions>  {

	const optionsPerRTIdent = stdOptionsPerRTIdent.poll();
	
	let bestPrefixFit: string | null = null;
	for (const key of optionsPerRTIdent.keys()) {
		if (ident.startsWith(key)
			&& (bestPrefixFit === null || bestPrefixFit.length < key.length)
		) {
			bestPrefixFit = key;
		}
	}

	if (bestPrefixFit === null) {
		LegacyLogger.error('unknown render target identifier, passing default params', ident);
		return {
			minFilter: NearestFilter,
			magFilter: NearestFilter,
			format: RGBAFormat,
			stencilBuffer: false,
			generateMipmaps: false,
		}
	}

	return optionsPerRTIdent.get(bestPrefixFit)!;
}



export class RenderTargetState {
	version: number = 1;
}


export class RTIdent {
	readonly ident: RenderTargetIdent;
	readonly scale: Vector2;
	readonly depth: 'own' | RenderTargetIdent | null; // own or shared with or none

	constructor(name: RenderTargetIdent, scale: Vector2, depth: 'own' | RenderTargetIdent | null) {
		this.ident = name;
		this.scale = scale;
		this.depth = depth;
	}

	static simple(name: RenderTargetIdent): RTIdent {
		return new RTIdent(name, new Vector2(1, 1), null);
	}
	static withDepth(name: RenderTargetIdent, depth: 'own' | RenderTargetIdent) {
		return new RTIdent(name, new Vector2(1, 1), depth);
	}
	static withScale(name: RenderTargetIdent, scale: Vector2) {
		return new RTIdent(name, scale, null);
	}

	equals(rhs: RTIdent): boolean {
		return this.ident == rhs.ident
			&& this.scale == rhs.scale
			&& this.depth == rhs.depth;
	}

	toString(): string {
		return `${this.ident}|${this.scale.x}-${this.scale.y}|${this.depth}`;
	}
}

export class RenderTargets {
	logger: ScopedLogger;
	renderer: RendererExt;
	
	rts = new Map<string, RenderTargetExt>();
	mrts = new Set<MRTHandle>();
	
	private _currScreenSize: Vector2 = new Vector2(200, 100);

    renderTargetsStates = new DefaultMap<RenderTargetIdent, RenderTargetState>(() => new RenderTargetState());

	private glContextLostCallback: () => void;
	private glContextRestoredCallback: () => void;

	constructor(logger: ScopedLogger, renderer: RendererExt) {
		this.logger = logger;
		this.renderer = renderer;
		this.glContextLostCallback = () => this.onGlContextLost();
		this.glContextRestoredCallback = () => this.onGlContextRestored();
		this.renderer.addGlContextEventListeners(this.glContextLostCallback, this.glContextRestoredCallback);
	}

	dispose() {
		this.renderer.removeGLContextEventListeners(this.glContextLostCallback, this.glContextRestoredCallback);

		for (const [key, rt] of this.rts) {
			this.rts.delete(key);
			rt.dispose();
		}
		for (const m of this.mrts) {
			this.mrts.delete(m);
			m.dispose(this.renderer);
		}
	}

	updateScreenSize(screenSize: Vector2): boolean {
		if (this._currScreenSize.equals(screenSize)) {
			return false;
		}
		this._currScreenSize.copy(screenSize);
		for (const [key, rt] of this.rts) {
			this.rts.delete(key);
			rt.dispose();
		}
		for (const m of this.mrts) {
			if (m.shouldBeDisposed()) {
				this.mrts.delete(m);
				m.dispose(this.renderer);
			}
		}
		return true;
	}
	peekCurrScreenSize(): Readonly<Vector2> {
		return this._currScreenSize;
	}
	peekCurrScreenSizeScaled(scale: Vector2): Vector2 {
		const s = this.peekCurrScreenSize().clone();
		s.multiply(scale);
		return s;
	}

	_findRtByIdentAndScale(name: string, scale: Vector2): RenderTargetExt | undefined {
		for (const rt of this.rts.values()) {
			const ident = rt.ident;
			if (ident.ident == name && ObjectUtils.areObjectsEqual(ident.scale, scale)) {
				return rt;
			}
		}
		return undefined;
	}

	getRT(ident: RTIdent) {
		const str = ident.toString();
		let rt = this.rts.get(str);
		if (!rt) {
			const size = this.peekCurrScreenSizeScaled(ident.scale);
			const params = getParamsByIdent(ident.ident);
			if (ident.depth == 'own') {
				params.depthBuffer = true;
				params.depthTexture = new DepthTexture(size.x, size.y, FloatType);
			} else if (typeof ident.depth == 'string') {
				params.depthBuffer = true;
				params.depthTexture = this.getRT(new RTIdent(ident.depth, ident.scale, 'own')).wrt.depthTexture;
			} else {
				params.depthBuffer = false;
			}
			const wrt = new WebGLRenderTarget(size.x, size.y, params);
			const sameRT = this._findRtByIdentAndScale(ident.ident, ident.scale);
			if (sameRT) {
				wrt.texture = sameRT.wrt.texture;
			}
			rt = new RenderTargetExt(ident, size, wrt);
			this.rts.set(str, rt);
		}
		return rt;
	}

	clearRT(rt: RenderTargetExt) {
		this.renderer.setRenderTarget(rt.wrt);
		this.renderer.clear(true, rt.ident.depth == 'own', false);
	}

	bindByIdent(ident: RTIdent) {
		const rt = this.getRT(ident);
		return this.bind(rt);
	}
	bindByIdents(idents: RTIdent[]) {
		if (idents.length == 1) {
			this.bindByIdent(idents[0]);
		} else if (idents.length > 1) {
			this.bindMrt(idents.map(id => this.getRT(id)));
		} else {
			this.resetBinding();
		}
	}
	resetBound() {
		this.renderer.setRenderTarget(null);
	}

	bind(rt: RenderTargetExt) {
		if (rt.ident.ident == 'screen') {
			this.renderer.setRenderTarget(null);
		} else {
			this.renderer.setRenderTarget(rt.wrt);
		}
		
		// this.renderer.setRenderTarget(null);
	}
	bindMrt(rts: RenderTargetExt[]) {
		let mrt: MRTHandle | null = null;
		for (const m of this.mrts) {
			if (Utils.areArraysEqual(m.sourcesRefs, rts)) {
				mrt = m;
				break;
			}
		}
		if (!mrt) {
			mrt = MRTHandle.allocateMrtFor(rts, this.renderer);
			this.mrts.add(mrt);
		}
		this.renderer.state.bindFramebuffer(this.renderer.context.FRAMEBUFFER, mrt.handle);
	}

	resetBinding() {
		this.renderer.setRenderTarget(null);
		this.renderer.state.bindFramebuffer(this.renderer.context.FRAMEBUFFER, null);
	}

	getVersionOf(rtIdent: RenderTargetIdent) {
		return this.renderTargetsStates.getOrCreate(rtIdent).version;
	}

	invalidateRt(rtIdent: RenderTargetIdent) {
		const s = this.renderTargetsStates.getOrCreate(rtIdent);
		s.version += 1;
	}

	private onGlContextLost() {
		for (const [key, rt] of this.rts) {
			this.rts.delete(key);
			rt.dispose();
		}
		for (const m of this.mrts) {
			this.mrts.delete(m);
			m.dispose(this.renderer);
		}
	}

	private onGlContextRestored() {

	}
}

let RTIds = 1;

export class RenderTargetExt {
	readonly ident: RTIdent;
	readonly size: Readonly<Vector2>;
	readonly wrt: WebGLRenderTarget;

	readonly id: number;
	isDisposed: boolean = false;

	constructor(ident: RTIdent, size: Vector2, rt: WebGLRenderTarget) {
		this.ident = ident;
		this.size = size;
		this.wrt = rt;
		this.id = (RTIds += 1)
	}

	dispose() {
		this.isDisposed = true;
		this.wrt.dispose();
	}
}

class MRTHandle {
	readonly sourcesRefs: Readonly<RenderTargetExt[]>;
	readonly handle: WebGLFramebuffer | null;

	id: number;
	isDisposed: boolean = false;

	constructor(handle: WebGLFramebuffer | null, rts: Readonly<RenderTargetExt[]>) {
		this.handle = handle;
		this.sourcesRefs = rts.slice();
		this.id = (RTIds += 1)
	}

	shouldBeDisposed(): boolean {
		return this.sourcesRefs.some(s => s.isDisposed);
	}

	dispose(renderer: RendererExt) {
		this.isDisposed = true;
		if (this.handle) {
			renderer.context.deleteFramebuffer(this.handle);
		}
	}


	static allocateMrtFor(rts: RenderTargetExt[], rend: RendererExt): MRTHandle {
		if (rts.length < 2) {
			throw new Error('mrt should have multiple targets');
		}
		// make sure textures are allocated
		for (const rt of rts) {
			this.bind(rt);
		}
		rend.resetRenderState();
		
		const gl = rend.context;

		var fb = gl.createFramebuffer();
		rend.state.bindFramebuffer(gl.FRAMEBUFFER, fb);
		
		let draw_buffers_consts: number[] = [];
		for (let i = 0; i < rts.length; ++i) {
			draw_buffers_consts.push((gl as any)[`COLOR_ATTACHMENT${i}`]);//gl.COLOR_ATTACHMENT0, etc..
		}
		gl.drawBuffers(draw_buffers_consts);
		if (rts[0].wrt.depthTexture) {
			gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.TEXTURE_2D,
				rend.properties.get(rts[0].wrt.depthTexture).__webglTexture, 0);
		}

		for (let i = 0; i < rts.length; ++i) {
			const wrt = rts[i].wrt;
			gl.framebufferTexture2D(gl.FRAMEBUFFER, (gl as any)[`COLOR_ATTACHMENT${i}`], gl.TEXTURE_2D,
				rend.properties.get(wrt.texture).__webglTexture, 0);
		}

		const status = gl.checkFramebufferStatus(gl.FRAMEBUFFER);
		if (status !== gl.FRAMEBUFFER_COMPLETE) {
			LegacyLogger.error('cant use mrt framebuffer, status ', status);
			// Can't use framebuffer.
			// See http://www.khronos.org/opengles/sdk/docs/man/xhtml/glCheckFramebufferStatus.xml
		}

		rend.state.bindFramebuffer(gl.FRAMEBUFFER, null);

		return new MRTHandle(fb, rts);
	}
}


