<script lang="ts">
    import type { PUI_OperationInProgressNode } from 'ui-bindings';
    import LinearProgress from '../reusable/linear-progress.svelte';
    
    export let property: PUI_OperationInProgressNode;

</script>


<div class="ui-config-property property-row bool-prop">
    <div class="property-name text-main-medium">
        <div class="property-name-label mdc-typography--body1">
            {property.name}
        </div>
    </div>
    <div class="notification-progress">
        <LinearProgress finished={false}></LinearProgress>
    </div>
</div>

<style lang="css">
    .property-row {
        margin: 4px 0;
    }
    .notification-progress {
        margin: 11px;
        width: 30%;
    }
</style>
