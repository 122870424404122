import type { IdBimScene, WireGauge } from 'bim-ts';
import { DC_CNSTS, EntitiesBase } from 'bim-ts';
import type { Vector2 } from 'math-ts';
import type { CablePairingType } from './models/route';

export class Cable {
    isBuried: boolean | null = null;

    constructor(
        public type: DC_CNSTS.ConductorType | null = null,
        public gauge: number | null = null,
        public gaugeName: string | null = null,
        public pairingType: CablePairingType | null = null,
        public constraints: DC_CNSTS.Constraints[] | null = null,
        public temperature: number = 0,
        public parent: IdBimScene | 0 = 0,
        public losses: number = 0,
        public drop: number = 0,
        public polyline: Vector2[] | null = null,
        public length: number = 0,
        public maxAmp: number = 0,
        public resistivity: number = 0,
        public gaugeSpec: WireGauge | null = null, 
        public operatingCurrent: number = 0,
        public maxCurrent: number = 0,
        public power: number = 0,
        public dcResistivity: number = 0,
        public acResistivity: number = 0,
        public reactance: number = 0,
    ) {
        this.recalculateProps();
    }

    recalculateProps() {
        this.calcIsBuried();
    }


    private calcIsBuried() {
        this.isBuried = this.constraints === null
            ? this.isBuried = null
            : this.isBuried = this.constraints
                .includes(DC_CNSTS.Constraints.BelowGround);
    }

}

export enum CableId {
    Default = 7,
}

export class LowVoltageCables extends EntitiesBase<Cable, CableId> {
    constructor() {
        super({
            identifier: 'low-voltage-objects',
            idsType: CableId.Default,
            T_Constructor: Cable,
        });
    }
    checkForErrors(): void {
        // not implemented
    }
}

