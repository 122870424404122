import type { ProjectMetrics, TrackerPilesCollection, UnitsMapper} from "bim-ts";
import { NumberProperty, type Bim } from "bim-ts";
import type { TasksRunner, Result} from "engine-utils-ts";
import { convertUnits, Failure, Success, VersionedInvalidator } from "engine-utils-ts";
import type { CostReport } from "../CostReport";
import { type TableEntry, TableHierarchy } from "../TableHierarchy";
import { registerCustomAmountAction } from "./custom-amount/CustomAmount";
import { registerHideAction } from "./hide/Hide";
import { registerOverrideAction } from "./override/Override";
import { registerQuantityTemplateAction } from "./quantity-template/QuantityTemplate";
import { registerReferenceAction } from "./reference/Reference";

export class Action<T extends any = any> {
    disable = false;
    userCreated = false;
    constructor(
        public name: string,
        public type: string,
        public props: T,
    ) {}
    as<Type>() {
        return (this as unknown) as Action<Type>;
    }
}

export interface QuantityRemapper {
    from: NumberProperty
    to: NumberProperty
}

export function remapNumber(
  fromNum: NumberProperty,
  remap: QuantityRemapper
): Result<NumberProperty> {
    const refValUnitResult = convertUnits(
        remap.from.value,
        remap.from.unit,
        fromNum.unit
    );
    if (refValUnitResult instanceof Failure) {
      return refValUnitResult;
    }
    const refValUnit = refValUnitResult.value;
    const qtyUnits = fromNum.value / refValUnit;
    const result = NumberProperty.new({
        unit: remap.to.unit,
        value: remap.to.value * qtyUnits,
    })
    return new Success(result);
}

export type ActionSolver<T = any> =
    (action: Action<T>, hierarhy: TableHierarchy, actionIndex: number) => void

export class ActionSolvers {
    private actionSolvers = new Map<string, ActionSolver>();
    registerActionSolver<T>(ident: string, solver: ActionSolver<T>) {
        this.actionSolvers.set(ident, solver);
    }
    applyActionSolver(
        action: Action,
        actionIndex: number,
        hierarchy = new TableHierarchy()
    ) {
        const solver = this.actionSolvers.get(action.type)
        if (!solver) {
            throw new Error('solver not found ' + action.type);
        }
        solver(action, hierarchy, actionIndex)
    }
}


export function registerActions(
    bim: Bim,
    tasksRunner: TasksRunner,
    costReport: CostReport,
    layoutMetrics: ProjectMetrics,
    trackerPilesCollection: TrackerPilesCollection,
) {
    registerHideAction(costReport);
    registerCustomAmountAction(costReport, bim);
    registerOverrideAction(costReport, bim);
    registerReferenceAction(costReport, bim);
    const qtyInv = registerQuantityTemplateAction(
      costReport,
      bim,
      tasksRunner,
      layoutMetrics,
      trackerPilesCollection
    );
    return new VersionedInvalidator([qtyInv]);
}

export function fixRateByRateUnit(
    entry: TableEntry,
    unitsMapper: UnitsMapper,
    rateUnit?: NumberProperty,
) {
    if (!entry.quantity || !entry.rate) {
      return;
    }

    if (!rateUnit) {
      rateUnit = NumberProperty.new({ value: 1, unit: entry.quantity.unit });
    }

    entry.quantity = entry.quantity.toConfiguredUnits(unitsMapper);
    entry.rate = entry.rate.toConfiguredUnits(unitsMapper);

    
    const rateUnitValueInQuantityUnits = convertUnits(
        rateUnit.value,
        rateUnit.unit,
        entry.quantity.unit,
    );

    if (rateUnitValueInQuantityUnits instanceof Success) {
        entry.rate = entry.rate.withDifferentValue(
          entry.rate.value / rateUnitValueInQuantityUnits.value
        );
    }
}

