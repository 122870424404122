<svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M6 13L5 12L9 7.9777L5 4L6 3L11 7.9777L6 13Z"
        fill="currentColor"
    />
</svg>
