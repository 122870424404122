<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
>
    <path
        d="M12.0052 22.1494C10.6018 22.1494 9.28302 21.883 8.04874 21.35C6.81445 20.817 5.73924 20.0931 4.82309 19.1783C3.90694 18.2635 3.18248 17.1891 2.64971 15.9551C2.11696 14.7212 1.85059 13.4027 1.85059 11.9996C1.85059 10.5966 2.11696 9.27824 2.64971 8.04454C3.18248 6.81083 3.90694 5.73655 4.82309 4.82172C5.73924 3.90687 6.81469 3.18196 8.04944 2.64699C9.28417 2.11203 10.6035 1.84454 12.0074 1.84454C13.4113 1.84454 14.7294 2.11203 15.9618 2.64699C17.1943 3.18196 18.2681 3.90642 19.1835 4.82037C20.0989 5.73432 20.823 6.80849 21.356 8.04287C21.889 9.27725 22.1555 10.5962 22.1555 11.9997C22.1555 13.4032 21.8892 14.722 21.3566 15.9561C20.824 17.1903 20.0998 18.2649 19.1839 19.1801C18.2681 20.0953 17.1931 20.819 15.9591 21.3512C14.7251 21.8834 13.4071 22.1494 12.0052 22.1494ZM10.937 20.4212V18.3772C10.3537 18.3772 9.86395 18.1617 9.46786 17.7307C9.07179 17.2997 8.87376 16.7942 8.87376 16.2141V15.1201L3.78481 10.031C3.70148 10.3643 3.64314 10.6915 3.60981 11.0125C3.57648 11.3335 3.55981 11.6596 3.55981 11.9907C3.55981 14.1423 4.259 16.0238 5.65739 17.6351C7.05575 19.2465 8.81562 20.1752 10.937 20.4212ZM18.2392 17.7391C18.6018 17.3431 18.9197 16.9211 19.1927 16.4731C19.4657 16.0251 19.697 15.5562 19.8867 15.0666C20.0764 14.5769 20.2171 14.075 20.3087 13.561C20.4004 13.047 20.4462 12.5226 20.4462 11.988C20.4462 10.2379 19.9669 8.64683 19.0082 7.21484C18.0495 5.78284 16.7678 4.7355 15.1631 4.07282V4.51684C15.1631 5.09524 14.9628 5.59935 14.5622 6.02917C14.1616 6.459 13.6721 6.67392 13.0938 6.67392H10.937V8.83517C10.937 9.11969 10.8245 9.35262 10.5995 9.53397C10.3745 9.7153 10.1197 9.80597 9.83521 9.80597H7.77994V11.988H14.1892C14.4709 11.988 14.7036 12.0967 14.8874 12.3139C15.0712 12.5311 15.1631 12.7804 15.1631 13.0616V16.2141H16.2261C16.7055 16.2141 17.1276 16.3558 17.4926 16.6391C17.8576 16.9225 18.1065 17.2891 18.2392 17.7391Z"
        fill="currentColor"
    />
</svg>
