import type {
	IdBimGeo, SceneInstance} from 'bim-ts';
import {
	BasicAnalyticalRepresentation, BimGeometryType, PolylineGeometry, SceneObjDiff,
} from 'bim-ts';
import { RGBA } from 'engine-utils-ts';
import { Vector3 } from 'math-ts';
import { entityTypeFromId } from 'verdata-ts';

import type { UniformsFlat } from '../composer/DynamicUniforms';
import { EngineGeoAnalytPolyline } from '../geometries/EngineGeoAnalytPolyline';
import { GeometryUtils } from '../geometries/GeometryUtils';
import { EngineMaterialId } from '../pools/EngineMaterialId';
import {
	InObjFullId, SelfIdAuxZero, SelfIdZero,
} from '../scene/EngineSceneIds';
import type { ESOHandle } from '../scene/ESOsCollection';
import { newLodGroupLocalIdent } from '../scene/LodGroups';
import type {
	EngineSubmeshDescription, RenderJobUpdater} from '../scene/Submeshes2';
import { LodMask
} from '../scene/Submeshes2';
import { ShaderFlags } from '../shaders/ShaderFlags';
import { ESO_AnotationFlags } from './ESO';
import { ESO_Diff } from './ESO_Diff';
import { ESO_SingleGeo } from './ESO_SingleGeo';
import { ESO_SingleGeoIndividual } from './ESO_SingleGeoIndividual';
import type { ESOsHandlerInputs } from './ESOsHandlerBase';
import type { ESSO_Any, SubmeshesCreationOutput, SubmeshesCreationResources} from './ESSO';
import {
	ESSO
} from './ESSO';
import {
	DefaultSelectionColor, SelectHighlightOverlayJobUpdater,
	SharedSelectHighlightJobUpdater,
} from './ESSO_HighlightUpdaters';
import {
	sceneInstanceGizmoColor, sceneInstanceTriangulationAlpha,
} from './SceneInstanceColor';
import type { EngineFullGraphicsSettings } from '../GraphicsSettingsFull';
import { Matrix4 } from 'math-ts';

export class ESO_Boundary extends ESO_SingleGeo {


	constructor(
		sceneInstanceRef: Readonly<SceneInstance>,
	) {
		super(sceneInstanceRef);
	}

	repr(): BasicAnalyticalRepresentation {
		return this.sceneInstanceRef.representationAnalytical as BasicAnalyticalRepresentation;
	}

	geometryId(): IdBimGeo | undefined {
		return this.repr().geometryId;
	}

}

export class ESO_BoundariesHandler extends ESO_SingleGeoIndividual<ESO_Boundary> {
	private readonly boundaryTypeIdentifiers = new Set(['boundary']);

	constructor(
		identifier: string,
		args: ESOsHandlerInputs,
	) {
		super(identifier, args);
		this._relevantBimUpdatesFlags |= SceneObjDiff.LegacyProps;
		this._annotationsMask |= ESO_AnotationFlags.IsInEdit;

	}

	tryCreateESO(instance: SceneInstance): ESO_Boundary | undefined {
		if (this.boundaryTypeIdentifiers.has(instance.type_identifier) && instance.representationAnalytical instanceof BasicAnalyticalRepresentation) {
			const geoId = instance.representationAnalytical.geometryId;
			const geoType = entityTypeFromId<BimGeometryType>(geoId);
			if (geoType === BimGeometryType.ExtrudedPolygon) {
				return new ESO_Boundary(instance);
			}
        }
		return undefined;
	}
	esosTypesToHandle(): Iterable<new (...args: any[]) => ESO_Boundary> {
		return [ESO_Boundary];
	}

	applyBimDiffToESO(obj: ESO_Boundary, diff: SceneObjDiff, handle: ESOHandle): ESO_Diff {
		let appliedDiff = super.applyBimDiffToESO(obj, diff, handle);
		const color = sceneInstanceGizmoColor(obj.sceneInstanceRef);
		if (color != obj.colorTint) {
			obj.colorTint = color;
			appliedDiff |= ESO_Diff.ColorTint;
		}
		return appliedDiff;
	}

	createSelfESSOs(handle: ESOHandle, obj: ESO_Boundary, result: ESSO_Any[]): void {
		result.push(new ESSO_BasicPolygonSpatialSelf(obj,  InObjFullId.new(handle, SelfIdAuxZero), obj.repr(), null));
		result.push(new ESSO_BasicPolygonLinesSelf(obj,  InObjFullId.new(handle, SelfIdZero), obj.repr(), null));
	}
}


export class ESSO_BasicPolygonSpatialSelf extends ESSO<BasicAnalyticalRepresentation> {
	createSubmeshes(resoures: SubmeshesCreationResources, output: SubmeshesCreationOutput): void {

		const geoId = resoures.geometries.extrudedPolygonGeometries.mapBimIdToEngineId(this.repr.geometryId);
		if (geoId === undefined) {
			resoures.logger.error('undefined mapped geo id', this);
			return;
		}
		const geo = resoures.geometries.extrudedPolygonGeometries.peekById(geoId);
		if (!geo) {
			resoures.logger.error('geometry absent', this);
			return;
		}

		output.submeshes.push({
			id: this.id,
			lodMask: LodMask.All,
			lodGroupLocalIdent: newLodGroupLocalIdent(0, 0),
			subObjectRef: this,
			descr: {
				geoId,
				materialId: EngineMaterialId.Spatial,
				localTransforms: null,
				mainRenderJobUpdater: new BasicPolygonRenderJobUpdater(),
				overlayRenderJobUpdater: BoundarySelectHighlighUpdater,
			}
		});
	}
}
export const BoundarySelectHighlighUpdater = new SelectHighlightOverlayJobUpdater(
	EngineMaterialId.Highlight,
	true,
	RGBA.withDifferentAlpha(DefaultSelectionColor, 0.15),
	1
);

export class ESSO_BasicPolygonLinesSelf extends ESSO<BasicAnalyticalRepresentation> {
	createSubmeshes(resoures: SubmeshesCreationResources, output: SubmeshesCreationOutput): void {
		const isEditMode = this.rootRef.annotationFlags & ESO_AnotationFlags.IsInEdit;

		const geoId = resoures.geometries.extrudedPolygonGeometries.mapBimIdToEngineId(this.repr.geometryId);
		if (geoId === undefined) {
			resoures.logger.error('undefined mapped geo id', this);
			return;
		}
		const geo = resoures.geometries.extrudedPolygonGeometries.peekById(geoId);
		if (!geo) {
			resoures.logger.error('geometry absent', this);
			return;
		}

		const polylinePoints =  geo.bimGeo.outerShell.points.map(p => new Vector3(p.x, p.y, 0));
		GeometryUtils.makePolylinePointsClosed(polylinePoints);

		const polylineId = resoures.geometries.analytPolylines.allocate([[
			resoures.geometries.analytPolylines.reserveEngineOnlyId(),
			new EngineGeoAnalytPolyline(PolylineGeometry.newWithAutoIds(polylinePoints, 0.01)),
		]])[0];

		for (const elevation of [geo.bimGeo.baseElevation, geo.bimGeo.topElevation]) {
			output.submeshes.push({
				id: this.id,
				lodMask: LodMask.All,
				lodGroupLocalIdent: newLodGroupLocalIdent(0, 0),
				subObjectRef: this,
				descr: {
					geoId: polylineId,
					materialId: isEditMode ? EngineMaterialId.BasicAnalyticalTransparent : EngineMaterialId.BasicAnalytical,
					localTransforms: [new Matrix4().setPosition(0, 0, elevation)],
					mainRenderJobUpdater: new BasicPolylineJobUpdater(),
					overlayRenderJobUpdater: SharedSelectHighlightJobUpdater
				}
			});
		}
	}
}



class BasicPolygonRenderJobUpdater implements RenderJobUpdater {
    updaterRenderJob(
        submeshDescription: Readonly<EngineSubmeshDescription<ESO_Boundary>>,
        renderSettings: Readonly<EngineFullGraphicsSettings>,
        output: { flags: ShaderFlags; materialId: EngineMaterialId; uniforms: UniformsFlat; }
    ): void {
		if (submeshDescription.subObjectRef.isHidden) {
			return;
		}
		output.materialId = submeshDescription.localDescr.materialId;
		const alpha = sceneInstanceTriangulationAlpha(submeshDescription.subObjectRef.rootRef.sceneInstanceRef);

		output.uniforms.push('alphaPerAxis', alpha);
    }
}


class BasicPolylineJobUpdater implements RenderJobUpdater {
    updaterRenderJob(
        submeshDescription: Readonly<EngineSubmeshDescription<ESO_Boundary>>,
        renderSettings: Readonly<EngineFullGraphicsSettings>,
        output: { flags: ShaderFlags; materialId: EngineMaterialId; uniforms: UniformsFlat; }
    ): void {
		if (submeshDescription.subObjectRef.isHidden) {
			return;
		}
		output.materialId = submeshDescription.localDescr.materialId;
		output.uniforms.push('lineWidth', 8);
    }
}

export class BasicPointSpriteJobUpdater implements RenderJobUpdater {
	readonly additionalFlags: ShaderFlags;

	constructor(additionalFlags?: ShaderFlags) {
		this.additionalFlags = additionalFlags ?? ShaderFlags.None;
	}

	updaterRenderJob(
		submeshDescription: Readonly<EngineSubmeshDescription<ESO_Boundary>>,
		renderSettings: Readonly<EngineFullGraphicsSettings>,
		output: { flags: ShaderFlags; materialId: EngineMaterialId; uniforms: UniformsFlat; }
	): void {
		if (submeshDescription.subObjectRef.isHidden) {
			return;
		}
		output.materialId = submeshDescription.localDescr.materialId;
		output.flags |= this.additionalFlags;
	}
}



