<script lang="ts">
import 'ag-grid-enterprise/styles/ag-theme-balham.min.css';
import { Bim, CostModel as CM } from 'bim-ts';
import { createGrid, GridApi } from "ag-grid-enterprise";
import { createGridConfig } from "./table";
import { GridConfigType } from "./table/types";
import { getContext, onDestroy, onMount } from 'svelte';
import { populateAgGridTable } from './table/utils';
import { InProgress, preferPreviousOverInProgressWrapper, Success } from 'engine-utils-ts';
import { VersionedStore } from 'src/VersionedStore';
import { UiPersistentState } from 'src/UiPersistentState';
import { ContextMenu, ContextMenuStructure, NamedCheckbox } from 'src/libui/context-menu';
import { Checkbox } from 'src/libui/checkbox';
import { getLcoeContext } from './LcoeContext';


export let isLoading: boolean
export let isReadonly: boolean

const bim = getContext<Bim>('bim');
const contextMenu = getContext<ContextMenu>('context-menu');
const lcoeContext = getLcoeContext();

let gridRootRef: HTMLElement;
let gridConfig: GridConfigType;
let gridApi: GridApi<CM.FlattenedCostCategoryParams>;

onMount(() => {
    gridConfig = createGridConfig(bim);
    gridApi = createGrid(gridRootRef, gridConfig.options);
})

onDestroy(() => {
    gridApi.destroy()
})

const uiSettings = new UiPersistentState<{
    hideIntermidYears: boolean
}>('lcoe-ui-settings', {
    hideIntermidYears: true
}).store;


const lcoeStore = new VersionedStore(preferPreviousOverInProgressWrapper(lcoeContext.lcoe), 200);
$: lcoe = $lcoeStore instanceof Success ? $lcoeStore.value : undefined;

$: if (gridConfig && gridApi && lcoe && typeof $uiSettings.hideIntermidYears === 'boolean' && typeof isReadonly === 'boolean') {
    const flattened: CM.FlattenedCostCategoryParams[] = lcoe.hierarchy.flattenCostHierarchy();

    populateAgGridTable({
        gridApi,
        gridConfig,
        flattened,
        hierarchy: lcoe.hierarchy,
        lcoe: lcoe.lcoe,
        hideIntermidYears: $uiSettings.hideIntermidYears,
        isReadonly,
    });
}

$: menu = new ContextMenuStructure([
    new NamedCheckbox(
        'Intermid years',
        new Checkbox(
            !$uiSettings.hideIntermidYears,
        ),
        () => {
            $uiSettings.hideIntermidYears = !$uiSettings.hideIntermidYears;
            contextMenu.disposeMenu();
        }
    )
], false);

$: {
    isLoading = $lcoeStore instanceof InProgress
}

</script>

<div
    class="container"
    on:keyup|stopPropagation on:keydown|stopPropagation
>
    <div class="CM-toolbar">
        <div class="CM-tabs">
            <div
                class="mdc-typography--headline5 item"
                class:selected={$uiSettings.hideIntermidYears}
                on:click={() => $uiSettings.hideIntermidYears = true}
            >
                Overview
            </div>
            <div
                class="mdc-typography--headline5 item"
                class:selected={!$uiSettings.hideIntermidYears}
                on:click={() => $uiSettings.hideIntermidYears = false}
            >
                YOY Report
            </div>
        </div>
    </div>

    <div
        bind:this={gridRootRef}
        class="cost-model-table ag-theme-balham"
    />
</div>

<style lang="scss">
.cost-model-table {
    height: 100%;
}
.container {
    display: flex;
    height: 100%;
    flex-direction: column;
    transition-delay: 0s;
    transition: opacity .5s;
}
</style>
