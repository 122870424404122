import { Transform, Vector2, Vector3 } from 'math-ts';
import type { Text } from './Text';
import { BufferAttribute, Object3D } from '../../3rdParty/three';
import { GeometryGpuRepr } from '../../geometries/KrBufferGeometry';
import { JUSTIFY_CONTENT } from '../utils/block-layout/JustifyContent';
import { Line, Lines } from './Lines';
import { collapseWhitespaceOnInlines, shouldBreak } from '../utils/inline-layout/Whitespace';
import { TEXT_ALIGN_H, textAlignH } from '../utils/inline-layout/TextAlignH';
import { TEXT_ALIGN_V, textAlignV } from '../utils/inline-layout/TextAlignV';
import { MSDFGlyph } from '../content/MSDFGlyph';
import { GeometriesUtils } from 'bim-ts';
import type { Font } from "./Font";
import { GeometryGenerator } from '../../geometries/GeometryGenerator';

export class BlockOptions {
    constructor(
        readonly width: number,
        readonly height: number,
        readonly padding: number = 0,
        readonly textAlignH: TEXT_ALIGN_H = TEXT_ALIGN_H.CENTER,
        readonly textAlignV: TEXT_ALIGN_V = TEXT_ALIGN_V.CENTER,
        readonly bestFit: 'none' | 'grow' | 'shrink' | 'auto' = 'shrink'
    ) {};

    clone(): BlockOptions {
        return new BlockOptions(
            this.width,
            this.height,
            this.padding, 
            this.textAlignH,
            this.textAlignV,
            this.bestFit
        );
    }
}

export class Block extends Object3D {
	width: number;
	height: number;
	padding: number;
	textAlignH: TEXT_ALIGN_H;
    textAlignV: TEXT_ALIGN_V;
	bestFit: string;

	size: Vector2;

    justifyContent: JUSTIFY_CONTENT;

	interLine: number;

	childrenTexts: Text[];

	lines?: Lines;

	constructor( options: BlockOptions, paragraphs: Text[] ) {
		super();

		this.width = options.width;
		this.height = options.height;
		this.padding = options.padding;
		this.textAlignH = options.textAlignH;
        this.textAlignV = options.textAlignV;
		this.bestFit = options.bestFit;

		this.size = new Vector2( options.width, options.height );

		this.justifyContent = JUSTIFY_CONTENT.START;

		this.interLine = 0.01;

		this.childrenTexts = paragraphs;
	}

    createText() {
        if ( this.bestFit != 'none' ) {
			this.calculateBestFit( this.bestFit );
		} else {
			this.childrenTexts.forEach( child => {
				child._fitFontSize = undefined;
			} );
		}

		if ( this.childrenTexts.length ) {
			this.computeInlinesPosition();
		}

        return this.buildText();
    }

	//  INLINE MANAGER
    /**
    Job: Positioning inline elements according to their dimensions inside this component

    Knows: This component dimensions, and its children dimensions

    This module is used for Block composition (Object.assign). A Block is responsible
    for the positioning of its inline elements. In order for it to know what is the
    size of these inline components, parseParams must be called on its children first.

    It's worth noting that a Text is not positioned as a whole, but letter per letter,
    in order to create a line break when necessary. It's Text that merge the various letters
    in its own updateLayout function.
    */

    /** Compute children .inlines objects position, according to their pre-computed dimensions */
    computeInlinesPosition() {
        // computed by BoxComponent
        const INNER_WIDTH = this.width - ( this.padding * 2 );
        const INNER_HEIGHT = this.height - ( this.padding * 2 );

        // got by MeshUIComponent
        const JUSTIFICATION = this.justifyContent;

        // Compute lines
        const lines = this.computeLines();

        /////////////////////////////////////////////////////////////////
        // Position lines according to justifyContent and contentAlign
        /////////////////////////////////////////////////////////////////

        const textHeight = Math.abs( lines.height );

        // Line vertical positioning
        const justificationOffset = ( () => {
            switch ( JUSTIFICATION ) {
                case JUSTIFY_CONTENT.START:
                    return (INNER_HEIGHT/2);

                case JUSTIFY_CONTENT.END:
                    return textHeight - ( INNER_HEIGHT / 2 );

                case JUSTIFY_CONTENT.CENTER:
                    return ( textHeight / 2 );

                default:
                    console.warn( `justifyContent: '${JUSTIFICATION}' is not valid` );
                    return 0;
            }
        } )();

        //

        lines.lines.forEach( ( line ) => {
            line.y += justificationOffset;
            line.inlines.forEach( ( inline ) => {
                inline.offsetY += justificationOffset;
            } );
        } );

        // Horizontal positioning
        textAlignH( lines, this.textAlignH, INNER_WIDTH );
        // Vertical positioning
        textAlignV( lines, this.textAlignV, INNER_HEIGHT );

        // Make lines accessible to provide helpful informations
        this.lines = lines;
    }


    calculateBestFit( bestFit: string ) {
        if ( this.childrenTexts.length === 0 ) return;

        switch ( bestFit ) {
            case 'grow':
                this.calculateGrowFit();
                break;
            case 'shrink':
                this.calculateShrinkFit();
                break;
            case 'auto':
                this.calculateAutoFit();
                break;
        }
    }

    calculateGrowFit() {
        const INNER_HEIGHT = this.height - ( this.padding * 2 );

        //Iterative method to find a fontSize of text children that text will fit into container
        let iterations = 1;
        const heightTolerance = 0.075;
        const firstText = this.childrenTexts[0];

        let minFontMultiplier = 1;
        let maxFontMultiplier = 2;
        let fontMultiplier = firstText?._fitFontSize ? firstText._fitFontSize / firstText.fontSize : 1;
        let textHeight;

        do {
            textHeight = this.calculateHeight( fontMultiplier );

            if ( textHeight > INNER_HEIGHT ) {
                if ( fontMultiplier <= minFontMultiplier ) { // can't shrink text
                    this.childrenTexts.forEach( textComponent => {
                        // ensure fontSize does not shrink
                        textComponent._fitFontSize = textComponent.fontSize;
                    } );
                    break;
                }

                maxFontMultiplier = fontMultiplier;
                fontMultiplier -= ( maxFontMultiplier - minFontMultiplier ) / 2;
            } else {
                if ( Math.abs( INNER_HEIGHT - textHeight ) < heightTolerance ) break;

                if ( Math.abs( fontMultiplier - maxFontMultiplier ) < 5e-10 ) maxFontMultiplier *= 2;

                minFontMultiplier = fontMultiplier;
                fontMultiplier += ( maxFontMultiplier - minFontMultiplier ) / 2;
            }
        } while ( ++iterations <= 10 );
    }

    calculateShrinkFit() {
        const INNER_HEIGHT = this.height - ( this.padding * 2 );

        // Iterative method to find a fontSize of text children that text will fit into container
        let iterations = 1;
        const heightTolerance = 0.075;
        const firstText = this.childrenTexts[0];

        let minFontMultiplier = 0;
        let maxFontMultiplier = 1;
        let fontMultiplier = firstText?._fitFontSize ? firstText._fitFontSize / firstText.fontSize : 1;
        let textHeight;

        do {
            textHeight = this.calculateHeight( fontMultiplier );

            if ( textHeight > INNER_HEIGHT ) {
                maxFontMultiplier = fontMultiplier;
                fontMultiplier -= ( maxFontMultiplier - minFontMultiplier ) / 2;
            } else {
                if ( fontMultiplier >= maxFontMultiplier ) { // can't grow text
                    this.childrenTexts.forEach( textComponent => {
                        // ensure fontSize does not grow
                        textComponent._fitFontSize = textComponent.fontSize;
                    } );
                    break;
                }

                if ( ( INNER_HEIGHT - textHeight ) <= heightTolerance ) break;

                minFontMultiplier = fontMultiplier;
                fontMultiplier += ( maxFontMultiplier - minFontMultiplier ) / 2;
            }
        } while ( ++iterations <= 8 );

        if ( textHeight > INNER_HEIGHT ) {
            this.childrenTexts.forEach( textComponent => {
                textComponent._fitFontSize = textComponent.fontSize * minFontMultiplier;
                textComponent.calculateInlines( textComponent._fitFontSize );
            } );
        }
    }

    calculateAutoFit()  {
        const INNER_HEIGHT = this.height - ( this.padding * 2 );

        //Iterative method to find a fontSize of text children that text will fit into container
        let iterations = 1;
        const heightTolerance = 0.075;
        const firstText = this.childrenTexts[0];

        let minFontMultiplier = 0;
        let maxFontMultiplier = 2;
        let fontMultiplier = firstText?._fitFontSize ? firstText._fitFontSize / firstText.fontSize : 1;
        let textHeight;

        do {
            textHeight = this.calculateHeight( fontMultiplier );

            if ( textHeight > INNER_HEIGHT ) {
                maxFontMultiplier = fontMultiplier;
                fontMultiplier -= ( maxFontMultiplier - minFontMultiplier ) / 2;
            } else {
                if ( ( INNER_HEIGHT - textHeight ) <= heightTolerance ) break;

                if ( Math.abs( fontMultiplier - maxFontMultiplier ) < 5e-10 ) maxFontMultiplier *= 2;

                minFontMultiplier = fontMultiplier;
                fontMultiplier += ( maxFontMultiplier - minFontMultiplier ) / 2;
            }
        } while ( ++iterations <= 8 );

        if ( textHeight > INNER_HEIGHT ) {
            this.childrenTexts.forEach( textComponent => {
                textComponent._fitFontSize = textComponent.fontSize * minFontMultiplier;
                textComponent.calculateInlines( textComponent._fitFontSize );
            } );
        }
    }

    /**
     * computes lines based on children's inlines array.
     */
    private computeLines() {
        // computed by BoxComponent
        const INNER_WIDTH = this.width - ( this.padding * 2 );

        // Will stock the characters of each line, so that we can
        // correct lines position before to merge
        const lines = new Lines( this.interLine );

        this.childrenTexts.forEach( ( textComponent ) => {
            // Abort condition
            if ( !textComponent.inlines ) return -1;

            const FONTSIZE = textComponent._fitFontSize || textComponent.fontSize;
            const LETTERSPACING = textComponent.letterSpacing * FONTSIZE;
            const WHITESPACE = textComponent.whiteSpace;
            const BREAKON = textComponent.breakOn;

            lines.lines.push( new Line( WHITESPACE ) );

            //////////////////////////////////////////////////////////////
            // Compute offset of each children according to its dimensions
            //////////////////////////////////////////////////////////////

            const whiteSpaceOptions = {
                WHITESPACE,
                LETTERSPACING,
                BREAKON,
                INNER_WIDTH
            }

            let lastInlineOffset: number = 0;
            const currentInlineInfo = textComponent.inlines.reduce<number>( ( lastInlineOffset, inline, i, inlines ) => {
                // Line break
                if ( shouldBreak( inlines, i, lastInlineOffset, whiteSpaceOptions ) ) {
                    const line = new Line( WHITESPACE );
                    line.inlines = [ inline ];
                    lines.lines.push( line );

                    inline.offsetX = inline.xoffset;

                    // restart the lastInlineOffset as zero.
                    if ( inline.width === 0 ) return 0;

                    // compute lastInlineOffset normally
                    // except for kerning which won't apply
                    // as there is visually no lefthanded glyph to kern with
                    return inline.xadvance + LETTERSPACING;
                }

                lines.lines[ lines.lines.length - 1 ].inlines.push( inline );

                inline.offsetX = lastInlineOffset + inline.xoffset + inline.kerning;

                return lastInlineOffset + inline.xadvance + inline.kerning + LETTERSPACING;

            }, lastInlineOffset );

            //

            return currentInlineInfo;
        } );

        // Compute lines dimensions

        let width = 0, height = 0, lineOffsetY = -this.interLine/2;
        lines.lines.forEach( ( line ) => {
            //

            line.lineHeight = line.inlines.reduce( ( height, inline ) => {
                const charHeight = inline.lineHeight !== undefined ? inline.lineHeight : inline.height;
                return Math.max( height, charHeight );
            }, 0 );

            //

            line.lineBase = line.inlines.reduce( ( lineBase, inline ) => {
                const newLineBase = inline.lineBase !== undefined ? inline.lineBase : inline.height;
                return Math.max( lineBase, newLineBase );
            }, 0 );

            //

            line.width = 0;
            line.height = line.lineHeight;

            if ( line.inlines.length > 0 ) {
                // starts by processing whitespace, it will return a collapsed left offset
                const whiteSpaceOffset = collapseWhitespaceOnInlines( line );

                // apply the collapsed left offset to ensure the starting offset is 0
                line.inlines.forEach( ( inline ) => {
                    inline.offsetX -= whiteSpaceOffset;
                } );

                // compute its width: length from firstInline:LEFT to lastInline:RIGHT
                line.width = this.computeLineWidth( line );
                if( line.width > width ){
                    width = line.width;
                }

                line.inlines.forEach( ( inline ) => {
                    inline.offsetY = (lineOffsetY - inline.height) - inline.anchor;
                    if( inline.lineHeight < line.lineHeight ){
                        inline.offsetY -= line.lineBase- inline.lineBase;
                    }
                } );

                line.y = lineOffsetY;
                // line.x will be set by textAlign

                height += ( line.lineHeight + this.interLine );

                lineOffsetY = lineOffsetY - (line.lineHeight + this.interLine );
            }
        } );

        lines.height = height;
        lines.width = width;

        return lines;
    }

    calculateHeight( fontMultiplier: number ) {
        this.childrenTexts.forEach( textComponent => {
            // Set font size and recalculate dimensions
            textComponent._fitFontSize = textComponent.fontSize * fontMultiplier;
            textComponent.calculateInlines( textComponent._fitFontSize );
        } );

        const lines = this.computeLines();
        return Math.abs( lines.height );
    }

    /**
     * Compute the width of a line
     */
    computeLineWidth( line: Line ) {
        // only by the length of its extremities
        const firstInline = line.inlines[ 0 ];

        const lastInline = line.inlines[ line.inlines.length - 1 ];

        // Right + Left ( left is negative )
        return (lastInline.offsetX + lastInline.width) + firstInline.offsetX;
    }


    /**
     * Creates a THREE.Plane geometry, with UVs carefully positioned to map a particular
     * glyph on the MSDF texture. Then creates a shaderMaterial with the MSDF shaders,
     * creates a THREE.Mesh, returns it.
     */
    buildText(): GeometryGpuRepr {
        const geometriesPlusTransforms: { geo: GeometryGpuRepr, tr: Transform }[] = [];

        for (const childText of this.childrenTexts) {
            if (!childText.inlines) {
                continue;
            }

            for (const inline of childText.inlines) {
                const glyph = new MSDFGlyph( inline, childText.fontFamily as Font );
                const edgesIndex = GeometriesUtils.findSurfacesBorderEdgesIndices(
                    glyph.attributes.position.array!, 
                    glyph.index.array!
                );
                geometriesPlusTransforms.push({ 
                    geo: new GeometryGpuRepr(glyph.attributes, glyph.index, new BufferAttribute(edgesIndex, 1)), 
                    tr: new Transform( new Vector3( inline.offsetX, inline.offsetY, 0 ) ) 
                });
            };
        }

        const mergedGeom = GeometryGenerator.mergeBufferGeometries<GeometryGpuRepr>( geometriesPlusTransforms );

        return mergedGeom!;
    }
}
