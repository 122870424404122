import type { Matrix2 } from './Matrix2';
import { Vector4 } from './Vector4';
import { KrMath } from './KrMath';
import { Vector2 } from './Vector2';
import type { Matrix3 } from './Matrix3';

export type Vector2Like = {x: number, y: number};

export class Aabb2 {

	min: Vector2;
	max: Vector2;
	applyMatrix3!: (this: Aabb2, matrix: Matrix3) => Aabb2;
	applyMatrix2!: (this: Aabb2, matrix: Matrix2) => Aabb2;

    constructor(
        min: Vector2,
	    max: Vector2,
    ) {
        this.min = min;
		this.max = max;
	}

	static empty(): Aabb2 {
        return new Aabb2(
            new Vector2(Infinity, Infinity),
            new Vector2(-Infinity, -Infinity),
        );
	}

	static infinite(): Aabb2 {
        return new Aabb2(
            new Vector2(-Infinity, -Infinity),
            new Vector2(Infinity, Infinity),
        );
	}

	static allocateSizeOne(): Aabb2 {
        return new Aabb2(
            new Vector2(-0.5, -0.5),
            new Vector2(0.5, 0.5),
        );
	}

	static calcFromArray(array: ArrayLike<number>) {
		if (array.length === 0) {
			return Aabb2.empty();
		}

		let minX = array[0];
		let minY = array[1];

		let maxX = array[0];
		let maxY = array[1];

		for ( var i = 2, l = array.length; i < l; i += 2 ) {
			const x = array[ i ];
			const y = array[ i + 1 ];

			if ( x < minX ) {
				minX = x;
			} else if ( x > maxX ) {
				maxX = x;
			}

			if ( y < minY ) {
				minY = y;
			} else if ( y > maxY ) {
				maxY = y;
			}
		}
		return new Aabb2(new Vector2(minX, minY), new Vector2(maxX, maxY));
	}

	equals(rhs: Aabb2) {
		return this.min.equals(rhs.min) && this.max.equals(rhs.max);
	}

    width()  { return this.max.x - this.min.x; }
	height() { return this.max.y - this.min.y; }

	asVec4() {
		return new Vector4(this.min.x, this.min.y, this.max.x, this.max.y);
	}

	roundTo(n: number) {
		this.min.floorTo(n);
		this.max.ceilTo(n);
		return this;
	}

	copy(box: Readonly<Aabb2>): Aabb2 {
        this.min.copy(box.min);
        this.max.copy(box.max);
		return this;
	}

	clone(): Aabb2 {
		return new Aabb2(this.min.clone(), this.max.clone());
	}

	isEmpty(): boolean {
        return (this.max.x < this.min.x)
            || (this.max.y < this.min.y);
	}

    makeEmpty(): void {
        this.min.set(+Infinity, +Infinity);
        this.max.set(-Infinity, -Infinity);
	}

	setFromPoints(points: ReadonlyArray<Vector2Like>): Aabb2 {
		if (points.length === 0) {
			this.makeEmpty();
		} else {
			this.min.set(points[0].x, points[0].y);
			this.max.set(points[0].x, points[0].y);
		}

		let point;
		for (let i = 1; i < points.length; ++i){
			point = points[i];

			if (point.x < this.min.x) {
				this.min.x = point.x;
			} else if (point.x > this.max.x) {
				this.max.x = point.x;
			}

			if (point.y < this.min.y) {
				this.min.y = point.y;
			} else if (point.y > this.max.y) {
				this.max.y = point.y;
			}
		}
		return this;
	}

	setFromCenterAndSize(center: Vector2, size: Vector2): Aabb2 {
		this.min.x = center.x - size.x * 0.5;
		this.min.y = center.y - size.y * 0.5;
		this.max.x = center.x + size.x * 0.5;
		this.max.y = center.y + size.y * 0.5;
		return this;
	}

	static fromCenterAndSize(center: Readonly<Vector2>, size: Readonly<Vector2>): Aabb2 {
		return new Aabb2(
			new Vector2(center.x - size.x * 0.5, center.y - size.y * 0.5),
			new Vector2(center.x + size.x * 0.5, center.y + size.y * 0.5),
		)
	}

	containsBox(box: Readonly<Aabb2>) {
        return this.min.x <= box.min.x && this.max.x >= box.max.x
            && this.min.y <= box.min.y && this.max.y >= box.max.y;
	}

	intersectsBox2(box: Aabb2): boolean {
        return this.min.x <= box.max.x && this.max.x >= box.min.x
            && this.min.y <= box.max.y && this.max.y >= box.min.y;
	}

	containsPoint(point: Vector2Like) {
        return point.x >= this.min.x && point.x <= this.max.x
            && point.y >= this.min.y && point.y <= this.max.y;
	}

	expandByPoint(point: Vector2Like) {
		this.min.x = Math.min(this.min.x, point.x);
		this.min.y = Math.min(this.min.y, point.y);

		this.max.x = Math.max(this.max.x, point.x);
		this.max.y = Math.max(this.max.y, point.y);
		return this;
	}

	expandByPoints(points: Vector2Like[]) {
		for (const point of points) {
			this.expandByPoint(point);
		}
		return this;
	}

	expandByScalar(s: number) {
		this.min.x -= s;
		this.min.y -= s;

		this.max.x += s;
		this.max.y += s;
        return this;
	}

	scale(s: number) {
		this.min.multiplyScalar(s);
		this.max.multiplyScalar(s);

        return this;
	}

	translate(offset: Vector2) {
		this.min.x += offset.x;
		this.min.y += offset.y;
		this.max.x += offset.x;
        this.max.y += offset.y;
        return this;
	}

	union(box: Aabb2) {
        this.min.x = Math.min(this.min.x, box.min.x);
        this.min.y = Math.min(this.min.y, box.min.y);
        this.max.x = Math.max(this.max.x, box.max.x);
		this.max.y = Math.max(this.max.y, box.max.y);
        return this;
	}

	getSize(): Vector2 {
		return new Vector2(
			this.max.x - this.min.x,
			this.max.y - this.min.y,
		);
	}

	maxSide(): number {
		return Math.max(this.max.x - this.min.x, this.max.y - this.min.y);
	}


	getCenter(): Vector2 {
		return new Vector2(
			(this.max.x + this.min.x) * 0.5,
			(this.max.y + this.min.y) * 0.5,
		);
	}

	centerX(): number {
		return (this.max.x + this.min.x) * 0.5;
	}

	centerY(): number {
		return (this.max.y + this.min.y) * 0.5;
	}

	clampTo(box: Aabb2) {
		this.min.x = Math.max(this.min.x, box.min.x);
		this.min.y = Math.max(this.min.y, box.min.y);
		this.max.x = Math.min(this.max.x, box.max.x);
		this.max.y = Math.min(this.max.y, box.max.y);
		return this;
	}

	clampVec2ToThis(vec: Vector2) {
		vec.x = KrMath.clamp(vec.x, this.min.x, this.max.x);
		vec.y = KrMath.clamp(vec.y, this.min.y, this.max.y);
	}

	clampPoint ( point:Vector2 ): Vector2 {
		return point.clamp( this.min, this.max );
	}

	distanceToPoint(point: Vector2): number {
		return this.clampPoint(point.clone()).sub(point).length();
	}

	cornerPoints(): ReadonlyArray<Readonly<Vector2>> {
		return [
			this.min,
			new Vector2(this.min.x, this.max.y),
			this.max,
			new Vector2(this.max.x, this.min.y),
		]
	}
}

Aabb2.prototype.applyMatrix2 = (function () {

	const points = [
		Vector2.zero(),
		Vector2.zero(),
		Vector2.zero(),
		Vector2.zero(),
	];

	return function (this: Aabb2, matrix: Matrix2) {

		// transform of empty box is an empty box.
		if (this.isEmpty()) return this;

		// NOTE: I am using a binary pattern to specify all 2^3 combinations below
		points[0].set(this.min.x, this.min.y).applyMatrix2(matrix); // 000
		points[1].set(this.max.x, this.min.y).applyMatrix2(matrix); // 001
		points[2].set(this.min.x, this.max.y).applyMatrix2(matrix); // 010
		points[3].set(this.max.x, this.max.y).applyMatrix2(matrix); // 011

		this.setFromPoints(points);

		return this;

	};

})();


Aabb2.prototype.applyMatrix3 = (function () {

	const points = [
		Vector2.zero(),
		Vector2.zero(),
		Vector2.zero(),
		Vector2.zero(),
	];

	return function (this: Aabb2, matrix: Matrix3) {

		// transform of empty box is an empty box.
		if (this.isEmpty()) return this;

		// NOTE: I am using a binary pattern to specify all 2^3 combinations below
		points[0].set(this.min.x, this.min.y).applyMatrix3(matrix); // 000
		points[1].set(this.max.x, this.min.y).applyMatrix3(matrix); // 001
		points[2].set(this.min.x, this.max.y).applyMatrix3(matrix); // 010
		points[3].set(this.max.x, this.max.y).applyMatrix3(matrix); // 011

		this.setFromPoints(points);

		return this;

	};

})();
