import { createNotificationSource } from 'ui-bindings';

export const notificationSource = createNotificationSource({
    generateLayout: {
        header: 'Generating layout',
        description: ''
    },
    noFeasibleOptions: {
        header: '',
        description: 'No feasible option found'
    },
    assignTrackerPosition: {
        header: 'Assigning tracker positions',
        description: ''
    },
    generateTranches: {
        header: 'Generating trenches',
        description: ''
    },
    generateWirings: {
        header: 'Generating wirings',
        description: '',
    },
    isolatedTransformers: {
        header: 'Invalid transformer',
        description: (ids) => `There are isolated transformers on the site with ids ${ids}.`,
    },
    sameTransformersPositions: {
        header: 'Invalid transformer position',
        description: (ids) => `There are transformers with the same positions on the site with ids ${ids}.`,
    },
    assetsNotFound: {
        header: 'Asset not found',
        description: (ids) => `Assets with ids: [${ids}] not found!`,
    },
    chooseCombinerBox: {
        header: 'Incorrect combiner box',
        description: (scheme) => `Please choose a ${
            scheme === "SI_Multiharness" ? "AC" : "DC"
        } Combiner Box for the ${scheme} scheme`,
    },
    chooseInventer: {
        header: 'Incorrect inverter',
        description: (scheme) => `Please choose a ${
            scheme === "SI_Multiharness" ? "string" : "central"
        } Inventer for the ${scheme} scheme`,
    },
    restoreAssets: {
        header: 'Restore assets',
        description: ''
    },
    restoreAssetsFailed: {
        header: 'Restore assets failed',
        description: ''
    },
    configNotFound: {
        header: 'Config not found',
        description: (id) => `Config with id ${id} not found!`,
    },
    assetNotFound: {
        header: 'Asset Not Found',
        description: (id) => `Asset with id: ${id} not found!`
    },
    genericSubstationNotFound: {
        header: `Generic Substation not found in catalog`,
        description: 'Please load predefined pack to the Catalog'
    },
    someErrorHappen: {
        header: 'Layout Error',
        description: (error) => `${error}`
    },
    augmentationTransformerNotInCatalog: {
        header: 'Some transformers in the scene are not in the catalog',
        description: 'These blocks are skipped',
    },
});
