<script lang="ts">
    export let upDown:boolean | undefined = undefined;
</script>

<div class="divider shadow" 
    class:up={upDown === true}
    class:down={upDown === false}
/>

<style lang="scss">
    .divider {
        background: var(--color-divider);
        border-color: var(--color-divider);
        border-radius: 1px;
        height: 1px;
        width: 100%;
        margin: 8px 0;
        &.shadow {
            &.up {
                box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);
            }
            &.down {
                box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);
            }
        }
    }
</style>
