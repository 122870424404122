import type { LazyVersioned, RGBAHex, InProgress} from 'engine-utils-ts';
import { Failure} from 'engine-utils-ts';
import type { Result} from 'engine-utils-ts';
import { ObjectUtils, LazyDerived, Success } from 'engine-utils-ts';
import type { Vector2, Vector3 } from 'math-ts';
import type { UiBindings } from '.';


export abstract class PUI_Node {
    readonly name: string;
    readonly parent: PUI_GroupNode | null;
    readonly hint?: string;

    readonly typeSortKey: number;
    readonly nameSortKey: number | string;

    constructor(params: PUI_NodeArgs) {
        this.name = params.name;
        this.parent = params.parent ?? null;
        this.hint = params.hint ?? undefined;
        this.typeSortKey = params.typeSortKeyOverride ?? this.defaultTypeSortKey();
        this.nameSortKey = params.nameSortKeyOverride ?? this.name;
    }

    getTopParent(): PUI_Node {
        if (this.parent == null) {
            return this;
        }
        return this.parent.getTopParent();
    }

    getPathFromRoot(): string[] {
        const r: string[] = [this.name];
        let parent = this.parent;
        for (let i = 0; i < 100 && parent != null; ++i) {
            if (parent.parent != null) {
                r.push(parent.name);
                parent = parent.parent;
            } else {
                break;
            }
        }
        return r.reverse();
    }

    abstract defaultTypeSortKey(): number;

    public static sortNodes(l: PUI_Node, r: PUI_Node): number {
        if (l.typeSortKey !== r.typeSortKey) {
            return l.typeSortKey - r.typeSortKey;
        }
        const nodeSortKeysCompRes = PUI_Node._compareSortKeys(l.nameSortKey, r.nameSortKey);
        if (nodeSortKeysCompRes) {
            return nodeSortKeysCompRes;
        }
        return l.name.localeCompare(r.name);
    }

    static _compareSortKeys(l: number | string, r: number | string) {
        if (typeof l === 'number') {
            if (typeof r === 'string') {
                return Math.sign(l);
            }
            return l - r;
        }
        if (typeof r === 'number') {
            if (typeof l === 'string') {
                return Math.sign(r) * -1;
            }
            return l - r;
        }
        return l.localeCompare(r);
    }
}

export interface PUI_NodeHeaderSelectorDescription {
    options: string[];
    onSelected: (option: string, index: number) => void;
}

export interface PUI_NodeArgs {
    name: string;
    hint?: string | null;
    parent?: PUI_GroupNode;
    typeSortKeyOverride?: number;
    nameSortKeyOverride?: number | string;
    inHeaderSelector?: PUI_NodeHeaderSelectorDescription;
}


export abstract class PUI_PropertyNode<UiT> extends PUI_Node {

    readonly defaultValue?: UiT;
    readonly readonly?: boolean;
    readonly calculated?: boolean;
    readonly notActive?: boolean;
    readonly description?: string;
    
    readonly validationResult: LazyVersioned<Result<UiT>>;

    protected _value: UiT;


    protected readonly _validators: PUI_PropertyValidator<UiT>[] = [];
    protected _onChangeCallback: PUI_PropertyChangeCallback<UiT>;
    // protected readonly transformer?: PUI_ConfigPropertyTransformer<any, UiT, any>;

    constructor(params: PUI_PropertyNodeArgs<UiT>) {
        super(params);
        if (params.value === undefined && params.defaultValue === undefined) {
            throw new Error(`${params.name} property was not provided with value or default value`);
        }
        this._value = ObjectUtils.deepFreeze((params.value === undefined ? params.defaultValue : params.value)!);
        this.defaultValue = params.defaultValue;
        this.readonly = params.readonly;
        this.calculated = params.calculated ?? false;
        this.notActive = params.notActive;
        // this.transformer = params.transformer;
        this.description = params.description ?? undefined;
        if (params.validator) {
            this._validators.push(params.validator);
        }
        this.validationResult = LazyDerived.fromMutatingObject<Result<UiT>>(() => this._validate());
        this._onChangeCallback = params.onChange;
    }

    get value(): UiT {
        return this._value;
    }

    set value(value: UiT) {
        // if (typeof this._value !== typeof value) {
        //     console.error(`attempt to patch ${this.name} with value of different type than current`);
        //     return;
        // }
        if (ObjectUtils.areObjectsEqual(this._value, value) || value === null) {
            return;
        }
        this._value = ObjectUtils.deepFreeze(value);
        if (this.isValid()) {
            this._onChangeCallback(this._value!, this);
        }
    }

    _validate(): Result<UiT> {
        let errors: string[] | undefined = undefined;
        let resultValue: UiT = this.value;
        for (const v of this._validators) {
            try {
                const r = v(this._value, this as any);
                if (r instanceof Failure) {
                    (errors || (errors = [])).push(r.errorMsg());
                } else {
                    resultValue = r.value;
                }
            } catch (e) {
                console.error(e);
                errors?.push('unexpected error');
            }
        }
        if (errors?.length) {
            return new Failure({uiMsg: errors.join(';')});
        }
        return new Success(resultValue);
    }


    isValid(): boolean {
        return this.validationResult.poll() instanceof Success;
    }

    hasError(): string | null {
        const r = this.validationResult.poll();
        if (r instanceof Failure) {
            return r.uiMsg ?? '';
        }
        return null;
    }

    defaultTypeSortKey(): number {
        return 10;
    }
}

export type PUI_PropertyChangeCallback <T> = (newValue: NonNullable<T>, propNode: PUI_PropertyNode<T>) => void;

export interface PUI_PropertyNodeArgs<T> extends PUI_NodeArgs {
    description?: string | null,
    value?: T,
    defaultValue?: T,
    readonly?: boolean,
    calculated?: boolean,
    validator?: PUI_PropertyValidator<T>,
    notActive?: boolean,
    // transformer?: PUI_ConfigPropertyTransformer<any, T, any>,
    onChange: PUI_PropertyChangeCallback<T>;
}
export type PUI_PropertyValidator<T> = (uiValue: T, propertyRef: PUI_PropertyNode<T>) => Result<T>;


export class PUI_PropertyNodeBool extends PUI_PropertyNode<boolean | null> {
}
export type PUI_PropertyNodeBoolArgs = PUI_PropertyNodeArgs<boolean | null>;

export class PUI_PropertyNodeString extends PUI_PropertyNode<string | null> {
    readonly isTextarea?: boolean;
    readonly placeholder?: string;
    readonly tag?: LazyVersioned<string>;
    constructor(params: PUI_PropertyNodeStringArgs) {
        super(params);
        this.isTextarea = params.isTextarea;
        this.placeholder = params.placeholder;
        this.tag = params.tag;
    }
}
export interface PUI_PropertyNodeStringArgs extends PUI_PropertyNodeArgs<string | null> {
    isTextarea?: boolean;
    placeholder?: string;
    tag?: LazyVersioned<string>;
};


export class PUI_PropertyNodeNumber extends PUI_PropertyNode<number | null> {
    minMax: [number, number];
    step: number;
    unit?: string;
    valueRenderFormatter?: PUI_PropertyValueRenderFormatter<number | null>;
    tag?: LazyVersioned<string>;
    icon?: IconDescription;

    constructor(
        params: PUI_PropertyNodeNumberArgs
    ) {
        super(params);
        this.minMax = params.minMax ?? [-1_000_000_000, 1_000_000_000];
        this.step = params.step ?? 0.0001;
        this.unit = params.unit;
        this.valueRenderFormatter = params.valueRenderFormatter;
        this.tag = params.tag;
        this.icon = params.icon;
        this._validators.unshift(PUI_PropertyNodeNumber.rangeValidator as PUI_PropertyValidator<number | null>);
        if (this.step == 1 && !this.unit) {
            this._validators.unshift(PUI_PropertyNodeNumber.isIntegerValidator  as PUI_PropertyValidator<number | null>);
        }
    }

    setValueUnit(value: number | null, unit: string | undefined) {
        if (this.value === value && this.unit === unit) {
            return;
        }
        this._value = value;
        this.unit = unit;
        if (this.isValid()) {
            this._onChangeCallback(this._value!, this);
        }
    }


    static isIntegerValidator(value: any, _property: PUI_PropertyNodeNumber): Result<number | null> {
        if (Number.isInteger(value)) {
            return new Success(Number(value));
        }
        if (value === null) {
            return new Success(value);
        }
        return new Failure({msg: `integer excepted`});
    }

    static rangeValidator(value: any, property: PUI_PropertyNodeNumber): Result<number | null> {
        if (value >= property.minMax[0] && value <= property.minMax[1]) {
            return new Success(Number(value));
        }
        if (value === null) {
            return new Success(value);
        }
        return new Failure({msg: `number between ${property.minMax[0]} ${property.minMax[1]} expected`});
    }
}

export type PUI_PropertyRenderColor = 'green' | 'red' | 'inherit' | 'lightgreen' | 'primary' | '#10131461';
export type PUI_PropertyValueRenderFormatter <T> = (value: T) => PUI_PropertyRenderColor;
export interface IconDescription {
    iconName: string;
    onClick: () => void;
}
export interface PUI_PropertyNodeNumberArgs extends PUI_PropertyNodeArgs<number | null> {
    minMax?: [number, number];
    step?: number,
    unit?: string,
    valueRenderFormatter?: PUI_PropertyValueRenderFormatter<number | null>,
    tag?: LazyVersioned<string>;
    icon?: IconDescription;
}

export class PUI_PropertyNodeColor extends PUI_PropertyNode<RGBAHex> {

    constructor(params: PUI_PropertyNodeArgs<RGBAHex>) {
        super(params);
    }
}
export interface PUI_PropertyNodeColorArgs extends PUI_PropertyNodeArgs<RGBAHex> {
}

export interface SelectorOption<T> {
    value: T;
    label?: string;
    group?: string;
    disabled?: boolean;
}
export class PUI_PropertyNodeSelector<T extends string | number = string> extends PUI_PropertyNode<T | null> {
    options: SelectorOption<T>[] = [];
    tag?: LazyVersioned<string>;
    showAsButtons: boolean;

    constructor(params: PUI_PropertyNodeSelectorArgs<T>) {
        super(params);
        this.options = params.options.length && typeof params.options[0] === 'string'
            ? params.options.map((option) => ({
                value: option as string,
                label: option as string
            }) as SelectorOption<T>)
            : <SelectorOption<T>[]>params.options;
        this._validators.unshift((option) => {
            if (option !== null && !this.options.find(o => o.value === option)) {
                const message = `one of [${this.options.join()}] options is expected`;
                console.error(message);
                return new Failure({msg: message});
            }
            return new Success(option);
        });
        this.tag = params.tag;
        this.showAsButtons = params.showAsButtons ?? false;
    }
}
export class PUI_TabSelector extends PUI_PropertyNodeSelector<string> {}

export interface PUI_PropertyNodeSelectorArgs<T extends string | number = string> extends PUI_PropertyNodeArgs<T | null> {
    options: T extends number ? Array<SelectorOption<number>> : Array<string | SelectorOption<string>>;
    tag?: LazyVersioned<string>;
    showAsButtons?: boolean;
}
export interface MultiSelectorValue extends SelectorOption<string | number> {
    invalid?: boolean;
    [key: string]: unknown;
}

export interface PUI_PropertyNodeMultiSelectorArgs extends PUI_PropertyNodeArgs<MultiSelectorValue[]> {
    options: MultiSelectorValue[];
    maxSelect?: number;
    enableSelectAll?: boolean;
    doubleLine?: boolean;
}

export class PUI_PropertyNodeMultiSelector extends PUI_PropertyNode<MultiSelectorValue[]> {
    options: MultiSelectorValue[] = [];
    maxSelect?: number;
    enableSelectAll?: boolean;
    doubleLine?: boolean;

    constructor(params: PUI_PropertyNodeMultiSelectorArgs) {
        super(params);
        this.options = params.options;
        this.maxSelect = params.maxSelect;
        this.enableSelectAll = params.enableSelectAll;
        this.doubleLine = params.doubleLine;
        this._validators.unshift((options) => {
            const current = this.options.map(o=>o.value);
            for (const op of options) {
                if (!current.includes(op.value)) {
                    const message = `allowed options are [${this.options.join()}]`;
                    console.error(message);
                    return new Failure({msg: message});
                }
            }
            return new Success(options);
        });
    }
}

export class PUI_PropertyNodeVec2 extends PUI_PropertyNode<Vector2> {
    step: number;
    unit?: string;

    constructor(
        params: PUI_PropertyNodeVec2Args
    ) {
        super(params);
        this.step = params.step ?? 0.0001;
        this.unit = params.unit;
    }
}
export interface PUI_PropertyNodeVec2Args extends PUI_PropertyNodeArgs<Vector2> {
    step?: number;
    unit?: string;
}

export class PUI_PropertyNodeVec3 extends PUI_PropertyNode<Vector3> {
    step: number;
    unit?: string;

    constructor(
        params: PUI_PropertyNodeVec3Args
    ) {
        super(params);
        this.step = params.step ?? 0.0001;
        this.unit = params.unit;
    }
}
export interface PUI_PropertyNodeVec3Args extends PUI_PropertyNodeArgs<Vector3> {
    step?: number;
    unit?: string;
}

export class PUI_CustomPropertyNode<T, Context = undefined> extends PUI_PropertyNode<T> {
    context: Context;
    type_ident: string;

    constructor(args: PUI_CustomPropertyNodeArgs<T, Context>) {
        super(args);
        this.context = args.context;
        this.type_ident = args.type_ident;
    }

    defaultTypeSortKey(): number {
        return 10;
    }
}
export interface PUI_CustomPropertyNodeArgs<T, Context = undefined> extends PUI_PropertyNodeArgs<T> {
    context: Context,
    type_ident: string;
}


export class PUI_OperationInProgressNode extends PUI_PropertyNode<InProgress> {
    constructor(params: PUI_OperationInProgressNodeArgs) {
        super(params);
    }
}
export interface PUI_OperationInProgressNodeArgs extends PUI_PropertyNodeArgs<InProgress> {
}

export class PUI_FailureNode extends PUI_PropertyNode<Failure> {
    constructor(params: PUI_FailureNodeArgs) {
        super(params);
    }
}
export interface PUI_FailureNodeArgs extends PUI_PropertyNodeArgs<Failure> {
}


interface SwitcherOption {
    value: string | number;
    label: string;
    disabled?: boolean;
    option?: SwitchAdditionalOption;
    tooltip?: string;
}

interface SwitchAdditionalOption {
    label: string;
    value: boolean;
    onClick: (newValue: boolean) => void;
    disabled?: boolean;
}

export class PUI_PropertyNodeSwitcher extends PUI_PropertyNode<string | number> {
    options: SwitcherOption[] = [];

    constructor(params: PUI_PropertyNodeSwitcherArgs) {
        super(params);
        this.options = params.options;
    }
}

export interface PUI_PropertyNodeSwitcherArgs extends PUI_PropertyNodeArgs<string | number> {
    options: SwitcherOption[];
};

export interface SceneInstancesSelectorValue {
    value: number; // IdBimScene
    label?: string;
    readonly?: boolean;
}
export interface TextButtonSettings {
    action: () => void;
	isEnabled?: LazyVersioned<boolean>;
    label: string;
}

export type ItemErrorMsg = (string | TextButtonSettings) [];
export class PUI_SceneInstancesSelectorPropertyNode extends PUI_PropertyNode<SceneInstancesSelectorValue[]> {
    maxSelect: number | null;
    types: string[];
    createInstance?: (ui: UiBindings) => void;
    filterItems?: (id: number) => boolean;
    hasItemsError?: LazyVersioned<Map<number, ItemErrorMsg>>;
    hasErrorInGroups?: LazyVersioned<Map<string, ItemErrorMsg>>;
    customSelectedItemsMessage?: (selected: number[], allItems: number[]) => string;
    
    constructor(params: PUI_SceneInstancesSelectorPropertyNodeArgs) {
        super(params);
        this.types = params.types;
        this.maxSelect = params.maxSelect ?? null;
        this.createInstance = params.createInstance;
        this.filterItems = params.filterItems;
        this.hasItemsError = params.hasItemsError;
        this.hasErrorInGroups = params.hasErrorInGroups;
        this.customSelectedItemsMessage = params.customSelectedItemsMessage;
    }
}

export interface PUI_SceneInstancesSelectorPropertyNodeArgs extends PUI_PropertyNodeArgs<SceneInstancesSelectorValue[]> {
    types: string[];
    maxSelect?: number | null;
    createInstance?: (ui: UiBindings) => void;
    filterItems?: (id: number) => boolean;
    hasItemsError?: LazyVersioned<Map<number, ItemErrorMsg>>;
    hasErrorInGroups?: LazyVersioned<Map<string, ItemErrorMsg>>;
    customSelectedItemsMessage?: (selected: number[], allItems: number[]) => string;
};

interface SliderSettings { 
    minValueLabel?: string;
    maxValueLabel?: string;
    message?: string;
}

export class PUI_PropertyNodeSlider extends PUI_PropertyNode<number> {
    minMax: [number, number];
    step: number;
    settings?: SliderSettings;

    constructor(params: PUI_PropertyNodeSliderArgs) {
        super(params);
        this.minMax = params.minMax;
        this.settings = params.settings;
        this.step = params.step ?? 1;
    }
}

export interface PUI_PropertyNodeSliderArgs extends PUI_PropertyNodeArgs<number> {
    step?: number;
    minMax: [number, number];
    settings?: SliderSettings;
};

export class PUI_ActionsNode extends PUI_Node {

    context: any;
    actions: PUI_ActionDescr<any>[];
    description: string | undefined;

    constructor(
        params: PUI_ActionsNodeArgs<any>,
    ) {
        super(params);
        this.context = params.context;
        this.actions = params.actions;
        this.description = params.description;
    }

    defaultTypeSortKey(): number {
        return 10;
    }
}
export interface PUI_ActionDescr<Context> {
    action: (context: Context) => void;
	isEnabled?: LazyVersioned<boolean>;
    label: string;
    hint?: string;
    style?: {
        type: 'primary' | 'secondary' | 'outlined' | 'text',
        compact?: boolean,
        icon?: string,
    },
}
export class PropertyActionsValue {
    constructor(
        public readonly actions: PUI_ActionDescr<any>[]
    ) {
    }
}
export interface PUI_ActionsNodeArgs<Context> extends PUI_NodeArgs {
    description?: string,
    actions: PUI_ActionDescr<any>[],
    context: Context;
}

export interface PUI_GroupNodeArgs extends PUI_NodeArgs {
    sortChildren?: boolean;
    status?: string;
    collapsible?: boolean;
    showTitle?: boolean;
}

export class PUI_GroupNode extends PUI_Node {

    readonly children: ReadonlyMap<string, PUI_Node> = new Map();

    readonly sortChildren: boolean;
    readonly status?: string;
    readonly collapsible?: boolean;
    readonly showTitle?: boolean;

    constructor(params: PUI_GroupNodeArgs) {
        super(params);
        this.sortChildren = params.sortChildren ?? true;
        this.status = params.status;
        this.collapsible = params.collapsible ?? true;
        this.showTitle = params.showTitle ?? true;
    }

    addMaybeChild(childNode?: PUI_Node | null) {
        if (childNode) {
            (this.children as Map<string, PUI_Node>).set(childNode.name!, childNode);
        }
    }

    defaultTypeSortKey(): number {
        return 100;
    }

    childrenOrdered(): PUI_Node[] {
        const childrenArray = Array.from(this.children.values());
        if (this.sortChildren) {
            childrenArray.sort(PUI_Node.sortNodes);
        }
        return childrenArray;
    }

    hasParent(): boolean {
        return !!this.parent;
    }

    isRootNode(): boolean {
        return !this.parent;
    }

    static tryGetNestedChild(node: PUI_GroupNode, path: string[]): PUI_Node | undefined {
        let nodeTmp: PUI_Node = node;
        for (const nextChildId of path) {
            if (!(nodeTmp instanceof PUI_GroupNode)) {
                return;
            }
            const nextChild = nodeTmp.children.get(nextChildId);
            if (!nextChild) {
                return;
            }
            nodeTmp = nextChild;
        }
        return nodeTmp;
    }
}


export class PUI_CustomNode<Context> extends PUI_Node {
    context: Context;
    type_ident: string;

    constructor(args: PUI_CustomNodeArgs<Context>) {
        super(args);
        this.context = args.context;
        this.type_ident = args.type_ident;
    }
    defaultTypeSortKey(): number {
        return 10;
    }
}
export interface PUI_CustomNodeArgs<Context> extends PUI_NodeArgs {
    context: Context,
    type_ident: string;
}

export abstract class PUI_CustomGroupNodeChildren {
    [key: string]: PUI_Node | PUI_Node[];
}

export interface PUI_CustomGroupNodeArgs<Children extends PUI_CustomGroupNodeChildren, Context = undefined> extends PUI_NodeArgs {
    children: Children;
    context: Context;
}

export class PUI_CustomGroupNode<Children extends PUI_CustomGroupNodeChildren, Context = undefined> extends PUI_Node {
    readonly children: Children;
    readonly context: Context;

    constructor(args: PUI_CustomGroupNodeArgs<Children, Context>) {
        super(args);
        this.children = args.children;
        this.context = args.context;
    }

    defaultTypeSortKey(): number {
        return 100;
    }
}

// export class OptionSelector extends UiNode {

//     options: string[];
//     selectMultiple?: boolean;
//     dropDown?: boolean;

//     _values: number[] = []; // if multiselect - mutliple indicies to options array, if single select - one option
//     default: number[] = [];

//     constructor(params: {
//         name: string,
//         hint?: string,
//         options: string[],
//         default?: string | string[],
//         selectMultiple?: boolean,
//         dropDown?: boolean,
//     }) {
//         super(params.name, params.hint);
//         this.options = params.options;
//         this.dropDown = params.dropDown;
//         if (params.default) {
//             if (params.default instanceof Array) {
//                 for (const d of params.default) {
//                     const index = this._indexOfOption(d);
//                     if (!this.default.includes(index)) {
//                         this.default.push(index);
//                     }
//                 }
//             } else if (typeof params.default == 'string') {
//                 this.default[0] = this._indexOfOption(params.default);
//             } else {
//                 console.error('options default should be string or string[] type');
//             }
//         }
//     }

//     _indexOfOption(option: string): number {
//         let index = this.options.indexOf(option);
//         if (index < 0) {
//             console.warn(option, 'is not valid option');
//             index = 0;
//         }
//         return index;
//     }
// }
