import type { BasicCollectionUpdates, ScopedLogger, VersionedValue } from 'engine-utils-ts';
import { Allocated, Deleted, LegacyLogger, ObservableStream } from 'engine-utils-ts';

import type { index } from '../utils/Utils';
import {
    createHandle, disabledHandle, HandleEnabledMask, HandleIndexMask, indexFromHandle, isHandleEnabled, nextGenHandle
} from './Handle';

export interface Allocator<TStruct> {
	allocate(index: number, argument:TStruct): boolean;
	free(indexes: number[]): void;
	changeCapacity(newCapacity: number): void;
}

export type ArgumentGetter<TArgs, TSingleArg> = (args: TArgs) => TSingleArg;


export class AllocationSynchronizer<THandleType extends number, TAllocArgs> implements VersionedValue {

	readonly logger: ScopedLogger;

	readonly updatesStream: ObservableStream<BasicCollectionUpdates<THandleType>>;

	readonly handles: THandleType[] = [];
	private readonly deletedIndices: index[] = []; // note: consider using sorted list instead
	
	readonly boundAllocators: Allocator<any>[] = []; 
	readonly boundArgumentExtractors: ArgumentGetter<TAllocArgs, any>[] = [];

	_capacity: number = 0;

	_version: number = 0;

	_allActiveVersion = 0;
	readonly _allActiveHandles: THandleType[] = [];
	readonly _allActiveIndices: index[] = [];
	private _onCapacityChangeCallbacks: ((newCapacty: number) => void)[] = [];

	constructor(
		identifier: string,
		logger: ScopedLogger
	) {
		this.logger = logger.newScope('alloc-syncer');
		this.updatesStream = new ObservableStream({
			identifier,
			defaultValueForNewSubscribersFactory: () => {
				const handles = this.getActiveHandles().slice();
				return new Allocated(handles);
			}
		})
	}

	version(): number {
		return this._version;
	}
	
	onCapacityChange(callback: (newCapacty: number) => void) {
		this._onCapacityChangeCallbacks.push(callback);
		callback(this._capacity);
	}

	getHandles(): Iterable<THandleType> {
		return this.handles;
	}

	handleFromIndex(index: index): THandleType { // temporal, this should not be necessary
		return this.handles[index];
	}

	_recollectActive() {
		LegacyLogger.debugAssert(this._allActiveVersion !== this._version, 'recollect handles only when stale');
		this._allActiveVersion = this._version;
		const handlesResult = this._allActiveHandles;
		const indsRsult = this._allActiveIndices;
		indsRsult.length = 0;
		handlesResult.length = 0;
		for (const h of this.handles) {
			if (h & HandleEnabledMask) {
				handlesResult.push(h);
				indsRsult.push(indexFromHandle(h));
			}
		};
	}

	getActiveHandles(): ReadonlyArray<THandleType> {
		if (this._allActiveVersion !== this._version) {
			this._recollectActive();
		}
		return this._allActiveHandles;
	}

	getActiveIndices(): ReadonlyArray<index> {
		if (this._allActiveVersion !== this._version) {
			this._recollectActive();
		}
		return this._allActiveIndices;
	}

	getActiveCount(): number {
		return this.getActiveIndices().length;
	}
	
	length() {
		return this.handles.length
	}

	_bindAllocator<TStruct>(allocator: Allocator<TStruct>, argumentGetter: ArgumentGetter<TAllocArgs, TStruct>) {
		this.boundAllocators.push(allocator);
		this.boundArgumentExtractors.push(argumentGetter);
	}

	tryGetIndex(handle: THandleType): number | -1 {
		const index = handle & HandleIndexMask;
		if ((handle & HandleEnabledMask) && (this.handles[index] === handle)) {
			return index;
		}
		// LegacyLogger.deferredWarn('tryGetIndex: invlid handle', new Error().stack);
		return -1;
	}

	mapToValidIndices(handles: Iterable<THandleType>): index[] {
		const res = [];
		for (const h of handles) {
			const index = indexFromHandle(h);
			if ((h & HandleEnabledMask) && (this.handles[index] === h)) {
				res.push(index);
			} else {
				LegacyLogger.warn('invalid handle, index: ', index);
			}
		}
		return res;
	}

	allocate(argsArr: TAllocArgs[]): THandleType[] {

		const handlesToUse = argsArr.map(_ => {
			let handle: THandleType;
			let index: number;
			if (this.deletedIndices.length > 0) {
			
				index = this.deletedIndices.pop()!;
				const oldHandleOfIndex = this.handles[index];
				handle = nextGenHandle(oldHandleOfIndex) as THandleType;
			} else {
				if (this.handles.length === this._capacity) {
					let nextCapacity = Math.max(this._capacity, 32) * 2;
					this.changeCapacity(nextCapacity);
				}
				index = this.handles.length;
				handle = createHandle(index) as THandleType;
			}
			this.handles[index] = handle;
			return handle;
		});



		for (let allocI = 0; allocI < this.boundAllocators.length; ++allocI) {
			const argumentExtractor = this.boundArgumentExtractors[allocI];
			const allocator = this.boundAllocators[allocI];

			for (let i = 0; i < argsArr.length; ++i) {
				const args = argsArr[i];
				const argument = argumentExtractor(args);
				const handle = handlesToUse[i];
				const index = handle & HandleIndexMask;
	
				const allocationSuccess = allocator.allocate(index, argument);
				if (!allocationSuccess) {
					this.logger.error('allocation should be successfull always, other case is not implemented');
				}
			}
		}

		if (handlesToUse.length) {
			++this._version;
		}

		this.logger.assert(handlesToUse.length === argsArr.length, 'result length sanity check');

		this.updatesStream.pushNext(new Allocated(handlesToUse));

		return handlesToUse;
	}

	delete(handles: THandleType[]): { removed:  THandleType[] } {

		const removed: THandleType[] = [];
		for (const handle of handles) {
			const index = handle & HandleIndexMask;
			if (!(this.handles.length > index)) {
				this.logger.error('delete: invalid handle, index out of bounds', handle, index);
				continue;
			}
			if (this.handles[index] !== handle) {
				this.logger.error('delete: invalid handle, allocator handle isnt same', handle, this.handles[index]);
				continue;
			}
			this.deletedIndices.push(index);
			this.handles[index] = disabledHandle(this.handles[index]) as THandleType;
			++this._version;
			removed.push(handle);
		}

		const submeshesIndexes = removed.map(handle => handle & HandleIndexMask);
		for (let i = 0; i < this.boundAllocators.length; ++i) {
			const allocator = this.boundAllocators[i];
			allocator.free(submeshesIndexes);
		}
		submeshesIndexes.length = 0;
		this.deletedIndices.sort((n1, n2) => n2 - n1);
		this.updatesStream.pushNext(new Deleted(removed));
		return { removed };
	}


	
	changeCapacity(newCapacity: number) {
		++this._version;
		if (newCapacity < this._capacity) {
			for (let i = newCapacity; i < this._capacity; ++i){
				const handle = this.handles[i];
				if (!isHandleEnabled(handle)) {
					this.deletedIndices.push(i);
				}
			}
			this.handles.length = newCapacity;
		}
		this._capacity = newCapacity;
		for (const allocator of this.boundAllocators) {
			allocator.changeCapacity(newCapacity);
		}
	}
}

