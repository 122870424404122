import type { LazyVersioned} from "engine-utils-ts";
import { LazyDerived } from "engine-utils-ts";
import type { GraphicsSettings } from "./GraphicsSettings";
import type { EngineTerrainPalette } from "./terrain/EngineTerrainPalette";
import type { TerrainDisplayEngineSettings } from "./TerrainDisplayEngineSettings";

export type EngineFullGraphicsSettings = {
    readonly graphics: Readonly<GraphicsSettings>,
    readonly terrainDisplay: Readonly<TerrainDisplayEngineSettings>,
    readonly terrainPalette: Readonly<EngineTerrainPalette>,
};


export function newFullGraphicsSettings(
    engineGraphics: LazyVersioned<GraphicsSettings>,
    terrainDisplay: LazyVersioned<TerrainDisplayEngineSettings>,
    terrainPalette: LazyVersioned<EngineTerrainPalette>,
): LazyVersioned<EngineFullGraphicsSettings> {
    return LazyDerived.new3(
        'engine-full-graphics',
        [],
        [engineGraphics, terrainDisplay, terrainPalette],
        ([graphics, terrainDisplay, terrainPalette]) => {
            return {
                graphics: graphics,
                terrainDisplay: terrainDisplay,
                terrainPalette: terrainPalette,
            };
        }
    ).withoutEqCheck();
}

