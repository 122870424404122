import { WGSCoord, TmyLocationContext, StringProperty, NumberProperty } from 'bim-ts';

export interface MeteoLocationData {
    lat: string | number;
    long: string | number;
    alt: string | number;
    timeZone: string | number;
}

export function tryReadMeteoLocationData(args: MeteoLocationData): TmyLocationContext | undefined {
    const lat = parseFloat(args.lat + "");
    const long = parseFloat(args.long + "");
    const timeZone = parseFloat(args.timeZone + "");
    const altitude = parseFloat(args.alt + "");

    const coords = WGSCoord.tryParseLatLongFromString(`${lat}, ${long}`);
    if (!coords) {
        return undefined;
    }
    if (!Number.isFinite(altitude) || altitude < -1000 || altitude > 10000) {
        return undefined;
    }
    if (!Number.isInteger(timeZone) || !(timeZone >= -12 && timeZone <= 12)) {
        return undefined;
    }
    return new TmyLocationContext({
        geoLocation: StringProperty.new({value: `${lat}, ${long}`}),
        altitude: NumberProperty.new({value: altitude, range: [-1000, 10000], unit: 'm'}),
        timeZone: NumberProperty.new({value: timeZone, range: [-12, 12], step: 1}),
    });
}