import type { LazyVersioned} from "engine-utils-ts";
import { DefaultMapObjectKey, IterUtils, LazyDerivedAsync, ObjectUtils, Yield, replaceCurrencyUnitWithSymbol, unitsConverter } from "engine-utils-ts";
import { NumberProperty, PileFeaturesAndOffsets, PileUndulationType, TrackerBins, getPileFeaturesDefaultAbbreviatedName, pileCrossSectionToString, pileWeightPerSize} from "src";
import type { Bim, IdBimScene, IdPile, PileFeaturesFlags, TrackerPile, TrackerPilesCollection } from "src";
import type { CostComponents, CostComponentsNonNullable, CostsConfigProvider, EstimateCost, InstanceCost, SingleEstimateProvider} from "src/cost-model/capital";
import { CostHierarchy, CostSourceType, createEmptyCostComponents, createEmptyCostComponentsNonNullable, createMiscCategory, fillModelBasedCostCategory, fillModelBasedTopLevelCategory, multiplyCosts } from "src/cost-model/capital";
import { PileKeyProps} from "src/piles/common";


export function create_Piles(
    bim: Bim,
    provider: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>,
    piles: TrackerPilesCollection,
) {

    const grouped = createGroupedPiles(piles);

    const costs = provider.lazyInstanceCostsByType(`tracker-pile`);

    const result = LazyDerivedAsync.new4<
        CostHierarchy,
        UniquePileGroup[],
        EstimateCost[],
        InstanceCost[],
        NumberProperty
    >(
        'piles',
        [bim.unitsMapper],
        [
            grouped,
            provider.allEstimateCosts,
            costs,
            totalDC,
        ],
        function* ([grouped, estimates, costs, totalDC]) {
            const costUnit = bim.unitsMapper.mapToConfigured({ value: 0, unit: 'usd' }).unit!;
            const massUnit = bim.unitsMapper.mapToConfigured({ value: 0, unit: 'kg' }).unit!;
            const massUnitInKg = NumberProperty.new({ value: 1, unit: massUnit }).as('kg');

            // extract final result
            const hierarchyEach = new CostHierarchy();
            const hierarchyMass = new CostHierarchy();

            const types = IterUtils.groupBy(grouped, x => x.keyProps.type.asNumber());
            const bins = new TrackerBins({})
            const typeEachItems: Array<[name: string, hierarchy: CostHierarchy]> = [];
            const typeMassItems: Array<[name: string, hierarchy: CostHierarchy]> = [];
            for (const type of types) {
                const typeEach = new CostHierarchy();
                const typeMass = new CostHierarchy();

                const sizes = IterUtils.groupBy(type[1], x => x.keyProps.size.asNumber())
                for (const size of sizes) {
                    const totalIds = new Set<IdBimScene>();
                    size[1].forEach(x => x.arrayIds.forEach(y => totalIds.add(y)));
                    const keyProps = size[1][0].keyProps;

                    // each
                    {
                        const overrides = costs.find(x =>
                            x.name === 'each' &&
                            ObjectUtils.areObjectsEqual(x.props, keyProps)
                        )?.costs ?? createEmptyCostComponents();
                        const defaults = createDefaultPileCostsPerEach();
                        const totalPileCount = IterUtils.sum(size[1], x => x.totalPileCount);
                        const category = typeEach.add({
                            description: { value: pileCrossSectionToString(keyProps.size.asNumber()) },
                            relatedSceneInstanceIds: totalIds,
                            costUnit: {
                                options: [replaceCurrencyUnitWithSymbol(costUnit) + '/each' ],
                                index: 0,
                            },
                            quantity: {
                                value: NumberProperty.new({ value: totalPileCount }),
                                integer: true,
                            },
                        })[1];


                        const updateCosts = (newCosts: CostComponents) => {
                            provider.findAndUpdateInstanceCost(
                                (prev) => (prev.costs = newCosts, prev),
                                { instance_type: `tracker-pile`, props: keyProps, name: 'each' }
                            )
                        }

                        fillModelBasedCostCategory(category, overrides, defaults, updateCosts, totalPileCount);
                    }

                    // mass
                    {
                        let overrides = costs.find(x =>
                            x.name === 'kg' &&
                            ObjectUtils.areObjectsEqual(x.props, keyProps)
                        )?.costs ?? createEmptyCostComponents();
                        overrides = multiplyCosts(overrides, massUnitInKg);
                        const defaults = multiplyCosts(createDefaultPileCostsPerKg(), massUnitInKg);
                        const totalMassKg = IterUtils.sum(size[1], x => x.totalMassKg);
                        const category = typeMass.add({
                            description: { value: pileCrossSectionToString(keyProps.size.asNumber()) },
                            relatedSceneInstanceIds: totalIds,
                            costUnit: {
                                options: [replaceCurrencyUnitWithSymbol(costUnit) + '/' + massUnit],
                                index: 0,
                            },
                            quantity: { value: NumberProperty.new({ value: totalMassKg, unit: 'kg' }) },
                        })[1];


                        const updateCosts = (newCosts: CostComponents) => {
                            provider.findAndUpdateInstanceCost(
                                (prev) => (prev.costs = multiplyCosts(newCosts, 1 / massUnitInKg), prev),
                                { instance_type: `tracker-pile`, props: keyProps, name: 'kg' }
                            )
                        }

                        fillModelBasedCostCategory(category, overrides, defaults, updateCosts, totalMassKg / massUnitInKg);
                    }

                }
                const featureFlagsPacked: PileFeaturesFlags = type[0];
                const abbr = getPileFeaturesDefaultAbbreviatedName(featureFlagsPacked);
                const description = bins.getPileFullName(featureFlagsPacked);
                const name = description + `(${abbr})`;

                const categoryEach = typeEach.addRoot({ description: { value: name } });
                typeEach.sumupChildren(categoryEach[0], { includeQuantity: true });
                typeEachItems.push([name, typeEach]);

                const categoryMass = typeMass.addRoot({ description: { value: name } });
                typeMass.sumupChildren(categoryMass[0], { includeQuantity: true });
                typeMassItems.push([name, typeMass]);
            }

            typeMassItems.sort((l, r) => l[0].localeCompare(r[0]))
            typeEachItems.sort((l, r) => l[0].localeCompare(r[0]))

            hierarchyEach.merge(...typeEachItems.map(x => x[1]))
            hierarchyMass.merge(...typeMassItems.map(x => x[1]))

            // create Pile install(each)
            {
                const category = hierarchyEach.addRoot({ description: { value: 'Pile Install (each)' } })
                hierarchyEach.sumupChildren(category[0], { includeQuantity: true });
            }

            // create Pile install(Mass)
            {
                const category = hierarchyMass.addRoot({ description: { value: 'Pile Material (weight)' } })
                hierarchyMass.sumupChildren(category[0], { includeQuantity: true });
            }

            const hierarchy = new CostHierarchy().merge(hierarchyEach, hierarchyMass);

            // create misc cable misc each
            {
                const id = 'piles-misc';
                const state: SingleEstimateProvider = {
                    id: id,
                    defaults: createEmptyCostComponentsNonNullable(),
                    value: estimates.find(x => x.id.value === id),
                    update: (fn) => provider.findAndUpdateEstimateCost(id, fn),
                }
                hierarchy.add(createMiscCategory(state, bim.unitsMapper, totalDC, { defaultCostSource: CostSourceType.BenchmarkLumpSum }))
            }


            // create module install category
            {
                const root = hierarchy.addRoot({ description: { value: PilesCategoryName } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.materialCost = NumberProperty.new({ value: 0.06 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    hierarchy,
                    'piles-benchmark',
                    estimates,
                    provider.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC,
                );
                root[1].matchesSceneInstance = (query) => query.type_identifier === `tracker-pile`;
            }

            return hierarchy;
        }
    )
    return result
}

export const PilesCategoryName = 'Piles';

type UniquePileGroupData = {
    totalMassKg: number,
    arrayIds: Set<IdBimScene>,
    totalPileCount: number
}

interface UniquePileGroup extends UniquePileGroupData {
    keyProps: typeof PileKeyProps
}

export function createGroupedPiles(piles: TrackerPilesCollection) {
    const result = LazyDerivedAsync.new1<
        UniquePileGroup[],
        ReadonlyMap<IdPile, TrackerPile>
    >(
        'groupedPiles',
        [],
        [piles],
        function* ([pilesPerId]) {
            const uniquePileGroups = new DefaultMapObjectKey<typeof PileKeyProps, UniquePileGroupData>({
                unique_hash: (props) => {
                    return [
                        props.type.asNumber().toString(),
                        props.size.asNumber().toString(),
                    ].join('/')
                },
                valuesFactory: () => ({
                    totalMassKg: 0,
                    arrayIds: new Set(),
                    totalPileCount: 0,
                })
            })
            for (const chunk of IterUtils.splitIterIntoChunks(pilesPerId, 10e3)) {
                yield Yield.Asap;
                for (const [pileId, pile] of chunk) {
                    const size = PileKeyProps.size.withDifferentValue(pile.shape);
                    const features = PileFeaturesAndOffsets.fromPacked([pile.features, 0]);
                    features.undulation = PileUndulationType.Rigid;
                    const type = PileKeyProps.type.withDifferentValue(features.toPacked()[0]);
                    if (!size || !type || !pile.length) {
                        continue;
                    }
                    const group = uniquePileGroups.getOrCreate(Object.freeze({ size, type }));
                    const arrayId = piles.pilesPerTrackerId.getParent(pileId);
                    if (arrayId) {
                        group.arrayIds.add(arrayId);
                    }
                    group.totalPileCount += 1;
                    // store lenght, later will multiply by weight/length
                    group.totalMassKg += pile.length;
                }
            }
            for (const [k, v] of uniquePileGroups) {
                const weightValueUnit = pileWeightPerSize(k.size.value);
                const weightPerMeter = unitsConverter.convertValue(weightValueUnit.value, weightValueUnit.unit, 'kg/m');
                v.totalMassKg *= weightPerMeter;
            }

            const result: UniquePileGroup[] = Array.from(uniquePileGroups.entries())
                .map(([k, v]) => ({ keyProps: k, ...v }))

            return result;
        }
    )
    return result;
}

export function createDefaultPileCostsPerEach() {
    const costs: CostComponentsNonNullable = {
        ...createEmptyCostComponentsNonNullable(),
        laborTimeUnits: NumberProperty.new({ value: 0.5 }),
        laborTimeUnitCost: NumberProperty.new({ value: 45, unit: 'usd' }),
        laborCost: NumberProperty.new({ value: 45 * 0.5, unit: 'usd' }),
    }
    return costs
}

export function createDefaultPileCostsPerKg() {
    const costs: CostComponentsNonNullable = {
        ...createEmptyCostComponentsNonNullable(),
        materialCost: NumberProperty.new({ value: 2 * 2.2, unit: 'usd' })
    }
    return costs
}
