<svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M7.99901 11.3522C7.07227 11.3522 6.28205 11.0256 5.62838 10.3724C4.9747 9.71921 4.64786 8.92924 4.64786 8.0025C4.64786 7.07576 4.97445 6.28338 5.62764 5.62538C6.28083 4.96739 7.0708 4.6384 7.99754 4.6384C8.92429 4.6384 9.71666 4.96715 10.3747 5.62465C11.0326 6.28216 11.3616 7.07429 11.3616 8.00103C11.3616 8.92778 11.0329 9.71799 10.3754 10.3717C9.71788 11.0253 8.92575 11.3522 7.99901 11.3522ZM7.99838 10.0319C8.57437 10.0319 9.05729 9.83751 9.44714 9.44877C9.837 9.06001 10.0319 8.57764 10.0319 8.00167C10.0319 7.42568 9.83755 6.94276 9.44881 6.5529C9.06007 6.16304 8.5777 5.96812 8.00171 5.96812C7.42572 5.96812 6.9428 6.16249 6.55294 6.55123C6.16309 6.93998 5.96816 7.42234 5.96816 7.99833C5.96816 8.57432 6.16253 9.05724 6.55128 9.4471C6.94002 9.83696 7.42239 10.0319 7.99838 10.0319ZM3.08483 14.2355C2.72364 14.2355 2.41329 14.1058 2.15379 13.8462C1.89428 13.5868 1.76453 13.2764 1.76453 12.9152V10.1333H3.08483V12.9152H5.86671V14.2355H3.08483ZM10.1334 14.2355V12.9152H12.9153V10.1333H14.245V12.9152C14.245 13.2764 14.1143 13.5868 13.853 13.8462C13.5916 14.1058 13.279 14.2355 12.9153 14.2355H10.1334ZM1.76453 5.86667V3.08478C1.76453 2.721 1.89428 2.40844 2.15379 2.14708C2.41329 1.88574 2.72364 1.75507 3.08483 1.75507H5.86671V3.08478H3.08483V5.86667H1.76453ZM12.9153 5.86667V3.08478H10.1334V1.75507H12.9153C13.279 1.75507 13.5916 1.88574 13.853 2.14708C14.1143 2.40844 14.245 2.721 14.245 3.08478V5.86667H12.9153Z"
        fill="currentColor"
    />
</svg>
