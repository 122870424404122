<script lang="ts">
import type { UnitsMapper } from "bim-ts";
import { getContext } from "svelte";
import { PUI_PropertyNodeString } from "ui-bindings";
import type { VersionedStore } from "../../../VersionedStore";
import PropertyView from "../../PropertyView.svelte";
import type { ArrayVariation } from "./FrameModuleMatcher";

export let description: ArrayVariation;

const unitsMapper = getContext<VersionedStore<UnitsMapper>>('unitsMapper');

$: modules =
    description.module.moduleModel + " " + description.module.modulePower.valueUnitUiString($unitsMapper)

$: moduleGeometry =
    description.module.moduleWidth.valueUnitUiString($unitsMapper) +
    " x " +
    description.module.moduleLength.valueUnitUiString($unitsMapper)

$: modulesInStringTotal =
    description.trackerFrame.modulesInString + "/" +
    description.trackerFrame.modulesTotal +
    "(" + description.trackerFrame.frameTotalLength.valueUnitUiString($unitsMapper) + ")";

</script>

<PropertyView offset={0} property={new PUI_PropertyNodeString({
    name: 'Rack series',
    value: description.trackerFrame.frameSeries,
    onChange: () => {},
    readonly: true,
})} />

<PropertyView offset={0} property={new PUI_PropertyNodeString({
    name: 'Modules',
    value: modules,
    onChange: () => {},
    readonly: true,
})} />

<PropertyView offset={0} property={new PUI_PropertyNodeString({
    name: 'Modules geometry',
    value: moduleGeometry,
    onChange: () => {},
    readonly: true,
})} />

<PropertyView offset={0} property={new PUI_PropertyNodeString({
    name: 'Modules in string/total',
    value: modulesInStringTotal,
    onChange: () => {},
    readonly: true,
})} />

<style lang="scss">
</style>
