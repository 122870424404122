import { CostModel as CM } from 'bim-ts';
import { ColDef, ICellEditorParams, ValueSetterParams } from "ag-grid-enterprise";
import { canEditCommonCell, commonCellClasses, getContext } from './common';

export const createCostUnitColumnDefinition = (): ColDef<CM.FlattenedCostCategoryParams> => ({
    colId: CM.CostUnitColId,
    headerName: CM.CostUnitColId,
    cellEditor: 'agRichSelectCellEditor',
    valueGetter: (params) => {
        const { category } = getContext(params)
        if (!category) {
            return;
        }
        const index = category.costUnit?.index
        if (index === undefined) {
            return;
        }
        return category.costUnit?.options?.[index];
    },

    editable: () => {
        return false;
        //const context = getContext(params);
        //if (!canEditCommonCell(context, context.category?.costUnit)) {
        //    return false;
        //}
        //return !!context.category?.costUnit?.update;
    },

    cellEditorParams: (params: ICellEditorParams<CM.FlattenedCostCategoryParams>) => {
        const context = getContext(params);
        return {
            values: context.category?.costUnit?.options ?? [],
            multiSelect: false,
            searchType: 'matchAny',
            filterList: true,
            highlightMatch: true,
            valueListMaxHeight: 220
        }
    },

    valueSetter: (params: ValueSetterParams<CM.FlattenedCostCategoryParams, string>) => {
        const context = getContext(params);
        if (typeof params.newValue !== 'string') {
            return false;
        }
        const newIndex = context.category?.costUnit?.options?.indexOf(params.newValue)
        if (newIndex === undefined) {
            return false;
        }
        context.category?.costUnit?.update?.(newIndex);
        //if (context.category?.costUnit) {
        //    context.category.costUnit.index = newIndex;
        //}
        return true;
    },

    cellClass: (params) => {
        const context = getContext(params);
        const classes = commonCellClasses(context, x => x.costUnit);
        if ((context.category?.costUnit?.options?.length ?? 0) > 1) {
            classes.push('ag-grid-dropdown-cell')
        }
        return classes;
    },

    minWidth: 110,

    cellStyle: {
        'text-align': 'left'
    },

    //headerClass: [
    //    'ag-grid-header-align-right'
    //],
})
