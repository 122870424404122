import type { SiteAreaSettings } from 'bim-ts';
import type { Vector2 } from 'math-ts';
import type { ContextMenuConfig } from 'ui-bindings';

export class SelectLayoutGenerationMode {
    constructor(
        public settings: SiteAreaSettings,
        public patchConfig: (updatedSettings: SiteAreaSettings) => void,
        public openRoadsSettings: (p: Vector2) => ContextMenuConfig,
        public openBlocksEquipmentSettings: (p: Vector2) => ContextMenuConfig,
    ) {

    }
}