<script lang="ts">
    import { getContext } from "svelte";
    import { Vector2 } from "math-ts";
    import ContextMenu from "../libui/context-menu/ContextMenu.svelte";
    import { ButtonComponent, IconButton } from "../libui/button";

    import { NavbarContext, ViewPosition } from "../navbar/Navbar";
    import { IconComponent, type IconName } from "../libui/icon";

    export let id: string;

    const navbar = <NavbarContext>getContext("ui-navbar");

    let open = false;
    let positionPx = new Vector2(0, 0);

    $: currentPath = $navbar.views[id];

    interface MenuItem {
        position: ViewPosition;
        name: string;
        icon: IconName;
    }

    const menu: MenuItem[] = [
        {
            position: ViewPosition.LeftTop,
            name: "Left Primary",
            icon: "LeftSide",
        },
        {
            position: ViewPosition.LeftSecond,
            name: "Left Secondary",
            icon: "LeftSecond",
        },
        {
            position: ViewPosition.LeftBottom,
            name: "Left Tertiary",
            icon: "LeftBottom",
        },
        {
            position: ViewPosition.RightTop,
            name: "Right Primary",
            icon: "RightSide",
        },
        {
            position: ViewPosition.RightBottom,
            name: "Right Tertiary",
            icon: "RightBottom",
        },
        {
            position: ViewPosition.Float,
            name: "Floating",
            icon: "Floating",
        },
    ];

    const menuHeight = 216;
    const menuWidth = 368;
    const menuLeftOffset = 336;

    function openMenu(e: Event) {
        const target = e.currentTarget as HTMLDivElement;
        const buttonPosition = target.getBoundingClientRect();
        const positionY =
            window.innerHeight - buttonPosition.bottom - 20 < menuHeight
                ? target.offsetTop - menuHeight
                : target.offsetTop + 20;
        const positionX =
            target.offsetLeft < menuLeftOffset
                ? 10
                : target.offsetLeft - menuLeftOffset;
        positionPx = new Vector2(positionX, positionY);
        open = !open;
    }
</script>

<div
    class="context-menu-container"
    style="--positionX:{positionPx.x}px;--positionY:{positionPx.y}px;"
>
    <div class="button-container" class:open>
        <ButtonComponent desc={new IconButton("Menu", openMenu)} />
    </div>
    {#if open}
        <ContextMenu
            settings={{
                isMenuOpened: true,
                offsetPx: positionPx,
                dispose: () => {
                    open = false;
                },
                closeOnInteraction: true,
                widthPx: menuWidth,
            }}
        >
            <div slot="body">
                <div class="content mdc-typography--body1 text-main-dark">
                    <div class="header mdc-typography--subheader2">
                        Dock panel
                    </div>
                    {#each menu as menuItem}
                        {@const isActive = currentPath === menuItem.position}
                        <div
                            class="menu-item text-main-medium"
                            class:active={isActive}
                            on:click={() => {
                                if (!isActive) {
                                    navbar.changeViewPosition(
                                        id,
                                        menuItem.position
                                    );
                                }
                            }}
                        >
                            <IconComponent name={menuItem.icon} />
                            <span class="text-main-dark">{menuItem.name}</span>
                            {#if isActive}
                                <IconComponent name={"Check"} />
                            {/if}
                        </div>
                    {/each}
                </div>
            </div>
        </ContextMenu>
    {/if}
</div>

<style lang="scss">
    .button-container.open {
        background-color: var(--color-main-light);
        color: var(--color-main-dark);
        pointer-events: none;
    }
    .context-menu-container :global(.mdc-menu) {
        transform: translateX(min(var(--positionX), 100vw - 100%))
            translateY(min(var(--positionY), 100vh - 100%)) translateZ(1px);
    }
    .content {
        padding: 4px 0 12px;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .header {
        padding: 0 12px;
    }
    .menu-item {
        padding: 4px 16px;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        &.active,
        &.active span {
            color: var(--mdc-theme-primary);
        }
        span {
            flex: 1;
        }
    }
</style>
