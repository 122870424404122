<script lang="ts">
    import { ButtonType } from "../button";
    import { Button } from "../button";
    import { ButtonComponent } from "../button";

    export let onSelect: () => void;
    export let onDeselect: () => void;
</script>

<div class="action-buttons">
    <ButtonComponent
        compact={true}
        desc={new Button(
            "Select all",
            ButtonType.Text,
            onSelect,
            false,
            "Check"
        )}
    />
    <ButtonComponent
        compact={true}
        desc={new Button(
            "Deselect all",
            ButtonType.Secondary,
            onDeselect,
            false,
            "Close"
        )}
    />
</div>

<style lang="scss">
    .action-buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 8px 0;
        gap: 4px;
        box-shadow:
            0px 1px 0px 0px rgba(21, 27, 31, 0.08) inset,
            0px -8px 8px 8px rgba(21, 27, 31, 0.02);
    }
</style>
