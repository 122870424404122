import type { LazyVersioned, ResultAsync, Result, PollWithVersionResult} from 'engine-utils-ts';
import { LruCache, ObjectUtils} from 'engine-utils-ts';
import { CachedCalculations, ComprArrOrderingType, CompressibleNumbersArray, Failure, IterUtils, WorkerClassPassRegistry, WorkerPool, convertThrow } from 'engine-utils-ts';
import { KrMath, Vector3, Matrix4, Euler, Quaternion } from 'math-ts';
import { TMY_Props } from '../meteo/TMY_Config';
import { EnergyFailure, energySucess, type EnergyResult } from './EnergyResult';
import type { UiBindings } from 'ui-bindings';
import { EnergyStageAction } from './EnergyStageWarning';
import type { Bim } from '../Bim';
import type { IdBimScene } from '../scene/SceneInstances';


export interface SolarYearlyPositionsData {
    warnings: EnergyFailure[];
    solar_time: CompressibleNumbersArray;
    declination: CompressibleNumbersArray;
    sun_height: CompressibleNumbersArray;
    longitude_subsolar_point: CompressibleNumbersArray;
    sun_azimuth: CompressibleNumbersArray;
    sun_zenith: CompressibleNumbersArray;
}

const solarYearPositionsDataCached = new CachedCalculations({
    identifier: 'solarYearPositionsData',
    maxCacheSize: 2,
    lazyInvalidation: [],
    calculator: generateSolarPositionsYearlyData,
});

export function generateSolarPositionsYearlyData(geo: {
    latitude: number,
    longitude: number,
    altitude: number,
    timeZone: number,
}): EnergyResult<SolarYearlyPositionsData> {

    if (!Number.isFinite(geo.latitude)) {
        return EnergyFailure.new('InvalidLatitiude');
    }
    if (!Number.isFinite(geo.longitude)) {
        return EnergyFailure.new('InvalidLongitude');
    }
    if (!Number.isFinite(geo.altitude)) {
        return EnergyFailure.new('InvalidAltitude');
    }
    if (!Number.isFinite(geo.timeZone)) {
        return EnergyFailure.new('InvalidTimezone');
    }

    const warnings: EnergyFailure[] = [];

    const hours = CompressibleNumbersArray.newFromValues('', Array.from({length: 24 * 365}, (_, i) => i + 0.5));

    const yAngle = CompressibleNumbersArray.map(
        hours,
        '',
        (hour) => {
        const day = Math.floor(hour / 24);
        return 2 * Math.PI * (day) / 365;
    });

    const TE = CompressibleNumbersArray.map(
        yAngle,
        '',
        (YAngle) => {
        return  0.0072*Math.cos(YAngle)
            - 0.0528*Math.cos(2*YAngle)
            - 0.0012*Math.cos(3*YAngle)
            - 0.1229*Math.sin(YAngle)
            - 0.1565*Math.sin(2*YAngle)
            - 0.0041*Math.sin(3*YAngle)
    });

    const DHLegHSol = TE.map('', (TE) => geo.timeZone - (geo.longitude / 15) - TE);

    const ST = CompressibleNumbersArray.map2(
        hours, DHLegHSol,
        '',
        (hour, DHLegHSol) => {
            const h = hour % 24;
            return h - DHLegHSol;
        }
    );
    const HourlyAngle = CompressibleNumbersArray.map(
        ST,
        'rad',
        (ST) => {
            return KrMath.degToRad(15 * (ST - 12));
        }
    );

    const Ecl = KrMath.degToRad(23.433)
    const declination = hours.map(
            'rad',
            (hour) => {
            const dayStarting0 = hour / 24;
            if (!(dayStarting0 >= 0 && dayStarting0 <= 365)) {
                throw new Error(`day ${dayStarting0} is not valid`);
            }
            return Math.asin(Math.sin(Ecl)*Math.sin(2*Math.PI*(dayStarting0 - 81) / 365))
        }
    );

    const sun_height = CompressibleNumbersArray.map2(
        declination, HourlyAngle,
        'rad',
        (declinationPerDay, hourlyAngle) => {
            const angle = Math.asin(
                Math.sin(KrMath.degToRad(geo.latitude)) * Math.sin(declinationPerDay)
                    + Math.cos(KrMath.degToRad(geo.latitude)) * Math.cos(declinationPerDay) * Math.cos(hourlyAngle)
            )
            return Math.max(angle, 0);
        }
    );

    const longitude_subsolar_point = CompressibleNumbersArray.map2(
        hours,
        TE,
        '',
        (hour, TE) => {
            hour = hour % 24;
            return -15 * (hour - geo.timeZone - 12 + TE);
        }
    );

    const sun_azimuth = CompressibleNumbersArray.map3(
        declination, longitude_subsolar_point, sun_height,
        'rad',
        (declinationPerDay, long_ss_point, sun_height) => {
            if (sun_height <= 0) {
                return 0;
            }
            const Sx = Math.cos(declinationPerDay) * Math.sin(KrMath.degToRad(long_ss_point - geo.longitude));
            const Sy = Math.cos(KrMath.degToRad(geo.latitude)) * Math.sin(declinationPerDay)
                - Math.sin(KrMath.degToRad(geo.latitude)) * Math.cos(declinationPerDay) * Math.cos(KrMath.degToRad(long_ss_point - geo.longitude));
            // const Sz = Math.sin(KrMath.degToRad(geo.latitude)) * Math.sin(declinationPerDay)
            //     + Math.cos(KrMath.degToRad(geo.latitude)) * Math.cos(declinationPerDay) * Math.cos(KrMath.degToRad(long_ss_point - geo.longitude));

            return Math.atan2(-Sx, -Sy);
        },
    )

    const sun_zenith = CompressibleNumbersArray.map(
        sun_height,
        'rad',
        (sun_height) => {
            return Math.PI / 2 - sun_height;
        }
    );

    warnings

    return energySucess({
        warnings,
        solar_time: ST,
        declination,
        sun_height,
        longitude_subsolar_point,
        sun_azimuth,
        sun_zenith,
    });
}


class PerModuleIrradianceCalculatorArgs {

    constructor(
        readonly latitude: number,
        readonly longitude: number,
        readonly altitude: number,

        readonly string_slope: number,
        readonly string_rotation_azimuth: number,

        readonly timeZone: number,
        readonly module_tilt_range_deg: number,
        readonly module_tilt_deg: number,
        readonly siteMeteoData: TMY_Props,
    ) {
    }

    static hashString(args: PerModuleIrradianceCalculatorArgs): string {
        let res = ``;
        res += `${args.latitude})`;
        res += `${args.longitude})`;
        res += `${args.altitude})`;
        res += `${args.timeZone})`;
        res += `(${args.module_tilt_range_deg})`;
        res += `(${args.module_tilt_deg})`;
        res += `(${args.string_slope})`;
        res += `(${args.string_rotation_azimuth})`;
        res += `(${WorkerPool.getUniqueIdForObject(args.siteMeteoData)})`;
        return res;
    }
}

export interface PlaneOfArrayIrradiance{ 
    altitude: number;
    declination: CompressibleNumbersArray;
    diffuse_horizontal_irradiance: CompressibleNumbersArray;
    direct_normal_irradiance: CompressibleNumbersArray;
    fresnel_multiplier: CompressibleNumbersArray;
    horizontal_irradiance: CompressibleNumbersArray;
    incidence_angle: CompressibleNumbersArray;
    longitude_subsolar_point: CompressibleNumbersArray;
    module_tilt_angle: CompressibleNumbersArray;
    poa_albedo: CompressibleNumbersArray;
    poa_beam: CompressibleNumbersArray;
    poa_diffuse: CompressibleNumbersArray;
    solar_time: CompressibleNumbersArray;
    sun_azimuth: CompressibleNumbersArray;
    sun_height: CompressibleNumbersArray;
}


export interface PlaneOfArrayIrradianceWithWarnings extends PlaneOfArrayIrradiance {
    sunCalculationsWarnings: EnergyFailure[];
    positionCalcWarnings: EnergyFailure[];
}



export class POA_IrradiancePerModulePlane implements LazyVersioned<POA_IrradiancePerModulePlane> {

    readonly _tmyProps: LazyVersioned<ResultAsync<TMY_Props>>;

    readonly _cache: LruCache<
        PerModuleIrradianceCalculatorArgs,
        EnergyResult<PlaneOfArrayIrradiance>
    >;

    readonly _cacheFull: LruCache<
        PerModuleIrradianceCalculatorArgs,
        EnergyResult<PlaneOfArrayIrradianceWithWarnings>
    >;

    constructor(
        bim: Bim,
    ) {
        
        this._tmyProps = bim.runtimeGlobals.getAsLazyVersionedByIdent(TMY_Props.name);

        this._cache = new LruCache<
            PerModuleIrradianceCalculatorArgs,
            EnergyResult<PlaneOfArrayIrradiance>
        >({
                identifier: 'cachedPerModuleIrradianceProducers',
                maxSize: 100,
                hashFn: PerModuleIrradianceCalculatorArgs.hashString,
                eqFunction: ObjectUtils.shallowEqual,
                factoryFn: calculatePerModulerPlaneIrradiance
            }
        );
        this._cacheFull = new LruCache<
            PerModuleIrradianceCalculatorArgs,
            EnergyResult<PlaneOfArrayIrradianceWithWarnings>
        >({
                identifier: 'cachedPerModuleIrradianceProducers',
                maxSize: 20,
                hashFn: PerModuleIrradianceCalculatorArgs.hashString,
                eqFunction: ObjectUtils.shallowEqual,
                factoryFn: (args) => calculatePerModulerPlaneIrradianceWithWarnings(args, this._cache),
            }
        );
    }

    getPerPlaneIrradianceFor(args: {
        module_tilt_range_deg: number,
        module_tilt_deg: number,
        tracker_slope: number,
        tracker_azim: number
    }): EnergyResult<PlaneOfArrayIrradianceWithWarnings> {
        const tmyResult = this._tmyProps.poll() as Result<TMY_Props>;
        if (tmyResult instanceof Failure) {
            return new EnergyFailure({errorMsgIdent: 'MeteoDataNotLoaded', inner: [tmyResult]});
        }
        const tmy = tmyResult.value;
        const wgs = tmy.locationContext.tryGetWgsCoord();
        if (wgs instanceof Failure) {
            return new EnergyFailure({errorMsgIdent: 'InvalidGeolocation', inner: [wgs]});
        }

        return this._cacheFull.get({
            latitude: wgs.value.lat,
            longitude: wgs.value.long,
            altitude: wgs.value.alt,
            module_tilt_range_deg: args.module_tilt_range_deg,
            module_tilt_deg: args.module_tilt_deg,
            siteMeteoData: tmy,
            timeZone: tmy.locationContext.timeZone.value,
            string_rotation_azimuth: args.tracker_azim,
            string_slope: args.tracker_slope,
        });
    }

    poll(): Readonly<POA_IrradiancePerModulePlane> {
        return this;
    }
    pollWithVersion(): PollWithVersionResult<
        Readonly<POA_IrradiancePerModulePlane>
    > {
        return { value: this.poll(), version: this.version() };
    }
    version() {
        return this._tmyProps.version();
    }

    static registerAsGlobal(bim: Bim) {
        const producer = new POA_IrradiancePerModulePlane(bim);
        bim.runtimeGlobals.registerByIdent<POA_IrradiancePerModulePlane>(POA_IrradiancePerModulePlane.name, producer);
    }

}

function calculatePerModulerPlaneIrradianceWithWarnings(
    args: PerModuleIrradianceCalculatorArgs,
    cache: LruCache<
        PerModuleIrradianceCalculatorArgs,
        EnergyResult<PlaneOfArrayIrradiance>
    >
): EnergyResult<PlaneOfArrayIrradianceWithWarnings> {
    
    const res = cache.get(args);
    if (res instanceof Failure) {
        return res;
    }
    const poa = res.value;
    
    const sunCalculationsWarnings: EnergyFailure[] = [];
    const positionCalcWarnings: EnergyFailure[] = [];

    // check that solar time corresponds to ghi
    {
        const ghi_peak_hour_fract = calculateDailyPeakHourFract(poa.horizontal_irradiance);
        const ghi_peak_hour = Math.round(ghi_peak_hour_fract);

        const sun_height_peak_hour_fract = calculateDailyPeakHourFract(poa.sun_height);
        const sun_height_hour = Math.round(sun_height_peak_hour_fract);

        if (Math.abs(ghi_peak_hour_fract - sun_height_peak_hour_fract) > 0.5) {
            const diff = ghi_peak_hour - sun_height_hour;
            const targetTimezone = (Math.round(args.timeZone + 12 + diff) % 24) - 12;

            sunCalculationsWarnings.push(EnergyFailure.new('TimezoneAndGeoCoordsDoNotConverge', new TimezoneFixAction('Fix timezone', targetTimezone)));
        }
    }

    // positions checks
    if (args.module_tilt_range_deg > 0) {
        // TRACKER CASE
        if ((Math.abs(args.string_rotation_azimuth) % Math.PI) > KrMath.degToRad(5)) {
            positionCalcWarnings.push(EnergyFailure.new('InadequateTrackerPosition', new TrackerAction_MakeNSOriented()));
        }
    } else {
        // FIX TILT CASE

        // binary search to find optimal tilt
        function evaluateTiltEnergy(tilt_deg: number): number {
            const poa = cache.get({...args, module_tilt_deg: tilt_deg});
            if (poa instanceof Failure) {
                return 0;
            }
            return poa.value.poa_beam.sum() + poa.value.poa_diffuse.sum() + poa.value.poa_albedo.sum();
        }


        let leftDeg = -80;
        let rightDeg = 80;
        
        let leftEnergy = evaluateTiltEnergy(leftDeg);
        let rightEnergy = evaluateTiltEnergy(rightDeg);

        const searchRelativeAdjustmentDeg = 0.25; // could be 0.5, assuming smooth even energy chart for different degrees, but take lower value to be safe
        let iterationsCount = 0;
        do {
            iterationsCount += 1;
            if (rightEnergy > leftEnergy) {
                leftDeg = Math.ceil(KrMath.lerp(leftDeg, rightDeg, searchRelativeAdjustmentDeg));
                leftEnergy = evaluateTiltEnergy(leftDeg);
            } else {
                rightDeg = Math.floor(KrMath.lerp(rightDeg, leftDeg, searchRelativeAdjustmentDeg));
                rightEnergy = evaluateTiltEnergy(rightDeg);
            }
        } while (leftDeg !== rightDeg);

        const bestAngle = leftEnergy > rightEnergy ? leftDeg : rightDeg;
        if (Math.abs(bestAngle - args.module_tilt_deg) > 0.9) {
            positionCalcWarnings.push(EnergyFailure.new('InadequateFixTiltTilt', new FixTiltAction_FixTilt(bestAngle)));
        }
    }

    return energySucess({
        ...poa,
        sunCalculationsWarnings,
        positionCalcWarnings
    });
}


function calculatePerModulerPlaneIrradiance(
    args: PerModuleIrradianceCalculatorArgs,
): EnergyResult<PlaneOfArrayIrradiance> {


    const sunDataResult = solarYearPositionsDataCached.acquire({
        latitude: args.latitude,
        longitude: args.longitude,
        altitude: args.altitude!,
        timeZone: args.timeZone,
    });
    
    if (sunDataResult instanceof Failure) {
        return sunDataResult;
    }

    const { declination, sun_zenith, sun_height, sun_azimuth, solar_time, longitude_subsolar_point } = sunDataResult.value;


    let Module_Tilt_Angle = args.string_slope;
    let Module_Azimuth_Angle = args.string_rotation_azimuth;
    let module_tilt_rad = KrMath.degToRad(args.module_tilt_deg);

    if (Module_Tilt_Angle < 0) {
        Module_Tilt_Angle *= -1;
        Module_Azimuth_Angle += Math.PI;
        module_tilt_rad *= -1; // FIX TILT SYSTEMS ARE NOT SYMMETRICAL
    }

    // Module_Azimuth_Angle = (Module_Azimuth_Angle + 2 * Math.PI) % (2 * Math.PI);
    if (Module_Azimuth_Angle > Math.PI) {
        Module_Azimuth_Angle -= 2 * Math.PI;
    }


    let Rotation_angle: CompressibleNumbersArray;
    
    if (args.module_tilt_range_deg > 0) {
        // TRACKER CASE

        const A = CompressibleNumbersArray.map2(
            sun_zenith, sun_azimuth,
            'rad',
            (sun_zenith, sun_azimuth) => {
                return Math.sin(sun_zenith) * Math.sin(sun_azimuth - Module_Azimuth_Angle) / (
                    Math.sin(sun_zenith) * Math.cos(sun_azimuth - Module_Azimuth_Angle) * Math.sin(Module_Tilt_Angle)
                        + Math.cos(sun_zenith) * Math.cos(Module_Tilt_Angle)
                )
            }
        );

        const B = CompressibleNumbersArray.map2(
            A, sun_azimuth,
            'rad',
            (A, sun_azimuth) => {
    
                if (Module_Azimuth_Angle >= 0) {
                    if (A < 0 && Module_Azimuth_Angle - sun_azimuth > Math.PI) {
                        return Math.PI;
                    }
                    if (A > 0 && Module_Azimuth_Angle + sun_azimuth > Math.PI) {
                        return -Math.PI;
                    }
                } else {
                    if (A < 0 && Module_Azimuth_Angle + sun_azimuth < -Math.PI) {
                        return Math.PI;
                    }
                    if (A > 0 && Module_Azimuth_Angle - sun_azimuth < -Math.PI) {
                        return -Math.PI;
                    }
                }
                return 0;
            }
        );

        Rotation_angle = CompressibleNumbersArray.map2(
            A, B,
            'rad',
            (A, B) => {
                let res = Math.atan(A) + B;
                if (res >= args.module_tilt_range_deg) {
                    return module_tilt_rad;
                }
                if (res <= -args.module_tilt_range_deg) {
                    return -module_tilt_rad;
                }
                return res;
            }
        );
    } else {
        // FIX TILT CASE
        Rotation_angle = CompressibleNumbersArray.newSingleValue('rad', module_tilt_rad, 365*24, ComprArrOrderingType.Tmy_365_24);
    }

    const Tilt_Angle = CompressibleNumbersArray.map(
        Rotation_angle,
        'rad',
        (Rotation_angle) => {
            let res = Math.acos(Math.cos(Rotation_angle) * Math.cos(Module_Tilt_Angle));
            return res;
        }
    )

    const C = CompressibleNumbersArray.map2(
        Rotation_angle, Tilt_Angle,
        'rad',
        (Rotation_angle, Tilt_Angle) => {
            let res = Math.sin(Rotation_angle) / Math.sin(Tilt_Angle);
            if (res > 1) {
                return 1;
            }
            if (res < -1) {
                return -1;
            }
            return res;
        }
    );

    const Azimuth_Angle = CompressibleNumbersArray.map(
        C,
        'rad',
        (C) => {
            let res = Math.asin(C) + Module_Azimuth_Angle;
            if (res > Math.PI) {
                res -= 2 * Math.PI;
            } else if (res < -Math.PI) {
                res += 2 * Math.PI;
            }
            return res;
        }
    );

    const incidence_angle = CompressibleNumbersArray.map5(
        sun_zenith, sun_azimuth, sun_height, Tilt_Angle, Azimuth_Angle,
        'rad',
        (sun_zenith, sun_azimuth, sun_height, Tilt_Angle, Azimuth_Angle) => {
            if (sun_height <= 0) {
                return Math.PI * 0.5;
            }
            let res = Math.cos(sun_zenith) * Math.cos(Tilt_Angle) + Math.sin(sun_zenith) * Math.sin(Tilt_Angle) * Math.cos(sun_azimuth - Azimuth_Angle);
            return Math.acos(res);
        }
    );

    const DNI = args.siteMeteoData.getDataColumnAsCompressibleArray('Direct_Normal_Irradiance');
    const GHI = args.siteMeteoData.getDataColumnAsCompressibleArray('Global_Horizontal_Irradiance');
    const DHI = args.siteMeteoData.getDataColumnAsCompressibleArray('Diffuse_Horizontal_Irradiance');

    if (!DNI) {
        return EnergyFailure.new('DNI_Absent');
    }
    if (!GHI) {
        return EnergyFailure.new('GHI_Absent');
    }
    if (!DHI) {
        return EnergyFailure.new('DHI_Absent');
    }
    
    const CUT_OFF_ANGLE_HIGH = KrMath.degToRad(88);

    const POA_Beam = CompressibleNumbersArray.map2(
        incidence_angle, DNI,
        'W/m2',
        (inc, dni) => {
            if (inc > CUT_OFF_ANGLE_HIGH) {
                return 0;
            }
            return Math.cos(inc) * dni;
        }
    );

    const FresnelInterpolator = ValuesInterpolator.fromKeyValueTuples([[0, 1], [30, 0.999], [50, 0.987], [60, 0.962], [70, 0.892], [75, 0.816], [80, 0.681], [85, 0.440], [90, 0.000]]);

    const POA_Beam_Fresnel_Multiplier = CompressibleNumbersArray.map(
        incidence_angle,
        '',
        (incidence_angle) => FresnelInterpolator.getValue(KrMath.radToDeg(incidence_angle)),
    );

    const Gsc = 1367;
    const Gamma = IterUtils.newArrayWithIndices(0, 365 * 24).map(hour => (hour / 24) / 365 * 2 * Math.PI);
    const R2 = Gamma.map(gamma => {
        return 1 / (1.000110 + 0.034221 * Math.cos(gamma) + 0.001280 * Math.sin(gamma) + 0.000719 * Math.cos(2 * gamma) + 0.000077 * Math.sin(2 * gamma))
    });
    const Extra_Terrestrial_Irradiation = CompressibleNumbersArray.newFromValues(
        'W/m2',
        R2.map(v => Gsc / v)
    );

    const POA_Diffuse = CompressibleNumbersArray.map6(
        DHI,
        DNI,
        Extra_Terrestrial_Irradiation,
        incidence_angle,
        sun_zenith,
        Tilt_Angle,
        'W/m2',
        (DHI, DNI, ETI, incidence_angle, sun_zenith, ModuleTilt) => {

            if (sun_zenith > CUT_OFF_ANGLE_HIGH) {
                return DHI * (1 + Math.cos(ModuleTilt)) / 2;
            }

            const a = Math.max(0, Math.cos(incidence_angle));
            const poa_diffuse = DHI * (( DNI / ETI * a / Math.cos(sun_zenith)) + (1 + Math.cos(ModuleTilt)) / 2 * (1 - DNI / ETI));
            
            return poa_diffuse;
        }
    );


    const rho = 0.2;
    const POA_Albedo = CompressibleNumbersArray.map2(
        GHI,    
        Tilt_Angle,
        'W/m2',
        (ghi, moduleTilt) => {
            return rho * ghi * (1 - Math.cos(moduleTilt)) / 2;
        }
    )

    return energySucess({
        altitude: args.altitude,
        sun_azimuth: sun_azimuth,
        sun_height: sun_height,
        longitude_subsolar_point: longitude_subsolar_point,
        horizontal_irradiance: GHI.withUnit('W/m2'),
        diffuse_horizontal_irradiance: DHI.withUnit('W/m2'),
        direct_normal_irradiance: DNI.withUnit('W/m2'),
        poa_albedo: POA_Albedo,
        poa_beam: POA_Beam,
        incidence_angle,
        module_tilt_angle: Tilt_Angle,
        fresnel_multiplier: POA_Beam_Fresnel_Multiplier,
        poa_diffuse: POA_Diffuse,
        declination,
        solar_time: solar_time,
    });
}

function calculateDailyPeakHourFract(arr: CompressibleNumbersArray) {

    const per_hour_sums = IterUtils.newArray(24, () => 0);
    let ideal_days_count = 0;

    per_day_loop:
    for (let i = 0; i < arr.length; i += 24) {
        
        let prevSign = 0;
        let signChangeCount = 0;
        let prevHourValue = arr.at(i)!;
        for (let j = 1; j < 24; ++j) {
            const hourValue = arr.at(i + j)!;
            const sign = Math.sign(hourValue - prevHourValue);
            if (prevSign !== 0 && sign !== 0 && sign !== prevSign) {
                signChangeCount += 1;
            }
            prevSign = sign;
            prevHourValue = hourValue;
            if (signChangeCount > 1) {
                // not ideal day, skip it
                continue per_day_loop;
            }
        }
        // we have ideal day
        for (let j = 0; j < 24; ++j) {
            per_hour_sums[j] += arr.at(i + j)!;
        }
        ideal_days_count += 1;
    }
    if (ideal_days_count < 365 * 0.5) {
        // just use sum for all year
        per_hour_sums.fill(0);
        arr.foreach(arr.unit, (v, i) => per_hour_sums[i] += v);
    }
    
    const peak_sum = Math.max(...per_hour_sums);
    const peak_hour = per_hour_sums.indexOf(peak_sum);
    const peak_hour_fract = (per_hour_sums.at(peak_hour-1)! / peak_sum) * -1
        + (per_hour_sums.at(peak_hour + 1)! / peak_sum)
        + peak_hour;

    return peak_hour_fract;
}


class FixTiltAction_FixTilt extends EnergyStageAction {
    constructor(
        readonly optimal_tilt_deg: number,
    ) {
        super(`Change fixed tilt to optimal`);
    }
    
    execute(bim: Bim, uiBindings: UiBindings, affectedInstances: IdBimScene[]): void {
        const sampleInstance = bim.instances.peekById(affectedInstances[0]);
        if (!sampleInstance) {
            console.error("fix tilt action: instance not found", affectedInstances[0]);
            return;
        }
        const prop = sampleInstance.properties.get('dimensions | tilt')!;

        const newProp = prop.withDifferentValue(convertThrow(this.optimal_tilt_deg, 'deg', prop.unit));
        
        bim.instances.applyPatchTo({ properties: [['dimensions | tilt', newProp]] }, affectedInstances);
    }
}
WorkerClassPassRegistry.registerClass(FixTiltAction_FixTilt);


class TrackerAction_MakeNSOriented extends EnergyStageAction {
    constructor(
    ) {
        super('Re-orient to NS')
    }
    execute(bim: Bim, uiBindings: UiBindings, affectedInstances: IdBimScene[]): void {
        const fixedWmPerId = new Map<IdBimScene, Matrix4>();
        for (const [id, instance] of bim.instances.peekByIds(affectedInstances)) {
            const wm = instance.worldMatrix;

            const pos = new Vector3();
            const rot = new Quaternion();
            const scale = new Vector3();
            wm.decompose(pos, rot, scale);
            const euler = new Euler().setFromQuaternion(rot);
            euler.y = 0;
            euler.z = 0;
            rot.setFromEuler(euler);
            scale.set(1, 1, 1);

            const newWm = new Matrix4().compose(pos, rot, scale);

            fixedWmPerId.set(id, newWm);
        }
        bim.instances.patchWorldMatrices(fixedWmPerId);
    }
}
WorkerClassPassRegistry.registerClass(TrackerAction_MakeNSOriented);


class TimezoneFixAction extends EnergyStageAction {
    constructor(
        name: string,
        readonly targetTimezone: number,
    ) {
        super(name);
    }

    execute(bim: Bim, uiBindings: UiBindings): void {
        bim.configs.patchSingletonProps(
            'typical-meteo-year',
            TMY_Props,
            (props) => {
                props.locationContext.timeZone = props.locationContext.timeZone.withDifferentValue(this.targetTimezone);
            }
        );
    }
}
WorkerClassPassRegistry.registerClass(TimezoneFixAction);


class ValuesInterpolator {

    constructor(
        readonly keyStart: number,
        readonly keyStep: number,
        readonly values: number[],
    ) {
    }

    getValue(key: number): number {
        const keyFr = (key - this.keyStart) / this.keyStep;
        const key0 = Math.floor(keyFr);
        const key1 = key0 + 1;

        if (key0 < 0) {
            return this.values[0];
        }
        if (key1 >= this.values.length) {
            return this.values.at(-1)!;
        }

        const v0 = this.values[key0];
        const v1 = this.values[key1];

        const v = v0 + (v1 - v0) * (keyFr - key0);

        return v;
    }

    static fromKeyValueTuples(
        keyValueTuples: [number, number][],
    ) {
        if (keyValueTuples.length < 2) {
            throw new Error(`need at least 2 key-value tuples`);
        }
        keyValueTuples.sort((a, b) => a[0] - b[0]);
        let keySteps: number[] = [];
        for (let i = 1; i < keyValueTuples.length; ++i) {
            const gap = keyValueTuples[i][0] - keyValueTuples[i - 1][0];
            if (!keySteps.includes(gap)) {
                keySteps.push(gap);
            }
        }
        keySteps.sort((a, b) => a - b);

        const keyStep = keySteps[0];
        for (let i = 1; i < keySteps.length; ++i) {
            if (keySteps[i] % keyStep !== 0) {
                throw new Error(`key steps are not multiple of each other: ${keySteps}`);
            }
        }

        const keyStart = keyValueTuples[0][0];
        const keyEnd = keyValueTuples.at(-1)![0];
        const stepsCount = Math.round((keyEnd - keyStart) / keyStep);

        const values: number[] = [keyValueTuples[0][1]];
        outer: for (let i = 1; i <= stepsCount; ++i) {
            const keyStepped = KrMath.roundTo(keyStart + i * keyStep, keyStep);


            for (let j = 1; j < keyValueTuples.length; ++j) {
                const t = keyValueTuples[j];
                if (t[0] === keyStepped) {
                    values.push(t[1]);
                    continue outer;
                }
                
                if (t[0] >= keyStepped) {
                    const nextKey = t[0];
                    const nextValue = t[1];

                    const prevT = keyValueTuples[j - 1];
                    const prevKey = prevT[0];
                    const prevValue = prevT[1];

                    const value = KrMath.lerp(prevValue, nextValue, (keyStepped - prevKey) / (nextKey - prevKey));
                    values.push(value);
                    continue outer;
                }
            }
            throw new Error('should not reach here');
        }
        return new ValuesInterpolator(keyStart, keyStep, values);
    }
}
