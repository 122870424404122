import type { CHD_PolarHeatmapChart } from 'ui-bindings';
import type { EChartOptions } from '.';


export function echartOptionsFromCHD_PolarHeatmapChart(chart: CHD_PolarHeatmapChart): EChartOptions {
    const options: EChartOptions = {
        textStyle: {
            fontSize: 10,
        },
        polar: {
            radius: "60%",
            center: ["40%", "50%"],
        },
        tooltip: {
            renderMode: 'html',
            confine: false,
            appendToBody: true,
            padding: 2,
            textStyle: {
                fontSize: 10,
            }
            // formatter: (params: any) => {
            //     console.log(params);
            //     return params.data.toString();
            // },
        },
        legend: {
            show: chart.legend?.show ?? false,
            selectedMode: false,
            icon: 'none',
        },
        visualMap: {
            type: chart.visualMap.type,
            show: chart.visualMap.show,
            min: chart.visualMap.min,
            max: chart.visualMap.max,
            align: chart.visualMap.align,
            dimension: 2,
            inRange: {
                color: chart.visualMap.inRange?.color,
            },
            outOfRange: {
                color: chart.visualMap.outOfRange?.color,
            },
            top: "middle",
            realtime: true,
            text: chart.visualMap.text,
            inverse: true,
            precision: 2,
            left: 'auto',
            right: 2,
            itemHeight: 80,
        },
        angleAxis: {
            type: chart.angleAxis.type,
            data: chart.angleAxis.data,
            boundaryGap: false,
            
        },
        radiusAxis: {
            type: chart.radiusAxis.type,
            data: chart.radiusAxis.data,
            z: 100,
            name: chart.radiusAxis.name,
            nameLocation: 'middle',
            nameRotate: 90,
            nameGap: 0,
        },
        series: chart.series.map(s => {
            return {
                name: s.name,
                type: 'custom',
                coordinateSystem: 'polar',
                animation: false,
                data: s.data,
                dimensions: s.dimensions,
                renderItem: function (params, api) {
                    const values = [api.value(0), api.value(1)];
                    const coord = api.coord(values);
                    const size = api.size!([1, 1], values) as number[];
                    return {
                        type: 'sector',
                        shape: {
                            cx: (params.coordSys as any).cx,
                            cy: (params.coordSys as any).cy,
                            r0: coord[2] - size[0] / 2,
                            r: coord[2] + size[0] / 2,
                            startAngle: -(coord[3] + size[1] / 2),
                            endAngle: -(coord[3] - size[1] / 2)
                        },
                        // style: api.style({
                        //     fill: api.visual('color', params.dataIndexInside)
                        // })
                        style: {
                            fill: api.visual('color', params.dataIndexInside),
                        },
                        styleEmphasis: {
                            stroke: "orange",
                        }
                    };
                },
            }
        })
    };
    return cleanedUpFromUndefined(options)!;
}

function cleanedUpFromUndefined<T extends Object>(obj: T): T | undefined {
    let result: Partial<T> = {};
    for (const key in obj) {
        let value: any = obj[key];

        if (value && typeof value === 'object' && !Array.isArray(value)) {
            value = cleanedUpFromUndefined(value);
        }
        if (value !== undefined) {
            (result as any)[key] = value;
        }
    }
    // if (ObjectUtils.isObjectEmpty(result)) {
    //     return undefined;
    // }
    return result as T;
}
