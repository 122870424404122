import type { Bim, IdBimScene, PropertiesGroupFormatters, SceneInstance} from "src";
import { NumberProperty, SceneObjDiff } from "src";
import { InverterKeyProps, InverterTypeIdent } from "src/archetypes/Inverter/Inverter";
import type { LazyVersioned} from "engine-utils-ts";
import { DefaultMap, IterUtils, LazyDerivedAsync, LegacyLogger, ObjectUtils, Yield, preferPreviousOverInProgressWrapper, replaceCurrencyUnitWithSymbol } from "engine-utils-ts";
import { CostHierarchy, createMiscCategory, fillModelBasedCostCategory, fillModelBasedTopLevelCategory } from "../..";
import type { CostComponents, CostComponentsNonNullable, CostsConfigProvider, EstimateCost, InstanceCost, SingleEstimateProvider} from "src/cost-model/capital";
import { createEmptyCostComponents, createEmptyCostComponentsNonNullable } from "src/cost-model/capital";

export function create_PowerConversionSystem_Inverters(
    bim: Bim,
    provider: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>
) {
    const instances = bim.instances.getLazyListOf({ type_identifier: InverterTypeIdent, relevantUpdateFlags: SceneObjDiff.NewProps | SceneObjDiff.LegacyProps })
    const grouped = createGroupedInstances(instances, bim.keyPropertiesGroupFormatter);


    const result = LazyDerivedAsync.new4<
        CostHierarchy,
        GroupedInvertes,
        InstanceCost[],
        EstimateCost[],
        NumberProperty
    >(
        'inverter',
        [bim.unitsMapper],
        [grouped, provider.allInstanceCosts, provider.allEstimateCosts, totalDC],
        function* ([groupedCables, costs, estimates, totalDC]) {

            const costUnit = bim.unitsMapper.mapToConfigured({ value: 0, unit: 'usd' }).unit!;

            // extract final result
            const hierarchy = new CostHierarchy()

            for (const { manufacturer, uniqueGroups } of groupedCables.manufacturerGroups) {
                const sub = new CostHierarchy();

                for (const { name, props, ids } of uniqueGroups) {
                    let overrides = costs.find(x => ObjectUtils.areObjectsEqual(x.props, props))?.costs
                        ?? createEmptyCostComponents();
                    const defaults = getInverterDefaultCostsPerEach(props);

                    const nameCategory = sub.add({
                        description: { value: name },
                        costUnit: {
                            options: [replaceCurrencyUnitWithSymbol(costUnit) + '/each' ],
                            index: 0,
                        },
                        quantity: {
                            value: NumberProperty.new({ value: ids.size }),
                            integer: true,
                        },
                        relatedSceneInstanceIds: ids,
                        matchesSceneInstance: (query) => {
                            if (query.si?.type_identifier !== InverterTypeIdent) {
                                return false;
                            }
                            const sampleProps = query.si.properties.extractPropertiesGroup(InverterKeyProps, { valueUnitOnly: true })
                            const result = ObjectUtils.areObjectsEqual(props, sampleProps);
                            return result;
                        }
                    })

                    const updateCosts = (newCosts: CostComponents) => {
                        provider.findAndUpdateInstanceCost(
                            (prev) => (prev.costs = newCosts, prev),
                            { instance_type: InverterTypeIdent, props }
                        )
                    }

                    fillModelBasedCostCategory(nameCategory[1], overrides, defaults, updateCosts, ids.size);
                }

                // create sub hierarchy root
                {
                    const root = sub.addRoot({ description: { value: manufacturer } })
                    sub.sumupChildren(root[0])
                }

                hierarchy.merge(sub);
            }

            // create misc cable misc
            {
                const costId = 'inverters-misc';
                const overrides = estimates.find(x => x.id.value === costId);
                const state: SingleEstimateProvider = {
                    id: costId,
                    defaults: createEmptyCostComponentsNonNullable(),
                    value: overrides,
                    update: (fn) => provider.findAndUpdateEstimateCost(costId, fn),
                }
                const misc = createMiscCategory(state, bim.unitsMapper, totalDC)
                hierarchy.add(misc);
            }

            // create model root category
            {
                const root = hierarchy.addRoot({ description: { value: 'Inverters' } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.materialCost = NumberProperty.new({ value: 0.023, unit: 'usd' });
                fillModelBasedTopLevelCategory(
                    root[0],
                    hierarchy,
                    'pcs-inverters-benchmark',
                    estimates,
                    provider.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC
                );
            }


            return hierarchy;
        }
    )
    return result;
}


function createGroupedInstances(
    instances: LazyVersioned<Array<[id: IdBimScene, si: SceneInstance]>>,
    keyPropsGroupFormatter: PropertiesGroupFormatters,
) {
    const result = preferPreviousOverInProgressWrapper(LazyDerivedAsync.new1(
        'inverter-grouping',
        [],
        [instances],
        function* ([instances]) {
            type Manufacturer = string
            type Name = string
            const groups = new DefaultMap<
                Manufacturer,
                DefaultMap<Name, UniqueInverterGroup>
            >(
                () => new DefaultMap(
                    (name) => ({
                        name: name,
                        props: InverterKeyProps,
                        ids: new Set(),
                    })
                )
            )

            for (const chunk of IterUtils.splitIterIntoChunks(instances, 10e3)) {
                yield Yield.Asap;
                const grouped = IterUtils.groupObjects(
                    chunk,
                    (o) => {
                        const props = o[1].properties.extractPropertiesGroup(InverterKeyProps);
                        return props.model.asText() + props.manufacturer.asText() + props.modules_count.asNumber();
                    },
                    (l, r) => ObjectUtils.areObjectsEqual(
                        l[1].properties.extractPropertiesGroup(InverterKeyProps, { valueUnitOnly: true }),
                        r[1].properties.extractPropertiesGroup(InverterKeyProps, { valueUnitOnly: true })
                    ),
                )
                for (const members of grouped) {
                    const sample = members[0][1]
                    const props = sample.properties.extractPropertiesGroup(InverterKeyProps, { valueUnitOnly: true });
                    const name = keyPropsGroupFormatter.format(InverterTypeIdent, sample.properties, sample.props)
                    if (!name) {
                        LegacyLogger.warn('Invalid inverter group ' + name + '. Skipping');
                        continue;
                    }
                    const manufacturerGroup = groups.getOrCreate(props.manufacturer.asText());
                    const group = manufacturerGroup.getOrCreate(name);
                    group.props = props;
                    members.forEach(x => group.ids.add(x[0]));
                }
            }

            const result: GroupedInvertes = {
                manufacturerGroups: []
            }
            for (const [manufacturer, manufGroup] of groups) {
                const group: InverterManufacturerGroup = {
                    manufacturer,
                    uniqueGroups: Array.from(manufGroup.values())
                }
                result.manufacturerGroups.push(group)
            }
            return result;
        }
    ));
    return result;
}


interface InverterManufacturerGroup {
    manufacturer: string
    uniqueGroups: UniqueInverterGroup[]
}

interface UniqueInverterGroup {
    name: string
    props: typeof InverterKeyProps
    ids: Set<IdBimScene>
}

interface GroupedInvertes {
    manufacturerGroups: InverterManufacturerGroup[],
}

export function getInverterDefaultCostsPerEach(props: typeof InverterKeyProps): CostComponentsNonNullable {
    const result: CostComponentsNonNullable = {
        ...createEmptyCostComponentsNonNullable(),
        materialCost: NumberProperty.new({ value: props.max_power.as('W') * 0.04, unit: 'usd' }),
    };

    return result;
}

