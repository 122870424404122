import type { IrregularHeightmapGeometry } from "../../geometries/IrregularHeightmapGeometries";
import type { Aabb2, Matrix4} from "math-ts";
import { Vec3XNeg, Vec3YNeg, Vector2, Vector3 } from "math-ts";
import { LegacyLogger } from "engine-utils-ts";
import { TerrainElevation } from "../TerrainElevation";
import type { TerrainMetricsRanges } from "./TerrainMetrics";
import { TerrainDisplaySlopeSelector } from "./TerrainMetrics";
import { TerrainMetrics, PerTileCutFillHeatmap, paletteSlicesIndicesFromValue, calcTriangleAreaByPoints } from "./TerrainMetrics";
import { triangleArea } from "../TriangleUtils";



export class TerrainMetricsIrregular {

    static calculateAreaMetricsForSlope(
        palette: TerrainMetricsRanges, 
        geometry: IrregularHeightmapGeometry, 
        wm: Matrix4, 
        slopeSelector: TerrainDisplaySlopeSelector
    ): TerrainMetrics {
        const angleToAxis = new Vector3();
        if (slopeSelector === TerrainDisplaySlopeSelector.EW) {
            angleToAxis.copy(Vec3XNeg);
        } else {
            angleToAxis.copy(Vec3YNeg);
        }
        const perTrMetrics = TerrainMetricsIrregular.calcSurfaceAngleToAxisPerTriangle(geometry, wm, angleToAxis);

        let totalArea = 0, currRange = {min: Infinity, max: -Infinity};
        const perSlice = palette.slices.map(_ => 0);
        for (const perTr of perTrMetrics) {
            totalArea += perTr.area;
            if (perTr.angle >= palette.slices[0])  {
                for (let sliceI = 0; sliceI < perSlice.length - 1; ++sliceI) {
                    const max = palette.slices[sliceI + 1];
                    if (perTr.angle < max) {
                        perSlice[sliceI] += perTr.area;
                        break;
                    } else if (perTr.angle === max) {
                        perSlice[sliceI] += 0.5 * perTr.area;
                        perSlice[sliceI + 1] += 0.5 * perTr.area;
                        break;
                    }
                }
            }
            if (perTr.angle < currRange.min) {
                currRange.min = perTr.angle;
            }
            if (perTr.angle > currRange.max) {
                currRange.max = perTr.angle;
            }
        }

        return new TerrainMetrics(
            palette,
            perSlice,
            totalArea,
            currRange
        );
    }

    static calcSurfaceAngleToAxisPerTriangle(geometry: IrregularHeightmapGeometry, wm: Matrix4, angleToAxis: Vector3): {trIndex: number, area: number, angle: number}[] {

        const res: {trIndex: number, area: number, angle: number}[] = [];

        const v0 = new Vector3();
        const v1 = new Vector3();
        const v2 = new Vector3();
        const edge = new Vector3();
        const normal = new Vector3();

        const isMatrixIdentitiy = wm.isIdentity();

        for (let trIndex = 0, il = geometry.trianglesIndices.length / 3; trIndex < il; ++trIndex) {
            const ind0 = geometry.trianglesIndices[trIndex * 3 + 0];
            const ind1 = geometry.trianglesIndices[trIndex * 3 + 1];
            const ind2 = geometry.trianglesIndices[trIndex * 3 + 2];

            v0.setFromArray(geometry.points3d, ind0 * 3);
            v1.setFromArray(geometry.points3d, ind1 * 3);
            v2.setFromArray(geometry.points3d, ind2 * 3);

            if (!isMatrixIdentitiy) {
                v0.applyMatrix4(wm);
                v1.applyMatrix4(wm);
                v2.applyMatrix4(wm);
            }
            const area = triangleArea(v0, v1, v2);

            edge.copy(v0).sub(v2).normalize();
            v0.sub(v1).normalize(); // edge1

            normal.crossVectors(edge, v0);
            if (normal.z < 0) {
                normal.multiplyScalar(-1);
            }
            const angleToNormal = normal.angleTo(angleToAxis);
            const angle = angleToNormal - Math.PI * 0.5;

            res.push({trIndex, area, angle, })
        }
        return res;
    }


    static calcAreaMetricsForElevation(
        palette: TerrainMetricsRanges,
        geometry: IrregularHeightmapGeometry,
        wm: Matrix4
    ): TerrainMetrics {
        const perSliceTriangles = TerrainMetricsIrregular._calcTrianglesAreaPerHeightSlice(palette.slices, geometry, wm);

        const v0 = new Vector3();
        const v1 = new Vector3();
        const v2 = new Vector3();

        const perSlicesArea = perSliceTriangles.map((trianglesPointsFlat) => {
            let totalArea = 0;

            for (let i = 0; i < trianglesPointsFlat.length; i += (3 * 3)) {

                v0.setFromArray(trianglesPointsFlat, i + 3 * 0);
                v1.setFromArray(trianglesPointsFlat, i + 3 * 1);
                v2.setFromArray(trianglesPointsFlat, i + 3 * 2);

                const area = triangleArea(v0, v1, v2);
                totalArea += area;
            }
            return totalArea;
        });

        const totalArea = geometry.area();

        return new TerrainMetrics(
            palette,
            perSlicesArea,
            totalArea,
            {min: 0, max: 1}
        );
    }

    static _calcTrianglesAreaPerHeightSlice(
        heightSlices: number[], geometry: IrregularHeightmapGeometry, wm: Matrix4
    ): number[][] {

        const trianglesPointsFlatPerSlice: number[][] = heightSlices.map(_ => []);

        const positions = geometry.points3d;
        const inds = geometry.trianglesIndices;

        const sourceTrV0 = new Vector3();
        const sourceTrV1 = new Vector3();
        const sourceTrV2 = new Vector3();
        const sourceTriangles = [sourceTrV0, sourceTrV1, sourceTrV2];

        const slices = heightSlices;

        const perSlicePointsReused = [new Vector3(), new Vector3(), new Vector3(), new Vector3()] as const;
        let perSlicePolygonPointsCounter = 0;

        function addPerSlicePolygonPoint(p: Vector3) {
            if (perSlicePointsReused.length === perSlicePolygonPointsCounter) {
                LegacyLogger.deferredError('wrong triangle height slice division, more than 4 points', p);
                return;
            }
            perSlicePointsReused[perSlicePolygonPointsCounter].copy(p);
            perSlicePolygonPointsCounter += 1;
        }

        const isMatrixIdentitiy = wm.isIdentity();

        for (let indsOffset = 0; indsOffset < inds.length; indsOffset +=3) {

            const ind0 = inds[indsOffset + 0];
            const ind1 = inds[indsOffset + 1];
            const ind2 = inds[indsOffset + 2];
            sourceTrV0.setFromArray(positions, ind0 * 3);
            sourceTrV1.setFromArray(positions, ind1 * 3);
            sourceTrV2.setFromArray(positions, ind2 * 3);

            if (!isMatrixIdentitiy) {
                sourceTrV0.applyMatrix4(wm);
                sourceTrV1.applyMatrix4(wm);
                sourceTrV2.applyMatrix4(wm);
            }

            let minSliceIndex = slices.length;
            let maxSliceIndex = -1;

            const sl0 = paletteSlicesIndicesFromValue(sourceTrV0.z, slices);
            if (sl0 instanceof Array) {
                minSliceIndex = Math.min(...sl0);
                maxSliceIndex = Math.max(...sl0);
            } else if (sl0 >= 0) {
                minSliceIndex = sl0;
                maxSliceIndex = sl0;
            }
            const sl1 = paletteSlicesIndicesFromValue(sourceTrV1.z, slices);
            if (sl1 instanceof Array) {
                minSliceIndex = Math.min(minSliceIndex, ...sl1);
                maxSliceIndex = Math.max(maxSliceIndex, ...sl1);
            } else if (sl1 >= 0) {
                minSliceIndex = Math.min(minSliceIndex, sl1);
                maxSliceIndex = Math.max(minSliceIndex, sl1);
            }
            const sl2 = paletteSlicesIndicesFromValue(sourceTrV2.z, slices);
            if (sl2 instanceof Array) {
                minSliceIndex = Math.min(minSliceIndex, ...sl2);
                maxSliceIndex = Math.max(maxSliceIndex, ...sl2);
            } else if (sl2 >= 0) {
                minSliceIndex = Math.min(minSliceIndex, sl2);
                maxSliceIndex = Math.max(minSliceIndex, sl2);
            }

            const edgeDirection = new Vector3();
            const intPointReused = new Vector3();

            if (minSliceIndex == maxSliceIndex) {
                if (minSliceIndex >= 0) {
                    trianglesPointsFlatPerSlice[minSliceIndex].push(sourceTrV0.x, sourceTrV0.y, sourceTrV0.z);
                    trianglesPointsFlatPerSlice[minSliceIndex].push(sourceTrV1.x, sourceTrV1.y, sourceTrV1.z);
                    trianglesPointsFlatPerSlice[minSliceIndex].push(sourceTrV2.x, sourceTrV2.y, sourceTrV2.z);
                }

            } else {
                for (let sliceN = minSliceIndex; sliceN <= maxSliceIndex; ++sliceN) {
                    if (sliceN < 0) {
                        continue;
                    }
                    perSlicePolygonPointsCounter = 0;

                    // find polygon intersection of source triangle with 2 z planes
                    const sliceMin = slices[sliceN];
                    const sliceMax = slices[sliceN + 1];

                    for (let edgeIndex = 0; edgeIndex < 3; ++edgeIndex) {
                        const startP = sourceTriangles[edgeIndex];
                        const endP = sourceTriangles[(edgeIndex + 1) % 3];
                        if (startP.equals(endP)) {
                            continue;
                        }
                        edgeDirection.copy(endP).sub(startP).normalize();

                        if (startP.z < sliceMin) {
                            if (endP.z < sliceMin) {
                                // both below, skip
                                continue;
                            }
                            intPointReused.copy(edgeDirection).multiplyScalar(sliceMin - startP.z).add(startP);
                            addPerSlicePolygonPoint(intPointReused);// min bound intersection

                            if (endP.z > sliceMax) {
                                intPointReused.copy(edgeDirection).multiplyScalar(sliceMax - startP.z).add(startP);
                                addPerSlicePolygonPoint(intPointReused);// min bound intersection
                            }



                        } else if (startP.z > sliceMax) {
                            if (endP.z > sliceMax) {
                                // both above, skip
                                continue;
                            }
                            intPointReused.copy(edgeDirection).multiplyScalar(startP.z - sliceMax).add(startP);
                            addPerSlicePolygonPoint(intPointReused);// max bound intersection

                            if (endP.z < sliceMin) {
                                intPointReused.copy(edgeDirection).multiplyScalar(startP.z - sliceMin).add(startP);
                                addPerSlicePolygonPoint(intPointReused);// min bound intersection
                            }

                        } else {
                            // start point inside
                            addPerSlicePolygonPoint(startP);
                            if (endP.z < sliceMin) {
                                intPointReused.copy(edgeDirection).multiplyScalar(startP.z - sliceMin).add(startP);
                                addPerSlicePolygonPoint(intPointReused);// min bound intersection

                            } else if (endP.z > sliceMax) {
                                intPointReused.copy(edgeDirection).multiplyScalar(sliceMax - startP.z).add(startP);
                                addPerSlicePolygonPoint(intPointReused);// min bound intersection

                            } else {
                                // ignore, point will be added on another iteration as startP
                            }
                        }
                    }

                    if (perSlicePolygonPointsCounter < 3) {
                        LegacyLogger.deferredWarn(
                            'unexpected invalid intersection for triangle',
                            [
                                perSlicePolygonPointsCounter,
                                perSlicePointsReused.slice(0, perSlicePolygonPointsCounter).map(v => v.clone()),
                                [sliceMin, sliceMax],
                            ]
                        );
                    } else {

                        trianglesPointsFlatPerSlice[sliceN].push(perSlicePointsReused[0].x, perSlicePointsReused[0].y, perSlicePointsReused[0].z);
                        trianglesPointsFlatPerSlice[sliceN].push(perSlicePointsReused[1].x, perSlicePointsReused[1].y, perSlicePointsReused[1].z);
                        trianglesPointsFlatPerSlice[sliceN].push(perSlicePointsReused[2].x, perSlicePointsReused[2].y, perSlicePointsReused[2].z);


                        if (perSlicePolygonPointsCounter > 3) {
                            trianglesPointsFlatPerSlice[sliceN].push(perSlicePointsReused[0].x, perSlicePointsReused[0].y, perSlicePointsReused[0].z);
                            trianglesPointsFlatPerSlice[sliceN].push(perSlicePointsReused[2].x, perSlicePointsReused[2].y, perSlicePointsReused[2].z);
                            trianglesPointsFlatPerSlice[sliceN].push(perSlicePointsReused[3].x, perSlicePointsReused[3].y, perSlicePointsReused[3].z);
                        }

                        if (perSlicePolygonPointsCounter > 4) {
                            LegacyLogger.deferredWarn(
                                'unexpected invalid intersection for triangle',
                                [
                                    perSlicePolygonPointsCounter,
                                    perSlicePointsReused.slice(0, perSlicePolygonPointsCounter).map(v => v.clone()),
                                    [sliceMin, sliceMax],
                                ]
                            );
                        }
                    }
                }
            }
        }

        return trianglesPointsFlatPerSlice;
    }

    static calculateCutFillHeatmapForTileGeos(
        tileAabb: Aabb2,
        initialGeo: IrregularHeightmapGeometry,
        updatedGeo: IrregularHeightmapGeometry,
        heatmapSize: number,
    ): PerTileCutFillHeatmap {

        const tilePointsCount = heatmapSize + 1;
        const tileSizeMeters = tileAabb.getSize().x;
        const heatmapStepMeters = tileSizeMeters / heatmapSize;
        const samplesCoords: Vector2[] = [];
        {
            for (let iy = 0; iy <= heatmapSize; ++iy) {
                for (let ix = 0; ix <= heatmapSize; ++ix) {
                    const flatIndex = iy * tilePointsCount + ix;
                    const coord = new Vector2(
                        ix * heatmapStepMeters,
                        iy * heatmapStepMeters,
                    ).add(tileAabb.min);
                    samplesCoords[flatIndex] = coord;
                }
            }
        }

        const originalSamples = TerrainElevation.sampleFromPointsTriangulation(
            Vector3.arrayFromFlatArray(initialGeo.points3d), initialGeo.trianglesIndices, samplesCoords
        );

        const updatedSamples = TerrainElevation.sampleFromPointsTriangulation(
            Vector3.arrayFromFlatArray(updatedGeo.points3d), updatedGeo.trianglesIndices, samplesCoords
        );

        const heatmapData = new Float32Array(heatmapSize * heatmapSize);
        const averageAreas = new Float32Array(heatmapSize * heatmapSize);

        let pOr00 = new Vector3(), pOr10 = new Vector3(), pOr01 = new Vector3(), pOr11 = new Vector3();
        let pUd00 = new Vector3(), pUd10 = new Vector3(), pUd01 = new Vector3(), pUd11 = new Vector3();
        let cOr = new Vector3(), cUd = new Vector3();

        for (let iy = 0; iy < heatmapSize; ++iy) {
            for (let ix = 0; ix < heatmapSize; ++ix) {
                const flatIndices = [iy * tilePointsCount + ix, iy * tilePointsCount + ix + 1, 
                    (iy + 1) * tilePointsCount + ix, (iy + 1) * tilePointsCount + ix + 1];
                const originalSample = [originalSamples[flatIndices[0]], originalSamples[flatIndices[1]],
                    originalSamples[flatIndices[2]], originalSamples[flatIndices[3]]];
                const updatedSample = [updatedSamples[flatIndices[0]], updatedSamples[flatIndices[1]],
                    updatedSamples[flatIndices[2]], updatedSamples[flatIndices[3]]];

                
                if (originalSample.every(s => s.distToRealSample < 0.05) && updatedSample.every(s => s.distToRealSample < 0.05)) {
                    pOr00.set(ix * heatmapStepMeters, iy * heatmapStepMeters, originalSample[0].elevation!);
                    pOr10.set((ix + 1) * heatmapStepMeters, iy * heatmapStepMeters, originalSample[1].elevation!);
                    pOr01.set(ix * heatmapStepMeters, (iy + 1) * heatmapStepMeters, originalSample[2].elevation!);
                    pOr11.set((ix + 1) * heatmapStepMeters, (iy + 1) * heatmapStepMeters, originalSample[3].elevation!);

                    cOr.x = (ix + 0.5) * heatmapStepMeters;
                    cOr.y = (iy + 0.5) * heatmapStepMeters;
                    cOr.z = 0.25 * (pOr00.z + pOr01.z + pOr10.z + pOr11.z);

                    pUd00.set(ix * heatmapStepMeters, iy * heatmapStepMeters, updatedSample[0].elevation!);
                    pUd10.set((ix + 1) * heatmapStepMeters, iy * heatmapStepMeters, updatedSample[1].elevation!);
                    pUd01.set(ix * heatmapStepMeters, (iy + 1) * heatmapStepMeters, updatedSample[2].elevation!);
                    pUd11.set((ix + 1) * heatmapStepMeters, (iy + 1) * heatmapStepMeters, updatedSample[3].elevation!);

                    cUd.x = (ix + 0.5) * heatmapStepMeters;
                    cUd.y = (iy + 0.5) * heatmapStepMeters;
                    cUd.z = 0.25 * (pUd00.z + pUd01.z + pUd10.z + pUd11.z);

                    const diff = cUd.z - cOr.z;
                    const originalArea = calcTriangleAreaByPoints(pOr00, pOr10, cOr) + calcTriangleAreaByPoints(pOr10, pOr11, cOr) + 
                        calcTriangleAreaByPoints(pOr11, pOr01, cOr) + calcTriangleAreaByPoints(pOr01, pOr00, cOr);
                    const updatedArea = calcTriangleAreaByPoints(pUd00, pUd10, cUd) + calcTriangleAreaByPoints(pUd10, pUd11, cUd) + 
                        calcTriangleAreaByPoints(pUd11, pUd01, cUd) + calcTriangleAreaByPoints(pUd01, pUd00, cUd);

                    heatmapData[iy * heatmapSize + ix] = diff;
                    averageAreas[iy * heatmapSize + ix] = 0.5 * (originalArea + updatedArea);
                } else {
                    heatmapData[iy * heatmapSize + ix] = NaN;
                    averageAreas[iy * heatmapSize + ix] = NaN;
                }
            }
        }

        return PerTileCutFillHeatmap.newFromFloats(
            heatmapStepMeters,
            tileSizeMeters,
            heatmapSize,
            heatmapData,
            averageAreas
        );
    }
}
