import { createNotificationSource } from 'ui-bindings';

export const EngineNotifications = createNotificationSource({
    takingScreenshot: {
        header: 'Taking screenshot',
        description: "Please do not interact with the model until it's done",
    },
    invalidParent: {
        header: 'Invalid parent',
        description: (parentWithChildrenIds: [string, string[]]) => `The parent ${parentWithChildrenIds[0]} has invalid children: [${parentWithChildrenIds[1].join(', ')}]. Please ensure all children are correctly assigned to their respective parents in the hierarchy.`
    }
});
