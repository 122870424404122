// automatically generated by the FlatBuffers compiler, do not modify

export enum BimGeometryType {
  Triangle = 0,
  Polyline = 1,
  Cube = 2,
  ExtrudedPolygon = 3,
  GraphGeometry = 4,
  HeightmapRegular = 16,
  HeightmapIrregular = 17
}
