import type { UndoStack } from 'engine-utils-ts';
import { IterUtils } from 'engine-utils-ts';
import { Aabb, Vec3X, Vec3Y, Vec3Z, Vector3 } from 'math-ts';

import type { IdInEntityLocal} from "../collections/LocalIds";
import { LocalIdsCounter } from "../collections/LocalIds";
import { BimGeometryType } from './BimGeometries';
import type { BimGeometryBase } from "./BimGeometriesBase";
import { BimGeometriesBase } from "./BimGeometriesBase";

const EmptyFloat64Array = new Float64Array();
export class PolylineGeometry implements BimGeometryBase {

    constructor(
        public readonly points3d: Float64Array = EmptyFloat64Array,
        public readonly pointsLocalIds: IdInEntityLocal[] = [],
        public readonly radius: number = 0,
	) {
    }

	calcAabb(): Aabb {
        let aabb = Aabb.empty();
        if (this.points3d.length >= 9) {
            aabb = Aabb.calcFromArray(this.points3d, 3, this.points3d.length - 3);
            aabb.expandByScalar(this.radius);
        }
		
        reusedV.set(
            this.points3d[3] - this.points3d[0],
            this.points3d[4] - this.points3d[1],
            this.points3d[5] - this.points3d[2],
        )
        calcDiskAabb(reusedV, this.radius, reusedAabb);
        reusedAabb.translate3(this.points3d[0], this.points3d[1], this.points3d[2]);
        aabb.union(reusedAabb);

        const last = this.points3d.length - 1;
        reusedV.set(
            this.points3d[last - 5] - this.points3d[last - 2],
            this.points3d[last - 4] - this.points3d[last - 1],
            this.points3d[last - 3] - this.points3d[last],
        );
        calcDiskAabb(reusedV, this.radius, reusedAabb);
        reusedAabb.translate3(this.points3d[last - 2], this.points3d[last - 1], this.points3d[last]);
        aabb.union(reusedAabb);
        
        return aabb;
	}
	
    checkForErrors(errors: string[]): void {
        if (this.points3d.length === 0) {
            errors.push(`points length is 0`)
        }
        if (this.points3d.length / 3 != this.pointsLocalIds.length) {
            errors.push(`invalid points:localIds counts ${this.points3d.length}:${this.pointsLocalIds.length}`);
        }
        // quick check of ids
        if (!IterUtils.areArrayItemsUnique(this.pointsLocalIds)) {
            errors.push(`ids are not unique`)
        }
        for (const p of this.points3d) {
            if (!Number.isFinite(p)) {
                errors.push(`point is not finite ${p}`)
            }
        }
    }

    static newWithAutoIdsFiltered(
        points: Vector3[] = [],
        radius: number = 0,
    ): PolylineGeometry {
        if (points.length > 2) {
            let filteredPoints = [points[0]];
            for (let i = 1; i < points.length - 1; ++i) {
                if (points[i].distanceToLine(points[i-1], points[i+1]) > 0.0001) {
                    filteredPoints.push(points[i]);
                }
            }
            filteredPoints.push(points[points.length - 1]);
            return PolylineGeometry.newWithAutoIds(filteredPoints, radius);
        } else {
            return PolylineGeometry.newWithAutoIds(points, radius);
        }
    }

    static newWithAutoIds(
        points: Vector3[] = [],
        radius: number = 0,
    ): PolylineGeometry {
        const ids: LocalIdsCounter = new LocalIdsCounter();
        return new PolylineGeometry(
            Vector3.arrToDoubleArr(points),
            ids.newIdsArray(points.length),
            radius,
        );
    }


    static localIdsMaxiumum(self: PolylineGeometry): number {
        return IterUtils.max(self.pointsLocalIds) ?? 0;
    }

	length() {
		let sum = 0;
        const p1 = new Vector3();
        const p2 = new Vector3();
        for (let i = 3; i < this.points3d.length; i += 3) {
            p1.setFromArray(this.points3d, i - 3);
            p2.setFromArray(this.points3d, i);
			sum += Vector3.distBetween(p1, p2);
        }
        return sum;
	}
}


export class PolylineGeometries extends BimGeometriesBase<PolylineGeometry> {

    constructor(
        undoStack?: UndoStack,
    ) {
        super({
            identifier: "polyline-geometries",
            idsType: BimGeometryType.Polyline,
            undoStack,
            T_Constructor: PolylineGeometry,
            interner: (p) => `${p.radius}|${p.points3d.join()}`,
            localIdsMaximumExtractor: PolylineGeometry.localIdsMaxiumum
        });
    }

}

const reusedV = new Vector3();
const reusedAabb = Aabb.empty();

function calcDiskAabb(v: Vector3, r: number, out: Aabb) {
    const nv = v.clone().normalize();
    const dx = r * nv.crossLength(Vec3X);
    const dy = r * nv.crossLength(Vec3Y);
    const dz = r * nv.crossLength(Vec3Z);
    out.elements[0] = -dx;
    out.elements[1] = -dy;
    out.elements[2] = -dz;
    out.elements[3] = dx;
    out.elements[4] = dy;
    out.elements[5] = dz;
}
