<script lang="ts">
    import {
        colorizeTrackersByWindPosition,
        TrackerWindPositionUiContext,
    } from "layout-service";
    import SelectObjectsComponent from "../pui/SelectObjects/SelectObjectsComponent.svelte";
    import { VersionedStore } from "../VersionedStore";
    import {
        PUI_ActionsNode,
        PUI_CustomPropertyNode,
        PUI_PropertyNodeNumber,
        PUI_SceneInstancesSelectorPropertyNode,
    } from "ui-bindings";
    import {
        TrackerPositionsConfig,
        SceneInstances,
        BooleanProperty,
    } from "bim-ts";
    import CustomGroupName from "../pui/custom-components/CustomGroupName.svelte";
    import NumberProperty from "../pui/NumberProperty.svelte";
    import { LazyBasic } from "engine-utils-ts";
    import ActionsProperty from "../pui/ActionsProperty.svelte";

    export let context: TrackerWindPositionUiContext;

    $: props = new VersionedStore<TrackerPositionsConfig>(context.props);

    $: isNotActive = !$props.settings.exterior_arrays.value;

    $: warnings = new VersionedStore(context.warnings);
</script>

<div class="root">
    <SelectObjectsComponent
        property={new PUI_SceneInstancesSelectorPropertyNode({
            name: "Solar arrays",
            value: $props.equipment.trackers.value.map((v) => ({ value: v })),
            onChange: (v) => {
                context.updateConfig((draft) => {
                    draft.equipment.trackers =
                        $props.equipment.trackers.withDifferentValue(
                            v.map((x) => x.value),
                        );
                });
            },
            customSelectedItemsMessage: (selected, allItems) => {
                if (allItems.length === 0) {
                    return "No solar arrays";
                } else if (
                    selected.length === allItems.length ||
                    selected.length === 0
                ) {
                    return "All solar arrays selected";
                } else if (selected.length === 1) {
                    const instance = context.bim.instances.peekById(
                        selected[0],
                    );
                    const name = instance
                        ? SceneInstances.uiNameFor(selected[0], instance)
                        : `${selected[0]}`;
                    return name;
                } else {
                    return `${selected.length} solar arrays selected`;
                }
            },
            types: $props.equipment.trackers.types,
        })}
    />
    <div class="group-offset" />
    <CustomGroupName
        property={new PUI_CustomPropertyNode({
            name: "Interior solar arrays",
            value: "",
            onChange: () => {},
            type_ident: "custom-group-name",
            context: {
                showOnlyName: true,
                switch: {
                    value: true,
                    onChange: () => {
                        console.error("should not be called");
                    },
                    disabled: true,
                },
            },
        })}
    />

    <div class="group-offset" />
    <div class="property-view">
        <CustomGroupName
            property={new PUI_CustomPropertyNode({
                name: "Lateral wind exposure:",
                value: "",
                onChange: () => {},
                type_ident: "custom-group-name",
                context: {
                    isProperty: true,
                    showOnlyName: true,
                },
            })}
        />
    </div>
    <div class="property-view">
        <NumberProperty
            sourceProperty={new PUI_PropertyNodeNumber({
                name: "Allowed exposure",
                value: $props.parameters.gapY.value,
                unit: $props.parameters.gapY.unit,
                onChange: (v) => {
                    context.updateConfig((draft) => {
                        draft.parameters.gapY =
                            $props.parameters.gapY.withDifferentValue(v);
                    });
                },
                tag: new LazyBasic(
                    "allowed-exposure-tag-x",
                    "max, for each side",
                ),
            })}
        />
    </div>
    <div class="property-view">
        <NumberProperty
            sourceProperty={new PUI_PropertyNodeNumber({
                name: "Tolerance",
                value: $props.parameters.offsetY.value,
                unit: $props.parameters.offsetY.unit,
                onChange: (v) => {
                    context.updateConfig((draft) => {
                        draft.parameters.offsetY =
                            $props.parameters.offsetY.withDifferentValue(v);
                    });
                },
                tag: new LazyBasic("tolerance x", "max, total for both sides"),
            })}
        />
    </div>
    <div class="group-offset" />

    <div class="property-view">
        <CustomGroupName
            property={new PUI_CustomPropertyNode({
                name: "Medial wind exposure:",
                value: "",
                onChange: () => {},
                type_ident: "custom-group-name",
                context: {
                    isProperty: true,
                    showOnlyName: true,
                },
            })}
        />
    </div>
    <div class="property-view">
        <NumberProperty
            sourceProperty={new PUI_PropertyNodeNumber({
                name: "Allowed exposure ",
                value: $props.parameters.gapX.value,
                unit: $props.parameters.gapX.unit,
                onChange: (v) => {
                    context.updateConfig((draft) => {
                        draft.parameters.gapX =
                            $props.parameters.gapX.withDifferentValue(v);
                    });
                },
                tag: new LazyBasic(
                    "allowed-exposure-tag-y",
                    "max, for each side",
                ),
            })}
        />
    </div>
    <div class="property-view">
        <NumberProperty
            sourceProperty={new PUI_PropertyNodeNumber({
                name: "Tolerance ",
                value: $props.parameters.offsetX.value,
                unit: $props.parameters.offsetX.unit,
                onChange: (v) => {
                    context.updateConfig((draft) => {
                        draft.parameters.offsetX =
                            $props.parameters.offsetX.withDifferentValue(v);
                    });
                },
                tag: new LazyBasic("tolerance y", "max, total for both sides"),
            })}
        />
    </div>
    <div class="group-offset" />

    <CustomGroupName
        property={new PUI_CustomPropertyNode({
            name: "Exterior arrays",
            value: "Any other arrays, placed in pairs",
            onChange: () => {},
            type_ident: "custom-group-name",
            description: $warnings.hasConfiguration,
            context: {
                switch: {
                    value: $props.settings.exterior_arrays.value,
                    onChange: () => {
                        context.updateConfig((draft) => {
                            const v = !$props.settings.exterior_arrays.value;
                            draft.settings.exterior_arrays =
                                $props.settings.exterior_arrays.withDifferentValue(
                                    v,
                                );
                            draft.settings.edge_arrays = BooleanProperty.new({
                                value: false,
                                isReadonly: !v,
                            });
                            draft.settings.edge_top_bot_arrays =
                                BooleanProperty.new({
                                    value: false,
                                    isReadonly: false,
                                });
                        });
                    },
                    disabled: false,
                },
                tooltipError: !!$warnings.hasConfiguration,
            },
        })}
    />
    <div class="group-offset" />
    <CustomGroupName
        property={new PUI_CustomPropertyNode({
            name: "Edge arrays",
            value: "No transverse wind protection",
            notActive: isNotActive,
            onChange: () => {},
            type_ident: "custom-group-name",
            description: $warnings.hasNoConfiguration,
            context: {
                switch: {
                    value: $props.settings.edge_arrays.value,
                    onChange: () => {
                        context.updateConfig((draft) => {
                            const v = !$props.settings.edge_arrays.value;
                            draft.settings.edge_arrays =
                                $props.settings.edge_arrays.withDifferentValue(
                                    v,
                                );
                            draft.settings.edge_top_bot_arrays =
                                BooleanProperty.new({
                                    value: false,
                                    isReadonly: !v,
                                });
                        });
                    },
                    disabled: $props.settings.edge_arrays.isReadonly,
                },
                tooltipError: !!$warnings.hasNoConfiguration,
            },
        })}
    />
    <CustomGroupName
        property={new PUI_CustomPropertyNode({
            name: "Edge-top/bottom",
            value: "Has transverse wind protection on one side only",
            notActive: isNotActive,
            onChange: () => {},
            type_ident: "custom-group-name",
            context: {
                multiline: true,
                switch: {
                    value: $props.settings.edge_top_bot_arrays.value,
                    onChange: () => {
                        context.updateConfig((draft) => {
                            const v =
                                !$props.settings.edge_top_bot_arrays.value;
                            draft.settings.edge_top_bot_arrays =
                                $props.settings.edge_top_bot_arrays.withDifferentValue(
                                    v,
                                );
                        });
                    },
                    disabled:
                        $props.settings.edge_top_bot_arrays.isReadonly ||
                        !$props.settings.edge_arrays.value,
                },
            },
        })}
    />
    <ActionsProperty
        property={new PUI_ActionsNode({
        name: "update_wind_pos",
        context: undefined,
        actions: [
            {
                label: "Colorize trackers by wind position",
                action: () => {
                    colorizeTrackersByWindPosition(
                        context.bim.instances.allIds(),
                        context.bim,
                    );
                },
                style: {
                    type: "text",
                    icon: "Color",
                },
            },
        ],
        })}
    />
    <ActionsProperty
        property={new PUI_ActionsNode({
            name: "update_wind_pos",
            context: undefined,
            actions: [
                {
                    label: "Update wind positions now",
                    action: async () => {
                        context.setWindPosition();
                    },
                    isEnabled: context.isGeneratingAvailable,
                    style: {
                        type: "text",
                        icon: "Restore",
                    },
                },
            ],
        })}
    />
</div>

<style lang="scss">
    .root {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .group-offset {
        height: 8px;
    }

    .property-view {
        width: 100%;
    }

    .property-view :global(.property-name .property-name-label) {
        margin-left: 26px;
    }
</style>
