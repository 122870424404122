// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import type { PropertyNumberRangeT } from './property-number-range.js';
import { PropertyNumberRange } from './property-number-range.js';


export class NumericRangeProperty implements flatbuffers.IUnpackableObject<NumericRangePropertyT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):NumericRangeProperty {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsNumericRangeProperty(bb:flatbuffers.ByteBuffer, obj?:NumericRangeProperty):NumericRangeProperty {
  return (obj || new NumericRangeProperty()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsNumericRangeProperty(bb:flatbuffers.ByteBuffer, obj?:NumericRangeProperty):NumericRangeProperty {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new NumericRangeProperty()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value(obj?:PropertyNumberRange):PropertyNumberRange|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new PropertyNumberRange()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

step():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

range(obj?:PropertyNumberRange):PropertyNumberRange|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new PropertyNumberRange()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

unit():string|null
unit(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
unit(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startNumericRangeProperty(builder:flatbuffers.Builder) {
  builder.startObject(4);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, valueOffset, 0);
}

static addStep(builder:flatbuffers.Builder, step:number) {
  builder.addFieldFloat64(1, step, 0.0);
}

static addRange(builder:flatbuffers.Builder, rangeOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, rangeOffset, 0);
}

static addUnit(builder:flatbuffers.Builder, unitOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, unitOffset, 0);
}

static endNumericRangeProperty(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}


unpack(): NumericRangePropertyT {
  return new NumericRangePropertyT(
    (this.value() !== null ? this.value()!.unpack() : null),
    this.step(),
    (this.range() !== null ? this.range()!.unpack() : null),
    this.unit()
  );
}


unpackTo(_o: NumericRangePropertyT): void {
  _o.value = (this.value() !== null ? this.value()!.unpack() : null);
  _o.step = this.step();
  _o.range = (this.range() !== null ? this.range()!.unpack() : null);
  _o.unit = this.unit();
}
}

export class NumericRangePropertyT implements flatbuffers.IGeneratedObject {
constructor(
  public value: PropertyNumberRangeT|null = null,
  public step: number = 0.0,
  public range: PropertyNumberRangeT|null = null,
  public unit: string|Uint8Array|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const value = (this.value !== null ? this.value!.pack(builder) : 0);
  const range = (this.range !== null ? this.range!.pack(builder) : 0);
  const unit = (this.unit !== null ? builder.createString(this.unit!) : 0);

  NumericRangeProperty.startNumericRangeProperty(builder);
  NumericRangeProperty.addValue(builder, value);
  NumericRangeProperty.addStep(builder, this.step);
  NumericRangeProperty.addRange(builder, range);
  NumericRangeProperty.addUnit(builder, unit);

  return NumericRangeProperty.endNumericRangeProperty(builder);
}
}
