import { DC_CNSTS } from 'bim-ts';
import type { Node } from './node';
import type { MakeOptional } from 'engine-utils-ts';
import type { Route } from './route';
import type { LossDropStats } from './utils';
import { sumReduce } from '../../utils';

export enum ConnectionId {}
export interface ConnectionDataModel {
    id: ConnectionId;
    from: Node;
    to: Node;
    mergingToReq?: Connection;
    route?: Route;
    lossDrop: LossDropStats;
}

type ConnectionOptionalKey = 'id' | 'lossDrop';
export type ConnectionInput = MakeOptional<
    ConnectionDataModel,
    ConnectionOptionalKey
>;
export type ConnectionDefaults = Pick<
    ConnectionDataModel,
    ConnectionOptionalKey
>;
export interface Connection extends ConnectionDataModel {}
export class Connection {
    private initParams: ConnectionInput;
    constraints: DC_CNSTS.Constraints[];
    conductorType: DC_CNSTS.ConductorType;
    conductor: DC_CNSTS.ConductorInPattern;
    pattern: DC_CNSTS.Pattern;
    conductorIdx: number;
    patternConfig: DC_CNSTS.PatternConfig;
    gauge: number;
    isCopied = false;
    label: string;
    operatingCurrent: number = 0;
    maxCurrent: number = 0;
    power: number = 0;

    /** for debug purposes */
    conductorName: string;

    private _route?: Route;
    get route(): Route {
        if (!this._route)
            throw new Error('No route found for request');
        return this._route;
    }
    set route(val: Route) {
        this._route = val;
    }
    isAc() {
        return this.from.isAc();
    }
    constructor(params: ConnectionInput) {
        this.initParams = params;
        const defaults: ConnectionDefaults = {
            id: NaN,
            lossDrop: { losses: 0, drop: 0 },
        };
        Object.assign(this, defaults);
        Object.assign(this, params);

        this.conductorIdx = Math.min(this.from.step.idx, this.to.step.idx);
        this.patternConfig = this.to.step.patternConfig;
        this.pattern = this.patternConfig.pattern;
        this.conductor = this.pattern.conductors[this.conductorIdx];
        const conductorDefaults =
            this.patternConfig.defaultCables[this.conductorIdx];
        if (!this.conductor || !conductorDefaults)
            throw new Error('No conductor config found.');
        this.gauge = conductorDefaults.gauge;
        this.constraints = this.conductor.contraints;
        this.conductorType = this.conductor.type;
        const isConductHoriz = this.conductor.contraints
            .includes(DC_CNSTS.Constraints.Horizontal);
        const isConductVert = this.conductor.contraints
            .includes(DC_CNSTS.Constraints.Vertical);
        this.label = 'other';
        this._route = params.route;
        this.conductorName = DC_CNSTS.getConductorName(this.conductorType);
    }

    copy() {
        const copied = new Connection(this.initParams);
        copied.gauge = this.gauge;
        return copied;
    }

    get temperatureParams(): DC_CNSTS.TemperatureConductorParams {
        return {
            temperature: this.patternConfig.temperature,
            isBuried: this.constraints.includes(
                DC_CNSTS.Constraints.BelowGround,
            ),
        };
    }

    isNotMainInMergingGroup() {
        if (!this.mergingToReq) return false;
        return this.mergingToReq !== this;
    }

    get length() {
        return sumReduce(this.route.pairing.routes, x => x.length);
    }

}
