<script lang="ts">
    import { getContext } from "svelte";
    import type { UiBindings } from "ui-bindings";
    import NavbarItem from "./NavbarItem.svelte";
    import {
        NavbarContext,
        navItemFromUiBindings,
        PanelPosition,
        rightNavItemFromUiBindings,
    } from "./Navbar";
    import NavbarPanel from "./NavbarPanel.svelte";
    import DropArea from "./DropArea.svelte";
    import SideToolbar from "../toolbar/SideToolbar.svelte";

    export let uiBindings: UiBindings;

    const navbar = <NavbarContext>getContext("ui-navbar");
    const panelViews = navItemFromUiBindings(uiBindings, navbar);

    $: menuItems = rightNavItemFromUiBindings(panelViews, $navbar.views);
    $: panel = $navbar.panels.getOrCreate(PanelPosition.Right);

    function getView(panelName?: string) {
        return menuItems.find((m) => m.id === panelName);
    }
    export let navbarWidth;
</script>

<div class="navbar-container text-main-dark" bind:offsetWidth={navbarWidth}>
    <div class="container" class:navbar-open={panel.open}>
        {#if menuItems.length}
            <div class="navbar text-main-medium" class:navbar-open={panel.open}>
                <div class="navbar-group">
                    {#each menuItems as menuItem (menuItem.name)}
                        <NavbarItem
                            id={menuItem.id}
                            name={menuItem.name}
                            iconName={menuItem.iconName}
                            collapsed={true}
                            active={menuItem.isActive(panel)}
                            secondary={menuItem.isSecondary(panel)}
                            onClick={menuItem.toggle}
                        />
                    {/each}
                </div>
            </div>
        {/if}
        <SideToolbar {uiBindings} />
    </div>
    {#if panel.open}
        <NavbarPanel
            position={PanelPosition.Right}
            topView={getView(panel.views[0])}
            bottomView={getView(panel.views[1])}
            --toolbar-height="32px"
        />
    {/if}
    <DropArea {navbar} side={PanelPosition.Right} />
</div>

<style lang="scss">
    .navbar-container {
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        display: flex;
    }
    .container {
        position: absolute;
        left: -44px;
        top: 32px;
        &.navbar-open {
            left: -40px;
        }
        :global(.mdc-tooltip) {
            transform: translate(-52px, -34px);
        }
        :global(.mdc-tooltip__surface) {
            white-space: nowrap;
        }
    }

    .navbar {
        border-radius: 4px 0px 0px 4px;
        box-sizing: border-box;
        margin-bottom: 8px;
        background: linear-gradient(
                0deg,
                rgba(16, 58, 82, 0.04) 0%,
                rgba(16, 58, 82, 0.04) 100%
            ),
            white;

        .navbar-group {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 4px 0;
        }
    }
</style>
