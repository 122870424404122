import { Matrix4, TransformFloats } from 'math-ts';
import type { RawDataGateway } from '../memory/RawDataGateway';

export const EmptyMatrix = Object.freeze(new Matrix4());
export class KrMatrixGateway implements RawDataGateway<Matrix4, Float32Array> {

	name: string;
	buffer!: Float32Array;
	readonly itemLength: number = TransformFloats;
	
	constructor(name: string) {
		this.name = name;
	}

	setInternalBuffer(buffer: Float32Array) {
		this.buffer = buffer;
	}
	
	toBuffer(obj: Matrix4, index: number) {
		this.buffer.set(obj.elements, index * TransformFloats);
	}

	toStruct(index: number, tr: Matrix4) {
		tr.fromArray(this.buffer, index * TransformFloats);
		return tr;
	}

	getStruct(index: number) {
		return this.toStruct(index, new Matrix4());
	}
}

