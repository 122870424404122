import { Euler, Matrix4, Transform, Vector3 } from "math-ts";
import type { Bim, SceneInstanceShapeMigration} from "../..";
import { BimMaterialStdRenderParams, StdMeshRepresentation, StdSubmeshRepresentation } from "../..";
import { BimProperty } from "../../bimDescriptions/BimProperty";
import type { PropertiesGroupFormatters } from "../../bimDescriptions/PropertiesGroupFormatter";
import { PropertiesGroupFormatter } from "../../bimDescriptions/PropertiesGroupFormatter";
import { BimPropertiesFormatter, sceneInstanceHierarchyPropsRegistry } from "../../catalog/SceneInstanceHierarchyPropsRegistry";
import type { AssetBasedCatalogItemCreators } from "../../catalog/CatalogItemCollection";
import type { ReactiveSolverBase, SolverInstancePatchResult } from "../../runtime/ReactiveSolverBase";
import { SolverObjectInstance } from "../../runtime/SolverObjectInstance";
import { registerEquipmentCommonAssetToCatalogItem } from "../EquipmentCommon";
import { ReadableTypeIdentifiers } from "../../ReadableTypeIdentifiers";
import { addEnergyPVModuleProps_ShapeMigration, addFirstSolarModuleInTrackersFix_ShapeMigration } from "./migrations/EnergyPvModulePropsMigration";

export const PVModuleTypeIdent = 'pv-module';

export const PvModuleMandatoryProps = {
    manufacturer: BimProperty.NewShared({ path: ["module", "manufacturer"], value: "" }),
    model: BimProperty.NewShared({ path: ["module", "model"], value: "" }),
    maximum_power: BimProperty.NewShared({ path: ["module", "maximum_power"], value: 0, unit: "W", numeric_range: [0, 1e9] }),
    current: BimProperty.NewShared({ path: ["module", "current"], value: 0, unit: "A", numeric_range: [0, 1e9] }),
    width: BimProperty.NewShared({ path: ["module", "width"], value: 0, unit: "m", numeric_range: [0, 1e9] }),
    length: BimProperty.NewShared({ path: ["module", "length"], value: 0, unit: "m", numeric_range: [0, 1e9] }),
    open_circuit_voltage: BimProperty.NewShared({ path: ["module", "open_circuit_voltage"], value: 0, unit: "V", numeric_range: [0, 1e9] }),
    temp_coeff_power: BimProperty.NewShared({ path: ["module", "temp_coeff_power"], value: 0, unit:"W/C" }),
    temp_coeff_voltage: BimProperty.NewShared({ path: ["module", "temp_coeff_voltage"], value: 0, unit:"V/C" }),
    temp_coeff_current: BimProperty.NewShared({ path: ["module", "temp_coeff_current"], value: 0, unit:"W/C" }),
    bifaciality_factor: BimProperty.NewShared({ path: ["module", "bifaciality_factor"], value: 0 }),
    technology: BimProperty.NewShared({ path: ["module", "technology"], value: "" }),
    power_degradation: BimProperty.NewShared({ path: ["module", "degradation", "power_degradation"], value: 0 }),
    first_year_power_degradation: BimProperty.NewShared({ path: ["module", "degradation", "first_year_power_degradation"], value: 0 }),
    standard: BimProperty.NewShared({ path: ["module", "mounting", "standard"], value: "" }),
    short_circuit_current: BimProperty.NewShared({ path: ["module", "short_circuit_current"], value: 0, unit: "A" }),
    max_system_voltage: BimProperty.NewShared({ path: ["module", "max_system_voltage"], value: 0, unit: "V" }),
} satisfies { [key: string]: BimProperty };

type PvModulePropsKeys = Pick<typeof PvModuleMandatoryProps, "manufacturer" | "model" | "maximum_power" | "current" | "width" | "length">;
export const PVModuleKeyProps = {
    manufacturer: PvModuleMandatoryProps.manufacturer,
    model: PvModuleMandatoryProps.model,
    maximum_power: PvModuleMandatoryProps.maximum_power,
    current: PvModuleMandatoryProps.current,
    width: PvModuleMandatoryProps.width,
    length: PvModuleMandatoryProps.length,
} satisfies PvModulePropsKeys;

export function registerPVModuleArchetype(bim: Bim) {
    bim.instances.archetypes.registerArchetype({
        type_identifier: PVModuleTypeIdent,
        mandatoryProps: Array.from(Object.values(PvModuleMandatoryProps)),
        propsShapeMigrations: migrations(),
    });
    bim.reactiveRuntimes.registerRuntimeSolver(pvModuleMeshGenerator(bim));
}

export function createModuleMountingProps_ShapeMigration(toVersion: number)
    : SceneInstanceShapeMigration
{
    const mountingStdPath = ['module', 'mounting', 'standard'];
    return {
        toVersion,
        validation: {
            updatedProps: [{ path: mountingStdPath }],
            deletedProps: [],
        },
        patch: (inst) => {
            const mountingStd = inst.properties.get(
                BimProperty.MergedPath(mountingStdPath)
            );
            if (mountingStd) {
                return;
            }
            // default mounting hole spacing is module width
            inst.properties.applyPatch([
                [
                    BimProperty.MergedPath(mountingStdPath),
                    {
                        value: "",
                        path: mountingStdPath,
                    }
                ]
            ])
        }
    }
}

export function fixModuleModelIsNumber_ShapeMigration(toVersion: number)
    : SceneInstanceShapeMigration
{
    return {
        toVersion,
        validation: {
            updatedProps: [{ path: ['module',  'model'] }],
            deletedProps: [],
        },
        patch: (inst) => {
            const moduleModel = inst.properties.get('module | model');
            if (!moduleModel) {
                console.error('module | model property not found');
                return;
            }
            if (typeof moduleModel.value === 'number') {
                inst.properties.applyPatch([
                    ['module | model', { value: "" + moduleModel.value}]
                ]);
            }
        }
    }
}

function migrations():SceneInstanceShapeMigration[]{
    return [
        fixModuleModelIsNumber_ShapeMigration(1),
        createModuleMountingProps_ShapeMigration(2),
        //rewrite version 3 addEnergyPVModuleProps_ShapeMigration
        addEnergyPVModuleProps_ShapeMigration(4),
        addFirstSolarModuleInTrackersFix_ShapeMigration(5),
    ]
}

export function registerPvModuleKeyPropertiesGroupFormatter(group: PropertiesGroupFormatters) {
    group.register(
        PVModuleTypeIdent,
        new PropertiesGroupFormatter(
            PVModuleKeyProps,
            (props, unitsMapper) => {
                return [
                    [
                        props.manufacturer,
                        props.model
                    ].map(x => x.asText()),
                    props.maximum_power.valueUnitUiString(unitsMapper),
                ].flat().join(' ');
            }
        )
    )
}

export const ModuleHeight = 0.05;

export function registerPVModuleAssetToCatalogItem(group: AssetBasedCatalogItemCreators) {
    registerEquipmentCommonAssetToCatalogItem(PVModuleTypeIdent, group);
}

export function pvModuleMeshGenerator(bim: Bim): ReactiveSolverBase {
    const PVModuleMeshGenInput = {
        legacyProps: {
            module_size_x: BimProperty.NewShared({path: ["module", "width"], value: 0, unit: 'm'}),
            module_size_y: BimProperty.NewShared({path: ["module", "length"], value: 0, unit: 'm'}),
        }
    }
    return new SolverObjectInstance({
        solverIdentifier: 'pv-module-mesh-gen',
        objectsDefaultArgs: PVModuleMeshGenInput,
        objectsIdentifier: PVModuleTypeIdent,
        cache: true,
        solverFunction: (inputObj): SolverInstancePatchResult => {
            const module_size_x = inputObj.legacyProps.module_size_x.as('m');
            const module_size_y = inputObj.legacyProps.module_size_y.as('m');
            const moduleGeoId = bim.cubeGeometries.shared!.get({
                size: new Vector3(module_size_x, module_size_y, ModuleHeight),
                // center: new Vector3(module_size_x * 0.5, )
            })!;
            const moduleMatId = bim.bimMaterials.shared!.get({
                name: "default",
                stdRenderParams: new BimMaterialStdRenderParams(
                    "#000077",
                    0,
                    0.85,
                    0.15
                ),
            })!;
            const submeshesResult: StdSubmeshRepresentation[] = [];
            const matReused = new Matrix4();
            const angle = 0;

            const rotateAroundPoleMatrix = new Matrix4().makeRotationFromEuler(
                new Euler(angle, 0, 0)
            );
            const rotateAroundZMatrix = new Matrix4().makeRotationFromEuler(
                new Euler(0, 0, Math.PI / 2)
            );
            const rotateAroundYMatrix = new Matrix4().makeRotationFromEuler(
                new Euler(0, angle, 0)
            );
            const rotateAroundPoint = new Matrix4();
            const rotateModuleAroundPoleMatrix = new Matrix4()
                .getInverse(rotateAroundPoint)
                .premultiply(rotateAroundPoleMatrix)
                .premultiply(rotateAroundZMatrix)
                .premultiply(rotateAroundPoint);
            matReused.premultiply(rotateModuleAroundPoleMatrix);
            // matReused.premultiply(rotateEverythingToPlusY);

            const tr = new Transform();
            tr.setFromMatrix4(matReused);
            submeshesResult.push(
                new StdSubmeshRepresentation(
                    moduleGeoId,
                    moduleMatId,
                    tr
                )
            );
            return {
                repr: submeshesResult.length ? new StdMeshRepresentation(submeshesResult) : null,
            }
        }
    })
}

sceneInstanceHierarchyPropsRegistry.set(
    PVModuleTypeIdent,
    [
        new BimPropertiesFormatter(
            {
                manufacturer: BimProperty.NewShared({
                    path: ['module', 'manufacturer'],
                    value: '',
                }),
            },
            (props) => [props.manufacturer.asText() || 'manufacturer not specified'],
        ),
        new BimPropertiesFormatter(
            {
                mounting_standard: BimProperty.NewShared({
                    path: ['module', 'mounting', 'standard'],
                    value: '',
                }),
            },
            (props) => [props.mounting_standard.asText() || 'mounting standard not specified'],
        )
    ]
);

ReadableTypeIdentifiers.set(PVModuleTypeIdent, 'PV Module');

export const ModuleUniqueProps = {
    manufacturer: BimProperty.NewShared({
        path: ['module', 'manufacturer'],
        value: 'unknown',
    }),
    model: BimProperty.NewShared({
        path: ['module', 'model'],
        value: 'unknown',
    }),
    power: BimProperty.NewShared({
        path: ['module', 'maximum_power'],
        value: 0, unit: 'W',
    }),
}
