import { LCOE, CostModel as CM, UnitsMapper } from "bim-ts";
import { ContextPlaceholder } from "../types";
import {
    CellStyle,
    ColDef,
    ValueGetterFunc,
    ValueParserFunc,
    ValueSetterFunc,
    ValueSetterParams,
} from "ag-grid-community";

export interface CategoryContext {
    category?: LCOE.LcoeCategory;
    data?: CM.FlattenedCostCategoryParams;
    unitsMapper: UnitsMapper;
    globalContext: ContextPlaceholder;
}

export function getContext(params: {
    data?: CM.FlattenedCostCategoryParams;
    context: ContextPlaceholder;
}): CategoryContext {
    const result: CategoryContext = {
        unitsMapper: params.context.unitsMapper,
        globalContext: params.context,
    };
    if (!params.data) {
        return result;
    }
    result.data = params.data;
    const context = params.context;
    const category = context.hierarchy.categories.get(params.data.categoryId);
    result.category = category;
    return result;
}

export const commonFontSize = (style: CellStyle, context: CategoryContext) => {
    if (context.data) {
        const nest = context.data.nestLevel;
        if (nest === 1) {
            style["font-size"] = "16px";
            style["display"] = "flex";
            style["align-items"] = "flex-end";
        } else if (nest === 2) {
            style["font-size"] = "15px";
            style["display"] = "flex";
            style["align-items"] = "flex-end";
        }
    }
};

export const commonFontWeight = (
    style: CellStyle,
    context: CategoryContext,
) => {
    if (context.data) {
        const nest = context.data.nestLevel;
        if (nest <= 1) {
            style["font-weight"] = "600";
        } else if (nest == 2) {
            style["font-weight"] = "500";
        }
    }
};

export const commonFontColor = (style: CellStyle, context: CategoryContext) => {
    if (context.data) {
        const nest = context.data.nestLevel;
        if (nest > 2) {
            style["color"] = defaultTextColor;
        }
    }
};

export const commonCellClasses = (
    context: CategoryContext,
    cellGetter: (category: LCOE.LcoeCategory) => CM.TableCell | undefined,
) => {
    if (!context.category || !context.data) {
        return [];
    }
    //const hierarchy = context.globalContext.hierarchy;

    const cell = cellGetter(context.category);

    const classes = new Set<string>();
    const categoryId = context.data?.categoryId;
    if (categoryId !== undefined) {
        //const categoryIgnored = hierarchy.ignored.has(categoryId);
        //const categoryDisableEdit = hierarchy.disableEdit.has(categoryId);
        //if (cell?.flags?.ignored || categoryIgnored) {
        //    classes.add('ag-grid-cell-ignored');
        //}
        //if (!categoryDisableEdit) {
        if (cell?.flags?.overriden) {
            classes.add("ag-grid-cell-overriden-highlight");
        } else if (cell?.flags?.editable) {
            classes.add("ag-grid-cell-editable-highlight");
        }
        //}
    }
    return Array.from(classes);
};

export function createModifiableCostCellDefinition(
    getter: (category: LCOE.LcoeCategory) => CM.ModifiableCostCell | undefined,
    roundNumberFormatting?: boolean,
) {
    const result = {} satisfies ColDef<CM.FlattenedCostCategoryParams>;
    return result;
}

export const defaultTextColor = "rgba(15, 19, 20, 0.87)";
