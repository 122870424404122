import { Vector2, Vector3 } from "math-ts";
const metersTofeetRatio = 0.3048;

export function MetersToFeet(meters: number): number {
    return meters / metersTofeetRatio;
}

export function FeetToMeters(feet: number): number {
    return feet * metersTofeetRatio;
}

export function Vector3ToFeet(vec: Vector3): Vector3 {
    return vec.divideScalar(metersTofeetRatio);
}

export function Vector2ToFeet(vec: Vector2): Vector2 {
    return vec.divideScalar(metersTofeetRatio);
}

export function RadToDeg(rad: number): number {
    let angle = (rad * 180) / Math.PI;
    return +angle.toFixed(2);
}
