import type { TasksRunner , Result} from "engine-utils-ts";
import { Failure, IterUtils, Success } from "engine-utils-ts";
import type { UiBindings } from "ui-bindings";
import type { Bim, MathSolversApi, NumberProperty, IdBimScene, SceneInstance} from "bim-ts";
import {  TransformerIdent } from "bim-ts";
import type { AugmentedHierarchy, AugmentedHierarchyNode, FlatHierarchyDimensions, Point2D, TrackerDimensions } from "./common";
import { ApplyAugmentationToScene, cleanupSceneHierarchy, gatherBlockDimensions, getChildEquipments, resetSceneZCoord, TrackersTypes } from "./common";
import { getResultValueOrThrow } from 'engine-utils-ts';
import { calcCiPixelValue } from "../farm-layout/ElectricalPatternFormulas";
import type { TrackerDimensions as TrackerInstanceDescription} from '../TrackerCommon';
import { calculateTrackerDimensions } from '../TrackerCommon';

export const create_cb_ci_block_imported = (solverName: string) => async function (
    // input
    input: CbCiBlockImportedInput,

    // dependencies
    bim: Bim,
    mathSolversApi: MathSolversApi,
    tasksRunner: TasksRunner,
    uiBindings: UiBindings,
) {
    await resetSceneZCoord(bim, input.blocks.map(x => x[0]), tasksRunner, uiBindings);

    const pixel_value = calculate_CI_pixel_value_watt(
        input.blocks.map(x => x[0]),
        input.combinerBox,
        input.necMultiplier,
        bim,
    );

    const solverInput: CbCiBlockImportedSolverInput = {
        blocks: gatherBlockDimensions(input.blocks.map(x => x[0]), bim),
        box_offset: input.combinerBoxOffset.as('m'),
        pixel_value: getResultValueOrThrow(pixel_value, 'pixel_value calculation'),
    };

    // calculation
    const solverOutput = await mathSolversApi.callSolver<
        CbCiBlockImportedSolverInput,
        CbCiBlockImportedSolverOutput
    >({
        solverName: solverName,
        request: solverInput,
        solverType: 'single',
    })

    // apply to scene
    applyCbCiSolverResultToScene(input, solverInput, solverOutput, bim);
}

export const cb_ci_block_imported = create_cb_ci_block_imported('cb_ci_block_imported');

export function applyCbCiSolverResultToScene(
    // input
    input: CiBlockImportedInputBase,
    solverInput: CiBlockImportedSolverInputBase,
    solverResult: CbCiBlockImportedSolverOutput,

    // dependencies
    bim: Bim,
) {
    const combinerBox = input.combinerBox;
    const inverterPerBlock = new Map(input.blocks);
    const blockDimensions = new Map(solverInput.blocks.map(x => [x.id, x]));

    const augmentedBlocks: AugmentedHierarchy[] = [];
    const touchedBlocks: FlatHierarchyDimensions[] = [];

    for (const block of solverResult.blocks) {
        const inverter = inverterPerBlock.get(block.id);
        const blockDimension = blockDimensions.get(block.id);
        if (!blockDimension || !inverter) {
            console.warn('block description not found. skipping');
            continue;
        }
        touchedBlocks.push(blockDimension);
        const augmentedBlock: AugmentedHierarchy = {
            children: [],
            transformerId: block.id,
        };
        augmentedBlocks.push(augmentedBlock);

        const inverterHierarchy: AugmentedHierarchyNode = {
            equipmentTemplate: inverter,
            position: {
                x: blockDimension.transformer.x,
                y: blockDimension.transformer.y + 2,
            }
        };

        inverterHierarchy.children = [];
        augmentedBlock.children.push(inverterHierarchy);

        for (const pixel of block.pixels) {
            const combinerHierarchy: AugmentedHierarchyNode = {
                position: pixel.combiner_box,
                equipmentTemplate: combinerBox,
                trackers: pixel.trackers
            }
            inverterHierarchy.children.push(combinerHierarchy)
        }
    }
    cleanupSceneHierarchy(bim, touchedBlocks);
    ApplyAugmentationToScene.augmentBlock(bim, augmentedBlocks);
}


export interface CiBlockImportedInputBase {
    combinerBox: SceneInstance,
    blocks: Array<[
        transformerId: IdBimScene,
        inverter: SceneInstance,
    ]>,
    combinerBoxOffset: NumberProperty,
    inverterOffset: NumberProperty,
}

export interface CbCiBlockImportedInput extends CiBlockImportedInputBase {
    necMultiplier: number,
}

export interface CiBlockImportedSolverInputBase {
    blocks: FlatHierarchyDimensions[]
    // grouping optimal power
    pixel_value: number
}

export interface CbCiBlockImportedSolverInput extends CiBlockImportedSolverInputBase {
    box_offset: number
}

export interface CbCiBlockImportedSolverOutput {
    blocks: Array<{
        pixels: Array<{
            trackers: TrackerDimensions[]
            combiner_box: Point2D
        }>
        id: number
    }>
}

export function calculate_CI_pixel_value_watt(
    transformers: IdBimScene[],
    combinerBox: SceneInstance,
    necMultiplier: number,

    // dependencies
    bim: Bim
): Result<number> {
    const mostPowerfullTrackerResult = findMostPowerfullTracker(transformers, bim);
    if (mostPowerfullTrackerResult instanceof Failure) {
        return mostPowerfullTrackerResult;
    }
    const mostPowerfulTracker = mostPowerfullTrackerResult.value;
    const combinerBoxCurrentAmp = combinerBox.properties.get('input | current')?.as('A') ?? 0;
    const pixelValue = calcCiPixelValue(
        combinerBoxCurrentAmp,
        mostPowerfulTracker.string_power,
        mostPowerfulTracker.string_max_current,
        necMultiplier,
    );

    return new Success(pixelValue);
}

export function findMostPowerfullTracker(
    parents: IdBimScene[],
    bim: Bim,
): Result<TrackerInstanceDescription> {
    const allTrackers = getChildEquipments(
        parents,
        bim,
        TrackersTypes,
        false,
        [TransformerIdent],
    );
    const trackerDimensions = allTrackers.map((x) => calculateTrackerDimensions(x[1]));
    const mostPowerfulTracker = IterUtils.maxBy(trackerDimensions, (x) => x.dc_power * x.string_power);
    if (!mostPowerfulTracker) {
        // block does not have trackers
        return new Failure({msg: 'block does not have trackers'});
    }
    return new Success(mostPowerfulTracker)
}

