import type { UndoStack } from 'engine-utils-ts';
import { Aabb } from 'math-ts';

import { BimGeometryType } from '../schema/bim-geometry-type';
import type { BimGeometryBase } from './BimGeometriesBase';
import { BimGeometriesBase } from './BimGeometriesBase';

export class TriGeometry implements BimGeometryBase {
    constructor(
        public readonly positions: Float32Array = new Float32Array(),
        public readonly normals: Int8Array = new Int8Array(),
        public readonly uvs: Float32Array | null = null,
        public readonly indices: Uint32Array | Uint16Array = new Uint16Array(),
        public readonly edgeindices: Uint32Array | Uint16Array | null = null,
    ) {
    }
	calcAabb(): Aabb {
		const res = Aabb.empty();
		res.expandByFlatArray(this.positions);
		return res;
	}
	checkForErrors(errors: string[]): void {
		if (!(this.indices.length > 2)) {
            errors.push(`invalid indices count ${this.indices.length}`);
        }
        if (!(this.positions.length > 3 * 3)) {
            errors.push(`invalid positions count ${this.positions.length}`);
        }
	}
}

export class TriGeometries extends BimGeometriesBase<TriGeometry> {

    constructor(
        undoStack?: UndoStack,
    ) {
        super({
            identifier: "tri-geometries",
            idsType: BimGeometryType.Triangle,
            undoStack,
            T_Constructor: TriGeometry
        })
    }
}
