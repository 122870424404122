import type { TasksRunner } from "engine-utils-ts";
import { Matrix4, Vector2, Vector3 } from "math-ts";
import type { UiBindings } from "ui-bindings";
import type {
    AssetCatalogItemProps,
    Bim,
    Catalog,
    IdBimScene,
    SceneInstance,
    SceneInstancePatch
} from "bim-ts";

import {
    AssetCatalogItemTypeIdentifier,
    BoundaryTypeIdent,
    FixedTiltTypeIdent,
    RoadTypeIdent,
    SceneInstances,
    TrackerTypeIdent,
    TransformerIdent,
    InverterTypeIdent,
    CombinerBoxTypeIdent,
    SubstationTypeIdent,
    resetTerrain,
    TerrainInstanceTypeIdent,
} from "bim-ts";
import { calculateTrackerDirection, calculateTrackerDimensions } from '../TrackerCommon';

export const TrackersTypes = [TrackerTypeIdent, FixedTiltTypeIdent, 'any-tracker'];

export function gatherBlockDimensions(transformers: IdBimScene[], bim: Bim) {
    // gather blocks
    const blocks: FlatHierarchyDimensions[] = [];

    for (const transformer of bim.instances.peekByIds(transformers)) {
        const xy = transformer[1].worldMatrix.extractPosition().xy();
        const block: FlatHierarchyDimensions = {
            trackers: [],
            transformer: { x: xy.x, y: xy.y },
            id: transformer[0],
        };
        const trackers = getChildEquipments(
            [transformer[0]],
            bim,
            TrackersTypes,
            false,
            [TransformerIdent],
        )

        for (const [id, tracker] of trackers) {
            const trackerDimensions = calculateTrackerDimensionsFromSceneInstance(tracker);
            trackerDimensions.id = id
            block.trackers.push(trackerDimensions);
        }
        
        blocks.push(block);
    }


    // return only blocks with trackers present
    const nonEmpty = blocks.filter(x => x.trackers.length);

    return nonEmpty;
}

export function getFirstCombinerBoxAndInverterFromCatalog(catalog: Catalog) {
    let cbAC: SceneInstance | undefined;
    let cbDC: SceneInstance | undefined;
    let ci: SceneInstance | undefined;
    let si: SceneInstance | undefined;
    for (const catalogItem of catalog.catalogItems.perId.values()) {
        if (cbAC && cbDC && ci && si) {
            break;
        }
        if (catalogItem.typeIdentifier !== AssetCatalogItemTypeIdentifier) {
            continue;
        }
        const assetCatalogItem = catalogItem.as<AssetCatalogItemProps>();
        const assetId = assetCatalogItem.properties.asset_id.value;
        const asset = catalog.assets.sceneInstancePerAsset.getAssetAsSceneInstance(assetId);
        if (!asset) {
            continue
        }
        if (asset.type_identifier === CombinerBoxTypeIdent) {
            const cbType = asset.properties.get('commercial | type')?.asText().toLowerCase();
            if (cbType?.startsWith('ac')) {
                cbAC = asset
            } else if (cbType?.startsWith('dc')) {
                cbDC = asset
            }
        } else if (asset.type_identifier === InverterTypeIdent) {
            const inverterModel = asset.properties.get('commercial | model')?.asText().toLowerCase();
            if (inverterModel?.startsWith('string')) {
                si = asset;
            } else if (inverterModel?.startsWith('central')) {
                ci = asset;
            }
        }
    }
    if (!cbAC || !ci || !cbDC || !si) {
        throw new Error('combiner box/inverter templates not found');
    }
    return {
        centralInverter: ci,
        combinerBoxAC: cbAC,
        combinerBoxDC: cbDC,
        stringInverter: si,
    };
}

export function calculateTrackerDimensionsFromSceneInstance(si: SceneInstance) {
    const center = si.worldMatrix.extractPosition().xy();
    const dir2d = calculateTrackerDirection(si);
    const info = calculateTrackerDimensions(si);
    const start = center.clone().addScaledVector(new Vector2(...dir2d), -info.length / 2);
    const dimensions: TrackerDimensions = {
        x: start.x,
        y: start.y,
        dir_x: dir2d[0],
        dir_y: dir2d[1],
        h: info.length,
        w: info.width,
        v: info.dc_power,
        r: null,
        id: 0,
    };
    return dimensions;
}

export function cloneSceneInstance(si: SceneInstance) {
    const siClone = {
        type_identifier: si.type_identifier,
        flags: si.flags,
        name: si.name,
        localTransform: si.localTransform.clone(),
        properties: si.properties.clone(),
        spatialParentId: si.spatialParentId,
    };
    return siClone;
}

export interface Point2D {
    x: number,
    y: number,
}
export interface TrackerDimensions extends 
    // coord of left-bottom corner 
    Point2D
{
    // width
    w: number,
    // height
    h: number,
    // dir vector form left-bottom corner
    dir_x: number,
    dir_y: number,
    r: null,
    // power
    v: number,
    // unique identifier of the tracker
    id: number,
}

export type SceneInstanceAndId = [id: IdBimScene, sceneInstance: SceneInstance];

const reusedV3 = new Vector3();
const reusedV2 = new Vector2();

export interface FlatHierarchyDimensions {
    trackers: TrackerDimensions[],
    transformer: Point2D
    id: number
}

export interface FlatHierarchy {
    dimensions: FlatHierarchyDimensions,
    transformer: SceneInstanceAndId,
    trackers: SceneInstanceAndId[]
}

export interface AugmentedHierarchyNode {
    equipmentTemplate: SceneInstance
    position: Point2D
    trackers?: TrackerDimensions[]
    children?: AugmentedHierarchyNode[]
}

export interface AugmentedHierarchy {
    transformerId: IdBimScene;
    children: AugmentedHierarchyNode[]
}

export interface ParentReference {
    id: IdBimScene
    worldMatrix: Readonly<Matrix4>
}

export class ApplyAugmentationToScene {
    static augmentBlock(
        bim: Bim,
        augmentedBlocks: AugmentedHierarchy[],
    ) {
        const patches: SceneInstancePatchPerId[] = [];
        const toAllocate: SceneInstanceAllocation[] = [];
        for (let i = 0; i < augmentedBlocks.length; i++) {
            const augmented = augmentedBlocks[i]
            const transformer = bim.instances.peekById(augmented.transformerId)!;
            const transformerReferenceAsParent: ParentReference = {
                id: augmented.transformerId,
                worldMatrix: transformer.worldMatrix,
            }
            for (const node of augmented.children) {
                ApplyAugmentationToScene.addAugmentedHierarchyNode(
                    bim, transformerReferenceAsParent, node, patches, toAllocate
                );
            }
        }
        bim.instances.allocate(toAllocate);
        bim.instances.applyPatches(patches);
    }

    static addAugmentedHierarchyNode(
        bim: Bim,
        parent: ParentReference,
        hierarchy: AugmentedHierarchyNode,
        patches: SceneInstancePatchPerId[] = [],
        toAllocate: SceneInstanceAllocation[] = [],
    ) {
        const equipmentCloneId = bim.instances.reserveNewId(); 
        const equipmentClone = cloneSceneInstance(hierarchy.equipmentTemplate);
        const equipmentWorldMatrix = new Matrix4()
            .setPosition(hierarchy.position.x, hierarchy.position.y, 0);
        const equipmentLocalTransform = SceneInstances
            .getLocalTransformRelativeToParentMatrix(parent.worldMatrix, equipmentWorldMatrix);
        equipmentClone.localTransform.copy(equipmentLocalTransform);
        equipmentClone.spatialParentId = parent.id;
        toAllocate.push([equipmentCloneId, equipmentClone]);
        const equipmentAsParentReference: ParentReference = {
            id: equipmentCloneId,
            worldMatrix: equipmentWorldMatrix,
        }

        if (hierarchy.trackers) {
            ApplyAugmentationToScene.addTrackers(bim, equipmentAsParentReference, hierarchy.trackers, patches);
        } else if (hierarchy.children) {
            for (const child of hierarchy.children) {
                ApplyAugmentationToScene.addAugmentedHierarchyNode(
                    bim, equipmentAsParentReference, child, patches, toAllocate
                )
            }
        }
    }

    static addTrackers(
        bim: Bim,
        parent: ParentReference,
        trackers: TrackerDimensions[],
        patches: SceneInstancePatchPerId[] = []
    ) {
        for (const tracker of trackers) {
            const si = bim.instances.peekById(tracker.id);
            if (!si) {
                continue;
            }
            const localTransform = SceneInstances.getLocalTransformRelativeToParentMatrix(
                parent.worldMatrix,
                si.worldMatrix,
            )
            patches.push([tracker.id, { spatialParentId: parent.id, localTransform }]);
        }
        return patches;
    }

}

type SceneInstancePatchPerId = [id: IdBimScene, patch: SceneInstancePatch];
type SceneInstanceAllocation = [id: IdBimScene, allocate: Partial<SceneInstance>];

export async function resetSceneZCoord(
    bim: Bim,
    transformers: IdBimScene[],

    // dependencies
    tasksRunner: TasksRunner,
    uiBindings: UiBindings,
) {
    const roots = new Set<IdBimScene>();
    for (const id of transformers) {
        const root = bim.instances.spatialHierarchy.getTopmostParent(id);
        roots.add(root);
    }
    const allIds = bim.instances.spatialHierarchy
        .gatherIdsWithSubtreesOf({ ids: Array.from(roots) })
    await resetTerrain({
        bim,
        input: {
            boundaries: [],
            ids: allIds,
            resetByBoundaries: false,
            resetEquipment: true,
            resetTerrain: false,
            terrainIds: [],
        },
        tasksRunner,
        uiBindings,
    })
    await tasksRunner.newLongTask({
        defaultGenerator: bim.runUpdatesTillCompletion({ forceRun: false })
    }).asPromise();
}

export function cleanupSceneHierarchy(bim: Bim, hierarchies: FlatHierarchyDimensions[]) {
    const excludeTypes = new Set([
        SubstationTypeIdent,
        TransformerIdent,
        RoadTypeIdent,
        TerrainInstanceTypeIdent,
        BoundaryTypeIdent,
        ...TrackersTypes,
    ]);

    const patches: SceneInstancePatchPerId[] = [];
    const toRemove: IdBimScene[] = [];
    for (const hierarchy of hierarchies) {
        const transformer = bim.instances.peekById(hierarchy.id)!;
        const topmostParentId = bim.instances.spatialHierarchy.getTopmostParent(hierarchy.id);
        bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(
            hierarchy.id, 
            (childId) => {
                const typeIdent = bim.instances.peekTypeIdentOf(childId);
                if (!typeIdent) {
                    return true;
                }
                if (excludeTypes.has(typeIdent)) {
                    return true;
                }
                toRemove.push(childId);                
                return true;
            },
            true
        );

        const transformerWorldMatrix = transformer.worldMatrix;

        // move transformer to scene root
        const parentMatrix = topmostParentId !== hierarchy.id 
            ? bim.instances.peekById(topmostParentId)?.worldMatrix
            : undefined;
        
        patches.push([
            hierarchy.id,
            {
                spatialParentId: topmostParentId,
                localTransform: SceneInstances.getLocalTransformRelativeToParentMatrix(
                    parentMatrix,
                    transformerWorldMatrix,
                )
            }
        ]);

        // move trackers, reset position
        for (const trackerDimensions of hierarchy.trackers) {
            const tracker = bim.instances.peekById(trackerDimensions.id)!;
            patches.push([
                trackerDimensions.id,
                {
                    spatialParentId: hierarchy.id,
                    localTransform: SceneInstances.getLocalTransformRelativeToParentMatrix(
                        transformerWorldMatrix,
                        tracker.worldMatrix,
                    ),
                }
            ]);
        }
    }

    bim.instances.applyPatches(patches);
    bim.instances.delete(toRemove);
}

export interface EquipmentTemplates {
    combinerBox: SceneInstance
    inverter: SceneInstance
}

export function getChildEquipments(
    parents: IdBimScene[],
    bim: Bim,
    childTypeIdentifier: string[],
    returnSingleInstance = false,
    stopOnTypeIdentifier: string[],
) {
    const matchingChilds: SceneInstanceAndId[] = [];
    for (const parent of parents) {
        bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(
            parent,
            (id) => {
                const si = bim.instances.peekById(id);
                if (!si) {
                    return false;
                }
                if (stopOnTypeIdentifier.includes(si.type_identifier)) {
                    return false;
                }
                if (childTypeIdentifier.includes(si.type_identifier)) {
                    matchingChilds.push([id, si]);
                    if (returnSingleInstance) {
                        return false;
                    }
                    return false;
                }
                return true;
            },
            true,
        );
    }
    return matchingChilds;
}
