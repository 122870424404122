import type { PropertyGroup} from "bim-ts";
import { AssetsProperty, BooleanProperty, CatalogItemsReferenceProperty, MultiSelectorProperty, NumberProperty, NumberPropertyWithOptions, PrimitivePropertyBase, PropertyBase, SceneInstancesProperty, SelectorProperty, StringProperty } from "bim-ts";
import type { LazyVersioned, ScopedLogger} from "engine-utils-ts";
import { StringUtils } from "engine-utils-ts";
import type { PUI_Builder } from "ui-bindings";
import { patchObject } from "./GeneratePanelUiBindings";

export type PropertyPath = (string | number)[];

export function addProperty<T extends PropertyGroup>({ 
    logger, config, patch, builder, path, sortKey, name, readonly , enableSelectAll, tag, notActive,
} : { 
    logger: ScopedLogger; 
    config: T; 
    patch: (patch: T) => void; 
    builder: PUI_Builder; 
    path: PropertyPath; 
    sortKey: number; 
    name?: string; 
    readonly?: boolean; 
    enableSelectAll?: boolean;
    tag?: LazyVersioned<string>;
    notActive?: boolean;
}) {
    if (!config) {
        logger.batchedWarn("config is undefined", [path]);
        return;
    }
    let prop = getField(config, path);
    const displayName = name ? name : StringUtils.capitalizeFirstLatterInWord(path[path.length - 1].toString());
    let isReadonly:boolean = false;
    if(readonly !== undefined){
        isReadonly = readonly;
    }else if(prop instanceof PrimitivePropertyBase){
        isReadonly = prop.isReadonly;
    }
    // console.log(path);
    if (prop instanceof NumberPropertyWithOptions) {
        addNumberWithOptionProp(config, prop, builder, path, sortKey, patch, displayName, isReadonly, notActive);
    } else if (prop instanceof SceneInstancesProperty) {
        addSceneInstancesProp(config, prop, builder, path, sortKey, patch, displayName, isReadonly, notActive);
    } else if (prop instanceof NumberProperty) {
        addNumberProp(config, prop, builder, path, sortKey, patch, displayName, isReadonly, tag, notActive);
    } else if (prop instanceof BooleanProperty) {
        addBoolProp(config, prop, builder, path, sortKey, patch, displayName, isReadonly, notActive);
    } else if (prop instanceof SelectorProperty) {
        addSelectionProp(config, prop, builder, path, sortKey, patch, displayName, isReadonly, notActive);
    } else if (prop instanceof AssetsProperty) {
        addAssetsProp(config, prop, builder, path, sortKey, patch, displayName, isReadonly, notActive);
    } else if (prop instanceof CatalogItemsReferenceProperty) {
        addCatalogItemsProp(config, prop, builder, path, sortKey, patch, displayName, isReadonly, enableSelectAll, notActive);
    } else if (prop instanceof StringProperty) {
        addStringProp(config, prop, builder, path, sortKey, patch, displayName, isReadonly, notActive);
    } else if (prop instanceof MultiSelectorProperty) {
        addMultiSelectionProp(config, prop, builder, path, sortKey, patch, displayName, isReadonly, notActive);
    } else {
        logger.batchedWarn("invalid input", [prop, path, config]);
    }
}

function addNumberProp<T extends PropertyGroup>(
    config: T,
    prop: NumberProperty,
    builder: PUI_Builder,
    path: PropertyPath,
    sortKey: number,
    patch: (props: T) => void,
    name: string,
    readonly: boolean,
    tag: LazyVersioned<string> | undefined,
    notActive: boolean | undefined,
) {
    builder.addNumberProp({
        name: name,
        typeSortKeyOverride: sortKey,
        value: prop.value,
        hint: prop.description,
        readonly: readonly,
        description: prop.description,
        unit: prop.unit,
        minMax: prop.range ?? undefined,
        step: prop.step,
        tag: tag,
        notActive: notActive,

        onChange: (value) => {
            const newProp = prop.withDifferentValue(value);
            patchObject(config, path, newProp, patch);
        },
    });
}

function addBoolProp<T extends PropertyGroup>(
    config: T,
    prop: BooleanProperty,
    builder: PUI_Builder,
    path: PropertyPath,
    sortKey: number,
    patch: (props: T)=>void,
    name: string,
    readonly: boolean,
    notActive: boolean | undefined,
) {
    builder.addBoolProp({
        name: name,
        typeSortKeyOverride: sortKey,
        value: prop.value,
        hint: prop.description,
        readonly: readonly,
        description: prop.description,
        defaultValue: false,
        notActive: notActive,
        onChange: (value) => {
            const newProp = prop!.withDifferentValue(value);
            patchObject(config, path, newProp, patch);
        },
    });
}

function addStringProp<T extends PropertyGroup>(
    config: T,
    prop: StringProperty,
    builder: PUI_Builder,
    path: PropertyPath,
    sortKey: number,
    patch: (props: T)=>void,
    name: string,
    readonly: boolean,
    notActive: boolean | undefined,
) {
    builder.addStringProp({
        name: name,
        typeSortKeyOverride: sortKey,
        value: prop.value,
        hint: prop.description,
        readonly: readonly,
        description: prop.description,
        notActive: notActive,
        onChange: (value) => {
            const newProp = prop!.withDifferentValue(value);
            patchObject(config, path, newProp, patch);
        },
    });
}

function addSelectionProp<T extends PropertyGroup>(
    config: T,
    prop: SelectorProperty,
    builder: PUI_Builder,
    path: PropertyPath,
    sortKey: number,
    patch: (props: T)=>void,
    name: string,
    readonly: boolean,
    notActive: boolean | undefined,
) {
    builder.addSelectorProp({
        name: name,
        typeSortKeyOverride: sortKey,
        defaultValue: "",
        value: prop.value,
        options: prop.options,
        readonly: readonly,
        notActive: notActive,

        onChange: (value) => {
            const newProp = prop!.withDifferentValue(value);
            patchObject(config, path, newProp, patch);
        },
    });
}

function addMultiSelectionProp<T extends PropertyGroup>(
    config: T,
    prop: MultiSelectorProperty,
    builder: PUI_Builder,
    path: PropertyPath,
    sortKey: number,
    patch: (props: T)=>void,
    name: string,
    readonly: boolean,
    notActive: boolean | undefined,
) {
    builder.addMultiSelectorProp({
        name: name,
        typeSortKeyOverride: sortKey,
        defaultValue: [],
        value: prop.value.map(v => ({value: v})),
        options: prop.options.map(v => ({value: v})),
        readonly: readonly,
        notActive: notActive,
        maxSelect: prop.maxSelect,
        onChange: (value) => {
            const newProp = prop!.withDifferentValue(value.map(v => v.value.toString()));
            patchObject(config, path, newProp, patch);
        },
    });
}

function addAssetsProp<T extends PropertyGroup>(
    config: T,
    prop: AssetsProperty,
    builder: PUI_Builder,
    path: PropertyPath,
    sortKey: number,
    patch: (props: T)=>void,
    name: string,
    readonly: boolean,
    notActive: boolean | undefined,
) {
    builder.addCustomProp({
        name: name,
        typeSortKeyOverride: sortKey,
        value: prop.value,
        description: prop.description,
        type_ident: 'assets-selector',
        context: {
            maxCount: prop.maxCount,
            types: prop.types,
        },
        readonly: readonly,
        notActive: notActive,
        onChange: (value) => {
            const newProp = prop!.withDifferentValue(value);
            patchObject(config, path, newProp, patch);
        },
    });
}

function addCatalogItemsProp<T extends PropertyGroup>(
    config: T,
    prop: CatalogItemsReferenceProperty,
    builder: PUI_Builder,
    path: PropertyPath,
    sortKey: number,
    patch: (props: T) => void,
    name: string,
    readonly: boolean,
    enableSelectAll: boolean = false,
    notActive: boolean | undefined,
) {
    builder.addCustomProp({
        name: name ?? path[path.length - 1],
        typeSortKeyOverride: sortKey,
        value: prop,
        type_ident: 'catalog-items-selector',
        context: {
            enableSelectAll
        },
        readonly: readonly,
        notActive: notActive,
        onChange: (value) => {
            const newProp = value.withDifferentValue(value.value);
            patchObject(config, path, newProp, patch);
        },
    });
}

function addSceneInstancesProp<T extends PropertyGroup>(
    config: T,
    prop: SceneInstancesProperty,
    builder: PUI_Builder,
    path: PropertyPath,
    sortKey: number,
    patch: (props: T) => void,
    name: string,
    readonly: boolean,
    notActive: boolean | undefined,
) {
    builder.addSceneInstancesSelectorProp({
        name: name,
        typeSortKeyOverride: sortKey,
        defaultValue: [],
        value: prop.value.map(v=> ({value: v})),
        description: prop.description,
        maxSelect: prop.maxCount,
        types: prop.types,

        readonly: readonly,
        notActive: notActive,

        onChange: (value) => {
            const newProp = prop.withDifferentValue(value.map(v => v.value));
            patchObject(config, path, newProp, patch);
        },
    });
}

function addNumberWithOptionProp<T extends PropertyGroup>(
    config: T,
    prop: NumberPropertyWithOptions,
    builder: PUI_Builder,
    path: PropertyPath,
    sortKey: number,
    patch: (props: T)=>void,
    name: string,
    readonly: boolean,
    notActive: boolean | undefined,
) {
    builder.addCustomProp({
        name: name,
        typeSortKeyOverride: sortKey,
        value: prop,
        description: prop.description,
        type_ident: "number-property-with-options",
        context: undefined,
        readonly: readonly,
        notActive: notActive,
        onChange: (newProp) => {
            patchObject(config, path, newProp, patch);
        },
    });
}

function getField(config:PropertyGroup|undefined, path:PropertyPath):PropertyBase|undefined{
    if (config === undefined) {
        return;
    }
    let group = config as any;
    for (const f of path) {
        group = group[f];
    }
    if(group instanceof PropertyBase){
        return group;
    }else{
        console.error('field not found', path);
        return;
    }
}