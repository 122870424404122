import type { Bim, IdBimScene} from 'bim-ts';
import { SceneInstancesProperty, calculateRoadsBoundaryTrim } from 'bim-ts';
import type { LazyVersioned, UndoStack
, Result
} from 'engine-utils-ts';
import {
    DefaultMap, LazyBasic,  ObservableObject, Success
} from 'engine-utils-ts';
import type { MenuPath, PUI_SceneInstancesSelectorPropertyNodeArgs, SceneInstancesSelectorValue } from 'ui-bindings';
import { EditActionResult, PUI_ConfigBasedBuilderParams, PUI_ConfigPropertyTransformer, PUI_SceneInstancesSelectorPropertyNode } from 'ui-bindings';
import type { EngineScene } from '../scene/EngineScene';
import { BimObjectSceneIntersection } from '../scene/Raycasts';
import type { SceneInt } from '../scene/SceneRaycaster';
import type { EditInteractionResult, InteractiveEditOperator } from './InteractiveEditOperator';
import type { MouseEventData } from './MouseGesturesBase';


interface RoadsBoundariesTrimState {
    initialRoadsIds: IdBimScene[];
    boundariesIds: IdBimScene[];
    createdRoadsIds: DefaultMap<IdBimScene, IdBimScene[]>;
}

class RoadsBoundariesTrimSettings {
    Roads = new SceneInstancesProperty({
        value: [],
        types: ["road"],
    });
    Boundaries = new SceneInstancesProperty({
        value: [],
        types: ["boundary"],
    });
}

export class InteractiveRoadsBoundariesTrim implements InteractiveEditOperator<RoadsBoundariesTrimState, RoadsBoundariesTrimSettings> {

    menuPath: MenuPath = ['Edit', 'Trim roads by boundaries'];
    canStart: LazyVersioned<boolean>;
    readonly config: ObservableObject<RoadsBoundariesTrimSettings>;

    constructor(
        readonly bim: Bim,
        readonly engineScene: EngineScene,
        undoStack: UndoStack,
    ) {
        this.canStart = new LazyBasic('', true);

        this.config = new ObservableObject({
            identifier: this.menuPath.join(),
            initialState: new RoadsBoundariesTrimSettings(),
            undoStack,
            throttling: {onlyFields: []}
        });

    }

    sceneInstancesPropertyTransformer(): PUI_ConfigPropertyTransformer<
        SceneInstancesProperty,
        SceneInstancesSelectorValue[],
        PUI_SceneInstancesSelectorPropertyNode,
        PUI_SceneInstancesSelectorPropertyNodeArgs
    > {
        return new PUI_ConfigPropertyTransformer(
            (p) => p.value.map(v=>({value: v})),
            (newValue, property)=>{
                const newProp = property.withDifferentValue(newValue.map(v => v.value));
                return new Success(newProp);
            },
            (prop) => [
                PUI_SceneInstancesSelectorPropertyNode,
                {
                    hint: prop.description || undefined,
                    readonly: prop.isReadonly,
                    types: prop.types,
                    maxSelect: prop.maxCount ?? undefined,
                }
            ],
        )
    }

    configBuilderSettings(): PUI_ConfigBasedBuilderParams {
        return PUI_ConfigBasedBuilderParams.new([
            [
                "Roads",
                this.sceneInstancesPropertyTransformer()
            ],
            [
                "Boundaries",
                this.sceneInstancesPropertyTransformer()
            ],
        ]);
    }

    start(): Result<RoadsBoundariesTrimState> {
        const initialState: RoadsBoundariesTrimState = {
            initialRoadsIds: [],
            boundariesIds: [],
            createdRoadsIds: new DefaultMap<IdBimScene, IdBimScene[]>(() => []),
        };
        return new Success(initialState);
    }
    handleConfigPatch(patch: Readonly<Partial<RoadsBoundariesTrimSettings>>, state: Readonly<RoadsBoundariesTrimState>):
        EditInteractionResult<RoadsBoundariesTrimState> {

        return {done: false, state: state};
    }
    finish(state: RoadsBoundariesTrimState): EditActionResult | undefined {
        const boundariesIds = this.config.currentValue().Boundaries.value;
        const roadsIds = this.config.currentValue().Roads.value;
        const trimmedRoadsIds = calculateRoadsBoundaryTrim(roadsIds, boundariesIds, this.bim);
        return new EditActionResult(trimmedRoadsIds);
    }
    cancel(state: RoadsBoundariesTrimState): void {

    }

    handleClick(sceneInt: SceneInt, me: MouseEventData, previousResult: RoadsBoundariesTrimState)
        : EditInteractionResult<RoadsBoundariesTrimState> {

        if (sceneInt instanceof BimObjectSceneIntersection) {
            for (const [id, handle] of this.engineScene.esos.idsToHandles) {
                if (handle === sceneInt.esoHandle) {
                    this.engineScene.bim.instances.setSelected([id]);
                    break;
                }
            }
        }
        return {done: false, state: previousResult};
    }
}