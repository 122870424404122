<script lang="ts">
    import PUI_Root from "../../pui/PUI_Root.svelte";
    import { DefaultMap, IterUtils } from "engine-utils-ts";
    import PileBin from "./PileBin.svelte";
    import { Button, ButtonComponent, ButtonType } from "../../libui/button";
    import { getContext } from "svelte";
    import { VersionedStore } from "../../VersionedStore";
    import {
        Bim,
        TrackerPilesCollection,
        type IdPile,
        type PileProfileType,
        IdBimScene,
        BinsBuilder,
    } from "bim-ts";
    import { getGridOptions } from "./TableConfig";
    import { getBinsPui } from "./BinUtils";
    import {
        NotificationDescription,
        NotificationType,
        UiBindings,
    } from "ui-bindings";
    import PanelWarning from "../../ui-panels/PanelWarning.svelte";
    import { notificationSource } from "../../Notifications";

    const bim = getContext<Bim>("bim");
    const uiBindings = getContext<UiBindings>("uiBindings");

    const pilesCollection = getContext<TrackerPilesCollection>(
        "trackerPilesCollection",
    );

    const builder = new BinsBuilder(bim, pilesCollection);

    const mappedBins = new VersionedStore(builder.selectedBins);

    const binTableOptions = getGridOptions(
        bim,
        builder,
        pilesCollection,
        false,
    );
    const outliersTableOptions = getGridOptions(
        bim,
        builder,
        pilesCollection,
        true,
    );

    const puiRoot = getBinsPui(builder, uiBindings);

    $: total = IterUtils.sum($mappedBins, (binData) => binData.total);


    $: showApplyToSceneButton = $mappedBins.some(bin => {
        if (bin.maxReveal.value === Infinity) {
            return false;
        }
        return bin.pilesWithNonComformingProfile.length > 0 || bin.pilesWithNonConformingLength.length > 0;
    });

    function applyDesiredProfilesAndLengthsToScene() {
        const perLengthIdsToPatch = new DefaultMap<number, IdPile[]>(() => []);
        const perProfileIdsToPatch = new DefaultMap<PileProfileType, IdPile[]>(() => []);
        for (const [_, bins] of builder.binsPerTracker) {
            for (const bin of bins) {
                if (bin.maxReveal.value === Infinity) {
                    continue;
                }

                const nonComformingLengths = new Set<IdPile>(bin.pilesWithNonConformingLength);
                for (const [_, it] of bin.items) {
                    for (const id of it.pileIds) {
                        if (nonComformingLengths.has(id)) {
                            perLengthIdsToPatch.getOrCreate(it.length).push(id);
                        }
                    }
                }
                    
                const nonComforming = new Set<IdPile>(bin.pilesWithNonComformingProfile);
                for (const [_, it] of bin.items) {
                    for (const id of it.pileIds) {
                        if (nonComforming.has(id)) {
                            perProfileIdsToPatch.getOrCreate(it.profile).push(id);
                        }
                    }
                }
            }
        }
        const couldNotApplyPatchTo = new Set<IdBimScene>();
        for (const [length, ids] of perLengthIdsToPatch) {
            const res = pilesCollection.applyLengthPatchTo(ids, length);
            IterUtils.extendSet(couldNotApplyPatchTo, res.unsupportedTrackerTypeIds);
        }
        for (const [profile, ids] of perProfileIdsToPatch) {
            const res = pilesCollection.applyProfilePatchTo(ids, profile);
            IterUtils.extendSet(couldNotApplyPatchTo, res.unsupportedTrackerTypeIds);
        }

        if (couldNotApplyPatchTo.size > 0) {
            uiBindings.addNotification(NotificationDescription.newBasic({
                source: notificationSource,
                key: "pileAdjustmentFailure",
                type: NotificationType.Error,
                addToNotificationsLog: true,
                removeAfterMs: 10000,
            }));

            const anyOldTrackersErroredOut = IterUtils.any(couldNotApplyPatchTo, id => bim.instances.peekTypeIdentOf(id) === "tracker");
            if (anyOldTrackersErroredOut) {
                uiBindings.addNotification(NotificationDescription.newWithActionToStart({
                    source: notificationSource,
                    key: "convertToAnyTrackersSuggestion",
                    type: NotificationType.Info,
                    addToNotificationsLog: true,
                    removeAfterMs: 10000,
                    actionDescription: {
                        name: "Convert trackers to any trackers",
                        actionArgs: [],
                        action: () => {
                            bim.convertToAnyTrackers();
                        },
                    },
                }));
            }
            
        }
    }

</script>

{#if showApplyToSceneButton}
    <PanelWarning>
        <div>
            <span>
                The length or profile of the piles in the project do not match the bins configuration, click
            </span>
            <ButtonComponent
                compact={true}
                desc={new Button(
                    "Update piles",
                    ButtonType.Text,
                    applyDesiredProfilesAndLengthsToScene,
                    false,
                    "Select",
                )}
            />
            <span>to update piles in the scene.</span>
        </div>
    </PanelWarning>
{/if}
<div>
    <PUI_Root configIdentifier="pile-reveal-bins" puiSource={puiRoot}
    ></PUI_Root>
    {#each $mappedBins as binTable, index (index)}
        {@const minReveal = $mappedBins[index - 1]?.maxReveal}
        {#if index !== $mappedBins.length - 1}
            <PileBin
                name={`Piles bin ${index + 1}`}
                min={minReveal}
                {total}
                {binTable}
                binsBuilder={builder}
                gridOptions={binTableOptions}
            />
        {:else}
            {#if $mappedBins.length === 1}
                <div class="no-bins">
                    <div class="mdc-typography--headline5">Pile bins</div>
                    <div>No pile bins by pile reveal length configured</div>
                </div>
            {/if}
            <ButtonComponent
                desc={new Button(
                    "Add piles bin",
                    ButtonType.Text,
                    () => builder.addBin(),
                    false,
                    "AddPlus",
                )}
            />
            <PileBin
                name={"Free length piles"}
                min={minReveal}
                {total}
                {binTable}
                binsBuilder={builder}
                gridOptions={outliersTableOptions}
            />
        {/if}
    {/each}
</div>

<style lang="scss">
    .no-bins {
        padding: 0 16px;
        div:first-child {
            margin-bottom: 4px;
        }
    }
</style>
