<script lang="ts">
import { type Catalog, type PropertyGroup, type AssetCatalogItemProps, BimProperty} from "bim-ts";
import { PropertyBase, StringProperty, NumberProperty, BooleanProperty, pilesTypes } from "bim-ts";
import { VerDataSyncStatus } from "verdata-ts";
import PUI_Root from '../pui/PUI_Root.svelte';
import { getContext } from "svelte";
import { ButtonComponent, Button, ButtonType, IconButton } from "../libui/button";
import type { CatalogItemManager, CatalogItemView } from "./CatalogItemManager";
import { VersionedStore } from "../VersionedStore";
import { modulePerWattCost, transformerPerWattCost } from "./Plugins";
import { PUI_Builder, PUI_Lazy } from "ui-bindings";
import { LazyBasic, LazyDerived, VersionedInvalidator } from "engine-utils-ts";

export let item: CatalogItemView;
let catalog = getContext<Catalog>('catalog');

let versionedItem = new LazyBasic<CatalogItemView>('versioned-item', item);
$: versionedItem.replaceWith(item);
let catalogItemPatch: PropertyGroup = {};
let newAssetName: string | undefined = undefined;
$: edited = Object.keys(catalogItemPatch).length !== 0 || typeof newAssetName === 'string';
let version = new VersionedInvalidator([catalog.invalidator]);
let puiRoot = new PUI_Lazy(LazyDerived.new1(
  'catalog-item-edit',
  [version],
  [versionedItem],
  ([item]) => {
    const { catalogItem, catalogItemUiInfo, id } = item;
    const builder = new PUI_Builder({});
    // name
    // expect single nested level structure for now
    for (const [key, prop] of Object.entries(catalogItem.properties)) {
        const sortKey = key[0].toUpperCase() === key[0] ? 2 : 1;
        const propFromPatch = catalogItemPatch[key] as (PropertyBase | undefined);
        if (!(prop instanceof PropertyBase)) {
          continue;
        }
        if (key.startsWith('price_')) {
          continue;
        }
        if (prop instanceof StringProperty) {
          if (key === 'pile_type_id') {
            builder.addStringProp({
              name: 'pile_type',
              // @ts-ignore
              defaultValue: pilesTypes[prop.value]?.decription ?? '',
              readonly: true,
              onChange: () => {},
              typeSortKeyOverride: sortKey,
            });
          } else {
            builder.addStringProp({
              name: key,
              // @ts-ignore
              value: propFromPatch?.value,
              defaultValue: prop.value,
              readonly: prop.isReadonly,
              onChange: (newVal) => {
                catalogItemPatch = {
                  ...catalogItemPatch,
                  [key]: prop.withDifferentValue(newVal),
                };
              },
              typeSortKeyOverride: sortKey,
            });
          }
        } else if (prop instanceof NumberProperty) {
           if (key === 'asset_id') {
             builder.addStringProp({
               name: 'asset',
               readonly: false,
               defaultValue: catalogItemUiInfo.title,
               onChange: (newVal) => newAssetName = newVal,
               typeSortKeyOverride: sortKey,
             });
           } else if (modulePerWattCost(
            builder,
            key,
            (catalogItemPatch[key] as NumberProperty) ?? prop,
            (newVal) => {
              catalogItemPatch = {
                ...catalogItemPatch,
                [key]: prop.withDifferentValue(newVal),
              };
              version.invalidate();
            },
            catalogItem,
            catalog,
          )) {} else if (transformerPerWattCost(
            builder,
            key,
            (catalogItemPatch[key] as NumberProperty) ?? prop,
            (newVal) => {
              catalogItemPatch = {
                ...catalogItemPatch,
                [key]: prop.withDifferentValue(newVal),
              };
              version.invalidate();
            },
            catalogItem,
            catalog,
          )) {} else {
            builder.addNumberProp({
              name: key,
              // @ts-ignore
              value: propFromPatch?.value,
              unit: prop.unit !== '' ? prop.unit : undefined,
              readonly: prop.isReadonly,
              defaultValue: prop.value,
              onChange: (newVal) => {
                catalogItemPatch = {
                  ...catalogItemPatch,
                  [key]: prop.withDifferentValue(newVal),
                };
              },
              typeSortKeyOverride: sortKey,
            });
          }
        } else if (prop instanceof BooleanProperty) {
            builder.addBoolProp({
              name: key,
              // @ts-ignore
              value: propFromPatch?.value,
              readonly: prop.isReadonly,
              defaultValue: prop.value,
              onChange: (newVal) => {
                catalogItemPatch = {
                  ...catalogItemPatch,
                  [key]: prop.withDifferentValue(newVal),
                };
              },
              typeSortKeyOverride: sortKey,
            });
        }

    }
    if (item.catalogItem.typeIdentifier === 'asset') do {
      const catalogItem = item.catalogItem.as<AssetCatalogItemProps>();
      const assetId = catalogItem.properties.asset_id.value;
      const si = catalog.assets.sceneInstancePerAsset.getAssetAsSceneInstance(assetId);
      if (!si) break;
      const uniqueProps = catalog.keyPropertiesGroupFormatters.getPropertiesGroup(
        si.type_identifier, si.properties, si.props,
      );
      if (!uniqueProps) break;
      builder.inGroup({ name: 'unique properties' }, () => {
          for (const prop of Object.values(uniqueProps)) {
            if (typeof prop.value === 'number') {
              builder.addNumberProp({
                name: BimProperty.MergedPath(prop.path),
                onChange: () => {},
                unit: prop.unit ?? '',
                value: prop.value,
                readonly: true,
                typeSortKeyOverride: 3,
              })
            } else {
              builder.addStringProp({
                  name: BimProperty.MergedPath(prop.path),
                  onChange: () => {},
                  defaultValue: prop.value,
                  readonly: true,
                  typeSortKeyOverride: 3,
              })
            }
          }
      })
    } while (0);

    return builder.finish();
  }
).withoutEqCheck())

let catalogItemManager = getContext<VersionedStore<CatalogItemManager>>('catalogItem-manager');

async function handleSave() {
    if (Object.keys(catalogItemPatch).length) {
        catalog.catalogItems.applyPatches([
            [item.id, { properties: {
              ...item.catalogItem.properties,
              ...catalogItemPatch,
            } }],
        ])
    }
    if (typeof newAssetName === 'string' && item.catalogItem.typeIdentifier === 'asset') {
        const assetId = item.catalogItem.as<AssetCatalogItemProps>().properties.asset_id.value;
        catalog.assets.applyPatches([
            [assetId, { name: newAssetName } ],
        ]);
    }
    $catalogItemManager.cancelEditMode();
}
function handleCancel() {
    catalogItemPatch = {};
    newAssetName = undefined;
}
let syncerStatus = new VersionedStore(catalog.getSyncer().status).skipDispose();
</script>


<div
    style="
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        margin-right: 15px;
        margin-bottom: 10px;
    "
>
    <ButtonComponent desc={new IconButton("Close", () => {
      $catalogItemManager.cancelEditMode();
    })} />
</div>

{#if edited}
    <div id="actions">
      <ButtonComponent desc={new Button(
        'save',
        ButtonType.Contained,
        handleSave,
        $syncerStatus.syncStatus !== VerDataSyncStatus.Loaded
      )}/>
      <ButtonComponent desc={new Button('cancel', ButtonType.Text, handleCancel)}/>
    </div>
{/if}
<PUI_Root configIdentifier='edit catalogItem' puiSource={puiRoot} />

<style lang="scss">
#actions {
  display: flex;
  justify-content: center;
}
</style>
