import { browserCompatibilityTest } from './FeatureDetect';
import { WasmVersion } from './Version';
import type { InitOutput } from '../../engine_wasm/dist/engine_wasm';
import init, { engine_version } from '../../engine_wasm/dist/engine_wasm';

const MainWasmBinary = 'engine_wasm_bg';

function GetWasmBinaryName(): string {
	return MainWasmBinary;
}

// declare var engine_bindgen_loader: any;

export let wasm: InitOutput | null = null;

export async function LoadEngineWasm(path: string): Promise<InitOutput> {

	if (wasm !== null) {
		return wasm;
	}

	await browserCompatibilityTest();

	const wasm_url = path;

	// if (typeof engine_bindgen_loader === 'undefined') {
	// 	throw new Error("Make sure engine_wasm.js is loaded (include in index.html) before constructing KreoEngine");
	// }
	wasm = await init(wasm_url, undefined as any);

	// wasm = engine_bindgen_loader;

	const wasmVersion = engine_version();
	const expectedWasmVersion = WasmVersion;
	if (wasmVersion !== expectedWasmVersion) {
		const errorMsg = `engine expected wasm ${expectedWasmVersion} and got ${wasmVersion}`;
		// LegacyLogger.error(errorMsg);
		throw new Error(errorMsg);
	}
	return wasm;
}



