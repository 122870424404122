import type { Result} from "engine-utils-ts";
import { Failure, Success } from "engine-utils-ts";
import type { Bim, DC_CNSTS } from "src";
import type { IdBimScene, SceneInstance } from "src/scene/SceneInstances";
import { ConnectionDescription } from "../types";

export function getBlockPattern(transformerId: IdBimScene, bim: Bim)
    : Result<DC_CNSTS.PatternName>
{
    const transformer = bim.instances.peekById(transformerId);
    if (!transformer) {
        return new Failure({ msg: "not found" });
    }

    const pattern = transformer.properties.get("circuit | pattern | type")?.asText() ?? undefined
    if (!pattern) {
        return new Failure({ msg: 'pattern not found' });
    }

    return new Success(pattern as DC_CNSTS.PatternName);
}

export function extractConnectionDescription(id: IdBimScene, si: SceneInstance) {
    const text = si.properties.get('specification | assetName')?.asText() ?? 'unknown';
    return new ConnectionDescription({
        sceneId: id,
        text,
    })
}

