import type { Bim} from "bim-ts";
import { BimGeometryType, SceneInstances, TerrainHeightMapRepresentation, TerrainInstanceTypeIdent } from "bim-ts";
import type { ScopedLogger} from "engine-utils-ts";
import { Result, Success, Yield } from "engine-utils-ts";
import type { ExportedFileDescription } from "ui-bindings";
import { entityTypeFromId } from "verdata-ts";
import type { CommonExportSettings} from "../CommonExportSettings";
import { ConvertTerrainToDem } from "./DemCreator";


export function* convertDem(bim: Bim, logger: ScopedLogger,  settings: CommonExportSettings, moduleSize:number, baseProjectName?:string): Generator<Yield, Result<ExportedFileDescription>[]> {

    const selectedIds = settings.export_only_selected ? bim.instances.getSelected() : bim.instances.readAll().map((i) => i[0]);

    const terrainInstances = bim.instances.peekByTypeIdent(TerrainInstanceTypeIdent).filter(x => selectedIds.includes(x[0]));

    const result: Result<ExportedFileDescription>[] = [];

    for (const [id, terrainInstance] of terrainInstances) {
        yield Yield.Asap;
        if (!(terrainInstance.representation instanceof TerrainHeightMapRepresentation)) {
            continue;
        }

        const instanceName = SceneInstances.uiNameFor(id, terrainInstance);
        const exportFileName = baseProjectName ? `${baseProjectName}_${instanceName}` : instanceName; 
 
        const isRegular = isSurfaceRegular(bim, terrainInstance.representation);

        if (isRegular === TerrainType.Unknown || isRegular === TerrainType.Irregular) {
            console.error(`The surface ${id} has irregular tiles!`);
            continue;
        }

        const demTxt =yield* ConvertTerrainToDem(bim, logger, terrainInstance, moduleSize)
        const encodedDem = new TextEncoder().encode(demTxt);
        result.push(new Success({extension: 'dem', file: encodedDem, name: exportFileName}));
    }
    return result;
}

function isSurfaceRegular(bim: Bim, terrain: TerrainHeightMapRepresentation):TerrainType {

    let terrainType = TerrainType.Unknown;

    for (const [terrainTileId, terrainTile] of terrain.tiles) {
        const tileGeoId = terrainTile.updatedGeo === 0 ? terrainTile.initialGeo : terrainTile.updatedGeo;

        const tileGeo = entityTypeFromId<BimGeometryType>(tileGeoId);

        if (tileGeo === BimGeometryType.HeightmapRegular) {
            if(terrainType === TerrainType.Irregular){
                return TerrainType.Unknown;
            }
            terrainType = TerrainType.Regular;
        }

        if (tileGeo === BimGeometryType.HeightmapIrregular) {
            if (terrainType === TerrainType.Regular) {
                return TerrainType.Unknown;
            }
            terrainType = TerrainType.Irregular;
        }
    }

    return terrainType;
}

enum TerrainType {Regular, Irregular, Unknown}

