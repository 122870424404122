<script lang="ts">
    import type { ColDef } from 'ag-grid-enterprise';
    import type { Bim, ProjectMetrics, TrackerPilesCollection, CostModel} from 'bim-ts';
    import { getContext, onDestroy } from "svelte";
    import { VersionedStore } from '../../VersionedStore';
    import { createQuantityItemsLazy } from "../QuantityItemsLazy";
    import AgGrid from "./AgGridComponent.svelte";
    import type { KreoEngine } from "engine-ts";
    import EngineUiPanel from "../../ui-panels/EngineUiPanel.svelte";
    import { ButtonComponent, IconButton } from 'src/libui/button';
    import { Action, ContextMenu, ContextMenuStructure } from 'src/libui/context-menu';

    const bim: Bim = getContext("bim");
    const layoutMetrics = getContext<ProjectMetrics>("layoutMetricsLazy");
    const engine = getContext<KreoEngine>("engine");
    const contextMenu = getContext<ContextMenu>('context-menu');
    const costsProvider = getContext<CostModel.CostsConfigProvider>('costsProvider');
    const quantityItemsLazy = createQuantityItemsLazy(
            "quantityItemsLazy",
            bim,
            engine.tasksRunner,
            layoutMetrics,
            getContext<TrackerPilesCollection>("trackerPilesCollection"),
            costsProvider,
    );
    const instancesCollection = new VersionedStore(quantityItemsLazy.items);

    const columnDefs: ColDef[] = [];

    onDestroy(() => {
        quantityItemsLazy.dispose();
    });

    let agGridRef: AgGrid
    const menu = new ContextMenuStructure([
        new Action('Excel Export', async () => {
            agGridRef.exportExcel();
        }),
    ])

</script>

<EngineUiPanel>
    <div slot="buttons">
        <ButtonComponent
            desc={
                new IconButton(
                    "Export",
                    (e) => contextMenu.openMenuAtPosition(e.clientX, e.clientY, menu),
                )
            }
        />
    </div>
    <AgGrid
        bind:this={agGridRef}
        {columnDefs}
        bimItems={instancesCollection}
    />
</EngineUiPanel>

<style>
    :global(:root) {
        --grid-height: 100%;
    }
</style>
