import type { ScopedLogger } from 'engine-utils-ts';
import { IterUtils } from 'engine-utils-ts';

import { SceneInstance } from './SceneInstances';
import { PropertiesCollection } from 'src/bimDescriptions/PropertiesCollection';
import type { PropsGroupBase } from 'src/properties/Props';
import type { RepresentationBase } from 'src/representation/Representations';
import type { UnitsMapper } from 'src/UnitsMapper';
import type { BimPropertyData } from 'src/bimDescriptions/BimProperty';
import { BimProperty } from 'src/bimDescriptions/BimProperty';
import { PropsGroupsRegistry } from 'src/properties/PropsGroupsRegistry';

export interface SceneInstancePropsShapeType {
    type_identifier: string;
    properties: PropertiesCollection;
    props: PropsGroupBase;
    representation?: RepresentationBase | null;
}

export const enum DefaultSceneInstanceShapeVersion {
	None = 0,
}

export interface SceneInstanceShapeMigration {
    toVersion: number;
    patch: (inst: SceneInstancePropsShapeType, unitMapper: UnitsMapper) => void;
    validation: SceneInstanceValidation;
}

type BimPropertyValidation = Pick<BimPropertyData, "path">;

interface SceneInstanceValidation {
    updatedProps: BimPropertyValidation[];
    deletedProps: BimPropertyValidation[];
}

export interface SceneInstanceArchetype {
    type_identifier: string;
    mandatoryProps: BimPropertyData[];
    optionalProps?: BimPropertyData[];
    propsShapeMigrations?: SceneInstanceShapeMigration[],
    propsClass?: { new(args: any): PropsGroupBase };
}

class SceneInstanceArchetypeImpl {

    mandatoryProps = new Map<string, BimProperty>();
    optionalProps = new Map<string, BimProperty>();

    migrator: LegacyPropsMigrator;

    constructor(logger: ScopedLogger, unitsMapper: UnitsMapper, a: SceneInstanceArchetype) {
        const pTuple = (p: BimPropertyData): [string, BimProperty] => {
            const bp = BimProperty.NewShared(p);
            return [bp._mergedPath, bp];
        }
        this.mandatoryProps = IterUtils.newMapFromTuples(a.mandatoryProps.map(pTuple));
        this.optionalProps = IterUtils.newMapFromTuples((a.optionalProps ?? []).map(pTuple));

        this.migrator = new LegacyPropsMigrator(
            logger.newScope(a.type_identifier),
            unitsMapper,
            a.propsShapeMigrations ?? [],
        )
    }

    getVersion(): number {
        return this.migrator.lastMigrationVersion;
    }
}

export class SceneInstancesArchetypes {

    readonly logger: ScopedLogger;
    readonly perTypeIdent = new Map<string, SceneInstanceArchetypeImpl>();
    readonly propsClassPerTypeIdent = new Map<string, { new(args: any): PropsGroupBase }>();

    constructor(
        logger: ScopedLogger,
        private readonly unitMapper: UnitsMapper,
    ) {
        this.logger = logger.newScope('archetypes');

    }

    getPropsClassFor(type_ident: string): { new(args: any): any } | undefined {
        return this.propsClassPerTypeIdent.get(type_ident);
    }

    registerArchetype(arch: SceneInstanceArchetype) {
        if (this.perTypeIdent.has(arch.type_identifier)) {
            this.logger.error(`${arch.type_identifier} archetype already registered, overriding`);
        }

        if(arch.propsShapeMigrations){
            const versions =  new Set<number>();
            for (const migration of arch.propsShapeMigrations) {
                versions.add(migration.toVersion);
            }
            if(versions.size !== arch.propsShapeMigrations.length){
                throw new Error(`${arch.type_identifier} archetype has duplicate migrations`);
            }
        }
        this.perTypeIdent.set(
            arch.type_identifier,
            new SceneInstanceArchetypeImpl(this.logger, this.unitMapper, arch)
        );
        if (arch.propsClass) {
            this.propsClassPerTypeIdent.set(arch.type_identifier, arch.propsClass);
        }
    }

    newDefaultInstanceForArchetype(type_identifier: string): SceneInstance {

        const sceneInstance = new SceneInstance(type_identifier);
        const archetype = this.perTypeIdent.get(type_identifier);
        if (!archetype) {
            this.logger.error('unrecognized type identifier', type_identifier);
        } else {
            sceneInstance.properties = new PropertiesCollection(Array.from(archetype.mandatoryProps.values()));
        }
        const newPropsClassExpected = this.getPropsClassFor(type_identifier);
        if (newPropsClassExpected) {
            sceneInstance.props = new newPropsClassExpected({});
        }


        return sceneInstance;
    }

    getVersionPerTypeIdentifier():[string, number][] {
        const versionPerTypeIdentifier:[string, number][] = [];
        for (const [ident, arch] of this.perTypeIdent) {
            const version = arch.getVersion();
            if (version !== DefaultSceneInstanceShapeVersion.None) {
                versionPerTypeIdentifier.push([ident, version]);
            }
        }

        return Array.from(versionPerTypeIdentifier);
    }

    migrate(inst: SceneInstancePropsShapeType, version: number) : void {
        const archetype = this.perTypeIdent.get(inst.type_identifier);
        if(!archetype){
            return;
        }
        archetype.migrator.migrate(inst, version, this.getPropsClassFor(inst.type_identifier));
    }
}


class LegacyPropsMigrator {

    _propsExpectedToBeRemoved: string[];
    _propsExpectedToBePresent: string[];

    readonly lastMigrationVersion: number;

    constructor(
        readonly logger: ScopedLogger,
        readonly unitsMapper: UnitsMapper,
        readonly migrations: SceneInstanceShapeMigration[],
    ) {
        const propertiesShouldBe = new Set<string>();
        const propertiesShouldNotBe = new Set<string>();

        for (const migration of migrations) {
            const currentMigrationProps = new Set<string>();
            for (const prop of migration.validation.updatedProps) {
                const mergedPath = BimProperty.MergedPath(prop.path);
                currentMigrationProps.add(mergedPath);
                propertiesShouldBe.add(mergedPath,);
                propertiesShouldNotBe.delete(mergedPath);
            }
            for (const prop of migration.validation.deletedProps) {
                const mergedPath = BimProperty.MergedPath(prop.path);
                if(currentMigrationProps.has(mergedPath)){
                    console.error('There are duplicate paths in migration', migration);
                }
                propertiesShouldNotBe.add(mergedPath);
                propertiesShouldBe.delete(mergedPath);
            }
        }

        this._propsExpectedToBePresent = Array.from(propertiesShouldBe);
        this._propsExpectedToBeRemoved = Array.from(propertiesShouldNotBe);
        this.lastMigrationVersion = IterUtils.maxBy(this.migrations, (m) => m.toVersion)?.toVersion ?? 0;
    }

    migrate(inst: SceneInstancePropsShapeType, version: number, expectedPropsClass?: {new(args: any): PropsGroupBase}) : void {

        if (version < this.lastMigrationVersion) {
            for (const migration of this.migrations) {
                if (version < migration.toVersion) {
                    migration.patch(inst, this.unitsMapper);
                }
            }
        }

        for (const mergedPath of this._propsExpectedToBePresent) {
            const prop = inst.properties.get(mergedPath);
            if(!prop){
                this.logger.batchedError("Property not found with path:" +  mergedPath, version);
            }
        }
        for (const mergedPath of this._propsExpectedToBeRemoved) {
            const prop = inst.properties.get(mergedPath);
            if(prop){
                this.logger.batchedError("Property should been removed with path: " + mergedPath, version);
            }
        }

        if (expectedPropsClass) {
            if (!(inst.props instanceof expectedPropsClass)) {
                this.logger.batchedError("Props class mismatch", [inst.props, expectedPropsClass.name]);
                inst.props = PropsGroupsRegistry.newGroupInstanceChecked(expectedPropsClass, inst.props ?? {});
            }
        }
    }
}
