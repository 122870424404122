import type { NotificationDescription } from './NotificationDescription';

let processId = 1;

export class GroupedNotificationGenerator {
	readonly processId: number;
    readonly groupName: string;

    constructor(groupName: string) {
        this.groupName = groupName;
        this.processId = processId++;
    }

    addNotification(notification: NotificationDescription): NotificationDescription {
        notification.group = {
            processId: this.processId,
            groupName: this.groupName,
            isRootInGroup: false
        }
        return notification;
    }

    addRootNotification(notification: NotificationDescription): NotificationDescription {
        notification.group = {
            processId: this.processId,
            groupName: this.groupName,
            isRootInGroup: true
        }
        return notification;
    }
}

