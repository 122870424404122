import type { Aabb2 } from "./Aabb2";

export type TypedArray = Int8Array | Uint8Array | Uint8ClampedArray
    | Int16Array | Uint16Array | Int32Array | Uint32Array | Float32Array | Float64Array;


export function combineHashCodes(h1: number, h2: number) {
    h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
    h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1>>>0);
}

export const DEG2RAD = Math.PI / 180;
export const RAD2DEG = 180 / Math.PI;
export const MAXINT16 = 32767;

export class KrMath {

	static clamp( value:number, min:number, max:number ) {
		return Math.max( min, Math.min( max, value ) );
	}
	
	static roundTo(number:number, roundTo: number) {
		if (roundTo < 1) {
			if (roundTo <= 0) {
				return number;
			}
			const intPart = Math.floor(number);
			const fractPart = number - intPart;
			return Math.round(fractPart / roundTo) * roundTo + intPart;
		} else {
			return Math.round(number / roundTo) * roundTo;
		}
	}
	static floorTo(number: number, roundTo: number) {
		if (roundTo < 1) {
			const intPart = Math.floor(number);
			const fractPart = number - intPart;
			return Math.floor(fractPart / roundTo) * roundTo + intPart;
		} else {
			return Math.floor(number / roundTo) * roundTo;
		}
	}
	static ceilTo(number: number, roundTo: number) {
		if (roundTo < 1) {
			const intPart = Math.floor(number);
			const fractPart = number - intPart;
			return Math.ceil(fractPart / roundTo) * roundTo + intPart;
		} else {
			return Math.ceil(number / roundTo) * roundTo;
		}
	}

	static roundToPowerOf(number: number, roundToPowerOf: number) {
		const log =  Math.round(Math.log(number) / Math.log(roundToPowerOf));
		return Math.pow(roundToPowerOf, log);
	}
	static floorToPowerOf(number: number, floorToPowerOf: number): number {
		if (number < 0) {
			return -KrMath.ceilToPowerOf(Math.abs(number), floorToPowerOf);
		}
		const log =  Math.floor(Math.log(number) / Math.log(floorToPowerOf));
		return Math.pow(floorToPowerOf, log);
	}
	static ceilToPowerOf(number: number, ceilToPowerOf: number): number {
		if (number < 0) {
			return -KrMath.floorToPowerOf(Math.abs(number), ceilToPowerOf);
		}
		const log =  Math.ceil(Math.log(number) / Math.log(ceilToPowerOf));
		return Math.pow(ceilToPowerOf, log);
	}

	static roundUpTo(n: number, roundTo: number) {
		const floored = Math.floor(n / roundTo) * roundTo;
		let result: number;
		if (floored !== n) {
			result = floored + roundTo;
		} else {
			result = floored
		}
		return result;
	}

	// compute euclidian modulo of m % n
	// https://en.wikipedia.org/wiki/Modulo_operation
	static euclideanModulo( n:number, m:number ) {
		return ( ( n % m ) + m ) % m;
	}

	static distance(v1: number[], v2: number[]): number {
		if (v1.length !== v2.length) {
			console.error('distance: vectors dimensions mismatch');
			return 0;
		}
		let sum = 0;
		for (let i = 0; i < v1.length; ++i){
			const d = v1[i] - v2[i];
			sum += d * d; 
		}
		const distance = Math.sqrt(sum);
		return distance;
	}

	// Linear mapping from range <a1, a2> to range <b1, b2>
	static mapLinear( x:number, a1:number, a2:number, b1:number, b2:number ) {
		return b1 + ( x - a1 ) * ( b2 - b1 ) / ( a2 - a1 );
	}

	// https://en.wikipedia.org/wiki/Linear_interpolation
	static lerp( x:number, y:number, t:number ) {
		return ( 1 - t ) * x + t * y;
	}

	// http://en.wikipedia.org/wiki/Smoothstep
	static smoothstep( x:number, min:number, max:number ) {
		if ( x <= min ) return 0;
		if ( x >= max ) return 1;

		x = ( x - min ) / ( max - min );
		return x * x * ( 3 - 2 * x );
	}

	static smootherstep( x:number, min:number, max:number ) {
		if ( x <= min ) return 0;
		if ( x >= max ) return 1;

		x = (x - min) / (max - min);
		return x * x * x * ( x * ( x * 6 - 15 ) + 10 );
	}

	// Random integer from <low, high> interval
	static randInt( low:number, high:number ) {
		return low + Math.floor( Math.random() * ( high - low + 1 ) );
	}

	// Random float from <low, high> interval

	static randFloat( low:number, high:number ) {
		return low + Math.random() * ( high - low );
	}

	// Random float from <-range/2, range/2> interval

	static randFloatSpread( range:number ) {
		return range * ( 0.5 - Math.random() );
	}

	static degToRad( degrees:number ) {
		return degrees * DEG2RAD;
	}

	static radToDeg( radians:number ) {
		return radians * RAD2DEG;
	}

	static isPowerOfTwo( value:number ) {
		return ( value & ( value - 1 ) ) === 0 && value !== 0;
	}

	static ceilPowerOfTwo( value:number ) {
		return Math.pow( 2, Math.ceil( Math.log( value ) / Math.LN2 ) );
	}

	static floorPowerOfTwo( value:number ) {
		return Math.pow( 2, Math.floor( Math.log( value ) / Math.LN2 ) );
	}

	static zOrder(x: number, y: number, aabb: Aabb2) {
		// coords are transformed into non-negative 15-bit integer range

		const sizex = aabb.max.x - aabb.min.x;
		const sizey = aabb.max.y - aabb.min.y;
		const invSize = 0x7fff / Math.max(sizex, sizey);

		x = ((x - aabb.min.x) * invSize) | 0;
		y = ((y - aabb.min.y) * invSize) | 0;
	
		x = (x | (x << 8)) & 0x00FF00FF;
		x = (x | (x << 4)) & 0x0F0F0F0F;
		x = (x | (x << 2)) & 0x33333333;
		x = (x | (x << 1)) & 0x55555555;
	
		y = (y | (y << 8)) & 0x00FF00FF;
		y = (y | (y << 4)) & 0x0F0F0F0F;
		y = (y | (y << 2)) & 0x33333333;
		y = (y | (y << 1)) & 0x55555555;
	
		return x | (y << 1);
	}

	static radToPercentage(radiansAngle: number) {
		return KrMath.roundTo(Math.tan(radiansAngle) * 100, 0.01);
	}

	static percToRadians(percAngle: number) {
		return Math.atan(percAngle / 100);
	}

};
