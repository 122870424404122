import { LegacyLogger, StringUtils, convertThrow } from "engine-utils-ts";
import { KrMath } from "math-ts";
import { TrackerPartType, trackersPartsCache, TrackerTypeIdent, type TrackerPart } from '../trackers/Tracker';
import { FixedTiltTypeIdent } from '../archetypes/fixed-tilt/FixedTilt';
import { BimProperty } from '../bimDescriptions/BimProperty';
import type { SceneInstance } from '../scene/SceneInstances';
import type { NamedBimPropertiesGroup } from '../bimDescriptions/NamedBimPropertiesGroup';
import type { TrackerWindPosition } from '../anyTracker/TrackerFeatures';


export const FixedTrackerPilesPropsGroup = {
    module_size_x: BimProperty.NewShared({ path: ["module", "width"], unit: "m", value: 0 }),
    module_size_y: BimProperty.NewShared({ path: ["module", "length"], unit: "m", value: 0 }),

    max_length: BimProperty.NewShared({ path: ["dimensions", "max_length"], unit: "m", value: 0 }),
    horiz_placement: BimProperty.NewShared({ path: ["dimensions", "modules_horizontal_placement"], unit: "m", value: 0 }),
    modules_gap: BimProperty.NewShared({ path: ["modules", "gap"], unit: "m", value: 0 }),

    pile_length: BimProperty.NewShared({ path: ["piles", "length"], unit: "m", value: 0 }),
    piles_count: BimProperty.NewShared({ path: ["piles", "count"], value: 0 }),
    piles_min_embedment: BimProperty.NewShared({ path: ["piles", "min_embedment"], unit: "m", value: 0 }),
    piles_max_reveal: BimProperty.NewShared({ path: ["piles", "max_reveal"], unit: "m", value: 0 }),
    piles_spacing: BimProperty.NewShared({ path: ["piles", "spacing"], unit: "m", value: 0 }),
    purlins_overhang: BimProperty.NewShared({ path: ["piles", "purlins_max_overhang"], unit: "%", value: 0 }),

    string_max_modules: BimProperty.NewShared({ path: ["strings", "max_modules_count"], value: 0 }),
    string_min_modules: BimProperty.NewShared({ path: ["strings", "min_modules_count"], value: 0 }),
    load_position: BimProperty.NewShared({ path: ["position", "load_wind_position"], value: "" }),
    group_cost: BimProperty.NewShared({ path: ["costs", "group_cost"], value: 0, unit: "usd" }),
    total_cost: BimProperty.NewShared({ path: ["costs", "total_cost"], value: 0, unit: "usd" }),
    area_index: BimProperty.NewShared({ path: ["circuit", "position", "area_index"], value: 0, }),
}

export const SolarTrackerPilesPropsGroup = {
    module_size_x: BimProperty.NewShared({ path: ["module", "width"], unit: 'm', value: 0, }),
    module_size_y: BimProperty.NewShared({ path: ["module", "length"], unit: 'm', value: 0, }),
    string_modules_count_x: BimProperty.NewShared({ path: ["tracker-frame", "string", "modules_count_x"], value: 0, }),
    string_modules_count_y: BimProperty.NewShared({ path: ["tracker-frame", "string", "modules_count_y"], value: 0, }),
    tracker_strings_count: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "strings_count"], value: 0, }),
    tracker_module_bay_size: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "module_bay_size"], value: 0, }),
    tracker_pile_length: BimProperty.NewShared({ path: ["tracker-frame", "piles", "length"], unit: 'm', value: 0, }),
    piles_min_embedment: BimProperty.NewShared({ path: ["tracker-frame", "piles", "min_embedment"], unit: 'm', value: 0, }),
    piles_max_reveal: BimProperty.NewShared({ path: ["tracker-frame", "piles", "max_reveal"], unit: 'm', value: 0, }),
    tracker_pile_bearings_gap: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "pile_bearings_gap"], unit: 'm', value: 0, }),
    tracker_strings_gap: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "strings_gap"], unit: 'm', value: 0, }),
    modules_gap: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "modules_gap"], unit: 'm', value: 0, }),
    tracker_motor_placement: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "motor_placement"], value: 0, }),
    tracker_motor_gap: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "motor_gap"], unit: 'm', value: 0, }),
    load_position: BimProperty.NewShared({ path: ["position", "load_wind_position"], value: '', }),
    modules_row: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "modules_row"], value: "", }),
    use_modules_row: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "use_modules_row"], value: true, }),
    group_cost: BimProperty.NewShared({ path: ["costs", "group_cost"], value: 0, unit: 'usd', }),
    total_cost: BimProperty.NewShared({ path: ["costs", "total_cost"], value: 0, unit: 'usd', }),
    area_index: BimProperty.NewShared({ path: ["circuit", "position", "area_index"], value: 0, }),
}

export function getSolarTrackerPilesRelatedProps(tracker:Readonly<SceneInstance>): SolarTrackerRelatedProps {
    if(tracker.type_identifier !== TrackerTypeIdent){
        throw new Error('unsupported type ' + tracker.type_identifier);
    }
    const props = getSolarTrackerPilesRelatedPropsFromPropsGroup(
        tracker.isHidden,
        tracker.properties.extractPropertiesGroup(SolarTrackerPilesPropsGroup)
    );
    return props;
}

export function getInstanceRelatedProps(instance: Readonly<SceneInstance>): TrackerRelatedProperties|null {
    if (instance.type_identifier === 'tracker') {
        return getSolarTrackerPilesRelatedProps(instance);
    } else if (instance.type_identifier === 'fixed-tilt') {
        return getFixedTrackerPilesRelatedProps(instance);
    } else {
        return null;
    }
}

export function getSolarTrackerPilesRelatedPropsFromPropsGroup(
    is_hidden: boolean,
    tracker: typeof SolarTrackerPilesPropsGroup
) {
    const props: SolarTrackerRelatedProps = {
        is_hidden,
        type_identifier: TrackerTypeIdent,
        module_size_x_meter: tracker.module_size_x.as("m"),
        module_size_y_meter: tracker.module_size_y.as("m"),
        string_modules_count_x: tracker.string_modules_count_x.asNumber(),
        string_modules_count_y: tracker.string_modules_count_y.asNumber(),
        tracker_strings_count: tracker.tracker_strings_count.asNumber(),
        tracker_module_bay_size: tracker.tracker_module_bay_size.asNumber(),
        piles_min_embedment_meter: roundLength(tracker.piles_min_embedment.as("m")),
        piles_max_reveal_meter: roundLength(tracker.piles_max_reveal.as("m")),
        tracker_pile_length_meter: roundLength(tracker.tracker_pile_length.as("m")),
        tracker_pile_bearings_gap_meter: tracker.tracker_pile_bearings_gap.as("m"),
        tracker_strings_gap_meter: tracker.tracker_strings_gap.as("m"),
        modules_gap_meter: tracker.modules_gap.as("m"),
        tracker_motor_placement: tracker.tracker_motor_placement.asNumber(),
        tracker_motor_gap_meter: tracker.tracker_motor_gap.as("m"),
        load_position: tracker.load_position.asText() as TrackerWindPosition,
        modules_row: tracker.modules_row.asText(),
        use_modules_row: tracker.use_modules_row.asBoolean(),
        group_cost_usd: tracker.group_cost.as('usd'),
        total_cost_usd: tracker.total_cost.as('usd'),
        area_index: tracker.area_index.asNumber(),
    };

    return props;
}

export interface SolarTrackerRelatedProps extends TrackerProperties {
    is_hidden: boolean;
    type_identifier: typeof TrackerTypeIdent;
    module_size_x_meter: number;
    module_size_y_meter: number;
    string_modules_count_x: number;
    string_modules_count_y: number;
    tracker_strings_count: number;
    tracker_module_bay_size: number;
    tracker_pile_length_meter: number;
    piles_min_embedment_meter: number;
    piles_max_reveal_meter: number;
    tracker_pile_bearings_gap_meter: number;
    tracker_strings_gap_meter: number;
    modules_gap_meter: number;
    tracker_motor_placement: number;
    tracker_motor_gap_meter: number;
    load_position: TrackerWindPosition;
    modules_row: string;
    use_modules_row: boolean;
    group_cost_usd: number;
    total_cost_usd: number;
    area_index: number;
}

export interface FixedTrackerRelatedProps extends TrackerProperties {
    is_hidden: boolean;
    type_identifier: typeof FixedTiltTypeIdent;
    module_size_x_meter: number;
    module_size_y_meter: number;
    max_length_meter: number;
    horiz_placement: boolean;
    modules_gap_meter: number;
    pile_length_meter: number;
    piles_count: number;
    piles_min_embedment_meter: number;
    piles_max_reveal_meter: number;
    piles_spacing_meter: number;
    purlins_overhang_percent: number;
    string_max_modules: number;
    string_min_modules: number;
    load_position: TrackerWindPosition;
    group_cost_usd: number;
    total_cost_usd: number;
    area_index: number;
}

// export interface AnyTrackerRelatedProps extends TrackerProperties {
//     is_hidden: boolean;
//     type_identifier: `any-tracker`;
//     load_position: TrackerWindPosition;

//     group_cost_usd: number;
//     total_cost_usd: number;
//     area_index: number;

//     piles_features: PileFeaturesFlags[];
//     piles_offsets: PileFrameOffset[];
//     piles_length: number[];
//     piles_embedment: number[];
// }

export type TrackerRelatedProperties = SolarTrackerRelatedProps | FixedTrackerRelatedProps;


function roundLength(length: number) {
    return Math.round(length * 1e3) / 1e3;
}

type TrackerProperties = { [key: string]: string | number | boolean | number[] };

export function _createSolarTrackerPilesProps(
    props: Readonly<SolarTrackerRelatedProps>,
    createProps: (args: CreatePilesArguments) => Map<string, BimProperty>,
): Map<string, BimProperty>[] {
    const parts = trackersPartsCache.acquire({
        modulesPerStringCountHorizontal: props.string_modules_count_x,
        stringsPerTrackerCount: props.tracker_strings_count,
        moduleBayCount: props.tracker_module_bay_size,
        moduleSize: props.module_size_x_meter,
        motorPlacementCoefficient: props.tracker_motor_placement,
        pileGap: props.tracker_pile_bearings_gap_meter,
        motorGap: props.tracker_motor_gap_meter,
        stringGap: props.tracker_strings_gap_meter,
        modulesGap: props.modules_gap_meter,
        modulesRow: props.modules_row,
        useModulesRow: props.use_modules_row,
    }).parts;

    const pileParts:TrackerPart[] = [];
    for (const part of parts) {
        if (part.ty & TrackerPartType.Pile) {
            pileParts.push(part);
        }
    }

    const pilesProps: Map<string, BimProperty>[] = [];
    for (let i = 0; i < pileParts.length; i++) {
        const part = pileParts[i];

        let position: PilePosition = "arrow";

        if (part.ty === TrackerPartType.Motor + TrackerPartType.Pile) {
            position = "motor";
        } else if (i === 0 || i === pileParts.length - 1) {
            position = "edge";
        } else if (checkEdgePile(i, props.load_position, pileParts.length)) {
            position = "pre_edge";
        } else {
            position = "arrow";
        }
        const args: CreatePilesArguments = {
            position,
            pile_length_meter: props.tracker_pile_length_meter,
            windPosition: props.load_position,
            max_reveal_meter: props.piles_max_reveal_meter,
            min_embedment_meter: props.piles_min_embedment_meter,
            area_index: props.area_index,
            pile_index: i,
        };

        const pile = createProps(args);

        pilesProps.push(pile);
    }
    return pilesProps;
}

export function createSolarTrackerPilesProps(
  props: Readonly<SolarTrackerRelatedProps>,
): Map<string, BimProperty>[] {
    return _createSolarTrackerPilesProps(props, createPileProps);
}

export function getFixedTrackerPilesRelatedProps(tracker: Readonly<SceneInstance>):FixedTrackerRelatedProps{
    if(tracker.type_identifier !== FixedTiltTypeIdent){
        throw new Error('unsupported type ' + tracker.type_identifier);
    }

    const properties = getFixedTrackerPilesRelatedPropsFromPropsGroup(
        tracker.isHidden,
        tracker.properties.extractPropertiesGroup(FixedTrackerPilesPropsGroup)
    );
    return properties;
}

export function getFixedTrackerPilesRelatedPropsFromPropsGroup(
    is_hidden: boolean,
    tracker: typeof FixedTrackerPilesPropsGroup
) {
    const props: FixedTrackerRelatedProps = {
        is_hidden,
        type_identifier: FixedTiltTypeIdent,
        module_size_x_meter: tracker.module_size_x.as("m"),
        module_size_y_meter: tracker.module_size_y.as("m"),

        max_length_meter: tracker.max_length.as("m"),
        horiz_placement: tracker.horiz_placement.asBoolean(),
        modules_gap_meter: tracker.modules_gap.as("m"),

        piles_count: tracker.piles_count.asNumber(),
        piles_min_embedment_meter: roundLength(tracker.piles_min_embedment.as("m")),
        piles_max_reveal_meter: roundLength(tracker.piles_max_reveal.as("m")),
        pile_length_meter: roundLength(tracker.pile_length.as("m")),
        piles_spacing_meter: tracker.piles_spacing.as("m"),
        purlins_overhang_percent: tracker.purlins_overhang.as("%"),

        string_max_modules: tracker.string_max_modules.asNumber(),
        string_min_modules: tracker.string_min_modules.asNumber(),

        load_position: tracker.load_position.asText() as TrackerWindPosition,
        group_cost_usd: tracker.group_cost.as('usd'),
        total_cost_usd: tracker.total_cost.as('usd'),
        area_index: tracker.area_index.asNumber(),
    };

    return props;
}


// export function getAnyTrackerPilesRelatedProps(tracker: Readonly<SceneInstance>):AnyTrackerRelatedProps{

//     const props = tracker.props as AnyTrackerProps;
//     const properties = tracker.properties;

//     const res: AnyTrackerRelatedProps = {
//         is_hidden: tracker.isHidden,
//         type_identifier: `any-tracker`,

//         load_position: properties.getPropStringValue(`position | load_wind_position`, '') as TrackerWindPosition,
//         group_cost_usd: properties.getPropNumberAs(`costs | group_cost`, 0, 'usd'),
//         total_cost_usd: properties.getPropNumberAs(`costs | total_cost`, 0, 'usd'),
//         area_index: properties.getPropNumberAs(`circuit | position | area_index`, 0, ''),

//         piles_features: props.piles.descriptions?.features ?? [],
//         piles_offsets: props.piles.descriptions?.offsets ?? [],
//         piles_length: props.piles.lengths?.values ?? [],
//         piles_reveal: props.piles.reveal?.values ?? [],

//     };

//     return res;
// }


export interface CreatePilesArguments extends TrackerProperties  {
    position: PilePosition;
    pile_length_meter: number;
    windPosition: TrackerWindPosition;
    min_embedment_meter: number,
    max_reveal_meter: number,
    area_index: number,
    pile_index: number,
};

type PilePosition = "edge" | "pre_edge" | "motor" | "arrow";

function checkEdgePile(index: number, trackerPosition:TrackerWindPosition, pilesCount: number ) {
    if (trackerPosition === "edge_top") {
        return pilesCount > 3 && index === 1;
    } else if (trackerPosition === "edge_bot") {
        return pilesCount > 3 && index === pilesCount - 2;
    } else {
        return pilesCount > 3 && (index === 1 || index === pilesCount - 2);
    }
}

export function createPileProps(args: CreatePilesArguments): Map<string, BimProperty> {
    let pile_type: typeof pilesTypes[keyof typeof pilesTypes] | undefined;

    switch (args.windPosition) {
        case "exterior":
            if (args.position === "motor") {
                pile_type = pilesTypes.HMP;
            } else if (args.position === "edge") {
                pile_type = pilesTypes.HADP;
            } else if (args.position === "pre_edge") {
                pile_type = pilesTypes.HADPE;
            } else if (args.position === "arrow") {
                pile_type = pilesTypes.HAP;
            } else {
                console.warn('not implemented type ' + args.position)
            }

            break;
        case "edge":
        case "edge_bot":
        case "edge_top":
            if (args.position === "motor") {
                pile_type = pilesTypes.SMP;
            } else if (args.position === "edge") {
                pile_type = pilesTypes.SADP;
            } else if (args.position === "pre_edge") {
                pile_type = pilesTypes.SAPE;
            } else if (args.position === "arrow") {
                pile_type = pilesTypes.SAP;
            }else {
                console.warn('not implemented type ' + args.position)
            }
            break;
        case "interior":
            if (args.position === "motor") {
                pile_type = pilesTypes.SMP;
            } else if (args.position === "edge") {
                pile_type = pilesTypes.SADP;
            } else if (args.position === "pre_edge") {
                pile_type = pilesTypes.SAP;
            } else if (args.position === "arrow") {
                pile_type = pilesTypes.SAP;
            } else {
                console.warn('not implemented type ' + args.position)
            }
            break;
        // case "none":
        //     //if tracker position solver hasn't been started yet
        //     break;
        default:
            LegacyLogger.deferredWarn('not implemented type ', args.windPosition)
            break;
    }

    const properties = new Map<string, BimProperty>();
    const weightLb = pile_type?.weightLbPerFt == null
        ? 0
        : KrMath.roundTo(pile_type.weightLbPerFt * convertThrow(args.pile_length_meter, 'm', 'ft'), 1e-3);
    const weightProp = BimProperty.NewShared({
        path: ["pile", "weight"],
        value: weightLb,
        unit: "lb",
    });
    properties.set(weightProp._mergedPath, weightProp);

    const typeProp = BimProperty.NewShared({
        path: ["pile", "type"],
        value:  pile_type?.type ?? "none",
    });
    properties.set(typeProp._mergedPath, typeProp);

    const positionProp = BimProperty.NewShared({
        path: ["pile", "position"],
        value:  pile_type?.description ?? "none",
    });
    properties.set(positionProp._mergedPath, positionProp);

    const sizeProp = BimProperty.NewShared({
        path: ["pile", "size"],
        value:  pile_type?.size ?? "none"
    });
    properties.set(sizeProp._mergedPath, sizeProp);

    const lengthProp = BimProperty.NewShared({
        path: ["pile", "length"],
        value: args.pile_length_meter, unit: "m"
    });
    properties.set(lengthProp._mergedPath, lengthProp);

    const revealProp = BimProperty.NewShared({
        path: ["pile", "reveal"],
        value: args.max_reveal_meter, unit: "m"
    });
    properties.set(revealProp._mergedPath, revealProp);

    const embedmentProp = BimProperty.NewShared({
        path: ["pile", "embedment"],
        value: args.min_embedment_meter,
        unit: "m",
    });
    properties.set(embedmentProp._mergedPath, embedmentProp);

    const areaIndexProp = BimProperty.NewShared({
        path: ["circuit", "position", "area_index"],
        value: args.area_index,
    });
    properties.set(areaIndexProp._mergedPath, areaIndexProp);

    for (const prop of costPilesProps) {
        properties.set(prop._mergedPath, prop);
    }
    
    const indexProperty = BimProperty.NewShared({
        path: ["pile", "in_tracker_index"],
        value: args.pile_index,
        readonly: true,
    });
    properties.set(indexProperty._mergedPath, indexProperty);

    return properties;
}

const costPilesProps: BimProperty[] = [
    BimProperty.NewShared({path: ["cost_bs", "level 1"], value: "FURNISH PILES SUBTOTAL" }),
    BimProperty.NewShared({path: ["cost_bs", "level 2"], value: "Pile install" }),
];

export function _createFixedTrackerPilesProps(
    props: FixedTrackerRelatedProps,
    createProps: (args: CreatePilesArguments) => Map<string, BimProperty>,
): Map<string, BimProperty>[] {
    const pilesProps: Map<string, BimProperty>[] = [];

    for (let i = 0; i < props.piles_count; i++) {
        let position: PilePosition = "arrow";

        if (i === 0 || i === props.piles_count - 1) {
            position = "edge";
        } else if (checkEdgePile(i, props.load_position, props.piles_count)) {
            position = "pre_edge";
        } else {
            position = "arrow";
        }

        const args: CreatePilesArguments = {
            position,
            pile_length_meter: props.pile_length_meter,
            windPosition : props.load_position,
            max_reveal_meter: props.piles_max_reveal_meter,
            min_embedment_meter: props.piles_min_embedment_meter,
            area_index: props.area_index,
            pile_index: i,
        };

        const pile = createProps(args);
        pilesProps.push(pile);
    }

    return pilesProps;
}

export function createFixedTrackerPilesProps(props: FixedTrackerRelatedProps): Map<string, BimProperty>[]{
    return _createFixedTrackerPilesProps(props, createPileProps);
}


// export function createAnyTrackerPilesProps(props: AnyTrackerRelatedProps): Map<string, BimProperty>[]{
//     return _createAnyTrackerPilesProps(props, createPileProps);
// }


// export function _createAnyTrackerPilesProps(
//     props: AnyTrackerRelatedProps,
//     createProps: (args: CreatePilesArguments) => Map<string, BimProperty>,
// ): Map<string, BimProperty>[] {
//     const pilesProps: Map<string, BimProperty>[] = [];

//     const pilesCount = props.piles_features.length;
//     for (let i = 0; i < pilesCount; i++) {
//         // const features = props.piles_features[i];
//         // const pileOffset = props.piles_offsets[i];
//         const length = props.piles_length[i] ?? 0;
//         const reveal = props.piles_reveal[i] ?? 0;

//         let position: PilePosition;
//         if (i === 0 || i === pilesCount - 1) {
//             position = "edge";
//         } else if (checkEdgePile(i, props.load_position, pilesCount)) {
//             position = "pre_edge";
//         } else {
//             position = "arrow";
//         }

//         const args: CreatePilesArguments = {
//             position,
//             pile_length_meter: props.piles_length[i],
//             windPosition: props.load_position,
//             max_reveal_meter: reveal,
//             min_embedment_meter: length - reveal,
//             area_index: props.area_index,
//             pile_index: i,
//         };

//         const pileProps = createProps(args);
//         pilesProps.push(pileProps);
//     }
//     return pilesProps;
// }

export const PileKeyProps = {
    size: BimProperty.NewShared({
        path: ["pile", "size"],
        value:  0,
    }),
    type: BimProperty.NewShared({
        path: ["pile", "type"],
        value:  0,
    })
} satisfies NamedBimPropertiesGroup

export const PileAllProps = {
    ...PileKeyProps,
    weight: BimProperty.NewShared({
        path: ["pile", "weight"],
        value: 0,
        unit: 'lb',
    }),
    length: BimProperty.NewShared({
        path: ["pile", "length"],
        value: 0,
        unit: 'm',
    }),
    reveal: BimProperty.NewShared({
        path: ["pile", "reveal"],
        value: 0,
        unit: 'm',
    }),
    embedment: BimProperty.NewShared({
        path: ["pile", "embedment"],
        value: 0,
        unit: 'm',
    }),
}

export const pilesTypes = {
    HAP: {
        type: 'HAP',
        description: 'HEAVY ARRAY PIER',
        size: 'W6x9',
        weightLbPerFt: 9,
    },
    HADP: {
        type: 'HADP',
        description: 'HEAVY ARRAY PIER DAMPER',
        size: 'W6x9',
        weightLbPerFt: 9,
    },
    HADPE: {
        type: 'HADPE',
        description: 'HEAVY ARRAY PIER EDGE DAMPER',
        size: 'W6x10.5',
        weightLbPerFt: 10.5,
    },
    HMP: {
        type: 'HMP',
        description: 'HEAVY MOTOR PIER',
        size: 'W6x15',
        weightLbPerFt: 15,
    },
    SAP: {
        type: 'SAP',
        description: 'STANDARD ARRAY PIER',
        size: 'W6x7',
        weightLbPerFt: 7,
    },
    SADP: {
        type: 'SADP',
        description: 'STANDARD ARRAY PIER DAMPER',
        size: 'W6x7',
        weightLbPerFt: 7,
    },
    SAPE: {
        type: 'SAPE',
        description: 'STANDARD ARRAY PIER EDGE',
        size: 'W6x8.5',
        weightLbPerFt: 8.5,
    },
    SMP: {
        type: 'SMP',
        description: 'STANDARD MOTOR PIER',
        size: 'W6x10.5',
        weightLbPerFt: 10.5,
    },
} as const;

export const pileTypesFullNameToShortName: { [key in string]: string } = {}

for (const [_id, obj] of Object.entries(pilesTypes)) {
    pileTypesFullNameToShortName[obj.description] = obj.type;
}

export type PileFullType = typeof pilesTypes[keyof typeof pilesTypes]['description']


export type PileType = keyof typeof pilesTypes;

export function getPileFullLabel(typeName: string) {
    const type = pilesTypes[typeName as keyof typeof pilesTypes];
    if (!type) {
        return 'unknown pile';
    }
    const label = type.type + ' (' + StringUtils.capitalizeFirstCharInEachWord(type.description.toLowerCase()) + ')';
    return label;
}
