<script lang="ts">
export let path: string[]

function removePrefixLevel(idxToRemove: number) {
    path = path.filter((_i, idx) => idx !== idxToRemove)
}
function addPrefixLevel() {
    path = [...path, '']
}
</script>

<div>
    <ul>
        {#each path as _, idx}
            <li>
                <input class="basic-interact" bind:value={path[idx]}>&nbsp;
                <span class="basic-interact" on:click={() => removePrefixLevel(idx)}>x</span>
            </li>
        {/each}
    </ul>

    <span class="basic-interact" on:click={addPrefixLevel}>+add</span>
</div>

<style lang="scss">
</style>
