<script lang="ts">
    import { Vector2 } from "math-ts";
    import type { PUI_CustomPropertyNode } from "ui-bindings";
    import { IconComponent, type IconName } from "../../libui/icon";
    import { CheckboxStandard, Switch } from "../../libui/checkbox";
    import PropertyHelp from "../PropertyHelp.svelte";

    interface IconAction {
        iconName: IconName;
        onClick: (pos: Vector2) => void;
    }
    export let property: PUI_CustomPropertyNode<string, {
        showOnlyName?: boolean,
        switch?: {
            value: boolean,
            onChange: (value: boolean) => void;
            disabled?: boolean;
        },
        isProperty?: boolean,
        checkBoxBefore?: {
            value: boolean,
            onClick: () => void;
            readonly?: boolean;
        },
        iconAfter?: IconAction[] | IconAction
        hasError?: boolean;
        tooltipError?: boolean;
        multiline?: boolean;
    }>;

    $: icons = property.context.iconAfter 
        ?  Array.isArray(property.context.iconAfter) ? property.context.iconAfter : [property.context.iconAfter] 
        : [];

</script>

<div 
    class="ui-config-property property-row"
    class:not-active = {property.notActive}
    >
    <div class="property-name property-name-group"
        class:mdc-typography--overline = {!property.context.isProperty}
        class:text-main-medium = {property.context.isProperty}
    >   {#if property.context.switch}
            {@const {value, onChange, disabled} = property.context.switch}
            <span 
                class="icon-wrap"
                class:clicable = {disabled}
            >
                <Switch 
                    {value} 
                    readonly={disabled} 
                    onChange ={onChange}
                />
            </span>
        {/if}
        <div class:property-name-label ={property.context.isProperty}
            class:mdc-typography--body1 = {property.context.isProperty}
        >
            {property.name}
        </div>
    </div>
    {#if icons.length > 0 && property.context.showOnlyName}
        <span class="icons-group">
            {#each icons as icon}
                <span tabindex="-1" 
                    class=" icon-holder"
                    class:icon-error = {property.context.hasError}
                    on:click={e => {icon.onClick(new Vector2(e.x, e.y));}}>
                    <IconComponent name={icon.iconName} />
                </span>
            {/each}
        </span>
    {/if}
    {#if !property.context.showOnlyName}
        <div 
            class="property-value"
            class:checkbox-holder = {property.context.checkBoxBefore || icons.length > 0 || property.description}
        >
            <span class="checkbox-holder">
                {#if property.context.checkBoxBefore}
                    <span class="icon-holder clickable">
                        <CheckboxStandard
                            value={property.context.checkBoxBefore?.value}
                            onChange={() => {property.context.checkBoxBefore?.onClick();}}
                            readonly={property.context.checkBoxBefore?.readonly}
                        />
                    </span>
                {/if}
                <span class = "mdc-typography--body1 read-only calculated text-order"
                    class:multiline = {property.context.multiline}
                    class:text-danger = {property.context.hasError}
                >
                    <span class:group-value = {!property.context.multiline}>
                        {@html property.value}
                    </span>
                </span>
            </span>
            {#if icons.length > 0 || !!property.description}
                <span class="icons-group">
                    {#if icons.length > 0}
                        {#each icons as icon }
                            <span tabindex="-1" 
                                class=" icon-holder clickable"
                                class:icon-error = {property.context.hasError}
                                on:click={e => {icon.onClick(new Vector2(e.x, e.y));}}>
                                <IconComponent name={icon.iconName} />
                            </span>
                        {/each}
                    {/if}
                    {#if !!property.description}
                        <span tabindex="-1" 
                            class="icon-holder clickable"
                            class:icon-error = {property.context.tooltipError}
                            >
                            <PropertyHelp
                                content={property.description}
                                name={property.name}
                            />
                        </span>
                    {/if}
                </span>   
            {/if}
        </div>
    {/if}
</div>

<style lang="scss">
    .icons-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        padding-right: 8px;
    }
    .icon-holder {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-main-medium);
    }
    .group-value {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .clickable {
        cursor: pointer;
    }

    .text-order {
        min-height: 26px;
    }

    .icon-error{
        color: var(--color-danger);
    }
    .checkbox-holder {
        display: flex;
        flex: 4;
        align-items: center;
        overflow: hidden;
    }

    .icon-error :global(.icon-container) {
        color: var(--color-danger);
        &:hover {
            color: var(--color-danger);
        }
    }
</style>
