import { AnyTrackerProps, SceneInstance } from "bim-ts";
import { Euler, KrMath } from "math-ts";


export function groupByNumber<T>(list: T[], keyGetter: (item: T) => number) {
    const map = new Map<number, T[]>();
    list.forEach(item => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });

    return map;
}
export function groupByString<T>(list: T[], keyGetter: (item: T) => string) {
    const map = new Map<string, T[]>();
    list.forEach(item => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });

    return map;
}

export function InstanceIsTracker(instance?:SceneInstance):boolean{

    if(!instance){
        return false;
    }

    if(instance.type_identifier.includes("tracker")){
        return true;
    }
    if(instance.type_identifier.includes("fixed-tilt")){
        return true;
    }
    if(instance.type_identifier.includes("any-tracker")){
        return true;
    }

    return false;
}

export function getTrackerDimensions(tracker: SceneInstance): {
    length: number;
    width: number;
} {
    let length = 0;
    let width = 0;

    if (tracker.type_identifier === "fixed-tilt") {
        length =
            tracker.properties.get("dimensions | length")?.asNumber() ?? 0;
        width =
            tracker.properties.get("dimensions | width")?.asNumber() ?? 0;
    } else if (tracker.type_identifier === "tracker") {
        length =
            tracker.properties
                .get("tracker-frame | dimensions | length")
                ?.asNumber() ?? 0;
        width =
            tracker.properties
                .get("tracker-frame | dimensions | max_width")
                ?.asNumber() ?? 0;
    } else if (
        tracker.type_identifier === "any-tracker" &&
        tracker.props instanceof AnyTrackerProps
    ) {
        length = tracker.props.tracker_frame.dimensions.length?.value || 0;
        width =
            tracker.props.tracker_frame.dimensions.max_width?.value || 0;
    }

    return { length, width };
}

export function getTrackerSlopeInDegrees(instance: SceneInstance){

    const transformRotation = new Euler().setFromRotationMatrix(instance.worldMatrix);

    const calculateSlopeDegrees = (slope: number, direction: string | undefined): number => {
        let degrees = KrMath.radToDeg(KrMath.percToRadians(slope));
        if (direction === "north-facing") {
            degrees = -degrees;
        }
        return degrees;
    };

    switch (instance.type_identifier) {
        case "fixed-tilt":
            return -KrMath.radToDeg(transformRotation.y);

        case "tracker":
            const dir = instance.properties.get("position | slope-direction")?.value;
            const slope = instance.properties.get("position | slope_first_to_last")?.as("%") ?? 0;
            return calculateSlopeDegrees(slope, dir);

        case "any-tracker":
            if (instance.props instanceof AnyTrackerProps) {
                const slope = instance.props.position.slope_first_to_last_pile.value || 0;
                const dir = instance.props.position.facing_first_to_last_pile.value;
                return calculateSlopeDegrees(slope, dir);
            }
            return 0;

        default:
            return 0;
    }
}

