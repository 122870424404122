import type { BasicPropertyValue, SceneInstance } from 'bim-ts';
import { type Bim, type IdBimScene, type UnitsMapper, PropertyBase, BimProperty, NumberProperty } from 'bim-ts';
import { ObservableObject, type LazyVersioned, LazyDerived, VersionedInvalidator, IterUtils, DefaultMapObjectKey, ObjectUtils } from 'engine-utils-ts';
import { PUI_ActionsNode, PUI_GroupNode, PUI_PropertyNodeMultiSelector, PUI_PropertyNodeSelector, type PUI_ActionDescr } from 'ui-bindings';


export interface PropertyFilter {
    fits(property: BimProperty | PropertyBase): boolean;
}
export class PropertyValueRangeFilter implements PropertyFilter {
    constructor(
        readonly asUnit: string,
        readonly minValue: number,
        readonly maxValue: number,
    ) {
    }

    fits(property: BimProperty | PropertyBase): boolean {
        let v: number|undefined = undefined;
        if (this.asUnit) {
            if (property instanceof BimProperty || property instanceof NumberProperty) {
                v = property.as(this.asUnit);
            } else {
                return false;
            }
        } else {
            v = (property as any).value;
        }
        if (v !== undefined && v >= this.minValue && v <= this.maxValue) {
            return true;
        }
        return false;
    }
}
export class PropertyExactValuesFilter implements PropertyFilter {
    constructor(
        readonly asUnit: string,
        readonly values: Set<string>,
    ) {
    }
    fits(property: BimProperty | PropertyBase): boolean {
        let v: number|string|undefined = undefined;
        if (this.asUnit) {
            if (property instanceof BimProperty || property instanceof NumberProperty) {
                v = property.as(this.asUnit);
            } else {
                return false;
            }
        } else {
            v = (property as any).value;
        }
        if (v === undefined) {
            return false;
        }
        v = v.toString();
        return this.values.has(v);
    }
}

export interface SceneInstanceFilter {
    fits(instance: SceneInstance): boolean;
}

export class SceneInstanceByTypeFilter implements SceneInstanceFilter {
    constructor(
        readonly allowedTypes: string[],
    ) {

    }
    fits(instance: SceneInstance): boolean {
        if (this.allowedTypes.length === 0) {
            return true;
        }
        return this.allowedTypes.includes(instance.type_identifier);
    }
}

export class SceneInstanceByPropertyFilter implements SceneInstanceFilter {
    
    readonly propertyPathSplit: string[];

    constructor(
        readonly propertyPath: string,
        readonly propertyFilters: PropertyFilter,
    ) {
        this.propertyPathSplit = propertyPath.split(' | ');
    }
    
    fits(instance: SceneInstance): boolean {
        const p = instance.properties.get(this.propertyPath);   
        if (p) {
            return this.propertyFilters.fits(p);
        }
        const pNew = instance.props.getAtPath(this.propertyPathSplit);
        if (pNew instanceof PropertyBase) {
            return this.propertyFilters.fits(pNew);
        }
        return false;
    }
    
}

export interface InstancesQuery {
    readonly filters: {filter: SceneInstanceFilter, inverter: boolean}[];
}

export interface InstancesQueryResult {
    readonly resultIds: IdBimScene[];
    readonly queryCountsPerFilter: number[];
}

export interface FilterQueryResult {
}

export interface SelectByPropsValueParams {
    object_types: string[];
    condition_state: SelectByValueState[];
}

interface SelectByValueState {
    property_path: string | null;
    property_values_as_string: string[];
    operation_type?: OperationType;
}

enum OperationType {
    Union,
    Intersection,
}

export function createSelectByPropParamsUi(
    bim: Bim,
    unitsMapper: UnitsMapper,
): [
    ObservableObject<SelectByPropsValueParams>,
    LazyVersioned<PUI_GroupNode>,
] {
    const paramsObj = new ObservableObject<SelectByPropsValueParams>({
        identifier: 'props-chart',
        initialState: {
            object_types: [],
            condition_state: [
                {
                    property_path: null,
                    property_values_as_string: [],
                    operation_type: undefined
                }
            ]
        }
    });

    paramsObj.observeObject({
        settings: {doNotNotifyCurrentState: true, immediateMode: true},
        onPatch: ({currentValueRef}) => {
            try {
                localStorage.setItem('select-by-props-value-params', JSON.stringify(currentValueRef));
            } catch (e) {
                console.error(e);
            }
        },
    });

    try {
        const settings = localStorage.getItem('select-by-props-value-params');
        if (settings) {
            const asObj = JSON.parse(settings) as SelectByPropsValueParams;
            paramsObj.applyPatch({
                patch: {
                    ...asObj,
                    condition_state: asObj.condition_state.map((state) => ({
                        ...state,
                        property_values_as_string: []
                    }))
                }
            });
        }
    } catch (e) {
        console.error(e);
    }

    const validObjsTypes = bim.instances.getLazyKnownTypes();

    const propsValuesGatherInvalidator = new VersionedInvalidator();
    class AvailablePropertyValue {

        readonly valueAsString: string;

        constructor(
            readonly valueUnit: BasicPropertyValue,
            readonly ids: IdBimScene[] = [],
        ) {
            this.valueAsString = valueUnit.value.toString();
            if (valueUnit.unit) {
                this.valueAsString += ` ${valueUnit.unit}`;
            }
        }

        toString() {
            let valueStr = this.valueUnit;
            if (typeof valueStr === 'number') {
                valueStr = valueStr;
            }
            return `${this.valueAsString} [${this.ids.length}]`;
        }
    }

    const propertySelectorParams = LazyDerived.new1(
        '', null,
        [paramsObj],
        ([params]) => {
            return {
                object_type: params.object_types,
                property_path: params.condition_state.map(v => v.property_path),
            };
        }
    )

    const lazyKnownProps = bim.instances.basicPropsView.asLazyVersioned();

    const availableValuesGatherer = LazyDerived.new2(
        'available-values-gatherer',
        [propsValuesGatherInvalidator],
        [propertySelectorParams, lazyKnownProps],
        ([params, knownProps]) => {
            const allValues: AvailablePropertyValue[][] = [];
            for (let i = 0; i < params.property_path.length; i++) {
                const propertyDescr = knownProps.find((kp) => kp.label == params.property_path[i]);
                if (!propertyDescr) {
                    continue;
                }
                const perPropertyValue = new DefaultMapObjectKey<BasicPropertyValue, AvailablePropertyValue>({
                    unique_hash: (k) => `${k.value}:${k.unit}`,
                    valuesFactory: (kp) => new AvailablePropertyValue(kp),
                });

                for (const [id, instance] of bim.instances.perId) {
                    if (!propertyDescr.isFoundInTypeIdent(instance.type_identifier)) {
                        continue;
                    }
                    let property = bim.instances.basicPropsView.peekKnownPropertyValueByPath(id, propertyDescr.path);
                    if (!property) {
                        property = { value: "N/A", unit: "" };
                    } else {
                        if (typeof property.value === 'number') {
                            const converted = unitsMapper.mapToConfigured({ value: property.value, unit: property.unit });
                            property = { value: converted.value, unit: converted.unit };
                        }
                    }
                    perPropertyValue.getOrCreate(Object.freeze(property)).ids.push(id);
                }

                allValues.push(Array.from(perPropertyValue.values()));
                if (allValues[i] === undefined)
                    continue;
                allValues[i].sort((a, b) => {
                    if (typeof a.valueUnit.value === 'number' && typeof b.valueUnit.value === 'number') {
                        return a.valueUnit.value - b.valueUnit.value;
                    }
                    return a.valueUnit.value.toString().localeCompare(b.valueUnit.value.toString(), undefined, { numeric: true });
                });

            }
            return allValues;

        }
    );

    const selectByPropsParamsUi = LazyDerived.new4(
        'select-by-props-params-ui',
        [],
        [paramsObj, validObjsTypes, lazyKnownProps, availableValuesGatherer],
        ([params, validObjsTypes, knownProps, availableValues]) => {
            const node = new PUI_GroupNode({
                name: 'params',
                sortChildren: false,
            });

            node.addMaybeChild(new PUI_PropertyNodeMultiSelector({
                name: `object type`,
                options: validObjsTypes.map(ty => ({ value: ty })),
                value: params.object_types.filter(t => validObjsTypes.includes(t)).map(ty => ({ value: ty })),
                onChange: (newValue) => {
                    const newValues = newValue.map(t => t.value) as string[];

                    paramsObj.applyPatch({
                        patch: {
                            object_types: newValues,
                        },
                    });
                },
            }));    
    
            for (let i = 0; i < params.condition_state.length; i++) {
                const numberOfGroup = (i==0 &&  params.condition_state.length == 1)? '' : `${i + 1}`;
                const knownPropertyDescription = knownProps.find(d => d.label == params.condition_state[i].property_path);
                const allowedPropsForSelectedTypes = params.object_types.length > 0
                    ? knownProps.filter(t => t.isFoundInTypeIdends(params.object_types))
                    : knownProps;
        
                const propertyOptions = allowedPropsForSelectedTypes.map(t => t.label);
        
                node.addMaybeChild(new PUI_PropertyNodeSelector({
                    name: `property group ${numberOfGroup}`,
                    value: knownPropertyDescription ? knownPropertyDescription.label : null,
                    options: [...propertyOptions],
                    onChange: (newValue) => {
                        paramsObj.applyPatch({
                            patch: {
                                condition_state: params.condition_state.map((state, index) =>
                                    index === i
                                        ? { ...state, property_path: newValue }
                                        : state
                                ),
                            },
                        });
                    },
                }));
        
                const filteredValues = IterUtils.filterMap(
                    params.condition_state[i].property_values_as_string,
                    (v) => {
                        const found = Array.isArray(availableValues[i])
                            ? availableValues[i].find(av => av.valueAsString == v)
                            : undefined;
                        if (!found) {
                            return undefined;
                        }
                        return { value: v, label: found.toString() };
                    }
                );
        
                node.addMaybeChild(new PUI_PropertyNodeMultiSelector({
                    name: `by exact values group ${numberOfGroup}`,
                    value: filteredValues,
                    options: Array.isArray(availableValues[i]) ? availableValues[i].map(v => ({ value: v.valueAsString, label: v.toString() })) : [],
                    onChange: (newValue) => {
                        paramsObj.applyPatch({
                            patch: {
                                condition_state: params.condition_state.map((state, index) =>
                                    index === i
                                        ? { ...state, property_values_as_string: newValue.map(v => v.value as string) }
                                        : state
                                ),
                            },
                        });
                    },
                }));

                const andOrActions: PUI_ActionDescr<any>[] = [{
                    label: 'and',
                    style: { type: params.condition_state[i].operation_type == OperationType.Intersection ? 'primary' : 'outlined' },
                    action: () => {
                        updateConditionState(i, OperationType.Intersection);
                    },
                },
                {
                    label: 'or',
                    style: { type: params.condition_state[i].operation_type == OperationType.Union ? 'primary' : 'outlined' },
                    action: () => {
                        updateConditionState(i, OperationType.Union);
                    },
                }];

                const deleteAction: PUI_ActionDescr<any> = {
                    label: 'delete',
                        style: { type: 'text' },
                        action: () => {
                            deleteCondition(i);
                        },
                }
                if (params.condition_state[i].operation_type !== undefined || params.condition_state[i].property_values_as_string.length > 0) {
                    node.addMaybeChild(new PUI_ActionsNode({
                        name: `and or action ${numberOfGroup}`,
                        actions: (params.condition_state.length>1) ? [...andOrActions, deleteAction] : andOrActions,
                        context: undefined,
                    }));
                }
            };

            if (params.condition_state.every(state => state.property_values_as_string.length > 0)) {
                node.addMaybeChild(new PUI_ActionsNode({
                    name: 'actions',
                    context: undefined,
                    actions: [
                        {
                            label: 'add to selection',
                            action: () => {
                                const resultIds = filterIds();

                                if (resultIds.length) {
                                    bim.instances.toggleSelected(true, resultIds);
                                } else {
                                    console.error(`No ids for selected range`);
                                }
                            }
                        },
                        {
                            label: 'select',
                            action: () => {
                                const resultIds = filterIds();

                                if (resultIds.length == 0) {
                                    console.error(`No ids for selected range`);
                                }
                                bim.instances.setSelected(resultIds);
                            }
                        },
                    ]
                }));
            }
            

            function filterIds() {
                let resultIds = new Set<IdBimScene>();

                for (let i = 0; i < params.condition_state.length; i++) {
                    const ids = new Set<IdBimScene>();

                    params.condition_state[i].property_values_as_string.forEach(value => {
                        const temp_ids = availableValues[i]?.find(av => av.valueAsString == value)?.ids;
                        if (temp_ids) {
                            temp_ids.forEach(id => ids.add(id));
                        } else {
                            console.error(`No ids for value ${value}`);
                        }
                    });
        
                    if (ids.size > 0) {
                        if (i === 0) {
                            resultIds = new Set(ids);
                        } else {
                            if (params.condition_state[i - 1].operation_type == OperationType.Intersection) {
                                resultIds = new Set([...resultIds].filter(id => ids.has(id)));
                            }
                            else {
                                ids.forEach(id => resultIds.add(id));
                            }
                        }
                    }
                }
                return Array.from(resultIds);
            }

            function updateConditionState(condition_index: number, operation_type: OperationType) {
                if (condition_index == params.condition_state.length - 1) {
                    params.condition_state.push({
                        property_path: null,
                        property_values_as_string: [],
                        operation_type: undefined
                    })
                }
                paramsObj.applyPatch({
                    patch: {
                        condition_state: params.condition_state.map((state, index) =>
                            index === condition_index
                                ? { ...state, operation_type: operation_type }
                                : state
                        ),
                    },
                });
            }

            function deleteCondition(condition_index: number) {
                if (params.condition_state.length > 1) {
                    const modifiedArr = ObjectUtils.deepCloneObj(params);
                    
                    if (condition_index == modifiedArr.condition_state.length - 1){
                        modifiedArr.condition_state[condition_index - 1].operation_type = undefined;
                    }
                    modifiedArr.condition_state.splice(condition_index, 1);
                    paramsObj.applyPatch({
                        patch: {
                            condition_state: modifiedArr.condition_state,
                        }
                    });
                }
            }
            
            return node;
        }
    );

    return [
        paramsObj,
        selectByPropsParamsUi,
    ]
}