import { DefaultMap } from './DefaultMap';

export enum LogLevel {
    Debug = 0,
	Info = 1,
	Warn = 2,
	Error = 3,

	Default = LogLevel.Info,
}

const defferedMsgsPerLogLevel: DefaultMap<string, any[]>[] = [
	new DefaultMap<string, any[]>(() => []),
	new DefaultMap<string, any[]>(() => []),
	new DefaultMap<string, any[]>(() => []),
	new DefaultMap<string, any[]>(() => []),
];

let scheduled = false;
function scheduleLog(logger: ScopedLogger) {
	if (scheduled) {
		return;
	}
	scheduled = true;
	Promise.resolve().then(() => {
		scheduled = false;
		for (let i = 0; i < defferedMsgsPerLogLevel.length; ++i) {
			const m = defferedMsgsPerLogLevel[i];
			try {
				for (const [message, objs] of m) {
					logger.log(i, message, objs);
				}
			} finally {
				m.clear();
			}
		}
	})
}

let handleMessage: ((msg: string) => void) | undefined = undefined;

export class ScopedLogger {
    logLevel: LogLevel;
    scopeMsg: string;

    constructor(scopeName: string, logLevel?: LogLevel) {
		this.scopeMsg = scopeName + '|';
        this.logLevel = logLevel ?? LogLevel.Default;
        Object.freeze(this);
    }

    newScope(name: string, overrideLogLevel?: LogLevel): ScopedLogger {
        return new ScopedLogger(this.scopeMsg + name, overrideLogLevel ?? this.logLevel);
	}

	private logToFile(level: LogLevel, message: string): void {
		if(handleMessage) {
			const logMessage = `[${new Date().toISOString()}] [${LogLevel[level]}] [${this.scopeMsg}] ${message}\n`;
			handleMessage(logMessage);
		}
	}

	log(logLevel: LogLevel, ...objects: any[]) {
		if (this.logLevel <= logLevel) {
			switch (logLevel) {
				case LogLevel.Debug:
					console.log('%c' + this.scopeMsg, 'color: blue', ...objects);
					break;
				case LogLevel.Info:
					console.log(this.scopeMsg, ...objects);
					break;
				case LogLevel.Warn:
					console.warn(this.scopeMsg, ...objects);
					break;
				case LogLevel.Error:
					console.error(this.scopeMsg, ...objects);
					break;
				default: 
					console.error('Invalid log level', logLevel, ...objects);
					break;
			}
			
			this.logToFile(logLevel, objects.join(' '));
		}
	}
	
	debug(...objects: any[]) {
		this.log(LogLevel.Debug, ...objects);
	}
    info(...objects: any[]) {
		this.log(LogLevel.Info, ...objects);
	}
	warn(...objects:any[]) {
		this.log(LogLevel.Warn, ...objects);
	}
	error (...objects:any[]) {
		this.log(LogLevel.Error, ...objects);
	}

	batchedDebug(msg: string, obj: any) {
		if (this.logLevel <= LogLevel.Debug) {
			scheduleLog(this);
			defferedMsgsPerLogLevel[LogLevel.Debug].getOrCreate(this.scopeMsg + msg).push(obj);
		}
	}
    batchedInfo(msg: string, obj: any) {
		if (this.logLevel <= LogLevel.Info) {
			scheduleLog(this);
			defferedMsgsPerLogLevel[LogLevel.Info].getOrCreate(this.scopeMsg + msg).push(obj);
		}
	}
	batchedWarn(msg: string, obj: any) {
		if (this.logLevel <= LogLevel.Warn) {
			scheduleLog(this);
			defferedMsgsPerLogLevel[LogLevel.Warn].getOrCreate(this.scopeMsg + msg).push(obj);
		}
	}
	batchedError(msg: string, obj: any) {
		if (this.logLevel <= LogLevel.Error) {
			scheduleLog(this);
			defferedMsgsPerLogLevel[LogLevel.Error].getOrCreate(this.scopeMsg + msg).push(obj);
		}
	}

	assert(condition: boolean, message: string, ...objects: any[]) {
		if (!condition) {
			this.error("Assertion failed:", this.scopeMsg, message, ...objects);
		}
	}

	debugAssert(condition: boolean, message: string, ...objects: any[]) {
		if (!condition) {
			this.error(this.scopeMsg, message, ...objects);
		}
	}

	static setLogHandler(handler?: (msg: string) => void) {
		if (handleMessage) {
			console.warn('handleMessage already set, overwriting it');
		}
		handleMessage = handler;
	}
	
	static removeLogHandler() {
		handleMessage = undefined;
	}
}
