import type { CombinerBoxDescription } from "../types";
import { Aabb2, Matrix3, Vector2 } from "math-ts";
import { Anchor2D } from "vector-graphic";
import { createManyToOneConnection } from "src/sld/templates/connections";
import { createModuleTables2D } from "src/sld/templates/module-table";
import { createCombinerBoxObject2D } from "../../templates/combiner-box";
import { createCombinerBoxInternalWiring } from "src/sld/templates/combiner-box";
import { createResistance } from "src/sld/templates/resistance";

export function createCombinerBoxObject2DWithChilds(
    combinerBoxDesc: CombinerBoxDescription,
    params: { index: string },
) : Anchor2D {
    // create children
    const object = createModuleTables2D(
        combinerBoxDesc.moduleTables,
        combinerBoxDesc.connectionType
    );

    // create base combiner box
    const combinerBox = createCombinerBoxObject2D(combinerBoxDesc, params)

    // create children wiring
    const wiring = createManyToOneConnection({
        sources: object.points,
        sourceOffset: 300,
        targetOffset: 100,
        wireOffset: combinerBoxDesc.connectionType === 'single'
            ? 0 : 20,
        targetHeight: combinerBoxDesc.connectionType === 'single'
            ? 0 : (combinerBox.aabb.height() * 0.4),
    })
    object.addAndExpandAabb(wiring);

    // position combiner box
    if (!object.aabb.isEmpty() && wiring.points.length) {
        const aabb = aabbReused.setFromPoints(wiring.points);
        combinerBox.position.set(aabb.centerX() - combinerBox.aabb.min.x, aabb.centerY());
        combinerBox.updateMatrix()
    }
    object.addAndExpandAabb(combinerBox);

    // cb internal wiring
    {
        const cbInverseMatrix = mat3Reused.copy(combinerBox.matrix).invert();
        const internalWiring = createCombinerBoxInternalWiring({
            cbCenter: new Vector2(),
            inputs: wiring.points.map(x => x.clone().applyMatrix3(cbInverseMatrix))
        })
        combinerBox.addAndExpandAabb(internalWiring);
    }


    // create resulting object
    const object2 = new Anchor2D();
    object2.addAndExpandAabb(object);

    // draw resistance
    {
        const label = combinerBoxDesc.outputCurrent
            ? (combinerBoxDesc.outputCurrent.value + ' ' + combinerBoxDesc.outputCurrent.unit)
            : ''
        const cbExitWorld = combinerBox.point.clone().applyMatrix3(combinerBox.matrix);
        const resistance = createResistance(label);
        resistance.position.copy(cbExitWorld);
        resistance.updateMatrix();
        resistance.zIndex = 2;
        object.addPrimitiveAndExpandAabb(resistance);
        object2.point.copy(resistance.point).applyMatrix3(resistance.matrix);
    }

    return object2;
}

const aabbReused = Aabb2.empty();
const mat3Reused = new Matrix3();
