import type { PropsGroupBase } from '../properties/Props';
import { PropertyBase } from '../properties/Props';
import type { UnitsMapper } from "../UnitsMapper";
import { BimProperty } from './BimProperty';
import type { NamedBimPropertiesGroup } from "./NamedBimPropertiesGroup";
import type { PropertiesCollection } from "./PropertiesCollection";



export class PropertiesGroupFormatter<T extends NamedBimPropertiesGroup> {
    constructor(
        public readonly props: T,
        public readonly format: (props: T, unitsMapper: UnitsMapper) => string
    ) {}
}


export class PropertiesGroupFormatters {
    private unitsMapper: UnitsMapper;
    private formaters = new Map<string, PropertiesGroupFormatter<NamedBimPropertiesGroup>>();

    constructor(unitsMapper: UnitsMapper) {
        this.unitsMapper = unitsMapper;
    }

    register(
        identifier: string,
        propertyGroup: PropertiesGroupFormatter<any>,
    ) {
        this.formaters.set(identifier, propertyGroup);
        return this;
    }

    format(
        identifier: string,
        properties: PropertiesCollection,
        props: PropsGroupBase
    ) {
        const namedProps = this.getPropertiesGroup(identifier, properties, props);
        if (!namedProps) {
            return null;
        }
        return this.formatNamedProps(identifier, namedProps);
    }

    formatNamedProps(
        identifier: string,
        namedProps: NamedBimPropertiesGroup,
    ) {
        const formatter = this.formaters.get(identifier) ?? null
        if (!formatter) {
            return null;
        }
        return formatter.format(namedProps, this.unitsMapper)
    }

    getPropertiesGroup(
        identifier: string,
        properties: PropertiesCollection,
        props: PropsGroupBase
    ) {
        const formatter = this.formaters.get(identifier) ?? null;
        if (!formatter) {
            return null;
        }
        const namedProps = extractIntoNamedPropsGroup(formatter.props, properties, props);
        return namedProps;
    }

    fullKeyPropsStr(
        identifier: string,
        properties: PropertiesCollection,
        props: PropsGroupBase
    ) {
      const namedProps = this.getPropertiesGroup(identifier, properties, props);
      if (!namedProps) {
        return null;
      }
      const components: string[] = [identifier];
      for (const [key, prop] of Object.entries(namedProps)) {
        const valueStr = prop.valueUnitUiString(this.unitsMapper);
        components.push(key + '=' + valueStr);
      }
      return components.join(' ');
    }

}

export function extractIntoNamedPropsGroup<T extends NamedBimPropertiesGroup>(
    groupDefinition: T,
    legacyProps: PropertiesCollection,
    props: PropsGroupBase,
): T {
    const group: NamedBimPropertiesGroup = {};
    for (const key in groupDefinition) {
        let resultProperty: BimProperty;
        
        const defaultProperty = groupDefinition[key];
        
        const legacyProperty = legacyProps.get(defaultProperty._mergedPath);
        if (legacyProperty) {
            resultProperty = legacyProperty;
        } else {
            const newProperty = props.getAtPath(defaultProperty.path);
            if (newProperty instanceof PropertyBase) {
                const bimProperty = tryCreateBimPropertyFromNewProperty(defaultProperty.path, newProperty);
                if (bimProperty) {
                    resultProperty = bimProperty;
                } else {
                    resultProperty = defaultProperty;
                }
            } else {
                resultProperty = defaultProperty;
            }
        }

        group[key] = resultProperty;
    }
    return group as T;
}

const conversionsCache = new WeakMap<PropertyBase, BimProperty|null>();
function tryCreateBimPropertyFromNewProperty(path: string[], newProperty: PropertyBase) {
    
    let result = conversionsCache.get(newProperty);
    if (result !== undefined) {
        return result;
    }
    const value = (newProperty as any).value;
    const unit = (newProperty as any).unit;

    if (typeof value === 'number' || typeof value === 'string' || typeof value === 'boolean') {
        result = BimProperty.NewShared({
            path,
            value: value,
            unit: unit,
        });
    } else {
        result = null;
    }
    conversionsCache.set(newProperty, result);
    return result;

}

