import { Bim, IdBimScene, BimPatch, SceneInstance, PolylineGeometry, BasicAnalyticalRepresentation, LocalIdsCounter, Points2D, ExtrudedPolygonGeometry } from "bim-ts";
import { RGBA } from "engine-utils-ts";
import { Aabb, Aabb2, Matrix4, PolygonUtils, Quaternion, Transform, Vector2, Vector3 } from "math-ts";
import { KmlParseResult, KmlElement, KmlFolder, KmlPolyline, projectCoordinates, KmlPolygon } from "./kmlDataModel";
import { contoursSimplifyingEps } from "src/CommonExportSettings";

const polylineArchetype = "polyline";

export function allocateGeometryRecursively(
    bim: Bim,
    projection: string,
    parseResult: KmlParseResult,
    elements: KmlElement[],
    parentId: IdBimScene
): { bimPatch: BimPatch; matixes: Map<IdBimScene, Matrix4> } | null {
    const bimPatch = new BimPatch();
    const positionPatches: Map<IdBimScene, Matrix4> = new Map();

    if (elements.length === 0) {
        return null;
    }

    for (const element of elements) {
        switch (element.type) {
            case 'folder':
                const folder = element as KmlFolder;
                if (!folder) {
                    break;
                }

                const folderInstanceId = bim.instances.reserveNewId();
                const folderInstance = new SceneInstance();
                folderInstance.name = folder.name;
                folderInstance.spatialParentId = parentId;
                bimPatch.instances.toAlloc.push([folderInstanceId, folderInstance]);

                const nestedPatch = allocateGeometryRecursively(
                    bim,
                    projection,
                    parseResult,
                    folder.children,
                    folderInstanceId
                );
                if (nestedPatch) {
                    bimPatch.mergeWith(nestedPatch.bimPatch);

                    for (const [i, m] of nestedPatch.matixes) {
                        positionPatches.set(i, m);
                    }
                }

                break;
            case 'polyline':
                const kmlPolyline = element as KmlPolyline;
                if (!kmlPolyline) {
                    break;
                }

                let color: string | undefined = undefined;
                const style = parseResult.styles[kmlPolyline.styleId ?? ''];
                if (style != null) {
                    color = style.lineColor;
                }

                const polylineCoordinates = projectCoordinates(projection, kmlPolyline.coordinates)
                const polylinePatch = allocatePolyline(bim, polylineCoordinates, parentId, color);

                if (polylinePatch) {
                    bimPatch.mergeWith(polylinePatch.bimPatch);

                    for(const[i,m] of polylinePatch.matixes){
                        positionPatches.set(i, m);
                    }
                }

                break;
            case 'polygon':
                const kmlPolygon = element as KmlPolygon;
                if (!kmlPolygon) {
                    break;
                }

                const polygonCoordinates = projectCoordinates(projection, kmlPolygon.coordinates);
                const polygonPatch = allocateBorder(bim, [polygonCoordinates], parentId);
                if (polygonPatch) {
                    bimPatch.mergeWith(polygonPatch.bimPatch);
                    
                    for(const[i,m] of polygonPatch.matixes){
                        positionPatches.set(i, m);
                    }
                }

                break;
            default:
                break;
        }
    }

    return { bimPatch: bimPatch, matixes: positionPatches };
}

export function allocatePolyline(
    bim: Bim,
    polyline: Vector3[],
    parentId: IdBimScene,
    color?: string
): { bimPatch: BimPatch; matixes: Map<IdBimScene, Matrix4> } | null {
    const bimPatch = new BimPatch();
    const positionPatches: Map<IdBimScene, Matrix4> = new Map();

    if (polyline.length < 1) {
        console.log("Line in the imported file has less then 2 points!")
        return null;
    }

    const instanceId = bim.instances.reserveNewId();
    const instance =
        bim.instances.archetypes.newDefaultInstanceForArchetype(
            polylineArchetype
        );

    const geometryId = bim.polylineGeometries.reserveNewId();

    const firstPoint = polyline[0];
    let points = polyline.map((b) =>
        b.clone().sub(firstPoint)
    );

    const m = new Matrix4().setPositionV(firstPoint)
    positionPatches.set(instanceId, m);

    const polylineGeometry = PolylineGeometry.newWithAutoIds(points);

    instance.representationAnalytical = new BasicAnalyticalRepresentation(
        geometryId
    );
    instance.spatialParentId = parentId;

    if (color != null) {
        const rgbaColor = RGBA.parseFromHexString(color);
        instance.colorTint = rgbaColor;
    }

    instance.name = "polyline"

    bimPatch.instances.toAlloc.push([instanceId, instance]);
    bimPatch.geometries.toAlloc.push([geometryId, polylineGeometry]);


    return { bimPatch: bimPatch, matixes: positionPatches };
}

export function allocateBorder(
    bim: Bim,
    borders: Vector3[][],
    parentId: IdBimScene
): { bimPatch: BimPatch; matixes: Map<IdBimScene, Matrix4> } {
    const bimPatch = new BimPatch();
    const positionPatches: Map<IdBimScene, Matrix4> = new Map();

    for (const border of borders) {
        const borderName = "KML border"
        const instanceId = bim.instances.reserveNewId();
        const instance =
            bim.instances.archetypes.newDefaultInstanceForArchetype("boundary");

        const extrudeId = bim.extrudedPolygonGeometries.reserveNewId();
        const localId = new LocalIdsCounter();

        const limits = Aabb.empty().setFromPoints(border).xy();
        const boundaryCenter = limits.getCenter();

        const m = new Matrix4().setPosition(boundaryCenter.x, boundaryCenter.y, 0);
        positionPatches.set(instanceId, m);

        let pts = border.map(
            (b) => new Vector2(b.x - boundaryCenter.x, b.y - boundaryCenter.y)
        );
        pts = PolygonUtils.simplifyContour(pts, contoursSimplifyingEps);

        const pointsIds = localId.newIdsArray(pts.length);

        const extrudePts = new Points2D(pts, pointsIds);
        const extrude = new ExtrudedPolygonGeometry(extrudePts);

        instance.representationAnalytical = new BasicAnalyticalRepresentation(
            extrudeId
        );
        instance.spatialParentId = parentId;

        instance.name = borderName;

        bimPatch.instances.toAlloc.push([instanceId, instance]);
        bimPatch.geometries.toAlloc.push([extrudeId, extrude]);
    }

    return { bimPatch: bimPatch, matixes: positionPatches };
}