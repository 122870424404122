import { Vector3 } from 'math-ts';
import type { WGSCoord } from './WGSCoord';
import type { ProjectionInfo } from './ProjectionInfo';


export class BimSceneOrigin {
    cartesianCoordsOrigin: Vector3;
    wgsProjectionOrigin: WgsProjectionOrigin | null;

    public constructor(
        civilCoord: Vector3 = new Vector3(),
        wgsCoord: WgsProjectionOrigin | null = null
    ) {
        this.cartesianCoordsOrigin = civilCoord;
        this.wgsProjectionOrigin = wgsCoord;
    }

    equals(origin: BimSceneOrigin) {
        return this.civilOriginEquals(origin) && this.wgsOriginEquals(origin);
    }

    civilOriginEquals(origin: BimSceneOrigin): boolean {
        return this.cartesianCoordsOrigin.equals(origin.cartesianCoordsOrigin);
    }

    wgsOriginEquals(origin: BimSceneOrigin) {
        let wgsEquality = false;

        if (
            this.wgsProjectionOrigin !== null &&
            origin.wgsProjectionOrigin !== null
        ) {
            wgsEquality = this.wgsProjectionOrigin.equals(
                origin.wgsProjectionOrigin
            );
        }
        return wgsEquality;
    }
}


export class WgsProjectionOrigin {
	public readonly projectionInfo: ProjectionInfo;
	public readonly wgsOriginLatLong: WGSCoord;
	public readonly wgsOriginCartesianCoords: Vector3;

	constructor (wgsCoordinates: WGSCoord, projectionInfo: ProjectionInfo, origin?: Vector3){
		this.wgsOriginLatLong = wgsCoordinates;
		this.projectionInfo = projectionInfo;
		if(origin!=null){
			this.wgsOriginCartesianCoords = origin;
		}else{
			this.wgsOriginCartesianCoords = Vector3.zero();
		}
		Object.freeze(this);
	}

	
	clone() {
		return new WgsProjectionOrigin(
			this.wgsOriginLatLong.clone(), 
			this.projectionInfo, 
			this.wgsOriginCartesianCoords.clone()
		);
	}
	equals(coord: WgsProjectionOrigin){
		return this.projectionInfo.equals(coord.projectionInfo)
		&& this.wgsOriginCartesianCoords.equals(coord.wgsOriginCartesianCoords) 
		&& this.wgsOriginLatLong.equals(coord.wgsOriginLatLong);
	}

}
