import { Aabb2, Matrix3, Vector2 } from "math-ts";
import { IterUtils } from 'engine-utils-ts';
import { Anchor2D, Object2D, VectorPrimitivePath, VectorPrimitivePathDescription, VectorPrimitiveRectangle, VectorPrimitiveText, createAabbLabel, createTextLabelWithPointer } from "vector-graphic";
import { createTreeDots } from 'vector-graphic';
import type { ModuleMatrixDescription, ModuleTableDescription } from "../types";
import { Anchors2D } from "vector-graphic";
import { blocksYOffset, helperLineWidth, normalTextFontSize, wireWidth } from "./config";
import { createManyToOneConnection } from "./connections";
import { threeDotSymbolTemplate } from "./common";


const fontSize = 20
const moduleWidth = 30
const moduleHeight = 40
const moduleHorOffset = 10
const moduleVertOffset = 10;

export function createModuleTable2D(tableDescription: ModuleTableDescription): ModuleTable2D {
    const table = new Anchor2D();
    const moduleMatrices: Anchors2D[] = [];
    for (const item of tableDescription.matrices) {
        const moduleMatrix = createModuleMatrix2D(item);
        if (!table.aabb.isEmpty()) {
            moduleMatrix.position.set(0, table.aabb.height() + moduleVertOffset*3)
            moduleMatrix.updateMatrix();
        }
        table.addAndExpandAabb(moduleMatrix);
        moduleMatrices.push(moduleMatrix);
    }

    // create table frame
    {
        const aabb = aabbReused.setFromPoints([
            { x: table.aabb.min.x - fontSize, y: table.aabb.min.y - moduleHeight / 2},
            { x: table.aabb.max.x + 3 * moduleWidth, y: table.aabb.max.y + moduleHeight / 2}
        ]);
        const frame = new VectorPrimitiveRectangle({
            height: aabb.height(),
            width: aabb.width(),
            cx: aabb.centerX(),
            cy: aabb.centerY(),
            strokeWidth: 5,
        })
        table.addPrimitiveAndExpandAabb(frame);
        table.point.set(frame.aabb.max.x - moduleWidth, frame.aabb.centerY());
    }


    // add bottom label
    {
        table.addPrimitiveAndExpandAabb(createAabbLabel({
            aabb: table.aabb,
            fontSize: fontSize,
            side: 'bottom',
            text: tableDescription.text
        }));
    }

    // add label on the left
    if (tableDescription.tableCount > 1) {
        table.addPrimitiveAndExpandAabb(createAabbLabel({
            aabb: table.aabb,
            fontSize: fontSize,
            side: 'left',
            text: `${tableDescription.tableCount} Tables`,
        }));
    }

    return {
        moduleMatrices,
        table
    };
}

export function createModuleTables2D(
    descriptions: ModuleTableDescription[],
    connectionType: CombinerBoxConnectionType,
): Anchors2D {
    const object = new Anchors2D()
    if (!descriptions.length) {
        return object;
    }

    const tables = descriptions.map(createModuleTable2D);

    const table2DObjects: Anchor2D[] = tables.map(x => x.table);

    // insert <...> when nessesary
    for (let idx = table2DObjects.length - 2; idx >= 0; idx--) {
        const currentTableDescription = descriptions[idx];
        const nextTableDescription = descriptions[idx + 1];
        const shouldAdd3DotSymbol =
            currentTableDescription.tableCount > 1 ||
            nextTableDescription.tableCount > 1;
        if (!shouldAdd3DotSymbol) {
            continue;
        }
        const threeDotSymbol = threeDotSymbolTemplate.clone()
        table2DObjects.splice(idx + 1, 0, threeDotSymbol);
    }

    object.addAndExpandAabb(
        Anchors2D.stackAnchorsAndMerge(table2DObjects, { yOffset: blocksYOffset })
    );

    const pairs: Array<[ModuleTable2D, ModuleTableDescription]> =
        IterUtils.map2(descriptions, tables, (a, b) => [b, a]);

    if (connectionType === 'multi') {
        createModuleTableMultiLineConnection(pairs, object);
    } else {
        createModuleTableSingleLineConnection(pairs, object);
    }

    return object
}

export interface ModuleTable2D {
    table: Anchor2D
    moduleMatrices: Anchors2D[]
}


function createModuleMatrix2D(matrix: ModuleMatrixDescription) {
    const moduleMatrix = new Anchors2D();
    const stringCount = matrix.stringCount;
    const modulesInStringCount = matrix.modulesInString
    // add first row
    {
        const row1 = createModuleRow(0, 0, modulesInStringCount);
        moduleMatrix.addAndExpandAabb(row1);
        moduleMatrix.points.push(...row1.points);
    }

    if (stringCount === 2) {
        const offset = (moduleHeight + moduleVertOffset) * 1
        const row2 = createModuleRow(0, offset, modulesInStringCount);
        moduleMatrix.addAndExpandAabb(row2);
        moduleMatrix.points.push(...row2.points);
    } else if (stringCount > 2) {
        const expandLenght = moduleHeight / 5 * 2;
        const expandSymbol = createTreeDots({
            vertical: true,
            length: expandLenght,
            x: 0,
            y: moduleHeight / 2 + moduleVertOffset + expandLenght / 2,
        })
        const row2 = createModuleRow(
            0,
            moduleHeight + expandLenght + moduleVertOffset * 2,
            modulesInStringCount
        );
        moduleMatrix.addAndExpandAabb(row2);
        moduleMatrix.points.push(...row2.points);
        moduleMatrix.addPrimitiveAndExpandAabb(...expandSymbol)
    }

    if (stringCount >= 3) {
        // add string count label
        moduleMatrix.addPrimitiveAndExpandAabb(createAabbLabel({
            aabb: moduleMatrix.aabb,
            fontSize: fontSize,
            side: 'left',
            text: `${stringCount} Strings`,
        }));
    }

    return moduleMatrix;
}

function createModuleRow(x: number, y: number, moduleCount: number): Anchors2D {
    const obj = new Anchors2D()
    const paths: VectorPrimitivePathDescription[] = []
    const stringExitPoint = new Vector2();

    // draw first module
    const first = createModulePrimitive(x, y, 1);
    paths.push(...first.contourPaths);
    obj.addPrimitiveAndExpandAabb(first.label);
    stringExitPoint.set(x + moduleWidth / 2, y);


    if (moduleCount >= 2) {
        // draw connection line
        {
            const pt1 = stringExitPoint.clone();
            const pt2 = new Vector2(pt1.x + moduleHorOffset, pt1.y)
            const moduleConnectionLine = new VectorPrimitivePathDescription([pt1, pt2])
            paths.push(moduleConnectionLine);
        }
        // draw second module
        {
            const offset = x + moduleWidth + moduleHorOffset;
            const second = createModulePrimitive(offset, y, 2);
            paths.push(...second.contourPaths);
            obj.addPrimitiveAndExpandAabb(second.label);
            stringExitPoint.set(offset + moduleWidth / 2, y);
        }
    }

    if (moduleCount === 3) {
        // draw connection line
        {
            const pt1 = stringExitPoint.clone();
            const pt2 = new Vector2(pt1.x + moduleHorOffset, pt1.y)
            const moduleConnectionLine = new VectorPrimitivePathDescription([pt1, pt2])
            paths.push(moduleConnectionLine);
        }
        // draw last module
        {
            const offset = x + (moduleWidth + moduleHorOffset) * 2;
            const module = createModulePrimitive(offset, y, moduleCount);
            paths.push(...module.contourPaths);
            obj.addPrimitiveAndExpandAabb(module.label);
            stringExitPoint.set(offset + moduleWidth / 2, y);
        }
    } else if (moduleCount > 3) {
        // draw hidden symbol
        {
            const offset = (moduleWidth + moduleHorOffset) * 2;
            const threeDotPrimitives = createTreeDots({
                length: moduleWidth / 3 * 2,
                x: offset,
                y
            });
            obj.addPrimitiveAndExpandAabb(...threeDotPrimitives);
        }
        // draw last module
        {
            const offset = x + (moduleWidth + moduleHorOffset) * 3;
            const module = createModulePrimitive(offset, y, moduleCount);
            paths.push(...module.contourPaths);
            obj.addPrimitiveAndExpandAabb(module.label);
            stringExitPoint.set(offset + moduleWidth / 2, y);
        }
    }

    obj.points = [stringExitPoint];

    obj.addPrimitiveAndExpandAabb(new VectorPrimitivePath({
        paths,
        strokeWidth: 2,
    }))

    obj.recalculateAabb(false, true);

    return obj;
}

function createModulePrimitive(x: number, y: number, moduleIndex: number): {
    contourPaths: VectorPrimitivePathDescription[],
    label: VectorPrimitiveText
} {
    const paths = [
        new VectorPrimitivePathDescription([
            new Vector2(x - moduleWidth / 2, y - moduleHeight / 2),
            new Vector2(x + moduleWidth / 2, y - moduleHeight / 2),
            new Vector2(x + moduleWidth / 2, y + moduleHeight / 2),
            new Vector2(x - moduleWidth / 2, y + moduleHeight / 2),
        ], true),
        new VectorPrimitivePathDescription([
            new Vector2(x - moduleWidth / 2, y - moduleHeight / 2),
            new Vector2(x, y - moduleHeight/2 + moduleWidth/2),
            new Vector2(x + moduleWidth / 2, y - moduleHeight / 2),
        ]),
    ]
    const text = new VectorPrimitiveText({
        fontSize,
        text: moduleIndex + '',
        anchor: 'middle',
        verticalAlignment: 'middle',
        x,
        y: y + moduleHeight/2 - (moduleHeight - moduleWidth/2) / 2,
    });
    return {
        contourPaths: paths,
        label: text
    };
}

export function createModuleTableMultiLineConnection(
    tables: Array<[ModuleTable2D, ModuleTableDescription]>,
    target: Anchors2D,
) {
    const outs: Vector2[] = [];
    for (const [{ moduleMatrices, table }, tableDesc] of tables) {
        const matricesOuts: Vector2[] = [];
        for (const moduleMatrix of moduleMatrices) {
            moduleMatrix.updateMatrix()
            const sources: Vector2[] = [];
            const worldMatrix = mat3Reused.copy(table.matrix).multiply(moduleMatrix.matrix)
            for (const point of moduleMatrix.points) {
                sources.push(point.clone().applyMatrix3(worldMatrix));
            }
            const matrixAabbWorld = aabbReused1.copy(moduleMatrix.aabb)
                .applyMatrix3(worldMatrix)
            const sourceX = sources[0].x;
            const wiring = createManyToOneConnection({
                sources,
                targetY: matrixAabbWorld.centerY(),
                targetX: 0,
                targetOffset: Math.abs(sourceX) - 20,
            })
            target.addAndExpandAabb(wiring)
            const out = wiring.points[0]
            matricesOuts.push(out);
            // add out connection label
            {
                const pt1 = new Vector2().copy(out);
                const tableAabb = aabbReused1.copy(table.aabb).applyMatrix3(table.matrix);
                const pt2 = new Vector2(tableAabb.max.x + 40, pt1.y)
                target.addPrimitiveAndExpandAabb(new VectorPrimitivePath({
                    paths: [new VectorPrimitivePathDescription([pt1, pt2])],
                    strokeWidth: wireWidth,
                }))
                outs.push(pt2);
                const connection = tableDesc.connectionToParent;
                if (connection) {
                    const outConnLabel = createTextLabelWithPointer({
                        fontSize: normalTextFontSize,
                        offset: 40,
                        pointerWidth: helperLineWidth,
                        text: connection.text,
                    })
                    outConnLabel.position.copy(pt2);
                    outConnLabel.updateMatrix();
                    target.addAndExpandAabb(outConnLabel);
                }
            }
        }
        {
            const pt = IterUtils.minBy(matricesOuts, pt => pt.y)!;
            const connection = tableDesc.matrices[0].connectionToParent;
            if (connection) {
                const outConnLabel = new Object2D([createTextLabelWithPointer({
                    fontSize: normalTextFontSize,
                    offset: 160,
                    pointerWidth: helperLineWidth,
                    text: connection.text
                })]);
                outConnLabel.position.copy(pt);
                outConnLabel.updateMatrix();
                target.add(outConnLabel);
                target.addPrimitiveAndExpandAabb(new VectorPrimitivePath({
                    paths: [new VectorPrimitivePathDescription(matricesOuts)],
                    strokeWidth: helperLineWidth,
                }))
            }
        }
    }
    target.points = outs;
}

export function createModuleTableSingleLineConnection(
    tables: Array<[ModuleTable2D, ModuleTableDescription]>,
    target: Anchors2D,
) {
    const betweenTableYs: number[] = []
    for (let i = 0; i < tables.length - 1; i++) {
        const [current] = tables[i];
        const [next] =  tables[i+1];
        const curMaxWorld = current.table.aabb.max.clone().applyMatrix3(current.table.matrix)
        const nextMinWorld = next.table.aabb.min.clone().applyMatrix3(next.table.matrix)
        const midY = 0.5 * (curMaxWorld.y + nextMinWorld.y);
        betweenTableYs.push(midY)
    }

    let middleBetweenTablesY = 0;
    if (betweenTableYs.length <= 1) {
        middleBetweenTablesY = target.aabb.centerY()
    } else {
        const idx = Math.max(
            0,
            Math.floor(betweenTableYs.length / 2) - 1
        );
        middleBetweenTablesY = betweenTableYs[idx]
    }
    let wiringMinY = Infinity;
    for (const [{ moduleMatrices, table }] of tables) {
        for (const moduleMatrix of moduleMatrices) {
            const matrixOuts: Vector2[] = []
            const worldMatrix = mat3Reused.copy(table.matrix).multiply(moduleMatrix.matrix)
            for (const point of moduleMatrix.points) {
                matrixOuts.push(point.clone().applyMatrix3(worldMatrix));
            }
            const wiring = createManyToOneConnection({
                sources: matrixOuts,
                targetY: middleBetweenTablesY,
                sourceOffset: 50,
                targetX: 0,
            })
            target.addAndExpandAabb(wiring);
            wiringMinY = Math.min(wiringMinY, wiring.aabb.min.y);
        }
    }

    // add out connection label
    {
        const pt1 = new Vector2(0, middleBetweenTablesY);
        const pt2 = new Vector2(target.aabb.max.x + 40, pt1.y)
        target.addPrimitiveAndExpandAabb(new VectorPrimitivePath({
            paths: [new VectorPrimitivePathDescription([pt1, pt2])],
            strokeWidth: wireWidth,
        }))
        target.points = [pt2.clone()];

        const connection = tables[0][1].connectionToParent;
        if (connection) {
            const outConnLabel = createTextLabelWithPointer({
                fontSize: normalTextFontSize,
                offset: 40,
                pointerWidth: helperLineWidth,
                text: connection.text,
            })
            outConnLabel.position.copy(pt2);
            outConnLabel.updateMatrix();
            target.addAndExpandAabb(outConnLabel);
        }
    }

    // add matrix out connection label
    const connection = tables[0]?.[1]?.matrices?.[0]?.connectionToParent;
    if (connection) {
        const label = new Object2D([createTextLabelWithPointer({
            fontSize: normalTextFontSize,
            offset: 80,
            pointerWidth: helperLineWidth,
            text: connection.text,
        })]);
        label.position.setY(wiringMinY)
        label.updateMatrix();
        target.add(label);
    }

}

export type CombinerBoxConnectionType = 'single' | 'multi';

const aabbReused = Aabb2.empty();
const aabbReused1 = Aabb2.empty();
const mat3Reused = new Matrix3();
