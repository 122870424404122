<svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
>
    <path
        d="M17.4997 4.62492C17.8447 4.34891 17.9007 3.84548 17.6247 3.50047C17.3487 3.15546 16.8452 3.09952 16.5002 3.37553L11.7194 7.20022H8.15136C7.89471 7.20022 7.6438 7.27619 7.43025 7.41856L2.55621 10.6679C2.18859 10.913 2.08925 11.4097 2.33433 11.7773C2.57942 12.1449 3.07611 12.2443 3.44374 11.9992L8.2422 8.80022H11.8246C12.1198 8.80022 12.4062 8.69976 12.6367 8.51535L17.4997 4.62492Z"
        fill="currentColor"
    />
    <path
        d="M17.8 8.84053C17.8 7.75047 16.5391 7.14444 15.6879 7.8254L12.7194 10.2002H9.1514C8.89475 10.2002 8.64384 10.2762 8.43029 10.4185L2.7789 14.1861C2.41724 14.4272 2.20001 14.8331 2.20001 15.2678V16.5004C2.20001 17.2184 2.78204 17.8004 3.50001 17.8004H16.5C17.218 17.8004 17.8 17.2184 17.8 16.5004V8.84053Z"
        fill="currentColor"
    />
</svg>
