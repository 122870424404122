<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.05425 3C5.36467 3 4.72373 3.35524 4.35825 3.94L2.304 7.2268C2.10534 7.54466 2 7.91196 2 8.2868V14.5001C2 14.7762 2.22386 15.0001 2.5 15.0001H8.5859L6.14647 12.5608C5.9512 12.3655 5.9512 12.0489 6.14645 11.8537L6.85355 11.1465C7.0488 10.9513 7.36539 10.9513 7.56065 11.1465L12.4142 15.9999L7.56067 20.8536C7.36541 21.0489 7.04883 21.0489 6.85356 20.8536L6.14644 20.1465C5.95117 19.9513 5.95116 19.6347 6.14642 19.4394L8.58566 17.0001H2.5C2.22386 17.0001 2 17.2239 2 17.5001V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V8.2868C22 7.91196 21.8947 7.54466 21.696 7.2268L19.6417 3.94C19.2763 3.35524 18.6353 3 17.9458 3H6.05425ZM6.05426 5H17.9458L19.1958 7H4.80426L6.05426 5Z"
        fill="currentColor"
    />
</svg>
