import { isValueAndUnit, type RowModel } from "./Models";

export type FilterModel = {
    [field: string]:
        | FilterSetType
        | FilterNumberType
        | FilterTextType
        | FilterNumberConditionType
        | FilterTextConditionType;
};

type FilterSetType = {
    filterType: "set";
    values: string[];
};

type FilterConditionType<T extends "number" | "text", TFilter  extends FilterNumberType | FilterTextType> = {
    filterType: T;
    operator: "OR" | "AND";
    condition1: TFilter;
    condition2: TFilter;
};

type FilterNumberConditionType = FilterConditionType<"number", FilterNumberType>;
type FilterTextConditionType = FilterConditionType<"text", FilterTextType>;

type FilterNumberType = {
    filterType: "number";
    type:
        | "equals"
        | "notEqual"
        | "greaterThan"
        | "greaterThanOrEqual"
        | "lessThan"
        | "lessThanOrEqual"
        | "inRange"
        | "blank"
        | "notBlank";
    filter: number;
    filterTo: number;
};

type FilterTextType = {
    filterType: "text";
    type:
        | "equals"
        | "notEqual"
        | "contains"
        | "notContains"
        | "startsWith"
        | "endsWith"
        | "blank"
        | "notBlank";
    filter: string;
};

export function filterList(data:RowModel[], filterModel:FilterModel|null|undefined) {
    const filterPresent = filterModel && Object.keys(filterModel).length > 0;
    if (!filterPresent) {
        return data;
    }
    const keys = Object.keys(filterModel);

    const resultOfFilter: RowModel[] = [];
    for (const item of data) {
        const isAddItem = filterFn(item, keys, filterModel);
        if (isAddItem) {
            resultOfFilter.push(item);
        }
    }

    return resultOfFilter;
}

function filterFn(item: RowModel, keys: string[], filterModel:FilterModel){
    let isAddItem = true;
    for (const fieldKey of keys) {
        let field = item[fieldKey];
        if (field == undefined) {
            isAddItem = false;
            break;
        }
        const value = isValueAndUnit(field) ? field.value : field;
        const filter = filterModel[fieldKey];
        const isUndefined = typeof value === 'undefined';
        if (filter.filterType === "set") {
            if (isUndefined) {
                continue;
            }
            const isFiltItem = filter.values.indexOf(value.toString()) < 0;
            if (isFiltItem) {
                isAddItem = false;
                break;
            }
        } else if (filter.filterType === "number" && (typeof value === "number" || isUndefined)) {
            const isFiltItem = createFilter(FilterNumber, value as number, filter);
            if (isFiltItem !== undefined && !isFiltItem) {
                isAddItem = false;
                break;
            }
        } else if (filter.filterType === "text" && (typeof value === "string" || isUndefined)) {
            const isFiltItem = createFilter(FilterText, value as string, filter);
            if (isFiltItem !== undefined && !isFiltItem) {
                isAddItem = false;
                break;
            }
        } else {
            console.error('unknown filter type: ' + JSON.stringify(filter), value);
        }
    }

    return isAddItem;
}

function createFilter<
    T extends "number" | "text",
    TValue extends number | string | undefined,
    TFilter extends FilterNumberType | FilterTextType
>(
    filterFn: (key: TValue, filter: TFilter) => boolean | undefined,
    key: TValue,
    item: FilterConditionType<T, TFilter> | TFilter
) {
    if ("operator" in item) {
        const condition1 = filterFn(key, item.condition1);
        const condition2 = filterFn(key, item.condition2);

        if (condition1 !== undefined && condition2 !== undefined) {
            if (item.operator === "AND") {
                return condition1 && condition2;
            } else {
                return condition1 || condition2;
            }
        } else {
            console.error('unknown number filter type: ' + JSON.stringify(item));
            return;
        }
    } else {
        return filterFn(key, item);
    }
}

function FilterNumber(key: number|undefined, item: FilterNumberType) {
    const isNumber = typeof key === "number";
    switch (item.type) {
        case "equals":
            return key === item.filter;
        case "notEqual":
            return key !== item.filter;
        case "greaterThan":
            return isNumber && key > item.filter;
        case "greaterThanOrEqual":
            return  isNumber && key >= item.filter;
        case "lessThan":
            return  isNumber && key < item.filter;
        case "lessThanOrEqual":
            return  isNumber && key <= item.filter;
        case "inRange":
            return  isNumber && key >= item.filter && key <= item.filterTo;
        case "blank":
            return !isNumber;
        case "notBlank":
            return isNumber;
        default:
            console.log("unknown number filter type: " + item.type);
            return;
    }
}

function FilterText(key: string | undefined, item: FilterTextType) {
    const isStr = typeof key === 'string';
    switch (item.type) {
        case "equals":
            return isStr && key === item.filter;
        case "notEqual":
            return isStr && key !== item.filter;
        case "contains":
            return isStr && key.includes(item.filter);
        case "notContains":
            return isStr && !key.includes(item.filter);
        case "startsWith":
            return isStr && key.startsWith(item.filter);
        case "endsWith":
            return isStr && key.endsWith(item.filter);
        case "blank":
            return !isStr || key.length === 0 || key.replace(/\s/g, '').length===0;
        case "notBlank":
            return isStr && key.length > 0 && key.replace(/\s/g, '').length!==0;
        default:
            console.log("unknown text filter type: " + item.type);
            return;
    }
}
