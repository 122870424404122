<script lang="ts">
</script>

<div class="icon-container">
    <img
        src="static/images/layout-help.png"
        alt="Layout geometry and offsets"
    />
</div>
<div class="content">
    <p>Notes:</p>
    <ul>
        <li>
            PVFarm applies 0% modules incline to calculate Glass to Glass and
            Ground Coverage Ratio
        </li>
        <li>
            Roads are always placed in the middle of Glass to Glass space and
            their width doesn't affect offsets calculations
        </li>
    </ul>
</div>

<style>
    .icon-container {
        margin-top: 8px;
    }
    .content {
        padding: 0 8px;
    }
</style>
