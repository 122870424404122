import { NumberProperty, StringProperty } from '../properties/PrimitiveProps';
import { PropsGroupBase } from '../properties/Props';
import { PropsGroupsRegistry } from '../properties/PropsGroupsRegistry';


export class ModuleDegradationProps extends PropsGroupBase {

    first_year_power_degradation: NumberProperty;
    power_degradation: NumberProperty;

    constructor(args: Partial<ModuleDegradationProps>) {
        super();
        this.first_year_power_degradation = args.first_year_power_degradation ?? NumberProperty.new({value: 0});
        this.power_degradation = args.power_degradation ?? NumberProperty.new({value: 0});
    }
}
PropsGroupsRegistry.register({
    class: ModuleDegradationProps,
    complexDefaults: {
    }
});

export class ModuleMountingProps extends PropsGroupBase {

    standard: StringProperty;

    constructor(args: Partial<ModuleMountingProps>) {
        super();
        this.standard = args.standard ?? StringProperty.new({value: ``});
    }
}
PropsGroupsRegistry.register({
    class: ModuleMountingProps,
    complexDefaults: {
    }
});

export class ModuleProps extends PropsGroupBase {

    width: NumberProperty;
    length: NumberProperty;

    manufacturer: StringProperty;
    model: StringProperty;

    technology: StringProperty;

    mounting: ModuleMountingProps;

    current: NumberProperty;
    voltage: NumberProperty;
    max_system_voltage: NumberProperty;
    maximum_power: NumberProperty;
    open_circuit_voltage: NumberProperty;
    short_circuit_current: NumberProperty;
    temp_coeff_current: NumberProperty;
    temp_coeff_power: NumberProperty;
    temp_coeff_voltage: NumberProperty;

    bifaciality_factor: NumberProperty;
    degradation: ModuleDegradationProps;

    constructor(args: Partial<ModuleProps>) {
        super();
        this.width = args.width ?? NumberProperty.new({value: 0, unit: 'm'});
        this.length = args.length ?? NumberProperty.new({value: 0, unit: 'm'});

        this.manufacturer = args.manufacturer ?? StringProperty.new({value: ``});
        this.model = args.model ?? StringProperty.new({value: ``});

        this.technology = args.technology ?? StringProperty.new({value: ``});
        this.mounting = args.mounting ?? new ModuleMountingProps({});
        
        this.current = args.current ?? NumberProperty.new({value: 0, unit: 'A'});
        this.voltage = args.voltage ?? NumberProperty.new({value: 0, unit: 'V'});
        this.max_system_voltage = args.max_system_voltage ?? NumberProperty.new({value:0, unit: 'V'});
        this.maximum_power = args.maximum_power ?? NumberProperty.new({value: 0, unit: 'W'});
        this.open_circuit_voltage = args.open_circuit_voltage ?? NumberProperty.new({value: 0, unit: 'V'});
        this.short_circuit_current = args.short_circuit_current ?? NumberProperty.new({value: 0, unit: 'A'});
        this.temp_coeff_current = args.temp_coeff_current ?? NumberProperty.new({value: 0});
        this.temp_coeff_power = args.temp_coeff_power ?? NumberProperty.new({value: 0});
        this.temp_coeff_voltage = args.temp_coeff_voltage ?? NumberProperty.new({value: 0});
        
        this.bifaciality_factor = args.bifaciality_factor ?? NumberProperty.new({value: 0});
        this.degradation = args.degradation ?? new ModuleDegradationProps({});
    }

    area() {
        return this.width.as('m') * this.length.as('m');
    }
}
PropsGroupsRegistry.register({
    class: ModuleProps,
    complexDefaults: {

    }
});
