import { createNotificationSource } from 'ui-bindings';

export const notificationSource = createNotificationSource({
    lowVoltage: {
        header: 'Generate Low Voltage circuit',
        description: ''
    },
    lowVoltageError: {
        header: 'Generate Low Voltage circuit failed',
        description: (message) => `${message}`
    },
    generateVisuals: {
        header: 'Generate visuals for Low Voltage',
        description: ''
    },
    generateVisualsError: {
        header: 'Generate visuals for Low Voltage failed',
        description: (message) => `${message}`,
    }
});