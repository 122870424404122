<script lang="ts">
    import { getContext } from "svelte";

    import type {
        Bim,
        EquipmentSlopeAnalysisPaletteConfig} from "bim-ts";
    import {
        EquipmentSlopeAnalysisPaletteMaxRowCount,
        EquipmentSlopeAnalysisType,
        generateEquipmentSlopeAnalysisConfigProps,
    } from "bim-ts";
    import {
        createLazyUiRowData,
        getProperties,
        getSlopesRange,
        patchConfig,
    } from "./EquipmentSlopeAnalysisData";
    import { TextField } from "../libui/fields";
    import { Immer, RGBA } from "engine-utils-ts";
    import ButtonComponent from "../libui/button/Button.svelte";

    import TableUi from "../grid-table-ui/TableUi.svelte";
    import { VersionedStore } from "../VersionedStore";
    import EngineUiPanel from '../ui-panels/EngineUiPanel.svelte';
    import { isInt } from "../terrain-analysis/TerrainAnalysisUiData";

    const bim: Bim = getContext("bim");
``
    const lazyTableData = createLazyUiRowData(bim);
    function getConfig(){
        const config = bim.configs.peekSingleton(EquipmentSlopeAnalysisType)!;
        return getProperties(config);
    }

    let rangeInput: string = "10";

    const lazyConfig = bim.configs.getLazySingletonOf({type_identifier: EquipmentSlopeAnalysisType});
    const configs = new VersionedStore(lazyConfig);

    $:if($configs){
        updateRange();
    }

    $: isValidRangeInput =
        isInt(rangeInput) &&
        parseInt(rangeInput) > 0 &&
        parseInt(rangeInput) <= EquipmentSlopeAnalysisPaletteMaxRowCount;

    $: if (rangeInput) {
        patchRange();
    }

    function updateRange(){
        const palette = getConfig();
        setRange(palette.slices.length);
    }

    function setRange(sliceCount:number){
        const strCount = sliceCount.toString();
        if(strCount !== rangeInput){
            rangeInput = strCount;
        }
    }

    function patchRange() {
        let paletteToUpdate = getConfig();
        if (!paletteToUpdate) {
            return;
        }

        if (
            isValidRangeInput &&
            paletteToUpdate.slices.length !== parseInt(rangeInput)
        ) {
            const newRange = parseInt(rangeInput);
            paletteToUpdate = Immer.produce(paletteToUpdate, (draft) => {
                if (draft.slices.length > newRange) {
                    draft.slices = draft.slices.slice(0, newRange);
                } else {
                    for (let i = draft.slices.length; i < newRange; i++) {
                        const lastRow = draft.slices[i - 1];
                        draft.slices[i] = {
                            min: lastRow.max.withDifferentValue(+lastRow.max.value),
                            max: lastRow.max.withDifferentValue(+(lastRow.max.value * 1.5).toFixed(2)),
                            color: lastRow.color.withDifferentValue(RGBA.newRGB(1,0,0)),
                        };
                    }
                }
            });


            paletteToUpdate = updateRanges(paletteToUpdate);
            paletteToUpdate = updateColors(paletteToUpdate);

            patchConfig(bim, {
                properties: paletteToUpdate,
            });
        }
    }

    function autoFillColors() {
        const palette = getConfig();
        const updatedPalette = updateColors(palette);

        patchConfig(bim, {
            properties: updatedPalette,
        });
    }

    function autoFillRanges() {
        const palette = getConfig();

        const updatedPalette = updateRanges(palette);

        patchConfig(bim, {
            properties: updatedPalette,
        });
    }

    function updateColors(palette: EquipmentSlopeAnalysisPaletteConfig){
        const samplePalette = generateEquipmentSlopeAnalysisConfigProps({
            paletteSize: palette.slices.length,
            slopeRangePercentagesExtents: [-10, 10],
        });
        const updatedPalette = Immer.produce(palette, (draft) => {
            for (let i = 0; i < draft.slices.length; ++i) {
                draft.slices[i].color = samplePalette.slices[i].color;
            }
        });
        return updatedPalette;
    }

    function updateRanges(palette: EquipmentSlopeAnalysisPaletteConfig){
        const sceneRange = getSlopesRange(bim);
        const samplePalette = generateEquipmentSlopeAnalysisConfigProps({
            paletteSize: palette.slices.length,
            slopeRangePercentagesExtents: sceneRange,
        });
        const paletteToUpdate = Immer.produce(palette, (draft) => {
            for (let i = 0; i < draft.slices.length; ++i) {
                draft.slices[i].min = samplePalette.slices[i].min;
                draft.slices[i].max = samplePalette.slices[i].max;
            }
        });
        return paletteToUpdate;
    }
</script>

<EngineUiPanel showPositionMenu={true}>
<div class="root">
    <span class="controls-group">
        <TextField
            labelText="Ranges Count"
            bind:value={rangeInput}
            bind:isValid={isValidRangeInput}
        />
    </span>
    <TableUi lazyTableData = {lazyTableData} />
    <span class="actions-group">
        <ButtonComponent
            data={{ label: "auto-fill ranges", onClick: autoFillRanges }}
        />
        <ButtonComponent
            data={{ label: "auto-fill colors", onClick: autoFillColors }}
        />
    </span>
</div>
</EngineUiPanel>

<style>
    .root {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .controls-group {
        padding: 5px;
        display: flex;
    }
    .actions-group {
        padding: 2px;
        display: flex;
        justify-content: center;
    }
</style>
