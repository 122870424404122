import { Vector3 } from 'math-ts';
import type { RayIntersection } from '../scene/Raycasts';

export abstract class GizmoIntersection implements RayIntersection {
	readonly point: Vector3;
	readonly distance: number;
	readonly normal: Vector3;

	constructor(point: Vector3, distance: number) {
		this.point = point.clone();
		this.distance = distance;
		this.normal = new Vector3(0, 1, 0);
	}
}
