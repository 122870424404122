import { UnitsMapper } from 'bim-ts';
import { TMY_ColumnDates } from 'bim-ts';
import { IterUtils, LegacyLogger } from 'engine-utils-ts';
import { DefaultMapObjectKey } from 'engine-utils-ts';
import { PUI_GroupNode, PUI_PropertyNodeString } from 'ui-bindings';
import { chooseRoundedChartMinMaxForNumbers, type EChartOptions } from 'ui-charts';

export interface TmyDataVizSettings {
    visualization_type: DataVizType;
    data_selector: string[];
}

export enum DataVizType {
    None,
    Chart,
    Heatmap,
    Heatmap_24_12,
    // SpreadSheet_24_12,
    RawData,
}

export interface TmySourceDataValues {
    get length(): number;
    at(index: number): number | string | undefined;
}
export interface TmySourceDataColumn {
    header: string;
    unit?: string;
    values: TmySourceDataValues;
}


export interface MeteoDataVizSourceData {
    dataColumns: TmySourceDataColumn[];
    dates: TMY_ColumnDates | null;
}

export type TmyDataVizType = Echart | TmyDataTableUi;
export class Echart {
    constructor(
        public readonly options: EChartOptions,
        public readonly heightPx: number,
    ) {
    }
}
export class TmyDataTableUi {
    constructor(
        public readonly rows: PUI_GroupNode[],
    ) {
    }
}

export function generateTmyDataVizs(
    visualization_type: DataVizType,
    sourceData: MeteoDataVizSourceData,
    unitsMapper: UnitsMapper,
    heightPerChart: number,
): TmyDataVizType[]{

        if (!(sourceData.dataColumns.length > 0)) { 
            return [];
        }

        return generateEchartsFor(
            sourceData.dataColumns,
            sourceData.dates,
            visualization_type,
            unitsMapper,
            heightPerChart
        );
}


export function generateEchartsFor(
    dataArrays: TmySourceDataColumn[],
    dates: TMY_ColumnDates | null,
    vizType: DataVizType,
    unitsMapper: UnitsMapper,
    heightPerChart: number,
): TmyDataVizType[] {
    const res: TmyDataVizType[] = [];
    if (vizType === DataVizType.None) {
        // nothing
    } else if (vizType === DataVizType.Chart) {
        const chart = generateMultichartForColumn({
            columns: dataArrays.map(c => createNumericDataColumnFrom(c, unitsMapper)),
            dates: dates,
            pixelsHeightPerChart: heightPerChart * 0.6 | 0,
            allowMultiseriesCharts: true,
        });
        res.push(chart);
    } else if (vizType === DataVizType.Heatmap) {
        for (const c of dataArrays) {
            const chart = generateHeatmapForColumn(
                c.header,
                createNumericDataColumnFrom(c, unitsMapper),
                dates,
                heightPerChart
            );
            res.push(chart);
        }
    } else if (vizType === DataVizType.Heatmap_24_12) {
        for (const c of dataArrays) {
            const chart = generateHeatmap24_12ForColumn(
                c.header,
                createNumericDataColumnFrom(c, unitsMapper),
                dates,
                heightPerChart
            );
            res.push(chart);
        }
    } else if (vizType === DataVizType.RawData) {
        const tableRows = generateTableFromColumns(dataArrays);
        res.push(new TmyDataTableUi(tableRows));
    } else {
        console.error("unexepected visualization_type", vizType);
    }
    return res;
}


function generateTableFromColumns(
    cols: TmySourceDataColumn[],
): PUI_GroupNode[] {
    const result: PUI_GroupNode[] = [];
    const count = Math.max(...cols.map(c => c.values.length));
    const columnsNames = cols.map(c => c.header.toString());
    for (let i = 0; i < count; ++i) {
        const row = new PUI_GroupNode({
            name: '',
            sortChildren: false,
        });
        row.addMaybeChild(new PUI_PropertyNodeString({
            name: 'index',
            onChange: emptyFn,
            value: i.toString(),
            readonly: true,
        }));
        for (let j = 0; j < cols.length; ++j) {
            const column = cols[j];
            let value: string;
            if (column instanceof TMY_ColumnDates) {
                const date = column.at(i);
                value = date ? TMY_ColumnDates.utcDateToString(date) : '';
            } else {
                value = column.values.at(i)?.toString() ?? '';
            }
            row.addMaybeChild(new PUI_PropertyNodeString({
                name: columnsNames[j],
                onChange: emptyFn,
                value: value ? value.toString() : '',
                readonly: true,
            }));
        }
        result.push(row);
    }
    return result;
}
const emptyFn = () => { };

export interface TmyDataVizNumericColumn {
    header: string;
    unit: string;
    values: number[];
    min: number;
    max: number;
}

function createNumericDataColumnFrom(genericDataColumn: TmySourceDataColumn, unitsMapper: UnitsMapper): TmyDataVizNumericColumn {
    const array = new Array<number>(genericDataColumn.values.length);

    const mapFromUnit = genericDataColumn.unit;
    let mapToUnit = mapFromUnit
        ? unitsMapper.mapUnitToConfigured(mapFromUnit)
        : null;
    if (mapToUnit === mapFromUnit) {
        mapToUnit = null;
    }

    let min: number = Infinity;
    let max: number = -Infinity;

    for (let i = 0; i < array.length; ++i) {
        let value = genericDataColumn.values.at(i) ?? '';
        if (typeof value !== 'number') {
            value = 0;
        } else if (mapToUnit) {
            value = UnitsMapper.mapTo({ value, unit: mapFromUnit }, mapToUnit);
        }
        value = Number.isNaN(value) ? 0 : value;
        min = Math.min(min, value);
        max = Math.max(max, value);
        array[i] = value;
    }

    return {
        header: genericDataColumn.header,
        unit: mapToUnit ?? mapFromUnit ?? '',
        values: array,
        min,
        max,
    };
}

export function generateMultichartForColumn({columns, dates, pixelsHeightPerChart, allowMultiseriesCharts}: {
    columns: TmyDataVizNumericColumn[],
    dates: TMY_ColumnDates | null,
    pixelsHeightPerChart: number,
    allowMultiseriesCharts: boolean,
}): Echart {

    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
    ];

    const dataCount = columns[0].values.length;

    const datesData: string[] = new Array(dataCount);
    for (let i = 0; i < dataCount; i++) {
        let dateStr: string;
        let dateUtc = dates?.at(i);
        if (dateUtc === undefined) {
            dateStr = i.toString();
        } else {
            const date = new Date(dateUtc);
            const monthStr = months[date.getUTCMonth()];
            const hour = date.getUTCHours().toString().padStart(2, '0');
            const min = date.getUTCMinutes().toString().padStart(2, '0');
            dateStr = `${monthStr} ${date.getUTCDate()} - ${hour}:${min}`;
        }
        datesData[i] = dateStr;
    }

    let chartIndexPerColumn: number[];
    if (allowMultiseriesCharts) {
       chartIndexPerColumn = [0];

        for (let i = 1; i < columns.length; ++i) {
            // try find previous column with same unit
    
            let chartIndex = -1;
            for (let j = 0; j < i; ++j) {
                const prevColumn = columns[j];
                if (prevColumn.unit === columns[i].unit) {
                    chartIndex = chartIndexPerColumn[j];
                    break;
                }
            }
    
            if (chartIndex < 0) {
                chartIndex = IterUtils.max(chartIndexPerColumn)! + 1;
            }
    
            chartIndexPerColumn.push(chartIndex);
        }
    } else {
        chartIndexPerColumn = IterUtils.newArray(columns.length, (i) => i);
    }

    const ChartsCount = IterUtils.max(chartIndexPerColumn)! + 1;

    console.assert(chartIndexPerColumn.length === columns.length, 'chartIndexPerColumn.length === columns.length');

    const ChartLabelHeight = 25;
    const ChartXAxisHeight = 35;

    const seriesCountsPerChart = IterUtils.newArray(ChartsCount, (i) => chartIndexPerColumn.filter(c => c === i).length);
    
    const totalGridSizes = seriesCountsPerChart.map((count) => {
        return ChartXAxisHeight + pixelsHeightPerChart + ChartLabelHeight * count;
    });
    const chartsGridOffsets: number[] = [];
    let cummulativeOffset = 0;
    for (const totalGridSize of totalGridSizes) {
        chartsGridOffsets.push(cummulativeOffset);
        cummulativeOffset += totalGridSize;
    }

    const TimelineHeight = 40;

    const totalHeight = cummulativeOffset + TimelineHeight;

    const addTimelineTools = datesData.length > 7*24;

    const options: EChartOptions = {
        color: ColorsPalette,
        tooltip: {
            renderMode: 'html',
            confine: true,
            trigger: 'axis',
            // position: function (pt) {
            //     return [pt[0], '10%'];
            // }
        },
        title: columns.map((c, colInd) => {
            const chartIndex = chartIndexPerColumn[colInd];
            const fontSize = 10;

            // const width = c.header.length * fontSize;
            const color = ColorsPalette[colInd % ColorsPalette.length];
            
            const chartOffset = chartsGridOffsets[chartIndex];
            let labelOffset = 0;
            for (let i = 0; i < colInd; ++i) {
                const chartIndexOfPrevCol = chartIndexPerColumn[i];
                if (chartIndexOfPrevCol === chartIndex) {
                    labelOffset += ChartLabelHeight;
                }
            }

            return {
                text: c.header,
                textAlign: 'left',
                left: 80,
                top: chartOffset + labelOffset,
                textStyle: {
                    fontSize,
                    // fontWeight: 'bold'
                },
                borderColor: color,
                borderWidth: 2,
                borderRadius: 2,
            }
        }),
        // legend: {
        //     selectedMode: false,
        // },
        toolbox: addTimelineTools ? {
            // orient: 'vertical',
            feature: {
                dataZoom: {
                    yAxisIndex: false,

                },
                restore: {},
            }
        } : undefined,
        grid: IterUtils.newArray(ChartsCount, (chartIndex) => {
            const chartGridOffset = chartsGridOffsets[chartIndex];
            const chartSize = totalGridSizes[chartIndex];
            const labelsSize = seriesCountsPerChart[chartIndex] * ChartLabelHeight;
            return {
                // gridIndex: i,
                left: 50,
                right: 30,
                height: chartSize - labelsSize - ChartXAxisHeight,
                top:chartGridOffset + labelsSize,
            }
        }),
        xAxis: IterUtils.newArray(ChartsCount, (chartIndex) => {
            return {
                gridIndex: chartIndex,
                type: 'category',
                data: datesData,
                min: 'dataMin',
                max: 'dataMax',
            }
        }),
        yAxis: IterUtils.newArray(ChartsCount, (chartIndex) => {
            let min: number = Infinity;
            let max: number = -Infinity;
            const columnsInThisChart = columns.filter((c, j) => chartIndexPerColumn[j] === chartIndex);
            for (const c of columnsInThisChart) {
                min = Math.min(min, c.min);
                max = Math.max(max, c.max);
            }
            const minMax = chooseRoundedChartMinMaxForNumbers({values: [min, max], preferZeroMin: true});
            return {
                gridIndex: chartIndex,
                type: 'value',
                min: minMax[0],
                max: minMax[1],
                name: columnsInThisChart[0].unit,
                // boundaryGap: false
            };
        }),
        dataZoom: addTimelineTools ? [
            {
                type: 'inside',
                xAxisIndex: IterUtils.newArrayWithIndices(0, columns.length),
                rangeMode: ['value', 'value']
                // zoomOnMouseWheel: 'ctrl',
            },
            {
                show: true,
                xAxisIndex: IterUtils.newArrayWithIndices(0, columns.length),
                type: 'slider',
            }
        ] : undefined,
        series: columns.map((column, columnIndex) => {
            return {
                label: {
                    show: true,
                },
                animation: false,
                name: column.header,
                type: 'line' as const,
                smooth: false,
                symbol: 'none',
                // areaStyle: {},
                data: column.values,
                xAxisIndex: chartIndexPerColumn[columnIndex],
                yAxisIndex: chartIndexPerColumn[columnIndex],
            }
        }),
    };

    if (dates) {
        options.dataZoomSourceObject = dates;
    }

    return new Echart(options, totalHeight);
}

const ColorsPalette = [
    '#4169e1',
    '#228b22',
    '#F99a00',
    '#aa0000',
    '#0000ee',
    '#00ee00',
    '#00eeee',
    '#f4a460',
    '#2f4f4f',
];


const monthsStrings = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
];

function generateHeatmapForColumn(
    header: string,
    column: TmyDataVizNumericColumn,
    dates: TMY_ColumnDates | null,
    pixelsHeightPerChart: number,
): Echart {

    const count = column.values.length;
    let min = Infinity;
    let max = -Infinity;
    const data = [];
    for (let i = 0; i < count; i++) {
        let dateStr: string | number;
        let dateUtc = dates?.at(i);
        if (dateUtc === undefined) {
            dateStr = i;
        } else {
            const date = new Date(dateUtc);
            const monthStr = monthsStrings[date.getUTCMonth()];
            const hour = date.getUTCHours().toString().padStart(2, '0');
            const min = date.getUTCMinutes().toString().padStart(2, '0');
            dateStr = `${monthStr} ${date.getUTCDate()} - ${hour}:${min}`;
        }
        const value = column.values[i];
        min = Math.min(min, value);
        max = Math.max(max, value);
        const hour = i % 24;
        const day = Math.floor(i / 24);
        data.push([day, hour, value]);
    }

    const options: EChartOptions = {
        title: {
            left: 'center',
            text: header,
        },
        tooltip: {},
        xAxis: {
            type: 'category',
            //   data: xData
        },
        yAxis: {
            type: 'category',
            //   data: yData
        },
        visualMap: {
            left: 'center',
            padding: 10,
            min: min,
            max: max,
            handleSize: '100%',
            calculable: true,
            realtime: true,
            range: [min, max],
            precision: 3,
            orient: 'horizontal',
            align: 'left',
            inRange: {
                color: [
                    '#313695',
                    '#4575b4',
                    '#74add1',
                    '#abd9e9',
                    '#e0f3f8',
                    '#ffffbf',
                    '#fee090',
                    '#fdae61',
                    '#f46d43',
                    '#d73027',
                    '#a50026'
                ]
            }
        },
        series: [
            {
                name: header,
                type: 'heatmap',
                data: data,
                emphasis: {
                    itemStyle: {
                        borderColor: '#333',
                        borderWidth: 1
                    }
                },
                progressive: 1000,
                animation: false,
                label: {
                    position: 'top'
                },
            }
        ]
    };
    return new Echart(options, pixelsHeightPerChart);
}


function generateHeatmap24_12ForColumn(
    header: string,
    column: TmyDataVizNumericColumn,
    dates: TMY_ColumnDates | null,
    pixelsHeightPerChart: number,
): Echart {

    const count = column.values.length;

    interface MonthHour {
        month: number;
        hour: number;
    }
    const perMonthHour = new DefaultMapObjectKey<MonthHour, number[]>({
        unique_hash: (key: MonthHour) => `${key.month}_${key.hour}`,
        valuesFactory: () => [],
    });
    for (let i = 0; i < count; i++) {

        let month: number;
        let hour: number;

        let dateUtc = dates?.at(i);
        if (dateUtc === undefined) {
            month = Math.min(Math.floor(i / (24 * (365/12))), 11);
            hour = i % 24;
        } else {
            month = dateUtc.getUTCMonth();
            hour = dateUtc.getUTCHours();
        }
        if (!(month >= 0 && month < 12)) {
            LegacyLogger.deferredError('unexpected month', month);
        }
        const valuesArr = perMonthHour.getOrCreate(Object.freeze({ month, hour }));
        const value = column.values[i];
        valuesArr.push(value);
    }

    const data = [];
    let min = Infinity;
    let max = -Infinity;
    for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
        const monthStr = monthsStrings[monthIndex];

        for (let hourIndex = 0; hourIndex <= 23; hourIndex++) {
            const hourString = hourIndex.toString().padStart(2, '0');

            const valuesArr = perMonthHour.getOrCreate(Object.freeze({ month: monthIndex, hour: hourIndex }));
            let value = valuesArr.reduce((a, b) => a + b, 0) / valuesArr.length;
            if (Number.isFinite(value)) {
                min = Math.min(min, value);
                max = Math.max(max, value);
                data.push([monthStr, hourString, value]);
            }
        }
    }

    const options: EChartOptions = {
        title: {
            left: 'center',
            text: header,
        },
        tooltip: {},
        xAxis: {
            type: 'category',
            //   data: xData
        },
        yAxis: {
            type: 'category',
            //   data: yData
        },
        visualMap: {
            left: 'center',
            padding: 10,
            min: min,
            max: max,
            handleSize: '100%',
            realtime: true,
            calculable: true,
            range: [min, max],
            precision: 3,
            orient: 'horizontal',
            align: 'left',
            inRange: {
                color: [
                    '#313695',
                    '#4575b4',
                    '#74add1',
                    '#abd9e9',
                    '#e0f3f8',
                    '#ffffbf',
                    '#fee090',
                    '#fdae61',
                    '#f46d43',
                    '#d73027',
                    '#a50026'
                ]
            }
        },
        series: [
            {
                name: header,
                type: 'heatmap',
                data: data,
                emphasis: {
                    itemStyle: {
                        borderColor: '#333',
                        borderWidth: 1
                    }
                },
                progressive: 1000,
                animation: false
            }
        ]
    };
    return new Echart(options, pixelsHeightPerChart);
}

