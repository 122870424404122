import type { TypedArray } from 'math-ts';
import type { PropValueTotalHash } from '../Props';
import { PropertyBase } from '../Props';
import { getPropertyReferenceId } from '../PropsRefsIds';
import { CompressibleNumbersArray, WorkerClassPassRegistry } from 'engine-utils-ts';

// export class CalculatedPropertyMetadata {
//     config_id: IdConfig | 0;
//     instance_id: IdBimScene | 0;
//     property_path: (string|number)[] | null;

//     constructor(args: Partial<CalculatedPropertyMetadata>){
//         this.config_id = args.config_id ?? 0;
//         this.instance_id = args.instance_id ?? 0;
//         this.property_path = args.property_path ?? null;
//     }
// }

export class NumbersArrayProperty extends PropertyBase {

    constructor(
        public readonly value: CompressibleNumbersArray,
    ) {
        super();
        Object.freeze(this);
    }

    uniqueValueHash(): PropValueTotalHash {
        return getPropertyReferenceId(this);
    }
    equals(other: PropertyBase): boolean {
        if (!(other instanceof NumbersArrayProperty)) {
            return false;
        }
        return this.value.equals(other.value);
    }

    public static newFromValues(
        unit: string,
        values: number[] | Float32Array | Float64Array,
    ) {
        const cv = CompressibleNumbersArray.newFromValues(unit, values);
        return new NumbersArrayProperty(cv);
    }

    public static newUncompressed(
        unit: string,
        values: number[] | TypedArray,
    ) {
        const cv = CompressibleNumbersArray.newFromValues(unit, values instanceof Float32Array ? values : new Float32Array(values));
        return new NumbersArrayProperty(cv);
    }

    at(index: number): number | undefined {
        return this.value.at(index);
    }

    get length(): number {
        return this.value.length;
    }

    get unit(): string {
        return this.value.unit;
    }
}
WorkerClassPassRegistry.registerClass(NumbersArrayProperty);
