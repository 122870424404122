<script lang="ts">
import {
    Bim,
    IdBimScene,
    generateSLDForSubstationSVG,
} from "bim-ts";
import { Failure, LazyDerived, LegacyLogger, Result, Success } from "engine-utils-ts";
import { VersionedStore } from "src/VersionedStore";
import FocusedContentViewer from "../../../libui/FocusedContentViewer.svelte";
import { getContext } from "svelte";
import { Expand } from "src/libui/icon/svg";
import { NotificationDescription, NotificationType, UiBindings } from "ui-bindings";
import { notificationSource } from "src/Notifications";

const bim = getContext<Bim>('bim');
const ui = getContext<UiBindings>('uiBindings');

const selectedSubstation = new VersionedStore(LazyDerived.new0<Result<IdBimScene>>(
    'selectedSubstation',
    [bim.instances],
    () => {
        const ids = bim.instances.getSelected()

        if (!ids) {
            return new Failure({msg: 'Select substation'});
        }

        if (ids.length !== 1) {
            return new Failure({msg: 'Select single substation'});
        }
        return new Success(ids[0]);
    }
).withoutEqCheck())


let innerHTML: Result<string> | undefined
function openImage(substationId: Result<IdBimScene>) {
    if (substationId instanceof Failure) {
        innerHTML = new Failure({ msg: substationId.msg });
        return
    }
    const svg = generateSLDForSubstationSVG(bim, substationId.value);
    if (svg instanceof Failure) {
        LegacyLogger.error('failed to substation sld', svg)
        ui.addNotification(NotificationDescription.newBasic({
            addToNotificationsLog: true,
            source: notificationSource,
            key: 'generatingSLD',
            type: NotificationType.Error,
            descriptionArg: ['Failed to generate SLD'],
        }))
        innerHTML = undefined
        return
    }
    innerHTML = new Success(svg.value.outerHTML);
}

function closeImage() {
    innerHTML = undefined;
}

let error: string | undefined;
$: if ($selectedSubstation instanceof Failure) {
    error = $selectedSubstation.msg;
} else if (innerHTML instanceof Failure) {
    error = innerHTML.msg;
} else {
    error = undefined
}

</script>

<div class="container" on:click={() => openImage($selectedSubstation)}>

    <div class="title-container">
        <span style="visibility: {error ? 'hidden' : 'visible'}">
            <Expand />
        </span>

        <p class="mdc-typography title">Single line diagram</p>
    </div>

    {#if error}
        <p class="mdc-typography error">{error}</p>
    {/if}

    {#if innerHTML instanceof Success}
        <FocusedContentViewer on:close={closeImage}>
            <div class="svg-holder">
                {@html innerHTML.value}
            </div>
        </FocusedContentViewer>
    {/if}
</div>

<style lang="scss">
.svg-holder {
    width: 100%;
    height: 100%;
    background: white;
}
.error {
    text-align: center;
    color: #ff0700;
}
.title-container {
    display: flex;
    gap: 4px;
}
.title {
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 0px;
}
.container {
    cursor: pointer;
    width: 100%;
    background-color: rgb(241, 241, 241);
    margin: 0 -8px 4px;
    padding: 4px 8px 1px;
}
</style>

