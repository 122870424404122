import type { Catalog, CatalogPropertyValue, SiteAreaSettings } from "bim-ts";
import { getAssets } from "../panels-config-ui/GeneratePanelUiBindings";
import { getInstances } from "./FarmLayoutUi";
import { notificationSource } from "../Notifications";

export class FarmLayoutValidation {
    static checkFarmConfig(catalog: Catalog, farmConfig: SiteAreaSettings) : string | undefined {
        const checkCombinerBoxes = this.checkCombinerBoxType(farmConfig.electrical.scheme.value, farmConfig.electrical.combiner_box.value, catalog);
        return checkCombinerBoxes;
    }

    static checkCombinerBoxType(scheme: string, combinerBoxes: CatalogPropertyValue[], catalog: Catalog) : string | undefined {
        const assets = getAssets(catalog);
        const instances = getInstances(assets, combinerBoxes);
        const combinerBoxesType = instances.map(c => c.properties.get('commercial | type')?.asText());
        const validType = scheme === 'SI_Multiharness' ? 'ac ' : 'dc ';
        const validation = combinerBoxesType.every(c => c?.toLocaleLowerCase().includes(validType));
        let errorMsg : string | undefined = undefined
        if(!validation && notificationSource.chooseCombinerBox.description instanceof Function){
            errorMsg = notificationSource.chooseCombinerBox.description(scheme);
        } else if(!validation){
            console.error('chooseCombinerBox notification not found');
        }
        return errorMsg;
    }

    static checkInvertorType(scheme: string, inverters: CatalogPropertyValue[], catalog: Catalog) : string | undefined {
        const assets = getAssets(catalog);
        const instances = getInstances(assets, inverters);
        const combinerBoxesType = instances.map(c => c.properties.get('commercial | model')?.asText());
        const validType = scheme === 'SI_Multiharness' ? 'string' : 'central';
        const validation = combinerBoxesType.every(c => c?.toLocaleLowerCase().includes(validType));
        let errorMsg : string | undefined = undefined;
        if(!validation && notificationSource.chooseInventer.description instanceof Function){
            errorMsg = notificationSource.chooseInventer.description(scheme);
        } else if(!validation){
            console.error('chooseInventer notification not found');
        }
        return errorMsg;
    }
}