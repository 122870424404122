<svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M1.84058 12.1116V10.9761H10.6427V12.1116H1.84058ZM13.3677 11.229L10.1221 7.98332L13.3511 4.75433L14.1634 5.56665L11.7467 7.98332L14.1801 10.4166L13.3677 11.229ZM1.84058 8.5344V7.3989H8.64274V8.5344H1.84058ZM1.84058 5.0239V3.8844H10.6427V5.0239H1.84058Z"
        fill="currentColor"
    />
</svg>
