<script lang="ts">
    import PUI_Root from '../pui/PUI_Root.svelte';
    import { createEnergyVizSettings, createEnergyVizSettingsUiGenerator } from './EnergyViewSettings';
    import { createEnergyViewDataVizGenerator } from './EnergyViewDataVizGenerator';
    import { VersionedStore } from '../VersionedStore';
    import { EnergyViewData, generateEnergyViewData } from './EnergyViewDataGenerator';
    import { PUI_Lazy, type PUI_ActionDescr, type UiBindings, DialogDescription, UiCustomNode } from 'ui-bindings';
    import { Echart, TmyDataTableUi, type TmyDataVizType } from '../tmy-data-viz-utils/TmyDataVizGenerators';
    import TableUi from '../grid-table-ui/TableUi.svelte';
    import AnyEchart from '../charts/AnyEchart.svelte';
    import { LazyBasic } from 'engine-utils-ts';
    import { createTotalEnergyShortStatsUi } from './TotalEnergyShortStatsUi';
    import EnergyReportChart from './chart/EnergyReportChart.svelte';
    import { TrackerShadingFactorsProperty, type Bim, type EnergyYieldPropsGroup } from 'bim-ts';
    import { getContext } from 'svelte';
    import ActionProperty from '../pui/ActionProperty.svelte';

    
    export let energyProps: EnergyYieldPropsGroup;
    
    const bim = getContext('bim') as Bim;
    const uiBindings: UiBindings = getContext("uiBindings");

    const vizSettings = createEnergyVizSettings();
    $: energyData = generateEnergyViewData(energyProps);

    const energyDataLazy = new LazyBasic('', new EnergyViewData());
    $: {
        energyDataLazy.replaceWith(energyData);
    }

    $: totalEnergyUi = createTotalEnergyShortStatsUi(energyData);

    const energyVizSettingsPui = createEnergyVizSettingsUiGenerator(vizSettings, energyDataLazy, 'Energy ');

    const HeightPerChart = 300;

    const energyVizsGenerator = createEnergyViewDataVizGenerator(
        vizSettings,
        energyDataLazy,
        bim.unitsMapper,
        HeightPerChart
    );

    $: visulalizations = new VersionedStore<TmyDataVizType[]>(energyVizsGenerator);

    const openShadingDataAction: PUI_ActionDescr<any> = {
        label: 'Shading Factors Weighted Average',
        action: () => {
            if (!energyProps.pipeline.shading_factors) {
                return;
            }
            const shadingProperty = new TrackerShadingFactorsProperty({
                value: energyProps.pipeline.shading_factors,
            })
            uiBindings.dialogsStream.pushNext(new DialogDescription({
                name: 'Shading Factors Weighted Average',
                context: shadingProperty,
                uiSource: new UiCustomNode({context: shadingProperty}),
            }));
        }
    }

</script>

<div class="root">

    <EnergyReportChart
        property = {energyProps.pipeline}
    ></EnergyReportChart>

    {#key totalEnergyUi}
        <PUI_Root
            configIdentifier={'total-energy-pui-root'}
            puiSource={new PUI_Lazy(new LazyBasic('', totalEnergyUi))}
        ></PUI_Root>
    {/key}
    

    {#if energyProps.pipeline.shading_factors}
        <div class="property-row">
            <span class="ui-config-property property-row buttons">
                <ActionProperty
                    actionDescr={openShadingDataAction}
                    context={null}
                ></ActionProperty>
            </span>
        </div>
    {/if}


    <PUI_Root
        configIdentifier={'meteo-viz-params'}
        puiSource={new PUI_Lazy(energyVizSettingsPui)}
    ></PUI_Root>

    {#each $visulalizations as viz (viz) }
        {#if viz instanceof TmyDataTableUi}
            <div class="chart" style="height: 300px;">
                <TableUi lazyTableData = {new LazyBasic('', viz.rows)} />
            </div>
        {:else if viz instanceof Echart}
            <div class="chart" style="height: {viz.heightPx}px;">
                <AnyEchart echartOptions = {viz.options} ></AnyEchart>
            </div>
        {/if}
    {/each}

</div>

<style lang="scss">
</style>