// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { FlatBuffersContourData } from '../flat-buffers/flat-buffers-contour-data.js';
import { FlatBuffersGeoPoint } from '../flat-buffers/flat-buffers-geo-point.js';
import { FlatBuffersRoad } from '../flat-buffers/flat-buffers-road.js';
import { FlatBuffersSolarMountingStructure } from '../flat-buffers/flat-buffers-solar-mounting-structure.js';
import { FlatBuffersSurfaceModel } from '../flat-buffers/flat-buffers-surface-model.js';
import { FlatBuffersTransformer } from '../flat-buffers/flat-buffers-transformer.js';


export class FlatBuffersSiteModel {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):FlatBuffersSiteModel {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsFlatBuffersSiteModel(bb:flatbuffers.ByteBuffer, obj?:FlatBuffersSiteModel):FlatBuffersSiteModel {
  return (obj || new FlatBuffersSiteModel()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsFlatBuffersSiteModel(bb:flatbuffers.ByteBuffer, obj?:FlatBuffersSiteModel):FlatBuffersSiteModel {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new FlatBuffersSiteModel()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

trackers(index: number, obj?:FlatBuffersSolarMountingStructure):FlatBuffersSolarMountingStructure|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new FlatBuffersSolarMountingStructure()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

trackersLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

roads(index: number, obj?:FlatBuffersRoad):FlatBuffersRoad|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new FlatBuffersRoad()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

roadsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

transformers(index: number, obj?:FlatBuffersTransformer):FlatBuffersTransformer|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new FlatBuffersTransformer()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

transformersLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

contour3d(index: number, obj?:FlatBuffersContourData):FlatBuffersContourData|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new FlatBuffersContourData()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

contour3dLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

geodata(obj?:FlatBuffersGeoPoint):FlatBuffersGeoPoint|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new FlatBuffersGeoPoint()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

pluginVersion():string|null
pluginVersion(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
pluginVersion(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

surfaces(index: number, obj?:FlatBuffersSurfaceModel):FlatBuffersSurfaceModel|null {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? (obj || new FlatBuffersSurfaceModel()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

surfacesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startFlatBuffersSiteModel(builder:flatbuffers.Builder) {
  builder.startObject(7);
}

static addTrackers(builder:flatbuffers.Builder, trackersOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, trackersOffset, 0);
}

static createTrackersVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startTrackersVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addRoads(builder:flatbuffers.Builder, roadsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, roadsOffset, 0);
}

static createRoadsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startRoadsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addTransformers(builder:flatbuffers.Builder, transformersOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, transformersOffset, 0);
}

static createTransformersVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startTransformersVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addContour3d(builder:flatbuffers.Builder, contour3dOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, contour3dOffset, 0);
}

static createContour3dVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startContour3dVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addGeodata(builder:flatbuffers.Builder, geodataOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, geodataOffset, 0);
}

static addPluginVersion(builder:flatbuffers.Builder, pluginVersionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, pluginVersionOffset, 0);
}

static addSurfaces(builder:flatbuffers.Builder, surfacesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(6, surfacesOffset, 0);
}

static createSurfacesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startSurfacesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endFlatBuffersSiteModel(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishFlatBuffersSiteModelBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset);
}

static finishSizePrefixedFlatBuffersSiteModelBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, undefined, true);
}

}
