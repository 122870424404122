import type { RGBAHex } from "engine-utils-ts";
import { DefaultMap, RGBA } from "engine-utils-ts";

export interface AutocacPaletteColor {
    paletteIndex: number,
    colorHex: string;
}

export function getClosestColorFromAutocadPalette(color: RGBAHex): AutocacPaletteColor {
    return autocadColorsMappping.getOrCreate(color);
}

const autocadColorsMappping = new DefaultMap<RGBAHex, AutocacPaletteColor>(
    (sourceColor) => {
        const sourceColorAsVector = RGBA.RGBAHexToVec4(sourceColor); 
        let minDistanceYet = Infinity;
        let resultColor = autocadPalette[0];
        for (const color of autocadPalette) {
            const autocadColorAsRGBA = RGBA.parseFromHexString(color.colorHex);
            const autocadColorAsVector = RGBA.RGBAHexToVec4(autocadColorAsRGBA);
            
            // calc colors distance as simple euiclidian distance (squared)
            // not the most accurate way to compare colors, but good enough for now
            const dx = sourceColorAsVector.x - autocadColorAsVector.x;
            const dy = sourceColorAsVector.y - autocadColorAsVector.y;
            const dz = sourceColorAsVector.z - autocadColorAsVector.z;
            const colorsDistance = dx * dx + dy * dy + dz * dz;
            if (colorsDistance < minDistanceYet) {
                minDistanceYet = colorsDistance;
                resultColor = color;
            }
        }
        return resultColor;
    }
)

const autocadPalette: AutocacPaletteColor[] = [
    { paletteIndex: 0, colorHex: "#000000" },
    { paletteIndex: 1, colorHex: "#FF0000" },
    { paletteIndex: 2, colorHex: "#FFFF00" },
    { paletteIndex: 3, colorHex: "#00FF00" },
    { paletteIndex: 4, colorHex: "#00FFFF" },
    { paletteIndex: 5, colorHex: "#0000FF" },
    { paletteIndex: 6, colorHex: "#FF00FF" },
    { paletteIndex: 7, colorHex: "#FFFFFF" },
    { paletteIndex: 8, colorHex: "#414141" },
    { paletteIndex: 9, colorHex: "#808080" },
    { paletteIndex: 10, colorHex: "#FF0000" },
    { paletteIndex: 11, colorHex: "#FFAAAA" },
    { paletteIndex: 12, colorHex: "#BD0000" },
    { paletteIndex: 13, colorHex: "#BD7E7E" },
    { paletteIndex: 14, colorHex: "#810000" },
    { paletteIndex: 15, colorHex: "#815656" },
    { paletteIndex: 16, colorHex: "#680000" },
    { paletteIndex: 17, colorHex: "#684545" },
    { paletteIndex: 18, colorHex: "#4F0000" },
    { paletteIndex: 19, colorHex: "#4F3535" },
    { paletteIndex: 20, colorHex: "#FF3F00" },
    { paletteIndex: 21, colorHex: "#FFBFAA" },
    { paletteIndex: 22, colorHex: "#BD2E00" },
    { paletteIndex: 23, colorHex: "#BD8D7E" },
    { paletteIndex: 24, colorHex: "#811F00" },
    { paletteIndex: 25, colorHex: "#816056" },
    { paletteIndex: 26, colorHex: "#681900" },
    { paletteIndex: 27, colorHex: "#684E45" },
    { paletteIndex: 28, colorHex: "#4F1300" },
    { paletteIndex: 29, colorHex: "#4F3B35" },
    { paletteIndex: 30, colorHex: "#FF7F00" },
    { paletteIndex: 31, colorHex: "#FFD4AA" },
    { paletteIndex: 32, colorHex: "#BD5E00" },
    { paletteIndex: 33, colorHex: "#BD9D7E" },
    { paletteIndex: 34, colorHex: "#814000" },
    { paletteIndex: 35, colorHex: "#816B56" },
    { paletteIndex: 36, colorHex: "#683400" },
    { paletteIndex: 37, colorHex: "#685645" },
    { paletteIndex: 38, colorHex: "#4F2700" },
    { paletteIndex: 39, colorHex: "#4F4235" },
    { paletteIndex: 40, colorHex: "#FFBF00" },
    { paletteIndex: 41, colorHex: "#FFEAAA" },
    { paletteIndex: 42, colorHex: "#BD8D00" },
    { paletteIndex: 43, colorHex: "#BDAD7E" },
    { paletteIndex: 44, colorHex: "#816000" },
    { paletteIndex: 45, colorHex: "#817656" },
    { paletteIndex: 46, colorHex: "#684E00" },
    { paletteIndex: 47, colorHex: "#685F45" },
    { paletteIndex: 48, colorHex: "#4F3B00" },
    { paletteIndex: 49, colorHex: "#4F4935" },
    { paletteIndex: 50, colorHex: "#FFFF00" },
    { paletteIndex: 51, colorHex: "#FFFFAA" },
    { paletteIndex: 52, colorHex: "#BDBD00" },
    { paletteIndex: 53, colorHex: "#BDBD7E" },
    { paletteIndex: 54, colorHex: "#818100" },
    { paletteIndex: 55, colorHex: "#818156" },
    { paletteIndex: 56, colorHex: "#686800" },
    { paletteIndex: 57, colorHex: "#686845" },
    { paletteIndex: 58, colorHex: "#4F4F00" },
    { paletteIndex: 59, colorHex: "#4F4F35" },
    { paletteIndex: 60, colorHex: "#BFFF00" },
    { paletteIndex: 61, colorHex: "#EAFFAA" },
    { paletteIndex: 62, colorHex: "#8DBD00" },
    { paletteIndex: 63, colorHex: "#ADBD7E" },
    { paletteIndex: 64, colorHex: "#608100" },
    { paletteIndex: 65, colorHex: "#768156" },
    { paletteIndex: 66, colorHex: "#4E6800" },
    { paletteIndex: 67, colorHex: "#5F6845" },
    { paletteIndex: 68, colorHex: "#3B4F00" },
    { paletteIndex: 69, colorHex: "#494F35" },
    { paletteIndex: 70, colorHex: "#7FFF00" },
    { paletteIndex: 71, colorHex: "#D4FFAA" },
    { paletteIndex: 72, colorHex: "#5EBD00" },
    { paletteIndex: 73, colorHex: "#9DBD7E" },
    { paletteIndex: 74, colorHex: "#408100" },
    { paletteIndex: 75, colorHex: "#6B8156" },
    { paletteIndex: 76, colorHex: "#346800" },
    { paletteIndex: 77, colorHex: "#566845" },
    { paletteIndex: 78, colorHex: "#274F00" },
    { paletteIndex: 79, colorHex: "#424F35" },
    { paletteIndex: 80, colorHex: "#3FFF00" },
    { paletteIndex: 81, colorHex: "#BFFFAA" },
    { paletteIndex: 82, colorHex: "#2EBD00" },
    { paletteIndex: 83, colorHex: "#8DBD7E" },
    { paletteIndex: 84, colorHex: "#1F8100" },
    { paletteIndex: 85, colorHex: "#608156" },
    { paletteIndex: 86, colorHex: "#196800" },
    { paletteIndex: 87, colorHex: "#4E6845" },
    { paletteIndex: 88, colorHex: "#134F00" },
    { paletteIndex: 89, colorHex: "#3B4F35" },
    { paletteIndex: 90, colorHex: "#00FF00" },
    { paletteIndex: 91, colorHex: "#AAFFAA" },
    { paletteIndex: 92, colorHex: "#00BD00" },
    { paletteIndex: 93, colorHex: "#7EBD7E" },
    { paletteIndex: 94, colorHex: "#008100" },
    { paletteIndex: 95, colorHex: "#568156" },
    { paletteIndex: 96, colorHex: "#006800" },
    { paletteIndex: 97, colorHex: "#456845" },
    { paletteIndex: 98, colorHex: "#004F00" },
    { paletteIndex: 99, colorHex: "#354F35" },
    { paletteIndex: 100, colorHex: "#00FF3F" },
    { paletteIndex: 101, colorHex: "#AAFFBF" },
    { paletteIndex: 102, colorHex: "#00BD2E" },
    { paletteIndex: 103, colorHex: "#7EBD8D" },
    { paletteIndex: 104, colorHex: "#00811F" },
    { paletteIndex: 105, colorHex: "#568160" },
    { paletteIndex: 106, colorHex: "#006819" },
    { paletteIndex: 107, colorHex: "#45684E" },
    { paletteIndex: 108, colorHex: "#004F13" },
    { paletteIndex: 109, colorHex: "#354F3B" },
    { paletteIndex: 110, colorHex: "#00FF7F" },
    { paletteIndex: 111, colorHex: "#AAFFD4" },
    { paletteIndex: 112, colorHex: "#00BD5E" },
    { paletteIndex: 113, colorHex: "#7EBD9D" },
    { paletteIndex: 114, colorHex: "#008140" },
    { paletteIndex: 115, colorHex: "#56816B" },
    { paletteIndex: 116, colorHex: "#006834" },
    { paletteIndex: 117, colorHex: "#456856" },
    { paletteIndex: 118, colorHex: "#004F27" },
    { paletteIndex: 119, colorHex: "#354F42" },
    { paletteIndex: 120, colorHex: "#00FFBF" },
    { paletteIndex: 121, colorHex: "#AAFFEA" },
    { paletteIndex: 122, colorHex: "#00BD8D" },
    { paletteIndex: 123, colorHex: "#7EBDAD" },
    { paletteIndex: 124, colorHex: "#008160" },
    { paletteIndex: 125, colorHex: "#568176" },
    { paletteIndex: 126, colorHex: "#00684E" },
    { paletteIndex: 127, colorHex: "#45685F" },
    { paletteIndex: 128, colorHex: "#004F3B" },
    { paletteIndex: 129, colorHex: "#354F49" },
    { paletteIndex: 130, colorHex: "#00FFFF" },
    { paletteIndex: 131, colorHex: "#AAFFFF" },
    { paletteIndex: 132, colorHex: "#00BDBD" },
    { paletteIndex: 133, colorHex: "#7EBDBD" },
    { paletteIndex: 134, colorHex: "#008181" },
    { paletteIndex: 135, colorHex: "#568181" },
    { paletteIndex: 136, colorHex: "#006868" },
    { paletteIndex: 137, colorHex: "#456868" },
    { paletteIndex: 138, colorHex: "#004F4F" },
    { paletteIndex: 139, colorHex: "#354F4F" },
    { paletteIndex: 140, colorHex: "#00BFFF" },
    { paletteIndex: 141, colorHex: "#AAEAFF" },
    { paletteIndex: 142, colorHex: "#008DBD" },
    { paletteIndex: 143, colorHex: "#7EADBD" },
    { paletteIndex: 144, colorHex: "#006081" },
    { paletteIndex: 145, colorHex: "#567681" },
    { paletteIndex: 146, colorHex: "#004E68" },
    { paletteIndex: 147, colorHex: "#455F68" },
    { paletteIndex: 148, colorHex: "#003B4F" },
    { paletteIndex: 149, colorHex: "#35494F" },
    { paletteIndex: 150, colorHex: "#007FFF" },
    { paletteIndex: 151, colorHex: "#AAD4FF" },
    { paletteIndex: 152, colorHex: "#005EBD" },
    { paletteIndex: 153, colorHex: "#7E9DBD" },
    { paletteIndex: 154, colorHex: "#004081" },
    { paletteIndex: 155, colorHex: "#566B81" },
    { paletteIndex: 156, colorHex: "#003468" },
    { paletteIndex: 157, colorHex: "#455668" },
    { paletteIndex: 158, colorHex: "#00274F" },
    { paletteIndex: 159, colorHex: "#35424F" },
    { paletteIndex: 160, colorHex: "#003FFF" },
    { paletteIndex: 161, colorHex: "#AABFFF" },
    { paletteIndex: 162, colorHex: "#002EBD" },
    { paletteIndex: 163, colorHex: "#7E8DBD" },
    { paletteIndex: 164, colorHex: "#001F81" },
    { paletteIndex: 165, colorHex: "#566081" },
    { paletteIndex: 166, colorHex: "#001968" },
    { paletteIndex: 167, colorHex: "#454E68" },
    { paletteIndex: 168, colorHex: "#00134F" },
    { paletteIndex: 169, colorHex: "#353B4F" },
    { paletteIndex: 170, colorHex: "#0000FF" },
    { paletteIndex: 171, colorHex: "#AAAAFF" },
    { paletteIndex: 172, colorHex: "#0000BD" },
    { paletteIndex: 173, colorHex: "#7E7EBD" },
    { paletteIndex: 174, colorHex: "#000081" },
    { paletteIndex: 175, colorHex: "#565681" },
    { paletteIndex: 176, colorHex: "#000068" },
    { paletteIndex: 177, colorHex: "#454568" },
    { paletteIndex: 178, colorHex: "#00004F" },
    { paletteIndex: 179, colorHex: "#35354F" },
    { paletteIndex: 180, colorHex: "#3F00FF" },
    { paletteIndex: 181, colorHex: "#BFAAFF" },
    { paletteIndex: 182, colorHex: "#2E00BD" },
    { paletteIndex: 183, colorHex: "#8D7EBD" },
    { paletteIndex: 184, colorHex: "#1F0081" },
    { paletteIndex: 185, colorHex: "#605681" },
    { paletteIndex: 186, colorHex: "#190068" },
    { paletteIndex: 187, colorHex: "#4E4568" },
    { paletteIndex: 188, colorHex: "#13004F" },
    { paletteIndex: 189, colorHex: "#3B354F" },
    { paletteIndex: 190, colorHex: "#7F00FF" },
    { paletteIndex: 191, colorHex: "#D4AAFF" },
    { paletteIndex: 192, colorHex: "#5E00BD" },
    { paletteIndex: 193, colorHex: "#9D7EBD" },
    { paletteIndex: 194, colorHex: "#400081" },
    { paletteIndex: 195, colorHex: "#6B5681" },
    { paletteIndex: 196, colorHex: "#340068" },
    { paletteIndex: 197, colorHex: "#564568" },
    { paletteIndex: 198, colorHex: "#27004F" },
    { paletteIndex: 199, colorHex: "#42354F" },
    { paletteIndex: 200, colorHex: "#BF00FF" },
    { paletteIndex: 201, colorHex: "#EAAAFF" },
    { paletteIndex: 202, colorHex: "#8D00BD" },
    { paletteIndex: 203, colorHex: "#AD7EBD" },
    { paletteIndex: 204, colorHex: "#600081" },
    { paletteIndex: 205, colorHex: "#765681" },
    { paletteIndex: 206, colorHex: "#4E0068" },
    { paletteIndex: 207, colorHex: "#5F4568" },
    { paletteIndex: 208, colorHex: "#3B004F" },
    { paletteIndex: 209, colorHex: "#49354F" },
    { paletteIndex: 210, colorHex: "#FF00FF" },
    { paletteIndex: 211, colorHex: "#FFAAFF" },
    { paletteIndex: 212, colorHex: "#BD00BD" },
    { paletteIndex: 213, colorHex: "#BD7EBD" },
    { paletteIndex: 214, colorHex: "#810081" },
    { paletteIndex: 215, colorHex: "#815681" },
    { paletteIndex: 216, colorHex: "#680068" },
    { paletteIndex: 217, colorHex: "#684568" },
    { paletteIndex: 218, colorHex: "#4F004F" },
    { paletteIndex: 219, colorHex: "#4F354F" },
    { paletteIndex: 220, colorHex: "#FF00BF" },
    { paletteIndex: 221, colorHex: "#FFAAEA" },
    { paletteIndex: 222, colorHex: "#BD008D" },
    { paletteIndex: 223, colorHex: "#BD7EAD" },
    { paletteIndex: 224, colorHex: "#810060" },
    { paletteIndex: 225, colorHex: "#815676" },
    { paletteIndex: 226, colorHex: "#68004E" },
    { paletteIndex: 227, colorHex: "#68455F" },
    { paletteIndex: 228, colorHex: "#4F003B" },
    { paletteIndex: 229, colorHex: "#4F3549" },
    { paletteIndex: 230, colorHex: "#FF007F" },
    { paletteIndex: 231, colorHex: "#FFAAD4" },
    { paletteIndex: 232, colorHex: "#BD005E" },
    { paletteIndex: 233, colorHex: "#BD7E9D" },
    { paletteIndex: 234, colorHex: "#810040" },
    { paletteIndex: 235, colorHex: "#81566B" },
    { paletteIndex: 236, colorHex: "#680034" },
    { paletteIndex: 237, colorHex: "#684556" },
    { paletteIndex: 238, colorHex: "#4F0027" },
    { paletteIndex: 239, colorHex: "#4F3542" },
    { paletteIndex: 240, colorHex: "#FF003F" },
    { paletteIndex: 241, colorHex: "#FFAABF" },
    { paletteIndex: 242, colorHex: "#BD002E" },
    { paletteIndex: 243, colorHex: "#BD7E8D" },
    { paletteIndex: 244, colorHex: "#81001F" },
    { paletteIndex: 245, colorHex: "#815660" },
    { paletteIndex: 246, colorHex: "#680019" },
    { paletteIndex: 247, colorHex: "#68454E" },
    { paletteIndex: 248, colorHex: "#4F0013" },
    { paletteIndex: 249, colorHex: "#4F353B" },
    { paletteIndex: 250, colorHex: "#333333" },
    { paletteIndex: 251, colorHex: "#505050" },
    { paletteIndex: 252, colorHex: "#696969" },
    { paletteIndex: 253, colorHex: "#828282" },
    { paletteIndex: 254, colorHex: "#BEBEBE" },
    { paletteIndex: 255, colorHex: "#FFFFFF" }
]




