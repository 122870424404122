<script lang="ts">
    export let startEnergyLength: number;
    export let endEnergyLength: number;

    let barLength: number;

    $: startScale = (barLength * startEnergyLength) / 100 / 4;
    $: endScale =
        ((endEnergyLength - startEnergyLength) * barLength) / 100 / 140;
</script>

<div class="row">
    <div class="bar-name"></div>
    <div class="bar-data">
        <div
            class="transition"
            class:transition-empty={!endEnergyLength}
            bind:offsetWidth={barLength}
        >
            <div style="width: {startEnergyLength}%; flex-shrink: 0;">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style="transform-origin: left; transform: scaleX({startScale});"
                    width="4"
                    height="26"
                    viewBox="0 0 4 26"
                    fill="none"
                >
                    <path d="M0 9.5V0H4V26H0V17.5V9.5Z" fill="#e2f2d6" />
                </svg>
                <div class="transition-border">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2"
                        height="26"
                        viewBox="0 0 2 26"
                        fill="none"
                    >
                        <path
                            d="M1 0V9.5V17.5V26"
                            stroke="#4DAF00"
                            stroke-width="2"
                        />
                    </svg>
                </div>
            </div>
            <div style="margin-left: -1px;">
                <div
                    class="transition-end"
                    style="transform-origin: left; transform: scaleX({endScale});"
                >
                    <div style="position: relative; top: 1px;">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="138"
                            height="25"
                            viewBox="0 0 138 25"
                            fill="none"
                        >
                            <path
                                d="M130.463 16.0631L7.53701 8.93693C3.30568 8.69163 0 5.18877 0 0.950337V25H138V24.0497C138 19.8112 134.694 16.3084 130.463 16.0631Z"
                                fill="#e2f2d6"
                            />
                        </svg>
                    </div>
                    <div class="transition-border">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="140"
                            height="26"
                            viewBox="0 0 140 26"
                            fill="none"
                        >
                            <path
                                d="M1 0V1.95034C1 6.18877 4.30568 9.69163 8.53701 9.93693L131.463 17.0631C135.694 17.3084 139 20.8112 139 25.0497V26"
                                stroke="#4DAF00"
                                stroke-width="2"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<style lang="scss">
    .transition {
        position: relative;
        width: calc(100% - 58px);
        display: flex;
        overflow: hidden;
        height: 26px;
        margin-bottom: 4px;
    }
    .transition-empty {
        width: 0px;
        border-left: 1px solid rgba(77, 175, 0, 0.48);
    }
    .transition-end {
        position: relative;
        top: 0px;
    }
    .transition-border {
        position: absolute;
        top: 0px;
    }
    .row {
        padding: 0 16px;
        display: flex;
        gap: 4px;
    }
    .bar-name {
        flex: 3;
    }
    .bar-data {
        flex: 4;
    }
</style>
