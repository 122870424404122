import type { DefaultMap } from "engine-utils-ts";
import type { Aabb } from "math-ts";
import { Vector3 } from "math-ts";
import type { RepresentationBase } from "src/representation/Representations";
import type { IdBimScene, SceneInstance } from "src/scene/SceneInstances";
import { FixedTiltTypeIdent } from "src/archetypes/fixed-tilt/FixedTilt";
import { calcPilesIndices } from "./PilesIndicesCalculator";
import { calculateTrackerFlatRepr } from "src/anyTracker/AnyTrackerMeshgen";
import type { AnyTrackerProps } from "src/anyTracker/AnyTracker";
import { TrackerTypeIdent } from "../Tracker";
import type { InRowPositionPacked } from "./IndicesWithPositionPacker";


export const Types = ["any-tracker", TrackerTypeIdent, FixedTiltTypeIdent];

const AngleEpsilon = 0.001;


export interface Tracker {
    id: IdBimScene,
    position: number, 
    hBounds: number[], 
    maxOffset: number,
    piles: number[] | undefined,
}


function calcMaxSpaceInRow(
    fixedAngleGroups: Tracker[][], 
    approxMaxSpaceInRow?: number,
): number {
    
    let spaces = [];
    let minTrackerLength = Infinity;
    
    let i, j;
    for (const group of fixedAngleGroups) {
        i = 0;
        j = 1;

        while (j < group.length) {
            if (group[j].hBounds[1] - group[j].hBounds[0] < minTrackerLength) {
                minTrackerLength = group[j].hBounds[1] - group[j].hBounds[0];
            }

            if (group[j].position - group[i].position > group[j].maxOffset + group[i].maxOffset) {
                const row = group.slice(i, j)
                    .map(tr => { return { min: tr.hBounds[0], max: tr.hBounds[1] } } )
                    .sort((a, b) => a.min - b.min);

                for (i = 1; i < row.length; ++i) {
                    spaces.push(row[i].min - row[i-1].max);
                }

                i = j;
            }
            j++;
        }
        
        const row = group.slice(i, j)
            .map(tr => { return { min: tr.hBounds[0], max: tr.hBounds[1] } } )
            .sort((a, b) => a.min - b.min);

        for (i = 1; i < row.length; ++i) {
            spaces.push(row[i].min - row[i-1].max);
        }
    }

    spaces = spaces.filter(sp => sp < minTrackerLength);
    spaces.sort((a, b) => a - b);
    if (spaces[spaces.length - 1] - spaces[0] < 0.2) {
        if (approxMaxSpaceInRow !== undefined) {
            return approxMaxSpaceInRow;
        } else {
            return Infinity;
        }
    } else {
        let maxDiff = 0, result = 0;
        for (i = 1; i < spaces.length; ++i) {
            if (spaces[i] - spaces[i - 1] > maxDiff) {
                maxDiff = spaces[i] - spaces[i - 1];
                result = (spaces[i] + spaces[i - 1]) / 2;
            }
        }

        return result;
    }
}

function calcRowsFixedAngle(
    group: Tracker[],
    maxSpaceInRow: number,
    calculatePilesIndices: boolean,
): [IdBimScene, InRowPositionPacked[] | undefined][][] {
    const rows: Tracker[][] = [];
    
    let i = 0, j = 1, k;
    while (j < group.length) {
        if (group[j].position - group[i].position > group[j].maxOffset + group[i].maxOffset) {
            const row = group.slice(i, j).sort((a, b) => a.hBounds[0] - b.hBounds[0]);

            k = 0;
            for (i = 1; i < row.length; ++i) {
                if (row[i].hBounds[0] - row[i-1].hBounds[1] > maxSpaceInRow) {
                    rows.push(row.slice(k, i));
                    k = i;
                }
            }
            rows.push(row.slice(k, i));

            i = j;
        }
        j++;
    }
    
    const row = group.slice(i, j).sort((a, b) => a.hBounds[0] - b.hBounds[0]);

    k = 0;
    for (i = 1; i < row.length; ++i) {
        if (row[i].hBounds[0] - row[i-1].hBounds[1] > maxSpaceInRow) {
            rows.push(row.slice(k, i));
            k = i;
        }
    }
    rows.push(row.slice(k, i));

    if (calculatePilesIndices) {
        return calcPilesIndices(rows);
    } else {
        const result: [IdBimScene, InRowPositionPacked[] | undefined][][] = [];
        for (const row of rows) {
            result.push(row.map(tr => [tr.id, undefined]));
        }
        return result;
    }
}

function calcRows(
    trackers: ( Tracker & { angle: number } )[], 
    calculatePilesIndices: boolean,
    splitRowsBySpaces: boolean, 
    approxMaxSpaceInRow?: number,
): [IdBimScene, InRowPositionPacked[] | undefined][][][] {
    const fixedAngleGroups: Tracker[][] = [];

    let i = 0, j = 0;
    while (j < trackers.length) {
        if (trackers[j].angle - trackers[i].angle < AngleEpsilon) {
            j++;
        } else {
            const group = trackers.slice(i, j)
                .map(tr => ({ id: tr.id, position: tr.position, hBounds: tr.hBounds, maxOffset: tr.maxOffset, piles: tr.piles }))
                .sort((a, b) => a.position - b.position);
            fixedAngleGroups.push(group);
            
            i = j;
        }
    }
    const group = trackers.slice(i, j)
        .map(tr => ({ id: tr.id, position: tr.position, hBounds: tr.hBounds, maxOffset: tr.maxOffset, piles: tr.piles }))
        .sort((a, b) => a.position - b.position);
    fixedAngleGroups.push(group);

    let maxSpaceInRow = Infinity;
    if (splitRowsBySpaces) {
        maxSpaceInRow = calcMaxSpaceInRow(fixedAngleGroups, approxMaxSpaceInRow);
    }

    const rowsGroups: [IdBimScene, InRowPositionPacked[] | undefined][][][] = [];
    for (const group of fixedAngleGroups) {
        const rows = calcRowsFixedAngle(group, maxSpaceInRow, calculatePilesIndices);
        rowsGroups.push(rows);
    }

    return rowsGroups;
}

export function calculateRows(
    group: [IdBimScene, SceneInstance][],
    reprsBboxes: DefaultMap<RepresentationBase, Aabb>, 
    splitRowsBySpaces: boolean,
    approxMaxSpaceInRow?: number,
    calculatePilesIndices: boolean = false,
): [IdBimScene, InRowPositionPacked[] | undefined][][][] {
    if (group.length === 0) {
        return [];
    }

    const trackers: ( Tracker & { angle: number } )[] = [];
    const vec3 = new Vector3();
    let width: number, depth: number, angle: number, position: number, distance: number;
    let bbox: Aabb | undefined;
    let sinus: number, cosinus: number;
    let piles: number[] | undefined;
    for (const tracker of group) {
        const repr = tracker[1].representation ?? tracker[1].representationAnalytical;
        if (repr) {
            bbox = reprsBboxes.getOrCreate(repr);
        }

        if (bbox !== undefined) {
            if (bbox.width() > bbox.depth()) {
                vec3.set(-1, 0, 0);
                width = bbox.depth();
                depth = bbox.width();
            } else {
                vec3.set(0, -1, 0);
                width = bbox.width();
                depth = bbox.depth();
            }
            const vec = vec3.applyMatrix4Rotation(tracker[1].worldMatrix).xy();
            
            angle = vec.angle();

            const center = bbox.getCenter_t().applyMatrix4(tracker[1].worldMatrix).xy();
            sinus = vec.cross(center) / (vec.length() * center.length());
            cosinus = vec.dot(center) / (vec.length() * center.length());
            position = center.length() * sinus;
            distance = center.length() * cosinus;

            if (angle < Math.PI) {
                angle += Math.PI;
                position *= -1;
                distance *= -1;
            }

            if (calculatePilesIndices && tracker[1].type_identifier === "any-tracker") {
                const flatRepr = calculateTrackerFlatRepr(tracker[1].props as AnyTrackerProps, true);
                piles = flatRepr.piles_offsets;
                for (let i = 0; i < piles.length; ++i) {
                    piles[i] += distance;
                }
            } else {
                piles = undefined;
            }

            trackers.push({ 
                id: tracker[0], 
                angle: angle, 
                position: position, 
                hBounds: [distance - depth / 2, distance + depth / 2], 
                maxOffset: width / 2,
                piles: piles,
            });
        }
    }
    
    trackers.sort((a, b) => a.angle - b.angle);
    return calcRows(trackers, calculatePilesIndices, splitRowsBySpaces, approxMaxSpaceInRow);
}