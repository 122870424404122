import type { SceneInstance } from "bim-ts";
import type { Aabb, Vector2Like} from "math-ts";
import { Matrix4, PolygonUtils, Vector2, Vector3 } from "math-ts";

const FEET_TO_METERS = 0.3048;

export function equalPoints(p1: Vector2Like, p2: Vector2Like): boolean {
    return isEqual(p1.x, p2.x) && isEqual(p1.y, p2.y);
}

export function isEqual(value1: number, value2: number): boolean {
    const EPSILON: number = 1e-3;
    return Math.abs(value1 - value2) < EPSILON;
}

export function isPointInside(point: Vector2Like, polygon: Vector2Like[]): boolean {
    const x = point.x;
    const y = point.y;
    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i].x;
        const yi = polygon[i].y;
        const xj = polygon[j].x;
        const yj = polygon[j].y;

        const intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
    }

    return inside;
}

export function orderContour(contour: Vector2[]): Vector2[] {
    return PolygonUtils.isClockwiseInner(contour) ? contour.slice().reverse() : contour;
}

export function orderContours(contours: Vector2[][]): Vector2[][] {
    return contours.map(orderContour);
}

export function orderHole(hole: Vector2[]){
    return PolygonUtils.isClockwiseInner(hole) ? hole : hole.slice().reverse();
}

export function orderHoles(holes: Vector2[][]): Vector2[][] {
    return holes.map(orderHole);
}


export function calcLength(polyline: Vector2[] | Vector3[]): number {
    let totalLength = 0;
    for (let index = 0; index < polyline.length - 1; index++) {
        const stP = polyline[index];
        const enP = polyline[index + 1];
        if(stP instanceof Vector3 && enP instanceof Vector3){
            totalLength += Math.sqrt(Math.pow(stP.x - enP.x, 2) + Math.pow(stP.y - enP.y, 2) + Math.pow(stP.z - enP.z, 2));
        }else {
            totalLength += Math.sqrt(Math.pow(stP.x - enP.x, 2) + Math.pow(stP.y - enP.y, 2));
        }
    }
    return totalLength;
}

export function closestDistTo( [ start, end ]:[Readonly<Vector2>, Readonly<Vector2>], position: Readonly<Vector2>): number
{
    const stP = start.clone();
    const enP = end.clone();
    const pos = position.clone();
    const dir = new Vector2().subVectors(enP, stP).normalize();
    const vec = new Vector2().subVectors(pos, stP);
    let dotProduct = vec.dot(dir);
    if (dotProduct < 0)
    {
        dotProduct = 0;
    }

    const len = stP.distanceTo(enP);
    if (dotProduct > len)
    {
        dotProduct = len;
    }

    const alongVector = dir.multiplyScalar(dotProduct);
    const closestPoint = stP.clone().add(alongVector);
    return closestPoint.distanceTo(pos);
}

export function convertFeetToMetersNum(value: number): number {
    return value * FEET_TO_METERS;
}

export function mergeEdges<T extends { fst: number; snd: number }, TR>(
    edges: T[],
    equalsFn: (point: number, newEdge: T, edge: T) => boolean,
    factoryFn: (poly: number[], edge: T) => TR
): TR[] {
    const polylines: TR[] = [];
    if (edges.length === 0) {
        return polylines;
    }
    let allEdges: [number, T][] = edges.map((e, index) => {
        return [index, e];
    });
    let [id, newEdge]: [number, T] = allEdges.pop()!;
    let newPolyline: number[] = [newEdge.fst, newEdge.snd];
    while (allEdges.length > 0) {
        if (newPolyline.length === 0) {
            [id, newEdge] = allEdges.pop()!;
            newPolyline = [newEdge.fst, newEdge.snd];
        } else {
            const first = newPolyline[0];
            const last = newPolyline[newPolyline.length - 1];
            const next = allEdges.find(([_id, edge]) =>
                equalsFn(last, newEdge, edge)
            );
            const prev = allEdges.find(([_id, edge]) =>
                equalsFn(first, newEdge, edge)
            );
            if (next) {
                const [nextId, nextEdge] = next;
                const pos = nextEdge.fst === last ? nextEdge.snd : nextEdge.fst;
                newPolyline.push(pos);
                allEdges = allEdges.filter(([id, _edge]) => id !== nextId);
            }
            if (prev) {
                const [prevId, prevEdge] = prev;
                const pos =
                    prevEdge.fst === first ? prevEdge.snd : prevEdge.fst;
                newPolyline.unshift(pos);
                allEdges = allEdges.filter(([id, _edge]) => id !== prevId);
            }
            if (!next && !prev) {
                polylines.push(factoryFn(newPolyline, newEdge));
                newPolyline = [];
            }
        }
    }
    if (newPolyline.length > 0) {
        polylines.push(factoryFn(newPolyline, newEdge));
    }

    return polylines;
}

export function getTransformedBBox2AndCenter(aabb: Aabb, instance: SceneInstance):[Vector2[], Vector3]{
    const transformedBox2d = aabb.get2DCornersAtZ(0)
        .map(r => r.applyMatrix4(instance.worldMatrix))
        .map(p => p.xy());
    const center = aabb.getCenter_t().applyMatrix4(instance.worldMatrix);
    return [transformedBox2d, center];
}

export function getTransformedBBox2dAndCenterOfInstanceWithoutZRotation(instance: SceneInstance, aabb: Aabb): [Vector2[], Vector2] {
    const [bbox, center] = getTransformedBBox2AndCenter(aabb, instance);
    const rotatedCenter = center.applyMatrix4Rotation(new Matrix4().extractRotation(instance.worldMatrix).invert());
    return [bbox, new Vector2(rotatedCenter.x, rotatedCenter.y)];
}