import type { LazyVersioned, Result} from 'engine-utils-ts';
import { ObservableObject, ScopedLogger, Yield, VersionedInvalidator, Allocated, Deleted, ObjectUtils, DefaultMap, IterUtils, CompressibleNumbersArray, LegacyLogger, JobExecutor, WorkerPool, registerExecutor, PollablePromise, Failure } from 'engine-utils-ts';
import type { PUI_GroupNode } from 'ui-bindings';
import { InverterProps } from '../archetypes/Inverter/Inverter';
import type { Bim } from '../Bim';
import type { EntitiesCollectionUpdates} from '../collections/EntitiesCollectionUpdates';
import { EntitiesUpdated } from '../collections/EntitiesCollectionUpdates';
import { producePropsPatch } from '../properties/Props';
import type { GroupDescription, GroupIdent, GroupingSolver, GroupingSolverSettings } from '../runtime/BimCustomGroupedRuntime';
import { BimCustomGroupedRuntime } from '../runtime/BimCustomGroupedRuntime';
import type { SharedGlobalsInput } from '../runtime/RuntimeGlobals';
import type { IdBimScene, SceneInstance} from '../scene/SceneInstances';
import { SceneObjDiff } from '../scene/SceneObjDiff';
import { TrackerProps } from '../trackers/Tracker';
import { chooseInverterEfficiencyCoeffs } from './EnergyEquipmentGlobals';
import { EnergyPipelineProperty, EnergyPipelineStage, EnergyStagesNames, type EnergyStageName, } from './EnergyPipelineProperty';
import { EnergyPipelinesMerger } from './EnergyPipelinesMerger';
import { EnergyStageChartIIVV, type EnergyStageChart, EnergyStageChartIV, EnergyStageChartW } from './EnergyStageCharts';
import type { EnergyYieldPerStringProducer } from './EnergyYieldPerStringProducer';
import { EnergyYieldPropsGroup } from './EnergyYieldPropsGroup';
import { FixedTiltProps } from '../archetypes/fixed-tilt/FixedTilt';
import { Success, getResultValueOrThrow } from 'engine-utils-ts';
import { EnergyFailure, energySucess, type EnergyResult } from './EnergyResult';
import { EnergyStageSettingsConfig } from '..';
import { EnergyStageOverrideConfig, type EnergyStageCalcConfig, EnergyStageCalculateConfig } from './EnergyCalcConfig';
import { EnergyCalculationsEnabled } from './EnergyCalculationsEnabled';
import { AnyTrackerProps } from '../anyTracker/AnyTracker';

export function registerInverterEnergyYieldSolver(bim: Bim) {
    bim.customRuntimes.registerCustomRuntime(new BimCustomGroupedRuntime(
        bim.logger,
        'InverterTmyStats',
        new InvertersEnergySolver()
    ));
}

class InverterGroupIdent implements GroupIdent {

    public readonly inverterId: IdBimScene | 0;
    public readonly sortKey: string;
    
    constructor(
        inverterId: IdBimScene,
    ) {
        this.inverterId = inverterId;
        this.sortKey = inverterId.toString();
        Object.freeze(this);
    }

    uniqueHash(): string | number {
        return this.inverterId;
    }
}

interface TrackerInputsHash {
    readonly module_energy: string|number,
    readonly strings_count: number,
}

type PerInverterInputs = {
    circuit_nominal_power: number,
    Vmin:number,
    Vmax:number,
    MaxPower:number,
    MaxEfficiency:number,
    Efficiency:number,
    Efficiency_Standart: string,
}

class InverterGroupDescription implements GroupDescription<InverterGroupIdent> {

    constructor(
        public readonly ident: InverterGroupIdent,
        public readonly inverterProps: PerInverterInputs,
        public readonly trackersIds: IdBimScene[],
        public readonly trackersPropsHashes: TrackerInputsHash[],
        public readonly strings_mismatch_config: EnergyStageCalcConfig,
        public readonly dc_wiring_config: EnergyStageCalcConfig,
        public readonly clipping_config: EnergyStageCalcConfig,
        public readonly efficiency_config: EnergyStageCalcConfig,
        public readonly max_power_config: EnergyStageCalcConfig,
    ) {
        if (trackersIds.length !== trackersPropsHashes.length) {
            throw new Error('trackersIds.length !== trackersParamsVersions.length');
        }
    }

    *inputInstancesIds(): Iterable<IdBimScene> {
        // yield this.ident.inverterId;
        // yield* this.trackersIds;
    }
}


class InverterEnergySolverSettings implements GroupingSolverSettings {
    enabled: boolean = true;
    delaySeconds: number = 0.5;
}

const globalArgsSelector = {
    [EnergyCalculationsEnabled.name]: EnergyCalculationsEnabled,
    [EnergyStageSettingsConfig.name]: EnergyStageSettingsConfig,
};

export class InvertersEnergySolver implements GroupingSolver<
    InverterGroupIdent,
    InverterGroupDescription,
    EnergyYieldPropsGroup,
    InverterEnergySolverSettings,
    typeof globalArgsSelector
> {
    logger: ScopedLogger;
    name: string = 'inverters-energy-solver';
    executionOrder: number = 100;
    maxGroupsExecutionParallelism: number = 5;
    calculationsInvalidator: VersionedInvalidator;
    
    globalArgsSelector = globalArgsSelector;

    settings: ObservableObject<InverterEnergySolverSettings>;
    ui?: LazyVersioned<PUI_GroupNode> | undefined;
    
    alwaysPassAllInvalidationBimUpdates: boolean = true;

    private _perArrayHashes = new Map<IdBimScene, TrackerInputsHash | null>();
    private _invertersProps = new Map<IdBimScene, PerInverterInputs>();

    constructor(
    ) {
        this.logger = new ScopedLogger(this.name);
        this.calculationsInvalidator = new VersionedInvalidator();
        this.settings = new ObservableObject({
            identifier: this.name + '-settings',
            initialState: new InverterEnergySolverSettings(),
        });
    }

    invalidate(
        logger: ScopedLogger,
        bim: Bim,
        instancesUpdate: EntitiesCollectionUpdates<IdBimScene, SceneObjDiff>,
    ): void {
        const instances = bim.instances;
        if (instancesUpdate instanceof Allocated) {
            for (const id of instancesUpdate.ids) {
                const typeIdent = instances.peekTypeIdentOf(id);
                if (!typeIdent) {
                    continue;
                }
                if (typeIdent === 'tracker' || typeIdent === 'fixed-tilt' || typeIdent === 'any-tracker') {
                    this._checkModuleArrayInputsHash(id, instances.peekById(id)!);
                } else if (typeIdent === 'inverter') {
                    this._checkInverterInputsHash(id, instances.peekById(id)!);
                }
            }
        } else if (instancesUpdate instanceof EntitiesUpdated) {
            const relevantFlags = SceneObjDiff.NewProps | SceneObjDiff.SpatialDescendants | SceneObjDiff.LegacyProps;
            if ((instancesUpdate.allFlagsCombined & relevantFlags) === 0) {
                return;
            }
            for (let i = 0; i < instancesUpdate.ids.length; ++i) {
                const id = instancesUpdate.ids[i];
                const flags = instancesUpdate.diffs[i];
                if ((flags & relevantFlags) == 0) {
                    continue;
                }
                const typeIdent = instances.peekTypeIdentOf(id);
                if (!typeIdent) {
                    continue;
                }
                if ((typeIdent === 'tracker' || typeIdent === 'fixed-tilt' || typeIdent === 'any-tracker') && (flags & (SceneObjDiff.NewProps | SceneObjDiff.LegacyProps))) {
                    this._checkModuleArrayInputsHash(id, instances.peekById(id)!);
                } else if (typeIdent === 'inverter') {
                    if (flags & SceneObjDiff.LegacyProps) {
                        this._checkInverterInputsHash(id, instances.peekById(id)!);
                    }
                    if (flags & SceneObjDiff.SpatialDescendants) {
                        this.calculationsInvalidator.invalidate();
                    }
                }
            }

        } else if (instancesUpdate instanceof Deleted) {
            for (const id of instancesUpdate.ids) {
                if (this._perArrayHashes.delete(id) || this._invertersProps.delete(id)) {
                    this.calculationsInvalidator.invalidate();
                }
            }
        } else {
            logger.error('could not recognize update type');
        }
    }

    invalidateFromSharedDependenciesUpdates(ident: keyof typeof globalArgsSelector): void {
        this.calculationsInvalidator.invalidate();
    }

    *generateCalculationGroups(args: {
        logger: ScopedLogger;
        bim: Bim;
        globalArgs: SharedGlobalsInput<typeof globalArgsSelector>;
    }): Generator<Yield, InverterGroupDescription[], unknown> {

        const enabledRes = args.globalArgs[EnergyCalculationsEnabled.name] as Result<EnergyCalculationsEnabled>;
        const isEnabled = enabledRes instanceof Success && enabledRes.value;

        const settingsRes = args.globalArgs[EnergyStageSettingsConfig.name] as Result<EnergyStageSettingsConfig>;
        
        if (settingsRes instanceof Failure) {
            args.logger.error(`failed to get global args ${EnergyStageSettingsConfig.name}`, settingsRes);
            return [];
        }
        const perInverterGroups = new DefaultMap<IdBimScene, InverterGroupDescription | null>(
            (invId) => {
                let inverterParams = this._invertersProps.get(invId);
                if (inverterParams === undefined) {
                    this.logger.batchedError('unexpected abscence of inverter params', invId);
                    return null;
                }
                return new InverterGroupDescription(
                    new InverterGroupIdent(invId),
                    inverterParams,
                    [],
                    [],
                    settingsRes.value.strings_mismatch.calculationConfig(),
                    settingsRes.value.dc_wiring.calculationConfig(),
                    settingsRes.value.inverter_voltage_clipping.calculationConfig(),
                    settingsRes.value.inverter_efficiency.calculationConfig(),
                    settingsRes.value.inverter_power_clipping.calculationConfig(),
                );
            }
        );

        for (const invId of this._invertersProps.keys()) {
            perInverterGroups.getOrCreate(invId);
        }

        const isInverter = (id: IdBimScene): boolean => {
            return this._invertersProps.has(id);
        }
        const instances = args.bim.instances;

        if (isEnabled) {
            for (const [trackerId, trackerInputs] of this._perArrayHashes) {
                if (!trackerInputs) {
                    continue;
                }
                const inverterId = instances.spatialHierarchy.findAmongAscendandsOf(trackerId, isInverter);
                if (!inverterId) {
                    continue;
                }
                const gd = perInverterGroups.getOrCreate(inverterId);
                if (!gd) {
                    continue;
                }
                gd.trackersIds.push(trackerId);
                gd.trackersPropsHashes.push(trackerInputs);
    
                yield Yield.Asap;
            }
        }

        yield Yield.Asap;

        return IterUtils.filterMap(perInverterGroups.values(), gr => gr ? Object.freeze(gr): undefined);
    }

    *startGroupResultsCalculation(args: {
        logger: ScopedLogger;
        bim: Bim;
        groupDescription: InverterGroupDescription;
    }): Generator<Yield, EnergyYieldPropsGroup | Result<EnergyYieldPropsGroup>, unknown> {

        if (args.groupDescription.trackersIds.length === 0) {
            return new Failure({msg: 'no trackers in group'});
        }

        const instances = args.bim.instances;

        // let dates: TMY_ColumnDates | null = null;

        const strings_counts = new DefaultMap<EnergyYieldPerStringProducer, StringInputDescription>(
            (c) => ({sring_energy_calculator: c, strings_count: 0, trackers_ids: []})
        );

        for (let i = 0; i < args.groupDescription.trackersIds.length; ++i) {

            const arrayId = args.groupDescription.trackersIds[i];
            const arrayInstance = instances.peekById(arrayId);
            if (!arrayInstance) {
                this.logger.batchedError('unexpected abscence of tracker when calculating group', arrayId);
                continue;
            }
            const energy_per_string = (arrayInstance.props as TrackerProps | FixedTiltProps).energy_per_string;
            if (!energy_per_string) {
                this.logger.batchedError('unexpected abscence of tracker energy_yield_per_module when calculating group', arrayId);
                continue;
            }

            const trackerParams = args.groupDescription.trackersPropsHashes[i];

            const perString = strings_counts.getOrCreate(energy_per_string);
            perString.strings_count += trackerParams.strings_count;
            perString.trackers_ids.push(arrayId);
        }

        yield Yield.Asap;

        const inverterCalcArgs: PerInverterEnergyCalcArgs = {
            inverterParams: args.groupDescription.inverterProps,
            strings: Array.from(strings_counts.values()),
            strings_mismatch_config: args.groupDescription.strings_mismatch_config,
            dc_wiring_config: args.groupDescription.dc_wiring_config,
            clipping_config: args.groupDescription.clipping_config,
            efficiency_config: args.groupDescription.efficiency_config,
            max_power_config: args.groupDescription.max_power_config,
        };
        
        const resultPromise = WorkerPool.execute(InverterEnergyJobExecutor, inverterCalcArgs);

        const result = yield* PollablePromise.generatorWaitFor(resultPromise);
        
        return getResultValueOrThrow(result, 'inverter energy');
    };
    
    applyResultsToBim(args: {
        logger: ScopedLogger; 
        bim: Bim;
        groupDescription: InverterGroupDescription;
        groupCalcResults: Result<EnergyYieldPropsGroup>;
    }): void {
        const inverterId = args.groupDescription.ident.inverterId;
        const inverterInstance = args.bim.instances.peekById(
            inverterId
        );

        if (!inverterInstance) {
            this.logger.batchedError(
                'unexpected abscence of inverter in bim',
                inverterId
            );
            return;
        }

        const props = inverterInstance.propsAs(InverterProps);

        const propsPatch = producePropsPatch(props, (props) => {
            if (args.groupCalcResults instanceof Success) {
                props.energy = args.groupCalcResults.value;
            } else {
                props.energy = null;
            }
        });

        if (propsPatch) {
            args.bim.instances.applyPatches([
                [inverterId, {props: propsPatch}]
            ])
        };
    }


    private _checkModuleArrayInputsHash(id: IdBimScene, pv_array: SceneInstance) {
        const prevHash = this._perArrayHashes.get(id);
        let perTrackerHashes: TrackerInputsHash | null;
        if (pv_array.props instanceof FixedTiltProps) {
            const energy_yield_hash = pv_array.props.energy_per_string?.uniqueValueHash();
            const strings_count = pv_array.properties.get('dimensions | strings_count')?.asNumber() ?? 0;
            perTrackerHashes = (energy_yield_hash && strings_count) ? {
                module_energy: energy_yield_hash,
                strings_count,
            } : null;
        } else if (pv_array.props instanceof TrackerProps) {
            const energy_yield_hash = pv_array.props.energy_per_string?.uniqueValueHash();
            const strings_count = pv_array.properties.get('tracker-frame | dimensions | strings_count')?.asNumber() ?? 0;
            perTrackerHashes = (energy_yield_hash && strings_count) ? {
                module_energy: energy_yield_hash,
                strings_count,
            } : null;
        } else if (pv_array.props instanceof AnyTrackerProps) {
            const energy_yield_hash = pv_array.props.energy_per_string?.uniqueValueHash();
            const strings_count = pv_array.props.tracker_frame.dimensions.strings_count.value ?? 0;
            perTrackerHashes = (energy_yield_hash && strings_count) ? {
                module_energy: energy_yield_hash,
                strings_count,
            } : null;
        } else {
            this.logger.batchedError('unexpected props type on tracker instance', id);
            perTrackerHashes = null;
        }
        if (ObjectUtils.areObjectsEqual(perTrackerHashes, prevHash)) {
            return;
        }
        this._perArrayHashes.set(id, perTrackerHashes);
        this.calculationsInvalidator.invalidate();
        return perTrackerHashes;
    }

    private _checkInverterInputsHash(id: IdBimScene, inverter: SceneInstance) {
        const prevInputs = this._invertersProps.get(id);

        const euroEff = inverter.properties.getPropNumberAs('inverter | efficiency | euro_efficiency', 0, '');
        const cecEff = inverter.properties.getPropNumberAs('inverter | efficiency | cec_efficiency', 0, '');

        const eff = Math.max(euroEff, cecEff);
        let eff_standard: string;
        if (eff === euroEff) {
            eff_standard = 'euro';
        } else if (eff === cecEff) {
            eff_standard = 'cec';
        } else {
            eff_standard = 'unknown';
        }

        const interterInputs: PerInverterInputs = {
            circuit_nominal_power: inverter.properties.getPropNumberAs('circuit | aggregated_capacity | dc_power', 0, 'W'),
            Vmin: inverter.properties.getPropNumberAs('inverter | min_mppt_voltage', 0, 'V'),
            Vmax: inverter.properties.getPropNumberAs('inverter | max_mppt_voltage', 0, 'V'),
            MaxPower: inverter.properties.getPropNumberAs('inverter | max_power', 0, 'W'),
            Efficiency: eff,
            Efficiency_Standart: eff_standard, 
            MaxEfficiency: inverter.properties.getPropNumberAs('inverter | efficiency | max_efficiency', 0, ''),
        };

        if (ObjectUtils.areObjectsEqual(interterInputs, prevInputs)) {
            return;
        }
        this._invertersProps.set(id, interterInputs);
        this.calculationsInvalidator.invalidate();
        return interterInputs;
    }
}
    
class InverterEnergyJobExecutor extends JobExecutor<PerInverterEnergyCalcArgs, EnergyYieldPropsGroup> {

    execute(args: PerInverterEnergyCalcArgs): EnergyYieldPropsGroup | Generator<Yield, EnergyYieldPropsGroup, unknown> {
        return calculateInverterEnergy(args);
    }
    estimateTaskDurationMs(args: PerInverterEnergyCalcArgs): number {
        return 5 + args.strings.length * 5;
    }
}
registerExecutor(InverterEnergyJobExecutor);

// interface PerInverterInputs {
//     Vmin: number;
//     Vmax: number;
//     MaxPower: number;
//     MaxEfficiency: number;
//     Efficiency: number;
//     Efficiency_Standart: string;
// }

interface StringInputDescription {
    sring_energy_calculator : EnergyYieldPerStringProducer;
    strings_count: number;
    trackers_ids: IdBimScene[];
}

interface PerInverterEnergyCalcArgs {
    inverterParams: PerInverterInputs,
    strings: StringInputDescription[],
    dc_wiring_config: EnergyStageCalcConfig,
    strings_mismatch_config: EnergyStageCalcConfig,
    clipping_config: EnergyStageCalcConfig,
    efficiency_config: EnergyStageCalcConfig,
    max_power_config: EnergyStageCalcConfig,
}

const strings_energy_cache = new WeakMap<EnergyYieldPerStringProducer, EnergyYieldPropsGroup>()

function* calculateInverterEnergy(args: PerInverterEnergyCalcArgs): Generator<Yield, EnergyYieldPropsGroup> {

    const nominalPowerCombined = new Float32Array(365 * 24);
    const pipelineMerger = new EnergyPipelinesMerger();

    const TOO_HIGH_VOLTAGE = Infinity;

    const InverterImpSTC = new Float32Array(365 * 24);
    const InverterImp = new Float32Array(365 * 24);
    const InverterIsc = new Float32Array(365 * 24);
    const InverterVmp = new Float32Array(365 * 24).fill(TOO_HIGH_VOLTAGE);
    const InverterVoc = new Float32Array(365 * 24).fill(TOO_HIGH_VOLTAGE);

    yield Yield.Asap;

    let strings_mismatch_mult = 0;
    if (args.strings_mismatch_config instanceof EnergyStageOverrideConfig) {
        strings_mismatch_mult = args.strings_mismatch_config.multiplier;
    }
    for (const {sring_energy_calculator, strings_count, trackers_ids} of args.strings) {

        let stringEnergyPropsGroup: EnergyYieldPropsGroup | undefined = strings_energy_cache.get(sring_energy_calculator);

        if (!stringEnergyPropsGroup) {
            try {
                stringEnergyPropsGroup = yield* sring_energy_calculator._calculate();
                strings_energy_cache.set(sring_energy_calculator, stringEnergyPropsGroup);
            } catch (e) {
                LegacyLogger.deferredError('failed to calculate module energy when calculating group', e);
                continue;
            }
        } else {
            // console.log('string cache hit');
        }


        const lastStage = stringEnergyPropsGroup.pipeline.stages.at(-1);

        if (!(lastStage?.energy instanceof Success) || !(lastStage.energy.value instanceof EnergyStageChartIIVV)) {
            LegacyLogger.deferredError('unexpected last stage type', lastStage?.energy);
            continue;
        }
        const stringEnergy = lastStage.energy.value;

        const errorsMap = new Map<EnergyStageName, IdBimScene[]>();
        for (let stageI = EnergyStagesNames.indexOf('tilt'); stageI < EnergyStagesNames.length; ++stageI) {
            const stageName = EnergyStagesNames[stageI];
            if (stageName === 'strings_mismatch') {
                break;
            }
            errorsMap.set(stageName, trackers_ids);
        }

        pipelineMerger.mergeIn(stringEnergyPropsGroup.pipeline, strings_count, errorsMap);

        for (let i = 0; i < InverterImp.length; ++i) {
            const imp = stringEnergy.Imp.at(i)! * strings_count;
            const isc = stringEnergy.Isc.at(i)! * strings_count;
            const vmp = stringEnergy.Vmp.at(i)! * strings_mismatch_mult;
            const voc = stringEnergy.Voc.at(i)! * strings_mismatch_mult;

            InverterImp[i] += imp;
            InverterIsc[i] += isc;

            InverterImpSTC[i] += sring_energy_calculator.args.Impp_STC * strings_count;

            InverterVmp[i] = Math.min(InverterVmp[i], vmp);
            InverterVoc[i] = Math.min(InverterVoc[i], voc);
        }

        const nominalPower = stringEnergyPropsGroup.pipeline.nominal_power.toArray('W');

        for (let i = 0; i < nominalPowerCombined.length; ++i) {
            const power = nominalPower[i] * strings_count;
            nominalPowerCombined[i] += power;
        }
    }

    yield Yield.Asap;

    for (let i = 0; i < InverterVmp.length; ++i) {
        if (InverterVmp[i] === TOO_HIGH_VOLTAGE) {
            InverterVmp[i] = 0;
        }
        if (InverterVoc[i] === TOO_HIGH_VOLTAGE) {
            InverterVoc[i] = 0;
        }
    }
    
    const {stages, shaded_modules_area, shading_factors} = pipelineMerger.finish();

    yield Yield.Asap;

    stages.push(new EnergyPipelineStage(
        'strings_mismatch',
        energySucess(
            new EnergyStageChartIIVV(
                CompressibleNumbersArray.newFromValues('A', InverterImp),
                CompressibleNumbersArray.newFromValues('A', InverterIsc),
                CompressibleNumbersArray.newFromValues('V', InverterVmp),
                CompressibleNumbersArray.newFromValues('V', InverterVoc),
            ),
            checkConnectedStringsSimilarity(args.strings),
        ),
    ));


    if (args.dc_wiring_config instanceof EnergyStageOverrideConfig) {
        let dcWiringChart: EnergyResult<EnergyStageChart>;
        if (args.dc_wiring_config.multiplier != 1) {
            const dc_loss = 1 - args.dc_wiring_config.multiplier;
            for (let i = 0; i < InverterImp.length; ++i) {
                const Impp_STC = InverterImpSTC[i];
                const Imp = InverterImp[i];
                const k = Imp / Impp_STC;
                const lossMult = Math.max(0, 1 - k * k * dc_loss);
                InverterVmp[i] *= lossMult;
                InverterVoc[i] *= lossMult;
            }
        }
        dcWiringChart = energySucess(new EnergyStageChartIIVV(
            CompressibleNumbersArray.newFromValues('A', InverterImp),
            CompressibleNumbersArray.newFromValues('A', InverterIsc),
            CompressibleNumbersArray.newFromValues('V', InverterVmp),
            CompressibleNumbersArray.newFromValues('V', InverterVoc),
        ));
        stages.push(new EnergyPipelineStage(
            'dc_wiring',
            dcWiringChart,
        ));
    }

    
    yield Yield.Asap;

    const Vmin = args.inverterParams.Vmin;
    const Vmax = args.inverterParams.Vmax;;
    const Inverter_maxPower = args.inverterParams.MaxPower;
    const Inverter_maxEfficiency = args.inverterParams.MaxEfficiency;
    const Inverter_Efficiency = args.inverterParams.Efficiency;
    const Inverter_Standart_Efficiency = args.inverterParams.Efficiency_Standart;
    const Inverter_minCurrent = (1 - Inverter_maxEfficiency)*Inverter_maxPower/Vmax;

    let clippingResult: EnergyResult<EnergyStageChart>|null;
    let lastPowerValue: CompressibleNumbersArray;
    if (args.clipping_config instanceof EnergyStageCalculateConfig) {

        const afterClippingPowerFlat = new Float32Array(365 * 24);

        for (let i = 0; i < InverterImp.length; ++i) {
            let Vmp = InverterVmp[i];
            let Voc = InverterVoc[i];
            let Imp = InverterImp[i];
            let Isc = InverterIsc[i];
            
            if (Vmp < Vmin) {
                Imp = Imp - Vmp * Imp / (Vmp - Voc) + Vmin * Imp / (Vmp - Voc)
                Vmp = Vmin;
            } else if (Vmp > Vmax) {
                Imp =  Isc + Vmax * (Imp - Isc) / Vmp
                Vmp = Vmax;
            }

            if (Imp < Inverter_minCurrent) {
                Imp = 0;
                Vmp = 0;
            }

            afterClippingPowerFlat[i] = Vmp * Imp;
        }
        lastPowerValue = CompressibleNumbersArray.newFromValues('W', afterClippingPowerFlat);
        const clippingChart = new EnergyStageChartW(lastPowerValue);

        clippingResult = energySucess(clippingChart, checkStringsVoltage(args.inverterParams, args.strings));

    } else if (args.clipping_config instanceof EnergyStageOverrideConfig) {
        clippingResult = energySucess(new EnergyStageChartIV(
            CompressibleNumbersArray.newFromValues('A', InverterImp),
            CompressibleNumbersArray.newFromValues('V', InverterVmp)
        ));

        const multiplier = args.clipping_config.multiplier;

        const afterClippingPowerFlat = new Float32Array(365 * 24);
        for (let i = 0; i < InverterImp.length; ++i) {
            afterClippingPowerFlat[i] = InverterImp[i] * InverterVmp[i] * multiplier;
        }
        lastPowerValue = CompressibleNumbersArray.newFromValues('W', afterClippingPowerFlat);
        clippingResult = energySucess(new EnergyStageChartW(lastPowerValue));

    } else {
        clippingResult = null;
        lastPowerValue = CompressibleNumbersArray.newFromValues('W', InverterImp.map((imp, i) => imp * InverterVmp[i]));
    }

    if (clippingResult) {
        stages.push(new EnergyPipelineStage(
            'inverter_voltage_clipping',
            clippingResult,
        ));
    }


    let inverterEfficiencyChart: EnergyResult<EnergyStageChart>|null;
    const efficciencyCalcSettings = args.efficiency_config;

    if (efficciencyCalcSettings instanceof EnergyStageCalculateConfig) {
        const efficiencyCoeffs = chooseInverterEfficiencyCoeffs(Inverter_Standart_Efficiency, Inverter_maxEfficiency, Inverter_Efficiency);
        if (efficiencyCoeffs) {
            lastPowerValue = lastPowerValue.map(
                'W',
                (power) => {
                    let lossDueEfficiency = efficiencyCoeffs.Ltr*Inverter_maxPower
                    + efficiencyCoeffs.Ldr*power
                    + power * power/(efficiencyCoeffs.Lrr*Inverter_maxPower);
    
                    if (!Number.isFinite(lossDueEfficiency)) {
                        LegacyLogger.deferredWarn('lossDueEfficiency is not finite', lossDueEfficiency);
                        return power;
                    }
    
                    if (lossDueEfficiency > power) {
                        // LegacyLogger.deferredWarn('inveter efficiency loss is higher than power', [lossDueEfficiency, power]);
                        lossDueEfficiency = power;
                    } else if (lossDueEfficiency < 0) {
                        // LegacyLogger.deferredWarn('inveter efficiency loss is lower than 0', lossDueEfficiency);
                        lossDueEfficiency = 0;
                    }
                    return power - lossDueEfficiency;
                }
            )
            inverterEfficiencyChart = energySucess(new EnergyStageChartW(lastPowerValue));
        } else {
            inverterEfficiencyChart = EnergyFailure.new('UnexpectedInverterEffStd');
        }

    } else if (efficciencyCalcSettings instanceof EnergyStageOverrideConfig) {
        lastPowerValue = lastPowerValue.map(
            'W',
            (power) => power * efficciencyCalcSettings.multiplier
        )
        inverterEfficiencyChart = energySucess(new EnergyStageChartW(lastPowerValue));
    } else {
        inverterEfficiencyChart = null;
    }

    if (inverterEfficiencyChart) {
        stages.push(new EnergyPipelineStage(
            'inverter_efficiency',
            inverterEfficiencyChart
        ));
    }


    let inverterMaxPowerChart: EnergyResult<EnergyStageChart>|null;

    const maxPowerCalcSettings = args.max_power_config;
    if (maxPowerCalcSettings instanceof EnergyStageCalculateConfig) {
        lastPowerValue = lastPowerValue.map('W', p => p > Inverter_maxPower ? Inverter_maxPower : p);
        inverterMaxPowerChart = energySucess(new EnergyStageChartW(lastPowerValue));
    } else if (maxPowerCalcSettings instanceof EnergyStageOverrideConfig) {
        lastPowerValue = lastPowerValue.map('W', p => p * maxPowerCalcSettings.multiplier);
        inverterMaxPowerChart = energySucess(new EnergyStageChartW(lastPowerValue));
    } else {
        inverterMaxPowerChart = null;
    }

    if (inverterMaxPowerChart) {
        stages.push(new EnergyPipelineStage(
            'inverter_power_clipping',
            inverterMaxPowerChart
        ));
    }

    const pipeline = new EnergyPipelineProperty(
        stages, 
        null,
        CompressibleNumbersArray.newFromValues(
            'kW', nominalPowerCombined.map(v => v / 1_000)
        ),
        null,
        args.inverterParams.circuit_nominal_power,
        shaded_modules_area,
        shading_factors,
    );
    return new EnergyYieldPropsGroup({
        pipeline,
    });
}

function checkConnectedStringsSimilarity(strings: StringInputDescription[]): EnergyFailure[] {
    let minVoltage = Infinity;
    let maxVoltage = -Infinity;
    for (const {sring_energy_calculator} of strings) {
        const stringVoltage = sring_energy_calculator.args.string_voltage;
        minVoltage = Math.min(minVoltage, stringVoltage);
        maxVoltage = Math.max(maxVoltage, stringVoltage);
    }
    const warnings: EnergyFailure[] = [];
    if ((minVoltage / maxVoltage) < 0.97) {
        const allIds = IterUtils.flatMapIter(strings, (s) => s.trackers_ids);
        warnings.push(
            new EnergyFailure({
                errorMsgIdent: 'DifferentStringsConnectedToInverter',
                affectedInstanceIds: allIds,
            })
        )
    }
    return warnings;
}


function checkStringsVoltage(inverterParams: PerInverterInputs, strings: StringInputDescription[]): EnergyFailure[] {
    const idsOutsideRange: IdBimScene[] = [];
    for (const {sring_energy_calculator, trackers_ids} of strings) {
        const stringVoltage = sring_energy_calculator.args.string_voltage;
        if (stringVoltage < inverterParams.Vmin || stringVoltage > inverterParams.Vmax * 1.01) {
            idsOutsideRange.push(...trackers_ids);
        }
    }
    const res: EnergyFailure[] = [];
    if (idsOutsideRange.length > 0) {
        res.push(new EnergyFailure({
            errorMsgIdent: 'StringVoltageDoesNotFallWithinVoltageOfInverter',
            affectedInstanceIds: idsOutsideRange,
        }));
    }
    return res;
}

