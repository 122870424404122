<script lang="ts">
    import { ButtonComponent, IconButton } from "../libui/button";
    import { ProjectStatus, type Project } from "../projects";
    import type { Dashboard } from "./Dashboard";
    import { getContext } from "svelte";

    let dashboard = getContext<Dashboard>("dashboard");

    export let project: Project;
    export let openDialog: (id: number) => void;
</script>

<div class="icons-container text-main-medium">
    <ButtonComponent
        desc={new IconButton(
            project.isShared ? "Group" : "GroupOff",
            (e) => {
                if (project.isShared) {
                    dashboard.unshare(project.id);
                } else {
                    dashboard.share(project.id);
                }
                e.stopPropagation();
            }
        )}
    />
    <ButtonComponent
        desc={new IconButton("Delete", (e) => {
            if (project.status === ProjectStatus.Archived) {
                openDialog(project.id);
            } else {
                dashboard.archive(project.id);
            }
            e.stopPropagation();
        })}
    />
</div>

<style>
    .icons-container {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 4px;
    }
</style>
