<svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
>
    <path
        d="M3.52113 13.4298L4.13905 13.3757L10.7806 5.43988L10.1086 4.87601L3.46706 12.8119L3.52113 13.4298ZM12.9778 4.5377L10.6258 2.56416L11.0815 2.01076C11.316 1.73128 11.6137 1.57418 11.9746 1.53946C12.3355 1.50473 12.6554 1.60148 12.9344 1.82973L13.4634 2.27371C13.7084 2.47341 13.8362 2.72482 13.847 3.02793C13.8577 3.33105 13.7687 3.59513 13.5798 3.82018L12.9778 4.5377ZM12.35 5.28585L4.67125 14.437L2.5085 14.6262L2.31928 12.4635L9.98946 3.32251L12.35 5.28585ZM10.454 5.15712L10.1086 4.87601L10.7806 5.43988L10.454 5.15712Z"
        fill="currentColor"
    />
</svg>
