import { CostModel as CM } from 'bim-ts';
import { ColDef } from "ag-grid-enterprise";
import { createModifiableCostCellDefinition } from './common';


export const createSubServiceCostPerUnitColumnDefinition = (): ColDef<CM.FlattenedCostCategoryParams> => ({
    colId: CM.SubServiceCostPerUnitColId,
    headerName: CM.SubServiceCostPerUnitColId,
    cellEditor: 'agNumberCellEditor',

    ...createModifiableCostCellDefinition(x => x.subService?.subServiceCostPerUnit),
})
