<script lang="ts">
import List from "./List.svelte";
import VirtualList from "./VirtualList.svelte";

import type { ListInput} from "./types";
import { ListGroup, ListSeparator, TitledList } from "./types";

export let input: ListInput;
</script>

{#if input.display instanceof ListGroup}
  {#each input.display.lists as groupItem}
    {#if groupItem instanceof TitledList}
      <h3 class="mdc-deprecated-list-group__subheader"><b>{groupItem.title.toUpperCase()}</b></h3>
      <List list={groupItem.list} />
    {:else if groupItem instanceof ListSeparator}
      <hr class="mdc-deprecated-list-divider">
    {:else if groupItem instanceof List}
      <List list={groupItem} />
    {/if}
    <div class="mdc-deprecated-list-group">
    </div>
  {/each}
{:else}
  {@const listComponent = input.display.options.virtualListHeight ? VirtualList : List}
  <svelte:component this={listComponent} list={input.display} />
{/if}

<style lang="scss">
</style>
