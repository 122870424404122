import { NumberProperty, StringProperty } from "src";
import type { CostCategory, CostHierarchy, IdCostCategory } from "..";
import { CostSourceType } from "..";
import type { UnitsMapper } from "src/UnitsMapper";
import type { CostComponents, CostComponentsNonNullable, EstimateCost, FindAndUpdateEstimateCost, SingleEstimateProvider} from "src/cost-model/capital";
import { createEmptyCostComponents } from "src/cost-model/capital";
import { Immer, replaceCurrencyUnitWithSymbol, unitsConverter } from 'engine-utils-ts';

export function fillModelBasedCostCategory(
    target: CostCategory,
    overrides: CostComponents,
    defaults: CostComponentsNonNullable,
    updateCosts: (newCosts: CostComponents) => void,
    quantity: number,
) {
    target.labor = {}

    const laborPerUnitValue = overrides?.laborTimeUnits?.value ?? defaults.laborTimeUnits.value;
    target.labor.laborPerUnit = {
        value: laborPerUnitValue,
        flags: {
            overriden: !!overrides?.laborTimeUnits,
            editable: true,
            ignored: !!overrides?.laborCost
        },
        update: (newValue) => {
            updateCosts(Immer.produce(overrides, (draft) => {
                if (newValue === undefined) {
                    draft.laborTimeUnits = null;
                } else {
                    draft.laborTimeUnits = NumberProperty.new({
                        value: newValue
                    })
                    draft.laborCost = null
                }
            }))
        }
    }

    const loadedWageRateValue = overrides?.laborTimeUnitCost?.value ?? defaults.laborTimeUnitCost.value;
    target.labor.loadedWageRate = {
        value: loadedWageRateValue,
        flags: {
            overriden: !!overrides?.laborTimeUnitCost,
            editable: true,
            ignored: !!overrides?.laborCost
        },
        update: (newValue) => {
            updateCosts(Immer.produce(
                overrides,
                draft => {
                    if (newValue === undefined) {
                        draft.laborTimeUnitCost = null;
                    } else {
                        draft.laborTimeUnitCost = NumberProperty.new({
                            value: newValue
                        })
                        draft.laborCost = null;
                    }
                }
            ))
        }
    }

    let laborCostPerUnitValue: number;
    {
        let isOverriden: boolean = false;
        let isIgnored: boolean = false;

        if (overrides.laborTimeUnits || overrides.laborTimeUnitCost) {
            // auto calculate labor cost per unit
            laborCostPerUnitValue = loadedWageRateValue * laborPerUnitValue;
            isOverriden = true;
            isIgnored = true;
        } else {
            laborCostPerUnitValue = overrides?.laborCost?.value ?? defaults.laborCost.value;
            isOverriden = !!overrides?.laborCost;
        }
        target.labor.laborCostPerUnit = {
            value: laborCostPerUnitValue,
            flags: {
                overriden: isOverriden,
                editable: true,
                ignored: isIgnored,
            },
            update: (newValue) => {
                updateCosts(Immer.produce(
                    overrides,
                    draft => {
                        if (newValue === undefined) {
                            draft.laborCost = null;
                        } else {
                            draft.laborCost = NumberProperty.new({
                                value: newValue
                            })
                            draft.laborTimeUnits = null;
                            draft.laborTimeUnitCost = null;
                        }
                    }
                ))
            }
        }

    }

    target.material = {}

    const materialCostPerUnitValue = overrides?.materialCost?.value ?? defaults.materialCost.value;
    target.material.materialCostPerUnit = {
        value: materialCostPerUnitValue,
        flags: { overriden: !!overrides?.materialCost, editable: true },
        update: (newValue) => {
            updateCosts(Immer.produce(
                overrides,
                draft => {
                    if (newValue === undefined) {
                        draft.materialCost = null;
                    } else {
                        draft.materialCost = NumberProperty.new({
                            value: newValue
                        })
                    }
                }
            ))
        }
    }

    target.equipment = {}

    const equipmentCostPerUnitValue = overrides?.equipmentCost?.value ?? defaults.equipmentCost.value;
    target.equipment.equipmentCostPerUnit = {
        value: equipmentCostPerUnitValue,
        flags: { overriden: !!overrides?.equipmentCost, editable: true },
        update: (newValue) => {
            updateCosts(Immer.produce(
                overrides,
                draft => {
                    if (newValue === undefined) {
                        draft.equipmentCost = null;
                    } else {
                        draft.equipmentCost = NumberProperty.new({
                            value: newValue
                        })
                    }
                }
            ))
        }
    }



    target.subService = {}

    const subServiceCostPerUnitValue = overrides?.serviceCost?.value ?? defaults.serviceCost.value;
    target.subService.subServiceCostPerUnit = {
        value: subServiceCostPerUnitValue,
        flags: { overriden: !!overrides?.serviceCost, editable: true },
        update: (newValue) => {
            updateCosts(Immer.produce(
                overrides,
                draft => {
                    if (newValue === undefined) {
                        draft.serviceCost = null;
                    } else {
                        draft.serviceCost = NumberProperty.new({
                            value: newValue
                        })
                    }
                }
            ))
        }
    }

    fillTotalCosts(target, quantity);
}

export function fillTotalCosts(
    target: CostCategory,
    quantity: number,
) {
    if (!target.subService) {
        target.subService = {}
    }
    const subServiceTotalValue = (target.subService?.subServiceCostPerUnit?.value ?? 0) * quantity;
    target.subService.subServiceTotal = { value: subServiceTotalValue }


    if (!target.equipment) {
        target.equipment = {}
    }
    const equipmentTotalValue = (target.equipment?.equipmentCostPerUnit?.value ?? 0) * quantity;
    target.equipment.equipmentTotal = { value: equipmentTotalValue }


    if (!target.material) {
        target.material = {}
    }
    const materialTotalValue = (target.material?.materialCostPerUnit?.value ?? 0) * quantity;
    target.material.materialTotal = { value: materialTotalValue }


    if (!target.labor) {
        target.labor = {}
    }
    const laborTotalValue = (target.labor?.laborCostPerUnit?.value ?? 0) * quantity;
    target.labor.laborTotal = { value: laborTotalValue }
}

export function createMiscCategory(
    state: SingleEstimateProvider,
    unitsMapper: UnitsMapper,
    totalDC: NumberProperty,
    params?: {
        name?: string,
        defaultCostSource?: CostSourceType,
    },
): CostCategory {
    const id = state.id;
    const costUnit = unitsMapper.mapToConfigured({ value: 0, unit: 'usd' }).unit!;

    const emptyOverrides: EstimateCost = {
        costs: createEmptyCostComponents(),
        id: StringProperty.new({ value: id }),
        costSource: StringProperty.new({ value: params?.defaultCostSource ?? CostSourceType.BenchmarkLumpSum }),
    }

    const category: CostCategory = {
        description: { value: params?.name ?? 'Misc.'},
    }

    const overrides = state.value ?? emptyOverrides;

    const costUnitOptions = [
        replaceCurrencyUnitWithSymbol(costUnit) + '/W',
        replaceCurrencyUnitWithSymbol(costUnit) + '/lump sum',
    ]
    let costUnitIndex: number;
    if (overrides?.costSource.value === CostSourceType.BenchmarkPerQuantity) {
        costUnitIndex = 0;
        category.quantity = {
            value: NumberProperty.new(unitsConverter.toShortest(totalDC))
        }
        fillPerUnitCosts(
            category,
            overrides.costs,
            state.defaults,
            (newCosts) => state.update((prev) => Immer.produce(
                prev ?? emptyOverrides,
                x => { x.costs = newCosts }
            )),
            totalDC.as('W'),
        )
    } else {
        costUnitIndex = 1;
        category.quantity = {
            value: NumberProperty.new({ value: 1 })
        }
        fillLumpSumCosts(
            category,
            overrides.costs,
            (newCosts) => state.update((prev) => Immer.produce(
                prev ?? emptyOverrides,
                x => { x.costs = newCosts }
            )),
        )
    }

    const options = [
        CostSourceType.BenchmarkPerQuantity,
        CostSourceType.BenchmarkLumpSum,
    ]
    let costSourceIndex = options.findIndex(x => overrides.costSource.value === x);
    category.costSource = {
        options,
        index: costSourceIndex,
        flags: { editable: true },
        update: (newIndex) => state.update((prev) => {
            if (newIndex < 0) {
                return undefined;
            }
            return Immer.produce(prev ?? overrides, x => {
                x.costSource = StringProperty.new({ value: options[newIndex] })
                x.costs = createEmptyCostComponents();
            })
        })
    }


    category.costUnit = {
        index: costUnitIndex,
        options: costUnitOptions,
        flags: { editable: false },
        update: (newIndex) => state.update((prev) => Immer.produce(
            prev ?? emptyOverrides,
            draft => {
                let newValue: string;
                if (newIndex === 0) {
                    newValue = CostSourceType.BenchmarkPerQuantity;
                } else {
                    newValue = CostSourceType.BenchmarkLumpSum;
                }
                const prevValue = draft.costSource.value;
                if (newValue !== prevValue) {
                    draft.costs = createEmptyCostComponents();
                }
                draft.costSource = StringProperty.new({ value: newValue });
                draft.costs = createEmptyCostComponents();
            }
        )),
    }
    return category;
}

export function fillPerUnitCosts(
    target: CostCategory,
    overrides: CostComponents,
    defaults: CostComponentsNonNullable,
    updateCosts: (newCosts: CostComponents) => void,
    quantity: number,
) {
    target.labor = {}
    target.labor.laborCostPerUnit = {
        flags: { editable: true, overriden: !!overrides.laborCost },
        value: overrides.laborCost?.value ?? defaults.laborCost.value,
        update: (newVal) => updateCosts(Immer.produce(overrides, (draft) => {
            if (newVal === undefined) {
                draft.laborCost = null;
            } else {
                draft.laborCost = NumberProperty.new({ value: newVal });
            }
        }))
    }

    target.material = {}
    target.material.materialCostPerUnit = {
        flags: { editable: true, overriden: !!overrides.materialCost },
        value: overrides.materialCost?.value ?? defaults.materialCost?.value,
        update: (newVal) => updateCosts(Immer.produce(overrides, (draft) => {
            if (newVal === undefined) {
                draft.materialCost = null;
            } else {
                draft.materialCost = NumberProperty.new({ value: newVal });
            }
        }))
    }

    target.equipment = {}
    target.equipment.equipmentCostPerUnit = {
        flags: { editable: true, overriden: !!overrides.equipmentCost },
        value: overrides.equipmentCost?.value ?? defaults.equipmentCost?.value,
        update: (newVal) => updateCosts(Immer.produce(overrides, (draft) => {
            if (newVal === undefined) {
                draft.equipmentCost = null;
            } else {
                draft.equipmentCost = NumberProperty.new({ value: newVal });
            }
        }))
    }

    target.subService = {}
    target.subService.subServiceCostPerUnit = {
        flags: { editable: true, overriden: !!overrides.serviceCost },
        value: overrides.serviceCost?.value ?? defaults.serviceCost?.value,
        update: (newVal) => updateCosts(Immer.produce(overrides, (draft) => {
            if (newVal === undefined) {
                draft.serviceCost = null;
            } else {
                draft.serviceCost = NumberProperty.new({ value: newVal });
            }
        }))
    }

    fillTotalCosts(target, quantity);
}

export function fillLumpSumCosts(
    target: CostCategory,
    overrides: CostComponents,
    updateCosts: (newCosts: CostComponents) => void,
) {
    target.labor = {}
    const laborPerUnit = overrides?.laborCost?.value ?? 0;
    target.labor.laborCostPerUnit = {
        flags: { editable: true, overriden: !!laborPerUnit },
        value: laborPerUnit,
        update: (newVal) => updateCosts(Immer.produce(overrides, (draft) => {
            if (newVal === undefined) {
                draft.laborCost = null;
            } else {
                draft.laborCost = NumberProperty.new({ value: newVal });
            }
        }))
    }
    target.labor.laborTotal = {
        value: laborPerUnit,
    }

    target.material = {};
    const materialPerUnit = overrides?.materialCost?.value ?? 0;
    target.material.materialCostPerUnit = {
        flags: { editable: true, overriden: !!materialPerUnit },
        value: materialPerUnit,
        update: (newVal) => updateCosts(Immer.produce(overrides, (draft) => {
            if (newVal === undefined) {
                draft.materialCost = null;
            } else {
                draft.materialCost = NumberProperty.new({ value: newVal });
            }
        }))
    }
    target.material.materialTotal = {
        value: materialPerUnit
    }

    target.equipment = {};
    const equipmentPerUnit = overrides?.equipmentCost?.value ?? 0;
    target.equipment.equipmentCostPerUnit = {
        flags: { editable: true, overriden: !!equipmentPerUnit },
        value: equipmentPerUnit,
        update: (newVal) => updateCosts(Immer.produce(overrides, (draft) => {
            if (newVal === undefined) {
                draft.equipmentCost = null;
            } else {
                draft.equipmentCost = NumberProperty.new({ value: newVal });
            }
        }))
    }
    target.equipment.equipmentTotal = {
        value: equipmentPerUnit
    }

    target.subService = {};
    const subServicePerUnit = overrides?.serviceCost?.value ?? 0;
    target.subService.subServiceCostPerUnit = {
        flags: { editable: true, overriden: !!subServicePerUnit },
        value: subServicePerUnit,
        update: (newVal) => updateCosts(Immer.produce(overrides, (draft) => {
            if (newVal === undefined) {
                draft.serviceCost = null;
            } else {
                draft.serviceCost = NumberProperty.new({ value: newVal });
            }
        }))
    }
    target.subService.subServiceTotal = {
        value: subServicePerUnit,
    }
}

export function fillModelBasedTopLevelCategory(
    targetId: IdCostCategory,
    hierarchy: CostHierarchy,
    estimateId: string,
    estimates: EstimateCost[],
    updateEstimate: FindAndUpdateEstimateCost,
    estimateDefaults: CostComponentsNonNullable,
    unitsMapper: UnitsMapper,
    totalDC: NumberProperty,
) {
    const target = hierarchy.categories.get(targetId);
    if (!target) {
        return;
    }
    const hasInstances = hierarchy.categoryWithSceneInstances.has(targetId);
    let _override = estimates.find(x => x.id.value === estimateId);

    if (hasInstances) {
        if (!_override) {
            _override = {
                costSource: StringProperty.new({ value: CostSourceType.GroupSum }),
                costs: createEmptyCostComponents(),
                id: StringProperty.new({ value: estimateId })
            }
        }
    } else {
        if (!_override || _override.costSource.value === CostSourceType.GroupSum) {
            _override = {
                costSource: StringProperty.new({ value: CostSourceType.BenchmarkPerQuantity }),
                costs: createEmptyCostComponents(),
                id: StringProperty.new({ value: estimateId })
            }
        }
    }

    const override = _override

    const costSourceOptions = [
        ...(hasInstances ? [CostSourceType.GroupSum] : []),
        CostSourceType.BenchmarkLumpSum,
        CostSourceType.BenchmarkPerQuantity,
    ];
    let costSourceIndex = costSourceOptions.findIndex(x => override.costSource.value === x);
    if (costSourceIndex < 0) {
        costSourceIndex = 0;
    }
    target.costSource = {
        options: costSourceOptions,
        index: costSourceIndex,
        flags: { editable: true },
        update: (newIndex) => updateEstimate(estimateId, (prev) => {
            if (newIndex < 0) {
                return undefined;
            }
            return Immer.produce(prev ?? override, x => {
                x.costSource = StringProperty.new({ value: costSourceOptions[newIndex] })
                x.costs = createEmptyCostComponents();
            })
        })
    }

    if (override.costSource.value === CostSourceType.GroupSum) {
        hierarchy.sumupChildren(targetId)
    } else {
        hierarchy.recursiveChildren(targetId, id => hierarchy.ignored.add(id));
        const updateCosts = (costs: CostComponents) =>
            updateEstimate(estimateId, prev => Immer.produce(prev ?? override, draft => { draft.costs = costs }))
        const costUnit = unitsMapper.mapToConfigured({ value: 0, unit: 'usd' }).unit!;
        if (override.costSource.value === CostSourceType.BenchmarkLumpSum) {
            target.costUnit = {
                options: [replaceCurrencyUnitWithSymbol(costUnit) + '/lump sum'],
                index: 0,
            }
            target.quantity = {
                value: NumberProperty.new({ value: 1 })
            }
            fillLumpSumCosts(target, override.costs, updateCosts)
        } else {
            target.costUnit = {
                options: [replaceCurrencyUnitWithSymbol(costUnit) + '/W'],
                index: 0,
            }
            target.quantity = { value: NumberProperty.new(unitsConverter.toShortest(totalDC)) }
            fillPerUnitCosts(target, override.costs, estimateDefaults, updateCosts, totalDC.as('W'));
        }
    }
}
