import type { VersionedValue } from './stateSync/LazyVersioned';

export class DefaultMap<K, V> implements VersionedValue {

	private readonly _map: Map<K, V> = new Map();

	private readonly _factory: (key: K) => V;
	private _disposeFn: ((v: V) => void) | null = null;
	private _version: number = 0;

	constructor(
		defaultFactory: (key: K) => V,
		disposeMethod?: (v: V) => void,
	) {
		this._factory = defaultFactory;
		this._disposeFn = disposeMethod ?? null;
	}

	withDispose(fnName: keyof V): DefaultMap<K, V> {
        this._disposeFn = (val) => {
            if (typeof val[fnName] == 'function') {
                (val[fnName] as unknown as Function)();
            } else {
                console.error(`dispose fn name (${fnName as string}) is invalid`);
            }
        }
        return this;
    }
    withCustomDisposeFn(disposingFn: ((val: V) => void)): DefaultMap<K, V> {
        this._disposeFn = disposingFn;
        return this;
    }

	version(): number {
		return this._version;
	}
	
	getOrCreate(key: K): V {
		let val = this._map.get(key);
		if (val === undefined) {
			val = this._factory(key);
			this._map.set(key, val);
			this._version += 1;
		}
		return val;
	}

	get(key: K): V | undefined {
		return this._map.get(key);
	}

	set(key: K, val: V) {
		if (this._disposeFn) {
			const prev = this._map.get(key);
			if (prev) {
				this._disposeFn(prev);
			}
		}
		this._version += 1;
		this._map.set(key, val);
	}

	has(key: K): boolean {
		return this._map.has(key);
	}

	clear() {
		if (this._disposeFn) {
			for (const v of this._map.values()) {
				this._disposeFn(v);
			}
		}
		this._version += 1;
		this._map.clear();
	}

	dispose() {
		this.clear();
	}

	delete(key: K): boolean {
		if (this._disposeFn) {
			const v = this._map.get(key);
			if (v) {
				this._disposeFn(v);
			}
		}
		this._version += 1;
		return this._map.delete(key);
	}

	get size(): number {
		return this._map.size;
	}

	[Symbol.iterator](): IterableIterator<[K, V]> {
		return this._map.entries()
	}

	entries(): IterableIterator<[K, V]> {
		return this._map.entries()
	}

	keys(): IterableIterator<K> {
		return this._map.keys();
	}

	values(): IterableIterator<V> {
		return this._map.values();
	}

}
