import type {
	AnyBimGeometry, IdBimGeo,
	SceneInstance} from 'bim-ts';
import {
	BasicAnalyticalRepresentation, BimGeometryType,
	SceneObjDiff,
} from 'bim-ts';
import type { RGBAHex } from 'engine-utils-ts';
import { IterUtils, Success, RGBA } from 'engine-utils-ts';
import { Matrix4 } from 'math-ts';
import { entityTypeFromId } from 'verdata-ts';

import { EngineMaterialId } from '../pools/EngineMaterialId';
import {
	InObjFullId, InObjIdType, InObjLocalId,
} from '../scene/EngineSceneIds';
import type { ESOHandle } from '../scene/ESOsCollection';
import { newLodGroupLocalIdent } from '../scene/LodGroups';
import type { SubmeshAllocArgs } from '../scene/Submeshes2';
import { LodMask } from '../scene/Submeshes2';
import { ESO_AnotationFlags } from './ESO';
import { ESO_Diff } from './ESO_Diff';
import type { ESOsHandlerInputs } from './ESOsHandlerBase';
import type { ESSO_Any, SubmeshesCreationOutput, SubmeshesCreationResources} from './ESSO';
import {
	ESSO
} from './ESSO';
import { SharedSelectHighlightJobUpdater } from './ESSO_HighlightUpdaters';
import { SharedStdRenderJobUpdater } from './SubmeshesStd';
import { ESO_GraphTriangulated, ESO_SingleGeoWidth } from './ESO_GraphTriangulated';
import type { GeometryCtor } from '../scene/GeometryEditsHandler';
import type { IdEngineGeo } from '../geometries/AllEngineGeometries';

const IdentityMatrix = Object.freeze(new Matrix4());

export class ESO_Trench extends ESO_SingleGeoWidth {

	constructor(
		sceneInstanceRef: Readonly<SceneInstance>,
	) {
		super(sceneInstanceRef);
		this.width = trenchWidth(sceneInstanceRef);
	}

	width: number;

	geometryId(): IdBimGeo | undefined {
		return this.sceneInstanceRef.representationAnalytical?.geometryId;
	}
}
function trenchWidth(sceneInstance: SceneInstance): number {
	const width = sceneInstance.properties.get('dimensions | width');
	return width?.as('m') ?? 0.1;
}

class ESSO_TrenchTriangulation extends ESSO<ESSO_Trench_Repr> {

	get parentWorldMatrix(): Readonly<Matrix4> {
		return IdentityMatrix; // triangulation is done in world space
	}

	get colorTint(): 0 | RGBAHex {
		let color = super.colorTint;
		if (!color) {
			return RGBA.new(0.1, 0.1, 0.1, 0.35);
		} else {
			const alpha = RGBA.alphaOnlyFromHex(color);
			return RGBA.withDifferentAlpha(color, alpha * 0.35);
		}
		return color;
	}

	createSubmeshes(resoures: SubmeshesCreationResources, output: SubmeshesCreationOutput): void {
		const roundedWidth = Math.max(1, Math.ceil((this.rootRef as ESO_Trench).width) * 2);
		const lodGroupLocalIdent = newLodGroupLocalIdent(0, roundedWidth);
		for (const geoId of this.repr.geometriesRefs) {
			const allocArgs: SubmeshAllocArgs = {
				id: this.id,
				lodMask: LodMask.Lod0,
				lodGroupLocalIdent,
				subObjectRef: this,
				descr: {
					geoId,
					localTransforms: null,
					materialId: EngineMaterialId.BasicAnalyticalTransparent,
					mainRenderJobUpdater: SharedStdRenderJobUpdater,
					overlayRenderJobUpdater: SharedSelectHighlightJobUpdater
				}
			};
			output.submeshes.push(allocArgs);
		}
	}
}

export interface ESSO_Trench_Repr {
	geometriesRefs: IdEngineGeo[];
}

export class ESSO_TrenchAnalytical extends ESSO<{}> {
	createSubmeshes(resoures: SubmeshesCreationResources, output: SubmeshesCreationOutput): void {

	}
}

export class ESO_TrenchHandler extends ESO_GraphTriangulated<ESO_Trench> {

	constructor(
		identifier: string,
		args: ESOsHandlerInputs,
	) {
		super(identifier, args);
		this._relevantBimUpdatesFlags |= SceneObjDiff.LegacyProps;
	}

	applyBimDiffToESO(obj: ESO_Trench, diff: SceneObjDiff, handle: ESOHandle): ESO_Diff {
		let appliedDiff = super.applyBimDiffToESO(obj, diff, handle);
		const width = trenchWidth(obj.sceneInstanceRef);
		if (!Object.is(width, obj.width)) {
			obj.width = width;
			appliedDiff |= ESO_Diff.RepresentationBreaking;
		}
		if (appliedDiff & ESO_Diff.Position) {
			appliedDiff |= ESO_Diff.RepresentationBreaking;
		}
		if (appliedDiff & ESO_Diff.RepresentationBreaking) {
			this.markForTriangulationUpdate(handle);
		}
		return appliedDiff;
	}

	tryCreateESO(instance: SceneInstance): ESO_Trench | undefined {
		if (instance.type_identifier !== 'trench') {
			return undefined;
		}
		if (!(instance.representationAnalytical instanceof BasicAnalyticalRepresentation)) {
			return;
		}
		const geoType = entityTypeFromId<BimGeometryType>(instance.representationAnalytical.geometryId);
		if (geoType !== BimGeometryType.GraphGeometry && geoType !== BimGeometryType.Polyline) {
			return;
		}
		return new ESO_Trench(instance);
	}
	esosTypesToHandle(): Iterable<new (...args: any[]) => ESO_Trench> {
		return [ESO_Trench];
	}

	propogateDirtyFlagsInside(): void {
		// // if any repr broke, mark all dirty and recalcualte
		// if (IterUtils.any(
		// 		this._dirtyObjects.values(),
		// 		dirtyFlags => (dirtyFlags & ESO_Diff.RepresentationBreaking) != 0
		// )) {
		// 	for (const h of this._allObjectsHandled) {
		// 		this.markDirty(h, ESO_Diff.RepresentationBreaking);
		// 	}
		// }
	}

	createSubObjectsFor(objs: [ESOHandle, ESO_Trench][]): Iterable<[ESOHandle, ESSO<any>[]]> {
		const triangulations = this.triangulationCalculations.peekByIds(objs.map(t => t[0]));
		const subObjects = IterUtils.mapIter(
			objs,
			([handle, obj]) => {
				let subobjs: ESSO<any>[] = [];

				const triGeometriesIds = triangulations.get(handle);
				if (triGeometriesIds instanceof Success) {
					const repr = new ESSO_TrenchTriangulation(
						obj,
						InObjFullId.new(handle, InObjLocalId.new(InObjIdType.ObjSelf, 0)),
						{geometriesRefs: triGeometriesIds.value},
						null
					);
					subobjs.push(repr);
				}

				if (obj.annotationFlags & ESO_AnotationFlags.IsInEdit) {
					const geoId = obj.geometryId()!;

					const bimGeo = this.bimGeos.peekById(geoId);
					if (bimGeo) {
						const editshandler = this.geometriesEditHandlers.get(bimGeo.constructor as GeometryCtor);
						if (editshandler) {
							editshandler.createEditRepr(handle, obj, bimGeo, subobjs);
						} else {
							this.logger.error('no geo edit handler for', bimGeo);
						}

						this.createAuxiluaryEditESSOs(handle, obj, geoId, bimGeo, subobjs);
					}
				}

				return [handle, subobjs] as [ESOHandle, ESSO<any>[]];
			}
		);
		return subObjects;
	}

	createAuxiluaryEditESSOs(handle: ESOHandle, obj: ESO_Trench, geoId: IdBimGeo, bimGeo: AnyBimGeometry, result: ESSO_Any[]): void {
		result.push(new ESSO_TrenchAnalytical(
			obj,
			InObjFullId.new(handle, InObjLocalId.new(InObjIdType.ObjSelfAux, 0)),
			[geoId, bimGeo],
			null,
		));
	}
}

