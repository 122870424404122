import type { TasksRunner, LongTask} from "engine-utils-ts";
import { Yield } from "engine-utils-ts";
import { EmptyTransform, type Vector3 } from "math-ts";
import type { UiBindings} from "ui-bindings";
import { NotificationDescription, NotificationType } from "ui-bindings";
import { notificationSource } from '../Notifications';
import type { Catalog } from './Catalog';
import { Bim } from '../Bim';
import type { AssetId } from '.';
import type { SceneInstance, IdBimScene, SceneInstancePatch } from '..';
import { importBimassetToBim } from '..';

export class InSceneCatalog {
    virtualBim = new Bim({});
    constructor(
        public catalog: Catalog,
        public bim: Bim,
        public uiBindings: UiBindings,
        public tasksRunner: TasksRunner,
    ) {}

    fillPriceProps() {
        //preprocess
        //prepare groups from assets

        //iterating items
        for (const [id, si] of this.bim.instances.perId) {
            // if type === tracker { fillPricesForTracker }
        }
    }

    fillPricesForTracker(si: SceneInstance) {
        // module pricing
        const moduleModel = si.properties.get('module | model')?.asText();
        const maxPower = si.properties.get('module | maximum_power')?.asNumber();
        const current = si.properties.get('module | current')?.asNumber();
        si.properties.get('module | width');
        si.properties.get('module | length');
        si.properties.get('circuit | equipment | modules_count');

    }

    loadAssetsToScene(assets: AssetId[], offset?: Vector3) {
        try {
            const uiBindings = this.uiBindings;
            const task: LongTask<any> = this.tasksRunner.newLongTask<any>({
                defaultGenerator: function*(inSceneCatalog: InSceneCatalog, bim: Bim) {
                    for (const assetid of assets) {
                        const asset = inSceneCatalog.catalog.assets.perId.get(assetid);
                        if (!asset) continue;
                        const ids = yield* importBimassetToBim(
                            asset.bimasset,
                            bim,
                        );
                        bim.instances.applyPatchTo({ localTransform: EmptyTransform.clone() }, ids)
                        bim.instances.setSelected(ids);

                        uiBindings.actions.get(['Camera', 'Focus Selected'].join())?.action?.([]);
                    }
                    yield Yield.Asap;
                }(this, this.bim),
            });
            this.uiBindings.addNotification(
                NotificationDescription.newWithTask({
                    source: notificationSource,
                    key: 'loadAsset',
                    taskDescription: { task },
                    type: NotificationType.Info,
                    removeAfterMs: 3000,
                    addToNotificationsLog: true
                })
            );
        } catch (e) {
            console.error(e)
        }
    }

    applyAssetPropToSceneInstances(assetId: AssetId, siIds: IdBimScene[]) {
        try {
            const inSceneCatalog = this;
            const task: LongTask<any> = this.tasksRunner.newLongTask<any>({
                defaultGenerator: function*() {
                    // try to apply props to all instances one by one
                    const asset = inSceneCatalog.catalog.assets.perId.get(assetId);
                    const assetTypeIdentifier = inSceneCatalog.catalog.assets.sceneInstancePerAsset
                        .getAssetAsSceneInstance(assetId)?.type_identifier
                    if (
                        !asset ||
                        !assetTypeIdentifier ||
                        assetTypeIdentifier.includes('pack')
                    ) return;
                    const fromSi = inSceneCatalog.catalog.assets.sceneInstancePerAsset
                        .getAssetAsSceneInstance(assetId);
                    if (!fromSi) return;
                    for (const toId of siIds) {
                        const toSi = inSceneCatalog.bim.instances.perId.get(toId);
                        if (!toSi) continue;
                        const patch = applyAssetProps(fromSi, toSi);
                        if (patch === null) continue;
                        inSceneCatalog.bim.instances.applyPatches([
                            [toId, patch]
                        ])
                    }
                }(),
            });
            this.uiBindings.addNotification(
                NotificationDescription.newWithTask({
                    source: notificationSource,
                    key: 'replaceProperties',
                    taskDescription: { task },
                    type: NotificationType.Info,
                    removeAfterMs: 3000,
                    addToNotificationsLog: true
                })
            );
        } catch (e) {
            console.error(e)
        }
    }

}

export function applyAssetProps(
    fromSi: SceneInstance,
    toSi: SceneInstance,
): SceneInstancePatch | null {
    const result: SceneInstancePatch = {};
    result.properties = []
    const isValidMapping =
        fromSi.type_identifier === toSi.type_identifier ||
        (
            fromSi.type_identifier.includes('module') &&
            toSi.type_identifier.includes('tracker')
        ) ||
        (
            fromSi.type_identifier === 'tracker' &&
            toSi.type_identifier === 'tracker-frame'
        ) ||
        (
            toSi.type_identifier === 'tracker' &&
            fromSi.type_identifier === 'tracker-frame'
        )
    if (!isValidMapping) {
        return null;
    }
    for (const [path, newProp] of fromSi.properties) {
        const oldProp = toSi.properties.get(path);
        const shouldReplaceProperty =
            oldProp;// && !oldProp.readonly && !oldProp.isComputedBy;
        if (shouldReplaceProperty) {
            result.properties.push([path, newProp]);
        }
    }
    if (!result.properties.length) {
        return null;
    }
    return result;
}
