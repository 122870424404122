import type { IdBimScene, Bim, Catalog, BimProperty, SceneInstance, SceneInstancePatch, RepresentationBase } from "bim-ts";
import type { PropsGroupBase } from "bim-ts";
import { IterUtils, LazyDerived, type RGBAHex, type LazyVersioned, RGBA, DefaultMap } from "engine-utils-ts";
import { Euler, KrMath, type Matrix4, Quaternion, RAD2DEG, Transform, Vector3, Aabb } from "math-ts";
import type { PUI_ConfigBasedBuilderParams} from "ui-bindings";
import { PUI_GroupNode, PUI_PropertyNodeColor, PUI_PropertyNodeNumber, PUI_PropertyNodeString } from "ui-bindings";
import { AsyncPropsUiRetriver } from './AsyncPropsUiRetriver';
import { CommonValueMerger, FieldsMergeState, GroupsCommonFieldsMerger } from "./CommonFieldsMerger";
import { createSelectionPropsPuiMappingConfig } from './SelectionPropsPuiMappingConfig';
import { buildPropsUi } from "./SelectionPropsUiBuilder";
import { buildLegacySelectionPropsUi } from "./SelectionPropsUiBuilderLegacy";
import type { TransformGizmoSettings } from 'engine-ts';

export interface SceneInstancesSelectionProps {
    simplePropsUi: PUI_GroupNode;
    transformUi: PUI_GroupNode;
    legacyPropertiesPui: PUI_GroupNode;
    propsPui: PUI_GroupNode;
}

export function bimInstancesCommonProps(
    selectedIds: LazyVersioned<IdBimScene[]>,
    bim: Bim,
    catalog: Catalog,
    transformSettings: LazyVersioned<TransformGizmoSettings>,
): LazyVersioned<SceneInstancesSelectionProps> {

    const asyncPropsUiRetriver = new AsyncPropsUiRetriver(bim);

    const pui = LazyDerived.new1(
        'SceneInstancesSelectionProps',
        [bim.instances, asyncPropsUiRetriver, transformSettings],
        [selectedIds],
        ([selectedIds]) => {

            const stateMerger = new InstancesCommonValueMerger(
                bim,
                asyncPropsUiRetriver,
                transformSettings,
            );

            for (const id of selectedIds) {
                const instance = bim.instances.peekById(id);
                if (!instance) {
                    continue;
                }
                stateMerger.mergeFrom(id, instance);
            };

            const commonPropsUiNode = stateMerger.toPui(bim, catalog);

            asyncPropsUiRetriver.disposeUnused();
            
            return commonPropsUiNode;
        }
    ).withoutEqCheck();
    return pui;
}


class InstancesCommonValueMerger {

    readonly asyncPropsUiRetriver: AsyncPropsUiRetriver;
    readonly transformSettings: LazyVersioned<TransformGizmoSettings>;

    readonly ids: IdBimScene[] = [];

    readonly type_identifier = new CommonValueMerger<string>();
    readonly name = new CommonValueMerger<string>();
    readonly color = new CommonValueMerger<RGBAHex | 0>();
    readonly parentId: CommonValueMerger<IdBimScene | undefined> = new CommonValueMerger();

    readonly localTransform = new GroupsCommonFieldsMerger<EuelerTransform>();
    readonly localDimensions = new GroupsCommonFieldsMerger<Vector3>();

    readonly globalTransform = new GroupsCommonFieldsMerger<EuelerTransform>();
    readonly globalBboxCenter = new GroupsCommonFieldsMerger<Vector3>();
    readonly globalDimensions = new GroupsCommonFieldsMerger<Vector3>();

    readonly legacyPropertiesMerger = new GroupsCommonFieldsMerger<{[key: string] : BimProperty}>();
    readonly propsMerger: GroupsCommonFieldsMerger<PropsGroupBase>;

    readonly _representationsBBoxes: DefaultMap<RepresentationBase | null, Aabb>;

    readonly _mappingParams: PUI_ConfigBasedBuilderParams;

    constructor(
        bim: Bim,
        asyncPropsUiRetriver: AsyncPropsUiRetriver,
        transformSettings: LazyVersioned<TransformGizmoSettings>,
    ) {
        this.asyncPropsUiRetriver = asyncPropsUiRetriver;
        this.transformSettings = transformSettings;
        
        this.propsMerger = new GroupsCommonFieldsMerger<PropsGroupBase>(asyncPropsUiRetriver);
        const geometriesBboxesAtThisMoment = bim.allBimGeometries.aabbs.poll();
        this._representationsBBoxes = new DefaultMap<RepresentationBase | null, Aabb>(
            repr => {
                const aabb = repr?.aabb(geometriesBboxesAtThisMoment) ?? Aabb.empty();
                if (aabb.isEmpty()) {
                    aabb.elements.fill(0);
                }
                return aabb;
            }
        );
        this._mappingParams = createSelectionPropsPuiMappingConfig();
    }


    mergeFrom(id: IdBimScene, instance: SceneInstance) {

        this.ids.push(id);
        this.type_identifier.merge(instance.type_identifier);
        this.name.merge(instance.name);
        this.color.merge(instance.colorTint);
        this.parentId.merge(instance.spatialParentId || undefined);

        
        if (this.localTransform.state() !== FieldsMergeState.Unmergable) {
            this.localTransform.merge(EuelerTransform.fromTransform(instance.localTransform));
        }
        if (this.localDimensions.state() !== FieldsMergeState.Unmergable) {
            const reprBbox = this._representationsBBoxes.getOrCreate(
                instance.representation ?? instance.representationAnalytical
            );
            this.localDimensions.merge(reprBbox.getSize());
        }

        if (this.globalTransform.state() !== FieldsMergeState.Unmergable) {
            const globalTransform = EuelerTransform.fromMatrix(instance.worldMatrix);
            this.globalTransform.merge(globalTransform);
        }
        if (this.globalBboxCenter.state() !== FieldsMergeState.Unmergable) {
            const reprBbox = this._representationsBBoxes.getOrCreate(
                instance.representation ?? instance.representationAnalytical
            );
            const bboxCenter = reprBbox.getCenter_t();
            const globalBboxCenter = bboxCenter.applyMatrix4(instance.worldMatrix);
            this.globalBboxCenter.merge(globalBboxCenter);
        }
        if (this.globalDimensions.state() !== FieldsMergeState.Unmergable) {
            const reprBbox = this._representationsBBoxes.getOrCreate(
                instance.representation ?? instance.representationAnalytical
            );
            const bboxSize = reprBbox.getSize();
            const matrixBasisComponent = new Vector3();
            for (let i = 0; i < 3; i++) {
                matrixBasisComponent.setFromMatrixColumn(instance.worldMatrix, i);
                const axisScale = matrixBasisComponent.length();
                bboxSize.setComponent(i, bboxSize.getComponent(i) * axisScale);
            }
            this.globalDimensions.merge(bboxSize);
        }

        if (this.legacyPropertiesMerger.state() !== FieldsMergeState.Unmergable) {
            const legacyPropsAsObjTree =  bimPropertiesToObjectTree(instance.properties.values());
            this.legacyPropertiesMerger.merge(legacyPropsAsObjTree);
        }
        if (this.propsMerger.state() !== FieldsMergeState.Unmergable) {
            this.propsMerger.merge(instance.props);
        }
    }

    toPui(
        bim: Bim,
        catalog: Catalog,
    ): SceneInstancesSelectionProps {
        const ids = this.ids.slice();
        const instances = bim.instances;

        let simplePropsUi: PUI_GroupNode;
        { // basic props
            const pui = new PUI_GroupNode({
                name: '',
                sortChildren: false,
            });
            simplePropsUi = pui;

            if (ids.length === 1) {
                pui.addMaybeChild(new PUI_PropertyNodeString({
                    name: 'id',
                    readonly: true,
                    value: ids[0].toString(),
                    onChange: () => {},
                }))
            }
            if (this.type_identifier.result() !== undefined) {
                pui.addMaybeChild(new PUI_PropertyNodeString({
                    name: 'type_identifier',
                    readonly: true,
                    value: this.type_identifier.nodeValue()!,
                    onChange: () => {},
                }))
            }
            if (this.name.result() !== undefined) {
                pui.addMaybeChild(new PUI_PropertyNodeString({
                    name: 'name',
                    value: this.name.nodeValue()!,
                    onChange: (value) => {
                        instances.applyPatchTo({name: value}, ids);
                    },
                }))
            }
            const color = this.color.nodeValue();
            if (color !== undefined) {
                pui.addMaybeChild(new PUI_PropertyNodeColor({
                    name: 'color_tint',
                    value: RGBA.toHexRgbString(color as RGBAHex) as any,
                    onChange: (value) => {
                        const rgba = RGBA.parseFromHexString(value as any);
                        instances.applyPatchTo({colorTint: rgba}, ids);
                    },
                }))
            }
            const parentId = this.parentId.nodeValue();
            if (parentId !== undefined) {
                const puiResult = parentId === null ? null : parentId!.toString();
                pui.addMaybeChild(new PUI_PropertyNodeString({
                    name: 'parent_id',
                    readonly: true,
                    value: puiResult,
                    onChange: () => {},
                }))
            }
        }


        const transformUi = new PUI_GroupNode({name: 'transform', sortChildren: false});

        { // add global transform props

            const pui = new PUI_GroupNode({
                name: 'transform',
                sortChildren: false,
            });
            transformUi.addMaybeChild(pui);


            const applyWorldPosPatchTo = (ids: IdBimScene[], patchMatrix: (wm: Matrix4) => void) => {
                const patches = IterUtils.filterMap(ids, (id) => {
                    const instance = instances.peekById(id);
                    if (!instance) {
                        return undefined;
                    }
                    const newMatrix = instance.worldMatrix.clone();
                    patchMatrix(newMatrix);
                    if (newMatrix.equals(instance.worldMatrix)) {
                        return undefined;
                    }
                    return [id, newMatrix] as [IdBimScene, Matrix4];
                });
                if (this.transformSettings.poll().moveOnlyParents) {
                    instances.patchWorldMatricesOfParentsOnly(new Map(patches));
                } else {
                    instances.patchWorldMatrices(new Map(patches));
                }
            }

            const valueIsValid = (value: any) => typeof value === 'number' && !isNaN(value) || value === null;

            if (this.globalBboxCenter.state() !== FieldsMergeState.Unmergable) {
                const centerNode = new PUI_GroupNode({name: 'center'});
                for (const component of ['x', 'y', 'z'] as const) {
                    const mergedComponent = this.globalBboxCenter.get(component);
                    if (!(mergedComponent instanceof CommonValueMerger)) {
                        continue;
                    }
                    const initialComponentValue = mergedComponent.nodeValue();
                    if (!valueIsValid(initialComponentValue)) {
                        continue;
                    }
                    centerNode.addMaybeChild(new PUI_PropertyNodeNumber({
                        name: component,
                        value: initialComponentValue,
                        unit: 'm',
                        onChange: (value) => {
                            if (!Number.isFinite(value)) {
                                console.error('invalid value, ignoring', value);
                                return;
                            }
                            applyWorldPosPatchTo(ids, (wm) => {
                                const pos = wm.extractPosition();
                                const diff = value - initialComponentValue;
                                pos[component] += diff;
                                wm.setPositionV(pos);
                            });
                        }
                    }));
                }
                pui.addMaybeChild(centerNode);
            }
            if (this.globalDimensions.state() !== FieldsMergeState.Unmergable) {
                const dimensionsNode = new PUI_GroupNode({name: 'dimensions'});
                for (const component of ['x', 'y', 'z'] as const) {
                    const mergedComponent = this.globalDimensions.get(component);
                    if (!(mergedComponent instanceof CommonValueMerger)) {
                        continue;
                    }
                    const initialComponentValue = mergedComponent.nodeValue();
                    if (!valueIsValid(initialComponentValue)) {
                        continue;
                    }
                    dimensionsNode.addMaybeChild(new PUI_PropertyNodeNumber({
                        name: component,
                        value: initialComponentValue,
                        unit: 'm',
                        readonly: true,
                        onChange: () => {},
                    }));
                }
                pui.addMaybeChild(dimensionsNode);
            }


            const mergedPositions = this.globalTransform.get('position');
            if (mergedPositions instanceof GroupsCommonFieldsMerger) {
                const positionGroup = new PUI_GroupNode({name: 'origin'});

                for (const component of ['x', 'y', 'z'] as const) {
                    const mergedComponent = mergedPositions.get(component);
                    if (!(mergedComponent instanceof CommonValueMerger)) {
                        continue;
                    }
                    const initialComponentValue = mergedComponent.nodeValue();
                    if (!valueIsValid(initialComponentValue)) {
                        continue;
                    }
                    positionGroup.addMaybeChild(new PUI_PropertyNodeNumber({
                        name: component,
                        value: initialComponentValue,
                        unit: 'm',
                        onChange: (value) => {
                            if (!Number.isFinite(value)) {
                                console.error('invalid value, ignoring', value);
                                return;
                            }
                            applyWorldPosPatchTo(ids, (wm) => {
                                const pos = wm.extractPosition();
                                pos[component] = value;
                                wm.setPositionV(pos);
                            });
                        }
                    }));
                }

                pui.addMaybeChild(positionGroup);
            }

            const mergeRotations = this.globalTransform.get('rotation');
            if (mergeRotations instanceof GroupsCommonFieldsMerger) {
                const positionGroup = new PUI_GroupNode({name: 'rotation'});

                for (const component of ['x', 'y', 'z'] as const) {
                    const mergedComponent = mergeRotations.get(component);
                    if (mergedComponent instanceof CommonValueMerger) {
                        positionGroup.addMaybeChild(new PUI_PropertyNodeNumber({
                            name: component,
                            value: mergedComponent.nodeValue()!,
                            unit: 'deg',
                            step: 0.1,
                            onChange: (value) => {
                                if (!Number.isFinite(value)) {
                                    console.error('invalid value, ignoring', value);
                                    return;
                                }
                                applyWorldPosPatchTo(ids, (wm) => {
                                    const position = new Vector3();
                                    const rotation = new Quaternion();
                                    const scale = new Vector3();
                                    wm.decompose(position, rotation, scale);
                                    const newRotationEuler = new Euler().setFromQuaternion(rotation);
                                    newRotationEuler[component] = KrMath.degToRad(value);
                                    rotation.setFromEuler(newRotationEuler);
                                    wm.compose(position, rotation, scale);
                                });
                            }
                        }));
                    }
                }
                pui.addMaybeChild(positionGroup);
            }

            const mergedScale = this.globalTransform.get('scale');
            if (mergedScale instanceof GroupsCommonFieldsMerger) {
                const positionGroup = new PUI_GroupNode({name: 'scale'});

                for (const component of ['x', 'y', 'z'] as const) {
                    const mergedComponent = mergedScale.get(component);
                    if (mergedComponent instanceof CommonValueMerger) {
                        positionGroup.addMaybeChild(new PUI_PropertyNodeNumber({
                            name: component,
                            value: mergedComponent.nodeValue()!,
                            onChange: (value) => {
                                if (!Number.isFinite(value)) {
                                    console.error('invalid value, ignoring', value);
                                }
                                applyWorldPosPatchTo(ids, (wm) => {
                                    const position = new Vector3();
                                    const rotation = new Quaternion();
                                    const scale = new Vector3();
                                    wm.decompose(position, rotation, scale);
                                    scale[component] = value;
                                    wm.compose(position, rotation, scale);
                                });
                            }
                        }));
                    }
                }
                pui.addMaybeChild(positionGroup);
            }

        }

        const applyComplexPatch = (ids: IdBimScene[], patchProducer: (instance: SceneInstance) => SceneInstancePatch | null) => {
            const patches = IterUtils.filterMap(ids, (id) => {
                const instance = instances.peekById(id);
                if (!instance) {
                    return undefined;
                }
                const patch = patchProducer(instance);
                if (!patch) {
                    return undefined;
                }
                return [id, patch] as [IdBimScene, SceneInstancePatch];
            });
            instances.applyPatches(patches);
        }

        { // local transform props

            const pui = new PUI_GroupNode({
                name: 'local transform (relative to parent)',
            });
            transformUi.addMaybeChild(pui);


            const mergedPositions = this.localTransform.get('position');
            if (mergedPositions instanceof GroupsCommonFieldsMerger) {
                const positionGroup = new PUI_GroupNode({name: 'origin'});

                for (const component of ['x', 'y', 'z'] as const) {
                    const mergedComponent = mergedPositions.get(component);
                    if (mergedComponent instanceof CommonValueMerger) {
                        positionGroup.addMaybeChild(new PUI_PropertyNodeNumber({
                            name: component,
                            value: mergedComponent.nodeValue()!,
                            unit: 'm',
                            onChange: (value) => {
                                if (!Number.isFinite(value)) {
                                    console.error('invalid value, ignoring', value);
                                    return;
                                }
                                applyComplexPatch(ids, (instance) => {
                                    const st = instance.localTransform;
                                    const newPos = st.position.clone();
                                    newPos[component] = value;
                                    return {localTransform: new Transform(newPos, st.rotation, st.scale)};
                                });
                            }
                        }));
                    }
                }

                pui.addMaybeChild(positionGroup);
            }

            const mergeRotations = this.localTransform.get('rotation');
            if (mergeRotations instanceof GroupsCommonFieldsMerger) {
                const positionGroup = new PUI_GroupNode({name: 'rotation'});

                for (const component of ['x', 'y', 'z'] as const) {
                    const mergedComponent = mergeRotations.get(component);
                    if (mergedComponent instanceof CommonValueMerger) {
                        positionGroup.addMaybeChild(new PUI_PropertyNodeNumber({
                            name: component,
                            value: mergedComponent.nodeValue()!,
                            unit: 'deg',
                            step: 0.1,
                            onChange: (value) => {
                                if (!Number.isFinite(value)) {
                                    console.error('invalid value, ignoring', value);
                                    return;
                                }
                                applyComplexPatch(ids, (instance) => {
                                    const st = instance.localTransform;
                                    const newRotationEuler = new Euler().setFromQuaternion(st.rotation);
                                    newRotationEuler[component] = KrMath.degToRad(value);
                                    const newRotation = new Quaternion().setFromEuler(newRotationEuler);
                                    return {localTransform: new Transform(st.position, newRotation, st.scale)};
                                });
                            }
                        }));
                    }
                }
                pui.addMaybeChild(positionGroup);
            }

            const mergedScale = this.localTransform.get('scale');
            if (mergedScale instanceof GroupsCommonFieldsMerger) {
                const positionGroup = new PUI_GroupNode({name: 'scale'});

                for (const component of ['x', 'y', 'z'] as const) {
                    const mergedComponent = mergedScale.get(component);
                    if (mergedComponent instanceof CommonValueMerger) {
                        positionGroup.addMaybeChild(new PUI_PropertyNodeNumber({
                            name: component,
                            value: mergedComponent.nodeValue()!,
                            onChange: (value) => {
                                applyComplexPatch(ids, (instance) => {
                                    const st = instance.localTransform;
                                    const newScale = st.scale.clone();
                                    newScale[component] = value;
                                    return {localTransform: new Transform(st.position, st.rotation, newScale)};
                                });
                            }
                        }));
                    }
                }
                pui.addMaybeChild(positionGroup);
            }

            if (this.localDimensions.state() !== FieldsMergeState.Unmergable) {
                const centerNode = new PUI_GroupNode({name: 'dimensions'});
                for (const component of ['x', 'y', 'z'] as const) {
                    const mergedComponent = this.localDimensions.get(component);
                    if (!(mergedComponent instanceof CommonValueMerger)) {
                        continue;
                    }
                    centerNode.addMaybeChild(new PUI_PropertyNodeNumber({
                        name: component,
                        value: mergedComponent.nodeValue()!,
                        unit: 'm',
                        readonly: true,
                        onChange: () => {},
                    }));
                }
                pui.addMaybeChild(centerNode);
            }
        }

        const legacyPropsUi = buildLegacySelectionPropsUi({
            props: this.legacyPropertiesMerger,
            sharedTypeIdentifier: this.type_identifier.result(),
            ids,
            bim,
            catalog,
            mappingParams: this._mappingParams,
        });

        const propsPui = buildPropsUi({
            props: this.propsMerger,
            sharedTypeIdentifier: this.type_identifier.result(),
            ids,
            bim,
            catalog,
            asyncPropsUiRetriver: this.asyncPropsUiRetriver,
            mappingParams: this._mappingParams,
        });

        return {simplePropsUi, transformUi, legacyPropertiesPui: legacyPropsUi, propsPui : propsPui};
    }
}





function bimPropertiesToObjectTree(props: Iterable<BimProperty>) {
    const res = {};
    for (const p of props) {
        let r: any = res;
        for (let i = 0; i < p.path.length; ++i) {
            const pathPortion = p.path[i];
            if (i === p.path.length - 1) {
                r[pathPortion] = p;
            } else {
                r = r[pathPortion] || (r[pathPortion] = {});
            }
        }
    }
    return res;
}

const _eulerReused = new Euler();
const _quatReused = new Quaternion();

class EuelerTransform {
    constructor(
        public readonly position: Readonly<Vector3>,
        public readonly rotation: Readonly<Vector3>,
        public readonly scale: Readonly<Vector3>,
    ) {
    }

    static fromTransform(tr: Transform): EuelerTransform {

        const rotationEuler = _eulerReused.setFromQuaternion(tr.rotation);
        const rotation = rotationEuler.toVector3().multiplyScalar(RAD2DEG);
        return new EuelerTransform(
            tr.position,
            rotation,
            tr.scale
        );
    }

    static fromMatrix(m: Matrix4): EuelerTransform {
        const pos = new Vector3();
        const rotation = new Vector3();
        const scale = new Vector3();

        m.decompose(pos, _quatReused, scale);
        _eulerReused.setFromQuaternion(_quatReused);
        _eulerReused.toVector3(rotation);
        rotation.multiplyScalar(RAD2DEG);

        return new EuelerTransform(pos, rotation, scale);
    }
}

