<script lang="ts">
    import PropertyHelp from "../../PropertyHelp.svelte";
    import { BinsBuilder, PileTypeSettings, TrackerBins } from "bim-ts";
    import { onMount } from "svelte";
    import { createGrid, GridApi } from "ag-grid-enterprise";
    import PileGridWrapper from "./PileGridWrapper.svelte";
    import { PUI_CustomPropertyNode } from "ui-bindings";
    import {
        getTypesGridOptions,
        updateTableData,
    } from "./PileTypeGridOptions";

    export let property: PUI_CustomPropertyNode<
        TrackerBins,
        { binsBuilder: BinsBuilder }
    >;

    let gridRootRef: HTMLElement;

    let gridApi: GridApi<PileTypeSettings> | undefined;

    $: {
        updateTableData(gridApi, property.value);
    }

    onMount(() => {
        gridApi = createGrid(
            gridRootRef,
            getTypesGridOptions(property.value, property.context.binsBuilder),
        );
        return () => {
            gridApi?.destroy();
        };
    });
</script>

<div class="table-property">
    <div class="mdc-typography--headline5 table-header">
        <div>Pile types</div>
        <div class="show-mode">
            <span>Always show</span>
            <PropertyHelp
                name="show-pile-type"
                content="Enable this option to include the specified pile type in all pile reports, even if the quantity is zero.<br/>
Option will have no any effect if there is at least one pile of given type in the project."
            />
        </div>
    </div>

    <PileGridWrapper>
        <div bind:this={gridRootRef} class="ag-theme-balham hide-header" />
    </PileGridWrapper>
</div>

<style lang="scss">
    .table-property {
        width: 100%;
    }
    .table-header {
        padding-bottom: 4px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--color-divider);
    }
    .show-mode {
        display: flex;
        align-items: center;
        gap: 4px;
    }
</style>
