import { DefaultMap, TypedNumbersVec, Yield } from "engine-utils-ts";
import { Aabb, Aabb2, buildConcaveHull, ContoursOrientation, Delaunator, KrMath, pointInPolygon, PointsInPolygonChecker, Vector2, Vector3 } from "math-ts";
import { IrregularHeightmapGeometry } from "../geometries/IrregularHeightmapGeometries";



export function triangulateTerrainHeightmap(heightmap3dPoints: Float64Array): IrregularHeightmapGeometry {

    type TriangleEdge = number;

    enum TriangleStatus {
        None = 0,
        Included = 1,
    }

    class Triangle2D {

        public status: TriangleStatus = TriangleStatus.None;

        constructor(
            public readonly indices: [number, number, number],
            public readonly edges: [TriangleEdge, TriangleEdge, TriangleEdge],
            public readonly maxSideLength: number,
            public readonly perimeter: number,
            public readonly area: number,
            public readonly centroid: Vector2,
            public readonly perimeterExceptSmallest: number,
        ) {

        }



        get elongation() {
            return (this.perimeter / this.area) || 0;
        }

        static new(ind1: number, ind2: number, ind3: number, points: Vector2[]) {
            const p1 = points[ind1];
            const p2 = points[ind2];
            const p3 = points[ind3];

            const centroid = p1.clone().add(p2).add(p3).divideScalar(3);

            const sideLength1 = p1.distanceTo(p2);
            const sideLength2 = p2.distanceTo(p3);
            const sideLength3 = p3.distanceTo(p1);
            const maxSide = Math.max(sideLength1, sideLength2, sideLength3);
            const minSide = Math.min(sideLength1, sideLength2, sideLength3);
            const perimeter = sideLength1 + sideLength2 + sideLength3;
            
            //Heron's formula
            const s = perimeter * 0.5;
            const area = Math.sqrt(s * (s - sideLength1) * (s - sideLength2) * (s - sideLength3));
            return new Triangle2D(
                [ind1, ind2, ind3],
                [
                    Triangle2D.newEdge(ind1, ind2),
                    Triangle2D.newEdge(ind2, ind3),
                    Triangle2D.newEdge(ind3, ind1),
                ],
                maxSide,
                perimeter,
                area,
                centroid,
                perimeter - minSide,
            );
        }

        static newEdge(ind1: number, ind2: number): TriangleEdge {
            if (ind2 < ind1) {
                let t = ind2;
                ind2 = ind1;
                ind1 = t;
            }
            if (ind2 > 0xFF_FFFF) { // 25 bits maximum per number
                throw new Error('index for edge is out of bounds ' + ind2);
            }
            const edge = ((ind1 & 0xFF_FFFF) * 0x100_0000 + (ind2 & 0xFF_FFFF));
            if (!Number.isSafeInteger(edge)) {
                throw new Error('edge is unsafe integer, out of bounds ' + edge);
            }
            return edge;
        }

        static edgeToTuple(edge: TriangleEdge): [number, number] {
            const id2 = (edge & 0xFF_FFFF);
            const id1 = ((edge - id2) / 0x100_0000);
            return [id1, id2];
        }
    }



    class TrianglesGroup {
        tris: Triangle2D[] = [];
        
        static new(): TrianglesGroup {
            return new TrianglesGroup();
        }
    
        add(tr: Triangle2D) {
            this.tris.push(tr);
        }
    
        sortWorstToBest() {
            if (this.tris.length > 1) {
                this.tris.sort(TrianglesGroup.worstToBestTrisSortFn)
            }
        }
    
        static worstToBestTrisSortFn(tr1: Triangle2D, tr2: Triangle2D) {
            return tr2.perimeterExceptSmallest - tr1.perimeterExceptSmallest;
        }
    }

    
    const points3dVectors = Vector3.arrayFromFlatArray(heightmap3dPoints);
    const aabb = Aabb.calcFromArray(heightmap3dPoints);
    const aabb2 = Aabb2.fromCenterAndSize(aabb.getCenter_t().xy(), aabb.getSize().xy());

    points3dVectors.sort((v1, v2) => {
        return KrMath.zOrder(v1.x, v1.y, aabb2) - KrMath.zOrder(v2.x, v2.y, aabb2)
    });

    const points2dVectors = points3dVectors.map(v => Object.freeze(v.xy()));

    const points2dFlat: number[] = [];
    for (const p of points2dVectors) {
        points2dFlat.push(p.x, p.y);
    }
    
    const triangulation = new Delaunator(points2dFlat);
    
    let allTriangles2d: Triangle2D[] = [];

    const trianglesPerPointIndex = new DefaultMap<number, TrianglesGroup>(TrianglesGroup.new);
    const trianglesPerEdge = new DefaultMap<TriangleEdge, TrianglesGroup>(TrianglesGroup.new);

    {
        const tris = triangulation.triangles;
        for (let i = 0; i < tris.length; i += 3) {
            const triangle = Triangle2D.new(
                tris[i + 0],
                tris[i + 1],
                tris[i + 2],
                points2dVectors
            );
            allTriangles2d.push(triangle);
            for (const ind of triangle.indices) {
                trianglesPerPointIndex.getOrCreate(ind).add(triangle);
            }
            for (const edge of triangle.edges) {
                trianglesPerEdge.getOrCreate(edge).add(triangle);
            }
        }
        for (const gr of trianglesPerPointIndex.values()) {
            gr.sortWorstToBest();
            // for (let i = gr.tris.length - 1; i >= Math.max(0, gr.tris.length - 2); --i) {
            // 	const tr = gr.tris[i];
            // 	tr.canDisable = false;
            // }
        }
        for (const gr of trianglesPerEdge.values()) {
            gr.sortWorstToBest();
        }
    }

    allTriangles2d.sort((tr1, tr2) => {
        return KrMath.zOrder(tr1.centroid.x, tr1.centroid.y, aabb2) - KrMath.zOrder(tr2.centroid.x, tr2.centroid.y, aabb2);
    })


    interface TrianglesStats {
        perc50Value: number,
        perc75Value: number,
        perc90Value: number,
        perc95Value: number,
        softOutliersStart: number,
        hardOutliersStart: number,
        maxValue: number,
    }

    function getTriangleParamStats(triangles: Triangle2D[], accessor: (tr: Triangle2D) => number): TrianglesStats {
        const trianglesSorted = triangles.slice().sort(
            (tr1, tr2) => accessor(tr1) - accessor(tr2)
        );
        const q1 = accessor(trianglesSorted[(trianglesSorted.length * 0.25) | 0]);
        const q3 = accessor(trianglesSorted[(trianglesSorted.length * 0.75) | 0]);
        const softOutliersStart = q3 + (q3 - q1) * 1.5;
        const hardOutliersStart = q3 + (q3 - q1) * 10;

        const perc50Value = accessor(trianglesSorted[(trianglesSorted.length * 0.5) | 0]);
        const perc75Value = accessor(trianglesSorted[(trianglesSorted.length * 0.75) | 0]);
        const perc90Value = accessor(trianglesSorted[(trianglesSorted.length * 0.9) | 0]);
        const perc95Value = accessor(trianglesSorted[(trianglesSorted.length * 0.95) | 0]);

        return {
            perc50Value,
            perc75Value,
            perc90Value,
            perc95Value,
            softOutliersStart,
            hardOutliersStart,
            maxValue: accessor(trianglesSorted.at(-1)!),
        }
    }


    const byPerimeter2 = getTriangleParamStats(allTriangles2d, tr => tr.perimeterExceptSmallest);

    console.log('byPerimeter2', byPerimeter2);

    const trianglesNeighboursByEdges = new DefaultMap<Triangle2D, Triangle2D[]>(triangle => {
        const res: Triangle2D[] = [];
        for (const edge of triangle.edges) {
            const trisByEdge = trianglesPerEdge.get(edge)!;
            // maximum 2 triangles should be possible for every edge
            // on of which is our smallTri
            console.assert(trisByEdge.tris.length <= 2, 'tris count per edge sanity check');
            for (const tr of trisByEdge.tris) {
                if (tr !== triangle) {
                    res.push(tr);
                }
            }
        }
        return res;
    });
    
    // 1st step
    // mark the triangles with the highest probablity of being necessary

    // include smallest by perimeter triangle near every vertex
    // it is not 100% correct
    // in some cases small triangles near the edges of concave polygons
    // that should not be included, will be included
    // but the error rate is not significant to the end result
    {
        for (const gr of trianglesPerPointIndex.values()) {
            const smalletTr = gr.tris.at(-1)!;
            smalletTr.status = TriangleStatus.Included;

            for (let i = 2; i <= gr.tris.length; ++i) {
                const tr2 = gr.tris.at(-i)!;
                if (tr2.perimeterExceptSmallest / smalletTr.perimeterExceptSmallest < 2
                    || tr2.perimeterExceptSmallest < byPerimeter2.perc90Value
                ) {
                    tr2.status = TriangleStatus.Included;
                }
            }
        }
    }

    
    // 2nd step
    // get neighbours by edges of every smallest triangle
    // and include them also, if they are sufficiently small
    // then repeat for newly included triangles
    function includeTrianglesWithIncludedNeighours() {
        let trianglesToCheckNeighboursFor = allTriangles2d.filter(tr => tr.status !== TriangleStatus.Included);
        for (let i = 0; trianglesToCheckNeighboursFor.length > 0 && i < 100; ++i) {
            const newTrisToCheck: Triangle2D[] = [];
            for (const smallTri of trianglesToCheckNeighboursFor) {
                const neighbourTris = trianglesNeighboursByEdges.getOrCreate(smallTri);
                for (const tr of neighbourTris) {
                    if (tr.status != TriangleStatus.Included && tr.perimeterExceptSmallest < byPerimeter2.perc90Value) {
                        tr.status = TriangleStatus.Included;
                        newTrisToCheck.push(tr);
                    }
                }
            }
            trianglesToCheckNeighboursFor = newTrisToCheck;
        }
    }
    includeTrianglesWithIncludedNeighours();

    function includeTrisWithOnly1ExcludedNeighbour() {
        let trisToMaybeEnable = allTriangles2d.filter(tr => tr.status != TriangleStatus.Included);
        for (let iter = 0; iter < 10 && trisToMaybeEnable.length > 0; ++iter) {

            trisToMaybeEnable.sort((tr1, tr2) => tr1.perimeter - tr2.perimeter);

            const trisToRecheck: Triangle2D[] = [];
            const trisToEnable: Triangle2D[] = [];

            outer: for (const tr of trisToMaybeEnable) {
                const neighbours = trianglesNeighboursByEdges.getOrCreate(tr);
                let disablableNeigbourTri: Triangle2D | null = null;
                let includedPerimetersSum: number = 0;
                let includedPerimetersCount: number = 0;
                for (const ntr of neighbours) {
                    if (ntr.status !== TriangleStatus.Included) {
                        if (disablableNeigbourTri === null) {
                            disablableNeigbourTri = ntr;
                        } else {
                            // more than one excluded neighbour
                            continue outer;
                        }
                    }
                }
                if (includedPerimetersCount === 0) {
                    continue;
                }
                const averageIncludedPerimeter = includedPerimetersSum / includedPerimetersCount;
                if (tr.perimeter > 2 * averageIncludedPerimeter) {
                    continue;
                }
                trisToEnable.push(tr);
                if (disablableNeigbourTri) {
                    trisToRecheck.push(disablableNeigbourTri);
                }
            }
            console.log('surrounded by only 1 disabled to enable', trisToEnable.length);
            for (const tr of trisToEnable) {
                tr.status = TriangleStatus.Included;
            }
            trisToMaybeEnable = trisToRecheck;
        }
    }
    // includeTrisWithOnly1ExcludedNeighbour();

    const outerShellTriangles: Triangle2D[] = [];
    {
        for (const trisGroup of trianglesPerEdge.values()) {
            if (trisGroup.tris.length === 1) {
                const outsideTriangle = trisGroup.tris[0];
                if (!outerShellTriangles.includes(outsideTriangle)) {
                    outerShellTriangles.push(outsideTriangle);
                }
            }
        }
        Object.freeze(outerShellTriangles);
    }

    
    function calculateNotIncludedTrianglesDistanceToOutside(){
        const trianglesDistances = new Map<Triangle2D, number>();
        // find distance to outside border in triangle hops for each not included triangle
        function addDisabledTrianglesDistances(triangles: Triangle2D[], distance: number) {
            const nextStepTris: Triangle2D[] = [];
            for (const tr of triangles) {
                if (tr.status === TriangleStatus.Included) {
                    continue;
                }
                if (trianglesDistances.has(tr)) {
                    continue;
                }
                trianglesDistances.set(tr, distance);
                for (const ntr of trianglesNeighboursByEdges.getOrCreate(tr)) {
                    nextStepTris.push(ntr);
                }
            }
            if (nextStepTris.length > 0) {
                addDisabledTrianglesDistances(nextStepTris, distance + 1);
            }
        }
        addDisabledTrianglesDistances(outerShellTriangles, 1);
        return trianglesDistances;
    }

    function includeTrianglesNotConnectedToOuside() {

        // enable all triangles that are not connected to outside
        const distancesToOutside = calculateNotIncludedTrianglesDistanceToOutside();
        const trisNoConnected = allTriangles2d.filter(tr => tr.status !== TriangleStatus.Included && !distancesToOutside.has(tr));
        console.log('not connected to ouside tris', trisNoConnected.length);
        for (const tr of trisNoConnected) {
            tr.status = TriangleStatus.Included;
        }
    }
    includeTrianglesNotConnectedToOuside();



    // set cluster id for every point
    const pointsClusterIds = points2dVectors.map((v) => 0);
    const pointsPerClusters = new DefaultMap<number, number[]>(() => []);
    {

        function assignClusterToPointAndNeighbours(pointIndexToAssign: number, clusterId: number) {
            const pointsToCheck: number[] = [pointIndexToAssign];

            const pointsVisited = new Set<number>();

            while (pointsToCheck.length) {
                const pointIndex = pointsToCheck.pop()!;
                pointsClusterIds[pointIndex] = clusterId;
                pointsVisited.add(pointIndex);

                for (const tr of trianglesPerPointIndex.getOrCreate(pointIndex).tris) {
                    if (tr.status !== TriangleStatus.Included) {
                        continue;
                    }
                    for (const neighbourPointIndex of tr.indices) {
                        if (pointsVisited.has(neighbourPointIndex)) {
                            continue;
                        }
                        pointsToCheck.push(neighbourPointIndex);
                    }
                }
            }
        }

        let nextFreeClusterId = 1;
        for (let index = 0; index < points2dVectors.length; ++index) {
            let cluster = pointsClusterIds[index];
            if (cluster > 0) {
                continue;
            }
            cluster = nextFreeClusterId += 1;
            assignClusterToPointAndNeighbours(index, cluster);
        }

        for (let index = 0; index < points2dVectors.length; ++index) {
            const clusterId = pointsClusterIds[index];
            console.assert(clusterId > 0, 'cluster id for point is set');
            let pointsIndsArray = pointsPerClusters.getOrCreate(clusterId);
            pointsIndsArray.push(index);
        }
    }
    console.log('clusters', pointsPerClusters);

    {
        // now get border for every cluster
        const allPointsAsTuples = points2dVectors.map(v => [v.x, v.y]);
        const pointsIndsPerTuples = new Map<number[], number>();
        for (let i = 0; i < allPointsAsTuples.length; ++i) {
            pointsIndsPerTuples.set(allPointsAsTuples[i], i);
        }

        const borderTrianglesInside = new Set<Triangle2D>();
        const borderTrianglesOutside = new Set<Triangle2D>();
        for (const tr of allTriangles2d) {
            // tr.status = TriangleStatus.None;
        }

        let clusters = Array.from(pointsPerClusters.values()).sort((c1, c2) => c2.length - c1.length);

        for (const pointsInds of clusters) {

            const trianglesInsideClusterSet = new Set<Triangle2D>();

            for (const pointIndex of pointsInds) {
                const tris = trianglesPerPointIndex.getOrCreate(pointIndex)!.tris;
                for (const tr of tris) {
                    if (tr.status === TriangleStatus.Included) {
                        trianglesInsideClusterSet.add(tr);
                    }
                }
            }

            const trianglesInsideCluster = Array.from(trianglesInsideClusterSet);

            const borderTrianglesInsideCluster = trianglesInsideCluster.filter(tr => {
                const neightbours = trianglesNeighboursByEdges.getOrCreate(tr);
                let countIncluded = 0;
                for (const ntr of neightbours) {
                    if (ntr.status === TriangleStatus.Included) {
                        countIncluded += 1;
                    }
                }
                if (countIncluded < 3) {
                    return tr;
                }
                return undefined;
            });

            const borderByPerimeter2 = getTriangleParamStats(borderTrianglesInsideCluster, tr => tr.perimeterExceptSmallest);

            const pointsAsTuples = pointsInds.map(ind => allPointsAsTuples[ind]);
            const concavePolygonn = buildConcaveHull(
                pointsAsTuples as [number, number][],
                1.5,
                borderByPerimeter2.perc95Value / 2
            );

            const concavePolygonIndices = concavePolygonn.map(p => pointsIndsPerTuples.get(p)!);

            const borderTrianglesToRetest = new Set<Triangle2D>(concavePolygonIndices.flatMap(pointIndex => {
                return trianglesPerPointIndex.get(pointIndex)!.tris;
            }));
            for (const tr of borderTrianglesInsideCluster) {
                borderTrianglesToRetest.add(tr);
            }

            for (const tr of borderTrianglesToRetest) {
                if (tr.perimeterExceptSmallest > borderByPerimeter2.maxValue) {
                    continue;
                }
                const isTriangleInsidePolygonn = pointInPolygon(
                    [tr.centroid.x, tr.centroid.y],
                    concavePolygonn
                );
                if (isTriangleInsidePolygonn) {
                    borderTrianglesInside.add(tr);
                } else {
                    borderTrianglesOutside.add(tr);
                }
            }

        }

        for (const tr of borderTrianglesInside) {
            borderTrianglesOutside.delete(tr);
            tr.status = TriangleStatus.Included;
        }
        for (const tr of borderTrianglesOutside) {
            tr.status = TriangleStatus.None;
        }
    }
    includeTrianglesNotConnectedToOuside();

    const geoFlatTriangles: number[] = [];
    for (const tr of allTriangles2d.filter(tr => tr.status == TriangleStatus.Included)) {
        geoFlatTriangles.push( // CHANGE TRIANGLE ORIENTATION
            tr.indices[0],
            tr.indices[2],
            tr.indices[1],
        );
    }

	const g = new IrregularHeightmapGeometry(
        new Float64Array(points3dVectors.flatMap(p => [p.x, p.y, p.z])),
        new Uint32Array(geoFlatTriangles)
	);
	return g;
}


export function *triangulateSurface(points3dFlat: Float64Array|number[], contours3dPoints: (Float64Array|number[])[]): Generator<Yield, IrregularHeightmapGeometry> {
    
    const points2dFlat = new Float64Array(2 * points3dFlat.length / 3);
    for (let i = 0; i < points3dFlat.length / 3; ++i) {
        points2dFlat[2 * i] = points3dFlat[3 * i];
        points2dFlat[2 * i + 1] = points3dFlat[3 * i + 1];
    }
    
    const contours2dPoints: Vector2[][] = [];
    for (const contour3d of contours3dPoints) {
        const contour2d = [];
        for (let i = 0; i < contour3d.length; i += 3) {
            contour2d.push(new Vector2(contour3d[i], contour3d[i+1]));
        }
        
        if(contour2d.length){
            contours2dPoints.push(contour2d);
        }
        
    }

    let polygonsChecker: PointsInPolygonChecker | undefined = undefined;
    if(contours2dPoints.length){
        polygonsChecker = PointsInPolygonChecker.fromContoursOrientation(contours2dPoints, ContoursOrientation.FirstInner);
    }
    

    yield Yield.Asap;

    const triangulation = new Delaunator(points2dFlat);

    yield Yield.Asap;

    const tris = triangulation.triangles;
    const usedTrianglesFlat = new TypedNumbersVec<Uint32Array>(s => new Uint32Array(s), points3dFlat.length);
    for (let i = 0; i < tris.length; i += 3) {
        const centroid = new Vector2(
            (points2dFlat[2 * tris[i]] + points2dFlat[2 * tris[i+1]] + points2dFlat[2 * tris[i+2]]) / 3,
            (points2dFlat[2 * tris[i] + 1] + points2dFlat[2 * tris[i+1] + 1] + points2dFlat[2 * tris[i+2] + 1]) / 3
        );

        if(polygonsChecker === undefined  || polygonsChecker.isPointInside(centroid)){
            usedTrianglesFlat.push3(tris[i], tris[i+2], tris[i+1]);
        }
    }

    yield Yield.Asap;

	const g = new IrregularHeightmapGeometry(
        new Float64Array(points3dFlat),
        usedTrianglesFlat.view().slice(),
	);
	return g;
}