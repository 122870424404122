// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';



export class GraphGeometry implements flatbuffers.IUnpackableObject<GraphGeometryT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):GraphGeometry {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsGraphGeometry(bb:flatbuffers.ByteBuffer, obj?:GraphGeometry):GraphGeometry {
  return (obj || new GraphGeometry()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsGraphGeometry(bb:flatbuffers.ByteBuffer, obj?:GraphGeometry):GraphGeometry {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new GraphGeometry()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

points(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readFloat64(this.bb!.__vector(this.bb_pos + offset) + index * 8) : 0;
}

pointsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

pointsArray():Float64Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? new Float64Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

pointsLocalIds(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
}

pointsLocalIdsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

pointsLocalIdsArray():Uint32Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? new Uint32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

edgesTuplesFlat(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readUint32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
}

edgesTuplesFlatLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

edgesTuplesFlatArray():Uint32Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? new Uint32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

static startGraphGeometry(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addPoints(builder:flatbuffers.Builder, pointsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, pointsOffset, 0);
}

static createPointsVector(builder:flatbuffers.Builder, data:number[]|Float64Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createPointsVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createPointsVector(builder:flatbuffers.Builder, data:number[]|Float64Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(8, data.length, 8);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addFloat64(data[i]!);
  }
  return builder.endVector();
}

static startPointsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(8, numElems, 8);
}

static addPointsLocalIds(builder:flatbuffers.Builder, pointsLocalIdsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, pointsLocalIdsOffset, 0);
}

static createPointsLocalIdsVector(builder:flatbuffers.Builder, data:number[]|Uint32Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createPointsLocalIdsVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createPointsLocalIdsVector(builder:flatbuffers.Builder, data:number[]|Uint32Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt32(data[i]!);
  }
  return builder.endVector();
}

static startPointsLocalIdsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addEdgesTuplesFlat(builder:flatbuffers.Builder, edgesTuplesFlatOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, edgesTuplesFlatOffset, 0);
}

static createEdgesTuplesFlatVector(builder:flatbuffers.Builder, data:number[]|Uint32Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createEdgesTuplesFlatVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createEdgesTuplesFlatVector(builder:flatbuffers.Builder, data:number[]|Uint32Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt32(data[i]!);
  }
  return builder.endVector();
}

static startEdgesTuplesFlatVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endGraphGeometry(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createGraphGeometry(builder:flatbuffers.Builder, pointsOffset:flatbuffers.Offset, pointsLocalIdsOffset:flatbuffers.Offset, edgesTuplesFlatOffset:flatbuffers.Offset):flatbuffers.Offset {
  GraphGeometry.startGraphGeometry(builder);
  GraphGeometry.addPoints(builder, pointsOffset);
  GraphGeometry.addPointsLocalIds(builder, pointsLocalIdsOffset);
  GraphGeometry.addEdgesTuplesFlat(builder, edgesTuplesFlatOffset);
  return GraphGeometry.endGraphGeometry(builder);
}

unpack(): GraphGeometryT {
  return new GraphGeometryT(
    this.bb!.createScalarList<number>(this.points.bind(this), this.pointsLength()),
    this.bb!.createScalarList<number>(this.pointsLocalIds.bind(this), this.pointsLocalIdsLength()),
    this.bb!.createScalarList<number>(this.edgesTuplesFlat.bind(this), this.edgesTuplesFlatLength())
  );
}


unpackTo(_o: GraphGeometryT): void {
  _o.points = this.bb!.createScalarList<number>(this.points.bind(this), this.pointsLength());
  _o.pointsLocalIds = this.bb!.createScalarList<number>(this.pointsLocalIds.bind(this), this.pointsLocalIdsLength());
  _o.edgesTuplesFlat = this.bb!.createScalarList<number>(this.edgesTuplesFlat.bind(this), this.edgesTuplesFlatLength());
}
}

export class GraphGeometryT implements flatbuffers.IGeneratedObject {
constructor(
  public points: (number)[] = [],
  public pointsLocalIds: (number)[] = [],
  public edgesTuplesFlat: (number)[] = []
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const points = GraphGeometry.createPointsVector(builder, this.points);
  const pointsLocalIds = GraphGeometry.createPointsLocalIdsVector(builder, this.pointsLocalIds);
  const edgesTuplesFlat = GraphGeometry.createEdgesTuplesFlatVector(builder, this.edgesTuplesFlat);

  return GraphGeometry.createGraphGeometry(builder,
    points,
    pointsLocalIds,
    edgesTuplesFlat
  );
}
}
