import { LegacyLogger, TypedNumbersVec } from 'engine-utils-ts';
import { TransformFloats, Vector3 } from 'math-ts';

import type { Camera } from '../3rdParty/three';
import type { HashedUniforms} from '../composer/DynamicUniforms';
import { EmptyHashedUniforms } from '../composer/DynamicUniforms';
import type { RenderJob } from '../composer/RenderJob';
import type { EngineMaterialIdFlags } from '../pools/EngineMaterialId';
import type { SortableVecWasm } from '../pools/SortableVec';
import type { LodMask } from '../scene/Submeshes2';
import { ObjectMaxBatchCount, PerObjectBlockSizeInFloats } from '../shaders/shaders_chunks';
import type { GeometryGpuId } from './GpuGeometries';
import { SubmeshInstancesInfoRaw, SubmeshRawDataSize, type SubmeshesInstancingRawBlocks } from 'src/scene/SubmeshesInstancingRawBlocks';

// export interface RenderJobsMerger {
// 	merge(rjs: RenderJob[]): RenderJobsMerged[];
// }

// export class GenericJobsMerger {

// }




export class RenderJobsMerged {

	geoId : GeometryGpuId = 0;
	lod: LodMask|0 = 0;
	materialId: EngineMaterialIdFlags = 0;
	dynamicUniforms: HashedUniforms = EmptyHashedUniforms;

	transforms_instanced_start: number = 0;
	transforms_instanced_end: number = 0;
	ubo_offset: number = 0;
	ubo_id: number = 0;
	ubo_instanceOffset: number = 0;

	constructor() {
	}

	init(geo: GeometryGpuId, lod: LodMask, material: EngineMaterialIdFlags, uniforms: HashedUniforms) {
		this.geoId = geo;
		this.lod = lod;
		this.materialId = material;
		this.dynamicUniforms = uniforms;
		this.transforms_instanced_start = 0;
		this.transforms_instanced_end = 0;
		this.ubo_id = 0;
		this.ubo_offset = 0;
		this.ubo_instanceOffset = 0;
	}

	instance_count(): number {
		return (this.transforms_instanced_end - this.transforms_instanced_start) / PerObjectBlockSizeInFloats;
	}

	addInstancesFromRaw(result: TypedNumbersVec<Float32Array>,  sourceData: Float32Array, sourceStart: number, sourceEnd: number, worldOrigin: Vector3) {
		LegacyLogger.debugAssert(this.instance_count() < ObjectMaxBatchCount, 'render jobs merge instance count overflow check');
		LegacyLogger.debugAssert(sourceData.length > sourceStart, 'addInstanceFromBin overflow check');
		LegacyLogger.debugAssert(sourceData.length >= sourceEnd, 'addInstanceFromBin overflow check');

		if (this.transforms_instanced_end === 0) {
			this.transforms_instanced_start = result.length;
		}

		//looks like allocating subarray and setting buffer data directly with buffer.set() call works faster
		//than acessing elements by index, but yet not faster than with no instancing of submeshes
		//for a reference: 
		//project with 3_000 scene instances results in merge jobs beign done in: no instancing ~ 0.5ms, subarray ~ 1ms, indexing ~ 3ms
		//project with 75_000 scene instances results in merge jobs beign done in: no instancing ~ 14ms, subarray ~ 15ms, indexing ~ 16ms
		const dataToCopy = sourceData.subarray(sourceStart, sourceEnd);
		result.extendWith(dataToCopy);

		if (worldOrigin.lengthSq() !== 0) {
			const instancesCount = (sourceEnd - sourceStart) / SubmeshRawDataSize;
			for(let i = 1; i <= instancesCount; ++i) {
				const buf = result.buffer;
				const offsetToPosition = result.length - i * SubmeshRawDataSize + 12;
				buf[offsetToPosition + 0] -= worldOrigin.x; // sourceData[offset + 12]
				buf[offsetToPosition + 1] -= worldOrigin.y; // sourceData[offset + 13]
				buf[offsetToPosition + 2] -= worldOrigin.z; // sourceData[offset + 14]
			}
		}
		this.transforms_instanced_end = result.length;
	}
}

export interface RJsMergeSettings {
	sort: boolean,
}

export interface MergedJobs {
	jobsCount: number,
	mergedJobs: RenderJobsMerged[],
	transforms: TypedNumbersVec<Float32Array>,
	worldOrigin: Vector3,
}


export class RenderJobsMergedPool {

	readonly rjs: RenderJobsMerged[] = [];
	_used_counter: number = 0;
	worldOrigin: Vector3 = new Vector3(0, 0, 0);
	transforms: TypedNumbersVec<Float32Array> = new TypedNumbersVec(size => new Float32Array(size), TransformFloats * 500);

	constructor(size_hint: number) {
		for (let i = 0; i < size_hint; ++i) {
			this.rjs.push(new RenderJobsMerged());
		}
	}

	reset(camera: Readonly<Camera>) {
		// this.worldOrigin.setFromMatrixColumn(camera.matrixWorld as any, 2);
		// this.worldOrigin.normalize();
		// this.worldOrigin.multiplyScalar(-1 * camera.near);
		// this.worldOrigin.add(camera.position);
		this._used_counter = 0;
		this.transforms.clear();
	}

	get(geo: GeometryGpuId, lod: LodMask, material: EngineMaterialIdFlags, uniforms: HashedUniforms): RenderJobsMerged {
		if (this._used_counter == this.rjs.length) {
			this.allocate_more();
		}
		const rj = this.rjs[this._used_counter];
		this._used_counter += 1;
		rj.init(geo, lod, material, uniforms);
		return rj;
	}


	allocate_more() {
		for (let i = 0; i < 400; ++i) {
			this.rjs.push(new RenderJobsMerged());
		}
	}

	mergeJobs(
		rjs: SortableVecWasm<RenderJob>,
		rawData: SubmeshesInstancingRawBlocks,
		s: RJsMergeSettings
	): MergedJobs {
		if (rjs.len() === 0) {
			return { worldOrigin: this.worldOrigin, mergedJobs: [], transforms: this.transforms, jobsCount: 0};
		}
		// if (s.sort) {
		// 	// now merge
		// 	rjs.sort(renderJobsSort);
		// }
		const offsets = rawData.instancingBufferRef;

		const mergedJobs: RenderJobsMerged[] = [];
		let lastRj: RenderJob | null = null;
		let currentMerge: RenderJobsMerged | null = null;
		const initialLen = rjs.len();

		for (const rj of rjs.consumeIter(s.sort)) {
			if (!currentMerge || !rj.can_merge(lastRj!)) {
				lastRj = rj;
				currentMerge = this.get(rj.geometryId, rj.object_lod, rj.materialIDF, rj.dynamicUniforms);
				mergedJobs.push(currentMerge);
			}

			rawData.getSubmeshInstancesInfoRaw(rj.object_index, reusedSubmeshInstancesInfo);
			
			while(reusedSubmeshInstancesInfo.instancesCount > 0) {
				let currentMergeInstancesCount = currentMerge!.instance_count();

				if (currentMergeInstancesCount === ObjectMaxBatchCount) {
					currentMerge = this.get(lastRj!.geometryId, lastRj!.object_lod, lastRj!.materialIDF, lastRj!.dynamicUniforms);
					mergedJobs.push(currentMerge);
					currentMergeInstancesCount = 0;
				}

				const instancesCountToAddToMerge = Math.min(ObjectMaxBatchCount - currentMergeInstancesCount, reusedSubmeshInstancesInfo.instancesCount);
				const sourceEnd = reusedSubmeshInstancesInfo.instancesOffset + instancesCountToAddToMerge * SubmeshRawDataSize;
				currentMerge.addInstancesFromRaw(this.transforms, offsets, reusedSubmeshInstancesInfo.instancesOffset, sourceEnd, this.worldOrigin);
				reusedSubmeshInstancesInfo.instancesCount -= instancesCountToAddToMerge;
				reusedSubmeshInstancesInfo.instancesOffset = sourceEnd;
			}
		}

		// console.log(`merged$:${mergedJobs.length}, initial: ${initialLen}`)
		// LegacyLogger.debugAssert(mergedJobs.reduce((sum, rj) => sum += rj.instance_count(), 0) == initialLen, 'render jobs merge count check');
		return { worldOrigin: this.worldOrigin, mergedJobs, transforms: this.transforms, jobsCount: initialLen};
		
	}
}


export class Pool<T> {
	
	readonly _factory: () => T;
	readonly _objects: T[] = [];
	_used_counter: number = 0;

	constructor(factory: () => T) {
		this._factory = factory;
	}

	allocate_more() {
		const nextSize = Math.max(this._objects.length, 30) * 2;
		for (let i = this._objects.length; i < nextSize; ++i) {
			this._objects.push(this._factory());
		}
	}

	reset() {
		this._used_counter = 0;
	}

	get(): T {
		if (this._used_counter == this._objects.length) {
			this.allocate_more();
		}
		const o = this._objects[this._used_counter];
		this._used_counter += 1;
		return o;
	}
}

const reusedSubmeshInstancesInfo: SubmeshInstancesInfoRaw = new SubmeshInstancesInfoRaw();