import type { UndoStack} from 'engine-utils-ts';
import { WorkerClassPassRegistry } from 'engine-utils-ts';
import { Aabb, Vector3 } from 'math-ts';

import { BimGeometryType } from './BimGeometries';
import { triangleArea } from '../terrain/TriangleUtils';
import type { BimGeometryBase } from './BimGeometriesBase';
import { BimGeometriesBase } from './BimGeometriesBase';

export class IrregularHeightmapGeometry implements BimGeometryBase {
    constructor(
        public readonly points3d: Float64Array = new Float64Array(),
        public readonly trianglesIndices: Uint32Array = new Uint32Array(),
    ) {
    }
	calcAabb(): Aabb {
		return Aabb.calcFromArray(this.points3d);
	}
	checkForErrors(errors: string[]): void {
		if (this.points3d.length === 0) {
			errors.push('points array is empty');
		}
		if (this.points3d.length % 3 !== 0) {
			errors.push(`points array length should be mutliple of 3, actual=${this.points3d.length}`);
		}
		if (this.points3d.includes(NaN)) {
			errors.push('points array contains NaN');
		}
	}

    area() {
        let totalArea = 0;
    
        const v0 = new Vector3();
        const v1 = new Vector3();
        const v2 = new Vector3();
    
        for (let indsOffset = 0; indsOffset < this.trianglesIndices.length; indsOffset +=3) {
    
            const ind0 = this.trianglesIndices[indsOffset + 0];
            const ind1 = this.trianglesIndices[indsOffset + 1];
            const ind2 = this.trianglesIndices[indsOffset + 2];
            v0.setFromArray(this.points3d, ind0 * 3);
            v1.setFromArray(this.points3d, ind1 * 3);
            v2.setFromArray(this.points3d, ind2 * 3);
            const area = Math.abs(triangleArea(v0, v1, v2));
            totalArea += area;
        }
        return totalArea;
    }
}
WorkerClassPassRegistry.registerClass(IrregularHeightmapGeometry);


export class IrregularHeightmapGeometries extends BimGeometriesBase<IrregularHeightmapGeometry> {

    constructor(
        undoStack?: UndoStack,
    ) {
        super({
            identifier: "irregular-heightmap-geometries",
            idsType: BimGeometryType.HeightmapIrregular,
            undoStack,
            T_Constructor: IrregularHeightmapGeometry
        })
    }
}
