import { DefaultMap, IterUtils, LegacyLogger } from 'engine-utils-ts';
import type { Builder } from 'flatbuffers';

import type {
    ObjectRepresentation} from '../';
import {
    HeightmapRepresentation, SceneImageRepresentation, StdMeshRepresentation,
    StdSubmeshRepresentation,
    TerrainTile,
    TerrainTileId,
} from '../';
import {
    BasicAnalyticalRepresentation, StdGroupedMeshRepresentation, StdSubmeshesLod, SubmeshGroupRepresentation, TerrainHeightMapRepresentation, 
} from '../representation/Representations';
import {
    BasicAnalyticalRepresentation as BasicAnalyticalRepresentationF,
} from '../schema/basic-analytical-representation';
import {
    HeightmapRepresentation as HeightmapRepresentationF,
} from '../schema/heightmap-representation';
import {
    SceneImageRepresentation as SceneImageRepresentationF,
} from '../schema/scene-image-representation';
import {
    SceneObjectAnalyticalRepresentation,
} from '../schema/scene-object-analytical-representation';
import {
    SceneObjectRepresentation,
} from '../schema/scene-object-representation';
import {
    StdMeshRepresentation as StdMeshRepresentationF,
} from '../schema/std-mesh-representation';
import {
    StdSubmeshRepresentation as StdSubmeshRepresentationF,
} from '../schema/std-submesh-representation';
import {
    StdGroupedMeshRepresentation as StdGroupedMeshRepresentationF,
} from '../schema/std-grouped-mesh-representation';
import {
    SubmeshGroupRepresentation as SubmeshGroupRepresentationF,
} from '../schema/submesh-group-representation';
import {
    TerrainHeightMapRepresentation as TerrainHeightMapRepresentationF,
} from '../schema/terrain-height-map-representation';
import {
    TerrainTileId as TerrainTileIdF,
} from '../schema/terrain-tile-id';
import {
    TerrainTile as TerrainTileF,
} from '../schema/terrain-tile';
import { FlatbufCommon } from './FlatbufCommon';
import { Transform } from 'math-ts';

export function createFlatbufReprObjectFromTypeId(ty:SceneObjectRepresentation): Object | null {
    switch (ty) {
        case SceneObjectRepresentation.NONE: {
            return null;
        }
        case SceneObjectRepresentation.StdMeshRepresentation: {
            return new StdMeshRepresentationF();
        }
        case SceneObjectRepresentation.SceneImageRepresentation: {
            return new SceneImageRepresentationF();
        }
        case SceneObjectRepresentation.HeightmapRepresentation: {
            return new HeightmapRepresentationF();
        }
        case SceneObjectRepresentation.TerrainHeightMapRepresentation: {
            return new TerrainHeightMapRepresentationF();
        }
        case SceneObjectRepresentation.StdGroupedMeshRepresentation: {
            return new StdGroupedMeshRepresentationF();
        }
        default: {
            LegacyLogger.deferredError('unexpected repr type', ty);
            return null;
        }
    }
}

export function createAnalyticalFlatbufReprObjFromTypeId(ty:SceneObjectAnalyticalRepresentation): Object | null {
    switch (ty) {
        case SceneObjectAnalyticalRepresentation.NONE: {
            return null;
        }
        case SceneObjectAnalyticalRepresentation.BasicAnalyticalRepresentation: {
            return new BasicAnalyticalRepresentationF();
        }
    }
}


export class InterningReprSerializer {

    readonly _serialized: DefaultMap<ObjectRepresentation, [offset: number, ty: SceneObjectRepresentation]>;
    readonly _serializedGroups: DefaultMap<SubmeshGroupRepresentation, number>;
    readonly _serializedAnalytical: DefaultMap<BasicAnalyticalRepresentation, [offset: number, ty: SceneObjectAnalyticalRepresentation]>;

    constructor(
        readonly builder: Builder
    ) {
        this._serialized = new DefaultMap((repr) => {
            const t = this._writeRepresentation(repr);
            return t;
        });
        this._serializedGroups = new DefaultMap((repr) => {
            const t = this._writeGroupRepresentation(repr);
            return t;
        });
        this._serializedAnalytical = new DefaultMap((repr) => {
            const t = this._writeRepresentationAnalytical(repr);
            return t;
        });
    }

    writeRepresentation(representation: ObjectRepresentation | null): [offset: number, ty: SceneObjectRepresentation] {
        if (representation === null) {
            return [0, 0];
        }
        return this._serialized.getOrCreate(representation);
    }

    _writeRepresentation(representation: ObjectRepresentation): [offset: number, ty: SceneObjectRepresentation] {
        const builder = this.builder;
        switch (representation.constructor) {
            case StdMeshRepresentation: {
                const r = representation as StdMeshRepresentation;
                return [
                    StdMeshRepresentationF.createStdMeshRepresentation(
                        builder,
                        StdMeshRepresentationF.createSubmeshesVector(
                            builder,
                            r.submeshes.map(s => this.writeStdSubmesh(builder, s)),
                        ),
                        r.lod1?.enableAfterDetailSize ?? 0,
                        r.lod1 ? StdMeshRepresentationF.createSubmeshesVector(
                            builder,
                            r.lod1.submeshes.map(s => this.writeStdSubmesh(builder, s)),
                        ) : 0,
                    ),
                    SceneObjectRepresentation.StdMeshRepresentation
                ]
            }
            case StdGroupedMeshRepresentation: {
                const r = representation as StdGroupedMeshRepresentation;
                const submeshesGrouped = r.submeshesGrouped.map(repr => this._serializedGroups.getOrCreate(repr));
                return [
                    StdGroupedMeshRepresentationF.createStdGroupedMeshRepresentation(
                        builder,
                        StdGroupedMeshRepresentationF.createSubmeshesVector(
                            builder,
                            r.submeshes.map(s => this.writeStdSubmesh(builder, s)),
                        ),
                        SubmeshGroupRepresentationF.createSubmeshesVector(
                            builder,
                            submeshesGrouped
                        ),
                        r.lod1?.enableAfterDetailSize ?? 0,
                        r.lod1 ? StdMeshRepresentationF.createSubmeshesVector(
                            builder,
                            r.lod1.submeshes.map(s => this.writeStdSubmesh(builder, s)),
                        ) : 0,
                    ),
                    SceneObjectRepresentation.StdGroupedMeshRepresentation
                ]
            }
            case SceneImageRepresentation: {
                const r = representation as SceneImageRepresentation;
                SceneImageRepresentationF.startSceneImageRepresentation(builder);
                SceneImageRepresentationF.addImageId(builder, r.imageId);
                SceneImageRepresentationF.addWorldSize(builder, FlatbufCommon.writeVec2(builder, r.worldSize));
                return [SceneImageRepresentationF.endSceneImageRepresentation(builder), SceneObjectRepresentation.SceneImageRepresentation];
            }
            case HeightmapRepresentation: {
                const r = representation as HeightmapRepresentation;
                return [HeightmapRepresentationF.createHeightmapRepresentation(
                    builder,
                    r.heightmapGeoId,
                    r.heightmapImageId ?? 0
                ), SceneObjectRepresentation.HeightmapRepresentation]
            }
            case TerrainHeightMapRepresentation: {
                const r = representation as TerrainHeightMapRepresentation;
                return [TerrainHeightMapRepresentationF.createTerrainHeightMapRepresentation(
                    builder,
                    r.tileSize,
                    TerrainHeightMapRepresentationF.createTilesIdsVector(
                        builder,
                        IterUtils.mapIter(
                            r.tiles.keys(), 
                            tileId => TerrainTileIdF.createTerrainTileId(builder, tileId.x, tileId.y),
                        ),
                    ),
                    TerrainHeightMapRepresentationF.createTilesVector(
                        builder,
                        IterUtils.mapIter(
                            r.tiles.values(), 
                            tile => TerrainTileF.createTerrainTile(builder, tile.initialGeo, tile.updatedGeo),
                        ),
                    ),
                ), SceneObjectRepresentation.TerrainHeightMapRepresentation]
            }
        }
        LegacyLogger.warn('unrecoginzed object representaiton', representation);
        return [0, 0];
    }
    
    _writeGroupRepresentation(representation: SubmeshGroupRepresentation): number {
        const builder = this.builder;
        const submeshes = SubmeshGroupRepresentationF.createSubmeshesVector(
            builder, 
            representation.submeshes.map(s => this.writeStdSubmesh(builder, s))
        );
        SubmeshGroupRepresentationF.startSubmeshGroupRepresentation(builder);
        SubmeshGroupRepresentationF.addSubmeshes(builder, submeshes);
        SubmeshGroupRepresentationF.addTransform(
            builder, 
            FlatbufCommon.writeTransform(builder, representation.transform)
        );
        return SubmeshGroupRepresentationF.endSubmeshGroupRepresentation(builder);
    }

    writeStdSubmesh(builder: Builder, sm: StdSubmeshRepresentation): number {
        StdSubmeshRepresentationF.startStdSubmeshRepresentation(builder);
        StdSubmeshRepresentationF.addGeometryId(builder, sm.geometryId);
        StdSubmeshRepresentationF.addMaterialId(builder, sm.materialId);
        if (sm.transform) {
            StdSubmeshRepresentationF.addTransform(builder, FlatbufCommon.writeTransform(builder, sm.transform));
        }
        return StdSubmeshRepresentationF.endStdSubmeshRepresentation(builder);
    }

    writeRepresentationAnalytical(representation: BasicAnalyticalRepresentation | null): [offset: number, ty: SceneObjectAnalyticalRepresentation] {
        if (representation === null) {
            return [0, 0];
        }
        return this._writeRepresentationAnalytical(representation);
    }
    
    _writeRepresentationAnalytical(representation: BasicAnalyticalRepresentation): [offset: number, ty: SceneObjectAnalyticalRepresentation] {
        if (representation instanceof BasicAnalyticalRepresentation) {
            return [BasicAnalyticalRepresentationF.createBasicAnalyticalRepresentation(
                this.builder,
                representation.geometryId
            ), SceneObjectAnalyticalRepresentation.BasicAnalyticalRepresentation]
        }
        LegacyLogger.warn('unrecoginzed object analytical representaiton', representation);
        return [0, 0];
    }
    
    
}

export class InterningReprDeserializer {

    readonly _perOffsetRepr: Map<number, ObjectRepresentation |  null> = new Map();
    readonly _perOffsetReprA: Map<number, BasicAnalyticalRepresentation |  null> = new Map();

    constructor() {
    }

    readRepresentation(ty:SceneObjectRepresentation, reprObject: any): ObjectRepresentation | null {
        console.assert(reprObject.bb_pos, 'serializer repr bb_pos');
        let deser = this._perOffsetRepr.get(reprObject.bb_pos);
        if (!deser) {
            deser = this._readRepresentation(ty, reprObject);
            this._perOffsetRepr.set(reprObject.bb_pos, deser);
        }
        return deser;
    }

    _readRepresentation(ty:SceneObjectRepresentation, reprObject: any): ObjectRepresentation | null {
        if (reprObject === null) {
            return null;
        }
        switch (ty) {
            case SceneObjectRepresentation.StdMeshRepresentation: {
                const r = reprObject as StdMeshRepresentationF;
                const submeshes: StdSubmeshRepresentation[] = [];
                for (let i = 0, il = r.submeshesLength(); i < il; ++i) {
                    submeshes.push(this.readStdSubmesh(r.submeshes(i)!));
                }

                const lod1Submeshes: StdSubmeshRepresentation[] = [];
                for (let i = 0, il = r.lod1SubmeshesLength(); i < il; ++i) {
                    lod1Submeshes.push(this.readStdSubmesh(r.lod1Submeshes(i)!));
                }

                return new StdMeshRepresentation(
                    submeshes,
                    lod1Submeshes.length ? new StdSubmeshesLod(lod1Submeshes, r.lod0DetailSize()) : null,
                );
            }
            case SceneObjectRepresentation.StdGroupedMeshRepresentation: {
                const r = reprObject as StdGroupedMeshRepresentationF;
                const submeshes: StdSubmeshRepresentation[] = [];
                for (let i = 0, il = r.submeshesLength(); i < il; ++i) {
                    submeshes.push(this.readStdSubmesh(r.submeshes(i)!));
                }

                const submeshesGrouped: SubmeshGroupRepresentation[] = [];
                for (let i = 0, il = r.submeshesGroupedLength(); i < il; ++i) {
                    submeshesGrouped.push(this.readGroupedSubmeshes(r.submeshesGrouped(i)!));
                }

                const lod1Submeshes: StdSubmeshRepresentation[] = [];
                for (let i = 0, il = r.lod1SubmeshesLength(); i < il; ++i) {
                    lod1Submeshes.push(this.readStdSubmesh(r.lod1Submeshes(i)!));
                }

                return new StdGroupedMeshRepresentation(
                    submeshes,
                    submeshesGrouped,
                    lod1Submeshes.length ? new StdSubmeshesLod(lod1Submeshes, r.lod0DetailSize()) : null,
                );
            }
            case SceneObjectRepresentation.SceneImageRepresentation: {
                const r = reprObject as SceneImageRepresentationF;
                return new SceneImageRepresentation(
                    r.imageId(),
                    FlatbufCommon.readVec2(r.worldSize()!)
                );
            }
            case SceneObjectRepresentation.HeightmapRepresentation: {
                const r = reprObject as HeightmapRepresentationF;
                return new HeightmapRepresentation(
                    r.heightmapGeoId(),
                    r.heightmapImageId(),
                );
            }
            case SceneObjectRepresentation.RulerRepresentation: {
                return null;
            }
            case SceneObjectRepresentation.TerrainHeightMapRepresentation: {
                const r = reprObject as TerrainHeightMapRepresentationF;
                const tiles = new Map<TerrainTileId, TerrainTile>();
                for (let i = 0;  i < r.tilesIdsLength(); ++i) {
                    const tileIdF = r.tilesIds(i)!;
                    const tileId = TerrainTileId.new(tileIdF.x(), tileIdF.y());
                    
                    const tileF = r.tiles(i)!;
                    const tile = new TerrainTile(tileF.initialGeoId(), tileF.updatedGeoId());
                    tiles.set(tileId, tile);
                }
                return new TerrainHeightMapRepresentation(
                    r.tileSize(),
                    tiles
                );
            }
        }
        return null;
    }
    
    readStdSubmesh(sm: StdSubmeshRepresentationF): StdSubmeshRepresentation {
        const trf = sm.transform();
        const tr = trf ? FlatbufCommon.readTransform(trf) : null;
        return new StdSubmeshRepresentation(
            sm.geometryId(),
            sm.materialId(),
            tr
        );
    }

    readGroupedSubmeshes(g: SubmeshGroupRepresentationF): SubmeshGroupRepresentation {
        const trf = g.transform();
        const tr = trf ? FlatbufCommon.readTransform(trf) : new Transform();
        
        const submeshes: StdSubmeshRepresentation[] = [];
        for (let i = 0, il = g.submeshesLength(); i < il; ++i) {
            submeshes.push(this.readStdSubmesh(g.submeshes(i)!));
        }

        return new SubmeshGroupRepresentation(
            submeshes,
            tr
        );
    }

    readRepresentationAnalytical(ty:SceneObjectAnalyticalRepresentation, reprObject: any): BasicAnalyticalRepresentation | null {
        console.assert(reprObject.bb_pos, 'serializer an_repr bb_pos');
        let deser = this._perOffsetReprA.get(reprObject.bb_pos);
        if (!deser) {
            deser = this._readRepresentationAnalytical(ty, reprObject);
            this._perOffsetReprA.set(reprObject.bb_pos, deser);
        }
        return deser;
    }
    
    _readRepresentationAnalytical(rt:SceneObjectAnalyticalRepresentation, reprObject: any): BasicAnalyticalRepresentation | null {
        switch (rt) {
            case SceneObjectAnalyticalRepresentation.BasicAnalyticalRepresentation: {
                const r = reprObject as BasicAnalyticalRepresentationF;
                return new BasicAnalyticalRepresentation(r.geometryId());
            }
        }
        return null;
    }
}
